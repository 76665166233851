import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputFieldDefinition } from '../../../../models/postsales-operations-response.model';
import { PlcDateUtils } from '../../../../utils/plc-date-utils';
import { FactorInputType, ToolConfDefinition } from '../../model/tool-conf-definition';
import { LpcToolUtils } from '../../utils/lpc-tool-utils';
import { FACTOR_TOOL_CODE, FactorToolCode } from '../../../../models/enums/vita.enum';

@Component({
  selector: 'lpc-tool-conf[definition]',
  templateUrl: './lpc-tool-conf.component.html',
  styleUrls: ['./lpc-tool-conf.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcToolConfComponent),
      multi: true
    }
  ]
})
export class LpcToolConfComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  private $subscriptions: Subscription[] = [];

  @Input() definition: ToolConfDefinition;

  @Input() operationCode: string;

  @Input() active: boolean;

  @Output() factorReload: EventEmitter<any> = new EventEmitter<any>();

  public inputField: InputFieldDefinition;
  public inputType: FactorInputType;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    factor: new UntypedFormControl(null)
  });

  constructor() {
  }

  ngOnInit(): void {
    this.$subscriptions.push(
      this.formGroup.get('factor').valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouch();
        if (this.definition.reloadOnChange) {
          this.factorReload.emit();
        }
      }),
    );
    if (this.definition.readOnly) {
      this.formGroup.get('factor').disable();
    }
    this.inputField = this.definition.type === FactorInputType.DATELIST ? LpcToolUtils.factorToolToInputField(this.definition) : null;
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  trackByFn(item: any): any {
    return item.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definition) {
      this.inputType = changes.definition.currentValue.type;
    }
}


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


  writeValue(obj: any): void {
    this.formGroup.get('factor').setValue(obj, {emitEvent: false});
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  public getLabel(code: FactorToolCode): string {
    if (code === FACTOR_TOOL_CODE.FIRSTTERM_CODE && !!this.operationCode) {
      return 'lpc_' + code + this.operationCode;
    }
    return 'lpc_' + code;
  }

  getSelectedLabel(code: string): string {
    if (!!this.definition && !!code) {
      const obj = this.definition.values.find(val => val.id === code);
      if (!!obj) {
        return this.definition.values.find(val => val.id === code).description;
      }
    }
  }

  getDate(date: string) {
    if (!!date) {
      return PlcDateUtils.isoToFormattedDate(date);
    }
  }
}

import {AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {ViewportRuler} from '@angular/cdk/overlay';


export interface StickableCheckerChange {
  isOutSideViewport: boolean;
  el: any;
}


/**
 * A directive that calculate the collision of an HTML element to detect when the screen frame has left/returned to the original container position.
 */
@Directive({
  selector: '[rgiRxStickableChecker]',
})
export class StickableCheckerDirective implements OnInit, AfterContentInit, OnDestroy {

  /**
   * the position of the sticker : top | bottom
   * this is used for the collision calculation and not for applying css positions
   */
  @Input('rgiRxStickableChecker') posRef: 'top' | 'bottom';

  /**
   * Emit a StickableCheckerChange event when the collision detection changes
   * @See StickableCheckerChange
   */
  @Output('stickableCheckerChange') change = new EventEmitter<StickableCheckerChange>();

  private isOut = false;
  private windowHostAnchor: HTMLElement;
  private originalDisplay: string;


  constructor(private hostElement: ElementRef, private ruler: ViewportRuler, private renderer: Renderer2) {
  }

  ngAfterContentInit(): void {
    this.windowHostAnchor = this.renderer.selectRootElement(this.hostElement.nativeElement.cloneNode(true));
    this.renderer.setAttribute(this.windowHostAnchor, 'rgiRxstickableCheckerHostAnchor', '');
    this.originalDisplay = this.windowHostAnchor.style.display ? this.windowHostAnchor.style.display : 'block';
    this.renderer.insertBefore(this.hostElement.nativeElement.parentNode, this.windowHostAnchor, this.hostElement.nativeElement);
    this.checkOutWindow();
  }

  ngOnInit(): void {
    if (!this.posRef) {
      this.posRef = 'top';
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll($event) {
    this.checkOutWindow();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.checkOutWindow();
  }

  private checkOutWindow() {

    setTimeout(
      () => {
        let element;
        if (this.windowHostAnchor.style.display === 'none') {
          element = this.hostElement.nativeElement;
        } else {
          element = this.windowHostAnchor;
        }
        const pos: ClientRect = element.getBoundingClientRect();
        const newIsOut = this.isOutsideElementViewPort(pos, this.posRef);
        if (newIsOut != this.isOut) {
          this.isOut = newIsOut;
          if (this.isOut === false) {
            this.renderer.setStyle(this.windowHostAnchor, 'display', `none`);
          } else {
            this.renderer.setStyle(this.windowHostAnchor, 'display', this.originalDisplay);
          }
          this.change.emit({isOutSideViewport: this.isOut, el: this.windowHostAnchor});
        }
      }
    );
  }


  ngOnDestroy(): void {
    this.renderer.removeChild(this.hostElement.nativeElement.parentNode, this.windowHostAnchor);
  }


  isOutsideElementViewPort(pos: ClientRect, posRef: 'top' | 'bottom'): boolean {
    switch (posRef) {
      case 'top':
        const posWindow = this.ruler.getViewportScrollPosition().top;
        return posWindow > 0 && posWindow > pos.height;
      case 'bottom':
        const windowHeight = this.ruler.getViewportSize().height;
        return (windowHeight < pos.top && pos.bottom > 0);
      default:
        throw new Error('check oui window directive: posRef unknown');
    }
  }
}

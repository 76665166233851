export class Unit {
    code: string;
    description: string;
    ministerialBranch?: string;
    claimBranch?: null | any;
    riskCode?: string;
}

export class Category {
    descrizione: string;
    codice: string;
    $$hashKey: string;
}
export class State {
    codice: string;
}

export class SelectedEventCategory {
    idCategory: number;
    description: string;
    cssClass: string;
    branchesCodes: string[] = [];
    ministerialBranchCodes: string[];
    certifiedOccurrence: boolean;
    code: string;
}

export class CodiceDescrizione {
    codice: string = '';
    descrizione: string = '';
}

export class ServiceInfo {
    executionId: string;
    logs: any;
    codiceErroreOccorso: string;
    tipoErroreOccorso: string;
    descrizioneErroreOccorso: any;
    dettagliSullErroreOccorso: any;
    eccezioneOccorsaSerializzata: any;
}

export class Outcome {
    code: string = '';
    descr: string = '';
    idpotentialclaim: string = '';
    potentialclaimnumber: string = '';
    companycode: string = '';
    detail: string = '';
    messages: string = '';
    savedCodeList: string = '';
    objects: string = '';
}

export class EnumType {
    descrizione: string;
    codice: string;
}

export class BaremEntity {
    objectId: string = '';
    barem: EnumType = new EnumType();
}

export class CategoryDetail {
    idCategory: number;
    description: string;
    cssClass: string;
    branchesCodes: string[];
    ministerialBranchCodes: string[];
    certifiedOccurrence: boolean;
    code: string;
}
export class OutputClaimContext {
    id: string;
    code: string;
    description: string;
}

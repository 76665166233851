import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccordionComponent, PanelDirective, PanelTitleDirective, PanelContentDirective} from './accordion';

export {AccordionComponent, PanelDirective, PanelTitleDirective, PanelContentDirective, PanelChangeEvent} from './accordion';

const ACCORDION_DIRECTIVES = [AccordionComponent, PanelDirective, PanelTitleDirective, PanelContentDirective];


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: ACCORDION_DIRECTIVES,
  exports: ACCORDION_DIRECTIVES
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class AccordionModule { }

<div *ngIf="state | async as state">
  <div class="div-gre">
    <div class="sticky-premium-bar">
      <div class="premium-instalment">
        <span class="title-premium" translate>RE_ISSUE.SUBSCRIPTION_INST_PREMIUM</span>
        <span *ngIf="quotationPremium">
        <div class="currency-premium">
          {{ quotationPremium?.productPremium?.subscriptionInstalment?.premium.gross | currency: currency.code : '' :'1.2-2':'it' }}
        </div>
        <span class="currency-gross">
        {{currency.symbol + " / "}}
          <span translate>RE_ISSUE.GROSS</span>
        </span>
      </span>
        <span *ngIf="!quotationPremium">--</span>
      </div>

      <hr class="separator">

      <div class="premium-instalment" data-qa="premium_annual">
        <span class="title-premium" translate>RE_ISSUE.ANNUAL_PREMIUM</span>
        <span *ngIf="quotationPremium">
        <div class="currency-premium">
          {{ quotationPremium?.productPremium?.annual?.premium.gross | currency: currency.code : '' :'1.2-2':'it' }}
        </div>
        <span class="currency-gross">
          {{currency.symbol + " / "}}
          <span translate>RE_ISSUE.GROSS</span>
        </span>
      </span>
        <span *ngIf="!quotationPremium">--</span>
      </div>

      <div class="premium-bar-buttons">

        <div class="premium-bar-item">
          <button rgi-rx-button (click)="recalculatePremium($event)"
                  [disabled]="!recalculateAvailable" data-qa="recalculate_button"
                  class="btn-premium-recalculate rgi-ui-btn-primary text-uppercase">
            <span translate>RE_ISSUE.RECALCULATE</span>
          </button>
        </div>

        <div class="premium-bar-item">
          <button rgi-rx-button (click)="openDiscountsModal()"
                  [disabled]="recalculateAvailable"
                  class="btn-premium-percentage rgi-ui-btn rgi-ui-btn-info">
            <span class="rgi-re-icon-percent"></span>
          </button>
        </div>

        <div class="premium-bar-item">
          <button rgi-rx-button [rgiRxMenuTrigger]="menu" #trigger1="rgiRxMenuTrigger"
                  data-qa="btn_view"
                  class="btn-premium rgi-ui-btn rgi-ui-btn-info text-uppercase">
            <span class="hidden-xs hidden-sm" translate>RE_ISSUE.VISUALIZE</span> <span
            class="pull-right rgifont rgi-caret-down top-2"></span>
            <span class="controls-icon visible-xs visible-sm rgifont rgi-info"></span>
          </button>
        </div>

<!--        <div ngbDropdown class=" premium-bar-item unit-style-toggle btn-group nmf_button_contview">-->
<!--          <button rgi-rx-button ngbDropdownToggle-->
<!--                  class="rgi-ui-btn rgi-ui-btn-info btn-premium nmf_button_headerecalculate nmf_button_menunit"-->
<!--                  aria-expanded="false" aria-haspopup="true" type="button">-->
<!--            <span class="rgifont rgi-cog"></span>-->
<!--            <span class="rgifont rgi-caret-down"></span>-->
<!--          </button>-->
<!--          <ul ngbDropdownMenu role="menu" class="quote-controls-dropdown">-->
<!--            <li ngbDropdownItem role="menuitem">-->
<!--              <a role="button" (click)="viewBoxUnits()">-->
<!--                <span class="rgifont rgi-view_square"></span>&nbsp;<b translate>RE_ISSUE.BOX</b>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li ngbDropdownItem role="menuitem">-->
<!--              <a role="button" (click)="viewListUnits()">-->
<!--                <span class="rgifont rgi-menu"></span>&nbsp;&nbsp;<b translate>RE_ISSUE.LIST</b>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

      </div>


    </div>
    <rgi-rx-menu #menu data-qa="quotation_view_menu">
      <div rgi-rx-menu-item (click)="openPremiumDetailsModal()" data-qa="premium_detail">
        <i class="rgifont"></i>
        <span class="quotation-menu" translate>RE_ISSUE.PREMIUM_DETAILS</span>
      </div>
      <div rgi-rx-menu-item *ngIf="state.lpsData?.policyLpsData?.lpsType !== '4'"
           (click)="openContributionsAndTaxesModal()" data-qa="contribution_taxes">
        <i class="rgifont"></i>
        <span class="quotation-menu" translate>RE_ISSUE.CONTRIBUTIONS_AND_TAXES</span>
      </div>
      <div rgi-rx-menu-item *ngIf="state.lpsData?.policyLpsData?.lpsType === '4'" (click)="openFPSTaxesModal()"
           data-qa="fps_taxes">
        <i class="rgifont"></i>
        <span class="quotation-menu" translate>RE_ISSUE.FPS_TAXES</span>
      </div>
      <div rgi-rx-menu-item (click)="openWarrantiesSummaryModal()" data-qa="warranties_summary">
        <i class="rgifont"></i>
        <span class="quotation-menu" translate>RE_ISSUE.WARRANTIES_SUMMARY</span>
      </div>
      <div rgi-rx-menu-item (click)="openCommissionsModal()" data-qa="commissions">
        <i class="rgifont"></i>
        <span class="quotation-menu" translate>RE_ISSUE.COMMISSIONS</span>
      </div>
    </rgi-rx-menu>
  </div>
</div>

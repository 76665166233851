<rgi-rx-modal>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="closeModal()">
      {{ '_CLAIMS_._PROPERTY_ADDRESS' | translate }}
    </rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-primary"  (click)="submitForm()">{{'_CLAIMS_._BUTTONS_._INSERT' | translate}}</button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeModal()">{{'_CLAIMS_._BUTTONS_._BACK' | translate}}</button>
    </rgi-rx-panel-footer>
    <div class="container-fluid">
      <claims-dsc-address-detail-component [typeOpenComponent]="enumTypeOpenTitle" [addressData]="addressData" (outputFormAddress)="outputAddressData($event)"></claims-dsc-address-detail-component>
    </div>
  </rgi-rx-panel>
</rgi-rx-modal>
import {RgiRxTranslations} from '@rgi/rx/i18n';

export function load_icd_i18n_IT() {
  return import(`./translations_it`).then(p => p.IT);
}
export function load_icd_i18n_EN() {
  return import(`./translations_en`).then(p => p.EN);
}


export const TRANSLATIONS_ICD: RgiRxTranslations = [
  {
    load: load_icd_i18n_IT,
    locale: 'it'
  },
  {
    load: load_icd_i18n_EN,
    locale: 'en'
  }
];

import {PartyAsset, PartyRequest} from './../../../models/domain-models/party-asset';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioPartyResourceService {

  private PROPOSALS_PATH = 'proposals/';
  private ASSETS_PATH = '/assets/';
  private PARTY_PATH = '/party';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  getAssetParties(proposalId: string, assetKey: string): Observable<PartyRequest> {
    return this.http.get<PartyRequest>(this.getAssetPartiesUri(proposalId, assetKey)).pipe(
      share()
    );
  }

  getAssetParty(resourceId: string, assetKey: string): Observable<PartyAsset> {
    return this.http.get<PartyAsset>(this.getAssetPartiesUri(resourceId, assetKey)).pipe(
      share()
    );
  }

  postAssetParty(proposalId: string, assetKey: string, partyAsset: PartyAsset): Observable<PartyAsset> {
    return this.http.post<PartyAsset>(this.getAssetPartiesUri(proposalId, assetKey), partyAsset).pipe(
      share()
    );
    // return of(null);
  }


  deleteAssetParty(proposalId: string, assetKey: string, idLatestPhotos: string, roleId: string) {
    return this.http.delete<PartyAsset>(this.deleteAssetPartiesUri(proposalId, assetKey, idLatestPhotos, roleId)).pipe(
      share()
    );
    // return of(null);
  }


  postAsset(resourceId: string, selectedAsset: string, arg2: null) {
    return this.http.post(this.getAssetPartiesUri(resourceId, selectedAsset), null).pipe(
      share()
    );
  }


  getAssetPartiesUri(proposalId: string, assetKey: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      this.ASSETS_PATH +
      assetKey +
      this.PARTY_PATH;
  }

  deleteAssetPartiesUri(proposalId: string, assetKey: string, idLatestPhotos: string, roleId: string): string {
    const queryParam = '?idLatestPhotos=' + idLatestPhotos + '&roleId=' + roleId
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      this.ASSETS_PATH +
      assetKey +
      this.PARTY_PATH + queryParam;
  }

  // getContractPartiesConf(contractId: string): string {
  //   return this.apiConf.api.portal.path + this.apiContactConf.path + contractId + this.apiContactConf.partiesPath;
  // }
}

import {Component, Input} from '@angular/core';

export class TableRow {
  label: string;
  taxable: string;
  rate: string;
  taxes: string;
  taxAntiMoneyLaundering: string;
  gross: string;
}

@Component({
  selector: 're-issue-table',
  templateUrl: './re-issue-table.component.html',
  styleUrls: ['./re-issue-table.component.css']
})
export class ReIssueTableComponent {
  @Input() dataSource: TableRow[];
  @Input() lpsTax?: any;
  @Input() footerData: any;
  @Input() totalAntiRacket: number;
  @Input() superHeader: string[];
  @Input() columnsToDisplay: string[];
}

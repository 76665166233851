import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {GpActionButton, GpGenericModalData} from '../../../group-policy-models/gp-modals';

/**
 * @author: dmasone
 * @description: Modal used for save action
 */
@Component({
  selector: 'rgi-gp-group-policy-modal',
  templateUrl: './group-policy-modal.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyModalComponent implements OnModalClose {

  public modalClose = new EventEmitter<string>();
  public text = '';
  btns: GpActionButton[];

  constructor(@Optional() @Inject(DIALOG_DATA) data: GpGenericModalData) {
    this.text = data.text;
    this.btns = data.buttons;
  }

}

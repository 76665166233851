import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {CardComponent, CardComponentService, SessionService} from '@rgi/digital-claims-common-angular';
import {RoutingHostDirective, RoutingService} from '@rgi/portal-ng-core';
import {LetterTemplatesCardComponent} from './letter-templates-card/letter-templates-card.component';

@Component({
  selector: 'claims-letter-templates',
  templateUrl: './letter-templates.component.html',
  styleUrls: ['./letter-templates.component.scss'],
  providers: [RoutingService]
})
export class LetterTemplatesComponent extends CardComponent implements AfterViewInit {
  @ViewChild(RoutingHostDirective, {static: true}) hostViewRef: RoutingHostDirective;
  routes: { letterTemplatesCard: any; };

  constructor(
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('letterTemplatesCard') letterTemplatesCard,
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      letterTemplatesCard,
    };
  }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;

    this.routingService.loadComponent(this.routes.letterTemplatesCard);

    (this.routingService.componentRef.instance as LetterTemplatesCardComponent).card = this.card;
  }
}

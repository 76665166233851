import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialSearchCardComponent } from './financial-search-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { FinancialListComponent } from './financial-list/financial-list.component';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import { ProfileAllocationComponent } from './profile-allocation/profile-allocation.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LoaderMobileModule } from '../../modules/lpc-loader-mobile/loader-mobile.module';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';



@NgModule({
    declarations: [
        FinancialSearchCardComponent,
        FinancialListComponent,
        ProfileDetailComponent,
        ProfileAllocationComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        PortalNgCoreModule,
        RgiCountryLayerModule,
        LoaderMobileModule,
        RgiRxI18nModule
    ],
    exports: [
        FinancialSearchCardComponent,
        FinancialListComponent,
        ProfileDetailComponent,
        ProfileAllocationComponent
    ],
    providers: [
        { provide: PV_TOKEN.SHOW_LOADER_MOBILE, useValue: false }
    ]
})
export class FinancialSearchCardModule { }

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ContentChildren, QueryList, AfterContentInit
} from '@angular/core';
import {QuestionnaireFlatI} from '../../models';
import {QuestionAnswerI, QuestionnaireI} from '../../models';
import {QuestionnaireValuesService} from "../../services/questionnaire-values.service";
import {QuestionnaireConverterService} from "../../services/questionnaire-converter.service";
import {CustomAnswerDirective} from "../../custom-answer.directive";
import {DynamicAnswerRenderService} from "../../services/dynamic-answer-render.service";

@Component({
  selector: 'ppevo-questionnaire, ng-passpro-questionnaire',
  templateUrl: './questionnaire.component.html',
  encapsulation: ViewEncapsulation.None,
  providers:[DynamicAnswerRenderService]
})
export class QuestionnaireComponent implements OnChanges, AfterContentInit {

  @Input() questionnaireFlat: QuestionnaireFlatI;
  @Input() disabledQuestions: Array<{ code: string }>;
  @Input() disabled:boolean;
  @Input() requireValidation: boolean = true;

  @Output() clickSectionInfoNotes = new EventEmitter<number>();
  @Output() clickQuestionInfoNotes = new EventEmitter<number>();

  @ContentChildren(CustomAnswerDirective) customAnswers: QueryList<CustomAnswerDirective>;

  questionnaire: QuestionnaireI;


  constructor(
    private questionnaireValuesService: QuestionnaireValuesService,
    private questConverter: QuestionnaireConverterService,
    private dynamocAnswersRenderer: DynamicAnswerRenderService,
  ) {}


  ngOnChanges(changes: SimpleChanges): void {

    if(changes.questionnaireFlat){
      this.updateQuestionnaire();
    }else if(changes.disabled){
      this.updateQuestionnaireDisabling();
    }

  }

  ngAfterContentInit(): void {
    this.dynamocAnswersRenderer.customAnswers = this.customAnswers.map(item => item);
  }




  validate() {
    if(!this.questionnaire){
      return false;
    }
    const valid = this.questionnaireValuesService.assignValidationAllQuestions(this.questionnaire);
    if (!this.requireValidation || valid) {
      this.questionnaireValuesService.assignFlatValues(this.questionnaire, this.questionnaireFlat);
    }
    return valid;
  }


  onChange(question: QuestionAnswerI) {
    this.questionnaireValuesService.assignValidationQuestion(question);
    this.questionnaireValuesService.checkConditions(this.questionnaire);
  }


  onClickSectionInfoNotes(sectionId:number){
    this.clickSectionInfoNotes.emit(sectionId);
  }


  onClickQuestionInfoNotes(questionId:number){
    this.clickQuestionInfoNotes.emit(questionId);
  }


  protected updateQuestionnaire(){
    if(this.questionnaireFlat){
      this.questionnaire = this.questConverter.convertFlatToQuestionnaire(this.questionnaireFlat, this.disabledQuestions);
      this.questionnaireValuesService.assignValues(this.questionnaire, this.questionnaireFlat);
      this.questionnaireValuesService.checkConditions(this.questionnaire);
      this.updateQuestionnaireDisabling();
    }else {
      this.questionnaire = null;
    }

  }


  protected updateQuestionnaireDisabling(){
    if( !this.questionnaire ){
      return;
    }
    if(this.disabled){
      this.questionnaireValuesService.disableAllQuestions(this.questionnaire);

    }else {
      this.questionnaireValuesService.enableAllQuestions(this.questionnaire, this.disabledQuestions);
    }
  }

}

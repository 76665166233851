import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {RgiRxUserService} from "@rgi/rx/auth";
import {RgiRxHttpClientFactory, RgiRxHttpClientWrapper} from "@rgi/rx/http";
import { Agency } from '../resources/models/sales-network-api-agency';
import { OperatorLight } from '../resources/models/external-service';

const KEY_PROPERTY_ADDRESS = 'EXTENDED_DESCRIPTION_POS';
const KEY_PROPERTY_EXTENDED_COD_POS_EXTERNAL = 'CCODPOSEXTERNAL';
const KEY_PROPERTY_EXTENDED_COD_AGE = 'CCODAGE';
const KEY_PROPERTY_EXTENDED_COD_ABI = 'CCODABI';
const KEY_PROPERTY_EXTENDED_COD_CAB = 'CCODCAB';
const KEY_PROPERTY_EXTENDED_COD_SUB = 'CCODSUB';
const KEY_PROPERTY_EXTENDED_COD_PROD = 'CCODPROD';

const TYPE_ITEM = 'salePoint';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  private baseApiUrl: string;
  private isArtUser = false;
  protected httpClient: RgiRxHttpClientWrapper;


  constructor(
      protected httpClientFactory: RgiRxHttpClientFactory,
    protected userService: RgiRxUserService,
    @Inject('environment') environment: any) {

    this.httpClient = this.httpClientFactory.buildWrapper();
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.isArtUser = this.getExternalProperties('isARTUser') === true;
  }

  getIterativeAgencies(currentNode): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    if (currentNode) {
      httpParams = httpParams.set('channel', '0');
      httpParams = httpParams.set('loginNodeId', currentNode.objectId);
      httpParams = httpParams.set('searchedNodeId', currentNode.objectId);
      httpParams = httpParams.set('showOnlyValidNodes', 'true');
    }

    return this.httpClient.get<any>(this.baseApiUrl + '/assimoco/iterativeagencies', { params: httpParams });
  }

  getArtAgency(nodes): any {
    let artAgency = null;
    const artAgencyCode = this.getExternalProperties('artAgencyCode');

    if (artAgencyCode != null) {
      nodes.agencies.forEach(agency => {
        const agencyProperties = agency.extension.properties.filter(property =>
          property.chiave === KEY_PROPERTY_EXTENDED_COD_AGE && property.valore === artAgencyCode);
        if (agencyProperties && agencyProperties.length > 0) {
          artAgency = agency;
        }
      });
    }

    return artAgency;
  }

  completeAgency(agency: any): any {
    if (!agency) {
        return null;
    }
    if (agency.data) {
      return agency;
    }
    return {
      data: agency,
      description: agency.description,
      value: agency.value,
      label: agency.description,
      id: agency.idSp,
      type: TYPE_ITEM
    };
  }

  getExternalProperties(key: string): any {
    const user: any = this.userService.getUser();
    const salePointDefault = user.salePoint;
    if (salePointDefault && salePointDefault.extension && Array.isArray(salePointDefault.extension.properties)) {
      const artAgencyCode = salePointDefault.extension.properties.filter(
        property => property.chiave === key);
      return artAgencyCode.length > 0 ? artAgencyCode[0].valore : null;
    }
    return null;
  }

  addExternalProperties(artAgency): void {
    if (artAgency && artAgency.extension && artAgency.extension.properties) {
      artAgency.extension.properties.forEach(property => {
        switch (property.chiave) {
          case KEY_PROPERTY_ADDRESS:
            artAgency.description = property.valore;
            break;
          case KEY_PROPERTY_EXTENDED_COD_POS_EXTERNAL:
            artAgency.codPosExternal = property.valore;
            break;
          case KEY_PROPERTY_EXTENDED_COD_AGE:
            artAgency.codAge = property.valore;
            break;
          case KEY_PROPERTY_EXTENDED_COD_ABI:
            artAgency.codAbi = property.valore;
            break;
          case KEY_PROPERTY_EXTENDED_COD_CAB:
            artAgency.codCab = property.valore;
            break;
          case KEY_PROPERTY_EXTENDED_COD_SUB:
            artAgency.codSub = property.valore;
            break;
          case KEY_PROPERTY_EXTENDED_COD_PROD:
            artAgency.codProd = property.valore;
            break;
        }
      });
    }
  }

  getArtUser(): boolean {
    return this.isArtUser;
  }

  getNodes$(): Observable<Agency[]> {
    let params = new HttpParams();
    if (this.userService.isLoggedIn()) {
      params = params.append('loginNodeId', this.userService.getUser<OperatorLight>().salePoint.objectId);
      params = params.append('showGroupingNodes', 'true');
    }
    return this.httpClient.get<Agency[]>(`${this.baseApiUrl}/v2/common/agencies`, {params});
  }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderMobileComponent } from './loader-mobile.component';



@NgModule({
    declarations: [LoaderMobileComponent],
    imports: [
        CommonModule
    ],
    exports: [LoaderMobileComponent]
})
export class LoaderMobileModule { }

export enum State {
  START,
  PRODUCT_ASSET_SELECTION_STANDARD_PLATE,
  PRODUCT_ASSET_SELECTION_ALTERNATIVE_PLATE,
  PRODUCT_ASSET_SELECTION_WITHOUT_PLATE,
  PRODUCT_ASSET_SELECTION_WRONG_DATA_IN_ANIA,
  PRODUCT_ASSET_SELECTION_DATA_IN_ANIA_NOT_FOUND,
  EDIT_ASSET_DATA_WITHOUT_PLATE,
  EDIT_ASSET_DATA_STANDARD_PLATE,
  EDIT_ASSET_DATA_ALTERNATIVE_PLATE,
  QUOTATION_WITHOUT_PLATE,
  QUOTATION_STANDARD_PLATE,
  QUOTATION_ALTERNATIVE_PLATE,
  PROPOSAL,
  PROPOSAL_WITHOUT_PLATE,
  ENTITLED,
  ENTITLED_WITHOUT_PLATE,
  TELEMATICS,
  SUMMARY,
  PROGRESS,
  PAYMENT,
  SUBSTITUTED_CONTRACTS,
  SURVEY,
  INCLUSION_APPLICATION,
  AMENDMENT_DATES
}

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {DataActions, MenuOperation, Operation} from '../../../group-policy-models/group-policy-inquiry';
import {
  GroupPolicyActionIntegrationService
} from '../../../group-policy-services/group-policy-action-integration.service';

@Component({
  selector: 'rgi-gp-group-policy-inquiry-actions-modal',
  templateUrl: './group-policy-inquiry-actions-modal.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyInquiryActionsModalComponent implements OnModalClose {

  public modalClose = new EventEmitter<any>();
  public data: DataActions;
  public singleOperations: Operation[];
  public nestedOperations: MenuOperation[];

  constructor(@Optional() @Inject(DIALOG_DATA) data: DataActions,
              protected actionIntegrationService: GroupPolicyActionIntegrationService) {
    this.data = data;
    if (data.actions && data.actions.length) {
      const singleOperations = data.actions.filter(action => action.operations && action.operations.length === 1);
      if (singleOperations && singleOperations.length > 0) {
        this.singleOperations = singleOperations.map(action => action.operations[0]);
        this.singleOperations.sort((a, b) => {
          return a.description.toUpperCase().localeCompare(b.description.toUpperCase());
        });
      }
      this.nestedOperations = data.actions.filter(action => action.operations && action.operations.length > 1);
      this.nestedOperations.sort(this.descriptionCompareFn());
      this.nestedOperations.forEach(nested => nested.operations.sort(this.descriptionCompareFn()));
    }
  }


  protected descriptionCompareFn() {
    return (a, b) => {
      return a.description.toUpperCase().localeCompare(b.description.toUpperCase());
    };
  }

  executeAction(operation: Operation) {
    this.actionIntegrationService.executeAction(operation, this.data);
    this.modalClose.emit();
  }
}

import {Component, ComponentRef, OnInit} from '@angular/core';
import {ApiPremiumDetail} from '../../models/api-models/api-premium-detail';
import {PremiumDetail} from '../../models/domain-models/premium-detail';
import {Modal} from '../../modal';
import {QuotationService} from '../quotation.service';

@Component({
  selector: 'mic-quotation-details',
  templateUrl: './quotation-details-modal.component.html',
  styleUrls: ['./quotation-details-modal.component.scss']
})
export class QuotationDetailsModalComponent implements Modal, OnInit {

  componentRef: ComponentRef<any>;
  premiumDetailSections: Array<PremiumDetail> = new Array<PremiumDetail>();
  // Premio di Rata Firma
  private premiumInstallment: ApiPremiumDetail;
  private annualPremium: ApiPremiumDetail;
  // Premio di Rata Successiva
  private premiumSubscriptionInstalment: ApiPremiumDetail;

  constructor(protected quotationService: QuotationService) {
  }

  ngOnInit() {
    this.annualPremium = this.quotationService.getPremium().productPremium.annual;
    this.premiumInstallment = this.quotationService.getPremium().productPremium.instalment;
    this.premiumSubscriptionInstalment = this.quotationService.getPremium().productPremium.subscriptionInstalment;
    this.initializePremiumDetailSections();
  }

  close() {
    this.componentRef.destroy();
  }

  private initializePremiumDetailSections() {
    this.premiumDetailSections.push(
      new PremiumDetail('Subscription Inst. Premium',
        (this.premiumSubscriptionInstalment.gross) ? this.premiumSubscriptionInstalment.gross : 0.00,
        (this.premiumSubscriptionInstalment.net) ? this.premiumSubscriptionInstalment.net : 0.00,
        (this.premiumSubscriptionInstalment.accessories) ? this.premiumSubscriptionInstalment.accessories : 0.00,
        (this.premiumSubscriptionInstalment.paymentFrequencyInterest)
          ? this.premiumSubscriptionInstalment.paymentFrequencyInterest : 0.00,
        (this.premiumSubscriptionInstalment.taxable) ? this.premiumSubscriptionInstalment.taxable : 0.00,
        (this.premiumSubscriptionInstalment.taxes) ? this.premiumSubscriptionInstalment.taxes : 0.00,
        (this.premiumSubscriptionInstalment.sSN) ? this.premiumSubscriptionInstalment.sSN : 0.00)
    );
    this.premiumDetailSections.push(
      new PremiumDetail('Premium Rate', (this.premiumInstallment.gross) ? this.premiumInstallment.gross : 0.00,
        (this.premiumInstallment.net) ? this.premiumInstallment.net : 0.00,
        (this.premiumInstallment.accessories) ? this.premiumInstallment.accessories : 0.00,
        (this.premiumInstallment.paymentFrequencyInterest) ? this.premiumInstallment.paymentFrequencyInterest : 0.00,
        (this.premiumInstallment.taxable) ? this.premiumInstallment.taxable : 0.00,
        (this.premiumInstallment.taxes) ? this.premiumInstallment.taxes : 0.00,
        (this.premiumInstallment.sSN) ? this.premiumInstallment.sSN : 0.00)
    );
    this.premiumDetailSections.push(
      new PremiumDetail('Annual Premium', (this.annualPremium.gross) ? this.annualPremium.gross : 0.00,
        (this.annualPremium.net) ? this.annualPremium.net : 0.00,
        (this.annualPremium.accessories) ? this.annualPremium.accessories : 0.00,
        (this.annualPremium.paymentFrequencyInterest) ? this.annualPremium.paymentFrequencyInterest : 0.00,
        (this.annualPremium.taxable) ? this.annualPremium.taxable : 0.00,
        (this.annualPremium.taxes) ? this.annualPremium.taxes : 0.00,
        (this.annualPremium.sSN) ? this.annualPremium.sSN : 0.00)
    );
  }

  isTaxComponentsAvailable(): boolean {
    return this.premiumInstallment.taxComponents && this.premiumInstallment.taxComponents.length > 0;
  }

}

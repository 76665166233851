import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ac-authorizations-list-detail',
  templateUrl: './authorizations-list-detail.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class AuthorizationsListDetailComponent implements OnInit {
  @Input() elementCurrentDetail: any;
  @Input() subcard: any;

  constructor() {
  }

  ngOnInit() {
  }

}

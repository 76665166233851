import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { PolicyCovertures } from '../../model/domain/policy/policy-covertures';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { PolicyAdditionalData } from '../../model/domain/policy/policy-additional-data';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  private baseApiUrlV2: string;
  private baseApiUrl: string;
  private urlPolicyFinder = '/ptfall/policies';
  private urlPolicyDetails = '/ptfdanni/contract';
  private urlAssetType = '/claims/assettype';
  private urlServiceGetPolicyAdditionalData = '/claims/getPolicyAdditionalData';
  private urlPolicyContext = '/claims/context';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getPolicyCovertures(policyNumber: string, occurrencedate: Date, occurrencetime: string): Observable<PolicyCovertures> {
    // console.log('getPolicyCovertures() - ', policyNumber, occurrencedate, occurrencetime);
    // TODO: uncomment when the service is done
    return this.httpClient.get(this.getPolicyCoverturesUri(policyNumber,
      occurrencedate, occurrencetime)).pipe(map(this.getPolicyCoverturesMapFunction));
    // TODO: remove when the service is done
    // return this.stubPolicyCovertureObservable();
  }

  private getPolicyCoverturesUri(policynumber: string, occurrencedate: Date, occurrencetime: string) {
    const formatdate = 'dd-MM-yyyy';
    const dateString = formatDate(occurrencedate, formatdate, this.locale);

    return this.baseApiUrlV2 + '/claims/covertures?policynumber=' + policynumber
      + '&occurrencedate=' + dateString
      + '&occurrencetime=' + occurrencetime;
  }

  // TODO: remove when the service is done
  private stubPolicyCovertureObservable(): Observable<PolicyCovertures> {
    const that = this;
    const result = new Observable<PolicyCovertures>((observer) => {
      observer.next(that.stubOkCoverture());
    });
    return result;
  }

  // TODO: remove when the service is done
  private stubOkCoverture(): PolicyCovertures {
    const covertures = new PolicyCovertures();
    covertures.technicalCoverture = true;
    covertures.administrativeCoverture = true;
    return covertures;
  }

  private getPolicyCoverturesMapFunction(result): PolicyCovertures {
    const covertures = new PolicyCovertures();
    covertures.technicalCoverture = result[0];
    covertures.administrativeCoverture = result[1];
    return covertures;
  }

  getPoliciesList(req: any) {
    return this.httpClient.post(this.baseApiUrl + this.urlPolicyFinder, req);
  }

  getPolicyDetail(objectId: number) {
    return this.httpClient.get(this.baseApiUrl + this.urlPolicyDetails + '/' + objectId);
  }

  getAssetType(assetCode: string) {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlAssetType + '?code=' + assetCode);
  }

  getPolicyContext(policyNumber: string) {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlPolicyContext + '/' + policyNumber);
  }

  getPolicyAdditionalData(policyNumber: string,
                          idCategory: string,
                          occurrenceDate: Date,
                          occurrenceTime?: string): Observable<PolicyAdditionalData> {
    const formatdate = 'dd-MM-yyyy';
    const dateString = formatDate(occurrenceDate, formatdate, this.locale);
    let queryString = '?policyNumber=' + policyNumber
                     + '&idCategory=' + idCategory
                     + '&occurrenceDate=' + dateString;
    if (occurrenceTime) {
      queryString += '&occurrenceTime=' + occurrenceTime;
    }
    return this.httpClient.get<PolicyAdditionalData>(this.baseApiUrlV2 + this.urlServiceGetPolicyAdditionalData + queryString)
      .pipe(map(this.getPolicyAdditionalDataMapFunction));
  }

  private getPolicyAdditionalDataMapFunction(result): PolicyAdditionalData {
    const policyAdditionalData = new PolicyAdditionalData();
    const covertures = new PolicyCovertures();
    covertures.technicalCoverture = result.covertures[0];
    covertures.administrativeCoverture = result.covertures[1];
    policyAdditionalData.covertures = covertures;
    policyAdditionalData.insuredActivityFactors = result.insuredActivityFactors;
    policyAdditionalData.enableMultiDamage = result.enableMultiDamage;
    policyAdditionalData.garctp = result.garctp;
    return policyAdditionalData;

  }
}

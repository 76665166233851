<div class="col-lg-12 blank lpc-warranty">
  <div class="row header-warranties">
    <div class="col-lg-10">
    <p style="font-weight: bold" translate>{{tot?.description}}</p>
    </div>
    <div class="col-lg-2 value-warranties">
      <span>{{ tot?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
    </div>
  </div>
  <div class="arrow-down"></div>
  <div class="tbld postsales-table-style hidden-xs">

    <!-- INTESTAZIONE -->
    <div class="postsales-row-title row">
      <div class="postsales-col-title col-16of100" translate>lpc_Guarantee</div> 
      <div class="postsales-col-title col-12of100" translate>lpc_pure</div>
      <div class="postsales-col-title col-12of100" translate>lpc_Loading</div>
      <div class="postsales-col-title col-12of100" translate>lpc_extra_premium</div>
      <div class="postsales-col-title col-12of100" translate>lpc_net</div>
      <div class="postsales-col-title col-8of100" translate>lpc_IntFract</div>
      <div class="postsales-col-title col-8of100" translate>lpc_Fees</div>
      <div class="postsales-col-title col-8of100" translate>lpc_Taxes</div>
      <div class="postsales-col-title col-12of100" translate>lpc_GrossPremium</div>
    </div>
    <!-- GARANZIE -->
    <div *ngFor="let inst of instalment" class="postsales-row-content row">
      <div class="postsales-col-content-index col-16of100" [attr.data-qa]="'garanzia-rataFirma-vita-'">{{inst.description}}</div>
      <div class="postsales-col-content col-12of100" [attr.data-qa]="'premioPuro-rataFirma-vita-'">
        {{inst.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
      <div class="postsales-col-content col-12of100" [attr.data-qa]="'caricamenti-rataFirma-vita-'">
        {{inst.loadings | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
      <div class="postsales-col-content col-12of100" [attr.data-qa]="'sovrappremio-rataFirma-vita-'">
        {{inst.extraPremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
      <div class="postsales-col-content col-12of100" [attr.data-qa]="'netto-rataFirma-vita-'">
        {{inst.net | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
      <div class="postsales-col-content col-8of100" [attr.data-qa]="'intFraz-rataFirma-vita-'">
        {{inst.frequencyInterests | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
      <div class="postsales-col-content col-8of100" [attr.data-qa]="'diritti-rataFirma-vita-'">
        {{inst.fees | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
      <div class="postsales-col-content col-8of100" [attr.data-qa]="'imposte-rataFirma-vita-'">
        {{inst.taxes | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
      <div class="postsales-col-content col-12of100" [attr.data-qa]="'lordo-rataFirma-vita-'">
        {{inst.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
      </div>
    </div>
    <!-- TOTALE -->
    <div *ngIf="!!tot" class="postsales-row-total row">
      <div class="postsales-col-total-index col-16of100" translate data-qa="garanzia-rataFirma-vita-total">lpc_total</div>
      <div class="postsales-col-total col-12of100" [attr.data-qa]="'premioPuro-rataFirma-vita-total'">{{ tot.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}</div>
      <div class="postsales-col-total col-12of100" data-qa="caricamenti-rataFirma-vita-total">{{ tot.loadings | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }} </div>
      <div class="postsales-col-total col-12of100" data-qa="sovrappremio-rataFirma-vita-total">{{ tot.extraPremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}</div>
      <div class="postsales-col-total col-12of100" data-qa="netto-rataFirma-vita-total">{{ tot.net | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
      <div class="postsales-col-total col-8of100" data-qa="intFraz-rataFirma-vita-total">{{ tot.frequencyInterests | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
      <div class="postsales-col-total col-8of100" data-qa="diritti-rataFirma-vita-total">{{ tot.fees | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
      <div class="postsales-col-total col-8of100" data-qa="imposte-rataFirma-vita-total">{{  tot.taxes | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}</div>
      <div class="postsales-col-total col-12of100" data-qa="lordo-rataFirma-vita-total">{{ tot.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
    </div>
  </div>
</div>


import {UnitDiscounts} from './discount-unit';

export class SectionDiscounts {
  constructor(
    public sectionId: number,
    public sectionCode: string,
    public sectionDescription: string,
    public productTotal: boolean,
    public unitDiscounts: Array<UnitDiscounts>,
    public enabled: boolean
  ) {
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { PaymentsDashboardIntegrationService } from '../configurations/payments-dashboard-integration.service';
import { map, mergeMap } from 'rxjs/operators';
import { restEndPoint } from '../configurations/payments-dashboard.endpoint';
import { AddForfait } from '../models/forfait/add-forfait.model';
import { ModifyForfait } from '../models/forfait/modify-forfait.model';
import { DeleteForfait } from '../models/forfait/delete-forfait.model';
import { SingleResponseForfait } from '../models/forfait/single-response-forfait.model';
import { ForfaitState } from '../states/forfait.state';
import { ForfaitDataFromRoute } from '../models/forfait/forfait-data-from-route.model';
import { OutputForfait } from '../models/forfait/output-forfait.model';

@Injectable({
  providedIn: "root",
})
export class ForfaitService {
  private baseApiUrlV2: string;

  constructor(
    private httpClient: HttpClient,
    @Inject("environment") environment: any,
    private integrationService: PaymentsDashboardIntegrationService
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + "/v2";
  }

  initState$(st: Observable<ForfaitState>, dataFromRoute: ForfaitDataFromRoute): Observable<ForfaitState> {
    return st.pipe(
      map((state: ForfaitState) => {
        state.search = dataFromRoute.searchForfaitOutputDTOs;
        state.dialogueFunctions = dataFromRoute.dialogueFunctions
        return state;
      })
    );
  }

  addForfait(st: Observable<ForfaitState>, addForfait: AddForfait) {
    return st.pipe(
      mergeMap((state: ForfaitState) => {
        const response: Observable<SingleResponseForfait> = this.httpClient.post<SingleResponseForfait>(this.baseApiUrlV2 + restEndPoint.addForfait, addForfait)
        return combineLatest([response, of(state)]);
      }),
      mergeMap(([response, state]) => {
        const singleResponseForfait: SingleResponseForfait = response

        if (singleResponseForfait == null) {
          state.errorMessage = '_CLAIMS_._ERROR_DURING_SAVING'
        } else {
          if (!!singleResponseForfait.requestOutcome
            && !!singleResponseForfait.requestOutcome.code
            && singleResponseForfait.requestOutcome.code == "KO"
            && !!singleResponseForfait.requestOutcome.description
          ) {
            state.errorMessage = singleResponseForfait.requestOutcome.description
          } else {
            const outputForfait: OutputForfait = singleResponseForfait.response;

            if (!!outputForfait && !!outputForfait.idForfait) {
              state.errorMessage = null
              state.add = response.response;
            } else {
              state.errorMessage = '_CLAIMS_._NOT_CORRECT_ENTRY_DATA'
            }
          }

          return of(state)
        }
      })
    );
  }

  modifyForfait(st: Observable<ForfaitState>, modifyForfait: ModifyForfait) {
    return st.pipe(
      mergeMap((state: ForfaitState) => {
        const response: Observable<SingleResponseForfait> = this.httpClient.put<SingleResponseForfait>(this.baseApiUrlV2 + restEndPoint.modifyForfait, modifyForfait);
        return combineLatest([response, of(state)]);
      }),
      mergeMap(([response, state]) => {
        if (!!response) {
          state.modify = response.response;
          state.oldIdForfait = modifyForfait.idForfait
        }

        return of(state)
      }),
    );
  }

  deleteForfait(st: Observable<ForfaitState>, deleteForfait: DeleteForfait) {
    return st.pipe(
      mergeMap((state: ForfaitState) => {
        const response = this.httpClient.delete(this.baseApiUrlV2 + restEndPoint.deleteForfait, { body: deleteForfait });
        return combineLatest([response, of(state)]);
      }),
      mergeMap(([response, state]) => {
        if(!!response) {
          state.delete = deleteForfait
          state.oldIdForfait = String(deleteForfait.idForfait)
        }
        return of(state)
      })
    );
  }
}
import {TermFlatI} from './term-flatI';

export class ConditionFlatI {

  terms: TermFlatI[];
  status: number;

  static copy(src: ConditionFlatI): ConditionFlatI {
    const cf = new ConditionFlatI();
    cf.status = src.status;
    cf.terms = src.terms.map(TermFlatI.copy);

    return cf;
  }
}

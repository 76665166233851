import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActiveRoute, RoutingService} from "@rgi/rx/router";
import {OutcomeresultsService} from "../services/outcomeresults.service";
import {RGI_RX_DROP_SELECTION_HANDLER} from "@rgi/rx/ui";
import {
  AdapterprintHomeNodeManagementComponent
} from "./adapterprint-home-node-management/adapterprint-home-node-management.component";
import {AdapterprintHomeNodeModel} from "../model/adapterprint-home-node-model";
import {RgiRxPushMessage, PushMessageHandlerService} from "@rgi/rx";
import {OperatorService} from "@rgi/portal-ng-core";
import {RgiRxDateTimeChange, RgiRxDateTimeFilterFn} from '@rgi/rx/ui/src/datepicker/date-picker-api';
import {DatePipe} from "@angular/common";
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {of} from "rxjs";
import {mergeMap} from "rxjs/operators";


@Component({
  selector: 'rgi-adapterprint-home',
  templateUrl: './adapterprint-home.component.html',
  host: {
    class: 'adapterprint-style'
  },
  providers: [{
    // DRAGDROP TOKEN
    provide: RGI_RX_DROP_SELECTION_HANDLER,
    useValue: {
      name: 'nodeSelection',
      handler: AdapterprintHomeNodeManagementComponent
    },
    multi: true
  }]
})
export class AdapterprintHomeComponent implements OnInit {

  constructor(public activeRoute: ActiveRoute, private routingService: RoutingService, private outcomeResultService: OutcomeresultsService,
              public pushMessageHandler: PushMessageHandlerService, private operatorService: OperatorService, public datepipe: DatePipe,
              public translateService: RgiRxTranslationService) {
  }

  //VARIABLES
  nodeDefault: any = this.operatorService.getSalePointLogin();
  nodeList: Array<AdapterprintHomeNodeModel>;
  emptyResult: boolean;
  notSent: string;
  sentOK: string;
  sentKO: string;


  states: Array<any>;

  // INIT
  ngOnInit() {
    this.translation();
    this.states  = [
      {description: this.sentOK, code: '1'},
      {description: this.sentKO, code: '2'},
      {description: this.notSent, code: '3'}
    ]
    this.emptyResult = false;
    this.outcomeResultService.getNode(this.nodeDefault.objectId).subscribe((response) => {
      this.nodeList = response;
    });
  }

  // FORM BINDING
  homeForm = new UntypedFormGroup({
    selectedNode: new UntypedFormControl(this.nodeDefault, Validators.required),
    dateFrom: new UntypedFormControl(this.getDateFromDefault(), [Validators.required]),
    dateTo: new UntypedFormControl(new Date, [Validators.required]),
    state: new UntypedFormControl(''),
    policyNumber: new UntypedFormControl(''),
    adressed: new UntypedFormControl('')
  })

  // DATE FROM DEFAULT VALUE
  getDateFromDefault() {
    let dateFromDefault: Date = new Date();
    dateFromDefault.setMonth(dateFromDefault.getMonth() - 1)
    return dateFromDefault
  }

// FIND
  search() {
    this.pushMessageHandler.clearTag('warnings');
    this.pushMessageHandler.clearTag('errors');
    if (this.homeForm.valid) {
      this.findResults()
    } else {
      this.homeForm.markAllAsTouched();
      let errorMsg: RgiRxPushMessage = new RgiRxPushMessage()
      const opts = {icon: "rgi-ui-icon-alert"};
      errorMsg.tag = "errors";
      errorMsg.status = "danger";
      this.translateService.translate('_ADAPTERPRINT_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(stringMsg => {
        errorMsg.content = stringMsg;
      }).unsubscribe();
      errorMsg.dismissible = false;
      errorMsg.options = opts;
      this.pushMessageHandler.notify(errorMsg);
    }
  }

  findResults() {
    const dateFromValue: string = this.homeForm.value.dateFrom;
    const dateToValue: string = this.homeForm.value.dateTo;
    const stateValue: string = this.homeForm.value.state;
    const policyNumberValue: string = this.homeForm.value.policyNumber;
    const addressed: string = this.homeForm.value.adressed;
    const selectedNodeCode: string = this.homeForm.value.selectedNode.code;
    // FIND PRINT OUTCOME RESULTS
    this.outcomeResultService.getOutcomeResults(selectedNodeCode, dateFromValue, dateToValue, stateValue, policyNumberValue, addressed)
      .subscribe((response) => {
        // GO TO RESULT PAGE OR DISPLAY EMPTY RESULT
        if (Object.keys(response).length) {
          this.emptyResult = false;
          response = this.formatResult(response);
          this.routingService.navigate('card.adapterprint.result', response, this.activeRoute.id);
        } else {
          let errorMsg: RgiRxPushMessage = new RgiRxPushMessage()
          const opts = {icon: "rgi-ui-icon-info"};
          this.emptyResult = true;
          errorMsg.tag = "warnings";
          errorMsg.status = "default";
          this.translateService.translate('_ADAPTERPRINT_._MSG_._NO_RESULTS_').subscribe(stringMsg => {
            errorMsg.content = stringMsg;
          }).unsubscribe();
          errorMsg.dismissible = false;
          errorMsg.options = opts;
          this.pushMessageHandler.notify(errorMsg);
        }
      });
  }

  formatResult(result: any) {
    let element;
    for (var i = 0; i < result.length; i++) {
      element = result[i];

      switch (element.outcome) {
        case '1': {
          element.outcome = this.sentOK;
          break;
        }
        case '2': {
          element.outcome = this.sentKO;
          break;
        }
        case '3': {
          element.outcome = this.notSent;
          break;
        }
        default: {
          //statements;
          break;
        }
      }
    }
    return result;
  }

  // CLEAR FORM
  clear() {
    this.homeForm.reset();
  }

  // DATE PICKER
  getMinDate() {
    return this.homeForm.value.dateFrom
  }

  getMaxDate() {
    return this.homeForm.value.dateTo
  }

  onDateTimeChange($event: RgiRxDateTimeChange<any>) {

  }

  onDateTimeInput($event: RgiRxDateTimeChange<any>) {

  }

  dateFilter: RgiRxDateTimeFilterFn<Date> = (date: Date) => {
    return true
  }

  translation() {
    const keys = of(['_ADAPTERPRINT_._MSG_._NOT_SENT_',
      '_ADAPTERPRINT_._MSG_._SENT_OK_',
      '_ADAPTERPRINT_._MSG_._SENT_KO_']);
    keys.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.notSent = next[0];
      this.sentOK = next[1];
      this.sentKO = next[2];
    }).unsubscribe();
  }
}

<ng-container>
  <rgi-rx-expansion-panel attr.data-qa="{{type}}">
    <rgi-rx-expansion-panel-header>
      <input [(ngModel)]="isChecked" [disabled]="!isCheckBoxEditable()"
             (change)="onCheckboxChange()" class="rgi-ui-form-control"
             type="checkbox" id="{{type}}" attr.data-qa="CHECKBOX_{{type}}">
      <label rgiRxLabel for="{{type}}" translate>RE_ISSUE.{{type}}</label>
    </rgi-rx-expansion-panel-header>
    <span class="rgi-ui-text-4 rgi-ui-default" translate>RE_ISSUE.{{subTitle}}</span>
    <table *ngIf="tableData?.length > 0" rgi-rx-table [dataSource]="tableData">

      <ng-container rgiRxColumnDef="policynumber">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate> RE_ISSUE.POLICY_NUMBER</th>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.policyNumber}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="effectiveDate">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.EFFECTIVE_DATE</th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          {{row.effectDate | date:'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="expireDate">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.EXPIRE_DATE</th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          {{row.expireDate | date:'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="status">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.POLICY_STATE</th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <span translate> {{row.active ? 'RE_ISSUE.VALID_POLICY':'RE_ISSUE.INVALID_POLICY'}}</span>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="actions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.ACTION</th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <button (click)="delete(row)" [disabled]="!isChecked" rgi-rx-button color="info" class="action-button" data-qa="delete">
            <span class="rgi-re-header-icon rgifont rgi-ui-icon-delete nfm_font_size"></span>
          </button>
        </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div class="input rgi-ui-grid-2">
      <div>
        <label for="policyNumber" class="rgi-ui-form-control-label">
        <span id="policyNumber" translate>RE_ISSUE.POLICY_NUMBER</span>
        </label>
        <rgi-rx-form-field>
          <input  [(ngModel)]="policyInput" [disabled]="!isChecked" rgiRxInput type="text" inputmode="numeric" attr.data-qa="INPUT_{{type}}">
        </rgi-rx-form-field>
      </div>
      <button rgi-rx-button (click)="emitSearchPolicy()" [disabled]="!isChecked || policyInput == null || policyInput == '' || disableSearchButton"  color="info" class="rgi-ui-btn text-uppercase" attr.data-qa="SEARCH_{{type}}" translate> RE_ISSUE.SEARCH</button>

    </div>

    <rgi-rx-snackbar tag="{{ERROR_TAG}}"></rgi-rx-snackbar>

  </rgi-rx-expansion-panel>

</ng-container>
<br>

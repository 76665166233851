import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {RgiRxTableComponent} from '@rgi/rx/ui';
import {PolicyGenericDto} from '../../../../models/pc-portfolio-models/policy-generic-dto';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ReIssueProductsConfigEnum} from '../../../../enums/re-issue-products-config-enum';
import {State} from '@rgi/rx/state';

@Component({
  selector: 're-issue-generic-accordion',
  templateUrl: './re-issue-generic-accordion.component.html',
  styleUrls: ['./re-issue-generic-accordion.component.css']
})
export class ReIssueGenericAccordionComponent implements OnInit, OnChanges {

  @Input() type: string;
  @Input() subTitle: string;
  @Input() tableData: PolicyGenericDto[];
  @Input() isChecked: boolean;
  @Input() disableSearchButton: boolean;
  @Input() set checkboxConfig(value: number) {
    this.checkboxConfig$ = value;
    if (this.isChecked == null) {
      switch (value) {
        case ReIssueProductsConfigEnum.UNCHEKED_DEFAULT_EDITABLE:
          this.isChecked = false;
          break;
        case ReIssueProductsConfigEnum.CHECKED_NOT_EDITABLE:
        case ReIssueProductsConfigEnum.CHEKED_DEFAULT_EDITABLE:
          this.isChecked = true;
          break;
      }
      this.emitSearchPolicy();
    }
  }
  @Input() stateObs: Observable<State>;
  @Output() checkboxEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchPolicy: EventEmitter<PolicyGenericDto> = new EventEmitter<PolicyGenericDto>();
  @Output() deletePolicy: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(RgiRxTableComponent) table: RgiRxTableComponent<any>;

  displayedColumns: string[] = ['policynumber', 'effectiveDate', 'expireDate', 'status', 'actions'];
  checkboxConfig$: number;
  policyInput: any;
  ERROR_TAG;
  state: State;


  numberRegex: RegExp = /^\d+$/;

  constructor(protected errorService: PushMessageHandlerService,
              private translateService: RgiRxTranslationService) {}

  ngOnInit() {
    this.stateObs.subscribe((state: State) => {
      this.state = state;
    });
    this.ERROR_TAG = 'POLICY_ERROR_' + this.type;
  }
  onCheckboxChange() {
    this.checkboxEmitter.emit(this.isChecked);
    if (!this.isChecked) {
      this.policyInput = null;
    }
    this.resetErrorMessage();
  }
  emitSearchPolicy() {
    this.resetErrorMessage();
    if(this.policyInput){
      this.policyInput = this.policyInput.replaceAll(' ','');
      if (this.numberRegex.test(this.policyInput)) {
        if(!this.checkForDuplicatePolicy(this.policyInput)){
          const policyGenericDto = new PolicyGenericDto();
          policyGenericDto.policyNumber = this.policyInput;
          this.searchPolicy.emit(policyGenericDto);
        } else {
          this.notifyErrorMessage(this.ERROR_TAG,this.getTranslation('RE_ISSUE.POLICY_ALREADY_ADDED') + ' in ' + this.checkForDuplicatePolicy(this.policyInput));
        }
      } else {
        this.notifyErrorMessage(this.ERROR_TAG,this.getTranslation('RE_ISSUE.INVALID_POLICY_NUMBER'));
      }
    }
  }
  delete(row: any) {
    this.resetErrorMessage();
    this.deletePolicy.emit(row.policyNumber);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData && this.table) {
      this.tableData = changes.tableData.currentValue;
      this.table.renderRows();
    }
  }
  notifyErrorMessage(tag: string, content: string) {
    const errorMsg = new RgiRxPushMessage();
    errorMsg.tag = tag;
    errorMsg.status = 'info';
    errorMsg.dismissible = true;
    errorMsg.options = {icon: 'rgi-ui-icon-alert'};
    errorMsg.content = content;
    this.errorService.notify(errorMsg);
  }
  checkForDuplicatePolicy(policyNumber: string) {
    const state: any = this.state;
    if (state){
      const commonRiskPolicies = state.policyTechnicalData.additionalTechnicalData.commonRiskPolicies.find(policy => policy.policyNumber === policyNumber);
      const linkedPolicies = state.policyTechnicalData.additionalTechnicalData.linkedPolicies.find(policy => policy.policyNumber === policyNumber);
      const complementaryPolicies = state.policyTechnicalData.additionalTechnicalData.complementaryPolicies.find(policy => policy.policyNumber === policyNumber);

      if(commonRiskPolicies)
        return this.getTranslation('RE_ISSUE.COMMON_RISK_POLICIES');
      else if(linkedPolicies)
        return this.getTranslation('RE_ISSUE.LINKED_POLICIES');
      else if(complementaryPolicies)
        return this.getTranslation('RE_ISSUE.COMPLEMENTARY_POLICIES');
      else
        return false;
    } else
      return false

  }
  getTranslation(value: string) {
    const msg = of([value]);
    let output: string;
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      output = next [0];
    }).unsubscribe();
    return output;
  }
  checkForErrorMessage() {
    const state: any = this.state;
    if (state){
      const errorMessage = state.accordionErrorMessage;
      if (errorMessage) {
        this.notifyErrorMessage(this.ERROR_TAG, errorMessage);
      }
    }
  }
  resetErrorMessage() {
    const state: any = this.state;
    if (state){
      state.accordionErrorMessage = null;
      this.errorService.clearTag(this.ERROR_TAG);
    }
  }
  isCheckBoxEditable() {
    return this.checkboxConfig$ === ReIssueProductsConfigEnum.CHEKED_DEFAULT_EDITABLE || this.checkboxConfig$ === ReIssueProductsConfigEnum.UNCHEKED_DEFAULT_EDITABLE;
  }


}

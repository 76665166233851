import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RgiRxDataPaginator, RgiRxDataTableDataSource, RgiRxDatatableRowAction} from "@rgi/rx/ui";
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

import {
    AUTHORIZATIONS_LIST_TABLE_SCHEMA,
    AUTHORIZATIONS_LIST_TABLE_SCHEMA_SUB
} from "../resources/models/authorization-list-tableschema";
import {AuthorizationsSearchService} from "../services/authorizations-search.service";
import {RequestStatus} from "../resources/constants/request-status";
import {AuthUtilityService}  from "../services/auth-utility.service";

@Component({
  selector: 'ac-authorizations-list-table',
  templateUrl: './authorizations-list-table.component.html',
    host: {
        class: 'rgi-authorizations-style'
    }
})
export class AuthorizationsListTableComponent implements OnInit {

    @Input() authorizations: any[];
    @Input() issubcard: any;
    @Output() action = new EventEmitter();
    rgiRxDatatableDatasource = new RgiRxDataTableDataSource([], new RgiRxDataPaginator());

    sortProp = 'requestCode';
    reverse = true;
    paginationForm: UntypedFormGroup;
    recordNumber = 10;
    start = 1;
    end = 10;
    page = 1;



    constructor(protected translate: RgiRxTranslationService,
                protected formBuilder: UntypedFormBuilder,
                protected authorizationsSearchService: AuthorizationsSearchService,
                protected authUtilityService: AuthUtilityService) {
    }

    protected get AUTHORIZATIONS_LIST_TABLE_SCHEMA() {   
        return this.authUtilityService.createSchema(AUTHORIZATIONS_LIST_TABLE_SCHEMA);
    };
    protected get AUTHORIZATIONS_LIST_TABLE_SCHEMA_SUB() {   
        return this.authUtilityService.createSchema(AUTHORIZATIONS_LIST_TABLE_SCHEMA_SUB);
    };

    protected onActionClick($event: RgiRxDatatableRowAction<any>) {
        switch ($event.name) {
            case 'OPEN':
                this.action.emit($event.row);
                break;
        }
    }

    ngOnInit(): void {
        if (this.issubcard) {
            this.authorizationsSearchService.setSubCard(true);
        }
        this.rgiRxDatatableDatasource.update(this.authorizations.map(auth => {
            return this.authorizationsSearchService.getAuthForList(auth, this.issubcard);            
        }));

        this.authorizations.forEach(element => {
            if (element.requestType.code) {
                element.requestTypeDescr = element.requestType.description;
                element.requestType = element.requestType.code;
            }
            if (element.lastEvent.description) {
                element.lastEvent = element.lastEvent.description;
            }
            if (element.state && element.state.id === RequestStatus.CANCELED) {
                element.lastEvent = 'Richiesta Annullata';
            }
            if (element.requestNode.code) {
                element.requestNodeDescr = element.requestNode.description;
                element.requestNode = element.requestNode.code;
            }
            if (element.policyNumber) {
                element.contractNumber = element.policyNumber;
            } else {
                element.contractNumber = element.proposalNumber;
            }
        });


        this.sortBy(this.sortProp, this.reverse);
        this.paginationForm = this.formBuilder.group(
            {
                recordsNum: 10,
            }
        );
        this.paginationForm.controls.recordsNum.valueChanges.subscribe((val: number) => {
            this.recordNumber = val;
            this.sortBy(this.sortProp, this.reverse);
            this.updateVisualizationDetail();
        });

        this.updateVisualizationDetail();
    }

    getDetailModel(ctx: any) {
        return this.authorizations.find(auth => auth.requestCode === ctx.authCode);
    }   

    sortBy(sortProp: string, reverse: boolean) {
        this.reverse = reverse;
        this.sortProp = sortProp;
        this.authorizations.sort((a, b) => {
            const aProp = a[sortProp];
            const bProp = b[sortProp];

            if (aProp.includes('/')) {
                const aPropDate = a[sortProp].split('/');
                const bPropDate = b[sortProp].split('/');
                const aDate = new Date(aPropDate[2], parseInt(aPropDate[1], 10) - 1, aPropDate[0]);
                const bDate = new Date(bPropDate[2], parseInt(bPropDate[1], 10) - 1, bPropDate[0]);
                return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
            }

            return (aProp < bProp) ? -1 : (aProp > bProp) ? 1 : 0;
        });

        if (this.reverse) {
            this.authorizations = this.authorizations.reverse();
        }
    }

    updateVisualizationDetail() {
        this.start = 1 + (Number(this.page) * Number(this.recordNumber)) - Number(this.recordNumber);
        const end = (Number(this.recordNumber) + Number(this.start)) - 1;
        this.end = end > this.authorizations.length ? this.authorizations.length : end;
    }

    
}

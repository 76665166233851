<div [formGroup]="formGroup">
  <div class="row" formArrayName="roles">
    <ng-container *ngFor="let definition of roleDefinitions; let j = index">

      <!-- DELEGATE -->
      <div class="col-lg-6 mb-4" *ngIf="definition.code === getRoleCode('delegate') && enableDelegateCheckbox">
        <div class="col-lg-12">
          <label class="label">
            <span translate>lpc_DelegateEqualToPolicyHolder</span>
          </label>
        </div>
        <div class="col-lg-12">
          <pnc-radio-group
            [name]="'delegateCheckbox'"
            [(options)]="checkboxOptions"
            [customFormGroup]="formGroup"
            [customFormControlName]="'delegateCheckbox'"
            (click)="onCheckboxChangeValue('delegateCheckbox', definition.code)">
          </pnc-radio-group>
        </div>
      </div>

      <!-- LEGAL GUARDIAN -->
      <div class="col-lg-6 mb-4" *ngIf="definition.code === getRoleCode('legalGuardian') && enableLegalGuardianCheckbox">
        <div class="col-lg-12">
          <label class="label">
            <span translate>lpc_LegalGuardianEqualToPolicyHolder</span>
          </label>
        </div>
        <div class="col-lg-12">
          <pnc-radio-group
            [name]="'legalGuardianCheckbox'"
            [(options)]="checkboxOptions"
            [customFormGroup]="formGroup"
            [customFormControlName]="'legalGuardianCheckbox'"
            (click)="onCheckboxChangeValue('legalGuardianCheckbox', definition.code)">
          </pnc-radio-group>
        </div>
      </div>

      <!-- THIRD PAYER -->
      <div class="col-lg-6 mb-4" *ngIf="definition.code === getRoleCode('thirdPayer') && enableThirdPayerCheckbox">
        <div class="col-lg-12">
          <label class="label">
            <span translate>lpc_ThirdPayerEqualToPolicyHolder</span>
          </label>
        </div>
        <div class="col-lg-12">
          <pnc-radio-group
            [name]="'thirdPayerCheckbox'"
            [(options)]="checkboxOptions"
            [customFormGroup]="formGroup"
            [customFormControlName]="'thirdPayerCheckbox'"
            (click)="onCheckboxChangeValue('thirdPayerCheckbox', definition.code)">
          </pnc-radio-group>
        </div>
      </div>

      <div *ngFor="let role of getFilteredRolesOf(definition.code); let i = index" [formGroupName]="i">
        <div [ngClass]="{'col-lg-6': (!!enableDelegateCheckbox || !!enableLegalGuardianCheckbox || !!enableThirdPayerCheckbox)}" *ngIf="showSubjectBox(role.role)">
          <div [ngClass]="{'col-lg-12': definition.code !== 'effectiveHolder', 'col-lg-6': definition.code === 'effectiveHolder'}">
            <label class="label">
              <span translate>{{definition.label}}</span>
              <lpc-required *ngIf="getMinCardinality(definition) === 1"  required="true"></lpc-required>
            </label>

            <div class="input-group">
              <input [ngClass]="{'with-percentage' : definition.showPercentage}"
                    readonly
                    [attr.data-qa-role]="role.role+'-role-has-value'"
                    type="text"
                    class="form-control"
                    formControlName="name">
              <input *ngIf="definition.showPercentage"
                    [ngClass]="{'with-percentage' : definition.showPercentage}"
                    type="number"
                    class="form-control"
                    (blur)="onBlurPercentage(role, formGroup.controls.roles.value[i].percentage)"
                    formControlName="percentage">
              <div class="input-group-btn">
                <!--  <button class="btn btn-outline-secondary" type="button" (click)="onEdit(role)"> -->
                <!--   <span class="rgifont rgi-pencil"></span> -->
                <!-- </button> -->
                <button [disabled]="!definition.editable || disableButtons" class="btn btn-default" type="button" (click)="onDelete(role, i)">
                  <span class="rgifont rgi-trash" [attr.data-qa-role]="role.role+'-role-trash-button'"></span>
                </button>
              </div>
            </div>

            <ng-container *ngFor="let subrole of definition.linkedRoles; index as subRoleIndex">
              <lpc-subroles-control
                [roleDefinition]="subrole"
                [formGroup]="getSubRoleGroup(j)"
                [roles]="getSubRolesValue(role)"
                (addSubj)="addSubSubject($event,role)"
                (deleteSubj)="deleteSubSubject($event,role)"
                [disableButtons]="disableButtons"
              ></lpc-subroles-control>
            </ng-container>

            <lpc-message-modal [showMessageModalOperation]="modalMessage"
                          (confirm)="deleteFromPopUp(role)"
                          (close)="closePopUp()"
                          [customMessage]="customMessage"
                          [title]="'lpc_confirm_operation'"
                          [nextLabel]="'CONFIRM'"
                          [backLabel]="'CANCEL'">
            </lpc-message-modal>
          </div>
        </div>
      </div>

      <div [ngClass]="{'col-lg-6': (!!enableDelegateCheckbox || !!enableLegalGuardianCheckbox || !!enableThirdPayerCheckbox)}" *ngIf="showSubjectBox(definition.code)">
        <div *ngIf="isSelectable(definition) && definition.editable && !disableButtons" [ngClass]="{'col-lg-12': definition.code !== 'effectiveHolder', 'col-lg-6': definition.code === 'effectiveHolder'}">
          <label class="label">
            <span translate>{{definition.label}}</span>
            <lpc-required *ngIf="getMinCardinality(definition) === 1" required="true"></lpc-required>
          </label>
          <input readonly
                [value]="selectLabel"
                (click)="onSelect(definition.code)"
                [attr.data-qa-role]="definition.code+'-role-no-value'"
                class="col-lg-12 drop-container dashedSelect">
        </div>
      </div>

    </ng-container>
  </div>
</div>

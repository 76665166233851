<div *ngIf="visible" class="ppevo-panel" [class]="class" [ngClass]="ngClass" [ngStyle]="ngStyle">
    <div class="ppevo-panel-header" *ngIf="showHeader">
        <ng-content select="[header]"></ng-content>
        <span *ngIf="closable" (click)="onClickClose()" class="ppevo-btn-close"></span>
    </div>
    <div class="ppevo-panel-body">
        <ng-content select="[body]"></ng-content>
    </div>
    
    <div *ngIf="showFooter" class="ppevo-panel-footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>

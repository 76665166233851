import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {CustomModalService} from '../../custom-modal.service';
import {InstallerModalComponent} from '../installer-modal/installer-modal.component';
import {GenericElement} from '../../models/domain-models/generic-element';
import {OctoInstallerData} from '../../models/api-models/api-octo-installers-response';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {ApiOctoInstallersFilter} from '../../models/api-models/api-octo-installers-filter';
import {ProposalService} from '../../proposal.service';
import {ApiVoucher} from '../../models/api-models/api-voucher';

@Component({
  selector: 'mic-installer',
  templateUrl: './installer.component.html',
  styleUrls: ['./installer.component.scss']
})
export class InstallerComponent implements OnInit, OnDestroy {

  static readonly NUMBER_OF_DIGIT_TO_ZIPCODE = 5;

  @ViewChild('modalOverlay', {read: ViewContainerRef, static: true})
  modalOverlayRef: ViewContainerRef;

  installer: OctoInstallerData;
  installerData: Array<any> = new Array<any>();
  installerDataRows: GenericElement[][];

  @Output()
  installerChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectableModal = true;
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  required = true;

  @Input()
  isInstallerSelectionButtonVisible = true;

  @Input()
  voucherSubstituted;
  @Input() events: Observable<ApiVoucher>;
  errors = new Array<string>();
  private eventsSubscription: Subscription;

  constructor(
    protected customModalService: CustomModalService,
    protected formBuilder: UntypedFormBuilder,
    protected proposalService: ProposalService
  ) {
  }

  ngOnInit(): void {
    if (this.parentForm) {
      this.parentForm.addControl(
        'zipCode',
        this.formBuilder.control('', this.required ? Validators.required : null)
      );
    } else {
      this.parentForm = this.formBuilder.group({
        zipCode: this.formBuilder.control('', this.required ? Validators.required : null)
      });
    }
    this.eventsSubscription = this.events.subscribe((voucher) => this.onLoadedInstaller(voucher));

    if (this.voucherSubstituted) {
      this.onLoadedInstaller(this.voucherSubstituted);
    }
  }

  openInstallerModal() {
    const regex = /^[0-9]+$/;
    if (regex.test(this.parentForm.get('zipCode').value) &&
      this.parentForm.get('zipCode').value.length !== InstallerComponent.NUMBER_OF_DIGIT_TO_ZIPCODE) {
      this.errors = new Array<string>();
      this.errors.push('Invalid zip code format');
      return;
    }

    this.errors = new Array<string>();

    const installerModal = this.customModalService.openModal(
      this.modalOverlayRef,
      InstallerModalComponent,
      null,
      () => {
        if (
          installerModal.instance.installerData &&
          installerModal.instance.installerData.installerCode
        ) {
          this.installer = installerModal.instance.installerData;
          this.installerChange.emit({
            installerCode: this.installer.installerCode,
            zipCode: this.installer.zipCode
          });
          this.parentForm.get('zipCode').setValue(this.installer.zipCode);
          this.loadInstallerData();
        }
      }
    );
    installerModal.instance.zipCode = this.parentForm.get('zipCode').value;
    installerModal.instance.selectableModal = this.selectableModal;
    installerModal.instance.isInstallerSelectionButtonVisible = this.isInstallerSelectionButtonVisible;
  }

  isZipCodeEmpty() {
    return (
      !this.parentForm.get('zipCode') ||
      this.parentForm.get('zipCode').value === null ||
      this.parentForm.get('zipCode').value === ''
    );
  }

  onLoadedInstaller(voucher: ApiVoucher) {
    const installersFilter = new ApiOctoInstallersFilter();
    installersFilter.filterInstallersOcto = {
      nation: 'IT',
      zipCode: voucher.installerZipCode
    };
    // this call is to be sure that the pre loaded installer actually exists, then it's loaded to be printed on screen
    this.proposalService.findInstallers(installersFilter).subscribe(result => {
      const installers = result.installersOcto;
      if (installers) {
        installers.forEach(installer => {
          if (installer.installerCode === voucher.installerCode) {
            this.installer = installer;
            this.loadInstallerData();
          }
        });
      }
    });
  }

  loadInstallerData() {
    this.installerData = [];
    this.installerData.push(
      new GenericElement('Name', this.installer.businessName)
    );
    this.installerData.push(
      new GenericElement('Code', this.installer.installerCode)
    );
    this.installerData.push(
      new GenericElement('Address', this.installer.address)
    );
    this.installerData.push(
      new GenericElement('Primary Phone Number', this.installer.phoneNumber1)
    );
    this.installerData.push(
      new GenericElement('Secondary Phone Number', this.installer.phoneNumber2)
    );
    this.installerData.push(
      new GenericElement('Fax', this.installer.faxNumber)
    );

    const installerDataNum = this.installerData.length;

    if (installerDataNum === 0) {
      return;
    }

    this.installerDataRows = new Array<Array<GenericElement>>(
      Math.ceil(installerDataNum / 3)
    );

    let i: number;
    let j = 0;

    for (i = 0; i < installerDataNum; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!this.installerDataRows[j]) {
        this.installerDataRows[j] = [];
      }
      this.installerDataRows[j].push(this.installerData[i]);
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}

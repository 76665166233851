import {Component, ComponentRef, OnInit} from '@angular/core';
import {Modal} from '../../modal';
import {UnitService} from '../asset-unit/unit.service';
import {Section} from '../../models/domain-models/section';
import {Unit} from '../../models/domain-models/unit';
import {WarrantySummary} from './warranty-summary';
import {ProposalService} from '../../proposal.service';
import {Variable} from '../../models/domain-models/variable';

@Component({
  selector: 'mic-warranties-summary',
  templateUrl: './warranties-summary-modal.component.html',
  styleUrls: ['./warranties-summary-modal.component.scss']
})
export class WarrantiesSummaryModalComponent implements Modal, OnInit {

  componentRef: ComponentRef<any>;
  title: string;
  warranties: WarrantySummary[] = new Array<WarrantySummary>();
  open = false;

  constructor(
    protected unitService: UnitService,
    protected proposalService: ProposalService
  ) {
  }

  ngOnInit() {
    const vehicle = this.proposalService.getAsset();
    const staticData = vehicle.vehicleStaticData;

    const brand = staticData.brand && staticData.brand.description ? staticData.brand.description : '';
    const setup = staticData.setup && staticData.setup.description ? staticData.setup.description : '';
    const plateNumber = vehicle.plate && vehicle.plate.plateNumber ? vehicle.plate.plateNumber : '';
    this.title = brand + ' ' + setup + ' (' + plateNumber + ')';
    this.unitService.getUnitsAsset().forEach(
      (section: Section) => {
        section.unitList.forEach(
          (unit: Unit) => {
            if (unit.selected) {
              this.warranties.push(
                new WarrantySummary(
                  unit.extendedDescription ? unit.extendedDescription : unit.description,
                  section.description,
                  this.getVariablesRows(unit.variables)
                )
              );
            }
          }
        );
      }
    );
  }

  getVariablesRows(variables: Variable[]): Variable[][] {
    if (!variables) {
      variables = [];
    }
    const variablesNum = variables.length;

    if (variablesNum === 0) {
      return new Array<Array<Variable>>(0);
    }

    const variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 2));

    let i: number;
    let j = 0;

    for (i = 0; i < variablesNum; i++) {
      if (i !== 0 && i % 2 === 0) {
        j++;
      }
      if (!variablesRows[j]) {
        variablesRows[j] = [];
      }
      variablesRows[j].push(variables[i]);
    }
    return variablesRows;
  }

  toggleAccordion() {
    this.open = !this.open;
  }

  close() {
    this.componentRef.destroy();
  }

}

import {PortalCard, PortalCards} from '@rgi/rx/portal';


export const ICD_CARD: PortalCards = [

    {

        card: {

            name: 'comissionDashboard',

            type: 'main',

            category: 'Cross',

            title: 'COMMISSIONS_CHECK',

            navigationInSession: true,

            subCards: [],


        } as PortalCard,
        routes: [
            {
                type: 'home',
                route: 'home',
                label: 'COMMISSIONS_CHECK',
                destination: 'dashboard-search'
            },
            {
                route: 'summary',
                label: 'COMMISSIONS_CHECK',
                destination: 'dashboard-summary'
            },
            {
                route: 'detail',
                label: 'COMMISSIONS_CHECK',
                destination: 'dashboard-detail'
            }
        ]

    }
];



<survey-eval-info #infoModal></survey-eval-info>

<!-- Products grouped by Line Of Business -->
<ng-container *ngIf="st.groupProductsByLob">
    <ng-container *ngFor="let lob of st.productsCfg.lobsCfg.lobs">
        <div class="ppevo-survey-eval-grouping" (click)="showHideContent(lob.code)">
            <span class="ppevo-lob-icon-{{lob.code}}"></span>
            <span class="ppevo-survey-eval-group-name">{{lob.description}}</span>
            <span [ngClass]="st.productsCfg.lobsCfg.visibilityMap[lob.code]?'ppevo-tree-close':'ppevo-tree-open'"></span>
        </div>
        <ng-container *ngIf="st.productsCfg.lobsCfg.visibilityMap[lob.code]">
            <ng-container *ngFor="let prodCode of st.productsCfg.lobsCfg.visibleProductsByLob[lob.code]">
                <ng-container 
                    *ngIf="st.productsCfg.prodsCfgMap[prodCode].visible"
                    [ngTemplateOutlet]="productTpl" 
                    [ngTemplateOutletContext]="{product: getProductByCode(prodCode)}"
                ></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<!-- Products default list without grouping -->
<ng-container *ngIf="!st.groupProductsByLob">
    <ng-container *ngFor="let product of st.evaluatorProducts">
        <ng-container 
            *ngIf="st.productsCfg.prodsCfgMap[product.code].visible"
            [ngTemplateOutlet]="productTpl" 
            [ngTemplateOutletContext]="{product: product}"
        ></ng-container>
    </ng-container>
</ng-container>

<ng-template #productTpl let-product="product">
    <survey-eval-product
        [attr.data-qa]="product.name"
        [product]="product"
        [editable]="st.selectEnabled"
        [validation]="st.validation && st.validation.products[product.code]"
        (clickInfoCoverage)="onClickInfoCoverage($event, product.code)"
        (clickInfoProduct)="onClickInfoProduct($event)"
        (select)="onSelectProduct($event)"
        [productCfg]="st.productsCfg.prodsCfgMap[product.code]"
    ></survey-eval-product>
</ng-template>

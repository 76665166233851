<div>
    <form [formGroup]="searchClaimsElasticForm" data-qa="claims-elastic-search-form">

        <div class="col-sm-12 container-claims-elastic-search">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="claims-elastic-search-claim-number-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="claimNumber" id="claimNumber" type="text" name="claimNumber" attr.data-qa="claims-elastic-search-claimnumber-input">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="claims-elastic-search-partyname-label">{{ '_CLAIMS_._PARTY' | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="partyName" id="partyName" type="text" name="partyName" attr.data-qa="claims-elastic-search-partyname-input">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="claims-elastic-search-magic-word-label">{{ '_CLAIMS_._MAGIC_STRING' | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="magicWord" id="magicWord" type="text" name="magicWord" attr.data-qa="claims-elastic-search-magic-word-input">

        </div>

        <span *ngIf="validationMessages && validationMessages.length > 0" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-update-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span *ngFor="let message of validationMessages; let even = even; let odd = odd; let invoiceDetailsIndex = index">
                {{message | translate}}
                <br>
            </span>
        </span>
        
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button type="button" class="btn btn-warning pull-right" (click)="emptyFields()"
                    attr.data-qa="claims-elastic-search-empty-btn">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
            </div>
            <div class="btn-group">
                <button type="submit" class="btn btn-warning pull-right" attr.data-qa="claims-elastic-search-find-btn"
                    id="invoice-search-submit" (click)="goToSearchResults()">{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
            </div>
        </div>
        
    </form>

</div>

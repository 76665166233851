import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {QuestionnaireElement} from '../questionnaire-element';
import {Subscription} from 'rxjs';
import {QuestionnaireService} from '../questionnaire.service';

@Component({
  selector: 'mic-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, OnDestroy {

  @Input() questionnaireForm: UntypedFormGroup;
  questionnaireElement: QuestionnaireElement;
  formSubmitted = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected questionnaireService: QuestionnaireService
  ) {
  }

  @Input()
  set questionnaireElementInput(element) {
    this.questionnaireElement = element;
  }

  ngOnInit() {
    const formControl = this.questionnaireForm.get(this.questionnaireElement.id);

    if (this.questionnaireElement.disabled) {
      formControl.disable();
    }

    if (this.questionnaireElement.questionType === 4) {
      const questionnaireElementValueChangesSubscription = formControl.valueChanges.subscribe(
        (data) => {
          if (this.questionnaireForm.get(this.questionnaireElement.id).errors
            && !this.questionnaireForm.get(this.questionnaireElement.id).errors.required) {
            this.questionnaireForm.get(this.questionnaireElement.id).setErrors(null);
          }
          try {
            const dateValue: Date = this.questionnaireForm.get(this.questionnaireElement.id).value;
            const year = dateValue.getFullYear();
            if (year.toString().length === 4) {
              this.onAnswerChange(dateValue.getTime());
            }
          } catch (e) {
          }
        }
      );
      this.subscriptions.add(questionnaireElementValueChangesSubscription);
    }

    const newAnswerSignalSubscription = this.questionnaireService.getNewAnswerSignal().subscribe(
      (questionnaireElement: QuestionnaireElement) => {
        if (this.questionnaireElement.condition) {
          if (questionnaireElement.answerCode === this.questionnaireElement.condition.answerCode) {
            if (String(questionnaireElement.selectedAnswer) ===
              String(this.questionnaireElement.condition.value)) {
              this.questionnaireElement.visible = true;
            } else {
              this.questionnaireElement.visible = false;
            }
          }
        }
      }
    );
    this.subscriptions.add(newAnswerSignalSubscription);

    const confirmQuestionnaireSignalSubscription = this.questionnaireService.getConfirmQuestionnaireSignal().subscribe(
      (event) => {
        this.formSubmitted = true;
      }
    );
    this.subscriptions.add(confirmQuestionnaireSignalSubscription);
  }

  onAnswerChange(value?: any) {
    if (this.questionnaireElement.questionType === 3
      || this.questionnaireElement.questionType === 4) {
      this.questionnaireElement.selectedAnswer = value;
    } else if (this.questionnaireElement.questionType === 0
      || this.questionnaireElement.questionType === 1
      || this.questionnaireElement.questionType === 2) {
      this.questionnaireElement.selectedAnswer = Number(this.questionnaireForm.get(this.questionnaireElement.id).value);
    } else {
      this.questionnaireElement.selectedAnswer = this.questionnaireForm.get(this.questionnaireElement.id).value;
    }
    this.questionnaireService.getCurrentQuestionnaire().questionList.forEach(
      (question) => {
        if (this.questionnaireElement.id === question.code && question.answerList[0]) {
          question.answerList[0].selectedValue = this.questionnaireElement.selectedAnswer;
        }
      }
    );
    this.questionnaireService.sendNewAnswerSignal(this.questionnaireElement);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}

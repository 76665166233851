import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { RoutableComponent, RoutingService } from '@rgi/portal-ng-core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { UntypedFormGroup } from '@angular/forms';
import { NewLetterTemplatesComponent } from '../new-letter-templates/new-letter-templates.component';
import { LetterTemplateEntity } from '../../dto/letter-template.entity';
import { RgiRxDatatableHeaderAction, TableSchema } from '@rgi/rx/ui';
import { UserAssociatedLetterEntity } from '../../dto/user-associated-letter-entity.entity';
import { DerogationLevelEntity } from '../../dto/derogation-level.entity';
import { LetterTemplatesService } from '../../letter-templates.service';
import { ManagePhaseEntity } from '../../dto/manage-phase.entity';
import { Subject, combineLatest } from 'rxjs';
import { AlertService, Outcome } from '@rgi/digital-claims-common-angular';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-letter-templates-users-list',
  templateUrl: './letter-templates-users-list.component.html',
  styleUrls: ['./letter-templates-users-list.component.scss']
})
export class LetterTemplatesUsersListComponent extends CardComponent implements OnInit, RoutableComponent {
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  routes: { newLetterTemplates: any; };
  changingValueUserCheck: Subject<boolean> = new Subject();

  // eventiale lettera da modificare
  letterTemplateEntity: LetterTemplateEntity;

  newLetterTemplateForm: UntypedFormGroup;
  errorMessage: string;

  // oggetti input
  livelliDeroga: DerogationLevelEntity[];
  fasiGestione: ManagePhaseEntity[];
  userList: UserAssociatedLetterEntity[];

  schema: TableSchema;

  constructor(
    private letterTemplatesService: LetterTemplatesService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('sessionService') private sessionServiceInject: any,
    private routingService: RoutingService,
    @Inject('newLetterTemplates') newLetterTemplates,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') private emitter: any,
    private alertService: AlertService,
    public translateService: RgiRxTranslationService
  ) {
    super(cardComponentService, sessionService);

    this.routes = {
      newLetterTemplates,
    };


    const obsUser$ = this.translateService.translate('_CLAIMS_._USER');
    const obsRealName$ = this.translateService.translate('_CLAIMS_._REAL_NAME');
    const obsUserDerogationLevel$ = this.translateService.translate('_CLAIMS_._USER_DEROGATION_LEVEL');
    const obsLetterDerogationLevel$ = this.translateService.translate('_CLAIMS_._LETTER_DEROGATION_LEVEL');
    const obsSelectAll$ = this.translateService.translate('_CLAIMS_._SELECT_ALL');
    const obsDeselectAll$ = this.translateService.translate('_CLAIMS_._DESELECT_ALL');
    combineLatest([
      obsUser$,
      obsRealName$,
      obsUserDerogationLevel$,
      obsLetterDerogationLevel$,
      obsSelectAll$,
      obsDeselectAll$
    ]).subscribe(
      ([user, realName, userDerogationLevel, letterDerogationLevel, selectAll, deselectAll]) => {

        this.schema = {
          rows: [
            {
              name: 'userCheckerComponent',
              title: ''
            },
            {
              name: 'nomeUtente',
              title: user,
            },
            {
              name: 'realName',
              title: realName,
            },
            {
              name: 'livelloDerogaUtente',
              title: userDerogationLevel,
            },
            {
              name: 'livelloDerogaLetteraComponent',
              title: letterDerogationLevel
            },
          ], header: ['userCheckerComponent', 'nomeUtente', 'realName', 'livelloDerogaUtente', 'livelloDerogaLetteraComponent'],
          headerActions: [
            {
              name: 'header.selectAll',
              description: selectAll,
              when: 'always',
              // styleClass: 'glyphicon glyphicon-check',
              label: selectAll
            },
            {
              name: 'header.deselectAll',
              description: deselectAll,
              when: 'always',
              // styleClass: 'glyphicon glyphicon-unchecked',
              label: deselectAll
            }
          ]
        };
      })
  }

  ngOnInit() {

    const obsNLM$ = this.translateService.translate('_CLAIMS_._NEW_LETTER_MODEL');
    const obsUserAssociation$ = this.translateService.translate('_CLAIMS_._USER_ASSOCIATION');
    combineLatest([
      obsNLM$,
      obsUserAssociation$,
    ]).subscribe(
      ([nlm, userAssociation]) => {
        this.card.title = nlm + ' - ' + userAssociation;
      })

    this.livelliDeroga = this.letterTemplatesService.getLivelliDeroga();
    this.fasiGestione = this.letterTemplatesService.getFasiGestione();

    if (this.letterTemplateEntity) {
      this.userList = this.letterTemplateEntity.listUsers;
    } else {
      this.userList = this.letterTemplatesService.getUserList();
      this.selectAllUsers();
    }
    this.letterTemplatesService.setUserListForCurrentEdit(this.userList);
  }

  onHeaderAction($event: RgiRxDatatableHeaderAction<UserAssociatedLetterEntity>) {
    switch ($event.name) {
      case 'header.selectAll': {
        this.selectAllUsers();
        break;
      }
      case 'header.deselectAll': {
        this.changingValueUserCheck.next(false);
        this.userList.forEach(value => value.associatoLettera = false);
        break;
      }
    }
  }

  private selectAllUsers() {
    this.changingValueUserCheck.next(true);
    this.userList.forEach(value => value.associatoLettera = true);
  }

  getTitle(): string {
    return this.newLetterTemplateForm.get('titolo').value;
  }

  getLivelloDeroga(): string {
    return this.newLetterTemplateForm.get('livelloDeroga').value;
  }

  getLivelloDerogaDesc(): string {
    return this.livelliDeroga.find(value => value.value === this.newLetterTemplateForm.get('livelloDeroga').value).desc;
  }

  getFaseGestione(): string {
    return this.fasiGestione.find(value => value.id === this.newLetterTemplateForm.get('idFaseGestione').value).desc;
  }

  back() {
    this.routingService.loadComponent(this.routes.newLetterTemplates);
    (this.routingService.componentRef.instance as NewLetterTemplatesComponent).card = this.card;
    (this.routingService.componentRef.instance as NewLetterTemplatesComponent).newLetterTemplateForm = this.newLetterTemplateForm;
    (this.routingService.componentRef.instance as NewLetterTemplatesComponent).letterTemplateEntity = this.letterTemplateEntity;
    this.letterTemplatesService.clenUserListForCurrentEdit();
  }

  getCurrentSession() {
    return this.sessionServiceInject.list().find(el => el.isActive);
  }

  save() {
    this.errorMessage = null;

    const selectedUser: UserAssociatedLetterEntity[] = this.userList.filter(value => value.associatoLettera);

    if (selectedUser.length > 0) {
      const letterModel: LetterTemplateEntity = this.newLetterTemplateForm.value;
      letterModel.deleted = false;
      letterModel.listUsers = selectedUser;

      this.gridInstance.cards.refresh();
      this.emitter.broadcastEvent('start-loader');

      this.letterTemplatesService.saveLetterTemplate(letterModel).subscribe((response: Outcome) => {
        if (response.code === Outcome.OK) {
          const session = this.getCurrentSession();
          this.letterTemplatesService.clenUserListForCurrentEdit();
          this.sessionServiceInject.remove(session.idSession);
          this.translateService.translate('_CLAIMS_._LETTER_TEMPLATE_SAVED')
            .subscribe(res => this.alertService.translatedMessage(res))
        } else {
          if (response.descr === 'Title already exists') {
            this.translateService.translate('_CLAIMS_._MESSAGE_._TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST')
              .subscribe(res => this.alertService.translatedMessage(res))
          } else {
            this.translateService.translate('_CLAIMS_._MESSAGE_._IN_ERROR')
            .subscribe(res => this.alertService.translatedMessage(res))
          }
        }
        this.emitter.broadcastEvent('stop-loader');
      });
    } else {
      this.translateService.translate('_CLAIMS_._SELECT_AT_LEAST_ONE_USER')
      .subscribe(res => this.errorMessage = res)
    }
  }
}

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
                      <span translate>Message
                    </span>
          <span (click)="onClose()" class="glyphicon glyphicon-remove pull-right cursorPointer"></span>
        </h3>
      </div>

      <div class="modal-body">
        <p *ngFor="let msg of messages">{{msg}}</p>
      </div>

      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="onClose()" class="btn btn-warning" data-qa="btn-substitution-modal-close" translate>CLOSE</button>
          </div>
          <div class="btn-group">
            <button (click)="onConfirm()" class="btn btn-warning" data-qa="btn-substitution-modal-confirm" translate>CONFIRM</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {ChangeDetectorRef, Component, Inject, Optional} from '@angular/core';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifierService} from '@rgi/portal-ng-core';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { ToolManagerComponent } from '../tool-manager/tool-manager.component';
import { AuthService } from '../../services/auth.service';
import { AnagService } from '../../services/anag.service';
import { PV_TOKEN } from '../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-activate-tool',
  templateUrl: './activate-tool.component.html',
  styleUrls: ['./activate-tool.component.css']
})
export class ActivateToolComponent extends ToolManagerComponent {

  public toolMap: any = {};

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authorizationService: AuthService,
    protected anag: AnagService,
    @Optional() @Inject(PV_TOKEN.TOOLS_HIDE_ENUMS) protected hideEnumWithOneValue: boolean
    ) {
      super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
        plcQuestService, authorizationService, anag, hideEnumWithOneValue);
  }
}

import {Injectable, NgModule, NgZone, Provider} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/**
 * @description This class wraps the underlying framework execution context and provides a way to provide a way to abstract the framework execution context.
 * This can allow to opt for Zone.js like optimizations optionally or define a custom execution context.
 */
export class RgiRxSdkFrameworkExecution {
  executeOutsideFramework<T>(fn: (...args: any[]) => T): T {
    return fn.apply(this);
   }
   runTaskInFramework<T>(fn: (...args: any[]) => T, applyThis?: any, applyArgs?: any[], name?: string): T {
     return fn.apply(applyThis, applyArgs);
   }
}


@Injectable()
/**
 * @description An RgiRxSdkFrameworkExecution implementation that uses Angular NgZone to execute tasks.
 */
export class RgiSdkFrameworkExecutionNgZone extends RgiRxSdkFrameworkExecution {
  constructor(private ngZone: NgZone) {
    super();
  }

  executeOutsideFramework<T>(fn: (...args: any[]) => T): T {
    return this.ngZone.runOutsideAngular(fn);
  }

  runTaskInFramework<T>(fn: (...args: any[]) => T, applyThis?: any, applyArgs?: any[], name?: string): T {
    return this.ngZone.run(fn);
  }
}


@NgModule({
  providers: [
    provideRgiRxSdkFrameworkExecutionNgZone()
  ]
})
/**
 * @description A module that provides the RgiSdkFrameworkExecutionNgZone implementation of RgiRxSdkFrameworkExecution.
 */
export class RgiRxSdkNgZoneModule {}

/**
 * @description A provider for the RgiSdkFrameworkExecutionNgZone implementation of RgiRxSdkFrameworkExecution.
 */
export function provideRgiRxSdkFrameworkExecutionNgZone(): Provider {
  return {
    provide: RgiRxSdkFrameworkExecution,
    useClass: RgiSdkFrameworkExecutionNgZone
  };
}

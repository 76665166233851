import { Injectable } from '@angular/core';
import { RgiRxHttpError } from '@rgi/rx/http';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { createMapRoleParties, mapObjForVcont } from '../../adapters/group-policy-utils';
import { ROUTES_GP_VCONT_DATES, ROUTES_GP_VCONT_VARIATION } from '../../group-policy-constants/routes-constants';
import { ErrorCode, MeanOfPayment, PaymentConfig, PaymentsPayload, SubjectContract, paymentPages } from '../../group-policy-models/group-policy-issue-policy-data';
import { Documents } from '../../group-policy-models/group-policy-issue-summary';
import { GroupPolicyVcontRouteData, NextRouteResponse } from '../../group-policy-models/group-policy-vcont-date';
import { SubcauseModel } from '../../group-policy-models/group-policy-vcont-variation';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyPaymentService } from '../../group-policy-services/group-policy-payment.service';
import { GroupPolicyStateVcontVariation } from '../group-policy-state';

// tslint:disable:max-line-length

/**
 * @author: dmasone
 * @description: Service used for render vcont variation step
 */
@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpVariationService {


  constructor(
    protected groupPolicyService: GroupPolicyResourceService,
    protected routingService: RoutingService,
    protected paymentService: GroupPolicyPaymentService,
    protected apiRestErrorService: GroupPolicyApiRestErrorService,
    protected integrationService: GroupPolicyIntegrationService) { }

  public initVariation$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontVariation> {
    const previousStepData = activeRoute.getRouteData<GroupPolicyVcontRouteData>();
    return groupPolicyStateVariation$.pipe(
      mergeMap((st: GroupPolicyStateVcontVariation) => {
        return combineLatest(of(st),
          this.getRoles$(of(st), previousStepData),
          this.getPaymentConfigs$(of(st), previousStepData),
          this.getAvailablePayments$(of(st), previousStepData),
          this.actionGetSubcausePostSales$(of(st), previousStepData.resId),
          this.actionNextRoute$(of(st), previousStepData.resId)
        );
      }),
      map(([st]) => {
        st.errors = previousStepData.errors;
        st.type = previousStepData.type;
        st.firstSubjectContract = (previousStepData.firstSubjectContract) ? previousStepData.firstSubjectContract : st.allRoleParties[1] && st.allRoleParties[1][0];
        st.steps = previousStepData.steps.concat([ROUTES_GP_VCONT_VARIATION]);
        st.labels = previousStepData.labels.concat(['_GP_._TITLE_._VARIATION_']);
        if ((previousStepData.firstSubjectContract && st.allRoleParties[1] && st.allRoleParties[1][0] && +previousStepData.firstSubjectContract.idParty !== +st.allRoleParties[1][0].idParty) || st.subcause) {
          st.showSubcauses = true;
        }

        return st;
      })
    );
  }

  public getRoles$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, data: GroupPolicyVcontRouteData): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$
      .pipe(
        concatMap((st: GroupPolicyStateVcontVariation) => {
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_GET', 'FOOTER']);
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_GET');
          return this.groupPolicyService.getPartyRolesPostSales$(data.resId).pipe(
            map(partyRoles => {
              st.allRoleParties = createMapRoleParties(partyRoles);
              return st;
            }),
            catchError(this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_GET'))
          );
        }),
        concatMap((st: GroupPolicyStateVcontVariation) => {
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_GET');
          return this.getAvailablePayments$(of(st), data).pipe(
            map((state: GroupPolicyStateVcontVariation) => state),
            catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_GET'))
          );
        }),
        concatMap((st: GroupPolicyStateVcontVariation) => {
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_GET');
          return this.actionGetSubcausesPostSales$(of(st), data.resId).pipe(
            map((state: GroupPolicyStateVcontVariation) => state),
            catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_GET'))
          );
        }),
        catchError(this.apiRestErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStateVcontVariation) => st)
      );
  }

  public updateParty$(
    groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>,
    data: GroupPolicyVcontRouteData,
    reqSubj: SubjectContract,
    showSubcauses = false) {
    return groupPolicyStateVariation$
      .pipe(
        concatMap((st: GroupPolicyStateVcontVariation) => {
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_UPD', 'FOOTER']);
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_UPD');
          return this.groupPolicyService.updatePartyRolePostSales$(data.resId, reqSubj).pipe(
            map((_resp) => {
              st.showSubcauses = showSubcauses;
              return st;
            }),
            catchError((showSubcauses) ? this.catchApiErrorFnSubcauses(st, 'ROLES_UPD') : this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_UPD'))
          );
        }),
        concatMap((st: GroupPolicyStateVcontVariation) => {
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES');
          return this.getRoles$(of(st), data);
        }),
        catchError(this.apiRestErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStateVcontVariation) => st)
      );
  }

  public getAvailablePayments$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, data: GroupPolicyVcontRouteData): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      mergeMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['PAYMENTS_GET', 'FOOTER']);
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_GET');
        return this.groupPolicyService.getAvailablePaymentsPostSales$(data.resId).pipe(
          map((selectablePayments: MeanOfPayment[]) => {
            st.selectablePayments = selectablePayments;
            return st;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_GET'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontVariation) => st)
    );
  }

  public getPaymentConfigs$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, data: GroupPolicyVcontRouteData): Observable<any> {
    return groupPolicyStateVariation$.pipe(
      mergeMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['PAYMENTS_CONF', 'FOOTER']);
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_CONF');
        return this.paymentService.getEditablePaymentConfigs$(data.resId, paymentPages.ammData, st, true).pipe(
          map((paymentConfig: PaymentConfig) => {
            st.editablePayments = paymentConfig;
            return st;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontVariation) => st)
    );
  }

  public deleteRole$(
    groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, data: GroupPolicyVcontRouteData,
    role: string, idParty: number, delSubcauses = false): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$
      .pipe(
        concatMap((st: GroupPolicyStateVcontVariation) => {
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_DEL', 'FOOTER']);
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_DEL');
          return this.groupPolicyService.deleteRolePostSales$(data.resId, role, idParty).pipe(
            map((_resp) => {
              if (delSubcauses) {
                st.showSubcauses = false;
              }
              return st;
            }),
            catchError((delSubcauses) ? this.catchApiErrorFnSubcauses(st, 'ROLES_DEL') : this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_DEL'))
          );
        }),
        concatMap((st: GroupPolicyStateVcontVariation) => {
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_GET', 'FOOTER']);
          st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_GET');
          return this.getRoles$(of(st), data).pipe(
            map((state: GroupPolicyStateVcontVariation) => state),
            catchError(this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_GET'))
          );
        }),
        concatMap((st: GroupPolicyStateVcontVariation) => {
          if (st.subcause && role === '1') {
            st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_SUBCAUSE', 'FOOTER']);
            st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_SUBCAUSE');
            return this.actionDeleteSubcausePostSales$(of(st), data.resId).pipe(
              map((state: GroupPolicyStateVcontVariation) => {
                state.subcause = null;
                return st;
              }),
              catchError(this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_SUBCAUSE'))
            );
          } else {
            return of(st);
          }
        }),
        catchError(this.apiRestErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStateVcontVariation) => st)
      );
  }

  public setPaymentMethod$(
    groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, paymentMethod: PaymentsPayload,
    data: GroupPolicyVcontRouteData): Observable<GroupPolicyStateVcontVariation> {
    const payload = [paymentMethod];

    return groupPolicyStateVariation$.pipe(
      concatMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['PAYMENTS_SET', 'FOOTER']);
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_SET');
        return this.groupPolicyService.setPaymentsPostSales$(data.resId, payload).pipe(
          map((_resp) => st),
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_SET'))
        );
      }),
      concatMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS');
        return this.getAvailablePayments$(of(st), data);
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontVariation) => st)
    );
  }

  public actionGetSubcausePostSales$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, resId: string): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      concatMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_SUBCAUSE', 'FOOTER']);
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_SUBCAUSE');
        return this.groupPolicyService.getSubcausePostSales$(resId).pipe(
          map((subcause: SubcauseModel) => {
            st.subcause = subcause;
            return st;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_SUBCAUSE'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontVariation) => st)
    );
  }

  public actionSetSubcausePostSales$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, resId: string, payload: SubcauseModel): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      concatMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_SUBCAUSE', 'FOOTER']);
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_SUBCAUSE');
        return this.groupPolicyService.setSubcausePostSales$(resId, payload).pipe(
          map((_resp) => {
            st.subcause = payload;
            return st;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_SUBCAUSE'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontVariation) => st)
    );
  }

  public actionGetSubcausesPostSales$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, resId: string): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      concatMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_SUBCAUSE', 'FOOTER']);
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_SUBCAUSE');
        return this.groupPolicyService.getSubcausesPostSales$(resId).pipe(
          map((subcauses: SubcauseModel[]) => {
            st.subcauses = subcauses;
            return st;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_SUBCAUSE'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontVariation) => st)
    );
  }

  public actionDeleteSubcausePostSales$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, resId: string): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      concatMap((st: GroupPolicyStateVcontVariation) => {
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, ['ROLES_SUBCAUSE', 'FOOTER']);
        st.errors = this.apiRestErrorService.cleanErrorsForCode(st.errors, 'ROLES_SUBCAUSE');
        return this.groupPolicyService.deleteSubcausePostSales$(resId).pipe(
          map((_resp) => {
            st.subcause = null;
            return st;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'ROLES_SUBCAUSE'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontVariation) => st)
    );
  }

  public actionNextRoute$(groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, resId: string): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      concatMap((state: GroupPolicyStateVcontVariation) => {
        state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, 'FOOTER');
        return this.groupPolicyService.nextRoutePostSales$(resId, ROUTES_GP_VCONT_VARIATION).pipe(
          map((resp: NextRouteResponse) => {
            state.nextRoute = resp;
            return state;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((state: GroupPolicyStateVcontVariation) => {
        return state;
      })
    );
  }

  public actionGoForword$(
    groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, resId: string,
    activeRoute: ActiveRoute, operationCode?: string): Observable<GroupPolicyStateVcontVariation> {
    const previousStepData = activeRoute.getRouteData<GroupPolicyVcontRouteData>();
    return groupPolicyStateVariation$.pipe(
      concatMap((state: GroupPolicyStateVcontVariation) => {
        state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, ['ROLES', 'FOOTER']);
        state.type = ErrorCode.BLOCKING;
        return this.groupPolicyService.checkChangesPostSales$(resId).pipe(
          map((_resp) => state),
          catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
        );
      }),
      concatMap((state: GroupPolicyStateVcontVariation) => {
        if (state.nextRoute && state.nextRoute.button.includes('_CONFIRM_')) {
          state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, 'FOOTER');
          return this.groupPolicyService.retrieveDocumentsPostSales$(resId, operationCode).pipe(
            map((documents: Documents[]) => {
              state.documents = documents;
              return state;
            }),
            catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
          );
        } else {
          return of(state);
        }
      }),
      concatMap((state: GroupPolicyStateVcontVariation) => {
        if (state.nextRoute && state.nextRoute.button.includes('_CONFIRM_')) {
          return this.groupPolicyService.saveOperationPostSales$(resId).pipe(
            map((_resp) => state),
            catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
          );
        } else {
          return of(state);
        }
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((state: GroupPolicyStateVcontVariation) => {
        if (!this.apiRestErrorService.containsBlockingErrors(state.errors)) {
          this.integrationService.navigate(this.routingService, state.nextRoute && state.nextRoute.nextRoute, mapObjForVcont(resId, state.steps, state.labels, previousStepData.proposalNumber, previousStepData.idParentSession, state.firstSubjectContract, state.documents), activeRoute);
        }
        return state;
      })
    );
  }

  public actionBack$(
    groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, previousStepData: GroupPolicyVcontRouteData,
    activeRoute: ActiveRoute, targetRoute = ROUTES_GP_VCONT_DATES): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      tap((state: GroupPolicyStateVcontVariation) => {
        previousStepData.errors = [];
        previousStepData.type = ErrorCode.BLOCKING;
        previousStepData.steps = previousStepData.steps.slice(0, previousStepData.steps.indexOf(targetRoute));
        previousStepData.labels = previousStepData.labels.slice(0, previousStepData.steps.indexOf(targetRoute));
        previousStepData.firstSubjectContract = state.firstSubjectContract;
        this.integrationService.navigate(this.routingService, targetRoute, previousStepData, activeRoute);
      })
    );
  }

  public actionClose$(
    groupPolicyStateVariation$: Observable<GroupPolicyStateVcontVariation>, previousStepData: GroupPolicyVcontRouteData,
    activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontVariation> {
    return groupPolicyStateVariation$.pipe(
      tap(() => {
        this.integrationService.backToInquiry(previousStepData.proposalNumber, activeRoute.id, previousStepData.idParentSession);
      })
    );
  }

  protected catchApiErrorFnSubcauses(st: GroupPolicyStateVcontVariation, areaCode?: string) {
    return (err: RgiRxHttpError) => {
      st.showSubcauses = false;
      if (err.status < 500) {
        st.errors = st.errors.concat(this.apiRestErrorService.manageErrors(err, areaCode));
        return throwError(st);
      }
      return throwError(err);
    };
  }

}

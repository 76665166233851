<div *ngIf="stateManager.getState$() | async as state">
    <form *ngIf="paramForm && overview?.functionality?.id ==='3'" [formGroup]="paramForm" class="dash-detail-param">
        <div class="row">
            <!-- SELECT OPERATION -->
            <div class="col-sm-3 col-xs-3" *ngIf="!operation.isMulti">
                <rgi-rx-form-field>
                    <label rgiRxLabel translate>ICD.SELECTED_OPERATION</label>
                    <select rgiRxNativeSelect formControlName="operationType"
                            data-qa="subStatusType">
                        <option value=""></option>
                        <option *ngFor="let operationType of state.operationList"
                                [ngValue]="operationType.id">{{operationType.description}}
                        </option>
                    </select>
                </rgi-rx-form-field>
            </div>
            <!-- ACTION -->
            <div class="col-sm-3 col-xs-3">
                <rgi-rx-form-field><label rgiRxLabel translate>ICD.ACTION</label>
                    <select rgiRxNativeSelect formControlName="actionType" data-qa="action">
                        <option value=""></option>
                        <option *ngFor="let action of state.actionList" [ngValue]="action.id">{{action.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>
            <!-- NOTES -->
            <div class="col-sm-3 col-xs-3">
                <rgi-rx-form-field>
                    <label rgiRxLabel translate>ICD.NOTES</label>
                    <select rgiRxNativeSelect formControlName="noteType" data-qa="note">
                        <option value=""></option>
                        <option *ngFor="let note of state.noteList" [ngValue]="note.id">{{note.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>
        </div>
        <br>
        <!-- BUTTONS -->
        <div class="row">
            <div class="btn-group">
                <!-- CANCEL BUTTON -->
                <button rgi-rx-button class="btn left-first-btn" (click)="cancel()" color="secondary">
                    <span translate>ICD.CANCEL</span>
                </button>
                <!-- APPLY BUTTON -->
                <button rgi-rx-button class="btn left" [disabled]="(!paramForm.controls.actionType.value && this.commissionStatus === '2') || (this.commissionStatus !== '2' && (!paramForm.controls.actionType.value && !paramForm.controls.noteType.value))"
                        (click)="modalParam.open()" color="primary">
                    <span translate>ICD.APPLY</span>
                </button>
            </div>
            <div class="btn-group">
                <rgi-rx-snackbar class="dashboard-detail-snackbar left" tag="operationError"></rgi-rx-snackbar>
            </div>
        </div>
    </form>


    <rgi-rx-modal #modalParam>
        <rgi-rx-panel class="custom_modal">
            <rgi-rx-panel-header [closeable]="true" (onClose)="modalParam.close()"><span translate>ICD.CONFIRM_OPERATION</span></rgi-rx-panel-header>
            <rgi-rx-panel-footer>
                <!-- CANCEL BUTTON -->
                <button  rgi-rx-button color="secondary" (click)="modalParam.close()">
                    <span translate>ICD.CANCEL</span>
                </button>
                <!-- APPLY BUTTON -->
                <button  rgi-rx-button color="primary"
                         (click)="applyValues(); modalParam.close()">
                    <span translate>ICD.CONFIRM</span>
                </button>
            </rgi-rx-panel-footer>
            <p>{{text}}</p>
        </rgi-rx-panel>
    </rgi-rx-modal>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ContributionResp } from '../models/pip.model';
import { EnviromentsService } from './enviroments.service';
import { PolicyService } from './policy-service';


@Injectable({
  providedIn: 'root'
})
export class PipService {
  private baseApiUrl: string;
  private baseApiPassURL: string;

  constructor(private httpClient: HttpClient,
              protected policyService: PolicyService,
              protected environment: EnviromentsService) {
    this.baseApiUrl = environment.baseApiURL;
    this.baseApiPassURL = environment.baseApiPassURL;
  }

  getTransferData(proposalId: string): Observable<any> {
    return this.httpClient.get(this.baseApiUrl + '/v2/life/proposal/' + proposalId + '/piptrasfer/definitions');
  }

  savePipTransferData(reqTransferData, resourceId: string): Observable<any> {
    if (!!reqTransferData && !!reqTransferData.fields.length ) {
      return this.httpClient.post(this.baseApiPassURL + '/ptflife/proposal/' + resourceId + '/piptrasfer', reqTransferData);
    } else {
      return of ({});
    }
  }

  savePipContributionData(reqContributionData, resourceId: string): Observable<any> {
    return this.httpClient.post(this.baseApiPassURL + '/ptflife/proposal/' + resourceId + '/pipcontribution', reqContributionData);
  }

  getContributionData(
    proposalId: string,
    productCode: string,
    effectiveDate: string,
    employee: string): Observable<ContributionResp> {
    const params = new HttpParams()
    .set('productCode', productCode)
    .set('effectiveDate', effectiveDate)
    .set('employee', employee);
    return this.httpClient
    .get<ContributionResp>(this.baseApiUrl + '/v2/life/proposal/' + proposalId + '/pipcontribution/definitions', {params});
  }

}

import {PostSalesOperation} from '../api/rgi-pnc-postsales.api';
import {PNC_POSTSALES_OPERATIONS_TYPES} from '../resources/enums/pnc-postsales-operations-types';
import {
  PncPsalesRequiredDataStateManagerCancellation
} from '../ops-managers/cancellation/required-data/required-data-state-manager-cancellation';
import {
  PncPsalesSummaryStateManagerCancellation
} from '../ops-managers/cancellation/summary/summary-state-manager-cancellation';
import {PncPsalesConfirmationStateManager} from '../state-managers/confirmation/confirmation-state-manager.service';
import {PncPsalesCancellationApiService} from '../resources/http/pnc-psales-cancellation-api.service';
import {RGI_PNC_POSTSALES_STEPS} from '../resources/constants/steps';

export const PNC_PSALES_CANCELLATION: PostSalesOperation = {
  type: PNC_POSTSALES_OPERATIONS_TYPES.CANCELLATION,
  sessionTitle: '_PCPSALES_._SESSION_TITLE_._POLICY_CANCELLATION_',
  steps: [
    {
      step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
      manager: PncPsalesRequiredDataStateManagerCancellation,
      deps: [PncPsalesCancellationApiService],
      stepLabel: '_PCPSALES_._STEPS_._CANCELLATION_DATA_',
      context: {
        operationParams: {
          reasonFieldCode: 'CANCELLATION_REASON',
          reasonFieldLabel: '_PCPSALES_._LABEL_._CANCELLATION_REASON_',
          notificationDateFieldLabel: '_PCPSALES_._LABEL_._CANCELLATION_NOTIFICATION_DATE_',
        }
      }
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
      manager: PncPsalesSummaryStateManagerCancellation,
      deps: [PncPsalesCancellationApiService],
      stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
      context: {
        operationParams: {
          reasonFieldLabel: '_PCPSALES_._LABEL_._CANCELLATION_REASON_',
        }
      }
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
      manager: PncPsalesConfirmationStateManager,
      deps: [],
      stepLabel: ''
    }
  ]
};

export const PNC_PSALES_APP_EXCLUSION: PostSalesOperation = {
  type: PNC_POSTSALES_OPERATIONS_TYPES.EXCLUSION,
  sessionTitle: '_PCPSALES_._SESSION_TITLE_._POLICY_EXCLUSION_',
  steps: [
    {
      step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
      manager: PncPsalesRequiredDataStateManagerCancellation,
      deps: [PncPsalesCancellationApiService],
      stepLabel: '_PCPSALES_._STEPS_._EXCLUSION_DATA_',
      context: {
        operationParams: {
          reasonFieldCode: 'EXCLUSION_REASON',
          reasonFieldLabel: '_PCPSALES_._LABEL_._EXCLUSION_REASON_',
          notificationDateFieldLabel: '_PCPSALES_._LABEL_._EXCLUSION_NOTIFICATION_DATE_'
        }
      }
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
      manager: PncPsalesSummaryStateManagerCancellation,
      deps: [PncPsalesCancellationApiService],
      stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
      context: {
        operationParams: {
          reasonFieldLabel: '_PCPSALES_._LABEL_._EXCLUSION_REASON_',
        }
      }
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
      manager: PncPsalesConfirmationStateManager,
      deps: [],
      stepLabel: ''
    }
  ]
};

import { Component, Input, Output, EventEmitter } from "@angular/core";

import { AnagFlowData, AnagSearchOrCreateResolver } from "@rgi/anag";
import { AlertService } from "@rgi/digital-claims-common-angular";
import { DscHeirService } from "./dsc-heir.service";
import { RgiRxTranslationService } from "@rgi/rx/i18n";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { AnagSubject, Heir, HeirsListSubjLink } from "../dsc-shared-model/dsc-model-subject";

@Component({
  selector: "claims-dsc-heir",
  templateUrl: "./dsc-heir.component.html",
  styleUrls: ["./dsc-heir.component.scss"],
})

export class DscHeirComponent {
  @Input() selectedNode: string;
  @Input() activeRouteId: string;
  @Input()
  get heirsList(): HeirsListSubjLink { return this._heirsList; }
  set heirsList(info: HeirsListSubjLink) {
    this._heirsList = info;
    if(this._heirsList.nodeId != '') {
      this.heirService.getSingleSubject(this._heirsList.objectId , this._heirsList.nodeId).subscribe(
        res => {
          if (!!res.subject) {
            this._heirsList.potentialHeirs = res.subject.links.map((el: Heir)=>({checked:false, ...el}));
          }
        }
      )
    }
  }

  private _heirsList: HeirsListSubjLink;
  @Output() outputHeirData = new EventEmitter<Heir>();

  constructor(
    protected anagSearchOrCreateResolver: AnagSearchOrCreateResolver,
    private alertService: AlertService,
    private rxTranslationsService: RgiRxTranslationService,
    private heirService: DscHeirService
  ) { }

  addHeir() {
    const anagFlowData = new AnagFlowData();
    anagFlowData.nodeCode = this.selectedNode;
    anagFlowData.idParentSession = this.activeRouteId;
    anagFlowData.partyRole='888';
    return this.anagSearchOrCreateResolver.searchOrCreateSubject(anagFlowData)
      .pipe(
        switchMap((resHeir: AnagSubject) => {
          const heir = this.inizializeHeir(resHeir);
          if (this.isHeirAlredyAdded(heir)) {
            return this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_ALREADY_ADDED')
            // controllo se l'erede che sto inserendo è uguale al soggetto deceduto
          } else if (heir.linkedPartyObjectId === this.heirsList.objectId) {
            return this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_IS_EQUAL_SUBJECT')
          } else {
            this._heirsList.potentialHeirs.push(heir);
            return of(false);
          }
        })
      ).subscribe((res: string | false) => {
        if (res) {
          this.alertService.translatedMessage(res)
        }
      })
  }

  inizializeHeir(heir: AnagSubject): Heir {
    return {
      notes: null,
      linkSubjectNominative: heir.nominative,
      linkType: null,
      linkedPartyUniqueKey: '',
      linkedPartyObjectId: heir.objectId,
      idSubjectLinkType: '',
      checked: false
    }
  }

  isHeirAlredyAdded(heir: Heir): boolean {
    return this.heirsList.potentialHeirs.some(el => (el.linkedPartyObjectId === heir.linkedPartyObjectId));
  }

  toggleHeir(heir) { 
      this.outputHeir(heir);
  }

  isHeirSelected(heir: Heir) {
    return this.heirsList.heirs.some(el => el.linkedPartyObjectId === heir.linkedPartyObjectId);
  }

  outputHeir(heirChecked: Heir) {
    this.outputHeirData.emit(heirChecked)
  }

}

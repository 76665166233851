import { Inject, Injectable, InjectionToken, StaticProvider, Type } from "@angular/core";
import { flatten } from "@rgi/rx";
import { ModalService } from "@rgi/rx/ui";
import { IdentificationDataComponent } from "../anag-components/identification-data/identification-data.component";
import {AnagPrivacyComponent} from "../anag-components/privacy/anag-privacy.component";
import { AnagModalComponent } from "../anag-components/modal/anag-modal.component";
import {AnagUbicationComponent } from "../anag-components/ubication-component/ubication.component";

import { AnagContactsComponent } from "../anag-components/contacts/anag-contacts.component";


export interface AnagModalComponentConfig {
    key: string;
    component: Type<any>
}

export const DEFAULT_ANAG_MODAL_CONFIG = [
    { key: 'IdentificationDataComponent', component: IdentificationDataComponent },
    { key: 'AnagPrivacyComponent', component: AnagPrivacyComponent },
    { key: 'AnagModalComponent', component: AnagModalComponent },
    { key: 'AnagUbicationComponent', component: AnagUbicationComponent},
    { key: 'AnagContactsComponent', component: AnagContactsComponent }
]

export const ANAG_MODAL_COMPONENTS = new InjectionToken<AnagModalComponentConfig[][]>('anag-modal-components-config');

@Injectable({
    providedIn: 'root'
})
export class AnagModalService {
    private  flattenConfig = new Map<string,AnagModalComponentConfig>();
    constructor(protected modalService: ModalService,
        @Inject(ANAG_MODAL_COMPONENTS) private config: AnagModalComponentConfig[][]) {
        flatten(config).forEach(it=>this.flattenConfig.set(it.key, it));
    }

    openComponent(component: string, data?: any, providers?: StaticProvider[]) {
        return this.modalService.openComponent(this.flattenConfig.get(component).component, data, providers)
    }
}

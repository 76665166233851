import {CommonModule} from '@angular/common';
import {Directive, Inject, Injectable, NgModule} from '@angular/core';
import {RgiRxPortalModule} from '@rgi/rx/portal';
import {AuthorizationsCardModule, AuthorizationsCardIntegrationService, AuthHomeFieldConfigurationService} from "@rgi/authorizations-card";
import {AUTHORIZATIONS_CARD} from "./authorizations-card.configurations";
import {AuthorizationCardPortalIntegrationService} from "./services/authorization-card-portal-integration.service";
import {Authorization, AuthorizationCardReducerService, AuthorizationsSearchService, AuthorizationsListTableComponent} from '@rgi/authorizations-card';
import {RoutingService} from '@rgi/rx/router';
import {downgradeComponent, downgradeInjectable} from "@angular/upgrade/static";

declare var angular: angular.IAngularStatic;

@Directive()
@Injectable()
export class AuthorizationCardReducerServicePortal extends AuthorizationCardReducerService {
  constructor(private routingService: RoutingService,
              protected authorizationsSearchService: AuthorizationsSearchService,
              protected inteGrationSevice: AuthorizationsCardIntegrationService,
              @Inject('coreResult') private coreResult: any,
              @Inject('sessionService') private sessionService: any,
              @Inject('authService') private authService: any) {
    super(routingService, inteGrationSevice);
  }

  openGroupPolicySession(authorizationDetail: Authorization) {
    const idSession = this.sessionService.list().find(session => session.isActive).idSession;
    const idNavigation = this.sessionService.open('groupPolicyIssue', 'edit', '', true, null, null, idSession);
    this.coreResult.setResult(idNavigation, 'edit', {
      proposalNumber: authorizationDetail.proposalNumber,
      isFromInquiry: false
    });
  }

  openPassPortalStandarSession(contractId: any) {
    // eslint-disable-next-line max-len
    this.authorizationsSearchService.getDamageProposal(contractId, true).subscribe((response: any) => {
      if (response) {
        const id = this.sessionService.open('ptfdamageProposal', 'propdata', '', true);
        this.coreResult.setResult(id, 'propdata', response);
      }
    });
  }

}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthorizationsCardModule,
    RgiRxPortalModule.forRoot(AUTHORIZATIONS_CARD)
  ],
  providers: [
    { provide: AuthorizationsCardIntegrationService, useClass: AuthorizationCardPortalIntegrationService }
  ]
})

export class AuthorizationsCardPortalModule {

  constructor() {
    angular.module('app')
        .directive('acAuthorizationsListTable', downgradeComponent({component: AuthorizationsListTableComponent}))
        .factory('authorizationsSearchService', downgradeInjectable(AuthorizationsSearchService) as any)
        .factory('authHomeFieldConfigurationService', downgradeInjectable(AuthHomeFieldConfigurationService) as any)
        .run(['$templateCache', ($templateCache) => {
      $templateCache.put('proxyTemplate', `<ac-authorizations-list-table ng-if="authorizations" issubcard="true" [authorizations]="authorizations" (action)="onSelect($event)"></ac-authorizations-list-table>`).directive;
    }]);
  }
}



import {Residence} from '../modules/lpc-policy-contact/models/residence';

export interface Payments {
  objectId?: any;
  extensionSubject?: any;
  iban?: any;
}

export interface AuthoritiesRelease {
  codice: string;
  descrizione: string;
}

export interface DocumentType {
  codice: string;
  descrizione: string;
}

export interface Document {
  objectId?: any;
  authoritiesRelease: AuthoritiesRelease;
  documentType: DocumentType;
  releaseDate: string;
  extensionSubject?: any;
  documentNumber: string;
  locationsRelease: string;
  expirationDate: string;
}

export interface Language {
  codice: string;
  descrizione: string;
}

export interface ProfessionDetail {
  codice: string;
  descrizione: string;
}

export interface LandlineNumber {
  objectId?: any;
  number?: any;
  extensionSubject?: any;
  internationalPrefix?: any;
  localPrefix?: any;
}

export interface SubjectType {
  codice: string;
  descrizione: string;
}

export interface ProvinceOfBirth {
  codice: string;
  descrizione: string;
}

export interface CountryOfBirth {
  codice: string;
  descrizione: string;
}

export interface PersonType {
  codice: string;
  descrizione: string;
}

export interface Privacy {
  identification: string;
  code: string;
  description: string;
}

export interface PrivacyConsensusValue {
  codice: string;
  descrizione: string;
}

export interface PrivacyConsensu {
  objectId?: any;
  extensionSubject?: any;
  privacy: Privacy;
  privacyConsensusValue: PrivacyConsensusValue;
}

export interface ProfessionType {
  codice: string;
  descrizione: string;
}

export interface Sex {
  codice: string;
  descrizione: string;
}

export interface Citizenship {
  codice: string;
  descrizione: string;
}

export interface Node {
  identification: string;
  code: string;
  description: string;
}

export interface Type {
  codice: string;
  descrizione: string;
}

export interface Key1 {
  label: string;
  state: string;
  type: Type;
  value: string;
}

export interface Type2 {
  codice: string;
  descrizione?: any;
}

export interface Key2 {
  label?: any;
  state: string;
  type: Type2;
  value?: any;
}

export interface Key3 {
  label?: any;
  state?: any;
  type?: any;
  value?: any;
}

export interface PartyKey {
  objectId: string;
  key1: Key1;
  country: string;
  key2: Key2;
  extensionSubject?: any;
  key3: Key3;
  main: boolean;
}

export interface Country2 {
  codice: string;
  descrizione: string;
}

export interface Province2 {
  codice: string;
  descrizione: string;
}

export interface BirthAddress {
  country: Country2;
  city: string;
  normalized: boolean;
  latitude: string;
  placeAddress?: any;
  number?: any;
  toponym?: any;
  cap?: any;
  province: Province2;
  countryCode: string;
  adminLevel3Short?: any;
  longitude: string;
  formatAddress: string;
  adminLevel3: string;
  adminLevel1?: any;
  subLocality?: any;
  adminLevel2: string;
  locality?: any;
  detailCode?: any;
  adminLevel2Short: string;
  fraction?: any;
  at?: any;
  extensionSubject?: any;
  adminLevel1Short?: any;
}

export interface PhoneFax {
  objectId?: any;
  number?: any;
  extensionSubject?: any;
  internationalPrefix?: any;
  localPrefix?: any;
}

export interface MaritalStatus {
  codice: string;
  descrizione: string;
}

export interface AnagSubject {
  objectId: string;
  privacyConsensusType?: any;
  payments: Payments;
  document: Document;
  documents: Document[];
  language: Language;
  professionDetail: ProfessionDetail;
  landlineNumber: LandlineNumber;
  subjectType: SubjectType;
  denomination?: any;
  emails: any[];
  mainContact?: any;
  cityOfBirth: string;
  provinceOfBirth: ProvinceOfBirth;
  countryOfBirth: CountryOfBirth;
  surname: string;
  fiscalCode: string;
  otherAddress: any[];
  company?: any;
  links: any[];
  iddQuestionnaires: any[];
  personType: PersonType;
  residence: Residence;
  privacyConsensus: PrivacyConsensu[];
  idLatestPhotos: string;
  sonsNumber: string;
  professionType: ProfessionType;
  sex: Sex;
  citizenship: Citizenship;
  vat?: any;
  groups: any[];
  dateOfBirth: string;
  clientNumber?: any;
  node: Node;
  corporateSector?: any;
  mobilePhone: any[];
  corporateForm?: any;
  extensionSubject?: any;
  name: string;
  educationalQualification?: any;
  partyKey: PartyKey[];
  birthAddress: BirthAddress;
  bondingCode?: any;
  partyNumber?: any;
  phoneFax: PhoneFax;
  privacyConsensusDate: string;
  maritalStatus: MaritalStatus;
  nominative: string;
}

export interface RootObject {
  subject: AnagSubject;
}

type PartyCompleteResult = 'KO' | 'WARNING' | 'OK';
export const PARTY_COMPLETE_KO = 'KO';
export const PARTY_COMPLETE_OK = 'OK';
export const PARTY_COMPLETE_WARNING = 'WARNING';

export interface PartyCompleted {
  outcome: string[];
  /** @values 'KO' | 'WARNING' | 'OK' */
  result: PartyCompleteResult;
}

export interface PartyCheck {
  errorMessages: PartyCheckErrorMessages[];
  partycongruent: boolean;
}

export interface PartyCheckErrorMessages {
  errorDescription: string;
  errorType: string;
  description?: string;
}

export class PartyCheckErrorMessages {
  errorDescription: string;
  errorType: string;
  constructor(message: string) {
    this.errorDescription = message;
  }
}

import { LifeSessionService } from './../../../../services/life-session-service';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { EventNotificator } from '../../../../event-notificator';
import { TranslationWrapperService } from '../../../../../i18n/translation-wrapper.service';
import { BeneficiaryService } from '../../../../services/beneficiary.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject } from '../../../../models/subject.model';
import { LifeRoleService } from './../../../../services/life-role.service';
import { AnagEditPartyResolver } from '@rgi/anag';

@Component({
  selector: 'lic-related-subject-list',
  templateUrl: './related-subject-list.component.html',
  styleUrls: ['./related-subject-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RelatedSubjectListComponent implements OnInit, EventNotificator {

  @Input() withPercentage: boolean;
  @Input() category: any;
  @Input() benef: any;
  @Input() minPercentage: any;
  @Input() maxLength: number;
  @Input() role: string;

  @Output() eventPropagation = new EventEmitter<any>();
  @Output() relatedSubjectDeletion = new EventEmitter<{cat: string, benef: any, subjId: string}>();
  @Output() subjetAnagEditedEvent = new EventEmitter<{ openedEdit: boolean, subject: Subject, role: string, withPercentage: boolean}>();
  public select;
  public percentage;
  public label;

  public percentageForm: UntypedFormGroup = new UntypedFormGroup({});
  public listOfSubjectLength: number;
  constructor(
    public lifeRoleService: LifeRoleService,
    protected translateService: TranslationWrapperService,
    protected benefService: BeneficiaryService,
    protected lifeSessionService: LifeSessionService,
    protected anagEditResolver: AnagEditPartyResolver) {
  }

  ngOnInit() {
    this.select = this.translateService.getImmediate('lic_select');
    this.percentage = this.translateService.getImmediate('lic_Percentage');
    this.label = this.lifeSessionService.partyRoles.find(el => el.codice === this.role).descrizione;
  }

  trashButtonClick(subjectId) {
    const event = {cat: this.category, benef: this.benef, subjId: subjectId};
    this.relatedSubjectDeletion.emit(event);
  }

  fill(subject: any) {
    /*if (subject.name != null) {
      return subject.surname + ' ' + subject.name;
    } else if (subject.denomination != null) {
      return subject.denomination;
    } else {
      return null;
    }*/
    const subj = this.lifeRoleService.getStoredSubject(subject.objectId);
    return subj.nominative || subject.denomination;
  }

  public openAnag() {
    this.eventPropagation.emit({
      role: this.role,
      withPercentage: this.withPercentage
    });
  }

  getListOfSubject(): {percentage: string, subject: Subject}[] {
    let subjList = this.benefService.getSubjectListFilterByRole(this.category, this.benef, this.role);
    if (subjList != null && subjList.length > 0) {
      subjList.forEach((s) => {
        if (!this.percentageForm.get(s.subject.objectId.toString())) {
          this.percentageForm.addControl(
            s.subject.objectId.toString(),
            new UntypedFormControl(s.percentage)
          );
        }
      });
    } else {
      subjList = [];
    }
    this.listOfSubjectLength = subjList.length;
    return subjList;
  }

  setPercentageBySubj(subj, event) {
    this.benefService.setPercentageOfSubj(this.category, this.benef, subj, event);
  }

  editSubject(subjId: string, role: string) {
    this.openSubjectModifyModal(this.lifeRoleService.storedSubjectMap.get(subjId), role);
   }

   openSubjectModifyModal(subject: Subject, role: string) {
     return this.anagEditResolver.editParty(
       subject as any,
       this.lifeRoleService.getAnagFlowData(role, subject.subjectType.codice)
     ).subscribe(editedParty => {
       this.lifeRoleService.storeSubject(editedParty);
       return this.openedAnagModifySession({ openedEdit: true, subject: editedParty, role, withPercentage: this.withPercentage });
     });
   }
   openedAnagModifySession(obj: { openedEdit: boolean, subject: Subject, role: string, withPercentage: boolean}) {
    // sub?: { role: string, withPercentage: boolean, subject: Subject, openedEdit: boolean }
    this.percentageForm.get(obj.subject.objectId.toString()).setValue(null);
    this.subjetAnagEditedEvent.emit(obj);
   }


}

<div [formGroup]="formGroup" style="padding-left: 10px;">
  <div class="row" formArrayName="subRoles">
    <div class="col-lg-12" *ngFor="let role of getFilteredRolesOf(roleDefinition.code); let i = index" [formGroupName]="i">
      <label class="label">
        <span translate>{{roleDefinition.label}}</span>
      </label>
      <div class="input-group">
        <input [ngClass]="{'with-percentage' : roleDefinition.showPercentage}" [attr.data-qa-role]="role.role+'-role-has-value'" type="text" class="form-control" formControlName="name">
        <input  [ngClass]="{'with-percentage' : roleDefinition.showPercentage}" type="text" class="form-control" formControlName="percentage" *ngIf="roleDefinition.showPercentage">
        <div class="input-group-btn">
          <button [disabled]="disableButtons" class="btn btn-outline-secondary" type="button" (click)="onDeleteSubRole(i, role.role)">
            <span class="rgifont rgi-trash" [attr.data-qa-role]="role.role+'-role-trash-button'"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <label class="label">
        <span translate>{{roleDefinition.label}}</span>
      </label>
      <input [value]="selectLabel" (click)="onSelect(roleDefinition.code)" [attr.data-qa-role]="roleDefinition.code+'-role-no-value'" class="col-lg-12 drop-container dashedSelect" readonly>
    </div>
  </div>
</div>

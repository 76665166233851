<rgi-rx-datatable rgi-rx-qa="outcome_results" qa-ctx="adapterprint-context"
                  [data]="RESULTS" [schema]="schema"
                  [pageOptions]="[10,25,50,100]"
                  [expansionRow]="rowDetail">
</rgi-rx-datatable>
<!-- REFERENCED TEMPLATE -->
<ng-template #rowDetail let-resultRow>
  <div class="table-results-row">
    <!-- FIRST COLUMN -->
    <div class="table-results-cell">
      <span><div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._NODE_CODE_</div>
      <div class="table-results-cell-value">{{resultRow.nodeCode || "-"}}</div></span>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._NODE_DESCRIPTION_</div>
      <div class="table-results-cell-value">{{resultRow.nodeDescr || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._USER_</div>
      <div class="table-results-cell-value">{{resultRow.user || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._TEMPLATE_</div>
      <div class="table-results-cell-value">{{resultRow.template || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._OUTCOME_</div>
      <div class="table-results-cell-value">{{resultRow.outcome || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._POLICY_</div>
      <div class="table-results-cell-value">{{resultRow.policy || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._ADDRESSED_</div>
      <div class="table-results-cell-value">{{resultRow.addressed || "-"}}</div>
    </div>
    <!-- SECOND COLUMN -->
    <div class="table-results-cell">
      <div class="table-results-cell-label" >Mail</div>
      <div class="table-results-cell-value">{{resultRow.mail || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._ADDRESS_</div>
      <div class="table-results-cell-value">{{resultRow.address || "-" }}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._DATE_SENT_</div>
      <div class="table-results-cell-value">{{resultRow.sendDate || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._DOCUMENT_TYPE_</div>
      <div class="table-results-cell-value">{{resultRow.documentType || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._OUTCOME_</div>
      <div class="table-results-cell-value">{{resultRow.outcome || "-"}}</div>

      <div class="table-results-cell-label" translate>_ADAPTERPRINT_._LABEL_._LOT_</div>
      <div class="table-results-cell-value">{{resultRow.lot || "-"}}</div>

      <div class="table-results-cell-label">Job</div>
      <div class="table-results-cell-value">{{resultRow.job || "-"}}</div>
    </div>
  </div>
</ng-template>
<div class="btn-group btn-group-justified">
  <div class="btn-group">
    <button class="btn btn-warning btn-secondary pull-right text-uppercase" (click)="back()" translate>_ADAPTERPRINT_._BTN_._BACK_</button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';
import { CustomTooltipDirective } from './customTooltip.directive';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
  imports: [
    CommonModule,
    RgiRxI18nModule
  ],
  declarations: [TooltipDirective, CustomTooltipDirective],
  exports: [TooltipDirective, CustomTooltipDirective]
})
export class TooltipModule { }

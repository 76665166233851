<div data-qa="life-profile-detail" *ngIf="onInitEnd">

  <div class="row" id="profile-row">
    <div class="col-md-8">
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                (click)="back()" data-qa="back-arrow"></span>
              <span class="separator"> | </span>
              <span class="menu-title" translate>lpc_profile_details</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 life-detail-first-info">
          <div class="col-md-5 life-detail-img">
            <div class="hidden-xs hidden-sm anag-user-ico">
              <span class="rgifont life-detail-icon-padding rgi-fogliocassa"></span>
            </div>
          </div>
          <div class="col-md-7 life-detail-general-info">
            <h3 class="col-md-12 card-color-blue" style="margin-top: 0px !important;">
              <span data-qa="profileDescription">
                {{profile.name}}
              </span>
            </h3>
            <div class="container-fluid anag-data">
              <div class="col-md-12">
                <span class="tbld_col_title" translate>lpc_division_type</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="division-type">
                 {{profile.type}}
                </span>
              </div>
              <div class="col-md-12">
                <span class="tbld_col_title" translate>lpc_status</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="status">
                  {{profile.status}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 padding-card" *ngIf="!!profile.relatedProducts.length">
          <div class="col-md-4 life-detail-infobox" *ngFor="let product of profile.relatedProducts">
            <div class="col-md-12">
              <span data-qa="product">
                {{product}}
              </span>
            </div>
          </div>
        </div>
      </div>
        <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
          <div class="tbld life-detail-margin-top">
              <div class="row life-detail-card-title-container">
                  <div class="row life-detail-title">
                    <div class="card-title">
                      <span class="header-icon rgifont "></span>
                      <span class="menu-title" translate>lpc_Allocation</span>
                    </div>
                  </div>
                </div>
            <div class="tbld_row life-detail-tbld_row_header">
              <div class="tbld_col tbld_col_title" translate>lpc_division_description</div>
              <div class="tbld_col tbld_col_title" translate>lpc_start_validity_date</div>
              <div class="tbld_col tbld_col_title" translate>lpc_end_validity_date</div>
              <div class="tbld_col tbld_col_title" translate>lpc_status</div>
              <div class="tbld_col tbld_col_title"></div>
            </div>
            <ng-container *ngFor="let version of profile.versions">
              <div class="tbld_row content">
                <div class="tbld_col tbld_col_value">{{version.name}}</div>
                <div class="tbld_col tbld_col_value">{{convert(version.validityFrom)}}</div>
                <div class="tbld_col tbld_col_value">{{convert(version.validityTo)}}</div>
                <div class="tbld_col tbld_col_value">{{version.status}}</div>
                <div class="tbld_col tbld_col_value" style="text-align: center">
                  <button (click)="openSession(version)" type="button" class="btn dt-btn">
                    <span class="rgifont rgi-chevron-right"></span>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
    </div>
  </div>
</div>

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from "@rgi/rx/ui";
import {RGI_DOCUMENTS_ACTIONS} from "../../resources/constants/actions";
import {Documents} from "../../resources/model/documents";
import {DocumentsService} from "../../services/documents.service";
import {GenericEntity} from "../../resources/model/generic-entity";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

export class RgiAddDocumentModalData {
  title: string;
  contractId: string;
  documents: Documents;
  constructor(title: string, contractId: string, documents: Documents) {
    this.title = title;
    this.contractId = contractId;
    this.documents = documents;
  }
}

@Component({
  selector: 'rgi-document-add-modal',
  templateUrl: './document-add-modal.component.html'
})
export class DocumentAddModalComponent implements OnModalClose {
  modalClose: EventEmitter<RGI_DOCUMENTS_ACTIONS | string> = new EventEmitter();
  data: RgiAddDocumentModalData;
  arDocumentType: GenericEntity[] = [];
  addDocumentForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private documentsService: DocumentsService,
    @Optional() @Inject(DIALOG_DATA) data: RgiAddDocumentModalData
  ) {
    if (data) {
      this.data = data;
    }
  }

  ngOnInit() {
    const documentTypeFormCtrl = new UntypedFormControl({}, {
      validators: [Validators.required],
      updateOn: 'change'
    });

    this.addDocumentForm.addControl('documentType', documentTypeFormCtrl);

    this.documentsService.getDocumentType(this.data.contractId).subscribe((documentTypes: GenericEntity[]) => {
      this.arDocumentType = documentTypes;
    });
  }

  close() {
    this.modalClose.emit(RGI_DOCUMENTS_ACTIONS.CANCEL);
  }

  confirmAddDocument() {
    const documentTypeIn = this.addDocumentForm.get('documentType')?.value;
    const documentType = this.arDocumentType.find((docType: GenericEntity) => {
      return docType.code === documentTypeIn;
    });
    if(documentType) {
      this.documentsService.addDocument(this.data.contractId, documentType).subscribe((documents: Documents) => {
        this.data.documents = documents;
        this.documentsService.setDocumentsSignal(this.data.documents);
        this.modalClose.emit(RGI_DOCUMENTS_ACTIONS.FILE_UPLOADED);
      });
    }
  }




}

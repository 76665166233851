<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()" translate>_GP_._TITLE_._ACTIONS_
  </rgi-rx-panel-header>
  <label class="rgi-ui-text-4 rgi-ui-warning" *ngIf="!data.actions || data.actions.length===0" translate>_GP_._LABEL_._NO_ACTION_FOUND_</label>
  <div *ngFor="let singleOp of singleOperations" class="menu-action">
    <div class="rgi-ui-list-item" (click)="executeAction(singleOp)" [attr.data-qa]="singleOp.code">
      <label class="rgi-ui-text-4 rgi-ui-info" translate>{{singleOp.description}}</label>
      <button class="rgi-ui-btn"><span class="rgi-ui-icon-right-arrow"></span></button>
    </div>
  </div>
  <div class="menu-action" *ngFor="let menu of nestedOperations">
    <div (click)="menu.expanded = !menu.expanded" data-qa="rgi-gp-open-action-menu">
      <button class="rgi-ui-btn"><span
        [ngClass]="menu.expanded ? 'rgi-ui-icon-open-content': 'rgi-ui-icon-close-content'"></span></button>
      <label class="rgi-ui-text-4" translate>{{menu.description}}</label>
    </div>
    <div class="rgi-ui-list" *ngIf="menu.expanded">
      <div class="rgi-ui-list-item" *ngFor="let operation of menu.operations" (click)="executeAction(operation)"
           [attr.data-qa]="operation.code">
        <label class="rgi-ui-text-4 rgi-ui-info" translate>{{operation.description}}</label>
        <button class="rgi-ui-btn"><span class="rgi-ui-icon-right-arrow"></span></button>
      </div>
    </div>
  </div>
  <rgi-rx-panel-footer>
    <button
      class="rgi-ui-btn rgi-ui-btn-primary uppercase"
      (click)="modalClose.emit()" translate>{{'_GP_._BTN_._CLOSE_'}}
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxLocaleSelectorComponent} from './rgi-rx-locale-selector.component';
import {RgiRxFormElementsModule} from '../form-elements/rgi-rx-form-elements.module';
import {RgiRxI18nModule, RgiRxLocaleDescriptionPipe} from '@rgi/rx/i18n';
import {RgiRxLocaleSelectorDirective} from './rgi-rx-locale-selector.directive';
import {RGI_RX_LOCALE_SELECTOR_TRANSLATIONS} from './i18n/localization';

@NgModule({
  declarations: [
    RgiRxLocaleSelectorComponent,
    RgiRxLocaleSelectorDirective,
  ],
  imports: [
    CommonModule,
    RgiRxFormElementsModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(RGI_RX_LOCALE_SELECTOR_TRANSLATIONS)
  ],
  exports: [
    RgiRxLocaleSelectorComponent,
    RgiRxLocaleSelectorDirective,
    RgiRxLocaleDescriptionPipe,
  ],
})
export class RgiRxLocaleSelectorModule {
}

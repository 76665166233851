import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PaymentFields } from '../../models/meansofpayment.model';




@Component({
  selector: 'lic-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {
  @Input() pagamenti: PaymentFields[];

  @Input() disableField: string;

  @Input() parentForm: UntypedFormGroup;

  /* @Input() disableBoolean: boolean; */

  @Output() changeValOfEmitter = new EventEmitter<string>();
  @Output() messageValOfEmitter = new EventEmitter<string>();


  @HostListener('changeValore')
  onChange() {
    this.changeValOfEmitter.emit();
  }

  @HostListener('errorMessage')
  ibanError() {
    if (this.messageValOfEmitter.error !== null) {
      this.messageValOfEmitter.emit();
    }
  }

  constructor() { }

  ngOnInit(): void { }

}

<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()"><span translate>RE_ISSUE.FPS_TAXES</span>
  </rgi-rx-panel-header>

  <div class="modal-body large-modal">

    <br class="hidden-xs" clear="all">

    <div class="contributions-taxes-summary hidden-xs" style="padding-right: 35px;padding-top: 18px;padding-left: 13px;"
         data-qa="contribution_taxes_summary_sign_instalment">
      <div class="summary-net-row">
        <span translate>RE_ISSUE.FPS_LABEL.INCEPTION_INSTALLMENT</span>
      </div>

    </div>

    <table rgi-rx-table [dataSource]="PREMIUM_SUBSCRIPTION_INSTALMENT_DATA">

      <ng-container rgiRxColumnDef="description">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.description}} </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>
      <ng-container rgiRxColumnDef="taxable">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row" style="text-align: right;">
          <ng-container *ngIf="row.rate > 0; else emptyDisplay">
            {{row.taxable | number : '1.2-2'}}
          </ng-container>
          <ng-template #emptyDisplay>
            -
          </ng-template>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>
      <ng-container rgiRxColumnDef="rate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.RATE</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" style="text-align: right;">
          <ng-container *ngIf="row.rate > 0; else emptyDisplay">
            {{row.rate | number : '1.2-2'}}%
          </ng-container>
          <ng-template #emptyDisplay>
            -
          </ng-template>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>
      <ng-container rgiRxColumnDef="taxes">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.TAXES</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" style="text-align: right;"> {{row.taxes | number : '1.2-2'}} </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>

      <!-- SUPER HEADER -->

      <ng-container rgiRxColumnDef="sh1">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
            style="text-align: center;border: 3px solid white">
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="border: 3px solid white;border-right: 0px;"
            [attr.colspan]="1" *rgiRxFooterCellDef>
          <span style="text-align: left" translate>RE_ISSUE.TOTAL</span>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="sh2">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
            style="text-align: left;border: 3px solid white; padding-left: 7px" translate>RE_ISSUE.TAXABLE
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header"
            style="text-align: center;border: 3px solid white;border-left: 0px;" [attr.colspan]="2" *rgiRxFooterCellDef>
          <span style="text-align: left"></span>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="sh3">
        <th rgi-rx-header-cell style="text-align: center;border: 3px solid white" *rgiRxHeaderCellDef
            [attr.colspan]="2" translate>
          RE_ISSUE.INSURANCE_TAX
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="text-align: right;border: 3px solid white"
            [attr.colspan]="2" *rgiRxFooterCellDef>
          <span style="text-align: right">{{lpsSignInstalmentAmount}}</span>
        </td>
      </ng-container>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="superHeader"></tr>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="signDerogationDisplayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: signDerogationDisplayedColumns;"></tr>
      <tr rgi-rx-footer-row *rgiRxFooterRowDef="superHeader"></tr>
    </table>

    <br class="hidden-xs" clear="all">

    <div class="contributions-taxes-summary hidden-xs"
         style=" padding-right: 35px;padding-top: 18px;padding-left: 13px;"
         data-qa="contribution_taxes_summary_sign_instalment">
      <div class="summary-net-row">
        <span translate>RE_ISSUE.FPS_LABEL.NEXT_INSTALMENT</span>
      </div>

    </div>
    <table rgi-rx-table [dataSource]="PREMIUM_INSTALMENT_DATA">
      <ng-container rgiRxColumnDef="description">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.description}} </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>
      <ng-container rgiRxColumnDef="taxable">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row" style="text-align: right;">
          <ng-container *ngIf="row.rate > 0; else emptyDisplay">
            {{row.taxable | number : '1.2-2'}}
          </ng-container>
          <ng-template #emptyDisplay>
            -
          </ng-template>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>
      <ng-container rgiRxColumnDef="rate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.RATE</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" style="text-align: right;">
          <ng-container *ngIf="row.rate > 0; else emptyDisplay">
            {{row.rate | number : '1.2-2'}}%
          </ng-container>
          <ng-template #emptyDisplay>
            -
          </ng-template>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>
      <ng-container rgiRxColumnDef="taxes">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.TAXES</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" style="text-align: right;"> {{row.taxes | number : '1.2-2'}} </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
      </ng-container>

      <!-- SUPER HEADER -->

      <ng-container rgiRxColumnDef="sh1">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
            style="text-align: center;border: 3px solid white">
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="border: 3px solid white;border-right: 0px;"
            [attr.colspan]="1" *rgiRxFooterCellDef>
          <span style="text-align: left" translate>RE_ISSUE.TOTAL</span>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="sh2">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
            style="text-align: left;border: 3px solid white; padding-left: 7px" translate>RE_ISSUE.TAXABLE
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header"
            style="text-align: center;border: 3px solid white;border-left: 0px;" [attr.colspan]="2" *rgiRxFooterCellDef>
          <span style="text-align: left"></span>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="sh3">
        <th rgi-rx-header-cell style="text-align: center;border: 3px solid white" *rgiRxHeaderCellDef
            [attr.colspan]="2" translate>
          RE_ISSUE.INSURANCE_TAX
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="text-align: right;border: 3px solid white"
            [attr.colspan]="2" *rgiRxFooterCellDef>
          <span style="text-align: right">{{lpsNextInstalmentAmount}}</span>
        </td>
      </ng-container>


      <tr rgi-rx-header-row *rgiRxHeaderRowDef="superHeader"></tr>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="signDerogationDisplayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: signDerogationDisplayedColumns;"></tr>
      <tr rgi-rx-footer-row *rgiRxFooterRowDef="superHeader"></tr>

    </table>

  </div>
</rgi-rx-panel>

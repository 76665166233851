import {Injectable, Injector} from '@angular/core';
import {HttpBackend, HttpInterceptor} from '@angular/common/http';
import {RgiRxHttpClientHandler, RgiRxHttpClientInterceptorResolver} from './rgi-rx-http-handler.service';
import {RgiRxHttpClientFactoryOpts, RgiRxHttpErrorAdapter, RgiRxHttpRequestAdapter} from './http-client.api';
import {RgiRxHttpErrorStream} from '../rgi-rx-http-error-stream';
import {RgiRxHttpClient} from './rgi-rx-http-client.service';

@Injectable()
/**
 * Factory responsible for creating HttpClient using the @rgi/rx/http API
 */
export class RgiRxHttpClientFactory {

  constructor(
    private backend: HttpBackend,
    private interceptorsResolver: RgiRxHttpClientInterceptorResolver,
    private adapter: RgiRxHttpErrorAdapter,
    private injector: Injector,
    private httpErrorStream: RgiRxHttpErrorStream
  ) {
  }

  buildWrapper(options?: RgiRxHttpClientFactoryOpts): RgiRxHttpClient {
    const interceptors: HttpInterceptor[] = this.interceptorsResolver.interceptors;
    /*  if (options && options.withInterceptors) {
        interceptors.concat(options.withInterceptors);
      }*/
    const adapter: RgiRxHttpErrorAdapter = options && options.withErrorAdapter ? this.injector.get<RgiRxHttpErrorAdapter>(options.withErrorAdapter) : this.adapter;
    const rgiRxHttpClientHandler = new RgiRxHttpClientHandler(
      this.backend,
      options?.withoutInterceptors ? [] : interceptors,
      adapter,
      this.httpErrorStream,
      !!options && !!options.withRequestAdapter ? this.injector.get<RgiRxHttpRequestAdapter>(options.withRequestAdapter) : undefined
    );
    return new RgiRxHttpClient(rgiRxHttpClientHandler, !!options ? {
      basePath: options.withBasePath
    } : undefined);
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimelineService } from '../../timeline.service';

@Component({
  selector: 'claims-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input()  userNoteId: string;
  @Input()  claimId: string;
  comments: any[];
  newComment: string;
  changed = false;
  showAll = false;

  constructor(
    private timelineService: TimelineService,
    public activeModal: NgbActiveModal,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any) {
      const listFunz = this.authService.getOperator().enabledFeatures;

      if (listFunz && listFunz.indexOf('SXTLCM') >= 0) {
       this.showAll = true;
      }
     }

  ngOnInit() {
    this.update();
  }

  closeModal() {
    this.activeModal.close(this.changed);
  }

  numberActiveComments() {
    if (!this.comments) {
      return 0;
    } else {
      return this.comments.filter(el => this.showComment(el)).length;
    }

  }

  showComment(comment: any) {
     if (this.showAll) {
      return true;
     } else {
      return comment.userIns === this.authService.getOperator().username;
     }
  }

  update() {
    this.newComment = '';
    this.timelineService.getComments(this.claimId, this.userNoteId).subscribe(
      (response: any) => {
       console.log('getComments ->', response);
       this.comments = response;
       this.eventService.broadcastEvent('stop-loader');
      },
      (error: any) => {
       this.comments = [];
      }
     );
  }

  cancel(userCommentId: string) {
    this.eventService.broadcastEvent('start-loader');
    this.timelineService.cancelComment(this.claimId, this.userNoteId, userCommentId).subscribe(
      (res: any) => {
        this.update();
        this.changed = true;
        // event.stopPropagation();
      },
      (err: any) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );
  }

  addComment() {
    if (this.newComment) {
      this.eventService.broadcastEvent('start-loader');
      this.timelineService.insertComment(this.claimId, this.userNoteId, this.newComment).subscribe(
        (res: any) => {
          this.update();
          this.changed = true;
          // event.stopPropagation();
        },
        (err: any) => {
          this.eventService.broadcastEvent('stop-loader');
        }
      );
    }
  }
}

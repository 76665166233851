import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {Variable} from '../../models/domain-models/variable';
import {REST_API_ANAG_VARIABLES_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueVariablesResourceService {
  private VARIABLES_PATH = '/variables'; // FOR MOCK SERVICE ONLY
  protected apiVariablesConf: any;

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any,
  ) {
    this.apiVariablesConf = REST_API_ANAG_VARIABLES_CONF;
  }

  get(resourceId: string): Observable<Array<Variable>> {
    return this.http.get<Array<Variable>>(this.variablesUri(resourceId)).pipe(share());
  }

  put(resourceId: string, data: Array<Variable>): Observable<Array<Variable>> {
    return this.http.put<Array<Variable>>(this.variablesUri(resourceId), data).pipe(share());
  }

  protected variablesUri(resourceId: string): string {
    return this.apiConf.api.portal.path + this.apiVariablesConf.path + resourceId + this.apiVariablesConf.variablesPath;
  }

}

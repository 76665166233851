import {Component, Inject, OnInit} from '@angular/core';
import {map, mergeMap, take} from 'rxjs/operators';
import {StepperSection} from '../../models/stepper-section';
import {
  ReIssueSummaryStateManagerService
} from '../re-issue-state-manager/re-issue-summary-state-manager.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ModalService} from '@rgi/rx/ui';
import {ReIssueMsgModalComponent} from '../re-issue-msg-modal/re-issue-msg-modal.component';
import {of} from 'rxjs';
import {ReIssueMsgData} from '../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import {Currency} from '../../models/domain-models/parameters/currency';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { Bond } from '../../models/domain-models/bond';
import { Subject } from '../../models/domain-models/subject';

import { Party } from '../../models/domain-models/parameters/party';
import { AttachmentType } from '../../models/domain-models/parameters/attachment-type';
import { RoleType } from '../../models/domain-models/parameters/roleType';
import { BondData } from '../../models/domain-models/bond-data';
import { RgiRxPushMessage, PushMessageHandlerService } from '@rgi/rx';
import {ReIssueSummaryState} from '../re-issue-state-manager/re-issue-summary.state';
import {RgiRxUserAuthorizationService, RgiRxUserService} from '@rgi/rx/auth';
import {ActiveRoute} from '@rgi/rx/router';


@Component({
  selector: 're-issue-summary',
  templateUrl: './re-issue-summary.component.html',
  styleUrls: ['./re-issue-summary.component.css']
})

export class ReIssueSummaryComponent implements OnInit {

  currentStep = StepperSection.summary;
  stepperLabels = ['RE_ISSUE.POLICY_DATA', 'RE_ISSUE.ASSETS_DATA', 'RE_ISSUE.QUOTATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.ISSUE_STEPPER'];
  selectedFrequency: any;
  msgData: string;
  currency: Currency;
  newBondForm: UntypedFormGroup;
  showNewBond = false;
  showNewBondIndex = -1;
  expandAssetIndex = -1;
  bindings: any[] = [];
  editMode = false; // Track whether in edit mode
  selectedBinding: any = null; // Store the selected binding for editing
  bond: Bond;
  TAG_LIEN_NUMBER_ERRORS = 'form_lien_number_errors';
  lienIsSelected: any;
  numOfAssets = 1;
  lienMandatoryList:boolean[] = [];
  NO_QUOTED_INSURED_PROPOSAL = 2;
  isDirectionalUser: boolean;
  
  constructor(
    public stateManager: ReIssueSummaryStateManagerService<ReIssueSummaryState>,
    private translateService: RgiRxTranslationService,
    protected customModalService: ModalService,
    private fb: UntypedFormBuilder, protected errorService: PushMessageHandlerService,
    private userService: RgiRxUserService,
    private userAuthorizationService: RgiRxUserAuthorizationService,
    private activeRoute: ActiveRoute,
    @Inject('sessionService') private portalSession: any
  ) {
    this.newBondForm = this.fb.group({
      description: [''],
      effectDate: [''],
      expiryDate: [''],
    });
  }

  ngOnInit() {
    this.stateManager.getState$().pipe(
      take(1),
      map(st => {
        this.lienIsSelected = st.policyTechnicalData.binder.selected;
        this.currency = st.policyTechnicalData.selectedCurrency;
        if (st.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList) {
          const paymentFrequencyList = st.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList;
          if (paymentFrequencyList && paymentFrequencyList.length > 0) {
            paymentFrequencyList.forEach(frequency => {
              if (frequency.selected) {
                this.selectedFrequency = frequency.description;
              }
            });
          }
        }
        this.initializeBindings(st);
      })
    ).subscribe();
    const isHeadOfOffice = this.userAuthorizationService.isAuthorizedFor('admin.config.operatortype.headoffice');
    this.isDirectionalUser = this.userService.getUser<any>().isDirectionalUser || isHeadOfOffice;
  }

  private initializeBindings(st: ReIssueSummaryState) {
    this.numOfAssets = st.proposal.assets.length;
    for (let i = 0; i < this.numOfAssets; i++) {
      const currentAsset = st.proposal.assets[i];
      this.lienMandatoryList.push(currentAsset.lienMandatory);
      currentAsset.bonds.forEach(element => {
        const personType = {
          codice: element.subject.type.code
        };
        const bodyBinding = {
          objectId: element.subject.id,
          idLatestPhotos: element.subject.snapshotId,
          nominative: element.subject.nominative,
          surname: element.subject.surname,
          name: element.subject.name,
          personType
        };
        const bond = {
          description: element.description,
          effectDate: element.effectDate,
          expiryDate: element.expirationDate,
          bodyBinding
        };
        this.bindings[i] = this.bindings[i] || [];
        this.bindings[i].push(bond);
      });
    }
  }

  back() {
    this.stateManager.back();
  }

  goToIthStep(i){
    this.stateManager.goToIthStep(i);
  }

  notifyErrorMessage(tag: string, content: string) {
    const errorMsg = new RgiRxPushMessage();
    errorMsg.tag = tag;
    errorMsg.status = 'danger';
    errorMsg.dismissible = false;
    errorMsg.options = { icon: 'rgi-ui-icon-alert' };
    errorMsg.content = content;
    this.errorService.notify(errorMsg);
  }

  goToProposalManagement() {
    this.errorService.clearTag(this.TAG_LIEN_NUMBER_ERRORS);
    if (!this.areBidingsValid()) {
      this.notifyErrorMessage(this.TAG_LIEN_NUMBER_ERRORS, 'RE_ISSUE.ERROR_MESSAGE_NUMBER_BOND');
    } else {
      this.stateManager.saveProposal().pipe(take(1)).subscribe(st => {
        const msgData = new ReIssueMsgData();
        const msg = of(['RE_ISSUE.PROPOSAL_N',
          'RE_ISSUE.SAVED_SUCCESSFULLY']);
        msg.pipe(mergeMap(r => {
          return this.translateService.translateAll(...r);
        })).subscribe(next => {
          this.msgData = next[0] +
            ' ' + st.proposal.contractNumber.proposal + ' ' + next[1];
        }).unsubscribe();
        msgData.msg = this.msgData;
        msgData.showButtonConfirm = false;
        msgData.showButtonContinue = true;
        const confirmationModal = this.customModalService.openComponent(ReIssueMsgModalComponent, msgData);
        confirmationModal.modal.onClose.subscribe(confirm => {
        if (confirm) {
            this.stateManager.goToProposalManagement();
        }
        });

      });
    }
  }

  areBidingsValid() : boolean{
    let index = 0;
    let areValid = true;
    if(this.lienIsSelected) {
      let atLeastOneAssetIsMandatory = false;
      for (const isLienMandatory of this.lienMandatoryList) {
        atLeastOneAssetIsMandatory = atLeastOneAssetIsMandatory || isLienMandatory;
        const elements = this.bindings[index] ? this.bindings[index] : [];
        areValid = areValid
          && ((isLienMandatory !== null && !isLienMandatory)
            || ((isLienMandatory === null || isLienMandatory) && elements.length > 0));
        index++;
      }
      if(!atLeastOneAssetIsMandatory && this.bindings.length <= 0){
        areValid = false;
      }
    }
    return areValid;
  }
  saveProposal(iscontinueAfterSave = true) {
    this.errorService.clearTag(this.TAG_LIEN_NUMBER_ERRORS);
    if (!this.areBidingsValid()) {
      this.notifyErrorMessage(this.TAG_LIEN_NUMBER_ERRORS, 'RE_ISSUE.ERROR_MESSAGE_NUMBER_BOND');
    } else {
      this.stateManager.saveProposal().pipe(take(1)).subscribe(st => {
        const msgData = new ReIssueMsgData();
        const msg = of(['RE_ISSUE.PROPOSAL_N',
          'RE_ISSUE.SAVED_SUCCESSFULLY']);
        msg.pipe(mergeMap(r => {
          return this.translateService.translateAll(...r);
        })).subscribe(next => {
          this.msgData = next[0] +
            ' ' + st.proposal.contractNumber.proposal + ' ' + next[1];
        }).unsubscribe();
        msgData.msg = this.msgData;
        msgData.showButtonConfirm = false;
        const confirmationModal = this.customModalService.openComponent(ReIssueMsgModalComponent, msgData);
        if( !iscontinueAfterSave ){
          confirmationModal.modal.onClose.subscribe(confirm => {
            this.portalSession.remove(this.activeRoute.id);
          });
        }
      });
    }
  }

  saveAuthorization(authorizationRequest) {
    this.errorService.clearTag(this.TAG_LIEN_NUMBER_ERRORS);
    if (!this.areBidingsValid()) {
      this.notifyErrorMessage(this.TAG_LIEN_NUMBER_ERRORS, 'RE_ISSUE.ERROR_MESSAGE_NUMBER_BOND');
    } else {
      this.stateManager.saveAuthorization().pipe(take(1)).subscribe(st => {
        this.stateManager.goToSaveProposal(authorizationRequest);
      });
    }
  }

  getAssetName(assetInstance) {
    let assetDescription;
    if (assetInstance && assetInstance.description) {
      assetDescription = assetInstance.description.toUpperCase();
    }
    return assetDescription;
  }

  getAsteriskForBindings(index){
    return this.lienMandatoryList[index] ? '*' : '';
  }

  toggleNewBond(index: number) {
    if (this.editMode) {
      // Exit edit mode and clear selected binding
      this.editMode = false;
      this.selectedBinding = null;
    } else {
      this.showNewBond = !this.showNewBond;
      this.showNewBondIndex = index;
      this.expandAssetIndex = index;
    }
  }

  saveNewBinding(formData: any, keyAsset, indexAsset) {
    if (!this.bindings[indexAsset]) {
      this.bindings[indexAsset] = []; // Initialize the array if it's undefined
    }
    if (this.editMode && this.selectedBinding) {
      // Update the selected binding in edit mode
      Object.assign(this.selectedBinding, formData);
      this.selectedBinding = null;
      this.editMode = false;
    } else {
      // Add a new binding to the list
      this.bindings[indexAsset].push(formData);
    }
    this.updateBonds(keyAsset, indexAsset);
    this.showNewBond = false;
    this.showNewBondIndex = -1;
    this.expandAssetIndex = indexAsset;
  }

  private updateBonds(keyAsset: any, indexAsset) {
    const bonds: Bond[] = [];
    this.bindings[indexAsset].forEach(bindlement => {
      const subject = new Subject(bindlement.bodyBinding.objectId, bindlement.bodyBinding.idLatestPhotos, bindlement.bodyBinding.nominative,
        bindlement.bodyBinding.surname, bindlement.bodyBinding.name
      );
      const roleType = new RoleType('5');
      const party = new Party(subject, roleType, bindlement.bodyBinding.idLatestPhotos);
      const attachementType = new AttachmentType('1');
      const bond = new Bond(party, bindlement.description, bindlement.effectDate, bindlement.expiryDate, attachementType);
      // Hide the "New Bond" section and reset the form
      bonds.push(bond);
    });

    const bondData = new BondData(bonds, keyAsset);
    this.stateManager.putBonds(bondData).pipe(take(1)).subscribe(st => {
      this.errorService.clearTag(this.TAG_LIEN_NUMBER_ERRORS);
    });
  }

  editBinding(updatedBinding: any, indexAsset) {
    // Enter edit mode and populate the form with selected binding data
    this.editMode = true;
    this.selectedBinding = updatedBinding;

    this.showNewBond = true; // Show the "New Bond" section in edit mode
    this.showNewBondIndex = indexAsset;
    this.expandAssetIndex = indexAsset;
  }

  deleteBinding(index: number, keyAsset, indexAsset) {
    this.bindings[indexAsset].splice(index, 1);
    this.updateBonds(keyAsset, indexAsset);
  }

  deletNewBinding() {
    this.showNewBond = false;
    this.showNewBondIndex = -1;
    this.newBondForm.reset();
  }


}

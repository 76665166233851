<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()"><span translate>RE_ISSUE.CLAUSE_DETAIL</span>
  </rgi-rx-panel-header>
  <h4 class="clause-title">{{clause.description}}</h4>
  <div *ngIf="clause?.textType === '2' || clause?.textType === '1'">
    <form [formGroup]="clauseTextForm">
      <rgi-rx-form-field>
        <textarea attr.data-qa="{{clause.code}}" rgiRxInput formControlName="text"></textarea>
      </rgi-rx-form-field>
    </form>
  </div>
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" data-qa="close" (click)="cancel()" translate>RE_ISSUE.CLOSE</button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" data-qa="confirm"
            (click)="saveClause()" [disabled]="!clauseTextForm.controls.text.valid" translate>RE_ISSUE.CONFIRM
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>


import {TableSchema} from '@rgi/rx/ui';

export const NORMALIZED_ADDRESSES_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'formatAddress',
      title: '_ANAG_._LABEL_._ADDRESS_'
    },
    {
      name: 'action',
      title: '_ANAG_._LABEL_._VOID_',
      actions: [{
          name: 'SELECT',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-right-arrow'
      }],
      description: '_ANAG_._LABEL_._SELECT_PARTY_'
    }
  ],
  header: ['formatAddress', 'action']
};

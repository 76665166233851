import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {
  RGI_RX_I18N_CONFIG,
  RGI_RX_LOCALE,
  RGI_RX_TRANSLATIONS,
  RgiRxI18nConfig,
  RgiRxI18nError, RgiRxI18nLanguageChangeOpts,
  RgiRxKeyInterpolationRef,
  RgiRxTranslations,
  TranslationService
} from './i18n-api';
import {combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {RgiRxTranslationCatalogService} from './rgi-rx-translation-catalog.service';
import {catchError, distinct, filter, finalize, take} from 'rxjs/operators';
import {RgiRxTranslationLoaderService} from './rgi-rx-translation-loader.service';
import {flatten, LoggerFactory, RgiRxInterpolationParamsType} from '@rgi/rx';
import {DOCUMENT} from '@angular/common';

const SUPPORTS_INTL_API = typeof Intl !== 'undefined';

const languageDescriptions = {
  it: 'italiano',
  en: 'English',
  fr: 'français',
  de: 'Deutsch',
  es: 'español',
};

@Injectable({
  providedIn: 'root'
})
export class RgiRxTranslationService implements TranslationService, OnDestroy {

  private readonly logger = LoggerFactory();
  private readonly _currentLanguage$: Subject<RGI_RX_LOCALE> = new Subject<RGI_RX_LOCALE>();
  private _currentLanguage: RGI_RX_LOCALE;
  private readonly _missingTranslationHit$ = new Subject<{ locale: RGI_RX_LOCALE, key: string, error: RgiRxI18nError}>();
  private translations: RgiRxTranslations;
  private registeredTranslations: RGI_RX_LOCALE[];
  private loaderSubscription = Subscription.EMPTY;
  private missingTranslationSubscription = Subscription.EMPTY;

  constructor(
    private catalog: RgiRxTranslationCatalogService,
    @Inject(RGI_RX_I18N_CONFIG) private config: RgiRxI18nConfig,
    private loader: RgiRxTranslationLoaderService,
    @Inject(DOCUMENT) private document: any | Document,
    @Optional() @Inject(RGI_RX_TRANSLATIONS) private readonly translationTokens: RgiRxTranslations[]) {
    if (!this.translationTokens) {
      this.translations = [];
      return;
    }
    this.translations = flatten<RgiRxTranslations>(this.translationTokens);

    if (config.localesOverride) {
      this.registeredTranslations = config.localesOverride;
    } else if (config.completeLocales === false) {  // if false the registered locales will list all the locales configured in at least one module
      this.registeredTranslations = [...new Set(this.translations.map(t => t.locale))];
    } else {  // else the registered locales will list only the ones with a configured translation in all the modules with RgiRxTranslations
      this.registeredTranslations = this.translationTokens.reduce((acc, b) => {
        acc = acc.filter(x => b.map(t => t.locale).includes(x.locale));
        return acc;
      }).map(t => t.locale);
    }

    if (!!this.config.logMissingTranslations) {
      this.missingTranslationSubscription.unsubscribe();
      this.missingTranslationSubscription = this._missingTranslationHit$
        .pipe(
          distinct(e => e.key),
          filter(miss => this.loader.hasLoaded(miss.locale))
        )
        .subscribe(next => {
          this.logger.debug(next.error.message, next.error.context);
        });
    }

  }

  getLanguageChange$(): Observable<RGI_RX_LOCALE> {
    return this._currentLanguage$.asObservable();
  }

  get currentLanguage(): RGI_RX_LOCALE {
    return this._currentLanguage;
  }

  setCurrentLanguage(locale: RGI_RX_LOCALE, opts : RgiRxI18nLanguageChangeOpts = {force: false}) {
    if (locale !== this._currentLanguage || opts.force) {
      this._currentLanguage = locale;
      this.loaderSubscription.unsubscribe();
      this.loaderSubscription = this.loader.load(this.translations, this._currentLanguage).pipe(
        finalize(() => {
          this._currentLanguage$.next(locale);
        this.document.documentElement.lang = locale;}),
        take(1)
      ).subscribe();
    }
  }

  getLanguageDescription(locale: RGI_RX_LOCALE) {
    if (SUPPORTS_INTL_API) {
      return new Intl.DisplayNames([locale], { type: 'language' }).of(locale);
    } else {
      return languageDescriptions[locale] || '';
    }
  }

  translate(key: string, params?: RgiRxInterpolationParamsType): Observable<string | null> {
    return this.catalog.getKey$(key, this._currentLanguage, {
      interpolation: params
    }).pipe(
      catchError((err) => {
        this.notifyMissingTranslation(key, this._currentLanguage, err);
        return of(null);
      })
    );
  }

  translateAll(...keys: string[]): Observable<string[]>;
  translateAll(...keyParams: RgiRxKeyInterpolationRef[]): Observable<string[]>;
  translateAll(...keys: (string | RgiRxKeyInterpolationRef)[]): Observable<string[]> {
    if (this.isInterpolationParams(keys)) {
      return combineLatest(keys.map(
        key => {
          return this.catalog.getKey$(key.key, this._currentLanguage, {
            interpolation: key.params
          }).pipe(
            catchError((err) => {
              this.notifyMissingTranslation(key.key, this._currentLanguage, err);
              return of(key.key);
            })
          );
        }
      ));
    }
    const keysAsStrings = keys as string[];
    return combineLatest(keysAsStrings.map(
      key => {
        return this.catalog.getKey$(key, this._currentLanguage).pipe(
          catchError((err) => {
            this.notifyMissingTranslation(key, this._currentLanguage, err);
            return of(key);
          })
        );
      }
    ));
  }

  private isInterpolationParams(param: (string | RgiRxKeyInterpolationRef)[]): param is RgiRxKeyInterpolationRef[] {
    return param && param.length > 0 && (param[0] as RgiRxKeyInterpolationRef).key !== undefined;
  }
  getRegisteredTranslations(): RGI_RX_LOCALE[] {
    return [...this.registeredTranslations];
  }

  get missingTranslationHit$(): Observable<{ locale: RGI_RX_LOCALE; key: string }> {
    return this._missingTranslationHit$.asObservable().pipe(filter(t => !!t));
  }

  private notifyMissingTranslation(key: string, locale: RGI_RX_LOCALE, error: RgiRxI18nError) {
    this._missingTranslationHit$.next({
      key,
      locale,
      error
    });
  }

  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
    this.missingTranslationSubscription.unsubscribe();
  }


}

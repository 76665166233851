export enum OperatorCodes {
    AND = 101,
    OR = 102,
    EQUAL= 1,
    NOT_EQUAL= 2,
    LOWER_THAN= 3,
    HIGHER_THAN= 4,
    LOWER_EQUAL= 5,
    HIGHER_EQUAL= 6,
    NOT= 103
}
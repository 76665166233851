import {RgiRxHttpErrorSeverity} from './http-api';


export function rgiRxHttpErrorSeverityFromStatus(status: number): RgiRxHttpErrorSeverity {
  if (status < 300) {
    return RgiRxHttpErrorSeverity.INFO;
  }
  if (status >= 300 && status < 500) {
    return RgiRxHttpErrorSeverity.WARN;
  }
  return RgiRxHttpErrorSeverity.ERROR;
}

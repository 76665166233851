<rgi-rx-panel class="lg-panel" data-qa="discountModal">
  <!-- HEADER -->
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()"><span translate>RE_ISSUE.DISCOUNTS</span>
  </rgi-rx-panel-header>

  <span translate>RE_ISSUE.ANNUAL_GROSS_PREM_BEFORE_CHARGES</span> (€)<br><br><br>

  <!-- UNITS DISCOUNT -->
  <form [formGroup]="discountForm">
    <table rgi-rx-table [dataSource]="warranties" class="layout-fixed" formArrayName="warranties" data-qa="warranties" aria-describedby="warranties">
      <ng-container rgiRxColumnDef="name">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="name"> {{!row.isUnit ? row.sectionName : row.name }} </td>
      </ng-container>
      <ng-container rgiRxColumnDef="annualGross">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.GROSS</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="annualGross"> {{!row.isUnit ? '' : (row.annualGross | currency: currency.code : currency.symbol :'1.2-2':localeId)}} </td>
      </ng-container>
      <ng-container rgiRxColumnDef="flexWithErosionMaxPercent">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef>% max</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="flexWithErosionMaxPercent">
          <div *ngIf="row.discountable">
            {{row.flexWithErosionMaxPercent | number: '1.2-2':localeId}}{{getSymbolPercent(row)}}
          </div>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="flexWithErosionPercent">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.DISCOUNT</span></td>
        <td rgi-rx-cell *rgiRxCellDef="let row let index = index" [formGroupName]="index" data-qa="flexWithErosionPercent">
          <div *ngIf="row.isUnit && isFlexDiscountModeOnSingleUnit() && row.discountable" formGroupName="flexWithErosion">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="percent"
                     class="text-align-right"
                     [ngClass]="{'ng-invalid': !warranties[index].valid[0]}"
                     data-qa="flexWithErosionPercentInput"
                     type="text" reIssueCurrency [negativeAccepted]="true"
                     [disabled] = "!flexDiscountConfig"
                     (change)="clearInputField('flexWithErosion','amount', index);
                     clearInputFieldAndRemoveMessageError('flexWithoutErosion','amount', index, row.assetCode, TAG_DISCOUNTS_WITHOUT_EROSION_ERROR, 3);
                     validateCaseDiscountOnSingleUnit(index, 0, 1, row, row.flexWithErosionMaxPercent, row.flexWithErosionMinPercent,'flexWithErosion','percent', 'amount', 'RE_ISSUE.FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR',TAG_DISCOUNTS_WITH_EROSION_ERROR)">
            </rgi-rx-form-field>
          </div>
          <div *ngIf="(row.isUnit && !isFlexDiscountModeOnSingleUnit()) && row.discountable">
            {{(row.flexDiscounts && row.flexDiscounts.flexWithErosion ? row.flexDiscounts.flexWithErosion.percent : 0) | number: '1.2-2':localeId}}{{getSymbolPercent(row)}}
          </div>
        </td>
      </ng-container>
      <ng-container rgiRxColumnDef="flexWithErosionAmount">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef>€ <span translate>RE_ISSUE.DISCOUNT</span></td>
        <td rgi-rx-cell *rgiRxCellDef="let row let index = index" [formGroupName]="index" data-qa="flexWithErosionAmount">
          <div *ngIf="row.isUnit && isFlexDiscountModeOnSingleUnit() && row.discountable" formGroupName="flexWithErosion">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="amount"
                     class="text-align-right"
                     [ngClass]="{'ng-invalid': !warranties[index].valid[1]}"
                     data-qa="flexWithErosionAmountInput"
                     type="text" reIssueCurrency [negativeAccepted]="true"
                     (change)="clearInputField('flexWithErosion','percent', index);
                              clearInputFieldAndRemoveMessageError('flexWithoutErosion','percent', index, row.assetCode, TAG_DISCOUNTS_WITHOUT_EROSION_ERROR, 2);
                              validateCaseDiscountOnSingleUnit(index, 1, -1, row, row.flexWithErosionMaxPercent, row.flexWithErosionMinPercent, 'flexWithErosion','amount','percent',  'RE_ISSUE.FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR', TAG_DISCOUNTS_WITH_EROSION_ERROR)">
            </rgi-rx-form-field>
          </div>
          <div *ngIf="(row.isUnit && !isFlexDiscountModeOnSingleUnit()) && row.discountable">
            {{(row.flexDiscounts && row.flexDiscounts.flexWithErosion ? row.flexDiscounts.flexWithErosion.amount : 0) | currency: currency.code : currency.symbol : '1.2-2':localeId}}
          </div>
        </td>
      </ng-container>
      <ng-container rgiRxColumnDef="flexWithoutErosionMaxPercent">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef>% max</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="flexWithoutErosionMaxPercent">
          <div *ngIf="row.discountable">
            {{row.flexWithoutErosionMaxPercent | number: '1.2-2':localeId}}{{getSymbolPercent(row)}}
          </div>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="flexWithoutErosionPercent">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.DISCOUNT</span></td>
        <td rgi-rx-cell *rgiRxCellDef="let row let index = index" [formGroupName]="index" data-qa="flexWithoutErosionPercent">
          <div *ngIf="row.isUnit && isFlexDiscountModeOnSingleUnit() && row.discountable" formGroupName="flexWithoutErosion">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="percent"
                     class="text-align-right"
                     [ngClass]="{'ng-invalid': !warranties[index].valid[2]}"
                     data-qa="flexWithoutErosionPercentInput"
                     type="text" reIssueCurrency [negativeAccepted]="true"
                     (change)="clearInputField('flexWithoutErosion','amount', index);
                     clearInputFieldAndRemoveMessageError('flexWithErosion','amount', index, row.assetCode, TAG_DISCOUNTS_WITH_EROSION_ERROR, 1);
                     validateCaseDiscountOnSingleUnit(index, 2, 1, row, row.flexWithoutErosionMaxPercent, row.flexWithoutErosionMinPercent, 'flexWithoutErosion','percent', 'amount', 'RE_ISSUE.FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR', TAG_DISCOUNTS_WITHOUT_EROSION_ERROR)">
            </rgi-rx-form-field>
          </div>
          <div *ngIf="(row.isUnit && !isFlexDiscountModeOnSingleUnit()) && row.discountable">
            {{(row.flexDiscounts && row.flexDiscounts.flexWithoutErosion ? row.flexDiscounts.flexWithoutErosion.percent : 0) | number: '1.2-2':localeId}}{{getSymbolPercent(row)}}
          </div>
        </td>
      </ng-container>
      <ng-container rgiRxColumnDef="flexWithoutErosionAmount">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef>€ <span translate>RE_ISSUE.DISCOUNT</span></td>
        <td rgi-rx-cell *rgiRxCellDef="let row let index = index" [formGroupName]="index" data-qa="flexWithoutErosionAmount">
          <div *ngIf="row.isUnit && isFlexDiscountModeOnSingleUnit() && row.discountable" formGroupName="flexWithoutErosion">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="amount"
                     class="text-align-right"
                     [ngClass]="{'ng-invalid': !warranties[index].valid[3]}"
                     data-qa="flexWithoutErosionAmountInput"
                     type="text" reIssueCurrency [negativeAccepted]="true"
                     (change)="clearInputField('flexWithoutErosion','percent', index);
                     clearInputFieldAndRemoveMessageError('flexWithErosion','percent', index, row.assetCode, TAG_DISCOUNTS_WITH_EROSION_ERROR, 2);
                     validateCaseDiscountOnSingleUnit(index, 3, -1, row, row.flexWithoutErosionMaxPercent, row.flexWithoutErosionMinPercent, 'flexWithoutErosion','amount', 'percent', 'RE_ISSUE.FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR', TAG_DISCOUNTS_WITHOUT_EROSION_ERROR)">
            </rgi-rx-form-field>
          </div>
          <div *ngIf="(row.isUnit && !isFlexDiscountModeOnSingleUnit()) && row.discountable">
            {{(row.flexDiscounts && row.flexDiscounts.flexWithoutErosion ?  row.flexDiscounts.flexWithoutErosion.amount : 0) | currency: currency.code : currency.symbol : '1.2-2':localeId}}
          </div>
        </td>
      </ng-container>

      <!-- SUPER HEADER -->
      <!-- Header row first group -->
      <ng-container rgiRxColumnDef="sh1">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
            style="text-align: center;border: 3px solid white"></th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="text-align: center;border: 3px solid white"
            [attr.colspan]="1" *rgiRxFooterCellDef translate>RE_ISSUE.TOTAL</td>
      </ng-container>

      <!-- Header row second group -->
      <ng-container rgiRxColumnDef="sh2">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
            style="text-align: left;border: 3px solid white; padding-left: 7px" translate>
          RE_ISSUE.PREMIUM
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="text-align: right;border: 3px solid white; padding-right: 14px"
            [attr.colspan]="1" *rgiRxFooterCellDef>
          <span style="text-align: right">{{totalGrossPremium | currency: currency.code : currency.symbol :'1.2-2':localeId}}</span>
        </td>
      </ng-container>

      <!-- Header row third group -->
      <ng-container rgiRxColumnDef="sh3">
        <th rgi-rx-header-cell style="text-align: center;border: 3px solid white" [attr.colspan]="3" *rgiRxHeaderCellDef translate>
          RE_ISSUE.FLEX_DISCOUNT_WITH_EROSION
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="text-align: center;border: 3px solid white"
            [attr.colspan]="3" *rgiRxFooterCellDef>
          <div class="col-12 div-flex">
            <div class="col-4 div-inline-block column-footer-small">
            </div>
            <div class="col-4 div-inline-block div-float">
              <rgi-rx-form-field *ngIf="isFlexDiscountModeOnTotal()">
                <input rgiRxInput [ngModelOptions]="{standalone: true}" [(ngModel)]="percentWithErosion" class="input-padding input-margin text-align-right"
                       type="text" reIssueCurrency [negativeAccepted]="true" data-qa="percentWithErosion"
                       [ngClass]="{'ng-invalid': !inputCaseDiscountOnTotalAreValid[0]}"
                       (change)="amountWithErosion='0.00'; amountWithoutErosion='0.00'; validateFormsCaseDiscountOnTotal();" >
              </rgi-rx-form-field>
            </div>
            <div class="col-4 div-inline-block div-float">
              <rgi-rx-form-field *ngIf="isFlexDiscountModeOnTotal()">
                <input rgiRxInput [ngModelOptions]="{standalone: true}" [(ngModel)]="amountWithErosion" class="input-padding input-margin text-align-right"
                       type="text" reIssueCurrency [negativeAccepted]="true" data-qa="amountWithErosion"
                       [ngClass]="{'ng-invalid': !inputCaseDiscountOnTotalAreValid[1]}"
                       (change)="percentWithErosion='0.00'; percentWithoutErosion='0.00'; validateFormsCaseDiscountOnTotal();">
              </rgi-rx-form-field>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Header row fourth group -->
      <ng-container rgiRxColumnDef="sh4">
        <th rgi-rx-header-cell style="text-align: center;border: 3px solid white" *rgiRxHeaderCellDef [attr.colspan]="3" translate>
          RE_ISSUE.FLEX_DISCOUNT_WITHOUT_EROSION
        </th>
        <td rgi-rx-footer-cell class="rgi-ui-table-header" style="text-align: center;border: 3px solid white"
            [attr.colspan]="3" *rgiRxFooterCellDef>
          <div class="col-12 div-flex">
            <div class="col-4 div-inline-block column-footer-small">
            </div>
            <div class="col-4 div-inline-block div-float">
              <rgi-rx-form-field *ngIf="isFlexDiscountModeOnTotal()">
                <input rgiRxInput [ngModelOptions]="{standalone: true}" [(ngModel)]="percentWithoutErosion" class="input-padding input-margin text-align-right"
                       type="text" reIssueCurrency [negativeAccepted]="true" data-qa="percentWithoutErosion"
                       [ngClass]="{'ng-invalid': !inputCaseDiscountOnTotalAreValid[2]}"
                       (change)="amountWithoutErosion='0.00'; amountWithErosion='0.00'; validateFormsCaseDiscountOnTotal();">
              </rgi-rx-form-field>
            </div>
            <div class="col-4 div-inline-block div-float">
              <rgi-rx-form-field *ngIf="isFlexDiscountModeOnTotal()">
                <input rgiRxInput [ngModelOptions]="{standalone: true}" [(ngModel)]="amountWithoutErosion" class="input-padding input-margin text-align-right"
                       type="text" reIssueCurrency [negativeAccepted]="true" data-qa="amountWithoutErosion"
                       [ngClass]="{'ng-invalid': !inputCaseDiscountOnTotalAreValid[3]}"
                       (change)="percentWithoutErosion='0.00'; percentWithErosion='0.00'; validateFormsCaseDiscountOnTotal();">
              </rgi-rx-form-field>
            </div>
          </div>
        </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedSuperHeader" class="height-row"></tr>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns" class="height-row make-light-grey"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"
          [ngClass]="row.isUnit ? 'make-light-grey' : 'make-light-blue'"
          class="height-row"></tr>
      <tr rgi-rx-footer-row *rgiRxFooterRowDef="displayedSuperHeader" class="height-row"></tr>
    </table>
  </form>

  <rgi-rx-snackbar *ngIf="isFlexDiscountModeOnSingleUnit()"></rgi-rx-snackbar>

  <rgi-rx-snackbar tag="{{TAG_DISCOUNTS_WITH_EROSION_ERROR}}" *ngIf="isFlexDiscountModeOnTotal()"></rgi-rx-snackbar>
  <rgi-rx-snackbar tag="{{TAG_DISCOUNTS_WITHOUT_EROSION_ERROR}}" *ngIf="isFlexDiscountModeOnTotal()"></rgi-rx-snackbar>

  <br>
  <!-- DISCOUNT AMOUNT -->
  <rgi-rx-expansion-panel [expanded]="false" data-qa="discountsAmount" *ngIf="discountsAmountShowed">
    <rgi-rx-expansion-panel-header class="panel-heading">
      <div class="col-sm-12 col-xs-12">
        <span class="panel-title" translate>RE_ISSUE.DISCOUNT_AMOUNT</span>
      </div>
    </rgi-rx-expansion-panel-header>
    <rgi-rx-datatable rgi-rx-qa="discounts-amount-table" qa-ctx="discounts-amount-context"
                      [data]="discountsFund" [schema]="tableSchema"
                      [pageOptions]="pageOptions" [expansionModel]="customExpansionModel"
                      [expansionRow]="rowDetail">
      <ng-template rgiRxDataTableCell="company" let-ctx>
        <div>
          {{ctx.row.companyName}} - {{ctx.row.branchName}}
        </div>
      </ng-template>
      <ng-template rgiRxDataTableCell="shareDiscountAmount" let-ctx>
        <div translate>
          {{ctx.row.shareDiscountAmount ? 'RE_ISSUE.YES' : 'RE_ISSUE.NO'}}
        </div>
      </ng-template>
    </rgi-rx-datatable>
  </rgi-rx-expansion-panel>

  <!-- ROW DETAIL -->
  <ng-template #rowDetail let-rowSelected>
    <!-- TABLE DETAIL -->
    <table rgi-rx-table [dataSource]="rowSelected.details" aria-describedby="discountDetails">

      <ng-container rgiRxColumnDef="period">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.PERIOD</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="period"><b>{{'RE_ISSUE.START' | translate}}</b> {{row.startDate | date:'dd/MM/yyyy'}}   <b>{{'RE_ISSUE.END' | translate}}</b> {{row.endDate | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="amount">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.AMOUNT</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="amount"> {{row.amount | currency: currency.code : currency.symbol :'1.2-2':localeId}} </td>
      </ng-container>
      <ng-container rgiRxColumnDef="residualAmount">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.RESIDUAL_AMOUNT</td>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="residualAmount"> {{row.residualAmount | currency: currency.code : currency.symbol :'1.2-2':localeId}} </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="discountAmountColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: discountAmountColumns;"></tr>
    </table>

  </ng-template>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="recalculateAndSave()" [disabled]="!canRecalculate()" data-qa="recalculate" translate>RE_ISSUE.RECALCULATE_SAVE</button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="clearDiscounts()" data-qa="clearDiscounts" translate>RE_ISSUE.CLEAR_DISCOUNTS</button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" data-qa="close" translate>RE_ISSUE.CLOSE</button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

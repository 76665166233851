

<form [formGroup]="claimsListElasticForm" data-qa="claims-list-elastic-form">
    <!-- filter start -->
    <div class="container-fluid table-white">
        <div class="head-result thDataTable row">
            
            <div class="col-sm-2" (click)="expanded = !expanded">
                <span class="rgifont iconDTleft rgi-plus" [ngClass]="{'rgi-plus': !expanded, 'rgi-minus':expanded}"></span>
                <span class="bold">{{ '_CLAIMS_._FILTERS' | translate }}</span>
            </div>

        </div>
    </div>

    <!-- <div *ngIf="hasResult()" class="col-sm-12" #collapse="ngbCollapse" [(ngbCollapse)]="!expanded"> -->

        <div class="row" #collapse="ngbCollapse" [(ngbCollapse)]="!expanded">
        <!-- <div class="row"> -->
            <div class="col-sm-12">
                <div class="container-fluid table-white">
                    <span>
                        <div class="container-fluid table-white">
                            <div class="row">
                                <div class="col-sm-1">
                                    <span attr.data-qa="invoice-claims-list-filter-claim-number-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}:</span>
                                </div>    
                                <div class="col-sm-2">
                                    <input formControlName="claimNumber" id="claimNumber" type="text" name="claimNumber" attr.data-qa="invoice-claims-list-filter-claim-number-input">
                                </div>    

                                <div class="col-sm-1">
                                    <span attr.data-qa="invoice-claims-list-filter-party-name-label">{{ '_CLAIMS_._PARTY' | translate }}:</span>
                                </div>
                                <div class="col-sm-2">
                                    <input formControlName="partyName" id="partyName" type="text" name="partyName" attr.data-qa="invoice-claims-list-filter-party-name-input">
                                </div>

                                <div class="col-sm-1">
                                    <span attr.data-qa="invoice-claims-list-filter-magic-word-label">{{ '_CLAIMS_._MAGIC_STRING' | translate }}:</span>
                                </div>
                                <div class="col-sm-2">
                                    <input (keyup)="keyupMagicWord()" formControlName="magicWord" id="magicWord" type="text" name="magicWord" attr.data-qa="invoice-claims-list-filter-magic-word-input">
                                    <br>
                                    <!-- <input type="checkbox" [checked]="itemCheckBoxDigit.checked"> -->
                                    
                                    <input
                                    type="checkbox"
                                    [checked]="itemCheckBoxDigit"
                                    (change)="itemCheckBoxDigit = !itemCheckBoxDigit"/>                                    
                                    
                                    Enable keyup
                                </div>

                                <div class="col-sm-2">
                                    <button class="btn btn-warning text-uppercase pull-right" style="width: 100px; height: 26px;" attr.data-qa="invoice-claim-search-find-btn"
                                    id="invoice-update-submit" (click)="clearSearchFields()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
                                    <button class="btn btn-warning text-uppercase pull-right" style="width: 100px; height: 26px;" attr.data-qa="invoice-claim-search-find-btn"
                                    id="invoice-update-submit" (click)="fromListSearch()">{{ '_CLAIMS_._BUTTONS_._SEARCH' | translate }}</button>
                                </div>

                            </div>
                        </div>

                        <!-- <div class="btn-group btn-group-justified">
                            <div class="btn-group">
                                <button class="btn btn-warning text-uppercase pull-right" attr.data-qa="invoice-claim-search-find-btn"
                                    id="invoice-update-submit" (click)="clearSearchFields()">{{ '_CLAIMS_._EMPTY' | translate }}</button>
                            </div>
                            <div class="btn-group">
                                <button class="btn btn-warning text-uppercase pull-right" attr.data-qa="invoice-claim-search-find-btn"
                                    id="invoice-update-submit" (click)="claimsListFilterSearch()">{{ '_CLAIMS_._SEARCH' | translate }}</button>
                            </div>
                        </div> -->

                    </span>
                </div>
            </div>
        </div>
    <!-- </div> -->
<!-- filter end -->
</form>

<br><br>
<!-- <div *ngIf="hasResult()" class="container-fluid table-white"> -->
<!-- <div class="container-fluid table-white"> -->
    <!-- <div class="head-result thDataTable row"> -->
<div>
    <div *ngIf="hasResult()" class="row" style="background-color:cornflowerblue;">
                
        <div align="left" class="col-sm-2">
            <span class="bold expert-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2">
            <span class="bold">{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2">
            <span class="bold">{{ '_CLAIMS_._POLICY_NUMBER' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2">
            <span class="bold">{{ '_CLAIMS_._CLAIMS_HANDLER' | translate }}</span>
        </div>
        <div align="left" class="col-sm-3">
            <span class="bold">{{ '_CLAIMS_._INVOLVED_PARTIES__DAMAGE_CAUSE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1">
            <span class="bold"></span>
        </div>
    
    </div>
</div>

<span *ngFor="let claim of claims; let even = even; let odd = odd; let invoiceIndex = index">
    <div class="container-fluid table-white">

        <div class="row">

            <div class="col-sm-2" (click)="claim.expanded = !claim.expanded">
				<span class="rgifont iconDTleft rgi-plus" [ngClass]="{'rgi-plus': !claim.expanded, 'rgi-minus':claim.expanded}"></span>
                <span class="bold">{{claim.claimNumber}}</span>
            </div>
			<div class="col-sm-2">
                <span>{{claim.occurenceDate | date:'dd/MM/yyyy' }}</span>
            </div>
			<div class="col-sm-2">
				<span>{{claim.policyNumber}}</span>
			</div>
			<div class="col-sm-2">
				<span>{{claim.claimsHandler}}</span>
			</div>
			<div class="col-sm-3">
				<span *ngFor="let party of claim.parties">{{party.name}} {{party.surname}} {{party.title}} - <span *ngFor="let typeOfLoss of party.typeOfLoss">{{typeOfLoss.typeOfLossDescription}}</span></span>
			</div>
			<div class="col-sm-1">
				<span class="bold">
                    <button type="button" class="btn dt-btn pull-right claims-margin-right-top padding-right-5" (click)="goToDetail(claim.claimNumber, '007')"
                        title="{{ '_CLAIMS_._BUTTONS_._EDIT' | translate }}" tooltip-append-to-body="true">
                        <span class="rgifont rgi-chevron-right iconDT modal-icon"></span>
                    </button>
                </span>
            </div>

			<div class="col-xs-12" #collapse="ngbCollapse" [(ngbCollapse)]="!claim.expanded">
                <div class="container-fluid table-white">
                    
                    <div *ngFor="let party of claim.parties">
                        <div class="row">

                            <div class="col-sm-2 padding-left-2">
                                <span><b>{{party.name}} {{party.surname}} {{party.title}}</b></span>
                            </div>

                            <div *ngFor="let typeOfLoss of party.typeOfLoss">
                                <div class="row">
        
                                    <div class="col-sm-2 padding-left-2">
                                        <span><b>{{typeOfLoss.typeOfLossDescription}}</b></span>
                                    </div>
        
                                </div>
        
                            </div>                            

                        </div>

                    </div>

                </div>
            </div>
		</div>

    </div>
</span>

<br>

<!-- <ul>
    <li>Pag. 
        <a *ngFor="let pageElement of arrPage; let even = even; let odd = odd; let invoicePagerIndex = index"
        (click)="goToPage(invoicePagerIndex+1)" class="pointer">
            <span [ngClass]="{ 'pager-current-page': currentPage == invoicePagerIndex+1 }">
                {{invoicePagerIndex + 1}}
            </span>&nbsp;
        </a>&nbsp; 
    </li>&nbsp;
</ul> -->

<span *ngIf="validationMessages && validationMessages.length > 0" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-update-error">
    <span class="rgifont rgi-exclamation-triangle"></span>
    <span *ngFor="let message of validationMessages; let even = even; let odd = odd; let invoiceDetailsIndex = index">
        {{message | translate}}
        <br>
    </span>
</span>

<div class="btn-group btn-group-justified btn-group-justified-claim">

    <div class="btn-group">
        <button (click)="back()" class="btn btn-warning pull-right text-uppercase" translate>BACK</button>
    </div>

</div>

<br>

<div>
    <div class="row">
        <div class="col-sm-4">
            <button class="btn btn-warning text-uppercase pull-left" style="width: 200px; height: 26px;" attr.data-qa="invoice-claim-search-find-btn"
            id="invoice-update-submit" (click)="aggregate('claimsHandler.keyword')">{{ '_CLAIMS_._SETTLEMENT_COUNTERS' | translate }}</button>
            <button class="btn btn-warning text-uppercase pull-left" style="width: 200px; height: 26px;" attr.data-qa="invoice-claim-search-find-btn"
            id="invoice-update-submit" (click)="aggregate('parties.typeOfLoss.typeOfLossDescription.keyword')">{{ '_CLAIMS_._TYPE_OF_LOSS_COUNTERS' | translate }}</button>
        </div>
    </div>
</div>

<!-- <div class="btn-group btn-group-justified btn-group-justified-claim">

    <div class="btn-group">
        <button (click)="aggregateTypeOfLoss()" class="btn btn-warning pull-right text-uppercase" translate>Contatori per causale</button>
    </div>

</div> -->

<!-- <div class="row">
    <div class="col-sm-12">
        <label ng-bind="label">{{ '_CLAIMS_._CONTATORI' | translate }}</label>
        <select class="core-select form-control" id="expert" name="expert"
            attr.data-qa="count-select" #abc>
            <option></option>
            <option value="parties.typeOfLoss.typeOfLossDescription.keyword">Causale</option>
            <option value="parties.typeOfLoss.typeOfLossDescription.keyword">Causale</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>
</div> -->

<div *ngFor="let bucket of buckets">
    <div class="row">

        <div class="col-sm-12 padding-left-2">
            <span><b>{{bucket.key}} - {{bucket.count}}</b></span>
        </div>

    </div>
</div>

import {BehaviorSubject, Observable, of} from 'rxjs';
import {Directive, InjectionToken, OnDestroy} from '@angular/core';
import {RoutableComponent} from '@rgi/rx/router';
import {LoggerFactory} from '@rgi/rx';
import {filter, take, tap} from 'rxjs/operators';

export interface RgiRxStateManagerDestroyOpts {
  /**
   * Destroy all StateStores matching the same StoreKey
   */
  destroyAllMatching: boolean;
}

@Directive({})
export abstract class RgiRxStateManager<T> implements OnDestroy {


  protected readonly state$ = new BehaviorSubject<T>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private readonly __logger = LoggerFactory();

  constructor(private stateStoreHandler: RgiRxStateStoreHandler<T>) {}


  /**
   * @description Return the current cached state from the StateStore if any
   */
  getCached(): T | undefined {
    if (this.stateStoreHandler.has(this.storeKey)) {
      return this.stateStoreHandler.get(this.storeKey) as T;
    }
    return undefined;
  }

  /**
   * @description Return if the state manager has cached data
   */
  hasCache(): boolean {
    return this.stateStoreHandler.has(this.storeKey);
  }

  /**
   * @description Get the state as an observable
   */
  getState$(): Observable<T> {
    return this.state$
        .pipe(
            filter(s => !!s)
        );
  }

  /**
   * @description Get the current state snapshot.
   * This state may be not updated or newer than the stored state.
   * Useful in actions process
   */
  getCurrentState(): T {
    return this.state$.getValue();
  }

  /**
   * @description Get the current state snapshot as observable.
   * @example of(this.getCurrentState)
   * @see getCurrentState
   */
  getCurrentState$(): Observable<T> {
    return of(this.getCurrentState());
  }

  /**
   * @description return the state as an observable like getState$.
   * This method will return also null or undefined states.
   */
  getCurrentStateAsObservable(): Observable<T> {
    return this.state$.asObservable();
  }

  /**
   * @description Emit a new state and set or update the state object into the StateStoreService
   * @param storeState$ an observable of the current state
   */
  updateState$(storeState$: Observable<T>) {
    storeState$.pipe(
        take(1),
        tap(st => {
          if (!this.stateStoreHandler.has(this.storeKey)) {
            this.__logger.debug(`RgiRxStateManager::updateState$ created new store with id ${this.storeKey}`, st);
            this.stateStoreHandler.set(this.storeKey, st);
          } else {
            this.__logger.debug(`RgiRxStateManager::updateState$ updated store with id ${this.storeKey}`, st);
            this.stateStoreHandler.put(st);
          }
          this.state$.next(st);
        })
    ).subscribe();
  }

  /**
   * @description Emit a new state and set or update the state object into the StateStore service by converting the
   * passed state to a new observable.
   * @param storeState the state snapshot tp update
   */
  updateState(storeState: T) {
    return this.updateState$(of(storeState));
  }

  /**
   * @description Dispose the state observable by completing it
   * Useful whe you want to preserve the state cache, but you want to complete the state$ observable from the
   * state manager itself instead of unsubscribe each consumer.
   * @since 0.8.1
   */
  dispose(): void {
    this.state$.complete();
  }

  /**
   * @description Dispose the state observable and delete the stored data if any.
   * Useful when you want to both dispose and clear the state cache.
   * If you only need to complete the observable, use dispose instead.
   * @param opts options to be passed
   * @see RgiRxStateManagerDestroyOpts
   */
  destroy(opts?: RgiRxStateManagerDestroyOpts): void {
    if (!!opts && !!opts.destroyAllMatching) {
      this.stateStoreHandler.destroyAllMatching(this.storeKey);
    } else if (this.stateStoreHandler.has(this.storeKey)) {
      this.stateStoreHandler.destroy(this.storeKey);
    }
    if (!this.state$.closed) {
      this.dispose();
    }
  }

  /**
   * @description Get the store key in use.
   * Overriding this method allows to change the key used by the state manger when working with the StateStoreService.
   * @see RgiRxStateStoreHandler
   */
  abstract get storeKey(): string;


  get stateStore(): RgiRxStateStoreHandler<unknown> {
    return this.stateStoreHandler;
  }

  ngOnDestroy(): void {
    this.dispose();
  }

}

export abstract class RgiRxStateStoreHandler<S> {

  abstract get(key: string): S;

  abstract set(key: string, state: S);

  abstract put(state: S);

  abstract destroy(key: string);

  abstract has(key: string): boolean;

  abstract destroyAll();

  abstract list(): IterableIterator<[string, S]>;

  abstract destroyAllMatching(id: string): void;
}




/**
 * @description The base class of a State representation.
 * Extended states should only allow Serializable fields.
 */
export class State {
  private readonly _$$id: string;

  constructor(id: string) {
    this._$$id = id;
  }

  /**
   * @description Serializable key identifier of the state
   */
  get $$id(): string {
    return this._$$id;
  }
}

/**
 * @description Di RgiRxState configuration type.
 * @see RGI_RX_STATE_CONFIG
 */
export interface RgiRxStateConfig {
  /**
   * whether to enable or disable deepClone on behalf the stateStore when persisting or updating the state
   */
  deepClone: boolean;
}

export const RGI_RX_STATE_DEFAULT_CONFIG: RgiRxStateConfig = {
  deepClone: true
};

/**
 * @description InjectionToken for RgiRxStateConfig
 */
export const RGI_RX_STATE_CONFIG = new InjectionToken<RgiRxStateConfig>('RGI_RX_STATE_CONFIG');



/**
 * @deprecated use RgiRxStateStoreHandler<T>
 * @see RgiRxStateStoreHandler
 */
// @ts-ignore
export interface StateStoreHandler extends RgiRxStateStoreHandler<State> {}

/**
 * @deprecated use RgiRxStateManager
 * @see RgiRxStateManager
 */
export interface StateManager<T> extends RgiRxStateManager<T>{}
// NG2007 requires a decorator when using Angular features
@Directive({})
export abstract class StateManagedComponent<STATE, MANAGER extends RgiRxStateManager<STATE>> extends RoutableComponent implements OnDestroy{
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private readonly __state$: Observable<STATE>;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  protected constructor(private __stateManager: MANAGER) {
    super();
    this.__state$ = this.__stateManager.getState$()
      .pipe(
        tap(st => this.tickHostChange())
      );
  }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.__stateManager.dispose();
  }


  get state$(): Observable<STATE> {
    return this.__state$;
  }

  get stateManager(): MANAGER {
    return this.__stateManager;
  }
}



import { Inject, Injectable } from '@angular/core';
import { DashboardAuthBusinessService } from '@rgi/icd-card';
@Injectable()
export class DashboardAuthPortalService extends DashboardAuthBusinessService {

  constructor(
    @Inject('authService') private authService,
  ) {
    super();
  }

  getNodeId(): string {
    return this.authService.getSalePointId();
  }
}

import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {RgiRxValidators} from './validators';

@Directive({
  selector: 'input[type="file"][rgiRxAcceptFileType],rgi-rx-input-file',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: RgiRxAcceptFileTypeValidatorDirective,
    multi: true
  }]
})
export class RgiRxAcceptFileTypeValidatorDirective implements Validator {

  private _accept: string[];


  @Input('rgiRxAcceptFileType') get accept(): string[] {
    return this._accept;
  }

  set accept(value: string[]) {
    this._accept = value;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return !this.accept ? null : RgiRxValidators.acceptFileType(this.accept)(control);
  }
}

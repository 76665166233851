import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {DetailLabelValues, Fund, GsFundDetail} from '../../../../models/life-detail.model';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../../services/currency-cache.service';

@Component({
  selector: 'lpc-gs-fund-detail',
  templateUrl: './gs-fund-detail.component.html',
  styleUrls: ['./gs-fund-detail.component.css']
})
export class GsFundDetailComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() fund: Fund;
  @Input() gsFund: GsFundDetail;
  @Input() warranty: string;
  @Input() labelValues: DetailLabelValues[];

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    console.log('start componente gestione separata');
  }
}

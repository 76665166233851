import {ApiPremiumDetail} from './api-premium-detail';

export class ApiWarranty {
  constructor(
    public idWarranty: number,
    public description: string,
    public code: string,
    public annual: ApiPremiumDetail,
    public instalment: ApiPremiumDetail,
    public subscriptionInstalment: ApiPremiumDetail
  ) {
  }
}

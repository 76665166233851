import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivityDictionary, ActivityGroup, ActivityUser, ActivityDto} from '@rgi/digital-claims-common-angular';
import { Activity } from '@rgi/digital-claims-common-angular/lib/dto/policy/activity';

@Injectable({
  providedIn: 'root'
})
export class ManualDeadlineService {

  private baseApiUrlV2;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  public getActivityDictionariesByArea(area: string) {
    return this.httpClient.get<ActivityDictionary[]>(this.getActivityDictionariesByAreaUri(area));
  }

  private getActivityDictionariesByAreaUri(area: string) {
    return this.baseApiUrlV2 + '/claims/activities/' + area + '/dictionaries';
  }

  public getActivityGroups() {
    return this.httpClient.get<ActivityGroup[]>(this.getActivityGroupsUri());
  }

  private getActivityGroupsUri() {
    return this.baseApiUrlV2 + '/claims/activities/groups';
  }

  public getActivityUsersByGroup(groupCode: string) {
    return this.httpClient.get<ActivityUser[]>(this.getActivityUsersByGroupUri(groupCode));
  }

  private getActivityUsersByGroupUri(groupCode: string) {
    return this.baseApiUrlV2 + '/claims/activities/reminder/users?groupCode=' + groupCode;
  }

  public saveActivity(activity: ActivityDto) {
    return this.httpClient.post(this.getActivitySaveUri(), activity);
  }

  private getActivitySaveUri() {
    return this.baseApiUrlV2 + '/claims/activities';
  }

}

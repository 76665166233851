import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const EN: RgiRxSerializedI18n = {
  _APP_PLACEHOLDER_: 'Select an app',
  _BACK_BTN_: 'BACK',
  _CANCEL_BTN_: 'CANCEL',
  _CONFIRM_BTN_: 'CONFIRM',
  _COPY_REPORT_BTN_: 'DUPLICATE REPORT',
  _DELETE_REPORT_BTN_: 'DELETE REPORT',
  _ENABLE_INTERACTIONS_: 'Enable Qlik interactions',
  _ENABLE_SELECTIONS_: 'Enable Qlik selections',
  _HIDE_FILTERS_: 'HIDE FILTERS',
  _LINK_REPORT_: 'Link report',
  _MANDATORY_LBL_: 'mandatory',
  _MAX_ROWS_: 'Max rows',
  _MAX_COLS_: 'Max cols',
  _MODAL_CLOSE_: 'Close',
  _MODAL_COPY_REPORT_: 'Report copied successfully!',
  _MODAL_COPY_REPORT_CONFIRM_: 'Are you sure about duplicating this report?',
  _MODAL_DELETE_REPORT_: 'Report deleted successfully!',
  _MODAL_SAVE_REPORT_: 'Report saved successfully!',
  _MODAL_TITLE_: 'SYSTEM MESSAGE',
  _MODAL_UPDATE_REPORT: 'Report updated successfully!',
  _MODE_DELETE_REPORT_CONFIRM_: 'Are you sure about deleting this report?',
  _MODIFY_REPORT_BTN_: 'Modify',
  _NEW_REPORT_: 'Add a report',
  _NEW_REPORT_CODE_: 'Report code',
  _NEW_REPORT_NAME_: 'Report name',
  _NO_CHART_: 'No chart available',
  _OPEN_APP_QLIK_: 'OPEN APP ON QLIK SENSE',
  _QLIK_BTN_: 'SEE ON QLIK',
  _QLIK_LINK_BTN_: 'Go to PASS_ANALYTICS',
  _QLIK_LINK_TAB_: 'Sign in Qlik Sense',
  _QLIK_SERVER_ERROR_: 'Qlik server not reachable',
  _REPORT_SETTINGS_: 'Settings',
  _SAVE_BTN_: 'SAVE',
  _SELECT_APP_: 'Select app',
  _SELECT_CHARTS_ERROR_: 'Select at least one chart',
  _SELECT_CHART_: 'Select chart',
  _SELECT_FILTER_: 'Select filter',
  _SELECT_SHEET_: 'Select sheet',
  _SELECT_STREAM_: 'Select stream',
  _SELECT_THEME_: 'Select theme',
  _SHOW_CHARTS_TITLE_: 'Filters and charts',
  _SHOW_CHARTS_: 'Show charts',
  _SHOW_FILTERS_: 'SHOW FILTERS',
  _UPDATE_REPORT_: 'Update report',
};

export const IT: RgiRxSerializedI18n = {
  _APP_PLACEHOLDER_: 'Seleziona app',
  _BACK_BTN_: 'INDIETRO',
  _CANCEL_BTN_: 'ANNULLA',
  _CONFIRM_BTN_: 'CONFERMA',
  _COPY_REPORT_BTN_: 'DUPLICA REPORT',
  _DELETE_REPORT_BTN_: 'ELIMINA REPORT',
  _ENABLE_INTERACTIONS_: 'Abilita interazioni con Qlik',
  _ENABLE_SELECTIONS_: 'Abilita selezioni con Qlik',
  _HIDE_FILTERS_: 'NASCONDI FILTRI',
  _LINK_REPORT_: 'Associa report',
  _MANDATORY_LBL_: 'obbligatorio',
  _MAX_ROWS_: 'Numero massimo righe',
  _MAX_COLS_: 'Numero massimo colonne',
  _MODAL_CLOSE_: 'Chiudi',
  _MODAL_COPY_REPORT_: 'Report duplicato con successo!',
  _MODAL_COPY_REPORT_CONFIRM_: 'Sei sicuro di voler cancellare questo report?',
  _MODAL_DELETE_REPORT_: 'Report eliminato con successo!',
  _MODAL_SAVE_REPORT_: 'Report salvato con successo!',
  _MODAL_TITLE_: 'MESSAGGIO DI SISTEMA',
  _MODAL_UPDATE_REPORT: 'Report aggiornato con successo',
  _MODE_DELETE_REPORT_CONFIRM_: 'Sei sicuro di voler cancellare questo report?',
  _MODIFY_REPORT_BTN_: 'Modifica',
  _NEW_REPORT_: 'Crea un nuovo report',
  _NEW_REPORT_CODE_: 'Codice report',
  _NEW_REPORT_NAME_: 'Nome report',
  _NO_CHART_: 'Nessun grafico disponibile',
  _OPEN_APP_QLIK_: 'APRI APP SU QLIK SENSE',
  _QLIK_BTN_: 'VEDI SU QLIK',
  _QLIK_LINK_BTN_: 'Vai su PASS_ANALYTICS',
  _QLIK_LINK_TAB_: 'Accedi a Qlik Sense',
  _QLIK_SERVER_ERROR_: 'Server di Qlik non raggiungibile',
  _REPORT_SETTINGS_: 'Impostazioni',
  _SAVE_BTN_: 'SALVA',
  _SELECT_APP_: 'Seleziona app',
  _SELECT_CHARTS_ERROR_: 'Seleziona almeno un grafico',
  _SELECT_CHART_: 'Seleziona grafico',
  _SELECT_FILTER_: 'Seleziona filtro',
  _SELECT_SHEET_: 'Seleziona foglio',
  _SELECT_STREAM_: 'Seleziona stream',
  _SELECT_THEME_: 'Seleziona tema',
  _SHOW_CHARTS_TITLE_: 'Filtri e grafici',
  _SHOW_CHARTS_: 'Mostra grafici',
  _SHOW_FILTERS_: 'MOSTRA FILTRI',
  _UPDATE_REPORT_: 'AGGIORNA REPORT',
};

export const FR: RgiRxSerializedI18n = {
  _APP_PLACEHOLDER_: "Sélectionner l'application",
  _BACK_BTN_: "RETOUR",
  _CANCEL_BTN_: "Annuler",
  _CONFIRM_BTN_: "CONFIRMER",
  _COPY_REPORT_BTN_: "DUPLIQUER LE RAPPORT",
  _DELETE_REPORT_BTN_: "SUPPRIMER LE RAPPORT",
  _ENABLE_INTERACTIONS_: "Activer les interactions",
  _ENABLE_SELECTIONS_: "Activer les sélections",
  _HIDE_FILTERS_: "MASQUER LES FILTRES",
  _LINK_REPORT_: "Lier le rapport",
  _MANDATORY_LBL_: "Obligatoire",
  _MAX_ROWS_: "Nombre maximal de lignes",
  _MAX_COLS_: "Nombre maximal de colonnes",
  _MODAL_CLOSE_: "Fermer",
  _MODAL_COPY_REPORT_: "Rapport dupliqué avec succès !",
  _MODAL_COPY_REPORT_CONFIRM_: "Êtes-vous sûr de vouloir dupliquer ce rapport ?",
  _MODAL_DELETE_REPORT_: "Rapport supprimé avec succès !",
  _MODAL_SAVE_REPORT_: "Rapport enregistré avec succès !",
  _MODAL_TITLE_: "MESSAGE SYSTÈME",
  _MODAL_UPDATE_REPORT: "Rapport mis à jour avec succès !",
  _MODE_DELETE_REPORT_CONFIRM_: "Êtes-vous sûr de vouloir supprimer ce rapport ?",
  _MODIFY_REPORT_BTN_: "Modifier",
  _NEW_REPORT_: "Créer un rapport",
  _NEW_REPORT_CODE_: "Code du rapport",
  _NEW_REPORT_NAME_: "Nom du rapport",
  _NO_CHART_: "Aucun graphique disponible",
  _OPEN_APP_QLIK_: "OUVRIR LES APPLICATIONS SUR QLIK SENSE",
  _QLIK_BTN_: "VOIR SUR QLIK",
  _QLIK_LINK_BTN_: "Aller à Qlik",
  _QLIK_LINK_TAB_: "Accéder à Qlik Sense",
  _QLIK_SERVER_ERROR_: 'Serveur Qlik inaccessible',
  _REPORT_SETTINGS_: "Paramètres",
  _SAVE_BTN_: "Sauvegarder",
  _SELECT_APP_: "Sélectionner l'application",
  _SELECT_CHARTS_ERROR_: "Veuillez sélectionner au moins un graphique",
  _SELECT_CHART_: "Sélectionner un tableau",
  _SELECT_FILTER_: "Sélectionner le filtre",
  _SELECT_SHEET_: "Sélectionner la feuille",
  _SELECT_STREAM_: "Sélectionner le flux",
  _SELECT_THEME_: "Sélectionner le thème",
  _SHOW_CHARTS_TITLE_: "Filtres et graphiques",
  _SHOW_CHARTS_: "Afficher le tableau",
  _SHOW_FILTERS_: "AFFICHER LES FILTRES",
  _UPDATE_REPORT_: "METTRE À JOUR LE RAPPORT"
};

export const ES: RgiRxSerializedI18n = {
  _APP_PLACEHOLDER_: 'Seleccionar una aplicación',
  _BACK_BTN_: 'ATRÁS',
  _CANCEL_BTN_: 'CANCELAR',
  _CONFIRM_BTN_: 'CONFIRMAR',
  _COPY_REPORT_BTN_: 'DUPLICAR INFORME',
  _DELETE_REPORT_BTN_: 'ELIMINAR INFORME',
  _ENABLE_INTERACTIONS_: 'Activar interacciones Qlik',
  _ENABLE_SELECTIONS_: 'Activar selecciones Qlik',
  _HIDE_FILTERS_: 'OCULTAR FILTROS',
  _LINK_REPORT_: 'Vincular informe',
  _MANDATORY_LBL_: 'obligatorio',
  _MAX_ROWS_: 'Máx. filas',
  _MAX_COLS_: 'Máx. columnas',
  _MODAL_CLOSE_: 'Cerrar',
  _MODAL_COPY_REPORT_: 'Informe duplicado con éxito!',
  _MODAL_COPY_REPORT_CONFIRM_: '¿Está seguro de duplicar este informe?',
  _MODAL_DELETE_REPORT_: 'Informe eliminado con éxito!',
  _MODAL_SAVE_REPORT_: 'Informe guardado con éxito!',
  _MODAL_TITLE_: 'MENSAJE DEL SISTEMA',
  _MODAL_UPDATE_REPORT_: 'Informe actualizado con éxito!',
  _MODE_DELETE_REPORT_CONFIRM_: '¿Está seguro de eliminar este informe?',
  _MODIFY_REPORT_BTN_: 'Modificar',
  _NEW_REPORT_: 'Agregar un informe',
  _NEW_REPORT_CODE_: 'Código de informe',
  _NEW_REPORT_NAME_: 'Nombre de informe',
  _NO_CHART_: 'No hay gráfico disponible',
  _OPEN_APP_QLIK_: 'ABRIR APP EN QLIK SENSE',
  _QLIK_BTN_: 'VER EN QLIK',
  _QLIK_LINK_BTN_: 'Ir a PASS_ANALYTICS',
  _QLIK_LINK_TAB_: 'Iniciar sesión en Qlik Sense',
  _QLIK_SERVER_ERROR_: 'Servidor Qlik inalcanzable',
  _REPORT_SETTINGS_: 'Configuración',
  _SAVE_BTN_: 'GUARDAR',
  _SELECT_APP_: 'Seleccionar aplicación',
  _SELECT_CHARTS_ERROR_: 'Seleccione al menos un gráfico',
  _SELECT_CHART_: 'Seleccionar gráfico',
  _SELECT_FILTER_: 'Seleccionar filtro',
  _SELECT_SHEET_: 'Seleccionar hoja',
  _SELECT_STREAM_: 'Seleccionar flujo',
  _SELECT_THEME_: 'Seleccionar tema',
  _SHOW_CHARTS_TITLE_: 'Filtros y gráficos',
  _SHOW_CHARTS_: 'Mostrar gráficos',
  _SHOW_FILTERS_: 'MOSTRAR FILTROS',
  _UPDATE_REPORT_: 'Actualizar informe'
};

export const DE: RgiRxSerializedI18n = {
  _APP_PLACEHOLDER_: 'Wählen Sie eine App',
  _BACK_BTN_: 'ZURÜCK',
  _CANCEL_BTN_: 'ABBRECHEN',
  _CONFIRM_BTN_: 'BESTÄTIGEN',
  _COPY_REPORT_BTN_: 'BERICHT KOPIEREN',
  _DELETE_REPORT_BTN_: 'BERICHT LÖSCHEN',
  _ENABLE_INTERACTIONS_: 'Qlik-Interaktionen aktivieren',
  _ENABLE_SELECTIONS_: 'Qlik-Auswahlen aktivieren',
  _HIDE_FILTERS_: 'FILTER AUSBLENDEN',
  _LINK_REPORT_: 'Bericht verlinken',
  _MANDATORY_LBL_: 'obligatorisch',
  _MAX_ROWS_: 'Maximale Zeilen',
  _MAX_COLS_: 'Maximale Spalten',
  _MODAL_CLOSE_: 'Schließen',
  _MODAL_COPY_REPORT_: 'Bericht erfolgreich kopiert!',
  _MODAL_COPY_REPORT_CONFIRM_: 'Sind Sie sicher, diesen Bericht zu duplizieren?',
  _MODAL_DELETE_REPORT_: 'Bericht erfolgreich gelöscht!',
  _MODAL_SAVE_REPORT_: 'Bericht erfolgreich gespeichert!',
  _MODAL_TITLE_: 'SYSTEMNACHRICHT',
  _MODAL_UPDATE_REPORT_: 'Bericht erfolgreich aktualisiert!',
  _MODE_DELETE_REPORT_CONFIRM_: 'Sind Sie sicher, diesen Bericht zu löschen?',
  _MODIFY_REPORT_BTN_: 'Ändern',
  _NEW_REPORT_: 'Bericht hinzufügen',
  _NEW_REPORT_CODE_: 'Berichtscode',
  _NEW_REPORT_NAME_: 'Berichtsname',
  _NO_CHART_: 'Kein Diagramm verfügbar',
  _OPEN_APP_QLIK_: 'APP IN QLIK SENSE ÖFFNEN',
  _QLIK_BTN_: 'IN QLIK ANZEIGEN',
  _QLIK_LINK_BTN_: 'Zu PASS_ANALYTICS gehen',
  _QLIK_LINK_TAB_: 'Bei Qlik Sense anmelden',
  _QLIK_SERVER_ERROR_: 'Qlik-Server nicht erreichbar',
  _REPORT_SETTINGS_: 'Einstellungen',
  _SAVE_BTN_: 'SPEICHERN',
  _SELECT_APP_: 'App auswählen',
  _SELECT_CHARTS_ERROR_: 'Mindestens ein Diagramm auswählen',
  _SELECT_CHART_: 'Diagramm auswählen',
  _SELECT_FILTER_: 'Filter auswählen',
  _SELECT_SHEET_: 'Blatt auswählen',
  _SELECT_STREAM_: 'Strom auswählen',
  _SELECT_THEME_: 'Thema auswählen',
  _SHOW_CHARTS_TITLE_: 'Filter und Diagramme',
  _SHOW_CHARTS_: 'Diagramme anzeigen',
  _SHOW_FILTERS_: 'FILTER ANZEIGEN',
  _UPDATE_REPORT_: 'Bericht aktualisieren'
};

import { Component, OnInit } from '@angular/core';
import {RgiRxSDKExpandableHost, RgiRxSDKExpandableNode} from '@rgi/rx/sdk';
import {RgiRxSnackbarNotification} from '../rgi-rx-snackbar-types';

@Component({
  selector: 'rgi-rx-snackbar-header',
  templateUrl: './rgi-rx-snackbar-header.component.html',
  providers: [
    {
      provide: RgiRxSDKExpandableNode, useExisting: RgiRxSnackbarHeaderComponent
    }
  ]
})
export class RgiRxSnackbarHeaderComponent extends RgiRxSDKExpandableNode{

  constructor(
    public snackbarNotification: RgiRxSnackbarNotification,
    public expandHost: RgiRxSDKExpandableHost
  ) {
    super();
  }

}

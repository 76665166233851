<rgi-rx-panel class="rgishared-modal-xs">
<rgi-rx-panel-header [closeable]="true" (onClose)="close()">{{data.title}}</rgi-rx-panel-header>
  <rgi-rx-panel-footer>

    <button rgi-rx-button color="primary"
            [disabled]="submitted && !upFileForm?.valid" (click)="confirmUpFile()">
      <span *ngIf="data.bUploadFile" translate>RGI_SHARED_COMPONENTS.LOAD</span>
      <span *ngIf="!data.bUploadFile" translate>RGI_SHARED_COMPONENTS.EDIT</span>
    </button>
  </rgi-rx-panel-footer>
  <form [formGroup]="upFileForm" class="rgi-ui-form-container">
    <div *ngFor="let metadata of data.file.metadataList">
      <div *ngIf="metadata?.visible">
        <label>{{ metadata?.description }}</label>
        <rgi-rx-form-field *ngIf="metadata?.type.code=='S'">
          <input rgiRxInput [formControlName]="metadata?.code" [attr.data-qa]="metadata?.code">
        </rgi-rx-form-field>
        <rgi-rx-form-field *ngIf="metadata?.type.code=='N'">
          <input type="number" rgiRxInput [formControlName]="metadata?.code" [attr.data-qa]="metadata?.code">
        </rgi-rx-form-field>
        <rgi-rx-form-field *ngIf="metadata?.type.code=='D'">
          <label rgiRxLabel>Datepicker (required)</label>
          <rgi-rx-date-picker>
            <input [rgiRxDateTimeInput]="picker1" [formControlName]="metadata?.code" [attr.data-qa]="metadata?.code">
          </rgi-rx-date-picker>
          <rgi-rx-date-time #picker1></rgi-rx-date-time>
        </rgi-rx-form-field>
        <rgi-rx-form-field *ngIf="metadata?.type.code=='L'">
          <select rgiRxNativeSelect [formControlName]="metadata?.code" [attr.data-qa]="metadata?.code">
            <option [value]="-1"></option>
            <option *ngFor="let value of metadata?.listValue" [value]="value.code">{{value.description}}</option>
          </select>
        </rgi-rx-form-field>
        <rgi-rx-form-field *ngIf="metadata?.type.code=='B'">
          <rgi-rx-button-toggle [formControlName]="metadata?.code" [attr.data-qa]="metadata?.code">
            <button rgi-rx-button rgiRxValueOption value="false" translate>
              RGI_SHARED_COMPONENTS.NO
            </button>
            <button rgi-rx-button rgiRxValueOption value="true" translate>
              RGI_SHARED_COMPONENTS.YES
            </button>
          </rgi-rx-button-toggle>
        </rgi-rx-form-field>

      </div>
    </div>
    <div class="" *ngIf="data.bUploadFile">

      <rgi-rx-form-field>
        <label rgiRxLabel >{{'RGI_SHARED_COMPONENTS.FILE' | translate}} ({{data.fileTypes}} - {{(data.maxKBFileSize/1024)}} MB)</label>
        <rgi-rx-input-file formControlName="attachmentFile" data-qa="attachmentFile" required [accept]="fileFormat" (change)="fileProgress($event.target.files)"></rgi-rx-input-file>
      </rgi-rx-form-field>

    </div>
  </form>
  <div id="">
    <pnc-form-message *ngIf="(upFileForm?.invalid && submitted && validationMessages?.length > 0)"
                      type="error" detail="validationError" [messages]="validationMessages"
                      data-qa="action-error-messages" objectType="complex"></pnc-form-message>
  </div>
</rgi-rx-panel>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EditablePanelTitleComponent, PanelService } from '@rgi/digital-claims-common-angular';
import { DocumentCategory } from '../model/document-category';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
import { DocumentTranslatorService } from '../service/document-translator.service';
import { FileUpload } from '../model/file-upload';

@Component({
  selector: 'claims-document-category-panel-title',
  templateUrl: './document-category-panel-title.component.html',
  styleUrls: ['./document-category-panel-title.component.scss']
})
export class DocumentCategoryPanelTitleComponent extends EditablePanelTitleComponent implements OnInit {
  @Input() documentCategory: DocumentCategory;
  @Input() policy?: any;
  @Input() hideUpload: boolean;
  @Output() categoryChange = new EventEmitter<DocumentCategory>();

  constructor(
    panelService: PanelService,
    private modalService: NgbModal,
    private documentTranslator: DocumentTranslatorService
  ) {
    super(panelService);
  }

  isObbligatory() {
    return this.documentCategory && this.documentCategory.mandatory;
  }

  hasDocuments() {
    return this.documentCategory
      && this.documentCategory.documents
      && this.documentCategory.documents.length;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.hasDocuments()) {
      this.setPanelComplete();
    }
  }

  upload(event: any) {
    event.stopPropagation();

    const modalRef = this.modalService.open(DocumentUploadComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.documentCategory = this.documentCategory;
    modalRef.componentInstance.policy = this.policy;
    const that = this;

    modalRef.result.then((result) => {
      if (result) {
        that.addUploadedDocument(result);
      }
    });

    return false;
  }

  private addUploadedDocument(doc: FileUpload) {
    const document = this.documentTranslator.fileUploadToClaimDocument(
      doc,
      this.documentCategory.categoryId,
      this.documentCategory.uuid
    );
    if (!this.documentCategory.documents) {
      this.documentCategory.documents = [];
    }
    this.documentCategory.documents.push(document);
    this.setPanelComplete();
    this.categoryChange.emit(this.documentCategory);
  }
}

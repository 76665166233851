<div data-qa-role="1" class="holder">
  <label class="label">{{label}}</label> <br>

  <ng-container *ngTemplateOutlet="(isEmpty) ? isNotFilled : isFilled"></ng-container>

</div>

<!-- TEMPLATE -->

<ng-template #isNotFilled>
  <input data-qa="1-role-no-value"
    class="drop-container dashedSelect"
    [value]="select"
    (click)="openAnag()"
  >
</ng-template>

<ng-template #isFilled>
  <div class="input-group col-lg-6">
    <input  value="{{fill()}}"
            style="width:100%"
            type="text"
            name="extraInformations_0"
            class="form-control effectiveHolderName"
            readonly=""
            [attr.data-qa-subj]="fill()+'-role-1'"
            data-qa="1-role-value"
    >
    <div class="input-group-btn">
      <button *ngIf="!hideHolderDeleting" [disabled]="disableTrash" type="button" class="btn btn-default" (click)="resetMethod()" data-qa="1-role-button-del">
        <span class="rgifont rgi-trash"></span>
      </button>
      <!--<button *ngIf="!policyService.isFromIDD" type="button" class="btn btn-default" data-qa="1-role-button-info">
        <span class="rgifont rgi-file-text"></span>
      </button>-->
      <button type="button" class="btn btn-default" (click)="editSubject(val.objectId, roleCode)" [attr.data-qa]="'modify-' + roleCode">
        <span class="rgifont rgi-pencil"></span>
      </button>
    </div>
  </div>
</ng-template>

export class Address {
  placeAddress: string;
  number?: string;
  city: string;
  cap: string;
  country: string;
  adminLevel1?: string;
  adminLevel2?: string;
  adminLevel3?: string;
  toponym?: string;
  fraction?: string;
  at?: string;
  adminLevel2Short?: string;
}


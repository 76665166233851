import { Component, Input, Output, OnInit, EventEmitter, Inject, LOCALE_ID } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AddressesService, ElemEnum, Party } from '@rgi/digital-claims-common-angular';
import {
  SearchHospitalComponent
} from '../../../claim-opening/components/claim-opening-details/party-data-panel-content/search-hospital/search-hospital.component';
import { ModalService } from '@rgi/rx/ui';
import { AnagFlowData, AnagSearchOrCreateResolver } from '@rgi/anag';
import { InjuryAsset, InjuryDamageArray, RSPtfAllIdentification, SurrogaAnsw } from '../../dsc-shared-model/dsc-model-injury';
import { DictionaryType } from '../../dsc-shared-model/dsc-model-address';
import { Subject } from '../../dsc-shared-model/dsc-model-subject';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-dsc-injury-asset-detail',
  templateUrl: './dsc-injury-asset-detail.component.html',
  styleUrls: ['./dsc-injury-asset-detail.component.scss']
})

export class DscInjuryAssetDetailComponent implements OnInit {
  @Input() party: Party;
  @Input() IinjuryAsset: InjuryAsset = new InjuryAsset();
  @Input() addAssetForm: UntypedFormGroup;
  @Input() card: any;
  @Input() visibleNominative: boolean = false;
  @Input() injuryCauseList: RSPtfAllIdentification[] = [];
  @Input() injuryNatureList: Map<string, any[]>;
  @Input() injuryLocationList: Map<string, any[]>;
  @Input() professionList: any[];
  @Input() selectedNode: string;
  @Input() activeRouteId: string;
  @Output() dscPhisicalInjury = new EventEmitter<InjuryAsset>();

  selectedCountryER: any;
  disablePlaceER: boolean = true;
  onlyMatching: boolean = true;
  disableSearchProviders: boolean = true;
  checkFatalityDate: boolean = false;
  injuryNatureCombo: RSPtfAllIdentification[] = [];
  injuryLocationCombo: any[] = [];
  surrogaAnsw: SurrogaAnsw[] = [];
  occurrenceDate: Date = new Date();
  countries: DictionaryType[] = [];
  allCounties: DictionaryType[] = [];
  filteredCountries: DictionaryType[] = [];
  disableMultiple: boolean = true;
  disableNotCodeable: boolean = true;
  injuryDamageArray: InjuryDamageArray[] = [];
  doctorSelected: Subject = new Subject();
  selectDataForDoctor: AnagFlowData = new AnagFlowData();
  rootImg = 'assets/img/Body/';
  mappaBody = new Map<string, string>();
  mappaBodyDescr = new Map<string, string>();
  corpoCranio = this.rootImg + '11.png';
  corpoCervicale = this.rootImg + '23.png';
  spallaSX = this.rootImg + '36.png';
  spallaDX = this.rootImg + '35.png';
  corpoSchiena = this.rootImg + '24.png';
  corpoLombi = this.rootImg + '25.png';
  corpoCoccige = this.rootImg + '26.png';
  corpoGambe = this.rootImg + '27corpoGambe.png';
  caviglieSX = this.rootImg + '70.png';
  caviglieDX = this.rootImg + '69.png';
  corpoFronte = this.rootImg + '11corpoFronte.png';
  orecchioDX = this.rootImg + '34.png';
  occhioDX = this.rootImg + '32.png';
  occhioSX = this.rootImg + '31.png';
  orecchioSX = this.rootImg + '33.png';
  corpoFaccia = this.rootImg + '15.png';
  gola = this.rootImg + '16.png';
  toraceAltoDX = this.rootImg + '40.png';
  toraceAltoSX = this.rootImg + '39.png';
  braccioDX = this.rootImg + '42.png';
  toraceCentroOrgani = this.rootImg + '17.png';
  braccioSX = this.rootImg + '41.png';
  gomitoDX = this.rootImg + '44.png';
  organiAddominali = this.rootImg + '18.png';
  gomitoSX = this.rootImg + '43.png';
  panciaAvambDX = this.rootImg + '46.png';
  corpoPancia = this.rootImg + '22.png';
  panciaAvambSX = this.rootImg + '45.png';
  ventreAncaDX = this.rootImg + '62.png';
  corpoVentre = this.rootImg + '21.png';
  ventreAncaSX = this.rootImg + '61.png';
  polsoDX = this.rootImg + '48.png';
  polsoSX = this.rootImg + '47.png';
  corpoInguine = this.rootImg + 'corpoPolsi_centro.png';
  maniDX = this.rootImg + '50.png';
  cosciaDX = this.rootImg + '64.png';
  cosciaSX = this.rootImg + '63.png';
  maniSX = this.rootImg + '49.png';
  ginocchioDX = this.rootImg + '66.png';
  ginocchioSX = this.rootImg + '65.png';
  gambaDX = this.rootImg + '68.png';
  gambaSX = this.rootImg + '67.png';
  piedeDX = this.rootImg + '72.png';
  piedeSX = this.rootImg + '71.png';

  // FORM
  // injuryAssetForm: FormGroup;
  constructor(
    @Inject('authService') private authService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: any,
    private addressService: AddressesService,
    private modalServiceRgi: ModalService,
    public formBuilder: UntypedFormBuilder,
    private anagSearchOrCreateResolver: AnagSearchOrCreateResolver,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    this.inizializzaMappaBody();
    this.selectDataForDoctor = this.getSubjectDropContainerData();

    const obsYes$ = this.rxTranslationsService.translate('_CLAIMS_._YES')
    const obsNo$ = this.rxTranslationsService.translate('_CLAIMS_._NO')

    combineLatest(
      [obsYes$,obsNo$]
    ).subscribe(
      ([yes, no]) => {
        this.surrogaAnsw = [
          { code: 1, description: yes },
          { code: 2, description: no },
        ];
      }
    )
  }
  ngOnInit() {
    this.selectDataForDoctor = this.getSubjectDropContainerData();
    this.doctorSelected.surname = null;
    this.retrieveCountries(this.IinjuryAsset.selectedCountryER);
    this.disableSearchProviders = true;
    this.disablePlaceER = true;
    if (this.IinjuryAsset.selectedCountryER === 'IT') {
      this.disableSearchProviders = false;
    } else if (this.IinjuryAsset.selectedCountryER &&
      this.IinjuryAsset.selectedCountryER !== 'IT') {
      this.disablePlaceER = false;
    }

    this.initInuryAsset();
    this.getAllCountry();
    this.checkHeirForFatalDate();
  }

  initInuryAsset() {
    if (this.IinjuryAsset) {
      /* this.injuryAssetForm.patchValue(this.IinjuryAsset, {emitEvent: false}); */
      this.injuryDamageArray = this.IinjuryAsset.damageArray;
      if (this.IinjuryAsset.descriptionER !== '') {
        this.disableSearchProviders = false;
      }
    } else {
      this.IinjuryAsset = new InjuryAsset();
    }
    this.emitDataDscPhisicalInjury();
  }

  inizializzaMappaBodySoloSel() {
    if (this.mappaBody[11].indexOf('SEL') > 0) {
      this.mappaBody[11] = this.corpoCranio;
    }
    if (this.mappaBody[23].indexOf('SEL') > 0) {
      this.mappaBody[23] = this.corpoCervicale;
    }
    if (this.mappaBody[36].indexOf('SEL') > 0) {
      this.mappaBody[36] = this.spallaSX;
    }
    if (this.mappaBody[35].indexOf('SEL') > 0) {
      this.mappaBody[35] = this.spallaDX;
    }
    if (this.mappaBody[24].indexOf('SEL') > 0) {
      this.mappaBody[24] = this.corpoSchiena;
    }
    if (this.mappaBody[25].indexOf('SEL') > 0) {
      this.mappaBody[25] = this.corpoLombi;
    }
    if (this.mappaBody[26].indexOf('SEL') > 0) {
      this.mappaBody[26] = this.corpoCoccige;
    }
    if (this.mappaBody[70].indexOf('SEL') > 0) {
      this.mappaBody[70] = this.caviglieSX;
    }
    if (this.mappaBody[69].indexOf('SEL') > 0) {
      this.mappaBody[69] = this.caviglieDX;
    }
    if (this.mappaBody[34].indexOf('SEL') > 0) {
      this.mappaBody[34] = this.orecchioDX;
    }
    if (this.mappaBody[32].indexOf('SEL') > 0) {
      this.mappaBody[32] = this.occhioDX;
    }
    if (this.mappaBody[31].indexOf('SEL') > 0) {
      this.mappaBody[31] = this.occhioSX;
    }
    if (this.mappaBody[33].indexOf('SEL') > 0) {
      this.mappaBody[33] = this.orecchioSX;
    }
    if (this.mappaBody[15].indexOf('SEL') > 0) {
      this.mappaBody[15] = this.corpoFaccia;
    }
    if (this.mappaBody[16].indexOf('SEL') > 0) {
      this.mappaBody[16] = this.gola;
    }
    if (this.mappaBody[40].indexOf('SEL') > 0) {
      this.mappaBody[40] = this.toraceAltoDX;
    }
    if (this.mappaBody[39].indexOf('SEL') > 0) {
      this.mappaBody[39] = this.toraceAltoSX;
    }
    if (this.mappaBody[42].indexOf('SEL') > 0) {
      this.mappaBody[42] = this.braccioDX;
    }
    if (this.mappaBody[17].indexOf('SEL') > 0) {
      this.mappaBody[17] = this.toraceCentroOrgani;
    }
    if (this.mappaBody[41].indexOf('SEL') > 0) {
      this.mappaBody[41] = this.braccioSX;
    }
    if (this.mappaBody[44].indexOf('SEL') > 0) {
      this.mappaBody[44] = this.gomitoDX;
    }
    if (this.mappaBody[18].indexOf('SEL') > 0) {
      this.mappaBody[18] = this.organiAddominali;
    }
    if (this.mappaBody[43].indexOf('SEL') > 0) {
      this.mappaBody[43] = this.gomitoSX;
    }
    if (this.mappaBody[46].indexOf('SEL') > 0) {
      this.mappaBody[46] = this.panciaAvambDX;
    }
    if (this.mappaBody[22].indexOf('SEL') > 0) {
      this.mappaBody[22] = this.corpoPancia;
    }
    if (this.mappaBody[45].indexOf('SEL') > 0) {
      this.mappaBody[45] = this.panciaAvambSX;
    }
    if (this.mappaBody[62].indexOf('SEL') > 0) {
      this.mappaBody[62] = this.ventreAncaDX;
    }
    if (this.mappaBody[21].indexOf('SEL') > 0) {
      this.mappaBody[21] = this.corpoVentre;
    }
    if (this.mappaBody[61].indexOf('SEL') > 0) {
      this.mappaBody[61] = this.ventreAncaSX;
    }
    if (this.mappaBody[48].indexOf('SEL') > 0) {
      this.mappaBody[48] = this.polsoDX;
    }
    if (this.mappaBody[47].indexOf('SEL') > 0) {
      this.mappaBody[47] = this.polsoSX;
    }
    if (this.mappaBody[50].indexOf('SEL') > 0) {
      this.mappaBody[50] = this.maniDX;
    }
    if (this.mappaBody[64].indexOf('SEL') > 0) {
      this.mappaBody[64] = this.cosciaDX;
    }
    if (this.mappaBody[63].indexOf('SEL') > 0) {
      this.mappaBody[63] = this.cosciaSX;
    }
    if (this.mappaBody[49].indexOf('SEL') > 0) {
      this.mappaBody[49] = this.maniSX;
    }
    if (this.mappaBody[66].indexOf('SEL') > 0) {
      this.mappaBody[66] = this.ginocchioDX;
    }
    if (this.mappaBody[65].indexOf('SEL') > 0) {
      this.mappaBody[65] = this.ginocchioSX;
    }
    if (this.mappaBody[68].indexOf('SEL') > 0) {
      this.mappaBody[68] = this.gambaDX;
    }
    if (this.mappaBody[67].indexOf('SEL') > 0) {
      this.mappaBody[67] = this.gambaSX;
    }
    if (this.mappaBody[72].indexOf('SEL') > 0) {
      this.mappaBody[72] = this.piedeDX;
    }
    if (this.mappaBody[71].indexOf('SEL') > 0) {
      this.mappaBody[71] = this.piedeSX;
    }
  }

  inizializzaMappaBody() {
    this.mappaBody[11] = this.corpoCranio;
    this.mappaBody[23] = this.corpoCervicale;
    this.mappaBody[36] = this.spallaSX;
    this.mappaBody[35] = this.spallaDX;
    this.mappaBody[24] = this.corpoSchiena;
    this.mappaBody[25] = this.corpoLombi;
    this.mappaBody[26] = this.corpoCoccige;
    this.mappaBody[70] = this.caviglieSX;
    this.mappaBody[69] = this.caviglieDX;
    this.mappaBody[34] = this.orecchioDX;
    this.mappaBody[32] = this.occhioDX;
    this.mappaBody[31] = this.occhioSX;
    this.mappaBody[33] = this.orecchioSX;
    this.mappaBody[15] = this.corpoFaccia;
    this.mappaBody[16] = this.gola;
    this.mappaBody[40] = this.toraceAltoDX;
    this.mappaBody[39] = this.toraceAltoSX;
    this.mappaBody[42] = this.braccioDX;
    this.mappaBody[17] = this.toraceCentroOrgani;
    this.mappaBody[41] = this.braccioSX;
    this.mappaBody[44] = this.gomitoDX;
    this.mappaBody[18] = this.organiAddominali;
    this.mappaBody[43] = this.gomitoSX;
    this.mappaBody[46] = this.panciaAvambDX;
    this.mappaBody[22] = this.corpoPancia;
    this.mappaBody[45] = this.panciaAvambSX;
    this.mappaBody[62] = this.ventreAncaDX;
    this.mappaBody[21] = this.corpoVentre;
    this.mappaBody[61] = this.ventreAncaSX;
    this.mappaBody[48] = this.polsoDX;
    this.mappaBody[47] = this.polsoSX;
    this.mappaBody[50] = this.maniDX;
    this.mappaBody[64] = this.cosciaDX;
    this.mappaBody[63] = this.cosciaSX;
    this.mappaBody[49] = this.maniSX;
    this.mappaBody[66] = this.ginocchioDX;
    this.mappaBody[65] = this.ginocchioSX;
    this.mappaBody[68] = this.gambaDX;
    this.mappaBody[67] = this.gambaSX;
    this.mappaBody[72] = this.piedeDX;
    this.mappaBody[71] = this.piedeSX;

    // Translations

    this.mappaBodyDescr[11] = '_CLAIMS_._SKULL';
    this.mappaBodyDescr[23] = '_CLAIMS_._SPINE_CERVICAL_COLUMN';
    this.mappaBodyDescr[36] = '_CLAIMS_._LEFT_SHOULDER';
    this.mappaBodyDescr[35] = '_CLAIMS_._RIGHT_SHOULDER';
    this.mappaBodyDescr[24] = '_CLAIMS_._SPINE_THORACIC_COLUMN';
    this.mappaBodyDescr[25] = '_CLAIMS_._SPINE_LUMBAR_COLUMN';
    this.mappaBodyDescr[26] = '_CLAIMS_._SPINE_SACRUS_AND_COCCYX';
    this.mappaBodyDescr[70] = '_CLAIMS_._LEFT_ANKLE';
    this.mappaBodyDescr[69] = '_CLAIMS_._RIGHT_ANKLE';
    this.mappaBodyDescr[34] = '_CLAIMS_._RIGHT_EAR';
    this.mappaBodyDescr[32] = '_CLAIMS_._RIGHT_EYE';
    this.mappaBodyDescr[31] = '_CLAIMS_._LEFT_EYE';
    this.mappaBodyDescr[33] = '_CLAIMS_._LEFT_EAR';
    this.mappaBodyDescr[15] = '_CLAIMS_._FACE';
    this.mappaBodyDescr[16] = '_CLAIMS_._NECK';
    this.mappaBodyDescr[40] = '_CLAIMS_._RIGHT_CHEST_WALL';
    this.mappaBodyDescr[39] = '_CLAIMS_._LEFT_CHEST_WALL';
    this.mappaBodyDescr[42] = '_CLAIMS_._RIGHT_ARM';
    this.mappaBodyDescr[17] = '_CLAIMS_._THORACIC_ORGANS';
    this.mappaBodyDescr[41] = '_CLAIMS_._LEFT_ARM';
    this.mappaBodyDescr[44] = '_CLAIMS_._RIGHT_ELBOW';
    this.mappaBodyDescr[18] = '_CLAIMS_._ABDOMINAL_ORGANS';
    this.mappaBodyDescr[43] = '_CLAIMS_._LEFT_ELBOW';
    this.mappaBodyDescr[46] = '_CLAIMS_._RIGHT_FOREARM';
    this.mappaBodyDescr[22] = '_CLAIMS_._ABDOMINAL_WALL';
    this.mappaBodyDescr[45] = '_CLAIMS_._LEFT_FOREARM';
    this.mappaBodyDescr[62] = '_CLAIMS_._PELVIS___RIGHT_HIP';
    this.mappaBodyDescr[21] = '_CLAIMS_._UROGENITAL_ORGANS';
    this.mappaBodyDescr[61] = '_CLAIMS_._PELVIS___LEFT_HIP';
    this.mappaBodyDescr[48] = '_CLAIMS_._RIGHT_WRIST';
    this.mappaBodyDescr[47] = '_CLAIMS_._LEFT_WRIST';
    this.mappaBodyDescr[50] = '_CLAIMS_._RIGHT_HAND';
    this.mappaBodyDescr[64] = '_CLAIMS_._RIGHT_THIGH';
    this.mappaBodyDescr[63] = '_CLAIMS_._LEFT_THIGH';
    this.mappaBodyDescr[49] = '_CLAIMS_._LEFT_HAND';
    this.mappaBodyDescr[66] = '_CLAIMS_._RIGHT_KNEE';
    this.mappaBodyDescr[65] = '_CLAIMS_._LEFT_KNEE';
    this.mappaBodyDescr[68] = '_CLAIMS_._RIGHT_LEG';
    this.mappaBodyDescr[67] = '_CLAIMS_._LEFT_LEG';
    this.mappaBodyDescr[72] = '_CLAIMS_._RIGHT_FOOT';
    this.mappaBodyDescr[71] = '_CLAIMS_._LEFT_FOOT';
  }

  inizializzaOnChangeNature(injuryLocationList: Map<string, any[]>, injuryNature: string) {
    this.mappaBody[11] = this.corpoCranio;
    this.mappaBody[23] = this.corpoCervicale;
    this.mappaBody[36] = this.spallaSX;
    this.mappaBody[35] = this.spallaDX;
    this.mappaBody[24] = this.corpoSchiena;
    this.mappaBody[25] = this.corpoLombi;
    this.mappaBody[26] = this.corpoCoccige;
    this.mappaBody[70] = this.caviglieSX;
    this.mappaBody[69] = this.caviglieDX;
    this.mappaBody[34] = this.orecchioDX;
    this.mappaBody[32] = this.occhioDX;
    this.mappaBody[31] = this.occhioSX;
    this.mappaBody[33] = this.orecchioSX;
    this.mappaBody[15] = this.corpoFaccia;
    this.mappaBody[16] = this.gola;
    this.mappaBody[40] = this.toraceAltoDX;
    this.mappaBody[39] = this.toraceAltoSX;
    this.mappaBody[42] = this.braccioDX;
    this.mappaBody[17] = this.toraceCentroOrgani;
    this.mappaBody[41] = this.braccioSX;
    this.mappaBody[44] = this.gomitoDX;
    this.mappaBody[18] = this.organiAddominali;
    this.mappaBody[43] = this.gomitoSX;
    this.mappaBody[46] = this.panciaAvambDX;
    this.mappaBody[22] = this.corpoPancia;
    this.mappaBody[45] = this.panciaAvambSX;
    this.mappaBody[62] = this.ventreAncaDX;
    this.mappaBody[21] = this.corpoVentre;
    this.mappaBody[61] = this.ventreAncaSX;
    this.mappaBody[48] = this.polsoDX;
    this.mappaBody[47] = this.polsoSX;
    this.mappaBody[50] = this.maniDX;
    this.mappaBody[64] = this.cosciaDX;
    this.mappaBody[63] = this.cosciaSX;
    this.mappaBody[49] = this.maniSX;
    this.mappaBody[66] = this.ginocchioDX;
    this.mappaBody[65] = this.ginocchioSX;
    this.mappaBody[68] = this.gambaDX;
    this.mappaBody[67] = this.gambaSX;
    this.mappaBody[72] = this.piedeDX;
    this.mappaBody[71] = this.piedeSX;


    const injuryLocationCombo = injuryLocationList[this.IinjuryAsset.injuryNature]
      .map(({ code, description }) => ({ code, description }));
    const injuryLocationCode = injuryLocationCombo.map(i => i.code);
    injuryLocationCode.forEach(location => {
      if (injuryLocationCode.includes(location) && this.mappaBody[location]) {
        this.mappaBody[location] = this.mappaBody[location].replace('.png', '_SEL.png');
        console.log(this.injuryDamageArray, injuryNature);
        if (this.injuryDamageArray.length > 0) {
          const selectedLocation = this.injuryDamageArray.find(el => el.bodilyLocation === location && el.injuryType === injuryNature);
          if (!!selectedLocation) {
            this.mappaBody[location] = this.mappaBody[location].replace('_SEL.png', '_IJR.png');
          }
        }
      }
    });
  }

  addVisualDamage(cod: string) {
    if (this.injuryLocationCombo && this.injuryLocationCombo.length > 0) {
      this.IinjuryAsset.injuryPlace = cod;
      if (this.IinjuryAsset.injuryPlace) {
        const bodilyLocation = this.injuryLocationCombo
          .find(el => el.code === this.IinjuryAsset.injuryPlace);
        const path: string = this.mappaBody[cod];

        if (path && path.indexOf('SEL') > 0) {
          this.mappaBody[cod] = path.replace('_SEL.png', '_IJR.png');
          const injuryDamage: InjuryDamageArray = {
            bodilyLocation: bodilyLocation.code,
            bodilyLocationDescr: bodilyLocation.description,
            injuryType: this.IinjuryAsset.injuryNature,
            injuryTypeDescr: this.injuryNatureCombo.find(res => res.code === this.IinjuryAsset.injuryNature).description
          };
          this.injuryDamageArray.push(injuryDamage);
        } else if (path && path.indexOf('_IJR') > 0) {
          this.mappaBody[cod] = path.replace('_IJR.png', '_SEL.png');
          const findIndex = this.injuryDamageArray.findIndex(damage => damage.bodilyLocation === cod
            && damage.injuryType === this.IinjuryAsset.injuryNature);
          this.injuryDamageArray.splice(findIndex, 1);
        }
        if (cod === '77') {
          this.disableMultiple = true;
        }

        if (cod === '99') {
          this.disableNotCodeable = true;
        }
      }
    }
    this.emitDataDscPhisicalInjury();
  }

  chargeNature() {
    this.injuryLocationCombo = [];
    /*  this.injuryAssetForm.controls.injuryPlace.setValue('');
     this.injuryAssetForm.controls.injuryNature.setValue(''); */
    this.inizializzaMappaBody();
    /* this.injuryNatureCombo = this.injuryNatureList[this.IinjuryAsset.injuryCause]
    .map((el) => { return { code: el.code, description: el.description } }); */
    this.injuryNatureCombo = this.injuryNatureList[this.IinjuryAsset.injuryCause]
      .map(({ code, description }) => ({ code, description }));
    /* this.injuryAssetForm.controls.notCodeable.setValue(false);
    this.injuryAssetForm.controls.multiple.setValue(false); */

    this.IinjuryAsset.notCodeable = false;
    this.IinjuryAsset.multiple = false;
    this.disableNotCodeable = true;
    this.disableMultiple = true;
    this.injuryDamageArray = [];
    this.emitDataDscPhisicalInjury();
  }

  chargeLocations() {
    // this.injuryAssetForm.controls.injuryPlace.setValue('');
    this.IinjuryAsset.injuryPlace = '';
    if (this.injuryLocationList[this.IinjuryAsset.injuryNature]) {
      this.injuryLocationCombo = this.injuryLocationList[this.IinjuryAsset.injuryNature]
        .filter(injLoc => !this.injuryDamageArray.some(aDam =>
          injLoc.code === aDam.bodilyLocation && aDam.injuryType === this.IinjuryAsset.injuryNature
        )).map(({ code, description }) => ({ code, description }));
      this.inizializzaOnChangeNature(this.injuryLocationList, this.IinjuryAsset.injuryNature);
      this.disableNotCodeable = true;
      this.disableMultiple = true;
      if (this.injuryLocationCombo.find(el => el.code === '99') &&
        !this.IinjuryAsset.notCodeable) {
        // non codificabile
        this.disableNotCodeable = false;
        this.IinjuryAsset.notCodeable = false;
      }
      if (this.injuryLocationCombo.find(el => el.code === '77' &&
        !this.IinjuryAsset.multiple)) {
        // multiple
        this.disableMultiple = false;
        this.IinjuryAsset.multiple = false;
      }
    }
    this.emitDataDscPhisicalInjury();
  }


  removeDamage(code: string, index: number) {
    const path: string = this.mappaBody[code];

    if (path) {
      this.mappaBody[code] = path.replace('_IJR.png', '_SEL.png');
    }

    this.mappaBody[code] = path.replace('_IJR.png', '_SEL.png');
    const findIndex = this.injuryDamageArray.findIndex(damage => damage.bodilyLocation === code
      && damage.injuryType === this.IinjuryAsset.injuryNature);
    this.injuryDamageArray.splice(findIndex, 1);
    if (code === '77') {
      this.disableMultiple = true;
    }

    if (code === '99') {
      this.disableNotCodeable = true;
    }
    this.emitDataDscPhisicalInjury();
  }

  enableProviders(event?: any) {
    this.disableSearchProviders = true;
    this.disablePlaceER = true;
    if (this.selectedCountryER.id === 'IT') {
      this.disableSearchProviders = false;
    } else if (this.selectedCountryER && this.selectedCountryER.id !== 'IT') {
      this.disablePlaceER = false;
    }
    this.IinjuryAsset.selectedCountryER = this.selectedCountryER.id;
    this.emitDataDscPhisicalInjury();
  }

  searchHealthcareProviders(event: any) {
    const form = this.formBuilder.group({
      percIP: [null, Validators.nullValidator],
      doctorName: ['', Validators.nullValidator],
      doctorNameRgi: [null],
      doctorIdSogg: ['', Validators.nullValidator],
      doctorIdSoggPA: ['', Validators.nullValidator],
      fatalDate: [null, Validators.nullValidator],
      selectedCountryER: ['', Validators.nullValidator],
      placeER: ['', Validators.nullValidator],
      idER: ['', Validators.nullValidator],
      descriptionER: ['', Validators.nullValidator],
      surroga: ['', Validators.nullValidator],
      biologicalDamage: [false, Validators.nullValidator],
      ggITB: ['', Validators.nullValidator],
      injuryCause: ['', Validators.nullValidator],
      injuryNature: ['', Validators.nullValidator],
      injuryPlace: ['', Validators.nullValidator],
      profession: ['', Validators.nullValidator],
      moreInjury: [false, Validators.nullValidator],
      multiple: [false, Validators.nullValidator],
      notCodeable: [false, Validators.nullValidator]
    });
    form.patchValue(this.IinjuryAsset, { emitEvent: false });
    this.modalServiceRgi.open(SearchHospitalComponent,
      {
        addAssetForm: form
      }).onClose.subscribe(res => {
        // onclose modal
        if (res) {
          this.IinjuryAsset.descriptionER = res.element.description;
          this.IinjuryAsset.idER = res.element.idObject;
          this.emitDataDscPhisicalInjury();
        }

      });

    /* const modalRef = this.modalService.open(SearchHospitalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.injuryAssetForm = this.injuryAssetForm;
    modalRef.result.then((result) => {
      if (result) {
        console.log('openSearchHospital ->', result);
      }
    }); */

  }

  onNgModelChange() {
    /* console.log()
    if (this.injuryAssetForm.value.injuryCause !== '') {
      this.injuryNatureCombo = this.injuryNatureList[this.injuryAssetForm.controls.injuryCause.value.code]
      .map((el) => { return { code: el.code, description: el.description } });
    } */
    this.checkHeirForFatalDate();
    this.emitDataDscPhisicalInjury();
  }

  checkHeirForFatalDate(){
    this.IinjuryAsset.fatalDate ? this.checkFatalityDate = true : this.checkFatalityDate = false;
    return this.checkFatalityDate;
  }

  getCounty(event) {
    this.filteredCountries = this.allCounties.filter((res: DictionaryType) =>
      res.value.toString().toLowerCase().includes(event.toLowerCase()));
    this.IinjuryAsset.selectedCountryER = this.filteredCountries[0].value;
    this.disableSearchProviders = false;
    this.emitDataDscPhisicalInjury();
  }

  retrieveCountries(selectedCountry?: string) {
    this.addressService.getCountries(new Date()).subscribe(
      (response: ElemEnum[]) => {
        for (const element of response) {
          this.countries.push({ id: element.codice, value: element.descrizione });
          if (!this.selectedCountryER && (selectedCountry === element.codice)) {
            this.selectedCountryER = { id: element.codice, value: element.descrizione };
          }
        }
      });
  }

  onCompleteCountry(event: string) {
    if (event.length < 3) {
      this.filteredCountries = this.allCounties;
    } else {
      this.filteredCountries = this.allCounties.filter((res: DictionaryType) =>
        res.value.toString().toLowerCase().includes(event.toLowerCase()));
    }
    this.countries = this.filteredCountries;
  }

  getAllCountry() {
    this.addressService.getCountries(this.occurrenceDate).subscribe(
      (response: ElemEnum[]) => {
        if (response.length > 0) {
          response.forEach((element: ElemEnum) => {
            this.allCounties.push({ id: element.codice, value: element.descrizione });
          });
        } else {
          this.allCounties = [];
        }
      });
  }

  emitDataDscPhisicalInjury() {
    this.IinjuryAsset.doctorSelected = this.doctorSelected ? this.doctorSelected : undefined;
    this.IinjuryAsset.damageArray = this.injuryDamageArray;
    this.dscPhisicalInjury.emit(this.IinjuryAsset);
  }

  protected setOwnerField() {
    let ownerField = null;
    ownerField = {
      ...this.doctorSelected
    };
    return ownerField;
  }

  getSubjectDropContainerData() {
    const data = new AnagFlowData();
    data.nodeCode = this.selectedNode;
    data.idParentSession = this.activeRouteId;
    return data;
  }

  onPartySelected(event: any) {
    this.doctorSelected = event.changed;
    this.IinjuryAsset.idDoctor = this.doctorSelected.idLatestPhotos;
    this.IinjuryAsset.descriptionDoctor = this.doctorSelected.nominative;
    // this.injuryAssetForm.controls.doctorName.setValue(this.injuryAssetForm.controls.doctorNameRgi.value);
    this.emitDataDscPhisicalInjury();
  }
  
  deleteDoctor() {
    this.IinjuryAsset.descriptionDoctor = '';
    this.IinjuryAsset.idDoctor = '';
    this.IinjuryAsset.doctorSelected = null;
    this.doctorSelected = null;
    this.emitDataDscPhisicalInjury();
  }

  searchDoctor() {
    const anagFlowData = new AnagFlowData();
    anagFlowData.nodeCode = this.selectedNode;
    anagFlowData.idParentSession = this.activeRouteId;
    anagFlowData.partyRole='888';
    this.anagSearchOrCreateResolver.searchOrCreateSubject(anagFlowData).subscribe(res => {
      this.IinjuryAsset.idDoctor = res.idLatestPhotos;
      this.IinjuryAsset.descriptionDoctor = res.nominative;
      this.IinjuryAsset.doctorSelected = res;
      this.doctorSelected = res;
    });
  }

  ngOnDestroy() {
    this.IinjuryAsset = new InjuryAsset();
    this.injuryDamageArray = this.IinjuryAsset.damageArray;
    this.emitDataDscPhisicalInjury();
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComboType {
  descrizione: string;
  codice: string;
}
export interface OutputCompoList {
    comboTypeOfAuthorityInvolved: ComboType[];
    comboAntifraudCategory: ComboType[];
    comboCatastrophicEventDescription: ComboType[];
}

export interface OutputInvolvedAuthorities {
  involvedAuthorities: involvedAuthoritie[];
}

export interface involvedAuthoritie {
  codeAuthority: string;
  descriptionAuthority: string;
}
export interface RequestBodyAD {
  potentialClaimNumber: string;
  claimNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class DscAdditionalDataService {

  private baseApiUrl: string;
  private baseApi: string;
  private urlServiceDscAdditionalData = '/claims/setAdditionalDataCombo';
  private urlinvolvedauthorities = '/claims/involvedauthorities'

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrl = environment.api.portal.path + '/v2';
    this.baseApi =  environment.api.portal.path;
  }

  setAdditionalDataCombo(requestBody: RequestBodyAD): Observable<OutputCompoList> {
    return this.httpClient.post<OutputCompoList>(this.baseApiUrl + this.urlServiceDscAdditionalData, requestBody);
  }

  setComboTypeOfAuthorityInvolved(): Observable<OutputInvolvedAuthorities> {
    return this.httpClient.get<OutputInvolvedAuthorities>(this.baseApi + this.urlinvolvedauthorities);
  }

}

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PartiesService} from '../parties.service';
import {ProposalService} from '../../proposal.service';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {EventNotificator} from '../../event-notificator';
import {PartiesData} from '../../models/domain-models/parties/parties-data';
import {RolesTypes} from '../../models/domain-models/parties/roles-types';
import {Role} from '../../models/domain-models/parties/role';
import {CustomModalService} from '../../custom-modal.service';
import {QuotationService} from '../../quotation/quotation.service';
import {Message} from '../../models/message';
import {TranslateService} from '@ngx-translate/core';
import {Modal} from '../../modal';
import {QuestionnaireDnService} from '../questionnairedn.service';
import {ApiSurveyRequest} from '../../models/api-models/api-survey-filter';
import {DatePipe} from '@angular/common';
import {catchError, concatMap, finalize, take, tap} from 'rxjs/operators';
import {
  SubstEnumPolicyholderRetention
} from '../../models/domain-models/substitution/enum/substitution-policyholderretention';
import {ANAG_FUNCTIONS, AnagFlowData} from '@rgi/anag';
import {MicIntegrationService} from '../../mic-integration.service';
import {UserAuthorizationService} from '@rgi/rx/auth';
import { ModalService } from '@rgi/portal-ng-core';
import { AmendmentService } from '../../amendment/amendment.service';


@Component({
  selector: 'mic-parties',
  templateUrl: './parties.component.html',
  styleUrls: ['./parties.component.scss']
})
export class PartiesComponent implements OnInit, OnDestroy, EventNotificator {

  partiesControlForm: UntypedFormGroup;
  parties: PartiesData;
  roleTypesConstants = RolesTypes;
  validationMessages: Message[] = [];
  nonBlockingMessages: Message[] = [];
  areaCode = 'ROLES';
  roleToChange: any;
  partyToChange: any;
  subscriberChange = false;
  rolesOk = true;
  lesseeOk = true;
  isPartyMngEnabled = true;
  keepSubscriber = false;
  anagPartyEditEnabled = false;
  isQuote = false;
  offsetAlternativeRole = 0;
  isPartyDeletable = true;

  @Input() substConfListener: Observable<any>;
  @Input() changeAssetListener: Observable<any>;
  @Input() showQuotationModal = true;
  @Input() disableOffset: string = null;
  @Input() skipQuotation = false;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() subscriberChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() subscriberRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() subscriberPresentCheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() editQuotation: EventEmitter<any> = new EventEmitter<any>();
  @Output() validationMessagesRoles: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  @Output() nonBlockingMessagesRoles: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  @Output() valuesChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('quotationComparisonModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) quotationComparisonModalOverlay: ViewContainerRef;

  subscriptions: Subscription = new Subscription();
  quotationComparisonModalComponent: Type<Modal>;
  anagPortalAjsFlow;

  constructor(
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected partiesService: PartiesService,
    protected proposalService: ProposalService,
    protected partiesServices: PartiesService,
    protected customModalService: CustomModalService,
    protected quotationService: QuotationService,
    protected questionnaireDnService: QuestionnaireDnService,
    protected datePipe: DatePipe,
    protected integrationService: MicIntegrationService,
    protected modalService: ModalService,
    protected userAuthorizationService: UserAuthorizationService,
    @Inject('quotationComparisonModalComponent') quotationComparisonModalComponent: Type<Modal>,
    @Inject('RGI_ANAG_PORTAL_AJS_FLOW') anagPortalAjsFlow: boolean,
    protected amendmentService: AmendmentService,
  ) {
    this.quotationComparisonModalComponent = quotationComparisonModalComponent;
    this.anagPortalAjsFlow = anagPortalAjsFlow;
  }

  async ngOnInit() {
    this.partiesControlForm = this.formBuilder.group(
      {
        subscriber: ['', Validators.required],
        leasingInstitute: [false],
        ownerEqualsSubscriber: [false],
        driverEqualsSubscriber: [false]
      }
    );

    this.offsetAlternativeRole = await this.partiesService.offsetAlternativeRole();

    let apiContract = this.proposalService.getApiContract();
    this.isQuote = apiContract.contractNumber === null || apiContract.contractNumber.proposalNumber === null;

    this.initCheckQuestionnairesParties();
    this.initCheckProposal();
    this.initParty();

    // For partyRole change
    this.manageUpdateParties();

    const isSubscriberEnabled = this.proposalService.isFieldEnabled('policyholder');
    if (isSubscriberEnabled === null) {
      this.isPartyMngEnabled = true;
    } else {
      this.isPartyMngEnabled = isSubscriberEnabled;
      if (!isSubscriberEnabled) {
        this.partiesControlForm.get('subscriber').disable();
      }
    }

    if (this.substConfListener) {
      const updateSubstConfigSubscription = this.substConfListener.subscribe((event) => {
        this.manageSubstitutionConfig();
      });
      this.subscriptions.add(updateSubstConfigSubscription);
    }
    if (this.changeAssetListener) {
      const changeAssetSubscription = this.changeAssetListener.subscribe((event) => {
        this.initParty();
      });
      this.subscriptions.add(changeAssetSubscription);
    }

    this.anagPartyEditEnabled = this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.edit);

    if(this.amendmentService.flowOriginAction?.operation.code==="MOTVAR"){
      this.isPartyDeletable = false;
    }
  }

  initParty() {
    this.partiesService.getPartiesOffset(this.proposalService.getContractId(), this.disableOffset).subscribe(data => {
      this.parties = data;
      this.subscriberPresentCheck.emit(this.parties.subscriber !== null);
      this.updateUntitled();

      if (this.questionnaireDnService.enabledIdd) {
        this.questionnaireDnService.startQuestionnairesDn(false);
      } else {
        this.partiesService.startCheckProposal(false);
      }

      this.partiesControlForm.controls.leasingInstitute.setValue(this.parties.leasingInstitution);
      this.checkLesseeParty();
      this.updateRolesEqualsSubscriberFlags();
      this.updateMessages();
    });

  }

  addRoleParty(newParty) {
    const myRole = this.getRoleData(this.roleToChange.code);
    // New partyRole
    if (!myRole.partyRoles) {
      myRole.partyRoles = [];
    }
    myRole.partyRoles.push({
      main: false,
      roleId: null,
      party: newParty
    });
  }

  replaceRoleParty(previousParty: any, newParty: any, roleCode: string) {
    const myRole = this.getRoleData(roleCode);
    // Change partyRole
    const myParty = myRole.partyRoles.find(partyRole => {
      return partyRole.party.partyId === previousParty.partyId;
    });
    if (myParty && myRole.partyRoles) {
      const index = myRole.partyRoles.indexOf(myParty);
      myRole.partyRoles[index].party = newParty;
    }
  }

  replaceSubscriberParty(newParty) {
    this.parties.subscriber = newParty;
  }

  async doManageParties(newParty: any) {
    const partyHasBeenEdited = (this.partyToChange?.partyId === newParty?.partyId && this.partyToChange?.idLatestPhotos !== newParty?.idLatestPhotos);
    const subscriberHasBeenEdited = this.subscriberChange && newParty?.partyId == this.parties.subscriber?.partyId;
    if (newParty?.counterTerrorismStatus?.code === '3' && (partyHasBeenEdited || subscriberHasBeenEdited)) {
      this.removeAllBlockedSubjects(newParty);
      const errorMsg = this.translate.instant('Warning! The subject {{nominative}} is blocked for antiterrorism and will be removed', { nominative: newParty.nominative });
      this.modalService.open([errorMsg], 'Error');
      this.updateParties();
    } else if (this.roleToChange) {
      const myRole = this.getRoleData(this.roleToChange.code);
      if (myRole) {
        if (this.getPartySelectedForRoleData(myRole, newParty.partyId) && !partyHasBeenEdited) {
          this.updateMessages(true);
          return;
        }
        if (this.partyToChange && myRole.partyRoles) {
          this.replaceRoleParty(this.partyToChange, newParty, this.roleToChange.code);
        } else {
          this.addRoleParty(newParty);
        }
        this.checkLesseeParty(newParty);
        if (this.rolesOk === true || newParty && (newParty.counterTerrorismStatus?.code === '3')) {
          await this.updateParties(newParty);
        } else {
          this.updateMessages();
        }
      }
      this.roleToChange = null;
      this.partyToChange = null;
    } else if (this.subscriberChange === true) {
      // Subscriber
      this.replaceSubscriberParty(newParty);
      this.questionnaireDnService.changeSubscriber = true;
      await this.updateParties(newParty);
    }
  }

  removeAllBlockedSubjects(newParty: any) {
    if (this.parties.subscriber?.partyId === newParty.partyId) {
      this.cleanSubscriber(true);
    }
    for (const role of this.parties.roles) {
      for (const partyRole of role.partyRoles) {
        if (partyRole.party.partyId === newParty.partyId) {
          this.cleanPartyRole(role, newParty, true);
        }
      }
    }
  }

  cleanSubscriber(blockedForTerrorism?: boolean) {
    if (!this.keepSubscriber) {
      this.parties.subscriber = null;
      this.partiesControlForm.patchValue({
        subscriber: null
      });
      this.subscriberRemoved.emit();
      this.cleanRoleEqualsSubscriberFlags();
      this.disableRoleEqualsSubscriberCheckboxes();
      blockedForTerrorism ? null : this.updateParties();
    }
  }

  searchSubscriber() {
    if (this.isPartyMngEnabled && !this.keepSubscriber && this.parties.subscriberDeletable) {
      this.roleToChange = null;
      this.partyToChange = null;
      this.subscriberChange = true;

      if (this.anagPortalAjsFlow) {
        this.openAnagAJSModal();
      } else {
        let role = new Role();
        role.id = '1';
        role.code = '1';
        this.partiesService.openAnagModal(this.createAnagRouteData(role));
      }
    }
  }

  addRole(role) {
    if (role) {
      this.roleToChange = role;
      this.partyToChange = null;
      if (this.anagPortalAjsFlow) {
        this.openAnagAJSModal();
      } else {
        this.partiesService.openAnagModal(this.createAnagRouteData(role));
      }
    }
  }

  cleanPartyRole(role, partyToDelete, blockedForTerrorism?: boolean) {
    const myRole = this.parties.roles.find(currentRole => {
      return currentRole.id === role.id;
    });

    if (myRole) {
      const myParty = myRole.partyRoles.find(partyRole => {
        return partyRole.party.partyId === partyToDelete.partyId;
      });

      if (myParty) {
        const index = myRole.partyRoles.indexOf(myParty);
        myRole.partyRoles.splice(index, 1);
      }
    }

    this.checkLesseeParty();
    if (!blockedForTerrorism) {
      if (this.rolesOk === true) {
        this.updateParties();
      } else {
        this.updateMessages();
      }
    }
  }

  searchPartyRole(role, party) {
    if (role && party) {
      const roleChanged = role.partyRoles.find(pa => pa.party.idLatestPhotos === party.idLatestPhotos);
      if (this.isPartyMngEnabled && roleChanged.deletable) { // todo add better role management
        this.roleToChange = role;
        this.partyToChange = party;
        if (this.anagPortalAjsFlow) {
          this.openAnagAJSModal();
        } else {
          this.partiesServices.openAnagModal(this.createAnagRouteData(role));
        }
      }
    }
  }

  createAnagRouteData(role: Role): AnagFlowData {
    let apiContract = this.proposalService.getApiContract();
    const flowData = new AnagFlowData();
    const code = role?.id ? role.id : '999';
    let codeInNumber = +code;
    flowData.partyRole = this.isQuote && code !== '999' ? '' + (this.offsetAlternativeRole + codeInNumber) : code;
    flowData.isQuote = this.isQuote;
    /* subscriber is the default */
    flowData.partyType = role && role.id === '6' ? '1' : undefined; // if the role is driver then the personType must be physical
    flowData.nodeCode = apiContract.nodeCode;
    return flowData;
  }

  createAnagRouteDataNoOffset(role: Role): AnagFlowData {
    let anagRouteData = this.createAnagRouteData(role);
    anagRouteData.partyRole = role?.id;
    return anagRouteData;
  }

  openAnagAJSModal() {
    this.eventPropagation.emit({
      eventName: 'requireSubject'
    });
  }

  changeLeasingInstitute() {
    const leasingInstituteControl = this.partiesControlForm.controls.leasingInstitute;
    leasingInstituteControl.setValue(!leasingInstituteControl.value);

    this.checkLesseeParty();
    if (this.rolesOk === true) {
      this.updateParties();
    }
  }

  recalculatePremium() {
    if (!this.proposalService.isSubstitution && !this.skipQuotation) {
      this.quotationService.retrieveEntitledPremium().subscribe(
        (data) => {
          this.quotationService.setEntitledPremium(data);
          if (this.showQuotationModal) {
            const quotationModal = this.customModalService.openModal(this.quotationComparisonModalOverlay,
              this.quotationComparisonModalComponent, this.eventPropagation, () => {
                if (quotationModal.instance.goEditQuotation) {
                  this.editQuotation.emit();
                }
                this.partiesService.setLastUpdateChangedPartyResidence(false);
              });
          }
        }
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * propagate event to AngularCommunicationPassProProCtrl
   * @param subject : subject
   * @param listSurvey: questionnaires filled in by subject
   * @param codeProduct: code product selected
   */
  startSurvey(subject: any, listSurvey: any, codeProduct: string) {
    // const result = JSON.parse(JSON.stringify(this.party));
    const result = {subject, surveys: listSurvey, codeProduct};

    this.eventPropagation.emit({
      eventName: 'startNewSurvey',
      result
    });
  }

  /**
   * propagate event to AngularCommunicationPassProProCtrl
   * @param subject : subject
   * @param listSurvey: questionnaires filled in by subject
   * @param codeProduct: code product selected
   * @param lastVersion: survey last version
   */
  startEditSurvey(subject: any, listSurvey: any, codeProduct: string, lastVersion: any) {
    // const result = JSON.parse(JSON.stringify(this.party));
    const result = {subject, surveys: listSurvey, codeProduct, version: lastVersion};

    this.eventPropagation.emit({
      eventName: 'startEditSurvey',
      result
    });
  }

  manageSubstitutionConfig() {
    const config = this.proposalService.getSubstitutionConfig();
    if (config.POLICYHOLDER_RETENTION === SubstEnumPolicyholderRetention.POLICYHOLDER_RETENTION_YES) {
      this.keepSubscriber = true;
      this.partiesControlForm.get('subscriber').disable();
    }
  }

  selectMainParty(roleCode: string, selectedPartyRole: any) {
    const selectedRole = this.parties.roles.find((role: Role) => {
      return role.code === roleCode;
    });
    selectedRole.partyRoles.forEach(partyRole => partyRole.main = partyRole.party.partyId === selectedPartyRole.party.partyId);
    this.updateParties();
  }

  protected manageUpdateParties() {
    const updatePartySubscription = this.partiesServices.getPartyObserable().subscribe(
      (newParty: any) => {
        if (newParty) {
          let party = newParty;
          if (Array.isArray(newParty)) {
            party = newParty[0];
          }
          const roleId = this.roleToChange ? this.roleToChange.id : null;
          this.partiesServices.getParty(this.proposalService.getContractId(), party.partyId, party.idLatestPhotos, roleId)
            .subscribe(
              data => {
                this.doManageParties(data);
              });
        }
      }
    );
    this.subscriptions.add(updatePartySubscription);
  }

  protected async updateParties(newParty?: any) {

    const roleToChange = this.roleToChange;
    // Update leasing institute
    this.parties.leasingInstitution = this.partiesControlForm.controls.leasingInstitute.value;

    // Update the main party in role
    this.updateMainPartyRole();
    const oldEntitledId = this.parties.entitledId;
    const oldEntitledIdLatestSnapshot = this.parties.entitledIdLatestShapshot;
    const oldTariffPartyId = this.parties.tariffPartyId;
    const oldTariffPartyIdLatestShapshot = this.parties.tariffPartyIdLatestShapshot;

    // Update the party roles
    if (newParty) {
      await this.replacePartiesWithNewPartyIfSamePartyId(newParty);
    }
    this.partiesServices.updateParties(this.proposalService.getContractId(), this.parties)
      .pipe(catchError((err: any) => {
        if (err.status === 422 && newParty?.counterTerrorismStatus?.code === '3') {
          this.modalService.open(err.error.messages, 'Error');
          this.removeNewParty(newParty, roleToChange);
          return throwError(err);
        } else if (err.status < 500) {
          if (err.error && err.error.messages && Array.isArray(err.error.messages)) {
            this.updateMessages();
            err.error.messages.forEach((msg: string) => {
              this.validationMessages.push(new Message(this.areaCode, msg));
            });
            this.validationMessagesRoles.emit(this.validationMessages);
            return of(null);
          } else {
            return throwError(err);
          }
        }
      }))
      .subscribe(data => {
        if (data) {
          if (this.subscriberChange) {
            this.subscriberChanged.emit();
            this.subscriberChange = false;
            this.enableRoleEqualsSubscriberCheckboxes();
            this.proposalService.setProposalState(true);
          }

          const newEntitledId = data.entitledId;
          const newEntitledIdLatestSnapshot = data.entitledIdLatestShapshot;
          const newTariffPartyId = data.tariffPartyId;
          const newTariffPartyIdLatestShapshot = data.tariffPartyIdLatestShapshot;

          const entitledChanged = (newEntitledId && newEntitledId !== oldEntitledId)
            || (newEntitledIdLatestSnapshot && newEntitledIdLatestSnapshot !== oldEntitledIdLatestSnapshot);

          const tariffPartyChanged = (newTariffPartyId && newTariffPartyId !== oldTariffPartyId)
            || (newTariffPartyIdLatestShapshot && newTariffPartyIdLatestShapshot !== oldTariffPartyIdLatestShapshot);

          if (this.questionnaireDnService.enabledIdd === true && this.questionnaireDnService.changeSubscriber === true) {
            this.questionnaireDnService.changeSubscriber = false;
            this.partiesService.setSurveyFilledOut(this.questionnaireDnService.buildSurveyFilledOut(null, null, false));
            const bRecalculatePremium: boolean = entitledChanged || tariffPartyChanged;
            this.questionnaireDnService.startQuestionnairesDn(bRecalculatePremium);
          } else {
            if (entitledChanged || tariffPartyChanged) {
              this.recalculatePremium();
              this.updateUntitled(data.entitledId);
            }
          }

          this.valuesChanged.emit();

          this.parties = data;

          this.updateRolesEqualsSubscriberFlags();
          this.checkLesseeParty();
          this.updateMessages();
        }
      });
  }

  removeNewParty(newParty: any, roleToChange: Role) {
    if (roleToChange) {
      this.cleanPartyRole(roleToChange, newParty);
    } else {
      this.cleanSubscriber();
    }
  }

  protected updateMessages(duplicatedPartyRole?: boolean) {
    this.validationMessages.length = 0;
    this.nonBlockingMessages.length = 0;
    if (duplicatedPartyRole) {
      this.nonBlockingMessages.push(new Message(this.areaCode, 'Party already present'));
    }

    if (!this.parties.subscriber) {
      this.validationMessages.push(new Message(this.areaCode, 'Subscriber is mandatory'));
    }

    this.parties.roles.forEach((role: Role) => {
      if (role.min > 0 && (!role.partyRoles || role.min > role.partyRoles.length)) {
        const msg = this.translate.instant('Enter at least {{value}} parties with the role:', {value: role.min})
          + ' ' + role.description;
        this.validationMessages.push(new Message(this.areaCode, msg));
      }
    });

    if (this.lesseeOk === false) {
      this.validationMessages.push(new Message(this.areaCode, 'Select a legal entity as the main owner'));
    }

    this.validationMessagesRoles.emit(this.validationMessages);
    this.nonBlockingMessagesRoles.emit(this.nonBlockingMessages);
  }

  protected checkLesseeParty(party?:any) {
    if (party?.counterTerrorismStatus?.code === '3') {
      return;
    }
    this.rolesOk = true;
    this.lesseeOk = true;

    const lesseeRole = this.getLesseeRole();
    const ownerRole = this.getOwnerRole();
    const mainOwner = this.getMainOwner();

    if (lesseeRole && lesseeRole.partyRoles && lesseeRole.partyRoles.length > 0) {
      this.updateOwnersForLessee(ownerRole, mainOwner);
    }
  }

  protected updateOwnersForLessee(ownerRole, mainOwner) {
    // The main party must be an leasing institute
    this.partiesControlForm.controls.leasingInstitute.setValue(true);

    // Only if the owner is mandatory
    if (ownerRole && ownerRole.min > 0) {
      if (mainOwner) {
        if (mainOwner.party.personType && mainOwner.party.personType.code !== '2') {
          ownerRole.partyRoles.length = 0;
          this.rolesOk = false;
          this.lesseeOk = false;
        }
      } else {
        this.rolesOk = false;
        this.lesseeOk = false;
      }
    }
  }

  protected updateUntitled(entitledId?: string) {
    if (!entitledId) {
      entitledId = this.parties.entitledId;
    }
    // necessario in ripresa della proposta in modifica perchè non si vedeil riquadro dell'avente diritto
    this.parties.roles.forEach((role: Role) => {
      if (role.partyRoles && role.partyRoles.length) {
        for (const item of role.partyRoles) {
          if (item.party.partyId === entitledId) {
            this.partiesService.setEntitledParty(item.party);
            // necessario lanciare l'emit per aggiornare l'avente diritto su proposal.component
            const onlyEntitled = true;
            this.subscriberChanged.emit(onlyEntitled);
            break;
          }
        }
      }
    });
  }

  private initCheckQuestionnairesParties() {

    const checkPartiesSubscription = this.questionnaireDnService.getStartQuestionnairesDnObservable().subscribe(
      (data) => {
        this.checkQuestionnairesDn(data);
      }
    );
    this.subscriptions.add(checkPartiesSubscription);
  }

  private initCheckProposal() {

    const checkPrososalSubscription = this.partiesServices.getStartCheckProposalObservable().subscribe(
      (forceRecalculatePremium) => {
        if (this.partiesServices.isProposedEntitledChanged()) {
          this.partiesServices.resetProposedEntitled();
          this.recalculatePremium();
        } else if (this.partiesService.hasLastUpdateChangedPartyResidence()) {
          this.recalculatePremium();
        } else if (forceRecalculatePremium === true) {
          this.recalculatePremium();
        }
      }
    );
    this.subscriptions.add(checkPrososalSubscription);
  }

  private getLesseeRole() {
    return this.parties.roles.find((role: Role) => {
      return role.code === this.roleTypesConstants.ROLE_LESSEE;
    });
  }

  private getOwnerRole() {
    return this.parties.roles.find((role: Role) => {
      return role.code === this.roleTypesConstants.ROLE_OWNER;
    });
  }

  private getMainOwner() {
    const ownerRole = this.getOwnerRole();

    // Only if the owner is mandatory
    if (ownerRole.partyRoles.length > 0) {
      let mainOwner = ownerRole.partyRoles.find(party => party.main === true);

      if (!mainOwner) {
        mainOwner = ownerRole.partyRoles[0];
      }

      return mainOwner;
    }
  }

  private checkQuestionnairesDn(recalculatePremium) {

    if (this.parties && this.parties.subscriber) {
      this.questionnaireDnService.recalculatePremium = recalculatePremium;
      const idSubject = this.parties.subscriber.partyId;
      const idNode = this.parties.subscriber.node.id;
      const codeProduct = this.proposalService.getProduct().code;

      this.questionnaireDnService.getSubject(idSubject, idNode).subscribe((data) => {

        if (data.subject !== null) {

          if (this.questionnaireDnService.isQuestionnaireVerified(idSubject, codeProduct)) {

            this.partiesService.setSurveyFilledOut(this.questionnaireDnService.buildSurveyFilledOut(data.subject, codeProduct, true));

          } else {

            const apiSurveyRequest: ApiSurveyRequest = this.questionnaireDnService.createApiSurveyRequest(
              (data.subject.iddQuestionnaires ? data.subject.iddQuestionnaires : []));
            const dataQuestionnaire = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
            let listSur = null;
            let lastSurvey = null;

            this.questionnaireDnService.getQuestionnairesInfo(apiSurveyRequest, dataQuestionnaire, codeProduct)
              .pipe(
                tap(results => listSur = this.questionnaireDnService.getSurveyListFromResults(results)),
                concatMap(results => this.questionnaireDnService.getSurveyVersionFromSurveyList(results)),
                tap(results => lastSurvey = this.questionnaireDnService.getLastVersion(results)),
                concatMap(results => this.questionnaireDnService.getEvaluateSurveyVersionFromSurveyVersion(results, codeProduct)),
                catchError(err => throwError(err))
              ).subscribe(
              results => {
                if (results && results.isSuccess) {
                  const listRes = (results.result && results.result.length > 0 ? results.result : []);
                  const listFitting = listRes.filter(
                    survey => {
                      if (survey.code === codeProduct && survey.score === 1) {
                        return survey;
                      }
                    });

                  if (listFitting && listFitting.length > 0) {
                    this.partiesService.setSurveyFilledOut(
                      this.questionnaireDnService.buildSurveyFilledOut(data.subject, codeProduct, true));
                  } else {
                    // open survey
                    this.startEditSurvey(data.subject, listSur, codeProduct, lastSurvey);
                  }

                } else {
                  this.startSurvey(data.subject, listSur, codeProduct);
                }

              }
            );
          }
        }
      });

    }

  }

  private updateMainPartyRole() {
    this.parties.roles.forEach((role: Role) => {
      // TODO gestire anche il caso in cui ho più soggetti
      if (role.partyRoles && role.partyRoles.length === 1) {
        role.partyRoles[0].main = true;
      }
    });
  }

  private replacePartiesWithNewPartyIfSamePartyId(newParty): Promise<void> {
    return new Promise(async resolve => {
      // Update Subscriber
      if (newParty && this.parties.subscriber && this.parties.subscriber.partyId === newParty.partyId && this.roleToChange !== null) {
        await this.getPartyForReplacement(newParty, null, null);
      }
      if (newParty) {
        for (const role of this.parties.roles) {
          if (role.partyRoles && (!this.roleToChange || this.roleToChange.id !== role.id)) {
            for (const partyRole of role.partyRoles) {
              if (partyRole.party.partyId === newParty.partyId) {
                await this.getPartyForReplacement(newParty, role.id, role.code);
              }
            }
          }
        }
      }
      resolve();
    });
  }

  async getPartyForReplacement(party: any, roleId: string, roleCode: string): Promise<void> {
    return new Promise(resolve => {
      this.partiesServices.getParty(
        this.proposalService.getContractId(), party.partyId, party.idLatestPhotos, roleId
      ).pipe(take(1), finalize(() => {
        resolve();
      }))
        .subscribe(newParty => roleId ? this.replaceRoleParty(newParty, newParty, roleCode) : this.replaceSubscriberParty(newParty));
    });
  }

  goToEditParty(party: any, role?: Role) {
    if (role) {
      this.roleToChange = role;
      this.partyToChange = party;
      this.subscriberChange = false;
    } else {
      this.roleToChange = null;
      this.partyToChange = null;
      this.subscriberChange = true;
    }
    if (this.anagPortalAjsFlow) {
      const partyToChange = JSON.parse(JSON.stringify(party));
      delete partyToChange.complete;

      this.eventPropagation.emit({
        eventName: 'updateParty',
        parent: 'anagCardManage',
        route: 'home',
        title: 'Edit subject',
        party: partyToChange
      });
    } else {
      this.partiesServices.getPartyAnag(party.partyId, party.idLatestPhotos).subscribe(partyResp => {
        this.partiesServices.goToEditParty(partyResp.subject, this.createAnagFlowData(party, role)).subscribe(updParty => {
          party = updParty;
        });
      });
    }
  }

  createAnagFlowData(party: any, role?: Role): AnagFlowData {
    const flowData = new AnagFlowData();
    const code = role?.id ? role.id : '1';
    let codeInNumber = +code;
    flowData.isQuote = this.isQuote;
    if (this.isQuote && code !== '999' && !this.disableOffset) {
      flowData.partyRole = '' + (this.offsetAlternativeRole + codeInNumber);
    } else {
      flowData.partyRole = code;
    }
    return flowData;
  }

  enableRoleEqualsSubscriberCheckboxes(): void {
    this.partiesControlForm.controls.ownerEqualsSubscriber.enable();
    this.partiesControlForm.controls.driverEqualsSubscriber.enable();
  }

  disableRoleEqualsSubscriberCheckboxes(): void {
    this.partiesControlForm.controls.ownerEqualsSubscriber.disable();
    this.partiesControlForm.controls.driverEqualsSubscriber.disable();
  }

  cleanRoleEqualsSubscriberFlags(): void {
    this.partiesControlForm.controls.ownerEqualsSubscriber.setValue(false);
    this.partiesControlForm.controls.driverEqualsSubscriber.setValue(false);
  }

  updateRolesEqualsSubscriberFlags(): void {
    if (this.parties && !this.parties.subscriber) {
      this.cleanRoleEqualsSubscriberFlags();
      this.disableRoleEqualsSubscriberCheckboxes();
    } else {
      const subscriberPartyId = this.parties.subscriber.partyId;
      this.setRoleEqualsSubscriberFlags(subscriberPartyId);
    }
  }

  setRoleEqualsSubscriberFlags(subscriberPartyId: string): void {
    [this.roleTypesConstants.ROLE_OWNER, this.roleTypesConstants.ROLE_DRIVER].forEach(
      roleCode => {
        const roleData = this.getRoleData(roleCode);
        const partyEqualsSubscriberSelectedForRoleData = this.getPartySelectedForRoleData(roleData, subscriberPartyId);
        const isRolePartyEqualsSubscriber =
          partyEqualsSubscriberSelectedForRoleData !== null && partyEqualsSubscriberSelectedForRoleData !== undefined;
        const control = this.getRoleEqualsSubscriberCheckboxFormControl(roleCode);
        control.setValue(isRolePartyEqualsSubscriber);
      }
    );
  }

  getRoleData(roleCode: string): Role {
    return this.parties && this.parties.roles ? this.parties.roles.filter(r => r.code === roleCode)[0] || null : null;
  }

  getPartySelectedForRoleData(role: Role, partyId: string): any {
    return role.partyRoles ? role.partyRoles.filter(p => p.party.partyId === partyId)[0] || null : null;
  }

  getRoleEqualsSubscriberCheckboxFormControl(roleCode: string): AbstractControl {
    switch (roleCode) {
      case this.roleTypesConstants.ROLE_OWNER:
        return this.partiesControlForm.controls.ownerEqualsSubscriber;
      case this.roleTypesConstants.ROLE_DRIVER:
        return this.partiesControlForm.controls.driverEqualsSubscriber;
      default:
        return null;
    }
  }

  isRoleEqualsSubscriberCheckboxDisabled(roleCode: string): boolean {
    if (!this.parties || !this.parties.subscriber || !this.isFieldEnabled(roleCode)) {
      return true;
    }
    return this.hasRoleReachedMaxAndRoleEqualsSubscriberIsUnchecked(roleCode);
  }

  hasRoleReachedMaxAndRoleEqualsSubscriberIsUnchecked(roleCode: string): boolean {
    const roleData = this.getRoleData(roleCode);
    const roleEqualsSubscriberFormControl = this.getRoleEqualsSubscriberCheckboxFormControl(roleCode);
    return roleData && roleData.partyRoles &&
      roleData.partyRoles.length === roleData.max && roleEqualsSubscriberFormControl.value === false;
  }

  manageRoleEqualsSubscriberOnCheckboxClick(roleCode: string): void {
    if (this.isRoleEqualsSubscriberCheckboxDisabled(roleCode) ||
      (roleCode === this.roleTypesConstants.ROLE_DRIVER && this.isSubscriberValuedWithLegalEntityPersonType())) {
      return;
    }
    const role = this.getRoleData(roleCode);
    const control = this.getRoleEqualsSubscriberCheckboxFormControl(roleCode);
    control.setValue(!control.value);
    if (control.value === true) {
      this.roleToChange = role;
      this.partiesServices.getParty(
        this.proposalService.getContractId(), this.parties.subscriber.partyId, this.parties.subscriber.idLatestPhotos, role.id
      ).pipe(take(1))
        .subscribe(data => this.doManageParties(data));
    } else {
      const partyRoleData = this.getPartySelectedForRoleData(role, this.parties.subscriber.partyId);
      this.cleanPartyRole(role, partyRoleData.party);
    }
  }

  isSubscriberValuedWithLegalEntityPersonType(): boolean {
    return this.parties && this.parties.subscriber && this.parties.subscriber.personType.code === '2';
  }

  isFieldEnabled(roleCode): boolean {
    const enabled = this.proposalService.isFieldEnabled(roleCode);
    if (enabled !== null) {
      return enabled;
    } else {
      return true;
    }
  }
}


<p style="margin-bottom: 15px;">
  <span class="button-holder" *ngIf="!volatility">
    <button class="btn btn-primary btn-block"
            [disabled]="disabled"
            (click)="onCalculate()" translate
            data-qa="volatility-button">lic_calculate_asset_volatility</button>
  </span>
  <span class="volatility" *ngIf="volatility" translate>
    <span translate>lic_volatility</span>: <span data-qa="volatility-value">{{ volatility | percent:'1.3-3' }}</span>
  </span>
</p>

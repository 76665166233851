import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList
} from '@angular/core';
import {LicStepComponent} from '../lic-step/lic-step.component';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'lic-stepper',
  templateUrl: './lic-stepper.component.html',
  styleUrls: ['./lic-stepper.component.css']
})
export class LicStepperComponent implements AfterViewInit, AfterContentChecked {

  @ContentChildren(LicStepComponent, {descendants: true}) stepsQL !: QueryList<LicStepComponent>;

  @Output() stepSlide: EventEmitter<number> = new EventEmitter<number>();

  private $active = 0;
  public get active(): number {
    return this.$active;
  }

  public get steps(): LicStepComponent[] {
    return this.stepsQL.toArray();
  }

  get labels(): string[] {
    return this.steps.map(step => step.label);
  }

  constructor() { }

  ngAfterViewInit(): void {
    this.stepsQL.changes.pipe(
      distinctUntilChanged()
    ).subscribe(() => {
      this.steps.forEach((step, index) => {
        step.stepper = this;
        step.index = index;
      });
      this.slideTo(this.$active);
    });
  }

  ngAfterContentChecked(): void {
    this.stepsQL.notifyOnChanges();
  }

  onNavigate(index: number) {
    if (!this.steps[index].disabled) {
      this.slideTo(index);
    }
  }

  public slideTo(index: number) {
    if (this.steps.length > index && index >= 0) {
      this.$active = index;
      this.steps.forEach(step => {
        step.deactivate();
      });
      this.steps[this.$active].activate();
      this.stepSlide.emit(this.$active);
    }
  }

  public back() {
    this.slideTo(this.$active - 1);
  }

  public next() {
    this.slideTo(this.$active + 1);
  }

}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { restEndPoint } from '../configurations/payments-dashboard.endpoint';
import { ValidationConsolidationState } from '../states/validation-consolidation.state';
import { SearchPaymentsOutput } from '../models/payments/search-payments-output.model';
import { SearchPayments } from '../models/payments/search-payments.model';
import { ValidationConsolidationDataFromRoute } from '../models/validation/validation-consolidation-data-from-route.model';

@Injectable({
  providedIn: "root",
})
export class ValidationConsolidationService {
  private baseApiUrlV2: string;

  constructor(
    private httpClient: HttpClient,
    @Inject("environment") environment: any,
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + "/v2";
  }

  initState$(st: Observable<ValidationConsolidationState>, dataFromRoute: ValidationConsolidationDataFromRoute): Observable<ValidationConsolidationState> {
    return st.pipe(
      map((state: ValidationConsolidationState) => {
        state.searchPaymentsInputDTO = dataFromRoute.searchPaymentsInputDTO
        state.searchPaymentsOutputDTO = dataFromRoute.searchPaymentsOutputDTO
        return state;
      })
    );
  }

  bookValidation(st: Observable<ValidationConsolidationState>, searchPaymentsInputDTO: SearchPayments) {
    return st.pipe(
      mergeMap((state) => {
        const responseList = this.httpClient.patch<SearchPaymentsOutput>(this.baseApiUrlV2 + restEndPoint.bookValidation, searchPaymentsInputDTO);
        return combineLatest([responseList, of(state)]);
      }),
      mergeMap(([responseList, state]) => {
        console.log("SECONDO MARGE MAP")
        return of(state)
      })
    );
  }

  bookConsolidation(st: Observable<ValidationConsolidationState>, searchPaymentsInputDTO: SearchPayments) {
    return st.pipe(
      mergeMap((state) => {
        const responseList = this.httpClient.patch<SearchPaymentsOutput>(this.baseApiUrlV2 + restEndPoint.bookConsolidation, searchPaymentsInputDTO);
        return combineLatest([responseList, of(state)]);
      }),
      mergeMap(([responseList, state]) => {
        console.log("SECONDO MARGE MAP")
        return of(state)
      })
    );
  }
}
import {Injectable} from '@angular/core';
import {GPClause} from "../group-policy-models/group-policy-issue-policy-data";

@Injectable({
  providedIn: 'root'
})
export class GpClausesService {

  constructor() { }

  showPreviewText(clause: GPClause): boolean {
    return clause.textType !== 'HTML' && this.showText(clause);
  }

  showText(clause: GPClause): boolean {
    return !!clause.text && clause.text.trim().length > 0;
  }
}

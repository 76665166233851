import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {_COALESCED_STYLE_SCHEDULER, _CoalescedStyleScheduler, CDK_TABLE_TEMPLATE, CdkTable} from '@angular/cdk/table';
import {_DisposeViewRepeaterStrategy, _VIEW_REPEATER_STRATEGY} from '@angular/cdk/collections';

@Component({
  selector: 'table[rgi-rx-table]',
  template: CDK_TABLE_TEMPLATE,
  inputs: ['dataSource'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'rgi-ui-table',
  },
  providers: [
    {provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy},
    {provide: CdkTable, useExisting: RgiRxTableComponent},
    {provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler},
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RgiRxTableComponent<T> extends CdkTable<T> implements OnInit {


  protected stickyCssClass = 'rgi-ui-table-sticky';
}

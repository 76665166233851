<rgi-rx-expansion-panel *ngIf="isAccordion else noAccordion" 
[expanded]="!(driver.objectId !== '' && driver.objectId === roles.holder.objectId) &&
!(driver.objectId !== '' && driver.objectId === roles.owner.objectId)" #first color="info">
  <div rgi-rx-expansion-panel-header>
    <div class="row rgi-claims-title-section-shared" style="padding:5px;">
      <div class="col-xs-12 col-md-12">
        <label ng-bind="label">
          {{ '_CLAIMS_._DRIVER' | translate }}
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="subject"></ng-container>
</rgi-rx-expansion-panel>

<ng-template #noAccordion>
  <div class="row rgi-claims-sub-title-section-shared">
    <div class="col-xs-12 col-md-12">
      <label ng-bind="label">
        {{ '_CLAIMS_._DRIVER' | translate }}
      </label>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="subject"></ng-container>
</ng-template>

<ng-template #subject>
  <div class="row">
    <div class="col-sm-6 col-md-6">
      <claims-subject-action [subject]="driver" [selectedNode]="selectedNode" [activeRouteId]="activeRouteId"
        (selectNewSubject)="selectNewSubject($event)" (deleteSubject)="deleteSubject($event)"></claims-subject-action>
    </div>
    <div class="col-sm-6 col-md-6">
      <div class="row">
        <div class="col-sm-6 col-md-6" *ngIf="driver?.subject?.anagSubject && 
      driver.objectId!='' && 
      driver.objectId != roles.holder.objectId && 
      driver.objectId != roles.owner.objectId">
          <button rgi-rx-button color="secondary" (click)="openDetail()">
            {{ '_CLAIMS_._DETAILS' | translate }}
            <span class="glyphicon glyphicon-pencil"></span>
          </button>
        </div>
        <div class="col-sm-6 col-md-6" *ngIf="driver?.objectId != '' && localContext != 'ES'">
          <button rgi-rx-button color="secondary" (click)="openModalAddDriverLicense()">
            {{ '_CLAIMS_._DRIVER_LICENSE_TITLE' | translate }}
            <span class="glyphicon glyphicon-pencil"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <claims-subject-info [subject]="driver"></claims-subject-info>
  <claims-subject-form *ngIf="driver?.objectId!='' && 
    driver?.objectId != roles.holder.objectId && 
    driver?.objectId != roles.owner.objectId" [fields]="fields" [selectedNode]="selectedNode"
    [activeRouteId]="activeRouteId" 
    [subject]="{
      chkMaterial: driver.chkMaterial,
      materialDescription: driver.materialDescription,
      chkInjury: driver.chkInjury,
      isIncapacitated: driver.isIncapacitated,
      isMinor: driver.isMinor
    }"  [driverIsNotHolderOrOwner]="driver?.objectId!='' && 
    driver?.objectId != roles.holder.objectId && 
    driver?.objectId != roles.owner.objectId" 
    [listMinorGuardian]="listMinorGuardian"
    (outputDataForm)="outputFormChange($event, listMinorGuardian)"
    (outputListMinorGuardian)="listMinorGuardianChange($event)">
  </claims-subject-form>
  <div *ngIf="chkInjury && (driver?.objectId!='' && 
  driver?.objectId != roles.holder.objectId && 
  driver?.objectId != roles.owner.objectId)">
    <claims-dsc-phisical-injury
    [expanded]="chkInjury && driver?.objectId!='' && 
    driver?.objectId != roles.holder.objectId && 
    driver?.objectId != roles.owner.objectId"
    [selectedNode]="selectedNode"
    [activeRouteId]="activeRouteId"
    [selectedPolicy]="selectedPolicy"
    [categorySelected]="categorySelected"
    [injuryAssetInput]="driver.subjectDataFormInjury"
    [categoryList]="categoryList"
    [subject]="driver" 
    [heirsList]="heirsList"
    (outputHeir)="outputHeirData($event)"
    (outputInjuryData)="outputFormChangeInjury($event)"></claims-dsc-phisical-injury>
  </div>
</ng-template>
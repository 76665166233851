<ng-container *ngIf="quotationGuaranteeDetails">

    <rgi-rx-panel class="rgi-modal">
        <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
            <div calss="rgi-ui-card-title" translate>
                _GP_._QUOTATION_._GUARANTEE_DETAILS_
            </div>
        </rgi-rx-panel-header>
        <rgi-rx-panel-footer>
            <button
                class="rgi-ui-btn rgi-ui-btn-primary uppercase"
                (click)="modalClose.emit()" translate>{{'_GP_._BTN_._CLOSE_'}}
            </button>
        </rgi-rx-panel-footer>

        <ng-container *ngTemplateOutlet="singleEl; context: {title:'_GP_._QUOTATION_._FIRST_INSTALLMENT_', model:quotationGuaranteeDetails.firstInstallment, translations:translations}">
        </ng-container>

        <ng-container *ngTemplateOutlet="singleEl; context: {title:'_GP_._QUOTATION_._NEXT_INSTALLMENT_', model:quotationGuaranteeDetails.nextInstallment, translations:translations}">
        </ng-container>

        <ng-container *ngTemplateOutlet="singleEl; context: {title:'_GP_._QUOTATION_._ANNUAL_PREMIUM', model:quotationGuaranteeDetails.annualPremium, translations:translations}">
        </ng-container>


    </rgi-rx-panel>

</ng-container>

<!-- Template -->

<ng-template #singleEl let-title="title" let-model="model" let-translations="translations">

    <div class="rgi-ui-text-4 rgi-ui-info">
        <span translate>{{title}}</span>
    </div>

    <table rgi-rx-table [dataSource]="bindModel(model)" style="margin-bottom: 30px">

        <ng-container *ngFor="let singleCol of displayedColumns">
            <ng-container rgiRxColumnDef="{{singleCol}}">
                <th rgi-rx-header-cell *rgiRxHeaderCellDef style="text-align: center;"><span translate>{{translations[singleCol]}}</span></th>
                <td rgi-rx-cell *rgiRxCellDef="let row" style="text-align: center;"><span translate>{{ isNumber(row[singleCol]) ? (row[singleCol] | currency:'EUR':true) : row[singleCol] }}</span></td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef style="text-align: center;"><span translate>{{translations[singleCol]}}</span></td>
            </ng-container>
        </ng-container>

        <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
        <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</ng-template>

import {ActiveRoute} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {ModalService} from '@rgi/rx/ui';
import {Observable, of} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {
  GroupPolicyInquiryActionsModalComponent
} from '../../group-policy-components/group-policy-modals/group-policy-modal-inquiry-actions/group-policy-inquiry-actions-modal.component';
import {GROUP_POLICY_MODE_FUNCTIONS, INQUIRY_BTN_ACTIONS} from '../../group-policy-constants/general';
import {
  ApplicationInclusionModalData,
  ContractDetails,
  DataActions
} from '../../group-policy-models/group-policy-inquiry';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {BaseEntity} from '../../group-policy-models/group-policy-issue-policy-data';
import {Mode} from '../../group-policy-models/group-policy-mode';
import {
  GroupPolicyApplicationInclusionService
} from '../../group-policy-services/group-policy-application-inclusion.service';
import {GroupPolicyStateCluster, GroupPolicyStateInquiry} from '../group-policy-state';
import {GPDetailClusterSubStateManager} from '../group-policy-sub-statemanagers/group-policy-cluster-sub-statemanager';
import {
  GroupPolicyStatelessOpClusterService
} from '../group-policy-sub-statemanagers/group-policy-stateless-op-cluster.service';
import {GroupPolicyStatelessOpInquiryService} from './group-policy-stateless-op-inquiry.service';

export class GroupPolicyStateManagerInquiry extends AbstractStateManager<GroupPolicyStateInquiry> implements GPDetailClusterSubStateManager<GroupPolicyStateInquiry> {

  constructor(activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: GroupPolicyStatelessOpInquiryService,
    protected clusterStatelesOps: GroupPolicyStatelessOpClusterService,
    protected modalService: ModalService,
    protected appInclusionService: GroupPolicyApplicationInclusionService) {
    super(activeRoute, stateStoreService);
    this.initInquiryDetail();
  }

  initInquiryDetail() {
    const stateInquiry = new GroupPolicyStateInquiry(this.activeRoute.key);
    const initInquiry$ = this.statelesOps.initInquiryDetail(of(stateInquiry), this.activeRoute.getRouteData(), this.activeRoute);
    this.updateState$(initInquiry$);
  }

  getPolicyActions$(): Observable<GroupPolicyStateInquiry> {
    const st = this.getCurrentState();
    const storeState$ = this.statelesOps.retrievePolicyOperations$(of(st), this.activeRoute.getRouteData());
    this.updateState$(storeState$);
    return storeState$;
  }

  actionBackToPrevPage(idCurrentRoute: string) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.backToPrevPage(of(st), this.activeRoute, idCurrentRoute));
  }

  getClusterDetail$(stateCluster: GroupPolicyStateCluster, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.getClusterDetailFromContract$(of(stateCluster), clusterCode);
  }

  initStateCluster(codeAsset: string, _routeData: DataForSteps): GroupPolicyStateCluster {
    const st = this.getCurrentState();
    const clusters = st.contractDetails.assetsDetail.find(asset => asset.codeAsset === codeAsset).clusters;
    const stateCluster = new GroupPolicyStateCluster(
      this.activeRoute.key, undefined, undefined, this.activeRoute.id, codeAsset, clusters);
    stateCluster.proposalNumber = st.contractDetails.proposalNumber;
    if(_routeData && _routeData.idMovement){
      stateCluster.idMovement = _routeData.idMovement;
    }

    return stateCluster;
  }

  initActionsButtonProperties() {
    const contractDetails: ContractDetails = this.getCurrentState().contractDetails;
    contractDetails.actionsBotton.forEach(button => {
      switch (button.code) {
        case INQUIRY_BTN_ACTIONS.ACTION:
          button.isButtonVisible = !this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.ACTIONS_BUTTON_HIDE);
          button.isButtonActive = !this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.ACTIONS_BUTTON_DISABLE);
          break;
        case INQUIRY_BTN_ACTIONS.INCLUDE_APPLICATION:
          const isCluster = contractDetails.assetsDetail.some(asset => !!asset.clusters);
          const hasValidClusters = contractDetails.assetsDetail.some(asset => asset.clusters && asset.clusters.some(cluster => cluster.status.code !== "3")
          );
          button.isButtonVisible = !this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.APP_INC_BUTTON_HIDE);
          button.isButtonActive = (!this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.APP_INC_BUTTON_DISABLE) && isCluster) ? hasValidClusters : true;
          break;
        case INQUIRY_BTN_ACTIONS.ISSUE:
          button.isButtonVisible = !this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.ISSUE_BUTTON_HIDE);
          button.isButtonActive = !this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.ISSUE_BUTTON_DISABLE);
          break;
        case INQUIRY_BTN_ACTIONS.DUPLICATE:
          button.isButtonVisible = !this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.DUPLICATE_BUTTON_HIDE);
          button.isButtonActive = !this.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.DUPLICATE_BUTTON_DISABLE);
          break;
      }
    });
  }

  handleContractDetailButtonAction(code: string, isCached: boolean, route: string) {

    if (code) {
      switch (code) {
        case INQUIRY_BTN_ACTIONS.ACTION:
          this.openActionsModal();
          break;
        case INQUIRY_BTN_ACTIONS.INCLUDE_APPLICATION:
          this.openApplicationInclusionModal(isCached, route, this.activeRoute.id);
          break;
        case INQUIRY_BTN_ACTIONS.ISSUE:
          this.issueValidProposal();
          break;
        case INQUIRY_BTN_ACTIONS.ALIGN_APPLICATIONS:
          this.alignApplication();
          break;
        case INQUIRY_BTN_ACTIONS.CLONE_MASTER_POLICY:
          this.cloneMasterPolicy();
          break;
        case INQUIRY_BTN_ACTIONS.RECOVER_APPLICATION:
          this.openRecoverApplicationsModal();
          break;
        case INQUIRY_BTN_ACTIONS.REQUEST_AUTHORIZATION:
          this.openRequestAuthorizationModal(this.activeRoute);
      }
    }
  }
  /**
   * modes managing
   */
  getMode(sMode: string): Mode {
    return this.statelesOps.getMode(sMode);
  }
  isFunctionEnabled(sFunction: string): boolean {
    let ret = false;
    if (!!this.getCurrentState().inquiryMode) {
      ret = this.statelesOps.hasFunction(this.getCurrentState().inquiryMode, sFunction);
    }
    return ret;
  }
  protected openActionsModal() {
    const currentState = this.getCurrentState();
    const lastMovEffDate = currentState.contractDetails.movements[currentState.contractDetails.movements.length - 1].effectDate;
    this.getPolicyActions$().pipe(take(1), tap((state: GroupPolicyStateInquiry) => {
      const data: DataActions = {
        actions: state.contractMenuOperations,
        id: this.activeRoute.id,
        node: state.contractDetails.proposalSettings.nodeCode + '',
        proposalNumber: state.contractDetails.proposalNumber,
        policyNumber: state.contractDetails.policyNumber,
        idPolicy: state.contractDetails.proposalSettings.idMasterContract,
        contractStatus: state.contractDetails.proposalSettings.contractStatus,
        activeRoute: this.activeRoute,
        product: {
          code: state.contractDetails.proposalSettings.productCode,
          type: state.contractDetails.proposalSettings.productType
        },
        effectiveDate: state.contractDetails.effectDate,
        lastMovEffDate,
        expireDate: state.contractDetails.contractDetail.detailElements[2].value, // risky
        managementNode: state.contractDetails.proposalSettings.nodeCode
      };
      const actionModal = this.modalService.open(GroupPolicyInquiryActionsModalComponent, data);
      actionModal.enableClickBackground = false;
    })).subscribe();
  }

  protected openApplicationInclusionModal(isCached: boolean, route: string, idComp: string) {
    const st = this.getCurrentState();
    const dataForInclusion = {
      isCached,
      route,
      idComponent: idComp,
      proposalNumber: st.contractDetails.proposalNumber,
      policyNumber: st.contractDetails.policyNumber,
      product: new BaseEntity(st.contractDetails.proposalSettings.productCode, st.contractDetails.productDescription)
    } as ApplicationInclusionModalData;
    this.appInclusionService.handleApplicationInclusion(dataForInclusion, st.contractDetails);
  }

  protected issueValidProposal() {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.issueValidProposal(of(st), this.activeRoute.getRouteData(), this.activeRoute));
  }

  private alignApplication() {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.alignApplication$(of(st)));
  }

  private cloneMasterPolicy() {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.cloneMasterPolicy(of(st)));
  }

  goToInquiryAtMovement(idMov: string) {
    this.updateState$(this.statelesOps.goToContractDetailAtMovement(of(this.getCurrentState()), this.activeRoute.getRouteData(), idMov));
  }

  private openRecoverApplicationsModal() {
    this.statelesOps.manageRecoverApplications$(this.getCurrentState().contractDetails.proposalNumber);
  }

  private openRequestAuthorizationModal(activeRoute: ActiveRoute) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.openRequestAuthorizationModal(of(st), activeRoute));
  }
}

import { NgModule } from '@angular/core';
import {
  RgiRxTooltipModule,
  RgiRxTableModule,
} from '@rgi/rx/ui';
import { TotalSummaryComponent } from './components/total-summary.component';
import { LibPortalIntegrationService } from '../../services/lib-portal-integration.service';
import { CommonModule } from '@angular/common';
import { TotalSummaryService } from './services/total-summary.service';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@NgModule({
  declarations: [
    TotalSummaryComponent
  ],
  imports: [
    CommonModule,
    RgiRxTableModule,
    RgiRxTooltipModule
  ],
  exports: [
    TotalSummaryComponent
  ],
  providers: [
    LibPortalIntegrationService,
    TotalSummaryService,
    RgiCountryLayerNumberFormatPipe
  ]
})
export class TotalSummaryCardModule { }

<div class="grid-item card card-height-normal card-main">
    
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">{{ '_CLAIMS_._MESSAGE_._COMPILING_LETTER_CONTENT' | translate }}
                    <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
                </h3>
            </div>
            <div class="modal-body" style="padding-top: 5px; height: 520px;">
                <div class="card shadow">
                    <div class="row" *ngFor="let aClause of document.clauses" >
                        <div class="col-xs-1" style="height: 50px;" *ngIf="aClause.type === '3'"> 
                            <input [(ngModel)]="aClause.value"  class="btn btn-checkbox"  type="checkbox"  />
                        </div>
                        <div  [ngClass]="{'col-xs-12': valTypeCss12(aClause), 'col-xs-11': !valTypeCss12(aClause)}">
                            <label ng-bind="label" style="font-weight: bold;">{{ aClause.text }} </label>
                        </div>
                        <div class="col-xs-12" style="height: 50px;" *ngIf="aClause.type === '5'"> 
                            <input [(ngModel)]="aClause.value"  class="form-control" placeholder="{{aClause.answerDescr}}" type="text"  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" >
                        <button class="btn btn-warning text-uppercase" (click)="onConfirm()" >{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { APP_SETTING, PV_TOKEN } from '../models/consts/lpc-consts';
import { FundsTrendRequest } from '../models/fundsRequest.model';
import { GsFundDetail, Revaluations } from '../models/life-detail.model';


@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  protected baseApiUrl: string;

  public get baseUrl(): string {
    return this.baseApiUrl + APP_SETTING.REST_BASE_URL;
  }

  constructor(
    protected httpClient: HttpClient,
    @Inject(PV_TOKEN.ENV) protected environment: any,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  public getFundsTrend(code: string, request: FundsTrendRequest,  amount: number = 1, unit: string  = 'year'): Observable<any> {
    const endDateRequest = moment(request.endDate).format('YYYY/MM/DD');
    const startDateRequest = moment(request.startDate)
                            /* .subtract(amount as DurationInputArg1, unit as DurationInputArg2) */
                            .format('YYYY/MM/DD');

    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/funds/' + code + '/values?' +
      'endDate=' + endDateRequest + '&frequency=' + request.frequency + '&startDate=' + startDateRequest);
  }

  public getDetailFunds(param: string): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/funds?' + param);
  }

  public getDetailFundByIdCrypt(id: string): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/funds/' + id);
  }

  public getFundDetailsByQueryParams(page: string, perPage: string, code: string, name: string, fundCode: string): Observable<any> {
    let params = new HttpParams()
    .set('page', page)
    .set('perPage', perPage);
    if (code != null) {
     params = params.set('isinCode', code);
    }
    if (name != null) {
      params = params.set('name', name);
    }
    if (fundCode != null) {
      params = params.set('fundCode', fundCode);
    }
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/funds/search', {params});
  }

  public getProfilesByQueryParams(page: string, perPage: string, name: string, productId: string): Observable<any> {
    let params = new HttpParams()
    .set('page', page)
    .set('perPage', perPage);
    if (productId != null) {
     params = params.set('productId', productId);
    }
    if (name != null) {
      params = params.set('name', name);
    }
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/profiles', {params});
  }

  public getProfileDetail(idCrypt: string): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/profiles/' + idCrypt);
  }

  public getProfileDistribution(idLine: string, idCrypt: string): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/profiles/distributions/?idLine=' + idLine + '&idVersion=' + idCrypt);
  }

  public getFundsVolatility(policyId, effectDate): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/consultation/' + policyId + '/values?effectDate=' +
      effectDate);
  }

  public getPolicyTrend(policyId, startDate, endDate, frequency): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/consultation/' + policyId + '/trend?startDate=' +
      startDate + '&endDate=' + endDate + '&frequency=' + frequency);
  }

  public getGsDetailFund(policyId): Observable<GsFundDetail> {
    return this.httpClient.get<any>(`${this.baseApiUrl}/v2/life/policies/${policyId}/synthesis_values/gs`);
  }

  public getPortfolioFund(idCrypt: string): Observable<any> {
    return this.httpClient.get<any>(this.baseApiUrl +
      '/v2/life/funds/' + idCrypt + '/portfolio');
  }

  public getProducts(): Observable<any> {
    /* const idManagementNode = this.authService.getSalePointId();
    const userCode = this.authService.getOperator().username; */
    const codManagementNode = this.authService.getSalePointCode();
    return this.httpClient.get(this.baseApiUrl + '/v2/life/consultation/products/' + codManagementNode);
  }

  public getRevaluationRates(idFundCrypt: string, startDate?: string, endDate?: string): Observable<Revaluations> {
    let endpoint = this.baseApiUrl + `/v2/life/funds/${idFundCrypt}/revaluationRates`;
    if (!!startDate) {
      endpoint = endpoint + '?startDate=' + startDate;
    }
    if (!!endDate && !!startDate) {
      endpoint = endpoint + '&endDate=' + endDate;
    } else if (!!endDate ) {
      endpoint = endpoint + '?endDate=' + endDate;
    }

    return this.httpClient.get<Revaluations>(endpoint);
  }

}

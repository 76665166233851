import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const GL_ES: RgiRxSerializedI18n  = {
  START_EMISSION_FLOW_RE: 'Fluxo de emisións RE',
  START_POLICY_DATA: 'Datos da póliza',
  START_ASSETS_DATA: 'Datos do ben asegurado',
  START_QUOTATION: 'Cotización',
  START_SUMMARY: 'Resumo',
  START_PROPOSAL_MANAGEMENT: 'Xestión da proposta',
  START_SAVE_PROPOSAL: 'Gardar proposta',
  START_EMISSION: 'Emisión',
  START_REPRINT_DOCUMENTS: 'Reimpresión de documentos',
  START_REPRINT_MOVEMENTS: 'Reimpresión de movementos',
  START_SUBSTITUTION: 'Substitución',
  // Replace space with underscore
  RE_ISSUE: {
    DATE_CREDIT_CARD: 'Non é posible continuar xa que a data de vixencia non pode atrasarse máis de 360 días a partir de hoxe',
    REASON: 'Motivo',
    RECALCULATE_SAVE: 'Recalcular e gardar',
    CLEAR_DISCOUNTS: 'Suprimir descontos',
    DISCOUNT_AMOUNT: 'Importe dos descontos',
    DISCOUNTS: 'Xestión de descontos',
    WARRANTIES_SUMMARY: 'Resumo de garantías',
    IS_MANDATORY: 'é obrigatorio',
    Country: 'País',
    POLICY_DATA: 'Datos da póliza',
    ASSET_DATA: 'Datos dos bens asegurados',
    QUOTATION: 'Cotización',
    SUMMARY: 'Resumo',
    VALIDATE_BRANCH: 'Valide a sucursal',
    FIELDS_MARKED_IN_RED: 'Os campos marcados en vermello son obrigatorios ou conteñen valores incorrectos',
    IS_REQUIRED: 'é necesario',
    COVERAGE_EXPIRE_DATE: 'Data de caducidade da cobertura',
    SUBSCRIPTION_INST_PREMIUM: 'Prima de cota de subscrición',
    INST_SIGN: 'Prazo de subscrición: ',
    MANDATORY_QUESTIONNAIRE_NOT_FILLED: 'Atención, hai polo menos un cuestionario obrigatorio sen cubrir',
    NATION: 'Nación',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    DATE_OF_BIRTH: 'Data de nacemento',
    FACTORS: 'Factores',
    PLACE_OF_BIRTH: 'Lugar de nacemento',
    DAMAGES_PROPOSAL_ASSET_DATA: 'Proposta de danos- datos do ben asegurado',
    SUBJECTS: 'Suxeitos',
    INSURED_SUBJECTS: 'Suxeito asegurado',
    OCCUPATION: 'Profesión',
    FISCAL_CODE: 'Código fiscal',
    BACK: 'Atrás',
    ADD_ASSET: 'Engadir ben asegurado',
    NEXT: 'Continuar',
    CONTINUE: 'Próximo',
    NEXT_START_CARD: 'Continuar',
    EMISSION: 'Emisión',
    POLICY_N: 'Núm. de póliza',
    SAVED_SUCCESSFULLY: 'Gardouse correctamente',
    SUCCESSFULLY_FINALIZED: 'Rematou correctamente',
    PRINT_PIN: 'Cómpre imprimir e inserir o PIN para rematar o contrato',
    PRINT: 'Imprimir',
    PRINT_ENTRY: 'Inserción do PIN',
    EXIT: 'Saír',
    ISSUE_CONFIRMATION: 'Confirmación de emisión',
    ENTER_PIN_TO_CONFIRM_POLICY_ISSUE: 'Insira o seu PIN para confirmar a emisión da póliza:',
    CONFIRM_PURCHASE: 'Confirmar compra',
    POLICY_PREVIEW: 'Vista previa da póliza',
    VALIDATE: 'Validar',
    POLICYHOLDER: 'Tomador',
    NODE: 'Nodo',
    NODE_START_CARD: 'Punto de venda',
    eMAIL: 'Correo electrónico',
    PRODUCT: 'Produto',
    BRANCH_OFFICE: 'Sucursal',
    FISCAL_CODE_VAT_NUMBER: 'Código fiscal/número de NIF',
    RESIDENCE_ADDRESS: 'Enderezo de residencia',
    CONTACTS: 'Contactos',
    MOBILE_PHONE: 'Teléfono móbil',
    EFFECTIVE_DATE: 'Data de vixencia',
    NEXT_INSTALMENT_DATE: 'Data do próximo prazo',
    EXPIRE_DATE: 'Data de vencemento',
    TACIT_RENEWAL: 'Renovación tácita',
    TECHNICAL_DATA: 'Datos técnicos',
    PAYMENT_FREQUENCY: 'Frecuencia de pagamento',
    AGREEMENT: 'Acordo',
    PROPOSAL_MANAGEMENT: 'Xestión da proposta',
    ISSUE_DATE: 'Data de emisión',
    POLICY_MANDATORY_DATA: 'Datos da póliza obrigatorios',
    POLICY_NUMBER: 'Número de póliza',
    MEANS_OF_PAYMENT: 'Métodos de pagamento',
    SELECT_MEANS_OF_PAYMENT: 'Selecciona método de pagamento',
    COORDINATES_TO_REFUND: 'Coordenadas da devolución',
    HEADING: 'Encabezamento',
    ISSUE: 'Emisión',
    '{{header}}': '',
    '{{ parameter?.description }}': '',
    QUESTIONNAIRES: 'Cuestionarios',
    NOT_COMPILED: 'Non recompilado',
    COMPILED: 'Recompilado',
    TO_BE_FILLED_IN_BY_THE_SUBJECT: 'A cubrir polo suxeito co rol de contratante',
    CLAUSES: 'Cláusulas',
    ASSET_CLAUSES: 'Cláusulas de bens asegurados',
    INSURABILITY_REQUIREMENTS: 'Requisitos para poder asegurar',
    PRE_DISCOUNT_PREMIUM: 'Prima previa ao desconto',
    GROSS: 'En bruto',
    PREMIUM_INSTALLMENT: 'Prima previa ao desconto',
    ANNUAL_PREMIUM: 'Prima anual',
    PREMIUM_RATE: 'Tipo de prima',
    RECALCULATE: 'Recalcular',
    VISUALIZE: 'Visualizar',
    PREMIUM_DETAILS: 'Detalles da prima',
    CONTRIBUTIONS_AND_TAXES: 'Cotizacións e impostos',
    BOX: 'Recadro',
    LIST: 'Listaxe ',
    COMMISSIONS: 'Comisións',
    AUTHORITY_LIMIT: 'Límite de autoridade',
    PERC_ACQUIRED_COMMISSIONS: '% de comisións obtidas',
    PERC_ACQUIRED_CHARGES: '% de gastos contraídos',
    PERC_COLLECTED_COMMISSIONS_NET: '% Comisión cobrada (neta)',
    PERC_COLLECTED_CHARGES: '% de gastos cobrados',
    APPLY: 'Aplicar',
    EDIT_AMOUNT: 'Editar importe',
    COMMISSIONS_AT_INCEPTION_DATE: 'Comisións na data de inicio',
    ACQUIRED_COMMISSIONS: ' de comisións obtidas',
    ACQUIRED_CHARGES: ' de gastos contraídos',
    COLLECTED_COMMISSIONS_NET: ' Comisión cobrada (neta)',
    COLLECTED_CHARGES: ' Gastos cobrados',
    TOTAL: 'Total',
    AMOUNT: 'Importe',
    '{{ commission.fees ? \'Fees\' : comisión.descripción }}': '',
    NEXT_COMM_INSTALMENT: 'Próximo prazo da com.',
    RECALCULATE_AND_SAVE: 'Recalcular e gardar',
    CLOSE: 'Pechar',
    INSTALMENT_AT_INCEPTION_DATE: 'Prazo na data de inicio',
    TAXABLE: 'Impoñible',
    INSTALMENT_AT_INCEPTION_DATE_GROSS: 'Prazo bruto na data de inicio:',
    INSURANCE_TAX: 'Imposto sobre o seguro',
    RATE: 'Tarifa',
    TAXES: 'Impostos',
    ANTIRACKET: '(Incluída a loita contra a extorsión)',
    NEXT_INSTALMENT: 'Novo prazo',
    NEXT_INSTALMENT_GROSS: 'Próximo prazo bruto',
    '{{questionnaireFlat?.descriptionType}}': '',
    QUOTATION_DETAILS: 'Información de cotización',
    '{{ premiumDetail.sectionTitle }}': '',
    NET: 'Neto',
    ACCESSORIES: 'Complementarios',
    INST_INTEREST: 'Xuro a prazos',
    'Damages proposal - Quotation': 'Proposta non vida-cotización',
    '{{assetInstance.instanceDescription}}': '',
    PARAMETERS: 'Parámetros da póliza',
    PROPOSAL_N: 'Número de proposta',
    AUTHORIZATION_SEND: 'e enviouse unha solicitude de autorización',
    SAVING_QUOTE: 'Gardar cotización',
    PROCEDE_TO_CHECKOUT: 'Proceder ao pagamento',
    CHECKOUT: 'Pagamento',
    CODE: 'Código',
    ACTION: 'Acción',
    NODE_LIST: 'Listaxe de nodos',
    DRAG_SELECT: 'Arrastrar/seleccionar',
    COMPANY: 'Compañía',
    POLICY_STATE: 'Estado da póliza',
    INSURANCE_ASSETS: 'Bens asegurados',
    SAVE_QUOTATION: 'Gardar cotización',
    'Damages proposal - Summary': 'Proposta de danos-resumo',
    PROPOSAL_DATA: 'Datos da proposta',
    OTHER_POLICY_DATA: 'Outros datos da póliza',
    SYSTEM_MESSAGE: 'MENSAXE DO SISTEMA',
    AN_ERROR_OCCURRED: 'Produciuse un erro, póñase en contacto co servizo de atención ao cliente',
    ERROR_DURING_SUBJECT_UPDATE: 'Erro durante a actualización do suxeito',
    QUESTIONNAIRE_NOT_COMPLETE: 'Atención, o cuestionario non está completo. Cubra todas as respostas para continuar',
    THE_CONTRACTOR_CONFIRMS_THE_VALIDITY: 'O contratante confirma a validez das respostas facilitadas',
    PACKAGE: 'Conxuntos',
    POLICY_EXPIRE_DATE: 'Data de vencemento da póliza',
    NORMALIZE: 'Normalizar',
    ASSETS_DATA: 'Datos do ben asegurado',
    REPRINT_POLICY_DOCUMENTS: 'Reimprimir documentos da póliza- núm. de póliza',
    OUTCOME: 'Resultado',
    AUTHORIZATION_REQUEST: 'Petición de autorización',
    ADDRESS: 'Enderezos',
    MAIN: 'Principal',
    DESCRIPTION: 'Descrición',
    ADD_ADDRESS: 'Engadir enderezo',
    POLICYHOLDER_COMMUNICATION_ADDRESSES: 'Enderezo de correo do tomador',
    POSTAL_MAIL: 'Correo postal',
    CHANGE_ADDRESS: 'Cambiar enderezo',
    EMAIL: 'Correo electrónico',
    CUSTOMER_RESERVED_AREA: 'Área reservada ao cliente',
    POLICYHOLDER_EMAIL: 'Correo electrónico do tomador',
    POLICYHOLDER_RESIDENCE_ADDRESS: 'Enderezo de residencia do tomador',
    POLICYHOLDER_DELIVERY_ADDRESS: 'Enderezo de entrega do tomador',
    OTHER_ADDRESS: 'Outros enderezos',
    SUBSCRIPTION_INSTALMENT_TAXABLE: 'Cota de subscrición suxeita a imposto',
    SUBSCRIPTION_INSTALMENT_GROSS: 'Cota bruta de subscrición',
    NEXT_INSTALMENT_TAXABLE: 'Prazo seguinte suxeito a imposto',
    ANNUAL_PREMIUM_TAXABLE: 'Prima anual suxeita a imposto:',
    ANNUAL_PREMIUM_GROSS: 'Prima anual bruta:',
    NEW: 'Novo',
    POLICY_CONTACT_ERROR: 'Insira polo menos un contacto do tomador',
    CLAUSE_DETAIL: 'Información da cláusula',
    SAVE: 'Gardar',
    PRODUCT_CODE: 'Código de produto',
    PRODUCT_DESCRIPTION: 'Descrición do produto',
    POLICY_EFFECT_DATE: 'Data de vixencia da póliza',
    EMPTY: 'Baleiro',
    DEBIT_MEAN_OF_PAYMENT: 'Medios de pagamento a débito',
    CREDIT_MEAN_OF_PAYMENT: 'Medios de pagamento a crédito',
    ALTERNATIVE_POLICY_ADRESS: 'Insira un enderezo da póliza alternativa',
    YES: 'Si',
    MANDATORY_TEXT: 'O texto da cláusula é obrigatorio',
    MANDATORY: 'obrigatorio',
    SELECT_PAYMENT_METHOD: 'Seleccione un método de pagamento',
    ERROR_ON_DATE: 'Non é posible continuar xa que a data de vixencia debe ser anterior á data de vencemento',
    SELECT_CONTRACTOR: 'Seleccionar un contratante',
    BENEFICIARIES: 'Beneficiarios',
    BENEFICIARY_TYPE: 'Tipo de beneficiario',
    BENEFICIARY: 'Beneficiario',
    PERCENTAGE: 'Porcentaxe',
    THIRD_PARTY_SUBJECT: 'Suxeito terceiro',
    WARNING_BENEFICIARY_SUBJECT: 'O suxeito seleccionado xa foi rexistrado como beneficiario, seleccione outro suxeito',
    WARNING_BENEFICIARY_THIRD_PERSON: 'O suxeito seleccionado xa foi rexistrado como beneficiario, seleccione outro suxeito',
    WARNING_BENEFICIARY_LIMIT: 'Número máximo de beneficiarios atinxido',
    WARNING_THIRDPERSON_SUBJECT: 'O suxeito seleccionado xa foi rexistrado como suxeito terceiro, seleccione outro suxeito',
    USUAL_OCCASIONAL_RESIDENCE: 'RESIDENCIA HABITUAL/OCASIONAL',
    ANAG_BENEF: 'Rexistro beneficiarios',
    SPOUSE_INSURED: 'Cónxuxe do asegurado',
    ERROR_FORMAT_DATE: 'Erro formato data',
    DATE1_AFTER_DATE2: 'A data de vencemento é inferior á data de vixencia',
    SELECT_NODE: 'Seleccionar nodo',
    DATA_CANCELLATION_POLICY: 'Data de cancelación da póliza',
    CANCELLATION_REASON: 'Motivo da cancelación',
    CANCELLATION_PREMIUM_REPAYMENT: 'A cancelación prevé o reembolso da prima',
    RIGHTS: 'Dereitos',
    DATA_CANCELLATION: 'Data de cancelación',
    CONFIRMATION_CANCELLATION: 'Confirmación cancelación',
    CANCELLATION_SUMMARY: 'Resumo da cancelación',
    END: 'Fin',
    DOCUMENTS: 'Documentos',
    POLICY: 'Póliza',
    CANCELED_SUCCESSFULLY: 'cancelado correctamente',
    CONFIRM_CANCELLATION: 'Desexa confirmar a cancelación da póliza',
    CURRENCY: 'Moeda',
    POSTED_NOTICE_DATE: 'Data de aviso de anulación',
    ISSUED_CORRECTLY: 'emitiuse correctamente',
    PREMIUM: 'Prima',
    FEES: 'Taxas',
    SELECT_ASSET: 'SELECCIONAR BEN ASEGURADO',
    ACTIVITY: 'Actividades',
    SECONDARY_ACTIVITY: 'Actividade secundaria',
    ACTIVITY_NOT_FOUND: 'Actividade non atopada',
    SEARCH_ACTIVITY_BY: 'Buscar actividade por',
    VALUE: 'Valor',
    SEARCH: 'Buscar',
    NO_VALUE: 'Non hai valor',
    PROPOSAL_REGULATION: 'Regulación de proposta',
    REGULATION_EXPIRATION_DATE: 'Data de vencemento da regulación',
    REGULATION_FRACTIONING: 'Fraccionamento da regulación',
    REGULATION_TYPE: 'Tipo de regulación',
    REGULATION_DAYS: 'Días de regulación',
    PERCENTAGE_OF_MIN_QUOTE_PREMIUM: '% de prima de cotización mínima / final',
    LPS: 'LPS',
    EU_COUNTRY: 'País da UE',
    EXTRA_EU_COUNTRY: 'País fóra da UE',
    CHANGE_DATE: 'Data de cambio',
    EXCHANGE_RATE: 'Tipo de cambio',
    USE_TAX: 'Imposto de uso',
    CO_INSURANCE: 'Coaeguro',
    CO_INSURANCE_DEP: 'Dep. de coaeguro',
    OUR_PERCENTAGE: 'A nosa porcentaxe',
    QUOTA_PERCENTAGE: 'Porcentaxe de cota',
    COMMISSION_PERCENTAGE: 'Porcentaxe de comisión',
    PERCENTAGE_TOTAL: 'Porcentaxe total',
    PERCENTAGE_ERROR: 'Porcentaxe non válida',
    NO_QUOTAS_ERROR: 'Non se ingresaron cotas de coaseguro',
    LEAD_COINS_ERROR: 'A porcentaxe total do coasegurador principal debe ser 100',
    POLICY_QUOTAS: 'Cotas da póliza',
    NEW_QUOTA: 'Nova cota de coaseguro',
    THIRD_PARTY_QUOTA: 'Datos da póliza do asegurador principal',
    QUOTA: 'Cota',
    INTERMEDIARY: 'Intermediario',
    LEADING_INSURER: 'Asegurador principal',
    RECALCULATE_UPDATE: 'RECALCULAR E ACTUALIZAR',
    RECALCULATE_DEFAULT_LPS_TAXES: 'RECALCULAR IMPOSTOS LPS POR DEFECTO',
    TAX_ANTI_MONEY_LAUNDERING: 'Imposto contra o branquexo de diñeiro',
    COMMON_RISK_POLICIES: 'Pólizas de risco común',
    COMPLEMENTARY_POLICIES: 'Pólizas complementarias',
    COMPLEMENTARY_POLICY_DATA: 'Datos de póliza complementaria',
    SECOND_RISK_POLICIES: 'Pólizas de segundo risco',
    LINKED_POLICIES: 'Pólizas vinculadas',
    NOTES: 'Notas',
    INDIRECT_CO_INSURANCE_POLICIES_LIST: 'Listaxe de pólizas de coaseguro indirecto',
    INDIRECT_CO_INSURANCE_POLICIES: 'Pólizas de coaseguro indirecto',
    SECOND_RISK_POLICIES_LIST: 'Listaxe de pólizas de segundo risco',
    ANNUAL_GROSS_PREM_BEFORE_CHARGES: 'Prima anual (excluíndo dereitos)',
    FLEX_DISCOUNT_WITH_EROSION: 'Flexibilidade con erosión de descontos',
    FLEX_DISCOUNT_WITHOUT_EROSION: 'Flexibilidade sen erosión de descontos',
    FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR: 'Importe coa erosión de descontos non válido. ',
    FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR: 'Importe sen erosión de descontos non válido. ',
    FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR: 'Porcentaxe coa erosión de descontos non válida. ',
    FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR: 'Porcentaxe sen erosión de descontos non válida. ',
    FLEX_DISCOUNT_RANGE_ALLOWED_VALUE: 'Intervalo de valores permitidos: ',
    FLEX_DISCOUNT_MAX_100: 'Inserir un valor menor que ',
    DISCOUNT: 'desconto',
    RESIDUAL_DISCOUNT: 'Desconto restante',
    SHARED_DISCOUNTS_AMOUNT: 'Compartir descontos',
    DISCOUNT_TYPE: 'Tipo de descontos',
    PERIOD: 'Período',
    RESIDUAL_AMOUNT: 'Importe restante',
    NO: 'Non',
    START: 'Inicio',
    LIMIT: 'Límite',
    ADD_POLICY: 'Agregar póliza',
    INPUT_PREMIUM: 'Prima de entrada',
    QUOT_COMMISSIONS: {
      LABEL: {
        ACCESSORIES: 'Accesorios',
        AUTHORITY_LIMIT: 'Límite de autoridade',
        ALL_RISK: 'Todo risco',
        ACQUIRED_CHARGES: 'Cargos adquiridos',
        ACQUIRED_COMMISSION: 'Comisión adquirida',
        AMOUNT: 'Importe',
        ANNUAL_PREMIUM: 'Prima anual',
        ANTIRACKET: 'Imposto contra o branquexo de diñeiro',
        COLLECTED_CHARGES: 'Cargos cobrados',
        COLLECTED_COMMISSION_NET: 'Comisión cobrada (neta)',
        COMMISSIONS: 'Comisións',
        CONTRIBUTIONS_TAXES: 'Contribucións e impostos',
        GROSS: 'Bruto',
        INCEPTION_INSTALLMENT: 'Cota de inicio',
        INSTALLMENT_INTEREST: 'Xuro da cota',
        INSTALLMENT_PREMIUM: 'Prima da cota',
        INSURANCE_TAX: 'Imposto de seguro',
        NET: 'Neto',
        PREMIUM_DETAIL: 'Detalle da prima',
        RATE: 'Taxa',
        SIGNED_COMMISSIONS: 'Comisións na data de inicio',
        DEROGATED: 'Derrogado',
        NEXT_INSTALMENT_COMMISSIONS: 'Próxima comisión de cota',
        RIGHTS: 'Dereitos',
        SSN: 'SSN',
        TAXABLE: 'Gravable',
        TAXES: 'Impostos',
        TOTAL: 'Total',
        VIEW: 'Ver',
        ASSIGN: 'Asignar',
        ACQUIRED_COMMISSIONS_MANDATE: 'Mandato de comisións adquiridas'
      }
    },
    FPS_LABEL: {
      NEXT_INSTALMENT: 'Próxima cota',
      INCEPTION_INSTALLMENT: 'Cota de inicio',
    },
    INDEXATION: 'Indexación',
    QUOTA_ALREADY_EXISTING: 'Cota xa existente',
    CO_POLICYHOLDER_SUBSCRIBER_ERROR: 'O cotitular debe ser unha parte diferente do titular da póliza',
    CO_POLICYHOLDER_JOINT_SUBSCRIBER_ERROR: 'O cotitular debe ser unha parte diferente dos outros cotitulares',
    COMMON_RISK_POLICIES_LIST: 'Listaxe de pólizas de risco común',
    COMMON_POLICY_DATA: 'Datos comúns da póliza',
    LINKED_POLICIES_LIST: 'Listaxe de pólizas vinculadas',
    VALID_POLICY: 'Póliza válida',
    INVALID_POLICY: 'Póliza non válida',
    INVALID_POLICY_NUMBER: 'Formato inválido de número de póliza',
    POLICY_ALREADY_ADDED : 'A póliza xa foi agregada',
    NO_POLICY_ADDED : 'Non se agregou ningunha póliza',
    AMOUNT_NOT_VALID: 'Importe non válido',
    GENERIC_SYSTEM_ERROR: 'Erro interno do servidor',
    SAVE_AND_CLOSE: 'Gardar e pechar'
  }
};

import {Injectable, Optional} from '@angular/core';
import {
  AuthenticationResult,
  RgiRxAuthenticationType,
  RgiRxAuthProvider,
  RgiRxLoginService
} from './auth-api';
import {TokenProviderService} from './token-provider.service';
import {Observable, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {UserService} from './user/user.service';

const ERROR_MSG = 'No provider for RgiRxLoginService have been registered!';

@Injectable()
export class RgiRxAuthService<USER = any, DATA = any> implements RgiRxAuthProvider<USER, DATA> {
  private _data?: DATA;
  constructor(private _tokenProvider: TokenProviderService, @Optional()  private loginService: RgiRxLoginService<USER, DATA>, private _userService: UserService) {}


  public authenticate(data?: RgiRxAuthenticationType): Observable<AuthenticationResult<USER, DATA> | void> {
    if (this.isLoginServiceAvailable()) {
      return this.loginService.login(data)
        .pipe(
          tap((result) => {
            if (result) {
              if (result.token) {
                this._tokenProvider.token = result.token;
              }
              if (result.user) {
                this._userService.user = result.user;
              }
              if (result.data){
                this._data = result.data;
              }
            }
          }),
          map((result) => {
            if (result) {
              return {
                user: result.user,
                data: result.data
              };
            }
          })
        );
    }
    return throwError(ERROR_MSG);
  }

  public logout(data?: any): Observable<any |void> {
    if (this.isLoginServiceAvailable()) {
      return this.loginService.logout(data).pipe(
        tap(logout => {
          this._tokenProvider.token = undefined;
          this._userService.logout();
        })
      );
    }
    return throwError(ERROR_MSG);
  }

  private isLoginServiceAvailable(): boolean {
    return !!this.loginService;
  }

  /**
   * Return any data that has been returned by the authenticate method invocation if any
   */
  get data(): DATA | undefined {
    return this._data;
  }

  isAuthenticated(): boolean {
    return this._userService.isLoggedIn() && !!this._tokenProvider.token;
  }
}

/**
 * @deprecated use RgiRxAuthService
 * @see RgiRxAuthService
 */
export class AuthService extends RgiRxAuthService<any, any> {}

<rgi-rx-panel [@fadeAnimation]>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    {{ data?.instName }} - {{data?.mainTitle}}
  </rgi-rx-panel-header>

  <div *ngIf="!!data">
    <div class="row header-unit-modal">
      <b>{{data?.title}}</b>
    </div>
    <table class="table-responsive table coreDT life-detail-margin-top">
      <tbody>
        <ng-container *ngFor="let item of data?.data | keyvalue">
          <tr class="table-row table-background" *ngIf="item.value !== null">
            <td class="table-border" [attr.data-qa]="item.key + 'key'">{{ item.key }}</td>
            <td class="table-border" [attr.data-qa]="item.key + 'value'">{{item.value | dataValueFormatterPipe}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose.emit()" translate>lpc_close_button</button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="anag-icon-m rgi-ui-icon-privacy"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>{{ '_ANAG_._LABEL_._PRIVACY_' | translate}}</span>
    <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
  </div>
  <ng-container [formGroup]="privacyForm">
    <div class="rgi-ui-panel-content" *ngIf="stateMgr.getState$() | async as state">

      <rgi-rx-form-field>
        <label rgiRxLabel><span>{{ '_ANAG_._LABEL_._ROBINSON_LIST_' | translate}}</span></label>
        &nbsp;&nbsp;&nbsp;
        <rgi-rx-button-toggle formControlName="robinsonList">
          <button rgi-rx-button rgiRxValueOption [value]="true">
            <span translate>{{'_ANAG_._LABEL_._YES_'}}</span></button>
          <button rgi-rx-button rgiRxValueOption [value]="false">
            <span translate>{{'_ANAG_._LABEL_._NO_'}}</span></button>
          <button rgi-rx-button rgiRxValueOption [value]="null">
            <span translate>{{'_ANAG_._LABEL_._DO_NOT_REPLY_'}}</span></button>
        </rgi-rx-button-toggle>
      </rgi-rx-form-field>
      <div style="margin-bottom: 15px">
      <span translate>{{'_ANAG_._LABEL_._PRIVACY_INFO_MSG_'}}</span>
      </div>
      <div class="rgi-ui-grid-4" *ngFor="let question of privacyQuestions">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ question.descrizione }}</label>
            <select rgiRxNativeSelect class="rgi-ui-form-control" [formControlName]="question.codice"
              [attr.data-qa]="'question-'+question.codice">
              <option *ngFor="let opt of questionOptions" [value]="opt.codice">{{opt.descrizione}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field style="display: contents">
            <div style="display: block">
              <label rgiRxLabel><span>{{ '_ANAG_._LABEL_._PRIVACY_CONSENSUS_DATE_' | translate}}</span></label>
              <div style="display:flex;">
                <input [rgiRxDateTimeInput]="privacyConsensusDatePicker" style="flex: 1"
                  [attr.data-qa]="'date-'+question.codice" [formControlName]="'date-'+question.codice" [max]="maxDate"
                  selectMode="single" rangeSeparator="-" placeholder="{{datepickerPlaceholder}}"
                  #inputToTriggerPrivacyConsensusDate="rgiRxDateTimeInput">
                <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                  [rgiRxDateTimeTrigger]="inputToTriggerPrivacyConsensusDate"></button>
              </div>
            </div>
            <rgi-rx-date-time #privacyConsensusDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._ORIGIN_' | translate}}</label>
            <select rgiRxNativeSelect [formControlName]="'origin-'+question.codice"
              [attr.data-qa]="'origin-'+question.codice">
              <option value=""></option>
              <option *ngFor="let originOpt of originOptions" [value]="originOpt.codice">{{originOpt.descrizione |
                translate}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field style="display: contents" disabled="true">
            <div style="display: block">
              <label rgiRxLabel><span>{{'_ANAG_._LABEL_._CHANGED_ON_' | translate}}</span></label>
              <input rgiRxInput
                     style="flex: 1"
                     [disabled]="true"
                     data-qa="privacyConsentDateUpd"
                     [attr.data-qa]="'privacyConsentDateUpd-'+question.codice"
                     [formControlName]="'privacyConsentDateUpd-'+question.codice">
            </div>
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
    <rgi-rx-snackbar tag="privacy-tag"></rgi-rx-snackbar>
    <div class="rgi-ui-panel-footer">
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
        translate>_ANAG_._BTN_._CANCEL_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="updatePrivacy()" data-qa="anag-btn-confirm"
        translate>_ANAG_._BTN_._CONFIRM_
      </button>
    </div>
  </ng-container>

</div>

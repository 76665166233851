import {RgiRxDatasource} from '@rgi/rx/ui';
import {RgiRxDataPaginator} from '@rgi/rx/ui';
import {DashboardCardState, DashboardManagerService} from '../state-manager/dashboard-manager.service';
import {combineLatest, of, Subscription} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {filter, mergeMap, skip, switchMap, take, tap} from 'rxjs/operators';
import {DashboardUtilityService} from './dashboard-utility.service';

export class AsyncDataSource extends RgiRxDatasource<any> {


    private changes = Subscription.EMPTY;
    private filterSub = Subscription.EMPTY;

    constructor(data: any[], paginator: RgiRxDataPaginator,
                public stateManager: DashboardManagerService<DashboardCardState>,
                public utility: DashboardUtilityService) {

        super(data, paginator);
        this.subscribeChanges();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.changes.unsubscribe();
    }

    private subscribeChanges() {
        this.changes.unsubscribe();
        this.filterSub.unsubscribe();

        // SUBSCRIBE TOTAL ELEMENTS
        let maxCounted;
        const count$ = this.stateManager.getTotalCommissions()
            .pipe(
                tap(count => {
                    maxCounted = count;
                    return this.paginator.elementCount = count;
                }),
                take(1)
            ).subscribe();

        // SUBSCRIBE SEARCH FILTER FIELD
        this.filterSub = this._filter
            .pipe(
                mergeMap(keyWord => {
                    if (!!keyWord) {
                        return this.stateManager.filterCommissions(keyWord)
                            .pipe(
                                tap(data => {
                                    this._data.next(data);
                                })
                            );
                    }
                    return of(this.stateManager.getCurrentState().commissionsData).pipe(
                        tap(data => {
                            this._data.next(data);
                        })
                    );
                }),
            )
            .subscribe();

        // SUBSCRIBE PAGINATOR CHANGES
        this.changes = this.paginator.changes
            .pipe(
                filter(changes => changes.size && !this.filter),
                skip(1),
                switchMap(changes => {
                    const state = this.stateManager.getCurrentState();
                    const page: string = String(changes.current + 1);
                    const limit: string = String(changes.size);
                    this.stateManager.setPage(page);
                    this.stateManager.setLimit(limit);
                    return combineLatest(of(changes), this.stateManager.getCommissions(state, page, limit));
                }),
                tap(([changes, data]) => {
                    const tableData = data.commissionsData;
                    const dataSource = this.utility.createData(tableData, data);
                    this._data.next(dataSource);
                    this.stateManager.updateState$(of(data));
                })
            ).subscribe();
    }

}

<div class="cc-modal-wrapper">
  <rgi-rx-panel class="report-panel">
  <!-- HEADER -->
  <rgi-rx-panel-header (onClose)="closeModal(null)" [closeable]="true">
    <span translate>CONTRACT_CONSULTATION.REPORT</span>
  </rgi-rx-panel-header>
  <div>
    <ul class="report-ul">
      <li>
          <span (click)="closeModal('csv')">Comma separated values (.csv)</span>
      </li>
      <li>
          <span (click)="closeModal('html')"> Web (.html)</span>
      </li>
      <li>
          <span (click)="closeModal('pdf')">Adobe Acrobat® (.pdf)</span>
      </li>
      <li>
          <span (click)="closeModal('rtf')">Rich Text Format (.rtf)</span>
      </li>
      <li>
          <span (click)="closeModal('xls')">Microsoft Excel® (.xlsx)</span>
      </li>
    </ul>
  </div>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="primary" class="cc-btn"
            data-qa="next" (click)="closeModal(null)" translate>
     CONTRACT_CONSULTATION.CLOSE</button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>
</div>

export const PRODUCTSCATEGORY = {
  INDIVIDUAL: 1,
  COLLECTIVE: 2
};

export const SALEPOINTTYPE = {
  ROOT: '1',
  AGENCY: '2'
};

export const ibanRejex = '^(?:(?:IT|SM)\\d{2}[A-Z]\\d{10}([A-Z0-9]{12})|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$';

export const dateRejex = '^(0[1-9]|1[0-2])\/?([0-9]{4})$';  // ^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$

export const phoneRejex = '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})';

export const percentageRejex = '^([0-9]|([1-9][0-9])|100)$';

export const twoDecimalRejex = '^\\d*\\.?\\d{1,2}$';

export enum ROLES {
  Policyholder = '1',
  JointHolder = '2',
  VehicleOwner = '3',
  Insured = '4',
  FinanceCompany = '5',
  Driver = '6',
  Beneficiary = '100',
  LegalRepresentative = '101',
  CoGuarantor = '102',
  TACParticipant = '103',
  Debtor = '104',
  CollateralOwner = '105',
  OperationalParty = '106',
  PartyOnBehalf = '107',
  Perceiving = '108',
  Payer = '109',
  Adherent = '110',
  Tenant = '111',
  Delegate = '112',
  EffectiveHolders = '113',
  Account = '114',
  usufructuary = '115',
  buyer = '116',
  InvoiceRecipient = '117',
  FeeInvoiceRecipient = '118',
  SubDepositor = '40001',
  BondRecipient = '40002',
  ClientOfTheBeneficiary = '40003',
  MasterOfWorks = '40004',
  CoContractor = '40005',
  Reversibile = '300000',
  BeneficiaryCoupon = '300001',
  Employer = '300002',
  WhoDistrain = '300003',
  PaymentInstitute = '300006',
  Guardian = '300007',
  LegalGuardian = '300008',
  OperationHolder = '300009',
  PartySubjectToTaxation = '300010',
  CompanyPensionFundFormOfOrigin = '300011',
  SettlementOwner = '300012',
  SubjectBound = '300013',
  Grantor = '300014',
  ThirdContactPerson = '300015',
  ThirdPayer = '300016',
  ThirdPayerOwner = '300017',
  BeneficiaryOwner = '300018',
  BeneficiaryExecutor = '300019',
  EffectiveRecipient = '300020',
  RecipientOwner = '300021',
  Associate = '30010'
}

export enum OPERATIONSCODE {
  ISSUE = '000001',
  EDIT_PROPOSAL = '000002',
  ISSUE_PROPOSAL = '000003'
}

export const INQUIRY_BTN_ACTIONS = {
  ACTION: 'PD2672',
  INCLUDE_APPLICATION: 'C00737',
  ISSUE: 'PC1013',
  DUPLICATE: 'PC1053',
  ALIGN_APPLICATIONS: 'GPS0024',
  CLONE_MASTER_POLICY: 'PC1053',
  RECOVER_APPLICATION: 'GPS0030',
  REQUEST_AUTHORIZATION: 'AUTHORIZATION'
};

export const CONTRACT_STATUS = {
  UNDEFINED: '4'
};

export const POLICY_DATA_FIELD_TYPE = {
  LIST: 'LIST',
  NUMERIC: 'NUM',
  BOOLEAN: 'BOOL',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  STRING: 'STRING',
  INSURANCE_HOUR: 'INSURANCE_HOUR'
};

export const GROUP_POLICY_MODE_FUNCTIONS = {
  PARTIES: {
    PREVENT_JUMP_TO_PARTIES: 'PREVENT_JUMP_TO_PARTIES'
  },
  CONTRACT_DETAILS: {
    BACK_BUTTON_HIDE: 'BACK_BUTTON_HIDE',
    BACK_BUTTON_DISABLE: 'BACK_BUTTON_DISABLE',
    ACTIONS_BUTTON_HIDE: 'ACTIONS_BUTTON_HIDE',
    ACTIONS_BUTTON_DISABLE: 'ACTIONS_BUTTON_DISABLE',
    APP_INC_BUTTON_HIDE: 'APP_INC_BUTTON_HIDE',
    APP_INC_BUTTON_DISABLE: 'APP_INC_BUTTON_DISABLE',
    ISSUE_BUTTON_HIDE: 'ISSUE_BUTTON_HIDE',
    ISSUE_BUTTON_DISABLE: 'ISSUE_BUTTON_DISABLE',
    DUPLICATE_BUTTON_HIDE: 'DUPLICATE_BUTTON_HIDE',
    DUPLICATE_BUTTON_DISABLE: 'DUPLICATE_BUTTON_DISABLE',
  },
};

export const GROUP_POLICY_MODE_PROPERTIES = {
  INQUIRY_MODE_PROP: 'modalitaConsultazione',
  MODES: {
    DEFAULT_MODE: 'DEFAULT_MODE',
    READ_ONLY: 'READ_ONLY'
  },
};

export enum FUNCTION_STATUS {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled'
}

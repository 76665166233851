import { Component, OnInit } from '@angular/core';
import { ActiveRoute, RoutableComponent } from '@rgi/rx/router';
import { GroupPolicyProposalissueRouteData } from '../../group-policy-models/group-policy-proposalissue';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyStateProposalIssue } from '../../group-policy-state/group-policy-state';
import {
  GroupPolicyStatemanagerProposalissue
} from '../../group-policy-state/group-policy-statemanager-proposalissue/group-policy-statemanager-proposalissue';

@Component({
  selector: 'rgi-gp-group-policy-proposal-issue',
  templateUrl: './group-policy-proposal-issue.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyProposalIssueComponent extends RoutableComponent implements OnInit {

  public state: GroupPolicyStateProposalIssue;

  constructor(protected activeRoute: ActiveRoute,
              protected stateManager: GroupPolicyStatemanagerProposalissue,
              protected integrationService: GroupPolicyIntegrationService) {
    super();
  }

  ngOnInit(): void {
    this.stateManager.getState$().subscribe((nextState: GroupPolicyStateProposalIssue) => {
      this.initPage(nextState);
    });
  }

  protected initPage(nextState: GroupPolicyStateProposalIssue) {
    this.state = nextState;
  }

  back() {
    const proposalIssueRouteData = this.activeRoute.getRouteData<GroupPolicyProposalissueRouteData>();
    this.integrationService.backToInquiry(proposalIssueRouteData.proposalNumber, this.activeRoute.id, proposalIssueRouteData.idParentSession);
  }

}

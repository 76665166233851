import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const EN: RgiRxSerializedI18n = {
  CONTRACT_CONSULTATION_CARD_TITLE: 'Contract Consultation',

  CONTRACT_CONSULTATION: {
    NUMBER: 'Number',
    DESCRIPTION: 'Description',
    POLICYHOLDER: 'Policyholder',
    EFFECTIVE_DATE: 'Effective Date',
    STATE: 'State',
    ANNUAL_PREMIUM_EXCEPT_RIGHTS: 'Annual Premium (except rights)',
    MANAGEMENT_NODE: 'Management Node',
    NODE_LIST: 'Node List',
    ISSUE_DATE: 'Issue date',
    CANCELLATION_DATE: 'Cancellation Date',
    PRODUCT: 'Product',
    EXPIRE_DATE: 'Expire Date',
    CURRENT_MOVEMENT: 'Current Movement',
    CONTRACTS_LIST: 'Contracts List',
    SEARCH_RESULTS: 'Search Results',
    BACK: 'BACK',
    FIND: 'FIND',
    EMPTY: 'EMPTY',
    REPORT: 'REPORT',
    CONTRACT_BRANCH: 'Contract Branch',
    TYPE: 'Type',
    SUBTYPE: 'Subtype',
    LIMITATION: 'Limitation',
    POINT_OF_SALE: 'Point of Sale',
    SEARCH_UNDER_SUBNET: 'Search Under Subnet',
    WHOLE_SALES_NETWORK: 'Search On The Whole Sales Network',
    COMPANY: 'Company',
    INDEPENDENT_AGENT: 'Independent Agent',
    BRANCH_OFFICE: 'Branch Office',
    CONTRACT_TYPE: 'Contract Type',
    PROPOSAL_NUMBER: 'Proposal Number',
    POLICY_NUMBER: 'Policy Number',
    PRODUCT_TYPE: 'Product Type',
    CONTRACT_CONDITION: 'Contract Condition',
    SUBJECT: 'Subject',
    SUBJECTS: 'Subjects',
    ALL: 'All',
    PC: 'P&C',
    LIFE: 'Life',
    INDIVIDUAL: 'Individual',
    COLLECTIVE: 'Collective',
    NOT_ANONYMIZED: 'Not Anonymized',
    ANONYMIZED: 'Anonymized',
    MASTER_POLICY: 'Master Policy',
    APPLICATION: 'Application',
    AT_PRESENT_DAY: 'At the present day',
    LAST: 'Last',
    MASTER_PROPOSAL_NUMBER: 'Master proposal number',
    MASTER_POLICY_NUMBER: 'Master policy number',
    APPLICATION_NUMBER: 'Application number',
    ACTIONS: 'Actions',
    CODE: 'Code',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    POLICIES: 'Policies',
    PROPOSALS: 'Proposals',
    POLICIES_OR_PROPOSALS: 'Policies or proposals',
    QUOTE_W_REG_PARTY: 'Quote with a registered party',
    ANONYMOUS_QUOTE: 'Anonymous quote',
    QUOTE_NUMBER: 'Quote number',
    SYSTEM_MESSAGE:'System message',
    VALORIZE_NUMBER: 'Valorize proposal number, policy number or application number.',
    SELECT_NODE: 'SELECT NODE',
    FIELDS_MARKED_IN_RED: 'Fields marked in red are required or contain incorrect values',
    NO_RESULTS: 'No results',
    NO_ACTIONS: 'No actions available for selected contract.',
    LABEL_ANONIMIZED: 'Displayed data have been anonymized and may therefore be meaningless',
    SUBJECT_ROLE: 'Subject role',
    PLATE_NUMBER: 'Plate / Chassis / Engine number',
    SHIP_NAME: 'Ship name / IMO code',
    ERROR_FORMAT_DATE: 'Date format error',
    CONTRACT_STATES: 'Contract states',
    CONTRACT_SUBSTATES: 'Contract substates',
    ACTION: 'Action',
    CREATE_COPY: 'Create a copy',
    CONTRACT_SUMMARY: 'Contract Summary',
    NOMINATIVE: 'Contractor',
    FISCAL_CODE: 'Fiscal Code',
    TACIT_RENEWAL: 'Tacit Renewal',
    FRACTIONATION: 'Fractionation',
    USER: 'User',
    MOVEMENTS: 'Movements',
    YES: 'Yes',
    NO: 'No',
    NOTES: 'Notes',
    MIGRATED: 'Migrated',
    ORIGINAL_EFFECTIVE_DATE: 'Original effective date',
    EXPIRY_DATE: 'Expiry Date',
    COVERAGE_EXPIRY_DATE: 'Coverage expiry date',
    TERMINATION_DATE: 'Termination date',
    PROPOSAL_VALIDITY_END_DATE: 'Proposal validity end date',
    SETTLEMENT_BLOCK_DATE: 'Settlement block date',
    MIGRATED_POLICY: 'Migrated policy',
    AGREEMENT: 'Agreement',
    POLICY_DELIVERY_METHOD: 'Policy delivery method',
    POLICY_DETAILS: 'Policy Details',
    ADMINISTRATIVE_DATA: 'Administrative Data',
    EMAIL: 'E-mail',
    ADDRESS: 'Address',
    SIGN_INSTALMENT: 'Instalment at Inception Date',
    NEXT_INSTALMENT: 'Next Instalment',
    ANNUAL_PREMIUM: 'Annual Premium',
    DISCOUNTS: 'Discounts',
    COMMISSIONS:'Commissions',
    PREMIUM:'Premium',
    CLAUSES: 'Clauses',
    FACTORS: 'Factors',
    NET: 'Net',
    ACCESSORIES: 'Accessories',
    FRACTIONING_INTERESTS: 'Fractioning Interests',
    TAXABLE: 'Taxable',
    TAXES: 'Taxes',
    GROSS: 'Gross',
    WARNINGS: 'Warnings',
    WARNINGS_LIST: 'Warnings List',
    BLOCKING_LEVEL: "Blocking Level",
    ORIGINAL_ENTITY: "Original Entity",
    SUBSTITUTION_EFFECTIVE_DATE: 'Substitution Effect Date',
    SUBSTITUTION_REASON: 'Substitution Reason',
    SUBSTITUTIONS:'Substitutions',
    SUBSTITUENT_POLICIES:'Substituent Policies',
    SUBSTITUTED_POLICIES:'Substituted Policies',
    SUBSTITUENT_PROPOSALS:'Substituent Proposals',
    PROPOSAL_DETAILS:'Proposal Details',
    WARRANTIES: 'Warranties and Insured Assets',
    TOTAL_GROSS: 'Total Gross Premium',
  }
};

import {GenericEntity} from './generic-entity';

export class LicensePlate {
  constructor(
    public plateNumber: string,
    public servicePlateType: string,
    public plateType: GenericEntity,
    public specialPlateType: GenericEntity,
    public specialPlatePrefix: GenericEntity,
    public country: GenericEntity) {
  }

  isNull() {
    if (!this.plateNumber && !this.servicePlateType && !this.plateType && !this.country) {
      return true;
    } else if (this.plateType.isNull() && this.servicePlateType === '' &&
      this.plateNumber === '' && this.specialPlateType.isNull() &&
      this.specialPlatePrefix.isNull() && this.country.isNull()) {
      return true;
    } else {
      return false;
    }
  }
}

import {ExistingProvider, FactoryProvider, InjectionToken, Provider, StaticClassProvider, Type} from '@angular/core';
import {RgiRxLocalStateManager} from './rgi-rx-local-state-manager';
import {ActiveRoute} from '@rgi/rx/router';
import {StateStoreService} from './state-store.service';
import {RgiRxStateManager} from './state-manager-api';


interface StaticClassManager<TOKEN extends RgiRxStateManager<any>, TYPE extends TOKEN | RgiRxStateManager<any>> extends StaticClassProvider {
  provide: Type<TOKEN> | InjectionToken<TOKEN>;
  useClass: Type<TYPE>;
}

interface StaticExistingManager<TOKEN extends RgiRxStateManager<any>> extends ExistingProvider {
  provide: Type<TOKEN> | InjectionToken<TOKEN>;
}

interface StaticFactoryManager<TOKEN extends RgiRxStateManager<any>> extends FactoryProvider {
  provide: Type<TOKEN> | InjectionToken<TOKEN>;
}

export type RgiRxLocalStateManagerProvider<TOKEN extends RgiRxLocalStateManager<any>, TYPE extends TOKEN | RgiRxLocalStateManager<any>> =
  StaticClassManager<TOKEN, TYPE>
  | StaticExistingManager<TOKEN>
  | StaticFactoryManager<TOKEN>;

  export type RgiRxStateManagerProvider<TOKEN extends RgiRxStateManager<any>, TYPE extends TOKEN | RgiRxStateManager<any>> =
  StaticClassManager<TOKEN, TYPE>
  | StaticExistingManager<TOKEN>
  | StaticFactoryManager<TOKEN>;


const isProviderWithDeps = (provider: RgiRxLocalStateManagerProvider<any, any>): provider is StaticExistingManager<any> => {
  return (provider as ExistingProvider).useExisting !== undefined;
};


export function provideLocalStateManager<TOKEN extends RgiRxLocalStateManager<any>, TYPE extends TOKEN | RgiRxLocalStateManager<any>>(
  provide: RgiRxLocalStateManagerProvider<TOKEN, TYPE>
): RgiRxLocalStateManagerProvider<TOKEN, TYPE> {
  const baseDeps = [ActiveRoute, StateStoreService];
  if (isProviderWithDeps(provide)) {
    return provide;
  }
  return {...provide, deps: [...baseDeps, ...provide.deps]};
}


export function provideStateManager(
  provide: RgiRxLocalStateManagerProvider<any, any> | RgiRxStateManagerProvider<any, any>
): Provider[] {
  return [
    provide,
    {
      provide: RgiRxStateManager,
      useExisting: provide.provide
    }
  ];
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Package} from '../../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {UntypedFormGroup} from '@angular/forms';
import {UnitService} from '../re-issue-quotation-services/unit.service';
import {Currency} from '../../../models/domain-models/parameters/currency';

@Component({
  selector: 're-issue-package',
  templateUrl: './re-issue-package.component.html',
  styleUrls: ['./re-issue-package.component.css']
})
export class ReIssuePackageComponent implements OnInit {

  @Input() quotePackets: Package[];
  @Input() packetContract: boolean;
  @Input() numAsset: number;
  @Input() currency: Currency;
  @Output() packetSelected = new EventEmitter<Package>();
  packetForm: UntypedFormGroup;

  constructor(protected unitService: UnitService) {
  }

  ngOnInit() {
    this.packetForm = this.unitService.toPacketFormGroup(this.quotePackets);
    this.quotePackets.forEach(packet => {
      this.packetForm.controls[packet.code].valueChanges.subscribe(selected => {
        packet.selected = selected;
        packet.units.forEach(unit => {
          unit.selected = selected;
        });
        const controls = this.packetForm.controls;
        for (const name in controls) {
          if (name !== packet.code) {
            controls[name].setValue(false, {onlySelf: true, emitEvent: false});
          }
        }
        this.selectPacket(packet);
      });
    });
  }

  selectPacket(packet: Package) {
    this.packetSelected.emit(packet);
  }
}

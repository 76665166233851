import {
  RePostSalesCancellationConfirmationState
} from '../re-postsale-state-manager/re-postsales-state-manager-cancellation-confirmation.service';
import {Injectable} from '@angular/core';
import {
  RePostsalesCancellationConfirmationResourceService
} from '../re-postsale-resources/re-postsales-cancellation-confirmation.resource.service';

@Injectable({
  providedIn: 'root'
})
export class RePostsalesCancellationConfirmationBusinessService<T extends RePostSalesCancellationConfirmationState> {

  constructor(
    private resourcesService: RePostsalesCancellationConfirmationResourceService
  ) {
  }

}

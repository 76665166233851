import { EnumType } from '@rgi/digital-claims-common-angular';
import { InvoiceDetails } from './invoice-details';
import { InvoiceExpert } from './invoice-expert';

export class Invoice {

    public idInvoice: number;
    public invoiceType: EnumType;
    public sdiCode: string;
    public sapCode: string;
    public sapRecordingDate: Date; // DATA REGISTRAZIONE SAP
    public documentType: EnumType;
    public invoiceDate: Date; // DATA FATTURA
    public invoiceNumber: string; // NUMERO FATTURA
    public currency: string; // VALUTA
    public taxableAmount: number; // IMPORTO IMPONIBILE
    public notTaxableAmount: number; // IMPORTO NON IMPONIBILE
    public percWithHoldTax: number; // ALIQUOTA RITENUTA
    public amountHoldTax: number; // IMPORTO RITENUTA
    public percPrevFund1: number; // ALIQUOTA CASSA PREVIDENZANZIALE
    public cashPrevidAmount: number; // IMPORTO CASSA PREVIDENZANZIALE
    public percPrevFund2: number; // ALIQUOTA CASSA INPS
    public inpsContribAmount: number; // IMPORTO CASSA INPS
    public vatRate: number; // ALIQUOTA IVA
    public vatAmount: number; // IMPORTO IVA
    public stampDutyAmount: number; // BOLLO
    public nRoundingCMount: number; // ARROTONDAMENTO
    public invoiceAmount: number; // IMPORTO TOTALE DOCUMENTO
    public amountToBePayd: number; // IMPORTO DA PAGARE
    public paymentType: EnumType; // TIPO PAGAMENTO
    public iban: string;
    public foreignAccount: string; // CONTO ESTERO
    public swift: string;
    public financeInstitute: string; // ISTITUTO FINANZIARIO
    public paymentAmount: number; // IMPORTO PAGAMENTO
    public status: EnumType;
    public rejectType: EnumType;
    public insertDate: Date;
    public expanded: boolean; // used only for front end accordion management, removed before calling backend in the saveInvoice method

    public invoiceDetailsList: InvoiceDetails[];
    public invoiceExpert: InvoiceExpert;

}

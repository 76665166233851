import {downgradeInjectable} from '@angular/upgrade/static';
import {PortalConfig} from '@rgi/rx/portal';
import {
  ContractConsultationFieldConfigurationService
} from '@rgi/contract-consultation-card';

declare var angular: angular.IAngularStatic;

export function downgradeContractConsultationService(config?: PortalConfig) {
  angular.module(config.module)
    .factory('ContractConsultationFieldConfigurationService', downgradeInjectable(ContractConsultationFieldConfigurationService));


}

import {LoaderService} from '@rgi/portal-ng-core';
import {UntypedFormBuilder} from '@angular/forms';
import {
  AuthorizationsStatesService,AuthorizationsRoutingService,
  AuthorizationsSearchService,
  AuthorizationsListComponent,
  AuthorizationCardStateManagerList} from '@rgi/authorizations-card';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {Component, Inject, OnInit} from '@angular/core';
import { AuthorizationsService } from '../services/authorizations-services';
import {DateTimeAdapter, RgiRxDatatableRowAction} from '@rgi/rx/ui';
import { CommonErrorResponse, PartiesService } from '../../../ext-rest/life/api';
import { ActiveRoute } from '@rgi/rx/router';
import {DatePipe} from "@angular/common";

@Component({
    selector: 'ac-authorizations-list',
    templateUrl: './authorizations-list-ext.component.html',
    styleUrls: ['./authorizations-list-ext.component.css'],
    providers: [AuthorizationsService, PartiesService]
})

export class AuthorizationsListExtComponent extends AuthorizationsListComponent implements OnInit {

  constructor(
    protected stateService: AuthorizationsStatesService,
    protected manager: AuthorizationCardStateManagerList,
    public activeRoute: ActiveRoute,
    protected routingService: AuthorizationsRoutingService,
    protected authorizationsSearchService: AuthorizationsSearchService,
    protected formBuilder: UntypedFormBuilder,
    protected translate: RgiRxTranslationService,
    protected datePipe: DatePipe,
    protected i18nService: RgiRxTranslationService,
    protected loaderService: LoaderService,
    protected partiesService: PartiesService,
    @Inject(DateTimeAdapter) protected dateTimeAdapter: any
  ) {
    super(stateService, manager, activeRoute, routingService, authorizationsSearchService, formBuilder, translate, datePipe, i18nService, dateTimeAdapter);
  }

    errorMessages: string[] = [];

    ngOnInit(){
       super.ngOnInit();
    }

    public onActionClick($event: RgiRxDatatableRowAction<any>) {
      this.errorMessages.length = 0;
      const selectedAuth = this.authorizationList.find(auth => auth.requestCode === $event.row.authCode);
      this.loaderService.startLoader(this.navigation);
      this.partiesService.getPartyNiceActimizeCheck(selectedAuth).subscribe(
          result => {
              const res = result as any
              this.loaderService.stopLoader(this.navigation);
              if (res.error) {
                  result = res as CommonErrorResponse
                  if (result.error.message) {
                      this.errorMessages.push(result.error.message)
                  } else {
                      this.errorMessages.push('_IIAB_._MOTOR_._NICE_ACTIMIZE_KO_')
                  }
              } else {
                  super.onActionClick(selectedAuth)

              }

          }),
          err => {
              this.loaderService.stopLoader(this.navigation);
          }

    }

}


import {Injectable} from '@angular/core';
import {ApiPcAddress} from '../../models/api-models/apiPcAddress';
import {GenericEntityPcPortfolio} from '../../models/pc-portfolio-models/genericEntityPcPortfolio';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {AnagEntityIta} from '@rgi/anag';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePolicyAddressUtilityService {
  constructor() {
  }

  addressApiToPcAddress(event: any): ApiPcAddress {
    const response = new ApiPcAddress();

    response.formattedAddress = this.getFormatAddress(event);

    if (event.level5Control && event.level5Control.code) {
      response.postalCode = event.level5Control.code;
    } else {
      response.postalCode = event.cap;
    }

    response.country = new GenericEntityPcPortfolio();
    if (event.countryCode) {
      response.country.code = event.countryCode;
    } else if (event.country) {
      response.country.description = event.country.description;
      response.country.code = event.country.code;
    }


    response.city = new GenericEntityPcPortfolio();
    if (event.level3Control && event.level3Control.code) {
      response.city.description = event.level3Control.description;
      response.city.code = event.level3Control.code;
    } else {
      response.city.description = event.adminLevel3;
    }

    response.district = new GenericEntityPcPortfolio();
    if (event.level2Control && event.level2Control.code) {
      response.district.description = event.level2Control.description;
      response.district.code = event.level2Control.code;
    } else {
      response.district.description = event.adminLevel2;
      response.district.code = event.adminLevel2Short;
    }

    response.toponym = new GenericEntity(null, event.toponym != null ? event.toponym.codice : '', event.toponym != null ? event.toponym.descrizione : '');

    response.id = event.id ? event.id : null;
    response.region = null;
    response.additionalLabels = null;
    response.main = true;
    response.normalized = false;
    if (event.houseNumber) {
      response.number = event.houseNumber;
    } else {
      response.number = event.number;
    }
    response.address = event.placeAddress;

    return response;
  }

  pcAddressToAddressData(element: ApiPcAddress): AddressApiContacts {
    const response = new AddressApiContacts();
    response.id = element.id;
    response.key = element.id;
    response.country = this.genericEntityPortfolioToGenericEntity(element.country);
    response.province = this.genericEntityPortfolioToGenericEntity(element.district);
    response.adminLevel2 = element.district ? element.district.description : '';
    response.adminLevel2Short = element.district ? element.district.code : '';
    response.adminLevel3 = element.city ? element.city.description : '';
    response.city = element.city ? element.city.code : '';
    response.placeAddress = element.address;
    response.number = element.number;
    response.cap = element.postalCode;
    response.toponym = new AnagEntityIta(element.toponym.code ? element.toponym.code : '', element.toponym ? element.toponym.description : '');
    response.formatAddress = element.formattedAddress;
    response.city = element.city ? element.city.description : '';
    response.main = element.main;

    return response;
  }

  genericEntityPortfolioToGenericEntity(genericEntityP: GenericEntityPcPortfolio): GenericEntity {
    if (genericEntityP) {
      return new GenericEntity(null, genericEntityP.code, genericEntityP.description);
    }
    return null;
  }

  getFormatAddress(addressData: any) {
    const toponym = addressData.toponym ? addressData.toponym : '';
    const address = addressData.placeAddress ? addressData.placeAddress : '';
    let addressNumber;
    let level5;
    let level1;
    let level2;
    let level3;

    if (addressData.houseNumber) {
      addressNumber = addressData.houseNumber;
    } else {
      addressNumber = addressData.number;
    }
    if (addressData.level5Control && addressData.level5Control.code) {
      level5 = addressData.level5Control.code;
    } else {
      level5 = addressData.cap;
    }
    if (addressData.countryCode) {
      level1 = addressData.countryCode;
    } else if (addressData.country) {
      level1 = addressData.country.code;
    } else {
      level1 = addressData.adminLevel1;
    }
    if (addressData.level2Control && addressData.level2Control.code) {
      level2 = addressData.level2Control.code;
    } else {
      level2 = addressData.adminLevel2Short;
    }
    if (addressData.level3Control && addressData.level3Control.description) {
      level3 = addressData.level3Control.description;
    } else {
      level3 = addressData.adminLevel3;
    }
    return ((toponym ? toponym.descrizione + ' ' : '') +
      (address ? address + ', ' : '') +
      (addressNumber ? addressNumber + ' ' : '') +
      (level5 ? '- ' + level5 + ' ' : '') +
      (level3 ? level3 + ' ' : '') +
      (level2 ? '(' + level2 + ') ' : '') +
      (level1 ? '- ' + level1 : '')).toUpperCase();

  }
}

import { Party } from '@rgi/digital-claims-common-angular';
import { PartyData } from './party-data';

export class MultiPartyEntity {
    public partyList: Array<PartyData>;
    public enableMultiParty: boolean;
    public uuid: string;
    public regAmm: boolean;
    public regTech: boolean;
    public selectedInsuredRadio: Party;
}

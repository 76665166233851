import {GenericEntity} from './generic-entity';

export class AddressApiContacts {
  id: string;
  country: GenericEntity;
  province: GenericEntity;
  city: string;
  placeAddress: string;
  number: string;
  cap: string;
  countryCode: string;
  adminLevel1: string;
  adminLevel1Short: string;
  adminLevel2: string;
  adminLevel2Short: string;
  adminLevel3: string;
  adminLevel3Short: string;
  formatAddress: string;
  locality: string;
  fraction: string;
  toponym: any;
  normalized: boolean;
  main: boolean;
  key: any;
}

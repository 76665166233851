import {
  isPortalRouteDefault,
  PortalCards,
  portalControllerFactory,
  RGI_RX_PORTAL_TPL,
  RGI_RX_PORTAL_TPL_CONTENT,
  UserBindingProvider
} from './ajs-integration-api';
import {TokenProviderService, UserService} from '@rgi/rx/auth';
import {Logger} from '@rgi/rx';
import {UserAuthorizationService} from '@rgi/rx/auth';
import {PortalConfig} from './rgi-rx-portal-api';

declare var angular: angular.IAngularStatic;

/**
 * A factory that compact all the PortalCards and create PassPortal Card and PassPortal router bindings with @rgi/rx/router.
 * @param portalConfig Configuration of the current @rgi/rx/portal module
 * @param logger The logger to used
 * @param portalCardConfigs? All the injection tokens of PortalCards
 * @see PortalCards
 * @see PortalConfig
 * @see RgiRxPortalModule.forRoot
 */
export function portalCardRegistrationFactory(portalConfig: PortalConfig, logger: Logger, portalCardConfigs?: PortalCards) {
  const module = angular.module(portalConfig.module);
  ([] as PortalCards).concat(...portalCardConfigs).forEach(
    config => {
      module.config(['cardManagementServiceProvider', 'parallelRoutingProvider', (cardManagement, parallelRoutingProvider) => {
        logger.debug(`RgiRxPortal registered card ${config.card.name}`, config);
        cardManagement.register(config.card);
        config.routes.forEach(
          route => {
            if (isPortalRouteDefault(route)) {
              parallelRoutingProvider.when(config.card.name, route.route, {
                template: RGI_RX_PORTAL_TPL,
                label: route.label,
                controller: route.controller ? route.controller : portalControllerFactory(route.destination, route.route)
              });
            } else {
              parallelRoutingProvider.when(config.card.name, route.route, {
                template: route.template,
                label: route.label,
                controller: route.controller
              });
            }
          }
        );
      }]);
    }
  );
}

/**
 * A factory that provide bindings with @rgi/rx/auth with digital-core-auth
 * @see AuthModule
 * @param portalConfig Configuration of the current @rgi/rx/portal module
 * @param tokenProvider The TokenProviderService instance for the bind
 * @param userService the UserService holding user information
 * @param userAuthorizationService Generic user service for the bind
 * @param userBinding the user binding provider
 * @param logger logger binding
 */
export function portalAuthFactory(portalConfig: PortalConfig, tokenProvider: TokenProviderService, userService: UserService, userAuthorizationService: UserAuthorizationService, userBinding: UserBindingProvider<any>, logger: Logger) {
  angular.module(portalConfig.module).run(['eventService', 'coreAuthService', 'functionListService', 'authService', (eventService, coreAuthService, functionListService, authService) => {
    const listenLoginCompleted = eventService.listenGlobalEvent('login-completed', (event, args) => {
      if (!!coreAuthService.getToken()) {
        listenLoginCompleted();
        tokenProvider.token = coreAuthService.getToken();
        logger.debug('PortalTokenIntegrationService::register token has been set from login-completed');
      }
    });
    const nodeStarted = eventService.listenGlobalEvent('node-started', (event, args) => {
      if (!!coreAuthService.getToken()) {
        nodeStarted();
        tokenProvider.token = coreAuthService.getToken();
        logger.debug('PortalTokenIntegrationService::register token has been set from node-started');
      }
    });
    eventService.listenGlobalEvent('node-completed', (event, args) => {
      userService.user = userBinding.getUser(authService);
    });
    eventService.listenGlobalEvent('functions-completed', (event, args) => {
      userAuthorizationService.putAuthorizations(functionListService.getFunctions());
    });
  }]);
}

/**
 * Factory that register RgiRxPortal templates in AngularJS $templateCache provider
 * @param portalConfig
 */
export function portal$TemplateCacheFactory(portalConfig: PortalConfig) {
  angular.module(portalConfig.module).run(['$templateCache', ($templateCache) => {
    $templateCache.put(RGI_RX_PORTAL_TPL, RGI_RX_PORTAL_TPL_CONTENT);
  }]);
}


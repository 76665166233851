import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbDateAdapter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RgiAnagModule} from '@rgi/anag';
import {RgiCountryLayerModule} from '@rgi/country-layer';
import {EVENTS, RgiRxFormModule, RgiRxQualityAssuranceModule} from '@rgi/rx';
import {RgiRxAuthModule} from '@rgi/rx/auth';
import {RgiRxI18nModule, RgiRxTranslationService} from '@rgi/rx/i18n';
import {RgiRxRoutingModule} from '@rgi/rx/router';
import {
  DateTimeAdapter,
  RGI_RX_DATA_TABLE_PIPE,
  RgiRxDatePickerModule,
  RgiRxDragDropModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule,
  RgiRxModalModule,
  RgiRxMultiselectModule,
  RgiRxNativeDateTimeModule,
  RgiRxPanelModule,
  RgiRxSnackbarModule,
  RgiRxStepperModule,
  RgiRxSwitchModule,
  RgiRxTableModule,
  RgiRxTabModule
} from '@rgi/rx/ui';
import {GroupPolicyDateAdapter} from './adapters/ngbDateCustomParserFormatter ';
import {GPBalanceDetailComponent} from './group-policy-components/gp-balance-detail/gpbalance-detail.component';
import {GpPartyRolesComponent} from './group-policy-components/gp-party-roles/gp-party-roles.component';
import {
  GroupPolicyApplicationsListComponent
} from './group-policy-components/group-policy-applications-list/group-policy-applications-list.component';
import {GroupPolicyAssetsComponent} from './group-policy-components/group-policy-assets/group-policy-assets.component';
import {
  GpFilterAssetClausesPipe
} from './group-policy-components/group-policy-assets/pipes/gp-filter-asset-clauses.pipe';
import {
  GpFilterAssetUnitsClausesPipe
} from './group-policy-components/group-policy-assets/pipes/gp-filter-asset-units-clauses.pipe';
import {GroupPolicyClauseComponent} from './group-policy-components/group-policy-clause/group-policy-clause.component';
import {
  GroupPolicyClustersComponent
} from './group-policy-components/group-policy-clusters/group-policy-clusters.component';
import {
  GpFilterAssetClusterUnitsCausesPipe
} from './group-policy-components/group-policy-clusters/pipes/gp-filter-asset-cluster-units-causes.pipe';
import {
  GroupPolicyContactDetailsComponent
} from './group-policy-components/group-policy-contact-details-component/group-policy-contact-details.component';
import {
  GPClusterDetailComponent
} from './group-policy-components/group-policy-details-components/gpcluster-detail/gpcluster-detail.component';
import {
  GPGenericDetailComponent
} from './group-policy-components/group-policy-details-components/gpgeneric-detail/gpgeneric-detail.component';
import {
  GPUnitsDetailComponent
} from './group-policy-components/group-policy-details-components/gpunits-detail-component/g-p-units-detail.component';
import {
  GroupPolicyPolicyErrorsComponent
} from './group-policy-components/group-policy-errors-component/group-policy-errors.component';
import {
  GroupPolicyExtensionDataComponent
} from './group-policy-components/group-policy-extension-data/group-policy-extension-data.component';
import {
  GroupPolicyPolicyFactorsComponent
} from './group-policy-components/group-policy-factors/group-policy-factors-component/group-policy-factors.component';
import {
  GroupPolicyPolicyFactorsDatepikerComponent
} from './group-policy-components/group-policy-factors/group-policy-factors-datepicker-component/group-policy-factors-datepicker.component';
import {
  FocusElementDirective
} from './group-policy-components/group-policy-factors/group-policy-factors-directives/focus-element.directive';
import {
  GroupPolicyPolicyFactorsInputComponent
} from './group-policy-components/group-policy-factors/group-policy-factors-input-component/group-policy-factors-input.component';
import {
  GroupPolicyPolicyFactorsNumericComponent
} from './group-policy-components/group-policy-factors/group-policy-factors-numeric-component/group-policy-factors-numeric.component';
import {
  GroupPolicyPolicyFactorsRadioComponent
} from './group-policy-components/group-policy-factors/group-policy-factors-radio-component/group-policy-factors-radio.component';
import {
  GroupPolicyPolicyFactorsSelectComponent
} from './group-policy-components/group-policy-factors/group-policy-factors-select-component/group-policy-factors-select.component';
import {GroupPolicyFieldsComponent} from './group-policy-components/group-policy-fields/group-policy-fields.component';
import {
  GpFieldsCustomActionLabelPipe
} from './group-policy-components/group-policy-fields/pipes/gp-fields-custom-action-label.pipe';
import {
  GpFieldsShowCustomActionPipe
} from './group-policy-components/group-policy-fields/pipes/gp-fields-show-custom-action.pipe';
import {
  GpAmendmentModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-amendment/gp-amendment-modal.component';
import {
  GroupPolicyModalAuthorizationDataComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-authorization-data/group-policy-modal-authorization-data.component';
import {
  GroupPolicyModalAvailableAssetsComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-available-assets/group-policy-modal-available-assets.component';
import {
  GroupPolicyModalCoinsurancesComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-coinsurances/group-policy-modal-coinsurances.component';
import {
  GroupPolicyModalCommissionsComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-commissions-component/group-policy-modal-commissions.component';
import {
  GroupPolicyModalCommissionsLifeComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-commissions-life-component/group-policy-modal-commissions-life.component';
import {
  GroupPolicyModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-component/group-policy-modal.component';
import {
  GpConfirmationModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-confirmation/gp-confirmation-modal.component';
import {
  GPIncludeApplicationModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-include-application/include-application-modal.component';
import {
  GroupPolicyInquiryActionsModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-inquiry-actions/group-policy-inquiry-actions-modal.component';
import {
  GroupPolicyModalOptionSelectionComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-option-selection/group-policy-modal-option-selection.component';
import {
  GroupPolicyQuotationDetailModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-quotation-detail-component/group-policy-modal-quotation-detail.component';
import {
  GroupPolicyQuotationGuaranteeDetailsModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-quotation-guarantee-details-component/group-policy-modal-quotation-guarantee-details.component';
import {
  GpRecoverApplicationsModalComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-recover-applications/recover-applications-modal.component';
import {
  GroupPolicyModalTextBoxComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-textbox/group-policy-modal-text-box.component';
import {
  GroupPolicyPaymentFieldsComponent
} from './group-policy-components/group-policy-payments/group-policy-payment-fields-component/group-policy-payment-fields.component';
import {
  GroupPolicyPaymentsModalComponent
} from './group-policy-components/group-policy-payments/group-policy-payments-modal/group-policy-payments-modal.component';
import {
  GroupPolicyPaymentsSectionComponent
} from './group-policy-components/group-policy-payments/group-policy-payments-section/group-policy-payments-section.component';
import {
  GroupPolicyQuestionnairesComponent
} from './group-policy-components/group-policy-questionnaires-component/group-policy-questionnaires.component';
import {
  GroupPolicyQuotationsComponent
} from './group-policy-components/group-policy-quotations-component/group-policy-quotations.component';
import {
  GroupPolicyStepperComponent
} from './group-policy-components/group-policy-stepper/group-policy-stepper.component';
import {GroupPolicyUnitComponent} from './group-policy-components/group-policy-units/group-policy-unit.component';
import {GpExistUnitClausesPipe} from './group-policy-components/group-policy-units/pipes/gp-exist-unit-clauses.pipe';
import {GpFilterUnitClausesPipe} from './group-policy-components/group-policy-units/pipes/gp-filter-unit-clauses.pipe';
import {GroupPolicyEditComponent} from './group-policy-edit/group-policy-edit.component';
import {GROUP_POLICY_EVENTS} from './group-policy-events/group-policy-event';
import {GroupPolicyDirectiveModule} from './group-policy-ext/group-policy-directive.module';
import {GROUP_POLICY_INQUIRY_ROUTES} from './group-policy-inquiry/gp-inquiry-routes';
import {GroupPolicyInquiryComponent} from './group-policy-inquiry/group-policy-inquiry.component';
import {
  GPBalanceDetailInquiryComponent
} from './group-policy-inquiry/inquiry-components/gpbalance-detail-inquiry/gpbalance-detail-inquiry.component';
import {
  GroupPolicyAppConfigurationDetailComponent
} from './group-policy-inquiry/inquiry-components/group-policy-app-configuration-detail/group-policy-app-configuration-detail.component';
import {
  GroupPolicyApplicationsListInquiryComponent
} from './group-policy-inquiry/inquiry-components/group-policy-applications-list-inquiry/group-policy-applications-list-inquiry.component';
import {
  GroupPolicyContractDetailComponent
} from './group-policy-inquiry/inquiry-components/group-policy-contract-detail/group-policy-contract-detail.component';
import {
  GroupPolicyInquiryAssetsDetailsComponent
} from './group-policy-inquiry/inquiry-components/group-policy-inquiry-assets-details/group-policy-inquiry-assets-details.component';
import {
  GroupPolicyMovementsDetailComponent
} from './group-policy-inquiry/inquiry-components/group-policy-movements-detail/group-policy-movements-detail.component';
import {
  GroupPolicyPartiesDetailComponent
} from './group-policy-inquiry/inquiry-components/group-policy-parties-detail/group-policy-parties-detail.component';
import {
  GroupPolicyPMDataDetailComponent
} from './group-policy-inquiry/inquiry-components/group-policy-pmdata-detail/group-policy-pmdata-detail.component';
import {
  GroupPolicyUnitDetailComponent
} from './group-policy-inquiry/inquiry-components/group-policy-unit-detail/group-policy-unit-detail.component';
import {GPDatePipe, gpDateTablePipe} from './group-policy-pipes/gpdate.pipe';
import {GPSignedNumberPipe} from './group-policy-pipes/gpsigned-number.pipe';
import {
  GP_COMMISSIONS_DISCOUNT_BUTTONS,
  GP_COMMISSIONS_DISCOUNT_CONFIG
} from './group-policy-resources/gp-commission-discount.config';
import {
  GP_INQUIRY_COMPONENTS,
  GP_INQUIRY_COMPONENTS_LIST
} from './group-policy-resources/gp-inquiry-component-interface';
import {GroupPolicyResourcesModule} from './group-policy-resources/group-policy-resources.module';
import {GroupPolicyActionIntegrationService} from './group-policy-services/group-policy-action-integration.service';
import {GroupPolicyCrossService} from './group-policy-services/group-policy-cross.service';
import {GroupPolicyIntegrationService} from './group-policy-services/group-policy-integration.service';
import {GroupPolicyStateModule} from './group-policy-state/group-policy-state.module';
import {
  GroupPolicyConfigurationPmComponent
} from './group-policy-steps/group-policy-configuration-pm/group-policy-configuration-pm.component';
import {
  GroupPolicyGuaranteesComponent
} from './group-policy-steps/group-policy-guarantees/group-policy-guarantees.component';
import {GroupPolicyHomeComponent} from './group-policy-steps/group-policy-home/group-policy-home.component';
import {
  GroupPolicyIssueConfirmComponent
} from './group-policy-steps/group-policy-issue-confirm/group-policy-issue-confirm.component';
import {
  GroupPolicyPolicyDataComponent
} from './group-policy-steps/group-policy-policy-data/group-policy-policy-data.component';
import {
  GroupPolicyProposalIssueComponent
} from './group-policy-steps/group-policy-proposal-issue/group-policy-proposal-issue.component';
import {
  GroupPolicySummaryAdmDataComponent
} from './group-policy-steps/group-policy-summary/group-policy-summary-component/group-policy-summary-adm-data-component/group-policy-summary-adm-data.component';
import {
  GroupPolicySummaryAssetsComponent
} from './group-policy-steps/group-policy-summary/group-policy-summary-component/group-policy-summary-assets-component/group-policy-summary-assets.component';
import {
  GroupPolicySummaryPartiesComponent
} from './group-policy-steps/group-policy-summary/group-policy-summary-component/group-policy-summary-parties-component/group-policy-summary-parties.component';
import {
  GroupPolicySummaryQuotationDetailComponent
} from './group-policy-steps/group-policy-summary/group-policy-summary-component/group-policy-summary-quotation-detail-component/group-policy-summary-quotation-detail.component';
import {GroupPolicySummaryComponent} from './group-policy-steps/group-policy-summary/group-policy-summary.component';
import {
  GroupPolicyVcontConfirmsComponent
} from './group-policy-vcont-steps/group-policy-vcont-confirms/group-policy-vcont-confirms.component';
import {
  GroupPolicyVcontDateComponent
} from './group-policy-vcont-steps/group-policy-vcont-date/group-policy-vcont-date.component';
import {
  GroupPolicyVcontQuestionnaireComponent
} from './group-policy-vcont-steps/group-policy-vcont-questionnaire/group-policy-vcont-questionnaire.component';
import {
  GroupPolicyVcontVariationComponent
} from './group-policy-vcont-steps/group-policy-vcont-variation/group-policy-vcont-variation.component';
import {GROUP_POLICY_ROUTES} from './group-policy.routes';
import {GROUP_POLICY_TRANSLATIONS} from './i18n/translations';
import {
  GroupPolicyCoinsurancesDetailComponent
} from './group-policy-inquiry/inquiry-components/group-policy-coinsurances-detail/group-policy-coinsurances-detail.component';
import {
  GpSubstitutedPoliciesInquiryComponent
} from './group-policy-inquiry/inquiry-components/gpsubstituted-policies-inquiry-component/gp-substituted-policies-inquiry.component';
import {GpSubstVariationTypePipe} from './group-policy-pipes/gp-subst-variation-type.pipe';
import {
  GroupPolicyModalManualPolicyNumberComponent
} from "./group-policy-components/group-policy-modals/group-policy-modal-manual-policy-number/group-policy-modal-manual-policy-number-component";
import {
  GpClausesDetailComponent
} from './group-policy-components/group-policy-details-components/gp-clauses-detail/gp-clauses-detail.component';
import {
  GroupPolicyModalShowClauseComponent
} from './group-policy-components/group-policy-modals/group-policy-modal-show-clause/group-policy-modal-show-clause.component';
import {
  GpProductClausesInquiryComponent
} from './group-policy-inquiry/inquiry-components/gp-product-clauses-inquiry/gp-product-clauses-inquiry.component';

@NgModule({
    declarations: [
        GroupPolicyHomeComponent,
        GroupPolicyPolicyDataComponent,
        GroupPolicyConfigurationPmComponent,
        GroupPolicyPolicyFactorsComponent,
        GroupPolicyPolicyFactorsInputComponent,
        GroupPolicyPolicyFactorsRadioComponent,
        GroupPolicyPolicyFactorsSelectComponent,
        GroupPolicyPolicyFactorsDatepikerComponent,
        GroupPolicyPaymentFieldsComponent,
        GroupPolicyPolicyFactorsNumericComponent,
        GroupPolicyPolicyErrorsComponent,
        GroupPolicyModalComponent,
        GroupPolicyGuaranteesComponent,
        GroupPolicySummaryComponent,
        GroupPolicySummaryAdmDataComponent,
        GroupPolicySummaryAssetsComponent,
        GroupPolicySummaryPartiesComponent,
        GroupPolicySummaryQuotationDetailComponent,
        GroupPolicyContactDetailsComponent,
        GroupPolicyModalCommissionsComponent,
        GroupPolicyModalCommissionsLifeComponent,
        GroupPolicyEditComponent,
        GroupPolicyQuestionnairesComponent,
        GroupPolicyQuotationsComponent,
        GroupPolicyQuotationDetailModalComponent,
        GroupPolicyQuotationGuaranteeDetailsModalComponent,
        GroupPolicyInquiryComponent,
        GroupPolicyApplicationsListComponent,
        GroupPolicyInquiryActionsModalComponent,
        GroupPolicyProposalIssueComponent,
        GPIncludeApplicationModalComponent,
        GroupPolicyModalCoinsurancesComponent,
        GroupPolicyClauseComponent,
        GroupPolicyModalTextBoxComponent,
        GroupPolicyPaymentsModalComponent,
        GroupPolicyPaymentsSectionComponent,
        GroupPolicyAssetsComponent,
        GroupPolicyUnitComponent,
        GroupPolicyModalAvailableAssetsComponent,
        GroupPolicyStepperComponent,
        GroupPolicyFieldsComponent,
        GroupPolicyVcontDateComponent,
        GroupPolicyVcontVariationComponent,
        GroupPolicyVcontQuestionnaireComponent,
        GpFieldsCustomActionLabelPipe,
        GroupPolicyVcontConfirmsComponent,
        GroupPolicyIssueConfirmComponent,
        GpFieldsShowCustomActionPipe,
        GpFilterAssetClausesPipe,
        GpFilterAssetUnitsClausesPipe,
        GpFilterUnitClausesPipe,
        GpExistUnitClausesPipe,
        GroupPolicyExtensionDataComponent,
        GroupPolicyModalOptionSelectionComponent,
        GroupPolicyAppConfigurationDetailComponent,
        GroupPolicyMovementsDetailComponent,
        GroupPolicyContractDetailComponent,
        GroupPolicyPartiesDetailComponent,
        GroupPolicyUnitDetailComponent,
        GroupPolicyPMDataDetailComponent,
        GpPartyRolesComponent,
        GroupPolicyApplicationsListInquiryComponent,
        GroupPolicyClustersComponent,
        GPGenericDetailComponent,
        GPUnitsDetailComponent,
        GPClusterDetailComponent,
        GroupPolicyInquiryAssetsDetailsComponent,
        GroupPolicyModalAuthorizationDataComponent,
        GpFilterAssetClusterUnitsCausesPipe,
        GPDatePipe,
        GPBalanceDetailComponent,
        GPSignedNumberPipe,
        GPBalanceDetailInquiryComponent,
        GpRecoverApplicationsModalComponent,
        GpConfirmationModalComponent,
        GpAmendmentModalComponent,
        FocusElementDirective,
        GroupPolicyCoinsurancesDetailComponent,
        GpSubstitutedPoliciesInquiryComponent,
        GpSubstVariationTypePipe,
        GroupPolicyModalManualPolicyNumberComponent,
        GpClausesDetailComponent,
        GroupPolicyModalShowClauseComponent,
        GpProductClausesInquiryComponent
    ],
    imports: [
        GroupPolicyResourcesModule,
        RgiRxRoutingModule.forRoot(GROUP_POLICY_ROUTES),
        RgiRxRoutingModule.forRoot(GROUP_POLICY_INQUIRY_ROUTES),
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CommonModule,
        GroupPolicyStateModule,
        RgiRxAuthModule,
        RgiRxExpansionModule,
        RgiRxModalModule,
        RgiRxPanelModule,
        RgiAnagModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(GROUP_POLICY_TRANSLATIONS),
        RgiRxTableModule,
        RgiRxFormModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        BrowserAnimationsModule,
        RgiRxDragDropModule,
        RgiRxTabModule,
        RgiRxStepperModule,
        RgiRxSwitchModule,
        RgiRxQualityAssuranceModule,
        GroupPolicyDirectiveModule,
        RgiRxSnackbarModule,
        RgiCountryLayerModule,
        RgiRxMultiselectModule,
    ],
    providers: [
        { provide: NgbDateAdapter, useExisting: GroupPolicyDateAdapter },
        { provide: EVENTS, useValue: GROUP_POLICY_EVENTS, multi: true },
        { provide: GP_INQUIRY_COMPONENTS, useValue: GP_INQUIRY_COMPONENTS_LIST, multi: true },
        { provide: GP_COMMISSIONS_DISCOUNT_BUTTONS, useValue: GP_COMMISSIONS_DISCOUNT_CONFIG },
        { provide: 'SHOW_UPLOAD_FILE_BTN', useValue: true },
        {
            provide: RGI_RX_DATA_TABLE_PIPE,
            multi: true,
            useFactory: gpDateTablePipe,
            deps: [RgiRxTranslationService, DatePipe, DateTimeAdapter]
        },
        GroupPolicyIntegrationService,
        GroupPolicyActionIntegrationService,
        GroupPolicyCrossService,
        GPSignedNumberPipe
    ],
    exports: [
        GroupPolicyHomeComponent,
        GroupPolicyPolicyDataComponent,
        GroupPolicyConfigurationPmComponent,
        GroupPolicyPolicyFactorsComponent,
        GroupPolicyPolicyFactorsInputComponent,
        GroupPolicyPolicyFactorsRadioComponent,
        GroupPolicyPolicyFactorsSelectComponent,
        GroupPolicyPolicyFactorsDatepikerComponent,
        GroupPolicyPaymentFieldsComponent,
        GroupPolicyPolicyFactorsNumericComponent,
        GroupPolicyPolicyErrorsComponent,
        GroupPolicyModalComponent,
        GroupPolicyGuaranteesComponent,
        GroupPolicySummaryComponent,
        GroupPolicySummaryAdmDataComponent,
        GroupPolicySummaryAssetsComponent,
        GroupPolicySummaryPartiesComponent,
        GroupPolicySummaryQuotationDetailComponent,
        GroupPolicyContactDetailsComponent,
        GroupPolicyModalCommissionsComponent,
        GroupPolicyModalCommissionsLifeComponent,
        GroupPolicyEditComponent,
        GroupPolicyQuestionnairesComponent,
        GroupPolicyQuotationsComponent,
        GroupPolicyQuotationDetailModalComponent,
        GroupPolicyQuotationGuaranteeDetailsModalComponent,
        GroupPolicyInquiryComponent,
        GroupPolicyApplicationsListComponent,
        GroupPolicyUnitComponent,
        GPIncludeApplicationModalComponent,
        GroupPolicyModalCoinsurancesComponent,
        GroupPolicyClauseComponent,
        GroupPolicyModalTextBoxComponent,
        GroupPolicyVcontDateComponent,
        GroupPolicyVcontVariationComponent,
        GroupPolicyVcontQuestionnaireComponent,
        GroupPolicyVcontConfirmsComponent,
        GroupPolicyIssueConfirmComponent,
        GroupPolicyStepperComponent,
        GroupPolicyFieldsComponent,
        GroupPolicyPaymentsSectionComponent,
        GroupPolicyExtensionDataComponent,
        GpFilterAssetUnitsClausesPipe,
        GpFilterAssetClausesPipe,
        GroupPolicyAppConfigurationDetailComponent,
        GroupPolicyMovementsDetailComponent,
        GroupPolicyContractDetailComponent,
        GroupPolicyPartiesDetailComponent,
        GroupPolicyUnitDetailComponent,
        GroupPolicyPMDataDetailComponent,
        GroupPolicyApplicationsListInquiryComponent,
        GpPartyRolesComponent,
        GroupPolicyInquiryAssetsDetailsComponent,
        GroupPolicyModalAuthorizationDataComponent,
        GroupPolicyCoinsurancesDetailComponent
    ]
})

export class GroupPolicyModule { }

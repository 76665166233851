import {Injectable} from '@angular/core';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable, of} from 'rxjs';
import {RePostsalesState} from './re-postsales-state';
import {EventService} from '@rgi/rx';
import {take} from 'rxjs/operators';
import {RePostsalesNavigationData} from '../re-postsale-model/re-postsales-navigation-data';


@Injectable()
export class RePostsalesStateManagerService<T extends RePostsalesState> extends AbstractStateManager<T> {


  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    public eventService: EventService,
  ) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<T>(this.activeRoute.id) ?
      this.getDamagesPostsalesState() :
      this.newDamagesPostsalesState();
    this.updateState$(of(st)); // this update the state
  }

  newDamagesPostsalesState(): T {
    const navData = this.activeRoute.getRouteData<RePostsalesNavigationData>();
    const damagesPostsalesState = new RePostsalesState(this.activeRoute.key) as T;
    if (navData) {
      damagesPostsalesState.operation = navData.operation;
      damagesPostsalesState.contract = navData.contract;
      damagesPostsalesState.resourceId = navData.resourceId;
      damagesPostsalesState.policyNumber = navData.contract.policyNumber;
      damagesPostsalesState.cancellationProperties = navData.cancellationProperties;
      if (damagesPostsalesState.operation) {
        this.loadOperationMapper(damagesPostsalesState);
      }
    }
    return damagesPostsalesState;
  }

  getDamagesPostsalesState(): T {
    const navData = this.activeRoute.getRouteData<RePostsalesNavigationData>();
    const damagesPostsalesState = this.stateStoreService.get<T>(this.activeRoute.id);
    if (navData.operation) {
      damagesPostsalesState.operation = navData.operation;
    }
    if (navData.contract) {
      damagesPostsalesState.contract = navData.contract;
    }
    if (navData.resourceId) {
      damagesPostsalesState.resourceId = navData.resourceId;
    }
    if (navData.contract && navData.contract.policyNumber) {
      damagesPostsalesState.policyNumber = navData.contract.policyNumber;
    }
    if (navData.cancellationProperties) {
      damagesPostsalesState.cancellationProperties = navData.cancellationProperties;
    }
    if (damagesPostsalesState.operation) {
      this.loadOperationMapper(damagesPostsalesState);
    }
    return damagesPostsalesState;
  }

  loadOperationMapper(damagesPostsalesState: RePostsalesState) {
    // const selectedOperation = OPERATION_MAPPER_DEFAULT.find(operation => {
    //   return operation.code.includes(damagesPostsalesState.operation.code);
    // });
    // if (selectedOperation) {
    //   damagesPostsalesState.stepFlow = selectedOperation.steps.sort((a: Step, b: Step) => a.index - b.index);
    // }
  }

  public nextStep() {
    if (this.hasNextStep()) {
      // const currentStateIndex = this.getCurrentStateIndex();
      // const route = this.getCurrentState().stepFlow[currentStateIndex + 1].route;
      // const navData: RePostsalesNavigationData = {
      //   contract: this.getCurrentState().contract,
      //   operation: this.getCurrentState().operation,
      //   resourceId: this.getCurrentState().resourceId,
      //   company: this.getCurrentState().company,
      //   cancellationProperties: this.getCurrentState().cancellationProperties
      // };
      // this.routingService.navigate(route, navData, this.activeRoute.id);
    }
  }

  public hasNextStep(): boolean {
    const st = this.getCurrentState();
    // if (!st.stepFlow && st.operation) {
    //   this.loadOperationMapper(st);
    // }
    // if (st.stepFlow && st.operation) {
    //   const currentStateIndex = this.getCurrentStateIndex() + 1;
    //   return currentStateIndex < this.getCurrentState().stepFlow.length;
    // }
    return false;
  }

  public previousStep() {
    if (this.hasPreviousStep()) {
      // const currentStateIndex = this.getCurrentStateIndex();
      // const route = this.getCurrentState().stepFlow[currentStateIndex - 1].route;
      // const navData: RePostsalesNavigationData = {
      //   contract: this.getCurrentState().contract,
      //   operation: this.getCurrentState().operation,
      //   resourceId: this.getCurrentState().resourceId,
      //   company: this.getCurrentState().company,
      //   cancellationProperties: this.getCurrentState().cancellationProperties
      // };
      // this.routingService.navigate(route, navData, this.activeRoute.id);
    }
  }

  updateState$(storeState$: Observable<T>) {
    storeState$.pipe(
      take(1),
    ).subscribe(
      stStore => {
        if (!this.stateStoreService.has(this.activeRoute.id)) {
          // this.__logger.debug(`AbstractStateManager::updateState$ created new store with id ${this.activeRoute.id}`, stStore);
          this.stateStoreService.set(this.activeRoute.id, stStore);
        } else {
          // this.__logger.debug(`AbstractStateManager::updateState$ updated store with id ${this.activeRoute.key}`, stStore);
          this.stateStoreService.put(stStore);
        }
        this.state$.next(stStore);
      }
    );
  }

  public hasPreviousStep(): boolean {
    const currentStateIndex = this.getCurrentStateIndex();
    return currentStateIndex > 0;
  }

  private getCurrentStateIndex(): number {
    // return this.getCurrentState().stepFlow.findIndex(step => {
    //   return step.route === this.activeRoute.route;
    // });
    return null;
  }

  // public closeApplication() {
  //   this.eventService.emit(new DamagesPostsalesCloseEventInstance(this.activeRoute.id));
  // }

  public setResourceId(resourceId: string): void {
    this.stateStoreService.get<T>(this.activeRoute.id).resourceId = resourceId;
  }

  public setErrorMessages(errorMessages: string[]): void {
    this.stateStoreService.get<T>(this.activeRoute.id).errorMessages = errorMessages;
  }

  public getErrorMessages(): string[] | null {
    return this.stateStoreService.get<T>(this.activeRoute.id).errorMessages;
  }

  public resetErrorMessages(): void {
    this.stateStoreService.get<T>(this.activeRoute.id).errorMessages = null;
  }


}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PcProductAsset} from '../../../models/pc-portfolio-models/assets-models/pc-product-asset';
import {OnModalClose, RgiRxTabSelectionChange} from '@rgi/rx/ui';

@Component({
  selector: 'lib-re-issue-asset-modal',
  templateUrl: './re-issue-asset-modal.component.html'
})
export class ReIssueAssetModalComponent implements OnModalClose, OnInit {
  @Input() productAssets: Array<PcProductAsset>;
  @Output() modalClose = new EventEmitter();
  @Output() contraenteSubject = new EventEmitter<string>();
  selectedValue: string = null;

  constructor() {
  }

  ngOnInit() {
  }

  onSelectionChange(tabSelection: RgiRxTabSelectionChange) {
    this.selectedValue = tabSelection.tab.label;
  }

  setData(event: any) {
    this.selectedValue = event.value;
  }

  actionClose() {
    this.modalClose.emit();
  }

  actionConfirm() {
    this.modalClose.emit(this.selectedValue);
  }
}

import {downgradeInjectable} from '@angular/upgrade/static';
import {PortalConfig} from '@rgi/rx/portal';
import {AnagHomeFieldConfigurationService} from '@rgi/anag';

declare var angular: angular.IAngularStatic;

export function downgradeAnagService(config?: PortalConfig) {
  angular.module(config.module)
    .factory('AnagHomeFieldConfigurationService', downgradeInjectable(AnagHomeFieldConfigurationService));


}

// @ts-ignore
declare var angular: angular.IAngularStatic;

export function AjsPassDamageCardServicesExt() {

  const ptfDamageServicesExt = angular.module('ptfdamage-nmmf-integration-services', []);

  ptfDamageServicesExt.service('PtfDamageDetailServiceFilter', DetailServiceFilter);

  ptfDamageServicesExt.config(['$provide', $provide => {

    $provide.decorator('PtfDamageDetailService',
      ['$delegate', 'PtfDamageContractOrigin', 'CONTRACT_ORIGIN', 'sessionService', 'PtfDamageDetailServiceFilter',
      'DAMAGE_ACTION_CODES',
      function DetailServiceDecorator($delegate, PtfDamageContractOrigin, CONTRACT_ORIGIN, sessionService, PtfDamageDetailServiceFilter,
                                      DAMAGE_ACTION_CODES) {

        const baseService = $delegate;
        const extended = angular.extend({}, baseService);

        extended.getContractOrigin = filter => {
          let contractOrigin = CONTRACT_ORIGIN.PORTALSTANDARDFLOW;

          const contractNumber = {
            policyNumber: undefined,
            proposalNumber: undefined
          };

          if (filter && filter.filter && filter.filter.policyNumber) {
            contractNumber.policyNumber = filter.filter.policyNumber;
          } else if (filter && filter.filter && filter.filter.proposalNumber) {
            contractNumber.proposalNumber = filter.filter.proposalNumber;
          }

          return PtfDamageContractOrigin.getContractOrigin({contractNumber}).$promise.then(
            result => {
              if (result) {
                contractOrigin = result.origin;
              }
              return contractOrigin;
            },
            error => null
          );
        };

        extended.openEditProposalNewMotorFlow = (proposalNumber, policyNumber, createCopy, route, action) => {
          const paramsNMF = {
            route: undefined,
            proposalNumber: undefined,
            policyNumber: undefined,
            createCopy: undefined,
            action: undefined,
            loadContract: true
          };

          paramsNMF.route = 'proposal';
          if (route) {
            paramsNMF.route = route;
          }

          if (action) {
            paramsNMF.action = action;
            if (action.operation.code === 'MOTCOV' || action.operation.code === 'MOTENI' || action.operation.code === 'MOTVAR') {
              paramsNMF.loadContract = false;
            }
          }

          if (policyNumber) {
            paramsNMF.policyNumber = policyNumber;
          } else if (proposalNumber) {
            paramsNMF.proposalNumber = proposalNumber;
          }
          paramsNMF.createCopy = createCopy != null ? createCopy : false;
          sessionService.open('newMotorContract', null, '', true, paramsNMF, false);
        };

        const superDuplicateProposal = extended.duplicateProposal;
        extended.duplicateProposal = (policyId, effectiveDate, contract) => {
          const filterContractOrigin = PtfDamageDetailServiceFilter.filterContractOrigin(contract.objectId,
            contract.proposalNumber, contract.policyNumber);

          extended.getContractOrigin(filterContractOrigin).then(contractOrigin => {
              if (contractOrigin) {
                if (contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
                  extended.openEditProposalNewMotorFlow(filterContractOrigin.filter.proposalNumber,
                    filterContractOrigin.filter.policyNumber, true);
                } else {
                  superDuplicateProposal(policyId, effectiveDate, contract);
                }
              }
            }
          );
        };

        const superActionIssue = extended.actionIssue;
        extended.actionIssue = ($scope) => {
          const filterContractOrigin = PtfDamageDetailServiceFilter.filterContractOrigin($scope.contract.objectId,
            $scope.contract.proposalNumber, $scope.contract.policyNumber);

          $scope.startProgressBar();

          extended.getContractOrigin(filterContractOrigin).then(contractOrigin => {
            if (contractOrigin) {
              if (contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
                extended.openEditProposalNewMotorFlow(filterContractOrigin.filter.proposalNumber,
                  filterContractOrigin.filter.policyNumber, false, 'mandatorydata');
              } else {
                superActionIssue($scope);
              }
            }
          });
        };

        const superActionEditProposal = extended.actionEditProposal;
        extended.actionEditProposal = ($scope, scopeCard, idResult, contract) => {

          const filterContractOrigin = PtfDamageDetailServiceFilter.filterContractOrigin(scopeCard.contract.objectId,
            scopeCard.contract.proposalNumber, scopeCard.contract.policyNumber);

          scopeCard.startProgressBar();
          extended.getContractOrigin(filterContractOrigin).then(contractOrigin => {
            if (contractOrigin && contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
              $scope.cancel();
              extended.openEditProposalNewMotorFlow(filterContractOrigin.filter.proposalNumber,
                filterContractOrigin.filter.policyNumber, false, 'proposal');
            } else {
              superActionEditProposal($scope, scopeCard, idResult, contract);
            }
          }).finally(scopeCard.completeProgressBar);
        };

        const superActionEditInclusionProposal = extended.actionEditInclusionProposal;
        extended.actionEditInclusionProposal = (scope, scopeCard, idResult, contract, go, contractNumber, act) => {

          const filterContractOrigin = PtfDamageDetailServiceFilter.filterContractOrigin(scopeCard.contract.objectId,
            scopeCard.contract.proposalNumber, scopeCard.contract.policyNumber);

          scopeCard.startProgressBar();
          extended.getContractOrigin(filterContractOrigin).then(contractOrigin => {
            if (contractOrigin && contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
              scope.cancel();
              extended.openEditProposalNewMotorFlow(filterContractOrigin.filter.proposalNumber,
                filterContractOrigin.filter.policyNumber, false, 'proposal');
            } else {
              superActionEditInclusionProposal(scope, scopeCard, idResult, contract, go, contractNumber, act);
            }
          }).finally(scopeCard.completeProgressBar);
        };

        const superActionSubstitute = extended.actionSubstitute;
        extended.actionSubstitute = ($scope, scopeCard, idResult, contract, go) => {

          const filterContractOrigin = PtfDamageDetailServiceFilter.filterContractOrigin(scopeCard.contract.objectId,
            scopeCard.contract.proposalNumber, scopeCard.contract.policyNumber);

          scopeCard.startProgressBar();
          extended.getContractOrigin(filterContractOrigin).then(contractOrigin => {
              if (contractOrigin && contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
                $scope.cancel();
                extended.openEditProposalNewMotorFlow(filterContractOrigin.filter.proposalNumber,
                  filterContractOrigin.filter.policyNumber, false, 'substitution');
              } else {
                superActionSubstitute($scope, scopeCard, idResult, contract, go);
              }
            }
          ).finally(scopeCard.completeProgressBar);
        };

        const superActionChangeCoversEndorsement = extended.actionChangeCoversEndorsement;
        extended.actionChangeCoversEndorsement = ($scope, scopeCard, idResult, contract, go, action) => {

          const filterContractOrigin = PtfDamageDetailServiceFilter.filterContractOrigin(scopeCard.contract.objectId,
            scopeCard.contract.proposalNumber, scopeCard.contract.policyNumber);

          scopeCard.startProgressBar();
          extended.getContractOrigin(filterContractOrigin).then(contractOrigin => {
              if (contractOrigin && contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
                $scope.cancel();
                extended.openEditProposalNewMotorFlow(filterContractOrigin.filter.proposalNumber,
                  filterContractOrigin.filter.policyNumber, false, 'amendment', action);
              } else {
                superActionChangeCoversEndorsement($scope, scopeCard, idResult, contract, go, action);
              }
            }
          ).finally(scopeCard.completeProgressBar);
        };

        return extended;
      }
    ]);
  }]);


  DetailServiceFilter.$inject = ['PtfDamageContractOrigin'];

  // tslint:disable-next-line:no-shadowed-variable
  function DetailServiceFilter(PtfDamageContractOrigin) {

    this.filterContractOrigin = _filterContractOrigin;

    function _filterContractOrigin(contractId, proposalNumber, policyNumber) {
      const inputFilter = PtfDamageContractOrigin.getInput();
      inputFilter.filter.policyId = contractId;
      inputFilter.filter.proposalNumber = proposalNumber;
      inputFilter.filter.policyNumber = policyNumber;

      return inputFilter;
    }

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalPortfolioExtrainfoComponent } from './external-portfolio-extrainfo.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../../../assets/i18n/translations';

@NgModule({
    declarations: [
        ExternalPortfolioExtrainfoComponent
    ],
    imports: [
        CommonModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
    exports: [
        ExternalPortfolioExtrainfoComponent,
    ]
})
export class ExternalPortfolioExtrainfoModule { }

import { Injectable } from '@angular/core';

@Injectable()
export class DashboardAuthBusinessService {
  private NOT_IMPLEMENTED_ERROR_STRING = 'Concrete Implementation is demanded to the application provider.';

  constructor() { }

  getNodeId(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getIdPv(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getSalepointId(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getIdCdl(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getUsername(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getAuthJwt(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getNode(): any {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getExecutionId(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  getProfilo(): string {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  setProfilo(profilo: string) {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  setAuthJwt(authJwt: string) {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  setUsername(username: string) {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  setSalepointId(salepointId: string) {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  setIdPv(idPv: string) {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }

  setNode(node: string) {
    throw new Error(this.NOT_IMPLEMENTED_ERROR_STRING);
  }
}

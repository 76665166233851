import {AnagApiResponse} from './anag-api-response';
import {AnagConfiguration} from '../anag-domain/anag-configuration';
import {AnagEntityIta} from './anag-subject-api';
import {AnagExtensionSubject} from "../anag-domain/anag-extension-subject";

export class AnagApiConfigurationFilterRequest {
  filter: AnagConfigurationFilter;

  constructor(filter: AnagConfigurationFilter) {
    this.filter = filter;
  }
}

export class AnagConfigurationFilter {
  legalForm: number;
  country: string;
  operation: string;
  productCode: string;
  productId: number;
  partyRole: number;
  partyType: number;
  extensionSubject: AnagExtensionSubject | undefined;

  constructor() {
    this.legalForm = 999;
    this.partyRole = 999;
  }
}

export class AnagApiConfiguration extends AnagApiResponse {
  output: AnagConfiguration;
}

export class AnagApiPartyTypeConfigRequest {
  personType: number;
  professionCode: string;
  legalForm: number;

  constructor() {
    this.legalForm = 999;
  }
}

export class AnagApiPartyTypeConfig extends AnagApiResponse {
  output: {
    partyType: AnagEntityIta;
  };
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcDatepickerComponent} from './lpc-datepicker/lpc-datepicker.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';

import { LpcHelpModule } from '../lpc-help/lpc-help.module';


@NgModule({
  declarations: [LpcDatepickerComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    LpcHelpModule
  ],
  exports: [LpcDatepickerComponent]
})
export class LpcDatepickerModule { }

<div class="mB1rem">
  <button rgi-rx-button color="primary" (click)="toggleContenuto()">
    <span *ngIf="!showForm">{{ '_CLAIMS_._BUTTONS_._SHOW' | translate }} </span>
    <span *ngIf="showForm">{{ '_CLAIMS_._BUTTONS_._HIDE' | translate }} </span>
    {{ '_CLAIMS_._MINORGUARDIAN' | translate }}
  </button>
</div>
<div *ngIf="listMinorGuardian && listMinorGuardian.length > 0">
  <div class="row">
    <div class="col-md-12 col-xs-12 col-sm-12">
      <div class="row">
        <div class="col-md-4 col-xs-12 col-sm-4">
          <strong>{{ '_CLAIMS_._LEGAL_GUARDIAN_ROLE' | translate }}</strong>
        </div>
        <div class="col-md-4 col-xs-12 col-sm-4">
          <strong>{{ '_CLAIMS_._NAME' | translate }}</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="row line-box" *ngFor="let minorGuardian of listMinorGuardian">
    <div class="col-md-12 col-xs-12 col-sm-12">
      <div class="row d-flex-center">
        <div class="col-md-4 col-xs-12 col-sm-4">
          {{ minorGuardian.enumGuardianRole.descrizione }}
        </div>
        <div class="col-md-4 col-xs-12 col-sm-4">
          {{ minorGuardian.minorGuardianSubject.anagSubject.nominative }}
        </div>
        <div class="col-md-4 col-xs-12 col-sm-4">
          <button rgi-rx-button variant="rounded" (click)="removeGuardian(minorGuardian)" color="secondary">
            <span class="glyphicon glyphicon-trash"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showForm" class="mT1rem">
  <div class="row">
    <div class="col-md-12 col-xs-12 col-sm-12 mB1rem">
      <label rgiRxLabel>{{ '_CLAIMS_._LEGAL_GUARDIAN_ROLE' | translate }}</label>
      <select rgiRxNativeSelect [formControl]="enumGuardianRole">
        <option value=""></option>
        <option *ngFor="let role of guardianRoles" [ngValue]="role">{{role.descrizione}}</option>
      </select>
    </div>
    <div class="col-md-12 col-xs-12 col-sm-12 mB1rem">
      <claims-subject-action [subject]="minorGuardian.minorGuardianSubject?.anagSubject" [selectedNode]="selectedNode"
        [activeRouteId]="activeRouteId" (selectNewSubject)="selectNewSubject($event)">
      </claims-subject-action>
    </div>
    <div class="col-md-12 col-xs-12 col-sm-12">
      <button class="mr-t10" rgi-rx-button (click)="addGuardian()" color="success">{{ '_CLAIMS_._BUTTONS_._SAVE' |
        translate }}</button>
    </div>
  </div>
</div>
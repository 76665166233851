import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {DIALOG_DATA, OnModalClose, RgiRxDataTableDataSource, RgiRxDatatableRowAction, TableSchema} from '@rgi/rx/ui';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs/operators';
import { twoDecimalRejex } from '../../../group-policy-constants/general';
import {
  CoinsuranceShare,
  CoinsuranceShareList,
  Entity,
  GRP_OBJ_MODE
} from '../../../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyResourceService } from '../../../group-policy-resources/group-policy-resource.service';
import { GP_COINSURANCE_TABLE_SCHEMA } from './group-policy-coinsurance-tableschema';

@Component({
  selector: 'rgi-gp-group-policy-modal-coinsurances',
  templateUrl: './group-policy-modal-coinsurances.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyModalCoinsurancesComponent implements OnModalClose, OnInit {

  public modalClose = new EventEmitter<any>();
  public coinsuranceType: Entity;
  public data: CoinsuranceShare[];
  public editableCoinsuranceShare: CoinsuranceShare;
  public ourPercentage: number;
  public totalPercentage: number;
  public totalCommissions: number;
  public tableData = new RgiRxDataTableDataSource<CoinsuranceShare>([]);
  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  public schema: TableSchema = GP_COINSURANCE_TABLE_SCHEMA;

  public GRP_OBJ_MODE = GRP_OBJ_MODE;
  public editorMode = GRP_OBJ_MODE.VIEW;

  public externalCompanies: Entity[];
  public intermediaries: Entity[];

  public coinsuranceShareForm: UntypedFormGroup;

  constructor(protected groupPolicyService: GroupPolicyResourceService, @Optional() @Inject(DIALOG_DATA) data: CoinsuranceShareList) {
    this.coinsuranceType = data.coinsuranceType;
    this.ourPercentage = data.ourPercentage;
    let totalPercentage = 0;
    let totalPercentageCommissions = 0;
    this.data = [];
    data.coinsuranceShares.forEach((el, index) => {
      el.rowIndex = index;
      totalPercentage += el.percentageShare;
      totalPercentageCommissions += el.percentageCommissions;
      this.data.push(cloneDeep(el));
    });

    this.totalPercentage = data.ourPercentage + totalPercentage;
    this.totalCommissions = totalPercentageCommissions;

    this.coinsuranceShareForm = this.getCoinsuranceShareForm();
    this.tableData.update(this.getTableData());
  }

  ngOnInit(): void {
    this.groupPolicyService.getExternalCompanies$().pipe(
      map(resp => this.externalCompanies = resp),
    ).subscribe();

    this.groupPolicyService.getIntermediaries$().pipe(
      map(resp => this.intermediaries = resp),
    ).subscribe();
  }

  public getTableData() {
    if (this.data && this.data.length) {
      return this.data.map(el => {
        return {
          company: el.company,
          intermediary: el.intermediary,
          companyDesc: el.company.description,
          intermediaryDesc: el.intermediary.description,
          percentageShare: el.percentageShare,
          percentageCommissions: el.percentageCommissions,
          policyNumber: el.policyNumber,
          rowIndex: el.rowIndex
        };
      });
    }
  }

  protected getCoinsuranceShareForm() {
    return new UntypedFormGroup({
      company: new UntypedFormControl('', Validators.required),
      intermediary: new UntypedFormControl('', Validators.required),
      percentageShare: new UntypedFormControl('', [Validators.required, Validators.pattern(twoDecimalRejex), Validators.min(0.01), Validators.max(100)]),
      percentageCommissions: new UntypedFormControl('', [Validators.required, Validators.pattern(twoDecimalRejex), Validators.min(0), Validators.max(100)]),
      policyNumber: new UntypedFormControl('', this.isThirdPartyMandate() ? [Validators.required] : [])
    });
  }

  public isThirdPartyMandate() {
    return this.coinsuranceType && this.coinsuranceType.code === '4';
  }

  public isButtonNewVisible() {
    return !this.isThirdPartyMandate() || this.data.length === 0;
  }

  public getTitle() {
    return this.isThirdPartyMandate() ? '_GP_._TITLE_._COINSURANCE_DELEGATED_' : '_GP_._TITLE_._COINSURANCE_SHARES_';
  }

  public onDelete(rowIndex: number) {
    const arrayIndex = this.data.findIndex(elem => elem.rowIndex === rowIndex);
    if (arrayIndex > -1) {
      const coinsuranceShares = this.data.map(el => ({ ...el }));
      this.totalPercentage -= coinsuranceShares[arrayIndex].percentageShare;
      this.totalCommissions -= coinsuranceShares[arrayIndex].percentageCommissions;
      coinsuranceShares.splice(arrayIndex, 1);
      this.data = coinsuranceShares;
      this.tableData.update(this.getTableData());
    }
  }

  public onEdit(coinsuranceShareToEdit: any) {
    this.editorMode = GRP_OBJ_MODE.EDIT;
    this.editableCoinsuranceShare = cloneDeep(coinsuranceShareToEdit);
    this.updateForm();
  }

  protected updateForm() {
    this.coinsuranceShareForm.patchValue({
      company: this.editableCoinsuranceShare.company.id,
      intermediary: this.editableCoinsuranceShare.intermediary.id,
      percentageShare: this.editableCoinsuranceShare.percentageShare,
      percentageCommissions: this.editableCoinsuranceShare.percentageCommissions,
      policyNumber: this.editableCoinsuranceShare.policyNumber
    });
  }

  public onConfirmEditNew() {
    this.coinsuranceShareForm.markAllAsTouched();
    if (this.coinsuranceShareForm.valid) {
      this.updateCoinsuranceShare();

      this.updateTotalPercentage();

      const arrayIndex = this.data.findIndex(elem => elem.rowIndex === this.editableCoinsuranceShare.rowIndex);
      arrayIndex > -1 ? this.data[arrayIndex] = this.editableCoinsuranceShare : this.data.push(this.editableCoinsuranceShare);
      this.tableData.update(this.getTableData());

      this.editableCoinsuranceShare = null;
      this.coinsuranceShareForm.reset();
      this.editorMode = GRP_OBJ_MODE.VIEW;
    }
  }

  protected updateTotalPercentage() {
    const arrayIndex = this.data.findIndex(elem => elem.rowIndex === this.editableCoinsuranceShare.rowIndex);
    if (arrayIndex > -1) {
      this.totalPercentage -= this.data[arrayIndex].percentageShare;
      this.totalCommissions -= this.data[arrayIndex].percentageCommissions;
    }
    this.totalPercentage += this.editableCoinsuranceShare.percentageShare;
    this.totalCommissions += this.editableCoinsuranceShare.percentageCommissions;
  }

  protected updateCoinsuranceShare() {
    const formData = this.coinsuranceShareForm.getRawValue();
    this.editableCoinsuranceShare.company = this.externalCompanies.find(company => company.id.toString() === formData.company.toString());
    this.editableCoinsuranceShare.intermediary = this.intermediaries.find(intermediary => intermediary.id.toString() === formData.intermediary.toString());
    this.editableCoinsuranceShare.percentageShare = formData.percentageShare;
    this.editableCoinsuranceShare.percentageCommissions = formData.percentageCommissions;
    this.editableCoinsuranceShare.policyNumber = formData.policyNumber;
  }

  public onUndoEditNew() {
    this.editableCoinsuranceShare = null;
    this.coinsuranceShareForm.reset();
    this.editorMode = GRP_OBJ_MODE.VIEW;
  }

  public onNew() {
    const newCoinsuranceShare = {} as CoinsuranceShare;
    newCoinsuranceShare.company = {} as Entity;
    newCoinsuranceShare.intermediary = {} as Entity;
    let maxRowIndex = -1;
    this.data.forEach(el => {
      if (el.rowIndex > maxRowIndex) {
        maxRowIndex = el.rowIndex;
      }
    });

    newCoinsuranceShare.rowIndex = maxRowIndex + 1;
    this.editableCoinsuranceShare = newCoinsuranceShare;
    this.updateForm();
    this.editorMode = GRP_OBJ_MODE.EDIT;
  }

  public onConfirm() {
    if (this.totalPercentage <= 100 && this.totalCommissions <= 100) {
      this.data.forEach(el => {
        delete el.rowIndex;
        delete el.companyDesc;
        delete el.intermediaryDesc;
      });
      this.modalClose.emit(this.data);
    }
  }

  public onClose() {
    this.modalClose.emit();
  }

  public onActionClick($event: RgiRxDatatableRowAction<any>) {
    switch ($event.name) {
      case 'EDIT':
        this.onEdit($event.row);
        break;
      case 'DELETE':
        this.onDelete($event.row.rowIndex);
        break;
    }
  }
}

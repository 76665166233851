<div data-qa="life-Funds-detail" *ngIf="onInitEnd">

  <div class="row" id="funds-row">
    <!-- PRIMA COLONNA COL-8 -->
    <div class="col-md-12 col-lg-8">
      <!-- INIZIO DATI FONDO DINAMICO -->
      <div class="col-md-12 life-detail-margin-box life-detail-box" *ngIf="!!initializeViewDetail" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                (click)="back()" data-qa="back-arrow"></span>
              <span class="separetor"> | </span>
              <span class="menu-title" translate>{{getDetailCardTitle()}}</span>
            </div>
          </div>
        </div>
        <!-- INIZIO DETTAGLI FONDO -->
        <lpc-single-fund-detail *ngIf="!!isGSFromConsultation() || !!isNonGSFund()"
          [fundsDetail]="fundsDetail"
          [revaluationRates]="revaluationRates"
          [fund]="!!data.finantialFunds.element ? data.finantialFunds.element: data.finantialFunds.fund "
          [isFromConsultation]="isGSFromConsultation()">
        </lpc-single-fund-detail>
        <lpc-gs-fund-detail *ngIf="!!isGSFromPolicy()"
          [fund]="!!data.finantialFunds.element ? data.finantialFunds.element: data.finantialFunds.fund "
          [warranty]="data.finantialFunds.warrantyName"
          [gsFund]="gsFund"
          [labelValues]="data.finantialFunds.labelValues">
        </lpc-gs-fund-detail>
        <!-- INIZIO DETTAGLI FONDO -->
      </div>
      <!-- FINE DATI FONDO DINAMICO -->

      <!-- CONSULTAZIONE GRAFICO NON GS-->
      <div class="col-md-12 life-detail-margin-box life-detail-box" *ngIf="!!initializeViewTrend && !!isNonGSFund()" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_fund_trend</span>
            </div>
          </div>
        </div>


        <div class="col-md-12" style="margin-top: 30px; margin-bottom: 30px" [formGroup]="formGroup" >
            <div class="col-md-4" data-qa="data-inizio-andamento-grafico">
              <pnc-datepicker [attr.data-qa]="'start-date'" [customFormGroup]="formGroup"
                [label]="'lpc_start_date' | translate" customFormControlName="startDate" (change)="onChangeStartDate($event)"
                [required]="true" (dateSelect)="onChangeStartDate($event)">
              </pnc-datepicker>
            </div>
            <div class="col-md-4" data-qa="data-fine-andamento-grafico">
              <pnc-datepicker [attr.data-qa]="'end-date'" [customFormGroup]="formGroup"
                [label]="'lpc_end_date' | translate" customFormControlName="endDate" (change)="onChangeEndDate($event)"
                [required]="true" (dateSelect)="onChangeEndDate($event)">
              </pnc-datepicker>
            </div>
            <div class="col-md-3" data-qa="periodicita-andamento-grafico">
              <label class="label-core" translate>lpc_frequecy</label>
              <lpc-required required="true"></lpc-required>
              <ng-select [attr.data-qa]="'frequency'" bindLabel="name" formControlName="frequency">
                <ng-option *ngFor="let frequency of frequencies" [value]="frequency.id">{{frequency.description}}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-md-1">
              <div class="col-md-6" data-qa="trend-chart-calculate">
                <button style="margin-top:25px; padding: 5px;" class="btn btn-primary" (click)="updateChart()"
                  translate>lpc_calculate</button>
              </div>
            </div>
        </div>

        <div class="col-lg-12">
            <pnc-form-message type="error" [messages]="chartErrors" *ngIf="chartErrors?.length >0"
              detail="validationError" data-qa="chart-validation-error-messages">
            </pnc-form-message>
        </div>

        <div class="padding-card" data-qa="fundTrend-chart">
          <lpc-funds-chart *ngIf="trendFound" [lineChartData]="lineChartData" [lineChartLabels]="lineChartLabels"
            [lineChartColors]="lineChartColors" [lineChartLegend]="lineChartLegend" [lineChartType]="lineChartType"
            [lineChartPlugins]="lineChartPlugins" [lineChartOptions]="lineChartOptions">
          </lpc-funds-chart>
          <p *ngIf="!trendFound" translate>lpc_no_result</p>
        </div>
      </div>

      <!-- CONSULTAZIONE GRAFICO GS -->
      <div class="col-md-12 life-detail-margin-box life-detail-box" *ngIf="isGsFund" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_fund_trend</span>
            </div>
          </div>
        </div>


        <div class="col-md-12" style="margin-top: 30px; margin-bottom: 30px" [formGroup]="formGroup" >
            <div class="col-md-4" data-qa="data-inizio-andamento-grafico">
              <pnc-datepicker [attr.data-qa]="'start-date'" [customFormGroup]="formGroup"
              [label]="'lpc_start_date' | translate" customFormControlName="startDateRevaluation">
              </pnc-datepicker>
            </div>
            <div class="col-md-4" data-qa="data-fine-andamento-grafico">
              <pnc-datepicker [attr.data-qa]="'end-date'" [customFormGroup]="formGroup"
                [label]="'lpc_end_date' | translate" customFormControlName="endDateRevaluation">
              </pnc-datepicker>
            </div>
            <div class="col-md-1">
              <div class="col-md-6" data-qa="trend-chart-calculate">
                <button style="margin-top:25px; padding: 5px;" class="btn btn-primary" (click)="updateRevaluationRates()"
                  translate>lpc_calculate</button>
              </div>
            </div>
        </div>

        <div class="col-lg-12">
            <pnc-form-message type="error" [messages]="chartErrors" *ngIf="chartErrors?.length >0"
              detail="validationError" data-qa="chart-validation-error-messages">
            </pnc-form-message>
        </div>

        <div class="padding-card" data-qa="fundTrend-chart">
          <lpc-funds-chart *ngIf="trendFound" [lineChartData]="lineChartData" [lineChartLabels]="lineChartLabels"
            [lineChartColors]="lineChartColors" [lineChartLegend]="lineChartLegend" [lineChartType]="lineChartType"
            [lineChartPlugins]="lineChartPlugins" [lineChartOptions]="lineChartOptions">
          </lpc-funds-chart>
          <p *ngIf="!trendFound" translate>lpc_no_result</p>
        </div>
      </div>

      <div class="col-md-12 life-detail-margin-box life-detail-box"
        *ngIf="!!initializeViewTrend && !!data.finantialFunds.gs" id="consulting-detail">
        <lpc-revaluation-detail-table [revaluationData]="gsFund.revaluations">
        </lpc-revaluation-detail-table>
      </div>

    </div>

    <div *ngIf="!!isNonGSFundFromConsultation()" class="col-md-4">
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title" style="width: 100%">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_portfolio_fund_display</span>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="tbld life-detail-margin-top">
            <div class="tbld_row life-detail-tbld_row_header">
              <div class="tbld_col tbld_col_title" translate>lpc_quotes_number</div>
              <div class="tbld_col tbld_col_title" translate>lpc_valuation</div>
              <div class="tbld_col tbld_col_title" translate>lpc_percentage_total</div>
            </div>
            <ng-container>
              <div class="tbld_row">
                <div class="tbld_col tbld_col_value">{{portfolioFund?.unitsQuantity | rgiCountryLayerNumberFormat: currencyService.locale : quoteNumberFormatterOptions }}
                </div>
                <div class="tbld_col tbld_col_value">{{portfolioFund?.netAssetValue | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}
                </div>
                <div class="tbld_col tbld_col_value">{{portfolioFund?.totalPercentage | rgiCountryLayerNumberFormat: currencyService.locale : percentFormatterOptions }}</div>
              </div>
            </ng-container>
          </div>
          <p translate>lpc_euro_valuation_not_fund_currency</p>
        </div>
      </div>

      <lpc-message-modal [showMessageModalOperation]="openMessageModal" (close)="back()" [serviceError]="serviceError"
        [customMessage]="modalMessage" [nextLabel]="null">
      </lpc-message-modal>

    </div>
  </div>
</div>

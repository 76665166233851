<form [formGroup]="group">
<div class="grid date-box" [attr.reuired]="config.compulsory">
  <pnc-datepicker
        [label]="label"
        [id]="config.code"
        [customFormControlName]="config.code"
        [customFormGroup]="group"
        [required]="config.compulsory"
        (change)="onChange()"
        (dateSelect)="onChange()"
        [attr.data-qa]="'factor' + config.code"
        [attr.data-mod] = "'factor' + config.code + modifiable" [attr.data-obb] = "'factor' + config.code + config.compulsory" >
  </pnc-datepicker>
</div>
</form>

import {RgiRxDatasource, RgiRxDataSourceTypeObjectProps, RgiRxTableSortComparatorDef, RgiRxTableSortOrder, TableRowTypeSchema} from './table-api';
import {RgiRxDSLAssertion} from '@rgi/rx';
import {RgiRxDatatableInputSortChange} from './rgi-rx-datatable-input-sort.directive';
import {CollectionViewer, SelectionChange, SelectionModel} from '@angular/cdk/collections';
import {InjectionToken, PipeTransform} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {RgiRxDataPaginator} from '../paginator/paginator-api';
import {map, mergeMap, take} from 'rxjs/operators';
import {orderBy} from 'lodash';

export interface RgiRxDatatableRowAction<T> {
  name: string;
  row: T;
  event: Event;
}

export interface RgiRxDatatableHeaderAction<T> {
  name: string;
  event: Event;
  selections: T[];
}

export interface RgiRxDatatableOnSortEnded<T> {
  change: RgiRxDatatableInputSortChange;
  row: T;
}

export interface RgiRxDatatableSelectionChange<T> extends SelectionChange<T> {
}


export interface RgiRxDataTableCellContextWrapper<T> {
  $implicit: RgiRxDataTableCellContext<T>;
}

/**
 * DataTableCell context definition for dynamic cells
 * The context provide access to the current row and simplify the interaction with the data-table
 */
export interface RgiRxDataTableCellContext<T> {
  /**
   * The current row
   */
  row: T;
  /**
   * The current row index
   */
  index: number;
  /**
   * The current row schema
   */
  schema: TableRowTypeSchema;
  /**
   * Transform the value of the mapped schema by applying formatter pipes to the current schema
   */
  transform: () => any;
  /**
   * Get styles for the current schema by evaluating all the DSLAssertions
   */
  styles: () => string[];
  /**
   * Check if the current row it's selected
   */
  isSelected: () => boolean;
  /**
   * Evaluate an assertion for the current row
   * @param assertion a RgiRxDSLAssertion to verify
   */
  assert: (assertion: RgiRxDSLAssertion) => boolean;
  /**
   * Perform an action event by click event
   * @param action the name of the action to invoke
   * @param event the event source of the mouse event
   */
  emitAction: (action: string, event: MouseEvent) => void;
}


export interface RgiRxDataTableColumnSortDef<T> {
  name: string;
  comparator: RgiRxTableSortComparatorDef<T>;
}


/**
 * The definition of an RgiRxDataTablePipe token
 */
export interface RgiRxDataTablePipe<T extends PipeTransform> {
  /**
   * Name of the pipe to use with the TableSchemaFormat
   * @see TableSchemaFormatSingle
   */
  name: string;
  /**
   * The instance of the PipeTransform
   * @see PipeTransform
   */
  pipe: T;

  dispose?: () => void;
}

/**
 * Known types for RgiRxDataTablePipes
 * @see RgiRxDataTablePipe.name
 */
export type RgiRxDataTablePipeType = 'uppercase' | 'lowercase' | 'titlecase' | 'date';

export const RGI_RX_DATA_TABLE_PIPE = new InjectionToken<RgiRxDataTablePipe<any>>('RGI_RX_DATA_TABLE_PIPE');
export const RGI_RX_DATA_TABLE_PIPE_VIEW = new InjectionToken<RgiRxDataTablePipe<any>>('RGI_RX_DATA_VIEW_TABLE_PIPE');


/**
 * A Datasource for building Reactive in-memory data-tables
 */
export class RgiRxDataTableDataSource<T> extends RgiRxDatasource<T> {

  /**
   * A dataset slice of the _data set to be rendered
   */
  protected readonly _renderData = new BehaviorSubject<T[]>([]);
  private _renderChanges = Subscription.EMPTY;


  constructor(data: T[] = [], paginator?: RgiRxDataPaginator, sourceObjectProps?: RgiRxDataSourceTypeObjectProps<T>) {
    super(data, paginator, sourceObjectProps);
    this.subscribeChanges();
  }


  connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
    return this._renderData.asObservable();
  }

  private subscribeChanges() {

    let obs$ = combineLatest([this._data, this._filter])
      .pipe(map(([data, filterTerm]) => this._filterData(data, filterTerm)));

    if (this._paginator) {
      obs$ = combineLatest([obs$, this._paginator.changes])
        .pipe(map(([data]) => this._page(data)));
    }
    this._renderChanges.unsubscribe();
    this._renderChanges = obs$.subscribe(data => this._renderData.next(data));
  }

  private _filterData(data: T[], filterTerm: string) {
    const filtered = !filterTerm ? data : data.filter(obj => this.filterElementsFromObject(obj, filterTerm, this.filterObjectProps));
    if (this.hasPaginator()) {
      this.paginator.elementCount = filtered.length;
      this.paginator.pageIndex = 0;
    }
    return filtered;
  }


  private filterElementsFromObject: ((data: T, filter: string, props: RgiRxDataSourceTypeObjectProps<T>) => boolean) = (data: T, filterTerm: string, props: RgiRxDataSourceTypeObjectProps<T>): boolean => {
    const dataStr = Object.keys(data)
      .filter(p => this.filterByObjectProps(p, props))
      .reduce((currentTerm: string, key: string) => {
        return currentTerm + (data as { [key: string]: any })[key] + '◬';
      }, '').toLowerCase();
    const transformedFilter = filterTerm.trim().toLowerCase();
    // eslint-disable-next-line eqeqeq
    return dataStr.indexOf(transformedFilter) != -1;
  }

  private filterByObjectProps(p: string, props: RgiRxDataSourceTypeObjectProps<T>) {
    return !!props ? props.hasOwnProperty(p) : true;
  }
}


export type RgiRxTableExpansionTrigger = <T>(row: T) => boolean;

export const RGI_RX_EXPANSION_TRIGGER_EVERGREEN = <T>(row: T) => {
  return true;
};

export interface RgiRxDataTableExpansionOptions {
  multiple: boolean;
  canExpand: RgiRxTableExpansionTrigger;
  canContract: RgiRxTableExpansionTrigger;
}

export interface RgiRxDataTableConfig {
  expansion: RgiRxDataTableExpansionOptions;
}


export const RGI_RX_DATA_TABLE_CONFIG = new InjectionToken<RgiRxDataTableConfig>('RGI_RX_DATA_TABLE_CONFIG');

export const RGI_RX_DATA_TABLE_DEFAULT_CONFIG: RgiRxDataTableConfig = {
  expansion: {
    multiple: true,
    canContract: RGI_RX_EXPANSION_TRIGGER_EVERGREEN,
    canExpand: RGI_RX_EXPANSION_TRIGGER_EVERGREEN
  }
};


export interface RgiRxTableExpansionChange<T> {
  expanded: T[];
  closed: T[];
  opened: T[];
}

export class RgiRxDataTableExpansionModel<T> {
  private expansionModel: SelectionModel<T>;

  constructor(
    private initiallyExpanded: T[] = [],
    private readonly options?: RgiRxDataTableExpansionOptions) {
    if (!options) {
      this.options = RGI_RX_DATA_TABLE_DEFAULT_CONFIG.expansion;
    }
    this.expansionModel = new SelectionModel<T>(options.multiple, initiallyExpanded, true);
  }

  expand(...row: T[]) {
    this.expansionModel.select(...row.filter(r => this.options.canExpand(r)));
  }

  contract(...row: T[]) {
    this.expansionModel.deselect(...row.filter(r => this.options.canContract(r)));
  }

  isExpanded(row: T) {
    return this.expansionModel.isSelected(row);
  }

  getChanges$(): Observable<RgiRxTableExpansionChange<T>> {
    return this.expansionModel.changed.pipe(
      map(changes => {
        return {
          expanded: changes.source.selected,
          closed: changes.removed,
          opened: changes.added
        };
      })
    );
  }
}

export class RgiRxDataTableSort<T> {

  private _sortColumn: Subject<{ column: string, order: RgiRxTableSortOrder, comparator?: RgiRxTableSortComparatorDef<T> }>;
  private sortChangeSubscription: Subscription = Subscription.EMPTY;
  private _original: T[];

  constructor(private datasource: RgiRxDatasource<T>) {
    this.connect();
  }

  sort(column: string, order: RgiRxTableSortOrder, comparator: RgiRxTableSortComparatorDef<T>) {
    this._sortColumn.next({
      column, order, comparator
    });
  }

  remove() {
    this._sortColumn.next(null);
  }


  connect() {
    this._sortColumn = new Subject();
    this.sortChangeSubscription.unsubscribe();
    this.sortChangeSubscription = this._sortColumn.asObservable()
      .pipe(
        mergeMap(changes => {
          return combineLatest(of(changes), this.datasource.data$.pipe(take(1)));
        })
      )
      .subscribe(
        ([changed, data]) => {
          if (!this._original) {
            this._original = data;
          }
          if (!changed) {
            this.datasource.update(this._original);
          } else {
            if (!!changed.comparator) {
              this.datasource.update(orderBy(data, changed.comparator, changed.order));
            } else {
              this.datasource.update(orderBy(data, changed.column, changed.order));
            }
          }
        }
      );
  }


  isConnected(): boolean {
    return !this.sortChangeSubscription.closed;
  }

  dispose() {
    this.sortChangeSubscription.unsubscribe();
    this._sortColumn.complete();
  }

  get changes$(): Observable<{ column: string; order: RgiRxTableSortOrder }> {
    return this._sortColumn.asObservable();
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {InstallmentHeader} from '../../../resources/model/common/quotation/quotation-header-data';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rgi-pnc-quotation-header-installment',
  templateUrl: './pnc-quotation-header-installment.component.html'
})
export class PncQuotationHeaderInstallmentComponent implements OnInit {

  @Input() headerInstallments: InstallmentHeader[];

  constructor() {
  }

  ngOnInit() {
  }

}

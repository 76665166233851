<div #premiumElement [ngClass]="{'fix-to-top': isPremiumElementFixedInPage}"
     class="quotation-controls quotation-controls2">
  <div class="quotation-top-control center-block">
    <div>
      <div class="pull-left">
        <div class="box-premium pull-left hidden-xs hidden-sm">
          <span class="title-premium" translate>Premium Installments<br></span>
          <div *ngIf="premiumInstallment" class="mrg-top">
            <span class="currency-premium">{{ premiumInstallment?.gross | currency:'EUR':'':'1.2-2':'it' }}</span>
            <span class="premium-quick-summary-label">&euro;/</span>
            <span class="premium-quick-summary-label" translate>Gross</span>
          </div>
          <div>
            <span *ngIf="!premiumInstallment" class="currency-premium currency-premium2 bold">--</span>
          </div>
        </div>
        <div class="sep pull-left">&nbsp;</div>
        <div class="box-premium pull-left">
          <span class="title-premium" translate>Annual Premium<br></span>
          <div *ngIf="annualPremiumValue" class="mrg-top">
            <span class="currency-premium">{{ annualPremiumValue?.gross | currency:'EUR':'':'1.2-2':'it' }}</span>
            <span class="premium-quick-summary-label">&euro;/</span>
            <span class="premium-quick-summary-label" translate>Gross</span>
          </div>
          <div>
            <span *ngIf="!annualPremiumValue" class="currency-premium currency-premium2 bold">--</span>
          </div>
        </div>
      </div>

      <div class="pull-right btn-toolbar btn-quotation-box nmf_cont_button_menunit">
        <div class="btn-group">
          <button (click)="recalculatePremium($event)" [disabled]="!recalculateAvailable"
                  class="btn btn-style btn-warning recalculate-btn">
            <span class="hidden-xs hidden-sm" translate>RECALCULATE</span>
            <span class="controls-icon visible-xs visible-sm rgifont rgi-refresh"></span>
          </button>
        </div>

        <!--         <div class="btn-group">
          <button class="btn btn-default btn-quotation-detail" (click)="openPremiumDetailsModal()"
            [disabled]="!annualPremiumValue && !premiumInstallment">
            <span class="hidden-xs hidden-sm" translate>DETAILS</span> <span class="rgifont"></span>
          </button>
        </div>
        <div class="btn-group">
          <button class="btn btn-default btn-quotation-detail nmf_button_headerecalculate">
            <span class="rgifont rgi-percent"></span>
          </button>
        </div>
 -->
        <div class="unit-style-toggle btn-group nmf_button_contview" ngbDropdown>
          <button [disabled]="!annualPremiumValue && !premiumInstallment" class="btn btn-default btn-quotation-detail" data-qa="btn-quotation-detail"
                  ngbDropdownToggle>
            <span class="hidden-xs hidden-sm" translate>DETAILS</span> <span class="rgifont"></span>
          </button>
          <ul class="quote-controls-dropdown" ngbDropdownMenu role="menu">
            <li ngbDropdownItem role="menuitem">
              <a (click)="openPremiumDetailsModal()" data-qa="btn-premium-details" role="button">
                <span class="rgifont"></span>&nbsp;<b translate>Premium Details</b>
              </a>
            </li>
            <li *ngIf="showContributionAndTaxesMenu" ngbDropdownItem role="menuitem">
              <a (click)="openContributionsAndTaxesModal()" data-qa="btn-contributions-taxes" role="button">
                <span class="rgifont"></span>&nbsp;&nbsp;<b translate>Contributions and Taxes</b>
              </a>
            </li>
            <li *ngIf="commissionsVisible" ngbDropdownItem role="menuitem">
              <a (click)="openCommissionsModal()" data-qa="btn-commissions" role="button">
                <span class="rgifont"></span>&nbsp;&nbsp;<b translate>Commissions</b>
              </a>
            </li>
            <li *ngIf="showLPSTaxes" ngbDropdownItem role="menuitem">
              <a (click)="openLPSTaxesModal()" data-qa="btn-lps-taxes" role="button">
                <span class="rgifont"></span>&nbsp;&nbsp;<b translate>LPS Taxes</b>
              </a>
            </li>
            <li *ngIf="showWarrantiesSummaryModal" ngbDropdownItem role="menuitem">
              <a (click)="openWarrantiesSummaryModal()" data-qa="btn-warranties-summary" role="button">
                <span class="rgifont"></span>&nbsp;&nbsp;<b translate>Warranties Summary</b>
              </a>
            </li>
          </ul>
        </div>
        <div class="btn-group">
          <button (click)="openDiscountsModal()"
                  [disabled]="!discountsEnabled" class="btn btn-default btn-quotation-detail nmf_button_headerecalculate">
            <span class="rgifont rgi-percent"></span>
          </button>
        </div>

        <div class="unit-style-toggle btn-group nmf_button_contview" ngbDropdown>
          <button aria-expanded="false"
                  aria-haspopup="true"
                  class="btn btn-default btn-quotation-detail nmf_button_headerecalculate nmf_button_menunit" ngbDropdownToggle type="button">
            <span class="rgifont rgi-cog"></span>
            <span class="rgifont rgi-caret-down"></span>
          </button>
          <ul class="quote-controls-dropdown" ngbDropdownMenu role="menu">
            <li *ngIf="showViewBoxesMenu" ngbDropdownItem role="menuitem">
              <a (click)="viewBoxUnits()" role="button">
                <span class="rgifont rgi-view_square"></span>&nbsp;<b translate>View Box Units</b>
              </a>
            </li>
            <li *ngIf="!showViewBoxesMenu" ngbDropdownItem role="menuitem">
              <a (click)="viewListUnits()" role="button">
                <span class="rgifont rgi-menu"></span>&nbsp;&nbsp;<b translate>View List Units</b>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

<h3 class="title-header" *ngIf="selectCovertures">{{ '_CLAIMS_._TYPES_OF_LOSS' | translate }}<br></h3>
<div class="container-fluid form-group">
    <div class="col-sm-6 col-xs-12">
        <label ng-bind="label">{{ '_CLAIMS_._CLAIM_BRANCH' | translate }}</label>
        <select class="core-select form-control" id="branch" name="branch" [(ngModel)]="branchFilter"
            attr.data-qa="damage-category-panel-branch-input" [disabled]="!enabled">
            <option></option>
            <option *ngFor="let branch of branches; " value={{branch.code}}>
                {{branch.name}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>

    <div class="col-sm-6 col-xs-12">
        <label ng-bind="label">{{ '_CLAIMS_._COVERTURE_TYPE' | translate }}</label>
        <select class="core-select form-control" id="coverture" name="coverture" [(ngModel)]="covertureTypeFilter"
            attr.data-qa="damage-category-panel-coverture-input" [disabled]="!enabled">
            <option></option>
            <option *ngFor="let coverture of covertureTypes | coverturesByBranch:branchFilter:selectableCovertures "
                value={{coverture.code}}>
                {{coverture.name}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>
</div>

<div class="container-fluid form-group">
    <div class="col-sm-6 col-xs-12">
    <span>{{ '_CLAIMS_._CLAIM_BRANCH' | translate }} - {{ '_CLAIMS_._COVERTURE_TYPE' | translate }} -
{{ '_CLAIMS_._TYPE_OF_LOSS_DESCRIPTION' | translate }} - {{ '_CLAIMS_._RISK_DESCRIPTION' | translate }}</span>
    </div>
    <div class="col-sm-3 col-xs-12">
        <span>{{ '_CLAIMS_._RISK_DATA' | translate }} </span>
    </div>
    <div class="col-sm-3 col-xs-12">
        <span>{{ '_CLAIMS_._COVERTURE_DATA' | translate }} </span>
    </div>
</div>

<div class="container-fluid form-group">
    <div class="form-check container-fluid  table-white" *ngFor="let content of checkboxContent">
        <div class="row" [ngClass]="{hidden: !showCheckBox(content)}">
            <div class="col-sm-1 col-xs-12">
                <input [ngClass]="{hidden: !showCheckBox(content)}" type="checkbox" class="btn-checkbox checkbox-unit"
                id="{{content.id}}" (change)="onCheckboxChange(content, $event)"
                [disabled]="!enabled || this.checkboxContent.length === 1"
                [checked]="this.checkboxContent.length === 1 || content.forceCheck === true"
                attr.data-qa="damage-category-panel-chekbox-input-{{content.id}}">
            </div>
            <div class="col-sm-5 col-xs-12">
                <label [hidden]="!showCheckBox(content)" class="form-check-label"
                    for="{{content.id}}">{{content.description}}</label>
            </div>
            <div class="col-sm-3 col-xs-12"><label [hidden]="!showCheckBox(content)" class="form-check-label"> {{content.riskFactors}} <B><font color="red"> <br> {{getShortageDays(content)}} </font></B></label></div>
            <div class="col-sm-3 col-xs-12"><label [hidden]="!showCheckBox(content)" class="form-check-label">{{content.covertureFactors}}</label></div>
        </div>

        <!-- <div [hidden]="!openFieldSection(content.codeCoverture, content.codeCovertureType, content.riskcode)" class="row"> -->
        <!-- <div [hidden]="!displayFields[content.codeCoverture + content.codeCovertureType + content.riskcode]" class="row"> -->
        <div *ngIf="displayFields[getIndexFromContent(content)]" class="row">
            <form [formGroup]="dynamicDataForm">
                <!-- <div *ngFor="let field of selectableCoverturesFields[content.codeCoverture + content.codeCovertureType + content.riskcode]" class="col-sm-6 col-xs-12"> -->
                <div *ngFor="let field of selectableCoverturesFields[getIndexFromContent(content)]"
                    class="col-sm-6 col-xs-12">
                    <claims-dynamic-field [field]="field" [formGroup]="dynamicDataForm" [disableAll]="!enabled">
                    </claims-dynamic-field>
                </div>
            </form>
        </div>

    </div>
</div>

<div id="dataClMadePostuma" style="margin-top: 2em" *ngIf="selectableCoverturesResponse && (selectableCoverturesResponse.claimsMade || selectableCoverturesResponse.postuma)">
    <div class="row" *ngIf="!policyVerified" style="text-align: center; color: red;">
        <div class="col-sm-12 col-xs-12"><label  class="form-check-label">{{ '_CLAIMS_._ON_CONFIRMATION' | translate }}</label></div>
    </div>
  <div class="row" *ngIf="policyVerified" style="text-align: center;">
    <div class="col-sm-6 col-xs-12"><label  class="form-check-label">{{ '_CLAIMS_._TECHNICAL_COVERAGE' | translate }}: {{ visualBoolean(regTech) | translate }} </label></div>
    <div class="col-sm-6 col-xs-12"><label  class="form-check-label">{{ '_CLAIMS_._ACCOUNTING_COVERAGE' | translate }}: {{ visualBoolean(regAmm) | translate }}</label></div>
    </div>
</div>

<div id="error-container" style="margin-top: 2em" *ngIf="validationMessages && validationMessages.length > 0">
    <pnc-form-message type="error" detail="validationError" [messages]="validationMessages"
        data-qa="damage-category-panel-action-error-messages" objectType="complex"></pnc-form-message>
</div>

<div class="modal-footer" *ngIf="enabled">
    <div class="btn-group btn-group-justified">

            <div class="btn-group" *ngIf="!selectCovertures">
            <button class="btn btn-primary colorBGBlu2"  (click)="onConfirm()" [disabled]="blockSave" data-qa="damage-category-panel-confirm-button"
                >{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
            </div>
            <div class="btn-group" *ngIf="selectCovertures">
            <button class="btn btn-flat btn-sm"  (click)="rectify()" [disabled]="blockSave" data-qa="damage-category-panel-correct-button"
                >{{ '_CLAIMS_._BUTTONS_._CORRECT' | translate }}</button>
            </div>
            <div class="btn-group" *ngIf="selectCovertures">
                <button class="btn btn-flat btn-sm"  (click)="onConfirm()" [disabled]="blockSave" data-qa="damage-category-panel-add-button"
                >{{ '_CLAIMS_._BUTTONS_._ADD' | translate }}</button>
            </div>
    </div>
</div>

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {QuestionnaireFlatI} from '@rgi/ng-passpro'
import {DatePipe} from "@angular/common";

import {ResponseList, ResponseResult, EnvironmentService} from '@rgi/ng-passpropro-core';
import {Questionnaire} from "../model/questionnaire";
import {QuestionnaireVersion} from "../model/questionnaire-version";
import {QuestionnaireSearch} from "../model/questionnaire-search";
import {
  NG_PASSPROPRO_QUESTIONNAIRE_CONFIG,
  NgPassProProQuestionnaireConfig
} from "../ng-passpro-pro-questionnaire-api";
import {EvaluationResourceDefinition} from "@rgi/ng-passpropro";
import {Source} from "@rgi/ng-passpropro/product"

const RESOURCE = {
  basePath: '/questionnaires'
};

const RESOURCE_V2 = {
  basePath: '/v2/questionnaire'
};

@Injectable()
export class QuestionnaireService {

  constructor(private http: HttpClient, private environmentService: EnvironmentService, private datePipe: DatePipe,
          @Inject(NG_PASSPROPRO_QUESTIONNAIRE_CONFIG) @Optional() private config : NgPassProProQuestionnaireConfig | undefined
      ) {
  }


  /**
   *
   * @param productCodes list of product codes
   * @param date Date the start date to be filtered, default is now
   * @param questTypeCode questionnaire type code (optional)
   */
  getQuestionnaires(productCodes:string[]=null, date:Date=null, questTypeCode:string=null): Observable<Array<Questionnaire>> {
    const path = `/questionnaire`;

    let httpParams = new HttpParams();

    if(productCodes){
      productCodes.forEach( (code) => httpParams = httpParams.append('products', code) );
    }

    const d = date ? date : new Date();

    //yyyy-MM-dd
    const dateFormatted = d.toISOString().split("T")[0];

    httpParams = httpParams.set('date', dateFormatted);

    if(questTypeCode){
      httpParams = httpParams.set('questType', questTypeCode);
    }


    if(this.config && Array.isArray(this.config.questionnaireCategories)  && this.config.questionnaireCategories.length){

      this.config.questionnaireCategories.forEach(
        (cat) => {
          httpParams = httpParams.append("categories", cat+"");
        }
      );
    }

    return this.http
      .get(this.environmentService.environmentUrl + RESOURCE_V2.basePath, { params: httpParams })
      .pipe(
        map((response: ResponseResult<Questionnaire[]>) => {
          return response.result;
        })
      );
  }

  getQuestionnairesBySources(sources: Source[]=[], date:Date = null, questTypeCode:string=null) {
    const path = `${RESOURCE_V2.basePath}/source`;

    let httpParams = new HttpParams();

    sources.forEach( (source) => httpParams = httpParams.append('source', JSON.stringify(source)) );

    if (date != null) {
      const dateFormatted = this.datePipe.transform(date, "yyyy-MM-dd");
      httpParams = httpParams.set('date', dateFormatted);
    }

    if(questTypeCode){
      httpParams = httpParams.set('type', questTypeCode);
    }

    if(this.config && Array.isArray(this.config.questionnaireCategories)  && this.config.questionnaireCategories.length){

      this.config.questionnaireCategories.forEach(
        (cat) => {
          httpParams = httpParams.append("categories", cat+"");
        }
      );
    }

    return this.http
      .get(this.environmentService.environmentUrl + path, { params: httpParams })
      .pipe(
        map((response: ResponseResult<Questionnaire[]>) => {
          return response.result;
        })
      );
  }


  getQuestionnaireByResources(resources: EvaluationResourceDefinition[], sources: Source[]=[], date:Date = null, questTypeCode:string=null ):Observable<Questionnaire[]>{
    const path = `${RESOURCE_V2.basePath}/rule`;
    let httpParams = new HttpParams();

    sources.forEach( (source) => httpParams = httpParams.append('source', JSON.stringify(source)) );

    if (date != null) {
      const dateFormatted = this.datePipe.transform(date, "yyyy-MM-dd");
      httpParams = httpParams.set('date', dateFormatted);
    }

    if(questTypeCode){
      httpParams = httpParams.set('type', questTypeCode);
    }

    if(this.config && Array.isArray(this.config.questionnaireCategories)  && this.config.questionnaireCategories.length){

      this.config.questionnaireCategories.forEach(
        (cat) => {
          httpParams = httpParams.append("categories", cat+"");
        }
      );
    }

    return this.http.put(this.environmentService.environmentUrl+path, {resources}, {params: httpParams }).pipe(
      map((response: ResponseResult<Questionnaire[]>) => {
        return response.result;
      })
    );

  }


  getQuestInclusionFormula(req: any): Observable<Array<Questionnaire>> {
    const path = `/productinstance`;
    return this.getQuestInclusionFormulaList(path, req);
  }

  getQuestionnaireWith(search: QuestionnaireSearch): Observable<Array<Questionnaire>> {
    const path = `/questionnaire`;
    let httpParams = new HttpParams();

    // Begin assigning parameters
    if (search.products) {
      search.products.forEach(p => httpParams = httpParams.append('products', p));
    }

    if (search.enableProductRestriction){
      httpParams = httpParams.append('enableProductRestriction', search.enableProductRestriction ? 'true' : 'false');
    }

    if (search.date) {
      httpParams = httpParams.append("date", this.datePipe.transform(search.date, 'dd/MM/yyyy'));
    }

    if (search.code) {
      httpParams = httpParams.append("code", search.code);
    }

    if (search.name) {
      httpParams = httpParams.append("name", search.name);
    }

    if (search.type) {
      httpParams = httpParams.append("questType", search.type);
    }

    return this.getServiceList<Questionnaire>(path, httpParams);
  }

  getVersions(id: number): Observable<Array<QuestionnaireVersion>> {
    const path = `/questionnaire/versions/${id}`;
    return this.getServiceList<QuestionnaireVersion>(path);
  }

  getQuestionnaireFromPASSPRO(code: string): Observable<QuestionnaireFlatI> {
    const path = `/fromPassPro/${code}`;
    return this.http.get(this.environmentService.environmentUrl + RESOURCE.basePath + path)
      .pipe(
        map(response => {
          return response && response[`questionnaire`];
        })
      );
  }

  mergeQuestionnaireFromPASSPRO(questionnaire: QuestionnaireFlatI, needs: any = []): Observable<QuestionnaireFlatI> {
    return this.http.put<QuestionnaireFlatI>(`${this.environmentService.environmentUrl}${RESOURCE.basePath}/fromPassPro`, {
      filter: { questionnaire: questionnaire, needs: needs }
    }).pipe(map((value: any) => value.questionnaire));
  }


  getSectionNotes(sectionId:number):Observable<string>{
    return this.http.get<string>(`${this.environmentService.environmentUrl}${RESOURCE.basePath}/section/text/${sectionId}`)
        .pipe(
          map((value: any) => value.text)
        );
  }


  getQuestionNotes(questionId:number):Observable<string>{
    return this.http.get<string>(`${this.environmentService.environmentUrl}${RESOURCE.basePath}/question/text/${questionId}`)
      .pipe(
        map((value: any) => value.text)
      );
  }


  private getQuestInclusionFormulaList(url: string, req: any): Observable<Array<Questionnaire>> {

    return this.http
    .put(this.environmentService.environmentUrl + RESOURCE_V2.basePath + url, req)
    .pipe(
      map((response: ResponseResult<Array<Questionnaire>>) => {
        return response.result;
      })
    );
  }

  private getServiceList<T>(url: string, httpParams?: HttpParams): Observable<Array<T>> {

    return this.http
      .get(this.environmentService.environmentUrl + RESOURCE.basePath + url, { params: httpParams })
      .pipe(
        map((response: ResponseList<T>) => {
          return response.list;
        })
      );
  }

}

import {AnswerFlatI} from './answer-flat-i';
import {SectionFlatI} from './section-flatI';
import {ConditionFlatI} from './condition-flatI';
import {QuestionStatuses} from './question-statuses';

export class QuestionFlatI {
  code: string;
  visible: boolean;
  answers: AnswerFlatI[];
  description: string;
  section: SectionFlatI;
  compulsory: boolean;
  hasNotes: boolean;
  id: string;
  text: string;
  conditions: ConditionFlatI[];
  order: number;
  status: QuestionStatuses;

  constructor() {
  }
}

<rgi-rx-panel class="lg-panel">

  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
    <span translate>RE_ISSUE.ADD_CONTRACT</span>
  </rgi-rx-panel-header>


  <div>
    <form [formGroup]="searchContractFormGroup" class="rgi-ui-grid-1">

      <div class="rgi-ui-col search-parameters">

        <!-- CONTRACT BRANCH -->
        <label rgiRxLabel for="contractBranch" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.CONTRACT_BRANCH
        </label>
        <rgi-rx-button-toggle id="contractBranch" class="rgi-ui-col rgi-ui-grid-2" formControlName="contractBranch">
          <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueContractBranchEnum.ALL}}" translate>CONTRACT_CONSULTATION.ALL</button>
          <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueContractBranchEnum.PC}}" translate>CONTRACT_CONSULTATION.PC</button>
          <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueContractBranchEnum.LIFE}}" translate>CONTRACT_CONSULTATION.LIFE</button>
        </rgi-rx-button-toggle>
        <br>

        <!-- TYPE -->
        <label rgiRxLabel for="type" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.TYPE
        </label>
        <rgi-rx-button-toggle id="type" class="rgi-ui-col rgi-ui-grid-2" formControlName="type" (onToggle)="resetNumberValues();resetProductFiled();">
          <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueTypeEnum.INDIVIDUAL}}" translate>CONTRACT_CONSULTATION.INDIVIDUAL</button>
          <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueTypeEnum.COLLECTIVE}}" translate>CONTRACT_CONSULTATION.COLLECTIVE</button>
        </rgi-rx-button-toggle>
        <br>

        <!-- SUBTYPE -->
        <div *ngIf="searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE">
          <label rgiRxLabel for="subtype" class="rgi-ui-title-3" translate>
            CONTRACT_CONSULTATION.SUBTYPE
          </label>
          <rgi-rx-button-toggle id="subtype" class="rgi-ui-col rgi-ui-grid-2" formControlName="subtype" (onToggle)="resetNumberValues()">
            <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueSubtypeEnum.MASTER_POLICY}}" translate>CONTRACT_CONSULTATION.MASTER_POLICY</button>
            <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueSubtypeEnum.APPLICATION}}" translate>CONTRACT_CONSULTATION.APPLICATION</button>
          </rgi-rx-button-toggle>
          <br>
        </div>

        <!-- LIMITATION -->
        <label rgiRxLabel for="limitation" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.LIMITATION
        </label>
        <rgi-rx-button-toggle id="limitation" class="rgi-ui-col rgi-ui-grid-2" formControlName="limitation">
          <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueLimitationEnum.NOT_ANONYMIZED}}" translate>CONTRACT_CONSULTATION.NOT_ANONYMIZED</button>
          <button class="rgi-ui-col button-padding" rgi-rx-button rgiRxValueOption value="{{ReIssueLimitationEnum.ANONYMIZED}}" translate>CONTRACT_CONSULTATION.ANONYMIZED</button>
        </rgi-rx-button-toggle>

        <!-- POINT OF SALE -->
        <div class="rgi-ui-col">
          <label rgiRxLabel class="rgi-ui-title-3" for="salePoint" translate>
            CONTRACT_CONSULTATION.POINT_OF_SALE
          </label>
          <div [ngClass]="{'is-invalid': searchContractFormGroup.get('salePoint').invalid}">
            <rgi-rx-drop-container id="salePoint" formControlName="salePoint" viewField="description"
                                   select="nodeSelection" [selectData]="nodeList" data-qa="salePoint">
              <ng-template rgiRxDropLabel let-ctx>
                <span translate>CONTRACT_CONSULTATION.SELECT_NODE</span>
              </ng-template>
            </rgi-rx-drop-container>
          </div>
        </div>

        <!-- SUBNET -->
        <div class="subnet">
          <input rgiRxInput formControlName="subnet" id="checkbox" type="checkbox">
          <label rgiRxLabel for="checkbox" class="rgi-ui-title-3" translate>
            CONTRACT_CONSULTATION.SEARCH_UNDER_SUBNET
          </label>
        </div>

        <!-- COMPANY -->
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.COMPANY
            </label>
            <select rgiRxNativeSelect formControlName="company" data-qa="company">
              <option value=""></option>
              <option *ngFor="let company of companyList" [ngValue]="company">{{company.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>

        <!-- INDEPENDENT AGENT -->
        <div class="rgi-ui-col" *ngIf="!(searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE && searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION)">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.INDEPENDENT_AGENT
            </label>
            <select rgiRxNativeSelect formControlName="independentAgent" data-qa="independentAgent">
              <option value=""></option>
              <option *ngFor="let agent of independentAgentList" [ngValue]="agent">{{agent.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>

        <!-- BRANCH OFFICE -->
        <div class="rgi-ui-col" *ngIf="!(searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE && searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION)">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.BRANCH_OFFICE
            </label>
            <select rgiRxNativeSelect formControlName="branchOffice" data-qa="branchOffice">
              <option value=""></option>
              <option *ngFor="let branch of branchesList" [ngValue]="branch">{{branch.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>

        <!-- CONTRACT TYPE -->
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.CONTRACT_TYPE
            </label>
            <select rgiRxNativeSelect formControlName="contractType" data-qa="contract" (change)="resetNumberValues()">
              <option value=""></option>
              <option *ngFor="let type of CONTRACT_TYPES" [ngValue]="type.code" translate>CONTRACT_CONSULTATION.{{type.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>

        <!-- POLICY NUMBER -->
        <div class="rgi-ui-col" *ngIf="isPolicyNumVisible()">
          <rgi-rx-form-field>
            <label *ngIf="!isMasterPolicyVisible()" rgiRxLabel translate>
              CONTRACT_CONSULTATION.POLICY_NUMBER
            </label>
            <label *ngIf="isMasterPolicyVisible()" rgiRxLabel translate>
              CONTRACT_CONSULTATION.MASTER_POLICY_NUMBER
            </label>
            <input rgiRxInput formControlName="policyNumber" data-qa="policyNumber">
          </rgi-rx-form-field>
        </div>

        <!-- APPLICATION NUMBER -->
        <div class="rgi-ui-col" *ngIf="isApplicationNumberVisible()">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.APPLICATION_NUMBER
            </label>
            <input rgiRxInput formControlName="policyNumber" data-qa="policyNumber">
          </rgi-rx-form-field>
        </div>

        <!-- PROPOSAL NUMBER -->
        <div class="rgi-ui-col" *ngIf="isProposalNumVisible()">
          <rgi-rx-form-field>
            <label *ngIf="searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE && searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.MASTER_POLICY" rgiRxLabel translate>
              CONTRACT_CONSULTATION.MASTER_PROPOSAL_NUMBER
            </label>
            <label *ngIf="!(searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE && searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.MASTER_POLICY)" rgiRxLabel translate>
              CONTRACT_CONSULTATION.PROPOSAL_NUMBER
            </label>
            <input rgiRxInput formControlName="proposalNumber" data-qa="proposalNumber">
          </rgi-rx-form-field>
        </div>

        <!-- QUOTE NUMBER -->
        <div class="rgi-ui-col" *ngIf="isQuoteNumberVisible()">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.QUOTE_NUMBER
            </label>
            <input rgiRxInput formControlName="quoteNumber" data-qa="quoteNumber">
          </rgi-rx-form-field>
        </div>

        <!-- PRODUCT TYPE -->
        <div class="rgi-ui-col" *ngIf="searchContractFormGroup.value.subtype !== ReIssueSubtypeEnum.APPLICATION
    && searchContractFormGroup.value.limitation !== ReIssueLimitationEnum.ANONYMIZED">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.PRODUCT_TYPE
            </label>
            <select rgiRxNativeSelect formControlName="productType" data-qa="productType">
              <option value=""></option>
              <option *ngFor="let productType of productTypeList" [ngValue]="productType">{{productType.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>

        <!-- PRODUCT -->
        <div class="rgi-ui-col" *ngIf="isProductVisible()">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>
              CONTRACT_CONSULTATION.PRODUCT
            </label>
            <select rgiRxNativeSelect formControlName="product" data-qa="product">
              <option value=""></option>
              <option *ngFor="let product of productList" [ngValue]="product">{{product.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>

        <!-- CONTRACT CONDITION -->
        <div class="rgi-ui-col">
          <span class="rgi-ui-title-3" translate >CONTRACT_CONSULTATION.CONTRACT_CONDITION</span>
          <rgi-rx-button-toggle class="rgi-ui-col rgi-ui-grid-2" formControlName="condition">
            <button  class="rgi-ui-col button-padding"  rgi-rx-button rgiRxValueOption value="true" translate>CONTRACT_CONSULTATION.AT_PRESENT_DAY</button>
            <button  class="rgi-ui-col button-padding"  rgi-rx-button rgiRxValueOption value="false" translate>CONTRACT_CONSULTATION.LAST</button>
          </rgi-rx-button-toggle>
        </div>

        <!-- SUBJECT -->
        <div class="rgi-ui-col" *ngIf="searchContractFormGroup.value.limitation === ReIssueLimitationEnum.NOT_ANONYMIZED && searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.ANONYMOUS_QUOTE">
          <label rgiRxLabel class="rgi-ui-title-3" translate>
            CONTRACT_CONSULTATION.SUBJECT
          </label>
          <rgi-rx-drop-container formControlName="subject" viewField="nominative"
                                 select="searchOrCreateSubject" [selectData]="getSubjectDropContainerData(roleKeys.rolePolicyHolder)"
                                 (onValueChange)="anagSubjectSelected($event)"
                                 (onRemove)="removeSubjectFormData()" data-qa="subject">
          </rgi-rx-drop-container>
        </div>

        <!--Subject Roles-->
        <div class="rgi-ui-col" *ngIf="searchContractFormGroup.value.subject">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>CONTRACT_CONSULTATION.SUBJECT_ROLE</label>
            <rgi-rx-multiselect formControlName="subjectRoles">
              <rgi-rx-option *ngFor="let subjectRole of subjectRoles" [value]="subjectRole"
                             [label]="subjectRole.description">{{
                  subjectRole.description
                }}</rgi-rx-option>
            </rgi-rx-multiselect>
          </rgi-rx-form-field>
        </div>

        <!-- ERRORS -->
        <div class="rgi-ui-col">
          <rgi-rx-snackbar tag="searchErrorMessage"></rgi-rx-snackbar>
        </div>

      </div>

    </form>
  </div>

  <rgi-rx-panel-footer>
    <button rgi-rx-button color="secondary" (click)="close()">
      <span translate>RE_ISSUE.BACK</span>
    </button>
    <button rgi-rx-button color="primary" (click)="findPolicy()">
      <span translate>RE_ISSUE.FIND</span>
    </button>
  </rgi-rx-panel-footer>

</rgi-rx-panel>

import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { sortMeansOfPayment } from '../adapters/group-policy-utils';
import {
  MeanOfPayment,
  PaymentConfig,
  paymentPages,
  paymentsCode,
  paymentsIDs,
  Property
} from '../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyResourceService } from '../group-policy-resources/group-policy-resource.service';
import { GroupPolicyStatePolicyData, GroupPolicyStateSummary, GroupPolicyStateVcontVariation } from '../group-policy-state/group-policy-state';
import { GroupPolicyApiRestErrorService } from './group-policy-api-rest-error.service';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyPaymentService {

  constructor(protected resourceService: GroupPolicyResourceService,
    protected apiRestErrorService: GroupPolicyApiRestErrorService) { }

  public getEditablePaymentConfigs$(resId: string, page: paymentPages, st: GroupPolicyStateVcontVariation | GroupPolicyStatePolicyData | GroupPolicyStateSummary, isPostSales = false): Observable<PaymentConfig | null> {
    const getPaymentTypePagesPostSalesDebit$ = this.resourceService.getPaymentTypePagesPostSales$(resId, paymentsCode.debit).pipe(
      map((debitCustomProp: Property) => {
        const retrieveDebitMeans = debitCustomProp && debitCustomProp.values && debitCustomProp.values.includes(page);
        return retrieveDebitMeans;
      }),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );
    const getPaymentTypePagesPostSalesCredit$ = this.resourceService.getPaymentTypePagesPostSales$(resId, paymentsCode.credit).pipe(
      map((creditCustomProp: Property) => {
        const retrieveCreditMeans = creditCustomProp && creditCustomProp.values && creditCustomProp.values.includes(page);
        return retrieveCreditMeans;
      }),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );
    const getPaymentTypePagesDebit$ = this.resourceService.getPaymentTypePages$(resId, paymentsCode.debit).pipe(
      map((debitCustomProp: Property) => {
        const retrieveDebitMeans = debitCustomProp && debitCustomProp.values && debitCustomProp.values.includes(page);
        return retrieveDebitMeans;
      }),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );
    const getPaymentTypePagesCredit$ = this.resourceService.getPaymentTypePages$(resId, paymentsCode.credit).pipe(
      map((creditCustomProp: Property) => {
        const retrieveCreditMeans = creditCustomProp && creditCustomProp.values && creditCustomProp.values.includes(page);
        return retrieveCreditMeans;
      }),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );
    const getPaymentConfigPostSalesDebit$ = this.resourceService.getPaymentConfigPostSales$(resId, paymentsIDs.debit).pipe(
      map((debitPaymentConf: PaymentConfig) => debitPaymentConf),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );
    const getPaymentConfigPostSalesCredit$ = this.resourceService.getPaymentConfigPostSales$(resId, paymentsIDs.credit).pipe(
      map((debitPaymentConf: PaymentConfig) => debitPaymentConf),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );
    const getPaymentConfigDebit$ = this.resourceService.getPaymentConfig$(resId, paymentsIDs.debit).pipe(
      map((debitPaymentConf: PaymentConfig) => debitPaymentConf),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );
    const getPaymentConfigCredit$ = this.resourceService.getPaymentConfig$(resId, paymentsIDs.credit).pipe(
      map((debitPaymentConf: PaymentConfig) => debitPaymentConf),
      catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
    );

    return combineLatest(
      (isPostSales) ? getPaymentTypePagesPostSalesDebit$ : getPaymentTypePagesDebit$,
      (isPostSales) ? getPaymentTypePagesPostSalesCredit$ : getPaymentTypePagesCredit$
    ).pipe(
      mergeMap(([retrieveDebitMeans, retrieveCreditMeans]: [boolean, boolean]) => {
        const paymentConfigDebit$ = (isPostSales) ? getPaymentConfigPostSalesDebit$ : getPaymentConfigDebit$;
        const paymentConfigCredit$ = (isPostSales) ? getPaymentConfigPostSalesCredit$ : getPaymentConfigCredit$;
        return combineLatest(
          retrieveDebitMeans ? paymentConfigDebit$ : of(null),
          retrieveCreditMeans ? paymentConfigCredit$ : of(null)
        ).pipe(
          catchError(this.apiRestErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map(([debitPaymentConf, creditPaymentConf]: [PaymentConfig | null, PaymentConfig | null]) => {
        if (debitPaymentConf && debitPaymentConf.debMeansOfPayment && debitPaymentConf.debMeansOfPayment) {
          this.resetPayments(debitPaymentConf.debMeansOfPayment);
        }
        if (creditPaymentConf && creditPaymentConf.credMeansOfPayment && creditPaymentConf.credMeansOfPayment) {
          this.resetPayments(creditPaymentConf.credMeansOfPayment);
        }
        const res: PaymentConfig = debitPaymentConf || creditPaymentConf ? {
          debMeansOfPayment: debitPaymentConf && debitPaymentConf.debMeansOfPayment ?
            sortMeansOfPayment(debitPaymentConf.debMeansOfPayment) : undefined,
          credMeansOfPayment: creditPaymentConf && creditPaymentConf.credMeansOfPayment ?
            sortMeansOfPayment(creditPaymentConf.credMeansOfPayment) : undefined,
        } : null;
        return res;
      })
    );
  }

  protected resetPayments(meansOfPayment: MeanOfPayment[]) {
    meansOfPayment.forEach(meanOfPayment => {
      meanOfPayment.selected = false;
      if (meanOfPayment.paymentConfig && meanOfPayment.paymentConfig.PaymentsFields
        && meanOfPayment.paymentConfig.PaymentsFields.length) {
        meanOfPayment.paymentConfig.PaymentsFields.forEach(field => {
          field.value = undefined;
        });
      }
    })
  }

}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActiveRoute, RoutableComponent } from "@rgi/rx/router";
import { tap } from "rxjs/operators";
import { GroupPolicyVcontRouteData } from "../../group-policy-models/group-policy-vcont-date";
import { GroupPolicyStateVcontQuestionnaire } from '../../group-policy-state/group-policy-state';
import { GroupPolicyStateManagerQuestionnaire } from "../../group-policy-state/group-policy-statemanager-questionnaire/group-policy-stateless-op-questionnaire";


/**
 * @author: dmasone
 * @description: Component used for render vcont questionnaire step
 */
@Component({
  selector: 'rgi-gp-group-policy-vcont-questionnaire',
  templateUrl: './group-policy-vcont-questionnaire.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyVcontQuestionnaireComponent extends RoutableComponent implements OnInit, OnDestroy {

    public vertical = true;
    public showIndex = false;
    public stateVcontQuestionnaire: GroupPolicyStateVcontQuestionnaire;
    public previousStepData: GroupPolicyVcontRouteData;
    public questionnaireForm = new UntypedFormGroup({});

    constructor(protected activeRoute: ActiveRoute,
        protected stateManagerVcontQuestionnaire: GroupPolicyStateManagerQuestionnaire) {
        super();
    }


    ngOnInit(): void {
        this.previousStepData = this.activeRoute.getRouteData();
        this.stateManagerVcontQuestionnaire.getState$().pipe(
            tap((state: GroupPolicyStateVcontQuestionnaire) => {
                console.log(state);
            })
        ).subscribe((nextState: GroupPolicyStateVcontQuestionnaire) => {
            this.initPage(nextState);
        });
    }

    ngOnDestroy(): void { }

    protected initPage(nextState: GroupPolicyStateVcontQuestionnaire) {
        this.stateVcontQuestionnaire = nextState;
    }

    public callActionGoForword() {
        this.stateManagerVcontQuestionnaire.actionGoForword(this.previousStepData.resId);
    }

    public callActionBack(targetRoute?: string) {
        this.stateManagerVcontQuestionnaire.actionBack(this.previousStepData, this.activeRoute, targetRoute);
    }

    public callActionClose() {
        this.stateManagerVcontQuestionnaire.actionClose(this.previousStepData);
    }

}

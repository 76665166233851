import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxTabHeaderComponent} from './rgi-rx-tab-header/rgi-rx-tab-header.component';
import {RgiRxTabGroupComponent} from './rgi-rx-tab-group/rgi-rx-tab-group.component';
import {RgiRxTabContentDirective} from './rgi-rx-tab-content.directive';
import {RgiRxTabBodyComponent} from './rgi-rx-tab-body/rgi-rx-tab-body.component';
import {PortalModule} from '@angular/cdk/portal';
import {RgiRxTabDirective} from './rgi-rx-tab.directive';
import {RgiRxTabLabelDirective} from './rgi-rx-tab-label.directive';
import {A11yModule} from '@angular/cdk/a11y';
import {RgiRxTabWrapperDirective} from './rgi-rx-tab-wrapper.directive';
import {RgiRxQualityAssuranceModule} from '@rgi/rx';
import {RgiRxTabGroupTargetComponent} from './rgi-rx-tab-group-target-component';
import {RgiRxTabGroupLayoutDirective, RgiRxTabGroupColorDirective} from './rgi-rx-tab-group.semantic.directives';


@NgModule({
  declarations: [
    RgiRxTabDirective,
    RgiRxTabHeaderComponent,
    RgiRxTabGroupComponent,
    RgiRxTabContentDirective,
    RgiRxTabBodyComponent,
    RgiRxTabLabelDirective,
    RgiRxTabWrapperDirective,
    RgiRxTabLabelDirective,
    RgiRxTabGroupTargetComponent,
    RgiRxTabGroupColorDirective,
    RgiRxTabGroupLayoutDirective
  ],
  imports: [
    CommonModule,
    PortalModule,
    A11yModule,
    RgiRxQualityAssuranceModule
  ],
  exports: [
    RgiRxTabGroupComponent,
    RgiRxTabDirective,
    RgiRxTabContentDirective,
    RgiRxTabLabelDirective,
    RgiRxTabGroupTargetComponent,
    RgiRxTabGroupColorDirective,
    RgiRxTabGroupLayoutDirective
  ]
})
export class RgiRxTabModule {
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 're-issue-bond-list',
  templateUrl: './re-issue-bond-list.component.html',
  styleUrls: ['./re-issue-bond-list.component.css']
})
export class ReIssueBondListComponent implements OnInit {

  @Input() bindings: any[] = [];

  @Output() onDelete: EventEmitter<number> = new EventEmitter();
  @Output() onEdit: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
   
  }

}



import {RgiRxSemanticStatus, CSSBaseUnits} from '@rgi/rx';

export class RgiRxTabHeaderClassBuilder {
  private _color?: RgiRxSemanticStatus | "link";
  private _tabsRow?: number;
  private _minWidthTab?: number;
  private _layout?:
    | "grid"
    | "flex-grid"
    | "flex-auto"
    | "auto";
  private _wordBreak?: boolean;
  private _minWidthTabUnits: CSSBaseUnits = 'px';


  private constructor() {}

  static create() {
    return new RgiRxTabHeaderClassBuilder();
  }

  withColor(color: RgiRxSemanticStatus | "link") {
    this._color = color;
    return this;
  }

  withTabsRow(tabs: number) {
    this._tabsRow = tabs;
    return this;
  }

  withMinWidthTab(minWidthTab: number) {
    this._minWidthTab = minWidthTab;
    return this;
  }

  withLayout(layout: "grid" | "flex-grid" | "flex-auto" | "auto") {
    this._layout = layout;
    return this;
  }

  withWordBreak(breakText: boolean) {
    this._wordBreak = breakText;
    return this;
  }

  build(): { style: string, classes: string } {
    let clasSet: string[] = [];
    let styleSet: string[] = [];
    if (this._color) {
      clasSet.push(`rgi-ui-${this._color}`);
      if (this._layout) {
        let layout = `rgi-ui-tabs-${this._layout}`;
        if (this._layout !== 'auto' && this._tabsRow) {
          layout += `-${this._tabsRow}`;
        }
        clasSet.push(layout);
      }
    }
    if (this._wordBreak) {
      clasSet.push(`rgi-ui-word-break`);
    }
    if (this._minWidthTab) {
      styleSet.push(`--grid-item-min-width: ${this._minWidthTab}${this._minWidthTabUnits}`)
    }
    return {
      classes: clasSet.join(' '),
      style: styleSet.join(' ')
    };
  }

  withMinWidthTabUnits(minWidthTabUnits: "px" | "em" | "rem") {
    this._minWidthTabUnits = minWidthTabUnits;
    return this;
  }
}

import {
  Component, EventEmitter, forwardRef, Inject, Input, OnChanges,
  OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { ValidatorService } from 'angular-iban';
import { Subscription } from 'rxjs';
import { EMPTY_STR, PV_TOKEN } from '../../../../models/consts/lpc-consts';
import { customAmountValidator } from '../../../../utils/custom-validators/custom-validators-utils';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { PlcObjectUtils } from '../../../../utils/plc-object-utils';
import { KarmaProfile } from '../../../lpc-karma-funds/model/karma-profile';
import { KarmaProfileDefinition } from '../../../lpc-karma-funds/model/karma-profile-definition';
import { FactorInputType, Rule, ToolConfDefinition } from '../../model/tool-conf-definition';
import { ToolDefinition } from '../../model/tool-definition';
import { LpcToolUtils } from '../../utils/lpc-tool-utils';
import { FACTOR_TOOL_CODE, FactorToolCode, ToolCode, ToolCodeIdType } from './../../../../models/enums/vita.enum';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../../services/currency-cache.service';
import { KarmaFundDefinition } from '../../../lpc-karma-funds/model/karma-fund-definition';

@Component({
  selector: 'lpc-tool[definition]',
  templateUrl: './lpc-tool.component.html',
  styleUrls: ['./lpc-tool.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcToolComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcToolComponent),
      multi: true
    },
    RgiCountryLayerNumberFormatPipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcToolComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  public formGroup: UntypedFormGroup;

  private $subscriptions: Subscription[] = [];

  private $defaultValueStructure: any;

  private $control: AbstractControl;

  // created boolean global value to avoid slow flow on the page due to the get method on the html
  public isProgressiveSwitch = false;

  @Input() public definition: ToolDefinition;

  @Input() public totalAmount: number;

  @Input() public showFundDistribution = false;

  @Input() public withValidation = true;

  @Input() public operationCode = EMPTY_STR;

  @Input() public active: boolean;

  @Input() public policyProfiles: KarmaProfile[] = [];

  @Output() factorReload: EventEmitter<any> = new EventEmitter<any>();

  disinvestments: Map<string, boolean> = new Map<string, boolean>();

  public get totalInvestAmount(): number {
    if (!this.isToolProgressiveSwitch()) {
      return this.totalAmount;
    } else {
      let amount = 0;
      const profileGroup = this.formGroup.get(this.profilesFormKey) as UntypedFormGroup;
      Object.keys(profileGroup.controls).forEach(profileKey => {
        const fundGroup = this.formGroup.get(this.profilesFormKey).get(profileKey).get(this.fundsFormKey) as UntypedFormGroup;
        Object.keys(fundGroup.controls).forEach(disinvest => {
          const disinvestValue = Number(fundGroup.get(disinvest).value);
          if (disinvestValue !== null && !isNaN(disinvestValue)) {
            amount += disinvestValue;
          }
        });
      });
      return amount;
    }
  }

  /** @deprecated
   * the visibility depends on the default funds value if its true it must shows the profiles
   */
  protected $toolsWithVisibleInvestmentProfiles: ToolCodeIdType[] = [ToolCode.SCHEDULED_PREMIUM, ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL];

  private readonly profilesFormKey = 'disinvestmentProfiles';
  private readonly fundsFormKey = 'funds';
  private readonly factorsFormKey = 'factors';
  private $factors: ToolConfDefinition[];
  public get factors(): ToolConfDefinition[] {
    return this.$factors;
  }

  public get visibleFactors(): ToolConfDefinition[] {
    return this.$factors.filter(factor => factor.visible);
  }

  private $investmentProfiles: KarmaProfileDefinition[];
  public get investmentProfiles(): KarmaProfileDefinition[] {
    return this.$investmentProfiles;
  }

  private $disinvestmentProfiles: KarmaProfileDefinition[];
  public get disinvestmentProfiles(): KarmaProfileDefinition[] {
    return this.$disinvestmentProfiles;
  }

  public get showInvestmentProfiles(): boolean {
    return !this.formGroup.get('defaultFunds').value;
  }
  get minThreshold() {
    if (!!this.definition.disinvestmentProfiles) {
      const minThreshold = this.definition.factors.find(
        factor => factor.code === FACTOR_TOOL_CODE.MIN_THRESHOLD_CODE
      );
      return minThreshold && minThreshold.value || 0;
    }
  }
  get maxThreshold() {
    if (!!this.definition.disinvestmentProfiles) {
      const maxThreshold = this.definition.factors.find(
        factor => factor.code === FACTOR_TOOL_CODE.MAX_THRESHOLD_CODE
      );
      return maxThreshold && maxThreshold.value || 0;
    }
  }

  // controllo se c'è un solo profilo ed un solo fondo selezionabile per mostrare il riassunto
  get isOnlyOneFundAvailable() {
    return !!this.definition.investmentProfiles && this.definition.investmentProfiles.length
      && this.definition.investmentProfiles.length === 1 &&
      !!this.definition.investmentProfiles[0].funds && this.definition.investmentProfiles[0].funds
      && this.definition.investmentProfiles[0].funds.length === 1;
  }

  /**
   * @description
   * returns 'PERC' as the sliderProp to disable the amount setting just for the progressive switch
   * @see RDDL-3672
   */
  get sliderProp() {
    if (this.isToolProgressiveSwitch) {
      return 'PERC';
    }
    return;
  }

  /** @description
   * return a boolean accordin to the length of the investments definition
   */
  protected get investmentsAvailable(): boolean {
    return !!this.definition.investmentProfiles && !!this.definition.investmentProfiles.length;
  }

  public getFactorsByGroup(group: string = null): ToolConfDefinition[] {
    return this.visibleFactors.filter(factor => factor.group === group);
  }

  protected occurrenceMinValue(): number {
    return 1;
  }

  // created to be overridden from other project to add max validation
  protected occurrenceMaxValue(): number {
    return;
  }

  constructor(
    protected translate: TranslationWrapperService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    @Optional() @Inject(PV_TOKEN.TOOLS_HIDE_ENUMS) protected hideEnumWithOneValue: boolean,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
    ) {
      this.currencyFormatterOptions.currency = currencyService.currency;
    }

  ngOnInit() {
    this.isProgressiveSwitch = this.isToolProgressiveSwitch();
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe(result => {
        this.onChange(this.formGroup.getRawValue());
        this.onTouch();
      })
    );
    if ((this.isScheduledPremium() || this.isScheduledPartialWithdrawal) && this.isPaymentModeDefined()) {
      this.$subscriptions.push(
        this.formGroup.get('factors').get(FACTOR_TOOL_CODE.PAYMENTMODES_CODE).valueChanges.subscribe((value) => {
          this.$factors = this.updateIbanVisibility();
        })
      );
    }
  }

  private initializeDisinvestmentFundsSelectionMap() {
    this.disinvestmentProfiles
    .forEach(prof => {
      prof.funds
      .map(fund => fund.id) 
      .forEach(fund => this.disinvestments.set(`${prof.profileId}_${fund}`, !!this.getFundValueBasedOnToolCode(prof.funds.find(el => el.id === fund))));
    });
  }

  private updateIbanVisibility(): ToolConfDefinition[] {
    const PAYMENT_RID = '3';
    const PAYMENT_TRANSFER = '4';
    const PAYMENT_BANKACCOUNT = '5';
    const paymentMode = this.formGroup.get('factors').get(FACTOR_TOOL_CODE.PAYMENTMODES_CODE).value;

    return this.$factors.map(factor => {
      if (factor.visible && factor.code === FACTOR_TOOL_CODE.IBAN_CODE) {
        factor.visible = [PAYMENT_RID, PAYMENT_TRANSFER, PAYMENT_BANKACCOUNT].includes(paymentMode);
        this.updateIBANValidation(factor);
      }
      return factor;
    });
  }

  private updateIBANValidation(factor: ToolConfDefinition): void {
    const formGroup: UntypedFormGroup = this.formGroup.get(this.factorsFormKey) as UntypedFormGroup;
    const formControl: UntypedFormControl = formGroup.get(factor.code) as UntypedFormControl;

    if (!!factor.visible) {
      if (!formControl) {
        formGroup.addControl(factor.code, new UntypedFormControl());
      }
      formGroup.get(factor.code).setValidators(
        (!!factor.mandatory) ? [Validators.required, ValidatorService.validateIban] : [ValidatorService.validateIban]
      );
    } else {
      if (!!formControl) {
        formControl.clearValidators();
        formGroup.removeControl(factor.code);
      }
    }
  }

  private isScheduledPremium(): boolean {
    return this.definition.operationCodeId === ToolCode.SCHEDULED_PREMIUM;
  }

  private isScheduledPartialWithdrawal(): boolean {
    return this.definition.operationCodeId === ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL;
  }

  private isToolProgressiveSwitch(): boolean {
    return LpcToolUtils.isToolProgressiveSwitch(this.definition.operationCodeId);
  }

  private isPaymentModeDefined() {
    return this.definition.factors.find(factor => factor.code === FACTOR_TOOL_CODE.PAYMENTMODES_CODE) !== undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definition) {
      this.$factors = PlcObjectUtils.asValidArray<ToolConfDefinition>(changes.definition.currentValue.factors);
      this.$investmentProfiles = PlcObjectUtils.asValidArray<KarmaProfileDefinition>(
        changes.definition.currentValue.investmentProfiles
      );
      this.$disinvestmentProfiles = PlcObjectUtils.asValidArray<KarmaProfileDefinition>(
        changes.definition.currentValue.disinvestmentProfiles
      );

      this.formGroup = this.getFormGroup();
      this.initializeDisinvestmentFundsSelectionMap();
      this.$defaultValueStructure = LpcToolUtils.getDefaultFormValueOf(this.definition, false, false, this.hideEnumWithOneValue);
      this.$factors.forEach(factor => {
        const formControl: UntypedFormControl = this.formGroup.get(this.factorsFormKey).get(factor.code) as UntypedFormControl;
        formControl.clearValidators();
        const validators = [];
        if (factor.visible && factor.mandatory) {
          validators.push(Validators.required);
        }
        if (factor.type === FactorInputType.IBAN) {
          validators.push(ValidatorService.validateIban);
        }
        if (factor.type === FactorInputType.DOUBLE) {
          validators.push(customAmountValidator);
        }
        if (factor.type === FactorInputType.INT) {
          validators.push(Validators.min(this.occurrenceMinValue()));
          if (!!this.occurrenceMaxValue()) { // check if the value is not null then it will add the validator
            validators.push(Validators.min(this.occurrenceMaxValue()));
          }
        }
        formControl.setValidators(validators);

      });
      const activeTools = {};
      this.$disinvestmentProfiles.forEach(profile => {
        activeTools[profile.id] = {};
        profile.funds.forEach(fund => {
          activeTools[profile.id][fund.id] = false;
        });
      });
    }
    if (changes.withValidation) {
      if (this.$control) {
        this.$control.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (!!obj) {
      this.formGroup.patchValue(PlcObjectUtils.merge({}, this.$defaultValueStructure, obj), {emitEvent: false});
      if ((this.isScheduledPremium() || this.isScheduledPartialWithdrawal) && this.isPaymentModeDefined()) {
        this.$factors = this.updateIbanVisibility();
      }
    } else {
      this.formGroup.patchValue(PlcObjectUtils.merge({}, this.$defaultValueStructure), {emitEvent: false});
    }
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    control.setErrors(null);
    this.$control = control;
    if (this.withValidation) {
      const errors: ValidationErrors = {};
      this.definition.factors.map(factor => {
        this.ruleInterpreter(control, factor, errors);
      });
      Object.assign(errors, this.validateFactors(control));
      if (!!this.definition.disinvestmentProfiles) {
        if (!!this.formGroup.get('disinvestmentProfiles')) {
          let percSum = 0;
          Object.keys((this.formGroup.get('disinvestmentProfiles') as UntypedFormGroup).controls).forEach((profileKey) => {
            const profileGroup = this.formGroup.get('disinvestmentProfiles').get(profileKey) as UntypedFormGroup;
            Object.keys((profileGroup.get('funds') as UntypedFormGroup).controls).forEach((fundKey) => {
              percSum += !!profileGroup.get('funds').get(fundKey).value ? +profileGroup.get('funds').get(fundKey).value : 0;
            });
            if (percSum === 0) {
              Object.assign(errors, { disinvestmentFunds: 'Incomplete disinvestment for the funds' });
            }
          });
        }
        // in case of the progressiveSwitch ill have the disinvestmentAmount as field on the disinvestment component
        Object.assign(errors, this.disinvestmentFieldValidation());
        // Object.assign(errors, this.thresholdValidation());
      }
      if (!this.formGroup.get('defaultFunds').value && !this.isOnlyOneFundAvailable && this.investmentsAvailable) {
        // se la distibuzione fondi identica è disabilitata controllo se sono stati selezionati dei fondi
        // di investmento per ogni profilo su cui è stato investimento,
        // se l'investimento sui fondi non è completo lancio l'errore
        const investmentProfilesForm = this.formGroup.get('investmentProfiles').value;
        if (!!investmentProfilesForm) {
          const isThereAtLeastAnInvestment = Object.keys(investmentProfilesForm)
                                    .map(invest => Object.values(investmentProfilesForm[invest].funds))[0]
                                    .some(value => !!value);
          if (!isThereAtLeastAnInvestment) {
            Object.assign(errors, { investmentFunds: this.translate.getImmediate('lpc_incomplete_fund_investment') });
          }
        } else {
          Object.assign(errors, { investmentProfile: this.translate.getImmediate('lpc_incomplete_profile_investment') });
        }
      }

      if (!!Object.keys(errors).length) {
        return errors;
      }
    }
    return null;
  }


  private validateFactors(control: AbstractControl) {
    const factorErrors: any = {};
    Object.keys((this.formGroup.get(this.factorsFormKey) as UntypedFormGroup).controls).forEach((key: FactorToolCode) => {
      const factor = this.$factors.find(f => f.code === key);
      const isVisible = factor.visible;
      if (!!control.value) {
        Object.keys(control.value.factors).forEach(el => {
          if (this.isVisibleFactorEmpty(el, key, isVisible, control) && factor.mandatory) {
            factorErrors[key] = true;
          } else if (el === key  && el === FACTOR_TOOL_CODE.IBAN_CODE && this.isFactorFieldInvalid(key)) {
            factorErrors[key] = this.formGroup.get(this.factorsFormKey).get(key).invalid;
            // checking if the occurrence factor is empty or invalid and it will add a validation message catched on the tool-manager
          }
          if (el === key && el === FACTOR_TOOL_CODE.OCCURRENCE_NUMBER_CODE && this.isFactorFieldInvalid(key)) {
            factorErrors[key] =  this.translate.getImmediate('lpc_the_occurenceNumber_must_be_a_value', {
              min: this.occurrenceMinValue(),
              max: this.occurrenceMaxValue()
            });
          }
        });
      }
      // gets the generic errors on the amount generated from a customValidator
      const controlErrors = (this.formGroup.get(this.factorsFormKey) as UntypedFormGroup).controls[key].errors;
      if (!!controlErrors && !!controlErrors.error) {
        factorErrors.error = controlErrors.error;
      }
    });
    return factorErrors;
  }

  private isVisibleFactorEmpty(el: any, key: FactorToolCode, isVisible: boolean, control: AbstractControl): boolean {
    return el === key && isVisible && (control.value.factors[key] == null || control.value.factors[key].trim() === EMPTY_STR);
  }

  private isFactorFieldInvalid(factorKey: FactorToolCode): boolean {
    return this.formGroup.get(this.factorsFormKey).get(factorKey).invalid;
  }

  ruleInterpreter(control: AbstractControl, factor: ToolConfDefinition,
                  errors: { [key: string]: string | boolean } ): { [key: string]: any }  {
    if (!!factor.rules && !!factor.rules.length) {
      factor.rules.forEach(rule => {
        if (!rule.dependencies.length) {
          Object.assign(errors, {});
          Object.assign(errors, this.ruleValidator(control, rule, factor.code));
        } else {
          rule.dependencies.forEach((dep) => {
            if (!!control.value) {
              Object.keys(control.value.factors).forEach((factorKey: FactorToolCode) => {
                if (factorKey === dep.factorCode && control.value.factors[factorKey] === dep.rule.value) {
                  Object.assign(errors, {});
                  Object.assign(errors, this.ruleValidator(control, rule, factor.code));
                }
              });
            }
          });
        }
      });
    }
    return errors;
  }

  ruleValidator(control: AbstractControl, rule: Rule, code: FactorToolCode ): { [key: string]: any } {
    let msg = {};
    Object.keys(control.value.factors).forEach(el => {
      if (el === code) {
        switch (rule.ruleType) {
          case 'GE':  if (Number.parseFloat(control.value.factors[code]) < Number.parseFloat(rule.value)) {
                        msg = { operator: 'GE', value: rule.value, factor: this.translate.getImmediate(code) };
                      }
                      break;
          case 'LE':  if (Number.parseFloat(control.value.factors[code]) > Number.parseFloat(rule.value)) {
                        msg = { operator: 'LE', value: rule.value, factor: this.translate.getImmediate(code) };
                      }
                      break;
          case 'EQ':  if (Number.parseFloat(control.value.factors[code]) !== Number.parseFloat(rule.value)) {
                        msg = { operator: 'EQ', value: rule.value, factor: this.translate.getImmediate(code) };
                      }
                      break;
        }
      }
    });
    return msg;
  }


  disinvestmentFieldValidation(): ValidationErrors | null {
    const profileGroup = this.formGroup.get(this.profilesFormKey) as UntypedFormGroup;
    const errors: ValidationErrors = {};
    Object.keys(profileGroup.controls).forEach(profileKey => {
      const fundGroup = this.formGroup.get(this.profilesFormKey).get(profileKey).get(this.fundsFormKey) as UntypedFormGroup;
      Object.keys(fundGroup.controls)
        .forEach(key => {
          this.addDisinvestmentValidation(profileKey, key, errors);
        });
    });
    return !!Object.keys(errors).length ? errors : null;
  }

  /**
   * @description
   * enters in each disinvestment fund and checks it has a value and in case it has it will add an error.
   * for the tools != from the progressive switch it will add an error for the threshold otherwise disinvestmentsAmount
   */
  protected addDisinvestmentValidation(profileKey: string, key: string, errors: ValidationErrors) {
    // Construct the key for accessing the disinvestments map
    const mapKey = `${profileKey}_${key}`;
  
    // Check if the fund is active; if not, return early
    if (!this.disinvestments.get(mapKey)) {
      return; // Skip validation for inactive funds
    }
  
    const fundControl = this.formGroup.get(this.profilesFormKey).get(profileKey).get(this.fundsFormKey).get(key);
    if (fundControl.hasError('min') || fundControl.hasError('max')) {
      if (!this.isToolProgressiveSwitch() && !!this.isFundWithValue(profileKey, key)) {
        Object.assign(errors, { minMaxThreshold: true, min: this.minThreshold, max: this.maxThreshold });
      }
      if (this.isToolProgressiveSwitch() && !!this.isFundWithValue(profileKey, key)) {
        Object.assign(errors, {
          minMaxDisinvestmentsAmount: true,
          min: this.rgiNumberFormatter.transform(this.getMinDisinvestmentsAmount().toString(), '', this.currencyFormatterOptions),
          max: this.rgiNumberFormatter.transform(this.getMaxDisinvestmentsAmount(key).toString(), '', this.currencyFormatterOptions)
        });
      }
    }
    if (!this.isFundWithValue(profileKey, key) && fundControl.hasError('required')) {
      if (!this.isToolProgressiveSwitch() && this.isDisinvestmentActive(profileKey, key)) {
        Object.assign(errors, {
          thresholdMissing: true, fund: this.disinvestmentProfiles.find(p => p.profileId === profileKey)
            .funds.find(fund => fund.id === key).description
        });
      }
      if (this.isToolProgressiveSwitch()) {
        if (this.isDisinvestmentActive(profileKey, key)) {
          Object.assign(errors, {
          disinvestmentAmountMissing: true, fund: this.disinvestmentProfiles.find(p => p.profileId === profileKey)
            .funds.find(fund => fund.id === key).description
          });
        }
        if (!this.hasAtleastADisinvestmentActive()) {
          Object.assign(errors, { addAtLeastADisinvestment: true });
        }
      }
    }
  }


  /**
   * @returns number: 1
   * @see RDDL-3672
   */
  getMinDisinvestmentsAmount(): number {
    return 1;
  }

  /**
   * @returns number
   * @description
   * the amount of the fund selected on the "fund investments" step, that will be the maxDisinvestmentsAmount
   * @see RDDL-3672
   */
  getMaxDisinvestmentsAmount(fundKey: string): number {
    if (!!this.policyProfiles.length) {
      const investedProfile = this.policyProfiles.find(profile => profile.id === this.definition.disinvestmentProfiles[0].id);
      return !!investedProfile.funds.find(fund => fund.id === fundKey) ?
      investedProfile.funds.find(fund => fund.id === fundKey).amount : 0;
    } else {
      return 0;
    }
  }

  onToolCheck(profileId: string, fundId: string, isActive: boolean): void {
    this.disinvestments.set(`${profileId}_${fundId}`, isActive);
    const fund = this.formGroup.get(this.profilesFormKey).get(profileId).get(this.fundsFormKey).get(fundId);
    if (!isActive) {
      fund.setValue(null);
    } else {
      fund.setValidators(this.getDisinvesmentValidators(1, fundId));
    }
  }

  isFundWithValue(profileId: string, fundId: string): boolean {
    return !!this.formGroup.get(this.profilesFormKey).get(profileId).get(this.fundsFormKey).get(fundId).value;
  }

  isDisinvestmentActive(profileId: string, fundId: string): boolean {
    return !!this.disinvestments.get(`${profileId}_${fundId}`);
  }

  hasAtleastADisinvestmentActive(): boolean {
    return Array.from(this.disinvestments.values()).some(activeDisinvest => activeDisinvest);
  }

  private getFormGroup(): UntypedFormGroup {
    const fg: UntypedFormGroup = new UntypedFormGroup({
      factors: new UntypedFormGroup({}),
      investmentProfiles: new UntypedFormControl(),
      disinvestmentProfiles: new UntypedFormGroup({}),
      defaultFunds: new UntypedFormControl(!!this.definition.usePolicyProfiles)
    });
    this.$factors.forEach(factor => {
      (fg.get(this.factorsFormKey) as UntypedFormGroup).addControl(factor.code, new UntypedFormControl());
      if (factor.readOnly) {
        fg.get(this.factorsFormKey).get(factor.code).disable({ emitEvent: false });
      }
    });
    this.$disinvestmentProfiles.forEach(profile => {
      (fg.get(this.profilesFormKey) as UntypedFormGroup).addControl(
        profile.id, new UntypedFormGroup({ funds: new UntypedFormGroup({}), percent: new UntypedFormControl()})
      );
      profile.funds.forEach(fund => {
        (fg.get(this.profilesFormKey).get(profile.id).get(this.fundsFormKey) as UntypedFormGroup)
          .addControl(fund.id, new UntypedFormControl(this.getFundValueBasedOnToolCode(fund), this.getDisinvesmentValidators(fund.value, fund.id)));
        });
    });
    return fg;
  }
  
  getFundValueBasedOnToolCode(fund: KarmaFundDefinition): number {
    if (!this.isToolProgressiveSwitch()) {
      if(!!fund.percent && typeof fund.percent === 'string')
      return parseFloat(fund.percent) ? fund.percent : null
    }
    return fund.value
  }

  getDisinvesmentValidators(value: number, fundID): ValidatorFn[] {
    const validators = [];
    if (!this.isToolProgressiveSwitch()) {
      validators.push(
        Validators.max(this.maxThreshold),
        Validators.min(this.minThreshold)
      );
      if (!!value) {
        validators.push(Validators.required);
      }
    } else {
      validators.push(
        Validators.required,
        Validators.max(this.getMaxDisinvestmentsAmount(fundID)),
        Validators.min(this.getMinDisinvestmentsAmount())
      );
    }
    return validators;
  }

  public changedDistro(): boolean {
    return this.showFundDistribution && !this.formGroup.get('defaultFunds').value && !this.active && !!this.investmentProfiles.length;
  }

  public unchangedDistro(): boolean {
    return this.showFundDistribution && !!this.formGroup.get('defaultFunds').value && !this.active && !!this.investmentProfiles.length;
  }

  public onFactorReload(event) {
    this.factorReload.emit(event);
  }
}

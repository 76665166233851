import {Component, ComponentRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Modal} from '../../modal';
import {ProposalService} from '../../proposal.service';
import {ApiOctoInstallersFilter} from '../../models/api-models/api-octo-installers-filter';
import {OctoInstallerData} from '../../models/api-models/api-octo-installers-response';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'installer-modal',
  templateUrl: './installer-modal.component.html',
  styleUrls: ['./installer-modal.component.scss']
})

export class InstallerModalComponent implements Modal, OnInit {

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectableModal = true;

  @Input()
  isInstallerSelectionButtonVisible = true;

  componentRef: ComponentRef<any>;
  title = 'Octo Telematics - Installer Data';
  zipCode: string;
  installers: OctoInstallerData[];
  installerData: any;
  visible: boolean[] = new Array<boolean>();
  itemsForPage = 10;
  page = 0;
  search: string = null;
  filteredInstallers: OctoInstallerData[] = [];

  constructor(protected proposalService: ProposalService) {
  }

  ngOnInit() {
    const installersFilter = new ApiOctoInstallersFilter();
    installersFilter.filterInstallersOcto = {
      nation: 'IT',
      zipCode: this.zipCode
    };
    this.proposalService.findInstallers(installersFilter).subscribe(result => {
      this.installers = result.installersOcto;
      this.filteredInstallers = this.installers;
    });
  }

  close() {
    this.componentRef.destroy();
  }

  select(installer) {
    this.installerData = installer;
    this.close();
  }

  getPages() {
    if (this.filteredInstallers && this.filteredInstallers.length > 0) {
      return new Array(Math.ceil((this.filteredInstallers.length / this.itemsForPage)));
    }
    return [{}];
  }

  filterInstallers() {
    if (this.search && this.search.trim() !== '') {
      this.filteredInstallers = this.installers.filter(elem => {
        return elem.businessName.toUpperCase().includes(this.search.trim().toUpperCase())
          || elem.address.toUpperCase().includes(this.search.trim().toUpperCase())
          || elem.installerCode.toUpperCase().includes(this.search.trim().toUpperCase());
      });
    } else {
      this.filteredInstallers = this.installers;
    }
    this.setPage(0);
  }

  setPage(n: number) {
    this.page = n;
  }

  getItemsToShow() {
    return this.filteredInstallers.slice(this.page * this.itemsForPage, this.page * this.itemsForPage + this.itemsForPage);
  }
}


export class ApiDisabledComponents {

  public static SECTION_POLICYHOLDER = 'policyholder';
  public static SECTION_OWNER = 'owner';
  public static SECTION_BUYER = 'buyer';
  public static SECTION_LESSEE = 'lessee';

  public static SECTION_ASSET_CLAUSES = 'asset_clauses';
  public static SECTION_INSURANCE_STATUS = 'insurance_status';
  public static SECTION_VEHICLE_DATA = 'vehicle_data';
  public static SECTION_ASSET_VARIABLES = 'asset_variables';

  public static SECTION_POLICY_DATA = 'policy_data';
  public static SECTION_POLICY_ADDRESS = 'policy_address';

  constructor(
    public components?: FieldComponent[],
    public unhandledCase?: boolean,
  ) {
  }
}

export class FieldComponent {
  constructor(
    public code: string,
    public enabled: boolean
  ) {
  }
}

import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MicHttpService} from './http/mic-http.service';
import {MicHttpDisableErrorInterceptorService} from './http/mic-http-disable-error-interceptor.service';
import {ProposalService} from './proposal.service';
import {ApiReg51Allowed} from './models/api-models/api-reg51-allowed';

@Injectable({
  providedIn: 'root'
})
export class IvassService {

  public DISABLE_ERR_INTERCEPT_HEADER = 'X-Disable-Error-Interceptor';
  protected baseApiUrlV2;
  protected baseApiUrlV1;
  protected httpClient: HttpClient;
  constructor(
    protected micHttpClient: MicHttpService,
    protected proposalService: ProposalService,
    @Inject('environment') environment: any
  ) {
    this.httpClient = this.micHttpClient.getHttpClient();
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  isReg51Allowed(): Observable<ApiReg51Allowed> {
    const path = `${this.baseApiUrlV2}/motor/contract/${this.proposalService.getContractId()}/ivass/quote/allowed`;
    return this.httpClient.get<ApiReg51Allowed>(path);
  }

  callReg51(): Observable<any> {
    const headers = MicHttpDisableErrorInterceptorService.getErrorInterceptorDisable();
    const path = `${this.baseApiUrlV2}/motor/contract/${this.proposalService.getContractId()}/ivass/quote`;
    return this.httpClient.post<any>(path, null,  {headers});
  }


}

<div *ngIf="stateManager.getState$() | async as state">
  <div class="rgi-ui-title-2 confirm-title">
    <ng-container>
      {{('RE_ISSUE.POLICY' | translate) + ' n. ' + state.contract.policyNumber + ' ' + ('RE_ISSUE.CANCELED_SUCCESSFULLY' | translate)}}
    </ng-container>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="171" height="223" viewBox="0 0 171 223">
        <g id="Oggetto_vettoriale_avanzato" data-name="Oggetto vettoriale avanzato" transform="translate(-25.65)">
          <path id="Tracciato_269" data-name="Tracciato 269"
                d="M179.55,223H42.75a17.127,17.127,0,0,1-17.1-17.154V51.462a17.127,17.127,0,0,1,17.1-17.154h136.8a17.127,17.127,0,0,1,17.1,17.154V205.847A17.127,17.127,0,0,1,179.55,223Z"
                fill="#145f9e"/>
          <path id="Tracciato_270" data-name="Tracciato 270"
                d="M179.55,51.462H42.75V169.17a5.727,5.727,0,0,0,1.669,4.043L75.28,204.171a5.692,5.692,0,0,0,4.03,1.675H179.549V51.462Z"
                fill="#f4f5f5"/>
          <path id="Tracciato_271" data-name="Tracciato 271"
                d="M143.9,26.73a11.786,11.786,0,0,1-8.1-8.125,25.637,25.637,0,0,0-49.307,0,11.806,11.806,0,0,1-8.112,8.129A25.73,25.73,0,0,0,59.851,51.256V68.615h102.6V51.461A25.731,25.731,0,0,0,143.9,26.73ZM111.26,34.308a8.577,8.577,0,1,1,8.55-8.577A8.564,8.564,0,0,1,111.26,34.308Z"
                fill="#cdd7de"/>
          <path id="Tracciato_272" data-name="Tracciato 272" d="M59.851,51.462V68.616h102.6V51.462Z" fill="#c0ccd5"/>
          <rect id="Rettangolo_4" data-name="Rettangolo 4" width="102.6" height="1.287"
                transform="translate(59.851 67.33)" fill="#b5bfc8"/>
          <path id="Tracciato_273" data-name="Tracciato 273"
                d="M76.95,182.975a11.418,11.418,0,0,0-11.4-11.436H43.28a5.7,5.7,0,0,0,1.14,1.675L75.28,204.171a5.689,5.689,0,0,0,1.669,1.143v-22.34Z"
                fill="#e6e6e5"/>
          <path id="Tracciato_274" data-name="Tracciato 274"
                d="M74.167,175.453a11.52,11.52,0,0,1,2.783,7.522v22.34a5.659,5.659,0,0,0,2.361.531h25.157Z"
                fill="#cdcccc"/>
          <path id="Tracciato_275" data-name="Tracciato 275"
                d="M162.628,97.9,150.536,85.77,96.124,140.355,71.941,116.086,59.85,128.225l36.275,36.389Z"
                fill="#f68020"/>
        </g>
      </svg>
    </div>
  </div>

  <div class="btn-group btn-group-justified">
    <div class="btn-group">
      <button data-qa="" rgi-rx-button color="primary" class="btn pull-right" translate>RE_ISSUE.PRINT
      </button>
    </div>
    <div class="btn-group">
            <span><button class="btn btn-warning pull-right" color="primary"
                          data-qa="Continue">
                          <span translate>RE_ISSUE.DOCUMENTS</span></button></span>
    </div>
    <div class="btn-group">
            <span><button (click)="close()" rgi-rx-button color="secondary" class="btn pull-right"
                          data-qa="end">
                          <span translate>RE_ISSUE.END</span></button></span>
    </div>
  </div>
</div>

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" [errors]="errors" fieldId="dates" id="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
                    (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step label="lpc_variation_step_label" [errors]="errors" fieldId="factors" id="factors" (next)="onNext($event)">
    <div class="panel-body">
      <div class="row">
        <div class="col-lg-6 factor-box" *ngIf="isActiveStep('factors')">
          <label class="label-core">
            {{'lpc_payment_frequency' | translate}}
            <lpc-required required="true"></lpc-required>
          </label>

          <ng-select *ngIf="paymentFrequency" (change)="onChange($event)" formControlName="factors" data-qa="paymentFrequency-select">
            <ng-option *ngFor="let option of options" [value]="option.code">{{option.description}}
            </ng-option>
          </ng-select>
        </div>
        <div *ngIf="!isActiveStep('factors')">
          <span> {{'lpc_payment_frequency' | translate}}: </span>
          <span>{{findlabel(formGroupValue.factors)}}</span>
        </div>
      </div>
    </div>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" id="quotation" fieldId="quotation" (next)="onNext($event)"
            (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div formGroupName="quotation" class="overflow">
      <div class="row">
        <div class="col-sm-6" *ngFor="let premium of listPremiumAmount">
          <lpc-premium-table [premium]="premium"></lpc-premium-table>
        </div>
      </div>
    </div>
  </lpc-step>

  <lpc-step *ngIf="hasPaymentTypes" [enableAuth]="isAuth" label="lpc_mean_of_payment" [errors]="errors" fieldId="payment" id="payment" (next)="onNext($event)">
    <lpc-payment-step
        [required]="true"
        [paymentTypeDefinitions]="paymentTypes"
        formControlName="payment"
        [summary]="isAfterId('payment')"
        [isPaymentTypeEditable]="isPaymentEditable">
    </lpc-payment-step>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
            (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

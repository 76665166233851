import {Component, EventEmitter, Inject, OnInit, Output, Type, ViewChild, ViewContainerRef, } from '@angular/core';
import {VariablesComponent} from '../variables/variables.component';
import {VariablesService} from '../variables/variables-service';
import {GISService} from '../gis-service';
import {ProposalService} from '../proposal.service';
import {DatePipe} from '@angular/common';
import {CustomModalService} from '../custom-modal.service';
import {Modal} from '../modal';
import {PartyVariablesModalComponent} from './party-variables-modal/party-variables-modal.component';

@Component({
  selector: 'mic-party-variables',
  templateUrl: './party-variables.component.html',
  styleUrls: ['./party-variables.component.scss']
})
export class PartyVariablesComponent extends VariablesComponent implements OnInit {


  @Output()
  showPartyVariablesEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('variablesModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) variablesModalViewContainerRef: ViewContainerRef;
  componentRef: any;

  constructor(
    protected variablesService: VariablesService,
    protected gisService: GISService,
    protected proposalService: ProposalService,
    protected datePipe: DatePipe,
    protected modalService: CustomModalService,
    @Inject('partyVariablesModalComponent') variablesModalComponent?: Type<Modal>
  ) {
    super(variablesService, gisService, proposalService, datePipe, modalService, variablesModalComponent);
  }

  goToEditVariables() {
    this.variablesService.setLastSelectedVariablesType(this.variablesType);

    this.modalService.openModal(this.variablesModalViewContainerRef,
      (this.variablesModal ? this.variablesModal : PartyVariablesModalComponent),
      this.eventPropagation, () => {

        const cachedVariables = this.variablesService.getCachedVariables();
        this.variables = this.filterVariables(cachedVariables);
        this.filteredVariables = this.filterVariables(cachedVariables);
        this.loadVariables();
        if (this.areVariablesCompleted()) {
          this.variablesCompleted.emit(true);
        }
      });
  }

}

import { Component, OnInit } from '@angular/core';
import {RgiRxDataTableExpansionModel, RgiRxTableExpansionTrigger, TableSchema} from '@rgi/rx/ui';
import {Policy} from '../../models/policy';
import {CONSULTATION_LIST_SCHEMA} from '../../constants/consultation-list-schema';
import {ConsultationListStateManagerService} from '../../state-managers/consultation-list-state-manager.service';
import {ConsultationListState} from '../../state-managers/states/consultation-list-state';
import {CategoriesFilterEnum} from '../../enumerations/categories-filter.enum';
import { SubtypeEnum } from '../../enumerations/subtype.enum';
import { TypeEnum } from '../../enumerations/type.enum';

@Component({
  selector: 'lib-consultation-list',
  templateUrl: './consultation-list.component.html',
  styleUrls: ['./consultation-list.component.css']
})
export class ConsultationListComponent implements OnInit {

  tableData: Array<Policy> = [];
  tableSchema: TableSchema = CONSULTATION_LIST_SCHEMA;
  pageOptions: number[] = [10, 20, 50];
  customExpansionModel: RgiRxDataTableExpansionModel<Policy>;
  readonly CategoriesFilterEnum = CategoriesFilterEnum;
  readonly SubtypeEnum = SubtypeEnum;
  readonly TypeEnum = TypeEnum;
  constructor(
    public stateManager: ConsultationListStateManagerService<ConsultationListState>) {

    this.customExpansionModel = new RgiRxDataTableExpansionModel<Policy>([], {
      multiple: false,
      canExpand: this.canExpand(),
      canContract: this.canContract()
    });
  }
  ngOnInit() {
    this.stateManager.getCurrentState().policies.policyRows.forEach(policy => {
      policy.number = policy.policyNumber ?? policy.proposalNumber
      this.tableData.push(policy);
    });
  }
  copyPolicy(row: Policy) {

  }

  goToDetails(row: Policy) {
    const policiesList =  this.stateManager.getCurrentState().policies;
    const userCode =  this.stateManager.getCurrentState().userCode;
    this.stateManager.goToNextPage(row.number,policiesList,userCode);
  }

  back() {
    this.stateManager.back();
  }

  canExpand = (): RgiRxTableExpansionTrigger => (row: any) => {
    return true;
  }

  // - reduce management
  canContract = (): RgiRxTableExpansionTrigger => (row: any) => {
    return true;
  }

  isCopyPolicyShowed(contract) {
    var showCopy = false;
    // If PC INDIVIDUAL and in this state range
    if(contract.category.code !== CategoriesFilterEnum.LIFE && contract.policyType.code === TypeEnum.INDIVIDUAL
      && (['0', '1', '2', '3', '4', '5', '11', '12', '13', '14', '30', '31']).includes(contract.state.code)) {
      showCopy = true;
    // If PC COLLECTIVE MASTER_POLICY/APPLICATION and in this state range
    } else if (contract.category.code !== CategoriesFilterEnum.LIFE
      && ([SubtypeEnum.MASTER_POLICY, SubtypeEnum.APPLICATION]).includes(contract.policyType.code)&& (['1', '3', '11']).includes(contract.state.code)) {
      showCopy = true;
    }
    return showCopy;
  }

}

import {ActiveRoute} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {of} from 'rxjs';
import {adaptStateFromFormHome} from '../../adapters/group-policy-utils';
import {DemandAndNeeds} from '../../group-policy-models/group-policy-issue-home';
import {GroupPolicyStatelessOpHomeService} from './group-policy-stateless-op-home.service';
import {GroupPolicyStateHome} from '../group-policy-state';


export class GroupPolicyStatemanagerHome extends AbstractStateManager<GroupPolicyStateHome> {

  constructor(activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: GroupPolicyStatelessOpHomeService) {
    super(activeRoute, stateStoreService);
    this.initHome();
  }

  initHome() {
    const statehome = new GroupPolicyStateHome(this.activeRoute.key);
    const initHome$ = this.statelesOps.initHome$(of(statehome));
    this.updateState$(initHome$);
  }

  actionSearchAvailableProducts(data: any) {
    const st = this.getCurrentState();
    adaptStateFromFormHome(st, data);
    this.updateState$(this.statelesOps.searchAvailableProducts$(of(st)));
  }

  actionStartNewFlow(data: any, demandAndNeeds?: DemandAndNeeds) {
    const st = this.getCurrentState();
    adaptStateFromFormHome(st, data);
    this.updateState$(this.statelesOps.startNewFlow$(of(st), demandAndNeeds, this.activeRoute));
  }

  showCompanyField() {
    return this.statelesOps.showCompanyField();
  }
}

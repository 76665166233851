import {Component, ElementRef, OnDestroy, OnInit, Optional} from '@angular/core';
import {RgiRxQAService} from '@rgi/rx';
import {RgiRxPanelComponent} from '../rgi-rx-panel/rgi-rx-panel.component';

let panelFooterCounter = 0;

@Component({
  selector: 'rgi-rx-panel-footer,[rgi-rx-panel-footer]',
  templateUrl: './rgi-rx-panel-footer.component.html',
  host: {
    class: 'rgi-ui-panel-footer'
  },
})
export class RgiRxPanelFooterComponent implements OnInit, OnDestroy {

  constructor(
    private elementRef: ElementRef,
    @Optional() private QA?: RgiRxQAService,
    @Optional() private panel?: RgiRxPanelComponent) {
    panelFooterCounter++;
  }

  ngOnInit() {
    if (this.QA && this.panel) {
      this.QA.render(this.elementRef.nativeElement, {
        id: `${this.panel.id}-footer-${panelFooterCounter}`,
        type: 'panel-footer'
      });
    }
  }

  ngOnDestroy(): void {
    if (this.QA) {
      this.QA.clear(this.elementRef.nativeElement);
    }
  }



}

import { Input, Component, Output, EventEmitter, Optional, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InvestmentAdapterConfig } from '../../../../../interfaces/operation-with-financial-step';
import { KarmaProfileDefinition } from '../../../model/karma-profile-definition';
import { CurrencyCacheService, LpcCurrencyCache } from './../../../../../services/currency-cache.service';

@Component({
  selector: 'lpc-investment-step-adapter',
  templateUrl: './lpc-investment-step-adapter.component.html',
  styleUrls: ['./lpc-investment-step-adapter.component.scss']
})
export class LpcInvestmentStepAdapterComponent {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() public currentStep: boolean; // indica quando siamo sullo step
  @Input() public activeStep: boolean; // indica quando abbiamo passato lo step
  @Input() public isFreeManagement: boolean;
  @Input() public definition: KarmaProfileDefinition[]; // lista delle definitions
  @Input() public totalAmount: number; // amount da investire in questo step
  @Input() public volatility: number; // INPUT/OUTPUT [(volatility)]
  @Input() public viewOnly = false; // attiva la visualizzazione in solo lettura -> step saltato
  @Input() public investedProfiles: KarmaProfileDefinition[];

  @Input() public customFormGroup: UntypedFormGroup;
  get profileFormGroup(): UntypedFormGroup {
    return this.customFormGroup.get(this.config.profileFormGroupName) as UntypedFormGroup;
  }
  get fundFormGroup(): UntypedFormGroup {
    return this.isFunds ? this.customFormGroup.get(this.config.fundFormGroupName) as UntypedFormGroup : null;
  }
  get isFunds(): boolean {
    return !!this.config.fundFormGroupName;
  }

  /** elemento di configurazione per settare le impostazioni dello step
   * @param profileFormGroupName: formControlName del profilo (generalmente investmentProfiles o disinvestmentProfiles)
   * @param fundFormGroupName: deve essere settato SOLO se è da gestire un FONDO (generalmente investmentFunds o disinvestmentFunds)
   * @param investmentMode: forza il metodo di investimento (tipo InvestmentMode)
   * @param prevalIfLengthEqual1: prevalorizza a 100% se è presente un solo fondo/profilo selezionabile
   * @param percentageSumEqual100: la somma delle percentuali deve essere uguale a 100%
   * @param enableVolatility: abilita la gestione della volatilità
   * @param enableSingleElementSelection: abilita la selezione singola di un elemento
   * @param enableOpenListDefault: apre di default gli accordion ed espone tutte le liste da selezionare
   * @param showPercentageInput: mostra il box della percentuale
   * @param showAmountColumnOnViewOnly: mostra la colonna dell'amount in consultazione
   * @param showSliderInput: mostra lo slider di percentuale/amount
   */
  @Input() config: InvestmentAdapterConfig = {
    profileFormGroupName: 'investmentProfiles', // nome del control group
    fundFormGroupName: 'investmentFunds', // nome del control group
    investmentMode: undefined, // forza il metodo di investimento (tipo InvestmentMode)
    prevalIfLengthEqual1: true, // prevalorizza a 100% se è presente un solo fondo/profilo selezionabile
    percentageSumEqual100: true, // la somma delle percentuali deve essere uguale a 100%
    enableVolatility: false, // abilita la gestione della volatilità
    enableSingleElementSelection: false, // abilita la selezione singola di un elemento
    enableOpenListDefault: true, // apre di default gli accordion ed espone tutte le liste da selezionare
    showPercentageInput: true, // mostra il box della percentuale
    showAmountColumnOnViewOnly: true, // mostra la colonna dell'amount in consultazione
    showSliderInput: true, // mostra lo slider di percentuale/amount
  };

  @Output() volatilityEvent = new EventEmitter<any>(); // gestisce gli eventi di calcolo e azzeramento della volatilità

  constructor(
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
    ) {
      this.currencyFormatterOptions.currency = currencyService.currency;
  }

  public getActivePanelList(): string[] {
    const panels = [];
    if (this.isFunds && this.config.enableOpenListDefault) {
      this.selectedProfiles.forEach(el => {
        panels.push('panel' + el.id);
      });
    }
    return panels;
  }

  public getProfileAmount(id: string): number {
    const percentage = this.profileFormGroup.value[id];
    if (!!percentage) {
      return percentage * this.totalAmount;
    }
    return 0;
  }

  public getProfilePercent(id: string): number {
    return this.profileFormGroup.value[id];
  }

  get selectedProfiles(): KarmaProfileDefinition[] {
    const elements: any = this.profileFormGroup.getRawValue() ? this.profileFormGroup.getRawValue() : {};
    const elementKeys = Object.keys(elements).filter(key => !!elements[key]);
    return this.definition.filter(pr => elementKeys.includes(pr.id));
  }

  public changeValueHandling(event) {
    if (this.config.enableVolatility && !!this.volatility) {
      this.volatility = 0;
    } else {
      this.volatilityEvent.emit({action: 'reset'});
    }
  }

}

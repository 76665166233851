import {Inject, Injectable} from '@angular/core';
import {RestApiConf} from "../../../conf/rest-api-conf";
import {RgiRxHttpClientWrapper} from "@rgi/rx/http";
import {Documents} from "../resources/model/documents";
import {GenericEntity} from "../resources/model/generic-entity";
import {DocumentFile} from "../resources/model/document-file";
import {HttpParams} from "@angular/common/http";
import {Metadata} from "../resources/model/metadata";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  protected hostPath: string;
  protected API_PREFIX_DOCUMENTS = 'documents';
  private documentsChannel: Subject<Documents> = new Subject<Documents>();

  constructor(
    @Inject('environment') protected apiConf: RestApiConf,
    public httpClient: RgiRxHttpClientWrapper
  ) {
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path + '/v2';
  }


  getDocuments(contractId: string): Observable<Documents> {
    return this.httpClient.get<Documents>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/documents`);
  }

  getDocumentType(contractId: string, showAll = false): Observable<GenericEntity[]> {
    let requestParams: HttpParams = new HttpParams();
    if(!showAll) {
      requestParams = requestParams.set('mandatory', 'false');
      requestParams = requestParams.set('included', 'false');
    }
    return this.httpClient.get<GenericEntity[]>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/documentTypes`, { params: requestParams });
  }

  addDocument(contractId: string, documentType: GenericEntity): Observable<Documents> {
    return this.httpClient.post<Documents>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/document`, documentType);
  }

  uploadFile(contractId: string, documentId: string, fileDoc: DocumentFile): Observable<DocumentFile> {
    return this.httpClient.post<DocumentFile>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/document/${documentId}/file/body`, fileDoc);
  }

  validateFile(contractId: string, documentId: string, file: DocumentFile): Observable<DocumentFile> {
    return this.httpClient.put<DocumentFile>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/document/${documentId}/validate`, file);
  }

  previewFile(fileId: string, input: boolean): Observable<any> {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('input', input ? 'true' : 'false');
    return this.httpClient.get<any>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/document/file/${fileId}/body`, { params: requestParams });
  }

  updateFileMetadata(contractId: string, documentId: string, fileId: string, listMetadata: Metadata[]): Observable<Metadata[]> {
    return this.httpClient.put<Metadata[]>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/document/${documentId}/file/${fileId}/metadata`, listMetadata);
  }

  deleteFile(contractId: string, documentId: string, fileId: string): Observable<any> {
    return this.httpClient.delete(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/document/${documentId}/file/${fileId}`);
  }

  deleteDocument(contractId: string, documentId: string): Observable<Documents> {
    return this.httpClient.delete<Documents>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${contractId}/document/${documentId}`);
  }

  public getDocumentsObservable(): Observable<Documents> {
    return this.documentsChannel.asObservable();
  }

  public setDocumentsSignal(newDocuments: Documents): void {
    this.documentsChannel.next(newDocuments);
  }

}

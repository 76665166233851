import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicKarmaFundButtonComponent} from './component/lic-karma-fund-button/lic-karma-fund-button.component';
import {ReactiveFormsModule} from '@angular/forms';
import {LicKarmaFundButtonsComponent} from './component/lic-karma-fund-buttons/lic-karma-fund-buttons.component';
import {LicKarmaFundStepComponent} from './component/lic-karma-fund-step/lic-karma-fund-step.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {LicKarmaFundMessageComponent} from './component/lic-karma-fund-message/lic-karma-fund-message.component';
import {LicFundsTableComponent} from './component/lic-funds-table/lic-funds-table.component';
import {HttpClientModule} from '@angular/common/http';
import {LicVolatilityButtonComponent} from './component/lic-volatility-button/lic-volatility-button.component';
import {LicKarmaFundTableComponent} from './component/lic-karma-fund-table/lic-karma-fund-table.component';
import {CustomNumberModule} from '../custom-number/custom-number.module';
import {RgiCurrencyModule} from '../rgi-currency/rgi-currency.module';
import {LicProfilesComponent} from './component/lic-profiles/lic-profiles.component';
import {LicFundsComponent} from './component/lic-funds/lic-funds.component';
import {LicDisinvestmentButtonComponent} from './component/lic-disinvestment-button/lic-disinvestment-button.component';
import {LicCheckboxAccordionModule} from '../invest-standalone-session/component/lic-checkbox-accordion/lic-checkbox-accordion.module';
import { LicKarmaFundElementsComponent } from './component/lic-karma-fund-elements/lic-karma-fund-elements.component';
import { LicKarmaFundElementComponent } from './component/lic-karma-fund-element/lic-karma-fund-element.component';
import { LicKarmaFundSummaryComponent } from './component/lic-karma-fund-summary/lic-karma-fund-summary.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LicRequiredModule } from '../utils/lic-required/lic-required.module';
import { RgiRxExpansionModule, RgiRxFormElementsModule } from '@rgi/rx/ui';


@NgModule({
  declarations: [
    LicKarmaFundButtonComponent,
    LicKarmaFundButtonsComponent,
    LicKarmaFundElementComponent,
    LicKarmaFundElementsComponent,
    LicKarmaFundStepComponent,
    LicKarmaFundMessageComponent,
    LicFundsTableComponent,
    LicVolatilityButtonComponent,
    LicKarmaFundTableComponent,
    LicProfilesComponent,
    LicFundsComponent,
    LicDisinvestmentButtonComponent,
    LicKarmaFundSummaryComponent
  ],
    exports: [
        LicKarmaFundStepComponent,
        LicFundsTableComponent,
        LicKarmaFundButtonsComponent,
        LicKarmaFundElementComponent,
        LicKarmaFundElementsComponent,
        LicVolatilityButtonComponent,
        LicKarmaFundTableComponent,
        LicKarmaFundMessageComponent,
        LicProfilesComponent,
        LicFundsComponent,
        LicDisinvestmentButtonComponent,
        LicKarmaFundSummaryComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        RgiCurrencyModule,
        CustomNumberModule,
        HttpClientModule,
        LicCheckboxAccordionModule,
        NgxPaginationModule,
        NgSelectModule,
        RgiCountryLayerModule,
        RgiRxI18nModule,
        LicRequiredModule,
        RgiRxFormElementsModule,
        RgiRxExpansionModule
    ],
  providers: []
})
export class LicKarmaFundModule {
}

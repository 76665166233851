import { ActiveRoute, Routes } from '@rgi/rx/router';
import { StateStoreService } from '@rgi/rx/state';
import {AdapterprintHomeComponent} from "./adapterprint-home/adapterprint-home.component";
import {AdapterprintResultComponent} from "./adapterprint-result/adapterprint-result.component";

export const CARD_ADAPTERPRINT_ROUTES: Routes = [
  {
    route: 'card.adapterprint.home',
    component: AdapterprintHomeComponent,
    providers: [
      ]
  },
  {
    route: 'card.adapterprint.result',
    component: AdapterprintResultComponent,
    providers: [
      ]
  }

];

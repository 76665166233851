import {AutomaticRenewal} from '../../domain-models/automatic-renewal';

export class SaveProposalResponse {
  key?: any;
  proposal: PcProposal;
  documentation?: any;
  messages?: any;
  additionalLabels: any[];
}

export class PcProposal {
  product: any;
  company: any;
  managementNode: any;
  currency: any;
  agreement?: any;
  premium: any;
  contractNumber: any;
  assets: any;
  issuingDate: string;
  effectDate: string;
  automaticRenewal: AutomaticRenewal;
  policyExpiration: string;
  proposalExpiration: string;
  renewal?: any;
  additionalLabels: any[];
  coverageExpiration: any;
  fees: any;
  instalmentDates: Array<string>;
}

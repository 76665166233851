import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {ENTER, ESCAPE} from '@angular/cdk/keycodes';

export interface RgiRxDatatableInputSortChange {
  startIndex: number;
  targetIndex: number;
}

/**
 * A directive to support the sortInput for the datatable
 * @internal
 */
@Directive({
  selector: 'input[rgiRxDatatableInputSort]',
  exportAs: 'rgiRxDatatableInputSort',
  host: {
    class: 'rgi-rx-datatable-sort-input'
  }
})
export class RgiRxDatatableInputSortDirective {

  @Output() onChanged = new EventEmitter<RgiRxDatatableInputSortChange>();

  private _value: string;
  private _min: string;
  private _max: string;


  constructor() {
  }

  @HostListener('keydown', ['$event'])
  _handleKeyBoard(event: KeyboardEvent) {
    const target = (event.target as HTMLInputElement);
    switch (event.keyCode) {
      case ENTER:
        if (!target.value) {
          target.value = this._value;
          return;
        }
        if (this._value === target.value) {
          return;
        }
        this.emitChange(target.value);
        break;
      case ESCAPE: {
        target.value = this._value;
        event.preventDefault();
      }
    }
  }

  private emitChange(current: string) {
    const startIndex = parseInt(this._value) - 1;
    let targetIndex = parseInt(current) - 1;
    const max = parseInt(this.max);
    if (targetIndex > max) {
      targetIndex = max - 1;
    } else if (targetIndex < 0) {
      targetIndex = 0;
    }
    this.onChanged.next({
      startIndex,
      targetIndex
    });
    this._value = current;
  }

  @HostListener('focusout', ['$event'])
  _handleFocusOut(event: Event) {
    // using timeout to prevent triggering change detection Angular 11+ after keydown listener
    setTimeout(() => {
      const target = (event.target as HTMLInputElement);
      if (!target.value) {
        target.value = this._value;
        return;
      }
      if (this._value === target.value) {
        return;
      }
      this.emitChange(target.value);
    });
  }


  @HostBinding('value') @Input() get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }


  @Input() @HostBinding('min') get min(): string {
    return this._min;
  }

  set min(value: string) {
    this._min = value;
  }

  @Input() @HostBinding('max') get max(): string {
    return this._max;
  }

  set max(value: string) {
    this._max = value;
  }
}

import {PortalNgCoreI18nKeys} from './i18n.keys';

export const EN: PortalNgCoreI18nKeys = {
  PORTAL_NG_CORE: {
    ERROR: 'Error',
    INVALID_VALUES: 'Fields marked in red are required or contain incorrect values',
    NO_RESULTS: 'No results.',
    WRONG_OBJECT_TYPE: 'initialization error: invalid object type',
    WRONG_TYPE: 'initialization error: invalid type'
  }
};

import {Component, OnInit, ViewChild} from '@angular/core';
import {RgiPncPostsalesStepComponent} from '../../api/rgi-pnc-postsales-step.component';
import {FormService} from '../../services/form.service';
import {PncPsalesSupplementaryTechAccState} from '../../resources/states/supplementary-tech-acc-state';
import {
  PncPsalesSupplementaryTechAccStateManager
} from '../../state-managers/technical-accounting/supplementary-tech-acc-state-manager.service';
import {take, tap} from 'rxjs/operators';
import {RgiRxDatatableComponent} from '@rgi/rx/ui';
import {PNC_PSALES_ACTIONS} from '../../resources/constants/actions';

@Component({
  selector: 'rgi-pnc-psales-pnc-psales-supplementary-tech-acc-transaction',
  templateUrl: './supplementary-tech-acc-transaction.component.html'
})
export class PncPsalesSupplementaryTechAccTransactionComponent extends RgiPncPostsalesStepComponent<PncPsalesSupplementaryTechAccState> implements OnInit {

  @ViewChild('insuredDT', {static: true}) insDt: RgiRxDatatableComponent;
  @ViewChild('notInsuredDT', {static: true}) notInsDt: RgiRxDatatableComponent;

  constructor(
    public stateMgr: PncPsalesSupplementaryTechAccStateManager,
    public formService: FormService
  ) {
    super(stateMgr, formService);
  }

  protected initStep() {
    this.stateMgr.updateInsuredRisksTableData();
    this.stateMgr.updateNotInsuredRisksTableData();
    this.stateMgr.insuredRisksTableData.pipe(take(1), tap(next => {
      if (this.insDt) {
        this.insDt.dataSource.update(next);
      }
    })).subscribe();
    this.stateMgr.notInsuredRisksTableData.pipe(take(1), tap(next => {
      if (this.notInsDt) {
        this.notInsDt.dataSource.update(next);
      }
    })).subscribe();
  }

  onQuotationBarBtnAction($event: PNC_PSALES_ACTIONS | string) {
    this.stateMgr.onAction($event);
  }
}

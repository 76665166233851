import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  StaticProvider,
  ViewChild
} from '@angular/core';
import {AnagStateManagerPartyEditor, AnagStatePartyEditor} from '../../../anag-states/party-editor/anag-state-manager-party-editor';
import {AnagStorageService} from '../../../anag-resources/anag-storage.service';
import {AnagApiParty} from '../../../anag-model/anag-domain/anag-api-party';
import {AnagPartyPayment} from '../../../anag-model/anag-domain/anag-party-payment';
import {PaymentAnag, PaymentField} from '../../../anag-model/anag-domain/anag-payments';
import {PAYMENT_MEANS_PARTY, PAYMENT_TYPES} from '../../../anag-constants/anag-constants';
import {AnagPaymentsComponent} from '../anag-payments/anag-payments.component';
import {AnagStatelessService} from '../../../anag-resources/anag-stateless.service';
import {ModalService} from '@rgi/rx/ui';
import {DatePipe} from '@angular/common';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 'rgi-anag-payment-section',
  templateUrl: './payment-section.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
@Injectable({
  providedIn: 'root',
})
export class AnagPaymentSectionComponent implements OnInit {

  @Input()
  inputParty: AnagApiParty;

  @Input()
  private stateMgrProvider: StaticProvider[];

  modalClose: EventEmitter<any> = new EventEmitter();
  partyPayments: AnagPartyPayment [] = [];
  paymentFormMap = new Map<string, PaymentAnag>();
  paymentsState = this.stateMgr.getCurrentState();
  readonly PAYMENT_TYPES = PAYMENT_TYPES;

  DATE_FORMAT: string;


  constructor(public stateMgr: AnagStateManagerPartyEditor,
              public anagStorage: AnagStorageService,
              public statelessService: AnagStatelessService,
              public modalService: ModalService,
              public datePipe: DatePipe,
              public translateService: RgiRxTranslationService) {
    this.DATE_FORMAT = this.statelessService.getLocaleDateFormat();
  }

  ngOnInit()  {
    if (this.inputParty) {
      if (!!this.inputParty.payments && !!this.inputParty.payments.length) {
        this.partyPayments = this.statelessService.deepClone(this.inputParty.payments);
      } else {
        this.partyPayments = [];
      }
    }
    this.createPaymentFieldsMap();
  }

  filteredSelect(meansOfPayment: PaymentAnag[]) {
    return meansOfPayment.filter(meanPay => { return meanPay.paymentFields.length > 0 ||
      this.partyPayments.find(partyPay =>
        partyPay.paymentTypeID === meanPay.meanOfPayment.identification
        && partyPay.paymentType === meanPay.paymentType.codice) === undefined;
    });
  }

  private createPaymentFieldsMap() {
    const typesOfPayment = this.paymentsState.meansOfPayment.meansOfPayment;
    let fieldsToDisplay: PaymentField[];
    if (!!typesOfPayment && !!typesOfPayment.credMeansOfPayment && !!typesOfPayment.debMeansOfPayment) {
      typesOfPayment.credMeansOfPayment.concat(typesOfPayment.debMeansOfPayment).forEach((payment) => {
        if (!!payment.paymentFields && !!payment.paymentFields.length) {
          fieldsToDisplay =  payment.paymentFields.filter(field => {
            return field.type !== 'N' || (!!payment.creditCards && !!payment.creditCards.length);
          });
          payment.paymentFields = fieldsToDisplay;
          this.paymentFormMap.set(
            `${payment.paymentType.codice}_${payment.meanOfPayment.identification}`, payment);
        }
      });
    }
  }

  getPayments(partyPayment: AnagPartyPayment): PaymentAnag {
    return this.paymentFormMap.get(`${partyPayment.paymentType}_${partyPayment.paymentTypeID}`);
  }

  getFieldValueFromParty(payment: AnagPartyPayment, field: PaymentField): string {
    let value = null;
    const paymentFieldKey = PAYMENT_MEANS_PARTY.get(field.name);
    if (paymentFieldKey) {
      value = payment[paymentFieldKey];
      if (!!value ) {
        if (!!value.codice) {
          value = value.codice;
        }
        if ('activationDateSDD' === paymentFieldKey || 'cancelDateSDD' === paymentFieldKey) {
          value = new Date(value);
        }
      }
    }
    return value;
  }

  getFieldValueFromPartyForSection(payment: AnagPartyPayment, field: PaymentField) {
    const fieldValue = this.getFieldValueFromParty(payment, field);
    let value;
    if (fieldValue) {
      switch (PAYMENT_MEANS_PARTY.get(field.name)) {
        case 'activationDateSDD':
        case 'cancelDateSDD':
          value = this.datePipe.transform(new Date(fieldValue), this.DATE_FORMAT);
          break;
        case 'cartaDiCreditoScadenza':
          if(fieldValue.includes('-')) {
            const parts = fieldValue.split('-');
            const year = parts[0];
            const month = parts[1];
            value = `${month}/${year}`;
          } else {
            value = fieldValue;
          }
          break;
        case 'cartaDiCreditoTipo':
          value = payment[PAYMENT_MEANS_PARTY.get(field.name)].descrizione;
          // this.translateService.translate(PAYMENT_TYPES.get(fieldValue)).subscribe(val => value = val);
          break;
        default:
          value = fieldValue;
      }
    }

    return value;
  }

  editPayments() {
    const paymentsModal = this.modalService.openComponent(
      AnagPaymentsComponent, this.inputParty, [this.stateMgrProvider]
    );
    paymentsModal.modal.enableClickBackground = false;
  }
}


import { ActiveRoute, Routes } from "@rgi/rx/router";
import { StateStoreService } from "@rgi/rx/state";
import { PaymentsDashboardIntegrationService } from "./configurations/payments-dashboard-integration.service";
import { ROUTES_PAYMENTS_SEARCHBOARD, ROUTES_PAYMENTS_VALIDATION_CONSOLIDATION_TABLE, ROUTES_FORFAIT_TABLE } from "./constants/routes.constants";
import { PaymentsSearchboardStateManager } from "./state-managers/payments-searchboard.state.manager";
import { PaymentsSearchboardService } from "./services/payments-searchboard.service";
import { ValidationConsolidationStateManager } from "./state-managers/validation-consolidation.state.manager";
import { ValidationConsolidationService } from "./services/validation-consolidation.service";
import { ForfaitService } from "./services/forfait.service";
import { PaymentsSearchboardComponent } from "./components/payments-searchboard/payments-searchboard.component";
import { TableValidationConsolidationComponent } from "./components/table-validation-consolidation/table-validation-consolidation.component";
import { TableForfaitComponent } from "./components/table-forfait/table-forfait.component";
import { ForfaitStateManager } from "./state-managers/forfait.state.manager";

export const PAYMENTS_DASHBOARD_ROUTES: Routes = [
  {
    route: ROUTES_PAYMENTS_SEARCHBOARD,
    component: PaymentsSearchboardComponent,
    providers: [{
      provide: PaymentsSearchboardStateManager,
      useClass: PaymentsSearchboardStateManager,
      deps: [ActiveRoute, StateStoreService, PaymentsSearchboardService, PaymentsDashboardIntegrationService]
    }]
  },
  {
    route: ROUTES_PAYMENTS_VALIDATION_CONSOLIDATION_TABLE,
    component: TableValidationConsolidationComponent,
    providers: [{
      provide: ValidationConsolidationStateManager,
      useClass: ValidationConsolidationStateManager,
      deps: [ActiveRoute, StateStoreService, ValidationConsolidationService, PaymentsDashboardIntegrationService]
    }]
  },
  {
    route: ROUTES_FORFAIT_TABLE,
    component: TableForfaitComponent,
    providers: [{
      provide: ForfaitStateManager,
      useClass: ForfaitStateManager,
      deps: [ActiveRoute, StateStoreService, ForfaitService, PaymentsDashboardIntegrationService]
    }]
  },
];
<div class="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <span translate>{{ title }}</span>
                    <span class="glyphicon glyphicon-remove pull-right cursorPointer" (click)="close()"></span>
                </h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="upFileForm">
                    <div class="form-group row" *ngFor="let metadata of file.metadataList">
                        <div *ngIf="metadata?.visible">
                            <label>{{ metadata?.description }}</label>
                            <pnc-required required="true" *ngIf="metadata?.mandatory"></pnc-required>
                            <input  *ngIf="metadata?.type.code=='S'" type="text" [formControlName]="metadata?.code" class="form-control mic-input">
                            <input  *ngIf="metadata?.type.code=='N'" type="number" [formControlName]="metadata?.code" class="form-control mic-input">
                            <input  *ngIf="metadata?.type.code=='D'" type="date" [formControlName]="metadata?.code" class="form-control mic-input">
                            <select *ngIf="metadata?.type.code=='L'" type="text" [formControlName]="metadata?.code" class="select-nativa">
                                <option [value]="-1"></option>
                                <option *ngFor="let valore of metadata?.listValue" [value]="valore.toUpperCase()">{{valore}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="bUploadFile">
                        <!--<button type="button" class="btn pnc_element_blue col-xs-3" (click)="searchAttachment()" translate>CHOOSE FILE</button>
                        <span class="col-xs-1"></span>-->
                        <label>{{'PORTAL_NG_COMMON.FILE' | translate}} ({{this.fileTypes}} - {{(this.maxKBFileSize/1024)}} MB)</label>
                        <input type="file" formControlName="attachmentFile" (change)="fileProgress($event.target.files)" required="true">
                    </div>
                </form>
                <div id="error-container">
                    <pnc-form-message *ngIf="(upFileForm?.invalid && submitted && validationMessages?.length > 0)" 
                      type="error" detail="validationError" [messages]="validationMessages"
                      data-qa="action-error-messages" objectType="complex"></pnc-form-message>
                  </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button class="btn btn-warning" [disabled]="submitted && !upFileForm?.valid" (click)="confirmUpFile()">
                            <span *ngIf="bUploadFile" translate>PORTAL_NG_COMMON.LOAD</span>
                            <span *ngIf="!bUploadFile" translate>PORTAL_NG_COMMON.EDIT</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { GeneralClaimsData } from './dsc-general-detail.config';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';

@Component({
  selector: 'claims-dsc-general-detail',
  templateUrl: './dsc-general-detail.component.html',
  styleUrls: ['./dsc-general-detail.component.scss'],
})
export class DscGeneralDetailComponent implements OnInit {

    @Input() inputObject: GeneralClaimsData;
    @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;

    isAccordion: boolean = false;
    expanded: boolean = true;

  constructor() {}

    ngOnInit() {
      console.log(this.inputObject);
      console.log(this.inputObject);
      this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
      // this.initGeneralDetail();
    }

   /*  initGeneralDetail(){
      if(!this.inputObject){
        this.inputObject = INIT_INFO_OBJ
      }
      this.inputObject.titleLabel = this.inputObject.titleLabel !== '' ? this.inputObject.titleLabel : INIT_INFO_OBJ.titleLabel;
      this.inputObject.subTitleLabel = this.inputObject.subTitleLabel !== '' ?
                                      this.inputObject.subTitleLabel : INIT_INFO_OBJ.subTitleLabel;
      this.inputObject.company = this.inputObject.company !== '' ? this.inputObject.company : INIT_INFO_OBJ.company;
    } */

    setAccordion() {
      this.expanded = !this.expanded;
    }

  }

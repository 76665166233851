<div *ngIf="stateManager.getState$() | async as state">
<form [formGroup]="commissionsForm">
    <rgi-rx-datatable rgi-rx-qa="my-table" qa-ctx="my-app-context"
                      [data]="tableData" [schema]="tableSchema"
                      [pageOptions]="pageOptions" [expansionModel]="customExpansionModel"
                      [disablePaginator]="paginatorDisabled" [disableSearch]="searchDisabled"
                      [expansionRow]="rowDetail">
        <!-- CUSTOM SELECTION -->
        <ng-template rgiRxDataTableCell="checkbox" let-ctx>
            <div style="display: flex" *ngIf="overview.functionality.id==='3'">
                <rgi-rx-form-field>
                    <input type="checkbox" formControlName="{{ctx.transform()}}" rgiRxInput>
                </rgi-rx-form-field>
            </div>
        </ng-template>

    </rgi-rx-datatable>
</form>
<br>
<form *ngIf="dashBoardDetailForm" [formGroup]="dashBoardDetailForm" class="rgi-ui-form-group">
    <div class="col-sm-3 col-xs-3">
        <rgi-rx-form-field>
            <label rgiRxLabel translate>ICD.SELECTED_OPERATION</label>
            <select rgiRxNativeSelect formControlName="operationType"
                    data-qa="operationType">
                <option value=""></option>
                <option *ngFor="let operationType of notManuallyOperation"
                        [ngValue]="operationType.id">{{operationType.description}}
                </option>
            </select>
        </rgi-rx-form-field>
    </div>
</form>
<br>
<dashboard-detail-table-all-risk *ngIf="tableIsVisible" [totalAllRisk]="totalAllRisk" [overview]="overview" [operation]="operation"
                                 [dashBoardDetailForm]="dashBoardDetailForm" (resetTableAllRiskValue)="resetTableAllRiskValue()"
                                 (applyRisks)="applyRisks($event, null)" (actionSelected)="updateAction($event)"
                                 (noteSelected)="updateNotes($event)" [commissionStatus]="commissionStatus">
</dashboard-detail-table-all-risk>

<!-- ROW DETAIL -->
<ng-template #rowDetail let-resultRow>
    <div class="exp-row">
        <div class="row row-detail-action-note">
            <div>{{'ICD.ACTION' | translate}} : <b>{{resultRow.action?.description}}</b></div>
            <div class="row-detail-note">{{'ICD.NOTES' | translate}} : <b>{{resultRow.note?.description}}</b></div>
        </div>
        <br>
        <!-- RISK DETAIL TABLE -->
        <dashboard-detail-table-risk *ngIf="resultRow.risksDetail" [riskDetailForm]="riskDetailForm"
                                     [idDashBoard]="resultRow.idDashBoard"
                                     [risks]="resultRow.risksDetail" [editMode]="overview.functionality.id==='3'">
        </dashboard-detail-table-risk>
        <!-- PARAM DETAIL -->
        <dashboard-detail-param [overview]="overview"
                                [text]="modalConfirmText"
                                [commissionStatus]="resultRow.status.id"
                                (blockExpansion)="blockExpand()"
                                (actionSelected)="updateAction($event)"
                                (noteSelected)="updateNotes($event)"
                                (apply)="applyRisks($event, resultRow.idDashBoard)"
                                (clear)="resetTableRiskValue()"
                                (operationSelected)="setOperation($event, resultRow.idDashBoard)" [operation]="operation">
        </dashboard-detail-param>
        <!-- TODO : Replace with style -->
        <br>
    </div>
</ng-template>
</div>

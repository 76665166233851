export class AddressData {

    constructor(
        public countryCode: string,
        public zipCode: string,
        public city: string,
        public address: string,
        public houseNumber: string,
        public adminLevel1: string,
        public adminLevel2: string,
        public adminLevel3: string,
        public toponym: string,
        public province: string
    ) { }
}

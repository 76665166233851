import { DatePipe } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { InfoType, ReservationRiskStatus } from '../../../models/enums/vita.enum';
import { ReservationDetail, ReservationFactors, ReservationQuestionnaires } from '../../../models/life-detail.model';
import { LpcQuestionnaireContainerComponent } from '../../../modules/lpc-questionnaire-container/lpc-questionnaire-container.component';
import { PlcQuestService } from '../../../services/plc-quest.service';
import { LpcDetailUtils } from '../../../utils/lpc-detail-utils';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Component({
  selector: 'lpc-life-booking-detail',
  templateUrl: './life-booking-detail.component.html',
  styleUrls: ['./life-booking-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LifeBookingDetailComponent implements OnInit, AfterViewChecked {

  @Input() id: string;
  @Input() data: { bookingDetailData: ReservationDetail};
  get bookingData(): ReservationDetail {
    return this.data.bookingDetailData;
  }
  @Output() eventPropagation = new EventEmitter<any>();

  public initializeViewDetailReservation = false; // section viewer

  get getSummaryDateDescription(): string {
    if (this.bookingData.cancellationDate) {
      return this.translate.getImmediate('lpc_booking_cancellationDate');
    } else if (this.bookingData.effectDate) {
      return this.translate.getImmediate('lpc_booking_effectiveDate');
    } else {
      return EMPTY_STR;
    }
  }

  get getSummaryDateValue(): string {
    if (this.bookingData.cancellationDate) {
      return this.datePipe.transform(this.bookingData.cancellationDate, 'dd/MM/yyyy');
    } else if (this.bookingData.effectDate) {
      return this.datePipe.transform(this.bookingData.effectDate, 'dd/MM/yyyy');
    } else {
      return EMPTY_STR;
    }
  }

  get showPolicyFactors() {
    return this.bookingData.policyFactors && this.bookingData.policyFactors.length > 0;
  }

  get showAssetFactors() {
    return this.bookingData.assetFactors && this.bookingData.assetFactors.length > 0;
  }

  get surveys(): ReservationQuestionnaires[] {
    const asset = this.bookingData.assetQuestionnaires ? this.bookingData.assetQuestionnaires : [];
    const policy = this.bookingData.policyQuestionnaires ? this.bookingData.policyQuestionnaires : [];
    return asset.concat(policy);
  }

  constructor(
    protected datePipe: DatePipe,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    protected modalService: NgbModal,
    protected questService: PlcQuestService,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    public translate: TranslationWrapperService
  ) {}

  ngOnInit(): void {
    if (!!this.bookingData) {
      this.initializeViewDetailReservation = true;
    }
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  formatFactorValue(value: string, type: InfoType) {
    if (type === InfoType.date) {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    }
    if (type === InfoType.currency || type === InfoType.number) {
      return this.rgiNumberFormatter.transform(value, type);
    }
    return !!value ? value : !!type ? '-' : EMPTY_STR;
  }

  getFactorFractionized(factors: ReservationFactors[]): Map<number, ReservationFactors[]> {
    return LpcDetailUtils.generateDynamicTable(factors) as Map<number, ReservationFactors[]>;
  }

  getFactorRows(factors: ReservationFactors[]) {
    return LpcDetailUtils.composeArray(Math.ceil(factors.length / 2));
  }

  composeArray(nRow: number): number[] {
    return Array(nRow).fill(0).map((x, i) => i);
  }

  chunkSize(array: number[], size: number, objectsArray): Map<number, object[]> {
    const indexMap = new Map<number, object[]>();
    let counter = 0;
    [].concat.apply([],
      array.map((i) => {
        let objectsForEachRow = [];
        if (i % size === 0) {
          array.slice(i, i + size).map((element) => objectsForEachRow.push(objectsArray[element]));
          objectsForEachRow = this.addMissingValuesForEachRow(objectsForEachRow, size);
          indexMap.set(counter, objectsForEachRow);
          counter++;
        }
      })
    );
    return indexMap;
  }

  private addMissingValuesForEachRow(objectsForEachRow: any[], size: number) {
    if (objectsForEachRow.length < size) {
      const missingValues = size - objectsForEachRow.length;
      const arrayWithMissingValues = new Array(missingValues).fill({description: EMPTY_STR, value: EMPTY_STR});
      return objectsForEachRow.concat(arrayWithMissingValues);
    }
    return objectsForEachRow;
  }

  isUnitRemoved(status) {
    return status === ReservationRiskStatus.DELETED;
  }
  /* -------------------- RISCHI -------------------- */

  public back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  public openQuest(id: string) {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    const modalRef = this.modalService.open(LpcQuestionnaireContainerComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    this.questService.getSurveyVersion(id).subscribe(el => {
      modalRef.componentInstance.questionnaireFlat = el.lastVersion.questionnaire;
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    });
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcQuotationControlComponent} from './lpc-quotation-control.component';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
    declarations: [LpcQuotationControlComponent],
    imports: [
        CommonModule,
        RgiRxI18nModule,
        RgiCountryLayerModule,
        NgbModule
    ],
    exports: [LpcQuotationControlComponent]
})
export class LpcQuotationControlModule {
}

<form [formGroup]="factorControlForm">
  <div [ngSwitch]="factor?.type">
    <!-- FACTOR LIST ------------------------------>
    <div *ngSwitchCase="LIST_TYPE">
      <rgi-rx-form-field>
        <label rgiRxLabel>{{ factor?.description }}
        </label>
        <select rgiRxNativeSelect (change)="onFactorValueChange()" [id]="factorCode" [formControlName]="factorCode" attr.data-qa="{{factorCode}}">
          <option [value]="-1"></option>
          <option *ngFor="let value of factor?.values; index as i;" [value]="value?.code"
                  [selected]="isSelected(value?.code, factor)">
            {{ value?.description }}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <!-- FACTOR BOOL ------------------------------>
    <div *ngSwitchCase="BOOL_TYPE">
      <rgi-rx-form-field class="bool-type">
        <label rgiRxLabel>{{ factor?.description }} </label>
        <rgi-rx-button-toggle (ngModelChange)="onFactorValueChange()" [id]="factorCode"
                              [formControlName]="factorCode" attr.data-qa="{{factorCode}}">
          <button rgi-rx-button rgiRxValueOption *ngFor="let value of factor?.values; index as i;"
                  [value]="value?.code">{{ value?.description }}</button>
        </rgi-rx-button-toggle>
      </rgi-rx-form-field>
    </div>
    <!-- FACTOR NUMBER ------------------------------>
    <div *ngSwitchCase="NUMBER_TYPE">
      <rgi-rx-form-field *ngIf="factor?.values?.length > 0; else asInput">
        <label rgiRxLabel>{{ factor?.description }}
        </label>
        <select rgiRxNativeSelect (change)="onFactorValueChange()" [id]="factorCode" [formControlName]="factorCode" attr.data-qa="{{factorCode}}">
        <option [value]="-1"></option>
        <option *ngFor="let value of factor?.values; index as i;" [value]="value?.description">
          {{ value?.description }}
        </option>
      </select>
      </rgi-rx-form-field>
      <ng-template #asInput>
        <rgi-rx-form-field *ngIf="showFormattedInput(factor)">
          <label rgiRxLabel>{{ factor?.description }}
          </label>

          <input rgiRxInput (blur)="formattedFactorValue(factor);onFactorValueChange()" [id]="factorCode" type="string"
                 [formControlName]="factorCode" [maxlength]="maxLen(factor)" [pattern]="pattern(factor)" attr.data-qa="{{factorCode}}">
        </rgi-rx-form-field>

        <rgi-rx-form-field *ngIf="showUnFormattedInput(factor)">
          <label rgiRxLabel>{{ factor?.description }}
          </label>
          <input rgiRxInput (blur)="onFactorValueChange()" [id]="factorCode" type="number" attr.data-qa="{{factorCode}}"
                 [formControlName]="factorCode">
        </rgi-rx-form-field>
      </ng-template>

    </div>
    <!-- FACTOR DATE ------------------------------>
    <div *ngSwitchCase="DATE_TYPE">
        <rgi-rx-form-field class="rgi-re-display-contents">
          <div class="rgi-re-display-block">
            <label rgiRxLabel>{{ factor?.description }}</label>
            <div class="rgi-re-display-flex">
              <input [rgiRxDateTimeInput]="variableDatePicker"
                     (dateTimeChange)="onFactorValueChange()"
                     style="flex: 1" [id]="factorCode"
                     [formControlName]="factorCode"
                     selectMode="single"
                     rangeSeparator="/"
                     data-qa="variableDate"
                     placeholder="gg/mm/yyyy" #inputToTriggerVariableDate="rgiRxDateTimeInput">
              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerVariableDate"></button>
            </div>
          </div>
          <rgi-rx-date-time #variableDatePicker
                            pickerType="calendar"
                            [hour12Timer]="true"
          ></rgi-rx-date-time>
        </rgi-rx-form-field>
    </div>
    <!-- FACTOR TEXT ------------------------------>
    <div *ngSwitchCase="TEXT_TYPE">
      <rgi-rx-form-field>
        <label rgiRxLabel>{{ factor?.description }}
        </label>
        <input rgiRxInput (blur)="onFactorValueChange()" [maxlength]="maxLen(factor)" [id]="factorCode"
               type="string" [formControlName]="factorCode" attr.data-qa="{{factorCode}}">
      </rgi-rx-form-field>
    </div>
  </div>
</form>

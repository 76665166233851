import { Component, OnInit, Input, Output, EventEmitter, Inject, AfterViewChecked } from '@angular/core';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { FinancialService } from '../../../services/financial.service';
import { PlcDateUtils } from '../../../utils/plc-date-utils';


@Component({
  selector: 'lpc-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.css']
})
export class ProfileDetailComponent implements OnInit, AfterViewChecked {

  @Input() data: any;
  @Input() id: any;
  @Output() eventPropagation = new EventEmitter<any>();
  public onInitEnd = false;
  public profile: any;


  constructor(
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    private financialService: FinancialService) { }

  ngOnInit() {
    if (!!this.data && !!this.data.idCrypt) {
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      this.financialService.getProfileDetail(this.data.idCrypt).subscribe(profile => {
        this.profile = profile;
        this.onInitEnd = true;
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      });
    }
  }

  back() {
    const info = this.data.info;
    this.eventPropagation.emit({eventName: JS_EVENT.BACK_TO_FINANCIAL_LIST, info});
  }

  convert(date: string) {
    if (!!date) {
      if (date === '2999-12-31') {
        return EMPTY_STR;
      }
      return PlcDateUtils.isoToFormattedDate(date);
    }
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  openSession(version: any) {
    const data = {
      version,
      profile: this.profile
    };
    this.eventPropagation.emit({eventName: JS_EVENT.OPEN_PROFILE_ALLOC_SESSION, data});
  }

}

import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { AnagService } from '../../services/anag.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { PlcQuestService } from '../../services/plc-quest.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CLAUSES, ClausesStepperConfig, INSURED_CLAUSE_TYPE_FORM, POLICY_CLAUSE_TYPE_FORM, RISK_CLAUSE_TYPE_FORM } from './config/change-clauses-configuration';
import {
  PostsalesOperationObject,
  ClauseDefinition, ClauseVariationInfo, PolicyClauseInfo, InsuredClauseInfo, RiskClauseInfo } from '../../models/postsales-operations-response.model';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { ChangeClauseUtils } from './util/change-clause-utils';

/* eslint-disable max-len */
@Component({
  selector: 'lpc-change-clauses',
  templateUrl: './change-clauses.component.html',
  styleUrls: ['./change-clauses.component.scss']
})
export class ChangeClausesComponent extends AbsOperationComponent implements OnInit {

  protected operationDataKey = 'clauses-variation';
  public readonly CLAUSES_STEP: ClausesStepperConfig = CLAUSES;

  clausesDefinition: {
    policyClauses: ClauseDefinition[];
    insuredClauses: ClauseDefinition[];
    riskClauses: ClauseDefinition[]
  } = {} as any;

  public clauses: {
    policyClauses: ClauseDefinition[];
    insuredClauses: ClauseDefinition[];
    riskClauses: ClauseDefinition[]
  } = {} as any;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected anag: AnagService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      [CLAUSES.DATE.formName]: new UntypedFormControl(),
      [CLAUSES.CLAUSES.formName]: new UntypedFormGroup({}),
      [CLAUSES.NOTES.formName]: new UntypedFormControl() // Text-area note
    });
  }


  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe((result) => {
        const operationData = result.data.operationData.data as ClauseVariationInfo;
        this.valorizeClauseArray(result);

        this.clearClauseForm();
        this.initializeClausesForm(operationData);
      })
    );
  }

  public updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string): void {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    const operationData = result.data.operationData.data as ClauseVariationInfo;
    this.valorizeClauseArray(result);

    this.clearClauseForm();
    this.initializeClausesForm(operationData);
  }

  protected getTransformedOperationData() {
    const clauses = {};
    Object.keys(this.formGroupValue.clauses as { [key: string]: any[]; }).forEach(clauseType => {
      clauses[clauseType] = this.filterOnlySelectedClausesAndMapToClausesData(clauseType);
    });
    return clauses;
  }

  public trackByFn(index, item) {
      if (item.key === POLICY_CLAUSE_TYPE_FORM) { return -1; }
      if (item.key === INSURED_CLAUSE_TYPE_FORM) { return 0; }
      if (item.key === RISK_CLAUSE_TYPE_FORM) { return 1; }
  }

  /**
   * Filters the selected clauses and maps them to ClausesData objects without the clauseState field.
   *
   * @param clauseType - The type of clauses to filter.
   * @returns An array of ClausesData objects representing the selected clauses.
   */
  private filterOnlySelectedClausesAndMapToClausesData(clauseType: string): PolicyClauseInfo[] | InsuredClauseInfo[] | RiskClauseInfo[] {
    return ChangeClauseUtils.createRequest(clauseType, this.formGroupValue);
  }

  /**
   * Clears the clause form by removing all controls from the form group.
   */
  private clearClauseForm() {
    if (!!this.formGroup.get(CLAUSES.CLAUSES.formName) && !!(this.formGroup.get(CLAUSES.CLAUSES.formName) as UntypedFormGroup).controls) {
      Object.keys((this.formGroup.get(CLAUSES.CLAUSES.formName) as UntypedFormGroup).controls)
      .forEach(type => (this.formGroup.get(CLAUSES.CLAUSES.formName) as UntypedFormGroup).removeControl(type));
    }
  }


  /**
   * Valorizes the clause array based on the provided PostsalesOperationObject.
   *
   * @param result - The PostsalesOperationObject containing the clause definitions.
   */
  private valorizeClauseArray(result: PostsalesOperationObject) {
    const policyClauses = PlcObjectUtils.asValidArray((result.definitions.policyClause as ClauseDefinition[]));
    const insuredClauses = PlcObjectUtils.asValidArray((result.definitions.insuredClause as ClauseDefinition[]));
    const riskClauses = PlcObjectUtils.asValidArray((result.definitions.riskClause as ClauseDefinition[]));
    this.clausesDefinition = {
      policyClauses,
      insuredClauses,
      riskClauses
    };
    Object.keys(this.clausesDefinition).forEach(type => {
      this.clauses[type] = ChangeClauseUtils.flatMapOnClauseListByType(this.clausesDefinition, type);
    });
  }

  /**
   * Initializes the clauses form.
   *
   * @param data Optional data for initializing the form.
   */
  initializeClausesForm(data?: ClauseVariationInfo) {
    Object.keys(this.clausesDefinition).forEach(type => {
      (this.formGroup.get(CLAUSES.CLAUSES.formName) as UntypedFormGroup).addControl(type, new UntypedFormArray([]));
      ChangeClauseUtils.flatMapOnClauseListByType(this.clausesDefinition, type, data)
      .forEach(clause => {
        ((this.formGroup.get(CLAUSES.CLAUSES.formName) as UntypedFormGroup).get(type) as UntypedFormArray).push(
          new UntypedFormGroup({
            [CLAUSES.CLAUSES.formChildren.clauseInstance]: new UntypedFormControl(clause.istanceName),
            [CLAUSES.CLAUSES.formChildren.codRisk]: new UntypedFormControl(clause.riskCode),
            [CLAUSES.CLAUSES.formChildren.clauseCode]: new UntypedFormControl(clause.codice),
            [CLAUSES.CLAUSES.formChildren.clauseText]: new UntypedFormControl({value: clause.testo, disabled: !clause.tipoTesto}),
            [CLAUSES.CLAUSES.formChildren.clauseState]: new UntypedFormControl(clause.selected/* , disabled: !clause.enabled */)
          }));
      });
    });
  }

  onClickCheckbox(clause) {
    clause.selected = !clause.selected;
  }

}

<div class="grid-item card card-height-normal card-main">
    <form [formGroup]="newPayFormModal">
        <div class="modal-content" >
            <div class="modal-header">
                <h3 class="modal-title">{{ '_CLAIMS_._CALCULATE' | translate }}
                    <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
                </h3>
            </div>
            <div class="form-group" *ngIf="exp.calculationDescription" style="text-align: center;"> 
                <textarea disabled rows="4" class="alert alert-info" style="width: 98%;">
                    {{exp.calculationDescription}}
                </textarea>
            </div>
            <div class="modal-body" style="padding-top: 5px; height: 400px;">
                <div class="col-xs-11 col-xs-12" style="font-size: 14px;">
                    <div *ngFor="let fact of factors; let factIndex = index;">
                        <div class="col-xs-6 col-xs-12" style="font-size: 14px;" 
                        *ngIf= "fact.visible">
                            <div *ngIf="fact.type === '1' || fact.type === '3'">
                                <label ng-bind="label" class="label-core">{{ fact.descriptionExt | translate }} 
                                    <pnc-required required="true" *ngIf="fact.required"></pnc-required>
                                </label>
                                <br>
                                <input type="number" class="form-control" name="factval{{factIndex}}"
                                        attr.data-qa="factval{{factIndex}}-input"
                                        formControlName="factval{{factIndex}}" *ngIf="fact.type === '1'"  />
                                <select class="core-select form-control" id="factval{{factIndex}}" *ngIf="fact.type === '3'"
                                        name="factval{{factIndex}}" formControlName="factval{{factIndex}}" >
                                        <option></option>
                                        <option  value={{true}}>
                                        {{ '_CLAIMS_._YES' | translate }}
                                        </option>
                                        <option  value={{false}}>
                                        {{ '_CLAIMS_._NO' | translate }}
                                        </option>
                                </select>
                                <span class="pull-right arrow-select-native 
                                    rgifont pnc-select pnc-select-chevron-down" *ngIf="fact.type === '3'"></span>
                            </div>
                            <div *ngIf="fact.type === '0' ">
                                <label ng-bind="label" class="label-core">{{ fact.descriptionExt | translate }} 
                                    <pnc-required required="true" *ngIf="fact.required"></pnc-required>
                                </label>
                                <br>
                                <select class="core-select form-control" id="factval{{factIndex}}" 
                                        name="factval{{factIndex}}" formControlName="factval{{factIndex}}" >
                                        <option></option>
                                        <option  *ngFor="let acombo of fact.listValues;" value={{acombo.code}}>
                                            {{acombo.description | translate}}
                                        </option>
                                </select>
                                <span class="pull-right arrow-select-native 
                                    rgifont pnc-select pnc-select-chevron-down" ></span>
                            </div>
                            <pnc-datepicker  label="{{fact.descriptionExt | translate}}"
                                    [required]="fact.required"
                                    customFormControlName="factval{{factIndex}}" [customFormGroup]="newPayFormModal"
                                    [maxDate]="today" attr.data-qa="factval{{factIndex}}-input"
                                    *ngIf="fact.type === '4'">
                            </pnc-datepicker>
                            
                        </div>
                    </div>
                </div>
                <div class="col-xs-1 col-xs-12" >
                    <button class="btn btn-primary text-uppercase" (click)="calculate()" style="height: 360px; margin: -13px 0px 0px 0px; width: 100%;"
                        translate><span class="glyphicon glyphicon-download-alt" style="width:10px; height:10px;" title="{{ '_CLAIMS_._CALCULATE_AMOUNT' | translate }}"></span></button>
                </div>
            </div>
            <hr style="height:1px; width:50%; border-width:0; color:grey; background-color:gray; width: 100%; margin: 0px;">
            <div class="form-group row">
                <div class="col-xs-7 col-xs-12" style="font-size: 14px;">
                    
                </div>
                <div class="col-xs-3 col-xs-12" style="text-align: right;">
                    <label ng-bind="label" class="label-core" style="font-size: 18px; font-weight: bold;">{{ '_CLAIMS_._CALCULATED_AMOUNT' | translate }} 
                    </label>
                </div>
                <div class="col-xs-2 col-xs-12" style="font-size: 18px; font-weight: bold; color: #d22323;text-align: center;">
                    {{exp.calculatedAmount | currency:'EUR':true}}
                </div>
            </div>
           
            <div class="form-group" >
             <div *ngIf="vectorErrorMessage!=null">
                 <div [ngClass]="cssMsg"  role="alert" *ngFor="let msg of vectorErrorMessage;">{{ msg | translate }}</div>
             </div>
            </div>
            <div class="modal-footer">
                
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button class="btn btn-warning text-uppercase" (click)="onConfirm()" 
                            >{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button> 
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

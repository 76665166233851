import { Directive, OnDestroy, ViewContainerRef, ComponentFactoryResolver, ComponentRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Factor } from '../../models/factor.model';
import { LpcSelectFactorComponent } from './lpc-select-factor/lpc-select-factor.component';
import { LpcInputFactorComponent } from './lpc-input-factor/lpc-input-factor.component';
import { LpcRadioGroupFactorComponent } from './lpc-radio-group-factor/lpc-radio-group-factor.component';
import { LpcDatepickerFactorComponent } from './lpc-datepicker-factor/lpc-datepicker-factor.component';
import { EMPTY_STR } from '../../models/consts/lpc-consts';
import { FACTOR_TYPE } from '../../models/enums/vita.enum';

@Directive({
  selector: '[lpcFactorLoader]',
})
export class LoaderDirective implements OnDestroy {
  element = {
    label: EMPTY_STR,
    value: EMPTY_STR
  };

  constructor(
    protected view: ViewContainerRef,
    protected resolver: ComponentFactoryResolver,
  ) { }

  private ref: ComponentRef<any>;

  @Input() group: UntypedFormGroup;
  @Input() set lpcFactorLoader(config: Factor) {

    this.view.clear();
    if (config) {
      let outputFactor: any;
      switch (config.type.toString().trim()) {
        case FACTOR_TYPE.NUMERIC:
          if (config.values.length !== 0) {
            outputFactor = this.resolver.resolveComponentFactory(LpcSelectFactorComponent);
            this.ref = this.view.createComponent(outputFactor);
// eslint-disable-next-line @typescript-eslint/no-shadow
            const selectFactor: ComponentRef<LpcSelectFactorComponent> = this.ref as ComponentRef<LpcSelectFactorComponent>;
            selectFactor.instance.setFactor(config, this.group);
          } else {
            outputFactor = this.resolver.resolveComponentFactory(LpcInputFactorComponent);
            this.ref = this.view.createComponent(outputFactor);
// eslint-disable-next-line @typescript-eslint/no-shadow
            const inputFactor: ComponentRef<LpcInputFactorComponent> = this.ref as ComponentRef<LpcInputFactorComponent>;
            inputFactor.instance.setFactor(config, this.group);
          }
          break;
        case FACTOR_TYPE.STRING:
          outputFactor = this.resolver.resolveComponentFactory(LpcInputFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const inputFactor: ComponentRef<LpcInputFactorComponent> = this.ref as ComponentRef<LpcInputFactorComponent>;
          inputFactor.instance.setFactor(config, this.group);
          break;
        case FACTOR_TYPE.STRUCT:
        case FACTOR_TYPE.LIST:
          outputFactor = this.resolver.resolveComponentFactory(LpcSelectFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const selectFactor: ComponentRef<LpcSelectFactorComponent> = this.ref as ComponentRef<LpcSelectFactorComponent>;
          selectFactor.instance.setFactor(config, this.group);
          break;
        case FACTOR_TYPE.BOOL:
          outputFactor = this.resolver.resolveComponentFactory(LpcRadioGroupFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const radioFactor: ComponentRef<LpcRadioGroupFactorComponent> = this.ref as ComponentRef<LpcRadioGroupFactorComponent>;
          radioFactor.instance.setFactor(config, this.group);
          break;
        case FACTOR_TYPE.DATE:
          outputFactor = this.resolver.resolveComponentFactory(LpcDatepickerFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const dateFactor: ComponentRef<LpcDatepickerFactorComponent> = this.ref as ComponentRef<LpcDatepickerFactorComponent>;
          dateFactor.instance.setFactor(config, this.group);
          break;
      }
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.destroy();
    }
  }
}

import {AnagApiNode} from '../anag-api/anag-api-node';
import {AnagApiEntity, AnagApiEntityExternal, AnagBirthAddress, AnagEntityIta} from '../anag-api/anag-subject-api';
import {AnagPrivacyConsensus} from './anag-privacy-config';
import {AnagDocument} from './anag-document';
import {AnagPartyLink} from './anag-party-link';
import {AnagDynamicObj} from './anag-dynamic-obj';
import {AnagPartyPayment} from './anag-party-payment';
import {AnagTaxClassification} from "./anag-tax-classification";
import { AnagRating } from './anag-rating';

export class AnagPhoneNumber {
  extensionSubject: string = null;
  internationalPrefix: string = null;
  localPrefix: string = null;
  number: string = null;
  objectId: string = null;
}

export class AnagKey {
  public label: string;
  public state: string;
  public type: AnagEntityIta;
  public value: string;
}

export class AnagPartyKey {
  public country?: string | null;
  public extensionSubject?: string | null;
  public key1?: AnagKey | null;
  public key2?: AnagKey | null;
  public key3?: AnagKey | null | undefined;
  public main?: boolean;
  public objectId?: string | null;
}

export class AnagPartyKeyService {
  public partyKey: AnagPartyKey;
}

export class AnagApiAddress extends AnagBirthAddress {
  public addressAddition: any;
  public cab: string;
  public city: string;
  public detailCode: string;
  public extensionSubject: string;
  public fraction: string;
  public latitude: string;
  public longitude: string;
  public postalAddress: string;
  public province: AnagEntityIta;
  public specialMention: any;
}

export class AnagApiOtherAddress {
  public addressType: AnagEntityIta = new AnagEntityIta(null, null);
  public baseAddress: AnagApiAddress = new AnagApiAddress();
  public fax: AnagPhoneNumber = new AnagPhoneNumber();
  public mainAddress: boolean = null;
  public objectId: string = null;
  public phone: AnagPhoneNumber = new AnagPhoneNumber();
  public availabilityEndDate: string = null;
  public availabilityStartDate: string = null;
  public availableFrom: string = null;
  public availableUntil: string = null;
}

export class AnagSubjectNode {
  code: string;
  description: string;
  nodeObjectId: string | number;
}

export class AnagApiParty {
  birthAddress: AnagBirthAddress;
  citizenship: AnagEntityIta;
  cityOfBirth: string | undefined;
  codAteco: AnagApiEntity;
  company: AnagEntityIta;
  corporateForm: AnagEntityIta | undefined;
  countryOfBirth: AnagEntityIta | undefined;
  dateOfBirth: Date;
  denomination: string | null;
  dynamicObjects: Array<AnagDynamicObj>;
  documents: AnagDocument[];
  emails: Array<string>;
  extensionSubject: any | undefined;
  fiscalCode: string;
  gruppoAE: AnagApiEntity | undefined;
  genderIdentity: AnagEntityIta;
  idLatestPhotos?: string | null;
  limitation: boolean;
  links: Array<AnagPartyLink>;
  landlineNumber: AnagPhoneNumber;
  maritalStatus: AnagEntityIta;
  mainContact: AnagEntityIta;
  mainSubject: boolean;
  mobilePhone: Array<AnagPhoneNumber>;
  name: string;
  node: AnagApiNode;
  nominative: string | null;
  objectId?: string;
  partyKey: Array<AnagPartyKey>;
  partyNumber: string;
  payments: AnagPartyPayment[];
  personType: AnagEntityIta;
  phoneFax: AnagPhoneNumber;
  privacyConsensus?: Array<AnagPrivacyConsensus>;
  privacyConsensusDate: Date;
  professionDetail: AnagEntityIta;
  professionType: AnagEntityIta;
  residence: AnagApiAddress | null;
  sex: AnagEntityIta;
  sottogruppoAE: AnagApiEntity;
  subjectType: AnagEntityIta;
  subjectNodes: Array<AnagSubjectNode>;
  surname: string;
  vat: string | undefined;
  corporateSector: AnagEntityIta;
  politicallyExp: boolean | undefined;
  counterTerrorismStatus: CounterTerrorismStatus;
  counterTerrorismDate: Date | undefined;
  otherAddress: Array<AnagApiOtherAddress> | null;
  birthName: string;
  language: AnagEntityIta;
  salutation: AnagEntityIta;
  sonsNumber: number;
  robinsonList: boolean;
  citizenshipPermit: string;
  citizenshipExpire: Date;
  regCountry: string;
  subsysReferences: AnagApiEntityExternal[] = [];
  taxClassifications: AnagTaxClassification[];
  leicode: string;
  ratings: AnagRating[];
}

export class CounterTerrorismStatus {
    codice: string | undefined;
    descrizione: string | undefined;
}



/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class TableElement {

  private description: string;
  private id: string;
  private event: string;
  private type: string;
  private customClass: string;

  constructor(description: string, type?: string, id?: string, event?: string,  customClass?: string) {
    this.description = description;
    if (id) {
      this.id = id;
    }
    if (event) {
      this.event = event;
    }
    if (type) {
      this.type = type;
    }
    if (customClass) {
      this.customClass = customClass;
    }
  }

  public getDescription() {
    return this.description;
  }

  public getId() {
    return this.id;
  }

  public getEvent() {
    return this.event;
  }

  public getType() {
    return this.type;
  }

  public getCustomClass() {
    return this.customClass;
  }

  public setDescription(value) {
    this.description = value;
  }

  public setId(value) {
    this.id = value;
  }

  public setEvent(value) {
    this.event = value;
  }

  public setType(value) {
    this.type = value;
  }

  public setCustomClass(value) {
    this.customClass = value;
  }

}

export class ProductsFilter {
  productsFilter:Filter;
}
export class Filter {
    idSp: string
    filterProductsCategory: string
    filterSalableProduct: boolean
    filterSalableProductTypes: boolean
    constructor(idSp: string, filterProductsCategory: string, filterSalableProduct: boolean, filterSalableProductTypes: boolean) {
      this.idSp = idSp;
      this.filterProductsCategory = filterProductsCategory;
      this.filterSalableProduct = filterSalableProduct;
      this.filterSalableProductTypes = filterSalableProductTypes;
    }
}

import { Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LegalFeeOmniaEntity } from '../domain/legal-fee-omnia-entity';
@Component({
  selector: 'claims-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
   export class PaymentModalComponent implements OnInit {
    @Input() public potPayFE: any;
    @Input() public settlementsDetails: boolean;
    @Input() public receiptsDetails: boolean;
    @Input() public compensationsDetails: boolean;
    @Input() public assignementsDetails: boolean;
    @Input() public settlementsDetailsTypeOfLiq: boolean;
    @Input() public omniaDetail: boolean;
    @Input() public listOmniaFee: Array<any>;
    public ministerialBranch: string;
    headerData: Array<string>;
    bodyData: [];




  constructor(
    private activeModal: NgbActiveModal
    ) { }

  ngOnInit() {
    if (this.settlementsDetails) {
      this.ministerialBranch = this.potPayFE.ministerialBranch.substr(0, this.potPayFE.ministerialBranch.indexOf(' '));
      if (this.ministerialBranch === '000010') {
        this.headerData = ['Number', 'Date', 'Payment Type', 'Technical definition',
        'Amount', 'Load Date', 'Forfait amount', 'State'];
      } else {
        this.headerData = ['Number', 'Date', 'Payment Type', 'Technical definition',
        'Amount', 'Load Date', 'State'];
      }
    }
    if (this.receiptsDetails) {
      this.headerData = ['Number', 'Date', 'Payment Type', 'Amount', 'State', ];
    }
    if (this.assignementsDetails) {
      this.headerData = ['Number', 'Expert', 'Assignment type', 'Assignment date', 'State'];
    }
    if (this.compensationsDetails) {
      this.headerData = ['Number', 'Type', 'State', 'Amount', 'Amount Active',
      'Opening date', 'Closure Date', 'Refused Date'];
    }
    if (this.settlementsDetailsTypeOfLiq) {
      this.headerData = ['Payment Type', 'Excess', 'Deductible', 'Ceiling', ];
    }
    if (this.omniaDetail) {
      this.headerData = ['Fee number', 'Fee date', 'Fee amount', 'Cashed', ];
    }
  }
  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }
}

<template #claimModal></template>
<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>{{ title }}</span>
          <span (click)="close()" class="glyphicon glyphicon-remove pull-right cursorPointer"></span>
        </h3>
      </div>
      <div class="modal-body">
        <table class="nmf_cont_atr">
          <thead>
          <tr>
            <th translate>Documents</th>
            <th translate>Print</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let document of documents; let i = index">
            <tr *ngIf="i % 2 === 0" class="nmf_oddRow">
              <td translate>
                <span class="rgifont rgi-file-text"> </span>
                <b> {{ document.documentName }}</b></td>
              <td translate>
                <span (click)="download(document.documentCode)" class="rgifont rgi-download"
                      style="cursor: pointer;"></span>
              </td>
            </tr>
            <tr *ngIf="i % 2 !== 0" class="nmf_oddCol">
              <td translate>
                <span class="rgifont rgi-file-text"> </span>
                <b> {{ document.documentName }}</b></td>
              <td>
                                    <span (click)="download(document.documentCode)" class="rgifont rgi-download"
                                          style="cursor: pointer;"></span>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-document-modal-close" translate>CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content"  >
      
      <div class="modal-header">
            <h3 class="modal-title">{{ '_CLAIMS_._MANUAL_NOTE' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
            </h3>
      </div>

      <div class="modal-body" style="padding-top: 5px; ">
        <div class="row">
            <div class="col-xs-12" style="font-size: 16px;">
                <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._INSERT_A_NEW_MANUAL_NOTE' | translate }} </label>
            </div>
        </div>
        <div class="row">
        <div class="col-xs-12" >
            <textarea class=" col-sm-12" [(ngModel)]="message" placeholder="{{ 'Message' | translate }}" 
            id="body" cols="30" rows="3"
            name="body" maxlength="4000" ngModel required></textarea>
        </div>
        </div>
        
      </div>
      <div class="row">
        <div class="col-xs-12" >
            <div class="btn-group btn-group-justified">
                <div class="btn-group" >
                    <button class="btn btn-warning text-uppercase" (click)="onConfirm()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                </div>
            </div>
        </div>
      </div>
    </div> 
    
</div>

import {InjectionToken} from '@angular/core';

export interface RgiRxLoadingOverlayConfig {
  /**
   * Whether the overlay should listen to RgiRxLoadingIndicator for activation.
   * @see RgiRxLoadingIndicator
   */
  auto: boolean;
  /**
   * The activation delay of the loader in milliseconds. If the activation time is below the delay threshold the loader won't be displayed.
   */
  debounceTimeMillis: number;
  /**
   * The loader class to use. By default, it's rgi-ui-loader
   */
  loaderClass: 'rgi-ui-loader' | string;
}


export const RGI_RX_LOADING_OVERLAY_DEFAULT_CONFIG: RgiRxLoadingOverlayConfig = {
  auto: false,
  debounceTimeMillis: 500,
  loaderClass: 'rgi-ui-loader'
};
export const RGI_RX_LOADING_OVERLAY_CONFIG = new InjectionToken<RgiRxLoadingOverlayConfig>('RGI_RX_LOADING_OVERLAY_CONFIG');

export abstract class RgiRxLoadingOverlay {
  abstract isOpen(): any;

  abstract show(): void;

  abstract hide(): void;

  abstract toggle(): void;
}

import { ApiEnumType } from '../api-enum-type';
import { ApiKeyValue } from './api-key-value';

export class ApiPolicyFilter {
    contractType: number;
    searchParentNodes: boolean;
    salePointId: number;
    salePointCode: number;
    contractTodayEffectDate: boolean;
    categories: ApiEnumType[];
    companyId: string;
    effectDateTo: Date;
    expirationDateFrom: Date;
    states: ApiEnumType[];
    policyNumber: string;
    numberPlate: string;
    extension: { properties: ApiKeyValue[] };
}

import { ActivityBusinessKeyClaim } from '../../model/domain/activity/activity-business-key-claim';
import { ActivityTask } from '../../model/domain/activity/activity-task';
import { ActivityVariable } from '../../model/domain/activity/activity-variable';

export class ActivityDto {

  public actBusinessKeyClaimBean: ActivityBusinessKeyClaim;
  public actTaskBean: ActivityTask[];
  public actVariableBean: ActivityVariable[];
  public assigneeGroup: string;
  public assigneeUser: string;
  public claimNumber: string;
  public expirationDate: string;
  public idActDict: number;
  public idAct: number;
  public message: string;
  public triggerEvent: number;
  public integrationType: number;
  public operationType: number;
  public priority: number;
  public title: string;
  public status: number;
  public decoupStatus: number;
  public idUserIns: number;
  public instanceId: string;
  public potClaimNumber: string;
  // public involvedParty: number;
  // public typeOfLoss: number;

  constructor(init?: Partial<ActivityDto>) {
    Object.assign(this, init);
  }

}

import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { CardComponent, CardComponentService } from '@rgi/digital-claims-common-angular';

import { NoteComponent } from '../../objects/note.component';

@Component({
  selector: 'claims-movement-note',
  templateUrl: './movement-note.component.html',
  styleUrls: ['./movement-note.component.scss']
})
export class MovementNoteComponent extends CardComponent implements NoteComponent, OnInit {

  @Input() data: any;
  @Input() rechargeEventEmitter: EventEmitter<boolean>;
  leftColumnStyle = '';

  constructor(
    @Inject('coreResult') private coreResult,
    @Inject('sessionService') private session: any,
    @Inject('containerCacheService') private cache,
    cardComponentService: CardComponentService) {
      super(cardComponentService, session);
     }

  ngOnInit() {
    this.leftColumnStyle = this.getLeftColumnStyle();
  }

  private getLeftColumnStyle() {
    return !this.data.hasRightColumn ? 'hasOnlyLeft' : '';
  }

  openDetailPayment() {
    const idSession = this.session.list().find(el => el.isActive).idSession;

    const claim = this.cache.getCache().get(idSession).data;

    const party = claim.claimParties.find(el => el.identifier === this.data.idParty);

    const filter: any = {};
    filter.claimId = claim.idClaim;
    filter.idPotencialClaim = claim.idPotencialClaim;
    filter.section = this.data.type === '16' ? 'receipts' : 'settlement';
    filter.partyId = this.data.idParty;

    const cardName = 'claimsNewPayment';
    const cardView = 'home';

    // openSession(parent, route, title, navigationDisabled, navigationParams, advancedSearch, idSessionParent, origin)
    const idResult = this.session.open(cardName, cardView, '', true, null, null, idSession);

    this.coreResult.setResult(idResult, 'claim', claim);
    this.coreResult.setResult(idResult, 'lastSession', idSession);
    this.coreResult.setResult(idResult, 'party', party);

    this.coreResult.setResult(idResult, 'filter', filter);
    this.coreResult.setResult(idResult, 'idsettlement', this.data.idPayment);
    this.coreResult.setResult(idResult, 'onlySettlementDetails', true);
  }
}

import {Injectable} from '@angular/core';
import {Logger, LogLevel} from './logging-api';
import {StreamLoggerStoreHandler} from './logger-store-handler.service';

@Injectable()
export class RgiRxLogger implements Logger {

  constructor(private handler: StreamLoggerStoreHandler) {
  }

  debug(message: any, ...optional: any[]): void {
    this.push(message, LogLevel.DEBUG, ...optional);
  }

  error(message: any, ...optional: any[]): void {
    this.push(message, LogLevel.ERROR, ...optional);
  }

  info(message: any, ...optional: any[]): void {
    this.push(message, LogLevel.INFO, ...optional);
  }

  log(message: any, ...optional: any[]): void {
    this.push(message, LogLevel.LOG, ...optional);
  }

  trace(message: any, ...optional: any[]): void {
    this.push(message, LogLevel.TRACE, ...optional);
  }

  warn(message: any, ...optional: any[]): void {
    this.push(message, LogLevel.WARN, ...optional);
  }


  private push(message: any, level: LogLevel, ...optional: any[]): void {
    this.handler.push(message, level, ...optional);
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcWarningMessagesComponent } from './lpc-warning-messages.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
  declarations: [LpcWarningMessagesComponent],
  imports: [
    CommonModule,
    RgiRxI18nModule
  ],
  exports: [LpcWarningMessagesComponent]
})
export class LpcWarningMessagesModule { }

import { MethodPaymentEntity } from '../domain/method-payment-entity';
import { SubjectEntity } from '../domain/subject-entity';

export class OutputDetailPaymentMethod {
    paymentData: MethodPaymentEntity = null;
    note: string = '';
    subjectSelected?: SubjectSelected = new SubjectSelected();
    validation: boolean;

}

export class SubjectSelected {
    subjectEntity: SubjectEntity = null;
    codeSelected: string = null;
}

export class Role {

  public id: string;
  public code: string;
  public description: string;
  public partyRoles: Array<any>;
  public min: number;
  public max: number;

  constructor() {
  }

}

import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { switchMap, tap } from 'rxjs/operators';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import {
  Definition,
  FactorDefinition, PostsalesError,
  Role
} from '../../models/postsales-operations-response.model';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { Quotation } from '../../modules/lpc-quotation/model/quotation.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-reduction',
  templateUrl: './reduction.component.html',
  styleUrls: ['./reduction.component.scss']
})
export class ReductionComponent extends AbsOperationComponent {

  protected operationDataKey = 'reduction';
  protected anagSubj = null;
  public listProductFactor: FactorDefinition[] = [];
  public requestFactor: RequestFactor[] = [];

  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];

  public damageData: any;

  public quotationData: Quotation;

  public action: string;

  public get hasProductFactors(): boolean {
    return !!this.listProductFactor ? !!this.listProductFactor.length : false;
  }


  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected anagService: AnagService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService, plcQuestService, authService, anagService);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {
        })
      ).subscribe()
    );
  }

  onNext(step, publish = false, context = step, isConfirmAndAccept = false) {
    const beStep: string = step;
    if (this.isFormValid(this.formGroup.get(step))) {
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, step, this).pipe(
        tap(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
          if (!!this.avcQuestionnaire) {
            this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(this.avcQuestionnaire);
          }
        }),
        switchMap(() => {
          return this.updateDraft(beStep);
        })
      ).subscribe(result => {
          this.quotationData = result.data.operationData.data as Quotation;
          this.detectChanges();
          if (!this.hasBlockingErrorsOnSteps(step)) {
            this.doPublishOrNext(publish, isConfirmAndAccept);
          }


        })
      );
    } else {
      this.setFeErrors(step);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      quotation: new UntypedFormGroup({})
    });
  }

  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

  protected getTransformedOperationData(): any {
    const obj: any = {
    };

    return obj;
  }

  confirmButtonVisibility() {
    return !this.hasBlockingErrorsOnSteps('quotation') && !!this.insuredSettlements && !!this.insuredSettlements.length;
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

  public changeRouteCloseSession(showModal: boolean) {
    if (showModal) {
      this.$subscriptions.push(
        fromPromise(
          this.openModal('Confirm', 'Are you sure you want to cancel this operation?', true).result
        ).subscribe(result => {
          if (result) {
            this.closeCardAndSession();
          }
        })
      );
    } else {
      this.closeCardAndSession();
    }
  }

  private closeCardAndSession() {
    const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
    if (!!this.$session.sessionInfo && !!this.$session.sessionInfo.sessionId
        && !!this.$session.sessionInfo.isFromReduction && !!sessionService) {
      const sessionStore = sessionService.list();
      for (const session of sessionStore) {
        if (session.id === this.$session.sessionInfo.sessionId) {
          sessionService.setActive(session.idSession);
        }
      }
    }
    this.closeCardWithoutModal();
  }
}

import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {CardAnalyticsReducerService} from './card-analytics-reducer.service';
import {of} from 'rxjs';
import { Report } from '../model/report';

export class CardAnalyticsStateNewReport extends State {

}


export class CardAnalyticsStateManagerNewReport extends RgiRxLocalStateManager<CardAnalyticsStateNewReport> {
  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, private reducer: CardAnalyticsReducerService) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<CardAnalyticsStateNewReport>(this.activeRoute.key) ?
      stateStoreService.get<CardAnalyticsStateNewReport>(this.activeRoute.key) : new CardAnalyticsStateNewReport(this.activeRoute.key);
    this.updateState$(of(st));
  }

  getReports(reports: Report[]) {
    const currentState = this.getCurrentState();
    this.updateState$(of(currentState));
  }

  actionOpen() {
    this.reducer.backHome(this.activeRoute);
  }

  removeSession(activeRoute: ActiveRoute) {
    this.reducer.removeSession(activeRoute);
  }

}


import {Component, ComponentRef, EventEmitter, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Modal} from '../../modal';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {QuotaCoass} from '../../models/domain-models/quota-coass';
import {QuotaCoassConfig} from '../../models/domain-models/quota-coass-config';

@Component({
  selector: 'mic-coinsurance-quote',
  templateUrl: './coinsurance-quote.modal.component.html'
})
export class QuotaCoinsuranceModalComponent implements OnInit, Modal {

  componentRef: ComponentRef<any>;
  quotaCoass: QuotaCoass;
  quotaForm: UntypedFormGroup;
  config: QuotaCoassConfig;
  quoteConfirmEmitter: EventEmitter<QuotaCoass> = new EventEmitter();
  isLeasingInstitute: boolean;

  constructor(
    protected formBuilder: UntypedFormBuilder
  ) {

  }

  ngOnInit(): void {

    this.config = this.quotaCoass.config;
    this.quotaForm = this.formBuilder.group({
      company: [this.quotaCoass.idCompany,
        this.config.idCompanyRequired ? Validators.required : Validators.nullValidator],
      intermediary: [this.quotaCoass.idIntermediary,
        this.config.idIntermediaryRequired ? Validators.required : Validators.nullValidator],
      ourPercentage: [this.quotaCoass.ourPerc,
        this.config.ourPercRequired ? Validators.required : Validators.nullValidator],
      commissionPercentage: [this.quotaCoass.commissionsPerc,
        this.config.commissionsPercRequired ? Validators.required : Validators.nullValidator],
      policyNumber: [this.quotaCoass.policyNumber,
        this.config.policyNumberRequired ? Validators.required : Validators.nullValidator]
    });
  }

  genericEntitiesTrackByFnCustom(genericEntity: GenericEntity) {
    return genericEntity.id;
  }

  genericEntitiesTrackByFnCustom1(genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  onKeyDown(e) {
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode === 8)) {
      return false;
    }
  }

  onConfirm() {

    if (this.quotaForm.valid) {

      this.quotaCoass.idCompany = this.getFcVal('company');
      this.quotaCoass.idIntermediary = this.getFcVal('intermediary');
      this.quotaCoass.ourPerc = this.getFcVal('ourPercentage');
      this.quotaCoass.commissionsPerc = this.getFcVal('commissionPercentage');
      this.quotaCoass.policyNumber = this.getFcVal('policyNumber');

      this.quoteConfirmEmitter.emit(this.quotaCoass);
    } else {
      this.setFieldsDirty();
    }
  }

  back() {
    this.quoteConfirmEmitter.emit(null);
  }

  getFcVal(controlName) {

    return this.quotaForm.controls[controlName] ?
      this.quotaForm.controls[controlName].value : null;
  }

  private setFieldsDirty() {
    Object.keys(this.quotaForm.controls).forEach(field => {
      this.quotaForm.get(field).markAsDirty();
    });
  }

  cancel() {
    this.componentRef.destroy();
  }
}

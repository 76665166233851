import { LpcKarmaDisinvestmentFundComponent } from './../lpc-karma-disinvestment-fund/lpc-karma-disinvestment-fund.component';
import { TranslationWrapperService } from '../../../../../i18n/translation-wrapper.service';
import { EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { InvestmentMode } from '../../../../../models/operation-property-code.enum';
import { FinancialElementToggleEvent, KarmaFundDefinition } from '../../../model/karma-fund-definition';
import { KarmaFundError } from '../../../model/karma-fund-error';
import { Subscription } from 'rxjs';
import { PlcObjectUtils } from '../../../../../utils/plc-object-utils';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'lpc-karma-disinvestment-funds',
  templateUrl: './lpc-karma-disinvestment-funds.component.html',
  styleUrls: ['./lpc-karma-disinvestment-funds.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFundsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFundsComponent),
      multi: true
    }
  ]
})
export class LpcKarmaDisinvestmentFundsComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  public formGroup: UntypedFormGroup = this.getFormGroup();

  @Input() public investmentMode: InvestmentMode;
  @Input() public definition: KarmaFundDefinition[];
  @Input() public investedProfiles: KarmaFundDefinition[];
  @Input() public totalAmount: number;
  @Input() public checksOnOverallAlloc: boolean;
  @Input() public showSliderInput = true;
  @Input() public isFreeManagement: boolean;
  @Output() public totalPercent: EventEmitter<number> = new EventEmitter<number>();
  @Input() public isFullPreval: boolean;
  @Input() public isprofile = true;

  @ViewChildren('singleFinancialElement') singleFinancialElement: QueryList<LpcKarmaDisinvestmentFundComponent>;

  public pageSize = 5;
  public pageSizes = [5, 10, 20, 30, this.translate.getImmediate('lpc_Mostra_tutti')];
  public page = 1;
  public totalItems = 5;
  public count = 0;

  public fundsSelected: any[] = [];

  private $funds: KarmaFundDefinition[];

  public get funds(): KarmaFundDefinition[] {
    if (!this.formGroup.get('search').value) {
      return this.$funds;
    } else {
      return this.$funds.filter(fund => fund.description.match(new RegExp(this.formGroup.get('search').value, 'i')));
    }
  }

  private $subscriptions: Subscription[] = [];

  private $defaultValueStructure: any;

  private $totalPercent = 0;

  protected $errors: KarmaFundError[];

  public get errors(): KarmaFundError[] {
    return this.$errors;
  }

  /** restituisce i fondi selezionati da passare alla summary */
  get selectedElementsForSummary(): KarmaFundDefinition[] {
    const elementsFromForm = this.formGroup.get('funds').value;
    const selectedFundsInfo = this.definition.filter(f => {
      const fund = f;
      fund.percentage = elementsFromForm[f.id];
      fund.percent = elementsFromForm[f.id];
      if (!!elementsFromForm[f.id] && Number(elementsFromForm[f.id]) > 0) {
        return fund;
      }
    });
    return selectedFundsInfo;
  }

  constructor(public translate: TranslationWrapperService) {
  }


  public getTotalAllocatedPercent(formValue: {[key: string]: number} = {}): number {
    let totalPerc = this.$totalPercent;
    if (!!formValue) {
      const obj = Object.keys(formValue)
      .filter(el => !!formValue[el] && !PlcObjectUtils.isObject(formValue[el]));

      if (!!obj.length) {
        totalPerc = obj.map(el => Number(formValue[el]))
        .reduce((a, c) => a + c, 0);
      }
    }
    return PlcObjectUtils.roundToDecimal(totalPerc, 6);
  }

  ngOnInit() {
    this.formGroup.get('count').setValue(5);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definition) {
      this.$funds = changes.definition.currentValue;
      if (!!this.funds) {
        this.count = this.funds.length;
      }
      if (!PlcObjectUtils.equal(Object.keys((this.formGroup.get('funds') as UntypedFormGroup).controls), this.funds.map(f => f.id))) {
        this.$defaultValueStructure = this.getDefaultStructure();
        this.populateFormGroup();
      }
      this.$subscriptions.push(
        this.formGroup.get('funds').valueChanges.pipe(distinctUntilChanged()).subscribe(result => {
          const obj = {};
          Object.keys(result).forEach(key => {
            obj[key] = result[key] ? result[key] : null;
          });
          this.$totalPercent = this.getTotalPercent();
          this.totalPercent.emit(this.$totalPercent);
          this.onChange(obj);
          this.onTouch();
        })
      );
    }
    if (changes.totalAmount) {
      this.formGroup.get('funds').updateValueAndValidity();
    }
  }

  getInvestedAmount(id: string): number {
    if (!!this.investedProfiles) {
      const fund = this.investedProfiles.find(el => Number.parseInt(el.id, 10) === Number.parseInt(id, 10));
      if (!!fund) {
        return PlcObjectUtils.roundToDecimalTrunc(fund.counterValue, 2);
      }
    }
  }

  public handlePageChange(event) {
    this.page = event;
  }

  handlePageSizeChange(event) {
    this.pageSize = event;
    this.page = 1;
    if (event === 'Mostra tutti') {
      this.pageSize = this.funds.length;
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  writeValue(obj: any): void {
    if (!!obj) {
      this.formGroup.get('funds').patchValue(
        PlcObjectUtils.merge({}, this.$defaultValueStructure, obj), { emitEvent: false }
      );
    } else {
      this.formGroup.get('funds').setValue(
        PlcObjectUtils.merge({}, this.$defaultValueStructure), { emitEvent: false }
      );
    }
    this.$totalPercent = this.getTotalPercent();
    this.totalPercent.emit(this.$totalPercent);
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  isAllocationCompleted(): boolean {
    return this.getTotalAllocatedPercent() === 1;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const obj: ValidationErrors = {};
    let funds: any = [];
     // i fondi devono raggiungere il totale investito per ogni profilo
    if (!this.checksOnOverallAlloc) {
      const totalAllocatedPercent = this.getTotalAllocatedPercent(control.value);
      if (totalAllocatedPercent > 1) {
        obj.max = { value: totalAllocatedPercent };
      }  else {
        if (totalAllocatedPercent !== 1 ) {
          obj.min = { value: totalAllocatedPercent };
        }
      }
    }
    if (!!control.value && Object.keys(control.value).every(el => control.value[el] == null)) {
      obj.required = true;
    }
    funds = this.populateFundsErrorField();
    if (!!Object.keys(funds).length) {
      obj.funds = funds;
    }
    const errors = !!Object.keys(obj).length ? obj : null;
    this.$errors = this.getErrorMessages(errors);
    return errors;
  }

  populateFundsErrorField(): any {
    const form = this.formGroup.get('funds') as UntypedFormGroup;
    if (!!form && !!form.controls) {
      return Object.entries(form.controls).filter(entry => !!entry[1].errors).map(entry => {
        return {[entry[0]] : entry[1].errors };
      });
    }
    return [];
  }

  private getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      search: new UntypedFormControl(),
      count: new UntypedFormControl(this.pageSize),
      funds: new UntypedFormGroup({})
    });
  }

  private populateFormGroup(): void {
    Object.keys((this.formGroup.get('funds') as UntypedFormGroup).controls).forEach(key => {
      (this.formGroup.get('funds') as UntypedFormGroup).removeControl(key);
    });
    this.$funds.forEach(fund => {
      (this.formGroup.get('funds') as UntypedFormGroup).addControl(fund.id.toString(), new UntypedFormControl());
    });

  }

  private getDefaultStructure(): any {
    const obj = {};
    this.$funds.forEach(fund => {
      obj[fund.id] = null;
    });
    return obj;
  }

  private getTotalPercent(): number {
    let total = 0;
    const funds = this.formGroup.getRawValue().funds;
    Object.keys(funds).forEach(key => {
      total += !!funds[key] ? funds[key] : 0;
    });
    return total;
  }

  protected getErrorMessages(errors: ValidationErrors): KarmaFundError[] {
    const array: KarmaFundError[] = [];
    if (errors) {
      if (errors.min || errors.max) {
        const type: 'min' | 'max' = errors.min ? 'min' : 'max';
        const difference: number = 1 - errors[type].value;
        array.push({
          description: 'TOTAL_FUNDS',
          amount: difference * this.totalAmount,
          percent: difference,
          limit: 1,
          type
        });
      }
      if (!!errors.funds) {
        (errors.funds as []).forEach(obj => {
          const key = Object.keys(obj)[0];
          const fundError: any = obj[key];
          const type: 'min' | 'max' = fundError.min ? 'min' : 'max';
          let amount = fundError[type].value;
          let differencePerc: number = null;
          let limit = fundError[type].limit;

          if (!fundError[type].isAmount) {
            differencePerc = fundError[type].limit - fundError[type].value;
            amount = differencePerc * this.totalAmount;
            limit = fundError[type].limit / 100;
          }
          array.push({
            description: this.getFundById(key).description,
            amount,
            percent: differencePerc,
            limit,
            type,
            isAmount: !!fundError[type].isAmount
          });
        });
      }
    }
    return array;
  }

  public getOkMessage(): KarmaFundError {
    return {
      description: 'TOTAL_FUNDS',
      amount: 0,
      percent: 0,
      limit: 1,
      type: 'ok'
    };
  }

  private getFundById(id: string): KarmaFundDefinition {
    return this.$funds.find(fund => fund.id === id);
  }

  public addToFunds(event) {
    if (event.selected) {
      const presentFund = this.fundsSelected.find(fund => fund.name === event.name);
      if (!!presentFund) {
        const index = this.fundsSelected.map(fund => fund.name).indexOf(presentFund.name);
        if (index !== -1) {
          this.fundsSelected.splice(index, 1);
        }
      }
      this.fundsSelected.push(event);
    } else {
      const index = this.fundsSelected.map(fund => fund.name).indexOf(event.name);
      if (index !== -1) {
        this.fundsSelected.splice(index, 1);
      }
    }
  }

  onToggleClick(obj: FinancialElementToggleEvent) {
    this.singleFinancialElement.filter(el => el.definition.id !== obj.id).forEach(elem => {
      elem.formGroup.get('isPercent').setValue(obj.isPercent);
    });
  }


}

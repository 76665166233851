export class UnitDiscounts {
  constructor(
    public unitId: number,
    public unitCode: string,
    public unitDescription: string,
    public flex1Min: number,
    public flex1Max: number,
    public flex1Perc: number,
    public flex1Taxable: number,
    public flex1EffectiveTaxable: number,
    public flex1EffectivePerc: number,
    // 1: No Discount, 2: Amount, 3: Percentage
    public flex1DiscountType: number,
    public flex1Enable: boolean,
    public flex2Min: number,
    public flex2Max: number,
    public flex2Perc: number,
    public flex2Taxable: number,
    public flex2EffectiveTaxable: number,
    public flex2EffectivePerc: number,
    // 1: No Discount, 2: Amount, 3: Percentage
    public flex2DiscountType: number,
    public flex2Enable: boolean,
    public grossPremium: number
  ) {
  }
}


<div class="modal-header">
  <div class="modal-title" translate>
      lpc_quotation_details
      <!-- <button type="button" class="close basic-close" (click)="showDetailModal=false">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
</div>
<div class="modal-body">
  <div class="row">
    <div class="row header-warranties">
      <div class="col-lg-12">
        <p style="font-weight: bold" translate>Provvigioni d'acquisto</p>
      </div>
    </div>
    <div class="tbld quotation-modal-table hidden-xs">
      <div class="tbld_row tbld_row_contributions_taxes_header">
        <div class="tbld_col" translate>lpc_Guarantee</div>
        <div *ngFor="let commission of commissions" class="tbld_col" translate>{{commission.description}}</div>
      </div>
      <div *ngFor="let risk of getRiskWithProv()" class="tbld_row tbld_row_contributions_taxes">
        <div class="tbld_col tbld_row_warranties" [attr.data-qa]="">{{risk.description}}</div>
        <div *ngFor="let commission of commissions" class="tbld_col">{{getValueFromRisk(risk, commission.code)| rgiCountryLayerNumberFormat: currencyService.locale: numberFormatterOptions}}</div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn-group btn-group-justified"
    style="margin-top: 0px !important; border: 0px !important; border-right: 0px !important;">
    <div class="btn-group">
      <button class="btn btn-warning btn-secondary" data-qa="close-button" (click)="closeModal()" translate>lpc_close_button</button>
    </div>
  </div>
</div>

import {CommonModule} from '@angular/common';
import {Inject, NgModule} from '@angular/core';
import {
  RgiPncPostsalesModule,
  PncPostSalesIntegrationService,
  PNC_POSTSALES_OPERATIONS,
  PostSalesOperation
} from '@rgi/pnc-postsales';
import {PortalConfig, RGI_RX_PORTAL_CONFIG, RgiRxPortalModule, RGI_RX_PORTAL_CARDS, PortalCards} from '@rgi/rx/portal';
import {
  angularGridInstance,
  manageNewCardInPassPortal,
  PNC_POSTSALES_CARD,
  PNC_PSALES_CARDS
} from './pnc-postsales.card.configurations';
import {downgradePncPostsalesService} from './ajs-downgrade';
import {PncPostsalesPortalIntegrationService} from './pnc-postsales-portal-integration.service';
import {flatten} from '@rgi/rx';


export function providePncPostsalesPortalOperations(operations: PostSalesOperation[][]): PortalCards {
  const card = PNC_POSTSALES_CARD;
  card.routes = flatten(flatten(operations).map(operation => {
    return operation.steps.map(step => {
      let route = 'pncpsales/' + operation.type + '/';
      route += operation.operationCode ? operation.operationCode + '/' : '';
      route += step.step.routeName;
      return {destination: route, route};
    });
  }));
  return [card];
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RgiPncPostsalesModule,
    RgiRxPortalModule.forRoot(PNC_PSALES_CARDS)
  ],
  providers: [
    {provide: PncPostSalesIntegrationService, useClass: PncPostsalesPortalIntegrationService},
    angularGridInstance,
    {provide: 'RGI_POSTSALES_CANCELLATION_OVERRIDE', useValue: true},
    {provide: 'RGI_POSTSALES_EXCLUSION_OVERRIDE', useValue: true},
    {provide: 'RGI_POSTSALES_OPEN_OLD_AJS_CONSULTATION', useValue: false},
    {
      provide: RGI_RX_PORTAL_CARDS,
      useFactory: providePncPostsalesPortalOperations,
      deps: [PNC_POSTSALES_OPERATIONS],
      multi: true
    }
  ]
})

export class RgiPncPostsalesPortalModule {
  constructor(
    @Inject(RGI_RX_PORTAL_CONFIG) config: PortalConfig,
    @Inject('RGI_POSTSALES_CANCELLATION_OVERRIDE') cancellationOverride: boolean,
    @Inject('RGI_POSTSALES_EXCLUSION_OVERRIDE') exclusionOverride: boolean,
    @Inject(PNC_POSTSALES_OPERATIONS) operations: PostSalesOperation[][]
  ) {
    const ops: { type: string, code: string | null }[] = flatten(operations).map(operation => {
      return {type: operation.type, code: operation.operationCode};
    });
    downgradePncPostsalesService(config);
    manageNewCardInPassPortal(config, ops, cancellationOverride, exclusionOverride);
  }
}


import {Component, Input, OnInit} from '@angular/core';
import {RgiRxSemanticStatus} from '@rgi/rx';

@Component({
  selector: 'rgi-rx-badge',
  template: `
    <ng-content></ng-content>`,
  host: {
    class: 'rgi-ui-badge-rounded-pill',
    '[class.rgi-ui-default]': 'color === "default"',
    '[class.rgi-ui-primary]': 'color === "primary"',
    '[class.rgi-ui-secondary]': 'color === "secondary"',
    '[class.rgi-ui-warning]': 'color === "warning"',
    '[class.rgi-ui-danger]': 'color === "danger"',
    '[class.rgi-ui-info]': 'color === "info"',
    '[class.rgi-ui-success]': 'color === "success"',
  }
})
export class RgiRxBadgeComponent {

  private _color: RgiRxSemanticStatus | string = 'default';

  @Input() get color(): | RgiRxSemanticStatus | string {
    return this._color;
  }

  set color(value: RgiRxSemanticStatus | string) {
    this._color = value;
  }

}

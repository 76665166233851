import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {CardComponentService, CardComponent, SessionService} from '@rgi/digital-claims-common-angular';
import {CompensationUtilityService} from '../compensation-utility.service';
import {
  TableSchema, RgiRxDataTableDataSource,
  RgiRxDatatableRowAction,
  RgiRxDatatableSelectionChange
} from '@rgi/rx/ui';
import {CompensationEntity} from '../../new-payment/domain/compensation-entity';
import {ExpertDetailsComponent} from '../../experts/expert-details/expert-details.component';
import {RoutingService} from '@rgi/portal-ng-core';
import {CompensationDiaryComponent} from '../compensation-diary/compensation-diary.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AutosoftCallsComponent} from '../../autosoft-board/autosoft-calls/autosoft-calls.component';
import {AutosoftSearchComponent} from '../../autosoft-board/autosoft-search/autosoft-search.component';
import {CompensationDiaryNoteEntity} from '../dto/compensation-diary-note-entity';
import {uuidv4} from '@rgi/rx';
import {PaymentEntity} from '../../new-payment/domain/payment-entity';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-compensation-board',
  templateUrl: './compensation-board.component.html',
  styleUrls: ['./compensation-board.component.scss']
})
export class CompensationBoardComponent extends CardComponent implements OnInit {

  public routes: any = null;
  public diaryEnabled: boolean = false;
  compensations: CompensationEntity[];
  // schema for rgirxdatatable
  schema: TableSchema = null;


  constructor(
    cardComponentService: CardComponentService,
    private utilityService: CompensationUtilityService,
    private sessionServiceComm: SessionService,
    private routingService: RoutingService,
    private activeModal: NgbActiveModal,
    public translateService: RgiRxTranslationService,
    @Inject('authService') private authService,
    @Inject('sessionService') private session: any,
    @Inject('eventService') private eventService: any,
    @Inject('claimsFinderIntegrationPassService') private claimsFinderIntegrationPassService: any,
    @Inject('cardPassService') private cardPassService: any,
    @Inject('compensationDiaryComponent') compensationDiaryComponent,
  ) {
    super();
    this.routes = {
      diary: compensationDiaryComponent
    };
  }

  @Input()
  card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();


  ngOnInit() {
    this.compensations = this.utilityService.getCompensations();
    // console.log('inside board : ', this.compensations, this.utilityService.getParty(), ' - ', this.utilityService.getClaim());

    const listFunz = this.authService.getOperator().enabledFeatures;
    if (listFunz && listFunz.indexOf('SXRVCS') >= 0) {
      this.diaryEnabled = true;
    }

    // diary button in table is determined here
    // if (this.diaryEnabled) {
      const obsNumber$ = this.translateService.translate('_CLAIMS_._NUMBER'); 
      const obsType$ = this.translateService.translate('_CLAIMS_._TYPE'); 
      const obsState$ = this.translateService.translate('_CLAIMS_._STATE');
      const obsAmount$ = this.translateService.translate('_CLAIMS_._AMOUNT');
      const obsQuotaAm$ = this.translateService.translate('_CLAIMS_._QUOTA_AMOUNT');
      const obsAmountActive$ = this.translateService.translate('_CLAIMS_._AMOUNT_ACTIVE');
      const obsAmountRes$ = this.translateService.translate('_CLAIMS_._AMOUNT_RESERVE_QUOTA');
      const obsOpeningDate$ = this.translateService.translate('_CLAIMS_._OPENING_DATE');
      const obsClosingDate$ = this.translateService.translate('_CLAIMS_._CLOSING_DATE');
      const obsRefusedDate$ = this.translateService.translate('_CLAIMS_._REFUSED_DATE');
      const obsActions$ = this.translateService.translate('_CLAIMS_._ACTIONS');
      combineLatest([
        obsNumber$,
        obsType$,
        obsState$,
        obsAmount$,
        obsQuotaAm$,
        obsAmountActive$,
        obsAmountRes$,
        obsOpeningDate$,
        obsClosingDate$,
        obsRefusedDate$,
        obsActions$,
      ]).subscribe(
          ([number, type, state, amount, quotaAm, amountActive, amountRes, openingDate, closingDate, refusedDate, actions]) => {
    this.schema = {
      header: ['number', 'type', 'state', 'amount', 'amountQuota',
        'amountActive', 'activeReserveQuota', 'openingDate',
        'closingDate', 'refusedDate', 'action'],
      rows: [
        {
          name: 'number', title: number,
        },
        {
          name: 'type', title: type,
        },
        {
          name: 'state', title: state
        },
        {
          name: 'amount', title: amount, // importo
        },
        {
          name: 'amountQuota', title: quotaAm, // importo quota
        },
        {
          name: 'amountActive', title: amountActive, // riserva attiva
        },
        {
          name: 'activeReserveQuota', title: amountRes, // riserva attiva quota
        },
        {
          name: 'openingDate', title: openingDate,
        },
        {
          name: 'closingDate', title: closingDate,
        },
        {
          name: 'refusedDate', title: refusedDate,
        },
        {
          name: 'action',
          title: actions,
          styleClass: 'compensation-datatable-cell-actions',
          actions: [
            {
              name: 'EDIT',
              styleClass: 'rgi-ui-btn rgifont rgi-pencil iconDT pull-right',
            },
            // diary functionality enabled here
            this.diaryEnabled ?
              {
                name: 'NOTES',
                styleClass: 'rgi-ui-btn rgifont rgi-cruscotto_check_unico iconDT pull-right',
              } : {name: '', styleClass: ''}
          ],
          description: 'Actions for each compensation' // a description of the field
        }

      ]

    };
          }
  )
  

    /* } else {
       this.schema = {
         header: ['number', 'type', 'state', 'amount', 'amountQuota',
           'amountActive', 'activeReserveQuota', 'openingDate',
           'closingDate', 'refusedDate'],
         rows: [
           {
             name: 'number', title: this.translate.instant('Number'),
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'type', title: this.translate.instant('Type'),
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'state', title: this.translate.instant('State'),
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'amount', title: this.translate.instant('Amount'), // importo
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'amountQuota', title: this.translate.instant('Quota amount'), // importo quota
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'amountActive', title: this.translate.instant('Active amount'), // riserva attiva
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'activeReserveQuota', title: this.translate.instant('Active reserve quota'), // riserva attiva quota
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'openingDate', title: this.translate.instant('Opening date'),
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'closingDate', title: this.translate.instant('Closing date'),
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'refusedDate', title: this.translate.instant('Refused date'),
             format: {
               pipe: 'translate'
             }
           },
           {
             name: 'action',
             title: 'Actions',
             styleClass: 'compensation-datatable-cell-actions',
             actions: [ // an action row is used to display action buttons instead of text. Can have many
               {
                 name: 'EDIT', // the event name that is fired upon click,
                 styleClass: 'rgi-ui-btn rgifont rgi-download pull-right',
               },
             ],
             description: 'Actions for each compensation' // a description of the field
           }

         ]

       };
     }*/
  }

  closeModal(event: any) {
    /*    this.activeModal.close();
        this.utilityService.getModal().close();
        this.utilityService.getModal().dismiss();
        this.utilityService.getEventEmitter().emit('chiusuraModale');
        event.stopPropagation();
        this.eventPropagation.emit('chiusuraModale');
        return false;*/
    this.utilityService.getEventEmitter().emit('chiusuraModale');
    event.stopPropagation();
    return false;
  }

  onActionDataTable($event: RgiRxDatatableRowAction<any>) {
    const type = $event.name;
    if (type === 'EDIT') {

      const act = {
        extension: null,
        jumpCommand: 'compensationRecoveryManagementClaim',
        menu: null,
      };

      const filter = {
        claimId: this.utilityService.getClaim().idClaim,
        // compensationNumber: 1,
        section: 'actorActions',
        jumpFunction: 'funcGestioneRecuperi'
      };
      const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
      // close modal
      this.utilityService.getEventEmitter().emit('chiusuraModale');
      this.eventPropagation.emit('chiusuraModale');
      // jump
      // createJump(card: any, objIntegrationPass: any, title: any, route: any, parent: any, callBackFunction?: any): void;
      this.cardPassService.createJump(this.card, objIntegrationPass, 'Gestione rivalse', 'action', this.card.name);
    } else if (type === 'NOTES') {
      this.utilityService.setSelectedCompensation($event.row);
      // perform query
      const tmpList: CompensationDiaryNoteEntity[] = [];
      this.eventService.broadcastEvent('start-loader');
      let i = 0;
      this.utilityService.getCompensationDiaryNotes().subscribe(res => {
        // console.log(res);
        res.forEach(o => {
          // angularjs component does not provide the idCompensation required for the 'add note' rest service.
          // if there are NO previous notes, there will be a single element in the array, with user = 'NO_NOTES'
          // and idCompensation with a defined value
          if (o.user === 'NO_NOTES') {
            const comp = this.utilityService.getSelectedCompensation();
            comp.idCompensation = o.idCompensation;
            this.utilityService.setSelectedCompensation(comp);
            return; // return is equivalent to continue in foreach
          } else if (o.user !== 'NO_NOTES' && i === 0) {
            i++;
            const comp = this.utilityService.getSelectedCompensation();
            comp.idCompensation = o.idCompensation;
            this.utilityService.setSelectedCompensation(comp);
          }
          const tmpNote: CompensationDiaryNoteEntity = new CompensationDiaryNoteEntity();
          tmpNote.user = o.user;
          tmpNote.text = o.noteText;
          tmpNote.datetime = o.datetime;
          tmpNote.idCompensation = o.idCompensation;
          tmpList.push(tmpNote);
        });

        this.utilityService.setSelectedCompensationDiaryNotes(tmpList);
        this.routingService.loadComponent(this.routes.diary);
        (this.routingService.componentRef.instance as CompensationDiaryComponent).card = this.card;
        this.eventService.broadcastEvent('stop-loader');
      });
    }
  }

}

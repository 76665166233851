<rgi-rx-expansion-panel *ngIf="clauses.length > 0" [expanded]="false" color="default">

  <rgi-rx-expansion-panel-header>
    <span rgi-rx-expansion-panel-label translate>CONTRACT_CONSULTATION.CLAUSES</span>
  </rgi-rx-expansion-panel-header>

  <div class="action-container" *ngFor="let clause of clauses">
    <div class="rgi-ui-col rgi-ui-grid-1">
      <div class="rgi-ui-col">
        <span class="rgi-ui-text-5">{{clause?.clause?.description ? clause?.clause?.description : "-"}}</span>
        <span class="rgi-ui-text-5"> : </span>
        <span class="rgi-ui-title-3">{{clause?.text ? clause?.text : "-"}}</span>
      </div>
    </div>
  </div>

</rgi-rx-expansion-panel>

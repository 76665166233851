import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import {delay, distinctUntilChanged, map, mergeMap, take} from 'rxjs/operators';
import {AcitvityPacket} from '../../../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {
  ReIssueAssetStateManagerService
} from '../../../re-issue-state-manager/re-issue-asset-state-manager.service';
import {ModalService} from '@rgi/rx/ui';
import {ReIssueMsgData} from '../../../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import {ReIssueMsgModalComponent} from '../../../re-issue-msg-modal/re-issue-msg-modal.component';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ReIssueAssetState} from '../../../re-issue-state-manager/re-issue-asset.state';

@Component({
  selector: 're-issue-asset-activity',
  templateUrl: './re-issue-asset-activity.component.html',
  styleUrls: ['./re-issue-asset-activity.component.css']
})
export class ReIssueAssetActivityComponent implements AfterContentInit, OnInit, OnChanges, OnDestroy {

  @Input() activityPacket: AcitvityPacket;

  @Input() readonly assetKey: string;

  @Input() readonly isSecondary: boolean;

  @Input() readonly isRequired: boolean;

  @Input() readonly isDisabled: boolean;

  @Input() readonly isBusy: boolean;

  @Output() formStateEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() formValidEmitter: EventEmitter<any> = new EventEmitter<any>();

  filteredOptions: Observable<{ code: string; description: string }[]>;
  options = [];
  subscription: Subscription = new Subscription();
  activitiesForm: UntypedFormGroup;
  searchForm: UntypedFormGroup;
  msgData: string;
  minChar: boolean;
  searchDisabled: boolean;
  byDescription: boolean;
  key: string;

  lvl1: string;
  lvl2: string;
  lvl3: string;
  lvl4: string;
  levels: any[] = [];

  constructor(
    public stateManager: ReIssueAssetStateManagerService<ReIssueAssetState>,
    protected modalService: ModalService,
    private translateService: RgiRxTranslationService,
  ) {
  }

  ngOnInit() {
    this.key = this.assetKey.substring(this.assetKey.length, this.assetKey.length - 8);
    this.filteredOptions = this.filter('');
    this.searchDisabled = true;
    this.lvl1 = this.isSecondary ? this.activityPacket.labels.lvl1 + 'Sec' + this.key : this.activityPacket.labels.lvl1 + this.key;
    this.lvl2 = this.isSecondary ? this.activityPacket.labels.lvl2 + 'Sec' + this.key : this.activityPacket.labels.lvl2 + this.key;
    this.lvl3 = this.isSecondary ? this.activityPacket.labels.lvl3 + 'Sec' + this.key : this.activityPacket.labels.lvl3 + this.key;
    this.lvl4 = this.isSecondary ? this.activityPacket.labels.lvl4 + 'Sec' + this.key : this.activityPacket.labels.lvl4 + this.key;
    this.levels.push(this.lvl1, this.lvl2, this.lvl3, this.lvl4);
  }

  ngAfterContentInit() {
    this.initializeSearchForm();
    this.initializeActivityForm();
    this.checkForNullValues();
    if (this.isDisabled && this.activityPacket.lvl1 == null && this.isSecondary) {
      this.activitiesForm.controls[this.lvl1].disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeActivityForm();
    this.initializeSearchForm();
    this.checkForNullValues();
    this.validateForm();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initializeActivityForm() {
    this.subscription.unsubscribe();

    this.activitiesForm = new UntypedFormGroup({});

    this.activitiesForm.addControl(
      this.lvl1, new UntypedFormControl({
        value: this.activityPacket.lvl1 != null ? this.activityPacket.lvl1.code : null,
        disabled: this.isBusy && !this.isSecondary || this.isDisabled && this.isSecondary
      }, this.isRequired ? Validators.required : null)
    );

    this.activitiesForm.addControl(
      this.lvl2, new UntypedFormControl({
        value: this.activityPacket.lvl2 != null ? this.activityPacket.lvl2.code : null,
        disabled: this.activityPacket.values.lvl2.length <= 0 || this.isBusy
      }, this.activityPacket.values.lvl2.length > 0 ? Validators.required : null)
    );

    this.activitiesForm.addControl(
      this.lvl3, new UntypedFormControl({
        value: this.activityPacket.lvl3 != null ? this.activityPacket.lvl3.code : null,
        disabled: this.activityPacket.values.lvl3.length <= 0 || this.isBusy
      }, this.activityPacket.values.lvl3.length > 0 ? Validators.required : null),
    );

    if (this.activityPacket.labels.lvl4 != null) {
      this.activitiesForm.addControl(
        this.lvl4, new UntypedFormControl({
          value: this.activityPacket.lvl4 != null ? this.activityPacket.lvl4.code : null,
          disabled: this.activityPacket.values.lvl4.length <= 0 || this.isBusy
        }, this.activityPacket.values.lvl4.length > 0 ? Validators.required : null),
      );
    }


    const listener = Object.keys(this.activitiesForm.controls).forEach((controlName) => {
      const control = this.activitiesForm.controls[controlName];
      control.valueChanges.pipe(distinctUntilChanged())
        .subscribe(value => {
          this.onActivityChange(controlName, value);
        });
    });

    this.subscription.add(listener);

  }

  initializeSearchForm() {

    this.searchForm = new UntypedFormGroup({});

    this.searchForm.addControl(
      'radioButton' + this.key,
      new UntypedFormControl({value: 'code', disabled: false}),
    );
    this.searchForm.addControl(
      'autoComplete' + this.key,
      new UntypedFormControl({value: '', disabled: this.isDisabled  || this.isBusy}, Validators.minLength(3)),
    );
    this.searchForm.addControl(
      'codeSearch' + this.key,
      new UntypedFormControl({value: '', disabled: this.isDisabled  || this.isBusy}, Validators.pattern('^[0-9]*$')),
    );
  }

  onAutoCompleteChange(event: any) {
    const data = typeof event === 'object' ? event.value : event.toLowerCase();
    if (data.length >= 3) {
      this.minChar = true;
      this.stateManager.getAutocomplete(data).subscribe(val => {
        this.options = !val.length ? ['No results...'] : val;
        this.filteredOptions = this.filter('');
      });
    } else {
      this.searchDisabled = true;
      this.minChar = false;
      this.options = [];
      this.filteredOptions = this.filter('');
    }
  }

  onAutoCompleteValueChange(event: any) {
    const data = typeof event === 'object' ? event.value : event.toLowerCase();
    if (data.length >= 3 && data != 'No results...') {
      this.searchDisabled = false;
    } else {
      this.searchDisabled = true;
    }
  }

  onClickBtn() {
    const state = this.stateManager.getCurrentState();
    this.stateManager.searchActivity(this.byDescription ? this.searchForm.controls['autoComplete' + this.key].value : this.searchForm.controls['codeSearch' + this.key].value, this.byDescription, this.isSecondary).subscribe(actvityPacket => {
      if (actvityPacket.lvl1) {
        this.stateManager.updateActivities(actvityPacket, this.assetKey, this.isSecondary).pipe(take(1)).subscribe(
          activities => {
            this.activityPacket = activities;
            this.initializeActivityForm();
            this.checkForNullValues();
            this.validateForm();
          }
        );
      } else {
        this.openModal();
      }
    });
  }

  onActivityChange(key: any, val: any) {
    if (key && val) {
      const state = this.stateManager.getCurrentState();
      this.stateManager.updateActivities(this.requestActivity(key, this.activityPacket, val), this.assetKey, this.isSecondary).pipe(take(1)).subscribe(
        activities => {
          this.activityPacket = activities;
          this.initializeActivityForm();
          this.checkForNullValues();
          this.validateForm();
        }
      );
    }
  }

  requestActivity(key: string, activityPacket: AcitvityPacket, val: any) {
    switch (key) {
      case(this.lvl1):
        activityPacket.lvl2 = activityPacket.lvl3 = activityPacket.lvl4 = null;
        activityPacket.values.lvl2 = activityPacket.values.lvl3 = activityPacket.values.lvl4 = [];
        activityPacket.lvl1 = activityPacket.values.lvl1.find(x => x.code === val);
        break;
      case(this.lvl2):
        activityPacket.lvl3 = activityPacket.lvl4 = null;
        activityPacket.values.lvl3 = activityPacket.values.lvl4 = [];
        activityPacket.lvl2 = activityPacket.values.lvl2.find(x => x.code === val);
        break;
      case(this.lvl3):
        activityPacket.lvl4 = null;
        activityPacket.values.lvl4 = [];
        activityPacket.lvl3 = activityPacket.values.lvl3.find(x => x.code === val);
        break;
      case(this.lvl4):
        activityPacket.lvl4 = activityPacket.values.lvl4.find(x => x.code === val);
        break;
    }
    return activityPacket;
  }

  private validateForm() {
    if (this.activitiesForm.touched && !this.activitiesForm.valid) {
      this.activitiesForm.markAsDirty();
      this.activitiesForm.markAsTouched();
      this.activitiesForm.markAllAsTouched();
    }
    this.formValidEmitter.emit(!this.activitiesForm.invalid);
  }


  checkForNullValues() {
    if (this.isSecondary) {
      this.formStateEmitter.emit(this.activitiesForm.controls[this.lvl1].value != null);
    } else {
      let formIsFilled = true;
      Object.keys(this.activitiesForm.controls).forEach((controlName) => {
        const control = this.activitiesForm.controls[controlName];
        if (control.value == null) {
          formIsFilled = false;
        }
      });
      this.formStateEmitter.emit(formIsFilled);
    }
  }

  openModal() {
    const msgData = new ReIssueMsgData();
    const msg = of(['RE_ISSUE.ACTIVITY_NOT_FOUND']);
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.msgData = next [0];
    }).unsubscribe();
    msgData.msg = this.msgData;
    msgData.showButtonConfirm = false;
    msgData.showButtonContinue = false;
    const confirmationModal = this.modalService.openComponent(ReIssueMsgModalComponent, msgData);
    confirmationModal.modal.onClose.subscribe(() => {
      this.searchForm.controls['codeSearch' + this.key].reset();
    });
  }

  private filter(data: any) {
    return combineLatest([of(this.options), of(data)])
      .pipe(
        delay(100),
        map(([v, d]) => v.filter(
          f => !d || (f.toLowerCase().includes(d.toLowerCase()))
        )),
      );
  }

  restore() {
    this.searchDisabled = true;
    this.options = [];
    this.filteredOptions = of(this.options);
  }

  onAdvancedModeChange() {
    this.searchDisabled = true;
    this.searchForm.controls['codeSearch' + this.key].reset();
    this.searchForm.controls['autoComplete' + this.key].reset();
    this.byDescription = this.searchForm.controls['radioButton' + this.key].value === 'description';
  }

  onCodeSearchChange(val: any) {
    this.searchDisabled = val ? val.length <= 0 : true;
  }


  resetForm() {
    this.stateManager.deleteActivity(this.isSecondary).pipe(take(1)).subscribe(
      activities => {
        this.activityPacket = activities;
        this.activitiesForm.reset();
        this.initializeActivityForm();
        this.checkForNullValues();
        this.validateForm();
      }
    );
  }
}

<!--<claims-ui-modal-dialog-demo style="display: none"  (modalDeleteEmitter)="deleteEvent($event)" ></claims-ui-modal-dialog-demo>-->
<div class="dragged">
  <form [formGroup]="detailForm">
    <div class="form-group">
      <div class="row title-header" style="padding:5px;">
        <div class="col-xs-12 col-md-12">
          <div class="rgi-ui-title-2 rgi-ui-info">
            {{ '_CLAIMS_._TRUSTEE_DATA' | translate }}
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: 2vh;">
        <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
          <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._SUBJECT' | translate }}</label>
          <input style="height: 40px; width: 100%;" *ngIf="expert.name.length>0 && expert.surname.length>0"
                 value="{{expert.name}} {{expert.surname}}"
                 disabled="true"  formControlName="subjectDenomination">
          <input style="height: 40px; width: 100%;" *ngIf="expert.subjectBusinessName.length>0" value="{{expert.subjectBusinessName}}"
                 disabled="true"
                  formControlName="subjectDenomination">
        </div>
      </div>

      <div class="row" style="margin-left: 2vh">
        <!--EXPERT TYPE-->
        <div class="col-xs-4" style="padding-left: 0">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._EXPERT_TYPE' | translate }}</label>
            <select [attr.disabled]="true" class="core-select form-control" id="expertType" name="expertType"
                    formControlName="expertType" attr.data-qa="expertType-input">
              <option value="{{expert.expertType}}">{{expert.expertTypeDescription}}</option>
            </select>
          </div>
        </div>
        <!--      ENROLLMENT CODE-->
        <div class="col-xs-4">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._ROSTER_REGISTRATION_CODE' | translate }}</label>
            <input style="height: 40px; width: 100%" type="text" [attr.disabled]="true"
                   value="{{expert.roleEnrollmentCode}}">
          </div>
        </div>

        <!--      ROLE ENROLLMENT DATE-->
        <div class="col-xs-4">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">

            <pnc-datepicker
              style="width: 100%;"
              label="{{ '_CLAIMS_._COURT_REGISTRATION_DATE' | translate }}"
              disabled="true"
              value="{{expert.roleEnrollmentDate!=='0' ? expert.roleEnrollmentDate : ''}}"
            >
            </pnc-datepicker>
          </div>
        </div>
      </div>

      <!--      TITLE SEDE FISCALE-->
      <div class="row title-header" style="padding:5px;">
        <div class="col-xs-12 col-md-12">
          <div class="rgi-ui-title-2 rgi-ui-info">
            {{ '_CLAIMS_._TAX_OFFICE' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div style="padding:5px;">
          <div class="col-xs-7 col-md-7">
            <div class="col-xs-4">
              <label ng-bind="label">
                {{ '_CLAIMS_._ADDRESS' | translate }}
              </label>
            </div>

            <div class="col-xs-3 col-md-3">
              <label ng-bind="label">
                {{ expert.fiscalAddress }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!--      TITLE LINKED EXPERTS-->
      <div class="row title-header" style="padding:5px;">
        <div class="col-xs-12 col-md-12"
             style="display: flex; align-items: center; margin-bottom: 2vh; margin-top: 2vh;">
          <!--         title-->
          <div class="rgi-ui-title-2 rgi-ui-info">
            {{ '_CLAIMS_._ASSOCIATED_TRUSTEES' | translate }}
          </div>
          <!--          NEW LINK BUTTON-->
          <div style="display: flex; justify-content: center">
            <!--      modalSearchExpertLink.open();     -->
            <button class="btn-actions causal new"
                    (click)="openModalSearchExpertNewLink()">
                    {{ '_CLAIMS_._BUTTONS_._NEW' | translate }}
            </button>
          </div>

        </div>
      </div>

      <!--      LINKED EXPERTS TABLE-->
      <!--      15 10 10 35 15-->
      <div style="text-align:center;font-size: 30px">
      <rgi-rx-snackbar style="text-align:center;font-size: 30px; font-weight: bolder;" tag="tagSnackbar" ></rgi-rx-snackbar >
      </div>
      <div *ngIf="this.linkedExperts && this.linkedExperts.length > 0">
        <!--  EXPERTS LIST-->

        <table class="table-responsive table coreDT" style="margin-top:1vh;">
          <thead>
          <tr class="head-result thDataTable">
            <td style="max-width: 10%;">
              <span attr.data-qa="experts-code-label">{{ '_CLAIMS_._CODE' | translate }}</span>
            </td>

            <td style="max-width: 20%;">
              <span attr.data-qa="experts-name-label">{{ '_CLAIMS_._DENOMINATION' | translate }}</span>
            </td>

            <td style="width: 35%;">
              <span attr.data-qa="experts-subject-identity-label"> {{ '_CLAIMS_._SUBJECT_IDENTITY' | translate }}</span>
            </td>

            <td style="max-width: 15%;">
              <span attr.data-qa="experts-expert-type-label"> {{ '_CLAIMS_._EXPERT_TYPE' | translate }}</span>
            </td>
            <td style="max-width: 10%;">
              <span attr.data-qa="experts-expert-type-provider-label">{{ '_CLAIMS_._TYPE_PROVIDER' | translate }}</span>
            </td>
            <td style="max-width: 10%;">
              <span><br/></span>
            </td>
          </tr>

          </thead>

          <tbody style="overflow: auto;">
          <tr *ngFor="let aRow of this.linkedExperts;
                    let even = even;
                    let odd = odd;
                    let expertIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
              class="table-row" attr.data-qa="autosoft-result-{{expertIndex}}">

            <td style="width:10%;">
              {{aRow.code}}
            </td>
            <td style="width:20%;">
              {{aRow.nameBySubjectType}}
            </td>
            <td style="width:35%;">
              {{aRow.subjectKeyWithDescription}}
            </td>
            <td style="width:15%;">
              {{aRow.expertTypeDescription}}
            </td>
            <td style="width:10%;">
              {{aRow.expertTypeProviderDescription}}
            </td>
            <td style="width: 10%;">
              <!--       modalDeleteLink.open()       -->
<!--              onOpenModalDeleteLink(content);-->
              <button (click)="onOpenModalDeleteLink(); this.selectedExpertIdLinkToDelete = (aRow.id).toString()"><span class="glyphicon glyphicon-trash" style="width:15px; height:10px;"></span></button>
            </td>


          </tr>
          </tbody>
        </table>
      </div>


      <!--      TITLE OFFICE LOCATION-->
      <div class="row title-header" style="padding:5px;">
        <div class="col-xs-12 col-md-12">
          <div class="rgi-ui-title-2 rgi-ui-info">
            {{ '_CLAIMS_._OFFICE_HEADQUARTERS' | translate }}
          </div>
        </div>
      </div>
      <!--      OFFICE COUNTRY + REGION + CITY ON SAME ROW-->
      <div class="row">
        <!--COUNTRY-->
        <div class="col-xs-2">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._COUNTRY' | translate }}</label>
            <select [attr.disabled]="true" class="core-select form-control" id="country" name="country"
                    formControlName="country" attr.data-qa="country-input">
              <option value="{{expert.country}}">{{expert.country}}</option>
            </select>
          </div>
        </div>
        <!--REGION-->
        <div class="col-xs-5">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._PROVINCE' | translate }}</label>
            <select [attr.disabled]="true" class="core-select form-control" id="region" name="region"
                    formControlName="region" attr.data-qa="region-input">
              <option value={{expert.region}}>{{expert.region.toUpperCase()}}</option>
            </select>
          </div>
        </div>
        <!--CITY-->
        <div class="col-xs-5">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._CITY' | translate }}</label>
            <select [attr.disabled]="true" class="core-select form-control" id="city" name="city"
                    formControlName="city" attr.data-qa="city-input">
              <option value="{{expert.city}}">
                {{(expert.city).toUpperCase()}}</option>
            </select>
          </div>
        </div>

      </div>

      <!--  TOPONYM + ADDRESS + houseNumber + ZIP CODE -->
      <div class="row">
        <!--TOPONYM-->
        <div class="col-xs-2">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._TOPONYM' | translate }}</label>
            <select [attr.disabled]="true" class="core-select form-control" id="toponym" name="toponym"
                    formControlName="toponym" attr.data-qa="toponym-input">
              <option value="{{expert.toponym}}">
                {{(expert.toponym).toUpperCase()}}</option>
            </select>
          </div>
        </div>
        <!--OFFICE ADDRESS-->
        <div class="col-xs-5">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._ADDRESS' | translate }}</label>
            <input style="height: 40px; width: 100%;" id="officeAddress" name="officeAddress"
                 value="{{expert.officeAddress.toUpperCase()}}" formControlName="officeAddress" attr.data-qa="officeAddress-input"
                 disabled="true"  >
           
          </div>
        </div>
        <!--HOUSE NUMBER-->
        <div class="col-xs-2">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._HOUSE_NUMBER' | translate }}</label>
            <input style="height: 40px; width: 100%;" id="houseNumber" name="houseNumber"
                 value="{{expert.houseNumber}}" formControlName="houseNumber" attr.data-qa="houseNumber-input"
                 disabled="true"  >
          </div>
        </div>
        <!--HOUSE NUMBER-->
        <div class="col-xs-3">
          <div class="rgi-ui-form-group rgi-ui-form-group-pristine rgi-ui-form-group-untouched">
            <label class="rgi-ui-form-control-label">{{ '_CLAIMS_._ZIP_CODE' | translate }}</label>
            <select [attr.disabled]="true" class="core-select form-control" id="zipCode" name="zipCode"
                    formControlName="zipCode" attr.data-qa="zipCode-input">
              <option value="{{expert.zipCode}}">
                {{expert.zipCode}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12" style="margin-top:1.5vh;">
          <button rgi-rx-button color="primary" style="width: 100%"
                  (click)="onExitDetailComponent()">{{'_CLAIMS_._BUTTONS_._BACK' | translate}} <span
            class="rgi-ui-icon-back-arrow2"></span></button>
        </div>
      </div>

    </div>

  </form>


  <!--MODAL SEARCH NEW EXPERT TO LINK-->
  <!--  <ng-template #contentSearchExpertLink>-->
  <div>
<!--    <rgi-rx-modal #modalSearchExpertLink>
      <rgi-rx-panel style="display: inline-block; margin-top:10%">
        <rgi-rx-panel-header [closeable]="true"
                             (onClose)="modalSearchExpertLink.close(); this.expertLinkedSearchList = []"></rgi-rx-panel-header>

        <form [formGroup]="searchForm" style="margin-bottom: 2vh;">
          <div class="form-group">
            &lt;!&ndash; COUNTRY &ndash;&gt;
            &lt;!&ndash;      entire country row is hidden if uma disabled&ndash;&gt;
            <div class="col-xs-3" *ngIf="isUmaUniqueKeyManagementEnabled">
              <label ng-bind="label">{{'Nation' | translate}}</label>
              <select class="core-select form-control" id="expert-search-country" name="experts-search-country"
                      formControlName="country"
                      attr.data-qa="experts-search-input">
                <option value=""></option>
                <option *ngFor="let country of this.formConfig.countries"
                        [value]="country.code">{{country.description}}</option>
              </select>
            </div>
            &lt;!&ndash; SUBJECT TYPE&ndash;&gt;
            <div class="col-xs-3">
              <label ng-bind="label">{{'Subject type' | translate}}</label>
              <select class="core-select form-control" id="expert-search-subjectType" name="experts-search-subjectType"
                      formControlName="subjectType"
                      attr.data-qa="experts-search-input">
                <option value=""></option>
                <option *ngFor="let type of this.formConfig.subjectTypes"
                        [value]="type.idObject">{{type.description}}</option>
              </select>
            </div>
            &lt;!&ndash;      EXPERT CODE&ndash;&gt;
            <div class="col-xs-3">
              <label ng-bind="label">{{ 'Code' | translate }} </label>

              <input type="text" class="form-control" name="code" formControlName="expertCode"
                     attr.data-qa="expertsearch-code-input">
            </div>

            &lt;!&ndash;      BUSINESS NAME (RAGIONE SOCIALE) &ndash;&gt;
            <div class="col-xs-3">
              <label ng-bind="label">{{ 'Business name' | translate }} </label>

              <input [attr.disabled]="!isLegalSubject ? true : null" type="text" class="form-control"
                     name="businessName"
                     formControlName="businessName"
                     attr.data-qa="expertsearch-businessName-input">
            </div>

            &lt;!&ndash;   NAME   &ndash;&gt;
            <div class="col-xs-3">
              <label ng-bind="label">{{ 'Name' | translate }} </label>

              <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control" name="name"
                     formControlName="name"
                     attr.data-qa="expertsearch-name-input">
            </div>
            &lt;!&ndash;   SURNAME&ndash;&gt;
            <div class="col-xs-3">
              <label ng-bind="label">{{ 'Surname' | translate }} </label>

              <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control" name="surname"
                     formControlName="surname"
                     attr.data-qa="expertsearch-surname-input">
            </div>


            &lt;!&ndash;      a priori show CF and P.Iva fields if uma is disabled&ndash;&gt;
            &lt;!&ndash;      CODICE FISCALE&ndash;&gt;
            <div *ngIf="!isUmaUniqueKeyManagementEnabled" class="col-xs-3">
              <label ng-bind="label">{{ 'Fiscal code' | translate }} </label>

              <input type="text" class="form-control" name="fiscalCode" formControlName="fiscalCode"
                     attr.data-qa="expertsearch-fiscalCode-input">
            </div>
            &lt;!&ndash;      PARTITA IVA &ndash;&gt;
            <div *ngIf="!isUmaUniqueKeyManagementEnabled" class="col-xs-3">
              <label ng-bind="label">{{ 'Vat Number' | translate }} </label>

              <input type="text" class="form-control" name="vatNumber" formControlName="vatNumber"
                     attr.data-qa="expertsearch-vatNumber-input">
            </div>

            &lt;!&ndash;      SHOW UMA FIELDS&ndash;&gt;
            &lt;!&ndash;  UMA fields shown only if enabled&ndash;&gt;
            <div *ngIf="isUmaUniqueKeyManagementEnabled" style="all:unset;">
              &lt;!&ndash;        loop through countries. only display currently selected country &ndash;&gt;
              <div *ngFor="let country of formConfig.countries; let countryIndex = index;">
                <div style="all:unset;" *ngIf="searchForm.value.country===country.code">
                  &lt;!&ndash;            display the 3 optional labels, only if they are defined &ndash;&gt;
                  &lt;!&ndash;          ngIf : row is shown only if the label has a value  &ndash;&gt;
                  &lt;!&ndash;            formControlName is a combination of COUNTRYCODE + G (legal or physical subject) + CKEY_NUMBER &ndash;&gt;
                  &lt;!&ndash;            CKEY 1&ndash;&gt;
                  <div class="col-xs-3"
                       *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey1 !==''">
                    <label
                      ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1']['labelCkey1']}} </label>
                    <input type="text" class="form-control"
                           name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey1' }}"
                           formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}"
                           attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}-input">
                  </div>
                  &lt;!&ndash;            CKEY 2&ndash;&gt;
                  <div class="col-xs-3"
                       *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2 !==''">
                    <label
                      ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2}} </label>
                    <input type="text" class="form-control"
                           name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey2' }}"
                           formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}"
                           attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}-input">
                  </div>
                  &lt;!&ndash;            CKEY 3&ndash;&gt;
                  <div class="col-xs-3"
                       *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3 !==''">
                    <label
                      ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3}} </label>
                    <input type="text" class="form-control"
                           name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey3' }}"
                           formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}"
                           attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}-input">
                  </div>
                </div>
              </div>
            </div>
            &lt;!&ndash;      EXPERT TYPE (TIPO FIDUCIARIO) &ndash;&gt;
            <div class="col-xs-3">
              <label ng-bind="label">{{'Expert type' | translate}}</label>
              <select rgiRxNativeSelect class="core-select form-control" id="expert-search-expertType"
                      name="experts-search-expertType" formControlName="expertType"
                      attr.data-qa="experts-search-expertType-input">
                <option value=""></option>
                <option *ngFor="let type of this.formConfig.expertTypes"
                        [value]="type.idObject">{{type.description}}</option>
              </select>
            </div>
            <div class="col-xs-12">
              <div class="form-group">
                <div class="alert alert-danger" style="font-size: 78%; margin-top:0.4em" *ngIf="showError" role="alert">
                  {{ 'Value at least one field between: name, surname, contractor fiscal code, VAT code, code independent expert' | translate }}
                </div>
              </div>
              <span *ngIf="showNoExpertsFound" class="col-sm-12 content-error validate-error"
                    attr.data-qa="expert-search-not-found">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{ 'No results.' | translate }}</span>
        </span>
            </div>
            <div class="col-xs-12"
                 style="margin-top:1em; margin-bottom: 2vh; display:flex; justify-content: space-between">
              <button class="rgi-ui-btn rgi-ui-btn-primary" style="width: 49.2%; justify-self: center"
                      (click)="onEmpty()">{{'EMPTY' | translate}}</button>
              <button class="rgi-ui-btn rgi-ui-btn-primary" style="width: 49.2%; justify-self: center"
                      (click)="onFind()">{{'FIND' | translate}}</button>
            </div>
          </div>
        </form>

        &lt;!&ndash; search results displayed here &ndash;&gt;
        <rgi-rx-datatable *ngIf="expertLinkedSearchList && expertLinkedSearchList.length>0" rgi-rx-qa="my-table"
                          qa-ctx="my-app-context" style="display: inline-block"
                          [data]="this.expertLinkedSearchList" [schema]="schema"
                          (onAction)="onActionClick($event)"
                          (select)="onSelectChange($event)"
                          [selectable]="true" [pageOptions]="[5]">
        </rgi-rx-datatable>

        &lt;!&ndash;SAVE BUTTON&ndash;&gt;
        <div class="col-xs-12" *ngIf="selections.size>0" style="margin-top: 2vh; margin-bottom: 2vh;">
          <div class="col-xs-4"></div>
          <div class="col-xs-4">
            <button style="width: 100%" rgi-rx-button (click)="onSaveNewExpertLink()" color="primary"
                    [disabled]="!selections.size">{{'Save' | translate}}</button>
          </div>
          <div class="col-xs-4"></div>
        </div>
      </rgi-rx-panel>
    </rgi-rx-modal>-->

  </div>



</div>

import {EventEmitter, Injectable} from '@angular/core';
import {AuthorizationsStates} from '../resources/constants/authorizations-states';
import {AuthorizationsActions} from '../resources/constants/authorizations-actions';
import {AuthorizationsSearchService} from './authorizations-search.service';
import {AuthorizationsRoutingService} from '../routing/authorizations-routing.service';


@Injectable({
  providedIn: 'root'
})
export class AuthorizationsStatesService {

  public currentState: AuthorizationsStates = AuthorizationsStates.START;
  public previousState: AuthorizationsStates;

  constructor(protected searchService: AuthorizationsSearchService,
              private routingService: AuthorizationsRoutingService) {
  }

  setCurrentState(state: AuthorizationsStates) {
    this.currentState = state;
  }

  nextState(routeId: any, navigation: EventEmitter<any>, action?: AuthorizationsActions) {
    this.routingService.register(navigation);

    let nextState: AuthorizationsStates;

    switch (this.currentState) {
      case AuthorizationsStates.START: {
        if (action === AuthorizationsActions.FIND) {
          const auths = this.searchService.getFilteredAuthorizations();

          if (auths && auths.length === 1) {
            nextState = AuthorizationsStates.DETAIL;
          } else {
            nextState = AuthorizationsStates.LIST;
          }
        }
        break;
      }
      case AuthorizationsStates.LIST: {
        if (action === AuthorizationsActions.DETAIL) {
          nextState = AuthorizationsStates.DETAIL;
        }
        break;
      }
      case AuthorizationsStates.DETAIL: {
        if (action === AuthorizationsActions.BACK) {
          nextState = AuthorizationsStates.LIST;
        }
        break;
      }
      default: {
        break;
      }
    }

    if (nextState) {
      this.setPreviousState(this.currentState);
      this.updateState(routeId, nextState);
    }
  }

  resetState(routeId: any) {
    this.updateState(routeId, AuthorizationsStates.START);
  }

  public updateState(routeId: any, state: AuthorizationsStates) {
    this.currentState = state;
    this.routingService.forward(routeId, this.currentState);
  }

  public setPreviousState(previousState: AuthorizationsStates) {
    this.previousState = previousState;
  }

}

import { Injectable, Inject, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterPolicy } from './dto/filter-policy';
import { retry } from 'rxjs/operators';
import { ExternalPolicy } from './dto/external-policy';

@Injectable({
  providedIn: 'root'
})
export class ExternalPortfolioService {

  private baseApiUrl: string;
  private domain: string;
  private urlexternalportfolio = '/claims/externalportfolio';
  private urlexternalportfolioNew = '/claims/externalportfolioNew';
  private urlexternalportfolioModify = '/claims/externalportfolioModify';
  private urlexternalportfolioDelete = '/claims/externalportfolioDelete';
  private urlextportfolioUpdatePolicyNumber = '/claims/extportfolioUpdatePolicyNumber';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
    ) {
   this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  setExternalPolicies(externalPortfolioPolicies: ExternalPolicy[]) {
    // this.externalPortfolioPolicies = externalPortfolioPolicies;
  }

  updatePolicyNumber(policyNumberOld: string, policyNumberNew: string, policyId: string) {
    const queryString = '?policyNumberOld=' + policyNumberOld + '&policyNumberNew=' + policyNumberNew + '&idPolicyNew=' + policyId;
    return this.httpClient.put(this.baseApiUrl + this.urlextportfolioUpdatePolicyNumber + queryString, null);
  }

  getPolicies(finder: FilterPolicy) {

    let queryString = '?';
    if (finder.company) {
      queryString += 'company=' + finder.company + '&';
    }
    if (finder.policyNumber) {
      queryString += 'policyNumber=' + finder.policyNumber + '&';
    }
    if (finder.lossDate) {
      queryString += 'lossDate=' + JSON.stringify(finder.lossDate) + '&';
    }
    if (finder.licensePlate) {
      queryString += 'licensePlate=' + finder.licensePlate + '&';
    }
    if (finder.surBusinessName) {
      queryString += 'surname=' + finder.surBusinessName + '&';
    }
    if (finder.firstName) {
      queryString += 'name=' + finder.firstName + '&';
    }
    if (finder.fiscalCodeVat) {
      queryString += 'codFiscPartIva=' + finder.fiscalCodeVat + '&';
    }
    if (finder.typeAsset) {
      queryString += 'typeAsset=' + finder.typeAsset + '&';
    }
    if (finder.insuredAsset) {
      queryString += 'insuredAsset=' + finder.insuredAsset + '&';
    }

    queryString += 'searchType=CONSULTAZIONE';

    return this.httpClient.get(this.baseApiUrl + this.urlexternalportfolio + queryString);
  }

  newPolicy(policy: ExternalPolicy) {
    return this.httpClient.post(this.baseApiUrl + this.urlexternalportfolioNew, policy);
  }

  modifyPolicy(policy: ExternalPolicy) {
    return this.httpClient.put(this.baseApiUrl + this.urlexternalportfolioModify, policy);
  }

  deletePolicy(policyId: string) {
    const queryString = '?policyId=' + policyId;
    return this.httpClient.put(this.baseApiUrl + this.urlexternalportfolioDelete + queryString, null);
  }


  getExternalPolicies(): ExternalPolicy[] {
      return null;
  }

  clearExternalPolicies(): void {
  }

  getCompanyList() {
    return this.httpClient.get(this.baseApiUrl + '/claims/companylist');
  }

  getProvincesList() {
    return this.httpClient.get(this.baseApiUrl + '/claims/provinceslist');
  }

  getTownListByProvince(siglaProvincia: string) {
    return this.httpClient.get(this.baseApiUrl + '/claims/townlist?siglaProvincia=' + siglaProvincia);
  }

  getCountriesList() {
    return this.httpClient.get(this.baseApiUrl + '/claims/countrieslist');
  }
}


import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SurveyVersionI } from '@rgi/ng-passpropro-survey/lib/models/survey-version';
import { QuestionnaireFlatI } from '@rgi/ng-passpro';
import { Questionnaire } from '../model/questionnaire.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyVersionService {

  constructor(private datePipe: DatePipe) { }

  static makeEmptySurveyVersion(): SurveyVersionI {  // todo move to dedicated service
    return {
      survey: {
        questionnaire: {
          questionnaireType: {}
        }
      } as any,
      dateCreated: null,
      versionNumber: 1,
      lastVersion: {}
    };
  }

  public makeSurveyVersion(questionnaire: Questionnaire, questFlat: QuestionnaireFlatI) {
    const surveyVersion = { ...SurveyVersionService.makeEmptySurveyVersion() };
    const questCopy =  {...questionnaire};
    delete questCopy.compiled;
    delete questCopy.mandatory;
    delete questCopy.score;
    if (questionnaire.idSaved) {
      surveyVersion.survey.id = questionnaire.idSaved;
      delete questCopy.idSaved;
    }
    surveyVersion.survey.questionnaire = questCopy;
    surveyVersion.lastVersion.questionnaire = questFlat;
    surveyVersion.dateCreated = this.datePipe.transform(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSZ'); // todo global const
    surveyVersion.lastVersion.dateCreated = this.datePipe.transform(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSZ');  // todo global const
    return surveyVersion;
  }

}

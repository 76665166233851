<div class="container-fluid">
    <rgi-rx-datatable [sortable]="false" [data]="this.tableData" [schema]="schema" [sortHeader]="true" [selectable]="false"
        (onAction)="onActionClick($event)" [pageOptions]="[5,10,20]"/>

    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button class="btn btn-warning pull-right" (click)="this.aggiungi()">
                {{'_CLAIMS_._BUTTONS_._ADD' | translate}}
            </button>
        </div>
    </div>
</div>
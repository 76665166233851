import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnviromentsService } from './enviroments.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { _DOCUMENT_ } from '../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(protected environment: EnviromentsService,
              private httpClient: HttpClient) {
  }

  public getDocumentList(idPolicy: string, operationCode: string, documentCodes: string[],
                         userCode: string, printMode: number): Observable<any> {
    const filterReq = {
      documentsFilter: {
        idPolicy,
        operationCode,
        documentCodes,
        userCode,
        printMode
      },
      saveDocuments: false,
      generateDocuments: false
    };
    return this.httpClient.post(this.environment.baseApiPassURL + '/ptflife/proposal/documents', {filter: filterReq}, {});
  }

  downloadDocument(idMovement: string, documentCode: string): Observable<{fileUrl: string, code: string}> {
    if (!idMovement) {
      console.error('Movement id null');
      return of(null);
    }
    const body = [{
      code: documentCode
    }];
    const url = `${this.environment.baseApiURL}/v2/life/movement/${idMovement}/documents`;
    return this.httpClient.put<{fileUrl: string, code: string}>(url, body)
    .pipe(
      map(response => this.addExtension(response[0]))
    );
  }

  addExtension(response: {fileUrl: string; code: string}): {fileUrl: string; code: string} {
    const arrayUrl =  response.fileUrl.split('/');
    const fileName = arrayUrl.pop();
    const arrayfilename = fileName.split('.');
    if (arrayfilename.length === 1) {
      return {
        fileUrl: `${response.fileUrl}.${_DOCUMENT_.EXTENSION_PDF}`,
        code: response.code
      };
    }
    return response;
  }

  callDocument(documentUrl): Observable<any> {
    return this.httpClient.get(`${this.environment.baseApiURL}/system${documentUrl}`, {responseType: 'arraybuffer'});
  }

  getDocumentContentTypeByURL(url) {
    const arrayUrl =  url.split('/');
    const fileName = arrayUrl.pop();
    const arrayfilename = fileName.split('.');
    const extension = arrayfilename.pop();
    switch (extension) {
        case _DOCUMENT_.EXTENSION_PDF:
            return 'application/pdf';
        case _DOCUMENT_.EXTENSION_XLS:
            return 'application/vnd.ms-excel';
        case _DOCUMENT_.EXTENSION_XLSX:
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case _DOCUMENT_.EXTENSION_CSV:
            return 'text/csv';
        case _DOCUMENT_.EXTENSION_HTML:
            return 'text/html';
        case _DOCUMENT_.EXTENSION_RTF:
            return 'application/rtf';
        default:
          return 'application/pdf';
    }
  }



}

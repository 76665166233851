import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcKarmaDatepickerComponent} from './lpc-karma-datepicker/lpc-karma-datepicker.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [LpcKarmaDatepickerComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    ReactiveFormsModule
  ],
  exports: [LpcKarmaDatepickerComponent]
})
export class LpcKarmaDatepickerModule { }

<form [formGroup]="availabilityOtherAddressForm">
  <div class="rgi-ui-grid-2">
    <div class="rgi-ui-col">
      <label rgiRxLabel><span>{{'_ANAG_._LABEL_._AVAILABILITY_START_DATE_' | translate}}</span></label>
      <rgi-rx-form-field>
        <input [rgiRxDateTimeInput]="availabilityStartDatePicker"
               style="flex: 1"
               data-qa="availabilityStartDate"
               formControlName="availabilityStartDate"
               selectMode="single"
               rangeSeparator="-"
               placeholder="{{datepickerPlaceholder}}" #inputToTriggerAvailabilityStartDate="rgiRxDateTimeInput">
        <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                [rgiRxDateTimeTrigger]="inputToTriggerAvailabilityStartDate"></button>
        <rgi-rx-date-time #availabilityStartDatePicker
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <label rgiRxLabel><span>{{'_ANAG_._LABEL_._AVAILABILITY_END_DATE_' | translate}}</span></label>
      <rgi-rx-form-field>
        <input [rgiRxDateTimeInput]="availabilityEndDatePicker"
               style="flex: 1"
               data-qa="availabilityEndDate"
               formControlName="availabilityEndDate"
               selectMode="single"
               rangeSeparator="-"
               placeholder="{{datepickerPlaceholder}}" #inputToTriggerAvailabilityEndDate="rgiRxDateTimeInput">
        <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                [rgiRxDateTimeTrigger]="inputToTriggerAvailabilityEndDate"></button>
        <rgi-rx-date-time #availabilityEndDatePicker
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <input rgiRxInput data-qa="anag-available-from" formControlName="availableFrom">
        <label rgiRxLabel translate>_ANAG_._LABEL_._AVAILABLE_FROM_</label>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <input rgiRxInput data-qa="anag-available-until" formControlName="availableUntil">
        <label rgiRxLabel translate>_ANAG_._LABEL_._AVAILABLE_UNTIL_</label>
      </rgi-rx-form-field>
    </div>
  </div>
</form>

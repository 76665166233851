import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComboType, DscAdditionalDataService, OutputCompoList, OutputInvolvedAuthorities } from './dsc-additional-data-service/dsc-additional-data.service';
import { AdditionalData } from './dsc-additional-data.model';
import { GeneralClaimsData } from '../dsc-general-detail/dsc-general-detail.config';
import { IncidentData } from '../dsc-incident-data/dsc-incident-data.model';

@Component({
  selector: 'claims-dsc-additional-data',
  templateUrl: './dsc-additional-data.component.html',
  styleUrls: ['./dsc-additional-data.component.scss']
})
export class DscAdditionalDataComponent implements OnInit {
  constructor(
    public formBuilder: UntypedFormBuilder,
    private dataService: DscAdditionalDataService
  ) { }

  @Input() additionalData: AdditionalData;
  @Input() generalData: GeneralClaimsData;
  @Input() incidentData: IncidentData;
  expanded: boolean = true;

  additionalDataForm: UntypedFormGroup =  this.formBuilder.group({
    // Required not definitive
    typeOfAuthorityInvolvedValue: [null],
    authorityDescriptionValue: [''],
    catastrophicEventDescriptionValue:  [''],
    antifraudCategoryValue: [''],
    openedClaimDuetoSummonsIssuedValue: [false],
    notIdentifiedWitnessValue: [false],
    lastAuditDateValue: [''],
    toBeSettledValue: [false],
    externalClaimNumberValue: ['', Validators.maxLength(30)]
  });
  additionalDataSubscription: Subscription;
  comboTypeOfAuthorityInvolved: ComboType[] = [];
  comboAntifraudCategory: ComboType[] = [];
  comboCatastrophicEventDescription: ComboType[] = [];

  @Output() outputFormAdditionalData = new EventEmitter<AdditionalData>();

  ngOnInit() {

    this.initAdditionalData();
  }


  initAdditionalData() {
    if(this.generalData.claimNumber === '' && this.generalData.potentialClaimNumber === ''){
      this.additionalDataForm.patchValue(this.additionalData, {
        emitEvent: false
      });
      this.generalData.claimNumber = null;
      this.generalData.potentialClaimNumber = null;
      // this.disableFormField();
      // if (this.additionalData.lastAuditDateValue) {
      //   this.additionalDataForm.controls.lastAuditDateValue.setValue(new Date(this.additionalData.lastAuditDateValue));
      // }
    }
    this.additionalDataSubscription = this.dataService
    .setAdditionalDataCombo({ potentialClaimNumber: this.generalData.potentialClaimNumber, claimNumber: this.generalData.claimNumber })
    .subscribe(
      (res: OutputCompoList) => {
        if (this.additionalData) {
          this.additionalDataForm.patchValue(this.additionalData, {
            emitEvent: false
          });
          this.disableFormField();
          if (this.additionalData.lastAuditDateValue) {
            this.additionalDataForm.controls.lastAuditDateValue.setValue(new Date(this.additionalData.lastAuditDateValue));
          }
        }
        this.comboTypeOfAuthorityInvolved = res.comboTypeOfAuthorityInvolved;
        this.comboAntifraudCategory = res.comboAntifraudCategory;
        this.comboCatastrophicEventDescription = res.comboCatastrophicEventDescription;
        console.log(this.additionalData);
        this.outputFormChange();
      },
      (error: Error) => {
        // console.log(error)
      }
    );
  }

  disableFormField() {
    this.additionalData.typeOfAuthorityInvolvedEnabled ? this.additionalDataForm.controls.typeOfAuthorityInvolvedValue.enable() :
      this.additionalDataForm.controls.typeOfAuthorityInvolvedValue.disable();
    this.additionalData.authorityDescriptionEnabled ? this.additionalDataForm.controls.authorityDescriptionValue.enable() :
      this.additionalDataForm.controls.authorityDescriptionValue.disable();
    // this.additionalData.catastrophicEventEnabled ? this.additionalDataForm.controls.catastrophicEventValue.enable() :
    //   this.additionalDataForm.controls.catastrophicEventValue.disable();
    this.additionalData.catastrophicEventDescriptionEnabled ? this.additionalDataForm.controls.catastrophicEventDescriptionValue.enable() :
      this.additionalDataForm.controls.catastrophicEventDescriptionValue.disable();
    this.additionalData.antifraudCategoryEnabled ? this.additionalDataForm.controls.antifraudCategoryValue.enable() :
      this.additionalDataForm.controls.antifraudCategoryValue.disable();
    this.additionalData.openedClaimDuetoSummonsIssuedEnabled ?
      this.additionalDataForm.controls.openedClaimDuetoSummonsIssuedValue.enable() :
      this.additionalDataForm.controls.openedClaimDuetoSummonsIssuedValue.disable();
    this.additionalData.notIdentifiedWitnessEnabled ? this.additionalDataForm.controls.notIdentifiedWitnessValue.enable() :
      this.additionalDataForm.controls.notIdentifiedWitnessValue.disable();
    this.additionalData.lastAuditDateEnabled ? this.additionalDataForm.controls.lastAuditDateValue.enable() :
      this.additionalDataForm.controls.lastAuditDateValue.disable();
    this.additionalData.toBeSettledEnabled ? this.additionalDataForm.controls.toBeSettledValue.enable() :
      this.additionalDataForm.controls.toBeSettledValue.disable();
    this.additionalDataForm.controls.typeOfAuthorityInvolvedValue.value === '99'
      || this.additionalDataForm.controls.typeOfAuthorityInvolvedValue.value === ''
      || this.additionalDataForm.controls.typeOfAuthorityInvolvedValue.value === null ?
      this.additionalDataForm.controls.authorityDescriptionValue.disable() :
      this.additionalDataForm.controls.authorityDescriptionValue.enable();
    // this.additionalDataForm.controls.externalClaimNumberValue.value ? this.additionalDataForm.controls.externalClaimNumberValue.enable() :
    //   this.additionalDataForm.controls.externalClaimNumberValue.disable();
  }

  onDestroy() {
    this.additionalDataSubscription.unsubscribe();
  }

  outputFormChange() {
    this.disableFormField();
    const dataChange: AdditionalData = {...this.additionalData };
    dataChange.antifraudCategoryValue = this.additionalDataForm.controls.antifraudCategoryValue.value;
    dataChange.toBeSettledValue = this.additionalDataForm.controls.toBeSettledValue.value;
    dataChange.lastAuditDateValue = this.additionalDataForm.controls.lastAuditDateValue.value;
    dataChange.authorityDescriptionValue = this.additionalDataForm.controls.authorityDescriptionValue.value;
    dataChange.notIdentifiedWitnessValue = this.additionalDataForm.controls.notIdentifiedWitnessValue.value;
    dataChange.typeOfAuthorityInvolvedValue = this.additionalDataForm.controls.typeOfAuthorityInvolvedValue.value;
    dataChange.catastrophicEventDescriptionValue = this.additionalDataForm.controls.catastrophicEventDescriptionValue.value;
    dataChange.openedClaimDuetoSummonsIssuedValue = this.additionalDataForm.controls.openedClaimDuetoSummonsIssuedValue.value;
    dataChange.externalClaimNumberValue = this.additionalDataForm.controls.externalClaimNumberValue.value;
    dataChange.isValidForm = this.additionalDataForm.valid;
    dataChange.lastAuditDateValue <= this.generalData.occurrenceDate && dataChange.lastAuditDateValue <= this.incidentData.dateReported ? dataChange.lastAuditDateValue : null;
    this.outputFormAdditionalData.emit(dataChange);
  }

  provaSelectionChange(event: any) {
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }
}

import { ExtensionSubject } from "../../cai-opening/config-model/cai-opening-claims.module";
import { Outcome } from "../dsc-shared-model/dsc-model-utility";

export class AdditionalData {
    typeOfAuthorityInvolvedValue: string;
    typeOfAuthorityInvolvedVisible: boolean;
    typeOfAuthorityInvolvedEnabled: boolean;
    authorityDescriptionEnabled: boolean;
    authorityDescriptionVisible: boolean;
    authorityDescriptionValue: string;
    catastrophicEventEnabled: boolean;
    catastrophicEventVisible: boolean;
    catastrophicEventDescriptionEnabled: boolean;
    catastrophicEventDescriptionVisible: boolean;
    catastrophicEventDescriptionValue: string;
    antifraudCategoryEnabled: boolean;
    antifraudCategoryVisible: boolean;
    antifraudCategoryValue: string;
    extension: Extension;
    openedClaimDuetoSummonsIssuedEnabled: boolean;
    openedClaimDuetoSummonsIssuedVisible: boolean;
    openedClaimDuetoSummonsIssuedValue: boolean;
    notIdentifiedWitnessEnabled: boolean;
    notIdentifiedWitnessVisible: boolean;
    notIdentifiedWitnessValue: boolean;
    lastAuditDateEnabled: boolean;
    lastAuditDateVisible: boolean;
    lastAuditDateValue: string;
    toBeSettledEnabled: boolean;
    toBeSettledVisible: boolean;
    toBeSettledValue: boolean;
    externalClaimNumberVisible: boolean;
    externalClaimNumberEnabled: boolean;
    externalClaimNumberValue: string;
    isValidForm?: boolean;
    outcome: Outcome;
}

export interface ExtensionProperty {
    chiave: string;
    valore: string;
}

export interface Extension {
    properties: ExtensionProperty[];
}

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lic-lic-investments-modal-error',
  templateUrl: './lic-investments-modal-error.component.html',
  styleUrls: ['./lic-investments-modal-error.component.css']
})
export class LicInvestmentsModalErrorComponent implements OnInit {

  public messages: string[];

  constructor(protected activeModal: NgbActiveModal) {
    // nothing
  }

  ngOnInit() {
    // nothing
  }

  onClose() {
    this.activeModal.close();
  }

}

import { RgiCountryLayerCurrencyFormatPipe } from '@rgi/country-layer';
import { CurrencyCacheService, LpcCurrencyCache } from './../../../../../services/currency-cache.service';
import {
  Component, EventEmitter, forwardRef, Inject, Input, OnChanges,
  OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DEFAULT_CURRENCY_SYMBOL, PV_TOKEN } from '../../../../../../lib/models/consts/lpc-consts';
import { InvestmentMode } from '../../../../../../lib/models/operation-property-code.enum';
import { PlcObjectUtils } from '../../../../../../lib/utils/plc-object-utils';
import { _OPERATION_CODES } from '../../../../lpc-operations-list/model/lpc-operations-constants';
import { FinancialElementToggleEvent, KarmaFundDefinition } from '../../../model/karma-fund-definition';
import { PostsalesOperationsService } from './../../../../../services/postsales-operations.service';

@Component({
  selector: 'lpc-karma-investment-fund',
  templateUrl: './lpc-karma-investment-fund.component.html',
  styleUrls: ['./lpc-karma-investment-fund.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaInvestmentFundComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcKarmaInvestmentFundComponent),
      multi: true
    },
    RgiCountryLayerCurrencyFormatPipe
  ]
})
export class LpcKarmaInvestmentFundComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  public get isPercent(): boolean {
    return this.formGroup.get('isPercent').value;
  }

  currencyCode = DEFAULT_CURRENCY_SYMBOL;

  @Input() public investmentMode: InvestmentMode = InvestmentMode.BOTH;
  @Input() public definition: KarmaFundDefinition;
  @Input() public totalAmount: number;
  @Input() public showSliderInput = true;
  @Input() public showPercentageInput = true;
  @Input() public enableSingleElementSelection = false;

  @Output() public fundSelected = new EventEmitter<any>();
  @Output() public valueChangeEmitter = new EventEmitter<any>();
  @Output() public toggleChange = new EventEmitter<FinancialElementToggleEvent>();

  private $subscriptions: Subscription[] = [];

  private $PercentIsDisabled = false;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl(false),
    percent: new UntypedFormControl(),
    amount: new UntypedFormControl(),
    isPercent: new UntypedFormControl(true)
  });

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected currencySymbolPipe: RgiCountryLayerCurrencyFormatPipe
  ) {
    this.currencyCode = !!currencyService.currency ? currencySymbolPipe.transform(currencyService.currency) : this.currencyCode;
  }

  ngOnInit() {
    this.initializeInputs();
    this.$subscriptions.push(
      // EVENTO DI CAMBIO PERCENTUALE
      // quando cambio la percentuale devo aggiornare anche l'amount senza scatenare l'evento di valueChange
      this.formGroup.get('percent').valueChanges.subscribe(value => {
        const amountFormValue = !!this.totalAmount ? PlcObjectUtils.roundToDecimal(value * this.totalAmount / 100, 2) : 0;
        this.formGroup.get('amount').setValue( amountFormValue, { emitEvent: false } );
        this.controlValueIsChanged(!!value ? value / 100 : value);
      }),
      // EVENTO DI CAMBIO AMOUNT
      this.formGroup.get('amount').valueChanges.subscribe(value => {
        const percentFormValue =  !!this.totalAmount ? PlcObjectUtils.roundToDecimal(value / this.totalAmount * 100, 2) : 0;
        this.formGroup.get('percent').setValue(percentFormValue, { emitEvent: false });
        this.controlValueIsChanged(!!this.formGroup.get('percent').value ? this.formGroup.get('percent').value / 100 : null);
      }),
      // SELEZIONE -> setto la percentuale a zero | caso min === max | caso enableSingleElementSelection
      // DESELEZIONE -> setto la percentuale a null (triggera l'evento di change)
      this.formGroup.get('active').valueChanges.subscribe(value => {
        if (!!value) {
          // --------------- SELEZIONE ---------------
          if (this.enableSingleElementSelection) {
            // con il singolo elemento attivo devo settare il 100%
            this.formGroup.get('percent').setValue(100, { emitEvent: true });
            this.disableInputs();
          } else {
            // se ho un valore fisso da selezionare (min === max)
            const min = this.definition.minPercentAllocation;
            const max = this.definition.maxPercentAllocation;
            if (!!min && !!max && min === max) {
              this.formGroup.get('percent').setValue(min * 100, { emitEvent: true });
              this.disableInputs();
            } else {
              // caso normale: lascio il valore già presente o setto lo zero se c'è il null
              const v = !!this.formGroup.get('percent').value ? this.formGroup.get('percent').value : 0;
              this.formGroup.get('percent').setValue(v, { emitEvent: true });
              this.enableInputs();
            }
          }
          // --------------- SELEZIONE ---------------
        } else if (!value) {
          // --------------- DESELEZIONE ---------------
          this.formGroup.get('percent').setValue(null, { emitEvent: true });
          this.disableInputs();
          // --------------- DESELEZIONE ---------------
        }
      })
    );
  }

  /**
   * @description metodo invocato ogni volta che viene modificata la percentuale o l'amount
   * onChange cambia il valore del form del padre ed è usato per mantenere il valore aggiornato
   * anche la selezione/deselezione passerà di qua
   */
  controlValueIsChanged(value) {
    this.onTouch();
    this.onChange(value);
    this.valueChangeEmitter.emit({
      id: this.definition.id,
      description: this.definition.description,
      value: !!this.formGroup.get('percent').value ? this.formGroup.get('percent').value / 100 : null
    });
  }

  initializeInputs() {
    this.disableInputs();
    if (this.definition.minPercentAllocation === null && this.definition.maxPercentAllocation === null) {
      return this.onChange(this.formGroup.get('percent').value);
    }
    if (this.definition.minPercentAllocation === this.definition.maxPercentAllocation) {
      if (!(!!this.definition.percent || !!this.definition.percentage)) {
        this.formGroup.get('percent').setValue(null, { emitEvent: false });
        this.formGroup.get('active').setValue(false, { emitEvent: false });
      } else {
        const percent = !!this.definition.percent ? this.definition.percent : this.definition.percentage;
        this.formGroup.get('percent').setValue(percent, { emitEvent: false });
        this.formGroup.get('active').setValue(true, { emitEvent: false });
        this.formGroup.disable({ emitEvent: false });
      }
    }
    this.onChange(this.formGroup.get('percent').value);
  }

  disableInputs() {
    this.formGroup.get('isPercent').disable({ emitEvent: false });
    this.formGroup.get('percent').disable({ emitEvent: false });
    this.formGroup.get('amount').disable({ emitEvent: false });
  }

  enableInputs() {
    if (!this.$PercentIsDisabled) {
      this.formGroup.get('isPercent').enable({ emitEvent: false });
    }
    this.formGroup.get('percent').enable({ emitEvent: false });
    this.formGroup.get('amount').enable({ emitEvent: false });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // RICALCOLO AMOUNT SE CAMBIA IL TOTALE
    if (changes.totalAmount) {
      if (!changes.totalAmount.currentValue) {
        this.formGroup.get('amount').setValue(
          0, { emitEvent: false }
        );
      } else {
        this.formGroup.get('amount').setValue(
          this.formGroup.get('percent').value * changes.totalAmount.currentValue, { emitEvent: false }
        );
      }
    }

    // SE CAMBIA IL METODO DI INVESTIMENTO INVERTO LA VISUALIZZAZIONE CON isPercent
    if (changes.investmentMode) {
      const investmentMode = !!changes.investmentMode.currentValue ? changes.investmentMode.currentValue : InvestmentMode.BOTH;
      if (investmentMode !== InvestmentMode.BOTH && investmentMode !== InvestmentMode.ALLSAME) {
        this.formGroup.get('isPercent').setValue(investmentMode === InvestmentMode.PERCENT, { emitEvent: false });
        this.$PercentIsDisabled = true;
        this.formGroup.get('isPercent').disable({ emitEvent: false });
      } else {
        this.$PercentIsDisabled = false;
        this.formGroup.get('isPercent').enable({ emitEvent: false });
      }
    }
  }

  // SET DEL CAMPO DA FUORI
  writeValue(percent: number): void {
    if (!!Number(percent)) {
      this.formGroup.patchValue({
        percent: PlcObjectUtils.roundToDecimal(percent * 100, 2),
        amount: percent * this.totalAmount,
      }, { emitEvent: false });
      this.formGroup.get('active').setValue(true, {emitEvent: false});
      if (this.enableSingleElementSelection ||
        this.definition.minPercentAllocation !== null && this.definition.maxPercentAllocation !== null &&
        (this.definition.minPercentAllocation === this.definition.maxPercentAllocation)) {
        this.disableInputs();
      } else {
        this.enableInputs();
      }
    } else {
      this.formGroup.patchValue({
        percent: null,
        amount: null,
      }, { emitEvent: false });

      // se setto il null da fuori vuol dire che devo deselezionare l'elemento
      // nel caso dovessi solamente azzerarlo dovrei passare il valore 0 (si riconduce al caso true sopra)
      this.formGroup.get('active').setValue(false, { emitEvent: false });
      this.disableInputs();
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onChange(obj: number) {
  }

  onTouch() {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.disableInputs();
    } else {
      this.enableInputs();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let obj: ValidationErrors = {};

    // VALIDAZIONI INSERITE SU SPECIFICHE OPERAZIONI
    if (this.operations.session.operation === _OPERATION_CODES.ADDITIONALPAYMENT) {
        obj = this.addValidationError(control);
    }
    if (this.operations.session.operation === _OPERATION_CODES.SWITCHFREE) {
      obj = this.addValidationError(control);
    }

    control.setErrors(!!Object.keys(obj).length ? obj : null);

    return !!Object.keys(obj).length ? obj : null;
  }

  private addValidationError(control: AbstractControl, toHundred = false) {
    const obj: ValidationErrors = {};
    const valPerc = !!this.formGroup.get('percent') ? this.formGroup.get('percent').value : 0;
    const percent = !toHundred ? Number(valPerc) / 100 : valPerc;
    if (!!this.formGroup.get('active').value) {
      if (this.definition.minPercentAllocation !== this.definition.maxPercentAllocation) {
        if (percent > this.definition.maxPercentAllocation) {
          // SE LA PERCENTUALE INSERITA è MAGGIORE DI QUELLA MASSIMA
          obj.max = { value: percent, limit: this.definition.maxPercentAllocation };
        } else if (percent < this.definition.minPercentAllocation) {
          // SE LA PERCENTUALE INSERITA è MINORE DI QUELLA MINIMA
          obj.min = { value: percent, limit: this.definition.minPercentAllocation };
        } else if (percent <= 0) {
          obj.noPerc = { value: percent };
        }
      }
    }
    return obj;
  }

  roundValue(field: string) {
    const value: number = this.formGroup.get(field).value;
    this.formGroup.get(field).setValue(PlcObjectUtils.roundToDecimal(value, 2));
  }

  onToggleClick() {
    if (this.investmentMode === InvestmentMode.ALLSAME) {
      const fund: FinancialElementToggleEvent = this.formGroup.getRawValue();
      fund.id = this.definition.id;
      this.toggleChange.emit(fund);
    }
  }



}

import {TableSchema} from '@rgi/rx/ui';

export const MOVEMENTS_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'type',
      title: '_RGIPNC_._LABEL_._TYPE_',
      styles: {
        'strikethrough-text': {
          assert: {
            path: 'cancelled',
            value: true,
            condition: '='
          }
        },
      }
    },
    {
      name: 'description',
      title: '_RGIPNC_._LABEL_._DESCRIPTION_',
      styles: {
        'strikethrough-text': {
          assert: {
            path: 'cancelled',
            value: true,
            condition: '='
          }
        },
      }
    },
    {
      name: 'issue',
      title: '_RGIPNC_._LABEL_._ISSUE_',
      styles: {
        'strikethrough-text': {
          assert: {
            path: 'cancelled',
            value: true,
            condition: '='
          }
        },
      }
    },
    {
      name: 'effect',
      title: '_RGIPNC_._LABEL_._EFFECT_',
      styles: {
        'strikethrough-text': {
          assert: {
            path: 'cancelled',
            value: true,
            condition: '='
          }
        },
      }
    },
    {
      name: 'effectHour',
      title: '_RGIPNC_._LABEL_._EFFECT_HOUR_',
      styles: {
        'strikethrough-text': {
          assert: {
            path: 'cancelled',
            value: true,
            condition: '='
          }
        },
      }
    },
    {
      name: 'annexNumber',
      title: '_RGIPNC_._LABEL_._NUMBER_',
      styles: {
        'strikethrough-text': {
          assert: {
            path: 'cancelled',
            value: true,
            condition: '='
          }
        },
      }
    },
    {
      name: 'user',
      title: '_RGIPNC_._LABEL_._USER_'
    },
    {
      name: 'cancelledDescription',
      title: '_RGIPNC_._LABEL_._CANCELLED_'
    },
    {
      name: 'actions',
      title: '_RGIPNC_._LABEL_._ACTIONS_',
      actions: [
        {
          name: 'DETAILS',
          styleClass: 'rgi-ui-icon-search'
        }, {
          name: 'DELETE',
          styleClass: 'rgi-ui-icon-delete',
          hidden: {
            assert: {
              path: 'cancellable',
              value: false,
              condition: '='
            }
          }
        }
      ]
    }
  ],
  header: ['type', 'description', 'issue', 'effect','effectHour','annexNumber', 'user', 'cancelledDescription', 'actions']
};

import { EventInstance, Events, EventType } from '@rgi/rx';
import { AnagApiSubject } from '../group-policy-models/group-policy-issue-policy-data';

export const FIND_SUBJECT: EventType = { eventName: 'FIND_SUBJECT' };
export const SUBJECT_SELECTED: EventType = { eventName: 'SUBJECT_SELECTED' };
export const CALLBACK_JUMP: EventType = { eventName: 'CALLBACK_JUMP' };


export const GROUP_POLICY_EVENTS: Events = [
  FIND_SUBJECT,
  SUBJECT_SELECTED,
  CALLBACK_JUMP
];

export interface FindSubjectEvent extends EventType {
  idSession: string;
  role: string;
}

export class FindSubjectEventInstance implements EventInstance<FindSubjectEvent> {
  event: FindSubjectEvent;
  constructor(idSession: string, role: string) {
    this.event = {
      idSession,
      role,
      eventName: FIND_SUBJECT.eventName
    };
  }
}

export interface SubjectSelectedEvent extends EventType {
  idSession: string;
  subject: any;
  role: string;
}

export class SubjectSelectedEventInstance implements EventInstance<SubjectSelectedEvent> {
  event: SubjectSelectedEvent;

  constructor(idSession: string, subject: AnagApiSubject, role: string) {
    this.event = {
      idSession,
      subject,
      role,
      eventName: SUBJECT_SELECTED.eventName
    };
  }
}

export interface CallBackEvent extends EventType {
  idSession?: string;
}

export class CallBackJumpInstance implements EventInstance<CallBackEvent> {
  event: CallBackEvent;
  constructor(idSession?: string) {
    this.event = {
      eventName: CALLBACK_JUMP.eventName,
      idSession
    };
  }
}

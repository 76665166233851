<div class="rgi-ui-multiselect-input-container">
  <div class="rgi-ui-multiselect-selected-option-preview">
    <div class="rgi-ui-multiselect-selected-option-container"
         *ngIf="selectedOptions.length && showSelected && !input.isOpen()">
      <div class="rgi-ui-multiselect-selected-option"
           rgiRxMultiselectSelectedCounter (onVisibilityChange)="updateMoreCount()"
           #counter="rgiRxMultiselectSelectedCounter"
           rgiRxSdkElementObserver (onElementRendered)="counter.hideIfOverflown()"
           *ngFor="let item of selectedOptions; let index = index; let first = first; let last = last; let odd = odd; let even = even">
        <ng-container *ngTemplateOutlet="inputTemplate || overlayTemplate || default; context: {
            $implicit: item,
            index: index,
            first: first,
            last: last,
            even: even,
            odd: odd
          }"></ng-container>
      </div>
    </div>
  </div>
  <input type="text" rgiRxInput
         [rgiRxAutoComplete]="autoCompleteComponentRef"
         [closeOnSelection]="false"
         [closeOnBlur]="false"
         (onValueChange)="onAutoCompleteValueChange($event)"
         [minLength]="0" [openOnFocus]="true"
         (keydown)="onInputKeyDown($event)"
         [onlyMatching]="true" [required]="false"
         (blur)="onInputBlur($event)"
         [placeholder]="!placeholder || (selectedOptions && selectedOptions.length > 0) ? '' : (placeholder | translate)"
         [disabled]="disabled"
         [ariaLabelledBy]="ariaLabelledBy"
         [ariaDescribedBy]="ariaDescribedBy"
         aria-haspopup="listbox"
         #input="rgiRxAutoComplete">

  <div class="rgi-ui-multiselect-expand">
    <span class="rgi-ui-multiselect-expand-icon" style="pointer-events: all" (click)="toggle($event)"
          [ngClass]="{'rgi-ui-icon-down-arrow': !autoCompleteDirective.isOpen(), 'rgi-ui-icon-up-arrow': this.autoCompleteDirective.isOpen()}"></span>
    <div class="rgi-ui-multiselect-selected-more-counter" *ngIf="moreChips">
      +{{ moreChips }}
    </div>
  </div>
</div>

<rgi-rx-auto-complete #autoCompleteComponentRef role="presentation">
  <ng-template rgiRxAutoCompleteContent>
    <div class="rgi-ui-multiselect-selected-option-container" role="grid" cdkTrapFocus
         (keydown.escape)="this.autoCompleteDirective.closeOverlay()"
         [attr.aria-label]="ariaLabel"
         [attr.aria-disabled]="disabled ? 'true' : 'false'"
         [attr.aria-invalid]="hasError() ? 'true' : 'false'"
         *ngIf="selectedOptions.length && showSelectedInOverlay">
      <div class="rgi-ui-multiselect-selected-option" role="row" [tabIndex]="disabled ? '-1':'0'" *ngFor="let item of selectedOptions;
        let index = index; let first = first; let last = last; let odd = odd; let even = even">
        <ng-container *ngTemplateOutlet="overlayTemplate || default; context: {
          $implicit: item,
          index: index,
          first: first,
          last: last,
          even: even,
          odd: odd
        }"></ng-container>
      </div>
    </div>
    <div class="rgi-ui-multiselect-options-container" role="listbox" [attr.aria-label]="ariaLabel">
      <ng-content></ng-content>
      <rgi-rx-option class="rgi-ui-title-3" *ngIf="hasAllOptionsSelected || hasFilterNoMoreOptions" [disabled]="true">{{ noMoreOptionsMessage | translate }}</rgi-rx-option>
    </div>
  </ng-template>
</rgi-rx-auto-complete>

<ng-template #default let-item let-index="index">
  <rgi-rx-badge [rgi-rx-qa]="id+'-selected-option-'+index"
                [qa-value]="item.value"
                qa-type="multiselect-selected-option"
                role="gridcell" (keydown.enter)="!disabled && removeOption(item)"
                [rgiRxMultiselectRemoveTriggerFor]="item" color="info"
                [ngClass]="{'rgi-ui-d-flex rgi-ui-g-2':input.isOpen()}"
                >
                <span role="presentation" class="rgi-ui-multiselect-option-value">
                  {{ item.label }}
                </span>
    <span role="presentation" class="rgi-ui-multiselect-option-remove rgi-ui-icon-close2"></span>
  </rgi-rx-badge>
</ng-template>

<ng-container *ngIf="localModel" [formGroup]="form">

  <rgi-rx-button-toggle color="info" formControlName="selectedContactType"
                        (onToggle)="changeContactTypeSelected($event)">
    <button rgi-rx-button rgiRxValueOption [value]="contactTypeSelectedEnum.ORDINARY_ADDRESS"
            data-qa="CONTACT_DETAILS_ORDINARY_ADDRESS"
            translate>_GP_._BTN_._ORDINARY_ADDRESS_
    </button>
    <button *ngIf="showEmail" rgi-rx-button rgiRxValueOption [value]="contactTypeSelectedEnum.EMAIL"
            data-qa="CONTACT_DETAILS_EMAIL"
            translate>_GP_._BTN_._EMAIL_
    </button>
    <button *ngIf="showHomeInsurance" rgi-rx-button rgiRxValueOption [value]="contactTypeSelectedEnum.HOMEINSURANCE"
            data-qa="CONTACT_DETAILS_HOME_INSURANCE"
            translate>_GP_._BTN_._HOME_INSURANCE_
    </button>
  </rgi-rx-button-toggle>

  <br>

  <ng-container *ngIf="contactTypeSelected === contactTypeSelectedEnum.ORDINARY_ADDRESS">
    <rgi-rx-form-field *ngFor="let address of localModel.listPolicyAddress">
      <input rgiRxInput
             type="radio"
             formControlName="{{contactTypeSelectedEnum.ORDINARY_ADDRESS}}"
             value="{{address.address.formatAddress}}"
             name="{{contactTypeSelectedEnum.ORDINARY_ADDRESS}}"
             [attr.data-qa]="'address' + address.address.idAddress"
             [checked]="address.main"> {{address.address.formatAddress}}
    </rgi-rx-form-field>
  </ng-container>

  <ng-container *ngIf="contactTypeSelected === contactTypeSelectedEnum.EMAIL">
    <rgi-rx-form-field *ngFor="let email of localModel.listPolicyEmails">
      <input rgiRxInput
             type="radio"
             formControlName="{{contactTypeSelectedEnum.EMAIL}}"
             (change)="onChangeAddress(email.email)"
             value="{{email.email}}"
             name="{{contactTypeSelectedEnum.EMAIL}}"
             [attr.data-qa]="'email' + email.email"
             [checked]="email.selected"> {{email.email}}
    </rgi-rx-form-field>
  </ng-container>

  <ng-container *ngIf="contactTypeSelected === contactTypeSelectedEnum.HOMEINSURANCE">

    <rgi-rx-form-field *ngFor="let email of localModel.homeInsurance.emails">
      <input rgiRxInput
             type="radio"
             formControlName="{{contactTypeSelectedEnum.HOMEINSURANCE}}"
             (change)="onChangePhoneAddress(email.email)"
             value="{{email.email}}"
             name="{{contactTypeSelectedEnum.HOMEINSURANCE}}"
             [attr.data-qa]="'email' + email.email"
             [checked]="email.selected">
      <span translate>_GP_._BTN_._EMAIL_</span>:{{email.email}}
    </rgi-rx-form-field>

    <div *ngIf="localModel.homeInsurance.phoneNumber" style="display: flex; column-gap: 20px">
      <rgi-rx-form-field>
        <input rgiRxInput
               type="radio"
               formControlName="{{contactTypeSelectedEnum.HOMEINSURANCE}}"
               (change)="onChangePhoneAddress(localModel.homeInsurance.phoneNumber.phoneNumber, false, true)"
               value="{{localModel.homeInsurance.phoneNumber.phoneNumber}}"
               name="{{contactTypeSelectedEnum.HOMEINSURANCE}}"
               [attr.data-qa]="'phoneNumber' + localModel.homeInsurance.phoneNumber.phoneNumber"
               [checked]="localModel.homeInsurance.phoneNumber.selected">
        <span translate>_GP_._LABEL_._PHONE_NUMBER_</span>:
      </rgi-rx-form-field>
      <rgi-rx-form-field>
        <input rgiRxInput
               [attr.data-qa]="'phoneNumber' + localModel.homeInsurance.phoneNumber.phoneNumber + '_number'"
               formControlName="{{contactTypeSelectedEnum.HOMEINSURANCE}}_number"
               (blur)="onChangeAddress(localModel.homeInsurance.phoneNumber.phoneNumber, true)">
      </rgi-rx-form-field>
    </div>

  </ng-container>

</ng-container>

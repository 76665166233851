<div class="message" *ngIf="total">
  <div class="message-warning" *ngIf="lower">
    <span translate>lpc_information</span><span>:</span>
    <span translate>{{getWarningMessage()}}</span>
  </div>
  <div class="message-error" *ngIf="!lower">
    <span translate>lpc_disinvestment_allocation_msg</span>
    <span> {{ amount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}</span>
  </div>
</div>

<div class="message" *ngIf="!total">
  <div class="message-error" *ngIf="type === 'max'" >
    <span>{{ error.description }}: </span>

    <ng-container *ngIf="!error.isAmount; else isAmountMessage">
      <span translate>lpc_maximum_percentage</span>
      <span> {{ limit | rgiCountryLayerNumberFormat: currencyService.locale : percentFormatterOptions }}</span>
    </ng-container>
    <ng-template #isAmountMessage>
      <span translate>lpc_maximum_amount</span>
      <span> {{ limit | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}</span>
    </ng-template>

  </div>
  <div class="message-error" *ngIf="type === 'min'" >
    <span>{{ error.description }}: </span>

    <ng-container *ngIf="!error.isAmount; else isAmountMessage">
      <span translate>lpc_minimum_percentage</span>
      <span> {{ limit | rgiCountryLayerNumberFormat: currencyService.locale: percentFormatterOptions }}</span>
    </ng-container>
    <ng-template #isAmountMessage>
      <span translate>lpc_minimum_amount</span>
      <span> {{ limit | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}</span>
    </ng-template>
  </div>
  <div class="message-error" *ngIf="!type" >
    <span>{{ error.description }}</span>
  </div>
</div>

import {Component, EventEmitter, Inject, Input, OnInit, Output, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {CustomModalService} from '../custom-modal.service';
import {Documents} from './documents';
import {Document} from './document';
import {Metadata} from './metadata';
import {DocumentFile} from './document-file';
import {DocumentsService} from './documents.service';
import {
    DocumentValidationFileModalComponent
} from './document-validation-file-modal/document-validation-file-modal.component';
import {DocumentAddModalComponent} from './document-add-modal/document-add-modal.component';
import {LoaderService} from '@rgi/portal-ng-core';
import {Modal} from '../modal';

@Component({
    selector: 'pcm-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

    @Input() contractId: string;
    @Input() uploadDownloadEnabled: boolean;
    @Input() documents: Documents;

    @Output() eventModifiedDocument: EventEmitter<any> = new EventEmitter<any>();
    @Output() startDocument: EventEmitter<any> = new EventEmitter<any>();
    @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('documentModalContainerRef', {
        read: ViewContainerRef,
        static: true
    }) documentModalViewContainerRef: ViewContainerRef;

    arInpDocValidation: Array<any>;
    addDocumentEnabled: boolean;
    deleteDocumentEnabled: boolean;
    validationDocEnabled: boolean;
    deleteFileEnabled: boolean;
    uploadFileEnabled: boolean;

    documentUploadFileModal: Type<Modal>;

    constructor(
        protected documentsService: DocumentsService,
        protected customModalService: CustomModalService,
        protected loaderService: LoaderService,
        protected sanitizer: DomSanitizer,
        @Inject('documentUploadFileModalComponent') documentUploadFileModalComponent: Type<Modal>
    ) {
          this.documentUploadFileModal = documentUploadFileModalComponent;
    }

    ngOnInit() {
        if (this.documents) {
            this.setArInpDocValidation();
            this.statusChanged(true);
        } else if (this.contractId) {

            this.documentsService.getDocumentsObservable().subscribe((documents: Documents) => {
                this.documents = documents;
            });

            this.documentsService.getDocuments(this.contractId).subscribe((documents: Documents) => {
                this.documents = documents;

                this.setArInpDocValidation();
                this.statusChanged(true);
                this.addDocumentEnabled = documents.documentsManageable;
                this.deleteDocumentEnabled = documents.documentsManageable;
                this.validationDocEnabled = documents.documentsManageable;
                this.deleteFileEnabled = documents.documentsDelatable;
                this.uploadFileEnabled = documents.documentsUploadable;
            },
                err => {
                    console.log('La chiamata è andata in errore');
                });
        }
    }

    uploadFile(documentId) {
        if (documentId) {
            const document = this.getInputDocument(documentId);

            if (document) {
                const file = this.instantiateFile(document);
                this.openUpFileModal(document, file, true, 'Upload new document');
            }
        }
    }


    deleteDocument(documentId) {
        if (documentId) {
            this.loaderService.startLoader(this.eventPropagation, true);
            this.documentsService.deleteDocument(this.contractId, documentId).subscribe(docs => {
                this.documents = docs;
            },
                () => {
                    console.log('Errore durante eliminazione documento');
                },
                () => {
                    this.loaderService.stopLoader(this.eventPropagation, true);
                });
        }
    }

    instantiateFile(document: Document) {
        const confMetadataList = document.confMetadataList;
        const id = Math.max.apply(Math, document.files.map(
            (file: DocumentFile) => {
                return file.id ? parseInt(file.id, 10) : 0;
            }
        )) + 1;

        const fileInput = new DocumentFile();
        fileInput.id = id;
        fileInput.signed = false;
        fileInput.downloaded = false;
        fileInput.validate = false;
        fileInput.url = '';
        fileInput.verifyDate = new Date();

        const metadataList = [];

        confMetadataList.forEach(confMetadata => {
            const metadata = new Metadata();
            metadata.code = confMetadata.code;
            metadata.description = confMetadata.description;
            metadata.value = confMetadata.value;
            metadata.valueDescription = confMetadata.valueDescription;
            metadata.listValue = confMetadata.listValue;
            metadata.visible = confMetadata.visible;
            metadata.usedForValidation = confMetadata.usedForValidation;
            metadata.mandatory = confMetadata.mandatory;
            metadata.complianceStatus = confMetadata.complianceStatus;
            metadata.type = confMetadata.propertyType;
            metadata.usedAsIndex = confMetadata.usedAsIndex;
            metadata.modifiable = confMetadata.modifiable;
            metadata.systemMetadato = confMetadata.systemMetadato;
            metadataList.push(metadata);
        });

        fileInput.metadataList = metadataList;

        return fileInput;
    }

    updateFile(documentId, fileId) {
        if (documentId && fileId) {
            const document = this.getInputDocument(documentId);
            if (document) {
                const file = this.getFile(document, fileId);

                if (file) {
                    this.openUpFileModal(document, file, false, 'Update file');
                }
            }
        }
    }

    /* Funzione per upload/update del file */
    openUpFileModal(document, file: DocumentFile, bUploadFile, title) {
        if (file) {
            const comp = this.customModalService.openModal(this.documentModalViewContainerRef, this.documentUploadFileModal,
                this.eventPropagation);
            comp.instance.title = title;
            comp.instance.contractId = this.contractId;
            comp.instance.document = document;
            comp.instance.file = file;
            comp.instance.bUploadFile = bUploadFile;
            comp.instance.arInpDocValidation = this.arInpDocValidation;
            comp.instance.maxKBFileSize = this.documents.documentsMaxKBSize;
            comp.instance.fileTypes = this.documents.documentsFileTypes;
            comp.instance.eventModifiedDocument.subscribe(() => {
                this.statusChanged();
            });
        }
    }

    validationFile(documentId, fileId) {
        if (documentId && fileId) {
            const document = this.getInputDocument(documentId);

            if (document) {
                const file = this.getFile(document, fileId);

                if (file) {
                    const comp = this.customModalService.openModal(this.documentModalViewContainerRef,
                        DocumentValidationFileModalComponent, this.eventPropagation);
                    comp.instance.title = 'Validation document';
                    comp.instance.contractId = this.contractId;
                    comp.instance.document = document;
                    comp.instance.file = file;
                    comp.instance.arInpDocValidation = this.arInpDocValidation;
                }
            }
        }
    }

    addDocument() {
        const comp = this.customModalService.openModal(this.documentModalViewContainerRef,
            DocumentAddModalComponent, this.eventPropagation);
        comp.instance.title = 'Add document';
        comp.instance.contractId = this.contractId;
        comp.instance.documents = this.documents;
        comp.instance.eventModifiedDocument.subscribe(() =>  {
            this.statusChanged();
        });
    }


    download(doc: Document, body: any) {
        const byteArray = new Uint8Array(body);
        const newBlob = new Blob([byteArray], { type: this.getFileType(doc.files[0]) });
        const datalocalURL = window.URL.createObjectURL(newBlob);

        /* scarica il file*/
        const fileLink = document.createElement('a');
        fileLink.href = datalocalURL;
        fileLink.download = doc.description;
        fileLink.target = '_blank';
        fileLink.click();
    }

    downloadDocument(doc: Document) {
        const body = doc.files[0].body;
        if (body && body.length > 0) {
            this.download(doc, body);
        } else {
            this.loaderService.startLoader(this.eventPropagation, true);
            this.documentsService.previewFile(doc.id, false).subscribe((respBody: any) => {
                doc.files[0].body = respBody;
                doc.files[0].downloaded = true;
                this.download(doc, respBody);
            },
                () => {
                    console.log('Errore durante download documento');
                },
                () => {
                    this.loaderService.stopLoader(this.eventPropagation, true);
                });
        }
    }

    previewFile(file: DocumentFile) {
        const body = file.body;
        if (body && body.length > 0) {
            this.preview(body, file);
        } else {
            this.documentsService.previewFile(file.id, true).subscribe((respBody: any) => {
                this.preview(respBody, file);
            });
        }
    }

    protected preview(body, file: DocumentFile) {
        const byteArray = new Uint8Array(body);
        const newBlob = new Blob([byteArray], { type: this.getFileType(file) });
        const datalocalURL = window.URL.createObjectURL(newBlob);

        /* scarica il file*/
        /*const fileLink = document.createElement('a');
        fileLink.href = datalocalURL;
        fileLink.download = file.name;
        fileLink.target = '_blank';
        fileLink.click();*/

        /* apre in nuovo tab*/
        const openWindow = window.open('about:blank');
        openWindow.document.write('<!DOCTYPE html>\n<body style="margin:0px;"></body>');
        const obj = document.createElement('iframe');
        obj.setAttribute('style', 'display: block;background: #000;border: none;height: 100vh;width: 100vw;');
        obj.frameBorder = '0';
        obj.width = '100%';
        obj.height = '100%';
        obj.src = datalocalURL;
        openWindow.document.body.appendChild(obj);
    }

    deleteFile(documentId: string, documentCode: string, fileId: string) {
        if (documentId && documentCode && fileId) {
            this.documentsService.deleteFile(this.contractId, documentCode.toString(), fileId.toString()).subscribe(data => {
                const document = this.getInputDocument(documentId);
                const filtered = document.files.filter((file: DocumentFile) => file.id !== fileId);
                document.files.length = 0;
                Array.prototype.push.apply(document.files, filtered);
                this.statusChanged();
            }, err => {
                console.log('La chiamata è andata in errore');
            });
        } else {
            console.log('Parametri non valorizzati');
        }
    }

    getInputDocument(documentId) {
        const docInput = this.documents.inputDocuments.find((document: Document) => {
            return document.id === documentId;
        });
        return docInput;
    }

    getFile(document, fileId) {
        const file = document.files.find((docFile: DocumentFile) => {
            return docFile.id === fileId;
        });
        return file;
    }

    setArInpDocValidation() {
        const docInput = this.documents.inputDocuments;

        if (docInput.length > 0) {
            this.arInpDocValidation = [];
            docInput.forEach(document => {
                if (document.toBeValidated) {
                    if (document.files && document.files.length > 0) {
                        this.arInpDocValidation[document.id] = true;
                        document.files.forEach(file => {
                            if (!file.validate) {
                                this.arInpDocValidation[document.id] = false;
                            }
                        });
                    } else {
                        this.arInpDocValidation[document.id] = false;
                    }
                }
            });
        }
    }

    remainingItems(file: DocumentFile) {
        if (file.metadataList) {
            const aVisibleItems = file.metadataList.filter(
                (element) => {
                return (element.visible);
            } );
            return Array(3 - (aVisibleItems.length % 3)).fill('a');
        } else {
            return [];
        }
    }

    statusChanged(start?: boolean) {
        let validated = true;
        let completed = true;

        const docInput = this.documents.inputDocuments;
        docInput.forEach((document: Document) => {
            if (document.toBeValidated && !this.arInpDocValidation[document.id]) {
                validated = false;
            }
            if (document.min > document.files.length) {
                completed = false;
            }
        });

        if (start && start === true) {
            this.startDocument.emit({
                validated,
                completed
            });
        } else {
            this.eventModifiedDocument.emit({
                validated,
                completed
            });
        }
    }

    getFileType(file: DocumentFile) {
        let contentType = 'application/pdf';

        if (file.name.toLowerCase().endsWith('.png')) {
            contentType = 'image/png';
        } else if (file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg')) {
            contentType = 'image/jpeg';
        } else if (file.name.toLowerCase().endsWith('.tif') || file.name.toLowerCase().endsWith('.tiff')) {
            contentType = 'image/tiff';
        } else if (file.name.toLowerCase().endsWith('.pdf')) {
            contentType = 'application/pdf';
        } else {
            contentType = 'application/octet-stream';
        }

        return contentType;
    }

}


type PartyCompleteResult = 'KO' | 'WARNING' | 'OK';
export const PARTY_COMPLETE_KO = 'KO';
export const PARTY_COMPLETE_OK = 'OK';
export const PARTY_COMPLETE_WARNING = 'WARNING';

export interface PartyCompleted {
  outcome: string[];
  result: PartyCompleteResult;
}

export interface PartyCheck {
  errorMessages: PartyCheckErrorMessages[];
  partycongruent: boolean;
}

export interface PartyCheckErrorMessages {
  errorDescription: string;
  errorType: string;
  description?: string;
}

export class PartyCheckErrorMessages {
  errorDescription: string;
  errorType: string;
  constructor(message: string) {
    this.errorDescription = message;
  }
}

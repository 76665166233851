import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {Variable} from '../../../models/domain-models/variable';
import {PcVariable} from '../../../models/pc-portfolio-models/variables-models/pc-variable';
import {ReIssueVariablesResourceService} from '../re-issue-variables-resource.service';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioVariablesResourceService extends ReIssueVariablesResourceService {
  private CONTRACT_PATH = 'contract/';
  private apiPcPortfolioConf: any;

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any,
    private refactorService: ReIssueRefactorService
  ) {
    super(http, apiConf);
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;
  }

  get(resourceId: string): Observable<Array<Variable>> {
    return this.http.get<Array<PcVariable>>(this.variablesUri(resourceId)).pipe(
      share(),
      map((pcVariables: Array<PcVariable>) => {
        return this.refactorService.variablesRefactor(pcVariables);
      })
    );
  }

  putVariables(resourceId: string, data: Array<PcVariable>): Observable<Array<Variable>> {
    return this.http.put<Array<PcVariable>>(this.variablesUri(resourceId), data).pipe(
      share(),
      map((pcVariables: Array<PcVariable>) => {
        return this.refactorService.variablesRefactor(pcVariables);
      })
    );
  }

  getVariables(resourceId: string): Observable<Array<PcVariable>> {
    return this.http.get<Array<PcVariable>>(this.variablesUri(resourceId)).pipe(
      share()
    );
  }

  variablesUri(resourceId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      resourceId +
      '/variables';
  }
}

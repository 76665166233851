import {
  AfterViewInit,
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from "@angular/forms";
import {
  CurrencyCacheService,
  LpcCurrencyCache,
} from "../../../../services/currency-cache.service";
import { RgiCountryLayerNumberFormatPipe } from "@rgi/country-layer";
import { ComponentWithAnagModal } from "../../../../interfaces/component-with-anag-modal";
import { FormFieldsDefinition, Role } from "../../../../models/postsales-operations-response.model";
import { AnagService } from "../../../../services/anag.service";
import { AnagSubject } from "../../../../models/subject.model";
import { Roles, RoleType } from "../../../../models/enum/lpc-subjects.enum";
import { CustomModalService } from "../../../../services/custom-modal.service";
import { REVERSIONARY } from "../../config/pension-buyback.const";
import { revisionaryValidator } from "../../config/pension-buyback.validatiors";

@Component({
  selector: "lpc-reversionary-subject",
  templateUrl: "./reversionary-subject.component.html",
  styleUrls: ["./reversionary-subject.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReversionarySubjectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReversionarySubjectComponent),
      multi: true,
    },
  ],
})
export class ReversionarySubjectComponent 
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, ComponentWithAnagModal
{
  constructor(
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    protected anag: AnagService,
    protected modalService: CustomModalService
  ) {}

  @Input() active: boolean;
  @Input() reversionary: FormFieldsDefinition[];
  roleCodeToAdd?: RoleType = Roles.REVERSIONARY;
  reversionaryForm: FormGroup = new FormGroup(
    {
      [REVERSIONARY]: new FormControl({value: null}, revisionaryValidator())
    }
  );

  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  ngOnInit(): void {
    this.reversionaryForm.valueChanges.subscribe(
      val => this.onChange(val)
    )
  }

  ngAfterViewInit(): void {
    this.onChange(this.reversionaryForm.get(REVERSIONARY).value);
  }

  writeValue(val: any): void {
    if (val) {
      this.reversionaryForm.get(REVERSIONARY).setValue(
        {
          value: val.value.reversionary
        }
      );
    }
  }

  validate(): ValidationErrors {
    const errors = {}
    Object.assign(errors, this.reversionaryForm.get(REVERSIONARY).errors, this.reversionaryForm.errors)
    return errors;
  }

  getReversionaryValue(): boolean{
    return !!this.reversionaryForm.get(REVERSIONARY).value && !!this.reversionaryForm.get(REVERSIONARY).value.value
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  onChange(value: any) {}

  onValidationChange(value: any) {}

  ngOnChanges(changes: any): void {}

  onFormChanges() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public openAnagSubjectModal() {
    this.anag.openSubjectModal(this);
  }

  public deleteRevers(event) {
    this.reversionaryForm.get(REVERSIONARY).setValue(null);
  }

  public receiveAnagSubjectFromModal(subject: AnagSubject) {
    const role: Role = AnagService.subjectToRole(subject, REVERSIONARY as RoleType);
    this.reversionaryForm.get(REVERSIONARY).setValue(
      {
        value: role
      }
    );
  }

  ngOnDestroy(): void {
    this.reversionaryForm.reset()  
  }
}

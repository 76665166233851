<form [formGroup]="formGroup" class="input-group">
  <rgi-rx-form-field>
    <label rgiRxLabel>
        <span>{{'lpc_node' | translate}}</span>
    </label>
    <select rgiRxNativeSelect
            [ngClass]="{ 'is-invalid': submitted && formGroup.controls.node.errors }"
            class="col-lg-12" (change)="selectChangeHandlerNode($event)"
            data-qa="new-life-proposal-node"
            name="node"
            formControlName="node" required>
      <option value=""></option>
      <option *ngFor="let node of descriptions" [value]="node.displayValue">{{node.description}}</option>
    </select>
  </rgi-rx-form-field>
  <br>
    <div>
      <label for="subject" class="label-core" translate>lpc_sbj</label>
      <lpc-required required="true"></lpc-required><br>
      <div *ngIf="isPresent(); else empty">
        <lpc-subject (delete)="deleteSbj($event)" formControlName="subject"></lpc-subject>
      </div>
      <ng-template #empty>
        <input
          translate
          [ngClass]="{ 'is-invalid': submitted && formGroup.get('subject').errors }"
          (click)="openAnagSubjectModal()"
          [value]="'Select'"
          class="col-lg-12 drop-container dashedSelect"
          data-qa="dashed-select-subject"
          readonly>
      </ng-template>
    </div>
</form>

<div class="btn-group btn-group-justified">
  <div class="btn-group">
    <button type="button" (click)="clean()" class="btn btn-warning btn-secondary" translate>lpc_empty</button>
  </div>
  <div class="btn-group">
    <button (click)="openSession()" type="submit" data-qa='claim-report-start' class="btn btn-warning">
      {{'Find'}}
    </button>
  </div>
</div>

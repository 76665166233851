import {PcProductAsset} from '../../models/pc-portfolio-models/assets-models/pc-product-asset';
import {Injectable} from '@angular/core';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable, of} from 'rxjs';
import {ModalService} from '@rgi/rx/ui';
import {ReIssueAssetModalComponent} from '../re-issue-asset/re-issue-asset-modal/re-issue-asset-modal.component';
import {ReIssueAssetsBusinessService} from '../re-issue-business-services/re-issue-assets-business.service';
import {map, mergeMap} from 'rxjs/operators';
import {ReIssueQuotationNavigationDataI} from './re-issue-quotation-state-manager.service';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {RgiRxLogger} from '@rgi/rx';
import {
  AcitvityPacket,
  PcAsset,
  PcAssetInstance
} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {Factor} from '../../models/pc-portfolio-models/factor-models/factor';
import {Clause} from '../../models/domain-models/clause';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {ApiPcAddress} from '../../models/api-models/apiPcAddress';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {ReIssueAssetState} from './re-issue-asset.state';

export interface ReissueAssetNavigationDataI {
  isSubstitution: boolean;
  node: any;
  resourceId: string;
  contractorSubject: AnagIssueSubject;
  policyTechnicalData: PolicyTechnicalData;
  lpsData: LpsData;
  productName: string;
  productId: number;
  productCode: string;
  branchOffice: string;
  companyId: string;
  editMode: boolean;
  editFunctionality: boolean;
  isSubjectModified: boolean;
  isToDisableFraz: boolean;
  authorize: boolean;
  bIntermediateSaving: boolean;
  thirdPersonContact: any;
}

@Injectable()
export class ReIssueAssetStateManagerService<T extends ReIssueAssetState> extends AbstractStateManager<T> {


  private readonly CONTRACTOR_ASSET_CODE = 'PFCON';
  private readonly CONTRACTOR_ASSET_NAME = 'CONTRAENTE';
  protected readonly PF_ASSET_CODE = 'PSFIS';
  protected readonly SUBJECT_POLICY_ROLE_ASSET = '4';

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected modalService: ModalService,
    protected reIssueAssetsBusinessService: ReIssueAssetsBusinessService<T>,
    protected logger: RgiRxLogger
  ) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<T>(this.activeRoute.key) ?
      stateStoreService.get<T>(this.activeRoute.key) : this.newReIssueAssetState();

    this.updateState$(this.initializeAssetState(st)); // this update the state
  }

  newReIssueAssetState(): T {
    return new ReIssueAssetState(this.activeRoute.key) as T;
  }


  initializeAssetState(state: T): Observable<T> {
    const navRequest = this.activeRoute.getRouteData<ReissueAssetNavigationDataI>();
    state.editMode = navRequest.editMode;
    state.resourceId = navRequest.resourceId;
    state.contractorSubject = navRequest.contractorSubject;
    state.policyTechnicalData = navRequest.policyTechnicalData;
    state.lpsData = navRequest.lpsData;
    state.productName = navRequest.productName;
    state.productId = navRequest.productId;
    state.productCode = navRequest.productCode;
    state.node = navRequest.node;
    state.companyId = navRequest.companyId;
    state.editFunctionality = navRequest.editFunctionality;
    state.isSubjectModified = navRequest.isSubjectModified;
    state.isSubstitution = navRequest.isSubstitution;
    state.authorize = navRequest.authorize;
    state.bIntermediateSaving = navRequest.bIntermediateSaving;
    state.thirdPersonContact = navRequest.thirdPersonContact;

    // return this.initializeAssets(state);
    return of(state);
  }

  initializeAssets(state: T): Observable<T> {
    return this.getProductAssets(state).pipe(
      mergeMap((internalState1: T) => {
        internalState1.assetInstances = [];

        return this.reIssueAssetsBusinessService.getAssets(internalState1);
      }),
      mergeMap((internalState2: T) => {
        if (internalState2.assetInstances.length !== 0) {
          if (internalState2.isSubjectModified) {
            internalState2.assetInstances.forEach((assetInstance: PcAssetInstance) => {
              if (assetInstance.asset.name === this.CONTRACTOR_ASSET_NAME) {
                return this.deleteAssetInstance(assetInstance);
              }
            });
          }
        }
        return of(internalState2);
      })
      // map((internalState3: T) => {
      //   if (!internalState3.assetInstances.length) {
      //     if (internalState3.productAssets.length === 1 && internalState3.productAssets[0].code === this.PF_ASSET_CODE) {
      //       this.openAnagModal(internalState3);
      //     } else {
      //       this.openAssetModal(internalState3);
      //     }
      //   }
      //   return internalState3;
      // })
    );
  }


  getAsset(key: string, isMoreThanZeroAddress: boolean, isMoreThanZeroSubject: boolean): Observable<T> {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.getAsset(state.resourceId, key).pipe(mergeMap(val => {
        state.assetInstances.find(asset => {
          if (asset.asset.key === val.key) {
            asset.asset.name = val.name;
          }
          return asset.asset.key === val.key;
        }).asset.factors = val.factors;
        state.assetInstances.find(asset => {
          if (asset.asset.key === val.key) {
            asset.asset.name = val.name;
          }
          return asset.asset.key === val.key;
        }).asset.clauses = val.clauses;
        state.assetInstances.find(asset => {
          if (asset.asset.key === val.key) {
            asset.asset.name = val.name;
          }
          return asset.asset.key === val.key;
        }).asset.activityPacket = val.activityPacket;
        state.assetInstances.find(asset => {
          if (asset.asset.key === val.key) {
            asset.asset.name = val.name;
          }
          return asset.asset.key === val.key;
        }).asset.secondaryActivityPacket = val.secondaryActivityPacket;
        state.assetInstances.find(asset => {
          if (asset.asset.key === val.key) {
            asset.asset.name = val.name;
          }
          return asset.asset.key === val.key;
        }).asset.activitiesMin = val.activitiesMin;
        state.assetInstances.find(asset => {
          if (asset.asset.key === val.key) {
            asset.asset.name = val.name;
          }
          return asset.asset.key === val.key;
        }).asset.activitiesMax = val.activitiesMax;
        if (isMoreThanZeroAddress) {
          return this.reIssueAssetsBusinessService.getAddress(state.resourceId, key);
        } else {
          return of(null);
        }

      }),
      mergeMap(address => {
        state.assetInstances.find(asset => {
          return asset.asset.key === key;
        }).asset.address = address;
        if (isMoreThanZeroSubject) {
          return this.getAssetParty(key);
        } else {
          return of(null);
        }
      }),
      map((subject: AnagIssueSubject) => {
        state.assetInstances.forEach(assetInstance => {
          if (assetInstance.asset.key === key) {
            assetInstance.subject = subject;
          }
        });
        return state;
      }));
  }

  getProductAssets(state: T): Observable<T> {
    if (state.productAssets.length !== 0) {
      return of(state);
    }
    return this.reIssueAssetsBusinessService.getProductAssets(state);
  }

  openAssetModal(state: T) {
    const filteredProductAsset: Array<PcProductAsset> = this.filterProductAsset(state);
    if (filteredProductAsset.length === 1) {
      this.initializeAssetByCode(filteredProductAsset[0].code, state);
    } else {
      const {modal, component} = this.modalService.openComponent(ReIssueAssetModalComponent);

      component.productAssets = new Array<PcProductAsset>();
      filteredProductAsset.forEach(asset => {
        component.productAssets.push(asset);
      });

      component.contraenteSubject.subscribe((selectedAsset: string) => {
        this.initializeAssetByCode(selectedAsset, state);
      });
      modal.onClose.subscribe(value => {
        const filteredAsset = filteredProductAsset.filter(asset => {
          return asset.description === value;
        });
        this.initializeAssetByCode(filteredAsset[0].code, state);
      });
    }

  }


  filterProductAsset(state: T): PcProductAsset[] {
    return state.productAssets.filter(product => {
      const filteredAsset = state.assetInstances.filter(asset => {
        return asset.asset.code === product.code;
      });
      return filteredAsset.length < product.maxInsurables;
    });
  }


  initializeAssetByCode(selectedAsset: string, state: T): Observable<PcAsset> {
    return this.reIssueAssetsBusinessService.initializeNormalAsset(state.resourceId, selectedAsset);
  }


  updateInsuredSubject(state: any, selectedAsset: string, subject: AnagIssueSubject): Observable<T> {
    return this.reIssueAssetsBusinessService.setInsuredSubject(state, selectedAsset, subject);

  }

  deleteInsuredSubject(state: any, assetKey: string, idLatestPhoto: string, roleId: string) {
    this.updateState$(this.reIssueAssetsBusinessService.deleteAssetParty(state, assetKey, idLatestPhoto, roleId));
    state.assetInstances.forEach(instance => {
      if (instance.asset.key === assetKey) {
        instance.asset.subject = undefined;
      }
    });
  }


  openAnagModal(st: T) {
    /*    const { modal, component } = this.modalService.openComponent(AnagIssueModalComponent);
        modal.enableClickBackground = false;
        component.node = st.node;
        component.modalRef = modal.elRef;
        component.nodeId = st.contractorSubject.node.identification;
        component.policyRole = this.SUBJECT_POLICY_ROLE_ASSET;
        // TODO : NEED?
      /!*  component.subjectAction.subscribe((a: AnagIssueSubjectAction) => {
          if (a.action === AnagIssueSubjectActionValue.create || a.action === AnagIssueSubjectActionValue.select) {
            this.updateState$(this.reIssueAssetsBusinessService.initializeAsset(st, this.PF_ASSET_CODE, a.subject));
          }
        });*!/
        modal.onClose.subscribe(() => {
        });*/
  }

  updateAssetFactor(factor: Factor, asset: PcAsset) {
    const state = this.getCurrentState();
    this.updateState$(
      this.reIssueAssetsBusinessService.updateAssetFactor(state, factor, asset)
    );
  }

  updateAssetClauses(state: any, clause: Clause, asset: PcAsset): Observable<T> {
    return this.reIssueAssetsBusinessService.updateAssetClause(state, clause, asset).pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteAsset(assetInstance: PcAssetInstance) {
    const state = this.deleteAssetInstance(assetInstance);
    this.updateState$(state);
    return state;
  }

  deleteAssetInstance(assetInstance: PcAssetInstance): Observable<T> {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.deleteAssetInstance(state, assetInstance.asset.key).pipe(
      map(response => {
        state.assetInstances = this.removeDeletedAssetFromAssetInstances(state.assetInstances, assetInstance.asset.key);
        return state;
      })
    );
  }

  deleteAddress(emitted: any) {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.deleteAddress(state, emitted.address, emitted.instanceAssetKey);
  }

  createAddress(address: any, instanceAssetKey: string) {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.createAddress(state, address, instanceAssetKey);
  }

  updateAddress(resourceId: string, address: ApiPcAddress, instanceAssetKey: string) {
    return this.reIssueAssetsBusinessService.updateAddress(resourceId, address, instanceAssetKey);
  }

  searchActivity(input: string, byDescription: boolean, isSecondary: boolean) {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.searchActivity(state, state.assetInstances[0].asset.key, input, byDescription, isSecondary);
  }

  updateActivities(activities: AcitvityPacket, assetKey: string, isSecondary: boolean) {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.updateActivities(activities, state, assetKey, isSecondary);
  }

  getAutocomplete(input: string) {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.getAutocomplete(state, state.assetInstances[0].asset.key, input);
  }

  deleteActivity(isSecondary: boolean) {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.deleteActivity(state, state.assetInstances[0].asset.key, isSecondary);
  }

  removeDeletedAssetFromAssetInstances(assetInstances: Array<PcAssetInstance>, key: string): Array<PcAssetInstance> {
    const assetInstancesWithoutDeleted = new Array<PcAssetInstance>();

    assetInstances.forEach(instance => {
      if (instance.asset.key !== key) {
        assetInstancesWithoutDeleted.push(instance);
      }
    });

    return assetInstancesWithoutDeleted;
  }

  isEmptyAsset(st: T): boolean {
    let res = false;
    if (!st.subjectsInstances || st.subjectsInstances.length === 0) {
      res = true;
    }
    return res;
  }

  hasContraenteSelected(st: T): boolean {
    let result = false;

    if (st.subjectsInstances && st.subjectsInstances.length) {
      st.subjectsInstances.forEach(element => {
        if (element.isContraente) {
          result = true;
        }
      });
    }

    return result;
  }

  getWarnings() {
    const state = this.getCurrentState();
    // TODO: CHECK GET WARNINGS SERVICE
    return this.reIssueAssetsBusinessService.getWarnings(state).pipe(mergeMap(
      st => {
        return this.reIssueAssetsBusinessService.getConsistencyAsset(st);
      }
    ));
  }

  getAssetParty(assetKey: string) {
    const state = this.getCurrentState();
    return this.reIssueAssetsBusinessService.getAssetParty(state, assetKey).pipe(
      map((subject: AnagIssueSubject) => {
        state.assetInstances.forEach(assetInstance => {
          if (assetInstance.asset.key === assetKey) {
            assetInstance.subject = subject;
          }
        });
        this.updateState$(of(state));
        return subject;
      })
    );
  }

  back() {
    const state = this.getCurrentState();
    // TODO: check if nulls are to populate
    const dataPolicy: any = {
      contractorSubject: state.contractorSubject,
      resourceId: null,
      productName: null,
      productId: null,
      node: null,
      productCode: null,
      companyId: null,
      policyTechnicalData: state.policyTechnicalData,
      editMode: state.editMode,
      editFunctionality: state.editFunctionality,
      isSubstitution: state.isSubstitution,
      backFromStep: true,
      isToDisableFraz: state.isToDisableFraz,
      authorize: state.authorize,
      thirdPersonContact: state.thirdPersonContact
    };

    dataPolicy.resourceId = this.getCurrentState().resourceId;
    this.routingService.navigate('re-issue-policy-data', dataPolicy, this.activeRoute.id);
  }

  saveProposal(): Observable<T> {
    return this.reIssueAssetsBusinessService.saveProposal(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  goToNextPage() {
    const st = this.getCurrentState();
    const request: ReIssueQuotationNavigationDataI = {
      resourceId: st.resourceId,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: st.lpsData,
      subject: st.contractorSubject,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: st.productName,
      productId:  st.productId,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isPersonaGiuridica: st.isPersonaGiuridica,
      resetQuestionnaire: true,
      isSubjectModified: st.isSubjectModified,
      isSubstitution: st.isSubstitution,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      bIntermediateSaving: st.bIntermediateSaving,
      clausesFromAsset: st.assetInstances
    };
    this.routingService.navigate('re-issue-quotation', request, this.activeRoute.id);
  }

  addNewAsset(assetCode: string, state: ReIssueAssetState): Observable<PcAssetInstance> {
    const resourceId = this.getCurrentState().resourceId;
    return this.reIssueAssetsBusinessService.initializeNormalAsset(resourceId, assetCode).pipe(map(insuredAsset => {
      const assetInstance = new PcAssetInstance();
      assetInstance.asset = new PcAsset(insuredAsset);
      return assetInstance;
    }));

  }
}

import {ApiDisabledComponents, FieldComponent} from '../models/api-models/api-disabled-components';
import {ApiSubstitutionReasonConf} from '../models/api-models/api-substitution-reason-config';
import {
  SubstEnumAssetDataModificationAllowed
} from '../models/domain-models/substitution/enum/substitution-assetdatamodificationallowed';

export function getDisabledComponentsFromSubstitutionConfig(config: ApiSubstitutionReasonConf) {

  const disabledFields = new ApiDisabledComponents();
  disabledFields.unhandledCase = false;
  disabledFields.components = new Array<FieldComponent>();
  disabledFields.components.push(new FieldComponent('effectiveDate', false));
  disabledFields.components.push(new FieldComponent('tacitRenewal', false));
  disabledFields.components.push(new FieldComponent('exipreDate', false));

  if (config.ASSET_DATA_MODIFICATION_ALLOWED === SubstEnumAssetDataModificationAllowed.ASSET_DATA_MODIFICATION_ALLOWED_NO
    || config.ASSET_DATA_MODIFICATION_ALLOWED === SubstEnumAssetDataModificationAllowed.ASSET_DATA_MODIFICATION_ALLOWED_YES_ONLY_SUBJECTS) {
    disabledFields.components.push(new FieldComponent('product', false));
    disabledFields.components.push(new FieldComponent('assetType', false));
    disabledFields.components.push(new FieldComponent('plateType', false));
    disabledFields.components.push(new FieldComponent('vehicleClass', false));
    disabledFields.components.push(new FieldComponent('usage', false));
    disabledFields.components.push(new FieldComponent('licensePlate', false));
    disabledFields.components.push(new FieldComponent('registrationDate', false));
    disabledFields.components.push(new FieldComponent('fuelType', false));
    disabledFields.components.push(new FieldComponent('brand', false));
    disabledFields.components.push(new FieldComponent('model', false));
    disabledFields.components.push(new FieldComponent('outOfMagazine', false));
    disabledFields.components.push(new FieldComponent('updatableInsValue', false));
    disabledFields.components.push(new FieldComponent('setupDescription', false));
  }

  if (config.BREAK_CHAIN !== '1') {
    disabledFields.components.push(new FieldComponent('vehicleOrigin', false));
    disabledFields.components.push(new FieldComponent('bersani', false));
    disabledFields.components.push(new FieldComponent('originPlateType', false));
    disabledFields.components.push(new FieldComponent('originPlateNumber', false));
  }

  return disabledFields;
}

import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-error-managent',
  templateUrl: './error-managent.component.html',
  styleUrls: ['./error-managent.component.css']
})
export class ErrorManagentComponent implements OnInit {
  @Output() modalClose = new EventEmitter();
  @Input() modalRef: ElementRef;
  @Input() errorMessage: string;
  @Input() errorType: string;
  @Input() confirmAlert: boolean;
  @Input() executionId: string;
  @Input() hideBasicMessage: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  close() {
    this.modalClose.emit();
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {AnagStatelessService} from '../../../anag-resources/anag-stateless.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ModalService} from '@rgi/rx/ui';
import {AnagStateManagerPartyEditor} from '../../../anag-states/party-editor/anag-state-manager-party-editor';
import {
  AnagTaxClassData,
  AnagTaxClassification,
  AnagtaxClassificationData
} from '../../../anag-model/anag-domain/anag-tax-classification';
import {AnagTaxClassificationComponent} from '../anag-tax-classification/anag-tax-classification.component';
import {DatePipe} from '@angular/common';
import {AnagStateManagerKeyData} from '../../../anag-states/key-data/anag-state-manager-key-data';
import {
  TaxClassificationHistoryModalComponent
} from '../tax-classification-history-modal/tax-classification-history-modal.component';

@Component({
  selector: 'rgi-anag-tax-classification-section',
  templateUrl: './tax-classification-section.component.html',
  styleUrls: ['./tax-classification-section.component.css'],
  host: {
    class: 'rgi-anag-style'
  }
})
export class TaxClassificationSectionComponent implements OnInit {

  @Input() taxClassifications: AnagTaxClassification[];
  label: string;

  canEdit = true;

  constructor(protected modalService: ModalService,
              protected translateService: RgiRxTranslationService,
              public datePipe: DatePipe,
              public statelessService: AnagStatelessService,
              public stateMgr: AnagStateManagerPartyEditor,
              public stateMgrKD: AnagStateManagerKeyData, ) {
    this.canEdit = stateMgr.getCurrentState().editorModeEnable;
  }

  ngOnInit() {
    this.translation();
  }

  formatDateToString(date: Date): string {
    const DATE_FORMAT = this.statelessService.getLocaleDateFormat();
    return this.datePipe.transform(date, DATE_FORMAT);
  }

  addNewTaxClassification() {
    const taxClassData = new AnagTaxClassData();
    taxClassData.modalLabel = this.label;
    taxClassData.tacClassificationList = this.taxClassifications;
    const taxClassModal = this.modalService.openComponent(AnagTaxClassificationComponent, taxClassData,
      [
        {
          provide: AnagStateManagerPartyEditor,
          useValue: this.stateMgr
        }
      ]);
    taxClassModal.modal.enableClickBackground = false;
    taxClassModal.modal.onClose.subscribe(newTaxClass => {
      if (newTaxClass) {
        this.stateMgr.addTaxClassification(newTaxClass);
      }
    });
  }

  editTaxClassification(taxClassificationData: AnagtaxClassificationData) {
    const taxClassData = new AnagTaxClassData();
    taxClassData.modalLabel = this.label;
    taxClassData.taxClassificationData = taxClassificationData;
    taxClassData.tacClassificationList = this.statelessService.deepClone(this.taxClassifications);
    taxClassData.editMode = true;
    const taxClassModal = this.modalService.openComponent(AnagTaxClassificationComponent, taxClassData,
      [
        {
          provide: AnagStateManagerPartyEditor,
          useValue: this.stateMgr
        }
      ]
    );
    taxClassModal.modal.enableClickBackground = false;
    taxClassModal.modal.onClose.subscribe(editedTaxClass => {
      if (editedTaxClass) {
        this.stateMgr.updateTaxClassification(editedTaxClass, taxClassificationData);
      }
    });
  }

  deleteTaxClassification(taxClass: AnagtaxClassificationData) {
    this.stateMgr.deleteTaxClassification(taxClass);
  }

  getValuedTaxCLassLabel(taxClass: AnagtaxClassificationData): string {
    const typeString = taxClass.type ? taxClass.type.descrizione : ' ';
    const countryString = taxClass.country ? ' - ' + taxClass.country.descrizione : ' ';
    const dataStartString = taxClass.countryStartDate ? ' - ' + this.formatDateToString(taxClass.countryStartDate) : ' ';
    const dataEndDateString = taxClass.countryEndDate ? ' - ' + this.formatDateToString(taxClass.countryEndDate) : ' - ';
    return typeString.concat(countryString).concat(dataStartString).concat(dataEndDateString);
  }

  translation() {
  }

  isFieldVisible(fieldName: string): boolean {
    return this.stateMgr.isTaxClassificationFieldVisible(fieldName);
  }

  openModalTaxClassificationHistory() {
    const taxClassificationhistory = [];
    this.taxClassifications.forEach(taxClass => {
      taxClass.taxClassificationsData.forEach(data => {
        taxClassificationhistory.push(data);
      })
    })
    this.modalService.openComponent(TaxClassificationHistoryModalComponent, taxClassificationhistory);
  }

  getLastTaxClassification(taxClassification: AnagTaxClassification[]): any {
    const taxClassificationDataList = [];
    if (taxClassification && taxClassification.length > 0) {
      taxClassification.forEach(taxclass => {
        if (taxclass.taxClassificationsData && taxclass.taxClassificationsData.length > 0) {
          let result = null;
          taxclass.taxClassificationsData.slice().reverse().forEach(data => {
            if (!data.classificationDeleted && result === null) {
              result = data;
            }
          })
          result ? taxClassificationDataList.push(result) : null;
        }
      })
    }
    return taxClassificationDataList;
  }

  isShowNoTaxClassification() {
    return !this.taxClassifications || this.taxClassifications.length === 0 || this.taxClassifications.every(taxClass => taxClass.taxClassificationsData.some(data => data.classificationDeleted))
  }

}

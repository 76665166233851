<div *ngIf="state && !!state.errors && !!state.errors.length" data-qa="errorMessage">
  <rgi-gp-group-policy-errors-component
    [errors]="state.errors"
    [type]="state.type"
  >
  </rgi-gp-group-policy-errors-component>
  <div class="rgi-ui-panel-footer" >
    <button
      class="rgi-ui-btn rgi-ui-btn-secondary uppercase"
      (click)="back()"
      data-qa="buttonsBack" translate>{{'_GP_._BTN_._BACK_'}}
    </button>

  </div>
</div>

import {
  ConsultationContractDetailsBusinessService
} from '../services/business/consultation-contract-details-business.service';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {RgiRxLocalStateManager, StateStoreService} from '@rgi/rx/state';
import {Observable, of} from "rxjs";
import {
  ConsultationContractDetailsNavigationData,
  ConsultationContractDetailState
} from './states/consultation-contract-details-state';
import {ConsultationStartCardBusinessService} from "../services/business/consultation-start-card-business.service";
import {PolicyOperationContainer, PolicyOperationPayload} from "../models/policy-operations";
import {ConsultationListNavigationData} from "./states/consultation-list-state";

export class ConsultationContractDetailsStateManagerService<T extends ConsultationContractDetailState> extends RgiRxLocalStateManager<T>{
  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    private routingService: RoutingService,
    private consultationContractDetailsBusinessService: ConsultationContractDetailsBusinessService)
  {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newConsultationContractDetailsState();
    this.updateState$(of(this.initConsultationContractDetailsState(st))); // this update the state

  }
  getContractDetails(contractNumber: string){
    return this.consultationContractDetailsBusinessService.getContractDetail(contractNumber);
  }
  newConsultationContractDetailsState(): T {
    return new ConsultationContractDetailState(this.activeRoute.key) as T;
  }
  initConsultationContractDetailsState(state: T) {
    const navData = this.activeRoute.getRouteData<ConsultationContractDetailsNavigationData>();
    state.contractNumber = navData.contractNumber;
    state.searchResults = navData.policies;
    state.userCode = navData.userCode
    return state;
  }
  back() {
    const policiesList=this.getCurrentState().searchResults;
    const user = this.getCurrentState().userCode;
    const navData:ConsultationListNavigationData = {policies:policiesList, userCode:user}
    this.routingService.navigate('consultation-results',navData, this.activeRoute.id);
  }

  getPolicyOperations(body){
    return this.consultationContractDetailsBusinessService.getPolicyOperations(body)
  }
}

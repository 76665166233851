import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { TotalSummaryComponent, TotalSummaryService } from '@rgi/iiab-portal-ext';
import { PortalConfig } from '@rgi/rx/portal';

declare let angular: angular.IAngularStatic;

export function downgradeIIABService(config: PortalConfig) {
    angular.module('app')
        .directive('totalSummary', downgradeComponent({ component: TotalSummaryComponent }))
        .run(['$templateCache', function ($templateCache) {
            $templateCache.put('cardTotalSummary.subcard.template', `
            <total-summary [data]="data" [schema]="schema"></total-summary>`);
        }]);

    angular.module(config.module)
        .factory('totalSummaryService', downgradeInjectable(TotalSummaryService));
}
import { ActivityBusinessKeyClaim } from './activity-business-key-claim';
import { ActivityDictionary } from './activity-dictionary';
import { ActivityTask } from './activity-task';
import { ActivityVariable } from './activity-variable';

export class Activity {

  public actBusinessKeyClaimBean: ActivityBusinessKeyClaim;
  public actDictionaryBean: ActivityDictionary;
  public actTaskBean: ActivityTask[];
  public assigneeGroup: string;
  public assigneeUser: string;
  public claimNumber: string;
  public expirationDate: Date;
  public idAct: number;
  public involvedParty: string;
  public typeOfLoss: string;
  public message: string;
  public actVariableBean: ActivityVariable[];
  public documentKey: string;
  public updDate: Date;
  public priority: number;
  public descrPriority: string;
  constructor() { }
}

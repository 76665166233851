// model per gestione questionario angularJs SoaService

export class AnswerValues {
  notes: null;
  visibilityLevel: string;
  description: string;
  order: null;
  id: string;
  score: string;
}
export class Answer {
  factorFormat: null;
  formatter: null;
  notes: null;
  factorCode: string;
  values: AnswerValues[] = [];
  value: string;
  factorDescr: string;
  paramType: string;
  defaultValue: string;
  factorType: string;
}
export class Question {
  defaultStatus: string;
  compulsory: null;
  code: string;
  visible: boolean;
  answers: Answer[] = [];
  description: string;
  isFactor: boolean;
  text: string;
  conditions: [] = [];
  order: string;
  status: string;
}
export class QuestionnaireDetailJS {
  compulsory: boolean;
  code: string;
  codeVersion: string;
  codeType: string;
  descriptionType: string;
  questions: Question[] = [];
  description: string;
  openDate: string;
  included: boolean;
  score: string;
  category: string;
}

// model per gestione questionario angular 8

export class ValueResponse {
  id: string;
  description: string;
}

export class CustomAnswer {
  questionCode: string;
  questionnaireCode: string;
  answerCode: string;
  valueResponse: string | null;
  typeResponse: string;
  valuesResponse: ValueResponse[] = [];
}

export class CustomQuestion {
  questionCode: string;
  questionValue: string;
  questionState: string;
  questionDescription: string;
  questionRca: boolean;
}

export class Item {
  question: CustomQuestion;
  answers: CustomAnswer[] = [];
}

export class QuestionnaireDetail {
  answers: CustomAnswer[] = [];
  items: Item[] = [];
  questionnaireCategory: string;
  questionnaireCode: string;
  questionnaireCodeType: string;
  questions: CustomQuestion[] = [];
  isValid?: boolean;
}

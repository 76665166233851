import { ActConfigurationBean } from './act-configuration-bean';

export class ActivityDictionary {

  public area: number;
  public dictionaryDescription: string;
  public idActDict: number;
  public activityType: number;
  public code: string;
  public actConfigurationBean: Array<ActConfigurationBean>;

  constructor() { }
}

import { Injectable } from '@angular/core';
import { ExternalPolicy } from './dto/external-policy';
import { FilterPolicy } from './dto/filter-policy';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private filterPolicy: FilterPolicy;
  private listExternalPolicies: Array<ExternalPolicy>;
  private isNewPolicy: boolean = false;
  private selectedExternalPolicy: ExternalPolicy;
  private disabilita: any;
  private companies: any;
  private provinces: any;
  private countries: any;

  constructor() { }

  setCompanies(companies: any) {
    this.companies = companies;
  }

  getCompanies(): any {
    return this.companies;
  }

  setProvinces(provinces: any) {
    this.provinces = provinces;
  }

  getProvinces(): any {
    return this.provinces;
  }

  setCountries(countries: any) {
    this.countries = countries;
  }

  getCountries(): any {
    return this.countries;
  }


  setFilterPolicy(filter: FilterPolicy) {
    this.filterPolicy = filter;
  }

  setNewPolicy(isNewPolicy: boolean) {
    this.isNewPolicy = isNewPolicy;
  }

  getNewPolicy(): boolean {
    return this.isNewPolicy;
  }

  getNewPolicyClear(): boolean {
    const temp = this.isNewPolicy;
    this.isNewPolicy = false;
    return temp;
  }

  getFilterPolicyClear(): FilterPolicy {
    const temp = this.filterPolicy;
    this.filterPolicy = new FilterPolicy();
    return temp;
  }

  getFilterPolicy(): FilterPolicy {
    return this.filterPolicy;
  }

  setListExternalPolicy(list: Array<ExternalPolicy>) {
    this.listExternalPolicies = list;
  }

  getListExternalPolicyClear(): Array<ExternalPolicy> {
    const temp = this.listExternalPolicies;
    this.listExternalPolicies = new Array<ExternalPolicy>();
    return temp;
  }

  getListExternalPolicy(): Array<ExternalPolicy> {
    return this.listExternalPolicies;
  }

  setSelectedExternalPolicy(externalPolicy: ExternalPolicy) {
    this.selectedExternalPolicy = externalPolicy;
  }

  getSelectedExternalPolicy() {
    return this.selectedExternalPolicy;
  }

  getSelectedExternalPolicyClear() {
    const temp = this.selectedExternalPolicy;
    this.selectedExternalPolicy = null;
    return temp;
  }

  setDisabilita(disabilita: boolean) {
    this.disabilita = disabilita;
  }

  isDisabiliato(): boolean {
    return this.disabilita;
  }


}

import {AnagTranslationsKeys} from './anag-translations-keys';

// Please keep the translation keys alphabetically ordered
export const ANAG_FR: AnagTranslationsKeys = {
  _ANAG_: {
    _BTN_: {
      _ADD_: 'Ajouter',
      _ADD_ADDRESS_: 'Ajoutez l\'adresse',
      _BACK_: 'Précédent',
      _CALCULATE_: 'Calculer',
      _CANCEL_: 'Annuler',
      _CLOSE_: 'Fermer',
      _CONFIRM_: 'Confirmer',
      _DETAIL_: 'Détail',
      _EDIT_: 'Editer',
      _EMPTY_: 'EFFACER CRITERES',
      _FIND_: 'RECHERCHER',
      _NEW_: 'NOUVEAU',
      _NEW_PAYMENT_: 'Nouveau paiement',
      _NORMALIZE_: 'NORMALISER',
      _SAVE_: 'Sauver',
      _DELETE_: 'Effacer',
      _DELETE_DEFINITIVELY_: 'Effacer définitivement',
      _HISTORY_: 'Historique',
      _LIMITATION_MANAGEMENT_: 'Gestion des limitations',
      _UNDO_: 'Annuler',
      _CONTINUE_: 'Continuer'
    },
    _LABEL_: {
      _ACTIONS_: 'Actes',
       _ACTION_TERRORISM_: 'Informations sur le terrorisme',
      _ADDRESS_: 'Adresse',
      _AE_: 'AE',
      _AGENCY_: 'Agence',
      _ATI_ASSOCIATIONS_: 'ATI Associations_FR',
      _BIRTH_DATE_: 'Date de naissance',
      _BIRTH_PLACE_: 'Lieu de naissance',
      _BIRTH_SURNAME_: 'Nom de naissance / Deuxième nom de famille',
      _CITIZENSHIP_: 'Citoyenneté',
      _CITY_OF_BIRTH_: 'Ville de naissance',
      _CIVIL_STATE_: 'Situation de famille',
      _CLIENT_NUMBER_: 'Numéro de client',
      _COMPANY_NAME_: 'Nom/nom de société',
      _CONTACTS_: 'Contacts',
      _CORPORATE_FORM_: 'Forme d\'entreprise',
      _CORPORATE_SECTOR_: 'Secteur des entreprises',
      _CORPORATION_: 'Corporation',
      _COWORKER_: 'Code fiscal du collègue',
      _CREDIT_: 'Crédit',
      _CRITERIA_: 'Type de critère',
      _DEBIT_: 'Débit:',
      _DOCUMENT_: 'Document',
      _DOCUMENT_NO_: 'Numéro du document',
      _DOCUMENT_TYPE_: 'Type de document',
      _EMAIL_: 'Email',
      _EMAIL2_: 'Email 2',
      _EMAIL3_: 'Email 3',
      _EXPIRATION_DATE_: 'Date d\'expiration',
      _FAX_NO_: 'Numéro de fax',
      _FEMALE_: 'Femelle',
      _FILL_ID_DATA_: 'Remplir les données de sujet d\'identification',
      _FISCAL_CODE_: 'Code fiscal',
      _FISCAL_CODE_VAT_NUMBER_: 'Code fiscal / Numéro de TVA',
      _GENDER_: 'Sexe',
      _GENDER_IDENTITY_: 'Identité de genre',
      _IDENTIFICATION_DATA_: 'Données d\'identification',
      _INT_PREFIX_: 'Int. Préfixe',
      _LANDLINE_NO_: 'Numéro de téléphone',
      _PHONE_NO_: 'Numéro de téléphone',
      _LEADER_: 'Leader ATI',
      _LINK_TYPE_: 'Type de lien',
      _LINKED_PARTIES_: 'Parties liées',
      _MALE_: 'Mâle',
      _MAIN_CONTACT_: 'Contact principal',
      _MANAGEMENT_NODE_: 'Noeud de gestion',
      _MOBILE_NO_: 'Téléphone portable',
      _MOBILE_NO_2_: 'Téléphone portable 2',
      _MUNICIPALITY_: 'Municipalité',
      _NAME_: 'Prénom',
      _NATION_: 'Pays (pour clé d\'identification)',
      _NODES_: 'Noeuds',
      _NO_ADDRESS_MSG_: 'Aucune adresse',
      _NUMBER_: 'Numéro',
      _OPEN_DATE_: 'Date d\'ouverture',
      _PARTICIPANTS_: 'Participants',
      _PARTIES_: 'Sujets',
      _PARTY_: 'Personne',
      _PARTY_DATA_: 'Détail du sujet',
      _PARTY_MANAGEMENT_: 'Recherche tiers',
      _PARTY_MERGER_: 'Unification de Tiers',
      _PARTY_NUMBER_: 'N° Tiers',
      _PARTY_TYPE_: 'Sous-type de Tiers',
      _PAYMENT_: 'Paiement',
      _PERSON_TYPE_: 'Type de Tiers',
      _PHYSICAL_: 'Physique',
      _POTICALLY_EXP: 'Personne exposée politiquement',
      _PREFIX_: 'Préfixe',
      _PRIVACY_: 'Accords de confidentialité',
      _PRIVACY_CONSENSUS_DATE_: 'Date',
      _CHANGED_ON_: 'Modifié le',
      _PRODUCTION_NODES_: 'Noeuds de production',
      _PROFESSION_: 'Métier',
      _PROFESSION_DETAIL_: 'Détail de la profession',
      _REGISTERED_OFFICE_: 'Adresse Fiscale',
      _RELEASE_AUTHORITY_: 'Autorité de libération',
      _RELEASE_DATE_: 'Date de libération',
      _RELEASE_LOCATION_: 'Lieu de libération',
      _REQUIRED_FIELD_: 'Requis',
      _RESIDENCE_: 'Residence',
      _ANTI_TERRORISM_: 'Anti-Terrorisme',
      _RESIDENCE_REGISTERED_OFFICE_: 'Residence / Adresse Fiscale',
      _SAE_: 'SAE',
      _SALE_POINT_: 'Point de vente',
      _SAVED_OK_: 'enregistré avec succès',
      _SELECT_PARTY_: 'Sélectionner la partie',
      _SEX_: 'Sexe',
      _STATUS_: 'Statut à afficher',
      _STATUS_NOT_PROCESSED_: 'Non traité',
      _STATUS_PROCESSED_: 'Traité',
      _SUBJECT_: 'Personne',
      _SUBJECT_DATA_: 'Détail du sujet',
      _SUBJECT_TYPE_: 'Type de partenaire',
      _SUBJECT_UNIQUE_KEY_: 'Tiers unique clé',
      _SUBJNO_: 'Sujet n.',
      _SUBSYSTEM_: 'Système externe',
      _SUBSYSTEM_PERSON_ID_: 'Référence externe',
      _SURNAME_: 'Nom',
      _SURNAMEORCOMPANY_: 'Nom / Raison sociale',
      _VOID_: ' ',
      _VAT_NUMBER_: 'Numéro de Tva',
      _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Changement de date au terrorisme',
      _COUNTER_TERRORISM_INDICATOR_: 'État antiterroriste',
      _DOMICILES_: 'Domiciles',
      _DOMICILE_: 'Domicile',
      _PRINCIPAL_ADDRESS_: 'Adresse principale',
      _AVAILABILITY_: 'Disponibilité',
      _TYPE_: 'type',
      _AVAILABILITY_START_DATE_: 'Date de début de disponibilité',
      _AVAILABILITY_END_DATE_: 'Date de fin de disponibilité',
      _AVAILABLE_FROM_: 'Disponible de',
      _AVAILABLE_UNTIL_: 'Disponible jusqu\'au',
      _INTERNATIONAL_DIALLING_CODE_: 'Code d\'accès international',
      _LOCAL_AREA_DIAL_CODE_: 'Code d\'accès local',
      _YES_: 'Oui',
      _NO_: 'Non',
      _COUNTERTERRORISM_STATUS_: 'état anti terrorisme',
      _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_: 'Changement de date anti terrorisme',
      _ORIGIN_: 'Source',
      _LANGUAGE_:'Langue',
      _HONOR_TITLE_ : 'Titre d Honneur',
      _SONS_: 'Nombre d\'enfants',
      _ROBINSON_LIST_: 'Liste Robinson',
      _CITIZENSHIP_PERMIT_: 'Permis de résidence',
      _CITIZENSHIP_EXPIRE_: 'Date valable jusqu\'au',
      _REG_COUNTRY_: 'Pays d\'immatriculation',
      _EXTERNALS_IDENTIFIERS_: 'Identifiants externes',
      _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Identifiants Sujet Sous-système',
      _TAX_CLASSIFICATION_: 'Classements Fiscaux',
      _RISK_CLASSIFICATION_ : 'Classement de Risque',
      _REASON_CLASSIFICATION_ : 'Classement de Raison',
      _BASIS_CLASSIFICATION_ : 'Classement de Basique',
      _CLASSIFICATION_DATE_ : 'Date de Classement',
      _COUNTRY_START_DATE_ : 'Date de Début du Pays',
      _COUNTRY_END_DATE_ : 'Date de Fin du Pays',
      _NO_TAX_CLASSIFICATIONS_ : 'Pas de Classement Fiscal',
      _COUNTRY_ : 'Pays (pour clé d\'identification)',
      _NOMINATIVE_ : 'Nominatif',
      _HISTORY_CONSULTATION_: 'Consultation historique',
      _DO_NOT_REPLY_: 'non défini',
      _DRIVING_LICENSE_TYPE_: 'Type de permis de conduire',
      _DRIVER_QUALIFICATION_CARD_: 'Carte de qualification de conducteur',
      _ADD_DRIVING_LICENSE_TYPE_: 'Ajouter un type de permis de conduire',
      _PRIVACY_INFO_MSG_: 'Le champ consulte les fichiers d\'exclusion publicitaire officiels connus sous le nom de Liste Robinson (RL) et nous indique si le client est inscrit dans la RL ou non.\n' +
        '\n' +
        'Si la Liste Robinson est définie sur Oui, nous ne pourrons pas envoyer de communications commerciales.\n' +
        '\n' +
        'Si la Liste Robinson est définie sur Non, les communications commerciales ne seront envoyées que lorsque le champ Exclure la publicité est défini sur Non.\n' +
        '\n' +
        'Si le client n\'a pas été consulté dans la RL, le champ apparaît sans valeur.',
      _LEI_CODE_: 'Code LEI',
      _RATING_: 'Classement',
      _RATING_DATE_: 'Date de classement',
      _RATING_PERIOD_: 'Période de classement',
      _RATING_USER_: 'Utilisateur',
      _RATING_ORIGIN_: 'Origine',
      _RATING_HISTORY_: 'Historique du classement',
      _COWORKERS_:'Salariés',
      _COMPANY_: 'Entreprise'
    },
    _MSG_: {
      _ASK_DELETION_CONFIRM_: 'Confirmer la suppression du sujet ?',
      _DELETION_CONFIRMED_: 'Le sujet a été supprimé correctement',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Les champs marqués en rouge sont requis ou contiennent des valeurs incorrectes',
      _ERROR_DATE_: 'Sujet de moins de 16 ans. Le consentement au traitement des données est entendu par les parents/tuteurs légaux',
      _ERROR_DATE_SP_: 'Consentement au traitement des données personnelles des enfants de moins de 14 ans',
      _INVALID_DATE_VALUE: 'Date incorrecte',
      _INVALID_FIELD_: 'Champ incorrect',
      _LENGTHERR_: 'Longueur du champ non autorisée',
      _LINK_ERROR_: 'Partie déjà insérée',
      _LINK_ERROR_SAME_PARTY_: 'Impossible de lier une fête à eux-mêmes',
      _NO_ELEMENT_TO_SHOW_: 'Il n\'y a aucun élément à afficher',
      _NO_LINKED_PARTIES_: 'Aucun lien',
      _NO_RESULTS_: 'Aucun résultat',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'Aucune adresse normalisée trouvée',
      _PATTERNERR_: 'Le champ contient des caractères erronés',
      _REFINE_SEARCH_: 'L\'extraction est partielle. Veuillez affiner votre recherche',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Remplir au moins : Nom/nom de société, Nom patronymique, Nom patronymique / Deuxième nom de famille, clé unique, Numéro de client, Numéro de Tiers, numéro de téléphone, Code Postal valide ou  Référence externe',
      _WRONG_INPUT_DATA_: 'Le flux de gestion de tiers est invoqué avec des valeurs d\'entrée non prises en charge',
      _INVALID_END_DATE_DOMICILE_: 'Saisir une date de fin de disponibilité postérieure à la date de début de disponibilité.',
      _INVALID_START_DATE_DOMICILE_: 'Saisir une date de début de disponibilité antérieure à la date de fin de disponibilité.',
      _INHERITANCE_: 'Il existe déjà un registre avec la même clé unique dans le réseau de vente. Appuyez sur confirmer si vous souhaitez utiliser les données du sujet déjà enregistré dans le registre, sinon il ne sera pas possible de continuer avec cette amélioration de la clé unique',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'Il existe déjà une classification fiscale valide pour le pays, le type et la période sélectionnés',
      _INFO_HOMONYMIES_: 'Attention : des homonymes ont été trouvés.\n' +
        'Veuillez sélectionner un tiers de la liste pour le modifier, ou bien cliquer sur le bouton CONTINUER pour créer un nouveau tiers.',
      _INFO_NATURAL_PERSON_ALLOWED_: 'Personne physique uniquement autorisée',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Legal Entity only allowed',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'La date de publication doit être antérieure à la date d’expiration',
      _IBAN_INVALID_: 'Format IBAN incorrect',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Saisir une date d\'échéance postérieure à la date de délivrance du document',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: 'Confirmer la suppression de la classification fiscale actuelle ?',
      _ASK_DELETION_DOCUMENT_CONFIRM_: 'Confirmez-vous la suppression du document actuel ?',
      _DOC_ALREADY_EXIST: 'Numéro de document déjà présent',
      _INSERT_DOC_TYPE: 'Entrez le type de document avant de continuer.',
      _INVALID_FORMAT_: 'Format invalide',
      _NORMALIZE_ERROR_MSG: 'Il est obligatoire de normaliser l\'adresse'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Modification Tier',
  _ANAG_TITLE_PARTY_DETAIL_: 'Affichage du tiers',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Recherche Tiers',
  _ANAG_TITLE_PARTY_ZOOM_: 'Zoom Tier',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Recherche de tiers',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: ''
};

import {ProposalDataResponse} from './../../../models/pc-portfolio-models/proposal-models/proposal-data-response';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map, share, take} from 'rxjs/operators';
import {PolicyTechnicalData} from '../../../models/domain-models/parameters/policy-technical-data';
import {ReIssueTechnicalDataResourceService} from '../re-issue-technical-data-resource.service';
import {ReIssuePortfolioProposalService} from './re-issue-portfolio-proposal.service';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {ReIssuePortfolioAgreementsResourceService} from './re-issue-portfolio-agreements-resource.service';
import {ReIssuePortfolioPaymentFrequencyResourceService} from './re-issue-portfolio-payment-frequency-resource.service';
import {
  ReIssuePortfolioProductCurrenciesResourceService
} from './re-issue-portfolio-product-currencies-resource.service';
import {ReIssuePortfollioUseTaxesResourceService} from './re-issue-portfolio-use-tax-resource.service';
import {ReIssuePortfolioIndexationResourceService} from './re-issue-portfolio-indexation-resource.service';
import {
  ReIssuePortfolioLpsDataResourceService
} from './re-issue-portfolio-lps-data-resource.service';
import {REST_API_TECHDATA_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioTechnicalDataResourceService extends ReIssueTechnicalDataResourceService {


  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any,
    private portfolioProposalService: ReIssuePortfolioProposalService,
    private agreementsResourceService: ReIssuePortfolioAgreementsResourceService,
    private paymentFrequencyResourceService: ReIssuePortfolioPaymentFrequencyResourceService,
    private productCurrenciesResourceService: ReIssuePortfolioProductCurrenciesResourceService,
    private refactorService: ReIssueRefactorService,
    private useTaxServixce: ReIssuePortfollioUseTaxesResourceService,
    private indexationService: ReIssuePortfolioIndexationResourceService,
    private lpsDataService: ReIssuePortfolioLpsDataResourceService,
  ) {
    super(http, apiConf);
  }

  get(resourceId: string): Observable<PolicyTechnicalData> {
    return combineLatest(
      // TODO: eliminare per ottimizzazione
      this.portfolioProposalService.getProposal(resourceId),
      this.paymentFrequencyResourceService.getPaymentFrequencies(resourceId),
      this.agreementsResourceService.getAgreements(resourceId),
      this.productCurrenciesResourceService.getProductCurrencies(resourceId),
      this.useTaxServixce.getUseTaxes(resourceId),
      this.indexationService.getIndexTypes(resourceId),
      this.lpsDataService.getLpsCountries(resourceId),
    ).pipe(
      share(),
      take(1),
      map(
        ([proposal, paymentFrequencies, agreements, currencies, useTaxes, indexTypes, lpsCountries]) => {
          return this.refactorService.policyTechnicalDataRefactor(proposal, paymentFrequencies, agreements, currencies, useTaxes, indexTypes, lpsCountries);
        }
      )
    );
  }

  put(resourceId: string, data: PolicyTechnicalData): Observable<PolicyTechnicalData> {
    return this.portfolioProposalService.putProposal(resourceId, this.refactorService.getProposalDataPutRequest(data)).pipe(
      share(),
      map((proposal: ProposalDataResponse) => {
        return this.refactorService.proposalToPolicyTechnicalData(data, proposal);
      })
    );
  }
}

import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {RoutableComponent} from '../../routable-component';
import {EventNotificator} from '../../event-notificator';
import {PartiesService} from '../parties.service';
import {ProposalService} from '../../proposal.service';
import {Subscription, throwError} from 'rxjs';
import {Entitled} from '../../models/domain-models/parties/entitled';
import {Role} from '../../models/domain-models/parties/role';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Message} from '../../models/message';
import {RolesTypes} from '../../models/domain-models/parties/roles-types';
import {StateService} from '../../state.service';
import {Action} from '../../models/action';
import {QuotationService} from '../../quotation/quotation.service';
import {CustomModalService} from '../../custom-modal.service';
import {AnagFlowData} from '@rgi/anag';
import { ModalService } from '@rgi/portal-ng-core';

@Component({
  selector: 'mic-entitled-party',
  templateUrl: './entitled-party.component.html',
  styleUrls: ['./entitled-party.component.scss']
})
export class EntitledPartyComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator {

  @Output()
  navigation: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  entitledControlForm: UntypedFormGroup;

  entitled: Entitled;
  party: any;
  roles: Array<Role>;

  roleTypesConstants = RolesTypes;

  validationMessages: Message[] = [];
  blockingMessages: Message[] = [];
  blockedForTerrorism: boolean;
  areaCode = 'ENTITLED';
  anagPortalAjsFlow;

  @ViewChild('quotationComparisonModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) quotationComparisonModalOverlay: ViewContainerRef;

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected partiesServices: PartiesService,
    public proposalService: ProposalService,
    protected stateService: StateService,
    protected quotationService: QuotationService,
    protected customModalService: CustomModalService,
    protected modalService: ModalService,
    @Inject('RGI_ANAG_PORTAL_AJS_FLOW') anagPortalAjsFlow: boolean) {
    this.anagPortalAjsFlow = anagPortalAjsFlow;
  }

  ngOnInit() {
    this.eventPropagation.emit({
      eventName: 'setTitle',
      title: 'P&C New Proposal - Party Management'
    });

    this.entitledControlForm = this.formBuilder.group(
      {
        role: [null, Validators.required],
        useForSubscriber: [true]
      }
    );

    this.partiesServices.getEntitled(this.proposalService.getContractId()).subscribe(data => {
        this.entitled = data;

        this.initPartyAndRoles();

        this.partiesServices.setProposedEntitled(data);

        const ownerRole = this.entitled.roles.find(role => {
          return role.code === this.roleTypesConstants.ROLE_OWNER;
        });

        if (ownerRole) {
          this.entitledControlForm.patchValue({
            role: ownerRole
          });
        }

      });

    const updatePartySubscription = this.partiesServices.getPartyObserable().subscribe(
      (data: any) => {
        if (data) {
          let party = data;
          if (Array.isArray(data)) {
            party = data[0];
          }
          const contractId = this.proposalService.getContractId();
          const roleId = this.entitledControlForm.controls.role.value ?
            this.entitledControlForm.controls.role.value.id : null;

          this.partiesServices.getParty(contractId, party.partyId, party.idLatestPhotos, roleId).subscribe(
            newParty => {
              this.entitled.parties = [newParty];
              this.initPartyAndRoles();
            });
        }
      }
    );
    this.subscriptions.add(updatePartySubscription);

    const entitledControlFormValueChangesSubscription = this.entitledControlForm.valueChanges.subscribe(val => {
      this.validateForm();
    });
    this.subscriptions.add(entitledControlFormValueChangesSubscription);
  }

  isNewParty() {
    // TODO modificare il DTO in modo da tornare dal BE l'informazione sul nuovo soggetto
    return this.party && this.party.partyId && parseInt(this.party.partyId, 10) < 0;
  }

  selectParty(party: any) {
    if (party) {
      this.party = party;
      this.checkParty();
    }
  }

  findParty() {
    if (this.anagPortalAjsFlow) {
      this.eventPropagation.emit({
        eventName: 'requireSubject'
      });
    } else {
      this.partiesServices.openAnagModal(this.createAnagFlowData());
    }
  }

  createAnagFlowData(): AnagFlowData {
    const flowData = new AnagFlowData();
    flowData.partyRole = this.entitled.role && this.entitled.role.code ? this.entitled.role.code : '999';
    flowData.nodeCode = this.proposalService.getApiContract().nodeCode;
    return flowData;
  }

  editParty() {
    if (this.anagPortalAjsFlow) {
      const partyToChange = JSON.parse(JSON.stringify(this.party));
      delete partyToChange.complete;

      this.eventPropagation.emit({
        eventName: 'updateParty',
        parent: 'anagCardManage',
        route: 'home',
        title: 'Edit subject',
        party: partyToChange
      });
    } else {
      this.partiesServices.goToEditParty(this.party, this.createAnagFlowData()).subscribe(updParty => {
        this.party = updParty;
        this.entitled.parties = [updParty];
      });
    }
  }

  confirmParty() {
    this.validateForm();

    if (this.entitledControlForm.valid) {
      this.entitled.role = this.entitledControlForm.controls.role.value;
      this.entitled.useAlsoForSubscriber = this.entitledControlForm.controls.useForSubscriber.value;

      this.partiesServices.updateEntitled(this.proposalService.getContractId(), this.entitled).subscribe(
        data => {
          this.partiesServices.setLastUpdateChangedPartyResidence(data.residenceChanged);
          this.partiesServices.setEntitledParty(this.party);
          this.proposalService.setProposalState(true);
          this.stateService.nextState(Action.CONFIRM_BUTTON_PRESSED);
        },
        err => {
          if (err.status === 422) {
            this.modalService.open(err.error.messages, 'Error');
            this.blockedForTerrorism = true;
            return throwError(err);
          }
        }
      );
    }
  }

  changeSubscriber() {
    const useForSubscriberControl = this.entitledControlForm.controls.useForSubscriber;
    useForSubscriberControl.setValue(!useForSubscriberControl.value);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private initPartyAndRoles() {
    if (this.entitled) {
      const parties = this.entitled.parties;

      if (parties && parties.length && parties.length === 1) {
        this.party = parties[0];

        this.checkParty();
      }

      this.roles = this.entitled.roles.filter(data => {
        return data.max > 0;
      });
    }
  }

  private checkParty() {
    this.blockingMessages.length = 0;
    this.blockedForTerrorism = false;

    if (this.isNewParty()) {
      this.blockingMessages.push(new Message(this.areaCode, 'The party is not present in the database'));
    } else if (this.party && this.party.complete === false) {
      this.blockingMessages.push(new Message(this.areaCode, 'Party data missing'));
    }

    this.eventPropagation.emit('layoutChanged');
  }

  private validateForm() {
    const controls = this.entitledControlForm.controls;

    this.validationMessages.length = 0;

    if (controls.role.errors && controls.role.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Role of entitled party role is mandatory'));
    }
    this.eventPropagation.emit('layoutChanged');
  }

}

import {TableSchema} from '@rgi/rx/ui';

export const PARTY_LIST_MODAL_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'nodeCode',
      title: '_ANAG_._LABEL_._AGENCY_'
    },
    {
      name: 'nominative',
      title: '_ANAG_._LABEL_._SUBJECT_'
    },
    {
      name: 'counterTerrorism',
      title: ' ',
    },
    {
      name: 'personType',
      title: '_ANAG_._LABEL_._SUBJECT_TYPE_'
    },
    {
      name: 'fiscalCode',
      title: '_ANAG_._LABEL_._FISCAL_CODE_VAT_NUMBER_'
    },
    {
      name: 'dateOfBirth',
      title: '_ANAG_._LABEL_._BIRTH_DATE_',
      format: {
        pipe: 'date',
        args: ['dd/MM/yyyy']
      }
    },
    {
      name: 'action',
      title: '_ANAG_._LABEL_._ACTIONS_',
      styleClass: 'rgi-anag-flex',
      actions: [
        {
          name: 'SELECT',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-right-arrow rgi-anag-text-tone'
        }, {
          name: 'EDIT',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-edit2 rgi-anag-text-tone',
          hidden: {
            assert: {
              path: 'canEditParty',
              value: false,
              condition: '='
            }
          }
        }
      ],
      description: '_ANAG_._LABEL_._SELECT_PARTY_'
    }
  ],
  header: ['nodeCode', 'nominative', 'counterTerrorism' , 'personType', 'fiscalCode', 'dateOfBirth', 'action']
};

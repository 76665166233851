<div class="rgi-ui-grid">
  <div class="rgi-ui-col">
    <span class="header-icon rgifont rgi-ui-icon-print nfm_font_size"></span>
    <span class="header-text" translate>_PCPSALES_._LABEL_._PRINT_DOCUMENTS_</span>
    <span class="pull-right header-text"></span>
  </div>
</div>
<div class="rgi-ui-grid" *ngFor="let document of documents.documents; let i = index">
  <span class="rgi-ui-col-10-md-10-sm-8 rgipc-flex-center" [attr.data-qa]="document.documentName">{{document.documentName}}</span>
  <div class="rgi-ui-col-2-md-2-sm-4 button-container">
    <div class="btn-group btn-group-justified">
      <button rgi-rx-button class="rgi-ui-icon-export icon-button" color="secondary" data-qa="open-document"
              (click)="openDocument(document)"></button>
      <button rgi-rx-button class="rgi-ui-icon-save icon-button" color="secondary" data-qa="download-document"
              (click)="downloadDocument(document)"></button>
    </div>
  </div>
  <hr class="divider" *ngIf="i < documents.documents.length - 1"/>
</div>

import { Inject, Injectable } from '@angular/core';

@Injectable()
export class EnviromentsService {

  private static readonly PROPERTY_USE_RGI_RX_HTTP_CLIENT_WRAPPER: string = 'useRgiRxHttpClientWrapper';
  private static readonly PROPERTY_USE_DEFAULT_HTTP_CLIENT: string = 'useDefaultHttpClient';
  private static readonly PROPERTY_EXCLUDE_ERROR_INTERCEPTOR: string = 'excludeErrorInterceptor';

  private _baseApiPassURL = '';
  private _baseApiURL = '';
  private readonly _lifeServiceErrorHandlerConfig = null;
  private readonly _existsUseRgiRxHttpClientWrapperInConfig: boolean;
  private readonly _existsUseDefaultHttpClientInConfig: any;

  public get baseApiPassURL() {
    return this._baseApiPassURL;
  }
  public set baseApiPassURL(value) {
    this._baseApiPassURL = value;
  }

  public get baseApiURL() {
    return this._baseApiURL;
  }
  public set baseApiURL(value) {
    this._baseApiURL = value;
  }

  public get useRgiRxHttpClientWrapper(): boolean {
    return this._existsUseRgiRxHttpClientWrapperInConfig ? this._lifeServiceErrorHandlerConfig[EnviromentsService.PROPERTY_USE_RGI_RX_HTTP_CLIENT_WRAPPER] : true;
  }

  public get excludeErrorInterceptor(): boolean {
    return !this.useRgiRxHttpClientWrapper
      && !!this._existsUseDefaultHttpClientInConfig && this._existsUseDefaultHttpClientInConfig[EnviromentsService.PROPERTY_EXCLUDE_ERROR_INTERCEPTOR];
  }

  constructor(@Inject('environment') protected environment: any) {
    this.baseApiPassURL = environment.api.portal.host + environment.api.portal.path + environment.api.gateway.path;
    this.baseApiURL = environment.api.portal.host + environment.api.portal.path;
    this._lifeServiceErrorHandlerConfig = environment.lifeServiceErrorHandlerConfig;
    if (!!this._lifeServiceErrorHandlerConfig) {
      this._existsUseRgiRxHttpClientWrapperInConfig = this.existsPropertyInLifeServiceErrorHandlerConfig(EnviromentsService.PROPERTY_USE_RGI_RX_HTTP_CLIENT_WRAPPER);
      if (!this._existsUseRgiRxHttpClientWrapperInConfig) {
        this._existsUseDefaultHttpClientInConfig = this.existsPropertyInLifeServiceErrorHandlerConfig(EnviromentsService.PROPERTY_USE_DEFAULT_HTTP_CLIENT);
      }
    }
  }

  private existsPropertyInLifeServiceErrorHandlerConfig(property: string): boolean {
    return this._lifeServiceErrorHandlerConfig[property] !== undefined && this._lifeServiceErrorHandlerConfig[property] !== null;
  }
}

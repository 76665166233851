import { ApiPager } from '../api-pager';

export class ApiActivityDetailsInput {
    pager: ApiPager;
    claimNumber: string;
    user: string;
    chiamante: string;

    constructor() {
        this.pager = new ApiPager();
    }
}

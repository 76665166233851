<form [formGroup] = "formSurveyEdit" class="ppevo-form-survey-edit">
  <div class="ppevo-title" translate>NG_PASSPROPRO_SURVEY.form-survey-edit_title</div>

  <div class="ppevo-form-surname" *ngIf="!anonymous">
    <label>
      <span translate>NG_PASSPROPRO_SURVEY.name-surname</span>
      <span class="ppevo-label-required">*<span translate>NG_PASSPROPRO_CORE.required</span></span>
    </label>
    <input formControlName = "nameSurname" data-qa="nameSurname" type="text" [ngClass]="{'ppevo-invalid': nameSurname.invalid && errorsVisible}" />
  </div>

  <div class="ppevo-form-cf-piva" *ngIf="!anonymous">
    <label translate>NG_PASSPROPRO_SURVEY.cf-piva</label>
    <input formControlName = "cfPiva" data-qa="cfPiva" type="text" />
  </div>

  <div class="ppevo-form-type" *ngIf="selectedQuestionnaire && selectedQuestionnaire.questionnaireType && selectedQuestionnaire.questionnaireType.code">
    <label translate>NG_PASSPROPRO_SURVEY.type</label>
    <input formControlName = "type" data-qa="type" type="text" />
  </div>

  <div class="ppevo-form-code" *ngIf="selectedQuestionnaire && selectedQuestionnaire.questionnaireType && selectedQuestionnaire.questionnaireType.code">
    <label translate>NG_PASSPROPRO_CORE.code</label>
    <input formControlName = "code" data-qa="code" type="text"  />
  </div>

  <div class="ppevo-form-date-creation">
    <label translate>NG_PASSPROPRO_SURVEY.creation-date</label>
    <input formControlName = "dateCreated" data-qa="dateCreated" type="text" />
  </div>

  <div class="ppevo-form-date-end">
    <label translate>NG_PASSPROPRO_SURVEY.validity-end-date</label>
    <input formControlName = "dateEnd" data-qa="dateEnd" type="text" />
  </div>

  <div class="ppevo-form-questionnaire">
    <label>
      <span translate>NG_PASSPROPRO_SURVEY.questionnaire</span>
      <span class="ppevo-label-required">*<span translate>NG_PASSPROPRO_CORE.required</span></span>
    </label>
    <select class="ppevo-form-control-questionnaire" formControlName = "questionnaire" data-qa="questionnaire" [ngClass]="{'ppevo-invalid': questionnaire.invalid && errorsVisible}" (change)="onQuestionnaireChange($event)">
      <option>
      </option>
      <option *ngFor="let questionnaire of questionnaires;" [ngValue]="questionnaire">
        {{ questionnaire.name }}
      </option>
    </select>
  </div>

</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcModalComponent } from './lpc-modal/lpc-modal.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcModalComponent],
    imports: [
        CommonModule,
        RgiRxI18nModule
    ],
    exports: [
        LpcModalComponent
    ]
})
export class LpcModalModule { }

import {Injectable} from '@angular/core';
import {ApiVehicleStaticData} from '../models/api-models/api-vehicle-static-data';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor() {
  }

  isVehicleStaticDataComplete(vehicleStaticData: ApiVehicleStaticData) {
    return vehicleStaticData && vehicleStaticData.vehicleClass && vehicleStaticData.vehicleUse
      && vehicleStaticData.registrationDate && vehicleStaticData.fuel && vehicleStaticData.brand
      && vehicleStaticData.model && vehicleStaticData.setup;
  }

}

export enum Roles {
  CONTRACTOR = '1',
  CEDENTE = '1',
  INSURED = '4',
  VINCOLATARIO = '5',
  EFFECTIVEHOLDER = '113',
  EXECUTOR = '112',
  DELEGATE = '112',
  LEGAL_GUARDIAN = '300008',
  THIRDPAYER = '300016',
  BENEFICIARY = '100',
  COSIGNED = '400000',
  EMPLOYER= '300002',
  THIRDREFERENT = '300015',
  ADERENTE = '110',
  EFFECTIVEHOLDER_BENEF = '300018',
  EFFECTIVEHOLDER_THIRDPAYER = '300017',
  COMPANY_PENSION_FORM = '300011',
  EFFECTIVEHOLDER_CLAIMBENEF = '300018',
  EXECUTOR_CLAIMBENEF = '300019',
  THIRDPAYER_CLAIMBENEF = '300020',
  PARTY_SUBJECT_TO_TAXATION = '300010',
  SECOND_INSURED = '300022',
  REVERSIONARY = 'reversionary'
}

export enum PersonType {
    PHYSICAL = '1',
    LEGAL = '2'
}

export type RoleType =
  Roles.CONTRACTOR |
  Roles.CEDENTE |
  Roles.INSURED |
  Roles.VINCOLATARIO |
  Roles.EFFECTIVEHOLDER |
  Roles.EXECUTOR |
  Roles.DELEGATE |
  Roles.LEGAL_GUARDIAN |
  Roles.THIRDPAYER |
  Roles.BENEFICIARY |
  Roles.COSIGNED |
  Roles.EMPLOYER |
  Roles.THIRDREFERENT |
  Roles.ADERENTE |
  Roles.EFFECTIVEHOLDER_BENEF |
  Roles.EFFECTIVEHOLDER_THIRDPAYER |
  Roles.COMPANY_PENSION_FORM |
  Roles.EFFECTIVEHOLDER_CLAIMBENEF |
  Roles.EXECUTOR_CLAIMBENEF |
  Roles.THIRDPAYER_CLAIMBENEF |
  Roles.PARTY_SUBJECT_TO_TAXATION |
  Roles.SECOND_INSURED |
  Roles.REVERSIONARY | 
  ''; 
  


import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Variable} from "../../../models/domain-models/variable";
import {Clause, ClauseOutput} from "../../../models/domain-models/clause";
import {PcClause} from "../../../models/pc-portfolio-models/quotation-models/pc-clause";

@Component({
  selector: 're-issue-clauses-and-factors',
  templateUrl: './re-issue-clauses-and-factors.component.html',
  styleUrls: ['./re-issue-clauses-and-factors.component.css']
})
export class ReIssueClausesAndFactorsComponent implements OnChanges {

  @Input() variables: Array<Variable>;
  @Input() clauses: Array<Clause>;
  @Output() updateClauses: EventEmitter<Array<PcClause>> = new EventEmitter<Array<PcClause>>();
  @Output() updateVariables: EventEmitter<Array<Variable>> = new EventEmitter<Array<Variable>>();
  validVariables: boolean = false;

  QUOTATION_CLAUSE_KEY = 'CLAQUO';

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterQuotationClauses();
  }

  private filterQuotationClauses() {
    this.clauses = this.clauses.filter(clause => this.isQuotationClause(clause));
  }

  private isQuotationClause(clause: Clause) {
    return (clause.properties || []).some(property => property.key === this.QUOTATION_CLAUSE_KEY && property.value);
  }

  protected onValidFormVariables(event: boolean) {
    this.validVariables = event;
  }

  onVariablesChange(event: Array<Variable>) {
    if (this.validVariables) {
      this.updateVariables.emit(event);
    }
  }

  onClausesChange(event: ClauseOutput) {
    if (event.formValid && event.clause) {
      this.updateClauses.emit([event.clause]);
    }
  }

}

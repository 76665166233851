// @ts-ignore
declare var angular: angular.IAngularStatic;

export function AjsPassQQCardExt() {
  const passqqCardServicesExt = angular.module('passqq-nmf-integration', []);

  passqqCardServicesExt.config(['$provide', $provide => {

    $provide.decorator('passqqCardQuotationService',
      ['$delegate', 'PassQQContractOrigin', 'CONTRACT_ORIGIN', 'sessionService', 'PassQQNMFSession',
        'functionListService', 'PASSQQ_FUNCTIONS',
        // tslint:disable-next-line:no-shadowed-variable
      function QuotationServiceDecorator($delegate, PassQQContractOrigin, CONTRACT_ORIGIN, sessionService, PassQQNMFSession,
                                         functionService, PASSQQ_FUNCTIONS) {
        const extended = angular.extend({}, $delegate);
        const superQuote2Proposal = extended.quote2Proposal;
        const superEditQuote = extended.editQuote;

        extended.quote2Proposal = function(quote, resourceId, operation) {
          if (quote.product.type.code === '000008' && functionService.isAuthorizedFor(PASSQQ_FUNCTIONS.quote2Prop)) {
            this.getQuoteOrigin(quote).then(contractOrigin => {
              if (contractOrigin) {
                if (contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
                  PassQQNMFSession.openQuoteToProposal(quote.quoteID);
                } else {
                  superQuote2Proposal(quote, resourceId, operation);
                }
              }
            });
          } else {
            superQuote2Proposal(quote, resourceId, operation);
          }
        };

        extended.editQuote = function(resourceId, quote) {
            this.getQuoteOrigin(quote).then(contractOrigin => {
              if (contractOrigin) {
                if (contractOrigin === CONTRACT_ORIGIN.PORTALNEWMOTORFLOW) {
                  PassQQNMFSession.openEditQuote(quote.quoteID);
                } else {
                  superEditQuote(resourceId, quote);
                }
              }
            });
        };

        extended.getQuoteOrigin = quote => {
          let contractOrigin = CONTRACT_ORIGIN.PORTALSTANDARDFLOW;

          return PassQQContractOrigin.get({quoteId: quote.quoteID}).$promise.then(
            result => {
              if (result) {
                contractOrigin = result.origin;
              }
              return contractOrigin;
            }
          );
        };

        return extended;
      }]);
  }]);

  passqqCardServicesExt.factory('PassQQContractOrigin', PassQQContractOrigin);

  PassQQContractOrigin.$inject = ['$resource', 'API_CONF'];
  function PassQQContractOrigin($resource, apiConf) {
    const endpoint = apiConf.server + apiConf.base_url + '/v2/portfolio/quote/:quoteId/origin';
    return $resource(endpoint, {quoteId: '@quoteId'});
  }

  passqqCardServicesExt.service('PassQQNMFSession', PassQQNMFSession);

  PassQQNMFSession.$inject = ['sessionService'];
  function PassQQNMFSession(sessionService) {
    this.openEditQuote = _openEditQuote;
    this.openQuoteToProposal = _openQuoteToProposal;

    function _openEditQuote(quoteNumber) {
      const paramsNMF = {
        route: 'quote',
        proposalNumber: undefined,
        policyNumber: undefined,
        quoteNumber,
        createCopy: false,
        editQuote: true
      };

      sessionService.open('newMotorContract', null, '', true, paramsNMF, false);
    }

    function _openQuoteToProposal(quoteNumber) {
      const paramsNMF = {
        route: 'quote',
        proposalNumber: undefined,
        policyNumber: undefined,
        quoteNumber,
        createCopy: false,
        editQuote: false
      };

      sessionService.open('newMotorContract', null, '', true, paramsNMF, false);
    }

  }
}

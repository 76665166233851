<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
    {{ '_MODAL_TITLE_' | translate | uppercase }}
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()">
      {{'_MODAL_CLOSE_' | translate | uppercase }}
    </button>
  </rgi-rx-panel-footer>
  <p>
    {{'_MODAL_DELETE_REPORT_' | translate | uppercase }}
  </p>
</rgi-rx-panel>

import { Component, Input, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { LpcDataTableHeaders } from '../../../models/data-table.model';

@Component({
  selector: 'lpc-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.css']
})
export class DataGridComponent implements OnInit {
  // Input properties
  /** @description Data array for table rows */
  @Input() data: any[];
    /** @description Array of table headers */
  @Input() headers: LpcDataTableHeaders[];
  /** @description Data-qa attribute value */
  @Input() dataQa: string;
  /** @description Sorting function for ngFor loop */
  @Input() sortingFunction: (a: KeyValue<number, string>, b: KeyValue<number, string>) => number;

  constructor() { }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  ngOnInit(): void {
  }

}

import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import {
  BeneficiaryDefinition, BeneficiaryRole,
  Definition,
  FactorDefinition,
  PaymentTypeDefinition,
  PostsalesError, Role
} from '../../models/postsales-operations-response.model';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';

@Component({
    selector: 'lpc-total-surrender',
    templateUrl: './total-surrender.component.html',
    styleUrls: ['./total-surrender.component.css']
  })
export class TotalSurrenderComponent extends AbsOperationComponent {

  protected operationDataKey = 'total-surrender';
  public listProductFactor: FactorDefinition[] = [];
  public requestFactor: RequestFactor[] = [];

  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];

  public action: string;

  private $paymentTypes: PaymentTypeDefinition[] = [];

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  protected anagSubj = null;

  private _beneficiaryData: any[];

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  get beneficiaryType(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }


  public get hasProductFactors(): boolean {
    return !!this.listProductFactor.length;
  }

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }


  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anagService: AnagService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anagService);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(tap(result => {
        LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
        this.insuredSettlements = result.data.operationData.data.insuredSettlements;
        this.tableDefinitions = result.definitions.settlement as Definition[];
        this.action = result.data.operationData.data.action;
        this.listProductFactor = PlcObjectUtils.asValidArray(result.definitions.productFactors as FactorDefinition[]);
        if (result.definitions.beneficiaries != null) {
          this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        }
        this._beneficiaryData = result.data.operationData.data.beneficiaries;
        if (!!this._beneficiaryData) {
          this.formGroup.get('beneficiaries').setValue(
            (this._beneficiaryData as any[]).map(beneficiary => {
              return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
            }),
            {emitEvent: false}
          );
        }
      })).subscribe()
    );
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    if (this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
      return this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id)
        .pipe(switchMap(_ =>
          super.updateDraft(step, reload, opDataType).pipe(
            switchMap(result => {
              this.detectChanges();
              this.insuredSettlements = result.data.operationData.data.insuredSettlements;
              this.tableDefinitions = result.definitions.settlement as Definition[];
              this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
              LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
              this.beneficiariesDefinitions = result.definitions.beneficiaries &&
              (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
              this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
              this.cd.detectChanges();
              return Promise.resolve(result);
            })
          )
        ));
    }
    return of({});
  }

  updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe((result) => {
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    }));
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      beneficiaries: new UntypedFormControl([])
    });
  }

  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }


  protected getTransformedOperationData(): any {
    const obj: any = {
      beneficiaries: this.formGroup.getRawValue().beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        }),
      action: this.action
    };
    if (this.requestFactor.length > 0) {
      obj.listProductFactor = this.requestFactor;
    }
    return obj;
  }

  confirmButtonVisibility() {
    return !this.hasBlockingErrorsOnSteps('quotation') && !!this.insuredSettlements && !!this.insuredSettlements.length;
  }

  onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
        })
      );
    }
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }


}

export enum PTFALL_SUBJECT_ROLES_NEW {
    CONTRAENTE = '1',
    COINTESTATARIO = '2',
    ASSICURATO = '4',
    PROPRIETARIO_VEICOLO = '3',
    ENTE_VINCOLANTE = '5',
    CONDUCENTE = '6',
    BENEFICIARIO = '100',
    LEGALE_RAPPRESENTANTE = '101',
    COOBBLIGATO = '102',
    PARTECIPANTE_ATI = '103',
    DEBITORE = '104',
    CONTROGARANTE = '105',
    SOGGETTO_OPERANTE = '106',
    SOGGETTO_PER_CONTO = '107',
    PERCIPIENTE = '108',
    PAGATORE = '109',
    ADERENTE = '110',
    LOCATARIO = '111',
    DELEGATO = '112',
    TITOLARI_EFFETTIVI = '113',
    ACCOUNT = '114',
    USUFRUTTUARIO = '115',
    ACQUIRENTE = '116',
    REVERSIONARIO = '300000',
    BENEFICIARIO_CEDOLA = '300001',
    DATORE_DI_LAVORO = '300002',
    PIGNORANTE = '300003',
    DELEGATO_AL_PAGAMENTO = '300006',
    ESERCENTE_POTESTA_GENITORIALE = '300007',
    TUTORE_LEGALE = '300008',
    TITOLARE_OPERAZIONE = '300009',
    SOGGETTO_SU_CUI_GRAVA_IL_CARICO_FISCALE = '300010',
    AZIENDA_FORMA_PENSIONISTICA_DI_PROVENIENZA = '300011',
    TITOLARE_EFFETTIVO_DI_LIQUIDAZIONE = '300012',
    SOGGETTO_LEGATO = '300013',
    FIDUCIANTE = '300014'
}

export const assetsList$ = [
        {
            aniaCode: 'A',
            code: 'SX0001',
            description: 'Autovettura.'
        },
        {
            aniaCode: 'C',
            code: 'SX0003',
            description: 'Autocarro.'
        },
        {
            aniaCode: 'M',
            code: 'SX0004',
            description: 'Motocicli.'
        },
        {
            aniaCode: 'B',
            code: 'SX0002',
            description: 'Autobus.'
        },
        {
            aniaCode: 'Q',
            code: 'SX0005',
            description: 'Motocarri.'
        },
        {
            aniaCode: 'R',
            code: 'SX0006',
            description: 'Rimorchi.'
        },
        {
            aniaCode: 'S',
            code: 'SX0007',
            description: 'Macchine Agricole.'
        },
        {
            aniaCode: 'T',
            code: 'SX0008',
            description: 'Trattrice, Macchine operatrici'
        },
        {
            aniaCode: 'W',
            code: 'SX0009',
            description: 'Ciclomotori.'
        },
        {
            aniaCode: 'N',
            code: 'SX0010',
            description: 'Natante.'
        }
    ];

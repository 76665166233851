import { AfterViewInit, Component, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { PlcObjectUtils } from '../../../../utils/plc-object-utils';
import { KarmaFundDefinition } from '../../model/karma-fund-definition';
import { PlcFormatterUtils } from '../../utils/PlcFormatter-utils';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../../services/currency-cache.service';
import { RgiCountryLayerCurrencyFormatPipe } from '@rgi/country-layer';
import { DEFAULT_CURRENCY_SYMBOL } from '../../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-karma-fund-button[totalAmount][definition]',
  templateUrl: './lpc-karma-fund-button.component.html',
  styleUrls: ['./lpc-karma-fund-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaFundButtonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcKarmaFundButtonComponent),
      multi: true
    },
    RgiCountryLayerCurrencyFormatPipe

  ]
})
export class LpcKarmaFundButtonComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator, AfterViewInit {

   /* public get isSelected(): boolean {
    return this.formGroup.get('percent').value > 0 || this.$selected;
  } */

  public get isPercent(): boolean {
    return this.formGroup.get('isPercent').value;
  }

  currencyCode = DEFAULT_CURRENCY_SYMBOL;

  constructor(
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected currencySymbolPipe: RgiCountryLayerCurrencyFormatPipe) {
      this.currencyCode = !!currencyService.currency ? currencySymbolPipe.transform(currencyService.currency) : this.currencyCode;
    }

  @Input() public definition: KarmaFundDefinition;
  @Input() public totalAmount: number;
  @Input() public sliderProperty: string;

  @Input() public preselect = false;

  percent = 0;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl(false),
    percent: new UntypedFormControl(),
    amount: new UntypedFormControl(),
    isPercent: new UntypedFormControl(true)
  });

  private $subscriptions: Subscription[] = [];

  protected $selected = false;

  protected $clickedInside: boolean;

  /* onClick() {
    this.$clickedInside = true;
    this.$selected = true;
  }

  @HostListener('document:click')
  onClickOut() {
    if (!this.$clickedInside) {
      this.$selected = false;
    }
    this.$clickedInside = false;
  } */

  ngOnInit() {
    this.initializeInputs();
    this.$subscriptions.push(
      this.formGroup.get('percent').valueChanges.subscribe(value => {
        this.formGroup.get('amount').setValue(
          !!this.totalAmount ? PlcObjectUtils.roundToDecimal(value * this.totalAmount / 100, 2) : 0,
          { emitEvent: false }
          );

        this.onChange(value / 100);
        this.onTouch();
      }),
      this.formGroup.get('amount').valueChanges.subscribe(value => {
        this.percent = !!this.totalAmount ? PlcObjectUtils.roundToDecimal(value / this.totalAmount * 100, 2) : 0;
        this.formGroup.get('percent').setValue(PlcFormatterUtils.getPercentValueConverted(this.percent), { emitEvent: false });
        this.onChange(this.percent / 100);
        this.onTouch();
      }),
      this.formGroup.get('active').valueChanges.subscribe(value => {
        if (value) {
          if (this.definition.minPercentAllocation != null && this.definition.maxPercentAllocation != null) {
            if (this.definition.minPercentAllocation === this.definition.maxPercentAllocation) {
              if (!this.definition.percent) {
                this.percent = this.definition.minPercentAllocation * 100;
                this.formGroup.get('percent').setValue(PlcFormatterUtils.getPercentValueConverted(this.percent));
              }
              this.disableInputs();
            } else {
              this.enableInputs();
            }
          } else {
            this.enableInputs();
          }
          if (this.sliderProperty === 'PERC') {
            this.formGroup.get('isPercent').setValue(true);
            this.formGroup.get('isPercent').disable();
          } else if (this.sliderProperty === 'IMP') {
            this.formGroup.get('isPercent').setValue(false);
            this.formGroup.get('isPercent').disable();
          }
        } else {
          this.formGroup.get('percent').setValue(0);
          this.formGroup.get('amount').setValue(0);
          this.disableInputs();
        }
      })
    );
  }

  ngAfterViewInit(): void {
    // the preselect variable is valorized from the parent component which in case of just one element it will be set to true
    if (this.preselect) {
      this.formGroup.get('active').setValue(this.preselect);
      this.formGroup.get('active').disable();
      this.formGroup.get('percent').setValue(100);
      this.disableInputs();
    }
  }

  initializeInputs() {
    this.disableInputs();
    if (this.definition.minPercentAllocation === this.definition.maxPercentAllocation) {
      if (!this.definition.percent) {
        this.formGroup.get('percent').setValue(0);
        this.formGroup.get('active').setValue(false, { emitEvent: false });
      } else {
        this.formGroup.get('active').setValue(true, { emitEvent: false });
        this.formGroup.disable({ emitEvent: false });
      }
    }
  }

  disableInputs() {
    this.formGroup.get('isPercent').disable({ emitEvent: false });
    this.formGroup.get('percent').disable({ emitEvent: false });
    this.formGroup.get('amount').disable({ emitEvent: false });
  }

  enableInputs() {
    this.formGroup.get('isPercent').enable({ emitEvent: false });
    this.formGroup.get('percent').enable({ emitEvent: false });
    this.formGroup.get('amount').enable({ emitEvent: false });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalAmount) {
      if (!changes.totalAmount.currentValue) {
        this.formGroup.get('amount').setValue(
          0, { emitEvent: false }
        );
        // this.formGroup.disable({ emitEvent: false });
      } else {
        this.formGroup.get('amount').setValue(
          this.percent * changes.totalAmount.currentValue, { emitEvent: false }
          );
        // this.formGroup.enable({ emitEvent: false });
      }
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  writeValue(percent: number): void {
    this.percent = PlcFormatterUtils.getPercentValueConverted(percent);
    if (!!percent) {
      this.formGroup.patchValue({
        percent: this.percent,
        amount: this.percent * this.totalAmount,
      }, { emitEvent: false });
      this.formGroup.get('active').setValue(true);
    } else {
      this.formGroup.patchValue({
        percent: 0,
        amount: 0,
      }, { emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const obj: ValidationErrors = {};
    if (this.formGroup.get('percent').value / 100 > this.definition.maxPercentAllocation ) {
      obj.max = { value: this.formGroup.get('percent').value / 100, limit: this.definition.maxPercentAllocation };
    } else if (this.formGroup.get('percent').value / 100 < this.definition.minPercentAllocation ) {
      obj.min = { value: this.formGroup.get('percent').value / 100, limit: this.definition.minPercentAllocation };
    }
    return !!Object.keys(obj).length ? obj : null;
  }

  onChange(obj: number) {
  }

  onTouch() {
  }

  roundValue(field: string) {
    const value: number = this.formGroup.get(field).value;
    this.formGroup.get(field).setValue(PlcObjectUtils.roundToDecimal(value, 2));
  }
}

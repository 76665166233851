import { ExposureEntity } from './exposure-entity';
import { SubjectEntity } from './subject-entity';
import { BeneficiaryTypeEntity } from './beneficiary-type-entity';
import { MethodPaymentEntity } from './method-payment-entity';
import { DocumentEntity } from './document-entity';


export class PotentialPaymentEntity {
    public id: number;
    public paymentType: string; // tipo pagamento (enumerato)
    public paymentTypeDescr: string; // tipo pagamento (description)
    public paymentManagement: string; // gestione pagamento
    public receiptType: string; // tipo quietanza (enumerato)
    public receiptTypeDescr: string; // tipo quietanza (description)
    public receiptDate: Date; // data quietanza
    public exposureList: Array<ExposureEntity>; // lista coperture da pagare in quietanza
    public totalAmount: number;
    public totalAmountNet: number; // importo al netto  ... importo da pagare
    public primary: boolean;
    public listBeneficiary: Array<SubjectEntity>; // lista beneficiary (BNF)
    public listPayee: Array<SubjectEntity>; // lista percipienti (PRC)
    public editableBenefPayee: boolean;
    public listPaymentMethod: Array<any>; // lista metodi di pagamento
    public beneficiaryTypeMap: Map<string, string>; // listatipi beneficiario
    public procedureCode: string;
    public generatePayment: boolean; // indica se deve nascere la liquidazione

    public beneficiaryTypeList: Array<BeneficiaryTypeEntity>;
    public separateReceipt: boolean;
    public confirmCCIAnswer: string;
    public methodPayment: MethodPaymentEntity;
    public paybackRequest: boolean;
    public driveType: string;
    public documentList: Array<DocumentEntity>;
      // multicurrency
  public currencyCode: string;
  public currencyCodeDate: Date;
  public currencyAmount: number;
  public legalFeeCurrencyAmount: number;

  public note: string;
}

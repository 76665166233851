import { EnviromentsService } from "@rgi/life-issue-card";
import { ObjectUtil } from "./object-util";


export class PathUtil {

  hostPath: string;

  constructor(
      private apiConf: any,
      protected environment: EnviromentsService
  ) {
      this.hostPath = this.apiConf.api.portal.host + '/api/rest/gel/';

  }

  public getHostApplicationPath(): string {
    return this.hostPath;
  }

  public getPathNiceActimizeCheck(idAuth: string): string {

    return `${this.getHostApplicationPath()}parties/niceactimize/authorizations/${ObjectUtil.convertToIdentifier(idAuth)}/checks`
  }


}

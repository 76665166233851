import {Injectable} from '@angular/core';
import {RgiRxHttpError} from '@rgi/rx/http';
import {ModalService} from '@rgi/rx/ui';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, map, switchMap, take} from 'rxjs/operators';
import {
  GPAppInclusionModalOnCloseData,
  GPIncludeApplicationModalComponent
} from '../group-policy-components/group-policy-modals/group-policy-modal-include-application/include-application-modal.component';
import {productCategories} from '../group-policy-models/group-policy-application';
import {GPLifeAppPayload} from '../group-policy-models/group-policy-flow-integrations';
import {
  ApplicationInclusionModalData,
  ContractDetails,
  TypeAdhesion
} from '../group-policy-models/group-policy-inquiry';
import {GroupPolicyResourceService} from '../group-policy-resources/group-policy-resource.service';
import {GroupPolicyCrossService} from './group-policy-cross.service';
import {GroupPolicyIntegrationService} from './group-policy-integration.service';
import {GPIdentificationEntity} from '../group-policy-models/group-policy-domain-types';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyApplicationInclusionService {

  constructor(
    protected modalService: ModalService,
    protected resourceService: GroupPolicyResourceService,
    protected crossService: GroupPolicyCrossService,
    protected integrationService: GroupPolicyIntegrationService
  ) {
  }

  handleApplicationInclusion(modalData: ApplicationInclusionModalData, contractDetail?: ContractDetails) {
    const proposalNumber = modalData.proposalNumber;
    this.getFlowUpdatedModalData$(modalData)
      .subscribe((updModalData: ApplicationInclusionModalData | null) => {
        if (updModalData) {
          const modal = this.modalService.open(GPIncludeApplicationModalComponent, updModalData);
          modal.enableClickBackground = false;
          modal.onClose.pipe(take(1)).subscribe((onCloseData: GPAppInclusionModalOnCloseData | null) => {
            if (onCloseData && 'confirm' === onCloseData.event) {
              switch (onCloseData.choice) {
                case 'upload':
                  this.uploadFileForBatch(proposalNumber, onCloseData);
                  break;
                case 'startInclusionFlow':
                  this.startInclusionFlow(onCloseData, modalData, contractDetail);
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
  }


  protected manageAppInclusionApiErr() {
    return (err: RgiRxHttpError, _caught) => {
      if (err.status > 500) {
        this.crossService.openBaseModal('An error occurred');
      }
      return of(null);
    };
  }

  protected uploadFileForBatch(proposalNumber: string, onCloseData: GPAppInclusionModalOnCloseData) {
    this.resourceService.uploadFile$(proposalNumber, onCloseData.file.name, onCloseData.file).pipe(
      switchMap((e) => {
        if (!e) {
          return this.crossService.openBaseModal('_GP_._LABEL_._FILE_UPLOAD_OK_').pipe(take(1));
        }
        return of(e);
      }),
      catchError((err) => {
        return of(err);
      })
    ).subscribe();
  }

  protected startInclusionFlow(onCloseData: GPAppInclusionModalOnCloseData,
                               modalData: ApplicationInclusionModalData, contractDetail?: ContractDetails) {
    switch (modalData.productCategory.code) {
      case productCategories.MOTOR:
        this.integrationService.startMotorFlow(onCloseData.inclusionFlowPayload, modalData.idComponent);
        break;
      case productCategories.LIFE:
        if (!!contractDetail) {
          this.integrationService.startLifeFlow(modalData.idComponent, this.getLifePayload(contractDetail),
            modalData.isCached, modalData.route);
        } else {
          this.crossService.openBaseModal('wip');
        }
        break;
    }
  }


  protected getLifePayload(contractDetail: ContractDetails): GPLifeAppPayload {
    const subjectId = (contractDetail.proposalSettings.typeAdhesion === TypeAdhesion.CAI) ?
      null : {objectId: contractDetail.proposalSettings.idPolicyHolder + ''};
    return {
      productType: contractDetail.proposalSettings.productType + '',
      nodeCode: contractDetail.proposalSettings.nodeCode + '',
      idpv: contractDetail.proposalSettings.idNode + '',
      subject: subjectId,
      product: {code: contractDetail.proposalSettings.productCode + ''},
      idMasterPolicy: contractDetail.proposalSettings.idMasterContract + ''
    } as GPLifeAppPayload;
  }

  protected getFlowUpdatedModalData$(modalData: ApplicationInclusionModalData): Observable<ApplicationInclusionModalData> {
    const proposalNumber = modalData.proposalNumber;
    return of(modalData).pipe(
      concatMap((mData: ApplicationInclusionModalData) => {
        return this.resourceService.getProductCategory$(proposalNumber).pipe(
          map((prodCategory: GPIdentificationEntity) => {
            mData.productCategory = prodCategory;
            return mData;
          }),
        );
      }),
      concatMap((mData: ApplicationInclusionModalData) => {
        return this.getFlowAdditionalInfo$(mData, proposalNumber);
      }),
      map((mData: ApplicationInclusionModalData) => mData)
    );
  }

  private getFlowAdditionalInfo$(mData: ApplicationInclusionModalData, proposalNumber: string): Observable<ApplicationInclusionModalData> {
    switch (mData.productCategory.code) {
      case productCategories.MOTOR:
        return this.resourceService.retrieveApplicationInclusionField$(proposalNumber, 'CREATION_REASON').pipe(
          map(dataField => {
            mData.fields = [
              dataField
            ];
            return mData;
          }),
          catchError(() => {
            return of(mData);
          })
        );
      default:
        return of(mData);
    }
  }
}

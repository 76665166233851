import {RequestFactor} from './../../models/factor.model';
import {UntypedFormGroup} from '@angular/forms';
import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, Optional, Output, ViewEncapsulation} from '@angular/core';
import {Factor} from '../../models/factor.model';
import {FactorDefinition} from '../../models/postsales-operations-response.model';
import {PlcDateUtils} from '../../utils/plc-date-utils';
import moment from 'moment';
import { EMPTY_STR } from '../../models/consts/lpc-consts';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { CurrencyCacheService, LpcCurrencyCache } from '../../services/currency-cache.service';

@Component({
  selector: 'lpc-factor-adapter',
  templateUrl: './lpc-factor-adapter.component.html',
  styleUrls: ['lpc-factor-adapter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LpcFactorAdapterComponent implements AfterViewInit {

  public factorDefinitionList: FactorDefinition[];
  public summaryFactors: RequestFactor[];
  public convertedFactors: Factor[];
  public locale: string;

  private formats = [
    moment.ISO_8601,
    'DD/MM/YYYY  :)  HH*mm*ss'
  ];

  @Input() form: UntypedFormGroup;
  @Input() summary = true;
  @Input() emitValueOnInit: boolean = false
  @Input() set factors(fattori: FactorDefinition[]) {
    if (!!fattori) {
      this.convertedFactors = fattori.map(factor => this.convertPostsalesFactor(factor));
      this.factorDefinitionList = fattori;
    }
  }

  @Output() changeValEmitter = new EventEmitter<any[]>();


  mapTypeCode = new Map([
    ['0', 'list'],
    ['1', 'number'],
    ['2', 'list'],
    ['3', 'boolean'],
    ['4', 'date'],
    ['5', 'string']
  ]);

  constructor(
    protected el: ElementRef, 
    public decPipe: RgiCountryLayerNumberFormatPipe,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
    ) {}

  ngAfterViewInit() {
    this.locale = this.currencyService.locale
    if(this.emitValueOnInit) {
        const reqFactor = this.getRequestFactor();
        this.changeValEmitter.emit(reqFactor);
    }
  }

  /* setFactors(fattori: FactorDefinition[]) {
    this.convertedFactors = [];
    this.factorDefinitionList = fattori;
    fattori.map((f) => {
      this.convertedFactors.push(this.convertPostsalesFactor(f));
    });
  } */

  convertPostsalesFactor(psf: FactorDefinition): Factor {
    if (!psf.typeCode) {
      throw new Error('Factor definition type code missing, can\'t convert it to Factor');
    }
    const newFactor: Factor = {
      visible: psf.visible,
      modifiable: psf.editable,
      type: psf.typeCode,
      description: psf.label,
      values: [],
      value: null,
      code: psf.code,
      compulsory: psf.required,
      formatter: psf.formatter,
      helpFile: psf.helpFile
    };

    if (this.mapTypeCode.get(psf.typeCode) === 'date') {
      if (psf.defaultValue) {
        newFactor.value = this.switchMonthDay(psf.defaultValue);
      }
    } else {
      newFactor.value = psf.defaultValue;
    }

    if (psf.listValue != null) { // TIPO LISTA
      if (psf.listValue.length > 0) {
        psf.listValue.forEach((opt) => {
          newFactor.values.push({
            description: opt.description,
            value: opt.code,
            order: opt.order
          });
        });
      }
    }

    return newFactor;
  }

  private convertFormFactors(form: UntypedFormGroup): RequestFactor[] {
    const converted: RequestFactor[] = [];
    if (form != null && form.controls != null) {
      Object.keys(this.form.controls).forEach(key => {
        converted.push({
          code: key,
          label: null,
          value: null,
          userInput: false,
          typeCode: null
        });
      });
    }

    converted.map(factor => {
      const definitionFactor: FactorDefinition = this.factorDefinitionList.find(f => f.code === factor.code );
      const actualValue = form.get(factor.code).value;
      if (definitionFactor != null) {
        if (this.mapTypeCode.get(definitionFactor.typeCode) === 'date' && moment(definitionFactor.defaultValue, this.formats, true).isValid()) { // SE DATA -> CONVERTO
          factor.value = PlcDateUtils.localDateToString(PlcDateUtils.dateToLocaleDate(actualValue));
        } else {
          factor.value = actualValue;
        }
        if (definitionFactor.defaultValue !== factor.value) {
          factor.userInput = true;
        }
        factor.label = definitionFactor.label;
        factor.typeCode = definitionFactor.typeCode;
      } else {
        console.error('Fattore ' + factor.code + ' non trovato nelle definitions');
      }
    });

    return converted;
  }

  convertValuesForSummary(converted: RequestFactor[]): RequestFactor[] {
    const factorForSummary = [];
    converted.forEach(factor => {
      const definitionFactor: FactorDefinition = this.factorDefinitionList.find(f => f.code === factor.code );
      let newValue = null;
      if (
        this.mapTypeCode.get(factor.typeCode) === 'list' ||
        this.mapTypeCode.get(factor.typeCode) === 'boolean'
      ) { // SE LISTA -> PRENDO IL VALORE DALLE OPTION
        if (definitionFactor != null) {
          if (factor.value != null && definitionFactor.listValue != null && definitionFactor.listValue.length > 0) {
            newValue = definitionFactor.listValue.find((f) => f.code === factor.value).description;
          }
        } else {
          console.error('Fattore ' + factor.code + ' non trovato nelle definitions');
        }
      } else {
        newValue = factor.value;
      }

      factorForSummary.push({
        label: factor.label,
        value: newValue,
        type: factor.typeCode,
        formatter: !!definitionFactor ? definitionFactor.formatter : null
      });

    });
    return factorForSummary;
  }

  switchMonthDay(date: string): string {
    const dateSplit = date.split('/');
    return [dateSplit[1], dateSplit[0], dateSplit[2]].join('/');
  }

  checkDirty(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(field => {
      if (!form.get(field).valid) {
        form.get(field).markAsDirty();
      }
    });
  }

  updateFactors(submit: boolean = false) {
    if (submit) {
      this.checkDirty(this.form);
    }
    const reqFactor = this.getRequestFactor();
    this.changeValEmitter.emit(reqFactor);
  }


  getSummaryFormFactor() {
    const rf = this.convertFormFactors(this.form).map(factor => {
      if (factor.typeCode === '1' && !!factor.value) {
        factor.value = factor.value.replace(',', '.');
        return factor;
      } else {
        return factor;
      }
    });
    return this.convertValuesForSummary(rf);
  }

  public getRequestFactor(): RequestFactor[] {
    const rf = this.convertFormFactors(this.form).map(factor => {
      if (factor.typeCode === '1' && !!factor.value) {
        factor.value = factor.value.replace(',', '.');
        return factor;
      } else {
        return factor;
      }
    });
    this.summaryFactors = this.convertValuesForSummary(rf);
    return rf;
  }

  formatFactor(factorInput): string {
    let factorFormatted = factorInput.value;
    if (factorInput.formatter != null && factorInput.type === '1') {
      if (factorInput.value != null && factorInput.value !== '-1' && factorInput.value !== EMPTY_STR) {
        const decimal = +factorInput.formatter.precision;
        if (decimal > 0) {
          if (factorInput.formatter.grouping && +factorInput.value != null) {
            const decimalNumberFormatterOptions: Intl.NumberFormatOptions = {
              style: 'decimal',
              maximumFractionDigits: decimal,
              minimumFractionDigits: decimal
            };
            factorFormatted = this.decPipe.transform(+factorInput.value, '', decimalNumberFormatterOptions);
          } else {
            factorFormatted = this.decPipe.transform(+factorInput.value);
          }
        }
      }
    } else if (factorInput.type === '4') {
      factorFormatted = moment(factorInput.value).format('DD/MM/YYYY');
    } else if (factorInput.type === '0' && !!factorInput.values.find(val => val.value === factorInput.value)) {
      factorFormatted = factorInput.values.find(val => val.value === factorInput.value).description;
    } else if (factorInput.type === '3' && !!factorInput.value && !!factorInput.values.length) {
      /* Case of type boolean: is matched the value selected with the possible values from PassProduct
         and returned the description
      */
      factorInput.values.map(factorInputEl => {
        if (!!factorInputEl.value &&  factorInputEl.value === factorInput.value) {
          factorFormatted = factorInputEl.description;
        }
      });
    }
    return factorFormatted;
  }

}

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Factor} from '../../../models/pc-portfolio-models/factor-models/factor';
import {Package} from '../../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {AssetSection} from '../../../models/domain-models/asset-section';
import {Unit} from '../../../models/domain-models/unit';
import {ReIssueRefactorService} from '../../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service';
import {formatNumber} from '@angular/common';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(public refactorService: ReIssueRefactorService,
              private translationService: RgiRxTranslationService) {
  }

  toFormGroup(sections: AssetSection[]): UntypedFormGroup {
    const group: any = {};
    sections.forEach(section => {
      if (section.unitList) {
        section.unitList.forEach((unit: any) => {
          const unitControl = section.code + '_' + unit.code;
          group[unitControl] = this.toUnitFormControl(unit.selected, unit.enabled);
          const factors = unit.factors;
          if (!factors || factors.length === 0) {
            return;
          }
          factors.forEach((factor: any) => {
            const factorControl = section.code + '_' + unit.code + '_' + factor.code;
            group[factorControl] = this.toFactorFormControl(factor, unit.selected);
          });
        });
      }
    });
    return new UntypedFormGroup(group);
  }

  toPacketFormGroup(packets: Package[]): UntypedFormGroup {
    const group: any = {};
    packets.forEach(packet => {
      group[packet.code] = this.toPacketFormControl(packet.selected, packet.enabled);
    });
    return new UntypedFormGroup(group);
  }

  updateGuaranteesForm(sections: AssetSection[], form: UntypedFormGroup): UntypedFormGroup {
    sections.forEach(section => {
      section.unitList.forEach(unit => {
        if (form.controls[section.code + '_' + unit.code].value !== unit.selected) {
          form.controls[section.code + '_' + unit.code].setValue(unit.selected, {onlySelf: true, emitEvent: false});
        }
        form = this.updateFactorsFormControl(unit, section.code, form);
      });
    });
    return form;
  }

  toFactorFormControl(factor: Factor, isUnitSelected: boolean) {
    const value = factor.value ? factor.value.replace(',', '.') : factor.value || undefined;
    return new UntypedFormControl({
      value,
      disabled: !isUnitSelected
    }, factor.mandatory /*&& isUnitSelected*/ ? Validators.required : null);
  }

  toUnitFormControl(value: boolean, enabled: boolean) {
    return new UntypedFormControl({value, disabled: !enabled});
  }

  toPacketFormControl(value: boolean, enabled: boolean) {
    return new UntypedFormControl({value, disabled: !enabled});
  }


  updateFactorsFormControl(unit: Unit, section: string, form: UntypedFormGroup): UntypedFormGroup {
    if (!unit.factors) {
      return form;
    }
    const factorCodes = unit.factors.map(factor => factor.code);
    factorCodes.forEach(fKey => {
      const factors: Array<Factor> = this.refactorService.variableToSection(unit.factors);
      const factor = factors.find(factor => factor.code === fKey);
      if (factor.modifiable && unit.selected) {
        form.controls[section + '_' + unit.code + '_' + fKey].enable({onlySelf: true, emitEvent: false});
      } else {
        form.controls[section + '_' + unit.code + '_' + fKey].disable({onlySelf: true, emitEvent: false});
      }
      const valueFormatted = this.formattedFactorValue(factor);
      form.controls[section + '_' + unit.code + '_' + fKey].setValue(valueFormatted, {
        onlySelf: true,
        emitEvent: false
      });
    });
    return form;
  }

  formattedFactorValue(factor: Factor): any {
    let result: any = factor.value;
    if (result && result !== '') {
      result = this.parseToNumberAndFormat(result);
      if (parseInt(result, 10) > -1) {
        if (factor.format === '%1[.2g]') {
          result = formatNumber(result, 'it-IT', '1.2-2');
        }
      }
      return result;
    }
  }

  parseToNumberAndFormat(value) {
    let valueParsed: number = value;
    if (Number.isNaN(Number(value))) {
      valueParsed = value.replaceAll('.', '').replace(',', '.');
    }
    return valueParsed;
  }

  getLocaleId() {
    const currentLanguage = this.translationService.currentLanguage;
    let locale = 'en-EN';
    if (currentLanguage === 'it') {
      locale = 'it-IT';
    }
    return locale;
  }

}

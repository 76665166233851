import {RgiRxDateTimeConfig} from "@rgi/rx/ui";

export enum ADDRESS_TYPE_ENUM {
  BIRTH_PLACE,
  RESIDENCE,
  RESIDENCE_FINDER,
  OTHER
}

export enum ANAG_TABS {
  FIND_PARTY,
  PARTY_MERGER
}

export enum ANAG_DYNAMIC_OBJ_MODE {
  VIEW,
  EDIT,
  INSERT,
  ADDRESS,
}

export enum ANAG_DOCUMENT_OBJ_MODE {
  EDIT,
  DRIVING_LICENCE_TYPE
}

export enum ANAG_RATING_OBJ_MODE {
  EDIT
}

export type AnagTabsStrings = keyof typeof ANAG_TABS;

export const ADDRESS_LAYOUT_VISIBLE = 'V';
export const ADDRESS_LAYOUT_HIDDEN = 'H';
export const ADDRESS_STATE_OPTIONAL = 'O';
export const ADDRESS_STATE_MANDATORY = 'M';

export const ANAG_STATES = [
  {code: '1', description: '_ANAG_._LABEL_._STATUS_PROCESSED_'},
  {code: '0', description: '_ANAG_._LABEL_._STATUS_NOT_PROCESSED_'}
];

export const ANAG_FUNCTIONS = {
  search: 'anag.party.find',
  merge: 'anag.party.grouping',
  insert: 'anag.party.new',
  edit: 'anag.party.edit',
  view: 'anag.party.find',
  delete: 'anag.party.delete',
  physicalDelete: 'anag.party.physicalDelete',
  normalize: 'anag.address.normalize',
  editRating: 'anag.rating.edit',
  addLicenseType: 'anag.add.licensetype',
  editLicenseType: 'anag.update.licensetype',
  deleteLicenseType: 'anag.delete.licensetype',
  limitationManagement: 'anag.limitationManagement'

};

export const CORE_PARTY_CONFIG = {
  partyKey: 'PIUniqueKeyManagement',
  partyNumber: 'PartyNumberManagement',
  partyHomonymy: 'PartyHomonymyManagement',
  anagPrivacyConsensoMultiplo: 'AnagPrivacyConsensoMultiplo',
  anagCheckCFDuplicato: 'AnagCheckCFDuplicato',
  anagGruppoCompagnie: 'AnagGruppoCompagnie',
  anagGroupingNodeslevel: 'ReteVenditaLivelloProvvigioniAttive',
  anagShowCounterTerrorismFinder: 'AnagShowCounterTerrorismAllFinder',
  sinistriNodoFittizioAnagrafe: 'SinistriNodoFittizioAnagrafe'
};

export const CORE_ADDRESS_CONFIG = {
  address: 'PIAddressManagement',
  normalize: 'PassCompagniaNormalizzaIndirizzi'
};

export const CORE_ADDRESS_FUNCTION = {
  subjectFinder: 'PartySubjectFinder',
  agentAddress: 'AgentAddress',
  partyBirthAddress: 'PartyBirthAddress',
  partySubjectResidence: 'PartySubjectResidence',
  partySubjetcDomicile: 'PartySubjectDomicile',
  portfolioAddress: 'PortfolioAddress',
  portfolioSubject: 'PortfolioArchiveSubject',
  portfolioAddressAsset: 'PortfolioAddressAsset',
  hiddenPortfolioAddress: 'HiddenPortfolioAddress'
};

export const PACONF_VALUES = {
  FILTER_DEFAULT: 999,
  HIDDEN: '2',
  MANDATORY: '1',
  OPTIONAL: '0',
  DEFAULT: '99'
};

export const PAYMENT_CONFIG_ANAG = {
  PARTY: 'PARTY'
};

export const PAYMENT_MEANS_PARTY = new Map<string, string>([
  ['dactivationsdd', 'activationDateSDD'],
  // ['', 'addressHolder'],
  ['cbankaddress', 'bankAddress'],
  ['cbankname', 'bankName'],
  ['dcancelsdd', 'cancelDateSDD'],
  // ['', 'cartaDiCreditoNumero'],
  ['dcardexpire', 'cartaDiCreditoScadenza'],
  ['idcreditcard', 'cartaDiCreditoTipo'],
  // ['', 'cartaDiCreditoTitolare'],
  // ['', 'cash'],
  // ['', 'ccABI'],
  ['cbic', 'ccBic'],
  // ['', 'ccCAB'],
  // ['', 'ccCIN'],
  // ['', 'ccCINIBAN'],
  // ['', 'ccCodiceNazioneIBAN'],
  ['ciban', 'ccIBAN'],
  // ['', 'ccNumeroConto'],
  ['cchecknumber', 'checkNumber'],
  // ['', 'codeSDD'],
  // ['', 'dateNotification'],
  // ['', 'extensionSubject'],
  ['cforeignaccount', 'foreignAccount'],
  ['cforeigncurrency', 'foreignCurrency'],
  ['cholder', 'holder'],
  // ['', 'ibanHolder'],
  // ['', 'regulationDate'],
  ['crum', 'rum'],
  ['estatesdd', 'stateSDD'],
  // ['', 'transactionDescription'],
  // ['', 'transactionNumber'],
  ['cuic', 'uic']
]);

export const PAYMENT_TYPES = new Map<string, string>([
  ['1', '_ANAG_._LABEL_._DEBIT_'],
  ['2', '_ANAG_._LABEL_._CREDIT_']
]);

export const DATE_TIME_CONFIG: RgiRxDateTimeConfig = {
  locale: 'browser'
};

export const DATE_PICKER_PLACEHOLDERS: Map<string, string> = new Map([
  ['de', 'tt.mm.jjjj'],
  ['en', 'dd/mm/yyyy'],
  ['es', 'dd/mm/aaaa'],
  ['fr', 'jj/mm/aaaa'],
  ['it', 'gg/mm/aaaa']
]);

export const DATE_FORMAT = 'dd/MM/yyyy';


export enum ADDRESS_INPUT_TYPE  {
  AUTOCOMPLETE,
  SELECT,
  INPUT
}

export const ADDRESS_FORM_CONFIG = new Map<string, any>([
['address', {type: ADDRESS_INPUT_TYPE.INPUT}],
['addressAddition', {type: ADDRESS_INPUT_TYPE.INPUT}],
['adminLevel1', {type: ADDRESS_INPUT_TYPE.AUTOCOMPLETE, optValue: 'description', optLabel: 'description'}],
['adminLevel1Short', {type: ADDRESS_INPUT_TYPE.AUTOCOMPLETE, optValue: 'description', optLabel: 'description'}],
['adminLevel2', {type: ADDRESS_INPUT_TYPE.AUTOCOMPLETE, optValue: 'description', optLabel: 'description'}],
['adminLevel2Short', {type: ADDRESS_INPUT_TYPE.AUTOCOMPLETE, optValue: 'description', optLabel: 'description'}],
['adminLevel3', {type: ADDRESS_INPUT_TYPE.AUTOCOMPLETE, optValue: 'description', optLabel: 'description'}],
['adminLevel3Short', {type: ADDRESS_INPUT_TYPE.AUTOCOMPLETE, optValue: 'description', optLabel: 'description'}],
['cco', {type: ADDRESS_INPUT_TYPE.INPUT}],
['estensione', {type: ADDRESS_INPUT_TYPE.INPUT}],
['houseNumber', {type: ADDRESS_INPUT_TYPE.INPUT}],
['latitude', {type: ADDRESS_INPUT_TYPE.INPUT}],
['locality', {type: ADDRESS_INPUT_TYPE.INPUT}],
['longitude', {type: ADDRESS_INPUT_TYPE.INPUT}],
['postalAddress', {type: ADDRESS_INPUT_TYPE.INPUT}],
['specialMention', {type: ADDRESS_INPUT_TYPE.INPUT}],
['subLocality', {type: ADDRESS_INPUT_TYPE.INPUT}],
['toponym', {type: ADDRESS_INPUT_TYPE.SELECT, optValue: 'codice', optLabel: 'descrizione'}],
['zip', {type: ADDRESS_INPUT_TYPE.AUTOCOMPLETE, optValue: 'code', optLabel: 'code'}],
]);

export const MULTICOMP_CONFIG = {
  PARAM_GRUPPO_COMPAGNIE: 'AnagGruppoCompagnie'
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AjsConfigurationMotor} from './ajs-configuration-motor';
import {MotorIssueCardModule} from '../motor-issue-card.module';
import {AjsPassQQCardExt} from './passqq/ajs-pass-q-q-card-ext';
import {AjsPassDamageCardExt} from './ptfdamage/ajs-pass-damage-card-ext';
import {MicIntegrationService} from '../mic-integration.service';
import {MicPortalIntegrationService} from './service/mic-portal-integration.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MotorIssueCardModule
  ],
  providers: [
    { provide: MicIntegrationService, useClass: MicPortalIntegrationService }
  ]
})
export class MotorIssueCardPortalIntegrationModule {
  constructor() {
    AjsConfigurationMotor();
    AjsPassQQCardExt();
    AjsPassDamageCardExt();
  }
}

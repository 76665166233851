<div class="rgi-ui-grid-1">
  <div class="rgi-ui-col-12">
    <table rgi-rx-table [dataSource]="detail.risks" *ngIf="detail.risks && detail.risks.length"
           class="rgi-gp-amount-table">
      <ng-container rgiRxColumnDef="{{columnNames.RISKDESCR}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-gp-descr">
          {{'_GP_._LABEL_._BALANCE_ON_TODAY_' | translate}} ({{detail.currency}})
        </td>
        <td rgi-rx-cell *rgiRxCellDef="let row" class="rgi-gp-descr"> {{row.riskDescription}} </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgi-gp-descr" translate>_GP_._QUOTATION_._TOTAL_</td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{columnNames.GROSS}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._QUOTATION_._GROSS_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.gross | gpSignedNumber: row.negative}}</td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell">
          {{riskTotals.gross | number: '1.2-2'}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{columnNames.NET}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._QUOTATION_._NET_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.net | gpSignedNumber: row.negative}}</td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell">
          {{riskTotals.net | number: '1.2-2'}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{columnNames.TAXES}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._QUOTATION_._TAX_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.taxes | gpSignedNumber: row.negative}}</td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell">
          {{riskTotals.taxes | number: '1.2-2'}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{columnNames.SSN}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._QUOTATION_._SSN_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.ssn | gpSignedNumber: row.negative}}</td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell">
          {{riskTotals.ssn | number: '1.2-2'}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{columnNames.COMMISSIONS}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._COMMISSIONS_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.commissions | gpSignedNumber: row.negative}}</td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell">
          {{riskTotals.commissions | number: '1.2-2'}}</td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
      <tr rgi-rx-footer-row *rgiRxFooterRowDef="displayedColumns" class="rgi-ui-row rgi-gp-bg-info"></tr>
    </table>
  </div>
</div>

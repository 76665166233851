import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyQuoteComponent } from './buy-quote.component';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { RgiRxDragDropModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxPanelModule } from '@rgi/rx/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { RgiAnagModule } from '@rgi/anag';
import { LpcErrorMessagesModule } from '../lpc-error-messages/lpc-error-messages.module';

@NgModule({
  imports: [
    CommonModule,
    LpcRequiredModule,
    RgiRxModalModule,
    ReactiveFormsModule,
    RgiRxFormElementsModule,
    RgiRxI18nModule,
    RgiRxPanelModule,
    RgiAnagModule,
    RgiRxDragDropModule,
    LpcErrorMessagesModule
  ],
  declarations: [BuyQuoteComponent],
  exports: [BuyQuoteComponent]
})
export class BuyQuoteModule { }

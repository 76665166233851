import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {SubjectContact} from '../../models/subject-contact';
import {Address} from '../../models/address';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fromPromise } from 'rxjs/internal-compatibility';
import { PlcObjectUtils } from '../../../../utils/plc-object-utils';
import { EMPTY_STR } from '../../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-policy-contact',
  templateUrl: './policy-contact.component.html',
  styleUrls: ['./policy-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PolicyContactComponent),
      multi: true
    }
  ]
})
export class PolicyContactComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public formGroup: UntypedFormGroup;

  public subscriptions: Subscription[] = [];

  @Input() public addresses: Address[] = [];
  @Input() public emails: string[] = [];
  @Input() public homeInsurances: string[] = [];

  @Input() public addressesVisible = false;
  @Input() public emailsVisible = false;
  @Input() public homeInsurancesVisible = false;

  @Input() public disabled = false;
  @Input() public policyNumber: string;

  @Input() public isAddressReceived = false;
  @Input() public handleNewContact = EMPTY_STR;
  public deletableContact: Address = null;
  @Output() eventPropagation = new EventEmitter<string>();

  public value: SubjectContact;

  constructor(
    protected cd: ChangeDetectorRef,
    protected modalService: NgbModal
  ) {
  }

  onChange = (obj: SubjectContact) => {};

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      postalAddress: new UntypedFormControl(),
      email: new UntypedFormControl(),
      phoneOrEmail: new UntypedFormControl(),
      contactType: new UntypedFormControl()
    });
    this.subscriptions.push(
      this.formGroup.get('contactType').valueChanges.subscribe(val => {
        this.cd.detectChanges();
        this.formGroup.get('postalAddress').setValue(null, { emitEvent: false });
        this.formGroup.get('email').setValue(null, { emitEvent: false });
        this.formGroup.get('phoneOrEmail').setValue(null, { emitEvent: false });

        if (val === 'POSTAL_MAIL') {
          this.formGroup.get('postalAddress').setValue(this.addresses[0], { emitEvent: false });
        } else if (val === 'EMAIL') {
          this.formGroup.get('email').setValue(this.emails[0], { emitEvent: false });
        } else {
          this.formGroup.get('phoneOrEmail').setValue(this.homeInsurances[0], { emitEvent: false });
        }
      }),
      this.formGroup.valueChanges.subscribe((val) => {
        this.writeValue(val);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  isSelectedContactType(contactType: string) {
    return this.formGroup.get('contactType').value === contactType;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  writeValue(obj: SubjectContact): void {
    if (obj != null) {
      if (!Object.keys(obj).includes('email')) {
        obj.email = null;
      }
      if (!Object.keys(obj).includes('postalAddress')) {
        obj.postalAddress = null;
      }
      if (!Object.keys(obj).includes('phoneOrEmail')) {
        obj.phoneOrEmail = null;
      }
    }
    this.onChange(obj);
    this.value = obj;
    if (obj !== null) {
      this.formGroup.setValue(obj, { emitEvent: false });
    }
  }

  public isDeleatable(contact: Address): boolean {
    return PlcObjectUtils.equal(this.deletableContact, contact);
  }

  public addNewContact() {
    const modalRef = this.modalService.open(AddContactComponent, {
      centered: true,
      windowClass: 'in',
      size: 'lg',
      backdropClass: 'light-blue-backdrop in'
    });
    this.subscriptions.push(
      fromPromise(modalRef.result).subscribe(address => {
        if (!!address) {
          this.addresses.push(address);
          this.isAddressReceived = true;
          this.deletableContact = address;
          this.formGroup.get('postalAddress').setValue(address);
        }
      })
    );
  }

  deleteContact(address: Address): void {
    this.addresses = this.addresses.filter(contact => !PlcObjectUtils.equal(contact, address));
    this.isAddressReceived = false;
    this.formGroup.get('postalAddress').setValue(null);
  }
}

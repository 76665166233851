<div class="rgi-ui-grid">
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel><span translate>RE_ISSUE.INPUT_PREMIUM</span> (<span translate>{{inputPremiumLabel}}</span>)
        </label>
        <input rgiRxInput (blur)="onInputPremiumChange()"
               type="text" inputmode="numeric"
               [(ngModel)]="input"
               [disabled]="!inputPremiumEnabled"
               data-qa="premium-input">
      </rgi-rx-form-field>
    </div>
  </div>

import { ActiveRoute } from '@rgi/rx/router';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import { of } from 'rxjs';
import { GroupPolicyStateProposalIssue } from '../group-policy-state';
import { GroupPolicyStatelessOpProposalissueService } from './group-policy-stateless-op-proposalissue.service';


export class GroupPolicyStatemanagerProposalissue extends AbstractStateManager<GroupPolicyStateProposalIssue> {

  constructor(activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: GroupPolicyStatelessOpProposalissueService) {
    super(activeRoute, stateStoreService);
    this.initIssueProposal();
  }

  initIssueProposal() {
    const statehome = new GroupPolicyStateProposalIssue(this.activeRoute.key);
    const initHome$ = this.statelesOps.initProposalIssue$(of(statehome), this.activeRoute);
    this.updateState$(initHome$);
  }

}

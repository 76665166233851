<form [formGroup]="reportForm" class="rgi-ui-form-container analytics-form" *ngIf="showForm">
  <h1 class="analytics-report-title">
    <span class="rgi-ui-icon-document"></span>
    {{'_UPDATE_REPORT_' | translate}}
    {{reportDetail.description}}
  </h1>

  <div class="analytics-new-report-input">
    <rgi-rx-form-field class="analytics-flex-col">
      <label rgiRxLabel>{{'_NEW_REPORT_NAME_' | translate}}
      </label>
      <input rgiRxInput formControlName="name" type="text">
    </rgi-rx-form-field>

    <rgi-rx-form-field class="analytics-flex-col">
      <label rgiRxLabel>{{'_SELECT_STREAM_' | translate}}
      </label>
      <select rgiRxNativeSelect formControlName="stream" (change)="selectStream()">
        <option *ngFor="let stream of streamList" value="{{stream.qId}}">{{stream.qName}}</option>
      </select>
    </rgi-rx-form-field>
  </div>
  <div class="analytics-new-report-input">
    <rgi-rx-form-field class="analytics-flex-col">
      <label rgiRxLabel>{{'_SELECT_APP_' | translate}}</label>
      <select rgiRxNativeSelect formControlName="app" (change)="selectApp($event)">
        <option *ngFor="let app of filterDocumentsByStream" value="{{app.qDocId}}">{{app.qDocName}}</option>
      </select>
    </rgi-rx-form-field>
    <div class="analytics-flex-col" *ngIf="showSheet">
      <rgi-rx-form-field>
      <label class="rgi-ui-form-control-label">{{'_SELECT_SHEET_' | translate}}
      </label>
      <rgi-rx-multiselect (selectionChange)="showCharts()"
                          formControlName="sheets">
        <rgi-rx-option *ngFor="let option of sheetSelect" [value]="option.id" [label]="option.title">
          {{ option.title }}
        </rgi-rx-option>
      </rgi-rx-multiselect>
      </rgi-rx-form-field>
    </div>
  </div>

  <!-- QLIK LINK TO OPENED APP  -->
  <div class="rgi-ui-form-group" *ngIf="showSheet" style="margin-top:10px;">
    <button class="analytics-open-app-btn" rgi-rx-button color="primary" (click)="openQlikSense()">
      {{ '_OPEN_APP_QLIK_' | translate }}
    </button>
  </div>

  <div class="analytics-new-report-input">
    <rgi-rx-form-field class="analytics-flex-col">
      <label rgiRxLabel class="rgi-ui-form-control-label">{{'_SELECT_THEME_' | translate}}
        <!--          <span class="analytics-required-fields"> *{{ '_MANDATORY_LBL_' | translate}}</span>-->
      </label>
      <select formControlName="theme" rgiRxNativeSelect >
        <option *ngFor="let theme of themes" value="{{theme.name}}">{{theme.name}}</option>
      </select>
    </rgi-rx-form-field>
  </div>

  <hr style="width: 100%;">


  <div class="analytics-report-container">
    <!-- SETTINGS -->
    <div class="analytics-settings-box">
      <h3 class="analytics-report-title">
        <span class="rgi-ui-icon-settings"></span>
        {{'_REPORT_SETTINGS_' | translate}}
      </h3>
      <div class="analytics-settings">
        <div>
          <rgi-rx-form-field class="analytics-settings-field">
            <input formControlName="enableSelections" rgiRxInput type="checkbox">
            <label rgiRxLabel>{{ '_ENABLE_SELECTIONS_' | translate }}</label>
          </rgi-rx-form-field>
          <rgi-rx-form-field class="analytics-settings-field analytics-settings-input">
            <label rgiRxLabel>{{ '_MAX_ROWS_' | translate }}</label>
            <input rgiRxInput formControlName="maxRows">
          </rgi-rx-form-field>
        </div>
        <div>
          <rgi-rx-form-field class="analytics-settings-field">
            <input formControlName="enableInteractions" rgiRxInput type="checkbox">
            <label rgiRxLabel>{{ '_ENABLE_INTERACTIONS_' | translate }}</label>
          </rgi-rx-form-field>
          <rgi-rx-form-field class="analytics-settings-field analytics-settings-input">
            <label rgiRxLabel>{{ '_MAX_COLS_' | translate }}</label>
            <input rgiRxInput formControlName="maxCols">
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
    <!-- LINK REPORT -->
    <div>
      <h3 class="analytics-report-title">
        <span class="rgi-ui-icon-export"></span>
        {{'_LINK_REPORT_' | translate}}
      </h3>
      <div class="analytics-link-report">
        <div class="analytics-link-report">
          <rgi-rx-form-field *ngFor="let reportArea of reportAreas" class="analytics-settings-field">
            <input rgiRxInput formControlName="area" type="radio" (change)="onReportChange($event)" [value]="reportArea.id">
            <label rgiRxLabel>{{ reportArea.description }}</label>
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
  </div>

  <hr style="width: 100%;">

  <h3 class="analytics-report-title">
    <span class="rgi-ui-icon-edit2"></span>
    {{ '_SHOW_CHARTS_TITLE_' | translate }}
  </h3>
  <div *ngIf="areChartsVisible">
    <div class="toolbar" style="height: 50px;"></div>

    <div *ngIf="filterLoaded">
      <rgi-rx-form-field>
        <label class="rgi-ui-form-control-label">{{'_SELECT_FILTER_' | translate}}</label>
        <rgi-rx-multiselect formControlName="filters">
          <rgi-rx-option *ngFor="let option of filterSelect" [value]="option.id" [label]="option.title">
            {{ option.title }}
          </rgi-rx-option>
        </rgi-rx-multiselect>
      </rgi-rx-form-field>
    </div>

    <div class="analytics-flex" *ngIf="displayCharts">
      <div class="analytics-charts" *ngFor="let item of objects;let i=index"
           [ngClass]="{'analytics-checked-chart': item.isChecked}" id="chart{{item.name}}">
        <input [checked]="item.isChecked" #checkboxes (change)="onCheckChange($event, item)" [value]="item.name"
               class="rgi-ui-form-control" type="checkbox"> {{'_SELECT_CHART_' | translate}}
        <div class="chart{{item.name}}" style="height: 300px;" [rgiRxAnimatedLoading]="getLoading$(item.name)"
             loaderClass="analytics-loader" [delay]="2000"></div>
      </div>
    </div>
  </div>


  <rgi-rx-panel-footer *ngIf="reportDetail">
    <button rgi-rx-button color="secondary" (click)="back()" style="margin: 0.1em;">
      {{'_BACK_BTN_ '| translate}}
    </button>
    <button rgi-rx-button color="primary" (click)="updateReport()" style="margin: 0.1em;"
            [disabled]="">
      {{'_SAVE_BTN_ '| translate}}
    </button>
  </rgi-rx-panel-footer>

</form>

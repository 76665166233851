import {AnnualPremiumAPI} from './premium-api';
import {CommissionDetailAPI} from './commission-api';
import {InstalmentDetailAPI} from './installment-api';

export class QuotationAPI {
  annualPremium: AnnualPremiumAPI;
  commissions: CommissionDetailAPI[];
  installmentDetail: InstalmentDetailAPI;
  nextInstallmentDetail: InstalmentDetailAPI;
}


import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { NgxPaginationModule } from 'ngx-pagination';
import { LpcContractListTableComponent } from './lpc-contract-list-table.component';


@NgModule({
  declarations: [
    LpcContractListTableComponent
  ],
  exports: [
    LpcContractListTableComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PortalNgCoreModule,
    NgSelectModule,

    NgxPaginationModule
  ],
  providers: []
})
export class LpcContractListTableModule {
}

import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NodeContainerEventName } from '../../resources/models/event-name';
import { Subscription } from 'rxjs';
import { NodeService } from '../../services/node.services';

const CARD_NAME = 'nodeManagement';

@Component({
  selector: 'ac-node-container',
  templateUrl: './node-container.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class NodeContainerComponent implements OnInit, OnDestroy {

  @Input() placeHolder: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() showDeleteButton: boolean;
  @Input() showEditButton: boolean;
  @Input() showResetButton: boolean;
  @Input() filterRequestKey: string;
  @Input() currentNode: any = {};
  @Input() defaultNode: any;
  @Input() setArtAgency = false;
  @Input() disabled = false;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  subscription: Subscription = new Subscription();

  constructor(private nodeService: NodeService) { }

  ngOnInit() {
    if (this.findAndSetArtAgencyEnabled()) {
      this.findAndSetArtAgency();
    } else {
      this.resetItem();
    }
  }

  findAndSetArtAgency() {
    if (this.currentNode && this.currentNode.objectId) {
      this.subscription.add(
        this.nodeService.getIterativeAgencies(this.currentNode).subscribe(nodes => {
          const artAgency = this.nodeService.getArtAgency(nodes);
          if (artAgency) {
            this.nodeService.addExternalProperties(artAgency);
            this.defaultNode = artAgency;
            this.currentNode = artAgency;
            this.resetItem();
          }
        })
      );
    }
  }

  findAndSetArtAgencyEnabled(): boolean {
    return this.nodeService.getArtUser() && this.setArtAgency;
  }

  onClick() {
    this.eventPropagation.emit({
      eventName: NodeContainerEventName.select,
      card: CARD_NAME,
      filter: JSON.parse(this.filterRequestKey),
      isArtAgencyDisabled: this.findAndSetArtAgencyEnabled()
    });
  }

  deleteItem() {
    this.eventPropagation.emit({ eventName: NodeContainerEventName.delete });
  }

  resetItem() {
    this.eventPropagation.emit({ eventName: NodeContainerEventName.reset, payload: this.nodeService.completeAgency(this.defaultNode) });
  }

  editItem() {
    this.eventPropagation.emit({
        eventName: NodeContainerEventName.edit,
        card: CARD_NAME,
        filter: JSON.parse(this.filterRequestKey),
        isArtAgencyDisabled: this.findAndSetArtAgencyEnabled()
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import {PostsalesOperationsService} from '../../../services/postsales-operations.service';
import {Subscription} from 'rxjs';
import {AvailableOperation} from '../../../models/availableOperation';
import { EMPTY_STR, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { PostsalesOperationKey } from '../../../models/postsales-session';

@Component({
  selector: 'lpc-operations-list',
  templateUrl: './operations-list.component.html',
  styleUrls: ['./operations-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OperationsListComponent implements OnInit, OnDestroy {

  subscription: Subscription[] = [];
  public policyNumber: string;
  public parentSessionId: string;
  availOperationsTree: Map<string, AvailableOperation[]>;
  loader = false;
  mobileLoaderActive: boolean;

  constructor(
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    protected activeModal: NgbActiveModal,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    @Inject(PV_TOKEN.SHOW_LOADER_MOBILE) protected showMobileLoader: any,
    protected $elementRef: ElementRef) {
      this.mobileLoaderActive = !!showMobileLoader ? showMobileLoader : false;
  }

  ngOnInit() {
    this.$elementRef.nativeElement.parentElement.setAttribute('id', 'fade-item');
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

  public loadData() {
    this.loader = true;
    this.operations.clear();
    this.operations.setPolicyNumber(this.policyNumber);
    this.subscription.push(
      this.operations.getAvailableOperations().pipe().subscribe((response) => {
        this.availOperationsTree = new Map<string, AvailableOperation[]>();
        response.sort((a, b) => a.description.localeCompare(b.description));
        response.forEach(o => {
          if (!this.availOperationsTree.has(o.type)) {
            this.availOperationsTree.set(o.type, []);
          }
          this.availOperationsTree.get(o.type).push(o);
        });
        this.loader = false;
      }, error => {
        this.close();
        this.loader = false;
      })
    );
  }

  openSession(operationSlug: PostsalesOperationKey, operationDescription: string, isFinancial: boolean) {
    const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
    if (sessionService != null) {
      this.close();
      if (this.operations.hasOPCode(operationSlug)) {
        const title = this.translate.getImmediate('lpc_policy_number_dot');
        this.operations.setSession({
          policyNumber: this.policyNumber,
          operation: operationSlug,
          operationDescription,
          isFinancial,
          sessionId: sessionService.open(
            this.operations.opCodesAndSessionNames().get(operationSlug), 'home',
            operationDescription + ' - ' + title + this.policyNumber,
            true, null, null, this.parentSessionId
          ),
          productId: null,
          managementNodeCode: null
        });
      }
    }
  }

  public accordionEvent(accordion: HTMLElement) {
    const collection = accordion.getElementsByTagName('a');
    if (accordion.hidden === true) {
      accordion.hidden = false;
      [...(collection as any)].forEach(op => op.classList.add('operation-list'));
      return;
    }
    [...collection as any].forEach(op => {
      op.classList.remove('operation-list');
      op.setAttribute('closed-operation-list', EMPTY_STR);
    });
    accordion.addEventListener('animationend', () => {
      [...(collection as any)].forEach(op => op.removeAttribute('closed-operation-list'));
      accordion.hidden = !accordion.hidden;
    }, {once: true});
  }

  close() {
    this.activeModal.close();
  }
}

import {NgModule} from '@angular/core';
import {RgiCountryLayerIbanValidatorDirective} from "./rgi-country-layer-iban-validator.directive";
import {RgiCountryLayerIbanFormatPipe} from "./rgi-country-layer-iban-formatter.pipe";

@NgModule({
  declarations: [
    RgiCountryLayerIbanValidatorDirective,
    RgiCountryLayerIbanFormatPipe,
  ],
  imports: [],
  exports: [
    RgiCountryLayerIbanValidatorDirective,
    RgiCountryLayerIbanFormatPipe,
  ],
})
  export class RgiCountryLayerIbanModule {
}

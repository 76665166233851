<div class="sm-col-12">
    <div *ngIf="showHistory" (click)="onBackClick()" class="chat-header">
        <div class="col-sm-1">
            <span class="glyphicon glyphicon-chevron-left"></span>
        </div>
        <div class="chat-header-text col sm-11">
            <div class="col-sm-1 header-user-image"><span class="rgifont rgi-user"></span></div>
            <div class="col-sm-10 header-user-text">{{selectedUser.userName}}</div>
            <div class="col-sm-10 header-message-count">
                <span>{{numberOfMessages}}</span>&nbsp;
                <span *ngIf="numberOfMessages == 1">{{ '_CLAIMS_._MESSAGE' | translate }}</span>
                <span *ngIf="numberOfMessages != 1">{{ '_CLAIMS_._MESSAGES' | translate }}</span>
            </div>
        </div>
    </div>

    <div *ngIf="!showHistory">
        <div *ngIf="!showUsersList" class="chat-header"><span>{{ '_CLAIMS_._ACTIVE_CHATS' | translate }}</span><span
                class="glyphicon glyphicon-plus pull-right" (click)="onNewConversationClick()"></span>
        </div>
        <div *ngIf="showUsersList">
            <div class="chat-header"><span class="glyphicon glyphicon-chevron-left" (click)="onBackClick()"></span>
                <span class="chat-header-text">{{ '_CLAIMS_._SELECT_RECIPIENT' | translate }}</span>
            </div>
            <div class="inner-icon-input right-icon-input">
                <span class="glyphicon glyphicon-search"></span>
                <input class="form-control" type="text" placeholder="{{ '_CLAIMS_._SEARCH' | translate }}" [(ngModel)]="typedUser"
                    (keyup)="onKeyUpTypedUser()" />
            </div>
        </div>
        <div [ngClass]="{'chat-users-list': showUsersList, 'chat-summary-list': !showUsersList}"
            class="disable-scrollbars">
            <div *ngIf="showUsersList">
                <div *ngFor="let user of filteredUsers" class="chat-list-element" (click)="onSelectedUser(user)">
                    <div class="col-sm-1 chat-user-image">
                        <span class="rgifont rgi-user"></span>&nbsp;
                    </div>
                    <div class="col-sm-11 chat-user-name">
                        {{user.userName}}
                    </div>
                </div>
            </div>
            <div *ngIf="!showUsersList">
                <div *ngIf="card.isSubCard">
                    <button class="btn btn-section col-6" [ngClass]="{'active': !blogModeSelected }"
                        (click)="onClickBlogMode(false)">{{ '_CLAIMS_._CHATS_WITH_ME' | translate }}</button>
                    <button class="btn btn-section col-6" [ngClass]="{'active': blogModeSelected }"
                        (click)="onClickBlogMode(true)">{{ '_CLAIMS_._CHAT_HISTORY' | translate }}</button>
                </div>
                <div *ngIf="!blogModeSelected">
                    <claims-chat-summary *ngFor="let message of summaries" [chatSummary]="message"
                        (eventClicked)="onSelectedConversation(message)">
                    </claims-chat-summary>
                    <div class="no-chat" *ngIf="summaries && summaries.length == 0">
                        <span>{{ '_CLAIMS_._MESSAGE_._NO_CHAT' | translate }}</span>
                    </div>
                </div>
                <claims-chat-history *ngIf="blogModeSelected" [otherUser]="selectedUser" [loggedUser]="currentUser"
                    [claim]="claim" [potentialClaim]="potentialClaim" [blogMode]=true>
                </claims-chat-history>
            </div>
        </div>
    </div>
    <div *ngIf="showHistory">
        <claims-chat-history [otherUser]="selectedUser" [loggedUser]="currentUser" [claim]="claim"
            [potentialClaim]="potentialClaim" [blogMode]=false>
        </claims-chat-history>
    </div>
</div>

import {
  ComponentFactoryResolver, ComponentRef, Directive, Input, OnDestroy, ViewContainerRef
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PaymentFields } from '../../models/meansofpayment.model';
import { PaymentWrapperService } from './payment-wrapper.service';


@Directive({
  selector: '[licPaymentLoader]',
})
export class PaymentLoaderDirective implements OnDestroy {
  element = {
    label: '',
    value: ''
  };

  constructor(
    protected view: ViewContainerRef,
    protected resolver: ComponentFactoryResolver,
    protected wrapperPaymentUtils: PaymentWrapperService) { }

  private ref: ComponentRef<any>;

  @Input() group: UntypedFormGroup;
  @Input() disableField: string;
  @Input() set licPaymentLoader(config: PaymentFields) {

    this.view.clear();
    if (config) {
      this.wrapperPaymentUtils.createPaymentFields(this.resolver, this.ref, this.view, this.group, config, this.disableField);
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.destroy();
    }
  }
}

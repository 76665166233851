import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RgiRxDatatableComponent, RgiRxDataTableExpansionModel, RgiRxTableExpansionTrigger} from '@rgi/rx/ui';
import {of, Subscription} from 'rxjs';
import {DashboardRiskDetail} from '../model/dashboard-risks-detail';
import {DashboardCardState, DashboardManagerService} from '../state-manager/dashboard-manager.service';
import {DashboardDetail} from '../model/dashboard-detail';
import {DashboardUtilityService} from '../services/dashboard-utility.service';
import {formatNumber} from '@angular/common';
import {mergeMap, pluck} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {DashboardTotalRiskDetail} from '../model/dashboard-total-risk-detail';
import {DashboardOperation} from '../model/dashboard-operation';
import {DashboardOption} from '../model/dashboard-option';
import {DashboardElem} from '../model/dashboard-element';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {OperationEnum} from "../enumerations/operation.enum";

@Component({
    selector: 'dashboard-detail-table',
    templateUrl: './dashboard-detail-table.component.html',
    styleUrls: ['./dashboard-detail-table.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardDetailTableComponent implements OnInit, OnDestroy {


    constructor(public stateManager: DashboardManagerService<DashboardCardState>,
                public utility: DashboardUtilityService,
                private translateService: RgiRxTranslationService,
                public pushMessageHandler: PushMessageHandlerService) {
    }

    @Input() tableSchema: any;
    @Input() tableData: any;
    @Input() commissions: DashboardDetail[];
    @Input() overview: any;
    @Output() eventChecked: EventEmitter<any> = new EventEmitter<any>();
    sub: Subscription = new Subscription();
    commissionsForm: UntypedFormGroup;
    commissionsFK: string[];
    riskDetailForm: UntypedFormGroup;
    expandedRow: any;
    editMode: boolean;
    @Input() pageOptions: number[];
    modalConfirmText: string;
    canExpandRow = true; // can expand row in edit mode
    canContractRow: boolean; // can contract row in edit mode
    @Input() totalAllRisk: DashboardTotalRiskDetail[];
    commissionKeys: string[];
    @Input() operation: DashboardOperation;
    @Input() tableIsVisible: boolean;
    @Input() dashBoardDetailForm: UntypedFormGroup;
    operationTypes: any;
    dashBoardDetailData: DashboardOption = new DashboardOption();
    @Input() checkBoxSelected;
    notManuallyOperation: any;
    @ViewChild(RgiRxDatatableComponent) dt: RgiRxDatatableComponent;
    message: string;
    operationError: string;
    commissionStatus: string;
    paginatorDisabled = false;
    searchDisabled: boolean;


    lockTable() {
        this.paginatorDisabled = true;
        this.searchDisabled = true;
    }

    unlockTable() {
        this.paginatorDisabled = false;
        this.searchDisabled = false;
    }


    // + expansion management
    canExpand = (): RgiRxTableExpansionTrigger => (row: any) => {
        const state = this.stateManager.getCurrentState();
        // If edit mode can't contract row
        this.canContractRow = !this.editMode;
        const checkSelected = this.selectedCheckbox();
        const expandable = !this.editMode || (this.editMode && this.canExpandRow);
        if (expandable && !checkSelected) {
            // Lock Table
            this.lockTable();
            this.operation.isMulti = Boolean(OperationEnum.SINGLE);
            this.pushMessageHandler.clearTag(this.operationError);
            // Save expanded row
            this.expandedRow = row;
            const selectedRow = state.commissionsData.find(comm => comm.idDashBoard === row.idDashBoard);
            if (!selectedRow.risksDetail) {
                this.stateManager.getRiskSummaryDetails(row.idDashBoard, this.overview.type, state).subscribe(state1 => {
                    this.stateManager.updateState$(of(state1));
                    this.createRiskCommissionForm(row.idDashBoard);
                });
            } else {
                this.createRiskCommissionForm(row.idDashBoard);
            }
            this.disableCommFormControls();

            return this.canExpandRow;
        } else {
            this.infoOperation(this.message);
        }
        return false;
    }

    // - reduce management
    canContract = (): RgiRxTableExpansionTrigger => (row: any) => {
        if (!this.editMode || (this.editMode && this.canContractRow)) {
            this.pushMessageHandler.clearTag(this.operationError);
            this.enableCommFormControls();
            this.unlockTable();
        } else {
            this.infoOperation(this.message);
        }
        return this.canContractRow;
    }

    // expansion row +/-
    customExpansionModel: RgiRxDataTableExpansionModel<any> = new RgiRxDataTableExpansionModel<any>([], {
        multiple: false,
        canExpand: this.canExpand(),
        canContract: this.canContract()
    });

    ngOnInit() {
        this.setTranslations();
        this.notManuallyOperation = this.findNotManuallyOperation(this.stateManager.getCurrentState().operationList);
        this.commissionKeys = Object.keys(this.totalAllRisk[0]);
        // Create form & subscribe by resp
        this.createCommissionsForm(this.commissions.map(comm => comm.idDashBoard + '_' + comm.status.id));
        // Subscribe form changes
        this.commissionsFormChanges();
        // MODE => EDIT/CONSULTATION
        this.editMode = this.overview.functionality.id === '3';
        // Edit mode => cannot close until select APPLY or CANCEL
        this.canContractRow = !this.editMode;
        const commissions = this.stateManager.getState$().pipe(
            pluck('commissionsData'));
        commissions.subscribe(comm => {
            const formCreated = this.commissionsFK && this.commissionsForm;
            const lastId = comm.length - 1;
            const foundKey = this.commissionsFK.find(k => k === comm[lastId].idDashBoard + '_' + comm[lastId].status.id);
            if (!formCreated || !foundKey) {
                // Create form & subscribe by resp
                this.createCommissionsForm(comm.map(c => c.idDashBoard + '_' + c.status.id));
                // Subscribe form changes
                this.commissionsFormChanges();
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

// check for selected checkbox
    selectedCheckbox() {
        return this.commissionsFK.find(commCode => this.commissionsForm.controls[commCode].value === true);
    }

// disable all form controls
    disableCommFormControls() {
        this.commissionsFK.forEach((code) => {
            this.commissionsForm.controls[code].disable({onlySelf: true, emitEvent: false});
        });
    }

// enable all form controls
    enableCommFormControls() {
        this.commissionsFK.forEach((code) => {
            this.commissionsForm.controls[code].enable({onlySelf: true, emitEvent: false});
        });
    }

// disable/enable form controls (logic)
    commControlsManagement(value: boolean, currentStatus: string) {
        const formControl = '_' + currentStatus;
        const opt = {onlySelf: true, emitEvent: false};
        if (value) {
            const toDisable = this.commissionsFK.filter(code => !code.includes(formControl) && !code.endsWith(formControl));
            toDisable.forEach(code => this.commissionsForm.controls[code].disable(opt));
        } else {
            const toEnable = this.commissionsFK.filter(code => !code.includes(formControl) && !code.endsWith(formControl));
            toEnable.forEach(code => this.commissionsForm.controls[code].enable(opt));
        }
    }

// create risk commission form
    createRiskCommissionForm(idDashBoard: string) {
        const state = this.stateManager.getCurrentState();
        const commission = state.commissionsData.find(comm => comm.idDashBoard === idDashBoard);
        const risks = commission.risksDetail;
        const riskDetailValues = this.getRisksValues(risks);
        const riskDetailCodes = this.getRisksCodes(risks);
        this.riskDetailForm = this.utility.toFormGroup(riskDetailCodes, riskDetailValues, true);
    }

// create commissions form
    createCommissionsForm(codes: string  []) {
        this.commissionsFK = codes;
        this.commissionsForm = this.utility.toFormGroup(codes, null, null);
        if (this.checkBoxSelected) {
            this.checkBoxSelected.forEach(selected => {
                const key = this.commissionsFK.find(k => k.startsWith(selected));
                if (key) {
                    // TODO : MOVE SUBSCRIBE
                    this.commissionsForm.controls[key].setValue(true);
                    const formKeys = key.split('_');
                    const status = formKeys[1];
                    this.commControlsManagement(true, status);
                } else {
                    this.commControlsManagement(true, this.commissionStatus);
                }
            });
        }
    }

// Subscribe commissions formControls (checkbox of table #1)
    commissionsFormChanges() {
        const state = this.stateManager.getCurrentState();
        this.commissionsFK.forEach(code =>
            this.commissionsForm.controls[code].valueChanges.subscribe(value => {
                this.operation.isMulti = Boolean(OperationEnum.MULTI);
                const info = code.split('_');
                const id = info[0];
                const status = info[1];
                this.commissionStatus = status;
                this.commControlsManagement(value, status);
                const selectedRow = state.commissionsData.find(comm => comm.idDashBoard === id);
                if (!selectedRow.risksDetail) {
                    this.stateManager.getRiskSummaryDetails(id, this.overview.type, state).subscribe(state1 => {
                        this.stateManager.updateState$(of(state1));
                        this.totalAllRisk = this.utility.getRiskTotals(id, this.commissionKeys, this.totalAllRisk, state1, value);
                    });
                } else {
                    this.totalAllRisk = this.utility.getRiskTotals(id, this.commissionKeys, this.totalAllRisk, state, value);
                }
                if (selectedRow) {
                    selectedRow.selected = value;
                }
                const emitted = {selected: value, idDashBoard: id, status, operationError: this.operationError};
                this.eventChecked.emit(emitted);
            }));
    }

    getRisksCodes(riskDetail: DashboardRiskDetail[]): string [] {
        const purchCodes = riskDetail.map(purch => purch.idDashBoard + '_' + purch.idDashRisk + '_commPurchAmt');
        const collCodes = riskDetail.map(purch => purch.idDashBoard + '_' + purch.idDashRisk + '_commCollAmt');
        return [...purchCodes, ...collCodes];
    }

    getRisksValues(riskDetail: DashboardRiskDetail[]): string [] {
        const purchValues = riskDetail.map(purch => formatNumber(Number(purch.commPurchAmt), 'it', '1.2-2'));
        const collValues = riskDetail.map(coll => formatNumber(Number(coll.commCollAmt), 'it', '1.2-2'));
        return [...purchValues, ...collValues];
    }

    resetTableRiskValue() {
        this.canContractRow = true;
        this.canExpandRow = true;
        this.customExpansionModel.contract(this.expandedRow);
        this.expandedRow = undefined;
    }

    resetTableAllRiskValue() {
        this.commissionsFK.forEach(code => {
            if (this.commissionsForm.controls[code].value) {
                this.commissionsForm.controls[code].setValue(false);
            }
        });
    }

    setOperation(selectedOperationId: any, idDashBoard: string) {
        this.dashBoardDetailData.subStatus = selectedOperationId;
        const state = this.stateManager.getCurrentState();
        const commission = state.commissionsData.find(comm => comm.idDashBoard === idDashBoard);
        const risks = commission.risksDetail;
        const riskDetailCodes = this.getRisksCodes(risks);
        // SET FORM CONTROLS STATEMENT
        riskDetailCodes.forEach(code => {
                this.riskDetailForm.controls[code].setValue('0,00');
                if (selectedOperationId.toString() === '6' && commission.status.id === '2') {
                    this.riskDetailForm.controls[code].enable();
                } else {
                    this.riskDetailForm.controls[code].disable();
                }
            }
        );
    }

    applyRisks(operation: any, idDashBoard: any) {
        const state = this.stateManager.getCurrentState();
        const bodyRequest = [];
        if (!this.operation.isMulti) {
            this.applyRiskOperationSingle(idDashBoard, state, bodyRequest);
            this.updateCommissions(state, bodyRequest);
        } else {
            this.applyRiskOperationMulti(idDashBoard, state, bodyRequest);
            this.updateCommissions(state, bodyRequest);
        }
    }

    applyRiskOperationSingle(idDashBoard: any, state: DashboardCardState, bodyRequest: any[]) {
        let riskEmitter = {};
        const emitter = {
            idDashBoard,
            subStatus: this.dashBoardDetailData.subStatus,
            action: this.dashBoardDetailData.action,
            note: this.dashBoardDetailData.note,
            riskCommissions: []
        };
        const selectedRow = state.commissionsData.find(comm => comm.idDashBoard === idDashBoard);
        selectedRow.risksDetail.forEach(elem => {
            riskEmitter = {
                idDashRisk: elem.idDashRisk,
                idDashBoard: elem.idDashBoard,
                commCollAmt: this.riskDetailForm.controls[elem.idDashBoard
                + '_' + elem.idDashRisk + '_commCollAmt'].value.replace('.', '').replace(',', '.'),
                commPurchAmt: this.riskDetailForm.controls[elem.idDashBoard
                + '_' + elem.idDashRisk + '_commPurchAmt'].value.replace('.', '').replace(',', '.')
            };
            emitter.riskCommissions.push(riskEmitter);
        });
        bodyRequest.push(emitter);
    }

    applyRiskOperationMulti(idDashBoard: any, state: DashboardCardState, bodyRequest: any[]) {
        this.totalAllRisk[0].idDashBoard.forEach(elem => {
            const emitter = {
                idDashBoard: elem,
                subStatus: this.dashBoardDetailForm.controls.operationType.value,
                action: this.dashBoardDetailData.action,
                note: this.dashBoardDetailData.note,
                riskCommissions: []
            };
            bodyRequest.push(emitter);
        });
    }

    private updateCommissions(state: DashboardCardState, bodyRequest: any[]) {
        this.stateManager.putCommissions(state, bodyRequest).subscribe(state1 => {
            if (state1) {
                this.stateManager.getCommissions(state1, state1.page, state1.limit).subscribe(state2 => {
                    this.stateManager.updateState$(of(state2));
                    // update table
                    this.tableData = this.utility.createData(this.tableData, state2);
                    this.dt.dataSource.update(this.tableData);
                    // reset checkBoxselected
                    const emitted = {reset: true};
                    this.eventChecked.emit(emitted);
                    this.totalAllRisk = [new DashboardTotalRiskDetail()];
                    this.resetTableRiskValue();
                    this.createCommissionsForm(state2.commissionsData.map(comm => comm.idDashBoard + '_' + comm.status.id));
                    // Subscribe form changes
                    this.commissionsFormChanges();
                });
            }
        });
    }

    updateAction(action: any) {
        this.dashBoardDetailData.action = action;
    }

    updateNotes(note: any) {
        this.dashBoardDetailData.note = note;
    }

    blockExpand() {
        if (this.editMode) {
            this.canExpandRow = false;
        }
    }

    setTranslations() {
        const keys$ = of([
            'ICD.MODAL_CONFIRM_TEXT_SINGLE',
            'ICD.ERROR_MESSAGE_UPDATE'
        ]);
        keys$.pipe(
            mergeMap(r => this.translateService.translateAll(...r))).subscribe(next => {
            this.modalConfirmText = next[0];
            this.message = next[1];
        });
    }

    findNotManuallyOperation(operationList: Array<DashboardElem>) {
        if (operationList && operationList.length > 0) {
            return operationList.filter(elem => elem.id !== '6');
        }
    }

    infoOperation(message: string) {
        this.operationError = 'operationError';
        const tag = this.operationError;
        this.pushMessageHandler.clearTag(tag);
        if (message) {
            const msg: RgiRxPushMessage = new RgiRxPushMessage();
            const opts = {icon: 'rgi-ui-icon-alert'};
            msg.tag = tag;
            msg.status = 'danger';
            msg.content = message;
            msg.dismissible = false;
            msg.options = opts;
            this.pushMessageHandler.notify(msg);
        }
    }
}

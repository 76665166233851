import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { EvaluatorScoreObj } from '../../../../evaluation/models/evaluator-score-obj';


@Component({
  selector: '[survey-eval-score]',
  templateUrl: './survey-eval-score.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SurveyEvalScoreComponent {

  @Input() scoreObj:EvaluatorScoreObj;
  @Input() scoreVisible:boolean;
  @Input() scoreStyleClass: string;

  constructor() { 

  }



}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ac-auth-history-detail',
  templateUrl: './auth-history-detail.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class AuthHistoryDetailComponent implements OnInit {
  @Input() elementCurrentDetail: any;

  constructor() { }

  ngOnInit() {
  }
}

<div class="activities-container"> <!-- TODO : REFACTOR WITH RGI UI -->
  <re-issue-asset-activity *ngIf="activityPacket != null"
                           [activityPacket]="activityPacket"
                           [isSecondary]="false"
                           [assetKey]="assetKey"
                           [isRequired]="isRequired"
                           [isBusy]="secondaryActivityBusy"
                           (formStateEmitter)="checkActivitiesForm($event)"
                           (formValidEmitter)="mainActivitiesFormValid($event)"
                           data-qa="activity_1">

  </re-issue-asset-activity>

  <re-issue-asset-activity *ngIf="secondaryActivityPacket != null && activitiesMax>1"
                           [activityPacket]="secondaryActivityPacket"
                           [assetKey]="assetKey"
                           [isSecondary]="true"
                           [isDisabled]="!mainActivityFilled"
                           (formStateEmitter)="checkSecondaryActivitiesForm($event)"
                           (formValidEmitter)="secondaryActivitiesFormValid($event)"
                           data-qa="activity_2">

  </re-issue-asset-activity>
</div>

import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ComponentWithAnagModal } from '../../interfaces/component-with-anag-modal';
import { FormFieldType } from '../../models/enums/vita.enum';
import { FormFieldsDefinition } from '../../models/postsales-operations-response.model';
import { AnagSubject } from '../../models/subject.model';
import { AnagService } from '../../services/anag.service';
import { PersonType, RoleType } from '../../models/enum/lpc-subjects.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lpc-form-field-handler',
  templateUrl: './lpc-form-field-handler.component.html',
  styleUrls: ['./lpc-form-field-handler.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcFormFieldHandlerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcFormFieldHandlerComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LpcFormFieldHandlerComponent implements OnInit, ControlValueAccessor, ComponentWithAnagModal, Validator, OnDestroy {

  @Input() definitions: FormFieldsDefinition[];
  @Input() isOpen: boolean;
  @Input() isActive: boolean;

  controlPartyRequested: { name: string; index: string };
  roleTypeToAdd: PersonType;
  roleCodeToAdd: RoleType;

  subscriptions: Subscription[] = [];

  formFieldData: UntypedFormGroup = new UntypedFormGroup({
    definitionsFields: new UntypedFormArray([]),
  });

  get definitionsFields() {
    return this.formFieldData.controls.definitionsFields as UntypedFormArray;
  }

  constructor(protected anagService: AnagService) {}

  ngOnInit() {
    this.definitions.forEach((x) => {
      if (
        x.type === FormFieldType.STRING ||
        x.type === FormFieldType.ENUM ||
        x.type === FormFieldType.DOUBLE ||
        x.type === FormFieldType.DATE ||
        x.type === FormFieldType.INT
      ) {
        this.definitionsFields.push(
          new UntypedFormGroup({
            [x.code]: new UntypedFormControl({
              value: x.type === FormFieldType.DATE && !!x.value ? new Date(x.value) : x.value,
              disabled: x.readOnly},
              x.mandatory && Validators.required,
            ),
          })
        );
      } else if (x.type === FormFieldType.PARTY) {
        this.definitionsFields.push(
          new UntypedFormGroup({
            [x.code]: new UntypedFormControl(this.defaultPartyValue(x)),
          })
        );
      }
    });
    this.subscriptions.push(
      this.formFieldData.valueChanges.subscribe((value: {definitionsFields: {[key: string]: any}[]}) => {
        this.onValidationChange(value);
        this.onChange(value);

      })
    );
  }

 defaultPartyValue(x: FormFieldsDefinition) {
    if (x.values && x.values[0]) {
      return {
        id: x.values[0].id,
        name: x.values[0].description
       };
     }
    return;
  }

  allowOnlyNumbers(event: KeyboardEvent): void {
  if (event.key === '.' || event.key === ',') {
    event.preventDefault();
    }
  }

  validate(): ValidationErrors {
    const error: ValidationErrors = {};
    this.definitions.forEach((val, i) => {
      const formControl = this.definitionsFields.get(i.toString()).get(val.code);
      if (val.mandatory && !formControl.value) {
        Object.assign(error, {required: true});
      }}
     );
    return error;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  openAnagSubjectModal(controlName: any): void {
    this.controlPartyRequested = controlName;
    this.roleCodeToAdd = controlName.name;
    this.valorizeRoleTypeByControlName(controlName.name);
    this.anagService.openSubjectModal(this);
  }

  // TODO: if cases for the legal type will increase please handle the logic by using an array
  private valorizeRoleTypeByControlName(controlName: any) {
    switch (controlName) {
      case 'TRAZACompanyNameOfPreviousPensionFund' || 'TRRISCompanyNameOfPreviousPensionFund':
        this.roleTypeToAdd = PersonType.LEGAL;
        break;
      default:
        break;
    }
  }

  deleteSbj(controlName: any) {
    this.definitionsFields
      .get(controlName.index)
      .get(controlName.name)
      .setValue(null);
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    this.definitionsFields
      .get(this.controlPartyRequested.index)
      .get(this.controlPartyRequested.name)
      .setValue({ id: subject.objectId, name: subject.nominative });
  }

  onChange(value: any) {}

  onValidationChange(value: any) {}

  onFormChanges() {}

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  intPasteController(event: ClipboardEvent): boolean {
    const pastedText = event.clipboardData.getData('text/plain');
    return !(pastedText.includes(',') || pastedText.includes('.'));
  }
}

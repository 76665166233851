import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {CoInsuranceDTO, Company} from '../../../models/domain-models/co-insurance';
import {PolicyGenericDto} from '../../../models/pc-portfolio-models/policy-generic-dto';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioCoInsuranceResourceService {

  private CONTRACT_PATH = 'contract/';
  private PROPOSALS_PATH = 'proposals/';
  private INDIRECT_COINSURANCE_PATH = 'indirect-coinsurance';
  private apiPcPortfolioConf: any;

  constructor(private http: HttpClient,
              @Inject('environment') private apiConf: any) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  getCompanies(proposalId: string): Observable<Company[]> {
    return this.http.get<Company[]>(
      this.getCompaniesUri(proposalId)
    ).pipe(
      share()
    );
  }

  getIntermediaries(proposalId: string): Observable<Company[]> {
    return this.http.get<Company[]>(
      this.getIntermediariesUri(proposalId)
    ).pipe(
      share()
    );
  }

  putCoInsuranceQuotas(proposalId: string, coInsuranceQuota: CoInsuranceDTO) {

    return this.http.put<CoInsuranceDTO>(
      this.getCoInsuranceUri(proposalId),
      coInsuranceQuota
    ).pipe(
      share()
    );
  }

  putIndirectCoInsurance(proposalId: string, indirectCoInsurance: PolicyGenericDto) {
    return this.http.put<PolicyGenericDto>(
      this.getProposalsBaseUrl(proposalId) +`/${this.INDIRECT_COINSURANCE_PATH}`,
      indirectCoInsurance
      ).pipe(
        share()
      );
  }

  searchCommonRiskPolicy(proposalId: string, policyDto: PolicyGenericDto) {
    return this.http.put<any>(
      this.getCommonRiskPolicyUri(proposalId),
      policyDto
    ).pipe(
      share()
    );
  }

  deleteIndirectCoInsurance(proposalId: string, objectId:number){
    const url= this.getProposalsBaseUrl(proposalId) +`/${this.INDIRECT_COINSURANCE_PATH}/${objectId}`;
    return this.http.delete(url).pipe(
      share()
    );
  }

  deleteAllIndirectCoInsurancePolicies(proposalId: string){
    const url= this.getProposalsBaseUrl(proposalId) +`/indirect-coinsurance-all`;
    return this.http.delete(url).pipe(
      share()
    );
  }

  private getCompaniesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/companies';
  }

  private getIntermediariesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/intermediaries';
  }

  private getCoInsuranceUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/coinsurance';
  }

  private getProposalsBaseUrl(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId;
  }

  private getCommonRiskPolicyUri(proposalId: string): string {
    // REVIEW THE URL
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      proposalId +
      '/policies-risk';
  }
}

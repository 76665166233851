import { Component, OnInit, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { ChatService } from './chat.service';
import { ChatMessage } from './dto/chat-message';
import { ChatUser } from './dto/chat-user';
import { CardComponent } from '@rgi/digital-claims-common-angular';

@Component({
  selector: 'claims-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent extends CardComponent implements OnInit {

  chatMessages: any;
  summaries: any;
  numberOfMessages: number;
  showHistory: boolean;
  selectedUser: ChatUser;
  currentUser: string;
  showUsersList: boolean;
  users: ChatUser[] = [];
  filteredUsers: ChatUser[] = [];
  typedUser = '';
  claim: { claimNumber: string };
  potentialClaim: { reportNumber: string };
  blogModeSelected = false;

  constructor(
    @Inject('authService') auth: any,
    @Inject('containerCacheService') private cache: any,
    private chatService: ChatService
  ) {
    super();
    this.showHistory = false;
    this.currentUser = auth.getUsername();
  }

  ngOnInit() {
    if (this.card.idSession) {
      this.claim = this.cache.getCache().get(this.card.idSession).type === 'claim' ?
        this.cache.getCache().get(this.card.idSession).data : null;
      this.potentialClaim = this.cache.getCache().get(this.card.idSession).type === 'pes' ?
        this.cache.getCache().get(this.card.idSession).data : null;
    }
    this.updateChat();
  }

  private groupMessagesByUser(messages: any[]) {
    return messages.reduce(
      (groupedMessages, message: ChatMessage) => {
        const user: string = message.otherUser.userName;
        groupedMessages[user] = groupedMessages[user] || [];
        groupedMessages[user].push(message);
        return groupedMessages;
      }, {}
    );
  }

  private getSummaries(messages: any) {
    const messageArray = this.objectToArray(messages);
    const summaries = [];
    messageArray.forEach(conversation => {
      let numberOfUnreadMessages = 0;
      conversation.forEach((message: { read: boolean; sender: ChatUser; }) => {
        if (!message.read && message.sender.userName !== this.currentUser) {
          numberOfUnreadMessages++;
        }
      });
      conversation[0].numberOfUnreadMessages = numberOfUnreadMessages;
      summaries.push(conversation[0]);
      numberOfUnreadMessages = 0;
    });
    return summaries;
  }

  private objectToArray(messages: Response) {
    const array = [];
    for (const prop of Object.keys(messages)) {
      array.push(messages[prop]);
    }
    return array;
  }


  public onSelectedConversation(message: ChatMessage) {
    this.showHistory = true;
    this.selectedUser = message.otherUser;
    this.numberOfMessages = this.groupMessagesByUser(this.chatMessages)[this.selectedUser.userName].length;
  }

  public onSelectedUser(user: ChatUser) {
    this.showHistory = true;
    this.selectedUser = user;
    this.showUsersList = false;
    this.numberOfMessages = this.groupMessagesByUser(this.chatMessages)[this.selectedUser.userName] ?
      this.groupMessagesByUser(this.chatMessages)[this.selectedUser.userName].length : 0;
  }

  public onBackClick() {
    this.selectedUser = null;
    this.updateChat();
    this.showHistory = false;
    this.showUsersList = false;
  }

  public onNewConversationClick() {
    this.selectedUser = null;
    this.showHistory = false;
    this.showUsersList = true;
    this.chatService.getUsers(this.currentUser)
      .subscribe(
        (response: Response) => {
          this.users = this.objectToArray(response);
          this.onKeyUpTypedUser();
        }
      );
  }

  public onKeyUpTypedUser() {
    this.filteredUsers = this.users.filter(user => user.userName.toUpperCase().includes(this.typedUser.toUpperCase()));
  }

  private updateChat() {
    this.chatService.getChats(this.currentUser, this.selectedUser, this.claim, this.potentialClaim, false)
      .subscribe(
        (response: Response) => {
          this.chatMessages = response;
          const groupedMessages = this.groupMessagesByUser(this.chatMessages);
          this.summaries = this.getSummaries(groupedMessages);
        },
        (error: Error) => console.log(error)
      );
  }

  onClickBlogMode(blog: boolean) {
    this.blogModeSelected = blog;
  }

}

import { PolicyService } from './../../../services/policy-service';
import {Component, Input, OnInit} from '@angular/core';
import {KarmaProfileDefinition} from '../../model/karma-profile-definition';


@Component({
  selector: 'lic-karma-fund-table',
  templateUrl: './lic-karma-fund-table.component.html',
  styleUrls: ['./lic-karma-fund-table.component.scss']
})
export class LicKarmaFundTableComponent implements OnInit {
  public locale = 'it-IT';
  public optionPercents: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };
  public optionCurrency: Intl.NumberFormatOptions = { style: 'currency' };

  @Input() profiles: KarmaProfileDefinition[];
  @Input() hideValueColumn = false;
  @Input() decimalPercentage = false;
  @Input() openListDefault = false; // true => apre le liste in automatico

  public profileToggle = {};

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
    this.optionCurrency.currency = policyService.currencyCode;
  }

  ngOnInit() {
    this.profileToggle = {};
    this.profiles.map(profile => this.profileToggle[profile.id] = this.openListDefault);
    if (this.decimalPercentage) {
      this.formatPercentageValue();
    }
  }

  public toggleProfile(profileId: string) {
    this.profileToggle[profileId] = !this.profileToggle[profileId];
  }

  // la tabella utilizza la pipe -> 1 => 100% | 0.5 => 50% | 0.25 => 25%
  // quindi gli passo le percentuali divise per 100
  formatPercentageValue() {
    this.profiles.forEach(p => {
      p.funds.forEach(f => {
        f.percent = (+f.percent) ? +f.percent / 100 : 0;
      });
      p.percent = (+p.percent) ? +p.percent / 100 : 0;
    });
  }

}

<ng-container *ngIf="clauses && clauses.length > 0">
  <div class="rgi-gp-section-title">
    <span class="rgi-gp-icon rgi-ui-icon-cube"></span>
    <b translate>_GP_._TITLE_._CLAUSES_</b>
  </div>
  <div class="rgi-gp-flex-row" [formGroup]="clausesForm">
    <div class="rgi-gp-field-container" *ngFor="let clause of clauses; index as i">
      <rgi-rx-form-field class="rgi-gp-border-bottom rgi-gp-mb-1" [attr.data-qa]="'rgi-gp-clause_' + clause.code">
        <input type="checkbox" rgiRxInput class="rgi-gp-fit-width" (click)="onClauseSelection(clause)" [formControlName]="clause.code + '_' + i">
        <div class="rgi-ui-text-5" [ngClass]="{'rgi-gp-mr-m': showText(clause)}">
          {{clause.extendedDescription}}
        </div>
        <button class="rgi-ui-btn" (click)="openModalText(clause)" data-qa="rgi-gp-show-or-edit-clause"
                [ngClass]="{'rgi-ui-btn-outline rgi-gp-flex': showText(clause)}"
                [disabled]="!clause.selected && !showText(clause)">
        <span *ngIf="showPreviewText(clause)" class="rgi-gp-prev-clause-text rgi-ui-text-5" data-qa="rgi-gp-clause-text">
          {{clause.text}}
        </span>
          <span
            [ngClass]="{'rgi-ui-icon-edit2': clause.editableText,'rgi-ui-icon-resources': !clause.editableText}"></span>
        </button>
        <span *ngIf="clause.mandatoryText" class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*</span>
      </rgi-rx-form-field>
    </div>
  </div>
</ng-container>

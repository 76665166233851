export class ExternalPolicySubject {
 public codRole: string;
 public lastBusinessName: string = '';
 public firstName: string = '';
 public fiscalCode: string = ''; // codice fiscale
 public vat: string = ''; // piva
 public address: string = ''; // codice fiscale
 public municipality: string = '';
 public postalCode: string = '';
 public stateCode: string = '';
 public birthDate: Date;
 public subjectCode: string;
 public gender: string;
 public country: string;

 constructor() { }

 fiscalCodeVat(): string {
     return (this.fiscalCode + ' ' + this.vat).trim();
 }

//  public denomination: string;
//  public firstName: string;
//  public subjectCode: string;
//  public birthDate: Date;
//  public fiscalCodeVat: string;
//  public gender: string;
//  public address: string;
//  public postalCode: string;
//  public stateCode: string;
//  public municipality: string;
}

import { Pipe, PipeTransform } from '@angular/core';
import { SelectableCoverture } from '@rgi/digital-claims-common-angular';
@Pipe({
    name: 'coverturesByBranch'
})
export class CovertureByBranchPipe implements PipeTransform {


    transform(covertures: any[], branchFilter: string, selectableCovertures: SelectableCoverture[]): SelectableCoverture[] {
        if (!branchFilter) {
            return covertures;
        }
        const filteredCovertures = selectableCovertures
            .filter(item => item.branchclaimcode === branchFilter);

        const covArray = [];
        filteredCovertures.forEach(coverture => {
            covArray.push(coverture.coverturetypecode);
        });
        return covertures.filter(
            cov => covArray.indexOf(cov.code) !== -1);
    }

}

<form [formGroup]="anagPaymentForm" *ngIf="stateMgr.getState$() | async as state">
  <div class="rgi-ui-panel-container">
    <div class="rgi-ui-panel-header rgi-anag-p-1">
      <span class="anag-icon-m rgi-ui-icon-ellipsis-menu"></span>
      <span class="rgi-ui-panel-title rgi-ui-text-1">{{'_ANAG_._LABEL_._PAYMENT_' | translate}}</span>
      <span class="rgi-ui-btn-close" (click)="actionClose()" data-qa="close-modal"></span>
    </div>
    <div class="rgi-ui-panel-content">
      <div rgi-rx-accordion style="margin: 10px;">
        <rgi-rx-expansion-panel *ngFor="let paymentMethod of anagPaymentSectionComponent.partyPayments; index as i"
          [attr.data-qa]="i">
          <rgi-rx-expansion-panel-header rgi-rx-expansion-panel-header class="rgi-anag-flex" (click)="resetErrorMsg()">
            <span class="rgi-anag-flex-gr-1" *ngIf="paymentMethod.paymentTypeDescription">
              {{paymentMethod.paymentTypeDescription}} - {{anagPaymentSectionComponent.PAYMENT_TYPES.get(paymentMethod.paymentType) | translate}}
            </span>
            <div
              *ngIf="anagPaymentForm.get(paymentMethod.paymentType+'_' + paymentMethod.paymentTypeID + '_' + i)?.invalid"
              class="rgi-ui-icon-snackbar rgi-ui-icon-alert rgi-anag-alert-pay">
            </div>
            <button class="rgi-ui-btn rgi-ui-icon-delete2 rgi-anag-payment-trash" type="button"
              (click)="deletePaymentMethod(i, paymentMethod.paymentType+'_' + paymentMethod.paymentTypeID + '_' + i)"></button>
          </rgi-rx-expansion-panel-header>
          <div rgiRxExpansionPanelContent>
            <div *ngIf="!paymentMethod.paymentTypeID; else valuedPaymentType">
              <rgi-rx-tab-group>
                <rgi-rx-tab *ngIf="anagPaymentSectionComponent.filteredSelect(state.meansOfPayment.meansOfPayment.debMeansOfPayment)"
                  label="{{'_ANAG_._LABEL_._DEBIT_' | translate}}">
                  <ng-template rgiRxTabContent>
                    <div data-qa="rgi-anag-payment-deb">
                      <rgi-rx-form-field>
                        <label rgiRxLabel translate>_ANAG_._LABEL_._DEBIT_</label>
                        <select rgiRxNativeSelect (change)="onPaymentTypeSelect($event, i, 'debit')">
                          <option selected></option>
                          <option
                            *ngFor="let paymentType of anagPaymentSectionComponent.filteredSelect(state.meansOfPayment.meansOfPayment.debMeansOfPayment)"
                            [value]="paymentType.meanOfPayment.identification">
                            {{paymentType.meanOfPayment.description}}
                          </option>
                        </select>
                      </rgi-rx-form-field>
                    </div>
                  </ng-template>
                </rgi-rx-tab>
                <rgi-rx-tab *ngIf="anagPaymentSectionComponent.filteredSelect(state.meansOfPayment.meansOfPayment.credMeansOfPayment)"
                  label="{{'_ANAG_._LABEL_._CREDIT_' | translate}}">
                  <ng-template rgiRxTabContent>
                    <rgi-rx-form-field>
                      <label rgiRxLabel translate>_ANAG_._LABEL_._CREDIT_</label>
                      <select rgiRxNativeSelect (change)="onPaymentTypeSelect($event, i, 'credit')">
                        <option selected></option>
                        <option
                          *ngFor="let paymentType of anagPaymentSectionComponent.filteredSelect(state.meansOfPayment.meansOfPayment.credMeansOfPayment)"
                          [value]="paymentType.meanOfPayment.identification">
                          {{paymentType.meanOfPayment.description}}
                        </option>
                      </select>
                    </rgi-rx-form-field>
                  </ng-template>
                </rgi-rx-tab>
              </rgi-rx-tab-group>
            </div>
            <ng-template #valuedPaymentType>
              <ng-container *ngIf="anagPaymentSectionComponent.getPayments(paymentMethod) as payment">
                <ng-container *ngFor="let field of payment.paymentFields"
                  [formGroupName]="paymentMethod.paymentType+'_' + paymentMethod.paymentTypeID + '_' + i">
                  <div *ngIf="field.type !== 'D'"
                    [ngClass]="{'rgi-ui-error':
                    isFieldInvalid((paymentMethod.paymentType + '_' + paymentMethod.paymentTypeID + '_' + i), field.name)}">
                    <rgi-rx-form-field *ngIf="field.type === 'N' && !payment?.creditCards.length">
                      <label rgiRxLabel>{{field.label}}</label>
                      <input rgiRxInput [attr.data-qa]="'anag-payment-' + field.name" type="number" formControlName="{{field.name}}">
                    </rgi-rx-form-field>
                    <rgi-rx-form-field *ngIf="field.type === 'F'" type="text">
                      <label rgiRxLabel>{{field.label}}</label>
                      <input rgiRxInput [attr.data-qa]="'anag-payment-' + field.name" formControlName="{{field.name}}">
                      <span *ngIf="field.name === 'ciban' && !isFieldEnable((paymentMethod.paymentType + '_' + paymentMethod.paymentTypeID + '_' + i), field.name)" (click)="enableIban((paymentMethod.paymentType + '_' + paymentMethod.paymentTypeID + '_' + i), field.name)" class="rgi-ui-icon-edit2 anag-edit-icon edit-iban"></span>
                      <span *ngIf="field.name === 'ciban' && canRevert((paymentMethod.paymentType + '_' + paymentMethod.paymentTypeID + '_' + i), field.name)" (click)="revertIban((paymentMethod.paymentType + '_' + paymentMethod.paymentTypeID + '_' + i), field.name)" class="rgi-ui-icon-change anag-edit-icon edit-iban"></span>
                    </rgi-rx-form-field>
                    <rgi-rx-form-field *ngIf="field.type === 'N' && payment?.creditCards.length">
                      <label rgiRxLabel>{{field.label}}</label>
                      <select rgiRxNativeSelect [attr.data-qa]="'anag-payment-' + field.name" formControlName="{{field.name}}">
                        <option selected></option>
                        <option *ngFor="let el of payment.creditCards" [value]="el.identification">
                          {{el.description}}
                        </option>
                      </select>
                    </rgi-rx-form-field>


                    <div class="rgi-ui-error-message"
                      *ngFor="let formError of checkErrors(anagPaymentForm.get(paymentMethod.paymentType+'_' + paymentMethod.paymentTypeID + '_' + i).get(field.name).errors)">
                      <span class="rgi-ui-message" translate>
                        {{formError}}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="field.type === 'D' && payment?.creditCards.length" [ngClass]="{'rgi-ui-error': isFieldInvalid((paymentMethod.paymentType + '_' +
                       paymentMethod.paymentTypeID + '_' + i), field.name)}">
                    <rgi-rx-form-field>
                      <label rgiRxLabel>{{field.label}}</label>
                      <input rgiRxInput [attr.data-qa]="field.name" formControlName="{{field.name}}"
                      [attr.data-qa]="'anag-payment-' + field.name" pattern="(0[1-9]|1[0-2])\/\d{4}">
                    </rgi-rx-form-field>
                  </div>
                  <div *ngIf="field.type === 'D' && !payment?.creditCards.length">
                  <label rgiRxLabel><span>{{field.label}}</span></label>
                  <rgi-rx-form-field>
                    <input [rgiRxDateTimeInput]="paymPicker"
                           style="flex: 1"
                           [attr.data-qa]="'anag-payment-' + field.name"
                           formControlName="{{field.name}}"
                           selectMode="single"
                           rangeSeparator="-"
                           placeholder="{{datepickerPlaceholder}}" #inputToTriggerPaymPickerDate="rgiRxDateTimeInput">
                    <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                            [rgiRxDateTimeTrigger]="inputToTriggerPaymPickerDate"></button>
                    <rgi-rx-date-time #paymPicker
                                      pickerType="calendar"
                                      [hour12Timer]="true"
                    ></rgi-rx-date-time>
                  </rgi-rx-form-field>
                  </div>
                </ng-container>
              </ng-container>
            </ng-template>

          </div>
        </rgi-rx-expansion-panel>
      </div>
      <div class="rgi-ui-panel-footer rgi-anag-m-m">
        <button class="rgi-ui-btn rgi-ui-btn-icon" type="button" data-qa="anag-btn-new-payment"
          [disabled]="anagPaymentSectionComponent.partyPayments && anagPaymentSectionComponent.partyPayments.length >0 && !anagPaymentSectionComponent.partyPayments[anagPaymentSectionComponent.partyPayments.length -1]?.paymentType"
          (click)="addNewPayment()" translate>_ANAG_._BTN_._NEW_PAYMENT_
          <span class="rgi-ui-icon-plus"></span>
        </button>
      </div>
    </div>

    <rgi-rx-snackbar tag="er-tag"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="iban-tag"></rgi-rx-snackbar>
    <div class="rgi-ui-panel-footer">
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
        translate>_ANAG_._BTN_._CANCEL_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button"
              (click)="savePaymentData()" data-qa="anag-btn-confirm"
        translate>_ANAG_._BTN_._CONFIRM_
      </button>
    </div>
  </div>
</form>

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mic-residual-discount',
  templateUrl: './residual-discount.component.html',
  styleUrls: ['./residual-discount.component.scss']
})
export class ResidualDiscountComponent implements OnInit {

  @Input() arrayOfElements: Array<Array<any>>;
  @Input() tableHeader;
  @Input() nestedTableHeader;
  @Input() viewAccordion = true;
  private nestedTablesOpen: Map<number, boolean> = new Map();

  ngOnInit() {
    this.arrayOfElements.forEach(
      (data) => {
        this.nestedTablesOpen.set(Number(data[0].getId()), false);
      }
    );
  }

  toggleAccordion(index) {
    const rowId = Number(this.arrayOfElements[index][0].getId());
    if (this.nestedTablesOpen.get(rowId) === true) {
      this.nestedTablesOpen.set(rowId, false);
    } else {
      this.nestedTablesOpen.set(rowId, true);
    }
  }

  isNestedTableOpen(index): boolean {
    if (index % 2 > 0) {
      index = index - 1;
    }
    if (!this.arrayOfElements || !this.arrayOfElements[index]
      || !this.arrayOfElements[index][0]
      || !this.arrayOfElements[index][0].getId()) {
      return false;
    }
    const rowId = Number(this.arrayOfElements[index][0].getId());
    return this.nestedTablesOpen.get(rowId);
  }
}

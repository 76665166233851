<div class="survey-edit" *ngIf="st.initialized">

  <app-form-survey-edit
    [questionnaires]="st.questionnaires"
    [selectedQuestionnaire]="st.surveyVersion.survey.questionnaire"
    [surveyVersion]="st.surveyVersion"
    [disabled]="st.saveMode!=='create'"
    [anonymous]="!!st.integrationFilter"
    (selectQuestionnaire)  = "onSelectQuestionnaire($event)"
  >
  </app-form-survey-edit>

  <div class="ppevo-survey-questionnaire" *ngIf="st.surveyVersion.lastVersion.questionnaire">
    <ppevo-questionnaire
      [disabledQuestions]="st.integrationFilter ? st.integrationFilter.lockedQuestions : []"
      [disabled]="st.saveMode!=='create' && !st.isEdit"
      [questionnaireFlat]="st.surveyVersion.lastVersion.questionnaire"
      (clickQuestionInfoNotes)="onClickQuestionInfoNotes($event, notesModal)"
      (clickSectionInfoNotes)="onClickSectionInfoNotes($event, notesModal)"
    ></ppevo-questionnaire>
  </div>

  <ppevo-navbar (btnClick)="onBtnClick($event)" [stickable]="st.navigation.stickable" *ngIf="st.navigation">
    <ng-container *ngFor="let btn of st.navigation.map | keyvalue: preserveKeysOrder" >
      <ppevo-navbtn *ngIf="btn.value.visible"
                    [btnId]="btn.key"
                    [ngClass]="btn.value.styleClass"
                    translate>{{btn.value.label}}</ppevo-navbtn>
    </ng-container>
  </ppevo-navbar>

</div>

<rgi-rx-modal #notesModal>
  <ppevo-panel [showFooter]="false" [closable]="true" [notifyCloseOnly]="true" (close)="notesModal.close()">
    <div header translate>NG_PASSPROPRO_SURVEY.notes-title</div>
    <div body [innerHTML]="notes"></div>
  </ppevo-panel>
</rgi-rx-modal>

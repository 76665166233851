import {Component, OnInit, Output, EventEmitter, Optional, Inject} from '@angular/core';
import {DIALOG_DATA} from '@rgi/rx/ui';

@Component({
  selector: 'lib-copy-report-modal',
  templateUrl: './copy-report-modal.component.html'
})
export class CopyReportModalComponent implements OnInit {

  @Output() modalClose = new EventEmitter();
  copiedId: any;
  copyreport: any;

  constructor(@Optional() @Inject(DIALOG_DATA) data: CopyReportModalComponent) { }

  ngOnInit() {
  }

  close() {
    this.modalClose.emit({close: true, copied: false});
  }

  copyReport(copiedId: any, copyreport: any) {
    this.modalClose.emit({close: true, copied: true});
  }
}

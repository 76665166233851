import { Inject, Injectable, Optional } from '@angular/core';
import { SurveyCommonOperations, SurveyEvalTools, SurveyEvaluateState, SurveyEvaluateStateOperations } from '@rgi/ng-passpropro-survey';
import { JS_ROUTES, PV_TOKEN } from '../models/consts/lpc-consts';
import { ModalService } from '@rgi/rx/ui';
import { DocumentResource } from '@rgi/ng-passpropro/documents';
import { RoutingService } from '@rgi/rx/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SurveyEvaluateStateLifeOperations<S extends SurveyEvaluateState> extends SurveyEvaluateStateOperations<SurveyEvaluateState> {

  constructor(
    _commOps: SurveyCommonOperations,
    _modalService: ModalService,
    _routingService: RoutingService,
    documentResource: DocumentResource,
    _tools: SurveyEvalTools,
    @Inject(PV_TOKEN.CORE_SESSION_SERVICE) @Optional() protected sessionService,
    @Inject(PV_TOKEN.CORE_INJECTOR) @Optional() protected injector) {
    super(_commOps, _modalService, _routingService, documentResource, _tools);
    console.log('Sessione', sessionService);
  }

  override getInit$(st$: Observable<S>): Observable<S> {
    return super.getInit$(st$).pipe(
      switchMap(state => {
        const lifeDetailSession = this.sessionService.list()?.find(session => session.currentRoute === JS_ROUTES.LIFE_POLICY_DETAIL)?.idSession;
        if (!lifeDetailSession) {
          return of(state as S);
        }

        const selectedProduct = this.injector.get('coreResult').getResult(lifeDetailSession, 'quoteFromDetail')?.quote?.product?.code;
        if (selectedProduct) {
          state.selectEnabled = false;

          if (!!state.productsCfg.prodsCfgMap[selectedProduct]) {
            state.productsCfg.prodsCfgMap[selectedProduct].visible = true;
          } else if (!state.productsCfg.prodsCfgMap[selectedProduct] || !state.evaluatorProducts.find(p => p.code === selectedProduct)) {
            state.productsCfg.hasVisibleProds = false;
          }
          if (!!state.evaluatorProducts.find(p => p.code === selectedProduct)) {
            state.evaluatorProducts.find(p => p.code === selectedProduct).selected = true;
          }

          // deselect all other products
          state.evaluatorProducts.filter(p => p.code !== selectedProduct).forEach(p => {
            p.selected = false;
            p.selectable = false;
          });

        }

        return of(state as S);
      })
    );
  }


  override beforeUpdate$(st: S): Observable<S> {
    const lifeDetailSession = this.sessionService.list()?.find(session => session.currentRoute === JS_ROUTES.LIFE_POLICY_DETAIL)?.idSession;
    const selectedProduct = this.injector.get('coreResult').getResult(lifeDetailSession, 'quoteFromDetail')?.quote?.product?.code;

    return super.beforeUpdate$(st).pipe(
      switchMap(state => {
        if (!!lifeDetailSession && !!selectedProduct && !!state.navigation?.map?.MODIFY) {
          // hide personalize button
          state.navigation.map.MODIFY.visible = false;
        }

        return of(state as S);
      })
    );
  }
}

export enum SelectionState {
    MANDATORY = 'MANDATORY',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
}

export enum ToolStatusOnCache {
  CHECKED = 'CHECKED',
  REMOVED = 'REMOVED'
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Variable} from '../../models/domain-models/variable';
import {UntypedFormGroup} from '@angular/forms';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {City} from '../../models/domain-models/city';
import {Province} from '../../models/domain-models/province';
import {Subscription} from 'rxjs';
import {GISService} from '../../gis-service';

@Component({
  selector: 'mic-variable-control',
  templateUrl: './variable-control.component.html'
})
export class VariableControlComponent implements OnInit, OnDestroy {

  @Input() validateFormOnEmitChanges = true;
  @Input() variable: Variable;
  @Input() variableControlForm: UntypedFormGroup;
  @Output() updateVariable: EventEmitter<string> = new EventEmitter();
  cities: Array<City>;
  provinces: Array<Province>;
  initializationCompleted = false;
  protected subscriptions: Subscription = new Subscription();

  constructor(
    protected gisService: GISService
  ) {
  }

  commaToDot(input: any) {
    return  (input != null && typeof input === 'string') ? input.replace(',', '.') : input;
  }

  ngOnInit(): void {

    /*         Object.keys(this.variableControlForm.controls).forEach((fieldName) => {
                this.variableControlForm.get(fieldName).markAsDirty();
            }); */

    if (this.variable.variableClass === 7) {
      const citiesSubscription = this.gisService.getCities().subscribe(
        (cities) => {
          if (this.cities && this.cities.length > 0) {
            this.variableControlForm.get(this.variable.identificationCode).setValue(null);
          }
          this.cities = cities;
          this.updateVariable.emit('updateProvinceVariable');
          // this.updateVariables();
        }
      );
      this.subscriptions.add(citiesSubscription);
    } else if (this.variable.variableClass === 8) {
      this.provinces = this.gisService.getProvinces();
    }

    if (this.variable.type === 4) {
      if (!this.variableControlForm.controls[this.variable.identificationCode].errors
        || (this.variableControlForm.controls[this.variable.identificationCode].errors
          && !this.variableControlForm.controls[this.variable.identificationCode].errors.required)) {
        this.variableControlForm.controls[this.variable.identificationCode].setErrors(null);
        this.variableControlForm.controls[this.variable.identificationCode].updateValueAndValidity();
        this.variableControlForm.updateValueAndValidity();
      }
      const variableValueChangesSubscription =
        this.variableControlForm.controls[this.variable.identificationCode].valueChanges.subscribe(
          (event) => {
            if (this.variable.type === 4) {
              const date: Date = event;
              if (date && date instanceof Date) {
                const year = date.getFullYear();
                if (year && year.toString().length === 4) {
                  this.onVariableValueChange();
                }
              } else {
                this.onVariableValueChange();
              }
            } else {
              this.onVariableValueChange();
            }
          }
        );
      this.subscriptions.add(variableValueChangesSubscription);
    }

    this.initializationCompleted = true;
  }


  onVariableValueChange() {

    if (!this.initializationCompleted) {
      return;
    }

    const value = this.variableControlForm.controls[this.variable.identificationCode].value;

    this.variable.value = value;

    if (this.variable.type === 4) {

      const newDate: Date = value;
      if (!(value instanceof Date)) {
        this.variableControlForm.controls[this.variable.identificationCode].setErrors({invalidDate: true});
        return;
      }
      const newMonth = (String(Number(newDate.getMonth()) + 1).length === 1) ? '0'
        + String(Number(newDate.getMonth()) + 1) : String(Number(newDate.getMonth()) + 1);

      this.variable.value = newDate.getDate()
        + '/'
        + newMonth
        + '/' + newDate.getFullYear();

      if (!this.variableControlForm.controls[this.variable.identificationCode].errors
        || (this.variableControlForm.controls[this.variable.identificationCode].errors
          && !this.variableControlForm.controls[this.variable.identificationCode].errors.required)) {
        this.variableControlForm.controls[this.variable.identificationCode].updateValueAndValidity({emitEvent: false});
        this.variableControlForm.updateValueAndValidity({emitEvent: false});
        this.variableControlForm.controls[this.variable.identificationCode].setErrors(null);
        this.updateVariables();
      }
    } else if (this.variable.variableClass === 7) {
      this.variable.value = value.istatCode;
    } else if (this.variable.variableClass === 8) {
      this.variable.value = value.code;
      const citiesSubscription = this.gisService.retrieveCities(value.code).subscribe(
        (cities) => {
          // this.cities = cities;
          this.gisService.setCities(cities);
        }
      );
      this.subscriptions.add(citiesSubscription);
    }
    if (this.variable.variableClass !== 8 && this.variable.type !== 4) {
      const variableControl = this.variableControlForm.get(this.variable.identificationCode);
      variableControl.updateValueAndValidity();
      if (this.variable.variablesValue && this.variable.variablesValue.length > 0) {
        const variableValue = this.variable.variablesValue.find(varValue => varValue.code === variableControl.value);
        this.variable.valueDescription = variableValue ? variableValue.description : null;
      }
      this.updateVariables();
    }
  }

  genericEntitiesTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  compareCities(city1: City, city2: City): boolean {
    return city1 && city2 ? city1.id === city2.id : city1 === city2;
  }

  compareProvinces(province1: Province, province2: Province): boolean {
    return province1 && province2 ? province1.id === province2.id : province1 === province2;
  }

  genericEntitiesTrackByFnCustom(genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private updateVariables() {
    if (!this.validateFormOnEmitChanges || this.variableControlForm.valid) {
      if (this.variable.dependent) {
        this.updateVariable.emit('updateDependentVariable');
      } else {
        this.updateVariable.emit('updateNotDependentVariable');
      }
    }
  }

}

import {RgiPncPsalesStateManager} from "../../api/rgi-pnc-postsales-state.manager";
import {BehaviorSubject, Observable, of} from "rxjs";
import {PncPsalesMovementsState} from "../../resources/states/movements-state";
import {ActiveRoute} from "@rgi/rx/router";
import {StateStoreService} from "@rgi/rx/state";
import {PncPostSalesOrchestratorService} from "../../orchestrator/pnc-postsales-orchestrator.service";
import {PncPostSalesIntegrationService} from "../../services/pnc-postsales-integration.service";
import {RgiRxPushMessageHandlerService} from "@rgi/rx";
import {PncPsalesHttpErrorService} from "../../services/pnc-postsales-http-error.service";
import {PncDomainService} from "../../resources/http/pnc-domain.service";
import {catchError, concatMap, map} from "rxjs/operators";
import {MovementsList} from "../../resources/model/api/movement";
import {ModalService, TableSchema} from "@rgi/rx/ui";
import {MOVEMENTS_TABLE_SCHEMA} from "../../resources/constants/table-schemas/movements-list-schema";
import {PNC_POSTSALES_REVERSAL_ACCOUNTING} from "../../resources/enums/reversal-accounting-collection";
import {PNC_PSALES_BTN_CANCEL, PNC_PSALES_BTN_CONTINUE, PNC_PSALES_BTN_END} from "../../resources/constants/buttons";
import {PNC_PSALES_ACTIONS} from "../../resources/constants/actions";
import {
  PncPsalesConfirmationModalComponent,
  RgiPncConfirmationModalData
} from "../../components/modals/pnc-confirmation-modal/confirmation-modal.component";
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {PncPsalesButton} from "../../resources/model/common/button";

export class PncPsalesMovementsStateManager extends RgiPncPsalesStateManager<PncPsalesMovementsState> {

  routePrefix: string;


  private movementsData$ = new BehaviorSubject<any>([]);

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private resourceService: PncDomainService,
    private modalService: ModalService,
    private _translateService: RgiRxTranslationService
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
    this.routePrefix = context.apiPrefix;
    if (!this.routePrefix) {
      throw new Error('Api Prefix is not provided for movements step');
    }
  }

  initState$(state: Observable<PncPsalesMovementsState>): Observable<PncPsalesMovementsState> {
    return state.pipe(
      concatMap((st: PncPsalesMovementsState) => {
        st.footerBtns = [PNC_PSALES_BTN_END];
        return this.resourceService.getDraftMovements$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix).pipe(
          map((resp: MovementsList) => {
            st.movements = resp;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesMovementsState) => st)
    );
  }

  public get movementsListTableData() {
    return this.movementsData$.asObservable();
  }

  drawMovementsTableRows() {
    this.movementsData$.next(this.getCurrentState().movements.map( (row) => {
      return {
        key: row.key,
        type: row.causeType?.description ? row.causeType.description : '',
        description: row.description,
        issue: row.issue,
        effect: row.effect,
        effectHour: row.effectHour,
        annexNumber: row.appendix ? row.appendix : '',
        user: row.user?.description ? row.user.description : '',
        cancelled: row.cancelled,
        cancelledDescription: this.getTranslated(row.cancelled ? '_RGIPNC_._LABEL_._YES_' : '_RGIPNC_._LABEL_._NO_'),
        cancellable: row.cancellable,
      };
    }));
  }

  getTranslated(key: string) {
    var translated = '';
    this._translateService.translate(key).subscribe(t => translated = t);
    return translated;
  }

  getTableDataSchema(): TableSchema {
    return MOVEMENTS_TABLE_SCHEMA;
  }

  onAction(action: string): Observable<PncPsalesMovementsState> | void {
    switch (action) {
      case PNC_PSALES_ACTIONS.END:
        this.integrationService.closeSession(this.activeRoute.id);
        break;
    }
  }

  onActionDetails($event) {
    alert('Work in progress: dettaglio');
  }

  onActionDelete($event) {
    var movementKey = $event.key;
    var msgKey = '_PCPSALES_._MSG_._NOT_ALLOWED_REVERSAL_';
    var buttons = [PNC_PSALES_BTN_CANCEL];

    if(this.getCurrentState().movements.find(m => m.key === movementKey).cancellable) {
      switch (this.getCurrentState().movements.find(m => m.key === movementKey).reversalAccCollection) {
        case PNC_POSTSALES_REVERSAL_ACCOUNTING.NONE:
          msgKey = '_PCPSALES_._MSG_._CONFIRM_REVERSAL_';
          buttons = [PNC_PSALES_BTN_CANCEL, PNC_PSALES_BTN_CONTINUE];
          break;
        case PNC_POSTSALES_REVERSAL_ACCOUNTING.NO:
          msgKey = '_PCPSALES_._MSG_._ALERT_ACCOUNTING_REVERSAL_';
          buttons = [];
          break;
        case PNC_POSTSALES_REVERSAL_ACCOUNTING.YES:
          msgKey = '_PCPSALES_._MSG_._WARNING_ACCOUNTING_REVERSAL_';
          buttons = [PNC_PSALES_BTN_CONTINUE];
        case PNC_POSTSALES_REVERSAL_ACCOUNTING.REQUEST:
          msgKey = '_PCPSALES_._MSG_._CONFIRM_ACCOUNTING_REVERSAL_';
          buttons = [PNC_PSALES_BTN_CANCEL, PNC_PSALES_BTN_CONTINUE];
          break;
      }
    }
    this.openModalDeleteMovement(msgKey, buttons, movementKey);
  }

  openModalDeleteMovement(msgKey: string, buttons: PncPsalesButton[], movementKey : string) {
    this._translateService.translate(msgKey)
      .pipe(
        concatMap(translated => {
          const cancellationModal = this.modalService.openComponent(
            PncPsalesConfirmationModalComponent, new RgiPncConfirmationModalData(translated, buttons));
          return cancellationModal.modal.onClose;
        })
      ).subscribe(action => {
      if (action === PNC_PSALES_ACTIONS.CONTINUE) {
        this.deleteMovement(movementKey);
      }
    });
  }

  deleteMovement(movementId: string) {
    const st$ = of(this.getCurrentState()).pipe(
      concatMap((st: PncPsalesMovementsState) => {
        return this.resourceService.deleteMovement$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix, movementId).pipe(
          map(() => {
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesMovementsState) => {
        return this.initState$(of(st));
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesMovementsState) => st)
    );
    this.updateState$(st$);
  }

  dispose() {
    super.dispose();
    // my disposes
    this.movementsData$.unsubscribe();
  }
}

import {FormControl, UntypedFormGroup} from '@angular/forms';


export function NodeValidator(controlName: string, selectLabel: string) {

  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.value) {
      if (control.value === selectLabel) {
      control.setErrors({required: true});
      } else {
      control.setErrors(null);
      }
    }
   };
}


export class TotalDiscounts {
  constructor(
    public totalGrossPremium: number,
    public totalEffective1: number,
    public totalMax1: number,
    public totalAmount1: number,
    public totalPerc1: number,
    public totalEffective2: number,
    public totalMax2: number,
    public totalAmount2: number,
    public totalPerc2: number,
    public totalMin1: number,
    public totalMin2: number
  ) {
  }
}

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA} from '@rgi/rx/ui';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {getDatePickerFilter} from '../../../group-policy-resources/group-policy-date.utils';
import {formatISO} from 'date-fns';

export class AmendmentModalData {
  lastMovEffDate: string;
  mpExpireDate: string;

  constructor(mpEffectiveDate: string, mpExpireDate: string) {
    this.lastMovEffDate = mpEffectiveDate;
    this.mpExpireDate = mpExpireDate;
  }
}

@Component({
  selector: 'rgi-gp-amendment-modal',
  templateUrl: './gp-amendment-modal.component.html'
})
export class GpAmendmentModalComponent {
  modalClose = new EventEmitter<string | null>();

  modalData: AmendmentModalData;
  amendmentFG = new UntypedFormGroup({});

  constructor(@Optional() @Inject(DIALOG_DATA) data: AmendmentModalData) {
    this.modalData = data;
    // todo refactor date mng
    const dateParts = this.modalData.mpExpireDate.split('/');
    this.modalData.mpExpireDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
    this.amendmentFG.addControl('amendmentEffDate', new UntypedFormControl(new Date(this.modalData.lastMovEffDate), Validators.required));
  }

  confirm() {
    if (this.amendmentFG.valid) {
      const effDate = this.amendmentFG.controls.amendmentEffDate.value as Date;
      this.modalClose.emit(formatISO(effDate, {representation: 'date'}));
    }
  }

  getDateFilter(modalData: AmendmentModalData) {
    if (!!modalData.lastMovEffDate || !!modalData.mpExpireDate) {
      return (pickerValue: Date) => {
        return getDatePickerFilter(modalData.lastMovEffDate, modalData.mpExpireDate, pickerValue);
      };
    }
    return () => true;
  }
}

<template #modalOverlay></template>
<div class="title-content-card ng-scope">
  <span translate>Installer</span>
</div>
<div class="row form-group">
  <div class="col-sm-6 col-xs-12">
    <label for="zipCode" translate>Zip Code:</label>
    <pnc-required [required]="required"></pnc-required>
    <div [formGroup]="parentForm" class="d-flex">
      <input
        #zipCode
        class="form-control mic-input"
        data-qa="zipCode"
        formControlName="zipCode"
        name="zipCode"
        type="text"
      />
      <button
        (click)="openInstallerModal()"
        [disabled]="isZipCodeEmpty()"
        class="btn btn-primary findInstallerBtn"
        data-qa="findInstaller"
        translate
        type="button"
      >
        FIND
      </button>
    </div>

    <div *ngIf="errors && errors.length > 0" class="">
      <ul class="list_error">
        <li *ngFor="let error of errors" translate>
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="installer && installer.installerCode">
  <div *ngFor="let installerDataRow of installerDataRows" class="nmf_cont_riga">
    <div
      *ngFor="let data of installerDataRow"
      class="nmf_cont_single_data nmf_cont_single_data_color"
    >
      <span translate>{{ data?.label }}</span>
      <span
      >: <b>{{ data?.value }}</b></span
      >
    </div>
  </div>
</div>

<ng-container [formGroup]="group">

  <rgi-rx-form-field *ngIf="!factor.multipleOptionsSelectable; else multiOptionTpl">
    <label rgiRxLabel>
      <span>{{factor.description}}</span>
    </label>
    <select rgiRxNativeSelect
            rgiGpFocusElement
            [attr.data-qa]="'factor' + factor.code"
            class="rgi-ui-form-control"
            formControlName="{{factor.code}}">
      <option value=""></option>
      <option *ngFor="let value of factor.values" [value]="value.code">{{value.description}}</option>
    </select>
  </rgi-rx-form-field>
  <ng-template #multiOptionTpl>
      <rgi-rx-form-field >
        <label rgiRxLabel>{{factor.description}}</label>
        <rgi-rx-multiselect placeholder="" formControlName="{{factor.code}}" (close)="onCloseEv($event)" >
          <rgi-rx-option *ngFor="let option of factor.values trackBy: trackOption" [value]="option" [label]="option.description">
            {{option.description}}
          </rgi-rx-option>
        </rgi-rx-multiselect>
      </rgi-rx-form-field>
  </ng-template>
</ng-container>

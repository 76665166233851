import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, NgForOf, NgSwitchCase} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RgiRxHttpModule} from '@rgi/rx/http';
import {
  RGI_RX_DROP_SELECTION_HANDLER,
  RgiRxDatePickerModule,
  RgiRxDragDropModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule,
  RgiRxModalModule,
  RgiRxMultiselectModule,
  RgiRxPanelModule,
  RgiRxSnackbarModule,
  RgiRxTableModule,
  RgiRxTabModule
} from '@rgi/rx/ui';
import {RgiRxFormModule, RgiRxQualityAssuranceModule} from '@rgi/rx';
import {RgiRxRoutingModule} from '@rgi/rx/router';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {ConsultationStartCardComponent} from './consultation/components/consultation-start-card/consultation-start-card.component';
import {CONTRACT_CONSULTATION_ROUTES} from './contract-consultation-routes';
import {TRANSLATIONS_CONTRACT_CONSULTATION} from '../translate_i18n/i18n';
import { ConsultationListComponent } from './consultation/components/consultation-list/consultation-list.component';
import {
  ConsultationStartCardNodeModalComponent
} from './consultation/components/consultation-start-card/consultation-start-card-node-modal/consultation-start-card-node-modal.component';
import {
  ConsultationStartCardActionModalComponent
} from './consultation/components/consultation-start-card/consultation-start-card-action-modal/consultation-start-card-action-modal.component';
import {
  ConsultationStartCardReportModalComponent
} from './consultation/components/consultation-start-card/consultation-start-card-report-modal/consultation-start-card-report-modal.component';
import {
  ContractConsultationFieldConfigurationService
} from './consultation/services/contract-consultation-field-configuration.service';
import {
  ConsultationContractDetailsComponent
} from './consultation/components/consultation-contract-details/consultation-contract-details.component';
import { AdministrativeDataComponent } from './consultation/components/consultation-contract-details/administrative-data/administrative-data.component';
import {
  ConsultationMovementsComponent
} from './consultation/components/consultation-contract-details/consultation-movements/consultation-movements.component';
import {
  ConsultationPolicyDetailsComponent
} from './consultation/components/consultation-contract-details/consultation-policy-details/consultation-policy-details.component';
import { CcPolicyDetailsAdminDataComponent } from './consultation/components/consultation-contract-details/consultation-policy-details/consultation-policy-details-administrative-data/cc-policy-details-admin-data.component';
import { ConsultationDeliveryAddressComponent } from './consultation/components/consultation-contract-details/consultation-policy-details/consultation-delivery-address/consultation-delivery-address.component';
import { ConsultationAccordionPremiumComponent } from './consultation/components/consultation-contract-details/consultation-accordion-premium/consultation-accordion-premium.component';
import { ConsultationPremiumTabComponent } from './consultation/components/consultation-contract-details/consultation-accordion-premium/consultation-premium-tab/consultation-premium-tab.component';
import { ConsultationRowComponent } from './consultation/components/consultation-contract-details/consultation-row/consultation-row.component';
import { ConsultationClausesComponent } from './consultation/components/consultation-contract-details/consultation-policy-details/consultation-clauses/consultation-clauses.component';
import { ConsulationPolicyDetailsFactorsComponent } from './consultation/components/consultation-contract-details/consultation-policy-details/consultation-policy-details-factors/consultation-policy-details-factors.component';
import { ConsultationPremiumDetailModalComponent } from './consultation/components/consultation-contract-details/consultation-accordion-premium/consultation-premium-detail-modal/consultation-premium-detail-modal.component';
import { ConsultationWarningsComponent } from './consultation/components/consultation-contract-details/consultation-policy-details/consultation-warnings/consultation-warnings.component';
import { ConsultationSubjectsComponent } from './consultation/components/consultation-contract-details/consultation-subjects/consultation-subjects.component';
import { ConsulationAssetsComponent } from './consultation/components/consultation-contract-details/consultation-subjects/consultation-assets/consultation-assets.component';
import { ConsultationWarrantiesComponent } from './consultation/components/consultation-contract-details/consultation-warranties/consultation-warranties.component';
import { ConsultationWarrantiesAssetsFactorsComponent } from './consultation/components/consultation-contract-details/consultation-warranties/consultation-assets/consultation-factors/consultation-factors.component';
import { ConsultationWarrantiesAssetsComponent } from './consultation/components/consultation-contract-details/consultation-warranties/consultation-assets/consultation-assets.component';
import { ConsultationWarrantiesAssetsSubjectsComponent } from './consultation/components/consultation-contract-details/consultation-warranties/consultation-assets/consultation-subjects/consultation-subjects.component';
import {
  ConsultationSubstitutionsComponent
} from './consultation/components/consultation-contract-details/consultation-policy-details/consultation-substitutions/consultation-substitutions.component';

@NgModule({
    declarations: [
        ConsultationStartCardComponent,
        ConsultationListComponent,
        ConsultationStartCardNodeModalComponent,
        ConsultationStartCardActionModalComponent,
        ConsultationStartCardReportModalComponent,
        ConsultationContractDetailsComponent,
        AdministrativeDataComponent,
        ConsultationPolicyDetailsComponent,
        CcPolicyDetailsAdminDataComponent,
        ConsultationDeliveryAddressComponent,
        CcPolicyDetailsAdminDataComponent,
        ConsultationAccordionPremiumComponent,
        ConsultationMovementsComponent,
        ConsultationPremiumTabComponent,
        ConsultationRowComponent,
        ConsultationClausesComponent,
        ConsulationPolicyDetailsFactorsComponent,
        ConsultationPremiumTabComponent,
        ConsultationPremiumDetailModalComponent,
        ConsultationWarningsComponent,
        ConsultationSubstitutionsComponent,
        ConsultationClausesComponent,
        ConsulationPolicyDetailsFactorsComponent,
        ConsultationSubjectsComponent,
        ConsulationAssetsComponent,
        ConsultationWarrantiesComponent,
        ConsultationWarrantiesAssetsComponent,
        ConsultationWarrantiesAssetsFactorsComponent,
        ConsultationWarrantiesAssetsSubjectsComponent
    ],
    imports: [
        CommonModule,
        RgiRxHttpModule,
        ReactiveFormsModule,
        RgiRxFormModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxTableModule,
        RgiRxTabModule,
        RgiRxRoutingModule.forRoot(CONTRACT_CONSULTATION_ROUTES),
        RgiRxI18nModule.forRoot(TRANSLATIONS_CONTRACT_CONSULTATION),
        RgiRxModalModule,
        RgiRxPanelModule,
        RgiRxSnackbarModule,
        RgiRxExpansionModule,
        RgiRxDragDropModule,
        RgiRxMultiselectModule,
        RgiRxQualityAssuranceModule,
        NgForOf,
        NgSwitchCase,
        ReactiveFormsModule,
        RgiRxFormElementsModule
    ],
    providers: [
        DatePipe,
        CurrencyPipe,
        {
            provide: RGI_RX_DROP_SELECTION_HANDLER,
            useValue: {
                name: 'nodeSelection',
                handler: ConsultationStartCardNodeModalComponent
            },
            multi: true
        },
        ContractConsultationFieldConfigurationService,
    ],
    exports: []
})
export class ContractConsultationModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AnagPartyLink} from '../../anag-model/anag-domain/anag-party-link';
import {AnagStateManagerPartyEditor} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagDialogComponent, ModalDialogData} from '../modal/anag-dialog/anag-dialog.component';
import {ModalService} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';


export class LinkPartyEvent {
  action: string;
  party: AnagPartyLink;
  linkSubjectType: string;

  constructor(action, party, personType?) {
    this.action = action;
    this.party = party;
    this.linkSubjectType = personType;
  }
}

@Component({
  selector: ' rgi-anag-party-link',
  templateUrl: './party-link.component.html',
  styleUrls: ['./party-link.component.css'],
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagPartyLinkComponent implements OnInit {

  @Input()
  party: AnagApiParty;

  @Output()
  linkUpd: EventEmitter<LinkPartyEvent> = new EventEmitter();

  tmpLinkModel: AnagPartyLink = undefined;
  tmpLinkModel2: AnagPartyLink = undefined;

  showFirstLink = true;
  showSecondLink = false;

  selectedLinksSubjectType: Map<string, string> = new Map();
  linksForm: UntypedFormGroup = new UntypedFormGroup({});

  @Input() edit: boolean;

  constructor(
    public stateMgr: AnagStateManagerPartyEditor,
    protected modalService: ModalService,
    protected translateService: RgiRxTranslationService
  ) {
    this.edit = stateMgr.getCurrentState().editorModeEnable;
  }

  ngOnInit() {
    if (this.party && this.party.links) {
      this.stateMgr.retrieveValuedLinksOptions();
      this.party.links.forEach(link => {
        if (this.edit) {
          this.linksForm.addControl(link.linkedPartyObjectId, new UntypedFormControl(link.linkType.codice, Validators.required));
        } else {
          this.linksForm.addControl(link.linkedPartyObjectId, new UntypedFormControl({
            value: link.linkType.codice,
            disabled: true
          }));
        }
        this.linksForm.addControl(link.linkedPartyObjectId + '-drop', new UntypedFormControl(link, Validators.required));
        this.selectedLinksSubjectType.set(link.linkedPartyObjectId, link.idSubjectLinkType);
      });
    }
  }

  onDropSubjectSelection(event: any) {
    const partySelected = event.changed;
    if (partySelected) {
      if (partySelected.objectId === this.party.objectId) {
        let msg;
        this.translateService.translate('_ANAG_._MSG_._LINK_ERROR_SAME_PARTY_').subscribe(translated => msg = translated).unsubscribe();
        this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(msg, false));
      } else {
        const link = new AnagPartyLink();
        link.linkSubjectNominative = partySelected.nominative;
        link.linkedPartyObjectId = partySelected.objectId;
        link.linkedPartyUniqueKey = partySelected.objectId;
        link.idSubjectLinkType = partySelected.subjectType.codice;
        this.selectedLinksSubjectType.set(partySelected.objectId, partySelected.subjectType.codice);
        this.linkUpd.emit(new LinkPartyEvent('addLink', link, partySelected.subjectType.codice));
        if (this.edit) {
          this.linksForm.addControl(link.linkedPartyObjectId, new UntypedFormControl('', Validators.required));
        }
        this.linksForm.addControl(link.linkedPartyObjectId + '-drop', new UntypedFormControl(link, Validators.required));
      }
      // todo provare a sostituire con eventi rx
      if (this.showFirstLink) {
        this.tmpLinkModel2 = null;
        this.showSecondLink = true;
        this.showFirstLink = false;
      } else {
        this.tmpLinkModel = null;
        this.showFirstLink = true;
        this.showSecondLink = false;
      }
    }
  }

  onLinkRemove(link: AnagPartyLink) {
    this.selectedLinksSubjectType.delete(link.linkedPartyObjectId);
    this.linksForm.removeControl(link.linkedPartyObjectId);
    this.linksForm.removeControl(link.linkedPartyObjectId + '-drop');
    this.linkUpd.emit(new LinkPartyEvent('deleteLink', link));
  }

  getlinkTypes(objectId: string) {
    return this.stateMgr.getCurrentState().linkTypes.get(this.selectedLinksSubjectType.get(objectId));
  }

  onLinkChange($event, link: AnagPartyLink) {
    const selectedLink = this.getlinkTypes(link.linkedPartyObjectId).find(linkType => linkType.code === $event.target.value);
    link.linkType = new AnagEntityIta(selectedLink.code, selectedLink.description);
    this.linkUpd.emit(new LinkPartyEvent('updateLink', link));
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {RgiPncPostsalesStepComponent} from "../../api/rgi-pnc-postsales-step.component";
import {PncPsalesMovementsState} from "../../resources/states/movements-state";
import {FormService} from "../../services/form.service";
import {PncPsalesMovementsStateManager} from "../../state-managers/movements/movements-state-manager.service";
import {RgiRxDatatableComponent} from "@rgi/rx/ui";
import {take, tap} from "rxjs/operators";

@Component({
  selector: 'rgi-pnc-psales-movements',
  templateUrl: './movements.component.html',
})
export class PncPsalesMovementsComponent extends RgiPncPostsalesStepComponent<PncPsalesMovementsState> implements OnInit {

  @ViewChild(RgiRxDatatableComponent, { static: true }) dt: RgiRxDatatableComponent;

  constructor(public stateMgr: PncPsalesMovementsStateManager, formService: FormService) {
    super(stateMgr, formService);
  }

  protected initStep() {
    this.stateMgr.drawMovementsTableRows();
    this.stateMgr.movementsListTableData.pipe(take(1), tap(next => {
      if(this.dt) {
        this.dt.dataSource.update(next);
      }
    })).subscribe();
  }

}

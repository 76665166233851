export class DerogationLevelEntity {
  value: string;
  desc: string;


  constructor(value: string, desc: string) {
    this.value = value;
    this.desc = desc;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccessNoteComponent } from './access-note/access-note.component';
import { BaseNoteComponent } from './base-note/base-note.component';
import { DayComponent } from './day/day.component';
import { MovementNoteComponent } from './movement-note/movement-note.component';
import { NoteHostDirective } from './note/note-host.directive';
import { ReserveNoteComponent } from './reserve-note/reserve-note.component';
import { AddManualNoteComponent } from './add-manual-note/add-manual-note.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommentsComponent } from './comments/comments.component';
import { DocumentNoteComponent } from './document-note/document-note.component';
import { CLAIMS_TRANSLATIONS } from '../../../assets/i18n/translations';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [
        DayComponent,
        AccessNoteComponent,
        BaseNoteComponent,
        NoteHostDirective,
        ReserveNoteComponent,
        MovementNoteComponent,
        AddManualNoteComponent,
        CommentsComponent,
        DocumentNoteComponent
    ],
    imports: [
        CommonModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        DayComponent,
        FormsModule
    ]
})
export class TimelineComponentsModule { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lpc-loader-mobile',
  templateUrl: './loader-mobile.component.html',
  styleUrls: ['./loader-mobile.component.css']
})
export class LoaderMobileComponent implements OnInit {

  @Input() loader: boolean;

  constructor() { }

  ngOnInit() {
  }

}

import moment from 'moment';
import { EMPTY_STR } from '../models/consts/lpc-consts';
import {LocaleDate} from '../models/locale-date';
import {DataInputField} from '../models/postsales-operations-response.model';


export class PlcDateUtils {
  public static isoToLocaleDate(iso: string): LocaleDate {
    if (iso === null || iso === EMPTY_STR) {
      return null;
    }
    const date = new Date(iso);
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  public static dateToLocaleDate(date: Date): LocaleDate {
    if (date) {
      return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
    }
    return null;
  }

  /* This method has been written to avoid null or empty string parameters to return 1970 date */
  public static isoToDate(iso: string): Date | null {
    // TODO: might worth to validate iso string
    if (iso) {
      const date: Date = new Date(iso);
      date.setHours(0, 0, 0 , 0);
      return date;
    }
    return null;
  }

  public static getDates(): DataInputField[] {
    return [{code: '_VOEMI', label: EMPTY_STR, value: new Date(), userInput: false },
            {code: '_1OEFF', label: EMPTY_STR, value: new Date(), userInput: false },
      ] as DataInputField[];
  }

  // Converte Date in YMD
  public static localDateToString(local: LocaleDate): string {
    if (local !== null) {
      let stringMonth = null;
      let stringDay = null;
      local.month < 10 ? stringMonth = '0' + local.month : stringMonth = local.month.toString();
      local.day < 10 ? stringDay = '0' + local.day : stringDay = local.day.toString();
      return [stringDay, stringMonth, local.year.toString()].join('/');
    } else {
      return null;
    }
  }

  // change separator
  public static isoToFormattedDate(date: string, separator: string = '/'): string {
    if (!date) {
      return EMPTY_STR;
    }
    return date.split('-').reverse().join(separator);
  }

  public static dateToString(date: Date): string | null {
    if (!!date) {
      return date.toISOString().substr(0, 10);
    }
    return null;
  }

  public static isoDateTimeToIsoDate(dateTime: string): string {
    const date: Date = new Date(dateTime);
    const year: string = date.getFullYear().toString();
    const month: string = '0' + (date.getMonth() + 1);
    const day: string = '0' + date.getDate();
    return year + '-' + month.substr(month.length - 2, 2) + '-' + day.substr(day.length - 2, 2);
  }

  public static compareTwoDatesAndReturnMin(firstDate: string, secondDate: string): string {
    if (!!firstDate && !!secondDate) {
      if (moment(firstDate).isAfter(secondDate)) {
        return secondDate;
      } else {
        return firstDate;
      }
    }
  }

  public static compareTwoDatesAndReturnMax(firstDate: string, secondDate: string): string {
    if (!!firstDate && !!secondDate) {
      if (moment(firstDate).isAfter(secondDate)) {
        return firstDate;
      } else {
        return secondDate;
      }
    }
  }
}

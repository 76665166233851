import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LpcWarrantyDetailModalComponent } from './lpc-warranty-detail-modal.component';
import { LpcWarrantyDetailModule } from './lpc-warranty-detail/lpc-warranty-detail.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';



@NgModule({
    declarations: [LpcWarrantyDetailModalComponent],
    imports: [
        CommonModule,
        LpcWarrantyDetailModule,
        RgiRxI18nModule
    ],
    exports: [LpcWarrantyDetailModalComponent]
})
export class LpcWarrantyDetailModalModule { }

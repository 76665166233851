import {GenericEntity} from './generic-entity';

export class InsuranceBackground {

  constructor(
    public insuranceBackgroundList: Array<GenericEntity>,
    public insuranceBackgroundRows: GenericEntity[][]
  ) {
  }

}

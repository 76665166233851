import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent} from '@angular/common/http';
import {ModalService, RgiRxSnackbarOverlay} from '@rgi/rx/ui';
import {ErrorManagentComponent} from './error-managent/error-managent.component';
import {PushMessageHandlerService, RgiRxLogger, RgiRxPushMessage} from '@rgi/rx';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {RgiRxHttpError} from '@rgi/rx/http';
import {ReIssueMsgData} from '../re-issue/re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import {ReIssueMsgModalComponent} from '../re-issue/re-issue-msg-modal/re-issue-msg-modal.component';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {mergeMap} from 'rxjs/operators';
import {Violation} from '../models/pc-portfolio-models/GenericErrorPcPortfolio';

@Injectable({
  providedIn: 'root'
})

export class ErrorManagementService {
  private  genericError = '';

  constructor(
    protected modalService: ModalService,
    private logger: RgiRxLogger,
    private translateService: RgiRxTranslationService,
    private pushMessageHandler: PushMessageHandlerService,
    protected snackBarOverlay: RgiRxSnackbarOverlay
  ) {
    this.translation();
  }

  manageHttpEvent(httpEvent: HttpEvent<any>) {
    if (this.isErrorEvent(httpEvent)) {
      this.manageErrorEvent(httpEvent);
    }
  }

  isErrorEvent(httpEvent: HttpEvent<any>): boolean {
    return this.isHttpErrorResponse(httpEvent);
  }

  isHttpErrorResponse(httpEvent: HttpEvent<any>): boolean {
    return httpEvent instanceof HttpErrorResponse;
  }

  manageErrorEvent(httpEvent: HttpEvent<any>) {
    if (this.isHttpErrorResponse(httpEvent)) {
      const errResponse = httpEvent as unknown as HttpErrorResponse;
      this.logger.debug('ErrorManagementService::manageErrorEvent() - error response', errResponse);

      this.showError(this.errorMessageFromHttpErrorResponse(errResponse), false, this.errorMessageTypeFromHttpErrorResponse(errResponse));
    }

    this.logger.debug('ErrorManagementService::manageErrorEvent() - http event', httpEvent);
  }

  showError(msg: string, hideMessage: boolean, type?: string) {
    const {modal, component} = this.modalService.openComponent(ErrorManagentComponent);
    component.errorMessage = msg;
    component.errorType = type;
    component.hideBasicMessage = hideMessage;
  }

  manageHttpCaughtError(error: any) {
    this.logger.debug('ErrorManagementService::manageHttpCaughtError() - caught error', error);
  }

  protected errorMessageTypeFromHttpErrorResponse(resp: HttpErrorResponse): string {
    let type = '';

    switch (resp.status) {
      case 401:
        type = 'unauthorized_error';
        break;
      case 405:
        type = 'authorization_error';
        break;
    }

    return type;
  }

  protected errorMessageFromHttpErrorResponse(resp: HttpErrorResponse): string {
    let msg = resp.message;

    switch (resp.status) {
      case 404:
        msg = 'Errore interno al sistema';
        break;
      case 500:
        msg = 'Errore interno al sistema';
        break;
      case 550:
        msg = this.errorDetailMessageFromHttpErrorResponse(resp, 'Errore servizi Banca');
        break;
      case 551:
        msg = this.errorDetailMessageFromHttpErrorResponse(resp, 'Errore servizi Filenet');
        break;
      case 552:
        msg = this.errorDetailMessageFromHttpErrorResponse(resp, 'Errore servizi Stampa');
        break;
    }

    return msg;
  }

  protected errorDetailMessageFromHttpErrorResponse(response: HttpErrorResponse, message: string) {
    if (response.error) {
      if (!response.error.messages && typeof response.error === 'string') {
        message += ' - ' + response.error;
      } else if (response.error.messages && response.error.messages.length) {
        message += ' - ' + response.error.messages[0];
      } else if (response.error.message) {
        message += ' - ' + response.error.message;
      }
    }

    return message;
  }

  public handleError(error) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.errorBody.messages[0]);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.errorBody.messages[0]);
    }
    // Return an observable with a user-facing error message.
    return throwError(error.errorBody);
  }

  public handleErrorWithModal(error: RgiRxHttpError) {
    if (error instanceof RgiRxHttpError) {
      const message = !!error.errorBody && !!error.errorBody.messages && error.errorBody.messages.length > 0
        && error.errorBody.messages[0].length > 0 ? error.errorBody.messages[0] : this.genericError;
      switch (error.status) {
        case 400:
        case 422:
        case 404: this.showSmallModalError(message); break;
        default: this.showSmallModalError(this.genericError); break;
      }

    }
  }

  public handleErrorWithSnackBar( tag: string): (err: RgiRxHttpError) => Observable<never> {
    return (err: RgiRxHttpError) => {
      if (err.status < 500) {
        if (err.errorBody && err.errorBody.violations && Array.isArray(err.errorBody.violations)) {
          this.manageViolationsArray(err.errorBody.violations, tag);
        } else {
          const error = err.errorBody && err.errorBody.description ? err.errorBody.description :
            'error found but the error structure is not implemented in FE\'s error management';
          this.pushMessageHandler.notify(new RgiRxPushMessage(error, {
              tag,
              status: 'danger',
              dismissible: true
            }
          ));
        }
      } else {
        this.snackBarOverlay.notify(new RgiRxPushMessage(this.genericError, {
          status: 'danger',
          dismissible: true
        }));
      }
      return EMPTY;
    };
  }

  showSmallModalError(msg: string) {
    const msgData = new ReIssueMsgData();
    msgData.msg = msg;
    msgData.showButtonConfirm = false;
    msgData.showButtonContinue = false;
    const {modal, component}  = this.modalService.openComponent(ReIssueMsgModalComponent, msgData);
  }

  showSnackBarError(msg: string, tag: string) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(msg, {
        tag,
        status: 'danger',
        dismissible: true
      }
    ));
  }

  manageViolationsArray(violations: Violation[], tag: string) {
    violations.forEach(error => {
      let level;
      switch (error.level) {
        case 'BLOCKING':
          level = 'danger';
          break;
        default:
          level = 'warning';
          break;
      }
      this.pushMessageHandler.notify(new RgiRxPushMessage(error.message, {
          tag,
          status: level,
          dismissible: true
        }
      ));
    });
  }

  translation() {
    const keys = of(['RE_ISSUE.GENERIC_SYSTEM_ERROR']);
    keys.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.genericError = next [0];
    }).unsubscribe();
  }
}

<ng-container [formGroup]="policyDataForm" *ngIf="statePolicyData">
  <rgi-gp-stepper></rgi-gp-stepper>
  <div style="width: 50%; flex-direction: column">
    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-users"></span>
      <b translate>_GP_._TITLE_._SUBJECTS_</b>
    </div>
    <rgi-gp-party-roles [parentForm]="policyDataForm" [partyRoles]="statePolicyData.allRoleParties"
                        [idParentSession]="activeRoute.id" [stepData]="previousStepData" [actionsExtension] = "dragExtensions"
                        (partyAction)="onPartyAction($event)" data-qa="partyRolesComponent">
    </rgi-gp-party-roles>
    <div data-qa="errorMessage">
      <rgi-gp-group-policy-errors-component *ngIf="!!statePolicyData.errors && !!statePolicyData.errors.length"
                                            [errors]="statePolicyData.errors"
                                            [areaCode]="'ROLES'">
      </rgi-gp-group-policy-errors-component>
    </div>
  </div>

  <div class="rgi-gp-section-title">
    <span class="rgi-gp-icon rgi-ui-icon-tech-data"></span>
    <b translate>_GP_._TITLE_._POLICY_GENERAL_DATA_</b>
  </div>

  <rgi-gp-group-policy-fields [policyDataFields]="statePolicyData.policyDataFields"
                              [parentForm]="policyDataForm"
                              (fieldUpdate)="onPolicyDataUpdate($event)"
                              (customAction)="onFieldAction($event.code)">
  </rgi-gp-group-policy-fields>

  <div class="rgi-gp-flex-row">
    <div class="rgi-gp-field-container">
      <div class="rgi-gp-field">
        <rgi-rx-form-field *ngIf="statePolicyData.conventions && statePolicyData.conventions.allowedValues.length > 0">
          <label rgiRxLabel>
            <span translate>_GP_._LABEL_._CONVENTION_</span>
          </label>
          <select rgiRxNativeSelect formControlName="convention" data-qa="convention" (change)="updateAgreement()">
            <option value=""></option>
            <option *ngFor="let convention of statePolicyData.conventions.allowedValues" [value]="convention?.code">
              {{convention?.description}}
            </option>
          </select>
        </rgi-rx-form-field>
      </div>
    </div>
    <div class="rgi-gp-field-container">
      <div class="rgi-gp-field">
        <rgi-rx-form-field *ngIf="statePolicyData.paymentFrequencies && statePolicyData.paymentFrequencies.allowedValues.length > 0">
          <label rgiRxLabel>
            <span translate>_GP_._LABEL_._PAYMENT_FREQUENCY_</span>
          </label>
          <select rgiRxNativeSelect formControlName="paymentFrequency" data-qa="paymentFrequency"
                  (change)="updatePaymentFrequency()">
            <option value="" *ngIf="statePolicyData.paymentFrequencies.allowedValues.length>1"></option>
            <option *ngFor="let paymentFrequency of statePolicyData.paymentFrequencies.allowedValues"
                    [value]="paymentFrequency.code">{{paymentFrequency.description}}</option>
          </select>
          <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
        </rgi-rx-form-field>
      </div>
    </div>
  </div>

  <rgi-gp-group-policy-factors-component [factors]="statePolicyData.factors" [parentForm]="variablesFG"
                                         (updateFactors)="updateFactors($event)"
                                         (customFactorAction)="onFieldAction($event.code)" class="rgi-gp-flex-row">
  </rgi-gp-group-policy-factors-component>

  <div class="rgi-gp-flex-row" *ngIf="statePolicyData.indexing && statePolicyData.indexing.visible">
    <div class="rgi-gp-field-container">
      <div class="rgi-gp-field">
        <rgi-rx-form-field>
          <label rgiRxLabel>
            <span translate>_GP_._LABEL_._INDEXING_</span>
            <span class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger"
                  *ngIf="statePolicyData.indexing.mandatory">*</span>
          </label>
          <select rgiRxNativeSelect formControlName="indexing" data-qa="indexing" (change)="updateIndexing()">
            <option value=""></option>
            <option *ngFor="let indexing of statePolicyData.indexing.allowedValues" [value]="indexing?.code">
              {{indexing?.description}}
            </option>
          </select>
        </rgi-rx-form-field>
      </div>
    </div>
  </div>

  <rgi-gp-group-policy-extension-data [extensionData]="statePolicyData.extensionData" [parentForm]="policyDataForm"
    (fieldUpdate)="onPolicyDataExtensionUpdate($event)" (customAction)="onFieldAction($event)">
  </rgi-gp-group-policy-extension-data>

  <div data-qa="rgi-gp-policy-data-errors">
    <rgi-gp-group-policy-errors-component [errors]="statePolicyData.errors" [areaCode]="'POLICYDATA'"
                                          *ngIf="!!statePolicyData.errors && !!statePolicyData.errors.length">
    </rgi-gp-group-policy-errors-component>
  </div>

  <rgi-gp-group-policy-clause
    *ngIf="!!statePolicyData.clauses"
    [clauses]="statePolicyData.clauses"
    (updateClauses)="updateClauses($event)"
    [parentForm]="policyDataForm"
    (formChange)="policyDataForm=$event"
  ></rgi-gp-group-policy-clause>

  <rgi-gp-payments-section *ngIf="statePolicyData.editablePayments"
                           [paymentConfig]="statePolicyData.editablePayments"
                           [selectablePayments]="statePolicyData.selectablePayments"
                           [paymentSectionValidCheck]="paymentValidationChecker"
                           (setPaymentMethod)="setPaymentMethod($event)"
                           (isSectionValid)="isPaymentOk = $event">
  </rgi-gp-payments-section>

  <div data-qa="contractAddresses"
       *ngIf="statePolicyData.groupPolicyContactsManagement !== groupPolicyContactsManagement.NONE">

    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-marker"></span>
      <b translate>_GP_._LABEL_._CONTACT_DETAILS_</b>
    </div>
    <rgi-gp-group-contact-details-component [form]="policyDataForm"
                                            [contractAddressesResp]="statePolicyData.contractAddressesResp"
                                            [groupPolicyContactsManagement]="statePolicyData.groupPolicyContactsManagement"
                                            [portfolioContactsManagement]="statePolicyData.portfolioContactsManagement"
                                            (updateContacts)="updateContacts($event)">
    </rgi-gp-group-contact-details-component>

  </div>

  <div data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="statePolicyData.errors"
                                          *ngIf="!!statePolicyData.errors && !!statePolicyData.errors.length"
                                          [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button *ngIf="statePolicyData.fromInquiryInfo.isFromInquiry" class="rgi-ui-btn rgi-ui-btn-secondary uppercase"
            (click)="back()" data-qa="buttonsBack" translate>{{'_GP_._BTN_._BACK_'}}
    </button>
    <button *ngIf="evalIntermediateSaveVisibility()"
            class="rgi-ui-btn rgi-ui-btn-secondary uppercase" [disabled]="policyDataForm.invalid ||
                    !statePolicyData.allRoleParties[1][0].idParty" (click)="callActionSave()" data-qa="buttonsSave"
            translate>{{'_GP_._BTN_._SAVE_'}}
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary uppercase"
            [disabled]="disableContinue()"
            (click)="callActionGoToConfigurationPm()" data-qa="buttonsContinue" translate>{{'_GP_._BTN_._CONTINUE_'}}
    </button>
  </div>

</ng-container>

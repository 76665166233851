<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels"></re-issue-stepper>
<div *ngIf="stateManager.getState$() | async as state">
  <h4>
    <span translate>RE_ISSUE.DATA_CANCELLATION_POLICY</span>
  </h4>

  <form [formGroup]="cancellationForm" class="form-group row">
    <div class="col-sm-6 col-xs-12">
      <rgi-rx-form-field style="display: contents;">
        <label rgiRxLabel translate>RE_ISSUE.CANCELLATION_REASON</label>
        <select rgiRxNativeSelect (change)="onCancellationReasonValueChange()" formControlName="cancellationReason"
                data-qa="cancellation-reason">
          <option value=""></option>
          <option *ngFor="let cancellationReason of state.cancellationReasons"
                  [ngValue]="cancellationReason">{{cancellationReason.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div class="col-sm-6 col-xs-12">
      <label rgiRxLabel><span translate>RE_ISSUE.ISSUE_DATE</span>
      </label>
      <rgi-rx-form-field>
        <input [rgiRxDateTimeInput]="issueDatePicker"
               style="flex: 1"
               data-qa="issueDate"
               selectMode="single"
               formControlName="issueDate"
               rangeSeparator="-"
               disabled="true"
               placeholder="{{'RE_ISSUE.ISSUE_DATE' | translate}}" #inputToTriggerIssueDate="rgiRxDateTimeInput">
        <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                [rgiRxDateTimeTrigger]="inputToTriggerIssueDate"></button>
        <rgi-rx-date-time #issueDatePicker
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>

    <div class="col-sm-6 col-xs-12">
      <rgi-rx-form-field class="date-field">
        <div class="label-input-container">
          <label rgiRxLabel><span translate>RE_ISSUE.EFFECTIVE_DATE</span></label>
          <div class="input-button-container">
            <input [rgiRxDateTimeInput]="effectiveDatePicker"
                   style="flex: 1"
                   data-qa="effectiveDate"
                   selectMode="single"
                   formControlName="effectDate"
                   rangeSeparator="-"
                   placeholder="{{'RE_ISSUE.POLICY_EFFECT_DATE' | translate}}"
                   #inputToTriggerEffectiveDate="rgiRxDateTimeInput">
            <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerEffectiveDate"></button>
          </div>
        </div>
        <rgi-rx-date-time #effectiveDatePicker
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
  </form>

  <form *ngIf="premiumRepaymentForm" [formGroup]="premiumRepaymentForm" class="form-group row">
    <h4 *ngIf="this.state.premiumRepaymentVisibility">
      <span translate>RE_ISSUE.CANCELLATION_PREMIUM_REPAYMENT</span>
    </h4>
    <div class="tbld" *ngIf="state.premiumRepaymentVisibility">
      <div class="tbld_row tbld_row_odd">
        <div class="tbld_col tbld_col_value tbld_border">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="taxes" data-qa="taxes">
            {{ 'RE_ISSUE.TAXES_REPAYMENT' | translate }}
          </rgi-rx-form-field>
        </div>
        <div class="tbld_col tbld_col_value tbld_border">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="taxable" data-qa="taxable">
            {{ 'RE_ISSUE.TAXABLE_REPAYMENT' | translate }}
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="tbld_row tbld_row_odd">
        <div class="tbld_col tbld_col_value tbld_border">
          <rgi-rx-form-field>
            <input rgiRxInput formControlName="rights" data-qa="rights">
            <label rgiRxLabel translate>RE_ISSUE.RIGHTS</label>
          </rgi-rx-form-field>
        </div>
        <div class="tbld_col tbld_col_value tbld_border"></div>
      </div>
    </div>
  </form>

  <rgi-rx-snackbar tag="errorMessage"></rgi-rx-snackbar>
  <div class="btn-group btn-group-justified">
    <div class="btn-group">
      <button data-qa="back" (click)="back()" rgi-rx-button color="secondary" class="btn pull-right" translate>
        RE_ISSUE.BACK
      </button>
    </div>
    <div class="btn-group">
            <span><button (click)="goToNextPage()" class="btn btn-warning pull-right" color="primary"
                          data-qa="Continue">
                          <span translate>RE_ISSUE.NEXT</span></button></span>
    </div>
  </div>
</div>

<div class="card-title" *ngIf="showTitle">
  <span class="menu-title">{{ '_CLAIMS_._POLICY_LIST' | translate }} </span>
</div>

<div [ngClass]="{'customSizeTable': policyList.length > 2}">
    <table rgi-rx-table [dataSource]="datasourcePolicyList">
    <ng-container rgiRxColumnDef="number">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-10"> {{ '_CLAIMS_._POLICY_NR' | translate }}</td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <span attr.data-qa="policy-enable-{{policyIndex}}" class="claims-popover icon rgifont"
                *ngIf="policy.claimBranchCodes || externalPortfolio"
                [ngClass]="isPolicyCanceled(policy) ? 'rgi-survey_ok yellow-color' : 'rgi-survey_ok green-color'">
                <span class="claims-popover-container popover-left">
                    <div *ngIf="isPolicyCanceled(policy)">{{ '_CLAIMS_._INVALID_POLICY' | translate }}</div>
                    <div *ngIf="!isPolicyCanceled(policy)">{{ '_CLAIMS_._VALID_POLICY' | translate }}</div>
                </span>
            </span>
            <span attr.data-qa="policy-number-{{policyIndex}}">{{ policy.policyNumber }}</span>
        </td>
    </ng-container>
    <ng-container rgiRxColumnDef="description">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-20"> {{ '_CLAIMS_._DESCRIPTION' | translate }}</td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <span *ngIf="policy.product" attr.data-qa="policy-description-{{policyIndex}}">{{ policy.description + ' - '
                + policy.product }}</span>
            <span *ngIf="!policy.product" attr.data-qa="policy-description-{{policyIndex}}">{{ policy.description
                }}</span>
        </td>
    </ng-container>
    <ng-container rgiRxColumnDef="holder">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-7"> {{ '_CLAIMS_._POLICY_HOLDER' | translate }}</td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <span attr.data-qa="policy-holder-{{policyIndex}}">{{ policy.contractingSubject }}</span>
        </td>
    </ng-container>
    <ng-container rgiRxColumnDef="originalEffectDate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-7"> {{ '_CLAIMS_._EFFECT_DATE' | translate }}</td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <span *ngIf="policy.originalEffectDate" attr.data-qa="policy-originaleffectdate-{{policyIndex}}">{{ policy.originalEffectDate | date:'dd/MM/yyyy'  }}</span>
            <span *ngIf="!policy.originalEffectDate" attr.data-qa="policy-originaleffectdate-{{policyIndex}}">-</span>
        </td>
    </ng-container>
    <ng-container rgiRxColumnDef="expirationDate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-7"> {{ '_CLAIMS_._EXPIRATION_DATE' | translate }}</td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <span attr.data-qa="policy-expirationdate-{{policyIndex}}">{{ policy.expirationDate | date:'dd/MM/yyyy'  }}</span>
        </td>
    </ng-container>
    <ng-container rgiRxColumnDef="cancellationDate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-7"> {{ '_CLAIMS_._CANCELLATION_DATE' | translate }}</td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <span attr.data-qa="policy-cancellationdate-{{policyIndex}}">
                {{ policy.cancellationDate? (policy.cancellationDate | date:'dd/MM/yyyy') : '-' }}
            </span>
        </td>
    </ng-container>
    <ng-container rgiRxColumnDef="units">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-20"> {{ '_CLAIMS_._UNITS' | translate }}</td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <span *ngFor="let unit of policy.units; let policyIndex = index; let last = last">
                <span attr.data-qa="policy-unit-{{policyIndex}}">
                    {{ unit.description}}
                </span>
                <span *ngIf="!last">, </span>
            </span>
        </td>
    </ng-container>
    <ng-container rgiRxColumnDef="buttons">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="container-width-7"></td>
        <td rgi-rx-cell *rgiRxCellDef="let policy; let policyIndex">
            <div class="row">
                <button type="button" class="btn btn-flat btn-sm mrc-1" (click)="policyDetails(policy)"
                    [disabled]="externalPortfolio">
                    <span class="rgifont rgi-search2"></span>
                </button>
                <button type="button" class="btn btn-flat btn-sm"
                    [ngClass]="!isPolicyEnabled(policy)? 'claims-popover' : ''" [disabled]="!isPolicyEnabled(policy)"
                    (click)="selectPolicyDetail(policy)">

                    <span class="action-icon rgifont rgi-chevron-right">
                        <span *ngIf="!isPolicyEnabled(policy)" class="claims-popover-container">
                            <div>{{ '_CLAIMS_._MESSAGE_._POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY' | translate }}</div>
                        </span>
                    </span>
                </button>
            </div>
        </td>
    </ng-container>
    <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumnsPolicy"></tr>
    <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumnsPolicy;"></tr>
</table>
</div>

export class ApiVoucher {

  objectId?: string;
  voucherNumber?: string;
  surname?: string;
  name?: string;
  companyFlag?: string;
  userId?: string;
  city?: string;
  postalCode?: string;
  address?: string;
  contactSurname?: string;
  contactName?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  phoneNumber3?: string;
  email?: string;
  manufacturer?: string;
  model?: string;
  licencePlateNumber?: string;
  chassisNumber?: string;
  color?: string;
  installerZipCode?: string;
  installerCode?: string;

  message?: string;

  constructor() {
  }


}

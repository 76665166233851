<button *ngIf="isAdminFunctionEnabled" class="slide analytics-buttons" (click)="openNewReport()">
    <div class="icon-left">
        <span class="rgi-ui-icon-note qlikIcon"></span>
    </div>
    <div class="text-left">
        {{'_NEW_REPORT_' | translate}}
    </div>
    <div class="icon-right">
        <span class="rgi-ui-btn rgi-ui-icon-right-arrow analytics-arrow"></span>
    </div>
</button>

<div rgi-rx-accordion #accordionRef=rgiRxAccordion>
  <rgi-rx-expansion-panel color="info" *ngFor="let reportArea of reportAreas | sortReport: 'null': 'description'">
      <div rgi-rx-expansion-panel-header (click)="onPanelExpanded()">
          <div rgi-rx-expansion-panel-label>{{reportArea.description}}</div>
      </div>
      <ng-container *ngIf="(state$ | async) as state" style="padding:0;">
          <ng-container *ngFor="let template of state.reports | sortReport: 'areaId': 'description'">
              <ng-container *ngIf="template.areaId == reportArea.id">
                <analytics-report-item class="row analytics-alternate" [template]="template" [isAdminFunctionEnabled]="isAdminFunctionEnabled"
                                 (openDashboard)="openDashboard($event)"
                                 (editReport)="editReport($event)"
                                 (copyReportModal)="copyReportModal($event)"
                                 (deleteReportModal)="deleteReportModal($event)">
                </analytics-report-item>
              </ng-container>
          </ng-container>
      </ng-container>
  </rgi-rx-expansion-panel>
</div>

<button class="slide analytics-buttons" (click)="openQlik()">
    <div class="icon-left">
        <span class="rgi-ui-icon-resources qlikIcon"></span>
    </div>
    <div class="text-left">
        {{'_QLIK_LINK_BTN_' | translate}}
    </div>
    <div class="icon-right">
        <span class="rgi-ui-btn rgi-ui-icon-right-arrow analytics-arrow"></span>
    </div>
</button>


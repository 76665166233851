import {RgiFormField} from './rgi-form-field';

export class PncPsalesForm {
  title?: string;
  fields: RgiFormField[];


  constructor(fields: RgiFormField[] | RgiFormField, title?: string) {
    this.title = title;
    if (Array.isArray(fields)) {
      this.fields = fields;
    } else {
      this.fields = [fields];
    }
  }
}

<section>
    <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="closeModal('exit')"></rgi-rx-panel-header>
        <rgi-rx-panel-footer>
                    <button rgi-rx-button class="rgi-ui-btn rgi-ui-btn-primary"
                        (click)="closeModal('exit')">{{'_CLAIMS_._CLOSE' |
                        translate}}</button>
                    <button rgi-rx-button class="rgi-ui-btn rgi-ui-btn-primary"
                        (click)="closeModal('confirm')">{{'_CLAIMS_._BUTTONS_._SAVE' |
                        translate}}</button>
        </rgi-rx-panel-footer>
        <ng-container>
        <form [formGroup]="fivaCtpForm" data-qa="dialogue-handler-search-form">

            <div class="container-fiva">

                <div class="row">
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._CLAIM_NUMBER'| translate }}
                            </label>
                            <input rgiRxInput class="form-control" formControlName="cclaimnumber" id="cclaimnumber"
                                type="text" name="cclaimnumber"
                                attr.data-qa="dialogue-handler-search-external-key-input">
                        </rgi-rx-form-field>
                    </div>
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._POLICY_NUMBER'| translate }}
                            </label>
                            <input rgiRxInput class="form-control" formControlName="cnumpol" id="cnumpol" type="text"
                                name="cnumpol" attr.data-qa="dialogue-handler-search-external-key-input">
                        </rgi-rx-form-field>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._PLATE_NUMBER'| translate }}
                            </label>
                            <input rgiRxInput class="form-control" formControlName="cplate" id="cplate" type="text"
                                name="cplate" attr.data-qa="dialogue-handler-search-external-key-input">
                        </rgi-rx-form-field>
                    </div>
                    <div class="col-md-6">
                        <rgi-rx-form-field>

                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._COUNTERPARTY_PLATE_NUMBER'| translate }}
                            </label>
                            <select rgiRxNativeSelect class="core-select form-control" formControlName="cplatecntparty"
                                id="cplatecntparty" attr.data-qa="damage-category-panel-coverture-input" required default="ctpPlates[0]">
                                <option *ngFor="let targaCtp of ctpPlates" value={{targaCtp}}>
                                    {{targaCtp}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>

                </div>

                <div class="row">

                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel rgiRxLabel>{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}</label>
                            <rgi-rx-date-picker [openOnClick]="false">
                                <input rgiRxInput [rgiRxDateTimeInput]="doccurrencePicker"
                                    data-qa="dialogue-handler-search-creation-date-start-input"
                                    formControlName="doccurrence">
                            </rgi-rx-date-picker>
                            <rgi-rx-date-time [pickerType]="'calendar'" #doccurrencePicker></rgi-rx-date-time>
                        </rgi-rx-form-field>
                    </div>

                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._TYPE_OF_DAMAGE'| translate }}
                            </label>
                            <select rgiRxNativeSelect class="core-select form-control" formControlName="etypedamage"
                                id="etypedamage" attr.data-qa="damage-category-panel-coverture-input" required>
                              <option value=""></option>


                                <option *ngFor="let typeDemage of fivaTypeDamages" value={{typeDemage.codice}}>
                                    {{typeDemage.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._REPORT'| translate }}
                            </label>
                            <select rgiRxNativeSelect class="core-select form-control" formControlName="ereport"
                                id="ereport" attr.data-qa="damage-category-panel-coverture-input" required [(ngModel)] = 'fivaReports[0].codice' >


                                <option *ngFor="let report of fivaReports" value={{report.codice}}>
                                    {{report.descrizione}}</option>

                            </select>
                        </rgi-rx-form-field>
                    </div>
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._PROCEDURE_NUMBER'| translate }}
                            </label>
                            <input rgiRxInput class="form-control" formControlName="cprocedurenumber"
                                id="cprocedurenumber" type="text" name="cprocedurenumber"
                                attr.data-qa="dialogue-handler-search-external-key-input">
                        </rgi-rx-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="label-core"
                                attr.data-qa="dialogue-handler-search-external-key-label">
                                {{'_CLAIMS_._INSTANCE'| translate }}
                            </label>
                            <input rgiRxInput class="form-control" formControlName="cinstance" id="cinstance"
                                type="text" name="cinstance" attr.data-qa="dialogue-handler-search-external-key-input">
                        </rgi-rx-form-field>
                    </div>
                </div>
            </div>
        </form>
        </ng-container>
        <br>
    </rgi-rx-panel>
    <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error" >
      <span class="rgifont rgi-exclamation-triangle"></span>
      <span>{{errorMessage}}</span>
    </span>
</section>

import {ErrorInterceptor} from './error.interceptor';
import {AuthInterceptor} from './auth.interceptor';
import {LangInterceptor} from './lang.interceptor';
import {CacheInterceptor} from './cache.interceptor';
import {PartiesInterceptor} from './parties.interceptor';
import {MIC_HTTP_INTERCEPTOR} from '../http/mic-http-constants';
import {HttpPendingRequestInterceptor} from '@rgi/rx/http';

export const micHttpInterceptorProviders = [
  { provide: MIC_HTTP_INTERCEPTOR, useClass: ErrorInterceptor, multi: true},
  { provide: MIC_HTTP_INTERCEPTOR, useClass: AuthInterceptor, multi: true },
  { provide: MIC_HTTP_INTERCEPTOR, useClass: LangInterceptor, multi: true },
  { provide: MIC_HTTP_INTERCEPTOR, useClass: LangInterceptor, multi: true },
  { provide: MIC_HTTP_INTERCEPTOR, useClass: LangInterceptor, multi: true },
  { provide: MIC_HTTP_INTERCEPTOR, useClass: CacheInterceptor, multi: true },
  { provide: MIC_HTTP_INTERCEPTOR, useClass: PartiesInterceptor, multi: true },
  { provide: MIC_HTTP_INTERCEPTOR, useClass: HttpPendingRequestInterceptor, multi: true }
];

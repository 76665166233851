<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title" style="max-width: 100%">{{ '_CLAIMS_._INVOICE_DATA' | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
            </h3>
        </div>

        <form [formGroup]="updateInvoiceForm" data-qa="invoice-update-form">

            <div class="col-md-12 col-sm-12 col-xs-12 panel-heading wf-party-header">
                <span class="rgifont rgi-user"></span>
                    {{'_CLAIMS_._EXPERT' | translate}}  
                <span class="bold">{{invoice.invoiceExpert.name}} {{invoice.invoiceExpert.surname}} {{invoice.invoiceExpert.title}}</span>
                -  {{'_CLAIMS_._INVOICE' | translate}}
                <span class="bold">{{invoice.invoiceNumber}} {{invoice.invoiceDate | date:'dd/MM/yyyy' }}</span>
                -  {{'_CLAIMS_._SAP_CODE' | translate}}
                <span class="bold">{{invoice.sapCode}}</span>
            </div>

            <div class="row padding-top-40">

                <div class="container-fluid table-white">

                    <br>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-taxableAmount-label">{{ '_CLAIMS_._TAXABLE_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="taxableAmount" id="taxableAmount" type="text" name="taxableAmount" attr.data-qa="invoice-update-taxableAmount-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-notTaxableAmount-label">{{ '_CLAIMS_._NOT_TAXABLE_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="notTaxableAmount" id="notTaxableAmount" type="text" name="notTaxableAmount" attr.data-qa="invoice-update-notTaxableAmount-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-amountHoldTax-label">{{ '_CLAIMS_._AMOUNT_HOLD_TAX' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="amountHoldTax" id="amountHoldTax" type="text" name="amountHoldTax" attr.data-qa="invoice-update-amountHoldTax-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-cashPrevidAmount-label">{{ '_CLAIMS_._CASH_PREVIDENTIAL_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="cashPrevidAmount" id="cashPrevidAmount" type="text" name="cashPrevidAmount" attr.data-qa="invoice-update-cashPrevidAmount-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-vatAmount-label">{{ '_CLAIMS_._VAT_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="vatAmount" id="vatAmount" type="text" name="vatAmount" attr.data-qa="invoice-update-vatAmount-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-stampDutyAmount-label">{{ '_CLAIMS_._STAMP_DUTY_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="stampDutyAmount" id="stampDutyAmount" type="text" name="stampDutyAmount" attr.data-qa="invoice-update-stampDutyAmount-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-invoiceAmount-label">{{ '_CLAIMS_._INVOICE_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="invoiceAmount" id="invoiceAmount" type="text" name="invoiceAmount" attr.data-qa="invoice-update-invoiceAmount-input"
                        [ngClass]="(updateInvoiceForm.controls.invoiceAmount.value=='0')?'ng-invalid':''">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-amountToBePayd-label">{{ '_CLAIMS_._AMOUNT_TO_BE_PAYD' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="amountToBePayd" id="amountToBePayd" type="text" name="amountToBePayd" attr.data-qa="invoice-update-amountToBePayd-input"
                        [ngClass]="(updateInvoiceForm.controls.amountToBePayd.value=='0')?'ng-invalid':''">
                    </div>

                    <!-- paymentAmount (importo pagamento) era impostato e hanno chiesto di toglierlo, 
                    per non rimuovere tutta la gestione ormai fatta sul component.ts, rendo hidden il campo 
                    e lascio la gestione del salvataggio nel caso servisse  -->
                    <div class="col-sm-6" [hidden]="true">
                        <label class="label-core" attr.data-qa="invoiceupdate-paymentAmount-label">{{ '_CLAIMS_._PAYMENT_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="paymentAmount" id="paymentAmount" type="text" name="paymentAmount" attr.data-qa="invoice-update-paymentAmount-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-inpsContribAmount-label">{{ '_CLAIMS_._INPS_CONTRIB_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="inpsContribAmount" id="inpsContribAmount" type="text" name="inpsContribAmount" attr.data-qa="invoice-update-inpsContribAmount-input">
                    </div>

                    <div class="col-sm-6">
                        <label class="label-core" attr.data-qa="invoiceupdate-nRoundingCMount-label">{{ '_CLAIMS_._ROUNDING_AMOUNT' | translate }}</label>
                        <input class="col-sm-6 form-control" formControlName="nRoundingCMount" id="nRoundingCMount" type="text" name="nRoundingCMount" attr.data-qa="invoice-update-nRoundingCMount-input">
                    </div>

                </div>

            </div>

            <br>
            
            <!-- new accordion start -->
            <div class="container-fluid table-white" [ngClass]="{'row-bgcolor-grey': !expanded, 'row-bgcolor-blue':expanded}">
                <div class="row">
                    <div class="col-sm-2" (click)="expanded = !expanded">
                        <span class="rgifont iconDTleft rgi-plus"
                        [ngClass]="{'rgi-plus icon-accordion-open': !expanded, 'rgi-minus icon-accordion-close':expanded}"></span>
                        <span class="bold">{{ '_CLAIMS_._PERCENTAGES' | translate }}</span>
                    </div>
                </div>
            </div>
    
            <div class="row padding-top-10">
                <div class="col-xs-12 padding-zero accordion-claims-margin-bottom" [hidden]="!expanded">
        
                    <div class="container-fluid table-white">

                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-percWithHoldTax-label">{{ '_CLAIMS_._PERC_WITH_HOLD_TAX' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="percWithHoldTax" id="percWithHoldTax" type="text" name="percWithHoldTax" attr.data-qa="invoice-update-percWithHoldTax-input">
                        </div>

                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-vatRate-label">{{ '_CLAIMS_._VAT_RATE' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="vatRate" id="vatRate" type="text" name="vatRate" attr.data-qa="invoice-update-vatRate-input">
                        </div>

                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-percPrevFund1-label">{{ '_CLAIMS_._PERC_PREV_FUND_1' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="percPrevFund1" id="percPrevFund1" type="text" name="percPrevFund1" attr.data-qa="invoice-update-percPrevFund1-input">
                        </div>

                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-percPrevFund2-label">{{ '_CLAIMS_._PERC_PREV_FUND_2' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="percPrevFund2" id="percPrevFund2" type="text" name="percPrevFund2" attr.data-qa="invoice-update-percPrevFund2-input">
                        </div>

                    </div>
                </div>
            </div>
            <!-- new accordion end -->

            <br>

            <div class="container-fluid table-white row-bgcolor-grey">
                <div class="row padding-left-15">
                    <div class="col-sm-2">
                        <span class="bold">{{ '_CLAIMS_._INVOICE_DETAILS' | translate }}</span>
                    </div>
                </div>
            </div>
            
            <div class="row">

                <div class="container-fluid table-white" *ngFor="let invoiceDetails of invoice.invoiceDetailsList; let even = even; let odd = odd; let invoiceDetailsIndex = index"
                attr.data-qa="invoice-details-container-cicle">

                        <br>

                        <!-- TODO - for future proforma management (more details for itself invoice) detailsTotAmountUnit e detailsTaxableAmount manage in cicle
                            with index, verify also .ts this.formBuilder.group -->
                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-totAmountUnit{{invoiceDetailsIndex}}-label">{{ '_CLAIMS_._TOT_AMOUNT_UNIT' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="totAmountUnitDetails" id="totAmountUnitDetails" type="text" name="totAmountUnitDetails" attr.data-qa="invoice-update-totAmountUnit-input"
                            [ngClass]="(updateInvoiceForm.controls.totAmountUnitDetails.value=='0')?'ng-invalid':''">
                        </div>

                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-taxableAmount{{invoiceDetailsIndex}}-label">{{ '_CLAIMS_._TAXABLE_AMOUNT' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="taxableAmountDetails" id="taxableAmountDetails" type="text" name="taxableAmountDetails" attr.data-qa="invoice-update-taxableAmount-input">
                        </div>

                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-notTaxableAmount{{invoiceDetailsIndex}}-label">{{ '_CLAIMS_._NOT_TAXABLE_AMOUNT' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="notTaxableAmountDetails" id="notTaxableAmountDetails" type="text" name="notTaxableAmountDetails" attr.data-qa="invoice-update-notTaxableAmount-input">
                        </div>

                        <div class="col-sm-6">
                            <label class="label-core" attr.data-qa="invoiceupdate-claimNumber{{invoiceDetailsIndex}}-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="claimNumberDetails" id="claimNumberDetails" type="text" name="claimNumberDetails" attr.data-qa="invoice-update-claimNumber-input"
                            [readonly]="true">
                        </div>

                </div>
            </div>

            <br>

            <span *ngIf="validationMessages && validationMessages.length > 0" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-update-error">
                <span class="rgifont rgi-exclamation-triangle"></span>
                <span *ngFor="let message of validationMessages; let even = even; let odd = odd; let invoiceDetailsIndex = index">
                    {{message | translate}}
                    <br>
                </span>
            </span>
            
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button type="submit" class="btn btn-warning pull-right" attr.data-qa="invoice-update-find-btn"
                        id="invoice-update-submit" (click)="confirmInvoice()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                </div>
            </div>
            
        </form>
    </div>
</div>

import {NgModule} from '@angular/core';
import {AjsConfigurationLifePostsales} from '../portal-integration/ajs-configuration-life-postsales';
import {CommonModule} from '@angular/common';
import {LifePostsalesCardModule} from './life-postsales-card/life-postsales-card.module';
import {LifePostsalesSessionModule} from './life-postsales-session/life-postsales-session.module';
import {LifeDetailCardModule} from './life-detail-card/life-detail.module';
import {FinancialSearchCardModule} from './financial-search-card/financial-search-card.module';
import {AnagClaimReportModule} from './anag-claim-report/anag-claim-report.module';
import {AngularCommunicationPostsales} from '../portal-integration/assets/angular-communication-postsales';
import {CurrencyCacheService, LpcCurrencyCache} from '../services/currency-cache.service';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {LPC_TRANSLATIONS, LPC_TRANSLATIONS_PPEVO_QUEST_LIFE} from '../i18n/translations';
import {TranslationWrapperService} from '../i18n/translation-wrapper.service';
import { SurveyEditStateLifeOperations } from '../survey/survey-edit-state-operations-life.service';
import { SurveyEditStateOperations } from '@rgi/ng-passpropro-survey';
import { SurveyVersionService } from '@rgi/questionnaires-manager';
import { SurveyVersionLifeService } from '../survey/survey-version.service';

@NgModule({
  imports: [
    CommonModule,
    LifePostsalesCardModule,
    LifePostsalesSessionModule,
    LifeDetailCardModule,
    FinancialSearchCardModule,
    AnagClaimReportModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(LPC_TRANSLATIONS),
    RgiRxI18nModule.forRoot(LPC_TRANSLATIONS_PPEVO_QUEST_LIFE)
  ],
  providers: [
    {provide: LpcCurrencyCache, useClass : CurrencyCacheService},
    TranslationWrapperService,
    SurveyEditStateLifeOperations,
    SurveyVersionLifeService,
    {provide: SurveyEditStateOperations, useClass: SurveyEditStateLifeOperations},
    {provide: SurveyVersionService, useClass: SurveyVersionLifeService}
  ]
})
export class LifePostsalesModule {
  constructor() {
    AngularCommunicationPostsales();
    AjsConfigurationLifePostsales();
  }
}


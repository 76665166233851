<form [formGroup]="customForm" >
  <div formArrayName="fromAnag">
    <div *ngFor="let arrayEl of fromAnag.controls; let i = index">
    <lic-select-subject-ben
      [customForm]="arrayEl"
      [key]="i" (delete)="delete($event)"
      [(submitted)]="submitted"
      (eventPropagation)="openAnag($event)"
      [cat]="cat"
      [_REG41Property]="_REG41Property"
      (subjetAnagEditedEvent)="editAnag($event)"
      (relatedSubjectDeletion)="deleteReletedSubject($event)">
    </lic-select-subject-ben>

    </div>

  </div>
  <!-- <pnc-checkbox
    *ngIf="irrevocableBeneficiaryAllowed"
    [attr.data-qa]="'benef-irrev-'+cat"
    [formControlName]="'irrevocable'"
    class="check"
    [checked]="irrevocable"
  >
    <span style="font-size: small;" translate>{{irrevocableLabel}}</span>
    <br>
    <br>
  </pnc-checkbox> -->
  <div class="row">
    <rgi-rx-form-field *ngIf="irrevocableBeneficiaryAllowed">
      <label rgiRxLabel>{{ 'lic_Irrevocable_beneficiary' | translate }}</label>
      <input type="checkbox" rgiRxInput [formControlName]="'irrevocable'" [checked]="irrevocable" [attr.data-qa]="'benef-irrev-'+cat">
    </rgi-rx-form-field>
  </div>
</form>

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { restEndPoint } from '../dialogue-handler-configuration/dialogue-handler.endpoint';
import { DetailObjectToList, DetailObjectToNew, DialogueHandlerSearch, ListObjectToSummary, ResponseFromSearchState } from '../dialogue-handler-configuration/dialogue-handler.model';
// import { DialogueFunction, DialogueObject, Etype, WrapperDialogueFunction, WrapperDialogueObject, WrapperDialogueCompany, DialogueCtpCompany, DialogueInternalCompany } from '../dto/dialogue-fill-search';
import { DialogueFunction, DialogueObject, Etype, FormSearch, WrapperDialogueCompany, WrapperDialogueFunction, WrapperDialogueObject } from '../dialogue-handler-configuration/dialogue-handler-search.model';
import { DHOutcome, DialogueHandlerListResponse } from '../dialogue-handler-configuration/dialogue-handler-list.model';
import { DialogueHandlerIntegrationService } from '../dialogue-handler-configuration/dialogue-handler-integration.service';
import { ActiveRoute } from '@rgi/rx/router';
import { DialogueRoleList } from '../dialogue-handler-configuration/new-dialogue.model';
import { DialogueSummaryObject } from '../dialogue-handler-configuration/dh-summary.model';
import { SummaryViewConfiguration } from '../dialogue-summary/dialogue-summary.component';

@Injectable({
  providedIn: "root",
})
export class DialogueHandlerSearchService {
  private baseApiUrlV2: string;

  constructor(
    private httpClient: HttpClient,
    @Inject("environment") environment: any,
    private integrationService: DialogueHandlerIntegrationService
  ) {
    this.baseApiUrlV2 =
      environment.api.portal.host + environment.api.portal.path + "/v2";
  }

  updateFormHome(formSearch: FormSearch, isValidForm: boolean, st: Observable<DialogueHandlerSearch>): Observable<DialogueHandlerSearch> {
    return st.pipe(
      map((state) => {
        state.formSearch = formSearch;
        // state.isValidForm = isValidForm;
        return state;
      })
    );
  }

  initStateObject(st: Observable<DialogueHandlerSearch>): Observable<DialogueHandlerSearch> {
    return st.pipe(
      switchMap((state) => {
        return forkJoin({
          firstResponse: this.httpClient.get<WrapperDialogueFunction>(this.baseApiUrlV2 + restEndPoint.fillInSearchFunMes),
          secondResponse: this.httpClient.get<WrapperDialogueObject>(this.baseApiUrlV2 + restEndPoint.fillInSearchOggetto),
          thirdResponse: this.httpClient.get<WrapperDialogueCompany>(this.baseApiUrlV2 + restEndPoint.fillInSearchCompany),
        }).pipe(
          map((apiResponses) => {
            state.searchWrapperObject.dialogueFunctionResponse = this.processFirstResponse(apiResponses.firstResponse.response);
            state.searchWrapperObject.dialogueObjectResponse = this.processSecondResponse(apiResponses.secondResponse);
            state.searchWrapperObject.dialogueCompResponse = this.processThirdResponse(apiResponses.thirdResponse);
            console.log("initStateObject=================> ", state);
            return state;
          })
        );
      })
    );
  }

  private processFirstResponse(apiResponse: any): DialogueFunction[] {
    if (!apiResponse) {
      console.error("La risposta è vuota.");
      return [];
    }
    return apiResponse.map(({ functionCode, functionDescription,functionStatusCode,statusDescription, dialogueMsgData }) => ({
      functionCode,
      functionDescription,
      functionStatusCode,
      statusDescription,
      dialogueMsgData: dialogueMsgData ? dialogueMsgData.map(({ dialogueMsgId, dialogueMsgCode, dialogueMsgDescription }) => ({
        dialogueMsgId,
        dialogueMsgCode,
        dialogueMsgDescription
      })) : []
    }));
  }


  private processSecondResponse(apiResponse: WrapperDialogueObject): DialogueObject[] {
    if (!apiResponse || !apiResponse.response) {
      console.error("La risposta è vuota o non è nel formato atteso.");
      return [];
    }
    const { response } = apiResponse;
    // Mappiamo ciascun oggetto DialogueObject nell'array in un nuovo array di DialogueObject
    console.log(response)
    return response.map(item => ({
      functionCode: item.functionCode,
      dialogueSubject: item.dialogueSubject ? item.dialogueSubject.map(({ code, description }) => ({
        code,
        description
      })) : []
    }));
  }






  private processThirdResponse(apiResponse: WrapperDialogueCompany): WrapperDialogueCompany {
    if (!apiResponse || !apiResponse.requestOutcome) {
      console.error("L'API Response non è nel formato atteso o è vuota:", apiResponse);
      return null;
    }
    // Estrazione dei dati relativi alle aziende controparti e interne dal wrapper
    let { counterpartCompany = [], internalCompany = [] } = apiResponse;
    // Mapping di DialogueCtpCompany e DialogueInternalCompany
    counterpartCompany = counterpartCompany.filter((item) => item.code != null);
    const dialogueCtpCompany: Etype[] = counterpartCompany.map(item => ({
      code: item.code,
      description: item.description
    }));
    const dialogueInternalCompany: Etype[] = internalCompany.map(item => ({
      code: item.code,
      description: item.description
    }));
    // Restituisco un nuovo oggetto WrapperDialogueCompany con i dati mappati
    return {
      requestOutcome: apiResponse.requestOutcome,
      counterpartCompany: dialogueCtpCompany,
      internalCompany: dialogueInternalCompany
    };
  }



  initList(responseList: DialogueHandlerListResponse[], st: Observable<ResponseFromSearchState>): Observable<any> {
    return st.pipe(
      map((state) => {
        state.responseFromSearchList = responseList;
        return state;
      })
    );
  }

  getDetailSearchResult(st: Observable<DialogueHandlerSearch>, activeRouteData: ActiveRoute, viewCond: string) {
    return st.pipe(
      mergeMap((state) => {
        console.log("valore dello state quando viene effettuata la search", state)
        let queryString = "";
        if (state.formSearch.function) { queryString += "&functionCode=" + state.formSearch.function; }
        if (state.formSearch.message) { queryString += "&statusCode=" + state.formSearch.message; }
        if (state.formSearch.object) { queryString += "&dialogueSubjectCode=" + state.formSearch.object; }
        if (state.formSearch.externalKey) { queryString += "&externalKey=" + state.formSearch.externalKey; }
        if (state.formSearch.damageMatch) { queryString += "&externalIdentifyDamageParty=" + state.formSearch.damageMatch; }
        if (state.formSearch.taxIdCode) { queryString += "&tinVatDamagedParty=" + state.formSearch.taxIdCode; }
        if (state.formSearch.claim) { queryString += "&claimNumberPass=" + state.formSearch.claim; }
        if (state.formSearch.complaint) { queryString += "&potClaimNumberPass=" + state.formSearch.complaint; }
        if (state.formSearch.association) { queryString += "&associatedNot=" + state.formSearch.association; }
        if (state.formSearch.company) { queryString += '&insuranceCodeUs=' + state.formSearch.company; }
        if (state.formSearch.ctpCompany) { queryString += "&insuranceCodeOther=" + state.formSearch.ctpCompany; }
        if (state.formSearch.carLicensePlate) { queryString += "&licensePlateUS=" + state.formSearch.carLicensePlate; }
        if (state.formSearch.ctpCarLicensePlate) { queryString += "&licensePlateOther=" + state.formSearch.ctpCarLicensePlate; }
        if (state.formSearch.creationDateStart) { queryString += "&dateStartFrom=" + state.formSearch.creationDateStart.getTime().toString();}
        if (state.formSearch.creationDateEnd) { queryString += "&dateStartTo=" + state.formSearch.creationDateEnd.getTime(); }
        if (state.formSearch.elaborationDateStart) { queryString += "&dateElabFrom=" + state.formSearch.elaborationDateStart.getTime(); }
        if (state.formSearch.elaborationDateEnd) { queryString += "&dateElabTo=" + state.formSearch.elaborationDateEnd.getTime(); }
        if (state.formSearch.expiryDateStart) { queryString += "&dateExpiryFrom=" + state.formSearch.expiryDateStart.getTime(); }
        if (state.formSearch.expiryDateEnd) { queryString += "&dateExpiryTo=" + state.formSearch.expiryDateEnd.getTime(); }
        if (state.formSearch.claimDateStart) { queryString += "&dateLossFrom=" + state.formSearch.claimDateStart.getTime(); }
        if (state.formSearch.claimDateEnd) { queryString += "&dateLossTo=" + state.formSearch.claimDateEnd.getTime(); }
        if (state.formSearch.activeTask) { queryString += "&activeTask=" + state.formSearch.activeTask; }
        if (state.formSearch.errorMessage) { queryString += "&errorMessages=" + state.formSearch.errorMessage; }
        if (state.formSearch.similarFlag) { queryString += "&similarFlag=" + state.formSearch.similarFlag; }

        if (queryString.length > 1) { queryString = "?" + queryString.slice(1); }
        const responseDetailListSearch =
          this.httpClient.get<DHOutcome>(this.baseApiUrlV2 + restEndPoint.goToSearchResults + queryString);
        return combineLatest([responseDetailListSearch, of(state)]);
      }),
      mergeMap(([responseList, state]) => {
        if (responseList && responseList.response) {
          if (responseList.response.length > 1) {
            const objectToList: DetailObjectToList = {
              formSearch: state.formSearch,
              responseFromSearchList: responseList.response,
              searchWrapperObject: state.searchWrapperObject,
              newWrapperObject: null,   //aggiunto per uniformare

              viewConfig: viewCond
            }
            this.integrationService.goToDialogueHandlerList(objectToList, activeRouteData);
          }
          else if (responseList.response.length == 1) {
            return this.goToSummary(st, responseList.response[0], activeRouteData);
          }
          else {
            state.errorMessage = "Nothing found";
          }
        }
        else {
          console.error('Risposta non valida dalla chiamata API o non è un array');
        }
        return of(state)
      }),
    )
  }

  goToSummary(st: Observable<DialogueHandlerSearch>, list: DialogueHandlerListResponse, activeRouteData: ActiveRoute,) {
    return st.pipe(
      mergeMap((state) => {
        let queryString = '';
        queryString += "?idClnotifcard=" + list.idClnotifcard;
        const responseSummary =
          this.httpClient.get<DialogueSummaryObject>(this.baseApiUrlV2 + restEndPoint.summary + queryString);
        return combineLatest([responseSummary, of(state)]);
      }),
      mergeMap(([responseSummary, state]) => {
        if (responseSummary) {
          const objectToSummary: ListObjectToSummary = {
            searchWrapperObject: state.searchWrapperObject,
            dialogueSummaryObject: responseSummary,
            responseFromSearchList: list,
            viewConfig: SummaryViewConfiguration.SUMMARY_VIEW_CONF,
          }
          this.integrationService.goToDialogueHandlerSummary(objectToSummary, activeRouteData);
        }
        else {
          console.error('Risposta non valida dalla chiamata API o non è un array');
        }

        return of(state)
      })
    )
  }

  getNewDialogueData(st: Observable<DialogueHandlerSearch>, activeRouteData: ActiveRoute, viewCond: string) {
    return st.pipe(
      mergeMap((state) => {
        const responseRoleList =
          this.httpClient.get<DialogueRoleList>(this.baseApiUrlV2 + restEndPoint.roleListActiveRoleCompany);
        return combineLatest([responseRoleList, of(state)]);
      }),
      mergeMap(([responseRoleList, state]) => {
        if (responseRoleList && responseRoleList.data && Array.isArray(responseRoleList.data)) {
          const objectToNew: DetailObjectToNew = {
            searchWrapperObject: state.searchWrapperObject,
            // wrapperNewDialogueObject: {data: responseRoleList.data, newDialogueRequest: null},
            newWrapperObject: {
              responseRoleList: responseRoleList, responseActCausOutcome: null,
              responseActDoc: null, responseFunParam: null, responseFunctionDoc: null
            },

            viewConfig: viewCond
          }
          this.integrationService.openNewDialogue(objectToNew, activeRouteData);
        }
        else {
          console.error('Risposta non valida dalla chiamata API o non è un array');
        }

        return of(state)
      })
    )
  }
}






<!--<div class="container-fluid">
  <re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels"></re-issue-stepper>

  <div class="container-fluid re-issue-card-styles" *ngIf="stateManager.getState$() | async as movementsState">
    <table mat-table [dataSource]="movements" class="mb-2">
      <ng-container matColumnDef="checkBox">
        <th width="5%" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
                    <span type="checkbox" class="btn btn-checkbox checkbox-unit nmf-unit-box-checkbox"
                          (click)="selectMovement(element)">
                        <span class="rgifont"
                              [ngClass]="{'rgi-check': selectedMovement?.objectId == element?.objectId }">
                        </span>
                    </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          {{'Description' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element?.description}} </td>
      </ng-container>

      <ng-container matColumnDef="effectDate">
        <th mat-header-cell *matHeaderCellDef> {{'Effect Date' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element?.effectiveDate | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="covertureDate">
        <th mat-header-cell *matHeaderCellDef> {{'End Policy Date' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element?.expirationDate | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="outcome">
        <th mat-header-cell *matHeaderCellDef> {{'RE_ISSUE.OUTCOME' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{titlesMap[element.objectId]}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="titleTableColumns;"></tr>
      <tr mat-row *matRowDef="let element; columns: titleTableColumns;"></tr>

    </table>
  </div>

&lt;!&ndash;  <div class="col-xs-12 error-message" *ngIf="errorMessages.length > 0">
    <div id="error-container" class="error-container">
      <pnc-form-message type="error" [messages]="errorMessages" data-qa="reprint-movements-error-messages"
                        objectType="complex"></pnc-form-message>
    </div>
  </div>&ndash;&gt;

  <div class="col-xs-12">
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button class="btn btn-secondary pull-right" (click)="exit()">{{ 'RE_ISSUE.EXIT' | translate | uppercase
          }}</button>
      </div>
      <div class="btn-group">
        <button class="btn btn-primary pull-right" type="submit" (click)="reprintMovement()">{{ 'RE_ISSUE.NEXT' |
          translate | uppercase }}</button>
      </div>
    </div>
  </div>
</div>-->

import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@rgi/digital-claims-common-angular';
import { Invoice } from '../dto/invoice';
import { InvoiceDetails } from '../dto/invoice-details';
import { InvoiceFileUpload } from '../dto/invoice-file-upload';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-upload',
  templateUrl: './invoice-upload.component.html',
  styleUrls: ['./invoice-upload.component.scss']
})
export class InvoiceUploadComponent implements OnInit {
  @Input() invoice: Invoice;
  validationMessages: string[];

  private uploadedFile: any;

  uploadForm: UntypedFormGroup;
  fileLoaded: boolean;
  fileName: string;
  pdfType: boolean = false;
  hasError: boolean = false;
  errorMessage: string = null;
  errorMessages = [];
  fileInput: any;

  constructor(
    private invoiceService: InvoiceService,
    formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    public translateService: RgiRxTranslationService,
    private alertService: AlertService,
  ) {
    this.uploadForm = formBuilder.group(
      {
        file: []
      }
    );
  }

  ngOnInit() {
  }

  fileChange(event) {
    const file = event.target.files[0];

    this.clearError();

    this.setFile(file);

    event.stopPropagation();
    return false;
  }

  unloadFile(event) {
    this.uploadedFile = null;
    this.fileLoaded = false;
    this.fileName = null;
  }

  uploadFile(event) {

    this.clearError();
    if (this.uploadedFile) {

      const file = new InvoiceFileUpload();
      const that = this;
      file.file = this.uploadedFile;
      file.fileName = this.fileName;

      // if (this.formalCtrl()) {

      this.invoiceService.saveInvoiceProforma(this.invoice).subscribe(
        (response: Response) => {
          this.activeModal.close(response);
          this.translateService.translate('_CLAIMS_._MESSAGE_._UPDATE_SUCCESSFUL').subscribe(res=> {
            this.alertService.translatedMessage(res);
          })
        },
        (error: Error) => {
          this.translateService.translate('_CLAIMS_._UPLOAD_ERROR').subscribe(res=> {
            this.signalError(res + error.message);
          })
        }
      );
      // }

    } else {
      this.translateService.translate('_CLAIMS_._MESSAGE_._YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD').subscribe(res=> {
        this.signalError(res);
      })
      
      
    }

    event.stopPropagation();
    return false;
  }


  closeModal(event) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  fileDropped(evt: DragEvent) {
    this.clearError();
    const file = evt.dataTransfer.files[0];
    this.setFile(file);
  }

  setFile(file: any) {

    this.fileInput = file;
    this.fileName = file.name;
    const fileExtension = this.fileName.split('.').pop();
    if (fileExtension && fileExtension.toUpperCase() !== 'CSV') {
      this.translateService.translate('_CLAIMS_._MESSAGE_._INSERT_A_CSV_FILE').subscribe(res=> {
        this.signalError(res);
      })
      return false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.uploadedFile = reader.result;
      this.fileLoaded = true;

      // populate invoice object
      let invoiceDet: InvoiceDetails;
      this.invoice.invoiceDetailsList = [];
      const lines: string[] = reader.result.toString().split('\r\n');
      let cicleNumber = 1;
      this.validationMessages = [];

      const firstFields: string[] = lines[0].split(';');

      const hasColunm1: boolean = firstFields[1] !== undefined && firstFields[1].length > 0;
      const hasColunm2: boolean = hasColunm1 && firstFields[2] !== undefined && firstFields[2].length > 0;
      const hasColunm3: boolean = hasColunm2 && firstFields[3] !== undefined && firstFields[3].length > 0;

      const dividedTotAmountUnit: number = this.invoiceService.round(this.invoice.invoiceAmount / lines.length, 2);
      let settedTotAmountUnit = 0;
      let settedCalculatedTaxableAmount = 0;
      let settedCalculatedNotTaxableAmount = 0;


      for (const line of lines) {

        const fields: string[] = line.split(';');

        invoiceDet = new InvoiceDetails();

        // existence controls
        if (fields[0] !== '') {
          invoiceDet.claimNumber = fields[0];
        } else {
          this.translateService.translate('_CLAIMS_._MESSAGE_._MISSING_CLAIM_NUMBER_AT_LINE').subscribe(res=> {
            this.validationMessages.push(res + cicleNumber);
          })
        }

        if (fields[1] !== undefined && fields[1].length > 0) {
          if (hasColunm1) {
            invoiceDet.totAmountUnit = this.invoiceService.formatNumber(fields[1]);
          } else {
            this.translateService.translate('_CLAIMS_._INCONSISTENT_ROW_AT_LINE').subscribe(res=> {
              this.validationMessages.push(res + cicleNumber)}
            );

          }
        } else {
          if (hasColunm1) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._MISSING_TOTAL_UNIT_AMOUNT_AT_LINE').subscribe(res=> {
              this.validationMessages.push(res + cicleNumber)}
            );
          } else {
            if (this.invoice.invoiceAmount - settedTotAmountUnit >= dividedTotAmountUnit) {
              invoiceDet.totAmountUnit = dividedTotAmountUnit;
              settedTotAmountUnit += dividedTotAmountUnit;

              if (cicleNumber === lines.length) {
                const scarto = this.invoice.invoiceAmount - settedTotAmountUnit;
                invoiceDet.totAmountUnit += scarto;
              }
            } else {
              invoiceDet.totAmountUnit = this.invoiceService.round(this.invoice.invoiceAmount - settedTotAmountUnit, 2);
              settedTotAmountUnit += dividedTotAmountUnit;
            }
          }
        }

        if (fields[2] !== undefined && fields[2].length > 0) {
          if (hasColunm2) {
            invoiceDet.taxableAmount = this.invoiceService.formatNumber(fields[2]);
          } else {
            this.translateService.translate('_CLAIMS_._INCONSISTENT_ROW_AT_LINE').subscribe(res=> {
              this.validationMessages.push(res + cicleNumber)}
            );
            
          }
        } else {
          if (hasColunm2) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._MISSING_TAXABLE_AMOUNT_AT_LINE').subscribe(res=> {
              this.validationMessages.push(res + cicleNumber)}
            );
          } else {
            const calculateSingleTaxableAmount: number =
              hasColunm1 ? this.calulatePercantage(invoiceDet.totAmountUnit, this.invoice.taxableAmount) :
                this.invoiceService.round(this.invoice.taxableAmount / lines.length, 2);


            // if (this.invoice.taxableAmount - settedCalculatedTaxableAmount >= calculateSingleTaxableAmount) {
            invoiceDet.taxableAmount = calculateSingleTaxableAmount;
            settedCalculatedTaxableAmount += calculateSingleTaxableAmount;

            if (cicleNumber === lines.length) {
              const scarto = this.invoice.taxableAmount - settedCalculatedTaxableAmount;
              invoiceDet.taxableAmount += scarto;
            }

            // } else {
            //   const tempTaxableAmount: number = this.invoiceService.round(this.invoice.taxableAmount - settedCalculatedTaxableAmount, 2);
            //   invoiceDet.taxableAmount = tempTaxableAmount;
            //   settedCalculatedTaxableAmount += tempTaxableAmount;
            // }
          }
        }

        if (fields[3] !== undefined && fields[3].length > 0) {
          if (hasColunm3) {
            invoiceDet.notTaxableAmount = this.invoiceService.formatNumber(fields[3]);
          } else {
            this.translateService.translate('_CLAIMS_._INCONSISTENT_ROW_AT_LINE').subscribe(res=> {
              this.validationMessages.push(res + cicleNumber)}
            );
            
          }
        } else {
          if (hasColunm3) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._MISSING_NOT_TAXABLE_AMOUNT_AT_LINE').subscribe(res=> {
              this.validationMessages.push(res + cicleNumber)}
            );
          } else {
            if (hasColunm1) {
              invoiceDet.notTaxableAmount = 0;
            } else {
              const calculateSingleNotTaxableAmount: number = this.invoiceService.round(this.invoice.notTaxableAmount / lines.length, 2);

              // if (this.invoice.notTaxableAmount - settedCalculatedNotTaxableAmount >= calculateSingleNotTaxableAmount) {
              invoiceDet.notTaxableAmount = calculateSingleNotTaxableAmount;
              settedCalculatedNotTaxableAmount += calculateSingleNotTaxableAmount;
              // } else {
              //   const tempNotTaxableAmount: number =
              //     this.invoiceService.round(this.invoice.notTaxableAmount - settedCalculatedNotTaxableAmount, 2);
              //   invoiceDet.notTaxableAmount = tempNotTaxableAmount;
              //   settedCalculatedNotTaxableAmount += tempNotTaxableAmount;
              // }
              if (cicleNumber === lines.length) {
                const scarto = this.invoice.notTaxableAmount - settedCalculatedNotTaxableAmount;
                invoiceDet.notTaxableAmount += scarto;
              }

            }
          }
        }

        this.invoice.invoiceDetailsList.push(invoiceDet);
        cicleNumber++;
      }

      if (this.validationMessages && this.validationMessages.length === 0) {
        // formal controls
        this.formalCtrl();
      }


    };

    reader.readAsText(this.fileInput);

  }

  calulatePercantage(value: number, valueToDivide: number): number {

    // value dettaglio lordo
    // valueToDivide =  totale importo impnibile/nn_imponibile di fattura  da proporzionare
    // this.invoice.invoiceAmount  lorodo totale fattura

    // const percentage: number = 100 / (this.invoice.invoiceAmount / value);
    // return this.invoiceService.round(valueToDivide / 100 * percentage, 2);

    return (valueToDivide * value) / this.invoice.invoiceAmount;
  }

  signalError(errStr: string) {
    this.hasError = true;
    this.errorMessage = errStr;
    this.errorMessages.push(errStr);
  }

  clearError() {
    this.hasError = false;
    this.errorMessage = null;
    this.errorMessages = [];
  }

 formalCtrl() {
    this.validationMessages = [];

    let totAmountUnitDetails = 0;
    let totTaxableAmountDetails = 0;
    let totNonTaxableAmountDetails = 0;

    for (const invoiceDetail of this.invoice.invoiceDetailsList) {
      totAmountUnitDetails += invoiceDetail.totAmountUnit;
      totTaxableAmountDetails += invoiceDetail.taxableAmount;
      totNonTaxableAmountDetails += invoiceDetail.notTaxableAmount;
    }

    totAmountUnitDetails = this.invoiceService.round(totAmountUnitDetails, 2);
    totTaxableAmountDetails = this.invoiceService.round(totTaxableAmountDetails, 2);
    totNonTaxableAmountDetails = this.invoiceService.round(totNonTaxableAmountDetails, 2);

    // COSTO TOTALE UNITARIO - NTOTUNITAMOUNT a zero
    if (totAmountUnitDetails <= 0) {
      // Costo totale unitario deve essere maggiore di zero
      this.translateService.translate('_CLAIMS_._MESSAGE_._TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO').subscribe(res=> {
        this.validationMessages.push(res)}
      );
    }

    // La somma dell’importo imponibile di tutti i dettagli della fattura (NTAXABLEAMOUNT)
    // <> dall’importo imponibile della fattura (NTAXABLEAMOUNT)
    if (totTaxableAmountDetails !== this.invoice.taxableAmount) {
      // Somma importo imponibile dei dettagli diverso da importo imponibile fattura
      this.translateService.translate('_CLAIMS_._MESSAGE_._SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE').subscribe(res=> {
        this.validationMessages.push(res)}
      );
    }

    // La somma dell’importo non imponibile di tutti i dettagli della fattura (NNOTAXABLEAMOUNT)
    // <> dall’importo non imponibile della fattura (NNOTAXABLEAMOUNT)
    if (totNonTaxableAmountDetails !== this.invoice.notTaxableAmount) {
      // Somma importo non imponibile dei dettagli diverso da importo non imponibile fattura
      this.translateService.translate('_CLAIMS_._MESSAGE_._SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE').subscribe(res=> {
        this.validationMessages.push(res)}
      );
    }

    // La somma dell’importo costo totale unitario di tutti i dettagli della fattura (NTOTUNITAMOUNT)
    // <> dall’importo totale documento della fattura (NINVOICEAMOUNT)
    if (totAmountUnitDetails !== this.invoice.invoiceAmount) {

      // nei dettagli non c'e' l'importo del bollo, quindi se la differenza e' di 2 euro va bene e non bisogna dare errore
      if (this.invoice.invoiceAmount - totAmountUnitDetails !== 2) {
      // Somma importo costo totale unitario dei dettagli diverso da importo fattura
      this.translateService.translate('Sum of the total unit amount of the details different from the invoice amount').subscribe(res=> {
        this.validationMessages.push(res);
      })

      }

    }

    if (this.validationMessages.length > 0) {
      return false;
    }
    return true;
  }



}

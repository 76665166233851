import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import {Address} from '../models/address';
import {Residence} from '../models/residence';

export class AddressUtils {

  public static fromAddressToResidence(address: Address): Residence {
    return {
      city: address.city,
      placeAddress: address.placeAddress,
      cap: address.cap,
      number: address.number,
      country: {
        descrizione: EMPTY_STR,
        codice: address.country
      }
    };
  }

  public static fromResidenceToAddress(address: Residence): Address {
    return {
      city: address.city,
      placeAddress: address.placeAddress,
      cap: address.cap,
      number: address.number,
      country: address.country && address.country.codice ? address.country.codice : null,
      toponym: address.toponym && address.toponym.descrizione ? address.toponym.descrizione : null,
      adminLevel1: !!address.adminLevel1 ? address.adminLevel1 : null,
      adminLevel2: !!address.adminLevel2 ? address.adminLevel2 : null,
      adminLevel2Short: !!address.adminLevel2Short ? address.adminLevel2Short : null,
      adminLevel3: !!address.adminLevel3 ? address.adminLevel3 : null,
      at: !!address.at ? address.at : null,
      fraction: !!address.fraction ? address.fraction : null
    };
  }

  public static composePostalAddress(postalAddress: Address): string {
    let address = EMPTY_STR;
    address = address.concat(postalAddress.toponym);
    address = address.concat(postalAddress.placeAddress);
    address = address.concat(', ');
    address = address.concat(postalAddress.number);
    address = address.concat(' ');
    address = address.concat(postalAddress.cap);
    address = address.concat(' ');
    address = address.concat(postalAddress.city);
    address = address.concat(' - ');
    address = address.concat(postalAddress.country);
    return address;
  }
}

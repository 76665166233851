import {Asset} from './asset';
import {Warning} from './warning';
import {Instalment} from './instalment';
import {AssetInstance} from './asset-instance';
import {InstalmentPremiumGroup} from './instalment-premium-group';
import {Beneficiary} from './beneficiary';
import {Notes} from './notes';
import {FlexDiscountConfig} from '../pc-portfolio-models/discounts-models/flex-discount-config';
export class QuotationPremium {
  productPremium: QuotationProductPremium;
  warnings: Array<Warning>;
}

export class QuotationProductPremium {
  public annual: InstalmentPremiumGroup;
  public instalment: InstalmentPremiumGroup;
  public subscriptionInstalment: InstalmentPremiumGroup;
}

export class AssetProductPremium {
  public annual: Instalment;
  public instalment: Instalment;
  public subscriptionInstalment: Instalment;
}

export class AssetList {
  public assets: Array<Asset>;

  getAsset(code: string): AssetInstance {
    return this.assets.find(asset => asset.assetId === code).instances[0];
  }
}

export class ProposalStatus {
  public status: number;
  public description: string;
}

export class Quotation {
  public isPackage: any;
  public isMultiPackages: any;
  public isSwitchEnable: any;
  public isRecalculate: any;
  public premium: QuotationPremium;
  public assetList: AssetList;
  public status: ProposalStatus;
  public beneficiariesProduct: Beneficiary[];
  public assets: any;
  public notificationsPP: string[];
  public notes: Notes;
  public flexDiscountConfig: FlexDiscountConfig;
}

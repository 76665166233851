import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';


export interface FileReadProgressEvent<T> {
  /**
   * The data when the file has been read
   */
  event: {
    readonly lengthComputable: boolean;
    readonly loaded: number;
    readonly total: number;
  };
  status: 'progress';
}

export interface FileReadCompleteEvent<T> {
  /**
   * The data when the file has been read
   */
  data?: T;
  /**
   * The data when the file has been read
   */
  event: {
    readonly lengthComputable: boolean;
    readonly loaded: number;
    readonly total: number;
  };
  status: 'complete';
}

export type FileReadEvent<T> = FileReadCompleteEvent<T> | FileReadProgressEvent<T>;

@Injectable({
  providedIn: 'root'
})
export class RgiRxFileReaderService {


  constructor() { }


  /**
   * Read file or blob as binary string
   * @param file the file to read
   */
  readAsBinaryString(file: Blob|File): Observable<FileReadEvent<string>> {
    return new Observable((observer: Observer<FileReadEvent<string>>) => {
      const reader = this.readerBackend(observer);
      reader.readAsBinaryString(file);
      return () => {};
    });
  }

  /**
   * Read the file as text, allows to set the encoding
   * @param file the file to read
   * @param encoding the encoding of the file
   */
  readAsText(file: Blob|File, encoding?: string): Observable<FileReadEvent<string>> {
    return new Observable((observer: Observer<FileReadEvent<string>>) => {
      const reader = this.readerBackend(observer);
      reader.readAsText(file, encoding);
      return () => {};
    });
  }

  /**
   * Read the file as an URL
   * @param file the file to read
   * @link https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
   */
  readAsDataURL(file: Blob|File): Observable<FileReadEvent<string>> {
    return new Observable((observer: Observer<FileReadEvent<string>>) => {
      const reader = this.readerBackend(observer);
      reader.readAsDataURL(file);
      return () => {};
    });
  }

  /**
   * Read the file as an ArrayBuffer
   * @param file to be read
   */
  readAsArrayBuffer(file: Blob|File): Observable<FileReadEvent<ArrayBuffer>> {
    return new Observable((observer: Observer<FileReadEvent<ArrayBuffer>>) => {
      const reader = this.readerBackend(observer);
      reader.readAsArrayBuffer(file);
      return () => {};
    });
  }

  private readerBackend(observer: Observer<FileReadEvent<ArrayBuffer|string>>) {
    const reader = new FileReader();
    reader.onerror = err => observer.error(err);
    reader.onabort = err => observer.error(err);
    reader.onloadend = () => observer.complete();
    reader.onload = loaded => observer.next({
      event: loaded,
      data: reader.result,
      status: 'complete'
    });
    reader.onprogress = progress => observer.next({
      event: progress,
      status: 'progress'
    });
    return reader;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtractFromArrayPipe } from './extract-from-array.pipe';

@NgModule({
    declarations: [ExtractFromArrayPipe],
    imports: [CommonModule],
    exports: [ExtractFromArrayPipe]
})
export class ExtractFromArrayPipeModule {
}

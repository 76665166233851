import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

/**
 * Flatten an array of elements
 * @param source
 */
export function flatten<T>(source: T[]): T {
  return ([] as any).concat(...source);
}


/**
 * Shift an item in an array from one index to another
 * @param array
 * @param fromIndex
 * @param toIndex
 */
export function shiftItemPosition<T>(array: T[], fromIndex: number, toIndex: number) {
  return moveItemInArray(array, fromIndex, toIndex)
}

/**
 * Transfer an item from one array to another at a specific index
 * @param currentArray
 * @param targetArray
 * @param currentIndex
 * @param targetIndex
 */
export function transferItemPosition<T>(currentArray: T[], targetArray: T[], currentIndex: number, targetIndex: number) {
  return transferArrayItem(currentArray, targetArray, currentIndex, targetIndex)
}

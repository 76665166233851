export class DashboardSummaryData {

    description: string;
    movements: number;
    grossPremium: number;
    commission: number;
    id: number;
    parent: number;

    constructor() {
    }

}

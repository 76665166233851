import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
import {LoadingIndicatorService} from '@rgi/rx';


@Injectable()
export class HttpPendingRequestInterceptor implements HttpInterceptor {

  constructor(private httpStatusService: LoadingIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.httpStatusService.increment(1);
    return next.handle(req)
      .pipe(
        finalize(() => this.httpStatusService.decrement(1))
      );
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ICD_CARD} from './icd-card';
import {RgiRxPortalModule} from '@rgi/rx/portal';
import {
  DashboardAuthBusinessService,
  DashboardModule,
  DashboardResourceService
} from '@rgi/icd-card';
import {DashboardAuthPortalService} from './icd-card-auth-portal.service';


@NgModule({
  imports: [
    DashboardModule,
    CommonModule,
    RgiRxPortalModule.forRoot(ICD_CARD)
  ],
  providers: [
    { provide: DashboardResourceService, useClass: DashboardResourceService },
    { provide: DashboardAuthBusinessService, useClass: DashboardAuthPortalService }]
})
export class IcdCardPortalModule {
  constructor() {
  }
}

import { InsurancePolicy } from "./dsc-model-policy";
import { Subject } from "./dsc-model-subject";
import { CodiceDescrizione, SelectedEventCategory } from "./dsc-model-utility";


export class Residence {
    objectId: string = null;
    country: CodiceDescrizione = new CodiceDescrizione();
    city: string = null;
    cab: string = null;
    normalized: false;
    latitude: string = null;
    placeAddress: string = null;
    number: string = null;
    toponym: string = null;
    cap: string = null;
    province: CodiceDescrizione = new CodiceDescrizione();
    addressAddition: string = null;
    specialMention: string = null;
    countryCode: string = null;
    adminLevel3Short: string = null;
    longitude: string = null;
    formatAddress: string = null;
    adminLevel3: string = null;
    adminLevel1: string = null;
    subLocality: string = null;
    adminLevel2: string = null;
    locality: string = null;
    detailCode: string = null;
    adminLevel2Short: string = null;
    fraction: string = null;
    at: string = null;
    postalAddress: string = null;
    extensionSubject: string = null;
    adminLevel1Short: string = null
}


export class DocumentAddress {
    objectId: string;
    country: any;
    city: any;
    cab: any;
    normalized: boolean;
    latitude: string;
    placeAddress: any;
    number: any;
    toponym: any;
    cap: any;
    province: any;
    addressAddition: any;
    specialMention: any;
    countryCode: any;
    adminLevel3Short: any;
    longitude: string;
    formatAddress: string;
    adminLevel3: any;
    adminLevel1: any;
    subLocality: any;
    adminLevel2: any;
    locality: any;
    detailCode: any;
    adminLevel2Short: any;
    fraction: any;
    at: any;
    postalAddress: any;
    extensionSubject: any;
    adminLevel1Short: any;
}

export class PrivacyConsensus {
    objectId: any;
    extensionSubject: any;
    privacy: {
        code: string;
        description: string;
        identification: string;
    };
    privacyConsentOrigin: any;
    privacyConsentDate: string;
    privacyConsentOriginDescription: any;
    privacyConsensusValue: CodiceDescrizione;
}

export class NodeCode {
    code: string = '';
    description: string = '';
    identification: string = ''
}

export class PartyRole {
    codice: string = '';
    descrizione: string = '';
}

export class AssetDetailDamage {
    vehicle = null;
    policyData = null;
    assetType: CodiceDescrizione = { codice: '', descrizione: '' };
    policyHolder = null;
    ppAsset = null;
    party = null;
}

export class CodeDescription {
    code: string = '';
    description: string = '';
}

export class MinisterialBranchDetail {
    ministerialBranch: CodeDescription = new CodeDescription();
}

export class DamageDescriptions {
    asset: AssetDetailDamage = new AssetDetailDamage();
    assetType: string = '';
    risk: string;
    enumAssetType: CodiceDescrizione | null;
    ministerialBranch: MinisterialBranchDetail = new MinisterialBranchDetail();
}


export class ClaimParties {
    damageDescriptions: DamageDescriptions[] = [];
    listMinorGuardianSubject: [] = [];
    partyRole: PartyRole = new PartyRole();
    partyType: string = '';
    subject: Subject = new Subject();
}


export class ClaimDetail {
    selectedPolicy?: InsurancePolicy = new InsurancePolicy();
    selectedEventCategory?: SelectedEventCategory = new SelectedEventCategory();
    liabilityMonitor: any;
    occurenceHourCertified: any;
    involvedAuthorityDescription: any;
    coverTypeCode: string;
    intermediary: any;
    occurencePlace: any;
    otherPartyResponsability: any;
    accountingReactivationOpeningDate: any;
    idFraudolentEvent: any;
    idClaim: any;
    reportDate: any;
    paidforfaitdebtor: any;
    agendaEvents: any;
    dateReceivedIntermediary: any;
    incidentDescription: string;
    incidentAdminNote: string;
    dateReportedEditable: boolean;
    intermediaryReceiptDateEditable: boolean;
    incidentDescriptionEditable: boolean;
    certifiedOccurrenceDate: boolean;
    actualOccurrenceTime: string;
    actualOccurrenceDate: string;
    additionalNotesEditable: boolean;
    draft: any;
    signType: any;
    forcingAdministrative: any;
    enumDeclaredLiabilityForcingType: any;
    balanceNoteNumber: any;
    contractorResidence: any;
    policyCancellationDate: any;
    vehicleManufacturerAndModel: any;
    idCatastrophicEvent: any;
    enumAniaClassification: any;
    isClaimMade: any;
    cardMap: any;
    paidforfaitgest: any;
    amountFees: any;
    claimBranch: any;
    forcedCoverageSecurityEffectDate: any;
    jointSignature: any;
    complementaryClaimNumber: any;
    surrenderRecoveryList: any;
    isCompExtraInfoManage: any;
    masterPolicyNumber: any;
    policyOriginalEffectDate: any;
    enumClaimTechnicalStatus: any;
    coinsuranceShare: any;
    openingFollowedRequestDamage: any;
    sicData: any;
    dateReceivedCHL: any;
    sicResponseDate: any;
    contractor: any;
    claimAccountingStatus: any;
    questionnaireItems: any;
    forcedLiabilityPercentage: any;
    extension: any;
    taxBranches: any;
    idDamageCategory: any;
    externalClaimNumber: any;
    exercise: any;
    workflowProcess: any;
    insuredGreenCardNumber: any;
    leadingInsurerCompany: any;
    questionnaireType: any;
    openingActFollowedQuote: any;
    insuredRisk: any;
    dateLastMovement: any;
    dateReceivedInsured: any;
    aniaCompanyCode: any;
    excess: any;
    protocolNumberSic: any;
    managementNote: any;
    mediation: any;
    fraudolentEventDescription: any;
    forcedDeclaredLiabilityIndicator: any;
    retention: any;
    hasPreviousClaims: any;
    forcedTechLiabilityPercentage: any;
    product: any;
    isMinorDamage: any;
    substatusCode: any;
    insuredPartyName: any;
    flowCreation: any;
    isMaterialDamageNotVehicle: any;
    reserveFees: any;
    lawyerType: any;
    debtorFoundOnPolicy: any;
    intercompany: any;
    claimRedeemableCard: any;
    policyType: any;
    operationsCardOutput: any;
    coinsuringCompanyShare: any;
    technicalReactivationOpeningDate: any;
    operationsOutput: any;
    enumOurDeclaredLiability: any;
    isMaterialDamageNotANorB: any;
    occurrenceDate: any;
    subscriptorEmail: any;
    reports: any;
    isMaterialLossCovertureOnPolicy: any;
    reopened: any;
    idPotencialClaim: any;
    forcedCoverageSecurityCollectionDate: any;
    coinsuringCompany: any;
    documents: any;
    isFastApproval: any;
    adjustements: any;
    policyNumber: string;
    occurenceDescription: any;
    coinsuranceType: any;
    leadingInsurerShare: any;
    claimTechnicalStatus: any;
    manageable: any;
    isLegalProtectionEnabled: any;
    amountIndemnity: any;
    claimType: any;
    isExternalPortfolio: any;
    catastrophicEventDescription: any;
    limit: any;
    policyAgencyData: any;
    involvedAuthority: any;
    migrated: any;
    fraudulent: any;
    policyExpirationDate: any;
    substatus: any;
    administrativeForcingNote: any;
    occurrenceDateCertified: any;
    policyNode: any;
    insertionDate: any;
    responsabilityType: any;
    sicOutcome: any;
    subscriptorBirthDate: any;
    policyEffectDate: any;
    forceLiability: any;
    openingType: any;
    signTypeConfirmed: any;
    insuredVehiclePlate: any;
    lawyerPolicyHolder: any;
    adjuster: any;
    insuredVehiclePlateSic: any;
    tipoCard: any;
    notIdentifiedWitness: any;
    technicalResponsabilityPercentage: any;
    codeAdjuster: any;
    ministerialBranchCodes: any;
    ministerialBranch: any;
    aniaResponsability: any;
    claimParties: any;
    specialGuarantees: any;
    sicSendDate: any;
    unknownVehicleColl: any;
    reservedforfaitdebtor: any;
    applicationNumber: any;
    descriptionMovement: any;
    internalCompanyCode: any;
    contractorDateOfBirth: any;
    technicalCoverage: any;
    coverageEndDate: any;
    partyVehiclePlateSic: any;
    baremCaseA: any;
    partyCompany: any;
    baremCaseB: any;
    securityCollectionDate: any;
    productClaimsList: any;
    reserve: any;
    claimAddress: any;
    leadingInsurerPolicyNumber: any;
    claimNumber: any;
    productType: any;
    subscriptorFiscalCode: any;
    accountingOpeningDate: any;
    enumClaimType: any;
    amount: any;
    existsActiveAssignments: any;
    accountingMovements: any;
    specificCover: any;
    contractorUniqueId: any;
    reportNumber: any;
    forcedCoverageSecurityExpiryDate: any;
    balanceNoteDate: any;
    subscriptorTelephone: any;
    managerEntity: any;
    claimBranchCodes: any;
    accountingClosingDate: any;
    technicalOpeningDate: any;
    accountingCoverage: any;
    antifraudCategory: any;
    claimNotInvolvedParties: any;
    externalCompanyCode: any;
    reservedforfaitgest: any;
    occurrenceHour: any;
    potentialClaimFileEvents: any;
    policyIssueDate: any;
    witnessesFor: any;
    forceLiabilityReason: any;
    claimsHandlingCentre: any;
    requestsDamages: any;
    enumClaimAccountingStatus: any;
    leastOneDocumentViewed: any;
    technicalClosingDate: any;
    selectedCoverture: any;
    contract: any;
    contractDetail: any;
    disabledBackButton?: boolean;
    percInsuredLiability: any;
    eventDenial: boolean;
    authorityDescriptionValue: string;
    typeOfAuthorityInvolvedValue: string;
    lastAuditDateValue: any;
    openedClaimDuetoSummonsIssuedValue: boolean;
    notIdentifiedWitnessValue: boolean;
    toBeSettledValue: boolean;
    companyDetailObject: any;
    context: string;
}


export class DamageDescription {
    acceptanceDate: any;
    agreementType: any;
    dateReceivedSettlementCentre: any;
    inspectorateTelephone: any;
    causative: any;
    coverageTypeCode: any;
    chassisOrPlateNumber: any;
    deprecated: any;
    inspectorate: any;
    updateReason: any;
    appointmentList: any;
    totalPaymentFees: any;
    managementType: any;
    accountingReactivationOpeningDate: any;
    totalPayment: any;
    damagesDescriptionId: any;
    reportDate: any;
    totalPaymentIndemnity: any;
    inspectorateTime: any;
    vehicleType: any;
    inspectorateFax: any;
    summonsList: any;
    substatus: any;
    claimDamagesList: any;
    reserveTechAmountContantRegister: any;
    fileAssumed: any;
    substatusList: any;
    reserveTechInsertionDate: any;
    assetType: string;
    reserveAccountingAssignmentExpenses: any;
    enumTypeOfLossTechnicalStatus: any;
    adjuster: any;
    negotiationsList: any;
    mediationsList: any;
    injuriesList: any;
    reserveTechAssignmentExpenses: any;
    description: any;
    dateForwardingToSettlementCentre: any;
    permanentDisabilityPercentage: any;
    company: any;
    coverageCode: any;
    accountingOpeningDate: any;
    enumAssetType: CodiceDescrizione;
    managementStatus: any;
    valoreInLite: any;
    inspectorateEmail: any;
    reserveTechAmount: any;
    substatusCode: any;
    enumTypeOfLossManagementType: any;
    litigationsList: any;
    technicalStatus: any;
    accountingClosingDate: any;
    technicalOpeningDate: any;
    accountingStatus: any;
    reserveAccountingAmountContantRegister: any;
    inspectorateAddress: any;
    operationsCardOutput: any;
    technicalReactivationOpeningDate: any;
    risk: any;
    reserveAccountingAmount: any;
    operationsOutput: any;
    technicalClosingDate: any;
    ministerialBranch: any;
    asset: Asset;
}

export class Asset {
    objectId: any;
    address: any;
    underage: any;
    policyData: {
        objectId: any;
        companyCode: string;
        isMaterialLossCovertureOnPolicy: any;
        effectDate: string;
        notes: any;
        address: any;
        contractorFiscalCode: any;
        policyNumber: string;
        companyData: any;
        greenCardNumber: any;
        extensionData: any;
        greenCardCountry: any;
        greenCardExpirationDate: any;
        agencyDescription: any;
        ownerFiscalCode: any;
        expirationDate: string;
        company: {
            objectId: any;
            isvapCode: any;
            aniaCode: any;
            extension: any;
            address: any;
            province: any;
            name: string;
            postCode: any;
            code: any;
            damagesPortfolio: boolean;
            lifePortfolio: boolean;
        };
    };
    extensionData: any;
    vehicle: {
        objectId: any;
        licensePlateCountryCode: string;
        firstRegistrationDate: string;
        substitution: any;
        bodyShopExternalKey: any;
        bodyShopSubject: any;
        manufacturerDescription: string;
        financialConstraint: any;
        licensePlateNumber: string;
        visibleDamages: any;
        modelCode: any;
        directlyInvolvedInCollision: true;
        parkedVehicle: any;
        blackboxCoherence: any;
        otherInvVehicles: any;
        responsabilityPercentage: any;
        observation: any;
        recoveryProvince: any;
        bodyShopTrusteeType: any;
        manufacturerCode: any;
        damagedParts: any;
        sicOutcome: any;
        isFromLayout: any;
        modelDescription: string;
        chassisNumber: any;
        aniaCode: any;
        insotherCompany: any;
        theftType: any;
        driver: Subject;
        bodyShopCode: any;
        recoveryDate: any;
        theftProtection: any;
        licensePlateType: CodiceDescrizione;
        caiposition: CodiceDescrizione;
    };
    creditTransfer: any;
    estimatedAmount: any;
    party: any;
    policyHolder: Subject;
    description: any;
    assetType: CodiceDescrizione;
    ppAsset: PpAsset;
    insuredAsset: any;
}

export interface PpAsset {
    code: string;
    description: string;
    identification: any;
}
import {downgradeInjectable} from "@angular/upgrade/static";
import {ProductSourceService} from "../resources/product-source.service";

declare var angular: angular.IAngularStatic;

export function downgrade() {

  let module = angular.module("app");

  module.factory('ProductSourceServiceAJS', downgradeInjectable(ProductSourceService));

}

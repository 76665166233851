import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Role } from '../../../models/postsales-operations-response.model';

@Component({
  selector: 'lpc-sub-beneficiary-control',
  templateUrl: './lpc-sub-beneficiary-control.component.html',
  styleUrls: ['./lpc-sub-beneficiary-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcSubBeneficiaryControlComponent),
      multi: true
    }
  ]
})
export class LpcSubBeneficiaryControlComponent implements OnInit, ControlValueAccessor {

  @Input() public showPercentage = true;
  @Output() public delete: EventEmitter<Role> = new EventEmitter<Role>();

  @Input() public value: Role;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    role: new UntypedFormControl(),
    name:  new UntypedFormControl(),
    percentage: new UntypedFormControl(),
    personType:  new UntypedFormControl(),
    adult: new UntypedFormControl(),
    linkedSubjectsRoles: new UntypedFormArray([])
  });

  constructor() { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((value) => {
      this.writeValue(value);
      this.onChange(value);
    });
  }

  writeValue(obj: Role): void {
    this.value = obj;
    if (!!obj) {
      this.formGroup.setValue(obj, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onChange(obj: Role) {
  }

   deleteBen(value: Role) {
    this.delete.emit(value);
  }

}

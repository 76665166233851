import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcQuestionnaireContainerComponent } from './lpc-questionnaire-container.component';
import { NgPassproModule } from '@rgi/ng-passpro';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
    imports: [
        CommonModule,
        NgPassproModule,
        RgiRxI18nModule
    ],
    exports: [
        LpcQuestionnaireContainerComponent
    ],
    declarations: [
        LpcQuestionnaireContainerComponent
    ]
})
export class LpcQuestionnaireContainerModule { }

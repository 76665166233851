import {PassProductsService} from "./pass-products.service";
import {Injectable} from "@angular/core";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ElaborateCustomProperties {
  constructor(
    protected passProductService: PassProductsService,
  ) {
  }

  async getBooleanCustomProperty(contractId, customPropertyCode, defaultValue): Promise<boolean> {
    return new Promise((resolve) => {
      this.passProductService.getProductProperty(contractId, customPropertyCode).pipe(take(1)).subscribe(resp => {
        if (resp.value === 'false') {
          resolve(false);
        } else if (resp.value === 'true') {
          resolve(true);
        } else {
          resolve(defaultValue);
        }
      }, () => {
        resolve(defaultValue);
      });
    });
  }
}

export enum CustomProperties {
  BINDING_PROPERTY = 'BINPRO'
}

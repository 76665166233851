import {AnagApiSubject} from './group-policy-issue-policy-data';

export type ActionPartyRole = 'EDIT-PARTY' | 'DELETE-PARTY-ROLE' | 'ADD-PERCENTAGE-ON-ROLE' | 'SET-PARTY-SELECTED';

export const  ACTION_PARTY_ROLE_ENUM = {
  EDITPARTY: 'EDIT-PARTY' as ActionPartyRole,
  DELETEPARTYROLE: 'DELETE-PARTY-ROLE' as ActionPartyRole,
  ADDPERCENTAGEONROLE: 'ADD-PERCENTAGE-ON-ROLE' as ActionPartyRole,
  SETPARTYSELECTED: 'SET-PARTY-SELECTED' as ActionPartyRole
};

export interface PartyRoleParentData {
  role?: string;
  percentage?: number;
  party?: AnagApiSubject;
  idParty: string;
  action: ActionPartyRole;
}

<div style="margin:10px">
  <div class="rgi-gp-section-title rgi-gp-mb-m">
    <b translate>_GP_._TITLE_._CLUSTER_LIST_</b>
  </div>
  <rgi-rx-datatable [data]="clusterListTableData"
                    [schema]="CLUSTER_LIST_TABLE_SCHEMA"
                    (onAction)="onActionClick($event)"
                    [sortHeader]="true"
                    [expansionRow]="clusterDetails"
                    [expansionModel]="clusterDataTableExpansionModel">
    <ng-template [rgiRxDataTableCell]="'clusterStatusDescription'" let-ctx>
      <span class="rgi-ui-badge-rounded-pill"
            [ngClass]="{'rgi-ui-danger': ctx.row.clusterStatusCode==='3', 'rgi-ui-warning': ctx.row.clusterStatusCode==='1', 'rgi-ui-success': ctx.row.clusterStatusCode==='2' }">
        {{ctx.row.clusterStatusDescription}}
      </span>
    </ng-template>
    <ng-template #clusterDetails let-ctx>
      <ng-template #consultation>
        <ng-container *ngIf="getCachedClusterByCode(ctx.clusterNumber) as cluster">
          <rgi-gp-cluster-detail [cluster]="cluster.cluster" [proposalNumber]="stateCluster.proposalNumber"
                                 [resourceId]="stateCluster.resId" [assetCode]="stateCluster.codeAsset"
                                 [showApplicationList]="showApplicationList"
                                 [idParentSession]="stateCluster.idParentSession"
                                 [clauses]="cluster.clauses[0]">
          </rgi-gp-cluster-detail>
        </ng-container>
      </ng-template>
      <ng-container *ngIf="showEditableClusterForm && stateCluster.editableCluster && stateCluster.editableCluster.cluster.code === ctx.clusterNumber; else consultation" [formGroup]="editableClusterFormGroup">
        <div style="margin:10px">
          <div class="rgi-ui-grid-2">
            <div class="rgi-ui-col">
              <rgi-rx-form-field>
                <input rgiRxInput formControlName="clusterName" rgi-rx-qa="cluster-name">
                <label rgiRxLabel translate>_GP_._LABEL_._CLUSTER_NAME_</label>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col">
              <rgi-rx-form-field>
                <input rgiRxInput formControlName="clusterDescription">
                <label rgiRxLabel translate>_GP_._LABEL_._DESCRIPTION_</label>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col">
              <rgi-rx-drop-container formControlName="clusterOwner" class="rgi-ui-col"
                                     [label]="'_GP_._LABEL_._CLUSTER_OWNER_' | translate"
                                     [select]="'searchOrCreateSubject'" viewField="nominative"
                                     [selectData]="getSubjectDropContainerData()"
                                     [attr.data-qa]="'btn_add_cluster_owner'"
                                     (onValueChange)="onPartySelected($event)">
                <rgi-rx-drag-action class="rgi-ui-icon-edit" (onAction)="modifySubject($event)"
                                    [attr.data-qa]="'modifyParty' + codeAsset + '_' + stateCluster.editableCluster.cluster.code">
                </rgi-rx-drag-action>
                <rgi-rx-drag-remove (onRemove)="deleteSubject()"
                                    [attr.data-qa]="'deleteParty' + codeAsset + '_' + stateCluster.editableCluster.cluster.code">
                </rgi-rx-drag-remove>
              </rgi-rx-drop-container>
            </div>
            <div class="rgi-ui-col"></div>
            <div class="rgi-ui-col">
              <rgi-rx-form-field class="rgi-ui-col">
                <label rgiRxLabel>
                  <span translate>_GP_._LABEL_._ASSET_CLASS_</span>
                </label>
                <select rgiRxNativeSelect formControlName="clusterClass" data-qa="clusterClass">
                  <option disabled value=""></option>
                  <option *ngFor="let class of stateCluster.classes" [value]="class.code">{{class.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col">
              <rgi-rx-form-field class="rgi-ui-col">
                <label rgiRxLabel>
                  <span translate>_GP_._LABEL_._ASSET_USE_</span>
                </label>
                <select rgiRxNativeSelect formControlName="clusterUses" data-qa="clusterUses">
                  <option disabled value=""></option>
                  <option *ngFor="let use of stateCluster.uses" [value]="use.code">{{use.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col">
              <rgi-rx-form-field class="rgi-ui-col">
                <label rgiRxLabel>
                  <span translate>_GP_._LABEL_._ASSET_SECTOR_</span>
                </label>
                <select rgiRxNativeSelect formControlName="clusterSectors" data-qa="clusterSectors">
                  <option disabled value=""></option>
                  <option *ngFor="let sector of stateCluster.sectors"
                          [value]="sector.code">{{sector.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="!!stateCluster.extensions && stateCluster.extensions.length > 0">
              <rgi-rx-form-field class="rgi-ui-col">
                <label rgiRxLabel>
                  <span translate>_GP_._LABEL_._ASSET_EXTENSION_</span>
                </label>
                <select rgiRxNativeSelect formControlName="clusterExtensions" data-qa="clusterExtensions">
                  <option disabled value=""></option>
                  <option *ngFor="let extension of stateCluster.extensions"
                          [value]="extension.code">{{extension.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
          </div>

          <div class="rgi-gp-section-title rgi-gp-mb-m"
               *ngIf="stateCluster.editableCluster.cluster.variables?.length>0">
            <b translate>_GP_._TITLE_._VARIABLES_</b>
          </div>
          <rgi-gp-group-policy-factors-component
            *ngIf="stateCluster.editableCluster.cluster.variables && stateCluster.editableCluster.cluster.variables.length"
            [factors]="stateCluster.editableCluster.cluster.variables"
            [parentForm]="assetVariablesFGMap.get(stateCluster.codeAsset+stateCluster.editableCluster.cluster.code)"
            (updateFactors)="onVariableUpdate($event, stateCluster.editableCluster.cluster)"
            class="rgi-gp-flex-row">
          </rgi-gp-group-policy-factors-component>
          <div class="rgi-gp-section-title rgi-gp-mb-m" *ngIf="stateCluster.editableCluster.cluster.units?.length>0">
            <b translate>_GP_._TITLE_._GUARANTEES_</b>
          </div>
          <rgi-gp-unit *ngFor="let unit of stateCluster.editableCluster.cluster.units; trackBy: trackUnit"
                       [unit]="unit.unit"
                       [unitsClause]="(stateCluster.editableCluster.clauses && stateCluster.editableCluster.clauses[0]) | gpFilterAssetClusterUnitsCauses:unit.unit"
                       [linkedUnits]="unit.linkedUnit"
                       [accordionMap]="accordionMap"
                       [accordionPrefix]="stateCluster.codeAsset + '_' + stateCluster.editableCluster.cluster.code + '_'"
                       [parentForm]="editableClusterFormGroup"
                       [unitInitialStatuses]="unitInitialStatuses"
                       (updateUnit)="updateUnit($event, stateCluster.editableCluster.cluster)"
                       (updateUnitClauses)="onUpdateAssetUnitClauses($event)">
          </rgi-gp-unit>
          <rgi-gp-group-policy-clause
            *ngIf="stateCluster.editableCluster.clauses && stateCluster.editableCluster.clauses[0] && stateCluster.editableCluster.clauses[0].assetClauses"
            [clauses]="stateCluster.editableCluster.clauses[0].assetClauses"
            [parentForm]="editableClusterFormGroup"
            (formChange)="editableClusterFormGroup=$event"
            (updateClauses)="onUpdateAssetClauses($event)"
          ></rgi-gp-group-policy-clause>

          <div class="rgi-ui-grid-1">
            <div class="rgi-ui-col">
              <rgi-rx-snackbar [tag]="'assetClusters' + this.codeAsset + 'edit'"></rgi-rx-snackbar>
            </div>
          </div>

          <div class="rgi-ui-panel-footer">
            <button rgi-rx-button (click)="openCommissionsModal()"
                    *ngIf="isCommDiscountBtnVisible"
                    [disabled]="!isCommDiscountBtnEnabled"
                    color="secondary" translate>{{commDiscountBtnLabel}}
            </button>
            <button rgi-rx-button (click)="saveCluster()" color="primary" translate>_GP_._BTN_._SAVE_CLUSTER_</button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </rgi-rx-datatable>

  <div class="rgi-ui-grid-1">
    <div class="rgi-ui-col">
      <rgi-rx-snackbar [tag]="'assetClusters' + this.codeAsset"></rgi-rx-snackbar>
    </div>
  </div>
  <button class="rgi-ui-btn rgi-ui-btn-outline" *ngIf="showCreateClusterBtn && editMode"
          (click)="createCluster()" translate>
    _GP_._BTN_._CREATE_CLUSTER_
  </button>

</div>

import {PcPremium} from '../pc-portfolio-models/quotation-models/pc-premium';
import {Unit} from './unit';

export class AssetSection {
  public id: string;
  public code: string;
  public description: string;
  public open: boolean;
  public unitList: Array<Unit>;
  public extendedDescription: string;
  public premium: PcPremium;

  getUnit(code: string): Unit {
    return this.unitList.find(unit => unit.id === code);
  }
}

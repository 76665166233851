import {ChangeDetectionStrategy, Component, ContentChild, HostBinding, Inject, Input} from '@angular/core';
import {CdkStep, CdkStepper} from '@angular/cdk/stepper';
import {RgiRxStepContentDirective} from '../rgi-rx-step-content.directive';
import {RGI_RX_STEPPER_OPTIONS, RgiRxStepperOptions} from '../rgi-rx-stepper-api';
import {RgiRxStepLabelDirective} from '../rgi-rx-step-label.directive';

let rgiRxStepCounter = 0;

@Component({
  selector: 'rgi-rx-step',
  template: `
    <ng-template>
      <ng-content>
      </ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CdkStep,
      useExisting: RgiRxStepComponent
    }
  ]
})
export class RgiRxStepComponent extends CdkStep {

  private _selectable = true;

  @ContentChild(RgiRxStepLabelDirective, {static: false}) stepLabel: RgiRxStepLabelDirective;
  private readonly _id: string;

  @Input() get selectable(): boolean {
    return this._selectable;
  }

  set selectable(value: boolean) {
    this._selectable = value;
  }

  @ContentChild(RgiRxStepContentDirective, {static: false}) _lazyContent: RgiRxStepContentDirective;

  constructor(_cdkStepper: CdkStepper, @Inject(RGI_RX_STEPPER_OPTIONS) _stepperOptions: RgiRxStepperOptions) {
    super(_cdkStepper, _stepperOptions);
    this._id = `rgi-rx-step-${rgiRxStepCounter++}`;
  }


  get id(): string {
    return this._id;
  }
}

import {TableSchema} from '@rgi/rx/ui';

export const APPLICATIONS_LIST_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'contractNumber',
      title: '_GP_._APPLICATIONS_._POLICY_REFERENCE_'
    },
    {
      name: 'statusDescription',
      title: '_GP_._APPLICATIONS_._STATUS_'
    },
    {
      name: 'contractDescription',
      title: '_GP_._APPLICATIONS_._POLICY_DESCRIPTION_'
    },
    {
      name: 'issueDate',
      title: '_GP_._LABEL_._ISSUE_DATE_',
      format: {
        pipe: 'gpDatePipe'
      }
    },
    {
      name: 'effectiveDate',
      title: '_GP_._APPLICATIONS_._EFFECTIVE_DATE_',
      format: {
        pipe: 'gpDatePipe'
      }
    },
    {
      name: 'userName',
      title: '_GP_._LABEL_._USER_'
    },
    {
      name: 'action',
      title: '_GP_._APPLICATIONS_._OPEN_DETAIL_',
      actions: [
        {
          name: 'OPEN_APPLICATION',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-right-arrow'
        }
      ]
    }
  ],
  header: ['contractNumber', 'statusDescription', 'contractDescription', 'issueDate', 'effectiveDate', 'userName', 'action']
};

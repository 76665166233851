<rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()"/>
    {{ '_CLAIMS_._PAYMENT_DELETE_CHECK_QUESTION' | translate }}
    <br><br><b>{{ '_CLAIMS_._FUNCTION' | translate }}</b>: {{ data.codiceFunzione }}
    <br><b>{{ '_CLAIMS_._CODE_MESSAGE' | translate }}</b>: {{ data.codiceMessaggio }}
    <br><b>{{ '_CLAIMS_._VALIDITY_START_DATE' | translate }}</b>: {{ data.dataInizioValidita }}
    <br><b>{{ '_CLAIMS_._VALIDITY_END_DATE' | translate }}</b>: {{ data.dataFineValidita }}
    <br><b>{{ '_CLAIMS_._IMPORT' | translate }}</b>: {{ data.importo }}
    <rgi-rx-panel-footer>
        <button rgi-rx-button color="secondary" translate="" (click)="modalClose.emit()"> {{'_CLAIMS_._BUTTONS_._CANCEL' | translate}}</button>
        <button rgi-rx-button color="primary" translate="" (click)="modalClose.emit(data.idForfait)"> {{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
    </rgi-rx-panel-footer>
</rgi-rx-panel>
import {getCurrencySymbol} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {RgiCountryLayerContext} from '../rgi-country-layer.api';

// fixme this does not support context change
@Pipe({
  name: 'rgiCountryLayerCurrencyFormat'
})
export class RgiCountryLayerCurrencyFormatPipe implements PipeTransform {

  constructor(private context: RgiCountryLayerContext) {
  }

  transform(code: string, format: 'wide' | 'narrow' = 'narrow', locale?: string ): string {
    const LOCALE = !!locale ? locale : this.context.locale;
    return getCurrencySymbol(code, format, LOCALE); // fixme extract locale currency symbol from Intl without relying on @angular/common.
  }

}

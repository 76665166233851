import { PanelService } from '../panel.service';
import { PanelStatus } from '../dto/panel-status';
import { EditablePanelElement } from '../editable-panel-element/editable-panel-element';
import { Directive } from "@angular/core";

@Directive()
export abstract class EditablePanelContentComponent extends EditablePanelElement {
  enabled: boolean;

  constructor(panelService: PanelService) {
    super(panelService);
  }

  protected panelStatusRecieved(panelStatus: PanelStatus) {
    this.panelStatus = panelStatus;
    this.enabled = panelStatus.contentEnabled;
  }

  confirm() {
    if (this.validateContent()) {
      this.storeData();
    } else {
      this.setPanelIncomplete();
    }
  }

  protected abstract validateContent(): boolean;
  protected abstract storeData();
}

import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {OnModalClose} from '@rgi/rx/ui';
import {GPRecoverAppsPayload, GPRecoverAppsSubstitutionType} from '../../../group-policy-models/gp-move-application';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'rgi-gp-recover-applications-modal',
  templateUrl: './recover-applications-modal.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GpRecoverApplicationsModalComponent implements OnInit, OnDestroy, OnModalClose {

  substitutionType = GPRecoverAppsSubstitutionType;
  modalClose = new EventEmitter<GPRecoverAppsPayload>();
  recoverAppsForm = new UntypedFormGroup({
    substitutionType: new UntypedFormControl(GPRecoverAppsSubstitutionType.FULL, Validators.required),
    renumberApps: new UntypedFormControl(false, Validators.required),
    // generateTechAcc: new FormControl(true, Validators.required),
  });

  subscriptions = new Subscription();

  constructor() {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.recoverAppsForm.controls.substitutionType.valueChanges.subscribe((substitutionType: GPRecoverAppsSubstitutionType) => {
        const renumberApps = this.recoverAppsForm.controls.renumberApps;
        switch (substitutionType) {
          case GPRecoverAppsSubstitutionType.FULL:
            if (renumberApps.disabled) {
              renumberApps.enable();
            }
            break;
          case GPRecoverAppsSubstitutionType.INCREMENTAL:
            renumberApps.setValue(true);
            renumberApps.disable();
            break;
          default:
            return;
        }
      })
    );
  }

  confirm() {
    this.recoverAppsForm.markAllAsTouched();
    if (this.recoverAppsForm.valid) {
      const payload = new GPRecoverAppsPayload();
      payload.substitutionType = this.recoverAppsForm.controls.substitutionType.value;
      // payload.generateTechAcc = this.recoverAppsForm.controls.generateTechAcc.value;
      payload.renumberApps = this.recoverAppsForm.controls.renumberApps.value;
      this.modalClose.emit(payload);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.modalClose.unsubscribe();
  }

}

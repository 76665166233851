import {Component, Input} from '@angular/core';
import { ContractDetails } from '../../../models/details/contract-details';

@Component({
  selector: 'lib-consultation-subjects',
  templateUrl: './consultation-subjects.component.html',
  styleUrls: ['./consultation-subjects.component.css']
})
export class ConsultationSubjectsComponent {
  @Input() contract:ContractDetails;
}

import {InjectionToken} from "@angular/core";

export type SurveyConfig = {
  evaluation: {
    hideNonFittingElements?: boolean,
    defaultProductsGroupBy?: 'product'|'lob',
    hideGroupProductSelection?: boolean,
    ephemeralEvaluation?: boolean
  }
  useSources?: boolean;
  useRuleResources?: boolean;
};
export const NG_PASSPROPRO_SURVEY_CONFIG = new InjectionToken<SurveyConfig>("NG_PASSPROPRO_SURVEY_CONFIG");

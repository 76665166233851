import {ApiTaxComponent} from './api-tax-component';

export class ApiPremiumDetail {

  constructor(
    public taxable: number,
    public gross: number,
    public accessories: number,
    public antiracket: number,
    public taxes: number,
    public net: number,
    public paymentFrequencyInterestNet: number,
    public addictionalInterests: number,
    public paymentFrequencyInterestAccessories: number,
    public paymentFrequencyInterest: number,
    public sSN: number,
    public taxRate: number,
    public ssnRate: number,
    public taxComponents: Array<ApiTaxComponent>
  ) {
  }

}

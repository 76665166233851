import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DashboardRiskDetail} from '../model/dashboard-risks-detail';
import {DashboardUtilityService} from '../services/dashboard-utility.service';
import {formatNumber} from '@angular/common';

@Component({
    selector: 'dashboard-detail-table-risk',
    templateUrl: './dashboard-detail-table-risk.component.html',
    styleUrls: ['./dashboard-detail-table-risk.component.css']
})
export class DashboardDetailTableRiskComponent {

    constructor(protected utility: DashboardUtilityService) {
    }
    @Input() riskDetailForm: UntypedFormGroup;
    @Input() idDashBoard: string;
    @Input() risks: DashboardRiskDetail[];
    @Input() editMode: boolean;

    displayedColumns: string[] = ['Rischi Assicurati', 'Provv.Acquisto Banca', 'Provv. Acquisto Compagnia',
        'Provv. Incasso Banca', 'Provv. Incasso Compagnia', 'Nuovo Importo Provv. Acquisto Banca',
        'Nuovo importo Provv. Incasso banca'];

    formatValue(formControlId: string) {
        let value = this.riskDetailForm.controls[formControlId].value;
        if (value) {
            const numericValue = value.replace(/\./g, '').replace(',', '.');
            value = formatNumber(Number(numericValue), 'it', '1.2-2');
            this.riskDetailForm.controls[formControlId].setValue(value);
        }
    }
/*
    unformatValue(formControlId: string) {
        let value = this.riskDetailForm.controls[formControlId].value;
        value = value.replace('.', '').replace(',', '.'); // 1.000,00 => 1000.00
        this.riskDetailForm.controls[formControlId].setValue(value);
    }
    */



    getSumOnEditMode(idDashBoard: string, key: string) {
        let sum = 0;
        this.risks.forEach((risk) => {
          const value = this.riskDetailForm.controls[`${idDashBoard}_${risk.idDashRisk}_${key}`].value;
          if (value && !isNaN(parseFloat(value.replace(/\./g, '').replace(',', '.')))) {
            const parsedValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
            sum += parsedValue;
          }
        });
        return sum;
      }

    getTotalSumRisk(idDashBoard: string, key: string) {
        return this.utility.getSum(idDashBoard, key, this.risks);
    }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagApiParty, AnagFlowData} from '@rgi/anag';
import {PushMessageHandlerService} from '@rgi/rx';
import {ActiveRoute} from '@rgi/rx/router';
import {
  RgiRxDatatableComponent,
  RgiRxDataTableExpansionModel,
  RgiRxDatatableRowAction,
  RgiRxOnContainerValueChange,
  RgiRxTableExpansionChange,
  RgiRxTableExpansionTrigger
} from '@rgi/rx/ui';
import {BehaviorSubject, Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {GroupPolicyDateAdapter} from '../../adapters/ngbDateCustomParserFormatter ';
import {
  CLUSTER_LIST_DETAIL_TABLE_SCHEMA,
  CLUSTER_LIST_TABLE_SCHEMA
} from '../../group-policy-constants/cluster-list-tableschema';
import {GPCluster, GPClusterParty} from '../../group-policy-models/group-policy-cluster';
import {GpUnitClause, Units} from '../../group-policy-models/group-policy-issue-guarantees';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {BaseEntity, Factor, GPClause} from '../../group-policy-models/group-policy-issue-policy-data';
import {GpCommissionDiscountBtnsService} from '../../group-policy-resources/gp-commission-discount-btns.service';
import {GPVariableService} from '../../group-policy-services/gpvariable.service';
import {GroupPolicyCrossService} from '../../group-policy-services/group-policy-cross.service';
import {GroupPolicyStateCluster} from '../../group-policy-state/group-policy-state';
import {
  GPDetailClusterSubStateManager,
  GPEditClusterSubStateManager
} from '../../group-policy-state/group-policy-sub-statemanagers/group-policy-cluster-sub-statemanager';
import {
  GpConfirmationEvent
} from '../group-policy-modals/group-policy-modal-confirmation/gp-confirmation-modal.component';
import {UpdateUnitEvent} from '../group-policy-units/group-policy-unit.component';

@Component({
  selector: 'rgi-gp-group-policy-clusters',
  templateUrl: './group-policy-clusters.component.html'
})
export class GroupPolicyClustersComponent implements OnInit {

  @Input() codeAsset: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() stateManagerCluster: GPEditClusterSubStateManager<any> | GPDetailClusterSubStateManager<any>;
  stateManagerEditCluster: GPEditClusterSubStateManager<any>;

  @Input() unitInitialStatuses: BaseEntity[];
  @Input() accordionMap: Map<string, boolean>;
  @Input() editMode = true;
  @Input() showApplicationList = true;
  @Output() reloadAssets = new EventEmitter<boolean>();
  @ViewChild(RgiRxDatatableComponent, { static: true }) dt: RgiRxDatatableComponent;


  public CLUSTER_LIST_TABLE_SCHEMA;
  public get clusterListTableData() {
    return this.lightClusters$.asObservable();
  }

  public clusterDataTableExpansionModel = new RgiRxDataTableExpansionModel([], {
    multiple: true,
    canExpand: (_row) => true,
    canContract: this.isRowInEditMode()
  });

  protected previousStepData: DataForSteps;
  public assetVariablesFGMap = new Map<string, UntypedFormGroup>();

  public editableClusterFormGroup: UntypedFormGroup;

  public stateCluster: GroupPolicyStateCluster;
  public showCreateClusterBtn: boolean;
  public showEditableClusterForm: boolean;
  private lightClusters$ = new BehaviorSubject<any>([]);


  constructor(
    public activeRoute: ActiveRoute,
    public variableService: GPVariableService,
    public dateAdapter: GroupPolicyDateAdapter,
    protected pushMessageHandler: PushMessageHandlerService,
    protected crossService: GroupPolicyCrossService,
    protected gpCommBtnService: GpCommissionDiscountBtnsService) {
  }

  ngOnInit() {
    this.previousStepData = this.activeRoute.getRouteData();

    this.stateManagerCluster.getState$()
      .subscribe((_nextState) => {
        this.stateCluster = this.stateManagerCluster.initStateCluster(this.codeAsset, this.previousStepData);
        this.fillClusterListTableData();
        if (this.editMode) {
          this.stateManagerEditCluster = this.stateManagerCluster as GPEditClusterSubStateManager<any>;
          this.stateManagerEditCluster.getClasses$(this.stateCluster).subscribe();
          this.showCreateClusterBtn = true;
          this.CLUSTER_LIST_TABLE_SCHEMA = CLUSTER_LIST_TABLE_SCHEMA;
        } else {
          this.CLUSTER_LIST_TABLE_SCHEMA = CLUSTER_LIST_DETAIL_TABLE_SCHEMA;
        }
      });


    this.clusterDataTableExpansionModel.getChanges$().subscribe(changes => {
      this.manageExpansionRows(changes);
    });
  }

  protected fillClusterListTableData() {
    this.lightClusters$.next(this.stateCluster.clusters.map(cluster => {
      return {
        clusterNumber: cluster.code,
        clusterName: cluster.name,
        clusterOwner: cluster.owner,
        clusterStatusCode: cluster.status.code,
        clusterStatusDescription: cluster.status.description
      };
    }));
  }

  protected updateClusterListTableData(): Observable<any> {
    this.fillClusterListTableData();
    return this.clusterListTableData.pipe(take(1), tap(next => this.dt.dataSource.update(next)));
  }

  createCluster() {
    this.pushMessageHandler.clearTag('assetClusters' + this.codeAsset + 'edit');
    this.stateManagerEditCluster.actionCreateCluster$(this.stateCluster).subscribe(_resp => {
      this.showEditableClusterForm = true;
      this.createForm();
      this.pushNewClustertoList();
      this.updateClusterListTableData().subscribe(next => {
        const row = next.find(el => el.clusterNumber === this.stateCluster.editableCluster.cluster.code);
        this.expandDataTableRow(row);
      });
      this.showCreateClusterBtn = false;
    });
  }

  protected pushNewClustertoList() {
    this.stateCluster.clusters.push({
      code: this.stateCluster.editableCluster.cluster.code,
      name: this.stateCluster.editableCluster.cluster.name,
      description: this.stateCluster.editableCluster.cluster.description,
      owner: this.stateCluster.editableCluster.cluster.owner ? this.stateCluster.editableCluster.cluster.owner.description : null,
      status: this.stateCluster.editableCluster.cluster.status
    });
  }

  protected alignClusterList() {
    this.stateCluster.clusters.forEach(cluster => {
      if (cluster.code === this.stateCluster.editableCluster.cluster.code) {
        cluster.name = this.stateCluster.editableCluster.cluster.name;
        cluster.description = this.stateCluster.editableCluster.cluster.description;
        cluster.owner = this.stateCluster.editableCluster.cluster.owner ?
          this.stateCluster.editableCluster.cluster.owner.description : null;
        cluster.status = this.stateCluster.editableCluster.cluster.status;
      }
    });
  }

  createForm() {
    this.editableClusterFormGroup = new UntypedFormGroup({});
    this.editableClusterFormGroup.addControl('unitSelection', new UntypedFormControl(this.atLeastOneUnitSelected(), Validators.requiredTrue));
    this.editableClusterFormGroup.addControl('clusterName', new UntypedFormControl(
      this.stateCluster.editableCluster.cluster.name,
      { validators: Validators.required, updateOn: 'blur' }
    ));
    this.editableClusterFormGroup.addControl('clusterDescription', new UntypedFormControl(
      this.stateCluster.editableCluster.cluster.description,
      { validators: Validators.required, updateOn: 'blur' }
    ));
    this.editableClusterFormGroup.addControl('clusterOwner', new UntypedFormControl(
      this.setOwnerField()
    ));
    this.editableClusterFormGroup.addControl('clusterClass', new UntypedFormControl(
      this.setClassField(),
      Validators.required
    ));
    this.editableClusterFormGroup.addControl('clusterUses', new UntypedFormControl(
      this.setUseField(),
      Validators.required
    ));
    this.editableClusterFormGroup.addControl('clusterSectors', new UntypedFormControl(
      this.setSectorField(),
      Validators.required
    ));
    this.editableClusterFormGroup.addControl('clusterExtensions', new UntypedFormControl(
      this.setExtensionsField()
    ));


    this.editableClusterFormGroup.get('clusterName').valueChanges.subscribe(clusterName => {
      this.editableClusterFormGroup.updateValueAndValidity({ emitEvent: false });
      this.stateCluster.editableCluster.cluster.name = clusterName;
      this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup).subscribe();
    });

    this.editableClusterFormGroup.get('clusterDescription').valueChanges.subscribe(clusterDescription => {
      this.editableClusterFormGroup.updateValueAndValidity({ emitEvent: false });
      this.stateCluster.editableCluster.cluster.description = clusterDescription;
      this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup).subscribe();
    });

    if (this.stateCluster.editableCluster.cluster.hasApplications) {
      this.editableClusterFormGroup.get('clusterOwner').disable();
      this.editableClusterFormGroup.get('clusterClass').disable({ emitEvent: false });
      this.editableClusterFormGroup.get('clusterUses').disable({ emitEvent: false });
      this.editableClusterFormGroup.get('clusterSectors').disable({ emitEvent: false });
      this.editableClusterFormGroup.get('clusterExtensions').disable({ emitEvent: false });
    } else {
      this.editableClusterFormGroup.get('clusterClass').valueChanges.subscribe(assetClass => {
        this.stateCluster.uses = null;
        this.stateCluster.sectors = null;
        this.stateCluster.extensions = null;
        this.stateCluster.editableCluster.cluster.class = this.stateCluster.classes.find(el => el.code === assetClass);
        this.stateCluster.editableCluster.cluster.use = null;
        this.stateCluster.editableCluster.cluster.sector = null;
        this.editableClusterFormGroup.get('clusterUses').reset(null, { emitEvent: false });
        this.editableClusterFormGroup.get('clusterSectors').reset(null, { emitEvent: false });
        this.editableClusterFormGroup.get('clusterExtensions').reset(null, { emitEvent: false });
        this.editableClusterFormGroup.updateValueAndValidity({ emitEvent: false });
        this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup).subscribe(_res => {
          this.stateManagerEditCluster.getUses$(this.stateCluster).subscribe(() => {
            if (this.stateCluster.uses && this.stateCluster.uses.length === 1) {
              this.editableClusterFormGroup.get('clusterUses').setValue(this.stateCluster.uses[0].code);
            }
          });
        });
      });

      this.editableClusterFormGroup.get('clusterUses').valueChanges.subscribe(assetUse => {
        this.stateCluster.sectors = null;
        this.stateCluster.editableCluster.cluster.use = this.stateCluster.uses.find(el => el.code === assetUse);
        this.stateCluster.editableCluster.cluster.sector = null;
        this.stateCluster.editableCluster.cluster.extension = null;
        this.editableClusterFormGroup.get('clusterSectors').reset(null, { emitEvent: false });
        this.editableClusterFormGroup.get('clusterExtensions').reset(null, { emitEvent: false });
        this.editableClusterFormGroup.updateValueAndValidity({ emitEvent: false });
        this.stateManagerEditCluster.getSectors$(this.stateCluster).subscribe(() => {
          if (this.stateCluster.sectors && this.stateCluster.sectors.length === 1) {
            this.editableClusterFormGroup.get('clusterSectors').setValue(this.stateCluster.sectors[0].code);
          }
        });
        this.stateManagerEditCluster.getExtensions$(this.stateCluster).subscribe(() => {
          if (this.stateCluster.extensions) {
            if (this.stateCluster.extensions.length > 0) {
              this.editableClusterFormGroup.get('clusterExtensions').setValidators(Validators.required);
            } else {
              this.editableClusterFormGroup.get('clusterExtensions').clearValidators();
            }
            this.editableClusterFormGroup.get('clusterExtensions').updateValueAndValidity({ emitEvent: false });
            if (this.stateCluster.extensions.length === 1) {
              this.editableClusterFormGroup.get('clusterExtensions').setValue(this.stateCluster.extensions[0].code);
            }
          }
          this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup).subscribe();
        });
      });

      this.editableClusterFormGroup.get('clusterSectors').valueChanges.subscribe(assetsSector => {
        this.stateCluster.editableCluster.cluster.sector = this.stateCluster.sectors.find(el => el.code === assetsSector);
        this.editableClusterFormGroup.updateValueAndValidity({ emitEvent: false });
        this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup, false).subscribe(
          () => {
            this.getClusterDetail(this.stateCluster.editableCluster.cluster.code);
          }
        );
      });

      this.editableClusterFormGroup.get('clusterExtensions').valueChanges.subscribe(assetsExtension => {
        this.stateCluster.editableCluster.cluster.extension = this.stateCluster.extensions.find(el => el.code === assetsExtension);
        this.editableClusterFormGroup.updateValueAndValidity({ emitEvent: false });
        this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup, false).subscribe(
          () => {
            this.getClusterDetail(this.stateCluster.editableCluster.cluster.code);
          }
        );
      });
    }
    this.parentForm.addControl(this.stateCluster.codeAsset + '-' +
      this.stateCluster.editableCluster.cluster.code, this.editableClusterFormGroup);
    this.createAssetVariablesFormGroup(this.stateCluster.editableCluster.cluster.variables,
      this.stateCluster.codeAsset + this.stateCluster.editableCluster.cluster.code);
  }

  protected setOwnerField() {
    let ownerField = null;
    if (this.stateCluster.editableCluster.cluster.owner && this.stateCluster.editableCluster.cluster.owner.idParty) {
      ownerField = {
        nominative: this.stateCluster.editableCluster.cluster.owner.description,
      };
    }
    return ownerField;
  }

  protected setClassField() {
    return this.stateCluster.editableCluster.cluster.class && this.stateCluster.editableCluster.cluster.class.code ?
      this.stateCluster.editableCluster.cluster.class.code : null;
  }

  protected setUseField() {
    return this.stateCluster.editableCluster.cluster.use && this.stateCluster.editableCluster.cluster.use.code ?
      this.stateCluster.editableCluster.cluster.use.code : null;
  }

  protected setSectorField() {
    return this.stateCluster.editableCluster.cluster.sector && this.stateCluster.editableCluster.cluster.sector.code ?
      this.stateCluster.editableCluster.cluster.sector.code : null;
  }

  protected setExtensionsField() {
    return this.stateCluster.editableCluster.cluster.extension && this.stateCluster.editableCluster.cluster.extension.code ?
      this.stateCluster.editableCluster.cluster.extension.code : null;
  }

  protected createAssetVariablesFormGroup(variables: Factor[], assetCode: string) {
    let formGroup: UntypedFormGroup = this.assetVariablesFGMap.get(assetCode);
    if (!formGroup) {
      formGroup = this.variableService.createVariablesFormGroup(variables);
      this.parentForm.addControl(assetCode + '_VARIABLES', formGroup);
      this.assetVariablesFGMap.set(assetCode, formGroup);
    } else {
      this.variableService.updateVariablesFormGroup(variables, formGroup);
    }
  }

  getSubjectDropContainerData() {
    const data = new AnagFlowData();
    data.partyRole = '3';
    data.idParentSession = this.stateCluster.idParentSession;
    data.nodeCode = this.stateCluster.nodeId;
    return data;
  }

  onPartySelected($event: RgiRxOnContainerValueChange) {
    this.stateCluster.editableCluster.cluster.owner = this.createOwnerToUpdate($event.changed as AnagApiParty);
    this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup).subscribe();
  }

  protected createOwnerToUpdate(party: AnagApiParty) {
    if (!!party) {
      return {
        idPartyLock: party.idLatestPhotos,
        idParty: party.objectId,
        description: party.nominative
      } as GPClusterParty;
    }
    return null;
  }

  deleteSubject() {
    this.editableClusterFormGroup.get('clusterOwner').reset();
    this.stateCluster.editableCluster.cluster.owner = null;
    this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup).subscribe();
  }

  updateUnit($event: UpdateUnitEvent, _editableCluster: GPCluster) {
    this.stateCluster.editableCluster.cluster.units = this.stateCluster.editableCluster.cluster.units.map(unit => {
      if (unit.unit.code === $event.unit.code &&
        unit.unit.riskCode === $event.unit.riskCode &&
        unit.unit.sectionCode === $event.unit.sectionCode) {
        unit.unit = $event.unit;
      }
      return unit;
    });
    this.editableClusterFormGroup.get('unitSelection').setValue(this.atLeastOneUnitSelected());
    this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup, false).subscribe();
  }

  onVariableUpdate(variableToUpdate: Factor, _editableCluster: GPCluster) {

    const variableControl = this.assetVariablesFGMap
      .get(this.stateCluster.codeAsset + this.stateCluster.editableCluster.cluster.code)
      .get(variableToUpdate.code);
    this.variableService.updateVariableInList(variableToUpdate, variableControl.value, this.stateCluster.editableCluster.cluster.variables);

    this.stateCluster.editableCluster.cluster.variables = this.stateCluster.editableCluster.cluster.variables.map(variable => {
      if (variable.code === variableToUpdate.code) {
        variable = variableToUpdate;
      }
      return variable;
    });
    this.stateManagerEditCluster.actionUpdateCluster$(this.stateCluster, this.editableClusterFormGroup, false).subscribe(() => {
      this.createAssetVariablesFormGroup(this.stateCluster.editableCluster.cluster.variables,
        this.stateCluster.codeAsset + this.stateCluster.editableCluster.cluster.code);
    });
  }

  saveCluster() {
    this.editableClusterFormGroup.markAllAsTouched();
    if (this.atLeastOneUnitSelected()) {
      if (this.editableClusterFormGroup.valid) {
        this.stateManagerEditCluster.actionCheckCluster$(this.stateCluster).pipe(
          tap(() => {
            this.alignClusterList();
            this.updateClusterListTableData().subscribe(next => {
              const row = next.find(el => el.clusterNumber === this.stateCluster.editableCluster.cluster.code);
              const index = this.stateCluster.openedClusters.findIndex(cluster =>
                cluster.cluster.code === this.stateCluster.editableCluster.cluster.code
              );
              this.stateCluster.openedClusters.splice(index, 1);
              this.contractDataTableRow(row);
              this.parentForm.removeControl(this.stateCluster.codeAsset + '-' + this.stateCluster.editableCluster.cluster.code);
            });
            this.reloadAssets.emit(this.stateCluster.editableCluster.isPartyEdited);
            this.pushMessageHandler.clearTag('assetClusters' + this.codeAsset + 'edit');

            this.stateCluster.editableCluster = null;
            this.stateCluster.uses = null;
            this.stateCluster.sectors = null;
            this.stateCluster.extensions = null;
            this.showCreateClusterBtn = true;
            this.showEditableClusterForm = false;
          })
        ).subscribe();
      }
    } else {
      this.pushMessageHandler.notify({
        content: '_GP_._MSG_._UNIT_SELECTION_WARNING_',
        dismissible: true,
        status: 'danger',
        tag: 'assetClusters' + this.codeAsset + 'edit'
      });
    }
  }

  protected atLeastOneUnitSelected(): boolean {
    return !!this.stateCluster.editableCluster.cluster.units.find(unitWrapper => unitWrapper.unit.selected);
  }

  public onActionClick($event: RgiRxDatatableRowAction<any>) {
    this.pushMessageHandler.clearTag('assetClusters' + this.codeAsset + 'edit');
    switch ($event.name) {
      case 'DELETE':
        let message = '_GP_._MSG_._CONFIRM_CLUSTER_CANCELLATION_';
        this.crossService.openConfirmationModal(message).subscribe(
          ((confirmationEvent: GpConfirmationEvent) => {
            if (confirmationEvent === GpConfirmationEvent.CONFIRM) {
              this.stateManagerEditCluster.actionDeleteCluster$(this.stateCluster, $event.row.clusterNumber).subscribe(_rest => {
                this.updateClusterListTableData().subscribe();
              });
              if (this.stateCluster.editableCluster && this.stateCluster.editableCluster.cluster.code === $event.row.clusterNumber) {
                this.showCreateClusterBtn = true;
                this.stateCluster.editableCluster = null;
                Object.keys(this.editableClusterFormGroup.controls).forEach(fc => {
                  this.editableClusterFormGroup.removeControl(fc);
                });
                this.editableClusterFormGroup.updateValueAndValidity({ emitEvent: false });
              }
            }
          })
        );
        break;
      case 'EDIT':
        if (!this.stateCluster.editableCluster) {
          this.stateManagerEditCluster.actionEditCluster$(this.stateCluster, $event.row.clusterNumber).subscribe(_resp => {
            this.showEditableClusterForm = true;
            this.createForm();
            this.expandDataTableRow($event.row);
            this.retrieveClassesUsesSectors();
            this.showCreateClusterBtn = false;
          });
        }
        break;
      case 'COPY':
        if (!this.stateCluster.editableCluster) {
          this.stateManagerEditCluster.actionCopyCluster$(this.stateCluster, $event.row.clusterNumber).subscribe(_resp => {
            this.pushNewClustertoList();
            this.updateClusterListTableData().subscribe(next => {
              const row = next.find(el => el.clusterNumber === this.stateCluster.editableCluster.cluster.code);
              this.expandDataTableRow(row);
            });

            this.retrieveClassesUsesSectors();
            this.createForm();
            this.showEditableClusterForm = true;
            this.showCreateClusterBtn = false;
          });
        }
        break;
      default:
        break;

    }

  }

  protected isRowInEditMode(): RgiRxTableExpansionTrigger {
    return (row: any) => {
      const canClose = !this.stateCluster.editableCluster ||
        (row.clusterNumber !== this.stateCluster.editableCluster.cluster.code);
      if (!canClose) {
        this.editableClusterFormGroup.markAllAsTouched();
      }
      return canClose;
    };
  }

  protected expandDataTableRow(row) {
    if (!this.clusterDataTableExpansionModel.isExpanded(row)) {
      this.clusterDataTableExpansionModel.expand(row);
    }
  }

  protected contractDataTableRow(row) {
    if (this.clusterDataTableExpansionModel.isExpanded(row)) {
      this.clusterDataTableExpansionModel.contract(row);
    }
  }

  protected manageExpansionRows(changes: RgiRxTableExpansionChange<any>) {
    if (changes.opened && changes.opened.length && changes.closed.length === 0) {
      const clusterCode = changes.opened[0].clusterNumber;
      this.getClusterDetail(clusterCode);
    } else if (changes.closed.length && !changes.opened.length) {
      const closedClusterIndex = this.stateCluster.openedClusters.findIndex(
        c => c.cluster.code === changes.closed[0].clusterNumber);
      this.stateCluster.openedClusters.splice(closedClusterIndex, 1);
    }
  }

  getClusterDetail(clusterCode: any) {
    this.stateManagerCluster.getClusterDetail$(this.stateCluster, clusterCode).subscribe(st => {
      if (st.openedClusters.length === 5) {
        const removedClusterCode = st.openedClusters[0].cluster.code;
        this.clusterListTableData.pipe(take(1)).subscribe(next => {
          const row = next.find(el => el.clusterNumber === removedClusterCode);
          this.clusterDataTableExpansionModel.contract(row);
        });
      }
    });
  }

  protected retrieveClassesUsesSectors() {
    if (this.stateCluster.editableCluster.cluster.class && this.stateCluster.editableCluster.cluster.class.code) {
      this.stateManagerEditCluster.getUses$(this.stateCluster).subscribe(_resp => {
        if (this.stateCluster.editableCluster.cluster.use && this.stateCluster.editableCluster.cluster.use.code) {
          this.stateManagerEditCluster.getSectors$(this.stateCluster).subscribe();
        }
        if (this.stateCluster.editableCluster.cluster.extension && this.stateCluster.editableCluster.cluster.extension.code) {
          this.stateManagerEditCluster.getExtensions$(this.stateCluster).subscribe();
          this.editableClusterFormGroup.get('clusterExtensions').setValidators(Validators.required);
        }
      });
    }
  }

  getCachedClusterByCode(code: string) {
    return this.stateCluster.openedClusters.find(c => c.cluster.code === code);
  }

  onUpdateAssetClauses(clauses: GPClause[]) {
    this.stateManagerEditCluster.actionUpdateAssetClauses$(this.stateCluster, clauses).subscribe();
  }

  onUpdateAssetUnitClauses(unitClause: GpUnitClause) {
    this.stateManagerEditCluster.actionUpdateUnitClauses$(this.stateCluster, unitClause).subscribe();
  }

  public openCommissionsModal() {
    this.stateManagerEditCluster.actionOpenClusterCommissionsModal(this.stateCluster).subscribe();
  }

  get isCommDiscountBtnEnabled(): boolean {
    return this.gpCommBtnService.isClusterCommDiscountBtnEnabled(this.stateCluster);
  }

  get isCommDiscountBtnVisible(): boolean {
    return this.gpCommBtnService.isClusterCommissionBtnVisible();
  }

  get commDiscountBtnLabel(): string {
    return this.gpCommBtnService.getClusterCommissionBtnLabel();
  }

  trackUnit(_index: number, unitWrapper: Units) {
    return unitWrapper.unit.code;
  }

  modifySubject($event) {
    if (!!$event.data && !!$event.data.objectId) {
      /* First time when the owner is empty and the party is just been selected */
      this.stateCluster.editableCluster.cluster.owner = this.createOwnerToUpdate($event.data);
    }
    /* The owner is already present on the state */
    this.stateManagerEditCluster
      .actionModifyOwner$(this.stateCluster, this.stateCluster.editableCluster.cluster.owner.idParty, this.activeRoute)
      .subscribe(_st => {
        this.createForm();
      }
      );
  }
}

import {RegulationPremium} from './pc-regulation-premium';
import {ErrorMessage} from '../GenericErrorPcPortfolio';

export class RegulationPremiumResponse {
  regulationPremium: RegulationPremium;
  messages: Array<ErrorMessage>;

  constructor(regulationPremium: RegulationPremium,
              messages: Array<ErrorMessage>
  ) {
    this.regulationPremium = regulationPremium;
    this.messages = messages;
  }
}

import {PortalNgCommonTranslationKeys} from './translation.keys';

export const IT: PortalNgCommonTranslationKeys = {
    RGI_PORTAL_COMPONENTS: {
      ADD: 'Aggiungi',
      ADD_DOCUMENT: 'Aggiungi documento',
      CANCEL: 'Cancella',
      CODE: 'Codice',
      CONFIRM: 'Conferma',
      DELETE_DOC: 'Cancella documento',
      DOCUMENTS_MANAGER: 'Gestione Documenti',
      DOCUMENT_TYPE: 'Tipo documento',
      DOCUMENT_VALIDATION_MESSAGE: 'Procedere con la validazione del documento?',
      DOWNLOAD: 'DOWNLOAD',
      EDIT: 'Modifica',
      FILE: 'File',
      INPUT_DOCUMENTS: 'Documenti Input',
      LOAD: 'CARICA',
      NO: 'No',
      NOTE: 'Note',
      NO_FILES: 'Nessun file',
      NUMBER_OF_FILES_INSERTED: '{{ filesLength }} di {{ fileMax }} files inseriti',
      OUTPUT_DOCUMENTS: 'Documenti Output',
      SAVE: 'SALVA',
      STATE: 'Stato',
      UPDATE_FILE: 'Modifica file',
      UPLOAD: 'UPLOAD',
      UPLOAD_NEW_DOCUMENT: 'Upload nuovo documento',
      VALIDATION: 'Validazione',
      VALIDATION_DOCUMENT: 'Validazione documento',
      YES: 'Sì',
    }
};

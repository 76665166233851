import {
  ChangeDetectionStrategy,
  Component,
  forwardRef, HostBinding,
  Input, OnChanges, SimpleChange, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

let nextUniqueId = 0;

@Component({
  selector: 'pnc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class CheckboxComponent implements ControlValueAccessor {

  @Input() required: boolean;
  @Input() name: string;

  @Input()
  set disabled(state: boolean) {
    this.isDisabled = state;
    this.isDisabledClass = this.isDisabled;
  }

  get disabled() {
    return this.isDisabled;
  }

  @Input()
  set checked(state: boolean) {
    this.isChecked = state;
    this.isCheckedClass = this.isChecked;
  }

  get checked() {
    return this.isChecked;
  }

  isDisabled: boolean;
  isChecked: boolean;

  id: string;

  @HostBinding ('class.checkbox-checked') isCheckedClass: boolean;
  @HostBinding ('class.checkbox-disabled') isDisabledClass: boolean;

  private onChange = (_: any) => { };

  constructor() {
    this.id = `checkbox-${++nextUniqueId}`;
    this.isChecked = false;
    this.isDisabledClass = this.disabled;
    this.isCheckedClass = this.checked;
  }

  public onClick(event: Event) {
    event.stopPropagation();
    if (!this.disabled) {
      this.isChecked = !this.isChecked;
      this.isCheckedClass = this.isChecked;
      this.onChange(this.isChecked);
    }
  }

  public onInteraction(event: Event) {
    // We always have to stop propagation on the change event.
    // This event bubbles up and changes the value again
    event.stopPropagation();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.isDisabledClass = this.disabled;
  }

  writeValue(obj: boolean): void {
    this.isChecked = !!obj;
    this.isCheckedClass = this.isChecked;
  }
}

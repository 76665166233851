import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcStaticLabelComponent} from './lpc-static-label/lpc-static-label.component';


@NgModule({
  declarations: [LpcStaticLabelComponent],
  exports: [
    LpcStaticLabelComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class LpcStaticLabelModule { }

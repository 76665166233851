export const RGI_RX_DATEPICKER_i18n = {
  it: {
    RGI_RX: {
      DATEPICKER: {
        UP_SECOND_LABEL: 'Aggiungi un secondo',
        DOWN_SECOND_LABEL: 'Rimuovi un secondo',
        UP_MINUTE_LABEL: 'Aggiungi un minuto',
        DOWN_MINUTE_LABEL: 'Rimuovi un minuto',
        UP_HOUR_LABEL: `Aggiungi un'ora`,
        DOWN_HOUR_LABEL: `Rimuovi un'ora`,
        PREV_MONTH_LABEL: 'Mese precendente',
        NEXT_MONTH_LABEL: 'Mese successive',
        PREV_YEAR_LABEL: 'Anno precendente',
        NEXT_YEAR_LABEL: 'Anno Successivo',
        PREV_MULTI_YEAR_LABEL: '21 anni precendenti',
        NEXT_MULTI_YEAR_LABEL: '21 anni successivi',
        SWITCH_MONTH_VIEW_LABEL: 'Passa alle vista mese',
        SWITCH_TO_MULTI_YEAR_VIEW_LABEL: `Scegli il messe e l'anno`,
        CANCEL_BTN_LABEL: 'Cancella',
        SET_BTN_LABEL: 'Seleziona',
        RANGE_FROM_LABEL: 'Da',
        RANGE_TO_LABEL: 'A',
        HOUR_12_AM_LABEL: 'AM',
        HOUR_12_PM_LABEL: 'PM',
        PARSE_ERROR: 'Formato della data invalido',
        RANGE_ERROR: `La data è fuori dall'arco consentito`,
        FILTER_ERROR: 'La data non è consentita',
        EXCEED_MAX_ERROR: 'La data {{actual}} è maggiore del massimo consentito {{max}}',
        EXCEED_MIN_ERROR: 'La data {{actual}} è minore del minimo consentito {{min}}'
      }
    }
  },
  en: {
    RGI_RX: {
      DATEPICKER: {
        UP_SECOND_LABEL: 'Add a second',
        DOWN_SECOND_LABEL: 'Minus a second',
        UP_MINUTE_LABEL: 'Add a minute',
        DOWN_MINUTE_LABEL: 'Minus a minute',
        UP_HOUR_LABEL: 'Add a hour',
        DOWN_HOUR_LABEL: 'Minus a hour',
        PREV_MONTH_LABEL: 'Previous month',
        NEXT_MONTH_LABEL: 'Next month',
        PREV_YEAR_LABEL: 'Previous year',
        NEXT_YEAR_LABEL: 'Next year',
        PREV_MULTI_YEAR_LABEL: 'Previous 21 years',
        NEXT_MULTI_YEAR_LABEL: 'Next 21 years',
        SWITCH_MONTH_VIEW_LABEL: 'Change to month view',
        SWITCH_TO_MULTI_YEAR_VIEW_LABEL: 'Choose month and year',
        CANCEL_BTN_LABEL: 'Cancel',
        SET_BTN_LABEL: 'Set',
        RANGE_FROM_LABEL: 'From',
        RANGE_TO_LABEL: 'To',
        HOUR_12_AM_LABEL: 'AM',
        HOUR_12_PM_LABEL: 'PM',
        PARSE_ERROR: 'Invalid date format',
        RANGE_ERROR: 'Date is not in accepted range',
        FILTER_ERROR: 'Date is not allowed',
        EXCEED_MAX_ERROR: 'Date {{actual}} is greater than the maximum allowed {{max}}',
        EXCEED_MIN_ERROR: 'Date {{actual}} is less than the minimum allowed {{min}}'
      }
    }
  },
  es: {
    RGI_RX: {
      DATEPICKER: {
        UP_SECOND_LABEL: 'Agregar un segundo',
        DOWN_SECOND_LABEL: 'Menos un segundo',
        UP_MINUTE_LABEL: 'Agregar un minuto',
        DOWN_MINUTE_LABEL: 'Menos un minuto',
        UP_HOUR_LABEL: 'Agregar una hora',
        DOWN_HOUR_LABEL: 'Menos una hora',
        PREV_MONTH_LABEL: 'Mes anterior',
        NEXT_MONTH_LABEL: 'Próximo mes',
        PREV_YEAR_LABEL: 'Año anterior',
        NEXT_YEAR_LABEL: 'Próximo año',
        PREV_MULTI_YEAR_LABEL: '21 años anteriores',
        NEXT_MULTI_YEAR_LABEL: 'Próximos 21 años',
        SWITCH_MONTH_VIEW_LABEL: 'Cambiar a vista de mes',
        SWITCH_TO_MULTI_YEAR_VIEW_LABEL: 'Elegir mes y año',
        CANCEL_BTN_LABEL: 'Cancelar',
        SET_BTN_LABEL: 'Conjunto',
        RANGE_FROM_LABEL: 'Desde',
        RANGE_TO_LABEL: 'Hasta',
        HOUR_12_AM_LABEL: 'AM',
        HOUR_12_PM_LABEL: 'PM',
        EXCEED_MAX_ERROR: 'Fecha {{actual}} es mayor que el máximo permitido {{max}}',
        EXCEED_MIN_ERROR: 'Fecha {{actual}} es inferior al mínimo permitido {{min}}',
        PARSE_ERROR: 'Formato de fecha inválido',
        RANGE_ERROR: 'La fecha no está en el rango aceptado',
        FILTER_ERROR: 'No se permite fecha',
      }
    }
  },
  fr: {
    RGI_RX: {
      DATEPICKER: {
        UP_SECOND_LABEL: 'Ajouter une seconde',
        DOWN_SECOND_LABEL: 'Moins une seconde',
        UP_MINUTE_LABEL: 'Ajouter une minute',
        DOWN_MINUTE_LABEL: 'Moins une minute',
        UP_HOUR_LABEL: 'Ajouter une heure',
        DOWN_HOUR_LABEL: 'Moins une heure',
        PREV_MONTH_LABEL: 'Mois précédent',
        NEXT_MONTH_LABEL: 'Mois prochain',
        PREV_YEAR_LABEL: 'Année précédente',
        NEXT_YEAR_LABEL: 'Année prochaine',
        PREV_MULTI_YEAR_LABEL: '21 années précédentes',
        NEXT_MULTI_YEAR_LABEL: '21 prochaines années',
        SWITCH_MONTH_VIEW_LABEL: 'Passer à la vue mensuelle',
        SWITCH_TO_MULTI_YEAR_VIEW_LABEL: `Choisir le mois et l'année`,
        CANCEL_BTN_LABEL: 'Annuler',
        SET_BTN_LABEL: 'Définir',
        RANGE_FROM_LABEL: 'De',
        RANGE_TO_LABEL: 'À',
        HOUR_12_AM_LABEL: 'AM',
        HOUR_12_PM_LABEL: 'PM',
        EXCEED_MAX_ERROR: 'Date {{actual}} est inférieure au minimum autorisé {{max}}',
        EXCEED_MIN_ERROR: 'Date {{actual}} est supérieure au maximum autorisé {{min}}',
        PARSE_ERROR: 'Format de date invalide',
        RANGE_ERROR: `La date n'est pas dans la plage acceptée`,
        FILTER_ERROR: `La date n'est pas autorisée`
      }
    }
  },
  de: {
    RGI_RX: {
      DATEPICKER: {
        UP_SECOND_LABEL: 'Einen zweiten hinzufügen',
        DOWN_SECOND_LABEL: 'Minus eine Sekunde',
        UP_MINUTE_LABEL: 'Minute hinzufügen',
        DOWN_MINUTE_LABEL: 'Minus eine Minute',
        UP_HOUR_LABEL: 'Eine Stunde hinzufügen',
        DOWN_HOUR_LABEL: 'Minus eine Stunde',
        PREV_MONTH_LABEL: 'Vorheriger Monat',
        NEXT_MONTH_LABEL: 'Nächster Monat',
        PREV_YEAR_LABEL: 'Vorheriges Jahr',
        NEXT_YEAR_LABEL: 'Nächstes Jahr',
        PREV_MULTI_YEAR_LABEL: 'Letzte 21 Jahre',
        NEXT_MULTI_YEAR_LABEL: 'Nächste 21 Jahre',
        SWITCH_MONTH_VIEW_LABEL: 'Zur Monatsansicht wechseln',
        SWITCH_TO_MULTI_YEAR_VIEW_LABEL: `Monat und Jahr wählen`,
        CANCEL_BTN_LABEL: 'Abbrechen',
        SET_BTN_LABEL: 'Setzen',
        RANGE_FROM_LABEL: 'Von',
        RANGE_TO_LABEL: 'An',
        HOUR_12_AM_LABEL: 'AM',
        HOUR_12_PM_LABEL: 'PM',
        EXCEED_MAX_ERROR: 'Datum {{actual}} liegt unter dem zulässigen Minimum {{max}}',
        EXCEED_MIN_ERROR: 'Datum {{actual}} ist größer als das zulässige Maximum {{min}}',
        PARSE_ERROR: 'ungültiges Datumsformat',
        RANGE_ERROR: `Das Datum liegt nicht im akzeptierten Bereich`,
        FILTER_ERROR: 'Datum ist nicht erlaubt'
      }
    }
  }
};

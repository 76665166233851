import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LicExtractFromArrayPipe } from './lic-extract-from-array.pipe';

@NgModule({
    declarations: [LicExtractFromArrayPipe],
    imports: [CommonModule],
    exports: [LicExtractFromArrayPipe]
})
export class LicExtractFromArrayPipeModule {
}

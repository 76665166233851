import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MicHttpHandlerService} from './mic-http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MicHttpService {

  protected httpClient: HttpClient;

  constructor(
    protected httpHandler: MicHttpHandlerService
  ) {
    this.httpClient = new HttpClient(httpHandler);
  }

  getHttpClient(): HttpClient {
    return this.httpClient;
  }
}

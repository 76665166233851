import {CommonModule} from '@angular/common';
import {Inject, NgModule} from '@angular/core';
import {
  GroupPolicyIntegrationService,
  GroupPolicyActionIntegrationService,
  GroupPolicyModule,
} from '@rgi/group-policy';
import {PortalConfig, RGI_RX_PORTAL_CONFIG, RgiRxPortalModule} from '@rgi/rx/portal';
import {GroupPolicyPortalIntegrationService} from './group-policy-portal-integration.service';
import {
  angularGridInstance, downgradeGroupPolicy,
  GROUP_POLICY_CARDS,
  manageNewCardInPassPortal
} from './group-policy.card.configurations';
import {GroupPolicyPortalActionIntegrationService} from './group-policy-portal-action-integration.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GroupPolicyModule,
    RgiRxPortalModule.forRoot(GROUP_POLICY_CARDS)
  ],
  providers: [
    { provide: GroupPolicyIntegrationService, useClass: GroupPolicyPortalIntegrationService },
    { provide: GroupPolicyActionIntegrationService, useClass: GroupPolicyPortalActionIntegrationService},
    angularGridInstance
  ]
})

export class GroupPolicyPortalModule {
  constructor(@Inject(RGI_RX_PORTAL_CONFIG) config: PortalConfig) {
    downgradeGroupPolicy(config);
    manageNewCardInPassPortal(config);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PPEVONavigationBarComponent} from './navigation-button/navigation-bar.component';
import {PPEVONavigationBtnDirective} from './navigation-button/navigation-btn.directive';
import { RgiRxStickableModule } from '@rgi/rx/ui';


@NgModule({
  declarations: [
    PPEVONavigationBarComponent,
    PPEVONavigationBtnDirective
  ],
  imports: [
    CommonModule,
    RgiRxStickableModule
  ],
  exports: [PPEVONavigationBarComponent, PPEVONavigationBtnDirective]
})
export class NavigationModule {
}


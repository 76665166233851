import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { forkJoin, Observable, of, Subject as SubjectRxjs } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DocSubject, PolicyRole } from '../models/policy.model';
import { Subject } from '../models/subject.model';
import { Roles } from './../../life-card/enum/life-issue.enum';
import { LifeSessionService } from './life-session-service';
import { PartyCompleted } from '../models/party-service.model';
import { AnagFlowData } from '@rgi/anag';

@Injectable({
    providedIn: 'root'
})
export class LifeRoleService implements OnInit {

    protected policyHolder: PolicyRole;
    protected policyInsured: PolicyRole;
    protected policyThirdRef: PolicyRole;
    protected policyAssignmentHolder: PolicyRole;
    protected policyEmployer: PolicyRole;
    protected policyCompanyPensionForm: PolicyRole;
    protected secondPolicyInsured: PolicyRole;

    validationMessages: string[] = [];
    policyRoles: PolicyRole[];

    holderSubj: Subject;
    cosignedSubj: Subject;
    employer: Subject;

    get hasPrevalPolicyHolder(): boolean {
      return !!this.getPolicyHolder();
    }

    storedPolicyRoles: PolicyRole[];
    storedSubjectMap = new Map<string, Subject>();

    private endOfReload = new SubjectRxjs<any>();

    private observable: SubjectRxjs<Subject> = new SubjectRxjs<Subject>();

    constructor(private httpClient: HttpClient,
                private lifeSessionService: LifeSessionService) {
    }

    ngOnInit() {
    }

    storeSubject(subjectFromAnag: Subject) {
      if (!!subjectFromAnag) {
        this.storedSubjectMap.set(subjectFromAnag.objectId.toString(), subjectFromAnag);
      }
    }

    getStoredSubject(id: string): Subject {
      return this.storedSubjectMap.get(id.toString());
    }

    getStoredSubjectFromCF(cf: string): Subject {
      let found = null;
      this.storedSubjectMap.forEach((v: Subject, k: string) => {
        if (!found && v.fiscalCode === cf) {
          found = v;
        }
      });
      return found;
    }

    getPolicyHolder(): PolicyRole {
      if (!!this.policyHolder) {
        const ph = this.getStoredSubject(this.policyHolder.objectId);
        this.policyHolder = this.getRoleDescription(new PolicyRole(Roles.CONTRACTOR, ph));
      }
      return this.policyHolder;
    }

    public getPolicyRolesFilled(validationService): PolicyRole[] {
      const prf: PolicyRole[] = [...validationService.policyRolesFilled.values()];
      const policyH: PolicyRole = this.getPolicyHolder();
      if (!!policyH && !prf.find(p => p.objectId === policyH.objectId)) {
        prf.push(policyH);
      }
      return prf;
    }

    public getAllPolicyRolesFilled(validationService): PolicyRole[] {
      const prf: PolicyRole[] = [...validationService.policyRolesFilled.values()];
      const policyH: PolicyRole = this.getPolicyHolder();
      if(!!policyH && !prf.find(p=> p.partyRole === policyH.partyRole)){
        prf.push(policyH);
      }
      return prf;
    }

    getSubjectHolder(): Subject {
      if (!!this.policyHolder) {
        return this.getStoredSubject(this.policyHolder.objectId);
      } else {
        return null;
      }
    }

    getPolicyHolderId(): string {
      return this.policyHolder ? this.policyHolder.objectId : null;
    }

    setPolicyHolder(value, role?: Roles) {
      if (!role) {
        role = Roles.CONTRACTOR;
      }
      if (value != null) {
        this.policyHolder = new PolicyRole(role, value);
        this.holderSubj = value;
      } else {
        this.policyHolder = null;
        this.holderSubj = null;
      }
    }

    getPolicyInsured() {
      if (!!this.policyInsured) {
        const pi = this.getStoredSubject(this.policyInsured.objectId);
        this.policyInsured = new PolicyRole(Roles.INSURED, pi);
      }
      return this.policyInsured;
    }

    setPolicyInsured(value: Subject) {
      if (value != null) {
        this.policyInsured = this.getRoleDescription(new PolicyRole(Roles.INSURED, value));
      } else {
        this.policyInsured = null;
      }
    }

    getSecondPolicyInsured() {
      if (!!this.secondPolicyInsured) {
        const subj = this.getStoredSubject(this.secondPolicyInsured.objectId);
        this.secondPolicyInsured =  this.getRoleDescription(new PolicyRole(Roles.SECOND_INSURED, subj));
      }
      return this.secondPolicyInsured;
    }

    setSecondPolicyInsured(value: Subject) {
      if (value != null) {
        this.secondPolicyInsured = new PolicyRole(Roles.SECOND_INSURED, value);
      } else {
        this.secondPolicyInsured = null;
      }
    }

    getPolicyThirdRef() {
      if (!!this.policyThirdRef) {
        const ptr = this.getStoredSubject(this.policyThirdRef.objectId);
        this.policyThirdRef = new PolicyRole(Roles.THIRDREFERENT, ptr);
      }
      return this.policyThirdRef;
    }

    setPolicyThirdRef(value: Subject) {
      if (value != null) {
        this.policyThirdRef = new PolicyRole(Roles.THIRDREFERENT, value);
      } else {
        this.policyThirdRef = null;
      }
    }

    getAssignmentHolder() {
      if (!!this.policyAssignmentHolder) {
        const ptr = this.getStoredSubject(this.policyAssignmentHolder.objectId);
        this.policyAssignmentHolder = new PolicyRole(Roles.VINCOLATARIO, ptr);
      }
      return this.policyAssignmentHolder;
    }

    setPolicyAssignmentHolder(value: Subject) {
      if (value != null) {
        this.policyAssignmentHolder = new PolicyRole(Roles.VINCOLATARIO, value);
      } else {
        this.policyAssignmentHolder = null;
      }
    }

    getPolicyByRole(role: Roles): PolicyRole {
      let pr = null;
      switch (role) {
        case Roles.EMPLOYER:
          pr = this.policyEmployer;
          break;
        case Roles.COMPANY_PENSION_FORM:
          pr = this.policyCompanyPensionForm;
          break;
      }

      if (!!pr) {
        pr = new PolicyRole(role, this.getStoredSubject(pr.objectId));
      }
      return pr;
    }

    setPolicyByRole(value: Subject, role: Roles) {
      let pr = null;
      if (value != null) {
        pr = new PolicyRole(role, value);
      }

      switch (role) {
        case Roles.EMPLOYER:
          this.policyEmployer = pr;
          break;
        case Roles.COMPANY_PENSION_FORM:
          this.policyCompanyPensionForm = pr;
          break;
      }
    }

    setPolicyInsuredEqualToPolicyHolder() {
      this.policyInsured = new PolicyRole(Roles.INSURED, this.holderSubj);
    }

    cleanPolicyHolder()  {
      this.policyHolder = null;
    }

    cleanInsured() {
      this.policyInsured = null;
    }

    cleanSecondInsured() {
      this.secondPolicyInsured = null;
    }

    cleanThirdRef() {
      this.policyThirdRef = null;
    }

    cleanAssignmentHolder() {
      this.policyAssignmentHolder = null;
    }

    cleanHolderSubj() {
      this.holderSubj = null;
    }

    resetService() {
        this.cleanPolicyHolder();
        this.cleanHolderSubj();
        this.policyRoles = [];
        this.cleanInsured();
        this.cosignedSubj = null;
        this.validationMessages = [];
        this.storedPolicyRoles = [];
        this.cleanThirdRef();
        this.cleanAssignmentHolder();
    }

    isPolicyHolder(idRole: string): boolean {
      return (idRole === Roles.CONTRACTOR || idRole === Roles.ADERENTE);
    }

    isPolicyInsured(idRole: string): boolean {
      return idRole === Roles.INSURED;
    }

    isPolicySecondInsured(idRole: string): boolean {
      return idRole === Roles.SECOND_INSURED;
    }

    isPolicyThirdRef(idRole: string): boolean {
      return idRole === Roles.THIRDREFERENT;
    }

    isPolicyAssignmentHolder(idRole: string): boolean {
      return idRole === Roles.VINCOLATARIO;
    }

    isPolicyCompanyPensionForm(idRole: string): boolean {
      return idRole === Roles.COMPANY_PENSION_FORM;
    }

    isPolicyEmployer(idRole: string): boolean {
      return idRole === Roles.EMPLOYER;
    }

    getObservable(): SubjectRxjs<Subject> {
      this.observable.next(null); // propago il null per terminare il precedente subscriber
      return this.observable;
    }

    setDefaultDocFromAnag(policyRole: PolicyRole) {
      console.log('Calling full registry service');
      const foundSubject = this.getStoredSubject(policyRole.objectId);
      if (!!foundSubject) {
        handlePolicyRole(foundSubject);
        return;
      }
      this.getSubjectFromAnag(policyRole.objectId, this.lifeSessionService.idPvManagement).toPromise().then((element) => {
        handlePolicyRole(element);
      });

      function handlePolicyRole(element: any) {
        console.log('Element full anag: ', element);

        if (element.subject && element.subject.document) {
          policyRole.docSubject.authorityRelease =
            element.subject.document.authoritiesRelease ? element.subject.document.authoritiesRelease.descrizione : null;
          policyRole.docSubject.documentDate = element.subject.document.releaseDate;
          policyRole.docSubject.documentNumber = element.subject.document.documentNumber;
          policyRole.docSubject.documentType =
            element.subject.document.documentType ? element.subject.document.documentType.codice : null;
          policyRole.docSubject.expirationDate = element.subject.document.expirationDate;
          policyRole.docSubject.locationRelease = element.subject.document.locationsRelease;
          policyRole.docSubject.objectId = element.subject.document.objectId;
        }
      }
    }

    isDocEmpty(docSubject: DocSubject) {
      return docSubject == null ||
            (docSubject.authorityRelease == null &&
             docSubject.documentDate == null &&
             docSubject.documentNumber == null &&
             docSubject.documentType == null &&
             docSubject.expirationDate == null &&
             docSubject.locationRelease == null &&
             docSubject.objectId == null);
    }

    policyRoleToLifeParty(policyRole: PolicyRole) {
      if (policyRole !== null || policyRole !== undefined) {
        return {
          party: {
            mainParty: null,
            ext: null,
            assetInstanceName: null,
            partyCode: null,
            specializedPartyCode: null,
            partyRole: policyRole.partyRole,
            extraInformations: this.fill(policyRole),
            objectID: policyRole.objectId,
            quoteID: null
          },
          percentageParty: policyRole.percentage,
          authorityRelease: policyRole.docSubject.authorityRelease,
          documentDate: policyRole.docSubject.documentDate,
          documentNumber: policyRole.docSubject.documentNumber,
          documentType: policyRole.docSubject.documentType,
          expirationDate: policyRole.docSubject.expirationDate,
          locationRelease: policyRole.docSubject.locationRelease,
          linkedLifeParties: this.policyRolesToLinkedLifeParty(policyRole.linkedSubjectRoles)
        };
      }
      return null;
    }

    policyRolesToLinkedLifeParty(roles: PolicyRole[]) {
      if (roles === null || roles === undefined) {
        return [];
      }
      return roles.map(role => {
        return {
          party: this.policyRoleToLifeParty(role).party
        };
      });
    }

    subjectToLifeParty(subject: Subject, code: string) {
      if (subject !== null && subject !== undefined) {
        return {
          party: {
            mainParty: null,
            ext: null,
            assetInstanceName: null,
            partyCode: null,
            specializedPartyCode: null,
            partyRole: code,
            extraInformations: this.fill(subject),
            objectID: subject.objectId,
            quoteID: null
          },
          authorityRelease: null,
          documentDate: null,
          documentNumber: null,
          documentType: null,
          expirationDate: null,
          locationRelease: null
        };
      }
      return null;
    }

    storePolicyRoles(policyRoles: PolicyRole[]) {
      this.storedPolicyRoles = policyRoles;
    }

    fill(subj) {
      if (!subj) {
        return '';
      }
    /*  if (subj !== null && subj !== undefined) {
        if (subj instanceof PolicyRole) {
          if (subj.name != null) {
            return subj.surname + ' ' + subj.name;
          } else if (subj.companyName != null) {
            return subj.companyName;
          }
        } else {
          if (subj.name != null) {
          return subj.surname + ' ' + subj.name;
        } else if (subj.denomination != null) {
          return subj.denomination;
        } else if (subj.companyName != null) {
          return subj.companyName;
        }
          return null;
        }
      }*/
      const subject = this.getStoredSubject(subj.objectId);
      return subject.nominative || subject.denomination;

    }

    createPolicyRole(policyRole: PolicyRole, abstractControl: any) {
      if (this.isDocEmpty(policyRole.docSubject)) {
        this.setDefaultDocFromAnag(policyRole);
      }
      abstractControl.setValue(policyRole);
      return policyRole;
    }

    getRoles(subject: PolicyRole, isFromPreventive, isFromQuoteModification, idMasterPolicy = null): Observable<any> {
      let request: any = {};

      const headers = {
        RGI_executionId: this.lifeSessionService.executionId,
        RGI_idPv: this.lifeSessionService.idPv
      };

      request = {
        subjectRole: {
          dateOfBirth: subject.dateOfBirth,
          personType: subject.personType,
          objectId: subject.objectId,
          agencyId: this.lifeSessionService.idPv,
          partyRole: subject.partyRole,
          name: subject.name,
          surname: subject.surname,
          companyName: subject.companyName
        },
        managementNodeId: this.lifeSessionService.idPvManagement
      };

      if (isFromPreventive || isFromQuoteModification) {
        return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/estimate/roles', request, { headers });
      } else {
        request.productTypeCode = this.lifeSessionService.productType;
        if (idMasterPolicy) {
          request.idMasterPolicy = idMasterPolicy;
        }
        return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/roles', request, { headers });
      }

    }

    getLinkedRoles(subject: any, isFromPreventive: boolean): Observable<any> {

      const idPv = this.lifeSessionService.idPv;

      const headers = {
        RGI_executionId: this.lifeSessionService.executionId,
        RGI_idPv: idPv
      };

      const request = {
        subjectRole: {
          dateOfBirth: subject.dateOfBirth,
          personType: subject.personType,
          objectId: subject.objectId,
          agencyId: idPv,
          partyRole: subject.partyRole
        },
        managementNodeId: idPv
      };

      if (isFromPreventive) {
        return of();
      } else {
        return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/role/linkedRoles', request, { headers });
      }

    }

    getRoleDescription(subjectRole: PolicyRole): PolicyRole {
      subjectRole.partyRoleDescription = this.lifeSessionService.partyRoles.find(el => el.codice === subjectRole.partyRole).descrizione;
      if (subjectRole.linkedSubjectRoles != null) {
        subjectRole.linkedSubjectRoles.map((linkedRole) => {
          linkedRole.partyRoleDescription = this.lifeSessionService.partyRoles.find(el => el.codice === linkedRole.partyRole).descrizione;
        });
      }
      return subjectRole;
    }


    findSubjectByRole(subjectRoles: PolicyRole[], role: string): PolicyRole {
      return subjectRoles.find((subject) => {
        if (subject.partyRole === role) {
          return true;
        }
        if (subject.linkedSubjectRoles != null && subject.linkedSubjectRoles.length > 0) {
          subject.linkedSubjectRoles.map((linkedSubject) => {
            if (linkedSubject.partyRole === role) {
              return true;
            }
          });
        }
      });
    }



  isPolicyCosigned(idRole: string): boolean {
    return idRole === Roles.COSIGNED;
  }

  setSubjectFromAnag(subject: Subject) {
    this.observable.next(subject);
  }

  getSubjectFromAnag(idSubj: any, idNodo?: string): Observable<any> {
    if (!idNodo) {
      idNodo = this.lifeSessionService.idPvManagement;
    }
    if (!idSubj) {
      return of(null);
    }
    return this.httpClient.get(this.lifeSessionService.baseApiUrl + '/anag/subject/' + idSubj + '?idNode=' + idNodo)
    .pipe(
      tap((subjectFromAnag) => {
        // salvo il soggetto recuperato in una mappa
        let subject: Subject = subjectFromAnag.subject;
        if(subjectFromAnag.subject.documents && subjectFromAnag.subject.documents.length > 0) {
          subject.document = subjectFromAnag.subject.documents[subjectFromAnag.subject.documents.length - 1];
        }
        this.storeSubject(subject);
      })
    );
  }

  checkPartyCompleted(idParty, idPartyPicture, partyRole, productId, nodeId): Observable<PartyCompleted> {
    const request = {
      checkPartyCompletedInput: {
        idParty,
        idPartyPicture,
        partyRole,
        productId,
        nodeId
      }
    };
    const headers = {
      RGI_idPv: !!this.lifeSessionService.idPv ? this.lifeSessionService.idPv : nodeId
    };
    if (!!this.lifeSessionService.executionId) {
      (headers as any).RGI_executionId = this.lifeSessionService.executionId;
    }
    return this.httpClient.post<PartyCompleted>(this.lifeSessionService.baseApiUrl + '/anag/partycompleted', request, {headers});
  }

  setCosigned(subject) {
    this.cosignedSubj = subject;
  }

  getCosigned() {
    return this.cosignedSubj;
  }

  reloadFromAuth(vectorOfSubj: {partyCode: string, partyRole: Roles, percentage: any, subSubjects: any[]}[]) {
    const subjCodes = this.getSubjectCodes(vectorOfSubj);
    const subjCalls: Observable<any>[] = [];


    if (subjCodes != null) {
      subjCodes.forEach((s) => {
        subjCalls.push(this.getSubjectFromAnag(s, this.lifeSessionService.idPvManagement));
      });

      forkJoin(subjCalls).subscribe((responses) => {
        const mapOfResponse = new Map<string, Subject>();
        responses.forEach((rSubj) => {
          mapOfResponse.set(rSubj.subject.objectId, rSubj.subject);
        });

        const vectorOfPolicyRoles = [];
        vectorOfSubj.map(s => {
          const subjectToPush = mapOfResponse.get(s.partyCode);

          if (this.isPolicyHolder(s.partyRole)) {
            this.setPolicyHolder(subjectToPush);
          } else if (this.isPolicyCosigned(s.partyRole)) {
            this.setCosigned(subjectToPush);
          } else if (this.isPolicyInsured(s.partyRole)) {
            this.setPolicyInsured(subjectToPush);
          } else if (this.isPolicySecondInsured(s.partyRole)) {
            this.setSecondPolicyInsured(subjectToPush);
          } else if (this.isPolicyAssignmentHolder(s.partyRole)) {
            this.setPolicyAssignmentHolder(subjectToPush);
          } else if (this.isPolicyThirdRef(s.partyRole)) {
            this.setPolicyThirdRef(subjectToPush);
          } else if (this.isPolicyCompanyPensionForm(s.partyRole)) {
            this.setPolicyByRole(subjectToPush, Roles.COMPANY_PENSION_FORM);
          } else if (this.isPolicyEmployer(s.partyRole)) {
            this.setPolicyByRole(subjectToPush, Roles.EMPLOYER);
          }

          // controllo i linkedRoles
          const vectorOfSubSubj = [];
          if (!!s.subSubjects && s.subSubjects.length > 0) {
            s.subSubjects.forEach(subSubj => {
              const subPolicyRole = new PolicyRole(subSubj.partyRole, mapOfResponse.get(subSubj.partyCode));
              vectorOfSubSubj.push(subPolicyRole);
              // vectorOfPolicyRoles.push(subPolicyRole);
            });
          }
          const subToPush = new PolicyRole(s.partyRole, subjectToPush);
          subToPush.linkedSubjectRoles = vectorOfSubSubj; // se si trova dei linkedRoles li aggancia qua
          if (s.partyRole === Roles.PARTY_SUBJECT_TO_TAXATION || s.partyRole === Roles.EFFECTIVEHOLDER) {
            subToPush.percentage = s.percentage;
          }
          vectorOfPolicyRoles.push(subToPush);

        });
        this.storePolicyRoles(vectorOfPolicyRoles);
      },
      err => {},
      () => {
        this.endReload();
      });
    }
  }

  reloadFromAnag(idVector: string[]): Observable<any[]> {
    const subjCalls: Observable<any>[] = [];
    idVector.forEach((id) => {
      subjCalls.push(this.getSubjectFromAnag(id, this.lifeSessionService.idPvManagement));
    });
    return forkJoin(subjCalls);
  }

  waitReloadEnd(): Observable<any> {
    return this.endOfReload.asObservable();
  }

  endReload() {
    this.endOfReload.next(true);
  }

  getSubjectCodes(vectorOfSubj: {partyCode: string, partyRole: string, subSubjects: any[]}[]) {
    const subjCodes = [];
    vectorOfSubj.forEach((subj) => {
      if (subjCodes.length > 0) {
        let flagFound = false;
        subjCodes.forEach(s => {
          if (s === subj.partyCode) {
            flagFound = true;
          }
        });

        if (!!subj.subSubjects && subj.subSubjects.length > 0) {
          subj.subSubjects.forEach((subSubj) => {
            let flagSubFound = false;
            subjCodes.forEach(s => {
              if (s === subSubj.partyCode) {
                flagSubFound = true;
              }
            });

            if (!flagSubFound) {
              subjCodes.push(subSubj.partyCode);
            }
          });
        }

        if (!flagFound) {
          subjCodes.push(subj.partyCode);
        }
      } else {
        subjCodes.push(subj.partyCode);
      }
    });

    return subjCodes;
  }

  // EXT
  getContractorDetails(): { cf: string, fullName: string } {
    const contr = this.getStoredSubject(this.getPolicyHolder().objectId);

    if (!!contr) {
      return {
        cf: contr.fiscalCode,
        fullName: contr.nominative
      };
    } else {
      return {
        cf: null,
        fullName: null
      };
    }
  }

  getAnagFlowData(role: string, subjectType: string): AnagFlowData {
    const anagFlowData = new AnagFlowData();
    anagFlowData.partyRole = role;
    anagFlowData.partyType = subjectType;
    if (this.lifeSessionService && this.lifeSessionService.idParentSession) {
      anagFlowData.nodeCode = this.lifeSessionService.codLoginNode;
      anagFlowData.idParentSession = this.lifeSessionService.idParentSession;
    }
    return anagFlowData;
  }
}


import {Agreement} from './../../../models/domain-models/parameters/agreement';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {PcAgreement} from '../../../models/pc-portfolio-models/agreements-models/pc-agreement';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioAgreementsResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
    private refactorService: ReIssueRefactorService
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;
  }

  getAgreements(proposalId: string): Observable<Array<PcAgreement>> {
    return this.http.get<Array<PcAgreement>>(this.getAgreementsUri(proposalId)).pipe(
      share()
    );
  }

  putAgreement(proposalId: string, agreement: Agreement): Observable<PcAgreement> {
    return this.http.put<PcAgreement>(
      this.getAgreementUri(proposalId),
      this.refactorService.agreementRefactor(agreement)
    ).pipe(
      share()
    );
  }

  getAgreementsUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/agreements';
  }

  getAgreementUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/agreement';
  }
}

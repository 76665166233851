import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxStepperComponent} from './rgi-rx-stepper/rgi-rx-stepper.component';
import {RgiRxStepContentDirective} from './rgi-rx-step-content.directive';
import {RgiRxStepHeaderComponent} from './rgi-rx-step-header/rgi-rx-step-header.component';
import {RgiRxStepComponent} from './rgi-rx-step/rgi-rx-step.component';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {FormsModule} from '@angular/forms';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {PortalModule} from '@angular/cdk/portal';
import {RgiRxStepBodyComponent} from './rgi-rx-step-body/rgi-rx-step-body.component';
import {RGI_RX_STEPPER_DEFAULT_OPTIONS, RGI_RX_STEPPER_OPTIONS, RgiRxStepper} from './rgi-rx-stepper-api';
import {RgiRxStepperNext, RgiRxStepperPrevious} from './rgi-rx-stepper-buttons';
import {RgiRxStepLabelDirective} from './rgi-rx-step-label.directive';
import {RgiRxQualityAssuranceModule} from '@rgi/rx';


@NgModule({
  declarations: [
    RgiRxStepperComponent,
    RgiRxStepComponent,
    RgiRxStepContentDirective,
    RgiRxStepHeaderComponent,
    RgiRxStepBodyComponent,
    RgiRxStepper,
    RgiRxStepperNext,
    RgiRxStepperPrevious,
    RgiRxStepLabelDirective
  ],
  imports: [
    CommonModule,
    CdkStepperModule,
    FormsModule,
    RgiRxI18nModule,
    RgiRxQualityAssuranceModule,
    PortalModule
  ],
  exports: [
    RgiRxStepper,
    RgiRxStepperComponent,
    RgiRxStepComponent,
    RgiRxStepContentDirective,
    RgiRxStepperNext,
    RgiRxStepperPrevious,
    RgiRxStepLabelDirective
  ],
  providers: [
    {
      provide: RGI_RX_STEPPER_OPTIONS,
      useValue: RGI_RX_STEPPER_DEFAULT_OPTIONS
    }
  ]
})
export class RgiRxStepperModule {
}

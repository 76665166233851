import { EventEmitter, Injectable } from '@angular/core';

@Injectable(
  { providedIn: 'root' }
)
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class LoaderService {

  private loaderStack = [];

  constructor() {}

  public startLoader(eventPropagation: EventEmitter<any>, forceStop?: boolean) {
    if (forceStop === true) {
      this.loaderStack.length = 0;
    }

    if (this.loaderStack.length === 0) {
      eventPropagation.emit('startLoader');
    }
    this.loaderStack.push('started');
  }

  public stopLoader(eventPropagation: EventEmitter<any>, forceStop?: boolean) {
    if (forceStop === true) {
      this.loaderStack.length = 0;
    }

    if (this.loaderStack.length > 0) {
      this.loaderStack.pop();
    }
    if (this.loaderStack.length === 0) {
      eventPropagation.emit('stopLoader');
    }
  }

}

import {Inject, Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CommissionsElement} from './commissions-element';
import {HttpClient} from '@angular/common/http';
import {ProposalService} from '../../proposal.service';
import {CommissionTable} from './commission-table';
import {MicHttpService} from '../../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class CommissionsService {

  baseApiUrlV2;
  protected httpClient: HttpClient;


  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected proposalService: ProposalService
  ) {
    const baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';
    this.httpClient = this.micHttpClient.getHttpClient();
  }

  toFormGroup(commissionsElements: CommissionsElement[]) {
    const group: any = {};
    commissionsElements.forEach(
      (commissionElement) => {
        group['purchasePerc' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.purchasePercentage);
        group['purchaseAmount' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.purchaseAmount);
        group['purchaseAdditionalPerc' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.purchaseAdditionalPercentage);
        group['purchaseAdditionalAmount' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.purchaseAdditionalAmount);
        group['collectionPerc' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.collectionPercentage);
        group['collectionAmount' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.collectionAmount);
        group['collectionAdditionalPerc' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.collectionAdditionalPercentage);
        group['collectionAdditionalAmount' + '-' + commissionElement.key]
          = new UntypedFormControl(commissionElement.collectionAdditionalAmount);
      });
    return new UntypedFormGroup(group);
  }

  getCommissions() {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.get<CommissionTable>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/commissions');
  }

  updateCommissions(commissions: CommissionTable) {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.put<CommissionTable>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/commissions', commissions);
  }

  getCommissionsStatus() {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.get<number>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/commissions/status');
  }
}

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {AnagStateManagerPartyEditor} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {PRIVACY_CONSENSUS_ENUM} from '../../anag-constants/enums-constants';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagConfiguration} from '../../anag-model/anag-domain/anag-configuration';
import {AnagFormFieldConfig} from '../../anag-model/anag-domain/anag-form-field-config';
import {AnagConfigService} from '../../anag-resources/anag-config.service';

@Component({
  selector: 'rgi-anag-privacy',
  templateUrl: './anag-privacy.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagPrivacyComponent implements OnInit, OnModalClose {

  inputParty: AnagApiParty;

  privacyQuestions: Array<AnagEntityIta>;
  privacyForm: UntypedFormGroup;
  maxDate = new Date();
  submitted: boolean;
  modalClose: EventEmitter<any> = new EventEmitter();
  originOptions: Array<AnagEntityIta>;
  config: AnagConfiguration;
  privacyDataFormMap: Map<string, AnagFormFieldConfig>;

  constructor(
    public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public anagStatelessService: AnagStatelessService,
    public pushMessageHandler: PushMessageHandlerService,
    protected translateService: RgiRxTranslationService,
    public configService: AnagConfigService,
    @Optional() @Inject(DIALOG_DATA) party: AnagApiParty
  ) {
    if (party.privacyConsensus || party.privacyConsensusDate) {
      this.inputParty = party;
    }
    this.privacyForm = new UntypedFormGroup({
      robinsonList: new UntypedFormControl(party.robinsonList !== null ? party.robinsonList : null)
    });
    this.privacyDataFormMap = new Map<string, AnagFormFieldConfig>([]);
  }

  ngOnInit() {
    this.pushMessageHandler.clearTag('privacy-tag');
    this.anagStorage.getOriginEnums().subscribe(origin => {
      this.originOptions = origin;
    });
    this.config = this.stateMgr.getCurrentState().configuration;
    this.anagStorage.getPrivacyEnums().subscribe(privacyQuestions => {
      privacyQuestions.forEach(question => {
        this.privacyForm.addControl(question.codice, new UntypedFormControl('3', Validators.required));
        this.privacyForm.addControl('date-' + question.codice, new UntypedFormControl('', Validators.required));
        this.privacyForm.addControl('origin-' + question.codice, new UntypedFormControl(''));
        this.privacyForm.addControl('privacyConsentDateUpd-' + question.codice, new UntypedFormControl(''));
        this.privacyDataFormMap.set(question.codice, new AnagFormFieldConfig('privacyConsensusType', this.privacyForm, []));
        // tslint:disable-next-line:max-line-length
        this.privacyDataFormMap.set('date-' + question.codice, new AnagFormFieldConfig('privacyConsensusDate', this.privacyForm, []));
        // tslint:disable-next-line:max-line-length
        this.privacyDataFormMap.set('origin-' + question.codice, new AnagFormFieldConfig('privacyConsensusOrigin', this.privacyForm, []));
        this.privacyDataFormMap.set('privacyConsentDateUpd-' + question.codice, new AnagFormFieldConfig('privacyConsensusDateUpd', this.privacyForm, []));
      });
      this.privacyQuestions = privacyQuestions;
      this.privacyForm.valueChanges.subscribe(() => {
        this.submitted = false;
      });
      if (this.inputParty) {

        if (this.inputParty.privacyConsensus) {
          this.inputParty.privacyConsensus.forEach(p => {
            if (p.privacyConsensusValue) {
              this.privacyForm.get(p.privacy.identification).setValue(p.privacyConsensusValue.codice);
            }
            if (p.privacyConsentDate) {
              this.privacyForm.get('date-' + p.privacy.identification).setValue(p.privacyConsentDate);
            }
            if (p.privacyConsentOrigin) {
              this.privacyForm.get('origin-' + p.privacy.identification).setValue(p.privacyConsentOrigin);
            }
            if (p.privacyConsentDateUpd) {
              const formattedPrivacyConsentDateUpd = this.formatPrivacyConsentDateUpd(p.privacyConsentDateUpd)
              this.privacyForm.get('privacyConsentDateUpd-' + p.privacy.identification).setValue(formattedPrivacyConsentDateUpd);
            }
          });
        }
      }
    });
    this.configService.setConfigToForm(this.privacyDataFormMap, this.config);
  }

   formatPrivacyConsentDateUpd(privacyConsentDateUpd) {
    const currentPrivacyConsentDateUpd = new Date(privacyConsentDateUpd);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return currentPrivacyConsentDateUpd.toLocaleString('it-IT', options);

  }

  notifyFormValidationMsg() {
    this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(stringMsg =>
      this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
        tag: 'privacy-tag',
        status: 'danger',
        dismissible: false
      }))
    ).unsubscribe();
  }

  actionClose() {
    this.modalClose.emit();
  }

  get questionOptions() {
    return this.anagStorage.getEnumsByCode(PRIVACY_CONSENSUS_ENUM);
  }

  updatePrivacy() {
    this.submitted = true;
    if (this.privacyForm.valid) {
      this.stateMgr.updatePrivacy(this.privacyForm.getRawValue(), this.privacyQuestions, this.questionOptions, this.originOptions);
      this.modalClose.emit();
    } else {
      this.privacyForm.markAllAsTouched();
      this.notifyFormValidationMsg();
    }
  }

  get datepickerPlaceholder() {
    return this.anagStatelessService.getDatePickerPlaceholderLabel();
  }


}

import {ANAG_FUNCTIONS} from './anag-constants';


export interface FIELD {
  name: string;
  label: string;
  visible: number;
  functions: string;
  disabled: number;
  parent: string;
  type: string;
  fields: Array<FIELD>;
}

export const FIELD_ANAG_STATE = {
  name : 'anagState',
  label : 'State_001',
  visible : 1,
  disabled : 1,
  parent: 'anagTabAccorpamento',
  type : 'select'
} as FIELD;

export const FIELD_ANAG_CRITERIA = {
  name : 'anagCriteria',
  label : 'Criteria_001',
  visible : 1,
  disabled : 1,
  parent: 'anagTabAccorpamento',
  type : 'select'
} as FIELD;

export const FIELD_ANAG_TAB_ACCORPAMENTO = {
  name: 'anagTabAccorpamento',
  label: 'Party merger',
  visible: 1,
  functions : ANAG_FUNCTIONS.merge ,
  type: 'tab',
  parent: 'tabsGestioneAccorpamento',
  fields: [FIELD_ANAG_CRITERIA , FIELD_ANAG_STATE]
} as FIELD;

export const FIELD_ANAG_SUBSYSTEM = {
  name : 'anagSubsystem',
  label : 'Subsystem',
  visible : 0,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_PARTY_TYPE = {
  name : 'anagPartyType',
  label : 'Party Type',
  visible : 0,
  parent: 'anagTabGestione',
  type : 'select'
} as FIELD;

export const FIELD_ANAG_BIRTH_NAME = {
  name : 'anagBirthName',
  label : 'Birth Name',
  visible : 0,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_PARTY_NUMBER = {
  name : 'anagPartyNumber',
  label : 'Party Number',
  visible : 0,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_CLIENT_NUMBER = {
  name : 'anagClientNumber',
  label : 'Customer Number',
  visible : 0,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_RESIDENCE = {
  name : 'anagAddress',
  label : 'Residence',
  visible : 0,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_UNIQUE_KEY = {
  name : 'anagUniqueKey',
  label : 'Subject Unique Key',
  visible : 1,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_SURNAME = {
  name : 'anagSurname',
  label : 'Surname / Company Name',
  visible : 1,
  type : 'text',
  parent: 'anagTabGestione',
} as FIELD;

export const FIELD_ANAG_NAME = {
  name : 'anagName',
  label : 'Name',
  visible : 1,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_COWORKER = {
  name : 'anagCoworker',
  label : 'Co-worker fiscal code',
  visible : 0,
  parent: 'anagTabGestione',
  type : 'text'
} as FIELD;

export const FIELD_ANAG_LOCAL_PREFIX = {
  name : 'anagLocalPrefix',
  label : 'Local Prefix',
  visible : 0,
  type : 'text',
  parent: 'anagTabGestione',
} as FIELD;

export const FIELD_ANAG_INTERNATIONAL_PREFIX = {
  name : 'anagInternationalPrefix',
  label : 'International Prefix',
  visible : 0,
  type : 'text',
  parent: 'anagTabGestione',
} as FIELD;

export const FIELD_ANAG_PHONE = {
  name : 'anagPhone',
  label : 'Phone number',
  visible : 0,
  type : 'text',
  parent: 'anagTabGestione',
} as FIELD;

export const FIELD_ANAG_EMAIL = {
  name : 'anagEmail',
  label : 'Email',
  visible : 0,
  type : 'text',
  parent: 'anagTabGestione',
} as FIELD;

export const FIELD_ANAG_BIRTH_DATE = {
  name : 'anagBirthDate',
  label : 'Date of birth',
  visible : 0,
  type : 'date',
  parent: 'anagTabGestione',
} as FIELD;

export const FIELD_ANAG_TAB_PARTY_MANAGEMENT = {
  name: 'anagTabGestione',
  label: 'Party management',
  visible: 1,
  functions : ANAG_FUNCTIONS.search ,
  type: 'tab',
  parent: 'tabsGestioneAccorpamento',
  fields: [FIELD_ANAG_SURNAME, FIELD_ANAG_NAME, FIELD_ANAG_COWORKER, FIELD_ANAG_RESIDENCE , FIELD_ANAG_UNIQUE_KEY, FIELD_ANAG_CLIENT_NUMBER, FIELD_ANAG_PARTY_NUMBER, FIELD_ANAG_BIRTH_NAME, FIELD_ANAG_PARTY_TYPE, FIELD_ANAG_SUBSYSTEM, FIELD_ANAG_BIRTH_DATE, FIELD_ANAG_INTERNATIONAL_PREFIX, FIELD_ANAG_LOCAL_PREFIX, FIELD_ANAG_PHONE, FIELD_ANAG_EMAIL]
} as FIELD;

export const ANAG_TABGROUP_PARTY = {
  label: 'Type',
  name: 'tabsGestioneAccorpamento',
  visible: 1,
  type: 'tabgroup',
  fields: [FIELD_ANAG_TAB_PARTY_MANAGEMENT, FIELD_ANAG_TAB_ACCORPAMENTO]
} as FIELD;

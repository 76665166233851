import {AdapterprintTranslationsKeys} from './adapterprint-translations-keys';

export const ADAPTERPRINT_DE: AdapterprintTranslationsKeys = {
  _START_ADAPTERPRINT_: 'Adapter Druckergebnisse',
  _RESULT_ADAPTERPRINT_: 'Adapter Druckliste',
  _ADAPTERPRINT_: {
    _BTN_: {
      _BACK_: 'Zurück',
      _EMPTY_: 'Leere',
      _FIND_: 'Finden',
      _UNDO_: 'Abbrechen'
    },
    _LABEL_: {
      _ACTION_: 'Aktion',
      _ADDRESS_: 'Adresse',
      _LOT_: 'Los',
      _CODE_: 'Code',
      _DATE_SENT_: 'Datum des Versands',
      _DATE_TO_: 'Datum am',
      _DESCRIPTION_: 'Beschreibung',
      _DOCUMENT_TYPE_: 'Art des Dokuments',
      _MANAGEMENT_NODE_: 'Management-Knoten',
      _NODE_: 'Nodo',
      _NODE_CODE_: 'Code des Knotens',
      _NODE_DESCRIPTION_: 'Beschreibung des Knotens',
      _NODE_LIST_: 'Liste der Knotenpunkte',
      _POLICY_NUMBER_: 'Nummer der Police',
      _ADDRESSED_: 'Empfänger',
      _START_DATE_: 'Datum von',
      _STATUS_: 'Status',
      _POLICY_: 'Politik',
      _TEMPLATE_: 'Template',
      _OUTCOME_: 'Ergebnis',
      _USER_: 'Benutzer'
    },
    _MSG_: {
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Rot markierte Felder sind Pflichtfelder oder enthalten falsche Werte',
      _NO_RESULTS_: 'Kein Ergebnis',
      _NOT_SENT_: 'Nicht gesendet',
      _SENT_OK_: 'Gebucht OK',
      _SENT_KO_: 'Geschrieben KO',
    }
  },
};

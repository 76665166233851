import {AnagIssueApiResidence, AnagIssuePhoneNumber, AnagIssueSubject} from '../anag-models';
import {Authorization} from '../authorization';

export function mapToReIssuePolicyData(response: any, authorizationDetail: Authorization): any {
    return {
        subject: mapToContractorSubject(response?.proposalSummary?.subscriber, authorizationDetail),
        nodeId: authorizationDetail?.requestNode?.id,
        isToDisableFraz: false,
        resourceId: response.id,
        productName: response?.proposalSummary?.product?.description,
        node: { idSp: authorizationDetail?.requestNode?.id},
        productCode: response?.proposalSummary?.product?.code,
        policyTechnicalData: {
            instalmentDates: response?.proposalSummary?.instalmentDates,
            selectedInstalmentDate: response?.proposalSummary?.coverageExpiration,
            effectiveDate: response?.proposalSummary?.effectDate,
            expireDate: response?.proposalSummary?.policyExpiration,
            issueDate: response?.proposalSummary?.issuingDate,

        },
        isFromExternalCard: true,
        companyId: response?.proposalSummary?.company?.code,
        editMode: true,
        editFunctionality: false,
        isSubstitution: false,
        backFromStep: false,
        isSubjectModified: true,
        authorize: response?.proposalSummary?.authorize,
        productId: authorizationDetail?.product?.id,
        thirdPersonContact: null
    };
}


function  mapToContractorSubject(subscriber: any, authorizationDetail: Authorization): AnagIssueSubject {
    const mobile: AnagIssuePhoneNumber = {
        extensionSubject: '', internationalPrefix: '', objectId: '',
        localPrefix: '', number: subscriber?.contacts?.mobile
    };
    const residence : AnagIssueApiResidence = {
        addressAddition: undefined,
        adminLevel1: '',
        adminLevel1Short: '',
        adminLevel2: '',
        adminLevel2Short: '',
        adminLevel3: '',
        adminLevel3Short: '',
        at: '',
        cab: '',
        cap: '',
        city: '',
        country: subscriber?.residence?.country,
        countryCode: '',
        detailCode: '',
        extensionSubject: '',
        fraction: '',
        latitude: '',
        locality: '',
        longitude: '',
        normalized: false,
        number: '',
        placeAddress: '',
        postalAddress: '',
        province: undefined,
        specialMention: undefined,
        subLocality: '',
        toponym: undefined,
        formatAddress: subscriber?.residence?.formattedAddress};
    return {
        birthAddress: undefined,
        cityOfBirth: '',
        clientNumber: '',
        company: undefined,
        countryOfBirth: '',
        dateOfBirth: subscriber?.birthDate,
        denomination: '',
        emails: [subscriber?.contacts?.email],
        extensionSubject: undefined,
        fiscalCode: subscriber?.fiscalCode ,
        vat: subscriber?.fiscalCode ,
        mobilePhone: [mobile] ,
        name: subscriber?.name,
        nominative: subscriber?.nominative,
        partyKey: undefined,
        partyNumber: '',
        payments: undefined,
        personType: {codice: subscriber?.type?.code, descrizione: subscriber?.type?.description},
        privacyConsensus: undefined,
        professionDetail: undefined,
        professionType: undefined,
        residence: residence,
        subjectType: undefined,
        surname: subscriber?.surname,
        objectId: subscriber?.id,
        idLatestPhotos: subscriber?.snapshotId,
        node: {
            identification: authorizationDetail?.requestNode?.id,
            code: authorizationDetail?.requestNode?.code,
            description: authorizationDetail?.requestNode?.description
        }
    }
}

import { Injectable } from '@angular/core';
import {FIELD} from '../anag-constants/anag-home-fields-constants';

@Injectable()
export class AnagHomeFieldConfigurationService {
  private _fieldMap: Map<string, Array<FIELD>>;

  constructor() {
    this._fieldMap = new Map<string, Array<FIELD>>();
  }

  setFields(id: string, fields: Array<FIELD>): void {
    this._fieldMap.set(id, fields);
  }

  getFields(id: string): Array<FIELD> {
    return this._fieldMap.get(id);
  }

  addField(id: string, field: FIELD): void {
    if (!this._fieldMap.has(id)) {
      this._fieldMap.set(id, []);
    }
    this.getFields(id).push(field);
  }

  clearField(id: string): void {
    this._fieldMap.delete(id);
  }
}

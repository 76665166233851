<div *ngIf="!!fieldLables.length" class="col-lg-12 blank">
  <h3 translate>{{label}}</h3>
  <div class="tbld postsales-table-style hidden-xs">
    <ng-container *ngFor="let rowlabel of fieldLables; let i = index">
      <div class="tbld_row postsales-row-content" *ngIf="!!fieldValues[i]" >
        <div
          class="tbld_col psvalue text-left">
          {{ rowlabel }}
        </div>
        <div
          class="tbld_col psvalue" [attr.data-qa]="rowlabel+'-value'">
          {{ getFieldValue(fieldValues[i], rowlabel) }}
        </div>
    </div>
    </ng-container>
  </div>
</div>


export class Beneficiary {
  id: number;
  code: string;
  beneficiaryType: number;
  defaultValue: boolean;
  description: string;
  extendedDescription: string;
  soggettiAnagMax: number;
  soggettiAnagMin: number;
  percentage: number;
  subject: any;
}

import {ConfMetadata} from './conf-metadata';
import {GenericEntity} from "../../../../../resources/models/generic-entity";


export class Metadata{

  id: string;
  code: string;
  description: string;
  value: string;
  valueDescription: string;
  listValue: GenericEntity[];
  visible: boolean;
  usedForValidation: boolean;
  mandatory: boolean;
  multiple: GenericEntity;
  complianceStatus: GenericEntity;
  type: GenericEntity;
  usedAsIndex: boolean;
  modifiable: boolean;
  systemMetadato: boolean;
  conf: ConfMetadata;

  constructor( ) {

  }


}

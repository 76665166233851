import {Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {RgiRxPanelComponent} from '../rgi-rx-panel/rgi-rx-panel.component';
import {RgiRxQAService} from '@rgi/rx';

let rgiRxPanelHeaderCounter = 0;

@Component({
  selector: 'rgi-rx-panel-header,[rgi-rx-panel-header]',
  templateUrl: './rgi-rx-panel-header.component.html',
  host: {
    class: 'rgi-ui-panel-header'
  }
})
export class RgiRxPanelHeaderComponent implements OnInit, OnDestroy {

  private readonly _id: string
  @Input() closeable = false;
  @Output() onClose = new EventEmitter<Event>();

  constructor(
    private elementRef: ElementRef,
    @Optional() private panel?: RgiRxPanelComponent,
    @Optional() private QA?: RgiRxQAService,
  ) {
    this._id = `${(this.panel ? this.panel.id : 'rgi-rx-panel')}-header-${++rgiRxPanelHeaderCounter}`
  }

  ngOnInit() {
    if (this.panel && this.QA) {
      this.QA.render(this.elementRef.nativeElement, {
        id: this.id,
        type: 'panel-header'
      });
    }
  }

  close($event: MouseEvent) {
    this.onClose.emit($event);
  }

  @HostBinding('id')
  get id(): string {
    return this._id;
  }

  ngOnDestroy(): void {
    if (this.QA) {
      this.QA.clear(this.elementRef.nativeElement);
    }
  }

}

import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ValidatorService} from 'angular-iban';
import {PaymentFieldDefinition} from '../../../models/postsales-operations-response.model';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-payment-input',
  templateUrl: './lpc-payment-input.component.html',
  styleUrls: ['./lpc-payment-input.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LpcPaymentInputComponent implements OnInit {
  value: string;
  config: PaymentFieldDefinition;
  group: UntypedFormGroup;
  editable: boolean;
  prevVal: string;
  @Output() messageIban: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected el: ElementRef) {
  }

  ngOnInit() {
    if (!this.group.get(this.config.code).value) {
      this.prevVal = null;
    } else {
      this.prevVal = this.group.get(this.config.code).value;
    }
    this.initInput();
    if (!this.editable) {
      this.group.get(this.config.code).disable();
    }
  }

  onBlur() {
    if (this.prevVal !== this.group.controls[this.config.code].value) {
      if (this.config.code === 'ciban' && this.group.controls[this.config.code].errors !== null) {
        const iban = this.group.controls[this.config.code].value.toUpperCase().replace(/[^A-Z0-9]/g, EMPTY_STR);
        this.group.controls[this.config.code].setValue(iban);
        this.el.nativeElement.dispatchEvent(new Event('errorMessage', {bubbles: true}));
        this.prevVal = iban;
      } else {
        this.el.nativeElement.dispatchEvent(new Event('changeValore', {bubbles: true}));
        this.prevVal = this.group.controls[this.config.code].value;
      }
    }
  }


  initInput() {
    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.group.get(this.config.code).value === null ||
        this.group.get(this.config.code).value === EMPTY_STR ||
        this.group.get(this.config.code).value === '-1') {
      this.group.controls[this.config.code].setValue(null, { emitEvent: false });
    } else {
      this.group.controls[this.config.code].setValue(this.group.get(this.config.code).value);
    }
    if (this.config.required) {
      if (this.config.required && this.config.code === 'ciban') {
        this.group.controls[this.config.code].setValidators([Validators.required, Validators.pattern('^\\S+(?:\\S+)*$'),
        ValidatorService.validateIban]);
      } else {
        this.group.controls[this.config.code].setValidators([Validators.required, Validators.pattern('^(?!\\s*$).+')]);
      }
    }
    if (this.config.editable === false && this.group.controls[this.config.code].disabled === false) {
      this.group.controls[this.config.code].disable({ emitEvent: false });
    } else {
      this.group.controls[this.config.code].enable({ emitEvent: false });
    }
  }

  setPayment(payment: PaymentFieldDefinition, form: UntypedFormGroup, editable: boolean) {
    this.config = payment;
    this.value = this.config.label;
    this.group = form;
    this.editable = editable;
  }

}


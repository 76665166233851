import {PcPaymentFrequency} from '../payment-frequency-models/pc-payment-frequency';

export class RegulationType {
  code: string;
  description: string;
  identification: number;
  additionalLabels: any;

  constructor(code: string,
              description: string,
              identification: number,
              additionalLabels: any) {
    this.code = code;
    this.description = description;
    this.identification = identification;
    this.additionalLabels = additionalLabels;
  }
}

export class RegulationPremium {
  minimumPremiumRegulationPercentage: string;
  premiumRegulationPercentage: string;
  premiumRegulationType: RegulationType;
  regulation: boolean;
  regulationDays: string;
  regulationExpirationDate: string;
  regulationExpirationDateSearchDoc: string;
  regulationFractioning: PcPaymentFrequency;
  additionalLabels: any;

  constructor(minimumPremiumRegulationPercentage: string,
              premiumRegulationPercentage: string,
              premiumRegulationType: RegulationType,
              regulation: boolean,
              regulationDays: string,
              regulationExpirationDate: string,
              regulationExpirationDateSearchDoc: string,
              regulationFractioning: PcPaymentFrequency,
              additionalLabels: any) {
    this.minimumPremiumRegulationPercentage = minimumPremiumRegulationPercentage;
    this.premiumRegulationPercentage = premiumRegulationPercentage;
    this.premiumRegulationType = premiumRegulationType;
    this.regulation = regulation;
    this.regulationDays = regulationDays;
    this.regulationExpirationDate = regulationExpirationDate;
    this.regulationExpirationDateSearchDoc = regulationExpirationDateSearchDoc;
    this.regulationFractioning = regulationFractioning;
    this.additionalLabels = additionalLabels;
  }
}

import {Inject, Injectable} from "@angular/core";
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {Observable} from 'rxjs';
import {PolicyOperationContainer, PolicyOperationPayload} from "../../models/policy-operations";
import {map, share} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ConsultationContractDetailsResourceService{
  constructor(
    private http: RgiRxHttpClientWrapper,
    @Inject('environment') private apiConf: any
  ) {
  }
  getContractDetail(contractNumber: string){
    return this.http.get<any>(`${this.apiConf.api.portal.path}/v2/portfolio/pc/contract/${contractNumber}/details`);
  }
  getPolicyOperations(body: PolicyOperationPayload): Observable<PolicyOperationContainer> {
    return this.http.post<PolicyOperationContainer>(`${this.apiConf.api.portal.path}/v2/portfolio/pc/policyoperations`, body).pipe(share(), map(val => {
        return val;
      })
    );
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomNumberPipe} from './custom-number.pipe';


@NgModule({
  declarations: [CustomNumberPipe],
  imports: [
    CommonModule
  ],
  exports: [CustomNumberPipe],
  providers: [CustomNumberPipe]
})
export class CustomNumberModule { }

export const REQUIRED_DATA_FIELD_CODES = {
  ISSUE_DATE: 'ISSUE_DATE',
  EFFECT_DATE: 'EFFECT_DATE',
  EFFECT_HOUR: 'EFFECT_HOUR',
  NOTIFICATION_DATE: 'NOTIFICATION_DATE',
  RIGHTS: 'RIGHTS',
  RCA_NET: 'RCA_NET',
  TAXABLE: 'TAXABLE',
  SSN: 'SSN',
  TAX: 'TAX'
};

export const REQUIRED_DATA_LABELS: Map<string, string> = new Map([
  [REQUIRED_DATA_FIELD_CODES.ISSUE_DATE, '_PCPSALES_._LABEL_._ISSUE_DATE_'],
  [REQUIRED_DATA_FIELD_CODES.EFFECT_DATE, '_PCPSALES_._LABEL_._EFFECT_DATE_'],
  [REQUIRED_DATA_FIELD_CODES.EFFECT_HOUR, '_PCPSALES_._LABEL_._EFFECT_HOUR_'],
  [REQUIRED_DATA_FIELD_CODES.RCA_NET, '_PCPSALES_._LABEL_._RCA_NET_'],
  [REQUIRED_DATA_FIELD_CODES.RIGHTS, '_PCPSALES_._LABEL_._RIGHTS_'],
  [REQUIRED_DATA_FIELD_CODES.TAXABLE, '_PCPSALES_._LABEL_._TAXABLE_'],
  [REQUIRED_DATA_FIELD_CODES.SSN, '_PCPSALES_._LABEL_._SSN_'],
  [REQUIRED_DATA_FIELD_CODES.TAX, '_PCPSALES_._LABEL_._TAX_']
]);


export const repaymentFieldsCodes: string[] = [
  REQUIRED_DATA_FIELD_CODES.RIGHTS,
  REQUIRED_DATA_FIELD_CODES.RCA_NET,
  REQUIRED_DATA_FIELD_CODES.TAXABLE,
  REQUIRED_DATA_FIELD_CODES.SSN,
  REQUIRED_DATA_FIELD_CODES.TAX
];

export const requiredDataFieldsCodes: string[] = [
  REQUIRED_DATA_FIELD_CODES.ISSUE_DATE,
  REQUIRED_DATA_FIELD_CODES.EFFECT_DATE,
  REQUIRED_DATA_FIELD_CODES.EFFECT_HOUR,
  REQUIRED_DATA_FIELD_CODES.NOTIFICATION_DATE];
export const requiredDataFieldsMap: Map<string, string> = new Map([
  [REQUIRED_DATA_FIELD_CODES.ISSUE_DATE, 'issueDate'],
  [REQUIRED_DATA_FIELD_CODES.EFFECT_DATE, 'effectDate'],
  [REQUIRED_DATA_FIELD_CODES.NOTIFICATION_DATE, 'notificationDate'],
  [REQUIRED_DATA_FIELD_CODES.EFFECT_HOUR, 'effectHour']
]);

export const premiumRepaymentFieldsMap: Map<string, string> = new Map([
  [REQUIRED_DATA_FIELD_CODES.TAXABLE, 'taxablePremium'],
  [REQUIRED_DATA_FIELD_CODES.TAX, 'tax'],
  [REQUIRED_DATA_FIELD_CODES.RCA_NET, 'rcaNetPremium'],
  [REQUIRED_DATA_FIELD_CODES.SSN, 'ssn'],
  [REQUIRED_DATA_FIELD_CODES.RIGHTS, 'rights'],
]);

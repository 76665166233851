/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
import {AuthTranslationsKeys} from './auth-translations-keys';


export const AUTH_GL_ES: AuthTranslationsKeys = {
    _AUTH_: {
        _CARD_TITLE_: 'Autorizacións',
        /* eslint-disable max-len */
        // _VIEW_FROM_{{_START_}}_TO_{{_END}}_OF_{{AUTHORIZATIONLISTLENGTH}}_ELEMENTS: "Vista desde {{inicio}} ata {{final}} de elementos {{authorizationList.length}}",
        _ACTION_IS_MANDATORY: 'A acción é obrigatoria',
        _ARE_YOU_SURE_TO_APPLY_THIS_ACTION: 'Está seguro de que quere solicitar esta acción?',
        _AUTHORIZATION_DEROGATION_LEVEL: 'Nivel de derrogación da autorización:',
        _AUTHORIZATION_LEVEL: 'Nivel de autorización:',
        _AUTHORIZATION_REQUEST_CODE: 'Código de petición da autorización',
        _BACK: 'Atrás',
        _BLOCK: 'Bloquear',
        _BUY_POLICY: 'subscribir_póliza',
        _BY_USER: 'polo usuario',
        _CANCEL: 'Cancelar',
        _CANCEL_REQUEST: 'Cancelar solicitude',
        _CHARACTERS: 'Caracteres',
        _CODE: 'Código',
        _COMMENT: 'Comentario:',
        _COMPETENCE_LEVEL: 'Nivel de competencia:',
        _COMPETENT_LEVEL: 'Nivel competente:',
        _COMPETENT_USER: 'Usuario competente:',
        _CONFIRM: 'confirmar',
        _CONFIRM_VARIATION: 'confirmar_variación',
        _CONTRACT_NUMBER: 'Número de contrato',
        _CONTRACT_TYPE: 'Tipo de contrato',
        _DATE: 'Data',
        _DEROGATION_LEVEL: 'Nivel de derrogación:',
        _DESCRIPTION: 'Descrición',
        _EFFECTIVE_DATE: 'Data de vixencia',
        _ELEMENTS: 'elementos',
        _EMPTY: 'baleiro',
        _ERROR: 'Erro',
        _ERR_AUTH_DEROGATION_LEVEL: 'ERR_AUTH_DEROGATION_LEVEL',
        _ERR_AUTH_EXTRA_CHECK: 'ERR_AUTH_EXTRA_CHECK',
        _ERR_AUTH_NOT_OPERABLE: 'ERR_AUTH_NOT_OPERABLE',
        _ERR_AUTH_NOT_SAVED: 'ERR_AUTH_NOT_SAVED',
        _EVENT: 'Evento',
        _FILL_IN_REQUEST_STATUS_OR_USER: 'Complete o estado de solicitude ou usuario',
        _FIND: 'atopar',
        _HISTORY: 'Historial',
        _IN_ORDER_TO_ACCEPT_THE_REQUEST_ALL_DOCUMENTS_MUST_BE_VALIDATED: 'Para aceptar a solicitude, téñense que validar todos os documentos',
        _ISSUE_DATE: 'data de emisión',
        _ISSUE_POLICY: 'emisión_póliza',
        _ISSUE_PROPOSAL: 'emisión_proposta',
        _ISSUE_USER: 'emitir usuario',
        _LAST_ACTION: 'Última acción:',
        _LAST_ACTION_DATE: 'Data da última acción:',
        _LAST_EVENT: 'Último evento',
        _LAST_EXECUTED_ACTION: 'Última acción excluída',
        _MANAGEMENT_NODE: 'Nodo de xestión',
        _MODIFY_PROPOSAL: 'modificar_proposta',
        _MODIFY_VARIATION: 'modificar_variación',
        _NEXT_STATUS: 'Seguinte estado',
        _NODE: 'Nodo',
        _NODE_CODE: 'Código de nodo',
        _NODE_IS_MANDATORY: 'O nodo é obrigatorio',
        _NOTES: 'Notas',
        _NOT_AVAILABLE: 'Non dispoñible',
        _NO_ELEMENT_PRESENT: 'Non hai ningún elemento presente',
        _OF: 'de',
        _OPERATION: 'Operación',
        _OPERATION_WAS_ISSUED_ON: 'A operación emitiuse o',
        _OPERATOR: 'Operador:',
        _OPERATOR_DEROGATION_LEVEL: 'Nivel de derrogación do operador:',
        _OPERATOR_LEVEL: 'Nivel de operador:',
        _ORIGIN_TYPE: 'Tipo de orixe:',
        _PM_POLICY_NUMBER: 'N.º póliza mestra',
        _PM_PROPOSAL_NUMBER: 'N.º proposta mestra',
        _POLICY_NUMBER: 'Número de póliza',
        _POLICY_NUMBER_PM: 'Número póliza mestra',
        _POLICY_TYPE: 'Tipo de póliza',
        _POSSIBLE_ACTIONS: 'Posibles accións',
        _POSSIBLE_DEROGATION_LEVEL: 'Posible nivel de derrogación',
        _POSTSELL_CODE: 'Código posvenda',
        _PRIORITY: 'Prioridade',
        _PRODUCT: 'Produto',
        _PRODUCT_TYPE: 'Tipo de produto',
        _PROPOSAL_NUMBER: 'Número de proposta',
        _PROPOSAL_NUMBER_PM: 'Número de proposta mestra',
        _REFERENCE: 'Referencia',
        _REQUESTS_LIST: 'Listaxe de solicitudes',
        _REQUEST_DATE: 'Data de solicitude',
        _REQUEST_DATE_FROM: 'Data de solicitude desde',
        _REQUEST_DATE_FROM_IS_NOT_A_VALID_DATE: 'Data de solicitude desde non é unha data válida',
        _REQUEST_DATE_FROM_MUST_BE_GREATER_THEN_REQUEST_DATE_TO: 'Data de solicitude debe ser maior da data de solicitude a',
        _REQUEST_DATE_TO: 'Data de solicitude a',
        _REQUEST_DATE_TO_IS_NOT_A_VALID_DATE: 'Data de solicitude non é unha data válida',
        _REQUEST_DETAIL: 'Detalle da solicitude',
        _REQUEST_NODE: 'Nodo de solicitude',
        _REQUEST_NUMBER: 'Número de petición',
        _REQUEST_OPERATION: 'Solicitar operación',
        _REQUEST_STATUS: 'Estado de solicitude',
        _REQUEST_STATUS_VARIATION: 'Variación do estado de solicitude',
        _REQUEST_TYPE: 'Tipo de solicitude',
        _REQUEST_USER: 'Usuario da solicitude:',
        _SALE_POINT_GROUP: 'Grupo de punto de venda',
        _SEARCH_UNDER_SUBNET: 'Busca en subrede',
        _SELECT_NODE: 'Seleccionar nodo',
        _STATUS: 'Estado:',
        _STATUS_AFTER_EVENT: 'Estado despois do evento:',
        _STATUS_CHANGES_ARE_NOT_ADMITTED_FOR_THIS_AUTHORIZATION_REQUEST: 'Non se admiten cambios de estado nesta solicitude de autorización',
        _STATUS_VARIATION: 'Variación de Estado',
        _SUBSCRIBER: 'Tomador',
        _SUBSYSTEM: 'Subsistema',
        _SUBSYSTEM_IS_MANDATORY: 'O subsistema é obrigatorio',
        _TO: 'a',
        _TYPE: 'Tipo',
        _USER: 'Usuario',
        _USER_TYPE: 'Tipo de usuario',
        _VARIATION_SUMMARY: 'variación_resumo',
        _VIEW_ELEMENT: 'Ver elemento',
        _VIEW_FROM: 'Ver formulario',
        _WARNINGS: 'Advertencias',
        _WORKFLOW_STATUS: 'Estado do fluxo de traballo',
        _MODIFY_AUTHORIZATION_LABEL: 'Modificar autorización',
        _CONFIRM_PROP_POL_AUTH: 'Confirmar proposta/política en autorización',
        _OPERATION_CONFIRMED: 'Confirmouse a operación'
    },
    _AUTHORIZATION_SEARCH: 'Busca de autorización',
    _AUTHORIZATION_SEARCH_DETAIL: 'Detalle de busca de autorización',
};

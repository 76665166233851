import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {API_PATHS, NgPassproproCoreModule} from '@rgi/ng-passpropro-core';
import {RgiRxRoutingModule} from "@rgi/rx/router"
import {RgiRxEventModule} from "@rgi/rx";
import {SurveyModule} from "./survey/survey.module";
import {SurveyComponentsModule} from "./components/survey-components.module";
import {NgPassproModule} from "@rgi/ng-passpro";
import {SURVEY_API_PATHS, SURVEY_ROUTES} from "./ng-passpropro-survey.routes";
import {SURVEY_EVENTS} from "./ng-passpropro-survey.events";
import {NG_PASSPROPRO_SURVEY_CONFIG, SurveyConfig} from './ng-passpropro-survey-api';
import {StateManagerSurveyModule} from './state/state-manager-survey.module';
import {NgPassProProQuestionnaireModule} from "@rgi/ng-passpropro/questionnaire"
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {NG_PASSPROPRO_SURVEY_TRANSLATIONS} from "./i18n";


@NgModule({
    exports: [],
    imports: [
        CommonModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgPassproproCoreModule,
        NgPassProProQuestionnaireModule,
        RgiRxEventModule.forRoot(SURVEY_EVENTS),
        SurveyComponentsModule,
        NgPassproModule,
        RgiRxRoutingModule.forRoot(SURVEY_ROUTES),
        RgiRxI18nModule.forRoot(NG_PASSPROPRO_SURVEY_TRANSLATIONS),
        SurveyModule,
        StateManagerSurveyModule
    ],
    providers: [
        { provide: API_PATHS, useValue: SURVEY_API_PATHS, multi: true }
    ]
})
class NgPassproproSurveyModule {
    static forRoot(config?: SurveyConfig): ModuleWithProviders<NgPassproproSurveyModule> {
        return {
            ngModule: NgPassproproSurveyModule,
            providers: [
                {provide: NG_PASSPROPRO_SURVEY_CONFIG, useValue: config, multi: false},
            ]
        };
    }
}

export {
    NgPassproproSurveyModule
}

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {Subscription} from 'rxjs';
import {RgiRxEventService} from '@rgi/rx';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {ROUTES, RoutingService} from '@rgi/rx/router';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ROUTES_KEY_DATA, ROUTES_MODAL_HOME} from '../../anag-constants/routes-constants';
import {AnagStatelessOpHomeService} from '../../anag-states/home/anag-stateless-op-home.service';
import {AnagStatelessOpResultService} from '../../anag-states/result/anag-stateless-op-result.service';
import {
  CLOSE_ANAG_MODAL,
  CloseAnagModalEvent,
  NEW_PARTY,
  NewPartyEvent,
  SUBJECT_SELECTED,
  SubjectSelectedEvent
} from '../../anag-events/anag-event';
import {AnagStatelessOpKeyDataService} from '../../anag-states/key-data/anag-stateless-op-key-data.service';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';

@Component({
  selector: 'rgi-anag-modal',
  templateUrl: './anag-modal.component.html',
  providers: [
    RoutingService,
    AnagStatelessOpHomeService,
    AnagStatelessOpResultService,
    AnagStatelessOpKeyDataService
   ],
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagModalComponent implements OnInit, OnModalClose {

  currentRoute = ROUTES_MODAL_HOME;

  modalTitle: string;
  modalData: any;
  modalClose: EventEmitter<any> = new EventEmitter<any>();

  subjectSelectionEvent: Subscription;
  newPartyEventSub: Subscription;
  closeAnagModalEvent: Subscription;
  routingData: AnagRouteData;

  constructor(
    protected eventService: RgiRxEventService,
    protected routingService: RoutingService,
    protected translateService: RgiRxTranslationService,
    @Optional() @Inject(DIALOG_DATA) data: AnagRouteData
  ) {
    this.modalData = data;
  }

  protected setTitle() {
    switch (this.currentRoute) {
      case ROUTES_MODAL_HOME:
        this.translateService.translate('_ANAG_._LABEL_._PARTY_MANAGEMENT_').subscribe(title => this.modalTitle = title).unsubscribe();
        break;
      case ROUTES_KEY_DATA:
        this.translateService.translate('_ANAG_._LABEL_._PARTY_DATA_').subscribe(title => this.modalTitle = title).unsubscribe();
        break;
    }
  }

  ngOnInit() {
    this.setTitle();
    this.routingData = this.modalData ? this.modalData : new AnagRouteData();
    this.routingData.isModalFlow = true;
    this.routingData.redirectToMainFlow = true;
    this.subjectSelectionEvent = this.eventService.listen<SubjectSelectedEvent>(SUBJECT_SELECTED).subscribe(eventInstance => {
      this.modalClose.emit({
        event: eventInstance.event,
        modalData: this.modalData
      });
    });

    this.newPartyEventSub = this.eventService.listen<NewPartyEvent>(NEW_PARTY).subscribe(newPartyEventInstance => {
      switch (newPartyEventInstance.event.routeData.action) {
        case 'keyData':
          this.currentRoute = ROUTES_KEY_DATA;
          this.routingService.navigate<AnagRouteData>(this.currentRoute, newPartyEventInstance.event.routeData);
          this.setTitle();
          break;
        case 'newParty':
          this.modalClose.emit(newPartyEventInstance);
          break;
      }
    });

    this.closeAnagModalEvent = this.eventService.listen<CloseAnagModalEvent>(CLOSE_ANAG_MODAL).subscribe(closeAnagModalInstance => {
      this.modalClose.emit();
    });
  }


  actionClose() {
    this.modalClose.emit();
  }
}

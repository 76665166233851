import { Component, Input, OnInit } from '@angular/core';
import { Insured } from '../../../models/claim-report.model';

import { PlcDateUtils } from '../../../utils/plc-date-utils';

@Component({
  selector: 'lpc-insured-data',
  templateUrl: './insured-data.component.html',
  styleUrls: ['./insured-data.component.css']
})
export class InsuredDataComponent implements OnInit {

  @Input() insured: Insured;

  constructor() { }

  ngOnInit() {
  }

  public formatDate(dateValue: string): string {
    if (!!dateValue) {
      return PlcDateUtils.isoToFormattedDate(PlcDateUtils.isoDateTimeToIsoDate(dateValue));
    }
  }

}

import {Injectable} from '@angular/core';
import {AbstractStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {PortfolioMovement} from '../../models/api-models/portfolio-movement';

export class ReIssueReprintDocumentsNavigation {
  policyNumber: string;
  movement: PortfolioMovement;

}

export class ReIssueReprintDocumentsState extends State {
}

@Injectable()
export class ReIssueReprintDocumentsStateManagerService<T extends ReIssueReprintDocumentsState> extends AbstractStateManager<T> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService
  ) {
    super(activeRoute, stateStoreService);

  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcQuestionnaireComponent} from './lpc-questionnaire/lpc-questionnaire.component';
import {QuestionnairesManagerModule} from '@rgi/questionnaires-manager';
import {LpcErrorMessagesModule} from '../lpc-error-messages/lpc-error-messages.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
  declarations: [LpcQuestionnaireComponent],
  imports: [
    CommonModule,
    QuestionnairesManagerModule,
    LpcErrorMessagesModule,
    RgiRxI18nModule
  ],
  exports: [ LpcQuestionnaireComponent ]
})
export class LpcQuestionnaireModule { }

import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiClaim, ApiEnumType } from '@rgi/digital-claims-common-angular';
import { LimitDto } from '../../dto/limit-dto';
import { LimitsManagementService } from '../../limits-management.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-limit-modal',
  templateUrl: './limit-modal.component.html',
  styleUrls: ['./limit-modal.component.scss']
})
export class LimitModalComponent implements OnInit {

  @Input() apiClaim: ApiClaim;
  @Input() limit: LimitDto;
  @Input() modalType: string;

  // Form
  formGroup: UntypedFormGroup;

  // Select values
  levels: ApiEnumType[];
  levelCodes: ApiEnumType[];
  typologies: ApiEnumType[];
  regimes: ApiEnumType[];
  subtypologies: ApiEnumType[];
  subtypologyCodes: ApiEnumType[];

  errorMessage: string;
  isEditMode: boolean;

  private notManagedLevels = [
    '4' // Istanza di bene
  ];

  private subtypeNoCode = [
    '2', // TESTA
    '4' // DOMICILIATARI
  ];

  constructor(
    @Inject('enumService') private enumService,
    @Inject('eventService') private eventService,
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private limitsManagementService: LimitsManagementService,
    private rxTranslationsService: RgiRxTranslationService
  ) { }

  ngOnInit() {

    const claimId = this.apiClaim.idClaim;
    const limitId = this.limit.id;
    this.isEditMode = this.modalType === 'EDIT';
    this.levelCodes = this.limit.levelCode ? [this.limit.levelCode] : [];
    this.regimes = this.limit.regime ? [this.limit.regime] : [];
    this.subtypologyCodes = this.limit.subtypeCode ? [this.limit.subtypeCode] : [];

    this.initDataForm();
    this.getRegimes(claimId);
    this.levels = this.getLevels();
    this.typologies = this.getTypes(claimId, limitId);
    this.initSubtypes();
  }

  initDataForm() {

    this.formGroup = this.formBuilder.group({
      description: [this.limit.name, Validators.required],
      level: [{ value: this.limit.level.codice, disabled: this.isEditMode }, Validators.required],
      levelCode: [{ value: this.limit.levelCode.codice, disabled: this.isEditMode }, Validators.required],
      typology: [{ value: this.limit.type.codice, disabled: this.isEditMode }, Validators.required],
      amount: [this.limit.initialAmount, Validators.required],
      netGrossExcess: [{ value: this.limit.grossExcess, disabled: this.isEditMode }],
      regime: [{ value: this.limit.regime.codice, disabled: this.isEditMode }, Validators.required],
      subtypology: [{ value: this.limit.subtype.codice, disabled: this.isEditMode }],
      subtypologyCode: [{ value: this.limit.subtypeCode.codice, disabled: this.isEditMode }]
    }, {
      validator: [this.formInputsValidator()]
    });
  }

  private formInputsValidator() {

    return (formGroup: UntypedFormGroup) => {
      const subtypologyField = formGroup.controls.subtypology;
      const subtypologyCodeField = formGroup.controls.subtypologyCode;
      const amountField = formGroup.controls.amount;

      if (subtypologyField.value && !subtypologyCodeField.value && !this.subtypeNoCode.includes(subtypologyField.value)) {
        subtypologyCodeField.setErrors({ required: true });
      } else {
        subtypologyCodeField.setErrors(null);
      }

      const initialAmount = amountField.value;
      if (initialAmount !== null && initialAmount <= 0) {
        amountField.setErrors({ tooLow: true });
      }
    };
  }

  getRegimes(idClaim: number) {

    const regimeField = this.formGroup.get('regime');

    regimeField.disable();

    this.limitsManagementService.getLimitRegimes(idClaim)
      .subscribe(
        (foundRegimes: ApiEnumType[]) => {
          if (foundRegimes.length > 0) {
            this.regimes = foundRegimes;
            if (foundRegimes.length === 1) {
              regimeField.setValue(foundRegimes[0].codice);
            } else if (!this.isEditMode) {
              regimeField.enable();
            }
          }
        }
      );
  }

  getTypes(idClaim: number, idLimit: number) {

    if (!this.isEditMode || this.limit.subtype.codice) {

      return this.enumService.getEnumList('claims.LimitType');
    }

    const typeField = this.formGroup.get('typology');

    this.limitsManagementService.getLimitTypes(idClaim, idLimit)
      .subscribe(
        (foundTypes: ApiEnumType[]) => {
          if (foundTypes.length > 0) {
            this.typologies = foundTypes;
            if (foundTypes.length === 1) {
              typeField.setValue(foundTypes[0].codice);
            } else {
              typeField.enable();
            }
          }
        }
      );
  }

  getLevels() {

    const levels = this.enumService.getEnumList('claims.LimitLevel');

    return levels.filter(
      level => !this.notManagedLevels.includes(level.codice)
    );
  }

  getLevelCodes(idClaim: number, levelId: number) {

    const levelCodeField = this.formGroup.get('levelCode');

    levelCodeField.reset();

    if (!levelId) { return; }

    levelCodeField.disable();

    this.limitsManagementService.getLimitLevelCode(idClaim, levelId)
      .subscribe(
        (levelCodes: ApiEnumType[]) => {
          this.levelCodes = levelCodes;

          if (levelCodes.length === 0) {

            return;
          } else if (levelCodes.length === 1) {

            levelCodeField.setValue(levelCodes[0].codice);
          } else {

            levelCodeField.enable();
          }
        }
      );
  }

  onLevelIdChange(event: any) {

    const levelId = event.target.value;

    this.getLevelCodes(this.apiClaim.idClaim, levelId);
  }

  onTypologyChange(event: any) {

    if (!this.isEditMode) {

      const typologyId = event.target.value;

      this.refreshSubtypology(typologyId);
    }
  }

  onSubtypologyChange(event: any) {

    const subtypologyId = event.target.value;

    this.refreshSubtypologyCode(subtypologyId);
  }

  isSubtypologyCodeRequired() {

    const subtype = this.formGroup.get('subtypology').value;
    return subtype && !this.subtypeNoCode.includes(subtype);
  }

  private initSubtypes() {

    if (this.isEditMode) {
      this.subtypologies = this.enumService.getEnumList('claims.LimitSubtype');
    } else {
      this.limitsManagementService.getLimitSubtypesForNewLimit()
        .subscribe((subtypologies: ApiEnumType[]) => this.subtypologies = subtypologies);
    }
  }

  private refreshSubtypology(typologyId: number) {

    const subtypologyField = this.formGroup.get('subtypology');
    const subtypologyCodeField = this.formGroup.get('subtypologyCode');

    if (!typologyId) {
      subtypologyField.reset();
      this.initSubtypes();
      this.subtypologyCodes = [];
      subtypologyCodeField.enable();
      this.enableLevelAndLevelCode();
      return;
    }

    this.limitsManagementService.getLimitSubtypesByType(typologyId)
      .subscribe((subtypologies: ApiEnumType[]) => {
        this.subtypologies = subtypologies;

        if (!subtypologies.find(subtype => subtype.codice === subtypologyField.value)) {
          subtypologyField.reset();
          this.subtypologyCodes = [];
          subtypologyCodeField.enable();
          this.enableLevelAndLevelCode();
        }
      });
  }

  private refreshSubtypologyCode(subtypologyId: number) {

    const subtypologyCodeField = this.formGroup.get('subtypologyCode');

    if (!subtypologyId) {
      this.subtypologyCodes = [];
      subtypologyCodeField.enable();
      this.enableLevelAndLevelCode();
      return;
    }

    this.setAndDisableLevelAndLevelCodeBySubtype(subtypologyId);

    if (this.subtypeNoCode.includes(String(subtypologyId))) {
      subtypologyCodeField.disable();
    } else {
      subtypologyCodeField.enable();
      this.limitsManagementService.getLimitSubtypeCodesBySubtype(this.apiClaim.idClaim, subtypologyId)
        .subscribe((subtypologyCodes: ApiEnumType[]) => {
          this.subtypologyCodes = subtypologyCodes;

          if (subtypologyCodes.length === 1) {
            subtypologyCodeField.setValue(subtypologyCodes[0].codice);

          } else if (!subtypologyCodes.find(subtypeCode => subtypeCode.codice === subtypologyCodeField.value)) {
            subtypologyCodeField.reset();
          }
        });
    }
  }

  private enableLevelAndLevelCode() {

    const levelField = this.formGroup.get('level');
    const levelCodeField = this.formGroup.get('levelCode');

    if (levelField.disabled) {
      levelField.enable();
      if (this.levelCodes.length > 1) {
        levelCodeField.enable();
      }
    }
  }

  private setAndDisableLevelAndLevelCodeBySubtype(subtypeId: number) {

    this.limitsManagementService.getLimitLevelBySubtype(subtypeId)
      .subscribe((level: ApiEnumType) => {

        if (level) {
          const levelField = this.formGroup.get('level');
          levelField.disable();
          if (levelField.value !== level.codice) {
            levelField.setValue(level.codice);
            this.getLevelCodes(this.apiClaim.idClaim, Number(level.codice));
          }
        }
      });
  }

  private isLimitModified() {

    if (!this.isEditMode) {
      return false;
    }

    const typeId = this.limit.type.codice;
    const newTypeId = this.formGroup.controls.typology.value;
    const initialAmount = this.limit.initialAmount;
    const newInitialAmount = this.formGroup.controls.amount.value;
    const name = this.limit.name;
    const newName: string = this.formGroup.controls.description.value;

    if (newTypeId === typeId && newInitialAmount === initialAmount
      && newName.trim().toUpperCase() === name.trim().toUpperCase()) {

      return false;
    }
    return true;
  }

  onConfirm() {

    let errorMessage: string = null;

    if (this.formGroup.invalid) {
      this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES')
        .subscribe(res => errorMessage = res)
    } else if (this.isEditMode && !this.isLimitModified()) {
      this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._NO_CHANGE_MADE')
      .subscribe(res => errorMessage = res)
    }

    if (errorMessage) {
      this.errorMessage = errorMessage;
      return;
    }

    this.errorMessage = null;

    const newLimit = this.getNewLimit();

    this.eventService.broadcastEvent('start-loader');

    this.limitsManagementService.newLimit(this.apiClaim.idClaim, newLimit).subscribe(
      outcome => {
        this.eventService.broadcastEvent('stop-loader');
        this.activeModal.close(outcome);
      }
    );

    return false;
  }

  getNewLimit() {

    const id = this.limit.id;
    const typeId = this.formGroup.get('typology').value;
    const initialAmount = this.formGroup.get('amount').value;
    const levelId = this.formGroup.get('level').value;
    const levelCodeId = this.formGroup.get('levelCode').value;
    const regimeId = this.formGroup.get('regime').value;
    const grossExcess = this.formGroup.get('netGrossExcess').value;
    const factorCode = this.limit.factorCode || 'MANINS'; // Hardcoded in case of manual insertion
    const name = this.formGroup.get('description').value.trim();
    const subtypeId = this.formGroup.get('subtypology').value;
    const subtypeCodeId = this.formGroup.get('subtypologyCode').value;

    const type = new ApiEnumType(typeId, null);
    const level = new ApiEnumType(levelId, null);
    const levelCode = new ApiEnumType(levelCodeId, null);
    const regime = new ApiEnumType(regimeId, null);
    const subtype = new ApiEnumType(subtypeId, null);
    const subtypeCode = new ApiEnumType(subtypeCodeId, this.isEditMode ? this.limit.subtypeCode.descrizione : null);
    const masterPolicy = this.isEditMode && typeId !== '1' ? this.limit.masterPolicy : false; // Not managed yet
    const unique = false; // Not managed yet
    const active = true;

    const dto = {
      id,
      type,
      initialAmount,
      level,
      levelCode,
      regime,
      grossExcess,
      factorCode,
      name,
      subtype,
      subtypeCode,
      masterPolicy,
      unique,
      active
    };

    return new LimitDto(dto);
  }

  closeModal(event: any) {

    this.activeModal.close();

    event.stopPropagation();

    return false;
  }
}

<form [formGroup]="group">
  <label>
    <lic-help-icon [helpFile]="config.helpFile" [title]="value"></lic-help-icon>
    <span>{{value}}</span>
    <lic-required *ngIf="config.compulsory" required="true"></lic-required>
  </label>
  <rgi-rx-button-toggle
      [(ngModel)]="selectedValue"
      [formControlName]="config.code"
      [attr.data-qa]="'factor' + config.code"
      [attr.data-mod] = "'factor' + config.code + modifiable"
      [attr.data-obb] = "'factor' + config.code + config.compulsory"
      rgiRxGrid="2" style="width: 100%">
      <div rgiRxGridCol *ngFor="let option of options; let i = index" style="width: 50%">
        <button style="width: 100%" rgi-rx-button rgiRxValueOption [value]="option.value" [attr.data-qa]="config.code + 'radio-label-' + i">{{option.label}}</button>
      </div>
  </rgi-rx-button-toggle>
</form>


  <claims-stepper [currentStep]="currentStep" [labels]="stepperLabels"></claims-stepper>

<div class="container-fluid policy-table" data-qa="policy-list">
  <table class="table-responsive table coreDT">
    <thead>
      <tr class="head-result thDataTable">

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-number-label" class="policy-number-label">{{ '_CLAIMS_._POLICY_NR' | translate
            }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-description-label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-holder-label">{{ '_CLAIMS_._POLICY_HOLDER' | translate }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" *ngIf="claimsOriginalEffectDateEnable">
          <span attr.data-qa="policy-originaleffectdate-label">{{ '_CLAIMS_._ORIGINAL_EFFECT_DATE' | translate }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="policy-effectdate-label">{{ '_CLAIMS_._EFFECT_DATE' | translate }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="policy-expirationdate-label">{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-cancellationdate-label">{{ '_CLAIMS_._CANCELLATION_DATE' | translate }}</span>
        </td>

        <td *ngIf="hasUnit()" class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <span attr.data-qa="policy-unit-label">{{ '_CLAIMS_._UNITS' | translate }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="policy-actions-label"></span>
        </td>

      </tr>

    </thead>

    <tbody>
      <tr *ngFor="let policy of policies; let even = even; let odd = odd; let policyIndex = index"
        [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
        attr.data-qa="policy-result-{{policyIndex}}">
        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-enable-{{policyIndex}}" class="claims-popover icon rgifont"
            *ngIf="policy.claimBranchCodes || externalPortfolio"
            [ngClass]="isPolicyCanceled(policy) ? 'rgi-survey_ok yellow-color' : 'rgi-survey_ok green-color'">
            <span class="claims-popover-container popover-left">
              <div *ngIf="isPolicyCanceled(policy)">{{ '_CLAIMS_._INVALID_POLICY' | translate }}</div>
              <div *ngIf="!isPolicyCanceled(policy)">{{ '_CLAIMS_._VALID_POLICY' | translate }}</div>
            </span>
          </span>
          <span attr.data-qa="policy-number-{{policyIndex}}">{{ policy.number }}</span>
        </td>
        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-description-{{policyIndex}}">{{ policyDescription(policy) }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-holder-{{policyIndex}}">{{ policy.holder }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" *ngIf="claimsOriginalEffectDateEnable">
          <span *ngIf="policy.originalEffectDate" attr.data-qa="policy-originaleffectdate-{{policyIndex}}">{{
            policy.originalEffectDate | date:'dd/MM/yyyy' }}</span>
          <span *ngIf="!policy.originalEffectDate" attr.data-qa="policy-originaleffectdate-{{policyIndex}}">-</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="policy-effectdate-{{policyIndex}}">{{ policy.effectDate | date:'dd/MM/yyyy' }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="policy-expirationdate-{{policyIndex}}">{{ policy.expirationDate | date:'dd/MM/yyyy'
            }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="policy-cancellationdate-{{policyIndex}}">{{ policy.cancellationDate?
            (policy.cancellationDate | date:'dd/MM/yyyy') : '-' }}</span>
        </td>

        <td *ngIf="policy.units" class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <span *ngFor="let unit of policy.units; let policyIndex = index; let last = last">
            <span attr.data-qa="policy-unit-{{policyIndex}}">{{ unit.description}}</span><span *ngIf="!last">, </span>
          </span>

        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="policy-actions-{{policyIndex}}">

      <tr>
        <td class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <button type="button" class="btn btn-flat btn-sm" (click)="policyDetails(policy)"
            [disabled]="externalPortfolio">
            <span class="rgifont rgi-search2"></span>
          </button>
        </td>
        <td class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <button type="button" class="btn btn-flat btn-sm" [ngClass]="!isPolicyEnabled(policy)? 'claims-popover' : ''"
            [disabled]="!isPolicyEnabled(policy)" (click)="selectPolicyDetail(policy)">

            <span class="action-icon rgifont rgi-chevron-right">
              <span *ngIf="!isPolicyEnabled(policy)" class="claims-popover-container">
                <div>{{ '_CLAIMS_._MESSAGE_._POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY' | translate }}</div>
              </span>
            </span>

          </button>
        </td>
      </tr>

      </span>
      </td>

      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="!hasResult()">
  <div>
    <span class="icon-attention-sign"></span>
    <span data-qa="no-policies-show" class="notasks-message">{{ '_CLAIMS_._NO_POLICY_TO_SHOW' | translate }}</span>
    <span class="icon-notepad"></span>
  </div>
</div>

<div class="btn-group btn-group-justified btn-group-justified-claim">

  <div class="btn-group">
    <button (click)="back()" class="btn btn-warning pull-right text-uppercase">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
  </div>

</div>
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Documents} from '@rgi/pnc-postsales/lib/resources/model/api/document';
import {REST_API_PORTFOLIO_PC_CONF} from './re-issue-resources-path';
import {ErrorManagementService} from "../../re-issue-core/error-management.service";

@Injectable({
  providedIn: 'root'
})
export class ReIssueDocumentsResourceServiceService {

  private CONTRACT_PATH = 'contract/';
  private apiPolicyDataConf: any;

  constructor(protected http: HttpClient,
              protected errorHandler: ErrorManagementService,
              @Inject('environment') private apiConf: any) {
    this.apiPolicyDataConf = REST_API_PORTFOLIO_PC_CONF;
  }

  getDocuments(proposalId: string, movementCode: string): Observable<Documents> {
    return this.http.get<Documents>(
      this.getDocumentsUri(proposalId, movementCode)
    ).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  private getDocumentsUri(proposalId: string, movementCode: string): string {
    return this.getBaseUri()
      + proposalId
      + '/documents?movementCode=' + movementCode;
  }

  downloadDocuments$(policyNumber: string, documentCode: string): Observable<Blob> {
    return this.http.get(`${this.getBaseUri()}${policyNumber}/document/${documentCode}/download`, {responseType: 'blob'});
  }

  private getBaseUri(): string {
    return this.apiConf.api.portal.path
      + this.apiPolicyDataConf.v2
      + this.apiPolicyDataConf.path
      + this.CONTRACT_PATH
  }
}

export enum CreditorType {
    FISICO = '7',
    AGENTI = '5'
}

export enum KarmaFactorCode {
  FIRSTTERM_CODE = 'firstTerm',
  EFFECTDATE_CODE = 'effectDate',
  FREQUENCY_CODE = 'frequency',
  PAYMENTMODES_CODE = 'payment_modes',
  EXPIRATIONDATE_CODE = 'expirationDate',
  BENEFICIARY_ID_CODE = 'beneficiary',
  IBAN = 'iban',
  FIXED_FEES = 'fixedFees',
  AMOUNT = 'amount',
  OCCURRENCE = 'occurrenceNumber'
}

export enum ProfileType {
  DISINVESTMENT = 'disinvestmentProfiles',
  INVESTMENT = 'investmentProfiles'
}

export enum TypeRefund {
  PRES_TIPO_RIMBORSO_TOTALE = '1',
  PRES_TIPO_RIMBORSO_PARZIALE = '2'
}

export enum ReservationRiskStatus {
  DELETED = 'D',
  ADDED = 'A',
  MODIFIED = 'M'
}


export enum InfoType {
  date = 'date',
  string = 'string',
  currency = 'currency',
  number = 'number',
  percentage = 'percentage'
}

export enum RestErrorType {
  BLOCKING = 0,
  WARNING = 1
}

export enum ClaimType {
  DeathClaim = '1',
  DisabilityClaim = '6',
  SeriousIllnessClaim = '7',
  InabilityClaim = '8',
}

export enum ProductTypeCodes {
  PIP = '10',
  FPA = '15',
  FIP = '15',
  UNITLINKED = '8',
  LTC = '14',
  TCM = '1',
  CAPITALIZZAZIONE = '3',
  RENDITA = '6',
  VITAINTERA = '2',
  CAPITALEDIFF = '5',
  CREDITPROTECTIONINS = '13',
  MISTA = '4',
  INDEXLINKED = '9',
  TFISSO = '7',
  CESSIONEQUINTOSTIP = '12',
}

export enum FormFieldType {
  PARTY = 'PARTY',
  STRING = 'STRING',
  ENUM = 'ENUM',
  DOUBLE = 'DOUBLE',
  DATE = 'DATE',
  INT = 'INT'
}

export enum SystemProperties {
  PREVAL_DELEGATE = 'PrevalorizationDelegate',
  PREVAL_THIRDPAYER = 'PrevalorizationThirdPayer',
  PREVAL_LEGALGUARDIAN = 'PrevalorizationLegalGuardian',
}

export type FactorToolCode =
  FACTOR_TOOL_CODE.FIRSTTERM_CODE |
  FACTOR_TOOL_CODE.EFFECTDATE_CODE |
  FACTOR_TOOL_CODE.FREQUENCY_CODE |
  FACTOR_TOOL_CODE.PAYMENTMODES_CODE |
  FACTOR_TOOL_CODE.EXPIRATIONDATE_CODE |
  FACTOR_TOOL_CODE.BENEFICIARY_ID_CODE |
  FACTOR_TOOL_CODE.MIN_THRESHOLD_CODE |
  FACTOR_TOOL_CODE.MAX_THRESHOLD_CODE |
  FACTOR_TOOL_CODE.OCCURRENCE_NUMBER_CODE |
  FACTOR_TOOL_CODE.SURRENDER_AMOUNT_CODE |
  FACTOR_TOOL_CODE.MINIMAL_AMOUNT_CODE |
  FACTOR_TOOL_CODE.AMOUNT_CODE |
  FACTOR_TOOL_CODE.CURRENCY_CODE |
  FACTOR_TOOL_CODE.IBAN_CODE |
  FACTOR_TOOL_CODE.FIXED_FEES_CODE |
  FACTOR_TOOL_CODE.PERIODIC_DISCOUNT_PERCENTAGE_CODE |
  FACTOR_TOOL_CODE.HOLDER_CODE
;

export enum FACTOR_TOOL_CODE {
  FIRSTTERM_CODE = '_1TERM',
  EFFECTDATE_CODE = '_EFFOP',
  FREQUENCY_CODE = '_OPZFR',
  PAYMENTMODES_CODE = '_OPZPM',
  EXPIRATIONDATE_CODE = 'expirationDate',
  BENEFICIARY_ID_CODE = '_OPBEN',
  MIN_THRESHOLD_CODE = '_MINTS',
  MAX_THRESHOLD_CODE = '_MAXTS',
  OCCURRENCE_NUMBER_CODE = '_NUMOC',
  SURRENDER_AMOUNT_CODE = '_AMTOP',
  MINIMAL_AMOUNT_CODE = '_MALMT',
  AMOUNT_CODE = 'amount',
  CURRENCY_CODE = 'currency',
  IBAN_CODE = '_CIBAN',
  FIXED_FEES_CODE = 'fixedFees',
  PERIODIC_DISCOUNT_PERCENTAGE_CODE = '_PCOUP',
  HOLDER_CODE = 'holder'
}

export enum FACTOR_TYPE {
  LIST = '0',
  NUMERIC = '1',
  STRUCT = '2',
  BOOL = '3',
  DATE = '4',
  STRING= '5'
}

export enum ToolCode {
  SCHEDULED_PREMIUM = '102', // VERSAMENTO PROGRAMMATO
  SCHEDULED_PARTIAL_WITHDRAWAL = '202', // RISCATTO PROGRAMMATO
  LOCK_IN = '301',
  STOP_LOSS = '302',
  PROGRESSIVE_SWITCH = '306',
  RISK_REDUCTION = '600',
  PERIODIC_COUPON = '789'
}

// da aggiornare con i nuovi codici di PP
export enum PPToolCode {
  SCHEDULED_PREMIUM = '_QUIET', // VERSAMENTO PROGRAMMATO
  SCHEDULED_PARTIAL_WITHDRAWAL = '_SCHPS', // RISCATTO PROGRAMMATO
  LOCK_IN = '_LCKIN',
  STOP_LOSS = '_STPLS',
  PROGRESSIVE_SWITCH = '_SCHSW',
  RISK_REDUCTION = '_DERIS',
  PERIODIC_COUPON = '_CEPER'
}

export type ToolCodeIdType =
  | ToolCode.SCHEDULED_PREMIUM
  | ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL
  | ToolCode.LOCK_IN
  | ToolCode.STOP_LOSS
  | ToolCode.PROGRESSIVE_SWITCH
  | ToolCode.RISK_REDUCTION
  | ToolCode.PERIODIC_COUPON;

export type ToolCodeType =
  | PPToolCode.SCHEDULED_PREMIUM
  | PPToolCode.SCHEDULED_PARTIAL_WITHDRAWAL
  | PPToolCode.LOCK_IN
  | PPToolCode.STOP_LOSS
  | PPToolCode.PROGRESSIVE_SWITCH
  | PPToolCode.RISK_REDUCTION
  | PPToolCode.PERIODIC_COUPON;


export enum CustomPropertyCode {
  LIFE_QUICK_QUOTATION_PURCHASE_THROUGH_IID = '_LQQDN'
}

export enum ErrorType {
  ERROR = '0',
  WARNING = '1'
}

export enum PropertyType {
  BOOLEAN = 1,
  LIST = 2
}

export enum OwnerType {
  TIPO_OWNER_PRODOTTO = 1,
  TIPO_OWNER_RISCHIO = 2
}

  export enum fundTypeId {
    GS = '4',
  }

import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChatMessage } from './dto/chat-message';
import { ChatUser } from './dto/chat-user';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private baseApiUrl: string;
  private urlChatMessage = '/claims/chat-messages';
  private urlUsers = '/users';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getChats(currentUser: string, selectedUser: ChatUser, claim: { claimNumber: string }, potentialClaim: { reportNumber: string }, showAll) {
    let queryString = '?sender=' + currentUser;
    if (selectedUser) {
      queryString += '&recipient=' + selectedUser.userName;
    }
    if (claim) {
      queryString += '&claimNumber=' + claim.claimNumber;
    }
    if (potentialClaim) {
      queryString += '&potentialClaimNumber=' + potentialClaim.reportNumber;
    }
    queryString += '&showAll=' + showAll;
    return this.httpClient.get(this.baseApiUrl + this.urlChatMessage + queryString);
  }

  updateChatMessages(messages: ChatMessage[]) {
    return this.httpClient.put(this.baseApiUrl + this.urlChatMessage, messages);
  }

  sendMessage(message: ChatMessage) {
    return this.httpClient.post(this.baseApiUrl + this.urlChatMessage, message);
  }

  getLastMessages(messages: any[]) {
    const map = new Map();
    messages.forEach((item) => {
      const key = item.user;
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getUsers(currentUser: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlChatMessage + this.urlUsers + '?sender=' + currentUser);
  }
}

import { ActiveRoute } from '@rgi/rx/router';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import { of } from 'rxjs';
import { GroupPolicyVcontRouteData } from '../../group-policy-models/group-policy-vcont-date';
import { GroupPolicyStateVcontQuestionnaire } from '../group-policy-state';
import { GroupPolicyStatelessOpQuestionnaireService } from './group-policy-stateless-op-questionnaire.service';


/**
 * @author: dmasone
 * @description: State Manager used for render vcont questionnaire step
 */
export class GroupPolicyStateManagerQuestionnaire extends AbstractStateManager<GroupPolicyStateVcontQuestionnaire> {

    constructor(activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: GroupPolicyStatelessOpQuestionnaireService) {
        super(activeRoute, stateStoreService);
        const statePolicyData = this.stateStoreService.has(this.activeRoute.key) ?
            this.stateStoreService.get<GroupPolicyStateVcontQuestionnaire>(this.activeRoute.key) :
            new GroupPolicyStateVcontQuestionnaire(this.activeRoute.key);
        const initial$ = this.statelesOps.initQuestionnaire$(of(statePolicyData), this.activeRoute);
        this.updateState$(initial$);
    }

    public actionGoForword(resId: string) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionGoForword$(of(st), resId, this.activeRoute));
    }

    public actionBack(previousStepData: GroupPolicyVcontRouteData, activeRoute: ActiveRoute, targetRoute: string) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionBack$(of(st), previousStepData, activeRoute, targetRoute));
    }

    public actionClose(previousStepData: GroupPolicyVcontRouteData) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionClose$(of(st), previousStepData, this.activeRoute));
    }

}

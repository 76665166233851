import {Directive, HostBinding, Input} from '@angular/core';
import {Highlightable} from '@angular/cdk/a11y';

@Directive({
  selector: '[rgiRxTabWrapper]',
  exportAs: 'rgiRxTabWrapper'
})
export class RgiRxTabWrapperDirective implements Highlightable {

  private _disabled: boolean;
  private _active: boolean;
  private _label: string;

  constructor() {
  }

  @Input() @HostBinding('class.rgi-ui-disabled') get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input() @HostBinding('class.rgi-ui-active') get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  @Input() get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  getLabel(): string {
    return this._label;
  }

  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
    }
  }

  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
    }
  }
}

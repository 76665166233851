import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../models/consts/lpc-consts';
import { FinancialService } from '../../services/financial.service';

@Component({
  selector: 'lpc-financial-search-card',
  templateUrl: './financial-search-card.component.html',
  styleUrls: ['./financial-search-card.component.scss']
})
export class FinancialSearchCardComponent implements OnInit {

  @Output() eventPropagation: EventEmitter<string | { [ key: string ]: any}> = new EventEmitter<string | { [ key: string ]: any}>();
  @Input() id: any;

  public typeSearchFunds: boolean;
  public typeSearchProfiles: boolean;
  protected functionListService: any;
  public showProfiles = true;
  public products: any[];
  public errors: any[] = [];
  loader = false;
  mobileLoaderActive: boolean;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    profileSearch: new UntypedFormGroup({
      profile: new UntypedFormControl(),
      product: new UntypedFormControl(),
    }),
    fundSearch: new UntypedFormGroup({
      fund: new UntypedFormControl(),
      isin: new UntypedFormControl(),
      fundCode: new UntypedFormControl()
    })
  });

  /**
   * `functionListService` is a js Service injected used to check the PUFX configurations and has these methods on it.
   *
   * `getFunctions`: ƒ _getFunctionList() --> returns all the configs
   *
   * `getFunctionsPromise`: ƒ _getFunctionsListPromise()
   *
   * `isAuthorizedFor`: ƒ _isAuthorizedFor(functionName) --> @returns a boolean for a config string if matches the config itsel
   *
   * ---- declared on the authorizations.xml
   *
   * `isAuthorizedForAllInList`: ƒ _isAuthorizedForAllInList(functionsList)
   * `isAuthorizedForOneInList`: ƒ _isAuthorizedForOneInList(functionsList)
   * `setFunctions`: ƒ _setFunctionList(list)
   */
  constructor(
    protected financialService: FinancialService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Inject(PV_TOKEN.SHOW_LOADER_MOBILE) protected showMobileLoader: any
    ) {
      this.functionListService = injector.get('functionListService');
      this.mobileLoaderActive = !!showMobileLoader ? showMobileLoader : false;
    }

  ngOnInit() {
    this.viewTypeSearch();

    if (this.showProfiles) {
      this.loader = true;
      this.financialService.getProducts().subscribe(response => {
        this.products = response;
        this.loader = false;
      },
      (error => {
        this.loader = false;
      }));
    }
  }


  viewTypeSearch() {
    this.typeSearchFunds = this.functionListService.isAuthorizedFor('ptflife.consultation.fin.filter.funds');
    this.typeSearchProfiles = this.functionListService.isAuthorizedFor('ptflife.consultation.fin.filter.profiles');
    if (!this.typeSearchProfiles && this.typeSearchFunds) {
      this.showProfiles = false;
    }
    if (!this.typeSearchProfiles && !this.typeSearchFunds) {
      this.formGroup.disable();
      this.errors.push({type: 'error', msg: 'Errore di Sistema, abilitare almeno un tipo di ricerca tra profili e fondi'});
      this.refreshCard();
    }
  }

  onProfilesClick() {
    this.errors = [];
    this.showProfiles = true;
    this.formGroup.get('fundSearch').reset();
    this.refreshCard();
  }

  errorPresent(): boolean {
    return this.errors.some(error => error.type === 'error');
  }

  onFundsClick() {
    this.errors = [];
    this.showProfiles = false;
    this.formGroup.get('profileSearch').reset();
    this.refreshCard();
  }

  clean() {
    this.errors = [];
    this.formGroup.reset();
  }

  openSession() {
    this.errors = [];
    this.loader = true;
    const info = {params: {}, type: EMPTY_STR};
    if (!this.showProfiles) {
      info.type = 'fund';
      const isinCode = this.formGroup.get('fundSearch').get('isin').value;
      const fundName = this.formGroup.get('fundSearch').get('fund').value;
      const fundCode = this.formGroup.get('fundSearch').get('fundCode').value;
      this.getFundList('1', '10', isinCode, fundName, fundCode).subscribe(response => {
        const funds = response.data;
        if (!!funds.length) {
          info.params = {list: funds, isinCode, fundName, fundCode};
          this.eventPropagation.emit({eventName: JS_EVENT.OPEN_FINANCIAL_LIST, info});
        } else {
          this.errors.push({type: 'warning', msg: 'Nessun risultato.'});
          this.refreshCard();
        }
        this.loader = false;
      },
      (error => {
        this.loader = false;
      }));
    } else {
      // search profiles
      info.type = 'profile';
      const productId = this.formGroup.get('profileSearch').get('product').value;
      const profileName = this.formGroup.get('profileSearch').get('profile').value;
      this.getProfileList('1', '10', productId, profileName).subscribe(response => {
        const profiles = response.data;
        if (!!profiles.length) {
          const productCode = !!productId ? this.products.find(product => product.id === productId).code : EMPTY_STR;
          info.params = {list: profiles, productId, productCode, profileName};
          this.eventPropagation.emit({eventName: JS_EVENT.OPEN_FINANCIAL_LIST, info});
        } else {
          this.errors.push({type: 'warning', msg: 'Nessun risultato.'});
          this.refreshCard();
        }
        this.loader = false;
      });
    }
  }

  private refreshCard() {
    const angularGridInstance = this.injector.get('angularGridInstance');
    if (angularGridInstance !== 'undefined') {
      angularGridInstance.cards.refresh();
    }
  }

  getErrors(type: string) {
    const errors = this.errors.filter(error => error.type === type);
    return errors.map(error => error.msg);
  }

  getFundList(page: string, perPage: string, isinCode: string, fundName: string, fundCode: string): Observable<any> {
    return this.financialService.getFundDetailsByQueryParams(
      page, perPage, isinCode ? isinCode : null, fundName ? fundName : null, fundCode ? fundCode : null);
  }

  getProfileList(page: string, perPage: string, productId: string, profileName: string): Observable<any> {
    return this.financialService.getProfilesByQueryParams(
      page, perPage, profileName ? profileName : null, productId ? productId : null);
  }

}


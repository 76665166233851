import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AnagSubject } from '../dsc-shared-model/dsc-model-subject';
import { ServiceInfo } from '../dsc-shared-model/dsc-model-utility';

interface ResSingleSubject {
serviceInfo: ServiceInfo,
subject: AnagSubject
}

@Injectable({
  providedIn: 'root'
})
export class DscHeirService {
  private baseApiUrl: string;
 
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  getSingleSubject(objectId: string, nodeId: string): Observable<ResSingleSubject> {
    const url = `${this.baseApiUrl}/anag/subject/${objectId}?idNode=${(nodeId)}`;
    return this.httpClient.get<ResSingleSubject>(url);
  }
}

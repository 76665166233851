import {NgbDateAdapter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {Inject, Injectable, Optional} from "@angular/core";
import {QUEST_CFG, QuestionnaireCfg} from "../../questionnaire-cfg";

@Injectable()
export class NgPassProProDateAdapter extends NgbDateAdapter<number> {

  private DELIMITER = "-";

  /** Ho mantenuto l'inject della configurazione per eventuali possibili cambiamenti futuri, ma questo adapter prende il valore presente nell'oggetto answer.value
   * che è sempre in formato date.getTime() => ms dall'01/01/1970, quindi arriva sempre un numero intero:
   *    • negativo se prima del 01/01/1970
   *    • zero se uguale a 01/01/1970
   *    • positivo se dopo il 01/01/1970
   *
   * Il suo ciclo di vita è quindi:
   *    Valore numerico (ms) del fattore => Valore ngModel con NgbDateStruct => Valore numerico (ms) del fattore
   * */
  constructor(@Optional() @Inject(QUEST_CFG) private questionnaireConfig: QuestionnaireCfg) {
    super();
    if (questionnaireConfig && questionnaireConfig.dateSeparator) {
      this.DELIMITER = questionnaireConfig.dateSeparator;
    }
  }

  fromModel(value: number | null): NgbDateStruct | null {
    if (value != null) {
      let d = new Date(value);
      return {
        year:  d.getFullYear(),
        month: d.getMonth()+1,
        day: d.getDate()
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): number | null {
    if (!date) {
      return null;
    }
    let d = new Date(date.year, date.month-1, date.day);
    return d.getTime();
  }

}

import {Injectable} from '@angular/core';
import {RGI_FIELD_TYPE, RgiFormField} from '../resources/model/common/rgi-form-field';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  createForm(fields: RgiFormField[]) {
    const formGroup = new UntypedFormGroup({});
    if (fields) {
      const visibleFields = fields.filter(field => field.visible);
      visibleFields.forEach(field => {
        const formCtrl = new UntypedFormControl({
          value: field.value,
          disabled: !field.editable
        }, {
          validators: this.createValidators(field),
          updateOn: this.getFieldUpdateOn(field)
        });
        formGroup.addControl(field.code, formCtrl);
      });
    }
    return formGroup;
  }

  private createValidators(field: RgiFormField) {
    const validators = [];
    if (field.mandatory) {
      validators.push(Validators.required);
    }
    if (field.pattern) {
      validators.push(Validators.pattern(field.pattern));
    }
    if (field.type === RGI_FIELD_TYPE.NUMERIC) {
      if (field.minValue) {
        validators.push(Validators.min(parseInt(field.minValue, 10)));
      }
      if (field.maxValue) {
        validators.push(Validators.max(parseInt(field.maxValue, 10)));
      }
    }
    return validators;
  }

  private getFieldUpdateOn(field: RgiFormField): 'change' | 'blur' {
    switch (field.type) {
      case RGI_FIELD_TYPE.STRING:
      case RGI_FIELD_TYPE.DATE:
      case RGI_FIELD_TYPE.DATETIME:
      case RGI_FIELD_TYPE.NUMERIC:
      case RGI_FIELD_TYPE.INSURANCE_HOUR:
        return 'blur';
      default:
        return 'change';
    }
  }
}

export  class OutboundDocumentsReference {
  policyNumber: string;
  proposalNumber: string;
  movementId: string;
  movementDesc: string;

  constructor(policyNumber : string, proposalNumber : string, movementNumber: string, movementDesc: string) {
    this.policyNumber = policyNumber;
    this.proposalNumber = proposalNumber;
    this.movementId = movementNumber;
    this.movementDesc = movementDesc;
  }
}

import { ClauseEntity } from './clause-entity';
import { LiqDocumentEntity } from './liq-document-entity';
import { SubjectEntity } from './subject-entity';

export class DocumentEntity {
    documentId: string;
    documentName: string;
    idDocConfig: string;
    code: string;
    selected: boolean;
    idDocInfo: string;
    status: string;
    validated: boolean;
    validationError: string;
    listLiqGenerations: Array<LiqDocumentEntity>;
    data: string;
    categoryId: string;
    user: string;
    note: string;
    category: string;
    mailed: boolean;
    idAnagrafe: string;
    fileType: string;
    clauses: Array<ClauseEntity>;
    subjectAt: SubjectEntity;
}

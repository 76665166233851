import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { mapObjForVcont } from '../../adapters/group-policy-utils';
import { ROUTES_GP_VCONT_DATES, ROUTES_GP_VCONT_QUESTIONNAIRE } from '../../group-policy-constants/routes-constants';
import { Surveys } from '../../group-policy-models/group-policy-issue-guarantees';
import { ErrorCode } from '../../group-policy-models/group-policy-issue-policy-data';
import { Documents } from '../../group-policy-models/group-policy-issue-summary';
import { GroupPolicyVcontRouteData, NextRouteResponse } from '../../group-policy-models/group-policy-vcont-date';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyStateVcontQuestionnaire } from '../group-policy-state';
// tslint:disable:max-line-length

/**
 * @author: dmasone
 * @description: Service used for render vcont questionnaire step
 */
@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpQuestionnaireService {


  constructor(
    protected groupPolicyService: GroupPolicyResourceService,
    protected routingService: RoutingService,
    protected apiRestErrorService: GroupPolicyApiRestErrorService,
    protected integrationService: GroupPolicyIntegrationService) { }

  public initQuestionnaire$(groupPolicyStateQuestionnaire$: Observable<GroupPolicyStateVcontQuestionnaire>,
    activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontQuestionnaire> {
    const previousStepData = activeRoute.getRouteData<GroupPolicyVcontRouteData>();
    return groupPolicyStateQuestionnaire$.pipe(
      mergeMap((st: GroupPolicyStateVcontQuestionnaire) => {
        return combineLatest(of(st),
          this.retrieveQuestionnaires$(of(st), previousStepData.resId, '2'),
          this.actionNextRoute$(of(st), previousStepData.resId)
        );
      }),
      map(([st]) => {
        st.errors = previousStepData.errors;
        st.type = previousStepData.type;
        st.steps = previousStepData.steps.concat([ROUTES_GP_VCONT_QUESTIONNAIRE]);
        st.labels = previousStepData.labels.concat(['_GP_._TITLE_._QUESTIONNAIRE_']);

        return st;
      })
    );
  }

  public retrieveQuestionnaires$(groupPolicyStateQuestionnaire$: Observable<GroupPolicyStateVcontQuestionnaire>, uuid: string,
    level: '1' | '2'): Observable<GroupPolicyStateVcontQuestionnaire> {
    return groupPolicyStateQuestionnaire$.pipe(
      concatMap((state: GroupPolicyStateVcontQuestionnaire) => {
        state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, 'FOOTER');
        return this.groupPolicyService.retrieveQuestionnairesPostSales$(uuid, level).pipe(
          map((questionnaires: Surveys[]) => {
            state.questionnaires = questionnaires;
            return state;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontQuestionnaire) => st)
    );
  }

  public actionNextRoute$(groupPolicyStateQuestionnaire$: Observable<GroupPolicyStateVcontQuestionnaire>, resId: string): Observable<GroupPolicyStateVcontQuestionnaire> {
    return groupPolicyStateQuestionnaire$.pipe(
      concatMap((state: GroupPolicyStateVcontQuestionnaire) => {
        return this.groupPolicyService.nextRoutePostSales$(resId, ROUTES_GP_VCONT_QUESTIONNAIRE).pipe(
          map((resp: NextRouteResponse) => {
            state.nextRoute = resp;
            return state;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((state: GroupPolicyStateVcontQuestionnaire) => {
        return state;
      })
    );
  }

  public actionGoForword$(groupPolicyStateQuestionnaire$: Observable<GroupPolicyStateVcontQuestionnaire>, resId: string,
    activeRoute: ActiveRoute, operationCode?: string): Observable<GroupPolicyStateVcontQuestionnaire> {
    const previousStepData = activeRoute.getRouteData<GroupPolicyVcontRouteData>();
    return groupPolicyStateQuestionnaire$.pipe(
      concatMap((state: GroupPolicyStateVcontQuestionnaire) => {
        if (state.nextRoute && state.nextRoute.button.includes('_CONFIRM_')) {
          state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, 'FOOTER');
          return this.groupPolicyService.retrieveDocumentsPostSales$(resId, operationCode).pipe(
            map((documents: Documents[]) => {
              state.documents = documents;
              return state;
            }),
            catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
          );
        } else {
          return of(state);
        }
      }),
      concatMap((state: GroupPolicyStateVcontQuestionnaire) => {
        if (state.nextRoute && state.nextRoute.button.includes('_CONFIRM_')) {
          return this.groupPolicyService.saveOperationPostSales$(resId).pipe(
            map((_resp) => state),
            catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
          );
        } else {
          return of(state);
        }
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((state: GroupPolicyStateVcontQuestionnaire) => {
        if (!this.apiRestErrorService.containsBlockingErrors(state.errors)) {
          this.integrationService.navigate(this.routingService, state.nextRoute && state.nextRoute.nextRoute, mapObjForVcont(resId, state.steps, state.labels, previousStepData.proposalNumber, previousStepData.idParentSession, previousStepData.firstSubjectContract, state.documents), activeRoute);
        }
        return state;
      })
    );
  }

  public actionBack$(groupPolicyStateQuestionnaire$: Observable<GroupPolicyStateVcontQuestionnaire>, previousStepData: GroupPolicyVcontRouteData,
    activeRoute: ActiveRoute, targetRoute = ROUTES_GP_VCONT_DATES): Observable<GroupPolicyStateVcontQuestionnaire> {
    return groupPolicyStateQuestionnaire$.pipe(
      tap(() => {
        previousStepData.errors = [];
        previousStepData.type = ErrorCode.BLOCKING;
        previousStepData.steps = previousStepData.steps.slice(0, previousStepData.steps.indexOf(targetRoute));
        previousStepData.labels = previousStepData.labels.slice(0, previousStepData.steps.indexOf(targetRoute));
        this.integrationService.navigate(this.routingService, targetRoute, previousStepData, activeRoute);
      })
    );
  }

  public actionClose$(groupPolicyStateQuestionnaire$: Observable<GroupPolicyStateVcontQuestionnaire>, previousStepData: GroupPolicyVcontRouteData,
    activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontQuestionnaire> {
    return groupPolicyStateQuestionnaire$.pipe(
      tap(() => {
        this.integrationService.backToInquiry(previousStepData.proposalNumber, activeRoute.id, previousStepData.idParentSession);
      })
    );
  }

}

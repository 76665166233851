import { TableSchema } from "@rgi/rx/ui";

export const schemaActor: TableSchema = {
    rows: [
      {
        name: 'selected',
        title: '',
      },
      {
        name: 'actorName',
        title: '_CLAIMS_._ACTOR_INVOLVED',
      },
      {
        name: 'causaleDescr',
        title: '_CLAIMS_._REASON',
      },
      {
        name: 'licensePlateUS',
        title: '_CLAIMS_._US_CAR_LICENSE_PLATE',
      }   
    ],
    header: ['selected','actorName','causaleDescr','licensePlateUS']
  };

  export const schemaFlow: TableSchema = {
    rows: [
      {
        name: 'docCode',
        title: '_CLAIMS_._DOCUMENT_TYPE_FLOW',
      },
      {
        name: 'compulsory',
        title: '_CLAIMS_._MANDATORY',
      },
      {
        name: 'numAttach',
        title: '_CLAIMS_._MATTER',
      },
      {
        name: 'dimensionMax',
        title: '_CLAIMS_._SIZE',
      }   
    ],
    header: ['docCode','compulsory','numAttach','dimensionMax']
  };

  export const schemaFlux: TableSchema = {
    rows: [      
      {
        name: 'docName',
        title: '_CLAIMS_._TITLE',
      },
      {
        name: 'actorName',
        title: '_CLAIMS_._ACTOR_INVOLVED',
      },
      {
        name: 'docCategory',
        title: '_CLAIMS_._CATEGORY',
      },
      {
        name: 'docSubCategory',
        title: '_CLAIMS_._SUB_CATEGORY',
      },
      {
        name: 'P5',
        title: '',
      },
      {
        name: 'docId',  // da sostituire con il corretto  tipo documento flusso
        title: '_CLAIMS_._DOCUMENT_TYPE_FLUX',
      },      
    ],
    header: ['docName','actorName','docCategory','docSubCategory','P5','docId']
  };
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CoassContainer} from '../../models/domain-models/parameters/coass-container';
import {QuotaCoass} from '../../models/domain-models/quota-coass';
import {QuotaCoassList} from '../../models/domain-models/quota-coass-list';
import {ProposalService} from '../../proposal.service';
import {MicHttpService} from '../../http/mic-http.service';

@Injectable(
  {providedIn: 'root'}
)
export class CoinsuranceService {

  private baseApiUrlV2;
  protected httpClient: HttpClient;


  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    private proposalService: ProposalService
  ) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath + '/portfolio'
      : environment.api.portal.host + environment.api.portal.path + '/v2/portfolio';
    this.httpClient = this.micHttpClient.getHttpClient();

  }

  getCoinsurance(): Observable<CoassContainer> {
    return this.httpClient.get<CoassContainer>(this.baseApiUrlV2 + '/contract/' + this.proposalService.getContractId()
      + '/coinsurance');
  }

  getCoinsuranceQuotes(): Observable<QuotaCoassList> {
    return this.httpClient.get<QuotaCoassList>(this.baseApiUrlV2 + '/contract/' + this.proposalService.getContractId()
      + '/coinsurance-quote');
  }

  newCoinsuranceQuote(): Observable<QuotaCoass> {
    return this.httpClient.get<QuotaCoass>(this.baseApiUrlV2 + '/contract/' + this.proposalService.getContractId()
      + '/coinsurance-quote-default');
  }

  putCoinsuranceQuotes(quotaCoass: QuotaCoassList): Observable<QuotaCoassList> {
    return this.httpClient.put<QuotaCoassList>(this.baseApiUrlV2 + '/contract/' + this.proposalService.getContractId()
      + '/coinsurance-quote', quotaCoass);
  }
}

import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule, DOCUMENT} from '@angular/common';
import {RgiRxQualityAssuranceDirective} from './rgi-rx-quality-assurance.directive';
import {
  RGI_RX_DEFAULT_QA_CONFIG,
  RGI_RX_QA_CONFIG,
  RGI_RX_QA_RENDER_STYLE,
  RGI_RX_QA_RENDER_STYLE_DEFAULTS,
  rgiRxQAFactory
} from './rgi-rx-data-qa-api';
import {RgiRxQAConfigService} from './rgi-rx-qaconfig.service';


@NgModule({
  declarations: [RgiRxQualityAssuranceDirective],
  imports: [
    CommonModule
  ],
  exports: [RgiRxQualityAssuranceDirective],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: rgiRxQAFactory,
      deps: [DOCUMENT, RgiRxQAConfigService],
      multi: true
    },
    {
      provide: RGI_RX_QA_RENDER_STYLE,
      useValue: RGI_RX_QA_RENDER_STYLE_DEFAULTS
    },
    {
      provide: RGI_RX_QA_CONFIG,
      useValue: RGI_RX_DEFAULT_QA_CONFIG
    }
  ]
})
export class RgiRxQualityAssuranceModule {
}

<div class="box-detail" *ngIf=isVisible>
  <header class="box-detail-header" translate>_GP_._TITLE_._BALANCE_SUMMARY_</header>
  <div class="box-detail-body">
    <rgi-gp-balance-detail *ngIf="balanceDetail.risks && balanceDetail.risks.length"
                           [detail]="balanceDetail"
                           class="rgi-gp-w-100">
    </rgi-gp-balance-detail>
    <rgi-rx-snackbar [tag]="footerTag"></rgi-rx-snackbar>
  </div>
</div>

<div *ngIf="!errors.length else errorMessages" translate>
    lpc_warning_message
  </div>
  <ng-template #errorMessages>
    <div class="tbld life-detail-margin-top" id="errorMessages">
      <div class="tbld_row life-detail-tbld_row_header">
        <div class="tbld_col tbld_col_title tbld_col_title_media_20_width header-icon-cell"></div>
        <div class="tbld_col tbld_col_title tbld_col_title_media_80_width" translate>lpc_warning</div>
      </div>
      <div *ngFor="let error of filteredErrors" class="tbld_row">
        <div class="tbld_col tbld_col_value tbld_col_value_media_20_width life-detail-table-border icon-cell"
             [ngClass]="{
              'warning-messages': error.severity === 'warning',
              'error-messages': error.severity === 'error',
              'info-messages': error.severity === 'info'
              }">
            <span
                [ngClass]="{
                  'rgifont message-icon rgi-refresh auth-messages': error.severity === 'auth',
                  'rgifont message-icon rgi-exclamation-circle warning-messages': error.severity === 'warning',
                  'rgifont message-icon rgi-exclamation-triangle error-messages': error.severity === 'error',
                  'rgifont message-icon rgi-exclamation-triangle info-messages': error.severity === 'info'
                  }">
            </span>
        </div>
        <div class="tbld_col tbld_col_value tbld_col_value_media_80_width life-detail-table-border"
             [ngClass]="{
             'auth-messages': error.severity === 'auth',
             'warning-messages': error.severity === 'warning',
             'error-messages-section': error.severity === 'error',
             'info-messages-section': error.severity === 'info'
             }">{{error.errorMessage}}</div>
      </div>
    </div>
  </ng-template>

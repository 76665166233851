import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

// custom validator to check id DOB is after TODAY
export function DobValidator(controlName: string) {

  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];

    // Data limite nel passato
    const minDOB: Date = new Date();
    minDOB.setFullYear(1900, 0, 1);

    if (control.value) {
      if (control.value instanceof Date) {

        const inputDate = control.value;
        inputDate.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (inputDate > currentDate) {
          control.setErrors({futureDate: true});
        } else if (inputDate < minDOB) {
          control.setErrors({tooOld: true});
        } else {
          control.setErrors(null);
        }
      } else {
        control.setErrors({invalidDate: true});
      }
    } else {
      const validator: any = control.validator && control.validator(new UntypedFormControl());

      if (validator && validator.required) {
        control.setErrors({required: true});
      } else if (controlName === 'registrationDate') {
        control.setErrors({invalidDate: true});
      } else {
        control.setErrors(null);
      }
    }
  };

}

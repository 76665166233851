import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'lpc-volatility-button',
  templateUrl: './lpc-volatility-button.component.html',
  styleUrls: ['./lpc-volatility-button.component.scss']
})
export class LpcVolatilityButtonComponent {

  @Input() disabled = false;
  @Input() volatility: number;
  @Output() calculate: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  onCalculate() {
    this.calculate.emit(true);
  }
}

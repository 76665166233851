import {RgiRxRouteSnapshot} from './router.api';

export interface RgiRxRouterEventType {
  /**
   * Type of the event emitted
   */
  type: RgiRxRouterEvents;
}


export interface RgiRxRouterComponentLoadedEvent extends RgiRxRouterEventType{
  snapshot: RgiRxRouteSnapshot;
  type: RgiRxRouterEvents.COMPONENT_LOADED;
}

export interface RgiRxRouterComponentDestroyedEvent extends RgiRxRouterEventType{
  type: RgiRxRouterEvents.COMPONENT_DESTROYED;
}

export type RgiRxRouterEvent = RgiRxRouterComponentLoadedEvent | RgiRxRouterComponentDestroyedEvent;


export enum RgiRxRouterEvents {
  /**
   * @description the component has been loaded
   */
  COMPONENT_LOADED = 'COMPONENT_LOADED',
  /**
   * @description the component has been destroyed
   */
  COMPONENT_DESTROYED = 'COMPONENT_DESTROYED'
}

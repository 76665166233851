import {RgiRxDropContainerSelectData, RgiRxOnSelectNode} from '@rgi/rx/ui';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {AnagRouteData} from '../anag-model/anag-domain/anag-route-data';
import {RoutingService} from '@rgi/rx/router';
import {take, tap} from 'rxjs/operators';
import {RgiRxEventService} from '@rgi/rx';
import {AnagIntegrationService} from '../services/anag-integration.service';
import {NEW_PARTY, PARTY_CREATED, PartyCreatedEvent, SUBJECT_SELECTED} from '../anag-events/anag-event';
import {AnagFlowData} from '../anag-model/anag-domain/anag-flow-data';
import { AnagModalService } from '../anag-modal/anag-modal-service';

@Injectable({
  providedIn: 'root'
})
export class AnagSearchOrCreateResolver implements RgiRxOnSelectNode<any, any, any> {

  constructor(
    protected anagModalService: AnagModalService,
    protected eventService: RgiRxEventService,
    protected anagIntegrationService: AnagIntegrationService,
    protected routingService: RoutingService
  ) {
  }

  onSelect$(model: RgiRxDropContainerSelectData<any, AnagFlowData>): Observable<any> {
    const dropData: AnagFlowData = model.selectData;

    return this.searchOrCreateSubject(dropData);
  }

  public searchOrCreateSubject(dropData: AnagFlowData): Observable<any> {
    const observable = new Subject();

    const routeData = new AnagRouteData();
    routeData.redirectToMainFlow = true;
    routeData.flowData = dropData;
    const modal = this.anagModalService.openComponent('AnagModalComponent', routeData);
    modal.modal.enableClickBackground = false;
    modal.modal.onClose.pipe(tap(onCloseData => {
      if (onCloseData && onCloseData.event) {
        const event = onCloseData.event;
        switch (event.eventName) {
          case NEW_PARTY.eventName: {
            if (event.routeData.action === 'newParty' || event.routeData.action === 'editParty') {
              this.anagIntegrationService.openEditSubject(event.routeData, dropData.idParentSession);
              this.eventService.listen<PartyCreatedEvent>(PARTY_CREATED).pipe(take(1)).subscribe(eventInstance => {
                const idSessionOld = dropData.idParentSession || eventInstance.event.idSession;
                this.anagIntegrationService.changeSession(eventInstance.event.idSession, idSessionOld);
                observable.next(eventInstance.event.party);
              });
            }
            break;
          }
          case SUBJECT_SELECTED.eventName: {
            observable.next(event.routeData.party);
            break;
          }
          default:
            break;
        }
      }
    })).subscribe();

    return observable;
  }
}

import {Component, Input} from '@angular/core';
import {RGI_FIELD_TYPE, RgiFormField} from '../../resources/model/common/rgi-form-field';
import {GenericEntity} from '../../resources/model/common/entities';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'rgi-pnc-psales-form-fields',
  templateUrl: './form-fields.component.html'
})
export class FormFieldsComponent {

  @Input() formFields: RgiFormField[];
  @Input() fieldsFormGroup: UntypedFormGroup;
  public readonly FIELD_TYPE = RGI_FIELD_TYPE;
  constructor() {
  }

  fieldsTrackBy(_index, field: RgiFormField) {
    return field.code;
  }

  allowedValuesTrackBy(_index, baseEntity: GenericEntity) {
    return baseEntity.code;
  }

  getMinDate(field: RgiFormField) {
    return field.minValue ? new Date(field.minValue) : undefined;
  }

  getMaxDate(field: RgiFormField) {
    return field.maxValue ? new Date(field.maxValue) : undefined;
  }
}

<div class="rgi-ui-grid-2">
  <div class="rgi-re-section-title ">
    <span class="rgi-re-header-icon rgi-ui-icon-marker"></span>
    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.POLICYHOLDER_COMMUNICATION_ADDRESSES</span>&nbsp;
  </div>
</div>
<form [formGroup]="policyContactForm">
  <div class="rgi-ui-grid">
  <div class="rgi-ui-col">
    <!-- lo stile inline sembra l'unico metodo funzionante -->
    <rgi-rx-tab-group class="custom-tab story-grid-column-content" (onTabSelectionChange)="setData($event)" [selectedIndex]="selectedIndex">
      <rgi-rx-tab data-qa="postal" label="{{ 'RE_ISSUE.POSTAL_MAIL' | translate }}">
        <ng-template rgiRxTabContent>
          <div data-qa="bodyPostal" class="pad-top" *ngIf="selectedIndex == ReIssueCommunicationTypeEnum.POSTAL_MAIL">
            <div *ngIf="addressData.length > 0" class="rgi-ui-grid-1">
              <rgi-rx-form-field *ngFor="let address of addressData;let i = index" class="rgi-ui-col--xs-12-sm-12">
                <input type="radio"  [value]="address" rgiRxInput
                       [checked]="radioButtonSelected[i]" (change)="selectedAddress(address, i)">
                {{ (i === 0 ? 'RE_ISSUE.POLICYHOLDER_RESIDENCE_ADDRESS' : 'RE_ISSUE.OTHER_ADDRESS') |translate}}:
                {{ address.formatAddress || address.formattedAddress }}
                <div class="rgi-ui-col--xs-12-sm-4" *ngIf="addressData.length === 2 && addressData[1] == address">
                  <button rgi-rx-button (click)="changeAddress(addressData[1])"
                          [disabled]="radioButtonSelected[0] == true" color="info"
                          data-qa="edit-contact" translate>RE_ISSUE.CHANGE_ADDRESS
                  </button>
                </div>
              </rgi-rx-form-field>
              <rgi-rx-form-field *ngIf="addressData.length < 2" class="rgi-ui-col--xs-12-sm-12">
                <input type="radio" rgiRxInput
                       [checked]="radioButtonSelected[1]" (change)="selectedAddress(addressData[1], 1)">
                {{ 'RE_ISSUE.OTHER_ADDRESS' |translate}}
                  <button rgi-rx-button (click)="addAddress()" [disabled]="radioButtonSelected[0] == true"
                          color="info"
                          data-qa="new-contact" translate>RE_ISSUE.NEW
                  </button>
              </rgi-rx-form-field>
            </div>
          </div>
        </ng-template>
      </rgi-rx-tab>
      <rgi-rx-tab data-qa="email" label="{{ 'RE_ISSUE.EMAIL' | translate }}">
        <ng-template rgiRxTabContent>
          <div data-qa="bodyEmail" class="pad-top" *ngIf="selectedIndex == ReIssueCommunicationTypeEnum.EMAIL">
            <div class="rgi-ui-col">
              <rgi-rx-form-field>
                <div class="rgi-ui-col">
                  <label rgiRxLabel><span translate>RE_ISSUE.POLICYHOLDER_EMAIL</span>
                  </label>
                  <input rgiRxInput type="text" formControlName="policyEmail" (blur)="selectEmail()">
                </div>
              </rgi-rx-form-field>
            </div>
          </div>
        </ng-template>
      </rgi-rx-tab>
      <rgi-rx-tab data-qa="reservedArea" label="{{ 'RE_ISSUE.CUSTOMER_RESERVED_AREA' | translate }}">
        <ng-template rgiRxTabContent>
          <div data-qa="bodyReservedArea" class="pad-top" *ngIf="selectedIndex == ReIssueCommunicationTypeEnum.RESERVED_AREA">
            <div data-qa="msg-reserved-area" class="col-sm-12 col-xs-12">
              <span>{{ 'RE_ISSUE.POLICY_CONTACT_RESERVED_AREA' | translate }}</span>
            </div>
          </div>
        </ng-template>
      </rgi-rx-tab>
    </rgi-rx-tab-group>
  </div>
  </div>
</form>

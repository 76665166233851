import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {CustomModalService} from '../custom-modal.service';
import {StateService} from '../state.service';
import {ProposalService} from '../proposal.service';
import {PartiesService} from '../proposal/parties.service';
import {ParameterService} from '../quotation/parameters/parameters.service';
import {DatePipe} from '@angular/common';
import {QuotationService} from '../quotation/quotation.service';
import {RoutableComponent} from '../routable-component';
import {EventNotificator} from '../event-notificator';
import {WarrantySummary} from '../quotation/warranties-summary/warranty-summary';
import {TranslateService} from '@ngx-translate/core';
import {AmendmentService} from '../amendment/amendment.service';
import {OutboundDocumentsModalComponent, OutboundDocumentsReference} from '@rgi/shared-components';
import {ModalService} from '@rgi/rx/ui';

@Component({
  selector: 'mic-policy-summary',
  templateUrl: './policy-summary.component.html',
  styleUrls: ['./policy-summary.component.scss'],
  providers: [DatePipe]
})
export class PolicySummaryComponent implements OnInit, RoutableComponent, EventNotificator {

  @Output()
  navigation: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  showOTP = false;
  policyNumber;
  subscriber;
  warranties: WarrantySummary[] = new Array<WarrantySummary>();
  isOTPError = false;
  validationMessages = [];
  isEndButtonEnabled = false;
  isPrintButtonClicked = false;
  showPrintButton: boolean;
  collectedAccounting = false;
  savePolicyMessage: string;
  showOtp: boolean;

  @ViewChild('modalOverlay', {read: ViewContainerRef, static: true}) modalOverlayRef: ViewContainerRef;

  constructor(
    protected customModalService: CustomModalService,
    protected stateService: StateService,
    protected proposalService: ProposalService,
    protected partiesService: PartiesService,
    protected parametersService: ParameterService,
    protected quotationService: QuotationService,
    protected translate: TranslateService,
    protected amendmentService: AmendmentService,
    protected modalService: ModalService,
    @Inject('showOtpButton') showOtpButton?: boolean) {
    this.showOtp = showOtpButton;
  }

  ngOnInit() {
    this.policyNumber = this.proposalService.getPolicyNumber();
    this.manageTitleAndPolicyMessage();

    const features = this.proposalService.getPolicyInfo().features;
    if (features) {
      this.showOTP = features.includes('OTP');
    }

    if (this.amendmentService?.isAmendment && this.proposalService.digitalSignatureRequired) {
      this.showPrintButton = false;
    } else {
      this.proposalService.getFunctionEnabledByCode('ODMMFN').subscribe(
        (isEnabled) => {
          this.showPrintButton = isEnabled;
        }
      );
    }
  }

  /**
   * @param digitalSignature digitalSignature
   * @private
   */
  protected manageTitleAndPolicyMessage() {
    if (this.amendmentService?.isAmendment) {
      this.translate.get('{{operation}} has been successfully saved',
        {operation: this.amendmentService?.flowOriginOperation?.description}).subscribe((message) => this.savePolicyMessage = message);
    } else if (this.proposalService.digitalSignatureRequired) {
      this.translate.get('The policy number {{policyNumber}} is being digitally signed.',
        {policyNumber: this.policyNumber}).subscribe((message) => this.savePolicyMessage = message);
    } else {
      this.translate.get('The policy number {{policyNumber}} has been correctly issued.',
        {policyNumber: this.policyNumber}).subscribe((message) => this.savePolicyMessage = message);
    }

    if (this.amendmentService.isAmendment) {
      this.eventPropagation.emit({
        eventName: 'setTitle',
        title: this.translate.instant('Amendment n. {{amendmentNumber}} on policy n. {{policyNumber}}',
          {amendmentNumber: this.amendmentService.appendixNumber, policyNumber: this.policyNumber})
      });
    } else {
      this.partiesService.getParties(this.proposalService.getContractId()).subscribe(data => {
        this.subscriber = data.subscriber;
        const subscriberNominative = this.subscriber ? this.subscriber.nominative : '';

        this.eventPropagation.emit({
          eventName: 'setTitle',
          title: this.translate.instant('Policy n.') + ' ' + this.policyNumber + ' - ' + subscriberNominative
        });
      });
    }
  }

  @HostListener('window:isOTPValid', ['$event'])
  onOTPValid(e) {
    this.validationMessages.length = 0;
    this.isOTPError = false;
    this.isEndButtonEnabled = true;
  }

  @HostListener('window:isOTPError', ['$event'])
  onOTPError(e) {
    this.validationMessages.length = 0;
    const message1 = 'Non è stato possibile contattare il servizio di Stampa OTP. ';
    const message2 = 'Si può procedere con il processo di stampa cartacea.';
    this.validationMessages.push(message1 + message2);
    this.isOTPError = true;
    this.isEndButtonEnabled = false;
  }

  openOutboundDocumentsModal() {
    if (this.showPrintButton) {
      const docRef = new OutboundDocumentsReference(this.proposalService.getPolicyNumber(), this.proposalService.getProposalNumber(),
        this.proposalService.getPolicyInfo().movementNumber, '');
      const modal = this.modalService.openComponent(OutboundDocumentsModalComponent, docRef);
      modal.modal.enableClickBackground = false;
    } else {
      console.error('User does not have permission to open Outbound Documents Modal');
    }
  }

  goToHome() {
    this.proposalService.refresh();
    this.stateService.resetState();
    this.eventPropagation.emit({
      eventName: 'backToMainPage',
    });
  }

  openOTP() {
    let idMov;
    const policyInfo = this.proposalService.getPolicyInfo();
    if (policyInfo) {
      idMov = policyInfo.movementNumber;
    }

    this.eventPropagation.emit({
      eventName: 'openOTP',
      idMovimento: idMov
    });

  }

  collectsAccounting() {
    const policyInfo = this.proposalService.getPolicyInfo();
    const idTit = new Array<string>();

    if (policyInfo.titleNumbers) {
      policyInfo.titleNumbers.forEach(
        (id) => {
          idTit.push(id);
        }
      );
    }

    this.collectedAccounting = true;

    this.eventPropagation.emit({
      eventName: 'collectsAccounting',
      idTitoli: idTit,
      policyNumber: this.proposalService.getPolicyNumber()
    });
  }

}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {REST_API_POLICY_DATA_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueEmissionResourceService {
  private PIN_PATH = '/banca/checkPin';
  private CONFIRM_ORDER_PATH = '/banca/confermaOrdine';

  private apiPolicyDataConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
  ) {
    this.apiPolicyDataConf = REST_API_POLICY_DATA_CONF;
  }

  postCheckPin(policyNumber: string, pinValue: string): Observable<any> {
    return this.http.post(this.getCheckPinUri(policyNumber, pinValue), {}, {responseType: 'text'}).pipe(
      share()
    );
  }

  getCheckPinUri(policyNumber: string, pinValue: string): string {
    return this.apiConf.api.portal.path + this.apiPolicyDataConf.pathV2 + this.PIN_PATH + '?numeroPolizza=' + policyNumber + '&pin=' + pinValue;
  }

  confirmOrder$(policyNumber: string): Observable<any> {
    return this.http.post<any>(this.getConfirmOrderUri(policyNumber), {}).pipe(
      share()
    );
  }

  getConfirmOrderUri(policyNumber: string): string {
    return this.apiConf.api.portal.path + this.apiPolicyDataConf.pathV2 + this.CONFIRM_ORDER_PATH + '?numeroPolizza=' + policyNumber;
  }
}

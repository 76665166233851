<form [formGroup]="parameterControlForm">

  <div [ngSwitch]="parameter?.type">

    <div *ngSwitchCase="'0'">
      <label translate>{{ parameter?.description }}</label>
      <pnc-required *ngIf="parameter.compulsory" required="true"></pnc-required>
      <select (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id" [compareWith]="compareGenericEntities"
              [attr.disabled]="parameter?.disabled ? 'disabled' : null"
              [formControlName]="parameter?.id" [id]="parameter?.id" class="select-nativa">
        <option></option>
        <option *ngFor="let value of parameter?.parameterValues;" [value]="value?.id">
          {{ value?.description }}</option>
      </select>
      <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
    </div>

    <div *ngSwitchCase="'1'">
      <label translate>{{ parameter?.description }}</label>
      <pnc-required *ngIf="parameter.compulsory" required="true"></pnc-required>
      <input (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id" [disabled]="parameter.disabled"
             [formControlName]="parameter?.id"
             [id]="parameter?.id" class="form-control mic-input" type="text" rgiCountryLayerInputNumberFormat  [maximumFractionDigits]="2">
    </div>

    <div *ngSwitchCase="'2'">
      <label translate>{{ parameter?.description }}</label>
      <pnc-required *ngIf="parameter.compulsory" required="true"></pnc-required>
      <input (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id" [disabled]="parameter.disabled"
             [formControlName]="parameter?.id"
             [id]="parameter?.id" class="form-control mic-input" type="text" rgiCountryLayerInputNumberFormat  [maximumFractionDigits]="2">
    </div>

    <div *ngSwitchCase="'3'">
      <label translate>{{ parameter?.description || ' '}}</label>
      <div (click)="onParameterValueChange(parameter)" [attr.data-qa]="'parameter_'+parameter?.id" [attr.disabled]="parameter?.disabled ? 'disabled' : null" [formControlName]="parameter?.id"
           class="btn btn-checkbox" [ngClass]="{'pointerEnabled':!parameter.disabled, 'pointerDisabled':parameter.disabled}"
           ngDefaultControl type="checkbox">
                <span [ngClass]="{'glyphicon-ok': parameter.value === 'true' }" class="glyphicon"
                      style="color: #f68020;">
                </span>
      </div>
    </div>
    <div *ngSwitchCase="'4'">
      <pnc-datepicker [attr.data-qa]="'parameter_'+parameter?.id" [customFormControlName]="parameter?.id"
                      [customFormGroup]="parameterControlForm" [disabled]="parameter?.disabled"
                      [id]="parameter?.id" [value]="parameter?.value"
                      label="{{ parameter?.description | translate }}">
      </pnc-datepicker>
      <pnc-required *ngIf="parameter.compulsory && !parameter?.disabled" required="true"></pnc-required>
    </div>

    <div *ngSwitchCase="'5'">
      <label translate>{{ parameter?.description }}</label>
      <pnc-required *ngIf="parameter.compulsory" required="true"></pnc-required>
      <input (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id" [formControlName]="parameter?.id"
             [id]="parameter?.id"
             [readonly]="parameter?.disabled" class="form-control mic-input" type="string">
    </div>

    <div *ngSwitchCase="'6'">
      <label translate>{{ parameter?.description }}</label>
      <pnc-required *ngIf="parameter.compulsory" required="true"></pnc-required>
      <input (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id" [formControlName]="parameter?.id"
             [id]="parameter?.id"
             [readonly]="parameter?.disabled" [value]="parameter?.value" class="form-control mic-input"
             type="text">
    </div>

  </div>

</form>

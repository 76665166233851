import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { EMPTY_STR } from '../../models/consts/lpc-consts';
import { _OPERATION_CODES } from '../lpc-operations-list/model/lpc-operations-constants';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';

@Component({
  selector: 'lpc-formule-quotation-table',
  templateUrl: './lpc-formule-quotation-table.component.html',
  styleUrls: ['./lpc-formule-quotation-table.component.scss'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LpcFormuleQuotationTableComponent implements OnChanges {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2
  };

  @Input() definitions: {[key: string]: string | number | {value: string, type: string}} = null;
  @Input() label = EMPTY_STR;
  @Input() liquidation: {column0: string, column1: string, column2: string}[] = [];
  @Input() operationCode = EMPTY_STR;

  fieldLables = [];
  fieldValues = [];

  constructor(
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    protected datePipe: DatePipe,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.currencyFormatterOptions.currency = currencyService.currency;
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definitions && changes.definitions.currentValue) {
      const definitions = Object.assign(changes.definitions.currentValue);
      if (!!definitions.insuredSettlements) {
        delete definitions.insuredSettlements;
      }
      this.fieldLables = Object.keys(definitions);
      this.fieldValues = Object.values(definitions);
    }
  }

  getFieldValue(value: any, label: string) {
    try {
      if (this.operationCode === _OPERATION_CODES.LOANGRANT || this.operationCode === _OPERATION_CODES.LOANREFUND) {
        return this.getFieldValue_LoanOp(value);
      } else {
        return this.getAutoFieldValueConversion(value, label);
      }
    } catch (error) {
      return value;
    }
  }


  public getFieldValue_LoanOp(value: {value: string, type: string}) {
    if (value.type !== 'date' && value.type !== 'string') {
      return this.rgiNumberFormatter.transform(value.value, '', this.numberFormatterOptions);
    } else {
      return value.value;
    }
  }

  public getAutoFieldValueConversion(value: string, label: string) {
    if (label.toLocaleLowerCase().includes('date') || label.toLocaleLowerCase().includes('data')) {
      return !!value ? this.datePipe.transform(value, 'dd/MM/yyyy') : EMPTY_STR;
    } else if (label.toLocaleLowerCase().includes('importo') || label.toLocaleLowerCase().includes('amount')) {
      return this.rgiNumberFormatter.transform(value, '', this.currencyFormatterOptions);
    } else {
      return value;
    }
  }

}

import {PortalCardConfig, PortalCards, PortalConfig} from '@rgi/rx/portal';

export const PNC_POSTSALES_CARD: PortalCardConfig = {
  card: {
    title: '',
    name: 'pncPostsalesEntryPoint',
    category: 'fakeHidden',
    type: 'main',
    functions: [],
    subCards: [],
    subCardsActive: [],
    navigationInSession: true
  },
  routes: []
};

export const PNC_PSALES_CARDS: PortalCards = [PNC_POSTSALES_CARD];

declare var angular: angular.IAngularStatic;

export function manageNewCardInPassPortal(config: PortalConfig, ops: { type: string; code: string | null }[],
                                          cancellationOverride: boolean, exclusionOverride: boolean) {
  const module = angular.module(config.module);
  module.config(['$provide', function($provide) {
    $provide.decorator('PtfDamageDetailService', ['$delegate', 'PncPostSalesOrchestratorService',
      function FinderServiceDecorator($delegate, orchestratorService) {

        const baseService = $delegate;
        const extended = angular.extend({}, $delegate);

        extended.isManagedByPncPostsales = (action) => {
          const isCancellation = action.type === '3' && cancellationOverride !== false;
          const isExclusion = action.type === '10' && exclusionOverride !== false;
          const isOverridden = ops.find(op => op.type === action.type && op.code === action.operation.code) !== undefined;
          return isCancellation || isExclusion || isOverridden;
        };

        extended.openPncPostsales = ($scope, scopeCard, action) => {
          $scope.cancel();
          const inputData = {
            policyNumber: scopeCard.contract.policyNumber,
            operationType: action.type,
            operations: scopeCard.actionsList,
            contractId: scopeCard.contract.objectId,
            currentOperation: action.type !== '3' ? action.operation : undefined
          };
          orchestratorService.processOperation(inputData);
        };


        return extended;
      }
    ]);
  }]);

}

export function angularGridInstanceFactory(injector) {
  return injector.get('angularGridInstance');
}

export const angularGridInstance = {
  provide: 'angularGridInstance',
  useFactory: angularGridInstanceFactory,
  deps: ['$injector']
};


<ng-container *ngIf="stateManager.getState$() | async as state">
  <administrative-data *ngIf="state.contract" [contract]="state.contract" (openActionModal)="getPolicyOperations($event)"
  (backEmitter)="back()">
  </administrative-data>
  <div rgi-rx-accordion [multi]="true">
    <consultation-accordion-premium *ngIf="state.contract"
                                    [nextInstalment]="state.nextInstalment"
                                    [signInstalment]="state.signInstalment"
                                    [annualPremium]="state.contract?.annualPremium"
                                    [currency]="state.contract?.currency">
    </consultation-accordion-premium>
    <lib-consultation-policy-details *ngIf="state.contract" [contract]="state.contract"></lib-consultation-policy-details>
    <lib-consultation-subjects *ngIf="state.contract" [contract]="state.contract"></lib-consultation-subjects>
    <lib-consultation-warranties *ngIf="state.contract" [contract]="state.contract"></lib-consultation-warranties>
    <consultation-movements *ngIf="state.contract?.allMovements.length > 0" [movements]="state.contract.allMovements"></consultation-movements>
  </div>
</ng-container>

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title" style="max-width: 100%">
{{ '_CLAIMS_._ASSIGNMENT_SELECTION' | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
            </h3>
        </div>
        <form [formGroup]="updateInvoiceForm" data-qa="invoice-update-form">

            <div class="row claims-text">
{{ '_CLAIMS_._SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST' | translate }}
            </div>

            <div *ngIf="hasResult()" class="table-white">
                <div class="head-result thDataTable row">

                    <div align="left" class="col-sm-1">
                        <span class="bold padding-left-5"></span>
                    </div>
                    <div align="left" class="col-sm-1">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._NUMBER' | translate }}</span>
                    </div>
                    <div align="left" class="col-sm-2">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._EXPERT' | translate }}</span>
                    </div>    
                    <div align="left" class="col-sm-3">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._EXPERT_TYPE' | translate }}</span>
                    </div>    
                    <div align="left" class="col-sm-2">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._ASSIGNMENT_DATE' | translate }}</span>
                    </div>    
                    <div align="left" class="col-sm-3">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._STATUS' | translate }}</span>
                    </div>    
                </div>
            </div>

            <div *ngFor="let assignment of assignments; let i = index">
                <div class="row">

                    <div align="center" class="col-sm-1 claims-text">
                        <input required
                            attr.data.qa="damage-claim-radio-{{i}}" name="assignment" formControlName="assignment" type="radio"
                            id="radio{{i}}" [value]="assignment" (change)="onSelectionChange(assignment.appointmentNumber)"
                            [ngClass]="{submitted: submitted}">
                    </div>

                    <div class="col-sm-1 claims-text">
                        <label attr.data.qa="claimNumber-label-{{i}}" for="radio{{i}}">{{assignment.appointmentNumber}}</label>
                    </div>

                    <div class="col-sm-2 claims-text">
                        <label attr.data.qa="claimHandler-label-{{i}}" for="radio{{i}}">{{assignment.expert}}</label>
                    </div>

                    <div class="col-sm-3 claims-text">
                        <label attr.data.qa="claimNumber-label-{{i}}" for="radio{{i}}">{{assignment.expertType}}</label>
                    </div>

                    <div class="col-sm-2 claims-text">
                        <label attr.data.qa="claimcompany-label-{{i}}" for="radio{{i}}">{{assignment.appointmentDate}}</label>
                    </div>

                    <div class="col-sm-3 claims-text">
                        <label attr.data.qa="claimHandler-label-{{i}}" for="radio{{i}}">{{assignment.appointmentState}}</label>
                    </div>                    
                </div>

            </div>

            <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-update-error">
                <span class="rgifont rgi-exclamation-triangle"></span>
                <span>{{errorMessage | translate}}</span>
            </span>

            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button type="submit" class="btn btn-warning pull-right" attr.data-qa="invoice-update-find-btn"
                        id="invoice-update-submit" (click)="confirmInvoice()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                </div>
            </div>

        </form>
    </div>
</div>
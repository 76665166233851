<form [formGroup]="parameterControlForm" class="rgi-ui-form-group">

  <div [ngSwitch]="parameter?.type" class="parameter">

    <div *ngSwitchCase="'0'">
      <rgi-rx-form-field>
        <label rgiRxLabel>{{ parameter?.description }}
        </label>
        <select rgiRxNativeSelect (change)="onParameterValueChange()" [id]="parameter?.id"
                [formControlName]="parameter?.id"
                [compareWith]="compareGenericEntities"
                attr.data-qa="{{parameter?.code}}">
          <option></option>
          <option *ngFor="let value of parameter?.parameterValues;"
                  [value]="value?.code">{{value?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'1'">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>{{ parameter?.description }}
          <!--
                    <pnc-required required="true" *ngIf="parameter.compulsory"></pnc-required>
          -->
        </label>
        <input rgiRxInput [disabled]="parameter.disabled" (change)="onParameterValueChange()" [id]="parameter?.id"
               type="number" [formControlName]="parameter?.id">
      </rgi-rx-form-field>
    </div>


    <div *ngSwitchCase="'2'">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>{{ parameter?.description }}
          <!--
                    <pnc-required required="true" *ngIf="parameter.compulsory"></pnc-required>
          -->
        </label>
        <input rgiRxInput (change)="onParameterValueChange()" [id]="parameter?.id" type="number"
               [formControlName]="parameter?.id">
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'3'">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>{{ parameter?.description }}</label>
        <input type="checkbox" rgiRxInput formControlName="parameter?.id"
               (click)="onParameterValueChange()" [attr.disabled]="parameter.disabled === true ? true : null">
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'4'">
      <rgi-rx-form-field>
        <label rgiRxLabel><span translate>{{ parameter?.description }}</span></label>
        <rgi-rx-date-picker>
          <input [rgiRxDateTimeInput]="datePickerParameter"
                 formControlName="{{parameter.id}}"
                 selectMode="single"
                 rangeSeparator="-"
                 placeholder="gg-mm-yyyy">
        </rgi-rx-date-picker>
        <rgi-rx-date-time #datePickerParameter
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'5'">

      <rgi-rx-form-field>>
        <label rgiRxLabel translate>{{ parameter?.description }}
          <!--
                    <pnc-required required="true" *ngIf="parameter.compulsory"></pnc-required>
          -->
        </label>
        <input rgiRxInput (change)="onParameterValueChange()" [id]="parameter?.id" type="string"
               [formControlName]="parameter?.id" [attr.disabled]="parameter?.disabled">
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'6'">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>{{ parameter?.description }}
          <!--
                    <pnc-required required="true" *ngIf="parameter.compulsory"></pnc-required>
          -->
        </label>
        <input rgiRxInput (change)="onParameterValueChange()" [id]="parameter?.id" type="text"
               [formControlName]="parameter?.id" [value]="parameter?.value">
      </rgi-rx-form-field>
    </div>

  </div>

</form>

import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {APP_BASE_HREF, HashLocationStrategy, Location, LocationStrategy, PathLocationStrategy, PlatformLocation} from '@angular/common';
import {RGI_RX_ROUTER_LOCATION_CONFIG, RGI_RX_ROUTER_LOCATION_DEFAULT_CONFIG, RGI_RX_ROUTER_LOCATION_GUARD, RgiRxRouterLocation, RgiRxRouterLocationConfig} from './router.location.api';
import {RgiRxRouterHistoryLocation} from './rgi-rx-router-history-location';
import {RoutingService} from '../routing.service';
import {RgiRxHistoryRouter} from './rgi-rx-router-location-facade';
import {RgiRxRouterUrlLinkDirective} from './rgi-rx-router-url-link.directive';
import {RgiRxRouterBackLinkDirective} from './rgi-rx-router-back-link.directive';
import {RgiRxRouterForwardLinkDirective} from './rgi-rx-router-forward-link.directive';

export function provideLocationStrategy(platformLocation: PlatformLocation, href: string, config: RgiRxRouterLocationConfig) {
  switch (config.strategy) {
    case 'path': {
      return new PathLocationStrategy(platformLocation, href);
    }
    default: {
      return new HashLocationStrategy(platformLocation, href);
    }
  }
}

@NgModule({
  declarations: [RgiRxRouterUrlLinkDirective, RgiRxRouterBackLinkDirective, RgiRxRouterForwardLinkDirective],
  exports: [RgiRxRouterUrlLinkDirective, RgiRxRouterBackLinkDirective, RgiRxRouterForwardLinkDirective],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useFactory: provideLocationStrategy,
      deps: [PlatformLocation,
        [new Inject(APP_BASE_HREF), new Optional()],
        [new Inject(RGI_RX_ROUTER_LOCATION_CONFIG)]
      ]
    },
    {
      provide: RgiRxRouterLocation,
      useExisting: RgiRxRouterHistoryLocation
    },
    {
      provide: RGI_RX_ROUTER_LOCATION_CONFIG, useValue: RGI_RX_ROUTER_LOCATION_DEFAULT_CONFIG
    },
    {
      provide: RGI_RX_ROUTER_LOCATION_GUARD, useValue: true
    },
    {
      provide: RgiRxHistoryRouter, useExisting: RoutingService
    }
  ]
})
export class RgiRxRouterLocationModule {


  static forRoot(config: RgiRxRouterLocationConfig): ModuleWithProviders<RgiRxRouterLocationModule> {
    return {
      ngModule: RgiRxRouterLocationModule,
      providers: [
        {
          provide: RGI_RX_ROUTER_LOCATION_CONFIG, useValue: config
        }
      ]
    };
  }

}

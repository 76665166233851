import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GenericEntity} from './models/domain-models/generic-entity';
import {ApiCompany} from './models/api-models/api-company';
import {ApiAgency} from './models/api-models/api-agency';
import {ApiAssetType} from './models/api-models/api-asset-type';
import {OperatorService} from '@rgi/portal-ng-core';
import {MicHttpService} from './http/mic-http.service';
import {Property} from './models/domain-models/property';

@Injectable({
  providedIn: 'root'
})
export class PassProductsService {

  private baseApiUrl: string;
  private baseApiUrlV2: string;
  protected httpClient: HttpClient;

  private availableCompaniesEndpoint: string;
  private productsEndpoint: string;
  private availableAgenciesEndpoint: string;
  private plateTypeEndpoint: string;
  private assetTypesEndpoint: string;
  private classesEndpoint: string;
  private usesEndpoint: string;
  private extensionsEndpoint: string;

  private productsFilter = {
    productsFilter: {
      idSp: undefined,
      filterProductsCategory: '1',
      filterOnlyDamagesProducts: true,
      filterSalableProduct: true,
      filterSalableProductTypes: true,
      effectiveDate: undefined
    }
  };

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected operatorService: OperatorService) {

    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;

    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.httpClient = this.micHttpClient.getHttpClient();

    this.productsEndpoint = this.baseApiUrl + '/admin/products';
    this.availableCompaniesEndpoint = this.baseApiUrl + '/admin/companies';
    this.availableAgenciesEndpoint = this.baseApiUrl + '/admin/agencies';
    this.plateTypeEndpoint = this.baseApiUrlV2 + '/motor/platetype';
    this.assetTypesEndpoint = this.baseApiUrlV2 + '/products/assets';
    this.classesEndpoint = this.baseApiUrlV2 + '/motor/classes';
    this.usesEndpoint = this.baseApiUrlV2 + '/motor/uses';
    this.extensionsEndpoint = this.baseApiUrlV2 + '/motor/extensions';
  }

  getAvailableCompanies(): Observable<Array<ApiCompany>> {
    return this.httpClient.get<Array<ApiCompany>>(this.availableCompaniesEndpoint).pipe(
      map((data: any) => data.companies));
  }

  getAvailableAgencies(): Observable<Array<ApiAgency>> {
    let httpParams: HttpParams = new HttpParams();

    const salePointLogin = this.operatorService.getSalePointDefault();
    if (salePointLogin) {
      httpParams = httpParams.set('loginNodeId', salePointLogin.objectId);
    }
    return this.httpClient.get<ApiAgency>(this.availableAgenciesEndpoint, {params: httpParams}).pipe(
      map((data: any) => data.agencies)
    );
  }

  getPlateType(plate: string, vehicleType: string) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('plate', plate);
    httpParams = httpParams.set('vehicleType', vehicleType);
    return this.httpClient.get(this.plateTypeEndpoint, {params: httpParams});
  }

  getProducts(idPv: string): Observable<any> {
    this.productsFilter.productsFilter.effectiveDate = new Date();
    this.productsFilter.productsFilter.idSp = idPv;
    return this.httpClient.post(this.productsEndpoint, this.productsFilter);
  }

  getAssetTypes(productCode: string): Observable<Array<ApiAssetType>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('productCode', productCode);
    return this.httpClient.get<Array<ApiAssetType>>(this.assetTypesEndpoint, {params: httpParams});
  }

  getAvailableClasses(assetTypeCode: string): Observable<Array<GenericEntity>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('assetCode', assetTypeCode);
    return this.httpClient.get<Array<GenericEntity>>(this.classesEndpoint, {params: httpParams});
  }

  getAvailableUses(assetTypeCode: string, vehicleClassCode: string): Observable<Array<GenericEntity>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('assetCode', assetTypeCode);
    httpParams = httpParams.set('classCode', vehicleClassCode);
    return this.httpClient.get<Array<GenericEntity>>(this.usesEndpoint, {params: httpParams});
  }

  getAvailableExtensions(contractId: string, classCode: string, useCode: string) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('contractId', contractId);
    if (classCode) {
      httpParams = httpParams.set('classCode', classCode);
    }
    if (useCode) {
      httpParams = httpParams.set('useCode', useCode);
    }
    return this.httpClient.get<Array<GenericEntity>>(this.extensionsEndpoint, {params: httpParams});
  }

  getProductProperty(resourceId: string, code: string) : Observable<Property> {
    return this.httpClient.get<Property>(this.baseApiUrlV2 + '/motor/drafts/' + resourceId + '/property/' + code);
  }

}

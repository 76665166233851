import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  registeredSessions = [];

  constructor(
    @Inject('sessionService') private sessionService: any
  ) { }

  signalDeadSession(deadSessionEvt: any) {
    let idSession = null;
    if (deadSessionEvt.idSession) {
      idSession = deadSessionEvt.idSession;
    } else if (deadSessionEvt.args.idSession) {
      idSession = deadSessionEvt.args.idSession;
    }

    this.signalIdSessionDead(idSession);
  }

  registerSessionDeathCallback(idSession, callback: () => void) {
    if (!this.registeredSessions[idSession]) {
      this.registeredSessions[idSession] = [];
    }
    this.registeredSessions[idSession].push(callback);
  }

  getSessionById(id: string) {
    console.log('getSessionById - list', this.sessionService.list());
    const sessions = this.sessionService.list();
    let result = null;

    if (sessions) {
      for (const session of sessions) {
        if (session.id === id) {
          result = session;
          break;
        }
      }
    }

    return result;
  }

  getSessionByIdSession(idSession: string) {
    const sessions = this.sessionService.list();
    let result = null;

    if (sessions) {
      for (const session of sessions) {
        if (session.idSession === idSession) {
          result = session;
          break;
        }
      }
    }

    return result;
  }

  idSessionFromId(id: string) {
    let idSession = null;
    const session = this.getSessionById(id);

    if (session) {
      idSession = session.idSession;
    }

    return idSession;
  }

  private signalIdSessionDead(idSession) {
    if (this.registeredSessions[idSession]) {
      const funcs = this.registeredSessions[idSession];
      delete this.registeredSessions[idSession];
      funcs.forEach((f: () => void) => {
        f();
      });
    }
  }

  createJump(card, objIntegrationPass, title, route, parent, callBackFunction?) {

    objIntegrationPass.objNavigation.idCardParent = card.id; // add idParent
    card.integrationPass = objIntegrationPass;

    if (objIntegrationPass.objNavigation.newSession) {
        const idNavigation = this.sessionService.open(parent, route, title, true, card.integrationPass, null, card.idSession);

        const idSession = this.idSessionFromId(idNavigation);
        if (callBackFunction && callBackFunction instanceof Function) {
          this.registerSessionDeathCallback(idSession, () => {
            callBackFunction();
          });
        }
    }

  }

}

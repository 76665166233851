export class ExtraInfo {

    idExtraInfo: string;
    selectable: boolean;
    checked: boolean;
    value: string;
    desc: string;
    code: string;
    day: number;
    amount: number;
    type: string;
    level: number;
    start: Date;
    end: Date;
    utente: string;
    sottoLivelli: Array<ExtraInfo>;
}

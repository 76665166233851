export class GenericEntityPcPortfolio {
  additionalLabels: any;
  code = '';
  description: string;
}

export class ProductsObject {
  public products: Array<any> = [];
  public productsType: Array<any> = [];
}

<div>
  <div *ngFor="let parameterRow of parametersRows" class="form-group row">
    <div *ngFor="let parameter of parameterRow" class="col-sm-6 col-xs-12">
      <mic-parameter-control (updateParameter)="onUpdateParameter($event)" [disabledFields]="disabledFields"
                             [disabled]="true" [parameterControlForm]="parametersForm"
                             [parameter]="parameter">
      </mic-parameter-control>
    </div>
  </div>
  <div class="form-group row" *ngIf="coassContainer && coassContainer.enabled && parametersForm">
    <mic-coinsurance (updateParameter)="onUpdateParameter($event)" [parameterControlForm]="parametersForm"
      [coass]="coassContainer"
      (eventPropagation)="eventPropagation.emit($event)"
      (coinsuranceErrorEmitter)="coinsuranceErrorNotificator($event)"
      [editable]="isSectionEditable('policy_data')">
    </mic-coinsurance>
  </div>
</div>

<form [formGroup]="drivingLicenseForm">
  <div class="rgi-ui-panel-content">
    <div class="rgi-ui-grid-2">
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._DRIVING_LICENSE_TYPE_</label>
          <select rgiRxNativeSelect formControlName="idTypeDrivingLicense" data-qa="rgi-anag-driving-type">
            <option selected></option>
            <option *ngFor="let drivingLicense of drivingLicenseTypeList" [value]="drivingLicense.idDrivingLicenseType">{{ drivingLicense.description }}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-col">
        <rgi-rx-form-field style="display: block">
            <label rgiRxLabel><span>{{'_ANAG_._LABEL_._RELEASE_DATE_' | translate}}</span></label>
            <div style="display:flex;">
              <input [rgiRxDateTimeInput]="releaseDatePicker"
                     style="flex: 1"
                     data-qa="rgi-anag-release-date"
                     selectMode="single"
                     rangeSeparator="-"
                     formControlName="dstartDrivingLicense"
                     [max]="maxDate"
                     [placeholder]="datepickerPlaceholder" #inputToTriggerReleaseDate="rgiRxDateTimeInput">
              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerReleaseDate"></button>
            </div>
            <rgi-rx-date-time #releaseDatePicker
                              pickerType="calendar"
                              [hour12Timer]="true"
            ></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-col">
        <rgi-rx-form-field style="display: block">
            <label rgiRxLabel><span>{{'_ANAG_._LABEL_._EXPIRATION_DATE_' | translate}}</span></label>
            <div style="display:flex;">
              <input [rgiRxDateTimeInput]="expirationDatePicker"
                     style="flex: 1"
                     data-qa="rgi-anag-release-date"
                     selectMode="single"
                     rangeSeparator="-"
                     formControlName="dendDrivingLicense"
                     [placeholder]="datepickerPlaceholder" #inputToTriggerExpirationDate="rgiRxDateTimeInput">
              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerExpirationDate"></button>
            </div>
            <rgi-rx-date-time #expirationDatePicker
                              pickerType="calendar"
                              [hour12Timer]="true"
            ></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-col">
        <rgi-rx-form-field class="rgi-anag-pt-l">
          <input type="checkbox" style="margin-left: 1rem;" rgiRxInput formControlName="bdriverQualifCard">
          <label rgiRxLabel translate>_ANAG_._LABEL_._DRIVER_QUALIFICATION_CARD_</label>
        </rgi-rx-form-field>
      </div>
    </div>
  </div>
  <rgi-rx-snackbar tag="error-driving-lecense"></rgi-rx-snackbar>
  <div class="rgi-ui-panel-footer">
    <button type="button" rgi-rx-button color="secondary" (click)="onClose()" translate>_ANAG_._BTN_._CANCEL_</button>
    <button type="button" rgi-rx-button color="primary" (click)="onConfirm()" translate>_ANAG_._BTN_._CONFIRM_</button>
  </div>
</form>

import {ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'pnc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class CardComponent {
  @HostBinding ('class') cardClass = 'pnc-card';
}


@Component({
  selector: 'pnc-card-header',
  templateUrl: './card-header.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class CardHeaderComponent {
  @HostBinding ('class') cardHeaderClass = 'pnc-card-header';
}

export interface Months {
    code: number;
    description: string;
}

export const months: Months[] = [
    { code: 1, description: "_CLAIMS_._JANUARY" },
    { code: 2, description: "_CLAIMS_._FEBRUARY" },
    { code: 3, description: "_CLAIMS_._MARCH" },
    { code: 4, description: "_CLAIMS_._APRIL" },
    { code: 5, description: "_CLAIMS_._MAY" },
    { code: 6, description: "_CLAIMS_._JUNE" },
    { code: 7, description: "_CLAIMS_._JULY" },
    { code: 8, description: "_CLAIMS_._AUGUST" },
    { code: 9, description: "_CLAIMS_._SEPTEMBER" },
    { code: 10, description: "_CLAIMS_._OCTOBER" },
    { code: 11, description: "_CLAIMS_._NOVEMBER" },
    { code: 12, description: "_CLAIMS_._DECEMBER" }
];

export const NOTHING_FOUND: string = "_CLAIMS_._MESSAGE_._NO_RES_FOUND";
import {TaxDetailAPI} from '../../api/tax-detail';
import {TableSchema} from '@rgi/rx/ui';
import {ACCOUNTING_DETAILS_SCHEMA} from './quotation-table-schemas';

export class AccountingDetailsModalData {
  installmentNet: string;
  installmentGross: string;
  tableRows: AccountingDetailsModalRow[];
  schema: TableSchema = ACCOUNTING_DETAILS_SCHEMA;
}

export class AccountingDetailsModalRow {
  riskDescription: string;
  NET: string;
  TOTAL_TAXES: string;
  GROSS: string;
  taxesDetail: TaxDetailAPI[];
  isTotal?: boolean = false;
}

import {Component, OnInit, Input} from '@angular/core';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

export enum MessageType {
  warning = 'warning',
  error = 'error',
  authorization = 'authorization',
  info = 'info',
}

export enum MessageSubType {
  validationError = 'validationError',
  noResults = 'noResults'
}

/* The new property 'objectType' has been introduced in order to specify which kind of
message objects will be passed to the FormMessageComponent, according to the following instructions:

1) 'simple' means string objects.
2) 'complex' means a complex object which should include at least a property called 'text' whose type should be string.
For example:

export class Message {
  constructor(
    public code: string,
    public text: string
  ) {}
}

This property is optional.
The default value is 'simple'.
 */
export enum MessageObjectType {
  simple = 'simple',
  complex = 'complex'
}

@Component({
  selector: 'pnc-form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.css']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class FormMessageComponent implements OnInit {

  private messagesHolder: string[];
  private isTypeError = false;
  private isObjectTypeError = false;
  @Input() type: MessageType = MessageType.error;
  @Input() detail: MessageSubType;
  @Input() objectType: MessageObjectType = MessageObjectType.simple;
  messageType = MessageType;
  messageObjectType = MessageObjectType;

  constructor() {
  }

  @Input() set messages(messages: any[]) {
    this.messagesHolder = messages;
  }

  get messages() {
    if (this.isTypeError) {
      return ['PORTAL_NG_CORE.WRONG_TYPE'];
    }
    if (this.detail === MessageSubType.validationError && !this.messagesHolder) {
      return ['PORTAL_NG_CORE.INVALID_VALUES'];
    }
    if (this.detail === MessageSubType.noResults && !this.messagesHolder) {
      return ['PORTAL_NG_CORE.NO_RESULTS'];
    }
    if (!this.messagesHolder && !this.detail) {
      return ['PORTAL_NG_CORE.ERROR'];
    }
    if (this.isObjectTypeError) {
      return ['PORTAL_NG_CORE.WRONG_OBJECT_TYPE'];
    }
    return this.messagesHolder;
  }

  ngOnInit() {
    if (!Object.values(MessageType).includes(this.type)) {
      this.type = MessageType.error;
      this.isTypeError = true;
    }
    if (!Object.values(MessageObjectType).includes(this.objectType)) {
      this.objectType = MessageObjectType.simple;
      this.isObjectTypeError = true;
    }
  }

}

<rgi-rx-panel>
  <rgi-rx-panel-header (onClose)="actionCancel()" [closeable]="true"><span translate>_RGIPNC_._TITLE_._NODE_LIST_</span>
  </rgi-rx-panel-header>
  <rgi-rx-datatable rgi-rx-qa="node_result" qa-ctx="adapterprint-context"
                    [data]="nodeList" [schema]="schema"
                    [pageOptions]="[10,25,50,100]" (onAction)="onActionClick($event)">
  </rgi-rx-datatable>
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="actionCancel()"><span
        translate>_RGIPNC_._BTN_._CANCEL_</span></button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

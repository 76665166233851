import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OnModalClose, RgiRxOnContainerValueChange} from "@rgi/rx/ui";
import {ReIssueSubtypeEnum} from "../../../../enums/re-issue-subtype-enum";
import {ReIssueTypeEnum} from "../../../../enums/re-issue-type-enum";
import {ReIssueContractBranchEnum} from "../../../../enums/re-issue-contract-branch-enum";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ReIssueContractTypeEnum} from "../../../../enums/re-issue-contract-type-enum";
import {ReIssueLimitationEnum} from "../../../../enums/re-issue-limitation-enum";
import {CONTRACT_TYPES} from "../../../../enums/re-issue-contract-types";
import {AnagFlowData} from "@rgi/anag";
import {RoleConstants} from "../../../../enums/re-issue-role-enum";
import {UserService} from "@rgi/rx/auth";
import {
  ACTIONS,
  COLLECTIVE,
  INDIVIDUAL
} from "../../../../enums/re-issue-enabled-features-visibility";
import {Company} from "../../../../models/domain-models/co-insurance";
import {PushMessageHandlerService, RgiRxPushMessage} from "@rgi/rx";
import {merge, Subscription} from "rxjs";
import {ReIssueCategoriesFilterEnum} from "../../../../enums/re-issue-categories-filter.enum";
import {Entity} from "../../../../models/domain-models/entity";
import {CATEGORIES} from "../../../../re-issue-constants/consultation-contract-branch-categories";
import {SearchCardDto} from "../../re-postsale-model/search-card-dto";

@Component({
  selector: 'lib-re-add-contract-modal',
  templateUrl: './re-add-contract-modal.component.html',
  styleUrls: ['./re-add-contract-modal.component.css']
})
export class ReAddContractModalComponent implements OnInit, OnModalClose {

  protected readonly ReIssueSubtypeEnum = ReIssueSubtypeEnum;
  protected readonly ReIssueTypeEnum = ReIssueTypeEnum;
  protected readonly ReIssueContractBranchEnum = ReIssueContractBranchEnum;
  protected readonly ReIssueContractTypeEnum = ReIssueContractTypeEnum;
  protected readonly ReIssueLimitationEnum = ReIssueLimitationEnum;
  protected readonly CONTRACT_TYPES = CONTRACT_TYPES;

  @Input() nodeList: Array<any> = new Array<any>();
  @Input() productTypeList: Array<any> = new Array<any>();
  @Input() productList: Array<any> = new Array<any>();
  @Input() companyList: Array<Company> = new Array<Company>();
  @Output() updateProducts = new EventEmitter();
  @Output() findPolicies = new EventEmitter();
  @Output() openPoliciesList = new EventEmitter();
  @Output() modalClose = new EventEmitter();

  typeDefault: any;

  searchContractFormGroup: UntypedFormGroup;

  independentAgentList: any;
  branchesList: any;
  roleKeys: any;
  subjectRoles: any = [];
  operatorLight: any;
  nodeDefault: any;

  typeIndividualVisibility = false;
  typeCollectiveVisibility = false;
  actionButtonVisibility = false;

  sub = new Subscription();
  ISSUE_DATE_FIELD_CODE = '3';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private operatorService: UserService,
    private errorMsgService: PushMessageHandlerService,
  ) {
    this.roleKeys = RoleConstants;
    this.nodeDefault = this.operatorService.getUser<any>().salePoint;
    this.operatorLight = this.operatorService.getUser<any>();
    this.independentAgentList = this.operatorLight.salePoint.realPointOfSale.producers;
    this.branchesList = this.operatorLight.salePoint.realPointOfSale.branches;

    if (this.operatorLight.enabledFeatures) {
      this.filterEnabledFeatures();
      this.typeDefault = this.typeIndividualVisibility && this.typeCollectiveVisibility || this.typeIndividualVisibility ?
        ReIssueTypeEnum.INDIVIDUAL : this.typeCollectiveVisibility && !this.typeIndividualVisibility ? ReIssueTypeEnum.COLLECTIVE : null;

    } else {
      this.typeDefault = ReIssueTypeEnum.INDIVIDUAL;
      this.typeIndividualVisibility = true;
      this.typeCollectiveVisibility = true;
      this.actionButtonVisibility = true;
    }

    this.createForm();
  }

  ngOnInit() {
    this.initializeState();
    this.subscribeControls();
  }

  initializeState() {
    if (this.companyList && this.companyList.length === 1) {
      this.searchContractFormGroup.patchValue({
        company: this.companyList[0]
      });
    }
    if (this.nodeList && this.nodeList.length === 1) {
      this.searchContractFormGroup.patchValue({
        salePoint: this.nodeList[0]
      });
    }
    if (this.nodeDefault && this.nodeList && this.nodeList.length > 0) {
      this.nodeList.forEach(node => {
        if (node.code === this.nodeDefault.code) {
          this.searchContractFormGroup.patchValue({
            salePoint: node
          });
        }
      });
    }
  }

  subscribeControls() {
    const formSub =   this.searchContractFormGroup.valueChanges.subscribe(val => {
      this.errorMsgService.clear();
      if (!this.searchContractFormGroup.value.salePoint) {
        this.notifyError( 'CONTRACT_CONSULTATION.FIELDS_MARKED_IN_RED', 'danger');
      }
      if (!this.searchContractFormGroup.value.contractType) {
        this.notifyError( 'CONTRACT_CONSULTATION.FIELDS_MARKED_IN_RED', 'danger');
      }
    });

    merge(
      this.searchContractFormGroup.controls.type.valueChanges,
      this.searchContractFormGroup.controls.limitation.valueChanges
    ).subscribe(() => {
      this.searchContractFormGroup.get('subject').setValue(null);
    });

    const productsSub = merge(
      this.searchContractFormGroup.controls.productType.valueChanges,
      this.searchContractFormGroup.controls.contractBranch.valueChanges,
      this.searchContractFormGroup.controls.type.valueChanges
    ).subscribe(() => {
      this.manageProductList(this.searchContractFormGroup.controls.type.value === ReIssueTypeEnum.COLLECTIVE); // Get Collective or Individual products
      this.filterProductsByContractBranch(this.searchContractFormGroup.controls.contractBranch.value); // Filter by ALL or PC or LIFE
      this.filterProductsByProductType(this.searchContractFormGroup.controls.productType.value); // Filter by product type
    });

    this.sub.add(formSub);
    this.sub.add(productsSub);
  }

  findPolicy() {
    this.errorMsgService.clear();
    if (this.searchContractFormGroup.value.wholeSalesNetwork &&
      !this.searchContractFormGroup.value.policyNumber &&
      !this.searchContractFormGroup.value.proposalNumber) {
      this.notifyError('CONTRACT_CONSULTATION.VALORIZE_NUMBER', 'danger');
      return;
    }
     const body = this.buildFilterPolicy();
    this.openPoliciesList.emit(body);
    // this.stateManager.findPolicies(body).subscribe(result => {
    //   if (result.policyRows.length) {
    //     this.stateManager.goToNextPage(result);
    //   } else {
    //     this.notifyError('CONTRACT_CONSULTATION.NO_RESULTS', 'default');
    //   }
    // });
  }
  buildFilterPolicy(){
    const body: SearchCardDto = new SearchCardDto();
    const formValues = this.searchContractFormGroup.value;
    if (formValues.branchOffice) {
      body.branchId = formValues.branchOffice.objectId;
    }
    if (formValues.company) {
      body.companyId = formValues.company.id;
      body.companyCode = formValues.company.code;
    }
    body.wholeSalesNetwork = formValues.wholeSalesNetwork;
    body.contractTodayEffectDate = formValues.condition;
    body.categories = this.filterCategories();
    if (formValues.contractType) {
      body.contractType = new Entity();
      body.contractType.code = formValues.contractType;
    }
    if (formValues.independentAgent) {
      body.independentAgentId = formValues.independentAgent.identification;
    }
    if (formValues.limitation && formValues.limitation !== ReIssueLimitationEnum.NOT_ANONYMIZED) {
      body.oblivionType = new Entity();
      body.oblivionType.code = formValues.limitation;
    }
    if (formValues.policyNumber && formValues.policyNumber !== '') {
      body.policyNumber = this.validateInputContractNumber(formValues.policyNumber);
    } else if (formValues.proposalNumber && formValues.proposalNumber !== '') {
      body.proposalNumber = this.validateInputContractNumber(formValues.proposalNumber);
    }
    if (formValues.product) {
      body.productCode = formValues.product.product.code;
      body.productId = formValues.product.product.identification;
    }
    if (formValues.productType) {
      body.productTypeCode = formValues.productType.code;
      body.productTypeId = formValues.productType.identification;
    }

    if (formValues.quoteNumber && formValues.quoteNumber !== '') {
      body.proposalNumber = this.validateInputContractNumber(formValues.quoteNumber);
    }
    if (formValues.salePoint) {
      body.salePointId = formValues.salePoint.idSp;
      body.salePointCode = formValues.salePoint.code;
    }
    if (formValues.subject) {
      body.subjectObjectId = formValues.subject.objectId;
    }

    if(formValues.subjectRoles){
      body.subjectRoles = formValues.subjectRoles;
    }

    body.policyType = new Entity();
    body.policyType.code = formValues.type;

    if (formValues.type === ReIssueTypeEnum.COLLECTIVE && formValues.subtype) {
      body.policyType.code = formValues.subtype;
    }

    body.sortType = new Entity();
    body.sortType.code = this.ISSUE_DATE_FIELD_CODE;

    return body;
  }

  validateInputContractNumber(input:string){
    const onlyDigits = new RegExp(/^\d+$/);
    if(!String(input.trim()).match(onlyDigits)){
      this.notifyError('CONTRACT_CONSULTATION.VALORIZE_NUMBER', 'info');
    }
    return input.trim();
  }
  filterCategories() {
    switch (this.searchContractFormGroup.value.contractBranch) {
      case ReIssueContractBranchEnum.ALL: {
        return CATEGORIES;
      }
      case ReIssueContractBranchEnum.PC: {
        return CATEGORIES.filter(x => {
          return x.code !== ReIssueCategoriesFilterEnum.LIFE;
        });
      }
      case ReIssueContractBranchEnum.LIFE: {
        return CATEGORIES.filter(x => {
          return x.code === ReIssueCategoriesFilterEnum.LIFE;
        });
      }
    }
  }
  manageProductList(isCollective: boolean) {
    if (isCollective) {
      this.updateProducts.emit(ReIssueTypeEnum.COLLECTIVE);
    } else {
      this.updateProducts.emit(ReIssueTypeEnum.INDIVIDUAL);
    }
  }

  filterProductsByContractBranch(contractBranch: ReIssueContractBranchEnum) {
    this.productTypeList = this.productTypeList.filter(productTypeList => {
      switch (contractBranch) {
        case ReIssueContractBranchEnum.ALL: return true;
        case ReIssueContractBranchEnum.PC: return productTypeList.policyCategory.codice !== ReIssueCategoriesFilterEnum.LIFE;
        case ReIssueContractBranchEnum.LIFE: return productTypeList.policyCategory.codice === ReIssueCategoriesFilterEnum.LIFE;
      }
    });
    this.productList = this.productList.filter(product => {
      switch (contractBranch) {
        case ReIssueContractBranchEnum.ALL: return true;
        case ReIssueContractBranchEnum.PC: return product.policyCategory.codice !== ReIssueCategoriesFilterEnum.LIFE;
        case ReIssueContractBranchEnum.LIFE: return product.policyCategory.codice === ReIssueCategoriesFilterEnum.LIFE;
      }
    });
  }

  filterProductsByProductType(productType: any) {
    if (productType) {
      this.productList = this.productList.filter(prod => {
        return prod.productType.code === productType.code;
      });
    }
    this.searchContractFormGroup.get('product').setValue(null);
  }

  notifyError(content: string, status: string) {
    const msg: RgiRxPushMessage = new RgiRxPushMessage();
    const opts = {icon: 'rgi-ui-icon-alert'};
    msg.tag = 'searchErrorMessage';
    msg.status = status;
    msg.content = content;
    msg.dismissible = false;
    msg.options = opts;
    this.errorMsgService.notify(msg);
  }

  createForm() {
    this.searchContractFormGroup = this.formBuilder.group(
      {
        contractBranch: [ReIssueContractBranchEnum.ALL,],
        type: [this.typeDefault,],
        subtype: [ReIssueSubtypeEnum.MASTER_POLICY,],
        limitation: [ReIssueLimitationEnum.NOT_ANONYMIZED,],
        salePoint: ['', Validators.required],
        subnet: [{value: true, disabled: true},],
        company: [null,],
        independentAgent: [null,],
        branchOffice: [null,],
        contract: [null,],
        contractType: [ReIssueContractTypeEnum.POLICIES_OR_PROPOSALS, Validators.required],
        proposalNumber: [null,],
        policyNumber: [null,],
        quoteNumber: [null,],
        productType: [null,],
        product: [null,],
        condition: ['false',],
        subject: [null,],
        subjectRoles: [null,]
      }
    );
  }

  filterEnabledFeatures() {
    INDIVIDUAL.forEach(code => {
      if (this.operatorLight.enabledFeatures.indexOf(code) >= 0) {
        this.typeIndividualVisibility = true;
      }
    });
    COLLECTIVE.forEach(code => {
      if (this.operatorLight.enabledFeatures.indexOf(code) >= 0) {
        this.typeCollectiveVisibility = true;
      }
    });
    ACTIONS.forEach(code => {
      if (this.operatorLight.enabledFeatures.indexOf(code) >= 0) {
        this.actionButtonVisibility = true;
      }
    });
  }

  getSubjectDropContainerData(role) {
    const data = new AnagFlowData();
    data.partyRole = '' + role;
    const formValues = this.searchContractFormGroup.value;
    if (formValues.salePoint) {
      data.nodeCode = formValues.salePoint.code;
    }
    return data;
  }

  anagSubjectSelected(val: RgiRxOnContainerValueChange) {
  }

  removeSubjectFormData() {
    this.searchContractFormGroup.get('SubjectRole').setValue(null);
  }

  isPolicyNumVisible() {
    return (this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.PROPOSALS
        && (this.searchContractFormGroup.value.limitation !== ReIssueLimitationEnum.ANONYMIZED
          && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.QUOTE_W_REG_PARTY
          && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.ANONYMOUS_QUOTE)
        || (this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE
          && (this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.QUOTE_W_REG_PARTY
            || this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.ANONYMOUS_QUOTE
            || this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.PROPOSALS)))
      &&
      (!(this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE &&
        this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION &&
        this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.POLICIES))
      &&
      (!(this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE &&
        this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.MASTER_POLICY &&
        this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.PROPOSALS));
  }

  isMasterPolicyVisible() {
    return this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE &&
      (!(this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE &&
        this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION &&
        this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.POLICIES)) &&
      (!(this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE &&
        this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.MASTER_POLICY &&
        this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.PROPOSALS));
  }

  isApplicationNumberVisible() {
    return this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE
      && this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION
      && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.PROPOSALS
      && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.QUOTE_W_REG_PARTY
      && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.ANONYMOUS_QUOTE;
  }

  isProposalNumVisible() {
    return this.searchContractFormGroup.value.limitation === ReIssueLimitationEnum.NOT_ANONYMIZED
      && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.POLICIES
      && (((this.searchContractFormGroup.value.type === ReIssueTypeEnum.INDIVIDUAL
            || (this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE
              && this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION))
          && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.QUOTE_W_REG_PARTY
          && this.searchContractFormGroup.value.contractType !== ReIssueContractTypeEnum.ANONYMOUS_QUOTE)
        || (this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE
          && this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.MASTER_POLICY));
  }

  isQuoteNumberVisible() {
    return (this.searchContractFormGroup.value.type === ReIssueTypeEnum.INDIVIDUAL
        || (this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE &&
          this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION))
      && (this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.QUOTE_W_REG_PARTY
        || this.searchContractFormGroup.value.contractType === ReIssueContractTypeEnum.ANONYMOUS_QUOTE);
  }

  isProductVisible() {
    return !(this.searchContractFormGroup.value.type === ReIssueTypeEnum.COLLECTIVE
        && this.searchContractFormGroup.value.subtype === ReIssueSubtypeEnum.APPLICATION)
      || this.searchContractFormGroup.value.type !== ReIssueTypeEnum.COLLECTIVE
      && this.searchContractFormGroup.value.subtype !== ReIssueSubtypeEnum.APPLICATION
      && this.searchContractFormGroup.value.limitation !== ReIssueLimitationEnum.ANONYMIZED;
  }

  resetNumberValues() {
    this.searchContractFormGroup.get('proposalNumber').setValue(null);
    this.searchContractFormGroup.get('policyNumber').setValue(null);
    this.searchContractFormGroup.get('quoteNumber').setValue(null);
  }

  resetProductFiled() {
    this.searchContractFormGroup.get('productType').setValue(null);
    this.searchContractFormGroup.get('product').setValue(null);
  }

  close() {
    this.modalClose.emit();
  }

}

<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()"><span translate>RE_ISSUE.QUOTATION_DETAILS</span>
  </rgi-rx-panel-header>


  <div class="issue-card-modal-body modal-premium large-modal">
    <div class="row">
      <div class="col-xs-4 tbld_col_summary tbld_annual_premium"
           *ngFor="let premiumDetail of premiumDetailSections; let i = index" attr.data-qa="section_{{i}}">
        <div class="tbld">
          <div class="tbld_row premium-title">
            <div class="tbld_col tbld_col_title"><span class="tbld_title_premi" attr.data-qa="section_title_{{i}}"
                                                       translate>RE_ISSUE.{{ premiumDetail.sectionTitle }}</span></div>
            <div class="tbld_col tbld_col_value">
                                    <span attr.data-qa="section_premium_{{i}}"
                                          [ngClass]="{'tbld_premium_gross_small': (premiumDetail.grossAmount).length > 5}"
                                          class="pull-right tbld_premium_gross">{{ premiumDetail.grossAmount | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;<span
                                      class="tbld_title_value_premi"></span></span>
            </div>
          </div>
          <div class="arrow-down"></div>
          <div class="tbld_row premium_tab" data-qa="premium_net">
            <div class="tbld_col tbld_col_title tbld_col_title_premi" translate>RE_ISSUE.NET</div>
            <div class="tbld_col tbld_col_value tbld_col_value_premi">
              <b class="pull-right">
                                        <span><span>
                                            </span>{{ premiumDetail.netAmount | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
              </b>
            </div>
          </div>
          <div class="tbld_row  premium_tab" data-qa="premium_accessories">
            <div class="tbld_col tbld_col_title tbld_col_title_premi" translate>RE_ISSUE.ACCESSORIES</div>
            <div class="tbld_col tbld_col_value tbld_col_value_premi">
              <b class="pull-right">
                                        <span><span>
                                            </span>{{ premiumDetail.accessories | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
              </b>
            </div>
          </div>
          <div class="tbld_row premium_tab" data-qa="premium_interest">
            <div class="tbld_col tbld_col_title tbld_col_title_premi " translate>RE_ISSUE.INST_INTEREST
            </div>
            <div class="tbld_col tbld_col_value tbld_col_value_premi">
              <b class="pull-right">
                                        <span><span>
                                            </span>{{ premiumDetail.instalmentInterest | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
              </b>
            </div>
          </div>
          <div class="tbld_row premium_tab" data-qa="premium_taxable">
            <div class="tbld_col tbld_col_title tbld_col_title_premi " translate>RE_ISSUE.TAXABLE</div>
            <div class="tbld_col tbld_col_value tbld_col_value_premi">
              <b class="pull-right">
                                        <span><span>
                                            </span>{{ premiumDetail.taxableAmount | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
              </b>
            </div>
          </div>
          <div class="tbld_row premium_tab" data-qa="premium_taxes">
            <div class="tbld_col tbld_col_title tbld_col_title_premi" translate>RE_ISSUE.TAXES</div>
            <div class="tbld_col tbld_col_value tbld_col_value_premi">
              <b class="pull-right">
                                        <span><span>
                                            </span>{{ premiumDetail.taxes | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <rgi-rx-panel-footer>

  </rgi-rx-panel-footer>

</rgi-rx-panel>

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DamageCategory } from './components/claim-opening-policy-search/dto/damage-categories';

@Injectable({
  providedIn: 'root'
})
export class DamageCategoryService {

  private baseApiUrl: string;
  private urlDamageCategory = '/claims/damage-categories';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getCategories() {
    return this.httpClient.get(this.baseApiUrl + this.urlDamageCategory);
  }

  getCategory(id: string): Observable<DamageCategory> {
    return this.httpClient.get<DamageCategory>(this.baseApiUrl + this.urlDamageCategory + '/' + id);
  }
}

import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PaymentFields } from '../../models/meansofpayment.model';
import { DatepickerPaymentComponent } from './datepicker-payment/datepicker-payment.component';
import { InputPaymentComponent } from './input-payment/input-payment.component';


@Injectable({
  providedIn: 'root'
})
export class PaymentWrapperService {

constructor() { }

// eslint-disable-next-line max-len
public createPaymentFields(resolver: ComponentFactoryResolver, ref: ComponentRef<any>, view: ViewContainerRef, group: UntypedFormGroup, config: PaymentFields, disableField = null) {
  let outputPayment: any;
  switch (config.type) {
    case 'N':
        outputPayment = resolver.resolveComponentFactory(InputPaymentComponent);
        ref = view.createComponent(outputPayment);
        const inputPaymentN: ComponentRef<InputPaymentComponent> = ref as ComponentRef<InputPaymentComponent>;
        inputPaymentN.instance.setPayment(config, group, disableField);
        break;

    case 'R':
      outputPayment = resolver.resolveComponentFactory(InputPaymentComponent);
      ref = view.createComponent(outputPayment);
      const inputPaymentR: ComponentRef<InputPaymentComponent> = ref as ComponentRef<InputPaymentComponent>;
      inputPaymentR.instance.setPayment(config, group, disableField);
      break;

    case 'F':
      outputPayment = resolver.resolveComponentFactory(InputPaymentComponent);
      ref = view.createComponent(outputPayment);
      const inputPayment: ComponentRef<InputPaymentComponent> = ref as ComponentRef<InputPaymentComponent>;
      inputPayment.instance.setPayment(config, group, disableField);
      break;

    case 'D':
      outputPayment = resolver.resolveComponentFactory(DatepickerPaymentComponent);
      ref = view.createComponent(outputPayment);
      const datePayment: ComponentRef<DatepickerPaymentComponent> = ref as ComponentRef<DatepickerPaymentComponent>;
      datePayment.instance.setPayment(config, group);
      break;

  }
}
}

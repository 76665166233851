import { PortalCard, PortalCardConfig } from '@rgi/rx/portal';

export const cardCaiOpening: PortalCardConfig = {
    card: {
        title: '_CLAIMS_TITLE_CARD_CAI_',
        type: 'sub',
        category: 'training',
        name: 'newClaimsHome',
        navigationInSession: true,
        subCards: [],
        subCardsActive: []
    } as PortalCard,
    routes: [
        {
            route: 'editClaimHome',
            destination: 'newClaimsHome', // la rappresentazione data nel router nuovo
        },
        {
            route: 'newCarBodywork',
            destination: '',
            template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
            controller: 'CardPassCtrl'
        },
        {
            route: 'summary',
            destination: '',
            template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
            controller: 'CardPassCtrl'
        }
    ]
};

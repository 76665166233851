import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AnagFormFieldConfig} from "../../../anag-model/anag-domain/anag-form-field-config";
import {AnagConfigService} from "../../../anag-resources/anag-config.service";
import {AnagConfiguration} from "../../../anag-model/anag-domain/anag-configuration";
import {
  AnagDrivingLicense,
  AnagDrivingLicenseTypeList
} from "../../../anag-model/anag-domain/anag-document";
import {AnagStatelessService} from "../../../anag-resources/anag-stateless.service";
import {AnagStorageService} from "../../../anag-resources/anag-storage.service";
import {RgiRxUserService} from "@rgi/rx/auth";
import {AnagApiParty} from "../../../anag-model/anag-domain/anag-api-party";
import {PushMessageHandlerService, RgiRxPushMessage} from "@rgi/rx";
import {DatePipe} from "@angular/common";
import {distinctUntilChanged} from "rxjs/operators";
import {Subscription} from "rxjs";
import {AnagUtilsService} from "../../../anag-resources/anag-utils.service";
import {AnagStateManagerPartyEditor} from "../../../anag-states/party-editor/anag-state-manager-party-editor";

@Component({
  selector: 'rgi-anag-document-driving-license-type',
  templateUrl: './anag-document-driving-license-type.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagDocumentDrivingLicenseTypeComponent implements OnInit, OnDestroy {
  drivingLicenseForm: UntypedFormGroup = new UntypedFormGroup({
    idTypeDrivingLicense: new UntypedFormControl('', [Validators.required]),
    dstartDrivingLicense: new UntypedFormControl('', [Validators.required]),
    bdriverQualifCard: new UntypedFormControl('', [])
  });
  datepickerPlaceholder: string;
  modalClose: EventEmitter<any> = new EventEmitter();
  drivingLicenseTypeList: Array<AnagDrivingLicenseTypeList>;
  subscriptions: Subscription = new Subscription();
  maxDate = new Date();


  drivingLicenceFormMap = new Map<string, AnagFormFieldConfig>([
    ['dendDrivingLicense', new AnagFormFieldConfig('dEndDrivingLicense', this.drivingLicenseForm, [], 'drivingLicense')],
  ]);
  @Output() onCloseDrivingLicence = new EventEmitter<any>();
  @Output() onConfirmDrivingLicence = new EventEmitter<any>();
  @Input() configuration: AnagConfiguration;
  @Input() drivingLicense: AnagDrivingLicense;
  @Input() party: AnagApiParty;
  @Input() expirationDateDocument: Date;
  @Input() drivingLicenseList: Array<AnagDrivingLicense>;
  @Input() editMode: boolean;
  @Input() indexDrivingLicense: number;

  constructor(
    public stateMgr: AnagStateManagerPartyEditor,
    public configService: AnagConfigService,
    public statelessService: AnagStatelessService,
    public anagStorage: AnagStorageService,
    protected userService: RgiRxUserService,
    public pushMessageHandler: PushMessageHandlerService,
    public datePipe: DatePipe,
    protected anagUtilsService: AnagUtilsService,) {
  }

  ngOnInit(): void {
    this.pushMessageHandler.clearTag('error-driving-lecense');
    this.datepickerPlaceholder = this.statelessService.getDatePickerPlaceholderLabel();
    this.configService.setConfigToForm(this.drivingLicenceFormMap, this.configuration);
    if (this.drivingLicense) {
      this.adaptDrivingLecenseToForm();
    }
    if (this.party.partyKey && this.party.partyKey.length > 0) {
      this.anagStorage.getDrivingLicenseType(this.party.partyKey[0].country).subscribe(drivingLicenseList => {
        if (drivingLicenseList && drivingLicenseList.length > 0) {
          this.drivingLicenseTypeList = drivingLicenseList;
        }
      })
    }
    if (this.editMode && !this.stateMgr.getCurrentState().isValidDrivingLecense && this.party?.dateOfBirth && this.drivingLicense) {
      const array = [];
      array.push(this.drivingLicense)
      const filter = this.anagUtilsService.createCheckDrivingLicenseFilter(this.party?.dateOfBirth, this.expirationDateDocument, array);
      this.anagStorage.checkDrivingLicense(filter).subscribe(resp => {
        if (resp.outcomeCode === '0') {
          this.notifyErrorMsg(resp.message);
        }
      });
    }
    this.manageSubscriptions();
  }

  manageSubscriptions() {
    this.subscriptions.add(this.drivingLicenseForm.get('dstartDrivingLicense').valueChanges.pipe(distinctUntilChanged()).subscribe(date => {
      this.pushMessageHandler.clearTag('error-driving-lecense');
      this.configService.dateComparisonValidator(this.drivingLicenseForm.get('dstartDrivingLicense'), this.drivingLicenseForm.get('dendDrivingLicense'));
    }));
    this.subscriptions.add(this.drivingLicenseForm.get('dendDrivingLicense').valueChanges.pipe(distinctUntilChanged()).subscribe(date => {
      this.pushMessageHandler.clearTag('error-driving-lecense');
      this.configService.dateComparisonValidator(this.drivingLicenseForm.get('dstartDrivingLicense'), this.drivingLicenseForm.get('dendDrivingLicense'));
    }));
  }

  adaptDrivingLecenseToForm() {
    if (this.drivingLicense.idTypeDrivingLicense) {
      this.setControlValue(this.drivingLicenseForm.get('idTypeDrivingLicense'), this.drivingLicense.idTypeDrivingLicense);
    }
    if (this.drivingLicense.dstartDrivingLicense) {
      this.drivingLicenseForm.get('dstartDrivingLicense').setValue(new Date(this.drivingLicense.dstartDrivingLicense));
    }
    if (this.drivingLicense.dendDrivingLicense) {
     this.drivingLicenseForm.get('dendDrivingLicense').setValue(new Date(this.drivingLicense.dendDrivingLicense));
    }
    if (this.drivingLicense.bdriverQualifCard) {
      this.setControlValue(this.drivingLicenseForm.get('bdriverQualifCard'), this.drivingLicense.bdriverQualifCard);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onClose() {
    this.onCloseDrivingLicence.emit();
  }

  onConfirm() {
    if (this.drivingLicenseForm.valid) {
      if (this.party?.dateOfBirth) {
        this.checkDrivingLicense();
      } else {
        this.pushMessageHandler.clearTag('error-driving-lecense');
        this.onConfirmDrivingLicence.emit(this.updateDrivingLicenseType(this.drivingLicenseForm.getRawValue()));
      }
    } else {
      this.notifyErrorMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_');
    }
  }

  updateDrivingLicenseType(formRawData) {
    let drivingLicenseType = new AnagDrivingLicense();
    if (formRawData.idTypeDrivingLicense) {
      const drivingLicense = this.drivingLicenseTypeList.find(license => license.idDrivingLicenseType === formRawData.idTypeDrivingLicense);
      drivingLicenseType.idTypeDrivingLicense = drivingLicense.idDrivingLicenseType;
      drivingLicenseType.code = drivingLicense.code;
      drivingLicenseType.descr = drivingLicense.description;
    }
    drivingLicenseType.dendDrivingLicense = formRawData.dendDrivingLicense;
    drivingLicenseType.dstartDrivingLicense = formRawData.dstartDrivingLicense;
    drivingLicenseType.bdriverQualifCard = formRawData.bdriverQualifCard;
    return drivingLicenseType;
  }

  setControlValue(control: AbstractControl, value: any) {
    if (control && value) {
      control.setValue(value);
    }
  }

  notifyErrorMsg(errorMsg) {
    this.pushMessageHandler.clearTag('error-driving-lecense');
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
      tag: 'error-driving-lecense',
      status: 'danger',
      dismissible: false
    }));
  }

  checkDrivingLicense() {
    const drivingLicenseListCloned = this.statelessService.deepClone(this.drivingLicenseList);
    if (this.editMode) {
      drivingLicenseListCloned[this.indexDrivingLicense] = this.updateDrivingLicenseType(this.drivingLicenseForm.getRawValue());
    } else {
      drivingLicenseListCloned.push(this.updateDrivingLicenseType(this.drivingLicenseForm.getRawValue()));
    }
    const filter = this.anagUtilsService.createCheckDrivingLicenseFilter(this.party?.dateOfBirth, this.expirationDateDocument, drivingLicenseListCloned);
    this.anagStorage.checkDrivingLicense(filter).subscribe(resp => {
      if (resp.outcomeCode !== '0') {
        this.pushMessageHandler.clearTag('error-driving-lecense');
        this.onConfirmDrivingLicence.emit(this.updateDrivingLicenseType(this.drivingLicenseForm.getRawValue()));
      } else {
        this.notifyErrorMsg(resp.message);
      }
    });
  }
}

import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})

// service da rimuovere se viene fatto un service di prodotto a seguito di questo RQP-9132
export class AnagPortalUtilityService {
    error: boolean;
    listFormats: string[];
    formatNumber: Map<string, string> = new Map();
    format: string;
    mask: string[];

    setFormatNumber(controlName: string, format: string) {
        this.formatNumber.set(controlName, format);
    }

    getFormatNumber(controlName: string) {
        return this.formatNumber.get(controlName);
    }

    removeFormat(formGroup: UntypedFormGroup, controlName: string , value: string) {
      if (value) {
        formGroup.controls[controlName].setValue(value.replace(/\s/g, ''));
      }
    }
    
    applyFormat(formGroup: UntypedFormGroup, controlName: string, value: string, format: string) {
        this.error = false;
        if (value) {
            value = value.replace(/\s/g, '');
            if (format && value) {
            value = this.applyFormatNumber(value, format);
            }
            if (this.error) {
                formGroup.controls[controlName].setErrors({'_ANAG_._MSG_._INVALID_FORMAT_': true})
            } else {
                formGroup.patchValue({
                [controlName]: value
            });
            }
        }
    }
    
      applyFormatNumber(value: string, formatNumber: string) {
        this.listFormats = formatNumber?.split(';');
        if (this.listFormats && this.listFormats.length > 0) {
          for(let i = 0; i < this.listFormats.length; i++) {
            this.format = this.listFormats[i];
            if (value.length === this.format.replace(/\s/g, '').length) {
              this.error = false;
              value = this.applyNumberFormat(value);
              break;
            }
            this.error = true;
          }
        }
        return value;
      }
    
      applyNumberFormat(value: string) {
        return this.initMask(value)
      }
    
      initMask(value: string) {
        this.mask = this.getFieldMask();
    
        const regex = this.createRegex();
    
        value = this.getMaskedValue(value);
    
        if (value.length > 0 && !value.match(regex)) {
          this.error = true;
        }
        return value;
      }
    
      getFieldMask() {
        return this.format.split('');
      }
    
      getMaskedValue(field) {
        if (!this.mask) {
          this.mask = this.getFieldMask()
        }
        if (this.mask === null) {
          return '';
        }
    
        let newVal = this.applyMask(field.split(''));
    
        while (newVal.endsWith(' ')) {
          newVal = newVal.substring(0, newVal.length - 1);
        }
    
        return newVal;
      }
    
      applyMask(field) {
        return this.mask.map(function(currentChar) {
          if ((currentChar != 'N') && (currentChar != 'X') && (currentChar != '*')) {
            return currentChar;
          }
          return field.shift();
        }).join('');
      }
    
      createRegex() {
        let regex = '';
    
        for (let i = 0; i < this.mask.length; i++) {
          const newChar = this.mask[i];
    
          if (newChar == 'N') {
            regex += '([0-9]{1})+';
          } else if (newChar == 'X') {
            regex += '([a-zA-Z]{1})+';
          } else if (newChar == '*') {
            regex += '([a-zA-Z0-9]{1})+';
          } else if (newChar == ' ') {
            regex += '[\\s]+';
          } else {
            regex += newChar;
          }
        }
        return new RegExp(regex);
      }
}
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { RgiRxDatatableComponent, TableSchema } from '@rgi/rx/ui';
import { GenericEntity, OperatorLight } from '../models/total-summary.model';
import { TotalSummaryService } from '../services/total-summary.service';
import { RgiRxUserService } from '@rgi/rx/auth';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Component({
  selector: 'iiab-total-summary',
  templateUrl: './total-summary.component.html'
})
export class TotalSummaryComponent implements OnChanges {

  @Input() data: GenericEntity[] = [];
  @Input() schema: TableSchema;

  @ViewChild('totalSummaryTable', { static: false }) totalSummaryTable!: RgiRxDatatableComponent;

  constructor(
    protected i18nService: RgiRxTranslationService,
    protected totalSummaryService: TotalSummaryService,
    protected userService: RgiRxUserService,
    protected rgiFormatter: RgiCountryLayerNumberFormatPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue) {
      this.data.map((elem) => {
        if (elem.value.includes('.') && !Number.isNaN(elem.value)) {
          const options: Intl.NumberFormatOptions = this.totalSummaryService.getFormatterOptionsWithDecimal('decimal', '0.2-2');
          const context = this.i18nService.currentLanguage && this.userService.getUser<OperatorLight>().salePoint?.context ? this.i18nService.currentLanguage + '-' + this.userService.getUser<OperatorLight>().salePoint.context : null;
          elem.value = this.rgiFormatter.transform(elem.value, context, options);
        }
      });

      setTimeout(() => {
        if (this.totalSummaryTable) {
          this.totalSummaryTable.showPaginator = false;
        }
      }, 0);
    }
  }
}

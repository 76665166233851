import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { OnModalClose } from '@rgi/rx/ui';
import { DscDriverLicenseModalService } from './dsc-car-bodywork-modal.service';
import { DataTable, FormDataModel } from './dsc-car-bodywork-filter.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'claims-app-dsc-car-bodywork-modal',
  templateUrl: './dsc-car-bodywork-modal.component.html',
  styleUrls: ['./dsc-car-bodywork-modal.component.scss'],
})
export class DscCarBodyworkModalComponent implements OnInit, OnModalClose {

  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  formData = new FormDataModel();
  showNewBodywork = true; // Imposta a true o false in base alle tue esigenze
  dataTable: DataTable = {
    data: [],
    arrayBodyworkTypes: [],
  };
  trusteeTypes;
  bodyworkTypeSix;
  bodyworkTypeSeven;
  arrayBodyworkTypes;
  typeBodyworkIsEmpty: boolean = false;
  noResult: boolean = false;

  constructor(
    private driverLicenseModalService: DscDriverLicenseModalService,
    @Inject('enumService') private enumService
  ) {}

  ngOnInit() {
    this.dataTable.data = [];
    this.trusteeTypes = this.enumService.getEnumList('sinistri.TipoSoggetto');
    this.bodyworkTypeSix = this.enumService.getEnum(
      'sinistri.TipoFiduciario',
      '6'
    );
    this.bodyworkTypeSeven = this.enumService.getEnum(
      'sinistri.TipoFiduciario',
      '7'
    );
    this.arrayBodyworkTypes = [this.bodyworkTypeSix, this.bodyworkTypeSeven];
  }

  submitForm() {
    this.noResult = false;
    if (this.formData.typeBodywork !== '') {
      this.typeBodyworkIsEmpty = false;
      this.driverLicenseModalService
        .searchTrustee({
          filterCarBodyWork: {
            trusteeType: this.formData.trusteeType,
            typeBodywork: this.formData.typeBodywork,
          },
        })
        .pipe(
          map((data) => {
            const filteredData = data.listCarBodyWork.filter((el: any) => {
              const driver = el.driver;
              return (
                (el.code === this.formData.code || this.formData.code === '') &&
                ((driver && driver.givenName === this.formData.name) ||
                  this.formData.name === '') &&
                ((driver && driver.surname === this.formData.surname) ||
                  this.formData.surname === '') &&
                ((driver &&
                  driver.anagSubject &&
                  driver.anagSubject.nominative ===
                    this.formData.businessName) ||
                  this.formData.businessName === '') &&
                ((driver && driver.fiscalCode === this.formData.fiscalCode) ||
                  this.formData.fiscalCode === '') &&
                ((driver && driver.vatCode === this.formData.vatCode) ||
                  this.formData.vatCode === '')
              );
            });
            return filteredData;
          })
        )
        .subscribe((data: any) => {
          this.dataTable.data = data;
          this.noResult = this.dataTable.data.length === 0;
          this.dataTable.arrayBodyworkTypes = this.arrayBodyworkTypes;
        });
    } else {
      this.typeBodyworkIsEmpty = true;
    }
  }

  onTypeBodyworkChange() {
    if (this.formData.typeBodywork !== '') {
      this.typeBodyworkIsEmpty = false;
    }
  }

  resetForm() {
    this.formData = new FormDataModel();
  }

  newBodywork() {
    console.log('New Bodywork');
  }

  closeModal(event) {
    this.resetResult();
    this.modalClose.emit(event);
  }

  resetResult() {
    this.dataTable.data = [];
    this.resetForm();
  }
  newBodyWork() {
    this.closeModal('newBodywork');
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {getPerc, integerDiv} from '../../../../adapters/group-policy-utils';
import {SummaryParties} from '../../../../group-policy-models/group-policy-issue-summary';


/**
 * @author: dmasone
 * @description: Component used for render summary parties data
 */
@Component({
    selector: 'rgi-gp-group-policy-summary-parties-component',
    templateUrl: './group-policy-summary-parties.component.html'
})
export class GroupPolicySummaryPartiesComponent implements OnInit, OnDestroy {


    @Input() summaryParties: SummaryParties[];

    public modelKeys = [];
    public integerDiv = integerDiv;
    public getPerc = getPerc;

    constructor() { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

}

import {
  ReIssueGeneralDataComponent
} from './re-issue-policy-data/re-issue-general-data/re-issue-general-data.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {ReIssueAssetComponent} from './re-issue-asset/re-issue-asset.component';
import {ReIssuePolicyDataComponent} from './re-issue-policy-data/re-issue-policy-data.component';
import {ReIssueQuotationComponent} from './re-issue-quotation/re-issue-quotation.component';
import {
  ReIssueTechnicalDetailsComponent
} from './re-issue-policy-data/re-issue-technical-details/re-issue-technical-details.component';
import {ReIssueVariablesComponent} from './re-issue-variables/re-issue-variables.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ReIssueVariableControlComponent
} from './re-issue-variables/re-issue-variable-control/re-issue-variable-control.component';
import {ReIssueStepperComponent} from './re-issue-stepper/re-issue-stepper.component';
import {ReIssueAssetModalComponent} from './re-issue-asset/re-issue-asset-modal/re-issue-asset-modal.component';
import {
  ReIssueQuotationControlsComponent
} from './re-issue-quotation/re-issue-quotation-controls/re-issue-quotation-controls.component';
import {ParametersComponent} from './re-issue-quotation/re-issue-parameters/re-issue-parameters.component';
// tslint:disable-next-line:max-line-length
import {
  ReIssueParameterControlComponent
} from './re-issue-quotation/re-issue-parameters/re-issue-parameter-control/re-issue-parameter-control.component';
import {ReIssueAssetUnitComponent} from './re-issue-quotation/re-issue-asset-unit/re-issue-asset-unit.component';
// tslint:disable-next-line:max-line-length
import {
  ReIssueQuotationDetailModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-quotation-detail-modal/re-issue-quotation-detail-modal.component';
// tslint:disable-next-line:max-line-length
import {
  ReIssueContributionTaxesModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-contribution-taxes-modal/re-issue-contribution-taxes-modal.component';
// tslint:disable-next-line:max-line-length
import {
  ReIssueCommissionModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-commission-modal/re-issue-commission-modal.component';
// tslint:disable-next-line:max-line-length
import {
  ReIssueWarrantyModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-warranty-modal/re-issue-warranty-modal.component';
import {ReIssueStartCardComponent} from './re-issue-start-card/re-issue-start-card.component';
// tslint:disable-next-line:max-line-length
import {
  ReIssueQuestionnaireModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-questionnaire-modal/re-issue-questionnaire-modal.component';
import {
  ReIssueQuestionnairesComponent
} from './re-issue-quotation/re-issue-questionnaires/re-issue-questionnaires.component';
import {ReIssueSummaryComponent} from './re-issue-summary/re-issue-summary.component';
import {
  RgiRxAutoCompleteModule,
  RgiRxDatePickerModule,
  RgiRxDragDropModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule,
  RgiRxMenuModule,
  RgiRxModalModule,
  RgiRxPanelModule,
  RgiRxSnackbarModule,
  RgiRxStepperModule,
  RgiRxSwitchModule,
  RgiRxTableModule,
  RgiRxTabModule
} from '@rgi/rx/ui';
import {ReIssueQuotationSaveComponent} from './re-issue-quotation-save/re-issue-quotation-save.component';
import {
  ReIssueProposalManagementComponent
} from './re-issue-proposal-management/re-issue-proposal-management.component';
import {ReIssueEmissionComponent} from './re-issue-emission/re-issue-emission.component';
import {ReIssueSaveQuoteComponent} from './re-issue-save-quote/re-issue-save-quote.component';
import {ReIssuePdfQuoteModalComponent} from './re-issue-pdf-quote-modal/re-issue-pdf-quote-modal.component';
import {ReIssueAddressComponent} from './re-issue-address/re-issue-address.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ReIssueEnterPinModalComponent} from './re-issue-enter-pin-modal/re-issue-enter-pin-modal.component';
import {ReIssuePrintPdfComponent} from './re-issue-print-pdf/re-issue-print-pdf.component';
import {
  ReIssueProposalQuestionairesModalComponent
} from './re-issue-proposal-management/re-issue-proposal-questionaires-modal/re-issue-proposal-questionaires-modal.component';
import {
  ReIssuePortfolioTechnicalDataResourceService
} from './re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-technical-data-resource.service';
import {ReIssueTechnicalDataResourceService} from './re-issue-resources/re-issue-technical-data-resource.service';
import {CommonResourceService} from './re-issue-address-service/common-resource.service';
import {
  ReIssuePortfolioCommonResourceService
} from './re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-common-resource.service';
import {ReIssueVariablesResourceService} from './re-issue-resources/re-issue-variables-resource.service';
import {
  ReIssuePortfolioVariablesResourceService
} from './re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-variables-resource.service';
import {ReIssueGeneralDataResourceService} from './re-issue-resources/re-issue-general-data-resource.service';
import {
  ReIssuePortfolioGeneralDataResourceService
} from './re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-general-data-resource.service';
import {ReIssueWarningsResourceService} from './re-issue-resources/re-issue-warnings-resource.service';
import {
  ReIssuePortfolioWarningsResourceService
} from './re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-warnings-resource.service';
import {ReIssueAssetsResourceService} from './re-issue-resources/re-issue-assets-resource.service';
import {
  ReIssuePortfolioAssetsResourceService
} from './re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-assets-resource.service';
import {ReIssueFactorsComponent} from './re-issue-factors/re-issue-factors.component';
import {ReIssuePackageComponent} from './re-issue-quotation/re-issue-package/re-issue-package.component';
import {ReIssueReprintMovementsComponent} from './re-issue-reprint-movements/re-issue-reprint-movements.component';
import {ReIssueReprintDocumentsComponent} from './re-issue-reprint-documents/re-issue-reprint-documents.component';
import {ReIssueCardDocumentComponent} from './re-issue-card-document/re-issue-card-document.component';
import {
  ReIssueCardDocumentPreviewComponent
} from './re-issue-card-document-preview/re-issue-card-document-preview.component';
import {ReIssueClausesComponent} from './re-issue-clauses/re-issue-clauses.component';
import {ClauseModalComponent} from './re-issue-clauses/clause-modal/clause-modal.component';
import {ReSubstitutionStartComponent} from './re-postsale/re-substitution-start/re-substitution-start.component';
import {RgiAnagModule} from '@rgi/anag';
import {ReIssueContactComponent} from './re-issue-contact/re-issue-contact.component';
import {BeneficiariesComponent} from './re-issue-quotation/re-issue-beneficiaries/re-issue-beneficiaries.component';
import {ReIssueMsgModalComponent} from './re-issue-msg-modal/re-issue-msg-modal.component';
import {ThirdPersonComponent} from './re-issue-quotation/re-issue-third-person/re-issue-third-person.component';
import {
  ReIssueStartCardNodeModalComponent
} from './re-issue-start-card/re-issue-start-card-modal/re-issue-start-card-node-modal/re-issue-start-card-node-modal.component';
import {
  ReCancellationStartComponent
} from './re-postsale/re-cancellation/re-cancellation-start/re-cancellation-start.component';
import {
  ReCancellationSummaryComponent
} from './re-postsale/re-cancellation/re-cancellation-summary/re-cancellation-summary.component';
import {
  ReCancellationConfirmationComponent
} from './re-postsale/re-cancellation/re-cancellation-confirmation/re-cancellation-confirmation.component';
import {
  ReIssueDiscountModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-discount-modal/re-issue-discount-modal.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {
  ReIssueAssetActivitiesComponent
} from './re-issue-asset/re-issue-asset-activities/re-issue-asset-activities.component';
import {ReIssuePremiumComponent} from './re-issue-summary/re-issue-premium/re-issue-premium.component';
import {
  ReIssueProposalRegulationComponent
} from './re-issue-policy-data/re-issue-expansion-panels/re-issue-proposal-regulation/re-issue-proposal-regulation.component';

import {ReIssueLpsComponent} from './re-issue-policy-data/re-issue-lps/re-issue-lps.component';
import {InputPremiumComponent} from './re-issue-quotation/re-issue-input-premium/re-issue-input-premium.component';
import {
  ReIssueCoInsuranceModalComponent
} from './re-issue-policy-data/re-issue-co-insurance/re-issue-co-insurance-modal/re-issue-co-insurance-modal.component';
import {
  ReIssueNewQuotaModalComponent
} from './re-issue-policy-data/re-issue-co-insurance/re-issue-co-insurance-modal/re-issue-new-share-modal/re-issue-new-quota-modal.component';
import {
  ReIssueFpsTaxesModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-fps-taxes-modal/re-issue-fps-taxes-modal.component';
import {
  ReIssueTableComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-contribution-taxes-modal/re-issue-table/re-issue-table.component';
import {
  ReIssueCoInsuranceComponent
} from './re-issue-policy-data/re-issue-co-insurance/re-issue-co-insurance.component';
import {
  ReIssueCommissionInputComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-commission-modal/re-issue-commission-input/re-issue-commission-input.component';
import {
  ReIssueAssetActivityComponent
} from './re-issue-asset/re-issue-asset-activities/re-issue-asset-activity/re-issue-asset-activity.component';
import {ReIssueBondItemComponent} from './re-issue-summary/binding/re-issue-bond-item/re-issue-bond-item.component';
import {ReIssueBondListComponent} from './re-issue-summary/binding/re-issue-bond-list/re-issue-bond-list.component';
import { ReIssueNotesComponent } from './re-issue-quotation/re-issue-notes/re-issue-notes.component';
import {
  ReIssueBeneficiaryModalComponent
} from './re-issue-quotation/re-issue-beneficiaries/re-issue-beneficiary-modal/re-issue-beneficiary-modal.component';
import {
  UnitBeneficiariesComponent
} from './re-issue-quotation/re-issue-beneficiaries/re-issue-unit-beneficiaries/re-issue-unit-beneficiaries.component';
import {
  ReIssueGenericAccordionComponent
} from './re-issue-policy-data/re-issue-expansion-panels/re-issue-generic-accordion/re-issue-generic-accordion.component';
import {
  ReIssueGenericAccordionWithModalComponent
} from './re-issue-policy-data/re-issue-expansion-panels/re-issue-generic-accordion-with-modal/re-issue-generic-accordion-with-modal.component';
import { ReIssueAddPolicyModalComponent } from './re-issue-policy-data/re-issue-expansion-panels/re-issue-generic-accordion-with-modal/re-issue-add-policy-modal/re-issue-add-policy-modal.component';
import {ReIssueCurrencyDirective} from '../directives/re-issue-currency.directive';
import {
  ReIssueProposalManagementPaymentsComponent
} from './re-issue-proposal-management/re-issue-proposal-management-payments/re-issue-proposal-management-payments.component';
import {
  ReIssueQuotationClauseModalComponent
} from './re-issue-quotation/re-issue-quotation-modal/re-issue-quotation-clause-modal/re-issue-quotation-clause-modal.component';
import { ReIssueClausesAndFactorsComponent } from './re-issue-quotation/re-issue-clauses-and-factors/re-issue-clauses-and-factors.component';
import {
  MultipleSubstitutionComponent
} from "./re-postsale/re-substitution-start/multiple-substitution/multiple-substitution.component";
import {RgiRxQualityAssuranceModule} from "@rgi/rx";
import {
  AddContractPolicyListComponent
} from "./re-postsale/re-substitution-start/add-contract-policy-list/add-contract-policy-list.component";
import {RgiPncPostsalesModule} from "@rgi/pnc-postsales";
import {
  ReAddContractModalComponent
} from './re-postsale/re-substitution-start/re-add-contract-modal/re-add-contract-modal.component';

@NgModule({
    declarations: [
        ReIssueStartCardComponent,
        ReIssuePolicyDataComponent,
        ReIssueAssetComponent,
        ReIssueQuotationComponent,
        ReIssueTechnicalDetailsComponent,
        ReIssueVariablesComponent,
        ReIssueClausesComponent,
        ReIssueVariableControlComponent,
        ReIssueGeneralDataComponent,
        ReIssueStepperComponent,
        ReIssueAssetModalComponent,
        ReIssueQuotationControlsComponent,
        ParametersComponent,
        BeneficiariesComponent,
        UnitBeneficiariesComponent,
        ThirdPersonComponent,
        ReIssueParameterControlComponent,
        ReIssueQuotationDetailModalComponent,
        ReIssueContactComponent,
        ReIssueAssetUnitComponent,
        ReIssueContributionTaxesModalComponent,
        ReIssueCommissionModalComponent,
        ReIssueWarrantyModalComponent,
        // QuestionnaireComponent,
        ReIssueQuestionnaireModalComponent,
        ReIssueQuestionnairesComponent,
        ReIssueSummaryComponent,
        ReIssueQuotationSaveComponent,
        ReIssueProposalManagementComponent,
        ReIssueProposalManagementPaymentsComponent,
        ReIssueQuotationClauseModalComponent,
        ReIssueEmissionComponent,
        ReIssueSaveQuoteComponent,
        ReIssuePdfQuoteModalComponent,
        ReIssueAddressComponent,
        ReIssueStartCardNodeModalComponent,
        ReIssueEnterPinModalComponent,
        ReIssuePrintPdfComponent,
        ReIssueProposalQuestionairesModalComponent,
        ReIssueFactorsComponent,
        ReIssuePackageComponent,
        ReIssueReprintMovementsComponent,
        ReIssueReprintDocumentsComponent,
        ReIssueCardDocumentComponent,
        ReIssueCardDocumentPreviewComponent,
        ClauseModalComponent,
        ReSubstitutionStartComponent,
        ReCancellationStartComponent,
        ReCancellationSummaryComponent,
        ReCancellationConfirmationComponent,
        ReIssueMsgModalComponent,
        ReIssueDiscountModalComponent,
        ReIssueAssetActivitiesComponent,
        ReIssueAssetActivityComponent,
        ReIssuePremiumComponent,
        ReIssueProposalRegulationComponent,
        ReIssueGenericAccordionComponent,
        ReIssueLpsComponent,
        ReIssueCoInsuranceComponent,
        InputPremiumComponent,
        ReIssueCoInsuranceModalComponent,
        ReIssueNewQuotaModalComponent,
        ReIssueFpsTaxesModalComponent,
        ReIssueTableComponent,
        ReIssueCommissionInputComponent,
        ReIssueBondItemComponent,
        ReIssueBondListComponent,
        ReIssueNotesComponent,
        ReIssueBeneficiaryModalComponent,
        ReIssueGenericAccordionWithModalComponent,
        ReIssueAddPolicyModalComponent,
        ReIssueCurrencyDirective,
        ReIssueClausesAndFactorsComponent,
        MultipleSubstitutionComponent,
        AddContractPolicyListComponent,
        ReAddContractModalComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RgiRxModalModule,
    RgiAnagModule,
    DragDropModule,
    RgiRxFormElementsModule,
    RgiRxDatePickerModule,
    RgiRxDragDropModule,
    RgiRxPanelModule,
    RgiRxTableModule,
    RgiRxExpansionModule,
    RgiRxSnackbarModule,
    RgiRxStepperModule,
    RgiRxTabModule,
    RgiRxI18nModule,
    RgiRxAutoCompleteModule,
    RgiRxSwitchModule,
    RgiRxMenuModule,
    RgiRxQualityAssuranceModule,
    RgiPncPostsalesModule,
  ],
    exports: [
        ReIssueStartCardComponent,
        ReIssuePolicyDataComponent,
        ReIssueAssetComponent,
        ReIssueQuotationComponent,
        ReIssueTechnicalDetailsComponent,
        ReIssueVariablesComponent,
        ReIssueClausesComponent,
        ReIssueVariableControlComponent,
        ReIssueGeneralDataComponent,
        ReIssueStepperComponent,
        ReIssueAssetModalComponent,
        ReIssueQuotationControlsComponent,
        ParametersComponent,
        BeneficiariesComponent,
        UnitBeneficiariesComponent,
        ThirdPersonComponent,
        ReIssueParameterControlComponent,
        ReIssueQuotationDetailModalComponent,
        ReIssueContactComponent,
        ReIssueAssetUnitComponent,
        ReIssueContributionTaxesModalComponent,
        ReIssueCommissionModalComponent,
        ReIssueWarrantyModalComponent,
        ReIssueProposalManagementComponent,
        ReIssueProposalManagementPaymentsComponent,
        ReIssueQuotationClauseModalComponent,
        // QuestionnaireComponent,
        ReIssueQuestionnaireModalComponent,
        ReIssueQuestionnairesComponent,
        ReIssuePdfQuoteModalComponent,
        ReIssuePrintPdfComponent,
        ReIssuePackageComponent,
        ReIssueFactorsComponent,
        ReIssueAddressComponent,
        ReIssueReprintDocumentsComponent,
        ReIssueReprintMovementsComponent,
        ReSubstitutionStartComponent,
        ReCancellationStartComponent,
        ReCancellationSummaryComponent,
        ReCancellationConfirmationComponent,
        ReIssueCoInsuranceComponent,
        ReIssueCoInsuranceModalComponent,
        ReIssueNewQuotaModalComponent,
        ReIssueFpsTaxesModalComponent,
        ReIssueBeneficiaryModalComponent,
        ReIssueAssetActivitiesComponent
    ],
    providers: [
        /*
          PcPortfolio Providers
        */
        {
            provide: ReIssueTechnicalDataResourceService,
            useExisting: ReIssuePortfolioTechnicalDataResourceService
        },
        {
            provide: CommonResourceService,
            useExisting: ReIssuePortfolioCommonResourceService
        },
        {
            provide: ReIssueVariablesResourceService,
            useExisting: ReIssuePortfolioVariablesResourceService
        },
        {
            provide: ReIssueGeneralDataResourceService,
            useExisting: ReIssuePortfolioGeneralDataResourceService
        },
        {
            provide: ReIssueWarningsResourceService,
            useExisting: ReIssuePortfolioWarningsResourceService
        },
        {
            provide: ReIssueAssetsResourceService,
            useExisting: ReIssuePortfolioAssetsResourceService
        },
        CurrencyPipe
    ]
})
export class ReIssueModule {
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { RoutingService, RoutingHostDirective, RoutableComponent } from '@rgi/portal-ng-core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { NewPaymentBoardComponent } from './new-payment-board/new-payment-board.component';
import { BeneficiaryPayeeComponent } from './beneficiary-payee/beneficiary-payee.component';
import { QuoteEvaluationComponent } from './quote-evaluation/quote-evaluation.component';
import { SubjectEntity } from './domain/subject-entity';
import { NewPaymentService } from './new-payment.service';
import { PaymentEntity } from './domain/payment-entity';
import { ExposureEntity } from './domain/exposure-entity';
import { PaymentConstants } from './domain/payment-constants';

@Component({
  selector: 'claims-new-payment',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.scss'],
  providers: [RoutingService]
})
export class NewPaymentComponent extends CardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;
  routes = null;
  finPays = new Array<PaymentEntity>();
  idsettlement: string;
  claim: any;
  party: any;
  fromModify: boolean = false;

  constructor(
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('newPaymentBoardComponent') newPaymentBoardComponent,
    @Inject('newPaymentDetailComponent') newPaymentDetailComponent,
    @Inject('coreResult') private coreResult: any,
    @Inject('eventService') private eventService: any,
    private newPaymentService: NewPaymentService,
    private paymentConstants: PaymentConstants
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      board: newPaymentBoardComponent,
      detail: newPaymentDetailComponent
    };
  }
  ngAfterViewInit(): void {


  }

  ngOnInit() {
    super.ngOnInit();
    this.idsettlement = this.coreResult.getResult(this.id, 'idsettlement');
    this.claim = this.coreResult.getResult(this.id, 'claim');
    this.party = this.coreResult.getResult(this.id, 'party');
    this.fromModify = this.coreResult.getResult(this.card.id, 'fromModify');
    this.newPaymentService.setPaymentDB(null);

    // this.fromModify = true;

    console.log('ACCESSO 1');
    console.log('this.idsettlement ' + this.idsettlement);
    console.log('this.claim ' + this.claim);
    console.log('this.party ' + this.party);
    if (this.idsettlement && !this.fromModify) {
      const consultationData: Map<string, string> = new Map();
      consultationData.set('idSettlement', this.idsettlement);
      consultationData.set('claimNum', this.claim.claimNumber);
      consultationData.set('partyId', this.party.identifier);
      this.newPaymentService.setConsultationData(consultationData);
    }

    if (this.fromModify) {
      const claimCurrent = this.coreResult.getResult(this.id, 'detail');
      const idAct = this.coreResult.getResult(this.id, 'idActAuthFee');
      // const claimCurrent = this.coreResult.getResult(this.card.id, 'detail');
      // const partyCurrent = this.coreResult.getResult(this.card.id, 'party');
      // this.idsettlement = '204342405';
      // paymentDetail?claimNumber=PS210000003988&idActor=204342260&idPayment=204342405
      // this.newPaymentService.getPaymentDetail('PS210000003988',
      // '204342260', '204342405')
      this.newPaymentService.getPaymentDetail(claimCurrent.claimNumber,
        this.party.identifier, this.idsettlement).subscribe(
          (payment: PaymentEntity) => {
          console.log('getPaymentDetail for modify-->', payment);
          if (payment && payment.exposureList) {
            this.updateGeneratePayment(payment.exposureList);
          }
          this.eventService.broadcastEvent('stop-loader');
          payment.idActRef = idAct;
          this.newPaymentService.setPaymentDB(payment);
          this.routingService.routes = this.routes;
          this.routingService.host = this.hostViewRef.viewContainerRef;
          this.routingService.loadComponent(this.routes.board);
          (this.routingService.componentRef.instance as NewPaymentBoardComponent).card = this.card;
          (this.routingService.componentRef.instance as QuoteEvaluationComponent).card = this.card;

        },
        (error: any) => {
        }
      );
    } else {

      this.routingService.routes = this.routes;
      this.routingService.host = this.hostViewRef.viewContainerRef;
      if (this.idsettlement && this.party) {
        this.routingService.loadComponent(this.routes.detail);
      } else {
        this.routingService.loadComponent(this.routes.board);
      }
      (this.routingService.componentRef.instance as NewPaymentBoardComponent).card = this.card;
      (this.routingService.componentRef.instance as QuoteEvaluationComponent).card = this.card;
    }
  }

  updateGeneratePayment(list: Array<ExposureEntity>) {
    for (const el of  list) {
      if (el.feeData) {
        el.feeData.generatePayment = true;
      }
      if (el.subExposureList && el.subExposureList.length > 0) {
        this.updateGeneratePayment(el.subExposureList);
      }
    }
  }


  ngOnDestroy() {
    this.newPaymentService.setPotPayCache(null);
    this.eventService.broadcastEvent('reloadClaim');
  }

  @Input() set beneficiary(beneficiary: any) {
    if (beneficiary && this.routingService.componentRef) {
      const index = this.newPaymentService.paymentIdForAddBenef;
      const benefComponent = this.routingService.componentRef.instance as BeneficiaryPayeeComponent;
      const subj = (beneficiary.data) ? beneficiary.data : beneficiary;
      if (this.newPaymentService.subjectRole === 'beneficiary') {
        benefComponent.potPayFE.paymentList[index].listBeneficiary.push(this.dataToSubjectEntity(subj));
      } else if (this.newPaymentService.subjectRole === 'addressee') {
        const tempAnag: SubjectEntity = this.dataToSubjectEntity(subj);
        // idSubject deve contenere l'idDatiSoggetto se vuoto il BE crea il soggetto nuovo
        // tempAnag.idSubject = tempAnag.idSubjectPA;
        benefComponent.potPayFE.subjectAnag.get(benefComponent.potPayFE.paymentList[index].id).receiver = tempAnag;
        // benefComponent.potPayFE.subjectAnag = this.dataToSubjectEntity(subj);
      } else {
        benefComponent.potPayFE.paymentList[index].listPayee.push(this.dataToSubjectEntity(subj));
      }
    }
  }

  dataToSubjectEntity(obj: any) {
    const subject: SubjectEntity = new SubjectEntity();

    let key = 'dateOfBirth';
    subject.birthDate = new Date(obj[key]);
    key = 'name';
    if (obj[key]) {
      subject.name = obj[key];
    }
    key = 'denomination';
    if (obj[key] && !subject.name) {
      subject.name = obj[key];
    }
    key = 'nominative';
    if (obj[key] && !subject.name) {
      subject.name = obj[key];
    }
    key = 'surname';
    subject.lastName = obj[key];
    key = 'fiscalCode';
    subject.fiscalCode = obj[key];
    key = 'vat';
    subject.vat = obj[key];

    subject.address = (obj.residence.toponym ? (obj.residence.toponym.descrizione + ' ') : '') +
      obj.residence.placeAddress;
    subject.municipality = obj.residence.adminLevel3;
    subject.country = obj.countryOfBirth ? obj.countryOfBirth.codice : '';
    subject.number = obj.residence.number;
    subject.postalCode = obj.residence.cap;
    subject.state = obj.residence.adminLevel2Short;
    subject.idSubjectPA = obj.objectId; // id soggetto della PASOGGETTO
    subject.primary = false;
    if (obj.counterTerrorismStatus != null) {
      subject.statusAntiFinancialCrimes = obj.counterTerrorismStatus.codice;
    }
    return subject;
  }
}

import { Injectable } from '@angular/core';
import enigma from 'enigma.js';
import schema from 'enigma.js/schemas/12.2015.0.json';
import { BehaviorSubject } from 'rxjs';
import { Configuration } from '../model/configuration';
import {LoggerService} from './logger.service';

@Injectable({
  providedIn: 'root',
})

/*
This service manage the enigma.js sockets
*/
export class EnigmaService {

  sessions = {};
  isSessionInTimeout$ = new BehaviorSubject<boolean>(false);
  configuration: Configuration;

  constructor(private logger: LoggerService) { }

  getIsSessionInTimeout$(): BehaviorSubject<boolean> {
    return this.isSessionInTimeout$;
  }

  setIsSessionTimeout$(timeout: boolean) {
    this.isSessionInTimeout$.next(timeout);
  }

  setConfiguration(configuration: Configuration) {
    this.configuration = configuration;
  }

  // Create enigma sessions
  async getSession(url: string) {
    let session = this.sessions[url];
    if (!session) {
      session = enigma.create({schema, url});
      session.on('closed', () => delete this.sessions[url]);
      session.on('traffic:received', async (data) => {
        // Handle the session timeout
        if (data.method === 'OnSessionTimedOut') {
          this.setIsSessionTimeout$(true);
        }
      });
      this.sessions[url] = session;
    }
    return await session.open();
  }

}

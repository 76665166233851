<div>

  <form [formGroup]="newBondForm">
    <div rgi-rx-accordion [multi]="true" class="unit-unselected"> <!-- second level -->
      <rgi-rx-expansion-panel data-qa="lien_item" [expanded]="true">
        <!-- TODO : ng-class change about unit selection-->
        <rgi-rx-expansion-panel-header class="unit-description" style="background-color:white">
          <div rgi-rx-expansion-panel-label>
            <span class="rgi-ui-title-2" translate>{{ editMode ? 'RE_ISSUE.EDIT_LIEN' : 'RE_ISSUE.NEW_LIEN'}}</span>
          </div>
        </rgi-rx-expansion-panel-header>
        <!-- UNIT CONTAINER -->
        <div class="rgi-ui-grid-2-xs-1">
          <div class="rgi-ui-col-6-xs-12">
            <rgi-rx-drop-container formControlName="bodyBinding" label="{{ 'RE_ISSUE.BODY_BINDING' | translate }}"
              viewField="nominative" data-qa="body-binding" [select]="'searchOrCreateSubject'"
              [selectData]="getSubjectDropContainerData(roleKeys.rolePolicyHolder)"
              (onValueChange)="anagSubjectSelected($event)"
              (onRemove)="removeSubjectFormData()"></rgi-rx-drop-container>
            <label for="bodyBinding" *ngIf="newBondForm.get('bodyBinding').errors?.required">
              <span translate style="color: red;">RE_ISSUE.BODY_Binding_Mandatory</span>
            </label>
          </div>
          <div class="rgi-ui-col-6-xs-12">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>RE_ISSUE.DESCRIPTION</label>
              <input rgiRxInput formControlName="description" data-qa="description">
            </rgi-rx-form-field>
          </div>
        </div>


        <!-- UNIT CONTAINER -->
        <div class="rgi-ui-grid-2-xs-1">
          <div class="rgi-ui-col-6-xs-12">
            <rgi-rx-form-field class="date-field">
              <div class="label-input-container">
                <label rgiRxLabel><span translate>RE_ISSUE.EFFECT_DATE</span></label>
                <div class="input-button-container">
                  <input [rgiRxDateTimeInput]="effectDatePicker" style="flex: 1" data-qa="effectDate"
                         selectMode="single" rangeSeparator="-" placeholder="gg-mm-yyyy"
                         #inputToTriggerEffectDate="rgiRxDateTimeInput" formControlName="effectDate">
                  <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                          [rgiRxDateTimeTrigger]="inputToTriggerEffectDate"></button>
                </div>
              </div>
              <rgi-rx-date-time #effectDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col-6-xs-12">
            <rgi-rx-form-field class="date-field">
              <div class="label-input-container">
                <label rgiRxLabel><span translate>RE_ISSUE.EXPIRY_DATE</span></label>
                <div class="input-button-container">
                  <input [rgiRxDateTimeInput]="expiryDatePicker" style="flex: 1" data-qa="expiryDate" selectMode="single"
                         rangeSeparator="-" placeholder="gg-mm-yyyy" #inputToTriggerExpiryDate="rgiRxDateTimeInput"
                         formControlName="expiryDate">
                  <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                          [rgiRxDateTimeTrigger]="inputToTriggerExpiryDate"></button>
                </div>
              </div>
              <rgi-rx-date-time #expiryDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
        </div>

        <rgi-rx-snackbar tag="{{TAG_DATES_ERROR}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_TYPE_LIEN_ERROR}}"></rgi-rx-snackbar>

        <div class="rgi-ui-grid-2 rgi-ui-grid-no-gutter">
          <!-- BACK -->
          <div class="rgi-ui-col btn-group">
            <div class="story-grid-column-content">
              <button rgi-rx-button color="secondary" class="width100 text-uppercase" data-qa="cancel_lien"
                      (click)="cancelNewBinding()"><span
                translate>RE_ISSUE.CANCEL</span>
              </button>
            </div>
          </div>
          <div class="rgi-ui-col btn-group">
            <div class="story-grid-column-content">
              <button rgi-rx-button class="width100 text-uppercase" color="primary"
                            data-qa="save_lien"
                            [disabled]="newBondForm.invalid"
                            (click)="saveNewBinding()"><span translate>{{ editMode ? 'RE_ISSUE.SAVE_CHANGES' : 'RE_ISSUE.SAVE'}}</span></button>
            </div>
          </div>

        </div>


      </rgi-rx-expansion-panel>
    </div>
  </form>
</div>

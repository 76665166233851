import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { TotalSummaryResponse } from '../models/total-summary.model';
import { RgiRxHttpClient, RgiRxHttpClientFactory } from '@rgi/rx/http';
import { RestApiConf } from '@rgi/anag/lib/anag-constants/rest-api-conf';
import { AnagApiParty } from '@rgi/anag';
import { TableSchema } from '@rgi/rx/ui';
import { TOTAL_SUMMARY_TABLE_SCHEMA } from '../constants/total-summary-tableschema';

@Injectable({
  providedIn: 'root'
})
export class TotalSummaryService {

  protected httpClient: RgiRxHttpClient;
  protected hostPath: string;

  constructor(
    protected httpClientFactory: RgiRxHttpClientFactory,
    @Inject('environment') protected apiConf: RestApiConf) {
    this.httpClient = this.httpClientFactory.buildWrapper();
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path;
  }

  getData(data: AnagApiParty): Observable<TotalSummaryResponse> {
    return this.httpClient.get<TotalSummaryResponse>(`${this.hostPath}/gel/party-summary/${data.objectId}/${data.node?.identification}`);
  }

  getTotalSummarySchema(): TableSchema {
    return TOTAL_SUMMARY_TABLE_SCHEMA;
  }

  getFormatterOptionsWithDecimal(style: 'decimal' | 'currency' | 'percent' | 'unit', decimal?: string, currencyCode?: string): Intl.NumberFormatOptions {
    const options: Intl.NumberFormatOptions = { style, currency: currencyCode };
    if (decimal) {
      const dec = decimal.split('.');
      if (Number(dec[0])) { options.minimumIntegerDigits = Number(dec[0]); }
      if (Number(dec[1].split('-')[0])) { options.minimumFractionDigits = Number(dec[1].split('-')[0]); }
      if (Number(dec[1].split('-')[1])) { options.maximumFractionDigits = Number(dec[1].split('-')[1]); }
    }
    return options;
  }
}

import { Injectable } from "@angular/core";
import { TableSchema, TableSchemaFormatSingle, TableTextRowSchema } from "@rgi/rx/ui";
import { cloneDeep } from 'lodash';
import { RgiRxTranslationService } from "@rgi/rx/i18n";

@Injectable({
    providedIn: 'root'
})
export class AuthUtilityService {
    constructor(
        protected translate: RgiRxTranslationService
    ) {}

    createSchema(tableSchema: TableSchema): TableSchema {
        let mySchema = cloneDeep(tableSchema);
        mySchema.rows.map((elem: TableTextRowSchema) => {
            if (elem.format) {
                if (!Array.isArray(elem.format)) {
                    const format = elem.format as TableSchemaFormatSingle;
                    if (format.pipe === 'date') {
                        format.args = ['shortDate', undefined, this.translate.currentLanguage];
                    }
                }
                
            }
        });

        return mySchema;
    }
    
}

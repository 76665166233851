import { GenericEntity } from '../generic-entity';
import { ConfMetadata } from './conf-metadata';


export class Metadata {

  public id: string;
  public code: string;
  public description: string;

  public value: string;
  public valueDescription: string;
  public listValue: Array<string>;
  public visible: boolean;
  public usedForValidation: boolean;
  public mandatory: boolean;
  public multiple: GenericEntity;
  public complianceStatus: GenericEntity;
  public type: GenericEntity;
  public usedAsIndex: boolean;
  public modifiable: boolean;
  public systemMetadato: boolean;
  public conf: ConfMetadata;

  constructor() { }

}

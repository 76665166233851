export class PotentialClaimData {
    constructor(
        public uuid: string,
        public contractNumber: string,
        public categoryCode: string,
        public occurrenceDate: string,
        public occurrencetime: string,
        public technicalcoverture: string,
        public administrativecoverture: string,
        public effectdate: string,
        public expirationdate: string
    ) { }
}


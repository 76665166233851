<div class="stepper" data-qa="progress">

    <div class="stepper-item" [ngClass]="{complete: currentStep > 0}" att.data-qa="claim-data-label">
        <span class="number">1</span><span class="description">{{ '_CLAIMS_._PAYMENT_DATA' | translate }}</span>
    </div>

    <div class="stepper-item" [ngClass]="{complete: currentStep > 1}" att.data-qa="claim-data-label">
        <span class="number">2</span><span class="description">{{ '_CLAIMS_._CALCULATION_OF_THE_INSURED_FEE' | translate }}</span>
    </div>

    <div class="stepper-item" [ngClass]="{complete: currentStep > 2}" att.data-qa="asset-data-label">
        <span class="number">3</span><span class="description">{{ '_CLAIMS_._BENEFICIARY' | translate }}</span>
    </div>

    <div class="stepper-item" [ngClass]="{complete: currentStep > 3}" att.data-qa="select-type-of-loss-label">
        <span class="number">4</span><span class="description">{{ '_CLAIMS_._METHOD_OF_PAYMENT' | translate }}</span>
    </div>

    <div class="stepper-item" [ngClass]="{complete: currentStep > 4}" att.data-qa="documents-label">
        <span class="number">5</span><span class="description">{{ '_CLAIMS_._SENDING_LETTERS' | translate }} </span>
    </div>
</div>
// tslint:disable:max-line-length
import {UntypedFormGroup} from '@angular/forms';
import {ActiveRoute} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {ModalService} from '@rgi/rx/ui';
import {Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {
  GroupPolicyModalCommissionsLifeComponent
} from '../../group-policy-components/group-policy-modals/group-policy-modal-commissions-life-component/group-policy-modal-commissions-life.component';
import {productCategories} from '../../group-policy-models/group-policy-application';
import {
  Commission,
  GPAsset,
  GpAssetUnitsClauses,
  GpUnitClause,
  Surveys
} from '../../group-policy-models/group-policy-issue-guarantees';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {ErrorCode, GPClause} from '../../group-policy-models/group-policy-issue-policy-data';
import {GroupPolicyStateCluster, GroupPolicyStateGuarantees} from '../group-policy-state';
import {GPEditClusterSubStateManager} from '../group-policy-sub-statemanagers/group-policy-cluster-sub-statemanager';
import {
  GroupPolicyStatelessOpClusterService
} from '../group-policy-sub-statemanagers/group-policy-stateless-op-cluster.service';
import {GroupPolicyStatelessOpGuaranteesService} from './group-policy-stateless-op-guarantees.service';
import { CommissionForm, GroupPolicyModalCommissionsComponent } from '../../group-policy-components/group-policy-modals/group-policy-modal-commissions-component/group-policy-modal-commissions.component';

export class GroupPolicyStatemanagerGuarantees extends AbstractStateManager<GroupPolicyStateGuarantees> implements GPEditClusterSubStateManager<GroupPolicyStateGuarantees> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected guaranteesStatelessOps: GroupPolicyStatelessOpGuaranteesService,
    protected clusterStatelesOps: GroupPolicyStatelessOpClusterService,
    protected modalService: ModalService) {
    super(activeRoute, stateStoreService);
    const stateGuaranteesData = this.stateStoreService.has(this.activeRoute.key) ?
      this.stateStoreService.get<GroupPolicyStateGuarantees>(this.activeRoute.key) :
      new GroupPolicyStateGuarantees(this.activeRoute.key);
    const initial$ = this.guaranteesStatelessOps.initGuaranteesData$(of(stateGuaranteesData), this.activeRoute);
    this.updateState$(initial$);
  }

  public actionUpdateState(state?: GroupPolicyStateGuarantees) {
    const st = state ? state : this.getCurrentState();
    this.updateState$(of(st));
  }

  public actionUpdateUnits(data: DataForSteps, units: GPAsset[]) {
    const st = this.getCurrentState();
    st.proposalNumber = null;
    this.updateState$(this.guaranteesStatelessOps.updateAssets$(of(st), data.resId, units));
  }

  public actionBack(previousStepData: DataForSteps, activeRoute: ActiveRoute, targetRoute: string) {
    const st = this.getCurrentState();
    st.proposalNumber = null;
    this.updateState$(this.guaranteesStatelessOps.actionBack$(of(st), previousStepData, activeRoute, targetRoute));
  }

  public actionSave(previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    st.errors = [];
    st.type = ErrorCode.BLOCKING;
    this.updateState$(this.guaranteesStatelessOps.actionSave$(of(st), previousStepData.resId, previousStepData.node, this.activeRoute));
  }

  public actionGoToSummary(previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    st.proposalNumber = null;
    this.updateState$(this.guaranteesStatelessOps.actionGoToSummary$(of(st), previousStepData, this.activeRoute));
  }

  public actionRetrieveCommissions(data: DataForSteps) {
    const st = this.getCurrentState();
    st.proposalNumber = null;
    this.updateState$(this.guaranteesStatelessOps.retrieveCommissions$(of(st), data.resId));
  }

  public actionUpdateCommissions(data: DataForSteps, commissions: Commission[]) {
    const st = this.getCurrentState();
    st.proposalNumber = null;
    this.updateState$(this.guaranteesStatelessOps.updateCommissions$(of(st), data.resId, commissions));
  }

  public actionUpdateQuestionnaires(data: DataForSteps, questionnaire: Surveys[]) {
    const st = this.getCurrentState();
    st.proposalNumber = null;
    this.updateState$(this.guaranteesStatelessOps.updateQuestionnaires$(of(st), data.resId, questionnaire));
  }

  actionGetAssets(data: DataForSteps) {
    this.updateState$(this.guaranteesStatelessOps.getAssets$(of(this.getCurrentState()), data.resId));
  }

  actionAddAssets(assetCodes: string[], data: DataForSteps) {
    this.updateState$(this.guaranteesStatelessOps.addAssets$(of(this.getCurrentState()), assetCodes, data.resId));
  }

  actionUpdateAssets(gpAssets: GPAsset[], data: DataForSteps) {
    this.updateState$(this.guaranteesStatelessOps.updateAssets$(of(this.getCurrentState()), data.resId, gpAssets));
  }

  actionDeleteAsset(assetCode: string, data: DataForSteps) {
    this.updateState$(this.guaranteesStatelessOps.deleteAsset$(of(this.getCurrentState()), assetCode, data.resId));
  }

  actionUpdateAssetsClauses(assetUnitsClauses: GpAssetUnitsClauses, data: DataForSteps) {
    this.updateState$(this.guaranteesStatelessOps.updateAssetClauses$(of(this.getCurrentState()), assetUnitsClauses, data));
  }

  actionCreateCluster$(stateCluster: GroupPolicyStateCluster): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.createCluster$(of(stateCluster));
  }

  actionUpdateCluster$(stateCluster: GroupPolicyStateCluster, clusterFormGroup: UntypedFormGroup, checkFormValidity: boolean = true): Observable<GroupPolicyStateCluster> {
    return !checkFormValidity || clusterFormGroup.valid ? this.clusterStatelesOps.updateCluster$(of(stateCluster)) : of(stateCluster);
  }

  actionCheckCluster$(stateCluster: GroupPolicyStateCluster): Observable<any> {
    return this.clusterStatelesOps.checkCluster$(of(stateCluster));
  }

  actionDeleteCluster$(stateCluster: GroupPolicyStateCluster, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.deleteCluster$(of(stateCluster), clusterCode);
  }

  actionEditCluster$(stateCluster: GroupPolicyStateCluster, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.editCluster$(of(stateCluster), clusterCode);
  }

  actionCopyCluster$(stateCluster: GroupPolicyStateCluster, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.copyCluster$(of(stateCluster), clusterCode);
  }

  getClasses$(stateCluster: GroupPolicyStateCluster): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.getClasses$(of(stateCluster));
  }

  getExtensions$(stateCluster: GroupPolicyStateCluster): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.getExtensions$(of(stateCluster));
  }

  getUses$(stateCluster: GroupPolicyStateCluster): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.getUses$(of(stateCluster));
  }

  getSectors$(stateCluster: GroupPolicyStateCluster): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.getSectors$(of(stateCluster));
  }

  getClusterDetail$(stateCluster: GroupPolicyStateCluster, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.getClusterDetail$(of(stateCluster), clusterCode);
  }

  initStateCluster(codeAsset: string, routeData: DataForSteps): GroupPolicyStateCluster {
    const st = this.getCurrentState();
    const clusters = st.assets.find(asset => asset.codeAsset === codeAsset).clusters;
    return new GroupPolicyStateCluster(this.activeRoute.key, routeData.resId, routeData.node, routeData.idParentSession, codeAsset, clusters);
  }

  actionUpdateAssetClauses$(stateCluster: GroupPolicyStateCluster, clauses: GPClause[]): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.updateAssetClauses$(of(stateCluster), clauses);
  }

  actionUpdateUnitClauses$(stateCluster: GroupPolicyStateCluster, unitClauses: GpUnitClause): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.updateUnitClauses(of(stateCluster), unitClauses);
  }

  actionOpenCommissionsModal(data: DataForSteps) {
    const state = this.getCurrentState();
    data.state = state;
    let modalCmp;
    let commissionsModal;
    switch (state.productCategory.code) {
      case productCategories.LIFE:
        modalCmp = GroupPolicyModalCommissionsLifeComponent;
        commissionsModal = this.modalService.open(modalCmp, data);
        break;
      default:
        modalCmp = GroupPolicyModalCommissionsComponent;
        const commissionsForm: CommissionForm = new CommissionForm();
        commissionsForm.commissions = state.commissions;
        commissionsModal = this.modalService.open(modalCmp, commissionsForm);
      commissionsModal.enableClickBackground = false;
      commissionsModal.onClose.subscribe((onCloseData) => {
        state.errorsCommissions = [];
        state.typeCommissions = ErrorCode.BLOCKING;
        if (onCloseData) {
          this.actionUpdateCommissions(data, onCloseData);
        }
      });
    }
  }

  actionOpenClusterCommissionsModal(clusterState: GroupPolicyStateCluster) {
    const commissionsForm: CommissionForm = this.getCommissionFormData(clusterState.editableCluster);
    const commissionsModal = this.modalService.open(
      GroupPolicyModalCommissionsComponent,
      commissionsForm
    );
    commissionsModal.enableClickBackground = false;

    return commissionsModal.onClose.pipe(
      mergeMap((onCloseData) => {
        if (onCloseData) {
          return this.clusterStatelesOps.updateClusterCommissions$(of(clusterState), onCloseData);
        }
        return of(clusterState);
      }),
      map(st => st)
    );
  }

  actionOpenDiscountsModal(_previousStepData: DataForSteps) {
    return;
  }
  actionModifyOwner$(stateCluster: GroupPolicyStateCluster, idParty: string, activeRoute: ActiveRoute): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.actionModifyOwner$(of(stateCluster), idParty, activeRoute);
  }

  getCommissionFormData(state: any) {
    return {
      commissions: state.commissions,
      title: '_GP_._BTN_._COMMISSIONS_'
    };
  }
}

<div class="document-category-header">
    <span class="rgifont" [class.rgi-plus]="!panelOpened" [class.rgi-minus]="panelOpened"></span>

    <span class="category-symbol"
        [ngClass]="{'hidden-counter': !documentCategory.documents || !documentCategory.documents.length}">
        <span class="rgifont rgi-folder-symbol"></span>
        <span class="documents-counter"
            *ngIf="documentCategory.documents && documentCategory.documents.length">{{documentCategory.documents.length}}</span>
        <span class="documents-counter"
            *ngIf="!documentCategory.documents || !documentCategory.documents.length"></span>
    </span>

    <div class="document-category-name">
        <span data-qa="panel-button" class="rgifont"></span>
        <span class="panel-name" *ngIf="title && !documentCategory">{{ title | uppercase | translate }}</span>
        <span class="panel-name" *ngIf="documentCategory">{{ documentCategory.categoryName | uppercase | translate }}</span>
    </div>

    <span attr.data-qa="panel-complete-{{panelId}}" *ngIf="isObbligatory()" class="icon rgifont obbligatory-icon"
        [ngClass]="{'rgi-survey_ok green-color': contentComplete, 'rgi-survey_ko red-color': !contentComplete}"></span>

    <span *ngIf="!hideUpload" class="pull-right rgifont rgi-upload upload-icon" (click)="upload($event)"></span>
</div>

import {State} from '@rgi/rx/state';
import {Variable} from '../../models/domain-models/variable';
import {Warning} from '../../models/domain-models/warning';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {
  QuestionFlateResponse
} from '../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {Clause} from '../../models/domain-models/clause';
import {PcProposal} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import { ContractorDataResponse, GenericEntity } from '../../../public-api';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {
  RegulationType
} from '../../models/pc-portfolio-models/regulation-models/pc-regulation-premium';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {CoInsuranceDTO} from '../../models/domain-models/co-insurance';
import {PolicyGenericDto} from '../../models/pc-portfolio-models/policy-generic-dto';

export interface ReIssuePolicyDataNavigationData {
  contractorSubject: AnagIssueSubject;
  previousContractor: AnagIssueSubject;
  isToDisableFraz: any;
  resourceId: string;
  productName: string;
  node: any;
  productCode: string;
  policyTechnicalData: PolicyTechnicalData;
  companyId: string;
  editMode: boolean;
  editFunctionality: boolean;
  isSubstitution: boolean;
  backFromStep: boolean;
  authorize: boolean;
  productId: number;
  thirdPersonContact: any;
}

export class ReIssuePolicyDataState extends State {
  public variables: Array<Variable>;
  public clauses: Array<Clause>;
  policyTechnicalData: PolicyTechnicalData;
  resourceId: string;
  contractorSubject: AnagIssueSubject;
  previousContractor: AnagIssueSubject;
  coOwners: Array<AnagIssueSubject> = [];
  productName: string;
  productCode: string;
  nodeName: string;
  counter = 0;
  warnings: Warning[] | null;
  nodeId: string;
  node: any;
  companyId: string;
  editMode: boolean;
  isSubjectModified: boolean;
  editFunctionality: boolean;
  backFromStep: boolean;
  isSubstitution: boolean;
  isToDisableFraz: boolean;
  authorize: boolean;
  bIntermediateSaving: boolean;
  regulationTypes: RegulationType[];
  questionnaireList: Array<QuestionFlateResponse> = new Array<QuestionFlateResponse>();
  questionnairesWarnings: Warning[] | null;
  questionnairesComplete: boolean;
  questionnairesAdequacy: boolean;
  proposal: PcProposal;
  productId: number;
  lpsData: LpsData;
  coInsurance: CoInsuranceDTO = new CoInsuranceDTO();
  coOwnerEnabled: boolean;
  thirdPersonContact: any;
  accordionErrorMessage: string = null;
  contractorData : ContractorDataResponse;
  isProposalRegulationChecked = false;

  constructor(id: string) {
    super(id);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnigmaService} from '../services/enigma.service';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable, of, Subscription} from 'rxjs';
import {ConfigurationService} from '../services/configuration.service';
import {embed} from '@nebula.js/stardust';
import {CookieAnalyticsService} from '../services/cookie.service';
import {catchError, take, tap} from 'rxjs/operators';
import {Configuration} from '../model/configuration';
import {Report} from '../model/report';
import {ReportService} from '../services/report.service';
import {ReportDetail} from '../model/report-detail';
import {AdminFunctionsService} from '../services/admin-functions.service';
import {
  CardAnalyticsStateDashboard,
  CardAnalyticsStateManagerDashboard
} from '../card-analytics-state/card-analytics-state-manager-dashboard';
import {StateManagedComponent} from '@rgi/rx/state';
import {elementTypes} from '../constants/elementsType';
import {NEBULA_CHARTS, NEBULA_CONF} from '../constants/nebulaConf';
import {ModalComponent, ModalService} from '@rgi/rx/ui';
import {DIMENSION_LIST} from '../constants/qlikApiParams';

import {GridsterConfig, GridsterItem, GridType} from 'angular-gridster2';
import {options} from '../constants/gridOptions';
import {DeleteReportModalComponent} from '../modals/delete-report-modal/delete-report-modal.component';
import {DeleteReportModalConfirmComponent} from '../modals/delete-report-modal-confirm/delete-report-modal-confirm.component';
import {CopyReportModalComponent} from '../modals/copy-report-modal/copy-report-modal.component';
import {CopyReportModalConfirmComponent} from '../modals/copy-report-modal-confirm/copy-report-modal-confirm.component';
import {UpdateReportModalComponent} from '../modals/update-report-modal/update-report-modal.component';
import {ReportElement} from '../model/report-element';
import {LoggerService} from '../services/logger.service';
import LoadingSubject from '../model/loading-subject';
import {AnalyticsSharedService} from '../shared/analytics-shared.service';


@Component({
  selector: 'lib-card-analytics-dashboard',
  templateUrl: './card-analytics-dashboard.component.html',
  host: {
    class: 'rgi-analytics'
  }
})

/*
* Dashboard component. Here you can see a report detail
*/
export class CardAnalyticsDashboardComponent
  extends StateManagedComponent<CardAnalyticsStateDashboard, CardAnalyticsStateManagerDashboard> implements OnInit, OnDestroy {

  NEBULA_CONF_TEST: any;
  actualTheme: any;
  allFilters: any = [];
  areFiltersVisible = true;
  charts: ReportElement[];
  configuration: Configuration;
  currentSession: any;
  dashboard: Array<GridsterItem> = [];
  filters = [];
  isAdminFunctionEnabled = false;
  loaderCharts = [];
  loadingSubjects: LoadingSubject[] = [];
  modal: ModalComponent;
  modifyReportDisabled = true;
  nebula: any;
  nebulaChart = [];
  nebulaConf: typeof embed;
  options: GridsterConfig;
  sessionTimeoutSubscription: Subscription = Subscription.EMPTY;
  report: Report;
  reportDetail: ReportDetail;
  reportFilterPipe = {typeCode: elementTypes.FILTER};
  rendererCharts: boolean;
  showCharts = false;



  constructor(public activeRoute: ActiveRoute,
              private adminFunctionsService: AdminFunctionsService,
              private configurationService: ConfigurationService,
              private cookieAnalyticsService: CookieAnalyticsService,
              private enigmaService: EnigmaService,
              private logger: LoggerService,
              private manager: CardAnalyticsStateManagerDashboard,
              private modalService: ModalService,
              private reportService: ReportService,
              private routingService: RoutingService,
              private sharedService: AnalyticsSharedService) {
    super(manager);
  }

  static itemChange(item, itemComponent) {
  }

  static itemResize(item, itemComponent) {
  }

  async ngOnInit() {
    this.rendererCharts = false;
    this.report = this.activeRoute.getRouteData();

    // reset themes
    // NEBULA_CONF.themes = [];

    // CHECK IF LOGGED AS ADMIN
    this.isAdminFunctionEnabled = await this.adminFunctionsService.getAdminEnabled().pipe(take(1)).toPromise();

    this.sessionTimeoutSubscription = this.enigmaService.getIsSessionInTimeout$().subscribe(
      res => {
        if (res) {
          this.enigmaService.setIsSessionTimeout$(false);
          this.manager.removeSession(this.activeRoute);
        }
      }
    );


    try {
      this.reportDetail = await this.reportService.getReport(this.report.id).pipe(take(1)).toPromise();

      this.setGridOptions();

      this.setEnableSelections(this.reportDetail.enableSelections);
      this.setEnableInteractions(this.reportDetail.enableInteractions);

      this.setMaxRows(this.reportDetail.maxRows);
      this.setMaxCols(this.reportDetail.maxCols);

      this.charts = this.reportDetail.elements.filter(chart => chart.typeCode === elementTypes.CHART);
      this.dashboard = this.charts.map(chart => ({
        x: chart.x,
        y: chart.y,
        cols: chart.cols,
        rows: chart.rows,
        id: chart.idQlikElement
      }));

      this.initializeLoaders(this.reportDetail.elements);

      this.showCharts = true;

      this.configuration = await this.configurationService.getConfiguration().pipe(take(1)).toPromise();
      await this.cookieAnalyticsService.setCookieAnalytics(this.configuration).toPromise();
      await this.initApp();

      const sheet = await this.currentSession.openDoc(this.report.idQlikApp);

      if (this.report.qlikTheme) {
        this.cookieAnalyticsService.getTheme(this.configuration, this.report.qlikTheme).pipe(
          tap(theme => {
            this.actualTheme = theme;
            this.NEBULA_CONF_TEST = {
              context: {
                theme: this.report.qlikTheme + '1',
                language: 'it-IT',
                constraints: {
                  active: false,
                  passive: false,
                  select: false,
                },
              },
              themes: [{id: this.report.qlikTheme + '1', load: () => theme}],
              types: NEBULA_CHARTS
            };
          }),
          catchError(err => {
            throw new Error('error in source. Details: ' + err);
          })
        ).subscribe(async () => {
          this.nebulaConf = embed.createConfiguration(this.NEBULA_CONF_TEST);
          await this.nebulaRenderCharts(sheet);
        });
      } else {
        this.nebulaConf = embed.createConfiguration(NEBULA_CONF);
        await this.nebulaRenderCharts(sheet);
      }
    } catch (error) {
      this.logger.error(error);
      this.manager.actionOpen();
    }
  }

  async nebulaRenderCharts(sheet: EngineAPI.IApp) {
    this.nebula = this.nebulaConf(sheet);
    await this.getDimensions(sheet);
    try {
      this.logger.log('elementi: ', this.reportDetail.elements);
      for (const element of this.reportDetail.elements) {
        if (element.idQlikElement) {
          await this.printCharts(element);
          this.completeLoading(element.idQlikElement);
        }
      }
      this.rendererCharts = true;
      this.modifyReportDisabled = false;
      const selections = await this.nebula.selections();

      const toolbar = document.querySelector('.toolbar');
      if (toolbar) {
        selections.unmount(toolbar);
        selections.mount(toolbar);
      }
    } catch (error) {
      this.logger.error(error);
      this.manager.actionOpen();
    }
  }

  async getDimensions(sheet) {
    const list = await sheet.createSessionObject(DIMENSION_LIST);
    const layout = await list.getLayout();
    const dimensionItems = layout.qDimensionList.qItems;
    this.allFilters.push(...dimensionItems);
  }

  async initApp() {
    const url = this.configuration.protocolSocket + '://' + this.configuration.host + '/' + this.configuration.proxy + '/app/'
      + this.report.idQlikApp;
    this.enigmaService.setConfiguration(this.configuration);
    this.currentSession = await this.enigmaService.getSession(url);
  }

  async printCharts(element: ReportElement) {
    this.logger.log('type: ', element.typeCode);
    if (element.typeCode === elementTypes.CHART) {
      const n = await this.sharedService.renderCharts(element.idQlikElement, this.nebula, '.chart' + element.idQlikElement);
      this.nebulaChart = [...this.nebulaChart, n];
      this.loaderCharts.push(element.idQlikElement);
    } else if (element.typeCode === elementTypes.FILTER) {
      await this.sharedService.renderFilters(this.allFilters, element, this.nebula);
    }
  }

  async closeSession() {
    this.manager.actionOpen();
  }

  openQlikSense(): void {
    const qlikUrl = this.configuration.protocolHttp + '://' + this.configuration.host + '/' +
      this.configuration.proxy + '/sense/app/' + this.reportDetail.idQlikApp;
    this.sharedService.openQlikSense(qlikUrl);
  }

  editReport() {
    this.stateManager.editReport(this.report, this.activeRoute);
  }

  async ngOnDestroy() {
    this.logger.log('DASHBOARD DESTROYED');
    await this.sharedService.unrenderCharts(this.nebulaChart, this.nebula);
  }

  saveReport() {
    // update the grid position and size
    this.reportDetail.elements.filter(el =>
      el.typeCode === elementTypes.CHART
    ).forEach(el => {
      const chart = this.dashboard.find(element => element.id == el.idQlikElement);
      if (chart) {
        el.x = chart.x;
        el.y = chart.y;
        el.cols = chart.cols;
        el.rows = chart.rows;
      }
    });

    this.reportService.updateReport(this.reportDetail).pipe(
      tap(
      res => {
        this.logger.log(res);
        const {modal} = this.modalService.openComponent(UpdateReportModalComponent);
        modal.onClose.subscribe(() => {
          this.logger.log('Closed successfully');
        });
        // this.manager.actionOpen();
      },
      err => {
        this.logger.error(err);
      })
    ).subscribe();
  }

  deleteReportConfirm(data: any) {
    const {modal, component} = this.modalService.openComponent(DeleteReportModalComponent);
    modal.onClose.subscribe((next) => {
      if (next.deleted) {
        this.deleteReport(data);
      }
    });
  }

  deleteReport(deletereport) {
    this.reportService.deleteReport(this.report.id)
      .pipe(
        tap(res => {
          this.logger.log(res);
          this.manager.actionOpen();
          const {modal, component} = this.modalService.openComponent(DeleteReportModalConfirmComponent);
          modal.onClose.subscribe(() => {
            this.logger.log('Closed successfully');
          });
        }),
        catchError(err => {
          this.logger.error(err);
          return of(null);
        })
      )
      .subscribe();
  }

  copyReportConfirm() {
    const {modal, component} = this.modalService.openComponent(CopyReportModalComponent);
    modal.onClose.subscribe((next) => {
      if (next.copied) {
        this.copyReport();
      }
    });
  }

  copyReport() {
    const copiedReport = this.reportDetail;
    delete copiedReport.id;
    delete copiedReport.code;
    copiedReport.description += ' - COPY';
    copiedReport.code = (Math.random() + 1).toString(36).substring(7);
    this.reportService.createReport(copiedReport).subscribe(
      res => {
        const {modal, component} = this.modalService.openComponent(CopyReportModalConfirmComponent);
        modal.onClose.subscribe(() => {
          this.logger.log('Closed successfully');
        });
      },
      err => {
        this.logger.error(err);
      }
    );
  }

  setMaxRows(maxRows: number) {
    this.options.maxRows = maxRows;
  }

  setMaxCols(maxCols: number) {
    this.options.maxCols = maxCols;
  }

  setEnableSelections(enableSelections: number) {
    NEBULA_CONF.context.constraints.active = !enableSelections;
  }

  setEnableInteractions(enableInteractions: number) {
    NEBULA_CONF.context.constraints.active = !enableInteractions;
  }

  setGridOptions() {
    this.options = options;
    this.options.gridType = GridType.VerticalFixed;
    this.options.draggable.ignoreContent = true;
    this.options.draggable.dragHandleClass = "drag";
    this.options.swap = true;
    this.options.swapWhileDragging = true;
    this.options.itemChangeCallback = CardAnalyticsDashboardComponent.itemChange;
    this.options.itemResizeCallback = CardAnalyticsDashboardComponent.itemResize;
  }

  getLoading$(chartId: string): Observable<boolean> {
    const matchedSubject = this.loadingSubjects.find(el => el.id === chartId);
    const matchedValue = matchedSubject ? matchedSubject.value : null;
    return matchedValue.asObservable();
  }

  completeLoading(chartId: string) {
    const matchedSubject = this.loadingSubjects.find(el => el.id === chartId);
    if (matchedSubject) {
      const value = matchedSubject.value.getValue();
      matchedSubject.value.next(!value);
    }
  }

  private initializeLoaders(elements: ReportElement[]) {
    for (let element of elements) {
      this.loadingSubjects.push(new LoadingSubject(element.idQlikElement, true));
    }
  }

  hideFiltersAndCharts() {
    this.showCharts = false;
    this.areFiltersVisible = false;
  }
}

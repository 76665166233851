<rgi-rx-panel class="rgi-ui-panel-container">
  <rgi-rx-panel-header class="rgi-ui-panel-header">
    <div class="row w-100">
      <div class="col-md-6 title-modal p-0-15">
        <span>{{'_CLAIMS_._MESSAGE' | translate }}</span>
      </div>
      <div class="col-md-6 btn-close-custom text-right">
        <span class="rgifont rgi-close_fine pull-right" (click)="closeModal('Close')"></span>
      </div>
    </div>
  </rgi-rx-panel-header>
  <ng-container class="main-container">
    <div class="card shadow p-1">
      <form [formGroup]="questionnaireDataForm">
        <div class="row">
          <div class="col-xs-6 text-center">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._NEW_QUESTIONNAIRE_DATE' | translate }}</label>
              <rgi-rx-date-picker [openOnClick]="true" [showRemoveButton]="true">
                <input [rgiRxDateTimeInput]="consDate" formControlName="controllDate" data-qa="rgi-questionnaire-date"
                  [min]="minDate" [max]="minDate">
              </rgi-rx-date-picker>
              <rgi-rx-date-time #consDate></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <rgi-rx-panel-footer>
    <div class="btn-group btn-group-justified btn-group-justified-claim">
      <div class="btn-group">
        <button rgi-rx-button color="primary" class="w-100" (click)="sendConfirm('Close')">
          {{'_CLAIMS_._BUTTONS_._CONFIRM' | translate }}
        </button>
      </div>
      <div class="btn-group">
        <button rgi-rx-button type="button" color="primary" class="w-100" (click)="sendConfirm('Confirm')">
          {{'_CLAIMS_._BUTTONS_._CONFIRM' | translate }}
        </button>
      </div>
    </div>
  </rgi-rx-panel-footer>
</rgi-rx-panel>
export class Province {

  constructor(
    public id: number,
    public description: string,
    public code: string,
    public startDate: number,
    public endDate: number,
    public exists: boolean
  ) {
  }

}



export class PaymentDataRequest {
  sign: Payment;
  instalment: Payment;
}

export class Payment {
  method: Method;
  bankAccount: BankAccount;
  card: CreditCard;
}

export class Method {
  code: string;
  description: string;
}

export class BankAccount {
  holder: string;
  countryCode: string;
  cab: string ;
  cin: string ;
  iban: string ;
}
export class CreditCard {
  holder: string;
  expiration: any;
  walletid: string;
  maskedpan: string;

}



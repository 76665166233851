import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DataValueFormatterModule } from '../pipe/data-value-formatter/data-value-formatter.module';

@NgModule({
  declarations: [
    DataGridComponent,
  ],
  imports: [
    CommonModule,
    DataValueFormatterModule,
  ],
  exports: [
    DataGridComponent],
})
export class ReusableTablesModule { }

export enum PASSPROPRO_SURVEY_CUSTOM_PROPERTIES {
  AGENT_DESCRIPTION = "AGE_DESCR",
  AGENT_CODE    =  "AGE_CODE",
  AGENT_TOWN    =  "AGE_TOWN",
  AGENT_RUI     ="RUI",
  ANAG_IDENTIFICATION ="FISCAL_CODE",
  ANAG_DESCRIPTION ="NAME",
  ANAG_RESIDENCE_CITY ="RESIDENCE_CITY",
  ANAG_RESIDENCE_CAP ="RESIDENCE_CAP",
  ANAG_RESIDENCE_LEV_2_SHORT ="RESIDENCE_LEV_2_SHORT",
  ANAG_ACTIVITY_DESC = "ACTIVITY"
}

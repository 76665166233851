
export class LicensePlateType {
    codice: string = '';
    descrizione: string = '';
}
export class GenericEnumVehicle {
    codice: string = '';
    descrizione: string = '';
}
export class VehicleData {
    objectId?: string = '';
    licensePlateCountryCode: string = '';
    firstRegistrationDate: string = '';
    substitution?: any = '';
    bodyShopExternalKey?: any = '';
    bodyShopSubject?: any = '';
    manufacturerDescription: string = '';
    financialConstraint?: any = '';
    licensePlateNumber: string = '';
    visibleDamages?: any = '';
    damageOption: GenericEnumVehicle = new GenericEnumVehicle();
    modelCode?: any = '';
    directlyInvolvedInCollision: boolean = false;
    parkedVehicle?: any = '';
    blackboxCoherence?: any = '';
    otherInvVehicles?: boolean = false;
    responsabilityPercentage?: any = '';
    observation?: any = '';
    recoveryProvince?: any = '';
    bodyShopTrusteeType?: any = '';
    manufacturerCode?: any = '';
    damagedParts:any[] = [];
    sicOutcome?: any = '';
    isFromLayout?: any = '';
    modelDescription: string = '';
    chassisNumber?: any = '';
    aniaCode?: any = '';
    insotherCompany?: any = '';
    theftType?: any = '';
    driver?: any = '';
    bodyShopCode?: any = '';
    recoveryDate?: any = '';
    theftProtection?: any = '';
    licensePlateType: LicensePlateType = new LicensePlateType();
    caiposition: GenericEnumVehicle = new GenericEnumVehicle();
    ppAsset: {
        description: string;
    };
    description: string;
}

import { EvaluatorLob } from "../../evaluation/models";


export type LobVisibilityMap = {[lobCode:string]:boolean};
export type ProdCodesByLobCode = { [lobCode: string]: string[]; };

export interface EvalLobConfig {
    lobs: EvaluatorLob[];
    visibilityMap: LobVisibilityMap;
    visibleProductsByLob: ProdCodesByLobCode;
}

export const LOB_UNCATEGORIZED: EvaluatorLob = {
    code: 'ZALTRO',
    description: 'Altri Prodotti',
    name: 'Altro',
    id: 9999999
};

export interface EvalPrice {
    value: number
}

export interface EvalPricing {
    price: EvalPrice
}

export interface EvalSectionCfg{
    visible: boolean;
    label: string
    isPackage: boolean;
    pricingSelected: EvalPricing;
}

export const EVAL_PACKAGE_SECTION_ID = "packageSection";

export type EvalSectionCfgMap = {[sectionId: string]: EvalSectionCfg};

export interface EvalSectionsCfg {
    map: EvalSectionCfgMap;
    visibleSectionIds: string[];
}

export interface EvalProdCluster {
    title: string;
    styleClass: string;
}

export interface EvalProductCfg {
    label:string;
    showSelectAll: boolean;
    showDeselectAll: boolean;
    covsCfg: EvalCovsCfg;
    pkgsCfg: EvalPkgsCfg;
    hasAnyChildVisible: boolean;
    childSelectableCount: number;
    expandContent: boolean;
    visible: boolean;
    sectionsCfg: EvalSectionsCfg;
    pricingSelected: EvalPricing;
    pricing: EvalPricing;
    scoreStyleClass: StyleClassScore;
    canShowScore: boolean;
    canShowInfo: boolean;
    cluster: EvalProdCluster|null
}

export type StyleClassScore = 'ppevo-high'|'ppevo-medium'|'ppevo-low'|'';

export interface EvalCovPkgCfg {
    visible: boolean;
    sellable: boolean;
    editable: boolean;
    selectionVisible: boolean;
    canShowInfo: boolean;
    canShowScore: boolean;
    pricing: EvalPricing;
    styleClassScore: StyleClassScore
}

export interface EvalCovCfg extends EvalCovPkgCfg{

}

export type EvalPkgCoveragePricingMap = {[covCode:string]: EvalPricing};
export interface EvalPkgCfg extends EvalCovPkgCfg {
    pkgPricing: EvalPkgPricing
}

export interface EvalPkgPricing {
    hasCoveragePricing: boolean;
    covPricingMap: EvalPkgCoveragePricingMap;
}

export type CovsMap = {[covCode:string]: EvalCovCfg};
export type PkgsMap = {[packageId:string]: EvalPkgCfg};

export interface EvalCovsOrPkgsCfg {
    hasAnyChildVisible: boolean;
    selectableCount: number;
    pricingSelected: EvalPricing;
    pricing: EvalPricing
}

export interface EvalCovsCfg extends EvalCovsOrPkgsCfg{
    covsMap: CovsMap
}

export interface EvalPkgsCfg extends EvalCovsOrPkgsCfg{
    pkgsMap: PkgsMap
}


export type EvalProdsCfgMap = {[productCode:string]: EvalProductCfg};
export enum EvalSelectionMode {PRODUCT, COV_OR_PKG};

export interface EvalProductsCfg {
    prodsCfgMap: EvalProdsCfgMap;
    lobsCfg: EvalLobConfig;
    hasVisibleProds: boolean;
    selectableProdsCount: number;
    visibleProdsCount: number;
    totalPricing: EvalPricing;
    selectionMode: EvalSelectionMode;
}

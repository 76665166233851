
    <rgi-rx-panel>
      <rgi-rx-panel-header [closeable]="true" (onClose)="actionClose()">
        <span class="anag-icon-m rgi-ui-icon-history"></span>
        {{'_ANAG_._LABEL_._HISTORY_CONSULTATION_' | translate}}
      </rgi-rx-panel-header>
        <p>
            <rgi-rx-datatable *ngIf="ratingHistory && ratingHistory.length && schema"
                                [data]="ratingHistoryTableData"
                                [schema]="schema"
                                [selectable]="false" [pageOptions]="[10,25,50,100]"
                                >
            </rgi-rx-datatable>
        </p>
        <rgi-rx-panel-footer>
            <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
                translate>_ANAG_._BTN_._CLOSE_
        </button>
        </rgi-rx-panel-footer>
    </rgi-rx-panel>


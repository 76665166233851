import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {ProductAssetModalComponent} from '../product-asset-modal/product-asset-modal-component';
import {PassProductsService} from '../pass-products.service';
import {CommonService} from '../common.service';
import {StateService} from '../state.service';
import {TranslateService} from '@ngx-translate/core';
import {OperatorService, TabChangeEvent} from '@rgi/portal-ng-core';
import {ProposalService} from '../proposal.service';
import {SubstitutionService} from '../substitution/substitution.service';
import {QuotationService} from '../quotation/quotation.service';
import {ErrorMessagesService} from '../error-messages.service';
import {ApiContract} from '../models/api-models/api-contract';
import {hasAssetNotFoundErrors, hasTechnicalDataErrors} from '../start-card/plates-utils';
import {Action} from '../models/action';
import {Message} from '../models/message';
import {LicensePlate} from '../models/domain-models/license-plate';
import {isPlateFormatOk$} from "../plate.validator";
import {catchError, concatMap, map} from "rxjs/operators";
import {of} from "rxjs";
import {State} from "../models/state";

export enum ChangeVehicleTabs {
  CHANGE_PLATE = 'plate',
  CHANGE_ASSET = 'asset'
}

@Component({
  selector: 'mic-change-vehicle-modal',
  templateUrl: './change-vehicle-modal.component.html',
  styleUrls: ['./change-vehicle-modal.component.scss']
})
export class ChangeVehicleModalComponent extends ProductAssetModalComponent implements OnInit {
  changeVehicleForm: UntypedFormGroup;
  products: GenericEntity[] = [];

  ChangeVehicleTabs = ChangeVehicleTabs;
  selectedTabs = ChangeVehicleTabs.CHANGE_PLATE.toString();
  isChangePlate: boolean;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected passProductsService: PassProductsService,
    protected commonService: CommonService,
    protected stateService: StateService,
    protected translate: TranslateService,
    protected operatorService: OperatorService,
    protected proposalService: ProposalService,
    protected substitutionService: SubstitutionService,
    protected quotationService: QuotationService,
    protected errorMessagesService: ErrorMessagesService
    ) {

    super(
      formBuilder,
      passProductsService,
      commonService,
      stateService,
      translate,
      operatorService,
      proposalService,
      substitutionService,
      quotationService,
      errorMessagesService
    );
  }

  protected getForm() {
    return this.changeVehicleForm;
  }
  protected buildForm() {
    this.changeVehicleForm = this.formBuilder.group(
      {
        licensePlate: ['', Validators.required],
        product: [''],
        plateType: [''],
        specPlateType: [''],
        specPlatePrefix: [''],
        countries: [''],
        specialLicensePlate: [''],
        assetType: [''],
        vehicleType: ['']
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected setWrongDataFromAnia() {
    this.wrongDataFromAnia = true;
  }

  protected setDataInAniaNotFound() {
    this.dataInAniaNotFound = false;
  }

  onSubmit() {
    this.submitted = true;
    Object.keys(this.getForm().controls).forEach(field => {
      this.getForm().get(field).markAsDirty();
    });

    this.validateForm();

    if (this.getForm().valid) {
      if (ChangeVehicleTabs.CHANGE_PLATE === this.selectedTabs) {
        this.onSubmitChangePlate();
      } else if (ChangeVehicleTabs.CHANGE_ASSET === this.selectedTabs) {
        this.onSubmitChangeAsset();
      }
    }
  }

  protected validateForm() {
    this.clearErrorMessages();
    if (ChangeVehicleTabs.CHANGE_PLATE === this.selectedTabs) {
      this.validateChangePlateForm();
    } else if (ChangeVehicleTabs.CHANGE_ASSET === this.selectedTabs) {
      this.validateChangeAssetForm();
    }
  }

  protected validateChangePlateForm() {
    const controls = this.getForm().controls;
    if (controls.licensePlate.errors && controls.licensePlate.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'License Plate is mandatory'));
    }
  }

  get isAssetTypeVisible() {
    return this.getForm().controls && this.getForm().controls.product &&
      this.getForm().controls.product.value && this.assetTypes && this.assetTypes.length > 1;
  }

  get isPlateTypeVisible() {
    return (this.getForm().controls && this.getForm().controls.product &&
      this.getForm().controls.product.value && (this.wrongDataFromAnia || this.dataInAniaNotFound || this.otherPlates) && this.plateTypes.length > 1) || this.isTestPlate;
  }

  get isSpecialPlateTypeVisible() {
    return this.otherPlates && this.getForm().controls && this.getForm().controls.plateType
      && this.getForm().controls.plateType.value && this.getForm().controls.plateType.value.code === '6';
  }

  get isPrefixVisible() {
    return this.otherPlates && this.getForm().controls && this.getForm().controls.specPlateType
      && this.getForm().controls.specPlateType.value;
  }

  get isCountryVisible() {
    return this.getForm().controls && this.getForm().controls.plateType && this.getForm().controls.plateType.value
      && (this.getForm().controls.plateType.value.code === '2' || this.getForm().controls.plateType.value.code === '4');
  }

  get isSpecialPlateNumberVisible() {
    return (this.getForm().controls && this.getForm().controls.specPlateType && this.getForm().controls.specPlateType.value)
      || (this.getForm().controls && this.getForm().controls.countries && this.getForm().controls.countries.value);
  }

  get isValidationMessagesVisible() {
    return (this.submitted && this.validationMessages && this.validationMessages.length > 0)
      || ( this.plateTypeValidityChecked && !this.isPlateTypeValid );
  }

  get isPlateNumberPresent() {
    return this.getForm().controls && this.getForm().controls.plateNumber
      && this.getForm().controls.plateNumber.value && this.getForm().controls.plateNumber.valid;
  }

  onTabChange(event: TabChangeEvent) {
    this.selectedTabs = event.nextId;
    this.clearErrorMessages();
    Object.keys(this.getForm().controls).forEach(field => {
      this.getForm().get(field).markAsPristine();
      this.getForm().get(field).clearValidators();
      this.getForm().get(field).updateValueAndValidity();
    });
    switch (this.selectedTabs) {
      case ChangeVehicleTabs.CHANGE_ASSET: {
        this.changeVehicleForm = this.formBuilder.group(
          {
            licensePlate: [''],
            product: ['', Validators.required],
            plateType: [''],
            specPlateType: [''],
            specPlatePrefix: [''],
            countries: [''],
            specialLicensePlate: [''],
            assetType: [''],
            vehicleType: ['']
          }
        );
        break;
      }
      case ChangeVehicleTabs.CHANGE_PLATE: {
        this.changeVehicleForm = this.formBuilder.group(
          {
            licensePlate: ['', Validators.required],
            product: [''],
            plateType: [''],
            specPlateType: [''],
            specPlatePrefix: [''],
            countries: [''],
            specialLicensePlate: [''],
            assetType: [''],
            vehicleType: ['']
          }
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  protected onSubmitChangePlate() {
    const oldPlate = JSON.parse(JSON.stringify(this.proposalService.getPlateNumber()));

    this.setNewPlate();

    const nodeId = this.operatorService.getSalePointLogin().objectId;
    const contractId = this.proposalService.getContractId();
    const plateNumber = this.proposalService.getPlateNumber().plateNumber;
    const plateType = this.getForm().controls.plateType && this.getForm().controls.plateType.value
      && this.getForm().controls.plateType.value !== '' ? this.getForm().controls.plateType.value : null;

    const assetType = this.getForm().controls.assetType && this.getForm().controls.assetType.value
    && this.getForm().controls.assetType.value !== '' ? this.getForm().controls.assetType.value.code : null;

    isPlateFormatOk$(this.getForm().controls.licensePlate, this.commonService, this.validationMessages,
      nodeId, this.areaCode, this.translate).pipe(
      concatMap((isPlateFormatOk) => {
        if (isPlateFormatOk){
          this.stateService.setCurrentState(State.EDIT_ASSET_DATA_STANDARD_PLATE);
          return this.proposalService.updateVehicleFromPlate('', nodeId, contractId, plateNumber, plateType, assetType, false);
        }
        return of(null);
      }),
      catchError((err) => {
        if (err.status > 400 && err.status < 500) {
          this.validationMessages.push(err.error.messages[0]);
        }
        // restore the old value since the services went in error
        this.proposalService.setPlateNumber(oldPlate);
        this.proposalService.getApiContract().vehicle.plate = oldPlate;
        return of(null);
      }),
      map((contract: ApiContract) => {
        if (contract) {
          this.manageUpdateFromPlate(contract, plateType);
        }
      })
    ).subscribe();
  }

  protected manageUpdateFromPlate(contract: ApiContract, plateType) {
    let selectedProduct = this.products.find(prd => prd.code === this.proposalService.getProduct().code);
    if (!!this.proposalService.getProduct() && !selectedProduct) {
      const prod = this.proposalService.getProduct();
      selectedProduct = new GenericEntity(prod.id, prod.code, prod.description);
      this.products = this.products.concat([selectedProduct]);
    }

    if ((hasAssetNotFoundErrors(contract) || hasTechnicalDataErrors(contract))
      || (contract.testPlate && !plateType)
      || (contract.products.length === 1 && contract.products[0].assets.length > 1)
    ) {
      this.needToSetAssetType = true;
      this.submitted = false;
      this.getForm().get('product').setValue(selectedProduct);
      this.getForm().get('product').disable();

      if (hasAssetNotFoundErrors(contract) || hasTechnicalDataErrors(contract)) {
        this.wrongDataFromAnia = true;
        this.dataInAniaNotFound = true;
      } else if (contract.testPlate && !plateType) {
        this.isTestPlate = true;
        this.getForm().get('plateType').patchValue(this.plateTypes.find(t => t.code === '3'));
      }
      this.onProductChange(false);
    } else if (contract.products.length === 1) {

      this.proposalService.setApiContract(contract);
      this.proposalService.setAsset(contract.vehicle);
      if (this.getForm().controls.assetType && this.getForm().controls.assetType.value
        && this.getForm().controls.assetType.value !== '') {

        this.proposalService.setAssetType(this.getForm().controls.assetType.value);
      }
      this.isChangePlate = true;
      if (this.proposalService.existAssetDataCompletenessErrors()) {
        this.cancel(Action.EDIT_ASSET_DATA_BUTTON_PRESSED);
      } else {
        this.bRecalculateQuotation = true;
        this.cancel(Action.EDIT_QUOTATION_BUTTON_PRESSED);
      }
    }
  }

  private setNewPlate() {
    let plate: LicensePlate;

    let plateNumber: string;

    plate = this.proposalService.getPlateNumber();
    plateNumber = this.getForm().controls.licensePlate.value;

    if (plateNumber) {
      if (plate) {
        plate.plateNumber = String(plateNumber).trim().toUpperCase();
      } else {
        plate = new LicensePlate(String(plateNumber).trim().toUpperCase(), null, null, null, null, null);
      }
      this.proposalService.setPlateNumber(plate);
    }
  }

}

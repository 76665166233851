import {StateStoreService} from '@rgi/rx/state';
import {PncPsalesSummaryState} from '../../resources/states/summary-state';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPostSalesOrchestratorService} from '../../orchestrator/pnc-postsales-orchestrator.service';
import {Observable, of} from 'rxjs';
import {PncPostSalesIntegrationService} from '../../services/pnc-postsales-integration.service';
import {RgiPncPsalesStateManager} from '../../api/rgi-pnc-postsales-state.manager';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPsalesHttpErrorService} from '../../services/pnc-postsales-http-error.service';
import {
  PncPsalesConfirmationModalComponent,
  RgiPncConfirmationModalData
} from '../../components/modals/pnc-confirmation-modal/confirmation-modal.component';
import {ModalService} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {concatMap} from 'rxjs/operators';
import {PNC_PSALES_ACTIONS} from '../../resources/constants/actions';
import {CommissionDetailAPI, CommissionInstalmentAPI} from '../../resources/model/api/quotation/commission-api';
import {AnnualPremiumAPI, PremiumDetailAPI} from '../../resources/model/api/quotation/premium-api';
import {InstalmentDetailAPI, InstalmentPremiumDetail} from '../../resources/model/api/quotation/installment-api';
import {
  InstallmentHeader,
  RGI_QUOTATION_MENU_ITEM_CODES,
  RgiQuotationHeaderData
} from '../../resources/model/common/quotation/quotation-header-data';
import {PremiumDetailColumn} from '../../resources/model/common/quotation/premium-detail';
import {GenericEntityId, LabeledEntity} from '../../resources/model/common/entities';
import {
  AccountingDetailsModalData
} from '../../resources/model/common/quotation/accounting-details-modal-data';
import {CommissionDetailModalData} from '../../resources/model/common/quotation/commission-detail-modal-data';
import {PNC_POSTSALES_COMMISSION_TYPE} from '../../resources/enums/commission-types-enum';
import {TaxDetailAPI} from '../../resources/model/api/tax-detail';
import {
  PolicyDetailModalData,
  PolicyDetailModalRow
} from '../../resources/model/common/quotation/policy-detail-modal-data';
import {DEFAULT_TABLE_VALUE} from '../../resources/constants/default-values-symbols';

export class PncPsalesSummaryStateManager extends RgiPncPsalesStateManager<PncPsalesSummaryState> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private modalService: ModalService,
    private _translateService: RgiRxTranslationService) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
  }

  initState$(_state: Observable<PncPsalesSummaryState>): Observable<PncPsalesSummaryState> {
    throw new Error('Method not implemented.');
  }

  updateNotes$(note: string) {
    const st = this.getCurrentState();
    st.notes = !!note ? note.trim() : null;
    st.allProperties.cancelProperties.notes = !!note ? note.trim() : null;
    this.updateState$(of(st));
  }

  onAction(action: string): Observable<PncPsalesSummaryState> | void {
    switch (action) {
      case PNC_PSALES_ACTIONS.BACK:
        this.actionBack();
        break;
      case PNC_PSALES_ACTIONS.CONTINUE:
        this.actionContinue();
        break;
      case PNC_PSALES_ACTIONS.FULL_SUMMARY:
        this.openFullSummary()
        break;
    }
  }


  actionContinue() {
    const st = this.getCurrentState();
    this._translateService.translate(
      '_PCPSALES_._MSG_._CONFIRM_OPERATION_',
      {opDescr: st.currentOperation.description, policyNumber: st.policyNumber}
    ).pipe(
      concatMap(translated => {
        const cancellationModal = this.modalService.openComponent(
          PncPsalesConfirmationModalComponent, new RgiPncConfirmationModalData(translated));
        return cancellationModal.modal.onClose;
      })
    ).subscribe(action => {
      if (action === PNC_PSALES_ACTIONS.CONTINUE) {
        this.finalizeOperation$(st);
      }
    });
  }

  protected finalizeOperation$(state: PncPsalesSummaryState) {
    throw new Error('Method not implemented.');
  }

  get translateService(): RgiRxTranslationService {
    return this._translateService;
  }

  protected populateQuotationHeader(commissions: CommissionDetailAPI[], annualPremium: AnnualPremiumAPI, installmentDetail: InstalmentDetailAPI, nextInstallmentDetail?: InstalmentDetailAPI, currency?: string): RgiQuotationHeaderData {
    const policyPremium = annualPremium.policyPremiumDetails;
    const qData = new RgiQuotationHeaderData();
    // fixme better currency refactor
    const currencyCode = 'EUR';
    const currencySymbol = '€';
    qData.headerInstallments = this.getQuotationHeaderInstallments(installmentDetail, policyPremium);
    qData.menu = {
      label: '_RGIPNC_._LABEL_._VIEW_',
      items: [
        {
          label: '_RGIPNC_._LABEL_._TOTAL_PREMIUMS_',
          actionCode: RGI_QUOTATION_MENU_ITEM_CODES.TOTALS_PREMIUM,
          ctx: this.getPremiumDetailModalData(installmentDetail, nextInstallmentDetail, policyPremium, currencyCode, currencySymbol)
        },
        {
          label: '_RGIPNC_._LABEL_._POLICY_DETAILS_',
          actionCode: RGI_QUOTATION_MENU_ITEM_CODES.POLICY_DETAILS,
          ctx: this.getPolicyDetailModalData(annualPremium)
        },
        {
          label: '_RGIPNC_._LABEL_._ACCOUNTING_DETAILS_',
          actionCode: RGI_QUOTATION_MENU_ITEM_CODES.ACCOUNTING_DETAILS,
          ctx: this.getContributionsAndTaxesModalData(installmentDetail)
        }
      ]
    };
    if (commissions && commissions.length > 0) {
      qData.menu.items.push(
        {
          label: '_RGIPNC_._LABEL_._COMMISSIONS_',
          actionCode: RGI_QUOTATION_MENU_ITEM_CODES.COMMISSIONS,
          ctx: this.getCommissionsModalData(commissions)
        }
      );
    }
    return qData;
  }

  protected getQuotationHeaderInstallments(instalmentDetail: InstalmentDetailAPI, annualPremium: PremiumDetailAPI): InstallmentHeader[] {
    return [
      {
        installmentType: '_RGIPNC_._LABEL_._INSTALLMENT_PREMIUM_',
        amountType: '_RGIPNC_._LABEL_._GROSS_',
        amount: instalmentDetail?.premium?.gross ? instalmentDetail?.premium?.gross : DEFAULT_TABLE_VALUE.HYPHEN,
        boldSection: false,
      },
      {
        installmentType: '_RGIPNC_._LABEL_._ANNUAL_PREMIUM_',
        amountType: '_RGIPNC_._LABEL_._GROSS_',
        amount: this.getValueOrDefault(annualPremium, 'gross', DEFAULT_TABLE_VALUE.HYPHEN),
        boldSection: true,
      },
    ];
  }

  protected getPremiumDetailModalData(installmentDetail: InstalmentDetailAPI, nextInstallmentDetail: InstalmentDetailAPI, annualPremium: PremiumDetailAPI, currencyCode: string, currencySymbol: string): PremiumDetailColumn[] {

    const installmentAmounts = this.createLabeledEntities(installmentDetail?.premium);
    const annualAmounts = this.createLabeledEntities(annualPremium);
    const nextInstallmentAmounts = nextInstallmentDetail?.premium ? this.createLabeledEntities(nextInstallmentDetail.premium) : null;

    const installmentModalData: PremiumDetailColumn = {
      premiumLabel: '_RGIPNC_._LABEL_._INSTALLMENT_PREMIUM_',
      gross: this.getValueOrDefault(installmentDetail?.premium, 'gross', DEFAULT_TABLE_VALUE.HYPHEN),
      currencyCode,
      currencySymbol,
      amounts: installmentAmounts,
    };

    const annualModalData: PremiumDetailColumn = {
      premiumLabel: '_RGIPNC_._LABEL_._ANNUAL_PREMIUM_',
      gross: this.getValueOrDefault(annualPremium, 'gross', DEFAULT_TABLE_VALUE.HYPHEN),
      currencyCode,
      currencySymbol,
      amounts: annualAmounts,
    };

    const nextInst = [];
    if (nextInstallmentAmounts) {
      const nextInstallmentModalData: PremiumDetailColumn = {
        premiumLabel: '_RGIPNC_._LABEL_._NEXT_INSTALLMENT_PREMIUM_',
        gross: this.getValueOrDefault(nextInstallmentDetail.premium, 'gross', DEFAULT_TABLE_VALUE.HYPHEN),
        currencyCode,
        currencySymbol,
        amounts: nextInstallmentAmounts,
      };
      nextInst.push(nextInstallmentModalData);
    }


    return [installmentModalData].concat(nextInst).concat(annualModalData);
  }

  createLabeledEntities(premium: any): LabeledEntity[] {
    let taxesDetails: LabeledEntity[];

    if (premium?.taxesDetails?.lentgh) {
      taxesDetails = premium?.taxesDetails.map((taxDet: TaxDetailAPI) => {
        return new LabeledEntity(taxDet.taxDescription, this.getValueOrDefault(taxDet, 'amount', DEFAULT_TABLE_VALUE.HYPHEN));
      });
    }

    return [
      new LabeledEntity('_RGIPNC_._LABEL_._NET_', this.getValueOrDefault(premium, 'net', DEFAULT_TABLE_VALUE.HYPHEN)),
      new LabeledEntity('_RGIPNC_._LABEL_._ACCESSORIES_', this.getValueOrDefault(premium, 'incidentals', DEFAULT_TABLE_VALUE.HYPHEN)),
      new LabeledEntity('_RGIPNC_._LABEL_._INSTALLMENT_INTEREST_', this.getValueOrDefault(premium, 'fractioningInterest', DEFAULT_TABLE_VALUE.HYPHEN)),
      new LabeledEntity('_RGIPNC_._LABEL_._TAXABLE_', this.getValueOrDefault(premium, 'taxable', DEFAULT_TABLE_VALUE.HYPHEN)),
      new LabeledEntity('_RGIPNC_._LABEL_._TAXES_', this.getValueOrDefault(premium, 'taxes', DEFAULT_TABLE_VALUE.HYPHEN),null, taxesDetails),
      new LabeledEntity('_RGIPNC_._LABEL_._GROSS_', this.getValueOrDefault(premium, 'gross', DEFAULT_TABLE_VALUE.HYPHEN))
    ];
  }

  private getContributionsAndTaxesModalData(installmentDetail: InstalmentDetailAPI): AccountingDetailsModalData {
    const modalData = new AccountingDetailsModalData();
    const installmentFees = installmentDetail?.fees?.premium;

    modalData.installmentNet = this.getValueOrDefault(installmentDetail?.premium, 'net', DEFAULT_TABLE_VALUE.HYPHEN);
    modalData.installmentGross = this.getValueOrDefault(installmentDetail?.premium, 'gross', DEFAULT_TABLE_VALUE.HYPHEN);


    if (installmentDetail?.amountByRisk) {
      modalData.tableRows = installmentDetail.amountByRisk.map((risk: InstalmentPremiumDetail) => {
        return {
          riskDescription: risk.description,
          NET: risk.net,
          TOTAL_TAXES: risk.taxes,
          GROSS: risk.gross,
          taxesDetail: risk.taxesDetails
        };
      });
    }
    let totalLabel: string;
    this.translateService.translate('_RGIPNC_._LABEL_._TOTAL_').subscribe(label => {
      totalLabel = label;
    });
    modalData.tableRows.push({
      riskDescription: totalLabel,
      NET: installmentDetail.premium.net,
      TOTAL_TAXES: installmentDetail.premium.taxes,
      GROSS: installmentDetail.premium.gross,
      taxesDetail: installmentDetail.premium.taxesDetails,
      isTotal: true
    });

    return modalData;
  }

  private getCommissionsModalData(commissions: CommissionDetailAPI[]): CommissionDetailModalData {
    const modalData = new CommissionDetailModalData();
    const subHeader = new CommissionDetailAPI();
    subHeader.subscriptionInstalment = new CommissionInstalmentAPI();
    const rightsRow = new CommissionDetailAPI();
    rightsRow.subscriptionInstalment = new CommissionInstalmentAPI();
    const totals = commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.TOTALS);
    const rights = commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.ADM_FEES);
    let amountLabel = '';
    this.translateService.translate('_RGIPNC_._LABEL_._AMOUNT_').subscribe(label => {
      amountLabel = label;
    });
    subHeader.subscriptionInstalment.netPercPurchaseCommissions = '%';
    subHeader.subscriptionInstalment.netPurchaseCommissions = amountLabel;
    subHeader.subscriptionInstalment.feePercPurchaseCommissions = '%';
    subHeader.subscriptionInstalment.feePurchaseCommissions = amountLabel;
    subHeader.subscriptionInstalment.netPercCollectionCommissions = '%';
    subHeader.subscriptionInstalment.netCollectionCommissions = amountLabel;
    subHeader.subscriptionInstalment.feePercCollectionCommissions = '%';
    subHeader.subscriptionInstalment.feeCollectionCommissions = amountLabel;
    subHeader.subscriptionInstalment.totPercCommissions = '%';
    subHeader.subscriptionInstalment.totCommissions = amountLabel;

    modalData.commissions = [subHeader].concat(commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.RISK));
    if (!!totals && totals[0] && totals[0].subscriptionTotals) {
      modalData.subscriptionTotals = totals[0].subscriptionTotals;
    }
    if (!!rights && rights[0] && rights[0].subscriptionFees) {
      this.translateService.translate('_RGIPNC_._LABEL_._RIGHTS_').subscribe(label => {
        rightsRow.risk = new GenericEntityId(null, label, null);
      });
      rightsRow.subscriptionInstalment.netCollectionCommissions = rights[0].subscriptionFees.netAdministrationFee;
      rightsRow.subscriptionInstalment.netPercCollectionCommissions = rights[0].subscriptionFees.percAdministrationFee;
      modalData.commissions.push(rightsRow);
    }
    return modalData;
  }

  getValueOrDefault(obj: any, key: string, defaultValue: string): string {
    return obj && !!obj[key] && +obj[key] > 0 ? obj[key] : defaultValue;
  }

  getPolicyDetailModalData(annualPremium: AnnualPremiumAPI): PolicyDetailModalData {
    const modalData = new PolicyDetailModalData();
    const tableRows: PolicyDetailModalRow[] = [];
    annualPremium.assetPremiumDetails.forEach(asset => {
      tableRows.push({
        ASSETS_RISKS: asset.description,
        NET: asset.premiumDetails.net,
        CHARGES: asset.premiumDetails.incidentals,
        NET_INTERESTS: asset.premiumDetails.fractioningInterest,
        TAXABLE: asset.premiumDetails.taxable,
        TOTAL_TAXES: asset.premiumDetails.taxes,
        GROSS: asset.premiumDetails.gross,
        taxesDetail: asset.premiumDetails.taxesDetails,
      });
      asset.riskPremiumDetails.forEach(risk => {
        tableRows.push({
          ASSETS_RISKS: risk.description,
          NET: risk.premiumDetails.net,
          CHARGES: risk.premiumDetails.incidentals,
          NET_INTERESTS: risk.premiumDetails.fractioningInterest,
          TAXABLE: risk.premiumDetails.taxable,
          TOTAL_TAXES: risk.premiumDetails.taxes,
          GROSS: risk.premiumDetails.gross,
          taxesDetail: risk.premiumDetails.taxesDetails,
          isRisk: true
        });
      });
    });
    let totalLabel: string;
    this.translateService.translate('_RGIPNC_._LABEL_._TOTAL_').subscribe(label => {
      totalLabel = label;
    });
    tableRows.push({
      ASSETS_RISKS: totalLabel,
      NET: annualPremium.policyPremiumDetails.net,
      CHARGES: annualPremium.policyPremiumDetails.incidentals,
      NET_INTERESTS: annualPremium.policyPremiumDetails.fractioningInterest,
      TAXABLE: annualPremium.policyPremiumDetails.taxable,
      TOTAL_TAXES: annualPremium.policyPremiumDetails.taxes,
      GROSS: annualPremium.policyPremiumDetails.gross,
      taxesDetail: annualPremium.policyPremiumDetails.taxesDetails,
      isTotal: true
    });
    modalData.tableRows = tableRows;
    return modalData;
  }

  openFullSummary() {
    const st = this.getCurrentState();
    this.integrationService.openPolicyTemporaryDetail(this.activeRoute.id, st.draftId, st.policyNumber);
  }

}

import {Inject, Injectable} from '@angular/core';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {RestApiConf} from '../constants/rest-api-conf';
import {Observable} from 'rxjs';
import {PncPostSalesCreateDraftResponse} from '../model/api/contract-operations';
import {UserService} from '@rgi/rx/auth';
import {PncPostsalesGenericMandatoryDates} from '../model/api/dates';
import {SupplementaryTechnicalAccounting, TechnicalAccountingList} from '../model/api/technical-accounting';
import {PncPsalesNote} from '../model/api/notes';
import {MovementsList} from '../model/api/movement';
import {HttpParams} from '@angular/common/http';
import {QuotationAPI} from '../model/api/quotation/quotation-api';
import {PropertyApi} from '../model/api/property';
import {PolicyTransferData} from '../model/api/policy-transfer-data';
import {ApiAgencies} from '../model/api/nodes';
import {Documents} from '../model/api/document';

/**
 * Service to invoke PNC domain APIs
 */
@Injectable({
  providedIn: 'root'
})
export class PncDomainService {
  protected hostPath: string;

  constructor(
    @Inject('environment') protected apiConf: RestApiConf,
    public httpClient: RgiRxHttpClientWrapper,
    protected userService: UserService
  ) {
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path + '/v2';
  }


  createPostSalesDraft$(policyNumber: string, operationType: string, operationCode: string, prefix: string): Observable<PncPostSalesCreateDraftResponse> {
    return this.httpClient.post<PncPostSalesCreateDraftResponse>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts`, {});
  }

  getDraftDates$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string): Observable<PncPostsalesGenericMandatoryDates> {
    return this.httpClient.get<PncPostsalesGenericMandatoryDates>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/dates`);
  }

  setDraftDates$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string, dates: PncPostsalesGenericMandatoryDates): Observable<PncPostsalesGenericMandatoryDates> {
    return this.httpClient.put<PncPostsalesGenericMandatoryDates>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/dates`, dates);
  }

  getTechAccountingList$(policyNumber: string, operationType: string, code: string, routePrefix: any, draftId: string): Observable<TechnicalAccountingList> {
    return this.httpClient.get<TechnicalAccountingList>(`${this.hostPath}/${routePrefix}/policies/${policyNumber}/operations/variations/${operationType}/${code}/drafts/${draftId}/technicalAccounting`);
  }

  getDraftNotes$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string): Observable<PncPsalesNote> {
    return this.httpClient.get<PncPsalesNote>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/notes`);
  }

  setDraftNotes$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string, notes: PncPsalesNote): Observable<any> {
    return this.httpClient.put<PncPsalesNote>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/notes`, notes);
  }

  executeOperation$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/execution`, {});
  }

  getDraftMovements$(policyNumber: string, operationType: string, operationCode: string, prefix: string): Observable<MovementsList> {
    return this.httpClient.get<MovementsList>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/movements`);
  }

  deleteMovement$(policyNumber: string, operationType: string, operationCode: string, prefix: string, movementId: string) {
    return this.httpClient.delete(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/movements/${movementId}`);
  }

  getSupplementaryTechAccounting$(policyNumber: string, operationType: string, code: string, routePrefix: any, draftId: string): Observable<SupplementaryTechnicalAccounting> {
    return this.httpClient.get<SupplementaryTechnicalAccounting>(`${this.hostPath}/${routePrefix}/policies/${policyNumber}/operations/variations/${operationType}/${code}/drafts/${draftId}/technicalAccountingTransactions/supplementary`);
  }

  setSupplementaryTechAccounting$(policyNumber: string, operationType: string, code: string, routePrefix: any, draftId: string, supplementaryTechAccountingList: SupplementaryTechnicalAccounting, reset?: boolean): Observable<void> {
    let params = new HttpParams();
    if (reset) {
      params = params.append('reset', reset);
    }
    return this.httpClient.put<void>(`${this.hostPath}/${routePrefix}/policies/${policyNumber}/operations/variations/${operationType}/${code}/drafts/${draftId}/technicalAccountingTransactions/supplementary`, supplementaryTechAccountingList, {params});
  }

  getQuotationData$(prefix: string, policyNumber: string, operationType: string, operationCode: string, resourceId: string): Observable<QuotationAPI> {
    return this.httpClient.get<QuotationAPI>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/quotation`);
  }

  getProperty$(prefix: string, policyNumber: string, operationType: string, operationCode: string, resourceId: string, propertyCode: string): Observable<PropertyApi> {
    return this.httpClient.get<PropertyApi>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/property/${propertyCode}`);
  }

  getPolicyTransferData$(routePrefix: string, policyNumber: string, operationType: string, operationCode: string, resourceId: string): Observable<PolicyTransferData> {
    return this.httpClient.get<PolicyTransferData>(`${this.hostPath}/${routePrefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/transferData`);
  }

  getNodes$(nodeId: string): Observable<ApiAgencies> {
    let params = new HttpParams();
    if (nodeId) {
      params = params.append('loginNodeId', nodeId);
    }
    return this.httpClient.get<ApiAgencies>(`${this.apiConf.api.portal.host + this.apiConf.api.portal.path}/admin/agencies`, {params});
  }

  setPolicyTransferData$(routePrefix: string, policyNumber: string, operationType: string, operationCode: string, resourceId: string, transferData: PolicyTransferData): Observable<void> {
    return this.httpClient.put<void>(`${this.hostPath}/${routePrefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/transferData`, transferData);
  }

  getDocuments$(policyNumber: string, movementCode: string): Observable<Documents> {
    return this.httpClient.get<Documents>(`${this.hostPath}/portfolio/pc/contract/${policyNumber}/documents?movementCode=${movementCode}`);
  }

  downloadDocuments$(policyNumber: string, documentCode: string): Observable<Blob> {
    return this.httpClient.get(`${this.hostPath}/portfolio/pc/contract/${policyNumber}/document/${documentCode}/download`, {responseType: 'blob'});
  }
}

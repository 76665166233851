import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {RgiRxUserService} from '@rgi/rx/auth';
import {OperatorLight} from '../../services/external-service';
import {AnagStateHome} from './anag-state-manager-home';
import {AnagResourceService} from '../../anag-resources/anag-resource.service';
import {AnagSubjectsFilter} from '../../anag-model/anag-api/anag-subjects-filter';
import {ROUTES_DETAIL, ROUTES_MODAL_RESULT, ROUTES_RESULT} from '../../anag-constants/routes-constants';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagApiPartyKeyConfigRequest} from '../../anag-model/anag-api/anag-api-party-key-config-request';
import {CORE_ADDRESS_CONFIG, CORE_PARTY_CONFIG, MULTICOMP_CONFIG} from '../../anag-constants/anag-constants';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {AnagApiSubject} from '../../anag-model/anag-api/anag-api-subject';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagUtilsService} from "../../anag-resources/anag-utils.service";

@Injectable({
  providedIn: 'root'
})
export class AnagStatelessOpHomeService {

  constructor(
    protected anagResources: AnagResourceService,
    protected userService: RgiRxUserService,
    protected anagStatelessService: AnagStatelessService,
    protected routingService: RoutingService,
    protected anagStorage: AnagStorageService,
    protected anagIntegration: AnagIntegrationService,
    protected anagUtilsService: AnagUtilsService
    ) {
  }

  clearState$(stateHome$: Observable<AnagStateHome>): Observable<AnagStateHome> {
    return stateHome$.pipe(map((st) => {
        st.party = new AnagApiParty();
        st.partyKey = undefined;
        st.partyNotFound = false;
        st.serviceError = undefined;
        return st;
      }
    ));
  }

  initHome$(stateHome$: Observable<AnagStateHome>): Observable<AnagStateHome> {
    return stateHome$.pipe(
      mergeMap(
        (st) => {
          return combineLatest([
            of(st),
            this.anagResources.retrieveCountries$().pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_ADDRESS_CONFIG.address).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.partyNumber).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(MULTICOMP_CONFIG.PARAM_GRUPPO_COMPAGNIE).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getNodes$().pipe(catchError((err) => {
              return of(err);
            })),
            this.anagResources.getCompanies$().pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              // tslint:disable-next-line:max-line-length
              ([state, resp, systemPropAddress, systemPropPartyNumber, systemPropGruppoComp, nodes, companies]) => {
                if (resp.countriesList && resp.countriesList.length !== 0) {
                  this.anagStorage.setCountriesList(resp.countriesList);
                }
                if (resp instanceof HttpErrorResponse) {
                  state.serviceError = resp.message;
                }
                if (systemPropAddress && systemPropAddress.value && systemPropAddress.key) {
                  state.isUmaPartyKeyEnable = systemPropAddress.value === 'Enabled';
                }
                if (systemPropPartyNumber && systemPropPartyNumber.value && systemPropPartyNumber.key) {
                  state.isUmaPartyNumberEnable = systemPropPartyNumber.value === 'Enable';
                }
                if (nodes && nodes.length) {
                  state.nodes = nodes;
                }
                if (systemPropGruppoComp && systemPropGruppoComp.value && systemPropGruppoComp.key) {
                  state.isMultiCompagnia = systemPropGruppoComp.value.toLowerCase() === 'true';
                  if (state.isMultiCompagnia && companies && companies.length !== 0) {
                    this.anagStorage.setCompanies(companies);
                  }
                }
                return state;
              }
            )
          );
        }
      )
    );
  }

  findParties$(stateHome$: Observable<AnagStateHome>, activeRoute: ActiveRoute): Observable<AnagStateHome> {
    return stateHome$.pipe(
      mergeMap(
        (st) => {
          if (st.flowData) {
            if (st.flowData.nodeCode) {
              st.searchNodeCode = st.flowData.nodeCode;
            }
            if (st.flowData.partyType) {
              st.partyType = st.flowData.partyType;
            }
          }
          return combineLatest([
            of(st), this.anagResources.findParties$(this.anagUtilsService.convertDateToString(AnagSubjectsFilter.adaptFromState(st))).pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              ([state, resp]) => {
                if (resp && resp.output) {
                  if (resp.output.totalCounter === '0') {
                    state.partyNotFound = true;
                  } else if (resp.output.totalCounter === '1' && !state.isModalFlow) {
                    state.party = resp.output.subjects[0];
                    this.findPartyAndGoTo$(of(state), activeRoute).subscribe();
                  } else {
                    const routeData = {
                      ...activeRoute.getRouteData<AnagRouteData>(),
                      party: this.anagStatelessService.deepClone(state.party),
                      parties: resp.output.subjects,
                      totalCounterParties: resp.output.totalCounter,
                      redirectToMainFlow: state.redirectToMainFlow
                    };
                    let route = ROUTES_RESULT;
                    if (routeData && routeData.isModalFlow) {
                      route = ROUTES_MODAL_RESULT;
                    }
                    state.party = new AnagApiParty();
                    // state.partyKey = undefined;
                    state.fiscalCodeDynamicObj = undefined;
                    state.clientNumber = undefined;
                    state.birthName = undefined;
                    state.subsystemIdentifier = undefined;
                    state.subsystemCode = undefined;
                    this.anagIntegration.navigate(this.routingService, route, routeData, activeRoute);
                  }
                }
                if (resp instanceof HttpErrorResponse) {
                  state.serviceError = resp.message;
                }
                return state;
              }
            )
          );
        }
      )
    );
  }

  findPartyAndGoTo$(stateHome$: Observable<AnagStateHome>, activeRoute: ActiveRoute) {
    return stateHome$.pipe(
      mergeMap(
        (st) => {
          return combineLatest([
            of(st), this.anagResources.getSubjectDetail$(st.party.objectId, st.party.node.identification, null).pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              ([state, resp]: [AnagStateHome, AnagApiSubject]) => {
                if (resp && resp.subject) {
                  const routeData = {
                    ...activeRoute.getRouteData<AnagRouteData>(),
                    party: resp.subject,
                    redirectToMainFlow: state.redirectToMainFlow
                  };
                  state.party = new AnagApiParty();
                  // state.partyKey = undefined;
                  state.fiscalCodeDynamicObj = undefined;
                  state.clientNumber = undefined;
                  state.birthName = undefined;
                  state.subsystemIdentifier = undefined;
                  state.subsystemCode = undefined;
                  this.anagIntegration.navigate(this.routingService, ROUTES_DETAIL, routeData, activeRoute);
                }
                if (resp instanceof HttpErrorResponse) {
                  state.serviceError = resp.message;
                }
                return state;
              }
            )
          );
        }
      )
    );
  }

  getPartyKeyConfig$(stateHome$: Observable<AnagStateHome>, countryCode: string) {
    return stateHome$.pipe(
      mergeMap(
        (st) => {
          return combineLatest([
            of(st), this.anagStorage.getPartyKeysByFilter(this.createPartyKeyConfReq(countryCode)).pipe(
              catchError((err) => {
                return of(err);
              })
            )
          ]).pipe(
            map(
              ([state, partyKey]) => {
                state.partyKey = partyKey;
                return state;
              }
            )
          );
        }
      )
    );
  }

  private createPartyKeyConfReq(countryCode: string): AnagApiPartyKeyConfigRequest {
    const req = new AnagApiPartyKeyConfigRequest();
    const filter = req.partyConfigurationFilter;
    filter.country = countryCode;
    return req;
  }

  getNodeCode(currentState: AnagStateHome): string {
    let node: string;
    if (this.userService.isLoggedIn()) {
      if (currentState.flowData && currentState.flowData.nodeCode) {
        node = currentState.flowData.nodeCode;
      } else if (this.userService.getUser<OperatorLight>().salePoint) {
        node = this.userService.getUser<OperatorLight>().salePoint.code;
      } else if (this.userService.getUser().isUserNetworkClaims) {
        node = this.userService.getUser<OperatorLight>().salePoint.code;
      }
    }
    return node;
  }
}

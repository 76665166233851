import { Component, Input } from '@angular/core';
import { TableSchema, RgiRxDataTableExpansionModel } from '@rgi/rx/ui';
import { Warnings } from '../../../../models/details/contract-details';

export const WARNINGS_LIST_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'blockLevel',
      title: 'CONTRACT_CONSULTATION.BLOCKING_LEVEL'
    },
    {
      name: 'description',
      title: 'CONTRACT_CONSULTATION.DESCRIPTION'
    },
    {
      name: 'type',
      title: 'CONTRACT_CONSULTATION.TYPE'
    },
    {
      name: 'origin',
      title: 'CONTRACT_CONSULTATION.ORIGINAL_ENTITY'
    }
  ],
  header: ['blockLevel', 'description', 'type', 'origin'],
  headerActions: [
    {
      name: '',
      label: 'CONTRACT_CONSULTATION.WARNINGS_LIST',
      when: 'always',
      styleClass: 'rgi-cc-table-title'
    }
  ]
}

@Component({
  selector: 'lib-consultation-warnings',
  templateUrl: './consultation-warnings.component.html',
  styleUrls: ['./consultation-warnings.component.css']
})
export class ConsultationWarningsComponent {

  @Input() warnings: Warnings;

  tableSchema: TableSchema = WARNINGS_LIST_SCHEMA;
  pageOptions: number[] = [10, 20, 50];
  customExpansionModel: RgiRxDataTableExpansionModel<Warnings>;
}

<div class="col-md-12 life-detail-first-info">

    <div class="col-md-3 life-detail-img">
        <div class="hidden-xs hidden-sm anag-user-ico">
            <span class="rgifont life-detail-icon-padding rgi-user"></span>
        </div>
    </div>
    <div class="col-md-9 life-detail-general-info">
        <h3 class="col-md-12 card-color-blue" style="margin-top: 0 !important;">
            <span translate>lpc_insured</span> <span> : {{insured?.name}} </span>
        </h3>

        <div class="container-fluid anag-data">
            <div class="col-lg-6">
                <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_fiscal_code</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">{{insured?.fiscalCode}}</span>
                </div>
                <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_sex</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">{{insured?.sex.descrizione}}</span>
                </div>
                <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_marital_status</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">{{insured?.maritalStatus.descrizione}}</span>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_birthdate</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">{{formatDate(insured?.birthDate)}}</span>
                </div>

                <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_address</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">{{insured?.address}}</span>
                </div>
            </div>


        </div>
    </div>
</div>

<ng-container [formGroup]="questionnaireForm" *ngIf="stateVcontQuestionnaire">
  <rgi-gp-stepper (targetRoute)="callActionBack($event)" [steps]="stateVcontQuestionnaire.steps" [vertical]="vertical"
    [showIndex]="showIndex" [labels]="stateVcontQuestionnaire.labels">
  </rgi-gp-stepper>

  <div class="rgi-gp-section-title">
    <span class="rgi-gp-icon rgi-ui-icon-rule"></span>
    <b translate>_GP_._TITLE_._QUESTIONNAIRE_</b>
  </div>

  <ng-container *ngIf="stateVcontQuestionnaire.questionnaires && stateVcontQuestionnaire.questionnaires.length">
    <rgi-gp-group-policy-questionnaires-component [questionnaires]="stateVcontQuestionnaire.questionnaires">
    </rgi-gp-group-policy-questionnaires-component>
  </ng-container>

  <div data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="stateVcontQuestionnaire.errors"
      *ngIf="!!stateVcontQuestionnaire.errors && !!stateVcontQuestionnaire.errors.length" [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button rgi-rx-button color="secondary" (click)="callActionClose()" data-qa="buttonsClose"
      translate>{{'_GP_._BTN_._CLOSE_'}}
    </button>
    <button rgi-rx-button color="primary" [disabled]="questionnaireForm.invalid" (click)="callActionGoForword()"
      translate>{{stateVcontQuestionnaire.nextRoute.button}}
    </button>
  </div>

</ng-container>
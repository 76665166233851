import { Component, Input, ViewEncapsulation } from "@angular/core";
import { SurveyEvaluateState, SurveyEvaluateStateManagerService } from "../../../../state/state-manager-survey.module";


@Component({
    templateUrl: './survey-eval-header.component.html',
    encapsulation: ViewEncapsulation.None,
    selector: '[survey-eval-header]'
})
export class  SurveyEvalHeaderComponent {
    @Input() st: SurveyEvaluateState;


    constructor(private _stMng: SurveyEvaluateStateManagerService<SurveyEvaluateState>) {
        
    }

    
    toggleGroupingByLob(b: boolean) {
        this._stMng.actionToggleGroupingByLob(b);
    }
}
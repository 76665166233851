import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { NgSelectModule } from '@ng-select/ng-select';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LpcSubjectModule } from '../../modules/lpc-subject/lpc-subject.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { LpcFactorAdapterModule } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.module';
import { AnagClaimReportComponent } from './anag-claim-report.component';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { ClaimDetailComponent } from './claim-detail/claim-detail.component';
import { InsuredDataComponent } from './insured-data/insured-data.component';
import { ClaimPoliciesComponent } from './claim-policies/claim-policies.component';
import { ClaimInsertComponent } from './claim-insert/claim-insert.component';
import { ClaimDataComponent } from './claim-data/claim-data.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LPC_TRANSLATIONS } from '../../i18n/translations';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';



@NgModule({
    declarations: [
        AnagClaimReportComponent,
        ClaimListComponent,
        ClaimDetailComponent,
        InsuredDataComponent,
        ClaimPoliciesComponent,
        ClaimInsertComponent,
        ClaimDataComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        LpcSubjectModule,
        NgxPaginationModule,
        LpcFactorAdapterModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(LPC_TRANSLATIONS),
        LpcRequiredModule,
        RgiRxFormElementsModule
    ],
    exports: [
        AnagClaimReportComponent,
        ClaimListComponent,
        ClaimDetailComponent,
        InsuredDataComponent,
        ClaimPoliciesComponent,
        ClaimInsertComponent
    ]
})
export class AnagClaimReportModule { }

<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="false" #first color="info">
    <div rgi-rx-expansion-panel-header>
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-12 col-md-12">
                <label ng-bind="label">
                    {{ '_CLAIMS_._INSURANCE_COMPANY' | translate }}
                </label>
            </div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="insuranceCompanyTemplate"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion">
    <div class="row">
        <h4 class="chapter">{{ '_CLAIMS_._INSURANCE_COMPANY' | translate }}</h4>
    </div>
    <ng-container *ngTemplateOutlet="insuranceCompanyTemplate"></ng-container>
</div>

<ng-template #insuranceCompanyTemplate>
    <div class="container-fluid table-white">
        <div class="row ptb-05">
            <div class="col-sm-12 col-xs-12">
                {{'_CLAIMS_._DENOMINATION' | translate}}:
                <span>{{ insuranceCompany.company?.description || '-'}}</span>
            </div>
        </div>

        <div class="row ptb-05">
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._POLICY_NUMBER' | translate}}:
                <span>{{insuranceCompany.policyNumber || '-'}}</span>
            </div>
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._GREEN_CARD_NUMBER' | translate}}:
                <span>{{insuranceCompany.greenCardNumber || '-'}}</span>
            </div>
        </div>

        <div class="row ptb-05">
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._VALIDITY_START_DATE' | translate}}:
                <span>{{(insuranceCompany.effectDate | date:'shortDate') || '-'}}</span>
            </div>
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._VALIDITY_END_DATE' | translate}}:
                <span>{{(insuranceCompany.expirationDate | date:'shortDate') || '-'}}</span>
            </div>
        </div>

        <div class="row ptb-05">
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._AGENCY' | translate}}:
                <span>{{insuranceCompany.agencyDescription || '-'}}</span>
            </div>
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._PROVINCE' | translate}}:
                <span>{{insuranceCompany.address?.province?.descrizione || '-'}}</span>
            </div>
        </div>

        <div class="row ptb-05">
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._CITY' | translate}}:
                <span>{{insuranceCompany.address?.city || '-'}}</span>
            </div>
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._ADDRESS' | translate}}:
                <span>{{ insuranceCompany.address?.toponymStr }} {{ insuranceCompany.address?.placeAddress }}
                    {{ insuranceCompany.address?.number }}</span>
            </div>
        </div>

        <div class="row ptb-05">
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._CAP' | translate}}:
                <span>{{insuranceCompany.address?.postCode || '-'}}</span>
            </div>
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._TELEPHONE' | translate}}:
                <span>{{phoneList}}</span>
            </div>
        </div>

        <div class="row ptb-05">
            <div class="col-sm-6 col-xs-12 ">
                {{'_CLAIMS_._EMAIL' | translate}}:
                <span>{{mailList}}</span>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-xs-6 ">
                <br clear="all">{{'_CLAIMS_._MESSAGE_._THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE' | translate}}:
            </div>
            <div class="col-sm-6 col-xs-6">
                <rgi-rx-button-toggle color="default" [(ngModel)]="isMaterialLossCovertureOnPolicy"
                    (ngModelChange)="onNgModelChange($event)">
                    <button rgi-rx-button rgiRxValueOption value="yes">{{'_CLAIMS_._YES' | translate }}</button>
                    <button rgi-rx-button rgiRxValueOption value="no">{{'_CLAIMS_._NO' | translate }}</button>
                </rgi-rx-button-toggle>
            </div>
        </div>
    </div>
</ng-template>
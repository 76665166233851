import {ChangeDetectorRef, Component, Inject, Optional} from '@angular/core';
import {AbsOperationComponent} from '../abs-operation-component/abs-operation.component';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MockTerminateRescissionService} from './mock/mock-terminate-rescission.service';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifierService} from '@rgi/portal-ng-core';
import { PlcQuestService } from '../../services/plc-quest.service';
import { AuthService } from '../../services/auth.service';
import { AnagService } from '../../services/anag.service';
import { PV_TOKEN } from '../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-terminate-rescission',
  templateUrl: './terminate-rescission.component.html',
  styleUrls: ['./terminate-rescission.component.css'],
  providers: [
    { provide: PV_TOKEN.POSTSALES_SERVICE, useClass: MockTerminateRescissionService }
  ]
})
export class TerminateRescissionComponent extends AbsOperationComponent {

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  protected operationDataKey: string;

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(this.createDraft().subscribe());
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      notes: new UntypedFormControl()
    });
  }

}

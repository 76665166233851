
<label class="lbl_input" translate>{{label}}</label>
<form [formGroup]="percentageForm">
  <div *ngFor="let s of getListOfSubject(); let i = index">
    <div class="input-group">
      <input
        value="{{fill(s.subject)}}"
        type="text"
        name="extraInformations_0"
        class="form-control"
        readonly=""
        [ngClass]="{'effectiveHolder': withPercentage}"
      >
      <!-- [ngClass]="{'is-invalid' : customForm.get('value').invalid && customForm.get('value').dirty && submitted}"" -->
      <input
        *ngIf="withPercentage"
        name="cat +'percentage'"
        data-qa="cat+percentage"
        [min]="minPercentage"
        max="100"
        class="form-control compulsory percentage"
        [placeholder]="percentage"
        required
        licPercentageDirective
        [formControlName]="s.subject.objectId"
        (ngModelChange)="setPercentageBySubj(s.subject.objectId, $event)"
      >
      <div class="input-group-btn">
        <button [attr.data-qa]="fill(s.subject)+'_subject_dataQa'" class="btn btn-default" (click)="trashButtonClick(s.subject.objectId)" [disabled]="">
          <span class="rgifont rgi-trash"></span>
        </button>
        <button type="button" class="btn btn-default" (click)="editSubject(s.subject.objectId, role)" [attr.data-qa]="'modify-' + role">
          <span class="rgifont rgi-pencil"></span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="maxLength > listOfSubjectLength">
    <input class="drop-container" [value]="select" data-qa="dashed-select-beneficiary" readonly (click)="openAnag()" >
    <br>
  </div>
</form>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'claims-amount-bar',
  templateUrl: './amount-bar.component.html',
  styleUrls: ['./amount-bar.component.scss']
})
export class AmountBarComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  containerAmount: number;

  @Input()
  insideAmount: number;

  constructor() { }

  ngOnInit() {
  }

}

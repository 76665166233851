import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent, CardComponentService, EnumType, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { ApiInvoiceFilter } from '../api/api-invoice-filter';
import { Invoice } from '../dto/invoice';
import { InvoiceDetails } from '../dto/invoice-details';
import { InvoiceClaimsListComponent } from '../invoice-claims-list/invoice-claims-list.component';
import { InvoiceExpertUpdateComponent } from '../invoice-expert-update/invoice-expert-update.component';
import { InvoiceTypeoflossListComponent } from '../invoice-typeofloss-list/invoice-typeofloss-list.component';
import { InvoiceUpdateComponent } from '../invoice-update/invoice-update.component';
import { InvoiceUploadComponent } from '../invoice-upload/invoice-upload.component';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent extends CardComponent implements OnInit, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  invoicesList: Invoice[] = [];
  invoicesFiltered: Invoice[] = [];
  filterInvoice: ApiInvoiceFilter;
  totalPages: number;
  sessionServiceInject: any;
  arrPage: number[] = [];
  errorMessage: string;
  emitter: any;
  currentPage: number;

  set invoices(invoices: Invoice[]) {
    // metto prima le invoice lavorabili per la pagina corrente
    this.invoicesList = invoices.sort((a, b) => {
      const aHasPriority: boolean = this.invoiceOrderStatusHasPriority(a);
      const bHasPriority: boolean = this.invoiceOrderStatusHasPriority(b);

      if (aHasPriority && !bHasPriority) {
        return -1;
      } else if (!aHasPriority && bHasPriority) {
        return 1;
      }

      return 0;
    });

    // metto prima i dettagli lavorabili
    for (const invoice of this.invoicesList) {
      invoice.invoiceDetailsList = invoice.invoiceDetailsList.sort((a, b) => {
        if (a.status.codice === '5' && b.status.codice !== '5') {
          return -1;
        }
        if (a.status.codice !== '5' && b.status.codice === '5') {
          return 1;
        }

        const aHasPriority: boolean = this.invoiceDetOrderRejectHasPriority(a);
        const bHasPriority: boolean = this.invoiceDetOrderRejectHasPriority(b);

        if (aHasPriority && !bHasPriority) {
          return -1;
        } else if (!aHasPriority && bHasPriority) {
          return 1;
        }

        return 0;
      });
    }
  }

  get invoices() {
    return this.invoicesList;
  }

  constructor(
    @Inject('sessionService') sessionServiceInject: any,
    @Inject('eventService') eventService: any,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private invoiceService: InvoiceService,
    private modalService: NgbModal,
    public translateService: RgiRxTranslationService,

  ) {
    super(cardComponentService, sessionService);
    this.sessionServiceInject = sessionServiceInject;
    this.emitter = eventService;
  }

  ngOnInit(): void {

    this.invoices = this.invoiceService.getSessionInvoices(this.card.id);
    this.invoicesFiltered = this.invoicesfiltering(this.invoices);
    this.filterInvoice = this.invoiceService.getSessionInvoiceFilter(this.card.id);
    this.totalPages = this.invoiceService.getSessionInvoiceTotalPages(this.card.id);
    this.currentPage = 1;

    for (let index = 0; index < this.totalPages; index++) {
      this.arrPage.push(index);
    }

  }

  rejectInvoice(rejectCode: string, invoice: Invoice) {

    switch (rejectCode) {

      // Costo sinistro incoerente con fattura
      case '12':
      // fattura inconsistente
      case '9':
      // dati fattura non coerenti
      case '10':
        const modalRefUpdate = this.modalService.open(InvoiceUpdateComponent, {
          windowClass: 'basic',
          size: 'lg'
        });
        modalRefUpdate.componentInstance.invoice = invoice;

        modalRefUpdate.result.then((result) => {
          if (result) {
            this.goToPage(this.currentPage);
          }
        });
        break;

      // manca sinistro
      case '1':
      // fattura non autorizzata
      case '28':
        const modalRefClaimsList = this.modalService.open(InvoiceClaimsListComponent, {
          windowClass: 'basic',
          size: 'lg'
        });
        modalRefClaimsList.componentInstance.invoice = invoice;

        modalRefClaimsList.result.then((result) => {
          if (result) {
            this.goToPage(this.currentPage);
          }
        });
        break;

      // Anagrafica inconsistente
      case '14':
        const modalRefExpertUpdate = this.modalService.open(InvoiceExpertUpdateComponent, {
          windowClass: 'basic',
          size: 'lg'
        });
        modalRefExpertUpdate.componentInstance.invoice = invoice;

        modalRefExpertUpdate.result.then((result) => {
          if (result) {
            this.goToPage(this.currentPage);
          }
        });
        break;

      // Piu causali collegate aperte
      case '18':
        const modalRefTypeoflossList = this.modalService.open(InvoiceTypeoflossListComponent, {
          windowClass: 'basic',
          size: 'lg'
        });
        modalRefTypeoflossList.componentInstance.invoice = invoice;

        modalRefTypeoflossList.result.then((result) => {
          if (result) {
            this.goToPage(this.currentPage);
          }
        });
        break;

      default:
        break;
    }

  }

  rejectDetails(invoiceDetails: InvoiceDetails) {
  }

  rejectExpert(invoice: Invoice) {
  }

  uploadDetails(invoice: Invoice) {

    const modalRefUpload = this.modalService.open(InvoiceUploadComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRefUpload.componentInstance.invoice = invoice;

    modalRefUpload.result.then((result) => {
      if (result) {
        this.goToPage(this.currentPage);
      }
    });


  }

  hasResult() {
    if (this.invoices && this.invoices.length > 0) {
      return true;
    }
    return false;
  }

  back() {
    const session = this.getCurrentSession();
    this.sessionServiceInject.remove(session.idSession);
  }

  getCurrentSession() {
    return this.sessionServiceInject.list().find(el => el.isActive);
  }

  goToPage(pageNumber: number) {

    this.emitter.broadcastEvent('start-loader');
    this.filterInvoice.pager.pageNumber = pageNumber;

    this.invoiceService.getInvoicesList(this.filterInvoice)
      .subscribe((response: any) => {

        this.invoices = response.invoiceList;
        this.invoicesFiltered = this.invoicesfiltering(this.invoices);
        this.currentPage = response.currentPage;

        if (this.invoices && this.invoices.length > 0) {
        } else {
          this.translateService.translate('_CLAIMS_._NO_RESULT').subscribe(res=> {
            this.errorMessage=res;
          }
          )
        }
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._MESSAGE_._SEARCH_ERROR').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.emitter.broadcastEvent('stop-loader');
        }
      );
  }

  isManagedRejectType(rejectCode: string) {

    let managedRejectType = false;

    switch (rejectCode) {

      // manca sinistro
      case '1':
      // fattura inconsistente
      case '9':
      // dati di fattura non coerenti
      case '10':
      // Costo sinistro incoerente con fattura
      case '12':
      // Anagrafica inconsistente
      case '14':
      // più causali di danno collegate aperte
      case '18':
      // fattura non autorizzata
      case '28':
        managedRejectType = true;
        break;

      default:
        break;
    }

    return managedRejectType;
  }

  // se invoice non ha rejecttype, gli metto quello del primo details a scarto e su questo lo elimino,
  // cosi facendo non avremo la riga vuota sull'invoice e non avremo ripetizione
  invoicesfiltering(invoices: Invoice[]) {

    const invoicesFilteredInMethod: Invoice[] = [];

    for (const invoiceElement of invoices) {

      if (!invoiceElement.rejectType) {
        this.getAndRemoveFirstDetailRejectType(invoiceElement);
      }

      invoicesFilteredInMethod.push(invoiceElement);
    }

    return invoicesFilteredInMethod;
  }

  getAndRemoveFirstDetailRejectType(invoice: Invoice) {

    const rejectTypeRetrieved: EnumType = new EnumType();
    const statusRetrieved: EnumType = new EnumType();

    for (const invoiceDetailsElement of invoice.invoiceDetailsList) {
      if (invoiceDetailsElement.rejectType) {
        rejectTypeRetrieved.codice = invoiceDetailsElement.rejectType.codice;
        rejectTypeRetrieved.descrizione = invoiceDetailsElement.rejectType.descrizione;
        statusRetrieved.codice = invoiceDetailsElement.status.codice;
        statusRetrieved.descrizione = invoiceDetailsElement.status.descrizione;
        break;
      }
    }

    invoice.rejectType = rejectTypeRetrieved;
    invoice.status = statusRetrieved;
  }

  private invoiceOrderStatusHasPriority(invoice: Invoice): boolean {

    if (invoice.rejectType != null &&
      (invoice.status.codice === '1' ||
        invoice.status.codice === '4' ||
        invoice.status.codice === '3' ||
        invoice.status.codice === '9')) {
      return true;
    }

    if (invoice.invoiceExpert.rejectType !== null &&
      (invoice.invoiceExpert.status.codice === '1' ||
        invoice.invoiceExpert.status.codice === '4' ||
        invoice.invoiceExpert.status.codice === '3' ||
        invoice.invoiceExpert.status.codice === '9')) {
      return true;
    }

    for (const det of invoice.invoiceDetailsList) {
      if (det.rejectType !== null &&
        (det.status.codice === '1' ||
          det.status.codice === '4' ||
          det.status.codice === '3' ||
          det.status.codice === '9')) {
        return true;
      }
    }

    return false;
  }

  private invoiceDetOrderRejectHasPriority(det: InvoiceDetails): boolean {
    if (det.rejectType !== null &&
      (det.rejectType.codice === '12' ||
        det.rejectType.codice === '9' ||
        det.rejectType.codice === '10' ||
        det.rejectType.codice === '1' ||
        det.rejectType.codice === '18' ||
        det.rejectType.codice === '15' ||
        det.rejectType.codice === '28' ||
        det.rejectType.codice === '16')) {
      return true;
    }

    return false;
  }

}

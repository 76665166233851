import {MeansOfPaymentConfigInput} from './../../models/means-of-payment-config-input';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

import {PolicyContacts} from '../../models/domain-models/policy-contacts';
import {
  ReIssueProposalManagementState
} from '../re-issue-state-manager/re-issue-proposal-management-state-manager.service';
import {PolicyAddress} from '../../models/domain-models/policy-address';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {PolicyNumber} from '../../models/policyNumber';
import {BankAccountDetailsResponse} from '../../models/bank-account-details-response';
import {BankAccountDetailsRequest} from '../../models/bank-account-details-request';
import {
  REST_API_CONTACT_CONF,
  REST_API_POLICY_DATA_CONF,
  REST_API_PORTFOLIO_PC_CONF
} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueProposalManagementResourceService<T extends ReIssueProposalManagementState> {
  private POLICY_PATH = '/policy';
  private BANK_ACCOUNT_DETAILS_PATH = '/banca/controlloCoordinateBancarie';

  protected apiContactConf: any;
  protected apiPolicyDataConf: any;
  protected apiPcPortfolioConf: any;

  constructor(
    protected http: HttpClient,
    @Inject('environment') private apiConf: any,
  ) {
    this.apiContactConf = REST_API_CONTACT_CONF;
    this.apiPolicyDataConf = REST_API_POLICY_DATA_CONF;
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;
  }

  meansOfPaymentPost(paymentConfigInput: MeansOfPaymentConfigInput): Observable<any> {
    return this.http.post<any>(this.meansOfPaymentUri(), paymentConfigInput).pipe(
      share()
    );
  }

  policyPost(resourceId: string): Observable<PolicyNumber> {
    return this.http.post<PolicyNumber>(this.policyPostUri(resourceId), null).pipe(
      share()
    );
  }

  policyPostUri(resourceId: string): string {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + resourceId + this.POLICY_PATH;
  }

  meansOfPaymentUri(): string {
    return this.apiConf.api.portal.path + '/admin/meansofpayment';
  }

  validateBankAccountDetails(bankAccountDetailsRequest: BankAccountDetailsRequest): Observable<BankAccountDetailsResponse> {
    return this.http.get<BankAccountDetailsResponse>(this.validateBankAccountDetailsUri(bankAccountDetailsRequest));
  }

  validateBankAccountDetailsUri(bankAccountDetailsRequest: BankAccountDetailsRequest): string {

    if (bankAccountDetailsRequest.operazione !== '04') {
      return this.apiConf.api.portal.path +
        this.apiPolicyDataConf.pathV2 +
        this.BANK_ACCOUNT_DETAILS_PATH +
        '?operazione=' +
        bankAccountDetailsRequest.operazione +
        '&abi=' +
        bankAccountDetailsRequest.abi +
        '&cab=' +
        bankAccountDetailsRequest.cab +
        '&conto=' +
        bankAccountDetailsRequest.conto +
        '&tipoConto=' +
        bankAccountDetailsRequest.tipoconto +
        '&fraz=' +
        bankAccountDetailsRequest.fraz +
        '&prodotto=' +
        bankAccountDetailsRequest.prodotto +
        '&contraente=' +
        bankAccountDetailsRequest.contraente +
        '&Numproposta=' +
        bankAccountDetailsRequest.Numproposta +
        '&canale=&compagnia=' +
        bankAccountDetailsRequest.compagnia +
        '&spt='
        + bankAccountDetailsRequest.spt;
    } else {
      // aggiunto else per funzionamento corretto del operazione 4 (solo campi obbligatori)
      return this.apiConf.api.portal.path +
        this.apiPolicyDataConf.pathV2 +
        this.BANK_ACCOUNT_DETAILS_PATH +
        '?operazione=' +
        bankAccountDetailsRequest.operazione +
        '&conto=' +
        bankAccountDetailsRequest.conto +
        '&compagnia=' +
        bankAccountDetailsRequest.compagnia +
        '&iban=' +
        bankAccountDetailsRequest.iban;
    }
  }

  // tslint:disable-next-line:max-line-length
  putContacts$(st: T, addressList: AddressApiContacts[], mainAddress: AddressApiContacts, mail: any, postalMailChecked: boolean): Observable<PolicyContacts> {
    const array: PolicyAddress[] = [];
    addressList.forEach(val => {
      let policyAddress;
      if (val.formatAddress !== mainAddress.formatAddress) {
        policyAddress = new PolicyAddress(val, false, null);
      } else {
        policyAddress = new PolicyAddress(val, true, null);
      }
      array.push(policyAddress);
    });
    const phoneNumber = st.subject && st.subject.mobilePhone && st.subject.mobilePhone[0] ? st.subject.mobilePhone[0].number : null;
    const mailSelected = st.subject && st.subject.emails && st.subject.emails[0] ? st.subject.emails[0] : null;

    const body: PolicyContacts = new PolicyContacts(null, null, null, array, phoneNumber, mail, null, postalMailChecked);

    return this.http.post<PolicyContacts>(this.postContactsUri(st.resourceId), body);
  }

  protected postContactsUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      proposalId +
      '/contacts';
  }

  getContacts$(st: T): Observable<PolicyContacts> {
    return this.http.get<PolicyContacts>(this.getContactsUri(st.resourceId));
  }

  protected getContactsUri(resourceId: string): string {
    return this.apiConf.api.portal.path + this.apiContactConf.path + resourceId + this.apiContactConf.contactPath;
  }
}

<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header">
    <span class="anag-icon-m rgi-ui-icon-user"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1">{{modalTitle}}</span>
    <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
  </div>
  <rgi-rx-routing-outlet [route]="currentRoute" [data]="routingData" *ngIf="routingData">
  </rgi-rx-routing-outlet>
</div>


import {Observable} from 'rxjs';
import {InjectionToken, Injector} from '@angular/core';
import {LogLevel, RgiRxInterpolationParamsType} from '@rgi/rx';

export type RGI_RX_LOCALE = 'it' | 'en' | 'fr' | 'de' | 'es' | string;

export interface TranslationService {
  readonly currentLanguage: RGI_RX_LOCALE;

  setCurrentLanguage(locale: RGI_RX_LOCALE);

  getLanguageDescription(locale: RGI_RX_LOCALE): string;

  translate(value: string, params?: RgiRxInterpolationParamsType): Observable<string | null>;

  translateAll(...keys: string[]): Observable<string[]>;

  translateAll(...keyParams: RgiRxKeyInterpolationRef[]): Observable<string[]>;

  getLanguageChange$(): Observable<RGI_RX_LOCALE>;

  getRegisteredTranslations(): RGI_RX_LOCALE[];
}


export interface TranslationCatalog {
  add(key: string, value: string, locale: RGI_RX_LOCALE);

  replace(value: string, key: string, locale: RGI_RX_LOCALE);

  load(data: RgiRxSerializedI18n, locale: RGI_RX_LOCALE);

  getKey$(key: string, locale: RGI_RX_LOCALE): Observable<string | undefined>;

  getCatalog$(locale: RGI_RX_LOCALE): Observable<RgiRxSerializedI18n>;
}

export interface RgiRxTranslation {
  load: (injector?: Injector) => RgiRxi18nModuleLoadType;
  locale: RGI_RX_LOCALE;
}


export interface RgiRxi18nModuleDef {
  data: RgiRxi18nModuleLoadType;
  locale: RGI_RX_LOCALE;
}

export type RgiRxi18nModuleLoadType = Promise<RgiRxSerializedI18n>;

export type RgiRxTranslations = RgiRxTranslation[];


export type Catalog = {
  [key in RGI_RX_LOCALE]: RgiRxSerializedI18n;
};

export interface RgiRxI18nConfig {
  /**
   * The default locale to be used within the application
   */
  defaultLocale: RGI_RX_LOCALE;
  /**
   * if true the registered locales will list only the ones with a configured translation in all the modules with RgiRxTranslations
   */
  completeLocales?: boolean;
  /**
   * if true the registered locales will list only the ones with a configured translation in all the modules with RgiRxTranslations
   */
  localesOverride?: RGI_RX_LOCALE[];

  /**
   * Enable missing translation log
   */
  logMissingTranslations?: boolean;
}

/**
 * @deprecated use RgiRxI18nConfig
 * @see RgiRxI18nConfig
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RgiRx18nConfig extends RgiRxI18nConfig{}

export const RGI_RX_I18N_CONFIG = new InjectionToken<RgiRxI18nConfig>('RGI_RX_I18N_CONFIG');

export const RGI_RX_I18N_DEFAULT_CONFIG: RgiRxI18nConfig = {
  defaultLocale: 'en',
  completeLocales: true,
  logMissingTranslations: true
};

export const RGI_RX_TRANSLATIONS = new InjectionToken<RgiRxTranslations>('RGI_RX_TRANSLATIONS');

export const RGI_RX_CHILDREN_TRANSLATIONS = new InjectionToken<RgiRxTranslations>('RGI_RX_CHILDREN_TRANSLATIONS');


export interface RgiRxSerializedI18n {
  [key: string]: string | RgiRxSerializedI18n;
}


/**
 * @deprecated use RgiRxSerializedI18n
 * @see RgiRxSerializedI18n
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Serialized18n extends RgiRxSerializedI18n {}


export interface RgiRxKeyInterpolationRef {
  key: string;
  params?: RgiRxInterpolationParamsType;
}

export interface CatalogGetOptions {
  interpolation?: RgiRxInterpolationParamsType;
}


export class RgiRxI18nError extends Error {
  constructor(message: string, public context: { node?: any, locale?: RGI_RX_LOCALE, key?: string }) {
    super(`RgiRxI18n error: ${message}`);
  }
}


/**
 * @description options for the language change
 */
export interface RgiRxI18nLanguageChangeOpts {
  /**
   * @description force the load and notification of the language change even if the language is the same
   */
  force: boolean
}



<div class="grid select-box"  [attr.reuired]="config?.compulsory">
  <form [formGroup]="group">
    <rgi-rx-form-field>
      <label rgiRxLabel>
        <lpc-help-icon [helpFile]="config.helpFile" [title]="value"></lpc-help-icon>
        <span>{{value}}</span>
      </label>
      <select rgiRxNativeSelect [formControlName]="config.code"
            (change)="onChange()"
            [attr.data-qa]="'factor' + config.code"
            [attr.data-mod] = "'factor' + config.code + modifiable"
            [attr.data-obb] = "'factor' + config.code + mandatory">
      <option *ngFor="let option of options" [value]="option.value">{{ option.description }}</option>
    </select>
    </rgi-rx-form-field>
  </form>
</div>

import { ClaimsAddress } from "./dsc-model-address";

export class CarBodywork {
    objectId: string = null;
    designation: string = '';
    givenName: string = '';
    surname: string = '';
    fiscalCode: string = '';
    vatCode: string = '';
    phoneNumber: string = '';
    email: string = '';
    code: string = null;
    claimsAddress: ClaimsAddress = new ClaimsAddress();
}
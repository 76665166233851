import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiClaimsListFilter } from './api/api-claims-list-filter';
import { ApiInvoiceFilter } from './api/api-invoice-filter';
import { Invoice } from './dto/invoice';
import { ModifyNdCLegalInputItem } from './dto/modify-ndc-legal-input';

@Injectable({
  providedIn: 'root'
})

export class InvoiceService {

  private sessioninvoices: Invoice[][];
  private filterInvoice: ApiInvoiceFilter[];
  private totalPages: number[];
  private baseApiUrlV2: string;
  private urlInvoiceList = '/claims/invoiceList';
  private urlInvoiceSave = '/claims/invoiceSave';
  private urlInvoiceSaveProforma = '/claims/invoiceSaveProforma';
  private urlClaimsList = '/claims/claimsList';
  private urlInvoiceExperts = '/claims/invoiceExpertList';
  private urlInvoiceRejectTypes = '/claims/invoiceRejectTypeList';
  private urlTypeoflossList = '/claims/getTypeofloss/list/';
  private urlClaimsHandlers = '/claims/claimsHandlers';
  private urlModifyNdCLegal = '/claims/modifyNdCLegal';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  setSessionInvoices(sessionId: string, invoices: Invoice[]) {
    if (!this.sessioninvoices) {
      this.sessioninvoices = [];
    }
    this.sessioninvoices[sessionId] = invoices;
  }

  getSessionInvoices(sessionId: string): Invoice[] {
    if (this.sessioninvoices) {
      return this.sessioninvoices[sessionId];
    }
  }

  setSessionInvoiceFilter(sessionId: string, invoiceFilter: ApiInvoiceFilter) {
    if (!this.filterInvoice) {
      this.filterInvoice = [];
    }
    this.filterInvoice[sessionId] = invoiceFilter;
  }

  getSessionInvoiceFilter(sessionId: string): ApiInvoiceFilter {
    if (this.filterInvoice) {
      return this.filterInvoice[sessionId];
    }
  }

  setSessionInvoiceTotalPages(sessionId: string, totalPages: number) {
    if (!this.totalPages) {
      this.totalPages = [];
    }
    this.totalPages[sessionId] = totalPages;
  }

  getSessionInvoiceTotalPages(sessionId: string): number {
    if (this.totalPages) {
      return this.totalPages[sessionId];
    }
  }

  getInvoicesList(req: any) {
    return this.httpClient.post(this.baseApiUrlV2 + this.urlInvoiceList, req);
  }

  validateDate(controlName: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];

      const min: Date = new Date(1990, 1, 1);

      if (control.value) {
        if (control.value instanceof Date) {

          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if (inputDate > currentDate) {
            control.setErrors({ futureDate: true });
          } else if (inputDate < min) {
            control.setErrors({ tooOld: true });
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({ invalidDate: true });
        }
      } else {
        const validator: any = control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  saveInvoice(invoice: Invoice, rejectTypeWorked: string) {
    // remove invoice.expanded why is mapped and used only for front end accordion management;
    delete invoice.expanded;

    const queryString = '?rejectTypeWorked=' + rejectTypeWorked;

    return this.httpClient.post(this.baseApiUrlV2 + this.urlInvoiceSave + queryString, invoice);
  }

  getExperts() {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlInvoiceExperts);
  }

  getRejectTypes() {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlInvoiceRejectTypes);
  }

  getClaimsList(apiClaimsListFilter: ApiClaimsListFilter) {
    return this.httpClient.post(this.baseApiUrlV2 + this.urlClaimsList, apiClaimsListFilter);
  }

  modifyNdCLegal(modifyNdCLegalInputItem: ModifyNdCLegalInputItem) {
    return this.httpClient.post(this.baseApiUrlV2 + this.urlModifyNdCLegal, modifyNdCLegalInputItem);
  }

  getClaimsHandlers() {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlClaimsHandlers);
  }

  getTypeoflossList(idDetail: string) {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlTypeoflossList + 'idDetail/' + idDetail);
  }

  round(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  formatNumber(field: string) {
    return parseFloat(String(field).replace(',', '.'));
  }

  saveInvoiceProforma(invoice: Invoice) {
    return this.httpClient.post(this.baseApiUrlV2 + this.urlInvoiceSaveProforma, invoice);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RgiQuotationHeaderData} from '../../resources/model/common/quotation/quotation-header-data';
import {PNC_PSALES_ACTIONS} from '../../resources/constants/actions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rgi-pnc-quotation-header',
  templateUrl: './pnc-quotation-header.component.html'
})
export class PncQuotationHeaderComponent {

  @Input() data: RgiQuotationHeaderData;
  @Output() onButtonAction: EventEmitter<PNC_PSALES_ACTIONS | string> = new EventEmitter<PNC_PSALES_ACTIONS | string>();

  onBtnAction(action: PNC_PSALES_ACTIONS | string) {
    this.onButtonAction.emit(action);
  }
}

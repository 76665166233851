import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DragActionExtDirective} from './group-policy-ext-directive/drag-action-ext.directive';


@NgModule({
  declarations: [DragActionExtDirective],
  imports: [
    CommonModule
  ],
  exports: [DragActionExtDirective]
})
export class GroupPolicyDirectiveModule { }

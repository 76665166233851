import {ActiveRoute, Routes} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {RgiRxEventService} from '@rgi/rx';
import {
  ROUTES_DETAIL,
  ROUTES_FULL_DETAIL,
  ROUTES_HOME,
  ROUTES_KEY_DATA,
  ROUTES_MODAL_HOME,
  ROUTES_MODAL_RESULT, ROUTES_MODAL_RESULT_HOMONYMY,
  ROUTES_PARTY_EDITOR,
  ROUTES_RESULT, ROUTES_RESULT_HOMONYMY
} from './anag-constants/routes-constants';
import {AnagHomeComponent} from './anag-flow/home/anag-home.component';
import {AnagStateManagerHome} from './anag-states/home/anag-state-manager-home';
import {AnagStatelessOpHomeService} from './anag-states/home/anag-stateless-op-home.service';
import {AnagResultComponent} from './anag-flow/result/anag-result.component';
import {AnagStateManagerResult} from './anag-states/result/anag-state-manager-result';
import {AnagStatelessOpResultService} from './anag-states/result/anag-stateless-op-result.service';
import {AnagPartyEditorComponent} from './anag-flow/party-editor/anag-party-editor.component';
import {AnagStateManagerPartyEditor} from './anag-states/party-editor/anag-state-manager-party-editor';
import {AnagStatelessOpPartyEditorService} from './anag-states/party-editor/anag-stateless-op-party-editor.service';
import {AnagPartyKeyDataComponent} from './anag-components/party-create-key-data/anag-party-key-data.component';
import {AnagStateManagerKeyData} from './anag-states/key-data/anag-state-manager-key-data';
import {AnagStatelessOpKeyDataService} from './anag-states/key-data/anag-stateless-op-key-data.service';
import {AnagStorageService} from './anag-resources/anag-storage.service';
import {AnagStatelessService} from './anag-resources/anag-stateless.service';
import {AnagIntegrationService} from './services/anag-integration.service';
import {ModalService} from '@rgi/rx/ui';
import {AnagDetailComponent} from './anag-flow/detail/anag-detail.component';
import {AnagStateManagerDetail} from './anag-states/detail/anag-state-manager-detail';
import {AnagStatelessOpDetailService} from './anag-states/detail/anag-stateless-op-detail.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagConfigService} from './anag-resources/anag-config.service';
import {RgiRxUserService} from '@rgi/rx/auth';
import {AnagResultHomonymyComponent} from './anag-flow/result-homonymy/anag-result-homonymy.component';
import {AnagStateManagerResultHomonymy} from './anag-states/result-homonymy/anag-state-manager-result-homonymy';
import {AnagStatelessOpResultHomonymyService} from './anag-states/result-homonymy/anag-stateless-op-result-homonymy.service';

export const ANAG_ROUTE_HOME = {
  route: ROUTES_HOME,
  component: AnagHomeComponent,
  providers: [{
    provide: AnagStateManagerHome,
    useClass: AnagStateManagerHome,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpHomeService, RgiRxEventService, AnagStorageService,
      AnagIntegrationService, ModalService, AnagStatelessService, AnagConfigService, RgiRxTranslationService]
  }, {
    provide: AnagStateManagerKeyData,
    useClass: AnagStateManagerKeyData,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpKeyDataService, RgiRxEventService, AnagStorageService,
      AnagStatelessService, AnagConfigService]
  }]
};
export const ANAG_ROUTE_MODAL_HOME = {
  route: ROUTES_MODAL_HOME,
  component: ANAG_ROUTE_HOME.component,
  providers: ANAG_ROUTE_HOME.providers
};

export const ANAG_ROUTE_RESULT = {
  route: ROUTES_RESULT,
  component: AnagResultComponent,
  providers: [{
    provide: AnagStateManagerResult,
    useClass: AnagStateManagerResult,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpResultService, RgiRxEventService, AnagStorageService,
      AnagIntegrationService, ModalService, AnagStatelessService, RgiRxTranslationService]
  }, {
    provide: AnagStateManagerKeyData,
    useClass: AnagStateManagerKeyData,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpKeyDataService, RgiRxEventService, AnagStorageService,
      AnagStatelessService, AnagStatelessService, AnagConfigService]
  }]
};

export const ANAG_ROUTE_MODAL_RESULT = {
  route: ROUTES_MODAL_RESULT,
  component: ANAG_ROUTE_RESULT.component,
  providers: ANAG_ROUTE_RESULT.providers
};

export const ANAG_ROUTE_RESULT_HOMONYMY = {
  route: ROUTES_RESULT_HOMONYMY,
  component: AnagResultHomonymyComponent,
  providers: [{
    provide: AnagStateManagerResultHomonymy,
    useClass: AnagStateManagerResultHomonymy,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpResultHomonymyService, RgiRxEventService, AnagStorageService,
      AnagIntegrationService, ModalService, AnagStatelessService, RgiRxTranslationService]
  }, {
    provide: AnagStateManagerKeyData,
    useClass: AnagStateManagerKeyData,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpKeyDataService, RgiRxEventService, AnagStorageService,
      AnagStatelessService, AnagStatelessService, AnagConfigService]
  }]
};

export const ANAG_ROUTE_MODAL_RESULT_HOMONYMY = {
  route: ROUTES_MODAL_RESULT_HOMONYMY,
  component: ANAG_ROUTE_RESULT_HOMONYMY.component,
  providers: ANAG_ROUTE_RESULT_HOMONYMY.providers
};

export const ANAG_ROUTE_DETAIL = {
  route: ROUTES_DETAIL,
  component: AnagDetailComponent,
  providers: [{
    provide: AnagStateManagerDetail,
    useClass: AnagStateManagerDetail,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpDetailService, RgiRxEventService, AnagIntegrationService,
      ModalService, AnagStatelessService, AnagConfigService, RgiRxTranslationService]
  }, {
    provide: AnagStateManagerKeyData,
    useClass: AnagStateManagerKeyData,
    deps: [ActiveRoute, StateStoreService, AnagStatelessOpKeyDataService, RgiRxEventService, AnagStorageService,
      AnagStatelessService, AnagConfigService]
  }]
};

export const ANAG_ROUTE_PARTY_EDITOR = {
  route: ROUTES_PARTY_EDITOR,
  component: AnagPartyEditorComponent,
  providers: [
    {
      provide: AnagStateManagerPartyEditor,
      useClass: AnagStateManagerPartyEditor,
      deps: [ActiveRoute, StateStoreService, AnagStatelessOpPartyEditorService, AnagStorageService, AnagStatelessService,
        AnagIntegrationService, ModalService, RgiRxTranslationService, AnagConfigService, RgiRxUserService, RgiRxEventService]
    }, {
      provide: AnagStateManagerKeyData,
      useClass: AnagStateManagerKeyData,
      deps: [ActiveRoute, StateStoreService, AnagStatelessOpKeyDataService, RgiRxEventService, AnagStorageService,
        AnagStatelessService, AnagConfigService, RgiRxUserService]
    }
  ]
};

export const ANAG_ROUTE_KEY_DATA = {
  route: ROUTES_KEY_DATA,
  component: AnagPartyKeyDataComponent,
  providers: [{
    provide: AnagStateManagerKeyData,
    useClass: AnagStateManagerKeyData,
    deps: [ActiveRoute,
      StateStoreService,
      AnagStatelessOpKeyDataService,
      RgiRxEventService,
      AnagStorageService,
      AnagStatelessService,
      AnagConfigService]
  }]
};

export const ANAG_ROUTE_FULL_DETAIL = {
  route: ROUTES_FULL_DETAIL,
  component: ANAG_ROUTE_PARTY_EDITOR.component,
  providers: ANAG_ROUTE_PARTY_EDITOR.providers
};

export const ANAG_ROUTES: Routes = [
  ANAG_ROUTE_HOME,
  ANAG_ROUTE_MODAL_HOME,
  ANAG_ROUTE_RESULT,
  ANAG_ROUTE_MODAL_RESULT,
  ANAG_ROUTE_RESULT_HOMONYMY,
  ANAG_ROUTE_MODAL_RESULT_HOMONYMY,
  ANAG_ROUTE_DETAIL,
  ANAG_ROUTE_PARTY_EDITOR,
  ANAG_ROUTE_KEY_DATA,
  ANAG_ROUTE_FULL_DETAIL
];

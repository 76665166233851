import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventService, RgiRxEventService} from './rgi-rx-event.service';
import {EVENTS, Events} from './event-api';
import {RgiRxLoggingModule} from '../logging/rgi-rx-logging.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RgiRxLoggingModule
  ],
  exports: [],
  providers: [
    {
      provide: EventService,
      useExisting: RgiRxEventService
    }
  ]
})
export class RgiRxEventModule {
  static forRoot(config: Events): ModuleWithProviders<RgiRxEventModule> {
    return {
      ngModule: RgiRxEventModule,
      providers: [
        {provide: EVENTS, useValue: config, multi: true},
        {
          provide: EventService,
          useExisting: RgiRxEventService
        }
      ]
    };
  }

}

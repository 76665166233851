import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {RoutableComponent} from '../../routable-component';
import {EventNotificator} from '../../event-notificator';
import {Message} from '../../models/message';
import {ProposalService} from '../../proposal.service';
import {LiensService} from './liens.service';
import {TranslateService} from '@ngx-translate/core';
import {ParameterService} from '../../quotation/parameters/parameters.service';
import {Lien} from '../../models/domain-models/lien';
import {LienComponent} from './lien/lien.component';
import {CustomModalService} from '../../custom-modal.service';

@Component({
  selector: 'mic-liens',
  templateUrl: './liens.component.html',
  styleUrls: ['./liens.component.scss']
})
export class LiensComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() valuesChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() validationMessagesBond: EventEmitter<Array<Message>> = new EventEmitter<Array<Message>>();

  @ViewChild('lienModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) lienModalViewContainerRef: ViewContainerRef;
  componentRef: any;

  liensList: Array<Lien> = new Array<Lien>();
  calculateBond = false;

  validationMessages: Message[] = [];
  areaCode = 'LIENS';

  constructor(protected proposalService: ProposalService,
              protected modalService: CustomModalService,
              protected liensService: LiensService,
              protected translate: TranslateService,
              protected parametersService: ParameterService) {
  }

  ngOnInit() {
    this.parametersService.retrievePolicyTechnicalData(this.proposalService.getContractId()).subscribe(
      (data) => {
        if (data.constraint) {
          this.initLiens();
        }
      }
    );
  }

  refreshLiens() {
    this.liensList.length = 0;
    Array.prototype.push.apply(this.liensList, this.liensService.getLiensList());

    this.liensChanged();
  }

  validate() {
    const filtered = this.validationMessages.filter((msg: Message) =>
      msg.area !== this.areaCode);

    this.validationMessages.length = 0;

    Array.prototype.push.apply(this.validationMessages, filtered);

    if (this.liensList.length === 0) {
      this.validationMessages.push(new Message(this.areaCode, 'There must be at least one lien'));
    }

    this.validationMessagesBond.emit(this.validationMessages);
  }

  removeLien(id: number) {
    // rimuovi dal service e dalla lista
    this.liensService.removeLien(id);
    this.refreshLiens();
  }

  addBond() {
    this.modalService.openModal(this.lienModalViewContainerRef,
      LienComponent, this.eventPropagation, () => {
        this.refreshLiens();
      });
  }

  ngOnDestroy() {
  }

  liensChanged() {
    this.valuesChanged.emit();
    this.validate();
  }

  private initLiens() {
    this.liensService.getLiens(this.proposalService.getContractId()).subscribe(
      (liens: Array<Lien>) => {
        if (liens.length > 0 && liens[0].effectiveDate && liens[0].expirationDate) {
          this.liensService.setLiensList(liens);
        }
        this.liensService.setBindingEntityMandatory(liens[0].bindingEntityMandatory);
        this.liensService.setEffectiveDateMandatory(liens[0].effectiveDateMandatory);
        this.liensService.setExpirationDateMandatory(liens[0].expirationDateMandatory);
        this.calculateBond = true;
        this.refreshLiens();
        this.validate();
      });
  }

}

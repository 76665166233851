import {InjectionToken, Type} from '@angular/core';
import {RgiPncPsalesStateManager} from './rgi-pnc-postsales-state.manager';
import {RgiPncPostsalesStepComponent} from './rgi-pnc-postsales-step.component';
import {PncPsalesRequiredDataComponent} from '../steps/required-data/required-data.component';
import {PncPsalesRequiredDataStateManager} from '../state-managers/required-data/required-data-state-manager.service';
import {PncPsalesSummaryComponent} from '../steps/summary/summary.component';
import {PncPsalesSummaryStateManager} from '../state-managers/summary/summary-state-manager.service';
import {PncPsalesConfirmationComponent} from '../steps/confirmation/confirmation.component';
import {PncPsalesConfirmationStateManager} from '../state-managers/confirmation/confirmation-state-manager.service';
import {PncPsalesPageStep} from '../resources/constants/steps';
import {PncPsalesMovementsComponent} from "../steps/movements/movements.component";
import {PncPsalesMovementsStateManager} from "../state-managers/movements/movements-state-manager.service";

/**
 * Describe a post sales operation step
 * @prop {PncPsalesPageStep} step - the generic step declartion. RGI_PNC_POSTSALES_STEPS contains the list of all the cpmmon steps
 * @prop {Type<RgiPncPsalesStateManager<any>>} manager - the state manager for the step, shpuld extend the step msnager or use the same one
 * @prop {Type<any>[]} deps - the list of dependencies for the manager, if any
 * @prop {string} stepLabel - the label to be displayed in the stepbar (i18n key)
 * @prop {any} context - the context to be passed to the step manager
 */
export interface OperationStepBase {
  step: PncPsalesPageStep;
  manager: Type<RgiPncPsalesStateManager<any>>;
  deps: Type<any>[];
  stepLabel: string;
  context?: any;
}

export interface OperationStepRequiredData extends OperationStepBase {
  component: Type<PncPsalesRequiredDataComponent>;
  manager: Type<PncPsalesRequiredDataStateManager>;
}

export interface OperationStepSummary extends OperationStepBase {
  component: Type<PncPsalesSummaryComponent>;
  manager: Type<PncPsalesSummaryStateManager>;
}

export interface OperationStepConfirmation extends OperationStepBase {
  component: Type<PncPsalesConfirmationComponent>;
  manager: Type<PncPsalesConfirmationStateManager>;
}

export type OperationStep =
  OperationStepBase
  | OperationStepRequiredData
  | OperationStepSummary
  | OperationStepConfirmation;

/**
 * Describe a post sales operation
 * @prop {string} type - the operation type
 * @prop {string} operationCode - the operation code
 * @prop {string} sessionTitle - the session title on the psssportal card
 * @prop {OperationStep[]} steps - the list of steps for the operation (ordered)
 */
export interface PostSalesOperation {
  type: string;
  operationCode?: string;
  sessionTitle: string;
  steps: OperationStep[];
}

/**
 * The injection token for the list of post sales operations, managed by the orchestrator and provided by the application
 */
export const PNC_POSTSALES_OPERATIONS = new InjectionToken<PostSalesOperation[]>('PNC_POSTSALES_OPERATIONS');
/**
 * The injection token for the context to be available inside the current route
 * @deprecated should be removed and replaced by the context provided by the @rgi/rx activeRoute
 */
export const PNC_PSALES_OP_CONTEXT = new InjectionToken<any>('PNC_PSALES_OP_CONTEXT');

import {Component, Input} from '@angular/core';

@Component({
  selector: 'lpc-static-label',
  templateUrl: './lpc-static-label.component.html',
  styleUrls: ['./lpc-static-label.component.css']
})
export class LpcStaticLabelComponent {

  @Input() label: string;
  @Input() value: string;

  constructor() { }

}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {NotificationService} from "./notification.service";
import {Toast} from "./toast-api";
import {URILocatorService} from "../environment/urilocator.service";

@Injectable()
export class ApiNotificationInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService, private uriLocator: URILocatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.uriLocator.isRelativeApiPath(req)) {
      return next.handle(req);
    }

    return next.handle(req)
      .pipe(map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

            if (event.body && !event.body.isSuccess && event.body.messages) {
              event.body.messages.forEach(msg => {
                const toast = new Toast();
                toast.header = "Warning"
                toast.status = "warning";
                toast.body = msg;
                this.notificationService.push(toast);
              })
            }
          }
          return event;
        }),
        catchError((err, caught) => {
          if (err instanceof HttpErrorResponse && err.status >= 500) {
            const toast = new Toast();
            toast.header = err.statusText;
            toast.status = "danger";
            toast.body = err.error.error ? err.error.error : err.error.message;
            this.notificationService.push(toast);
            if (err.error && err.error.messages) {
              err.error.messages.forEach(msg => {
                const messageToast = new Toast();
                messageToast.body = msg;
                messageToast.status = 'danger';
                this.notificationService.push(messageToast)
              })
            }
          }
          return throwError(err);
        })
      );
  }
}

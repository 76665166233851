import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxCalendarComponent} from './calendar/calendar.component';
import {RgiRxCalendarBodyComponent} from './calendar-body/calendar-body.component';
import {RgiRxCalendarYearViewComponent} from './calendar-year-view/calendar-year-view.component';
import {RgiRxCalendarMonthViewComponent} from './calendar-month-view/calendar-month-view.component';
import {RgiRxCalendarMultiYearViewComponent} from './calendar-multi-year/calendar-multi-year-view.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';


@NgModule({
  declarations: [
    RgiRxCalendarComponent,
    RgiRxCalendarBodyComponent,
    RgiRxCalendarMonthViewComponent,
    RgiRxCalendarYearViewComponent,
    RgiRxCalendarMultiYearViewComponent
  ],
  imports: [
    CommonModule,
    RgiRxI18nModule
  ],
  exports: [
    RgiRxCalendarComponent,
    RgiRxCalendarBodyComponent,
    RgiRxCalendarMonthViewComponent,
    RgiRxCalendarYearViewComponent,
    RgiRxCalendarMultiYearViewComponent
  ]
})
export class RgiRxCalendarModule {
}

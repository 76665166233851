import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {AuthorizationsStatesService} from '../services/authorizations-states.service';
import {AuthorizationsRoutingService} from '../routing/authorizations-routing.service';
import {AuthorizationsSearchService} from '../services/authorizations-search.service';
import {Authorization} from '../resources/models/authorization';
import {AuthorizationsActions} from '../resources/constants/authorizations-actions';
import {AuthorizationsStates} from '../resources/constants/authorizations-states';
import {RequestStatus} from '../resources/constants/request-status';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {DateTimeAdapter, RgiRxDatatableRowAction, TableSchema} from '@rgi/rx/ui';
import {Observable, of} from 'rxjs';
import {AUTHORIZATIONS_LIST_TABLE_SCHEMA} from '../resources/models/authorization-list-tableschema';
import {PolicyType} from '../resources/constants/policy-type';
import {ActiveRoute} from '@rgi/rx/router';
import {StateManagedComponent} from '@rgi/rx/state';
import {AuthorizationCardStateList} from '../authorization-card-state/authorization-card-state';
import {
    AuthorizationCardStateManagerList
} from '../authorization-card-state/authorization-card-statemanager-list';

@Component({
    selector: 'ac-authorizations-list',
    templateUrl: './authorizations-list.component.html',
    host: {
        class: 'rgi-authorizations-style'
    },
    providers: [AuthorizationsStatesService]
})
export class AuthorizationsListComponent extends StateManagedComponent<AuthorizationCardStateList, AuthorizationCardStateManagerList> implements OnInit, OnDestroy {

    @Output() navigation: EventEmitter<string> = new EventEmitter<string>();


    paginationForm: UntypedFormGroup;

    authorizationList: any[];
    submitted = false;
    sortProp = 'requestCode';
    reverse = true;

    recordNumber = 10;
    start = 1;
    end = 10;
    page = 1;
    isSubCard = true;

    public authorizationListTableData: Observable<any[]>;
    /**
     * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
     * since the compiler fail to reference the import correctly ang generates:
     * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
     * Hence the ngcc linker fails.
     * Specify the type : TableSchema to prevent this issue.
     * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
     * Eventually transform those constants in factories or the clone the reference because this is another code smell,
     * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
     * the schema!
     */
    public AUTHORIZATIONS_LIST_TABLE_SCHEMA: TableSchema = AUTHORIZATIONS_LIST_TABLE_SCHEMA;


    constructor(protected stateService: AuthorizationsStatesService,
                protected manager: AuthorizationCardStateManagerList,
                public activeRoute: ActiveRoute,
                protected routingService: AuthorizationsRoutingService,
                protected authorizationsSearchService: AuthorizationsSearchService,
                protected formBuilder: UntypedFormBuilder,
                protected translate: RgiRxTranslationService,
                @Inject(DateTimeAdapter) protected dateTimeAdapter: any) {
        super(manager);
    }

    ngOnInit() {

        this.stateService.setCurrentState(AuthorizationsStates.LIST);
        this.routingService.register(this.navigation);


        const list = this.authorizationsSearchService.getFilteredAuthorizations();
        this.authorizationList = JSON.parse(JSON.stringify(list));

        this.authorizationListTableData = of(this.authorizationList.map(auth => {

            return {
                authId: auth.id,
                authCode: auth.requestCode,
                authRequestType: auth.requestType.description,
                authContractType: auth.policyType ? auth.policyType.description : null,
                authOperation: auth.operation,
                authContractNumber: auth.policyNumber ? auth.policyNumber : auth.proposalNumber,
                authDescription: this.evaluteDescription(auth),
                authReference: this.evaluateReference(auth),
                authLastEvent: auth.lastEvent.description,
                authRequestDate: auth.requestDate,
                authNodeCode: auth.requestNode.code,
            };
        }));


        this.authorizationList.forEach(element => {
            if (element.requestType.code) {
                element.requestTypeDescr = element.requestType.description;
                element.requestType = element.requestType.code;
            }
            if (element.lastEvent.description) {
                element.lastEvent = element.lastEvent.description;
            }
            if (element.state && element.state.id === RequestStatus.CANCELED) {
                element.lastEvent = 'Richiesta Annullata';
            }
            if (element.requestNode.code) {
                element.requestNodeDescr = element.requestNode.description;
                element.requestNode = element.requestNode.code;
            }
            if (element.policyNumber) {
                element.contractNumber = element.policyNumber;
            } else {
                element.contractNumber = element.proposalNumber;
            }
        });


        this.sortBy(this.sortProp, this.reverse);
        this.paginationForm = this.formBuilder.group(
            {
                recordsNum: 10,
            }
        );
        this.paginationForm.controls.recordsNum.valueChanges.subscribe((val: number) => {
            this.recordNumber = val;
            this.sortBy(this.sortProp, this.reverse);
            this.updateVisualizationDetail();
        });

        this.updateVisualizationDetail();
    }

    removeSession() {
        this.stateService.resetState(this.activeRoute.id);
        this.submitted = false;
        this.manager.removeSession(this.activeRoute.id);
    }

    selectAuthorization(auth: any) {
        this.authorizationsSearchService.getAuthorizationDetailById(auth.authId).subscribe(
            (item) => {
                this.authorizationsSearchService.setAuthorization(item);
                this.stateService.nextState(this.activeRoute.id, this.navigation, AuthorizationsActions.DETAIL);
            });
    }

    updateVisualizationDetail() {
        this.start = 1 + (Number(this.page) * Number(this.recordNumber)) - Number(this.recordNumber);
        const end = (Number(this.recordNumber) + Number(this.start)) - 1;
        this.end = end > this.authorizationList.length ? this.authorizationList.length : end;
    }

    sortBy(sortProp: string, reverse: boolean) {
        this.reverse = reverse;
        this.sortProp = sortProp;
        this.authorizationList.sort((a, b) => {
          const aProp = a[sortProp];
          const bProp = b[sortProp];

          if (aProp.includes('/')) {
            const aPropDate = a[sortProp].split('/');
            const bPropDate = b[sortProp].split('/');
            const aDate = new Date(aPropDate[2], parseInt(aPropDate[1], 10) - 1, aPropDate[0]);
            const bDate = new Date(bPropDate[2], parseInt(bPropDate[1], 10) - 1, bPropDate[0]);
            return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
          }

          return (aProp < bProp) ? -1 : (aProp > bProp) ? 1 : 0;
        });

        if (this.reverse) {
          this.authorizationList = this.authorizationList.reverse();
        }
      }

    ngOnDestroy() {
        this.stateService.resetState(this.activeRoute);
    }

    public onActionClick($event: RgiRxDatatableRowAction<any>) {
        switch ($event.name) {
            case 'OPEN':
                const selectedAuth = this.authorizationList.find(auth => auth.requestCode === $event.row.authCode);
                this.selectAuthorization(selectedAuth);
                break;
        }
    }

    getDetailModel(ctx: any) {
        return this.authorizationList.find(auth => auth.requestCode === ctx.authCode);
    }

    evaluateReference(auth: Authorization) {
        let label;
        if (!! auth.policyType && (PolicyType.CHILD === auth.policyType.code)) {
            if (auth.masterPolicyNumber) {
                this.translate.translate('_AUTH_._PM_POLICY_NUMBER').subscribe(text => label = text);
                return label.concat(' ').concat(auth.masterPolicyNumber);
            } else {
                this.translate.translate('_AUTH_._PM_PROPOSAL_NUMBER').subscribe(text => label = text);
                return label.concat(' ').concat(auth.masterProposalNumber);
            }
        }
        return label;
    }

    private evaluteDescription(auth: Authorization) {
        if (!!auth.policyType && (PolicyType.CHILD === auth.policyType.code)) {
            return auth.plate ? auth.plate : null;
        }
        return auth.product && auth.product.description ? auth.product.description : null;
    }

    onSelect($event: any) {
        if ($event.authCode) {
            this.selectAuthorization($event);
        }
    }
}

import { Injectable } from '@angular/core';
import { SurveyEvalProductsCfgService } from './survey-eval-products-cfg.service';
import { SurveyEvalValidationTool } from './survey-eval-validation.service';


@Injectable()
export class SurveyEvalTools {

    constructor(
        public validationTool: SurveyEvalValidationTool,
        public productsCfgTool: SurveyEvalProductsCfgService
    ){}
}

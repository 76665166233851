import {
    AfterContentInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    QueryList,
    ViewEncapsulation
} from '@angular/core';
import { StickableCheckerChange } from '@rgi/rx/ui';
import { PPEVONavigationBtnDirective } from './navigation-btn.directive';


@Component({
    selector: 'ppevo-navbar',
    templateUrl: './navigation-bar.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PPEVONavigationBarComponent {

    @Input() stickable?: boolean = false;
    @Input() ngClassDefault = "ppevo-btn-primary";

    @Output('btnClick') btnClick: EventEmitter<{ btn: PPEVONavigationBtnDirective, event: Event }> = new EventEmitter;
    @Output('onStickyChange') onStickyChange: EventEmitter<boolean> = new EventEmitter;

    @ContentChildren(PPEVONavigationBtnDirective) btns: QueryList<PPEVONavigationBtnDirective>;

    isSticky: boolean = false;


    constructor(public hostElement: ElementRef,) { }


    onBtnClick(event: Event, btn: PPEVONavigationBtnDirective) {
        this.btnClick.emit({ btn, event });
    }


    changeSticky(stickableChange: StickableCheckerChange) {
        this.isSticky = stickableChange.isOutSideViewport;
        this.onStickyChange.emit(this.isSticky)
    }


}

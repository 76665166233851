import {Injectable} from '@angular/core';
import {AnagStateDetail} from './anag-state-manager-detail';
import {combineLatest, Observable, of} from 'rxjs';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {CORE_ADDRESS_CONFIG, CORE_PARTY_CONFIG} from '../../anag-constants/anag-constants';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {
  AnagApiConfigurationFilterRequest,
  AnagConfigurationFilter
} from '../../anag-model/anag-api/anag-api-configuration-filter';
import {AnagResourceService} from '../../anag-resources/anag-resource.service';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {ROUTES_FULL_DETAIL, ROUTES_RESULT} from '../../anag-constants/routes-constants';

@Injectable({
  providedIn: 'root'
})
export class AnagStatelessOpDetailService {

  constructor(
    protected anagStorage: AnagStorageService,
    protected anagResource: AnagResourceService,
    protected routingService: RoutingService,
    protected anagIntegration: AnagIntegrationService
  ) {

  }

  initDetail$(stateDetail$: Observable<AnagStateDetail>, activeRoute: ActiveRoute) {
    const routeData = activeRoute.getRouteData<AnagRouteData>();
    const configFilter = new AnagConfigurationFilter();
    configFilter.partyType = parseInt(routeData.party.subjectType.codice);
    configFilter.country = routeData.party.partyKey && routeData.party.partyKey.length > 0 ? routeData.party.partyKey[0].country : '';
    configFilter.legalForm = routeData.party.corporateForm ? parseInt(routeData.party.corporateForm.codice) : 999;
    configFilter.partyRole = 999;
    return stateDetail$.pipe(
      mergeMap(
        (st) => {
          return combineLatest([
            of(st),
            this.anagStorage.getSystemProperties$(CORE_ADDRESS_CONFIG.address).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.partyNumber).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.partyHomonymy).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagCheckCFDuplicato).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagGruppoCompagnie).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagResource.getPartyConfig$(new AnagApiConfigurationFilterRequest(configFilter)).pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              ([state, systemPropAddress, systemPropPartyNumber, systemPropPartyHomonymy, systemPropPartyCFDuplicate, systemPropPartyGroupCompanies,  configuration]) => {
                if (systemPropAddress && systemPropAddress.value && systemPropAddress.key) {
                  state.isUmaPartyKeyEnable = systemPropAddress.value === 'Enabled';
                }
                if (systemPropPartyNumber && systemPropPartyNumber.value && systemPropPartyNumber.key) {
                  state.isUmaPartyNumberEnable = systemPropPartyNumber.value === 'Enable';
                }
                if (systemPropPartyHomonymy && systemPropPartyHomonymy.value && systemPropPartyHomonymy.key) {
                  state.isUmaPartyHomonymyEnable = systemPropPartyHomonymy.value === 'Enable';
                }
                if (systemPropPartyCFDuplicate && systemPropPartyCFDuplicate.value) {
                  st.isUmaPartyCFDuplicate = systemPropPartyCFDuplicate.value === 'true';
                }
                if (systemPropPartyGroupCompanies && systemPropPartyGroupCompanies.value) {
                  st.isUmaPartyGroupCompanies = systemPropPartyGroupCompanies.value === 'true';
                }
                if (typeof configuration === 'string') {
                  if (st.errors.length === 0) {
                    st.errors.push(configuration);
                  }
                } else {
                  st.partyConfiguration = configuration.output;
                }
                st.parties = routeData.parties;
                st.party = routeData.party;
                st.isModal = routeData.isModalFlow === true;
                st.redirectToMainFlow = routeData.redirectToMainFlow;
                this.anagIntegration.setPartyInContainerCache(st.party, activeRoute.id);
                return state;
              }
            )
          );
        }
      )
    );
  }

  goToResultPage(activeRoute: ActiveRoute) {
    this.anagIntegration.navigate(this.routingService, ROUTES_RESULT, activeRoute.getRouteData<AnagRouteData>(), activeRoute);
  }

  goToFullDetailPage$(stateDetail$: Observable<AnagStateDetail> , activeRoute: ActiveRoute): Observable<AnagStateDetail> {
    return stateDetail$.pipe(
      map(res => {
        this.anagIntegration.navigate(this.routingService, ROUTES_FULL_DETAIL, activeRoute.getRouteData<AnagRouteData>(), activeRoute);
        return res;
      })
    );
  }
}

export class AuthorizationFilter {

  public requestCode: string;
  public idSubject: string;
  public nodeCode: string;
  public userLogin: string;
  public subtree: boolean;
  public sellGroup: string;
  public requestType: string;
  public priority: string;
  public userType: string;
  public user: string;
  public authorizationState: string;
  public workflowState: string;
  public proposalNumber: string;
  public policyNumber: string;
  public dateFrom: number;
  public dateTo: number;
  public policyType: string;
  public productType: string;
  public postSellCode: string;
  public derogationLevel: boolean;
  public underSystem: string;
  public derogationLevelNumber: string;
  public lastAction: string;
  public contractType: string;

  constructor() { }
}

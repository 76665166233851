import { InjectionToken } from '@angular/core';

export const SOURCE_ENVIRONMENT_KEYS = {
    NODE: "portal.operator.node",
    USERNAME: "portal.operator.username"
  }

export type SourceEnvironment = { [key: string]: string };

export const SOURCE_ENVIRONMENT_PROVIDER = new InjectionToken<SourceEnvironment>("SOURCE_ENVIRONMENT_PROVIDER");

export const PRODUCT_API_PATHS = ['v2/product'];

import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BeneficiaryCathegory, BeneficiaryGroupingTypeObj, LicCustomProperties, Roles } from './../enum/life-issue.enum';


export class LicRolesUtils {
  public static getRoleDescriptionByCode(roleCode: string): string {
    if (roleCode === Roles.CONTRACTOR) {
      return 'Policy Holder';
    } else if (roleCode === Roles.EFFECTIVEHOLDER) {
      return 'Beneficial owners';
    } else if (roleCode === Roles.INSURED) {
      return 'Insured';
    } else if (roleCode === Roles.THIRDREFERENT) {
      return 'Third contact person';
    } else if (roleCode === Roles.BENEFICIARY) {
      return 'beneficiary';
    } else if (roleCode === Roles.EFFECTIVEHOLDER_BENEF) {
      return 'effective beneficiary holder';
    }
    return;
  }

  public static isThereOneEmptyBenefControl(arrayForm: UntypedFormArray): boolean {
    let emptyControl = false;
    Object.keys(arrayForm.controls).forEach((key: any) => {
      if (arrayForm.get(key) instanceof UntypedFormGroup) {
        emptyControl = this.isEmptyBenefControl(arrayForm.get(key) as UntypedFormGroup);
      }
    });
    return emptyControl;
  }

  public static isEmptyBenefControl(fg: UntypedFormGroup): boolean {
    if (fg instanceof UntypedFormGroup) {
      return (fg.get('value').value == null && fg.get('percentage').value == null);
    }
  }

  // eslint-disable-next-line max-len
  public static createSubjectBenefFormGroup(subj, scelta, groupingBenefOpt?: BeneficiaryGroupingTypeObj): UntypedFormGroup {
    const formGroup = this.createSubjectBenefFormControl(subj, scelta);
    if (!!scelta) {
      if (scelta.typeBeneficiary.codice === BeneficiaryCathegory.CEDOLA) {
        formGroup.get('paymentInfo').get('idPaymentType').setValidators(Validators.required);
      }
      if (groupingBenefOpt && !!groupingBenefOpt.activeTypes.find(t => t.code === scelta.typeBeneficiary.codice)) {
        formGroup.get('positionNumber').get('positionNumberType').setValidators(Validators.required);
      }
    }
    return formGroup;
  }

  private static createSubjectBenefFormControl(subj, scelta): UntypedFormGroup {
    if (!!scelta) {
      const payments = !!scelta.payments && !!scelta.payments[0] ? scelta.payments[0] : { paymentType: {} };
      const positionNumberProp = scelta && scelta.extProperties && scelta.extProperties
                                  .find(p => p.chiave === LicCustomProperties.POSITION_NUMBER_FOR_BENEFICIARIES);
      return new UntypedFormGroup({
        value: new UntypedFormControl(subj, Validators.required),
        percentage: new UntypedFormControl(
          scelta.beneficiaryLifeParty.percentageParty ? scelta.beneficiaryLifeParty.percentageParty : null,
          [Validators.required, Validators.max(100), Validators.min(0.01)]),
        _reg41: new UntypedFormControl(true),
        paymentInfo: new UntypedFormGroup({
          idPaymentType: new UntypedFormControl(payments.paymentType.codice),
          cchecknumber: new UntypedFormControl(payments.checkNumber),
          ciban: new UntypedFormControl(payments.iban),
          cholder: new UntypedFormControl(payments.bankAccountOwner),
          creditCardNumber: new UntypedFormControl(payments.creditCardNumber),
          creditCardExpiredDate: new UntypedFormControl(payments.cardExpirationDate)
          }),
          positionNumber: new UntypedFormGroup({
            positionNumberType: new UntypedFormControl(positionNumberProp && positionNumberProp.valore)
          })
      });
    } else {
      return new UntypedFormGroup({
        value: new UntypedFormControl(subj),
        percentage: new UntypedFormControl(null, [Validators.required, Validators.max(100), Validators.min(0.01)]),
        _reg41: new UntypedFormControl(true),
        paymentInfo: new UntypedFormGroup({
          idPaymentType: new UntypedFormControl(null),
          cchecknumber: new UntypedFormControl(null),
          ciban: new UntypedFormControl(null),
          cholder: new UntypedFormControl(null),
          creditCardNumber: new UntypedFormControl(null),
          creditCardExpiredDate: new UntypedFormControl(null)
          }),
          positionNumber: new UntypedFormGroup({
            positionNumberType: new UntypedFormControl(null)
          })
      });
    }
  }

  public static groupByPositionNumberType(arr: UntypedFormArray): { [key: string]: UntypedFormGroup[] } {
    const groupedBeneficiariesByPositionType = arr.controls.reduce((grouped, obj) => {
      const key = obj.get('positionNumber').get('positionNumberType').value;

      // Se la chiave non esiste ancora nell'oggetto raggruppato, creala
      if (!grouped[key]) {
        grouped[key] = [];
      }

      // Aggiungi l'oggetto corrente al gruppo corrispondente
      grouped[key].push(obj);

      return grouped;
    }, {});
    return groupedBeneficiariesByPositionType;
  }
}

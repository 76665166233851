import {TableSchema} from '@rgi/rx/ui';

export const GP_COINSURANCE_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'percentageShare',
      title: '_GP_._LABEL_._SHARE_PERC_'
    },
    {
      name: 'percentageCommissions',
      title: '_GP_._LABEL_._COMMISSIONS_PERC_'
    },
    {
      name: 'companyDesc',
      title: '_GP_._LABEL_._COMPANY_'
    },
    {
      name: 'intermediaryDesc',
      title: '_GP_._LABEL_._INTERMEDIARY_'
    },
    {
      name: 'policyNumber',
      title: '_GP_._LABEL_._POLICY_NUMBER_',
    },
    {
      name: 'actions',
      title: 'Actions',
      styleClass: 'rgi-gp-action-cell',
      actions: [
        {
          name: 'EDIT',
          styleClass: 'rgi-ui-icon-edit2'
        }, {
          name: 'DELETE',
          styleClass: 'rgi-ui-icon-delete',
        }
      ]
    }
  ],
  header: ['percentageShare', 'percentageCommissions', 'companyDesc', 'intermediaryDesc', 'policyNumber', 'actions']
};

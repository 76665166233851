<div>
  <form [formGroup]="searchForm" data-qa="expert-search-form">
    <div class="col-sm-12 container-expert-search">
      <!-- COUNTRY -->
      <div class="col-sm-12" *ngIf="isUmaUniqueKeyManagementEnabled">
        <label>{{ '_CLAIMS_._NATION' | translate }}</label>
        <select class="core-select form-control" id="country" name="country" formControlName="country"
                attr.data-qa="expert-search-input">
          <option></option>
          <option *ngFor="let country of this.formConfig.countries"
                  [value]="country.code">{{country.description}}</option>
        </select>
      </div>
      <!-- SUBJECT TYPE -->
      <div class="col-sm-12">
        <label>{{ '_CLAIMS_._SUBJECT_TYPE' | translate }}</label>
        <select class="core-select form-control" id="subjectType" name="subjectType"
                formControlName="subjectType" attr.data-qa="expert-search-input">
          <option></option>
          <option *ngFor="let type of this.formConfig.subjectTypes"
                  [value]="type.idObject">{{type.description}}</option>
        </select>
      </div>
      <!-- EXPERT CODE -->
      <div class="col-sm-12">
        <label >{{ '_CLAIMS_._CODE' | translate }} </label>
      </div>
      <input type="text" class="col-sm-12 form-control" name="expertCode" formControlName="expertCode" id="expertCode"
             attr.data-qa="expert-search-code-input">
      <!-- NAME -->
      <div class="col-sm-12">
        <label>{{ '_CLAIMS_._NAME' | translate }} </label>
      </div>
      <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control col-sm-12" name="name"
             formControlName="name" id="name"
             attr.data-qa="expert-search-name-input">
      <!-- SURNAME -->
      <div class="col-sm-12">
        <label>{{ '_CLAIMS_._SURNAME' | translate }} </label>
      </div>
      <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control col-sm-12" name="surname"
             formControlName="surname" id="surname"
             attr.data-qa="expert-search-surname-input">
      <!-- BUSINESS NAME (RAGIONE SOCIALE) -->
      <div class="col-sm-12">
        <label>{{ '_CLAIMS_._BUSINESS_NAME' | translate }} </label>
      </div>
      <input [attr.disabled]="!isLegalSubject ? true : null" type="text" class="form-control col-sm-12"
             name="businessName" formControlName="businessName" id="businessName"
             attr.data-qa="expert-search-businessName-input">

      <div *ngIf="!isUmaUniqueKeyManagementEnabled">
        <!-- CODICE FISCALE -->
        <div class="col-sm-12">
          <label>{{ '_CLAIMS_._FISCAL_CODE' | translate }} </label>
        </div>
        <input type="text" class="form-control col-sm-12" name="fiscalCode" id="fiscalCode"
               formControlName="fiscalCode" attr.data-qa="expert-search-fiscalCode-input">
        <!-- PARTITA IVA -->
        <div class="col-sm-12">
          <label>{{ '_CLAIMS_._VAT_NUMBER' | translate }} </label>
        </div>
        <input type="text" class="form-control col-sm-12" name="vatNumber" id="vatNumber"
               formControlName="vatNumber" attr.data-qa="expert-search-vatNumber-input">
      </div>

      <div *ngIf="isUmaUniqueKeyManagementEnabled">
        <!--        loop through countries. only display currently selected country -->
        <div *ngFor="let country of formConfig.countries; let countryIndex = index;">
          <div *ngIf="searchForm.value.country===country.code">
            <!-- display the 3 optional labels, only if they are defined -->
            <!-- ngIf : row is shown only if the label has a value -->
            <!-- formControlName is a combination of COUNTRYCODE + G (legal or physical subject) + CKEY_NUMBER -->
            <!-- CKEY 1 -->
            <div *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey1 !==''">
              <div class="col-sm-12">
                <label>{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1']['labelCkey1']}} </label>
              </div>
              <input type="text" class="form-control col-sm-12"
                     name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey1' }}"
                     formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}"
                     id="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}"
                     attr.data-qa="expert-search-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}-input">
            </div>
            <!-- CKEY 2 -->
            <div *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2 !==''">
              <div class="col-sm-12">
                <label>{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2}} </label>
              </div>
              <input type="text" class="form-control col-sm-12"
                     name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey2' }}"
                     formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}"
                     id="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}"
                     attr.data-qa="expert-search-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}-input">
            </div>
            <!-- CKEY 3 -->
            <div *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3 !==''">
              <div class="col-sm-12">
                <label>{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3}} </label>
              </div>
              <input type="text" class="form-control col-sm-12"
                     name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey3' }}"
                     formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}"
                     id="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}"
                     attr.data-qa="expert-search-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}-input">
            </div>
          </div>
        </div>
      </div>

      <!--      EXPERT TYPE (TIPO FIDUCIARIO) -->
      <div class="col-sm-12">
        <label>{{ '_CLAIMS_._EXPERT_TYPE' | translate }}</label>
        <select class="core-select form-control" id="expertType" name="expertType" formControlName="expertType"
                attr.data-qa="expert-search-expertType-input">
          <option></option>
          <option *ngFor="let type of this.formConfig.expertTypes" [value]="type.idObject">{{type.description}}</option>
        </select>
      </div>
    </div>
    <span *ngIf="showError" class="col-sm-12 content-error validate-error" attr.data-qa="expert-search-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>
              {{ '_CLAIMS_._MESSAGE_._VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT' | translate }}
            </span>
        </span>
    <span *ngIf="showNoExpertsFound" class="col-sm-12 content-error validate-error"
          attr.data-qa="expert-search-not-found">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{ '_CLAIMS_._NO_RESULT' | translate }}</span>
        </span>
    <span *ngIf="!showError && !showNoExpertsFound"><br/>&nbsp;</span>
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button type="button" class="btn btn-warning pull-right" (click)="onEmpty()"
                attr.data-qa="expert-search-empty-btn">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
      </div>
      <div class="btn-group">
        <button type="submit" class="btn btn-warning pull-right" attr.data-qa="expert-find-btn"
                (click)="onFind()" id="expert-search-submit">{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
      </div>
    </div>
  </form>
</div>

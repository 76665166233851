import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RgiCountryLayerNumberFormat, RgiCountryLayerNumberFormatService} from './rgi-country-layer-number-format.service';
import { RgiCountryLayerNumberFormatPipe } from './rgi-country-layer-number-format.pipe';



@NgModule({
  declarations: [RgiCountryLayerNumberFormatPipe],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: RgiCountryLayerNumberFormat, useExisting: RgiCountryLayerNumberFormatService}
  ],
  exports: [RgiCountryLayerNumberFormatPipe]
})
export class RgiCountryLayerNumberFormatModule { }

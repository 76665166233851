import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxLocalStateManager, StateStoreService} from '@rgi/rx/state';
import {AuthorizationCardStateStart} from "./authorization-card-state";
import {AuthorizationCardReducerService} from './authorization-card-reducer.service';


export class AuthorizationCardStateManagerStart extends RgiRxLocalStateManager<AuthorizationCardStateStart> {

  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, private reducer: AuthorizationCardReducerService) {
    super(activeRoute, stateStoreService);
  }
}

<div [formGroup]="formGroup" class="tool">
  <!--<div class="tool-head">
    <rgi-rx-form-field>
      <div id="checkbox_min_plus_accordion">
        <span class="rgi-plus" *ngIf="!isToolBodyOpen()"></span>
        <span class="rgi-minus" *ngIf="isToolBodyOpen()"></span>
      </div>
      <label rgiRxLabel>{{ toolName }}</label>
      <input type="checkbox" rgiRxInput formControlName="active" [disabled]="disabled === true ? true : null">
    </rgi-rx-form-field>
     <pnc-checkbox [disabled]="disabled" formControlName="active"></pnc-checkbox>
    <span>{{ toolName }}</span>
  </div>
  <div class="tool-body row" [ngClass]="{ 'active' : isToolBodyOpen() }">
    <div #toolBodyContent>
      <ng-content></ng-content>
    </div>
  </div>-->

  <div style="margin: 29px 1px;">
    <rgi-rx-expansion-panel color="{{accordionColor}}">
      <div rgi-rx-expansion-panel-header>
        <input type="checkbox" formControlName="active" [disabled]="disabled === true ? true : null" class="rgi-ui-form-control">
        <div rgi-rx-expansion-panel-label>{{ toolName }}</div>
      </div>
      <ng-template rgiRxExpansionPanelContent>
        <div #toolBodyContent>
          <ng-content></ng-content>
        </div>
      </ng-template>
    </rgi-rx-expansion-panel>
  </div>
  
</div>

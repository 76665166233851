import {Pipe, PipeTransform} from '@angular/core';
import {GpAssetUnitsClauses, GpUnitClause, SingleUnit} from '../../../group-policy-models/group-policy-issue-guarantees';

@Pipe({
  name: 'gpFilterAssetClusterUnitsCauses'
})
export class GpFilterAssetClusterUnitsCausesPipe implements PipeTransform {

  transform(assetUnitsClauses: GpAssetUnitsClauses, unit: SingleUnit): GpUnitClause {
    return assetUnitsClauses && assetUnitsClauses.unitsClauses && assetUnitsClauses.unitsClauses.length > 0 ?
      assetUnitsClauses.unitsClauses.find(value => value.codeUnit === unit.code && value.codeSection === unit.sectionCode) :
      null;
  }

}

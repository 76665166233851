import {Component, Inject, Input, OnChanges, Optional, SimpleChanges} from '@angular/core';
import {Definition} from '../../../models/postsales-operations-response.model';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';

@Component({
  selector: 'lpc-definition-table',
  templateUrl: './lpc-definition-table.component.html',
  styleUrls: ['./lpc-definition-table.component.scss']
})
export class LpcDefinitionTableComponent implements OnChanges {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() public definitions: Definition[] = [];

  @Input() public rows: any[] = [];

  @Input() label: string = null;

  private $visibleDefinitions: Definition[] = [];
  get visibleDefinitions(): Definition[] {
    return this.$visibleDefinitions;
  }

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.definitions.currentValue) {
      this.$visibleDefinitions = (changes.definitions.currentValue as Definition[]).filter(definition => definition.visible);
    }
  }

}

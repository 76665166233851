import { Output, EventEmitter, Component, OnInit, ViewChild, AfterViewInit, Inject, Input } from '@angular/core';
import { RoutableComponent, RoutingService, AccordionComponent } from '@rgi/portal-ng-core';
import {
  Policy,
  AlertService,
  PartyTranslatorService,
  DomainField,
  DamagedAsset
} from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../claim-opening.service';
import { ClaimOpeningDetailsService } from './claim-opening-details.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SavePotentialClaimResponse } from './model/save-potential-claim-response';
import { LoadPotentialClaimResponse } from './model/load-potential-claim-response';
import { DamageCategoryService } from '../../damage-category.service';
import { DamageCategory } from '../claim-opening-policy-search/dto/damage-categories';
import { OccurrenceData } from './occurence-data-panel-content/dto/occurrence-data';
import { PartyData } from './party-data-panel-content/dto/party-data';
import { DamageCategoriesCacheEntity } from './damage-category-panel-content/dto/damage-categories-cache-entity';
import { DocumentCategory } from './document-management-panel-content/model/document-category';
import * as uuid from 'uuid';
import { ClaimOpeningPolicySearchResultsService } from '../claim-opening-policy-results/claim-opening-policy-results.service';
import { TimeRequest } from '../claim-opening-policy-search/dto/time-request';
import { StepperSection } from './model/stepper-section';
import { ExternalPolicy } from '../../../external-portfolio/dto/external-policy';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-claim-opening-details',
  templateUrl: './claim-opening-details.component.html',
  styleUrls: ['./claim-opening-details.component.scss']
})
export class ClaimOpeningDetailsComponent implements RoutableComponent, OnInit, AfterViewInit {
  @ViewChild(AccordionComponent) accordionComponent: AccordionComponent;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  @Input() card: any;

  policyModifyData: any;
  isPolicyModifyData: boolean;

  pes: any;
  isClaimOpened: boolean;

  authSrv: any;
  public abilitaAperturaSenzaCausale: boolean;
  public labelSave: string;

  isOpen: true;
  currentStep = StepperSection.occurrenceData;
  policy: Policy;
  disabledPanels: any;
  saveDisabled: boolean = true;
  bodywork: any;
  bodyworkIntegrationObj: any;
  driver: any;
  third: any;
  pesLoaded: boolean;
  savedFields: DomainField[];
  stepperLabels = [];

  occurrenceData: OccurrenceData;
  partyData: PartyData;
  damageCategoriesData: DamageCategoriesCacheEntity;
  documentsData: DocumentCategory;
  selectedAsset: DamagedAsset[];
  isSelectedThirdParty: boolean;
  damageCategory: DamageCategory;
  editDone: boolean;
  enableMultiDamage: boolean = false;
  constructor(
    private routingService: RoutingService,
    private claimOpeningService: ClaimOpeningService,
    private claimOpeningDetailsService: ClaimOpeningDetailsService,
    private alertService: AlertService,
    @Inject('eventService') private eventService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('claimsService') private claimsService: any,
    @Inject('authService') private authService: any,
    private damageCategoryService: DamageCategoryService,
    private partyTranslatorService: PartyTranslatorService,
    private claimOpeningPolicySearchResultsService: ClaimOpeningPolicySearchResultsService,
    private rxTranslationsService: RgiRxTranslationService
  ) {

    this.authSrv = authService;

    const listFunz = this.authSrv.getOperator().enabledFeatures;

    if (listFunz && listFunz.indexOf('SXSCOP') >= 0) {
      this.abilitaAperturaSenzaCausale = true;
    }
    if (this.stepperLabels.length == 0) {
      this.rxTranslationsService.translate('_CLAIMS_._SELECT_POLICY').subscribe(
        res => this.stepperLabels.push(res)
      )
      this.rxTranslationsService.translate('_CLAIMS_._OCCURRENCE_DATA').subscribe(
        res => this.stepperLabels.push(res)
      )
      this.rxTranslationsService.translate('_CLAIMS_._DAMAGED__ASSET').subscribe(
        res => this.stepperLabels.push(res)
      )
      this.rxTranslationsService.translate('_CLAIMS_._SELECT_TYPE_OF_LOSS').subscribe(
        res => this.stepperLabels.push(res)
      )
      this.rxTranslationsService.translate('_CLAIMS_._DOCUMENTS').subscribe(
        res => this.stepperLabels.push(res)
      )
    }


  }

  ngAfterViewInit(): void {
    if (!this.isEditPes()) {
      this.manageAccordion();
    }
  }

  ngOnInit() {
    this.enableMultiDamage = this.claimOpeningService.getEnableMultiDamage();
    if (this.isEditPes()) {
      this.claimOpeningService.setSessionUuid(this.card.id, uuid.v4());
      this.currentStep = StepperSection.last;
      this.loadPolicyFromPes();
      this.loadPotentialClaim();
    } else {
      this.policy = this.claimOpeningService.getSessionSelectedPolicy(this.card.id);
      this.damageCategory = this.claimOpeningService.getCategory(this.card.id);
    }

    if (this.card) {
      this.policyModifyData = this.claimOpeningService.getPolicyModifyDataFromSession(this.card.id);
    }

    const observable1$ = this.rxTranslationsService.translate('_CLAIMS_._BUTTONS_._SAVE');
    const observable2$ = this.rxTranslationsService.translate('_CLAIMS_._BUTTONS_._SAVE_PES');
    combineLatest([observable1$, observable2$]).subscribe(
      ([res1,res2]) =>  this.labelSave = this.policyModifyData != null ? res1 : res2
    )
    this.isPolicyModifyData = this.policyModifyData != null;

  }

  save() {
    const uuidVal = this.claimOpeningService.getSessionUuid(this.card.id);
    const that = this;
    let polExt: ExternalPolicy = null;
    let idTipoPortafoglio = '1'; // default portafoglio PASS

    this.eventService.broadcastEvent('start-loader');

    if (this.claimOpeningService.getExternaPortfolio()) {
      idTipoPortafoglio = this.claimOpeningService.getTypePtf();
      polExt = this.claimOpeningService.getExternalPolicyCurrent();
    }

    this.claimOpeningDetailsService
      .savePotentialClaim(uuidVal, this.pes ? this.pes.reportNumber : null, idTipoPortafoglio, polExt,
        this.policyModifyData ? this.policyModifyData.idPotentialClaim : null)
      .subscribe(
        (response: SavePotentialClaimResponse) => {
          that.alertService.translatedMessage(response.descr);
          that.goToSummary(response.potentialclaimnumber, response.companycode);

          if (this.policyModifyData) {
            this.sessionService.remove(this.policyModifyData.idSessionOldPesDetails);
          }

        },
        (error: HttpErrorResponse) => {
          this.eventService.broadcastEvent('stop-loader');
        }
      );
    return null;
  }

  cancel() {
    this.sessionService.setActive(this.card.idSessionParent);
    this.sessionService.remove(this.card.idSession);
  }

  progress(nextStep: number) {
    if (nextStep > this.currentStep) {
      this.currentStep = nextStep;
    }
  }

  onPanelCompleted(completed: boolean) {
    this.saveDisabled = !completed;
  }

  onEditDone(edited: boolean) {
    this.editDone = edited;
  }

  goToSummary(claimNumber: string, companyCode: string) {
    const that = this;

    this.claimsService.getClaimsPesDetailByNumber(companyCode, claimNumber, '6').then(
      (result) => {
        const detailResult = result.claimInquiry;
        detailResult.pesRequestType = result.potencialClaimTypeRequest;
        if (!this.pes) {
          const idResult = that.sessionService.open('claimsFinder', 'detail-pes', '', true);
          detailResult.idResult = idResult;
          that.coreResult.setResult(idResult, 'detail-pes', detailResult);
        }
        that.eventService.broadcastEvent('stop-loader');
        that.sessionService.remove(that.card.idSession);
        if (this.pes) {
          that.card.integrationPass.objNavigation.reloadOnSave();
        }
      });
  }

  loadPotentialClaim() {
    this.eventService.broadcastEvent('start-loader');

    this.claimOpeningDetailsService.loadPotentialClaim(this.pes.reportNumber).subscribe(
      (response: LoadPotentialClaimResponse) => {
        this.loadCategory(response);
        this.initPotentialClaim(response);
        this.loadOccurrenceData(response.occurrenceData);
        this.loadPartyData(response.partyData);
        this.loadDamageCategories(response.damageCategoriesData);
        this.loadDocuments(response.documentsData);
        this.manageAccordion();
      }
    );
  }

  initPotentialClaim(data: LoadPotentialClaimResponse) {
    data.selectContractData.uuid = this.claimOpeningService.getSessionUuid(this.card.id);
    this.claimOpeningService.setSessionOccurrenceDate(this.card.id, new Date(data.selectContractData.occurrenceDate));
    const time = new TimeRequest();
    time.translateFromString(data.selectContractData.occurrencetime);
    this.claimOpeningService.setSessionTimeRequest(this.card.id, time);
    this.claimOpeningPolicySearchResultsService.initPotentialClaim(data.selectContractData).subscribe(
      () => {
        console.log('Potential claim cache initialized');
        this.fillCache(data);
      }
    );
  }

  fillCache(data: LoadPotentialClaimResponse) {
    data.occurrenceData.uuid = this.claimOpeningService.getSessionUuid(this.card.id);
    data.partyData.uuid = this.claimOpeningService.getSessionUuid(this.card.id);
    data.damageCategoriesData.uuid = this.claimOpeningService.getSessionUuid(this.card.id);
    this.claimOpeningDetailsService.saveOccurrenceDataInCache(data.occurrenceData).subscribe(
      () => {
        console.log('Occurrence data cache initialized');
        this.claimOpeningDetailsService.savePartyDataInCache(data.partyData).subscribe(
          () => {
            console.log('Party data cache initialized');
          }
        );
      }
    );
  }

  loadCategory(response: LoadPotentialClaimResponse) {
    if (response.selectContractData.categoryCode) {
      this.damageCategoryService.getCategory(response.selectContractData.categoryCode).subscribe(
        (damageCategoryServiceResponse: DamageCategory) => {
          this.claimOpeningService.setCategory(this.card.id, damageCategoryServiceResponse);
          this.damageCategory = damageCategoryServiceResponse;
          this.pesLoaded = true;
          this.manageAccordion();
        }
      );
    } else {
      this.pesLoaded = true;
      this.manageAccordion();
    }
  }

  loadPolicyFromPes() {
    this.policy = new Policy();
    this.policy.number = this.pes.policyNumber;
    this.policy.description = this.pes.policyType;
    this.policy.effectDate = this.pes.policyEffectDate;
    this.policy.holder = this.pes.contractor;
    this.policy.expirationDate = this.pes.policyExpirationDate;
    this.policy.product = this.pes.product;
    this.claimOpeningService.setSessionSelectedPolicy(this.card.id, this.policy);
  }

  loadOccurrenceData(occurrenceData: OccurrenceData) {
    this.occurrenceData = occurrenceData;
  }

  loadPartyData(partyData: PartyData) {
    this.loadDriver(partyData);

    this.partyData = partyData;
    this.savedFields = partyData.fields;
  }

  loadDriver(partyData: PartyData) {
    if (partyData.driversubjectid) {
      for (const party of partyData.parties) {
        if (party.id === partyData.driversubjectid) {
          this.driver = this.partyTranslatorService.partyDtoToAnagJson(party);
          break;
        }
      }
    }
  }

  loadDamageCategories(damageCategoriesData: DamageCategoriesCacheEntity) {
    this.damageCategoriesData = damageCategoriesData;
  }

  loadDocuments(documentsData: DocumentCategory) {
    this.documentsData = documentsData;
  }

  manageAccordion() {

    if (this.accordionComponent) {
      console.log('ClaimOpeningDetailsComponent - manageAccordion - expanding');
      this.accordionComponent.expand('panel-occurence-data');
    } else {
      console.log('ClaimOpeningDetailsComponent - manageAccordion - not expanding');
    }
  }

  isEditPes() {
    return typeof this.pes !== 'undefined' && this.pes !== null;
  }

  assetSelected(event: DamagedAsset[]) {
    this.selectedAsset = event;
  }

  onSelectedThirdParty(event: boolean) {
    this.isSelectedThirdParty = event;
  }
}

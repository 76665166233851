<rgi-rx-expansion-panel [expanded]="true" #first color="info">
    <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-6 col-md-6">
                <label ng-bind="label">
                    {{ '_CLAIMS_._ADDITIONAL_DATA' | translate }}
                </label>
            </div>
        </div>
    </div>
    <form [formGroup]="additionalDataForm">
        <div class="form-group row">
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.typeOfAuthorityInvolvedVisible">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._TYPE_OF_AUTHORITY' | translate }}</label>
                    <select rgiRxNativeSelect formControlName="typeOfAuthorityInvolvedValue"
                        (change)="outputFormChange()">
                        <option value=""></option>
                        <option *ngFor="let type of comboTypeOfAuthorityInvolved" [value]="type.codice">
                            {{ type.descrizione }}
                        </option>
                    </select>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.authorityDescriptionVisible">
                <rgi-rx-form-field>
                    <input rgiRxInput formControlName="authorityDescriptionValue"
                        (blur)="outputFormChange()">
                    <label rgiRxLabel>{{ '_CLAIMS_._DESCRIPTION_AUTHORITY' | translate }}</label>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3">
                <!-- *ngIf="additionalData.externalClaimNumberValue" -->
                <rgi-rx-form-field>
                    <input rgiRxInput formControlName="externalClaimNumberValue"
                        (blur)="outputFormChange()">
                    <label rgiRxLabel>{{ '_CLAIMS_._EXTERNAL_CLAIM_NUMBER' | translate }}</label>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="form-group row d-flex align-items-center" *ngIf="additionalData.catastrophicEventVisible">
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.catastrophicEventDescriptionVisible">


                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._DESCRIPTION_EVENT' | translate }}</label>
                    <select rgiRxNativeSelect formControlName="catastrophicEventDescriptionValue"
                        (change)="outputFormChange()">
                        <option value=""></option>
                        <option *ngFor="let category of comboCatastrophicEventDescription" [value]="category.codice">
                            {{ category.descrizione }}
                        </option>
                    </select>
                </rgi-rx-form-field>

            </div>
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.antifraudCategoryVisible">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._ANTI_FRAUD_CATHEGORY' | translate }}</label>
                    <select rgiRxNativeSelect formControlName="antifraudCategoryValue"
                        (change)="outputFormChange()">
                        <option value=""></option>
                        <option *ngFor="let category of comboAntifraudCategory" [value]="category.codice">
                            {{ category.descrizione }}
                        </option>
                    </select>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.openedClaimDuetoSummonsIssuedVisible">
                <rgi-rx-form-field>
                    <input type="checkbox" rgiRxInput formControlName="openedClaimDuetoSummonsIssuedValue"
                        (change)="outputFormChange()">
                    <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._MESSAGE_._OPENED_CLAIM_DO_ISSUE' | translate }}</label>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.notIdentifiedWitnessVisible">
                <rgi-rx-form-field>
                    <input type="checkbox" rgiRxInput formControlName="notIdentifiedWitnessValue"
                        (change)="outputFormChange()">
                    <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._MESSAGE_._NOT_IDENTIFIED_WITNESS' | translate }}</label>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="form-group row d-flex align-items-center">
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.lastAuditDateVisible">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._LAST_AUDIT_DATE' | translate }}</label>
                    <rgi-rx-date-picker>
                        <input [rgiRxDateTimeInput]="auditPicker" formControlName="lastAuditDateValue"
                            (dateTimeChange)="outputFormChange()">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time pickerType="calendar" #auditPicker></rgi-rx-date-time>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="additionalData.toBeSettledVisible">
                <rgi-rx-form-field>
                    <input type="checkbox" rgiRxInput formControlName="toBeSettledValue"
                        (change)="outputFormChange()">
                    <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._LIQUIDABLE' | translate }}</label>
                </rgi-rx-form-field>
            </div>
        </div>
    </form>
</rgi-rx-expansion-panel>

export class GenericEntity {

  id: string;
  code: string;
  description: string;


  public isNull(): boolean {
    if (!this.id && !this.code && !this.description) {
      return true;
    } else if (this.id === '' && this.code === '' && this.description === '') {
      return true;
    } else {
      return false;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FilterTAT } from './dto/filter-tat';
import { FilterTATDetails } from './dto/filter-tat-details';

@Injectable({
  providedIn: 'root'
})
export class AccountingTransactionsService {

  private baseApiUrl;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }


  public getTitoli(filter: FilterTAT) {

    const urlTitoli = this.baseApiUrl + '/accounting/findertitles';


    return this.httpClient.post(urlTitoli, filter);


  }


}

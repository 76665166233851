import { Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { Instalment } from '../../../models/postsales-operations-response.model';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../services/currency-cache.service';

@Component({
  selector: 'lpc-warranty-detail',
  templateUrl: './lpc-warranty-detail.component.html',
  styleUrls: ['./lpc-warranty-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LpcWarrantyDetailComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  @Input() instalment: Instalment[];
  @Input() tot: Instalment;

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
  }

}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-copy-report-modal-confirm',
  templateUrl: './copy-report-modal-confirm.component.html'
})
export class CopyReportModalConfirmComponent implements OnInit {
  @Output() modalClose = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  close() {
    this.modalClose.emit({close: true});
  }
}

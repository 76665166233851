import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { DefinitionPaymentTypes, FactorDefinition, PaymentTypeDefinition } from '../../models/postsales-operations-response.model';
import { Premium } from '../../modules/lpc-premium/model/premium.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-change-payment-frequency',
  templateUrl: './change-payment-frequency.component.html',
  styleUrls: ['./change-payment-frequency.component.css']
})
export class ChangePaymentFrequencyComponent extends AbsOperationComponent
  implements OnInit {
  protected operationDataKey = 'payment-frequency';
  public paymentFrequency: FactorDefinition;
  public listPremiumAmount: Premium[];
  public paymentTypes: PaymentTypeDefinition[] = [];
  isPaymentEditable = true;
  options: NgOption[];

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  get hasPaymentTypes(): boolean {
    return !!this.paymentTypes && !!this.paymentTypes.length;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      quotation: new UntypedFormGroup({}),
      payment: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(this.createDraft().subscribe(resp => {
      this.paymentFrequency = resp.definitions.paymentFrequency as FactorDefinition;
      this.formGroup.get('factors').setValue(this.paymentFrequency.defaultValue);
      this.valuatePayments(resp);
    }));
    this.formGroup.get('factors').valueChanges.subscribe(el => {
      this.errors = [];
      this.$feErrors = [];
    });
  }

  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

  onNext(step, publish = false, context = null, isConfirmAndAccept = false) {
    if (this.isFormValid(this.formGroup.get(step))) {
      this.$subscriptions.push(
        this.updateDraft(step).subscribe(result => {
          this.detectChanges();
          this.listPremiumAmount = result.data.operationData.data.quotationData.listPremiumAmount;
          this.paymentFrequency = result.definitions.paymentFrequency as FactorDefinition;
          this.valuatePayments(result);
          this.options = this.paymentFrequency.listValue;
          if (!this.hasBlockingErrorsOnSteps(step)) {
            const bErrors = result.errors && result.errors.length > 0;
            const toBePublished = (isConfirmAndAccept && !bErrors) || this.stepper.isAtLastStep;
            this.doPublishOrNext(toBePublished, isConfirmAndAccept);
          }
          this.cd.detectChanges();
        })
      );
    } else {
      this.setFeErrors(step);
    }
  }

  protected valuatePayments(result: any) {
    this.formGroup.get('payment').setValue(result.data.operationData.data.payment);
    this.isPaymentEditable = (result.definitions.paymentTypes as DefinitionPaymentTypes).editable;
    this.paymentTypes = ((result.definitions.paymentTypes as DefinitionPaymentTypes).definitionPaymentTypes).reverse();
  }

  protected getTransformedOperationData(): any {
    return {
      paymentFrequency: { code: this.formGroup.get('factors').value, label: EMPTY_STR },
      payment: !!this.formGroupValue.payment ? this.formGroupValue.payment : {}
    };
  }

  findlabel(code: string): string {
    if (this.options) {
      return this.options.find(el => el.code === code).description;
    }
  }

  onChange(event) {
    this.formGroup.get('factors').setValue(event);
    this.errors = [];
  }
}

import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Injectable} from '@angular/core';
import {SectionDiscounts} from './discount-section';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  toFormGroup(sectionsDiscounts: SectionDiscounts[], isInclusionApplication: boolean) {
    const group: any = {};
    sectionsDiscounts.forEach(
      (section) => {
        section.unitDiscounts.forEach(unit => {
          group['flex1Perc' + '-' + section.sectionCode + '-' + unit.unitCode] =
            new UntypedFormControl(unit.flex1Perc, [Validators.required, Validators.max(unit.flex1Max), Validators.min(unit.flex1Min)]);
          group['flex2Perc' + '-' + section.sectionCode + '-' + unit.unitCode] =
            new UntypedFormControl(unit.flex2Perc, [Validators.required, Validators.max(unit.flex2Max), Validators.min(unit.flex2Min)]);
          group['flex1Taxable' + '-' + section.sectionCode + '-' + unit.unitCode] =
            new UntypedFormControl(unit.flex1Taxable, [Validators.required, Validators.min(0)]);
          group['flex2Taxable' + '-' + section.sectionCode + '-' + unit.unitCode] =
            new UntypedFormControl({value: unit.flex2Taxable, disabled: isInclusionApplication},
              [Validators.required, Validators.min(0)]);
        });
      });
    return new UntypedFormGroup(group);
  }
}

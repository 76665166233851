<div class="rgi-ui-grid-2">
  <div *ngIf="!isSecondary" class="rgi-re-section-title">
    <span class="rgi-ui-icon-list-checkbox rgi-re-header-icon"></span>
    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.ACTIVITY</span>&nbsp;
  </div>

  <div *ngIf="isSecondary" class="rgi-re-section-title">
    <span class="rgi-ui-icon-plus rgi-re-header-icon"></span>
    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.SECONDARY_ACTIVITY</span>&nbsp;
  </div>
</div>
<form [formGroup]="searchForm">
  <div class="form-group rgi-ui-grid-3 tbld_row_odd">
    <div class="rgi-ui-col rgi-re-flex-justify-center">
      <div>
        <br>
        <span class="rgi-re-header-icon rgifont rgi-ui-icon-search nfm_font_size"></span>
      </div>
      <div>
        <label for="radioButton{{key}}" class="rgi-ui-form-control-label">
          <span translate>RE_ISSUE.SEARCH_ACTIVITY_BY</span>
        </label>
        <rgi-rx-button-toggle (ngModelChange)="onAdvancedModeChange()" id="radioButton"
                              formControlName="radioButton{{key}}">
          <button rgi-rx-button rgiRxValueOption value='code' data-qa="activity_code_button" translate> RE_ISSUE.CODE
          </button>
          <button rgi-rx-button rgiRxValueOption value='description' data-qa="activity_descr_button" translate>
            RE_ISSUE.DESCRIPTION
          </button>
        </rgi-rx-button-toggle>
      </div>
    </div>
    <div class="rgi-ui-col">
      <label for="autoComplete{{key}}" class="rgi-ui-form-control-label" translate>
        <span translate>RE_ISSUE.VALUE</span>
      </label>
      <rgi-rx-form-field *ngIf="byDescription">
        <input id="autoComplete" type='text' formControlName="autoComplete{{key}}" rgiRxInput (focusin)="restore()"
               [rgiRxAutoComplete]="autoCompleteComponentForm" [minLength]="3" [openOnFocus]="true"
               (onChange)="onAutoCompleteChange($event)" (onValueChange)="onAutoCompleteValueChange($event)"
               data-qa="activity_autoComplete">
        <rgi-rx-auto-complete #autoCompleteComponentForm>
          <ng-template rgiRxAutoCompleteContent>
            <ng-container *ngIf=" minChar && filteredOptions | async as opts">
              <rgi-rx-option *ngFor="let option of opts" [value]="option" [attr.data-qa]="option">
                {{ option }}
              </rgi-rx-option>
            </ng-container>
          </ng-template>
        </rgi-rx-auto-complete>
      </rgi-rx-form-field>
      <rgi-rx-form-field *ngIf="!byDescription">
        <input (keyup.enter)="onClickBtn()" (ngModelChange)="onCodeSearchChange($event)" rgiRxInput
               formControlName="codeSearch{{key}}" data-qa="activity_code_search">
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col  rgi-re-flex-justify-center rgi-re-flex-align-center">
      <button [disabled]="searchDisabled || this.searchForm.invalid" id="autocompleteSearch" rgi-rx-button
              (click)="onClickBtn()" color="info" data-qa="activity_search_btn" translate>RE_ISSUE.SEARCH
      </button>
      <button [disabled]="this.activitiesForm.controls[this.lvl1].value==null || this.isBusy" id="resetForm"
              rgi-rx-button (click)="resetForm()" color="info" data-qa="activity_reset">
        <span class="rgi-re-header-icon rgifont rgi-ui-icon-delete nfm_font_size"></span>
      </button>
    </div>
  </div>
</form>
<form [formGroup]="activitiesForm">
  <div class="form-group rgi-ui-grid-2">
    <div *ngIf="activityPacket.labels.lvl1 != null" class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>{{activityPacket.labels.lvl1}}</label>
        <select rgiRxNativeSelect attr.data-qa="activity_{{activityPacket.labels.lvl1.toLowerCase()}}"
                [formControlName]="lvl1">
          <option value=""></option>
          <option *ngFor="let c of activityPacket.values.lvl1" [ngValue]="c.code">{{c?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div *ngIf="activityPacket.labels.lvl2 != null" class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel>{{activityPacket.labels.lvl2}}</label>
        <select rgiRxNativeSelect attr.data-qa="activity_{{activityPacket.labels.lvl2.toLowerCase()}}"
                [formControlName]="lvl2">
          <option *ngFor="let c of activityPacket.values.lvl2" [ngValue]="c.code">{{c?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
  </div>
  <div class="form-group rgi-ui-grid-2">
    <div *ngIf="activityPacket.labels.lvl3 != null" class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel>{{activityPacket.labels.lvl3}}</label>
        <select rgiRxNativeSelect attr.data-qa="activity_{{activityPacket.labels.lvl3.toLowerCase()}}"
                [formControlName]="lvl3">
          <option *ngFor="let c of activityPacket.values.lvl3" [ngValue]="c.code">{{c?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div *ngIf="activityPacket.labels.lvl4 != null" class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel>{{activityPacket.labels.lvl4}}</label>
        <select rgiRxNativeSelect attr.data-qa="activity_{{activityPacket.labels.lvl4.toLowerCase()}}"
                [formControlName]="lvl4">
          <option *ngFor="let c of activityPacket.values.lvl4" [ngValue]="c.code">{{c?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
  </div>
</form>

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
import {MotorPostsalesTranslationsKeys} from './motor-psales-translations-keys';


export const MOTOR_PSALES_IT: MotorPostsalesTranslationsKeys = {
  _MOTORPSALES_: {
    _BTN_: {

    },
    _LABEL_: {

    },
    _MSG_: {

    },
    _TITLE_: {

    },
    _STEPS_: {
      _EXTENSION_LIST_: 'Lista estensioni',
    },
    _SESSION_TITLE_: {
      _EXTENSION_GREEN_CARD_: 'Estensione Carta Verde polizza n. {{policyNumber}}',
      _ISSUE_GREEN_CARD_: 'Emissione Carta Verde polizza n. {{policyNumber}}'
    }
  },
  _VOID_: '',
};

import {GenericEntity} from './generic-entity';
import {ClaimsReportYear} from './claims-report-year';
import {ClaimDetail} from './claim-detail';
import {Enumerated} from './enumerated';

import {InsuranceBackgroundProperties} from './insurance-background-properties';

export class InsuranceStatus {

  public relative: boolean;
  public relativeEnabled: boolean;
  public originUniqueClassOrigin: string;
  public relativePlate: string;
  public messages: Array<GenericEntity>;

  constructor(
    public bersani: boolean,
    public companyClassToEnum: GenericEntity,
    public companyClassTo: string,
    public companyClassFromEnum: GenericEntity,
    public companyClassFrom: string,
    public originUniqueClassTo: string,
    public originUniqueClassFrom: string,
    public rateForm: GenericEntity,
    public companyId: GenericEntity,
    public deductiblesAmount: number,
    public startObservation: Date,
    public originPlateCountry: string,
    public deductiblesNumber: number,
    public originChassis: string,
    public originPolicyNumber: string,
    public pejusPerc: number,
    public originPlate: string,
    public originPlateType: GenericEntity,
    public policyExpirationDate: Date,
    public ingressoBersani: GenericEntity,
    public vehicleOrigin: GenericEntity,
    public originSpecialPlateType: GenericEntity,
    public lastYearOfTheReport: string,
    public claimsReportYears: Array<ClaimsReportYear>,
    public claimDetails: Array<ClaimDetail>,
    public cuValues?: Array<GenericEntity>,
    public pejusValues?: Array<GenericEntity>,
    public originRateValues?: Array<GenericEntity>,
    public vehicleOriginValues?: Array<Enumerated>,
    public bersaniValues?: Array<GenericEntity>,
    public originPlateTypeValues?: Array<GenericEntity>,
    public originSpecialPlateTypeValues?: Array<GenericEntity>,
    public properties?: InsuranceBackgroundProperties,
    public completenessErrors?: Array<GenericEntity>,
    public correctnessErrors?: Array<GenericEntity>,
    public reloadPolicyTechinicalData?: boolean,
    public iurCode?: string,
    public lawCode?: GenericEntity,
    public name?: string,
    public surnameOrCompanyName?: string,
    public fiscalCode?: string,
    public vehicleTypeOrigin?: GenericEntity,
    public vehicleTypeOriginValues?: Array<GenericEntity>,
    public vehicleTypeRelative?: GenericEntity,
    public vehicleTypeRelativeValues?: Array<GenericEntity>,
  ) {
  }

  public static createEmptyObject(): InsuranceStatus {
    return new InsuranceStatus(
      null, null, null, null, null, null, null,
      null, null, null, null, null, null, null, null, null,
      null, null, null, null, null, null, null, null, null);
  }

  public updateOriginCU(newValue: string) {
    this.originUniqueClassFrom = newValue;
  }

  public updateAssignedCU(newValue: string) {
    this.originUniqueClassTo = newValue;
  }

  public updateOriginRate(newValue: GenericEntity) {
    this.rateForm = newValue;
  }

  public updateOriginChassis(newValue: string) {
    this.originChassis = newValue;
  }

  public updatePolicyExpirationDate(newValue: Date) {
    this.policyExpirationDate = newValue;
  }

  public updatePejus(newValue: number) {
    this.pejusPerc = newValue;
  }

  public updateDeductiblesAmount(newValue: number) {
    this.deductiblesAmount = newValue;
  }

  public updateDeductiblesNumber(newValue: number) {
    this.deductiblesNumber = newValue;
  }

  public updateVehicleOrigin(newValue: Enumerated) {
    this.vehicleOrigin = new GenericEntity(newValue.id, newValue.code, newValue.description);
  }

  public updateOriginPlateType(newValue: GenericEntity) {
    this.originPlateType = newValue;
  }

  public updateSpecialOriginPlateType(newValue: GenericEntity) {
    this.originSpecialPlateType = newValue;
  }

  public updateIngressoBersani(newValue: GenericEntity) {
    this.ingressoBersani = newValue;
  }

  public updatePolicyNumber(newValue: string) {
    this.originPolicyNumber = newValue;
  }

  public updateCompany(newValue: GenericEntity) {
    this.companyId = newValue;
  }

  public updateLastYear(newValue: string) {
    this.lastYearOfTheReport = newValue;
  }

  public updateClaims(
    year: number,
    claimsType: string,
    claimsNum: string,
    realYear: number) {

    const claimsReport = this.claimsReportYears.find((element) => {
      return (element.year - 1) === year;
    });

    claimsNum = this.convertStringIntoNegativeValue(claimsNum);

    claimsReport.yearReal = realYear;

    if (claimsReport) {
      if (claimsType === 'principal_total') {
        claimsReport.principalResponsibility.total = claimsNum;
      } else if (claimsType === 'principal_people') {
        claimsReport.principalResponsibility.ofWhichPerson = claimsNum;
      } else if (claimsType === 'principal_things') {
        claimsReport.principalResponsibility.ofWhichThings = claimsNum;
      } else if (claimsType === 'principal_mixed') {
        claimsReport.principalResponsibility.ofWhichMixed = claimsNum;
      } else if (claimsType === 'pair_total') {
        claimsReport.pairResponsibility.total = claimsNum;
      } else if (claimsType === 'pair_people') {
        claimsReport.pairResponsibility.ofWhichPerson = claimsNum;
      } else if (claimsType === 'pair_things') {
        claimsReport.pairResponsibility.ofWhichThings = claimsNum;
      } else if (claimsType === 'pair_mixed') {
        claimsReport.pairResponsibility.ofWhichMixed = claimsNum;
      }
    }
  }

  public updateVehicleTypeOrigin(newValue: GenericEntity) {
    this.vehicleTypeOrigin = newValue;
  }

  public updateVehicleTypeRelavite(newValue: GenericEntity) {
    this.vehicleTypeRelative = newValue;
  }

  private convertStringIntoNegativeValue(value): string {
    if (value !== null && value !== undefined) {
      if (value === 'N.A.') {
        return '-1';
      } else if (value === 'N.D.') {
        return '-2';
      } else if (value === '--') {
        return '-3';
      } else {
        return value;
      }
    }
  }

}


import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  constructor(
    @Inject('functionListService') private functionListService: any,
    @Inject('CLAIMS_FEATURES') private CLAIMS_FEATURES: any,
    @Inject('authService') private authService: any
  ) { }

  isAuthorizedFor(functionName: string): boolean {
    return this.functionListService.isAuthorizedFor(functionName);
  }

  isAuthorizedForClaimFeature(functionName: string): boolean {
    return this.isAuthorizedFor(this.CLAIMS_FEATURES[functionName]);
  }

  isAuthorizedForByCode(functionCode: string): boolean {
    let isAuthorized = false;
    const functionList = this.authService.getOperator().enabledFeatures;
    if (functionList && functionList.indexOf(functionCode) >= 0) {
      isAuthorized = true;
    }
    return isAuthorized;
  }

}

<div *ngIf="!summary" class="row" [formGroup]="formGroup">
  <div class="col-lg-12">
    <div class="col-lg-6"> 
          <label class="label-core" translate>lpc_payment_type</label>
          <lpc-required *ngIf="required" required="true"></lpc-required>
    </div>
    <div class="col-lg-6" >
      <ng-select formControlName="idPaymentType" (change)="onChangeIdPaymentType($event)" [clearable]="false" data-qa="PaymentType" [ngClass]="{'disabled': !isPaymentTypeEditable}"
                 data-obb="true">
        <ng-option *ngFor="let paymentType of paymentTypeDefinitions"  [value]="paymentType.id">{{paymentType.label}}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div *ngIf="paymentFieldDefinitions.length" class="col-lg-12" id="payment-fields">
    <lpc-payment [editable]="editable" [parentForm]="formGroup" [pagamenti]="paymentFieldDefinitions">
    </lpc-payment>
  </div>
</div>
<div *ngIf="summary" class="row" [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-6">
      <span for="label" translate>lpc_payment_method</span>
      <span for="value">: {{ paymentSelectedOptionLabel }}</span>
    </div>
  </div>
</div>

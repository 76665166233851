<div *ngIf="bindings && bindings.length > 0">
  <div *ngFor="let binding of bindings; let i = index" attr.data-qa="{{'lien_' + i}}">
    <!----><!---->

    <div class="rgi-ui-grid rgi-ui-grid-no-gutter">
      <div class="rgi-ui-col">
        <div class="story-grid-column-content" translate>RE_ISSUE.BODY_BINDING</div>
      </div>
      <div class="rgi-ui-col">
        <div class="story-grid-column-content">{{ binding.bodyBinding.nominative }}</div>
      </div>
      <div class="rgi-ui-col">
        <div class="story-grid-column-content" translate>RE_ISSUE.EFFECT_DATE</div>
      </div>
      <div class="rgi-ui-col">
        <div class="story-grid-column-content">{{ binding.effectDate | date:'dd/MM/yyyy'}}</div>
      </div>
      <div class="rgi-ui-col">
        <div class="story-grid-column-content" translate>RE_ISSUE.EXPIRY_DATE</div>
      </div>
      <div class="rgi-ui-col">
        <div class="story-grid-column-content">{{ binding.expiryDate | date:'dd/MM/yyyy'}}</div>
      </div>
      <div class="rgi-ui-col">
        <div class="story-grid-column-content">
          <button class=" rgi-re-pull-right " (click)="onDelete.emit(i)"
            attr.data-qa="{{'delete_lien_' + i}}">
            <span class="rgi-ui-icon-delete2"></span>
          </button>
          <button class="rgi-re-pull-right " (click)="onEdit.emit(binding)"
            attr.data-qa="{{'update_lien_' + i}}">
            <span class="rgi-ui-icon-edit2"></span>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ChatService } from '../../chat.service';
import { ChatMessage } from '../../dto/chat-message';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatUser } from '../../dto/chat-user';

@Component({
  selector: 'claims-chat-new-message',
  templateUrl: './chat-new-message.component.html',
  styleUrls: ['./chat-new-message.component.scss']
})
export class ChatNewMessageComponent implements OnInit {

  chatMessage: ChatMessage;
  errorMessage: string;
  typedClaimNumber: string;
  typedPotClaimNumber: string;
  @Input() recipient: ChatUser;
  @Input() senderName: string;
  @Input() claim: { claimNumber: string };
  @Input() potentialClaim: { reportNumber: string };
  @Output() eventSent = new EventEmitter<Response>();

  constructor(private chatService: ChatService) { }

  ngOnInit() {
    const sender = new ChatUser();
    sender.userName = this.senderName;
    this.chatMessage = new ChatMessage(sender, this.recipient);
  }

  onSendMessage(form: NgForm) {
    this.errorMessage = '';
    this.chatMessage.subject = form.form.value.subject;
    this.chatMessage.body = form.form.value.body;
    this.chatMessage.claimNumber = this.claim ? this.claim.claimNumber : form.form.value.claimNumber;
    this.chatMessage.potentialClaimNumber = this.potentialClaim ? this.potentialClaim.reportNumber : form.form.value.potentialClaimNumber;
    form.reset();
    this.chatService.sendMessage(this.chatMessage)
      .subscribe(
        (response: Response) => {
          this.eventSent.emit(response);
        },
        (error: HttpErrorResponse) => {
          this.eventSent.emit(null);
          this.errorMessage = error.error.messages[0];
        });
  }

  onKeyUpClaim() {

  }

  onKeyUpPotClaim() {

  }
}

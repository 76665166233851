import { Component, OnInit, EventEmitter, Output, Input, Inject } from '@angular/core';
import {
  EditablePanelContentComponent,
  PanelService, PanelSignal,
  SelectableCoverture,
  Outcome,
  SelectableCovertureResponse,
  DomainTranslatorFieldService,
  Field,
  DamagedAsset,
  Policy,
  AlertService,
  FunctionService
} from '@rgi/digital-claims-common-angular';
import { ClaimOpeningDetailsService } from '../claim-opening-details.service';
import { ClaimOpeningService } from '../../../claim-opening.service';
import { DamageCategoriesCacheEntity } from './dto/damage-categories-cache-entity';
import { DamageCategoryEntity } from './dto/damage-category-entity';
import { DamageCategoryCheckBox } from './dto/damage-category-checkbox';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-damage-category-panel-content',
  templateUrl: './damage-category-panel-content.component.html',
  styleUrls: ['./damage-category-panel-content.component.scss']
})
export class DamageCategoryPanelContentComponent extends EditablePanelContentComponent implements OnInit {

  @Output() stepperEventEmitter = new EventEmitter<number>();
  @Output() rectifyEventEmitter = new EventEmitter<boolean>();
  @Output() coverturesEventEmitter = new EventEmitter<DamageCategoriesCacheEntity>();
  @Input() public card: any;
  @Input() pes: any;
  @Input() abilitaAperturaSenzaCausale: boolean;
  @Input() policy: Policy;
  @Input() selectCovertures: boolean;


  selectableCoverturesResponse: SelectableCovertureResponse;
  selectableCovertures: SelectableCoverture[];
  selectableCoverturesFields: Field[][];
  compatibleAssetsCodes: string[];

  branchFilter = '';
  covertureTypeFilter = '';

  covertureTypes: any[];
  branches: any[];
  checkboxContent: DamageCategoryCheckBox[];
  selectedCategories: DamageCategoryCheckBox[] = [];

  validationMessages: string[];

  displayFields: boolean[] = [];
  damageCategoriesDataInternal: DamageCategoryEntity[];
  selectedAssetInternal: DamagedAsset[];
  isSelectedThirdPartyInternal: boolean;
  assetChanged: boolean;
  policyVerified: boolean = false;
  regAmm: boolean = false;
  regTech: boolean = false;
  blockSave: boolean = false;
  listFunz: any[];
  @Output() editDone = new EventEmitter<boolean>();

  // fieldsForRow: number = 2;
  // fieldCount: number;

  dynamicDataForm: UntypedFormGroup;

  constructor(
    panelService: PanelService,
    private claimOpeningService: ClaimOpeningService,
    private claimOpeningDetailsService: ClaimOpeningDetailsService,
    private domainTranslatorFieldService: DomainTranslatorFieldService,
    private alertService: AlertService,
    private functionService: FunctionService,
    public formBuilder: UntypedFormBuilder,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    private rxTranslationsService: RgiRxTranslationService) {
    super(panelService);
  }

  ngOnInit() {

    // if (this.abilitaAperturaSenzaCausale) {
    //   this.setPanelComplete();
    // } else {
    this.setPanelIncomplete();
    this.disablePanel();
    this.listFunz = this.authService.getOperator().enabledFeatures;
    this.blockSave = false;

    this.listFunz = this.authService.getOperator().enabledFeatures;

    if (this.selectCovertures) {
      this.enabled = true;
      this.initializeComponent();
    }
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (panelSignal === PanelSignal.open) {
      this.enableAndOpenContent();
    }
    if (this.damageCategoriesDataInternal) {
      this.disablePanelContent();
      this.setPanelComplete();
    }

    this.initializeComponent();

  }

  initializeComponent() {
    if (!this.selectableCovertures || this.assetChanged) {
      this.assetChanged = false;
      this.selectableCoverturesResponse = this.claimOpeningService.getSessionSelectableCoverturesResponse(this.card.id);
      this.selectableCovertures = this.selectableCoverturesResponse.covertures;

      const formVariables = {};
      // manage dynamic fields dependencies
      this.selectableCoverturesFields = [];
      this.compatibleAssetsCodes = [];
      if (this.selectableCovertures) {
        this.selectableCovertures.forEach((element) => {
          const assetCompatible = this.isCovertureCompatible(element);
          if (assetCompatible) {
            this.compatibleAssetsCodes.push(element.coverturecode + element.coverturetypecode + element.riskcode);
            this.selectableCoverturesFields[element.coverturecode + element.coverturetypecode + element.riskcode] =
            element.fieldscontainer.fields ? this.domainTranslatorFieldService.toFields(element.fieldscontainer.fields) : [];

            this.selectableCoverturesFields[element.coverturecode +
              element.coverturetypecode + element.riskcode].forEach((field: Field) => {
                if (field.mandatory && field.type !== 'DATE') {
                  formVariables[field.id] = [{ value: '', disabled: field.disabled }, Validators.required];
                } else {
                  formVariables[field.id] = [{ value: '', disabled: field.disabled }];
                }
              });
          }
        });
      }

      this.dynamicDataForm = this.formBuilder.group(formVariables);

      // enable / disable fields initialization
      for (const key in this.selectableCoverturesFields) {
        if (this.selectableCoverturesFields.hasOwnProperty(key)) {
          const element = this.selectableCoverturesFields[key];

          element.forEach((field: Field) => {
            if (this.damageCategoriesDataInternal) {
              field.value = this.initSavedField(field.id);
            }
            field.init();
          });

        }
      }

      this.branches = this.getBranches();
      this.covertureTypes = this.getCovertureTypes();
      this.checkboxContent = this.getCheckBoxItems();

      if (this.checkboxContent.length === 1) {

        this.selectedCategories.push(this.checkboxContent[0]);

        this.displayFields[this.checkboxContent[0].codeCoverture +
          this.checkboxContent[0].codeCovertureType + this.checkboxContent[0].riskcode] = true;
      } else {
        if (this.damageCategoriesDataInternal) {
          this.checkboxContent.forEach(checkBox => {
            this.damageCategoriesDataInternal.forEach(savedCoverture => {
              if (checkBox.codeBranch === savedCoverture.branchclaimcode &&
                checkBox.codeCovertureType === savedCoverture.coverturetypecode &&
                checkBox.codeCoverture === savedCoverture.coverturecode &&
                checkBox.riskcode === savedCoverture.riskcode) {
                checkBox.forceCheck = true;
                this.selectedCategories.push(checkBox);
                this.displayFields[checkBox.codeCoverture +
                  checkBox.codeCovertureType + checkBox.riskcode] = true;
              }
            });
          });
        }
      }
    }
    if (this.damageCategoriesDataInternal || this.abilitaAperturaSenzaCausale) {
      this.claimOpeningDetailsService.saveDamageCategoriesInCache(this.createDamageCategoryToSaveInCache()).subscribe(
        () => {

          console.log('Damage category cache initialized');

          this.signalToNextPanel(PanelSignal.open);

        }
      );

    }
/*      const entity = this.createDamageCategoryToSaveInCache();
      if (!this.selectCovertures) {
        this.claimOpeningDetailsService.saveDamageCategoriesInCache(entity).subscribe(
          () => {
            console.log('Damage category cache initialized');
            this.signalToNextPanel(PanelSignal.open);
          }
        );
      } else {
        this.coverturesEventEmitter.emit(entity);
      }
    }*/
    if (this.pes && this.pes.idClaim) {
      this.disablePanel();
      this.disablePanelContent();
    }

/*    if (this.abilitaAperturaSenzaCausale) {
      this.setPanelComplete();
    }*/
  }

  isCovertureCompatible(coverture: SelectableCoverture) {
    if (this.isSelectedThirdPartyInternal && (!coverture.partiescontainer || !coverture.partiescontainer.thirdsubject)) {
      return false;
    }
    if (this.policy.isDummyPolicy && this.isPresentSameAssetTypeInSelectedAsset(coverture)) {
      return true;
    }

    // [AMTrust] If policy does not have damaged asset
    if (!coverture.assetscontainer.damagedassets || coverture.assetscontainer.damagedassets.length === 0) {

      for (const asset of this.selectedAssetInternal) {
        if (asset.third && coverture.assetscontainer.otherasset &&
          asset.assettype === coverture.assetscontainer.assettype) {
          return true;
        }
      }
    } else {
      for (const damagedAsset of coverture.assetscontainer.damagedassets) {
        if (!this.selectedAssetInternal) {
          return true;
        } else {
          for (const asset of this.selectedAssetInternal) {
            if (asset.third && coverture.assetscontainer.otherasset &&
              asset.assettype === coverture.assetscontainer.assettype) {
              return true;
            }
            if (asset.id === damagedAsset.id) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  isPresentSameAssetTypeInSelectedAsset(coverture: SelectableCoverture): boolean {
    for (const selectedAsset of this.selectedAssetInternal) {
      if (selectedAsset.assettype === coverture.assetscontainer.assettype) {
        return true;
      }
    }
    return false;
  }

  isThirdAssetSelected() {
    for (const asset of this.selectedAssetInternal) {
      if (asset.third) {
        return true;
      }
    }
    return false;
  }

  @Input()
  set selectedAsset(asset: DamagedAsset[]) {
    this.assetChanged = this.selectedAssetInternal && asset !== this.selectedAssetInternal;

    this.selectedAssetInternal = asset;
  }

  @Input()
  set isSelectedThirdParty(value: boolean) {
    console.log('isSelectedThirdParty - damage-category-panel-content -> ', value);
    this.isSelectedThirdPartyInternal = value;
  }

  initSavedField(id: string): string {
    for (const damageCat of this.damageCategoriesDataInternal) {
      for (const field of damageCat.fields) {
        if (field.id === id) {
          return this.domainTranslatorFieldService.toField(field).value;
        }
      }
    }
    return '';
  }

  @Input()
  set damageCategoriesData(damageCategoriesData: DamageCategoriesCacheEntity) {
    if (damageCategoriesData) {
      this.damageCategoriesDataInternal = damageCategoriesData.damagecategories;
    }
  }

  codeEquals(input: any) {
    return ((x: { code: any; }) => input === x.code);
  }

  checkBoxEquals(input: any) {
    return ((x: { codeBranch: string, codeCovertureType: string, codeCoverture: string, riskcode: string}) =>
      input.codeBranch === x.codeBranch &&
      input.codeCovertureType === x.codeCovertureType &&
      input.codeCoverture === x.codeCoverture &&
      input.riskcode === x.riskcode
    );
  }

  getBranches() {
    const branches: { code: string, name: string }[] = [];
    this.selectableCoverturesResponse.covertures.forEach(
      (selectableCoverture: SelectableCoverture) => {
        const el = { code: selectableCoverture.branchclaimcode, name: selectableCoverture.branchclaimdesc };
        if (branches.findIndex(this.codeEquals(el.code)) === -1 && this.isCovertureCompatible(selectableCoverture)) {
          branches.push(el);
        }
      }
    );
    return branches;
  }

  getCovertureTypes() {
    const covertures: { code: string, name: string }[] = [];
    this.selectableCoverturesResponse.covertures.forEach(
      (selectableCoverture: SelectableCoverture) => {
        const el = {
          code: selectableCoverture.coverturetypecode, name: selectableCoverture.coverturetypedesc
        };
        if (covertures.findIndex(this.codeEquals(el.code)) === -1 && this.isCovertureCompatible(selectableCoverture)) {
          covertures.push(el);
        }
      }
    );
    return covertures;
  }

  getCheckBoxItems(): DamageCategoryCheckBox[] {
    const items: DamageCategoryCheckBox[] = [];
    this.selectableCoverturesResponse.covertures.forEach(
      (selectableCoverture: SelectableCoverture) => {
        if (this.compatibleAssetsCodes.indexOf(selectableCoverture.coverturecode +
          selectableCoverture.coverturetypecode + selectableCoverture.riskcode) !== -1) {
          const el = new DamageCategoryCheckBox(
            selectableCoverture.branchclaimcode,
            selectableCoverture.coverturetypecode,
            selectableCoverture.coverturecode,
            selectableCoverture.riskcode,
            selectableCoverture.branchclaimdesc + ' - ' +
            selectableCoverture.coverturetypedesc + ' - ' +
            selectableCoverture.coverturedescr + ' - ' + selectableCoverture.riskdesc,
            selectableCoverture.branchclaimcode + '#' +
            selectableCoverture.coverturecode + '#' + selectableCoverture.coverturecode + '#' + selectableCoverture.riskcode,
            selectableCoverture.riskFactors,
            selectableCoverture.covertureFactors,
            selectableCoverture.dShortage,
            selectableCoverture.existingCoverage
          );



          if (items.findIndex(this.checkBoxEquals(el)) === -1) {
            items.push(el);
          }
        }
      }
    );
    return items;
  }

  onConfirm() {
    if (this.validateContent()) {
      // let message = '';
      // this.selectedCategories.forEach(
      //   (cat: DamageCategoryCheckBox) => {
      //     message += cat.description + '\n';
      //   }
      // )
      // if (confirm('Stai inserendo le seguenti categorie:\n' + message)) {

      let validShortageSelected = true;

      this.selectedCategories.forEach((category: DamageCategoryCheckBox) => {
        if (validShortageSelected) {
          validShortageSelected = this.isValidShortageDays(category);
        }
      });

      if (validShortageSelected) {
        this.blockSave = false;
        if (this.selectableCoverturesResponse.claimsMade || this.selectableCoverturesResponse.postuma) {
          const risks = new Array<string>();
          this.selectedCategories.forEach((category: DamageCategoryCheckBox) => {
            risks.push(category.riskcode);
          });

          this.policyVerified = true;
          this.eventService.broadcastEvent('start-loader');
          const compliantDate = new Date(this.claimOpeningService.getCompliantDate());
          this.claimOpeningDetailsService
            .claimVerifyClMadePostuma(risks, this.claimOpeningService.getSessionUuid(this.card.id))
            .subscribe((response: any[]) => {
                response.forEach(aOut => {
                  if (aOut.description === 'REGAMM') {
                    this.regAmm = aOut.code === 'true' ? true : false;
                  }
                  if (aOut.description === 'REGTECH') {
                    this.regTech = aOut.code === 'true' ? true : false;
                  }
                });
                if (!this.regTech &&
                  this.listFunz && this.listFunz.indexOf('SXIPNV') < 0) {
                  this.blockSave = true;
                  this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY').subscribe(
                    res => this.alertService.translatedMessage(res)
                  )
                } else if (!this.regAmm &&
                  !this.functionService.isAuthorizedForClaimFeature('ADMINISTRATIVE_FORCING_COMPLAINT')) {
                  this.blockSave = true;
                  this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY').subscribe(
                    res => this.alertService.translatedMessage(res)
                  )
                } else if (!this.regAmm && this.policy.isCanceled) {
                  const cancellationDate = new Date(this.policy.cancellationDate);
                  if (compliantDate >= cancellationDate) {
                    if (this.listFunz.indexOf('SXAPPA') > 0) {
                      this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY').subscribe(
                        res => this.alertService.translatedMessage(res)
                      )
                    } else {
                      this.blockSave = true;
                      this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY').subscribe(
                        res => this.alertService.translatedMessage(res)
                      )
                    }
                  }
                }

                if (!this.blockSave) {
                  this.storeData();
                  this.editDone.emit(true);
                }
                this.eventService.broadcastEvent('stop-loader');
              },
              (error) => {
                this.blockSave = true;
                this.eventService.broadcastEvent('stop-loader');
              });

        } else {
          this.storeData();
          this.editDone.emit(true);
        }
      }
      // }
    }
  }

  visualBoolean(value: boolean) {
    if (value) {
      return 'YES';
    } else {
      return 'NO';
    }
  }

  protected validateContent(): boolean {
    let valid = true;
    this.validationMessages = [];

    if (this.selectedCategories.length === 0 && !this.abilitaAperturaSenzaCausale) {
      this.validationMessages.push('You have to select at least one type of loss.');
      valid = false;
    }

    for (const content in this.selectableCoverturesFields) {
      if (this.selectableCoverturesFields.hasOwnProperty(content)) {
        const element: Field[] = this.selectableCoverturesFields[content];
        if (this.displayFields[content]) {
          element.forEach((el: Field) => {
            el.invalid = false;
            if (!el.disabled && el.mandatory && !el.value && !this.abilitaAperturaSenzaCausale) {
              if (this.validationMessages.length === 0) {
                el.invalid = true;
                this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(
                  res =>  this.validationMessages.push(res)
                )
              }
              valid = false;
            }
          });
        }

      }
    }
    return valid;
  }

  isValidShortageDays(cat: DamageCategoryCheckBox) {
    let valid = true;
    if (cat.dShortage && Number(cat.dShortage) > 0 && !cat.existingCoverage) {
      // ALERT: blocco per utente non abilitato all'apertura in scopertura tecnica
      if (this.listFunz && !(this.listFunz.indexOf('SXIPNV') > 0)) {
        this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY').subscribe(
          res => this.alertService.translatedMessage(res)
        )
        valid = false;
      }
    }
    return valid;
  }

  onCheckboxChange(cat: DamageCategoryCheckBox, event: any) {
    cat.forceCheck = false;
    if (this.selectedCategories.indexOf(cat) === -1) {
      this.selectedCategories.push(cat);
    } else {
      this.selectedCategories.splice(this.selectedCategories.indexOf(cat), 1);
    }

    if (event.target.checked) {
      this.displayFields[cat.codeCoverture + cat.codeCovertureType + cat.riskcode] = true;
      //   for (const field of this.selectableCoverturesFields[cat.codeCoverture]) {
      //     field.activateIfOrphan();
      //   }
    } else {
      this.displayFields[cat.codeCoverture + cat.codeCovertureType + cat.riskcode] = false;
      if (this.damageCategoriesDataInternal) {
        this.damageCategoriesDataInternal.forEach(category => {
          if (cat.codeBranch === category.branchclaimcode &&
            cat.codeCoverture === category.coverturecode &&
            cat.codeCovertureType === category.coverturetypecode &&
            cat.riskcode === category.riskcode) {
            if (this.damageCategoriesDataInternal.indexOf(category) !== -1) {
              this.damageCategoriesDataInternal.splice(this.damageCategoriesDataInternal.indexOf(category), 1);
            }
          }
        });
      }
      //   for (const field of this.selectableCoverturesFields[cat.codeCoverture]) {
      //     field.deactivate();
      //   }
    }

  }

  // openFieldSection(codeCoverture: string, codeCovertureType: string, riskcode: string) {
  //   if (this.displayFields[codeCoverture + codeCovertureType + riskcode]) {
  //     return this.displayFields[codeCoverture + codeCovertureType + riskcode];
  //   }
  //   return false;
  // }

  showCheckBox(checkBox: DamageCategoryCheckBox): boolean {
    if (this.branchFilter && checkBox.codeBranch !== this.branchFilter) {
      return false;
    }
    if (this.covertureTypeFilter && checkBox.codeCovertureType !== this.covertureTypeFilter) {
      return false;
    }
    return true;
  }

  createDamageCategoryToSaveInCache(): DamageCategoriesCacheEntity {
    const damageCategories: DamageCategoryEntity[] = [];
    this.selectedCategories.forEach(
      (category: DamageCategoryCheckBox) => {
        const domainFields = this.domainTranslatorFieldService.toDomainFields(
          this.selectableCoverturesFields[category.codeCoverture + category.codeCovertureType + category.riskcode]
        );
        const damageCategoryEntity = new DamageCategoryEntity(
          category.codeCoverture,
          category.codeCovertureType,
          category.riskcode,
          category.codeBranch,
          domainFields,
          category.description
        );
        damageCategories.push(damageCategoryEntity);
      }
    );
    return new DamageCategoriesCacheEntity(this.claimOpeningService.getSessionUuid(this.card.id),
                                           damageCategories, this.regAmm, this.regTech);
  }

  protected storeData() {
    const entity = this.createDamageCategoryToSaveInCache();

    if (!this.selectCovertures) {
      this.eventService.broadcastEvent('start-loader');
      this.claimOpeningDetailsService.saveDamageCategoriesInCache(entity).subscribe(
        (response: Outcome) => {
          if (response.code === Outcome.OK) {
            this.setPanelComplete();
            this.claimOpeningService.setSessionEventSet(this.card.id);
            this.eventService.broadcastEvent('stop-loader');
            this.setPanelComplete();
            this.disablePanelContent();
            this.signalToNextPanel(PanelSignal.open);
            this.signalToNextPanel(PanelSignal.init);
            this.stepperEventEmitter.emit(5);
          } else {
            this.validationMessages = [response.descr];
          }
        },
        (error: Error) => {
          this.validationMessages = ['Error on saving'];
          console.error(error);
          this.eventService.broadcastEvent('stop-loader');
        });
      } else {
      if (this.abilitaAperturaSenzaCausale || entity.damagecategories.length > 0) {
        this.setPanelComplete();
      }
      this.coverturesEventEmitter.emit(entity);
      }
  }

  // newLine() {
  //   this.fieldCount++;
  //   if (this.fieldCount >= this.fieldsForRow) {
  //     this.fieldCount = 0;
  //     return true;
  //   }
  // }

  getIndexFromContent(content: DamageCategoryCheckBox) {
    return content.codeCoverture + content.codeCovertureType + content.riskcode;
  }

  getShortageDays(content: DamageCategoryCheckBox) {
    if (content.dShortage && Number(content.dShortage) > 0) {
      return 'Giorni di Carenza: ' + content.dShortage;
    }
  }

  rectify() {
    this.rectifyEventEmitter.emit(true);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { DigitalClaimsCommonAngularModule, enumServiceProvider, getSystemPropertyProvider } from '@rgi/digital-claims-common-angular';
import { BrowserModule } from '@angular/platform-browser';
import { EditorModule } from '@tinymce/tinymce-angular';
import { RgiRxAutoCompleteModule, RgiRxDatePickerModule, RgiRxDragDropModule, RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxNativeDateTimeModule, RgiRxPanelModule, RgiRxTabModule, RgiRxTableModule, RgiRxTooltipModule } from '@rgi/rx/ui';
import { ActiveRoute, RgiRxRoutingModule } from '@rgi/rx/router';
import { PAYMENTS_DASHBOARD_ROUTES } from './payments-dashboard.route';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../../assets/i18n/translations';
import { RgiRxPortalModule } from '@rgi/rx/portal';
import { RgiRxFormModule } from '@rgi/rx';
import { RgiAnagModule } from '@rgi/anag';
import { PaymentsDashboardIntegrationService } from './configurations/payments-dashboard-integration.service';
import { PAYMENTS_DASHBOARD_CARD } from './configurations/payments-dashboard.card';
import { PaymentsSearchboardService } from './services/payments-searchboard.service';
import { PaymentsSearchboardStateManager } from './state-managers/payments-searchboard.state.manager';
import { ValidationConsolidationService } from './services/validation-consolidation.service';
import { ValidationConsolidationStateManager } from './state-managers/validation-consolidation.state.manager';
import { ForfaitService } from './services/forfait.service';
import { PaymentsSearchboardComponent } from './components/payments-searchboard/payments-searchboard.component';
import { TableValidationConsolidationComponent } from './components/table-validation-consolidation/table-validation-consolidation.component';
import { TableForfaitComponent } from './components/table-forfait/table-forfait.component';
import { ModalForfaitComponent } from './components/table-forfait/modal-forfait/modal-forfait.component';
import { AddEditForfaitComponent } from './components/table-forfait/add-edit-forfait/add-edit-forfait.component';
import { ForfaitStateManager } from './state-managers/forfait.state.manager';


@NgModule({
  declarations: [
    PaymentsSearchboardComponent,
    TableValidationConsolidationComponent,
    TableForfaitComponent,
    ModalForfaitComponent,
    AddEditForfaitComponent
  ],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    NgSelectModule,
    DigitalClaimsCommonAngularModule,
    RgiRxI18nModule,
    RgiRxFormElementsModule,
    RgiRxDatePickerModule,
    RgiRxNativeDateTimeModule,
    RgiRxPortalModule.forRoot(PAYMENTS_DASHBOARD_CARD),
    RgiRxRoutingModule.forRoot(PAYMENTS_DASHBOARD_ROUTES),
    RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    FormsModule,
    ReactiveFormsModule,
    RgiRxAutoCompleteModule,
    RgiRxFormModule,
    RgiRxTooltipModule,
    RgiRxExpansionModule,
    RgiRxDragDropModule,
    RgiAnagModule,
    BrowserModule,
    RgiRxTableModule,
    EditorModule,
    RgiRxModalModule,
    RgiRxPanelModule,
    RgiRxFormElementsModule,
    RgiRxTabModule,
    RgiRxExpansionModule
  ],
  exports: [
    PaymentsSearchboardComponent,
    TableValidationConsolidationComponent,
    TableForfaitComponent,
    ModalForfaitComponent,
    AddEditForfaitComponent
  ],
  providers: [
    enumServiceProvider,
    getSystemPropertyProvider,
    PaymentsSearchboardService,
    ForfaitService,
    ForfaitStateManager,
    ValidationConsolidationService,
    UntypedFormBuilder,
    PaymentsDashboardIntegrationService,
    PaymentsSearchboardStateManager,
    ValidationConsolidationStateManager,
    ActiveRoute,
  ],
})
export class PaymentsDashboardModule { }
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {RePostsalesCancellationState} from '../../re-postsale-state-manager/re-postsales-cancellation-state';
import {
  RePostsalesStateManagerCancellationStartService
} from '../../re-postsale-state-manager/re-postsales-state-manager-cancellation-start.service';
import {of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';

@Component({
  selector: 're-cancellation-start',
  templateUrl: './re-cancellation-start.component.html',
  styleUrls: ['./re-cancellation-start.component.css'],
})
export class ReCancellationStartComponent implements OnInit {

  currentStep = 0;
  stepperLabels = ['RE_ISSUE.DATA_CANCELLATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.CONFIRMATION_CANCELLATION'];
  cancellationForm: UntypedFormGroup;
  premiumRepaymentForm: UntypedFormGroup;
  errorMessage: string;
  policyOperationsInput: any;

  constructor(protected formBuilder: UntypedFormBuilder,
              protected routingService: RoutingService,
              private activeRoute: ActiveRoute,
              public stateManager: RePostsalesStateManagerCancellationStartService<RePostsalesCancellationState>,
              @Inject('sessionService') private portalSession: any,
              @Inject('coreResult') private coreResult: any,
              private translateService: RgiRxTranslationService,
              public pushMessageHandler: PushMessageHandlerService, ) {
    this.cancellationForm = this.formBuilder.group({
      cancellationReason: [, Validators.required],
      issueDate: [new Date()],
      effectDate: [{value: '', disabled: true}]
    });
    this.premiumRepaymentForm = this.formBuilder.group({
      taxes: [, ],
      taxable: [, ],
      rights: [, Validators.pattern('[0-9]')]
    });
  }

  ngOnInit() {
    this.setTranslations();
    this.pushMessageHandler.clearTag('errorMessage');
    const state = this.stateManager.getCurrentState();
    if (!state.cancellationReasons) {
      this.policyOperationsInput = this.createReqBody(state);
      this.stateManager.initAllData(state, this.policyOperationsInput).subscribe(state1 => {
        const operation = [];
        if (state1) {
          state1.forEach(x => {
            if (x.type === '3') {
              operation.push(x.operation);
            }
          });
          state.cancellationReasons = operation;
        }
        this.stateManager.updateState$(of(state));
      });
    }
    if (state.premiumRepaymentVisibility) {
      this.setPremiumRepaymentFormValueFromState(state);
    }
    this.setCancellationFormValueFromState(state);
  }

  createReqBody(state) {
    this.policyOperationsInput = this.getInput();
    this.policyOperationsInput.operationsInput.userCode = state.userCode;
    this.policyOperationsInput.operationsInput.productionNodeCode = state.productionNodeCode;
    this.policyOperationsInput.operationsInput.authenticationNodeCode = state.authenticationNodeCode;
    this.policyOperationsInput.operationsInput.objectId = {identification: state.contract.objectId};
    this.policyOperationsInput.operationsInput.objectId.description = '1';
    return this.policyOperationsInput;
  }

  getInput() {
    return {
      operationsInput: {}
    };
  }

  onCancellationReasonValueChange() {
    this.pushMessageHandler.clearTag('errorMessage');
    const state = this.stateManager.getCurrentState();
    state.premiumRepaymentVisibility = this.cancellationForm.value.cancellationReason.premiumRepayment;
    if (this.cancellationForm.value.cancellationReason) {
      this.enableEffectDateControl();
    } else {
      this.resetEffectDateControl();
    }
  }

  enableEffectDateControl() {
    this.cancellationForm.patchValue({
      effectDate: new Date()
    });
    this.cancellationForm.controls.effectDate.setValidators(Validators.required);
    this.cancellationForm.controls.effectDate.enable();
  }

  resetEffectDateControl() {
    this.cancellationForm.patchValue({
      effectDate: new UntypedFormControl('', )
    });
    this.cancellationForm.controls.effectDate.setValidators(null);
    this.cancellationForm.controls.effectDate.disable();
  }

  setCancellationFormValueFromState(state) {
    if (state.cancellationReason) {
      const i = state.cancellationReasons.find(x => x.description === state.cancellationReason.description);
      this.cancellationForm.controls.cancellationReason.setValue(i);
    }
    if (state.issueDate) {
      this.cancellationForm.controls.issueDate.setValue(new Date(state.issueDate));
    }
    if (state.effectiveDate) {
      this.cancellationForm.controls.effectDate.setValue(new Date(state.effectiveDate));
      this.cancellationForm.controls.effectDate.setValidators(Validators.required);
      this.cancellationForm.controls.effectDate.enable();
    }
  }

  setPremiumRepaymentFormValueFromState(state) {
    if (state.taxes) {
      this.premiumRepaymentForm.controls.taxes.setValue(state.taxes);
    }
    if (state.taxable) {
      this.premiumRepaymentForm.controls.taxable.setValue(state.taxable);
    }
    if (state.rights) {
      this.premiumRepaymentForm.controls.rights.setValue(state.rights);
    }
  }

  goToNextPage() {
    if (this.cancellationForm.valid && this.premiumRepaymentForm.valid) {
      this.stateManager.goToNextPage(this.cancellationForm, this.premiumRepaymentForm);
    } else {
      this.setErrorMessage();
    }
  }

  back() {
    const st = this.stateManager.getCurrentState();
    this.portalSession.remove(this.activeRoute.id);
    const idNavigation = this.portalSession.open('ptfallFinder', 'detail-damage', '', false);
    this.coreResult.setResult(idNavigation, 'detail-damage', st.contract);
  }

  setErrorMessage() {
    const msg: PushMessage = new PushMessage();
    const opts = {icon: 'rgi-ui-icon-alert'};
    msg.tag = 'errorMessage';
    msg.status = 'danger';
    msg.content = this.errorMessage;
    msg.dismissible = false;
    msg.options = opts;
    this.pushMessageHandler.notify(msg);
  }

  private setTranslations() {
    const keys$ = of(['RE_ISSUE.FIELDS_MARKED_IN_RED']);
    keys$.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.errorMessage = next [0];
    }).unsubscribe();
  }
}

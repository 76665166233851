<div *ngIf="quests">
    <div class="panel-body" *ngFor="let quest of quests">
        <lib-questionnaire-item
          [quest]="quest"
          [isReadOnly]="isReadOnly(quest)"
          (getQuest)="getSelectedQuest($event)"
          [loader]="loader"
          (selectedQuest)="selectedQuestEmitter($event)">
        </lib-questionnaire-item>
    </div>
</div>

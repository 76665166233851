import {Component, Input, OnInit} from '@angular/core';
import {CORE_ADDRESS_FUNCTION} from '../../anag-constants/anag-constants';
import {ModalService} from '@rgi/rx/ui';
import {AnagStateManagerPartyEditor} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagAddressData} from "../../anag-model/anag-domain/anag-route-data";
import {AnagUbicationComponent} from "../ubication-component/ubication.component";
import {AnagApiAddress, AnagApiOtherAddress} from "../../anag-model/anag-domain/anag-api-party";
import {AnagModalService} from '../../anag-modal/anag-modal-service';

@Component({
  selector: 'rgi-anag-address',
  templateUrl: './address.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AddressComponent implements OnInit {

  @Input() otherAddressParty;
  label: string;

  canEdit = true;

  constructor(protected modalService: ModalService,
              protected translateService: RgiRxTranslationService,
              public stateMgr: AnagStateManagerPartyEditor,
              public anagModalService: AnagModalService) {

    this.canEdit = stateMgr.getCurrentState().editorModeEnable;
  }

  ngOnInit() {
    this.translation();

  }

  addOtherAddress() {
    const ubicationData = new AnagAddressData();
    ubicationData.modalLabel = this.label;
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.partySubjetcDomicile;
    ubicationData.showOtherAddress = true;
    ubicationData.disabledMainAddress = this.isDisabledMainAddress();
    // default value, you can change it to rietreve different address configuration (check exported CORE_ADDRESS_FUNCTION)
    const ubicationModal = this.anagModalService.openComponent('AnagUbicationComponent', ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(otherAddress => { // addr is an AnagApiAddress object
      if (otherAddress) {
        this.stateMgr.addOtherAddress(otherAddress);
      }
    });
  }

  editOtherAddress(otherAddress: AnagApiOtherAddress, i) {
    const ubicationData = new AnagAddressData();
    ubicationData.modalLabel = this.label;
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.partySubjetcDomicile;
    ubicationData.showOtherAddress = true;
    ubicationData.disabledMainAddress = otherAddress.mainAddress ? !otherAddress.mainAddress : this.isDisabledMainAddress();
    ubicationData.address = otherAddress.baseAddress;
    ubicationData.otherAddress = otherAddress;
    // default value, you can change it to rietreve different address configuration (check exported CORE_ADDRESS_FUNCTION)
    const ubicationModal = this.anagModalService.openComponent('AnagUbicationComponent', ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(otherAddr => { // addr is an AnagApiAddress object
      if (otherAddr) {
        this.stateMgr.updateOtherAddress(otherAddr, i);
      }
    });
  }

  isDisabledMainAddress() {
    if (this.otherAddressParty && this.otherAddressParty.length > 0) {
      return this.otherAddressParty.filter(x => x.mainAddress === true).length === 1;
    } else {
      return false;
    }
  }

  deleteOtherAddress(i) {
    this.stateMgr.deleteOtherAddress(i);
  }

  getValuedAddressLabel(address: AnagApiAddress): string {
    return address.formatAddress ? address.formatAddress : ''.concat(address.toponym ? address.toponym.descrizione + ' ' : '')
      .concat(address.placeAddress ? address.placeAddress : ' ')
      .concat(address.number ? ', ' + address.number : ' ')
      .concat(address.cap ? ' - ' + address.cap + ' ' : ' ')
      .concat(address.adminLevel3 ? address.adminLevel3 : ' ')
      .concat(address.adminLevel2Short ? ' (' + address.adminLevel2Short + ') - ' : ' - ')
      .concat(address.countryCode ? address.countryCode : '');
  }

  translation() {
    this.translateService.translate('_ANAG_._LABEL_._DOMICILE_').subscribe(translated =>  this.label = translated).unsubscribe();
  }

}

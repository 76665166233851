import {RiskCertificateCell} from './risk-certificate-cell';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {InsuranceStatus} from '../models/domain-models/insurance-status';

export class RiskCertificateData {

  constructor(
    public principalRespTotalAmounts: RiskCertificateCell[],
    public principalRespPeopleAmounts: RiskCertificateCell[],
    public principalRespThingsAmounts: RiskCertificateCell[],
    public principalRespMixedAmounts: RiskCertificateCell[],
    public pairRespTotalAmounts: RiskCertificateCell[],
    public pairRespPeopleAmounts: RiskCertificateCell[],
    public pairRespThingsAmounts: RiskCertificateCell[],
    public pairRespMixedAmounts: RiskCertificateCell[],
    public years: number[],
    public showPejus: boolean,
    public pejus: number,
    public showDeductibles: boolean,
    public deductiblesNumber: number,
    public deductiblesAmount: number,
    public plateOfOrigin: string,
    public originChassis: string,
    public originPolicyNumber: string,
    public uniqueClassFrom: string,
    public uniqueClassTo: string,
    public policyExpirationDate: Date,
    public originRate: GenericEntity,
    public company: GenericEntity,
    public riskCertificateTable: RiskCertificateCell[][],
    public insuranceStatus: InsuranceStatus,
    public showATRHeader: boolean,
    public showATRTable: boolean,
    public lastYear: string,
    public originUniqueClassVisible: boolean
  ) {
  }

}

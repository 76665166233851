import {Pipe, PipeTransform} from '@angular/core';
import {GPPolicyDataField} from '../../../group-policy-models/group-policy-issue-policy-data';
import {UntypedFormGroup} from '@angular/forms';

@Pipe({
  name: 'gpFieldsCustomActionLabel'
})
export class GpFieldsCustomActionLabelPipe implements PipeTransform {

  constructor() {
  }
  transform(value: GPPolicyDataField, policyDataForm: UntypedFormGroup) {
    switch (value.code) {
      case 'COINSURANCE_OUR_PERCENTAGE':
        return policyDataForm.get('COINSURANCE_TYPE').value === '2' ?
          '_GP_._BTN_._COINSURANCE_SHARES_' : '_GP_._BTN_._COINSURANCE_DELEGATED_';
      default:
        return '';
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Claim } from './dto/claim';

@Injectable({
  providedIn: 'root'
})
export class ClaimsElasticService {

  private sessioninvoices: Claim[][];
  private baseApiUrlV2: string;
  private urlGraphql = ''; // removed for vulnerabilities, resume from history and add properties

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  setSessionClaims(sessionId: string, claims: Claim[]) {
    if (!this.sessioninvoices) {
      this.sessioninvoices = [];
    }
    this.sessioninvoices[sessionId] = claims;
  }

  getSessionClaims(sessionId: string): Claim[] {
    if (this.sessioninvoices) {
      return this.sessioninvoices[sessionId];
    }
  }

  getClaimsElasticList(req: any) {

    // TODO call graphql
    return this.httpClient.post(this.urlGraphql, req);

  }

  getPartySearch(magicString: string) {

    return  `{partySearch(terms: "` + magicString + `", start: 0, size: 10){
      claimNumber occurenceDate claimsHandler policyNumber parties { name surname typeOfLoss { typeOfLossDescription }}}}`;

  }

  getClaimSearch(claimNumber: string) {

    return  `{getClaimByNumber(claimNumber: "` + claimNumber + `"){
      claimNumber occurenceDate claimsHandler policyNumber parties { name surname typeOfLoss { typeOfLossDescription }}}}`;

  }

  getRegexpSearch(magicString: string) {

    return  `{regexpSearch(terms: "` + magicString + `", start: 0, size: 10){
      claimNumber occurenceDate claimsHandler policyNumber parties { name surname typeOfLoss { typeOfLossDescription }}}}`;

  }

  getAggregationSearch(field: string) {

    return  `{aggregateSearch(field: "` + field + `", start: 0, size: 100){ doc_count key }}`;

  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { RoutingService } from '@rgi/portal-ng-core'
import { ValidationConsolidationStateManager } from '../../state-managers/validation-consolidation.state.manager'
import { RgiRxDataTableColumnSortDef, RgiRxDataTableExpansionModel, RgiRxDatatableComponent, RgiRxSwitchChange, RgiRxTableExpansionTrigger, TableRowSchema, TableRowTypeSchema, TableSchema } from '@rgi/rx/ui'
import { claimNumberDlg, codFunction, codMessage, companyCodeCnt, companyCodeUs, error, externalKey, forced, importForfait, internalKey, lossDate, lossDateDlg, operationCentre, operationCentreCnt, registerType, role, roleDlg, sendingFlowDate, skipped, state } from '../../constants/validation.constants'
import { SearchPaymentsOutput } from '../../models/payments/search-payments-output.model'
import { ValidationConsolidationState } from '../../states/validation-consolidation.state'
import { Subscription } from 'rxjs'
import { ViewFunctionCondition } from '../../models/validation/function-condition.enum'
import { ValidationConsolidationStatus } from '../../models/validation/validation-consolidation-status.enum'
import { UtilityMethod } from '../../constants/forfait.constants'
import { SearchPayments } from '../../models/payments/search-payments.model'

// Consente la chiusura dell'accordion
const canContract = (): RgiRxTableExpansionTrigger => (row: any) => {
  console.info('can contract?', row)
  return true
}

// Ordinamento dei componenti custom all'interno della tabella
const CUSTOM_TEMPLATE_SORT_DEF: RgiRxDataTableColumnSortDef<SearchPaymentsOutput>[] = [
  { name: forced, comparator: 'name' },
  { name: skipped, comparator: 'name' }
]

@Component({
  selector: "table-validation-consolidation",
  templateUrl: "./table-validation-consolidation.component.html",
  styleUrls: ["./table-validation-consolidation.component.scss"],
})
export class TableValidationConsolidationComponent implements OnInit {
  subscriptions: Subscription[] = []
  localState: ValidationConsolidationState = null
  searchPaymentsInputDTO: SearchPayments = new SearchPayments()

  constructor(
    @Inject('authService') private authService,
    protected routingService: RoutingService,
    public tableStateManager: ValidationConsolidationStateManager
  ) { }

  ngOnInit(): void {
    this.checkState()
    this.isValida = this.isDisabledValida()
    this.isConsolida = this.isDisabledConsolida()
    this.isBilancio = this.isDisabledBilancio()
  }

  checkState() {
    this.subscriptions.push(this.tableStateManager.getState$().subscribe((state: ValidationConsolidationState) => {
      if (state && this.localState == null) {
        this.localState = state

        if (!!state.searchPaymentsOutputDTO) {
          this.tableData = state.searchPaymentsOutputDTO
          this.dynamicTableColumns()
        }

        if(!!state.searchPaymentsInputDTO) {
          this.searchPaymentsInputDTO = state.searchPaymentsInputDTO
        }
      }
    })
    )
  }

  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe()
        sub = null
      }
    })
  }



  /**
   * Tabella 
   */
  
  // Consente l'espansione dell'accordion
  canExpand = (): RgiRxTableExpansionTrigger => (row: any) => {
    console.info('can expand?', row)
    this.hideFileds(row)
    return true
  }

  @ViewChild(RgiRxDatatableComponent, { static: true }) dt: RgiRxDatatableComponent

  protected tableData: SearchPaymentsOutput[] = []
  protected sortDef: RgiRxDataTableColumnSortDef<SearchPaymentsOutput>[] | boolean = CUSTOM_TEMPLATE_SORT_DEF

  protected customExpansionModel: RgiRxDataTableExpansionModel<SearchPaymentsOutput> = new RgiRxDataTableExpansionModel<SearchPaymentsOutput>(
    this.tableData, { multiple: false, canExpand: this.canExpand(), canContract: canContract() }
  )

  private rows: TableRowTypeSchema[] = [
    { name: codFunction, title: 'CODICE FUNZIONE' },
    { name: companyCodeUs, title: 'CODICE COMPAGNIA' },
    { name: internalKey, title: 'RIFERIMENTO ENTITÀ' },
    { name: lossDate, title: 'DATA SINISTRO' }
  ]

  protected schema: TableSchema = { rows: this.rows, header: [] }

  protected onToggleSortHeaders($event: RgiRxSwitchChange) {
    this.sortDef = $event.selected ? CUSTOM_TEMPLATE_SORT_DEF : false
  }

  private addColumn(columnName: string, titleColumn: string): void {
    const columnSchema: TableRowSchema = { name: columnName, title: titleColumn }
    this.rows.push(columnSchema)
  }

  private dynamicTableColumns(): void {
    if (
      !!this.tableData
      && this.tableData.length > 0
      && !!this.tableData[0]
      && !!this.tableData[0].codFunction
      && !!this.tableData[0].codFunction.code
    ) {
      const functionCode: string = this.tableData[0].codFunction.code

      switch (functionCode) {
        case ViewFunctionCondition.CICOS:
          this.addColumn(role, 'RUOLO')
          this.addColumn(importForfait, 'IMPORTO FORFAIT')
          break
        case ViewFunctionCondition.SDM:
          this.addColumn(role, 'RUOLO')
          break
        case ViewFunctionCondition.SDM_MODULAR:
          this.addColumn(role, 'RUOLO')
          break
        case ViewFunctionCondition.CAS:
          break
      }

      const endRows: TableRowTypeSchema[] = [
        { name: state, title: 'STATO' },
        { name: error, title: 'ERRORE' },
        { name: claimNumberDlg, title: 'N° SINISTRO' },
        { name: forced, title: 'FORZATURA' },
        { name: skipped, title: 'SKIP' }
      ]

      this.rows = [...this.rows, ...endRows]

      this.schema.rows = this.rows;
      this.schema.header = this.rows.map((row) => row.name);
    }
  }

  protected setForzatura(searchPaymentsOutput: SearchPaymentsOutput) {
    searchPaymentsOutput.forced = searchPaymentsOutput.forced
    searchPaymentsOutput.skipped = !searchPaymentsOutput.skipped
  }

  protected setSkip(searchPaymentsOutput: SearchPaymentsOutput) {
    searchPaymentsOutput.skipped = searchPaymentsOutput.skipped
    searchPaymentsOutput.forced = !searchPaymentsOutput.forced
  }

  protected isDisabledForzatura(searchPaymentsOutput: SearchPaymentsOutput): boolean {
    return !(searchPaymentsOutput.state.code === ValidationConsolidationStatus.INERROR 
      && !!searchPaymentsOutput.claimNumberDlg.code 
      && !!searchPaymentsOutput.idTypeOfLossDlg
    )
  }

  protected isDisabledSkip(searchPaymentsOutput: SearchPaymentsOutput): boolean {
    return searchPaymentsOutput.state.code !== ValidationConsolidationStatus.INERROR
  }



  /**
   * Accordion 
   */
  protected accordion: SearchPaymentsOutput = new SearchPaymentsOutput()
  protected accordionKeys: string[] = []
  protected accordionRowN: number[] = []
  protected accordionColumnN: number[] = []

  // Numero di elementi per riga dell'accordion
  protected numberElementsRow: number = 4

  protected hideFileds(ctx: SearchPaymentsOutput): void {
    let newAccordion: SearchPaymentsOutput = new SearchPaymentsOutput()
    this.accordionKeys = Object.keys(newAccordion)

    if(this.accordionKeys.length < Object.keys(ctx).length) {
      return
    }

    const functionCode: string = ctx.codFunction.code

    switch (functionCode) {
      case ViewFunctionCondition.CICOS:
        const removableElementsCicos: string[] = [
          ...this.schema.header
          // cif, targa, numeroFattura, dataFattura, totalAmount
          // provincia, codiceCompensazione, dataCompensazione
          // concept
        ]
        this.removeElementsFromAccordionKeys(removableElementsCicos)
        break
      case ViewFunctionCondition.SDM:
        const removableElementsSdm: string[] = [
          ...this.schema.header,
          // cif, targa, numeroFattura, dataFattura, totalAmount
          codMessage, 
          registerType, 
          operationCentre, 
          operationCentreCnt
        ]
        this.removeElementsFromAccordionKeys(removableElementsSdm)
        break
      case ViewFunctionCondition.SDM_MODULAR:
        const removableElementsSdmModular: string[] = [
          ...this.schema.header,
          // cif, targa, numeroFattura, dataFattura, totalAmount
          codMessage, 
          registerType, 
          operationCentre, 
          operationCentreCnt
        ]
        this.removeElementsFromAccordionKeys(removableElementsSdmModular)
        break
      case ViewFunctionCondition.CAS:
        const removableElementsCas: string[] = [
          ...this.schema.header,
          // targaAssicurato, targaCtp, codiceUnivoco
          // provincia, codiceCompensazione, dataCompensazione
          codMessage, 
          registerType, 
          operationCentre, 
          operationCentreCnt,
          companyCodeCnt, 
          externalKey, 
          roleDlg
        ]
        this.removeElementsFromAccordionKeys(removableElementsCas)
        break
    }

    for (let key of this.accordionKeys) {
      if(ctx[key] != null) {
        if(key == companyCodeCnt) {
          ctx[key] = ctx[key].role
        }
  
        if(key == sendingFlowDate || key == lossDateDlg) {
          ctx[key] = UtilityMethod.transform(ctx[key])
        }
      }
      
      newAccordion[key] = ctx[key]
    }

    this.accordion = newAccordion
    this.accordionRowN = []
    this.accordionColumnN = []

    this.createArrayOfNumberForUI(this.accordionRowN, this.accordionKeys.length / this.numberElementsRow)
    this.createArrayOfNumberForUI(this.accordionColumnN, this.numberElementsRow)
  }

  private createArrayOfNumberForUI(arrayToFill: number[], lengthArrayToFill: number): void {
    for (let i = 0; i < lengthArrayToFill; i++) {
      arrayToFill.push(i)
    }
  }

  private removeElementsFromAccordionKeys(removableElements: string[]): void {
    removableElements.forEach((removableElement: string) => {
      this.accordionKeys.splice(this.accordionKeys.indexOf(removableElement), 1)
    })
  }

  protected transformHtmlDate(date: string): string {
    return UtilityMethod.transform(date)
  }



  /**
   * Bottoni
   */
  protected isValida: boolean
  protected isConsolida: boolean
  protected isBilancio: boolean

  private isDisabledValida(): boolean {
    let find: SearchPaymentsOutput = this.tableData.find(
      (searchPaymentsOutput: SearchPaymentsOutput) =>
        searchPaymentsOutput.state.code === ValidationConsolidationStatus.TOBEVALIDATED
        || !!searchPaymentsOutput.error
        && (!searchPaymentsOutput.forced && !searchPaymentsOutput.skipped)
    )

    return !!!find
  }

  private isDisabledConsolida(): boolean {
    let find: SearchPaymentsOutput = this.tableData.find((searchPaymentsOutput: SearchPaymentsOutput) =>
      searchPaymentsOutput.state.code === ValidationConsolidationStatus.VALIDATED
      || (
        !!searchPaymentsOutput.error
        && (
          searchPaymentsOutput.forced
          || searchPaymentsOutput.skipped
        )
      )
    )

    return !!!find
  }

  private isDisabledBilancio(): boolean {
    return true
  }

  protected valida(): void {
    this.tableStateManager.bookValidation$(this.searchPaymentsInputDTO)
  }

  protected consolida() {
    this.tableStateManager.bookConsolidation$(this.searchPaymentsInputDTO)
  }

  // In attesa di nuovi sviluppi.
  protected bilancio() { }
}
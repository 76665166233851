import {PncPsalesBaseState} from './pnc-postsales-state';
import {LabeledEntity} from '../model/common/entities';
import {CancellationFullProperties} from '../model/api/cancellation-flow';
import {RgiQuotationHeaderData} from '../model/common/quotation/quotation-header-data';
import {PncPsalesSummaryStepDetail} from '../model/common/summary-step-detail';

export class PncPsalesSummaryState extends PncPsalesBaseState {
  summaryDetails: LabeledEntity[];
  allProperties: CancellationFullProperties;
  quotationHeaderData: RgiQuotationHeaderData;
  stepSummaries: PncPsalesSummaryStepDetail[];
}

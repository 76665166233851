import { Inject, Injectable } from '@angular/core';
import { AnagStorageService } from './anag-storage.service';
import { AnagStateKeyData } from '../anag-states/key-data/anag-state-manager-key-data';
import { CORPORATE_FORM_ENUM, PERSON_TYPE_ENUM, PROFESSION_TYPE_ENUM } from '../anag-constants/enums-constants';
import { AnagApiNode } from '../anag-model/anag-api/anag-api-node';
import { ValidatorFn } from '@angular/forms';
import { AnagEntityIta } from '../anag-model/anag-api/anag-subject-api';
import {
  AnagApiConfiguration,
  AnagApiConfigurationFilterRequest,
  AnagApiPartyTypeConfig,
  AnagApiPartyTypeConfigRequest,
  AnagConfigurationFilter
} from '../anag-model/anag-api/anag-api-configuration-filter';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AnagResourceService } from './anag-resource.service';
import { AnagFlowData } from '../anag-model/anag-domain/anag-flow-data';
import {AnagApiParty, AnagPartyKey} from '../anag-model/anag-domain/anag-api-party';
import { DATE_PICKER_PLACEHOLDERS, PACONF_VALUES } from '../anag-constants/anag-constants';
import { DateTimeAdapter } from '@rgi/rx/ui';
import { DatePipe } from '@angular/common';

@Injectable()
export class AnagStatelessService {

  constructor(
    public anagStorage: AnagStorageService,
    public anagResourceService: AnagResourceService,
    public datePipe: DatePipe,
    @Inject(DateTimeAdapter) private dateTimeAdapter: any) {
  }

  dateRangeValidator(min: Date, max: Date): ValidatorFn {
    const ret = (control) => {
      if (!control.value) {
        return null;
      }

      const dateValue = new Date(control.value);

      if (min && dateValue < min) {
        return { message: 'error message' };
      }

      if (max && dateValue > max) {
        return { message: 'error message' };
      }
    };
    return ret;
  }

  maxDateRangeValidator(max: Date): ValidatorFn {
    const ret = control => {
      if (!control.value) {
        return null;
      }

      const dateValue = new Date(control.value);

      if (max && dateValue > max) {
        return { message: '_ANAG_._MSG_._INVALID_DATE_VALUE' };
      }
    };
    return ret;
  }

  // formatDate(date: Date) {
  //   if (date) {
  //     if (!(date instanceof Date)) {
  //       date = new Date(date);
  //     }
  //     return date.getFullYear().toString() + '-' +
  //       (date.getMonth() + 101).toString().substring(1) + '-' +
  //       (date.getDate() + 100).toString().substring(1);
  //   }
  //   return undefined;
  // }

  updatePartyWithKeyData(state: AnagStateKeyData, keyDataForm: any): AnagApiParty {
    const party = state.party;
    const selectedNodeForm = state.nodes.find(node => node.code === keyDataForm.salePoint);
    party.node = new AnagApiNode(selectedNodeForm.code, selectedNodeForm.description, '' + selectedNodeForm.id);
    const countryOfBirth = this.anagStorage.countries.find(country => country.abbreviation === keyDataForm.nation);
    party.countryOfBirth = new AnagEntityIta(countryOfBirth.abbreviation, countryOfBirth.description);
    party.personType = this.anagStorage.getEnumsByCode(PERSON_TYPE_ENUM).find(personType => personType.codice === keyDataForm.personType);

    if (keyDataForm.personType === '1') {
      const physicalPartyForm = keyDataForm.physicalPartyForm;

      if (physicalPartyForm.professionType) {
        party.professionType = this.anagStorage.getEnumsByCode(PROFESSION_TYPE_ENUM).find(professionType => professionType.codice === physicalPartyForm.professionType);
      }

      if (physicalPartyForm.professionDetail) {
        party.professionDetail = state.professionDetails.find(detail => detail.codice === physicalPartyForm.professionDetail);
      }

      party.denomination = undefined;
      party.corporateForm = undefined;

      if (party.links) {
        party.links = party.links.filter(link => link.linkType.codice && link.linkType.codice !== '23' && link.linkType.codice !== '24');
      }

      if (state.isUmaPartyHomonymyEnable) {
        party.surname = physicalPartyForm.homySurname;
        party.name = physicalPartyForm.homyName;
        party.birthName = physicalPartyForm.homyBirthName;
        party.dateOfBirth = physicalPartyForm.homyDateofBirth;
      }
    } else if (keyDataForm.personType === '2') {
      party.denomination = party.denomination || party.surname;
      party.name = party.surname = undefined;
      party.corporateForm = this.anagStorage.getEnumsByCode(CORPORATE_FORM_ENUM).find(corporateForm => corporateForm.codice === keyDataForm.legalPartyForm.corporateForm);

      if (party.links) {
        party.links = party.links.filter(link => link.linkType.codice && (link.linkType.codice === '23' || link.linkType.codice === '24'));
      }

      party.documents = undefined;

      if (state.isUmaPartyHomonymyEnable) {
        party.denomination = keyDataForm.legalPartyForm.homyCompanyName;
      }
    }

    return party;
  }

  updatePartyWithKeyDataHomonymy(state: AnagStateKeyData, keyDataForm: any): AnagApiParty {
    const party = state.party;
    const selectedNodeForm = state.nodes.find(node => node.code === keyDataForm.salePoint);
    party.node = new AnagApiNode(selectedNodeForm.code, selectedNodeForm.description, '' + selectedNodeForm.id);
    const countryOfBirth = this.anagStorage.countries.find(country => country.abbreviation === keyDataForm.nation);
    party.countryOfBirth = new AnagEntityIta(countryOfBirth.abbreviation, countryOfBirth.description);
    party.personType = this.anagStorage.getEnumsByCode(PERSON_TYPE_ENUM).find(
      personType => personType.codice === keyDataForm.personType
    );
    if (keyDataForm.personType === '1') {

      if (keyDataForm.physicalPartyForm.professionType) {
        party.professionType = this.anagStorage.getEnumsByCode(PROFESSION_TYPE_ENUM).find(
          professionType => professionType.codice === keyDataForm.physicalPartyForm.professionType
        );
      }

      if (keyDataForm.physicalPartyForm.professionDetail) {
        party.professionDetail = state.professionDetails
          .find(detail => detail.codice === keyDataForm.physicalPartyForm.professionDetail);
      }

      party.denomination = undefined;
      party.corporateForm = undefined;
      if (party.links) {
        party.links = party.links.filter(link => link.linkType.codice && link.linkType.codice !== '23' && link.linkType.codice !== '24');
      }
      if (state.isUmaPartyHomonymyEnable) {
        party.surname = keyDataForm.physicalPartyForm.homySurname;
        party.name = keyDataForm.physicalPartyForm.homyName;
        party.birthName = keyDataForm.physicalPartyForm.homyBirthName;
        party.dateOfBirth = keyDataForm.physicalPartyForm.homyDateofBirth;
      }
    } else if (keyDataForm.personType === '2') {
      party.denomination = party.denomination ? party.denomination : party.surname;
      party.name = party.surname = undefined;
      party.corporateForm = this.anagStorage.getEnumsByCode(CORPORATE_FORM_ENUM).find(
        corporateForm => corporateForm.codice === keyDataForm.legalPartyForm.corporateForm
      );
      if (party.links) {
        party.links = party.links.filter(link => link.linkType.codice && (link.linkType.codice === '23' || link.linkType.codice === '24'));
      }
      party.documents = undefined;
      if (state.isUmaPartyHomonymyEnable) {
        party.denomination = keyDataForm.legalPartyForm.homyCompanyName;
      }
    }
    return party;
  }

  deepClone(obj): any {
    // If it's not an array or an object, returns null
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    let cloned;
    // Handle: Date
    if (obj instanceof Date) {
      cloned = new Date(obj.getTime());
      return cloned;
    }
    // Handle: array
    // @ts-ignore
    if (obj instanceof Array) {
      cloned = [];
      obj.forEach(el => cloned.push(this.deepClone(el)));
      return cloned;
    }
    // Handle: object
    cloned = {};
    let i;
    for (i in obj) {
      if (obj.hasOwnProperty(i)) {
        cloned[i] = this.deepClone(obj[i]);
      }
    }
    return cloned;
  }

  createPartyTypeReq(party: AnagApiParty) {
    const req = new AnagApiPartyTypeConfigRequest();
    if (party.personType.codice === '1') {
      req.personType = 1;
      if (party.professionType && party.professionType.codice) {
        req.professionCode = party.professionType.codice;
      }
    } else if (party.personType.codice === '2') {
      req.personType = 2;
      req.legalForm = party.corporateForm ? +party.corporateForm.codice : 999;
    }
    return req;
  }

  getUniqueKeysValue(party: AnagApiParty) {
    let value = '';
    // [RCT-6546]
    let codice;
    if (party && party.partyKey && party.partyKey.length > 0) {
      if (party.partyKey[0].country != null) {
        codice = party.partyKey[0].country;
      } else if (party.countryOfBirth && party.countryOfBirth.codice) {
        codice = party.countryOfBirth.codice;
      }
      const partyKey = party.partyKey.find(key => key.country === codice);
      const keys = [];
      if (partyKey && partyKey.key1 && partyKey.key1.value) {
        keys.push(partyKey.key1.value);
      }
      if (partyKey && partyKey.key2 && partyKey.key2.value) {
        keys.push(partyKey.key2.value);
      }
      if (partyKey && partyKey.key3 && partyKey.key3.value) {
        keys.push(partyKey.key3.value);
      }
      if (keys.length) {
        if (keys.length > 1) {
          keys.forEach((key, index) => {
            value += key;
            if (index + 1 < keys.length) {
              value += '/';
            }
          });
        } else if (keys.length === 1) {
          value = keys[0];
        }
      }
    }
    return value !== '' ? value : '-';
  }

  retrieveSubjectType(partyTypeReq: AnagApiPartyTypeConfigRequest): Observable<AnagApiPartyTypeConfig | string> {
    return this.anagResourceService.getPartyTypeConfig$(partyTypeReq).pipe(
      tap((res: AnagApiPartyTypeConfig) => {
        return (res !== null && !res.serviceInfo.codiceErroreOccorso) ? throwError(res.serviceInfo.descrizioneErroreOccorso) : of(res);
      }),
      catchError((err) => {
        return of(err ? err : 'Error for partyType');
      })
    );
  }

  getFlowDataUpdatedConfFilter(filter: AnagConfigurationFilter, flowData: AnagFlowData): AnagConfigurationFilter {
    if (!filter) {
      filter = new AnagConfigurationFilter();
    }
    if (flowData) {
      // tslint:disable-next-line:radix
      filter.partyRole = flowData.partyRole ? parseInt(flowData.partyRole) : PACONF_VALUES.FILTER_DEFAULT;
      filter.productId = flowData.productId;
      filter.productCode = flowData.productCode;
      filter.operation = flowData.operation;
    }
    return filter;
  }

  retrievePartyConf(confFilter: AnagConfigurationFilter): Observable<AnagApiConfiguration | string> {
    return this.anagResourceService.getPartyConfig$(new AnagApiConfigurationFilterRequest(confFilter)).pipe(
      tap((res: AnagApiConfiguration) => {
        return (res !== null && !res.serviceInfo.codiceErroreOccorso) ?
          throwError(res.serviceInfo.descrizioneErroreOccorso) : of(res);
      }),
      catchError((err) => {
        return of(err ? err : 'Error for party configuration');
      })
    );
  }

  today(): Date {
    return new Date();
  }

  getDatePickerPlaceholderLabel() {
    return DATE_PICKER_PLACEHOLDERS.get(this.dateTimeAdapter.language);
  }

  getLocaleDateFormat() {
    return this.dateTimeAdapter.dateParser.localeFormats[this.dateTimeAdapter.locale];
  }

  getMajorAge(codCountry: string) {
    return this.anagResourceService.getPartyMajorAge$(codCountry);
  }

  extendsAnagPartyKey(physicalPartyForm, legalPartyForm, partyKeyConf: AnagPartyKey) {

  }

  formatDateToStringPeriod(date: Date): string {
    const DATE_FORMAT = this.getLocaleDateFormat();
    return this.datePipe.transform(date, DATE_FORMAT);
  }
}

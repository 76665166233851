import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TypeOfLossConstants {
    public TYPE_OF_LOSS_VEHICLE = '1';
    public TYPE_OF_LOSS_PERSON = '2';
    public TYPE_OF_LOSS_THING = '3';
}

<h3 class="title-header">{{ '_CLAIMS_._INJURY_CODE' | translate }} </h3>
<br>
<form [formGroup]="dataForm">
    <div class="form-group row">
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._DEPARTMENT' | translate }}<pnc-required required="true"></pnc-required>
            </label>
            <select class="core-select form-control" formControlName="departmentInjury" id="departmentInjury"
                name="departmentInjury" attr.data-qa="occurrence-data-panel-departmentInjury-input">
                <option></option>
                <option *ngFor="let el of injuryEntity.listDepartment; " value={{el.code}}>
                    {{el.description}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._TYPE_OF_DAMAGE' | translate }} </label>
            <select class="core-select form-control" formControlName="typeOfLossInjury" id="typeOfLossInjury"
                name="typeOfLossInjury" attr.data-qa="occurrence-data-panel-typeOfLossInjury-input">
                <option></option>
                <option *ngFor="let el of injuryEntity.listTypeofLoss;" value={{el.code}}>
                    {{el.description}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._TYPE_CIVIL__PENAL' | translate }} </label>
            <select class="core-select form-control" formControlName="matterTypeInjury" id="matterTypeInjury"
                name="matterTypeInjury" attr.data-qa="occurrence-data-panel-matterTypeInjury-input">
                <option></option>
                <option *ngFor="let el of injuryEntity.listTypeMatter; " value={{el.code}}>
                    {{el.description}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-12 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._ALLEGATION_DESCRIPTION' | translate }} </label>
            <textarea class="col-sm-12 form-control" formControlName="allegationDescrInjury" id="allegationDescrInjury"
                rows="3" type="text" name="allegationDescrInjury"
                attr.data-qa="occurrence-data-panel-allegationDescrInjury-input"></textarea>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-12 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._NOTE' | translate }} </label>
            <textarea class="col-sm-12 form-control" formControlName="noteInjury" id="noteInjury" rows="3" type="text"
                name="noteInjury" attr.data-qa="occurrence-data-panel-noteInjury-input"></textarea>
        </div>
    </div>
</form>
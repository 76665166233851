<rgi-rx-expansion-panel *ngIf="subjects.length > 0" [expanded]="false" color="default" >
  <rgi-rx-expansion-panel-header>
    <span rgi-rx-expansion-panel-label translate>CONTRACT_CONSULTATION.SUBJECTS</span>
  </rgi-rx-expansion-panel-header>
  
  <div class="rgi-ui-col rgi-ui-grid-3">
    <div *ngFor="let subject of subjects" class="rgi-ui-col">
        <consultation-row [type]="'TEXT'" [label]="subject.type.description" [value]="subject.subject.nominative"></consultation-row>
    </div>
  </div>
  
</rgi-rx-expansion-panel>

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {RoutableComponent} from '../routable-component';
import {EventNotificator} from '../event-notificator';
import {ApiPremium} from '../models/api-models/api-premium';
import {VehicleProperties} from '../models/domain-models/vehicle-properties';
import {ApiPremiumDetail} from '../models/api-models/api-premium-detail';
import {GenericElement} from '../models/domain-models/generic-element';
import {Section} from '../models/domain-models/section';
import {Variable} from '../models/domain-models/variable';
import {VariablesService} from '../variables/variables-service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ApiSetup} from '../models/api-models/api-setup';
import {MagazinesService} from '../vehicle-data/magazines-service';
import {StateService} from '../state.service';
import {State} from '../models/state';
import {RoutingService} from '../routing.service';
import {ProposalService} from '../proposal.service';
import {ApiContract} from '../models/api-models/api-contract';
import {ApiQuotation} from '../models/api-models/api-quotation';
import {Action} from '../models/action';
import {QuotationDetailsModalComponent} from './quotation-details/quotation-details-modal.component';
import {QuotationService} from './quotation.service';
import {ContractClauseService} from '../contract-clause/contract-clause-service';
import {CustomModalService} from '../custom-modal.service';
import {ContributionsAndTaxesModalComponent} from './contributions-and-taxes/contributions-taxes-modal.component';
import {CommissionsModalComponent} from './commissions/commissions-modal.component';
import {Observable, Subscription} from 'rxjs';
import {WarrantiesSummaryModalComponent} from './warranties-summary/warranties-summary-modal.component';
import {Message} from '../models/message';
import {TranslateService} from '@ngx-translate/core';
import {ApiQuote} from '../models/api-models/api-quote';
import {ModalService} from '@rgi/portal-ng-core';
import {DiscountsModalComponent} from './discounts/discounts-modal.component';
import {ApiVehicle} from '../models/api-models/api-vehicle';
import {ApiVehicleStaticData} from '../models/api-models/api-vehicle-static-data';
import {InsuranceBackgroundService} from '../insurance-background/insurance-background.service';
import {RiskCertificateService} from '../risk-certificate/risk-certificate-service';
import {ErrorMessagesService} from '../error-messages.service';
import {ParameterService} from './parameters/parameters.service';
import {LiensService} from '../proposal/liens/liens.service';
import {Modal} from '../modal';
import {Package} from '../models/domain-models/package';
import {Clause} from '../models/domain-models/clause';
import {SubstEnumAssetRetention} from '../models/domain-models/substitution/enum/substitution-assetretention';
import {LpsTaxesModalComponent} from './lps-taxes/lps-taxes-modal.component';
import {QuoteService} from '../quote.service';
import {ChangeVehicleModalComponent} from '../change-vehicle-modal/change-vehicle-modal.component';
import {ApiDisabledComponents} from '../models/api-models/api-disabled-components';

@Component({
  selector: 'mic-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator {

  vehicle: ApiVehicle;
  staticData: ApiVehicleStaticData;

  premium: ApiPremium = new ApiPremium(null, null, null, null, null);
  unitSections: Array<Section>;
  quotation: ApiQuotation;

  annualPremiumValue: ApiPremiumDetail;
  premiumInstallment: ApiPremiumDetail;
  premiumSubscriptionInstalment: ApiPremiumDetail;
  recalculateAvailable = false;
  showLPSTaxes = false;
  showWarrantiesSummaryModal = true;
  isPolicyInLPS = false;
  showContributionsAndTaxesMenu = true;
  disableSaveQuote = false;
  disableNext = false;
  vehicleProperties: VehicleProperties;
  adjustInsuredValue = false;
  showFloatingMessages = false;
  enableWarningIcon = true;
  showATRHeader = false;
  showATRTable = false;
  showSaveQuoteButton = false;
  isAutoSaveQuoteEnabled = false;
  isDeliveryOfTransaparencyDocuments = false;

  showPartyVariables = true;
  packageView = false;


  contractVariableListner: Subscription;
  @Output()
  navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('productAssetModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) productAssetModalViewContainerRef: ViewContainerRef;

  @ViewChild('premiumDetailsModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) modalViewContainerRef: ViewContainerRef;

  @ViewChild('packageModalModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) packageModalViewContainerRef: ViewContainerRef;
  componentRef: any;
  assetStaticData: Array<any> = new Array<any>();

  assetStaticDataRows: GenericElement[][];

  @ViewChild('errorsElement')
  errorsElement: ElementRef<HTMLDivElement>;
  validationMessages: Message[] = new Array<Message>();
  constraintMessages: Message[] = new Array<Message>();
  nonBlockingMessages: Message[] = new Array<Message>();
  blockingMessages: Message[] = new Array<Message>();
  insuranceStatusMessages: Message[] = new Array<Message>();
  formMessages: Message[] = new Array<Message>();
  parameterMessages: Message[] = new Array<Message>();

  recalculateAvailableQuotation: boolean;
  variables: Variable[] = [];
  contractVariables: Variable[] = [];
  editableVariables: Variable[] = [];
  variablesRows: Variable[][];


  clausesList: Array<Clause>;
  assetForm: UntypedFormGroup;
  setupsListMode = true;
  setupsInitialized = false;

  setups: Array<ApiSetup> = new Array<ApiSetup>();
  areaCode = 'QUOTATION';
  areaCodePremium = 'PREMIUM';
  areaCodeProposal = 'PROPOSAL';
  areaCodeCoverage = 'COVERAGE';

  areaCodeParameter = 'PARAMETERS';
  setupOutOfMagazine = false;
  showInfocarCode: boolean;
  viewUnitsBox: boolean;

  isQuotationReady = false;
  quotationDetailsModalComponent: Type<Modal>;
  packageVariablesModal: Type<Modal>;
  discountsModalComponent: Type<Modal>;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected translate: TranslateService,
    protected variablesService: VariablesService,
    protected magazineService: MagazinesService,
    protected formBuilder: UntypedFormBuilder,
    protected stateService: StateService,
    protected routingService: RoutingService,
    protected proposalService: ProposalService,
    protected quotationService: QuotationService,
    protected customModalService: CustomModalService,
    protected modalService: ModalService,
    protected errorMessagesService: ErrorMessagesService,
    protected insuranceStatusService: InsuranceBackgroundService,
    protected riskCertificateService: RiskCertificateService,
    protected parameterService: ParameterService,
    protected liensService: LiensService,
    protected clauseService: ContractClauseService,
    protected quoteService: QuoteService,
    @Inject('showInfocarCodeInQuotationPage') showInfocarCodeInQuotationPage?: boolean,
    @Inject('viewUnitsBox') viewUnitsBox?: boolean,
    @Inject('saveQuoteButtonEnabled') saveQuoteButtonEnabled?: boolean,
    @Inject('quotationDetailsModalComponent') quotationDetailsModal?: Type<Modal>,
    @Inject('packageVariablesModalComponent') packageVariablesModalComponent?: Type<Modal>,
    @Inject('discountsModalComponent') discountsModal?: Type<Modal>
  ) {
    this.showInfocarCode = showInfocarCodeInQuotationPage;
    this.viewUnitsBox = viewUnitsBox;
    this.showSaveQuoteButton = saveQuoteButtonEnabled;
    this.quotationDetailsModalComponent = quotationDetailsModal;
    this.packageVariablesModal = packageVariablesModalComponent;
    this.discountsModalComponent = discountsModal;
  }

  get isSubstitution(): boolean {
    return this.proposalService.isSubstitution;
  }

  get isModificaVeicoloVisibile(): boolean {
    if (this.isSubstitution) {
      const config = this.proposalService.getSubstitutionConfig();
      return config.ASSET_RETENTION !== SubstEnumAssetRetention.ASSET_RETENTION_YES;
    } else {
      return this.proposalService.isFieldEnabled(ApiDisabledComponents.SECTION_VEHICLE_DATA) !== false;
    }
  }

  async ngOnInit() {

    this.eventPropagation.emit({
      eventName: 'setTitle',
      title: this.translate.instant('P&C New Proposal - Quotation')
    });

    this.isAutoSaveQuoteEnabled = await this.quoteService.isAutoSaveQuoteEnabled();
    this.isDeliveryOfTransaparencyDocuments = await this.quoteService.isDeliveryOfTransaparencyDocuments();

    const recalculatePremiumChannelSubscription = this.quotationService.getRecalculatePremiumSignal().subscribe(
      (signal) => {
        this.calculatePremium();
      }
    );
    this.subscriptions.add(recalculatePremiumChannelSubscription);

    const apiContract: ApiContract = this.proposalService.getApiContract();
    if (apiContract && this.proposalService.getContractId()) {
        this.init();
    }

    const newContractSubscription = this.proposalService
      .getNewContractSignal()
      .subscribe(data => {
        this.init();
        this.stateService.setCurrentState(State.QUOTATION_STANDARD_PLATE);
        this.routingService.setPreviousState(State.QUOTATION_STANDARD_PLATE);
      });
    this.subscriptions.add(newContractSubscription);

  }

  protected init() {
    const disabledFields = this.proposalService.getContractDisabledFields();
    if (disabledFields instanceof Observable) {
      disabledFields.subscribe(data => {
        this.proposalService.setDisabledFields(data);
      });
    }

    this.getPremium(true);
  }

  checkAssetData() {
    if (this.proposalService.existAssetDataCompletenessErrors()) {
      this.blockingMessages.push(new Message(this.areaCode, 'Asset Data not complete'));
    } else {
      this.removeCompletenessErrors('ASSET_DATA_NOT_COMPLETE');
    }
    this.updateMessages();
  }

  removeCompletenessErrors(messCode: string): void {
    let completenessErrors = this.proposalService.getApiContract().completenessErrors;
    if (!completenessErrors) {
      completenessErrors = [];
    }
    const index = completenessErrors.findIndex((mess) => {
      return mess.code === messCode;
    });
    if (index !== -1) {
      completenessErrors.splice(index, 1);
    }
  }


  resetPackage() {
    this.quotation.packages = [];
  }

  getPremiumResetPackage(onInit?: boolean) {
    this.resetPackage();
    this.getPremium(onInit);
  }

  getPremium(onInit?: boolean) {
    this.quotationService.getQuotation(this.quotation).subscribe(data => {
        this.premium = data.premium;
        this.quotationService.setPolicyLps(data.policyInFPS);
        if (onInit === true) {
          this.isQuotationReady = true;
          this.packageView = !!data.packages && !!data.packages.length;
          data.isPackage = this.packageView;
          this.initialization();
        } else {
          this.initializeVariables();
          if (this.quotationService.getRefreshInsuranceStatusToken() === true) {
            this.insuranceStatusService.sendRecalculateInsuranceStatusSignal();
            this.riskCertificateService.sendRecalculateInsuranceStatusSignal();
          }
        }

        if (this.premium && this.premium.productPremium) {
          this.quotationService.setPreviousAnnualPremium(this.premium.productPremium.annual);
        }

        this.unitSections = data.sections;
        this.quotation = data;
        this.showLPSTaxes = this.quotation.showFPSTaxes;
        this.showContributionsAndTaxesMenu = this.quotation.showContributionsAndTaxes;
        this.isPolicyInLPS = this.quotation.policyInFPS;
        this.showSaveQuoteButton = this.showSaveQuoteButton && this.quotation.saveQuoteAllowed;
        this.showWarrantiesSummaryModal = !this.packageView;

        this.afterCalculatingPremium();

      },
      err => {
        if (onInit === true) {
          this.isQuotationReady = true;
          this.initialization();
        } else {
          this.initializeVariables();
          if (this.quotationService.getRefreshInsuranceStatusToken() === true) {
            this.insuranceStatusService.sendRecalculateInsuranceStatusSignal();
            this.riskCertificateService.sendRecalculateInsuranceStatusSignal();
          }
        }
      });
  }

  updateMessages() {
    this.validationMessages = new Array<Message>();
    Array.prototype.push.apply(this.validationMessages, this.formMessages);
    Array.prototype.push.apply(this.validationMessages, this.blockingMessages);
    Array.prototype.push.apply(this.validationMessages, this.parameterMessages);
    Array.prototype.push.apply(this.validationMessages, this.insuranceStatusMessages);
  }

  putContractVariablesMessages(): void {
    const contractWariablesErrors = this.proposalService.getApiContract().completenessErrors;
    contractWariablesErrors.forEach((error) => {
      this.validationMessages.push(new Message(this.areaCodeProposal, error.description));
    });
  }

  populateAssetStaticData() {

    const product = this.proposalService.getApiContract().products[0];
    const asset = product.assets[0];

    this.assetStaticData.push(new GenericElement('Product:',
      (product.extendedDescription ? product.extendedDescription : product.description)));
    this.assetStaticData.push(new GenericElement('Asset Type:', asset.description));

    if (this.vehicle) {

      if (this.staticData.vehicleClass && this.staticData.vehicleClass.description !== '') {
        this.assetStaticData.push(new GenericElement('Class:', this.staticData.vehicleClass.description));
      }
      if (this.staticData.vehicleUse && this.staticData.vehicleUse.description !== '') {
        this.assetStaticData.push(new GenericElement('Usage:', this.staticData.vehicleUse.description));
      }
      if (this.staticData.extension && this.staticData.extension.description !== '') {
        this.assetStaticData.push(new GenericElement('Extension:', this.staticData.extension.description));
      }
      if ((this.stateService.getCurrentState() === State.QUOTATION_STANDARD_PLATE
          || this.stateService.getCurrentState() === State.QUOTATION_ALTERNATIVE_PLATE)
        && this.vehicle.plate) {
        if (this.vehicle.plate.plateType && this.vehicle.plate.plateType.description !== '') {
          this.assetStaticData.push(new GenericElement('License Plate Type:', this.vehicle.plate.plateType.description));
        }
        if (this.vehicle.plate.plateNumber !== '') {
          this.assetStaticData.push(new GenericElement('License Plate Number:', this.vehicle.plate.plateNumber));
        }
        if (this.isForeignPlate() && this.vehicle.plate.country && this.vehicle.plate.country.description !== '') {
          this.assetStaticData.push(new GenericElement('License Plate Country:', this.vehicle.plate.country.description));
        }
        if (this.vehicle.plate.specialPlateType && this.vehicle.plate.specialPlateType.description !== '' &&
          (this.vehicleProperties.optionalPlateChassis || this.vehicleProperties.requirePlateChassis)) {
          this.assetStaticData.push(new GenericElement('Special Plate Type:', this.vehicle.plate.specialPlateType.description));
        }
      }
      if (this.staticData.chassis?.description !== '' && (this.vehicleProperties.optionalPlateChassis ||
        this.vehicleProperties.requirePlateChassis || this.vehicleProperties.registrationDateJuly)) {
        this.assetStaticData.push(new GenericElement('Frame:', this.staticData.chassis?.description));
      }
      if (this.staticData.engineNumber !== '' && this.vehicleProperties.engineNumberVisible) {
        this.assetStaticData.push(new GenericElement('Engine Number:', this.staticData.engineNumber));
      }
      if (this.staticData.registrationDate) {
        this.assetStaticData.push(new GenericElement('Registration Date:', String(this.staticData.registrationDate)));
      }
      if (this.staticData.brand && this.staticData.brand.description !== '') {
        this.assetStaticData.push(new GenericElement('Brand:', this.staticData.brand.description));
      }
      if (this.staticData.fuel) {
        this.assetStaticData.push(new GenericElement('Fuel Type:', this.staticData.fuel.description));
      }
      if (this.staticData.model && this.staticData.model.description !== '') {
        this.assetStaticData.push(new GenericElement('Model:', this.staticData.model.description));
      }
      if (this.adjustInsuredValue) {
        const valAdjustInsuredValue = this.adjustInsuredValue === true ?
          this.translate.instant('YES') : this.translate.instant('NO');
        this.assetStaticData.push(new GenericElement('Adjust Insured Value:', valAdjustInsuredValue));
      }
      const setupCode = this.staticData.setup ? String(this.staticData.setup.code) : null;
      if (this.showInfocarCode && setupCode) {
        this.assetStaticData.push(new GenericElement('Infocar Code:', setupCode));
      }
    }
    this.loadAssetStaticDataRows();
  }

  loadAssetStaticDataRows() {
    const staticDataNum = this.assetStaticData.length;

    if (staticDataNum === 0) {
      return;
    }

    this.assetStaticDataRows = new Array<Array<GenericElement>>(Math.ceil(staticDataNum / 3));

    let i: number;
    let j = 0;

    for (i = 0; i < staticDataNum; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!this.assetStaticDataRows[j]) {
        this.assetStaticDataRows[j] = [];
      }
      this.assetStaticDataRows[j].push(this.assetStaticData[i]);
    }

  }

  calculatePremium() {
    this.quotationService.calculatePremium(this.quotation).subscribe(data => {
        this.premium = data;

        if (this.premium && this.premium.productPremium) {
          this.quotationService.setPreviousAnnualPremium(this.premium.productPremium.annual);
        }

        this.afterCalculatingPremium();

        this.checkAssetData();
      });
  }

  refreshUnitList(event) {
    this.unitSections = event;
    this.quotation.sections = event;
    this.checkUnitOrPackageSelected();
  }

  checkUnitOrPackageSelected() {
    this.formMessages = this.formMessages.filter(msg => msg.area !== this.areaCodeCoverage);
    let present = false;
    if (this.unitSections) {
      this.unitSections.forEach(section => {
        section.unitList.forEach(unit => {
          if (unit.selected) {
            present = true;
          }
        });
      });
    }
    if (this.quotation.packages) {
      this.quotation.packages.forEach(pack => {
        if (pack.selection) {
          present = true;
          if (pack.unitList) {
            pack.unitList.forEach(unit => {
              if (unit.selected && unit.variables) {
                unit.variables.forEach(variable => {
                  if (variable.compulsory && (!variable.value || variable.value === '')) {
                    present = false;
                  }
                });
              }
            });
          }
        }
      });
    }
    if (!present) {
      this.formMessages.push(new Message(this.areaCodeCoverage, this.translate.instant('Package not complete')));
    } else {
      const index = this.formMessages.findIndex(msg => {
        return msg.area === this.areaCodeCoverage && msg.text === this.translate.instant('Package not complete');
      });
      if (index !== -1) {
        this.formMessages.splice(index, 1);
      }
    }
    this.updateMessages();
  }

  afterCalculatingPremium() {
    this.blockingMessages.length = 0;
    this.nonBlockingMessages = this.nonBlockingMessages.filter(
      (message) => {
        return message.area !== this.areaCodePremium;
      });

    this.blockingMessages = this.blockingMessages.filter(
      (message) => {
        return message.area !== this.areaCodeProposal;
      });

    this.annualPremiumValue = null;
    this.premiumInstallment = null;
    this.premiumSubscriptionInstalment = null;

    if (this.premium && this.premium.productPremium && this.premium.productPremium.annual) {
      this.annualPremiumValue = this.premium.productPremium.annual;
    }
    if (this.premium && this.premium.productPremium && this.premium.productPremium.instalment) {
      this.premiumInstallment = this.premium.productPremium.instalment;
    }
    if (this.premium && this.premium.productPremium && this.premium.productPremium.subscriptionInstalment) {
      this.premiumSubscriptionInstalment = this.premium.productPremium.subscriptionInstalment;
    }
    if (this.premium.warnings) {
      this.premium.warnings
        .filter(msg => msg.mandatory === false)
        .map(msg => msg.description).forEach(
        (nonBlockingMessage: string) => {
          this.nonBlockingMessages.push(new Message(this.areaCodePremium, nonBlockingMessage));
        }
      );
      this.premium.warnings
        .filter(msg => msg.mandatory === true)
        .map(msg => msg.description).forEach(
        (blockingMessage: string) => {
          this.blockingMessages.push(new Message(this.areaCodePremium, blockingMessage));
        }
      );
    }

    if (this.proposalService.isPolicyATRExpiresBeyond()) {
      const messagePart1 = 'Non è stato superato il controllo di postdatabilità a partire dalla data scadenza contratto ATR. ';
      const messagePart2 = 'Impostata Data Effetto = Data di Sistema.';
      this.nonBlockingMessages.push(new Message(this.areaCodeProposal,
        messagePart1 + messagePart2
      ));
    }

    this.recalculateAvailable = false;
    this.recalculateAvailableQuotation = false;
    // reload Contract status to Check Blocking Messages
    this.subscriptions.add(
      this.proposalService.updateContract(this.proposalService.getApiContract()).subscribe((response) => {
        this.proposalService.setApiContract(response);
        this.checkBlockingMessages();
        // in caso di isDeliveryOfTransaparencyDocuments il sistema è obbligato a salvare la proposta
        // in quanto nella stessa schermata il sistema consegna documenti digitali
        if (this.isDeliveryOfTransaparencyDocuments) {
          this.saveQuoteProposalPrintDoc();
        } else if (this.isAutoSaveQuoteEnabled && !this.disableSaveQuote) {
          this.saveQuote(true);
        }
      })
    );
  }

  checkBlockingMessages() {

    this.updateMessages();
    this.checkUnitOrPackageSelected();
    this.disableSaveQuote = this.validationMessages.length !== 0;
    this.disableNext = this.formMessages.length !== 0;
  }

  goToEditAssetData() {
    this.stateService.nextState(Action.EDIT_ASSET_DATA_BUTTON_PRESSED);
  }

  goToProposal() {
    this.stateService.nextState(Action.NEXT_BUTTON_PRESSED);
  }

  thisIsNotMyVehicle() {
    if (this.isSubstitution) {
      this.stateService.nextState(Action.SUBSTITUTION_EDIT_ASSET);
    } else {
      this.stateService.nextState(Action.NOT_MY_VEHICLE_BUTTON_PRESSED);
    }
    this.openProductAssetModal();
  }

  openPremiumDetailsModal() {
    this.quotationService.setPremium(this.premium);
    this.customModalService.openModal(this.modalViewContainerRef,
      (this.quotationDetailsModalComponent ? this.quotationDetailsModalComponent : QuotationDetailsModalComponent));
  }

  openContributionsAndTaxesModal() {
    this.quotationService.setPremium(this.premium);
    const modal = this.customModalService.openModal(this.modalViewContainerRef, ContributionsAndTaxesModalComponent);
    modal.instance.eventLpsAmountModified.subscribe((data: Array<number>) => {
      this.quotation.premium.productPremium.lpsTaxDetailSubscriptionInstallment.lpsAmount = data[0];
      this.quotation.premium.productPremium.lpsTaxDetailInstallment.lpsAmount = data[1];
      this.calculatePremium();
    });
  }

  openLPSTaxesModal() {
    this.quotationService.setPremium(this.premium);
    this.customModalService.openModal(this.modalViewContainerRef, LpsTaxesModalComponent);
  }

  openCommissionsModal() {
    this.customModalService.openModal(this.modalViewContainerRef, CommissionsModalComponent, this.eventPropagation);
  }

  openWarrantiesSummaryModal() {
    this.customModalService.openModal(this.modalViewContainerRef, WarrantiesSummaryModalComponent);
  }

  openDiscountsModal() {
    this.customModalService.openModal(this.modalViewContainerRef,
      (this.discountsModalComponent ? this.discountsModalComponent : DiscountsModalComponent),
      this.eventPropagation);
  }

  isForeignPlate() {
    if (this.vehicle.plate && this.vehicle.plate.plateType) {
      return this.vehicle.plate.plateType.description === 'Targa Estera';
    } else {
      return false;
    }
  }

  changeATRHeaderVisibility(event) {
    this.showATRHeader = event;
  }

  changeATRTableVisibility(event) {
    this.showATRTable = event;
  }

  onUnitsViewModeChange(event) {
    if (event === 'BOX') {
      this.viewUnitsBox = true;
    } else if (event === 'LIST') {
      this.viewUnitsBox = false;
    }
  }

  changeRecalculateAvailable(event?) {
    // TODO controllare eventualmente le segnalazioni
    this.recalculateAvailable = true;
    this.revaluatePremium();
  }

  revaluatePremium() {
    this.recalculateAvailableQuotation = this.recalculateAvailable && (this.formMessages.length === 0);
    this.disableSaveQuote = this.recalculateAvailableQuotation || (this.validationMessages.length !== 0);
    this.checkUnitOrPackageSelected();
    this.disableNext = this.recalculateAvailableQuotation || (this.formMessages.length !== 0);

    if (this.recalculateAvailableQuotation) {
      this.annualPremiumValue = null;
      this.premiumInstallment = null;
      this.premiumSubscriptionInstalment = null;
    }
  }

  saveQuote(automatic = false) {
    const contractId = this.proposalService.getContractId();
    const quoteId = this.proposalService.getQuoteId();

    const quote = new ApiQuote(quoteId, null);

    this.quoteService.saveQuote(contractId, quote).subscribe(data => {
        /* The same message of the angularjs flow */
        if (!automatic) {
          this.modalService.open([this.translate.instant('Quote n.') + ' '
            + data.quoteNumber + ' ' + this.translate.instant('successfully saved')],
            this.translate.instant('SYSTEM MESSAGE'));
        }
      });
  }

  verifyAsset(event) {
    this.formMessages = event;

    this.updateMessages();
    this.revaluatePremium();
  }

  verifyParameterBlockingMessages(event) {
    this.parameterMessages = event;
    this.updateMessages();
    this.revaluatePremium();
  }

  verifyParameterNonBlockingMessages(event) {
    this.nonBlockingMessages = this.nonBlockingMessages.filter(
      (message) => {
        return message.area !== this.areaCodeParameter;
      });

    if (this.nonBlockingMessages.length > 0) {
      const store = this.nonBlockingMessages;
      if (event.length > 0) {
        event.forEach((messaggeEvent) => {
          store.push(messaggeEvent);
        });
        this.nonBlockingMessages = store;
      }
    } else {
      this.nonBlockingMessages = event;
    }
  }

  genericElementsTrackByFn(index, genericElement: GenericElement) {
    return genericElement.label;
  }

  initializeVariables() {
    this.variablesService.getVariables().subscribe(
      (data) => {
        this.variables = data;
        this.variablesService.setVariables(data);

        this.additionalDeclarationCheck();
        this.showPartyVariablesCheck();

        this.editableVariables = data.filter(
          (variable) => {
            return variable.visibilityArea === 4 && variable.editableInView;
          });
        this.loadVariables();

        if (this.assetForm) {
          this.assetForm.addControl('setup', new UntypedFormControl(this.staticData.setup));
          this.assetForm.addControl('setupDescription',
            new UntypedFormControl((this.staticData.setup) ? this.staticData.setup.description : ''));

        } else {
          this.assetForm = this.formBuilder.group(
            {
              setup: [this.staticData.setup],
              setupDescription: [(this.staticData.setup) ? this.staticData.setup.description : ''],
            }
          );
        }
        this.reInitializeSetups();

        this.updateMessages();
      }
    );

    const variablesChannelSubscription = this.variablesService.getVariablesObserable().subscribe(data => {
      this.variables = data;
      this.showPartyVariablesCheck();
    });
    this.subscriptions.add(variablesChannelSubscription);
  }

  initializeContractVariables() {
    this.variablesService.getContractVariables().subscribe(
      (data) => {
        this.contractVariables = data;
        this.variablesService.setContractVariables(this.contractVariables);
        this.addOnContractFactorChangeListner();
      });

  }

  initializeContractClauses() {
    this.clauseService.getPreQuotationClauses(this.proposalService.getContractId()).subscribe(
      (data) => {
        this.clausesList = data;
      }
    );
  }

  loadVariables() {

    this.assetForm = this.variablesService.toFormGroup(this.editableVariables);

    const tipoPacchettoInfortuniVariable = this.editableVariables.find(data => data.code === '3TIPIC');

    if (tipoPacchettoInfortuniVariable) {
      tipoPacchettoInfortuniVariable.order = 1000000;
    }

    const variablesNum = this.editableVariables.length;

    if (variablesNum === 0) {
      return;
    }

    this.editableVariables.sort(
      (a: Variable, b: Variable) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 0;
        }
      }
    );

    this.variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 2));

    let i: number;
    let j = 0;

    for (i = 0; i < variablesNum; i++) {
      if (i !== 0 && i % 2 === 0) {
        j++;
      }
      if (!this.variablesRows[j]) {
        this.variablesRows[j] = [];
      }
      this.variablesRows[j].push(this.editableVariables[i]);
    }

  }

  onVariableChange(event: string) {
    this.resetPackage();


    this.variablesService.updateVariables(this.editableVariables).subscribe(
      (data) => {
        this.variablesService.setVariables(data);
        this.changeRecalculateAvailable();
        this.getPremium();
      }
    );
  }

  onPacketChange(event: boolean) {
    this.quotation.isPackage = true;
    this.getPremium();
  }

  onSetupChange() {
    if (!this.assetForm) {
      return;
    }

    if (!this.setupOutOfMagazine && !this.assetForm.get('setup').value) {
      this.assetForm.get('setup').setErrors({required: true});
    }

    if (this.setupOutOfMagazine && !this.assetForm.get('setupDescription').value) {
      this.assetForm.get('setupDescription').setErrors({required: true});
    }

    if ((!this.assetForm.get('setup').value && !this.setupOutOfMagazine)
      || (!this.assetForm.get('setupDescription').value && this.setupOutOfMagazine)) {
      return;
    }

    let setup: ApiSetup;
    if (this.setupOutOfMagazine) {
      setup = new ApiSetup('', '', this.assetForm.get('setupDescription').value, '', '', '');
    } else {
      setup = this.assetForm.get('setup').value;
    }
    this.proposalService.getApiContract().vehicle.vehicleStaticData.setup = setup;
    this.proposalService.updateContract(this.proposalService.getApiContract()).subscribe(
      (data) => {
        this.staticData.setup = setup;
        this.initializeVariables();
        this.changeRecalculateAvailable();
      }
    );
  }

  compareGenericEntities(ent1: any, ent2: any): boolean {
    return ent1 && ent2 ? ent1.code === ent2.code : ent1 === ent2;
  }

  reInitializeSetups() {

    const fuelType = this.staticData.fuel;
    const doors = this.staticData.doors;
    const power = this.staticData.power;
    const brandCode = this.staticData.brand ? this.staticData.brand.code : null;
    const modelCode = this.staticData.model ? this.staticData.model.code : null;
    let registrationDate = this.staticData.registrationDate ? this.staticData.registrationDate : null;
    if (brandCode && modelCode && registrationDate) {
      if (!(registrationDate instanceof Date)) {
        registrationDate = new Date(registrationDate);
      }
      const registrationMonth = String(registrationDate.getMonth() + 1);
      const registrationYear = String(registrationDate.getFullYear());
      const approvalCode = String(this.proposalService.getApiContract().vehicle.vehicleStaticData.approvalCode);
      const vehicleCode = this.proposalService.getApiContract().vehicle.vehicleStaticData.vehicleCodes;
      this.initializeSetups(fuelType?.code, doors?.description, power?.description, brandCode, modelCode, registrationMonth,
          registrationYear, approvalCode, true, vehicleCode);
    }
  }

  onWarningIconClick() {
    this.showFloatingMessages = true;
    this.enableWarningIcon = false;
  }

  onCloseErrorsClick() {
    this.showFloatingMessages = false;
    this.enableWarningIcon = true;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window && this.errorsElement && this.errorsElement.nativeElement &&
      window.pageYOffset > this.errorsElement.nativeElement.offsetTop) {
      this.showFloatingMessages = false;
      this.enableWarningIcon = false;
    } else {
      this.enableWarningIcon = true;
    }
  }

  variablesRowsContract(): Variable[][] {
    return this.genericVariablesRows(this.contractVariables);
  }

  variablesRowsAsset(): Variable[][] {
    return this.genericVariablesRows(this.variables);
  }

  genericVariablesRows(variables: Variable[]): Variable[][] {

    let filteredVariables: Variable[];

    filteredVariables = variables.filter(
      (variable) => {
        return variable.visibilityArea === 0
          || variable.visibilityArea === 1
          || variable.visibilityArea === 4;
      }
    ).sort(
      (a: Variable, b: Variable) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 0;
        }
      }
    );

    const variablesNum = filteredVariables.length;

    const variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 3));
    let i: number;
    let j = 0;

    for (i = 0; i < variablesNum; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!variablesRows[j]) {
        variablesRows[j] = [];
      }
      variablesRows[j].push(filteredVariables[i]);
    }

    return variablesRows;
  }

  switchView() {
    this.packageView = !this.packageView;
    this.quotationService.setCustomView(this.packageView);
    this.quotation.isPackage = this.packageView;
    this.quotation.isRecalculate = true;
    // reset
    this.quotation.packages = [];
    this.quotation.sections = [];
    this.getPremium();
  }

  openPackageModal(packet: Package) {
    const comp = this.customModalService.openModal(this.packageModalViewContainerRef, this.packageVariablesModal,
      this.eventPropagation, () => {
        // TODO: doSomenthing
      });
    comp.instance.title = packet.description;
    comp.instance.package = packet;
    comp.instance.quotation = this.quotation;
    comp.instance.eventPackageModified.subscribe((data: Package) => {
      this.quotation.isPackage = true;
      this.quotation.isRecalculate = true;
      this.quotation.packages = this.quotation.packages.map((pk) => {
        if (pk.code === data.code) {
          pk = data;
        }
        return pk;
      });
      this.getPremium();
    });

  }

  showPartyVariablesCheck() {
    let filteredVariables;
    if (this.variables) {
      filteredVariables = this.variables.filter(
        (variable) => {
          return variable.visibilityArea === 2;
        }
      );
    }
    if (!filteredVariables || filteredVariables.length === 0) {
      this.showPartyVariables = false;
    }
  }

  goToMandatoryData() {
    this.validateConstraints();
    if (this.constraintMessages.length === 0) {
      this.stateService.nextState(Action.SUBSTITUTION_NEXT_BUTTON_PRESSED);
    }
  }

  validateConstraints() {
    // removing constraint messages from validationMessages
    const constraintsAreaCode = 'LIENS';
    const filteredMessages = this.validationMessages.filter((msg: Message) => msg.area !== constraintsAreaCode);
    this.validationMessages.length = 0;
    Array.prototype.push.apply(this.validationMessages, filteredMessages);
    const technicalData = this.parameterService.getPolicyTechnicalData();
    const isConstraintEnabled = (technicalData && technicalData.constraint && technicalData.constraint.constraint) ? true : false;
    if (isConstraintEnabled) {
      const liensList = this.liensService.getLiensList();
      if (liensList.length === 0) {
        this.constraintMessages.push(new Message(constraintsAreaCode, 'There must be at least one lien'));
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected openProductAssetModal() {
    const modal = this.customModalService.openModal(this.productAssetModalViewContainerRef, ChangeVehicleModalComponent,
      this.eventPropagation, () => {
        if (modal.instance.isChangePlate && this.stateService.isNotValidContract() === true) {
            this.stateService.nextState(Action.CONFIRM_BUTTON_PRESSED);
        } else {
          if (modal.instance.bRecalculateQuotation) {
            this.assetStaticData.length = 0;
            this.getPremium(true);
          }
          this.onProductAssetModalDestroy();
        }
      });
  }

  protected onProductAssetModalDestroy() {
    if (this.proposalService.getIsReadyToCreateNewContract()) {
      this.proposalService.setIsReadyToCreateNewContract(false);
      this.proposalService.createNewContract(this.proposalService.getContractIdCached()).subscribe(
        (contract: ApiContract) => {
          this.proposalService.setApiContract(contract);
          this.stateService.nextState(Action.CONFIRM_BUTTON_PRESSED);
        }
      );
    } else {
      this.stateService.nextState(Action.CANCEL_BUTTON_PRESSED);
    }
  }

  // substitution flow

  private initialization() {

    this.adjustInsuredValue = this.proposalService.getApiContract().adjustInsuredValue;
    this.vehicle = this.proposalService.getAsset();
    this.staticData = this.vehicle.vehicleStaticData;
    if (this.proposalService.getVehicleProperties()) {
      this.vehicleProperties = this.proposalService.getVehicleProperties();
      this.checkAssetData();
    } else {
      this.proposalService.retrieveVehicleProperties(this.proposalService.getContractId()).subscribe(
        (data) => {
          this.vehicleProperties = data;
          this.populateAssetStaticData();
          this.checkAssetData();
        }
      );
    }

    this.initializeVariables();
    this.initializeContractVariables();
    this.initializeContractClauses();


    this.stateService.sendResetStartFormEvent();

    this.setupOutOfMagazine = this.proposalService.getApiContract().isbValueOutsideMagazine;

    // this.additionalDeclarationCheck();

  }

  private additionalDeclarationCheck() {
    if (this.variables) {
      const additionalDeclarationVariable: Variable = this.variables.find(
        variable => {
          return variable.identificationCode === InsuranceBackgroundService.ADDITIONAL_DECLARATION_VARIABLE_CODE;
        }
      );
      if (additionalDeclarationVariable && additionalDeclarationVariable.compulsory
        && !additionalDeclarationVariable.value) {
        const message = new Message(this.areaCode, 'Additional Declaration is mandatory');
        if (!this.errorMessagesService.includesMessage(this.insuranceStatusMessages, message)) {
          this.insuranceStatusMessages.push(message);
        }
      }
    }
  }

  private addOnContractFactorChangeListner() {
    if (this.contractVariableListner) {
      this.contractVariableListner.unsubscribe();
    }
    this.contractVariableListner = this.variablesService.getContractVariablesObserable().subscribe(
      (data) => {
        this.contractVariables = data;
      });
  }

  private initializeSetups(
    fuelType: string,
    door: string,
    power: string,
    brandCode: string,
    modelCode: string,
    registrationMonth: string,
    registrationYear: string,
    approvalCode: string,
    firstInitialization: boolean,
    vehicleCode: string[]) {
    this.magazineService.getSetups(this.vehicle.magazineType,
      fuelType, door, power, brandCode, modelCode, registrationMonth, registrationYear, approvalCode, vehicleCode).subscribe(
      (data: any) => {
        if (data.length < 1) {
          this.setupsListMode = false;
          // this.assetForm.get('setup').disable();
          if (firstInitialization) {
            this.assetForm.patchValue({
              setup: new ApiSetup('', '', '', '', '', ''),
              setupDescription: this.staticData.setup.description
            });
          } else {
            this.assetForm.patchValue({
              setup: new ApiSetup('', '', '', '', '', ''),
              setupDescription: this.staticData.setup.description
            });
          }
        } else {
          this.setups = data;
          this.setupsListMode = true;
          // this.assetForm.get('setup').enable();
          if (firstInitialization) {
            this.assetForm.patchValue({
              setup: this.staticData.setup,
              setupDescription: this.staticData.setup.description
            });
          } else {
            this.assetForm.patchValue({
              setup: new ApiSetup('', '', '', '', '', ''),
              setupDescription: this.staticData.setup.description
            });
          }
        }
        this.setupsInitialized = true;
      }, err => {
        this.setupsListMode = false;
        this.assetForm.get('setup').disable();
        if (firstInitialization) {
          this.assetForm.patchValue({
            setup: new ApiSetup('', '', '', '', '', ''),
            setupDescription: this.staticData.setup.description
          });
        } else {
          this.assetForm.patchValue({
            setup: new ApiSetup('', '', '', '', '', ''),
            setupDescription: this.staticData.setup.description
          });
        }
        this.setupsInitialized = true;
      });
  }

  saveQuoteProposalPrintDoc() {
    // const contractId = this.proposalService.getContractId();
    const quote = new ApiQuote(this.proposalService.getQuoteId(), null);
    this.quoteService.saveQuote(this.proposalService.getContractId(), quote).subscribe( resp => {
      if (!!resp) {
        this.proposalService.saveProposal().subscribe(saveProposalResp => {
          this.proposalService.printDocuments(true).subscribe(printResponse => {
            if (!!printResponse?.messages?.length) {
              this.modalService.open(printResponse.messages, this.translate.instant('SYSTEM MESSAGE'));
            }
          });
        });
      }
    });
  }
}

import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'lpc-checkbox-accordion',
  templateUrl: './lpc-checkbox-accordion.component.html',
  styleUrls: ['./lpc-checkbox-accordion.component.scss']
})
export class LpcCheckboxAccordionComponent implements OnInit, OnDestroy {

  @ViewChild('toolBodyContent', {static: true, read: ElementRef}) toolBodyContent: ElementRef;

  @Output() toolCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() toolName: string;

  @Input() active: boolean;

  @Input() isSelected: boolean;

  @Input() initialOpen = false;

  @Input() mandatory = false;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl(false)
  });

  private $subscriptions: Subscription[] = [];

  constructor() {
  }

  public isToolBodyOpen(): boolean {
    return this.formGroup.get('active').value;
  }

  ngOnInit() {
    this.formGroup.get('active').setValue(this.initialOpen);
    if (this.mandatory) {
      this.formGroup.get('active').disable();
    }
    this.$subscriptions.push(
      this.formGroup.get('active').valueChanges.subscribe(value => {
        this.toolCheck.emit(value);
      })
    );
  }

  /*ngOnChanges(changes: SimpleChanges) {
    if (changes.open) {
      this.formGroup.get('active').setValue(changes.open.currentValue);
    }
  }*/

  /*ngOnChanges(changes: SimpleChanges): void {
    if (changes.tool) {
      const currentTools: PassResponseOption = changes.tool.currentValue;
      this.unsubscribe(this.$activeChanges);
      this.formGroup = new FormGroup({});

      currentTools.forEach(tool => {
        this.formGroup.addControl(tool.id, new FormGroup({
          active: new FormControl(),
          body: new FormGroup({
            dummy: new FormControl({value: null, disabled: true})
          })
        }));
        this.$activeChanges.push(
          this.formGroup.get(tool.id).get('active').valueChanges.subscribe(active => {
            if (active) {
              this.formGroup.get(tool.id).get('body').enable({ emitEvent: false });
            } else {
              this.formGroup.get(tool.id).get('body').disable({ emitEvent: false });
            }
          })
        );
      });
    }
  }*/

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}

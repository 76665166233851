import { ClaimDocument } from './claim-document';

export class DocumentCategory {
    public documents: ClaimDocument[];
    public categoryId: string;
    public categoryName: string;
    public mandatory: boolean;
    public uuid: string;
    public microCategory: string;
    public macroCategory: string;
}

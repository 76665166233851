  <div [formGroup]="partyRolesForm" >
    <ng-container *ngFor="let role of partyRoles | keyvalue">
      <div *ngFor="let roleValue of role.value; index as i"  [ngClass]="{'rgi-gp-flex': roleValue.showPercentage}" class="rgi-gp-custom-flex-bottom">
        <rgi-rx-drop-container *ngIf="partyRolesForm.get(roleValue.role + '_' + i)"
                               formControlName="{{roleValue.role + '_' + i}}"
                               label="{{roleValue === role.value[0] ? roleValue.description : ''}}"
                               [select]="'searchOrCreateSubject'" viewField="nominative"
                               [selectData]="getSubjectDropContainerData(role.key)"
                               [attr.data-qa]="'btn_add_role_' + role.key"
                               (onValueChange)="onPartySelected($event, role.key)"
                               [ngClass]="{'rgi-gp-flex-70': roleValue.showPercentage,
                                 'rgi-w-100-perc': !roleValue.showPercentage || !roleValue.idParty}">
          <rgi-rx-drag-action [class]="action.actionsExt.class" (onAction)="action.actionsExt.onAction.emit($event)"
                              *ngFor="let action of actionsExtension">
          </rgi-rx-drag-action>
          <rgi-rx-drag-action class="rgi-ui-icon-edit" (onAction)="modifySubject(role.key, roleValue.idParty)"
                              [attr.data-qa]="'modify_role_' + role.key"></rgi-rx-drag-action>
          <rgi-rx-drag-remove (onRemove)="deleteSubject(role.key, roleValue.idParty)"
                              [attr.data-qa]="'delete_role_' + role.key"></rgi-rx-drag-remove>
        </rgi-rx-drop-container>
        <rgi-rx-form-field class="rgi-gp-flex-30 test" *ngIf="roleValue.idParty && roleValue.showPercentage">
          <div *ngIf="roleValue === role.value[0]">&nbsp;</div>
          <div class="rgi-gp-custom-flex">
          <label rgiRxLabel></label>
          <input type="number" rgiRxInput
                 formControlName="{{roleValue.role}}_{{roleValue.idParty}}_percentage"
                 [attr.data-qa]="'linked_' + roleValue.role + '_' + roleValue.idParty + '_percentage'">
          </div>
        </rgi-rx-form-field>
        <ng-container *ngFor="let linkedRole of roleValue.linkedRoles | keyvalue">
          <div *ngFor="let linkedRoleValue of linkedRole.value; index as j" [ngClass]="{'rgi-gp-flex': linkedRoleValue.showPercentage}">
            <rgi-rx-drop-container *ngIf="partyRolesForm.get(linkedRoleValue.role + '_' + j)"
                                   formControlName="{{roleValue.role + '_' + linkedRoleValue.role + '_' + j}}"
                                   label="{{linkedRoleValue === linkedRole.value[0] ? linkedRoleValue.description : ''}}"
                                   [select]="'searchOrCreateSubject'" viewField="nominative"
                                   [selectData]="getSubjectDropContainerData(role.key)"
                                   [attr.data-qa]="'btn_add_role_' + linkedRole.key"
                                   (onValueChange)="onPartySelected($event, linkedRole.key)"
                                   [ngClass]="{'rgi-gp-flex-70': linkedRoleValue.showPercentage,
                                      'rgi-w-100-perc': !linkedRoleValue.showPercentage || !linkedRoleValue.idParty}">
              <rgi-rx-drag-action [class]="action.actionsExt.class" (onAction)="action.actionsExt.onAction.emit($event)"
                                  *ngFor="let action of actionsExtension">
              </rgi-rx-drag-action>
              <rgi-rx-drag-action class="rgi-ui-icon-edit" (onAction)="modifySubject(linkedRole.key, linkedRoleValue.idParty)"
                                  [attr.data-qa]="'modify_linked_role_' + linkedRole.key"></rgi-rx-drag-action>
              <rgi-rx-drag-remove (onRemove)="deleteSubject(linkedRole.key, linkedRoleValue.idParty)"
                                  [attr.data-qa]="'delete_linked_role_' + linkedRole.key">
              </rgi-rx-drag-remove>
            </rgi-rx-drop-container>
            <rgi-rx-form-field class="rgi-gp-flex-30"
                               *ngIf="linkedRoleValue.idParty && linkedRoleValue.showPercentage">
              <label rgiRxLabel></label>
              <input type="number" rgiRxInput
                     formControlName="linked_{{linkedRoleValue.role}}_{{linkedRoleValue.idParty}}_percentage"
                     [attr.data-qa]="'linked_' + linkedRoleValue.role + '_' + linkedRoleValue.idParty + '_percentage'">
            </rgi-rx-form-field>
          </div>
        </ng-container>

      </div>
    </ng-container>
  </div>

import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { ROUTES_GP_VCONT_CONFIRMS, ROUTES_GP_VCONT_DATES } from '../../group-policy-constants/routes-constants';
import { ErrorCode } from '../../group-policy-models/group-policy-issue-policy-data';
import { Documents } from '../../group-policy-models/group-policy-issue-summary';
import { GroupPolicyVcontRouteData } from '../../group-policy-models/group-policy-vcont-date';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyStateVcontConfirms } from '../group-policy-state';
// tslint:disable:max-line-length

/**
 * @author: dmasone
 * @description: Service used for render vcont confirms step
 */
@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpConfirmsService {


  constructor(
    protected groupPolicyService: GroupPolicyResourceService,
    protected routingService: RoutingService,
    protected apiRestErrorService: GroupPolicyApiRestErrorService,
    protected integrationService: GroupPolicyIntegrationService) { }


  public initConfirms$(groupPolicyStateConfirms$: Observable<GroupPolicyStateVcontConfirms>, activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontConfirms> {
    const previousStepData = activeRoute.getRouteData<GroupPolicyVcontRouteData>();
    return groupPolicyStateConfirms$.pipe(
      mergeMap((st: GroupPolicyStateVcontConfirms) => {
        return combineLatest(of(st)
          // this.retrieveDocuments$(of(st), previousStepData.resId)
        );
      }),
      map(([st]) => {
        st.errors = previousStepData.errors;
        st.type = previousStepData.type;
        st.documents = previousStepData.documents;
        st.steps = previousStepData.steps.concat([ROUTES_GP_VCONT_CONFIRMS]);
        st.labels = previousStepData.labels.concat(['_GP_._TITLE_._CONFIRMS_']);

        return st;
      })
    );
  }

  public retrieveDocuments$(groupPolicyStateConfirms$: Observable<GroupPolicyStateVcontConfirms>, uuid: string, operationCode?: string) {
    return groupPolicyStateConfirms$.pipe(
      concatMap((state: GroupPolicyStateVcontConfirms) => {
        state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, 'FOOTER');
        return this.groupPolicyService.retrieveDocumentsPostSales$(uuid, operationCode).pipe(
          map((documents: Documents[]) => {
            state.documents = documents;
            return state;
          }),
          catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
        );
      }),
      catchError(this.apiRestErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateVcontConfirms) => st)
    );
  }

  public actionBack$(groupPolicyStateConfirms$: Observable<GroupPolicyStateVcontConfirms>, previousStepData: GroupPolicyVcontRouteData, activeRoute: ActiveRoute, targetRoute = ROUTES_GP_VCONT_DATES): Observable<GroupPolicyStateVcontConfirms> {
    return groupPolicyStateConfirms$.pipe(
      tap(() => {
        previousStepData.errors = [];
        previousStepData.type = ErrorCode.BLOCKING;
        previousStepData.steps = previousStepData.steps.slice(0, previousStepData.steps.indexOf(targetRoute));
        previousStepData.labels = previousStepData.labels.slice(0, previousStepData.steps.indexOf(targetRoute));
        this.integrationService.navigate(this.routingService, targetRoute, previousStepData, activeRoute);
      })
    );
  }

  public actionClose$(groupPolicyStateConfirms$: Observable<GroupPolicyStateVcontConfirms>, previousStepData: GroupPolicyVcontRouteData, _activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontConfirms> {
    return groupPolicyStateConfirms$.pipe(
      tap(() => {
        previousStepData.steps = [];
        previousStepData.labels = [];
        this.integrationService.backToInquiry(previousStepData.proposalNumber, _activeRoute.id, previousStepData.idParentSession);
      })
    );
  }

}

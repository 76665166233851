export interface DocumentList {
  idDocumento: string;
  nomeFile: string;
  documentCode: string;
  idDocument: string;
}

export interface DocumentEvent {
  eventName: string;
  document: DocumentList;
  type: string;
}

export const _DOCUMENT_ = {
  EXTENSION_PDF: 'pdf',
  EXTENSION_XLS: 'xls',
  EXTENSION_XLSX: 'xlsx',
  EXTENSION_CSV: 'csv',
  EXTENSION_HTML: 'html',
  EXTENSION_RTF: 'rtf'
};

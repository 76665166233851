import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuestionnairesManagerService } from '@rgi/questionnaires-manager';
import { Observable, of } from 'rxjs';
import { LpcQuestionnaireComponent } from '../modules/lpc-questionnaire/lpc-questionnaire/lpc-questionnaire.component';

@Injectable()
export class PlcQuestService {

  constructor(
    protected httpClient: HttpClient,
    protected questManager: QuestionnairesManagerService
  ) {
  }

  public prevalQuest(operationDataKey: string, step: string, operation: any): Observable<any[]> {
    return of([]);
  }


  public disableQuest(operationDataKey: string, step: string, operation: any): any[] {
    return [];
  }

  public defaultAnswerBySec(operationDataKey: string, step: string, operation: any): any[] {
    return [];
  }

  public checkQuestionnariesOrder(avcQuestionnaire: LpcQuestionnaireComponent): any[] {
    return avcQuestionnaire.questionnaireManager.questList;
  }

  public getSurveyVersion(uuid: string): Observable<any> {
    return this.questManager.getSurveyVersion(uuid);
  }

  public getPrevalAnswersForClaim(
    _roles: any,
    _operation: any,
    _gross: number
  ): { answers: any[], disabledQuestions: any[] } {
    return { answers: [], disabledQuestions: [] };
  }

}


<!-- PARTIES ----------------------------------------------------------------------------->
<div class="mb-1" *ngIf="isEntitledBeforeQuotation()">
  <div class="nmf_cont_titsez nmf_cont_titsez_color">
    <span class="header-icon rgifont rgi-riparti_coass nfm_font_size"></span>
    <h3 class="header-text" translate>Parties</h3>
  </div>
  <mic-parties [showQuotationModal]="false"
               [skipQuotation]="true"
               (eventPropagation)="eventPropagation.emit($event)"
               (valuesChanged)="onPartyDataChanged()"
               (subscriberChanged)="onSubscriberChanged()"
               (subscriberRemoved)="onSubscriberRemoved()"
               (subscriberPresentCheck)="onSubscriberPresetCheck($event)"
               (nonBlockingMessagesRoles)="addPartyRolesNonBlockingMessaging($event)"
               (validationMessagesRoles)="addPartyRolesMessaging($event)">
  </mic-parties>
  <div *ngIf="!partiesConfirmed" class="btn-group btn-group-justified">
    <div class="btn-group">
      <button class="btn btn-warning pull-right text-uppercase" [disabled]="!hasSubscriber"  (click)="confirmParties()" type="submit">
        <span translate>confirm</span>
      </button>
    </div>
  </div>
  <div *ngIf="partiesConfirmed">
    <br>
    <mic-party-variables (eventPropagation)="eventPropagation.emit($event)"
                         (variablesCompleted)="onPartyVariablesCompleted($event)" *ngIf="assetDataCompleted && variables"
                         [partyAreaVariablesCompletenessErrors]="partyAreaVariablesCompletenessErrors" [variablesType]="PARTY_KEY"
                         [variables]="variables">
    </mic-party-variables>
  </div>
</div>

<br>
<div *ngIf="partiesConfirmed">
  <mic-vehicle (eventPropagation)="eventPropagation.emit($event)" (assetDataCompleted)="onAssetDataCompleted($event)" (hideAll)="onHideAll($event)"
               (updateAssetData)="onUpdateAssetData($event)" (vehicleChanged)="onVehicleChanged($event)"
               [hasSystemIntegration]="hasSystemIntegration">
  </mic-vehicle>
  <br>
  <mic-variables (eventPropagation)="eventPropagation.emit($event)" (variablesCompleted)="onAssetVariablesCompleted($event)" *ngIf="assetDataCompleted && variables"
                 [assetAreaVariablesCompletenessErrors]="assetAreaVariablesCompletenessErrors" [variablesCompletenessErrors]="variablesCompletenessErrors"
                 [variablesRows]="variablesRowsAsset()"
                 [variablesType]="ASSET_KEY"
                 [variables]="variables"
                 [editable]="isSectionEditable('asset_variables')">
  </mic-variables>
  <br>
  <div *ngIf="!isEntitledBeforeQuotation()">
    <mic-party-variables (eventPropagation)="eventPropagation.emit($event)" (variablesCompleted)="onPartyVariablesCompleted($event)"
                         *ngIf="assetDataCompleted && variables"
                         [partyAreaVariablesCompletenessErrors]="partyAreaVariablesCompletenessErrors"
                         [variablesType]="PARTY_KEY"
                         [variables]="variables">
    </mic-party-variables>
    <br>
  </div>
  <br>
  <mic-insurance-background (eventPropagation)="eventPropagation.emit($event)" (insuranceBackgroundCompleted)="onInsuranceBackgroundCompleted($event)"
                            (riskCertificateError)="onRiskCertificateCompletenessErrors($event)"
                            (showATRHeader)="changeATRHeaderVisibility($event)"
                            (showATRTable)="changeATRTableVisibility($event)"
                            (showEditAtr)="changeEditAtrVisibility($event)"
                            (updateMessages)="updateInsuranceBackgroundMessages($event)"
                            *ngIf="showInsuranceBackground"
                            [insuranceStatusCompletenessErrors]="insuranceStatusCompletenessErrors"
                            [isAdditionalDeclarationMandatory]="additionalDeclarationIsMandatory"
                            [showEdit]="true">
  </mic-insurance-background>
  <br>
  <mic-risk-certificate (riskCertificateError)="onRiskCertificateCompletenessErrors($event)" *ngIf="showATR && (showATRHeader || showATRTable)"
                        [riskCertificateCompletenessErrors]="riskCertificateCompletenessErrors" [showATRHeader]="showATRHeader"
                        [showATRTable]="showATRTable"
                        [showEdit]="showEditAtr">
  </mic-risk-certificate>
  <br>
  <mic-clause *ngIf="assetDataCompleted && clausesList" [clauseList]="clausesList" [headerIcon]="'rgi-clausole'"
              [header]="'Pre-Quotation Clauses'" [viewError]="true" [areClausesEnabled]="isSectionEditable('asset_clauses')"
              (clauseChange)="onClauseChange()"></mic-clause>
  <br>
  <div *ngIf="(validationMessages?.length > 0
      || nonBlockingMessages?.length > 0) && enableWarningIcon && !showFloatingMessages"
       class="errors-warnings-floating-wrapper ng-scope">
    <div (click)="onWarningIconClick()" class="errors-warnings-floating-icon minimized">
      <span class="rgifont rgi-exclamation-triangle"></span>
    </div>
  </div>

  <div #errorsElement class="errors-warnings-floating-wrapper fix-to-bottom">
    <div *ngIf="(validationMessages?.length > 0
          || nonBlockingMessages?.length > 0) && showFloatingMessages" class="errors-warnings-floating-messages">
      <div class="content-errors nmf_content-errors">
        <pnc-form-message [messages]="nonBlockingMessages" data-qa="action-error-messages" detail="validationError"
                          objectType="complex" type="warning">
        </pnc-form-message>
        <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                          objectType="complex" type="error">
        </pnc-form-message>
        <div (click)="onCloseErrorsClick()" *ngIf="(validationMessages?.length > 0
                  || nonBlockingMessages?.length > 0) && showFloatingMessages" class="errors-warnings-floating-icon expanded">
          <span class="rgifont rgi-close"></span>
        </div>
        <div class="nmf_triangle"></div>
      </div>
    </div>
  </div>

  <div *ngIf="!showFloatingMessages" id="error-container">
    <pnc-form-message *ngIf="nonBlockingMessages?.length > 0" [messages]="nonBlockingMessages" data-qa="action-error-messages"
                      detail="validationError" objectType="complex" type="warning">
    </pnc-form-message>
    <pnc-form-message *ngIf="validationMessages?.length > 0" [messages]="validationMessages" data-qa="action-error-messages"
                      detail="validationError" objectType="complex" type="error">
    </pnc-form-message>
  </div>

  <br>
  <div *ngIf="assetDataCompleted" class="btn-group btn-group-justified">
    <div *ngIf="!isSubstitution" class="btn-group">
      <button (click)="removeSession()" class="btn btn-warning pull-right text-uppercase" data-qa="btn-asset-cancel"
              type="button">
        <span translate>Cancel</span>
      </button>
    </div>
    <div class="btn-group">
      <button (click)="goToQuote()" [disabled]="validationMessages?.length > 0 || riskCertificateCompletenessErrors || insuranceStatusCompletenessErrors" class="btn btn-warning pull-right text-uppercase"
              data-qa="btn-asset-confirm"
              type="button">
        <span *ngIf="validationMessages?.length > 0" translate>Confirm</span>
        <span *ngIf="validationMessages?.length == 0" translate>Go to quote</span>
      </button>
    </div>
  </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';

@Component({
  selector: 'lpc-life-movement-installment-premium-detail',
  templateUrl: './life-movement-installment-premium-detail.component.html',
  styleUrls: ['./life-movement-installment-premium-detail.component.css'],
  animations: [
    trigger('fadeAnimation', [
      state('void', style({ opacity: 0 })),
      transition(':enter', animate(200)),
      transition(':leave', animate(200))
    ]),
  ]
})
export class LifeMovementInstallmentPremiumDetailComponent implements OnInit {

  @Output() modalClose = new EventEmitter();

  @Input() grossDetails: any;

  title = '';
  tableTitle = '';

  constructor(
    private activeModal: NgbActiveModal,
    protected translate: TranslationWrapperService
  ) { }

  ngOnInit() {
    this.title = this.translate.getImmediate('lpc_gross_detail');
    this.tableTitle = this.translate.getImmediate('lpc_type_of_covers');
  }

  closeModal() {
    this.activeModal.close();
  }


/*
  @Output() modalClose = new EventEmitter();
  grossDetails: any;

  constructor(@Optional() @Inject(DIALOG_DATA) data: any) {
    this.grossDetails = data.grossDetails;
  }

  ngOnInit() {
  }
*/
}

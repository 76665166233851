import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RgiRxBadgeComponent } from './rgi-rx-badge/rgi-rx-badge.component';

@NgModule({
  declarations: [RgiRxBadgeComponent],
  imports: [
    CommonModule
  ],
  exports: [RgiRxBadgeComponent]
})
export class RgiRxBadgeModule { }

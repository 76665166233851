
export class PortalAction {
  constructor(
    public extension: any,
    public jumpCommand: any,
    public menu: string,
    public operation: ActionOperation,
    public type: string
  ) {
  }
}

export class ActionOperation {
  constructor(
    public code: string,
    public description: string,
    public identification: string
  ) { }
}

<div [cdkTrapFocus]="picker.pickerMode !== 'inline'"
     [@fadeInPicker]="picker.pickerMode === 'inline'? '' : 'enter'"
     class="rgi-ui-datepicker-container-inner">

  <rgi-rx-calendar
    *ngIf="pickerType === 'both' || pickerType === 'calendar'"
    class="rgi-ui-datepicker-container-row"
    [firstDayOfWeek]="picker.firstDayOfWeek"
    [(pickerMoment)]="pickerMoment"
    [selected]="picker.selected"
    [selecteds]="picker.selecteds"
    [selectMode]="picker.selectMode"
    [minDate]="picker.minDateTime"
    [maxDate]="picker.maxDateTime"
    [dateFilter]="picker.dateTimeFilter"
    [startView]="picker.startView"
    [hideOtherMonths]="picker.hideOtherMonths"
    (yearSelected)="picker.selectYear($event)"
    (monthSelected)="picker.selectMonth($event)"
    (selectedChange)="dateSelected($event)"></rgi-rx-calendar>

  <rgi-rx-date-time-timer
    *ngIf="pickerType === 'both' || pickerType === 'timer'"
    class="rgi-ui-datepicker-container-row"
    [pickerMoment]="pickerMoment"
    [minDateTime]="picker.minDateTime"
    [maxDateTime]="picker.maxDateTime"
    [showSecondsTimer]="picker.showSecondsTimer"
    [hour12Timer]="picker.hour12Timer"
    [stepHour]="picker.stepHour"
    [stepMinute]="picker.stepMinute"
    [stepSecond]="picker.stepSecond"
    (selectedChange)="timeSelected($event)"></rgi-rx-date-time-timer>

  <div *ngIf="picker.isInRangeMode"
       role="radiogroup"
       class="rgi-ui-datepicker-container-info rgi-ui-datepicker-container-row">
    <div role="radio" [tabindex]="activeSelectedIndex === 0 ? 0 : -1"
         [attr.aria-checked]="activeSelectedIndex === 0"
         class="rgi-ui-datepicker-control rgi-ui-datepicker-container-range rgi-ui-datepicker-container-from"
         [ngClass]="{'rgi-rx-dt-container-info-active': activeSelectedIndex === 0}"
         (click)="handleClickOnInfoGroup($event, 0)"
         (keydown)="handleKeydownOnInfoGroup($event, to, 0)" #from>
            <span class="rgi-ui-datepicker-control-content rgi-ui-datepicker-container-range-content" tabindex="-1">
                <span
                  class="rgi-ui-datepicker-container-info-label">{{'RGI_RX.DATEPICKER.RANGE_FROM_LABEL' | translate}}
                  :</span>
                <span class="rgi-ui-datepicker-container-info-value">{{fromFormattedValue}}</span>
            </span>
    </div>
    <div role="radio" [tabindex]="activeSelectedIndex === 1 ? 0 : -1"
         [attr.aria-checked]="activeSelectedIndex === 1"
         class="rgi-ui-datepicker-control rgi-ui-datepicker-container-range rgi-ui-datepicker-container-to"
         [ngClass]="{'rgi-rx-dt-container-info-active': activeSelectedIndex === 1}"
         (click)="handleClickOnInfoGroup($event, 1)"
         (keydown)="handleKeydownOnInfoGroup($event, from, 1)" #to>
            <span class="rgi-ui-datepicker-control-content rgi-ui-datepicker-container-range-content" tabindex="-1">
                <span class="rgi-ui-datepicker-container-info-label">{{'RGI_RX.DATEPICKER.RANGE_TO_LABEL' | translate}}
                  :</span>
                <span class="rgi-ui-datepicker-container-info-value">{{toFormattedValue}}</span>
            </span>
    </div>
  </div>

  <div *ngIf="showControlButtons" class="rgi-ui-datepicker-container-buttons rgi-ui-datepicker-container-row">
    <button
      class="rgi-ui-datepicker-control rgi-ui-datepicker-control-button rgi-ui-datepicker-container-control-button rgi-ui-btn"
      type="button" tabindex="0"
      (click)="onCancelClicked($event)">
            <span class="rgi-ui-datepicker-control-content rgi-ui-datepicker-control-button-content" tabindex="-1">
                {{'RGI_RX.DATEPICKER.CANCEL_BTN_LABEL' | translate}}
            </span>
    </button>
    <button
      class="rgi-ui-datepicker-control rgi-ui-datepicker-control-button rgi-ui-datepicker-container-control-button rgi-ui-btn"
      type="button" tabindex="0"
      (click)="onSetClicked($event)">
            <span class="rgi-ui-datepicker-control-content rgi-ui-datepicker-control-button-content" tabindex="-1">
                  {{'RGI_RX.DATEPICKER.SET_BTN_LABEL' | translate}}
            </span>
    </button>
  </div>
</div>

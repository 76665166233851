<div class="container-inquiry" *ngIf="stateInquiry">
  <div class="container-big-column">
    <ng-container *ngFor="let inquiryComponent of inquiryComponentsMain; index as i">
      <rgi-rx-routing-outlet [id]="getActiveRoute().id+'_inquiry_'+i"
                             route="{{inquiryComponent.route}}" [data]="getActiveRoute().getRouteData()">
      </rgi-rx-routing-outlet>
    </ng-container>
  </div>
  <div class="container-small-column">
    <ng-container *ngFor="let inquiryComponent of inquiryComponentsSide; index as i">
      <rgi-rx-routing-outlet [id]="getActiveRoute().id+'_inquiry_side_'+i"
                             route="{{inquiryComponent.route}}" [data]="getActiveRoute().getRouteData()">
      </rgi-rx-routing-outlet>
    </ng-container>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { LpcDatesStepModule } from '../../modules/lpc-dates-step/lpc-dates-step.module';
import { LpcDocumentComponent } from '../../modules/lpc-document-wrapper/lpc-document/lpc-document.component';
import { OperationComponentsModule } from '../../operation-components/operation-components.module';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { LifePostSalesHostDirective } from './directives/life-postsales-host.directive';
import { LifePostsalesSessionComponent } from './life-postsales-session.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LPC_TRANSLATIONS } from '../../i18n/translations';



@NgModule({
    declarations: [LifePostsalesSessionComponent, LifePostSalesHostDirective],
    imports: [
        CommonModule,
        LpcDatesStepModule,
        OperationComponentsModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(LPC_TRANSLATIONS)
    ],
    exports: [
        LifePostsalesSessionComponent
    ],
    providers: [
        { provide: PV_TOKEN.POSTSALES_SERVICE, useExisting: PostsalesOperationsService },
        { provide: PV_TOKEN.DOCUMENT_COMPONENT, useValue: LpcDocumentComponent }
    ]
})
export class LifePostsalesSessionModule { }

<div class="anag-w-100 anag-section-card" *ngIf="stateMgr.getState$() | async as state">
  <div class="anag-section-card-header">
    <span class="anag-section-card-icon rgi-ui-icon-tariff-price"></span>
    <span data-qa="tax-cl-badge" *ngIf="stateMgr.isTaxClassificationsMandatory()" class="rgi-anag-badge" [ngClass]="{
          'rgi-ui-icon-confirmation rgi-anag-ok-badge': this.stateMgr.isTaxClassificationsValid(),
          'rgi-ui-icon-warning rgi-anag-ko-badge': !this.stateMgr.isTaxClassificationsValid()}"></span>
    <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._TAX_CLASSIFICATION_' | translate}}</span>
    <button *ngIf="!state.editorModeEnable" class="anag-section-edit-button" data-qa="editExternalsIdentifiers" (click)="openModalTaxClassificationHistory()">
      <span class="rgi-ui-icon-history anag-edit-icon"> </span>
    </button>
  </div>
  <div class="anag-section-content">
    <span *ngIf="isShowNoTaxClassification()">{{'_ANAG_._LABEL_._NO_TAX_CLASSIFICATIONS_' | translate}}</span>
    <div class="rgi-ui-grid">
      <div class="rgi-ui-col">
        <div rgi-rx-accordion>
          <rgi-rx-expansion-panel color="default" *ngFor="let taxClass of getLastTaxClassification(taxClassifications); let i = index" [attr.data-qa]="'anag-tax-classification_'+i">
            <rgi-rx-expansion-panel-header>
              {{getValuedTaxCLassLabel(taxClass) | uppercase}}
            </rgi-rx-expansion-panel-header>
            <div class="rgi-ui-grid-2">
              <div class="rgi-ui-col">
                <div>{{'_ANAG_._LABEL_._TYPE_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col">
                <b translate>{{taxClass.type ? taxClass.type.descrizione : '-'}}</b>
              </div>
              <div class="rgi-ui-col">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._COUNTRY_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col">
                <b>{{taxClass.country ? taxClass.country.descrizione : '-'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('tin')">
                <div class="story-grid-column-content">TIN:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('tin')">
                <b>{{taxClass.tin? taxClass.tin : '-'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationRisk')">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._RISK_CLASSIFICATION_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationRisk')">
                <b>{{taxClass.riskClassification ? ' ' + taxClass.riskClassification.descrizione : '-'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationReason')">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._REASON_CLASSIFICATION_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationReason')">
                <b>{{taxClass.reasonClassification ? ' ' + taxClass.reasonClassification.descrizione : '-'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationBasis')">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._BASIS_CLASSIFICATION_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationBasis')">
                <b>{{taxClass.basisClassification ? ' ' + taxClass.basisClassification.descrizione : '-'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationDate')">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._CLASSIFICATION_DATE_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationDate')">
                <b>{{taxClass.classificationDate ? this.formatDateToString(taxClass.classificationDate) : '-'}}</b>
              </div>
              <div class="rgi-ui-col">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._COUNTRY_START_DATE_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col">
                <b>{{taxClass.countryStartDate ? this.formatDateToString(taxClass.countryStartDate) : '-'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('countryEndDate')">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._COUNTRY_END_DATE_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="isFieldVisible('countryEndDate')">
                <b>{{taxClass.countryEndDate ? this.formatDateToString(taxClass.countryEndDate) : '-'}}</b>
              </div>
            </div>
            <div class="rgi-ui-col rgi-ui-grid-2">
              <button type="button" (click)="editTaxClassification(taxClass)" [disabled]="!canEdit"
                      class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-edit2 rgi-anag-text-tone"></button>
              <button type="button" (click)="deleteTaxClassification(taxClass)" [disabled]="!canEdit"
                      class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-delete rgi-anag-text-tone"></button>
            </div>
          </rgi-rx-expansion-panel>
        </div>
      </div>
    </div>
    <div class="rgi-ui-grid-1">
      <div class="rgi-ui-col">
        <div class="rgi-ui-panel-footer">
          <button type="button" class="rgi-ui-btn rgi-ui-btn-outline rgi-anag-text-tone" data-qa="anag-btn-add-tax-classification" (click)="addNewTaxClassification()"
                  [disabled]="!canEdit" translate>_ANAG_._BTN_._ADD_
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {path} from '../conf/conf';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private readonly API_URL: string;
  private readonly QLIK_TOKEN_URL: string;

  constructor(private http: HttpClient, @Inject('environment') environment: any) {
    this.API_URL = environment.api.portal.host + environment.api.portal.path + environment.api.gateway.path;
    this.QLIK_TOKEN_URL = this.API_URL +  path.REPORT_URL + path.REST_QLIK_TOKEN;
  }

  getConfiguration(): Observable<any> {
    return this.http.get<any>(this.QLIK_TOKEN_URL);
  }

}

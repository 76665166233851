<rgi-rx-panel class="md-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
    <span translate>RE_ISSUE.{{type}}</span>
  </rgi-rx-panel-header>
  <div class="modal-body large-modal">
    <form [formGroup]="addPolicyForm">
      <div class="rgi-ui-grid-1">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="company" translate>RE_ISSUE.COMPANY</label>
            <select rgiRxNativeSelect id="company" formControlName="company" data-qa="company" [compareWith]="compareCompanies">
              <option *ngFor="let company of companies" [ngValue]="company">{{company.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="rgi-ui-grid-1">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="policyNumber" translate>RE_ISSUE.POLICY_NUMBER</label>
            <input rgiRxInput id="policyNumber" formControlName="policyNumber" data-qa="policyNumber">
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="rgi-ui-grid-1">
        <rgi-rx-form-field class="date-field rgi-ui-col">
          <div class="label-input-container">
            <label rgiRxLabel for="expireDate" translate>RE_ISSUE.EXPIRE_DATE</label>
            <div id="expireDate" class="input-button-container">
              <input [rgiRxDateTimeInput]="expireDatePicker" style="flex: 1" data-qa="expireDate"
                     formControlName="expireDate" selectMode="single" rangeSeparator="/" placeholder="gg/mm/yyyy"
                     #inputToTriggerExpireDate="rgiRxDateTimeInput">
              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerExpireDate"></button>
            </div>
          </div>
          <rgi-rx-date-time #expireDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-grid-1">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="notes" translate>RE_ISSUE.NOTES</label>
            <textarea rgiRxInput id="notes" formControlName="notes" maxlength="250" data-qa="notes"></textarea>
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="rgi-ui-grid-1" [hidden]="!canShowLimit()">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="limit" translate>RE_ISSUE.LIMIT</label>
            <input rgiRxInput id="limit" formControlName="limit" type="text" reIssueCurrency data-qa="limit">
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="row col-sm-12 col-xs-12">
        <rgi-rx-snackbar tag="{{TAG_POLICY_DUPLICATED_ERROR}}"></rgi-rx-snackbar>
      </div>
    </form>
  </div>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" data-qa='close' translate>
      RE_ISSUE.CLOSE
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" data-qa="confirm" (click)="confirm()" [disabled]="!addPolicyForm.valid" translate>
      RE_ISSUE.CONFIRM
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

import {
  AfterViewInit,
  Component, EventEmitter, forwardRef, Inject,
  Input, OnChanges, OnDestroy,
  Output, SimpleChanges, } from '@angular/core';

import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import {InputFieldDefinition, PostsalesError} from '../../models/postsales-operations-response.model';
import {Subscription, Subject} from 'rxjs';
import {PlcDateUtils} from '../../utils/plc-date-utils';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import {delay, distinctUntilChanged} from 'rxjs/operators';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-dates-step',
  templateUrl: './dates-step.component.html',
  styleUrls: ['./dates-step.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatesStepComponent),
      multi: true
    },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatesStepComponent), multi: true }
  ]
})
export class DatesStepComponent implements OnChanges, OnDestroy, ControlValueAccessor, Validator, AfterViewInit {

  private $inputFields: Subject<{help: string, date: string}[]> = new Subject();

  @Input() inputFields: InputFieldDefinition[] = [];
  @Output() reloadRequest: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() hide = true;

  public helpInfo: {help: string, date: string}[] = [];

  private subscriptions: Subscription[] = [];
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  public value: { [key: string]: Date } = {};

  get visibleInputFields(): InputFieldDefinition[] {
    if (!!this.inputFields) {
      return this.inputFields.filter(inputField => !!inputField.visible);
    }
  }

  get errors(): PostsalesError[] {
    return this.operations.errors/*. concat(this.feErrors) */.filter(error => error.context === 'dates' || error.severity !== 'error');
  }


  constructor(@Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService) {
  }


  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.$inputFields.pipe(
        distinctUntilChanged((prev, curr) => PlcObjectUtils.equal(prev, curr))
      ).subscribe(helps => this.helpInfo = helps)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputFields && changes.inputFields.currentValue) {
      const inputFields: InputFieldDefinition[] = changes.inputFields.currentValue;
      this.$inputFields.next(this.getHelpInfo(inputFields));
      this.formGroup = new UntypedFormGroup({});
      inputFields.forEach(inputField => {
        this.formGroup.addControl(
          inputField.code,
          new UntypedFormControl()
        );
        if (inputField.required) {
            this.formGroup
            .get(inputField.code).setValidators(Validators.required);
        }
        if (!inputField.editable) {
          this.formGroup.get(inputField.code).disable({emitEvent: false});
        }
        this.subscriptions.push(this.formGroup.get(inputField.code).valueChanges
          .subscribe(value => this.dateChange.emit({code: inputField.code, value})));
        if (inputField.reloadOnChange) {
          this.subscriptions.push(
            this.formGroup.get(inputField.code).valueChanges.pipe(
              delay(500)
            ).subscribe(value => {
              if (!!value) {
                this.reloadRequest.emit(value);
              }
            })
          );
        }
      });
      this.subscriptions.push(
        this.formGroup.valueChanges.subscribe(() => {
          const result = this.formGroup.getRawValue();
          this.onChange(result);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(ss => ss.unsubscribe());
  }

  getLocalMaxDate(maxDate: string) {
    return PlcDateUtils.isoToLocaleDate(maxDate);
  }

  getLocalMinDate(minDate) {
    return PlcDateUtils.isoToLocaleDate(minDate);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: { [key: string]: Date }): void {
    this.value = obj;
    if (obj) {
      Object.keys(obj).forEach(key => {
        if (!!this.formGroup.get(key)) {
          this.formGroup.get(key).setValue(obj[key], {emitEvent: false});
        }
      });
    }
  }

  validate(control: AbstractControl): { [key: string]: boolean } | null {
      const errors: { [key: string]: boolean } = {};
      Object.keys(this.formGroup.controls).forEach(key => {
        if (!!this.formGroup.get(key).invalid) {
          Object.assign(errors, { required: true });
        }
        if (!(this.formGroup.get(key).value as Date) && this.formGroup.get(key).value != null) {
          Object.assign(errors, { formatInvalid: true });
        }
      });
      if (!!Object.keys(errors).length) {
        return errors;
      }
      return null;
  }

  onChange = (obj: { [key: string]: Date }) => {
  }

   // Converte Date in YMD
   convertToDMY(d: Date): string {
    if (d !== null && d instanceof Date) {
      let month = EMPTY_STR + (d.getMonth() + 1);
      let day = EMPTY_STR + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      if (year < 1000) {
        return EMPTY_STR;
      }

      return [day, month, year].join('/');
    } else {
      return null;
    }

  }

  getHelpInfo(helps: InputFieldDefinition[]): {help: string, date: string}[] {
    return helps.filter(dates => !!dates.inputNotes).map(date => {
      return {
        help: date.inputNotes,
        date: date.label
      };
    });
  }
}

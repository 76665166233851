import { FieldDependency } from './domain-field-dependency';

export class DomainField {

    public label: string;
    public type: string;
    public id: string;
    public comboValues: string[];
    public value: string;
    public numberOfDecimals: string;
    public visualizationdependencies: FieldDependency[];
    public mandatory: string;

    constructor() { }

}

import {
  ComponentRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {fromEvent, merge, of, Subscription} from 'rxjs';
import {ComponentPortal} from '@angular/cdk/portal';
import {RgiRxTooltipComponent} from './rgi-rx-tooltip/rgi-rx-tooltip.component';
import {
  RGI_RX_TOOLTIP_CONFIG,
  RgiRxToolTip,
  RgiRxToolTipConfig,
  RgiRxTooltipPosition,
  RgiRxTooltipTrigger
} from './rgi-rx-tooltip-api';
import {ConnectedPosition} from '@angular/cdk/overlay';
import {Directionality} from '@angular/cdk/bidi';
import {DOCUMENT} from '@angular/common';
import {delay, filter, switchMap, takeUntil} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ESCAPE} from '@angular/cdk/keycodes';

@Directive({
  selector: '[rgiRxTooltip]',
  exportAs: 'rgiRxTooltip',
  host: {
    '[class.rgi-ui-tooltip-trigger]': '_trigger === "click" && !disableTooltip'
  }
})
export class RgiRxTooltipDirective implements OnInit, OnChanges, OnDestroy {

  /**
   * The input text or a TemplateRef to be rendered
   */
  @Input() rgiRxTooltip: string | TemplateRef<any>;
  /**
   * The position where the tooltip should be placed.
   * If the tooltip collides with the viewport the position might change to allow the tooltip to remain visible on screen.
   */
  @Input() position: RgiRxTooltipPosition;
  _trigger: RgiRxTooltipTrigger = 'mouseenter';
  /**
   * a custom panel class for the tooltip overlay
   */
  @Input() panelClass: string | string[];
  /**
   * Delay the dismiss event after the specified timeout has elapsed
   */
  @Input() dismissDelay: number;
  /**
   * Delay the activation by specified milliseconds of the trigger by debouncing the activator
   */
  @Input() triggerDebounce: number;

  private _disableTooltip = false;

  private triggerSubscription = Subscription.EMPTY;
  private dismissSubscription = Subscription.EMPTY;
  private _overlayRef?: OverlayRef;
  private portal?: ComponentPortal<RgiRxToolTip>;
  private toolTipRef: ComponentRef<RgiRxToolTip>;
  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private vc: ViewContainerRef,
    private directionality: Directionality,
    @Inject(DOCUMENT) private document,
    @Inject(RGI_RX_TOOLTIP_CONFIG) config: RgiRxToolTipConfig
  ) {
    this.dismissDelay = config.dismissDelay !== undefined ?  config.dismissDelay : 0;
    this.triggerDebounce = config.triggerDebounce !== undefined ? config.triggerDebounce : 0;
    this.panelClass = config.panelClass !== undefined ? config.panelClass : '';
    this.trigger = config.trigger !== undefined ? config.trigger : 'mouseenter';
    this.position = config.position !== undefined ? config.position : 'top';
  }

  /**
   * The trigger event that should render the tooltip.
   * When using click, the tooltip remains visible until any outside click or focus shift occurs
   */
  @Input() get trigger(): RgiRxTooltipTrigger {
    return this._trigger;
  }

  set trigger(value: RgiRxTooltipTrigger) {
    this._trigger = value;
  }

  @Input() get disableTooltip(): boolean {
    return this._disableTooltip;
  }

  set disableTooltip(value: boolean) {
    this._disableTooltip = coerceBooleanProperty(value);
  }

  ngOnInit(): void {
    this.subscribeTrigger();
    this.subscribeDismiss();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes && changes.trigger && !changes.trigger.isFirstChange()) {
      this.subscribeTrigger();
    }
    if (changes && changes.triggerDebounce && !changes.triggerDebounce.isFirstChange()) {
      this.subscribeTrigger();
    }
    if (changes && changes.dismissDelay && !changes.dismissDelay.isFirstChange()) {
      this.close();
      this.subscribeDismiss();
    }
    if (changes && changes.position && !changes.position.isFirstChange()) {
      if (this.overlayRef) {
        this.overlayRef.updatePositionStrategy(this.createPositionStrategy());
      }
    }
  }

  private subscribeDismiss() {
    this.dismissSubscription.unsubscribe();
    this.dismissSubscription = fromEvent(this.elementRef.nativeElement, 'mouseleave')
      .pipe(delay(this.dismissDelay))
      .subscribe(
        (next: MouseEvent) => {
          if(this.overlayRef?.overlayElement?.contains(next.relatedTarget as Node)) {
            return;
          }
          this.close();
        }
      );
  }
  private subscribeTrigger() {
    this.triggerSubscription.unsubscribe();
    this.triggerSubscription = fromEvent(this.elementRef.nativeElement, this._trigger)
      .pipe(
        switchMap( (evt) =>
          of(evt).pipe(
            delay(this.triggerDebounce),
            takeUntil(fromEvent(this.elementRef.nativeElement, 'mouseleave'))
          )
        )
      )
      .subscribe(
        next => {
          this.open();
        }
      );
  }

  open() {
    if (this.disableTooltip) {
      return;
    }
    this.createOverlay();
    this.toolTipRef.instance.show();
  }

  private createOverlay() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this.detach();
    }
    this.portal = new ComponentPortal<any>(RgiRxTooltipComponent, this.vc);
    const strategy = this.createPositionStrategy();
    this._overlayRef = this.overlay.create({
      positionStrategy: strategy,
      direction: this.directionality.value,
      panelClass: this.panelClass,
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        autoClose: true,
        scrollThrottle: 20
      })
    });
    this.toolTipRef = this._overlayRef.attach(this.portal);
    this.toolTipRef.instance.content = this.rgiRxTooltip;
    this.toolTipRef.instance.show();
    const mouseLeaveTooltip$ = fromEvent(this.toolTipRef.location.nativeElement, 'mouseleave');
    const exitOnTooltip$ = this.overlayRef.keydownEvents().pipe(
      filter((evt: KeyboardEvent) => evt.keyCode === ESCAPE)
    );
    merge(mouseLeaveTooltip$, exitOnTooltip$).pipe(takeUntil(this.overlayRef.detachments())).subscribe(() => this.close());
    this.toolTipRef.changeDetectorRef.detectChanges();
    this.elementRef.nativeElement.setAttribute('aria-describedby', this.toolTipRef.instance.id);
  }

  private createPositionStrategy() {
    return this.overlay.position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.createPosition())
      .withFlexibleDimensions(false)
      .withViewportMargin(8);
  }

  close() {
    if (this.toolTipRef) {
      this.toolTipRef.instance.hide();
    }
    this.detach();
  }

  private createPosition(): ConnectedPosition[] {
    const positions: ConnectedPosition[] = [];
    const topPosition: ConnectedPosition = {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom'
    };
    const bottomPosition: ConnectedPosition = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    };
    const rightPosition: ConnectedPosition = {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center'
    };

    const leftPosition: ConnectedPosition = {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center'
    };
    switch (this.position) {
      case 'top': {
        positions.unshift(topPosition, bottomPosition);
        break;
      }
      case 'bottom': {
        positions.unshift(bottomPosition, topPosition);
        break;
      }
      case 'right': {
        positions.unshift(rightPosition, leftPosition, topPosition, bottomPosition);
        break;
      }
      case 'left': {
        positions.unshift(leftPosition, rightPosition, topPosition, bottomPosition);
        break;
      }
    }
    return positions;
  }


  private detach() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  private dispose() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  ngOnDestroy(): void {
    this.triggerSubscription.unsubscribe();
    this.dismissSubscription.unsubscribe();
    this.dispose();
  }


  get overlayRef(): OverlayRef {
    return this._overlayRef;
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'rgi-pnc-psales-section-title',
  templateUrl: './section-title.component.html'
})
export class PncPsalesSectionTitleComponent {

  @Input() title: string;
  @Input() icon: string;

  constructor() { }

}

import {Serialized18n} from "@rgi/rx/i18n";

export const EN: Serialized18n = {
  NG_PASSPRO: {
    required: "required",
    yes: "yes",
    no: "no"
  }
}

export const IT: Serialized18n = {
  NG_PASSPRO: {
    required: "obbligatorio",
    yes: "Si",
    no: "No"
  }
}


export const FR: Serialized18n = {
  NG_PASSPRO: {
    required: "obligatoire",
    yes: "Oui",
    no: "Non"
  }
}

export const DE: Serialized18n = {
  NG_PASSPRO: {
    required: "erforderlich",
    yes: "Jawohl",
    no: "Nein"
  }
}
export const ES: Serialized18n = {
  NG_PASSPRO: {
    "required": "requerido",
    yes: "Sí",
    no: "No"
  }
}

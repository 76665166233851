import {Component, Input} from '@angular/core';
import {GPClause} from "../../../group-policy-models/group-policy-issue-policy-data";
import {GpClausesService} from "../../../group-policy-services/gp-clauses.service";
import {take} from "rxjs/operators";
import {GroupPolicyCrossService} from "../../../group-policy-services/group-policy-cross.service";

@Component({
  selector: 'rgi-gp-clauses-detail',
  templateUrl: './gp-clauses-detail.component.html'
})
export class GpClausesDetailComponent {
  @Input() clauses: GPClause[];
  @Input() showTitle = true;
  @Input() showVertical: boolean = false;

  constructor(
    protected crossService: GroupPolicyCrossService,
    protected clausesService: GpClausesService
  ) { }
  showText(clause: GPClause): boolean {
    return this.clausesService.showText(clause);
  }

  showPreviewText(clause: GPClause): boolean {
    return this.clausesService.showText(clause);
  }

  public openModalText(clause: GPClause) {
    this.crossService.showClauseModal(clause)
      .pipe(take(1))
      .subscribe();
  }
}

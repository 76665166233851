import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'claims-new-payment-progress',
  templateUrl: './new-payment-progress.component.html',
  styleUrls: ['./new-payment-progress.component.scss']
})
export class NewPaymentProgressComponent implements OnInit {
  @Input() currentStep: number;

  constructor() { }

  ngOnInit() {
  }

}

<div class="container-fluid policy-table" data-qa="autosoft-list" style="height: 250px;">
    <form [formGroup]="searchForm">
        <div class="row">
            <div class="form-group">
                <div [ngClass]="classImg" style="text-align: center;padding-top: 10%">
                    &nbsp; &nbsp;<img style="cursor: pointer;" src="{{img}}" (click)="action()" />

                </div>
                <div class="col-xs-10" *ngIf="actionOn">
                    <div class="row" *ngIf="!answwerOn">{{zzz()}}
                        <input type="text" class="form-control" name="criteria" formControlName="criteria"
                            placeholder="What do you need to know?Ask me..." id="criteria" (change)="search()"
                            (blur)="search()" (keyup)="search()" attr.data-qa="assistant-criteria-input">
                    </div>
                    <div *ngIf="faqList && !answwerOn" style="height: 200px;overflow: auto;">
                        <ul>
                            <div class="row" *ngFor="let faq of faqList; let num = index;">

                                <div class="col-xs-12">
                                    <li (click)="select(faq.idFaq)" style="cursor: pointer;">- {{faq.title}}</li>
                                </div>

                            </div>
                        </ul>
                    </div>

                    <div class="row" *ngIf="faqSel && answwerOn">
                        <div class="col-xs-12" style="font-size: 18px;
                                                  font-style: italic;
                                                  font-weight: bold;">
                            {{faqSel.title}}
                        </div>

                        <div class="col-xs-12">
                            <textarea class="col-sm-12 form-control" disabled
                                style="width: 500px; height: 112px; font-size: smaller;">{{faqSel.detail}}</textarea>
                        </div>
                        <div class="col-xs-12" style="font-style: italic; padding-top: 10px;">
                            {{ '_CLAIMS_._DOCUMENTS' | translate }}
                        </div>
                        <div class="col-xs-12" *ngIf="!faqSel.links || faqSel.links.length === 0">
                            {{ '_CLAIMS_._NO_SUGGESTIONS' | translate }}
                        </div>
                        <div class="col-xs-12" *ngFor="let link of faqSel.links">
                            <a href="#" (click)="openWin(link)">{{link}}</a>
                        </div>
                        <div class="row">
                            <div class="col-xs-10" style="text-align: right;padding-top: 2%;">
                                <input type="text" class="form-control" name="tags" formControlName="tags"
                                    placeholder="Suggest tags to this FAQ using # as 1st char" id="tags"
                                    attr.data-qa="assistant-tags-input">
                            </div>
                            <div class="col-xs-2" style="text-align: right;padding-top: 1%;">
                                <img style="cursor: pointer; height: 40px;" src="assets/img/Assistant/ok.png"
                                    (click)="usefull()" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
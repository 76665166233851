<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">

        <!-- Intestazione -->
        <div class="modal-header">
            <h3 class="modal-title">{{ modalTitle | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal('Close')"></span>
            </h3>
        </div>

        <!-- Corpo del form -->
        <div class="modal-body" style="padding-top: 5px; height: 520px;">
            <div class="card shadow" *ngIf="detailCancellationForm">
                <form [formGroup]="detailCancellationForm">
                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <!-- Riga contenente la data -->
                            <rgi-rx-form-field>
                                <label rgiRxLabel>{{ '_CLAIMS_._CANCELLATION_DATE' | translate }}</label>
                                <rgi-rx-date-picker [openOnClick]="false">
                                    <input rgiRxInput [rgiRxDateTimeInput]="consDate" formControlName="dateCancellation"
                                        data-qa="rgi-cancellation-date" placeholder="{{datepickerPlaceholder}}"
                                        [max]="today" [min]="currentReceiptDate"
                                        (ngModelChange)="enableConfirmButton()">
                                </rgi-rx-date-picker>
                                <rgi-rx-date-time #consDate></rgi-rx-date-time>
                            </rgi-rx-form-field>
                        </div>
                        <div class="col-xs-6" *ngIf="reasonCancelledList.length > 0">
                            <rgi-rx-form-field>
                                <label rgiRxLabel>{{ '_CLAIMS_._REASON_CANCELLATION' | translate }} </label>
                                <select rgiRxNativeSelect formControlName="reasonCancelled"
                                    (ngModelChange)="enableConfirmButton()">
                                    <option *ngFor="let reason of reasonCancelledList;" value={{reason.code}}>
                                        {{reason.description}}</option>
                                </select>
                            </rgi-rx-form-field>
                        </div>
                    </div>
                    <!-- Riga contenente il campo note -->
                    <div class="row">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                            <textarea rgiRxInput formControlName="note"
                                (ngModelChange)="enableConfirmButton()"></textarea>
                        </rgi-rx-form-field>
                    </div>
                </form>
            </div>
            <div class="btn-group btn-group-justified btn-group-justified-claim">
                <div class="btn-group ">
                    <button rgi-rx-button color="primary" class="w-100-custom" (click)="closeModal('Close')">{{
                        '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
                </div>
                <div class="btn-group">
                    <!-- [disabled]="detailReceiptReturnForm.invalid" -->
                    <button rgi-rx-button type="button" color="primary" class="w-100-custom"
                        (click)="confirmCancellationReturn()" [rgiRxTooltip]="dateErrorTooltipTemplate"
                        [position]="'top'" [trigger]="'mouseenter'" [disableTooltip]="descErrOrTooltip === ''">{{
                        '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                </div>
            </div>
        </div>
        <ng-template #dateErrorTooltipTemplate>
            {{ descErrOrTooltip | translate }}
        </ng-template>
    </div>
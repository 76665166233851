



<div *ngIf="hasResult()" class="table-white">
    <div class="head-result thDataTable row">
        <!-- <div class="col-sm-1" >
            <span class="bold"></span>
        </div> -->
        
        <div align="left" class="col-sm-2">
            <span class="bold expert-label">{{ '_CLAIMS_._EXPERT' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1">
            <span class="bold">{{ '_CLAIMS_._INVOICE_DATE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2">
            <span class="bold">{{ '_CLAIMS_._INVOICE_NUMBER' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1">
            <span class="bold">{{ '_CLAIMS_._AMOUNT' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2">
            <span class="bold">{{ '_CLAIMS_._SAP_CODE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1">
            <span class="bold">{{ '_CLAIMS_._RECEIVED_DATE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2">
            <span class="bold">{{ '_CLAIMS_._REJECT_TYPE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1">
            <span class="bold"></span>
        </div>
    
    </div>
</div>


<!-- <span *ngIf="hasResult()" *ngFor="let invoice of invoices; let even = even; let odd = odd; let invoiceIndex = index"> -->
<span *ngFor="let invoice of invoicesFiltered; let even = even; let odd = odd; let invoiceIndex = index">
    <div class="container-fluid table-white">
		<!-- <div class="row" [ngClass]="{'row-result-odd':$index %2 == 0}"> -->
        <div class="row">
            <!-- <div class="col-sm-1" (click)="invoice.expanded = !invoice.expanded">
				<span class="rgifont iconDTleft rgi-plus" [ngClass]="{'rgi-plus': !invoice.expanded, 'rgi-minus':invoice.expanded}"></span>
			</div> -->
			<!-- <div class="col-sm-2"> -->
            <div class="col-sm-2" (click)="invoice.expanded = !invoice.expanded">
				<span class="rgifont iconDTleft rgi-plus" [ngClass]="{'rgi-plus': !invoice.expanded, 'rgi-minus':invoice.expanded}"></span>
                <span class="bold">{{invoice.invoiceExpert.name}} {{invoice.invoiceExpert.surname}} {{invoice.invoiceExpert.title}}</span>
            </div>
			<div class="col-sm-1">
                <span>{{invoice.invoiceDate | date:'dd/MM/yyyy' }}</span>
            </div>
			<div class="col-sm-2">
				<span>{{invoice.invoiceNumber}}</span>
			</div>
			<div class="col-sm-1">
				<span>{{invoice.invoiceAmount | currency:'EUR'}}</span>
			</div>
			<div class="col-sm-2">
				<span>{{invoice.sapCode}}</span>
			</div>
			<div class="col-sm-1">
                <span>{{invoice.insertDate | date:'dd/MM/yyyy' }}</span>
            </div>
			<div class="col-sm-2">
				<span class="bold" *ngIf="invoice.rejectType">{{invoice.rejectType.descrizione}}</span>
			</div>
			<div class="col-sm-1">
                <div *ngIf="invoice.rejectType && isManagedRejectType(invoice.rejectType.codice)">
                    <span class="bold">
                        <button type="button" class="btn dt-btn pull-right claims-margin-right-top padding-right-5" (click)="rejectInvoice(invoice.rejectType.codice, invoice)"
                            title="{{ '_CLAIMS_._EDIT' | translate }}" tooltip-append-to-body="true">
                            <span class="rgifont rgi-pencil iconDT modal-icon"></span>
                        </button>
                    </span>
                    <div *ngIf="invoice.status.codice==5" class="green-check">
                        <span class="rgifont rgi-survey_ok green-color ico-dimension-small" title="{{ '_CLAIMS_._REJECT_WORKED' | translate }}"></span>
                    </div>
                </div>
                <div *ngIf="invoice.rejectType.codice == 16">
                    <span class="bold">
                        <button type="button" class="btn dt-btn pull-right claims-margin-right-top padding-right-5" (click)="uploadDetails(invoice)"
                            title="{{ '_CLAIMS_._UPLOAD_DETAILS' | translate }}" tooltip-append-to-body="true">
                            <span class="rgifont rgi-upload iconDT modal-icon"></span>
                        </button>
                    </span>
                </div>
            </div>


            <!-- <div class="col-sm-1" *ngIf="invoice.status.codice==5">
                <span class="rgifont rgi-survey_ok green-color ico-dimension-small"></span>
            </div> -->

			<div class="col-xs-12" #collapse="ngbCollapse" [(ngbCollapse)]="!invoice.expanded">
                <div class="container-fluid table-white">
                    
                    <!-- <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 text-padd-right">
{{ '_CLAIMS_._REJECT_LIST' | translate }}:
                        </div>
                    </div> -->

                    <!-- details start -->
                    <div *ngFor="let invoiceDetails of invoice.invoiceDetailsList">
                        <div class="row">

                            <div class="col-sm-8">
                            </div>
                            
                            <!--Escludo dalla vista CONSULENZE => fatture DAS -->
                            <div *ngIf="invoiceDetails.claimNumber!=='CONSULENZE'"class="col-sm-1 padding-left-0">
                                <span>{{ invoiceDetails.claimNumber }}</span>
                            </div>

                            <div class="col-sm-2 padding-left-2">
                                <!--{{ '_CLAIMS_._REJECT_TYPE' | translate }}: -->
                                <span *ngIf="invoiceDetails.rejectType"><b>{{ invoiceDetails.rejectType.descrizione }}</b></span>
                            </div>

                            <div class="col-sm-1 padding-right-0" *ngIf="invoiceDetails.rejectType && isManagedRejectType(invoiceDetails.rejectType.codice)">
                                <button type="button" class="btn dt-btn pull-right claims-margin-right-top"
                                    (click)="rejectInvoice(invoiceDetails.rejectType.codice, invoice)" title="{{ '_CLAIMS_._EDIT' | translate }}"
                                    tooltip-append-to-body="true">
                                    <span class="rgifont rgi-pencil iconDT modal-icon"></span>
                                </button>

                                <div *ngIf="invoiceDetails.status.codice==5" class="green-check">
                                    <span class="rgifont rgi-survey_ok green-color ico-dimension-small" title="{{ '_CLAIMS_._REJECT_WORKED' | translate }}"></span>
                                </div>
                            </div>

                            <!-- <div class="col-sm-1" *ngIf="invoiceDetails.status.codice==5">
                                <span class="rgifont rgi-survey_ok green-color ico-dimension-small"></span>
                            </div> -->
                        </div>

                        <!-- <div class="row border-bottom">

                            <div class="col-sm-1 text-padd-right">
                            </div>

                            <div align="right" class="col-sm-3 text-padd-right">
{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}:
                                <span>{{ invoiceDetails.claimNumber }}</span>
                            </div>

                        </div> -->

                    </div>
                    <!-- details end -->

                    <!-- expert start -->
                    <!-- <div class="row"> -->


                        <div class="row border-bottom">

                            <div class="col-sm-9">
                            </div>

                            <div class="col-sm-2 padding-left-2">
                                <!--{{ '_CLAIMS_._REJECT_TYPE' | translate }}: -->
                                <span *ngIf="invoice.invoiceExpert.rejectType"><b>{{ invoice.invoiceExpert.rejectType.descrizione }}</b></span>
                            </div>

                            <div class="col-sm-1 padding-right-0" *ngIf="invoice.invoiceExpert.rejectType && isManagedRejectType(invoice.invoiceExpert.rejectType.codice)">
                                <button type="button" class="btn dt-btn pull-right claims-margin-right-top"
                                    (click)="rejectInvoice(invoice.invoiceExpert.rejectType.codice, invoice)" title="{{ '_CLAIMS_._EDIT' | translate }}"
                                    tooltip-append-to-body="true">
                                    <span class="rgifont rgi-pencil iconDT modal-icon"></span>
                                </button>

                                <div *ngIf="invoice.invoiceExpert.status.codice==5" class="green-check">
                                    <span class="rgifont rgi-survey_ok green-color ico-dimension-small" title="{{ '_CLAIMS_._REJECT_WORKED' | translate }}"></span>
                                </div>
                            </div>

                            <!-- <div class="col-sm-1" *ngIf="invoice.invoiceExpert.status.codice==5">
                                <span class="rgifont rgi-survey_ok green-color ico-dimension-small"></span>
                            </div> -->

                        </div>

                        <!-- <div class="row">

                            <div class="col-sm-1 text-padd-right">
                            </div>

                            <div class="col-sm-2 text-padd-right">
{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}:
                                <span>{{ invoiceExpert.claimNumber }}</span>
                            </div>

                        </div> -->


<!-- 




                        <div class="col-md-5 col-sm-5 col-xs-12 text-padd-right">
{{ '_CLAIMS_._EXPERT_FIELD_1' | translate }}:
                            <span>{{ invoice.idInvoice }}</span>
                        </div>
                        <div class="col-md-5 col-sm-5 col-xs-12 text-padd-right">
{{ '_CLAIMS_._EXPERT_FIELD_2' | translate }}:
                            <span>{{ invoice.idInvoice }}</span>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12">
                            <button type="button" class="btn dt-btn pull-right claims-margin-right-top"
                                (click)="rejectExpert(invoice.invoiceExpert)" title="{{ '_CLAIMS_._EDIT' | translate }}"
                                tooltip-append-to-body="true">
                                <span class="rgifont rgi-pencil iconDT modal-icon"></span>
                            </button>
                        </div> -->



                    <!-- </div> -->
                    <!-- expert end -->

                </div>
            </div>
		</div>
		<!-- <div class="row"></div> -->
		<!-- </div> -->
    </div>
</span>



<!-- <ul>
    <li>Pag. <a ng-repeat="x in [].constructor(pages) track by $index" ng-click="goToPage($index + 1)"><span
                ng-class="{ 'pager-current-page': serviceInfo.pagerRequest.pageNumber == $index+1 }">{{$index +
                1}}</span>&nbsp;</a>&nbsp; </li>&nbsp;
</ul> -->

<!-- <br> -->

<!-- <ul>
    <li>Pag. 
        <a *ngFor="let pageElement of arrPage; let even = even; let odd = odd; let invoicePagerIndex = index"
        (click)="goToPage(invoicePagerIndex+1)" class="pointer">
            <span [ngClass]="{ 'pager-current-page': currentPage == invoicePagerIndex+1 }">
                {{invoicePagerIndex + 1}}
            </span>&nbsp;
        </a>&nbsp; 
    </li>&nbsp;
</ul> -->

<div class="claims-text">
    <label attr.data.qa="">Pag.</label>
    <a *ngFor="let pageElement of arrPage; let even = even; let odd = odd; let invoicePagerIndex = index"
    (click)="goToPage(invoicePagerIndex+1)" class="pointer">
        <span [ngClass]="{ 'pager-current-page': currentPage == invoicePagerIndex+1 }">
            {{invoicePagerIndex + 1}}
        </span>&nbsp;
    </a>&nbsp; 
</div>

<span *ngIf="errorMessage" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-search-error">
    <span class="rgifont rgi-exclamation-triangle"></span>
    <span>{{errorMessage | translate}}</span>
</span>

<div class="btn-group btn-group-justified btn-group-justified-claim">

    <div class="btn-group">
        <button (click)="back()" class="btn btn-warning pull-right text-uppercase">{{'_CLAIMS_._BUTTONS_._BACK' | translate}}</button>
    </div>

</div>

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Guardian } from '../../dsc-shared-model/dsc-model-subject';

@Component({
  selector: 'claims-subject-add-minor-guardian',
  templateUrl: './subject-add-minor-guardian.component.html',
  styleUrls: ['./subject-add-minor-guardian.component.scss']
})
export class SubjectAddMinorGuardianComponent {

  constructor(@Inject('enumService') private enumService) { }

  @Input() selectedNode: string;
  @Input() activeRouteId: string;
  guardianRoles = this.enumService.getEnumList('claims.GuardianRole');
  showForm: boolean = false;
  minorGuardian: Guardian = {
    minorGuardianSubject: null,
    enumGuardianRole: null
  };
  enumGuardianRole = new FormControl(null);
  @Output() outputDataForm = new EventEmitter<Guardian[]>();

  @Input() listMinorGuardian: Guardian[] = [];
  btnAddGurdianIsDisabled: boolean = false;

  removeGuardian(minorGuardian) {
    const index = this.listMinorGuardian.indexOf(minorGuardian);
    this.listMinorGuardian.splice(index, 1);
    this.outputFormChange();
  }

  addGuardian() {
    this.minorGuardian.enumGuardianRole = this.enumGuardianRole.value;
    if (this.minorGuardian.minorGuardianSubject !== null &&
      this.minorGuardian.enumGuardianRole !== null) {
      this.minorGuardian.minorGuardianSubject.tipoSoggetto = this.minorGuardian.minorGuardianSubject.anagSubject.subjectType;
      this.listMinorGuardian.push(this.minorGuardian);
      this.outputFormChange();
      this.toggleContenuto();
    }
  }

  selectNewSubject(event) {
    this.minorGuardian.minorGuardianSubject = event.subject;
  }

  toggleContenuto() {
    this.showForm = !this.showForm;
    if (this.showForm) {
      this.minorGuardian = {
        minorGuardianSubject: null,
        enumGuardianRole: null
      };
      this.enumGuardianRole.setValue(this.minorGuardian.enumGuardianRole);
    }
  }

  outputFormChange() {
    this.outputDataForm.emit(this.listMinorGuardian);
  }
}

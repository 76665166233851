import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, RgiRxDataTableExpansionModel} from '@rgi/rx/ui';
import {
  AnagTaxClassification,
  AnagtaxClassificationData
} from '../../../anag-model/anag-domain/anag-tax-classification';
import {TAX_CLASSIFICATIONS_HISTORY_LIST_MODAL_TABLE_SCHEMA} from '../../../anag-constants/tax-classifications-history-list-modal-tableschema';
import {AnagStatelessService} from "../../../anag-resources/anag-stateless.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'rgi-anag-tax-classification-history-modal',
  templateUrl: './tax-classification-history-modal.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class TaxClassificationHistoryModalComponent implements OnInit {

  taxClassificationsHistory: AnagtaxClassificationData[];
  TAX_CLASSIFICATION_HISTORY_LIST_MODAL_TABLE_SCHEMA = TAX_CLASSIFICATIONS_HISTORY_LIST_MODAL_TABLE_SCHEMA;
  taxClassificationHistoryTableData = [];
  modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(public statelessService: AnagStatelessService,
              public datePipe: DatePipe,
              @Optional() @Inject(DIALOG_DATA) data: AnagtaxClassificationData[]) {
    this.taxClassificationsHistory = data;
  }

  ngOnInit() {
    this.translation();
    this.createData(this.taxClassificationsHistory);
  }

  translation() {
  }

  actionClose() {
    this.modalClose.emit();
  }

  createData(taxClassificationsHistory: AnagtaxClassificationData[]) {
    return this.taxClassificationHistoryTableData = taxClassificationsHistory.map(taxClassification => {
      return {
        typeDescr: taxClassification.type ? taxClassification.type.descrizione : taxClassification.type,
        countryDescr: taxClassification.country ? taxClassification.country.descrizione : taxClassification.country,
        tin: taxClassification.tin,
        riskClassificationDescr: taxClassification.riskClassification ?
          taxClassification.riskClassification.descrizione : taxClassification.riskClassification,
        reasonClassificationDescr: taxClassification.reasonClassification ?
          taxClassification.reasonClassification.descrizione : taxClassification.reasonClassification,
        basisClassificationDescr: taxClassification.basisClassification ?
          taxClassification.basisClassification.descrizione : taxClassification.basisClassification,
        classificationDate: taxClassification.classificationDate ? this.datePipe.transform(new Date(taxClassification.classificationDate), this.localFormat()) : '-',
        countryStartDate: taxClassification.countryStartDate,
        countryEndDate: taxClassification.countryEndDate,
      };
    });
  }

  localFormat() {
    return this.statelessService.getLocaleDateFormat();
  }
}

<template #productAssetModalOverlay></template>
<template #nodeSearchModalOverlay></template>
<div class="mic-start-form" id="start-form-wrapper">
  <form (ngSubmit)="onSubmit($event)" [formGroup]="startForm">

    <div *ngIf="availableCompanies && availableCompanies?.length > 1" class="form-group">
      <label translate>Company</label>
      <pnc-required required="true"></pnc-required>
      <select class="select-nativa mic-select-company" data-qa="select-company" formControlName="company" id="company"
              name="company">
        <option></option>
        <option *ngFor="let company of availableCompanies" [value]="company?.objectId">{{company?.description}}</option>
      </select>
      <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
    </div>
    <div *ngIf="isAgency && (availableAgencies && availableAgencies?.length > 1 && availableAgencies?.length < maxNode)"
         class="form-group">
      <label translate>Select Node</label>
      <pnc-required required="true"></pnc-required>
      <select (change)="selectChangeHandlerNode($event)" class="select-nativa mic-select-node" data-qa="select-node" formControlName="node"
              id="node" name="node">
        <option></option>
        <option *ngFor="let node of availableAgencies" [ngValue]="node?.idSp">{{node?.description}}</option>
      </select>
      <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
    </div>

    <div *ngIf="!isAgency || (availableAgencies && availableAgencies?.length >= maxNode)"
         class="form-group btn-group btn-group-justified" style="margin-top: 0px;">
      <label for="node" translate>Node</label>
      <pnc-required required="true"></pnc-required>
      <div class="nmf_cont_roles">
        <input (click)="openNodeSearchModal()"  class="btn btn-start-node form-control mic-input" formControlName="node">
        <div class="drop-item-delete">
          <button (click)="cleanNode()" *ngIf="node" class="drag-del-item" type="button">
            <span class="glyphicon glyphicon-trash"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="plateNumber" translate>Plate number</label>
      <pnc-required required="true"></pnc-required>
      <input #plateNumber (change)="onPlateChange(plateNumber?.value)" [value]="plateNumber?.value | uppercase" class="form-control mic-input mic-plateNumber"
             data-qa="plateNumber" formControlName="plateNumber"
             type="text">
    </div>

    <div *ngIf="showDateOfBirth" class="form-group">
      <pnc-datepicker [customFormGroup]="startForm" [maxDate]="maxDate" [minDate]="minDate"
                      customFormControlName="dateOfBirth" data-qa="dateOfBirth" id="dateOfBirth"
                      label="{{'Date of birth' | translate}}"></pnc-datepicker>
    </div>

    <a (click)="onDoNotKnowPlateNumber()" class="nmf_link_card" translate>Is it a new vehicle and you still don't have the license plate?</a>
    <br clear="all">
    <a *ngIf="showAlternativePlateLink()" (click)="onOtherPlateTypes()" class="nmf_link_card nmf_link_card_last" translate>Alternative plates</a>

    <div *ngIf="(startForm?.invalid && validationMessages?.length > 0 && (submitted || plateWrongFormat))" id="error-container"
         style="margin-top: 2em">
      <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                        objectType="complex" type="error"></pnc-form-message>
    </div>
    <!-- Buttons -->
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button (click)="onClear()" class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="empty-btn"
                type="button">
          <span translate>empty</span>
        </button>
      </div>
      <div class="btn-group">
        <button [disabled]="(submitted || plateWrongFormat) && !startForm?.valid" class="btn btn-warning pull-right text-uppercase"
                data-qa="find-actions-btn" type="submit">
          <span translate>find</span>
        </button>
      </div>
    </div>
  </form>
</div>

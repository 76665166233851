import { Component, OnInit, Input, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { RoutingService, RoutingHostDirective, RoutableComponent } from '@rgi/portal-ng-core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { AutosoftCallsComponent } from './autosoft-calls/autosoft-calls.component';
import { AutosoftSearchComponent } from './autosoft-search/autosoft-search.component';
import { UtilityService } from '../external-portfolio/utility.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-autosoft-board',
  templateUrl: './autosoft-board.component.html',
  styleUrls: ['./autosoft-board.component.scss'],
  providers: [RoutingService]
})
export class AutosoftBoardComponent extends CardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;
  routes = null;

  constructor(
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private utilytyServ: UtilityService,
    @Inject('authService') private authService,
    @Inject('autosoftCallsComponent') autosoftCallsComponent,
    @Inject('autosoftSearchComponent') autosoftSearchComponent,
    public translateService: RgiRxTranslationService
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      board: autosoftSearchComponent,
      calls: autosoftCallsComponent
    };
  }
  ngAfterViewInit(): void {
    console.log('ACCESSO CRUSCOTTO AUTOSOFT');

    const listFunz = this.authService.getOperator().enabledFeatures;

    if (listFunz && listFunz.indexOf('SXAUDB') >= 0) {
      this.routingService.routes = this.routes;
      this.routingService.host = this.hostViewRef.viewContainerRef;
      if (this.utilytyServ.getAutosoftResult()) {
        this.routingService.loadComponent(this.routes.calls);
        (this.routingService.componentRef.instance as AutosoftCallsComponent).card = this.card;

      } else {
        this.routingService.loadComponent(this.routes.board);
        (this.routingService.componentRef.instance as AutosoftSearchComponent).card = this.card;
     }
    } else {
      this.translateService.translate('_CLAIMS_._INSUFFICIENT_PERMISSIONS').subscribe(res => {
        this.card.title = res;
      }
      )
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
  }
}

import { Component, EventEmitter, Inject, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { Subscription } from 'rxjs/internal/Subscription';
import { QuoteStatusCode, QuoteStatusLabel } from '../../../enum/life-detail-enum';
import { EMPTY_STR, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { CustomModalService } from '../../../services/custom-modal.service';
import { ProposalDetailService } from '../../../services/proposal-detail.service';
import {
  AvailablesInquiryActions,
  GeneralData,
  LifeContractMessage,
  PaymentAdditionalData
} from './../../../models/life-detail.model';

@Component({
  selector: 'lpc-summary-detail-card',
  templateUrl: './summary-detail-card.component.html',
  styleUrls: ['./summary-detail-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryDetailCardComponent implements  OnChanges {

  public generalDataMap: Map<string, string> = new Map<string, string>();
  protected subscriptions: Subscription[] = [];

  @Input() header: string;
  @Input() generalData: GeneralData;
  @Input() additionalData: PaymentAdditionalData;
  @Input() buttons = false;
  @Input() isPolicy: boolean;
  @Input() lastMovementId: boolean;
  @Input() messages: LifeContractMessage[];
  @Input() movDetail: string;
  @Input() avalActionOperations: AvailablesInquiryActions;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() actionPropagation = new EventEmitter<any>();

  // showModalCancelPolicy: boolean;
  public serviceError: boolean;
  public modalMessage: string;
  public nextLabel: string;
  public backActionCode = 'BACK';

  get isFinancial(): boolean {
    return this.generalData.prodType === 'Unit Linked';
  }

  get setMaxFontSize() {
    return !!this.generalData.polNum ? this.generalData.polNum.length <= 16 : this.generalData.propNum.length <= 16;
  }

  public get label() {
    if (this.generalData.statusCode.toString() === QuoteStatusCode.VALID) {
      return this.translateService.getImmediate(QuoteStatusLabel.NUMBER);
    } else if (this.generalData.statusCode.toString() === QuoteStatusCode.CANCELLED) {
      return this.translateService.getImmediate(QuoteStatusLabel.NUMBER);
    } else if (this.generalData.polNum) {
      return this.translateService.getImmediate('lpc_policy_number');
    } else if (this.generalData.propNum) {
      return this.translateService.getImmediate('lpc_proposal_number');
    }
  }

  constructor(
    protected proposalDetailService: ProposalDetailService,
    protected notifierService: NotifierService,
    protected customModalService: CustomModalService,
    protected translateService: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnChanges(): void {
    this.dataConversionForGeneralData(this.generalData);
  }

  public get hasMessages(): boolean {
    return !!this.messages && this.messages.length > 0;
  }

  public getMessages(level: string): string[] {
    if (!!this.messages) {
      return this.messages.filter(m => m.livello == level).map(m => m.msg);
    }
    return [];
  }

  public dataConversionForGeneralData(generalData: GeneralData): Map<string, string> {
    this.generalDataMap.set('lpc_state', generalData.status);
    this.generalDataMap.set('lpc_substate', generalData.subStatus);
    this.generalDataMap.set('lpc_product_type', generalData.prodType);
    this.generalDataMap.set('lpc_managementnode', generalData.managmentNode);
    if (!!generalData.subAgency) {
      this.generalDataMap.set('lpc_subagency', generalData.subAgency);
    }

    // RDDL-3750
    if (!!generalData.correspondenceAddress) {
      this.generalDataMap.set('lpc_policy_address', generalData.correspondenceAddress);
    }

    if (generalData.filiale.visible) {
      this.generalDataMap.set('lpc_branch_name', generalData.filiale.value == null ? EMPTY_STR : generalData.filiale.value);
    }

    this.generalDataMap.set('lpc_agreement', generalData.agreement);
    this.generalDataMap.set('lpc_payment_frequency', generalData.paymentFreq);

    // RDDL-3947
    let meanPayment = generalData.paymentMethod;

    if (!!generalData.additionalData && !!generalData.additionalData.length) {
      generalData.additionalData.forEach(a => {
        if (a.value && a.value.length > 0) {
          meanPayment += ' - ' + a.fieldLabel + ' ' + a.value;
        }
      });
    }

    this.generalDataMap.set('lpc_mean_of_payment', !!meanPayment ? meanPayment.toUpperCase() : '-');

    // if (meanPayment.length > 0) {
    //   this.generalDataMap.set('lpc_mean_of_payment', meanPayment.toUpperCase());
    // } else {
    //   this.generalDataMap.set('lpc_mean_of_payment', generalData.paymentMethod);
    // }

    // RDDL-2835
    if (generalData.statusCode.toString() === QuoteStatusCode.VALID) {
      this.generalDataMap.set('lpc_quote_due_date', generalData.estimationExpDate);
    }

    return this.generalDataMap;
  }

  public removeSession() {
    this.actionPropagation.emit(this.backActionCode);
  }

  public asIsOrder(a, b) {
    return 1;
  }

  public propagateAction(actionCode: string) {
    this.actionPropagation.emit(actionCode);
  }


}

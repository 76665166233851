import { PolicyService } from './../../../services/policy-service';
import { SustainabilityData } from './../../model/PassResponse';
import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lic-finantial-param-calculator',
  templateUrl: './lic-finantial-param-calculator.component.html',
  styleUrls: ['./lic-finantial-param-calculator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicFinantialParamCalculatorComponent {
  public locale = 'it-IT';
  public optionPercents: Intl.NumberFormatOptions = {style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 };

  private _hasSustainability$ = new BehaviorSubject<boolean>(false);
  private _hasOneSustainability$ = new BehaviorSubject<boolean>(true);

  @Input() disabled = false;
  @Input() volatility: number;
  @Input() set _sustainability(value: SustainabilityData) {
    this._hasSustainability$.next(!!value && Object.keys(value).length > 0);
    this._hasOneSustainability$.next(!!value && Object.keys(value).length <= 2);
    this.sustainability = value;
  }
  @Input() label = '';
  @Output() calculate: EventEmitter<boolean> = new EventEmitter<boolean>();

  sustainability: {[key: string]: any};

  get hasSustainability(): boolean {
    return this._hasSustainability$.value;
  }

  get hasOneSustainability(): boolean {
    return this._hasOneSustainability$.value;
  }

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
  }

  onCalculate() {
    this.calculate.emit(true);
  }

}

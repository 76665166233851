import { Component, Input, OnInit } from '@angular/core';
import { PlcObjectUtils } from '../../utils/plc-object-utils';

@Component({
  selector: 'lpc-warning-messages',
  templateUrl: './lpc-warning-messages.component.html',
  styleUrls: ['./lpc-warning-messages.component.scss']
})
export class LpcWarningMessagesComponent implements OnInit {

  @Input() errors;

  constructor() { }

  ngOnInit() {
  }

  get filteredErrors() {
    return PlcObjectUtils.removeDuplicatesFromPostSalesErrors(this.errors);
  }
}

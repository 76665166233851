import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {KarmaProfile} from '../model/karma-profile';
import { VolatilityResponse, VolatilityType } from '../model/karma-volatility';
import { PV_TOKEN } from '../../../models/consts/lpc-consts';

@Injectable()
export class KarmaFundService {

  private readonly baseApiUrl: string;

  constructor(
    @Inject(PV_TOKEN.ENV) environment: any,
    protected http: HttpClient
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }


  // amountType and investment/disinvestment modality are kelia stuff
  // to be removed when kelia is gone
  public calculateVolatility(
    investmentsfunds: KarmaProfile[],
    dinvestmentsfunds: KarmaProfile[],
    effectDate: string,
    productId: string,
    policyNumber: string,
    netPremiumAmount: number,
    contractorId: string,
    managementNode: string,
    operation: string,
    investmentMode?: number,
    demandType?: VolatilityType,
    oldDisinvestmentProfiles?: KarmaProfile[]
  ): Observable<VolatilityResponse> {
    const request: any = {
      managementNode,
      policyNumber,
      idSoggetto: contractorId,
      idProdotto: productId,
      effectDate,
      codeOper: operation
    };
    if (demandType === VolatilityType.premiumDemand) {
      request.premiumDemand = {
        netPremiumAmount,
        investmentType: 1,
        profAlloc: investmentsfunds
      };
      request.investmentProfiles = investmentsfunds;
      request.disinvestmentProfiles = [];
    } else if (demandType === VolatilityType.surrenderDemand) {
      request.surrenderDemand = {
        amountType: 15,
        netPremiumAmount,
        disinvestModality: investmentMode,
        profAlloc: investmentsfunds
      };
      request.investmentProfiles = [];
      request.disinvestmentProfiles = dinvestmentsfunds;
    } else if (demandType === VolatilityType.switchDemand) {
      request.switchDemand = {
        amountType: 19,
        amountValue: netPremiumAmount,
        disinvestModality: 21,
        desinvestProfAlloc: oldDisinvestmentProfiles,
        investModality: !!investmentMode ? investmentMode : 21,
        investProfAlloc: investmentsfunds
      };
      request.investmentProfiles = investmentsfunds;
      request.disinvestmentProfiles = dinvestmentsfunds;
    } else if (demandType === VolatilityType.changeCombination) {
      request.changeCombination = {
        amountType: 19,
        amountValue: netPremiumAmount,
        disinvestModality: 21,
        desinvestProfAlloc: oldDisinvestmentProfiles,
        investModality: !!investmentMode ? investmentMode : 21,
        investProfAlloc: investmentsfunds
      };
      const disinvestmentProfilesNoFunds = dinvestmentsfunds.map(item => {
        const { funds, ...rest } = item;
        return rest;
      });
      request.investmentProfiles = investmentsfunds;
      request.disinvestmentProfiles = disinvestmentProfilesNoFunds;
    }
    request.amount = netPremiumAmount;
    return this.http.post<any>(this.baseApiUrl + '/v2/life/funds/volatility', request);
  }
}

import {Inject, Injectable, Injector, isDevMode, Optional, StaticProvider, Type} from '@angular/core';
import {BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {
  ActiveRoute,
  isGuardResultFragment,
  RGI_RX_ROUTER_CONFIG,
  RgiRxActiveRoute,
  RgiRxCanActivate,
  RgiRxCanActivateChild,
  RgiRxCanDeactivate,
  RgiRxGuardResult,
  RgiRxRouteData,
  RgiRxRouteFragment,
  RgiRxRouteNotFoundError,
  RgiRxRouteOptions,
  RgiRxRouterConfig,
  RgiRxRouterOutlet,
  RgiRxRouterUrlHandlingStrategy,
  RgiRxRoutes,
  RgiRxRouteSnapshot,
  Route,
  ROUTE_DEF_WITH_WILDCARDS_PATTERN,
  RouteChangeOptions,
  RouteComponentLoader,
  Routes,
  ROUTES,
  RoutesWithOptions
} from './router.api';
import {RgiRxRoutingHostDirective} from './directives/rgi-rx-routing-host.directive';
import {
  LoggerFactory,
  projectUnionToObservable,
  RGI_RX_WILDCARD_TEMPLATE_REGEXP,
  RgiRxRuntimeError,
  safeRegexpTest
} from '@rgi/rx';
import {filter, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {cloneDeep, isArray, isEmpty, merge} from 'lodash';
import {
  isParentLink,
  isRelativeLink,
  removeLeadingAndTrailingSlashes,
  removeRelativeRefsFromLink,
  validateRouteData
} from './router.fns';
import {RgiRxRouteLocationOptions, RgiRxRouterLocation, RgiRxURLRouteDef} from './location/router.location.api';
import {RgiRxUrlParser} from './rgi-rx-url-parser';
import {RgiRxHistoryRouter} from './location/rgi-rx-router-location-facade';
import {RgiRxRouterTitleResolver, RgiRxRouterTitleStrategy} from './router.title-strategy';
import {RgiRxRouterEvent, RgiRxRouterEvents, RgiRxRouterEventType} from './router.event';


let transitionId = 0;

interface RgiRxRouteTransitionResult {
  canActivate: boolean,
  canActivateChild: boolean;
  canDeactivate: boolean;
}

class RgiRxRouteTransition {
  id: number;
  result: RgiRxRouteTransitionResult = {
    canActivate: true,
    canActivateChild: true,
    canDeactivate: true
  };
  instance: RgiRxRouteSnapshot;
  route: RgiRxRouteDef;
  replace?: RgiRxRouteSnapshot;

  constructor(instance: RgiRxRouteSnapshot, route: RgiRxRouteDef, replace?: RgiRxRouteSnapshot) {
    this.id = ++transitionId;
    this.instance = instance;
    this.route = route;
    this.replace = replace;
    if (this.replace) {
      this.instance.componentRef = this.replace.componentRef;
    }
  }
}

export const RGI_RX_ROUTE_NAME_MATCH = /^[$()*,;~!_+.\-\/\w\s{}]*$/; // add {} for path params impl.
export const RGI_RX_ROUTE_PATH_FRAGMENT = '{\\s?([^{}]{1,})\\s?}';


export interface RgiRxRouteDef<CTX = any> {
  route: string;
  component: Type<any>;
  providers?: StaticProvider[];
  canActivate?: Type<RgiRxCanActivate>[];
  canActivateChild?: Type<RgiRxCanActivateChild>[];
  canDeActivate?: Type<RgiRxCanDeactivate<any>>[];
  parent?: RgiRxRouteDef;
  children: string[];
  path: string;
  context?: CTX;
  title?: string | Type<RgiRxRouterTitleResolver>;
}

/**
 * RgiRx router public api Facade
 * @since 0.15
 */
export abstract class RgiRxRouter {

  /**
   * Get the computed RgiRxRouteDef for the provided routes.
   * The values returned is a clone to prevent side effects from outside the router.
   */
  abstract get definitions(): RgiRxRouteDef[];

  /**
   * Navigate to a route.
   * Using key parameter allow to set the partition key of the route to support multiple parallel routes. Use this when
   * your application does have multiple rgi-rx-routing-outlet hosts.
   * @param route The route name
   * @param routeData The data to be passed to the ActiveRoute
   * @param id The identifier of the route
   * @param options Any other options that can help to support your infrastructure
   * @throws an error when the route it's not registered in the router context
   */
  abstract navigate<T, O extends RgiRxRouteOptions>(route: string, routeData?: RgiRxRouteData<T>, id?: string, options?: O): void;


  /**
   * destroy a route by a specific RgiRxRouterOutlet id
   * @param route the id of the route RgiRxRouterOutlet id or the ActiveRoute
   * @see RgiRxRouterOutlet
   */
  abstract destroy(route: string | ActiveRoute): void;

  /**
   * Subscribe changes and load the route into the RgiRxRoutingHostDirective.
   * Use the RgiRxRouterOutlet implementation as a standard.
   * @param host the host view to attach
   * @param id the id of the RgiRxRouterOutlet
   * @param routeChangeOptions options for route change
   */

  abstract getRouteChange$(host: RgiRxRoutingHostDirective, id?: string, routeChangeOptions?: RouteChangeOptions): Observable<ActiveRoute>;

  /**
   * Observe the last ActiveRoute change stream
   * @param id when set the observation will happen on that ActiveRoute id
   */
  abstract activeRouteChange$(id?: string): Observable<ActiveRoute>;

  /**
   * Observe events emitted by the Router
   * @see RgiRxRouterEventType
   */
  abstract event$(type?: RgiRxRouterEvents): Observable<RgiRxRouterEvent>;
}

@Injectable({
  providedIn: 'root'
})
/**
 * RgiRxRouter implementation
 * @deprecated use RgiRxRouter token instead
 * @see RgiRxRouter
 */
export class RoutingService extends RgiRxRouter implements RgiRxHistoryRouter {
  private transitions: {
    [key: string]: BehaviorSubject<RgiRxRouteTransition>
  } = {root: new BehaviorSubject<RgiRxRouteTransition>(null)};
  private transitionSubscriptions: { [key: string]: Subscription } = {};
  private _lastActive: BehaviorSubject<RgiRxActiveRoute> = new BehaviorSubject<RgiRxActiveRoute | undefined>(undefined);
  private routes: Map<string, RgiRxRouteDef> = new Map<string, RgiRxRouteDef>();
  private _destroyed = new BehaviorSubject<RgiRxActiveRoute>(null);
  private _event = new Subject<RgiRxRouterEvent>();
  private logger = LoggerFactory();

  constructor(
    private routeLoader: RouteComponentLoader,
    private injector: Injector,
    private urlParser: RgiRxUrlParser,
    private urlHandlingStrategy: RgiRxRouterUrlHandlingStrategy,
    /*    private titleStrategy: RgiRxRouterTitleStrategy,*/
    @Optional() @Inject(ROUTES) routes?: RgiRxRoutes[],
    @Optional() private location?: RgiRxRouterLocation,
    @Optional() @Inject(RGI_RX_ROUTER_CONFIG) private config ?: RgiRxRouterConfig
  ) {
    super();
    if (!routes) {
      this.logger.debug('RgiRxRouter::init no route registered in this application');
      return;
    }
    this.readRoutes(this.mapRoutesWithOptions(routes));
    this.subscribeTransition();
    this.subscribeLocationChange();
  }


  /**
   * Navigate to a route.
   * Using key parameter allow to set the partition key of the route to support multiple parallel routes. Use this when
   * your application does have multiple rgi-rx-routing-outlet hosts.
   * @param route The route name
   * @param routeData The data to be passed to the ActiveRoute
   * @param id The identifier of the route
   * @param options Any other options that can help to support your infrastructure
   * @throws an error when the route it's not registered in the router context
   */
  navigate<T, O extends RgiRxRouteOptions = any>(route: string, routeData?: RgiRxRouteData<T>, id?: string, options?: O) {

    const foundRoute = this.findRouteByPath(route, options);
    const existing = id === undefined ? this.getReplacedActiveRoute(foundRoute.targetPath, options) : undefined;
    const transition = new RgiRxRouteTransition({
      route: foundRoute.targetPath,
      id: this.sanitizeId(id),
      routeData,
      options: options ? options : {},
      context: {...foundRoute.targetRouteDef.context},
    }, foundRoute.targetRouteDef, existing?.snapshot());
    this.validateTransition(transition);
    if (!!id) {
      if (!this.transitions[id]) {
        this.transitions[id] = new BehaviorSubject<RgiRxRouteTransition>(transition);
        this.subscribeTransition(id);
      } else {
        this.transitions[id].next(transition);
      }
    } else {
      this.transitions.root.next(transition);
    }
  }

  private findRouteByPath<O extends RgiRxRouteOptions>(route: string, options: O) {
    let targetPath = route;
    let targetRouteDef: RgiRxRouteDef;
    const basePathFromRelative = this.getPathFromRelativeRoute(route, options);
    if (!!options && !!options.relativeTo && !!basePathFromRelative) {
      targetPath = removeRelativeRefsFromLink(route);
      const composedPath = !!targetPath ? `${basePathFromRelative}/${targetPath}` : basePathFromRelative;
      targetRouteDef = this.getRouteConfig(composedPath);
      targetPath = composedPath;
    } else {
      targetRouteDef = this.getRouteConfig(route);
    }
    return {targetPath, targetRouteDef};
  }

  private getPathFromRelativeRoute<O extends RgiRxRouteOptions>(route: string, options: O): string | undefined {
    const isParent = isParentLink(route);
    const isRelative = isRelativeLink(route);
    if (!isRelative && !isParent) {
      return undefined;
    }

    const relative = this.getRouteConfig(options.relativeTo.route);
    let basePath = relative.path;
    if (isParent) {
      basePath = this.resolveParent(route, relative);
    } else if (isRelative) {
      basePath = relative.path;
    }
    return basePath;
  }


  navigateByURL<T>(url: string, routeData?: RgiRxRouteData<T>, options?: RgiRxRouteLocationOptions) {
    this.verifyLocationProvider();
    let routeDefResult = this.findRouteByURL(url, routeData, options);
    if (!routeDefResult) {
      const wildcardRoute = this.getWildcardRoute();
      if (!wildcardRoute) {
        throw new RgiRxRouteNotFoundError(url);
      }
      routeDefResult = {
        uriDef: this.location.toDefinition(url),
        routeDef: wildcardRoute,
      };
    }
    this.navigate(routeDefResult.routeDef.path, this.urlParser.deserializeURLData(routeDefResult.routeDef, routeDefResult.uriDef, routeData), undefined, options);
  }

  private verifyLocationProvider() {
    if (!this.location) {
      throw new RgiRxRuntimeError('RgRxRouter::navigateByURL can only works when providing RgiRxRouterLocation! please import RgiRxRouterLocationModule');
    }
  }

  destroy(route: string | ActiveRoute) {
    if (typeof route !== 'string') {
      return this.destroyRoute(route)
    }
    const routedKey = this._lastActive.value?.id;
    if (routedKey === route) {
      return this.destroyRoute(this._lastActive.value);
    }
  }


  private destroyRoute(activeRoute: RgiRxActiveRoute<any, any, any>) {
    this._destroyed.next(activeRoute);
    this.disposeTransition(activeRoute.id);
    this.logger.debug(`RgiRxRouter::destroy route ${activeRoute.route} ${activeRoute.id ? 'with id ' + activeRoute.id : ''}`, activeRoute);
  }


  /**
   * @deprecated this method does return the last active route in form of an array, it's here only for retro compatibility.
   * and will be removed in version 2.x.
   * Please make sure no code is relying on this method.
   * @internal
   * @param route
   */
  getRoute(route: string): ActiveRoute[] {
    return this.lastActive ? [this.lastActive] : [];
  }

  getRouteConfig(route: string): RgiRxRouteDef {
    if (!this.routes.has(route)) {
      throw new RgiRxRouteNotFoundError(route);
    }
    return this.routes.get(route);
  }

  /**
   * Returns a clone of all computed RgiRxRouteDef from the original Routes provided to the module
   */
  get definitions(): RgiRxRouteDef[] {
    return cloneDeep(Array.from(this.routes.values()));
  }

  getRouteChange$(host: RgiRxRoutingHostDirective, id?: string, routeChangeOptions?: RouteChangeOptions): Observable<ActiveRoute> {
    return this.activeRouteChange$(id)
      .pipe(
        map(current => {
          const providers = this.routes.has(current.route) && this.routes.get(current.route).providers ? this.routes.get(current.route).providers : [];
          if (current.getComponent() !== null && id === current.id) {
            this.onRouteChange(current, host, providers);
          }
          return current;
        }),
        mergeMap(current => {
          const injector = current.componentRef?.injector;
          if (!injector) {
            return of(current);
          }
          const titleStrategy = injector.get<RgiRxRouterTitleStrategy>(RgiRxRouterTitleStrategy);
          const routeConfig = this.getRouteConfig(current.route);
          const titleResolver: RgiRxRouterTitleResolver = routeConfig.title && typeof routeConfig.title !== 'string' ? injector.get<RgiRxRouterTitleResolver>(routeConfig.title) : titleStrategy;
          if (typeof routeConfig.title === 'string') {
            current.title = routeConfig.title;
            current.routeOptions.originTitle = routeConfig.title;
          }
          const resolverResult = projectUnionToObservable(titleResolver.resolve(current.snapshot()));
          return resolverResult.pipe(
            take(1),
            mergeMap(resolvedValue => {
              current.routeOptions.title = {
                key: resolvedValue.title,
                params: resolvedValue.params
              };
              return combineLatest([of(current), projectUnionToObservable(titleStrategy.write(resolvedValue))]);
            }),
            map(([activeRoute, resolvedTitle]) => {
              activeRoute.title = resolvedTitle;
              return activeRoute;
            })
          );
        }),
        tap(current => {
          if (this.location) {
            this.location.setTitle(current.title);
          }
          this._event.next({
            type: RgiRxRouterEvents.COMPONENT_LOADED,
            snapshot: current.snapshot()
          });
        })
      );
  }

  destroyed$(): Observable<ActiveRoute> {
    return this._destroyed.asObservable().pipe(filter(p => !!p));
  }

  /**
   * Empty all the ActiveRoutes
   */
  clear() {
    this._lastActive.next(undefined);
  }

  activeRouteChange$(id?: string): Observable<ActiveRoute> {
    return combineLatest([this._lastActive.asObservable(), of(id)])
      .pipe(
        switchMap(([instance, routeId]) => {
          return instance && instance.id === this.sanitizeId(routeId) ? of(instance) : EMPTY;
        })
      );
  }

  event$(type?: RgiRxRouterEvents): Observable<RgiRxRouterEvent> {
    return this._event.asObservable().pipe(
      filter(e => !type || e.type === type)
    );
  }


  private onRouteChange(activeRoute: RgiRxActiveRoute, host: RgiRxRoutingHostDirective, providers: StaticProvider[]) {
    if (!activeRoute.componentRef) {
      activeRoute.componentRef = this.routeLoader.load(activeRoute, host, providers);
    }
    if (activeRoute.componentRef) {

      activeRoute.destroy$().subscribe(next => {
        this.destroyRoute(activeRoute);
        this._event.next({
          type: RgiRxRouterEvents.COMPONENT_DESTROYED
        });
      })
    }
  }

  private getRouteDataKey(route: string, id?: string) {
    return id !== undefined ? `${route}_${id}` : route;
  }

  private subscribeTransition(key: string = 'root') {
    if (!!this.transitionSubscriptions[key]) {
      this.transitionSubscriptions[key].unsubscribe();
    }
    this.transitionSubscriptions[key] = this.transitions[key]
      .pipe(
        filter(t => !!t),
        switchMap(rootTransition => {
          if (!!rootTransition.instance.options && !!rootTransition.instance.options.skipGuards) {
            return of(rootTransition);
          }
          if (!this.urlHandlingStrategy.shouldProcess(rootTransition.route.route)) {
            return EMPTY;
          }
          return of(rootTransition).pipe(
            // canDeactivate
            mergeMap(next => {
              const instance = this._lastActive.value;
              const routeConfig = instance ? this.getRouteConfig(instance.route) : undefined;
              if (instance && (!instance.routeOptions || !instance.routeOptions.initialNavigation) && routeConfig && routeConfig.canDeActivate && routeConfig.canDeActivate.length) {
                const canDeactivates$ = routeConfig.canDeActivate
                  .map(canDeactivateDef => {
                    try {
                      return instance.componentRef.injector.get(canDeactivateDef);
                    } catch (e) {
                      this.logger.error('RgiRxRouter error resolving RgiRxCanDeactivate token', e);
                      throw e;
                    }
                  }).map(canActivateFn => this.wrapGuardResult(canActivateFn.canDeactivate(instance ? instance.componentRef.instance : undefined, next.instance))
                    .pipe(take(1)));
                return of(next).pipe(
                  mergeMap(transition => {
                    return combineLatest([of(transition), forkJoin(canDeactivates$)]);
                  }),
                  mergeMap(([transition, canDeactivateResults]) => {
                    return this.handleGuardTransition(transition, canDeactivateResults, 'canDeactivate');
                  }),
                  filter(transition => !!transition.result.canDeactivate)
                );
              }
              return of(next);
            }),
            // canActivate
            mergeMap(next => {
              if (next.route.canActivate && next.route.canActivate.length) {
                const canActivates$ = next.route.canActivate.map(canActivateType => {
                  try {
                    return this.injector.get(canActivateType);
                  } catch (e) {
                    this.logger.error('RgiRxRouter error resolving RgiRxCanActivate token', e);
                    throw e;
                  }
                }).map(canActivateFn => this.wrapGuardResult(canActivateFn.canActivate(next.instance)).pipe(take(1)));
                return of(next).pipe(
                  mergeMap(transition => {
                    return combineLatest([of(transition), forkJoin(canActivates$)]);
                  }),
                  mergeMap(([transition, canActivateResults]) => {
                    return this.handleGuardTransition(transition, canActivateResults, 'canActivate');
                  }),
                  filter(transition => !!transition.result.canActivate)
                );
              }
              return of(next);
            }),
            // canActivateChild
            mergeMap(next => {
              const canActivateChild = !!next.route.parent ? next.route.parent.canActivateChild : undefined;
              if (canActivateChild) {
                const canActivatesChild$ = canActivateChild.map(canActivateType => {
                  try {
                    return this.injector.get(canActivateType);
                  } catch (e) {
                    this.logger.error('RgiRxRouter error resolving RgiRxCanActivateChild token', e);
                    throw e;
                  }
                }).map(canActivateFn => this.wrapGuardResult(canActivateFn.canActivateChild(next.instance)).pipe(take(1)));
                return of(next).pipe(
                  mergeMap(transition => {
                    return combineLatest([of(transition), forkJoin(canActivatesChild$)]);
                  }),
                  mergeMap(([transition, canActivateChildResults]) => {
                    return this.handleGuardTransition(transition, canActivateChildResults, 'canActivateChild');
                  }),
                  filter(transition => !!transition.result.canActivateChild)
                );
              }
              return of(next);
            })
          );
        })
      )
      .subscribe(
        next => {
          if (next.result.canActivate && next.result.canDeactivate) {
            this.pushTransition(next);
          }
        }
      );
  }


  private redirect(...redirects: RgiRxRouteFragment[]) {
    const destination = redirects[redirects.length - 1];
    if (!destination) {
      return;
    }
    if (this.location && !destination.id) {
      this.navigateByURL(destination.route, destination.routeData, destination.options);
    } else {
      this.navigate(destination.route, destination.routeData, destination.id, destination.options);
    }
  }

  private flattenRouteToDefs(route: Route, parent?: RgiRxRouteDef, defs: RgiRxRouteDef[] = []): RgiRxRouteDef[] {
    const rootDef: any = merge({}, route, {
      path: parent && parent.path !== '' ? removeLeadingAndTrailingSlashes(`${parent.path}/${route.route}`) : removeLeadingAndTrailingSlashes(route.route),
      parent: parent ? parent : undefined
    });
    defs.push(rootDef);
    if (rootDef.children) {
      rootDef.children.forEach(c => {
        return this.flattenRouteToDefs(c, rootDef, defs);
      });
      rootDef.children = rootDef.children.map(c => c.route);
    }
    return defs;
  }

  private validateRoute(route: Route, parents: string[] = [], checks: string[] = []): string[] {
    const isValid = this.validateRouteName(route.route);
    if (!isValid) {
      checks.push(!!parents.length ? `${parents.join('/')}${route.route}` : route.route);
    }
    if (route.children) {
      parents.unshift(route.route);
      route.children.forEach(
        children => {
          this.validateRoute(children, parents, checks);
        }
      );
    }
    return checks;
  }

  private validateRouteName(route: string): boolean {
    return safeRegexpTest(RGI_RX_ROUTE_NAME_MATCH, route);
  }

  private readRoutes(routes: RoutesWithOptions[]) {
    const tempRoutes = new Map<string, Route>();
    routes.forEach(r =>
      r.routes.forEach(
        flat => {
          let route: Route = flat;
          const routeValidations = this.validateRoute(route);
          if (routeValidations.length) {
            this.logger.warn(`RgiRxRouter::init detected illegal route name: ${route.route}\n Routes name should only contain valid URI path characters [${RGI_RX_ROUTE_NAME_MATCH}, fix the route name since they will not be supported anymore in version 1.x!`);
          }
          if (tempRoutes.has(route.route)) {
            const oldRoute = tempRoutes.get(route.route);
            route = r.options && !r.options.mergeExisting ? flat : merge({}, oldRoute, flat);
            this.logger.debug(`RgiRxRouter::init route ${oldRoute.route} is overridden by a new configuration`, {
              old: oldRoute,
              current: route
            });
          }
          tempRoutes.set(route.route, route);
        }));
    tempRoutes.forEach(route => {
      const definitions = this.flattenRouteToDefs(route);
      definitions.forEach(def => {
        this.routes.set(def.path, def);
      });
    });
    this.logger.debug(`RgiRxRouter::init registered routes: ${this.routes.size}`, this.routes);
  }

  private disposeTransition(id: string) {
    const transitionStack = this.transitions[id];
    if (!!transitionStack) {
      transitionStack.complete();
      delete this.transitions[id];
    }
    const transitionSubscription = this.transitionSubscriptions[id];
    if (!!transitionSubscription) {
      this.transitionSubscriptions[id].unsubscribe();
      delete this.transitionSubscriptions[id];
    }
  }

  private pushTransition(transition: RgiRxRouteTransition) {
    const activeRoute = new RgiRxActiveRoute(
      transition.route.component,
      transition.instance.route,
      transition.instance.routeData,
      transition.instance.id,
      transition.instance.options,
      transition.instance.componentRef,
      transition.instance.context,
      this.config
    );

    if (activeRoute.routeOptions) {
      activeRoute.routeOptions.state = {...activeRoute.routeOptions.state, _transitionId: transition.id};
    }

    if (this.location && !activeRoute.id) {
      this.location.push(activeRoute);
    }
    this.logger.debug(`RgiRxRouter::routeChange last route ${activeRoute.route} with id ${activeRoute.id}`, activeRoute);
    this._lastActive.next(activeRoute);
  }

  private wrapGuardResult(handle: RgiRxGuardResult): Observable<boolean | RgiRxRouteFragment> {
    if (typeof handle === 'boolean') {
      return of(handle);
    } else if (isGuardResultFragment(handle)) {
      return of(handle);
    }
    return handle;
  }

  private mapRoutesWithOptions(routes: RgiRxRoutes[]): RoutesWithOptions[] {
    return routes
      .map(root => {
        if (this.isRouteType(root)) {
          return {
            routes: root,
            options: undefined
          };
        } else {
          return root;
        }
      });
  }

  private isRouteType(routes: RgiRxRoutes): routes is Routes {
    return isArray(routes);
  }

  private sanitizeId(id: string) {
    if (id === null || id === undefined) {
      return undefined;
    }
    return id;
  }

  private resolveParent(targetPath: string, relative: RgiRxRouteDef) {
    const parentRoundTrips = targetPath.split('/').filter(p => p === '..').length;
    let i = 0;
    let route = relative;
    while (i < parentRoundTrips) {
      if (!route.parent) {
        throw new RgiRxRuntimeError(`RgiRxRouter invalid path ${targetPath}, ${route.route} has no parent!`);
      }
      route = route.parent;
      i++;
    }
    return route.path;
  }


  private subscribeLocationChange() {
    if (!this.location) {
      return;
    }

    if (this.location.shouldNavigateAtLaunch()) {
      const startLocation = removeLeadingAndTrailingSlashes(this.location.path());
      this.navigateByURL(startLocation, this.urlParser.deSerializeQueryString(this.location.query()), {
        initialNavigation: true
      });
    }

    this.location.subscribe(
      next => {
        const source = next.type === 'popstate' ? 'popstate' : 'hashchange';
        if (source === 'popstate') {
          setTimeout(() => {
            if (this.urlHandlingStrategy.shouldProcess(next.url)) {
              this.navigateByURL(next.url, {}, {
                state: next.state
              });
              this.logger.debug('RgiRxRouter::locationChange', next);
            }
          });
        }
      }
    );
  }


  findRouteByURL<T>(url: string, routeData?: RgiRxRouteData<T>, options?: RgiRxRouteLocationOptions): RgiRxURLRouteDef {
    this.verifyLocationProvider();
    let urlParsed = url;
    if (!!options && options.relativeTo) {
      const basePath = this.getPathFromRelativeRoute(urlParsed, options);
      if (!!basePath) {
        const clearedBase = removeRelativeRefsFromLink(urlParsed);
        urlParsed = `${basePath}/${clearedBase}`;
      }
      const mergedRouteData = this.mergeDataFromRelativeRoute(routeData, options.relativeTo);
      if (mergedRouteData) {
        const serializeRoute = this.urlParser.serializeRoute(urlParsed, mergedRouteData);
        urlParsed = options && options.preserveQueryString ? serializeRoute.url : serializeRoute.path;
      }
    } else {
      urlParsed = this.urlParser.serializeRoute(urlParsed, routeData).url;
    }

    const normalizedURL = removeLeadingAndTrailingSlashes(urlParsed);
    const def = this.location.toDefinition(normalizedURL);
    try {
      return {
        uriDef: def,
        routeDef: this.getRouteConfig(def.path)
      };
    } catch (e) {
      const paths = def.path.split('/').filter(v => !!v);
      const iterator = this.routes.values();
      let result = iterator.next();
      let matchingPaths = [];
      while (!result.done) {
        matchingPaths = [];
        const routePath = result.value.path;
        const routeSegments = routePath.split('/');
        if (safeRegexpTest(ROUTE_DEF_WITH_WILDCARDS_PATTERN, routePath) && routeSegments.length === paths.length) {
          for (let i = 0; i < routeSegments.length; i++) {
            if (routeSegments[i] === paths[i]) {
              matchingPaths.push(routeSegments[i]);
              continue;
            } else if (safeRegexpTest(RGI_RX_WILDCARD_TEMPLATE_REGEXP, routeSegments[i])) {
              try {
                const parsedPath = JSON.parse(decodeURIComponent(paths[i]));
                if (typeof parsedPath === 'object') {
                  this.logger.warn(`RgiRxRouter::findDefinitionByURL parsed path parameter at position ${i} ${paths[i]} is a non scalar value. Invalid URL`);
                  break;
                }
                matchingPaths.push(parsedPath);
              } catch (e) {
                matchingPaths.push(paths[i]);
              }
              continue;
            }
            break;
          }
        }
        if (matchingPaths.length && matchingPaths.join('/') === def.path) {
          return {
            uriDef: def,
            routeDef: result.value,
          };
        }
        result = iterator.next();
      }
    }
  }


  /**
   * Navigate forward in history
   * @see RgiRxHistoryRouter
   */
  forward(): void {
    this.location.forward();
  }

  /**
   * Navigate back in history
   * @see RgiRxHistoryRouter
   */
  back(): void {
    this.location.back();
  }

  private getWildcardRoute(): RgiRxRouteDef | undefined {
    return this.routes.get('**');
  }

  private mergeDataFromRelativeRoute(data: RgiRxRouteData<any>, route: RgiRxRouteSnapshot | ActiveRoute | RgiRxActiveRoute, ...includeKeys: string[]): RgiRxRouteData<any> {
    if (this.isRouteSnapshot(route)) {
      return merge({}, route.routeData, data);
    } else {
      return merge({}, route.getRouteData(), data);
    }
  }

  private isRouteSnapshot(route: RgiRxRouteSnapshot | ActiveRoute | RgiRxActiveRoute): route is RgiRxRouteSnapshot {
    return ((route as RgiRxActiveRoute).getRouteData) === undefined;
  }

  private handleGuardTransition(transition: RgiRxRouteTransition, guardResults: boolean[] | RgiRxGuardResult[], guardType: keyof RgiRxRouteTransitionResult): Observable<RgiRxRouteTransition | never> {
    transition.result[guardType] = guardResults.filter(c => c !== undefined && typeof c === 'boolean').every(c => !!c);
    const redirects = guardResults.filter(c => c !== undefined && typeof c !== 'boolean') as RgiRxRouteFragment[];
    if (redirects.length) {
      this.redirect(...redirects);
      return EMPTY;
    }
    return of(transition);
  }

  private get lastActive(): RgiRxActiveRoute | undefined {
    return this._lastActive.value;
  }

  private getReplacedActiveRoute<O extends RgiRxRouteOptions>(targetPath: string, opts?: O): ActiveRoute | undefined {
    if (!this.lastActive || !this.location) {
      return;
    }
    return this.lastActive.route === targetPath &&
    this.lastActive.id === undefined &&
    this.lastActive.route === opts?.relativeTo?.route ? this.lastActive : undefined;
  }


  private validateTransition(transition: RgiRxRouteTransition) {
    if (isDevMode()) {
      const validation = validateRouteData(transition.instance.routeData);

      if (!isEmpty(validation)) {
        const invalidKeys = Object.keys(validation);
        this.logger.warn(`RgiRxRouter Warning!
Detected invalid route data keys (${invalidKeys.join(', ')}) for route: ${transition.instance.route}${transition.instance.id ? ` with id ${transition.instance.id}` : ''}.
Please make sure you're not using nested objects or collections as route data!
Route data should be serializable as query parameters or path segments to support the RgiRxRouterLocationModule.
Eventually use the state option to define any nested data to be preserved in the History browser state, but make sure that any information used to resolve your resources can be rendered in the URI.
This warning will be displayed only in development mode, but will be enforced in next versions of the framework.
`, validation);
      }
    }
  }
}

import {PncPostsalesTranslationsKeys} from './pnc-psales-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const PNC_PSALES_IT: PncPostsalesTranslationsKeys = {
  _PCPSALES_: {
    _BTN_: {
      _BACK_: 'Indietro',
      _CALCULATE_: 'Calcola',
      _CONFIRM_: 'Conferma',
      _CONTINUE_: 'Continua',
      _DETAIL_: 'Dettaglio',
      _DOCS_: 'Gestione Documenti',
      _END_: 'Fine',
      _EXIT_: 'Esci',
      _PRINT_: 'Stampa',
      _RESET_: 'Reset'
    },
    _LABEL_: {
      _AMOUNT_: 'Importo',
      _ANTIRACKET_: 'Antiracket',
      _APPENDIX_EFFECT_DATE_: 'Data effetto appendice',
      _APPENDIX_EXPIRY_DATE_: 'Data Scadenza appendice',
      _CANCELLATION_NOTIFICATION_DATE_: 'Data Invio comunicazione Annullo',
      _CANCELLATION_REASON_: 'Motivo Annullo',
      _CHARGES_: 'Accessori',
      _CHARGES_INTERESTS_: 'Int. Acc.',
      _COLLECTED_COMMISSIONS_: 'Provv Inc.',
      _EFFECT_DATE_: 'Data Effetto',
      _EFFECT_HOUR_: 'Ora Effetto',
      _EXCLUSION_NOTIFICATION_DATE_: 'Data Invio comunicazione Annullo',
      _EXCLUSION_REASON_: 'Motivo Esclusione',
      _FEES_: 'Tasse',
      _GROSS_: 'Lordo',
      _INSURED_RISKS_: 'Rischi assicurati',
      _ISSUE_DATE_: 'Data Emissione',
      _NET_: 'Netto',
      _NET_INTERESTS_: 'Int. Netto',
      _NOT_INSURED_RISKS_: 'Rischi non presenti in polizza',
      _NOTES_: 'Note',
      _OPERATION_PREMIUM_: 'Premio Operazione',
      _PRINT_DOCUMENTS_: 'Stampa documenti',
      _PURCHASED_COMMISSIONS_: 'Provv Acq.',
      _RCA_NET_: 'Netto RCA',
      _RATE_: 'Percentuale',
      _RIGHTS_: 'Diritti',
      _SSN_: 'SSN',
      _TAX_: 'Imposte',
      _TAXABLE_: 'Imponibile',
      _TAXES_: 'Tasse'
    },
    _MSG_: {
      _ALERT_ACCOUNTING_REVERSAL_: 'Impossibile effettuare lo storno poiché il movimento ha un titolo in stato diverso da arretrato. Procedere manualmente con lo storno dell’incasso del titolo legato.',
      _CONFIRM_ACCOUNTING_REVERSAL_: 'Attenzione, confermando oltre al movimento sarà stornato anche l\'incasso del titolo legato. Si vuole proseguire?',
      _CONFIRM_OPERATION_: 'Confermare l\'operazione {{opDescr}} sulla polizza n. {{policyNumber}}?',
      _CONFIRM_REVERSAL_: 'Confermi l\'annullamento del movimento? Tale annullamento è definitivo e il movimento non potrà essere ripristinato.',
      _NO_TECH_ACC_FOUND_: 'Non è presente nessun titolo arretrato precedente alla data effetto variazione o incassato successivamente',
      _NODE_TO_REQUIRED_: 'Il nodo ricevente è obbligatorio',
      _NOT_ALLOWED_REVERSAL_: 'Storno movimento non permesso.',
      _OPERATION_FOR_POLICY_OK_: 'L\'operazione {{opDescr}} sulla polizza {{policyNumber}} è stata eseguita correttamente',
      _SAME_NODE_: 'Il nodo trasferente e ricevente sono uguali',
      _SAME_BRANCHES_: 'Le filiali trasferente e ricevente sono uguali',
      _WARNING_ACCOUNTING_REVERSAL_: 'Attenzione, procedendo oltre al movimento sara\' stornato anche l\'incasso del titolo legato.',
    },
    _TITLE_: {
      _PREMIUM_REPAYMENT_: 'Rimborso premio',
      _TECH_ACC_LIST_: 'Elenco titoli',
      _MOVEMENTS_LIST_: 'Lista Movimenti'
    },
    _STEPS_: {
      _CANCELLATION_DATA_: 'Dati Annullo',
      _EXCLUSION_DATA_: 'Dati Esclusione',
      _TECHNICAL_ACCOUNTING_: 'Titoli',
      _SUMMARY_: 'Riepilogo',
      _VARIATION_DATES_: 'Date Variazione',
      _MOVEMENTS_: 'Movimenti',
      _SUPPLEMENTARY_TECH_ACC_: 'Premio Appendice',
      _POLICY_TRANSFER_DATA_: 'Dati Trasferimento Polizza'
    },
    _SESSION_TITLE_: {
      _APPLICATION_EXCLUSION_: 'Esclusione applicazione n. {{policyNumber}}',
      _MOVEMENTS_REVERSAL_: 'Storno movimento polizza n. {{policyNumber}}',
      _POLICY_CANCELLATION_: 'Annullo polizza n. {{policyNumber}}',
      _POLICY_REACTIVATION_: 'Rimessa in vigore polizza n. {{policyNumber}}',
      _POLICY_TRANSFER_: 'Trasferimento Polizza n. {{policyNumber}}',
      _TECHNICAL_ACCOUNTING_APPENDIX_: 'Appendice con Titolo Contabile polizza n. {{policyNumber}}'
    }
  },
  _RGIPNC_: {
    _BTN_: {
      _CANCEL_: 'Annulla',
      _CLOSE_: 'Chiudi',
      _CONFIRM_: 'Conferma'
    },
    _LABEL_: {
      _ACCESSORIES_: 'Accessori',
      _ACQUIRED_CHARGES_: 'Acc. Acq.',
      _ACQUIRED_COMMISSION_: 'Netto Acq.',
      _ACTION_: 'Azione',
      _ACTIONS_: 'Azioni',
      _AMOUNT_: 'Importo',
      _ANNUAL_PREMIUM_: 'Premio Annuo',
      _ANTIRACKET_: '(Di cui antiracket)',
      _APPENDIX_: 'Appendice',
      _BRANCH_FROM_: 'Filiale Trasferente',
      _BRANCH_TO_: 'Filiale Ricevente',
      _CANCELLED_: 'Stornato',
      _CODE_: 'Codice',
      _COLLECTED_CHARGES_: 'Acc. Inc.',
      _COLLECTED_COMMISSION_NET_: 'Netto Inc.',
      _COMMISSIONS_: 'Provvigioni',
      _ACCOUNTING_DETAILS_: 'Dettaglio Titoli',
      _CURRENCY_: 'Divisione',
      _DESCRIPTION_: 'Descrizione',
      _EFFECT_: 'Effetto',
      _EFFECT_DATE_: 'Data Effetto',
      _EFFECT_HOUR_: 'Ora Effetto',
      _GROSS_: 'Lordo',
      _INCEPTION_INSTALLMENT_: 'Rata Firma',
      _INSTALLMENT_INTEREST_: 'Interessi di frazionamento',
      _INSTALLMENT_PREMIUM_: 'Premio di rata',
      _INSURANCE_TAX_: 'Imposta d\'Assicurazione',
      _ISSUE_: 'Emissione',
      _NET_: 'Netto',
      _NEXT_INSTALLMENT_PREMIUM_: 'Prossima Rata',
      _NODE_: 'Nodo',
      _NODE_FROM_: 'Nodo Trasferente',
      _NODE_TO_: 'Nodo Ricevente',
      _NUMBER_: 'Numero',
      _POLICY_DETAILS_: 'Dettaglio premio di polizza',
      _TOTAL_PREMIUMS_: 'Premi Totali',
      _RATE_: 'Aliquota',
      _RIGHTS_: 'Diritti',
      _SELECT_NODE_: 'Seleziona Nodo',
      _SIGNED_COMMISSIONS_: 'Commissioni alla firma',
      _SSN_: 'SSN',
      _STATUS_: 'Stato',
      _TAXABLE_: 'Imponibile',
      _TAXES_: 'Tasse',
      _TOTAL_: 'Totale',
      _TYPE_: 'Tipo',
      _USER_: 'Utente',
      _VIEW_: 'Visualizza',
      _YES_: 'SI',
      _NO_: 'NO'
    },
    _TITLE_: {
      _NODE_LIST_: 'Elenco Nodi',
      _SYSTEM_MESSAGE_: 'Messaggio di sistema',
    }
  },
  _VOID_: '',
};

<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="expanded" #first color="info">
    <!-- Intestazione / Titolo -->
    <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-6 col-md-6">
                <label ng-bind="label">
                    {{ '_CLAIMS_._INJURY_PHISICAL' | translate }}
                </label>
            </div>
        </div>
    </div>
    <!-- Form -->
    <ng-container *ngTemplateOutlet="containerPhisical"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion">
    <ng-container *ngTemplateOutlet="containerPhisical"></ng-container>
</div>

<ng-template #containerPhisical>
    <claims-dsc-injury-asset-detail [party]="party" [card]="card"
    [selectedNode]="selectedNode"
    [IinjuryAsset]="injuryAssetInput"
    [activeRouteId]="activeRouteId"
    [injuryNatureList]="injuryNatureList"
    [injuryLocationList]="injuryLocationList" 
    [subject]="subject" 
    [professionList]="professionList" [injuryCauseList]="injuryCauseList"
    [addAssetForm]="addAssetForm" (dscPhisicalInjury)="getDataFormInjury($event)"></claims-dsc-injury-asset-detail>
    <div *ngIf="injuryAssetInput.fatalDate">
        <claims-dsc-heir [selectedNode]="selectedNode" [heirsList]="heirsList"
        [activeRouteId]="activeRouteId" (outputHeirData)="outputHeirData($event)">
        </claims-dsc-heir>
    </div>
</ng-template>
<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Expected Documents List</span>
          <span (click)="close()" class="glyphicon glyphicon-remove pull-right cursorPointer"></span>
        </h3>
      </div>
      <div class="modal-body">
        <table class="nmf_cont_atr">
          <thead *ngIf="!messages || messages.length === 0">
          <tr>
            <th translate>Document Name</th>
            <th *ngIf="showStatus" translate>Status</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let document of expectedDocuments; let i = index">
            <tr *ngIf="i % 2 === 0" class="nmf_oddRow">
              <td translate>
                <b> {{ document.name }}</b></td>
              <td *ngIf="showStatus">{{ document.status }}</td>
            </tr>
            <tr *ngIf="i % 2 !== 0" class="nmf_oddCol">
              <td translate>
                <b> {{ document.name }}</b></td>
              <td *ngIf="showStatus">{{ document.status }}</td>
            </tr>
          </ng-container>
          <div *ngIf="messages && messages.length > 0" id="error-container" style="margin-top: 2em">
            <pnc-form-message [messages]="messages" detail="validationError" type="warning">
            </pnc-form-message>
          </div>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-expected-document-modal-close" translate>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { ClaimsElasticService } from '../claims-elastic.service';
import { Claim } from '../dto/claim';
// import { Injectable } from '@angular/core';
import { Observable, of, from, throwError } from 'rxjs';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
// import { map } from 'rxjs/operators';

const url = ''; // removed for vulnerabilities, resume from history and add properties
const userId = '';
const readQuery = `{
  users {
    id
    username
    courses {
      id
      title
      description
      chapters {
        title
        objectives
        description
        lessons {
          title
          content
        }
      }
    }
  }
}`;

const loginQuery = `{
  login($email: String $password: String) {
    login(email: $email password: $password) {
      token
      user
    }
  }
}`;

const loadQuery = `{
  users {
    id
    username
    courses {
      id
      title
      description
      chapters {
        title
        objectives
        description
        lessons {
          title
          content
        }
      }
    }
  }

}`;

// query getClaimById($claimsId: String) {
//   getByClaimId(id: $claimsId){
//     claimNumber
//     occurenceDate
//     claimsHandler
//     parties {
//       name
//       surname
//     }
//   }
// }
const queryClaim = `{getByClaimId(id: "1160040"){ claimNumber }}`;

// query freeSearch($words: String){
//   freeSearch(terms: $words, start: 0, size: 10){
//     claimNumber
//     occurenceDate
//     parties {
//       name
//       surname
//     }
//   }
// }
// const queryClaimFreeSearch = `{freeSearch(terms: "Antonio", start: 0, size: 10){ claimNumber occurenceDate claimsHandler
//    policyNumber parties { name surname typeOfLoss { typeOfLossDescription } } }}`;

const execute = (type: 'query' | 'mutation', myquery: string, vars: any = {}): Observable<any> => {
  return from(fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ [type]: myquery, variables: vars }),
    })
    .then(data => data.json())
    // .then(data => data['data'])
    .catch(throwError)
  );
};

const query = (myquery: string, vars: any = {}): Observable<any> => {
  return execute('query', myquery, vars);
};

@Component({
  selector: 'claims-claims-elastic-search',
  templateUrl: './claims-elastic-search.component.html',
  styleUrls: ['./claims-elastic-search.component.scss']
})
export class ClaimsElasticSearchComponent extends CardComponent implements OnInit, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  emitter: any;
  searchClaimsElasticForm: UntypedFormGroup;
  validationMessages: string[];
  magicString: string = '';
  claimNumber: string = '';
  partySearch: boolean = false;
  claimSearch: boolean = false;
  regexpSearch: boolean = false;
  query: string;


  constructor(
    @Inject('sessionService') private session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') eventService: any,
    public translateService: RgiRxTranslationService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private claimsElasticService: ClaimsElasticService,
    private formBuilder: UntypedFormBuilder,
    // private apollo: Apollo
  ) {
    super(cardComponentService, sessionService);
    this.emitter = eventService;
  }

  ngOnInit(): void {
    this.initValidators();
  }

  initValidators() {
    this.searchClaimsElasticForm = this.formBuilder.group({
      claimNumber: [],
      partyName: [],
      magicWord: [],
    });
  }

  public readData(): Observable<any> {
    return query(queryClaim);
  }

  prepareRequest(type: 'query' | 'mutation', myquery: string, vars: any = {}) {
    return JSON.stringify({ [type]: myquery, variables: vars });
  }

  goToSearchResults() {

    this.claimSearch = false;
    this.partySearch = false;
    this.regexpSearch = false;

    // claimNumber search
    if (this.searchClaimsElasticForm.controls.claimNumber.value) {
      this.claimNumber = this.searchClaimsElasticForm.controls.claimNumber.value;
      this.claimSearch = true;
      this.query = this.claimsElasticService.getClaimSearch(this.claimNumber);
    }

    // partyName search
    if (this.searchClaimsElasticForm.controls.partyName.value) {
      this.magicString = this.searchClaimsElasticForm.controls.partyName.value;
      this.partySearch = true;
      this.query = this.claimsElasticService.getPartySearch(this.magicString);
    }

    // magicWord search
    if (this.searchClaimsElasticForm.controls.magicWord.value) {
      this.magicString = this.searchClaimsElasticForm.controls.magicWord.value;
      this.regexpSearch = true;
      this.query = this.claimsElasticService.getRegexpSearch(this.magicString);
    }


    const claims: Claim[] = [];
    this.validationMessages = [];

    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');

    // TODO temp
    // const claimTemp: Claim = new Claim();
    // claimTemp.claimNumber = '21SIN-99999';
    // claimTemp.claimsHandler = 'FTUSCANO';
    // claimTemp.occurenceDate = new Date('01/12/2021');
    // claims.push(claimTemp);
    // const sessionId = this.session.open('claimsClaimselastic', 'home', '', true);
    // this.claimsElasticService.setSessionClaims(sessionId, claims);

    // this.readData();

    // const testQuery = '{query getByClaimId(id: 1160040){ claimNumber }}';
    // const testQuery1 = {query: gql` getByClaimId(id: 1160040){ claimNumber }` };

    // request example
    // {"query":"{getByClaimId(id: \"1160040\"){ claimNumber }}","variables":{}}
    // response example
    // {"data":{"getByClaimId":{"claimNumber":"21SIN-01218"}}}

    let claimsRes: Claim[] = [];
    let singleClaim: Claim;

    // const request = '{"query":"{getByClaimId(id: "1160040"){ claimNumber }}","variables":{}}';
    // const request = this.prepareRequest('query', queryClaim);

    const request = this.prepareRequest('query', this.query);

    this.claimsElasticService.getClaimsElasticList(request)
      .subscribe((response: any) => {

        if (this.claimSearch) {
          singleClaim = response.data.getClaimByNumber;
          claimsRes[0] = singleClaim;
        } else if (this.partySearch) {
          claimsRes = response.data.partySearch;
        } else if (this.regexpSearch) {
          claimsRes = response.data.regexpSearch;
        }

        if (claimsRes && claimsRes.length > 0) {

          for (const claimRes of claimsRes) {
            claims.push(claimRes);
          }

          const sessionId = this.session.open('claimsClaimselastic', 'home', '', true);
          this.claimsElasticService.setSessionClaims(sessionId, claims);

        } else {
          this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(
            res => this.validationMessages.push(res)
          )
        }
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._MESSAGE_._SEARCH_ERROR').subscribe(
            res => this.validationMessages.push(res)
          )
          this.emitter.broadcastEvent('stop-loader');
        }
      );

  }

  emptyFields() {
    this.searchClaimsElasticForm.reset();
  }

}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormatterUtils } from '../../../invest-standalone-session/utils/FormatterUtils';
import { Profile } from '../../model/profile';

@Component({
  selector: 'lic-profile-step',
  templateUrl: './lic-profile-step.component.html',
  styleUrls: ['./lic-profile-step.component.scss']
})
export class LicProfileStepComponent implements OnInit, OnDestroy {

  @Input() selectedProfileLabel: string;
  @Input() amount: number;
  @Input() leftAmount = 0;
  @Input() hide: boolean;
  @Input() profiles: Profile[] = [];
  @Input() selectedProfiles: Profile[] = [];
  @Output() profileChanged: EventEmitter<Profile[]> = new EventEmitter<Profile[]>();

  subscriptions: Subscription[] = [];
  constructor(public formatter: FormatterUtils) {
  }
  fg: UntypedFormGroup = new UntypedFormGroup({
    FAProfiles: new UntypedFormArray([])
  });

  ngOnInit() {
    this.profiles.forEach(p => (this.fg.get('FAProfiles') as UntypedFormArray).push(new UntypedFormControl(p)));
    this.profileChanged.emit(this.fg.get('FAProfiles').value);
    this.subscriptions.push(this.fg.valueChanges.subscribe(value => {
      this.profileChanged.emit(value.FAProfiles);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getLeftPercent(): number {
    return +((this.leftAmount / this.amount) * 100).toFixed(3);
  }

  getProfilePercentage(profile: Profile): string {
    return profile.percent ? (+profile.percent).toFixed(3) : '';
  }
}

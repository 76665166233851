export const RGI_RX_DROP_i18n = {
  it: {
    RGI_RX: {
      DRAG_DROP: {
        DRAG: 'TRASCINA',
        DROP: 'RILASCIA',
        SELECT: 'SELEZIONA'
      }
    }
  },
  en: {
    RGI_RX: {
      DRAG_DROP: {
        DRAG: 'DRAG',
        DROP: 'DROP',
        SELECT: 'SELECT'
      }
    }
  },
  es: {
    RGI_RX: {
      DRAG_DROP: {
        DRAG: 'ARRASTRAR',
        DROP: 'LIBERAR',
        SELECT: 'SELECCIONE'
      }
    }
  },
  fr: {
    RGI_RX: {
      DRAG_DROP: {
        DRAG: 'TRAÎNE',
        DROP: 'LAISSEZ',
        SELECT: 'SÉLECTIONNER'
      }
    }
  },
  de: {
    RGI_RX: {
      DRAG_DROP: {
        DRAG: 'ZIEHEN',
        DROP: 'TROPFEN',
        SELECT: 'AUSWÄHLEN'
      }
    }
  }
};

import { Component, EventEmitter, Inject } from '@angular/core';
import { OnModalClose, RGI_RX_DROP_SELECTION_DATA, RgiRxDatatableRowAction, RgiRxDropContainerSelectData, TableSchema } from '@rgi/rx/ui';
import { of } from 'rxjs';

@Component({
  selector: 'lic-node-selection-modal',
  template: `
      <rgi-rx-panel>
          <rgi-rx-panel-header [closeable]="true" (onClose)="this.modalClose.emit()">
              {{'lic_select_node' | translate}}
          </rgi-rx-panel-header>
          <rgi-rx-datatable [data]="data" [schema]="schema" (onAction)="onActionClick($event)"></rgi-rx-datatable>
      </rgi-rx-panel>

  `
})
export class NodeSelectionModalComponent implements OnModalClose {
  modalClose = new EventEmitter<any>();


  constructor(
    @Inject(RGI_RX_DROP_SELECTION_DATA) protected dropSelectionData: RgiRxDropContainerSelectData<any, any>
  ) {
    console.log('dropSelectionData', dropSelectionData);
  }

  data = of([].concat(this.dropSelectionData && this.dropSelectionData.selectData || [])); // this.environment.availableNode.asObservable();

  schema: TableSchema = {
    rows: [
      {
        name: 'code', title: 'lic_code',
        format: {
          pipes: [
            {
              pipe: 'uppercase'
            }
          ]
        },
        description: '', styleClass: 'my-name-class'
      },
      {
        name: 'description', title: 'lic_description',
        format: {
          pipes: [
            {
              pipe: 'uppercase'
            }
          ]
        }
        , description: ''
      },
      {
        name: 'action', title: 'lic_action',
        actions: [
          {
            name: 'select',
            styleClass: 'rgifont rgi-chevron-right iconDT'
          },
        ],
        description: 'fire an event'
      }
    ],
    header: ['code', 'description', 'action']
  };

  onActionClick(event: RgiRxDatatableRowAction<any>) {
    if (event.name === 'select') {
      this.modalClose.emit(event.row);
    }
  }

}


import { IbanEntity } from './iban-entity';
import { TaxregimeEntity } from './taxregime-entity';

export class SubjectEntity {
    public type: string; // BNF/PRC/DST/IJR/CTU/CTP/LAV
    public primary: boolean;
    public idSubject: string;
    public idActor: string;
    public idSubjectPA: string; // id soggetto PassAnagrafe
    public idCausale: string;
    public name: string;
    public lastName: string;
    public country: string;
    public stateCode: string;
    public state: string; // provincia
    public municipalityCode: string;
    public municipality: string; // comune
    public postalCode: string;
    public taxIvaRegimeAggregate: string;
    public taxRegimeAggregate: string;
    public address: string;
    public number: string;
    public subMunicipality: string;
    public fiscalCode: string;
    public vat: string;
    public typeBeneficiary: string;
    public legalCode: string; // codice legale per dati fattura
    public ibans: Array<IbanEntity>;
    public subjectLitigationType: string;
    public taxRegime: TaxregimeEntity;
    public taxIvaRegime: TaxregimeEntity;
    public birthDate: Date;
    public toUpgrade: boolean;
    public idSubjectUpgrade: string; // iddatisoggetto del soggetto nuovo
    public nameUpgrade: string;
    public lastNameUpgrade: string;
    public vatUpgrade: string;
    public cfupgrade: string;
    public swifts: Array<IbanEntity>;
    public sepas: Array<IbanEntity>;
    public statusAntiFinancialCrimes: string;
    public statusAntiFinancialCrimesDescr: string;

}

import {Inject, Injectable} from '@angular/core';
import {InsuranceStatus} from '../models/domain-models/insurance-status';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {InsuranceBackground} from '../models/domain-models/insuranceBackground';
import {HttpClient, HttpParams} from '@angular/common/http';
import {InsuranceBackgroundProperties} from '../models/domain-models/insurance-background-properties';
import {BehaviorSubject, Subject} from 'rxjs';
import {Variable} from '../models/domain-models/variable';
import {TranslateService} from '@ngx-translate/core';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceBackgroundService {

  public static ADDITIONAL_DECLARATION_VARIABLE_CODE = '2DICAG';
  private baseApiUrlV2;
  private motorContractEndpoint;
  private variables = new Array<Variable>();
  private insuranceStatusChannel: BehaviorSubject<InsuranceStatus> =
    new BehaviorSubject<InsuranceStatus>(null);
  private recalculateInsuranceStatusChannel: Subject<any> = new Subject<any>();

  protected httpClient: HttpClient;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected translate: TranslateService) {

    this.httpClient = this.micHttpClient.getHttpClient();

    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.motorContractEndpoint = this.baseApiUrlV2 + '/motor/contract';
  }

  public populateArrayInsuranceBackground(insuranceStatus: InsuranceStatus): InsuranceBackground {

    const insuranceBackgroundList = new Array<GenericEntity>();

    if (insuranceStatus.vehicleOrigin &&
      insuranceStatus.vehicleOrigin.description !== '') {
      insuranceBackgroundList.push(new GenericEntity('', 'Vehicle Origin:', insuranceStatus.vehicleOrigin.description));
    }
    if (insuranceStatus.ingressoBersani &&
      insuranceStatus.ingressoBersani.description !== '') {
      insuranceBackgroundList.push(new GenericEntity('', 'Law 134 Benefit:', insuranceStatus.ingressoBersani.description));
    }
    if (insuranceStatus.originPlateType &&
      insuranceStatus.originPlateType.description !== '') {
      insuranceBackgroundList.push(new GenericEntity('', 'Origin Plate Type:', insuranceStatus.originPlateType.description));
    }
    if (insuranceStatus.originPlateCountry &&
      insuranceStatus.originPlateCountry !== '') {
      insuranceBackgroundList.push(new GenericEntity('', 'Original Plate Country:', insuranceStatus.originPlateCountry));
    }
    if (insuranceStatus.originSpecialPlateType &&
      insuranceStatus.originSpecialPlateType.description !== '') {
      insuranceBackgroundList.push(new GenericEntity('', 'Origin Special Plate Type:',
        insuranceStatus.originSpecialPlateType.description));
    }
    if (insuranceStatus.originPlate &&
      insuranceStatus.originPlate !== '') {
      insuranceBackgroundList.push(new GenericEntity('', 'Original Plate Number:', insuranceStatus.originPlate));
    }
    if (insuranceStatus.originChassis &&
      insuranceStatus.originChassis !== '') {
      insuranceBackgroundList.push(new GenericEntity('', 'Origin Chassis:', insuranceStatus.originChassis));
    }
    if (insuranceStatus.relative) {
      const relative = insuranceStatus.relative === true ?
        this.translate.instant('YES') : this.translate.instant('NO');
      insuranceBackgroundList.push(new GenericEntity('', 'Family bonus:', String(relative)));
    }
    if (insuranceStatus.relativePlate) {
      insuranceBackgroundList.push(new GenericEntity('', 'Family vehicle licence plate number:', insuranceStatus.relativePlate));
    }

    if (this.variables) {
      this.variables.forEach(
        (variable) => {
          if (variable.visible) {
            insuranceBackgroundList.push(new GenericEntity('', (variable.extendedDescription
              ? variable.extendedDescription : variable.description) + ':',
              variable.valueDescription));
          }
        }
      );
    }

    const insuranceBackgroundRows = new Array<Array<GenericEntity>>(Math.ceil(insuranceBackgroundList.length / 3));

    let i: number;
    let j = 0;

    for (i = 0; i < insuranceBackgroundList.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!insuranceBackgroundRows[j]) {
        insuranceBackgroundRows[j] = [];
      }
      insuranceBackgroundRows[j].push(insuranceBackgroundList[i]);
    }

    const insuranceBackground = new InsuranceBackground(insuranceBackgroundList, insuranceBackgroundRows);

    return insuranceBackground;

  }

  public isManualATR(vehicleOriginValue: GenericEntity): boolean {
    // redefinito in ext
    return false;
  }

  public enableOriginPlateNumber(atrTypeCode: string): boolean {
    // redefinito in ext
    return false;
  }

  public onPreSubmitInsuranceStatus(insuranceStatus: InsuranceStatus) {
    // redefinito in ext
    return;
  }

  isInsuranceStatusCallError(insuranceStatus: InsuranceStatus): boolean {
    let found = false;
    if (insuranceStatus.completenessErrors) {
      const index = insuranceStatus.completenessErrors.findIndex((mess) => {
        return mess.code === 'INSURANCE_STATUS_CALL_ERROR';
      });
      if (index !== -1) {
        insuranceStatus.completenessErrors.splice(index, 1);
        found = true;
      }
    }
    return found;
  }

  public setVehicleOrigin(contractId: string, vehicleOriginId: number, inputDateOfBirth: number) {
    let httpParams: HttpParams = new HttpParams();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }
    if (vehicleOriginId) {
      httpParams = httpParams.set('vehicleOriginId', String(vehicleOriginId));
    }
    const url = this.motorContractEndpoint + '/' + contractId + '/vehicleorigin';
    return this.httpClient.get<Array<Variable>>(url, {params: httpParams});
  }

  public getInsuranceStatus(contractId: string, inputDateOfBirth: number) {
    let httpParams: HttpParams = new HttpParams();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }
    const path = this.motorContractEndpoint + '/' + contractId + '/insurancestatus';
    return this.httpClient.get<InsuranceStatus>(path, {params: httpParams});
  }

  public updateInsuranceStatus(contractId: string, insuranceStatus: InsuranceStatus, inputDateOfBirth: number) {
    let httpParams: HttpParams = new HttpParams();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }

    const path = this.motorContractEndpoint + '/' + contractId + '/insurancestatus';
    return this.httpClient.put<InsuranceStatus>(path, insuranceStatus, {params: httpParams});
  }

  public getInsuranceBackgroundProperties(contractId: string) {
    return this.httpClient.get<InsuranceBackgroundProperties>(this.motorContractEndpoint + '/' + contractId +
      '/insurancebackground/properties');
  }

  public getInsuranceStatusObs() {
    return this.insuranceStatusChannel.asObservable();
  }

  public setInsuranceStatus(newInsuranceStatus: InsuranceStatus) {
    this.insuranceStatusChannel.next(newInsuranceStatus);
  }

  public getInsuranceStatusObject(): InsuranceStatus {
    return this.insuranceStatusChannel.value;
  }

  getRecalculateInsuranceStatusSignal() {
    return this.recalculateInsuranceStatusChannel.asObservable();
  }

  sendRecalculateInsuranceStatusSignal(insuranceStatus?: InsuranceStatus) {
    this.recalculateInsuranceStatusChannel.next(insuranceStatus);
  }

  setVariables(variables: Variable[]) {
    this.variables = variables;
  }

  getVariables() {
    return this.variables;
  }

   /** Il metodo imposta l'id corretto sulla compagnia prendendolo dal campo codice in quanto l'id essendo un
    * long potrebbe essere approssimato se superiore a 15 caratteri
    */
   public setCorrectIdOnCompany(insuranceStatus: InsuranceStatus) {
    if (insuranceStatus && insuranceStatus.companyId && insuranceStatus.companyId.id &&
      this.isNumber(insuranceStatus.companyId.id) && insuranceStatus.companyId.code) {
      insuranceStatus.companyId.id = insuranceStatus.companyId.code;
    }
  }

  public isNumber(val: any): boolean { return typeof val === 'number'; }


}

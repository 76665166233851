import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcFundsChartComponent } from './lpc-funds-chart.component';
import { ChartsModule } from 'ng2-charts';



@NgModule({
    declarations: [LpcFundsChartComponent],
    imports: [
        CommonModule,
        ChartsModule
    ],
    exports: [
        LpcFundsChartComponent
    ]
})
export class LpcFundsChartModule { }

import {
    Component, ComponentFactoryResolver, ComponentRef,
    EventEmitter, Inject, Input, OnInit, Output, ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Roles } from '../../../models/enum/lpc-subjects.enum';
import { EMPTY_STR, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { Role, RoleDefinition } from '../../../models/postsales-operations-response.model';
import { LpcRolesStepComponent } from '../lpc-roles-step.component';

export interface LpcRolesStepWrapperSummaryRole {
    id: string;
    label: string;
    value: string;
    percentage?: string;
}

@Component({
  selector: 'lpc-roles-step-wrapper',
  templateUrl: './lpc-roles-step-wrapper.component.html',
  styleUrls: ['./lpc-roles-step-wrapper.component.scss']
})
export class LpcRolesStepWrapperComponent implements OnInit {

    @Input() active: boolean; // mostra il componente o il riassunto

    @Input() roles: Role[] = [];
    @Input() operationCode: string;
    @Input() roleDefinition: RoleDefinition[] = [];

  /**
   * @description boolean input to handle the view of the checkbox for the delegate role,
   * it's valorized by the value of the systemProperty - PrevalorizationDelegate.
   * It's true when the policy holder is an adult and the property is true - check made on the LpcRolesStepComponent
   */
  @Input() enableDelegateCheckbox: boolean;
  /**
   * @description boolean input to handle the view of the checkbox for the legal guardian role
   * it's valorized by the value of the systemProperty - PrevalorizationLegalGuardian.
   * It's true when the policy holder is an adult and the property is true - check made on the LpcRolesStepComponent
   */
  @Input() enableLegalGuardianCheckbox: boolean;
  /** @description boolean input to handle the view of the checkbox for the third payer role
   * it's valorized by the value of the systemProperty - PrevalorizationThirdPayer.
   * It's true when the policy holder is an adult and the property is true - check made on the LpcRolesStepComponent
   */
  @Input() enableThirdPayerCheckbox: boolean;

    @Output() roleEvent = new EventEmitter<any>();

    @ViewChild('lpcRoleStepReference', { read: ViewContainerRef }) stepRoleContainer: ViewContainerRef;
    stepRoleComponentRef: ComponentRef<Component>;
    stepRoleComponent: any;

    get selectedRoles(): string {
        if (!!this.roles && this.roles.length > 0) {
          const role: Role = this.roles.find(element => Roles.CONTRACTOR === element.role);
          return role ? role.name : EMPTY_STR;
        } else {
            return null;
        }
    }

    get rolesForSummary(): LpcRolesStepWrapperSummaryRole[] {
        if (!!this.stepRoleComponentRef && this.stepRoleComponentRef.instance) {
            const stepRoleInstance = (this.stepRoleComponentRef.instance as LpcRolesStepComponent);
            if (!!stepRoleInstance.roles && !!stepRoleInstance.roleDefinition) {
                return stepRoleInstance.roles.map(r => {
                    const roleDefinition = this.roleDefinition.find(rd => rd.code === r.role);
                    if (!!roleDefinition) {
                        return {
                            id: r.id,
                            label: roleDefinition.label,
                            value: r.name,
                            percentage: r.percentage
                        };
                    }
                });
            }
        }

        return [];
    }

    constructor(
        @Inject(PV_TOKEN.ROLES_STEP_COMPONENT) lpcRoleStep,
        protected componentFactoryResolver: ComponentFactoryResolver
    ) {
        this.stepRoleComponent = lpcRoleStep;
    }

    ngOnInit() {
    }

    loadOperationRoleStep() {
        if (this.stepRoleContainer) { // il container deve essere visibile
            if (!this.stepRoleComponentRef) {
                this.stepRoleContainer.clear();
                this.stepRoleComponentRef = this.stepRoleContainer.createComponent(
                    this.componentFactoryResolver.resolveComponentFactory(this.stepRoleComponent)
                );
                (this.stepRoleComponentRef.instance as any).roleEvent.subscribe((event) => {
                    this.roleEvent.emit(event);
                });
            }
            // aggiorno i valori
            this.updateOperationRoleStepData(this.roles, this.roleDefinition, this.operationCode);
        }
    }

    updateOperationRoleStepData(roles: Role[], roleDefinition: RoleDefinition[], operationCode?: string) {
        if (this.stepRoleComponentRef.instance) {
            // aggiorno i valori solo se il componente è istanziato
            (this.stepRoleComponentRef.instance as LpcRolesStepComponent).roles = roles;
            (this.stepRoleComponentRef.instance as LpcRolesStepComponent).roleDefinition = roleDefinition;
            (this.stepRoleComponentRef.instance as LpcRolesStepComponent).enableDelegateCheckbox = this.enableDelegateCheckbox;
            (this.stepRoleComponentRef.instance as LpcRolesStepComponent).enableLegalGuardianCheckbox = this.enableLegalGuardianCheckbox;
            (this.stepRoleComponentRef.instance as LpcRolesStepComponent).enableThirdPayerCheckbox = this.enableThirdPayerCheckbox;
            if (!!operationCode) { // facoltativo inserirlo perchè non dovrebbe cambiare dopo la create
                (this.stepRoleComponentRef.instance as LpcRolesStepComponent).operationCode = operationCode;
            }
        }
    }

    checkRoles(): string[] {
        // richiama il controllo interno al componente dello step dei ruoli
        return (this.stepRoleComponentRef.instance as LpcRolesStepComponent).checkRoles();
    }
}

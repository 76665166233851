import { SubjectEntity } from './subject-entity';

export class MethodPaymentEntity {
    public methodId: string;
    public iban: string;
    public accountHolder: string;
    public bankDescription: string;
    public bankAddress: string;
    public bic: string;
    public currency: string;
    public description: string;
    public uic: string;
    public foreignBankAccount: string;
    public receiver: SubjectEntity; // domiciliatario
    public cro: string;
    public swift: string;
    public transferDescription: string; // causale bonifico

}

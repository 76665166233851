import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener, Inject,
  Input,
  Optional,
  Output,
  Renderer2
} from '@angular/core';
import {Highlightable, ListKeyManagerOption} from '@angular/cdk/a11y';
import {RGI_RX_OPTION_A11Y_CONFIG, RgiRxOptionA11yConfig} from '../a11y';
import {ENTER, SPACE} from '@angular/cdk/keycodes';

@Directive({
  selector: '[rgiRxValueOption]',
  host: {
    '[attr.role]': 'a11.role || null',
    '[attr.aria-hidden]': 'hidden ? "true": "false"',
  }
})
export class RgiRxValueOptionDirective<T> implements Highlightable, ListKeyManagerOption {
  private _value: T;
  private _active = false;
  private _disabled: boolean;
  private _hidden: boolean;
  private _label?: string;
  readonly a11: RgiRxOptionA11yConfig = {useAriaPressed: false}

  @Output() onValueChange = new EventEmitter<T>();

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(RGI_RX_OPTION_A11Y_CONFIG) _a11yConfig?: RgiRxOptionA11yConfig
  ) {
    if (_a11yConfig) {
      this.a11 = _a11yConfig;
    }
  }

  @Input() get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
    this._changeDetectorRef.markForCheck();
  }

  @Input() @HostBinding('class.rgi-ui-active') get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
    this._changeDetectorRef.markForCheck();
    if(this.a11.useAriaPressed) {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-pressed', `${value}`);
    }
  }

  @Input() @HostBinding('class.rgi-ui-disabled') get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (!!value) {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'disabled', '');
    } else {
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'disabled');
    }
    this._disabled = value;
    this._changeDetectorRef.markForCheck();
  }


  @Input() @HostBinding('class.rgi-ui-hidden') get hidden(): boolean {
    return this._hidden;
  }

  set hidden(value: boolean) {
    this._hidden = value;
    this._changeDetectorRef.markForCheck();
  }

  @HostListener('mousedown', ['$event'])
  _handleMouseDown(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.select();
  }

  select() {
    if (!this.disabled) {
      this.onValueChange.emit(this.value);
    }
  }

  @HostListener('keydown', ['$event'])
  _handleKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    switch (keyCode) {
      case ENTER: {
        this.select();
        break;
      }
      case SPACE: {
        this.select();
        break;
      }
    }
  }
  @HostListener('click', ['$event'])
  _handleClick(event: MouseEvent) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
    }
  }

  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
    }
  }

  getLabel(): string {
    return this.label;
  }

  @Input() get label(): string {
    return this._label ? this._label : `${this.value}`;
  }

  set label(label: string) {
    this._label = !label ? `${this.value}` : label;
    this._changeDetectorRef.markForCheck();
  }

  get value$() {
    return this.onValueChange.asObservable();
  }

  get elementRef(): HTMLElement {
    return this._elementRef.nativeElement;
  }

}

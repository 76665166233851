import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NotificationService, PPEVONavigationBtnDirective} from '@rgi/ng-passpropro-core';
import {SurveyEvaluateState, SurveyEvaluateStateManagerService} from '../../state/state-manager-survey.module';
import {RoutableComponent} from '@rgi/rx/router';
import { SurveyEvalProductsComponent } from './components/survey-eval-products/survey-eval-products.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
    selector: 'lib-survey-evaluate',
    templateUrl: './survey-evaluate.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SurveyEvaluateComponent extends RoutableComponent implements OnInit {

    st: SurveyEvaluateState;
    isHeaderSticky = false;

    @ViewChild(SurveyEvalProductsComponent, {static: false}) formEvalProductComponent: SurveyEvalProductsComponent;


    constructor(
      private _stMng: SurveyEvaluateStateManagerService<SurveyEvaluateState>,
      private translateService: RgiRxTranslationService,
      private notificationService: NotificationService
    ) {
        super();
    }


    ngOnInit() {
        this._stMng.actionInit();
        this._stMng.getState$().subscribe(st => this.st = st);
    }


    onBtnClick(params: { btn: PPEVONavigationBtnDirective, event: Event }) {
        this._stMng.actionNavigationClick(params.btn.btnId, this.validate);
    }


    /**
     * pipe per ngFor per mantenere l'ordinamento naturale delle chiavi dell'oggetto che si sta ciclando con il pipe keyvalue (di angular)
     */
    preserveKeysOrder = () => 0;


    protected validate = (): boolean => {
        if (this.formEvalProductComponent) {
          if (!this.atLeastAProductSelected()) {
            this.showValidationError();
            return false;
          }
        }
        return true;
      }

    private atLeastAProductSelected() {
      return this.formEvalProductComponent.surveyProductComponent
        .map(product => product.isSelected())
        .some(selected => selected);
    }

      protected showValidationError() {
        this.translateService.translate('NG_PASSPROPRO_SURVEY.product-not-selected').subscribe((res: string) => {
          this.notificationService.push({
            body: res,
            status: 'danger',
            delay: 5000
          });
        });
      }


}

import {Observable} from 'rxjs';
import {RgiRxRouteSnapshot} from './router.api';
import {RgiRxInterpolationParamsType, RgiRxTemplateInterpolationService} from '@rgi/rx';
import {Injectable} from '@angular/core';

export interface RgiRxRouterTitleResolvedValue {
  title?: string;
  params?: RgiRxInterpolationParamsType | any;
}

export abstract class RgiRxRouterTitleStrategy implements RgiRxRouterTitleResolver {
  abstract resolve(snapshot: RgiRxRouteSnapshot): RgiRxRouterTitleResolvedValue | Observable<RgiRxRouterTitleResolvedValue>;
  abstract write(resolved: RgiRxRouterTitleResolvedValue): string | Observable<string>;
}

export interface RgiRxRouterTitleResolver {
  resolve(snapshot: RgiRxRouteSnapshot): RgiRxRouterTitleResolvedValue | Observable<RgiRxRouterTitleResolvedValue>;
}


@Injectable({
  providedIn: 'root'
})
export class RgiRxDefaultRouterTitleStrategy extends RgiRxRouterTitleStrategy {


  constructor(private compiler: RgiRxTemplateInterpolationService) {
    super();
  }

  resolve(snapshot: RgiRxRouteSnapshot): RgiRxRouterTitleResolvedValue | Observable<RgiRxRouterTitleResolvedValue> {
    return {
      title: snapshot.title,
      params: snapshot.routeData
    };
  }

  write(resolved: RgiRxRouterTitleResolvedValue): string | Observable<string> {
    if (!resolved.title){
      return '';
    }
    return this.compiler.interpolate(resolved.title, resolved.params);
  }
}

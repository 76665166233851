import {ExpertEntity} from './expert-entity';

export class ExpertDetailEntity extends ExpertEntity {
  constructor(aRow: ExpertEntity) {
    super();
    this.id = aRow.id;
    this.code = aRow.code;
    this.name = aRow.name;
    this.surname = aRow.surname;
    this.fiscalCode = aRow.fiscalCode;
    this.vatNumber = aRow.vatNumber;
    this.expertType = aRow.expertType;
    this.expertTypeProvider = aRow.expertTypeProvider;
    this.percCash = aRow.percCash;
    this.percVAT = aRow.percVAT;
    this.percWithHolding = aRow.percWithHolding;
    this.abi = aRow.abi;
    this.cabi = aRow.cabi;
    this.bankAccount = aRow.bankAccount;
    this.maxNumAssignments = aRow.maxNumAssignments;
    this.isPublicDepartment = aRow.isPublicDepartment;
    this.isAvailableReplacementCar = aRow.isAvailableReplacementCar;
    this.isAvailableCarCollection = aRow.isAvailableCarCollection;
    this.carBrandDescription = aRow.carBrandDescription;
    this.subjectBusinessName = aRow.subjectBusinessName;
    this.countryCode = aRow.countryCode;
    this.subjectKey1 = aRow.subjectKey1;
    this.subjectKey2 = aRow.subjectKey2;
    this.subjectKey3 = aRow.subjectKey3;
    this.taxRegimeId = aRow.taxRegimeId;
    this.taxRegimeDescription = aRow.taxRegimeDescription;
    this.expertTypeDescription = aRow.expertTypeDescription;
  }
  roleEnrollmentDate: string;
  roleEnrollmentCode: string;
  fiscalAddress: string;
  associatedExperts: ExpertDetailEntity[];
  // fields related to the expert's OFFICE ADDRESS INFORMATION
  country: string;
  region: string;
  city: string;
  zipCode: string;
  toponym: string;
  houseNumber: string;
  officeAddress: string;
  subjectDenomination: string;
}

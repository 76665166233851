<div id="errors-messages">
  <!-- <pnc-floating-error-messages
  [blockingMsgs]="errorMessages"
  [warningMsgs]="warningMessages"
  [authoMsgs]="authMessages"></pnc-floating-error-messages> -->

  <pnc-form-message
    type="info"
    [messages]="infoMessages"
    *ngIf="infoMessages?.length >0"
    detail="infoMessages"
    data-qa="info-error-messages">
  </pnc-form-message>

  <pnc-form-message
    type="warning"
    [messages]="warningMessages"
    *ngIf="warningMessages?.length >0"
    detail="warningMessages"
    data-qa="warning-error-messages">
  </pnc-form-message>

  <pnc-form-message
    type="authorization"
    [messages]="authMessages"
    *ngIf="authMessages?.length >0"
    detail="authMessages"
    data-qa="autho-error-messages">
  </pnc-form-message>

  <pnc-form-message
    type="error"
    [messages]="errorMessages"
    *ngIf="errorMessages?.length >0"
    detail="validationError"
    data-qa="validation-error-messages">
  </pnc-form-message>
</div>

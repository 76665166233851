import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Outcome } from '@rgi/digital-claims-common-angular';
import { DriverLicenseResponse, RSDriverLicenseOutput } from '../../dsc-shared-model/dsc-model-driver-license';

@Injectable({
  providedIn: 'root',
})
export class DscDriverLicenseModalService {
  private baseApiUrl: string;
  urlDriverLicense: string;
  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.urlDriverLicense = 'claims/driverLicense';
  }

getDriverLicense(objectId: string): Observable<DriverLicenseResponse> {
  const url = `${this.baseApiUrl}/${this.urlDriverLicense}/${objectId}`;
  return this.httpClient.get<DriverLicenseResponse>(url);
}
saveDriverLicense(driverLicense: RSDriverLicenseOutput): Observable<Outcome> {
  const url = `${this.baseApiUrl}/${this.urlDriverLicense}`;
  return this.httpClient.post<Outcome>(url, driverLicense);
}

}

import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GISService} from '../../gis-service';
import {Variable} from '../../models/domain-models/variable';
import {VariablesModalComponent} from '../../variables/variables-modal/variables-modal.component';
import {VariablesService} from '../../variables/variables-service';

@Component({
  selector: 'mic-contract-variables-modal',
  templateUrl: './contract-variables-modal.component.html',
  styleUrls: ['./contract-variables-modal.component.scss']
})
export class ContractVariablesModalComponent extends VariablesModalComponent implements OnInit {

  constructor(
    protected variablesService: VariablesService,
    protected gisService: GISService,
    protected translate: TranslateService,
  ) {

    super(variablesService, gisService, translate);
  }

  ngOnInit() {
    this.validationMessages.length = 0;
    if (this.variablesType === null) {
      this.variablesType = this.variablesService.getLastSelectedVariablesType();
    }
    this.originVariables = JSON.parse(JSON.stringify(this.variablesService.getContractVariablesObject()));

    if (this.variables && this.variables.length > 0) {
      this.filterVariables(this.variables);
      this.loadVariables();
    } else {
      this.variablesService.getContractVariables().subscribe(
        (data) => {
          if (data) {
            this.filterVariables(data);
            this.loadVariables();
          }
        }
      );
    }

    /* Only at change of temporary variables. */
    const variablesChannelSubscription = this.variablesService.getContractVariablesObserable().subscribe(
      (data) => {
        if (data) {
          this.filterVariables(data);

          if (this.readyToBeClose) {
            this.eventPropagation.emit('destroy');
            if (this.componentRef && this.componentRef.destroy) {
              this.componentRef.destroy();
            }
            this.loadVariables();
          }
        }
      }
    );
    this.subscriptions.add(variablesChannelSubscription);
  }

  close() {
    /* reset the initial values */
    this.getUpdateVariablesObservable(this.originVariables).subscribe(
      data => {
        this.readyToBeClose = true;
        this.variablesService.setContractVariables(data);
      },
      err => {
        this.eventPropagation.emit('destroy');
        this.componentRef.destroy();
      });
  }

  onVariableChange(event: string) {
    this.updateErrorMessages();

    if (event === 'updateProvinceVariable') {
      this.updProvinceVariableValue(this.variables);
      return;
    }

    if (event !== 'confirm'
      && event !== 'updateDependentVariable' && event !== 'updateProvinceVariable') {
      return;
    }
    this.getUpdateVariablesObservable(this.variables).subscribe(
      (data) => {
        if (event === 'confirm') {
          this.readyToBeClose = true;
          this.variablesService.setContractVariables(data);
          return;
        }

        this.filterVariables(data);
        this.loadVariables();
      },
      (err) => {
        if (event === 'confirm') {
          this.eventPropagation.emit('destroy');
          this.componentRef.destroy();
        }
      }
    );
  }

  protected getUpdateVariablesObservable(variables: Array<Variable>) {
    return this.variablesService.updateContractVariables(variables);
  }
}

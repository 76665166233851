export class PaymentControl {
  idPaymentType?: string;
  cchecknumber?: string;
  ciban?: string;
  cholder?: string;
  creditCardNumber?: string;
  creditCardExpiredDate?: string;

  constructor(obj: PaymentControl) {
    if (obj.idPaymentType) {
      this.idPaymentType = obj.idPaymentType.toString();
    }
    if (obj.cchecknumber) {
      this.cchecknumber = obj.cchecknumber.toString();
    }
    if (obj.ciban) {
      this.ciban = obj.ciban.toString();
    }
    if (obj.cholder) {
      this.cholder = obj.cholder.toString();
    }
    if (obj.creditCardNumber) {
      this.creditCardNumber = obj.creditCardNumber.toString();
    }
    if (obj.creditCardExpiredDate) {
      this.creditCardExpiredDate = obj.creditCardExpiredDate.toString();
    }
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentFrequency} from '../../../../models/api-models/api-payment-frequency';
import {
  RegulationPremium,
  RegulationType
} from '../../../../models/pc-portfolio-models/regulation-models/pc-regulation-premium';
import {debounceTime, distinctUntilChanged, map, skip} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {
  RegulationPremiumResponse
} from "../../../../models/pc-portfolio-models/regulation-models/pc-regulation-premium-response";
import {ReIssueRegulationConfigEnum} from "../../../../enums/re-issue-regulation-premium-enum";
import {
  ReIssuePortfolioRegulationResourceService
} from '../../../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-regulation-resource.service';
import {State} from '@rgi/rx/state';


@Component({
  selector: 're-issue-proposal-regulation',
  templateUrl: './re-issue-proposal-regulation.component.html',
  styleUrls: ['./re-issue-proposal-regulation.component.css']
})
export class ReIssueProposalRegulationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() paymentFrequencyList: PaymentFrequency[];
  @Input() regulationPremiumResponse: RegulationPremiumResponse;

  @Input() set premiumAdjustmentConfig(value: number) {
    switch (value) {
      case ReIssueRegulationConfigEnum.UNCHEKED_DEFAULT_EDITABLE: {
        this.isFormVisible = true;
        this.isFormModifiable = true;
        this.isFormChecked = false;
        break;
      }
      case ReIssueRegulationConfigEnum.CHECKED_NOT_EDITABLE: {
        this.isFormVisible = true;
        this.isFormModifiable = false;
        this.isFormChecked = true;
        break;
      }
      case ReIssueRegulationConfigEnum.CHEKED_DEFAULT_EDITABLE: {
        this.isFormVisible = true;
        this.isFormModifiable = true;
        this.isFormChecked = true;
        break;
      }
    }
  }
  @Input() stateObs: Observable<State>;
  @Output() regulationPremiumChange = new EventEmitter<RegulationPremium>();
  @Output() regulationFormValid = new EventEmitter<boolean>();
  @Output() checkboxEmitter = new EventEmitter<boolean>();

  regulationForm: UntypedFormGroup;
  regulationTypesList: RegulationType[];
  isFormVisible: boolean;
  isFormModifiable: boolean;
  isFormChecked: boolean;
  private unsubscribe$ = new Subject<void>();
  state: State;

  constructor(
    private formBuilder: UntypedFormBuilder,
    protected regulationResourceService: ReIssuePortfolioRegulationResourceService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.stateObs.subscribe((state: State) => {
      this.state = state;
    });
    this.createForm();
    this.getDataFromState();
    this.subscribeToFormChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.regulationPremiumResponse) this.regulationPremiumResponse = changes.regulationPremiumResponse.currentValue;
  }

  private getDataFromState() {
    this.getAllRegulationTypes$(this.state).subscribe((st: any) => {
      this.regulationTypesList = st.regulationTypes;
      this.initializeForm();
      this.validateForm();
    });
  }

  private subscribeToFormChanges() {
    this.regulationForm.valueChanges
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
      skip(1)
    )
    .subscribe((formValues) => {
      if ((this.completeForm(formValues) || this.disabledForm(formValues))) this.updateRegulationPremium();
      this.validateForm();
    });
  }

  private completeForm(formValues: any): boolean {
    return formValues.minQuotePremium &&
      formValues.regulationType &&
      formValues.regulationDays &&
      formValues.regulationExpirationDate &&
      formValues.regulationFractioning &&
      formValues.regulation != null;
  }

  private disabledForm(formValues: any): boolean {
    return !formValues.regulation;
  }

  updateRegulationPremium() {
    let regulationPremium = new RegulationPremium(
      this.regulationForm.controls.minQuotePremium.value,
      this.regulationForm.controls.minQuotePremium.value,
      this.regulationForm.controls.regulationType.value,
      this.regulationForm.controls.regulation.value,
      this.regulationForm.controls.regulationDays.value,
      this.formatDate(this.regulationForm.controls.regulationExpirationDate.value),
      null,
      this.regulationForm.controls.regulationFractioning.value,
      null
    );
    if (this.regulationForm.valid) this.regulationPremiumChange.emit(regulationPremium);
  }

  private formatDate(date: string) {
    let formattedDate = null;
    if (date) formattedDate = this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
    return formattedDate;
  }

  private validateForm() {
    const formGroupControls = this.regulationForm.controls;
    let valid = true;
    Object.keys(formGroupControls).forEach((controlName: string) => {
      const control = formGroupControls[controlName];
      if (formGroupControls.regulation.value) {
        if (control.invalid || !control.value) {
          control.markAsDirty();
          control.updateValueAndValidity();
          valid = false;
        }
      }
    });
    this.regulationFormValid.emit(valid);
  }

  private createForm() {
    this.regulationForm = this.formBuilder.group({
      regulation: [{value: this.isFormChecked, disabled: !this.isFormModifiable}],
      regulationExpirationDate: [{value: null, disabled: !this.isFormChecked}],
      regulationFractioning: [{value: null, disabled: !this.isFormChecked}],
      regulationType: [{value: null, disabled: !this.isFormChecked}],
      regulationDays: [{value: null, disabled: !this.isFormChecked}],
      minQuotePremium: [{value: null, disabled: !this.isFormChecked}]
    });
  }

  private initializeForm() {
    if (this.regulationPremiumResponse) {
      const regulationPremium = this.regulationPremiumResponse.regulationPremium;
      const formControls = this.regulationForm.controls;

      this.setControlValue(formControls.regulation, regulationPremium.regulation);
      this.setControlValue(formControls.regulationExpirationDate, regulationPremium.regulationExpirationDate);
      this.setControlValueFromList(formControls.regulationFractioning, regulationPremium.regulationFractioning, this.paymentFrequencyList);
      this.setControlValueFromList(formControls.regulationType, regulationPremium.premiumRegulationType, this.regulationTypesList);
      this.setControlValue(formControls.regulationDays, regulationPremium.regulationDays);
      this.setControlValue(formControls.minQuotePremium, regulationPremium.minimumPremiumRegulationPercentage);

      this.regulationForm.controls.regulation.value ? this.enableForm() : this.disableForm();
    }
  }

  private setControlValue(control: AbstractControl, value: any) {
    if (value !== null) {
      control.setValue(value);
    }
  }

  private setControlValueFromList(control: AbstractControl, value: any, list: any[]) {
    if (value && list) {
      const selected = list.find(item => item.code === value.code);
      if (selected) {
        control.setValue(selected);
      }
    }
  }

  toggleForm() {
    !this.regulationForm.controls.regulation.value ? this.disableForm() : this.enableForm();
  }

  private enableForm() {
    this.setFormControlsState(true, Validators.required);
  }

  private disableForm() {
    this.setFormControlsState(false, null);
  }

  private setFormControlsState(enable: boolean, validator: any) {
    this.checkboxEmitter.emit(enable);
    Object.keys(this.regulationForm.controls).forEach((controlName: string) => {
      const control = this.regulationForm.controls[controlName];
      if (control !== this.regulationForm.controls.regulation) {
        if (enable) {
          control.enable();
          control.setValidators(validator);
        } else {
          control.disable();
          control.clearValidators();
        }
      }
    });
    this.validateForm();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAllRegulationTypes$(state: any): Observable<State> {
    return this.regulationResourceService.getRegulationTypes(state.resourceId).pipe(
      map((response: RegulationType[]) => {
        state.regulationTypes = response;
        return state;
      })
    );
  }
}

export enum ProposalStatusTypes {

  STATE_UNDEFINED = 'UNDEFINED',
  STATE_VALID_PROPOSAL = 'VALID_PROPOSAL',
  STATE_NOT_QUOTABLE_PROPOSAL = 'NOT_QUOTABLE_PROPOSAL',
  STATE_PROPOSED_TO_BE_AUTHORIZED = 'PROPOSED_TO_BE_AUTHORIZED',
  STATE_UNDEFINED_PROPOSAL = 'UNDEFINED_PROPOSAL',
  STATE_CANCELLED_PROPOSAL = 'CANCELLED_PROPOSAL',
  STATE_VALID_POLICY = 'VALID_POLICY',
  STATE_SUSPENDED_POLICY = 'SUSPENDED_POLICY',
  STATE_CANCELLED_POLICY = 'CANCELLED_POLICY',
  STATE_EXCLUDED_POLICY = 'EXCLUDED_POLICY',
  STATE_VALID_PROPOSAL_FOR_AMENDMENT = 'VALID_PROPOSAL_FOR_AMENDMENT',
  STATE_PROPOSAL_FOR_AMENDMENT_TO_BE_AUTHORIZED = 'PROPOSAL_FOR_AMENDMENT_TO_BE_AUTHORIZED',
  STATE_PURCHASED_PROPOSAL = 'PURCHASED_PROPOSAL',
  STATE_PROPOSAL_NOT_AUTHORIZED = 'PROPOSAL_NOT_AUTHORIZED',
  STATE_OFFERED_PROPOSAL = 'OFFERED_PROPOSAL',
  STATE_REVERSED_POLICY = 'REVERSED_POLICY'

}

import {ChangeDetectorRef, Component, Inject, Optional} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import {DefinitionPaymentTypes, PaymentTypeDefinition} from '../../models/postsales-operations-response.model';
import {AbsOperationComponent} from '../abs-operation-component/abs-operation.component';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifierService} from '@rgi/portal-ng-core';
import { PlcQuestService } from '../../services/plc-quest.service';
import { AuthService } from '../../services/auth.service';
import { AnagService } from '../../services/anag.service';
import { PV_TOKEN } from '../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-change-payment-mode',
  templateUrl: './change-payment-mode.component.html',
  styleUrls: ['./change-payment-mode.component.css']
})
export class ChangePaymentModeComponent extends AbsOperationComponent {

  protected operationDataKey = 'payment';
  isPaymentEditable = true;
  public paymentTypes: PaymentTypeDefinition[] = [];

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authorizationService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authorizationService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(response => {
        this.paymentTypes = ((response.definitions.paymentTypes as DefinitionPaymentTypes).definitionPaymentTypes).reverse();
        this.isPaymentEditable = (response.definitions.paymentTypes as DefinitionPaymentTypes).editable;
        if (!!response.data.operationData.data) {
          this.formGroup.get('payment').setValue(response.data.operationData.data, { emitEvent: false });
        }
      }),
    );
    this.formGroup.get('payment').valueChanges.subscribe(el => {
      this.errors = [];
    });
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      payment: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

}

import {RgiRxLocalStateManager, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {RgiRxLogger} from '@rgi/rx';
import {ConsultationListNavigationData, ConsultationListState} from './states/consultation-list-state';
import {of} from 'rxjs';
import {ConsultationListBusinessService} from '../services/business/consultation-list-business.service';
import {
  ConsultationContractDetailsNavigationData
} from './states/consultation-contract-details-state';


export class ConsultationListStateManagerService<T extends ConsultationListState> extends RgiRxLocalStateManager<T> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected logger: RgiRxLogger,
    private consultationListBusinessService: ConsultationListBusinessService


  ) {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newReIssuePolicyDataState();
    this.updateState$(of(this.initPolicyData(st))); // this update the state
  }


  newReIssuePolicyDataState(): T {
    return new ConsultationListState(this.activeRoute.key) as T;
  }

  initPolicyData(state: T) {
    const navData = this.activeRoute.getRouteData<ConsultationListNavigationData>();
    state.policies = navData.policies;
    state.userCode = navData.userCode;
    return state;
  }

  back() {
    this.consultationListBusinessService.remove(this.activeRoute.id);
  }
  goToNextPage(contractNumber:string, policies:any, user:string){
    const req: ConsultationContractDetailsNavigationData = {contractNumber:contractNumber, policies:policies, userCode:user};
    this.routingService.navigate('contract-details', req , this.activeRoute.id);
  }
}

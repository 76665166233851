import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { containerCacheServiceProvider } from '@rgi/digital-claims-common-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { RgiRxDatePickerModule, RgiRxFormElementsModule, RgiRxNativeDateTimeModule } from '@rgi/rx/ui';
import { OccurrenceDataModifyComponent } from './occurrence-data-modify.component';
import { DscComponentModule } from '../shared/dsc-component.module';
import { OccurrenceDataModifyService } from './state-service/occurrence-data-modify.service';
import { RgiRxPortalModule } from '@rgi/rx/portal';
import { cardOccurrenceDataModify } from './occurrence-card';
import { RgiRxRoutingModule } from '@rgi/rx/router';
import { OCCURRENCE_DATA_ROUTES } from './router-occurrence';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';


/*
all'interno del modulo gestisco il tipo di card associata al modulo "RgiRxPortalModule.forRoot([cardOccurrenceDataModify]),"
e le rotte che vengono esportate dal modulo "RgiRxRoutingModule.forRoot(OCCURRENCE_DATA_ROUTES)"
in questo modo non ho bisogno di registrare il modulo come veniva fatto precedentemente perché le rotte mi vengono direttamente
collegate alla sessione che voglio aprire (vedi su angular js quando viene creata una nuova sessione)
*/

@NgModule({
    declarations: [
        OccurrenceDataModifyComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        DscComponentModule,
        RgiRxI18nModule,
        RgiRxPortalModule.forRoot([cardOccurrenceDataModify]),
        RgiRxRoutingModule.forRoot(OCCURRENCE_DATA_ROUTES),
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
    exports: [
        OccurrenceDataModifyComponent
    ],
    providers: [
        containerCacheServiceProvider,
        OccurrenceDataModifyService
    ]
})
export class OccurrenceDataModifyModule {

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Package} from '../../models/domain-models/package';
import {ApiPremiumSection} from '../../models/api-models/api-premium-section';
import {CustomModalService} from '@rgi/portal-ng-common';
import {Unit} from '../../models/domain-models/unit';

@Component({
  selector: 'mic-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() recalculateAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updatePacket: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editPacketDetails: EventEmitter<Package> = new EventEmitter<Package>();

  @Input() packagesList: Array<Package>;
  @Input() packagesPremium: Map<string, ApiPremiumSection>;
  @Input() isMultiPacket = false;

  constructor(
    protected customModalService: CustomModalService
  ) {
  }

  ngOnInit() {
  }

  selectPacket(packet: Package) {
    packet.selection = !packet.selection;
    if (packet.selection && !this.isMultiPacket) {
      this.packagesList.forEach((item) => {
        if (item.code !== packet.code) {
          item.selection = false;
        }
      });
      this.updatePacket.emit(true);
    } else {
      packet.selection = true;
      this.eventPropagation.emit({eventName: 'alertMsg', message: 'Deve esserci almeno un pacchetto selezionato'});
    }
  }

  editVariablesModal(packet: Package) {
    this.editPacketDetails.emit(packet);
  }

  toggleUnit(unit: Unit, isPackageSelected: boolean) {
    if (isPackageSelected) {
      unit.selected = !unit.selected;
      this.updatePacket.emit(true);
    }
  }

  isPackageComplete(pack: Package) {
    let complete = true;
    if (pack.unitList) {
      pack.unitList.forEach(unit => {
        if (unit.selected && unit.variables) {
          unit.variables.forEach(variable => {
            if (variable.compulsory && (!variable.value || variable.value === '')) {
              complete = false;
            }
          });
        }
      });
    }
    return complete;
  }
}

import {AfterViewChecked, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {EventService} from '@rgi/rx';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {CALLBACK_JUMP, CallBackEvent} from '../group-policy-events/group-policy-event';
import {GroupPolicyIntegrationService} from '../group-policy-services/group-policy-integration.service';
import {GroupPolicyStateInquiry} from '../group-policy-state/group-policy-state';
import {
  GroupPolicyStateManagerInquiry
} from '../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {GpInquiryComponentInterface} from '../group-policy-resources/gp-inquiry-component-interface';
import {GPInquiryService} from '../group-policy-services/gp-inquiry.service';

@Component({
  selector: 'rgi-gp-group-policy-inquiry',
  templateUrl: './group-policy-inquiry.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyInquiryComponent extends RoutableComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Output() eventPropagation = new EventEmitter<any>();


  public stateInquiry: GroupPolicyStateInquiry;
  public proposalNumber: string;
  public inquiryComponentsMain: GpInquiryComponentInterface[];
  public inquiryComponentsSide: GpInquiryComponentInterface[];


  constructor(
    protected activeRoute: ActiveRoute,
    protected integrationService: GroupPolicyIntegrationService,
    protected stateManager: GroupPolicyStateManagerInquiry,
    protected eventService: EventService,
    protected gpInquiryService: GPInquiryService
  ) {
    super();
  }

  ngOnInit() {
    this.inquiryComponentsMain = this.gpInquiryService.getComponents().filter(c => !c.displayOnSide).sort(this.sortComponentByOrderFn());
    this.inquiryComponentsSide = this.gpInquiryService.getComponents().filter(c => c.displayOnSide).sort(this.sortComponentByOrderFn());
    this.stateManager.getState$().subscribe((nextState: GroupPolicyStateInquiry) => {
      this.stateInquiry = nextState;
    });
    this.eventService.listen<CallBackEvent>(CALLBACK_JUMP).subscribe(event => {
      if (event.event.idSession === this.activeRoute.id) {
        this.stateManager.initInquiryDetail();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.integrationService.addCssClass(this.id);
  }

  ngOnDestroy(): void {
    this.integrationService.removeCssClass(this.id);
    this.eventService.clear(CALLBACK_JUMP);
  }
  public close() {
    this.integrationService.closeSession(this.id);
  }
  getActiveRoute() {
    return this.activeRoute;
  }
  private sortComponentByOrderFn() {
    return (p1: GpInquiryComponentInterface, p2: GpInquiryComponentInterface) => p1.order - p2.order;
  }
}

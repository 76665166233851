import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { RoutingService, RoutingHostDirective } from '@rgi/portal-ng-core';
import { CacheService } from './cache.service';




@Component({
  selector: 'claims-external-portfolio',
  templateUrl: './external-portfolio.component.html',
  styleUrls: ['./external-portfolio.component.scss'],
  providers: [RoutingService]
})

export class ExternalPortfolioComponent implements AfterViewInit {
  @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;
  routes = null;


  constructor(
    private routingService: RoutingService,
    private cacheServ: CacheService,
    @Inject('searchComponentExt') searchComponent,
    @Inject('searchResultComponentExt') searchResultComponent,
    @Inject('detailsComponentExt') detailsComponent,
    @Inject('newComponent') newComponent,
  ) {
    this.routes = {
      search: searchComponent,
      searchResult: searchResultComponent,
      details: detailsComponent,
      new: newComponent
  };
  }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;
    console.log(this.cacheServ.getListExternalPolicy());
    if (this.cacheServ.getListExternalPolicy() && this.cacheServ.getListExternalPolicy().length > 0) {
      console.log('list ---> ');
      this.routingService.loadComponent(this.routes.searchResult);
    } else if (this.cacheServ.getNewPolicyClear()) {
      this.routingService.loadComponent(this.routes.new);
    } else {
      console.log('search ---> ');
      this.routingService.loadComponent(this.routes.search);
    }
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LpsData} from '../../../models/domain-models/parameters/policy-lps-data';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {Country} from '../../../models/domain-models/country';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioLpsDataResourceService {

  private CONTRACT_PATH = 'contract/';
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(private http: HttpClient,
              @Inject('environment') private apiConf: any) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  putLpsData(proposalId: string, lpsData: LpsData): Observable<LpsData> {
    return this.http.put<LpsData>(
      this.getLpsDataUri(proposalId),
      lpsData
    ).pipe(
      share()
    );
  }

  getLpsData(proposalId: string): Observable<LpsData> {
    return this.http.get<LpsData>(
      this.getLpsDataUri(proposalId)
    ).pipe(
      share()
    );
  }

  getLpsCountries(proposalId: string): Observable<Country[]> {
    return this.http.get<Country[]>(
      this.getLpsCountriesUri(proposalId)
    ).pipe(
      share()
    );
  }

  private getLpsDataUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      proposalId +
      '/lpsdata';
  }

  private getLpsCountriesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/lpscountries';
  }
}

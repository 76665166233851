import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PassproproDownloadService} from "./passpropro-download.service";
import {PdfModalComponent} from './pdf-modal/pdf-modal.component';
import {PanelModule} from "../panel/panel.module";
import {RgiRxModalModule} from "@rgi/rx/ui";


@NgModule({
    declarations: [PdfModalComponent],
    imports: [
        CommonModule,
        PanelModule,
        RgiRxModalModule
    ],
    providers: [PassproproDownloadService]
})
class DownloadModule {
}

export {
  DownloadModule,
  PassproproDownloadService,
  PdfModalComponent
}

<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels" (goToIthStep)="goToIthStep($event)"></re-issue-stepper>
<div *ngIf="stateManager.getState$() | async as st">
  <div class="container-fluid">
    <!--    <div class="row">-->
    <!--      <div class="card-title re-issue-card-styles col-sm-12 col-xs-12">-->
    <!--                <span class="menu-title">{{ 'Proposal Management' | translate }}-->
    <!--                  {{ st.proposal.contractNumber.proposal }}-->
    <!--                  - -->
    <!--                  {{ st.subject.nominative | lowercase | titlecase }}</span>-->
    <!--      </div>-->
    <!--      <img class="title-logo" src="assets/title-logo.png"/>-->
    <!--    </div>-->

    <div data-qa="policyMandatoryData">
      <div class="rgi-re-section-title" >
        <span class="rgi-re-header-icon rgi-ui-icon-clipboard-list"></span>
        <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.POLICY_MANDATORY_DATA</span>&nbsp;
      </div>
      <form [formGroup]="proposalManagementForm" class="rgi-ui-form-group rgi-ui-grid-2-xs-1">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel><span translate>RE_ISSUE.POLICY_NUMBER</span> </label>
            <input rgiRxInput formControlName="policyNumber" data-qa="policyNumber">
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field class="date-field">
            <div class="label-input-container">
              <label rgiRxLabel><span translate>RE_ISSUE.ISSUE_DATE</span></label>
              <div class="input-button-container">
                <input [rgiRxDateTimeInput]="issueDatePicker"
                       style="flex: 1"
                       data-qa="issueDate"
                       formControlName="issueDate"
                       selectMode="single"
                       rangeSeparator="-"
                       disabled="true"
                       placeholder="gg-mm-yyyy" #inputToTriggerIssueDate="rgiRxDateTimeInput">
                <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                        [rgiRxDateTimeTrigger]="inputToTriggerIssueDate"></button>
              </div>
            </div>
            <rgi-rx-date-time #issueDatePicker
                              pickerType="calendar"
                              [hour12Timer]="true"
            ></rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field class="date-field">
            <div class="label-input-container">
              <label rgiRxLabel><span translate>RE_ISSUE.EFFECTIVE_DATE</span></label>
              <div class="input-button-container">
                <input [rgiRxDateTimeInput]="effectiveDatePicker"
                       style="flex: 1"
                       data-qa="effectiveDate"
                       formControlName="effectiveDate"
                       selectMode="single"
                       rangeSeparator="-"
                       placeholder="gg-mm-yyyy" #inputToTriggerEffectiveDate="rgiRxDateTimeInput">
                <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                        [rgiRxDateTimeTrigger]="inputToTriggerEffectiveDate"></button>
              </div>
            </div>
            <rgi-rx-date-time #effectiveDatePicker
                              pickerType="calendar"
                              [hour12Timer]="true"
            ></rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>
      </form>
    </div>


    <div data-qa="meansOfPayment" class="row" *ngIf="st.meansOfPayment && st.meansOfPayment.meansOfPayment
            && st.meansOfPayment.meansOfPayment.debMeansOfPayment
            && st.meansOfPayment.meansOfPayment.debMeansOfPayment.length > 0">
      <div class="rgi-re-section-title" >
        <span class="rgi-re-header-icon rgi-ui-icon-payment"></span>
        <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.MEANS_OF_PAYMENT</span>&nbsp;
      </div>

      <form [formGroup]="paymentDebCredForm" >
        <div class="rgi-ui-grid-1" *ngIf="st.meansOfPayment && st.meansOfPayment.meansOfPayment
        && st.meansOfPayment.meansOfPayment.debMeansOfPayment
         && st.meansOfPayment.meansOfPayment.debMeansOfPayment.length > 0">
          <div class="rgi-ui-col">
            <rgi-rx-panel>
              <rgi-rx-panel-header><span translate>RE_ISSUE.DEBIT_MEAN_OF_PAYMENT</span>
                <select rgiRxNativeSelect (change)="selectedPayment()" formControlName="meanOfPaymentDeb"
                        data-qa="meanOfPaymentDeb" style="color:black">
                  <option value=""></option>
                  <option *ngFor="let meanOfPaymentDeb of st.meansOfPayment.meansOfPayment.debMeansOfPayment"
                          [ngValue]="meanOfPaymentDeb.meanOfPayment">{{meanOfPaymentDeb.meanOfPayment.description}}</option>
                </select>
              </rgi-rx-panel-header>
            </rgi-rx-panel>
            <form [formGroup]="paymentForm">
              <div class="rgi-ui-grid-2-xs-1" *ngIf="paymentSelect">
                  <div *ngFor="let paymentOpt of paymentOptions; let i=index"
                       class="rgi-ui-col">
                    <!-- TODO : MOVE ELEMENTS TYPE TO STANDALONE COMPONENT -->

                    <!-- ELEMENT TYPE => TEXT -->
                    <div *ngIf="paymentOpt.type === 'F'">
                      <rgi-rx-form-field>
                        <label rgiRxLabel>{{paymentOpt.label}}
                          <!--
                                                    <pnc-required required="true" *ngIf="paymentOpt.mandatory"></pnc-required>
                          -->
                        </label>
                        <input rgiRxInput [formControlName]="getOptName(paymentOpt.name)"
                               [attr.disabled]="paymentOpt.readOnly">
                      </rgi-rx-form-field>
                    </div>
                    <!-- ELEMENT TYPE => SELECT -->
                    <div *ngIf="paymentOpt.type === 'N' && meanOfPaymentSelected.creditCards.length === 0">
                      <rgi-rx-form-field>
                        <label rgiRxLabel>{{ paymentOpt?.label }}
                          <!--
                                                    <pnc-required required="true" *ngIf="paymentOpt?.mandatory"></pnc-required>
                          -->
                        </label>
                        <select rgiRxNativeSelect [formControlName]="getOptName(paymentOpt.name)">
                          <option [value]="-1"></option>
                          <option *ngFor="let value of paymentOpt?.values; index as i;" [value]="value">
                            {{ value?.description }}</option>
                        </select>
                      </rgi-rx-form-field>
                    </div>
                    <!-- ELEMENT TYPE => SELECT FOR CREDIT CARDS OPTIONS-->
                    <div *ngIf="paymentOpt.type === 'N' && meanOfPaymentSelected.creditCards.length > 0">
                      <rgi-rx-form-field>
                        <label rgiRxLabel>{{ paymentOpt?.label}}
                          <!--
                                                    <pnc-required required="true" *ngIf="paymentOpt?.mandatory"></pnc-required>
                          -->
                        </label>
                        <select rgiRxNativeSelect [formControlName]="getOptName(paymentOpt.name)">
                          <option [value]="-1"></option>
                          <option *ngFor="let value of meanOfPaymentSelected.creditCards; index as i;" [value]="value">
                            {{ value?.description }}</option>
                        </select>
                      </rgi-rx-form-field>
                    </div>
                    <!-- ELEMENT TYPE => DATE -->
                    <div *ngIf="paymentOpt.type === 'D'" >
                      <label rgiRxLabel>{{ paymentOpt?.label }}
                        <!--
                                                <pnc-required required="true" *ngIf="paymentOpt?.mandatory"></pnc-required>
                        -->
                      </label>
                        <input formControlName="{{getOptName(paymentOpt.name)}}"
                               type="text" pattern="^(0[1-9]|1[0-2])\/[0-9]{4}$" maxlength="7" placeholder="mm/yyyy">
                        <rgi-rx-date-time #effectiveDatePicker></rgi-rx-date-time>

                    </div>
                  </div>
              </div>
              <!-- TODO : PROJECT BUTTON - KEEP IN PRODUCT MODULE?
                 <div class="row mt-2">
                    <div class="btn-group col-sm-12 col-xs-12">
                      <button type="button" (click)="verifyBankAccountDetails()"
                              [disabled]="!paymentForm.valid"
                              [hidden] ="!paymentSelect"
                              class="btn btn-primary pull-left text-uppercase">{{"Validate" | translate}}</button>
                    </div>
                  </div> -->
            </form>
          </div>
          <div *ngIf="st.meansOfPayment && st.meansOfPayment.meansOfPayment
                && st.meansOfPayment.meansOfPayment.credMeansOfPayment
                && st.meansOfPayment.meansOfPayment.credMeansOfPayment.length > 0" class="rgi-ui-col">
            <div class="rgi-ui-grid">
              <div class="rgi-ui-col">
                <rgi-rx-panel>
                  <rgi-rx-panel-header><span translate>RE_ISSUE.CREDIT_MEAN_OF_PAYMENT</span>
                    <select rgiRxNativeSelect (change)="selectedCredPayment()" formControlName="meanOfPaymentCred"
                            data-qa="meanOfPaymentCred" style="color:black">
                      <option value=""></option>
                      <option *ngFor="let meanOfPaymentCred of st.meansOfPayment.meansOfPayment.credMeansOfPayment"
                              [ngValue]="meanOfPaymentCred.meanOfPayment">{{meanOfPaymentCred.meanOfPayment.description}}</option>
                    </select>
                  </rgi-rx-panel-header>
                </rgi-rx-panel>
                <form [formGroup]="refundForm">
                  <div class="rgi-ui-grid-2-xs-1" *ngIf="paymentRefundSelect">
                      <div *ngFor="let paymentOpt of creditOptions; let i=index"
                           class="rgi-ui-col">
                        <!-- TODO : MOVE ELEMENTS TYPE TO STANDALONE COMPONENT -->

                        <!-- ELEMENT TYPE => TEXT -->
                        <div *ngIf="paymentOpt.type === 'F'">
                          <rgi-rx-form-field>
                            <label rgiRxLabel>{{paymentOpt.label}}
                              <!--
                                                            <pnc-required required="true" *ngIf="paymentOpt.mandatory"></pnc-required>
                              -->
                            </label>
                            <input rgiRxInput [formControlName]="getOptName(paymentOpt.name)"
                                   [attr.disabled]="paymentOpt.readOnly">
                          </rgi-rx-form-field>
                        </div>
                        <!-- ELEMENT TYPE => SELECT -->
                        <div *ngIf="paymentOpt.type === 'N' && meanOfPaymentSelected.creditCards.length === 0">
                          <rgi-rx-form-field>
                            <label rgiRxLabel>{{ paymentOpt?.label }}
                              <!--
                                                        <pnc-required required="true" *ngIf="paymentOpt?.mandatory"></pnc-required>
                              -->
                            </label>
                            <select rgiRxNativeSelect [formControlName]="getOptName(paymentOpt.name)">
                              <option [value]="-1"></option>
                              <option *ngFor="let value of paymentOpt?.values; index as i;" [value]="value">
                                {{ value?.description }}</option>
                            </select>
                          </rgi-rx-form-field>
                        </div>
                        <!-- ELEMENT TYPE => SELECT FOR CREDIT CARDS OPTIONS-->
                        <div *ngIf="paymentOpt.type === 'N' && meanOfPaymentSelected.creditCards.length > 0">
                          <rgi-rx-form-field>
                            <label rgiRxLabel>{{ paymentOpt?.label}}
                              <!--
                                                        <pnc-required required="true" *ngIf="paymentOpt?.mandatory"></pnc-required>
                              -->
                            </label>
                            <select rgiRxNativeSelect [formControlName]="getOptName(paymentOpt.name)">
                              <option [value]="-1"></option>
                              <option *ngFor="let value of meanOfPaymentSelected.creditCards; index as i;" [value]="value">
                                {{ value?.description }}</option>
                            </select>
                          </rgi-rx-form-field>
                        </div>
                        <!-- ELEMENT TYPE => DATE -->
                        <div *ngIf="paymentOpt.type === 'D'" >
                          <label rgiRxLabel>{{ paymentOpt?.label }}
                            <!--
                                                    <pnc-required required="true" *ngIf="paymentOpt?.mandatory"></pnc-required>
                            -->
                          </label>
                          <input formControlName="{{getOptName(paymentOpt.name)}}"
                                 type="text" pattern="^(0[1-9]|1[0-2])\/[0-9]{4}$" maxlength="7" placeholder="mm/yyyy">
                          <rgi-rx-date-time #effectiveDatePicker></rgi-rx-date-time>

                        </div>
                      </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="contacts-container">
        <re-issue-contact [addressData]="addressData" [subject]="st.subject"
                          [contact]="st.contact" (updatePolicyAddress)="updatePolicyAddress($event)"
                          (policyContactErrorMessage)="checkPolicyContactValidity($event)">
        </re-issue-contact>
    </div>

    <div class="rgi-ui-grid">
      <div class="rgi-ui-col">
        <rgi-rx-snackbar tag="{{TAG_ERRORS}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_FORM_ERRORS}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="policyContactErrors"></rgi-rx-snackbar>
      </div>
    </div>
    <div class="rgi-ui-grid rgi-ui-grid-no-gutter">
      <div class="rgi-ui-col btn-group">
        <div class="story-grid-column-content">
          <button rgi-rx-button color="secondary"  class="width100 text-uppercase" (click)="back()" data-qa="back" [disabled]="isFromExternalCard">
            <span translate>RE_ISSUE.BACK</span></button>
        </div>
      </div>
      <div class="rgi-ui-col btn-group">
        <div class="story-grid-column-content">
          <button  rgi-rx-button (click)="issue()"  class="width100 text-uppercase" color="primary"
                  data-qa="issue" [disabled]="!!policyContactMessages">
            <span translate>RE_ISSUE.ISSUE</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

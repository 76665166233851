<div style="font-family: 'DINProRegular' !important">
  <rgi-rx-panel style="margin-top:6%;display: inline-block; ">
    <rgi-rx-panel-header [closeable]="true"
                         (onClose)="onCloseModalEditDocument(); ">
      <span style="font-family: DINProRegular, Arial, sans-serif !important">{{ '_CLAIMS_._EDIT_DOCUMENT' | translate }}</span>
    </rgi-rx-panel-header>
    <div
    style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold'">
      <!--DENOMINATION INVOLVED PARTY-->
      <!--party denomination-->
      <div class="col-xs-1">

      </div>

      <div class="col-xs-9" style="padding-left: 3vh; font-family: DINProRegular, Arial, sans-serif !important">
        <div class="col-xs-12" *ngIf="this.utilityService.getSysPropShowParties()"
             style="height: 8vh; display: flex; align-items: center; border-bottom: 1px solid darkgrey; font-size: 28px">
          <span class="circle" style="scale: 0.8">
                  <span class="rgifont rgi-user"
                        style="color: white; scale: 1.4 ; position: relative; top:14px; left:15px"></span>
          </span>
          <span style="margin-left: 2vh" *ngIf="!party.subject.surname">{{party.subject.designation}}</span>
          <span style="margin-left: 2vh" *ngIf="party.subject.surname">{{party.subject.givenName}} {{party.subject.surname}}</span>
        </div>
        <!--DOCUMENT INFO-->
        <div class="col-xs-12" style="font-weight: 700; height: 8vh; border-bottom: 1px solid darkgrey;">
          <span style="padding:1vh; scale:1.4; font-size: 28px;" class="rgifont rgi-doc-file-format"></span>  {{document.fileName}}
        </div>
        <form [formGroup]="editForm" style="margin-bottom: 2vh;">
          <!--CAMBIO ATTORE-->
          <div class="col-xs-12" *ngIf="this.utilityService.getSysPropShowParties()">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._CHANGE_PARTY' | translate }}</label>
              <select rgiRxNativeSelect formControlName="party">
                <option
                  [value]="this.party.identifier">
                  {{party.subject.surname ? party.subject.givenName + ' ' + party.subject.surname : party.subject.designation}}
                </option>
                <option *ngFor="let pt of claimParties" [value]="pt.identifier">
                  {{pt.subject.surname ? pt.subject.givenName + ' ' + pt.subject.surname : pt.subject.designation}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>

          <!--CATEGORIA -  REQUIRED-->
          <div class="col-xs-12">
            <rgi-rx-form-field>
              <label style="font-family: DINProRegular, Arial, sans-serif !important"
                     rgiRxLabel>{{ '_CLAIMS_._SELECT_CATEGORY' | translate }}</label>
              <select rgiRxNativeSelect formControlName="category" (change)="handleCategorySelectionChange($event)" required>
                <option
                  [value]="this.category?.elementId">{{this.category.elementDescription}}</option>
                <option *ngFor="let cat of this.documentCategories"
                        [value]="cat.elementId">{{cat.elementDescription}}</option>
              </select>
            </rgi-rx-form-field>
          </div>

          <!--SOTTOCATEGORIA - REQUIRED-->
          <div class="col-xs-12"
               *ngIf="category.subcategories && category.subcategories.length > 0">
            <rgi-rx-form-field>
              <label rgiRxLabel style="font-family: DINProRegular, Arial, sans-serif !important">
                {{ '_CLAIMS_._SELECT_SUBCATEGORY' | translate }}</label>
              <select rgiRxNativeSelect formControlName="subcategory" required>
                <option *ngIf="this.subcategory != null"
                        [value]="this.subcategory.elementId">{{this.subcategory.elementDescription}}</option>
                <!--2 cases, subcat or no subcat-->
                <option *ngFor="let subcat of this.category.subcategories" [value]="subcat.elementId">
                  {{subcat.elementDescription}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <!--NOTE-->
          <div class="col-xs-12">
            <label style="font-size: 14px; color:#555555; font-family: DINProRegular, Arial, sans-serif !important"
                   rgiRxLabel>{{ '_CLAIMS_._NOTES' | translate }} </label>
            <input type="text" class="form-control" name="uploadNote" formControlName="uploadNote"
                   attr.data-qa="documentupload-upload-note-input">
          </div>
          <!--SUBTITLE-->
          <div class="col-xs-12">
            <label style="font-size: 14px; color:#555555; font-family: DINProRegular, Arial, sans-serif"
                   rgiRxLabel>{{ '_CLAIMS_._SUBTITLE' | translate }}</label>
            <input type="text" class="form-control" name="subtitle" formControlName="subtitle"
                   attr.data-qa="documentupload-subtitle-input">
          </div>
          <!--TIPO BENE COLPITO-->
          <div class="col-xs-12">
            <rgi-rx-form-field>
              <label rgiRxLabel
                     style="font-family: DINProRegular, Arial, sans-serif !important">{{ '_CLAIMS_._ASSET_TYPE' | translate }}</label>
              <!--                [selectedIndex]="this.document.assetType"-->
              <select rgiRxNativeSelect formControlName="assetType">
                <option value="1">{{ '_CLAIMS_._VEICHLE' | translate }}</option>
                <option value="2">{{ '_CLAIMS_._PEOPLE' | translate }}</option>
                <option value="3">{{ '_CLAIMS_._THINGS' | translate }}</option>
              </select>
            </rgi-rx-form-field>
          </div>

          <!--if the category requires it, show reception date required-->
          <div class="col-xs-6" style="margin-bottom: 1vh" *ngIf="this.isReceptionDateForLegal">
            <form [formGroup]="receiptDateForm">
              <rgi-rx-form-field>
                <label rgiRxLabel>{{ '_CLAIMS_._RECEIIPT_DATE' | translate }}</label>
                <rgi-rx-date-picker

                  [openOnClick]="true"
                  [showRemoveButton]="true"
                >
                  <input [rgiRxDateTimeInput]="picker2"
                         formControlName="receiptDatePickerFormField"
                         [max]="getMaxDay()"
                         [selectMode]="'single'"
                         (dateTimeChange)="onDateTimeChange($event)"
                         (dateTimeInput)="onDateTimeChange($event)"
                         required
                  >
                </rgi-rx-date-picker>
                <rgi-rx-date-time #picker2

                                  [startView]="'month'"
                                  [pickerType]="'calendar'"
                ></rgi-rx-date-time>
              </rgi-rx-form-field>
            </form>
          </div>

          <!--EXTERNAL VISIBILITY-->
          <div class="col-xs-12" style="font-family: DINProRegular, Arial, sans-serif !important">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._EXTERNAL_VISIBILITY' | translate }}</label>
              <input type="checkbox" [value]="document.externalVisibility" rgiRxInput formControlName="externalVisibility">
            </rgi-rx-form-field>
          </div>


          <!--SEND TO EBDS-->
          <!--              <div class="col-xs-12">
                          <rgi-rx-form-field>
                            <label rgiRxLabel>{{'Send to EBDS' | translate}}</label>
                            <input type="checkbox" [value]="document.toSendEBDS" rgiRxInput formControlName="toSendEBDS">
                          </rgi-rx-form-field>
                        </div>-->
        </form>
      </div>
    </div>
    <rgi-rx-panel-footer style="display: inline; ">
      <!-- [disabled]="!this.uploadedFiles.length && !this.editForm.controls.subcategory"-->
      <button rgi-rx-button color="primary"
              style="margin-top:4vh ; font-family: DINProRegular, Arial, sans-serif !important"
              [disabled]="
              (this.isReceptionDateForLegal && !this.isReceptionDateValid) ||
              (this.category.subcategories && this.category.subcategories.length > 0 &&
              !this.editForm.controls.subcategory.value || this.editForm.controls.subcategory.value === '')"
              (click)="onConfirmEditDocument(document.id)">{{ '_CLAIMS_._BUTTONS_._MODIFY' | translate }}</button>
    </rgi-rx-panel-footer>
    <!--DELETE BUTTON-->
  </rgi-rx-panel>
</div>

import {map, mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {
  ReIssuePortfolioProposalService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal.service';
import {SaveProposalResponse} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';
// tslint:disable-next-line:max-line-length
import {
  ReIssuePortfolioWarningsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-warnings-resource.service';
import {Warning} from '../../models/domain-models/warning';
import { ReIssuePortfollioBondsResourceService } from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-bond-resource.service';
import {ReIssueSummaryState} from '../re-issue-state-manager/re-issue-summary.state';

@Injectable({
  providedIn: 'root'
})
export class ReIssueSummaryBusinessService<T extends ReIssueSummaryState> {
  protected readonly POST_QUOTATION_WARNING_LEVEL = 'POST_QUOTATION';

  constructor(
    protected portfolioProposalService: ReIssuePortfolioProposalService,
    protected portfolioWarningsResourceService: ReIssuePortfolioWarningsResourceService,
    protected reIssuePortfollioBondsResourceService : ReIssuePortfollioBondsResourceService
  ) {
  }

  getProposal(state: T): Observable<T> {
    return this.portfolioProposalService.getProposal(state.resourceId).pipe(
      mergeMap(proposal => {
        state.proposal = proposal;
        state.authorize = proposal.authorize;
        state.proposalStatus.status = parseInt(proposal.contractStatus.code)
        state.proposalStatus.description = proposal.contractStatus.description
        return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.POST_QUOTATION_WARNING_LEVEL);
      }),
      map(warnings => {
        state.warnings = this.getBlockingWarnings(warnings);
        state.nonBlockingWarnings = this.getNonBlockingWarnings(warnings);
        return state;
      })
    );
  }

  getNonBlockingWarnings(warnings: any): Array<Warning> {
    const nonBlockingWarnings = new Array<Warning>();

    warnings.forEach((warning: Warning) => {
      if (warning.warningMessage) {
        nonBlockingWarnings.push(warning);
      }
    });

    return nonBlockingWarnings;
  }

  getBlockingWarnings(warnings: any): Array<Warning> {
    const blockingWarnings = new Array<Warning>();

    warnings.forEach((warning: Warning) => {
      if (warning.blockingMessage || warning.authorizationMessage) {
        blockingWarnings.push(warning);
      }
    });

    return blockingWarnings;
  }

  saveProposal(state: T): Observable<T> {
    return this.portfolioProposalService.saveProposal(state.resourceId, this.constructSaveProposalRequestBody(state)).pipe(
      map((proposalResponse: SaveProposalResponse) => {
        state.proposal = proposalResponse.proposal;
        return state;
      })
      // map((res: ContractNumber) => {
      //   state.contractNumber = res;
      //   return state;
      // })
    );
  }

  saveAuthorization(state: T): Observable<T> {
    return this.portfolioProposalService.saveAuthorization(state.resourceId, this.constructSaveProposalRequestBody(state)).pipe(
      map((proposalResponse: SaveProposalResponse) => {
        state.proposal = proposalResponse.proposal;
        return state;
      })
      // map((res: ContractNumber) => {
      //   state.contractNumber = res;
      //   return state;
      // })
    );
  }

  constructSaveProposalRequestBody(state: T): any {
    return {
      additionalLabels: null,
      extensions: null
    };
  }



  putBonds(state: T, bondData): Observable<T> {
    return this.reIssuePortfollioBondsResourceService.putBonds(state.resourceId, bondData).pipe(
      mergeMap(proposal => {
        return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.POST_QUOTATION_WARNING_LEVEL);
      }),
      map(warnings => {
        return state;
      })
    );
  }

}

<div class="ac_cont_page">
    <div class="ac_cont_sez">
        <div class="error-card-home" *ngIf="authorizationDetail?.issueDate || authorizationDetail?.issueUser">
            <span type="error">
                <div class="content-error validate-warning">
                    <span class="rgifont rgi-exclamation-circle"></span>
                    <span translate>_AUTH_._OPERATION_WAS_ISSUED_ON</span><span> {{ authorizationDetail.issueDate | date:'dd/MM/yyyy' }} </span><span
                        translate>_AUTH_._BY_USER</span><span> {{ authorizationDetail.issueUser }}</span>
                </div>
            </span>
        </div>
        <div class="ac_cont_titsez ac-sub-section-title-color" *ngIf="authorizationDetail?.requestType?.description">
            <h2 translate>{{ authorizationDetail?.requestType?.description }}</h2>
        </div>

        <div class="ac_cont_titsez nmf_cont_titsez_color">
            <span class="header-icon rgifont rgi-dettagli_tecnici ac_font_size"></span>
            <h1 translate>_AUTH_._REQUEST_DETAIL</h1>
        </div>
        <!-- ATUHTORIZATION REQUEST DATA ----------------------------------------------------------------------------->
        <div class="ac_cont_dati">
            <div class="nmf_cont_riga" *ngFor="let requestDataRow of authorizationRequestDataRows">
                <div class="ac_cont_single_data nmf_cont_single_data_color"
                     *ngFor="let data of requestDataRow; index as i; trackBy: genericElementsTrackByFn">
                    <span *ngIf="!data?.isDate">{{ data?.label | translate }}<b> {{ data?.value }}</b></span>
                    <span *ngIf="data?.isDate">{{ data?.label | translate }}<b>
                    {{ data?.value | date:'dd/MM/yyyy h:mm:ss' }}</b></span>
                </div>
            </div>
        </div>
        <div class="ac_cont_titsez ac-sub-section-title-color">
            <h2 translate>_AUTH_._REQUEST_STATUS</h2>
        </div>
        <div class="ac_cont_dati">
            <div class="nmf_cont_riga" *ngFor="let statusDataRow of statusDataRows">
                <div class="ac_cont_single_data nmf_cont_single_data_color"
                     *ngFor="let data of statusDataRow; index as i; trackBy: genericElementsTrackByFn">
                    <span *ngIf="!data?.isDate">{{ data?.label | translate }}<b> {{ data?.value }}</b></span>
                    <span *ngIf="data?.isDate">{{ data?.label | translate }}<b>
                    {{ data?.value | date:'dd/MM/yyyy h:mm:ss' }}</b></span>
                </div>
            </div>
        </div>
        <!-- REQUEST OPERATION DATA----------------------------------------------------------------------------->
        <div *ngIf="requestOperationDataRows && requestOperationDataRows.length > 0">
            <div class="ac_cont_titsez ac-sub-section-title-color">
                <h2 translate>_AUTH_._REQUEST_OPERATION</h2>
            </div>
            <div class="ac_cont_dati">
                <div class="nmf_cont_riga" *ngFor="let requestOperationDataRow of requestOperationDataRows">
                    <div class="ac_cont_single_data nmf_cont_single_data_color"
                         *ngFor="let data of requestOperationDataRow; index as i; trackBy: genericElementsTrackByFn">
                        <span *ngIf="!data?.isDate">{{ data?.label | translate }}<b> {{ data?.value }}</b></span>
                        <span *ngIf="data?.isDate">{{ data?.label | translate }}<b>
                        {{ data?.value | date:'dd/MM/yyyy' }}</b></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="ac_cont_titsez ac-sub-section-title-color" *ngIf="!authorizationDetail?.policyNumber">
            <h2>{{ '_AUTH_._PROPOSAL_NUMBER' | translate }}: {{ authorizationDetail?.proposalNumber }}</h2>
        </div>
        <div class="ac_cont_titsez ac-sub-section-title-color" *ngIf="authorizationDetail?.policyNumber">
            <h2>{{ '_AUTH_._POLICY_NUMBER' | translate }}: {{ authorizationDetail?.policyNumber }}</h2>
        </div>
        <div class="ac_cont_dati">
            <div class="nmf_cont_riga" *ngFor="let proposalDataRow of proposalDataRows">
                <div class="ac_cont_single_data nmf_cont_single_data_color"
                     *ngFor="let data of proposalDataRow; index as i; trackBy: genericElementsTrackByFn">
                    <span>{{ data?.label | translate }}<b> {{ data?.value }}</b></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="accordion-container">
    <rgi-rx-expansion-panel #first rgi-rx-qa="warnings-panel">
        <div rgi-rx-expansion-panel-header>
            <div rgi-rx-expansion-panel-label>
                <span class="rgifont"></span>
                <span data-qa="panel-button" class="rgifont"></span>
                <span class="header-icon rgi-ac rgi-ac-bell nfm_font_size"></span>
                <span class="panel-name" translate>_AUTH_._WARNINGS</span>
            </div>
        </div>
        <ac-auth-messages [warnings]="authorizationDetail?.warnings"></ac-auth-messages>
    </rgi-rx-expansion-panel>
</div>
<div class="accordion-container">
    <rgi-rx-expansion-panel #first rgi-rx-qa="history-panel">
        <div rgi-rx-expansion-panel-header>
            <div rgi-rx-expansion-panel-label>
                <span class="rgifont"></span>
                <span data-qa="panel-button" class="rgifont"></span>
                <span class="header-icon rgi-ac rgi-ac-clock nfm_font_size"></span>
                <span class="panel-name" translate>_AUTH_._HISTORY</span>
            </div>
        </div>
        <ac-auth-history [historyList]="authorizationDetail?.events"></ac-auth-history>
    </rgi-rx-expansion-panel>
</div>
<div class="accordion-container" *ngIf="authorizationDetail?.workflowState.code !== '4' && writePermission">
    <rgi-rx-expansion-panel #first rgi-rx-qa="request-status-panel">
        <div rgi-rx-expansion-panel-header>
            <div rgi-rx-expansion-panel-label>
                <span class="rgifont"></span>
                <span data-qa="panel-button" class="rgifont"></span>
                <span class="header-icon rgi-ac rgi-ac-semaphore nfm_font_size"></span>
                <span class="panel-name" translate>_AUTH_._REQUEST_STATUS_VARIATION</span>
            </div>
        </div>
        <ac-auth-request [authorizationDetail]="authorizationDetail" [isSubcard]="isSubCard" (back)="back($event)"
                         [documentsValidated]="documentsValidated"></ac-auth-request>
    </rgi-rx-expansion-panel>
</div>
<div class="nmf_cont_moredetails" *ngIf="contractId && showDocuments">
    <rgi-rx-expansion-panel #first rgi-rx-qa="documents-panel">
        <div rgi-rx-expansion-panel-header>
            <div rgi-rx-expansion-panel-label>
                <span class="rgifont"></span>
                <span data-qa="panel-button" class="rgifont"></span>
                <span class="header-icon rgi-ac rgi-ac-clock nfm_font_size"></span>
                <span class="panel-name" translate>Documents</span>
            </div>
        </div>
        <pcm-documents [contractId]="contractId" validationDocEnabled="true"
                       (eventModifiedDocument)="verifyDocuments($event)"
                       (startDocument)="verifyDocuments($event)"
                       [documents]="documents"></pcm-documents>
    </rgi-rx-expansion-panel>
</div>
<div *ngIf="writePermission && !isSubCard" class="rgi-ui-panel-footer">
    <div class="btn-group rgi-auth-w-100" *ngFor="let button of buttons; index as i">
        <button *ngIf="i === 0" rgi-rx-button class="btn" color="secondary" (click)="doAction(button)"
                [attr.data-qa]="button">{{ transformButtonLabelIfNecessary(button) | translate | uppercase }}
        </button>
        <button *ngIf="i > 0" rgi-rx-button class="btn" color="primary" (click)="doAction(button)"
                [attr.data-qa]="button">{{ transformButtonLabelIfNecessary(button) | translate | uppercase }}
        </button>
    </div>
</div>




<div class="grid-item card card-height-normal card-main">
  <div class="modal-content">
    <div class="modal-header">
      <span class="rgifont rgi-dettagli_tecnici"></span>
      {{ 'Historical Erosion' | translate }}
      <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
    </div>
    <div class="modal-body" style="padding-top: 5px">
      <table class="table-responsive table coreDT" style="display: block;">
        <thead>
        <tr class="head-result thDataTable">

          <td style="width:12%; font-size: 12px;">
            <span attr.data-qa="historyErosionD-policy-label">{{ '_CLAIMS_._POLICY_NUMBER' | translate }}</span>
          </td>

          <td style="width:10%; font-size: 12px;">
            <span attr.data-qa="historyErosionD-masterpolicy-label">{{ '_CLAIMS_._MASTER_POLICY_NUMBER' | translate }}</span>
          </td>

          <td style="width:20%; font-size: 12px;">
            <span attr.data-qa="historyErosionD-risk-label">{{ '_CLAIMS_._RISK' | translate }}</span>
          </td>

          <td style="width:13%; font-size: 12px;">
            <span attr.data-qa="historyErosionD-claimnumber-label">{{ '_CLAIMS_._CLAIM_' | translate }}</span>
          </td>

          <td style="width:10%; font-size: 12px;">
            <span attr.data-qa="historyErosionD-movDescr-label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</span>
          </td>

          <td style="width:10%; font-size: 12px;">
            <span attr.data-qa="historyErosionD-insertDate-label">{{ '_CLAIMS_._INSERT_DATE' | translate }}</span>
          </td>
        </tr>

        </thead>
        <tbody>
        <tr class="table-row">

          <td style="width:16%; font-size: 12px;">
            {{ historyDetail.policyNumber }}
          </td>

          <td style="width:10%; font-size: 12px;">
            {{ historyDetail.applicationNumber }}
          </td>

          <td style="width:28%; font-size: 12px;">
            {{ historyDetail.unitDescription }}
          </td>

          <td style="width:10%; font-size: 12px;">
            {{ historyDetail.claimNumber }}
          </td>

          <td style="width:20%; font-size: 12px;">
            {{ historyDetail.movementDescr }}
          </td>

          <td style="width:10% ; font-size: 12px;">
            {{ historyDetail.insDate | date:'dd/MM/yyyy' }}
          </td>
        </tr>

        </tbody>
      </table>
      <hr>
      <table class="table-responsive table" style="display: block;">
        <tbody style="height: 330px; display: inline-block; overflow: auto;">
          <tr>
            <td>
              <div class="container-fluid table-white">
                <h6 class="text-info" >{{ '_CLAIMS_._POLICY' | translate }}</h6>
                <div class="row">
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{ '_CLAIMS_._VALIDITY_START_DATE' | translate }}</label>
                    <div class="col-sm-6">{{ (historyDetail.validityStartDate | date:'dd/MM/yyyy') || '-' }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" translate>{{ '_CLAIMS_._VALIDITY_END_DATE' | translate }}</label>
                    <div class="col-sm-6">{{ (historyDetail.validityEndDate | date:'dd/MM/yyyy') || '-' }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{ '_CLAIMS_._POLICY_ID' | translate }}</label>
                    <div class="col-sm-6">{{ historyDetail.policyId || '-' }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{ '_CLAIMS_._POLICY_ID_START_DATE' | translate }}</label>
                    <div class="col-sm-6">{{ (historyDetail.insuredStartDate | date:'dd/MM/yyyy') || '-' }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{ '_CLAIMS_._POLICY_ID_END_DATE' | translate }}</label>
                    <div class="col-sm-6">{{ (historyDetail.insuredEndDate | date:'dd/MM/yyyy') || '-' }}</div>
                  </div>
                </div>

                <h6 class="text-info" >{{'_CLAIMS_._CLAIM_EXCESS_POLICY_VALUE' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_CAP_INDICATOR' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumCapClaim) }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_CAP_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.capClaimAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_EXCESS_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.excessClaimAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_EXCESS_ADVANCE' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceExcessCl) }}</div>
                  </div>
                </div>

                <h6 class="text-info">{{'_CLAIMS_._CLAIM_EXCESS_RESIDUE' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6">{{'_CLAIMS_._CLAIM_EXCESS_LIQUIDATOR_ADVANCE' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceExcClLiq) }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6"> {{'_CLAIMS_._CLAIM_CAP_AMOUNT_RESIDUE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.resCapClAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-4 col-xs-12 dl-element">
                    <label class="col-sm-6" translate>{{'_CLAIMS_._CLAIM_EXCESS_AMOUNT_ADVANCE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.excessClResAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                </div>


                <h6 class="text-info" >{{'_CLAIMS_._FIRST_SIR_POLICY_VALUES' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_CAP_INDICATOR' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumCapClaim) }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_CAP_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.capClaimAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._SIR_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.firstSirAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_.FIRST_SIR_ADVANCE' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceSir) }}</div>
                  </div>
                </div>

                <h6 class="text-info" >{{'_CLAIMS_.FIRST_SIR_RESIDUE' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_.FIRST_SIR_ADVANCE' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceSirLiq) }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_CAP_AMOUNT_RESIDUE' | translate}} </label>
                    <div class="col-sm-6">{{ (historyDetail.resCapClAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._FIRST_SIR_RESIDUE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.firstSirResidualAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                </div>

                <h6 class="text-info" >{{'_CLAIMS_._EXCESS_AGGREGATE_POLICY_VALUE' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._AGGREGATE_CAP_INDICATOR' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumCapAggregate) }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._AGGREGATE_CAP_AMOUNT' | translate}} </label>
                    <div class="col-sm-6">{{ (historyDetail.capAggregAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._EXCESS_AGGREGATE_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.excessAggrAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._EXCESS_AGGREGATE_ADVANCE' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceExcessAggr) }}</div>
                  </div>
                </div>

                <h6 class="text-info" >{{'_CLAIMS_._EXCESS_AGGREGATE_RESIDUE' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceExcAggrLiq) }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._AGGREGATE_CAP_AMOUNT_RESIDUE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.resCapAggrAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._EXCESS_AGGREGATE_AMOUNT_RESIDUE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.excAggrResAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                </div>

                <h6 class="text-info" >{{'_CLAIMS_._SECOND_SIR_POLICY_VALUES' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._AGGREGATE_CAP_INDICATOR' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumCapAggregate) }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._AGGREGATE_CAP_AMOUNT' | translate}} </label>
                    <div class="col-sm-6">{{ (historyDetail.capAggregAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._SIR_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.secondSirAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._EXCESS_SECOND_SIR' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceSir) }}</div>
                  </div>
                </div>

                <h6 class="text-info" >{{'_CLAIMS_._SECOND_SIR_RESIDUE' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR' | translate}}</label>
                    <div class="col-sm-6">{{ visual(historyDetail.enumAdvanceSirLiq) }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._AGGREGATE_CAP_AMOUNT_RESIDUE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.resCapAggrAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._SECOND_SIR_RESIDUE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.secondSirResidualAmount | currency:'EUR':true) || '-' }}</div>
                  </div>
                </div>

                <h6 class="text-info" >{{'_CLAIMS_._CLAIM_DATA' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._OCCURRENCE_DATE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.incidentDate | date:'dd/MM/yyyy') || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._TYPE_OF_LOSS' | translate}}</label>
                    <div class="col-sm-6">{{ historyDetail.tolDescription || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._NOTIFY_DATE' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.notifyDate | date:'dd/MM/yyyy') || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._SEVERITY_CODE' | translate}}</label>
                    <div class="col-sm-6">{{ historyDetail.severityCode || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._DAMAGED' | translate}}</label>
                    <div class="col-sm-6">{{ historyDetail.partyClaiming || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._PHISICAL_INJURY_CODE' | translate}}</label>
                    <div class="col-sm-6">{{ historyDetail.injuryCode || '-' }}</div>
                  </div>
                </div>
                <h6 class="text-info" >{{'_CLAIMS_._SETTLEMENT' | translate}}</h6>
                <div class="row">
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._INSURED_SETTLEMENT_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.amountLiqInsured | currency:'EUR':true) || '-' }}</div>
                  </div>
                  <div class="col-sm-6 col-xs-12 dl-element">
                    <label class="col-sm-6" >{{'_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate}}</label>
                    <div class="col-sm-6">{{ (historyDetail.amountLiqCompany | currency:'EUR':true) || '-' }}</div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>

export class ExternalPolicyWarrenty {
 public branch: string;
 public branchDescription: string;
 public description: string;
 public premium: number;
 public codTerm: string; // codice clausola
 public riskObject: string;
 public policyCeiling: number;

 constructor() { }
}

import { Serialized18n } from '@rgi/rx/i18n';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_LIFE_PPEVO_EN: Serialized18n = {
 /* "Il Contraente è una persona": 'The policyholder is',
 "Data di nascita": 'Date of birth',
 'Tipologia cliente': 'Customer type',
 'La sua consistenza patrimoniale complessiva è:': 'Their total financial assets are:',
 'Il suo reddito netto annuo è:': 'Their annual net income is:',
 'La sua capacità media di risparmio annuo, valutata… degli impegni finanziari già in essere è pari a:': 'Their average annual savings capacity, considering existing financial commitments, is:',
 'I suoi impegni finanziari mensili sono:': 'Their monthly financial commitments are:',
 "E' intestatario di un mutuo o di un finanziamento?": 'Are they the holder of a mortgage or a loan?' */
}

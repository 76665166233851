import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function acceptFileTypeValidator(accept: string[]): ValidatorFn {
  const validator =  (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const file = control.value as File;
    return !accept.includes(file.type) ? {rgiRxAcceptFileType: {
        rgiRxAcceptFileType: accept.join(' ,')
      }} : null;
  };
  return validator;
}


export class RgiRxValidators {
  static acceptFileType(accept: string[]) {
    return acceptFileTypeValidator(accept);
  }
}

import {RgiRxHttpErrorFilterType, RgiRxHttpErrorSeverity} from '@rgi/rx/http';
import {FactoryProvider, InjectionToken, Optional} from '@angular/core';
import {merge} from 'lodash';
/**
 * Portal configuration object
 */
export interface PortalConfig {
  /**
   * The name of the AngularJS root module
   * @default app
   */
  module?: string;

  /**
   * Http configuration for Portal
   */
  http?: {
    /**
     * Weather should render the RgiRxHttpClient API errors
     */
    enableGlobalHttpErrorRender?: boolean;
    /**
     * A filtering logic for the errors to render
     */
    when?: RgiRxHttpErrorFilterType;
  };
  /**
   * PortalUI settings
   */
  ui?: {
    /**
     * Enable global httpLoading indicator
     */
    enableGlobalHttpLoadingIndicator?: boolean;
  };
  i18n?: {
    /**
     * Enable the getTextCatalog merge with RgiRxi18nModule
     */
    mergeCatalog?: boolean
  };
}


export const RGI_RX_PORTAL_DEFAULT_CONFIG: PortalConfig = {
  module: 'app',
  ui: {
    enableGlobalHttpLoadingIndicator: false
  },
  http: {
    enableGlobalHttpErrorRender: true,
    when: {
      severity: RgiRxHttpErrorSeverity.ERROR,
      scopes: ['global', 'feature']
    }
  },
  i18n: {
    mergeCatalog: false
  }
};




export function rgiRxMergedPortalConfigFactory(portalConfig?: PortalConfig): PortalConfig {
  if (portalConfig) {
    return merge({}, RGI_RX_PORTAL_DEFAULT_CONFIG, portalConfig);
  }
  return RGI_RX_PORTAL_DEFAULT_CONFIG;
}

export const RGI_RX_PORTAL_CONFIG = new InjectionToken<PortalConfig>('RGI_RX_PORTAL_CONFIG');
export const RGI_RX_PORTAL_MERGED_CONFIG = new InjectionToken<PortalConfig>('RGI_RX_PORTAL_MERGED_CONFIG');

export const RGI_RX_PORTAL_MERGED_CONFIG_PROVIDER: FactoryProvider = {
  provide: RGI_RX_PORTAL_MERGED_CONFIG,
  useFactory: rgiRxMergedPortalConfigFactory,
  deps: [[new Optional(), RGI_RX_PORTAL_CONFIG]]
};

import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalPolicy } from '../../dto/external-policy';
import { CacheService } from '../../cache.service';
import { ExternalPolicySubject } from '../../dto/external-policy-subject';
import { RoutableComponent } from '@rgi/portal-ng-core';


@Component({
  selector: 'claims-external-portfolio-list',
  templateUrl: './external-portfolio-list.component.html',
  styleUrls: ['./external-portfolio-list.component.scss']
})
export class ExternalPortfolioListComponent implements OnInit, RoutableComponent {
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  emitter: any;
  sessionService: any;
  session: any;
  authSrv: any;
  public companies: any[] ;

  public modificaAbilitata: boolean;

  public rows: Array<any> = new Array<any>();

  constructor(
    @Inject('sessionService') session: any,
    @Inject('authService') authService,
    private servCache: CacheService,
    private modalService: NgbModal
    // private translatorService: TranslatorService
    ) {
      this.session = session;
      this.authSrv = authService;

      this.companies = this.authSrv.getOperator().visibleCompanies;

      const listFunz = this.authSrv.getOperator().enabledFeatures;

      if (listFunz && listFunz.indexOf('SXPMOD') >= 0) {
        this.modificaAbilitata = true;
      } else {
        this.modificaAbilitata = false;
      }
  }

  ngOnInit() {
    const listaExtPols = this.servCache.getListExternalPolicyClear();

    listaExtPols.forEach (aPol => {
      let company = '';
      if (this.companies.find(value => value.aniaCode === aPol.company)) {
        company = this.companies.find(value => value.aniaCode === aPol.company).description;
      }
      const aRow = {
            expanded: false,
            policyOwner: this.policyOwner(aPol.subjectList),
            companyDescr: company,
            externalPolicy: aPol,
            updateDate: (aPol.updateDate ?  aPol.updateDate : aPol.insertDate)
          };
      this.rows.push(aRow);
    });

    // this.externalPolicies = this.externalPortfolioService.getExternalPolicies();
  }

  hasResult(): boolean {
    return !!this.rows && this.rows.length > 0;
  }

  externalPolicyDetails(externalPolicyFromList: ExternalPolicy) {

    // this.emitter.broadcastEvent('start-loader');
    // const req = {
    //   filterPolicies: null
    // };

    // this.externa.getPolicyDetail(externalPolicyFromList.objectId)
    //   .subscribe((response: any) => {
    //     const contractRes = response.contract;
    //     console.log(contractRes);
    //     if (contractRes) {

    //       const policyDetail = this.translatorService.rsPolicyToPolicy(contractRes);

    //       const modalRef = this.modalService.open(ExternalPortfolioDetailsComponent, {
    //         windowClass: 'basic',
    //         size: 'lg'
    //       });
    //       modalRef.componentInstance.policy = externalPolicyFromList;

    //     }
    //     this.emitter.broadcastEvent('stop-loader');
    //   },
    //     (error: Error) => {
    //       console.log(error);
    //       this.emitter.broadcastEvent('stop-loader');
    //     }
    //   );
  }

  selectModPolicyDetail(externalPolicy: ExternalPolicy, disabilitato: boolean) {
    this.servCache.setSelectedExternalPolicy(externalPolicy);
    this.servCache.setDisabilita(disabilitato);
    this.navigation.emit('details');
  }

  ctrDetails(index) {
    console.log('INDICE SELEZIONATO');
    console.log(index);
    if (this.rows[index].expanded) {
      this.rows[index].expanded = false;
    } else {
     this.rows[index].expanded = true;
    }
  }

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }

  back() {
    const session = this.getCurrentSession();
    this.session.remove(session.idSession);
  }

  policyOwner(subjectList: ExternalPolicySubject[]): ExternalPolicySubject {
    if (subjectList && subjectList !== null && subjectList.length > 0) {
       return subjectList.find(value => value.codRole === 'CNT');
    } else {
      return new ExternalPolicySubject();
    }
   }

}

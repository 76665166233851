import {CDK_ROW_TEMPLATE, CdkFooterRow, CdkFooterRowDef, CdkHeaderRow, CdkHeaderRowDef, CdkRow, CdkRowDef} from '@angular/cdk/table';
import {ChangeDetectionStrategy, Component, Directive, ViewEncapsulation} from '@angular/core';


@Directive({
  selector: '[rgiRxHeaderRowDef]',
  providers: [{provide: CdkHeaderRowDef, useExisting: RgiRxHeaderRowDef}],
  inputs: ['columns: rgiRxHeaderRowDef', 'sticky: rgiRxHeaderRowDefSticky'],
})
export class RgiRxHeaderRowDef extends CdkHeaderRowDef {
}

@Directive({
  selector: '[rgiRxFooterRowDef]',
  providers: [{provide: CdkFooterRowDef, useExisting: RgiRxFooterRowDef}],
  inputs: ['columns: rgiRxFooterRowDef', 'sticky: rgiRxFooterRowDefSticky'],
})
export class RgiRxFooterRowDef extends CdkFooterRowDef {
}

@Directive({
  selector: '[rgiRxRowDef]',
  providers: [{provide: CdkRowDef, useExisting: RgiRxRowDef}],
  inputs: ['columns: rgiRxRowDefColumns', 'when: rgiRxRowDefWhen'],
})
export class RgiRxRowDef<T> extends CdkRowDef<T> {
}

@Component({
  selector: 'rgi-rx-header-row, tr[rgi-rx-header-row]',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'rgi-ui-table-header',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: CdkHeaderRow, useExisting: RgiRxHeaderRow}],
})
export class RgiRxHeaderRow extends CdkHeaderRow {
}


@Component({
  selector: 'rgi-rx-footer-row, tr[rgi-rx-footer-row]',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'rgi-ui-footer-row',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: CdkFooterRow, useExisting: RgiRxFooterRow}],
})
export class RgiRxFooterRow extends CdkFooterRow {
}

@Component({
  selector: 'rgi-rx-row, tr[rgi-rx-row]',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'rgi-ui-row',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: CdkRow, useExisting: RgiRxRow}],
})
export class RgiRxRow extends CdkRow {
}

import {Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';

let rgiRxExpansionPanelCounter = 0;
@Component({
  selector: 'rgi-rx-expansion-panel-header,[rgi-rx-expansion-panel-header]',
  templateUrl: './rgi-rx-expansion-panel-header.component.html',
  host: {
    class: 'rgi-ui-expansion-panel-header',
    '[attr.id]': 'id',
  }
})
export class RgiRxExpansionPanelHeaderComponent implements OnDestroy {
  private _onClick = new Subject<any>();
  private readonly _id: string;

  private _disabled;

  constructor(private host: ElementRef) {
    this._id = `rgi-rx-expansion-panel-header-${++rgiRxExpansionPanelCounter}`;
  }


  @HostBinding('class.rgi-ui-disabled') get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
  }

  @HostListener('click', ['$event'])
  _handleHostClick(event: Event) {
    if (!this.disabled && event && event.target === this.element || this.isLabel(event.target as HTMLElement)) {
      this._onClick.next();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private isLabel(target: HTMLElement) {
    return target.hasAttribute('rgi-rx-expansion-panel-label') || target.hasAttribute('rgiRxExpansionPanelLabel');
  }


  get element(): HTMLElement {
    return this.host.nativeElement;
  }

  get onClick(): Observable<any> {
    return this._onClick.asObservable();
  }

  ngOnDestroy(): void {
    this._onClick.complete();
  }


  get id(): string {
    return this._id;
  }
}

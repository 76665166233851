import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import {RGI_RX_DROP_i18n} from './i18n/localization';

export function loadDrop18nIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DROP_i18n.it);
  });
}

export function loadDrop18nEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DROP_i18n.en);
  });
}

export function loadDrop18nFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DROP_i18n.fr);
  });
}

export function loadDrop18nES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DROP_i18n.es);
  });
}

export function loadDrop18nDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DROP_i18n.de);
  });
}


export const RGI_RX_DROP_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: loadDrop18nIT
  },
  {
    locale: 'en',
    load: loadDrop18nEN
  },
  {
    locale: 'fr',
    load: loadDrop18nFR
  },
  {
    locale: 'es',
    load: loadDrop18nES
  },
  {
    locale: 'de',
    load: loadDrop18nDE
  }
];

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gpSubstVariationType'
})
export class GpSubstVariationTypePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'SUBSTITUITED':
        return '_GP_._LABEL_._SUBSTITUITED_';
      case 'SUBSTITUENT':
        return '_GP_._LABEL_._SUBSTITUENT_';
      case 'GENERIC_VARIATION':
        return '_GP_._LABEL_._GENERIC_VARIATION_';
      case 'RENEWAL_PROPOSAL':
        return '_GP_._LABEL_._RENEWAL_PROPOSAL_';
      case 'TO_RENEW':
        return '_GP_._LABEL_._TO_RENEW_';
    }
  }

}

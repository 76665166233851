import {SCALAR_TYPE, walk} from '../reflection';

/**
 * Equality and inequality comparator
 */
type EQUALITY = '=' | '!=';
/**
 * Numeric comparators
 */
type COMPARATOR = '>' | '<' | '<=' | '>=';
/**
 * Path of the node expression to walk
 * @see walk
 */
type PATH = string;

/**
 * A DSL node that support EQUALITY ONLY
 */
export interface RgiRxDSLNodeEqualityExpression {
  path: PATH;
  condition: EQUALITY;
  value: string | null | boolean | number | undefined;
}
/**
 * A DSL node that support EQUALITY AND COMPARATORS
 */
export interface RgiRxDSLNodeComparableExpression {
  path: PATH;
  condition: EQUALITY | COMPARATOR;
  value: number;
}

/**
 * A DSL node that support pattern match
 */
export interface RgiRxDSLNodePatternExpression {
  path: PATH;
  condition: 'match';
  value: RegExp | string;
}

export type RgiRxDSLNodeExpression = RgiRxDSLNodeEqualityExpression | RgiRxDSLNodeComparableExpression | RgiRxDSLNodePatternExpression;

/**
 * A singular DSL assertion on a node
 */
export interface RgiRxDSLAssertionNode {
  /**
   * The assertion to evaluate
   */
  assert: RgiRxDSLNodeExpression;
}
/**
 * An expression group with children nodes
 */
export interface RgiRxDSLAssertionNodeGroup extends RgiRxDSLAssertionNode {
  /**
   * children assertions to evaluate
   */
  children: RgiRxDSLAssertionNode[] | RgiRxDSLAssertionNodeGroup[];
  /**
   * A boolean condition that is applied from this parent when evaluating the children boolean values.
   * Allow to define weather the children must evaluate via AND/OR
   */
  condition: '||' | '&&';
}

/**
 * An assertion that could be either a single node assertion or a group
 */
export type RgiRxDSLAssertion = RgiRxDSLAssertionNode | RgiRxDSLAssertionNodeGroup;


const isDSLNodeGroup = (exp: RgiRxDSLAssertion ): exp is RgiRxDSLAssertionNodeGroup => {
  return (exp as RgiRxDSLAssertionNodeGroup).children !== undefined && (exp as RgiRxDSLAssertionNodeGroup).condition !== undefined;
};

/**
 * Evaluate the assertion against an object
 * @param exp the assertion to check
 * @param obj the target object to evaluate
 */
export function rgiRxDSLAssert<T>(exp: RgiRxDSLAssertion, obj: T): boolean {
  if (isDSLNodeGroup(exp)) {
    return rgiRxEvalDSLNodeGroup<T>(exp, obj);
  }
  return rgiRxEvalDSLNode<T>(exp.assert, obj);
}

/**
 * Evaluate a node group.
 * When nested groups are present it's called recursively
 * @param exp the assertion to check
 * @param obj the object to evaluate
 */
function rgiRxEvalDSLNodeGroup<T>(exp: RgiRxDSLAssertionNodeGroup, obj: T): boolean {
  const expEval = rgiRxEvalDSLNode<T>(exp.assert, obj);

  const childEval: boolean = (exp.children as any).map(c => {
     if (isDSLNodeGroup(c)) {
       return rgiRxEvalDSLNodeGroup<T>(c, obj);
     }
     return rgiRxEvalDSLNode<T>(c.assert, obj);
   }).reduce((previousValue, currentValue) => currentValue && previousValue);

  switch (exp.condition) {
    case '||': {
      return expEval || childEval;
    }
    default: {
      return expEval && childEval;
    }
  }
}

/**
 * Walk the node and compare the extracted value with the expected value by its condition
 * @param node the node to evaluate
 * @param obj the object to evaluate
 */
function rgiRxEvalDSLNode<T>(node: RgiRxDSLNodeExpression, obj: T): boolean {
  const resolved = walk<T>(node.path, obj) as SCALAR_TYPE;
  switch (node.condition) {
    case '<': {
      return resolved < node.value;
    }
    case '<=': {
      return resolved <= node.value;
    }
    case '>': {
      return resolved > node.value;
    }
    case '>=': {
      return resolved >= node.value;
    }
    case '!=': {
      return resolved !== node.value;
    }
    case 'match': {
      const regExp = new RegExp(node.value);
      const matched = resolved.toString().match(regExp);
      return !!matched && matched.length > 0;
    }
    default: {
      return resolved === node.value;
    }
  }

}

/**
 * Expose all public DSL functions for simplicity
 */
export class RgiRxDSL {
  /**
   * Assert an object against an RgiRxDSLAssertion
   * @see rgiRxDSLAssert
   * @param exp the expression of the assertion
   * @param obj the target object to check
   */
  public static assert<T>(exp: RgiRxDSLAssertion, obj: T) {
    return rgiRxDSLAssert<T>(exp, obj);
  }
}




import { GenericEntity } from '../generic-entity';
import { Metadata } from './metadata';

export class DocumentFile {

  public id: string;
  public name: string;
  public userIns: GenericEntity;
  public signed: boolean;
  public downloaded: boolean;
  public signatureDate: Date;
  public validate: boolean;
  public note: string;
  public verifyDate: Date;
  public tokenSign: string;
  public url: string;
  public metadataList: Array<Metadata>;
  public body: any;

  constructor() { }

}

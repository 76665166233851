import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';

import {AcitvityPacket} from '../../../models/pc-portfolio-models/assets-models/pc-asset-instance';

@Component({
  selector: 're-issue-asset-activities',
  templateUrl: './re-issue-asset-activities.component.html',
  styleUrls: ['./re-issue-asset-activities.component.css']
})
export class ReIssueAssetActivitiesComponent implements OnInit {

  @Input() activityPacket: AcitvityPacket;

  @Input() secondaryActivityPacket: AcitvityPacket;

  @Input() readonly assetKey: string;

  @Input() readonly activitiesMin: number;

  @Input() readonly activitiesMax: number;

  @Output() formValidEmitter: EventEmitter<any> = new EventEmitter<any>();

  isRequired: boolean;

  mainActivityFilled: boolean;

  secondaryActivityBusy: boolean;

  mainActivityValid = true;

  secondaryActivityValid = true;

  constructor() {
  }

  ngOnInit() {
    this.isRequired = this.activitiesMin >= 1;
  }

  checkActivitiesForm(val: any) {
    this.mainActivityFilled = val;
  }

  checkSecondaryActivitiesForm(val: any) {
    this.secondaryActivityBusy = val;
  }

  mainActivitiesFormValid(val: any) {
    this.mainActivityValid = val;
    this.formValidEmitter.emit(this.mainActivityValid && this.secondaryActivityValid);
  }

  secondaryActivitiesFormValid(val: any) {
    this.secondaryActivityValid = val;
    this.formValidEmitter.emit(this.mainActivityValid && this.secondaryActivityValid);
  }
}

import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {StepperSection} from '../../models/stepper-section';
import {
  ReIssueAssetStateManagerService
} from '../re-issue-state-manager/re-issue-asset-state-manager.service';
import {concatMap, mergeMap, take} from 'rxjs/operators';
import {forkJoin, of, Subscription} from 'rxjs';
import {PushMessageHandlerService, RgiRxLogger, RgiRxPushMessage} from '@rgi/rx';
import {PcAsset, PcAssetInstance} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {Factor} from '../../models/pc-portfolio-models/factor-models/factor';
import {ApiPcAddress} from '../../models/api-models/apiPcAddress';
import {GenericEntityPcPortfolio} from '../../models/pc-portfolio-models/genericEntityPcPortfolio';
import {MapAddress} from '../../models/domain-models/map-address';
import {PcProductAsset} from '../../models/pc-portfolio-models/assets-models/pc-product-asset';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {AnagEntityIta, AnagFlowData} from '@rgi/anag';
import {ReIssuePolicyAddressUtilityService} from '../re-issue-utility/re-issue-policy-address-utility.service';
import {ReIssueMsgData} from '../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import {ReIssueMsgModalComponent} from '../re-issue-msg-modal/re-issue-msg-modal.component';
import {ModalService, RgiRxOnContainerValueChange} from '@rgi/rx/ui';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {AnagIssueSubjectBusinessService} from '../re-issue-anag/anag-issue-service';
import {Warning} from '../../models/domain-models/warning';
import {ActiveRoute} from '@rgi/rx/router';
import {ReIssuePolicyDataConstants} from '../../re-issue-constants/re-issue-policy-data-constants';
import {ReIssueAssetModalComponent} from './re-issue-asset-modal/re-issue-asset-modal.component';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {ReIssueAssetsBusinessService} from '../re-issue-business-services/re-issue-assets-business.service';
import {ReIssueAssetState} from '../re-issue-state-manager/re-issue-asset.state';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'rgi-re-issue-asset',
  templateUrl: './re-issue-asset.component.html',
  styleUrls: ['./re-issue-asset.component.css']
})
export class ReIssueAssetComponent implements OnInit, OnDestroy {

  togglePanel = 0;
  addressCompliance: boolean;
  cancelAddress = false;
  assetsForm: UntypedFormGroup;
  subscription: Subscription = new Subscription();
  assetNameTranslate: string;
  isFormSubmitted = false;
  addressData: MapAddress = {};
  formatAddress: string;
  mainAddress: AddressApiContacts;
  currentStep = StepperSection.assetData;
  stepperLabels = ['RE_ISSUE.POLICY_DATA', 'RE_ISSUE.ASSETS_DATA', 'RE_ISSUE.QUOTATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.ISSUE_STEPPER'];
  msgData: string;
  clausesValid = true;
  TAG_ERRORS = 'errors';
  isRemove: boolean[] = [true];
  @Output() subjectSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  subjectSelected = false;
  TAG_FORM_ERRORS = 'form_errors';
  activitiesValid = true;
  multiAsset = false;
  isSaveDisabled: boolean = true;

  constructor(
    private reIssueAssetsBusinessService: ReIssueAssetsBusinessService<any>,
    protected subjectBusinessService: AnagIssueSubjectBusinessService,
    public stateManager: ReIssueAssetStateManagerService<ReIssueAssetState>,
    private portfolioPolicyAddressUtilityService: ReIssuePolicyAddressUtilityService,
    protected fb: UntypedFormBuilder,
    private translateService: RgiRxTranslationService,
    protected logger: RgiRxLogger,
    protected customModalService: ModalService,
    protected errorService: PushMessageHandlerService,
    public activateRoute: ActiveRoute,
    protected modalService: ModalService,
    protected datePipe: DatePipe
  ) {
    this.initializeForm();
    this.roleKeys = ReIssuePolicyDataConstants;
  }
  roleKeys: any;

  ngOnInit() {
    const state = this.stateManager.getCurrentState();
    this.stateManager.initializeAssets(state).subscribe(state1 => {
      this.multiAsset = state.productAssets.length > 1 && state.productAssets[0].multiAsset;
      const openModal = !state.assetInstances.length && state.productAssets.length > 1;
      if (openModal) {
        this.openAssetsModal();
      } else {
        this.initAssetArray();
      }
    });
  }

  ngOnDestroy(): void {
    this.errorService.clear();
  }

  protected initializeForm() {
    this.assetsForm = this.fb.group({
      assets: this.fb.array([])
    });

  }
  getFirstSubjectMode(code) {
    const state = this.stateManager.getCurrentState();
    return state.productAssets.find(prod => {
      return prod.code === code;
    }).firstPartyMode;
  }


  initAssetArray() {
    (this.assetsForm.controls.assets as UntypedFormArray).clear();
    this.addAssetsToFormArray();
  }

  addAssetsToFormArray() {
    const assetInstances = this.stateManager.getCurrentState().assetInstances;

    forkJoin(
      assetInstances.map((assetInstance: PcAssetInstance) => {
        return this.stateManager.getAsset(assetInstance.asset.key,
          this.addressCompliance = this.isMoreThanZeroMinAddress(assetInstance.asset.code),
          this.isMoreThanZeroMinSubject(assetInstance.asset.code));
      })).subscribe( (res: ReIssueAssetState[]) => {
      for (let i = 0; i < res.length; i++) {
        const val = res[i];
        const assetInstance = assetInstances[i];
        this.subCreateAssetFormData(assetInstance, val);
      }
    });
  }




  addAssetToFormArray(assetInstance) {
    this.createAssetFormData(assetInstance);
  }

  initSubjectForm(subject) {

    const formGroup = new UntypedFormGroup({
      subjectName: new UntypedFormControl(),
      birthPlace: new UntypedFormControl(),
      birthDate: new UntypedFormControl(),
      fiscalCode: new UntypedFormControl(),
    });
    formGroup.get('birthPlace').disable();
    formGroup.get('birthDate').disable();
    formGroup.get('fiscalCode').disable();

    return formGroup;


  }

  getFiscalCodeOrVat(subject: any): string | Date {

    if (subject.subjectType && subject.subjectType.codice === '2') {
      if (subject.vat) {
        return subject.vat;
      } else {
        return subject.fiscalCode;
      }
    } else {
      return subject.fiscalCode;
    }

  }

  addFactorsToFormGroup(fbGroup: UntypedFormGroup, factors: Array<Factor>) {
    factors.forEach(factor => {
      if(factor && factor.type && factor.type === "DATE" && factor.value) {
        var dateParts = factor.value.split("/");

        fbGroup.addControl(
          factor.code,
          new UntypedFormControl({
              value: new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]),
              disabled: !factor.modifiable
            },
            factor.mandatory ? Validators.required : null
          )
        );
      } else {
        fbGroup.addControl(
          factor.code,
          new UntypedFormControl({
              value: factor.value,
              disabled: !factor.modifiable
            },
            factor.mandatory ? Validators.required : null
          )
        );
        }
    });
  }

  selectedAddressEmitter(event: AddressApiContacts) {
    this.mainAddress = event;
  }

  createAssetFormData(assetInstance: PcAssetInstance) {
    this.stateManager.getAsset(assetInstance.asset.key,
      this.addressCompliance = this.isMoreThanZeroMinAddress(assetInstance.asset.code),
      this.isMoreThanZeroMinSubject(assetInstance.asset.code)).pipe(take(1))
      .subscribe(val => {
        this.subCreateAssetFormData(assetInstance, val);
        });
  }

  subCreateAssetFormData(assetInstance: PcAssetInstance, val: ReIssueAssetState) {
    this.addressData[assetInstance.asset.key] = [];
    const fbGroup: UntypedFormGroup = this.initSubjectForm(assetInstance.subject);

    const asset = val.assetInstances.find(assetFrom => {
      return assetFrom.asset.key === assetInstance.asset.key;
    });
    if (asset.subject) {
      this.formatAddress = asset.subject.birthAddress.placeAddress;
      this.setSubjectFormControlValues(fbGroup, asset.subject);
      fbGroup.updateValueAndValidity();
    } else {
      fbGroup.updateValueAndValidity();
    }

    if (assetInstance && assetInstance.asset && assetInstance.asset.hasFactors()) {
      this.addFactorsToFormGroup(fbGroup, assetInstance.asset.factors);
    }

    if (asset.asset.address && asset.asset.address.formattedAddress !== null
      && asset.asset.address.formattedAddress !== '') {
      const address = this.pcAddressToAddressData(asset.asset.address);
      if (address) {
        this.addressData[assetInstance.asset.key].push(address);
      }
    }
    (this.assetsForm.controls.assets as UntypedFormArray).push(fbGroup);

    this.stateManager.updateState$(of(val));
  }


  createdAddressEmitter(emitted: any, i) {
    const apiPcAddress: ApiPcAddress = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(emitted.address);
    this.stateManager.createAddress(apiPcAddress, emitted.instanceAssetKey).pipe(take(1)).subscribe(
      address => {
        const ad = this.pcAddressToAddressData(address);
        if (ad) {
          const assetInstances = this.stateManager.getCurrentState().assetInstances;
          assetInstances.find(assetInstance => assetInstance.asset.key === emitted.instanceAssetKey).asset.address = address;
          this.addressData[emitted.instanceAssetKey].push(ad);
        }
      }
    );
  }

  updateAddressEmitter(emitted: any, i) {
    const resourceId = this.stateManager.getCurrentState().resourceId;
    const apiPcAddress: ApiPcAddress = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(emitted.address);
    if (!apiPcAddress.id) {
      const assetInstances = this.stateManager.getCurrentState().assetInstances;
      apiPcAddress.id = assetInstances[i].asset.address.id;
    }
    this.stateManager.updateAddress(resourceId, apiPcAddress, emitted.instanceAssetKey).pipe(take(1)).subscribe(
      address => {
        // tslint:disable-next-line:no-shadowed-variable
        this.addressData[emitted.instanceAssetKey].forEach((elem, i) => {
          this.addressData[emitted.instanceAssetKey][i] = this.pcAddressToAddressData(address);
        });
      }
    );
  }



  pcAddressToAddressData(element: ApiPcAddress): AddressApiContacts {
    let response: any;
    if (element) {
      response = new AddressApiContacts();
      response.id = element.id;
      response.key = element.id;
      response.country = this.genericEntityPortfolioToGenericEntity(element.country);
      response.province = this.genericEntityPortfolioToGenericEntity(element.district);
      response.adminLevel2 = element.district ? element.district.description : '';
      response.adminLevel2Short = element.district ? element.district.code : '';

      response.city = element.city ? element.city.code : '';
      response.adminLevel3 = element.city ? element.city.description : '';
      response.city = element.city ? element.city.description : '';

      response.placeAddress = element.address;
      response.number = element.number;
      response.cap = element.postalCode;
      response.toponym = new AnagEntityIta(element.toponym ? element.toponym.code : '', element.toponym ? element.toponym.description : '');
      response.formatAddress = element.formattedAddress;
      response.main = element.main;
    }
    return response;
  }


  genericEntityPortfolioToGenericEntity(genericEntityP: GenericEntityPcPortfolio): GenericEntity {
    if (genericEntityP) {
      return new GenericEntity(null, genericEntityP.code, genericEntityP.description);
    }
    return null;
  }

  setSubjectFormControlValues(formGroup: UntypedFormGroup, subject: AnagIssueSubject) {

    // SUBJECT NAME
    const subjectName = subject;
    formGroup.controls.subjectName.setValue(subjectName);
    formGroup.controls.subjectName.setValidators(Validators.required);

    // BIRTHPLACE
    const birthPlace =
      subject.subjectType && subject.subjectType.codice === '2' ?
        subject.residence.city : subject.cityOfBirth;
    formGroup.controls.birthPlace.setValue(birthPlace);
    formGroup.controls.birthPlace.disable();
    // BIRTH DATE
    const birthDate = subject.dateOfBirth;
    formGroup.controls.birthDate.setValue(birthDate);
    formGroup.controls.birthDate.disable();
    // FISCAL CODE
    const fiscalCode = this.getFiscalCodeOrVat(subject);
    formGroup.controls.fiscalCode.setValue(fiscalCode);
    formGroup.controls.fiscalCode.disable();
    // CHECK
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.controls[key].updateValueAndValidity();
    });
  }



  validateForm() {
    this.errorService.clear();
    const assetsFormArray = this.assetsForm.controls.assets as UntypedFormArray;
    assetsFormArray.controls.forEach((control, i) => {
      if (this.isFormSubmitted) {
        const form = control as UntypedFormGroup;
        // this.checkFormNullValues(form.controls as unknown as FormControl[]);
        form.markAsDirty();
        this.errorService.clearTag(i + '_' + this.TAG_FORM_ERRORS);
        if (form.invalid || !this.activitiesValid) {
          const errorMsg = new RgiRxPushMessage();
          errorMsg.tag = i + '_' + this.TAG_FORM_ERRORS;
          errorMsg.status = 'danger';
          errorMsg.dismissible = false;
          errorMsg.options = {icon: 'rgi-ui-icon-alert'};
          errorMsg.content = 'RE_ISSUE.FIELDS_MARKED_IN_RED';
          this.errorService.notify(errorMsg);
        }
      }

    });
  }

  checkFormNullValues(controls: UntypedFormControl[]) {
    Object.keys(controls).forEach(key => {
      controls[key].markAsDirty();
    });
  }

  onFactorChange(factor: Factor, asset: PcAsset) {
    this.validateForm();
    if (!this.isFactorValueNullAndMandatory(factor)) {
      this.stateManager.updateAssetFactor(factor, asset);
      this.isSaveDisabled = false;
    }
  }

  isFactorValueNullAndMandatory(factor: Factor) {
    return (factor.value === '-1' || factor.value === null) && factor.mandatory;
  }

  getFormAsset(i: number): UntypedFormGroup {
    return (this.assetsForm.controls.assets as UntypedFormArray).controls[i] as UntypedFormGroup;
  }

  deleteAccordion(event: any, assetInstance: PcAssetInstance, index: number) {
    event.stopPropagation();
    const msgData = new ReIssueMsgData();
    const msg = of(['RE_ISSUE.DELETE_ASSET']);
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.msgData = next [0] + ' ' + assetInstance.asset.name + '?';
    }).unsubscribe();
    msgData.msg = this.msgData;
    msgData.showButtonConfirm = true;
    const {modal, component} = this.customModalService.openComponent(ReIssueMsgModalComponent, msgData);
    modal.onClose.subscribe(value => {
      if (value === 'confirm') {
        this.stateManager.deleteAsset(assetInstance).subscribe(state => {
          this.togglePanel = 0;
          this.deleteAssetFromFieldsForm(index);
          if (state.assetInstances.length === 0) {
            this.addNewAsset();
          }
        });
      }
    });
  }

  get assetFormArray(): UntypedFormArray {
    return this.assetsForm.controls.assets as UntypedFormArray;

  }

  back() {
    this.stateManager.updateState$(of(this.stateManager.getCurrentState()));
    this.stateManager.back();
  }

  goToIthStep(i){
    this.back();
  }

  deleteAddressEmitter(event: any) {
    this.stateManager.deleteAddress(event).pipe(take(1)).subscribe(val => {
      this.addressData[event.instanceAssetKey] = this.addressData[event.instanceAssetKey].filter(filt => {
        return filt.key !== event.address.key;
      });
    });
  }

  verifyProductAssetsLimit(productAssets: PcProductAsset[], assetInstances: PcAssetInstance[]) {
    let response = false;
    if (this.multiAsset) {
      let counter = 0;
      productAssets.forEach(product => {
        const assetFiltered = assetInstances.filter(asset => {
          return asset.asset.code === product.code;
        });
        if (assetFiltered && assetFiltered.length < product.maxInsurables) {
          counter++;
        }
      });
      if (counter > 0) {
        response = true;
      }
    } else {
      productAssets.forEach(product => {
        const assetFiltered = assetInstances.filter(asset => {
          return asset.asset.code === product.code;
        });
        if (assetFiltered.length === 0 && assetInstances.length > 0) {
          return false;
        } else if (assetFiltered.length < product.maxInsurables) {
          response = true;
        }
      });
    }
    return response;
  }

  verifyProductAssetsLimitModal(productAssets: PcProductAsset[], assetInstances: PcAssetInstance[]) {
    let response = false;
    let counter = 0;
    productAssets.forEach(product => {
      const assetFiltered = assetInstances.filter(asset => {
        return asset.asset.code === product.code;
      });
      if (assetFiltered && assetFiltered.length < product.maxInsurables) {
        counter++;
      }
    });
    if (counter > 1) {
      response = true;
    }
    return response;
  }

  thereIsAtLeastOneProductToAdd() {
    const state = this.stateManager.getCurrentState();
    if (state) {
      return this.verifyProductAssetsLimit(state.productAssets, state.assetInstances);
    } else {
      return false;
    }

  }


  goToNextPage() {
    this.isFormSubmitted = true;
    this.validateForm();
    if (this.assetsForm.valid && this.activitiesValid) {
      // TODO: CHECK GET WARNINGS SERVICE
      this.stateManager.getWarnings().subscribe(st => {
        const block: boolean = st.warnings && !!st.warnings.find(warning => warning.blockingMessage);
        if (st.warnings && st.warnings.length) {
          st.warnings.forEach(message => {
            this.notifyWarningMessage(message, block);
          });
        }
        if (!block) {
          this.subscription.unsubscribe();
          this.stateManager.goToNextPage();
        }
      });
    }
  }

  notifyWarningMessage(message: Warning, blocker: boolean) {
    const st = this.stateManager.getCurrentState();
    let tag = this.TAG_ERRORS;
    if (message.key) {
      st.assetInstances.forEach((assetInstance, index) => {
        if (assetInstance.asset.key === message.key) {
          tag = index + '_' + this.TAG_FORM_ERRORS;
        }
      });
    }
    const errorMsg = new RgiRxPushMessage();

    errorMsg.tag = tag;
    errorMsg.status = blocker ? 'danger' : 'info';
    errorMsg.dismissible = false;
    errorMsg.options = blocker ? {icon: 'rgi-ui-icon-alert'} : {icon: 'rgi-ui-icon-info'};
    errorMsg.content = message.description;
    this.errorService.notify(errorMsg);

  }

  setTitleTranslate(): string {

    const assetName = of(['RE_ISSUE.USUAL_OCCASIONAL_RESIDENCE']);
    assetName.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.assetNameTranslate = next [0];
    }).unsubscribe();
    return this.assetNameTranslate;
  }


  getMaxAddress(code) {
    const state = this.stateManager.getCurrentState();
    return state.productAssets.find(prod => {
      return prod.code === code;
    }).maxLocations;
  }

  getFirstAddressMode(code) {
    const state = this.stateManager.getCurrentState();
    return state.productAssets.find(prod => {
      return prod.code === code;
    }).firstAddressMode;
  }


  isMoreThanZeroMinAddress(code) {
    const state = this.stateManager.getCurrentState();
    return state.productAssets.find(prod => {
      return prod.code === code;
    }).minLocations > 0;
  }


  isMoreThanZeroMinSubject(code: string): any {
    const state = this.stateManager.getCurrentState();
    return state.productAssets.find(prod => {
      return prod.code === code;
    }).minParties > 0;
  }

  saveProposal(authorizationRequest) {
    this.isFormSubmitted = true;
    this.validateForm();
    const state = this.stateManager.getCurrentState();
    this.stateManager.updateState$(of(state));
    if (this.assetsForm.valid && this.activitiesValid) {
      // TODO: CHECK GET WARNINGS SERVICE
      this.errorService.clearTag(this.TAG_ERRORS);
      this.stateManager.getWarnings().subscribe(st => {
        const block: boolean = st.warnings && !!st.warnings.find(warning => warning.blockingMessage);
        if (st.warnings && st.warnings.length) {
          st.warnings.forEach(message => {
            this.notifyWarningMessage(message, block);
          });
        }
        if (!block) {
          this.subscription.unsubscribe();
          // tslint:disable-next-line:no-shadowed-variable
          this.stateManager.saveProposal().pipe(take(1)).subscribe(state => {
            this.isSaveDisabled = true;
            const msgData = new ReIssueMsgData();
            const msg = of(['RE_ISSUE.PROPOSAL_N',
              'RE_ISSUE.SAVED_SUCCESSFULLY']);
            msg.pipe(mergeMap(r => {
              return this.translateService.translateAll(...r);
            })).subscribe(next => {
              this.msgData = next [0] +
                ' ' + state.proposal.contractNumber.proposal + ' ' + next [1];
            }).unsubscribe();
            msgData.msg = this.msgData;
            msgData.showButtonConfirm = false;
            this.customModalService.openComponent(ReIssueMsgModalComponent, msgData);
          });
        }
      });
    }
  }


  anagSubjectSelected(val: RgiRxOnContainerValueChange, assetKey: string, index: number, assetCode: string) {
    if (val !== null) {
      const state = this.stateManager.getCurrentState();
      if (state.thirdPersonContact && state.thirdPersonContact.objectId === val.changed.objectId) {
        const idMsg = 'RE_ISSUE.INSURED_THIRD_PERSON_ERROR';
        this.showGenericErrorModal(idMsg);
        this.subjectSelected = false;
        this.removeSubjectFormData(index, assetKey, val.changed, assetCode);
      } else {
        this.subjectBusinessService
          .getSubject(val.changed.objectId, val.changed.node.identification)
          .subscribe(subj => {
            this.subjectSelected = true;
            this.patchSubjectDataAndValidateForm(subj, assetKey, index, assetCode);
            this.subjectSelectedEmitter.emit(subj);

          });
      }

    } else {
      this.subjectSelected = false;
      this.patchSubjectDataAndValidateForm(null, assetKey, index, assetCode);
    }
  }

  patchSubjectDataAndValidateForm(subject: AnagIssueSubject, assetKey: string, index: number, assetCode: string) {
    if (subject) {
      this.addSubjectData(subject, assetKey, index);
    } else {
      this.removeSubjectFormData(index, assetKey, subject, assetCode);
    }
    this.assetsForm.updateValueAndValidity();
  }


  addSubjectData(subject: AnagIssueSubject, assetKey: string, index: number) {
    this.isRemove[index] = true;
    const apiPcAddress: ApiPcAddress = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(subject.residence);
    const asset = (this.assetsForm.get('assets') as UntypedFormArray).controls[index];
    asset.get('fiscalCode').setValue(this.getFiscalCode(subject));
    asset.get('birthPlace').setValue(subject.cityOfBirth);
    asset.get('birthDate').setValue(new Date(subject.dateOfBirth));
    this.stateManager.updateInsuredSubject(this.stateManager.getCurrentState(), assetKey, subject).pipe(concatMap((state: any) => {
      if (this.cancelAddress) {
        apiPcAddress.id = state.assetInstances[index].asset.address.id;
        this.cancelAddress = false;
        return this.stateManager.updateAddress(state.resourceId, apiPcAddress, assetKey);
      } else {
        this.stateManager.updateState$(of(state));
        this.isSaveDisabled = false;
        return of(null);
      }
    })).subscribe(newAddress => {
      const state = this.stateManager.getCurrentState();
      this.addressData[assetKey].filter((item) => item.id !== apiPcAddress.id);
      const addr = this.pcAddressToAddressData(newAddress);
      if (addr) {
        this.addressData [assetKey].push(addr);
      }
      state.assetInstances.find(instance => instance.asset.key === assetKey).asset.address = newAddress;
      this.stateManager.updateState$(of(state));
      this.isSaveDisabled = false;
    });
  }

  getSubjectDropContainerData(role, assetInstance) {
    const state = this.stateManager.getCurrentState();
    const prodAsset = state.productAssets.find(prod => prod.code === assetInstance.asset.code);
    const data = new AnagFlowData();
    data.partyRole = '' + role;
    data.idParentSession = this.activateRoute.id;
    if (prodAsset.partyType && (prodAsset.partyType === 1 || prodAsset.partyType === 2)) {
      data.partyType = prodAsset.partyType.toString();
    }
    return data;
  }


  getFiscalCode(subject: AnagIssueSubject) {
    let fiscalCode = subject.vat;
    if (!fiscalCode) {
      if (subject.partyKey && subject.partyKey[0]) {
        if (subject.partyKey[0].key1 && subject.partyKey[0].key1.value && subject.partyKey[0].key1.value !== '') {
          fiscalCode = subject.partyKey[0].key1.value;
        } else if (subject.partyKey[0].key2 && subject.partyKey[0].key2.value && subject.partyKey[0].key2.value !== '') {
          fiscalCode = subject.partyKey[0].key2.value;
        }
      }
    }

    return fiscalCode;
  }


  removeSubjectFormData(index: number, assetKey: string, subject: AnagIssueSubject, assetCode: string) {
    this.stateManager.deleteInsuredSubject(this.stateManager.getCurrentState(), assetKey, subject.idLatestPhotos, '4');
    this.isRemove[index] = false;
    this.resetFieldsForm(index);
    const firstAddressMode = this.getAddressMode(assetCode);
    if ((firstAddressMode === 3 ||
        firstAddressMode === 1) &&
      index === 0) {
      this.cancelAddress = true;
      this.addressData [assetKey] = [];


    }
  }

  private resetFieldsForm(index: number) {
    const asset = (this.assetsForm.get('assets') as UntypedFormArray).controls[index];
    asset.get('fiscalCode').setValue(null);
    asset.get('birthPlace').setValue(null);
    asset.get('birthDate').setValue(null);
    this.assetsForm.updateValueAndValidity();
  }

  private deleteAssetFromFieldsForm(index: number) {
    (this.assetsForm.get('assets') as UntypedFormArray).removeAt(index);
    this.assetsForm.updateValueAndValidity();
  }

  openAssetsModal() {
    const state = this.stateManager.getCurrentState();
    const filteredProductAsset: Array<PcProductAsset> = this.stateManager.filterProductAsset(state);
    const {modal, component} = this.modalService.openComponent(ReIssueAssetModalComponent);
    component.productAssets = new Array<PcProductAsset>();
    filteredProductAsset.forEach(asset => {
      component.productAssets.push(asset);
    });
    modal.onClose.subscribe(value => {
      // tslint:disable-next-line:no-shadowed-variable
      const filteredAsset = filteredProductAsset.find(asset => asset.description === value);
      this.stateManager.initializeAssetByCode(filteredAsset.code, state).subscribe(asset => {
        const assetInstance = new PcAssetInstance();
        asset.code = filteredAsset.code;
        assetInstance.asset = asset;
        state.assetInstances.push(assetInstance);
        this.addressCompliance = true;
        this.addressData[assetInstance.asset.key] = [];
        const fbGroup: UntypedFormGroup = this.initSubjectForm(assetInstance.subject);
        if (assetInstance.subject) {
          this.formatAddress = assetInstance.subject.birthAddress.placeAddress;
          this.setSubjectFormControlValues(fbGroup, assetInstance.subject);
          fbGroup.updateValueAndValidity();
        } else {
          fbGroup.updateValueAndValidity();
        }
        if (assetInstance && assetInstance.asset && assetInstance.asset.factors && assetInstance.asset.factors.length > 0) {
          this.addFactorsToFormGroup(fbGroup, assetInstance.asset.factors);
        }
        if (assetInstance.asset.address && assetInstance.asset.address.formattedAddress !== null
          && assetInstance.asset.address.formattedAddress !== '') {
          const addr = this.pcAddressToAddressData(assetInstance.asset.address);
          if (addr) {
            this.addressData[assetInstance.asset.key].push(addr);
          }
        }
        (this.assetsForm.controls.assets as UntypedFormArray).push(fbGroup);
        this.stateManager.updateState$(of(state));
        this.isSaveDisabled = false;
      });
      this.togglePanel = state.assetInstances.length;
    });
  }


  addNewAsset() {
    this.errorService.clear();
    const state = this.stateManager.getCurrentState();
    this.stateManager.getWarnings().subscribe(st => {
      const block: boolean = st.warnings && !!st.warnings.find(warning => warning.blockingMessage);
      if (st.warnings && st.warnings.length) {
        st.warnings.forEach(message => {
          this.notifyWarningMessage(message, block);
        });
      }
      if (!block) {
        if (this.multiAsset || (state.assetInstances.length === 0 && state.productAssets.length > 1)) {
          if (this.verifyProductAssetsLimitModal(state.productAssets, state.assetInstances)) {
            this.openAssetsModal();
          } else {
            const assetCode = this.getAssetCodeToAdd(state.productAssets, state.assetInstances);
            if (assetCode) {
              this.stateManager.addNewAsset(assetCode, state).subscribe(instance => {
                instance.asset.code = assetCode;
                state.assetInstances.push(instance);
                this.addAssetToFormArray(instance);
                this.stateManager.updateState$(of(state));
                this.isSaveDisabled = false;
              });
              this.togglePanel = state.assetInstances.length;
            }
          }
        } else {
          const filteredProductAsset: Array<PcProductAsset> = this.stateManager.filterProductAsset(state);
          let assetCode = filteredProductAsset[0].code; // TODO : OTHER WAYS
          if (state.assetInstances && state.assetInstances.length > 0) {
            const firstAssetCode = state.assetInstances[0].asset.code;
            const filteredAsset = filteredProductAsset.find(asset => asset.code === firstAssetCode);
            assetCode = filteredAsset.code;
          }
          this.stateManager.addNewAsset(assetCode, state).subscribe(instance => {
            instance.asset.code = assetCode;
            state.assetInstances.push(instance);
            this.addAssetToFormArray(instance);
            this.stateManager.updateState$(of(state));
            this.isSaveDisabled = false;
          });
          this.togglePanel = state.assetInstances.length;
        }
      }
    });
  }


  getAddressMode(code) {
    const state = this.stateManager.getCurrentState();
    const productAsset = state.productAssets.find(prod => prod.code === code);
    return productAsset ? productAsset.firstAddressMode : null;
  }

  updateClause(val: any) {
    this.clausesValid = val.formValid;
    this.validateForm();
    if (val.clause && val.assetKey) {
      const state = this.stateManager.getCurrentState();
      const asset = state.assetInstances.find(a => a.asset.key === val.assetKey).asset;
      this.stateManager.updateAssetClauses(state, val.clause, asset).subscribe(
        state1 => {
          this.stateManager.updateState$(of(state1));
          this.isSaveDisabled = false;
        }
      );
    }
  }

  activitiesFormValid(val: any) {
    this.activitiesValid = val;
    this.validateForm();
  }

  private getAssetCodeToAdd(productAssets: Array<PcProductAsset>, assetInstances: Array<PcAssetInstance>) {
    let assetCode = null;
    productAssets.forEach(product => {
      const assetFiltered = assetInstances.filter(asset => {
        return asset.asset.code === product.code;
      });
      if (assetFiltered && assetFiltered.length < product.maxInsurables) {
        assetCode = product.code;
      }
    });
    return assetCode;
  }

  canDeletetheInstance(assetInstance: PcAssetInstance) {
    let response = true;
    const state = this.stateManager.getCurrentState();
    const productAsset = state.productAssets.find(prod => {
      return assetInstance.asset.code === prod.code;
    });

    if (productAsset) {
      const filteredAssetInstances = state.assetInstances.filter(instance => {
        return assetInstance.asset.code === instance.asset.code;
      });
      if (filteredAssetInstances && filteredAssetInstances.length === productAsset.minInsurables) {
        response = false;
      }
    }
    return response;
  }

  showGenericErrorModal(idMsg: string) {
    const msgData = new ReIssueMsgData();
    const msg = of([idMsg]);
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.msgData = next [0];
    }).unsubscribe();
    msgData.msg = this.msgData;
    msgData.showButtonConfirm = false;
    this.modalService.openComponent(ReIssueMsgModalComponent, msgData);
  }

  isAddressVisible(assetInstance: PcAssetInstance) {
    let isVisible = false;
    const state = this.stateManager.getCurrentState();
    const prodAsset = state.productAssets.find(pa => pa.code === assetInstance.asset.code);
    if (prodAsset.maxLocations > 0) {
      isVisible = true;
    }
    return isVisible;
  }

  isAnagVisible(assetInstance: PcAssetInstance) {
    let isVisible = false;
    const state = this.stateManager.getCurrentState();
    const prodAsset = state.productAssets.find(pa => pa.code === assetInstance.asset.code);
    if (prodAsset.maxParties > 0) {
      isVisible = true;
    }
    return isVisible;
  }
}

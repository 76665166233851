import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ApiPager, CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { ApiInvoiceFilter } from '../api/api-invoice-filter';
import { Invoice } from '../dto/invoice';
import { InvoiceService } from '../invoice.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { EnumType } from '@rgi/digital-claims-common-angular';
import { InvoiceExpert } from '../dto/invoice-expert';
import { ApiExpertFilter } from '../api/api-expert-filter';
import { InvoiceConstants } from '../invoice-constants';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-invoice-search',
  templateUrl: './invoice-search.component.html',
  styleUrls: ['./invoice-search.component.scss']
})
export class InvoiceSearchComponent extends CardComponent implements OnInit, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  invoicenumber: string;
  searchInvoiceForm: UntypedFormGroup;
  errorMessage: string;
  emitter: any;
  today: NgbDate;
  experts: InvoiceExpert[];
  rejectTypes: EnumType[];

  constructor(
    @Inject('sessionService') private session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') eventService: any,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private invoiceService: InvoiceService,
    private formBuilder: UntypedFormBuilder,
    public translateService: RgiRxTranslationService,
  ) {
    super(cardComponentService, sessionService);
    this.emitter = eventService;
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  ngOnInit(): void {

    this.initValidators();
    this.initExperts();
    this.initRejectTypes();
  }

  initValidators() {
    this.searchInvoiceForm = this.formBuilder.group({
      claimNumber: [],
      invoiceNumber: [],
      invoiceDate: [],
      receivedDateFrom: [],
      receivedDateTo: [],
      expert: [],
      rejectType: [],
      sdiCode: [],
      sapCode: []
    }
      // , {
      //   validator: [
      //     this.invoiceService.validateDate('invoiceDate'),
      //     this.invoiceService.validateDate('receptionDateFrom'),
      //     this.invoiceService.validateDate('receptionDateTo')
      //   ]
      // }
    );
  }

  initExperts() {
    this.invoiceService.getExperts()
      .subscribe((response: any) => {
        this.experts = response.invoiceExpertList;
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._SEARCH_EXPERTS_ERROR').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.emitter.broadcastEvent('stop-loader');
        }
      );
  }

  initRejectTypes() {
    this.invoiceService.getRejectTypes()
      .subscribe((response: any) => {
        this.rejectTypes = response.rejectTypeEntityList;
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._SEARCH_REJECT_TYPES_ERROR').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.emitter.broadcastEvent('stop-loader');
        }
      );
  }

  goToSearchResults() {

    let invoicesRes = [];
    const invoices: Invoice[] = [];
    // const req = {
    //   filterInvoices: null
    // };

    this.errorMessage = null;
    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');
    const filter = new ApiInvoiceFilter();
    const expertFilter = new ApiExpertFilter();
    const pagerFilter = new ApiPager();

    // TODO test1 - inizio
    // filter.invoiceNumber = this.searchInvoiceForm.controls.invoiceNumber.value
    //  ? this.searchInvoiceForm.controls.invoiceNumber.value : '';
    // filter.claimNumber = this.searchInvoiceForm.controls.claimNumber.value ? this.searchInvoiceForm.controls.claimNumber.value : '';
    // filter.invoiceDate = this.searchInvoiceForm.controls.invoiceDate.value ? this.searchInvoiceForm.controls.invoiceDate.value : '';
    // filter.receivedDateFrom = this.searchInvoiceForm.controls.receivedDateFrom.value ?
    //  this.searchInvoiceForm.controls.receivedDateFrom.value : '';
    // filter.receivedDateTo = this.searchInvoiceForm.controls.receivedDateTo.value ?
    //  this.searchInvoiceForm.controls.receivedDateTo.value : '';
    // expertFilter.name = this.searchInvoiceForm.controls.expert.value
    //  ? this.searchInvoiceForm.controls.expert.value.split('|')[0] : '';
    // expertFilter.surname = this.searchInvoiceForm.controls.expert.value
    //  ? this.searchInvoiceForm.controls.expert.value.split('|')[1] : '';
    // expertFilter.title = this.searchInvoiceForm.controls.expert.value
    //  ? this.searchInvoiceForm.controls.expert.value.split('|')[2] : '';
    // filter.expert = expertFilter;
    // filter.rejectType = this.searchInvoiceForm.controls.rejectType.value ? this.searchInvoiceForm.controls.rejectType.value : '';
    // filter.sdiCode = this.searchInvoiceForm.controls.sdiCode.value ? this.searchInvoiceForm.controls.sdiCode.value : '';
    // filter.sapCode = this.searchInvoiceForm.controls.sapCode.value ? this.searchInvoiceForm.controls.sapCode.value : '';
    // pagerFilter.pageNumber = 1;
    // pagerFilter.pageSize = InvoiceConstants.PAGE_ELEMENTS;
    // filter.pager = pagerFilter;
    // TODO test1 - fine

    // TODO test2 - inizio
    if (this.searchInvoiceForm.controls.invoiceNumber.value) {
      filter.invoiceNumber = this.searchInvoiceForm.controls.invoiceNumber.value;
    }
    if (this.searchInvoiceForm.controls.claimNumber.value) {
      filter.claimNumber = this.searchInvoiceForm.controls.claimNumber.value;
    }
    if (this.searchInvoiceForm.controls.invoiceDate.value) {
      filter.invoiceDate = this.searchInvoiceForm.controls.invoiceDate.value;
    }
    if (this.searchInvoiceForm.controls.receivedDateFrom.value) {
      filter.receivedDateFrom = this.searchInvoiceForm.controls.receivedDateFrom.value;
    }
    if (this.searchInvoiceForm.controls.receivedDateTo.value) {
      filter.receivedDateTo = this.searchInvoiceForm.controls.receivedDateTo.value;
    }
    if (this.searchInvoiceForm.controls.expert.value) {
      expertFilter.name = this.searchInvoiceForm.controls.expert.value.split('|')[0];
    }
    if (this.searchInvoiceForm.controls.expert.value) {
      expertFilter.surname = this.searchInvoiceForm.controls.expert.value.split('|')[1];
    }
    if (this.searchInvoiceForm.controls.expert.value) {
      expertFilter.title = this.searchInvoiceForm.controls.expert.value.split('|')[2];
    }
    filter.expert = expertFilter;

    if (this.searchInvoiceForm.controls.rejectType.value) {
      filter.rejectType = this.searchInvoiceForm.controls.rejectType.value;
    }
    if (this.searchInvoiceForm.controls.sdiCode.value) {
      filter.sdiCode = this.searchInvoiceForm.controls.sdiCode.value;
    }
    if (this.searchInvoiceForm.controls.sapCode.value) {
      filter.sapCode = this.searchInvoiceForm.controls.sapCode.value;
    }

    pagerFilter.pageNumber = 1;
    pagerFilter.pageSize = InvoiceConstants.PAGE_ELEMENTS;
    filter.pager = pagerFilter;
    // TODO test2 - fine

    // filter.invoiceNumber = this.searchInvoiceForm.controls.invoiceNumber.value;
    // filter.claimNumber = this.searchInvoiceForm.controls.claimNumber.value;
    // filter.invoiceDate = this.searchInvoiceForm.controls.invoiceDate.value;
    // filter.receivedDateFrom = this.searchInvoiceForm.controls.receivedDateFrom.value;
    // filter.receivedDateTo = this.searchInvoiceForm.controls.receivedDateTo.value;
    // filter.expert.name = this.searchInvoiceForm.controls.expert.value.split('|')[0];
    // filter.expert.surname = this.searchInvoiceForm.controls.expert.value.split('|')[1];
    // filter.expert.title = this.searchInvoiceForm.controls.expert.value.split('|')[2];
    // filter.rejectType = this.searchInvoiceForm.controls.rejectType.value;
    // filter.sdiCode = this.searchInvoiceForm.controls.sdiCode.value;
    // filter.sapCode = this.searchInvoiceForm.controls.sapCode.value;
    // req.filterInvoices = filter;

    this.invoiceService.getInvoicesList(filter)
      .subscribe((response: any) => {
        invoicesRes = response.invoiceList;
        if (invoicesRes && invoicesRes.length > 0) {

          for (const invoiceRes of invoicesRes) {
            // let invoice = new Invoice();
            // invoice = invoiceRes;
            invoices.push(invoiceRes);
          }

          const sessionId = this.session.open('claimsInvoice', 'home', '', true);
          this.invoiceService.setSessionInvoices(sessionId, invoices);
          this.invoiceService.setSessionInvoiceFilter(sessionId, filter);
          this.invoiceService.setSessionInvoiceTotalPages(sessionId, response.totalPages);


          // invoicesRes.forEach(invoiceRes => {
          //   const invoice = new Invoice();
          //   invoice.idInvoice = invoiceRes.idInvoice;
          //   invoices.push(invoice);
          // });

        } else {
          this.translateService.translate('_CLAIMS_._NO_RESULT').subscribe(res=> {
            this.errorMessage=res;
          }
          )
        }
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._MESSAGE_._SEARCH_ERROR').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.emitter.broadcastEvent('stop-loader');
        }
      );

  }


  // isMissingInvoiceNumber() {
  // }

  // isMissingClaimNumber() {
  // }

  // isMissingSdiCode() {
  // }

  // isMissingSapCode() {
  // }

  emptyFields() {
    this.searchInvoiceForm.reset();
  }

  validateDate(controlName: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];

      const min: Date = new Date(1990, 1, 1);

      if (control.value) {
        if (control.value instanceof Date) {

          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if (inputDate > currentDate) {
            control.setErrors({ futureDate: true });
          } else if (inputDate < min) {
            control.setErrors({ tooOld: true });
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({ invalidDate: true });
        }
      } else {
        const validator: any = control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

}

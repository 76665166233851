// GET DIMENSIONS FROM QLIK
export const DIMENSION_LIST = {
    qDimensionListDef: {
        qType: 'dimension',
        qData: {
            title: '/title',
            tags: '/tags',
            grouping: '/qDim/qGrouping',
            info: '/qDimInfos'
        }
    },
    qInfo: {
        qId: 'DimensionList',
        qType: 'DimensionList'
    }
};

export const PARAMS_SESSION_OBJECT = {
  qInfo: {
    qType: 'SheetList',
  },
  qAppObjectListDef: {
    qType: 'sheet',
    qData: {
      title: '/qMetaDef/title',
      description: '/qMetaDef/description',
      thumbnail: '/thumbnail',
      cells: '/cells',
      rank: '/rank',
      columns: '/columns',
      rows: '/rows',
    },
  },
};
export const PARAMS_DIMENSIONS_LIST = {
  qInfo: {
    qType: 'DimensionList'
  },
  qDimensionListDef: {
    qType: 'dimension',
    qData: {
      title: '/title',
      tags: '/tags',
      grouping: '/qDim/qGrouping',
      info: '/qDimInfos'
    }
  }
};

import {Inject, Pipe, PipeTransform} from '@angular/core';
import {DateTimeAdapter} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'micDate'
})
export class MicDatePipe implements PipeTransform {

  constructor(
    protected i18nService: RgiRxTranslationService,
    protected datePipe: DatePipe,
    @Inject(DateTimeAdapter) protected dateTimeAdapter: any,
  ) {
  }

  transform(value: string, customFormat?: string): string {
    const format = !!customFormat ? customFormat : this.dateTimeAdapter.dateParser.localeFormats[this.i18nService.currentLanguage];
    return this.datePipe.transform(value, format);
  }

}

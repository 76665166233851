import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LpcMessageModalComponent } from './lpc-message-modal.component';
import { LpcNavigationButtonsModule } from '../lpc-navigation-buttons/lpc-navigation-buttons.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcMessageModalComponent],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        LpcNavigationButtonsModule,
        RgiRxI18nModule
    ],
    exports: [
        LpcMessageModalComponent
    ]
})
  export class MessageModalComponentModule { }

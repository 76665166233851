import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {of} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {DashboardUtilityService} from '../services/dashboard-utility.service';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {DashboardTotalRiskDetail} from '../model/dashboard-total-risk-detail';
import {DashboardOperation} from '../model/dashboard-operation';

@Component({
  selector: 'dashboard-detail-table-all-risk',
  templateUrl: './dashboard-detail-table-all-risk.component.html',
  styleUrls: ['./dashboard-detail-table-all-risk.component.css']
})
export class DashboardDetailTableAllRiskComponent implements OnInit {


  constructor(protected utility: DashboardUtilityService, private translateService: RgiRxTranslationService) { }
  @Input() totalAllRisk: DashboardTotalRiskDetail[];
  @Input() overview: any;
  @Input() operation: DashboardOperation;
  @Input() dashBoardDetailForm: UntypedFormGroup;
  modalConfirmText: string;
  @Output() resetTableAllRiskValue: any = new EventEmitter();
  @Output() applyRisks: any = new EventEmitter();
  @Output() actionSelected: any = new EventEmitter();
  @Output() noteSelected: any = new EventEmitter();
  @Input() commissionStatus: string;

  displayedColumns: string[] = ['Movements', 'Comm. Bank Purchase', 'Comm. Company Purchase',
    'Comm. Bank Collect', 'Comm. Company Collect', 'Comm. Final Bank', 'Comm. Final Company'];

  ngOnInit() {
    this.setTranslations();
  }

  clear() {
    this.resetTableAllRiskValue.emit();
  }

  apply() {
    this.applyRisks.emit();
  }

  updateAction($event) {
    this.actionSelected.emit($event);
  }

  updateNotes($event) {
    this.noteSelected.emit($event);
  }

  setTranslations() {
    const keys$ = of([
      'ICD.MODAL_CONFIRM_TEXT_MULTI',
    ]);
    keys$.pipe(
        mergeMap(r => this.translateService.translateAll(...r)) ).subscribe(next => {
      this.modalConfirmText = next[0];
    });
  }
}

import { AfterViewInit, Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { PolicyModel } from '../../../models/policy.model';
import { ScheduledPremiumFactor } from './models/scheduledPremiumFactors';
import { KarmaProfileDefinition } from '../../../../life-card/lic-karma-funds/model/karma-profile-definition';
import { RgiCountryLayerNumberFormatPipe  } from '@rgi/country-layer';
import { PolicyService } from '../../../../life-card/services/policy-service';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { InvestmentUtils } from '../../utils/lic-investments-utils';

@Component({
  selector: 'lic-scheduled-premium',
  templateUrl: './lic-scheduled-premium.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduledPremiumComponent),
      multi: true
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => ScheduledPremiumComponent),
        multi: true
    }
  ]
})
export class ScheduledPremiumComponent implements AfterViewInit, ControlValueAccessor {
  @Input() formGroup: UntypedFormGroup;
  @Input() active: number;
  @Input() karmaProfileDefinitions: KarmaProfileDefinition[];
  @Input() selectedScheduledProfileDefinitions: KarmaProfileDefinition[];
  @Input() mainProposal: PolicyModel;
  @Input() sliderProperty: string | null;
  @Input() isActive: boolean;
  @Input() isDistributionSameAsInitial: boolean;
  @Input() isScheduledPremiumActive: boolean;
  scheduledPremiumFactors: ScheduledPremiumFactor[];

  constructor(
    protected rgiFormatter: RgiCountryLayerNumberFormatPipe,
    protected policyService: PolicyService,
    protected translateService: TranslationWrapperService,
  ) { }

  writeValue(value: any): void {
    if (value) {
      this.formGroup.patchValue(value, { emitEvent: false });
    }
  }

  ngAfterViewInit(): void {
    const options: Intl.NumberFormatOptions = this.policyService.getFormatterOptionsWithDecimal('currency');
    this.scheduledPremiumFactors = [
        new ScheduledPremiumFactor(
          this.translateService.getImmediate('lic_amount'), 'amount', this.rgiFormatter.transform(
              this.mainProposal.proposal.policyPremium.successiveInstalmentGross,
              this.policyService.currentLocale,
              options
            ),
            'STRING'),
        new ScheduledPremiumFactor(
            this.translateService.getImmediate('lic_frequency'), 'frequency', this.mainProposal.proposal.policyVersion.fractionation.description, 'STRING')
    ];
  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
      if (InvestmentUtils.checkIfFundsInvestmentCompleted(this.formGroup)) {
        this.formGroup.get('funds').setErrors(null);
    } else if (InvestmentUtils.checkIfProfileInvestmentCompleted(this.formGroup)) {
        this.formGroup.get('profiles').setErrors(null);
    }
      return null;
  }

}


import { Injectable } from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ReportService } from '../services/report.service';
import { CardAnalyticsStateHome } from './card-analytics-state-manager-home';
import {
  ANALYTICS_ROUTE_DASHBOARD,
  ANALYTICS_ROUTE_EDITREPORT,
  ANALYTICS_ROUTE_HOME,
  ANALYTICS_ROUTE_NEWREPORT
} from '../constants/analytics-routes-constants';


@Injectable({
  providedIn: 'root'
})
export class CardAnalyticsReducerService {

  constructor(private router: RoutingService, private extendedReportService: ReportService) {
  }

  initPageOne(st$: Observable<CardAnalyticsStateHome>, activeRoute: ActiveRoute): Observable<CardAnalyticsStateHome> {
    return combineLatest(st$, this.extendedReportService.getAllReports()).pipe(
      mergeMap(([st, reports]) => {
        st.reports = reports;
        return combineLatest(of(st), this.extendedReportService.getAllReports())
      }),
      map(([st, reports]) => {
        st.reports = reports
        return st;
      })
    );
  }

  get reportService() {
    return this.extendedReportService;
  }

  getAdminEnabled() {
    return false;
  }

  getQlikLinkEnabled() {
    return false;
  }

  openDashboard(template, activeRoute: ActiveRoute) {
    this.router.navigate(this.getFullRoute(ANALYTICS_ROUTE_DASHBOARD), template, activeRoute.id);
  }

  editReport(report, activeRoute: ActiveRoute) {
    this.router.navigate(this.getFullRoute(ANALYTICS_ROUTE_EDITREPORT), report, activeRoute.id);
  }

  copyReport(report, activeRoute: ActiveRoute) {

  }

  deleteReport(template, activeRoute: ActiveRoute) {

  }

  backHome(activeRoute: ActiveRoute) {
    this.router.navigate(this.getFullRoute(ANALYTICS_ROUTE_DASHBOARD), {}, activeRoute.id);
  }

  openCreateReport(activeRoute: ActiveRoute) {
    this.router.navigate(this.getFullRoute(ANALYTICS_ROUTE_NEWREPORT), {}, activeRoute.id);
  }

  removeSession(activeRoute: ActiveRoute) {
    this.router.navigate(ANALYTICS_ROUTE_HOME, {}, activeRoute.id)
  }

  public getFullRoute(routeName: string) {
    return ANALYTICS_ROUTE_HOME + '/' + routeName;
  }

}

import {Injectable} from '@angular/core';
import {RoutingService} from '@rgi/rx/router';
import {
  ROUTES_GP_EDIT_ISSUE,
  ROUTES_GP_PROP_ISSUE,
  ROUTES_GP_VCONT_DATES
} from '../group-policy-constants/routes-constants';
import {DataActions, Operation} from '../group-policy-models/group-policy-inquiry';
import {GroupPolicyResourceService} from '../group-policy-resources/group-policy-resource.service';
import {GroupPolicyCrossService} from './group-policy-cross.service';
import {GroupPolicyIntegrationService} from './group-policy-integration.service';
import {DataForSteps} from '../group-policy-models/group-policy-issue-home';
import {OPERATIONSCODE} from '../group-policy-constants/general';
import {ModalService} from '@rgi/rx/ui';
import {
  AmendmentModalData,
  GpAmendmentModalComponent
} from '../group-policy-components/group-policy-modals/group-policy-modal-amendment/gp-amendment-modal.component';

@Injectable()
export class GroupPolicyActionIntegrationService {

  constructor(
    protected routingService: RoutingService,
    protected policyResources: GroupPolicyResourceService,
    protected crossService: GroupPolicyCrossService,
    protected integrationService: GroupPolicyIntegrationService,
    protected modalService: ModalService
  ) { }


  executeAction(_operation: Operation, _data: DataActions) {
    switch (_operation.code) {
      case 'PMPROP':
        this.integrationService.navigate(this.routingService, ROUTES_GP_EDIT_ISSUE,
          { proposalNumber: _data.proposalNumber, isFromInquiry: true, contractStatus: _data.contractStatus,
            managementNode: _data.managementNode} as DataForSteps, _data.activeRoute);
        break;
      case '_EMPRO':
        this.integrationService.navigate(this.routingService, ROUTES_GP_PROP_ISSUE,
          { proposalNumber: _data.proposalNumber, isFromInquiry: true,
            managementNode: _data.managementNode } as DataForSteps, _data.activeRoute);
        break;
      case '_PEMPO':
        this.policyResources.askBatch$(_operation.code, _data.proposalNumber).subscribe(resp => {
            if (!resp) {
              this.crossService.openBaseModal('_GP_._MODAL_._OPERATION_').subscribe();
            }
          }
        );
        break;
      case '_REVPR':
        this.policyResources.contractRevocation$(_data.proposalNumber).subscribe();
        break;
      case '_VCONT':
        this.integrationService.navigate(this.routingService, ROUTES_GP_VCONT_DATES, {
          proposalNumber: _data.proposalNumber,
          isFromInquiry: true,
          node: _data.node
        }, _data.activeRoute);
        break;
      case 'LMVAPM':
        const amendModal = this.modalService.open(
          GpAmendmentModalComponent,
          new AmendmentModalData(_data.lastMovEffDate, _data.expireDate));
        amendModal.enableClickBackground = false;
        amendModal.onClose.subscribe(amendmentEffDate => {
          if (amendmentEffDate) {
            this.policyResources.createAmendmentProposal$(_data.proposalNumber, amendmentEffDate).subscribe(resp => {
                if (resp) {
                  const nextStepParam = {
                    resId: resp.uuid,
                    operationCode: OPERATIONSCODE.ISSUE,
                    contractStatus: _data.contractStatus,
                    managementNode: _data.managementNode
                  } as DataForSteps;

                  this.integrationService.goToEdit(nextStepParam);
                }
              }
            );
          }
        });
        break;
      case 'DI05':
        this.policyResources.masterPolicySubstitution$(_data.proposalNumber).subscribe(resp => {
            if (resp) {
              const nextStepParam = {
                resId: resp.uuid,
                idParentSession: null,
                operationCode: OPERATIONSCODE.ISSUE,
                managementNode: _data.managementNode
              } as DataForSteps;
              this.integrationService.goToEdit(nextStepParam);
            }
          }, error => {
            if (error.status > 400 && error.status < 500) {
              this.crossService.openBaseModal(error.errorBody.messages[0]).subscribe();
            }
          }
        );
    }
  }

}

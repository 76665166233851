import {RGI_RX_MULTISELECT_i18n} from './i18n/localization';

import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';

export function load18nMultiselectIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_MULTISELECT_i18n.it);
  });
}

export function load18nMultiselectEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_MULTISELECT_i18n.en);
  });
}

export function load18nMultiselectFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_MULTISELECT_i18n.fr);
  });
}

export function load18nMultiselectES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_MULTISELECT_i18n.es);
  });
}

export function load18nMultiselectDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_MULTISELECT_i18n.de);
  });
}


export const RGI_RX_MULTISELECT_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: load18nMultiselectIT
  },
  {
    locale: 'en',
    load: load18nMultiselectEN
  },
  {
    locale: 'fr',
    load: load18nMultiselectFR
  },
  {
    locale: 'es',
    load: load18nMultiselectES
  },
  {
    locale: 'de',
    load: load18nMultiselectDE
  }
];

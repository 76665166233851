import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { RgiRxDataTableSort } from '../data-table-api';
import { RgiRxTableColumnSorted, RgiRxTableSortComparatorDef, RgiRxTableSortOrder, TableRowTypeSchema } from '../table-api';
import {RgiRxHeaderCell} from '../cell-directives';


@Component({
  selector: 'rgi-rx-table-header-sort',
  templateUrl: './rgi-rx-table-header-sort.component.html',
  host: {
    class: 'rgi-ui-header-cell-sort',
    role: 'button',
    '[attr.aria-disabled]': 'disabled ? "true" : "false"'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RgiRxTableHeaderSortComponent<T> implements OnInit, OnDestroy {

  @Input() rowSchema: TableRowTypeSchema;
  @Input() comparator?: RgiRxTableSortComparatorDef<T>;
  @Input() disabled = false;
  @Output() sorted = new EventEmitter<RgiRxTableColumnSorted>();

  order?: RgiRxTableSortOrder;
  private tableSortSubscription: Subscription = Subscription.EMPTY;
  private _isActive = false;

  constructor(
    private tableSort: RgiRxDataTableSort<T>,
    private cdr: ChangeDetectorRef,
    @Optional() private _headerCell?: RgiRxHeaderCell
  ) { }

  ngOnInit(): void {
    if (!this.tableSort.isConnected()) {
      this.tableSort.connect();
    }

    this.tableSortSubscription = this.tableSort.changes$
      .subscribe(
        next => {
          this._isActive = next !== null && (next.column === this.rowSchema.name);
          if (this._isActive) {
            this.sorted.emit({
              order: this.order,
              schema: this.rowSchema
            });
          } else { // restore default with another changes
            this.order = undefined;
          }
          if (this._headerCell) {
            this._headerCell.ariaSort = this.order;
          }
          this.cdr.markForCheck();
        }
      );
  }

  sortHeader() {
    if (this.order === undefined) {
      this.order = 'asc';
    } else if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = undefined;
      this.tableSort.remove();
      return;
    }
    this.tableSort.sort(this.rowSchema.name, this.order, this.comparator);
  }

  ngOnDestroy(): void {
    this.tableSort.dispose();
    this.tableSortSubscription.unsubscribe();
  }

  isActive(): boolean {
    return this._isActive;
  }

  @HostListener('click', ['$event'])
  _handleClick(event: MouseEvent){
    if (this.disabled) {
      event.stopPropagation()
      return;
    }
    this.sortHeader();
  }

}

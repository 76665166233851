import {PcPaymentFrequency} from './../../../models/pc-portfolio-models/payment-frequency-models/pc-payment-frequency';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, share} from 'rxjs/operators';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioPaymentFrequencyResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
    private refactorService: ReIssueRefactorService
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;
  }

  getPaymentFrequencies(proposalId: string): Observable<Array<PcPaymentFrequency>> {
    return this.http.get<Array<PcPaymentFrequency>>(this.getPaymentFrequenciesUri(proposalId)).pipe(
      share()
    );
  }

  putPaymentFrequency(proposalId: string, paymentFrequency: PcPaymentFrequency): Observable<PcPaymentFrequency> {
    return this.http.put<PcPaymentFrequency>(
      this.getPaymentFrequencyUri(proposalId),
      paymentFrequency
    ).pipe(
      share()
    );
  }

  getPaymentFrequency(proposalId: string): Observable<PcPaymentFrequency> {
    return this.http.get<PcPaymentFrequency>(
      this.getPaymentFrequencyUri(proposalId)
    ).pipe(
      share(),
      catchError((err, caught) => {
        return of(null);
      })
    );
  }

  getPaymentFrequenciesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/payment-frequencies';
  }

  getPaymentFrequencyUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/payment-frequency';
  }
}

import {NgModule} from '@angular/core';
import {NgPassproproCoreModule} from "@rgi/ng-passpropro-core"

import {FacadeInteropModule} from "./facade-interop/facade-interop.module";

@NgModule({
  declarations: [],
  imports: [
    NgPassproproCoreModule,
    FacadeInteropModule
  ],
  providers: [],
  exports: [
    NgPassproproCoreModule
  ]
})
/**
 * @module NgPassproproCoreAjsModule
 * Use this module to integrate NgPassProProCore with existing Angular js apps.
 */
export class NgPassproproCoreAjsModule {

  constructor() {

  }
}

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FundPercentageError} from '../../model/FundPercentageError';

@Component({
  selector: 'lic-funds-percentage-message',
  templateUrl: './lic-funds-percentage-message.component.html',
  styleUrls: ['./lic-funds-percentage-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicFundsPercentageMessageComponent implements OnInit {

  @Input() error: FundPercentageError;
  constructor() { }

  ngOnInit() {
  }

}

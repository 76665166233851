<div class="anag-w-100 anag-section-card">
  <div class="anag-section-card-header">
    <span class="anag-section-card-icon rgi-ui-icon-ati"></span>
    <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._ATI_ASSOCIATIONS_' | translate}}</span>
  </div>
  <div class="anag-section-content">
    <form [formGroup]="atiLinksForm">

      <div class="rgi-ui-text-4"><b translate>_ANAG_._LABEL_._LEADER_</b></div>
      <rgi-rx-drop-container
        *ngIf="atiLinksForm.get('leaderDropContainer')"
        formControlName="leaderDropContainer"
        viewField="nominative"
        [select]="'searchSubject'"
        data-qa="anag-ati-party-leader"
        (onValueChange)="onDropSubjectSelection($event, '23')"
        (onRemove)="removeLink($event)">
      </rgi-rx-drop-container>

      <div class="rgi-ui-text-4"><b translate>_ANAG_._LABEL_._PARTICIPANTS_</b></div>
      <rgi-rx-drop-list-container
        (onValueChange)="onDropSubjectSelection($event, '24')"
        formControlName="participantDropContainer"
        viewField="nominative"
        select="searchSubject"
        (onRemove)="removeLink($event)">
      </rgi-rx-drop-list-container>
    </form>
  </div>
</div>


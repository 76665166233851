import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MeansOfPayment } from '../models/meansofpayment.model';
import { EnviromentsService } from './enviroments.service';
import { PolicyService } from './policy-service';

@Injectable({
  providedIn: 'root'
})
export class CustomPropertiesService {
  protected baseApiUrl: string;
  protected getCustomProp: string;
  protected getMeanPayment: string;

  private $cachedCedolaPayments: MeansOfPayment = null;

  set cachedCedolaPayments(res) {
    this.$cachedCedolaPayments = res;
  }
  get cachedCedolaPayments() {
    return this.$cachedCedolaPayments;
  }

  constructor(protected environment: EnviromentsService,
              protected httpClient: HttpClient,
              protected policyService: PolicyService) {
    this.baseApiUrl = environment.baseApiURL;

  }


  getPaymentMethods(tipoOwner: number, codiceProprieta: string, propertyType: number): Observable<any> {
    const productCode = this.policyService.mainProposal.quote.product.code;
    this.getCustomProp = '/v2/life/properties?tipoOwner=' + tipoOwner + '&codiceProdotto=' + productCode + '&codiceProprieta=' +
                           codiceProprieta + '&propertyType=' + propertyType;
    return this.httpClient.get(this.baseApiUrl + this.getCustomProp);
  }

  get_meansOfPayment(request: any): Observable<any> {
    this.getMeanPayment = this.baseApiUrl + '/admin/meansofpayment';
    return this.httpClient.post(this.getMeanPayment, request);
  }


}

export class Agency {

    public idSp: string;
    public extension: string;
    public code: string;
    public description: string;

    constructor() {}

}

<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()"><span translate>RE_ISSUE.COMMISSIONS</span>
  </rgi-rx-panel-header>

  <div class="modal-body large-modal">
    <!-- COMMISSIONS TABLE -->
    <form [formGroup]="commissionForm">


      <br><br>
      <table rgi-rx-table [dataSource]="COMMISSION_DATA">

        <ng-container rgiRxColumnDef="derogation">
          <td rgi-rx-header-cell *rgiRxHeaderCellDef><span
            translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.AUTHORITY_LIMIT</span></td>
          <td rgi-rx-cell *rgiRxCellDef="let row"><span translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.ALL_RISK</span></td>
        </ng-container>
        <ng-container rgiRxColumnDef="perc_net_acq">
          <div>
            <td rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.ACQUIRED_COMMISSION</span>
            </td>
            <td rgi-rx-cell *rgiRxCellDef="let row"><input rgiRxInput class="div-table-row-input" type="number"
                                                           formControlName="perc_net_acq" data-qa="perc_net_acq"></td>
          </div>
        </ng-container>
        <ng-container rgiRxColumnDef="perc_acc_acq">
          <div class="div-table-row-input">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.ACQUIRED_CHARGES</span>
            </td>
            <td rgi-rx-cell *rgiRxCellDef="let row"><input rgiRxInput class="div-table-row-input" type="number"
                                                           formControlName="perc_acc_acq" data-qa="perc_acc_acq"></td>
          </div>
        </ng-container>
        <ng-container rgiRxColumnDef="perc_net_inc">
          <div class="div-table-row-input">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.COLLECTED_COMMISSION_NET</span>
            </td>
            <td rgi-rx-cell *rgiRxCellDef="let row"><input type="number" rgiRxInput class="div-table-row-input"
                                                           formControlName="perc_net_inc" data-qa="perc_net_inc"></td>
          </div>
        </ng-container>
        <ng-container rgiRxColumnDef="perc_acc_inc">
          <div class="div-table-row-input">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.COLLECTED_CHARGES</span>
            </td>
            <td rgi-rx-cell *rgiRxCellDef="let row"><input type="number" rgiRxInput class="div-table-row-input"
                                                           formControlName="perc_acc_inc" data-qa="perc_acc_inc"></td>
          </div>
        </ng-container>
        <ng-container rgiRxColumnDef="functions">
          <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
          <td rgi-rx-cell *rgiRxCellDef="let row">
            <button (click)="assign()" [disabled]="!allRiskFormValid" class="rgi-ui-btn rgi-ui-btn-primary pull-right" data-qa="assign">
              <span translate uppercase>RE_ISSUE.QUOT_COMMISSIONS.LABEL.ASSIGN</span>
            </button>
          </td>
        </ng-container>

        <tr rgi-rx-header-row *rgiRxHeaderRowDef="headerDisplayedColumns"></tr>
        <tr rgi-rx-row *rgiRxRowDef="let row; columns: headerDisplayedColumns;"></tr>

      </table>

      <!-- EDIT VALUES -->

      <br>
      <rgi-rx-snackbar tag="COMMISSION_MODAL_ALLRISK"></rgi-rx-snackbar>
      <br>

      <rgi-rx-form-field>
        <span class="px-4" translate>RE_ISSUE.EDIT_AMOUNT</span>
        <rgi-rx-switch formControlName="edit" data-qa="commissions.edit_amount"></rgi-rx-switch>
      </rgi-rx-form-field>

      <!-- DEROGATION SIGN RATE TABLE -->

      <br><br>
      <table rgi-rx-table [dataSource]="commissions">

        <ng-container rgiRxColumnDef="checkboxField">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef translate class="pl-0 rgi-ui-table-title">
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.DEROGATED
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row">
            <div *ngIf="row.commissionType === 1">
              <input type="checkbox" rgiRxInput
                     formControlName="checkboxField_{{row?.risk?.id}}" attr.data-qa="checkboxField_{{row?.risk?.id}}">
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef></td>
        </ng-container>

        <ng-container rgiRxColumnDef="signedCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef translate class="pl-0 rgi-ui-table-title">
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.SIGNED_COMMISSIONS
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row" class="pl-0"> {{row?.risk?.description}}</td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef translate class="pl-0">RE_ISSUE.QUOT_COMMISSIONS.LABEL.TOTAL</td>
        </ng-container>


        <ng-container rgiRxColumnDef="netPurchaseCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.ACQUIRED_COMMISSION
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row">
            <div class="rgi-re-display-flex">
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row
                                         field="netPercPurchaseCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row field="netPurchaseCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef>
            <div class="rgi-re-display-flex">
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"></div>
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50" data-qa='subscriptionTotals.netCommissionsPurchase'>
                {{commissionTotal?.subscriptionTotals?.netCommissionsPurchase | number : '1.2-2'}}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="feePurchaseCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.ACQUIRED_CHARGES
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row">
            <div class="rgi-re-display-flex">
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row
                                         field="feePercPurchaseCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row field="feePurchaseCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef>
            <div class="rgi-re-display-flex">
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"></div>
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"
                   data-qa='subscriptionTotals.accessoriesCommissionsPurchase'>
                {{commissionTotal?.subscriptionTotals?.accessoriesCommissionsPurchase | number : '1.2-2'}}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="netCollectionCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.COLLECTED_COMMISSION_NET
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row">
            <div class="rgi-re-display-flex">
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row
                                         field="netPercCollectionCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row field="netCollectionCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef>
            <div class="rgi-re-display-flex">
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"></div>
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"
                   data-qa='subscriptionTotals.netCommissionsCollection'>
                {{commissionTotal?.subscriptionTotals?.netCommissionsCollection | number : '1.2-2'}}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="feeCollectionCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.COLLECTED_CHARGES
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row">
            <div class="rgi-re-display-flex">
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row
                                         field="feePercCollectionCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
              <re-issue-commission-input [formGroup]="commissionForm" [commission]=row field="feeCollectionCommissions"
                                         class="rgi-ui-flx-g1-width-50"></re-issue-commission-input>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef>
            <div class="rgi-re-display-flex">
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"></div>
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"
                   data-qa='subscriptionTotals.accessoriesCommissionsCollection'>
                {{commissionTotal?.subscriptionTotals?.accessoriesCommissionsCollection | number : '1.2-2'}}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="totCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.TOTAL
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row">
            <div class="rgi-re-display-flex">
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"
                   attr.data-qa="{{'totPercCommissions_' + row?.risk?.id}}">
                {{formatValue(row?.subscriptionInstalment?.totPercCommissions, row.commissionType)}}
              </div>
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"
                   attr.data-qa="{{'totCommissions_' + row?.risk?.id}}">
                {{formatValue(row?.subscriptionInstalment?.totCommissions, row.commissionType)}}
              </div>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef>
            <div class="rgi-re-display-flex">
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50"></div>
              <div class="rgi-ui-provv-cell rgi-ui-flx-g1-width-50" data-qa='totalInstallment'>
                {{commissionTotal?.subscriptionTotals?.totalInstallment | number : '1.2-2'}}
              </div>
            </div>
          </td>
        </ng-container>

        <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
        <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
        <tr rgi-rx-footer-row *rgiRxFooterRowDef="displayedColumns" class="rgi-ui-row rgipc-bg-info"></tr>

      </table>
      <br>
      <rgi-rx-snackbar tag="COMMISSIONS_MODAL"></rgi-rx-snackbar>
      <br>
      <!-- DEROGATION NEXT RATE TABLE -->

      <table rgi-rx-table [dataSource]="commissions" class="nextInstalment">
        <ng-container rgiRxColumnDef="nextInstalment.signedCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.NEXT_INSTALMENT_COMMISSIONS
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row"> {{row?.risk?.description}}</td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.TOTAL
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="nextInstalment.netPurchaseCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.ACQUIRED_COMMISSION
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.netPercPurchaseCommissions, row.commissionType)}}</div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.netPurchaseCommissions, row.commissionType)}}</div>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-d-flex rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div class="rgi-ui-cell"></div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{commissionTotal?.nextInstalmentTotals?.netCommissionsPurchase | number : '1.2-2'}}</div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="nextInstalment.feePurchaseCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.ACQUIRED_CHARGES
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.feePercPurchaseCommissions, row.commissionType)}}</div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.feePurchaseCommissions, row.commissionType)}}</div>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div class="rgi-ui-cell"></div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{commissionTotal?.nextInstalmentTotals?.accessoriesCommissionsPurchase | number : '1.2-2'}}</div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="nextInstalment.netCollectionCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.COLLECTED_COMMISSION_NET
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.netPercCollectionCommissions, row.commissionType)}}</div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.netCollectionCommissions, row.commissionType)}}</div>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div class="rgi-ui-cell"></div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{commissionTotal?.nextInstalmentTotals?.netCommissionsCollection | number : '1.2-2'}}</div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="nextInstalment.feeCollectionCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.COLLECTED_CHARGES
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.feePercCollectionCommissions, row.commissionType)}}</div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.feeCollectionCommissions, row.commissionType)}}</div>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div class="rgi-ui-cell"></div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{commissionTotal?.nextInstalmentTotals?.accessoriesCommissionsCollection | number : '1.2-2'}}</div>
            </div>
          </td>
        </ng-container>

        <ng-container rgiRxColumnDef="nextInstalment.totCommissions">
          <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
            RE_ISSUE.QUOT_COMMISSIONS.LABEL.TOTAL
          </th>
          <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.totPercCommissions, row.commissionType)}}</div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{formatValue(row?.nextInstalment?.totCommissions, row.commissionType)}}</div>
            </div>
          </td>
          <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
            <div class="rgipc-d-flex rgipc-2cell-col">
              <div class="rgi-ui-cell"></div>
              <div
                class="rgi-ui-cell rgipc-text-right">{{commissionTotal?.nextInstalmentTotals?.totalInstallment | number : '1.2-2'}}</div>
            </div>
          </td>
        </ng-container>

        <tr rgi-rx-header-row *rgiRxHeaderRowDef="nextInstalmentDisplayedColumns"></tr>
        <tr rgi-rx-row *rgiRxRowDef="let row; columns: nextInstalmentDisplayedColumns;"></tr>
        <tr rgi-rx-footer-row *rgiRxFooterRowDef="nextInstalmentDisplayedColumns" class="rgi-ui-row rgipc-bg-info"></tr>

      </table>
      <br><br>

      <!-- Derogation Commission Schema -->
      <br><br>
      <rgi-rx-form-field>
        <label rgiRxLabel translate>RE_ISSUE.QUOT_COMMISSIONS.LABEL.ACQUIRED_COMMISSIONS_MANDATE</label>
        <select rgiRxNativeSelect data-qa='purchComm' formControlName="purchComm">
          <option *ngFor="let value of acquiredCommissionsMandates; index as i;" [value]="value?.code">
            {{ value?.description }}</option>
        </select>
      </rgi-rx-form-field>
    </form>
  </div>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" data-qa='commissions.close' translate>
      RE_ISSUE.CLOSE
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" [disabled]="!formValid" (click)="recalculate()" data-qa='commissions.recalculate' translate>
      RE_ISSUE.RECALCULATE_SAVE
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

import { Injectable } from '@angular/core';
import { DamageLocation } from '../dto/policy/damage-location';
import { AddressData } from '../dto/address';
import { Toponym } from '../dto/policy/toponym';

@Injectable({
  providedIn: 'root'
})
export class AddressTranslatorService {

  public addressDataToDamageLocation(address: AddressData): DamageLocation {
    const damageLocation = new DamageLocation();

    damageLocation.address = address.address;
    damageLocation.adminLevel2 = address.adminLevel2;
    damageLocation.adminLevel3 = address.adminLevel3;
    damageLocation.city = address.city;
    damageLocation.houseNumber = address.houseNumber;
    damageLocation.idToponym = new Toponym();
    damageLocation.idToponym.code = address.toponym;
    damageLocation.idToponym.description = address.toponym;
    damageLocation.nationAbbreviation = address.countryCode;
    damageLocation.province = address.province;

    console.log('AddressTranslatorService::addressDataToDamageLocation - address', address);
    console.log('AddressTranslatorService::addressDataToDamageLocation - damageLocation', damageLocation);

    return damageLocation;
  }

  public damageLocationToAddressData(address: DamageLocation): AddressData {
    const addressData = new AddressData(
      address.nationAbbreviation,
      address.zipCode,
      address.city,
      address.address,
      address.houseNumber,
      address.nationAbbreviation,
      address.adminLevel2,
      address.adminLevel3,
      address.idToponym.description,
      address.province
    );

    console.log('AddressTranslatorService::damageLocationToAddressData - address', address);
    console.log('AddressTranslatorService::damageLocationToAddressData - addressData', addressData);

    return addressData;
  }

  constructor() { }
}

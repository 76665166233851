<ng-container  [ngSwitch]="inputType">
  <rgi-rx-form-field *ngSwitchCase="ADDRESS_INPUT_TYPE.AUTOCOMPLETE" [formGroup]="formGroup" >
    <label rgiRxLabel>{{!!addressConfigField ? addressConfigField.label : ""}}</label>
    <input type="text" rgiRxInput (focusin)="restore()" (blur)="onZipBlur(fieldName)" [rgiRxAutoComplete]="autoCompleteComponent"
           [formControlName]=fieldName
           [openOnFocus]="true" [onlyMatching]="fieldName!=='zip' && isOptionListAvailable" [attr.data-qa]="'umaaddr-'+fieldName"
            (onChange)="onAutoCompleteChange($event)" (onValueChange)="onAutoCompleteValueChange($event)">
    <rgi-rx-auto-complete #autoCompleteComponent>
      <ng-template rgiRxAutoCompleteContent>
        <ng-container *ngIf="(filteredOptions | async) as opts">
          <rgi-rx-option *ngFor="let option of opts" [value]="option[optValue]" [attr.data-qa]="option[optLabel]">
            {{option[optLabel]}}
          </rgi-rx-option>
        </ng-container>
      </ng-template>
    </rgi-rx-auto-complete>
  </rgi-rx-form-field>
  <rgi-rx-form-field *ngSwitchCase="ADDRESS_INPUT_TYPE.SELECT" [formGroup]="formGroup">
    <label rgiRxLabel>{{!!addressConfigField ? addressConfigField.label : ""}}</label>
    <select rgiRxNativeSelect [formControlName]=fieldName [attr.data-qa]="'umaaddr-'+fieldName">
      <option selected></option>
      <option *ngFor="let opts of filteredOptionsValues" [value]="opts[optValue]">
        {{opts[optLabel]}}
      </option>
    </select>
  </rgi-rx-form-field>
  <rgi-rx-form-field *ngSwitchCase="ADDRESS_INPUT_TYPE.INPUT" [formGroup]="formGroup">
    <input rgiRxInput [formControlName]=fieldName [attr.data-qa]="'umaaddr-'+fieldName">
    <label rgiRxLabel>{{!!addressConfigField ? addressConfigField.label : ""}}</label>
  </rgi-rx-form-field>
</ng-container>


<div modal-render="true" tabindex="-1" role="dialog" class="modal fade in" uib-modal-animation-class="fade"
    modal-in-class="in" ng-style="{'z-index': 1050 + index*10, display: 'block'}" uib-modal-window="modal-window"
    index="0" animate="animate" modal-animation="true" style="z-index: 1050; display: block;">

    <div class="modal-dialog  modal-lg">
        <div class="modal-new-close" (click)="cancel()">
            CHIUDI
        </div>
        <div class="modal-content">
            <div class="modal-new-style">
                <div class="modal-header">
                    <h3 class="modal-title" translate>Technical Card</h3>
                </div>
                <div class="modal-body modal-body-max">
                    <div class="title-modal-quotation-axa">
                        <span translate>Policy quotes</span>
                    </div>

                    <div class="row content-modal-quotation-axa tbld_linked_policies">
                        <div class="tbld">
                            <div class="tbld_row tbld_row_even">
                                <div class="tbld_col tbld_col_title"><span translate>Percentuale nostra</span>
                                    {{coass.ourCoassPercentage}}
                                </div>
                            </div>
                        </div>
                        <div class="tbld">
                            <div class="tbld_row tbld_row_header">
                                <div class="tbld_col tbld_col_title">% Quota</div>
                                <div class="tbld_col tbld_col_title">% Provvigioni</div>
                                <div class="tbld_col tbld_col_title">Compagnia</div>
                                <div class="tbld_col tbld_col_title">Intermediario</div>
                                <div class="tbld_col tbld_col_title">Numero Polizza</div>
                                <div class="tbld_col tbld_col_title">&nbsp;</div>
                                <div class="tbld_col tbld_col_title">&nbsp;</div>
                            </div>
                            <div class="tbld_row tbld_row_odd" *ngIf="!coassQuotes.quotas || coassQuotes.quotas.length < 1">
                                <div class="tbld_col tbld_col_value"> Nessuna quota</div>
                                <div class="tbld_col tbld_col_value"></div>
                                <div class="tbld_col tbld_col_value"></div>
                                <div class="tbld_col tbld_col_value"></div>
                                <div class="tbld_col tbld_col_value"></div>
                                <div class="tbld_col tbld_col_value"></div>
								<div class="tbld_col tbld_col_value"></div>
                            </div>
                            <div class="tbld_row tbld_row_odd" *ngFor="let quote of coassQuotes.quotas">
                                <div class="tbld_col tbld_col_value">{{quote.ourPerc}}</div>
                                <div class="tbld_col tbld_col_value">{{quote.commissionsPerc}}</div>
                                <div class="tbld_col tbld_col_value">{{getCompany(quote)}}</div>
                                <div class="tbld_col tbld_col_value">{{getIntermediary(quote)}}</div>
                                <div class="tbld_col tbld_col_value">{{quote.policyNumber}}</div>
                                <div class="tbld_col tbld_col_value">
                                    <button class="btn btn-default btn-xs pull-right" style="border: none" (click)="editQuoteCoass(quote)">
                                        <span class="icon rgifont rgi-pencil-square-o"></span>
                                    </button>
                                </div>
                                <div class="tbld_col tbld_col_value">
                                    <button class="btn btn-default btn-xs pull-right" style="border: none" (click)="deleteQuoteCoass(quote)">
                                        <span class="glyphicon glyphicon-remove"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="tbld">
                            <div class="tbld_row tbld_row_odd">
                                <div class="tbld_col tbld_col_value"><span translate="">Percentuale totale</span>: <span
                                        class="{{coass.totalPercentage}} > 100 ? 'error-text' : ''}}"
                                        class="error-text">{{coass.totalPercentage}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-white" (click)="cancel()" translate>CLOSE</button>
                    <button class="btn btn-primary" (click)="newQuoteCoass()" translate>NEW</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop fade in" uib-modal-animation-class="fade" modal-in-class="in"
    uib-modal-backdrop="modal-backdrop" modal-animation="true" style="z-index: 1040;"></div>

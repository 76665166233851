import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { PV_TOKEN } from '../models/consts/lpc-consts';
import {PremiumDetailObj, Profile} from '../models/life-detail.model';

@Injectable({
  providedIn: 'root'
})
export class ProposalDetailService {

  private readonly baseApiUrl: string;
  private readonly baseApiUrlV2: string;
  private readonly baseApiUrlPass: string;

  constructor(
    @Inject(PV_TOKEN.ENV) environment: any,
    private httpClient: HttpClient
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';
    this.baseApiUrlPass = this.baseApiUrl + environment.api.gateway.path;
  }

  public cancelProposal(proposalNumber: string): Observable<any> {
    return this.httpClient.delete<any>(this.baseApiUrl + '/v2/life/proposals/' + proposalNumber) as Observable<any>;
  }

  public cancelPrev(proposalNumber: string): Observable<any> {
    return this.httpClient.delete<any>(this.baseApiUrl + '/v2/life/estimate/' + proposalNumber) as Observable<any>;
  }

  public proposalIssue(contractId: string, nodeCode: string, authId: string) {
    return this.httpClient.put(this.baseApiUrlV2 + '/life/auth/proposalIssue/' + contractId
            + '?userCode=' + nodeCode + '&authId=' + authId, {});
  }

  public policyIssue(contractId: string, nodeCode: string) {
    return this.httpClient.put(this.baseApiUrlV2 + '/life/auth/policyIssue/' + contractId
            + '?userCode=' + nodeCode, {});
  }

  public getLifeProposal(propNumber: string): Observable<{output: any}> {
    return this.httpClient.get<{output: any}>(this.baseApiUrlPass + '/ptflife/proposal/quote/' + propNumber);
  }

  public getQuoteFromProposal(propNumber: string, quoteMode: boolean) {
    return this.httpClient.get(this.baseApiUrlPass + '/ptflife/proposal/quote/' + propNumber + '?quoteMode=' + quoteMode);
  }

  public getBenefitVersion(policyNumber: string, riskCode: string, idMovement = null): Observable<any> {
    const movement = !!idMovement ? '&idMovement=' + idMovement : '';
    return this.httpClient
    .get(this.baseApiUrlV2 + '/life/policies/' + policyNumber + '/benfitsversions?riskcode=' + riskCode + movement);
  }

  public getFiscalData(proposalNumber: string, idMovement: number): Observable<any> {
    if (idMovement) {
      return this.httpClient.get(this.baseApiUrlV2 + '/life/policies/' + proposalNumber + '/fiscal_data?idMovement=' + idMovement);
    }
    return this.httpClient.get(this.baseApiUrlV2 + '/life/policies/' + proposalNumber + '/fiscal_data');
  }


  public getPremiumDetail(policyNumber: string, idMovement = null): Observable<PremiumDetailObj> {
    const movement = !!idMovement ? '?idMovement=' + idMovement : '';
    return this.httpClient.get<PremiumDetailObj>(this.baseApiUrlV2 + `/life/policies/${policyNumber}/premium-details${movement}`);
  }

  public getWarrantydetail(idWarrInst: string, idMov: string): Observable<any> {
    return this.httpClient
    .get(this.baseApiUrlV2 + `/life/consultation/${idMov}/warranty/detail/${idWarrInst}`);
  }

  /**
   * Returns response of api rest /life/policies/{uuid}/investment-plan
   *
   * @param proposalNumber - string proposal number
   * @param movementId - optional string movement id
   * @returns Observable<Array<Profile>>
   */
  public getInvestmentPlan(proposalNumber: string, movementId?: string): Observable<Array<Profile>> {
    return this.httpClient.get<Array<Profile>>(this.baseApiUrlV2 + `/life/policies/${proposalNumber}/investment-plan${!!movementId ? `?idMovement=${movementId}` : ''}`);
  }


  /**
   * Returns response of api rest /life/policies/{proposalNumber}/due-registers/{dueRegisterId}/investment-plan
   *
   * @param proposalNumber - string proposal number
   * @param dueRegisterId - string due register id
   * @returns Observable<Array<Profile>>
   */
  public getDueRegisterInvestmentPlan(proposalNumber: string, dueRegisterId: string): Observable<Array<Profile>> {
    return this.httpClient.get<Array<Profile>>(this.baseApiUrlV2 + `/life/policies/${proposalNumber}/due-registers/${dueRegisterId}/investment-plan`);
  }

}

import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {SectionQuestionI} from '../../models/section-question';
import {QuestionAnswerI} from '../../models/question-answer';

@Component({
  selector: 'ppevo-questionnaire-section',
  templateUrl: './section.component.html',
  encapsulation: ViewEncapsulation.None
})

export class SectionComponent implements OnInit {

  @Output() change = new EventEmitter();
  @Output() clickSectionInfoNotes = new EventEmitter<number>();
  @Output() clickQuestionInfoNotes = new EventEmitter<number>();

  @Input() inputSection: SectionQuestionI;

  constructor() { }

  ngOnInit() {
  }


  onChange(value: QuestionAnswerI) {
    this.change.emit(value);
  }


  onClickInfoNotes(){
    this.clickSectionInfoNotes.emit(this.inputSection.questionnaireSection.id);
  }


  onClickQuestionInfoNotes(questionId:number){
    this.clickQuestionInfoNotes.emit(questionId);
  }

}

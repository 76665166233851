import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiPncPostsalesModule} from './rgi-pnc-postsales.module';
import {PNC_PSALES_APP_EXCLUSION, PNC_PSALES_CANCELLATION} from './flows/pnc-postsales-cancellation.routes';
import {PNC_PSALES_VDRIMV} from './flows/pnc-postsales-VDRIMV.routes';
import {PNC_PSALES_VD0062} from "./flows/pnc-postsales-VD0062.routes";
import {PNC_PSALES_VD0059} from './flows/pnc-postsales-VD0059.routes';
import {PNC_PSALES_VD0007} from './flows/pnc-postsales-transfer-policy-VD007.routes';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RgiPncPostsalesModule.withOperation([PNC_PSALES_CANCELLATION, PNC_PSALES_APP_EXCLUSION,
      PNC_PSALES_VDRIMV,
      PNC_PSALES_VD0062,
      PNC_PSALES_VD0059,
      PNC_PSALES_VD0007
    ])
  ],
  providers: []
})
export class RgiPncPsalesFlowsModule {
}

import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  private baseApiUrl: string;
  private getNodesEndpoint: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {

    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.getNodesEndpoint = this.baseApiUrl + '/admin/agencies';
  }

  getNodes(code: string, description: string, codeLoginNode: string, showGroupingNodes?: boolean): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('codeSp', code);
    httpParams = httpParams.set('descriptionSp', description);
    httpParams = httpParams.set('codeLoginNode', codeLoginNode);
    if (showGroupingNodes) {
      httpParams = httpParams.set('showGroupingNodes', showGroupingNodes.toString());
    }
    return this.httpClient.get<any>(this.getNodesEndpoint, { params: httpParams });
  }

  getNodesByLoginNodeId(nodeId: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('loginNodeId', nodeId);
    return this.httpClient.get<any>(this.getNodesEndpoint, { params: httpParams });
  }

}

import { TypeOfLossDamageSave } from './typeofloss-request-save';

export class ReserveUpdateRequest {
  confermaRiserva: boolean = false;
  claimNumber: string;
  typeoflossList: TypeOfLossDamageSave[];
  confermaSuperamentoMassimale: boolean = false;


  constructor(claimNumber: string, typeoflossDamageReserve: TypeOfLossDamageSave[], confermaRiserva: boolean, clickConferma: boolean) {
    this.confermaRiserva = confermaRiserva;
    this.claimNumber = claimNumber;
    this.typeoflossList = typeoflossDamageReserve;
    this.confermaSuperamentoMassimale = clickConferma;
  }
}

import {Inject, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalConfig, RGI_RX_PORTAL_CONFIG, RgiRxPortalModule} from '@rgi/rx/portal';
import {
  angularGridInstance,
  CONTRACT_CONSULTATION_CARD,
  CONTRACT_CONSULTATION_CARDS
} from './contract-consultation-card';
import {ContractConsultationModule, ContractConsultationIntegrationService} from '@rgi/contract-consultation-card';
import {RgiRxEventService} from '@rgi/rx';
import {downgradeContractConsultationService} from './ajs-downgrade';
import {ContractConsultationPortalIntegrationService} from './service/contract-consultation-portal-integration.service';

export function gettextCatalogFactory(injector) {
  return injector.get('gettextCatalog');
}

export const gettextCatalog = {
  provide: 'gettextCatalog',
  useFactory: gettextCatalogFactory,
  deps: ['$injector']
};

export function coreLangFactory(injector) {
  return injector.get('coreLang');
}

export const coreLang = {
  provide: 'coreLang',
  useFactory: coreLangFactory,
  deps: ['$injector']
};

const _REQUIRED_AJS_TRANSLATOR_PROVIDERS_ = [gettextCatalog, coreLang];


@NgModule({
  imports: [
    CommonModule,
    ContractConsultationModule,
    RgiRxPortalModule.forRoot(CONTRACT_CONSULTATION_CARDS)
  ],
  providers: [
    {provide: ContractConsultationIntegrationService, useClass: ContractConsultationPortalIntegrationService},
    angularGridInstance,
    _REQUIRED_AJS_TRANSLATOR_PROVIDERS_
  ]
})
export class ContractConsultationPortalModule {
  constructor(@Inject(RGI_RX_PORTAL_CONFIG) config: PortalConfig,
              protected eventService: RgiRxEventService
  ) {
    downgradeContractConsultationService(config);

  }
}


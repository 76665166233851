import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Factor} from '../../../group-policy-models/group-policy-issue-policy-data';
import {ModalService, RgiRxMultiselectCloseEvent} from '@rgi/rx/ui';
import {Subscription} from 'rxjs';
import {trackOption} from '../../../adapters/group-policy-utils';

@Component({
  selector: 'rgi-gp-group-policy-factors-select-component',
  templateUrl: './group-policy-factors-select.component.html'
})
export class GroupPolicyPolicyFactorsSelectComponent implements OnInit, OnDestroy {
  constructor(public modalService: ModalService) {
  }

  @Output() updateFactors = new EventEmitter<Factor>();
  @Input() factor: Factor;
  @Input() group: UntypedFormGroup;
  private multiSelectSub = Subscription.EMPTY;
  readonly trackOption = trackOption;

  ngOnInit(): void {
    if (!!this.factor.selectedValues) {
      this.group.get(this.factor.code).patchValue(this.factor.selectedValues, {emitEvent: false});
      this.group.updateValueAndValidity({emitEvent: false});
    }
    this.group.get(this.factor.code).valueChanges.subscribe(_newValue => {
      this.onChangeFactor();
    });
  }
  ngOnDestroy(): void {
    this.multiSelectSub.unsubscribe();
  }

  public onChangeFactor() {
    this.updateFactors.emit(this.factor);
  }

  onCloseEv(_$event: RgiRxMultiselectCloseEvent<any>) {
    this.factor.selectedValues = _$event.currentSelections.map(el => el.value);
    this.updateFactors.emit(this.factor);
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimQuestionnaireComponent } from './claim-questionnaire/claim-questionnaire.component';
import { RgiRxDatePickerModule, RgiRxFormElementsModule } from '@rgi/rx/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CardService } from '@rgi/digital-claims-common-angular';
import { ClaimQuestionnaireEditDataComponent } from './claim-questionnaire-edit-module/claim-questionnaire-edit-data.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [
        ClaimQuestionnaireComponent,
        ClaimQuestionnaireEditDataComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        ReactiveFormsModule,
        RgiRxDatePickerModule,
        RgiRxFormElementsModule
    ],
    exports: [
        ClaimQuestionnaireComponent,
        ClaimQuestionnaireEditDataComponent
    ],
    providers: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ClaimQuestionnaireModule {

  constructor(cardService: CardService) {
    const title = '_RCA_QUESTIONNAIRE';
    const moduleName = 'claimsQuestionnaireNew';
    const parentCardName = 'claimsFinder';
    const debugCardsOn = false;
    const communication = {
      reloadsubcard: 'reloadsubcard'
    };

    const cardOpeningModule = cardService.registerSubCard(
      moduleName,
      title,
      ClaimQuestionnaireComponent,
      parentCardName,
      null,
      null,
      communication
    );
  }
}

import {TableSchema} from '@rgi/rx/ui';

export const WARNING_LIST_AUTHORIZATION_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'warningBlockLevel',
      title: '_GP_._LABEL_._BLOCK_LEVEL_'
    },
    {
      name: 'warningError',
      title: '_GP_._LABEL_._ERROR_'
    },
    {
      name: 'warningType',
      title: '_GP_._LABEL_._TYPE_'
    },
    {
      name: 'warningOrigin',
      title: '_GP_._LABEL_._ORIGIN_ENTITY_',
    }
  ],
  header: ['warningBlockLevel', 'warningError', 'warningType', 'warningOrigin']
};

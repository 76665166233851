import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Factor} from '../../models/pc-portfolio-models/factor-models/factor';
import {formatNumber} from '@angular/common';
import {UnitService} from '../re-issue-quotation/re-issue-quotation-services/unit.service';

@Component({
  selector: 're-issue-factors',
  templateUrl: './re-issue-factors.component.html',
  styleUrls: ['./re-issue-factors.component.css'],
})
export class ReIssueFactorsComponent implements OnInit, OnDestroy {
  readonly DATE_TYPE = 'DATE';
  readonly TEXT_TYPE = 'TEXT';
  readonly LIST_TYPE = 'LIST';
  readonly NUMBER_TYPE = 'NUM';
  readonly BOOL_TYPE = 'BOOL';

  @Input() factor: Factor;
  @Input() factorControlForm: UntypedFormGroup;
  @Output() updateFactor: EventEmitter<Factor> = new EventEmitter<Factor>();
  @Input() factorCode: string;


  initializationCompleted = false;

  private subscriptions: Subscription = new Subscription();


  constructor(private unitService: UnitService) {
  }

  ngOnInit() {
    this.factorCode = this.factorCode ? this.factorCode : this.factor.code;
    this.initializationCompleted = true;
    if(this.factor && (!this.factor.type || this.factor.type !== this.DATE_TYPE)) {
      this.formattedFactorValue(this.factor);
    }
  }

  onFactorValueChange() {
    if (!this.initializationCompleted) {
      return;
    }
    this.factor.value = this.factorControlForm.controls[this.factorCode].value;
    if (this.factor.type === this.NUMBER_TYPE) {
      this.factor.value = String(this.unitService.parseToNumberAndFormat(this.factor.value));
      if (isNaN(+this.factor.value)) {
        this.factorControlForm.controls[this.factorCode].setErrors({ 'RE_ISSUE.INVALID_NUMBER': true });
      } else {
        this.factorControlForm.controls[this.factorCode].setErrors(null);
      }
    }
    this.updateFactors();
  }

  private updateFactors() {
    this.updateFactor.emit(this.factor);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showFormattedInput(factor: Factor): boolean {
    return !!factor.format;
  }

  disable(factorCode: string) {
    this.factorControlForm.controls[factorCode].disable();
  }

  showUnFormattedInput(factor: Factor): boolean {
    return !factor.format;
  }

  formattedFactorValue(factor: Factor): any {
    let result: any = this.factorControlForm.controls[this.factorCode].value;
    const separatore = ',';
    if (result && result !== '') {
      result = this.unitService.parseToNumberAndFormat(result);
      /*
      removed to avoid deleting decimals
      if (parseInt(result, 10) > 0) {
        result = '' + parseInt(result, 10);
      }
      */
      if (parseInt(result, 10) > -1) {
        if (factor.format === '%1[.2g]') {
          result = (Math.round(result * 100) / 100).toFixed(2);
          result = formatNumber(result, 'it-IT', '1.2-2');
        } else if (factor.format === '%1[.2]') {
          result = (Math.round(result * 100) / 100).toFixed(2);
          result = result.split(separatore).join('');
        } else if (factor.format === '%1[4]') {
          result = result.split(separatore)[0]
        } else if (factor.format === '%1[singleline:6]') {
          result = result.split(separatore)[0];
        } else if (factor.format && factor.format.indexOf('.2g]') !== -1) {
          result = (Math.round(result * 100) / 100).toFixed(2);
        } else {
          result = (Math.round(result * 100) / 100).toFixed(0);
          result = result.split(separatore).join('');
        }
      }
      return this.controlFormValue(result);
    }

    return this.controlFormValue(result);

  }

  maxLen(factor: Factor) {
    if (factor.format === '%1[4]') {
      return 4;
    } else if (factor.format === '%1[singleline:6]') {
      return 6;
    }
  }

  pattern(factor: Factor) {
    if (factor.format === '%1[4]') {
      return '[0-9]{4}';
    } else if (factor.format === '%1[singleline:6]') {
      return '.{6}';
    }
  }

  controlFormValue(value: any): any {
    this.patchValue(value);
    return value;
  }

  patchValue(value: any) {
    this.factorControlForm.controls[this.factorCode].patchValue(value);
  }


  isSelected(value: any, factor: Factor) {
    let factorVal = null;
    if (!(factor.type === this.BOOL_TYPE && factor.value === null)) {
      factorVal = factor.value;
    }
    const out = value === factorVal;
    if (out) {
      this.patchValue(value);
    }
    return out;
  }

}

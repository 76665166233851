import { UntypedFormGroup } from '@angular/forms';
import { Ramo } from '../../enum/life-issue.enum';
import { FUNDS_INVESTMENT_CONTROL, InvestmentContext,
  PROFILES_INVESTMENT_CONTROL, TOTAL_INVESTMENT_AMOUNT } from '../../life-issue-card/lic-consts';
import {Benefit, Unit, Warranties} from '../../models/policy.model';
import { PolicyService } from '../../services/policy-service';
import { PassProfileDefinition } from '../../lic-fund/model/PassProfileDefinition';
import { KarmaProfileDefinition } from '../../lic-karma-funds/model/karma-profile-definition';

export class InvestmentUtils {

  /**
   * @description
   * returns a filtered array from the availbleProfiles getting just the
   * valorized profile on page by the user getting it from
   */
  public static getInvestmentProfiles(availableProfiles: PassProfileDefinition[], formGroup: UntypedFormGroup ): PassProfileDefinition[] {
    const valorizedProfiles = availableProfiles
      .filter(p => formGroup.getRawValue().profiles && Object.keys(formGroup.getRawValue().profiles)
        .includes(p.id));
    const investmentProfiles = !!valorizedProfiles && valorizedProfiles.length ? valorizedProfiles : availableProfiles;
    return investmentProfiles;
  }

  /** @description returns the GS benefit performance amount */
  public static getGSBenefitPerformance(policyService: PolicyService): number {
    const validSection = policyService.mainProposal.quote.product.assets[0]?.instances[0]?.sections?.find(section => section.units.length > 0);
    const baseUnitsFiltered = (validSection?.units || []).filter(u => u.base);
    const firstWarrantiesFiltered = policyService.mainProposal.quote.product.warranties
      .filter(w => w.ministerialDivisionCode === Ramo.PRIMO);
    let benefit = null;
    if (baseUnitsFiltered.length > 0 && firstWarrantiesFiltered.length > 0) {
      const gsUnit: Unit = InvestmentUtils.findGsUnit(baseUnitsFiltered, firstWarrantiesFiltered);
      if (!!gsUnit) {
        benefit = policyService.mainProposal.proposal.benefit.find(b => b.riskCode === gsUnit.riskCode);
      }
    }
    return benefit && Number(benefit.initialPerformance);
  }

  private static findGsUnit(baseUnitsFiltered, firstWarrantiesFiltered) {
    let gsUnit: Unit = null;
    let iBu = 0;
    while (iBu < baseUnitsFiltered.length && !gsUnit) {
      let iFw = 0;
      const unit: Unit = baseUnitsFiltered[iBu];
      while (iFw < firstWarrantiesFiltered.length && !gsUnit) {
        const warranty: Warranties = firstWarrantiesFiltered[iFw];
        if (unit.warrantyCode === warranty.code) {
          gsUnit = unit;
        }
        iFw++;
      }
      iBu++;
    }
    return gsUnit;
  }

  /**
   * Calculates the total benefit performance for a given policy service.
   * @param policyService - The policy service to calculate the total benefit performance for.
   * @returns The total benefit performance as a number.
   */
  public static getTotalBenefitPerformance(policyService: PolicyService): number {
    const filteredBenefit = this.getBaseRisk(policyService);
    const sumInitialBenefit = (accumulator: number, benefit: Benefit) => accumulator + this.parseInitialPerformance(benefit);
    return filteredBenefit.reduce(sumInitialBenefit, 0);
  }

  /**
   * Returns an array of Benefit objects that represent the base risk of the policy.
   * @param policyService - The PolicyService object that contains the policy data.
   * @returns An array of Benefit objects that represent the base risk of the policy.
   */
  private static getBaseRisk(policyService: PolicyService): Benefit[] {
    const  benefitArray =  policyService.mainProposal.quote.product.assets[0].instances[0].sections
    .filter((section: any) => !!section.units)
    .reduce((accumulator: string[], section: any) => {
      const foundedUnit = section.units.filter((u: any) => u.base).map((u: any) => u.riskCode);
      return accumulator.concat(foundedUnit);
    }, [])
    .map((code: string) => policyService.mainProposal.proposal.benefit.find((b: Benefit) => b.riskCode === code))
    .filter((benefit: Benefit | undefined) => !!benefit);

    return benefitArray;
  }

  /**
   * Parses the initial performance of a benefit.
   * @param benefit - The benefit to parse.
   * @returns The initial performance as a number, or 0 if it cannot be parsed.
   */
  private static parseInitialPerformance(benefit: Benefit): number {
    const initialPerformance = parseFloat(benefit.initialPerformance);
    return initialPerformance || 0;
  }

/**
 * Calculates the total allocation for a given investment control.
 * @param investmentControl - The investment control to calculate the total allocation for.
 * @returns The total allocation as a number.
 */
// @dynamic
public static calculateTotalAllocation(investmentControl: any): number {
  const alloc = Object.keys(investmentControl).map((el) => +investmentControl[el]).reduce((acc, val) => acc + val, 0);
  return alloc;
}

/**
 * Checks if the investment is completed based on the total allocation.
 * @param formGroup - The form group containing investment controls.
 * @param profileControlName - The name of the profile investment control.
 * @param fundsControlName - The name of the funds investment control.
 * @returns A boolean indicating whether the investment is completed or not.
 */
public static checkIfInvestmentCompleted(
  formGroup: UntypedFormGroup,
  profileControlName: string,
  fundsControlName: string,
  selectedProfiles: KarmaProfileDefinition[] = []
): boolean {
  const profileControl = formGroup.get(profileControlName);
  const fundsControl = formGroup.get(fundsControlName);

  if (profileControl && profileControl.value !== null && (fundsControl === null || fundsControl.value === null)) {
    const totalProfileAllocation = InvestmentUtils.calculateTotalAllocation(profileControl.value);

    // Check if the calculated total allocation matches the target amount
    return totalProfileAllocation === TOTAL_INVESTMENT_AMOUNT;
  }

  if (fundsControl && fundsControl.value !== null) {

      let i = 0;
      let isTotalOk = true;
      while (i < selectedProfiles.length && isTotalOk) {
        const totalProfileAllocation = InvestmentUtils.calculateTotalAllocation(fundsControl.value[selectedProfiles[i].id]);
        isTotalOk = totalProfileAllocation === TOTAL_INVESTMENT_AMOUNT;
        i++;
      }

      return isTotalOk;
  }

  return false;
}

public static checkIfProfileInvestmentCompleted(formGroup: UntypedFormGroup): boolean {
  return InvestmentUtils.checkIfInvestmentCompleted(formGroup, PROFILES_INVESTMENT_CONTROL, null);
}

public static checkIfFundsInvestmentCompleted(formGroup: UntypedFormGroup, selectedProfiles?: KarmaProfileDefinition[]): boolean {
  return InvestmentUtils.checkIfInvestmentCompleted(formGroup, PROFILES_INVESTMENT_CONTROL, FUNDS_INVESTMENT_CONTROL, selectedProfiles);
}

  /**
   * Returns an array of error messages and their corresponding investment context for a given step and form group.
   * @param step - The current step in the investment process.
   * @param formGroup - The form group containing the investment data.
   * @returns An array of error messages and their corresponding investment context.
   */
public static getStepErrors(step: string, formGroup: UntypedFormGroup, selectedProfiles?: KarmaProfileDefinition[]): {msg: string, context: InvestmentContext}[] {
  if (step === PROFILES_INVESTMENT_CONTROL && !InvestmentUtils.checkIfProfileInvestmentCompleted(formGroup)) {
    return [{msg: 'lic_profileInvestmentIncomplete', context: step}];
  }
  if (step === FUNDS_INVESTMENT_CONTROL && !InvestmentUtils.checkIfFundsInvestmentCompleted(formGroup, selectedProfiles)) {
    return [{msg: 'lic_fundsInvestmentIncomplete', context: step}];
  }
  return [];
}

}

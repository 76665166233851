import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Modal} from '../../modal';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ProposalService} from '../../proposal.service';
import {ParameterService} from '../../quotation/parameters/parameters.service';

@Component({
  selector: 'mic-policy-summary-modal',
  templateUrl: './policy-summary-modal.component.html',
  styleUrls: ['./policy-summary-modal.component.scss']
})
export class PolicySummaryModalComponent implements OnInit, Modal {

  componentRef: import ('@angular/core').ComponentRef<any>;
  policySummaryForm: UntypedFormGroup;
  effectiveDate: Date;
  isPolicyNumberEnabled: boolean;
  policyMessages: string[];
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formBuilder: UntypedFormBuilder,
    public proposalService: ProposalService,
    protected parametersService: ParameterService
  ) {
  }

  ngOnInit() {
    if (this.parametersService.getEffectiveDate()) {
      this.effectiveDate = new Date(this.parametersService.getEffectiveDate());
    }
    this.policySummaryForm = this.formBuilder.group({
      policyNumber: [],
      issueDate: [new Date()],
      effectiveDate: [this.effectiveDate]
    });
    this.policySummaryForm.get('issueDate').disable();
    this.policySummaryForm.get('effectiveDate').disable();
  }

  close() {
    this.componentRef.destroy();
  }

  onSubmit() {
    const contractId = this.proposalService.getContractId();
    const policyNumber = this.policySummaryForm.get('policyNumber').value;

    this.proposalService.setManualPolicyNumber(policyNumber);

    this.proposalService.savePolicy(contractId, policyNumber).subscribe(data => {
      if (!!data.messages.length) {
        this.policyMessages = data.messages;
      } else {
        this.proposalService.setIssueCompleted();
        this.proposalService.setPolicyInfo(data);
      }
      this.close();
    });
  }

}

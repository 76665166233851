import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReIssueWarningsResourceService} from '../re-issue-warnings-resource.service';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {
  ReIssuePortfolioWarningsUtilityService
} from '../../re-issue-utility/re-issue-portfolio-warnings-utility.service';
import {ConsistencyResponse} from '../../../models/pc-portfolio-models/warnings-models/consistency-response';
import {REST_API_ANAG_WARNINGS_CONF, REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioWarningsResourceService extends ReIssueWarningsResourceService {

  private CONTRACT_PATH = 'contract/';
  private PROPOSALS_PATH = 'proposals/';

  private apiPcPortfolioConf: any ;

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any,
    private portfolioWarningsUtilityService: ReIssuePortfolioWarningsUtilityService,
  ) {
    super(http, apiConf);
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;
  }

  get(resourceId: string): Observable<any> {
    // TODO: Warning service not implemented yet
    return this.http.get<any>(this.getWarningsUri(resourceId)).pipe(
      share()
    );
  }

  // TODO: PUT Warning service not implemented yet
  postWarnings(resourceId: string, level: string): Observable<any> {
    return this.http.post<any>(
      this.getWarningsUri(resourceId),
      this.portfolioWarningsUtilityService.costructWarningsRequest(level)
    ).pipe(
      share()
    );
  }

  getConsistencyAsset(resourceId: string): Observable<Array<ConsistencyResponse>> {
    return this.http.get<Array<ConsistencyResponse>>(this.getConsistencyAssetUri(resourceId)).pipe(
      share()
    );
  }

  getConsistencyAssetUri(resourceId): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      resourceId +
      '/consistency/assets';

  }

  getWarningsUri(resourceId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      resourceId +
      '/warnings';
  }
}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title" style="max-width: 100%">{{ '_CLAIMS_._CLAIM_SELECTION' | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
            </h3>
        </div>
        <form [formGroup]="updateInvoiceForm" data-qa="invoice-update-form">

            <!-- filter new start -->
            <div class="container-fluid table-white" [ngClass]="{'row-bgcolor-grey': !expanded, 'row-bgcolor-blue':expanded}">
                <div class="row">
                    <div class="col-sm-2" (click)="expanded = !expanded">
                        <span class="rgifont iconDTleft rgi-plus"
                        [ngClass]="{'rgi-plus icon-accordion-open': !expanded, 'rgi-minus icon-accordion-close':expanded}"></span>
                        <span class="bold">{{ '_CLAIMS_._FILTERS' | translate }}</span>
                    </div>
                </div>
            </div>
    
            <div class="row padding-top-10">
                <div class="col-xs-12 padding-zero accordion-claims-margin-bottom" [hidden]="!expanded">
        
                    <div class="container-fluid table-white">

                        <div class="col-sm-4">
                            <label class="label-core" attr.data-qa="invoiceclaimslist-filterSubjectSurnameOrDenomination-label">{{ '_CLAIMS_._SUBJECT_SURNAME_OR_DENOMINATION' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="filterSubjectSurnameOrDenomination" id="filterSubjectSurnameOrDenomination" type="text" name="filterSubjectSurnameOrDenomination" attr.data-qa="invoice-claims-list-filter-subject-surname-or-denomination-input">
                        </div>

                        <div class="col-sm-4">
                            <label class="label-core" attr.data-qa="invoiceclaimslist-filterSubjectName-label">{{ '_CLAIMS_._SUBJECT_NAME' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="filterSubjectName" id="filterSubjectName" type="text" name="filterSubjectName" attr.data-qa="invoice-claims-list-filter-subject-name-input">
                        </div>

                        <div class="col-sm-4">
                            <label class="label-core" attr.data-qa="invoiceclaimslist-filterClaimNumber-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</label>
                            <input class="col-sm-6 form-control" formControlName="filterClaimNumber" id="filterClaimNumber" type="text" name="filterClaimNumber" attr.data-qa="invoice-claims-list-filter-claim-number-input">
                        </div>

                        <div class="col-sm-4">
                            <label class="label-core" attr.data-qa="invoiceclaimslist-filterClaimsHandler-label">{{ '_CLAIMS_._CLAIMS_HANDLER' | translate }}</label>
                            <select class="core-select form-control" formControlName="filterClaimsHandler" id="filterClaimsHandler" name="filterClaimsHandler"
                                attr.data-qa="invoice-claims-list-filter-claim-handler-input">
                                <option></option>
                                <option *ngFor="let claimsHandler of claimsHandlers; " value={{claimsHandler.cod}}>
                                    {{claimsHandler.nominative}}</option>
                            </select>
                            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                        </div>
                        
                        <div class="col-sm-4">
                            <pnc-datepicker label="{{ '_CLAIMS_._OPENING_DATE_FROM' | translate }}" customFormControlName="filterClaimOpeningDateFrom"
                                [customFormGroup]="updateInvoiceForm" [maxDate]="today"
                                attr.data-qa="invoice-claims-list-filter-opening-date-from-input">
                            </pnc-datepicker>
                        </div>
            
                        <div class="col-sm-4">
                            <pnc-datepicker label="{{ '_CLAIMS_._OPENING_DATE_TO' | translate }}" customFormControlName="filterClaimOpeningDateTo"
                                [customFormGroup]="updateInvoiceForm" [maxDate]="today"
                                attr.data-qa="invoice-claims-list-filter-opening-date-to-input">
                            </pnc-datepicker>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-core" attr.data-qa="invoiceclaimslist-filterClaimTechnicalStatus-label">{{ '_CLAIMS_._CLAIMS_TECHNICAL_STATUS' | translate }}</label>
                            <select class="core-select form-control" formControlName="filterClaimTechnicalStatus" id="filterClaimTechnicalStatus" name="filterClaimTechnicalStatus"
                                attr.data-qa="invoice-claims-list-filter-claim-TechnicalStatus-input">
                                <option></option>
                                <option *ngFor="let elemStatus of technicalStatus; " value={{elemStatus.codice}}>
                                    {{elemStatus.descrizione}}</option>
                            </select>
                            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                        </div>

                    </div>

                    <span *ngIf="validationMessages && validationMessages.length > 0" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-update-error">
                        <span class="rgifont rgi-exclamation-triangle"></span>
                        <span *ngFor="let message of validationMessages; let even = even; let odd = odd; let invoiceDetailsIndex = index">
                            {{message | translate}}
                            <br>
                        </span>
                    </span>
                    
                    <div class="btn-group btn-group-justified">
                        <div class="btn-group">
                            <button class="btn btn-warning text-uppercase pull-right" attr.data-qa="invoice-claim-search-find-btn"
                                id="invoice-update-submit" (click)="clearSearchFields()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
                        </div>
                        <div class="btn-group">
                            <button class="btn btn-warning text-uppercase pull-right" attr.data-qa="invoice-claim-search-find-btn"
                                id="invoice-update-submit" (click)="claimsListFilterSearch()">{{ '_CLAIMS_._SEARCH' | translate }}</button>
                        </div>
                    </div>

                </div>
            </div>
            <!-- filter new end -->

            <div class="col-md-12 col-sm-12 col-xs-12 panel-heading wf-party-header">
                {{'_CLAIMS_._CLAIM_NUMBER_RECEIVED'| translate}}  
                <!-- TODO - for future proforma management (more details for itself invoice) invoiceDetailsList[0].claimNumber manage in cicle with index -->
                <span class="bold">{{invoice.invoiceDetailsList[0].claimNumber}}</span>
            </div>

            <div *ngIf="hasResult()" class="row claims-text">
{{ '_CLAIMS_._SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST' | translate }}
            </div>

            <div *ngIf="hasResult()" class="table-white">
                <div class="head-result thDataTable row">
                    
                    <div class="col-sm-1">
                        <span class="bold"></span>
                    </div>
                    <div class="col-sm-2">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</span>
                    </div>    
                    <div class="col-sm-3">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._COMPANY' | translate }}</span>
                    </div>    
                    <div class="col-sm-2">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._CLAIMS_HANDLER' | translate }}</span>
                    </div>
                    <div class="col-sm-2">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._TECHNICAL_STATE' | translate }}</span>
                    </div>
                    <div class="col-sm-1">
                        <span class="bold padding-left-5">{{ '_CLAIMS_._RESERVED' | translate }}</span>
                    </div>
                    <div class="col-sm-1" align="center">
                        <span class="bold">{{ '_CLAIMS_._SUBJECTS' | translate }}</span>
                    </div>
                    
                </div>
            </div>

            <div *ngFor="let claim of claimsList; let i = index">
                <div class="row">

                    <div class="col-sm-1 claims-text" align="center">
                        <input required
                            attr.data.qa="damage-claim-radio-{{i}}" name="claim" formControlName="claim" type="radio"
                            id="radio{{i}}" [value]="claim" (change)="onSelectionChange(claim.claimNumber)"
                            [ngClass]="{submitted: submitted}">
                    </div>
                    <div class="col-sm-2 claims-text">
                        <label attr.data.qa="claimNumber-label-{{i}}" for="radio{{i}}">{{claim.claimNumber}}</label>
                    </div>
                    <div class="col-sm-3 claims-text">
                        <label attr.data.qa="claimcompany-label-{{i}}" for="radio{{i}}">{{claim.company}}</label>
                    </div>
                    <div class="col-sm-2 claims-text">
                        <label attr.data.qa="claimHandler-label-{{i}}" for="radio{{i}}">{{claim.claimHandler}}</label>
                    </div>
                    <div class="col-sm-2 claims-text">
                        <label attr.data.qa="technicalState-label-{{i}}" for="radio{{i}}">{{claim.technicalState}}</label>
                    </div>
                    <div class="col-sm-1 claims-text">
                        <label attr.data.qa="reserve-label-{{i}}" for="radio{{i}}">{{claim.reserve | currency:'EUR'}}</label>
                    </div>
                    
                    <div class="col-sm-1 claims-text" align="center">

                        <button type="button" class="btn btn-flat btn-sm" class="claims-popover">
                        
                            <span class="action-icon rgifont rgi-user">
                                <span class="claims-popover-container">

                                    <label *ngFor="let subject of claim.subjects; let i=index" attr.data.qa="reserve-label-{{i}}" for="radio{{i}}">
                                        {{subject.role}} - {{subject.nominative}}<br>
                                    </label>

                                </span>
                            </span>
                        
                        </button>

                    </div>

                </div>

            </div>

            <div *ngIf="hasResult()" class="claims-text">
                <label attr.data.qa="">Pag.</label>
                <a *ngFor="let pageElement of arrPage; let even = even; let odd = odd; let invoicePagerIndex = index"
                (click)="goToPage(invoicePagerIndex+1)" class="pointer">
                    <span [ngClass]="{ 'pager-current-page': currentPage == invoicePagerIndex+1 }">
                        {{invoicePagerIndex + 1}}
                    </span>&nbsp;
                </a>&nbsp; 
            </div>

            <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-update-error">
                <span class="rgifont rgi-exclamation-triangle"></span>
                <span>{{errorMessage | translate}}</span>
            </span>

            <div *ngIf="hasResult()" class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button type="submit" class="btn btn-warning pull-right" attr.data-qa="invoice-update-find-btn"
                        id="invoice-update-submit" (click)="confirmInvoice()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                </div>
            </div>

        </form>
    </div>
</div>
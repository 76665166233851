import {Component, forwardRef, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import { PV_TOKEN } from '../../../models/consts/lpc-consts';
import {PlcObjectUtils} from '../../../utils/plc-object-utils';

@Component({
  selector: 'lpc-percent-form-group',
  templateUrl: './lpc-percent-form-group.component.html',
  styleUrls: ['./lpc-percent-form-group.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcPercentFormGroupComponent),
      multi: true
    }
  ]
})
export class LpcPercentFormGroupComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    percent: new UntypedFormControl()
  });

  @Input() label: string;

  @Input() required = false;

  private $subscriptions: Subscription[] = [];

  tresholdInputType = 'number';
  options = [10, 20, 30]; // in caso di select

  constructor(@Optional() @Inject(PV_TOKEN.THRESHOLD_INPUT_TYPE) tit,
  ) {
    if (!!tit) {
      this.tresholdInputType = tit;
    }
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe(_ => {
        this.onChange(PlcObjectUtils.roundToDecimal(this.formGroup.getRawValue().percent, 2));
        this.onTouch();
      })
    );
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  writeValue(percent: number | string): void {
    // Convert input to a number if it's a string
    const numericPercent = typeof percent === 'string' ? parseFloat(percent) : percent;
  
    // Check if the conversion resulted in a valid number
    if (!isNaN(numericPercent)) {
      const fixRoundNumber = PlcObjectUtils.roundToDecimal(numericPercent, 2);
      // Use patchValue instead of setValue and remove the multiplication by 100
      this.formGroup.patchValue({ percent: fixRoundNumber });
    }
  }
  onChange(obj: number) {
  }

  onTouch() {
  }

  roundValue() {
    const value: number = PlcObjectUtils.roundToDecimal(this.formGroup.get('percent').value, 2);
    this.formGroup.get('percent').setValue(value);
  }
}

<div [formGroup]="formGroup">
    <div style="margin-top:5px" class="input-group">
        <input [ngClass]="{'with-percentage' : showPercentage}" type="text" class="form-control" readonly=""
            formControlName="name">
        <input [ngClass]="{'with-percentage' : showPercentage}" type="text" [placeholder]="'Percentuale'"
            class="form-control" formControlName="percentage" *ngIf="showPercentage">
        <div class="input-group-btn">
            <!--  <button class="btn btn-outline-secondary" type="button" (click)="onEdit(role)"> -->
            <!--   <span class="rgifont rgi-pencil"></span> -->
            <!-- </button> -->
            <button (click)="deleteBen(value)" class="btn btn-outline-secondary" type="button">
                <span class="rgifont rgi-trash"></span>
            </button>
        </div>
    </div>
</div>
import {GPAsset, SingleUnit, Units} from '../group-policy-models/group-policy-issue-guarantees';
import {DataForSteps} from '../group-policy-models/group-policy-issue-home';
import {
  BaseEntity,
  ErrorCode,
  Factor,
  MeanOfPayment,
  PartyRoleState,
  PaymentsField,
  PaymentsPayload,
  Role,
  SubjectsRoleState
} from '../group-policy-models/group-policy-issue-policy-data';
import {Documents} from '../group-policy-models/group-policy-issue-summary';
import {GroupPolicyVcontRouteData} from '../group-policy-models/group-policy-vcont-date';
import {
  GroupPolicyStateHome,
  GroupPolicyStatePolicyData,
  GroupPolicyStateSummary
} from '../group-policy-state/group-policy-state';

/**
 * @author: dmasone
 * @description: Generics utils for group policy
 */

export function sortMeansOfPayment(meansOfPayment: MeanOfPayment[]): MeanOfPayment[] {
  if (meansOfPayment && meansOfPayment.length > 0) {
    meansOfPayment.sort((a: MeanOfPayment, b: MeanOfPayment) => {
      return a.paymentConfig.meanOfPayment.description.localeCompare(b.paymentConfig.meanOfPayment.description);
    });
  }

  return meansOfPayment;
}

export function mapPayment(pc: string, mopt: string, st: number, pf: PaymentsField[]): PaymentsPayload {
  const res = {
    paymentCode: pc,
    meanOfPaymentType: +mopt,
    settlementType: st
  };
  if (pf) {
    pf.forEach((singleEl: PaymentsField) => {
      if (singleEl && singleEl.name) {
        res[singleEl.name] = (singleEl.name === 'dcardexpire') ? creditCardToApiDate(singleEl.value) : singleEl.value;
      }
    });
  }
  return res;
}

export function creditCardToApiDate(value: string | null): string | null {
  if (value != null) {
    let dateParts;
    if (value.includes('-')) {
      return value;
    } else {
      dateParts = value.split('/');
    }
    return (`${dateParts[1]}-${dateParts[0]}-01`);
  }
  return null;
}

export function createObjForNextStep(resId: string, respNewFlowIssue: GroupPolicyStatePolicyData, previousStepData: DataForSteps): DataForSteps {
  return {
    resId,
    node: previousStepData.node,
    operationCode: previousStepData.operationCode,
    product: previousStepData.product,
    errors: [],
    type: respNewFlowIssue.type,
    contractStatus: previousStepData.contractStatus,
    managementNode: previousStepData.managementNode
  } as DataForSteps;
}

export function adaptStateFromForm(st: GroupPolicyStatePolicyData | GroupPolicyStateSummary) {
  st.errors = [];
  st.type = ErrorCode.BLOCKING;
}

export function adaptStateFromFormHome(st: GroupPolicyStateHome, data: any) {
  st.selectedNode = data.node;
  st.selectedCompany = data.company;
  st.targetId = data.targetId;
  st.issueDate = data.issueDate;
  st.selectedProduct = data.filterProduct;
  st.effectiveDate = data.effectiveDate;
  st.errors = [];
  st.type = ErrorCode.BLOCKING;
}

export function mapUnitsCodeForForm(units: GPAsset[]): GPAsset[] {
  const tmpUnits: GPAsset[] = JSON.parse(JSON.stringify(units));
  tmpUnits.forEach((unit: GPAsset) => {
    if (unit) {
      unit.units.forEach((singleUnit: Units) => {
        if (singleUnit && singleUnit.unit) {
          singleUnit.unit.listVariable.forEach((factor: Factor) => {
            if (factor) {
              factor.code = factor.code + '_' + singleUnit.unit.code + '_' + singleUnit.unit.sectionCode;
            }
          });
        }
        if (singleUnit && singleUnit.linkedUnit) {
          singleUnit.linkedUnit.forEach((singleLinked: SingleUnit) => {
            if (singleLinked) {
              singleLinked.listVariable.forEach((factor: Factor) => {
                if (factor) {
                  factor.code = factor.code + '_' + singleLinked.code + '_' + singleLinked.sectionCode;
                }
              });
            }
          });
        }
      });
    }
  });

  return tmpUnits;
}

export function mapUnitsCodeForPut(units: GPAsset[]): GPAsset[] {
  const tmpUnits: GPAsset[] = JSON.parse(JSON.stringify(units));
  tmpUnits.forEach((unit: GPAsset) => {
    if (unit) {
      unit.units.forEach((singleUnit: Units) => {
        if (singleUnit && singleUnit.unit) {
          singleUnit.unit.listVariable.forEach((factor: Factor) => {
            if (factor) {
              factor.code = factor.code.substring(0, factor.code.indexOf('_'));
            }
          });
        }
        if (singleUnit && singleUnit.linkedUnit) {
          singleUnit.linkedUnit.forEach((singleLinked: SingleUnit) => {
            if (singleLinked) {
              singleLinked.listVariable.forEach((factor: Factor) => {
                if (factor) {
                  factor.code = factor.code.substring(0, factor.code.indexOf('_'));
                }
              });
            }
          });
        }
      });
    }
  });

  return tmpUnits;
}

export function integerDiv(n: number): number {
  return (Math.floor(n / 3));
}

export function getPerc(perc: string): string {
  return (perc) ? (' ' + perc + '%') : '';
}

export function getSystemKeys(): string[] {
  return [
    'GroupPolicyContactsManagement',
    'PortfolioContactsManagement'
  ];
}

export function isNumber(e) { return typeof e === 'number'; }

export function createMapRoleParties(availableRoles: Role[]): SubjectsRoleState<PartyRoleState> {
  let subjMap = {} as SubjectsRoleState<PartyRoleState>;
  const linkedRoleMap = {} as SubjectsRoleState<PartyRoleState>;
  if (availableRoles && !!availableRoles.length) {
    availableRoles.map(availableRole => {
      subjMap[availableRole.role] = !subjMap[availableRole.role] ? [] : subjMap[availableRole.role];
      subjMap[availableRole.role].push(Object.assign({}, availableRole));
      if (availableRole.linkedRoles && !!availableRole.linkedRoles.length) {
        availableRole.linkedRoles.map(linkedRole => {
          linkedRoleMap[linkedRole.role] = !linkedRoleMap[linkedRole.role] ? [] : linkedRoleMap[linkedRole.role];
          linkedRoleMap[linkedRole.role].push(Object.assign({}, linkedRole));
        }
        );
        /* A role with linked roles > 0 can have max 1 instance of itself */
        subjMap[availableRole.role][0].linkedRoles = linkedRoleMap;
      }
    }
    );
  }
  subjMap = addEmptyRoles(subjMap);
  return subjMap;
}

export function addEmptyRoles(rolesMaps) {
  let rolesToFill = Object.assign({}, rolesMaps);
  const rolesKeys = Object.keys(rolesMaps);
  let linkedRolesKeys;
  let linkedRoles;
  rolesKeys.forEach(roleKey => {
    rolesToFill = createEmptyRole(rolesMaps, roleKey);
    if (rolesToFill[roleKey][0].linkedRoles && !!Object.keys(rolesToFill[roleKey][0].linkedRoles).length) {
      /* A role with linked roles > 0 can have max 1 instance of itself */
      linkedRolesKeys = Object.keys(rolesToFill[roleKey][0].linkedRoles);
      linkedRoles = rolesToFill[roleKey][0].linkedRoles;
      linkedRolesKeys.forEach(key => {
        linkedRoles[key].linkedRoles = createEmptyRole(linkedRoles, key);
      });
    }
  });
  return rolesToFill;
}

export function createEmptyRole(rolesMaps, role: string) {
  let newRole;
  const mapPopulated = Object.assign({}, rolesMaps);
  if (mapPopulated[role].length >= 1
    && mapPopulated[role][0].maxInstances > mapPopulated[role].length
    && !mapPopulated[role].find(el => !el.idParty)) {
    newRole = Object.assign({}, mapPopulated[role][0]);
    newRole.idParty = null;
    mapPopulated[role].unshift(newRole);
  }
  return mapPopulated;
}

export function mapObjForVcont(resId: string, steps: string[], labels: string[], proposalNumber: string, idParentSession: string, firstSubjectContract: PartyRoleState = null, documents: Documents[] = null): GroupPolicyVcontRouteData {
  return {
    resId,
    steps,
    labels,
    proposalNumber,
    idParentSession,
    firstSubjectContract,
    documents
  } as GroupPolicyVcontRouteData;
}

export function trackOption(_index: number, option: BaseEntity) {
  return option.code;
}

import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


const destroyableEmitterProperty = '__rgiRxDestroyableEmitter__';
export const destroyableEmitterPropertyMeta = '__rgiRxDestroyableEmitter__meta__';

export function Destroyable(options: { autoUnsubscribe: boolean, emitterName?: string } = {autoUnsubscribe: false, emitterName: destroyableEmitterProperty}) {
  return (target: any) => {
    const propertyName = options.emitterName || destroyableEmitterProperty;
    target.prototype[propertyName] = new Subject<void>();
    target.prototype[destroyableEmitterPropertyMeta] = propertyName;
    target.prototype.ngOnDestroy = ((ngOnDestroy: (() => void) | undefined) => function() {
      if (ngOnDestroy) {
        ngOnDestroy.call(this);
      }
      this[propertyName].next();
      this[propertyName].complete();

      if (options.autoUnsubscribe) {
        Object.values(this).filter(prop => prop instanceof Subscription).forEach((prop: Subscription) => prop.unsubscribe());
      }
    })(target.prototype.ngOnDestroy);
  };
}


export function getDestroyableProperty(instance: any) {
  const prototype = instance.constructor.prototype;
  // provide retro compatibility with WithSubscriptions
  if(instance.destroy$) {
    return instance.destroy$;
  }
  if (!prototype.hasOwnProperty(destroyableEmitterPropertyMeta)) {
    throw Error(`Instance ${instance} is not decorated with @Destroyable, cannot use takeUntilDestroyed`);
  }
  return prototype[prototype[destroyableEmitterPropertyMeta]];
}

/**
 * @description use this operator to automatically unsubscribe from the source observable when the destroyable property emits
 * @param instance the instance of the class decorated with @Destroyable
 */
export function takeUntilDestroyed<K>(instance: K) {
  return <T>(source: Observable<T>) => source.pipe(takeUntil<T>(
    getDestroyableProperty(instance)
  ));
}

import {Component, Input, OnInit} from '@angular/core';
import {BaseEntity, BaseEntityMultiDescriptions} from '../../../group-policy-models/group-policy-issue-policy-data';
import { integerDiv } from '../../../adapters/group-policy-utils';
import {ModalService} from '@rgi/rx/ui';
import {
  GroupPolicyModalOptionSelectionComponent,
  MultiOptionDataTable
} from '../../group-policy-modals/group-policy-modal-option-selection/group-policy-modal-option-selection.component';


@Component({
  selector: 'rgi-gp-generic-detail',
  templateUrl: './gpgeneric-detail.component.html'
})
export class GPGenericDetailComponent implements OnInit {

  @Input() details: BaseEntity[] | BaseEntityMultiDescriptions[];

  @Input() translateLabel = false;

  public integerDiv = integerDiv;

  constructor(protected modalService: ModalService) { }

  ngOnInit() {
  }

  openModalListDescriptions(item: BaseEntityMultiDescriptions) {
    const data = new MultiOptionDataTable(item.multiDescriptions, null, true);
    this.modalService.openComponent(GroupPolicyModalOptionSelectionComponent, data);
  }
}

<div class="container-fluid table-white">
    <div class="row">
        <div class="col-md-1 col-sm-6 col-xs-12 text-padd-right" *ngIf="isMultipleAssetsPresent()">
            <input required class="asset-select-radio" attr.data.qa="damaged-asset-radio-{{damagedAsset.id}}"
                name="selectedAsset" type="radio" id="damaged-asset-radio-{{damagedAsset.id}}" [value]="damagedAsset.id"
                [(ngModel)]="selectedAssetRadio"
                [ngClass]="{'submitted-with-error': isSelectedAssetError(), 'asset-select-radio': true}"
                [disabled]="disabled">
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12 text-padd-right"
            [ngClass]="{'col-md-3': isMultipleAssetsPresent(), 'col-md-4': !isMultipleAssetsPresent()}"
            attr.data-qa="damagedAsset-data-panel-asset-{{damagedAsset.id}}">{{ '_CLAIMS_._ASSET' | translate }}:
            <span *ngIf="!damagedAsset.vehicleTypeDescr">{{ '_CLAIMS_._MOTOR_CARD' | translate }}</span>
            <span *ngIf="damagedAsset.vehicleTypeDescr">{{ damagedAsset.vehicleTypeDescr }}</span>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
            attr.data-qa="damagedAsset-data-panel-assetdescription-{{damagedAsset.id}}">
            {{ '_CLAIMS_._ASSET_DESCRIPTION' | translate }}: <span>{{damagedAsset.description || '-'}}</span></div>
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
            attr.data-qa="damagedAsset-data-panel-registrationdate-{{damagedAsset.id}}">
            {{ '_CLAIMS_._FIRST_REGISTRATION_DATE' | translate }}:
            <span>{{damagedAsset.immatricolationdate | date:'dd/MM/yyyy' }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-1 col-sm-6 col-xs-12 text-padd-right" *ngIf="isMultipleAssetsPresent()">
        </div>
        <div class="col-sm-6 col-xs-12 text-padd-right"
            [ngClass]="{'col-md-3': isMultipleAssetsPresent(), 'col-md-4': !isMultipleAssetsPresent()}"
            attr.data-qa="damagedAsset-data-panel-assetdescription-{{damagedAsset.id}}">
            {{ '_CLAIMS_._PLATE_TYPE' | translate }}: <span>{{damagedAsset.vehicleplatetype || '-'}}</span></div>
        <div *ngIf="damagedAsset.vehicleplate" class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
            attr.data-qa="damagedAsset-data-panel-licenceplatenumber-{{damagedAsset.id}}">
            {{ '_CLAIMS_._PLATE_NUMBER' | translate }}: <span>{{damagedAsset.vehicleplate || '-'}}</span></div>
        <div *ngIf="damagedAsset.chassis" class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
            attr.data-qa="damagedAsset-data-panel-chassisnumber-{{damagedAsset.id}}">
            {{ '_CLAIMS_._CHASSIS_NUMBER' | translate }}: <span>{{damagedAsset.chassis || '-'}}</span></div>
    </div>
    <div class="row">
        <div class="col-md-1 col-sm-6 col-xs-12 text-padd-right" *ngIf="isMultipleAssetsPresent()">
        </div>
        <div class="col-sm-6 col-xs-12 text-padd-right"
            [ngClass]="{'col-md-3': isMultipleAssetsPresent(), 'col-md-4': !isMultipleAssetsPresent()}"
            attr.data-qa="damagedAsset-data-panel-manufacturer-{{damagedAsset.id}}">
            {{ '_CLAIMS_._MANUFACTURER' | translate }}: <span>{{damagedAsset.manufacturerdescr || '-'}}</span></div>
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
            attr.data-qa="damagedAsset-data-panel-model-{{damagedAsset.id}}">{{ '_CLAIMS_._MODEL' | translate }}:
            <span>{{damagedAsset.modeldescr || '-'}}</span>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
            attr.data-qa="damagedAsset-data-panel-model-{{damagedAsset.id}}">{{ '_CLAIMS_._NATION' | translate }}:
            <span>{{transcodeNationCode(damagedAsset.nationcode) || '-'}}</span>
        </div>
    </div>
</div>
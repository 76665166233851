import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, Inject } from '@angular/core';
import { EditablePanelContentComponent, PanelService, PanelSignal } from '@rgi/digital-claims-common-angular';
import { DocumentService } from './document.service';
import { DocumentCategory } from './model/document-category';
import { ClaimOpeningService } from '../../../claim-opening.service';
import { ClaimDocument } from './model/claim-document';
import {
  DocumentCategoryPanelTitleComponent
} from './document-category/document-category-panel-title.component';

@Component({
  selector: 'claims-document-management-panel-content',
  templateUrl: './document-management-panel-content.component.html',
  styleUrls: ['./document-management-panel-content.component.scss']
})
export class DocumentManagementPanelContentComponent extends EditablePanelContentComponent implements OnInit {
  private uuid: string;
  obbligatoryDocuments: DocumentCategory[] = [];
  notObbligatoryDocuments: DocumentCategory[] = [];
  showDocumentList = true;
  documentDataInternal: DocumentCategory[];
  hideUpload: boolean;
  @Input() public card: any;
  @Input() public policy?: any;
  @Input() public pes: any;
  @Output() panelCompleted = new EventEmitter<boolean>();
  @Output() editDone = new EventEmitter<boolean>();
  @ViewChildren(DocumentCategoryPanelTitleComponent) documentCategories!: QueryList<DocumentCategoryPanelTitleComponent>;

  isPolicyModifyData: boolean;

  constructor(
    panelService: PanelService,
    private documentService: DocumentService,
    public claimOpeningService: ClaimOpeningService,
    @Inject('eventService') private eventService: any) {
    super(panelService);
  }

  ngOnInit() {

    if (this.card) {
      this.isPolicyModifyData = this.claimOpeningService.getPolicyModifyDataFromSession(this.card.id) != null;
    }

    // this.initDocuments();
    this.disablePanel();

    // if (this.isPolicyModifyData) {
    //   this.setPanelComplete();
    // }
  }

  onCategoryChange() {
    this.editDone.emit(true);
    this.checkObbligatoryDocuments();
  }

  onDocumentDelete(document: ClaimDocument, documentCategory: DocumentCategory) {
    if (documentCategory.documents && documentCategory.documents.length) {
      documentCategory.documents = documentCategory.documents.filter((el: ClaimDocument) => {
        return el.documentId !== document.documentId;
      });
      if (!documentCategory.documents || !documentCategory.documents.length) {
        this.checkObbligatoryDocuments();
        const element = this.documentCategories.find((cat: DocumentCategoryPanelTitleComponent) => {
          return cat.documentCategory.categoryId === documentCategory.categoryId;
        });
        element.setPanelIncomplete();
      }
    }
  }

  @Input()
  set documentsData(documentsData: any) {
    if (documentsData) {
      this.documentDataInternal = Object.values(documentsData.documentsMap) as DocumentCategory[];
    }
    console.log(this.documentDataInternal);
  }

  protected validateContent(): boolean {
    return false;
  }

  protected storeData() {
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {

    if (!this.isPolicyModifyData) {

      if (this.acceptSignals) {
        if (panelSignal === PanelSignal.open) {
          if (this.documentDataInternal) {
            this.uuid = this.claimOpeningService.getSessionUuid(this.card.id);
            this.documentCategoriesRecieved(Object.values(this.documentDataInternal), this.uuid);
          }
          this.enableAndOpenContent();
        }
        if (PanelSignal.init === panelSignal) {
          this.initDocuments();
        }
      }
      if (this.pes && this.pes.idClaim) {
        this.disablePanel();
        this.disablePanelContent();
        this.hideUpload = true;
      }
    } else {
      if (panelSignal === PanelSignal.init) {
        this.setPanelComplete();
      } else {
        this.setPanelIncomplete();
      }
    }
  }

  protected initDocuments() {
    this.uuid = this.claimOpeningService.getSessionUuid(this.card.id);
    this.getDocumentCategories(this.uuid);
  }

  protected getDocumentCategories(uuid: string) {
    const that = this;
    this.eventService.broadcastEvent('start-loader');
    const pesId = this.pes ? this.pes.idPotencialClaim : null;
    this.documentService.getDocumentCategories(uuid, 'opening', pesId).subscribe({
      next: (result: DocumentCategory[]) => {
        this.eventService.broadcastEvent('stop-loader');
        that.documentCategoriesRecieved(result, uuid);
      },
      error: (error) => {
        that.documentCategoriesError(error);
        this.eventService.broadcastEvent('stop-loader');
      }
    });
  }

  protected documentCategoriesRecieved(documentCategories: DocumentCategory[], uuid?: string) {
    this.obbligatoryDocuments = [];
    this.notObbligatoryDocuments = [];

    for (const documentCategory of documentCategories) {
      if (!documentCategory.uuid) {
        documentCategory.uuid = uuid;
      }
      if (documentCategory.mandatory) {
        this.obbligatoryDocuments.push(documentCategory);
      } else {
        this.notObbligatoryDocuments.push(documentCategory);
      }
    }

    this.showDocumentList = this.obbligatoryDocuments.length > 0 || this.notObbligatoryDocuments.length > 0;

    this.checkObbligatoryDocuments();
  }

  protected documentCategoriesError(error: any) {
    console.log('DocumentManagementPanelContentComponent::documentCategoriesError()', error);
  }

  protected checkObbligatoryDocuments() {
    let allValid = true;

    for (const documentCategory of this.obbligatoryDocuments) {
      if (!documentCategory.documents || !documentCategory.documents.length) {
        allValid = false;
        break;
      }
    }

    if (allValid) {
      this.setPanelComplete();
    } else {
      this.setPanelIncomplete();
    }
  }

  setPanelComplete() {
    this.panelCompleted.emit(true);
    super.setPanelComplete();
  }

  setPanelIncomplete() {
    this.panelCompleted.emit(false);
    super.setPanelIncomplete();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LpcThirdPartyComponent} from './lpc-third-party.component';
import {ReactiveFormsModule} from '@angular/forms';
import {LpcBeneficiaryModule} from '../lpc-beneficiary/lpc-beneficiary.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
  declarations: [LpcThirdPartyComponent],
  exports: [
    LpcThirdPartyComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LpcBeneficiaryModule,
    RgiRxI18nModule
  ]
})
export class LpcThirdPartyModule { }


<div class="rgi-ui-upload-control rgi-ui-form-control" [class.rgi-ui-error]="hasError()">
  <input type="file" aria-hidden="true" [accept]="accept.join(',')" #input class="rgi-ui-form-control" (change)="onInputChange($event)">
  <div class="rgi-ui-upload-file-container">
    <ng-container *ngIf="value">
      <div class="rgi-ui-file-uploaded-selected">{{value.name}}</div>
      <button class="rgi-ui-btn" (click)="onRemove($event)">
        <span class="rgi-ui-icon-close"></span>
      </button>
    </ng-container>
  </div>
  <button rgi-rx-button color="info" [disabled]="disabled" (click)="onSelect($event)" translate>{{selectLabel}}</button>
</div>


<div class="rgi-ui-upload-supported-file" *ngIf="(!value || (!!value && !hasError())) && this.accept.length > 0">{{'RGI_RX.INPUT_FILE.SUPPORTED'| translate}}: {{accept.join(', ')}}.</div>
<div class="rgi-ui-error-message" *ngIf="!!value && hasError()">{{ 'rgiRxAcceptFileType' | translate :  acceptError}}</div>

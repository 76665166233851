<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Warranties Summary</span>
        </h3>
      </div>
      <div class="modal-body">
        <div class="nmf_cont_moredetails">

          <!--  INIZIO UNIT container -->
          <div class="unit-container selected">
                        <span (click)="toggleAccordion()" [attr.aria-controls]="1" [attr.aria-expanded]="open"
                              [class.rgi-minus]="open" [class.rgi-plus]="!open" class="icon rgifont"></span>

            <b (click)="toggleAccordion()" [attr.aria-controls]="1" [attr.aria-expanded]="open"
               class="selected" style="font-size: 18px; padding-left: 10px;">
              {{ title }}
            </b>
          </div>

          <div [ngbCollapse]="!open" id="1">
            <div *ngFor="let warranty of warranties;" class="panel-body unit-border">
              <b style="color: #62b4ff">{{ warranty.unitTitle }} - ({{ warranty.sectionTitle }})</b>
              <div class="nmf_cont_dati">
                <div *ngFor="let variablesRow of warranty.variablesRows">
                  <div *ngFor="let variable of variablesRow"
                       class="nmf_cont_single_data nmf_cont_single_data_color">
                                        <span>{{ variable.extendedDescription ? variable.extendedDescription : variable.description }}
                                          :
                                            <b>{{ variable.valueDescription }}</b></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-warranties-summary-modal-close" translate>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

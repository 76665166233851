
<div class="ppevo-quest-section-title">
    <span>{{ inputSection.questionnaireSection.name }}</span>
    <span *ngIf="inputSection.questionnaireSection.hasNotes" class="ppevo-icon-info" (click)="onClickInfoNotes()"></span>
</div>

<ng-container *ngFor="let q of inputSection.questions">

    <ppevo-question *ngIf="q.visible" (change)="onChange($event)" [question]='q'
    (clickQuestionInfoNotes)="onClickQuestionInfoNotes($event)"
    ></ppevo-question>
</ng-container>
    


import {Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {Subscription} from 'rxjs';
import {AnagStateManagerPartyEditor} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {AbstractControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {MAIN_CONTACT_ENUM} from '../../anag-constants/enums-constants';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {PushMessageHandlerService, RgiRxPushMessage} from "@rgi/rx";
import {RgiRxUserService} from '@rgi/rx/auth';
import {OperatorLight} from '../../services/external-service';
import {AnagFormFieldConfig} from "../../anag-model/anag-domain/anag-form-field-config";
import {AnagConfigService} from "../../anag-resources/anag-config.service";
import {AnagIntPrefixEntity} from "../../anag-model/anag-domain/anag-int-prefix-entity";
import {distinctUntilChanged} from "rxjs/operators";
import {AnagEntityIta} from "../../anag-model/anag-api/anag-subject-api";

@Component({
  selector: 'rgi-anag-contacts',
  templateUrl: './anag-contacts.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagContactsComponent implements OnInit, OnModalClose, OnDestroy {

  modalClose: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription = new Subscription();

  inputParty: AnagApiParty;
  internationalPrefixes: Array<AnagIntPrefixEntity>;
  numberValidator = Validators.pattern('^[0-9 ]*$');
  submitted = false;
  contextNode: string;
  formatNumber: Map<string, string> = new Map();
  contactsForm: UntypedFormGroup;
  contactsFormMap: Map<string, AnagFormFieldConfig>;
  listFormats: string[];
  format: string;
  mask: string[];
  controlName: string;
  error: boolean;
  mainContacts: AnagEntityIta[];
  emailControls = ['email', 'email2', 'email3'];
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Assign emailValidator based on the pattern
  emailValidator = (control) => {
    if (control.value === '' || this.emailPattern.test(control.value)) {
      return null; // Valid email
    } else {
      return { 'invalidEmail': true }; // Invalid email
    }
  };
  constructor(
    protected userService: RgiRxUserService,
    public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public configService: AnagConfigService,
    public pushMessageHandler: PushMessageHandlerService,
    @Optional() @Inject(DIALOG_DATA) party: AnagApiParty) {
    if (party) {
      this.inputParty = party;
    }
  }

  ngOnInit() {
    this.pushMessageHandler.clearTag('error-tag');
    this.internationalPrefixes = this.stateMgr.getCurrentState().internationalPrefixes;
    this.mainContacts = this.anagStorage.getEnumsByCode(MAIN_CONTACT_ENUM);
    this.initForms();
    this.configService.setConfigToForm(this.contactsFormMap, this.stateMgr.getCurrentState().configuration);
    this.subscribeToValueChanges('landlineNumberIntPrefix');
    this.subscribeToValueChanges('faxNumberIntPrefix');
    this.subscribeToValueChanges('phoneNumberIntPrefix');
    this.subscribeToValueChanges('phoneNumberIntPrefix2');
    this.manageInputParty();
    this.manageMainContactChange();
    this.setPrefixByContext();
    if (this.stateMgr.isContactsMandatory()) {
      this.contactsForm.controls.mainContact.setValidators(Validators.required);
    }
  }

  initForms() {
    this.contactsForm = new UntypedFormGroup({});
    this.contactsFormMap = new Map<string, AnagFormFieldConfig>([
      ['mainContact', new AnagFormFieldConfig('mainContact', this.contactsForm, [])],
      ['landlineNumberIntPrefix', new AnagFormFieldConfig('internationalPrefix', this.contactsForm, [], 'phone')],
      ['landlineNumberPrefix', new AnagFormFieldConfig('localPrefix', this.contactsForm, [this.numberValidator], 'phone')],
      ['landlineNumber', new AnagFormFieldConfig('number', this.contactsForm, [this.numberValidator], 'phone')],
      ['faxNumberIntPrefix', new AnagFormFieldConfig('internationalPrefix', this.contactsForm, [], 'fax')],
      ['faxNumberPrefix', new AnagFormFieldConfig('localPrefix', this.contactsForm, [this.numberValidator], 'fax')],
      ['faxNumber', new AnagFormFieldConfig('number', this.contactsForm, [this.numberValidator], 'fax')],
      ['phoneNumberIntPrefix', new AnagFormFieldConfig('internationalPrefix', this.contactsForm, [], 'mobilePhone', 0)],
      ['phoneNumberPrefix', new AnagFormFieldConfig('localPrefix', this.contactsForm, [this.numberValidator], 'mobilePhone', 0)],
      ['phoneNumber', new AnagFormFieldConfig('number', this.contactsForm, [this.numberValidator], 'mobilePhone', 0)],
      ['phoneNumberIntPrefix2', new AnagFormFieldConfig('internationalPrefix', this.contactsForm, [], 'mobilePhone', 1)],
      ['phoneNumberPrefix2', new AnagFormFieldConfig('localPrefix', this.contactsForm, [this.numberValidator], 'mobilePhone', 1)],
      ['phoneNumber2', new AnagFormFieldConfig('number', this.contactsForm, [this.numberValidator], 'mobilePhone', 1)],
      ['email', new AnagFormFieldConfig('emails', this.contactsForm, [this.emailValidator], null, 0)],
      ['email2', new AnagFormFieldConfig('emails', this.contactsForm, [this.emailValidator], null, 1)],
      ['email3', new AnagFormFieldConfig('emails', this.contactsForm, [this.emailValidator], null, 2)]
    ]);
  }

  manageMainContactChange() {
    this.subscriptions.add(this.contactsForm.controls.mainContact.valueChanges.subscribe(mainContact => {
      this.submitted = false;
      this.pushMessageHandler.clearTag('error-tag');
      this.resetValidators();
      this.setValidators(mainContact);
    }));
  }

  private subscribeToValueChanges(controlName: string): void {
    const control: AbstractControl = this.contactsForm.get(controlName);
    if (control) {
      control.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(intPrefix => {
          const prefix = this.internationalPrefixes?.find(x => x.code === intPrefix);
          if (prefix) {
            this.formatNumber.set(controlName, prefix.format);
            const list = {
              'landlineNumberIntPrefix': 'landlineNumber',
              'faxNumberIntPrefix': 'faxNumber',
              'phoneNumberIntPrefix': 'phoneNumber',
              'phoneNumberIntPrefix2': 'phoneNumber2'
            };
            this.applyFormat(list[controlName], this.contactsForm.controls[list[controlName]].value, prefix.format);
          }
        });
    }
  }

  setPrefixByContext() {
    this.contextNode = this.userService.getUser<OperatorLight>().salePoint.context;
    const prefix = this.internationalPrefixes.find(x => x.codCountry === this.contextNode)
    if (this.contactsForm.controls.faxNumberIntPrefix && !this.contactsForm.controls.faxNumberIntPrefix.value) {
      this.contactsForm.get('faxNumberIntPrefix').setValue(prefix.code);
    }
    if (this.contactsForm.controls.landlineNumberIntPrefix && !this.contactsForm.controls.landlineNumberIntPrefix.value) {
      this.contactsForm.get('landlineNumberIntPrefix').setValue(prefix.code);
    }
    if (this.contactsForm.controls.phoneNumberIntPrefix && !this.contactsForm.controls.phoneNumberIntPrefix.value) {
      this.contactsForm.get('phoneNumberIntPrefix').setValue(prefix.code);
    }
    if (this.contactsForm.controls.phoneNumberIntPrefix2 && !this.contactsForm.controls.phoneNumberIntPrefix2.value) {
      this.contactsForm.get('phoneNumberIntPrefix2').setValue(prefix.code);
    }
  }

  setValidators(mainContact) {
    this.setEmailValidators(this.contactsForm.controls);
    switch (mainContact) {
      case '1':
        const phoneControls = [
          { name: 'phoneNumberIntPrefix', validators: [Validators.required] },
          { name: 'phoneNumberPrefix', validators: [Validators.required, this.numberValidator] },
          { name: 'phoneNumber', validators: [Validators.required, this.numberValidator] }
        ];
        const phone2Controls = [
          { name: 'phoneNumberIntPrefix2', validators: [Validators.required] },
          { name: 'phoneNumberPrefix2', validators: [Validators.required, this.numberValidator] },
          { name: 'phoneNumber2', validators: [Validators.required, this.numberValidator] }
        ];
        const controlsToUpdate = (this.contactsForm.controls.phoneNumberIntPrefix || this.contactsForm.controls.phoneNumberPrefix || this.contactsForm.controls.phoneNumber) ? phoneControls : phone2Controls;
        this.setValidatorsAndUpdateValidity(controlsToUpdate);
        break;
      case '2':
        const controls = this.contactsForm.controls;
        for (const emailControl of this.emailControls) {
          if (controls[emailControl]) {
            this.setValidatorsRequiredAndUpdateValidity(controls[emailControl]);
            break;
          }
        }
        break;
      case '3':
        const faxControls = [
          { name: 'faxNumberIntPrefix', validators: [Validators.required] },
          { name: 'faxNumberPrefix', validators: [Validators.required, this.numberValidator] },
          { name: 'faxNumber', validators: [Validators.required, this.numberValidator] }
        ];
        this.setValidatorsAndUpdateValidity(faxControls);
        break;
      case '5':
        const landlineControls = [
          { name: 'landlineNumberIntPrefix', validators: [Validators.required] },
          { name: 'landlineNumberPrefix', validators: [Validators.required, this.numberValidator] },
          { name: 'landlineNumber', validators: [Validators.required, this.numberValidator] }
        ];
        this.setValidatorsAndUpdateValidity(landlineControls);
        break;
    }
  }

  private setValidatorsRequiredAndUpdateValidity(control: AbstractControl): void {
    control.setValidators([Validators.required, this.emailValidator]);
    control.updateValueAndValidity();
  }

  private setEmailValidators(controls) {
    this.emailControls.forEach(emailControl => {
      controls[emailControl]?.setValidators(this.emailValidator);
    });
  }

  setValidatorsAndUpdateValidity(controlsToUpdate: { name: string, validators: any[] }[]): void {
    controlsToUpdate.forEach(({ name, validators }) => {
      const control = this.contactsForm.get(name);
      if (control) {
        control.setValidators(validators);
        control.updateValueAndValidity();
      }
    });
  }
  resetValidators() {
    this.configService.setConfigToForm(this.contactsFormMap, this.stateMgr.getCurrentState().configuration);
  }

  actionClose() {
    this.modalClose.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  manageInputParty() {
    if (this.inputParty) {
      if (this.inputParty.mainContact) {
        this.setControlValue(this.contactsForm.controls.mainContact, this.inputParty.mainContact.codice);
        this.setValidators(this.inputParty.mainContact.codice);
      }
      if (this.inputParty.landlineNumber) {
        this.setControlValue(this.contactsForm.controls.landlineNumberIntPrefix, this.inputParty.landlineNumber.internationalPrefix);
        this.setControlValue(this.contactsForm.controls.landlineNumberPrefix, this.inputParty.landlineNumber.localPrefix);
        this.setControlValue(this.contactsForm.controls.landlineNumber, this.inputParty.landlineNumber.number);
      }
      if (this.inputParty.phoneFax) {
        this.setControlValue(this.contactsForm.controls.faxNumberIntPrefix, this.inputParty.phoneFax.internationalPrefix);
        this.setControlValue(this.contactsForm.controls.faxNumberPrefix, this.inputParty.phoneFax.localPrefix);
        this.setControlValue(this.contactsForm.controls.faxNumber, this.inputParty.phoneFax.number);
      }
      if (this.inputParty.mobilePhone && this.inputParty.mobilePhone[0]) {
        this.setControlValue(this.contactsForm.controls.phoneNumberIntPrefix, this.inputParty.mobilePhone[0].internationalPrefix);
        this.setControlValue(this.contactsForm.controls.phoneNumberPrefix, this.inputParty.mobilePhone[0].localPrefix);
        this.setControlValue(this.contactsForm.controls.phoneNumber, this.inputParty.mobilePhone[0].number);
      }
      if (this.inputParty.mobilePhone && this.inputParty.mobilePhone[1]) {
        this.setControlValue(this.contactsForm.controls.phoneNumberIntPrefix2, this.inputParty.mobilePhone[1].internationalPrefix);
        this.setControlValue(this.contactsForm.controls.phoneNumberPrefix2, this.inputParty.mobilePhone[1].localPrefix);
        this.setControlValue(this.contactsForm.controls.phoneNumber2, this.inputParty.mobilePhone[1].number);
      }
      if (this.inputParty.emails && this.inputParty.emails[0]) {
        this.contactsForm.controls.email.setValue(this.inputParty.emails[0]);
      }
      if (this.inputParty.emails && this.inputParty.emails[1]) {
        this.contactsForm.controls.email2.setValue(this.inputParty.emails[1]);
      }
      if (this.inputParty.emails && this.inputParty.emails[2]) {
        this.contactsForm.controls.email3.setValue(this.inputParty.emails[2]);
      }
    }
  }

  setControlValue(control: AbstractControl, value: string) {
    if (control && value) {
      control.setValue(value);
    }
  }

  updateContacts() {
    this.submitted = true;
    if (this.contactsForm.valid) {
      this.stateMgr.updateContacts(this.contactsForm.getRawValue());
      this.modalClose.emit();
    } else {
      this.contactsForm.markAllAsTouched();
      this.notifyFormValidationErrorMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_');
    }
  }

  notifyFormValidationErrorMsg(errorMsg) {
    this.pushMessageHandler.clearTag('error-tag');
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
      tag: 'error-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  controlPrefixNumber() {
    if (!this.contactsForm.get('landlineNumber').value || !this.contactsForm.get('landlineNumberIntPrefix').value) {
      this.contactsForm.get('landlineNumberIntPrefix').reset();
      this.contactsForm.get('landlineNumberPrefix').reset();
    }
    if (!this.contactsForm.get('faxNumber').value || !this.contactsForm.get('faxNumberIntPrefix').value) {
      this.contactsForm.get('faxNumberIntPrefix').reset();
      this.contactsForm.get('faxNumberPrefix').reset();
    }
    if (!this.contactsForm.get('phoneNumber').value || !this.contactsForm.get('phoneNumberIntPrefix').value) {
      this.contactsForm.get('phoneNumberIntPrefix').reset();
      this.contactsForm.get('phoneNumberPrefix').reset();
    }
    if (!this.contactsForm.get('phoneNumber2').value || !this.contactsForm.get('phoneNumberIntPrefix2').value) {
      this.contactsForm.get('phoneNumberIntPrefix2').reset();
      this.contactsForm.get('phoneNumberPrefix2').reset();
    }
  }

  isFieldVisible(field: string) {
    return this.contactsForm.get(field);
  }

  removeFormat(controlName: string , value: string) {
    this.contactsForm.controls[controlName].setValue(value.replace(/\s/g, ''));
  }
  applyFormat(controlName: string, value: string, format: string) {
    this.error = false;
    this.controlName = controlName;
    value = value.replace(/\s/g, '');
    if (format && value) {
      value = this.applyFormatNumber(value, format);
    }
    if (this.error) {
      this.contactsForm.controls[this.controlName].setErrors({'_ANAG_._MSG_._INVALID_FORMAT_': true})
    } else {
      this.contactsForm.patchValue({
        [controlName]: value
      });
    }
  }

  applyFormatNumber(value: string, formatNumber: string) {
    this.listFormats = formatNumber?.split(';');
    if (this.listFormats && this.listFormats.length > 0) {
      for(let i = 0; i < this.listFormats.length; i++) {
        this.format = this.listFormats[i];
        if (value.length === this.format.replace(/\s/g, '').length) {
          this.error = false;
          value = this.applyNumberFormat(value);
          break;
        }
        this.error = true;
      }
    }
    return value;
  }

  applyNumberFormat(value: string) {
    return this.initMask(value)
  }

  initMask(value: string) {
    this.mask = this.getFieldMask();

    const regex = this.createRegex();

    value = this.getMaskedValue(value);

    if (value.length > 0 && !value.match(regex)) {
      this.error = true;
    }
    return value;
  }

  getFieldMask() {
    return this.format.split('');
  }

  getMaskedValue(field) {
    if (!this.mask) {
      this.mask = this.getFieldMask()
    }
    if (this.mask === null) {
      return '';
    }

    let newVal = this.applyMask(field.split(''));

    while (newVal.endsWith(' ')) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    return newVal;
  }

  applyMask(field) {
    return this.mask.map(function(currentChar) {
      if ((currentChar != 'N') && (currentChar != 'X') && (currentChar != '*')) {
        return currentChar;
      }
      return field.shift();
    }).join('');
  }

  createRegex() {
    let regex = '';

    for (let i = 0; i < this.mask.length; i++) {
      const newChar = this.mask[i];

      if (newChar == 'N') {
        regex += '([0-9]{1})+';
      } else if (newChar == 'X') {
        regex += '([a-zA-Z]{1})+';
      } else if (newChar == '*') {
        regex += '([a-zA-Z0-9]{1})+';
      } else if (newChar == ' ') {
        regex += '[\\s]+';
      } else {
        regex += newChar;
      }
    }
    return new RegExp(regex);
  }
}

import {Injectable} from '@angular/core';
import {LogEvent, LoggerStore, LogLevel} from './logging-api';
import {Observable} from 'rxjs';
import {StreamLoggerStoreHandler} from './logger-store-handler.service';

@Injectable()
export class LoggerStoreService extends LoggerStore {

  constructor(private loggerStoreHandler: StreamLoggerStoreHandler) {
    super();
  }

  logs$(level?: LogLevel): Observable<LogEvent[]> {
    return this.loggerStoreHandler.logs$(level);
  }

  takeLast$(): Observable<LogEvent> {
    return this.loggerStoreHandler.takeLast$();
  }


}

import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {
  RGI_RX_HTTP_CLIENT_INTERCEPTOR,
  RgiRxHttpErrorAdapter,
  RgiRxHttpErrorInterceptor,
  RgiRxHttpRequestAdapter
} from './http-client.api';
import {HttpBackend, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {RgiRxHttpErrorStream} from '../rgi-rx-http-error-stream';
import {clone} from 'lodash';
import {mergeMap, take} from 'rxjs/operators';

class LocalHttpHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}


@Injectable()
export class RgiRxHttpClientInterceptorResolver {
  private readonly _interceptors: HttpInterceptor[] = [];

  constructor(@Optional() @Inject(RGI_RX_HTTP_CLIENT_INTERCEPTOR) interceptors?: HttpInterceptor[]) {
    if (interceptors) {
      this._interceptors = interceptors;
    }
  }

  get interceptors(): HttpInterceptor[] {
    return clone(this._interceptors);
  }
}

export class RgiRxHttpClientHandler extends HttpHandler {
  private chain?: HttpHandler = null;

  constructor(
    private backend: HttpBackend,
    private interceptors: HttpInterceptor[],
    errorAdapter: RgiRxHttpErrorAdapter,
    httpErrorStream: RgiRxHttpErrorStream,
    private requestAdapter?: RgiRxHttpRequestAdapter,
  ) {
    super();
    this.interceptors.unshift(new RgiRxHttpErrorInterceptor(errorAdapter, httpErrorStream));
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (this.chain === null) {
      this.chain = this.interceptors.reduceRight(
        (next, interceptor) => new LocalHttpHandler(next, interceptor), this.backend);
    }
    if (!!this.requestAdapter) {
      const adapt = this.requestAdapter.adapt(req.clone());
      if (this.isAdaptedHttpRequest(adapt)) {
        return this.chain.handle(adapt);
      }
      return adapt.pipe(
        take(1),
        mergeMap(request => {
          return this.chain.handle(request);
        }));
    }
    return this.chain.handle(req);
  }

  private isAdaptedHttpRequest(req: HttpRequest<any> | Observable<HttpRequest<any>>): req is HttpRequest<any> {
    return (req as Observable<HttpRequest<any>>).subscribe === undefined;
  }
}



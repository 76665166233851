import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ToggleSwitchComponent} from './toggle-switch/toggle-switch.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ModalOverlayComponent } from './modal-overlay/modal-overlay.component';
import { ModalService } from './modal.service';
import { ModalComponent } from './modal/modal.component';
import { TabContentDirective, TabDirective, TabsetComponent, TabsetModule, TabTitleDirective } from './tabset/tabset.module';
import { AccordionComponent, PanelContentDirective, PanelDirective, PanelTitleDirective} from './accordion/accordion';
import { AccordionModule } from './accordion/accordion.module';
import { SelectComponent } from './select/select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent} from './checkbox/checkbox.component';
import { ButtonComponent} from './button/button.component';
import { DividerComponent } from './divider/divider.component';
import { MessageComponent } from './message/message.component';
import { InputComponent } from './input/input.component';
import { CardComponent, CardHeaderComponent } from './card/card.component';
import { CardContentDirective, CardMenuDirective, CardTitleDirective } from './card/card.directive';
import { RequiredComponent } from './required/required.component';
import { FormMessageComponent } from './form-message/form-message.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DateFormatterDirective } from './datepicker/date-formatter.directive';
import { TableComponent } from './table/table.component';
import { RoutingHostDirective } from './routing/routing-host.directive';
import { RoutingService } from './routing/routing.service';
import { OperatorService } from './operator.service';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { AdvancedSelectComponent } from './advanced-select/advanced-select.component';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { FloatingErrorMessagesComponent } from './floating-error-messages/floating-error-messages.component';
import {CustomModalService} from './custom-modal.service';
import {TranslationService} from './translations.service';
import {LoaderService} from './loader-service';
import {ReactiveAdvancedSelectComponent} from './reactive-advanced-select/reactive-advanced-select.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {PORTAL_NG_CORE_TRANSLATIONS} from './i18n/translations';

@NgModule({
    imports: [
        CommonModule,
        TabsetModule,
        AccordionModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        NgSelectModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(PORTAL_NG_CORE_TRANSLATIONS),
    ],
    providers: [
        ModalService,
        RoutingService,
        OperatorService,
        CustomModalService,
        TranslationService,
        LoaderService
    ],
    declarations: [
        ToggleSwitchComponent,
        RadioGroupComponent,
        ModalOverlayComponent,
        ModalComponent,
        ModalBasicComponent,
        SelectComponent,
        ButtonComponent,
        CheckboxComponent,
        DividerComponent,
        MessageComponent,
        InputComponent,
        CardComponent,
        CardHeaderComponent,
        CardContentDirective,
        CardTitleDirective,
        CardMenuDirective,
        RequiredComponent,
        FormMessageComponent,
        DatepickerComponent,
        DateFormatterDirective,
        TableComponent,
        RoutingHostDirective,
        DynamicTableComponent,
        AdvancedSelectComponent,
        FloatingErrorMessagesComponent,
        ReactiveAdvancedSelectComponent
    ],
    exports: [
        ToggleSwitchComponent,
        RadioGroupComponent,
        ModalComponent,
        ModalBasicComponent,
        ModalOverlayComponent,
        TabsetComponent,
        TabDirective,
        TabContentDirective,
        TabTitleDirective,
        AccordionComponent,
        PanelDirective,
        PanelTitleDirective,
        PanelContentDirective,
        SelectComponent,
        CheckboxComponent,
        ButtonComponent,
        CardComponent,
        CardHeaderComponent,
        CardContentDirective,
        CardTitleDirective,
        CardMenuDirective,
        InputComponent,
        DividerComponent,
        MessageComponent,
        RequiredComponent,
        FormMessageComponent,
        DatepickerComponent,
        TableComponent,
        RoutingHostDirective,
        DynamicTableComponent,
        AdvancedSelectComponent,
        FloatingErrorMessagesComponent,
        ReactiveAdvancedSelectComponent
    ]
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class PortalNgCoreModule { }

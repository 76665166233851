import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressesService, ElemEnum } from '@rgi/digital-claims-common-angular';
import { ClaimOpeningDetailsService } from '../../claim-opening-details.service';
import { FilterEntity } from '../dto/filter-entity';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';

@Component({
  selector: 'claims-search-hospital',
  templateUrl: './search-hospital.component.html',
  styleUrls: ['./search-hospital.component.scss']
})
export class SearchHospitalComponent implements OnInit, OnModalClose {
  @Input() addAssetForm: UntypedFormGroup;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  filter: FilterEntity;
  countries: any[] = [];
  cities: any[] = [];
  citiesFromResponse: any[] = [];
  toponyms: any[] = [];
  zipCodes: any[] = [];
  selectedCountry: any;
  selectedMunicipality: any;
  hospitalList: any[] = [];
  isOpenFromRgiModal: boolean = false;

  constructor(
    @Inject('authService') private authService: any,
    @Inject('eventService') private eventService: any,
    public addressService: AddressesService,
    public activeModal: NgbActiveModal,
    @Optional() @Inject(DIALOG_DATA) data: any,
    private claimOpeningDetailService: ClaimOpeningDetailsService
    ) {
      if (data) {
        this.addAssetForm = data.addAssetForm;
        this.isOpenFromRgiModal = true;
      }
     }

  ngOnInit() {
    if (!this.addAssetForm.controls.descriptionHospital) {
      this.addAssetForm.addControl('countryHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('stateHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('stateDescriptionHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('municipalityHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('addressHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('numberHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('postalCodeHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('descriptionHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('addressHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('numberHospital',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
    }
    this.addAssetForm.controls.stateHospital.setValue('');
    this.addAssetForm.controls.stateDescriptionHospital.setValue('');
    this.addAssetForm.controls.descriptionHospital.setValue('');
    this.addAssetForm.controls.addressHospital.setValue('');
    this.addAssetForm.controls.numberHospital.setValue('');
    this.addAssetForm.controls.municipalityHospital.setValue('');
    this.addAssetForm.controls.postalCodeHospital.setValue('');
    this.retrieveCountries('IT');
    this.retrieveCities();
  }

  protected retrieveCountries(selectedCountry?: string) {
    this.addressService.getCountries(new Date()).subscribe(
      (response: ElemEnum[]) => {
        for (const element of response) {
          this.countries.push({ id: element.codice, value: element.descrizione });
          if ((!this.selectedCountry && (this.selectedCountry === element.codice )) ||
               selectedCountry === element.codice ) {
            this.selectedCountry = { id: element.codice, value: element.descrizione };
          }
        }
      });
  }

  protected retrieveCities() {
    this.addressService.getCities().subscribe(
      (response: any[]) => {
        let index = 0;
        for (const element of response) {
          const city = { id: index, value: element.description };
          this.cities.push(city);
          this.citiesFromResponse[index] = element;
          element.id = index++;
        }
      });
  }


  protected updateZipCodeList(value: string) {
    this.zipCodes = [];
    this.addressService.getZipCodes(value).subscribe(
      (response: ElemEnum[]) => {
        for (const element of response) {
          this.zipCodes.push({ id: element.codice, value: element.descrizione });
        }
      });
  }

  setState(event: any) {
      this.addAssetForm.controls.stateHospital
       .setValue(this.citiesFromResponse[this.selectedMunicipality.id].siglaprovincia);
      this.addAssetForm.controls.stateDescriptionHospital
       .setValue(this.citiesFromResponse[this.selectedMunicipality.id].descrizioneprovincia);
      this.updateZipCodeList(this.citiesFromResponse[this.selectedMunicipality.id].nomecomune);
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  searchHealthcareProviders(event: any) {
    if (this.selectedMunicipality) {
       this.addAssetForm.controls.municipalityHospital.setValue(this.citiesFromResponse[this.selectedMunicipality.id].nomecomune);
    }
    const filter = new FilterEntity();
    filter.state = this.addAssetForm.controls.stateHospital.value;
    filter.municipality = this.addAssetForm.controls.municipalityHospital.value;
    filter.address = this.addAssetForm.controls.addressHospital.value;
    filter.number = this.addAssetForm.controls.numberHospital.value;
    filter.description = this.addAssetForm.controls.descriptionHospital.value;
    filter.postalCode = this.addAssetForm.controls.postalCodeHospital.value;
    this.eventService.broadcastEvent('start-loader');

    this.claimOpeningDetailService.searchHospital(filter)
          .subscribe((respose: any[]) => {
               this.hospitalList = [];
               respose.forEach(el => {
                this.hospitalList.push({
                  element: el,
                  description: el.description,
                  selected: false
                });
               });
               this.eventService.broadcastEvent('stop-loader');
          },
          (error) => {
              this.hospitalList = [];
              this.eventService.broadcastEvent('stop-loader');
      });
  }

  selectionHealthcareProviders(aStruct: any) {
     this.addAssetForm.controls.idER.setValue(aStruct.element.idObject);
     this.addAssetForm.controls.descriptionER.setValue(aStruct.description);
     if (this.isOpenFromRgiModal) {
      this.modalClose.emit(aStruct);
     } else {
      this.activeModal.close();
     }
     // event.stopPropagation();
  }
}

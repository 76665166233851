import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Surveys} from '../../group-policy-models/group-policy-issue-guarantees';

/**
 * @author: dmasone
 * @description: Component used for manage questionnaire
 */
@Component({
    selector: 'rgi-gp-group-policy-questionnaires-component',
    templateUrl: './group-policy-questionnaires.component.html'
})
export class GroupPolicyQuestionnairesComponent implements OnInit, OnDestroy {

    @Input() questionnaires: Surveys[];

    constructor() { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

}

import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QuestionnaireComponent} from './components/questionnaire/questionnaire.component';
import {SectionComponent} from './components/section/section.component';
import {MultivalueComponent} from "./components/multivalue/multivalue.component";
import {QuestionComponent} from "./components/question/question.component";
import {AnswerComponent} from './components/answer/answer.component';
import {YesnoComponent} from "./components/yesno/yesno.component";
import {QUEST_CFG, QuestionnaireCfg} from './questionnaire-cfg';
import {QuestionnaireConverterService} from './services/questionnaire-converter.service';
import {QuestionnaireConditionsService} from './services/questionnaire-conditions.service';
import {QuestionnaireValuesService} from './services/questionnaire-values.service';
import {CustomAnswerDirective} from './custom-answer.directive';
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {NG_PASSPRO_TRANSLATIONS} from "./i18n";


@NgModule({
    declarations: [
        QuestionnaireComponent,
        SectionComponent,
        AnswerComponent,
        QuestionComponent,
        YesnoComponent,
        MultivalueComponent,
        CustomAnswerDirective
    ],
    exports: [
        QuestionnaireComponent,
        SectionComponent,
        AnswerComponent,
        QuestionComponent,
        YesnoComponent,
        MultivalueComponent,
        CustomAnswerDirective
    ],
    imports: [
        CommonModule,
        BrowserModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RgiRxI18nModule.forRoot(NG_PASSPRO_TRANSLATIONS)
    ],
    providers: [QuestionnaireConverterService, QuestionnaireConditionsService, QuestionnaireValuesService]
})
export class PassproQuestionnaireModule {
  static forRoot(config?: QuestionnaireCfg): ModuleWithProviders<PassproQuestionnaireModule> {
    return {
      ngModule: PassproQuestionnaireModule,
      providers: [
        {provide: QUEST_CFG, useValue: config, multi: false},
      ]
    };
  }

}

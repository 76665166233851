import {Component} from '@angular/core';
import {GpInquiryComponentDetail} from "../gp-inquiry-component-detail";
import {
  GroupPolicyStateManagerInquiry
} from "../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry";
import {GroupPolicyIntegrationService} from "../../../group-policy-services/group-policy-integration.service";
import {GroupPolicyInquiryComponent} from "../../group-policy-inquiry.component";

@Component({
  selector: 'rgi-gp-gp-product-clauses-inquiry',
  templateUrl: './gp-product-clauses-inquiry.component.html'
})
export class GpProductClausesInquiryComponent extends GpInquiryComponentDetail {

  constructor(protected stateMngr: GroupPolicyStateManagerInquiry,
              protected integrationService: GroupPolicyIntegrationService,
              protected parent: GroupPolicyInquiryComponent) {
    super(stateMngr);
  }
}

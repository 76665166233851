<!-- PALLINO DEGLI ERRORI -->
<div class="errors-warnings-floating-wrapper ng-scope"
  *ngIf="(validationMessages?.length > 0 || blockingMsgs?.length > 0 || warningMsgs?.length > 0 || authoMsgs?.length > 0)
          && enableWarningIcon && !showFloatingMessages">
  <div class="errors-warnings-floating-icon minimized" (click)="onWarningIconClick()"
    [ngClass]="{
      'red-icon' : validationMessages?.length != 0 || blockingMsgs?.length != 0,
      'yellow-icon' : validationMessages?.length == 0 && blockingMsgs?.length == 0 && authoMsgs?.length != 0,
      'grey-icon' : validationMessages?.length == 0 && blockingMsgs?.length == 0 && authoMsgs?.length == 0 && warningMsgs?.length != 0
    }"
  >
    <span class="rgifont rgi-exclamation-triangle"></span>
  </div>
</div>

<!-- MESSAGGI FISSI ALL'APERTURA DEL PALLINO -->
<div class="errors-warnings-floating-wrapper fix-to-bottom">
  <div class="errors-warnings-floating-messages"
    *ngIf="(validationMessages?.length > 0 || blockingMsgs?.length > 0 || warningMsgs?.length > 0 || authoMsgs?.length > 0)
            && enableWarningIcon && showFloatingMessages">
    <div class="error-container">
      <pnc-form-message type="warning" [messages]="warningMsgs" *ngIf="warningMsgs?.length >0"
        data-qa="warning-error-messages" detail="warningMsgs">
      </pnc-form-message>
      <pnc-form-message type="authorization" [messages]="authoMsgs" *ngIf="authoMsgs?.length >0"
        data-qa="autho-error-messages" detail="authoMsgs">
      </pnc-form-message>
      <pnc-form-message type="error" [messages]="validationMessages" *ngIf="validationMessages?.length >0"
        data-qa="validation-error-messages" detail="validationError">
      </pnc-form-message>
      <pnc-form-message type="error" [messages]="blockingMsgs" *ngIf="blockingMsgs?.length >0"
        data-qa="blocking-error-messages" detail="blockingMsgs">
      </pnc-form-message>
      <div class="errors-warnings-floating-icon expanded"
        [ngClass]="{
          'red-icon-close' : validationMessages?.length != 0 || blockingMsgs?.length != 0,
          'yellow-icon-close' : validationMessages?.length == 0 && blockingMsgs?.length == 0 && authoMsgs?.length != 0,
          'grey-icon-close' : validationMessages?.length == 0 && blockingMsgs?.length == 0 && authoMsgs?.length == 0 && warningMsgs?.length != 0
        }"
        (click)="onCloseErrorsClick()"
      >
        <span class="rgifont rgi-close"></span>
      </div>
      <div class="nmf_triangle"
        [ngClass]="{
          'triangle-red' : validationMessages?.length != 0 || blockingMsgs?.length != 0,
          'triangle-yellow' : validationMessages?.length == 0 && blockingMsgs?.length == 0 && authoMsgs?.length != 0,
          'triangle-grey' : validationMessages?.length == 0 && blockingMsgs?.length == 0 && authoMsgs?.length == 0 && warningMsgs?.length != 0
        }"
      ></div>
    </div>
  </div>
</div>

<!-- MESSAGGI IN FONDO -->
<div>
  <div #errorsElement class="row" style="padding-top: 1px"></div>
  <div id="error-container" class="btn-msg">
    <pnc-form-message type="warning" [messages]="warningMsgs" *ngIf="warningMsgs?.length >0" detail="warningMsgs"
      data-qa="warning-error-messages">
    </pnc-form-message>
    <pnc-form-message type="authorization" [messages]="authoMsgs" *ngIf="authoMsgs?.length >0" detail="authoMsgs"
      data-qa="autho-error-messages">
    </pnc-form-message>
    <pnc-form-message type="error" [messages]="validationMessages" *ngIf="validationMessages?.length >0"
      detail="validationError" data-qa="validation-error-messages">
    </pnc-form-message>
    <pnc-form-message type="error" [messages]="blockingMsgs" *ngIf="blockingMsgs?.length >0" detail="blockingMsgs"
      data-qa="blocking-error-messages">
    </pnc-form-message>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import {GPAsset, GpAssetUnitsClauses} from '../../../group-policy-models/group-policy-issue-guarantees';
import {GPClause} from '../../../group-policy-models/group-policy-issue-policy-data';

@Pipe({
  name: 'gpFilterAssetClauses'
})
export class GpFilterAssetClausesPipe implements PipeTransform {

  transform(assetUnitsClauses: GpAssetUnitsClauses[], asset: GPAsset): GPClause[] {
    const gpAssetUnitsClauses = assetUnitsClauses ? assetUnitsClauses.find(value => value && value.codeAsset && value.codeAsset === asset.codeAsset) : null;
    return gpAssetUnitsClauses ? gpAssetUnitsClauses.assetClauses : [];
  }

}

import {CdkStepperNext, CdkStepperPrevious} from '@angular/cdk/stepper';
import {Directive} from '@angular/core';

/** Button that moves to the next step in a stepper workflow. */
@Directive({
  selector: 'button[rgiRxStepperNext]',
  host: {
    '[type]': 'type',
    '[disabled]': '_stepper.linear && (_stepper.selected && _stepper.selected.stepControl && (_stepper.selected.stepControl.invalid))'

  },
  inputs: ['type']
})
export class RgiRxStepperNext extends CdkStepperNext {
}

/** Button that moves to the previous step in a stepper workflow. */
@Directive({
  selector: 'button[rgiRxStepperPrevious]',
  host: {
    '[type]': 'type',
  },
  inputs: ['type']
})
export class RgiRxStepperPrevious extends CdkStepperPrevious {
}

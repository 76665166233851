<div *ngIf="quotations && (quotations | json) != '{}'" class="rgi-gp-quotations-container">
  <div style="flex: 20%;"></div>
  <div style="flex: 33%; border: 1px solid grey;">
    <div style="display: flex; flex-direction: row;">
      <div class="rgi-gp-quotation-amount">
        <label class="rgi-ui-text-4 rgi-ui-info">
          <span translate>_GP_._QUOTATION_._FIRST_INSTALLMENT_</span>
        </label><br>
        <label class="rgi-ui-text-4 rgi-ui-info">
          <span>{{quotations.summaryQuotation.firstInstallment | currency:'EUR':true}}</span>
        </label>
      </div>
      <div class="rgi-gp-quotation-amount">
        <label class="rgi-ui-text-4 rgi-ui-info">
          <span translate>_GP_._QUOTATION_._ANNUAL_PREMIUM</span>
        </label><br>
        <label class="rgi-ui-text-4 rgi-ui-info" data-qa="annual-premium">
          <span>{{quotations.summaryQuotation.annualPremium | currency:'EUR':true}}</span>
        </label>
      </div>
      <div class="rgi-gp-quotation-drop">
        <div class="rgi-dropdown">
          <button (click)="showDropDown = !showDropDown" class="rgi-ui-btn-outline uppercase"
            translate>{{'_GP_._QUOTATION_._VIEW_'}}</button>
          <div class="rgi-gp-dropdown-content" *ngIf="showDropDown">
            <button rgi-rx-button class="rgi-ui-btn-outline" (click)="openQuotationDetailModal()" translate>
              _GP_._QUOTATION_._DETAIL_
            </button>
            <button rgi-rx-button class="rgi-ui-btn-outline" (click)="openQuotationGuaranteeDetailsModal()"
                    translate>_GP_._QUOTATION_._GUARANTEE_DETAILS_
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 20%;"></div>
</div>

<template #lienModalOverlay></template>

<div *ngIf="liensList.length === 0">
  <span translate>There is no liens</span>
</div>

<!-- aggiungere la lista dei lien già impostati -->
<div *ngIf="liensList?.length" class="nmf_cont_sin_paritari">
  <div class="nmf_sin_paritari">
    <div *ngFor="let bond of liensList" class="nmf_sin_paritari_riga">
      <div class="nmf_sin_paritari_txt">
        <span translate>Description:</span> <b>{{bond?.description}}</b> -
        <span translate>Effective Date:</span> <b>{{bond?.effectiveDate | date:'dd/MM/yyyy'}}</b> -
        <span translate>Expiration Date:</span>
        <b>{{bond?.expirationDate | date:'dd/MM/yyyy'}}</b>&nbsp;&nbsp;

        <button (click)="removeLien(bond?.id)" [attr.data-qa]="'remove-lien_'+bond?.id" class="btn btn-default nmf_sin_paritari_button"
                type="button">
          <span class="glyphicon glyphicon-trash"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<br>

<button (click)="addBond()" class="btn btn-default nmf_button_add" data-qa="btn-liens-add" translate type="button">Add
  Lien
</button>

import {AuthTranslationsKeys} from './auth-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const AUTH_IT: AuthTranslationsKeys = {
    _AUTH_: {
        // eslint-disable-next-line max-len
        // View_from_{{_start_}}_to_{{_end}}_of_{{authorizationListlength}}_elements: 'Vedi from {{ start }} to {{ end}} of {{authorizationList.length}} elements',
        _ACTION_IS_MANDATORY: 'L\'azione è obbligatoria',
        _ARE_YOU_SURE_TO_APPLY_THIS_ACTION: 'Sei sicuro di voler applicare l\'azione?',
        _AUTHORIZATION_DEROGATION_LEVEL: 'Livello deroga richiesto dalla richiesta di autorizzazione:',
        _AUTHORIZATION_LEVEL: 'Livello Aut. Competente:',
        _AUTHORIZATION_REQUEST_CODE: 'Codice richiesta autorizzazione',
        _BACK: 'Indietro',
        _BLOCK: 'Blocco',
        _BUY_POLICY: 'Acquista Polizza',
        _BY_USER: 'dall\'utente',
        _CANCEL: 'Annulla',
        _CANCEL_REQUEST: 'Annulla Richiesta',
        _CARD_TITLE_: 'Autorizzazioni',
        _CHARACTERS: 'Caratteri',
        _CODE: 'Codice',
        _COMMENT: 'Commento:',
        _COMPETENCE_LEVEL: 'Livello competente:',
        _COMPETENT_LEVEL: 'Livello competente:',
        _COMPETENT_USER: 'Utente competente:',
        _CONFIRM: 'conferma',
        _CONFIRM_PROP_POL_AUTH: 'Conferma proposta/polizza in Autorizzazione',
        _CONFIRM_VARIATION: 'Conferma Variazione',
        _CONTRACT_NUMBER: 'Numero contratto',
        _CONTRACT_TYPE: 'Tipo Contratto',
        _DATE: 'Data',
        _DEROGATION_LEVEL: 'Livello deroga:',
        _DESCRIPTION: 'Descrizione',
        _EFFECTIVE_DATE: 'Data effetto',
        _ELEMENTS: 'elementi',
        _EMPTY: 'svuota',
        _ERROR: 'Errore',
        _ERR_AUTH_DEROGATION_LEVEL: 'Problema sul livello dell\'utente',
        _ERR_AUTH_EXTRA_CHECK: 'Gli extracheck non sono passati',
        _ERR_AUTH_NOT_OPERABLE: 'L\'evento non è applicabile',
        _ERR_AUTH_NOT_SAVED: 'Evento non salvato',
        _EVENT: 'Evento',
        _FILL_IN_REQUEST_STATUS_OR_USER: 'Valorizzare lo stato della richiesta o in alternativa l\'utente competente',
        _FIND: 'cerca',
        _HISTORY: 'Storia',
        // eslint-disable-next-line max-len
        _IN_ORDER_TO_ACCEPT_THE_REQUEST_ALL_DOCUMENTS_MUST_BE_VALIDATED: 'Per poter accettare la richiesta, tutti i documenti devono essere validati',
        _ISSUE_DATE: 'Data emissione',
        _ISSUE_POLICY: 'Emetti Polizza',
        _ISSUE_PROPOSAL: 'Emetti Proposta',
        _ISSUE_USER: 'Utente emissione',
        _LAST_ACTION: 'Ultima azione:',
        _LAST_ACTION_DATE: 'Data ultima azione:',
        _LAST_EVENT: 'Ultimo evento',
        _LAST_EXECUTED_ACTION: 'Ultima azione eseguita',
        _MANAGEMENT_NODE: 'Nodo di gestione',
        _MODIFY_AUTHORIZATION_LABEL: 'Modifica Autorizzazione',
        _MODIFY_PROPOSAL: 'Modifica',
        _MODIFY_VARIATION: 'Modifica Variazione',
        _NEXT_STATUS: 'Prossimo stato',
        _NODE: 'Nodo richiedente',
        _NODE_CODE: 'Codice nodo',
        _NODE_IS_MANDATORY: 'Il nodo è obbligatorio',
        _NOTES: 'Note',
        _NOT_AVAILABLE: 'Non disponibile',
        _NO_ELEMENT_PRESENT: 'Nessun elemento presente',
        _OF: 'di',
        _OPERATION: 'Operazione',
        _OPERATION_CONFIRMED: 'L\'operazione è stata confermata',
        _OPERATION_WAS_ISSUED_ON: 'L\'Operazione è stata emessa il',
        _OPERATOR: 'Operatore autenticato:',
        _OPERATOR_DEROGATION_LEVEL: 'Livello deroga operatore autenticato:',
        _OPERATOR_LEVEL: 'Livello operatore autenticato:',
        _ORIGIN_TYPE: 'Entita origine:',
        _PM_POLICY_NUMBER: 'Polizza Madre N.',
        _PM_PROPOSAL_NUMBER: 'Proposta Madre N.',
        _POLICY_NUMBER: 'Numero polizza',
        _POLICY_NUMBER_PM: 'Numero polizza madre',
        _POLICY_TYPE: 'Tipo contratto',
        _POSSIBLE_ACTIONS: 'Azioni possibili',
        _POSSIBLE_DEROGATION_LEVEL: 'Livello deroga possibile',
        _POSTSELL_CODE: 'Codice operazione post-vendita',
        _PRIORITY: 'Priorità',
        _PRODUCT: 'Prodotto',
        _PRODUCT_TYPE: 'Tipo prodotto',
        _PROPOSAL_NUMBER: 'Numero proposta',
        _PROPOSAL_NUMBER_PM: 'Numero proposta madre',
        _REFERENCE: 'Riferimento',
        _REQUESTS_LIST: 'Elenco richieste',
        _REQUEST_DATE: 'Data richiesta:',
        _REQUEST_DATE_FROM: 'Data richiesta dal',
        _REQUEST_DATE_FROM_IS_NOT_A_VALID_DATE: 'La Data richiesta dal non è valida',
        _REQUEST_DATE_FROM_MUST_BE_GREATER_THEN_REQUEST_DATE_TO: 'La data richiesta dal deve essere maggiore della data richiesta al.',
        _REQUEST_DATE_TO: 'Data richiesta al',
        _REQUEST_DATE_TO_IS_NOT_A_VALID_DATE: 'La Data richiesta al non è valida',
        _REQUEST_DETAIL: 'Dati richiesta',
        _REQUEST_NODE: 'Nodo Richiesta',
        _REQUEST_NUMBER: 'Numero Richiesta',
        _REQUEST_OPERATION: 'Operazione richiesta',
        _REQUEST_STATUS: 'Stato della richiesta',
        _REQUEST_STATUS_VARIATION: 'Variazioni Stato Richiesta',
        _REQUEST_TYPE: 'Tipo richiesta',
        _REQUEST_USER: 'Richiedente:',
        _SALE_POINT_GROUP: 'Gruppo Punto Vendita',
        _SEARCH_UNDER_SUBNET: 'Ricerca nel Sottoalbero',
        _SELECT_NODE: 'Seleziona',
        _STATUS: 'Stato:',
        _STATUS_AFTER_EVENT: 'Stato dopo l\'evento:',
        // eslint-disable-next-line max-len
        _STATUS_CHANGES_ARE_NOT_ADMITTED_FOR_THIS_AUTHORIZATION_REQUEST: 'Non hai la possibilità di modificare lo stato di questa richiesta di autorizzazione!',
        _STATUS_VARIATION: 'Variazione stato',
        _SUBSCRIBER: 'Contraente',
        _SUBSYSTEM: 'Sottosistema',
        _SUBSYSTEM_IS_MANDATORY: 'Il sottosistema è obbligatorio',
        _TO: 'a',
        _TYPE: 'Tipo',
        _USER: 'Utente',
        _USER_TYPE: 'Tipo utente competente',
        _VARIATION_SUMMARY: 'Modifica',
        _VIEW_ELEMENT: 'Visualizza Elementi',
        _VIEW_FROM: 'Vista da',
        _WARNINGS: 'Segnalazioni',
        _WORKFLOW_STATUS: 'Stato nel workflow',
    },
    _AUTHORIZATION_SEARCH: 'Ricerca Autorizzazione',
    _AUTHORIZATION_SEARCH_DETAIL: 'Dettaglio Ricerca di Autorizzazione',
};

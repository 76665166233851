import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AdapterprintCardModule} from "@rgi/adapterprint-card";
import {RgiRxPortalModule} from "@rgi/rx/portal";
import {ADAPTERPRINT_CARD} from "./adapterprint-card.card";

@NgModule({

  declarations: [],

  imports: [

    CommonModule,

    AdapterprintCardModule,

    RgiRxPortalModule.forRoot(ADAPTERPRINT_CARD)

  ],

  providers: [


  ]

})

export class AdapterPrintPortalModule {
}

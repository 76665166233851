import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiCountryLayerInputNumberFormatDirective} from './rgi-country-layer-input-number-format.directive';
import {RgiCountryLayerNumberFormatModule} from '../number-format/rgi-country-layer-number-format.module';


@NgModule({
    declarations: [RgiCountryLayerInputNumberFormatDirective],
    imports: [
        CommonModule,
        RgiCountryLayerNumberFormatModule
    ],
    exports: [
        RgiCountryLayerInputNumberFormatDirective
    ]
})
export class RgiCountryLayerFormsModule {
}

export class ContractNumber {
  createCopy: string;
  policyNumber: string;
  proposalNumber: string;
  quoteNumber: string;
  policyNumberEncrypted: string;
  proposalNumberEncrypted: string;

  constructor() {
  }
}

import { ActiveRoute, Routes } from '@rgi/rx/router';
import { StateStoreService } from '@rgi/rx/state';
import { CaiOpeningComponent } from './cai-opening.component';
import { CaiOpeningStateService } from './state-service/cai-opening.state.manager';
import { CaiOpeningService } from './state-service/cai-opening.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { CaiOpeningPartyUtility } from './state-service/utility/cai-opening-party.utility';
import { ModalService } from '@rgi/rx/ui';

export const CAI_OPENING_ROUTES: Routes = [
    {
        route: 'newClaimsHome',
        component: CaiOpeningComponent,
        providers: [
            {
                provide: CaiOpeningStateService,
                useClass: CaiOpeningStateService,
                deps: [ActiveRoute, StateStoreService, CaiOpeningService,
                    ModalService, RgiRxTranslationService, CaiOpeningPartyUtility]
            }
        ],
        children: []
    }
];

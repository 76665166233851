<div class="panel-body" [hidden]="!funds || !funds.length">
  <div class="row summary-container" *ngIf="!!selectedElementsForSummary.length && !checksOnOverallAlloc">
    <lpc-karma-fund-summary [funds]="selectedElementsForSummary" [totalAmount]="totalAmount"></lpc-karma-fund-summary>
  </div>
  <div>
    <lpc-karma-fund-message *ngFor="let error of errors" [error]="error"></lpc-karma-fund-message>
  </div>
  <div *ngIf="getFormGroup.valid">
    <div class="message">
      <div class="message-ok">
        <span translate>lpc_info_investment_complete</span>
      </div>
    </div>
  </div>
  <div class="row" [formGroup]="internalFormGroup">
    <div class="col-sm-6 col-lg-6" *ngIf="!checksOnOverallAlloc">
      <span>
        <ng-select class="col-sm-6 col-lg-2" id="custom" [clearable]="false" formControlName="count"
          (change)="handlePageSizeChange($event)">
          <ng-option *ngFor="let size of pageSizes" [value]="size">
            {{ size }}
          </ng-option>
        </ng-select>
      </span>
      <span style="line-height: 37px;" translate>elements</span>
    </div>
    <div class="col-lg-6" [ngStyle]="{'text-align': checksOnOverallAlloc ? 'left' : 'right'}">
      <label>
        <span translate>Name: </span><input name="filter" formControlName="search" /></label>
    </div>
  </div>
  <br>
  <div class="row" [formGroup]="formGroup">
    <!-- ****************************** FONDI ****************************** -->
    <ng-container *ngIf="!!profileIdForFunds">
      <div [formGroupName]="profileIdForFunds">
        <div class="col-sm-12" *ngFor="let fund of funds | paginate: {
          id: 'pagination-'+funds.length,
          itemsPerPage: pageSize,
          currentPage: page,
          totalItems: count
        }">
          <lpc-karma-investment-fund
            [investmentMode]="investmentMode"
            [formControlName]="fund.id.toString()"
            [totalAmount]="totalAmount"
            [definition]="fund"
            [showSliderInput]="!!showSliderInput"
            [showPercentageInput]="!!showPercentageInput"
            [enableSingleElementSelection]="!!enableSingleElementSelection"
            (valueChangeEmitter)="valueChangeHandling($event)"
            (toggleChange)="onToggleClick($event)"
            #singleFinancialElement>
          </lpc-karma-investment-fund>
        </div>
      </div>
    </ng-container>
    <!-- ****************************** FONDI ****************************** -->
    <!-- ***************************** PROFILI ***************************** -->
    <ng-container *ngIf="!profileIdForFunds">
      <div>
        <div class="col-sm-12" *ngFor="let fund of funds | paginate: {
          id: 'pagination-'+funds.length,
          itemsPerPage: pageSize,
          currentPage: page,
          totalItems: count
        }">
          <lpc-karma-investment-fund
            [investmentMode]="investmentMode"
            [formControlName]="fund.id.toString()"
            [totalAmount]="totalAmount"
            [definition]="fund"
            [showSliderInput]="!!showSliderInput"
            [showPercentageInput]="!!showPercentageInput"
            [enableSingleElementSelection]="!!enableSingleElementSelection"
            (valueChangeEmitter)="valueChangeHandling($event)"
            (toggleChange)="onToggleClick($event)"
            #singleFinancialElement>
          </lpc-karma-investment-fund>
        </div>
      </div>
    </ng-container>
    <!-- ***************************** PROFILI ***************************** -->
    <div class="row">
      <div class="right-align">
        <pagination-controls id="pagination-{{funds.length}}" previousLabel="" nextLabel=""
          (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>
      </div>
    </div>
  </div>
</div>
<div class="panel-body" *ngIf="!funds || !funds.length">
  <span style="text-align: center" translate>lpc_investment_not_found</span>
</div>

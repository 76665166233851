<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <span class="rgifont rgi-dettagli_tecnici"></span>
            {{ '_CLAIMS_._NEW_ASSET' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
        </div>
        <div class="modal-body" style="padding-top: 5px">
            <form [formGroup]="addAssetForm" data-qa="add-asset-form">
                <div class="container-fluid">
                    <div class="form-group">
                        <!--<div class="col-sm-12">
                            <label class="label-core"
                                attr.data-qa="new-asset-type-label">{{ '_CLAIMS_._ASSET_TYPE' | translate }}</label>
                        </div>
                        <input class="col-sm-12 form-control" formControlName="type" id="type" type="text" name="type"
                            attr.data-qa="new-asset-type-input" disabled> -->
                        <div class="form-group row">
                            <div class="col-sm-6 col-xs-12">
                                <label ng-bind="label">{{ '_CLAIMS_._ASSET_TYPE' | translate }}</label>
                                <select class="core-select form-control" formControlName="type" id="type" name="type"
                                    attr.data-qa="new-asset-type-input" [disabled]="disableTypeLoss"
                                    (click)="assetDetail()">
                                    <option></option>
                                    <option *ngFor="let typeOfLoss of typesOfLoss; " value={{typeOfLoss.id}}>
                                        {{typeOfLoss.description}}</option>
                                </select>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>

                            <div class="col-sm-6 col-xs-12" *ngIf="selInjury">
                                <label ng-bind="label">{{ '_CLAIMS_._NOMINATIVE' | translate }}</label><pnc-required
                                    required="true"></pnc-required>
                            </div>
                            <div class="col-sm-6 col-xs-12" style="text-decoration: underline;"
                                *ngIf="selInjury && party.description">
                                {{party.description}}
                            </div>

                            <div class="col-sm-6 col-xs-12" style="text-decoration: underline;"
                                *ngIf="selInjury && !party.description">
                                <input class="col-sm-6 form-control" formControlName="nominative" id="nominative"
                                    type="text" name="nominative" attr.data-qa="injury-asset-detail-nominative-input"
                                    maxlength="256">
                            </div>
                        </div>
                        <div *ngIf="selThings">
                            <div class="col-sm-12">
                                <label class="label-core" attr.data-qa="new-asset-description-label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</label>
                                <pnc-required required="true"></pnc-required>
                            </div>
                            <div class="col-sm-12">
                                <textarea class="col-sm-12 form-control" maxlength="100" formControlName="description"
                                    id="description" type="text" name="description"
                                    attr.data-qa="new-asset-description-input">
                            </textarea>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <claims-injury-asset-detail *ngIf="selInjury" [party]="party" [card]="card"
                                [injuryNatureList]="injuryNatureList" [injuryLocationList]="injuryLocationList"
                                [professionList]="professionList" [injuryCauseList]="injuryCauseList"
                                [addAssetForm]="addAssetForm"></claims-injury-asset-detail>
                        </div>
                        <div *ngIf="selVehicle">
                            <div class="col-sm-12">
                                <claims-vehicle-asset-detail [party]="party" [card]="card" [addAssetForm]="addAssetForm"
                                    (validationEventEmitter)="validationVehicleSet($event)"></claims-vehicle-asset-detail>
                            </div>
                        </div>
                        <div *ngIf="hasError" class="error-container">
                            <pnc-form-message type="error" detail="validationError" [messages]="errorMessages"
                                data-qa="new-asset-error-messages"></pnc-form-message>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button class="btn btn-warning pull-right" (click)="addAsset($event)"
                        attr.data-qa="claim-opening-policy-details-close-btn">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate |
                        uppercase}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
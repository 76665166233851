import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  OnChanges, OnInit,
  QueryList,
  SimpleChanges, TrackByFunction,
  ViewChildren
} from '@angular/core';
import {CdkStepper, StepperOrientation} from '@angular/cdk/stepper';
import {Directionality} from '@angular/cdk/bidi';
import {RgiRxStepHeaderComponent} from '../rgi-rx-step-header/rgi-rx-step-header.component';
import {RgiRxStepComponent} from '../rgi-rx-step/rgi-rx-step.component';
import {takeUntil} from 'rxjs/operators';
import {RgiRxStepper} from '../rgi-rx-stepper-api';
import {coerceBooleanProperty} from '@angular/cdk/coercion';


@Component({
  selector: 'rgi-rx-stepper',
  templateUrl: './rgi-rx-stepper.component.html',
  host: {
    class: 'rgi-ui-stepper',
    '[attr.aria-orientation]': 'orientation',
    role: 'tablist',
    '[class.rgi-ui-stepper-vertical]': 'orientation === "vertical"'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CdkStepper,
      useExisting: RgiRxStepperComponent
    },
    {
      provide: RgiRxStepper,
      useExisting: RgiRxStepperComponent
    }
  ],
  inputs: ['selectedIndex']
})
export class RgiRxStepperComponent extends RgiRxStepper implements OnInit, AfterContentInit, OnChanges {


  private _vertical = false;

  private _showIndex = true;
  trackStep: TrackByFunction<any> = (index, item: RgiRxStepComponent) => item.id;


  /**
   * @deprecated use orientation field
   */
  @Input() @HostBinding('class.rgi-ui-stepper-vertical') get vertical(): boolean {
    return this._vertical;
  }

  /**
   * @deprecated use orientation field
   */
  set vertical(value: boolean) {
    this._vertical = coerceBooleanProperty(value);
  }


  @Input() get showIndex(): boolean {
    return this._showIndex;
  }

  set showIndex(value: boolean) {
    this._showIndex = coerceBooleanProperty(value);
  }

  constructor(_directionality: Directionality, _changeRef: ChangeDetectorRef, _elRef: ElementRef) {
    super(_directionality, _changeRef, _elRef);
  }

  ngOnInit(): void {
    if (this.orientation === 'vertical') {
      this.vertical = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vertical && !changes.vertical.isFirstChange() && changes.vertical.currentValue !== changes.vertical.previousValue) {
      super.orientation = (changes.vertical.currentValue) ? 'vertical' : 'horizontal';
    }
  }

  ngAfterContentInit(): void {
    super.ngAfterContentInit();
    // Mark the component for change detection whenever the content children query changes
    this._steps.changes.pipe(takeUntil(this._destroyed)).subscribe(() => this._stateChanged());
  }

  get orientation(): StepperOrientation {
    return super.orientation;
  }

  _getStepHeaderId(i: number) {
    return `rgi-rx-step-header-${this._groupId}-${i}`;
  }


  override _getStepContentId(i: number): string {
    return `rgi-rx-step-content-${this._groupId}-${i}`;
  }


  override _getStepLabelId(i: number): string {
    return `rgi-rx-step-label-${this._groupId}-${i}`;
  }

  onStepHeaderClick(event: MouseEvent, step: RgiRxStepComponent) {
    if (!!step.selectable) {
      step.select();
    }
  }

  /**
   * Wrap the _onKeyDown method to filter keyboard interaction upon [selectable]
   * @param event the KeyboardEvent
   * @param step The StepComponent
   */
  _handleKeyDown(event: KeyboardEvent, step: RgiRxStepComponent) {
    if (step.selectable) {
      super._onKeydown(event);
    }
  }
}

import { PostsalesOperationKey } from '../../../models/postsales-session';

export const OPCODESANDSESSIONSNAMES = new Map([
    ['_VRECP', 'postsalesSessionCard'],
    ['_VTEXT', 'postsalesSessionCard'],
    ['_VCONT', 'postsalesSessionCard'],
    ['_ANPSE', 'postsalesSessionCard'],
    ['_VBENE', 'postsalesSessionCard'],
    ['_VAGG', 'postsalesSessionCard'],
    ['_RISPA', 'postsalesSessionCard'],
    ['_RISTO', 'postsalesSessionCard'],
    ['_SWITC', 'postsalesSessionCard'],
    ['_VMODP', 'postsalesSessionCard'],
    ['_ANREC', 'postsalesSessionCard'],
    ['_ESANT', 'postsalesSessionCard'],
    ['_ESANP', 'postsalesSessionCard'],
    ['_SIMOR', 'postsalesSessionCard'],
    ['_VARFR', 'postsalesSessionCard'],
    ['_VGARI', 'postsalesSessionCard'],
    ['_SOVRP', 'postsalesSessionCard'],
    ['_VRESI', 'postsalesSessionCard'],
    ['_VPRIR', 'postsalesSessionCard'],
    ['_VGARD', 'postsalesSessionCard'],
    ['_VVARC', 'postsalesSessionCard'],
    ['_VPRIN', 'postsalesSessionCard'],
    ['_ARESI', 'postsalesSessionCard'],
    ['_DTOOL', 'postsalesSessionCard'],
    ['_ATOOL', 'postsalesSessionCard'],
    ['_MTOOL', 'postsalesSessionCard'],
    ['_CCOMB', 'postsalesSessionCard'],
    ['_AANPS', 'postsalesSessionCard'],
    ['_VBLIQ', 'postsalesSessionCard'],
    ['_ANAPP', 'postsalesSessionCard'],
    ['_APEGN', 'postsalesSessionCard'],
    ['_AVINC', 'postsalesSessionCard'],
    ['_CPEGN', 'postsalesSessionCard'],
    ['_CVINC', 'postsalesSessionCard'],
    ['_TRFNO', 'postsalesSessionCard'],
    ['_VFIGU', 'postsalesSessionCard'],
    ['_SCOPZ', 'postsalesSessionCard'],
    ['_ARATA', 'postsalesSessionCard'],
    ['_RIDUZ', 'postsalesSessionCard'],
    ['_INPAG', 'postsalesSessionCard'],
    ['_AANRE', 'postsalesSessionCard'],
    ['_RIAPU', 'postsalesSessionCard'],
    ['_VPRFR', 'postsalesSessionCard'],
    ['_ASIMO', 'postsalesSessionCard'],
    ['_ASCAD', 'postsalesSessionCard'],
    ['_SINVA', 'postsalesSessionCard'],
    ['_TRRIS', 'postsalesSessionCard'],
    ['_TRAZA', 'postsalesSessionCard'],
    ['_ARINV', 'postsalesSessionCard'],
    ['_TROUT', 'postsalesSessionCard'],
    ['_ANTIC', 'postsalesSessionCard'],
    ['_VVCLA', 'postsalesSessionCard'],
  ]);

export const _OPERATION_CODES: {[key: string]: PostsalesOperationKey}  = {
  CHANGECONTACT: '_VRECP',
  CHANGEAPPENDIX: '_VTEXT',
  CHANGEHOLDER: '_VCONT',
  NONCOMPLETION: '_ANPSE',
  CHANGEBENEFICIARIES: '_VBENE',
  ADDITIONALPAYMENT: '_VAGG',
  PARTIALSURRENDER: '_RISPA',
  CHANGEPAYMENTMODE: '_VMODP',
  RECESS: '_ANREC',
  TOTALEARLYREPAYMENT: '_ESANT',
  PARTIALEARLYREPAYMENT: '_ESANP',
  DEATHCLAIM: '_SIMOR',
  CHANGEPAYMENTFREQUENCY: '_VARFR',
  CHANGEWARRANTYOPERATION: '_VGARI',
  OVERPREMIUMOPERATION: '_SOVRP',
  TERMINATIONINDIVIDUALPOLICY: '_VRESI',
  CHANGEWARRANTY: '_VGARD',
  CAPITALVARIATION: '_VVARC',
  RENEWALBOOKING: '_VPRIN',
  TERMINATERESCISSION: '_ARESI',
  DELETETOOL: '_DTOOL',
  ACTIVATETOOL: '_ATOOL',
  MODIFYTOOL: '_MTOOL',
  CHANGECOMBINATION: '_CCOMB',
  CANCELLATIONNONCOMPLETION: '_AANPS',
  CHANGESETTLEMENTBENEF: '_VBLIQ',
  CANCELAPPENDIX: '_ANAPP',
  OPENLIEN: '_APEGN',
  OPENASSIGNMENT: '_AVINC',
  TOTALSURRENDER: '_RISTO',
  CLOSEASSIGNMENT: '_CPEGN',
  CLOSEASSIGNMENT_: '_CVINC',
  SWITCHFREE: '_SWITC',
  POLICYTRASNFER: '_TRFNO',
  CHANGEROLES: '_VFIGU',
  EXPIRYCHOICE: '_SCOPZ',
  INSTALLMENTCANCELLATION: '_ARATA',
  REDUCTION: '_RIDUZ',
  PREMIUMPAYMENTSUSPENSION: '_INPAG',
  CANCELLATIONRECESS: '_AANRE',
  PREMIUMPAYMENTREACTIVATION: '_RIAPU',
  PREMIUMPAYMENTVARIATION: '_VPRFR',
  DISABILITYCLAIM: '_SINVA',
  INABILITYCLAIM: '_SINAB',
  SERIOUSILLNESSCLAIM: '_SIMAG',
  LOANGRANT: '_CPRES',
  LOANREFUND: '_RPRES',
  RENEWALCANCELLATION: '_ARINV',
  RESERVATIONTERMINATION: '_VPRIR',
  CHANGECLAUSES: '_VVCLA',
};

<div class="ppevo-survey-eval-market-tree" *ngFor="let market of markets">
    <div class="ppevo-tree-item" (click)="expand=!expand" title="market">
        <span *ngIf="market.rules.length" [ngClass]="expand?'ppevo-tree-close':'ppevo-tree-open'"></span>
        <span>
            <span class="ppevo-name">{{market.description}}</span> 
            <span class="ppevo-score"> [score: {{market.score}}]</span>
        </span>
    </div>
    <div class="ppevo-tree-body" [hidden]="!expand">
        <ng-container *ngIf="market.rules && market.rules.length">
            <div *ngFor="let rule of market.rules">
                <survey-eval-rule-tree [rule]="rule" [expand]="{rule:expandChild,msgs:expandChild, vars: expandChild, forms: expandChild}"></survey-eval-rule-tree>
            </div>
        </ng-container>
    </div>
</div>
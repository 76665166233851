import {Injectable} from '@angular/core';
import {
  AnagDynamicAttribute,
  AnagDynamicObj,
  AnagDynAttributeValue,
  AnagRowDataDynamicObject,
  AnagRowDataDynamicObjectLim
} from '../anag-model/anag-domain/anag-dynamic-obj';
import {Observable, of} from 'rxjs';
import {AnagStatelessService} from '../anag-resources/anag-stateless.service';
import {AnagEntityIta} from '../anag-model/anag-api/anag-subject-api';
import {ValidatorFn, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AnagDynamicObjectsService {

  constructor(
    public statelessService: AnagStatelessService
  ) { }

  getDynamicObjectTableData$(dynamicObj: AnagDynamicObj): Observable<Array<AnagRowDataDynamicObject>> | Observable<Array<AnagRowDataDynamicObjectLim>> {
    return of(this.getDynamicObjectTableData(dynamicObj));
  }

  getDynamicObjectTableData(dynamicObj: AnagDynamicObj): (AnagRowDataDynamicObjectLim | AnagRowDataDynamicObject)[] {
    const dynamicObjectInstances = this.getDynamicObjectInstancesByObject(dynamicObj);
    var isLimited = false;
    return dynamicObjectInstances.map((attribute, index) => {
      if (this.getVisibilityByName('CF', attribute) !== '1' && this.getVisibilityByName('CF', attribute) !== '2') {
        //var fC = this.getValueByName('CF', attribute);
        isLimited = true;
      } else { isLimited = false;}
      if(isLimited) {
        return {
          index,
          idObjectInstance: attribute.idInstance,
          name: this.getValueByName('NM', attribute),
          surname: this.getValueByName('SN', attribute)
        } as AnagRowDataDynamicObjectLim;
      } else {
      return {
          index,
          idObjectInstance: attribute.idInstance,
          fiscalCode : this.getValueByName('CF', attribute),
          name: this.getValueByName('NM', attribute),
          surname: this.getValueByName('SN', attribute)
        } as AnagRowDataDynamicObject;
      }
    });
  }

  getDynamicObjectInstancesByObject(dynamicObject: AnagDynamicObj) {
    const dynamicObjectInstances = [];
    if (dynamicObject.dynamicAttributes) {
      const firstAttribute = dynamicObject.dynamicAttributes[0];
      if (firstAttribute.values) {
        firstAttribute.values.forEach((firstAttributeValue, i) => {
          const dynamicObjectInstance = {
            idObject: dynamicObject.idDynObject,
            nameObject: dynamicObject.descrDynObject,
            idInstance: firstAttributeValue.idDynObjectInstance ? firstAttributeValue.idDynObjectInstance : undefined,
            values: []
          };

          const idDynObjectInstance = firstAttributeValue.idDynObjectInstance;

          dynamicObject.dynamicAttributes.forEach(dynamicAttribute => {
            if (dynamicAttribute.values) {
              let dynamicAttributeValue = null;
              if (idDynObjectInstance) {
                dynamicAttributeValue = dynamicAttribute.values.find(attrValue => attrValue.idDynObjectInstance === idDynObjectInstance);
              } else {
                dynamicAttributeValue = dynamicAttribute.values[i];
              }
              const dynAttributeValue = {
                descrDynAttribute: dynamicAttribute.descrDynAttribute,
                enumerationValues: dynamicAttribute.enumerationValues,
                idDynAttribute: dynamicAttribute.idDynAttribute,
                codeDynAttribute: dynamicAttribute.codeDynAttribute,
                mandatory: dynamicAttribute.mandatory,
                visibility: dynamicAttribute.visibilityValues,
                order: dynamicAttribute.order,
                typeAttribute: dynamicAttribute.typeAttribute,
                indexValue: dynamicAttributeValue.indexValue,
                value: this.getValueAttributeByType(dynamicAttributeValue, dynamicAttribute.typeAttribute.codice)
              };
              dynamicObjectInstance.values.push(dynAttributeValue);
            }
          });

          dynamicObjectInstances.push(dynamicObjectInstance);
        });
      }
    }
    return dynamicObjectInstances;
  }

  getValueAttributeByType(attributeValue: AnagDynAttributeValue, attributeType) {
    let value = {};
    switch (attributeType) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '9':
      case '10':
        value = attributeValue.stringValue;
        break;
      case '6':
        value = attributeValue.enumerationValue ? attributeValue.enumerationValue.codice : null;
        break;
      case '7':
        value = attributeValue.dataValue;
        break;
      case '8':
        value = attributeValue.addressValue;
        break;
      default:
        break;
    }
    return value;
  }

  setValueAttributeByType(attributeValue: AnagDynAttributeValue, attributeType, value, addressValue) {
    switch (attributeType) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '9':
      case '10':
        attributeValue.stringValue = value;
        break;
      case '6':
        if (!attributeValue.enumerationValue) {
          attributeValue.enumerationValue = new AnagEntityIta(null, null);
        }
        attributeValue.enumerationValue.codice = value;
        break;
      case '7':
        attributeValue.dataValue = value;
        break;
      case '8':
        attributeValue.addressValue = addressValue.addressValue;
        break;
      default:
        break;
    }
  }

  getValidatorByType(attribute: AnagDynamicAttribute): Array<ValidatorFn> {
    const validator = [];
    if (attribute.mandatory) {
      validator.push(Validators.required);
    }
    switch (attribute.typeAttribute.codice) {
      case '4':
        if (attribute.codeDynAttribute === 'CF') {
          validator.push(Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$'));
        }
        break;
      case '9':
        // tslint:disable-next-line:max-line-length
        validator.push(Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
        break;
      case '10':
        validator.push(Validators.pattern('^[0-9]*$'));
        break;
      default:
        break;
    }

    return validator;
  }

  getValueByName(attributeName, dynamicObjectInstance) {
    let attributeValue = null;
    dynamicObjectInstance.values.forEach(value => {
      if (value.codeDynAttribute === attributeName && value.value) {
        attributeValue = value.value.descrizione ? value.value.descrizione : value.value;
      }
    });
    return attributeValue;
  }

  getVisibilityByName(attributeName, dynamicObjectInstance) {
    let visibilityValue = null;
    dynamicObjectInstance.values.forEach(value => {
      if (value.codeDynAttribute === attributeName && value.visibility) {
        visibilityValue = value.visibility.codice ? value.visibility.codice : null;
      }
    });
    return visibilityValue;
  }

  isIstanceOfLimitedDynamicObj(AnagDynamicObj){
    if(this.getDynamicObjectTableData(AnagDynamicObj) instanceof AnagRowDataDynamicObject){
      return 1
    }
    if(this.getDynamicObjectTableData(AnagDynamicObj) instanceof AnagRowDataDynamicObjectLim){
      return 2
    }
  }


  removeRowToDynamicObject(inputDynamicObject: AnagDynamicObj, idObjectInstance, indexObjectInstance: number) {
    const index = inputDynamicObject.idDynObjectInstance.findIndex(elem => elem === idObjectInstance);
    if (index > -1) {
      inputDynamicObject.idDynObjectInstance.splice(index, 1);
    }
    inputDynamicObject.dynamicAttributes.forEach(dynamicAttribute => {
      if (!idObjectInstance) {
        dynamicAttribute.values.splice(indexObjectInstance, 1);
      } else {
        const attributeIndex = dynamicAttribute.values.findIndex(elem => elem.idDynObjectInstance === idObjectInstance);
        if (attributeIndex > -1) {
          dynamicAttribute.values.splice(attributeIndex, 1);
        }
      }
    });
  }

  createEditableDynamicObject(inputDynamicObject: AnagDynamicObj, idObjectInstance, indexObjectInstance: number): AnagDynamicObj {
    const editableDynamicObject = this.statelessService.deepClone(inputDynamicObject);
    editableDynamicObject.dynamicAttributes.forEach(dynamicAttribute => {
      if (!idObjectInstance) {
        dynamicAttribute.values = [dynamicAttribute.values[indexObjectInstance]];
      } else {
        const attributeIndex = dynamicAttribute.values.findIndex(elem => elem.idDynObjectInstance === idObjectInstance);
        if (attributeIndex > -1) {
          dynamicAttribute.values = [dynamicAttribute.values[attributeIndex]];
        }
      }
    });
    editableDynamicObject.dynamicAttributes.sort((a, b) => {
      return a.order - b.order;
    });
    return editableDynamicObject;
  }

  createInsertableDynamicObject(inputDynamicObject: AnagDynamicObj) {
    const insertableDynamicObject = this.statelessService.deepClone(inputDynamicObject);
    insertableDynamicObject.dynamicAttributes.forEach(dynamicAttribute => {
      const anagDynAttributeValue = new AnagDynAttributeValue();
      anagDynAttributeValue.enumerationValue = new AnagEntityIta(null, null);
      dynamicAttribute.values = [anagDynAttributeValue];
    });
    insertableDynamicObject.dynamicAttributes.sort((a, b) => {
      return a.order - b.order;
    });
    return insertableDynamicObject;
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  AnagIssueApiCompleteSubjectResponse,
  AnagIssueApiCreateSubject,
  AnagIssueApiGetSubjectResponse,
  AnagIssueApiSubjectApi,
  AnagIssueApiSubjects,
  AnagIssueCreateSubjectRequest,
  AnagIssueGetSubjectQueryString,
  AnagIssueSubject,
  AnagIssueSubjectsFilter
} from './anag-issue';


@Injectable({
  providedIn: 'root'
})
export class AnagIssueSubjectResourceService {
  private ANAG = '/anag/';
  private NODE = 'idNode';
  private SUBJECTS = 'subjects';
  private SUBJECT = 'subject';

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
  }

  getSubjectsByFilterPost(request: AnagIssueSubjectsFilter): Observable<Array<AnagIssueSubject>> {
    return this.http.post<AnagIssueApiSubjects>(this.getSubjectsUri(), request).pipe(
      map(resp => {
        return resp.output.subjects;
      })
    );
  }

  createSubject(request: AnagIssueCreateSubjectRequest, activeRoute: any) {
    return this.http.post<AnagIssueApiCreateSubject>(this.createSubjectsUri(activeRoute), request).pipe(
      map(resp => {
        return resp.subject;
      })
    );
  }

  getSubject(subjectId: string, nodeId: string): Observable<AnagIssueSubject> {
    return this.http.get<AnagIssueApiGetSubjectResponse>(this.getSubjectUri(subjectId, nodeId)).pipe(
      map(resp => {
        return resp.subject;
      })
    );
  }

  getSubjectComplete(
    subjectId: string,
    nodeId: string
  ): Observable<AnagIssueApiSubjectApi> {
    return this.http.get<AnagIssueApiCompleteSubjectResponse>(this.getSubjectUri(subjectId, nodeId)).pipe(
      map(resp => {
        return resp.subject;
      })
    );
  }

  putSubject(subject: AnagIssueApiSubjectApi): Observable<AnagIssueApiSubjectApi> {
    return this.http.put<AnagIssueApiCompleteSubjectResponse>(
      this.putSubjectUri(subject.objectId, subject.node.identification),
      {subject}
    ).pipe(map(resp => {
      return resp.subject;
    }));
  }

  putSubjectUri(subjectId: string | undefined, nodeId: string): string {
    return this.apiConf.api.portal.path + this.ANAG + this.SUBJECT +
      '/' +
      subjectId +
      '?idNode=' +
      nodeId;
  }

  protected getSubjectsUri(queryString?: AnagIssueGetSubjectQueryString): string {
    const uri = this.apiConf.api.portal.path + this.ANAG + this.SUBJECTS;
    return uri;
  }

  protected createSubjectsUri(activeRoute: any): string {
    let uri = this.apiConf.api.portal.path + this.ANAG + this.SUBJECT;

    uri += '?' + this.NODE + '=' + activeRoute.nodeId;

    return uri;
  }

  protected getSubjectUri(subjectId: string, nodeId: string): string {
    let uri = this.apiConf.api.portal.path + this.ANAG + this.SUBJECT;

    uri += '/' + subjectId;
    uri += '?' + this.NODE + '=' + nodeId;

    return uri;
  }
}

import {Inject, Injectable, Optional} from '@angular/core';
import {
  EVALUATION_NORMALIZED_FITTING,
  EvaluatorLob,
  EvaluatorObjectI,
  EvaluatorProductI,
  EvaluatorSectionI
} from '../../evaluation/models';
import {EvaluatorScoreObj} from '../../evaluation/models/evaluator-score-obj';
import {NG_PASSPROPRO_SURVEY_CONFIG, SurveyConfig} from '../../ng-passpropro-survey-api';
import {
  EvalProdCluster,
  EvalCovCfg,
  EvalCovsCfg,
  EvalLobConfig,
  EvalPkgCfg,
  EvalPkgPricing,
  EvalPkgsCfg,
  EvalPricing,
  EvalProductCfg,
  EvalProductsCfg,
  EvalSectionCfgMap,
  EvalSectionsCfg,
  EvalSelectionMode,
  EVAL_PACKAGE_SECTION_ID,
  LOB_UNCATEGORIZED,
  StyleClassScore
} from '../models/eval-product-cfg';

export function createProductsSectionMapId(product: EvaluatorProductI, section: EvaluatorSectionI) {
  return `${product.code}_${section.description}`
}

@Injectable()
export class SurveyEvalProductsCfgService {


  constructor(@Inject(NG_PASSPROPRO_SURVEY_CONFIG) @Optional() private config: SurveyConfig | undefined) {

  }


  createCfg(products: EvaluatorProductI[]): EvalProductsCfg {
    const prodsCfg: EvalProductsCfg = {
      prodsCfgMap: {},
      lobsCfg: null,
      hasVisibleProds: false,
      visibleProdsCount: 0,
      selectableProdsCount: 0,
      totalPricing: {price: {value: 0}},
      selectionMode: this.getSelectionMode(products)
    };

    products.forEach(
      prod => {
        const prodCfg = this.createProdCfg(prod);
        prodsCfg.prodsCfgMap[prod.code] = prodCfg;

        if (prodCfg.visible) {
          prodsCfg.visibleProdsCount++;
          if (prodsCfg.selectionMode == EvalSelectionMode.PRODUCT) {
            if (prod.selected) {
              prodsCfg.totalPricing.price.value += prodCfg.pricing.price.value;
            }
            prodsCfg.selectableProdsCount++;
          } else {
            prodsCfg.totalPricing.price.value += prodCfg.pricingSelected.price.value;
            prodsCfg.selectableProdsCount += prodCfg.childSelectableCount > 0 ? 1 : 0;
          }
        }

      }
    );

    prodsCfg.hasVisibleProds = prodsCfg.visibleProdsCount > 0;
    prodsCfg.lobsCfg = this.createLobsCfg(prodsCfg, products);

    return prodsCfg;
  }


  setSelectDeselectButtonsVisibility(evalProductCfg: EvalProductCfg, prod: EvaluatorProductI): EvalProductCfg {
    evalProductCfg.showDeselectAll = this.getShowDeselectAll(prod);
    evalProductCfg.showSelectAll = this.getShowSelectAll(prod);

    return evalProductCfg;
  }


  isGroupProductSelectionEnabled = (): boolean => !this.config || this.config.evaluation.hideGroupProductSelection === undefined || this.config.evaluation.hideGroupProductSelection === false;


  isGroupProductsByLob = (): boolean => !!this.config && this.config.evaluation.defaultProductsGroupBy === 'lob';


  /**
   * Update producs cfg by product.
   * So update prodCfg for product, visibleProdsCount, hasVisible and pricing
   *
   */
  updateCfgByProd(prod: EvaluatorProductI, products: EvaluatorProductI[], prodsCfg: EvalProductsCfg): EvalProductsCfg {
    const updateProdCfg = prodsCfg.prodsCfgMap[prod.code];
    const oldProdVisible = updateProdCfg.visible;

    updateProdCfg.showSelectAll = this.getShowSelectAll(prod);
    updateProdCfg.showDeselectAll = this.getShowDeselectAll(prod);
    updateProdCfg.covsCfg = this.getCoveragesCfg(prod, updateProdCfg.covsCfg);
    updateProdCfg.pkgsCfg = this.getPackagesCfg(prod, updateProdCfg.pkgsCfg);
    updateProdCfg.visible = this.isProductVisible(prod, updateProdCfg);

    if (updateProdCfg.visible != oldProdVisible) {
      prodsCfg.visibleProdsCount += updateProdCfg.visible ? 1 : -1;
    }

    prodsCfg.hasVisibleProds = prodsCfg.visibleProdsCount > 0;
    prodsCfg.prodsCfgMap[prod.code] = updateProdCfg;

    return this.updatePricing(prodsCfg, products);
  }


  updatePricing(prodsCfg: EvalProductsCfg, products: EvaluatorProductI[]): EvalProductsCfg {

    prodsCfg.totalPricing.price.value = 0;

    Object.keys(prodsCfg.prodsCfgMap).forEach(
      prodCode => {
        const prodCfg = prodsCfg.prodsCfgMap[prodCode];
        const prod: EvaluatorProductI = products.find(p => p.code == prodCode);

        const covs = prod.sections.reduce((covs, section) => [...covs, ...section.coverages], []);
        const covsCfg = prodCfg.covsCfg;

        covsCfg.pricing.price.value = 0;
        covsCfg.pricingSelected.price.value = 0;

        Object.keys(covsCfg.covsMap).forEach(
          covCode => {
            const covCfg = covsCfg.covsMap[covCode];
            const cov: EvaluatorObjectI = covs.find(c => c.code == covCode);

            if (covCfg.visible) {
              if (cov.selected) {
                covsCfg.pricingSelected.price.value += covCfg.pricing.price.value;
              }
              covsCfg.pricing.price.value += covCfg.pricing.price.value;
            }
          }
        );

        const pkgsCfg = prodCfg.pkgsCfg;

        pkgsCfg.pricing.price.value = 0;
        pkgsCfg.pricingSelected.price.value = 0;

        Object.keys(pkgsCfg.pkgsMap).forEach(
          pkgId => {
            const pkgCfg = pkgsCfg.pkgsMap[pkgId];
            const pkg = prod.packages.find(p => p.package.id + "" == pkgId);

            if (pkgCfg.visible) {
              pkgsCfg.pricing.price.value += pkgCfg.pricing.price.value;
              if (pkg.selected) {
                pkgsCfg.pricingSelected.price.value += pkgCfg.pricing.price.value;
              }

            }
          }
        );

        prodCfg.pricing.price.value = covsCfg.pricing.price.value + pkgsCfg.pricing.price.value;
        prodCfg.pricingSelected.price.value = covsCfg.pricingSelected.price.value + pkgsCfg.pricingSelected.price.value;

        if (prodCfg.visible) {
          if (prodsCfg.selectionMode == EvalSelectionMode.PRODUCT) {
            if (prod.selected) {
              prodsCfg.totalPricing.price.value += prodCfg.pricing.price.value;
            }
          } else {
            prodsCfg.totalPricing.price.value += prodCfg.pricingSelected.price.value;
          }
        }
      }
    );

    return prodsCfg;
  }


  protected createProdCluster(prod: EvaluatorProductI): EvalProdCluster | null {
    if (prod.clusters && prod.clusters.length) {
      return {
        title: prod.clusters.map(cluster => cluster.name).join(", "),
        styleClass: "ppevo-cluster"
      }
    }
    return null;
  }


  /**
   * returns the type of selection, that is, at the product or coverage/package level
   * @param products
   * @returns EvalSelectionMode.PRODUCT | EvalSelectionMode.COV_OR_PKG
   */
  protected getSelectionMode(products: EvaluatorProductI[]): EvalSelectionMode {
    return products.some(this.isProductSelectable) ?
      EvalSelectionMode.PRODUCT :
      EvalSelectionMode.COV_OR_PKG;
  }


  protected createProdCfg(prod: EvaluatorProductI): EvalProductCfg {
    const prodCfg: EvalProductCfg = {
      label: prod.name,
      showSelectAll: this.getShowSelectAll(prod),
      showDeselectAll: this.getShowDeselectAll(prod),
      covsCfg: {...this.getCoveragesCfg(prod)},
      pkgsCfg: {...this.getPackagesCfg(prod)},
      hasAnyChildVisible: false,
      childSelectableCount: 0,
      expandContent: true,
      visible: false,
      sectionsCfg: null,
      pricingSelected: {price: {value: 0}},
      pricing: {price: {value: 0}},
      scoreStyleClass: this.getStyleClassScoreProduct(prod),
      canShowScore: this.canShowScore(prod),
      canShowInfo: this.canShowInfoProduct(prod),
      cluster: this.createProdCluster(prod)
    };

    prodCfg.hasAnyChildVisible = prodCfg.covsCfg.hasAnyChildVisible || prodCfg.pkgsCfg.hasAnyChildVisible;
    prodCfg.childSelectableCount = prodCfg.covsCfg.selectableCount + prodCfg.pkgsCfg.selectableCount;
    prodCfg.visible = this.isProductVisible(prod, prodCfg);
    prodCfg.sectionsCfg = {...this.createSectionsCfg(prod)};
    prodCfg.pricingSelected.price.value = prodCfg.covsCfg.pricingSelected.price.value + prodCfg.pkgsCfg.pricingSelected.price.value
    prodCfg.pricing.price.value = prodCfg.covsCfg.pricing.price.value + prodCfg.pkgsCfg.pricing.price.value;

    return prodCfg;
  }


  protected isProductVisible(prod: EvaluatorProductI, prodCfg: EvalProductCfg) {
    if (prod.selectable) {
      return !this.isFittingTooLowToShow(prod);
    } else {
      return !(!prodCfg.hasAnyChildVisible && this.isFittingTooLowToShow(prod))
    }
  }


  protected createLobsCfg(prodsCfg: EvalProductsCfg, products: EvaluatorProductI[]): EvalLobConfig {
    const lobCfg: EvalLobConfig = {
      lobs: [],
      visibilityMap: {},
      visibleProductsByLob: {}
    };

    products.forEach(prod => this.createProdLobCfg(lobCfg, prodsCfg.prodsCfgMap[prod.code], prod));
    lobCfg.lobs = [...this.sortLobs(lobCfg.lobs)];

    return lobCfg;
  }


  protected createSectionsCfg(prod: EvaluatorProductI): EvalSectionsCfg {
    const sectionsCfg: EvalSectionsCfg = {
      map: {},
      visibleSectionIds: []
    }

    prod.sections.forEach(
      section => {
        const fakeId = createProductsSectionMapId(prod, section);
        sectionsCfg.map[fakeId] = {
          visible: this.isSectionVisible(section, prod.packages),
          isPackage: false,
          label: section.description,
          pricingSelected: {price: {value: 0}}
        };
        if (sectionsCfg.map[fakeId].visible) {
          !sectionsCfg.visibleSectionIds.some(id => id == fakeId)
          &&
          sectionsCfg.visibleSectionIds.push(fakeId);
        }
      }
    );

    const packageSection = {
      code: '',
      coverages: [...prod.packages],
      description: '',
      id: EVAL_PACKAGE_SECTION_ID
    };

    sectionsCfg.map[packageSection.id] = {
      visible: this.isSectionVisible(packageSection, prod.packages),
      isPackage: true,
      label: '',
      pricingSelected: {price: {value: 0}}
    };

    if (sectionsCfg.map[EVAL_PACKAGE_SECTION_ID].visible) {
      sectionsCfg.visibleSectionIds.push(packageSection.id);
    }

    return sectionsCfg;
  }


  protected isSectionVisible(section: EvaluatorSectionI, packages: EvaluatorObjectI[] = []): boolean {
    return !section.coverages
      .filter(c => {
        return packages.length === 0 || packages.every(pack => !pack.package.coverages.find(cov => cov.code === c.code))
      })
      .every((pkg) => {
        return this.isFittingTooLowToShow(pkg);
      })
  }


  /**
   * Create product lob configuration if product is visible
   */
  protected createProdLobCfg(lobCfg: EvalLobConfig, prodCfg: EvalProductCfg, prod: EvaluatorProductI) {
    if (!prodCfg.visible) return;

    const lob = (prod.lineOfBusiness && prod.lineOfBusiness.code && prod.lineOfBusiness) || LOB_UNCATEGORIZED;
    lobCfg.visibilityMap[lob.code] = true;

    if (!lobCfg.visibleProductsByLob[lob.code]) {
      lobCfg.visibleProductsByLob[lob.code] = [];
      lobCfg.lobs.push(lob);
    }

    lobCfg.visibleProductsByLob[lob.code].push(prod.code);
  }


  /**
   * Sort lob by alphabetic order, except uncategorized empty lob placed last
   */
  protected sortLobs(lobs: EvaluatorLob[]): EvaluatorLob[] {
    return [...lobs].sort(
      (lob1, lob2) => {
        if (lob1.code == LOB_UNCATEGORIZED.code) return 1;
        if (lob2.code == LOB_UNCATEGORIZED.code) return -1;
        return lob1.code.localeCompare(lob2.code)
      }
    );
  }


  protected getShowSelectAll(evaluatorProduct: EvaluatorProductI): boolean {
    const allAreSelected = (accumulator, value: boolean) => accumulator && value;
    const result = this.getPackagesCoveragesSelectionsByAccumulator(evaluatorProduct, allAreSelected);
    return (result.packagesAccumulated !== undefined && !result.packagesAccumulated) || (result.coveragesAccumulated !== undefined && !result.coveragesAccumulated);
  }


  protected getShowDeselectAll(evaluatorProduct: EvaluatorProductI): boolean {
    const anyIsSelected = (accumulator, value: boolean) => accumulator || value;
    const result = this.getPackagesCoveragesSelectionsByAccumulator(evaluatorProduct, anyIsSelected);

    return (result.packagesAccumulated !== undefined && result.packagesAccumulated) || (result.coveragesAccumulated !== undefined && result.coveragesAccumulated);
  }


  /**
   * @Returns Object with two booleans: packagesAccumulated and coveragesAccumulated for sellable packages and coverages.
   *
   * @param evaluatorProduct
   * @param accumulator
   * @protected
   */
  protected getPackagesCoveragesSelectionsByAccumulator(evaluatorProduct: EvaluatorProductI, accumulator: any): { packagesAccumulated: boolean, coveragesAccumulated: boolean } {
    const pkgs = evaluatorProduct.packages.filter(p => p.sellable && p.selectable).map(p => p.selected);

    return {
      packagesAccumulated: pkgs.length > 0 ? pkgs.reduce(accumulator) : undefined,
      coveragesAccumulated: evaluatorProduct.sections.map(sect => {
          const covs = sect.coverages.filter(cov => cov.sellable && cov.selectable).map(cov => cov.selected);
          return covs.length > 0 ? covs.reduce(accumulator) : undefined;
        }
      )[0]
    };
  }


  protected getCoveragesCfg(prod: EvaluatorProductI, covsCfg?: EvalCovsCfg): EvalCovsCfg {
    const isUpdate = !!covsCfg;

    if (!isUpdate) {
      covsCfg = {
        covsMap: {},
        hasAnyChildVisible: false,
        selectableCount: 0,
        pricingSelected: {price: {value: 0}},
        pricing: {price: {value: 0}}
      };
    }

    covsCfg.hasAnyChildVisible = false;
    covsCfg.selectableCount = 0;
    covsCfg.pricing = {price: {value: 0}};
    covsCfg.pricing = {price: {value: 0}};

    prod.sections
      .reduce((covs, section) => [...covs, ...section.coverages], [])
      .forEach(
        (cov: EvaluatorObjectI) => {
          covsCfg.covsMap[cov.code] = this.getCoverageCfg(prod, cov, isUpdate ? covsCfg.covsMap[cov.code] : undefined);
          covsCfg.hasAnyChildVisible = covsCfg.hasAnyChildVisible || covsCfg.covsMap[cov.code].visible;
          covsCfg.selectableCount += covsCfg.covsMap[cov.code].visible && covsCfg.covsMap[cov.code].selectionVisible ? 1 : 0;

          if (covsCfg.covsMap[cov.code].visible) {
            if (cov.selected) {
              covsCfg.pricingSelected.price.value += covsCfg.covsMap[cov.code].pricing.price.value;
            }
            covsCfg.pricing.price.value += covsCfg.covsMap[cov.code].pricing.price.value;
          }
        }
      );

    return covsCfg;
  }


  protected getCoverageCfg(prod: EvaluatorProductI, cov: EvaluatorObjectI, covCfg?: EvalCovCfg): EvalCovCfg {
    const visible = this.isCoverageVisible(prod, cov);

    if (!covCfg) {

      covCfg = {
        visible: false,
        sellable: false,
        selectionVisible: false,
        editable: this.isCoverageEditable(prod, cov),
        pricing: {
          price: {
            value: visible && cov.pricing && cov.pricing.score ? cov.pricing.score : 0
          }
        },
        canShowInfo: this.canShowInfo(cov),
        canShowScore: this.canShowScore(cov),
        styleClassScore: this.getStyleClassScore(cov)
      };
    }

    covCfg.visible = visible;
    covCfg.sellable = this.isCoverageSellable(prod, cov);
    covCfg.selectionVisible = this.isCoverageSelectionVisible(prod, cov);

    return covCfg;
  }


  protected getPackagesCfg(prod: EvaluatorProductI, pkgsCfg?: EvalPkgsCfg): EvalPkgsCfg {
    const isUpdate = !!pkgsCfg;

    if (!isUpdate) {
      pkgsCfg = {
        pkgsMap: {},
        hasAnyChildVisible: false,
        selectableCount: 0,
        pricingSelected: {price: {value: 0}},
        pricing: {price: {value: 0}}
      };
    }

    pkgsCfg.hasAnyChildVisible = false;
    pkgsCfg.selectableCount = 0;
    pkgsCfg.pricing = {price: {value: 0}};
    pkgsCfg.pricingSelected = {price: {value: 0}};

    prod.packages
      .forEach(
        (pkg: EvaluatorObjectI) => {
          pkgsCfg.pkgsMap[pkg.package.id] = this.getPackageCfg(prod, pkg, isUpdate ? pkgsCfg.pkgsMap[pkg.package.id] : undefined);
          pkgsCfg.hasAnyChildVisible = pkgsCfg.pkgsMap[pkg.package.id].visible || pkgsCfg.hasAnyChildVisible;
          pkgsCfg.selectableCount += pkgsCfg.pkgsMap[pkg.package.id].visible && pkgsCfg.pkgsMap[pkg.package.id].selectionVisible ? 1 : 0;

          if (pkgsCfg.pkgsMap[pkg.package.id].visible) {
            pkgsCfg.pricing.price.value += pkgsCfg.pkgsMap[pkg.package.id].pricing.price.value;
            if (pkg.selected) {
              pkgsCfg.pricingSelected.price.value += pkgsCfg.pkgsMap[pkg.package.id].pricing.price.value;
            }

          }
        }
      );

    return pkgsCfg;
  }


  protected getPackageCfg(prod: EvaluatorProductI, pkg: EvaluatorObjectI, cfg?: EvalPkgCfg): EvalPkgCfg {
    if (!cfg) {
      const pricingData = this.createPkgPricing(prod, pkg);
      cfg = {
        visible: false,
        sellable: false,
        selectionVisible: false,
        editable: this.isPackageEditable(prod, pkg),
        pricing: pricingData.pricing,
        canShowInfo: this.canShowInfo(pkg),
        canShowScore: this.canShowScore(pkg),
        styleClassScore: this.getStyleClassScore(pkg),
        pkgPricing: pricingData.pkgPricing
      }
    }

    cfg.visible = this.isPackageVisible(prod, pkg);
    cfg.sellable = this.isPackageSellable(prod, pkg);
    cfg.selectionVisible = this.isPackageSelectionVisible(prod, pkg);

    return cfg;
  }


  /**
   * For UI, disable checkbox if false
   * @param prod
   * @param cov
   */
  protected isCoverageEditable(prod: EvaluatorProductI, cov: EvaluatorObjectI): boolean {
    return true;
  }


  /**
   * For UI, disable checkbox if false
   * @param prod
   * @param pkg
   */
  protected isPackageEditable(prod: EvaluatorProductI, pkg: EvaluatorObjectI): boolean {
    return true;
  }


  /**
   * For UI, hide checkbox if false
   * @param prod
   * @param cov
   */
  protected isCoverageSelectionVisible(prod: EvaluatorProductI, cov: EvaluatorObjectI): boolean {
    return this.isCoverageSellable(prod, cov);
  }


  /**
   * For UI, hide checkbox if false
   * @param prod
   * @param pkg
   */
  protected isPackageSelectionVisible(prod: EvaluatorProductI, pkg: EvaluatorObjectI): boolean {
    return this.isPackageSellable(prod, pkg);
  }


  /**
   * For Business Logic
   * @param prod
   * @param cov
   */
  protected isCoverageSellable(prod: EvaluatorProductI, cov: EvaluatorObjectI): boolean {
    return cov.sellable && cov.selectable;
  }


  /**
   * For UI, hide package box if false
   * @param prod
   * @param pkg
   */
  protected isPackageVisible(prod: EvaluatorProductI, pkg: EvaluatorObjectI): boolean {
    return !this.isFittingTooLowToShow(pkg);
  }


  /**
   * For Business Logic
   * @param prod
   * @param pkg
   */
  protected isPackageSellable(prod: EvaluatorProductI, pkg: EvaluatorObjectI): boolean {
    return pkg.sellable && pkg.selectable;
  }


  /**
   * For UI, hide coverage box if false
   * @param product
   * @param cov
   */
  protected isCoverageVisible(product: EvaluatorProductI, cov: EvaluatorObjectI): boolean {
    return !product.packages.some(
        (pkg: EvaluatorObjectI) =>
          pkg.package.coverages.some(
            (coverage: EvaluatorObjectI) => coverage.code === cov.code
          )
      )
      && !this.isFittingTooLowToShow(cov);
  }


  /**
   * For info, return package total pricing and package coverages pricing
   * @param product
   * @param packageObj
   * @returns
   */
  protected createPkgPricing(product: EvaluatorProductI, packageObj: EvaluatorObjectI): { pricing: EvalPricing, pkgPricing: EvalPkgPricing } {
    const pricing: EvalPricing = {
      price: {value: 0}
    }

    const pkgPricing: EvalPkgPricing = {
      covPricingMap: {},
      hasCoveragePricing: false
    }

    let hasCoveragePricing: boolean = false;

    const price = packageObj.package.coverages.reduce(
      (price: number, packCov: EvaluatorObjectI) => {

        const productCov = product.sections
          .reduce((covs: [], section: EvaluatorSectionI) => [...covs, ...section.coverages], [])
          .find((cov: EvaluatorObjectI) => cov.code === packCov.code);

        pkgPricing.covPricingMap[packCov.code] = {price: {value: 0}};

        if (productCov && productCov.pricing && productCov.pricing.score) {
          const covPrice = productCov.pricing.score;
          pkgPricing.covPricingMap[packCov.code].price.value = covPrice;
          hasCoveragePricing = true;
          return price + covPrice;
        } else {

          return price;
        }
      }
      , 0
    );

    pricing.price.value = price;

    if (price > 0) {
      pkgPricing.hasCoveragePricing = hasCoveragePricing;
    }

    return {pricing, pkgPricing};
  }


  /**
   * For UI, return if show info for coverage or package
   * @param covOrPkg
   * @returns
   */
  protected canShowInfo(covOrPkg: EvaluatorObjectI): boolean {
    return !!(
      (covOrPkg.description && covOrPkg.description.length)
      ||
      (covOrPkg.messages && covOrPkg.messages.length)
      ||
      (covOrPkg.targetmarket && covOrPkg.targetmarket.length)
      ||
      (covOrPkg.notargetmarket && covOrPkg.notargetmarket.length)
      ||
      covOrPkg.pricing
      ||
      (covOrPkg.pricing && covOrPkg.pricing.messages && covOrPkg.pricing.messages)
      ||
      (covOrPkg.coverages && covOrPkg.coverages.length)
    );

  }


  protected canShowInfoProduct(p: EvaluatorProductI) {
    return !!(
      (p.description && p.description.length)
      ||
      (p.messages && p.messages.length)
      ||
      (p.targetmarket && p.targetmarket.length)
      ||
      (p.notargetmarket && p.notargetmarket.length)
    );
  }


  protected canShowScore(covOrPkg: EvaluatorObjectI): boolean {
    return !!(covOrPkg.notargetmarket.length || covOrPkg.targetmarket.length);
  }


  /**
   * For UI, return class style for generic score object (coverage, package or product)
   * @param scoreObj
   * @returns
   */
  protected getStyleClassScore(scoreObj: EvaluatorScoreObj): StyleClassScore {
    const fitting = scoreObj.fitting;
    if (fitting >= 4 && fitting < 7) {
      return "ppevo-medium";
    } else if (fitting >= 7) {
      return "ppevo-high";
    } else if (fitting < 4) {
      return "ppevo-low";
    }
    return '';
  }


  /**
   * For UI, return class style for product
   * @param product
   * @returns
   */
  protected getStyleClassScoreProduct(product: EvaluatorProductI): StyleClassScore {
    if ((product.targetmarket && product.targetmarket.length) || (product.notargetmarket && product.notargetmarket.length)) {
      return this.getStyleClassScore(product)
    }
    return '';
  }


  protected isFittingTooLowToShow(evaluationObject: EvaluatorObjectI) {
    return this.config && this.config.evaluation.hideNonFittingElements ? this.normalizeFitting(evaluationObject.fitting) === EVALUATION_NORMALIZED_FITTING.LOW : false;
  }


  protected isProductSelectable = (product: EvaluatorProductI): boolean => product.sellable && product.selectable;


  protected normalizeFitting(fitting: number | string) {
    if (fitting <= 3) {
      return EVALUATION_NORMALIZED_FITTING.LOW;
    } else if (fitting >= 4 && fitting < 7) {
      return EVALUATION_NORMALIZED_FITTING.MID;
    }
    return EVALUATION_NORMALIZED_FITTING.HIGH;
  };

}

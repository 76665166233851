import { SelectFactorComponent } from './select-factor/select-factor.component';
import { Factor } from '../../models/policy.model';
import {
  Directive, ViewContainerRef, Input,
  ComponentFactoryResolver, ComponentRef, OnDestroy
} from '@angular/core';
import { RadioGroupFactorComponent } from './radio-group-factor/radio-group-factor.component';
import { DatepickerFactorComponent } from './datepicker-factor/datepicker-factor.component';
import { InputFactorComponent } from './input-factor/input-factor.component';
import { UntypedFormGroup } from '@angular/forms';
import { FACTOR_TYPE } from '../../enum/life-issue.enum';


@Directive({
  selector: '[licFactorLoader]',
})
export class LoaderDirective implements OnDestroy {
  element = {
    label: '',
    value: ''
  };

  constructor(
    private view: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
  ) { }

  private ref: ComponentRef<any>;

  @Input() group: UntypedFormGroup;
  @Input() set licFactorLoader(config: Factor) {

    this.view.clear();
    if (config) {
      let outputFactor: any;
      switch (config.type.toString().trim()) {
        case FACTOR_TYPE.NUMERIC:
          if (config.values.length !== 0) {
            outputFactor = this.resolver.resolveComponentFactory(SelectFactorComponent);
            this.ref = this.view.createComponent(outputFactor);
// eslint-disable-next-line @typescript-eslint/no-shadow
            const selectFactor: ComponentRef<SelectFactorComponent> = this.ref as ComponentRef<SelectFactorComponent>;
            selectFactor.instance.setFactor(config, this.group);
          } else {
            outputFactor = this.resolver.resolveComponentFactory(InputFactorComponent);
            this.ref = this.view.createComponent(outputFactor);
// eslint-disable-next-line @typescript-eslint/no-shadow
            const inputFactor: ComponentRef<InputFactorComponent> = this.ref as ComponentRef<InputFactorComponent>;
            inputFactor.instance.setFactor(config, this.group);
          }
          break;
        case FACTOR_TYPE.STRING:
          outputFactor = this.resolver.resolveComponentFactory(InputFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const inputFactor: ComponentRef<InputFactorComponent> = this.ref as ComponentRef<InputFactorComponent>;
          inputFactor.instance.setFactor(config, this.group);
          break;
        case FACTOR_TYPE.STRUCT:
        case FACTOR_TYPE.LIST:
          outputFactor = this.resolver.resolveComponentFactory(SelectFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const selectFactor: ComponentRef<SelectFactorComponent> = this.ref as ComponentRef<SelectFactorComponent>;
          selectFactor.instance.setFactor(config, this.group);
          break;
        case FACTOR_TYPE.BOOL:
          outputFactor = this.resolver.resolveComponentFactory(RadioGroupFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const radioFactor: ComponentRef<RadioGroupFactorComponent> = this.ref as ComponentRef<RadioGroupFactorComponent>;
          radioFactor.instance.setFactor(config, this.group);
          break;
        case FACTOR_TYPE.DATE:
          outputFactor = this.resolver.resolveComponentFactory(DatepickerFactorComponent);
          this.ref = this.view.createComponent(outputFactor);
          const dateFactor: ComponentRef<DatepickerFactorComponent> = this.ref as ComponentRef<DatepickerFactorComponent>;
          dateFactor.instance.setFactor(config, this.group);
          break;
      }
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.destroy();
    }
  }
}

import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild, ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'rgi-rx-modal',
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    role: 'dialog',
    '[attr.aria-labelledby]': 'ariaLabelledBy || null',
  },
  styles: [
    `
      body.rgi-rx-modal-for-body {
        overflow: hidden;
      }

      .rgi-rx-modal-show {
        display: block;
      }

      .rgi-rx-modal-hidden {
        display: none;
      }
    `
  ]
})
export class ModalComponent implements OnInit, AfterContentInit {

  @Input() enableClickBackground = true;
  @Output() onClose = new EventEmitter();

  @ViewChild('ng-content', {static: true}) host: ViewContainerRef;

  ariaLabelledBy?: string;

  constructor(public elRef: ElementRef, @Inject(DOCUMENT) private document: any) {
  }


  ngOnInit() {
    this.close();
  }

  ngAfterContentInit(): void {
    // this is a workaround for the aria-labelledby attribute until we refactor this dialog system
    setTimeout(() => {
      const panelTitle = this.elRef.nativeElement.querySelector('.rgi-ui-panel-title');
      this.ariaLabelledBy = panelTitle ? panelTitle.id : null;
    });
  }




  close(data?: any) {
    this.elRef.nativeElement.classList.remove('rgi-rx-modal-show');
    this.elRef.nativeElement.classList.add('rgi-rx-modal-hidden');
    this.document.body.classList.remove('rgi-rx-modal-for-body');
    this.onClose.emit(data);
  }


  open() {
    this.elRef.nativeElement.classList.add('rgi-rx-modal-show');
    this.elRef.nativeElement.classList.remove('rgi-rx-modal-hidden');
    this.document.body.classList.add('rgi-rx-modal-for-body');
  }


  onClickBackground() {
    if (this.enableClickBackground) {
      this.close();
    }
  }


  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeyDown(event: KeyboardEvent) {
    this.close();
  }
}

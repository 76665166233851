import {BaseEntity, Factor, GPClause} from './group-policy-issue-policy-data';
import {GPLightCluster} from "./group-policy-cluster";

export interface GPAsset {
  codeAsset: string;
  descrAsset: string;
  deletable: boolean;
  units: Units[];
  variables: Factor[];
  structure: BaseEntity;
  clusters: GPLightCluster[];
  clauses: GPClause[];
}

export interface Units {
  unit: SingleUnit;
  linkedUnit: SingleUnit[];
}

export interface SingleUnit {
  code: string;
  riskCode: string;
  guaranteeCode: string;
  description: string;
  selected: boolean;
  enable: boolean;
  startStatusType: number;
  startStatusDescr: string;
  linkedUnitRiskcode: string;
  sectionCode: string;
  unitType: string;
  unitTypeDescr: string;
  listVariable: Factor[];
  commissions: Commission;
  clauses: GPClause[];
}

export interface Commission {
  idRisk: string;
  percCollNet?: CommissionProp;
  percentageCollDiritti?: CommissionProp;
  percentageCollInteressiFrazionamento?: CommissionProp;
  percentageCollInteressiMora?: CommissionProp;
  percentageCollInteressiPrestito?: CommissionProp;
  percentageCollInteressiRiattivazione?: CommissionProp;
  percentageCollPremioNetto?: CommissionProp;
  percentageCollSovrappremioAltro?: CommissionProp;
  percentageCollSovrappremioProfessionale?: CommissionProp;
  percentageCollSovrappremioSanitario?: CommissionProp;
  percentageCollSovrappremioSportivo?: CommissionProp;
  percentageCommissioniGestione?: CommissionProp;
  percentageCostiFissi?: CommissionProp;
  percentagePurchaseDiritti?: CommissionProp;
  percentagePurchaseInteressiFrazionamento?: CommissionProp;
  percentagePurchaseInteressiMora?: CommissionProp;
  percentagePurchaseInteressiPrestito?: CommissionProp;
  percentagePurchaseInteressiRiattivazione?: CommissionProp;
  percentagePurchasePremioNetto?: CommissionProp;
  percentagePurchaseSovrappremioAltro?: CommissionProp;
  percentagePurchaseSovrappremioProfessionale?: CommissionProp;
  percentagePurchaseSovrappremioSanitario?: CommissionProp;
  percentagePurchaseSovrappremioSportivo?: CommissionProp;
  percFlex?: CommissionProp;
  percProvvAcqSoprappCatastrofale?: CommissionProp;
  percProvvIncSoprappCatastrofale?: CommissionProp;
  percPurchaseNet?: CommissionProp;
  riskDescr: string;
}

export interface CommissionProp {
  label: string;
  editable: true;
  value: number;
}

export interface Surveys {
  code: string;
  description: string;
  category: {
    code: string;
    description: string;
  };
  mandatory: boolean;
}

export interface LinkedPolicyQuotation {
  summaryQuotation: SummaryQuotation;
  quotationDetail: QuotationDetail;
  quotationGuaranteeDetails: QuotationGuaranteeDetails;
}

export interface SummaryQuotation {
  firstInstallment: number;
  annualPremium: number;
}

export interface QuotationDetail {
  firstInstallment: QuotationSubDetail;
  nextInstallment: QuotationSubDetail;
  annualPremium: QuotationSubDetail;
}

export interface QuotationSubDetail {
  tax: number;
  right: number;
  intPaymentFreq: number;
  net: number;
  gross: number;
}

export interface QuotationGuaranteeDetails {
  firstInstallment: QuotationGuaranteeSubDetails;
  nextInstallment: QuotationGuaranteeSubDetails;
  annualPremium: QuotationGuaranteeSubDetails;
}

export interface QuotationGuaranteeSubDetails {
  quotationGuarantees: QuotationGuarantees[];
  total: QuotationGuarantees;
}

export interface QuotationGuarantees {
  riskCode: string;
  riskDesc: string;
  loading: number;
  overpremium: number;
  net: number;
  intPaymentFreq: number;
  tax: number;
  right: number;
  gross: number;
}

export interface GpUnitClause {
  codeUnit: string;
  codeSection: string;
  unitClauses: GPClause[];
}

export interface GpAssetUnitsClauses {
  codeAsset: string;
  assetClauses?: GPClause[];
  unitsClauses?: GpUnitClause[];
}

export enum SummaryQuotationTranslations {
  riskDesc = '_GP_._QUOTATION_._GUARANTEE_',
  purePremium = '_GP_._QUOTATION_._PURE_PREMIUM_',
  loading = '_GP_._QUOTATION_._LOADING',
  overpremium = '_GP_._QUOTATION_._OVER_PREMIUM_',
  net = '_GP_._QUOTATION_._NET_',
  intPaymentFreq = '_GP_._QUOTATION_._INIT_PAYMENT_FREQ_',
  tax = '_GP_._QUOTATION_._TAX_',
  right = '_GP_._QUOTATION_._RIGHT_',
  gross = '_GP_._QUOTATION_._GROSS_',
  total = '_GP_._QUOTATION_._TOTAL_',
  firstInstallment = '_GP_._QUOTATION_._FIRST_INSTALLMENT_',
  nextInstallment = '_GP_._QUOTATION_._NEXT_INSTALLMENT_',
  annualPremium = '_GP_._QUOTATION_._ANNUAL_PREMIUM',
  quotationDetail = '_GP_._QUOTATION_._DETAIL_',
  quotationGuaranteeDetails = '_GP_._QUOTATION_._GUARANTEE_DETAILS_'
}

<div class="modal-content">
  <div class="">
    <div class="modal-header">
      <h3 class="modal-title"><span>{{ "RE_ISSUE.SYSTEM_MESSAGE" | translate | uppercase }}</span></h3>
    </div>
    <div class="modal-body">
            <span *ngIf='!hideBasicMessage' class="">
                <span translate>RE_ISSUE.AN_ERROR_OCCURRED</span>
                <span *ngIf="executionId"><br/>ExecutionId: {{executionId}}</span>
            </span>
      <span *ngIf="errorMessage"><br/>{{errorMessage}}</span>
      <span *ngIf="errorType==='unauthorized_error'">Sessione scaduta.</span>
      <span *ngIf="errorType==='authorization_error'">Non si possiede
                l'autorizzazione per accedere al servizio</span>
      <span *ngIf="errorType==='errore_generico'" translate="" class="ng-hide">Impossibile concludere
                l'operazione</span>
    </div>
    <div class="modal-footer">
      <div class="btn-group btn-group-justified">
        <!---->
        <div class="btn-group" *ngIf="!confirmAlert">
          <button class="btn btn-warning" (click)="close()">{{'Close'| translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

import {Beneficiary} from './beneficiary';

export class Beneficiaries {
  assetKey: string;
  section: string;
  unit: string;
  unitCode: string;
  packetCode: string;
  beneficiary: Beneficiary[];
}

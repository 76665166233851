import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { PV_TOKEN, STEP } from '../../models/consts/lpc-consts';
import { PostsalesError } from '../../models/postsales-operations-response.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-cancellation-operations-component',
  templateUrl: './cancellation-operations-component.html',
  styleUrls: ['./cancellation-operations-component.scss']
})
export class CancellationOperationsComponent extends AbsOperationComponent implements OnInit {

  protected operationDataKey = 'null';

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(
      operations, cd, translate, injector,
      questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe()
    );
  }

  protected getFormGroup() {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

  mapError(error: PostsalesError) {
    if (error.context === STEP.CONCLUDE.id) {
      error.context = STEP.FLOW.id;
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Instalment} from '../../../../models/domain-models/instalment';
import {PremiumDetail} from '../../../../models/domain-models/premium-detail';
import {OnModalClose} from '@rgi/rx/ui';
import {Currency} from '../../../../models/domain-models/parameters/currency';


@Component({
  selector: 'lib-re-issue-quotation-detail-modal',
  templateUrl: './re-issue-quotation-detail-modal.component.html',
  styleUrls: ['./re-issue-quotation-detail-modal.component.css']
})
export class ReIssueQuotationDetailModalComponent implements OnInit, OnModalClose {

  premiumDetailSections: Array<PremiumDetail> = new Array<PremiumDetail>();
  @Output() modalClose = new EventEmitter();
  @Input() premiumInstallment: Instalment;
  @Input() annualPremium: Instalment;
  @Input() premiumSubscriptionInstalment: Instalment;
  @Input() currency: Currency;


  ngOnInit() {
    this.initializePremiumDetailSections();
  }

  private initializePremiumDetailSections() {
    this.premiumDetailSections.push(
      new PremiumDetail('SUBSCRIPTION_INST_PREMIUM',
        (this.premiumSubscriptionInstalment.gross) ? this.premiumSubscriptionInstalment.gross : '0',
        (this.premiumSubscriptionInstalment.net) ? this.premiumSubscriptionInstalment.net : '0',
        (this.premiumSubscriptionInstalment.accessories) ? this.premiumSubscriptionInstalment.accessories : '0',
        (this.premiumSubscriptionInstalment.fractioningInterest)
          ? this.premiumSubscriptionInstalment.fractioningInterest : '0',
        (this.premiumSubscriptionInstalment.taxable) ? this.premiumSubscriptionInstalment.taxable : '0',
        (this.premiumSubscriptionInstalment.taxes) ? this.premiumSubscriptionInstalment.taxes : '0',
        (this.premiumSubscriptionInstalment.sSN) ? this.premiumSubscriptionInstalment.sSN : '0',
        (this.premiumSubscriptionInstalment.flexDiscounts) ? this.premiumSubscriptionInstalment.flexDiscounts : null)
    );
    this.premiumDetailSections.push(
      new PremiumDetail('PREMIUM_RATE', (this.premiumInstallment.gross) ? this.premiumInstallment.gross : '0',
        (this.premiumInstallment.net) ? this.premiumInstallment.net : '0',
        (this.premiumInstallment.accessories) ? this.premiumInstallment.accessories : '0',
        (this.premiumInstallment.fractioningInterest) ? this.premiumInstallment.fractioningInterest : '0',
        (this.premiumInstallment.taxable) ? this.premiumInstallment.taxable : '0',
        (this.premiumInstallment.taxes) ? this.premiumInstallment.taxes : '0',
        (this.premiumInstallment.sSN) ? this.premiumInstallment.sSN : '0',
        (this.premiumInstallment.flexDiscounts) ? this.premiumInstallment.flexDiscounts : null)
    );
    this.premiumDetailSections.push(
      new PremiumDetail('ANNUAL_PREMIUM', (this.annualPremium.gross) ? this.annualPremium.gross : '0',
        (this.annualPremium.net) ? this.annualPremium.net : '0',
        (this.annualPremium.accessories) ? this.annualPremium.accessories : '0',
        (this.annualPremium.fractioningInterest) ? this.annualPremium.fractioningInterest : '0',
        (this.annualPremium.taxable) ? this.annualPremium.taxable : '0',
        (this.annualPremium.taxes) ? this.annualPremium.taxes : '0',
        (this.annualPremium.sSN) ? this.annualPremium.sSN : '0',
        (this.annualPremium.flexDiscounts) ? this.annualPremium.flexDiscounts : null)
    );
  }

  close() {
    this.modalClose.emit();
  }
}

import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter, HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';


let nextUniqueId = 0;
@Component({
  selector: 'pnc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class ButtonComponent {
  @Input() disabled: boolean;
  @Output() clickEvent = new EventEmitter();
  classes = {
    'pnc-btn': true,
    primary: false,
    secondary: false,
    icon: false,
  };
  id: string;

  @HostBinding ('class.btn-disabled') btnDisabled = () => this.disabled;

  constructor(@Attribute('type') public type: string = 'primary') {
    this.classes[type] = true;
    this.id = `button-${++nextUniqueId}`;
    console.warn('pnc-button is deprecated. use a plain button');
  }



  public onClick(event: Event) {
    event.stopPropagation();
    if (!this.disabled) {
      this.clickEvent.emit();
    }
  }
}

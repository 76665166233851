import {LpcErrorMessagesModule} from '../lpc-error-messages/lpc-error-messages.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatesStepComponent} from './dates-step.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {LpcNavigationButtonsModule} from '../lpc-navigation-buttons/lpc-navigation-buttons.module';
import {ReactiveFormsModule} from '@angular/forms';
import {LpcDatepickerModule} from '../lpc-datepicker/lpc-datepicker.module';
import { LpcDateStepperModule } from '../lpc-date-stepper/lpc-date-stepper.module';
import { TooltipModule } from '../../utils/directives/tooltip.module';


@NgModule({
    declarations: [DatesStepComponent],
    imports: [
        CommonModule,
        LpcErrorMessagesModule,
        PortalNgCoreModule,
        LpcNavigationButtonsModule,
        ReactiveFormsModule,
        LpcDatepickerModule,
        LpcDateStepperModule,
        TooltipModule
    ],
    exports: [
        DatesStepComponent
    ]
})
export class LpcDatesStepModule { }

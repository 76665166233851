<form [formGroup]="generalDataForm">
  <div class="rgi-ui-grid-2">
    <div class="rgi-ui-col">
      <rgi-rx-drop-container formControlName="policyholder"
                             label="{{'RE_ISSUE.POLICYHOLDER' | translate}}" viewField="nominative"
                             data-qa="subject" [select]="'searchOrCreateSubject'"
                             [selectData]="getSubjectDropContainerData(roleKeys.rolePolicyHolder,true)"
                             (onValueChange)="anagSubjectSelected($event)"
                             (onRemove)="removeSubjectFormData()"></rgi-rx-drop-container>
    </div>
    <div class="rgi-ui-col" [hidden]="!coOwnerEnabled">
      <rgi-rx-drop-list-container formControlName="coOwners"
                                  label="{{'RE_ISSUE.CO_OWNERS' | translate}}"
                                  data-qa="co-owners"
                                  viewField="nominative"
                                  [select]="'searchOrCreateSubject'"
                                  [selectData]="getSubjectDropContainerData(roleKeys.coOwner,false)"
                                  (onValueChange)="anagCoOwnerSelected($event)"
                                  (onRemove)="onRemove($event)"
                                  [sortable]="false" class="rgi-ui-drop-container rgi-ui-drop test">
      </rgi-rx-drop-list-container>
    </div>
  </div>
  <div class="form-group rgi-ui-grid-2">
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="fiscalCode" translate>RE_ISSUE.FISCAL_CODE_VAT_NUMBER</label>
        <input rgiRxInput id="fiscalCode" formControlName="fiscalCode" data-qa="fiscalCode">
      </rgi-rx-form-field>
    </div>

    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="residence" translate>RE_ISSUE.RESIDENCE_ADDRESS</label>
        <input rgiRxInput id="residence" formControlName="residence" data-qa="residence">
      </rgi-rx-form-field>
    </div>
  </div>
  <div class="rgi-re-section-title">
    <span class="rgi-ui-icon-marker rgi-re-header-icon"></span>
    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.CONTACTS</span>&nbsp;
  </div>
  <div class="rgi-ui-grid-2">
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="email">Email</label>
        <input rgiRxInput id="email" (blur)="onEmailChange()" formControlName="email" data-qa="email">
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="mobilePhone" translate>RE_ISSUE.MOBILE_PHONE</label>
        <input rgiRxInput id="mobilePhone" (blur)="onMobilePhoneChange()" formControlName="mobilePhone" data-qa="mobilePhone">
      </rgi-rx-form-field>
    </div>
  </div>
</form>

import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { switchMap, tap } from 'rxjs/operators';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import { FactorDefinition, PaymentTypeDefinition } from '../../models/postsales-operations-response.model';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { Premium } from '../../modules/lpc-premium/model/premium.model';
import { Quotation } from '../../modules/lpc-quotation/model/quotation.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-partial-early-repayment',
  templateUrl: './partial-early-repayment.component.html',
  styleUrls: ['./partial-early-repayment.component.scss']
})
export class PartialEarlyRepaymentComponent extends AbsOperationComponent
  implements OnInit {

  public get hasProductFactors(): boolean {
    return !!this.listProductFactor.length;
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  private $paymentTypes: PaymentTypeDefinition[] = [];
  protected operationDataKey = 'partial-early-repurchase';
  public stepPaymentFieldId = 'payment';
  public listProductFactor: FactorDefinition[] = [];

  public quotationData: Quotation;
  public damageQuotationData: Quotation;

  public listPremiumAmount: Premium[] = [];
  public damageListPremiumAmount: Premium[] = [];
  public showCreditPayment: boolean;

  requestFactor: RequestFactor[] = [];

  @ViewChild('factorAdapter') child: LpcFactorAdapterComponent;

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      operationRoles: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      notes: new UntypedFormControl(), // Text-area note
      payment: new UntypedFormControl()
    });
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {
          this.createDraftResponse(result);
        })
      ).subscribe()
    );
  }

  createDraftResponse(result) {
    this.formGroup.get('payment').setValue(result.data.operationData.data.creditPayment, {emitEvent: false});
  }

  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

  onNext(step, publish = false,  context = null, isConfirmAndAccept = false) {
    const beStep: string = step; // === 'dates' ? 'factors' : step;
    if (step === 'factors') {
      this.requestFactor = this.child.getRequestFactor();
    }
    if (this.isFormValid(this.formGroup.get(step))) {
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, step, this).pipe(
        tap(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
          if (!!this.avcQuestionnaire) {
            this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(this.avcQuestionnaire);
          }
        }),
        switchMap(() => {
          return this.updateDraft(beStep);
        })
      ).subscribe(result => {

          this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
          this.quotationData = result.data.operationData.data.quotationData;
          this.damageQuotationData = result.data.operationData.data.damageQuotationData;
          this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
          if (this.quotationData != null) {
            this.listPremiumAmount = this.quotationData.listPremiumAmount;
          }
          if (result.data.operationData.data.damageQuotationData) {
            this.damageListPremiumAmount = result.data.operationData.data.damageQuotationData.listPremiumAmount;
          }

          this.detectChanges();
          if (!this.hasBlockingErrorsOnSteps(step)) {
            this.doPublishOrNext(publish, isConfirmAndAccept);
          }

          if (result.definitions.paymentTypes != null) {
            this.showCreditPayment = true;
          } else {
            this.showCreditPayment = false;
          }

        })
      );
    } else {
      this.setFeErrors(step);
    }
  }

  updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe(result => {
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    }));
  }

  protected getTransformedOperationData(): any {
    const obj: any = {
      listProductFactor: !!this.requestFactor && this.requestFactor.length > 0 ? this.requestFactor : [],
      creditPayment: !!this.formGroupValue[this.stepPaymentFieldId] ? this.formGroupValue[this.stepPaymentFieldId] : {}
    };

    return obj;
  }
}

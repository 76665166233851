import { Injectable, ViewContainerRef, ComponentFactoryResolver, Type, EventEmitter, ComponentRef } from '@angular/core';
import { Modal } from './modal/modal';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class CustomModalService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  openModal(
    viewContainerRef: ViewContainerRef,
    modalComponent: Type<Modal>,
    eventPropagation?: EventEmitter<any>,
    onDestroyFunction?: () => void) {

    let componentRef: ComponentRef<any>;
    viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalComponent);
    componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.componentRef = componentRef;
    const eventPropagationSuscription = new Subscription();

    if (eventPropagation) {
      eventPropagationSuscription.add(componentRef.instance.eventPropagation.subscribe((event) => {
        eventPropagation.emit(event);
      }));
    }

    function onDestroyComponentRef() {
      eventPropagationSuscription.unsubscribe();
      if (onDestroyFunction) {
        onDestroyFunction();
      }
    }

    componentRef.onDestroy(onDestroyComponentRef);

    return componentRef;
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Note } from '../../objects/note';

@Component({
  selector: 'claims-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss']
})
export class DayComponent implements OnInit {

  @Input() notes: Note[];
  @Output() rechargeEventEmitter = new EventEmitter<boolean>();
  alignment: string;
  day: string;

  constructor() { }

  ngOnInit() {
    this.alignment = this.getAlignment();
    this.day = this.notes[0].data.date.toLocaleDateString();
  }

  private getAlignment() {
    if (!this.notes[0].data.hasLeftColumn) {
      return 'left';
    } else if (!this.notes[0].data.hasRightColumn) {
      return 'right';
    }
    return '';
  }

  recharge(event: any) {
    this.rechargeEventEmitter.emit(event);
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { ConfigurationService } from '../services/configuration.service';
import {RgiRxTokenProvider} from '@rgi/rx/auth';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
  configurationService: any;
  constructor(configurationService: ConfigurationService, private tokenProvider: RgiRxTokenProvider) {
    this.configurationService = configurationService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      setHeaders: {
        RGI_token: this.tokenProvider.token
      }
    });
    return next.handle(newReq);
  }

}

import { Input, Component, OnInit, ComponentRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GenericEntity } from '../../generic-entity';
import { DocumentsService } from '../documents.service';
import { Documents } from '../documents';
import { LoaderService } from '@rgi/portal-ng-core';

@Component({
  selector: 'pcm-document-add-modal',
  templateUrl: './document-add-modal.component.html',
  styleUrls: ['./document-add-modal.component.css']
})
export class DocumentAddModalComponent implements OnInit {

  @Input() title: string;
  @Input() contractId: string;
  @Input() documents: Documents;
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventModifiedDocument: EventEmitter<boolean> = new EventEmitter<boolean>();

  componentRef: ComponentRef<any>;
  addDocumentForm: UntypedFormGroup;
  arDocumentType: Array<GenericEntity>;


  constructor(
    private documentsService: DocumentsService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder
  ) { }


  ngOnInit() {
    this.loaderService.startLoader(this.eventPropagation, true);
    this.title = this.componentRef.instance.title;
    this.contractId = this.componentRef.instance.contractId;
    this.documents = this.componentRef.instance.documents;
    this.addDocumentForm = this.formBuilder.group({
      documentType: [false, Validators.required]
    });

    this.documentsService.getDocumentType(this.contractId).subscribe((documentTypes: Array<GenericEntity>) => {
      this.arDocumentType = documentTypes;
    },
      () => {
        console.log('La chiamata è andata in errore');
      },
      () => {
        this.loaderService.stopLoader(this.eventPropagation, true);
      });
  }

  close() {
    this.componentRef.destroy();
  }

  confirmAddDocument() {
    this.loaderService.startLoader(this.eventPropagation, true);
    const documentTypeIn = this.addDocumentForm.controls.documentType.value;
    const documentType = this.arDocumentType.find((docType: GenericEntity) => {
      return docType.code === documentTypeIn;
    });
    this.documentsService.addDocument(this.contractId, documentType).subscribe((documents: Documents) => {
      this.documents = documents;
      this.documentsService.setDocumentsSignal(documents);
    },
      () => {
        console.log('La chiamata è andata in errore');
      },
      () => {
        this.eventModifiedDocument.emit(true);
        this.loaderService.stopLoader(this.eventPropagation, true);
        this.close();
      });
  }

}

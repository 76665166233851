<div *ngIf="data.loanDataDetail" data-qa="life-loan-detail">

    <div class="row">
        <div class="col-lg-12">
            <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                            <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos"
                                style="cursor: pointer;" (click)="back()" data-qa="back-arrow"></span>
                            <span class="separator"> | </span>
                            <span class="menu-title" translate>lpc_loans</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="data.loanDataDetail" class="col-md-12 life-detail-margin-box life-detail-box"
        id="consulting-detail">
        <div class="tbld life-detail-margin-top">
            <div class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_issueDate</div>
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_effectiveDate</div>
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_payment_frequency</div>
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_loanAmount</div>
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_loanRepaymentProcedure</div>
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_rateType</div>
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_loan_interest</div>
                <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_REMAINING_AMOUNT</div>
            </div>
            <div *ngFor="let loan of data.loanDataDetail"
                class="tbld_row life-detail-tbld_row_header">
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.issueDate | date:'dd/MM/yyyy'}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.effectiveDate | date:'dd/MM/yyyy'}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.paymentFrequencyType}}</div>
                <ng-template [ngIf]="!!loan.loanAmount" [ngIfElse]="no_data">
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.loanAmount | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</div>
                </ng-template>
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.loanRepaymentProcedure}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.rateType}}</div>
                <ng-template [ngIf]="!!loan.interestAmount" [ngIfElse]="no_data">
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.interestAmount | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</div>
                </ng-template>
                <ng-template [ngIf]="!!loan.remainingAmount" [ngIfElse]="no_data">
                <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{loan.remainingAmount | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</div>
                </ng-template>
            </div>
        </div>
    </div>

    <ng-template #no_data>
        <div class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">-</div>
      </ng-template>

</div>



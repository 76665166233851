import { Injectable, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PaymentFieldDefinition } from '../../../models/postsales-operations-response.model';
import { LpcPaymentDatepickerComponent } from '../lpc-payment-datepicker/lpc-payment-datepicker.component';
import { LpcPaymentInputComponent } from '../lpc-payment-input/lpc-payment-input.component';

@Injectable({
  providedIn: 'root'
})
export class LpcPaymentWrapperService {

constructor() { }

  // eslint-disable-next-line max-len
  public createLpcPaymentFields(resolver: ComponentFactoryResolver, ref: ComponentRef<any>, view: ViewContainerRef, group: UntypedFormGroup, config: PaymentFieldDefinition, editable: boolean) {
    let outputPayment: any;
    switch (config.type) {
      case 'N':
          outputPayment = resolver.resolveComponentFactory(LpcPaymentInputComponent);
          ref = view.createComponent(outputPayment);
          const inputPaymentN: ComponentRef<LpcPaymentInputComponent> = ref as ComponentRef<LpcPaymentInputComponent>;
          inputPaymentN.instance.setPayment(config, group, editable);
          break;
      case 'R':
        outputPayment = resolver.resolveComponentFactory(LpcPaymentInputComponent);
        ref = view.createComponent(outputPayment);
        const inputPaymentR: ComponentRef<LpcPaymentInputComponent> = ref as ComponentRef<LpcPaymentInputComponent>;
        inputPaymentR.instance.setPayment(config, group, editable);
        break;
      case 'F':
        outputPayment = resolver.resolveComponentFactory(LpcPaymentInputComponent);
        ref = view.createComponent(outputPayment);
        const inputPayment: ComponentRef<LpcPaymentInputComponent> = ref as ComponentRef<LpcPaymentInputComponent>;
        inputPayment.instance.setPayment(config, group, editable);
        break;
      case 'D':
        outputPayment = resolver.resolveComponentFactory(LpcPaymentDatepickerComponent);
        ref = view.createComponent(outputPayment);
        const datePayment: ComponentRef<LpcPaymentDatepickerComponent> = ref as ComponentRef<LpcPaymentDatepickerComponent>;
        datePayment.instance.setPayment(config, group, editable);
        break;
    }
  }
}

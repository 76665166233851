import {RGI_RX_I18N_CONFIG, RGI_RX_LOCALE, RgiRx18nConfig, RgiRxTranslationService} from '@rgi/rx/i18n';
import {Inject, Injectable, InjectionToken, OnDestroy, Optional} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';

export const RgiCountryLayer1To21Digits =
  [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21] as const;
export const RgiCountryLayer0To20Digits = RgiCountryLayer1To21Digits.map(value => value - 1);


export type RgiCountryLayerSignificantDigits = typeof RgiCountryLayer1To21Digits[number];
export type RgiCountryLayerFractionDigits = typeof RgiCountryLayer0To20Digits[number];

export interface RgiCountryLayerContextState {
  locale: RGI_RX_LOCALE;
}

export enum RgiRxCountryLayerNumberPrecision {
  AUTO,
  EXPERIMENTAL
}


export interface RgiCountryLayerConfig {
  /**
   * @description sync the current context to the `@rgi/rx/i18n` current language.
   */
  syncWithI18n: boolean;
  /**
   * @description set a default locale. By default use `@rgi/rx/i18n` default locale even when syncWithI18n is false.
   */
  defaultLocale?: RGI_RX_LOCALE;
}

export const RGI_COUNTRY_LAYER_CONFIG = new InjectionToken<RgiCountryLayerConfig>('RGI_COUNTRY_LAYER_CONFIG');


@Injectable({
  providedIn: 'root'
})
/**
 * @description RgiCountryLayerContext service provide the global context of the current Country. Features from the library may use this provider
 * to define the country context or to react when the context changes.
 */
export class RgiCountryLayerContext implements OnDestroy {

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _context: BehaviorSubject<RgiCountryLayerContextState>;
  private languageSubscription = Subscription.EMPTY;

  constructor(@Inject(RGI_COUNTRY_LAYER_CONFIG) private countryLayerConfig: RgiCountryLayerConfig, private rgiRxTranslationService: RgiRxTranslationService) {
    this._context = new BehaviorSubject<RgiCountryLayerContextState>({
      locale: countryLayerConfig.defaultLocale ? countryLayerConfig.defaultLocale : rgiRxTranslationService.currentLanguage
    });


    if (countryLayerConfig.syncWithI18n) {
      this.languageSubscription = rgiRxTranslationService.getLanguageChange$().subscribe(
        next => {
          this._context.next({
            ...this._context.value, locale: next
          });
        }
      );
    }
  }

  /**
   * @description The the current context
   */
  get context$(): Observable<RgiCountryLayerContextState> {
    return this._context.asObservable().pipe(
      skip(1)
    );
  }

  /**
   * @description Change the current context locale
   * @param locale the RGI_RX_LOCALE to set
   */
  set locale(locale: RGI_RX_LOCALE) {
    if (this.countryLayerConfig.syncWithI18n) {
      this.rgiRxTranslationService.setCurrentLanguage(locale);
    } else {
      this._context.next({
        ...this._context.value, locale
      });
    }
  }

  /**
   * @description Get the current locale in sync. Pay attention that this might return a different locale if the context is changing
   * underneath. Always use context$ for a correct context read. You can use the locale for initializers that may be updated when the context
   * changes.
   */
  get locale(): RGI_RX_LOCALE {
    return this._context.value.locale;
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }
}


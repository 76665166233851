import {RgiRxSnackbarOverlayConfig} from './rgi-rx-snackbar-api';

export const RGI_RX_SNACKBAR_DEFAULT_OVERLAY_CONFIG: RgiRxSnackbarOverlayConfig = {
  positionStrategy: {
    centerHorizontally: true,
    right: '30px',
    bottom: '20px'
  },
  options: {
    disposeOnNavigation: false,
    backDrop: false,
    minWidth: '300px',
    maxWidth: '300px'
  }
};

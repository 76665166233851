import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {GzipActivationStrategy, RGI_RX_HTTP_CONFIG, RgiRxHttpConfig} from '../http-api';
import {HttpRequest} from '@angular/common/http';

@Injectable()
export class DefaultGzipActivationStrategy extends GzipActivationStrategy {


  constructor(@Inject(DOCUMENT) private document: any, @Inject(RGI_RX_HTTP_CONFIG) private config: RgiRxHttpConfig) {
    super();
  }

  shouldBeActivated(request: HttpRequest<any>): boolean {
    return this.config.gzip && this.document && !(this.document.location.hostname === 'localhost' || this.document.location.hostname === '127.0.0.1' || request.params.has('disableCompression'));
  }
}

export type ConfiguredAmount =
  'NET'
  | 'NET_INTERESTS'
  | 'CHARGES'
  | 'CHARGES_INTERESTS'
  | 'TAXABLE'
  | 'TOTAL_TAXES'
  | 'GROSS'
  | 'COLLECTED_COMMISSIONS'
  | 'PURCHASED_COMMISSIONS';
export const SUPPL_TECH_ACC_ENUM: { [key: string]: ConfiguredAmount } = {
  NET: 'NET',
  NET_INTERESTS: 'NET_INTERESTS',
  CHARGES: 'CHARGES',
  CHARGES_INTERESTS: 'CHARGES_INTERESTS',
  TAXABLE: 'TAXABLE',
  TOTAL_TAXES: 'TOTAL_TAXES',
  GROSS: 'GROSS',
  COLLECTED_COMMISSIONS: 'COLLECTED_COMMISSIONS',
  PURCHASED_COMMISSIONS: 'PURCHASED_COMMISSIONS'
};

export const SUPPL_TECH_ACC_MODEL_ENUM = {
  NET: 'net',
  NET_INTERESTS: 'netInterests',
  CHARGES: 'charges',
  CHARGES_INTERESTS: 'chargesInterests',
  TAXABLE: 'taxable',
  TOTAL_TAXES: 'totalTaxes',
  GROSS: 'gross',
  COLLECTED_COMMISSIONS: 'collectedCommissions',
  PURCHASED_COMMISSIONS: 'purchasedCommissions',
  INSURED_RISKS: 'insuredRisks',
  NOT_INSURED_RISKS: 'notInsuredRisks',
  RISK_DESCR: 'riskDescr'
};

export const EDITABLE_SUPPL_TECH_ACC_AMOUNTS = [
  SUPPL_TECH_ACC_ENUM.NET,
  SUPPL_TECH_ACC_ENUM.NET_INTERESTS,
  SUPPL_TECH_ACC_ENUM.CHARGES,
  SUPPL_TECH_ACC_ENUM.CHARGES_INTERESTS,
  SUPPL_TECH_ACC_ENUM.COLLECTED_COMMISSIONS,
  SUPPL_TECH_ACC_ENUM.PURCHASED_COMMISSIONS
];

export const SUPPLEMENTARY_TECH_ACC_CONFIGURED_FIELDS = new Map<string, ConfiguredAmount>([
  [SUPPL_TECH_ACC_MODEL_ENUM.NET, SUPPL_TECH_ACC_ENUM.NET],
  [SUPPL_TECH_ACC_MODEL_ENUM.NET_INTERESTS, SUPPL_TECH_ACC_ENUM.NET_INTERESTS],
  [SUPPL_TECH_ACC_MODEL_ENUM.CHARGES, SUPPL_TECH_ACC_ENUM.CHARGES],
  [SUPPL_TECH_ACC_MODEL_ENUM.CHARGES_INTERESTS, SUPPL_TECH_ACC_ENUM.CHARGES_INTERESTS],
  [SUPPL_TECH_ACC_MODEL_ENUM.TAXABLE, SUPPL_TECH_ACC_ENUM.TAXABLE],
  [SUPPL_TECH_ACC_MODEL_ENUM.TOTAL_TAXES, SUPPL_TECH_ACC_ENUM.TOTAL_TAXES],
  [SUPPL_TECH_ACC_MODEL_ENUM.GROSS, SUPPL_TECH_ACC_ENUM.GROSS],
  [SUPPL_TECH_ACC_MODEL_ENUM.COLLECTED_COMMISSIONS, SUPPL_TECH_ACC_ENUM.COLLECTED_COMMISSIONS],
  [SUPPL_TECH_ACC_MODEL_ENUM.PURCHASED_COMMISSIONS, SUPPL_TECH_ACC_ENUM.PURCHASED_COMMISSIONS],
]);


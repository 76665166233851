<form [formGroup]="parentForm" >
  <div *ngFor="let f of fattori">
      <div *ngIf="f.applicationFactor !== false" class="col-lg-6 factor-box-content">
          <ng-container
          [group]="parentForm"
          [licFactorLoader]="f">
          </ng-container>
      </div>
  </div>
</form>

import { EnumType } from '@rgi/digital-claims-common-angular';

export class InvoiceDetails {

    public idDetails: number;
    public claimNumber: string; // NUMERO SINISTRO
    public totAmountUnit: number; // COSTO TOTALE UNITARIO
    public taxableAmount: number; // IMPORTO IMPONIBILE
    public notTaxableAmount: number; // IMPORTO NON IMPONIBILE
    public idAssignment: number;
    public idPayment: number;
    public status: EnumType;
    public rejectType: EnumType;
    public codeAssignment: string;
    public idTypeofloss: string;
    public idParty: string;

}

import {Component, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'rgi-rx-tab-group-target',
  template: `<ng-container #ref></ng-container>`
})
export class RgiRxTabGroupTargetComponent {
  @ViewChild('ref', {static: true, read: ViewContainerRef}) container: ViewContainerRef;
  constructor() {}
  get viewContainerRef(): ViewContainerRef {
    return this.container;
  }
}

import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[pncRoutingHost]'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class RoutingHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

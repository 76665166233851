import {InjectionToken} from "@angular/core";


export const PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS = {
  EVALUATION_OBJECT_KEY: "evaluationObject",
  EVALUATION_BRIDGE_KEY: 'IDD_SURVEY_BRIDGE_'
}




import { Component, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { RoutableComponent } from '@rgi/portal-ng-core';
import {RgiCountryLayerNumberFormatPipe} from '@rgi/country-layer';
import {SummaryComponent, PolicyService, LifeSessionService, CardsNavigationService, SystemPropertiesService, ActionsService, MasterPolicyService, AuthService} from '@rgi/life-issue-card';
import { TranslationWrapperService } from '@rgi/life-issue-card';
import { RgiRxPushMessageHandlerService } from '@rgi/rx';

@Component({
  templateUrl: './summary-ext.component.html',
  styleUrls: ['./summary-ext.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class SummaryExtComponent extends SummaryComponent implements OnInit, RoutableComponent, OnDestroy {

  constructor(
    protected policyService: PolicyService,
    protected lifeSessionService: LifeSessionService,
    protected cardsNavigationService: CardsNavigationService,
    protected systemPropertiesService: SystemPropertiesService,
    protected actionsService: ActionsService,
    protected translate: TranslationWrapperService,
    protected masterPolicyService: MasterPolicyService,
    @Optional() protected questCacheManager: QuestionnaireCacheService,
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected rgiFormatter: RgiCountryLayerNumberFormatPipe,
    protected pushMessageHandler: RgiRxPushMessageHandlerService
  ) { super(policyService, lifeSessionService, cardsNavigationService, systemPropertiesService, actionsService,
    translate, masterPolicyService, questCacheManager, authService, modalService, rgiFormatter, pushMessageHandler); }


  ngOnInit() {
    super.ngOnInit();
    this.generalDataMap = this.generateDynamicTable(this.dynamicTableGeneralData);
    this.rowsGeneralData = this.composeArray(Math.ceil(this.dynamicTableGeneralData.length / this.nCol));
  }

}

import {Inject, Injectable} from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';


@Injectable()
/**
 * @deprecated this is already provided by @rgi/rx/portal and should be removed
 */
export class LangInterceptor implements HttpInterceptor {
  constructor(@Inject('$injector') private injector: any) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const coreLang = this.injector.get('coreLang');
    const currentLang = coreLang.getLang();
    const clonedReq = req.clone({
      headers: req.headers.set('RGI_locale', currentLang)
    });
    return next.handle(clonedReq);
  }
}

import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'pnc-modal-basic',
  templateUrl: './modal-basic.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./modal-basic.component.scss']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class ModalBasicComponent implements OnInit {
  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  public visible = true;
  public visibleAnimate = true;

  constructor() {}

  ngOnInit() {

  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('modal')) {
      // this.hide();
    }
  }

}

import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { EvaluatorInfoI } from '../../../../evaluation/models/evaluator-info';
import { PPEVOPanelComponent } from '@rgi/ng-passpropro-core';

import {ModalComponent} from "@rgi/rx/ui";
import { EvalPkgPricing } from '../../../../state/state-manager-survey.module';

@Component({
  selector: 'survey-eval-info',
  templateUrl: './survey-eval-info.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SurveyEvalInfoComponent {

  info: EvaluatorInfoI;
  defaultShowDetails: boolean = false;
  defaultShowDetailsChild: boolean = false;
  expandDetails: boolean = this.defaultShowDetails;
  pkgPricing?:EvalPkgPricing;

  @ViewChild('infoModal') infoModal: ModalComponent;

  constructor() {

  }


  open() {
    this.expandDetails = this.defaultShowDetails;
    setTimeout(() => {
      this.infoModal.open();
    })

  }

  canShowMessages():boolean{
    return !!(
        (this.info.messages && this.info.messages.length)
        ||
        (this.info.pricing && this.info.pricing.messages && this.info.pricing.messages.length)
    );
  }

  canShowDetails():boolean{
    const info:EvaluatorInfoI = this.info;
    return !!(
      (info.targetmarket && info.targetmarket.length)
      ||
      (info.notargetmarket && info.notargetmarket.length)
      ||
      info.pricing
    );
  }

}

import {
  Component, OnDestroy, OnInit, ViewChild,
  Output, EventEmitter, Input, Inject
} from '@angular/core';
import { AuthorizationsCardHostDirective } from '../authorizations-card-host.directive';
import {AuthorizationsRoutingService} from '../routing/authorizations-routing.service';
import {AuthorizationsSearchService} from '../services/authorizations-search.service';
import {ActiveRoute, RgiRxRouter} from '@rgi/rx/router';


@Component({
  selector: 'ac-base',
  templateUrl: './authorizations-card.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class AuthorizationsCardComponent implements OnInit, OnDestroy {
  @Output() eventPropagation = new EventEmitter<string>();

  @ViewChild(AuthorizationsCardHostDirective, { static: true }) acHost: AuthorizationsCardHostDirective;

  private naviPosition = 'start';

  constructor(
      protected routingService: RgiRxRouter,
      protected authorizationsRoutingService: AuthorizationsRoutingService,
      protected authorizationsSearchService: AuthorizationsSearchService,
      public activeRoute: ActiveRoute,
      @Inject('authorizationsStartComponent') startComponent?,
      @Inject('authorizationsListComponent') authorizationsListComponent?,
      @Inject('authorizationsDetailComponent') authorizationsDetailComponent?,
    ) {}

  ngOnInit() {

    if (this.authorizationsRoutingService.getNaviPosition()) {
      this.naviPosition = this.authorizationsRoutingService.getNaviPosition();
      this.authorizationsRoutingService.setNaviPosition(null,this.activeRoute.id);
    }
    this.load(this.naviPosition);
  }

  load(position) {
    this.naviPosition = position;
    this.routingService.navigate(position, {}, this.activeRoute.id);
  }

  @Input()
  set navi(navi) {
    if (navi) {
      this.authorizationsRoutingService.setNaviPosition(navi,this.activeRoute.id);
      this.naviPosition = navi;
    }
  }

  @Input()
  set routesOverride(routes) {

  }

  @Input()
  set selectedSubject(party) {
    if (party) {
      this.authorizationsSearchService.setParty(party);
    }
  }

  @Input()
  set advancedSearch(advancedSearch) {
    if (advancedSearch) {
      this.authorizationsSearchService.setAdvancedSearch(advancedSearch);
    }
  }

  ngOnDestroy() {

  }

}

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { KarmaFundDefinition } from '../../model/karma-fund-definition';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../../services/currency-cache.service';

@Component({
  selector: 'lpc-karma-fund-summary',
  templateUrl: './lpc-karma-fund-summary.component.html',
  styleUrls: ['./lpc-karma-fund-summary.component.css']
})
export class LpcKarmaFundSummaryComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  @Input() funds: any[];
  @Input() totalAmount: number = null;

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
  }

  getPercentage(fund: KarmaFundDefinition): number {
    return !!fund.percent ? Number(fund.percent) : !!fund.percentage ? Number(fund.percentage) : 0;
  }

  getAmount(fund: KarmaFundDefinition): number {
    const percentage = this.getPercentage(fund);
    return !!Number(percentage) && !!Number(this.totalAmount) ? Number(percentage) * Number(this.totalAmount) : 0;
  }

}

import { ExtraInfo } from './external-policy-extrainfo';
import { ExternalPolicyWarrenty } from './external-policy-warrenty';

export class ExternalPolicyAsset {
 public type: string;
 public description: string;
 public effectDate: Date;
 public expirationDate: Date;
 public value: number;
 public licensePlate: string;
 public vin: string; // telaio
 public brand: string;
 public registrationDate: Date;
 public warrentyList: ExternalPolicyWarrenty[];
 public extraInfoList: ExtraInfo[];
 public codTerm: string;

 constructor() { }
}

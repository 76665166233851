import { Serialized18n } from '@rgi/rx/i18n';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const IIAB_LIFE_PPEVO_IT: Serialized18n = {
  /* "Il Contraente è una persona": 'The holder is',
  "Data di nascita": 'Birth date',
  "Tax residence (country)": "Residenza fiscale (paese)",
  "Switzerland":  "Svizzeraaaaa",
  "Martial Arts": "Arti marziali",
  "What are the risks?": "Quali sono i rischi?",
  "For what period of time do you\nintend to save?": "Per quale periodo di tempo intendi risparmiare?",
  "Less than 15 years": "Meno di 15 anni",
  "Investment Profile": "Profilo di investimento",
  "General questions for the person to be insured": "Domande generali per la persona da assicurare", */
}

<ng-container *ngIf="isFormVisible">
  <form [formGroup]="regulationForm" class="re-issue-card-styles re-issue-no-error-border">
    <rgi-rx-expansion-panel data-qa="PROPOSAL_REGULATION">
      <rgi-rx-expansion-panel-header>
        <input formControlName="regulation"
               (change)="toggleForm()"
               class="rgi-ui-form-control"
               type="checkbox"
               [disabled]="!isFormModifiable"
               [checked]="isFormChecked"
               id="regulation"
               data-qa="CHECKBOX_PROPOSAL_REGULATION"
        >
        <label for="regulation" rgiRxLabel translate>RE_ISSUE.PROPOSAL_REGULATION</label>
      </rgi-rx-expansion-panel-header>

      <div class="form-group rgi-ui-grid-2">

        <div class="rgi-ui-col">
          <rgi-rx-form-field class="date-field">
            <div class="label-input-container">
              <label rgiRxLabel for="expirationDate" translate>RE_ISSUE.REGULATION_EXPIRATION_DATE</label>
              <div id="expirationDate" class="input-button-container">
                <input [rgiRxDateTimeInput]="regulationExpirationDatePicker" style="flex: 1"
                       class=" custom-margin"
                       data-qa="expirationDate"
                       formControlName="regulationExpirationDate" selectMode="single"
                       rangeSeparator="/"
                       placeholder="gg/mm/yyyy" #inputToTriggerExpirationDate="rgiRxDateTimeInput">

                <button style="flex: 0; order: 1" rgi-rx-button variant="icon"
                        class="rgi-ui-icon-calendar2"
                        [rgiRxDateTimeTrigger]="inputToTriggerExpirationDate"></button>
              </div>
            </div>
            <rgi-rx-date-time #regulationExpirationDatePicker
                              pickerType="calendar"
                              [hour12Timer]="true">
            </rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="regulationFractioning" translate>RE_ISSUE.REGULATION_FRACTIONING</label>
            <select rgiRxNativeSelect id="regulationFractioning" formControlName="regulationFractioning" class=" custom-margin">
              <option value=""></option>
              <option *ngFor="let paymentFrequency of paymentFrequencyList"
                      [ngValue]="paymentFrequency">{{paymentFrequency?.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
      </div>

      <div class="form-group rgi-ui-grid-2">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="regulationType" translate>RE_ISSUE.REGULATION_TYPE</label>
            <select rgiRxNativeSelect id="regulationType" formControlName="regulationType" class=" custom-margin">
              <option value=""></option>
              <option *ngFor="let regulationType of regulationTypesList"
                      [ngValue]="regulationType">{{regulationType?.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="regulationDays" translate>RE_ISSUE.REGULATION_DAYS</label>
            <input rgiRxInput id="regulationDays" formControlName="regulationDays" type="number"  class=" custom-margin">
          </rgi-rx-form-field>
        </div>
      </div>

      <div class="form-group rgi-ui-grid-2">
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel for="minQuotePremium" translate>RE_ISSUE.PERCENTAGE_OF_MIN_QUOTE_PREMIUM</label>
            <input rgiRxInput id="minQuotePremium" formControlName="minQuotePremium" type="number"  class=" custom-margin">
          </rgi-rx-form-field>
        </div>
      </div>


    </rgi-rx-expansion-panel>
  </form>
</ng-container>
<br>

import { TableSchema } from "@rgi/rx/ui";

export const TOTAL_SUMMARY_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'description',
      title: '_IIAB_._LABEL_._DESCRIPTION_'
    },
    {
      name: 'value',
      title: '_IIAB_._LABEL_._VALUE_'
    }
  ],
  header: ['description', 'value']
};
<tr *ngFor="let currentAddress of addressesList; let i=index">
  <th scope="row">
    <input (click)="selectMainAddress(currentAddress)" [checked]="currentAddress?.main" type="radio"
      [disabled]="!areAddressesEnabled"/>
  </th>
  <td>
    <span *ngIf="addressesType===addressTypeEnum.POLICYADDRESS" translate>Policy Address</span>
    <span *ngIf="addressesType===addressTypeEnum.SUBSCRIBERADDRESS" translate>Subscriber's Residence Address</span>
    :
    <span
      [attr.data-qa]="(addressesType===addressTypeEnum.POLICYADDRESS ? 'policy' : 'subscriber')+'-addr-description_'+i">
      <span *ngIf="currentAddress?.address?.formatAddress"
            class="address-description">{{currentAddress?.address?.formatAddress}}
      </span>
      <span *ngIf="!currentAddress?.address?.formatAddress" class="address-description">
          <span>
              {{currentAddress?.address?.toponym?.description}} {{currentAddress?.address?.placeAddress}}
            , {{currentAddress?.address?.number}} - {{currentAddress?.address?.cap}}
            {{currentAddress?.address?.city}}
            <span *ngIf="currentAddress?.address?.province?.code">
              ({{currentAddress?.address?.province?.code}})
            </span>
               - {{currentAddress?.address?.countryCode}}
          </span>
      </span>
      <button (click)="editAddress(currentAddress.address,i)" *ngIf="addressesType===addressTypeEnum.POLICYADDRESS && areAddressesEnabled"
              class="btn btn-default btn-address btn-xs" data-qa="btn-edit-address" type="button">
        <span class="rgifont rgi-pencil-square-o"></span>
      </button>
      <button (click)="delAddress(i)" *ngIf="addressesType===addressTypeEnum.POLICYADDRESS && addressesList?.length > 1 && areAddressesEnabled"
              class="btn btn-default btn-address btn-xs" data-qa="btn-delete-address" type="button">
        <span class="rgifont rgi-trash"></span>
      </button>
    </span>
  </td>
</tr>

import {KarmaProfileDefinition} from '../model/karma-profile-definition';
import {InvestmentPlanData, InvestmentPlanFund, InvestmentPlanProfile, KarmaProfile} from '../model/karma-profile';
import {KarmaFund} from '../model/karma-fund';
import {PassProfileDefinition} from '../../lic-fund/model/PassProfileDefinition';

export class LicKarmaFundUtils {
  public static getDefaultFundStepValueOf(
    profileDefinitions: KarmaProfileDefinition[]
  ): any {
    const obj: any = {
      profiles: {},
      funds: {},
    };
    profileDefinitions.forEach((profile) => {
      obj.profiles[profile.id] = profile.percent ? profile.percent : null;
      obj.funds[profile.id] = {};
      profile.funds.forEach((fund) => {
        let percent = fund.value;
        if (!fund.value) {
          percent = !!fund.percent ? fund.percent : null;
        }
        obj.funds[profile.id][fund.id] = percent;
      });
    });
    return obj;
  }

  public static filterProfileDefinitionBy(
    definitions: KarmaProfileDefinition[],
    profiles: KarmaProfileDefinition[] | KarmaProfile[]
  ): KarmaProfileDefinition[] {
    const investmentIds: any = {};
    profiles.forEach((investmentProfile) => {
      investmentIds[investmentProfile.id] = investmentProfile.funds.map(
        (fund) => fund.id
      );
    });

    return definitions
      .filter((profile) => investmentIds.hasOwnProperty(profile.id))
      .map((profile) => {
        const prfl: KarmaProfileDefinition = Object.assign({}, profile);
        prfl.funds = profile.funds.filter((fund) =>
          (investmentIds[profile.id] as string[]).includes(fund.id)
        );
        return prfl;
      });
  }

  public static getProfileFormValueOf(
    profileDefinitions: PassProfileDefinition[]
  ): any {
    const obj: any = {};
    profileDefinitions.forEach((profile) => {
      if (!!profile.percent) {
        obj[profile.id] = profile.percent ? profile.percent : null;
      }
    });
    return !!Object.keys(obj).length ? obj : null;
  }

  public static getFundFormValueOf(
    profileDefinitions: PassProfileDefinition[]
  ): any {
    const obj: any = {};
    profileDefinitions.forEach((profile) => {
      obj[profile.id] = {
        // percent: profile.value ? profile.value : null,
        // funds: {}
      };
      profile.funds.forEach((fund) => {
        let percent = fund.value;
        if (!fund.value) {
          percent = !!fund.percent ? fund.percent : null;
        }
        obj[profile.id][fund.id] = percent;
      });
    });
    return obj;
  }

  public static getKarmaProfileFromForm(
    profiles: any,
    funds: any,
    toHundred: boolean = false
  ): KarmaProfile[] {
    profiles = profiles ? profiles : {};
    const array: KarmaProfile[] = [];
    Object.keys(profiles).forEach((profileKey) => {
      const profile: KarmaProfile = {
        id: profileKey,
        percentage: profiles[profileKey],
        funds: [],
      };
      if (!!profile.percentage) {
        Object.keys(funds[profileKey]).forEach((fundKey) => {
          let percent: number = funds[profileKey][fundKey]
            ? funds[profileKey][fundKey]
            : 0;
          if (toHundred) {
            percent *= 100;
          }
          const fund: KarmaFund = {
            id: fundKey,
            percentage: percent,
          };
          profile.funds.push(fund);
        });
        array.push(profile);
      }
    });
    return array;
  }

  public static createInvestmentProfileData(
    profileObj: InvestmentPlanProfile,
    fundObj: InvestmentPlanFund
  ): InvestmentPlanData | null {
    if (!!profileObj && !!fundObj) {
      const profiles: InvestmentPlanData['profiles'] = Object.entries(
        profileObj
      ).map(([profileId, profilePercentage]) => {
        const funds = Object.entries(fundObj).map(
          ([fundId, fundPercentage]) => ({
            id: fundId,
            percentage: (fundPercentage * 100).toString(),
          })
        );

        return {
          id: profileId,
          percentage: (profilePercentage * 100).toString(),
          funds,
        };
      });

      return {
        profiles,
      };
    } else {
      return null;
    }
  }
}

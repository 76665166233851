import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
    RgiRxTableModule, RgiRxDatePickerModule,
    RgiRxFormElementsModule, RgiRxTooltipModule, RgiRxExpansionModule,
    RgiRxAutoCompleteModule, RgiRxPanelModule, RgiRxDragDropModule, RgiRxNativeDateTimeModule
} from '@rgi/rx/ui';
import { RgiRxFormModule } from '@rgi/rx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DscGeneralDetailComponent } from './dsc-general-detail/dsc-general-detail.component';
import { DscAddressDetailComponent } from './dsc-address-detail-data/dsc-address-detail.component';
import { DscAddressDataService } from './dsc-address-detail-data/dsc-address-detail-services/dsc-address-detail.service';
import { DscInjuryCodeComponent } from './dsc-injury-code/dsc-injury-code.component';
import { DscAdditionalDataComponent } from './dsc-additional-data/dsc-additional-data.component';
import { DscIncidentDataComponent } from './dsc-incident-data/dsc-incident-data.component';
import { DscAdditionalDataService } from './dsc-additional-data/dsc-additional-data-service/dsc-additional-data.service';
import { DscCircumstancesDataComponent } from './dsc-circumstances-data/dsc-circumstances-data.component';
import { DscPolicyListComponent } from './dsc-policy-list/dsc-policy-list.component';
import { DscPolicyHolderComponent } from './dsc-policy-holder/dsc-policy-holder.component';
import { DscVehicleComponent } from './dsc-vehicle/dsc-vehicle.component';
import { DscInsuranceCompanyComponent } from './dsc-insurance-company/dsc-insurance-company.component';
import { DscOwnerComponent } from './dsc-owner/dsc-owner.component';
import { DscDriverComponent } from './dsc-driver/dsc-driver.component';
import { DscDamagedPartsComponent } from './dsc-damaged-parts/dsc-damaged-parts.component';
import { DscCarBodyworkComponent } from './dsc-car-bodywork/dsc-car-bodywork.component';
import { DscPolicyDetailComponent } from './dsc-policy-list/dsc-policy-detail/dsc-policy-detail.component';
import { ModalService } from '@rgi/rx/ui';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DscSimilarClaimsComponent } from './dsc-similar-claims/dsc-similar-claims.component';
import { claimsBaseObjectServiceProvider,
    claimsFinderIntegrationPassServiceProvider,
    getSystemPropertyProvider } from '@rgi/digital-claims-common-angular';
import { SubjectActionComponent } from './subject/subject-action/subject-action.component';
import { SubjectFormComponent } from './subject/subject-form/subject-form.component';
import { SubjectInfoComponent } from './subject/subject-info/subject-info.component';
import { DscCirumstanceService } from './dsc-circumstances-data/dsc-circumstances-service/dsc-circumstances-data.service';
import { DscBaremsModalComponent } from './dsc-circumstances-data/dsc-barems-modal/dsc-barems-modal.component';
import { DscPhisicalInjuryComponent } from './dsc-phisical-injury/dsc-phisical-injury.component';
import { ClaimOpeningModule } from '../claim-opening/claim-opening.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
import { AnagIntegrationService, RgiAnagModule } from '@rgi/anag';
import { SubjectAddMinorGuardianComponent } from './subject/subject-add-minor-guardian/subject-add-minor-guardian.component';
import { DscDriverLicenseModalComponent } from './dsc-driver/dsc-driver-license-modal/dsc-driver-license-modal.component';
import { DscCarBodyworkModalComponent } from './dsc-car-bodywork/dsc-car-bodywork-modal/dsc-car-bodywork-modal.component';
import { DscInjuryAssetDetailComponent } from './dsc-injury-code/dsc-injury-asset-detail/dsc-injury-asset-detail.component';
import {
    DscCarBodyworkTableComponent
} from './dsc-car-bodywork/dsc-car-bodywork-modal/dsc-car-bodywork-table/dsc-car-bodywork-table.component';
import { DscDamagedPartsModalComponent } from './dsc-damaged-parts/dsc-damaged-parts-modal/dsc-damaged-parts-modal.component';
import { DscHeirComponent } from './dsc-heir/dsc-heir.component';
import { GlobalModalConfirmComponent } from './modal/global-modal-confirm/global-modal-confirm.component';
import { DscAddressModal } from './dsc-address-modal/dsc-address-modal.component';

@NgModule({
    declarations: [
        DscGeneralDetailComponent,
        DscAddressDetailComponent,
        DscInjuryCodeComponent,
        DscAdditionalDataComponent,
        DscIncidentDataComponent,
        DscCircumstancesDataComponent,
        DscPolicyListComponent,
        DscPolicyHolderComponent,
        DscVehicleComponent,
        DscInsuranceCompanyComponent,
        DscOwnerComponent,
        DscDriverComponent,
        DscDamagedPartsComponent,
        DscCarBodyworkComponent,
        DscPolicyDetailComponent,
        DscSimilarClaimsComponent,
        SubjectActionComponent,
        SubjectInfoComponent,
        SubjectFormComponent,
        SubjectAddMinorGuardianComponent,
        DscBaremsModalComponent,
        DscSimilarClaimsComponent,
        DscPhisicalInjuryComponent,
        DscDriverLicenseModalComponent,
        DscCarBodyworkModalComponent,
        DscInjuryAssetDetailComponent,
        DscCarBodyworkTableComponent,
        DscHeirComponent,
        DscDamagedPartsModalComponent,
        DscAddressModal,
        GlobalModalConfirmComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RgiRxTableModule,
        RgiRxAutoCompleteModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxFormModule,
        RgiRxTooltipModule,
        RgiRxExpansionModule,
        RgiRxDragDropModule,
        RgiAnagModule,
        [NgbModule],
        RgiRxPanelModule,
        BrowserAnimationsModule,
        RgiRxI18nModule,
        RgiRxNativeDateTimeModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
    providers: [
        DscAddressDataService,
        DscAdditionalDataService,
        DscCirumstanceService,
        ModalService,
        claimsFinderIntegrationPassServiceProvider,
        claimsBaseObjectServiceProvider,
        getSystemPropertyProvider,
    ],
    exports: [
        DscGeneralDetailComponent,
        DscAddressDetailComponent,
        DscInjuryCodeComponent,
        DscAdditionalDataComponent,
        DscIncidentDataComponent,
        DscCircumstancesDataComponent,
        DscPolicyListComponent,
        DscPolicyHolderComponent,
        DscVehicleComponent,
        DscInsuranceCompanyComponent,
        DscOwnerComponent,
        DscDriverComponent,
        DscDamagedPartsComponent,
        DscCarBodyworkComponent,
        SubjectActionComponent,
        SubjectInfoComponent,
        SubjectFormComponent,
        SubjectAddMinorGuardianComponent,
        DscBaremsModalComponent,
        DscSimilarClaimsComponent,
        DscPhisicalInjuryComponent,
        DscPolicyDetailComponent,
        DscDriverLicenseModalComponent,
        DscCarBodyworkModalComponent,
        DscInjuryAssetDetailComponent,
        DscCarBodyworkTableComponent,
        DscHeirComponent,
        DscDamagedPartsModalComponent,
        GlobalModalConfirmComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class DscComponentModule {}


import { AnagApiAddress, AnagApiParty } from '@rgi/anag';
import { GPIdentificationEntity } from './group-policy-domain-types';

export enum paymentsCode {
  debit = 'PLCDAC',
  credit = 'PLCPAG'
}

export enum paymentsIDs {
  credit = '2',
  debit = '1'
}

export enum paymentPages {
  ammData = 'DATAMM',
  postIssue = 'POSTEM'
}

export interface PartyRoleState {
  dateOfBirth?: string;
  name?: string;
  surname?: string;
  companyType?: string;
  legalphysical: string;
  role?: string;
  idPartyLock?: string;
  idParty: string;
  personType: string;
  maxInstances?: number;
  showPercentage?: boolean;
  minPercentage?: number;
  percentage?: number;
  linkedRoles?: SubjectsRoleState<PartyRoleState>;
}

export interface CheckPartyOutput {
  partycongruent: boolean;
  errorMessages: ErrorResp[];
}

export class ErrorResp {
  code?: string | null;
  constraintType?: string | null;
  level: ErrorCodeType;
  message: string;
}

export class ErrorMsg {
  errorCode: string;
  errorDescription: string;
  errorType: number;
}

export class ResultMessage {
  message: string;
}

// tslint:disable-next-line:no-empty-interface
export interface AnagApiSubject extends AnagApiParty {
}

export interface Role {
  dateOfBirth?: string;
  name?: string;
  surname?: string;
  companyType?: string;
  legalphysical: string;
  idPartyLock?: string;
  idParty: string;
  showPercentage?: boolean;
  minPercentage?: number;
  percentage?: number;
  linkedRoles: Role[];
  maxInstances: number;
  personType: string;
  role: string;
}

export interface SubjectContract {
  idParty: string;
  role: string;
  percentage: number;
}

export interface PaymentFrequency {
  value: BaseEntity;
  allowedValues: BaseEntity[];
  editableValue: boolean;
}

export class BaseEntity {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }

}

export class BaseEntityMultiDescriptions extends BaseEntity {

  multiDescriptions: Array<BaseEntity>;

  constructor(code: string, description: string, multiDescriptions: Array<BaseEntity>) {
    super(code, description);
    this.multiDescriptions = multiDescriptions;
  }
}

export interface GpListValues {
  value: BaseEntity;
  allowedValues: BaseEntity[];
  editableValue: boolean;
}

export interface Property {
  key: string;
  value: string | null;
  values: string[] | null;
}

export class PaymentConfig {
  credMeansOfPayment: MeanOfPayment[];
  debMeansOfPayment: MeanOfPayment[];
}

export interface MeanOfPayment {
  editable: boolean;
  paymentConfig: MeanOfPaymentDetails;
  selected: boolean;
}

export interface MeanOfPaymentDetails {
  PaymentsFields: PaymentsField[];
  meanOfPayment: GPIdentificationEntity;
  paymentType: string;
}

export interface PaymentsField {
  name: string;
  readOnly: boolean;
  label: string;
  type: string;
  value: string;
  values: Array<any>;
  mandatory: boolean;
}

export interface Factor extends BaseEntity {
  type: string;
  editable: boolean;
  persistent: boolean;
  mandatory: boolean;
  applicationFactor: boolean;
  configurable: boolean;
  properties: [
    {
      key: string;
      value: string;
    }
  ];
  value: string;
  values: Array<BaseEntity>;
  pattern: string;
  minMaxVisible: boolean;
  minValue: string;
  maxValue: string;
  editableCheckVisible: boolean;
  editInApplication: boolean;
  multipleOptionsSelectable: boolean;
  selectedValues: Array<BaseEntity>;
}

export interface SubjectsRoleState<PartyRole> {
  [role: number]: [PartyRole];
}

export interface ChecksAllResp {
  partycongruent: boolean;
  messages: string[];
  violations: ErrorResp[];
}

export interface PaymentsPayload {
  paymentCode: string;
  meanOfPaymentType: number;
  settlementType: number;
}

export interface SaveResp {
  proposalNumber: string;
  containsApplications: boolean;
}

export type ErrorCodeType = 'BLOCKING' | 'WARNING' | 'INFO' | 'IGNORED';

export enum ErrorCode {
  BLOCKING = 'BLOCKING',
  WARNING = 'WARNING',
  INFO = 'INFO',
  IGNORED = 'IGNORED'
}

export enum GroupPolicyContactsManagement {
  NONE = 1, // No address box
  ADDRESS = 2,  // Address visible without add address capability
  ALL = 3 // Address Visible with add address capability
}

export enum PortfolioContactsManagement {
  ALL = 1,  // Tutti (default)
  EMAIL = 2,  // Posta normale + mail
  HOME_INSURANCE = 3, // Posta normale + home insurance
  NONE = 4  // Solo posta normale
}

export enum ContactTypeSelected {
  ORDINARY_ADDRESS = 'ORDINARY_ADDRESS',
  EMAIL = 'EMAIL',
  HOMEINSURANCE = 'HOMEINSURANCE',
}

export interface SystemProp {
  serviceInfo: ServiceInfo;
  systemProperties: SystemProperties[];
}

export interface ServiceInfo {
  codiceErroreOccorso: string;
  descrizioneErroreOccorso: string;
  dettagliSullErroreOccorso: string;
  eccezioneOccorsaSerializzata: string;
  executionId: string;
  logs: string;
  tipoErroreOccorso: string;
}

export interface SystemProperties {
  key: string;
  value: string;
}

export interface ContractAddressesResp {
  listPolicyAddress: ListPolicyAddress[];
  listPolicyEmails: ListPolicyEmails[];
  homeInsurance: HomeInsurance;
  contactTypeSelected: ContactTypeSelected;
}

export interface ListPolicyAddress {
  address: Address;
  main: boolean;
  isPolicyHolderResidence: boolean;
}

export interface Address extends AnagApiAddress {
  idAddress: number;
}

export interface ListPolicyEmails {
  email: string;
  selected: boolean;
}

export interface HomeInsurance {
  emails: ListPolicyEmails[];
  phoneNumber: {
    phoneNumber: string;
    selected: boolean;
  };
}

export interface ContractAddressesPut {
  policyAddress?: Address;
  policyEmail?: string;
  phoneNumber?: string;
  contactTypeSelected: string;
}

export interface CoinsuranceShareList {
  coinsuranceType: Entity;
  ourPercentage: number;
  coinsuranceShares: CoinsuranceShare[];
}

export interface CoinsuranceShare {
  rowIndex: number;
  companyDesc: string;
  intermediaryDesc: string;
  company: Entity;
  intermediary: Entity;
  percentageShare: number;
  percentageCommissions: number;
  policyNumber: string;
}

export interface Entity {
  code: string;
  description: string;
  id: number;
}

export enum GRP_OBJ_MODE {
  VIEW,
  EDIT,
  INSERT,
}

export interface Indexing {
  value: BaseEntity;
  allowedValues: BaseEntity[];
  visible: boolean;
  mandatory: boolean;
}

export interface GPClause {
  code: string;
  description: string;
  extendedDescription: string;
  selected: boolean;
  editable: boolean;
  hasText: boolean;
  text: string;
  editableText: boolean;
  mandatoryText: boolean;
  initialState: GpListValues;
  hiddenOnApplication: boolean;
  textType: string;
}

export interface GPPolicyDataField {
  type: string;
  label: string;
  code: string;
  valueStr: string;
  valueDate: string;
  minDate: string;
  maxDate: string;
  valueNumb: number;
  valueBool: boolean;
  allowedValues: BaseEntity[];
  pattern: string;
  mandatory: boolean;
  editable: boolean;
  visible: boolean;
  order: number;
  reloadOnChange: boolean;
  maxLength: string;
  minLength: string;
}

export interface GPExtensionDataSection {
  icon: string;
  label: string;
  fields: GPPolicyDataField[];
  visible: boolean;
}

export interface GPExtensionDataSections {
  sections: GPExtensionDataSection[];
}

export interface GPPolicyFieldUpdate {
  updatedField: GPPolicyDataField;
  updatedFieldList: GPPolicyDataField[];
}

export class ActionButton extends BaseEntity {
  isButtonVisible: boolean;
  isButtonActive: boolean;

  constructor(code: string, description: string, isButtonActive?: boolean) {
    super(code, description);
    isButtonActive !== null ? this.initActionButtonPropertiesWithParameter(isButtonActive) : this.initActionButtonProperties();
    this.initActionButtonPropertiesWithParameter(isButtonActive);
  }
  initActionButtonPropertiesWithParameter(isButtonActive: boolean) {
    this.isButtonVisible = true;
    this.isButtonActive = isButtonActive;
  }
  initActionButtonProperties() {
    this.isButtonVisible = true;
    this.isButtonActive = true;
  }
}

import {Component, ComponentRef, EventEmitter, OnInit, Output} from '@angular/core';
import {QuotationService} from '../../quotation/quotation.service';
import {InsuranceStatus} from '../../models/domain-models/insurance-status';
import {Modal} from '../../modal';
import {PremiumDetail} from '../../models/domain-models/premium-detail';
import {ApiPremiumDetail} from '../../models/api-models/api-premium-detail';

@Component({
  selector: 'mic-quotation-comparison',
  templateUrl: './quotation-comparison-modal.component.html',
  styleUrls: ['./quotation-comparison-modal.component.scss']
})
export class QuotationComparisonModalComponent implements Modal, OnInit {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  componentRef: ComponentRef<any>;
  premiumDetailSections: Array<PremiumDetail> = new Array<PremiumDetail>();
  insuranceStatus: InsuranceStatus;
  enableSkipQuotation = false;
  goEditQuotation = false;
  protected previousAnnualPremium: ApiPremiumDetail;
  protected currentAnnualPremium: ApiPremiumDetail;

  constructor(protected quotationService: QuotationService) {
  }

  ngOnInit() {
    const entitledPremium = this.quotationService.getEntitledPremium();
    this.insuranceStatus = entitledPremium.insuranceStatus;
    this.currentAnnualPremium = entitledPremium.premium;
    if (this.quotationService.getPreviousAnnualPremium()) {
      this.previousAnnualPremium = this.quotationService.getPreviousAnnualPremium();
    }
    this.initializePremiumDetailSections();
    if (this.currentAnnualPremium && this.previousAnnualPremium &&
      this.currentAnnualPremium.gross === this.previousAnnualPremium.gross) {
      this.enableSkipQuotation = true;
    }
  }

  editQuotation() {
    this.goEditQuotation = true;
    this.close();
  }

  close() {
    this.componentRef.destroy();
  }

  private initializePremiumDetailSections() {
    if (this.previousAnnualPremium) {
      this.premiumDetailSections.push(
        new PremiumDetail('Previous Annual Premium',
          (this.previousAnnualPremium.gross) ? this.previousAnnualPremium.gross : 0.00,
          (this.previousAnnualPremium.net) ? this.previousAnnualPremium.net : 0.00,
          (this.previousAnnualPremium.accessories) ? this.previousAnnualPremium.accessories : 0.00,
          (this.previousAnnualPremium.paymentFrequencyInterest)
            ? this.previousAnnualPremium.paymentFrequencyInterest : 0.00,
          (this.previousAnnualPremium.taxable) ? this.previousAnnualPremium.taxable : 0.00,
          (this.previousAnnualPremium.taxes) ? this.previousAnnualPremium.taxes : 0.00,
          (this.previousAnnualPremium.sSN) ? this.previousAnnualPremium.sSN : 0.00)
      );
    }
    if (this.currentAnnualPremium) {
      this.premiumDetailSections.push(
        new PremiumDetail('Current Annual Premium', (this.currentAnnualPremium.gross) ? this.currentAnnualPremium.gross : 0.00,
          (this.currentAnnualPremium.net) ? this.currentAnnualPremium.net : 0.00,
          (this.currentAnnualPremium.accessories) ? this.currentAnnualPremium.accessories : 0.00,
          (this.currentAnnualPremium.paymentFrequencyInterest) ? this.currentAnnualPremium.paymentFrequencyInterest : 0.00,
          (this.currentAnnualPremium.taxable) ? this.currentAnnualPremium.taxable : 0.00,
          (this.currentAnnualPremium.taxes) ? this.currentAnnualPremium.taxes : 0.00,
          (this.currentAnnualPremium.sSN) ? this.currentAnnualPremium.sSN : 0.00)
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { Party } from '../../dto/policy/party';

@Injectable({
  providedIn: 'root'
})
export class PartyTranslatorService {

  anagJsonToPartyDto(anagJson: any): Party {
    // TODO: improve translations

    const party = new Party();
    if (anagJson.data) {
      if (anagJson.data.residence) {
        party.address = anagJson.data.residence.formatAddress;
      }
      party.fiscalcode = anagJson.data.fiscalCode;
      party.id = anagJson.data.objectId;
      party.name = anagJson.data.name;
      party.piva = anagJson.data.vat;
      party.surname = anagJson.data.surname;
      // party.statusAntiFinancialCrimes = anagJson.data.counterTerrorismStatus.codice;
      if (anagJson.data.sex) {
        if (anagJson.data.sex.codice === '1') {
          party.type = 'M';
        } else if (anagJson.data.sex.codice === '2') {
          party.type = 'F';
        }
      }
    }

    party.description = anagJson.description;
    // TODO: add
    party.mail = null;
    // TODO: add
    party.phoneNumber = null;
    // TODO: add
    party.tax = null;

    return party;
  }

  partyDtoToAnagJson(party: Party) {
    let sexCode: string;
    if (party.type === 'M') {
      sexCode = '1';
    } else if (party.type === 'F') {
      sexCode = '2';
    }
    // TODO: add description, mail, phonenumber, tax
    return {
      data: {
        residence: {
          formatAddress: party.address
        },
        fiscalCode: party.fiscalcode,
        objectId: party.id,
        name: party.name,
        vat: party.piva,
        surname: party.surname,
        sex: {
          codice: sexCode
        }
      }
    };
  }

  anagSubjectToPartyDto(anagJson: any): Party {
    // TODO: improve translations

    const party = new Party();
    if (anagJson.residence) {
      party.address = anagJson.residence.formatAddress;
    }
    party.fiscalcode = anagJson.fiscalCode;
    party.id = anagJson.objectId;
    party.name = anagJson.name;
    party.piva = anagJson.vat;
    party.surname = anagJson.surname;
    if (anagJson.sex) {
      if (anagJson.sex.codice === '1') {
        party.type = 'M';
      } else if (anagJson.sex.codice === '2') {
        party.type = 'F';
      }
    }

    party.description = anagJson.description;
    // TODO: add
    party.mail = null;
    // TODO: add
    party.phoneNumber = null;
    // TODO: add
    party.tax = null;

    return party;

  }

  constructor() { }
}

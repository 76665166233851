import {ActiveRoute} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {of} from 'rxjs';
import {GroupPolicyStatelessOpEditService} from './group-policy-stateless-op-edit.service';
import {GroupPolicyStateEdit} from '../group-policy-state';


export class GroupPolicyStatemanagerEdit extends AbstractStateManager<GroupPolicyStateEdit> {

  constructor(activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: GroupPolicyStatelessOpEditService) {
    super(activeRoute, stateStoreService);
    this.initHome();
  }

  initHome() {
    const statehome = new GroupPolicyStateEdit(this.activeRoute.key);
    const initHome$ = this.statelesOps.initEditProposal$(of(statehome), this.activeRoute);
    this.updateState$(initHome$);
  }

}

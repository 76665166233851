import {
  Directive, ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  isDevMode,
  Optional,
  Output,
  Self
} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {ENTER, ESCAPE, SPACE} from '@angular/cdk/keycodes';
import {
  RgiRxControlForwardClass,
  RgiRxErrorStateMatcher,
  RgiRxFormControl
} from '../../form-elements/rgi-rx-form-elements-api';
import {RgiRxFormControlDirective} from '../../form-elements/rgi-rx-form-control.directive';

export interface RgiRxSwitchChange {
  event: Event;
  selected: boolean;
}

@Directive({
  selector: 'rgi-rx-switch,[rgi-rx-switch],[rgiRxSwitch]',
  host: {
    class: 'rgi-ui-switch',
    '[class.rgi-ui-disabled]': 'disabled',
    '[tabindex]': 'disabled ? -1 : 0',
    '[attr.aria-checked]': 'value ? "true" : "false"',
    '[attr.aria-readonly]': 'disabled ? "true" : "false"',
    role: 'switch'
  },
  inputs: [
    'disabled'
  ],
  providers: [
    {
      provide: RgiRxFormControl,
      useExisting: RgiRxSwitchDirective
    }
  ],
})
export class RgiRxSwitchDirective extends RgiRxFormControlDirective<boolean> implements ControlValueAccessor {

  constructor(
    _elementRef: ElementRef,
    _errorStateMatcher: RgiRxErrorStateMatcher,
    @Optional() @Self() ngControl?: NgControl,
    @Optional() _parentForm?: NgForm,
    @Optional() _parentFormGroup?: FormGroupDirective
  ) {
    super(_elementRef, _errorStateMatcher, ngControl, _parentForm, _parentFormGroup);
    super.inheritDefaultClass = false;
    if (!!ngControl) {
      ngControl.valueAccessor = this;
    }
  }


  @HostBinding('class.rgi-ui-active') @Input() get value(): boolean {
    return this._value;
  }

  set value(value: boolean) {
    this._value = value;
  }

  @Output() readonly onSwitchChange = new EventEmitter<RgiRxSwitchChange>();

  onChange = (changed) => {};
  onTouched = () => {};


  @HostListener('click', ['$event'])
  onHostClick($event: Event) {
    if (this.disabled) {
      return;
    }
    this.toggle($event);
    this.onTouched();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.disabled) {
      return;
    }
    const keyCode = event.keyCode;
    switch (keyCode) {
      case ESCAPE: {
        event.preventDefault();
        break;
      }
      case SPACE: {
        this.toggle(event);
        break;
      }
    }
  }

  public toggle(event?: Event) {
    this._value = !this._value;
    this.onChange(this._value);
    this.onSwitchChange.emit({
      event,
      selected: this._value
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this._value = obj;
  }
  // retro-compatibility
  /**
   * @deprecated
   */
  @Input() get isSelected() {
    return this._value;
  }
  /**
   * @deprecated
   */
  // retro-compatibility
  set isSelected(value: boolean) {
    this._value = value;
    if (isDevMode()) {
      console.error('Using isSelected in rgi-rx-switch is deprecated. Use value instead! this field will be removed in 2.x');
    }
  }
  get forwardClasses(): RgiRxControlForwardClass {
    return ['rgi-ui-form-group-label-static'];
  }
}

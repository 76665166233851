import {Component, Inject, Optional} from '@angular/core';
import {GroupPolicyModalTextBoxComponent} from "../group-policy-modal-textbox/group-policy-modal-text-box.component";
import {DIALOG_DATA} from "@rgi/rx/ui";
import {BaseEntityMultiDescriptions, GPClause} from "../../../group-policy-models/group-policy-issue-policy-data";
import {RgiRxTranslationService} from "@rgi/rx/i18n";

@Component({
  selector: 'rgi-gp-group-policy-modal-show-clause',
  templateUrl: './group-policy-modal-show-clause.component.html'
})
export class GroupPolicyModalShowClauseComponent extends GroupPolicyModalTextBoxComponent {
  private clauseDetails: BaseEntityMultiDescriptions[] = [];


  constructor(public translateService: RgiRxTranslationService,
              @Optional() @Inject(DIALOG_DATA) clause: GPClause) {
    super(clause);
  }

  ngOnInit() {
    if(this.clauseData.initialState){
      this.clauseDetails.push(new BaseEntityMultiDescriptions("_GP_._SUMMARY_._INITIAL_STATE", this.clauseData.initialState.value.description, null));
      this.translateService.translate(this.clauseData.hiddenOnApplication ? '_GP_._BTN_._YES_' :
        '_GP_._BTN_._NO_').subscribe(label => {
        this.clauseDetails.push(new BaseEntityMultiDescriptions("_GP_._LABEL_._HIDE_ON_APPLICATION_", label, null));
      }).unsubscribe();
    }
  }
  getClauseDetails(): BaseEntityMultiDescriptions[] {
    return this.clauseDetails;
  }
}

import {Directive, HostBinding} from '@angular/core';


@Directive({
  selector: 'pnc-card-content'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class CardContentDirective {
  @HostBinding ('class') cardContentClass = 'pnc-card-content';
}


@Directive({
  selector: 'pnc-card-title'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class CardTitleDirective {
  @HostBinding ('class') cardTitleClass = 'pnc-card-title';
}


@Directive({
  selector: 'pnc-card-menu'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class CardMenuDirective {
  @HostBinding ('class') cardMenuClass = 'pnc-card-menu';
}

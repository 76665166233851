import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AnagApiOtherAddress} from '../../anag-model/anag-domain/anag-api-party';
import { AnagStatelessService } from '../../anag-resources/anag-stateless.service';

@Component({
  selector: 'rgi-anag-availability-address',
  templateUrl: './availability-address.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AvailabilityAddressComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  @Input() inputOtherAddress;
  @Output() outputOtherAddressAvailabilityValidity: EventEmitter<string> = new EventEmitter();
  @Input() parentForm: UntypedFormGroup;

  numberValidator = Validators.pattern('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
  availabilityStartDate = new UntypedFormControl('');
  availabilityEndDate = new UntypedFormControl(undefined);
  availableFrom = new UntypedFormControl(undefined, this.numberValidator);
  availableUntil = new UntypedFormControl('', this.numberValidator);

  availabilityOtherAddressForm: UntypedFormGroup = new UntypedFormGroup({
    availabilityStartDate: this.availabilityStartDate,
    availabilityEndDate: this.availabilityEndDate,
    availableFrom: this.availableFrom,
    availableUntil: this.availableUntil
  });
  constructor(public anagStorage: AnagStorageService,
    public anagStatelessService: AnagStatelessService,
    ) { }

  ngOnInit() {
    if (this.parentForm) {
      this.parentForm.setControl('availabilityOtherAddressForm', this.availabilityOtherAddressForm);
    }
    if (this.inputOtherAddress) {
      this.adaptInputOtherAddressToForm(this.inputOtherAddress);
    } else {
      this.inputOtherAddress = new AnagApiOtherAddress();
    }
    this.manageSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private adaptInputOtherAddressToForm(inputAddress: AnagApiOtherAddress) {
    this.updateFormField(this.availabilityOtherAddressForm.get('availabilityStartDate'), inputAddress.availabilityStartDate);
    this.updateFormField(this.availabilityOtherAddressForm.get('availabilityEndDate'), inputAddress.availabilityEndDate);
    this.updateFormField(this.availabilityOtherAddressForm.get('availableFrom'), inputAddress.availableFrom);
    this.updateFormField(this.availabilityOtherAddressForm.get('availableUntil'), inputAddress.availableUntil);
  }

  protected updateFormField(control: AbstractControl, value: string) {
    if (control && value !== control.value) {
      control.setValue(value, {emitEvent: false});
    }
  }

  manageSubscriptions() {
    this.subscriptions.add(this.availabilityOtherAddressForm.valueChanges.subscribe(formData => {
      this.adaptFormToApiOtherAddressContacts(formData);
    }));
  }

  adaptFormToApiOtherAddressContacts(formData: any): any {
    if (formData.availabilityStartDate && formData.availabilityEndDate) {
      this.dateComparisonValidator();
    }
    this.inputOtherAddress.availabilityStartDate = formData.availabilityStartDate;
    this.inputOtherAddress.availabilityEndDate = formData.availabilityEndDate;
    this.inputOtherAddress.availableFrom = formData.availableFrom;
    this.inputOtherAddress.availableUntil = formData.availableUntil;
    return this.inputOtherAddress;
  }

  dateComparisonValidator() {
    // tslint:disable-next-line:max-line-length
      if (this.availabilityOtherAddressForm.get('availabilityStartDate').value > this.availabilityOtherAddressForm.get('availabilityEndDate').value) {
        this.availabilityOtherAddressForm.get('availabilityStartDate').setErrors({'_ANAG_._MSG_._INVALID_START_DATE_DOMICILE_': true});
        this.availabilityOtherAddressForm.get('availabilityEndDate').setErrors({'_ANAG_._MSG_._INVALID_END_DATE_DOMICILE_': true});
        } else {
        this.availabilityOtherAddressForm.get('availabilityStartDate').setErrors(null);
        this.availabilityOtherAddressForm.get('availabilityEndDate').setErrors(null);
      }
      return false;
  }

  get datepickerPlaceholder() {
    return this.anagStatelessService.getDatePickerPlaceholderLabel();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditablePanelTitleComponent } from './editable-panel-title/editable-panel-title.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_COMMON_TRANSLATIONS } from '../../../assets/i18n/translations';

@NgModule({
  declarations: [
    EditablePanelTitleComponent
  ],
  imports: [
    CommonModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(CLAIMS_COMMON_TRANSLATIONS),

  ],
  exports: [
    EditablePanelTitleComponent
  ]
})
export class PanelModule { }

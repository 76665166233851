import {BaseEntity, GPPolicyDataField} from './group-policy-issue-policy-data';
import {GPIdentificationEntity} from './group-policy-domain-types';

export enum productCategories {
  MOTOR = '1',
  RE = '2',
  BOND = '3',
  CREDIT = '4',
  HAIL = '5',
  TRANSPORT = '6',
  LIFE = '7'
}

export enum inclusionReason {
  NEW = '1',
  SUBSTITUTION = '2',
}

export interface ApplicationsResponse {
  applications: Array<ApplicationReference>;
  numberOfApplications: number;
}

export interface ApplicationReference {
  idPolicy: number;
  policyNumber: string;
  proposalNumber: string;
  contractNumber: string;
  contractDescription: string;
  category: BaseEntity;
  status: BaseEntity;
  managementNode: BaseEntity;
  issueDate: string;
  effectiveDate: string;
  expirationDate: string;
  product: BaseEntity;
  channel: string;
  isPolicy: boolean;
  totalPremium: number;
  userName: string;
  extensionProperties: [
    {
      key: string;
      value: string;
    }
  ];
}

export interface GPAppInclusionData {
  productCategory: GPIdentificationEntity;
  policyTypeCluster?: boolean;
}

export interface GPAppInclusionMotorData extends GPAppInclusionData {
  plateNumber?: GPPolicyDataField;
  creationReason?: GPPolicyDataField;
  assetCode?: GPPolicyDataField;
  issueDate?: GPPolicyDataField;
  effectiveDate?: GPPolicyDataField;
  expirationDate?: GPPolicyDataField;
  effectiveHour?: GPPolicyDataField;
  applicationNumber?: GPPolicyDataField;
}

export class FilterEntity {
   public country: string;
   public state: string;
   public municipality: string;
   public postalCode: string;
   public address: string;
   public number: string;
   public description: string;
   public code: string;
}

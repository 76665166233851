<div class="ppevo-quest-question" [ngClass]='getStyleClassesByAswerType(question)' [attr.data-qa]="question.question.code">
    <div class="ppevo-title">
        <span>{{ question.question.description }}</span>
        <span *ngIf="question.hasNotes" class="ppevo-icon-info" (click)="onClickInfoNotes()" ></span>
        <span *ngIf="question.compulsory"
            class="ppevo-label-required" >*<span translate>NG_PASSPRO.required</span></span>
    </div>
    <ng-container *ngFor="let answer of question.answers">
        <ppevo-answer (change)="onChange($event)" *ngIf="answer" [answer]="answer"
        [isRequired]="question.compulsory" [isValid]="question.isValid"
        [disabled]="question.isDisabled"></ppevo-answer>
    </ng-container>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {Variable} from '../../models/domain-models/variable';

@Component({
  selector: 'mic-package-factor',
  templateUrl: './package-factor.component.html',
  styleUrls: ['./package-factor.component.scss']
})
export class PackageFactorComponent implements OnInit {

  @Input() factor: Variable;

  constructor() {
  }

  ngOnInit() {
  }

  valueFormatter(value: string | number) {
    if (this.factor.variablesValue && this.factor.variablesValue.length > 0) {
      const variableVal = this.factor.variablesValue.find(val => val.code === value);
      return variableVal ? variableVal.description : null;
    }
    if (!Number.isNaN(Number(value))) {
      return Number(value).toLocaleString('it-IT');
    }
    return value;
  }

}

import {Inject, Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LifeHttpErrorMessageHandlerService {

  private static readonly HEADERS_KEY_RGI_EXECUTION_ID = 'RGI_executionId';

  private static readonly ERROR_DESCRIPTION_DEFAULT: string = 'Error';

  constructor(@Inject('authService') protected authService: any/*,
              protected lifeHttpErrorUtilityService: LifeHttpErrorUtilityService*/) { }

  public getDescriptionErrorMessageByCatchError(err: any): string {
    if (!!err.error && !!err.error.description) {
      return err.error.description;
    }

    if (!!err.errorBody && !!err.errorBody.description) {
      return err.errorBody.description;
    }

    return LifeHttpErrorMessageHandlerService.ERROR_DESCRIPTION_DEFAULT;
  }

  public getModalErrorMessagesByCatchError(err: any): string[] {
    const message = `${err.message}`;
    const status: number = err.status;

    let messages: any = null;
    if (!!err.error && !!err.error.messages) {
      messages = err.error.messages;
    } else if (!!err.errorBody && !!err.errorBody.messages) {
      messages = err.errorBody.messages;
    }

    let executionId: any = null;
    if (err.headers instanceof HttpHeaders) {
      executionId = err.headers.get(LifeHttpErrorMessageHandlerService.HEADERS_KEY_RGI_EXECUTION_ID);
      executionId = executionId ? executionId : this.authService.executionId;
    }

    return this.getModalErrors(message, status, messages, executionId);

    /*if (err instanceof HttpErrorResponse) {
      return this.getModalErrorMessagesFromHttpResponse(err);
    }
    if (err instanceof RgiRxHttpError) {
      return this.getModalErrorMessagesFromRgiRxHttpError(err);
    }
    return [];*/
  }

  /*private getModalErrorMessagesFromHttpResponse(httpErrorResponse: HttpErrorResponse): string[] {
    const message = `${httpErrorResponse.message}`;
    const status: number = httpErrorResponse.status;
    const messages: any = httpErrorResponse.error.messages;
    const executionId: string = this.lifeHttpErrorUtilityService.getExecutionIdFromHttpErrorResponse(httpErrorResponse);
    return this.getModalErrors(message, status, messages, executionId);
  }

  private getModalErrorMessagesFromRgiRxHttpError(rgiRxHttpError: RgiRxHttpError): string[] {
    const message = `${rgiRxHttpError.message}`;
    const status: number = rgiRxHttpError.status;
    const messages: any = rgiRxHttpError.errorBody.messages;
    const executionId: string = this.lifeHttpErrorUtilityService.getExecutionIdFromRgiRxHttpError(rgiRxHttpError);
    return this.getModalErrors(message, status, messages, executionId);
  }*/

  private getModalErrors(message: string, status: number, messages: any, executionId: string): string[]   {
    let modalErrors: string[] = [];
    let errorMessages = message;
    if (status === 400 && messages) {
      errorMessages = messages;
    }
    modalErrors = ['A server error occurred.', errorMessages];
    if (!!executionId) {
      modalErrors.push('Error Code: ' + executionId);
    }
    return modalErrors;
  }

}

import {AmountByCover} from './amount-by-cover';
import {Instalment} from './instalment';
import {Fees} from './fees';
import {FlexDiscountParam} from '../pc-portfolio-models/discounts-models/flex-discount-param';

export class InstalmentPremiumGroup {
  public premium: Instalment;
  public amountByCover: Array<AmountByCover>;
  public fees: Fees;
  public lpsSignInstalmentAmount: number;
  public lpsNextInstalmentAmount: number;
  public lpsAdditionalFees: any;
  public flexDiscounts: FlexDiscountParam;
}

<ng-container *ngIf="summaryAssets" data-qa="rgi-gp-guarantees">
  <div class="rgi-ui-text-3 rgi-gp-mb-m" translate>
    _GP_._TITLE_._ASSET_LIST_
  </div>
  <ng-container *ngFor="let asset of summaryAssets">
    <rgi-rx-expansion-panel [attr.data-qa]="asset.codeAsset" color="default">
      <div rgi-rx-expansion-panel-header>
        <div class="rgi-gp-font-family" rgi-rx-expansion-panel-label>
          {{asset.descrAsset}}
        </div>
      </div>
      <ng-template rgiRxExpansionPanelContent>
        <ng-container *ngIf="asset.clusters && asset.clusters.length; else units">
          <rgi-gp-group-policy-clusters [codeAsset]="asset.codeAsset" [stateManagerCluster]="stateManagerCluster"
                                        [editMode]="false">
          </rgi-gp-group-policy-clusters>
        </ng-container>
        <ng-template #units>
          <rgi-gp-units-detail [units]="asset.units">
          </rgi-gp-units-detail>
          <rgi-gp-clauses-detail [clauses]="asset.clauses"></rgi-gp-clauses-detail>
        </ng-template>

      </ng-template>
    </rgi-rx-expansion-panel>

  </ng-container>

</ng-container>

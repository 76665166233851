<table rgi-rx-table [dataSource]="dataSource">
  <ng-container rgiRxColumnDef="label">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
    <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.label}} </td>
    <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row" translate>RE_ISSUE.TOTAL</td>
  </ng-container>
  <ng-container rgiRxColumnDef="taxable">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
    <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.taxable}} </td>
    <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"
        data-qa='section_total'>{{footerData.premium.taxable | number : '1.2-2'}}</td>
  </ng-container>
  <ng-container rgiRxColumnDef="rate">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.RATE</td>
    <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.rate}} </td>
    <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row"></td>
  </ng-container>
  <ng-container rgiRxColumnDef="taxes">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.TAXES</td>
    <td rgi-rx-cell *rgiRxCellDef="let row">
      <ng-container *ngIf="row.label === 'LPS'; else taxesDisplay">
        <input rgiRxInput type="number" [(ngModel)]="lpsTax" data-qa="lpsTax">
      </ng-container>
      <ng-template #taxesDisplay>
        {{ row.taxes }}
      </ng-template>
    </td>
    <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row">{{footerData.premium.taxes | number : '1.2-2'}}</td>
  </ng-container>
  <ng-container rgiRxColumnDef="taxAntiMoneyLaundering">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.TAX_ANTI_MONEY_LAUNDERING</td>
    <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.taxAntiMoneyLaundering}} </td>
    <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row">{{totalAntiRacket | number : '1.2-2'}}</td>
  </ng-container>
  <ng-container rgiRxColumnDef="gross">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
    <td rgi-rx-cell *rgiRxCellDef="let row">{{row.gross | number : '1.2-2'}}</td>
    <td rgi-rx-footer-cell *rgiRxFooterCellDef="let row">{{footerData.premium.gross | number : '1.2-2'}}</td>
  </ng-container>

  <!-- SUPER HEADER -->
  <ng-container rgiRxColumnDef="emptyHeader">
    <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
        style="text-align: center;border: 3px solid white">
    </th>
  </ng-container>
  <ng-container rgiRxColumnDef="taxableHeader">
    <th rgi-rx-header-cell *rgiRxHeaderCellDef [attr.colspan]="1"
        style="text-align: left;border: 3px solid white; padding-left: 7px" translate>RE_ISSUE.TAXABLE
    </th>
  </ng-container>
  <ng-container rgiRxColumnDef="insuranceTaxHeader">
    <th rgi-rx-header-cell style="text-align: center;border: 3px solid white" [attr.colspan]="3"
        *rgiRxHeaderCellDef translate>RE_ISSUE.INSURANCE_TAX
    </th>
  </ng-container>
  <ng-container rgiRxColumnDef="grossHeader">
    <th rgi-rx-header-cell style="text-align: center;border: 3px solid white" *rgiRxHeaderCellDef
        [attr.colspan]="1" translate>RE_ISSUE.GROSS
    </th>
  </ng-container>

  <tr rgi-rx-header-row *rgiRxHeaderRowDef="superHeader"></tr>
  <tr rgi-rx-header-row *rgiRxHeaderRowDef="columnsToDisplay"></tr>
  <tr rgi-rx-row *rgiRxRowDef="let row; columns: columnsToDisplay;"></tr>
  <tr rgi-rx-footer-row *rgiRxFooterRowDef="columnsToDisplay"></tr>
</table>

<rgi-rx-panel>
  <!-- HEADER -->
  <rgi-rx-panel-header (onClose)="closeModal()" [closeable]="true"><span translate>RE_ISSUE.NODE_LIST</span>
  </rgi-rx-panel-header>
  <!-- DATATABLE -->
  <rgi-rx-datatable rgi-rx-qa="node_result" qa-ctx="adapterprint-context"
                    [data]="dropSelectionData.selectData" [schema]="schema"
                    [pageOptions]="[10,25,50,100]" (onAction)="onActionClick($event)">
  </rgi-rx-datatable>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeModal()"><span translate>RE_ISSUE.CANCEL</span></button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Factor} from '../../../group-policy-models/group-policy-issue-policy-data';

/**
 * @author: dmasone
 * @description: Component used for manage radio factors
 */
@Component({
    selector: 'rgi-gp-group-policy-factors-radio-component',
    templateUrl: './group-policy-factors-radio.component.html'
})
export class GroupPolicyPolicyFactorsRadioComponent implements OnInit, OnDestroy {

    @Output() updateFactors = new EventEmitter<Factor>();
    @Input() factor: Factor;
    @Input() group: UntypedFormGroup;

    constructor() { }

    ngOnInit(): void {
      this.group.get(this.factor.code).valueChanges.subscribe(newValue => {
        this.factor.value = newValue;
        this.onChangeFactor();
      });
    }

    ngOnDestroy(): void { }

    public onChangeFactor() {
        this.updateFactors.emit(this.factor);
    }

}

import {Component, ComponentRef, OnInit} from '@angular/core';
import {Modal} from '../../../modal';
import {SubstitutionService} from '../../substitution.service';

@Component({
  selector: 'mic-substitution-modal',
  templateUrl: './substitution-modal.component.html',
  styleUrls: ['substitution-modal.component.scss']
})
export class SubstitutionModalComponent implements Modal, OnInit {

  constructor(protected substitutionService: SubstitutionService) { }

  componentRef: ComponentRef<any>;
  messages: Array<string> = [];
  isConfirm: boolean;

  ngOnInit() {
    this.messages = this.substitutionService.getWarningMessages();
  }

  onConfirm() {
    this.isConfirm = true;
    this.componentRef.destroy();
  }

  onClose() {
    this.componentRef.destroy();
  }

}

import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
  CardComponentService,
  EditablePanelContentComponent,
  PanelService,
  PanelSignal,
  SessionService
} from '@rgi/digital-claims-common-angular';
import {InitPotentialPaymentEntity} from '../domain/init-potential-payment-entity';
import {PotentialPaymentEntity} from '../domain/potential-payment-entity';
import {NewPaymentService} from '../new-payment.service';
import {SubjectEntity} from '../domain/subject-entity';
import {BeneficiaryTypeEntity} from '../domain/beneficiary-type-entity';
import {PaymentConstants, TypeEnum} from '../domain/payment-constants';
import {ENUMSLISTPAYMENT} from '../domain/payment-constants.config';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 'claims-beneficiary-payee',
  templateUrl: './beneficiary-payee.component.html',
  styleUrls: ['./beneficiary-payee.component.scss']
})
export class BeneficiaryPayeeComponent extends EditablePanelContentComponent implements OnInit {
  @Output() stepperEventEmitter = new EventEmitter<number>();
  @Output() bodyworkEvent = new EventEmitter<any>();

  @Input() potPayFE: InitPotentialPaymentEntity;
  @Input() public card: any;
  private partyDetailLastSession: any;


  public beneficiaryTypeMap: Map<string, string>;
  public showPayeeMap: Map<PotentialPaymentEntity, boolean> = new Map();
  public lockedConfMap: Map<PotentialPaymentEntity, boolean> = new Map();

  errorMessageBen: string;
  errorMessagePayee: string;
  errorMessageConfirm: string;
  paymentIdForAddBenef: number;
  eTipoPagamentoSpeseLegali: TypeEnum;
  eTipoPagamentoSpeseCtu: TypeEnum;
  eTipoPagamentoSpeseCtp: TypeEnum;
  eTipoPagamentoImpRegistro: TypeEnum;
  eTipoPagamentoOnorariSeparati: TypeEnum;
  eTipoBeneFAll: TypeEnum[] = [];

  constructor(
    panelService: PanelService,
    private newPaymentService: NewPaymentService,
    @Inject('sessionService') private sessionService: any,
    @Inject('AnagFinderSubject') private anagFinderSubject: any,
    @Inject('claimsSessionStatus') private claimsSessionStatus: any,
    @Inject('authService') private authService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('enumService') private enumService: any,
    @Inject('eventService') private eventService: any,
    @Inject('coreResult') private coreResult: any,
    private sessionServiceComm: SessionService,
    public translateService: RgiRxTranslationService,
    private cardComponentService: CardComponentService,
    private paymentConstants: PaymentConstants,
  ) {
    super(panelService);
  }

  initEnums() {
    this.eTipoBeneFAll = this.paymentConstants.ETIPOBENEF_ALL();
    this.eTipoPagamentoSpeseLegali = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_SPESE_LEGALI);
    this.eTipoPagamentoSpeseCtu = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_SPESE_CTU);
    this.eTipoPagamentoSpeseCtp = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_SPESE_CTP);
    this.eTipoPagamentoImpRegistro = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_IMPOSTE_DI_REGISTRO);
    this.eTipoPagamentoOnorariSeparati = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOQUIETANZA_ONORARI_SEPARATI);
  }

  ngOnInit() {
    this.initEnums();
    this.beneficiaryTypeMap = new Map();
    this.eTipoBeneFAll.
    forEach(elem => this.beneficiaryTypeMap.set(elem.eTypeCode, elem.eTypeDescription));
    console.log(this.potPayFE.paymentList);
  }

  subjectModify(beneficiary: SubjectEntity, event: any) {
    const that = this;
    const nodeId = this.potPayFE.nodoFittizioSx;
    const request = {
      idSubject: beneficiary.idSubjectPA,
      idNode: nodeId,
      role: '888'

    };
    this.eventService.broadcastEvent('start-loader');
    this.anagFinderSubject.get(request).$promise.then(result => {
      if (result && result.subject) {

        /*      var idNavigation = sessionService.open(ANAG_CARD_MANAGE.CARD_NAME, 'home', '', true);
                coreResult.setResult(idNavigation, 'newSubj', {
                    subject: $scope.subject
                }); */
        const idNavigation = that.sessionService.open('anagCardManage', 'home', '', true, null, null, that.card.idSession);
        const idSession = that.sessionServiceComm.idSessionFromId(idNavigation);
        this.partyDetailLastSession = idSession;

        this.sessionServiceComm.registerSessionDeathCallback(idSession, () => {
          that.refreshParty(beneficiary);
        });
        that.coreResult.setResult(idNavigation, 'newSubj', {
          subject: result.subject,
          subjectRole: '888'
        });
        that.coreResult.setResult(idNavigation, 'previousSession', {
          sessionId: that.card.idSession
        });
      }
      this.eventService.broadcastEvent('stop-loader');
    });
    event.stopPropagation();
    return false;
  }

  refreshParty(beneficiary: SubjectEntity) {
    // const that = this;
    // const nodeId = this.authService.getSalePointId();
    // const request = {
    //   idSubject: beneficiary.idSubjectPA,
    //   idNode: nodeId
    // };
    this.eventService.broadcastEvent('start-loader');
    this.newPaymentService.updateSubject(beneficiary,
      this.authService.getOperator().liquidationCentre.code,
      this.authService.getOperator().objectId,
      this.potPayFE.claimNumber).subscribe((benefUpd: SubjectEntity) => {
        console.log('updateSubject ', benefUpd);
        this.potPayFE.paymentList.forEach(aPay => {
          // aPay.listBeneficiary.push(benefUpd);
          const benVis = aPay.listBeneficiary.find(aBen => aBen.idSubject === beneficiary.idSubject);
          const percVis = aPay.listPayee.find(aBen => aBen.idSubject === beneficiary.idSubject);
          if (benVis) {
            this.updateDataSubjectFE(benVis, benefUpd);
          } else if (percVis) {
            this.updateDataSubjectFE(percVis, benefUpd);
          }
        });

        this.eventService.broadcastEvent('stop-loader');
      },
      (error: any) => {
        this.eventService.broadcastEvent('stop-loader');
      });
    // const updatedParty = this.claimsSessionStatus.getSessionStatus(this.partyDetailLastSession, 'partyUpdated');
    // if (updatedParty && updatedParty.objectId === beneficiary.idSubjectPA) {
    //   // this.sessionService.remove(this.partyDetailLastSession);
    //   // this.sessionService.setActive(this.card.idSession);
    //   this.eventService.broadcastEvent('start-loader');

    //   this.anagFinderSubject.get(request).$promise.then(result => {
    //     if (result && result.subject) {
    //       beneficiary = that.partyTranslatorService.anagSubjectToPartyDto(result.subject);
    //     }
    //     that.eventService.broadcastEvent('stop-loader');
    //   });
    // }
  }

  updateDataSubjectFE(soggOld: SubjectEntity, soggNew: SubjectEntity) {
    soggOld.name = soggNew.name;
    soggOld.address = soggNew.address;
    soggOld.birthDate = soggNew.birthDate;
    soggOld.country = soggNew.country;
    soggOld.fiscalCode = soggNew.fiscalCode;
    soggOld.lastName = soggNew.lastName;
    soggOld.municipality = soggNew.municipality;
    soggOld.municipalityCode = soggNew.municipalityCode;
    soggOld.number = soggNew.number;
    soggOld.postalCode = soggNew.postalCode;
    soggOld.stateCode = soggNew.stateCode;
    soggOld.subMunicipality = soggNew.subMunicipality;
    soggOld.vat = soggNew.vat;
    soggOld.idSubject = soggNew.idSubject;
  }

  onConfirm() {
    if (this.validateContent()) {
      this.storeData();

      this.setPanelComplete();
      this.disablePanelContent();
      this.stepperEventEmitter.emit(3);
      this.signalToNextPanel(PanelSignal.open);
      this.signalToNextPanel(PanelSignal.init);
    }
  }

  protected validateContent(): boolean {
    this.errorMessageConfirm = '';
    const listFunz = this.authService.getOperator().enabledFeatures;
    let permissionReceiptsNoCF = false;
    let permissionNoCF = false;

    if (listFunz && listFunz.indexOf('SXQCFM') >= 0) {
      permissionReceiptsNoCF = true;
    }
    if (listFunz && listFunz.indexOf('SXCPM') >= 0) {
      permissionNoCF = true;
    }
    for (let i = 0; i < this.potPayFE.paymentList.length; i++) {
      const payment = this.potPayFE.paymentList[i];
      // only if percipient is enabled
      if (payment.listPayee.length > 1) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYMENT_NUMBER').subscribe(res=> {
          this.errorMessageConfirm=res;
        })
        this.errorMessageConfirm= this.errorMessageConfirm;
        this.translateService.translate('_CLAIMS_._MESSAGE_._ONE_PAYEE_IS_REQUIRED').subscribe(res=> {
          if (this.errorMessageConfirm) {
            this.errorMessageConfirm=this.errorMessageConfirm+res;
          } else {
            this.errorMessageConfirm = res;
          }
        })
        return false;
      }
      if ((payment.listBeneficiary.length < 1 || payment.listPayee.length < 1)) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYMENT_NUMBER').subscribe(res=> {
          this.errorMessageConfirm=res;
        })
        this.errorMessageConfirm= this.errorMessageConfirm;
        this.translateService.translate('_CLAIMS_._MESSAGE_._ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED').subscribe(res=> {
          if (this.errorMessageConfirm) {
            this.errorMessageConfirm = this.errorMessageConfirm + res;
          } else {
            this.errorMessageConfirm = res;
          }
        })
        return false;

      }
      if (payment.listBeneficiary.filter(subject => subject.primary === true).length !== 1) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYMENT_NUMBER').subscribe(res=> {
          this.errorMessageConfirm=res;
        })
        this.errorMessageConfirm= this.errorMessageConfirm;
        this.translateService.translate('_CLAIMS_._MESSAGE_._ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED').subscribe(res=> {
          if (this.errorMessageConfirm) {
           this.errorMessageConfirm=this.errorMessageConfirm+res;
          } else {
            this.errorMessageConfirm = res;
          }
        })
        return false;
      }
      if (payment.listPayee.filter(subject => subject.primary === true).length !== 1) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYMENT_NUMBER').subscribe(res=> {
          this.errorMessageConfirm=res;
        })
        this.errorMessageConfirm= this.errorMessageConfirm;
        this.translateService.translate('_CLAIMS_._MESSAGE_._ONE_PRIMARY_PAYEE_REQUIRED').subscribe(res=> {
          if (this.errorMessageConfirm) {
           this.errorMessageConfirm=this.errorMessageConfirm+res;
          } else {
            this.errorMessageConfirm = res;
          }
        })
        return false;
      }
      if (payment.listBeneficiary
          .some(subject => subject.country === 'IT' &&
            (subject.fiscalCode == null || subject.fiscalCode === '') &&
            (subject.vat == null || subject.vat === '')) ||
        (payment.listPayee
          .some(subject => subject.country === 'IT' &&
            (subject.fiscalCode == null || subject.fiscalCode === '') &&
            (subject.vat == null || subject.vat === '')))) {
        if (!permissionNoCF && (!permissionReceiptsNoCF || (permissionReceiptsNoCF && payment.generatePayment))) {
          this.translateService.translate('_CLAIMS_._MESSAGE_._PAYMENT_NUMBER').subscribe(res=> {
            this.errorMessageConfirm=res;
          })
          this.errorMessageConfirm= this.errorMessageConfirm + (i+1);
          this.translateService.translate('_CLAIMS_._MESSAGE_._TAX_CODE__VAT_NUMBER_MISSING').subscribe(res=> {
             this.errorMessageConfirm=this.errorMessageConfirm+res;
          })

          return false;
        }
      }
      // Il percipiente deve essere sempre un soggetto maggiorenne
      if (payment.listPayee.some(subject => this.isMinorenne(subject) && !this.isPagamentoMinoreAbilitato())) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYMENT_NUMBER').subscribe(res=> {
          this.errorMessageConfirm=res;
        })
        this.errorMessageConfirm= this.errorMessageConfirm;
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYEE_MUST_BE_ADULT').subscribe(res=> {
          if (this.errorMessageConfirm) {
           this.errorMessageConfirm=this.errorMessageConfirm+res;
          } else {
            this.errorMessageConfirm = res;
          }
        })
        return false;
      }

      if (payment.listPayee.some(subject => !subject.idSubjectPA)) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION').subscribe(res=> {
          this.errorMessageConfirm=res;
        })
        return false;
      }
      if (payment.listBeneficiary.some(subject => !subject.idSubjectPA)) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION').subscribe(res=> {
          this.errorMessageConfirm=res;
        })
        return false;
      }
    }
    return true;
  }

  protected storeData() {
    this.newPaymentService.savePhotoInitPotentialPayment(this.potPayFE)
      .subscribe((responseSave: any) => {
        console.log('savePhotoInitPotentialPayment - outcome:  ', responseSave);
      });
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (this.acceptSignals) {
      if (panelSignal === PanelSignal.open) {
        console.log('Doing beneficiary and payee open action.');
        this.potPayFE.paymentList.forEach(payment => {
          // per ogni pagamento
          // recupero tipi beneficiario disponibili
          const isDamagedPresent = payment.listBeneficiary.some(beneficiary => beneficiary.type === 'IJR');
          this.newPaymentService.getBeneficiaryTypes(this.potPayFE.uuid, payment.paymentType, String(isDamagedPresent))
            .subscribe((beneficiaryTypes: Array<BeneficiaryTypeEntity>) => {
              payment.beneficiaryTypeList = beneficiaryTypes;
              // console.log(payment.beneficiaryTypeList);
            });
          // visualizzo sezione percipienti
          if (payment.paymentType === this.eTipoPagamentoSpeseLegali.eTypeCode ||
            payment.paymentType === this.eTipoPagamentoSpeseCtu.eTypeCode ||
            payment.paymentType === this.eTipoPagamentoSpeseCtp.eTypeCode ||
            payment.paymentType === this.eTipoPagamentoImpRegistro.eTypeCode ||
            payment.receiptType === this.eTipoPagamentoOnorariSeparati.eTypeCode) {
            this.showPayeeMap.set(payment, false);
            // ed eventualmente altre azioni
          } else {
            this.showPayeeMap.set(payment, true);
          }

          // forza configurazione default
          if (payment.paymentType === this.eTipoPagamentoSpeseLegali.eTypeCode ||
            payment.paymentType === this.eTipoPagamentoSpeseCtu.eTypeCode ||
            payment.paymentType === this.eTipoPagamentoSpeseCtp.eTypeCode ||
            !payment.editableBenefPayee) {
            this.lockedConfMap.set(payment, true);
          } else {
            this.lockedConfMap.set(payment, false);
          }

          if (!payment.listPayee || payment.listPayee.length === 0) {
            // payment.listPayee.splice(0, payment.listPayee.length);
            payment.listPayee.push(...payment.listBeneficiary);
          }
        });
        this.enableAndOpenContent();
        this.setPanelIncomplete();
      }
      if (panelSignal === PanelSignal.init) {
        console.log('Doing beneficiary and payee init action.');
      }
    }
  }

  isPayee(payment: PotentialPaymentEntity, beneficiary: SubjectEntity) {
    return payment.listPayee.findIndex(sg => sg.idSubject === beneficiary.idSubject) >= 0;
  }

  addBeneficiary(payment: PotentialPaymentEntity) {
    this.newPaymentService.paymentIdForAddBenef = this.potPayFE.paymentList.indexOf(payment);
    this.newPaymentService.subjectRole = 'beneficiary';
    const nodeId = this.authService.getSalePointId();
    console.log('>>>>>> addBeneficiary nodo', nodeId);
    this.cardComponentService.emit({
      eventName: 'add-beneficiary',
      salePointId: nodeId
    }, this.card.id);
  }

  onPercipientChange(event: any, payment: PotentialPaymentEntity, beneficiary: SubjectEntity) {
    if (event.target.checked) {
      if (this.pushPayee(payment, beneficiary)) {
        event.target.checked = true;
      } else {
        event.target.checked = false;
      }
    } else {
      payment.listPayee = payment.listPayee.filter(elem => elem !== beneficiary);
    }
  }

  isMinorenne(subject: SubjectEntity) { // birthday is the date already in milliseconds
    if (subject.birthDate && !subject.vat) {
      const birthday = subject.birthDate;
      const eighteenYears = 567648000000;
      const ageDifMs = Date.now() - new Date(birthday).getTime();
      return ageDifMs < eighteenYears;
    } else {
      return false;
    }
  }

  isPagamentoMinoreAbilitato() {
    const listFunz = this.authService.getOperator().enabledFeatures;
    if (listFunz && listFunz.indexOf('SXPMIN') >= 0) {
      return true;
    }
    return false;
  }

  addPayee(payment: PotentialPaymentEntity) {
    console.log(this.potPayFE);
    this.newPaymentService.paymentIdForAddBenef = this.potPayFE.paymentList.indexOf(payment);
    this.newPaymentService.subjectRole = 'payee';
    const nodeId = this.authService.getSalePointId();
    console.log('>>>>>> addPayee nodo', nodeId);
    this.cardComponentService.emit({
      eventName: 'add-beneficiary',
      salePointId: nodeId
    }, this.card.id);
  }

  pushPayee(payment: PotentialPaymentEntity, payee: SubjectEntity): boolean {
    if (this.isMinorenne(payee) && !this.isPagamentoMinoreAbilitato()) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._PAYEE_MUST_BE_ADULT').subscribe(res=> {
        alert(res);
      }
      )
      return false;
    } else {
      payment.listPayee.push(payee);
      return true;
    }
  }

  onCheckboxChange(payment: PotentialPaymentEntity, beneficiary: SubjectEntity) {
    payment.listBeneficiary.filter(elem => elem !== beneficiary).forEach(elem => elem.primary = false);
  }

  removeBeneficiary(payment: PotentialPaymentEntity, beneficiary: SubjectEntity) {
    if (payment.listBeneficiary.length > 1) {
      const index = payment.listBeneficiary.indexOf(beneficiary);
      if (index > -1) {
        payment.listBeneficiary.splice(index, 1);
      }
    } else {
      this.translateService.translate('_CLAIMS_._MESSAGE_._BEFORE_DELETE_ADD_NEW_BENEFICIARY').subscribe(res=> {
        alert(res);
      }
      )
    }
  }

  removePayee(payment: PotentialPaymentEntity, payee: SubjectEntity) {
    if (payment.listPayee.length > 1) {
      const index = payment.listPayee.indexOf(payee);
      if (index > -1) {
        payment.listPayee.splice(index, 1);
      }
    } else {
      this.translateService.translate('_CLAIMS_._MESSAGE_._BEFORE_DELETE_ADD_NEW_PAYEE').subscribe(res=> {
        alert(res);
      }
      )
    }
  }

  isAntiFinancialCrimes(party: SubjectEntity): boolean {
    if (party != null && party.statusAntiFinancialCrimes != null) {
      switch (party.statusAntiFinancialCrimes) {
        case '2':
          party.statusAntiFinancialCrimesDescr = 'Signaled subject';
          return true;
        case '3':
          party.statusAntiFinancialCrimesDescr = 'Blocked subject';
          return true;
        default:
          party.statusAntiFinancialCrimesDescr = '';
          return false;
      }
    }
    return false;
  }

}

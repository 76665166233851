import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {QuotationPremium} from '../../../models/domain-models/quotation';
import {Observable} from 'rxjs';
import {ReIssueRefactorService} from '../../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service';
import {Currency} from '../../../models/domain-models/parameters/currency';
import {ReIssueQuotationState} from '../../re-issue-state-manager/re-issue-quotation.state';

@Component({
  selector: 're-issue-quotation-controls',
  templateUrl: './re-issue-quotation-controls.component.html',
  styleUrls: ['./re-issue-quotation-controls.component.css']
})
export class ReIssueQuotationControlsComponent implements OnInit {
  @ViewChild('premiumElement')
  premiumElement: ElementRef<HTMLDivElement>;
  showViewBoxesMenu = true;
  isDiscountable = false;
  isPremiumElementFixedInPage = false;
  tolleranzaDiscout: number;
  currency: Currency;

  @Input() state: Observable<ReIssueQuotationState>;

  quotationPremium: QuotationPremium;

  premiumBeforeDiscount: number;

  @Input()
  recalculateAvailable: boolean;

  @Input()
  isUnique: boolean;

  @Output()
  recalculate = new EventEmitter<any>();

  @Output()
  unitsViewMode: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  showPremiumDetails: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showContributionsAndTaxes: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showFPSTaxes: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showCommissions: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showWarrantiesSummary: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showDiscounts: EventEmitter<any> = new EventEmitter<any>();
  instanceCounter: any;

  constructor(
    protected refactorService: ReIssueRefactorService
  ) {

  }

  ngOnInit() {
    this.state.subscribe(st => {
      this.instanceCounter = 0;
      this.tolleranzaDiscout = 5;
      this.quotationPremium = st.quotation.premium;
      this.premiumBeforeDiscount = 0;
      this.currency = st.policyTechnicalData.selectedCurrency;
      this.getPremiumBeforeDiscount(st);
      if (this.instanceCounter > 1) {
        this.isDiscountable = true;
      }
    });

  }


  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (this.premiumElement) {
      if (window.pageYOffset > this.premiumElement.nativeElement.offsetTop) {
        this.isPremiumElementFixedInPage = true;
      } else {
        this.isPremiumElementFixedInPage = false;
      }
    }
  }


  recalculatePremium(event: any) {
    this.recalculate.emit(event);
  }

  viewBoxUnits() {
    this.unitsViewMode.emit('BOX');
    this.showViewBoxesMenu = false;
  }

  viewListUnits() {
    this.unitsViewMode.emit('LIST');
    this.showViewBoxesMenu = true;
  }

  openPremiumDetailsModal() {
    this.showPremiumDetails.emit();
  }

  openContributionsAndTaxesModal() {
    this.showContributionsAndTaxes.emit();
  }

  openFPSTaxesModal() {
    this.showFPSTaxes.emit();
  }

  openCommissionsModal() {
    this.showCommissions.emit();
  }

  openWarrantiesSummaryModal() {
    this.showWarrantiesSummary.emit();
  }

  openDiscountsModal() {
    this.showDiscounts.emit();
  }

  isPremiumBeforeDiscount() {
    // tslint:disable-next-line:max-line-length
    if (this.premiumBeforeDiscount > 0 && (Math.abs(this.premiumBeforeDiscount - Math.ceil(parseFloat(this.quotationPremium.productPremium.annual.premium.gross))) < this.tolleranzaDiscout)) {
      return false;
    }
    return true;
  }


  protected getPremiumBeforeDiscount(st: ReIssueQuotationState) {
    st.quotation.assetList.assets.forEach(asset => {
      if (asset.instances) {
        asset.instances.forEach(instance => {
          this.instanceCounter++;
          if (instance.sections) {
            instance.sections.forEach(section => {
              if (section.unitList) {
                section.unitList.forEach(unit => {
                  if (unit.factors && unit.selected) {
                    unit.factors.forEach(variable => {
                      if (variable.code === '3PRLAS') {
                        this.premiumBeforeDiscount += Number(variable.value);
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MinisterialBranchesConstants {
    public COD_RAMO_INFORTUNI = '000001';
    public COD_RAMO_MALATTIA = '000002';
    public COD_RAMO_CVT = '000003';
    public COD_RAMO_CVF = '000004';
    public COD_RAMO_CVA = '000005';
    public COD_RAMO_CVM = '000006';
    public COD_RAMO_MERCITRASPORTATE = '000007';
    public COD_RAMO_INCENDIO = '000008';
    public COD_RAMO_ALTRIDANNI = '000009';
    public COD_RAMO_RCVEICOLITERR = '000010';
    public COD_RAMO_RCAEROMOBILI = '000011';
    public COD_RAMO_RCVEICOLIMARITT = '000012';
    public COD_RAMO_RCGENERALE = '000013';
    public COD_RAMO_CREDITO = '000014';
    public COD_RAMO_CAUZIONI = '000015';
    public COD_RAMO_PERDITEPEC = '000016';
    public COD_RAMO_TUTELAGIUDIZ = '000017';
    public COD_RAMO_ASSISTENZA = '000018';
}

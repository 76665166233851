import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RgiRxTooltipDirective } from './rgi-rx-tooltip.directive';
import {OverlayModule} from '@angular/cdk/overlay';
import { RgiRxTooltipComponent } from './rgi-rx-tooltip/rgi-rx-tooltip.component';
import {RGI_RX_TOOLTIP_CONFIG, RGI_RX_TOOLTIP_DEFAULT_CONFIG} from './rgi-rx-tooltip-api';



@NgModule({
    declarations: [RgiRxTooltipDirective, RgiRxTooltipComponent],
    imports: [
        CommonModule,
        OverlayModule
    ],
    exports: [RgiRxTooltipDirective],
    providers: [
        {
            provide: RGI_RX_TOOLTIP_CONFIG,
            useValue: RGI_RX_TOOLTIP_DEFAULT_CONFIG
        }
    ]
})
export class RgiRxTooltipModule { }



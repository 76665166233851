import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AnswerFlatI, QuestionFlatI, QuestionnaireFlatI } from '@rgi/ng-passpro';
import { Questionnaire, SurveyVersionService } from '@rgi/questionnaires-manager';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyVersionLifeService extends SurveyVersionService {

  constructor(
    _datePipe: DatePipe,
    protected rgiRxTranslationService: TranslationWrapperService
  ) {
    super(_datePipe);
  }

  override makeSurveyVersion(questionnaire: Questionnaire, questFlat: QuestionnaireFlatI) {

    questFlat.description = this.rgiRxTranslationService.getImmediate(questFlat.description);
    questFlat.questions = questFlat.questions.map(quest => {
      quest.section.name = this.rgiRxTranslationService.getImmediate(quest.section.name);
      quest.text = this.rgiRxTranslationService.getImmediate(quest.text);
      this.iterOnAnswers(quest);
      return quest;
    });
    return super.makeSurveyVersion(questionnaire, questFlat);
  }


  protected iterOnAnswers(quest: QuestionFlatI) {
    quest.answers = quest.answers.map(answer => {
      this.translateAnswerValues(answer);
      return answer;
    });
  }

  protected translateAnswerValues(answer: AnswerFlatI) {
    answer.values = answer.values.map(value => {
      value.description = this.rgiRxTranslationService.getImmediate(value.description);
      return value;
    });
  }

}

import {GenericEntity} from '../domain-models/generic-entity';
import {ApiAssetType} from './api-asset-type';

export class ApiProduct extends GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public assets: Array<ApiAssetType>,
    public extendedDescription: string
  ) {
    super(id, code, description);
  }
}

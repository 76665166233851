import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SurveySearchFilter} from './model/survey-filters';
import {SurveyService} from './survey.service';



@NgModule({
  declarations: [],
  exports: [
  ],
  imports: [
    CommonModule
  ]
})
class SurveyModule {
}

export {
  SurveyModule,
  SurveyService,
  SurveySearchFilter
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CircumstanceMultiselect,
  OutputLiabilityDetermination,
  OutputLiabilityDeterminationV2
} from '../dsc-circumstances-model/dsc-circumstances.config';
import { Observable } from 'rxjs';
import { OutputBaremList } from '../dsc-barems-modal/dsc-barems-model/dsc-barems.config';

@Injectable({
    providedIn: 'root'
})
export class DscCirumstanceService {

    private baseApiUrl: string;
    private v2Url: string;
    private urlCircumstanceMultiselectComponent = '/claims/setCircumstancesDataCombo';
    private urlBaremsList = '/claims/barem';
    private urlCalculateLiability = '/claims/liability';

    constructor(
        private httpClient: HttpClient,
        @Inject('environment') environment: any) {
        this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
        this.v2Url = '/v2';
    }

    /**
     * Servizio che torna i valori delle multiselect presenti nella sezione delle Circostanze
     */
    populateCircumstanceMultiselectComponent(): Observable<CircumstanceMultiselect> {
        return this.httpClient.post<CircumstanceMultiselect>(this.baseApiUrl + this.v2Url + this.urlCircumstanceMultiselectComponent, {});
    }

    /**
     * Servizio che torna la lista dei barems
     */
    baremsList(): Observable<OutputBaremList> {
        return this.httpClient.post<OutputBaremList>(this.baseApiUrl + this.urlBaremsList, {});
    }

    calculateLiability(baremAObjectId: string, baremBObjectId: string, policyNumber: string): Observable<OutputLiabilityDeterminationV2> {
        return this.httpClient.get<OutputLiabilityDeterminationV2>(this.baseApiUrl + this.v2Url + this.urlCalculateLiability
            + `?idBaremCaseA=${baremAObjectId}&idBaremCaseB=${baremBObjectId}&policyNumber=${policyNumber}`);
    }

}

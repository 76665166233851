import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {SurveyVersionI} from '../models/survey-version';
import {EvaluatorProductI} from '../evaluation/models';
import {catchError, map, tap} from 'rxjs/operators';
import {PrintParameters, SurveyEvaluateFilter, SurveyExportFilter, SurveySearchFilter} from "./model/survey-filters";
import {
    EnvironmentService,
    NotificationService,
    PassproproDownloadService,
    ResponseList,
    ResponseResult
} from '@rgi/ng-passpropro-core';
import {SurveyPrintFactory} from "./survey.print.factory";
import {AnswerFlatI, QuestionFlatI} from '@rgi/ng-passpro'
import {SurveyInputFactor, SurveyIntegrationFilter} from '../integration/integration.module';
import {EvaluatedItem} from "@rgi/ng-passpropro";
import {AnswerType} from "@rgi/ng-passpropro/questionnaire";

const RESOURCE = {
    basePath: '/questionnaires',
};


export interface ResponseResultEvaluation extends ResponseResult<EvaluatorProductI[]> {
    evaluations: EvaluatedItem[]
}


@Injectable({
    providedIn: 'root'
})
export class SurveyService {

    private SURVEY_BASEPATH;

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private downloadService: PassproproDownloadService,
                private notifcationService: NotificationService
    ) {
        this.SURVEY_BASEPATH = this.environmentService.environmentUrl + "/questionnaires/survey";
    }

    /**
     * @deprecated
     * @param idSurvey
     */
    getSurveyVersions(idSurvey: number): Observable<Array<SurveyVersionI>> {
        const path = `/survey/versions/${idSurvey}`;
        const url = this.environmentService.environmentUrl + RESOURCE.basePath + path;
        const $resp = this.http.get(url, {}).pipe(
            map(response => {
                return response && response[`list`];
            })
        );
        return $resp as Observable<Array<SurveyVersionI>>;
    }

    getSurveyVersionsBy(filter: SurveySearchFilter): Observable<any> {
        return <Observable<Array<SurveyVersionI>>>this.http.put(this.environmentService.environmentUrl + "/v2/survey/filter", {filter: filter})
            .pipe(
                map((response: any) => response.list));
    }

    /**
     * @param surveyVersion
     * @param params
     */
    getPrint(surveyVersion: SurveyVersionI, params: PrintParameters = {parameters: {}}): Observable<Blob> {
        const path = `${this.environmentService.environmentUrl}/v2/survey/version/${surveyVersion.uuid}/print`;
        return this.http.put(path, params, {responseType: 'blob', headers: {"content-type": "application/json"}});
    }

    /**
     * @deprecated
     * @param surveyExport
     */
    getPrintByUuid(surveyExport: SurveyExportFilter): Observable<Blob> {
        const path = `${this.SURVEY_BASEPATH}/export/byuuid`;
        return this.http.put(path, {surveyExport: surveyExport}, {responseType: 'blob'});
    }

    /**
     * @deprecated
     * @param surveyVersion
     */
    print(surveyVersion: SurveyVersionI) {
        const path = `${this.SURVEY_BASEPATH}/export/${surveyVersion.id}`;
        const request = new HttpRequest('GET', path, {responseType: 'blob'});
        this.downloadService.openBlob(request);
    }

    /**
     * @deprecated
     * @param surveyVersion
     */
    printByUuid(surveyExportFilter: SurveyExportFilter) {
        const path = `${this.SURVEY_BASEPATH}/export/byuuid`;
        const request = new HttpRequest('PUT', path, {surveyExport: surveyExportFilter}, {responseType: 'blob'});
        this.downloadService.openBlob(request);
    }

    createSurvey(surveyVersion: SurveyVersionI): Observable<ResponseResult<SurveyVersionI>> {
        return this.http.post<ResponseResult<SurveyVersionI>>(`${this.environmentService.environmentUrl}/v2/survey`, surveyVersion)
            .pipe(
                tap(ev => {
                    this.notifcationService.success();
                })
            );
    }

    updateSurvey(surveyVersion: SurveyVersionI): Observable<ResponseResult<SurveyVersionI>> {
        return this.http.put<ResponseResult<SurveyVersionI>>(this.environmentService.environmentUrl + "/v2/survey/version", surveyVersion)
            .pipe(
                tap(ev => {
                    this.notifcationService.success();
                })
            );
    }

    createNewVersion(surveyVersion: SurveyVersionI): Observable<ResponseResult<SurveyVersionI>> {
        const url = `${this.environmentService.environmentUrl}/v2/survey/version`;
        return this.http.post<ResponseResult<SurveyVersionI>>(url, surveyVersion)
            .pipe(
                tap(ev => {
                    this.notifcationService.success();
                })
            )
    }


    protected getParamsByFilter(surveyEvaluateFilter: SurveyEvaluateFilter): any {
        const params: any = {};
        if (surveyEvaluateFilter.product) {
            params.product = [];
            surveyEvaluateFilter.product.forEach(p => params.product.push(p));
        }
        if (surveyEvaluateFilter.sources) {
            params.source = [];
            surveyEvaluateFilter.sources.forEach(p => params.source.push(JSON.stringify(p)));
        }
        return params;
    }


    evaluateSurvey(surveyEvaluateFilter: SurveyEvaluateFilter): Observable<ResponseResult<EvaluatorProductI[]>> {
        const params = this.getParamsByFilter(surveyEvaluateFilter)
        const url = `${this.environmentService.environmentUrl}/v2/survey/evaluation/${surveyEvaluateFilter.surveyVersion.uuid}`;
        return this.http.post<ResponseResult<EvaluatorProductI[]>>(url, {}, {params: params})
            .pipe(
                tap(ev => {
                    this.notifcationService.success();
                })
            )
    }


    evaluateEphemeral(surveyEvaluateFilter: SurveyEvaluateFilter): Observable<ResponseResult<EvaluatorProductI[]>> {
        const params = this.getParamsByFilter(surveyEvaluateFilter);
        //params.surveyVer = surveyEvaluateFilter.surveyVersion;
        const url = `${this.environmentService.environmentUrl}/v2/survey/evaluation/ephemeral`;
        return this.http.put<ResponseResult<EvaluatorProductI[]>>(url, surveyEvaluateFilter.surveyVersion, {params});
    }


    /**
     * return string with string 'NO_EVALUATION' when server return 409
     */
    getSurveyEvaluation(surveyVersion: SurveyVersionI): Observable<ResponseResult<EvaluatorProductI[]> | string> {
        const path = `/v2/survey/evaluation/${surveyVersion.uuid}`;
        const url = this.environmentService.environmentUrl + path;
        return this.http.get<ResponseResult<EvaluatorProductI[]>>(url).pipe(
            catchError(
                err => {
                    if (err instanceof HttpErrorResponse && err.status == 409) {
                        return of('NO_EVALUATION');
                    } else {
                        return throwError(err);
                    }
                }
            ),
        );
    }

    getValidSurveys(requestFilter: { versions: string[], questionnaireCode: string }): Observable<ResponseList<{ uuid: string, questionnaireCode: string, dateCreated: string }>> {
        const path = '/survey/valid';
        const url = this.environmentService.environmentUrl + RESOURCE.basePath + path;
        return this.http.put<ResponseList<{ uuid: string, questionnaireCode: string, dateCreated: string }>>(url, {
            filter: requestFilter
        });
    }

    setInputFactors(surveyVersion: SurveyVersionI, inputFactors: SurveyInputFactor[]) {
        const multiListMap: { [questionCode: string]: { idFactorValues: string[], answer: AnswerFlatI } } = {};

        inputFactors.forEach(
            factor => {
                surveyVersion.lastVersion.questionnaire.questions.forEach(
                    question => {
                        question.answers.forEach(
                            answer => {
                                if (!(answer.ppfactorCode === factor.code || answer.trascode === factor.code)) {
                                    return;
                                }

                                if ("" + answer.factorType === AnswerType.LIST) {
                                    const found = answer.values.find(val => val.ppFactorValue === factor.value || val.trascode === factor.value);
                                    if (!found) return;
                                    if (answer.multivalue) {
                                        if (!multiListMap[question.code]) {
                                            multiListMap[question.code] = {
                                                answer,
                                                idFactorValues: []
                                            }
                                        }
                                        multiListMap[question.code].idFactorValues.push(found.id);
                                    } else {
                                        answer.value = found.id;
                                    }

                                } else {
                                    answer.value = factor.value;
                                }
                            }
                        )
                    }
                )
            }
        );

        Object.values(multiListMap).forEach(
            ({idFactorValues, answer}) => {
                answer.value = idFactorValues.join(";");
            }
        );
    }

    isValueAlreadyPresentInMultivalue(answer: AnswerFlatI, value: string): boolean {
        if (answer.value === undefined || answer.value == null || answer.value.trim().length == 0) {
            return false;
        }

        return answer.value.split(";").filter(v => v.trim() === value.trim()).length > 0;
    }

    getQuestionsByFactors(surveyVersion: SurveyVersionI, inputFactors: Array<{ code: string, value: string }>): QuestionFlatI[] {
        return surveyVersion.lastVersion.questionnaire.questions
            .filter(
                question => {
                    return question.answers.filter(answer => {
                            return inputFactors.filter(inputFactor => {
                                return answer.ppfactorCode === inputFactor.code || answer.trascode === inputFactor.code
                            }).length > 0;
                        }
                    ).length > 0;
                });
    }

    addCustomPropertiesToQuestionnaire(surveyVersion: SurveyVersionI, factory: SurveyPrintFactory): void {

        if (!surveyVersion.lastVersion.questionnaire) {
            surveyVersion.lastVersion.questionnaire = {};
        }
        surveyVersion.lastVersion.questionnaire.customProperties = factory.build();
    }


    getQuestionsWithInputFactorsValueUpdated(surveyVersion: SurveyVersionI, inputFactors: Array<{ code: string, value: string }>): QuestionFlatI[] {
        const filteredQuest: QuestionFlatI[] = [];
        const multiListMap: { [questionCode: string]: { idFactorValues: string[], answer: AnswerFlatI, question: QuestionFlatI } } = {};

        inputFactors.forEach(factor => {
            surveyVersion.lastVersion.questionnaire.questions
                .forEach(
                    question => {
                        question.answers.forEach(answer => {
                            if (answer.ppfactorCode === factor.code || answer.trascode === factor.code) {
                                if ("" + answer.factorType === AnswerType.LIST) {
                                    const found = answer.values.find(val => val.ppFactorValue === factor.value || val.trascode === factor.value);
                                    if (!found) return;
                                    if (answer.multivalue) {
                                        if (!multiListMap[question.code]) {
                                            multiListMap[question.code] = {
                                                answer,
                                                idFactorValues: [],
                                                question
                                            }
                                        }
                                        multiListMap[question.code].idFactorValues.push(found.id);
                                    } else {
                                        if (answer.value != found.id) {
                                            filteredQuest.push(question);
                                            return;
                                        }
                                    }

                                } else if ("" + answer.factorType === AnswerType.DATE) {
                                    const v: any = answer.value;
                                    if (v) {
                                        const vTypeof = typeof (v);
                                        let d: Date = null;
                                        if (vTypeof == 'string') {
                                            const isInt = /^-?\d+$/.test(v);
                                            if (isInt) {
                                                d = new Date(parseInt(v));
                                            } else {
                                                d = new Date(v);
                                            }

                                        } else if (vTypeof == 'object' && typeof (v.toISOString) == 'function') {
                                            d = v;
                                        }
                                        if (d && !isNaN(d.getTime())) {
                                            var dateStr: String = d.toISOString().split('T')[0];
                                            if (dateStr !== factor.value) {
                                                console.log('DATE CHECK', factor.code, factor.value);
                                                filteredQuest.push(question);
                                            }
                                        }
                                    }

                                } else if (factor.value !== answer.value) {
                                    filteredQuest.push(question);
                                }
                            }

                        });
                    }
                );
        });

        Object.values(multiListMap).forEach(
            ({idFactorValues, answer, question}) => {
                const aswerValueStr = answer.value || '';
                const answerValues = aswerValueStr.split(';');
                if (idFactorValues.length != answerValues.length) {
                    filteredQuest.push(question);
                    return;
                }

                const idFactorValuesSer = idFactorValues
                    .map(v => typeof (v) == 'string' ? v.trim() : v)
                    .sort()
                    .join(',');

                const answerValuesSer = answerValues
                    .map(v => typeof (v) == 'string' ? v.trim() : v)
                    .sort()
                    .join(',');

                const changed = idFactorValuesSer != answerValuesSer;
                if (changed) {
                    filteredQuest.push(question);
                }

            }
        );
        console.log('Changed questions', filteredQuest);
        return filteredQuest;
    }


    validateEphemeral(evaluationProducts: EvaluatorProductI[], surveyVersion: SurveyVersionI): Observable<[EvaluatorProductI[], EvaluatedItem[]]> {
        const path = '/v2/survey/evaluation/ephemeral/validate';
        const url = this.environmentService.environmentUrl + path;
        return this.http.put<ResponseResultEvaluation>(url, {
            evaluationProducts,
            surveyVersion
        }).pipe(
            tap(
                resp => {
                    if (!resp.isSuccess && resp.messages) {
                        this.notifcationService.error(resp.messages[0]);
                    }
                }
            ),
            map(responseResult => [responseResult.result, responseResult.evaluations])
        );
    }


    updateEvaluation(surveyVersion: SurveyVersionI, evaluationProducts: EvaluatorProductI[]): Observable<[EvaluatorProductI[], EvaluatedItem[]]> {
        const path = `/v2/survey/evaluation/${surveyVersion.uuid}`;
        const url = this.environmentService.environmentUrl + path;
        return this.http.put<ResponseResultEvaluation>(url, {request: evaluationProducts})
            .pipe(
                tap((resp) => {
                    if (resp.isSuccess) {
                        this.notifcationService.success()
                    } else {
                        if (resp.evaluations && resp.result) {
                            this.notifcationService.warning(resp.messages[0]);
                        } else {
                            this.notifcationService.error(resp.messages[0]);
                        }
                    }
                }),
                map(responseResult => [responseResult.result, responseResult.evaluations])
            );
    }


    isSurveyVersionValid(surveyVersion: SurveyVersionI) {
        const dateEndStr = surveyVersion.dateEnd;
        const d = new Date(dateEndStr);
        if (!dateEndStr || isNaN(d.getTime())) {
            return true;
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return d >= today;
    }


    createSurveyEvaluationFilter(surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter): SurveyEvaluateFilter {
        const filter = new SurveyEvaluateFilter();
        filter.surveyVersion = surveyVersion;
        filter.sources = integrationFilter && integrationFilter.sources ? integrationFilter.sources : [];
        filter.product = integrationFilter ? integrationFilter.products : [];
        return filter;
    }

}

export class DocumentCategoryEntity {
  elementDescription: string;
  elementId: string;
  subcategories: Array<DocumentSubcategoryEntity>;
  type: string = 'category';
  isDeprecated: boolean;
  isUpload: boolean;
}

export class DocumentSubcategoryEntity extends DocumentCategoryEntity {
  type: string = 'subcategory';
}

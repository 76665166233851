<div *ngIf="showTitle" class="rgi-re-section-title">
  <span class="rgi-ui-icon-list-check rgi-re-header-icon"></span>
  <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.CLAUSES</span>&nbsp;
</div>

<form [formGroup]="clausesForm">
  <div *ngFor="let clause of clausesList">
    <rgi-rx-form-field *ngIf="clause.enable || clause.selected" class="rgi-ui-col">
      <!-- CLAUSE CHECKBOX -->
      <span><input attr.data-qa="{{clause.code}}" type="checkbox" rgiRxInput
                   formControlName="{{clause.code}}_selected"></span>
      <!-- CLAUSE DESCRIPTION -->
      <span><label rgiRxLabel>{{clause?.description}}</label></span>
      <!-- CLAUSE ICON AND MODAL OPENING -->
      <span *ngIf="clause.selected && clause.textType != 0"
            [ngClass]="{'icon-red': clause.selected && !clause.text && clause.mandatoryText}"
            (click)="openClause(clause)"
            attr.data-qa="{{clause.code}}_edit" class="icon rgifont rgi-pencil-square-o pull-right"></span>
    </rgi-rx-form-field>
    <!-- CLAUSE ERROR MANAGEMENT -->
    <div class="rgi-ui-grid">
      <div class="rgi-ui-col">
        <rgi-rx-snackbar tag="{{clause.code}}"></rgi-rx-snackbar>
      </div>
    </div>
  </div>
</form>

import {Address} from './address';

export class PolicyAddress {

  constructor(
    public address: Address,
    public main: boolean,
    public id: string
  ) {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimDocument } from '../domain/claim-document';

@Component({
  selector: 'claims-preview-doc-modal',
  templateUrl: './preview-doc-modal.component.html',
  styleUrls: ['./preview-doc-modal.component.scss']
})
export class PreviewDocModalComponent implements OnInit {

  @Input() document: ClaimDocument;
  fileUrl: SafeResourceUrl;
  constructor(
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    console.log('PreviewDocModalComponent onInit', this.document);
    const docType = this.document.file.split(',')[0];
    if (docType.indexOf('application/pdf') === -1) {
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.document.file);
    } else {
      // hide pdf browser toolbar, since the file has no name
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.b64toBlob(this.document.file.split(',')[1],
        'application/pdf')) + '#toolbar=0&navpanes=0&scrollbar=0');
    }
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  b64toBlob(b64Data: string, contentType: string): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

}

import {GenericEntity} from '../common/entities';

export class PolicyOperationsResponse {
  operationsOutput: {
    extension: any,
    operations: PncPolicyOperation[],
  };
}

export class PolicyOperationPayload {
  operationsInput:
    {
      userCode: string,
      productionNodeCode: string,
      authenticationNodeCode: string,
      objectId:
        {
          identification: string,
          description: string
        }
    };
}

export class PncPolicyOperation {
  extension: any;
  jumpCommand: string;
  type: string;
  menu: string;
  operation: GenericEntity;
}

export class PncPostSalesCreateDraftResponse {
  resourceId: string;
}

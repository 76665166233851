<rgi-rx-tab-header
  [ariaLabel]="ariaLabel"
>
  <div
    class="rgi-ui-tab"
    [id]="getTabId(i)"
    [attr.tabindex]="getTabIndex(tab, i)"
    [attr.aria-selected]="selectedIndex === i"
    [attr.aria-posinset]="i + 1"
    [attr.aria-setsize]="_allTabs.length"
    [active]="i === selectedIndex"
    [disabled]="tab.disabled"
    [label]="tab.labelTemplate ? undefined : tab.label"
    *ngFor="let tab of _allTabs; let i = index"
    (click)="tabClick(i, $event)"
    (keydown)="handleKeyDown($event, i)"
    role="tab"
    rgiRxTabWrapper
    cdkMonitorElementFocus
    (cdkFocusChange)="onTabFocusChange(i, $event)"
    [rgi-rx-qa]="getTabId(i)"
    [qa-value]="tab.label"
    qa-type="tab"
  >
    <ng-container [ngSwitch]="getLabelType(tab)">
      <ng-container *ngSwitchCase="'template'">
        <ng-container
          *ngTemplateOutlet="tab.labelTemplate.template"
        ></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ tab.label }}
      </ng-container>
    </ng-container>
  </div>
</rgi-rx-tab-header>

<ng-container *ngIf="!target">
  <ng-container
    *ngTemplateOutlet="tabBody; context: { $implicit: _allTabs }"
  ></ng-container>
</ng-container>

<ng-template let-ctx #tabBody>
  <ng-container *ngFor="let tab of ctx; let i = index">
    <rgi-rx-tab-body
      [id]="getTabBodyId(i)"
      [attr.aria-labelledby]="getTabId(i)"
      [tab]="tab"
      [position]="i"
      [active]="i === selectedIndex"
    ></rgi-rx-tab-body>
  </ng-container>
</ng-template>

<div class="rgi-re-section-title">
  <span class="rgi-re-header-icon rgi-re-icon-profiles"></span>
  <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.PARAMETERS</span>&nbsp;
  <span class="pull-right header-text"> </span>
</div>

<div class="re-issue-card-styles">
  <div class="rgi-ui-grid-2" *ngFor="let parameterRow of parametersRows">
    <div class="rgi-ui-col" *ngFor="let parameter of parameterRow">
      <re-issue-parameter-control (updateParameter)="onUpdateParameter($event)" [parameter]="parameter"
                                  [disabled]="true" [parameterControlForm]="parametersForm" [stateObs]="stateObs">
      </re-issue-parameter-control>
    </div>
  </div>
</div>


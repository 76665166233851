import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {LoadingIndicatorService} from '@rgi/rx';

@Component({
  selector: 'rgi-rx-portal-loading',
  templateUrl: './portal-loading-indicator.component.html'
})
export class PortalLoadingIndicatorComponent implements OnInit {

  isLoading$: Observable<boolean>;

  constructor(private loadingIndicatorService: LoadingIndicatorService) {
  }

  ngOnInit() {
    this.isLoading$ = this.loadingIndicatorService.hasPending$();
  }

}

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dummy" label="lpc_MovementData" [errors]="errors" fieldId="dummy" (next)="onNext($event, true)" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'">
    <table style="width:100%" [formGroup]="formGroup" class="settlement-table active">
      <thead>
      <tr>
        <th class="life-detail-table-width-35" translate>lpc_description</th>
        <th class="life-detail-table-width-20" translate>lpc_issueDate</th>
        <th class="life-detail-table-width-20" translate>lpc_effectiveDate</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="life-detail-table-width-35">{{ data.description }}</td>
        <td class="life-detail-table-width-20">{{ data.issueDate | date : 'dd/MM/yyyy' }}</td>
        <td class="life-detail-table-width-20">{{ data.effectiveDate | date : 'dd/MM/yyyy' }}</td>
      </tr>
      </tbody>
    </table>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="lpc_confirm_variation_step" id="documents"
            [errors]="errors" [nextVisible]="false">
    <div *ngIf="!!publishMessage">
      <p class="text-center">{{ publishMessage }}</p>
      <lpc-navigation-buttons (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'" [backVisible]="true"
                              [nextVisible]="false">
      </lpc-navigation-buttons>
    </div>
  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()"
                          [nextVisible]="false"
                          [qa]="'annulla'"></lpc-navigation-buttons>
</div>

import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {downgradeRootModule} from './ajs-downgrade';
import {LoggerFactory, RgiRxEventModule, RgiRxModule, RgiRxRuntimeError} from '@rgi/rx';
import {RGI_RX_ROUTER_LOCATION_GUARD, RgiRxRoutingModule, RouteComponentLoader} from '@rgi/rx/router';
import {PortalBindingProvider, PortalCards, RGI_RX_PORTAL_CARDS, UserBindingProvider} from './ajs-integration-api';
import {
  RGI_RX_AUTH_CONFIG,
  RgiRxAuthConfig,
  RgiRxAuthModule,
  RgiRxTokenProvider,
  RgiRxUserAuthorizationService,
  RgiRxUserService
} from '@rgi/rx/auth';
import {PortalUIModule} from './portal-ui/portal-ui.module';
import {RgiRxStatemanagerModule} from '@rgi/rx/state';
import {
  portal$TemplateCacheFactory,
  portalAuthFactory,
  portalCardRegistrationFactory
} from './portal-integration.factories';
import {portalLanguageChangeFactory, portalRgiRxTranslationsFactory} from './i18n/i18n-integration-factories';
import {RGI_RX_TRANSLATIONS, RgiRxTranslationCatalogService, RgiRxTranslationService} from '@rgi/rx/i18n';
import {PortalRouterComponentLoader} from './router/portal-router-component-loader';
import {PortalCardService, RgiRxPortalCardService} from './portal-card.service';
import {RGI_RX_HTTP_CLIENT_INTERCEPTOR, RgiRxHttpErrorAdapter, RgiRxHttpTracingProvider} from '@rgi/rx/http';
import {RgiHeadersInterceptor, RgiLangInterceptor} from './http/passportal-http-interceptors';
import {RgiRxPortalHttpErrorAdapter} from './http/rgi-rx-portal-http-error-adapter';
import {PortalConfig, RGI_RX_PORTAL_MERGED_CONFIG, RGI_RX_PORTAL_MERGED_CONFIG_PROVIDER} from './rgi-rx-portal-api';
import {RgiRxPortalDefaultTracingProvider} from './execution-id.provider';


const authConfig: RgiRxAuthConfig = {
  refreshTokenHeader: 'RGI_token'
};
@NgModule({
  declarations: [],
  imports: [
    RgiRxModule,
    RgiRxRoutingModule,
    RgiRxAuthModule,
    RgiRxEventModule,
    PortalUIModule,
    RgiRxStatemanagerModule
  ],
  exports: [
    PortalUIModule
  ],
  providers: [
    {
      provide: RgiRxHttpTracingProvider,
      useClass: RgiRxPortalDefaultTracingProvider
    }
  ]
})

/**
 * @module RgiRxPortal
 * Use this module to integrate RgiRxPortal with existing PassPortal apps.
 */
export class RgiRxPortalModule {
  constructor(
    @Inject(RGI_RX_PORTAL_MERGED_CONFIG) config: PortalConfig,
    translationService: RgiRxTranslationService,
    catalog: RgiRxTranslationCatalogService,
    tokenService: RgiRxTokenProvider,
    userService: RgiRxUserService,
    userAuthorizationService: RgiRxUserAuthorizationService,
    userBindingProvider: UserBindingProvider<any>,
    @Optional() @Inject(RGI_RX_PORTAL_CARDS) portalCardConfigs?: PortalCards,
    @Optional() @Inject(RGI_RX_ROUTER_LOCATION_GUARD) locationGuard?: boolean
  ) {
    const logger = LoggerFactory();
    if (!!locationGuard) {
      throw new RgiRxRuntimeError(`Detected activation of RgiRxRouterLocation! Please remove any import of RgiRxRouterLocationModule since it's not supported in Portal applications!`);
    }
    downgradeRootModule(config);
    portalLanguageChangeFactory(config, translationService, catalog);
    portalCardRegistrationFactory(config, logger, portalCardConfigs);
    portal$TemplateCacheFactory(config);
    portalAuthFactory(config, tokenService, userService, userAuthorizationService, userBindingProvider, logger);
  }
  /**
   * Register the module with it's providers
   * @param cards The cards to be registered with the @rgi/rx/portal bindings
   */
  static forRoot(cards: PortalCards): ModuleWithProviders<RgiRxPortalModule> {
    return {
      ngModule: RgiRxPortalModule,
      providers: [
        {
          provide: PortalCardService,
          useExisting: RgiRxPortalCardService
        },
        RGI_RX_PORTAL_MERGED_CONFIG_PROVIDER,
        {
          provide: RGI_RX_PORTAL_CARDS,
          useValue: cards,
          multi: true
        },
        {
          provide: RouteComponentLoader,
          useClass: PortalRouterComponentLoader
        },
        {provide: RGI_RX_TRANSLATIONS, useFactory: portalRgiRxTranslationsFactory, multi: true, deps: [RGI_RX_PORTAL_MERGED_CONFIG]},
        {provide: RGI_RX_AUTH_CONFIG, useValue: authConfig},
        {
          provide: UserBindingProvider,
          useClass: PortalBindingProvider
        },
        {
          provide: RGI_RX_HTTP_CLIENT_INTERCEPTOR,
          useClass: RgiHeadersInterceptor,
          multi: true
        },
        {
          provide: RGI_RX_HTTP_CLIENT_INTERCEPTOR,
          useClass: RgiLangInterceptor,
          multi: true
        },
        {
          provide: RgiRxHttpErrorAdapter,
          useClass: RgiRxPortalHttpErrorAdapter
        }
      ]
    };
  }
}

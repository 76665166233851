import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';
import { LpcModalComponent } from '../modules/lpc-modal/lpc-modal/lpc-modal.component';
import { ModalMessage } from '../modules/lpc-modal/lpc-modal/lpc-modal.model';

@Injectable({
  providedIn: 'root'
})
export class CustomModalService {

constructor(
  protected modalService: NgbModal,
  protected translate: TranslationWrapperService,
) { }


public openModal(
    title: string,
    message: string,
    confirm: boolean = false,
    error: boolean = false,
    cancel: boolean = false,
    messages: ModalMessage[] = []): NgbModalRef {
    const modalRef: NgbModalRef = this.modalService.open(LpcModalComponent, {
      centered: true,
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    modalRef.componentInstance.title = this.translate.getImmediate(title);
    modalRef.componentInstance.message = !!message ? this.translate.getImmediate(message) : message;
    modalRef.componentInstance.confirmVisible = confirm;
    modalRef.componentInstance.closeVisible = cancel;
    modalRef.componentInstance.serviceError = error;
    modalRef.componentInstance.messages = messages;
    if (!cancel) {
      modalRef.componentInstance.confirmLabel = 'lpc_close_button';
    }
    return modalRef;
  }

}

import {
    PortalCard,
    PortalCardConfig,
    PortalCards,
  } from "@rgi/rx/portal";


  export const FIVA_PLATE_CTP_DETAIL: PortalCardConfig = {
    card: {
      title: "Fiva",
      name: "fiva.platectpdetail",
      category: "Claims",
      type: "sub",
      subCards: [],
      subCardsActive: [],
      navigationInSession: true,
    } as PortalCard,
    routes: [
      {
        route: "home",
        destination: "fiva.platectpdetail",
        label: "_CLAIMS_._FIVA_PLATE_CTP_DETAIL_TITLE",
        type: "home"
      },
    ],
  };


  export const FIVA_CARD: PortalCards = [
    FIVA_PLATE_CTP_DETAIL
  ];

import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {QuestionAnswerI} from '../../models/question-answer';
import {AnswerType} from '../../models/answer-type';

@Component({
  selector: 'ppevo-question',
  templateUrl: './question.component.html',
  encapsulation: ViewEncapsulation.None
})
export class QuestionComponent {

  @Input() question: QuestionAnswerI;

  @Output() change = new EventEmitter();
  @Output() clickQuestionInfoNotes = new EventEmitter<number>();

  answerType = AnswerType;

  constructor() { }

  onChange(value){
    this.change.emit(this.question);
  }

  getStyleClassesByAswerType(q:QuestionAnswerI){
    if(q && Array.isArray(q.answers) && q.answers.length>0)
      return {
        'boolean':  q.answers[0].answer.type==this.answerType.BOOLEAN,
        'numeric': q.answers[0].answer.type==this.answerType.NUMERIC,
        'date': q.answers[0].answer.type==this.answerType.DATE,
        'list': q.answers[0].answer.type==this.answerType.LIST,
        'string':q.answers[0].answer.type==this.answerType.STRING,
        'invalid': !q.isValid
      };
  }


  onClickInfoNotes(){
    this.clickQuestionInfoNotes.emit(this.question.question.id);
  }
}

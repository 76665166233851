<div [formGroup]="parentForm">
  <label class="lbl">{{label}}</label>
  <span *ngIf="getMaxInstances() > 1" class="addSub">
    <button [disabled]="this.counter === this.getMaxInstances()" 
            (click)="addSubj()" 
            class="addition">+</button>
  </span>
  <div *ngFor="let subj of val; let j = index; trackBy: trackByFn">
    <lic-select-subject [showPercentage]="showPercentage"
                        [val]="subj.val" 
                        [parentForm]="getSubjectGroup(j)" 
                        [role]="role" 
                        [disableDelete]="disableDelete"
                        (eventPropagation)="getAnag(j,$event)"
                        (subjetAnagEditedEvent)="editAnag(j,$event)" 
                        (delete)="resetMethod(j, $event)"
    >
    </lic-select-subject>
  </div>
</div>

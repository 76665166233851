<div *ngIf="!!state">

  <rgi-pnc-quotation-header *ngIf="state.showQuotation" [data]="state.quotationHeaderData"></rgi-pnc-quotation-header>

  <ng-container *ngFor="let summary of state.stepSummaries">
    <div class="rgi-ui-text-1 rgi-ui-title-1 rgi-ui-info rgipsales-mv-l">
      <span class="rgi-ui-text-2 rgi-ui-title-2 rgi-ui-info rgi-ui-icon-minus"></span>
      &nbsp;{{summary.stepLabel | translate}}
    </div>
    <ng-container *ngFor="let section of summary.sections">
      <div *ngIf="section.title" class="rgi-ui-text-2 rgi-ui-title-2 rgi-ui-info rgipnc-psales-section-title">
        {{section.title | translate}}</div>
      <rgi-pnc-psales-generic-detail
        [details]="section.detail.type === 'ROWS' ? section.detail.rows : section.detail.data">
      </rgi-pnc-psales-generic-detail>
    </ng-container>
  </ng-container>

  <div [formGroup]="summaryForm" class="rgi-ui-grid-1 rgipc-mt-l">
    <div class="rgi-ui-col-12">
      <rgi-rx-form-field>
        <textarea rgiRxInput formControlName="notesField" data-qa="NOTES"></textarea>
        <label rgiRxLabel><span translate>_PCPSALES_._LABEL_._NOTES_</span></label>
      </rgi-rx-form-field>
    </div>
  </div>
</div>

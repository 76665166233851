import {PostsalesOperationsResponse} from '../../../models/postsales-operations-response.model';

export const MOCK_TERMINATE_RESCISSION_RESPONSE: PostsalesOperationsResponse = {
  data: {
    dates: [
      {
        code: '_1OEFF',
        label: '_1OEFF',
        value: '2020-03-09T23:00:00.000+0000',
        userInput: true,
        persistent: false
      },
      {
        code: '_VOEMI',
        label: '_VOEMI',
        value: '2020-03-15T23:00:00.000+0000',
        userInput: true,
        persistent: false
      }
    ],
    causeCode: '_ESANP',
    subCauseCode: '',
    username: 'ADMIN',
    id: 'cbbb386f-1ff1-4f60-8396-bd30876448a2',
    contractId: '40787',
    contractorId: null,
    managementNodeId: '202286',
    productId: '123456',
    managementNodeCode: null,
    operationData: {
      data: {},
      operationDataType: '_ESANP'
    }
  },
  definitions: {
    dates: [
      {
        code: '_VOEMI',
        label: 'Data Emissione',
        visible: true,
        editable: true,
        reloadOnChange: true,
        required: true,
        defaultValue: '2020-03-15T23:00:00.000+0000',
        minValue: null,
        maxValue: null
      },
      {
        code: '_1OEFF',
        label: 'Data Effetto',
        visible: true,
        editable: true,
        reloadOnChange: true,
        required: true,
        defaultValue: '2020-03-09T23:00:00.000+0000',
        minValue: null,
        maxValue: null
      }
    ]
  },
  finalizable: true,
  errors: []
};

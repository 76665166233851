import {ContentChild, Directive} from '@angular/core';
import {RgiRxDragActionDirective} from '@rgi/rx/ui';

@Directive({
  selector: '[rgiGpDragActionExt]'
})
export class DragActionExtDirective {

  @ContentChild(RgiRxDragActionDirective) actionsExt: RgiRxDragActionDirective;

  constructor() { }



}

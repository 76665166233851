export class PrintDocumentsRequest {
  productCode: string;
  operationCode: string;
  channelCode: string;
  proposalNumber: string;
  policyNumber: string;
  dataEffetto: string;
  contractUUID: string;
  movementId: number;
  reprint: boolean;
}

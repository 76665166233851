import {Directive, Input, TemplateRef} from '@angular/core';
import {RgiRxTemplateContentDirective} from '../rgi-ui-api';
import {RgiRxDataTableCellContext, RgiRxDataTableCellContextWrapper} from './data-table-api';

@Directive({
  selector: 'ng-template[rgiRxDataTableCell]'
})
export class RgiRxDataTableCellDirective<T> extends RgiRxTemplateContentDirective {
  @Input() rgiRxDataTableCell: string;
  constructor(template: TemplateRef<RgiRxDataTableCellContextWrapper<T>>) {
    super(template);
  }
}

@Directive({})
export abstract class RgiRxDataTableCell<T> {
  private _context: RgiRxDataTableCellContext<T>;


  @Input() get context(): RgiRxDataTableCellContext<T> {
    return this._context;
  }

  set context(value: RgiRxDataTableCellContext<T>) {
    this._context = value;
  }
}

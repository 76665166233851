import { Component, ElementRef, EventEmitter, Inject, OnDestroy, Renderer2 } from '@angular/core'
import { Document as PassProDocument, DocumentResource } from '@rgi/ng-passpropro/documents'
import { DIALOG_DATA, OnModalClose, RgiRxDatatableRowAction, TableSchema } from '@rgi/rx/ui'

@Component({
  selector: 'lib-survey-eval-document-modal',
  templateUrl: './survey-eval-document-modal.component.html'
})
export class SurveyEvalDocumentModalComponent implements OnModalClose, OnDestroy {
  schema: TableSchema = {
    rows: [
      {
        name: 'name',
        title: 'Nome Documento'
      },
      {
        name: 'actions',
        title: 'Azioni',
        actions: [
          {
            name: 'download',
            styleClass: 'rgi-ui-icon-save',
            description: 'NG_PASSPROPRO_SURVEY.download'
          },
          {
            name: 'view',
            styleClass: 'rgi-ui-icon-export',
            description: 'NG_PASSPROPRO_SURVEY.view'
          }
        ]
      }
    ],
    header: ['name', 'actions']
  }

  constructor(
    @Inject(DIALOG_DATA) public data: DataDialog,
    private documentResource: DocumentResource,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  urlList: string[] = []
  modalClose: EventEmitter<any> = new EventEmitter()

  ngOnDestroy(): void {
    this.urlList.forEach(url => URL.revokeObjectURL(url))
    this.urlList = []
  }

  onAction($event: RgiRxDatatableRowAction<PassProDocument>): void {
    this.documentResource.downloadDocumentById($event.row.id).subscribe(file => {
      let url: string = URL.createObjectURL(file)
      this.urlList.push(url);

      if ($event.name === 'download') {
        this.downloadDocument($event.row.name, url)
      }

      if ($event.name === 'view') {
        this.viewDocument(url)
      }
    })
  }

  private createElement(objectAsUrl: string): any {
    let a = this.renderer.createElement("a")
    this.renderer.appendChild(this.elementRef.nativeElement, a)
    a.href = objectAsUrl
    a.rel = "noreferrer"

    return a
  }

  private viewDocument(objectAsUrl: string): void {
    let a  = this.createElement(objectAsUrl)
    a.target = '_blank'
    a.click()
  }

  private downloadDocument(fileName: string, objectAsUrl: string): void {
    let a  = this.createElement(objectAsUrl)
    a.download = fileName
    a.click()
  }
}

interface DataDialog {
  documentList: Document[]
}

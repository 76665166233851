import {NgModule} from '@angular/core';
import {DocumentsComponent} from "./components/documents/documents.component";
import {
  DocumentValidationFileModalComponent
} from './components/documents/modals/document-validation-file-modal/document-validation-file-modal.component';
import {
  DocumentUploadFileModalComponent
} from './components/documents/modals/document-upload-file-modal/document-upload-file-modal.component';
import {DocumentAddModalComponent} from './components/documents/modals/document-add-modal/document-add-modal.component';
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {
  RgiRxBadgeModule, RgiRxDatePickerModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule,
  RgiRxModalModule,
  RgiRxPanelModule
} from "@rgi/rx/ui";
import {RGI_PORTAL_COMPONENTS_TRANSLATIONS} from "./i18n/translations";
import { GenericDetailComponent } from './components/generic-detail/generic-detail.component';

@NgModule({
  declarations: [
    DocumentsComponent,
    DocumentValidationFileModalComponent,
    DocumentUploadFileModalComponent,
    DocumentAddModalComponent,
    GenericDetailComponent,
  ],
  imports: [
    ReactiveFormsModule,
    JsonPipe,
    NgIf,
    RgiRxI18nModule,
    RgiRxFormElementsModule,
    NgForOf,
    RgiRxModalModule,
    RgiRxPanelModule,
    RgiRxExpansionModule,
    RgiRxBadgeModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(RGI_PORTAL_COMPONENTS_TRANSLATIONS),
    CommonModule,
    RgiRxDatePickerModule
  ],
  exports: [
    DocumentsComponent,
    GenericDetailComponent,
  ]
})
export class RgiPortalComponentsModule {

}

import {AfterContentInit, Component, Input} from '@angular/core';
import {Currency} from '../../../models/domain-models/parameters/currency';
import {PremiumSummary} from '../../../models/domain-models/premium-summary';

@Component({
  selector: 're-issue-premium',
  templateUrl: './re-issue-premium.component.html',
  styleUrls: ['./re-issue-premium.component.css']
})
export class ReIssuePremiumComponent implements AfterContentInit {

  @Input() premium: any;
  @Input() currency: Currency;
  @Input() fees: any;
  premiumSummary: PremiumSummary;

  ngAfterContentInit() {
    this.premiumSummary = new PremiumSummary(
      this.premium ? this.premium.subscriptionInstalment.premium.gross : null,
      this.premium ? this.premium.subscriptionInstalment.premium.net : null,
      this.premium ? this.premium.subscriptionInstalment.premium.fractioningInterest : null,
      this.premium ? this.premium.subscriptionInstalment.premium.taxes : null,
      this.fees ? this.fees.sign.commissions : null);
  }
}



<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step
      [inputFields]="dateDefinitions"
      [formControlName]="'dates'"
      (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()"
      [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step
    id="questsFin" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')"
    fieldId="questsFin" label="lpc_financial_questionnaire_step" (next)="onNext($event)">
    <lpc-questionnaire
        [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
        [questionnairesCode]="getQuestMap('FIN')" type="FIN"
        [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
        [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
        (loaderQuest)="loaderQuestManager($event)"
        (loaded)="countQuest($event)"
        #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step id="factors" *ngIf="hasProductFactors" label="lpc_variation_step_label" [errors]="errors" fieldId="factors" (next)="onNext($event)">
    <div formGroupName="factors" class="row">
      <lpc-factor-adapter #factorAdapter
        (changeValEmitter)="updateFactors($event)"
        [form]="formGroup.controls['factors']"
        [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="quotation" [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event, true)">
    <div formGroupName="quotation" class="row">
      <div *ngFor="let insuredSettlement of insuredSettlements">
        <p *ngIf="!!insuredSettlement.insured && insuredSettlement.insured.length > 1;else singleInsured" class="insured">
          <label translate>lpc_Assicurati</label>
          <label>: {{ insuredSettlement.insured[0] + ' - ' + insuredSettlement.insured[1] }}</label>
        </p>
        <ng-template #singleInsured>
          <p class="insured">
            <label translate>lpc_Assicurato</label>
            <label>: {{ insuredSettlement.insured[0] }}</label>
          </p>
        </ng-template>
        <lpc-definition-table
          [label]="'lpc_life_policy'"
          [definitions]="tableDefinitions"
          [rows]="insuredSettlement.settlement">
        </lpc-definition-table>
        <br>
      </div>
    </div>
  </lpc-step>


  <lpc-step
        id="beneficiaries" [enableAuth]="isAuth" label="lpc_life_detail_beneficiaries" [errors]="errors" fieldId="beneficiaries"
        (next)="onNext($event, false, 'beneficiaries')" [feErrors]="feErrors"
        feFieldId="beneficiaries" (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-claim-beneficiaries
      formControlName="beneficiaries" [paymentTypes]="paymentTypes"
      [beneficiaryType]="beneficiaryType"
      [idAssicurato]="idAssicurato" [summary]="!isActiveStep('beneficiaries')"
      [questionnairesCode]="getUnfilteredQuestMap('IST')"
      [questionnairesDefinitions]="questionnaireDefinitions"
      [blockBeneficiaries]="blockBeneficiaries"
      (addedSubject)="onAddedSubject($event)"
      (triggerQuestPreval)="onTriggerQuestPreval($event)"
      (loaderQuest)="loaderQuestManager($event)"
      [questFactorsArray]="questFactorsArray"
      [disabledQuestionArray]="disabledQuestionArray"
      [validateMandatoryBeneficiary]="isMandatoryBeneficiaries"
      #beneficiaries >
    </lpc-claim-beneficiaries>
  </lpc-step>

  <lpc-step
        *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()"
        [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
        (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages
        *ngIf="!!errors"
        [errors]="errors">
    </lpc-warning-messages>
  </lpc-step>


  <lpc-step label="lpc_confirm_variation_step" [errors]="errors" fieldId="documents" id="documents" [nextVisible]=false>
    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons
    (back)="closeCard()"
    [nextVisible]="false"
    [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>

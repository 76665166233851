<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

    <lpc-step [label]="CLOSE_ASSIGNMENT.DATE.label" [fieldId]="CLOSE_ASSIGNMENT.DATE.fieldId" [id]="CLOSE_ASSIGNMENT.DATE.id" [errors]="errors" (next)="onNext($event)">
      <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="CLOSE_ASSIGNMENT.DATE.formName" (dateChange)="onDateChange($event)"
        (reloadRequest)="updateData()" [hide]="activeStep<1">
      </lpc-dates-step>
    </lpc-step>

    <lpc-step [id]="CLOSE_ASSIGNMENT.CLOSE.id" [label]="getOperationCode === '_CVINC' ? 'lpc_Close_assignment': 'lpc_Close_lien'" [errors]="errors" [fieldId]="CLOSE_ASSIGNMENT.CLOSE.fieldId"
        (next)="onNext($event)" (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
        <div class="row" [formGroupName]="CLOSE_ASSIGNMENT.CLOSE.formName">
            <div *ngIf="isActiveStep(CLOSE_ASSIGNMENT.CLOSE.id)">
                    <div *ngFor="let assignmentDate of assignmentDates; let i = index;" class="dates-height col-lg-6">
                        <lpc-datepicker [formControlName]="assignmentDate.code"
                                        [label]="assignmentDate.label"
                                        [id]="assignmentDate.code"
                                        [hidden]="!isActiveStep(CLOSE_ASSIGNMENT.CLOSE.id)"
                                        [helpFile]="assignmentDate.helpFile">
                        </lpc-datepicker>
                    </div>
                    <div *ngIf="!!responseOperation?.definitions?.assignmentTypeCreditor" class="col-md-6" data-qa="assignment_type_creditor">
                        <label class="label-core" translate>{{responseOperation.definitions.assignmentTypeCreditor?.label}}</label>
                        <input type="string" [value]="responseOperation.definitions.assignmentTypeCreditor.value" class="form-control" disabled data-qa="assignment_type_creditor_value">
                    </div>
                    <div *ngIf="!!responseOperation?.definitions?.assignmentCreditorDescription" class="col-md-6" data-qa="assignment_creditor_description">
                        <label for="subject" translate class="label-core">{{responseOperation.definitions.assignmentCreditorDescription.label}}</label>
                        <input type="string" [value]="responseOperation.definitions.assignmentCreditorDescription.value" class="form-control" disabled data-qa="assignment_creditor_description_value">
                    </div>
                    <div *ngIf="!!responseOperation?.definitions?.assignmentCompany && !!responseOperation?.definitions?.assignmentCompany?.value" class="col-md-6" data-qa="assignment_company">
                        <label for="subject" translate class="label-core">{{responseOperation.definitions.assignmentCompany.label}}</label>
                        <input type="string" [value]="responseOperation.definitions.assignmentCompany.value" class="form-control" disabled data-qa="assignment_company_value">
                    </div>
                    <div *ngIf="!!responseOperation?.definitions?.contractNumber && !!responseOperation?.definitions?.contractNumber?.value" class="col-md-6" data-qa="contract_number">
                        <label for="subject" translate class="label-core">{{responseOperation.definitions.contractNumber.label}}</label>
                        <input type="string" [value]="responseOperation.definitions.contractNumber.value" class="form-control" disabled data-qa="contract_number_value">
                    </div>
                    <div *ngIf="!!responseOperation?.definitions?.assignmentClosedCause" class="col-md-6" data-qa="assignment_closed_cause">
                        <label for="subject" translate class="label-core">{{responseOperation.definitions.assignmentClosedCause.label}}</label>
                        <input formControlName="assignmentClosedCause" type="string" class="form-control" data-qa="assignment_closed_cause_value">
                    </div>
                    <div *ngIf="!!responseOperation?.definitions?.assignmentType" class="col-md-6" data-qa="assignment_type">
                        <label for="subject" translate class="label-core">{{responseOperation.definitions.assignmentType.label}}</label>
                        <input type="string" [value]="responseOperation.definitions.assignmentType.value" class="form-control" disabled data-qa="assignment_type_value">
                    </div>
                    <div *ngIf="!!responseOperation?.definitions?.amount?.value" class="col-md-6" data-qa="amount">
                        <label for="subject" translate class="label-core">{{responseOperation.definitions.amount.label}}</label>
                        <input type="string" [value]="responseOperation.definitions.amount.value" class="form-control" disabled data-qa="amount">
                    </div>
            </div>
            <!-- SUMMARY -->

            <div *ngIf="!isActiveStep(CLOSE_ASSIGNMENT.CLOSE.id)">
                <div class="col-lg-6" *ngIf="!!assignmentDates">
                    <span translate>{{assignmentDates[0]?.label}}</span> : {{assignmentDates[0]?.defaultValue | date : 'dd/MM/yyyy'}}
                </div>
                <div class="col-lg-6" *ngIf="!!responseOperation?.definitions?.assignmentTypeCreditor && !!responseOperation?.definitions?.assignmentTypeCreditor?.value">
                    <span translate>{{responseOperation?.definitions?.assignmentTypeCreditor?.label}}</span> : {{responseOperation?.definitions?.assignmentTypeCreditor?.value}}
                </div>
                <div class="col-lg-6" *ngIf="!!responseOperation?.definitions?.assignmentCreditorDescription && !!responseOperation?.definitions?.assignmentCreditorDescription?.value">
                    <span translate>{{responseOperation?.definitions?.assignmentCreditorDescription?.label}}</span> : {{responseOperation?.definitions?.assignmentCreditorDescription?.value}}
                </div>
                <div class="col-lg-6" *ngIf="!!responseOperation?.definitions?.assignmentCompany && !!responseOperation?.definitions?.assignmentCompany?.value">
                    <span translate>{{responseOperation?.definitions?.assignmentCompany?.label}}</span> : {{responseOperation?.definitions?.assignmentCompany?.value}}
                </div>
                <div class="col-lg-6" *ngIf="!!responseOperation?.definitions?.assignmentClosedCause && formGroup.get(CLOSE_ASSIGNMENT.CLOSE.formName).get(CLOSE_ASSIGNMENT.CLOSE.formChildren.assignmentClosedCause).value">
                    <span translate>{{responseOperation?.definitions?.assignmentClosedCause?.label}}</span> : {{formGroup.get(CLOSE_ASSIGNMENT.CLOSE.formName).get(CLOSE_ASSIGNMENT.CLOSE.formChildren.assignmentClosedCause).value }}
                </div>
                <div class="col-lg-6" *ngIf="!!responseOperation?.definitions?.assignmentType && !!responseOperation?.definitions?.assignmentType?.value">
                    <span translate>{{responseOperation?.definitions?.assignmentType?.label}}</span> : {{responseOperation?.definitions?.assignmentType?.value}}
                </div>
                <div class="col-lg-6" *ngIf="!!responseOperation?.definitions?.amount?.value && !!responseOperation?.definitions?.amount?.value">
                    <span translate>{{responseOperation?.definitions?.amount?.label}}</span> : {{responseOperation?.definitions?.amount?.value}}
                </div>
            </div>
            <!-- END SUMMARY -->
        </div>
    </lpc-step>

    <lpc-step *ngIf="isAuth || !!errors && !!errors.length" [id]="CLOSE_ASSIGNMENT.WARNING.id" [label]="CLOSE_ASSIGNMENT.WARNING.label" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
              (confirmAccept)="publish(true)" [isConfirmAndAccept]="isConfirmAndAccept">
      <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    </lpc-step>

    <lpc-step
      [label]="CLOSE_ASSIGNMENT.DOCUMENT.label"
      [errors]="errors"
      [fieldId]="CLOSE_ASSIGNMENT.DOCUMENT.fieldId"
      [id]="CLOSE_ASSIGNMENT.DOCUMENT.id"
      [nextVisible]=false>

      <lpc-document-wrapper
        *ngIf="!!publishMessage"
        [contractId]="getContractId"
        [operationCode]="getOperationCode"
        [publishMessage]="publishMessage"
        [isAuth]="isAuth"
        [authorizationId]="authorizationId"
        [printMode]="printMode"
        [config]="documentsConfig"
        (documentEvent)="handleDocumentEvent($event)">
      </lpc-document-wrapper>

      <lpc-navigation-buttons
        id="last-step-buttons"
        (back)="closeCardWithoutModal()"
        [backLabel]="CLOSE_ASSIGNMENT.CLOSE.label"
        [backVisible]="true"
        [nextVisible]="false">
      </lpc-navigation-buttons>

    </lpc-step>
  </lpc-stepper>

  <div class="btn-group btn-group-justified external" *ngIf="!isActiveStep(CLOSE_ASSIGNMENT.DOCUMENT.id)">
      <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
  </div>


import {RgiRxHttpError, RgiRxHttpErrorAdapter, rgiRxHttpErrorSeverityFromStatus} from '@rgi/rx/http';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
export class RgiRxPortalHttpErrorAdapter extends RgiRxHttpErrorAdapter {


  adapt(httpError: HttpErrorResponse): RgiRxHttpError | Observable<RgiRxHttpError> {
    return new RgiRxHttpError(httpError.error.message, httpError.status, httpError.url, {
      headers: httpError.headers,
      body: httpError.error,
      statusText: httpError.statusText,
      severity: rgiRxHttpErrorSeverityFromStatus(httpError.status),
      scope: 'global',
      tracing: this.adaptTracing(httpError)
    });
  }

  private adaptTracing(httpError: HttpErrorResponse) {
    return{
      id: httpError.headers.has('Rgi_ExecutionId') ? httpError.headers.get('Rgi_ExecutionId') : httpError.error.executionId,
      systemMessage: httpError.error.code
    };
  }
}

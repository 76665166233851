import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {AbstractStateManager, State, StateStoreService} from '@rgi/rx/state';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DashboardResourceService} from '../resources/dashboard-resources.service';
import {DashboardProduct} from '../model/dashboard-product';
import {DashboardElem} from '../model/dashboard-element';
import {UntypedFormGroup} from '@angular/forms';
import {DashboardSummary} from '../model/dashboard-summary';
import {DashboardSearchData} from '../model/dashboard-search-data';
import {DashboardSummaryData} from '../model/dashboard-summary-data';
import {DashboardNavigationData} from './dashboard-data-state';
import {DashboardDetail} from '../model/dashboard-detail';
import {Company} from '../const/company';
import {FunctionalityEnum} from '../enumerations/functionality.enum';


export class DashboardCardState extends State { // this is the model of the state
    public companyList: Array<Company>;
    public productList: Array<DashboardProduct>;
    public productTypeList: Array<DashboardProduct>;
    public statusList: Array<DashboardElem>;
    public actionList: Array<DashboardElem>;
    public summaryData: DashboardSummary;
    public commissionsData: DashboardDetail[];
    public operationList: Array<DashboardElem>;
    public noteList: Array<DashboardElem>;
    public totalCommissions: string;
    public extractedCommissions: string;
    public page: string;
    public limit: string;
}


export class DashboardManagerService<T extends DashboardCardState> extends AbstractStateManager<T> {


    constructor(
        activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        private routingService: RoutingService,
        private resourceService: DashboardResourceService) {
        super(activeRoute, stateStoreService);
        const st = !!stateStoreService.get<T>(this.activeRoute.key) ?
            stateStoreService.get<T>(this.activeRoute.key) : this.newDashboardState();

        this.updateState$(of(st)); // this update the state
    }

    newDashboardState(): T {
        return new DashboardCardState(this.activeRoute.key) as T;
    }

    initAllData(st: T, node: string): Observable<T> {

        const comp$ = this.resourceService.getCompanies$();
        const prod$ = this.resourceService.getProducts$(st, node);
        const start$ = this.resourceService.getStartData$();

        return combineLatest(comp$, prod$, start$).pipe(map(([companies, products, start]) => {
            st.companyList = companies;
            st.productList = products.products;
            st.productTypeList = products.productsType;
            st.statusList = this.getStatusList(start);
            st.actionList = this.getActionList(start);
            return st;

        }));
    }

    initAllDataDetail(st: T, selectable): Observable<T> {
        const operation$ = this.resourceService.getOperations$(st, selectable);
        const actions$ = this.resourceService.getActions$(st);
        const notes$ = this.resourceService.getNotes$(st);

        return combineLatest(operation$, actions$, notes$).pipe(map(([operation, actions, notes]) => {
            st.actionList = actions;
            st.operationList = operation;
            st.noteList = notes;
            return st;
        }));
    }


    getSummaryData(st: T): Observable<T> {
        const summ$ = this.resourceService.getSummary$(this.activeRoute.getRouteData<DashboardNavigationData>());

        return combineLatest(summ$).pipe(map(([summ]) => {
            summ.details = this.rearrangeSummaryDetails(summ.details);
            st.summaryData = summ;
            return st;

        }));
    }

    getRiskSummaryDetails(idDashBoard: string, type: string, st: T): Observable<T> {
        const summ$ = this.resourceService.getSummaryRiskDetail$(idDashBoard, type);
        return combineLatest(summ$).pipe(map(([data]) => {
            const riskCommissions = data.riskCommissions;
            if (riskCommissions && riskCommissions.length > 0) {
                st.commissionsData.find(row => riskCommissions[0].idDashBoard === row.idDashBoard).risksDetail = riskCommissions;
            }
            return st;
        }));
    }


    getCommissions(st: T, offset: string, limit: string): Observable<T> {
        const navData = (this.activeRoute.getRouteData<DashboardNavigationData>());
        const commissions$ = this.resourceService.getCommissions$(navData, offset, limit);
        return combineLatest(commissions$).pipe(map(data => {
            st.commissionsData = data[0].commissions;
            st.totalCommissions = data[0].totalRecord;
            st.extractedCommissions = data[0].extractedRecord;
            return st;
        }));
    }

    putCommissions(st: T, bodyRequest): Observable<T> {
        const commissions$ = this.resourceService.putCommissions$(bodyRequest);
        return combineLatest(commissions$).pipe(map(data => {
            return st;
        }));
    }

    rearrangeSummaryDetails(details: Array<DashboardSummaryData>): Array<DashboardSummaryData> {
        const rearrengedList = new Array<DashboardSummaryData>();
        if (details) {
            const tempArray = details.sort((a, b) => {
                if (a.id > b.id) {
                    return 1;
                } else if (a.id < b.id) {
                    return -1;
                } else {
                    return 0;
                }
            });

            for (const el of tempArray) {
                if (!el.parent) {
                    rearrengedList.push(el);
                }
            }

            for (const el of tempArray) {
                if (el.parent) {
                    const idx = rearrengedList.map(x => x.id).indexOf(el.parent);
                    rearrengedList.splice(idx + 1, 0, el);
                }
            }
        }
        return rearrengedList;
    }

    getStatusList(search: DashboardSearchData): Array<DashboardElem> {

        const statusArray = new Array<DashboardElem>();

        if (search) {
            for (const stat of search.status) {
                statusArray.push(new DashboardElem(stat.id, stat.description));
            }
        }

        return statusArray;
    }

    getActionList(search: DashboardSearchData): Array<DashboardElem> {
        const actionsArray = new Array<DashboardElem>();

        if (search) {
            for (const stat of search.actions) {
                actionsArray.push(new DashboardElem(stat.id, stat.description));
            }
        }

        return actionsArray;
    }

    goToNextPage(formGroup: UntypedFormGroup, type: number) {

        // Routing Data
        const req: DashboardNavigationData = {
            product: formGroup.controls.product.value,
            company: formGroup.controls.company.value,
            competenceDateFrom: formGroup.controls.competenceDateFrom.value,
            competenceDateTo: formGroup.controls.competenceDateTo.value,
            status: formGroup.controls.status.value,
            action: formGroup.controls.action.value,
            mismatchAmountFrom: formGroup.controls.mismatchAmountFrom ? formGroup.controls.mismatchAmountFrom.value : null,
            mismatchAmountTo: formGroup.controls.mismatchAmountTo ? formGroup.controls.mismatchAmountTo.value : null,
            type,
            functionality: formGroup.controls.functionality.value
        };

        // Navigate
        if (formGroup.controls.functionality.value.id === FunctionalityEnum.TOTAL_CONSULTATION) {
            this.routingService.navigate('dashboard-summary', req, this.activeRoute.id);
        } else if (formGroup.controls.functionality.value.id === FunctionalityEnum.DETAIL_CONSULTATION ||
            formGroup.controls.functionality.value.id === FunctionalityEnum.EDIT) {
            this.routingService.navigate('dashboard-detail', req, this.activeRoute.id);
        }
    }

    getTotalCommissions(): Observable<any> {
        return of(this.getCurrentState().totalCommissions);
    }

    filterCommissions(keyWord: string): Observable<any> {
        const comm = of(this.getCurrentState().commissionsData);
        return comm.pipe(
            map(person => {
                return person.filter(p => {
                    const dataStr = Object.keys(p).reduce((currentTerm: string, key: string) => {
                        return currentTerm + (p as { [key: string]: any })[key] + '◬';
                    }, '').toLowerCase();
                    const transformedFilter = keyWord.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                });
            }),
            // delay(1000),
        );
    }

    setPage(page: string) {
        const state = this.getCurrentState();
        state.page = page;
        this.updateState$(of(state));
    }

    getPage() {
        const state = this.getCurrentState();
        return state.page;
    }

    setLimit(limit: string) {
        const state = this.getCurrentState();
        state.limit = limit;
        this.updateState$(of(state));
    }

    getLimit() {
        const state = this.getCurrentState();
        return state.limit;
    }

}

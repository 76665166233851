export class CheckPartyComplete {

  constructor(
    public idPartyPicture: string,
    public partyRole: string,
    public productId: string,
    public nodeId: string
  ) {
  }
}


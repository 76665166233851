<template #openClaimModal></template>
<div class="headerClaim">
  <div class="nmf_cont_sin_paritari">
    <h2 *ngIf="insuranceStatus && insuranceStatus?.claimDetails && insuranceStatus?.claimDetails?.length"
        translate>Claims with equal responsibility</h2>
    <div *ngIf="insuranceStatus && insuranceStatus?.claimDetails && insuranceStatus?.claimDetails?.length"
         class="nmf_sin_paritari">
      <div *ngFor="let claimDetails of insuranceStatus?.claimDetails; index as i; trackBy: claimDetailsTrackByFn"
           class="nmf_sin_paritari_riga">
        <div class="nmf_sin_paritari_txt">
          <span translate>Year:</span> <b>{{claimDetails?.year}}</b> -
          <span *ngIf="claimDetails?.claimNumber"><span
            translate>Claim Number:</span> <b>{{claimDetails?.claimNumber}}</b> -</span>
          <span translate>Percentage Responsibility:</span> <b>{{claimDetails?.responsabilityPortion}}%</b> -
          <span translate>Malus:</span> <b>{{translateBoolean(claimDetails?.malus)}}</b> -
          <!-- <span translate>Malus:</span> <b *ngIf="claimDetails.malus">{{claimDetails.malus}}</b> <b *ngIf="!claimDetails.malus">false</b> - -->
          <span translate>Type of Damage:</span> <b
          *ngIf="claimDetails?.damageType">{{genericDamageTypeList[claimDetails?.damageType?.id - 1]?.description}}</b>
          -
          <span translate>Description:</span> <b>{{claimDetails?.description}}</b>&nbsp;&nbsp;
          <button (click)="openClaim(false,claimDetails)" *ngIf="showEdit" class="btn btn-default nmf_sin_paritari_button"
                  type="button">
            <span class="glyphicon glyphicon-pencil"></span>
          </button>
          <button (click)="removeClaim(claimDetails?.id)" *ngIf="showEdit" class="btn btn-default nmf_sin_paritari_button"
                  type="button">
            <span class="glyphicon glyphicon-trash"></span>
          </button>
        </div>
      </div>
    </div>
    <button (click)="openClaim(true,null)" *ngIf="showEdit" [disabled]="!insuranceStatus.lastYearOfTheReport"
            class="btn btn-default nmf_button_add" data-qa="btn-eq-resp-modal-open"
            translate type="button">Add claims with equal responsibility
    </button>
  </div>

import { ApiEnumType } from '@rgi/digital-claims-common-angular';

export class ExcessDamageDto {

    amountLiqInsured: number;

    // excess
    capAggregAmount: number;
    capClaimAmount: number;
    enumAdvanceExcClLiq: ApiEnumType;
    enumAdvanceExcAggrLiq: ApiEnumType;
    enumAdvanceExcessAggr: ApiEnumType;
    enumAdvanceExcessCl: ApiEnumType;
    enumAdvanceSir: ApiEnumType;
    enumAdvanceSirLiq: ApiEnumType;
    enumCapAggregate: ApiEnumType;
    enumCapClaim: ApiEnumType;
    excAggrResAmount: number;
    excessAggrAmount: number;
    excessAggrErodedAmount: number;
    excessClaimAmount: number;
    excessClErodedAmount: number;
    excessClResAmount: number;
    resCapAggrAmount: number;
    resCapClAmount: number;
    firstSirAmount: number;
    firstSirResidualAmount: number;
    firstSirErodedAmount: number;
    secondSirAmount: number;
    secondSirResidualAmount: number;
    secondSirErodedAmount: number;

    // deductible
    deductibleErodedAmount: number;
    deductibleMaxAmount: number;
    deductibleMinAmount: number;
    deductiblePercentage: number;
    deductibleResAmount: number;
    enumAdvanceDeductibleLiq: ApiEnumType;
}

import {RGI_FIELD_TYPE, RgiFormField} from '../common/rgi-form-field';

export class PncPostsalesDateDto {
  value: string;
  min?: string;
  max?: string;
  editable?: boolean;
  mandatory?: boolean;
}

export interface PncPostsalesGenericMandatoryDates {
  issueDate: PncPostsalesDateDto;
  effectDate: PncPostsalesDateDto;
  effectHour: PncPostsalesDateDto;
  expiryDate: PncPostsalesDateDto;
}

export function dateDtoToFormField(fieldName: string, fieldLabel: string, date: PncPostsalesDateDto): RgiFormField {
  return {
    value: date.value,
    code: fieldName,
    label: fieldLabel,
    editable: date.editable,
    mandatory: date.mandatory,
    visible: true,
    type: RGI_FIELD_TYPE.DATE
  };
}

export function stringDtoToFormField(fieldName: string, fieldLabel: string, date: PncPostsalesDateDto): RgiFormField {
  return {
    value: date.value,
    code: fieldName,
    label: fieldLabel,
    editable: date.editable,
    mandatory: date.mandatory,
    visible: true,
    type: RGI_FIELD_TYPE.STRING
  };
}

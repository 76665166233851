<div [ngClass]="{'rgi-ui-panel-content': state.isModal}">
  <div>{{'_ANAG_._MSG_._INFO_HOMONYMIES_' | translate}}</div>
  <rgi-rx-datatable *ngIf="partiesTableData && partiesTableData.length"
                    [data]="partiesTableData"
                    [schema]="PARTY_LIST_MODAL_TABLE_SCHEMA"
                    [selectable]="false"
                    [expansionRow]="partyHomonymyDetailRow"
                    (onAction)="onActionClick($event)">
  </rgi-rx-datatable>
  <ng-template #partyHomonymyDetailRow let-partyRow>
    <div class="rgi-ui-grid-1 rgi-ui-grid-no-gutter">
      <div class="rgi-ui-col" *ngIf="partyRow.residence?.formatAddress">
        <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._RESIDENCE_</p>
        <p data-qa="party-residence">{{partyRow.residence.formatAddress}}</p>
      </div>
      <div class="rgi-ui-col" *ngIf="partyRow.dateOfBirth">
        <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._BIRTH_DATE_</p>
        <p data-qa="party-residence">{{partyRow.dateOfBirth | date: localFormat()}}</p>
      </div>
      <div class="rgi-ui-col" *ngIf="partyRow.birthName">
        <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._BIRTH_SURNAME_</p>
        <p data-qa="party-residence">{{partyRow.birthName}}</p>
      </div>
    </div>
  </ng-template>
  <div class="rgi-ui-snackbar rgi-ui-danger" *ngIf="state.serviceError" data-qa="rgi-anag-httpError">
    <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
    <span class="rgi-ui-message" translate>{{state.serviceError}}</span>
  </div>
  <div class="rgi-ui-snackbar rgi-ui-default" *ngIf="showRefineSearch">
    <span class="rgi-ui-message" data-qa="partyNotFoundMsg" translate>_ANAG_._MSG_._REFINE_SEARCH_</span>
  </div>
</div>
<div class="rgi-ui-panel-footer">
  <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionGoBack()" data-qa="anag-btn-back"
          translate>_ANAG_._BTN_._BACK_
  </button>
  <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="continueParty()"
          data-qa="anag-btn-new" translate>_ANAG_._BTN_._CONTINUE_
  </button>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { InvestmentContext } from './../../../life-issue-card/lic-consts';

@Component({
  selector: 'lic-navigation-buttons',
  templateUrl: './lic-navigation-buttons.component.html',
  styleUrls: ['./lic-navigation-buttons.component.css']
})
export class LicNavigationButtonsComponent implements OnInit {

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() qa: string;
  @Input() context: InvestmentContext;

  @Input() backLabel = 'CANCEL';
  @Input() isConfirm = false;
  @Input() saveVisible = false;
  @Input() backVisible = true;
  @Input() nextVisible = true;
  @Input() set errors(errorsArray: {msg: string, context: InvestmentContext}[]) {
    this.errorMessages = errorsArray
    .filter(err => err.context === this.context)
    .map(err => err.msg)
    .filter(err => !!err);
  }

  errorMessages = [];

  constructor() {
  }

  ngOnInit() {
  }

  onBack() {
    this.back.emit(true);
  }

  onNext() {
    this.next.emit(true);
  }

  onSave() {
    this.save.emit(true);
  }
}

import {Injectable, Injector} from '@angular/core';
import {RGI_RX_LOCALE, RgiRxTranslations} from './i18n-api';
import {combineLatest, forkJoin, from, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {RgiRxTranslationCatalogService} from './rgi-rx-translation-catalog.service';
import {LoggerFactory} from '@rgi/rx';

@Injectable({
  providedIn: 'root'
})
export class RgiRxTranslationLoaderService {

  private readonly logger = LoggerFactory();
  private _loadedLocales: RGI_RX_LOCALE[] = [];

  constructor(
    private injector: Injector,
    private catalog: RgiRxTranslationCatalogService,
  ) {
  }

  load(translations: RgiRxTranslations, locale: RGI_RX_LOCALE | string) {

    if (this._loadedLocales.indexOf(locale) > -1) {
      return of(locale);
    }

    const translations$ = of(
      translations.filter(p => {
        return p.locale === locale;
      })
    );

    return combineLatest([translations$, of(locale)])
      .pipe(
        mergeMap(([trans, loc]) => {
          const promises = trans.map(t => {
            return t.load(this.injector);
          });
          return forkJoin([from(Promise.all(promises)), of(loc)])
            .pipe(
              tap(([loaded, l]) => {
                loaded.forEach(
                  load => {
                    this.catalog.load(load, l);
                  }
                );
                this._loadedLocales.push(l);
              })
            );
        }),
        map(([loaded, loc]) => {
          return loc;
        }),
      );
  }

  hasLoaded(locale: RGI_RX_LOCALE) {
    return this._loadedLocales.indexOf(locale) >= -1;
  }
}

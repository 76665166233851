<div *ngIf="stickable" class="ppevo-navbar" [class.ppevo-sticky]="isSticky" rgiRxStickableChecker="bottom" (stickableCheckerChange)="changeSticky($event)">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
</div>
<div *ngIf="!stickable" class="ppevo-navbar">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
</div>

<ng-template #buttons>
    <button class="ppevo-btn" 
        *ngFor="let btn of btns" (click)="onBtnClick($event, btn)"
        [ngClass]="btn.ngClass || ngClassDefault"
        [ngStyle]="btn.ngStyle"
        [disabled]="btn.disabled"
        [innerHTML]="btn.label"
    ></button>
</ng-template>
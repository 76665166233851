import {ReIssueGeneralDataResourceService} from '../re-issue-general-data-resource.service';
import {Inject, Injectable} from '@angular/core';
import {PartyAsset, PartyRequest} from '../../../models/domain-models/party-asset';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {ContractorDataResponse} from '../../../models/pc-portfolio-models/contractor-models/contractor-data-response';
import {ReIssuePortfolioContractorService} from './re-issue-portfolio-contractor.service';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {ErrorManagementService} from '../../../re-issue-core/error-management.service';


@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioGeneralDataResourceService extends ReIssueGeneralDataResourceService {

  constructor(
    protected http: RgiRxHttpClientWrapper,
    @Inject('environment') protected apiConf: any,
    protected errorHandler: ErrorManagementService,
    private portfolioContractorService: ReIssuePortfolioContractorService,
    private refactorService: ReIssueRefactorService
  ) {
    super(http, errorHandler, apiConf);
  }


  put(resourceId: string, data: PartyRequest): Observable<PartyAsset> {
    return this.portfolioContractorService.postContractor(resourceId, this.refactorService.contractorRequestRefactor(data)).pipe(
      share(),
      map((contractorData: ContractorDataResponse) => {
        return this.refactorService.contractorResponseRefactor(contractorData);
      })
    );
  }
}

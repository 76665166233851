import {Component, OnInit} from '@angular/core';
import {RgiRxPushMessage} from '@rgi/rx';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'rgi-rx-snackbar-host',
  styleUrls: ['./rgi-rx-snackbar-host.component.scss'],
  host: {
    class: 'rgi-rx-snackbar-overlay-host',
    'aria-live': 'polite',
  },
  templateUrl: './rgi-rx-snackbar-host.component.html',
})
export class RgiRxSnackbarHostComponent {

  private _notifications = new BehaviorSubject<RgiRxPushMessage[]>([]);
  constructor() { }


  trackBy = (index: number, item: RgiRxPushMessage) => {
    return item.id;
  }


  add(...pushMessage: RgiRxPushMessage[]) {
    let value = this._notifications.value;
    this._notifications.next(value.concat(pushMessage));
  }


  get notifications$(): Observable<RgiRxPushMessage[]> {
    return this._notifications.asObservable();
  }

  remove(notification: RgiRxPushMessage) {
    const pushMessages = this._notifications.value.filter(
      value => value !== notification
    );
    this._notifications.next(pushMessages);
  }

  clear() {
    this._notifications.next([]);
  }
}

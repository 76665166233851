<rgi-rx-modal>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="closeModal(null)">
      {{'_CLAIMS_._NEW_BODYWORK' | translate}}
    </rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-primary" *ngIf="dataTable.data.length == 0" (click)="resetForm()">{{'_CLAIMS_._BUTTONS_._EMPTY' | translate}}</button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" *ngIf="dataTable.data.length > 0" (click)="resetResult()">{{'_CLAIMS_._BUTTONS_._BACK' | translate}}</button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="newBodyWork()">{{'_CLAIMS_._BUTTONS_._NEW' | translate}}</button>
      <button (click)="submitForm()" class="rgi-ui-btn rgi-ui-btn-primary" *ngIf="dataTable.data.length == 0">{{'_CLAIMS_._BUTTONS_._FIND' | translate}}</button>
    </rgi-rx-panel-footer>
    <div class="container-fluid">
      <form #formG="ngForm" *ngIf="dataTable.data.length == 0">
        <div class="form-group row">
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._SUBJECT_TYPE' | translate}}</label>
              <select rgiRxNativeSelect id="trusteeType" name="trusteeType" [(ngModel)]="formData.trusteeType">
                <option value=""></option>
                <option *ngFor="let type of trusteeTypes" [value]="type.codice">{{type.descrizione}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._CODE' | translate}}</label>
              <input rgiRxInput type="text" id="code" name="code" [(ngModel)]="formData.code" />
            </rgi-rx-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6 col-xs-12 col-sm-6" *ngIf="formData.trusteeType !== '2'">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._NAME' | translate}}</label>
              <input rgiRxInput type="text" id="name" name="name" [(ngModel)]="formData.name" />
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6" *ngIf="formData.trusteeType !== '2'">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._SURNAME' | translate}}</label>
              <input rgiRxInput type="text" id="surname" name="surname" [(ngModel)]="formData.surname" />
            </rgi-rx-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6 col-xs-12 col-sm-6" *ngIf="formData.trusteeType !== '1'">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._BUSINESS_NAME' | translate}}</label>
              <input rgiRxInput type="text" id="businessName" name="businessName" [(ngModel)]="formData.businessName">
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6" *ngIf="formData.trusteeType !== '2'">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._FISCAL_CODE' | translate}}</label>
              <input rgiRxInput type="text" id="fiscalCode" name="fiscalCode" [(ngModel)]="formData.fiscalCode">
            </rgi-rx-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6 col-xs-12 col-sm-6" *ngIf="formData.trusteeType !== '1'">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._VAT_CODE' | translate}}</label>
              <input rgiRxInput type="text" id="vatCode" name="vatCode" [(ngModel)]="formData.vatCode">
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{'_CLAIMS_._TYPE_OF_BODYWORK' | translate}}</label>
              <select rgiRxNativeSelect id="typeBodywork" name="typeBodywork" [(ngModel)]="formData.typeBodywork"   (change)="onTypeBodyworkChange()"
                required>
                <option value=""></option>
                <option *ngFor="let type of arrayBodyworkTypes" [value]="type.codice">{{ type.descrizione }}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
        </div>
        <div *ngIf="typeBodyworkIsEmpty">
          <div class="alert alert-danger" role="alert">
            {{'_CLAIMS_._MESSAGE_._TYPE_OF_BODYWORK_IS_MANDATORY' | translate}}
          </div>
        </div>
        <div *ngIf="noResult">
          <div class="alert alert-danger" role="alert">
            {{'_CLAIMS_._MESSAGE_._NO_RES_FOUND' | translate}}
          </div>
        </div>
      </form>
      <claims-dsc-car-bodywork-table *ngIf="dataTable.data.length > 0" [result]="dataTable" (outputCarBodywork)="closeModal($event)"></claims-dsc-car-bodywork-table>
    </div>
  </rgi-rx-panel>
</rgi-rx-modal>
import {Inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PartiesData} from '../models/domain-models/parties/parties-data';
import {Entitled} from '../models/domain-models/parties/entitled';
import {MicHttpService} from '../http/mic-http.service';
import {RoutingService} from '../routing.service';
import {AnagEditPartyResolver, AnagFlowData, AnagSearchOrCreateResolver} from '@rgi/anag';
import {map, take} from 'rxjs/operators';
import {AnagDocument} from '@rgi/anag/lib/anag-model/anag-domain/anag-document';
import {ProposalService} from '../proposal.service';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class PartiesService {

  private baseApiUrl;
  private baseApiUrlV2;
  private entitled: any;
  private isProposedEntitled = false;
  private proposedEntitledFiscalCode: string;
  private proposedEntitledVat: string;
  private lastUpdateChangedPartyResidence = false;
  protected httpClient: HttpClient;
  private storedAlternativeRoleOffset: number;

  private alternativeRoleOffsetKey = 'PortfolioQuoteRolesConfigOffset';

  private partyChannel: Subject<Array<any>> =
    new Subject<Array<any>>();

  private startCheckProposalDataChannel: Subject<any> = new Subject<any>();
  private surveyFilledOutDataChannel: Subject<any> = new Subject<any>();
  private subscriberChangedChannel: Subject<any> = new Subject<any>();

  constructor(
    protected micHttpClient: MicHttpService,
    protected routingServiceNMF: RoutingService,
    @Inject('environment') environment: any,
    @Inject('sessionService') private portalSession: any,
    @Inject('coreResult') private portalCoreResult: any,
    protected anagSearchorCreateResolver: AnagSearchOrCreateResolver,
    protected anagEditResolver: AnagEditPartyResolver,
    protected proposalService: ProposalService,
    protected commonService: CommonService
  ) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrl = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';

    this.httpClient = this.micHttpClient.getHttpClient();
  }

  getEntitled(contractId: string) {
    return this.httpClient.get<Entitled>(this.baseApiUrlV2 + '/motor/contract/' + contractId + '/entitled');
  }

  updateEntitled(contractId: string, entitled: Entitled) {
    return this.httpClient.put<Entitled>(this.baseApiUrlV2 + '/motor/contract/' + contractId + '/entitled', entitled);
  }

  getParty(contractId: string, partyId: string, partyLockId: string, roleId?: string) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('partyId', partyId);

    if (partyLockId) {
      httpParams = httpParams.set('partyLockId', partyLockId);
    }
    if (roleId) {
      httpParams = httpParams.set('roleId', roleId);
    }

    return this.httpClient.get<PartiesData>(this.baseApiUrlV2 + '/motor/contract/' + contractId + '/party',
      {params: httpParams});
  }

  getPartyAnag(partyId: string, partyLockId: string) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('idNode', this.proposalService.getApiContract().nodeId);

    return this.httpClient.get<any>(this.baseApiUrl + '/anag/subject/' + partyId,
      {params: httpParams});
  }

  getParties(contractId: string) {
    return this.httpClient.get<PartiesData>(this.baseApiUrlV2 + '/motor/contract/' + contractId + '/parties');
  }

  getPartiesOffset(contractId: string, disableOffset: string) {
    return this.httpClient.get<PartiesData>(this.baseApiUrlV2 + '/motor/contract/' + contractId + '/parties?disableOffset='+disableOffset);
  }

  updateParties(contractId: string, partiesData: PartiesData) : Observable<PartiesData> {
    return this.httpClient.put<PartiesData>(this.baseApiUrlV2 + '/motor/contract/' + contractId + '/parties', partiesData);
  }

  checkParties(resourceId: string, disableOffset?: boolean) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('disableOffset', '' + disableOffset);
    return this.httpClient.post<PartiesData>(this.baseApiUrlV2 + '/motor/drafts/' + resourceId + '/parties/check', null, {params: httpParams})
  }

  public getPartyObserable() {
    return this.partyChannel.asObservable();
  }

  public setParty(newParty: Array<any>) {
    this.partyChannel.next(newParty);
  }

  public getEntitledParty() {
    return this.entitled;
  }

  public setEntitledParty(entitled: any) {
    this.entitled = entitled;
  }

  public setProposedEntitled(proposedEntitled: Entitled) {
    if (proposedEntitled && proposedEntitled.parties && proposedEntitled.parties.length > 0) {
      this.isProposedEntitled = true;
      this.proposedEntitledFiscalCode = proposedEntitled.parties[0].fiscalCode;
      this.proposedEntitledVat = proposedEntitled.parties[0].vat;
    }
  }

  public resetProposedEntitled() {
    this.isProposedEntitled = false;
    this.proposedEntitledFiscalCode = null;
    this.proposedEntitledVat = null;
  }

  public isProposedEntitledChanged() {
    let changed = false;

    if (this.entitled && this.isProposedEntitled) {
      changed = (this.proposedEntitledFiscalCode !== this.entitled.fiscalCode) ||
        (this.proposedEntitledVat !== this.entitled.vat);
    }
    return changed;
  }

  hasLastUpdateChangedPartyResidence() {
    return this.lastUpdateChangedPartyResidence;
  }

  setLastUpdateChangedPartyResidence(hasResidenceChanged) {
    if (hasResidenceChanged === null) {
      hasResidenceChanged = false;
    }
    this.lastUpdateChangedPartyResidence = hasResidenceChanged;
  }

  public getStartCheckProposalObservable() {
    return this.startCheckProposalDataChannel.asObservable();
  }

  public startCheckProposal(forceRecalculatePremium: boolean) {
    this.startCheckProposalDataChannel.next(forceRecalculatePremium);
  }

  public getSurveyFilledOutObservable() {
    return this.surveyFilledOutDataChannel.asObservable();
  }

  public setSurveyFilledOut(survey: any) {
    this.surveyFilledOutDataChannel.next(survey);
  }

  public getSubscriberChangedObservable() {
    return this.subscriberChangedChannel.asObservable();
  }

  public sendSubscriberChanged() {
    this.subscriberChangedChannel.next();
  }

  public openAnagModal(anagFlowData?: AnagFlowData) {
    anagFlowData.idParentSession = this.routingServiceNMF.getIdCardSession();
    this.anagSearchorCreateResolver.searchOrCreateSubject(anagFlowData).subscribe(
      party => this.setParty([this._transformPartyData(party)])
    );
  }

  protected _transformPartyData(party: any) {
    if (party) {
      const newParty = {

        birthAddress: this._getAddress(party.birthAddress),
        company: this._getEnum(party.company),
        citizenship: this._getEnum(party.citizenship),
        cityOfBirth: party.cityOfBirth,
        countryOfBirth: this._getEnum(party.countryOfBirth),
        mobilePhone: undefined,
        dateOfBirth: party.dateOfBirth,
        denomination: party.denomination,
        document: this._getDocument(party.document),
        dynamicObjects: party.dynamicObjects,
        emails: party.emails,
        fiscalCode: party.fiscalCode,
        idLatestPhotos: party.idLatestPhotos,
        landlineNumber: this._getPhoneContact(party.landlineNumber),
        links: party.links,
        mainContact: this._getEnum(party.mainContact),
        name: party.name,
        node: this._getIdentification(party.node),
        nominative: party.nominative,
        partyId: party.objectId,
        partyKey: undefined,
        payments: party.payments,
        personType: this._getEnum(party.personType),
        phoneFax: this._getPhoneContact(party.phoneFax),
        privacyConsensus: undefined,
        privacyConsensusDate: party.privacyConsensusDate,
        professionDetail: this._getEnum(party.professionDetail),
        professionType: this._getEnum(party.professionType),
        residence: this._getAddress(party.residence),
        sex: this._getEnum(party.sex),
        subjectType: this._getEnum(party.subjectType),
        surname: party.surname,
        honorTitle: this._getEnum(party.salutation),
        sonsNumber: party.sonsNumber,
        language: party.language,
        politicallyExp: party.politicallyExp,
        counterTerrorismDate: party.counterTerrorismDate,
        counterTerrorismStatus: party.counterTerrorismStatus,
        codAteco: this._getEnum(party.codAteco),
        otherAddress: undefined,
        corporateForm: this._getEnum(party.corporateForm)
      };

      if (party.partyKey) {
        newParty.partyKey = [];

        party.partyKey.forEach(el => {
          newParty.partyKey.push(this._getPartyKeyContainer(el));
        });
      }

      if (party.mobilePhone) {
        newParty.mobilePhone = [];

        party.mobilePhone.forEach(el => {
          newParty.mobilePhone.push(this._getPhoneContact(el));
        });
      }

      if (party.privacyConsensus) {
        newParty.privacyConsensus = [];

        party.privacyConsensus.forEach(el => {
          newParty.privacyConsensus.push(this._getPrivacyConsent(el));
        });
      }

      /* if (party.links) {
         newParty.links = [];

         party.links.forEach(el => {
           newParty.links.push(this._getAjsLink(el));
         });
       }*/

      if (party.otherAddress) {
        newParty.otherAddress = [];

        party.otherAddress.forEach(el => {
          newParty.otherAddress.push(this._getAjsOtherAddress(el));
        });
      }

      if (party.payments) {
        newParty.payments = [];

        party.payments.forEach(el => {
          newParty.payments.push(this._getAjsPayment(el));
        });
      }

      return newParty;
    }
  }

  protected _getEnum(enumVal) {
    let newEnumVal = null;

    if (enumVal) {
      newEnumVal = {
        code: enumVal.codice,
        description: enumVal.descrizione
      };
    }
    return newEnumVal;
  }

  protected _getIdentification(identificationVal) {
    let newIdentificationVal = null;

    if (identificationVal) {
      newIdentificationVal = {
        id: identificationVal.identification,
        code: identificationVal.code,
        description: identificationVal.description
      };
    }
    return newIdentificationVal;
  }

  protected _getAddress(addressVal) {
    let newAddressVal = null;

    if (addressVal) {
      newAddressVal = {
        country: this._getEnum(addressVal.country),
        province: this._getEnum(addressVal.province),
        city: addressVal.city,
        placeAddress: addressVal.placeAddress,
        number: addressVal.number,
        cap: addressVal.cap,
        countryCode: addressVal.countryCode,
        adminLevel1: addressVal.adminLevel1,
        adminLevel1Short: addressVal.adminLevel1Short,
        adminLevel2: addressVal.adminLevel2,
        adminLevel2Short: addressVal.adminLevel2Short,
        adminLevel3: addressVal.adminLevel3,
        adminLevel3Short: addressVal.adminLevel3Short,
        formatAddress: addressVal.formatAddress,
        locality: addressVal.locality,
        fraction: addressVal.fraction,
        toponym: this._getEnum(addressVal.toponym),
        normalized: addressVal.normalized
      };
    }
    return newAddressVal;
  }

  protected _getPhoneContact(phoneContact) {
    let newPhoneContact = null;

    if (phoneContact) {
      newPhoneContact = {
        id: phoneContact.objectId,
        number: phoneContact.number,
        internationalPrefix: phoneContact.internationalPrefix,
        localPrefix: phoneContact.localPrefix
      };
    }
    return newPhoneContact;
  }

  protected _getPrivacyConsent(privacyConsent) {
    let newPrivacyConsent = null;

    if (privacyConsent) {
      newPrivacyConsent = {
        id: privacyConsent.objectId,
        privacy: this._getIdentification(privacyConsent.privacy),
        privacyConsensusValue: this._getEnum(privacyConsent.privacyConsensusValue),
        privacyConsentOrigin: privacyConsent.privacyConsentOrigin,
        privacyConsentDate: privacyConsent.privacyConsentDate,
        privacyConsentOriginDescription: privacyConsent.privacyConsentOriginDescription
      };
    }
    return newPrivacyConsent;
  }

  protected _getPartyKeyContainer(partyKeyContainer) {
    let newPartyKeyContainer = null;

    if (partyKeyContainer) {
      newPartyKeyContainer = {
        id: partyKeyContainer.objectId,
        country: partyKeyContainer.country,
        main: partyKeyContainer.main,
        key1: this._getPartyKey(partyKeyContainer.key1),
        key2: this._getPartyKey(partyKeyContainer.key2),
        key3: this._getPartyKey(partyKeyContainer.key3)
      };
    }
    return newPartyKeyContainer;
  }

  protected _getPartyKey(partyKey) {
    let newPartyKey = null;

    if (partyKey) {
      newPartyKey = {
        label: partyKey.label,
        state: partyKey.state,
        value: partyKey.value,
        type: this._getEnum(partyKey.type)
      };
    }
    return newPartyKey;
  }

  public _getAjsParty(party) {
    if (party) {
      const newParty = {
        birthAddress: undefined,
        citizenship: undefined,
        cityOfBirth: undefined,
        codAteco: undefined,
        company: undefined,
        corporateForm: undefined,
        countryOfBirth: undefined,
        dateOfBirth: undefined,
        denomination: undefined,
        dynamicObjects: undefined,
        document: undefined,
        emails: undefined,
        extensionSubject: undefined,
        fiscalCode: undefined,
        gruppoAE: undefined,
        genderIdentity: undefined,
        idLatestPhotos: undefined,
        limitation: undefined,
        links: undefined,
        landlineNumber: undefined,
        maritalStatus: undefined,
        mainContact: undefined,
        mobilePhone: undefined,
        name: undefined,
        node: undefined,
        nominative: undefined,
        objectId: undefined,
        partyKey: undefined,
        partyNumber: undefined,
        payments: undefined,
        personType: undefined,
        phoneFax: undefined,
        privacyConsensus: undefined,
        privacyConsensusDate: undefined,
        professionDetail: undefined,
        professionType: undefined,
        residence: undefined,
        sex: undefined,
        sottogruppoAE: undefined,
        subjectType: undefined,
        subjectNodes: undefined,
        surname: undefined,
        vat: undefined,
        corporateSector: undefined,
        honorTitle: undefined,

        sonsNumber: undefined,
        language: undefined,
        politicallyExp: undefined,
        counterTerrorismDate: undefined,
        counterTerrorismStatus: undefined,
        otherAddress: undefined,

      };
      // newParty.provinceOfBirth = this._getAjsEnum(party.provinceOfBirth); // provinceOfBirth not managed by @rgi/anag
      newParty.company = party.company;
      newParty.birthAddress = this._getAjsAddress(party.birthAddress);
      newParty.citizenship = this._getAjsEnum(party.citizenship);
      newParty.cityOfBirth = party.cityOfBirth;
      newParty.countryOfBirth = this._getAjsEnum(party.countryOfBirth);
      newParty.dateOfBirth = party.dateOfBirth;
      newParty.denomination = party.denomination;
      newParty.dynamicObjects = party.dynamicObjects;
      newParty.emails = party.emails;
      newParty.fiscalCode = party.fiscalCode;
      newParty.genderIdentity = party.genderIdentity;
      newParty.idLatestPhotos = party.idLatestPhotos;
      newParty.landlineNumber = this._getAjsPhoneContact(party.landlineNumber);
      newParty.links = [];
      newParty.mainContact = this._getAjsEnum(party.mainContact);
      newParty.mobilePhone = [];
      newParty.name = party.name;
      newParty.node = this._getAjsIdentification(party.node);
      newParty.nominative = party.nominative;
      newParty.objectId = party.partyId;
      newParty.partyKey = party.partyKey;
      newParty.personType = this._getAjsEnum(party.personType);
      newParty.phoneFax = this._getAjsPhoneContact(party.phoneFax);
      newParty.privacyConsensus = [];
      newParty.privacyConsensusDate = party.privacyConsensusDate;
      newParty.professionDetail = this._getAjsEnum(party.professionDetail);
      newParty.professionType = this._getAjsEnum(party.professionType);
      newParty.residence = this._getAjsAddress(party.residence);
      newParty.sex = this._getAjsEnum(party.sex);
      newParty.subjectType = this._getAjsEnum(party.subjectType);
      newParty.maritalStatus = this._getAjsEnum(party.maritalStatus);
      newParty.document = party.document;
      newParty.surname = party.surname;
      newParty.honorTitle = this._getAjsEnum(party.salutation);
      newParty.sonsNumber = party.sonsNumber;
      newParty.language = this._getAjsEnum(party.language);
      newParty.politicallyExp = party.politicallyExp;
      newParty.counterTerrorismDate = party.counterTerrorismDate;
      newParty.counterTerrorismStatus = party.counterTerrorismStatus;
      newParty.otherAddress = [];
      newParty.payments = [];
      newParty.codAteco = this._getAjsEnum(party.codAteco);
      newParty.corporateForm = this._getAjsEnum(party.corporateForm);

      if (party.partyKey) {
        party.partyKey.forEach(el => {
          newParty.partyKey.push(this._getAjsPartyKeyContainer(el));
        });
      }

      if (party.mobilePhone) {
        party.mobilePhone.forEach(el => {
          newParty.mobilePhone.push(this._getAjsPhoneContact(el));
        });
      }

      if (party.privacyConsensus) {
        party.privacyConsensus.forEach(el => {
          newParty.privacyConsensus.push(this._getAjsPrivacyConsent(el));
        });
      }

      /*if (party.links) {
        party.links.forEach(el => {
          newParty.links.push(this._getAjsLink(el));
        });
      }*/

      if (party.otherAddress) {
        party.otherAddress.forEach(el => {
          newParty.otherAddress.push(this._getAjsOtherAddress(el));
        });
      }

      if (party.payments) {
        party.payments.forEach(el => {
          newParty.payments.push(this._getAjsPayment(el));
        });
      }

      return newParty;
    }
  }

  protected _getAjsEnum(enumVal) {
    let newEnumVal = null;

    if (enumVal) {
      newEnumVal = {
        codice: enumVal.code,
        descrizione: enumVal.description
      };
    }
    return newEnumVal;
  }

  protected _getAjsIdentification(identificationVal) {
    let newIdentificationVal = null;

    if (identificationVal) {
      newIdentificationVal = {
        identification: identificationVal.id,
        code: identificationVal.code,
        description: identificationVal.description
      };
    }
    return newIdentificationVal;
  }

  protected _getAjsAddress(addressVal) {
    let newAddressVal = null;

    if (addressVal) {
      newAddressVal = {
        country: this._getAjsEnum(addressVal.country),
        province: this._getAjsEnum(addressVal.province),
        city: addressVal.city,
        placeAddress: addressVal.placeAddress,
        number: addressVal.number,
        cap: addressVal.cap,
        countryCode: addressVal.countryCode,
        adminLevel1: addressVal.adminLevel1,
        adminLevel1Short: addressVal.adminLevel1Short,
        adminLevel2: addressVal.adminLevel2,
        adminLevel2Short: addressVal.adminLevel2Short,
        adminLevel3: addressVal.adminLevel3,
        adminLevel3Short: addressVal.adminLevel3Short,
        formatAddress: addressVal.formatAddress,
        locality: addressVal.locality,
        fraction: addressVal.fraction,
        toponym: this._getAjsEnum(addressVal.toponym),
        normalized: addressVal.normalized
      };
    }
    return newAddressVal;
  }

  protected _getAjsPhoneContact(phoneContact) {
    let newPhoneContact = null;

    if (phoneContact) {
      newPhoneContact = {
        objectId: phoneContact.id,
        number: phoneContact.number,
        internationalPrefix: phoneContact.internationalPrefix,
        localPrefix: phoneContact.localPrefix
      };
    }
    return newPhoneContact;
  }

  protected _getAjsPartyKeyContainer(partyKeyContainer) {
    let newPartyKeyContainer = null;

    if (partyKeyContainer) {
      newPartyKeyContainer = {
        objectId: partyKeyContainer.id,
        country: partyKeyContainer.country,
        main: partyKeyContainer.main,
        key1: this._getAjsPartyKey(partyKeyContainer.key1),
        key2: this._getAjsPartyKey(partyKeyContainer.key2),
        key3: this._getAjsPartyKey(partyKeyContainer.key3)
      };
    }
    return newPartyKeyContainer;
  }

  protected _getAjsPrivacyConsent(privacyConsent) {
    let newPrivacyConsent = null;

    if (privacyConsent) {
      newPrivacyConsent = {
        objectId: privacyConsent.id,
        privacy: this._getAjsIdentification(privacyConsent.privacy),
        privacyConsensusValue: this._getAjsEnum(privacyConsent.privacyConsensusValue),
        privacyConsentDate: privacyConsent.privacyConsentDate
      };
    }
    return newPrivacyConsent;
  }

  protected _getAjsPartyKey(partyKey) {
    let newPartyKey = null;
    if (partyKey) {
      newPartyKey = {
        label: partyKey.label,
        state: partyKey.state,
        value: partyKey.value,
        type: this._getAjsEnum(partyKey.type)
      };
    }
    return newPartyKey;
  }

  public goToEditParty(party: any, anagFlowData?: AnagFlowData): Observable<any> {
    anagFlowData.idParentSession = this.routingServiceNMF.getIdCardSession();
    return this.anagEditResolver.editParty(/*this._getAjsParty(*/party, anagFlowData).pipe(map(editedParty => {
      const nmfParty = this._transformPartyData(editedParty);
      this.setParty([nmfParty]);
      return nmfParty;
    }));
  }

  private _getDocument(document: AnagDocument) {
    let newDocument = null;

    if (document) {
      newDocument = {
        authoritiesRelease: this._getAjsEnum(document.authoritiesRelease),
        documentAddress: document.documentAddress,
        documentNumber: document.documentNumber,
        documentType: this._getAjsEnum(document.documentType),
        expirationDate: document.expirationDate,
        extensionSubject: document.extensionSubject,
        locationsRelease: document.locationsRelease,
        objectId: document.objectId,
        releaseDate: document.releaseDate,
      };
    }
    return newDocument;
  }

  private _getAjsLink(link) {
    let newLink = null;
    if (link) {
      newLink = {
        idSubjectLinkType: link.idSubjectLinkType,
        linkSubjectNominative: link.linkSubjectNominative,
        linkType: this._getAjsEnum(link.linkType),
        linkedPartyObjectId: link.linkedPartyObjectId,
        linkedPartyUniqueKey: link.linkedPartyUniqueKey,
        notes: link.notes
      };
    }
    return newLink;
  }

  private _getAjsOtherAddress(otherAddress) {
    let newOtherAddress = null;
    if (otherAddress) {
      newOtherAddress = {
        addressType: this._getAjsEnum(otherAddress.addressType),
        availabilityEndDate: otherAddress.availabilityEndDate,
        availabilityStartDate: otherAddress.availabilityStartDate,
        availableFrom: otherAddress.availableFrom,
        availableUntil: otherAddress.availableUntil,
        baseAddress: this._getAddress(otherAddress.baseAddress),
        fax: this._getPhoneContact(otherAddress.fax),
        mainAddress: otherAddress.mainAddress,
        objectId: otherAddress.objectId,
        phone: this._getPhoneContact(otherAddress.phone)
      };
    }
    return newOtherAddress;
  }

  private _getAjsPayment(payment) {
    let newPayment = null;
    if (payment) {
      newPayment = {
        activationDateSDD: payment.activationDateSDD,
        addressHolder: payment.addressHolder,
        bankAddress: payment.bankAddress,
        bankName: payment.bankName,
        cancelDateSDD: payment.cancelDateSDD,
        cartaDiCreditoNumero: payment.cartaDiCreditoNumero,
        cartaDiCreditoScadenza: payment.cartaDiCreditoScadenza,
        cartaDiCreditoTipo: payment.cartaDiCreditoTipo,
        cartaDiCreditoTitolare: payment.cartaDiCreditoTitolare,
        cash: payment.cash,
        ccABI: payment.ccABI,
        ccBic: payment.ccBic,
        ccCAB: payment.ccCAB,
        ccCIN: payment.ccCIN,
        ccCINIBAN: payment.ccCINIBAN,
        ccCodiceNazioneIBAN: payment.ccCodiceNazioneIBAN,
        ccIBAN: payment.ccIBAN,
        ccNumeroConto: payment.ccNumeroConto,
        checkNumber: payment.checkNumber,
        codeSDD: payment.codeSDD,
        dateNotification: payment.dateNotification,
        extensionSubject: payment.extensionSubject,
        foreignAccount: payment.foreignAccount,
        foreignCurrency: payment.foreignCurrency,
        holder: payment.holder,
        ibanHolder: payment.ibanHolder,
        objectId: payment.objectId,
        paymentType: payment.paymentType,
        paymentTypeDescription: payment.paymentTypeDescription,
        paymentTypeID: payment.paymentTypeID,
        regulationDate: payment.regulationDate,
        rum: payment.rum,
        stateSDD: payment.stateSDD,
        transactionDescription: payment.transactionDescription,
        transactionNumber: payment.transactionNumber,
        uic: payment.uic
      };
    }
    return newPayment;
  }

  async offsetAlternativeRole(): Promise<number> {
    return new Promise((resolve) => {
      if (this.storedAlternativeRoleOffset) {
        resolve(this.storedAlternativeRoleOffset);
      }
      this.commonService.getSystemPoperty(this.alternativeRoleOffsetKey).pipe(take(1)).subscribe(resp => {
        this.storedAlternativeRoleOffset = +resp.value;
        resolve(this.storedAlternativeRoleOffset);
      }, () => {
        resolve(0);
      });
    });
  }
}

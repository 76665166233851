export class ManagePhaseEntity {
  id: string;
  desc: string;
  area: string;


  constructor(id: string, desc: string, area: string) {
    this.id = id;
    this.desc = desc;
    this.area = area;
  }
}

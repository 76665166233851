import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ReserveRequest } from './dto/reserve-request';
import { ReserveUpdateRequest } from './dto/reserve-update-request';
import { IReasonReopening } from './dto/reopeningReactivation/reopening-reactivation.interface';

@Injectable({
  providedIn: 'root'
})
export class UpdateReserveService {
  private baseApiUrl: string; 
  private baseApiUrlV2: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  private getLoadingReserveUri() {
    return this.baseApiUrlV2 + '/claims/loading-update-reserve';
  }

  private getSaveReserveUri() {
    return this.baseApiUrlV2 + '/claims/checkupdate-reserve/multi';
  }
  private getChekUpdateReactivationMulti() {
    return this.baseApiUrlV2 + '/claims/checkupdate-reactivation/multi';
  }
  private getReasonReopeningEndpoint() {
    return this.baseApiUrlV2 + '/claims/reopening/reasonsReopening ';
  }

  public loadingUpdateReserve(request: ReserveRequest) {
    return this.httpClient.post(this.getLoadingReserveUri(), request);
  }

  public saveUpdateReserve(request: ReserveUpdateRequest) {
    return this.httpClient.post(this.getSaveReserveUri(), request);
  }
  public chekUpdateReactivationMulti(request: ReserveUpdateRequest) {
    return this.httpClient.post(this.getChekUpdateReactivationMulti(), request);
  }
  public getReasonReopening(request: IReasonReopening) {
    return this.httpClient.post(this.getReasonReopeningEndpoint(), request);
  }

  public getDetailClaim(queryParams: string) {
    return this.httpClient.get<any>(
      this.baseApiUrl + '/claims/claim/' + queryParams);
  }
}

import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { DamagedAsset, Party } from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../../../claim-opening.service';
import { formatDate } from '@angular/common';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-party-data-new-asset',
  templateUrl: './party-data-new-asset.component.html',
  styleUrls: ['./party-data-new-asset.component.scss']
})
export class PartyDataNewAssetComponent implements OnInit {

  addAssetForm: UntypedFormGroup;
  hasError: boolean;
  hasErrorVehicle: boolean;
  errorMessage: string;
  errorMessages: string[] = [];
  disableTypeLoss: boolean;
  selThings: boolean = false;
  selInjury: boolean = false;
  selVehicle: boolean = false;

  @Input() docSelected: any;
  @Input() typesOfLoss: { id: string, description: string }[];
  @Input() party: Party;
  @Input() nextId: number;
  @Input() card: any;
  @Input() comeFrom: string;
  @Input() injuryCauseList: any[];
  @Input() injuryNatureList: Map<string, any[]>;
  @Input() injuryLocationList: Map<string, any[]>;
  @Input() professionList: any[];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private claimOpeningService: ClaimOpeningService,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private rxTranslationsService: RgiRxTranslationService
  ) {
  }

  ngOnInit() {
    this.addAssetForm = this.claimOpeningService.getAddAssetForm();
    if (!this.addAssetForm) {
      this.addAssetForm = this.formBuilder.group(
        {
          description: [, Validators.required],
          type: []
        }
      );
      this.addAssetForm.addControl('nominative',
      new UntypedFormControl(Validators.required));
      this.addAssetForm.controls.nominative.setValue(this.party.description);
      this.claimOpeningService.setAddAssetForm(this.addAssetForm);
      this.selThings = false;
      if (!this.typesOfLoss || this.typesOfLoss.length === 0) {
        this.typesOfLoss = [];
        this.rxTranslationsService.translate('_CLAIMS_._THINGS').subscribe(
          (res) => {
            this.typesOfLoss.push({ id: '3', description: res });
          })  
      }

      if (this.typesOfLoss.length > 1) {
        this.disableTypeLoss = false;
      } else {
        this.disableTypeLoss = true;
        this.addAssetForm.controls.type.setValue(this.typesOfLoss[0].id);
        this.assetDetail();
      }
    } else {
      this.assetDetail();
      if (this.docSelected) {
        this.addAssetForm.controls.doctorName.setValue(this.docSelected.description);
        this.addAssetForm.controls.doctorIdSoggPA.setValue(this.docSelected.id);
        this.addAssetForm.controls.doctorIdSogg.setValue(this.docSelected.data.idLatestPhotos);
      }
    }
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  assetDetail() {
    if (this.addAssetForm.controls.type.value === '3') {
      this.selThings = true;
      this.selInjury = false;
      this.selVehicle = false;
    }
    if (this.addAssetForm.controls.type.value === '2') {
      this.selThings = false;
      this.selInjury = true;
      this.selVehicle = false;
    }
    if (this.addAssetForm.controls.type.value === '1') {
      this.selThings = false;
      this.selInjury = false;
      this.selVehicle = true;
    }
  }
  addAsset(event: any) {
    this.clearError();
    if (this.hasErrorVehicle) {
      return false;
    }
    this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES')
    .subscribe(
      (res) => {
        const error = res;
        if (!this.selInjury && !this.selVehicle && !this.addAssetForm.controls.description.value) {
          this.addAssetForm.controls.description.markAsDirty();
          this.signalError(error);
          return false;
        } else if (this.selVehicle) {
    
          if (!this.addAssetForm.controls.licensePlate.value &&
              !this.addAssetForm.controls.chassisNumber.value) {
              this.addAssetForm.controls.licensePlate.markAsDirty();
              this.addAssetForm.controls.chassisNumber.markAsDirty();
              this.signalError(error);
              return false;
          } else if (!this.addAssetForm.controls.licensePlateType.value) {
              this.addAssetForm.controls.licensePlateType.markAsDirty();
              this.signalError(error);
              return false;
          }
        } else if (this.selInjury && !this.addAssetForm.controls.nominative.value) {
          this.addAssetForm.controls.nominative.markAsDirty();
          this.signalError(error);
          return false;
        }
      })

    const that = this;
    const newAsset = new DamagedAsset();
    const assetType = this.addAssetForm.controls.type.value;
    newAsset.id = 'T_' + assetType + '_' + this.nextId;
    newAsset.third = true;
    newAsset.assettype = assetType;

    if (newAsset.assettype === '3') {
     newAsset.description = this.addAssetForm.controls.description.value;
     this.addAssetForm.controls.description.setValue('');
    }

    if (newAsset.assettype === '1') {
        this.rxTranslationsService.translate('_CLAIMS_._VEHICLE')
        .subscribe(
          (res) => {
            newAsset.description = res + ' ' +
            this.addAssetForm.controls.licensePlate.value ? this.addAssetForm.controls.licensePlate.value : '' + ' ' +
            this.addAssetForm.controls.chassisNumber.value ? this.addAssetForm.controls.chassisNumber.value : '';
          })
      newAsset.chassis = this.addAssetForm.controls.chassisNumber.value;
      newAsset.vehicleplate = this.addAssetForm.controls.licensePlate.value;
      newAsset.vehicleplatetypeCode = this.addAssetForm.controls.licensePlateType.value;
      newAsset.vehicleplatetype = this.addAssetForm.controls.licensePlateTypeDescr.value;
      newAsset.modeldescr = this.addAssetForm.controls.model.value;
      newAsset.immatricolationdate = this.addAssetForm.controls.regDate.value;
      newAsset.nationcode = this.addAssetForm.controls.selectedCountry.value;
      newAsset.manufacturerdescr = this.addAssetForm.controls.brand.value;
      newAsset.vehicleTypeAnia = this.addAssetForm.controls.vehicleType.value;
      newAsset.vehicleType = this.addAssetForm.controls.vehicleTypePP.value;
      newAsset.vehicleTypeDescr = this.addAssetForm.controls.vehicleTypeDescr.value;
      newAsset.parked = this.addAssetForm.controls.parked.value;

      this.addAssetForm.controls.licensePlateType.setValue(null);
      this.addAssetForm.controls.licensePlateTypeDescr.setValue(null);
      this.addAssetForm.controls.vehicleType.setValue(null);
      this.addAssetForm.controls.vehicleTypePP.setValue(null);
      this.addAssetForm.controls.vehicleTypeDescr.setValue(null);
      this.addAssetForm.controls.licensePlate.setValue(null);
      this.addAssetForm.controls.chassisNumber.setValue(null);
      this.addAssetForm.controls.brand.setValue(null);
      this.addAssetForm.controls.model.setValue(null);
      this.addAssetForm.controls.selectedCountry.setValue('');
      this.addAssetForm.controls.regDate.setValue(null);
      this.addAssetForm.controls.parked.setValue(false);

    }

    if (newAsset.assettype === '2') {
      // lesioni
      const formatdate = 'dd-MM-yyyy';
      const fatalDateString = (this.addAssetForm.controls.fatalDate.value != null) ?
               formatDate(this.addAssetForm.controls.fatalDate.value, formatdate, this.locale) : null;

      newAsset.description = this.party.description && this.party.description.length > 0 ?
                             this.party.description : this.addAssetForm.controls.nominative.value;
      if (this.addAssetForm.controls.percIP.value &&
          this.addAssetForm.controls.percIP.value !== null) {
       newAsset.percIP = this.addAssetForm.controls.percIP.value;
      }
      if (this.addAssetForm.controls.ggITB.value &&
          this.addAssetForm.controls.ggITB.value !== null) {
       newAsset.ggITB = this.addAssetForm.controls.ggITB.value;
      }
      if (fatalDateString && fatalDateString !== null) {
       newAsset.fatalDate = fatalDateString;
      }
      newAsset.selectedCountryER = this.addAssetForm.controls.selectedCountryER.value;
      newAsset.placeER = this.addAssetForm.controls.placeER.value;
      newAsset.descriptionER = this.addAssetForm.controls.descriptionER.value;
      newAsset.idER = this.addAssetForm.controls.idER.value;
      newAsset.biologicalDamage = this.addAssetForm.controls.biologicalDamage.value;
      newAsset.surroga = this.addAssetForm.controls.surroga.value;
      newAsset.doctorIdSogg = this.addAssetForm.controls.doctorIdSogg.value;
      newAsset.doctorIdSoggPA = this.addAssetForm.controls.doctorIdSoggPA.value;
      newAsset.doctorName = this.addAssetForm.controls.doctorName.value;
      newAsset.moreInjury = this.addAssetForm.controls.moreInjury.value;
      newAsset.profession = this.addAssetForm.controls.profession.value;
      newAsset.injuryCause = this.addAssetForm.controls.injuryCause.value;
      newAsset.injuryDamageList = new Map<string, string>();
      this.addAssetForm.controls.damageList.value
      .forEach(dam => {
        newAsset.injuryDamageList[dam.idNat] = dam.idLoc;
      });
      this.addAssetForm.controls.percIP.setValue(null);
      this.addAssetForm.controls.ggITB.setValue(null);
      this.addAssetForm.controls.surroga.setValue('');
      this.addAssetForm.controls.injuryCause.setValue('');
      this.addAssetForm.controls.profession.setValue('');
      this.addAssetForm.controls.selectedCountryER.setValue('');
      this.addAssetForm.controls.fatalDate.setValue(null);
      this.addAssetForm.controls.biologicalDamage.setValue(false);
      this.addAssetForm.controls.moreInjury.setValue(false);
      this.addAssetForm.controls.idER.setValue('');
      this.addAssetForm.controls.descriptionER.setValue('');
      this.addAssetForm.controls.placeER.setValue('');
      this.addAssetForm.controls.doctorIdSogg.setValue('');
      this.addAssetForm.controls.doctorIdSoggPA.setValue('');
      this.addAssetForm.controls.doctorName.setValue('');
      this.addAssetForm.controls.damageList.setValue(new Array<any>());
      this.addAssetForm.controls.notCodeable.setValue(false);
      this.addAssetForm.controls.multiple.setValue(false);
    }
    this.selInjury = false;
    that.activeModal.close(newAsset);
    event.stopPropagation();
    if (this.addAssetForm.controls.componentActive) {
     this.addAssetForm.controls.componentActive.setValue(false);
    }
    return true;
  }

  signalError(errStr: string) {
    this.hasError = true;
    this.errorMessage = errStr;
    this.errorMessages.push(errStr);
  }

  clearError() {
    this.hasError = false;
    this.errorMessage = null;
    this.errorMessages = [];
  }

  validationVehicleSet(event: boolean) {
    if (!event) {
      this.hasErrorVehicle = true;
    } else {
      this.hasErrorVehicle = false;
    }
  }

}

import { LegalFeeEntity } from '../../new-payment/domain/legal-fee-entity';

export class ModifyNdCLegalInputItem {
  public legalFeeEntity: LegalFeeEntity;
  public idClaim: number;
  public idActivity: number;
  public idLiqStorno: number;
  public idLiq: number;
  public idDatiFattura: number;
  public idLegal: number;
  public idCausaleDanno: number;
}

import {Component, OnInit} from '@angular/core';
import {RgiPncPostsalesStepComponent} from '../../api/rgi-pnc-postsales-step.component';
import {FormService} from '../../services/form.service';
import {PncPsalesTechAccountingState} from '../../resources/states/technical-accounting-state';
import {
  PncPsalesTechAccountingStateManager
} from '../../state-managers/technical-accounting/tech-accounting-state-manager.service';

@Component({
  selector: 'rgi-pnc-psales-technical-accounting',
  templateUrl: './technical-accounting.component.html'
})
export class PncPsalesTechnicalAccountingComponent extends RgiPncPostsalesStepComponent<PncPsalesTechAccountingState> implements OnInit {

  constructor(public stateMgr: PncPsalesTechAccountingStateManager, formService: FormService) {
    super(stateMgr, formService);
  }

  ngOnInit() {
  }

}

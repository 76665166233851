import {Component, Input} from '@angular/core';
import {TaxDetailAPI} from '../../resources/model/api/tax-detail';

@Component({
  selector: 'rgi-pnc-psales-taxes-detail',
  templateUrl: './pnc-taxes-detail.component.html'
})
export class PncPsalesTaxesDetailComponent {
  @Input() taxes: TaxDetailAPI[];
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {ContractorDataResponse} from '../../../models/pc-portfolio-models/contractor-models/contractor-data-response';
import {ContractorDataRequest} from '../../../models/pc-portfolio-models/contractor-models/contractor-data-request';
import {AnagIssueSubject} from '../../re-issue-anag/anag-issue';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioContractorService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  postContractor(proposalId: string, request: ContractorDataRequest): Observable<ContractorDataResponse> {
    return this.http.post<ContractorDataResponse>(this.getContractorUri(proposalId), request).pipe(
      share()
    );
  }
  postCoOwners(proposalId: string, request: ContractorDataRequest): Observable<AnagIssueSubject> {
    return this.http.post<AnagIssueSubject>(this.getCoOwnersUri(proposalId), request).pipe(
      share()
    );
  }

  deleteCoOwner(proposalId: string, subjectId: string){
    const url= this.getCoOwnersUri(proposalId) +`/${subjectId}`;
    return this.http.delete(url).pipe(
      share()
    );
  }

  getContractor(proposalId: string): Observable<ContractorDataResponse> {
    return this.http.get<ContractorDataResponse>(this.getContractorUri(proposalId)).pipe(
      share()
    );
  }


  getCoOwners(proposalId: string): Observable<AnagIssueSubject[]> {
    return this.http.get<Array<AnagIssueSubject>>(this.getCoOwnersUri(proposalId)).pipe(
      share()
    );
  }

  getContractorUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/contractor';
  }

  getCoOwnersUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/co-owners';
  }
}

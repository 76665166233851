import {Component, ElementRef, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {RgiRxQAService} from '@rgi/rx';

let rgiRxPanelCounter = 0;

/**
 * The panel is a simple content wrapper that can host content wrapped eventually by an header and a footer.
 *
 * ```ts
 * @Component({
 *     selector: 'app-ui-panel',
 *     template: `
 *     <rgi-rx-panel [class.example-panel-hide]="!display">
 *          <rgi-rx-panel-header (onClose)="display = false" [closeable]="true">Hello!</rgi-rx-panel-header>
 *              <div>
 *                   this is my panel content
 *              </div>
 *          <rgi-rx-panel-footer>
 *              <button class="rgi-ui-btn rgi-ui-btn-primary"  (click)="display=false">CLOSE</button>
 *          </rgi-rx-panel-footer>
 *     </rgi-rx-panel>
 *     <button class="rgi-ui-btn rgi-ui-btn-default" *ngIf="!display" (click)="display=true">SHOW AGAIN</button>
 *     `,
 *     styles:[
 *         `
 *             .example-panel-hide {
 *                 display: none;
 *             }
 *         `
 *     ]
 * })
 * export class UiPanelComponent {
 *     display = true;
 *     onClosePanel($event: Event) {
 *         alert(`Closed!`)
 *     }
 * }
 * ```
 */
@Component({
  selector: 'rgi-rx-panel',
  templateUrl: './rgi-rx-panel.component.html',
  host: {
    class: 'rgi-ui-panel-container'
  },
  providers: [RgiRxQAService]
})
export class RgiRxPanelComponent implements OnInit, OnDestroy {

  private _id = `rgi-rx-panel-${rgiRxPanelCounter}`;


  @HostBinding('id')
  get id(): string {
    return this._id;
  }

  constructor(private QA: RgiRxQAService,
              private elementRef: ElementRef) {
    rgiRxPanelCounter++;
  }

  ngOnInit() {
    this.QA.render(this.elementRef.nativeElement, {
      id: this._id,
      type: 'panel'
    }, 'child');
  }

  ngOnDestroy(): void {
    this.QA.clear(this.elementRef.nativeElement);
  }



}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {
  AnagApiSubject,
  PartyRoleState,
  Role,
  SubjectsRoleState
} from '../../group-policy-models/group-policy-issue-policy-data';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AnagFlowData} from '@rgi/anag';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {ACTION_PARTY_ROLE_ENUM, PartyRoleParentData} from '../../group-policy-models/gp-party-role';

@Component({
  selector: 'rgi-gp-party-roles',
  templateUrl: './gp-party-roles.component.html'
})
export class GpPartyRolesComponent implements OnChanges {

  @Input() parentForm: UntypedFormGroup;
  @Input() partyRoles: SubjectsRoleState<PartyRoleState>;
  @Input() idParentSession: string;
  @Input() stepData: DataForSteps;
  @Input() actionsExtension: any;
  @Output() partyAction = new EventEmitter<PartyRoleParentData>();
  public partyRolesForm = new UntypedFormGroup({});
  readonly ROLES_FG_NAME = 'rolesPartyForm';
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.partyRoles) {
      this.addRolesOnForm();
    }
  }

  protected addRolesOnForm() {
    this.partyRolesForm.reset();
    let isLinkedRole = false;
    Object.keys(this.partyRoles).forEach(roleKey => {
      this.partyRoles[roleKey].forEach((roleParty, i) => {
        roleParty.nominative = roleParty.companyName ? roleParty.companyName :
          roleParty.surname + ' ' + roleParty.name;

        const partyRoleControl = this.partyRolesForm.get(`${roleParty.role}_${i}`);
        if (partyRoleControl) {
          partyRoleControl.setValue(roleParty.idParty ? roleParty : undefined);
        } else {
          this.partyRolesForm.addControl(`${roleParty.role}_${i}`, new UntypedFormControl(roleParty.idParty ?
            roleParty : undefined));
        }

        this.addControlPercentage(roleParty, this.partyRolesForm, isLinkedRole);
        if (roleParty.idParty) {
          if (roleParty.linkedRoles) {
            Object.keys(roleParty.linkedRoles).forEach((linkedRoleKey, j) => {
              roleParty.linkedRoles[linkedRoleKey].forEach(linkedRole => {
                linkedRole.nominative = linkedRole.companyName ? linkedRole.companyName :
                  linkedRole.surname + ' ' + linkedRole.name;

                // tslint:disable-next-line:no-shadowed-variable
                const partyRoleControl = this.partyRolesForm.get(`${roleParty.role}_${linkedRole.role}_${j}`);
                if (partyRoleControl) {
                  partyRoleControl.setValue(linkedRole.idParty ? linkedRole : undefined);
                } else {
                  this.partyRolesForm.addControl(`${roleParty.role}_${linkedRole.role}_${j}`, new UntypedFormControl(linkedRole.idParty ?
                    linkedRole : undefined));
                }

                if (linkedRole.idParty) {
                  isLinkedRole = true;
                  this.addControlPercentage(linkedRole, this.partyRolesForm, isLinkedRole);
                }
              });
            });
          }
        }
      });
    });
    this.parentForm.setControl(this.ROLES_FG_NAME, this.partyRolesForm);
  }
  public addControlPercentage(roleParty: Role, rolesPartyForm: UntypedFormGroup, isLinkedRole: boolean) {
    const formControlName = isLinkedRole ?
      `linked_${roleParty.role}_${roleParty.idParty}_percentage` :
      `${roleParty.role}_${roleParty.idParty}_percentage`;
    if (roleParty.idParty && roleParty.showPercentage) {
      const linkedRoleControl = rolesPartyForm.get(formControlName);
      if (linkedRoleControl) {
        linkedRoleControl.patchValue(roleParty.percentage, { emitEvent: false });
      } else {
        rolesPartyForm.addControl(
          formControlName,
          new UntypedFormControl(roleParty.percentage, [Validators.required, this.customValidatorPercentage(roleParty.minPercentage)])
        );
      }
      rolesPartyForm.get(formControlName).valueChanges.pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
        .subscribe(percentage => {
          if (percentage) {
            if (rolesPartyForm.get(formControlName).valid) {
              this.setPercentageOnRole(roleParty, percentage);
            }
            rolesPartyForm.get(this.ROLES_FG_NAME).patchValue({ formControlName: percentage });
          }
        });
    }
  }

  public customValidatorPercentage(minPercentage = 0): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      return (control.value < minPercentage) || (control.value > 100) ? { overLimit: true } : null;
    };
  }

  getSubjectDropContainerData(roleKey: number) {
    const data = new AnagFlowData();
    data.partyRole = '' + roleKey;
    data.idParentSession = this.idParentSession;
    data.nodeCode = this.stepData.node;
    return data;
  }

  modifySubject(role: string, idParty: string) {
    const partyData = {} as PartyRoleParentData;
    partyData.action = ACTION_PARTY_ROLE_ENUM.EDITPARTY;
    partyData.role = role;
    partyData.idParty = idParty;
    this.partyAction.emit(partyData);
  }

  onPartySelected($event, roleKey: number) {
    this.setPartySelected($event.changed, '' + roleKey);
  }

  public setPartySelected(party: AnagApiSubject, role: string) {
    const partyData = {} as PartyRoleParentData;
    partyData.action = ACTION_PARTY_ROLE_ENUM.SETPARTYSELECTED;
    partyData.role = role;
    partyData.party = party;
    this.partyAction.emit(partyData);
    this.addRolesOnForm();
  }

  public deleteSubject(role: string, idParty: string) {
    const partyData = {} as PartyRoleParentData;
    partyData.action = ACTION_PARTY_ROLE_ENUM.DELETEPARTYROLE;
    partyData.role = role;
    partyData.idParty = idParty;
    this.partyAction.emit(partyData);
  }

  protected setPercentageOnRole(roleParty: Role, percentage: number) {
    const partyData = {} as PartyRoleParentData;
    partyData.action = ACTION_PARTY_ROLE_ENUM.ADDPERCENTAGEONROLE;
    partyData.idParty = roleParty.idParty;
    partyData.role = roleParty.role;
    partyData.percentage = percentage;
    this.partyAction.emit(partyData);
  }

  getFormRol(rolVal, i) {
    return this.partyRolesForm.get(rolVal.role + '_' + i);
  }
}

export class ActivityVariable {

    public idActVar: number;
    public varType: number;
    public varName: string;
    public varValueStr: string;
    public varValueBool: boolean;
    public varValueData: Date;
    public varValueBlob: string;
    public varValueNumb: number;
    public idUserIns: number;
    public idUserUpd: number;

    constructor() {}

  }

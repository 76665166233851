<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Edit Variables</span>
        </h3>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="onSubmit()">
          <div *ngFor="let variableRow of variablesRows" class="form-group row">
            <div *ngFor="let variable of variableRow; index as i; trackBy: variablesTrackByFn"
                 class="col-sm-6 col-xs-12">
              <mic-variable-control (updateVariable)="onVariableChange($event)" [variableControlForm]="variablesForm"
                                    [variable]="variable">
              </mic-variable-control>
            </div>
          </div>
        </form>
        <div *ngIf="(variablesForm?.invalid && validationMessages && validationMessages?.length > 0)" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-var-modal-cancel" translate>CANCEL</button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" [disabled]="validationMessages?.length > 0" class="btn btn-warning"
                    data-qa="btn-var-modal-confirm" translate>CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

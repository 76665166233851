import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Clause} from '../../../models/domain-models/clause';
import {OnModalClose} from '@rgi/rx/ui';

@Component({
  selector: 're-clause-modal',
  templateUrl: './clause-modal.component.html',
  styleUrls: ['clause-modal.component.css']
})
export class ClauseModalComponent implements OnModalClose, OnInit {

  @Output() modalClose = new EventEmitter();
  clause: Clause;
  clauseTextForm: UntypedFormGroup;

  constructor() {
  }

  ngOnInit() {
    this.clauseTextForm = new UntypedFormGroup({
      text: new UntypedFormControl(this.clause.text, this.clause.mandatoryText ? Validators.required : null)
    });
  }

  cancel() {
    this.modalClose.emit();
  }

  saveClause() {
    this.modalClose.emit(this.clauseTextForm.controls.text.value);
  }
}

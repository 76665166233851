import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder} from '@angular/forms';
import {DigitalClaimsCommonAngularModule, enumServiceProvider, getSystemPropertyProvider} from '@rgi/digital-claims-common-angular';
import {BrowserModule} from '@angular/platform-browser';
import {EditorModule} from '@tinymce/tinymce-angular';
import {RgiRxAutoCompleteModule, RgiRxDatePickerModule, RgiRxDragDropModule, RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxNativeDateTimeModule, RgiRxPanelModule, RgiRxTabModule, RgiRxTableModule, RgiRxTooltipModule} from '@rgi/rx/ui';
import { ActiveRoute, RgiRxRoutingModule } from '@rgi/rx/router';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { RgiRxPortalModule } from '@rgi/rx/portal';
import { RgiRxFormModule } from '@rgi/rx';
import { RgiAnagModule } from '@rgi/anag';
import { FivaPlateCtpDetailComponent } from './fiva-plate-ctp-detail/fiva-plate-ctp-detail.component';
import { FIVA_CARD } from './fiva-configuration/fiva.card';
import { FIVA_ROUTES } from './fiva.route';
import { FivaStateManager} from './state-manager/fiva-state-manager';
import { FivaPlateCtpDetailModalComponent } from './fiva-plate-ctp-detail-modal/fiva-plate-ctp-detail.modal.component';
import { FivaService } from './fiva-service/fiva-service';



@NgModule({
  declarations: [
    FivaPlateCtpDetailComponent,
    FivaPlateCtpDetailModalComponent
  ],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    NgSelectModule,
    DigitalClaimsCommonAngularModule,
    RgiRxI18nModule,
    RgiRxFormElementsModule,
    RgiRxDatePickerModule,
    RgiRxNativeDateTimeModule,
    RgiRxPortalModule.forRoot(FIVA_CARD),
    RgiRxRoutingModule.forRoot(FIVA_ROUTES),
    FormsModule,
    ReactiveFormsModule,
    RgiRxAutoCompleteModule,
    RgiRxFormModule,
    RgiRxTooltipModule,
    RgiRxExpansionModule,
    RgiRxDragDropModule,
    RgiAnagModule,
    BrowserModule,
    RgiRxTableModule,
    EditorModule,
    RgiRxModalModule,
    RgiRxPanelModule,
    RgiRxFormElementsModule,
    RgiRxTabModule,
    RgiRxExpansionModule
  ],
  exports: [
    FivaPlateCtpDetailComponent,
    FivaPlateCtpDetailModalComponent
  ],
  providers: [
    enumServiceProvider,
    getSystemPropertyProvider,
    UntypedFormBuilder,
    ActiveRoute,
    FivaStateManager,
    FivaService,
    FivaStateManager
  ],
})
export class FivaModule {}
 
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Contract} from '../resources/models/contract';
import {ContractNumber} from '../resources/models/contract-number';
import {Observable} from 'rxjs';
import {RgiRxHttpClientFactory, RgiRxHttpClientWrapper} from "@rgi/rx/http";

@Injectable(
  {providedIn: 'root'}
)
export class CommonService {

  private baseApiUrlV2;
  private authorizationsEndpoint;
  private portfolioEndpoint;
  private pcPortfolioEndpoint;
  protected httpClient: RgiRxHttpClientWrapper;

  constructor(protected httpClientFactory: RgiRxHttpClientFactory, @Inject('environment') environment: any) {
    this.httpClient = this.httpClientFactory.buildWrapper();

    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
    ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
    : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.authorizationsEndpoint = this.baseApiUrlV2 + '/authorizations';
    this.portfolioEndpoint = this.baseApiUrlV2 + '/portfolio';
    this.pcPortfolioEndpoint = environment.api.portal.host + environment.api.portal.path + '/v2' + '/portfolio/pc';
  }

  getEnumValues(enumKey: string): any {
    return this.httpClient.get(this.authorizationsEndpoint + '/enumValues/' + enumKey);
  }

  loadContract(proposalNumber: string) {
    const contract = new Contract();
    contract.contractNumber = new ContractNumber();
    contract.contractNumber.proposalNumber = proposalNumber;
    return this.httpClient.post<Contract>(this.portfolioEndpoint + '/contract', contract);
  }

  removeContract(contractId: string) {
    return this.httpClient.delete(this.portfolioEndpoint + '/contract/' + contractId);
  }

  getLifePolicyId(proposalNumber: string) {
    return this.httpClient
    .get<{ errorMessages: string[], policyId: string }>(this.baseApiUrlV2 + '/life/policy/' + proposalNumber + '/policyId');
  }

  openReProposal(proposalNumber: string): Observable<any> {
    return this.httpClient.get<any>(this.pcPortfolioEndpoint + '/proposals/saved/'  + proposalNumber);
  }

}

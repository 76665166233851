import {Inject, Injectable, Injector} from '@angular/core';
import {RgiRxHttpTracingProvider} from '@rgi/rx/http';

@Injectable()
export class RgiRxPortalDefaultTracingProvider extends RgiRxHttpTracingProvider {
  constructor(private injector: Injector) {
    super();
  }
  get traceId(): string | undefined {
    return this.injector.get('coreAuthService')?.getExecutionId();
  }
}

import { DomainField, Party, DamagedAsset } from '@rgi/digital-claims-common-angular';
import { DamageCategoriesCacheEntity } from '../../damage-category-panel-content/dto/damage-categories-cache-entity';
import { Bodywork } from './bodywork';

export class PartyData {
    constructor(
        public uuid: string,
        public damagedassets: DamagedAsset[],
        public parties: Party[],
        public driversubjectid: string,
        public fields: DomainField[],
        public bodywork: Bodywork,
        public exposures: DamageCategoriesCacheEntity
    ) { }

}

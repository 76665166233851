<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()" data-qa="close-dialog">
  </rgi-rx-panel-header>
  <p *ngIf="message" data-qa="dialog-message">{{message}}</p>
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" *ngIf="!confirmMode" (click)="close()" data-qa="rgi-anag-btn-close" translate>
      _ANAG_._BTN_._CLOSE_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" *ngIf="confirmMode" (click)="close()" data-qa="rgi-anag-btn-cancel" translate>
      _ANAG_._BTN_._CANCEL_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" *ngIf="confirmMode" (click)="confirm()" data-qa="rgi-anag-btn-confirm" translate>
      _ANAG_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

import {ReIssueStartCardResourceService} from './re-issue-resources/re-issue-start-card-resource.service';
import {ActiveRoute, Routes, RoutingService} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {ModalService} from '@rgi/rx/ui';
import {ReIssueAssetComponent} from './re-issue-asset/re-issue-asset.component';
import {ReIssueAssetsBusinessService} from './re-issue-business-services/re-issue-assets-business.service';
import {ReIssuePolicyDataBusinessService} from './re-issue-business-services/re-issue-policy-data-business.service';
import {ReIssuePolicyDataComponent} from './re-issue-policy-data/re-issue-policy-data.component';
import {ReIssueQuotationComponent} from './re-issue-quotation/re-issue-quotation.component';
import {ReIssueAssetStateManagerService} from './re-issue-state-manager/re-issue-asset-state-manager.service';
import {
  ReIssuePolicyDataStateManagerService
} from './re-issue-state-manager/re-issue-policy-data-state-manager.service';
import {ReIssueQuotationStateManagerService} from './re-issue-state-manager/re-issue-quotation-state-manager.service';
import {ReIssueQuotationBusinnessService} from './re-issue-business-services/re-issue-quotation-businness.service';
import {ReIssueStartCardComponent} from './re-issue-start-card/re-issue-start-card.component';
import {ReIssueStartCardStateManagerService} from './re-issue-state-manager/re-issue-start-card-state-manager.service';
import {ReIssueStartCardBusinessService} from './re-issue-business-services/re-issue-start-card-business.service';
import {ReIssueSummaryComponent} from './re-issue-summary/re-issue-summary.component';
import {ReIssueSummaryStateManagerService} from './re-issue-state-manager/re-issue-summary-state-manager.service';
import {
  ReIssueProposalManagementComponent
} from './re-issue-proposal-management/re-issue-proposal-management.component';
import {
  ReIssueProposalManagementStateManagerService
} from './re-issue-state-manager/re-issue-proposal-management-state-manager.service';
import {ReIssueSaveQuoteComponent} from './re-issue-save-quote/re-issue-save-quote.component';
import {ReIssueSaveQuoteStateManagerService} from './re-issue-state-manager/re-issue-save-quote-state-manager.service';
import {
  ReIssueProposalManagementBusinessService
} from './re-issue-business-services/re-issue-proposal-management-business.service';
import {ReIssueEmissionComponent} from './re-issue-emission/re-issue-emission.component';
import {ReIssueEmissionStateManagerService} from './re-issue-state-manager/re-issue-emission-state-manager.service';
import {ReIssueSummaryBusinessService} from './re-issue-business-services/re-issue-summary-business.service';
import {ReIssueEmissionBusinessService} from './re-issue-business-services/re-issue-emission-business.service';
import {EventService, RgiRxLogger} from '@rgi/rx';
import {ReIssueSaveQuoteBusinessService} from './re-issue-business-services/re-issue-save-quote-business.service';
import {ReIssueReprintDocumentsComponent} from './re-issue-reprint-documents/re-issue-reprint-documents.component';
import {
  ReIssueReprintDocumentsStateManagerService
} from './re-issue-state-manager/re-issue-reprint-documents-state-manager.service';
import {ReIssueReprintMovementsComponent} from './re-issue-reprint-movements/re-issue-reprint-movements.component';
import {
  ReIssueReprintMovementsStateManagerService
} from './re-issue-state-manager/re-issue-reprint-movements-state-manager.service';
import {ReIssuePortfolioBusinessService} from './re-issue-business-services/re-issue-portfolio-business.service';
import {ReSubstitutionStartComponent} from './re-postsale/re-substitution-start/re-substitution-start.component';
import {
  RePostsalesStateManagerSubstitutionService
} from './re-postsale/re-postsale-state-manager/re-postsales-state-manager-substitution.service';
import {
  RePostsalesSubstitutionBusinessService
} from './re-postsale/re-postsale-services/re-postsales-substitution-business.service';
import {
  ReCancellationStartComponent
} from './re-postsale/re-cancellation/re-cancellation-start/re-cancellation-start.component';
import {
  ReCancellationSummaryComponent
} from './re-postsale/re-cancellation/re-cancellation-summary/re-cancellation-summary.component';
import {
  ReCancellationConfirmationComponent
} from './re-postsale/re-cancellation/re-cancellation-confirmation/re-cancellation-confirmation.component';
import {
  RePostsalesStateManagerCancellationStartService
} from './re-postsale/re-postsale-state-manager/re-postsales-state-manager-cancellation-start.service';
import {
  RePostsalesStateManagerCancellationSummaryService
} from './re-postsale/re-postsale-state-manager/re-postsales-state-manager-cancellation-summary.service';
import {
  RePostsalesStateManagerCancellationConfirmationService
} from './re-postsale/re-postsale-state-manager/re-postsales-state-manager-cancellation-confirmation.service';
import {
  RePostsalesCancellationStartBusinessService
} from './re-postsale/re-postsale-services/re-postsales-cancellation-start-business.service';
import {AnagIssueSubjectBusinessService} from './re-issue-anag/anag-issue-service';


export const RE_ROUTES: Routes = [
  {
    route: 're-issue-start-card',
    component: ReIssueStartCardComponent,
    providers: [
      {
        provide: ReIssueStartCardStateManagerService,
        useClass: ReIssueStartCardStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ReIssueStartCardResourceService, ReIssueStartCardBusinessService],
      }
    ]
  },
  {
    route: 're-issue-policy-data',
    component: ReIssuePolicyDataComponent,
    providers: [
      {
        provide: ReIssuePolicyDataStateManagerService,
        useClass: ReIssuePolicyDataStateManagerService,
        deps: [
          ActiveRoute,
          StateStoreService,
          RoutingService,
          ReIssuePolicyDataBusinessService,
          RgiRxLogger
        ],
      }
    ]
  },
  {
    route: 're-issue-asset',
    component: ReIssueAssetComponent,
    providers: [
      {
        provide: ReIssueAssetStateManagerService,
        useClass: ReIssueAssetStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService,
          ModalService, ReIssueAssetsBusinessService, AnagIssueSubjectBusinessService]
      }
    ]
  },
  {
    route: 're-issue-quotation',
    component: ReIssueQuotationComponent,
    providers: [
      {
        provide: ReIssueQuotationStateManagerService,
        useClass: ReIssueQuotationStateManagerService,
        deps: [
          ActiveRoute,
          StateStoreService,
          RoutingService,
          ReIssueQuotationBusinnessService,
          RgiRxLogger
        ]
      }
    ]
  },
  {
    route: 're-issue-summary',
    component: ReIssueSummaryComponent,
    providers: [
      {
        provide: ReIssueSummaryStateManagerService,
        useClass: ReIssueSummaryStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ReIssueSummaryBusinessService]
      }
    ]
  },
  {
    route: 're-issue-proposal-management',
    component: ReIssueProposalManagementComponent,
    providers: [
      {
        provide: ReIssueProposalManagementStateManagerService,
        useClass: ReIssueProposalManagementStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ReIssueProposalManagementBusinessService]
      }
    ]
  },
  {
    route: 're-issue-save-quote',
    component: ReIssueSaveQuoteComponent,
    providers: [
      {
        provide: ReIssueSaveQuoteStateManagerService,
        useClass: ReIssueSaveQuoteStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ReIssueSaveQuoteBusinessService]
      }
    ]
  },
  {
    route: 're-issue-emission',
    component: ReIssueEmissionComponent,
    providers: [
      {
        provide: ReIssueEmissionStateManagerService,
        useClass: ReIssueEmissionStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ReIssueEmissionBusinessService]
      }
    ]
  },
  {
    route: 're-issue-reprint-documents',
    component: ReIssueReprintDocumentsComponent,
    providers: [
      {
        provide: ReIssueReprintDocumentsStateManagerService,
        useClass: ReIssueReprintDocumentsStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService]
      }
    ]
  },
  {
    route: 're-issue-reprint-movements',
    component: ReIssueReprintMovementsComponent,
    providers: [
      {
        provide: ReIssueReprintMovementsStateManagerService,
        useClass: ReIssueReprintMovementsStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, RgiRxLogger, ReIssuePortfolioBusinessService]
      }
    ]
  },
  {
    route: 're-substitution-start',
    component: ReSubstitutionStartComponent,
    providers: [
      {
        provide: RePostsalesStateManagerSubstitutionService,
        useClass: RePostsalesStateManagerSubstitutionService,
        deps: [ActiveRoute, StateStoreService, RoutingService, EventService, RePostsalesSubstitutionBusinessService],
        // provide: null,
        // useClass: null,
        // deps: [ActiveRoute, StateStoreService, RoutingService, null, null],
      }
    ]
  },
  {
    route: 're-cancellation-start',
    component: ReCancellationStartComponent,
    providers: [
      {
        provide: RePostsalesStateManagerCancellationStartService,
        useClass: RePostsalesStateManagerCancellationStartService,
        deps: [ActiveRoute, StateStoreService, RoutingService, EventService, RePostsalesCancellationStartBusinessService],
      }
    ]
  },
  {
    route: 're-cancellation-summary',
    component: ReCancellationSummaryComponent,
    providers: [
      {
        provide: RePostsalesStateManagerCancellationSummaryService,
        useClass: RePostsalesStateManagerCancellationSummaryService,
        deps: [ActiveRoute, StateStoreService, RoutingService, EventService, RePostsalesCancellationStartBusinessService],
      }
    ]
  },
  {
    route: 're-cancellation-confirmation',
    component: ReCancellationConfirmationComponent,
    providers: [
      {
        provide: RePostsalesStateManagerCancellationConfirmationService,
        useClass: RePostsalesStateManagerCancellationConfirmationService,
        deps: [ActiveRoute, StateStoreService, RoutingService, EventService, RePostsalesCancellationStartBusinessService],
      }
    ]
  }
];

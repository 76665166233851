import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import {PNC_PSALES_IT} from './it';
import {PNC_PSALES_EN} from './en';


export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(PNC_PSALES_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(PNC_PSALES_EN);
  });
}

export const PNC_PSALES_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  }
];

import {Component, ComponentRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {RiskCertificateService} from '../risk-certificate-service';
import {InsuranceStatus} from '../../models/domain-models/insurance-status';
import {CommonService} from '../../common.service';
import {ProposalService} from '../../proposal.service';
import {InsuranceBackgroundService} from '../../insurance-background/insurance-background.service';
import {Modal} from '../../modal';
import {RiskCertificateData} from '../risk-certificate-data';
import {Subscription} from 'rxjs';
import {Message} from '../../models/message';
import {RiskCertificateCell} from '../risk-certificate-cell';
import {SystemProperty} from '../../models/api-models/api-system-properties';

@Component({
  selector: 'mic-risk-certificate-modal',
  templateUrl: './risk-certificate-modal.component.html',
  styleUrls: ['risk-certificate-modal.component.scss']
})
export class RiskCertificateModalComponent implements Modal, OnInit, OnDestroy {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  riskCertificateData: RiskCertificateData;

  riskCertificateForm: UntypedFormGroup;
  componentRef: ComponentRef<any>;
  validationMessages: Message[] = [];
  blockingMessages: Message[] = [];
  formMessages: Message[] = [];
  submitted = false;
  readyToBeClose = false;
  showCompleteCertificate = false;
  bersani = false;
  pejusPercentages: Array<GenericEntity>;
  originRates: Array<GenericEntity>;
  assignedCUs: Array<GenericEntity>;
  originCUs: Array<GenericEntity>;
  companies: Array<GenericEntity>;
  aniaForceCurrentAtr: string;


  PEJUS_ENUM_KEY = 'PEJUS';
  CU_ORIGIN_ENUM_KEY = 'CU_ORIGIN';
  CU_ASSIGNED_ENUM_KEY = 'CU_ASSIGNED';
  RATE_ORIGIN_ENUM_KEY = 'RATE_ORIGIN';

  hasPlateOfOrigin: boolean;
  areaCode = 'RISK_CERTIFICATE';

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected commonService: CommonService,
    protected riskCertificateService: RiskCertificateService,
    protected insuranceBackgroundService: InsuranceBackgroundService,
    protected proposalService: ProposalService,
    protected formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    const insuranceStatusSubscription = this.insuranceBackgroundService.getInsuranceStatusObs().subscribe(data => {
      if (this.readyToBeClose === true) {
        this.close();
      }
    });

    this.subscriptions.add(insuranceStatusSubscription);

    this.riskCertificateData = this.riskCertificateService.getRiskCertificateData();

    this.bersani = this.riskCertificateData.insuranceStatus.bersani;

    this.hasPlateOfOrigin = this.riskCertificateData.plateOfOrigin && this.riskCertificateData.plateOfOrigin !== '';

    if (this.riskCertificateData.showATRTable) {
      this.riskCertificateForm = this.riskCertificateService.toFormGroup(this.riskCertificateData.riskCertificateTable);
    } else {
      this.riskCertificateForm = this.formBuilder.group({});
    }

    const policyExpirationDateReq = this.riskCertificateData.policyExpirationDate;
    const policyExpirationDate = policyExpirationDateReq ? new Date(policyExpirationDateReq) : undefined;

    this.initializeCompanies();
    this.initializeCULists();
    this.initializeOriginRates();
    this.initializePejusPercentages();
    this.initializeSystemProperty('AniaForceCurrentATR');

    this.riskCertificateForm.addControl('companyOfOrigin',
      new UntypedFormControl(this.riskCertificateData.company, Validators.required));

    this.riskCertificateForm.addControl('policyExpirationDate',
      new UntypedFormControl(policyExpirationDate, Validators.required));

    this.riskCertificateForm.addControl('originRate',
      new UntypedFormControl(this.riskCertificateData.originRate, Validators.required));

    this.riskCertificateForm.addControl('uniqueClassFrom',
      new UntypedFormControl(this.riskCertificateData.uniqueClassFrom ?
        new GenericEntity(null, this.riskCertificateData.uniqueClassFrom, this.riskCertificateData.uniqueClassFrom) : null));

    this.riskCertificateForm.addControl('uniqueClassTo',
      new UntypedFormControl(this.riskCertificateData.uniqueClassTo ?
        new GenericEntity(null, this.riskCertificateData.uniqueClassTo,
          this.riskCertificateData.uniqueClassTo) : null, this.riskCertificateData.originUniqueClassVisible ? Validators.required : null));

    if (!this.hasPlateOfOrigin) {
      this.riskCertificateForm.addControl('originChassis',
        new UntypedFormControl(this.riskCertificateData.originChassis, Validators.required));
    }

    this.riskCertificateForm.addControl('policyNumber',
      new UntypedFormControl(this.riskCertificateData.originPolicyNumber));

    if (this.riskCertificateData.showPejus) {
      this.riskCertificateForm.addControl('pejus',
        new UntypedFormControl(
          new GenericEntity(null, String(this.riskCertificateData.pejus), String(this.riskCertificateData.pejus)), Validators.required));
    }

    if (this.riskCertificateData.showDeductibles) {
      this.riskCertificateForm.addControl('deductiblesNumber',
        new UntypedFormControl(this.riskCertificateData.deductiblesNumber, Validators.required));

      this.riskCertificateForm.addControl('deductiblesAmount',
        new UntypedFormControl(this.riskCertificateData.deductiblesAmount, Validators.required));
    }

    const riskCertificateFormValueChangesSubscription = this.riskCertificateForm.get('policyExpirationDate').valueChanges.subscribe(
      (data) => {
        if (this.riskCertificateForm.get('policyExpirationDate').errors
          && !this.riskCertificateForm.get('policyExpirationDate').errors.required) {
          this.riskCertificateForm.get('policyExpirationDate').setErrors(null);
        }
      }
    );
    this.subscriptions.add(riskCertificateFormValueChangesSubscription);

    this.riskCertificateForm.addControl('lastYear',
      new UntypedFormControl(this.riskCertificateData.lastYear));

    if (this.riskCertificateData.showATRTable) {
      this.riskCertificateForm.get('lastYear').setValidators([Validators.required]);
    }

    this.initRiskCertificate();
  }

  setBersaniValue() {
    return false;
  }

  onSubmit() {
    this.submitted = true;

    this.validateForm();

    if (this.riskCertificateForm.valid) {
      let insuranceStatus: InsuranceStatus = InsuranceStatus.createEmptyObject();
      insuranceStatus.bersani = this.setBersaniValue();
      this.riskCertificateData.insuranceStatus.bersani = insuranceStatus.bersani;
      insuranceStatus = Object.assign(insuranceStatus, this.riskCertificateData.insuranceStatus);
      insuranceStatus = this.updateInsuranceStatusObject(insuranceStatus);
      const dateOfbirth = this.proposalService.getDateOfBirth();
      this.insuranceBackgroundService.updateInsuranceStatus(this.proposalService.getContractId(), insuranceStatus, dateOfbirth).subscribe(
        data => {
          this.insuranceBackgroundService.setCorrectIdOnCompany(data);
          this.checkResponseErrors(data);
          if (this.formMessages.length > 0) {
            this.updateMessages();
            this.readyToBeClose = false;
            this.eventPropagation.emit(true);

          } else {
            this.readyToBeClose = true;
            this.insuranceBackgroundService.sendRecalculateInsuranceStatusSignal(data);
            this.eventPropagation.emit(false);
          }
          this.insuranceBackgroundService.setInsuranceStatus(data);
        },
        err => {
          this.eventPropagation.emit(true);
          this.blockingMessages.push(new Message(this.areaCode, 'Error during call to Update InsuranceStatus Service'));
          this.updateMessages();
        }
      );
    }

  }

  close() {
    this.submitted = false;
    this.fixDatepickerInvalidStatus();
    if (this.riskCertificateForm.valid) {
      this.eventPropagation.emit(false);
    } else {
      this.eventPropagation.emit(true);
    }

    this.componentRef.destroy();
  }

  compareGenericEntities(ent1: any, ent2: any): boolean {
    return ent1 && ent2 ? ent1.description === ent2.description : ent1 === ent2;
  }

  onRateChange() {
    if (!this.riskCertificateForm) {
      return;
    }

    const originRateCode = this.riskCertificateForm.get('originRate').value.code;
    if (originRateCode === '4') {
      this.riskCertificateData.showPejus = true;
      if (!this.riskCertificateForm.get('pejus')) {
        this.riskCertificateForm.addControl('pejus',
          new UntypedFormControl(
            new GenericEntity(null, String(this.riskCertificateData.pejus), String(this.riskCertificateData.pejus)), Validators.required));
      }
    } else {
      if (this.riskCertificateData.showPejus) {
        this.riskCertificateData.showPejus = false;
        this.riskCertificateForm.get('pejus').clearValidators();
      }
    }

    if (originRateCode === '2' || originRateCode === '3') {
      this.riskCertificateData.showDeductibles = true;
      if (!this.riskCertificateForm.get('deductiblesNumber')) {
        this.riskCertificateForm.addControl('deductiblesNumber',
          new UntypedFormControl(this.riskCertificateData.deductiblesNumber, Validators.required));
      }
      if (!this.riskCertificateForm.get('deductiblesAmount')) {
        this.riskCertificateForm.addControl('deductiblesAmount',
          new UntypedFormControl(this.riskCertificateData.deductiblesAmount, Validators.required));
      }
    } else if (this.riskCertificateData.showDeductibles) {
      this.riskCertificateData.showDeductibles = false;
      this.riskCertificateForm.get('deductiblesNumber').clearValidators();
      this.riskCertificateForm.get('deductiblesAmount').clearValidators();
    }
    this.fixDatepickerInvalidStatus();
  }

  fillCellsWithValue(year, value, index) {
    if (value !== 'N.A.' && value !== 'N.D.' && value !== '0') {
      return;
    }

    if (this.riskCertificateForm.controls[index + '.principal_people'].value !== '--') {
      this.riskCertificateForm.controls[index + '.principal_people'].setValue(value);
    }
    if (this.riskCertificateForm.controls[index + '.principal_things'].value !== '--') {
      this.riskCertificateForm.controls[index + '.principal_things'].setValue(value);
    }
    if (this.riskCertificateForm.controls[index + '.principal_mixed'].value !== '--') {
      this.riskCertificateForm.controls[index + '.principal_mixed'].setValue(value);
    }
    if (this.riskCertificateForm.controls[index + '.pair_people'].value !== '--') {
      this.riskCertificateForm.controls[index + '.pair_people'].setValue(value);
    }
    if (this.riskCertificateForm.controls[index + '.pair_things'].value !== '--') {
      this.riskCertificateForm.controls[index + '.pair_things'].setValue(value);
    }
    if (this.riskCertificateForm.controls[index + '.pair_mixed'].value !== '--') {
      this.riskCertificateForm.controls[index + '.pair_mixed'].setValue(value);
    }
    if (this.riskCertificateForm.controls[index + '.principal_total'].value !== '--') {
      this.riskCertificateForm.controls[index + '.principal_total'].setValue(value);
    }
    if (this.riskCertificateForm.controls[index + '.pair_total'].value !== '--') {
      this.riskCertificateForm.controls[index + '.pair_total'].setValue(value);
    }
  }

  recalculateTotalClaims(year, type: string, index) {

    const newValue = this.riskCertificateForm.controls[index + '.' + type].value;

    if (!isNaN(newValue)) {
      let totalClaimsControlName: string;
      let newTotalValue = 0;

      if (type.includes('pair')) {
        totalClaimsControlName = index + '.pair_total';
        const pairPeopleClaims = this.riskCertificateForm.controls[index + '.pair_people'].value;
        if (!isNaN(pairPeopleClaims)) {
          newTotalValue = newTotalValue + Number(pairPeopleClaims);
        }
        const pairThingsClaims = this.riskCertificateForm.controls[index + '.pair_things'].value;
        if (!isNaN(pairThingsClaims)) {
          newTotalValue = newTotalValue + Number(pairThingsClaims);
        }
        const pairMixedClaims = this.riskCertificateForm.controls[index + '.pair_mixed'].value;
        if (!isNaN(pairMixedClaims)) {
          newTotalValue = newTotalValue + Number(pairMixedClaims);
        }
      } else if (type.includes('principal')) {
        totalClaimsControlName = index + '.principal_total';
        const principalPeopleClaims = this.riskCertificateForm.controls[index + '.principal_people'].value;
        if (!isNaN(principalPeopleClaims)) {
          newTotalValue = newTotalValue + Number(principalPeopleClaims);
        }
        const principalThingsClaims = this.riskCertificateForm.controls[index + '.principal_things'].value;
        if (!isNaN(principalThingsClaims)) {
          newTotalValue = newTotalValue + Number(principalThingsClaims);
        }
        const principalMixedClaims = this.riskCertificateForm.controls[index + '.principal_mixed'].value;
        if (!isNaN(principalMixedClaims)) {
          newTotalValue = newTotalValue + Number(principalMixedClaims);
        }
      }
      this.riskCertificateForm.controls[totalClaimsControlName].setValue(newTotalValue);
    }

  }

  genericEntitiesTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  riskCertificateCellsTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  onChangeLastYear() {
    const lastYear: string = this.riskCertificateForm.controls.lastYear.value;

    if (lastYear.length < 4) {
      return;
    }

    const polExpirationDate: Date = this.riskCertificateForm.controls.policyExpirationDate.value;
    const nLastYear: number = parseInt(lastYear, 10);

    const messageLastObservation = new Message(this.areaCode, 'The last year of observation must be ' +
      'less than or equal to the policy expiration year');
    if ((polExpirationDate && lastYear && polExpirationDate.getFullYear() >= nLastYear)
      || (this.aniaForceCurrentAtr && this.aniaForceCurrentAtr === 'Enabled')) {

      this.riskCertificateForm.controls.lastYear.setErrors(null);

      this.blockingMessages = this.blockingMessages.filter(item => JSON.stringify(item) !== JSON.stringify(messageLastObservation));
      this.updateMessages();

      const numOfYearsToShow: number = this.riskCertificateService.getNumberOfYears(nLastYear);
      this.riskCertificateData.years.length = 0;
      let year = nLastYear - numOfYearsToShow + 1;

      for (let i = 0; i < numOfYearsToShow; i++) {
        this.riskCertificateData.years.push(year);
        year++;
      }

      this.riskCertificateData.principalRespTotalAmounts.length = 0;
      this.riskCertificateData.principalRespPeopleAmounts.length = 0;
      this.riskCertificateData.principalRespThingsAmounts.length = 0;
      this.riskCertificateData.principalRespMixedAmounts.length = 0;
      this.riskCertificateData.pairRespTotalAmounts.length = 0;
      this.riskCertificateData.pairRespPeopleAmounts.length = 0;
      this.riskCertificateData.pairRespThingsAmounts.length = 0;
      this.riskCertificateData.pairRespMixedAmounts.length = 0;
      this.riskCertificateData.riskCertificateTable.length = 0;

      const k = 0;

      for (let i = 0; i < numOfYearsToShow; i++) {
        year = nLastYear - (numOfYearsToShow - (i + 1));

        this.clearCellsValues(i, year, k);
        this.addMissingCellsFormControl(i);
        this.clearWritableCells(nLastYear, year, i);
        this.setReadOnlyCells(nLastYear, year, i);

      }

      if (numOfYearsToShow === 6) {
        for (let i = 6; i < 11; i++) {
          this.clearOldControls(i);
        }
      }

      this.fixDatepickerInvalidStatus();

      this.showCompleteCertificate = true;
    } else {
      this.blockingMessages.push(messageLastObservation);
      this.updateMessages();
      this.riskCertificateForm.controls.lastYear.setErrors({invalid: true});
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected checkResponseErrors(insuranceStatus: InsuranceStatus) {
    Object.keys(this.riskCertificateForm.controls).forEach(key => {
      if (this.riskCertificateForm.controls[key]) {
        this.riskCertificateForm.controls[key].markAsDirty();
      }
    });

    if (insuranceStatus.completenessErrors && insuranceStatus.completenessErrors.length > 0) {
      insuranceStatus.completenessErrors.forEach(
        (completenessError) => {
          this.formMessages.push(new Message(this.areaCode, completenessError.description));
        }
      );
    }
    if (insuranceStatus.correctnessErrors && insuranceStatus.correctnessErrors.length > 0) {
      insuranceStatus.correctnessErrors.forEach(
        (correctnessError) => {
          this.formMessages.push(new Message(this.areaCode, correctnessError.description));
        }
      );
    }

  }

  private initRiskCertificate() {
    const lastYear: string = this.riskCertificateForm.controls.lastYear.value;
    const nLastYear: number = parseInt(lastYear, 10);

    this.riskCertificateData.years.forEach((year, index) => {
      this.setReadOnlyCells(nLastYear, year, index);
    });

    this.showCompleteCertificate = this.riskCertificateData.lastYear != null;
  }

  private initializeCompanies() {
    this.commonService.getCompanies().subscribe(data => {
      this.companies = data;
    });
  }

  private initializeCULists() {
    const insStatus = this.insuranceBackgroundService.getInsuranceStatusObject();
    if (insStatus && insStatus.cuValues) {
      this.assignedCUs = insStatus.cuValues;
      this.originCUs = insStatus.cuValues;
    } else {
      this.commonService.getEnumValues(this.CU_ASSIGNED_ENUM_KEY, '').subscribe(
        (data) => {
          this.assignedCUs = data;
        }
      );
      this.commonService.getEnumValues(this.CU_ORIGIN_ENUM_KEY, '').subscribe(
        (data) => {
          this.originCUs = data;
        }
      );
    }
  }

  private initializePejusPercentages() {
    const insStatus = this.insuranceBackgroundService.getInsuranceStatusObject();
    if (insStatus && insStatus.cuValues) {
      this.pejusPercentages = insStatus.pejusValues;
    } else {
      this.commonService.getEnumValues(this.PEJUS_ENUM_KEY, '').subscribe(
        (data) => {
          this.pejusPercentages = data;
        }
      );
    }
  }

  private initializeOriginRates() {
    const insStatus = this.insuranceBackgroundService.getInsuranceStatusObject();
    if (insStatus && insStatus.cuValues) {
      this.originRates = insStatus.originRateValues;
    } else {
      this.commonService.getEnumValues(this.RATE_ORIGIN_ENUM_KEY, '').subscribe(
        (data) => {
          this.originRates = data;
        }
      );
    }
  }

  private initializeSystemProperty(key: string) {
    this.commonService.getSystemPoperty(key).subscribe(
      (data: SystemProperty) => {
        this.aniaForceCurrentAtr = data ? data.value : 'Disabled';
      }
    );
  }

  protected updateMessages() {
    if (this.validationMessages) {
      this.validationMessages.length = 0;
    } else {
      this.validationMessages = [];
    }

    Array.prototype.push.apply(this.validationMessages, this.formMessages);
    Array.prototype.push.apply(this.validationMessages, this.blockingMessages);
  }

  protected validateForm() {
    Object.keys(this.riskCertificateForm.controls).forEach(key => {
      if (this.riskCertificateForm.controls[key]) {
        this.riskCertificateForm.controls[key].markAsDirty();
      }
    });

    const controls = this.riskCertificateForm.controls;

    this.formMessages.length = 0;

    if (controls.policyExpirationDate.errors && controls.policyExpirationDate.errors.required) {
      this.formMessages.push(new Message(this.areaCode, 'Policy Expiration Date is mandatory'));
    } else {
      controls.policyExpirationDate.setErrors(null);
    }

    if (controls.originRate.errors && controls.originRate.errors.required) {
      this.formMessages.push(new Message(this.areaCode, 'Rate of Origin is mandatory'));
    }
    if (controls.companyOfOrigin.errors && controls.companyOfOrigin.errors.required) {
      this.formMessages.push(new Message(this.areaCode, 'Company of Origin is mandatory'));
    }
    if (controls.uniqueClassTo.errors && controls.uniqueClassTo.errors.required) {
      this.formMessages.push(new Message(this.areaCode, 'Assigned CU is mandatory'));
    }
    if (!this.hasPlateOfOrigin && controls.originChassis.errors && controls.originChassis.errors.required) {
      this.formMessages.push(new Message(this.areaCode, 'Chassis of Origin is mandatory'));
    }
    if (this.riskCertificateData.showPejus && controls.pejus.errors && controls.pejus.errors.required) {
      this.formMessages.push(new Message(this.areaCode, 'Pejus is mandatory'));
    }
    if (this.riskCertificateData.showDeductibles) {
      if (controls.deductiblesAmount.errors && controls.deductiblesAmount.errors.required) {
        this.formMessages.push(new Message(this.areaCode, 'Amount of unpaid deductibles is mandatory'));
      }
      if (controls.deductiblesNumber.errors && controls.deductiblesNumber.errors.required) {
        this.formMessages.push(new Message(this.areaCode, 'Number of unpaid deductibles is mandatory'));
      }
    }
    if (controls.lastYear.errors && controls.lastYear.errors.required) {
      this.formMessages.push(new Message(this.areaCode, 'Last year of observation is mandatory'));
    }
    this.updateMessages();
  }

  private fixDatepickerInvalidStatus() {
    const controls = this.riskCertificateForm.controls;
    if (controls.policyExpirationDate.errors && !controls.policyExpirationDate.errors.required) {
      controls.policyExpirationDate.setErrors(null);
    }
  }

  protected updateInsuranceStatusObject(insuranceStatus: InsuranceStatus): InsuranceStatus {

    if (this.riskCertificateForm.get('uniqueClassTo').value) {
      insuranceStatus.updateAssignedCU(this.riskCertificateForm.get('uniqueClassTo').value.description);
    }
    if (this.riskCertificateForm.get('uniqueClassFrom').value) {
      insuranceStatus.updateOriginCU(this.riskCertificateForm.get('uniqueClassFrom').value.description);
    }
    insuranceStatus.updateOriginRate(this.riskCertificateForm.get('originRate').value);
    insuranceStatus.updatePolicyExpirationDate(this.riskCertificateForm.get('policyExpirationDate').value);

    if (!this.hasPlateOfOrigin) {
      insuranceStatus.updateOriginChassis(this.riskCertificateForm.get('originChassis').value);
    }
    if (this.riskCertificateData.showPejus) {
      insuranceStatus.updatePejus(this.riskCertificateForm.get('pejus').value.description);
    }
    if (this.riskCertificateData.showDeductibles) {
      insuranceStatus.updateDeductiblesAmount(this.riskCertificateForm.get('deductiblesAmount').value);
      insuranceStatus.updateDeductiblesNumber(this.riskCertificateForm.get('deductiblesNumber').value);
    }
    insuranceStatus.updatePolicyNumber(this.riskCertificateForm.get('policyNumber').value);
    insuranceStatus.updateCompany(this.riskCertificateForm.get('companyOfOrigin').value);
    insuranceStatus.updateLastYear(this.riskCertificateForm.get('lastYear').value);

    const lastYear: string = this.riskCertificateForm.controls.lastYear.value;
    const nLastYear: number = parseInt(lastYear, 10);
    const numOfYearsToShow: number = this.riskCertificateService.getNumberOfYears(nLastYear);

    Object.keys(this.riskCertificateForm.controls).forEach(key => {
      if (key.includes('pair_') || key.includes('principal_')) {
        const claimsNum = this.riskCertificateForm.controls[key].value;
        const claimsYear: number = Number(key.substring(0, key.indexOf('.')));
        const realYear: number = nLastYear - (numOfYearsToShow - (claimsYear + 1));
        const claimsType = key.substring(key.indexOf('.') + 1);


        insuranceStatus.updateClaims(claimsYear, claimsType, claimsNum, realYear);
      }
    });

    return insuranceStatus;
  }

  private clearCellsValues(i: number, year, k: number) {
    this.riskCertificateData.principalRespTotalAmounts.push(
      new RiskCertificateCell(i + '.principal_total', year, null, 'principal_total'));
    this.riskCertificateData.principalRespPeopleAmounts.push(
      new RiskCertificateCell(i + '.principal_people', year, null, 'principal_people'));
    this.riskCertificateData.principalRespThingsAmounts.push(
      new RiskCertificateCell(i + '.principal_things', year, null, 'principal_things'));
    this.riskCertificateData.principalRespMixedAmounts.push(
      new RiskCertificateCell(i + '.principal_mixed', year, null, 'principal_mixed'));
    this.riskCertificateData.pairRespTotalAmounts.push(
      new RiskCertificateCell(i + '.pair_total', year, null, 'pair_total'));
    this.riskCertificateData.pairRespPeopleAmounts.push(
      new RiskCertificateCell(i + '.pair_people', year, null, 'pair_people'));
    this.riskCertificateData.pairRespThingsAmounts.push(
      new RiskCertificateCell(i + '.pair_things', year, null, 'pair_things'));
    this.riskCertificateData.pairRespMixedAmounts.push(
      new RiskCertificateCell(i + '.pair_mixed', year, null, 'pair_mixed'));

    const riskCertificateColumn: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();

    riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_total', year,
      null, 'principal_total'));
    riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_people', year,
      null, 'principal_people'));
    riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_things', year,
      null, 'principal_things'));
    riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_mixed', year,
      null, 'principal_mixed'));
    riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_total', year,
      null, 'pair_total'));
    riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_people', year,
      null, 'pair_people'));
    riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_things', year,
      null, 'pair_things'));
    riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_mixed', year,
      null, 'pair_mixed'));

    this.riskCertificateData.riskCertificateTable[k++] = riskCertificateColumn;
  }

  private addMissingCellsFormControl(i: number) {
    if (!this.riskCertificateForm.get(i + '.principal_total')) {
      this.riskCertificateForm.addControl((i + '.principal_total'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.principal_people')) {
      this.riskCertificateForm.addControl((i + '.principal_people'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.principal_things')) {
      this.riskCertificateForm.addControl((i + '.principal_things'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.principal_total')) {
      this.riskCertificateForm.addControl((i + '.principal_total'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.principal_mixed')) {
      this.riskCertificateForm.addControl((i + '.principal_mixed'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.pair_total')) {
      this.riskCertificateForm.addControl((i + '.pair_total'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.pair_people')) {
      this.riskCertificateForm.addControl((i + '.pair_people'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.pair_things')) {
      this.riskCertificateForm.addControl((i + '.pair_things'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.pair_total')) {
      this.riskCertificateForm.addControl((i + '.pair_total'), new UntypedFormControl(null, Validators.required));
    }
    if (!this.riskCertificateForm.get(i + '.pair_mixed')) {
      this.riskCertificateForm.addControl((i + '.pair_mixed'), new UntypedFormControl(null, Validators.required));
    }
  }

  private clearWritableCells(nLastYear: number, year: number, index: number) {
    if (year >= 2015) {
      this.riskCertificateForm.controls[index + '.principal_total'].setValue(null);
      this.riskCertificateForm.controls[index + '.pair_total'].setValue(null);
      this.riskCertificateForm.controls[index + '.principal_people'].setValue(null);
      this.riskCertificateForm.controls[index + '.principal_things'].setValue(null);
      this.riskCertificateForm.controls[index + '.principal_mixed'].setValue(null);
      this.riskCertificateForm.controls[index + '.pair_people'].setValue(null);
      this.riskCertificateForm.controls[index + '.pair_things'].setValue(null);
      this.riskCertificateForm.controls[index + '.pair_mixed'].setValue(null);
    } else if (nLastYear < 2019 || year > 2012) {
      this.riskCertificateForm.controls[index + '.principal_total'].setValue(null);
      this.riskCertificateForm.controls[index + '.pair_total'].setValue(null);
    }
  }

  private setReadOnlyCells(lastYear: number, year: number, index: number) {
    if (year >= 2015) {
      if (this.riskCertificateForm.controls[index + '.principal_total'] !== undefined) {
        this.riskCertificateForm.controls[index + '.principal_total'].disable();
      }
      if (this.riskCertificateForm.controls[index + '.pair_total'] !== undefined) {
        this.riskCertificateForm.controls[index + '.pair_total'].disable();
      }

      if (this.riskCertificateForm.controls[index + '.principal_people'] !== undefined) {
        this.riskCertificateForm.controls[index + '.principal_people'].enable();
      }

      if (this.riskCertificateForm.controls[index + '.principal_things'] !== undefined) {
        this.riskCertificateForm.controls[index + '.principal_things'].enable();
      }

      if (this.riskCertificateForm.controls[index + '.principal_mixed'] !== undefined) {
        this.riskCertificateForm.controls[index + '.principal_mixed'].enable();
      }

      if (this.riskCertificateForm.controls[index + '.pair_people'] !== undefined) {
        this.riskCertificateForm.controls[index + '.pair_people'].enable();
      }

      if (this.riskCertificateForm.controls[index + '.pair_things'] !== undefined) {
        this.riskCertificateForm.controls[index + '.pair_things'].enable();
      }

      if (this.riskCertificateForm.controls[index + '.pair_mixed'] !== undefined) {
        this.riskCertificateForm.controls[index + '.pair_mixed'].enable();
      }
    } else {
      if (lastYear >= 2019 && year <= 2012) {
        if (this.riskCertificateForm.controls[index + '.principal_total'] !== undefined) {
          this.riskCertificateForm.controls[index + '.principal_total'].disable();
          this.riskCertificateForm.controls[index + '.principal_total'].setValue('--');
        }
        if (this.riskCertificateForm.controls[index + '.pair_total'] !== undefined) {
          this.riskCertificateForm.controls[index + '.pair_total'].disable();
          this.riskCertificateForm.controls[index + '.pair_total'].setValue('--');
        }
      } else {
        if (this.riskCertificateForm.controls[index + '.principal_total'] !== undefined) {
          this.riskCertificateForm.controls[index + '.principal_total'].enable();
        }
        if (this.riskCertificateForm.controls[index + '.pair_total'] !== undefined) {
          this.riskCertificateForm.controls[index + '.pair_total'].enable();
        }
      }

      if (this.riskCertificateForm.controls[index + '.principal_people'] !== undefined) {

        this.riskCertificateForm.controls[index + '.principal_people'].disable();
        this.riskCertificateForm.controls[index + '.principal_people'].setValue('--');

      }
      if (this.riskCertificateForm.controls[index + '.principal_things'] !== undefined) {
        this.riskCertificateForm.controls[index + '.principal_things'].disable();
        this.riskCertificateForm.controls[index + '.principal_things'].setValue('--');

      }

      if (this.riskCertificateForm.controls[index + '.principal_mixed'] !== undefined) {
        this.riskCertificateForm.controls[index + '.principal_mixed'].disable();
        this.riskCertificateForm.controls[index + '.principal_mixed'].setValue('--');
      }

      if (this.riskCertificateForm.controls[index + '.pair_people'] !== undefined) {
        this.riskCertificateForm.controls[index + '.pair_people'].disable();
        this.riskCertificateForm.controls[index + '.pair_people'].setValue('--');
      }

      if (this.riskCertificateForm.controls[index + '.pair_things'] !== undefined) {
        this.riskCertificateForm.controls[index + '.pair_things'].disable();
        this.riskCertificateForm.controls[index + '.pair_things'].setValue('--');
      }
      if (this.riskCertificateForm.controls[index + '.pair_mixed'] !== undefined) {
        this.riskCertificateForm.controls[index + '.pair_mixed'].disable();
        this.riskCertificateForm.controls[index + '.pair_mixed'].setValue('--');
      }

    }
  }

  private clearOldControls(index: number) {
    if (
      this.riskCertificateForm.controls[index + '.principal_total'] !== undefined
    ) {
      this.riskCertificateForm.controls[index + '.principal_total'].disable();
    }
    if (
      this.riskCertificateForm.controls[index + '.principal_people'] !== undefined
    ) {
      this.riskCertificateForm.controls[index + '.principal_people'].disable();
    }
    if (
      this.riskCertificateForm.controls[index + '.principal_things'] !== undefined
    ) {
      this.riskCertificateForm.controls[index + '.principal_things'].disable();
    }
    if (
      this.riskCertificateForm.controls[index + '.principal_mixed'] !== undefined
    ) {
      this.riskCertificateForm.controls[index + '.principal_mixed'].disable();
    }
    if (this.riskCertificateForm.controls[index + '.pair_total'] !== undefined) {
      this.riskCertificateForm.controls[index + '.pair_total'].disable();
    }
    if (this.riskCertificateForm.controls[index + '.pair_people'] !== undefined) {
      this.riskCertificateForm.controls[index + '.pair_people'].disable();
    }
    if (this.riskCertificateForm.controls[index + '.pair_things'] !== undefined) {
      this.riskCertificateForm.controls[index + '.pair_things'].disable();
    }
    if (this.riskCertificateForm.controls[index + '.pair_mixed'] !== undefined) {
      this.riskCertificateForm.controls[index + '.pair_mixed'].disable();
    }
  }

}

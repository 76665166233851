import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KarmaAuthService} from './karma-auth.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    KarmaAuthService
  ],
})
class KarmaAuthModule { }
export {
  KarmaAuthModule,
  KarmaAuthService
};

export const lifePreventiveCard = `
  <div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlIssue">
    <lic-preventive-card
      [selected-subject]="selectedSubject.received.data"
      [node]="selectedNode"
      [idSession]="card.idSession"
      (event-propagation)="wrapEvent($event)">
    </lic-preventive-card>
  </div>`;


export const newLifeCard = `
  <div class="lic-life-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlIssue">
    <lic-life-card
        data-qa="mic-entry-{{ card.id }}"
        [data]="{data, idParentSession: card.idSession}"
        [selected-subject]="selectedSubject.received.data"
        (event-propagation)="wrapEvent($event)">
    </lic-life-card>
  </div>`;


export const newLifeProposal = `
  <div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlIssue">
    <lic-life-proposal-card
        data-qa="mic-entry-{{ card.id }}"
        (event-propagation)="wrapEvent($event)">
    </lic-life-proposal-card>
  </div>`;

<template #quotationComparisonModalOverlay></template>
<div *ngIf="party" class="anag-content-detail">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-2 nmf_cont_partyimage">
      <div class="anag-user-ico">
        <img *ngIf="party?.personType?.code=='1' && party?.sex?.code=='1'" src="../../../../assets/img/man.svg" width="120">
        <img *ngIf="party?.personType?.code=='1' && party?.sex?.code=='2'" src="../../../../assets/img/woman.svg" width="120">
        <img *ngIf="party?.personType?.code=='2'" src="../../../../assets/img/build.svg" width="120">
      </div>
    </div>
    <div class="col-xs-12 col-sm-8 col-md-10 nmf_cont_party">
      <h1 *ngIf="party?.personType?.code=='1'" class="nmf_cont_partyTit"><span translate>Nominative:</span>
        <b> {{party?.nominative || '-'}}</b></h1>
      <h1 *ngIf="party?.personType?.code=='2'" class="nmf_cont_partyTit"><span translate>Denomination:</span>
        <b> {{party?.denomination || '-'}}</b></h1>
      <!-- Party data ------------------------------------------------------------------------------------------------>
      <div class="nmf_cont_dati">

        <div *ngIf="party?.personType?.code=='1'" class="nmf_cont_riga">
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Person type:<b>
            {{party?.personType?.description}}</b></div>
          <div *ngIf="party?.personType?.code=='1'" class="nmf_cont_single_data nmf_cont_single_data_color"
               translate>Fiscal code:<b> {{party?.fiscalCode}}</b></div>
          <div *ngIf="party?.personType?.code=='2'" class="nmf_cont_single_data nmf_cont_single_data_color"
               translate>Vat number:<b> {{party?.vat}}</b></div>
          <div class="nmf_cont_single_data nmf_cont_single_data_color">
            <span translate>Sex:</span><b> {{party?.sex?.description}}</b>
          </div>
        </div>
        <div class="nmf_cont_riga">
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Birth date:<b>
            {{party?.dateOfBirth | date: 'dd/MM/yyyy'}}</b></div>
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>City of birth:<b>
            {{party?.cityOfBirth | titlecase}}</b></div>
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Citizenship:<b>
            {{party?.citizenship?.description}}</b></div>
        </div>
      </div>
      <br clear="all">
      <!-- Residence data ------------------------------------------------------------------------------------------------>
      <div class="nmf_cont_titsez nmf_cont_titsez_color">
        <span class="header-icon rgifont rgi-indirizzi nfm_font_size"></span>
        <h3 *ngIf="party?.personType?.code=='1'" translate>Residence</h3>
        <h3 *ngIf="party?.personType?.code=='2'" translate>Registered office</h3>
      </div>
      <div class="nmf_cont_dati">
        <div class="nmf_cont_riga">
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Country:<b>
            {{party?.residence?.country?.description || '-'}}</b></div>
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Province:<b>
            {{party?.residence?.province?.code || '-'}}</b></div>
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>City:<b>
            {{party?.residence?.city || '-'}}</b></div>
        </div>
        <div class="nmf_cont_riga">
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Zip:<b>
            {{party?.residence?.cap || '-'}}</b></div>
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Toponym:<b>
            {{party?.residence?.toponym?.description || '-'}}</b></div>
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Address:<b>
            {{party?.residence?.placeAddress || '-'}}</b></div>
        </div>
        <div class="nmf_cont_riga no-border">
          <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Number:<b>
            {{party?.residence?.number || '-'}}</b></div>
        </div>
        <br clear="all">
        <!-- Contacts data ------------------------------------------------------------------------------------------------>
        <div class="nmf_cont_titsez nmf_cont_titsez_color">
          <span class="header-icon rgifont rgi-contacts nfm_font_size"></span>
          <h3 translate>Contacts</h3>
        </div>
        <div class="nmf_cont_dati">
          <div class="nmf_cont_riga">
            <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Main contact:<b>
              {{party?.mainContact?.description || '-'}}</b></div>
            <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Mobile phone number:<b
              *ngIf="party?.mobilePhone">{{(
              party?.mobilePhone[0]?.localPrefix ?
                party?.mobilePhone[0]?.localPrefix + ' ' : '')}}{{party?.mobilePhone[0]?.number || '-'}}</b>
              <b *ngIf="!party?.mobilePhone"> - </b>
            </div>
            <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Landline number:<b
              *ngIf="party?.landlineNumber"> {{(
              party?.landlineNumber?.localPrefix ?
                party?.landlineNumber?.localPrefix + ' ' : '')}}{{party?.landlineNumber?.number || '-'}}</b>
              <b *ngIf="!party?.landlineNumber"> - </b>
            </div>
          </div>
          <div class="nmf_cont_riga">
            <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Email:<b *ngIf="party?.emails">
              {{party?.emails[0] || '-'}}</b>
              <b *ngIf="!party?.emails"> - </b>
            </div>
            <div class="nmf_cont_single_data nmf_cont_single_data_color" translate>Fax:<b *ngIf="party?.phoneFax">
              {{(party?.phoneFax?.localPrefix ?
              party?.phoneFax?.localPrefix + ' ' : '')}}{{party?.phoneFax?.number || '-'}}</b>
              <b *ngIf="!party?.phoneFax"> - </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br clear="all"><br clear="all">

  <!-- Role of entitled -->
  <div class="row">
    <form [formGroup]="entitledControlForm">
      <div class="col-sm-4 col-md-2 col-xs-12">
      </div>

      <div class="col-sm-4 col-md-5 col-xs-12">
        <div class="form-group">
          <label translate>Role:</label>
          <pnc-required required="true"></pnc-required>
          <select class="select-nativa mic-select-node" data-qa="select-role" formControlName="role" id="node"
                  name="role">
            <option></option>
            <option *ngFor="let entitledRole of roles" [ngValue]="entitledRole">{{entitledRole?.description}}</option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
      </div>

      <div class="col-sm-4 col-md-5 col-xs-12" *ngIf="!proposalService.getIsInclusionApplication()">

        <label translate>Subscriber</label><br>
        <div (click)="changeSubscriber()" class="btn btn-checkbox" formControlName="useForSubscriber" ngDefaultControl
             type="checkbox">
          <span [ngClass]="{'glyphicon-ok': entitledControlForm.controls.useForSubscriber.value === true }" class="glyphicon"
                style="color: #f68020;">
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<br clear="all"><br clear="all"><br clear="all">

<div *ngIf="!party && entitled?.parties?.length > 0">
  <table class="table-responsive table coreDT">
    <thead core-data-table-thead="">
    <tr class="head-result thDataTable">
      <th>&nbsp;</th>
      <th>Soggetto</th>
      <th>Tipo soggetto</th>
      <th>Codice fiscale / Partita iva</th>
      <th>Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of entitled?.parties; let i=index" class="table-row  row-result-even"
        ng-class="{ 'row-result-even': $even, 'row-result-odd': $odd }">
      <td class="td-function">
        <button (click)="row.expanded = !row.expanded;" class="btn dt-row-toggle" type="button">
            <span class="rgifont iconDTleft rgi-plus"
                  ng-class="{'rgi-plus': !row?.expanded, 'rgi-minus':row?.expanded}"></span>
        </button>
      </td>
      <td>
        <span class="column-name-xs visible-xs-block pull-left">Soggetto</span>
        <span>
            {{row?.nominative}}
          </span>
      </td>
      <td>
        <span class="column-name-xs visible-xs-block pull-left">Tipo soggetto</span>
        <span>
            <span>{{row?.personType?.description}}</span>
          </span>
      </td>
      <td>
        <span class="column-name-xs visible-xs-block pull-left">Codice fiscale / Partita iva</span>
        <span>
            <span>{{row?.fiscalCode}}</span>
          </span>
      </td>
      <td>
        <span class="column-name-xs visible-xs-block pull-left">Azioni</span>
        <div>
          <div class="dt-btn-group hidden-sm">
            <button (click)="selectParty(row)" class="btn dt-btn" stop-event="touchend" tooltip-append-to-body="true"
                    type="button">
              <span class="rgifont rgi-chevron-right iconDT"></span>
            </button>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<!-- Form messages -->
<div id="error-container" style="margin-top: 2em">
  <pnc-form-message *ngIf="entitledControlForm?.invalid && validationMessages && validationMessages?.length > 0" [messages]="validationMessages" data-qa="action-error-messages"
                    detail="validationError" objectType="complex"
                    type="error">
  </pnc-form-message>
  <pnc-form-message *ngIf="blockingMessages && blockingMessages.length > 0" [messages]="blockingMessages" data-qa="action-error-messages" detail="blockingMessages"
                    objectType="complex" type="error">
  </pnc-form-message>
</div>

<!-- Button group -->
<div class="btn-group btn-group-justified">
  <div class="btn-group">
    <button (click)="findParty()" class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="btn-party-changeparty"
            type="button">
      <span translate>CHANGE PARTY</span>
    </button>
  </div>
  <div *ngIf="party" class="btn-group">
    <button (click)="editParty()" class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="btn-party-edit"
            type="button">
      <span *ngIf="!isNewParty()"><span translate>EDIT</span></span>
      <span *ngIf="isNewParty()"><span translate>FILL</span></span>
    </button>
  </div>
  <div *ngIf="party && party?.complete && !isNewParty()" class="btn-group">
    <button (click)="confirmParty()" class="btn btn-warning pull-right text-uppercase" data-qa="btn-party-confirm"
            type="button" [disabled]="blockedForTerrorism">
      <span translate>CONFIRM</span>
    </button>
  </div>
</div>

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, share} from 'rxjs/operators';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {PcUseTax} from '../../../models/pc-portfolio-models/us-taxes-models/pc-us-taxes';
import {UseTax} from '../../../models/domain-models/parameters/use-tax';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfollioUseTaxesResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
    private refactorService: ReIssueRefactorService
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  getUseTaxes(proposalId: string): Observable<Array<PcUseTax>> {
    return this.http.get<Array<PcUseTax>>(this.getUseTaxesUri(proposalId)).pipe(
      share()
    );
  }

  getSelectedUseTax(proposalId: string): Observable<PcUseTax> {
    return this.http.get<PcUseTax>(this.getUseTaxUri(proposalId)).pipe(
      share()
    );
  }

  getUseTaxesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId + '/use-taxes';
  }

  putUseTax(proposalId: string, useTax: UseTax): Observable<PcUseTax> {
    return this.http.put<PcUseTax>(
      this.getUseTaxUri(proposalId),
      this.refactorService.useTaxRefactor(useTax)
    ).pipe(
      share()
    );
  }

  getUseTaxUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/use-tax';
  }

}

<div *ngIf="!hide && !!qd" class="blank" data-qa="warranty-table-quotation">
    <h3 translate>{{label}}</h3>
  <div class="tbld postsales-table-style hidden-xs">
    <!-- INTESTAZIONE -->
    <div class="tbld_row postsales-col-title">
      <div class="tbld_col" translate>lpc_Guarantee</div>
      <div *ngIf="!!qd.totalResidualBenefitAmount" class="tbld_col" translate>lpc_leftInsuredAmount</div>
      <div class="tbld_col" translate *ngIf="repaidVisible">lpc_amount_reimbured</div>
      <div class="tbld_col" translate>lpc_provvigioni_da_recuperare</div>
    </div>
    <!-- GARANZIE -->
    <div *ngFor="let war of qd.listWarrantyRow" class="tbld_row postsales-row-content">
      <div class="tbld_col postsales-row-title" [attr.data-qa]="'warranty-description'">{{war.warrantyDescription}}</div>
      <div *ngIf="!!qd.totalResidualBenefitAmount" class="tbld_col psvalue" [attr.data-qa]="'residual-benefit-amount'">{{war.residualBenefitAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
      <div class="tbld_col psvalue" [attr.data-qa]="'reimbursement-amount'" *ngIf="repaidVisible">{{war.reimbursementAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
      <div class="tbld_col psvalue" [attr.data-qa]="'recovered-commission'">{{war.recoveredCommission | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
    </div>
    <div class="tbld_row postsales-row-total">
      <div class="tbld_col" translate data-qa="garanzia-rataFirma-vita-total">lpc_total</div>
      <div *ngIf="!!qd.totalResidualBenefitAmount" class="tbld_col"></div>
      <div class="tbld_col psvalue" data-qa="total-reimbursement-amount" *ngIf="repaidVisible">{{qd.totalReimbursementAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
      <div class="tbld_col psvalue" data-qa="total-recovered-commission">{{qd.totalRecoveredCommission | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
    </div>
  </div>
</div>
<div *ngIf="hide" class="row" data-qa="summary-dates-step">
  <div class="col-md-6">
    <span for="label" [attr.data-qa]="'total-reimbursement-amount'">{{'lpc_amount_reimbured'}}: {{qd.totalReimbursementAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
  </div>
  <div class="col-md-6">
    <span for="value" [attr.data-qa]="'total-recovered-commission'">{{'lpc_provvigioni_da_recuperare'}}: {{qd.totalRecoveredCommission | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
  </div>
</div>

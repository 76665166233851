export class TimeRequest {
    public hour: number;
    public minute: number;
    public second: number;

    toString(): string {
        return this.fillWithZero(this.hour) + ':' + this.fillWithZero(this.minute);
    }

    private fillWithZero(nmb: number): string {
        const pad = '00';
        const str = '' + nmb;
        return pad.substring(0, pad.length - str.length) + str;
    }

    public translateFromString(timeAsString: string) {
        if (timeAsString) {
            const splitted = timeAsString.split(':');
            this.hour = +splitted[0];
            this.minute = +splitted[1];
        }
    }
}

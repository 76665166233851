<template #editRiskCertificateModal></template>
<!-- TITLE SECTION ----------------------------------------------------------------------------->
<div *ngIf="(( showATRHeader ) || showATRTable)" class="nmf_cont_titsez nmf_cont_titsez_color">
  <span class="header-icon rgifont rgi-dati_aggiuntivi nfm_font_size"></span>
  <h3 translate>Risk Certificate</h3>
  <div *ngIf="riskCertificateCompletenessErrors" class="nmf_errorautomaticbranch_icon">
    <span class="rgifont rgi-survey_ko"></span>
  </div>
  <button (click)="goToEditRiskCertificate()" *ngIf="showEdit" class="btn btn-default btn-quotation-detail"
          translate type="button">Edit<span class="glyphicon glyphicon-pencil"></span>
  </button>

</div>
<!-- DATA ----------------------------------------------------------------------------->

<div *ngIf="showATRHeader" class="nmf_cont_dati">
  <div *ngFor="let insuranceStatusDataRow of insuranceStatusDataRows" class="nmf_cont_riga">
    <div *ngFor="let data of insuranceStatusDataRow; index as i; trackBy: genericElementsTrackByFn"
         class="nmf_cont_single_data nmf_cont_single_data_color">
      <span *ngIf="data?.label !== 'Policy Expiration Date:'">{{ data?.label | translate }} <b>
          {{ data?.value }}</b></span>
      <span *ngIf="data?.label === 'Policy Expiration Date:'">{{ data?.label | translate }}
        <b> {{ data?.value | date:'dd/MM/yyyy' }}</b></span>
    </div>
  </div>
</div>

<!-- ATR TABLE ----------------------------------------------------------------------------->
<table *ngIf="showATRTable && !insuranceStatus.lastYearOfTheReport" class="nmf_cont_atr">
  <thead>
  <tr>
    <th></th>
    <th *ngFor="let year of years">-</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td data-label="Title" translate>Paid with majority responsibility</td>
    <td *ngFor="let cell of principalRespTotalAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">==
    </td>
  </tr>
  <tr class="nmf_oddCol">
    <td data-label="Title" translate>Paid with equal responsibility</td>
    <td *ngFor="let cell of pairRespTotalAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">==
    </td>
  </tr>
  </tbody>
</table>

<table *ngIf="showATRTable && insuranceStatus.lastYearOfTheReport" class="nmf_cont_atr">
  <thead>
  <tr>
    <th></th>
    <th *ngFor="let year of years">{{ year }}</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td data-label="Title" translate>Paid with majority responsibility</td>
    <td *ngFor="let cell of principalRespTotalAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">
      {{ cell?.amount }}</td>
  </tr>
  <tr class="nmf_oddRow">
    <td class="nmf_add_td_padding" data-label="Title" translate>of which person</td>
    <td *ngFor="let cell of principalRespPeopleAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">
      {{ cell?.amount }}</td>
  </tr>
  <tr class="nmf_oddCol">
    <td class="nmf_add_td_padding" data-label="Title" translate>of which things</td>
    <td *ngFor="let cell of principalRespThingsAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">
      {{ cell?.amount }}</td>
  </tr>
  <tr class="nmf_oddRow">
    <td class="nmf_add_td_padding" data-label="Title" translate>of which (person + things)</td>
    <td *ngFor="let cell of principalRespMixedAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">
      {{ cell?.amount }}</td>
  </tr>
  <tr class="nmf_oddCol">
    <td data-label="Title" translate>Paid with equal responsibility</td>
    <td *ngFor="let cell of pairRespTotalAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">{{ cell?.amount }}
    </td>
  </tr>
  <tr class="nmf_oddRow">
    <td class="nmf_add_td_padding" data-label="Title" translate>of which person</td>
    <td *ngFor="let cell of pairRespPeopleAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">
      {{ cell?.amount }}
    </td>
  </tr>
  <tr class="nmf_oddCol">
    <td class="nmf_add_td_padding" data-label="Title" translate>of which things</td>
    <td *ngFor="let cell of pairRespThingsAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">
      {{ cell?.amount }}
    </td>
  </tr>
  <tr class="nmf_oddRow">
    <td class="nmf_add_td_padding" data-label="Title" translate>of which (person + things)</td>
    <td *ngFor="let cell of pairRespMixedAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
        [attr.data-label]="cell?.year">{{ cell?.amount }}
    </td>
  </tr>
  </tbody>
</table>
<mic-equal-resp-claims-det *ngIf="showATRTable" [insuranceStatus]="insuranceStatus" [showEdit]="showEdit">
</mic-equal-resp-claims-det>

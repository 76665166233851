import { PolicyService } from './../../../services/policy-service';
import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {KarmaProfileDefinition} from '../../model/karma-profile-definition';
import {distinctUntilChanged} from 'rxjs/operators';
import {LicObjectUtils} from '../../../utils/lic-object-utils';

@Component({
  selector: 'lic-profiles[totalAmount][definition]',
  templateUrl: './lic-profiles.component.html',
  styleUrls: ['./lic-profiles.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicProfilesComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicProfilesComponent),
      multi: true
    }
  ]
})
export class LicProfilesComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  public locale = 'it-IT';
  public optionPercents: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };
  public optionCurrency: Intl.NumberFormatOptions = { style: 'currency' };

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    profiles: new UntypedFormControl()
  });

  private $subscriptions: Subscription[] = [];

  @Input() active = false;
  @Input() public definition: KarmaProfileDefinition[];
  @Input() public totalAmount: number;
  @Input() public sliderProperty: string;

  private $selectedProfiles: any = [];
  public get selectedProfiles(): any[] {
    return this.$selectedProfiles;
  }

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
    this.optionCurrency.currency = policyService.currencyCode;
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.get('profiles').valueChanges.pipe(
        distinctUntilChanged((prev, curr) => LicObjectUtils.equal(prev, curr))
      ).subscribe(result => {
        this.$selectedProfiles = this.getSelectedProfiles();
        if (this.formGroup.get('profiles').valid) {
          this.onChange(this.getRawData());
        } else {
          this.onChange(null);
        }
        this.onTouch();
      })
    );
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.formGroup.get('profiles').setValue(obj, { emitEvent: false });
    this.$selectedProfiles = this.getSelectedProfiles();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formGroup.get('profiles').errors;
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  private getSelectedProfiles(): any[] {
    return this.definition.filter(profileDefinition => {
      return !!this.formGroup.get('profiles').value && !!this.formGroup.get('profiles').value[profileDefinition.id];
    }).map(profileDefinition => {
      const percent: number = this.formGroup.get('profiles').value[profileDefinition.id];
      return {
        name: profileDefinition.description,
        amount: percent * this.totalAmount,
        percent
      };
    });
  }

  private getRawData(): any {
    const obj: any = {};
    Object.keys(this.formGroup.getRawValue().profiles).filter(profileKey => {
      return !!this.formGroup.getRawValue().profiles[profileKey];
    }).forEach(profileKey => {
      obj[profileKey] = this.formGroup.getRawValue().profiles[profileKey];
    });
    return obj;
  }
}

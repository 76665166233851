import {StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPsalesRequiredDataState} from '../../resources/states/required-data-state';
import {PncPostSalesOrchestratorService} from '../../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../../services/pnc-postsales-integration.service';

import {RgiPncPsalesStateManager} from '../../api/rgi-pnc-postsales-state.manager';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {Observable} from 'rxjs';
import {PncPsalesHttpErrorService} from '../../services/pnc-postsales-http-error.service';

/**
 * @classdesc This state manager is the generic linked to the PncPsalesSummaryComponent.
 * Extend this class and provide it to the step definition for the specific flow
 */
export class PncPsalesRequiredDataStateManager extends RgiPncPsalesStateManager<PncPsalesRequiredDataState> {


  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, orchestrator: PncPostSalesOrchestratorService, integrationService: PncPostSalesIntegrationService, pushMessageHandler: RgiRxPushMessageHandlerService, errorService: PncPsalesHttpErrorService, context: any) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
  }

  initState$(state: Observable<PncPsalesRequiredDataState>): Observable<PncPsalesRequiredDataState> {
    throw Error('Extend this state Manager and implement it in an operation flow step');
  }
}

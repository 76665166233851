import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {isNumber} from '../../../adapters/group-policy-utils';
import {
  QuotationGuaranteeDetails,
  QuotationGuarantees,
  QuotationGuaranteeSubDetails,
  SummaryQuotationTranslations
} from '../../../group-policy-models/group-policy-issue-guarantees';

/**
 * @author: dmasone
 * @description: Modal used for quotation guarantee details
 */
@Component({
  selector: 'rgi-gp-group-policy-modal-quotation-guarantee-details.component',
  templateUrl: './group-policy-modal-quotation-guarantee-details.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyQuotationGuaranteeDetailsModalComponent implements OnModalClose {

    public modalClose = new EventEmitter<any>();
    public isNumber = isNumber;
    public quotationGuaranteeDetails: QuotationGuaranteeDetails;
    public translations = SummaryQuotationTranslations;
    public displayedColumns: string[] = ['riskDesc',
        'purePremium',
        'loading',
        'overpremium',
        'net',
        'intPaymentFreq',
        'tax',
        'right',
        'gross'];

    constructor(@Optional() @Inject(DIALOG_DATA) data: any) {
        this.quotationGuaranteeDetails = data.quotationGuaranteeDetails;
    }

    public bindModel(model: QuotationGuaranteeSubDetails): QuotationGuarantees[] {
        const res: QuotationGuarantees[] = [];

        model.quotationGuarantees.forEach((singleEl: QuotationGuarantees) => {
            if (singleEl) {
                res.push(singleEl);
            }
        });
        model.total.riskDesc = '_GP_._QUOTATION_._TOTAL_';
        res.push(model.total);

        return res;
    }

}

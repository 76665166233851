import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pnc-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.css']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class DynamicTableComponent implements OnInit {

  @Input() sectionTitle = '';
  @Input() rows: number[];
  @Input() objectMap;

  constructor() { }

  ngOnInit() {
  }

  composeArray(lenght: number): number [] {
    return Array(lenght).fill(0).map((x, i) => i);
  }

  isVectorValue(value): boolean {
    return (value instanceof Array);
  }

}

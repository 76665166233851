import {Component, ComponentRef, OnInit} from '@angular/core';
import {Clause} from '../../models/domain-models/clause';
import {Modal} from '../../modal';
import {ClauseService} from '../clause-service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'mic-clause-modal',
  templateUrl: './clause-modal.component.html',
  styleUrls: ['clause-modal.component.scss']
})
export class ClauseModalComponent implements Modal, OnInit {

  componentRef: ComponentRef<any>;
  clause: Clause;
  clauseTextForm: UntypedFormGroup;
  editMode = true;
  textOutput: string;


  constructor(
    protected clauseService: ClauseService,
    protected formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.clause = this.clauseService.getClause();
    this.clauseTextForm = this.formBuilder.group({
      text: new UntypedFormControl(decodeURIComponent(this.clause && this.clause.text ? this.clause.text : ''))      }
    );
    if (this.clause.text) {
      this.editMode = false;
    }
  }

  decodeClause(text) {
    return decodeURIComponent(text);
  }

  startEditMode() {
    this.editMode = true;
  }

  onSubmit() {
    const newText = this.clauseTextForm.get('text').value;
    this.textOutput = encodeURIComponent(newText ? newText : '');
    this.cancel();
  }

  cancel() {
    this.componentRef.destroy();
  }

}

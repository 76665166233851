import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ErrorCode, ErrorCodeType, ErrorMsg, ErrorResp} from '../../group-policy-models/group-policy-issue-policy-data';

/**
 * @author: dmasone
 * @description: Component used for manage server errors
 */
@Component({
  selector: 'rgi-gp-group-policy-errors-component',
  templateUrl: './group-policy-errors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPolicyPolicyErrorsComponent implements OnInit, OnDestroy {

  @Input() errors: ErrorResp[] | ErrorMsg[] = [];
  @Input() type: ErrorCode = ErrorCode.BLOCKING; // deprecated
  @Input() areaCode: string;


  errorCodeMap: Map<ErrorCodeType, number> = new Map<ErrorCodeType, number>([
    [ErrorCode.BLOCKING, 0],
    [ErrorCode.WARNING, 1],
    [ErrorCode.INFO, 2],
    [ErrorCode.IGNORED, 3]
  ]);

  areaErrors: ErrorResp[] = [];

  public ERROR_CODE = ErrorCode;

  constructor() {
  }

  ngOnInit() {
    if (this.errors && this.errors.length) {
      // @ts-ignore
      if (!!this.errors[0].errorDescription) {
        // @ts-ignore
        this.errors = this.errors.map((err: ErrorMsg)  => {
          let level: ErrorCodeType;
          switch (err.errorType) {
            case 0:
              level = ErrorCode.BLOCKING;
              break;
            case 1:
              level = ErrorCode.WARNING;
              break;
            case 2:
              level = ErrorCode.INFO;
              break;
            default:
              level = ErrorCode.BLOCKING;
          }
          return {
            code: err.errorCode,
            message: err.errorDescription,
            level
          } as ErrorResp;
        });
      }
      if (this.areaCode) {
        // @ts-ignore
        this.areaErrors = this.errors.filter(err => err.message && err.code.startsWith(this.areaCode));
      } else {
        // @ts-ignore
        this.areaErrors = this.errors.filter(err => !!err.message);
      }

      this.areaErrors.map(err => this.errorCodeMap.get(err.level)).sort((a, b) => {
        return a - b;
      });
    }
  }

  ngOnDestroy() {
    this.areaErrors = undefined;
  }

}

import {AdapterprintTranslationsKeys} from './adapterprint-translations-keys';
export const ADAPTERPRINT_ES: AdapterprintTranslationsKeys = {
  _START_ADAPTERPRINT_: 'Adaptador Resultados de la impresión',
  _RESULT_ADAPTERPRINT_: 'Lista de impresión de adaptadores',
  _ADAPTERPRINT_: {
    _BTN_: {
      _BACK_: 'Volver',
      _EMPTY_: 'Vacío',
      _FIND_: 'Encuentre',
      _UNDO_: 'Cancelar'
    },
    _LABEL_: {
      _ACTION_: 'Acción',
      _ADDRESS_: 'Dirección',
      _LOT_: 'Lote',
      _CODE_: 'Código',
      _DATE_SENT_: 'Fecha de envío',
      _DATE_TO_: 'Fecha en',
      _DESCRIPTION_: 'Descripción',
      _DOCUMENT_TYPE_: 'Tipo de documento',
      _MANAGEMENT_NODE_: 'Nodo de gestión',
      _NODE_: 'Nodo',
      _NODE_CODE_: 'Código de nodo',
      _NODE_DESCRIPTION_: 'Nodo Descripción',
      _NODE_LIST_: 'Lista de nodos',
      _POLICY_NUMBER_: 'Número de póliza',
      _ADDRESSED_: 'Destinatario',
      _START_DATE_: 'Fecha de',
      _STATUS_: 'Estado',
      _POLICY_: 'Política',
      _TEMPLATE_: 'Template',
      _OUTCOME_: 'Resultado',
      _USER_: 'Usuario'
    },
    _MSG_: {
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Los campos marcados en rojo son obligatorios o contienen valores incorrectos',
      _NO_RESULTS_: 'Ningún resultado',
      _NOT_SENT_: 'No enviado',
      _SENT_OK_: 'Publicado OK',
      _SENT_KO_: 'Publicado KO',
    }
  },
};

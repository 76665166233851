
<div style="height:60px;" class="modal-header">
  <div class="card-title">
    <span class="header-icon rgifont "></span> 
    <span *ngIf="settlementsDetails" style="color:white;" class="menu-title" ng-show="!titleHeader">{{ '_CLAIMS_._SETTLEMENTS_LIST' | translate }} {{potPayFE.claimNumber}}</span>
    <span *ngIf="receiptsDetails" style="color:white;" class="menu-title" ng-show="!titleHeader">{{ '_CLAIMS_._RECEIPTS_LIST' | translate }} {{potPayFE.claimNumber}}</span>
    <span *ngIf="compensationsDetails" style="color:white;" class="menu-title" ng-show="!titleHeader">{{ '_CLAIMS_._COMPENSATIONS_LIST' | translate }} {{potPayFE.claimNumber}}</span>
    <span *ngIf="assignementsDetails" style="color:white;" class="menu-title" ng-show="!titleHeader">{{ '_CLAIMS_._APPOINTMENTS_LIST' | translate }} {{potPayFE.claimNumber}}</span>
    <span *ngIf="settlementsDetailsTypeOfLiq" style="color:white;" class="menu-title" ng-show="!titleHeader">{{ '_CLAIMS_._TYPOLOGIES_SETTLEMENT_LIST' | translate }}</span>
    <span *ngIf="omniaDetail" style="color:white;" class="menu-title" ng-show="!titleHeader">{{ '_CLAIMS_._DETAILS' | translate }}</span>
</div>
</div>
<div style="float:left;width: 100%;height: 900px;" class="modal-body">
   <table style="width: 100%;">
      <thead>
        <tr class="head-result thDataTable">
          <th *ngFor="let headerField of headerData; let first = first;" [ngClass]="settlementsDetailsTypeOfLiq && first ? 'flLeftWidth25': ''">{{headerField | translate}}</th>
        </tr>
      </thead>
      <tbody *ngIf="settlementsDetails">
        <tr *ngFor="let pay of potPayFE.paymentSavedList; let even = even; let odd = odd;" [ngClass]="{ 'row-result-even': odd , 'row-result-odd': even }" class="table-row">
          <td>{{pay.adjustmentNumber}}</td>
          <td>{{pay.adjustmentDate | date:'dd/MM/yyyy'}}</td>
          <td>{{pay.paymentType}}</td>
          <td>{{pay.technicalAdjustementType}}</td>
          <td>{{pay.amount | number:'1.2-2'}}</td>
          <td>{{pay.recordingDate | date:'dd/MM/yyyy'}}</td>
          <td *ngIf="ministerialBranch =='000010'">{{pay.forfaitAmount | number:'1.2-2'}}</td>
          <td>{{pay.status}}</td>

          
        
        </tr>
      </tbody>

      <tbody name="dataReceipt" *ngIf="receiptsDetails">
        <tr  *ngFor="let payReceipt of potPayFE.receiptSavedList; let even = even; let odd = odd;" [ngClass]="{ 'row-result-even': odd , 'row-result-odd': even }" class="table-row">
          <td>{{payReceipt.adjustmentNumber}}</td>
          <td>{{payReceipt.adjustmentDate | date:'dd/MM/yyyy'}}</td>
          <td>{{payReceipt.paymentType}}</td>
          <td>{{payReceipt.amount | number:'1.2-2'}}</td>
          <td>{{payReceipt.status}}</td>

        </tr>
      </tbody>

      <tbody *ngIf="compensationsDetails">
        <tr  *ngFor="let compensation of potPayFE.compensationSavedList; let even = even; let odd = odd;" [ngClass]="{ 'row-result-even': odd , 'row-result-odd': even }" class="table-row">
          <td>{{compensation.number}}</td>
          <td>{{compensation.type}}</td>
          <td>{{compensation.state}}</td>
          <td>{{compensation.amount | number:'1.2-2'}}</td>
          <td>{{compensation.amountActive}}</td>
          <td>{{compensation.openingDate | date:'dd/MM/yyyy'}}</td>
          <td>{{compensation.closingDate | date:'dd/MM/yyyy'}}</td>
          <td>{{compensation.refusedDate | date:'dd/MM/yyyy'}}</td>
        </tr>
      </tbody>

      <tbody name="assignementsDetail" *ngIf="assignementsDetails">
        <tr  *ngFor="let assignment of potPayFE.assessmentSavedList; let even = even; let odd = odd;" [ngClass]="{ 'row-result-even': odd , 'row-result-odd': even }" class="table-row">
          <td>{{assignment.appointmentNumber}}</td>
          <td>{{assignment.expert}}</td>
          <td>{{assignment.paymentSurveyType}}</td>
          <td>{{assignment.appointmentDate | date:'dd/MM/yyyy'}}</td>
          <td>{{assignment.appointmentState}}</td>
        </tr>
      </tbody>
      <tbody name="settlementsDetailsTypeOfLiq" *ngIf="settlementsDetailsTypeOfLiq">
        <tr  *ngFor="let paymentType of potPayFE.paymentTypeList; let even = even; let odd = odd;" [ngClass]="{ 'row-result-even': odd , 'row-result-odd': even }" class="table-row">
          <td style="float: left;">{{paymentType.eTypeDescription}}</td>
          <td><span class="glyphicon" [ngClass]="paymentType.franchigia ? 'glyphicon-ok-circle':'glyphicon-remove-circle'"></span></td>
          <td><span class="glyphicon" [ngClass]="paymentType.scoperto ? 'glyphicon-ok-circle':'glyphicon-remove-circle'"></span></td>
          <td><span class="glyphicon" [ngClass]="paymentType.massimale ? 'glyphicon-ok-circle':'glyphicon-remove-circle'"></span></td>
        </tr>
      </tbody>

      <tbody name="omniaDetail" *ngIf="omniaDetail">
        <tr  *ngFor="let feeOmnia of listOmniaFee; let even = even; let odd = odd;" [ngClass]="{ 'row-result-even': odd , 'row-result-odd': even }" class="table-row">
          <td>{{feeOmnia.feeData.feeNumber}}</td>
          <td>{{feeOmnia.feeData.feeDate | date:'dd/MM/yyyy'}}</td>
          <td>{{feeOmnia.feeData.feeAmount  | number:'1.2-2'}}</td>
          <td>{{feeOmnia.amount  | number:'1.2-2'}}</td>
        </tr>
      </tbody>
   </table>
  </div>
<br>
<div class="modal-footer">
  <button name="closeButton" style="margin:20px" type="button" class="btn btn-warning pull-right" (click)="closeModal($event)">{{'_CLAIMS_._BUTTONS_._CLOSE' | translate}}</button>
</div>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgPassproModule } from '@rgi/ng-passpro';
import { NgPassproproCoreModule, SSOModule } from '@rgi/ng-passpropro-core';
import { NgPassproproSurveyModule } from '@rgi/ng-passpropro-survey';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { QuestionnairesManagerComponent, QuestionnairesManagerModule } from '@rgi/questionnaires-manager';
import { NgxPaginationModule } from 'ngx-pagination';
import { LicPreventiveCardComponent } from '../life-preventive-card/lic-preventive-card.component';
import { CardsNavigationComponent } from './cards-navigation/cards-navigation.component';
import { CardsNavigationDirective } from './cards-navigation/cards-navigation.directive';
import { CustomNumberModule } from './custom-number/custom-number.module';
import { CustomNumberFormatDirective } from './directive/custom-number-format.directive';
import { PercentageDirective } from './directive/percentage.directive';
import { ReinvestmentNumberFormatDirective } from './directive/reinvestment-number-format.directive';
import { LicInvestmentsComponent } from './invest-standalone-session/component/lic-investments/lic-investments.component';
import { InvestStandaloneSessionModule } from './invest-standalone-session/invest-standalone-session.module';
import { LicHelpModule } from './lic-help/lic-help.module';
import { LicInvestmentContractModule } from './lic-investment-contract/lic-investment-contract.module';
import { LifeCardHostDirective } from './life-card-host.directive';
import { LifeCardComponent } from './life-card.component';
import { DatiAmmComponent } from './life-issue-card/dati-amm/dati-amm.component';
import { SelectCosignedComponent } from './life-issue-card/dati-amm/select-cosigned/select-cosigned.component';
import { DatepickerFactorComponent } from './life-issue-card/fattore/datepicker-factor/datepicker-factor.component';
import { LoaderDirective } from './life-issue-card/fattore/factor-loader.directive';
import { FactorsComponent } from './life-issue-card/fattore/factors.component';
import { FormatFactorDirective } from './life-issue-card/fattore/format-factor.directive';
import { InputFactorComponent } from './life-issue-card/fattore/input-factor/input-factor.component';
import { RadioGroupFactorComponent } from './life-issue-card/fattore/radio-group-factor/radio-group-factor.component';
import { SelectFactorComponent } from './life-issue-card/fattore/select-factor/select-factor.component';
import { GoodsInfoComponent } from './life-issue-card/goods-info/goods-info.component';
import { LicIssueModalErrorComponent } from './life-issue-card/issue/issue-modal-error/issue-modal-error.component';
import { LifeRolePageComponent } from './life-issue-card/life-role/life-role-page.component';
import { SelectHolderComponent } from './life-issue-card/life-role/select-holder/select-holder.component';
import { SelectInsuredComponent } from './life-issue-card/life-role/select-insured/select-insured.component';
import { SelectSubjectListComponent } from './life-issue-card/life-role/select-subject-list/select-subject-list.component';
import { SelectSubjectComponent } from './life-issue-card/life-role/select-subject/select-subject.component';
import { SelectSubjectModule } from './life-issue-card/life-role/select-subject/select-subject.module';
import { DatepickerPaymentModule } from './life-issue-card/pagamenti/datepicker-payment/datepicker-payment.module';
import { InputPaymentModule } from './life-issue-card/pagamenti/input-payment/input-payment.module';
import { PaymentComponent } from './life-issue-card/pagamenti/payment.component';
import { PaymentLoaderDirective } from './life-issue-card/pagamenti/payment.loader.directive';
import { PipDataComponent } from './life-issue-card/pip-data/pip-data.component';
import { BeneficiariesSelectComponent } from './life-issue-card/quotation/beneficiary-select/beneficiaries-select.component';
import { LicQuotationComponent } from './life-issue-card/quotation/lic-quotation.component';
import { QuotationControlsComponent } from './life-issue-card/quotation/quotation-controls/quotation-controls.component';
import {
  RelatedSubjectListComponent
} from './life-issue-card/quotation/select-subject/related-subject-list/related-subject-list.component';
import { SelectSubjectBenComponent } from './life-issue-card/quotation/select-subject/select-subject-ben.component';
import { UnitsComponent } from './life-issue-card/quotation/units/units.component';
import { ReinvestmentModalComponent } from './life-issue-card/reinvestment-modal/reinvestment-modal.component';
import { SummaryComponent } from './life-issue-card/summary/summary.component';
import { LicToolsModule } from './lic-tools/lic-tools.module';
import { DocumentListComponent } from './utils/documents/document-list.component';
import { ModalBasicComponent } from './utils/modal/modal-basic.component';
import { PremiumTableComponent } from './utils/premium-table/premium-table.component';
import { QuestionnairesComponent } from './utils/questionnaires/questionnaires.component';
import { WarrantiesTableComponent } from './utils/warranties-table/warranties-table.component';
import {SelectSecondInsuredComponent} from './life-issue-card/life-role/select-second-insured/select-second-insured.component';
import { EnviromentsService } from './services/enviroments.service';
import { AjsConfigurationLife } from '../portal-integration/ajs-configuration-life-issue';
import { LifeProposalCardModule } from './life-proposal-card/life-proposal-card.module';
import { AngularCommunicationIssue } from '../portal-integration/assets/angular-communication-issue';
import { RgiRxExpansionModule, RgiRxModalModule, RgiRxPanelModule, RgiRxSnackbarModule } from '@rgi/rx/ui';
import { RgiRxFormElementsModule, RgiRxTabModule } from '@rgi/rx/ui';
import { LayeredRuleService } from './services/layered-rule.service';
import { RgiAnagModule } from '@rgi/anag';
import { LicExtractFromArrayPipeModule } from './utils/pipes/lic-extract-from-array/lic-extract-from-array.module';
import { IssueComponent } from './life-issue-card/issue/issue.component';
import {UnitsGroupComponent} from './life-issue-card/quotation/units/units-group/units-group.component';
import { WarrantiesGroupTableComponent } from './utils/warranties-table/warranties-group-table/warranties-group-table.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LIC_TRANSLATIONS } from '../i18n/translations';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';
import { LicRequiredModule } from './utils/lic-required/lic-required.module';
import { RgiCountryLayerModule } from '@rgi/country-layer';

export const DECLARATIONS = [
  LifeCardComponent,
  CardsNavigationComponent,
  IssueComponent,
  LifeRolePageComponent,
  DatiAmmComponent,
  PipDataComponent,
  GoodsInfoComponent,
  SelectHolderComponent,
  SelectSubjectListComponent,
  SelectInsuredComponent,
  SelectSecondInsuredComponent,
  SummaryComponent,
  PremiumTableComponent,
  LicQuotationComponent,
  QuotationControlsComponent,
  FactorsComponent,
  UnitsComponent,
  UnitsGroupComponent,
  BeneficiariesSelectComponent,
  SelectSubjectBenComponent,
  RelatedSubjectListComponent,
  QuestionnairesComponent,
  WarrantiesTableComponent,
  WarrantiesGroupTableComponent,
  SelectCosignedComponent,
  PaymentComponent,
  LicIssueModalErrorComponent,

  // TODO togliere il componente ed utilizzare quello in PNC
  ModalBasicComponent,
  DocumentListComponent,

  // FATTORI
  SelectFactorComponent,
  RadioGroupFactorComponent,
  DatepickerFactorComponent,
  InputFactorComponent,

  // PREVENTIVE
  LicPreventiveCardComponent,

  // DIRETTIVE
  LifeCardHostDirective,
  CardsNavigationDirective,
  PercentageDirective,
  LoaderDirective,
  CustomNumberFormatDirective,
  ReinvestmentNumberFormatDirective,
  PaymentLoaderDirective,
  FormatFactorDirective,
  ReinvestmentModalComponent
];

export const IMPORTS = [
  LifeProposalCardModule,
  SelectSubjectModule,
  CommonModule,
  InvestStandaloneSessionModule,
  PortalNgCoreModule,
  FormsModule,
  ReactiveFormsModule,

  NgSelectModule,
  NgPassproModule,
  RgiRxModalModule,
  RgiRxPanelModule,

  DatepickerPaymentModule,
  InputPaymentModule,

  QuestionnairesManagerModule,
  NgPassproproSurveyModule,
  SSOModule,
  NgPassproproCoreModule,
  NgbModule,
  NgxPaginationModule,
  LicToolsModule,
  LicInvestmentContractModule,

  CustomNumberModule,
  RgiRxFormElementsModule,
  RgiRxTabModule,

  // sotto moduli
  RgiRxI18nModule,
  RgiRxI18nModule.forRoot(LIC_TRANSLATIONS),
  LicHelpModule,
  RgiCountryLayerModule,
  RgiRxExpansionModule,
  RgiAnagModule,
  LicExtractFromArrayPipeModule,
  LicRequiredModule,
  RgiRxSnackbarModule
];

export const EXPORTS = [
  LifeCardComponent,
  CardsNavigationComponent,
  IssueComponent,
  LifeRolePageComponent,
  DatiAmmComponent,
  PipDataComponent,
  GoodsInfoComponent,
  SelectHolderComponent,
  SummaryComponent,
  PremiumTableComponent,
  LicQuotationComponent,
  QuotationControlsComponent,
  SelectSubjectListComponent,
  SelectInsuredComponent,
  SelectSecondInsuredComponent,
  FactorsComponent,
  UnitsComponent,
  UnitsGroupComponent,
  BeneficiariesSelectComponent,
  SelectSubjectBenComponent,
  RelatedSubjectListComponent,
  QuestionnairesComponent,
  WarrantiesTableComponent,
  WarrantiesGroupTableComponent,
  SelectCosignedComponent,
  PaymentComponent,
  ModalBasicComponent,
  DocumentListComponent,
  SelectSubjectComponent,
  QuestionnairesManagerComponent,
  LicIssueModalErrorComponent,

  // FATTORI
  SelectFactorComponent,
  RadioGroupFactorComponent,
  DatepickerFactorComponent,
  InputFactorComponent,

  // PREVENTIVE
  LicPreventiveCardComponent,

  // DIRETTIVE
  LifeCardHostDirective,
  CardsNavigationDirective,
  LoaderDirective,
  PercentageDirective,
  DatepickerPaymentModule,
  InputPaymentModule,
  LicHelpModule,
  CustomNumberModule,
  ReinvestmentModalComponent,
  CustomNumberFormatDirective,
  FormatFactorDirective
];

export const PROVIDERS = [
  { provide: 'lifeRolePageComponent', useValue: LifeRolePageComponent },
  { provide: 'datiAmmComponent', useValue: DatiAmmComponent },
  { provide: 'pipDataComponent', useValue: PipDataComponent },
  { provide: 'goodsInfoComponent', useValue: GoodsInfoComponent },
  { provide: 'licQuotationComponent', useValue: LicQuotationComponent },
  { provide: 'issueComponent', useValue: IssueComponent },
  { provide: 'investmentsComponent', useValue: LicInvestmentsComponent },
  { provide: 'summaryComponent', useValue: SummaryComponent },
  { provide: 'cardsNavigationComponent', useValue: CardsNavigationComponent },
  EnviromentsService,
  LayeredRuleService,
  TranslationWrapperService
];

@NgModule({
    declarations: DECLARATIONS,
    imports: IMPORTS,
    exports: EXPORTS,
    providers: PROVIDERS
})
export class LifeCardModule {
  constructor() {
    AngularCommunicationIssue();
    AjsConfigurationLife();
  }
}

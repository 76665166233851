import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiFileBean} from '../models/api-models/api-file-bean';
import {MicHttpService} from '../http/mic-http.service';


@Injectable({
  providedIn: 'root'
})
export class HelpService {

  protected baseApiUrlV2;
  protected baseApiUrlV1;
  protected motorContractEndpoint;
  protected helpEndpoint;
  protected httpClient: HttpClient;

  private helpFiles = new Array<ApiFileBean>();

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any
  ) {

    this.httpClient = this.micHttpClient.getHttpClient();
    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.baseApiUrlV1 = environment.api.portal.host + environment.api.portal.path;
    this.helpEndpoint = this.baseApiUrlV2 + '/portfolio/help';
  }

  getHelpFile(fileName: string) {
    return this.httpClient.get<ApiFileBean>(this.helpEndpoint + '/' + fileName);
  }

  getHelpFileByName(fileName: string): ApiFileBean {
    for (const help of this.helpFiles) {
      if (help.fileName === fileName) {
        return help;
      }
    }

    return null;
  }

  setHelpFile(fileBean: ApiFileBean) {
    this.helpFiles.push(fileBean);
  }
}

<div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" *ngIf="!!documentList">
  <div class="row life-detail-card-title-container">
    <div class="row life-detail-title">
      <div class="card-title">
                <span *ngIf="!isMotor" class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                      (click)="backLife()" data-qa="back-arrow"></span>
        <span *ngIf="!isMotor" class="separator"> | </span>
        <span class="menu-title" translate>_IIAB_._OTP_._OTP_DOCUMENTS_</span>
      </div>
    </div>
  </div>
  <div *ngIf="areThereDocuments" class="document-list-container">

    <div *ngFor="let doc of documentList">
      <div class="document-container">
        <div class="row">
          <div class="col-md-11 col-sm-11">
            <span class="icon rgi-documenti_causali"></span>
            <b class="desc-container">{{doc.documentTitle}}</b>
          </div>
          <div class="col-md-1 col-sm-1">
            <div class="button-container">
              <button type="button" class="btn btn-download"
                      (click)="openDocument(doc, openDocumentModal);">
                <span class="icon rgi-download"></span>
              </button>

              <rgi-rx-modal #openDocumentModal>
                <rgi-rx-panel [attr.data-qa]="doc.documentTitle + '-doc-panel'">
                  <rgi-rx-panel-header [closeable]="true" (onClose)="openDocumentModal.close()">
                    <p>{{doc.documentTitle}}</p>
                  </rgi-rx-panel-header>

                  <ng-container *ngIf="!!srcSanithized">
                    <embed [src]="srcSanithized" type="application/pdf" width="100%" height="500px" style="margin: -5px;" />
                  </ng-container>

                  <rgi-rx-panel-footer>
                    <button class="text-uppercase rgi-ui-btn rgi-ui-btn-primary" (click)="openDocumentModal.close();" data-qa="close-btn" translate>close</button>
                  </rgi-rx-panel-footer>
                </rgi-rx-panel>
              </rgi-rx-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="!!message" class="example-row">
    <pnc-form-message
      *ngFor="let item of message"
      [type]="item.type"
      [messages]="item.msg"
      [detail]="item.detail"
      [attr.data-qa]="item.type + '-messages'">
    </pnc-form-message>
  </div>


  <div *ngIf="isMotor" class="rgi-ui-panel-footer rgi-anag-tm-1">
    <!--<div class="button-container">-->
      <button type="button" class="rgi-ui-btn rgi-ui-btn-primary"
              (click)="backMotor()" [disabled]="false">
        <span>BACK</span>
      </button>
    <!--</div>-->
  </div>
</div>

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="title?.length > 0">
        <h3 class="modal-title">
          <span data-qa="modal-title">{{ title }}</span>
        </h3>
      </div>
      <div class="modal-body" data-qa="modal-message-list">
        <p *ngFor="let message of messages; let i = index" [attr.data-qa]="'modal-message-list-item-' + i">{{ message }}</p>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group" *ngIf="!onConfirm">
            <button class="btn btn-warning" (click)="close()" translate data-qa="modal-close-btn">OK</button>
          </div>
          <div class="btn-group" *ngIf="!!onConfirm">
            <button class="btn btn-warning" (click)="close()" translate data-qa="modal-cancel-btn">CANCEL</button>
          </div>
          <div class="btn-group" *ngIf="!!onConfirm">
            <button class="btn btn-warning" (click)="confirm()" translate data-qa="modal-confirm-btn">CONFIRM</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

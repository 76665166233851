import {InjectionToken, isDevMode} from '@angular/core';
import {SCALAR_TYPE} from '../reflection';
import {RgiRxQAConfigService} from './rgi-rx-qaconfig.service';

export interface RgiRxQAConfig {
    enabled: boolean;
    debug: RgiRxQARenderType | false;
}

export const RGI_RX_QA_CONFIG = new InjectionToken<RgiRxQAConfig>('RGI_RX_QA_CONFIG');

export const RGI_RX_DEFAULT_QA_CONFIG: RgiRxQAConfig = {
  enabled: false,
  debug: false
};

export const RGI_RX_QA_RENDER_STYLE_DEFAULTS: { [key: string]: string | number } = {
    color: 'hotpink',
    'font-family': 'monospace',
    'font-style': 'italic',
    'font-weight': 600,
    'font-size': '10px',
    background: 'whitesmoke',
    border: '1px dashed hotpink',
    padding: '2px'
};

export interface QAContext {
    id: string;
    value?: SCALAR_TYPE;
    ctx?: string;
    type?: string;
}

export const RGI_RX_QA_RENDER_STYLE = new InjectionToken<RgiRxQAConfig>('RGI_RX_QA_RENDER_STYLE');

const RGI_RX_DATA_QA_QUERY = 'rgi-rx-qa';


export type RgiRxQARenderType = 'log' | 'render';


function extractQueryParam(queryString: string) {
    const urlSearchParams = new URLSearchParams(queryString);
    if (urlSearchParams.has(RGI_RX_DATA_QA_QUERY)) {
        return urlSearchParams.get(RGI_RX_DATA_QA_QUERY).toLowerCase();
    }
    return queryString.indexOf(RGI_RX_DATA_QA_QUERY) > -1 ? '' : false;
}

function detectRgiQAQueryParams(location: Location): string | boolean {
    if (location.search) {
        return extractQueryParam(location.search);
    }
    if (location.hash) {
        return extractQueryParam(location.hash.replace('#', ''));
    }
    return false;
}

export function rgiRxQAFactory(document: Document, configService: RgiRxQAConfigService): () => Promise<RgiRxQAConfig> {
    const lambda = () => new Promise<RgiRxQAConfig>((resolve) => {
        const renderType = document && document.location ? detectRgiQAQueryParams(document.location) : false;
        if (renderType === false) {
          return resolve(configService.config);
        }
        configService.enabled = renderType === '' || !!renderType;
        let debug: boolean | RgiRxQARenderType = false;
        if (isDevMode() && renderType !== '') {
            debug = renderType as RgiRxQARenderType;
        }
        configService.debug = debug;
        resolve(configService.config);
    });
    return lambda;
}

import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiContract} from '../models/api-models/api-contract';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {SubstitutionService} from './substitution.service';
import {ProposalService} from '../proposal.service';
import {StateService} from '../state.service';
import {State} from '../models/state';
import {RoutingService} from '../routing.service';
import {CustomModalService, Message} from '@rgi/portal-ng-core';
import {Subscription} from 'rxjs';
import {Action} from '../models/action';
import {SubReason} from '../models/api-models/api-substitution-reason';
import {TranslateService} from '@ngx-translate/core';
import {SubstitutionModalComponent} from './substitution-modal/substitution-modal/substitution-modal.component';
import {MicIntegrationService} from '../mic-integration.service';

@Component({
  selector: 'mic-substitution',
  templateUrl: './substitution.component.html',
  styleUrls: ['./substitution.component.scss']
})
export class SubstitutionComponent implements OnInit, OnDestroy {

  @ViewChild('openSubstitutionModal', {
    read: ViewContainerRef,
    static: true
  }) openSubstitutionModalViewContainerRef: ViewContainerRef;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  substitutionForm: UntypedFormGroup;
  substReasons: Array<SubReason>;
  products: Array<GenericEntity>;
  policyNumber: string;
  validationMessages: Message[] = [];
  areaCode = 'SUBSTITUTION';
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected substitutionService: SubstitutionService,
    protected proposalService: ProposalService,
    protected stateService: StateService,
    protected routingService: RoutingService,
    protected translateService: TranslateService,
    protected modalService: CustomModalService,
    protected integrationService: MicIntegrationService) {
  }

  ngOnInit() {

    const newContractSubscription = this.proposalService.getNewContractSignal().subscribe(data => {
      this.proposalService.refresh();
      this.proposalService.setApiContract(data);
      this.policyNumber = this.proposalService.getApiContract().contractNumber.policyNumber;
      this.stateService.setCurrentState(State.SUBSTITUTED_CONTRACTS);
      this.routingService.setPreviousState(State.SUBSTITUTED_CONTRACTS);

      this.eventPropagation.emit({
        eventName: 'setTitle',
        title: this.translateService.instant('Substituted Contracts')
      });

      this.init();
    });
    this.subscriptions.add(newContractSubscription);

    this.substitutionForm = this.formBuilder.group({
      substReason: [null, Validators.required],
      effect: [null, {validators: Validators.required, updateOn: 'blur'}],
      product: [null, Validators.required]
    });
  }

  compareGenericEntities(ent1: any, ent2: any): boolean {
    return ent1 && ent2 ? ent1.code === ent2.code : ent1 === ent2;
  }

  genericEntitiesTrackByFn(index: any, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  goBack() {
    this.proposalService.refresh();
    this.stateService.resetState();
    this.eventPropagation.emit({
      eventName: 'backToContractDetail',
    });
  }

  onSubmit() {
    this.validateForm();
    if (this.validationMessages.length === 0) {
      const effect = this.substitutionForm.get('effect');
      const wasDisabled = effect.disabled;
      if (wasDisabled === true) {
        effect.enable({emitEvent: false});
      }
      if (effect.valid) {
        this.substitutionService.checkSubstitutionFeasibility( this.policyNumber,
          effect.value.getTime(),
          this.substitutionForm.get('substReason').value,
          this.substitutionForm.get('product').value,
          this.proposalService.subClusterCode,
          this.proposalService.subAssetCode).subscribe( resp => {
            if (resp.blockingMessages && resp.blockingMessages.length > 0) {
              resp.blockingMessages.forEach(msg => {
                this.validationMessages.push(new Message(this.areaCode, msg));
              });
            } else if (resp.warningMessages && resp.warningMessages.length > 0) {
              this.substitutionService.setWarningMessages(resp.warningMessages);
              const modal = this.modalService.openModal(this.openSubstitutionModalViewContainerRef,
                SubstitutionModalComponent, null, () => {
                  if (modal.instance.isConfirm) {
                    this.madeSubstitution(effect);
                  }
                });
            } else {
              this.madeSubstitution(effect);
            }
          }
        );
      }
      if (wasDisabled === true) {
        effect.disable({emitEvent: false});
      }
    }
  }

  private madeSubstitution(effect: AbstractControl) {
    this.substitutionService.createSubstituentContract(
      this.policyNumber,
      effect.value.getTime(),
      this.substitutionForm.get('substReason').value,
      this.substitutionForm.get('product').value,
      this.proposalService.subClusterCode,
      this.proposalService.subAssetCode
    ).subscribe(data => {
      if (!data.substituted && data.messages) {
        data.messages.forEach(msg => {
          this.validationMessages.push(new Message(this.areaCode, msg));
        });
      } else {
        const apiContract = new ApiContract();
        apiContract.id = data.resourceId;
        this.proposalService.substitution = true;
        this.proposalService.setApiContract(apiContract);
        this.proposalService.getContractInCache(apiContract).subscribe(loadedContract => {
          loadedContract.id = data.resourceId;
          this.proposalService.setSubstitutionReason(this.substitutionForm.get('substReason').value);
          let codeReason = '';
          this.substReasons.forEach(reason => {
            if (reason.id === this.substitutionForm.get('substReason').value) {
              codeReason = reason.code;
            }
          });
          this.proposalService.setSubstitutionReasonCode(codeReason);
          this.proposalService.setLoadedProposal(loadedContract);
          this.stateService.nextState(Action.NEXT_BUTTON_PRESSED);
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected validateForm() {
    const controls = this.substitutionForm.controls;

    this.validationMessages.length = 0;

    if (controls.effect.errors && controls.effect.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Effective Date is mandatory'));
    }
    if (controls.product.errors && controls.product.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Product is mandatory'));
    }
    if (controls.substReason.errors && controls.substReason.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Substitution Reason is mandatory'));
    }
    this.eventPropagation.emit('layoutChanged');
  }

  protected init() {
    this.substitutionService.getSubstitutionReasons(this.policyNumber).subscribe(data => {
      this.substReasons = data.subReasons;
      if (data.messages) {
        data.messages.forEach(msg => {
          this.validationMessages.push(new Message(this.areaCode, msg));
        });
      }
    });
    this.substitutionForm.get('effect').disable({emitEvent: false});
    this.substitutionForm.get('product').disable();

    this.substitutionForm.get('substReason').valueChanges.subscribe(value => {
      if (value) {
        this.validationMessages.length = 0;
        const subReasonSelected = this.substReasons.find(reason => reason.id === value);
        const effect = this.substitutionForm.get('effect');
        effect.enable({emitEvent: false});
        if (subReasonSelected.effectiveDate) {
          effect.setValue(new Date(subReasonSelected.effectiveDate));
          if (!subReasonSelected.effectiveDateEnabled) {
            effect.disable({emitEvent: false});
          }
        }
      }
    });

    this.substitutionForm.get('effect').valueChanges.subscribe(value => {
      if (value) {
        if (this.substitutionForm.get('effect').valid) {
          this.validationMessages.length = 0;
          this.substitutionService.getCompatibleProducts(
            this.policyNumber,
            this.substitutionForm.get('effect').value.getTime()
          ).subscribe(data => {
            if (!data.messages) {
              data.messages = [];
            }
            data.messages.forEach(msg => {
              this.validationMessages.push(new Message(this.areaCode, msg));
            });
            this.products = data.compatibleProducts;
            if (this.products) {
              this.substitutionForm.get('product').enable();
              if (this.products.length === 1) {
                this.substitutionForm.get('product').disable();
                this.substitutionForm.patchValue({product: this.products[0].id});
              }
            }
          });
        }
      }
    });
  }
}

import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  AnagIssueApiGetSubjectResponse,
  AnagIssueSubject
} from '../../components/consultation-anag/consultation-anag';
import {RgiRxHttpClientWrapper} from "@rgi/rx/http";


@Injectable({
  providedIn: 'root'
})
export class AnagIssueSubjectResourceService {
  private NODE = 'idNode';

  constructor(
    private http: RgiRxHttpClientWrapper,
    @Inject('environment') private apiConf: any
  ) {
  }

  getSubject(subjectId: string, nodeId: string): Observable<AnagIssueSubject> {
    return this.http.get<AnagIssueApiGetSubjectResponse>(
      `${this.apiConf.api.portal.path}/anag/subject/${subjectId}?${this.NODE}=${nodeId}`
    ).pipe(map(resp => {
        return resp.subject;
      })
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'sortByDatePipe',
})

export class SortByDatePipe implements PipeTransform {
    transform(array: Array<any>, key: string, order: 'asc' | 'desc' = 'desc' ): any {
      if (array != null) {
        array.sort((a: any, b: any) => {
          const aDate = a[key];
          const bDate = b[key];
          if (aDate == null && bDate == null) {
            return 0;
          } else if (aDate == null) {
            return -1;
          } else if (bDate == null) {
            return 1;
          } else if (moment(aDate).isAfter(bDate)) {
            return -1;
          } else if (moment(aDate).isBefore(bDate)) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return order === 'asc' ? array.reverse() : array;
    }
  }

import {DetailClaimsNumber} from './detail-claims-number';

export class ClaimsReportYear {

  constructor(
    public year: number,
    public yearReal: number,
    public principalResponsibility: DetailClaimsNumber,
    public pairResponsibility: DetailClaimsNumber
  ) {
  }

}

<ng-container *ngIf="summaryAdministrativeData" data-qa="rgi-gp-policyData">

  <div class="rgi-gp-grid-container">
    <div *ngFor="let item of modelKeys; index as i">
      <label class="rgi-ui-form-control-label">
        <span class="rgi-ui-text-5" translate data-qa="rgi-gp-policyDataLabel">{{mapKey[item]}}</span>
        <span class="rgi-ui-text-5">:
                <b
                  data-qa="rgi-gp-policyDataValue">{{(item) === 'status' ? summaryAdministrativeData[item].description :
                  formatDate(summaryAdministrativeData[item], item)}}</b>
              </span>
      </label>
      <div *ngIf="modelKeys.length > 3 && i < (integerDiv(modelKeys.length)*3)">
              <hr class="rgi-gp-separator">
            </div>
        </div>
    </div>

</ng-container>

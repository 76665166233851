<div class="btn-group btn-group-justified">
  <div class="btn-group">
    <button type="button" class="btn btn-warning pull-right" (click)="openNewModel()"
            attr.data-qa="letter-templates-card-btn">{{ '_CLAIMS_._BUTTONS_._NEW_LETTER_MODEL' | translate }}</button>
  </div>
  <div class="btn-group">
    <button type="submit" class="btn btn-warning pull-right" attr.data-qa="letter-templates-card-btn"
            id="letter-templates-card-submit" (click)="openLetterModelList()">{{ '_CLAIMS_._BUTTONS_._MODELS_LIST' | translate }}</button>
  </div>
</div>

<div class="btn-group btn-group-justified" *ngIf="labels | async; else loading">
  <div class="btn-group text-uppercase" *ngIf="backVisible" @fade>
    <button (click)="onBack()" class="btn btn-warning btn-secondary"  [attr.data-qa]="'back-'+qa" type="button" translate>{{ backLabel }}</button>
  </div>
  <div class="btn-group no-border text-uppercase" *ngIf="nextVisible" @fade>
    <button [disabled]="disableNext" (click)="onNext()" [ngClass]="buttonClass" class="btn btn-warning"
            [attr.data-qa]="isConfirm ? 'confirm-' : 'next-' + qa" type="button" translate>{{ nextLabel }}</button>
  </div>
  <div class="btn-group no-border text-uppercase" *ngIf="isConfirmAndAccept" @fade>
    <button (click)="onNextConfAccept()" [disabled]="disableNext" [ngClass]="buttonClass" class="btn btn-warning btn-secondary"
             type="button" [attr.data-qa]="'button'+confirmAcceptLabel.toLowerCase().trim()" translate>{{ confirmAcceptLabel }}</button>
  </div>
</div>
<ng-template #loading>
  <div class="btn-group btn-group-justified">
    <div class="btn-group text-uppercase" @fade>
      <button class="btn btn-loading btn-secondary" type="button" [attr.data-qa]="'rendering-button'">
        <span class="rgi-refresh navigation-button"></span>
      </button>
    </div>
  </div>
</ng-template>

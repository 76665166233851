import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';

@Pipe({name: 'toPercentage'})
export class ToPercentage implements PipeTransform {
  transform(value: string): string {
    const numberValue: number = Number(value);
    if (value == null || value === EMPTY_STR || isNaN(numberValue)) {
      return '-';
    }
    const percentage = numberValue * 100;
    return `${percentage.toFixed(2)} %`;
  }
}

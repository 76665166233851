import {Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {AnagPaymentSectionComponent} from '../payment-section/payment-section.component';
import {AnagApiParty} from '../../../anag-model/anag-domain/anag-api-party';
import {DIALOG_DATA, ModalService, OnModalClose} from '@rgi/rx/ui';
import {AnagStorageService} from '../../../anag-resources/anag-storage.service';
import {AnagStateManagerPartyEditor} from '../../../anag-states/party-editor/anag-state-manager-party-editor';
import {AnagStatelessService} from '../../../anag-resources/anag-stateless.service';
import {FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {PAYMENT_MEANS_PARTY} from '../../../anag-constants/anag-constants';
import {AnagPartyPayment} from '../../../anag-model/anag-domain/anag-party-payment';
import {AnagIdentificationEntity} from '../../../anag-model/anag-domain/anag-identification-entity';
import {AnagEntityIta} from '../../../anag-model/anag-api/anag-subject-api';
import {DatePipe} from '@angular/common';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PaymentField} from '../../../anag-model/anag-domain/anag-payments';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {RgiCountryLayerValidators} from "@rgi/country-layer";

@Component({
  selector: 'rgi-anag-anag-payments',
  templateUrl: './anag-payments.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagPaymentsComponent implements OnInit, OnModalClose, OnDestroy {
  anagPaymentForm = new UntypedFormGroup({});
  isConfirmed: boolean;
  modalClose: EventEmitter<any> = new EventEmitter();
  ibanMap = new Map<string, string>;

  constructor(public stateMgr: AnagStateManagerPartyEditor,
              public anagStorage: AnagStorageService,
              public statelessService: AnagStatelessService,
              public modalService: ModalService,
              public datePipe: DatePipe,
              public translateService: RgiRxTranslationService,
              public pushMessageHandler: PushMessageHandlerService,
              public anagPaymentSectionComponent: AnagPaymentSectionComponent,
              @Optional() @Inject(DIALOG_DATA) party: AnagApiParty) {
    if (party) {
      this.anagPaymentSectionComponent.inputParty = party;
      if (this.anagPaymentSectionComponent.inputParty.payments && this.anagPaymentSectionComponent.inputParty.payments.length > 0) {
        this.anagPaymentSectionComponent.partyPayments = this.anagPaymentSectionComponent.inputParty.payments;
      }
    }
  }



  ngOnInit() {
    this.pushMessageHandler.clearTag('er-tag');
    this.pushMessageHandler.clearTag('iban-tag');
    this.anagPaymentSectionComponent.ngOnInit();
    if (!!this.anagPaymentSectionComponent.partyPayments && !!this.anagPaymentSectionComponent.partyPayments.length) {
      this.createFormFromFields(null, null, true);
    }
  }

  private createFormFromFields(indexDeleting?: number, tmpPartyPayments?: AnagPartyPayment[], isOnInit?: boolean) {
    if (tmpPartyPayments) {
      if (tmpPartyPayments.length > 0 && indexDeleting >= 0) {
        const tmpForm = new UntypedFormGroup({});
        let indexForLoadField;
        let startIncreaseIndex;

        tmpPartyPayments.forEach((payment, i) => {
          if (i === indexDeleting) {
            startIncreaseIndex = true;
          }
          indexForLoadField = startIncreaseIndex ? i + 1 : i;
          tmpForm.setControl(`${payment.paymentType}_${payment.paymentTypeID}_${i}`, this.loadFieldsForPayment(payment, isOnInit, indexForLoadField));
        });
        this.anagPaymentForm.controls = Object.assign({}, tmpForm.controls);
      } else {
        this.anagPaymentForm = new UntypedFormGroup({});
      }

    } else {
      this.anagPaymentSectionComponent.partyPayments.forEach((payment, i) => {
        this.formatCreditCardExp(payment);
        this.anagPaymentForm.setControl(`${payment.paymentType}_${payment.paymentTypeID}_${i}`, this.loadFieldsForPayment(payment, isOnInit, i));
      });
    }
    this.anagPaymentForm.updateValueAndValidity();
  }

   formatCreditCardExp(payment: AnagPartyPayment) {
    if (payment.cartaDiCreditoScadenza) {
      if (payment.cartaDiCreditoScadenza.includes('-')) {
        const creditCardExp = payment.cartaDiCreditoScadenza.split('-');
        const year = creditCardExp[0];
        const month = creditCardExp[1];
        payment.cartaDiCreditoScadenza = `${month}/${year}`;
      }
    }
  }

  savePaymentData() {
    this.isConfirmed = true;
    this.anagPaymentSectionComponent.partyPayments = this.anagPaymentSectionComponent.partyPayments.filter(el => el.paymentTypeID && !!el.paymentTypeID);
    this.anagPaymentForm.updateValueAndValidity();
    if (this.checkIban()) {
      if (this.anagPaymentForm.valid) {
        this.adaptFormToPartyPayment();
        this.stateMgr.updatePaymentMethods(this.anagPaymentSectionComponent.partyPayments);
        this.modalClose.emit();
      } else {
        this.pushMessageHandler.clearTag('iban-tag');
        this.notifyFormValidationErrorMsg();
      }
    } else {
      this.pushMessageHandler.clearTag('er-tag');
      this.notifyIbanErrorMsg();
    }
  }

  checkIban(): boolean {
    const rawForm = this.anagPaymentForm.getRawValue();
    const keys = Object.keys(rawForm);
    const lastKey = keys[keys.length - 1];
    const lastObject = rawForm[lastKey];
    const ciban = (lastObject && lastObject.ciban) || '';
    if (!ciban || (this.anagPaymentForm.controls[lastKey] as FormGroup).controls['ciban'].disabled) {
      return true;
    }
    return (this.anagPaymentForm.controls[lastKey] as FormGroup).controls['ciban'].valid;
  }


  private adaptFormToPartyPayment() {
    const rawForm = this.anagPaymentForm.getRawValue();
    const keyRawForm = Object.keys(rawForm);
    keyRawForm.forEach(rawFormGroupKey => {
      const splittedFormGroupKey = rawFormGroupKey.split('_');
      const paymentType = splittedFormGroupKey[0];
      const meanOfPaymentType = splittedFormGroupKey[1];
      const partyPaymentIndex = splittedFormGroupKey[2];
      const fieldsKeys = Object.keys(rawForm[rawFormGroupKey]);
      fieldsKeys.forEach(field => {
        if (PAYMENT_MEANS_PARTY.get(field) === 'cartaDiCreditoTipo') {
          const typesOfPayment = this.anagPaymentSectionComponent.paymentsState.meansOfPayment.meansOfPayment;
          if (!!typesOfPayment && !!typesOfPayment.credMeansOfPayment && !!typesOfPayment.debMeansOfPayment) {
            const concatDebCred = typesOfPayment.credMeansOfPayment.concat(typesOfPayment.debMeansOfPayment);
            const creditCardMean = concatDebCred.find(meanOfPayment => {
              return meanOfPayment.paymentType.codice === paymentType && meanOfPayment.meanOfPayment.identification === meanOfPaymentType;
            });
            const creditCardObj: AnagIdentificationEntity = creditCardMean.creditCards.find(
              creditCard => creditCard.identification === rawForm[rawFormGroupKey][field]
            );
            if (creditCardObj) {
              this.anagPaymentSectionComponent.partyPayments[partyPaymentIndex][PAYMENT_MEANS_PARTY.get(field)] = {
                codice: creditCardObj.identification,
                descrizione: creditCardObj.description
              } as AnagEntityIta;
            }
          }
        } else {
          if (['dactivationsdd', 'dcancelsdd'].includes(field) && rawForm[rawFormGroupKey][field]) {
            this.anagPaymentSectionComponent.partyPayments[partyPaymentIndex][PAYMENT_MEANS_PARTY.get(field)] = new Date(rawForm[rawFormGroupKey][field]);
          } else {
            this.anagPaymentSectionComponent.partyPayments[partyPaymentIndex][PAYMENT_MEANS_PARTY.get(field)] = rawForm[rawFormGroupKey][field];
          }
        }
      });
    });
  }

  loadFieldsForPayment(payment: AnagPartyPayment, isOnInit?: boolean, index?: number): UntypedFormGroup {
    const key = `${payment.paymentType}_${payment.paymentTypeID}`;
    const paymentEl = this.anagPaymentSectionComponent.paymentFormMap.get(key);
    let fieldFormEl;
    let fieldFormValue;
    if (!!paymentEl && !!paymentEl.paymentFields && !!paymentEl.paymentFields.length) {
      const formGroupPayment = new UntypedFormGroup({});
      paymentEl.paymentFields.forEach((field) => {
        fieldFormValue = this.anagPaymentForm.get(
          payment.paymentType.concat('_').concat(payment.paymentTypeID).concat('_').concat(String(index)).concat('.').concat(field.name)
        );
        const bOffuscated = this.isIbanOffuscated(field, payment);
        fieldFormEl = new UntypedFormControl('', this.customValidatorsAnagPayments(field, bOffuscated));
        if (isOnInit) {
          if (field.name === 'ciban' && bOffuscated) {
            fieldFormEl.disable();
          }
          fieldFormEl.patchValue(this.anagPaymentSectionComponent.getFieldValueFromParty(payment, field));
        } else {
          fieldFormEl.patchValue(fieldFormValue && fieldFormValue.value ? fieldFormValue.value : null);
        }
        formGroupPayment.setControl(  `${field.name}`, fieldFormEl);
      });
      this.anagPaymentForm.updateValueAndValidity();
      return formGroupPayment;
    }
    return undefined;
  }


  customValidatorsAnagPayments(field: PaymentField, isIbanOffuscated: boolean) {
    const validators = [];
    if (field.mandatory) {
      validators.push(Validators.required);
    }
    switch (field.name) {
      case 'cbic':
        validators.push(Validators.maxLength(11));
        validators.push(Validators.pattern('^[a-zA-Z]*$'));
        break;
      case 'cuic':
        validators.push(Validators.maxLength(1));
        validators.push(Validators.pattern('^[a-zA-Z]*$'));
        break;
      case 'cforeigncurrency':
        validators.push(Validators.maxLength(3));
        validators.push(Validators.pattern('^[a-zA-Z]*$'));
        break;
      case 'ciban':
        if (!isIbanOffuscated) {
          validators.push(RgiCountryLayerValidators.iban);
        }

        break;
    }
    this.anagPaymentForm.updateValueAndValidity();
    return validators;
  }

  isIbanOffuscated(field: PaymentField, payment: AnagPartyPayment) {
    if (payment) {
      const ibanValue = this.anagPaymentSectionComponent.getFieldValueFromParty(payment, field);
      if (ibanValue && ibanValue.includes('*')) {
        return true;
      }
    }
    return false;
  }

  onPaymentTypeSelect(event, i: number, type: string) {
    let paymentTypeSelected;
    switch (type) {
      case 'debit':
        paymentTypeSelected = this.anagPaymentSectionComponent.paymentsState.meansOfPayment.meansOfPayment.debMeansOfPayment.find(
          meanOfPayment => {
            return meanOfPayment.meanOfPayment.identification === event.target.value;
          }
        );
        break;
      case 'credit':
        paymentTypeSelected = this.anagPaymentSectionComponent.paymentsState.meansOfPayment.meansOfPayment.credMeansOfPayment.find(
          meanOfPayment => {
            return meanOfPayment.meanOfPayment.identification === event.target.value;
          }
        );
        break;
    }
    this.anagPaymentSectionComponent.partyPayments[i].paymentTypeDescription = paymentTypeSelected.meanOfPayment.description;
    this.anagPaymentSectionComponent.partyPayments[i].paymentType = paymentTypeSelected.paymentType.codice;
    this.anagPaymentSectionComponent.partyPayments[i].paymentTypeID = paymentTypeSelected.meanOfPayment.identification;

    this.createFormFromFields();
  }

  addNewPayment() {
    this.anagPaymentSectionComponent.partyPayments.push(new AnagPartyPayment());
  }

  deletePaymentMethod(index: number, controlName: string) {
    this.pushMessageHandler.clearTag('er-tag');
    this.pushMessageHandler.clearTag('iban-tag');
    if (index !== undefined ) {
      const tmpPartyPaymentsSpliced = this.anagPaymentSectionComponent.partyPayments.map(el => ({...el}));
      tmpPartyPaymentsSpliced.splice(index, 1);
      this.createFormFromFields(index, tmpPartyPaymentsSpliced);
      this.anagPaymentSectionComponent.partyPayments = tmpPartyPaymentsSpliced;
    }
  }

  actionClose() {
    this.modalClose.emit();
  }

  ngOnDestroy(): void {
  }

  isFieldInvalid(formGroupName: string, controlName: string) {
    const formControl = this.anagPaymentForm.get(formGroupName.concat('.').concat(controlName));
    return formControl && formControl.invalid && (formControl.touched || this.isConfirmed) ;
  }

  isFieldEnable(formGroupName: string, controlName: string) {
    const formControl = this.anagPaymentForm.get(formGroupName.concat('.').concat(controlName));
    return formControl && formControl.enabled;
  }

  canRevert(formGroupName: string, controlName: string) {
    const frmCtrlName = formGroupName.concat('.').concat(controlName);
    const formControl = this.anagPaymentForm.get(frmCtrlName);
    return formControl.enabled && this.ibanMap.get(frmCtrlName);
  }

  enableIban(formGroupName: string, controlName: string) {
    const frmCtrlName = formGroupName.concat('.').concat(controlName);
    const formControl = this.anagPaymentForm.get(frmCtrlName);

    if (formControl.value) {
      formControl.enable();
      this.ibanMap.set(frmCtrlName, formControl.value);
      formControl.patchValue('');
      formControl.setValidators([RgiCountryLayerValidators.iban]);
      this.anagPaymentForm.updateValueAndValidity();
    }
  }

  revertIban(formGroupName: string, controlName: string) {
    const frmCtrlName = formGroupName.concat('.').concat(controlName);
    const formControl = this.anagPaymentForm.get(frmCtrlName);
    const oldValue = this.ibanMap.get(frmCtrlName);

    if (oldValue) {
      formControl.patchValue(oldValue);
      formControl.setValidators(null);
      formControl.disable();
      this.pushMessageHandler.clearTag('iban-tag');
      this.anagPaymentForm.updateValueAndValidity();
    }
  }

  checkErrors(errors: ValidationErrors) {
    const messages = [];
    if (errors) {
      Object.keys(errors).forEach(err => {
        switch (err) {
          case 'pattern':
            messages.push('_ANAG_._MSG_._PATTERNERR_');
            break;
          case 'maxlength':
            messages.push('_ANAG_._MSG_._LENGTHERR_');
            break;
        }
      });
    }
    return messages;
  }

  notifyFormValidationErrorMsg() {
    this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(stringMsg =>
      this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
        tag: 'er-tag',
        status: 'danger',
        dismissible: false
      }))
    ).unsubscribe();
  }

  get datepickerPlaceholder() {
    return this.statelessService.getDatePickerPlaceholderLabel();
  }

  notifyIbanErrorMsg() {
    this.translateService.translate('_ANAG_._MSG_._IBAN_INVALID_').subscribe(stringMsg =>
      this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
        tag: 'iban-tag',
        status: 'danger',
        dismissible: false
      }))
    ).unsubscribe();
  }

  resetErrorMsg() {
    this.pushMessageHandler.clearTag('er-tag');
    this.pushMessageHandler.clearTag('iban-tag');
  }

}

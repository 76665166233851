import {
  RGI_RX_LOCALE,
  RgiRxi18nModuleLoadType,
  RgiRxTranslationCatalogService,
  RgiRxTranslations,
  RgiRxTranslationService,
  Serialized18n
} from '@rgi/rx/i18n';
import {Injector} from '@angular/core';
import {isScalarType, LoggerFactory} from '@rgi/rx';
import {filter, map, take, tap} from 'rxjs/operators';
import {PortalConfig} from '../rgi-rx-portal-api';

declare var angular: angular.IAngularStatic;

export function portalLanguageChangeFactory(portalConfig: PortalConfig, translationService: RgiRxTranslationService, catalog: RgiRxTranslationCatalogService) {
  const loaded: { [key in RGI_RX_LOCALE]: boolean } = {};
  angular.module(portalConfig.module).run(['coreLang', '$rootScope', 'gettextCatalog', (coreLang, $rootScope, gettextCatalog) => {
    translationService.getLanguageChange$()
      .pipe(
        filter(locale => !loaded[locale])
      )
      .subscribe(
        locale => {
          catalog.getCatalog$(locale).pipe(
            take(1),
            map(i18n => {
              const mapped: any = {};
              for (const i18nKey in i18n) {
                if (isScalarType(i18n[i18nKey])) {
                  mapped[i18nKey] = i18n[i18nKey];
                }
              }
              return mapped;
            }),
            filter(value => !!value && Object.keys(value).length > 0),
            tap(i18n => {
              gettextCatalog.setStrings(locale, i18n);
              loaded[locale] = true;
              coreLang.setLang(locale);
            })
          ).subscribe(); // todo refactor this inner subscription with mergeMap
        }
      );
    $rootScope.$on('gettextLanguageChanged', () => {
      translationService.setCurrentLanguage(gettextCatalog.getCurrentLanguage());
    });
    translationService.setCurrentLanguage(gettextCatalog.getCurrentLanguage(), {force: true});
  }]);
}

export function getTextCatalogI18nFactory(injector: Injector, locale: RGI_RX_LOCALE) {
  return new Promise<Serialized18n>(resolve => {

    setTimeout(() => {
      let gettextCatalog;
      const logger = LoggerFactory();
      try {
        gettextCatalog = injector.get('gettextCatalog');
      } catch (e) {
        logger.debug('Cannot resolve gettextCatalog provider since is not provided. Provide gettextCatalog to allow i18n factories to resolve the legacy portal catalog!', e);
        return resolve({});
      }
      const catalogue = gettextCatalog.strings[locale];
      const target = {};
      Object.entries(catalogue).forEach(
        (value: any) => {
          if (value && value[0] && value[1] && value[1].hasOwnProperty('$$noContext')) {
            target[value[0]] = value[1].$$noContext[0];
          }
        }
      );
      return resolve(target);
    });
  });
}

export function loadCatalogLocaleIT(injector?: Injector): RgiRxi18nModuleLoadType {
  return getTextCatalogI18nFactory(injector, 'it');
}

export function loadCatalogLocaleEN(injector?: Injector): RgiRxi18nModuleLoadType {
  return getTextCatalogI18nFactory(injector, 'en');
}

export function loadCatalogLocaleFR(injector?: Injector): RgiRxi18nModuleLoadType {
  return getTextCatalogI18nFactory(injector, 'fr');
}

export function loadCatalogLocaleDE(injector?: Injector): RgiRxi18nModuleLoadType {
  return getTextCatalogI18nFactory(injector, 'de');
}

export function loadCatalogLocaleES(injector?: Injector): RgiRxi18nModuleLoadType {
  return getTextCatalogI18nFactory(injector, 'es');
}

export const PORTAL_i18n_bindings: RgiRxTranslations = [
  {
    load: loadCatalogLocaleIT,
    locale: 'it'
  },
  {
    load: loadCatalogLocaleEN,
    locale: 'en'
  },
  {
    load: loadCatalogLocaleFR,
    locale: 'fr'
  },
  {
    load: loadCatalogLocaleES,
    locale: 'es'
  }
];

export function portalRgiRxTranslationsFactory(portalConfig: PortalConfig): RgiRxTranslations {
  if (portalConfig.i18n && portalConfig.i18n.mergeCatalog) {
    return PORTAL_i18n_bindings;
  }
  return [];
}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressesService, CardComponentService, ElemEnum, Party, SessionService } from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../../../claim-opening.service';
import { SearchHospitalComponent } from '../search-hospital/search-hospital.component';

@Component({
  selector: 'claims-injury-asset-detail',
  templateUrl: './injury-asset-detail.component.html',
  styleUrls: ['./injury-asset-detail.component.scss']
})
export class InjuryAssetDetailComponent implements OnInit {
  @Input() party: Party;
  @Input() addAssetForm: UntypedFormGroup;
  @Input() card: any;
  @Input() visibleNominative: boolean = false;
  @Input() injuryCauseList: any[];
  @Input() injuryNatureList: Map<string, any[]>;
  @Input() injuryLocationList: Map<string, any[]>;
  @Input() professionList: any[];

  selectedCountryER: any;
  countries: any[] = [];
  disableSearchProviders: boolean = true;
  disablePlaceER: boolean = true;
  visGraphicBody: boolean = true;
  disableNotCodeable: boolean = true;
  disableMultiple: boolean = true;
  surrogaAnsw: any[] = [
    {code: 1, description: 'YES'},
    {code: 2, description: 'NO'},
  ];

 // injuryCauseCombo: any[] =[];
  injuryNatureCombo: any[] = [];
  // injuryNatureCombo2: any[] =[];
  // injuryNatureCombo3: any[] =[];
  injuryLocationCombo: any[] = [];
  // injuryLocationCombo2: any[] =[];
  // injuryLocationCombo3: any[] =[];

  // damageList: any[] =[]
  classCaus = 'col-sm-3 col-xs-12';
  classNat = 'col-sm-4 col-xs-12';

  rootImg = 'assets/img/Body/';
  mappaBody = new Map<string, string>();
  mappaBodyDescr = new Map<string, string>();
  corpoCranio = this.rootImg + '11.png';
  corpoCervicale = this.rootImg + '23.png';
  spallaSX = this.rootImg + '36.png';
  spallaDX = this.rootImg + '35.png';
  corpoSchiena = this.rootImg + '24.png';
  corpoLombi = this.rootImg + '25.png';
  corpoCoccige = this.rootImg + '26.png';
  corpoGambe = this.rootImg + '27corpoGambe.png';
  caviglieSX = this.rootImg + '70.png';
  caviglieDX = this.rootImg + '69.png';
  corpoFronte = this.rootImg + '11corpoFronte.png';
  orecchioDX = this.rootImg + '34.png';
  occhioDX = this.rootImg + '32.png';
  occhioSX = this.rootImg + '31.png';
  orecchioSX = this.rootImg + '33.png';
  corpoFaccia = this.rootImg + '15.png';
  gola = this.rootImg + '16.png';
  toraceAltoDX = this.rootImg + '40.png';
  toraceAltoSX = this.rootImg + '39.png';
  braccioDX = this.rootImg + '42.png';
  toraceCentroOrgani = this.rootImg + '17.png';
  braccioSX = this.rootImg + '41.png';
  gomitoDX = this.rootImg + '44.png';
  organiAddominali = this.rootImg + '18.png';
  gomitoSX = this.rootImg + '43.png';
  panciaAvambDX = this.rootImg + '46.png';
  corpoPancia = this.rootImg + '22.png';
  panciaAvambSX = this.rootImg + '45.png';
  ventreAncaDX = this.rootImg + '62.png';
  corpoVentre = this.rootImg + '21.png';
  ventreAncaSX = this.rootImg + '61.png';
  polsoDX = this.rootImg + '48.png';
  polsoSX = this.rootImg + '47.png';
  corpoInguine = this.rootImg + 'corpoPolsi_centro.png';
  maniDX = this.rootImg + '50.png';
  cosciaDX = this.rootImg + '64.png';
  cosciaSX = this.rootImg + '63.png';
  maniSX = this.rootImg + '49.png';
  ginocchioDX = this.rootImg + '66.png';
  ginocchioSX = this.rootImg + '65.png';
  gambaDX = this.rootImg + '68.png';
  gambaSX = this.rootImg + '67.png';
  piedeDX = this.rootImg + '72.png';
  piedeSX = this.rootImg + '71.png';

  constructor(
    @Inject('authService') private authService: any,
    private claimOpeningService: ClaimOpeningService,
    private cardComponentService: CardComponentService,
    private sessionServiceComm: SessionService,
    private modalService: NgbModal,
    public addressService: AddressesService,
    public activeModal: NgbActiveModal
  ) {
    this.inizializzaMappaBody();
    if (this.visGraphicBody) {
      this.classCaus = 'col-sm-6 col-xs-12';
      this.classNat = 'col-sm-6 col-xs-12';
    }
  }

  inizializzaMappaBodySoloSel() {
   if (this.mappaBody[11].indexOf('SEL') > 0) {
     this.mappaBody[11] = this.corpoCranio;
   }
   if (this.mappaBody[23].indexOf('SEL') > 0) {
    this.mappaBody[23] = this.corpoCervicale;
   }
   if (this.mappaBody[36].indexOf('SEL') > 0) {
    this.mappaBody[36] = this.spallaSX;
   }
   if (this.mappaBody[35].indexOf('SEL') > 0) {
    this.mappaBody[35] = this.spallaDX;
   }
   if (this.mappaBody[24].indexOf('SEL') > 0) {
    this.mappaBody[24] = this.corpoSchiena;
   }
   if (this.mappaBody[25].indexOf('SEL') > 0) {
    this.mappaBody[25] = this.corpoLombi;
   }
   if (this.mappaBody[26].indexOf('SEL') > 0) {
    this.mappaBody[26] = this.corpoCoccige;
   }
   if (this.mappaBody[70].indexOf('SEL') > 0) {
    this.mappaBody[70] = this.caviglieSX;
   }
   if (this.mappaBody[69].indexOf('SEL') > 0) {
    this.mappaBody[69] = this.caviglieDX;
   }
   if (this.mappaBody[34].indexOf('SEL') > 0) {
    this.mappaBody[34] = this.orecchioDX;
   }
   if (this.mappaBody[32].indexOf('SEL') > 0) {
    this.mappaBody[32] = this.occhioDX;
   }
   if (this.mappaBody[31].indexOf('SEL') > 0) {
    this.mappaBody[31] = this.occhioSX;
   }
   if (this.mappaBody[33].indexOf('SEL') > 0) {
    this.mappaBody[33] = this.orecchioSX;
   }
   if (this.mappaBody[15].indexOf('SEL') > 0) {
    this.mappaBody[15] = this.corpoFaccia;
   }
   if (this.mappaBody[16].indexOf('SEL') > 0) {
    this.mappaBody[16] = this.gola;
   }
   if (this.mappaBody[40].indexOf('SEL') > 0) {
    this.mappaBody[40] = this.toraceAltoDX;
   }
   if (this.mappaBody[39].indexOf('SEL') > 0) {
    this.mappaBody[39] = this.toraceAltoSX;
   }
   if (this.mappaBody[42].indexOf('SEL') > 0) {
    this.mappaBody[42] = this.braccioDX;
   }
   if (this.mappaBody[17].indexOf('SEL') > 0) {
    this.mappaBody[17] = this.toraceCentroOrgani;
   }
   if (this.mappaBody[41].indexOf('SEL') > 0) {
    this.mappaBody[41] = this.braccioSX;
   }
   if (this.mappaBody[44].indexOf('SEL') > 0) {
    this.mappaBody[44] = this.gomitoDX;
   }
   if (this.mappaBody[18].indexOf('SEL') > 0) {
    this.mappaBody[18] = this.organiAddominali;
   }
   if (this.mappaBody[43].indexOf('SEL') > 0) {
    this.mappaBody[43] = this.gomitoSX;
   }
   if (this.mappaBody[46].indexOf('SEL') > 0) {
    this.mappaBody[46] = this.panciaAvambDX;
   }
   if (this.mappaBody[22].indexOf('SEL') > 0) {
    this.mappaBody[22] = this.corpoPancia;
   }
   if (this.mappaBody[45].indexOf('SEL') > 0) {
    this.mappaBody[45] = this.panciaAvambSX;
   }
   if (this.mappaBody[62].indexOf('SEL') > 0) {
    this.mappaBody[62] = this.ventreAncaDX;
   }
   if (this.mappaBody[21].indexOf('SEL') > 0) {
    this.mappaBody[21] = this.corpoVentre;
   }
   if (this.mappaBody[61].indexOf('SEL') > 0) {
    this.mappaBody[61] = this.ventreAncaSX;
   }
   if (this.mappaBody[48].indexOf('SEL') > 0) {
    this.mappaBody[48] = this.polsoDX;
   }
   if (this.mappaBody[47].indexOf('SEL') > 0) {
    this.mappaBody[47] = this.polsoSX;
   }
   if (this.mappaBody[50].indexOf('SEL') > 0) {
    this.mappaBody[50] = this.maniDX;
   }
   if (this.mappaBody[64].indexOf('SEL') > 0) {
    this.mappaBody[64] = this.cosciaDX;
   }
   if (this.mappaBody[63].indexOf('SEL') > 0) {
    this.mappaBody[63] = this.cosciaSX;
   }
   if (this.mappaBody[49].indexOf('SEL') > 0) {
    this.mappaBody[49] = this.maniSX;
   }
   if (this.mappaBody[66].indexOf('SEL') > 0) {
    this.mappaBody[66] = this.ginocchioDX;
   }
   if (this.mappaBody[65].indexOf('SEL') > 0) {
    this.mappaBody[65] = this.ginocchioSX;
   }
   if (this.mappaBody[68].indexOf('SEL') > 0) {
    this.mappaBody[68] = this.gambaDX;
   }
   if (this.mappaBody[67].indexOf('SEL') > 0) {
    this.mappaBody[67] = this.gambaSX;
   }
   if (this.mappaBody[72].indexOf('SEL') > 0) {
    this.mappaBody[72] = this.piedeDX;
   }
   if (this.mappaBody[71].indexOf('SEL') > 0) {
    this.mappaBody[71] = this.piedeSX;
   }
  }


  inizializzaMappaBody() {
    this.mappaBody[11] = this.corpoCranio;
    this.mappaBody[23] = this.corpoCervicale;
    this.mappaBody[36] = this.spallaSX;
    this.mappaBody[35] = this.spallaDX;
    this.mappaBody[24] = this.corpoSchiena;
    this.mappaBody[25] = this.corpoLombi;
    this.mappaBody[26] = this.corpoCoccige;
    this.mappaBody[70] = this.caviglieSX;
    this.mappaBody[69] = this.caviglieDX;
    this.mappaBody[34] = this.orecchioDX;
    this.mappaBody[32] = this.occhioDX;
    this.mappaBody[31] = this.occhioSX;
    this.mappaBody[33] = this.orecchioSX;
    this.mappaBody[15] = this.corpoFaccia;
    this.mappaBody[16] = this.gola;
    this.mappaBody[40] = this.toraceAltoDX;
    this.mappaBody[39] = this.toraceAltoSX;
    this.mappaBody[42] = this.braccioDX;
    this.mappaBody[17] = this.toraceCentroOrgani;
    this.mappaBody[41] = this.braccioSX;
    this.mappaBody[44] = this.gomitoDX;
    this.mappaBody[18] = this.organiAddominali;
    this.mappaBody[43] = this.gomitoSX;
    this.mappaBody[46] = this.panciaAvambDX;
    this.mappaBody[22] = this.corpoPancia;
    this.mappaBody[45] = this.panciaAvambSX;
    this.mappaBody[62] = this.ventreAncaDX;
    this.mappaBody[21] = this.corpoVentre;
    this.mappaBody[61] = this.ventreAncaSX;
    this.mappaBody[48] = this.polsoDX;
    this.mappaBody[47] = this.polsoSX;
    this.mappaBody[50] = this.maniDX;
    this.mappaBody[64] = this.cosciaDX;
    this.mappaBody[63] = this.cosciaSX;
    this.mappaBody[49] = this.maniSX;
    this.mappaBody[66] = this.ginocchioDX;
    this.mappaBody[65] = this.ginocchioSX;
    this.mappaBody[68] = this.gambaDX;
    this.mappaBody[67] = this.gambaSX;
    this.mappaBody[72] = this.piedeDX;
    this.mappaBody[71] = this.piedeSX;


    this.mappaBodyDescr[11] = '_SKULL';
    this.mappaBodyDescr[23] = '_SPINE_CERVICAL_COLUMN';
    this.mappaBodyDescr[36] = '_LEFT_SHOULDER';
    this.mappaBodyDescr[35] = '_RIGHT_SHOULDER';
    this.mappaBodyDescr[24] = '_SPINE_THORACIC_COLUMN';
    this.mappaBodyDescr[25] = '_SPINE_LUMBAR_COLUMN';
    this.mappaBodyDescr[26] = '_SPINE_SACRUS_AND_COCCYX';
    this.mappaBodyDescr[70] = '_LEFT_ANKLE';
    this.mappaBodyDescr[69] = '_RIGHT_ANKLE';
    this.mappaBodyDescr[34] = '_RIGHT_EAR';
    this.mappaBodyDescr[32] = '_RIGHT_EYE';
    this.mappaBodyDescr[31] = '_LEFT_EYE';
    this.mappaBodyDescr[33] = '_LEFT_EAR';
    this.mappaBodyDescr[15] = '_FACE';
    this.mappaBodyDescr[16] = '_NECK';
    this.mappaBodyDescr[40] = '_RIGHT_CHEST_WALL';
    this.mappaBodyDescr[39] = '_LEFT_CHEST_WALL';
    this.mappaBodyDescr[42] = '_RIGHT_ARM';
    this.mappaBodyDescr[17] = '_THORACIC_ORGANS';
    this.mappaBodyDescr[41] = '_LEFT_ARM';
    this.mappaBodyDescr[44] = '_RIGHT_ELBOW';
    this.mappaBodyDescr[18] = '_ABDOMINAL_ORGANS';
    this.mappaBodyDescr[43] = '_LEFT_ELBOW';
    this.mappaBodyDescr[46] = '_RIGHT_FOREARM';
    this.mappaBodyDescr[22] = '_ABDOMINAL_WALL';
    this.mappaBodyDescr[45] = '_LEFT_FOREARM';
    this.mappaBodyDescr[62] = '_PELVIS_RIGHT_HIP';
    this.mappaBodyDescr[21] = '_UROGENITAL_ORGANS';
    this.mappaBodyDescr[61] = '_PELVIS_LEFT_HIP';
    this.mappaBodyDescr[48] = '_RIGHT_WRIST';
    this.mappaBodyDescr[47] = '_LEFT_WRIST';
    this.mappaBodyDescr[50] = '_RIGHT_HAND';
    this.mappaBodyDescr[64] = '_RIGHT_THIGH';
    this.mappaBodyDescr[63] = '_LEFT_THIGH';
    this.mappaBodyDescr[49] = '_LEFT_HAND';
    this.mappaBodyDescr[66] = '_RIGHT_KNEE';
    this.mappaBodyDescr[65] = '_LEFT_KNEE';
    this.mappaBodyDescr[68] = '_RIGHT_LEG';
    this.mappaBodyDescr[67] = '_LEFT_LEG';
    this.mappaBodyDescr[72] = '_RIGHT_FOOT';
    this.mappaBodyDescr[71] = '_LEFT_FOOT';
  }

  ngOnInit() {
    if (!this.addAssetForm.controls.percIP || this.addAssetForm.controls.percIP.value == null) {
       this.addAssetForm.addControl('percIP',
       new UntypedFormControl({value: '' }, Validators.nullValidator));
       this.addAssetForm.addControl('doctorName',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('doctorIdSogg',
       new UntypedFormControl({value: '' }, Validators.nullValidator));
       this.addAssetForm.addControl('doctorIdSoggPA',
       new UntypedFormControl({value: '' }, Validators.nullValidator));
       this.addAssetForm.addControl('fatalDate',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('selectedCountryER',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('placeER',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('idER',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('descriptionER',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('surroga',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('biologicalDamage',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('ggITB',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('injuryCause',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('injuryNature',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('injuryPlace',
       new UntypedFormControl(Validators.nullValidator));
      //  this.addAssetForm.addControl('injuryNature2',
      //  new FormControl(Validators.nullValidator));
      //  this.addAssetForm.addControl('injuryPlace2',
      //  new FormControl(Validators.nullValidator));
      //  this.addAssetForm.addControl('injuryNature3',
      //  new FormControl(Validators.nullValidator));
      //  this.addAssetForm.addControl('injuryPlace3',
      //  new FormControl(Validators.nullValidator));
       this.addAssetForm.addControl('profession',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('moreInjury',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('damageList',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('notCodeable',
       new UntypedFormControl(Validators.nullValidator));
       this.addAssetForm.addControl('multiple',
       new UntypedFormControl(Validators.nullValidator));

       this.addAssetForm.controls.percIP.setValue(null);
       this.addAssetForm.controls.ggITB.setValue(null);
       this.addAssetForm.controls.surroga.setValue('');
       this.addAssetForm.controls.injuryCause.setValue('');
       this.addAssetForm.controls.profession.setValue('');
       this.addAssetForm.controls.selectedCountryER.setValue('');
       this.addAssetForm.controls.fatalDate.setValue(null);
       this.addAssetForm.controls.biologicalDamage.setValue(false);
       this.addAssetForm.controls.moreInjury.setValue(false);
       this.addAssetForm.controls.idER.setValue('');
       this.addAssetForm.controls.descriptionER.setValue('');
       this.addAssetForm.controls.placeER.setValue('');
       this.addAssetForm.controls.doctorIdSogg.setValue('');
       this.addAssetForm.controls.doctorIdSoggPA.setValue('');
       this.addAssetForm.controls.doctorName.setValue('');
       this.addAssetForm.controls.damageList.setValue(new Array<any>());
       this.addAssetForm.controls.notCodeable.setValue(false);
       this.addAssetForm.controls.multiple.setValue(false);
    }
    this.retrieveCountries(this.addAssetForm.controls.selectedCountryER.value);
    this.disableSearchProviders = true;
    this.disablePlaceER = true;
    if (this.addAssetForm.controls.selectedCountryER.value === 'IT') {
      this.disableSearchProviders = false;
    } else if (this.addAssetForm.controls.selectedCountryER.value &&
              this.addAssetForm.controls.selectedCountryER.value !== 'IT') {
      this.disablePlaceER = false;
    }
  }

  chargeNature() {
      this.injuryLocationCombo = [];
      this.addAssetForm.controls.injuryPlace.setValue('');
      this.addAssetForm.controls.injuryNature.setValue('');
      this.addAssetForm.controls.damageList.setValue(new Array<any>());
      this.inizializzaMappaBody();
      this.injuryNatureCombo = this.injuryNatureList[this.addAssetForm.controls.injuryCause.value];
      this.addAssetForm.controls.notCodeable.setValue(false);
      this.addAssetForm.controls.multiple.setValue(false);
      this.disableNotCodeable = true;
      this.disableMultiple = true;
  }

  chargeLocations() {
    this.addAssetForm.controls.injuryPlace.setValue('');
    this.injuryLocationCombo = this.injuryLocationList[this.addAssetForm.controls.injuryNature.value]
                              .filter(injLoc => !this.addAssetForm.controls.damageList.value
                                                .find(aDam => injLoc.code === aDam.idLoc));

    this.inizializzaMappaBodySoloSel();
    this.injuryLocationCombo.forEach(aLocSel => {
      const path: string = this.mappaBody[aLocSel.code];
      if (path) {
       this.mappaBody[aLocSel.code] = path.replace('.png', '_SEL.png');
      }
    });
    this.disableNotCodeable = true;
    this.disableMultiple = true;
    if (this.injuryLocationCombo.find(el => el.code === '99') &&
       !this.addAssetForm.controls.notCodeable.value) {
      // non codificabile
      this.disableNotCodeable = false;
    }
    if (this.injuryLocationCombo.find(el => el.code === '77' &&
       !this.addAssetForm.controls.multiple.value)) {
      // multiple
      this.disableMultiple = false;
    }
  }

  addVisualDamage(cod: string) {
    if (this.injuryLocationCombo && this.injuryLocationCombo.length > 0) {
      this.addAssetForm.controls.injuryPlace.setValue(cod);
      if (this.addAssetForm.controls.injuryPlace.value &&
          !this.addAssetForm.controls.damageList.value
          .find(el => el.idLoc === this.addAssetForm.controls.injuryPlace.value)) {
        const descrIjrLoc = this.injuryLocationCombo
              .find(el => el.code === this.addAssetForm.controls.injuryPlace.value).description;
        const descrIjrNat = this.injuryNatureCombo
              .find(el => el.code === this.addAssetForm.controls.injuryNature.value).description;
        // this.damageList.push({
        //   idNat: this.addAssetForm.controls.injuryNature.value,
        //   idLoc: this.addAssetForm.controls.injuryPlace.value,
        //   nature: descrIjrNat,
        //   location: descrIjrLoc});
        this.addAssetForm.controls.damageList.value.push({
            idNat: this.addAssetForm.controls.injuryNature.value,
            idLoc: this.addAssetForm.controls.injuryPlace.value,
            nature: descrIjrNat,
            location: descrIjrLoc});

        const path: string = this.mappaBody[this.addAssetForm.controls.injuryPlace.value];

        if (path && path.indexOf('SEL') > 0) {
          this.mappaBody[this.addAssetForm.controls.injuryPlace.value] = path.replace('_SEL.png', '_IJR.png');
        } else if (path) {
          this.mappaBody[this.addAssetForm.controls.injuryPlace.value] = path.replace('.png', '_IJR.png');
        }

        if (cod === '77') {
          this.disableMultiple = true;
        }

        if (cod === '99') {
          this.disableNotCodeable = true;
        }

        // this.addAssetForm.controls.injuryPlace.setValue('');
        // this.addAssetForm.controls.injuryNature.setValue('');
      }
    }
  }

  addDamage(event: any) {
    if (this.addAssetForm.controls.injuryPlace.value &&
        !this.addAssetForm.controls.damageList.value
         .find(el => el.nature === this.addAssetForm.controls.injuryNature.value)) {
      const descrIjrLoc = this.injuryLocationCombo
             .find(el => el.code === this.addAssetForm.controls.injuryPlace.value).description;
      const descrIjrNat = this.injuryNatureCombo
             .find(el => el.code === this.addAssetForm.controls.injuryNature.value).description;
      // this.damageList.push({
      //   idNat: this.addAssetForm.controls.injuryNature.value,
      //   idLoc: this.addAssetForm.controls.injuryPlace.value,
      //   nature: descrIjrNat,
      //   location: descrIjrLoc});
      this.addAssetForm.controls.damageList.value.push({
          idNat: this.addAssetForm.controls.injuryNature.value,
          idLoc: this.addAssetForm.controls.injuryPlace.value,
          nature: descrIjrNat,
          location: descrIjrLoc});

      const path: string = this.mappaBody[this.addAssetForm.controls.injuryPlace.value];

      if (path && path.indexOf('SEL') > 0) {
        this.mappaBody[this.addAssetForm.controls.injuryPlace.value] = path.replace('_SEL.png', '_IJR.png');
      } else if (path) {
        this.mappaBody[this.addAssetForm.controls.injuryPlace.value] = path.replace('.png', '_IJR.png');
      }

      this.addAssetForm.controls.injuryPlace.setValue('');
      this.addAssetForm.controls.injuryNature.setValue('');
    }
  }

  removeDamage(index: number) {
    const path: string = this.mappaBody[this.addAssetForm.controls.damageList.value[index].idLoc];

    if (path) {
     this.mappaBody[this.addAssetForm.controls.damageList.value[index].idLoc] = path.replace('_IJR.png', '.png');
    }

    this.inizializzaMappaBodySoloSel();

    this.addAssetForm.controls.damageList.value.splice(index, 1);
    this.addAssetForm.controls.injuryPlace.setValue('');
    this.addAssetForm.controls.injuryNature.setValue('');

    if (this.addAssetForm.controls.damageList.value[index].idLoc === '77') {
      this.addAssetForm.controls.multiple.setValue(false);
    }

    if (this.addAssetForm.controls.damageList.value[index].idLoc === '99') {
      this.addAssetForm.controls.notCodeable.setValue(false);
    }
  }

  protected retrieveCountries(selectedCountry?: string) {

      this.addressService.getCountries(new Date()).subscribe(
        (response: ElemEnum[]) => {
          for (const element of response) {
            this.countries.push({ id: element.codice, value: element.descrizione });
            if (!this.selectedCountryER && (selectedCountry === element.codice)) {
              this.selectedCountryER = { id: element.codice, value: element.descrizione };
            }
          }
        });
  }

  enableProviders(event?: any) {
    this.disableSearchProviders = true;
    this.disablePlaceER = true;
    if (this.selectedCountryER.id === 'IT') {
      this.disableSearchProviders = false;
    } else if (this.selectedCountryER && this.selectedCountryER.id !== 'IT') {
      this.disablePlaceER = false;
    }
    this.addAssetForm.controls.selectedCountryER.setValue(this.selectedCountryER.id);
  }

  searchHealthcareProviders(event: any) {
    event.stopPropagation();
    //  this.activeModal.close();
    this.claimOpeningService.setSubjectType('hospitalSearch');
    this.claimOpeningService.setAddAssetForm(this.addAssetForm);

    const modalRef = this.modalService.open(SearchHospitalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.addAssetForm = this.addAssetForm;
    modalRef.result.then((result) => {
      if (result) {
        console.log('openSearchHospital ->', result);
      }
    });
    return false;
  }

  searchDoc(event: any) {
    const nodeId = this.authService.getSalePointId();
    const eventName = 'add-third';

    this.claimOpeningService.setSubjectType('doctor');
    this.claimOpeningService.setAddAssetForm(this.addAssetForm);
    this.cardComponentService.emit({
      eventName,
      salePointId: nodeId
    }, this.card.id);
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 're-issue-stepper',
  templateUrl: './re-issue-stepper.component.html',
  styleUrls: ['./re-issue-stepper.component.scss']
})
export class ReIssueStepperComponent implements OnInit {

  constructor() {
  }

  @Input() currentStep: number;
  @Input() labels: string[];
  @Input() isExternalCard: boolean;
  @Output() goToIthStep: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
  }

  isStepSelectable(index: number): boolean {
      return index <= this.currentStep;


  }

  public onStepChange(event: any): void {
    console.log(event.selectedIndex);
    this.currentStep = event.selectedIndex;
    this.goToIthStep.emit(this.currentStep);
  }



}


import { ContractNumber } from './contract-number';

export class Contract {
    public id: string;
    public contractNumber: ContractNumber;
    public completenessErrors: any;
    public correctnessErrors: any;
    public editProposal: boolean;
    public policyType: any;
    public contractId: number;

    constructor() { }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { ReactiveFormsModule } from '@angular/forms';
import { DatepickerPaymentComponent } from './datepicker-payment.component';


@NgModule({
    declarations: [DatepickerPaymentComponent],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        ReactiveFormsModule
    ],
    providers: [],
    exports: [DatepickerPaymentComponent]
})
export class DatepickerPaymentModule { }

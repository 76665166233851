import {Provincia} from './provincia';
import {ApiContract} from '../models/api-models/api-contract';
import {GenericEntity} from '../models/domain-models/generic-entity';

// REGEXP GENERICHE
const LETTERECONSENTITE = '[ABCDEFGHJKLMNPRSTVWXYZ]';
const LETTERECONSENTITEPROVV = '[ABCDEHLMNOPRSTVWX]';
const RGX_MOTO_RIMORCHI = '^(?:' + LETTERECONSENTITE + '{2})[0-9]{5}$';
const RGX_FARMING_MACHINES = '^(?:' + LETTERECONSENTITE + '{2})[0-9]{3}' + LETTERECONSENTITE + '$';
const RGX_OPERATING_MACHINES = '^(?:' + LETTERECONSENTITE + '{3})[0-9]{3}$';
const RGX_CARS_TRUCKS_TOW = '^(?:' + LETTERECONSENTITE + '{2})[0-9]{3}(?:' + LETTERECONSENTITE + '{2})$';
const RGX_TEMPORARY = '^(?:' + LETTERECONSENTITEPROVV + '{1})[0-9]{7}$';
const RGX_SCOOTER = '^(?:' + LETTERECONSENTITE + '[]{1})[0-9]{7}$';

export const PROVINCE: Provincia[] = [
  {nome: 'Agrigento', sigla: 'AG', siglaProvv: 'W1', siglaProvvRichiesta: 'W1'},
  {nome: 'Alessandria', sigla: 'AL', siglaProvv: 'A1', siglaProvvRichiesta: 'A1'},
  {nome: 'Ancona', sigla: 'AN', siglaProvv: 'O1', siglaProvvRichiesta: 'O1'},
  {nome: 'Aosta', sigla: 'AO', siglaProvv: 'A2', siglaProvvRichiesta: 'A2'},
  {nome: 'Arezzo', sigla: 'AR', siglaProvv: 'M1', siglaProvvRichiesta: 'M1'},
  {nome: 'Ascoli Piceno', sigla: 'AP', siglaProvv: 'O2', siglaProvvRichiesta: 'O2'},
  {nome: 'Asti', sigla: 'AT', siglaProvv: 'A3', siglaProvvRichiesta: 'A3'},
  {nome: 'Avellino', sigla: 'AV', siglaProvv: 'S1', siglaProvvRichiesta: 'S1'},
  {nome: 'Bari', sigla: 'BA', siglaProvv: 'T1', siglaProvvRichiesta: 'T1'},
  {nome: 'Belluno', sigla: 'BL', siglaProvv: 'D1', siglaProvvRichiesta: 'D1'},
  {nome: 'Benevento', sigla: 'BN', siglaProvv: 'S2', siglaProvvRichiesta: 'S2'},
  {nome: 'Bergamo', sigla: 'BG', siglaProvv: 'B1', siglaProvvRichiesta: 'B1'},
  {nome: 'Biella', sigla: 'BI', siglaProvv: 'A8', siglaProvvRichiesta: 'A8'},
  {nome: 'Bologna', sigla: 'BO', siglaProvv: 'L1', siglaProvvRichiesta: 'L1'},
  {nome: 'Bolzano', sigla: 'BZ', siglaProvv: 'C1', siglaProvvRichiesta: 'C1'},
  {nome: 'Brescia', sigla: 'BS', siglaProvv: 'B2', siglaProvvRichiesta: 'B2'},
  {nome: 'Brindisi', sigla: 'BR', siglaProvv: 'T2', siglaProvvRichiesta: 'T2'},
  {nome: 'Cagliari', sigla: 'CA', siglaProvv: 'X1', siglaProvvRichiesta: 'X1'},
  {nome: 'Caltanissetta', sigla: 'CL', siglaProvv: 'W2', siglaProvvRichiesta: 'W2'},
  {nome: 'Campobasso', sigla: 'CB', siglaProvv: 'R1', siglaProvvRichiesta: 'R1'},
  {nome: 'Caserta', sigla: 'CE', siglaProvv: 'S3', siglaProvvRichiesta: 'S3'},
  {nome: 'Catania', sigla: 'CT', siglaProvv: 'W3', siglaProvvRichiesta: 'W3'},
  {nome: 'Catanzaro', sigla: 'CZ', siglaProvv: 'V1', siglaProvvRichiesta: 'V1'},
  {nome: 'Chieti', sigla: 'CH', siglaProvv: 'R2', siglaProvvRichiesta: 'R2'},
  {nome: 'Como', sigla: 'CO', siglaProvv: 'B3', siglaProvvRichiesta: 'B3'},
  {nome: 'Cosenza', sigla: 'CS', siglaProvv: 'V2', siglaProvvRichiesta: 'V2'},
  {nome: 'Cremona', sigla: 'CR', siglaProvv: 'B4', siglaProvvRichiesta: 'B4'},
  {nome: 'Crotone', sigla: 'KR', siglaProvv: 'V4', siglaProvvRichiesta: 'V4'},
  {nome: 'Cuneo', sigla: 'CN', siglaProvv: 'A4', siglaProvvRichiesta: 'A4'},
  {nome: 'Enna', sigla: 'EN', siglaProvv: 'W4', siglaProvvRichiesta: 'W4'},
  {nome: 'Ferrara', sigla: 'FE', siglaProvv: 'L2', siglaProvvRichiesta: 'L2'},
  {nome: 'Firenze', sigla: 'FI', siglaProvv: 'M2', siglaProvvRichiesta: 'M2'},
  {nome: 'Foggia', sigla: 'FG', siglaProvv: 'T3', siglaProvvRichiesta: 'T3'},
  {nome: 'Forli-Cesena', sigla: 'FO', siglaProvv: 'L3', siglaProvvRichiesta: 'L3'},
  {nome: 'Frosinone', sigla: 'FR', siglaProvv: 'P1', siglaProvvRichiesta: 'P1'},
  {nome: 'Genova', sigla: 'GE', siglaProvv: 'H1', siglaProvvRichiesta: 'H1'},
  {nome: 'Gorizia', sigla: 'GO', siglaProvv: 'E1', siglaProvvRichiesta: 'E1'},
  {nome: 'Grosseto', sigla: 'GR', siglaProvv: 'M3', siglaProvvRichiesta: 'M3'},
  {nome: 'Imperia', sigla: 'IM', siglaProvv: 'H2', siglaProvvRichiesta: 'H2'},
  {nome: 'Isernia', sigla: 'IS', siglaProvv: 'R6', siglaProvvRichiesta: 'R6'},
  {nome: 'L’Aquila', sigla: 'AQ', siglaProvv: 'R3', siglaProvvRichiesta: 'R3'},
  {nome: 'La Spezia', sigla: 'SP', siglaProvv: 'H3', siglaProvvRichiesta: 'H3'},
  {nome: 'Latina', sigla: 'LT', siglaProvv: 'P2', siglaProvvRichiesta: 'P2'},
  {nome: 'Lecce', sigla: 'LE', siglaProvv: 'T4', siglaProvvRichiesta: 'T4'},
  {nome: 'Lecco', sigla: 'LC', siglaProvv: 'B10', siglaProvvRichiesta: 'BA'},
  {nome: 'Livorno', sigla: 'LI', siglaProvv: 'M4', siglaProvvRichiesta: 'M4'},
  {nome: 'Lodi', sigla: 'LO', siglaProvv: 'B11', siglaProvvRichiesta: 'BB'},
  {nome: 'Lucca', sigla: 'LU', siglaProvv: 'M5', siglaProvvRichiesta: 'M5'},
  {nome: 'Macerata', sigla: 'MC', siglaProvv: 'O3', siglaProvvRichiesta: 'O3'},
  {nome: 'Mantova', sigla: 'MN', siglaProvv: 'B5', siglaProvvRichiesta: 'B5'},
  {nome: 'Massa', sigla: 'MS', siglaProvv: 'M6', siglaProvvRichiesta: 'M6'},
  {nome: 'Matera', sigla: 'MT', siglaProvv: 'S4', siglaProvvRichiesta: 'S4'},
  {nome: 'Messina', sigla: 'ME', siglaProvv: 'W5', siglaProvvRichiesta: 'W5'},
  {nome: 'Milano', sigla: 'MI', siglaProvv: 'B6', siglaProvvRichiesta: 'B6'},
  {nome: 'Modena', sigla: 'MO', siglaProvv: 'L4', siglaProvvRichiesta: 'L4'},
  {nome: 'Napoli', sigla: 'NA', siglaProvv: 'S5', siglaProvvRichiesta: 'S5'},
  {nome: 'Novara', sigla: 'NO', siglaProvv: 'A5', siglaProvvRichiesta: 'A5'},
  {nome: 'Nuoro', sigla: 'NU', siglaProvv: 'X2', siglaProvvRichiesta: 'X2'},
  {nome: 'Oristano', sigla: 'OR', siglaProvv: 'X4', siglaProvvRichiesta: 'X4'},
  {nome: 'Padova', sigla: 'PD', siglaProvv: 'D2', siglaProvvRichiesta: 'D2'},
  {nome: 'Palermo', sigla: 'PA', siglaProvv: 'W6', siglaProvvRichiesta: 'W6'},
  {nome: 'Parma', sigla: 'PR', siglaProvv: 'L5', siglaProvvRichiesta: 'L5'},
  {nome: 'Pavia', sigla: 'PV', siglaProvv: 'B7', siglaProvvRichiesta: 'B7'},
  {nome: 'Perugia', sigla: 'PG', siglaProvv: 'N1', siglaProvvRichiesta: 'N1'},
  {nome: 'Pesaro', sigla: 'PS', siglaProvv: 'O4', siglaProvvRichiesta: 'O4'},
  {nome: 'Pescara', sigla: 'PE', siglaProvv: 'R4', siglaProvvRichiesta: 'R4'},
  {nome: 'Piacenza', sigla: 'PC', siglaProvv: 'L6', siglaProvvRichiesta: 'L6'},
  {nome: 'Pisa', sigla: 'PI', siglaProvv: 'M7', siglaProvvRichiesta: 'M7'},
  {nome: 'Pistoia', sigla: 'PT', siglaProvv: 'M8', siglaProvvRichiesta: 'M8'},
  {nome: 'Pordenone', sigla: 'PN', siglaProvv: 'E3', siglaProvvRichiesta: 'E3'},
  {nome: 'Potenza', sigla: 'PZ', siglaProvv: 'S6', siglaProvvRichiesta: 'S6'},
  {nome: 'Prato', sigla: 'PO', siglaProvv: 'M10', siglaProvvRichiesta: 'MA'},
  {nome: 'Ragusa', sigla: 'RG', siglaProvv: 'W7', siglaProvvRichiesta: 'W7'},
  {nome: 'Ravenna', sigla: 'RA', siglaProvv: 'L7', siglaProvvRichiesta: 'L7'},
  {nome: 'Reggio', sigla: 'RC', siglaProvv: 'V3', siglaProvvRichiesta: 'V3'},
  {nome: 'Reggio Emilia', sigla: 'RE', siglaProvv: 'L8', siglaProvvRichiesta: 'L8'},
  {nome: 'Rieti', sigla: 'RI', siglaProvv: 'P3', siglaProvvRichiesta: 'P3'},
  {nome: 'Rimini', sigla: 'RN', siglaProvv: 'L9', siglaProvvRichiesta: 'L9'},
  {nome: 'Roma', sigla: 'RM', siglaProvv: 'P4', siglaProvvRichiesta: 'P4'},
  {nome: 'Rovigo', sigla: 'RO', siglaProvv: 'D3', siglaProvvRichiesta: 'D3'},
  {nome: 'Salerno', sigla: 'SA', siglaProvv: 'S7', siglaProvvRichiesta: 'S7'},
  {nome: 'Sassari', sigla: 'SS', siglaProvv: 'X3', siglaProvvRichiesta: 'X3'},
  {nome: 'Savona', sigla: 'SV', siglaProvv: 'H4', siglaProvvRichiesta: 'H4'},
  {nome: 'Siena', sigla: 'SI', siglaProvv: 'M9', siglaProvvRichiesta: 'M9'},
  {nome: 'Siracusa', sigla: 'SR', siglaProvv: 'W8', siglaProvvRichiesta: 'W8'},
  {nome: 'Sondrio', sigla: 'SO', siglaProvv: 'B8', siglaProvvRichiesta: 'B8'},
  {nome: 'Taranto', sigla: 'TA', siglaProvv: 'T5', siglaProvvRichiesta: 'T5'},
  {nome: 'Teramo', sigla: 'TE', siglaProvv: 'R5', siglaProvvRichiesta: 'R5'},
  {nome: 'Terni', sigla: 'TR', siglaProvv: 'N2', siglaProvvRichiesta: 'N2'},
  {nome: 'Torino', sigla: 'TO', siglaProvv: 'A6', siglaProvvRichiesta: 'A6'},
  {nome: 'Trapani', sigla: 'TP', siglaProvv: 'W9', siglaProvvRichiesta: 'W9'},
  {nome: 'Trento', sigla: 'TN', siglaProvv: 'C2', siglaProvvRichiesta: 'C2'},
  {nome: 'Treviso', sigla: 'TV', siglaProvv: 'D4', siglaProvvRichiesta: 'D4'},
  {nome: 'Trieste', sigla: 'TS', siglaProvv: 'E4', siglaProvvRichiesta: 'E4'},
  {nome: 'Udine', sigla: 'UD', siglaProvv: 'E2', siglaProvvRichiesta: 'E2'},
  {nome: 'Varese', sigla: 'VA', siglaProvv: 'B9', siglaProvvRichiesta: 'B9'},
  {nome: 'Venezia', sigla: 'VE', siglaProvv: 'D5', siglaProvvRichiesta: 'D5'},
  {nome: 'Verbania', sigla: 'VB', siglaProvv: 'A9', siglaProvvRichiesta: 'A9'},
  {nome: 'Vercelli', sigla: 'VC', siglaProvv: 'A7', siglaProvvRichiesta: 'A7'},
  {nome: 'Verona', sigla: 'VR', siglaProvv: 'D6', siglaProvvRichiesta: 'D6'},
  {nome: 'Vibo Valentia', sigla: 'VV', siglaProvv: 'V5', siglaProvvRichiesta: 'V5'},
  {nome: 'Vicenza', sigla: 'VI', siglaProvv: 'D7', siglaProvvRichiesta: 'D7'},
  {nome: 'Viterbo', sigla: 'VT', siglaProvv: 'P5', siglaProvvRichiesta: 'P5'}
];


export function FilterAniaTypesByPlate(plate: string): Set<string> {

  const filtered = new Set<string>();

  if (MayBeCarOrTruck(plate)) {
    filtered.add('A');
    filtered.add('C');
  }

  if (MayBeMotorbike(plate)) {
    filtered.add('M');
  }

  if (MayBeFarmingMachine(plate)) {
    filtered.add('S');
  }

  if (MayBeOperatingMachine(plate)) {
    filtered.add('T');
  }

  if (MayBeTow(plate)) {
    filtered.add('R');
  }

  return filtered;
}

function MayBeNewTow(plate: string): boolean {
  let toRet = true;
  if (new RegExp(RGX_CARS_TRUCKS_TOW).test(plate)) {
    // New Tow Plates are like the cars, but staring with X
    if (plate.indexOf('X') === 0) {
      toRet = true;
    }
  } else {
    toRet = false;
  }
  return toRet;
}

export function MayBeCarOrTruck(plate: string): boolean {
  let toRet = true;
  if (new RegExp(RGX_CARS_TRUCKS_TOW).test(plate)) {
    // Need to exclude new Tow Plates (X) and special plate Escursionisti Esteri(EE)
    if (plate.indexOf('X') === 0 || plate.indexOf('EE') === 0) {
      toRet = false;
    }
  } else {
    toRet = false;
  }
  return toRet;
}

export function MayBeMotorbike(plate: string): boolean {
  let toRet = true;
  if (new RegExp(RGX_MOTO_RIMORCHI).test(plate)) {
    const PARTE_LETTERALE: string = plate.substring(0, 2);
    PROVINCE.forEach((provincia: Provincia) => {
      if (provincia.sigla === PARTE_LETTERALE) {
        toRet = false;
      }
    });
  } else {
    toRet = false;
  }
  return toRet;
}

export function MayBeTow(plate: string): boolean {
  let toRet = true;
  if (!new RegExp(RGX_MOTO_RIMORCHI).test(plate)) {
    toRet = false;
  }
  return toRet || MayBeNewTow(plate);
}

export function MayBeFarmingTow(plate: string): boolean {
  return MayBeFarmingMachine(plate);
}

export function MayBeFarmingMachine(plate: string): boolean {
  let toRet = true;
  if (!new RegExp(RGX_FARMING_MACHINES).test(plate)) {
    toRet = false;
  }
  return toRet;
}

export function MayBeTowedMachine(plate: string): boolean {
  return MayBeOperatingMachine(plate);
}

export function MayBeOperatingMachine(plate: string): boolean {
  let toRet = true;
  if (!new RegExp(RGX_OPERATING_MACHINES).test(plate)) {
    toRet = false;
  }
  return toRet;
}

export function MayBeTemporary(plate: string): boolean {
  let toRet = true;
  if (new RegExp(RGX_TEMPORARY).test(plate)) {
    PROVINCE.forEach((provincia: Provincia) => {
      if (plate.indexOf(provincia.siglaProvv) === 0 || plate.indexOf(provincia.siglaProvvRichiesta) === 0) {
        toRet = true;
      }
    });
  } else {
    toRet = false;
  }
  return toRet;
}

export function hasTechnicalDataErrors(contract: ApiContract) {
  let technicalDataErrors: Array<GenericEntity> = [];
  if (contract.completenessErrors) {
    technicalDataErrors = contract.completenessErrors.filter((val: GenericEntity) => {
      return val.code === 'TD_NOT_FOUND';
    });
  }
  return technicalDataErrors.length > 0;
}

export function hasAssetNotFoundErrors(contract: ApiContract) {
  let assetNotFoundErrors: Array<GenericEntity> = [];
  if (contract.completenessErrors) {
    assetNotFoundErrors = contract.completenessErrors.filter((val: GenericEntity) => {
      return val.code === 'ASSET_NOT_FOUND';
    });
  }
  return assetNotFoundErrors.length > 0;
}

<pnc-datepicker
  [customFormGroup]="formGroup"
  customFormControlName="date"
  [label]="dateLabel"
  [required]="required"
  [minDate]="minLocaleDate"
  [maxDate]="maxLocaleDate"
  [disabled]="disabled"
  [id]="id"
  [attr.data-qa]="'factor-date-' + dateLabel"
  [attr.data-obb]="id + required">
</pnc-datepicker>

import { EnumType } from '@rgi/digital-claims-common-angular';

export class ElaborationRequest {

    public actionCode: string;
    public actionDescr: string;
    public actionTitle: string;
    public elabId: number;
    public queueOutcomeState: EnumType;
    public queueRerunRequest: boolean;
    public queueTCancellationDate: Date;
    public queueTElaborationDate: Date;
    public queueTElaborationRequestDate: Date;
    public queueTIns: Date;

    constructor(request) {
        this.actionCode = request.actionCode;
        this.actionDescr = request.actionDescr;
        this.actionTitle = request.actionTitle;
        this.elabId = request.elabId;
        this.queueRerunRequest = request.queueRerunRequest;
        this.queueTCancellationDate = request.queueTCancellationDate;
        this.queueTElaborationDate = request.queueTElaborationDate;
        this.queueTElaborationRequestDate = request.queueTElaborationRequestDate;
        this.queueTIns = request.queueTIns;

        if (request.queueOutcomeState !== null) {
            this.queueOutcomeState = {
                codice: request.queueOutcomeState.code,
                descrizione: request.queueOutcomeState.description
            };
        }
    }


}

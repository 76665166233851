<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
    <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
        <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
            (reloadRequest)="updateData()" [hide]="activeStep<1">
        </lpc-dates-step>
    </lpc-step>
    <lpc-step id="openAssignment" label="lpc_open_assignment" [errors]="errors" fieldId="openAssignment"
        (next)="onNext($event)" (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
        <div class="row" formGroupName="openAssignment">
            <div *ngIf="isActiveStep('openAssignment')">
                <div class="row">
                    <div *ngFor="let assignmentDate of assignmentDates; let i = index;" class="col-lg-6">
                        <lpc-datepicker [formControlName]="assignmentDate.code" [label]="assignmentDate.label"
                            [required]="assignmentDate.required" [id]="assignmentDate.code"
                            [hidden]="!isActiveStep('openAssignment')" [helpFile]="assignmentDate.helpFile">
                        </lpc-datepicker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" data-qa="assignment_holder_type">
                        <label class="label-core" translate>lpc_assignment_holder_type</label>
                        <lpc-required required="true"></lpc-required>
                        <ng-select [attr.data-qa]="'assignmentHolderType'" formControlName="assignmentHolderType">
                            <ng-option *ngFor="let type of assignmentHolderType" [value]="type.codice">
                                {{type.descrizione}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-6" data-qa="assignment_holder">
                        <label for="subject" translate class="label-core">lpc_assignment_holder</label>
                        <lpc-required required="true"></lpc-required><br>
                        <div *ngIf="!!formGroup.get('openAssignment').get('assignmentHolder').value; else empty">
                            <lpc-subject (delete)="deleteSbj($event)" formControlName="assignmentHolder"></lpc-subject>
                        </div>
                        <ng-template #empty>
                            <input (click)="openAnagSubjectModal()" [value]="'lpc_Seleziona' | translate"
                                class="col-lg-12 drop-container dashedSelect" data-qa="dashed-select-subject" readonly>
                        </ng-template>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label for="subject" translate class="label-core">lpc_assignment_policy_number</label>
                        <input type="number" class="form-control" data-qa="assignment-policy_number"
                            formControlName="policyNumber">
                    </div>
                    <div *ngIf="!!formGroup.get('openAssignment').get('policyNumber').value" class="col-md-6">
                        <label for="subject" translate class="label-core">lpc_assignment_company</label>
                        <lpc-required required="true"></lpc-required><br>
                        <ng-select [attr.data-qa]="'assignmentCompany'" formControlName="assignmentCompany">
                            <ng-option *ngFor="let company of companies" [value]="company.codice">
                                {{company.descrizione}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-6">
                        <label for="subject" translate class="label-core">lpc_assignment_type</label>
                        <lpc-required required="true"></lpc-required><br>
                        <ng-select [attr.data-qa]="'assignmentTypes'" formControlName="assignmentType">
                            <ng-option *ngFor="let type of assignmentTypes" [value]="type.codice">{{type.descrizione}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="showAmountAndBenefit()" class="col-md-6">
                        <label for="subject" translate class="label-core">lpc_assignment_amount</label>
                        <lpc-required required="true"></lpc-required><br>
                        <input class="form-control" data-qa="assignment-amount" formControlName="amount">
                    </div>
                </div>
                <div class="row">
                    <div [hidden]="!showAmountAndBenefit()" class="col-md-6">
                        <label for="subject" translate class="label-core">lpc_assignment_benefit</label>
                        <lpc-required required="true"></lpc-required><br>
                        <input class="form-control" data-qa="assignment-benefit" formControlName="benefit">
                    </div>
                </div>
            </div>
            <!-- SUMMARY -->
            <div *ngIf="!isActiveStep('openAssignment')">
                <div *ngFor="let assignmentDate of assignmentDates; let i = index;" class="col-lg-6">
                    <div *ngIf="showSummaryValue(assignmentDate.code)">
                        <span translate>{{assignmentDate.label}}</span> : {{formGroup.get('openAssignment').get(assignmentDate.code).value | date : 'dd/MM/yyyy'}}
                    </div>
                </div>
                <div class="col-lg-6" *ngIf="showSummaryValue('assignmentHolderType')">
                    <span translate>lpc_assignment_holder_type</span> : {{getAssignmentHolderTypeDescription(formGroup.get('openAssignment').get('assignmentHolderType').value)}}
                </div>
                <div class="col-lg-6" *ngIf="showSummaryValue('assignmentHolder')">
                    <span translate>lpc_assignment_holder</span> : {{formGroup.get('openAssignment').get('assignmentHolder')?.value?.name}}
                </div>
                <div class="col-lg-6" *ngIf="showSummaryValue('policyNumber')">
                    <span translate>lpc_assignment_policy_number</span> : {{formGroup.get('openAssignment').get('policyNumber')?.value}}
                </div>
                <div class="col-lg-6" *ngIf="showSummaryValue('assignmentCompany')">
                    <span translate>lpc_assignment_company</span> : {{getCompanyDescription(formGroup.get('openAssignment').get('assignmentCompany')?.value)}}
                </div>
                <div class="col-lg-6" *ngIf="showSummaryValue('assignmentType')">
                    <span translate>lpc_assignment_type</span> : {{getAssignmentTypeDescription(formGroup.get('openAssignment').get('assignmentType')?.value)}}
                </div>
                <div class="col-lg-6" *ngIf="showAmountAndBenefit() && showSummaryValue('amount')">
                    <span translate>lpc_assignment_amount</span> : {{formGroup.get('openAssignment').get('amount')?.value | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
                </div>
                <div class="col-lg-6" *ngIf="showAmountAndBenefit() && showSummaryValue('benefit')">
                    <span translate>lpc_assignment_benefit</span> : {{formGroup.get('openAssignment').get('benefit')?.value | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}
                </div>
            </div>
            <!-- END SUMMARY -->
        </div>
    </lpc-step>
    <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()"
        [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'" (confirmAccept)="publish(true)" [isConfirmAndAccept]="isConfirmAndAccept">
        <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    </lpc-step>

    <lpc-step label="lpc_confirm_variation_step" [errors]="errors" fieldId="documents" id="documents" [nextVisible]=false>

        <lpc-document-wrapper *ngIf="!!publishMessage" [contractId]="getContractId"
            [operationCode]="getOperationCode" [publishMessage]="publishMessage" [isAuth]="isAuth"
            [authorizationId]="authorizationId">
        </lpc-document-wrapper>

        <lpc-navigation-buttons id="last-step-buttons" (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'"
            [backVisible]="true" [nextVisible]="false">
        </lpc-navigation-buttons>

    </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
    <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

export enum CONTEXT_TYPE {
    CONTRACT = 'Contract',
    PARTY  = 'Party'
}


export enum ExtensionPropertyChExt {
  DERISK_SELECTED = 'ext_derisk_selected',
  PARTY_QUESTIONNAIRE = 'PARTY_QUESTIONNAIRE'
}

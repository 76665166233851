import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {GenericEntity} from '../../../models/domain-models/generic-entity';
import {Variable} from '../../../models/domain-models/variable';
import {UnitSection} from '../../../models/domain-models/unit-section';
import {Unit} from '../../../models/domain-models/unit';
import {VariableUtilityService} from '../../re-issue-utility/variable-utility.service';
import {ExtendedUnit} from '../../../models/domain-models/extended-unit';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 're-issue-variable-control',
  templateUrl: './re-issue-variable-control.component.html'
})
export class ReIssueVariableControlComponent implements OnInit, OnDestroy {
  // @Input() policyDataState: ReIssuePolicyDataState;
  @Input() variable: Variable;
  @Input() variableControlForm: UntypedFormGroup;
  @Input() disabled = false;
  @Input() unit: Unit;
  @Input() extendedUnit: ExtendedUnit;
  @Input() section: UnitSection;

  @Output() updateVariable: EventEmitter<any> = new EventEmitter<any>();

  initializationCompleted = true;
  variableCode: string;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private variableUtilityService: VariableUtilityService,
    public currencyPipe: CurrencyPipe
  ) {
  }

  ngOnInit() {
    if (this.extendedUnit) {
      this.variableCode = this.variableUtilityService.getVariableIdFromExtended(this.extendedUnit, this.variable);
    } else if (this.unit && this.section) {
      this.variableCode = this.variableUtilityService.getVariableId(this.section, this.unit, this.variable);
    } else {
      this.variableCode = this.variable.code;
    }

    if (this.variable.type === 4) {
      if (!this.variableControlForm.controls[this.variableCode].errors
        || (this.variableControlForm.controls[this.variableCode].errors
          && !this.variableControlForm.controls[this.variableCode].errors.required)) {
        this.variableControlForm.controls[this.variableCode].setErrors(null);
        this.variableControlForm.controls[this.variableCode].updateValueAndValidity();
        this.variableControlForm.updateValueAndValidity();
      }
      const variableValueChangesSubscription =
        this.variableControlForm.controls[this.variableCode].valueChanges.subscribe(
          (event) => {
            if (this.variable.type === 4) {
              const date: Date = event;
              if (date && date instanceof Date) {
                const year = date.getFullYear();
                if (year && year.toString().length === 4) {
                  this.onVariableValueChange();
                }
              } else {
                this.onVariableValueChange();
              }
            } else {
              this.onVariableValueChange();
            }
          }
        );
      this.subscriptions.add(variableValueChangesSubscription);
    }

    this.initializationCompleted = true;

  }

  isVariableDisabled(variable: Variable) {
    return !this.disabled && variable.editable ? null : true;
  }

  onVariableValueChange() {

    if (!this.initializationCompleted) {
      return;
    }

    const value = this.variableControlForm.controls[this.variableCode].value;

    // TODO in attesa che code scenda valorizzato dalla get

    this.variable.value = value;

    if (this.variable.type === 4) {

      const newDate: Date = value;
      if (!(value instanceof Date)) {
        this.variableControlForm.controls[this.variableCode].setErrors({invalidDate: true});
        return;
      }
      const newMonth = (String(Number(newDate.getMonth()) + 1).length === 1) ? '0'
        + String(Number(newDate.getMonth()) + 1) : String(Number(newDate.getMonth()) + 1);

      this.variable.value = newDate.getDate()
        + '/'
        + newMonth
        + '/' + newDate.getFullYear();

      if (!this.variableControlForm.controls[this.variableCode].errors
        || (this.variableControlForm.controls[this.variableCode].errors
          && !this.variableControlForm.controls[this.variableCode].errors.required)) {
        this.variableControlForm.controls[this.variableCode].updateValueAndValidity({emitEvent: false});
        this.variableControlForm.updateValueAndValidity({emitEvent: false});
        this.variableControlForm.controls[this.variableCode].setErrors(null);
        this.updateVariables();
      }
    } else if (this.variable.variableClass === 7) {
      this.variable.value = value.istatCode;
    } else if (this.variable.variableClass === 8) {
      this.variable.value = value.code;
    }
    if (this.variable.variableClass !== 8
      && this.variable.type !== 4) {
      this.variableControlForm.controls[this.variableCode].updateValueAndValidity();
      this.updateVariables();
    }
  }

  private updateVariables() {
    this.updateVariable.emit(this.variable);
  }

  genericEntitiesTrackByFn(genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showFormattedInput(variable: Variable): boolean {
    // TODO: uncomment when the formatting will ok
    // return variable.variablesValue && variable.variablesValue.length === 0 && this.isVariableDisabled(variable);
    return variable.values && variable.format && this.isVariableDisabled(variable);
    // return variable.value && variable.format !== null ? true : false;
    // return false;
  }

  showUnFormattedInput(variable: Variable): boolean {
    // TODO: uncomment when the formatting will ok
    // return variable.variablesValue && variable.variablesValue.length === 0 && !this.isVariableDisabled(variable);
    return variable.values &&
      variable.values.length === 0 &&
      !variable.format;
    // return variable.value && variable.variablesValue.length === 0 && variable.format === null ? true : false;
  }

  formattedVariableValue(variable: Variable): any {
    let result: any = '';
    const separatore = ',';
    result = variable.value;

    if (result && result !== '') {
      // result = variable.value;
      // if (variable.type === '1') {
      if (parseInt(result, 10) > 0) {
        result = '' + parseInt(result, 10);
      }
      if (parseInt(result, 10) > -1) {
        if (variable.format === '%1[.2g]') {
          result = (Math.round(result * 100) / 100).toFixed(2);
        } else if (variable.format === '%1[.2]') {
          result = (Math.round(result * 100) / 100).toFixed(2);
          result = result.split(separatore).join('');
        } else if (variable.format === '%1[4]') {
          result = result.split(separatore)[0];
        } else if (variable.format && variable.format.indexOf('.2g]') !== -1) {
          result = (Math.round(result * 100) / 100).toFixed(2);
        } else {
          result = (Math.round(result * 100) / 100).toFixed(0);
          result = result.split(separatore).join('');
        }
      }

      if (variable.format) {
        result = this.currencyPipe.transform(result, 'EUR', 'symbol', '1.2-2', 'it');
      }
      // }
    }

    return result;
  }

}

import {Directive, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {UserAuthorizationService} from './user-authorization.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[rgiRxAuthorized]'
})
export class AuthorizedDirective implements OnInit, OnDestroy {

  @Input('rgiRxAuthorized') acl: string[] = [];
  @Input('action') action: 'hidden' | 'disable' = 'hidden';
  private subscription: Subscription;

  constructor(private vc: ViewContainerRef, private userAuthorization: UserAuthorizationService, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.subscription = this.userAuthorization.isAuthorizedFor$(this.acl).subscribe(next => {
      switch (this.action) {
        case 'disable': {
          this.renderer.setAttribute(this.vc.element.nativeElement, 'disabled', '' + !next);
          break;
        }
        default: {
          if (this.vc.element.nativeElement.nodeType === 8) {
            this.vc.clear();
          } else {
            this.renderer.setStyle(this.vc.element.nativeElement, 'display', next ? '' : 'none');
            break;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}

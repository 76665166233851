import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const ES: RgiRxSerializedI18n = {
  CONTRACT_CONSULTATION_CARD_TITLE: 'Consulta de Contrato',

  CONTRACT_CONSULTATION: {
    NUMBER: 'Número',
    DESCRIPTION: 'Descripción',
    POLICYHOLDER: 'Tomador',
    EFFECTIVE_DATE: 'Fecha de vigencia',
    STATE: 'Estado',
    ANNUAL_PREMIUM_EXCEPT_RIGHTS: 'Prima anual (excluyendo derechos)',
    MANAGEMENT_NODE: 'Nodo de gestión',
    NODE_LIST: 'Lista de nodos',
    ISSUE_DATE: 'Fecha de emisión',
    CANCELLATION_DATE: 'Fecha de cancelación',
    PRODUCT: 'Producto',
    EXPIRE_DATE: 'Fecha de caducidad',
    CURRENT_MOVEMENT: 'Movimiento actual',
    CONTRACTS_LIST: 'Lista de contratos',
    SEARCH_RESULTS: 'Resultados de la búsqueda',
    BACK: 'ATRÁS',
    FIND: 'ENCONTRAR',
    EMPTY: 'VACìO',
    REPORT: 'INFORME',
    CONTRACT_BRANCH: 'Filial de contrato',
    TYPE: 'Tipo',
    SUBTYPE: 'Subtipo',
    LIMITATION: 'Limitación',
    POINT_OF_SALE: 'Punto de venta',
    SEARCH_UNDER_SUBNET: 'Buscar bajo subárbol',
    WHOLE_SALES_NETWORK: 'Buscar en toda la Red de Ventas',
    COMPANY: 'Compañía',
    INDEPENDENT_AGENT: 'Agente independiente',
    BRANCH_OFFICE: 'Branch Office',
    CONTRACT_TYPE: 'Tipo de contrato',
    PROPOSAL_NUMBER: 'Número de propuesta',
    POLICY_NUMBER: 'Número de póliza',
    PRODUCT_TYPE: 'Tipo de producto',
    CONTRACT_CONDITION: 'Condición de contrato',
    SUBJECT: 'Sujeto',
    SUBJECTS: 'Sujetos',
    ALL: 'Todos',
    PC: 'P&C',
    LIFE: 'Vida',
    INDIVIDUAL: 'Individual',
    COLLECTIVE: 'Collectivo',
    NOT_ANONYMIZED: 'No Anonimizada',
    ANONYMIZED: 'Anonimizada',
    MASTER_POLICY: 'Póliza matriz',
    APPLICATION: 'Solicitud',
    AT_PRESENT_DAY: 'A la fecha presente',
    LAST: 'Último',
    ACTIONS: 'ACCIÓN',
    CODE: 'Código',
    CANCEL: 'Anular',
    CLOSE: 'Cerca',
    POLICIES: 'ES_Polizze',
    PROPOSALS:'ES_Proposte',
    POLICIES_OR_PROPOSALS: 'ES_Polizze o proposte',
    QUOTE_W_REG_PARTY: 'ES_Preventivi anagrafati',
    ANONYMOUS_QUOTE: 'ES_Preventivi anonimi',
    QUOTE_NUMBER: 'ES_Numero preventivo',
    SYSTEM_MESSAGE: 'Mensaje del sistema',
    VALORIZE_NUMBER: 'Valorar número de propuesta, número de póliza o número de solicitud.',
    SELECT_NODE: 'SELECT NODE',
    FIELDS_MARKED_IN_RED: 'Los campos marcados en rojo son obligatorios o contienen valores incorrectos',
    NO_RESULTS: 'No hay resultados',
    NO_ACTIONS: 'No hay acciones disponibles para el contracto seleccionado.',
    WAIT: 'ES_Attendere la richiesta è stata mandata al server...',
    LABEL_ANONIMIZED: 'ES_I dati visualizzati sono stati anonimizzati, pertanto potrebbero essere privi di significato',
    SUBJECT_ROLE: 'ES_Ruolo soggetto',
    PLATE_NUMBER: 'Número de placa/chasis/motor',
    SHIP_NAME: 'Nombre de la embarcación/código OMI',
    ERROR_FORMAT_DATE: 'Error formato fecha',
    CONTRACT_STATES: 'Estados del contrato',
    CONTRACT_SUBSTATES: 'Subestados del contrato',
    ACTION: 'ES_Azioni',
    CREATE_COPY: 'ES_Crea Copia',
    CONTRACT_SUMMARY: 'ES_Riepilogo Contratto',
    NOMINATIVE: 'ES_Nominativo Contraente',
    FISCAL_CODE: 'ES_Codice Fiscale',
    TACIT_RENEWAL: 'ES_Tacito Rinnovo',
    FRACTIONATION: 'ES_Frazionamento',
    USER: 'Usuario',
    MOVEMENTS: 'Movimientos',
    YES: 'Yes',
    NO: 'No',
    NOTES: 'Notas',
    MIGRATED: 'ES_Migrated',
    ORIGINAL_EFFECTIVE_DATE: 'ES_Data Effetto Originale',
    EXPIRY_DATE: 'ES_Data Scadenza',
    COVERAGE_EXPIRY_DATE: 'ES_Data Scadenza Copertura',
    TERMINATION_DATE: 'ES_Data Rescindibilità',
    PROPOSAL_VALIDITY_END_DATE: 'ES_Data Fine Validità Proposta',
    SETTLEMENT_BLOCK_DATE: 'ES_Data Blocco Quietanze',
    MIGRATED_POLICY: 'ES_Contratto Migrato',
    AGREEMENT: 'ES_Convenzione',
    POLICY_DELIVERY_METHOD: 'ES_Modalità di Recapito Polizza',
    POLICY_DETAILS: 'ES_Dettaglio Polizza',
    ADMINISTRATIVE_DATA: 'ES_Dati Amministrativi',
    SIGN_INSTALMENT: 'ES_Rata Firma',
    NEXT_INSTALMENT: 'ES_Rata Successiva',
    ANNUAL_PREMIUM: 'ES_Premio Annuale',
    DISCOUNTS: 'ES_Sconti applicati',
    COMMISSIONS: 'ES_Provvigioni',
    PREMIUM: 'ES_Premio',
    CLAUSES:'ES_Clausole',
    FACTORS: 'Factores',
    NET: 'ES_Netto',
    ACCESSORIES: 'ES_Accessori',
    FRACTIONING_INTERESTS: 'ES_Interessi di Frazionamento',
    TAXABLE: 'ES_Imponibile',
    TAXES: 'ES_Tasse',
    GROSS: 'ES_Lordo',
    WARNINGS:'ES_Advertencias',
    WARNINGS_LIST: 'ES_Lista De Advertencia',
    BLOCKING_LEVEL: "Limite de bloqueo",
    ORIGINAL_ENTITY: "ES_Entidad Fuente",
    SUBSTITUTION_EFFECTIVE_DATE: 'ES_Substitution Effect Date',
    SUBSTITUTION_REASON: 'ES_Substitution Reason',
    SUBSTITUTIONS:'ES_Substitutions',
    SUBSTITUENT_POLICIES:'ES_Substituent Policies',
    SUBSTITUTED_POLICIES:'ES_Substituted Policies',
    SUBSTITUENT_PROPOSALS:'ES_Substituent Proposals',
    PROPOSAL_DETAILS:'ES_Dettaglio Proposta',
    TOTAL_GROSS: 'ES_Premio totale lordo',
  }
};

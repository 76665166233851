import { PolicyService } from './../../../services/policy-service';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lic-funds-table',
  templateUrl: './lic-funds-table.component.html',
  styleUrls: ['./lic-funds-table.component.css']
})
export class LicFundsTableComponent implements OnInit {

  @Input() toolData: any[];

  locale = 'it-IT';
  optionPercents: Intl.NumberFormatOptions = {style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 };

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
  }

  ngOnInit() {
  }


  public totalPercentSummary(profiles: any[]): number {
    return Number(profiles.reduce((subtotal, item) => subtotal + Number(item.percent), 0)
      .toString(10));
  }
}

import { Serialized18n } from '@rgi/rx/i18n';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_LIFE_PPEVO_IT: Serialized18n = {
 "Il Contraente è una persona": 'The holder is',
 "Data di nascita": 'Birth date',
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {AmendmentService} from './amendment.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Message} from '@rgi/portal-ng-core';
import {Observable, throwError} from 'rxjs';
import {ProposalService} from '../proposal.service';
import {StateService} from '../state.service';
import {RoutingService} from '../routing.service';
import {MicIntegrationService} from '../mic-integration.service';
import {VariationDates} from '../models/api-models/api-variation-dates';
import {catchError, finalize} from 'rxjs/operators';
import {Action} from '../models/action';
import {State} from '../models/state';
import {OperationDate} from '../models/domain-models/operation-date';
import {ApiContract} from '../models/api-models/api-contract';
import {Violation} from "../models/api-models/violation";

@Component({
  selector: 'mic-amendment',
  templateUrl: './amendment.component.html',
  styleUrls: ['./amendment.component.scss']
})
export class AmendmentComponent implements OnInit {

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  amendmentForm: UntypedFormGroup;
  policyNumber: string;
  operationCode: string;
  validationMessages: Message[] = [];
  areaCode = 'AMENDMENT';
  resourceId: string;
  operationDates: VariationDates;
  operationType: string;

  constructor(
    protected amendmentService: AmendmentService,
    protected formBuilder: UntypedFormBuilder,
    protected proposalService: ProposalService,
    protected stateService: StateService,
    protected routingService: RoutingService,
    protected integrationService: MicIntegrationService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.amendmentService.isAmendment = true;
    this.policyNumber = this.amendmentService.flowOriginPolicyNumber;
    this.operationCode = this.amendmentService.flowOriginOperation.code;
    this.operationType = this.amendmentService.flowOriginAction.type;

    this.stateService.setCurrentState(State.AMENDMENT_DATES);
    this.routingService.setPreviousState(State.AMENDMENT_DATES);

    this.eventPropagation.emit({
      eventName: 'setTitle',
      title: this.amendmentService.flowOriginOperation.description
    });
    this.init();

    this.amendmentForm = this.formBuilder.group({
      effectDate: [null, { validators: Validators.required, updateOn: 'blur' }],
      operationDate: [{ value: null, disabled: true }, { validators: Validators.required, updateOn: 'blur' }],
      effectHour: [{ value: null, disabled: false}]
    });

  }

  protected init() {
    this.amendmentService.preliminaryCheck(this.policyNumber, this.operationType, this.operationCode)
      .pipe(catchError((err: any) => {
        if (err.status < 500) {
          if (err.error && err.error.violations && Array.isArray(err.error.violations)) {
            this.pushIntoValidationMessages(err.error.violations);
          } else {
            return throwError(err);
          }
        }
      }))
      .subscribe(res => {
        this.resourceId = res.resourceId;
        this.amendmentService.resourceId = this.resourceId;
        this.setDates();
      });
  }

  setDates() {
    this.amendmentService.getPolicyDates(this.policyNumber, this.resourceId, this.operationType, this.operationCode)
      .subscribe(res => {
        this.operationDates = res;
        const effectDate = this.amendmentForm.get('effectDate');
        if (res.effectDate && res.effectDate.value) {
          effectDate.setValue(new Date(res.effectDate.value));
        } else {
          this.operationDates.effectDate = new OperationDate(null, null, null);
        }
        if(!this.operationDates.effectDate.editable){
          effectDate.disable();
        }
        const operationDate = this.amendmentForm.get('operationDate');
        operationDate.setValue(new Date(res.issueDate.value));
        if(!this.operationDates.issueDate.editable){
          operationDate.disable();
        }
        this.subscribeToEffectDate();
        const effectHour = this.amendmentForm.get('effectHour');
        if(effectHour.value == null) {
          effectHour.setValue(this.operationDates.effectHour.value);
          if(!this.operationDates.effectHour.editable){
            effectHour.disable();
          }
        }
      });
  }

  subscribeToEffectDate() {
    this.amendmentForm.get('effectDate').valueChanges.subscribe(value => {
      this.validateForm();
      if (this.validationMessages.length === 0) {
        const datePickerValue = new Date(value);
        const datePickerFormattedValue = this.datepipe.transform(datePickerValue, 'yyyy-MM-dd');
        this.operationDates.effectDate.value = datePickerFormattedValue;
        this.amendmentService.setPolicyDates(this.policyNumber, this.resourceId, this.operationType, this.operationCode, this.operationDates)
          .pipe(catchError((err: any) => {
            if (err.status < 500) {
              if (err.error && err.error.violations && Array.isArray(err.error.violations)) {
                this.pushIntoValidationMessages(err.error.violations);
              } else {
                return throwError(err);
              }
            }
          }))
          .subscribe(res => {
            this.amendmentService.getPolicyDates(this.policyNumber, this.resourceId, this.operationType, this.operationCode)
              .subscribe(res => {
                const effectHour = this.amendmentForm.get('effectHour');
                effectHour.setValue(res.effectHour.value);
                if(!res.effectHour.editable) {
                  effectHour.disable();
                }
              });
          });
      }
    });
  }

  goBack() {
    this.proposalService.refresh();
    this.stateService.resetState();
    this.eventPropagation.emit({
      eventName: 'backToContractDetail',
    });
  }

  onSubmit() {
    this.validateForm();
    if (this.validationMessages.length === 0) {
      this.amendmentService.setPolicyDates(this.policyNumber, this.resourceId, this.operationType, this.operationCode, this.operationDates)
        .pipe(catchError((err: any) => {
          if (err.status < 500) {
            if (err.error && err.error.violations && Array.isArray(err.error.violations)) {
              this.pushIntoValidationMessages(err.error.violations);
            } else {
              return throwError(err);
            }
          }
        }))
        .subscribe(res => {
          this.continueFlow();
        });
    }
  }

  validateForm() {
    this.validationMessages.length = 0;
    const controls = this.amendmentForm.controls;
    if (controls.effectDate.errors && controls.effectDate.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Effective Date is mandatory'));
    }
    this.eventPropagation.emit('layoutChanged');
  }

  pushIntoValidationMessages(messages: Array<Violation>) {
    this.validationMessages.length = 0;
    messages.forEach(msg => {
      this.validationMessages.push(new Message(this.areaCode, msg.message));
    });
  }

  continueFlow() {
    const apiContract = new ApiContract();
    apiContract.id = this.resourceId;
    this.proposalService.getContractInCache(apiContract)
      .pipe(finalize(() => {
        this.setDisabledFields();
      }))
      .subscribe(loadedContract => {
        this.proposalService.setLoadedProposal(loadedContract, true);
      });
  }

  setDisabledFields() {
    const disabledFields = this.proposalService.getContractDisabledFields();
    if (disabledFields instanceof Observable) {
      disabledFields.pipe(finalize(() => {
        this.stateService.nextState(Action.NEXT_BUTTON_PRESSED);
      }))
        .subscribe(data => {
          this.proposalService.setDisabledFields(data);
        });
    } else {
      this.stateService.nextState(Action.NEXT_BUTTON_PRESSED);
    }
  }
}

<!-- Box -->

<div *ngIf="viewUnitsBox" class="panel-body nmf_cont_viewbox">
  <div *ngFor="let unitsBoxesRow of unitsBoxesRows" class="d-flex flex-wrap">
    <!--SECTION UNIT-->
    <div
      *ngFor="let unit of unitsBoxesRow; index as i; trackBy: unitsTrackByFn"
      [ngClass]="[
        'nmf-unit-box-section-div',
        'd-flex flex-wrap',
        'col-12',
        'col-sm-6',
        'col-lg-3',
        'order-' + (1 + 3 * i),
        'pt-2',
        'pb-0',
        'px-3',
        'm-0',
        (i + 1) % 2 == 0
          ? 'order-sm-' + (((i - 1) / 2) * 6 + 1 + 1)
          : 'order-sm-' + ((i / 2) * 6 + 1),
        'order-lg-' + (i + 1)
      ]"
    >
      <div
        *ngIf="
          unit?.code ===
            firstUnitInSectionMap.get(
              riskToSectionMap?.get(unit?.risk?.id)?.code
            ) && sectionsPremium
        "
        class="col-auto nmf-unit-box-section-cont_title d-flex align-items-center"
      >
        <span
          *ngIf="
            unit?.code ===
            firstUnitInSectionMap.get(
              riskToSectionMap?.get(unit?.risk?.id)?.code
            )
          "
          class="nmf-unit-box-section-title"
        >{{ riskToSectionMap?.get(unit?.risk?.id)?.description }}:
        </span>
        <span
          *ngIf="
            unit?.code ===
              firstUnitInSectionMap.get(
                riskToSectionMap?.get(unit?.risk?.id)?.code
              ) && sectionsPremium
          "
          class="nmf-unit-box-section-premium px-1"
        >{{
          sectionsPremium[(riskToSectionMap?.get(unit?.risk?.id)?.code)]
            .premium.instalment.gross | currency: "EUR":"symbol":"1.2-2":"it"
          }}</span
        >
        <span
          *ngIf="
            unit?.code !==
            firstUnitInSectionMap.get(
              riskToSectionMap?.get(unit?.risk?.id)?.code
            )
          "
          class="nmf-unit-box-divider"
        ></span>
      </div>
      <div
        [ngClass]="[
          'd-flex',
          'flex-column',
          'justify-content-center',
          'nmf-unit-box-section-div-line-container',
          'px-0',
          unit?.code ===
          firstUnitInSectionMap.get(riskToSectionMap?.get(unit?.risk?.id)?.code)
            ? 'col'
            : 'col-12'
        ]"
      >
        <div class="nmf-unit-box-section-div-line-top"></div>
        <div class="nmf-unit-box-section-div-line-botton"></div>
      </div>
    </div>

    <div
      *ngFor="let i of calculateEmptyRow(unitsBoxesRow)"
      [ngClass]="[
        'nmf-unit-box-section-div',
        'd-flex flex-wrap',
        'col-12',
        'col-sm-6',
        'col-lg-3',
        'order-' + (1 + 3 * i),
        'pt-2',
        'pb-0',
        'px-0',
        'm-0',
        (i + 1) % 2 == 0
          ? 'order-sm-' + (((i - 1) / 2) * 6 + 1 + 1)
          : 'order-sm-' + ((i / 2) * 6 + 1),
        'order-lg-' + (i + 1)
      ]"
    ></div>

    <!---UNIT-->
    <div
      *ngFor="let unit of unitsBoxesRow; index as i; trackBy: unitsTrackByFn"
      [ngClass]="[
        'unit-box-container',
        'col-12',
        'col-sm-6',
        'col-lg-3',
        'order-' + (2 + 3 * i),
        (i + 1) % 2 == 0
          ? 'order-sm-' + (3 + ((i - 1) / 2) * 6 + 1)
          : 'order-sm-' + (3 + (i / 2) * 6),
        'order-lg-' + (5 + i)
      ]"
    >
      <div
        [ngClass]="{
          'nmf-unit-box-active': unit?.selected && !unit?.open,
          'nmf-unit-box-expanded': unit?.selected && unit?.open,
          'nmf-unit-box-not-selected-open': !unit?.selected && unit?.open
        }"
        class="d-flex flex-column justify-content-between unit-box mx-2 my-0"
      >
        <div
          class="unit-box-heading d-flex flex-column align-items-stretch px-3 pt-3 flex-grow-1"
        >
          <button
            (click)="selectUnit(unit); updateErrorMessageVariable()"
            [disabled]="!unit.enabled"
            class="btn btn-link btn-block nmf-unit-box-heading_button d-flex flex-wrap mx-0"
            type="button"
          >
            <div class="nmf-unit-box-heading_button_sx text-wrap col-9">
              {{
              unit?.extendedDescription
                ? unit?.extendedDescription
                : unit?.description
              }}
            </div>
            <div
              class="nmf-unit-box-heading_button_dx col-3 d-flex justify-content-end"
            >
              <span
                class="btn btn-checkbox checkbox-unit nmf-unit-box-checkbox"
                type="checkbox"
              >
                <span
                  [ngClass]="{ 'glyphicon-ok': unit?.selected && unit?.enabled, 'glyphicon-lock': unit?.selected && !unit?.enabled}"
                  class="glyphicon"
                >
                </span>
              </span>
            </div>
          </button>
          <div *ngIf="sectionsPremium" class="nmf-unit-premium">
            {{
            sectionsPremium[(riskToSectionMap?.get(unit?.risk?.id)?.code)]
              .unitsPremium[
              unit?.code?.includes("_")
                ? unit?.code
                : unit?.code + "_" + unit?.warrantyCode
              ]?.instalment?.gross | currency: "EUR":"symbol":"1.2-2":"it"
            }}
          </div>
        </div>
        <div class="unit-box-toggle px-3 pb-3" (click)="toggleVariables(unit)">
          <a role = "button">
            <span
              [ngClass]="{
                'rgi-chevron-down': !unit?.open,
                'rgi-chevron-up': unit?.open
              }"
              class="rgifont"
            ></span>
          </a>
        </div>
      </div>
    </div>

    <div
      *ngFor="let number of calculateEmptyRow(unitsBoxesRow)"
      [ngClass]="[
        'unit-box-container',
        'col-12',
        'col-sm-6',
        'col-lg-3',
        'order-' + (2 + 3 * number),
        (number + 1) % 2 == 0
          ? 'order-sm-' + (3 + ((number - 1) / 2) * 6 + 1)
          : 'order-sm-' + (3 + (number / 2) * 6),
        'order-lg-' + (5 + number)
      ]"
    ></div>

    <!--DETAILS-->
    <div
      *ngFor="let unit of unitsBoxesRow; index as i; trackBy: unitsTrackByFn"
      [ngClass]="[
        'col-12',
        'px-3',
        'unit-box-details',
        'order-' + (3 + 3 * i),
        (i + 1) % 2 == 0
          ? 'order-sm-' + (5 + ((i - 1) / 2) * 6 + 1)
          : 'order-sm-' + (5 + (i / 2) * 6),
        'order-lg-' + (9 + i)
      ]"
    >
      <div
        *ngIf="
          unit?.open &&
          (unit?.variables?.length > 0 || unit?.risk?.clauses?.length > 0)
        "
      >
        <div
          [ngClass]="{
            'nmf-unit-border-exp': unit?.open && !unit?.selected,
            'nmf-unit-border': unit?.selected
          }"
          class="panel-body d-flex flex-wrap"
          id="{{ unit?.risk?.code }}"
        >
          <div
            *ngFor="
              let variable of unit?.variables;
              index as i;
              trackBy: variablesTrackByFn
            "
            class="col-12 col-md-auto col-lg-auto"
            style="min-width: 50%; padding: 10px;"
          >
            <mic-variable-control
              (updateVariable)="validateVariables($event)"
              [variableControlForm]="guaranteesForm"
              [variable]="variable"
            >
            </mic-variable-control>
          </div>
          <mic-clause
            (clauseChange)="changeClauses($event)"
            (validationMessagesClause)="verifyClauses($event, unit)"
            [clauseList]="unit?.risk?.clauses"
            [isUnitEnabled]="unit?.selected"
            [viewError]="true"
            [risk]="unit?.risk"
            (eventPropagation)="eventPropagation.emit($event)"
            (updateUnit)="updateUnitClause($event)"
            class="col-12"
          >
          </mic-clause>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- List -->

<div *ngIf="!viewUnitsBox">
  <form class="nmf_cont_moredetails form-group">
    <div *ngFor="let section of unitSections; let i = index">
      <div class="section-container">
        <span class="section-name">
          <span
            (click)="section.open = !section.open"
            [attr.aria-controls]="section?.code"
            [attr.aria-expanded]="!section?.open"
            [class.rgi-minus]="section?.open"
            [class.rgi-plus]="!section?.open"
            class="icon rgifont"
          >
          </span>
          <span class="section-descr">
            {{ section?.extendedDescription || section?.description }}
          </span>
        </span>

        <span *ngIf="sectionsPremium" class="price price-section">{{
          sectionsPremium[(section?.code)]?.premium?.instalment?.gross
            | currency: "EUR":"symbol":"1.2-2":"it"
          }}</span>
      </div>

      <div
        *ngFor="let unit of section?.unitList; let i = index"
        [ngbCollapse]="!section?.open"
        id="{{ section?.code }}"
      >
        <!--  INIZIO UNIT container -->
        <div [ngClass]="{ selected: unit?.selected }" class="unit-container">
          <span
            (click)="unit.open = !unit.open"
            [attr.aria-controls]="unit?.risk?.code"
            [attr.aria-expanded]="!unit?.open"
            [class.rgi-minus]="unit?.open"
            [class.rgi-plus]="!unit?.open"
            class="icon rgifont"
          ></span>

          <span
            (click)="unit.enabled && onSelectedUnit(unit)"
            [attr.disabled]="disabledUnit(unit)"
            class="btn btn-checkbox checkbox-unit"
            type="checkbox"
          >
            <span
              [ngClass]="{ 'glyphicon-ok': unit?.selected }"
              class="glyphicon"
              style="color: #f68020;"
            >
            </span>
          </span>
          <b
            (click)="unit.open = !unit.open"
            [attr.aria-controls]="unit?.risk?.code"
            [attr.aria-expanded]="!unit?.open"
            [ngClass]="{
              selected: unit?.selected,
              'unit-name': !unit?.selected
            }"
            style="font-size: 18px; padding-left: 10px;"
          >
            {{
            unit?.extendedDescription
              ? unit?.extendedDescription
              : unit?.description
            }}
          </b>

          <span class="price price-unit">
            <span
              *ngIf="
                sectionsPremium &&
                sectionsPremium[(section?.code)] &&
                sectionsPremium[(section?.code)].unitsPremium &&
                sectionsPremium[(section?.code)].unitsPremium[
                  unit?.code + '_' + unit?.warrantyCode
                ]
              "
              [ngClass]="{ selected: unit?.selected }"
            >
              {{
              sectionsPremium[(section?.code)].unitsPremium[
              unit?.code + "_" + unit?.warrantyCode
                ]?.instalment?.gross | currency: "EUR":"symbol":"1.2-2":"it"
              }}
            </span>
          </span>
        </div>

        <!--  INIZIO UNIT container -->
        <div
          *ngIf="unit?.variables?.length > 0 || unit?.risk?.clauses?.length > 0"
        >
          <div
            [ngbCollapse]="!unit?.open"
            class="panel-body unit-border"
            id="{{ unit?.risk?.code }}"
          >
            <div
              *ngFor="
                let variable of unit?.variables;
                index as i;
                trackBy: variablesTrackByFn
              "
              style="display: inline-block; width: 50%; padding: 10px;"
            >
              <mic-variable-control
                (updateVariable)="validateVariables($event)"
                [variableControlForm]="guaranteesForm"
                [variable]="variable"
              >
              </mic-variable-control>
            </div>
            <mic-clause
              (clauseChange)="changeClauses($event)"
              (validationMessagesClause)="verifyClauses($event, unit)"
              [clauseList]="unit?.risk?.clauses"
              [isUnitEnabled]="unit?.selected"
              [viewError]="false"
              [risk]="unit?.risk"
              (eventPropagation)="eventPropagation.emit($event)"
              (updateUnit)="updateUnitClause($event)"
            ></mic-clause>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

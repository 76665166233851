import { LpcErrorMessagesModule } from '../lpc-error-messages/lpc-error-messages.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PolicyContactComponent} from './components/policy-contact/policy-contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddressComponent} from './components/address/address.component';
import {AddressPipe} from './pipe/address.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddContactComponent } from './components/add-contact/add-contact.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LoaderMobileModule } from '../lpc-loader-mobile/loader-mobile.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { RgiRxAutoCompleteModule,RgiRxFormElementsModule } from '@rgi/rx/ui';


@NgModule({
    declarations: [PolicyContactComponent, AddressComponent, AddressPipe, AddContactComponent],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        FormsModule,
        ReactiveFormsModule,
        LpcErrorMessagesModule,
        NgSelectModule,
        LoaderMobileModule,
        RgiRxI18nModule,
        LpcRequiredModule,
        RgiRxAutoCompleteModule,
        RgiRxFormElementsModule
    ],
    exports: [PolicyContactComponent, AddressComponent, AddressPipe, AddContactComponent]
})
export class LpcPolicyContactModule { }

import { Inject, Injectable, Optional } from "@angular/core";
import { QuestionnaireFlatI } from '@rgi/ng-passpro/lib/passpro/questionnaire/models/questionnaireFlat';
import { ResponseResult } from '@rgi/ng-passpropro-core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvaluatorProductI } from '../../evaluation/models/evaluator-products';
import { SurveyIntegrationFilter } from '../../integration/integration.module';
import { QuestionnaireI } from '../../models/questionnaire';
import { QuestionnaireTypeI } from '../../models/questionnaire-type';
import { SurveyVersionI } from '../../models/survey-version';
import { NG_PASSPROPRO_SURVEY_CONFIG, SurveyConfig } from '../../ng-passpropro-survey-api';
import { PASSPROPRO_SURVEY_CUSTOM_PROPERTIES } from '../../survey/constants/survey.constants';
import { SurveyBackEventInstance, SurveyEvaluationBackInstance, SurveyEvaluationErrorEventInstance, SurveySaveErrorEventInstance, SurveySaveEventInstance, SurveySelectProductEvaluatedInstance } from '../../survey/model/survey-lifecycle';
import { SurveyPrintFactory } from '../../survey/survey.print.factory';
import { SurveyService } from '../../survey/survey.service';
import { EvaluatedItem, EvaluationResourceDefinition } from '@rgi/ng-passpropro';
import {QuestionnaireService} from "@rgi/ng-passpropro/questionnaire";
import {Source} from "@rgi/ng-passpropro/product";
import {EventService} from "@rgi/rx";
import { UserAuthorizationService } from "@rgi/rx/auth";
import { SURVEY_ACL } from "../../survey.constants";
import { SurveyAcl } from "../models/survey-acl";


/**
 * common operation stateless
 */
@Injectable()
export class SurveyCommonOperations {

    constructor(
        @Inject(NG_PASSPROPRO_SURVEY_CONFIG) @Optional() private _config: SurveyConfig | undefined,
        private _eventService: EventService,
        private _surveyService: SurveyService,
        private _questionnaireService: QuestionnaireService,
        private _userAuth: UserAuthorizationService
    ) {
    }


    createAcl():SurveyAcl{
        return {
            write : this._userAuth.isAuthorizedFor(SURVEY_ACL.WRITE),
            read : this._userAuth.isAuthorizedFor(SURVEY_ACL.READ),
            newVersion : this._userAuth.isAuthorizedFor(SURVEY_ACL.NEW_VERSION),
            evaluate : this._userAuth.isAuthorizedFor(SURVEY_ACL.EVALUATE)
        }

    }


    isEphemeral(): boolean {
        return !!(this._config && this._config.evaluation.ephemeralEvaluation);
    }


    isSurveyPersisted(surveyVersion: SurveyVersionI): boolean {
        return !!(surveyVersion.uuid && surveyVersion.uuid !== '');
    }


    notifyProductsSelect(activeRouteId: string, products: EvaluatorProductI[], surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter, forwardData?: any) {
        this._eventService.emit(
            new SurveySelectProductEvaluatedInstance(
                activeRouteId,
                products,
                surveyVersion,
                integrationFilter && integrationFilter.subject,
                forwardData
            )
        );
    }


    notifySurveyBack(activeRouteId: string){
        this._eventService.emit( new SurveyBackEventInstance(activeRouteId) );
    }


    notifySurveyEvaluationBack(activeRouteId: string, askConfirm: boolean){
        this._eventService.emit( new SurveyEvaluationBackInstance(activeRouteId, askConfirm) );
    }


    createSurvey$(activeRouteId: string, routeOptions:any, surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter):Observable<SurveyVersionI> {
        this.prepareDataForSurveySave(surveyVersion, integrationFilter);
        return combineLatest(of(surveyVersion), of(integrationFilter), this._surveyService.createSurvey(surveyVersion)).pipe(
            map( ([surveyVer, integrationFilter, response]) => {
                if(response.isSuccess){
                    this.notifySaveSuccess(activeRouteId, routeOptions, response.result, integrationFilter);
                    return response.result;
                }
                this.notifySaveError(surveyVer, integrationFilter, this.getErrorByResponseResult(response, "error save survey"));
                throw "error save survey";
            }),
        );
    }


    updateSurvey$(activeRouteId: string, routeOptions:any, surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter):Observable<SurveyVersionI> {
        this.prepareDataForSurveySave(surveyVersion, integrationFilter);
        return combineLatest(of(surveyVersion), of(integrationFilter), this._surveyService.updateSurvey(surveyVersion)).pipe(
            map( ([surveyVer, integrationFilter, response]) => {
                if(response.isSuccess){
                    this.notifySaveSuccess(activeRouteId, routeOptions, response.result, integrationFilter);
                    return response.result;
                }
                this.notifySaveError(surveyVer, integrationFilter, this.getErrorByResponseResult(response, "error update survey"));
                throw "error update survey";
            }),
        );
    }


    createNewVersion$(activeRouteId:string, routeOptions:any, surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter):Observable<SurveyVersionI> {
        this.prepareDataForSurveySave(surveyVersion, integrationFilter);
        return combineLatest(of(surveyVersion), of(integrationFilter), this._surveyService.createNewVersion(surveyVersion)).pipe(
            map( ([surveyVer, integrationFilter, response]) => {
                if(response.isSuccess){
                    this.notifySaveSuccess(activeRouteId, routeOptions, response.result, integrationFilter);
                    return response.result;
                }
                this.notifySaveError(surveyVer, integrationFilter, this.getErrorByResponseResult(response, "error create new survey version"));
                throw "error create new survey version";
            }),
        );
    }


    isSurveyValid(surveyVersion: SurveyVersionI):boolean{
        return this._surveyService.isSurveyVersionValid(surveyVersion);
    }


    evaluate$(routeId:string, surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter):Observable<EvaluatorProductI[]>{
        const filter = this._surveyService.createSurveyEvaluationFilter(surveyVersion, integrationFilter);
        const data = { surveyVersion, integrationFilter, routeId };
        const combine$ = combineLatest(of(data), this._surveyService.evaluateSurvey(filter));
        return combine$.pipe(
            map(
                ([d, response]) => {
                    if(!response.isSuccess){
                        const error = this.getErrorByResponseResult(response, "error evaluate");
                        this.notifyEvaluationError(d.surveyVersion, d.routeId, d.integrationFilter, false, error);
                        throw "error evaluate";
                    }
                    return response.result;
                }
            )
        );
    }


    evaluateEphemeral$(routeId:string, surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter):Observable<EvaluatorProductI[]>{
        const filter = this._surveyService.createSurveyEvaluationFilter(surveyVersion, integrationFilter);
        const data = { surveyVersion, integrationFilter, routeId };
        const combine$ = combineLatest(of(data), this._surveyService.evaluateEphemeral(filter));
        return combine$.pipe(
            map(
                ([d, response]) => {
                    if(!response.isSuccess){
                        const error = this.getErrorByResponseResult(response, "error evaluate ephemeral");
                        this.notifyEvaluationError(d.surveyVersion, d.routeId, d.integrationFilter, true, error);
                        throw "error evaluate ephemeral";
                    }
                    return response.result;
                }
            )
        );
    }


    loadEvaluation$(surveyVersion: SurveyVersionI):Observable<EvaluatorProductI[]>{
        return this._surveyService.getSurveyEvaluation(surveyVersion).pipe(
            map(
                resp => {
                    if(resp=='NO_EVALUATION'){
                      return null;
                    }else if(resp instanceof Object){
                      const result:EvaluatorProductI[] = resp.result;
                      return result;
                    }
                  }
            )
        )
    }


    updateEvaluation$(surveyVersion:SurveyVersionI, products:EvaluatorProductI[]):Observable<[EvaluatorProductI[], EvaluatedItem[]]>{
        return this._surveyService.updateEvaluation(surveyVersion, products);
    }


    updateSurveyInputFactor(surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter, questionnaireFlat: QuestionnaireFlatI, isCreate:boolean):boolean {
        if (integrationFilter && integrationFilter.inputFactors && questionnaireFlat) {
            const updatedQuestionAnswers = this._surveyService.getQuestionsWithInputFactorsValueUpdated(surveyVersion, integrationFilter.inputFactors);
            const questionsByFactors = this._surveyService.getQuestionsByFactors(surveyVersion, integrationFilter.inputFactors);
            integrationFilter.lockedQuestions = questionsByFactors.map(q => {
                if (q.code.indexOf(".") > -1) {
                    return { code: q.code.split(".")[1] }
                }
                return { code: q.code };
            });
            const updated = updatedQuestionAnswers.length > 0;
            if (updated || isCreate) {
                this._surveyService.setInputFactors(surveyVersion, integrationFilter.inputFactors);
            }
            return updated;
        }
        return false;
    }


    hasInputFactorChanged(surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter):boolean{
        if (integrationFilter && integrationFilter.inputFactors) {
            const questFlat = this._surveyService.getQuestionsWithInputFactorsValueUpdated(surveyVersion, integrationFilter.inputFactors);
            return questFlat.length > 0;
        }
        return false;
    }


    createVoidQuestionnaire(): QuestionnaireI {
        return {
            questionnaireType: {} as QuestionnaireTypeI
        } as QuestionnaireI;
    }


    createVoidQuestionnaireFlat(): QuestionnaireFlatI {
        return null;
    }


    getQuestFlatByQuest$ = (questionnaire: QuestionnaireI): Observable<QuestionnaireFlatI> => {
        return this._questionnaireService.getQuestionnaireFromPASSPRO(questionnaire.code);
    };


    getQuestionnaires$(integrationFilter: SurveyIntegrationFilter):Observable<QuestionnaireI[]>{

        let service$;

        const sources:Source[] = (integrationFilter && Array.isArray(integrationFilter.sources) && integrationFilter.sources) || [];
        //TODO filtrare tramite servizio rest
        const questionnaireFilter:string[] = (integrationFilter && Array.isArray(integrationFilter.questionnaires) && integrationFilter.questionnaires) || [];
        const resources: EvaluationResourceDefinition[] = (integrationFilter && Array.isArray(integrationFilter.resources) && integrationFilter.resources) || [];

        if( resources.length ){
            service$ = this._questionnaireService.getQuestionnaireByResources(resources, sources);
        }else if(sources.length){
            service$ = this._questionnaireService.getQuestionnairesBySources(sources);
        }else {
            service$ = this._questionnaireService.getQuestionnaires();
        }

        if(questionnaireFilter.length){
            const getFiltered = ([filter, questionnaireList]: [string[], QuestionnaireI[]]): QuestionnaireI[] =>
                questionnaireList.filter(quest => filter.some(filterCode => quest.code === filterCode));

            service$ = combineLatest(of(questionnaireFilter), service$).pipe( map(getFiltered) );
        }

        return service$;
    }


    mergeQuestFlat$(questFlat: QuestionnaireFlatI): Observable<QuestionnaireFlatI> {
        return this._questionnaireService.mergeQuestionnaireFromPASSPRO(questFlat);
    }


    print$(surveyVersion: SurveyVersionI):Observable<Blob>{
        return this._surveyService.getPrint(surveyVersion);
    }


    validateEphemeral$(products: EvaluatorProductI[], surveyVersion: SurveyVersionI):Observable<[EvaluatorProductI[], EvaluatedItem[]]>{
        return this._surveyService.validateEphemeral(products, surveyVersion)
    }


    protected notifySaveSuccess(activeRouteId: string, routeOptions:any, surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter) {

        this._eventService.emit(new SurveySaveEventInstance(
            activeRouteId,
            routeOptions,
            surveyVersion,
            integrationFilter ? integrationFilter.subject : null,
            surveyVersion.survey.questionnaire ? surveyVersion.survey.questionnaire.code : null
            , 1,
            integrationFilter ? integrationFilter.userCode : null)
        );
    }


    protected notifySaveError(surveyVersion: SurveyVersionI, integrationFilter: SurveyIntegrationFilter, error:string[]|string){
        this._eventService.emit(
            new SurveySaveErrorEventInstance(
                surveyVersion,
                integrationFilter ? integrationFilter.subject : null,
                surveyVersion.survey.questionnaire ? surveyVersion.survey.questionnaire.code : null
                , 1,
                integrationFilter ? integrationFilter.userCode : null,
                error
            )
        );
    }


    protected notifyEvaluationError(surveyVersion: SurveyVersionI, routeId:string, integrationFilter: SurveyIntegrationFilter, ephemeral:boolean, error:string[]|string){
        this._eventService.emit(
            new SurveyEvaluationErrorEventInstance(
                surveyVersion,
                routeId,
                integrationFilter && integrationFilter.subject,
                ephemeral,
                error
            )
        );
    }


    protected prepareDataForSurveySave(surveyVersion:SurveyVersionI, integrationFilter:SurveyIntegrationFilter){
        surveyVersion.lastVersion.dateCreated = surveyVersion.dateCreated;
        if (integrationFilter) {
            this._surveyService.addCustomPropertiesToQuestionnaire(surveyVersion, this.getPrintFactory(integrationFilter));
        }
    }


    protected getPrintFactory(integrationFilter:SurveyIntegrationFilter): SurveyPrintFactory {
        const factory = SurveyPrintFactory.getFactory(integrationFilter)
          .setProperty(PASSPROPRO_SURVEY_CUSTOM_PROPERTIES.ANAG_IDENTIFICATION, (
            integrationFilter.subject ?
              (integrationFilter.subject.fiscalCode ? integrationFilter.subject.fiscalCode : integrationFilter.subject.vat)
              : ""
          )
          )
          .setProperty(PASSPROPRO_SURVEY_CUSTOM_PROPERTIES.ANAG_DESCRIPTION, integrationFilter.subject ? integrationFilter.subject.nominative : "")
          .setProperty(PASSPROPRO_SURVEY_CUSTOM_PROPERTIES.ANAG_RESIDENCE_CITY, (integrationFilter.subject && integrationFilter.subject.residence) ? integrationFilter.subject.residence.city : "")
          .setProperty(PASSPROPRO_SURVEY_CUSTOM_PROPERTIES.ANAG_RESIDENCE_CAP, (integrationFilter.subject && integrationFilter.subject.residence) ? integrationFilter.subject.residence.cap : "")
          .setProperty(PASSPROPRO_SURVEY_CUSTOM_PROPERTIES.ANAG_RESIDENCE_LEV_2_SHORT, (integrationFilter.subject && integrationFilter.subject.residence) ? integrationFilter.subject.residence.adminLevel2Short : "")
          .setProperty(PASSPROPRO_SURVEY_CUSTOM_PROPERTIES.ANAG_ACTIVITY_DESC, (integrationFilter.subject && integrationFilter.subject.corporateSector) ? integrationFilter.subject.corporateSector.descrizione : "");
        return factory;
    }


    protected getErrorByResponseResult(response:ResponseResult<any>, defaultMsg:string):string|string[]{
        return response.messages && response.messages.length ? response.messages : defaultMsg;
    }

}

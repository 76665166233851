import { Document } from './document';

export class Documents {

    public inputDocuments: Array<Document>;
    public outputDocuments: Array<Document>;
    public documentsDelatable: boolean;
    public documentsManageable: boolean;
    public documentsUploadable: boolean;
    public documentsMaxKBSize: number;
    public documentsFileTypes: Array<string>;

  constructor() { }

}

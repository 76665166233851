import {PolicyTime} from './policy-time';
import {PolicyDate} from './policy-date';
import {Constraint} from './constraint';
import {Adjustment} from './adjustement';
import {Notes} from './notes';
import {CommissionsRegime} from './commissions-regime';
import {TaxTypeContainer} from './tax-type-container';
import {AgreementsContainer} from './agreements-container';
import {PaymentFrequencyContainer} from './payment-frequency-container';
import {NotDrivingDeclaration} from './notdriving';
import {TacitRenewal} from './tacitRenewal';
import {PaymentData} from './payment-data';
import {Currency} from './currency';
import {UseTaxesContainer} from './use-tax-container';
import {GenericEntityPcPortfolio} from '../../pc-portfolio-models/genericEntityPcPortfolio';
import {RegulationPremiumResponse} from '../../pc-portfolio-models/regulation-models/pc-regulation-premium-response';
import {Binder} from './binder';
import {CoInsuranceDTO, Company} from '../co-insurance';
import {IndexType} from './index-type';
import {Country} from '../country';
import {AdditionalTechnicalData} from '../../pc-portfolio-models/proposal-models/proposal-data-response';
import {AnagIssueSubject} from "../../../re-issue/re-issue-anag/anag-issue";

export class PolicyTechnicalData {

  constructor(
    public paymentFrequencyContainer: PaymentFrequencyContainer,
    public effectiveDate: PolicyDate,
    public backdatingDays: Date,
    public postdatingDays: Date,
    public msgDateBack: string,
    public msgDatePost: string,
    public expireDate: PolicyDate,
    public issueDate: PolicyDate,
    public instalmentDates: Array<string>,
    public selectedInstalmentDate: string,
    public policyTime: PolicyTime,
    public agreementContainer: AgreementsContainer,
    public useTaxContainer: UseTaxesContainer,
    public taxTypeContainer: TaxTypeContainer,
    public constraint: Constraint,
    public adjustment: Adjustment,
    public premiumAdjustmentConfig: number,
    public minimumPremiumRegulationPercentage: number,
    public notes: Notes,
    public contractAdjustment: boolean,
    public contractContraint: boolean,
    public contractNotDrivingDeclaration: boolean,
    public commissionsRegime: CommissionsRegime,
    public notDrivingDeclaration: NotDrivingDeclaration,
    public reloadVariables: boolean,
    public recalculate: boolean,
    public errorMessages: any,
    public reloadInsuranceStatus: boolean,
    public arrearsDays: number,
    public tacitRenewal: TacitRenewal,
    public paymentData: PaymentData[],
    public branchCode: string,
    public currencies: Currency[],
    public defaultCurrency: string,
    public selectedCurrency: Currency,
    public regulationPremiumResponse: RegulationPremiumResponse,
    public coInsuranceTypes: GenericEntityPcPortfolio[],
    public companies: Company[],
    public intermediaries: Company[],
    public binder: Binder,
    public indexationConfigPP: number,
    public indexType: IndexType,
    public indexTypes: Array<IndexType>,
    public coOwnerEnabled: boolean,
    public indirectCoInsuranceConfig: number,
    public lpsCountries: Array<Country>,
    public policyRiskCommon: number,
    public managerOfRelatedPolicies: number,
    public complementaryPolicies: number,
    public policySecondRisk: number,
    public additionalTechnicalData: AdditionalTechnicalData,
    public coInsurance: CoInsuranceDTO,
    public subscriber: AnagIssueSubject,
    public coOwners: Array<AnagIssueSubject>
  ) {
  }
}






import { Injectable } from '@angular/core';
import { LibPortalIntegrationService } from '@rgi/iiab-portal-ext';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { RoutingService } from '@rgi/rx/router';

@Injectable()
export class PortalIntegrationService extends LibPortalIntegrationService {

  constructor(protected _routingService: RoutingService,
    protected translationService: RgiRxTranslationService) {
    super(_routingService, translationService);
  }
}

import {RgiRxLocalStateManager, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConsultationStartCardResourceService} from '../services/resource/consultation-start-card-resource.service';
import {ConsultationStartCardBusinessService} from '../services/business/consultation-start-card-business.service';
import {StartCardFindDto} from '../models/start-card-find-dto';
import {ConsultationStartCardState} from './states/consultation-start-card-state';
import {TypeEnum} from '../enumerations/type.enum';
import {ConsultationListNavigationData} from './states/consultation-list-state';
import {PolicyRow} from '../models/policy';
import {PolicyOperationContainer, PolicyOperationPayload} from '../models/policy-operations';


export class ConsultationStartCardStateManagerService<T extends ConsultationStartCardState> extends RgiRxLocalStateManager<T> {
  readonly TypeEnum = TypeEnum;

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    private routingService: RoutingService,
    private startCardService: ConsultationStartCardResourceService,
    private consultationStartCardBusinessService: ConsultationStartCardBusinessService) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<T>(this.activeRoute.key) ?
      stateStoreService.get<T>(this.activeRoute.key) : this.newReIssueStartCardState();

    this.updateState$(of(st)); // this update the state
  }

  initAllData(st: T, node: string, typeDefault:string,enumCodeList:Array<string>): Observable<T> {

    const comp$ = this.consultationStartCardBusinessService.getCompanies$();
    const prod$ = this.consultationStartCardBusinessService.getProducts$(st, node, typeDefault);
    const node$ = this.consultationStartCardBusinessService.getNode$(st, node);
    const enumReq = {enumList: {codes: enumCodeList}};
    const enumResp$ = this.startCardService.getEnumsByCodeList$(enumReq);

    return combineLatest([comp$, prod$, node$, enumResp$]).pipe(map(([companies, products, nodes, enumResp]) => {
      st.companyList = companies;
      st.nodeList = nodes;
      st.enumValues = enumResp.enums;
      if(typeDefault === TypeEnum.INDIVIDUAL){
        st.productListIndividual = products.products;
        st.productTypeListIndividual= products.productsType;
      } else if (typeDefault === TypeEnum.COLLECTIVE){
        st.productListCollective = products.products;
        st.productTypeListCollective= products.productsType;
      }
      return st;

    }));
  }

  newReIssueStartCardState(): T {
    return new ConsultationStartCardState(this.activeRoute.key) as T;
  }

  validate() {
    this.consultationStartCardBusinessService.validate();
  }

  findPolicies(body: StartCardFindDto ) : Observable<PolicyRow> {
    return this.consultationStartCardBusinessService.findPolicies(body);
  }

  getProducts(st: T,node: string, productsCategory:string){
    return this.consultationStartCardBusinessService.getProducts$(st, node, productsCategory)
  }

  getPolicyOperations(body: PolicyOperationPayload): Observable<PolicyOperationContainer> {
    return this.consultationStartCardBusinessService.getPolicyOperations(body);
  }

  goToNextPage(policies:PolicyRow, user:string){
    const req: ConsultationListNavigationData = {policies:policies, userCode:user};
    this.routingService.navigate('consultation-results', req , this.activeRoute.id);
  }
  createReport(extension: string, body: StartCardFindDto){
    return this.consultationStartCardBusinessService.downloadReport(extension,body);
  }
}

import {Component, ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef} from '@angular/core';
import { RoutableComponent } from './routable-component.interface';
import { Subject } from 'rxjs';

@Injectable()
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class RoutingService {

  public routes = {};
  public host: ViewContainerRef;
  componentRef: ComponentRef<Component>;
  activeStep = new Subject<string>();

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  loadComponent(component: Type<any>) {
    if (!this.host) {
      console.error('routing host not setted');
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.host.clear();

    this.componentRef = this.host.createComponent(componentFactory);
    (this.componentRef.instance as RoutableComponent).navigation.subscribe((nextRoute) => {
      if (!this.routes.hasOwnProperty(nextRoute)) {
        console.error('route does not exist');
        return;
      }
      this.loadComponent(this.routes[nextRoute]);
      this.activeStep.next(nextRoute);
    });
  }

  load(position) {
    this.loadComponent(this.routes[position]);
  }

  getActiveStep() {
    return this.activeStep.asObservable();
  }
}

import {Component, Input} from '@angular/core';
import {Clause} from "../../../../models/clause";

@Component({
  selector: 'lib-consultation-clauses',
  templateUrl: './consultation-clauses.component.html',
  styleUrls: ['./consultation-clauses.component.css']
})
export class ConsultationClausesComponent {
  @Input() clauses: Array<Clause>;
}

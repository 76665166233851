import {TechnicalData} from './technicalData';
import {Equipment} from './equipment';
import {Model} from './model';

export class VehicleVersion {

  constructor(
    public vehicleVersionCode: string,
    public previousVehicleVersionCode: string,
    public progressiveNumber: number,
    public brandCode: string,
    public modelCode: string,
    public description: string,
    public modelDescription: string,
    public registrationYearStart: number,
    public registrationYearEnd: number,
    public registrationMonthEnd: number,
    public registrationMonthStart: number,
    public vehicleTypeCode: string,
    public vehicleTypeDescription: string,
    public priceListEuro: number,
    public priceListDate: Date,
    public updatingDate: Date,
    public creationDate: Date,
    public fuelTypeCode: string,
    public bodyworkCode: string,
    public cubicCapacity: number,
    public horsepower: number,
    public taxHorsePower: number,
    public powerKW: number,
    public mass: number,
    public technicalData: TechnicalData,
    public equipment: Equipment,
    public model: Model
  ) {
  }
}

import {State} from '@rgi/rx/state';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {Asset} from '../../models/domain-models/asset';
import {ContractNumber} from '../../models/contract-number';
import {PolicyContact} from '../../models/domain-models/policy-contact';
import {Warning} from '../../models/domain-models/warning';
import {PcAssetInstance} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {ProposalStatus, Quotation} from '../../models/domain-models/quotation';
import {PcProposal} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';

export class ReIssueSummaryState extends State {
  resourceId: string;
  policyTechnicalData: PolicyTechnicalData;
  lpsData: LpsData;
  subject: AnagIssueSubject;
  allAssets: Asset[];
  productName: string;
  productId: number;
  productCode: string;
  contractNumber: ContractNumber;
  proposalStatus: ProposalStatus;
  node: any;
  contact: PolicyContact;
  companyId: string;
  editMode: boolean;
  editFunctionality: boolean;
  isPersonaGiuridica: boolean;
  isSubstitution: boolean;
  isToDisableFraz: boolean;
  warnings: Warning[] | null;
  nonBlockingWarnings: Warning[] | null;
  authorize: boolean;
  bIntermediateSaving: boolean;

  assetInstances: Array<PcAssetInstance>;
  quotation: Quotation;
  proposal: PcProposal;
  clausesFromAsset: Array<PcAssetInstance>;
}

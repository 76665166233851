/*
 * Public API Surface of shared-components
 */


export * from './lib/rgi-shared-components.module';
export * from './lib/components/documents/inbound-documents-component/documents.component';
export * from './lib/components/documents/outbound-documents-component/outbound-documents.component';
export * from './lib/components/documents/services/documents.service';
export * from './lib/components/documents/services/outbound-documents.service';
export * from './lib/components/documents/outbound-documents-component/outbound-documents.component';
export *
  from './lib/components/documents/modals/outbound-documents-modal/outbound-documents-modal/outbound-documents-modal.component';
export * from './lib/components/generic-detail/generic-detail.component';
export * from './lib/components/documents/resources/model/common/outbound-documents-reference';

import {Component, ComponentRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Modal} from '../modal';
import {ProposalService} from '../proposal.service';
import {Document} from './document';

@Component({
  selector: 'mic-documents-modal',
  templateUrl: './documents-modal.component.html',
  styleUrls: ['documents-modal.component.scss']
})
export class DocumentsModalComponent implements Modal, OnInit {

  @Input() documents: Array<Document>;
  @Input() title: string;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  componentRef: ComponentRef<any>;

  constructor(public proposalService: ProposalService) {
  }

  ngOnInit() {
  }

  download(documentCode) {
    const documentToDownload = this.documents.find(document => document.documentCode === documentCode);
    if (documentToDownload) {
      this.proposalService.downloadDocument(documentToDownload.url).subscribe(
        (data) => {
          window.open('/api/rest/system' + data.url);
        }
      );
    }
  }

  close() {
    this.componentRef.destroy();
  }

}

import { Component, Inject, Input, OnInit, Output, EventEmitter, LOCALE_ID } from '@angular/core';
import {
  ApiTranslatorPolicyService,
  PolicyService,
  Policy
} from '@rgi/digital-claims-common-angular';
import { DatePipe } from '@angular/common';
import { RgiRxDataTableDataSource, TableSchema } from '@rgi/rx/ui';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CLAIMS_SIMILAR_LEGAL, CLAIMS_SIMILAR_NO_LEGAL, DataTableSimilarClaims, OutputShowDetail } from './dsc-similar-claims.config';
import { InsurancePolicy } from '../dsc-shared-model/dsc-model-policy';
import { PotentialClaim } from '../../cai-opening/config-model/cai-opening-config.module';

@Component({
  selector: 'claims-dsc-similar-claims',
  templateUrl: './dsc-similar-claims.component.html',
  styleUrls: ['./dsc-similar-claims.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class DscSimilarClaimsComponent implements OnInit {
  @Input() similarClaimsList: PotentialClaim[] = [];
  @Input() showTitleTotal: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() legalProtectionActive: boolean;
  @Output() outputShowDetail: EventEmitter<OutputShowDetail> = new EventEmitter<OutputShowDetail>();

  tableData: RgiRxDataTableDataSource<DataTableSimilarClaims>;

  isServicePolicyDetailsEnded: boolean;
  coverturesFromService: any;
  policy: Policy;
  policyModifyData: boolean;

  unitsProductbin: Array<any>;
  covertureOk: boolean;
  rows: Array<any>;
  searchFE: string;
  totalFilter: number;
  //legalProtectionActive: boolean;
  listFunz: any[];
  inputPotentialClaim: PotentialClaim;
  displayedColumnsSimilarClaims: string[];
  displayedColumnsSimilarLegal: string[] = ['expand', 'numeroDenuncia', 'numberClaim', 'dataAccadimento',
    'descrTipoCopertura', 'dataDenuncia', 'numeroPolizza', 'nominativoAssicurato',
    'nominativoControparte', 'numeroProcedimento', 'buttons'];
  displayedColumnsSimilarNotLegal: string[] = ['expand', 'numeroDenuncia', 'numberClaim', 'dataAccadimento',
    'numeroPolizza', 'claimBranch', 'claimType', 'liquidatore', 'ispettorato', 'buttons'];
  dataSourceSimilarClaims: any;
  schema: TableSchema = CLAIMS_SIMILAR_LEGAL;


  lengthDisplayLegal: number = 11;
  lengthDisplayNotLegal: number = 9;

  configModel = {
    allowSelections: false,
    stickyHeader: false,
    stickyColumns: [],
    stickySort: false,
    stickySelection: false,
    stickyExpansion: false,
    stickyPagination: true
  };

  totalClaims: string = '';


  constructor(
    @Inject('eventService') private eventService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('claimsService') private claimsService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject(LOCALE_ID) private locale: string,
    private policyService: PolicyService,
    private translatorService: ApiTranslatorPolicyService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.schema = this.legalProtectionActive ? CLAIMS_SIMILAR_LEGAL : CLAIMS_SIMILAR_NO_LEGAL;
    this.dataSourceSimilarClaims = this.getTableData();
    this.totalClaims = this.dataSourceSimilarClaims.length.toString();
  }


  getTableData(): DataTableSimilarClaims[] {
    if (this.similarClaimsList && this.similarClaimsList.length) {
      return this.similarClaimsList.map((claim: PotentialClaim) => {
        return {
          numeroDenuncia: this.legalProtectionActive ? claim.numeroDenuncia + ' ' + claim.stato.descrizione :
            claim.numeroDenuncia,
          numberClaim: claim.numberClaim ? claim.numberClaim : '-',
          dataAccadimento: this.datepipe.transform(claim.dataAccadimento, 'dd-MM-yyyy'),
          descrTipoCopertura: claim.descrTipoCopertura,
          dataDenuncia: claim.dataDenuncia,
          dataInoltroCdl: claim.dataInoltroCdl,
          numeroPolizza: claim.numeroPolizza,
          nominativoAssicurato: claim.nominativoAssicurato,
          nominativoControparte: claim.nominativoControparte,
          contraente: claim.contraente,
          numeroProcedimento: claim.numeroProcedimento,
          companyCode: claim.companyCode,
          liquidatore: claim.liquidatore,
          ispettorato: claim.ispettorato,
          claimBranch: claim.claimBranch,
          claimType: claim.claimType,
          targaTelaio: claim.targaTelaio,
          descrizioneAccadimento: claim.descrizioneAccadimento,
          prodotto: claim.prodotto,
          lawsuitInfo: claim.lawsuitInfo,
          actionPes: claim.numberClaim ? false : true,
          nodoPolizza: claim.nodoPolizza,
          numeroPolizzaMadre: claim.numeroPolizzaMadre,
          numeroApplicazione: claim.numeroApplicazione,
          controparti:  claim.controparti == null ? "-" : claim.controparti.map(x => x.targa + " (" + x.compagnia + ")").join('<br>'),
          stato: claim.stato
        };
      });
    } else {
      return [];
    }

  }


  onActionClick(event: any) {
    // this.outputShowDetail.emit(event.row);
    // const extrNumberClaims = event.row.numberClaim.split([' ']);
    const selectedClaims = this.similarClaimsList.find((claim: PotentialClaim) => claim.numeroDenuncia === event.row.numeroDenuncia);
    const IBodySelectedClaims: OutputShowDetail = {
      numeroDenuncia: selectedClaims.numeroDenuncia,
      companyCode: event.row.companyCode,
      claimSelected: selectedClaims
    };

    this.outputShowDetail.next(IBodySelectedClaims);
  }



  policyDetails(policyFromList: InsurancePolicy) {
    const req = {
      filterPolicies: null
    };
    this.policyService.getPolicyDetail(Number(policyFromList.objectId))
      .subscribe((response: any) => {
        const contractRes = response.contract;
        if (contractRes) {
          const policyDetail = this.translatorService.apiPolicyToDtoPolicy(contractRes);
          this.policy = this.translatorService.apiPolicyToDtoPolicy(contractRes);
        }
      },
        (error: Error) => {
          // console.log(error);
        }
      );
  }

  valuateStringFieldForVisual(field: string) {
    if (field) {
      return field;
    } else {
      return '-';
    }
  }

  valuateBooleanFieldForVisual(field: boolean) {
    if (field) {
      return 'YES';
    } else {
      return 'NO';
    }
  }


  valuateArrayFieldForVisual(field: string[]) {
    if (field && field.length > 1) {
      return field;
    } else {
      const ret: string[] = ['-'];
      return ret;
    }
  }

  search() {
    this.totalFilter = 0;
    this.rows.forEach(arow => {
      const searchString: string = arow.serachString;
      if (this.searchFE &&
        searchString.toUpperCase().indexOf(this.searchFE.toUpperCase()) < 0) {
        arow.filtered = false;
      } else {
        arow.filtered = true;
        this.totalFilter++;
      }
    });
  }

  ctrDetails(index) {
    if (this.rows[index].expanded) {
      this.rows[index].expanded = false;
    } else {
      this.rows[index].expanded = true;
    }
  }


  newClaim() {
    const time = this.datepipe.transform(new Date(), 'HH:mm');
    this.chargeDetail(this.policy, new Date(), time);
  }

  chargeDetail(policy: Policy, occurrenceDate: Date, occurrenceTime: string) {

  }
}

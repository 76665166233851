import {Injectable} from '@angular/core';
import {ConditionFlatI, TermFlatI} from '../models/questionnaireFlat';
import {AnswerContainerI, AnswerType, OperatorCodes, QuestionAnswerI, QuestionStatuses} from '../models';

@Injectable()
export class QuestionnaireConditionsService {


    public checkQuestionsSections(allQuestions: QuestionAnswerI[]) {
        //let allQuestionnaireAnswers:AnswerContainerI[] = [];
        allQuestions.forEach(
            (question: QuestionAnswerI) => {
                question.compulsory = question.status * 1 == QuestionStatuses.COMPULSORY;
                question.visible = question.status * 1 != QuestionStatuses.EXCLUDED;

                if(question.conditionsFlat){

                    question.conditionsFlat.some(
                        (conditionFlat: ConditionFlatI) => {
                            let result = this.evaluateCondition(conditionFlat, allQuestions);
                            if (conditionFlat.status*1 === QuestionStatuses.UNKNOW) { //section visible
                                result = !result;
                            }
                            if (result) {
                                switch (conditionFlat.status*1) {
                                    case QuestionStatuses.COMPULSORY:
                                        // STATUS_COMPULSORY
                                        question.compulsory = true;
                                        question.visible = true;
                                        break;
                                    case QuestionStatuses.OPTIONAL:
                                      //STA<TUS_OPTIONAL
                                        question.compulsory = false;
                                        question.visible = true;
                                        break;
                                    case QuestionStatuses.EXCLUDED:
                                      question.compulsory = false;
                                      question.visible = false;
                                      break;
                                    case QuestionStatuses.UNKNOW:
                                      question.compulsory = false;
                                      question.visible = false;
                                      break;
                                    default:

                                        break;
                                }

                            }
                            return result;

                        }
                    );
                }
            }
        );
    }


    //vecchia versione
    public evaluateCondition(conditionOrig: ConditionFlatI, allQuestions: QuestionAnswerI[]) {
        var condition = ConditionFlatI.copy(conditionOrig) ;
        /* variabile di ritorno(default FALSE) */
        let result: boolean = false;
        var operator: TermFlatI;
        var firstOperand: TermFlatI;
        var secondOperand: TermFlatI;

        if (condition.terms.length === 1) {
            firstOperand = condition.terms[0];
            var questionOfFirstOperand: QuestionAnswerI = allQuestions.find(
              q => firstOperand.questionCode && q.question.code === firstOperand.questionCode);
            var firstAnswer: AnswerContainerI = this.getAnswer(questionOfFirstOperand, firstOperand)[0];
            result = this.evaluateTerm(firstOperand, firstAnswer);
        } else {
            while (condition.terms.length > 1) {

                for (var operatorIndex = 0; operatorIndex < condition.terms.length; operatorIndex++) {
                    if ((condition.terms[operatorIndex].factorCode === null || condition.terms[operatorIndex].factorCode === "") && !condition.terms[operatorIndex].isResult) {
                        operator = condition.terms[operatorIndex];
                        secondOperand = condition.terms[operatorIndex - 1];
                        firstOperand = condition.terms[operatorIndex - 2];
                        condition.terms.splice(operatorIndex - 2, 3);
                        break;
                    }
                }


                result = this.evaluate3Term(operator, firstOperand, secondOperand, allQuestions);
                condition.terms.splice(0, 0, { value: result, isResult: true } as TermFlatI);
            }
        }

        return result;
    }


    protected evaluate3Term(operator: TermFlatI, firstOperand: TermFlatI, secondOperand: TermFlatI, questions: QuestionAnswerI[]) {
        var firstAnswer: AnswerContainerI;
        var secondAnswer: AnswerContainerI;

        var questionOfFirstOperand: QuestionAnswerI =
          questions.find((q: QuestionAnswerI) => firstOperand.questionCode != null && q.question.code === firstOperand.questionCode);
        var questionOfSecondOperand: QuestionAnswerI =
          questions.find((q: QuestionAnswerI) => secondOperand.questionCode != null && q.question.code === secondOperand.questionCode);

        if (questionOfFirstOperand) {
            firstAnswer = this.getAnswer(questionOfFirstOperand, firstOperand)[0];
        }
        if (questionOfSecondOperand) {
            secondAnswer = this.getAnswer(questionOfSecondOperand, secondOperand)[0];
        }

        return this.evaluateOperation(firstOperand, secondOperand,
            firstAnswer, secondAnswer, operator);
    }


    protected getAnswer(question: QuestionAnswerI, operand: TermFlatI): AnswerContainerI[] {
        var answers: AnswerContainerI[] = [];

        if (question && question.answers && question.answers.length > 0) {
            /* ciclo sulle risposte */
            for (var j = 0; j < question.answers.length; j++) {
                var answer: AnswerContainerI = question.answers[j];
                /* controllo che il codice fattore della risposta sia uguale */
                /* al codice fattore della domanda */
                if (answer.answer.code === operand.factorCode) {
                    answers.push(answer);
                }
            }
        }

        return answers;
    }


    protected evaluateOperation(firstOperand:TermFlatI, secondOperand:TermFlatI,
        firstAnswer:AnswerContainerI, secondAnswer:AnswerContainerI, operator:TermFlatI):boolean {

        /* Imposto i 2 operandi e l'operatore */
        var resEvaluate:boolean = false;
        var operandA = firstOperand.isResult ? firstOperand.value : this.evaluateTerm(firstOperand, firstAnswer);
        var operandB = secondOperand.isResult ? secondOperand.value : this.evaluateTerm(secondOperand, secondAnswer);
        /* Controllo l'operatore e faccio la valutazione tra i due operandi */
        switch (operator.operator*1) {
            case OperatorCodes.AND:
                resEvaluate = Boolean(operandA && operandB);
                break;
            case OperatorCodes.OR:
                resEvaluate = Boolean(operandA || operandB);
                break;
        }
        return resEvaluate;
    }


    protected evaluateTerm(term: TermFlatI, questionAnswer: AnswerContainerI):boolean {
        /* Valutazione tra il valore del termine e il valore della risposta considerando */
        /* il tipo di operatore */
        var questionValue = questionAnswer ? questionAnswer.value : "";
        var validation:boolean = false;

      /* fix valori nulli */
      if (term.value==="")
      {
        term.value="-1";
      }

      if (!questionValue || questionValue==="")
      {
        questionValue="-1";
      }

        if ( questionValue) {
            if(typeof(questionValue)=='object' ){
                if(questionValue['id']){
                    questionValue = questionValue.id+"";
                }else {
                    console.error("strange question value without id: ", questionValue);
                }
            }
            var arr = String(questionValue).toUpperCase().split(";");
            switch (term.operator*1) {
                case OperatorCodes.EQUAL:
                    var termval = (questionAnswer.answer.type === AnswerType.STRING) ? term.value : String(parseInt(term.value));
                    validation = arr.indexOf(termval) >= 0;
                    //validation = arr.indexOf(String(parseInt(term.value))) >= 0;
                    //validation = Boolean(parseInt(term.value) === parseInt(questionValue));
                    break;
                case OperatorCodes.NOT_EQUAL:
                    var termval2 = (questionAnswer.answer.type === AnswerType.STRING) ? term.value : String(parseInt(term.value));
                    validation = arr.indexOf(termval2) === -1;
                    //validation = arr.indexOf(String(parseInt(term.value))) === -1;
                    //validation = Boolean(parseInt(term.value) !== parseInt(questionValue));
                    break;
                case OperatorCodes.LOWER_THAN:
                    validation = Boolean(parseInt(term.value) > parseInt(arr[0]));
                    break;
                case OperatorCodes.HIGHER_THAN:
                    validation = Boolean(parseInt(term.value) < parseInt(arr[0]));
                    break;
                case OperatorCodes.LOWER_EQUAL:
                    validation = Boolean(parseInt(term.value) >= parseInt(arr[0]));
                    break;
                case OperatorCodes.HIGHER_EQUAL:
                    validation = Boolean(parseInt(term.value) <= parseInt(arr[0]));
                    break;
                case OperatorCodes.AND:
                    validation = Boolean(term.value && arr[0]);
                    break;
                case OperatorCodes.OR:
                    validation = Boolean(term.value || arr[0]);
                    break;
                case OperatorCodes.NOT:
                    validation = Boolean(!term.value);
                    break;
                default:
                    break;
            }
        }
        return validation;
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxSwitchDirective} from './rgi-rx-switch/rgi-rx-switch.directive';
import {RgiRxFormElementsModule} from '../form-elements/rgi-rx-form-elements.module';


@NgModule({
  declarations: [RgiRxSwitchDirective],
  imports: [
    CommonModule,
    RgiRxFormElementsModule
  ],
  exports: [RgiRxSwitchDirective]
})
export class RgiRxSwitchModule {
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiPremium} from '../models/api-models/api-premium';
import {ProposalService} from '../proposal.service';
import {Discount} from './discounts/discount';
import {Subject} from 'rxjs';
import {DiscountFund} from './discounts/discount-fund';
import {ApiQuotation} from '../models/api-models/api-quotation';
import {EntitledQuotation} from '../proposal/quotation-comparison/entitled-quotation';
import {ApiPremiumDetail} from '../models/api-models/api-premium-detail';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  private baseApiUrl: string;
  private baseApiUrlV2: string;
  protected httpClient: HttpClient;
  private premium: ApiPremium;
  private recalculatePremiumChannel: Subject<any> = new Subject<any>();
  private previousAnnualPremium: ApiPremiumDetail;
  private entitledPremium: EntitledQuotation;
  private refreshInsuranceStatusToken = new Array(1);
  private isCustomView = false;
  private policyInLPS = false;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected proposalService: ProposalService) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;

    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';
    this.httpClient = this.micHttpClient.getHttpClient();
  }

  calculatePremium(quotation: ApiQuotation) {
    const contractId = this.proposalService.getContractId();
    if (quotation === null || quotation === undefined) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const options = { headers };
      return this.httpClient.put<ApiPremium>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/premium', quotation, options);
    } else {
      return this.httpClient.put<ApiPremium>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/premium', quotation);
    }
  }

  getQuotation(quotation: ApiQuotation) {
    const contractId = this.proposalService.getContractId();
    if (quotation === null || quotation === undefined) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const options = { headers };
      return this.httpClient.put<ApiQuotation>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/quotation', quotation, options);
    } else {
      return this.httpClient.put<ApiQuotation>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/quotation', quotation);
    }

  }

  getMockQuotation(quotation: ApiQuotation) {
    return this.httpClient.get<ApiQuotation>('/mock-api/quotation');
  }

  getPremium() {
    return this.premium;
  }

  setPremium(premium: ApiPremium) {
    this.premium = premium;
  }


  getDiscounts() {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.get<Array<Discount>>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/flex');
  }

  updateDiscounts(discounts: Array<Discount>) {
    const contractId = this.proposalService.getContractId();
    if (discounts === null || discounts === undefined) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const options = {headers};
      return this.httpClient.put<Array<Discount>>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/discount', discounts,
         options);
    } else {
      return this.httpClient.put<Array<Discount>>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/discount', discounts);
    }
  }

  getRecalculatePremiumSignal() {
    return this.recalculatePremiumChannel.asObservable();
  }

  sendRecalculatePremiumSignal() {
    this.recalculatePremiumChannel.next();
  }

  getResidualDiscount() {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.get<Array<DiscountFund>>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/residualdiscountfund');
  }

  retrieveEntitledPremium() {
    const contractId = this.proposalService.getContractId();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {headers};
    return this.httpClient.put<EntitledQuotation>(this.baseApiUrlV2 + '/motor/contract/' + contractId + '/entitled/quotation',
      {}, options);
  }

  getEntitledPremium() {
    return this.entitledPremium;
  }

  setEntitledPremium(entitledPremium) {
    this.entitledPremium = entitledPremium;
  }

  getPreviousAnnualPremium() {
    return this.previousAnnualPremium;
  }

  setPreviousAnnualPremium(previousAnnualPremium) {
    this.previousAnnualPremium = previousAnnualPremium;
  }

  getRefreshInsuranceStatusToken() {
    return this.refreshInsuranceStatusToken.pop();
  }

  setRefreshInsuranceStatusToken() {
    this.refreshInsuranceStatusToken.push(true);
  }

  getCustomView() {
    return this.isCustomView;
  }

  setCustomView(isCustomView: boolean) {
    this.isCustomView = isCustomView;
  }

  isPolicyLps() {
    return this.policyInLPS;
  }

  setPolicyLps(isPolicyInLPS: boolean) {
    this.policyInLPS = isPolicyInLPS;
  }

  getProposalService() {
    return this.proposalService;
  }

}

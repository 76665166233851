<rgi-rx-panel class="rgi-gp-modal-method">
    <rgi-rx-panel-header>
        <div class="d-flex">
            <div class="title-div modal-title-div">
                <div>{{ '_CLAIMS_._SETTLEMENTS' | translate }}</div>
            </div>
            <div class="title-div modal-title-div">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;color:  white;">{{ 'Amount' |
                    translate }}: €</label>
                <label ng-bind="label" style="font-weight: bold;color: white;">
                    {{ totalAmount | number:'1.2-2' }}</label>
            </div>
            <div class="title-div modal-close-div">
                <div class="btn-close-custom text-right">
                    <div class="rgifont rgi-close_fine pull-right" (click)="onCloseModal()"></div>
                </div>
            </div>
           
        </div>
    </rgi-rx-panel-header>
    <rgi-rx-panel-footer>
        <div class="btn-group btn-group-justified btn-group-justified-claim">
            <div class="btn-group pc-01">
                <button rgi-rx-button color="primary" class="w-100-custom" (click)="onCloseModal()">{{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
            </div>
            <div class="btn-group pc-01">
                <button rgi-rx-button color="primary"
                [disabled]="(saveDisabled || settlementForm.invalid || MsgError !== null)"
                class="w-100-custom" (click)="onConfirm()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
            </div>
        </div>
    </rgi-rx-panel-footer>
    <ng-container>
        <div class="grid-item card card-height-normal card-main">
            <!-- Corpo del form -->
            <!-- <claims-new-payment-progress [currentStep]="currentStep"></claims-new-payment-progress> -->
            <div class="card shadow">
                <div class="row mb-2" *ngIf="feeData">
                    <div class="col-xs-4">
                        {{"_CLAIMS_._INVOICE_TYPE" | translate}}<span>: {{feeData.feeType}}</span>
                    </div>
                    <div class="col-xs-4">
                        {{"_CLAIMS_._INVOICE_NUMBER" | translate}}<span>: {{feeData.feeNumber}}</span>
                    </div>
                    <div class="col-xs-4">
                        {{"_CLAIMS_._INVOICE_DATE" | translate}}<span>: {{feeData.feeDate | date:'shortDate' }}</span>
                    </div>
                </div>
               <!--  <ng-template #noFeeData>
                    <div class="col-xs-12 no-invoices">
                        <span>{{"No Invoices" | translate }}</span>
                    </div>
                </ng-template> -->
                <!-- <div ngIf="!feeData">
                </div> -->
                <form [formGroup]="settlementForm">
                    <div class="row">
                        <div class="col-xs-12" style="font-size: 14px;">
                            <rgi-rx-form-field>
                                <label rgiRxLabel>{{ '_CLAIMS_._PAYMENT_DATE' | translate }}</label>
                                <rgi-rx-date-picker [openOnClick]="false">
                                <input [rgiRxDateTimeInput]="consDate"
                                        formControlName="date"
                                        data-qa="rgi-update-reserve-reopen-date"
                                        placeholder="{{datepickerPlaceholder}}"
                                        [max]="today">
                                </rgi-rx-date-picker>
                                <rgi-rx-date-time #consDate></rgi-rx-date-time>
                            </rgi-rx-form-field>
                        </div>
                        <!-- <div class="col-xs-6">
                            <rgi-rx-form-field>
                                <label rgiRxLabel>{{ '_CLAIMS_._CUSTOM_COMBO' | translate }} </label>
                                <select rgiRxNativeSelect  formControlName="combo">
                                <option *ngFor="let reason of reasonSettlementList; " value={{reason.code}}>
                                    {{reason.description}}</option>
                                </select>
                            </rgi-rx-form-field>
                        </div> -->
                    </div>
                    <!-- Riga contenente il campo note -->
                    <div class="row">
                        <div class="col-xs-12">
                            <rgi-rx-form-field>
                                <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                                <textarea rgiRxInput formControlName="note"></textarea>
                            </rgi-rx-form-field>
                        </div>   
                    </div>
                </form>
                <div class="row" *ngIf="viewPotPay">
                    <div class="col-xs-12">
                        <claims-payment-method panelId="payment-method" [card]="card" nextPanelId="document-choice"
                            (stepperEventEmitter)="progress($event)" (saveEmitter)="saveAvailable($event)" acceptSignals="true"
                            (detailPaymetOutput)="onChangeModelForm($event)"
                            [claimInput]="claimCurrent"
                            [potPayFE]="potPayFe" openOutOfStepper="true">
                        </claims-payment-method>
                    </div>
                </div>
                
                <!-- <claims-general-data [potPayFE]="potPayFE"></claims-general-data> -->
                <!-- Riga contenente la data -->

            </div>
            <!-- <div class="row" *ngIf="detailDataMethodPayment">
                <div *ngIf="detailDataMethodPayment.validation">Dati Validi {{detailDataMethodPayment?.paymentData?.iban}}</div>
                <div *ngIf="!detailDataMethodPayment.validation">Dati non Validi {{detailDataMethodPayment?.paymentData?.iban}}</div>
            </div> -->
        </div>
        <div class="form-group">
            <div class="alert alert-danger" *ngIf="MsgError!=null" role="alert">{{MsgError}} </div>
        </div>
    </ng-container>
    
</rgi-rx-panel>
import { CurrencyCacheService, LpcCurrencyCache } from './../../../../../services/currency-cache.service';
import { Component, forwardRef, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationWrapperService } from '../../../../../i18n/translation-wrapper.service';
import { RgiCountryLayerCurrencyFormatPipe } from '@rgi/country-layer';
import { LpcKarmaDisinvestmentFundComponent } from '../../lpc-disinvestments/lpc-karma-disinvestment-fund/lpc-karma-disinvestment-fund.component';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'lpc-karma-disinvestment-full-fund',
  templateUrl: '../../lpc-disinvestments/lpc-karma-disinvestment-fund/lpc-karma-disinvestment-fund.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFullFundComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFullFundComponent),
      multi: true
    },
    RgiCountryLayerCurrencyFormatPipe
  ]
})
export class LpcKarmaDisinvestmentFullFundComponent extends LpcKarmaDisinvestmentFundComponent {

  constructor(
    protected translateService: TranslationWrapperService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected currencySymbolPipe: RgiCountryLayerCurrencyFormatPipe
  ) {
    super(translateService, currencyService, currencySymbolPipe);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  subscribeToActiveChanges(): Observable<any> {
    if (!this.isFullPreval) {
      return super.subscribeToActiveChanges();
    }
    return this.formGroup.get('active').valueChanges.pipe(
      tap(value => {
        const percentage = !!value ? 100 : 0;
        this.formGroup.get('percent').setValue(percentage);
        this.formGroup.get('percent').disable(); // Disable the percent control to prevent modification
        this.fundSelected.emit({
            name: this.definition.description,
            selected: true,
            value: percentage
        });
      }
    ));
  }
}

export class PolicyDate {
  constructor(
    public date: Date,
    public maxDate: Date,
    public minDate: Date,
    public modifiable: boolean,
    public enabled: boolean,
    public selected ?: boolean,
  ) {
  }
}

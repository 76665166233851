import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LicHelpService {

    protected endpoint: string;

    constructor(
        protected httpClient: HttpClient,
        @Inject('environment') protected environment: any) {
        this.endpoint = environment.api.portal.host + environment.api.portal.path + '/v2/life/help/';
    }

    public getHelpContent(helpFile: string): Observable<any> {
        return this.httpClient.get(this.endpoint + helpFile, { responseType: 'text' });
    }
}

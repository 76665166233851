import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { switchMap } from 'rxjs/operators';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { QuotationReactivation } from '../../modules/lpc-quotation/model/quotation.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-reactivation-from-termination',
  templateUrl: './reactivation-from-termination.component.html',
  styleUrls: ['./reactivation-from-termination.component.scss']
})
export class ReactivationFromTerminationComponent extends AbsOperationComponent implements OnInit {

  protected operationDataKey = 'termination-reactivation';

  public quotationData: QuotationReactivation = null;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService,
      notifierService, plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe()
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      quotation: new UntypedFormControl()
    });
  }

  onNext(step, publish = false, context = step, isConfirmAndAccept = false) {
    const beStep: string = step;
    if (this.isFormValid(this.formGroup.get(step))) {
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, step, this).pipe(
        switchMap((result) => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
          if (!!this.avcQuestionnaire) {
            this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(this.avcQuestionnaire);
          }

          return this.updateDraft(beStep);
        })
      ).subscribe(result => {
          this.quotationData = result.data.operationData.data as QuotationReactivation;
          this.detectChanges();
          if (!this.hasBlockingErrorsOnSteps(step)) {
            this.doPublishOrNext(publish, isConfirmAndAccept);
          }
        })
      );
    } else {
      this.setFeErrors(step);
    }
  }


  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

}

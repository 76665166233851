import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortByDatePipe } from './dates-order-pipe';


@NgModule({
  declarations: [SortByDatePipe],
  imports: [
    CommonModule
  ],
  exports: [SortByDatePipe],
  providers: [SortByDatePipe]
})
export class SortByDateModule { }

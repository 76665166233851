import {Entity} from '../entity';

export class CommissionsTotal {
  accessoriesCommissionsPurchase: number;
  netCommissionsPurchase: number;
  accessoriesCommissionsCollection: number;
  netCommissionsCollection: number;
  totalInstallment: number;
}
export class PremiumItem{
  type: Entity;
  percentage: number;
  gross: number;
  net: number;
  tax: number;
  fee: number;
  feeInterests: number;
  netInterests: number;
  ssn: number;
  sign: number;
  fractioningInterestsAccessories: number;
  fractioningInterestsNet: number;
  accessories: number;
  antiRacket: number;
  taxable: number;
  commissions: CommissionsTotal;
  description: string;
  rate: number;
}
export class InstalmentItem{
  due: Array<PremiumItem>;
  refunded: Array<PremiumItem>;
}

export class InstalmentContainer {
  dueTotal: PremiumDetail;
  amountsByGuarantee: InstalmentItem;
  amountsByRisk: InstalmentItem;
}



export class PremiumDetail {
  gross: number;
}

export class TaxDetail{
  taxDescription: string;
  amount: number;
  taxRate: number;
  antiracket: number;
}
export class  InstalmentPremiumDetail{
  net: number;
  taxes: number;
  taxDetails: Array<TaxDetail>;
  taxable: number;
  gross: number;
  antiracket: number;
  description: string;
  rate: number;
  accessories: number;
  fractioningInterestsNet: number;
}


export class InstalmentDetail {
  premium: PremiumDetail;
  amountByCover: Array<InstalmentPremiumDetail>;
  amountByRisk: Array<InstalmentPremiumDetail>;
}


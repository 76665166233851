import {RgiRxi18nModuleLoadType, RgiRxTranslations} from "@rgi/rx/i18n";
import {DE, EN, ES, FR, IT} from "./translations";


export function loadNgPassProProCore18n():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(EN);
  })
}

export function loadNgPassProProCore18nIT():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(IT);
  })
}

export function loadNgPassProProCore18nFR():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(FR);
  })
}


export function loadNgPassProProCore18nDE():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(DE);
  })
}

export function loadNgPassProProCore18nES():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(ES);
  })
}

export const NG_PASSPROPRO_CORE_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadNgPassProProCore18n,
    locale: "en"
  },
  {
    load: loadNgPassProProCore18nIT,
    locale: "it"
  },
  {
    load: loadNgPassProProCore18nES,
    locale: "es"
  },
  {
    load: loadNgPassProProCore18nDE,
    locale: "de"
  },
  {
    load: loadNgPassProProCore18nFR,
    locale: "fr"
  }
]

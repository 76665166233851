export class DiscountFundPeriod {
  constructor(
    public amount: number,
    public endDate: number,
    public objectId: number,
    public residualAmount: number,
    public startDate: number
  ) {
  }
}

<ng-container *ngIf="activeStep && isFunds">
  <div [hidden]="viewOnly || (activeStep && !currentStep)">
    <div rgi-rx-accordion [multi]="true" *ngFor="let profile of selectedProfiles" >
      <rgi-rx-expansion-panel [expanded]="true" color="default">
        <div rgi-rx-expansion-panel-header>
          <span class="accordion-text" translate>{{ profile.description }}</span>
        </div>
        <div content data-qa="panel-button">
            <lpc-karma-investment-funds
              [formGroup]="fundFormGroup"
              [profileIdForFunds]="profile.id.toString()"
              [definition]="profile.funds"
              [totalAmount]="getProfileAmount(profile.id)"
              [investmentMode]="config.investmentMode"
              [percentageSumEqual100]="config.percentageSumEqual100"
              [showPercentageInput]="config.showPercentageInput"
              [showSliderInput]="config.showSliderInput"
              [enableSingleElementSelection]="config.enableSingleElementSelection"
              [prevalIfLengthEqual1]="config.prevalIfLengthEqual1"
              (changeValueEvent)="changeValueHandling($event)">
            </lpc-karma-investment-funds>
        </div>
      </rgi-rx-expansion-panel>
    </div>
  </div>
  <div *ngIf="viewOnly || (activeStep && !currentStep)">
    <lpc-karma-fund-table
      [profiles]="definition"
      [isFreeManagement]="isFreeManagement"
      [totalAmount]="totalAmount"
      [showAmountColumnOnViewOnly]="config.showAmountColumnOnViewOnly"
      [enableOpenListDefault]="config.enableOpenListDefault"
      [investedProfiles]="investedProfiles">
    </lpc-karma-fund-table>
  </div>
  <div class="col-lg-12" *ngIf="config.enableVolatility && ((profileFormGroup.valid && fundFormGroup.valid) || viewOnly)">
    <lpc-volatility-button
      [volatility]="volatility"
      [disabled]="!!volatility"
      (calculate)="volatilityEvent.emit({action: 'calculate'})">
    </lpc-volatility-button>
  </div>
</ng-container>
<ng-container *ngIf="activeStep && !isFunds">
  <lpc-karma-investment-funds [hidden]="viewOnly || (activeStep && !currentStep)"
    [formGroup]="profileFormGroup"
    [definition]="definition"
    [totalAmount]="totalAmount"
    [investmentMode]="config.investmentMode"
    [percentageSumEqual100]="config.percentageSumEqual100"
    [showPercentageInput]="config.showPercentageInput"
    [showSliderInput]="config.showSliderInput"
    [enableSingleElementSelection]="config.enableSingleElementSelection"
    [prevalIfLengthEqual1]="config.prevalIfLengthEqual1"
    (changeValueEvent)="changeValueHandling($event)">
  </lpc-karma-investment-funds>
  <div *ngIf="viewOnly || (activeStep && !currentStep)">
    <ul>
      <li [attr.data-qa]="profile.id + 'info_investment_profile'" *ngFor="let profile of selectedProfiles">
        <span for="label">{{ profile.description }} </span>
        <span for="label">{{ getProfileAmount(profile.id.toString()).toString() | rgiCountryLayerNumberFormat: currencyService.locale:currencyFormatterOptions }}</span>
        <span for="value">({{ getProfilePercent(profile.id.toString()).toString() | percent: '1.2-2' }})</span>
      </li>
    </ul>
  </div>
</ng-container>

import { LifeParty, PolicyRole, Proposal, ChoiceBeneficiaryData } from './../models/policy.model';
import { Injectable, OnInit } from '@angular/core';
import { Subject as SubjectRxjs } from 'rxjs';
import { BENEFICIARY_CHOICE } from '../enum/life-issue.enum';

@Injectable({
    providedIn: 'root'
  })

export class FromJsService implements OnInit {

  constructor() {
  }

  protected messageFromJs: SubjectRxjs<any> = new SubjectRxjs<any>();
  protected proposalFromJs: SubjectRxjs<Proposal> = new SubjectRxjs<Proposal>();

  ngOnInit() {
  }

  setMessageFromJs(message: any) {
    // ricordarsi di fare il reset dei messaggi una volta ricevuti
    // .emit('resetMessageFromJs');
    this.messageFromJs.next(message);
  }

  getMessageFromJs(): SubjectRxjs<any> {
    // ricordarsi di fare il reset dei messaggi una volta ricevuti
    // .emit('resetMessageFromJs');
    return this.messageFromJs;
  }

  setProposalFromJs(proposal: Proposal) {
    this.proposalFromJs.next(proposal);
  }

  getProposalFromJs(): SubjectRxjs<Proposal> {
    return this.proposalFromJs;
  }

  getConsensuesFromProposal(proposal: Proposal) {
    const cons = [];
    if (!!proposal && !!proposal.extensionData
      && proposal.extensionData.properties && proposal.extensionData.properties.length > 0) {
        proposal.extensionData.properties.forEach((prop) => {
          if (prop.chiave.startsWith('CONSENSUSES_')) {
            cons.push(prop);
          }
        });
    }
    return cons;
  }

  convertIntoPartiesInfo(roleVector: PolicyRole[]): { partyRole: string, partyCode: string }[] {
    const partiesInfo = [];

    roleVector.forEach((subject) => {
      if (!!subject) {
        partiesInfo.push({ partyRole: subject.partyRole, partyCode: subject.objectId });

        if (!!subject.linkedSubjectRoles && subject.linkedSubjectRoles.length > 0) {
          subject.linkedSubjectRoles.forEach((subSubject) => {
            partiesInfo.push({ partyRole: subSubject.partyRole, partyCode: subSubject.objectId });
          });
        }
      }
    });

    return partiesInfo;
  }

  convertLifePartyIntoPartiesInfo(sv: LifeParty[], bv?: ChoiceBeneficiaryData[]): { partyRole: string, partyCode: string }[] {
    const partiesInfo = [];

    sv.forEach((subject: LifeParty) => {
      if (!!subject) {
        partiesInfo.push({ partyRole: subject.party.partyRole, partyCode: subject.party.objectID });

        if (!!subject.linkedLifeParties && subject.linkedLifeParties.length > 0) {
          subject.linkedLifeParties.forEach((subSubject: LifeParty) => {
            partiesInfo.push({ partyRole: subSubject.party.partyRole, partyCode: subSubject.party.objectID });
          });
        }
      }
    });

    if (!!bv) {
      bv.forEach((benef: ChoiceBeneficiaryData) => {
        if (benef.choiceTypeBeneficiary === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
          if (!!benef.beneficiaryLifeParty && benef.beneficiaryLifeParty.party) {
            partiesInfo.push({
              partyRole: benef.beneficiaryLifeParty.party.partyRole,
              partyCode: benef.beneficiaryLifeParty.party.objectID
            });
          }
        }
      });
    }

    return partiesInfo;
  }

}


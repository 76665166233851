import {Pipe, PipeTransform} from '@angular/core';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';

/** @deprecated */
@Pipe({
  name: 'customNumberPipe'
})
export class CustomNumberPipe implements PipeTransform {
  constructor() {
  }

    transform(value: string | number, type: string = 'number', fractionSize: number = 2, output: string = '-', format = true): string {
      if (value != null && value !== undefined) {
        value = value.toString();
        const decimalSeparator = ',';
        const thousandSeparator = '.';
        let prefix = EMPTY_STR;
        let suffix = EMPTY_STR;

        if (type === 'currency') {
          prefix = '€ ';
        } else if (type === 'percentage') {
          prefix = EMPTY_STR;
          suffix = ' %';
        }

        if (format) {
          let numericValue;
          if (type !== 'number' && fractionSize < 3) {
            numericValue = (Math.round(parseFloat(value) * 100) / 100).toString();
          } else {
            numericValue = value.toString();
          }

          let [ integer, fraction = EMPTY_STR] = (parseFloat(numericValue).toString() || EMPTY_STR).toString().split('.');

          fraction = fractionSize > 0 ? decimalSeparator + (fraction).substring(0, fractionSize) : EMPTY_STR;
          while (fraction.length < (decimalSeparator.length + fractionSize)) {
            fraction = fraction.concat('0');
          }

          integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
          if (isNaN(parseFloat(integer))) {
            integer = '0';
          }

          return prefix + integer + fraction + suffix;
        } else {
          return prefix + value + suffix;
        }

      }
      return output;
    }
  }


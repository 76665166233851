import {RgiRxTranslations, Serialized18n} from '@rgi/rx/i18n';
import {IT} from './translations_it';
import {EN} from './translations_en';
import {ES} from './translations_es';
import {CA_ES} from "./translations_ca-ES";
import {EU_ES} from "./translations_eu-ES";
import {GL_ES} from "./translations_gl-ES";

export function load_re_issue_i18n_IT() {
  return new Promise<Serialized18n>(resolve => {
    resolve(IT);
  });
}

export function load_re_issue_i18n_EN() {
  return new Promise<Serialized18n>(resolve => {
    resolve(EN);
  });
}

export function load_re_issue_i18n_ES() {
  return new Promise<Serialized18n>(resolve => {
    resolve(ES);
  });
}

export function load_re_issue_i18n_ES_EU() {
  return new Promise<Serialized18n>(resolve => {
    resolve(EU_ES);
  });
}

export function load_re_issue_i18n_ES_CA() {
  return new Promise<Serialized18n>(resolve => {
    resolve(CA_ES);
  });
}

export function load_re_issue_i18n_ES_GL() {
  return new Promise<Serialized18n>(resolve => {
    resolve(GL_ES);
  });
}
export const TRANSLATIONS_RE_ISSUE: RgiRxTranslations = [
  {
    load: load_re_issue_i18n_IT,
    locale: 'it'
  },
  {
    load: load_re_issue_i18n_EN,
    locale: 'en'
  },
  {
    load: load_re_issue_i18n_ES,
    locale: 'es'
  },
  {
    load: load_re_issue_i18n_ES_EU,
    locale: 'eu-ES'
  },
  {
    load: load_re_issue_i18n_ES_CA,
    locale: 'ca-ES'
  },
  {
    load: load_re_issue_i18n_ES_GL,
    locale: 'gl-ES'
  }
];

import {PortalNgCommonTranslationKeys} from './translation.keys';

export const EN: PortalNgCommonTranslationKeys = {
    RGI_PORTAL_COMPONENTS: {
      ADD: 'Add',
      ADD_DOCUMENT: 'Add document',
      CANCEL: 'Cancel',
      CODE: 'Code',
      CONFIRM: 'Confirm',
      DELETE_DOC: 'Delete document',
      DOCUMENTS_MANAGER: 'Documents Manager',
      DOCUMENT_TYPE: 'Document type',
      DOCUMENT_VALIDATION_MESSAGE: 'Proceed with the validation of the document?',
      DOWNLOAD: 'DOWNLOAD',
      EDIT: 'Edit',
      FILE: 'File',
      INPUT_DOCUMENTS: 'Input Documents',
      LOAD: 'LOAD',
      NO: 'No',
      NOTE: 'Note',
      NO_FILES: 'No files',
      NUMBER_OF_FILES_INSERTED: '{{ filesLength }} of {{ fileMax }} files inserted',
      OUTPUT_DOCUMENTS: 'Output Documents',
      SAVE: 'SAVE',
      STATE: 'Status',
      UPDATE_FILE: 'Update file',
      UPLOAD: 'UPLOAD',
      UPLOAD_NEW_DOCUMENT: 'Upload new document',
      VALIDATION: 'Validation',
      VALIDATION_DOCUMENT: 'Validation document',
      YES: 'Yes',
    }
};

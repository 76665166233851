import {Component} from '@angular/core';
import {VariablesModalComponent} from '../../variables/variables-modal/variables-modal.component';
import {Variable} from '../../models/domain-models/variable';

@Component({
  selector: 'mic-party-variables-modal',
  templateUrl: './party-variables-modal.component.html',
  styleUrls: ['./party-variables-modal.component.scss']
})
export class PartyVariablesModalComponent extends VariablesModalComponent {

  protected getUpdateVariablesObservable(variables: Array<Variable>) {
    return this.variablesService.updatePartyVariables(variables);
  }

}

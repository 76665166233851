import { PolicyService } from './../../../services/policy-service';
import {Component, Input, OnInit} from '@angular/core';
import {KarmaFundError} from '../../model/karma-fund-error';

@Component({
  selector: 'lic-karma-fund-message[error]',
  templateUrl: './lic-karma-fund-message.component.html',
  styleUrls: ['./lic-karma-fund-message.component.scss']
})
export class LicKarmaFundMessageComponent implements OnInit {

  public locale = 'it-IT';
  public formatterOptionPerc: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };
  public formatterOptionCurrency: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() error: KarmaFundError;

  public lower: boolean;

  public total: boolean;

  public type: 'min' | 'max' | 'ok';

  public amount: number;

  public percent: number;

  public limit: number;

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
    this.formatterOptionCurrency.currency = policyService.currencyCode;
  }

  ngOnInit() {
    this.lower = this.error.percent > 0;
    this.total = this.error.description === 'TOTAL_FUNDS';
    this.amount = Math.abs(this.error.amount);
    this.percent = Math.abs(this.error.percent);
    this.limit = this.error.limit;
    this.type = this.error.type;
  }


}

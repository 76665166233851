import {WarningsRequest} from './../../models/pc-portfolio-models/warnings-models/warnings-request';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioWarningsUtilityService {
  private readonly POLICY_DATA_LEVEL = 'PRODUCT';
  private readonly ASSETS_DATA_LEVEL = 'ASSET';
  private readonly PRE_QUOTATION_DATA_LEVEL = 'PRE_QUOTATION';
  private readonly POST_QUOTATION_DATA_LEVEL = 'POST_QUOTATION';
  private readonly QUESTIONNAIRE_DATA_LEVEL = 'QUESTIONNAIRE';
  private readonly PROPOSAL_MANAGEMENT_DATA_LEVEL = 'PROPOSAL_MANAGEMENT';
  private readonly ALL_DATA_LEVEL = '';

  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_TECNICI = 'T';
  private readonly CODICE_LIVELLO_AZIONE_POST_QUESTIONARI = 'QUE';
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_BENE = 'SB';
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_BENE = 'B';
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_ATR = 'BATR';
  private readonly CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_UNIT = 'PG';
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_UNIT = 'G';
  private readonly CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_CLAUSOLE = 'PC';
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_CLAUSOLE = 'C';
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_FATTORI_UNIT = 'U';
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_FATTORI_SLAVE = 'FS';
  private readonly CODICE_LIVELLO_AZIONE_PRE_DATI_PRE_QUOTAZIONE = 'BPQ';
  private readonly CODICE_LIVELLO_AZIONE_POST_DATI_PRE_QUOTAZIONE = 'APQ'; // ??
  private readonly CODICE_LIVELLO_AZIONE_POST_QUOTAZIONE = 'Q';
  private readonly CODICE_LIVELLO_FORMULA_ASSUMIBILITA = 'ASS'; // ??
  private readonly CODICE_LIVELLO_FORMULA_VENDIBILITA = 'VEN'; // ??
  private readonly CODICE_LIVELLO_POST_SELEZIONE_APPENDICE = 'A'; // ??
  private readonly CODICE_LIVELLO_PRE_SALVATAGGIO_APPENDICE = 'PA'; // ??
  private readonly CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_PACCHETTO = 'PKG'; // ??
  private readonly CODICE_LIVELLO_AZIONE_POST_SELEZIONE_PACCHETTO = 'PKGP';

  constructor() {
  }

  costructWarningsRequest(level: string): WarningsRequest {
    switch (level) {
      case this.POLICY_DATA_LEVEL:
        return this.getPolicyDataWarningsRequest();
      case this.ASSETS_DATA_LEVEL:
        return this.getAssetsDataWarningsRequest();
      case this.PRE_QUOTATION_DATA_LEVEL:
        return this.getPreQuotationDataWarningsRequest();
      case this.POST_QUOTATION_DATA_LEVEL:
        return this.getPostQuotationDataWarningsRequest();
      case this.QUESTIONNAIRE_DATA_LEVEL:
        return this.getQuestionnaireDataWarningsRequest();
      case this.PROPOSAL_MANAGEMENT_DATA_LEVEL:
        return this.getProposalManagementDataWarningsRequest();
      case this.ALL_DATA_LEVEL:
        return this.getAllDataWarningsRequest();
    }
  }

  getProposalManagementDataWarningsRequest(): WarningsRequest {
    return new WarningsRequest(
      new Array<string>(
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_TECNICI,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_ATR,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_UNIT,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_CLAUSOLE,
        this.CODICE_LIVELLO_AZIONE_PRE_DATI_PRE_QUOTAZIONE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_UNIT,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_CLAUSOLE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_FATTORI_UNIT,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_FATTORI_SLAVE,
        this.CODICE_LIVELLO_AZIONE_POST_DATI_PRE_QUOTAZIONE,
        this.CODICE_LIVELLO_AZIONE_POST_QUOTAZIONE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_PACCHETTO
      )
    );
  }

  getQuestionnaireDataWarningsRequest(): WarningsRequest {
    return new WarningsRequest(
      new Array<string>(
        this.CODICE_LIVELLO_AZIONE_POST_QUESTIONARI
      )
    );
  }

  getPolicyDataWarningsRequest(): WarningsRequest {
    return new WarningsRequest(
      new Array<string>(
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_TECNICI
      )
    );
  }

  getAssetsDataWarningsRequest(): WarningsRequest {
    return new WarningsRequest(
      new Array<string>(
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_TECNICI,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_ATR,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_UNIT,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_CLAUSOLE,
        this.CODICE_LIVELLO_AZIONE_PRE_DATI_PRE_QUOTAZIONE
      )
    );
  }

  getPreQuotationDataWarningsRequest(): WarningsRequest {
    return new WarningsRequest(
      new Array<string>(
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_TECNICI,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_ATR,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_UNIT,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_CLAUSOLE,
        this.CODICE_LIVELLO_AZIONE_PRE_DATI_PRE_QUOTAZIONE
      )
    );
  }

  getPostQuotationDataWarningsRequest(): WarningsRequest {
    return new WarningsRequest(
      new Array<string>(
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_TECNICI,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_BENE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_DATI_ATR,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_UNIT,
        this.CODICE_LIVELLO_AZIONE_PRE_SELEZIONE_CLAUSOLE,
        this.CODICE_LIVELLO_AZIONE_PRE_DATI_PRE_QUOTAZIONE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_UNIT,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_CLAUSOLE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_FATTORI_UNIT,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_FATTORI_SLAVE,
        this.CODICE_LIVELLO_AZIONE_POST_DATI_PRE_QUOTAZIONE,
        this.CODICE_LIVELLO_AZIONE_POST_QUOTAZIONE,
        this.CODICE_LIVELLO_AZIONE_POST_SELEZIONE_PACCHETTO
      )
    );
  }

  getAllDataWarningsRequest(): WarningsRequest {
    return new WarningsRequest(
      new Array<string>()
    );
  }

}

import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DateValidatorUtilityService {

  constructor() {
  }

  dateComparisonValidator(controlNameDate1: string, controlNameDate2: string) {

    return (formGroup: UntypedFormGroup) => {
      const control1 = formGroup.controls[controlNameDate1];
      const control2 = formGroup.controls[controlNameDate2];


      if (control1.value && control2.value) {
        if (control1.value instanceof Date && control2.value instanceof Date) {
          if (control1.value > control2.value) {
            control1.setErrors({'RE_ISSUE.DATE1_AFTER_DATE2': true});
            control2.setErrors({'RE_ISSUE.DATE1_AFTER_DATE2': true});
          } else {
            control1.setErrors(null);
            control2.setErrors(null);
          }
        } else {
          control1.setErrors({invalidDate: true});
          control2.setErrors({invalidDate: true});
        }
      }
    };
  }

  formatDateValidator(controlNameDate: string, isValid: boolean) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlNameDate];
      if (isValid) {
        control.setErrors(null);

      } else {
        control.setErrors({invalidDate: true});
      }

    };

  }


  dateRangeValidator(controlName: string, minDate: Date, maxDate: Date) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];

      if (control.value && maxDate && minDate) {
        if (control.value instanceof Date) {
          const inputDate = control.value;
          const minD = minDate;
          const maxD = maxDate;
          inputDate.setHours(0, 0, 0, 0);
          minD.setHours(0, 0, 0, 0);
          maxD.setHours(0, 0, 0, 0);
          if (inputDate < minD || inputDate > maxD) {
            control.setErrors({invalidDate: true});
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({invalidDate: true});
        }
      }
    };
  }
}

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose, TableSchema} from '@rgi/rx/ui';
import {
  GpAuthorizationDemandInfo,
  GpAuthorizationProposalInfo,
  GpAuthorizationStatusInfo,
  GpProposalAuthorization
} from '../../../group-policy-models/group-policy-authorization';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseEntity} from '../../../group-policy-models/group-policy-issue-policy-data';
import {DatePipe} from '@angular/common';
import {Observable, of} from 'rxjs';
import {WARNING_LIST_AUTHORIZATION_TABLE_SCHEMA} from '../../../group-policy-constants/warning-list-authorization-tableschema';

@Component({
  selector: 'rgi-gp-group-policy-modal-authorization-data',
  templateUrl: './group-policy-modal-authorization-data.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyModalAuthorizationDataComponent implements OnModalClose, OnInit {

  authorizationData: GpProposalAuthorization;
  modalClose: EventEmitter<GpProposalAuthorization> = new EventEmitter<GpProposalAuthorization>();
  selectedIndex = 0;
  authorizationDataFormGroup = new UntypedFormGroup({});
  warningListTableData: Observable<any[]>;
  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  WARNING_LIST_AUTHORIZATION_TABLE_SCHEMA: TableSchema = WARNING_LIST_AUTHORIZATION_TABLE_SCHEMA;

  constructor(
    protected datePipe: DatePipe,
    @Optional() @Inject(DIALOG_DATA) data: GpProposalAuthorization) {
    this.authorizationData = data;
  }

  ngOnInit(): void {
    this.authorizationDataFormGroup.addControl('userApplicat', new UntypedFormControl(
      null,
      Validators.required
    ));
    this.authorizationDataFormGroup.addControl('priority', new UntypedFormControl(
      null,
      Validators.required
    ));

    this.authorizationDataFormGroup.addControl('note', new UntypedFormControl(
      null,
      Validators.required
    ));
    this.fillWarningListTableData();
  }

  protected fillWarningListTableData() {
    this.warningListTableData = of(this.authorizationData.warnings.map(warning => {
      return {
        warningDescription: warning.description,
        warningType: warning.type,
        warningOrigin: warning.origin,
        warningError: warning.error,
        warningBlockLevel: warning.blockLevel
      };
    }));
  }

  onConfirm() {
    this.authorizationDataFormGroup.markAllAsTouched();
    if (this.authorizationDataFormGroup.valid) {
      this.authorizationData.userApplicant = new BaseEntity(this.authorizationDataFormGroup.get('userApplicat').value , '') ;
      this.authorizationData.priority = new BaseEntity(this.authorizationDataFormGroup.get('priority').value , '') ;
      this.authorizationData.note = this.authorizationDataFormGroup.get('note').value ;
      this.modalClose.emit(this.authorizationData);
    }
  }


  onClose() {
    this.modalClose.emit();
  }

  getDemandInfo(demandInfo: GpAuthorizationDemandInfo) {
    const demandInfoEntity: BaseEntity[] = [];
    demandInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._REQUEST_DATE_',
      this.datePipe.transform(demandInfo.demandDate, 'dd/MM/yyyy')
    ));
    demandInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._DEROGATION_LEVEL_',
      demandInfo.derogationLevel)
    );
    demandInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._APPLICANT_',
      demandInfo.applicant.description)
    );
    demandInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._COMPANY_', demandInfo.company)
    );
    demandInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._NODE_', demandInfo.node.description)
    );
    return demandInfoEntity;
  }

  getStatusInfo(statusInfo: GpAuthorizationStatusInfo) {
    const statusInfoEntity: BaseEntity[] = [];
    statusInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._LAST_ACTION_',
      statusInfo.lastAction)
    );
    statusInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._COMPETENCE_LEVEL_',
      statusInfo.levelCompetent)
    );
    statusInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._LAST_ACTION_DATE_',
      this.datePipe.transform(statusInfo.lastActionDate, 'dd/MM/yyyy, h:mm:ss'))
    );
    statusInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._REQUEST_STATE_',
      statusInfo.demandStatus)
    );
    statusInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._MANUAL_NOTE_',
      statusInfo.note)
    );
    return statusInfoEntity;
  }

  getProposalInfo(proposalInfo: GpAuthorizationProposalInfo) {
    const proposalInfoEntity: BaseEntity[] = [];
    proposalInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._PRODUCT_TYPE_',
      proposalInfo.productType)
    );
    proposalInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._PRODUCT_',
      proposalInfo.productDescription)
    );
    proposalInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._POLICY_HOLDER_',
      proposalInfo.contractor)
    );
    proposalInfoEntity.push(new BaseEntity(
      '_GP_._LABEL_._PROPOSAL_DESCRIPTION_',
      proposalInfo.description)
    );
    return proposalInfoEntity;
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {tap} from 'rxjs/operators';
import {ModalService} from '@rgi/portal-ng-core';
import {MicHttpDisableErrorInterceptorService} from '../http/mic-http-disable-error-interceptor.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  eventService: any;

  constructor(private modal: ModalService,
              @Inject('eventService') eventService: any,
              @Inject('authService') protected authService: any) {
    this.eventService = eventService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        tap( () => {}, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            let message = `${err.message}`;
            let modalErrors: string[] = [];
            // getting the executionId to help searching the log
            let executionId = req.headers.get('RGI_executionId');
            executionId = !!executionId ? executionId : this.authService.executionId;
            console.log(MicHttpDisableErrorInterceptorService.isErrorInterceptorDisable(req));
            if (err.status === 400 && err.error.hasOwnProperty('messages')) {
              message = err.error.messages;
            }
            if (!(MicHttpDisableErrorInterceptorService.isErrorInterceptorDisable(req) ||
            ( err.status === 400 || err.status === 422 || err.status === 412))) {
              console.log('error interceptor disabled by request');
              modalErrors = ['A server error occurred.', message];
              if (!!executionId) {
                modalErrors.push('Error Code: ' + executionId);
              }
              this.modal.open(modalErrors, 'Error');
            }
          }
        }));
  }

}

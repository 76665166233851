<div class="card-shadow" style="display: block; overflow: auto; height: auto;">

  <rgi-rx-expansion-panel  #accordionSearch>
    <rgi-rx-expansion-panel-header >
      <div rgi-rx-expansion-panel-label>{{ '_CLAIMS_._SEARCH_FILTERS' | translate }}</div>
    </rgi-rx-expansion-panel-header>

    <form [formGroup]="searchForm" style="margin-bottom: 2vh; display: inline-block; width:100%" >
    <div class="form-group" style="width:100%; display: inline-block">
      <!-- COUNTRY -->
      <!--      entire country row is hidden if uma disabled-->
      <div class="col-xs-3"  *ngIf="isUmaUniqueKeyManagementEnabled" >
        <label ng-bind="label" >{{ '_CLAIMS_._NATION' | translate }}</label>
        <select class="core-select form-control" id="expert-search-country" name="experts-search-country" formControlName="country">
                <!-- attr.data-qa="experts-search-input"> -->
          <option value=""></option>
          <option  *ngFor="let country of this.formConfig.countries" [value]="country.code">{{country.description}}</option>
        </select>
      </div>
      <!-- SUBJECT TYPE-->
      <div class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._SUBJECT_TYPE' | translate }}</label>
        <select class="core-select form-control" id="expert-search-subjectType" name="experts-search-subjectType" formControlName="subjectType"
                attr.data-qa="experts-search-input">
          <option value=""></option>
          <option *ngFor="let type of this.formConfig.subjectTypes" [value]="type.idObject">{{type.description}}</option>
        </select>
      </div>
      <!--      EXPERT CODE-->
      <div class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._CODE' | translate }} </label>

        <input type="text" class="form-control" name="code" formControlName="expertCode"
               attr.data-qa="expertsearch-code-input">
      </div>

      <!--      BUSINESS NAME (RAGIONE SOCIALE) -->
      <div class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._BUSINESS_NAME' | translate }} </label>

        <input [attr.disabled]="!isLegalSubject ? true : null" type="text" class="form-control" name="businessName" formControlName="businessName"
               attr.data-qa="expertsearch-businessName-input">
      </div>

      <!--   NAME   -->
      <div class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._NAME' | translate }} </label>

        <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control" name="name" formControlName="name"
               attr.data-qa="expertsearch-name-input">
      </div>
      <!--   SURNAME-->
      <div class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._SURNAME' | translate }} </label>

        <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control" name="surname" formControlName="surname"
               attr.data-qa="expertsearch-surname-input">
      </div>


      <!--      a priori show CF and P.Iva fields if uma is disabled-->
      <!--      CODICE FISCALE-->
      <div *ngIf="!isUmaUniqueKeyManagementEnabled" class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._FISCAL_CODE' | translate }} </label>

        <input type="text" class="form-control" name="fiscalCode" formControlName="fiscalCode"
               attr.data-qa="expertsearch-fiscalCode-input">
      </div>
      <!--      PARTITA IVA -->
      <div *ngIf="!isUmaUniqueKeyManagementEnabled" class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._VAT_NUMBER' | translate }} </label>

        <input type="text" class="form-control" name="vatNumber" formControlName="vatNumber"
               attr.data-qa="expertsearch-vatNumber-input">
      </div>

      <!--      SHOW UMA FIELDS-->
      <!--  UMA fields shown only if enabled-->
      <div *ngIf="isUmaUniqueKeyManagementEnabled" style="all:unset;">
        <!--        loop through countries. only display currently selected country -->
        <div *ngFor="let country of formConfig.countries; let countryIndex = index;">
          <div style="all:unset;" *ngIf="searchForm.value.country===country.code">
            <!--            display the 3 optional labels, only if they are defined -->
            <!--          ngIf : row is shown only if the label has a value  -->
            <!--            formControlName is a combination of COUNTRYCODE + G (legal or physical subject) + CKEY_NUMBER -->
            <!--            CKEY 1-->
            <div class="col-xs-3" *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey1 !==''">
              <label   ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1']['labelCkey1']}} </label>
              <input type="text" class="form-control" name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey1' }}" formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}"
                     attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}-input" >
            </div>
            <!--            CKEY 2-->
            <div class="col-xs-3" *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2 !==''">
              <label   ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2}} </label>
              <input type="text" class="form-control" name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey2' }}" formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}"
                     attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}-input">
            </div>
            <!--            CKEY 3-->
            <div class="col-xs-3" *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3 !==''">
              <label   ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3}} </label>
              <input type="text" class="form-control" name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey3' }}" formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}"
                     attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}-input">
            </div>
          </div>
        </div>
      </div>

      <!--      EXPERT TYPE (TIPO FIDUCIARIO) -->
      <div class="col-xs-3">
        <label ng-bind="label">{{ '_CLAIMS_._EXPERT_TYPE' | translate }}</label>
        <select rgiRxNativeSelect class="core-select form-control" id="expert-search-expertType" name="experts-search-expertType" formControlName="expertType"
                attr.data-qa="experts-search-expertType-input">
          <option value=""></option>
          <option *ngFor="let type of this.formConfig.expertTypes" [value]="type.idObject">{{type.description}}</option>
        </select>
      </div>

        <div class="col-xs-12" style="margin-top:1vh; margin-bottom: 1vh;">
            <span *ngIf="showError" class="col-sm-12 content-error validate-error" attr.data-qa="expert-search-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{ '_CLAIMS_._MESSAGE_._VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT' | translate }}</span>
        </span>
          <span *ngIf="showNoExpertsFound" class="col-sm-12 content-error validate-error"
                attr.data-qa="expert-search-not-found">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{ '_CLAIMS_._NO_RESULT' | translate }}</span>
        </span>
          <span *ngIf="!showError && !showNoExpertsFound"><br/>&nbsp;</span>
        </div>



        <div class="btn-group btn-group-justified" >
          <div class="btn-group" >
            <button class="rgi-ui-btn rgi-ui-btn-primary" style="width: 100%;"   (click)="onEmpty()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
          </div>
          <div class="btn-group">
            <button class="rgi-ui-btn rgi-ui-btn-primary" style="width: 100%;"  (click)="onFind()" >{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
          </div>
        </div>
    </div>
  </form>

  </rgi-rx-expansion-panel>
<!-- </rgi-rx-expansion-panel> -->
  <div>
<!--  EXPERTS LIST-->
  <table class="table-responsive table coreDT" style="margin-top:5vh;"
         *ngIf="this.expertList.length > 0">
    <thead >
    <tr class="head-result thDataTable">
      <td style="max-width: 10%;">
        <span attr.data-qa="autosoft-claim-label">{{ '_CLAIMS_._CODE' | translate }}</span>
      </td>
      <td style="max-width: 40%;">
        <span attr.data-qa="autosoft-AssNumber-label">{{ '_CLAIMS_._DENOMINATION' | translate }}</span>
      </td>
      <td style="width: 30%;">
        <span attr.data-qa="autosoft-reqDate-label">{{ '_CLAIMS_._SUBJECT_IDENTITY' | translate }}</span>
      </td>
      <td style="max-width: 10%;">
        <span attr.data-qa="autosoft-idReq-label">{{ '_CLAIMS_._EXPERT_TYPE' | translate }}</span>
      </td>
      <td style="max-width: 10%;">
        <span attr.data-qa="autosoft-respErr-label">{{ '_CLAIMS_._TYPE_PROVIDER' | translate }}</span>
      </td>
      <td style="max-width: 5%;">
        <span><br/></span>
      </td>
    </tr>
    </thead>

    <tbody style="overflow: auto;">
    <tr *ngFor="let aRow of this.expertList;
                    let even = even;
                    let odd = odd;
                    let expertIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
        class="table-row" attr.data-qa="experts-result-{{expertIndex}}">

      <td style="width:10%;">
        {{aRow.code}}
      </td>
      <td style="width:40%;">
        {{aRow.nameBySubjectType}}
      </td>
      <!-- <td style="width:10%;">
        {{aRow.surname}}
      </td> -->
      <td style="width:30%;">
        {{aRow.subjectKeyWithDescription}}
      </td>
      <td style="width:10%;">
        {{aRow.expertTypeDescription}}
      </td>
      <td style="width:10%;">
        {{aRow.expertTypeProviderDescription}}
      </td>
      <td style="width: 5%;">
        <button  (click)="onHandleModalExpertDetail(aRow.id, aRow)"><span class="action-icon rgifont rgi-chevron-right" style="width:10px; height:10px;"></span></button>
      </td>
    </tr>
    </tbody>
  </table>
  </div>
</div>


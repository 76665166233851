import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DocumentPrint} from '../../models/api-models/document-print';
import {RgiRxLogger} from '@rgi/rx';
import {Ie11UtilityService} from '../re-issue-utility/ie-11-utility.service';

@Component({
  selector: 're-issue-print-pdf',
  templateUrl: './re-issue-print-pdf.component.html',
  styleUrls: ['./re-issue-print-pdf.component.css']
})
export class ReIssuePrintPdfComponent implements OnInit {
  fileUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    protected logger: RgiRxLogger,
    protected ie11Utility: Ie11UtilityService
  ) {
  }

  ngOnInit() {
  }

  @Input()
  set document(doc: DocumentPrint) {
    if (doc) {
      this.logger.debug('ReIssuePrintPdfComponent::document() - doc.contratto', doc.contratto);
      // if (this.ie11Utility.isIE11()) {  // ie11 non supportato da Angular 13
      //   this.ie11Utility.printIE11File(
      //     this.b64toBlob(
      //       doc.sampaPdf,
      //       'application/pdf'
      //     ));
      // } else {
        this.createDocument(doc);
      // }
    } else {
      this.fileUrl = null;
    }
  }

  createDocument(doc: DocumentPrint) {
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.b64toBlob(doc.sampaPdf,
      'application/pdf')) + '#toolbar=0&navpanes=0&scrollbar=0');
  }

  b64toBlob(b64Data: string, contentType: string): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

}

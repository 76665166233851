import {RgiRxEventService} from '@rgi/rx';
import {ActiveRoute, RgiRxRouteData} from '@rgi/rx/router';
import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {of} from 'rxjs';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AnagStatelessOpResultService} from './anag-stateless-op-result.service';
import {
  KEY_DATA_UPDATE,
  KeyDataUpdateEvent,
  NewPartyEvent,
  NewPartyEventInstance,
  SubjectSelectedEvent,
  SubjectSelectedEventInstance
} from '../../anag-events/anag-event';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagStateManagerKeyData} from '../key-data/anag-state-manager-key-data';
import {
  KeyDataModalComponent
} from '../../anag-components/party-create-key-data/key-data-modal/key-data-modal.component';
import {take} from 'rxjs/operators';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {ModalService} from '@rgi/rx/ui';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagFlowData} from '../../anag-model/anag-domain/anag-flow-data';


export class AnagStateResult extends State {
  parties: Array<AnagApiParty>;
  serviceError: string;
  isModal = false;
  party: AnagApiParty;
  canCreateParty: boolean;
  canEditParty: boolean;
  totalCounterParties: string;
  redirectToMainFlow = false;
  flowData: AnagFlowData;
  isUmaPartyHomonymyEnable: boolean;
  isBackFromHomonymy: boolean;
  isUmaPartyCFDuplicate: boolean;
  isUmaPartyGroupCompanies: boolean;
  showCounterTerrorism: boolean;
}

export class AnagStateManagerResult extends RgiRxLocalStateManager<AnagStateResult> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelessOps: AnagStatelessOpResultService,
    protected eventService: RgiRxEventService,
    protected anagStorage: AnagStorageService,
    protected anagIntegrationService: AnagIntegrationService,
    protected modalService: ModalService,
    protected statelessService: AnagStatelessService,
    protected i18nService: RgiRxTranslationService
  ) {
    super(activeRoute, stateStoreService);
    this.initResults(activeRoute.getRouteData<AnagRouteData>());
  }

  initResults(routeData: RgiRxRouteData<AnagRouteData>) {
    const newResultState = new AnagStateResult(this.activeRoute.key);
    const initResultState$ = this.statelessOps.initResults$(of(newResultState), routeData);
    this.updateState$(initResultState$);
  }

  datatableActionSelectSubject(row, name, stateMgrKeyData) {
    if (row.isModal) {
      this.eventService.emit<SubjectSelectedEvent>(
        new SubjectSelectedEventInstance(
          this.getCurrentState().parties.find(party => {
            return party.objectId === row.objectId;
          })
        )
      );
    } else {
      this.editOrSelectSubject(row, name, stateMgrKeyData);
    }
  }

  goToHomePage() {
    this.statelessOps.goToHomePage(this.activeRoute);
  }

  editOrSelectSubject(row, name, stateMgrKeyData) {
    const currentState = this.getCurrentState();
    this.updateState$(this.statelessOps.findPartyAndGoTo$(of(currentState), this.activeRoute, row, name, stateMgrKeyData));
  }

  actionCreateParty(stateMgrKeyData: AnagStateManagerKeyData) {
    const currentState = this.getCurrentState();
    if (currentState.isModal) {
      this.eventService.emit<NewPartyEvent>(new NewPartyEventInstance({
        action: 'keyData',
        party: this.statelessService.deepClone(currentState.party),
        lastAction: 'resultFlow',
        isModalFlow: true,
        flowData: currentState.flowData,
        redirectToMainFlow: currentState.redirectToMainFlow,
        parties: currentState.parties
      }));
    } else {
      const keyDataModal = this.modalService.openComponent(KeyDataModalComponent, currentState.party, [
        {
          provide: AnagStateManagerKeyData,
          useValue: stateMgrKeyData
        }
      ]);
      keyDataModal.modal.enableClickBackground = false;
      keyDataModal.modal.onClose.asObservable().pipe(take(1)).subscribe(resp => {
        currentState.isBackFromHomonymy = false;
      });
      this.eventService.listen<KeyDataUpdateEvent>(KEY_DATA_UPDATE).pipe(take(1)).subscribe(eventInstance => {
        this.anagIntegrationService.openEditSubject(eventInstance.event);
      });
    }
  }

  actionNewParty(party: any) {
    const currState = this.getCurrentState();
    const routeData = new AnagRouteData();
    routeData.party = this.statelessService.deepClone(party);
    routeData.action = 'newParty';
    routeData.lastAction = 'keyData';
    // routeData.configuration = currState.partyConfiguration;
    routeData.redirectToMainFlow = currState.redirectToMainFlow;
    routeData.flowData = currState.flowData;
    this.anagIntegrationService.openEditSubject(routeData);
  }

  setTitle() {
    this.i18nService.translate('_ANAG_TITLE_SEARCH_RESULTS_').subscribe(title => {
      this.anagIntegrationService.changeTitle(this.activeRoute.id, title);
    }).unsubscribe();
  }

  isCounterTerrorismVisible(): boolean {
    const currState = this.getCurrentState();
    if (currState.showCounterTerrorism) {
      return true;
    }
    return false;
  }
}

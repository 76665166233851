import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { AuthService } from '../../services/auth.service';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { AnagService } from '../../services/anag.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import {
  BeneficiaryDefinition, BeneficiaryRole, Definition, FactorDefinition,
  OperationBeneficiaryData, PaymentTypeDefinition, PostsalesError, PostsalesOperationObject, Role
} from '../../models/postsales-operations-response.model';
import { RequestFactor } from '../../models/factor.model';
import { tap } from 'rxjs/operators';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';

@Component({
  selector: 'lpc-early-total-surrender',
  templateUrl: './early-total-surrender.component.html',
  styleUrls: ['./early-total-surrender.component.css']
})
export class EarlyTotalSurrenderComponent extends AbsOperationComponent implements OnInit {
  protected operationDataKey = 'early-total-surrender';
  public listProductFactor: FactorDefinition[] = [];
  public requestFactor: RequestFactor[] = [];
  private $paymentTypes: PaymentTypeDefinition[] = [];
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  private _beneficiaryData: OperationBeneficiaryData[];
  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];
  protected anagSubj = null;
  public isMandatoryBeneficiaries = false;

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return '';
    }
  }

  get beneficiaryType(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  public get hasProductFactors(): boolean {
    return !!this.listProductFactor.length;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      beneficiaries: new UntypedFormControl([])
    });
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anagService: AnagService
  ) {
    super(
      operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anagService
    );
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {
          LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
          this.insuredSettlements = result.data.operationData.data.insuredSettlements;
          this.tableDefinitions = result.definitions.settlement as Definition[];
          this.listProductFactor = PlcObjectUtils.asValidArray(result.definitions.productFactors as FactorDefinition[]);
          if (result.definitions.beneficiaries != null) {
            this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
          }
          this._beneficiaryData = result.data.operationData.data.beneficiaries;
          if (!!this._beneficiaryData) {
            this.formGroup.get('beneficiaries').setValue(
              (this._beneficiaryData as any[]).map(beneficiary => {
                return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
              }),
              {emitEvent: false}
            );
          }
        })
      ).subscribe()
    );
  }

  updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe((result) => {
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    }));
  }

  onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, '', this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, '', this);
        })
      );
    }
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

  protected getTransformedOperationData(): any {
      const obj: any = {
        beneficiaries: this.formGroup.getRawValue().beneficiaries
          .map(claimBeneficiary => {
            return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
          })
      };
      if (this.requestFactor.length > 0) {
        obj.listProductFactor = this.requestFactor;
      }
      obj.mandatoryBeneficiaries = this.isMandatoryBeneficiaries;
      return obj;
  }

  public updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload);
    this.insuredSettlements = result.data.operationData.data.insuredSettlements;
    this.tableDefinitions = result.definitions.settlement as Definition[];
    this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
    this.isMandatoryBeneficiaries = result.data.operationData.data.mandatoryBeneficiaries;
    this.blockBeneficiaries = result.data.operationData.data.blockBeneficiaries;
    // eslint-disable-next-line max-len
    this.beneficiariesDefinitions = result.definitions.beneficiaries && (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    this.formGroup.get('beneficiaries').setValue(
      this.getClaimBeneficiaries(result),
      { emitEvent: false }
    );
  }

  getClaimBeneficiaries(result: any) {
    if (result.data.operationData.data.beneficiaries) {
      return (result.data.operationData.data.beneficiaries as any[]).map(beneficiary => {
        return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
      });
    }
    return [];
  }

}


  <a class="analytics-reports-btn" (click)="onOpenDashboard(template)">
    <div class="text-left-dark">{{template.description}}</div>
  </a>
  <div class="icon-right">
    <span *ngIf="isAdminFunctionEnabled" class="analytics-home-icon rgi-ui-icon-edit2" title="Modifica" (click)="onEditReport(template)"></span>
    <span *ngIf="isAdminFunctionEnabled" class="analytics-home-icon rgi-ui-icon-duplicate" title="Duplica" (click)="onCopyReportModal(template)"></span>
    <span *ngIf="isAdminFunctionEnabled" class="analytics-home-icon rgi-ui-icon-delete" title="Elimina" (click)="onDeleteReportModal(template)"></span>
    <span class="analytics-home-icon rgi-ui-icon-right-arrow" title="Consulta" (click)="onOpenDashboard(template)"></span>
  </div>

import {FinancialService} from './../../../services/financial.service';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, finalize, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { EMPTY_STR, JS_EVENT } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-financial-list',
  templateUrl: './financial-list.component.html',
  styleUrls: ['./financial-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinancialListComponent implements OnInit {

  @Output() eventPropagation = new EventEmitter<any>();
  @Input() data: any;

  private $elements: any[];
  private filter: string;
  public pageSizes = [10, 20, 50];
  public pageSize = 10;
  public page = 1;
  public count = 0;

  public get elements(): any [] {
    return this.$elements;
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(),
    count: new UntypedFormControl(this.pageSize)
  });

  constructor(
    private financialService: FinancialService,
    private translateService: TranslationWrapperService
    ) { }

  ngOnInit() {
    this.retrieveData().subscribe();
    this.eventPropagation.emit({
      eventName: JS_EVENT.SET_TITLE,
      title: this.translateService.getImmediate(this.data.info.type === 'fund' ? 'lpc_investment_funds' : 'lpc_Investment_profiles')
    });
    this.formGroup.get('search').valueChanges.pipe(
      debounceTime(850),
      distinctUntilChanged(),
      switchMap((value) => this.retrieveData(value)),
    ).subscribe();
  }

  retrieveData(name?: string): Observable<any> {
    this.filter = name;
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    const observable: Observable<any> = !!this.isFundList() ?
    this.getFundList(this.page.toString(),
    this.pageSize.toString(),
    this.data.info.params.isinCode,
    !!this.filter ? this.filter : this.data.info.params.fundName,
    !!this.filter ? EMPTY_STR : this.data.info.params.fundCode
    ) : this.getProfileList(this.page.toString(),
    this.pageSize.toString(), this.data.info.params.productId, !!this.filter ? this.filter : this.data.info.params.profileName);
    return observable.pipe(
      tap( result => {
        this.$elements = result.data;
        this.count = result.pagination.total;
      }), finalize(() => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      })
    );

  }

  getFundList(page: string, perPage: string, isinCode: string, fundName: string, fundCode: string): Observable<any> {
    return this.financialService.getFundDetailsByQueryParams(
      page, perPage, isinCode ? isinCode : null, fundName ? fundName : null, fundCode ? fundCode : null);
  }

  getProfileList(page: string, perPage: string, productId: string, profileName: string): Observable<any> {
    return this.financialService.getProfilesByQueryParams(
      page, perPage, profileName ? profileName : null, productId ? productId : null);
  }

  openSession(element: any) {
    if (!!this.isFundList()) {
      const compose = {
        element,
        gs: (element.fundTypeId === '4')
      };
      this.eventPropagation.emit({eventName: JS_EVENT.OPEN_FUNDS_DETAIL, compose});
    } else {
      this.eventPropagation.emit({eventName: JS_EVENT.OPEN_PROFILE_DETAIL, idCrypt: element.id});
    }
  }

  public isFundList(): boolean {
    return this.data.info.type === 'fund';
  }

  public handlePageChange(event) {
    this.page = event;
    this.retrieveData().subscribe();
  }

  handlePageSizeChange(event) {
    this.pageSize = event;
    this.page = 1;
    this.retrieveData().subscribe();
  }

  cancel() {
    this.eventPropagation.emit({ eventName: JS_EVENT.REMOVE_CURRENT_SESSION });
  }
}

import {PolicyDate} from './policy-date';
import {Constraint} from './constraint';
import {Adjustment} from './adjustement';
import {Notes} from './notes';
import {CommissionsRegime} from './commissions-regime';
import {TaxTypeContainer} from './tax-type-container';
import {AgreementsContainer} from './agreements-container';
import {PaymentFrequencyContainer} from './payment-frequency-container';
import {NotDrivingDeclaration} from './notdriving';
import {TacitRenewal} from './tacitRenewal';
import {PolicyLPSData} from './policy-lps-data';
import {CoassContainer} from './coass-container';
import {PolicyText} from '../policy-text';

export class PolicyTechnicalData {

  constructor(
    public paymentFrequencyContainer: PaymentFrequencyContainer,
    public effectiveDate: PolicyDate,
    public exipreDate: PolicyDate,
    public issueDate: PolicyDate,
    public policyTime: PolicyText,
    public policyExpiryTime: PolicyText,
    public agreementContainer: AgreementsContainer,
    public taxTypeContainer: TaxTypeContainer,
    public constraint: Constraint,
    public adjustment: Adjustment,
    public notes: Notes,
    public contractAdjustment: boolean,
    public contractContraint: boolean,
    public contractNotDrivingDeclaration: boolean,
    public commissionsRegime: CommissionsRegime,
    public notDrivingDeclaration: NotDrivingDeclaration,
    public reloadVariables: boolean,
    public recalculate: boolean,
    public errorMessages: Array<string>,
    public warningMessages: Array<string>,
    public reloadInsuranceStatus: boolean,
    public arrearsDays: number,
    public tacitRenewal: TacitRenewal,
    public policyLps: boolean,
    public policyLPSData: PolicyLPSData,
    public coassContainer: CoassContainer
  ) {
  }
}

<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">{{clauseData.extendedDescription}}</rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose.emit()" data-qa="rgi-gp-modal-close" translate>
      _GP_._BTN_._CLOSE_
    </button>
  </rgi-rx-panel-footer>
  <ng-container>
    <div>
      <ng-container *ngIf="clauseData.initialState">
        <rgi-gp-generic-detail [details]="getClauseDetails()"  [translateLabel]="true">
        </rgi-gp-generic-detail>
      </ng-container>

      <div *ngIf="clauseData.textType === 'NONE'" class="rgi-gp-mt-m rgi-gp-w-100">
        <label class="rgi-ui-title-3"><span translate>{{getTextAreaLabel()}}</span></label>
        <p>{{clauseData.text}}</p>
      </div>

      <div *ngIf="clauseData.textType === 'HTML'" class="rgi-gp-mt-m rgi-gp-w-100">
        <label class="rgi-ui-title-3"><span translate>{{getTextAreaLabel()}}</span></label>
        <p [innerHTML]="clauseData.text" ></p>
      </div>
    </div>
  </ng-container>
</rgi-rx-panel>

<div *ngIf="quest.mandatory && !quest.compiled" class="questionnaire-block-white col-xs-1">
  <div class="col-xs-12">
    <span class="rgifont font-survey top-margin-ico rgi-survey_ko font-survey-ko" data-qa="questNotCompiled"></span>
  </div>
</div>
<div *ngIf="!quest.mandatory && !quest.compiled" class="questionnaire-block-white col-xs-1">
  <div class="col-xs-12">
    <span class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-orange"></span>
  </div>
</div>
<div *ngIf="quest.compiled" class="questionnaire-block-white col-xs-1" data-qa="questCompiled">
  <div class="col-xs-12">
    <span class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok"></span>
  </div>
</div>
<div class="col-xs-11">
  <div class="col-xs-12">
    <div class="col-xs-1 custom-arrow-left"></div>
    <div class="col-xs-11 large questionnaire-block">
      <div class="center-questionarie-block">
        <div class="col-xs-10">
          <div class="block-with-text" data-qa="questName">{{ quest.name }}</div>
        </div>
        <div class="col-xs-2">
          <div class="to-right">
            <button type="button" class="btn btn-default btn-address btn-xs" id="questionarieIcon"
              (click)="selectQuestionnaire()" data-qa="mic-button-file-holder" id="id{{ quest.name }}">
              <span class="rgifont rgi-pencil-square-o"></span>
            </button>
            <button *ngIf="quest.compiled" [disabled]="isReadOnly" type="button" class="btn btn-default btn-address btn-xs empty" id="questionarieIcon"
              (click)="emptyQuestionnaire()" data-qa="mic-button-trash-holder">
              <span class="rgifont rgi-trash"></span>
            </button>
          </div>
        </div>


      <dq-loader-mobile [loader]="loader"></dq-loader-mobile>
      </div>
    </div>
  </div>
</div>

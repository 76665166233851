import {AnagApiEntity, AnagApiParty, AnagEntityIta} from '@rgi/anag';

/**
 * @description
 * Objects redifined on iiab-portal-ext of rgi/anag classes
 */
export class AnagApiServiceInfo {
  public executionId: string;
  public logs: string;
  public codiceErroreOccorso: string;
  public tipoErroreOccorso: string;
  public descrizioneErroreOccorso: string;
  public dettagliSullErroreOccorso: string;
  public eccezioneOccorsaSerializzata: string;
}

export class AnagApiResponse {
  public serviceInfo: AnagApiServiceInfo;
  public error: any;
}

export class AnagApiCreateSubject extends AnagApiResponse {
  public subject: AnagApiParty;

  constructor(party: AnagApiParty) {
    super();
    this.subject = party;
  }
}

export class AnagApiSaveSubjectResponse extends AnagApiCreateSubject {
  idSubject: string;
  outcome: Array<any>;
}


export class AnagIdentificationEntity extends AnagApiEntity {
  public identification: string | null;

  constructor(code, description, id) {
    super(code, description);
    this.identification = id;
  }
}


export class AnagPrivacyConsensus {
  privacy: AnagIdentificationEntity;
  privacyConsensusValue: AnagEntityIta;
  privacyConsentDate: Date;
  privacyConsentOrigin: string;
  privacyConsentOriginDescription: string;

  constructor(privacy, privacyConsensusValue, privacyConsentDate, privacyConsentOrigin, privacyConsentOriginDescription) {
    this.privacy = privacy;
    this.privacyConsensusValue = privacyConsensusValue;
    this.privacyConsentDate = privacyConsentDate;
    this.privacyConsentOrigin = privacyConsentOrigin;
    this.privacyConsentOriginDescription = privacyConsentOriginDescription;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Activityconstants {
    public TASK_ID_NO_BPM = 'NO_BPM_ONLY_ACTIVITY';
    public INSTANCE_ID_NO_BPM = 'NO_BPM_ONLY_ACTIVITY';
}


export interface VolatilityResponse {
  volatility: number;
  errors: VolatilityError[];
}

export interface VolatilityError {
  errorCode: string;
  errorDescription: string;
  errorType: string;
}

export enum VolatilityErrorType {
  BLOCKING = '0',
  WARNING = '1'
}

export enum VolatilityType {
  premiumDemand = 'premiumDemand',
  surrenderDemand = 'surrenderDemand',
  switchDemand = 'switchDemand',
  changeCombination = 'changeCombinationDemand'
}

import {downgradeInjectable} from "@angular/upgrade/static";
import { QuestionnaireResourcesService } from '@rgi/ng-passpropro/questionnaire';
import {QuestionnaireService} from "@rgi/ng-passpropro/questionnaire";

declare var angular: angular.IAngularStatic;

export function downgrade() {

  let module = angular.module("app");

  module.factory('QuestionnaireServiceAJS', downgradeInjectable(QuestionnaireService));
  module.factory('QuestionnaireResourcesServiceAJS', downgradeInjectable(QuestionnaireResourcesService));
}

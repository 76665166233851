import {ActiveRoute} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {of} from 'rxjs';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {ErrorCode, Factor} from '../../group-policy-models/group-policy-issue-policy-data';
import {GroupPolicyStatelessOpConfigurationPmService} from './group-policy-stateless-op-configuration-pm.service';
import {GroupPolicyStateConfigurationPm} from '../group-policy-state';


/**
 * @author: dmasone
 * @description: Component used for configuration pm state manager
 */
export class GroupPolicyStatemanagerConfigurationPm extends AbstractStateManager<GroupPolicyStateConfigurationPm> {

    constructor(activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: GroupPolicyStatelessOpConfigurationPmService) {
        super(activeRoute, stateStoreService);
        const stateConfigurationPmData = this.stateStoreService.has(this.activeRoute.key) ?
            this.stateStoreService.get<GroupPolicyStateConfigurationPm>(this.activeRoute.key) :
            new GroupPolicyStateConfigurationPm(this.activeRoute.key);
        const initial$ = this.statelesOps.initConfigurationPmData$(of(stateConfigurationPmData), this.activeRoute);
        this.updateState$(initial$);
    }

    public actionUpdateFactors(data: DataForSteps, factors: Factor[]) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.updateFactors$(of(st), data.resId, factors));
    }

    public actionBack(previousStepData: DataForSteps, activeRoute: ActiveRoute, targetRoute: string) {
      const st = this.getCurrentState();
      this.updateState$(this.statelesOps.actionBack$(of(st), previousStepData, activeRoute, targetRoute));
    }

    public actionSave(previousStepData: DataForSteps) {
        const st = this.getCurrentState();
        st.errors = [];
        st.type = ErrorCode.BLOCKING;
      this.updateState$(this.statelesOps.actionSave$(of(st), previousStepData.resId, previousStepData.node, this.activeRoute));
    }

    public actionGoToGuarantees(previousStepData: DataForSteps) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionGoToGuarantees$(of(st), previousStepData, this.activeRoute));
    }

}

import {GenericEntity} from './generic-entity';

export class Variable extends GenericEntity {

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public identificationCode: string,
    public visible: boolean,
    public compulsory: boolean,
    public dependent: boolean,
    public transcoderCode1: string,
    public transcoderCode2: string,
    public value: string,
    public valueDescription: string,
    // 0: Lista, 1: Numeric, 2: Numeric (verificare), 3: Boolean (Si/No), 4: Date, 5: String
    public type: number,
    public order: number,
    public variablesValue: Array<GenericEntity>,
    public extendedDescription: string,
    // 1 - Asset Area
    // 2 - Party Area (Anagrafica)
    // 3 - Extra Area
    // 4 - Vehicle Data Area
    // 5 - Insurance Background Area
    public visibilityArea: number,
    // public provinceList: boolean,
    // public cityList: boolean,
    public editableInView: boolean,
    public variableClass: number,
    public dummyValue: string,
    public editable: boolean,
    public helpFile: string,
    public valueMin: string,
    public valueMax: string
  ) {

    super(id, code, description);
  }

}

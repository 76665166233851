<template #quotationComparisonModalOverlay></template>
<form [formGroup]="partiesControlForm">

  <!-- Subscriber -->
  <div class="form-group">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <label for="subcriber" translate>Subscriber</label>
        <pnc-required required="true"></pnc-required>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-11">
        <div class="form-group btn-group btn-group-justified" style="margin-top: 0">
          <div class="nmf_cont_roles">
            <input #subcriber (click)="searchSubscriber()" [ngClass]="{'disabled': !isPartyMngEnabled || !parties?.subscriberDeletable}"
                   [value]="parties?.subscriber?.nominative" class="btn btn-start-node form-control mic-input mic-plateNumber disabled"
                   data-qa="SUBSCRIBER" formControlName="subscriber" placeholder="Select"
                   type="value">
            <div class="drop-item-delete">
            <button (click)="goToEditParty(parties?.subscriber)" *ngIf="anagPartyEditEnabled && isPartyMngEnabled && parties?.subscriber" class="drag-del-item mr-1" type="button">
              <span class="glyphicon glyphicon-pencil"></span>
            </button>
            <button (click)="cleanSubscriber()" *ngIf="parties?.subscriber && isPartyMngEnabled && !keepSubscriber && parties?.subscriberDeletable && isPartyDeletable"
                    class="drag-del-item" type="button">
              <span class="glyphicon glyphicon-trash"></span>
            </button>
            </div>
            <div class="rgi-ui-text-4 rgi-ui-info pt-2" *ngIf="parties?.subscriber?.automaticallyUpdated">
              <span class="rgi-ui-icon-info"></span><span translate>Party automatically updated</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12 nmf-parties-checkbox-wrapper">
        <div class="row">
          <!-- Owner equals subscriber checkbox -->
          <div *ngIf="getRoleData(roleTypesConstants.ROLE_OWNER)?.max > 0" class="nmf-owner-equals-subscriber nmf-parties-checkbox">
            <div (click)="manageRoleEqualsSubscriberOnCheckboxClick(roleTypesConstants.ROLE_OWNER)"
                 [attr.data-qa]="'owner_equals_subscriber'" class="btn btn-checkbox" formControlName="ownerEqualsSubscriber"
                 ngDefaultControl type="checkbox" [ngClass]="{'disabled': isRoleEqualsSubscriberCheckboxDisabled(roleTypesConstants.ROLE_OWNER)}">
                  <span [ngClass]="{'glyphicon-ok': partiesControlForm.controls.ownerEqualsSubscriber.value === true }" class="glyphicon" style="color: #f68020;">
                  </span>
            </div>
            <label translate>Vehicle owner</label>
          </div>
          <!-- Driver equals subscriber checkbox -->
          <div *ngIf="getRoleData(roleTypesConstants.ROLE_DRIVER)?.max > 0" class="nmf-driver-equals-subscriber nmf-parties-checkbox">
            <div (click)="manageRoleEqualsSubscriberOnCheckboxClick(roleTypesConstants.ROLE_DRIVER)"
                 [attr.data-qa]="'driver_equals_subscriber'" class="btn btn-checkbox" formControlName="driverEqualsSubscriber"
                 ngDefaultControl type="checkbox"
                 [ngClass]="{'disabled': isRoleEqualsSubscriberCheckboxDisabled(roleTypesConstants.ROLE_DRIVER) || isSubscriberValuedWithLegalEntityPersonType()}">
                  <span [ngClass]="{'glyphicon-ok': partiesControlForm.controls.driverEqualsSubscriber.value === true }" class="glyphicon" style="color: #f68020;">
                  </span>
            </div>
            <label translate>Driver</label>
          </div>
        </div>
        <ng-container *ngIf="parties?.subscriber">
          <div class="row nmf-max-role-parties-reached"
               *ngIf="hasRoleReachedMaxAndRoleEqualsSubscriberIsUnchecked(roleTypesConstants.ROLE_OWNER)">
            <span class="rgi-nmf rgi-nmf-info"></span>
            <span translate>Maximum number of vehicle owners reached</span>
          </div>
          <div class="row nmf-max-role-parties-reached"
               *ngIf="hasRoleReachedMaxAndRoleEqualsSubscriberIsUnchecked(roleTypesConstants.ROLE_DRIVER) && !isSubscriberValuedWithLegalEntityPersonType()">
            <span class="rgi-nmf rgi-nmf-info"></span>
            <span translate>Maximum number of drivers reached</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Other roles -->
  <div *ngFor="let role of parties?.roles; let i=index" class="form-group">
    <div *ngIf="role.max > 0">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label><span translate>{{role?.description}}</span></label>
        </div>
        <div *ngIf="role?.partyRoles?.length >1" class="col-sm-6 col-xs-12">
          <label><span translate>Main</span></label>
        </div>

      </div>
      <div *ngFor="let partyRole of role?.partyRoles; let y=index" class="row">
        <div class="col-sm-6 col-xs-11">
          <div class="form-group btn-group btn-group-justified" style="margin-top: 0">
            <div class="nmf_cont_roles">
              <input (click)="searchPartyRole(role, partyRole.party)" [attr.data-qa]="role.code"
                     [ngClass]="{'disabled': !isFieldEnabled(role.code) || !partyRole.deletable}"
                     [value]="partyRole.party?.nominative" class="btn btn-start-node form-control mic-input mic-plateNumber"
                     placeholder="Select" type="value"
                     [disabled]="!isFieldEnabled(role.code)">

              <div class="drop-item-delete">
                <button (click)="goToEditParty(partyRole.party, role)" *ngIf="anagPartyEditEnabled && isFieldEnabled(role.code) && partyRole.party?.nominative" class="drag-del-item mr-1" type="button">
                  <span class="glyphicon glyphicon-pencil"></span>
                </button>
                <button (click)="cleanPartyRole(role, partyRole.party)" *ngIf="isFieldEnabled(role.code) && partyRole.party?.nominative && partyRole.deletable && isPartyDeletable" class="drag-del-item"
                        type="button">
                  <span class="glyphicon glyphicon-trash"></span>
                </button>
              </div>
            </div>
            <div class="rgi-ui-text-4 rgi-ui-info pt-2" *ngIf="partyRole?.party?.automaticallyUpdated">
              <span class="rgi-ui-icon-info"></span><span translate>Party automatically updated</span>
            </div>
          </div>
        </div>
        <div class="col-sm-1 col-xs-1" *ngIf="role?.partyRoles?.length > 1">
          <input (click)="selectMainParty(role.code, partyRole)" [checked]="partyRole.main" type="radio"/>
        </div>
        <!-- Leasing institute -->
        <div class="col-sm-5 col-xs-12 nmf-parties-checkbox-wrapper">
          <div *ngIf="role.code === roleTypesConstants.ROLE_OWNER && y === 0" class="nmf-content-leasing nmf-parties-checkbox">
            <div (click)="changeLeasingInstitute()" [attr.data-qa]="'leasing_'+role.code" class="btn btn-checkbox" formControlName="leasingInstitute"
                 ngDefaultControl type="checkbox"
                 [ngClass]="{'disabled': !isFieldEnabled(role.code)}">
              <span [ngClass]="{'glyphicon-ok': partiesControlForm.controls.leasingInstitute.value === true }" class="glyphicon" style="color: #f68020;">
              </span>
            </div>
            <label translate>Leasing institute</label>
          </div>
        </div>
      </div>

      <!-- Initial selection -->
      <div class="row">
        <div *ngIf="!role?.partyRoles?.length" class="col-sm-6 col-xs-12">
          <div class="form-group btn-group btn-group-justified" style="margin-top: 0px;">
            <div>
              <input (click)="addRole(role)" [attr.data-qa]="'select_'+role.code" class="btn btn-start-node form-control mic-input mic-plateNumber"
                     placeholder="Select" type="value" [disabled]="!isFieldEnabled(role.code)">
            </div>
          </div>
        </div>
      </div>

      <!-- There is always at least one element to select a partyRole.
      I show the button only from the second insertable partyRole -->
      <div *ngIf="role.max >= role.partyRoles?.length + 1 && role.partyRoles?.length>0 && isFieldEnabled(role.code)" class="row">
        <div class="col-sm-6 col-xs-12">
          <button (click)="addRole(role)" [attr.data-qa]="'add_'+role.code" class="btn btn-default nmf_button_add"
                   type="button">{{'Add another' | translate}} {{role.description | translate | lowercase}}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

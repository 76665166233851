import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';


@Injectable()
/**
 * @deprecated this is already provided by @rgi/rx/portal and should be removed
 */
export class AuthInterceptor implements HttpInterceptor {
  coreAuthService: any;
  authService: any;

  constructor(
    @Inject('coreAuthService') coreAuthService: any,
    @Inject('authService') authService: any) {
    this.coreAuthService = coreAuthService;
    this.authService = authService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.coreAuthService.getToken();
    const operator = this.authService.getOperator();
    const executionId = this.coreAuthService.getExecutionId();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    let authReq = null;


    if (operator.liquidationCentre) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
          .set('RGI_idPv', operator.salePoint.objectId)
          .set('RGI_user', operator.username)
          .set('RGI_executionId', executionId)
          .set('RGI_idCdl', operator.liquidationCentre ? operator.liquidationCentre.identification : null)
      });
    } else {
      authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
          .set('RGI_idPv', operator.salePoint.objectId)
          .set('RGI_user', operator.username)
          .set('RGI_executionId', executionId)
      });
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}

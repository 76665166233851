import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {RgiRxFormControl, RgiRxFormField} from '../rgi-rx-form-elements-api';
import {LoggerFactory, RgiRxVirtualDOMError} from '@rgi/rx';
import {Subscription} from 'rxjs';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';
import {delay, startWith} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

let rgiRxFormFieldCounter = 0;

@Component({
  selector: 'rgi-rx-form-field',
  templateUrl: './rgi-rx-form-field.component.html',
  host: {
    class: 'rgi-ui-form-group',
    '[class.rgi-ui-error]': 'control.hasError()',
    '[class.rgi-ui-form-group-stable]': 'hasValue() && !_isFocused',
    '[class.rgi-ui-form-group-focused]': '_isFocused',
    '[class.rgi-ui-form-group-untouched]': '_formControlState("untouched")',
    '[class.rgi-ui-form-group-touched]': '_formControlState("touched")',
    '[class.rgi-ui-form-group-pristine]': '_formControlState("pristine")',
    '[class.rgi-ui-form-group-dirty]': '_formControlState("dirty")',
    '[class.rgi-ui-form-group-valid]': '_formControlState("valid")',
    '[class.rgi-ui-form-group-invalid]': '_formControlState("invalid")',
    '[class.rgi-ui-form-group-pending]': '_formControlState("pending")',
    '[class.rgi-ui-form-group-required]': '_controlIsRequired()',
    'aria-live': 'polite'
  },
  providers: [
    {
      provide: RgiRxFormField,
      useExisting: RgiRxFormFieldComponent
    }
  ]
})
export class RgiRxFormFieldComponent<T= any> extends RgiRxFormField<T> implements AfterViewInit, AfterContentInit, OnDestroy {
  private readonly logger = LoggerFactory();
  private readonly _id: string;
  private _displayErrors = true;
  private stateChangeSubscription = Subscription.EMPTY;
  private formFieldErrorChangesSubscription =  Subscription.EMPTY;

  constructor(private _vc: ViewContainerRef, private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2) {
    super();
    this._id = `rgi-rx-form-field-${++rgiRxFormFieldCounter}`;
  }


  @Input() get id(): string {
    return this._id;
  }

  /**
   * Weather the errors should be rendered
   */
  @Input() get displayErrors(): boolean {
    return this._displayErrors;
  }

  set displayErrors(value: boolean) {
    this._displayErrors = coerceBooleanProperty(value);
  }

  ngAfterViewInit(): void {
    this.formFieldErrorChangesSubscription = this.rgiRxFormFieldErrors.changes
      .pipe(
        startWith(this.rgiRxFormFieldErrors),
        delay(0)
      ).subscribe(
      next => {
        this.rgiRxFormControl.ariaDescribedBy = this.rgiRxFormFieldErrors.map(e => e.id);
      }
    );
  }

  ngAfterContentInit(): void {
    if (!this.rgiRxFormControl) {
      this.logger.error(`rgi-rx-form-field requires a RgiRxFormControl children, but none is defined!
If you're using an input you may forgot rgiRxInput directive, else please use a RgiRxFormControl element or implement RgiRxControl API in your component!`, this._vc.element.nativeElement);
      throw new RgiRxVirtualDOMError(`rgi-rx-form-field ${this.id} requires a children RgiRxFormControl!`);
    }
    if (this.rgiRxLabel) {
      this.rgiRxLabel.for = this.rgiRxFormControl.id;
    }
    this.stateChangeSubscription = this.rgiRxFormControl.stateChanges.subscribe(
      next => {
        this.changeDetectorRef.markForCheck();
      }
    );

    if (!!this.rgiRxFormControl.forwardAttributes) {
      const forwardAttributes = this.rgiRxFormControl.forwardAttributes;
      for (const forwardAttributesKey in forwardAttributes) {
        if (forwardAttributes.hasOwnProperty(forwardAttributesKey)) {
          this.renderer.setAttribute(this._vc.element.nativeElement, forwardAttributesKey, forwardAttributes[forwardAttributesKey]);
        }
      }
    }
    if (!!this.rgiRxFormControl.forwardClasses) {
      const forwardClasses = this.rgiRxFormControl.forwardClasses;
      if (typeof forwardClasses === 'string') {
        this.renderer.addClass(this._vc.element.nativeElement, forwardClasses);
      } else {
        forwardClasses.forEach(c => this.renderer.addClass(this._vc.element.nativeElement, c));
      }
    }

    if (this.rgiRxLabel) {
      this.rgiRxFormControl.ariaLabelledBy.push(this.rgiRxLabel.id);
      this.rgiRxFormControl.ariaLabel = this.rgiRxLabel.elementRef?.nativeElement.textContent
    }


  }

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
    this.formFieldErrorChangesSubscription.unsubscribe();
  }

  get control(): RgiRxFormControl<any> | undefined {
    return this.rgiRxFormControl;
  }

  /**
   * Get the formControl state of the control by AbstractControlDirective properties
   */
  _formControlState(prop: keyof AbstractControlDirective): boolean {
    const control = this.rgiRxFormControl.ngControl ? this.rgiRxFormControl.ngControl : null;
    return control && control[prop];
  }

  _controlIsRequired(): boolean {
    return this.control && (this.control.required || (this.control.ngControl && this.control.ngControl.control && this.hasRequiredField(this.control.ngControl.control)));
  }

  hasValue() {
    return this.rgiRxFormControl && !!(this._formControlState('value') || this.rgiRxFormControl.value);
  }

  get _isFocused() {
    return this.rgiRxFormControl && this.rgiRxFormControl.focused;
  }

  hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    const ctrl = abstractControl as any;
    if (ctrl.controls) {
      for (const controlName in ctrl.controls) {
        if (ctrl.controls[controlName]) {
          if (this.hasRequiredField(ctrl.controls[controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  shouldRenderValidation(key: string) {
    return !this.rgiRxFormControl.skipValidationKeys || this.rgiRxFormControl.skipValidationKeys.indexOf(key) === -1;
  }
}

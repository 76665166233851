<section class="global-modal-confirm">
  <div class="global-modal-confirm-container">
    <rgi-rx-panel [class.example-panel-hide]="!display">
      <rgi-rx-panel-header (onClose)="display = false" [closeable]="true">Message</rgi-rx-panel-header>
      <article>
        {{modalTextContent}}
      </article>
      <rgi-rx-panel-footer>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeModal('Close')">{{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeModal('Confirm')">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
      </rgi-rx-panel-footer>
    </rgi-rx-panel>
  </div>
</section>

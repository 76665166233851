import { Inject, Injectable, Optional } from '@angular/core';
import {EnvironmentService, ResponseResult} from "@rgi/ng-passpropro-core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Source} from "./../model/source";
import {map} from "rxjs/operators";
import { SourceEnvironment, SOURCE_ENVIRONMENT_PROVIDER } from '../product-api';

const BASEPATH = "/v2/product/source";

@Injectable()
export class ProductSourceService {

  constructor(
    @Inject(SOURCE_ENVIRONMENT_PROVIDER) @Optional() private sourceEnvs: SourceEnvironment[],
    private environmentService: EnvironmentService,
    private httpClient: HttpClient,
  ) { }


  getSources():Observable<Source[]>{
    let environmentVariables = this.sourceEnvs ?
        this.sourceEnvs.reduce( (map, env) => Object.assign({}, map, env),  {} ) :
        {};

    return this.httpClient.put(this.environmentService.environmentUrl + BASEPATH, {
      variables : environmentVariables
    })
      .pipe(
        map((res : ResponseResult<Source[]>) => {
          return res.result;
        })
      )
  }
}

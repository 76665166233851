import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Activity, ActivityService, ActivityVariable, ApiClaim, ApiTranslatorClaimService, ServiceError
} from '@rgi/digital-claims-common-angular';
import { ReassignTaskComponent } from '../../../reassign-task/reassign-task.component';

@Component({
  selector: 'claims-activity-menu',
  templateUrl: './activity-menu.component.html',
  styleUrls: ['./activity-menu.component.scss']
})
export class ActivityMenuComponent implements OnInit {

  @Input()
  activity: Activity;

  @Input() card: any;

  public isOpen: boolean;
  public functionsLoading = false;
  public functions: [];

  public runActivityEnabled = false;
  public updateAssigneeEnabled = false;

  private claim: ApiClaim;

  constructor(
    @Inject('containerCacheService') private cache,
    @Inject('eventService') private eventService: any,
    private activityService: ActivityService,
    private apiTranslatorClaimService: ApiTranslatorClaimService,
    private modalService: NgbModal) { }

    ngOnInit() {
      const jsonClaim = this.cache.getCache().get(this.card.idSession).data;
      this.claim = this.apiTranslatorClaimService.jsonClaimToApiClaim(jsonClaim);
      // this.getActivitiesFromClaim(this.claim);
    }

  public dropdownToggle(event: boolean): void {
    this.isOpen = event;

    if (this.isOpen) {
      this.functionsLoading = true;
      const that = this;
      let promise;
      try {
        promise = this.activityService.getTaskFunctions(this.activity);
      } catch (e) {
        that.functionsLoading = false;
        throw e;
      }

      if (promise) {
        promise.then((result) => {

          that.functionsLoading = false;
          const functions = result.data;
          if (functions) {
            that.runActivityEnabled = false;
            that.updateAssigneeEnabled = false;
            functions.forEach((element) => {
              if (element.id != null) {
                switch (element.id) {
                  case 1:
                    that.runActivityEnabled = true;
                    break;
                  case 2:
                    that.updateAssigneeEnabled = true;
                    break;
                }
              }
            });
          }
        });
      } else {
        that.functionsLoading = false;
        throw new ServiceError('Could not create service promise');
      }
    }
  }

  genericFunction( fn: () => void) {
    if (fn) {
      fn();
    } else {
       return true;
    }
  }

  public runActivity() {

    // if activity is managed in claims finder and there is not camunda, i don't open a new session but update the current one
    // TODO replace test this.activityManagedInClaimsFinder with :
    // if activityDict.relatedbpmprocess = null && activityDict.erunredirect = portal
    if (this.activityManagedInClaimsFinder(this.activity)) {
      // TODO manage this.claimsActivityService.updateAssigneeUserInTask ? view example in digital-claims-common-angular
      this.reloadClaimOrPes(this.activity);
    } else {
      this.activityService.runActivity(this.activity, this.genericFunction, this.card);
    }

  }

  reloadClaimOrPes(activityParam: Activity) {

    const integrationWFParam = {
      activity: activityParam,
      params: this.actVariableToWfParams(activityParam.actVariableBean),
      idTask: 'NO_BPM_ONLY_ACTIVITY',
      idAct: activityParam.idAct
    };

    const parameters = {
      idSession: this.card.idSession,
      objectReference: this.claim.claimNumber,
      objectType: 'claim',
      companyCode: this.claim.aniaCompanyCode,
      integrationWF: integrationWFParam
    };

    this.eventService.broadcastEvent('reloadClaim', parameters);
  }

  actVariableToWfParams(actVariableBean: ActivityVariable[]) {
    const wfParamsList: {} [] = [];

    let wfParamsElem = {};

    for (const actVariableElem of actVariableBean) {

      wfParamsElem = {
        booleanValue: actVariableElem.varValueBool,
        dateValue: actVariableElem.varValueData,
        idActVar: actVariableElem.idActVar,
        idUserIns: actVariableElem.idUserIns,
        idUserUpd: actVariableElem.idUserUpd,
        int32Value: actVariableElem.varValueNumb,
        int64Value: actVariableElem.varValueNumb,
        name: actVariableElem.varName,
        realValue: actVariableElem.varValueNumb,
        stringValue: actVariableElem.varValueBlob || actVariableElem.varValueStr,
        varType: actVariableElem.varType
      };

      wfParamsList.push(wfParamsElem);
    }

    return wfParamsList;
  }

  activityManagedInClaimsFinder(activity: Activity) {

    // TODO test with ERUNREDIRECT = 2 and CRELATEDBPMPROCESS is null ?
    switch (activity.actDictionaryBean.code) {
      case 'SX0097':
      case 'SX0098':
      case 'SX0099':
        return true;
        // break;
      default:
        break;
    }

    return false;
  }

  public updateAssignee() {
    this.activityService.updateAssignee(this.activity);
  }

  public assign() {
    this.activityService.assign(this.activity);
  }

  public related() {
    this.activityService.related(this.activity, null);
  }


  reassigntask(event: any) {
    event.stopPropagation();

    const modalRef = this.modalService.open(ReassignTaskComponent, {
      windowClass: 'basic',
      size: 'lg'
    });

    modalRef.componentInstance.apiClaim = this.claim;
    // modalRef.componentInstance.idTask =  this.activity.idAct;
    // modalRef.componentInstance.idInstance = this.activity.actTaskBean[0].taskId;
    modalRef.componentInstance.activity = this.activity;
    // modalRef.result.then((result) => {
    //   if (result) {
    //     (this.claim.claimNumber, this.activity.idAct);
    //   }
    // });

    return false;
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as uuid from 'uuid';
import { ExposureEntity } from './domain/exposure-entity';
import { InitPotentialPaymentEntity } from './domain/init-potential-payment-entity';
import { LegalFeeEntity } from './domain/legal-fee-entity';
import { PaymentEntity } from './domain/payment-entity';
import { SubjectEntity } from './domain/subject-entity';
import { SubsidiaryChecksData } from './pre-controls/dto/subsidiary-checks-data';
import { DamageCardDto } from './quote-evaluation/dto/damage-card-dto';
import { DamageObj } from './quote-evaluation/wsobj/damageobj';
import { ReserveCapacityObj } from './quote-evaluation/dto/reservecapacityobj';
import { Observable } from 'rxjs';
import { BodyRequest, IComboListReceipt } from './receipt-return-modal/receipt-return.interface';
import {
  CancellationBodyRequest, IComboListCancellation,
  ResponseReasonCancelled, ResponseSaveCancelled
} from './cancellation-modal/cancellation.interface';
import { PotentialPaymentEntity } from './domain/potential-payment-entity';
import { IComboListReversal, ResponseReasonReversal, ResponseSaveReversal,
  ReversalBodyRequest } from './settlement-reversal/settlement-reversal.interface';
import { BodyRequestDataSettlement, BodyRequestDataSettlementPaymentMethod, OutputSetlementResultResponse } from './new-settlement-detail-modal/new-settlement-detail.interface';
import { LegalFeeOmniaEntity } from './domain/legal-fee-omnia-entity';

@Injectable({
  providedIn: 'root'
})
export class NewPaymentService {

  private sessionUuid: string[];
  private baseApiUrl: string;
  private urlservice = '/claims/potentialpayment';
  private potPayCache: InitPotentialPaymentEntity;
  private finalPayCache: Array<PaymentEntity>;
  private message: string;
  private cssMsg: string;
  public paymentIdForAddBenef: number;
  public subjectRole: string;
  public consultationData: Map<string, string>;
  public card: any;
  private baseApiUrlRs: string;
  private lastConsultationData: Map<string, string>;
  private claimCurrent: any;
  private paymentDb: PaymentEntity;
  public transferDescrFixMap: Map<number, string> = new Map();


  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrlRs = environment.api.portal.host + environment.api.portal.path;
    this.lastConsultationData = null;
  }

  setPaymentDB(pay: PaymentEntity) {
    this.paymentDb = pay;
  }

  getPaymentDB(): PaymentEntity {
    return this.paymentDb;
  }


  setTransferDescrFixMap(map: Map<number, string>) {
    this.transferDescrFixMap = map;
  }

  getTransferDescrFixMap(): Map<number, string> {
    return this.transferDescrFixMap;
  }

  setMessageCache(message: string, css: string) {
    this.message = message;
    this.cssMsg = css;
  }

  getMessageCache(): string[] {
    const temp = [this.message, this.cssMsg];
    this.message = null;
    this.cssMsg = null;
    return temp;
  }

  setCardCache(card: any) {
    this.card = card;
  }

  getCardCache(): any {
    const temp = this.card;
    this.card = null;
    return temp;
  }

  setClaimCache(claimCurrent: any) {
    this.claimCurrent = claimCurrent;
  }

  setFinalPayCache(finalPayCache: Array<PaymentEntity>) {
    this.finalPayCache = finalPayCache;
  }

  getClaimCache(): any {
    const temp = this.claimCurrent;
    this.claimCurrent = null;
    return temp;
  }

  getFinalPayCache(): Array<PaymentEntity> {
    const temp = this.finalPayCache;
    this.finalPayCache = null;
    return temp;
  }


  setConsultationData(consultationData: Map<string, string>) {
    this.consultationData = consultationData;
  }

  getConsultationData(): Map<string, string> {
    const temp = this.consultationData;
    this.consultationData = null;
    return temp;
  }

  clearLastConsultationData() {
    this.lastConsultationData = null;
  }

  setLastConsultationData(consultationData: Map<string, string>) {
    this.lastConsultationData = consultationData;
  }

  getLastConsultationData() {
    return this.lastConsultationData;
  }

  setPotPayCache(potPayCache: InitPotentialPaymentEntity) {
    this.potPayCache = potPayCache;
  }

  getPotPayCache(): InitPotentialPaymentEntity {
    const temp = this.potPayCache;
    this.potPayCache = null;
    return temp;
  }


  initSessionUuid(idSession: string) {
    this.setSessionUuid(idSession, uuid.v4());
  }

  setSessionUuid(idSession: string, uuidStr: string) {
    if (!this.sessionUuid) {
      this.sessionUuid = [];
    }
    this.sessionUuid[idSession] = uuidStr;
  }

  getSessionUuid(idSession: string): string {
    let uuidString = null;
    if (this.sessionUuid) {
      uuidString = this.sessionUuid[idSession];
    }
    if (!uuidString) {
      this.initSessionUuid(idSession);
      uuidString = this.sessionUuid[idSession];
    }

    return uuidString;
  }

  initPotentialPayment(input: InitPotentialPaymentEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice, input);
  }

  getMulticurrencyIsoCodes(): Observable<any> {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/multicurrencyISOcodes');
  }

  getPotentialPaymentPayessUrl(uuidString: string) {
    return this.baseApiUrl + this.urlservice + '/' + uuidString + '/payees';
  }

  getPotentialPaymentPayess(uuidString: string) {
    return this.httpClient.get(this.getPotentialPaymentPayessUrl(uuidString));
  }

  getSubsidiaryChecks(claimId: string, codeCDLLogIn: string, idUserLogIn: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/subsidiary-checks?claimId=' + claimId
      + '&' + 'codeCDLLogIn=' + codeCDLLogIn
      + '&' + 'idUserLogIn=' + idUserLogIn);
  }

  getListConfigurationPayment(claimNumber: string, idActor: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/listConfigurationPayment?claimNumber=' + claimNumber + '&' + 'idActor=' + idActor);
  }

  postSubsidiaryChecks(claimId: string, input: SubsidiaryChecksData, codeCDLLogIn: string, idUserLogIn: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/subsidiary-checks?claimId=' + claimId
      + '&' + 'codeCDLLogIn=' + codeCDLLogIn
      + '&' + 'idUserLogIn=' + idUserLogIn, input);
  }

  getListExposuresProfs(claimNumber: string, idActor: string, codeCDLLogIn: string, idUserLogIn: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/listExposuresProfs?claimNumber=' + claimNumber
      + '&' + 'idActor=' + idActor
      + '&' + 'codeCDLLogIn=' + codeCDLLogIn
      + '&' + 'idUserLogIn=' + idUserLogIn);
  }

  savePhotoInitPotentialPayment(initPotPay: InitPotentialPaymentEntity) {
    // return this.httpClient.post(this.baseApiUrl + this.urlservice + '/' + initPotPay.uuid +
    //          '/savePhotoPayment', initPotPay);

    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/savePhotoPayment', initPotPay);
  }

  getBeneficiaryTypes(uuidString: string, paymentType: string, isDamagedPresent: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/' + uuidString + '/beneficiaryTypes?paymentType=' + paymentType +
      '&' + 'isDamagedPresent=' + isDamagedPresent);
  }
  checkPaymentData(claimId: string, initPotPay: InitPotentialPaymentEntity, codeCDLLogIn: string, idUserLogIn: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/' + 'checkPaymentData?claimId=' + claimId
      + '&' + 'codeCDLLogIn=' + codeCDLLogIn
      + '&' + 'idUserLogIn=' + idUserLogIn, initPotPay);

  }


  verifyPaymentData(initPotPay: InitPotentialPaymentEntity, codeCDLLogIn: string, idUserLogIn: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/veryfyPaymentData' + '?' + 'codeCDLLogIn=' + codeCDLLogIn
      + '&' + 'idUserLogIn=' + idUserLogIn, initPotPay);
  }


  dataFeeVerify(legalFee: LegalFeeEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/dataFeeVerify', legalFee);
  }

  dataFeeOmniaVerify(exposure: ExposureEntity): Observable<LegalFeeOmniaEntity> {
    return this.httpClient.post<LegalFeeOmniaEntity>(this.baseApiUrl + this.urlservice +
      '/dataFeeOmniaVerify', exposure);
  }

  dataFeeOmniaModify(exposure: ExposureEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/dataFeeOmniaModify', exposure);
  }

  factorsLogicVerifyCalculate(exposure: ExposureEntity, claimNumber: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/factorsLogicVerifyCalculate?' + 'claimNumber=' + claimNumber, exposure);
  }

  cancelReceipt(idPayment: string, claimNumber: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/cancelReceipt?'
      + 'claimNumber=' + claimNumber
      + '&' + 'idPayment=' + idPayment, null);
  }


  updateSubject(subject: SubjectEntity, codeCDLLogIn: string, idUserLogIn: string, claimNumber: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/updateSubject?'
      + 'codeCDLLogIn=' + codeCDLLogIn
      + '&' + 'idUserLogIn=' + idUserLogIn
      + '&' + 'claimNumber=' + claimNumber, subject);
  }

  upgradeSubject(subject: SubjectEntity, idPayment: string, claimNumber: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/upgradeSubject?'
      + 'idSubjectIni=' + subject.idSubject
      + '&' + 'idSubjectFin=' + subject.idSubjectUpgrade
      + '&' + 'claimNumber=' + claimNumber
      + '&' + 'idPayment=' + idPayment, null);
  }

  paidIbans(subject: SubjectEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/paidIbans', subject);
  }
  payMethods(subject: SubjectEntity, paymentType: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/paymethods?' + 'paymenttype=' + paymentType, subject);
  }
  getPaymentMethods(uuidString: string, paymentType: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/' + uuidString + '/paymentMethods?paymentType=' + paymentType);
  }

  finalSavePayment(initPotPay: InitPotentialPaymentEntity, codeCDLLogIn: string, idUserLogIn: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/finalSavePayment?'
      + 'codeCDLLogIn=' + codeCDLLogIn
      + '&' + 'idUserLogIn=' + idUserLogIn, initPotPay);
  }

  getPotentialDocuments(uuidString: string, paymentType: string, claimNumber: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/' + uuidString + '/potentialDocuments?paymentType=' + paymentType + '&' +
      'claimNumber=' + claimNumber);
  }

  getPrintableClauses(codeDoc: string) {
    return this.httpClient.get(this.baseApiUrlRs + '/claims/' + codeDoc + '/printableClauses');
  }

  getPaymentDetail(claimNumber: string, idActor: string, idPayment: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/paymentDetail?' + 'claimNumber=' + claimNumber + '&' + 'idActor=' + idActor + '&' + 'idPayment=' + idPayment);
  }

  // SERVICE LOADING DAMAGE CARD (get)
  public loadingDamageCard(claimNumber: string, idParty: number, reasonUpdate: string) {
    return this.httpClient.get<DamageObj>(this.getLoadingDamageCardUri(claimNumber, idParty, reasonUpdate));
  }

  private getLoadingDamageCardUri(claimNumber: string, idParty: number, reasonUpdate: string) {
    return this.baseApiUrlRs + '/claims/' + claimNumber + '/loadingDamageCardPortal/' + idParty + '?reasonUpdate=' + reasonUpdate;
  }

  // SERVICE CALCULATE/SAVE DAMAGE CARD (post)
  public calculateSaveDamageCard(claimNumber: string, damageCardDto: DamageCardDto) {
    return this.httpClient.post(this.getcalculateSaveDamageCardUri(claimNumber), damageCardDto);
  }

  public getFormDamagePhoto(idPayment: any) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/damageFormPhoto?idPayment=' + idPayment);
  }

  private getcalculateSaveDamageCardUri(claimNumber: string) {
    return this.baseApiUrl + '/claims/' + claimNumber + '/damagecard/calculate';
  }

  // SERVICE RESERVE CAPACITY
  public verifyReserveCapacity(reserveCapacityObj: ReserveCapacityObj) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/verifyReserveCapacity', reserveCapacityObj);
  }

  // SERVICE PREVIEW DOCUMENT
  public previewDocument(idDocument: number) {
    return this.httpClient.get(this.getpreviewDocumentUri(idDocument));
  }

  private getpreviewDocumentUri(idDocument: number) {
    return this.baseApiUrlRs + '/claims/mailingDocuments/preview/' + idDocument;
  }

  getFeeData(idPayment: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/feeData?' + 'idLiquidazione=' + idPayment);
  }

  getBanktransferdesc(claimNumber: string, idActor: string, pay: PotentialPaymentEntity) {
    return this.httpClient.post(this.baseApiUrl + '/claims/potentialpayment/banktransferdesc?' +
      'claimNumber=' + claimNumber +
      '&idActor=' + idActor, pay);
  }

  getListPayment(filter: any) {
    return this.httpClient.post(this.baseApiUrl + '/claims/payment/listPayments', filter);
  }

  // Servizio chiamato alla chiusura della modale relativa alla restituzione della quietanza (RDC-237)
  saveReceipt(claimNumber: string, body: BodyRequest) {
    return this.httpClient.post(this.baseApiUrl + '/claims/potentialpayment/saveReceipt?' +
      'updateCase=A&claimNumber=' + claimNumber, body);
  }

  getReasonRejectedList(body: IComboListReceipt): Observable<any> {
    return this.httpClient.post<any>(this.baseApiUrl + '/claims/potentialpayment/reasonsReceiptCancellation?' +
      'tipoCausale=' + body.tipoCausale +
      '&dataSinistro=' + body.dataSinistro +
      '&ramoMinisteriale=' + body.ramoMinisteriale, body);
  }

  // Chiamata al nuovo servizio che restituisce l'elenco delle motivazioni di annullamento quietanza (RDC-236)
  getReasonsReceiptDeleteList(body: IComboListCancellation, claimNumber: string): Observable<ResponseReasonCancelled> {
    return this.httpClient.post<ResponseReasonCancelled>(this.baseApiUrl
      + '/claims/potentialpayment/reasonsReceiptDelete?claimNumber=' + claimNumber, body);
  }

  // Chiamata al nuovo servizio che restituisce l'elenco delle motivazioni di annullamento liquidazione (RDC-236)
  getReasonsCancelSettlementsList(body: IComboListCancellation, claimNumber: string): Observable<ResponseReasonCancelled> {
    return this.httpClient.post<ResponseReasonCancelled>(this.baseApiUrl
      + '/claims/potentialpayment/reasonsCancelSettlements?claimNumber=' + claimNumber, body);
  }

  // Chiamata al nuovo servizio che restituisce l'elenco delle motivazioni di annullamento liquidazione (RDC-236)
  getReasonsSettlementsDeleteList(body: IComboListCancellation, claimNumber: string): Observable<any> {
    return this.httpClient.post<any>(this.baseApiUrl
      + '/claims/potentialpayment/reasonsCancelSettlements?claimNumber=' + claimNumber, body);
  }

  // Servizio chiamato alla chiusura della modale relativa all'annullamento quietanza (RDC-236)
  saveDeleteReceipt(claimNumber: string, body: CancellationBodyRequest): Observable<ResponseSaveCancelled> {
    return this.httpClient.post<ResponseSaveCancelled>(this.baseApiUrl
      + '/claims/potentialpayment/deleteReceipt?claimNumber=' + claimNumber, body);
  }
  // Servizio che richiama i dati della liquidazione
  // dataSettlementDetail(bodyRequestDataSettlement: any): Observable<OutputSettlementDetail>{
  //   return this.httpClient.get<OutputSettlementDetail>('/api/rest/v2/claims/potentialpayment/newpayment');
  // }

  // Servizio chiamato alla chiusura della modale relativa all'annullamento liquidazione (RDC-236)
  saveDeleteSettlement(claimNumber: string, body: CancellationBodyRequest): Observable<ResponseSaveCancelled> {
    return this.httpClient.post<ResponseSaveCancelled>(this.baseApiUrl
      + '/claims/potentialpayment/deleteSettlement?claimNumber=' + claimNumber, body);
  }

  // Chiamata al servizio che restituisce i dati utili per lo storno della liquidazione (RDC-268)
  listReasonsSettlementsReversal(body: IComboListReversal, claimNumber: string): Observable<ResponseReasonReversal> {
    return this.httpClient.post<ResponseReasonReversal>(this.baseApiUrl
      + '/claims/potentialpayment/reasonsTransferSettlements?claimNumber=' + claimNumber, body);
  }

  // Servizio chiamato alla chiusura della modale relativa allo storno della liquidazione - Salvataggio dei dati (RDC-268)
  saveSettlementsReversal(claimNumber: string, body: ReversalBodyRequest): Observable<ResponseSaveReversal> {
    return this.httpClient.post<ResponseSaveReversal>(this.baseApiUrl
      + '/claims/potentialpayment/transferSettlements?claimNumber=' + claimNumber, body);
  }

  // Servizio per salvere nuova Liquidazione || Service for save new Settlement parameters (RDC-235)
  seveNewSettlement(body: BodyRequestDataSettlement): Observable<OutputSetlementResultResponse> {
    return this.httpClient.post<OutputSetlementResultResponse>(this.baseApiUrl
        + '/claims/potentialpayment/newpayment', body);
  }
  seveNewSettlementWithPaymentMethod(body: BodyRequestDataSettlementPaymentMethod): Observable<OutputSetlementResultResponse> {
    return this.httpClient.post<OutputSetlementResultResponse>(this.baseApiUrl
        + '/claims/potentialpayment/newpayment', body);
  }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, flatMap, map} from "rxjs/operators";
import {PassproproAuthService} from "../passpropro-auth.module";
import {EnvironmentService} from "../../environment/environment.service";
import {LoggerFactory} from "@rgi/rx";
import {URILocatorService} from "../../environment/urilocator.service";

const SSO_AUTH_HEADER_MAKER = 'RGI_PPPRO_SSO';

@Injectable()
export class SSOInterceptor implements HttpInterceptor {

  private readonly logger = LoggerFactory();


  constructor(private authService: PassproproAuthService, private environment: EnvironmentService,private uriLocator: URILocatorService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.environment.environment.SSO) {
      if (!this.uriLocator.isRelativeApiPath(req)) {
        return next.handle(req);
      }
      return this.authService.$getSSOToken().pipe(
        catchError(err => {
          this.logger.error("Cannot handle sso token get, this may result in authorization errors later on", err)
          return next.handle(req);
        }),
        flatMap((event) => {
          return next.handle(this.process(req)).pipe(
            this.mapSSOAuthHeader$
          )
        })
      );
    }
    return next.handle(req)
  }

  private process(req: HttpRequest<any>): HttpRequest<any> {
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.authService.getSSOToken()
      }
    });
    return req;
  }

  private mapSSOAuthHeader$ = map((event: HttpEvent<any>) => {
    if (event instanceof HttpResponse) {
      if (event.headers.has(SSO_AUTH_HEADER_MAKER)) {
        this.authService.SSOtoken = event.headers.get(SSO_AUTH_HEADER_MAKER)
      }
    }
    return event;
  });



}

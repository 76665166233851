import {InjectionToken} from "@angular/core";


export const DEFAULT_API_PATHS: string[] =  [
  "questionnaires",
  "passpropro",
  "document",
  "acl",
  "audit"
];

export type ApiPaths = string[];

export const API_PATHS = new InjectionToken<ApiPaths>('API_PATHS');

export type NgPassProProConfig = {
  environment?: {host: string, path: string};
  dynamicEnvironmentUrl?: string
};

export const NG_PASSPROPRO_CONFIG = new InjectionToken<NgPassProProConfig>('NG_PASSPROPRO_CONFIG');


export interface ResponseList<T> {
  list: Array<T>;
}
export interface ResponseResult<T> {
  messages: string[],
  isSuccess: boolean,
  result: T;
}

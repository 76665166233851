export class InsuranceBackgroundProperties {
  constructor(
    public insuranceBackgroundVisible: boolean,
    public originPlateVisible: boolean,
    public sourceVINVisible: boolean,
    public atrPortable: boolean,
    public law134Visible: boolean,
    public originUniqueClassVisible: boolean) {
  }
}

import { CurrencyCacheService, LpcCurrencyCache } from './../../../../../services/currency-cache.service';
// eslint-disable-next-line max-len
import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { TranslationWrapperService } from '../../../../../i18n/translation-wrapper.service';
import { Observable, Subscription } from 'rxjs';
import { InvestmentMode } from '../../../../../models/operation-property-code.enum';
import { PlcObjectUtils } from '../../../../../utils/plc-object-utils';
import { FinancialElementToggleEvent, KarmaFundDefinition } from '../../../model/karma-fund-definition';
import { RgiCountryLayerCurrencyFormatPipe } from '@rgi/country-layer';
import { DEFAULT_CURRENCY_CODE } from './../../../../../models/consts/lpc-consts';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lpc-karma-disinvestment-fund',
  templateUrl: './lpc-karma-disinvestment-fund.component.html',
  styleUrls: ['./lpc-karma-disinvestment-fund.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFundComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFundComponent),
      multi: true
    },
    RgiCountryLayerCurrencyFormatPipe
  ]

})
export class LpcKarmaDisinvestmentFundComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  public get isPercent(): boolean {
    return this.formGroup.get('isPercent').value;
  }

  @Input() public investmentMode: InvestmentMode = InvestmentMode.BOTH;
  @Input() public definition: KarmaFundDefinition;
  @Input() public totalAmount: number;
  @Input() public investedProfiles: number;
  @Input() public checksOnOverallAlloc = true;
  @Input() public showSliderInput: boolean;
  @Input() public isprofile: boolean;
  @Input() public isFullPreval: boolean;
  @Output() public fundSelected = new EventEmitter<any>();
  @Output() public toggleChange = new EventEmitter<FinancialElementToggleEvent>();

  currencyCode = DEFAULT_CURRENCY_CODE;
  public currencySymbol: string;
  amountChanges$: Observable<any>;
  percentChanges$: Observable<any>;
  activeChanges$: Observable<any>;

  private $subscriptions: Subscription[] = [];
  public remainingFundAmount = 0;
  private $PercentIsDisabled = false;
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency',
  };

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl(false),
    percent: new UntypedFormControl(),
    amount: new UntypedFormControl(),
    isPercent: new UntypedFormControl(true)
  });

  constructor(
    protected translateService: TranslationWrapperService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected currencySymbolPipe: RgiCountryLayerCurrencyFormatPipe
    ) {
      this.currencySymbol = currencySymbolPipe.transform(!!currencyService.currency ? currencyService.currency : this.currencyCode);
      this.currencyFormatterOptions.currency = this.currencyCode;
    }
    ngOnInit() {
      this.initializeInputs();

      this.amountChanges$ = this.subscribeToAmountChanges();
      this.percentChanges$ = this.subscribeToPercentChanges();
      this.activeChanges$ = this.subscribeToActiveChanges();

      this.$subscriptions.push(
        this.amountChanges$.subscribe(),
        this.percentChanges$.subscribe(),
        this.activeChanges$.subscribe()
      );
    }

    protected subscribeToAmountChanges(): Observable<any> {
      return this.formGroup.get('amount').valueChanges.pipe(map(value => {
        let percentFormValue: number = null;

        if (this.isprofile) {
          percentFormValue = PlcObjectUtils.roundToDecimalTrunc(value / this.getMaxDisinvestmentAmount() * 100, 2);
        } else {
          percentFormValue = PlcObjectUtils.roundToDecimalTrunc(value / this.totalAmount * 100, 2);
        }

        this.remainingFundAmount = PlcObjectUtils.roundToDecimalTrunc(this.investedProfiles - value, 2);
        this.formGroup.get('percent').setValue(percentFormValue, { emitEvent: false });

        this.onChange(this.formGroup.get('percent').value / 100);
        this.onTouch();

        this.fundSelected.emit({
          name: this.definition.description,
          selected: true,
          value: this.investmentMode === InvestmentMode.PERCENT ? percentFormValue + '%' :
            this.investmentMode === InvestmentMode.AMOUNT ? '€ ' + value : percentFormValue + '%'
        });
      }));
    }

    protected subscribeToPercentChanges(): Observable<any> {
      return this.formGroup.get('percent').valueChanges.pipe(map(value => {
        const amountFormValue = PlcObjectUtils.roundToDecimalTrunc(value * this.totalAmount / 100, 2);
        this.remainingFundAmount = PlcObjectUtils.roundToDecimalTrunc(this.investedProfiles - amountFormValue, 2);
        this.formGroup.get('amount').setValue(amountFormValue, { emitEvent: false });
        this.onChange(value / 100);
        this.onTouch();
        this.fundSelected.emit(
          {
            name: this.definition.description,
            selected: true,
            value: this.investmentMode === InvestmentMode.PERCENT ? value + '%' :
              this.investmentMode === InvestmentMode.AMOUNT ? this.currencySymbol + ' ' + amountFormValue : value + '%'
          });
      }));
    }

    protected subscribeToActiveChanges(): Observable<any> {
      return this.formGroup.get('active').valueChanges.pipe(map(value => {
        let selected = false;
        if (value) {
          selected = true;
          if (this.definition.minPercentAllocation != null && this.definition.maxPercentAllocation != null) {
            if (this.definition.minPercentAllocation === this.definition.maxPercentAllocation) {
              if (!this.definition.percentage) {
                this.formGroup.get('percent').setValue(this.definition.minPercentAllocation * 100);
              }
              this.disableInputs();
            } else {
              this.enableInputs();
            }
          } else {
            this.enableInputs();
          }
        } else {
          this.formGroup.get('percent').setValue(0);
          this.formGroup.get('amount').setValue(0);
          this.disableInputs();
          selected = false;

          this.fundSelected.emit({ name: this.definition.description, selected });
        }
      }));
    }

  getPercent() {
    return PlcObjectUtils.roundToDecimalTrunc(this.investedProfiles / this.totalAmount * 100, 4);
  }

  getMaxPercent(): number {
    if (this.checksOnOverallAlloc) {
      return this.definition.maxPercentAllocation;
    } else {
      return this.getPercent() < 100 ? this.getPercent() : 100;
    }
  }

  getMaxDisinvestmentAmount(): number {
    return Math.min(this.investedProfiles, this.totalAmount);
  }

  public getFundDescription(): string {
    const descrFundTotAmount = this.translateService.getImmediate('lpc_TOTAL_INVESTED');
    const formattedInvestedProfiles = PlcObjectUtils.formatNumberLocale(+this.investedProfiles, this.translateService.getCurrentLanguage());

    if (!!this.investedProfiles) {
        if (!this.checksOnOverallAlloc) {
            const descrRemainingAmount = this.translateService.getImmediate('lpc_REMAINING_AMOUNT');
            const formattedRemainingAmount =
              PlcObjectUtils.formatNumberLocale(this.remainingFundAmount, this.translateService.getCurrentLanguage());
            return `${this.definition.description} (${descrFundTotAmount} ${this.currencySymbol} ${formattedInvestedProfiles}, ${descrRemainingAmount} ${this.currencySymbol} ${formattedRemainingAmount})`;
        } else {
            return `${this.definition.description} (${descrFundTotAmount} ${this.currencySymbol} ${formattedInvestedProfiles})`;
        }
    }

    return this.definition.description;
}

  initializeInputs() {
    this.disableInputs();
    if (this.definition.minPercentAllocation === this.definition.maxPercentAllocation) {
      if (!this.definition.percentage) {
        this.formGroup.get('percent').setValue(0);
        this.formGroup.get('active').setValue(false, { emitEvent: false });
      } else {
        this.formGroup.get('active').setValue(true, { emitEvent: false });
        this.formGroup.disable({ emitEvent: false });
      }
    }
  }

  disableInputs() {
    this.formGroup.get('isPercent').disable({ emitEvent: false });
    this.formGroup.get('percent').disable({ emitEvent: false });
    this.formGroup.get('amount').disable({ emitEvent: false });
  }

  enableInputs() {
    if (!this.$PercentIsDisabled) {
      this.formGroup.get('isPercent').enable({ emitEvent: false });
    }
    this.formGroup.get('percent').enable({ emitEvent: false });
    this.formGroup.get('amount').enable({ emitEvent: false });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalAmount) {
      if (!changes.totalAmount.currentValue) {
        this.formGroup.get('amount').setValue(
          0, { emitEvent: false }
        );
      } else {
        this.formGroup.get('amount').setValue(
          this.formGroup.get('percent').value * changes.totalAmount.currentValue, { emitEvent: false }
        );
      }
    }
    if (changes.investmentMode) {
      const investmentMode = !!changes.investmentMode.currentValue ? changes.investmentMode.currentValue : InvestmentMode.BOTH;
      if (investmentMode !== InvestmentMode.BOTH) {
        this.formGroup.get('isPercent').setValue(investmentMode === InvestmentMode.PERCENT, { emitEvent: false });
        this.$PercentIsDisabled = true;
        this.formGroup.get('isPercent').disable({ emitEvent: false });
      } else {
        this.$PercentIsDisabled = false;
        this.formGroup.get('isPercent').enable({ emitEvent: false });
      }
    }
  }

  writeValue(percent: number): void {
    if (!!percent) {
      this.formGroup.patchValue({
        percent: PlcObjectUtils.roundToDecimalTrunc(percent * 100, 2),
        amount: !this.checksOnOverallAlloc ?
        PlcObjectUtils.roundToDecimalTrunc(percent * this.totalAmount, 2) :
          PlcObjectUtils.roundToDecimalTrunc(percent * this.investedProfiles, 2),
      }, { emitEvent: false });
      this.formGroup.get('active').setValue(true);
    } else {
      this.formGroup.patchValue({
        percent: 0,
        amount: 0,
      }, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};
    // validation for percentage
    if (!!this.formGroup.get('active').value && this.formGroup.get('isPercent').value) {
      if (this.definition.minPercentAllocation !== this.definition.maxPercentAllocation && this.checksOnOverallAlloc) {
        if (this.formGroup.get('percent').value > this.definition.maxPercentAllocation) {
          errors.max = { value: this.formGroup.get('percent').value, limit: this.definition.maxPercentAllocation };
        } else if (this.formGroup.get('percent').value < this.definition.minPercentAllocation) {
          errors.min = { value: this.formGroup.get('percent').value, limit: this.definition.minPercentAllocation };
        }
      } else if (this.definition.minPercentAllocation !== this.definition.maxPercentAllocation && !this.checksOnOverallAlloc) {
        const limit = this.getMaxPercent();
        const value = this.formGroup.get('percent').value;
        if (value > limit) {
          errors.max = { value, limit};
        } else if (value < this.definition.minPercentAllocation) {
          errors.min = { value, limit: this.definition.minPercentAllocation };
        }
      }
      // validation for amount
    } else if (!!this.formGroup.get('active').value && !this.formGroup.get('isPercent').value) {
      const limit = this.getMaxDisinvestmentAmount();
      const value = this.formGroup.get('amount').value;
      if (value > limit) {
        errors.max = { value, limit, isAmount: true };
      }
    }
    return !!Object.keys(errors).length ? errors : null;
  }

  onChange(obj: number) {
  }

  onTouch() {
  }

  roundValue(field: string) {
    const value: number = this.formGroup.get(field).value;
    this.formGroup.get(field).setValue(PlcObjectUtils.roundToDecimal(value, 2));
  }

  roundValueTrunc(field: string) {
    const value: number = this.formGroup.get(field).value;
    this.formGroup.get(field).setValue(PlcObjectUtils.roundToDecimalTrunc(value, 2));
  }

  onToggleClick() {
    if (this.investmentMode === InvestmentMode.ALLSAME) {
      const fund: FinancialElementToggleEvent = this.formGroup.getRawValue();
      fund.id = this.definition.id;
      this.toggleChange.emit(fund);
    }
  }


}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxDecorableDirective} from './directives/rgi-rx-decorator.directive';


@NgModule({
  declarations: [RgiRxDecorableDirective],
  imports: [
    CommonModule
  ],
  exports: [RgiRxDecorableDirective]
})
export class RgiRxExtensionModule {
}

import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  private baseApiUrl: string;
  private urlCities = '/claims/cities';
  private urlCoutries = '/claims/countries';
  private urlToponym = '/claims/toponym';
  private urlZipCodes = '/claims/zipCodes';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject(LOCALE_ID) private locale: string) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getCities(filter?: string) {
    let url = this.baseApiUrl + this.urlCities;
    if (filter) {
      url += '?filter=' + filter;
    }
    return this.httpClient.get(url);
  }

  getCountries(occurrenceDate: Date, code?: string) {
    const formatdate = 'dd-MM-yyyy';
    const dateString = formatDate(occurrenceDate, formatdate, this.locale);
    let url = this.baseApiUrl + this.urlCoutries + '?occurrencedate=' + dateString;
    if (code) {
      url += '&code=' + code;
    }
    return this.httpClient.get(url);
  }

  getToponyms(occurrenceDate: Date) {
    const formatdate = 'dd-MM-yyyy';
    const dateString = formatDate(occurrenceDate, formatdate, this.locale);
    const url = this.baseApiUrl + this.urlToponym + '?occurrencedate=' + dateString;
    return this.httpClient.get(url);
  }

  getZipCodes(municipality: string) {

    const url = this.baseApiUrl + this.urlZipCodes + '?municipality=' + municipality;
    return this.httpClient.get(url);
  }

}

import {GenericEntity} from '../../models/domain-models/generic-entity';

export class Address {

  public id: string;
  public country: GenericEntity;
  public province: GenericEntity;
  public city: string;
  public placeAddress: string;
  public number: string;
  public cap: string;
  public countryCode: string;
  public adminLevel1: string;
  public adminLevel1Short: string;
  public adminLevel2: string;
  public adminLevel2Short: string;
  public adminLevel3: string;
  public adminLevel3Short: string;
  public formatAddress: string;
  public locality: string;
  public fraction: string;
  public toponym: GenericEntity;
  public normalized: boolean;

  constructor() {
  }


}

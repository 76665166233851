import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {fromEvent, merge, Subscription} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';
import {filter, takeUntil} from 'rxjs/operators';
import {RgiRxAutoCompleteComponent} from './rgi-rx-auto-complete/rgi-rx-auto-complete.component';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {DOWN_ARROW, ENTER, ESCAPE, LEFT_ARROW, RIGHT_ARROW, TAB, UP_ARROW} from '@angular/cdk/keycodes';
import {RgiRxOptionComponent} from '../form-elements/rgi-rx-option/rgi-rx-option.component';
import {isEqual} from 'lodash';
import {RgiRxVirtualDOMError, walk} from '@rgi/rx';
import {Directionality} from '@angular/cdk/bidi';


export type RgiRxAutoCompleteDisplayWithFn<T> = (model: T) => string;

@Directive({
  selector: 'input[rgiRxAutoComplete], textarea[rgiRxAutoComplete]',
  exportAs: 'rgiRxAutoComplete',
  host: {
    autocomplete: 'off',
    'aria-haspopup': 'true',
  }
})
export class RgiRxAutoCompleteDirective implements OnInit, OnChanges, AfterContentInit, OnDestroy {

  private closingKeys = [ENTER, ESCAPE, DOWN_ARROW, UP_ARROW, RIGHT_ARROW, LEFT_ARROW];

  /**
   * @description The ref of the rgi-rx-autocomplete component instance
   */
  @Input('rgiRxAutoComplete') autoCompleteComponent: RgiRxAutoCompleteComponent;
  /**
   * @description Whether to open overlay on focus
   */
  @Input() openOnFocus = true;

  /**
   * @description Minimum characters before triggering changes
   */
  @Input() minLength = 0;


  /**
   * @description Weather the input should allow only matching items from the autocomplete options
   */
  @Input() onlyMatching = true;

  /**
   * @description Weather close the overlay by selection of an option
   */
  @Input() closeOnSelection = true;

  /**
   * @description Weather close the overlay when the input has blur
   */

  @Input() closeOnBlur = true;

  /**
   * A RgiRxAutoCompleteDisplayWithFn or a node path to change the viewValue of the model.
   * It's required when using the autocomplete with object models
   */
  @Input() displayWith: RgiRxAutoCompleteDisplayWithFn<any> | string;

  /**
   * Emit changes when a new value its set
   */
  @Output() onValueChange = new EventEmitter<any>();
  /**
   * Emit a change when the value of the input changes
   */
  @Output() onChange = new EventEmitter<any>();

  @Output() onClose = new EventEmitter<void>();

  @Output() onOpen = new EventEmitter<void>();


  private _overlayRef: OverlayRef;

  private focusSubscription: Subscription = Subscription.EMPTY;
  private optionClickSubscription: Subscription = Subscription.EMPTY;

  constructor(
    protected _elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    protected _vcr: ViewContainerRef,
    protected _overlay: Overlay,
    private directionality: Directionality,
    @Inject(DOCUMENT) protected document: any,
    @Optional() @Self() protected _ngControl?: NgControl,
  ) {
  }


  ngOnInit(): void {
    this.handleFocusSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.openOnFocus && !changes.openOnFocus.isFirstChange() && changes.openOnFocus.currentValue !== changes.openOnFocus.previousValue) {
      this.handleFocusSubscription();
    }
  }

  ngAfterContentInit(): void {
    if (this._ngControl && this._ngControl.value && typeof this._ngControl.value === 'object') {
      if (!this.displayWith) {
        throw new RgiRxVirtualDOMError('rgiRxAutoComplete input must provide [displayWith] when the model is of type object');
      }
      this.elementRef.nativeElement.value = this.getDisplayWithData(this._ngControl.value);
    }
    this.optionClickSubscription = this.autoCompleteComponent.onOptionClick$()
      .subscribe((option: RgiRxOptionComponent<any>) => {
        this.setCurrentValue(option);
        if (this.closeOnSelection) {
          this.elementRef.nativeElement.blur();
        }
      });
  }

  private setCurrentValue(option: RgiRxOptionComponent<any>) {
    const displayWithData = this.getDisplayWithData(option.value);
    const viewValue = displayWithData !== undefined ? displayWithData : option.value;
    this.updateValue(option.value, viewValue);
    this.autoCompleteComponent.setCurrentActiveItem(option);
    this.onValueChange.emit(option.value);
  }


  private getDisplayWithData(model: any): string | undefined {
    if (!this.displayWith) {
      return undefined;
    }
    if (typeof this.displayWith === 'string') {
      return walk(this.displayWith, model);
    }
    return this.displayWith(model);
  }

  private updateValue(value: any, viewValue?: any) {
    if (this._ngControl) {
      this._ngControl.control.setValue(value);
    }
    if (viewValue) {
      this._elementRef.nativeElement.value = `${viewValue}`;
    }
  }

  openOverlay() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      return;
    }

    this._overlayRef = this._overlay.create({
      width: this._elementRef.nativeElement.offsetWidth,
      maxHeight: 230,
      backdropClass: 'rgi-ui-auto-complete-overlay-backdrop',
      panelClass: 'rgi-ui-auto-complete-overlay',
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      positionStrategy: this.getOverlayPosition(),
      direction: this.directionality.value
    });
    const template = new TemplatePortal(this.autoCompleteComponent.rootTemplate, this._vcr);
    this._overlayRef.attach(template);
    this.onOpen.emit();
    this.handleClosingActions(this._overlayRef, this._elementRef.nativeElement).subscribe(() => this.closeOverlay());

  }


  @HostListener('blur', ['$event'])
  _handleBlur(event: Event) {
    this.deactivate();
    if (this.closeOnBlur) {
      this.closeOverlay();
    }
  }

  @HostListener('window:resize', ['$event'])
  _handleWindowResize(event: Event) {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.updateSize({
      width: this._elementRef.nativeElement.offsetWidth,
    })
    this.overlayRef.updatePosition();
  }

  closeOverlay() {
    this._overlayRef.detach();
    this.onClose.emit();
  }

  private getOverlayPosition() {
    return this._overlay
      .position()
      .flexibleConnectedTo(this._elementRef.nativeElement)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom'
        },
      ])
      .withFlexibleDimensions(false)
      .withPush(false)
  }


  handleClosingActions(overlayRef: OverlayRef, origin: HTMLElement) {
    return merge(fromEvent<MouseEvent>(this.document, 'click'), fromEvent<KeyboardEvent>(this.document, 'keydown').pipe(
      filter(keydown => {
        return this.closingKeys.indexOf(keydown.keyCode) > -1;
      })
    ))
      .pipe(
        filter((event: Event) => {
          const eventTarget = event.target as HTMLElement;
          const notOrigin = eventTarget !== origin; // the input
            const notOverlay = !!overlayRef && (overlayRef.overlayElement.contains(eventTarget) === false); // the autocomplete
          return notOrigin && notOverlay;
        }),
        takeUntil(overlayRef.detachments())
      );
  }

  @HostListener('input', ['$event'])
  handleInput($event: KeyboardEvent) {
    const target = $event.target as HTMLInputElement;
    const value = target.value as string;
    if (this.overlayRef && !this._overlayRef.hasAttached()) {
      this.openOverlay();
    }
    if (this.minLength <= 0 || value.length >= this.minLength) {
      this.onChange.emit(value);
    }
  }


  private deactivate() {
    if (this.onlyMatching && this._ngControl && this._ngControl.value) {
      const options = this.autoCompleteComponent.options.toArray();
      if (!options.find(opt => {
        const displayWithData = this.getDisplayWithData(opt.value);
        return isEqual(opt.value, this._ngControl.value) || !!(displayWithData && isEqual(displayWithData, this.elementRef.nativeElement.value));
      })) {
        this.clear();
      }
    } else {
      this.updateValue(this.elementRef.nativeElement.value);
    }
  }

  get activeOption(): RgiRxOptionComponent<any> {
    if (this.autoCompleteComponent) {
      return this.autoCompleteComponent.keyManager.activeItem;
    }
    return null;
  }

  @HostListener('keydown', ['$event'])
  _handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    if (keyCode === ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      this.closeOverlay();
      this.elementRef.nativeElement.blur();
    }
    if (this.activeOption && keyCode === ENTER && this._overlayRef.hasAttached()) {
      this.activeOption.setActiveStyles();
      event.preventDefault();
      this.setCurrentValue(this.activeOption);
      this.closeOverlay();
      this.elementRef.nativeElement.blur();
    } else if (this.autoCompleteComponent) {
      const prevActiveItem = this.autoCompleteComponent.keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (this._overlayRef.hasAttached() || keyCode === TAB) {
        this.autoCompleteComponent.keyManager.onKeydown(event);
      }

      if (isArrowKey || this.autoCompleteComponent.keyManager.activeItem !== prevActiveItem) {
        this._scrollToOption(event.target as HTMLElement);
      }
    }
  }


  ngOnDestroy(): void {
    if (this.isOpen()) {
      this.closeOverlay();
    }
    if (this.overlayRef) {
      this._overlayRef.dispose();
    }
    this.optionClickSubscription.unsubscribe();
    this.focusSubscription.unsubscribe();

  }

  clear() {
    if (this._ngControl) {
      this._ngControl.control.setValue('');
    }
    this._elementRef.nativeElement.value = '';
    this.onChange.next(this.elementRef.nativeElement.value);
  }

  private _scrollToOption(target: HTMLElement): void {
    const index = this.autoCompleteComponent.keyManager.activeItemIndex || 0;
    const labelCount = this._countOptionsBeforeCurrent(this.autoCompleteComponent.keyManager.activeItem);
    if (index === 0 && labelCount === 1) {
      this._overlayRef.overlayElement.scrollTop = 0;
    } else {
      this._overlayRef.overlayElement.scrollTop = this._getOptionScrollPosition(
        index + labelCount,
        target.offsetHeight,
        this._overlayRef.overlayElement.scrollTop,
        230
      );
    }
  }

  private _countOptionsBeforeCurrent(activeItem: RgiRxOptionComponent<any>): number {
    return this.autoCompleteComponent.options.filter(
      p => {
        return activeItem.value !== p.value && p.id < activeItem.id; // todo maybe check number comparison
      }
    ).length;
  }

  private _getOptionScrollPosition(optionIndex: number, optionHeight: number, currentScrollPosition: number, panelHeight: number): number {
    const optionOffset = optionIndex * optionHeight;

    if (optionOffset < currentScrollPosition) {
      return optionOffset;
    }

    if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
      return Math.max(0, optionOffset - panelHeight + optionHeight);
    }

    return currentScrollPosition;
  }


  get elementRef(): ElementRef<HTMLInputElement | HTMLTextAreaElement> {
    return this._elementRef;
  }


  get overlayRef(): OverlayRef | undefined {
    return this._overlayRef;
  }

  isOpen(): boolean {
    return this.overlayRef && this.overlayRef.hasAttached();
  }

  private handleFocusSubscription() {
    this.focusSubscription.unsubscribe();
    if (this.openOnFocus) {
      this.focusSubscription = fromEvent(this._elementRef.nativeElement, 'focus').subscribe(() => {
        this.openOverlay();
      });
    }
  }
}

import { FactorCode } from '../invest-standalone-session/utils/factor-code.enum';
import { Asset, RiskCommission, Unit } from '../models/policy.model';
import { LicObjectUtils } from './lic-object-utils';

/* eslint-disable max-len */
export class LicQuotationUtils {

  /**
   * Returns an object with units grouped by transcoder code.
   * @param assets - An array of Asset objects.
   * @returns An object with units grouped by transcoder code.
   */
  public static getUnitGroupedByTranscoderCode(assets: Asset[]): {[key: string]: Unit[]} {
    const unitsObj = {};
    if (!assets) {
      return unitsObj;
    }
    assets.forEach(asset => {
      asset.instances.forEach(inst => {
        inst.sections.forEach(sec => {
          sec.units.forEach(unit => {
            const trancod: string = unit.transcoderCode1;
            if (trancod) {
              if (unitsObj[trancod]) {
                unitsObj[trancod].push(unit);
              } else {
                unitsObj[trancod] = [unit];
              }
            }
          });
        });
      });
    });
    return unitsObj;
  }

  /**
   * Checks if a given risk code is found on a group of units.
   * @param unitsMap - An object containing an array of units for each transcoder code.
   * @param riskcode - The risk code to search for.
   * @param unit - The unit to check for the risk code.
   * @returns A boolean indicating whether the risk code was found on the group of units.
   */
  public static foundRiskOnGroup(unitsMap: {[key: string]: Unit[]}, riskcode: string, unit: Unit) {
    const trancod: string = unit.transcoderCode1;
    if (trancod) {
      const units: Unit[] = unitsMap[trancod] || [];
      return (!!units.find(u => u.riskCode === riskcode));
    }
    return false;
  }

  /**
   * Returns an array of risk codes for a given unit's transcoder code.
   * @param unitsMap - An object containing an array of units for each transcoder code.
   * @param unit - The unit for which to retrieve the risk codes.
   * @returns An array of risk codes for the given unit's transcoder code.
   */
  public static getRisksOnGroup(unitsMap: {[key: string]: Unit[]}, unit: Unit): string[] {
    const trancod: string = unit.transcoderCode1;
    if (trancod) {
      const units: Unit[] = unitsMap[trancod] || [];
      return units.map(u => u.riskCode);
    }
    return [];
  }

  /**
   * Sorts an array of RiskCommission objects in a specific order based on the order of their risk codes.
   * @param cloneRisks - The array of RiskCommission objects to be sorted.
   * @param groupedRisks - The array of risk codes that specifies the order in which the RiskCommission objects should be sorted.
   * @returns A new array of RiskCommission objects sorted in the specified order.
   */
  public static sortRiskCommissionInOrderToGroupRisks(cloneRisks: RiskCommission[], groupedRisks: string[]) {
    let riskOrdered = [];
    riskOrdered = cloneRisks.sort((a, b) => {
      const indexA = groupedRisks.indexOf(a.riskCode);
      const indexB = groupedRisks.indexOf(b.riskCode);

      // Se entrambi sono presenti nell'array di ordine, confronta gli indici
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // Se uno dei due non è presente nell'array di ordine, posizionalo alla fine
      if (indexA === -1) { return 1; }
      if (indexB === -1) { return -1; }

      // Altrimenti, mantieni l'ordine corrente
      return 0;
    });
    return riskOrdered;
  }

  /**
   * Returns a function that checks if the given field's code is equal to FactorCode.PURCHASE_AMOUNT.
   * @returns A function that takes a field and returns a boolean indicating if the field's code is equal to FactorCode.PURCHASE_AMOUNT.
   */
  public static getPurchaseAmountField(): any {
    const newLocal: any = f => f.code === FactorCode.PURCHASE_AMOUNT;
    return newLocal;
  }

  /**
   * Sums the risk premiums by group and returns the riskCommission array filtered by the first risk on the group with the final values and the other risks not grouped.
   * @param group - The group to sum the risk premiums for.
   * @param groupUnits - The units in the group.
   * @param risks - The risks to sum the premiums for.
   * @returns An array of RiskCommission objects with the summed premiums.
   */
  public static sumRiskPremiumsByGroup(group: string, groupUnits: { [key: string]: Unit[] }, risks: RiskCommission[]): RiskCommission[] {
    const units = groupUnits[group];
    if (units) {
      const groupedRisks = units.map(u => u.riskCode);
      const filteredRisks = risks.filter(r => groupedRisks.includes(r.riskCode));

      if (!!filteredRisks.length) {
        const cloneRisks: RiskCommission[] = LicQuotationUtils.sortRiskCommissionInOrderToGroupRisks(LicObjectUtils.cloneObject(risks), groupedRisks);
        cloneRisks.filter(r => groupedRisks.includes(r.riskCode)).reduce(LicQuotationUtils.reduceArrayBySummingPurchaseAmount());
        const firstElementWithGroupedValues = cloneRisks.find(r => groupedRisks.includes(r.riskCode));
        return []
        .concat([firstElementWithGroupedValues])
        .concat(risks.filter(r => !groupedRisks.includes(r.riskCode)));
      }
    }
  }

  /**
   * Returns a function that reduces an array of RiskCommission objects by summing the purchase amount of each object.
   * @returns A function that takes in four parameters: previousValue, currentValue, currentIndex, and array, and returns a RiskCommission object.
   */
  private static reduceArrayBySummingPurchaseAmount(): (previousValue: RiskCommission, currentValue: RiskCommission, currentIndex: number, array: RiskCommission[]) => RiskCommission {
    const returnFunction = (previousValue: RiskCommission, currentValue: RiskCommission): RiskCommission => {
      Object.keys(previousValue).forEach(key => {
        const isCommissionFormulaPresent = LicQuotationUtils.isCommissionFormulaPresent(key, previousValue);
        if (isCommissionFormulaPresent) {
          const sumValue = Number(previousValue[key].find(LicQuotationUtils.getPurchaseAmountField()).value) + Number(currentValue[key].find(LicQuotationUtils.getPurchaseAmountField()).value);
          previousValue[key].find(LicQuotationUtils.getPurchaseAmountField()).value = sumValue.toString();
        }
      });
      return previousValue;
    };
    return returnFunction;
  }

  private static isCommissionFormulaPresent(key: string, previousValue: RiskCommission) {
    const returnCondition = key === 'commissionFormula' && previousValue[key] && previousValue[key].find(LicQuotationUtils.getPurchaseAmountField()) && previousValue[key].find(LicQuotationUtils.getPurchaseAmountField()).value;
    return returnCondition;
  }
}

import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_ES: ClaimsTranslationsKeys = {
    _CLAIMS_TITLE_CARD_CAI_: 'Nuevo siniestro',
    _CLAIMS_TITLE_DIALOGUE_DASHBOARD_: 'Gestor de diálogos',
    _CLAIMS_TITLE_TIREA_DASHBOARD_: 'Tarjeta de pago Tirea',
    _CLAIMS_TITLE_DIALOGUE_HANDLER_LIST: 'Lista de Dialogo',
    _CLAIMS_TITLE_PAYMENT_TABLE_TYREA: 'Tabla de pagos de Tirea',
    _CLAIMS_TITLE_NEW_ACTOR_: 'Nuevo Actor',
    _CLAIMS_TITLE_HOSPITAL_BILLS_: 'Facturas Hospitalarias',
    _INVOICE: 'Factura',
    _NEW_PAYMENT: 'New payment',
    _COMPENSATION_LIST: 'Compensation list',
    _CLAIMS_CONNECTOR: 'Conector de Reclamaciones',
    _CHAT: 'CHAT',
    _EDIT_CLAIMS: 'Edit Claims',
    _ACTIVITY: 'Actividad',
    _CLAIMS_PILLS: 'Claims Pills',
    _AUTOSOFT_DASHBOARD: 'Autosoft Dashboard',
    _DOSSIER_MANAGEMENT: 'Documentos de siniestros',
    _CLAIM_DETAIL: 'Claim detail',
    _NEW_FNOL: 'Nuevo FNOL',
    _RCA_QUESTIONNAIRE: 'Cuestionario RCA',
    _CLAIM_SUBJECTS: 'Sujetos demandantes',
    _CLAIMS_FROM_ELASTIC: 'Claims from elastic',
    _EXPERTS: 'Expertos',
    _LETTER_TEMPLATES: 'Plantillas de cartas',
    _LIMITS_MANAGEMENT: 'Gestión de límites',
    _TIMELINE: 'Cronología',
    _INVOICES_LIST: 'Lista de facturas',
    _UPDATE_RESERVE: 'Update reserve',
    _CLAIMS_: {
        _TAX_REGIME: 'Régimen Fiscal',
        _IVA_REGIME: 'Régimen del IVA',
        _AGGREGATE_TAX_REGIME: 'Régimen fiscal agregado',
        _AGGREGATE_IVA_REGIME: 'Régimen de IVA agregado',
        _FISCAL_DATA: 'Datos fiscales',
        _ACCOUNTING_DATE: 'Fecha de contabilidad',
        _ACT_ACTIVE: 'Acto activo',
        _ACT_CLOSED: 'Acto cerrado',
        _ACT_EVASION_DATE: 'Acto cumplido fecha',
        _ACTION: 'Eliminar petición',
        _ACTIVE_CHATS: 'Chats activos',
        _ACTUAL_DATE_OF_OCCURRENCE: 'Fecha real del incidente',
        _ACTUAL_TIME_OCCURRENCE: 'Hora real del incidente',
        _ADD_ASSET: 'Añadir bien asegurado',
        _ADDRESS: 'Dirección',
        _ADMIN_LEVEL_1: 'Nivel administrativo 1',
        _ADMIN_LEVEL_2: 'Nivel administrativo 2',
        _ADMIN_LEVEL_3: 'Nivel administrativo 3',
        _ADMINISTRATIVE_REGULARITY: 'Regularidad administrativa',
        _AGENCY_UNIQUE_KEY: 'Clave única de la agencia',
        _AGENCY: 'Agencia',
        _ALLEGATION_DESCRIPTION: 'Descripción de la denuncia',
        _AMOUNT_CLEARED: 'Importe liquidado',
        _AMOUNT_HOLD_TAX: 'Importe de la retención a cuenta',
        _AMOUNT_TO_BE_PAYD: 'Importe a pagar',
        _ASSET: 'Bien asegurado',
        _ASSETS_DATA: 'Datos de bien asegurado',
        _ASSET_DESCRIPTION: 'Descripción del bien asegurado',
        _ASSIGNED_TO_A_GROUP: 'Asignado a un grupo',
        _ASSIGNMENT_SELECTION: 'Selección de asignación',
        _BODY: 'Cuerpo',
        _CANCELED_BY: 'Cancelado por',
        _CANCEL_REQUEST_DATE: 'Fecha comunicación baja',
        _CAP: 'ZIP',
        _CASH_PREVIDENTIAL_AMOUNT: 'Importe del fondo de cotizaciones sociales',
        _CERTIFIED_OCCURRENCE_DATE: 'Fecha del incidente certificada',
        _CERTIFIED_EVENT_DATE: 'Fecha del evento certificada',
        _CHASSIS_NUMBER: 'Número de chasis',
        _CDL_RECEIPT_DATE: 'Fecha de recepción de CHC',
        _CHAT_HISTORY: 'Historial del chat',
        _CHATS_WITH_ME: 'Chats conmigo',
        _CITY: 'Ciudad',
        _CIVIC_NUMBER: 'Número de la calle',
        _CLAIM_NUMBER_RECEIVED: 'Número de siniestro recibido',
        _CLAIM_SELECTION: 'Selección de siniestro',
        _CLAIMS: 'SINIESTROS',
        _CLAIMS_TECHNICAL_STATUS: 'Estado técnico del siniestro',
        _COMPANY_DESCRIPTION: 'Descripción de la empresa',
        _COMPLAINT_DATA: 'Datos de la denuncia',
        _CLAIM_NUMBER: 'Número siniestro',
        _CLAIM_POLICY: 'Claim Policy',
        _CLICK_HERE: 'haga clic aquí',
        _CLOSE: 'Close',
        _COHERENT: 'Coherente',
        _COLLECTION_DATE: 'Fecha de cobro',
        _COMMENTS: 'Comentarios',
        _COMPULSORY_QUEST: 'Compulsory question: ',
        _CONFIRM: 'Confirm',
        _CONTRACTOR: 'Contractor',
        _COMPANY: 'Código Entidad',
        _CORPORATE_LITIGATION: 'Corporate Litigation',
        _COUNT: 'Count',
        _COUNTERPARTY_PLATE_NUMBER: 'Matrícula Contrario',
        _CREATION: 'Creación',
        _DAMAGE_CATEGORY: 'Categoría de daños',
        _DAMAGE_POSITION: 'Posición de daño',
        _DAMAGED: 'Parte perjudicada',
        _DATE_RECEIVED_INTERMEDIARY: 'Fecha de recepción del intermediario',
        _DAMAGE_TO_THINGS: 'Daños a los bienes',
        _DAMAGED__ASSET: 'Parte perjudicada / Bien asegurado',
        _DAMAGED__INVOLVED_ITEM_DATA: 'Datos del elemento dañado/implicado',
        _DAMAGED_ASSETS: 'Bienes asegurados dañados',
        _DAMAGED_PARTS: 'Partes dañadas',
        _DEADLINE_DATA: 'Datos del plazo',
        _DELETED: 'Suprimido',
        _DEROGATION_LEVEL: 'Nivel de autorización',
        _DESELECT_ALL: 'Deseleccionar todo',
        _DETAILS: 'Información',
        _DEPARTMENT: 'Departamento',
        _LAST_EXECUTION_DETAILS: 'Last execution details',
        _SAVE_NEW_REQUEST: 'Save new request',
        _DOCUMENT_MANAGEMENT: 'Gestión de documentos',
        _DOCUMENTS_UPLOAD: 'Carga de documentos',
        _DRIVER: 'Conductor',
        _DRIVER_MANDATORY: 'Conductor obligatorio',
        _DRIVER_LICENSE_TITLE: 'Permiso de conducir',
        _RELEASED_BY: 'Released by',
        _RELEASED_DATE: 'Release date',
        _EXPIRATION_DATE: 'Fecha de caducidad',
        _CATEGORY: 'Category',
        _VALIDITY_START_DATE: 'Fecha de inicio de validez',
        _VALIDITY_END_DATE: 'Fecha final de validez',
        _ADD_CATEGORY: 'Add Category',
        _DUTIES_AMOUNT: 'Importe de los impuestos',
        _EDIT_LIMIT: 'Edición de límite',
        _EMAIL: 'Correo electrónico',
        _ERROR_CODE: 'Código ERROR',
        _EXCLUDE_FROM_CALCULATION: 'Excluir del cálculo',
        _EXCLUDED_FROM_CALCULATION: 'Excluido del cálculo',
        _EXPERT: 'Experto',
        _EFFECTIVE_COMPLAINT_DATE: 'Fecha efectiva de denuncia',
        _EXPERT_DATA: 'Datos del perito',
        _EXPERT_TYPE: 'Expert type',
        _FAX: 'Fax',
        _FEES_AMOUNT: 'Importe de los honorarios',
        _FIRST_REGISTRATION_DATE: 'Primera fecha de registro',
        _FISCAL_CODE__VAT_CODE: 'Código fiscal / núm. de IVA',
        _FNOL_DATA_COLLECTION: 'Recogida de datos FNOL',
        _GENDER: 'Género',
        _GENERAL_DATA: 'Datos generales',
        _GIVEN_FIRST_REGISTRATION: 'Given first registration',
        _GREEN_CARD_NUMBER: 'Número de carta verde',
        _GROSS: 'En bruto',
        _GROSS_EXCESS: 'Bruto',
        _INCONSISTENT_ROW_AT_LINE_: 'Fila inconsistente en la línea ',
        _METAINFO: 'Metainfo',
        _INDEMNITY_AMOUNT: 'Importe de la indemnización',
        _INITIAL_LIMIT_AMOUNT: 'Importe límite inicial',
        _INITIAL_LIMIT_VALUE: 'Valor límite inicial',
        _INJURY: 'Lesión',
        _INJURY_CODE: 'Código de lesiones',
        _INCIDENT_DATA: 'Datos del incidente',
        _INJURY_PHYSICAL: 'Lesión física',
        _INPS_CONTRIB_AMOUNT: 'Importe de contribución INPS',
        _INSERT: 'Insert',
        _INSERT_NEW_REQUEST: 'Insertar nueva solicitud',
        _INSERTION_DATE: 'Fecha Petición',
        _INSPECTOR: 'Inspector',
        _INSTANCE: 'Instancia',
        _INSURANCE_COMPANY: 'Compañía de seguros',
        _INSURED_ACTIVITY: 'Actividad asegurada',
        _INSUREDS: 'Asegurados',
        _INSURED_REPORT_DATE: 'Datos de los informes de los asegurados',
        _INVALID_FIELDS: 'Campo no válido',
        _INVOICE: 'Factura',
        _INVOICE_AMOUNT: 'Importe de la factura',
        _INVOICE_DATA: 'Datos de factura',
        _INVOICE_DETAILS: 'Datos de la factura',
        _INVOICE_NUMBER: 'Número de factura',
        _INVOICE_TYPE: 'Tipo de factura',
        _INVOICES: 'Facturas',
        _LETTER_DEROGATION_LEVEL: 'Nivel de autorización de la carta',
        _LETTER_TEMPLATE_SAVED: 'Plantilla de carta guardada',
        _LEVEL: 'Nivel',
        _LEVEL_CODE: 'Código de nivel',
        _LIMITS_POLICY_N: 'Límites póliza n.º',
        _MAIN_SUBSTATUS: 'Subestado principal',
        _MANAGE_PHASE: 'Fase de gestión',
        _MANAGEMENT_NOTE: 'Nota de gestión',
        _MANDATORY_DOCUMENTS: 'Documentos obligatorios',
        _MANUAL_EXCESS: 'Manual de franquicias',
        _MANUFACTURER: 'Fabricante',
        _MATERIAL_DAMAGE: 'Daños materiales',
        _MERGE_ABSORPTION_DATE: 'Fecha fusión-absorción',
        _MESSAGE: 'Mensaje',
        _MESSAGE_RESPONSE: 'Mensaje Respuesta',
        _MESSAGES: 'Mensajes',
        _MINOR: 'Menor',
        _MINORGUARDIAN: 'TUTOR MENOR',
        _MINISTERIAL_BRANCH: 'Clase ministerial',
        _MOBILE_PHONE: 'Teléfono móvil',
        _MODELS_LIST: 'Lista de plantillas',
        _MODIFY_FNOL: 'Modificar FNOL',
        _MOTOR_CARD: 'Tarjeta del motor',
        _NAME: 'Nombre',
        _NATION_ADDRESS: 'Dirección del país',
        _NATIONALITY: 'Nacionalidad',
        _NET: 'Neto',
        _NETGROSS_EXCESS: 'Franquicia neta/bruta',
        _NEW_COMPANY: 'Nueva entidad',
        _NEW_LETTER_MODEL: 'Nuevo modelo de carta',
        _NEW_LIMIT: 'Nuevo límite',
        _NEW_UPLOAD: 'Nueva carga',
        _NORMALIZE: 'Normalizar',
        _NOT_COHERENT: 'No es coherente',
        _NOTIFICATION_DATE: 'Fecha baja',
        _NOT_TAXABLE_AMOUNT: 'Base imponible exenta',
        _OBJECT: 'Concepto',
        _OCCURRENCE_TIME: 'Hora de incidente',
        _OCCURRENCE_DESCRIPTION: 'Descripción del incidente',
        _OBSERVATIONS: 'Observaciones',
        _OPENING_DATE_FROM: 'Fecha de apertura a partir de',
        _OPENING_DATE_TO: 'Fecha de apertura a',
        _OTHER_DOCUMENTS: 'Otros documentos',
        _OTHER: 'Otros',
        _OWNER: 'Propietario',
        _PARTY_INVOLVED: 'Parte implicada',
        _PARKED_VEHICLE: 'Vehículo estacionado',
        _PRODUCT: 'Producto',
        _PASSENGER: 'Pasajero',
        _PAYMENT_AMOUNT: 'Importe de pago',
        _PEC: 'Porcentaje',
        _PERC_PREV_FUND_1: 'Porcentaje prev fondo 1',
        _PERC_PREV_FUND_2: 'Porcentaje prev fondo 2',
        _PERC_WITH_HOLD_TAX: 'Porcentaje de retención a cuenta',
        _PERCENTAGES: 'Porcentajes',
        _PERSON: 'Persona',
        _PHONE: 'Teléfono',
        _PHYSICAL_INJURY: 'Lesión física',
        _PLATE_NUMBER: 'Matrícula asegurado',
        _POLICY: 'Póliza',
        _POLICY_DETAIL: 'Información de la póliza',
        _POLICY_HOLDER__INSURED: 'Tomador / Asegurado',
        _POLICY_LIST: 'Lista de pólizas',
        _PROVINCE: 'Provincia',
        _REAL_NAME: 'Nombre real',
        _RECEIVED_DATE: 'Fecha de recepción',
        _RECEIVED_DATE_FROM: 'Fecha de recepción de',
        _RECEIVED_DATE_TO: 'Fecha de recepción hasta',
        _RECIPIENT: 'Destinatario',
        _REGIME: 'Régimen',
        _REGISTRY: 'Registro',
        _REJECT_WORKED: 'Rechazar trabajado',
        _REPORT: 'Mensaje envío',
        _RESERVE: 'Reserva',
        _RESIDUE_LIMIT_AMOUNT: 'Importe límite residual',
        _RESIDUE_LIMIT_VALUE: 'Valor límite residual',
        _RISK_DESCRIPTION: 'Descripción del riesgo',
        _ROUNDING_AMOUNT: 'Importe de redondeo',
        _SAP_CODE: 'Código SAP',
        _SAP_RECORDING_DATE: 'Fecha de registro SAP',
        _SDI_CODE: 'Código SDI',
        _SEARCH_DOCTOR: 'Buscar médico',
        _SELECT_ALL: 'Seleccionar todo',
        _SELECT_ASSIGNMENT: 'Seleccionar asignación',
        _SELECT_AT_LEAST_ONE_USER: 'Seleccionar al menos un usuario',
        _SELECT_BY_DEFAULT: 'Seleccionar por defecto',
        _SELECT_CLAIM: 'Seleccionar siniestro',
        _SELECT_POLICY: 'Seleccionar póliza',
        _SELECT_RECIPIENT: 'Seleccionar destinatario',
        _SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST: 'Seleccione la asignación correspondiente de la lista',
        _SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST: 'Seleccione el siniestro correspondiente de la lista',
        _SELECT_THE_CORRESPONDING_TYPE_OF_LOSS_FROM_THE_LIST: 'Seleccione en la lista el tipo de siniestro correspondiente',
        _SELECT_TYPE_OF_LOSS: 'Seleccione el tipo de siniestro',
        _SELECTECT_SUBJECT: 'Seleccionar parte',
        _SENDING_DATE: 'Fecha envío',
        _SIC_OUTCOME: 'Resultado SIC',
        _SIMILAR_COMPLAINTS: 'Denuncias similares',
        _SIMILAR_SUBJECT: 'Parte similar',
        _STAMP_DUTY_AMOUNT: 'Importe del impuesto sobre actos jurídicos documentados',
        _SUBJECT_NAME: 'Nombre de la parte',
        _SUBJECT_SURNAME_OR_DENOMINATION: 'Apellido o denominación de la parte',
        _SUBTYPOLOGY: 'Subtipología',
        _SUBTYPOLOGY_CODE: 'Código de subtipología',
        _SUIT_NUMBER: 'Número de demanda',
        _SURNAME: 'Apellido',
        _SX_SUBJECT: 'SX_Sujeto',
        _TAX_ID: 'Identificación fiscal',
        _TECHNICAL_COVERAGE: 'Cobertura técnica',
        _TAXABLE_AMOUNT: 'Base imponible',
        _TOTAL_DISTRUCTION: 'Cancelación',
        _THINGS: 'Bienes',
        _THIRD_PARTY: 'Terceros',
        _TO_BE_EVALUATED: 'Pendiente de evaluación',
        _TO_BE_REEVALUATED: 'Para revaluar',
        _TO_SELECT: 'seleccionar',
        _TOPONYM: 'Nombre del lugar',
        _TOTAL: 'Total',
        _TOT_AMOUNT_UNIT: 'Importe unitario total',
        _TOTAL_RESERVES_AMOUNT: 'Importe total de las reservas',
        _TYPE_OF_LOSS_DESCRIPTION: 'Descripción del tipo de siniestro',
        _TYPE_OF_LOSS_SELECTION: 'Selección del tipo de siniestro',
        _TYPE_OF_DAMAGE: 'Tipo de daño',
        _TYPE_CIVIL__PENAL: 'Tipo Civil/Penal',
        _UNIQUE: 'Único',
        _UPDATE_REASON: 'Actualizar motivo',
        _USER_ASSOCIATION: 'Asociación de usuario',
        _USER_BY_WORKLOAD: 'Usuario (por carga de trabajo)',
        _USER_DATA: 'Datos del usuario',
        _USER_DEROGATION_LEVEL: 'Nivel de autorización del usuario',
        _VAT_AMOUNT: 'Importe IVA',
        _VAT_RATE: 'Tipo de IVA',
        _VEHICLE: 'Vehículo',
        _VISIBLE_DAMAGE_ON_VEHICLE: 'Daños visibles en el vehículo',
        _NUMBER_CLAIM: 'Número de siniestro',
        _OCCURRENCE_DATE: 'Fecha occurencia',
        _CLAIM_BRANCH: 'Sucursal del siniestro',
        _CLAIM_TYPE: 'Tipo de siniestro',
        _POLICY_NUMBER: 'Número de póliza',
        _POLICY_NR: 'Núm. de póliza',
        _CDL: 'CHC',
        _ADJUSTER: 'Liquidador',
        _PROCEDURE_NUMBER: 'Número de procedimiento',
        _COUNTERPART: 'Contraparte',
        _COUNTERPARTS: 'Counterparts',
        _INSURED: 'Asegurado',
        _COMPLAINT_DATE: 'Fecha de la denuncia',
        _COVERTURE_TYPE: 'Tipo de cobertura',
        _CLAIM_NUMBER_STATUS: 'Número y estado del siniestro',
        _COMPLAINT_NUMBER_STATUS: 'Número y estado de la denuncia',
        _OCCURRENCE_DATA: 'Datos del incidente',
        _DOCUMENTS: 'Documentos',
        _CANCELLATION_DATE: 'Fecha Cancelación Petición',
        _DESCRIPTION: 'Descripción',
        _EFFECT_DATE: 'Fecha de vigencia',
        _INVALID_POLICY: 'Póliza no válida',
        _POLICY_HOLDER: 'Titular',
        _UNITS: 'Unidad',
        _VALID_POLICY: 'Póliza válida',
        _MANAGEMENT_NODE: 'Nodo de gestión',
        _CONTRACT_STATUS: 'Estado del contrato',
        _FRACTIONING: 'Pago a plazos',
        _ISSUE_FRACTIONING: 'Emisión a plazos',
        _POLICY_TYPE: 'Tipo de póliza',
        _ISSUE_DATE: 'fecha de emisión',
        _COVERAGE_EXPIRATION_DATE: 'Fecha de caducidad de la cobertura',
        _COINSURANCE_SHARE: 'Cuota de coaseguro',
        _INITIAL_EFFECTIVE_DATE: 'Fecha inicial de vigencia',
        _RESCINDABILITY_DATE: 'Fecha de rescisión',
        _COINSURANCE_TYPE: 'Tipo de coaseguro',
        _PREMIUM_ADJUSTMENT: 'Ajuste de la prima',
        _INDEXING: 'Indexación',
        _LPS: 'LPS',
        _NO: 'No',
        _YES: 'Sí',
        _INDIRECT_COINSURANCE: 'Coaseguro indirecto',
        _MIGRATED: 'Migrado',
        _REINSURANCE: 'Reaseguro',
        _BONDED: 'Garantizado',
        _TACIT_RENEWAL: 'Renovación tácita',
        _SUBJECTS: 'Partes',
        _ARCHIVE_SUBJECTS: 'Archivo de partes',
        _LOCATIONS: 'Ubicaciones',
        _PRINCIPAL: 'Principal',
        _PROPERTY_ADDRESS: 'Dirección de la propiedad',
        _SECONDARY: 'Secundario',
        _ACTIVITIES: 'Actividades',
        _VEHICLE_DATA: 'Información del vehículo',
        _CLASS: 'Clase',
        _EXTENSION: 'Extensión',
        _SPECIAL_ABBREVIATION: 'Abreviatura especial',
        _PLATE_TYPE: 'Tipo de Matrícula',
        _BRAND: 'Marca',
        _MODEL: 'Modelo',
        _FACTORS: 'Factores',
        _CLAUSES: 'Cláusulas',
        _ADDITIONAL_DATA: 'Datos adicionales',
        _TYPE_OF_AUTHORITY: 'Tipo de autoridad',
        _DESCRIPTION_AUTHORITY: 'Descripción de la autoridad',
        _CATASTROPHIC_EVENT: 'Catástrofe',
        _DESCRIPTION_EVENT: 'Descripción del suceso',
        _ANTI_FRAUD_CATHEGORY: 'Categoría antifraude',
        _LAST_AUDIT_DATE: 'Última fecha de auditoría',
        _LIQUIDABLE: 'Liquidable',
        _NATION: 'País',
        _ZIP_CODE: 'Código postal',
        _BODYWORK: 'Carrocería',
        _SELECT: 'Seleccionar',
        _DENOMINATION: 'Denominación',
        _TELEPHONE: 'Teléfono',
        _ACCIDENT_CIRCUMSTANCE: 'Circunstancia del accidente',
        _BAREMS: 'Escalas',
        _BLACK_BOX_COHERENCE: 'Coherencia de caja negra',
        _ACCIDENTS: 'Accidentes',
        _INSURED_LIABILITY_PERC: 'Porcentaje de responsabilidad asegurada',
        _EVENT_DENIAL: 'Casos de denegación',
        _SIGN_TYPE: 'Tipo de señal',
        _INSURED_VEICHLE_POSITION: 'Posición del vehículo asegurado',
        _THIRD_PART_VEICHLE_POSITION: 'Posición del vehículo de terceros',
        _CALCULATED_LIABILITY: 'Responsabilidad calculada',
        _LIABILITY_TYPE: 'Tipo de responsabilidad',
        _COUNTERPART_LIABILITY_PERC: 'Porcentaje de responsabilidad de la contraparte',
        _DECLARED_LIABILITY: 'Responsabilidad declarada',
        _ANIA_CLASSIFICATION: 'Clasificación ANIA',
        _APPLY_TECH_LIABILITY_FORCED: 'Aplicar la responsabilidad técnica obligada',
        _NOTE: 'Nota',
        _APPLY_ANIA_LIABILITY_FORCED: 'Aplicar la responsabilidad obligada ANIA',
        _FORCE_REASON: 'Forzar motivo',
        _FORCED_LIABILITY: 'Forzar la responsabilidad',
        _EDIT_ACCIDENTS: 'Editar accidentes',
        _USE: 'Uso',
        _BUTTONS_: {
            _ACTIONS: 'ACCIONES',
            _BACK: 'ATRÁS',
            _CLOSE: 'CERRAR',
            _NEXT: 'SIGUIENTE',
            _NEW: 'NUEVO',
            _CANCEL: 'CANCELAR',
            _CONFIRM: 'CONFIRMAR',
            _EXIT: 'SALIR',
            _SAVE: 'GUARDAR',
            _EMPTY: 'VACÍO',
            _FIND: 'ENCONTRAR',
            _SHOW: 'MOSTRAR',
            _HIDE: 'OCULTAR',
            _INSERT: 'INTRODUCIR',
            _NEW_DIALOGUE: 'NUEVO DIÁLOGO',
            _ASSOCIATE: 'ASOCIACIÓN',
            _REQUEST: 'SOLICITUD',
            _RESET_ADVANCE: 'RESET ADVANCE',
            _SAVE_PES: 'SAVE PES',
            _CORRECT: 'CORRECT',
            _ADD: 'AÑADA',
            _SEARCH: 'SEARCH',
            _IBD: 'IBD',
            _OK: 'OK',
            _ADD_NOTE: 'AÑADIR NOTA',
            _FAILED_TRANSMISSION: 'FAILED TRANSMISSION',
            _EVENTS: 'EVENTS',
            _SEND: 'SEND',
            _DOWNLOAD_DOCUMENTI_SELEZIONATI: 'DOWNLOAD SELECTED DOCUMENTS',
            _DOWNLOAD_CLAIM_DOCUMENTS: 'DOWNLOAD CLAIM DOCUMENTS',
            _DOCVIEWER: 'DOCVIEWER',
            _DOWNLOAD_POLICY_DOCUMENTS: 'DOWNLOAD POLICY DOCUMENTS',
            _EDIT: 'EDITAR',
            _NEW_REQUEST: 'New Request',
            _RERUN: 'Rerun',
            _RESERVATION: 'RESERVATION',
            _ONDEMAND: 'ONDEMAND',
            _NEW_MANUAL_DEADLINE: 'Nueva fecha límite manual',
            _UPLOAD: 'UPOLAD',
            _TRANSMISSION: 'TRANSMISSION',
            _MODELS_LIST: 'Models list',
            _NEW_LETTER_MODEL: 'New letter model',
            _DELETE: 'DELETE',
            _ADD_ACTOR: 'ADD ACTOR',
            _RETRIEVE_INFORMATION: 'RETRIEVE INFORMATION',
            _REASSOCIATE: 'REASOCIAR',
            _MODIFY: 'MODIFY',
            _RESPONSE: 'RESPONSE',
        },
        _MESSAGE_: {
            _NO_RESULT: 'Ningún resultado',
            _OPENED_CLAIM_DO_ISSUE: 'Siniestro abierto por citación judicial',
            _NOT_IDENTIFIED_WITNESS: 'Testigo no identificado',
            _SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION: 'Seleccione solo las casillas de uso para la descripción',
            _SELECT_AT_LEAST_ONE_CAUSAL: 'Seleccione al menos un motivo antes de continuar',
            _INVALID_DATE_INCIDENT: 'La fecha introducida no puede ser diferente de la fecha comunicada.',
            _AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT: 'El importe a pagar no puede ser inferior a la suma de la base imponible y la base no imponible',
            _AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT: 'Importe a pagar distinto del importe de la factura menos el importe de la retención a cuenta',
            _AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO: 'El importe a pagar debe ser superior a cero',
            _ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY: '¡Advertencia! Se está abriendo un siniestro sobre una póliza técnicamente no cubierta',
            _ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY: 'Advertencia: apertura de siniestro sobre una póliza cancelada',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT: 'Advertencia: el pago supera los importes residuales del límite',
            _CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY: 'No se puede abrir un siniestro sobre una póliza cancelada',
            _CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY: 'No se puede abrir un siniestro sobre una póliza sin validez administrativa',
            _CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe del fondo de cotizaciones sociales no puede ser superior a la base imponible',
            _DRAG_A_FILE_TO_UPLOAD: 'Arrastre un archivo para cargarlo',
            _HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe de la retención a cuenta no puede ser superior a la base imponible',
            _IMPOSSIBLE_TO_EXECUTE_THE_REQUIRED_OPERATION_CLAIM_RECEIPTS_TO_AUTHORIZE: 'Imposible ejecutar la operación requerida: recibos de siniestros para autorizar',
            _IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'No se puede proceder: Usuario no autorizado a abrir un siniestro sobre una póliza técnicamente no cubierta',
            _INSERT_A_CSV_FILE: 'Insertar un archivo .csv',
            _INSERT_POLICY_NUMBER_OR_PLATE: 'Insertar número de póliza o matrícula',
            _INSERT_SUBJECT_SURNAME_OR_DENOMINATION: 'Insertar apellido o denominación de la parte',
            _INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT: 'Importe de la factura distinto de la suma de la base imponible, la base imponible exenta, el importe del fondo de cotizaciones sociales, el importe del impuesto sobre actos jurídicos documentados y el importe IVA',
            _INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'El importe de la factura debe ser superior a cero',
            _IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM: 'Imposible suprimir un documento ya cargado en el sistema de gestión de los documentos',
            _MISSING_CLAIM_NUMBER_AT_LINE: 'Falta el número de siniestro en la línea ',
            _MISSING_NOT_TAXABLE_AMOUNT_AT_LINE: 'Falta la base imponible exenta en la línea ',
            _MISSING_TAXABLE_AMOUNT_AT_LINE: 'Falta la base imponible en la línea ',
            _MISSING_TOTAL_UNIT_AMOUNT_AT_LINE: 'Falta el importe total por unidad en la línea ',
            _N_NOT_CONSISTENT: 'N Los daños del vehículo no concuerdan con los datos de la caja negra',
            _NO_ACTIVITY_TO_SHOW: 'No hay actividad que mostrar',
            _NO_CHANGE_MADE: 'Sin cambios',
            _NO_CHAT: 'Sin chat',
            _NO_DOCUMENTS_TO_UPLOAD: 'No hay documentos para cargar',
            _NO_NORMALIZED_ADDRESS_FOUND: 'No se ha encontrado ninguna dirección normalizada',
            _NO_RESULTS: 'No hay resultados',
            _POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY: 'Póliza no conforme con la categoría de daños seleccionada',
            _POLICY_NOT_COMPLIANT: 'Póliza no conforme con la categoría de daños seleccionada',
            _PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM: 'Pulse el botón de calcular antes de confirmar',
            _RESERVE_AMOUNT_NOT_CAPACIOUS_UPDATE_RESERVE_BEFORE: 'El importe de la reserva no es elevado: actualice primero la reserva',
            _S_CONSISTENT: 'Y Los daños del vehículo concuerdan con los datos de la caja negra',
            _SAVING_ERROR: 'Error de guardado',
            _SEARCH_ERROR: 'Error de búsqueda',
            _STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe del impuesto sobre actos jurídicos documentados no puede ser superior a la base imponible',
            _SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'La suma de la base imponible y de la base imponible exenta debe ser superior a cero',
            _SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma de la base imponible exenta de los datos distinta de la base imponible exenta de la factura',
            _SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma de la base imponible de los datos distinta de la base imponible de la factura',
            _SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT: 'Suma del importe unitario total de los datos distinta del importe de la factura',
            _TAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'La base imponible debe ser superior a cero',
            _THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE: 'El documento se suprimirá de forma permanente. ¿Desea continuar?',
            _THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE: 'La póliza también cubre los daños materiales de su vehículo',
            _TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST: 'El título y el nivel de autorización ya existen',
            _TOL_CLAIMS_MADE: 'Existen tipos de siniestros gestionadas con siniestros realizados / póstumos: se reharán los controles de validación técnica y administrativa en el apartado de tipo de siniestro',
            _TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'El importe total unitario debe ser superior a cero',
            _UNABLE_TO_MAKE_THE_PAYMENT_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED: 'Imposible efectuar el pago: importe residual del límite superado',
            _UPDATE_SUCCESSFUL: 'Actualización correcta',
            _VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'El importe IVA no puede ser superior a la base imponible',
            _WARNING_YOU_ARE_EXCEEDING_THE_CEILING: 'Advertencia: está sobrepasando el límite máximo',
            _X_TO_BE_VERIFIED: 'X Coherencia de los daños con los datos de la caja negra por verificar',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET: 'Debe seleccionar al menos un bien asegurado',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY: 'Debe seleccionar al menos una parte',
            _YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD: 'Necesita añadir un documento para ejecutar la carga',
            _TYPE_OF_BODYWORK_IS_MANDATORY: 'El tipo de carrocería es obligatorio',
            _NO_RES_FOUND: 'No se ha encontrado ningún resultado',
            _CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY: 'It is impossible to open a claim on an uncovered policy',
            _FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES: 'Fields marked in red are required or contain incorrect values',
            _INCORRECT_OR_MISSING_DATA: 'Incorrect or missing data',
            _THERE_ARE_TYPES_OF_LOSS_MANAGED_WITH_CLAIMS_MADE__POSTUMA: 'There are types of loss managed with claims made / postuma:',
            _TECHNICAL_AND_ADMINISTRATIVE_VALIDATION_CONTROLS_WILL_BE_REDONE_IN_THE_TYPE_OF_LOSS_SECTION: ' technical and administrative validation controls will be redone in the type of loss section',
            _CACHE_ERROR: 'Cache error',
            _ERROR_IN_INITIALIZATION_PROCEED_WITH_MANUAL_SELECTION: 'ERROR in initialization. Proceed with manual selection',
            _UPDATE_ERROR: 'Update error',
            _SEARCH_EXPERTS_ERROR: 'Search Experts Error',
            _SEARCH_REJECT_TYPES_ERROR: 'Search reject types error',
            _EXISTING_PES_ALERT: 'A PES/claim with same date occurred and policy is already present. Continue?',
            _UPDATE_RESERVE_SUCCESSFUL: 'Update reserve successful',
            _UPDATE_RESERVE_ERROR: 'Update reserve error',
            _HEIR_ALREADY_ADDED: 'Heir has already been added',
            _HEIR_DATE_INVALID: 'It is not possible to add fatal date for a subject chosen as an heir',
            _HEIR_IS_EQUAL_SUBJECT: 'The deceased subject cannot be added as a subject',
            _HEIR_INVALID: 'It is not possible to select a person with a fatal date as an heir',
            _HEIR_INVALID_FATAL_DATE: 'Heir removed due to death date, check heirs again',
            _ON_CONFIRMATION: 'Upon confirmation, the regularity of the policy will be checked based on the coverage selected',
            _YOU_CAN_INSERT_1_TO_2_ATTACHMENT: 'You can insert 1 to 2 attachments.',
            _ALLOWED_EXTENSION: 'Allowed extensions: txt, pdf, jpg.',
            _THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB: 'The size of an attachment cannot exceed 1 MB.',
            _COMPILING_LETTER_CONTENT: 'Compiling letter content',
            _OPENED_POST_LITIGATION: 'Opened post litigation',
            _PRESENCE_REQUEST_DAMAGE: 'Presence request damage',
            _WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT: 'Warning, the reserve has been reduced by the paid amount',
            _OF_WHICH_ATTORNEYS_FEES: 'Of which attorneys fees',
            _NO_BANK_ACCOUNT_FOR_THE_SUBJECT: 'No bank account for the subject',
            _NO_IBAN_PRESENT_OF_THE_PAYEE: 'No iban present of the payee',
            _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
            _INSERT_DATE: 'Insert date',
            _CLAIM_EXCESS_POLICY_VALUES: 'Claim excess policy values',
            _CLAIM_EXCESS_RESIDUE: 'Claim excess residue',
            _CLAIM_EXCESS_ERODED_AMOUNT: 'Claim excess eroded amount',
            _EXCESS_AGGREGATE_POLICY_VALUES: 'Excess aggregate policy values',
            _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue',
            _EXCESS_AGGREGATE_ERODED_AMOUNT: 'Excess aggregate eroded amount',
            _RECEIPT_ACCEPTED: 'Receipt accepted',
            _RECEIPT_REJECTED: 'Receipt rejected',
            _REASON_REJECTED: 'Reason rejected',
            _ONE_PAYEE_IS_REQUIRED: 'One payee is required',
            _ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED: 'One payee and one beneficiary are required',
            _ONE_PRIMARY_BENEFICIARIES_REQUIRED: 'One primary beneficiary required',
            _ONE_PRIMARY_PAYEE_REQUIRED: 'One primary payee required',
            _TAX_CODE__VAT_NUMBER_MISSING: 'Tax code/Vat number missing',
            _PAYEE_MUST_BE_ADULT: 'Payee must be an adult',
            _PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION: 'Payee not regstered, save subject with subject managment function.',
            _BEFORE_DELETE_ADD_NEW_BENEFICIARY: 'Before delete add new beneficiary',
            _BEFORE_DELETE_ADD_NEW_PAYEE: 'Before delete add new payee',
            _THE_CANCELLATION_DATE_IS_NOT_VALID: 'The cancellation date is not valid',
            _SELECT_REASON_CANCELLATION: 'Select cancellation reason',
            _METHOD_NOT_IMPLEMENTED: 'Method not implemented',
            _THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE: 'There is a payment withou associated documents, continue?',
            _SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED: 'Subject with incomplete tax data, unable to proceed',
            _OPERATION_NOT_AVAILABLE: 'Operation not available',
            _FEE_DATA_MISSING: 'Fee data missing',
            _MANDATORY_DATA_MISSING: 'Mandatory data missing',
            _SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS: 'Select the definition',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS: 'You have to select at least one type of loss',
            _THE_AMOUNT_FOR_THE_REASON_SELECTED: 'The amount for the reason selected',
            _INCOMPLETE_ADDRESS: 'Incomplete address',
            _INCOMPLETE_DATA: 'Incomplete data',
            _UIC_NOT_VALID__MAX_3_CHARACTER: 'Uic not valid, max 3 character',
            _FOREIGN_BANK_ACCOUNT_INVALID: 'Foreign bank account not valid',
            _IBAN_INVALID: 'Iban not valid',
            _INSERT_A_VALID_ITALIAN_IBAN: 'Insert a valid italian iban',
            _SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE: 'Special characters are not allowed in iban code',
            _RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE: 'Reserve amount not capacious, update reserve before',
            _IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossible to execute required operation,claim receipts to authorize',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT: 'Attention,the payment exceeds the residual amount of the limit',
            _SELECT_REASON_REVERSAL: 'Select reason reversal',
            _ENTER_NOTES: 'Enter notes',
            _THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0: 'The total amount cannot be greater than or equal to 0 ',
            _THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE: 'The credit note cannot be higher than the original invoice',
            _INVOICE_TYPE_REQUIRED: 'Invoice type required',
            _FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED: 'Fee number and fee date are required',
            _THE_REVERSAL_DATE_IS_NOT_VALID: 'The reversal date is not valid',
            _ERROR_CODE: 'Código de error',
            _ERROR: 'Error',
            _SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO: 'Specify event/assignmentNumber/date from-to',
            _SPECIFY_ONLY_CLAIM_NUMBER: 'Specify only claim number',
            _SPECIFY_CRITERIA: 'Specify a criteria',
            _SPECIFY_EVENT: 'Specify event',
            _SPEFCIFY_CLAIM_NUMBER: 'Specify claim number',
            _ALERT_NEW_DOC: 'Alert new doc',
            _EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH: 'Existing Requests for damages to match',
            _NO_REQUEST_DAMAGES: 'No hay daños',
            _REQUEST_FOR_DAMAGES_RECEIPT_DATE: 'Request for damages receipt date',
            _GENERIC_ERROR_ON_SAVE_QUESTIONNARIE: 'Error genérico al guardar el cuestionario.',
            _QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL: 'El cuestionario se actualizó correctamente',
            _QUESTIONNAIRE_MODIFICATION_CANCELLED: 'Modificación del cuestionario cancelada',
            _ENTER_A_VALID_IBAN: 'Enter a valid Iban',
            _INSERT_A_VALID_SPANISH_IBAN: 'Enter a valid Spanish Iban',
            _NO_NOTES_FOR_THIS_COMPENSATION: 'No notes for this compensation',
            _CHARACTER_LIMIT_EXCEEDED: 'Character limit exceeded',
            _AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE: 'An error occurred while adding the note',
            _VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT: 'Value at least one field between: name, surname, business name, contractor fiscal code, VAT code, code independent expert',
            _ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION: 'Are you sure you want to delete the trustee association?',
            _EXPERT_LINK_ADDED_WITH_SUCCESS: 'Expert link added with success!',
            _AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST: 'An error occurred while processing the request.',
            _EXPERT_ASSOCIATION_DELETED_CORRECTLY: 'Expert association deleted correctly!',
            _AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION: 'An error occurred while deleting the expert association',
            _IN_ERROR: 'In error',
            _SELECT_A_USER_BEFORE_CONFIRMING: 'Select a user before confirming',
            _OPERATION_PROPERLY_TERMINATED: 'Operation propely terminated',
            _INCOMPLETE_COMPILATION: 'Incomplete compilation',
            _FISCAL_CODE__VAT_UNCORRECT: 'Fiscal code/VAT uncorrect',
            _VAT_UNCORRECT: 'VAT uncorrect',
            _FISCAL_CODE_UNCORRECT: 'Fiscal code uncorrect',
            _SELECT_A_CRITERIA: 'Select a criteria',
            _IMPUTE_THE_NAME_OR_THE_FISCAL_CODE: 'Impute the name or the fiscal code',
            _SPECIFY_THE_TYPE_ASSET: 'Specify the type asset',
            _SELECT_AT_LEAST_ONE_REASON: 'Select at least one reason',
            _FILE_IS_EMPTY: 'File is empty',
            _MAXIMUM_FILE_SIZE_EXCEEDED: 'Maximum file size exceeded',
            _NOT_GENERATED_FOR_CHOICE: 'Not generated for choice',
            _GENERIC_ERROR_SAVE_ACTOR: 'Error al guardar un nuevo actor',
            _SAVE_NEW_ACTOR: 'Guardar nuevo actor',
            _SELECT_THE_DOCUMENT_TO_ATTACH: 'Select the documents to attach to the payment',
            _KO_UPLOAD_DOC: 'An error occurred while uploading the document',
            _CANCELLATION_DIALOGUE: '¿Estás seguro de que quieres cancelar el diálogo?',
        },
        _TITLE_: {
            _QUESTIONNAIRE_TITLE: 'Cuestionario',
            _SELECT_POLICY: 'Seleccionar póliza',
            _OCCURRENCE_DATA: 'Datos del incidente',
            _DATA_COLLECTION: 'Recogida de datos',
            _SUMMARY: 'Resumen',
            _DIALOGUE_HANDLER_LIST: 'Dialogue List',
            _DIALOGUE_HANDLER_NEW: 'Nueva demanda',
            _DIALOGUE_HANDLER_SUMMARY: 'Resumen Diálogo',
            _ADDRESS_MANAGEMENT: 'Address management'
        },
        _DATA_FORWARDING_CDL: 'Transmisión de datos a CHC',
        _INTERMEDIARY: 'Intermediario',
        _MASTER_POLICY_NUMBER: 'Número de póliza base',
        _APPLICATION_NUMBER: 'Número de solicitud',
        _COMPLAINT_STATE: 'Estado de la denuncia',
        _PLATE__CHASSIS: 'Chapa / Chasis',
        _ACCOUNTING_COVERAGE: 'Cobertura contable',
        _LEGAL_GUARDIAN_ROLE: 'Papel de tutor legal',
        _CAR_BODY_DETAIL: 'Información de la carrocería',
        _START_DATE_OF_COLLABORATION: 'Fecha de inicio de la colaboración',
        _END_DATE_COLLABORATION: 'Fecha de finalización de la colaboración',
        _HOUSE_NUMBER: 'Número de casa',
        _MUNICIPALITY: 'Municipio',
        _NEW_BODYWORK: 'Carrocería nueva',
        _SUBJECT_TYPE: 'Tipo de parte',
        _CODE: 'Código',
        _BUSINESS_NAME: 'Razón social',
        _FISCAL_CODE: 'Código fiscal',
        _VAT_CODE: 'Código de IVA',
        _TYPE_OF_BODYWORK: 'Tipo de carrocería',
        _INJURY_PHISICAL: 'Lesión física',
        _CAUSE_OF_INJURY: 'Causa de la lesión',
        _UNCODABLE: 'Incodificable',
        _MULTIPLE: 'Múltiple',
        _NATURE_OF_INJURY: 'Naturaleza de la lesión',
        _INJURY_LOCATION: 'Localización de la lesión',
        _FURTHER_INJURY_INDICATOR: 'Indicador de lesión adicional',
        _PROFESSIONAL_QUALIFICATION: 'Cualificación profesional',
        _PERCENTAGE_OF_PERMANENT_DISABILITY: 'Porcentaje de incapacidad permanente',
        _TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE: 'Días de incapacidad temporal sin secuelas',
        _FATALITY_DATE: 'Fecha de la muerte',
        _FIRST_AID_COUNTRY: 'País de primeros auxilios',
        _FIRST_AID_PLACE: 'Lugar de primeros auxilios',
        _HEALTHCARE_PROVIDERS: 'Proveedores de asistencia sanitaria',
        _BIOLOGICAL_DAMAGE: 'Daño biológico',
        _SUBROGATION_SOCIAL_ASS_EMPLOYER: 'Subrogación asistencia social/Empresario',
        _DOCTOR: 'Doctor',
        _SEARCH: 'Buscar',
        _SKULL: 'CRÁNEO',
        _SPINE_CERVICAL_COLUMN: 'COLUMNA VERTEBRAL-CERVICAL',
        _LEFT_SHOULDER: 'HOMBRO IZQUIERDO',
        _RIGHT_SHOULDER: 'HOMBRO DERECHO',
        _SPINE_THORACIC_COLUMN: 'COLUMNA VERTEBRAL-TORÁCICA',
        _SPINE_LUMBAR_COLUMN: 'COLUMNA VERTEBRAL-LUMBAR',
        _SPINE_SACRUS_AND_COCCYX: 'COLUMNA VERTEBRAL-SACRO Y CÓCCIX',
        _LEFT_ANKLE: 'TOBILLO IZQUIERDO',
        _RIGHT_ANKLE: 'TOBILLO DERECHO',
        _RIGHT_EAR: 'OREJA DERECHA',
        _RIGHT_EYE: 'OJO DERECHO',
        _LEFT_EYE: 'OJO IZQUIERDO',
        _LEFT_EAR: 'OREJA IZQUIERDA',
        _FACE: 'CARA',
        _NECK: 'CUELLO',
        _RIGHT_CHEST_WALL: 'PARED TORÁCICA DERECHA',
        _LEFT_CHEST_WALL: 'PARED TORÁCICA IZQUIERDA',
        _RIGHT_ARM: 'BRAZO DERECHO',
        _THORACIC_ORGANS: 'ÓRGANOS TORÁCICOS',
        _LEFT_ARM: 'BRAZO IZQUIERDO',
        _RIGHT_ELBOW: 'CODO DERECHO',
        _ABDOMINAL_ORGANS: 'ÓRGANOS ABDOMINALES',
        _LEFT_ELBOW: 'CODO IZQUIERDO',
        _RIGHT_FOREARM: 'ANTEBRAZO DERECHO',
        _ABDOMINAL_WALL: 'PARED ABDOMINAL',
        _LEFT_FOREARM: 'ANTEBRAZO IZQUIERDO',
        _PELVIS___RIGHT_HIP: 'PELVIS - CADERA DERECHA',
        _UROGENITAL_ORGANS: 'ÓRGANOS UROGENITALES',
        _PELVIS___LEFT_HIP: 'PELVIS - CADERA IZQUIERDA',
        _RIGHT_WRIST: 'MUÑECA DERECHA',
        _LEFT_WRIST: 'MUÑECA IZQUIERDA',
        _RIGHT_HAND: 'MANO DERECHA',
        _RIGHT_THIGH: 'MUSLO DERECHO',
        _LEFT_THIGH: 'MUSLO IZQUIERDO',
        _LEFT_HAND: 'MANO IZQUIERDA',
        _RIGHT_KNEE: 'RODILLA DERECHA',
        _LEFT_KNEE: 'RODILLA IZQUIERDA',
        _RIGHT_LEG: 'PIERNA DERECHA',
        _LEFT_LEG: 'PIERNA IZQUIERDA',
        _RIGHT_FOOT: 'PIE DERECHO',
        _LEFT_FOOT: 'PIE IZQUIERDO',
        _REQUIRED: 'Requerido',
        _HEIR: 'Heir',
        _HEIR_ADD: 'Add heir',
        _HEIRS: 'Heirs',
        _EXTERNAL_CLAIM_NUMBER: 'External claim number',
        _POTENTIAL_CLAIM_NUMBER: 'Número siniestro Potencial',
        _ALIGN_POLICY: 'Alinear Póliza',
        _ACTIONS: 'Actions',
        _DATE_ENTRY_FROM: 'Fecha de envío',
        _ELABORATION_DATE_FROM: 'Fecha de recepción sistema',
        _EXPIRY_DATE_FROM: 'Fecha de Caducidad regulatoria',
        _CLAIM_DATE_FROM: 'Fecha de siniestro',
        _FUNCTION: 'Convenio',
        _DAMAGE_MATCH: 'Damage Match',
        _TAX_ID_CODE: 'Tax Id Code',
        _COMPLAINT: 'Número siniestro Potencial',
        _NOT_ASSOCIATED: 'No asociado',
        _CTP_COMPANY: 'Código Entidad Contrario',
        _US_CAR_LICENSE_PLATE: 'Matrícula asegurado',
        _CTP_CAR_LICENSE_PLATE: 'Matrícula Contrario',
        _ACTIVE_TASK: 'Tareas abiertas',
        _ERROR_MESSAGE: 'Mensajes de error',
        _SIMILAR_FLAG: 'Similar Flag',
        _FILTER: 'Filter',
        _NOT_ASSOCIATED_DIALOGUES: 'Not Associated Dialogues',
        _DIALOGUE_DATE_START: 'Dialogue Date Start',
        _END_DATE: 'Fecha final',
        _SX_DATE: 'Siniestro Date',
        _DIALOGUE: 'Datos Dialogo',
        _EXTERNAL_KEY_DAMAGED: 'Identificador lesionado contrario',
        _INTERNAL_KEY_DEF: 'Internal Key definitive',
        _INTERNAL_KEY_TEMP: 'Internal Key temporary',
        _INTERNAL_KEY_DAMAGED_DEF: 'Internal Key Damaged definitive',
        _INTERNAL_KEY_DAMAGED_TEMP: 'Internal Key Damaged temporary',
        _INTERNAL_KEY_DAMAGED: 'Identificador lesionado',
        _US_TYPE_PLATE: 'Tipo Matrícula As.',
        _CTP_TYPE_PLATE: 'Tipo Matrícula Con.',
        _IDENTIFIER_UNIQUE_CODE: 'Código unico (TIREA)',
        _PARAMETERIZED_DIALOGUE_DATA: 'Datos diálogo dinámicos',
        _REGULATORY_EXPIRY_DATE: 'Fecha de Caducidad regulatoria',
        _LAST_UPDATE_DATE: 'Última actualización',
        _USER_UPDATE: 'Actualizar usuario',
        _ACTIVITY_TYPE: 'Tipo de acción',
        _PARAMETERS_UPPERCASE: 'PARÁMETROS',
        _REQUEST_NOTE: 'Nota de solicitud ',
        _RESPONSE_NOTE: 'Nota de respuesta',
        _DOCUMENTS_UPPERCASE: 'DOCUMENTOS',
        _DISCARDED_UPPERCASE: 'ERRORES',
        _CAUSAL: 'Causal',
        _EXPIRATION: 'Fecha de Caducidad',
        _SENDED: 'Enviado',
        _START_STATUS: 'Estado de envío',
        _DOCUMENT_TYPE: 'Tipo de documento',
        _DOCUMENT_TYPE_FLUX: 'Tipo de documento (Flux)',
        _CODE_ERROR: 'Código de error',
        _INPUT: 'Input',
        _OUTPUT: 'Output',
        _RESPONSE_NOTES: 'Notas de respusta',
        _DOCUMENTS_REQUIRED: 'Documents required for managing the selected answer',
        _SELECTED_DOCUMENTS: 'Select the documents to associate with the response by entering the flow document type for each',
        _ACTOR_INVOLVED: 'Actor Involved',
        _REASON: 'Reason',
        _DOCUMENT_TYPE_FLOW: 'Document Type Flow',
        _MANDATORY: 'Mandatory',
        _MATTER: 'Matter',
        _SUB_CATEGORY: 'Sub-Category',
        _LST_MSG_CODE: 'Código del último mensaje',
        _LST_MES_REQ_DATA: 'Fecha de solicitud (LST. MS.)',
        _LST_MES_REG_EXP: 'Fecha de caducidad (LST. MS.)',
        _START_DATE: 'Fecha de inicio',
        _INTERNAL_KEY: 'Referencia',
        _LST_RESP_MES_CODE: 'Código de respuesta (LST. MS.)',
        _LST_MSG_DATE: 'Fecha del último mensaje',
        _LST_MSG_INT_EXP: 'Fecha de caducidad interna (U.M)',
        _EXT_KEY_IDEF_DAM: 'Llave de referencia externa dañada',
        _ONGOING: 'EN CURSO',
        _EVADE: 'EVADE',
        _INSURANCE_CODE_US: 'Empresa asegurada',
        _ACTOR_NOMINATIVE: 'Actor asociado',
        _DETAILS_CLAIMS_DATA: 'Detalles Siniestro',
        _CONSOLIDATE: 'Consolidate',
        _BALANCE: 'Balance',
        _VALIDATION_AND_CONSOLIDATION: 'Validación&Carga',
        _PAYMENT_MANAGE: 'Gestión de Pagos',
        _FORFAIT_MANAGE: 'Gestión de Forfait',
        _YEAR: 'Año',
        _MONTH: 'Mes',
        _JANUARY: 'Enero',
        _FEBRUARY: 'Febrero',
        _MARCH: 'Marzo',
        _APRIL: 'Abril',
        _MAY: 'Mayo',
        _JUNE: 'Junio',
        _JULY: 'Julio',
        _AUGUST: 'Agosto',
        _SEPTEMBER: 'Septiembre',
        _OCTOBER: 'Octubre',
        _NOVEMBER: 'Noviembre',
        _DECEMBER: 'Diciembre',
        _INCAPACITATED: 'Incapacitated',
        _TYPES_OF_LOSS: 'Types of loss',
        _RISK_DATA: 'Risk data',
        _COVERTURE_DATA: 'Coverture data',
        _RULES_FOR_UPLOADING_DOCUMENTS: 'Rules for uploading documents:',
        _OCCURRENCE_HOUR: 'Occurence hour',
        _DATE_INTERMEDIARY: 'Date intermediary',
        _TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED: 'Type of authorities possibly involved',
        _CATASTROPHIC_EVENT_DESCRIPTION: 'Catastrophic event description',
        _OPENING_MODE: 'Claim opening mode',
        _PARTY: 'Parte',
        _ASSET_INSURED: 'Asset insurance',
        _NEW_DAMAGED: 'New damaged',
        _TYPE_OF_LOSS: 'Daño causal',
        _NOMINATIVE: 'Asunto implicado',
        _NO_RESULT: 'No results.',
        _NEW_ASSET: 'New asset',
        _ASSET_TYPE: 'Asset type',
        _TYPE: 'Tipo',
        _FOUND: 'Found',
        _COVERAGES_DETAIL: 'Coverages detail',
        _ADD_A_DAMAGED: 'Add a dameged',
        _PHYSICAL_PERSON: 'Physical person',
        _POLICY_DATA: 'Policy data',
        _ADMINISTRATIVE_VALIDITY: 'Administrative Validity',
        _LIST_OF_RELATED_CLAIMS: 'List of related claims',
        _CF__PIVA: 'CF/PIVA',
        _REFERENCE: 'Referencia',
        _DOL: 'DOL',
        _DON: 'DON',
        _MAIN: 'Main',
        _ORIGINAL_EFFECT_DATE: 'Original Effect date',
        _NO_POLICY_TO_SHOW: 'No policy to show',
        _ASSIGNMENT_DATE: 'Assignment Date',
        _STATUS: 'Estado',
        _FILTERS: 'Filters',
        _CLAIMS_HANDLER: ' Claims Handler',
        _TECHNICAL_STATE: 'Technical State',
        _RESERVED: 'Reservado',
        _INVOICE_DATE: 'Invoice Date',
        _AMOUNT: 'Monto',
        _REJECT_TYPE: 'Reject Type',
        _UPLOAD_DETAILS: 'Upload Details',
        _NUMBER: 'Number',
        _ACTOR: 'Actor',
        _UPLOAD: 'Upload',
        _EXPERT_INVOICE: 'Expert Invoice',
        _TAX_CODE__VAT_NUMBER: 'Tax Code/VAT Number',
        _MANUAL_NOTE: 'Manual note',
        _INSERT_A_NEW_MANUAL_NOTE: 'Insert a new manual note',
        _COMMENTS_FORM: 'Comments form',
        _THERE_ARE_NO_COMMENTS: 'There are no comments',
        _NO_PERMISSION: 'No permission',
        _CANCELLATION: 'Cancelación',
        _COMMENT: 'Comment',
        _TOTAL_ASSIGNMENT_EXPENSE_AMOUNT: 'Monto total de gastos de asignación',
        _TOTAL_RESERVE_FOR_LITIGATION: 'Reserva total para litigios',
        _FROM: 'Desde',
        _TO: 'Para',
        _QUICK_FILTERS: 'Quick filters',
        _ASCENDING_ORDER: 'Ascending order',
        _DESCENDING_ORDER: 'Descending order',
        _MOVEMENTS: 'Movimientos',
        _ACCESSES: 'Accesses',
        _ACTIVITY: 'Activity',
        _RESERVES: 'Reserves',
        _TITLES: 'Titles',
        _STATE: 'Estado',
        _ASSIGNEE_GROUP: 'Asignar grupo',
        _ASSIGNEE_USER: 'Asignar usuario',
        _RUN_DATE: 'fecha de ejecución',
        _PRIORITY: 'Prioridad',
        _SUSPENDED: 'Suspended',
        _COMPLETION_DATE: 'Completion date',
        _ESCAPE_METHODS: 'Escape methods',
        _RUN_ACTIVITY: 'Run Activity',
        _TAKE_CHARGE_ACTIVITY: 'Take charge activity',
        _REASSESS_TASK: 'Reassess task',
        _NO_SUGGESTIONS: 'No suggestions',
        _CRITERIA: 'Criteria',
        _ASSIGNMENT: 'Assignment',
        _EVENTS: 'Events',
        _R_PER: 'Return assignment (R-PER)',
        _A_COM: 'Communication (A-COM)',
        _S_RIF: 'Refuse assignment (S-RIF)',
        _R_FAT: 'Invoice  (R-FAT)',
        _TRANSMISSION_LIST: 'Trasmission list',
        _FAILED_TRANSMISSION_LIST: 'Failed trasmission list',
        _STARTER_EVENT: 'Evento starter',
        _REQUEST: 'Solicitud',
        _ID: 'Id',
        _WS: 'WS',
        _XML: 'Xml',
        _RESPONSE: 'Respuesta',
        _DETAIL: 'Detail',
        _VIEW_DETAIL_BLOW: 'View detail blow',
        _INSERT_DATE: 'Insert date',
        _ELABORATION_DATE: 'Elaboration date',
        _INSUFFICIENT_PERMISSIONS: 'Insufficient Permissions',
        _CLAIM: 'Número siniestro',
        _POTENTIAL_CLAIM_INQUIRY: 'Investigación de reclamación potencial',
        _VALIDATE: 'Validate',
        _INVALIDATE: 'Invalidate',
        _MODIFY: 'Editar',
        _SUBCATEGORY: 'Subcategory',
        _RECEPTION_CHANNEL: 'Reception channel',
        _FORMAT: 'Format',
        _SIZE: 'Size',
        _NUMBER_OF_PAGES: 'Number of pages',
        _RECEIIPT_DATE: 'Receipt date',
        _USER: 'User',
        _SUBTITLE: 'SubTitle',
        _DOC_KEY: 'Doc key',
        _POSTALIZATION_RESULT: 'Postalization result',
        _POSTALIZATION_DATE: 'Postalization date',
        _SHIPPING_TYPE: 'Shipping type',
        _VIEW_BY_CATEGORIES: 'View by categories',
        _UPLOAD_DATE: 'Upload date',
        _SUBCATEGORY_DEPRECATED: ' Subcategory deprecated',
        _CATEGORY_DEPRECATED: ' Category deprecated',
        _HISTORY: 'History',
        _OPERATIONS: 'Operations',
        _EDIT_DOCUMENT: 'Edit document data',
        _CHANGE_PARTY: 'Change party',
        _SELECT_CATEGORY: 'Select category',
        _SELECT_SUBCATEGORY: 'Select subcategory',
        _PEOPLE: 'People',
        _EXTERNAL_VISIBILITY: 'External visibility',
        _VEICHLE: 'Veichle',
        _VALIDATED: 'Validate',
        _NO_VALIDATE: 'No validate',
        _TECHNICAL_STATUS: 'Estado técnico',
        _RESERVE_AMOUNT: 'Cantidad de Reserva',
        _TECHNICAL_REACTIVATION_OPENING_DATE: 'Reactivación/fecha de apertura técnica',
        _ACCOUNTING_REACTIVATION_OPENING_DATE: 'Fecha de apertura de reactivación contable',
        _TECHNICAL_CLOSING_DATE: 'Fecha de cierre técnico',
        _NEW_QUESTIONNAIRE_DATE: 'New Questionnaire Date',
        _ROLE: 'Posición',
        _CODE_CHAIN: 'Code chain',
        _EXTERNAL_KEY: 'Referencia contrario',
        _CODE_ACTION: 'Code action',
        _DATA_ENTRY: 'Data entry',
        _OUTCOME: 'Outcome',
        _INSERTED: 'Inserted',
        _JSON_N: 'Json N°',
        _LAST_ELABORATION: 'Última fecha modificación',
        _CANCELLATION_REASON: 'Cancellation Reason',
        _ASSOCIATED_ENTITY: 'Associated Entity',
        _EXECUTIONS: 'Executions',
        _JSON_OK_N: 'Json OK n.',
        _JSON_KO_N: 'Json KO n.',
        _RERUN: 'Rerun',
        _RESERVATION_DATE: 'Reservation date',
        _IN_PROGRESS: 'In progress',
        _CLOSED: 'Closed',
        _FORCED_CLOSURE: 'Forced closure',
        _NOT_STOPPED: 'Not stopped',
        _STOPPED_SUCCESSFULLY: 'Stopped successfully',
        _RERUN_RESERVED: 'Rerun reserved',
        _RERUN_FAILURE: 'Rerun failure',
        _RESULTS: 'Results',
        _TITLE: 'Title',
        _INSERTION: 'Fecha de envío',
        _ELABORATION: 'Elaboration',
        _REQUEST_TYPE: 'Request type',
        _ELABORATION_REQUEST_DATE: 'Elaboration request date',
        _INPUT_PARAMETERS: 'Input parameters',
        _JSON: 'Json',
        _JSON_EXTERNAL_KEY: 'Json External key',
        _CREATION_FAILED: 'Creation failed',
        _REQUEST_CREATED: 'Request created',
        _RESERVATION: 'Reservation',
        _CREATION_ERROR: 'Creation error',
        _TOO_MANY_JSON_WERE_UPLOADED: 'Too many json were uploaded',
        _COMPANY_SETTLEMENT_AMOUNT: 'Monto de liquidación de la empresa',
        _PAYEE: 'Payee',
        _PAYMENT_TYPE: 'Tipo de pago',
        _BENEFICIARIES: ' Beneficiarios',
        _ADD_BENEFICIARY: 'Add beneficiary',
        _PAYEES: 'Beneficiarios',
        _ADD_PAYEE: 'Add Payee',
        _CALCULATE: 'Calculate',
        _REASON_CANCELLATION: 'Cancellation Reason',
        _C__O: 'C/O',
        _NO_DOCUMENTS: 'No Documents',
        _FEE_DATA: 'Fee Data',
        _FEE_NUMBER: 'Fee Number',
        _FEE_DATE: 'Fee Date',
        _IVA_AMOUNT: 'Iva amount',
        _IRPF_AMOUNT: 'IRPF amount',
        _WITH_HOLDING_AMOUNT: 'With holding amount',
        _NET_AMOUNT: 'Net amount',
        _CASH_AMOUNT_1: 'Cash amount 1',
        _CASH_AMOUNT_2: 'Cash amount 2',
        _SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED: 'Settlement blocked: % same invoice already reimbursed to insured',
        _PAID_AMOUNT: 'Paid Amount ',
        _PERCENTAGE_OF_THE_TOTAL: '% of the total ',
        _ALREADY_INSERT: 'Already insert',
        _REMAINING_AMOUNT: ' Remaining amount',
        _EVALUATION: ' Evaluation',
        _PAYMENT_DATA: 'Datos de pago',
        _CALCULATION_OF_THE_INSURED_FEE: 'Cálculo de la tarifa asegurada',
        _BENEFICIARIES_AND_PAYEES: 'Beneficiarias y beneficiarias',
        _METHODS_OF_PAYMENT: 'Métodos de pago',
        _LETTERS_MANAGEMENT: 'Letters managment',
        _RECEIPT_SUMMARY: 'Resumen de recibos',
        _SETTLEMENT_SUMMARY: 'Settlement summary',
        _PARTY_ROLE: 'Papel del partido',
        _OPERATION_USER: 'Usuario de operación',
        _QUITTANCE_DATE: 'Fecha de despido',
        _CURRENCY_DATE: 'Currency date',
        _PAYMENT_TRANSACTION_TYPE: 'Tipo de transacción del pago',
        _RECEIPT_TYPE: 'Tipo de recibo',
        _MANAGMENT_PAYMENT: 'Managment payment',
        _SEPARATE_RECEIPT: 'Recibo separado',
        _CURRENCY: 'Currency',
        _DUE_DATE: 'Due date',
        _PROCEDURE_CODE: 'Procedure code',
        _AMOUNT_QUOTA: 'Cuota del monto',
        _OPEN_DETAIL: 'Open detail',
        _FORFAIT_TYPE: 'Forfait type',
        _AMOUNT_QUOTA_FORFAIT: 'Cuota del monto forfait',
        _FORFAIT: 'Forfait',
        _IMPORT: 'Importe',
        _METHOD_OF_PAYMENT: 'Method of payment',
        _BANK_TRANSFER_DESCRIPTION: 'Descripción de la transferencia bancaria',
        _INCOMPLETE_ADDRESS: 'Incomplete address',
        _NOT_GENERATED_FOR_MISSING_DATA: 'Not generated for missing data',
        _GENERATED: 'Generated',
        _RETURN_OF_RECEIPT: 'Devolución de recibo',
        _RECEIPT_STATUS: 'Receipt status',
        _RECEIPT_DATE: 'Receipt date',
        _REASON_CANCEL_RECEIPT: 'Reason cancel receipt',
        _RECEIPT_NOTES: 'Receipt notes',
        _RETURN: 'Devolver',
        _BENEFICIARY: 'Beneficiario',
        _SENDING_LETTERS: 'Sending letters',
        _MANAGEMENT_PAYMENT: 'Managment payment',
        _PAYABILITY: 'Payability',
        _WAITING: 'Waiting',
        _SUBSTATUS: 'Substatus',
        _PAYMENT_DEFINITION: 'Definición del pago',
        _RESERVED_AMOUNT: 'Reserved Amount',
        _PARTIAL: 'Partial',
        _CHC: 'CHC',
        _LOAD_DATE: 'Load date',
        _OFFER_DATE: 'Offer date',
        _IBAN: 'Iban',
        _IBAN_ON_SUBJECT: 'Iban on subject',
        _ACCOUNT_HOLDER: 'Account holder',
        _BANK_DESCRIPTION: 'Bank description',
        _BANK_ADDRESS: 'Bank address',
        _SWIFT: 'Swift',
        _FOREIGN_BANK_ACCOUNT: 'Foreign Bank Account',
        _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
        _BIC: 'BIC',
        _UIC: 'UIC',
        _BANK: 'Bank',
        _LEGAL_OPPONENT: 'Legal opponent ',
        _REGISTER_SUBJECT: 'Register subject',
        _OPEN_REGISTER: 'Open register',
        _SETTLEMENTS_LIST: 'Settlements list',
        _RECEIPTS_LIST: 'Receipts list',
        _COMPENSATIONS_LIST: 'Compensation list',
        _APPOINTMENTS_LIST: 'Appointments list',
        _TYPOLOGIES_SETTLEMENT_LIST: 'Typologies settlement list',
        _ACCESSORY_CHECKS: 'Accessory checks',
        _FORCING_APPLY: 'Forcing apply',
        _ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO: 'Administrative validity overidden on policy no',
        _EFFECTIVE_DATE_OF_COLLECTION: 'Effective date of collection',
        _EXPIRY_DATE_OF_COLLECTION: 'Expiry date of collection',
        _DATE_OF_COLLECTION: 'Date of collection',
        _CONFIRM_RESPONSIBILITY: 'Confirm responsibility',
        _PERCENTAGE_INSURED_LIABILITY: 'Percentage insured liability',
        _REASON_TO_FORCE_LIABILITY_PERCENTAGE: 'Reason to force liability percentage',
        _COUNTERPART_VEHICLE: 'Counterpart vehicle',
        _CHASSIS__PLATE_NUMBER: 'Chassis plate number',
        _LIABILITY_PERCENTAGE: 'Liability percentage',
        _HISTORICAL_EROSION: 'Historical erosion',
        _INPUT_AMOUNT: 'Cantidad de entrada',
        _INSURED_SETTLEMENT_AMOUNT: 'Monto de liquidación asegurado',
        _CLAIM_EXCESS: 'Claim excess ',
        _DEDUCTIBLE: 'Deductible',
        _EXCESS_AGGREGATE: 'Excess aggregate',
        _SIR: 'Sir',
        _LIMIT: 'Limit',
        _FIRST_SIR: 'First sir',
        _SECOND_SIR: 'Second sir',
        _ADVANCE: 'Advance',
        _SIR_ADVANCED_BY_COMPANY_SOMETIMES: 'Sir advanced by company sometimes',
        _CAP_INDICATOR: 'Cap indicator',
        _CAP_AMOUNT: 'Cap amount',
        _EXCESS_AMOUNT: 'Excess amount ',
        _FIRST_SIR_POLICY_VALUES: 'First sir policy values ',
        _FIRST_SIR_RESIDUE: 'First sir residue ',
        _FIRST_SIR_ERODED_AMOUNT: 'First sir eroded amount ',
        _SIR_AMOUNT: 'Sir amount',
        _DEDUCTIBLE_POLICY_VALUES: 'Deductible policy values',
        _DEDUCTIBLE_RESIDUE: 'Deductible residue',
        _DEDUCTIBLE_ERODED_AMOUNTS: 'Deductible eroded amounts',
        _PERCENTAGE_AMOUNT: 'Percentage amount',
        _DEDUCTIBLE_AMOUNT_RESIDUE: 'Deductible amount residue',
        _CALCULATED_AMOUNT: 'Calculated amount',
        _MAXIMUM_AMOUNT: 'Maximum amount',
        _MINIMUM_AMOUNT: 'Minimum amount',
        _MAXIMUM_LEVELS_POLICY_VALUES: 'Maximum levels policy values',
        _MAXIMUM_LEVELS_RESIDUE: 'Maximum levels residue',
        _MAXIMUM_LEVELS_ERODED_AMOUNTS: 'Maximum levels eroded amounts',
        _RETURN_DATE: 'Return date ',
        _SETTLEMENT_REVERSAL: 'Settlement reversal ',
        _REVERSAL_DATE: 'Reversal date ',
        _REASON_REVERSAL: 'Reason reversal ',
        _PAYMENT_NUMBER: 'Payment number ',
        _PAYMENT_SAVING_SUMMARY: 'Payment saving summary',
        _ACTIVATED: 'Activated',
        _SETTLEMENT_NUMBER: 'Settlement number ',
        _ISSUED_CORRECTLY: 'Issued correctly ',
        _RISK: 'Risk ',
        _POLICY_ID: 'Policy ID ',
        _CLAIM_EXCESS_POLICY_VALUE: 'Claim excess policy value',
        _CLAIM_CAP_AMOUNT: 'Claim cap amount ',
        _CLAIM_EXCESS_AMOUNT: 'Claim excess amount ',
        _POLICY_ID_END_DATE: 'Policy id end date ',
        _POLICY_ID_START_DATE: 'Policy id start date',
        _CLAIM_CAP_INDICATOR: 'Claim cap indicator ',
        _CLAIM_EXCESS_ADVANCE: 'Claim excess advance ',
        _CLAIM_EXCESS_RESIDUE: 'Claim excess residue ',
        _CLAIM_EXCESS_LIQUIDATOR_ADVANCE: 'Claim excess liquidator advance ',
        _CLAIM_EXCESS_AMOUNT_ADVANCE: 'Claim excess amount advance ',
        _CLAIM_CAP_AMOUNT_RESIDUE: 'Claim cap amount residue ',
        _FIRST_SIR_ADVANCE: 'First sir advance ',
        _AGGREGATE_CAP_INDICATOR: 'Aggregate cap indicator ',
        _EXCESS_AGGREGATE_POLICY_VALUE: 'Excess aggregate policy value ',
        _AGGREGATE_CAP_AMOUNT: 'Aggregate cap amount ',
        _EXCESS_AGGREGATE_AMOUNT: 'Excess aggregate amount ',
        _EXCESS_AGGREGATE_ADVANCE: 'Excess aggregate advance ',
        _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue ',
        _EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE: 'Excess aggregate liquidator advance ',
        _AGGREGATE_CAP_AMOUNT_RESIDUE: 'Aggregate cap amount residue ',
        _EXCESS_AGGREGATE_AMOUNT_RESIDUE: 'Excess aggregate amount residue ',
        _SECOND_SIR_POLICY_VALUES: 'Second sir policy values ',
        _EXCESS_SECOND_SIR: 'Excess second sir ',
        _CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR: 'Claim liquidator advance second sir',
        _SECOND_SIR_RESIDUE: 'Second sir residue ',
        _CLAIM_DATA: 'Datos Siniestro ',
        _NOTIFY_DATE: 'Notify date ',
        _SEVERITY_CODE: 'Severity code ',
        _PHISICAL_INJURY_CODE: 'Phisical injury code ',
        _CASHED: 'Cobrada',
        _COLLECTED: 'Collected',
        _FISCAL_TAX_REGIME: 'Fiscal tax regime',
        _GENERATE_PAYMENT: 'Generate payment',
        _INDEMNITY_COST: 'Indemnity cost',
        _LEGAL_CODE: 'Legal code',
        _NEW_INVOICE: 'New invoice',
        _PARTY_CODE: 'Party code',
        _PRESENCE_LAWYERS: 'Presence lawyers',
        _PROTOCOLLO_SAP: 'Sap protocl',
        _PROTOCOLLO_SDI: 'SDI Protocol',
        _RECEIPTS: 'Receipts',
        _RESISTANCE_COST: 'Resistance cost',
        _SETTLEMENT_COST: 'Settlement cost',
        _SETTLEMENTS: 'Asentamientos',
        _TOTAL_AMOUNT: 'Monto total',
        _PAYMENTS_FIND: 'Payments find',
        _OPENING_DUE_TO_WRIT_OF_SUMMONS: 'Opening due to writ of summons',
        _APPLY_PERCENTAGE_LIABILITY_FORCING: 'Apply percentage liability forcing',
        _DAMAGE_CARD: 'Tarjeta de daño',
        _SEND_TO: 'Send to',
        _CURRENT_RESERVE: 'Current reserve',
        _NOTES: 'Notes',
        _COMPENSATIONS: 'Compensations',
        _MAGIC_STRING: 'Magic string',
        _INVOLVED_PARTIES__DAMAGE_CAUSE: 'Involved parties/Damage cause',
        _SETTLEMENT_COUNTERS: 'Settlement counters',
        _TYPE_OF_LOSS_COUNTERS: 'Type of loss counters',
        _TRUSTEE_DATA: 'Trustee data',
        _SUBJECT: 'Subject',
        _ROSTER_REGISTRATION_CODE: 'Roster registration code',
        _COURT_REGISTRATION_DATE: 'Court registration date',
        _TAX_OFFICE: 'Tax office',
        _ASSOCIATED_TRUSTEES: 'Associated trustees',
        _SUBJECT_IDENTITY: 'Subject identity',
        _TYPE_PROVIDER: 'Type provider',
        _OFFICE_HEADQUARTERS: 'Office Headquarters',
        _COUNTRY: 'Country',
        _SEARCH_FILTERS: 'Search filters',
        _VAT_NUMBER: 'Vat number',
        _EXPERT_TYPE_PROVIDER: 'Expert Type Provider',
        _DATE_OF_INSERTION: 'Fecha de inserción',
        _CANCELED: 'CERRADO',
        _UPDATE_TYPE: 'Actualizar tipo',
        _DATE: 'Fecha de siniestro',
        _TYPOLOGY: 'Typology',
        _CREATION_DATE: 'Creation date',
        _GROUP: 'Group',
        _BY_WORKLOAD: 'By Workload',
        _USERS_ASSIGNABLE_TASKS: 'Tareas asignables a los usuarios',
        _ORIGIN: 'Origin',
        _LAST_UPDATE: 'Last update',
        _CANCELED_POLICY: 'Canceled policy',
        _EMISSION_DATE: 'Emission date',
        _POLICY_REPLACED_NUMBER: 'Policy replaced number',
        _POLICY_REPLACEMENT_NUMBER: 'Policy replacement number',
        _SUSPENSION_DATE_FROM: 'Suspension date from',
        _SUSPENSION_DATE_TO: 'Suspension date to',
        _PREMIUM_DATE_FROM: 'Premium date from',
        _PREMIUM_DATE_TO: 'Premium date to',
        _OUT_OF_RANGE: 'Out of range',
        _DIARIA_DAYS: 'Diaria days',
        _ARREARS_DAYS: 'Arrears days',
        _PRODUCT_CODE: 'Product code',
        _PRODUCT_DESCRIPTION: 'Product description',
        _DIARIA: 'Diaria',
        _LOCATION: 'Location',
        _TERM_CODE: 'Term code',
        _DAYS: 'Days',
        _SELECTABLE: 'Selectable',
        _POSTAL_CODE: 'Postal code',
        _POLICY_CONDITION: 'Policy condition',
        _POLICY_CEILING: 'Policy ceiling',
        _MINIMUM_UNCOVERED_VALUE: 'Minimum uncovered value',
        _SEARCH_RESULTS: 'Search results',
        _UPDATE_DATE: 'Update date',
        _EXCESS: 'Excess',
        _BRANCH: 'Branch',
        _SURNAME__BUSINESS_NAME: 'Surname/Business name',
        _PERSON_TYPE: 'Person type',
        _LEGAL_PERSON: 'Legal person',
        _EDIT_SUBJECT: 'Edit subject',
        _UPDATE_RESERVE: 'Update reserve',
        _REOPENING_DATA: 'Datos de Reapertura',
        _REOPENING_REASON: 'Motivo de Reapertura',
        _SETTLEMENT_COSTS: 'Settlement costs',
        _VIEW_FOR_ACTORS: 'View for Actors',
        _NEW_MANUAL_DEADLINE: 'Nueva actividad manual',
        _QUOTA_AMOUNT: 'Importe de cuota',
        _AMOUNT_ACTIVE: 'Activo importe',
        _AMOUNT_RESERVE_QUOTA: 'Cuota de reserva activa',
        _OPENING_DATE: 'Fecha de apertura',
        _CLOSING_DATE: 'Fecha de cierre',
        _REFUSED_DATE: 'Fecha rechazada',
        _COMPENSATION_DIARY_N: 'Compensation Diary N.',
        _NEW_NOTE: 'New note',
        _EXTRA_INFO: 'Extra info',
        _NO_DATA: 'No data',
        _CONV_TYPE: 'Convention Type',
        _INVALID_PARAMETER_FORM: 'Invalid parameter form',
        _NO_PARTIES: 'No parties',
        _SELECT_ACTOR_ROLE: 'Select actor role',
        _NEW_ACTOR_LIST: 'Actor list',
        _ASSETS_LIST: 'Assets list',
        _DAMAGED_ASSET: 'Damaged asset',
        _ADD_OTHER_ASSET: 'Add new damage',
        _ACTOR_ROLE: 'Actor role',
        _SOLD: 'Sold',
        _RISK_FACTORS: 'Risk Factors',
        _THING: 'Thing',
        _VEHICLE_ASSET: 'Vehicle Asset',
        _EVENT_RISK: 'Event risk',
        _EVENT: 'Evento',
        _NO_REASON_MEETS_THE_SEARCH_CRITERIA: 'No reason meets the search criteria mettila in uppecase',
        _RESIDENCE: 'Residenza',
        _DOMICILE: 'Domicilio',
        _ADD_NEW_ADDRESS: 'Añadir una nueva dirección',
        _DIALOGUE_DESCRIPTION: 'Concepto',
        _INSURED_PLATE_NUMBER: 'Matrícula asegurado',
        _COMPANY_CODE: 'Código entidad',
        _INVOLVED_PARTY_IDENTIFIER: 'Identificador lesionado',
        _INVOLVED_PARTY_KEY: 'NIF',
        _NUMBER_REQUEST: 'Número Petición',
        _RESPONSE_IN: 'Respueta (IN)',
        _REQUEST_OUT: 'Solicitud (OUT)',
        _REQUEST_IN: 'Solicitud (IN)',
        _RESPONSE_OUT: 'Respuesta (OUT)',
        _RESPONSE_INPUT: 'Respuesta (Input)',
        _REQUEST_INPUT: 'Solicitud (Input)',
        _REQUEST_OUTPUT: 'Solicitud (Output)',
        _RESPONSE_OUTPUT: 'Respuesta (Output)',
        _SIMILAR_DIALOGUES: 'Diálogos similares',
        _CANCEL_MESSAGE: 'Cerrado con éxito!',
        _CF_PIVA: 'CF/PIVA',
        _LOSS_DATE: 'Fecha de perdida',
        _VERSION: 'Version',
        _CODE_MESSAGE: 'Código del mensaje',
        _PAYMENT_DELETE_CHECK_QUESTION: '¿Está seguro de que desea eliminar el siguiente elemento de la tabla?',
        _ERROR_DURING_SAVING: 'Error al guardar!',
        _NOT_CORRECT_ENTRY_DATA: 'Los datos introducidos no son correctos!',
        _WRONG_DATES: 'Las fechas introducidas no son válidas!',
        _MANDATORY_FUNCTION_CODE: 'Código de función obligatorio!',
        _INCORRECT_AMOUNT: 'El importe introducido no es válido',
        _ALREADY_EXISTS_RECORD: "Existe un Forfait en el mismo período de tiempo indicado",
        _NEW_FORFAIT : 'Nuevo forfait'
    }
};


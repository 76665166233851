import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComponentWithAnagModal } from '../../interfaces/component-with-anag-modal';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../models/consts/lpc-consts';
import { InputFieldDefinition, LienTypesCreditor } from '../../models/postsales-operations-response.model';
import { AnagSubject } from '../../models/subject.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcDateUtils } from '../../utils/plc-date-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { Roles } from '../../models/enum/lpc-subjects.enum';

@Component({
  selector: 'lpc-open-lien',
  templateUrl: './open-lien.component.html',
  styleUrls: ['./open-lien.component.css']
})
export class OpenLienComponent  extends AbsOperationComponent implements OnInit, ComponentWithAnagModal {

  protected operationDataKey = 'lienOpenInfo';
  public lienDates: InputFieldDefinition[];
  public lienTypesCreditor: LienTypesCreditor[];

  // TODO: valorizzare questa variabile con il ruolo che ci scenderà a BE
  roleCodeToAdd = Roles.VINCOLATARIO;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anagService: AnagService,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authenticationService: any
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anagService);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      operationRoles: new UntypedFormControl(),
      openLien: new UntypedFormGroup({
        lienTypeCreditor: new UntypedFormControl(null, [Validators.required]),
        creditor: new UntypedFormControl(null, [Validators.required])
      })
    });
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.lienDates = result.definitions.lienDates as InputFieldDefinition[];
        this.lienTypesCreditor = result.definitions.lienTypesCreditor as LienTypesCreditor[];
        this.initLienForm(result);
      }),
      this.formGroup.get('openLien').valueChanges.subscribe(el => {
        this.$feErrors = [];
      })
    );
  }

  public showSummaryValue(controlName) {
    return !this.isActiveStep('openLien') && this.formGroup && this.formGroup.get('openLien')
      && this.formGroup.get('openLien').get(controlName) && this.formGroup.get('openLien').get(controlName).value;
  }

  setCreditor(creditor: any) {
    if (!creditor) {
      return;
    }
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.anagService.getSubject(creditor, this.authenticationService.getOperator().salePoint.objectId).subscribe(respSubject => {
      if (!!respSubject && !!respSubject.subject) {
        this.receiveAnagSubjectFromModal(respSubject.subject);
      }
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    }, err => {
      console.error(err);
    });
  }


  protected initLienForm(result: any) {
    this.lienDates.map(date => {
     if (date.code === '_LIEN_OPEN_DATA' ) {
      (this.formGroup.get('openLien') as UntypedFormGroup).addControl(
        date.code,
        new UntypedFormControl(PlcDateUtils.isoToDate(this.formGroup.value.dates._1OEFF))
      );
      this.formGroup.get('openLien').get(date.code).setValue(PlcDateUtils.isoToDate(this.formGroup.value.dates._1OEFF));
     } else {
      (this.formGroup.get('openLien') as UntypedFormGroup).addControl(
        date.code,
        new UntypedFormControl(PlcDateUtils.isoToDate(date.defaultValue))
      );
     }
     if (!date.editable) {
        this.formGroup.get('openLien').get(date.code).disable();
      }
    });
    const lienData = result.data.operationData.data;
    if (!!lienData) {
      this.formGroup.get('openLien').patchValue({
        lienTypeCreditor: lienData.creditorType,
        _LIEN_EXPIRE_DATA: PlcDateUtils.isoToDate(lienData.expireDate)
      });
      this.setCreditor(lienData.creditor);
    }
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      tap(result => {
        this.initLienForm(result);
      })
    );
  }

  public deleteSbj(event) {
    this.formGroup.get('openLien').get('creditor').setValue(null);
  }

  public openAnagSubjectModal() {
    this.anagService.openSubjectModal(this);
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    this.formGroup.get('openLien').get('creditor').setValue({ id: subject.objectId, name: subject.nominative });
  }

  getTransformedOperationData() {
    const lienTypeCreditor = this.formGroup.get('openLien').get('lienTypeCreditor').value;
    const creditor = this.formGroup.get('openLien').get('creditor').value;
    return  {
      creditorType: lienTypeCreditor,
      openDate: PlcDateUtils.isoToDate(this.formGroup.value.dates._1OEFF),
      expireDate: this.formGroup.get('openLien').get('_LIEN_EXPIRE_DATA').value,
      creditor: !!creditor ? creditor.id : null,
    };
  }

  get lienTypeDescriptor() {
    if (!!this.formGroup.get('openLien').get('lienTypeCreditor').value) {
      const type = this.lienTypesCreditor.find(lien => lien.codice === this.formGroup.get('openLien').get('lienTypeCreditor').value);
      return !!type ? type.descrizione : EMPTY_STR;
    }
    return EMPTY_STR;
  }
}

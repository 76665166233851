import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { EventNotificator } from './../../../event-notificator';
import { PolicyRole } from './../../../models/policy.model';
import { LifeRoleService } from './../../../services/life-role.service';
import { PolicyService } from './../../../services/policy-service';
import { Subject } from './../../../../life-card/models/subject.model';
import { AnagEditPartyResolver } from '@rgi/anag';


@Component({
  selector: 'lic-select-subject',
  templateUrl: './select-subject.component.html',
  styleUrls: ['./select-subject.component.css']
})
export class SelectSubjectComponent implements OnInit, EventNotificator {

  @Input() parentForm: UntypedFormGroup;
  @Input() val: PolicyRole;
  @Input() role: string;
  @Input() wholeColumn = false;
  @Input() submitted = false;
  @Input() showPercentage: boolean;
  @Input() disableDelete = false;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() subjetAnagEditedEvent = new EventEmitter<{ opened: boolean, subject: Subject, role: string}>();

  public select;
  abc: any;
  get isEmpty(): boolean {
    return (this.val === null || this.val === undefined);
  }

  constructor(public lifeRoleService: LifeRoleService,
              public policyService: PolicyService,
              public translateService: TranslationWrapperService,
              protected anagEditResolver: AnagEditPartyResolver) {
              this.select = this.translateService.getImmediate('lic_select');
  }

  ngOnInit(): void { }

  public fill() {
    return this.lifeRoleService.fill(this.val);
  }

  public resetMethod() {
    this.delete.emit(this.val);
    if (this.parentForm.get('val') != null) {
      this.parentForm.get('val').setValue(null);
    }
    if (this.parentForm.get('perc') != null) {
      this.parentForm.get('perc').setValue(null);
    }
    this.val = null;
  }

  public openAnag() {
    this.eventPropagation.emit({
      eventName: 'requireSubject',
      sessionParent: 'anagFinder',
      sessionRoute: 'home',
      sessionTitle: 'Seleziona soggetto',
      navigationDisabled: true,
      roleCode: this.role
    });
  }


  editSubject(subjId: string, role: string) {
    this.openSubjectModifyModal(this.lifeRoleService.storedSubjectMap.get(subjId), role);
  }

  openSubjectModifyModal(subject: Subject, role: string) {
     return this.anagEditResolver.editParty(
       subject as any,
       this.lifeRoleService.getAnagFlowData(role, subject.subjectType.codice)
     ).subscribe(editedParty => {
       return this.openedAnagModifySession({ opened: true, subject: editedParty, role });
     });
  }

  openedAnagModifySession(obj: { opened: boolean, subject: Subject, role: string}) {
    this.lifeRoleService.storeSubject(obj.subject);
    this.subjetAnagEditedEvent.emit(obj);
  }

  isDeleteDisabled() {
    return this.disableDelete ? 'disabled' : '';
  }

}

import {PNC_POSTSALES_OPERATIONS_TYPES} from '../enums/pnc-postsales-operations-types';
import {GenericEntity} from './common/entities';

export class PncPostsalesInputData {
  policyNumber: string;
  operationType: PNC_POSTSALES_OPERATIONS_TYPES | string;
  currentOperation: GenericEntity;
  operations: GenericEntity[];
  draftId: string;
  contractId: string;
  idParentSession: string;
  showQuotation: boolean;
  notes: string;
  routesPrefix: string;
}

<div class="container-fluid">
    <rgi-rx-datatable [sortable]="false" [data]="this.tableData" [schema]="schema" [sortHeader]="true" [selectable]="false"
        [pageOptions]="[5,10,20]" [expansionRow]="rowDetail" [expansionModel]="customExpansionModel">
        
        <ng-template rgiRxDataTableCell="codFunction" let-ctx>
            <div *ngIf="ctx.row?.codFunction">
                <span>{{ ctx.row?.codFunction?.code }} </span>
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="companyCodeUs" let-ctx>
            <div *ngIf="ctx.row?.companyCodeUs">
                <span>{{ ctx.row?.companyCodeUs?.descr }} </span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="role" let-ctx>
            <div *ngIf="ctx.row?.role">
                <span>{{ ctx.row?.role?.descr }} </span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="lossDate" let-ctx>
            <div *ngIf="ctx.row?.lossDate">
                <span>{{ this.transformHtmlDate(ctx.row?.lossDate) }}</span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="state" let-ctx>
            <div *ngIf="ctx.row?.state">
                <span>{{ ctx.row?.state?.descr }} </span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="forced" let-ctx>
            <div>
                <rgi-rx-form-field>
                    <input 
                        type="checkbox" 
                        [(ngModel)]="ctx.row.forced"
                        (ngModelChange)="setForzatura(ctx.row)"
                        [disabled]="isDisabledForzatura(ctx.row)"
                        rgiRxInput
                    >
                </rgi-rx-form-field>
            </div>
        </ng-template>
        
        <ng-template rgiRxDataTableCell="skipped" let-ctx>
            <div>
                <rgi-rx-form-field>
                    <input 
                        type="checkbox" 
                        [(ngModel)]="ctx.row.skipped"
                        (ngModelChange)="setSkip(ctx.row)"
                        [disabled]="isDisabledSkip(ctx.row)"
                        rgiRxInput
                    >
                </rgi-rx-form-field>
            </div>
        </ng-template>
    </rgi-rx-datatable>

    <ng-template #rowDetail let-ctx>
        <div class="row infoAccordion" *ngFor="let column of this.accordionRowN; let columnIndex = index">
            <div class="col" *ngFor="let row of this.accordionColumnN; let rowIndex = index">
                <span class="accordionTitle">{{ this.accordionKeys[(columnIndex * this.numberElementsRow) + rowIndex] }}</span>
                <br><br><span class="accordionBody">{{ this.accordion[this.accordionKeys[(columnIndex * this.numberElementsRow) + rowIndex]] }}</span>
            </div>
        </div>
    </ng-template>

    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button  class="btn btn-warning pull-right" (click)="this.valida()" [disabled]="this.isValida">
                {{ '_CLAIMS_._VALIDATE' | translate}}
            </button>
        </div>
        <div class="btn-group">
            <button class="btn btn-warning pull-right" (click)="this.consolida()" [disabled]="this.isConsolida">
                {{ '_CLAIMS_._CONSOLIDATE' | translate }}
            </button>
        </div>
        <div class="btn-group" *ngIf="false">
            <button class="btn btn-warning pull-right" (click)="this.bilancio()" [disabled]="this.isBilancio">
                {{ '_CLAIMS_._BALANCE' | translate }}
            </button>
        </div>
    </div>
</div>
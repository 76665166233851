import { InstalmentType } from './policy.model';

export class MeansOfPayment {
  credMeansOfPayment: CredMeansOfPayment[];
  debMeansOfPayment: DebMeansOfPayment[];
  objectId?: any;
}

export class DebMeansOfPayment {
  creditCards: any[];
  meanOfPayment: MeanOfPayment;
  paymentFields: PaymentFields[];
  paymentType: PaymentType;
}

export class MeanOfPayment {
  code: string;
  description: string;
  identification: string;
}

export class PaymentFields {
  label: string;
  mandatory: string;
  name: string;
  objectId?: any;
  readOnly: boolean;
  type: string;
  value?: any;
  values?: string[];
}

export class PaymentType {
  codice: string;
  descrizione: string;

  constructor(codice: string, descrizione: string) {
    this.codice = codice;
    this.descrizione = descrizione;
  }

  setCodice(codice: string) {
    this.codice = codice;
  }
  getCodice() {
    return this.codice;
  }
  setDescrizione(descrizione: string) {
    this.descrizione = descrizione;
  }
}

export class PaymentFrequency {
  code: string;
  description: string;
  paymentFrequencyType: string;
  premiumAdjustment: boolean;
  quote: boolean;
}

export class Payments {
  instalmentType: InstalmentType;
  paymentType: PaymentType;

  // additional payments details
  objectId?: string;
  bankCABCode?: string;
  bankAccountOwner?: string;
  checkNumber?: string;
  fiscalCodeOfTheAccountOwner?: string;
  bankABICode?: string;
  bankTransferActivationReasonCode?: string;
  countryCodeIBAN?: string;
  accountNumber?: string;
  creditCardOwner?: string;
  creditCardNumber?: string;
  cardExpirationDate?: string;
  iban?: string;
  ciniban?: string;
  bankCINCode?: string;
  key?: string;
  properties?: any[];

  constructor(instalmentType: InstalmentType, paymentType: PaymentType) {
    this.instalmentType = instalmentType;
    this.paymentType = paymentType;
  }

  setInstalmentType(instalmentType: InstalmentType) {
    this.instalmentType = instalmentType;
  }
  setPaymentType(paymentType: PaymentType) {
    this.paymentType = paymentType;
  }
}

export class CredMeansOfPayment {
  creditCards: any[];
  meanOfPayment: MeanOfPayment;
  paymentFields: PaymentFields[];
  paymentType: PaymentType;
}

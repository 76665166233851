import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiCompany} from './models/api-models/api-company';
import {PassProductsService} from './pass-products.service';
import {tap} from 'rxjs/operators';
import {ApiAgency} from './models/api-models/api-agency';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  availableCompanies: Array<ApiCompany>;
  availableAgencies: Array<ApiAgency>;

  constructor(protected passproductService: PassProductsService) { }

  getAvailableCompanies(): Observable<Array<ApiCompany>> {
    if (this.availableCompanies && this.availableCompanies.length > 0) {
      return of(this.availableCompanies);
    } else {
      return this.passproductService.getAvailableCompanies().pipe(tap( companies => {
        this.availableCompanies = companies;
      }));
    }
  }

  getAvailableAgencies(): Observable<Array<ApiAgency>> {
    if (this.availableAgencies && this.availableAgencies.length > 0) {
      return of(this.availableAgencies);
    } else  {
      return this.passproductService.getAvailableAgencies().pipe(tap(agencies => {
        this.availableAgencies = agencies;
      }));
    }
  }
}

<div class="container-fluid policy-table" data-qa="autosoft-list">
  <form [formGroup]="searchForm">
    <table class="container-fluid policy-table">
      <tr>
        <td style="width: 10px">
          <span class="rgifont iconDTleft rgi-plus" [ngClass]="{'rgi-plus': !criteriaOn, 'rgi-minus':criteriaOn}"
            (click)=ctrCriteria()> </span>
        </td>
        <td style="text-align: left;width: 2000px;">{{ '_CLAIMS_._CRITERIA' | translate }} </td>
      </tr>
      <tr *ngIf="criteriaOn">
        <td colspan="2">
          <div class="row">
            <div class="form-group">
              <div class="col-xs-3">
                <label ng-bind="label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }} </label>

                <input type="text" class="form-control" name="claimNumber" formControlName="claimNumber"
                  attr.data-qa="autosoftsearch-claimNumber-input">
              </div>
              <div class="col-xs-2" style="text-align: left;">
                <br><button class="btn btn-warning pull-right" (click)="searchNoTransmission()">{{ '_CLAIMS_._BUTTONS_._FAILED_TRANSMISSION'
                  | translate }}</button>
              </div>
              <div class="col-xs-7">
                &nbsp;
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="form-group">
              <div class="col-xs-3">
                <label ng-bind="label">{{ '_CLAIMS_._ASSIGNMENT' | translate }} </label>

                <input type="text" class="form-control" name="assignmentNumber" formControlName="assignmentNumber"
                  attr.data-qa="autosoftsearch-assignmentNumber-input">
              </div>
              <div class="col-xs-3">
                <pnc-datepicker label="{{ '_CLAIMS_._FROM' | translate }}" customFormControlName="dateFrom"
                  [customFormGroup]="searchForm" [maxDate]="today" attr.data-qa="autosoftsearch-dateFrom-input">
                </pnc-datepicker>
              </div>
              <div class="col-xs-3">
                <pnc-datepicker label="{{ '_CLAIMS_._TO' | translate }}" customFormControlName="dateTo"
                  [customFormGroup]="searchForm" [maxDate]="today" attr.data-qa="autosoftsearch-dateTo-input">
                </pnc-datepicker>
              </div>
              <!-- <div class="col-xs-3">
                <label ng-bind="label">{{ '_CLAIMS_._IN_ERROR' | translate }} </label>
                <br>
                <input type="radio" class="btn btn-radio" name="inError" value="true" formControlName="inError"
                  attr.data-qa="autosoftsearch-inError-input"> &nbsp;&nbsp; <label ng-bind="label">{{ 'Yes' | translate
                  }}</label> &nbsp;&nbsp;
                <input type="radio" class="btn btn-radio" name="inError" value="false" formControlName="inError"
                  attr.data-qa="autosoftsearch-inError-input"> &nbsp;&nbsp; <label ng-bind="label">{{ 'No' | translate
                  }}</label> &nbsp;&nbsp;
                <input type="radio" class="btn btn-radio" name="inError" value="null" formControlName="inError"
                  attr.data-qa="autosoftsearch-inError-input"> &nbsp;&nbsp; <label ng-bind="label">{{ 'All' | translate
                  }}</label>

              </div> -->

            </div>
          </div>
          
          <div class="row">
            <div class="form-group">
              <div class="col-xs-6">
                <label ng-bind="label">{{ '_CLAIMS_._EVENTS' | translate }} </label>
                <select class="core-select form-control" id="event" name="event" formControlName="event"
                  attr.data-qa="autosoftsearch-event-input">
                  <option value="">&nbsp;</option>
                  <option value="R-PER">{{ '_CLAIMS_._R_PER' | translate }}</option>
                  <option value="A-COM">{{ '_CLAIMS_._A_COM' | translate }}</option>
                  <option value="S-RIF">{{ '_CLAIMS_._S_RIF' | translate }}</option>
                  <option value="R-FAT">{{ '_CLAIMS_._R_FAT' | translate }}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
              </div>
            </div>
          </div>

    
          <div class="row"> 
            <br>
            <div [ngClass]="classMsg" *ngIf="errore" role="alert">{{ errorMessage }} </div>
            <div *ngIf="!errore">&nbsp;<br> </div>
          </div>

          <div class="btn-group btn-group-justified">
            <div class="btn-group">
              <button class="btn btn-warning pull-right" (click)="clearFilter()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
            </div>
            <div class="btn-group">
              <button class="btn btn-warning pull-right" (click)="searchTransmission()">{{ '_CLAIMS_._BUTTONS_._TRANSMISSION' | translate
                }}</button>
            </div>
            <div class="btn-group">
              <button class="btn btn-warning pull-right" (click)="searchEvents()">{{ '_CLAIMS_._BUTTONS_._EVENTS' | translate }}</button>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <br>
    <div class="col-xs-12" *ngIf="searchForm.controls.typeSearch.value === '1'"
      style="background-color: #689ecb; color: white;">
      <label ng-bind="label">{{ '_CLAIMS_._TRANSMISSION_LIST' | translate }} </label>
      <br>
    </div>
    <div class="col-xs-12" *ngIf="searchForm.controls.typeSearch.value === '2'"
      style="background-color: #689ecb; color: white;">
      <label ng-bind="label">{{ '_CLAIMS_._FAILED_TRANSMISSION_LIST' | translate }} </label>
      <br>
    </div>
    <div class="col-xs-12" *ngIf="searchForm.controls.typeSearch.value === '3'"
      style="background-color: #689ecb; color: white;">
      <label ng-bind="label">{{ '_CLAIMS_._EVENTS' | translate }} </label>
      <br>
    </div>

    <table class="table-responsive table coreDT" style="display: block;overflow: auto;height: 500px;"
      *ngIf="listAutosoftInfo && searchForm.controls.typeSearch.value !== '3'">
      <thead>
        <tr class="head-result thDataTable">
          <td style="width: 7%;">
            <span attr.data-qa="autosoft-claim-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</span>
          </td>

          <td>
            <span attr.data-qa="autosoft-AssNumber-label">{{ '_CLAIMS_._ASSIGNMENT' | translate }}</span>
          </td>

          <td style="width: 4%;">
            <span attr.data-qa="autosoft-AssNumber-label">{{ '_CLAIMS_._STARTER_EVENT' | translate }}</span>
          </td>

          <td>
            <span attr.data-qa="autosoft-reqDate-label">{{ '_CLAIMS_._REQUEST' | translate }}</span>
          </td>

          <td>
            <span attr.data-qa="autosoft-idReq-label">{{ '_CLAIMS_._ID' | translate }}</span>
          </td>
          <td>
            <span attr.data-qa="autosoft-respErr-label">{{ '_CLAIMS_._WS' | translate }}</span>
          </td>
          <td>
            <span attr.data-qa="autosoft-xmlReq-label">{{ '_CLAIMS_._XML' | translate }}</span>
          </td>

          <td>
            <span attr.data-qa="autosoft-respDate-label">{{ '_CLAIMS_._RESPONSE' | translate }}</span>
          </td>

          <td>
            <span attr.data-qa="autosoft-respId-label">{{ '_CLAIMS_._ID' | translate }}</span>
          </td>

          <td>
            <span attr.data-qa="autosoft-respXml-label">{{ '_CLAIMS_._XML' | translate }}</span>
          </td>

          <td>
            <span attr.data-qa="autosoft-respErr-label">{{ '_CLAIMS_._MESSAGE_._ERROR_CODE' | translate }}</span>
          </td>
          <td *ngIf="searchForm.controls.typeSearch.value === '2'">

          </td>
        </tr>

      </thead>

      <tbody style="height: 500px; overflow: auto;">
        <tr *ngFor="let aRow of rows; 
                    let even = even; 
                    let odd = odd; 
                    let autosoftIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
          class="table-row" attr.data-qa="autosoft-result-{{autosoftIndex}}">

          <td [ngClass]="aRow.class">
            {{aRow.info.claimNumber}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.numberIncarico}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.eventPossible}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.datOraInsRequest}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.idRequest}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.serviceType}}
          </td>
          <td [ngClass]="aRow.class">
            <span style="cursor: pointer;" title="{{ '_CLAIMS_._VIEW_DETAIL_BLOW' | translate }}"
              (click)="detailRequestXml(aRow)">{{limitXml(aRow.info.payLoadRequest)}}</span>

          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.datOraInsResponse}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.idResponse}}
          </td>

          <td [ngClass]="aRow.class">
            <span style="cursor: pointer;" title="{{ '_CLAIMS_._VIEW_DETAIL_BLOW' | translate }}"
              (click)="detailResponseXml(aRow)">{{limitXml(aRow.info.payLoadResponse)}}</span>
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.respError}}
          </td>

          <td [ngClass]="aRow.class" *ngIf="searchForm.controls.typeSearch.value === '2'" style=" width: 50px;">
            <div class="btn-group">
              <button class="btn btn-warning pull-right" (click)="send(aRow.info)">{{ '_CLAIMS_._BUTTONS_._SEND' | translate }}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table-responsive table coreDT" style="display: block;"
      *ngIf="listAutosoftInfo && searchForm.controls.typeSearch.value === '3'">
      <thead>
        <tr class="head-result thDataTable">
          <td style="width: 7%;">
            <span attr.data-qa="autosoft-claim-label">{{ '_CLAIMS_._MESSAGE' | translate }}</span>
          </td>

          <td style="width: 10%;">
            <span attr.data-qa="autosoft-AssNumber-label">{{ '_CLAIMS_._INSERT_DATE' | translate }}</span>
          </td>

          <td style="width: 10%;">
            <span attr.data-qa="autosoft-AssNumber-label">{{ '_CLAIMS_._ELABORATION_DATE' | translate }}</span>
          </td>

          <td style="width: 10%;">
            <span attr.data-qa="autosoft-idReq-label" *ngIf="isInvoiceEvent()">{{ '_CLAIMS_._REQUEST' | translate }}</span>
          </td>

          <td style="width: 30%;">
            <span attr.data-qa="autosoft-xmlReq-label" *ngIf="isInvoiceEvent()">{{ '_CLAIMS_._XML' | translate }}</span>
          </td>

          <td style="width: 10%;">
            <span attr.data-qa="autosoft-respDate-label" *ngIf="isInvoiceEvent()">{{ '_CLAIMS_._RESPONSE' | translate }}</span>
          </td>
          <td style="width: 40%;">
            <span attr.data-qa="autosoft-respXml-label" *ngIf="isInvoiceEvent()">{{ '_CLAIMS_._XML' | translate }}</span>
          </td>

          <td style="width: 80%;">
            &nbsp;
          </td>
        </tr>

      </thead>

      <tbody style="height: 500px; overflow: auto;">
        <tr *ngFor="let aRow of rows; 
                    let even = even; 
                    let odd = odd; 
                    let autosoftIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
          class="table-row" attr.data-qa="autosoft-result-{{autosoftIndex}}">

          <td [ngClass]="aRow.class">
            {{aRow.info.eventPossible}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.datOraInsRequest}}
          </td>

          <td [ngClass]="aRow.class">
            {{aRow.info.datOraInsResponse}}
          </td>

          <td [ngClass]="aRow.class">
            <p *ngIf="isInvoiceEvent()"> {{aRow.info.idRequest}}</p>
          </td>


          <td [ngClass]="aRow.class">
            <span style="cursor: pointer;" *ngIf="isInvoiceEvent()" title="{{ '_CLAIMS_._VIEW_DETAIL_BLOW' | translate }}"
              (click)="detailRequestXml(aRow)">{{limitXml(aRow.info.payLoadRequest)}}</span>

          </td>


          <td [ngClass]="aRow.class">
            <p *ngIf="isInvoiceEvent()"> {{aRow.info.idResponse}} </p>
          </td>

          <td [ngClass]="aRow.class">
            <span style="cursor: pointer;" *ngIf="isInvoiceEvent()" title="{{ '_CLAIMS_._VIEW_DETAIL_BLOW' | translate }}"
              (click)="detailResponseXml(aRow)">{{limitXml(aRow.info.payLoadResponse)}}</span>
          </td>

          <td [ngClass]="aRow.class"  style=" width: 50px;">
            <div class="btn-group" *ngIf="!aRow.info.datOraInsResponse">
              <button class="btn btn-warning pull-right" (click)="retreiveEvent(aRow.info)">{{ '_CLAIMS_._BUTTONS_._SEND' | translate }}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <br>
    <div class="col-xs-12" *ngIf="visDetail" style="background-color: #689ecb; color: white;">
      <label ng-bind="label">{{ '_CLAIMS_._DETAIL' | translate }} {{detailTitle}} </label>
      <br>
    </div>

    <table style="width: 100%;" *ngIf="visDetail" class="table-responsive table coreDT">
      <tr>
        <td>
          <p *ngFor="let str of detail">{{str}}</p>

        </td>
      </tr>
    </table>
  </form>
</div>
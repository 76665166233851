import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from "@angular/platform-browser";
import {DIALOG_DATA, ModalService, OnModalClose} from "@rgi/rx/ui";


@Component({
  selector: 'lib-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.sass']
})
export class PdfModalComponent implements OnInit, OnModalClose {

  @Input() file: Blob;
  @Input() title?: string =  "Download print";

  @Output() modalClose = new EventEmitter();

  pdfUrl: SafeResourceUrl;


  constructor(private sanitizer: DomSanitizer, @Optional() @Inject(DIALOG_DATA) data: any, private modalService: ModalService) {
    if (data) {
      if (data.file) {
        this.file = data.file;
      }
      if (data.title) {
        this.title = data.title;
      }
    }
  }


  ngOnInit() {
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.file));
  }


  close(){
    this.modalClose.emit();
  }

}

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from "@rgi/rx/ui";
import {RGI_DOCUMENTS_ACTIONS} from "../../resources/constants/actions";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {DocumentFile} from "../../resources/model/document-file";
import {Subscription} from "rxjs";
import {DocumentsService} from "../../services/documents.service";
import {Document} from "../../resources/model/document";
import {Metadata} from "../../resources/model/metadata";
import {ConfMetadata} from "../../resources/model/conf-metadata";


export class RgiUploadFileModalData {
  title: string;
  contractId: string;
  document: Document;
  file: DocumentFile;
  bUploadFile: boolean;
  arInpDocValidation: Array<any>;
  maxKBFileSize = 5020;
  fileTypes: Array<string>;

  constructor(title: string, contractId: string, document: Document, file: DocumentFile, bUploadFile: boolean, arInpDocValidation: Array<any>, maxKBFileSize: number, fileTypes: Array<string>) {
    this.title = title;
    this.contractId = contractId;
    this.document = document;
    this.file = file;
    this.bUploadFile = bUploadFile;
    this.arInpDocValidation = arInpDocValidation;
    this.maxKBFileSize = maxKBFileSize;
    this.fileTypes = fileTypes;
  }
}
@Component({
  selector: 'rgi-document-upload-file-modal',
  templateUrl: './document-upload-file-modal.component.html'
})
export class DocumentUploadFileModalComponent implements OnModalClose {

  modalClose: EventEmitter<RGI_DOCUMENTS_ACTIONS | string> = new EventEmitter();
  data: RgiUploadFileModalData;

  stateSubscription: Subscription;
  fileData: File;
  fileFormat = ['.pdf'];
  arrayBuffer: any;
  validationMessages: string[] = [];
  submitted = false;
  upFileForm: UntypedFormGroup = new UntypedFormGroup({});


  constructor(
    private documentsService: DocumentsService,
    @Optional() @Inject(DIALOG_DATA) data: RgiUploadFileModalData
  ) {
    if (data) {
      this.data = data;
    }
  }

  ngOnInit() {
    if (this.data.fileTypes) {
      this.fileFormat = this.data.fileTypes;
    }
    this.upFileForm = this.toFormGroup(this.data.document.confMetadataList, this.data.file.metadataList);
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  close() {
    this.modalClose.emit(RGI_DOCUMENTS_ACTIONS.CANCEL);
  }

  searchAttachment() {
  }

  fileProgress(event: any) {
    let fileIn = event[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.arrayBuffer = reader.result;
    };

    reader.readAsArrayBuffer(fileIn);

    this.validationMessages.length = 0;
    if (this.checkFileFormat(fileIn)) {
      this.fileData = fileIn;
      /*this.upFileForm.patchValue({
        attachmentFile: fileIn
      });
      this.upFileForm.get('attachmentFile').updateValueAndValidity();*/
    } else {
      fileIn = null;
    }
  }

  checkFileFormat(fileIn: File) {
    const attachmentFile = this.upFileForm.get('attachmentFile');
    attachmentFile?.setErrors(null);
    //this.upFileForm.controls.attachmentFile.setErrors(null);
    if ((fileIn.size / 1024) > this.data.maxKBFileSize) {
      this.validationMessages.push('File exceed the maximum file size');
      attachmentFile?.setErrors({required: true});
      return false;
    } else {
      for (const format of this.fileFormat) {
        if (fileIn.name.toLowerCase().endsWith(format.toLowerCase().trim())) {
          return true;
        }
      }
      attachmentFile?.setErrors({required: true});
      this.validationMessages.push('File type not admitted');
      return false;
    }
  }

  confirmUpFile() {
    this.submitted = true;
    this.validateForm();
    this.setFieldsDirty();

    if (this.upFileForm.valid) {
      this.data.file.metadataList.forEach((metadata: Metadata) => {
        if (metadata.visible) {
          const metInput = this.upFileForm.controls[metadata.code];
          metadata.value = metInput.value;
        }
      });

      if (this.data.bUploadFile) {
        this.confirmUploadFile();
      } else {
        this.confirmUpdateFile();
      }
    }
  }


  private setFieldsDirty() {
    Object.keys(this.upFileForm.controls).forEach((field: any) => {
      // @ts-ignore
      this.upFileForm.get(field).markAsDirty();
    });
  }

  validateForm() {
    const controls = this.upFileForm.controls;
    this.validationMessages.length = 0;

    if (!this.upFileForm.valid) {
      this.validationMessages.push('Set mandatory fields');
    } else {
      if (this.data.bUploadFile) {
        if (this.checkFileFormat(this.fileData)) {
          this.upFileForm.get('attachmentFile')?.setErrors(null);
        }
      }
    }
  }

  confirmUploadFile() {
    this.data.file.name = this.fileData.name;
    const fileByteArray: { push?: any; } = [];
    const array = new Uint8Array(this.arrayBuffer);
    array.forEach((elem: any) => {
      fileByteArray.push(elem);
    });

    this.data.file.body = fileByteArray;

    this.documentsService.uploadFile(this.data.contractId, this.data.document.code, this.data.file).subscribe((file: DocumentFile) => {
        this.data.document.files.push(file);
        if (this.data.document.toBeValidated) {
          this.data.arInpDocValidation[this.data.document.id] = false;
        }
        //FIXME gestire l'evento di eventModifiedDocument
        //this.eventModifiedDocument.emit(true);
        this.close();
      });
  }

  confirmUpdateFile() {
    this.documentsService.updateFileMetadata(this.data.contractId, this.data.document.code, this.data.file.id, this.data.file.metadataList)
      .subscribe((metadataList: Array<Metadata>) => {
          if (metadataList) {
            this.data.file.metadataList.forEach((metadata: Metadata) => {
              const metadataIn = metadataList.find((md: Metadata) => md.code === metadata.code);
              if(metadataIn) {
                metadata.value = metadataIn.value;
                metadata.valueDescription = metadataIn.valueDescription;
                metadata.id = metadataIn.id;
              }
            });
            //FIXME gestire l'evento di eventModifiedDocument
            //this.eventModifiedDocument.emit(true);
            this.close();
          }
        });
  }

  toFormGroup(confMetadataList: ConfMetadata[], metadataList: Metadata[]) {
    const group: any = {};

    confMetadataList.forEach((confMetadata: ConfMetadata) => {
      if (confMetadata.visible) {
        let found = false;
        let value = null;
        for (const metadata of metadataList) {
          if (confMetadata.code === metadata.code) {
            found = true;
            value = metadata.value;
            break;
          }
        }

        if (!found) {
          const metadata = new Metadata();
          metadata.code = confMetadata.code;
          metadata.description = confMetadata.description;
          metadata.value = confMetadata.value;
          metadata.valueDescription = confMetadata.valueDescription;
          metadata.listValue = confMetadata.listValue;
          metadata.visible = confMetadata.visible;
          metadata.usedForValidation = confMetadata.usedForValidation;
          metadata.mandatory = confMetadata.mandatory;
          metadata.complianceStatus = confMetadata.complianceStatus;
          metadata.type = confMetadata.propertyType;
          metadata.usedAsIndex = confMetadata.usedAsIndex;
          metadata.modifiable = confMetadata.modifiable;
          metadata.systemMetadato = confMetadata.systemMetadato;
          metadataList.push(metadata);
        }

        const control = new UntypedFormControl(value, confMetadata.mandatory ? Validators.required : null);
        control.setValidators(this.confMetadataValidator(confMetadata));
        this.patchValueToForm(control, value, confMetadata);
        if (!confMetadata.modifiable) {
          control.disable();
        } else {
          control.enable();
        }
        group[confMetadata.code] = control;
      }
    });

    if (this.data.bUploadFile) {
      const formControl = new UntypedFormControl(null, Validators.required);
      group.attachmentFile = formControl;
    }

    return new UntypedFormGroup(group);
  }

  patchValueToForm(control: UntypedFormControl, value: any, confmetadata: ConfMetadata) {
    if (value) {
      if (confmetadata.propertyType.code === 'S') {
        control.patchValue(value.toUpperCase());
      } else {
        control.patchValue(value);
      }
    }
  }

  confMetadataValidator(metadata: ConfMetadata): ValidatorFn {
    // @ts-ignore
    return (control: AbstractControl): ValidationErrors | null => {
      if (metadata.mandatory) {
        if (!control.value || control.value === '-1') {
          return { required: true };
        }
        return null;
      }
    };
  }

}

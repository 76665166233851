<div *ngIf="stateManager.getState$() | async as state">
    <div [formGroup]="dashboardFormGroup" class="dashboard-container container-fluid">
        <div class="form-group row">

            <rgi-rx-tab-group class="custom-tab-search" (onTabSelectionChange)="onSectionSelect($event)" [selectedIndex]="selectedRadioId">
                <rgi-rx-tab label="{{ 'ICD.Stored' | translate }}">
                    <ng-template rgiRxTabContent></ng-template>
                </rgi-rx-tab>
                <rgi-rx-tab label="{{ 'ICD.Not_stored' | translate }}">
                    <ng-template rgiRxTabContent></ng-template>
                </rgi-rx-tab>
            </rgi-rx-tab-group>

            <rgi-rx-form-field>
                <label rgiRxLabel>{{ 'ICD.Function' | translate }}</label>
                <select rgiRxNativeSelect  #functionality (change)="onFunctionChange()" formControlName="functionality"
                    name="functionality">
                    <option></option>
                    <option *ngFor="let funType of funTypes" [ngValue]="funType">
                        {{funType.description}}
                    </option>
                </select>
            </rgi-rx-form-field>
                
            <rgi-rx-form-field>
                <label rgiRxLabel>{{ 'ICD.COMPANY' | translate }}</label>
                <select rgiRxNativeSelect #company formControlName="company" name="company">
                    <option></option>
                    <option *ngFor="let company of state.companyList" [ngValue]="company">
                        {{company?.description}}
                    </option>
                </select>
            </rgi-rx-form-field>
            <div class="row">
                <div class="col-sm-6 col-xs-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel>{{'ICD.Competence_date' | translate}}</label>
                        <rgi-rx-date-picker>
                            <input [rgiRxDateTimeInput]="pickerFrom" formControlName="competenceDateFrom"  [max]="getMaxDate()"  rangeSeparator="-"
                                placeholder="{{ 'ICD.From' | translate }}">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time #pickerFrom pickerType="calendar"></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
                <div class="col-sm-6 col-xs-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel>{{'ICD.Competence_date' | translate}}</label>
                        <rgi-rx-date-picker>
                            <input [rgiRxDateTimeInput]="pickerTo" formControlName="competenceDateTo" [min]="getMinDate()"  [max]="maxMonth()" rangeSeparator="-"
                                placeholder="{{ 'ICD.To' | translate }}">
                        </rgi-rx-date-picker>
                        <rgi-rx-date-time #pickerTo pickerType="calendar"></rgi-rx-date-time>
                    </rgi-rx-form-field>
                </div>
            </div>

            <div class="row">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>ICD.Product</label>
                <select rgiRxNativeSelect #product (change)="onProductChange()" formControlName="product" name="product">
                    <option></option>
                    <option *ngFor="let product of state.productList" [ngValue]="product">
                        {{product?.description}}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>

            <rgi-rx-form-field>
                <label rgiRxLabel translate>ICD.Product_code</label>
                <select rgiRxNativeSelect #productCode (change)="onProductCodeChange()" formControlName="productCode" name="productCode">
                    <option></option>
                    <option *ngFor="let product of state.productList" [ngValue]="product">
                        {{product?.code}}
                    </option>
                </select>
            </rgi-rx-form-field>

            <rgi-rx-form-field>
                <label rgiRxLabel translate>ICD.Status</label>
                <select rgiRxNativeSelect #status formControlName="status" name="status">
                    <option></option>
                    <option *ngFor="let status of state.statusList" [ngValue]="status">
                        {{status?.description}}
                    </option>
                </select>
            </rgi-rx-form-field>

            <rgi-rx-form-field>
                <label rgiRxLabel translate>ICD.ACTION</label>
                <select rgiRxNativeSelect #action formControlName="action" name="action">
                    <option></option>
                    <option *ngFor="let action of state.actionList" [ngValue]="action">
                        {{action?.description}}
                    </option>
                </select>
            </rgi-rx-form-field>

            <rgi-rx-form-field>
                <input rgiRxInput formControlName="mismatchAmountFrom" type="number">
                <label rgiRxLabel>{{ 'ICD.Mismatch_amount_from' | translate }}</label>
            </rgi-rx-form-field>
                

            <rgi-rx-form-field>
                <input rgiRxInput formControlName="mismatchAmountTo" type="number">
                <label rgiRxLabel>{{ 'ICD.Mismatch_amount_to' | translate }}</label>
            </rgi-rx-form-field>

            <div class="col-sm-12 col-xs-12">
                <rgi-rx-snackbar tag="{{TAG_DATE_RANGE_ERROR}}"></rgi-rx-snackbar>
            </div>
        </div>
        
        
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button rgi-rx-button color="secondary" class="btn pull-right" (click)="clear()" translate>ICD.Clear</button>
            </div>
            <div class="btn-group">
                <button rgi-rx-button color="primary" class="btn pull-right" (click)="goToNextPage()" translate>ICD.Next</button>
            </div>
        </div>
    </div>
</div>

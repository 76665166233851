import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContractCacheService {

  protected contractsMap: Map<string, any> = new Map();

  setContractInCache(resourceId: string, contract: any) {
    this.contractsMap.set(resourceId, contract);
  }

  getContractFromCache(resourceId: string) {
    return this.contractsMap.get(resourceId);
  }

  removeContractFromCache(resourceId: string) {
    this.contractsMap.delete(resourceId);
  }

}

import {CdkStep, CdkStepper, StepperOptions} from '@angular/cdk/stepper';
import {ContentChildren, Directive, InjectionToken, QueryList, ViewChildren} from '@angular/core';
import {RgiRxStepHeaderComponent} from './rgi-rx-step-header/rgi-rx-step-header.component';
import {RgiRxStepComponent} from './rgi-rx-step/rgi-rx-step.component';

@Directive({
  selector: '[rgi-rx-stepper]',
  providers: [{provide: CdkStepper, useExisting: RgiRxStepper}]
})
/**
 * Base stepper class
 */
export class RgiRxStepper extends CdkStepper {

  @ViewChildren(RgiRxStepHeaderComponent) override _stepHeader: QueryList<RgiRxStepHeaderComponent>;

  @ContentChildren(RgiRxStepComponent, {descendants: true})override _steps: QueryList<RgiRxStepComponent>;

  /**
   * Find a CdkStep in the RgiRxStepComponent list
   * @param selectedStep the step to be searched
   */
  findStep(selectedStep: CdkStep): RgiRxStepComponent | undefined {
    return this._steps.find(
      item => {
        return item === selectedStep;
      }
    );

  }
}

/**
 * RgiRxStepper configuration options
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RgiRxStepperOptions extends StepperOptions {
}


export const RGI_RX_STEPPER_DEFAULT_OPTIONS: RgiRxStepperOptions = {
  displayDefaultIndicatorType: false,
  showError: true
};

export const RGI_RX_STEPPER_OPTIONS = new InjectionToken<RgiRxStepperOptions>('RGI_RX_STEPPER_OPTIONS');

import {Binder} from '../../domain-models/parameters/binder';
import {AdditionalLabel} from './additional-label';

export class ProposalDataPutRequest {
  additionalLabels?: AdditionalLabel[];
  effect?: string;
  expire?: string;
  issue?: string;
  extentions?: Extention[];
  binder: Binder;

  constructor(effect: string, expire: string, issue: string, binder: Binder) {
    this.effect = effect;
    this.expire = expire;
    this.issue = issue;
    this.binder = binder;
  }
}

export interface Extention {
  additionalLabels?: AdditionalLabel[];
  key: string;
  value: string;
}

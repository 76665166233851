<label class="rgi-ui-form-control-label rgi-rx-drop-body-label">

  {{dropContainer.label}}

</label>
<div class="rgi-ui-drop-content rgi-rx-drop-body-content"
     [class.rgi-ui-disabled]="disabled"
     [class.rgi-ui-drag-enter]="dropContainer.isEntered"
     [attr.tabindex]="disabled ? -1 : 0"
     [rgi-rx-qa]="dropContainer.id + '-drop-content'" [qa-value]="dropContainer._getTemplateCtx().viewModel"
     qa-type="drop-body"
>

  <ng-container *ngIf="!!dropContainer.dropLabelDirective">
    <ng-container
      *ngTemplateOutlet="dropContainer.dropLabelDirective.template; context: dropContainer._getTemplateCtx()"></ng-container>
  </ng-container>

  <ng-container *ngIf="!dropContainer.dropLabelDirective">
    <ng-container
      *ngTemplateOutlet="dropContainer.select ? withSelect : onlyDrag; context: dropContainer._getTemplateCtx()"></ng-container>
  </ng-container>

</div>

<ng-template #withSelect let-ctx="label">{{'RGI_RX.DRAG_DROP.DROP' | translate}}
  / {{'RGI_RX.DRAG_DROP.SELECT' | translate }} {{ctx}}</ng-template>

<ng-template #onlyDrag let-ctx="label">{{'RGI_RX.DRAG_DROP.DROP' | translate}} {{ctx}}</ng-template>

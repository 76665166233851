import {Injectable} from '@angular/core';
import {AbstractStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable} from 'rxjs';
import {ReIssueEmissionBusinessService} from '../re-issue-business-services/re-issue-emission-business.service';
import {Documents} from "@rgi/pnc-postsales/lib/resources/model/api/document";
import {PcPolicy} from "../../models/pc-portfolio-models/policy-models/pc-policy";

export interface ReIssueEmissionNavigationData {
  resourceId: string;
  policy: PcPolicy;
} // this is the interface of the navigiation params

export class ReIssueEmissionState extends State { // this is the model of the state
  public resourceId: string;
  public policy: PcPolicy;
  public documents: Documents;
  public checkPinResponse = false;
  public pinResponse: any;
  public confirmOrderResponse: any;
}

@Injectable({
  providedIn: 'root'
})
export class ReIssueEmissionStateManagerService<T extends ReIssueEmissionState> extends AbstractStateManager<T> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    private emissionBusinessService: ReIssueEmissionBusinessService<T>
  ) {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newReIssueEmissionState();

    this.updateState$(this.initEmission(st)); // this update the state
  }

  newReIssueEmissionState() {
    return new ReIssueEmissionState(this.activeRoute.key) as T;
  }

  initEmission(state: T): Observable<T> {
    const req = this.activeRoute.getRouteData<ReIssueEmissionNavigationData>();
    state.resourceId = req.resourceId;
    state.policy = req.policy;
    return this.emissionBusinessService.getDocuments(state);
  }

  // checkPin(pinValue: string): Observable<T> {
  //   const state = this.getCurrentState();
  //   return this.emissionBusinessService.checkPin(state, pinValue);
  // }

  // confirmOrder() {
  //   const state = this.getCurrentState();
  //   return this.emissionBusinessService.confirmOrder$(state);
  // }

  public downloadDocuments$(documentCode: string): Observable<Blob> {
    let currentState = this.getCurrentState();
    return this.emissionBusinessService.downloadDocuments$(currentState.policy.contractNumber.policy, documentCode);
  }
}

export class FilterPolicy {

    public company: string = '';
    public policyNumber: string = '';
    public licensePlate: string = '';
    public insuredAsset: string = '';
    public typeAsset: string = '';
    public firstName: string = '';
    public surBusinessName: string = '';
    public fiscalCodeVat: string = '';
    public dateScadDa: Date = null;
    public dateScadA: Date = null;
    public lossDate: Date = null;
    public lossDateFrom: Date = null;
    public lossDateTo: Date = null;
    public dateEffDa: Date = null;
    public dateEffA: Date = null;
    public confirm: boolean = false;

    constructor() { }
}


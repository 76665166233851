<form (ngSubmit)="onSendMessage(form)" #form="ngForm">
    <div class="col-sm-12 message-fields">
        <input class="col-sm-11" placeholder="{{ '_CLAIMS_._OBJECT' | translate }}" id="subject" type="text" name="subject"
            maxlength="128" ngModel required>
        <button type="submit" class="btn btn-send col-sm-1" [disabled]='!form.valid'>
            <span class="glyphicon glyphicon-send"></span></button>
        <input *ngIf="!claim && !potentialClaim" class="col-sm-11" type="text" name="claimNumber"
            placeholder="{{ '_CLAIMS_._CLAIM' | translate }}" [(ngModel)]=typedClaimNumber
            [disabled]="typedPotClaimNumber !== undefined && typedPotClaimNumber !== null && typedPotClaimNumber.length > 0">
        <input *ngIf="!claim && !potentialClaim" class="col-sm-11" type="text" name="potentialClaimNumber"
            placeholder="{{ '_CLAIMS_._POTENTIAL_CLAIM_INQUIRY' | translate }}" [(ngModel)]=typedPotClaimNumber
            [disabled]="typedClaimNumber!== undefined && typedClaimNumber!== null && typedClaimNumber.length > 0">
        <textarea class=" col-sm-11" placeholder="{{ '_CLAIMS_._MESSAGE' | translate }}" name="body" id="body" cols="30" rows="3"
            name="body" maxlength="4000" ngModel required>
        </textarea>
    </div>
    <span class="error-block">{{errorMessage}}</span>
</form>
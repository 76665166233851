import { Injectable } from '@angular/core';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { Subject } from 'rxjs';
import { StepperConfiguration, STEPS } from '../life-issue-card/lic-consts';
import { ErrorType } from '../models/response.model';
import { LifeSessionService } from '../services/life-session-service';
import { PolicyService } from './../services/policy-service';

@Injectable({
    providedIn: 'root'
})
export class CardsNavigationService {

  private _steps: any[] = [];
  private messageForNextPage = new Map<string, {type: ErrorType, message: string}[]>();

  // mappa che contiene le pagine navigate (ovvero dove ho premuto il tasto avanti)
  navigationHistory: Map<string, boolean> = new Map();

  constructor(protected translate: TranslationWrapperService,
              protected policyService: PolicyService,
              protected lifeSessionService: LifeSessionService) {}

  private _cardsList = new Subject<any[]>();
  public currentCards = this.cardsList.asObservable();
  public STEP = this.getSteps();

  public get steps() {
      return this._steps;
  }

  public set steps(steps: any) {
    this._steps = steps;
  }

  public get cardsList() {
      return this._cardsList;
  }

  public set cardsList(cardsList: Subject<any[]>) {
    this._cardsList = cardsList;
  }

  public resetNavigationSteps() {
    this.steps = [];
    if (!(this.policyService.isFromPreventive && this.policyService.isAnonymous)) {
      this.steps.push({ name: this.STEP.SOGGETTI.route,
        label: this.translate.getImmediate('lic_Parties'), enabled: false, current: false, blocked: false });
    }
    this.steps.push(
      { name: this.STEP.DATI_AMMINISTRATIVI.route,
        label: this.translate.getImmediate('lic_policyData'), enabled: false, current: false, blocked: false }
    );
    if (this.lifeSessionService.productType === 'PIP') {
      this.steps.push({ name: this.STEP.PIP.route,
        label: this.translate.getImmediate('lic_pipData'), enabled: false, current: false, blocked: false });
    }
    this.steps.push(
      { name: this.STEP.DATI_DI_BENE.route,
        label: this.translate.getImmediate('lic_assets'), enabled: false, current: false, blocked: false },
      { name: this.STEP.QUOTAZIONE.route,
        label: this.translate.getImmediate('lic_quotation'), enabled: false, current: false, blocked: false }
    );
    if (!this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification) {
      this.steps.push(
        { name: this.STEP.SOMMARIO.route,
          label: this.translate.getImmediate('lic_summary'), enabled: false, current: false, blocked: false }
      );
    }
    this.steps.push({ name: this.STEP.ISSUE.route,
      label: this.translate.getImmediate('lic_issue'), enabled: false, current: false, blocked: false });

    // abilito e attivo il primo step del flusso perchè non è detto che ci sia la pagina dei ruoli
    this.steps[0].enabled = true;
    this.steps[0].current = true;
  }

  isNavigatedCard(step): boolean {
    return !!this.navigationHistory.get(step);
  }

  public checkInvestmentsStepVisibility(showStep: boolean) {
    if (showStep) {
      // aggiungo lo step investments se non presente
      const newSteps = [];
      const isAlreadyIn = this.steps.find(step => step.name === this.STEP.INVESTIMENTI.route);
      if (!isAlreadyIn) {
        this.steps.forEach(step => {
          newSteps.push(step);
          if (step.name === this.STEP.QUOTAZIONE.route) {
            newSteps.push({
              name: this.STEP.INVESTIMENTI.route,
              label: this.translate.getImmediate('lic_investments'),
              enabled: false,
              current: false,
              blocked: false
            });
          }
        });
        this.steps = newSteps;
      }
    } else {
      // rimuovo lo step investmenst
      this.steps = this.steps.filter(step => step.name !== this.STEP.INVESTIMENTI.route);
    }
    this.cardsList.next([...this.steps]);
  }

  goStraigthToIssue() {
    this.enableUpTo(this.STEP.ISSUE.route);
    this.cardsList.next([...this.steps]);
  }

  public enableUpTo(stepName: string) {
    let i = 0;
    const indexOfStep = this.steps.findIndex(step => step.name === stepName);
    while (i <= indexOfStep) {
      this.steps[i].enabled = true;
      this.steps[i].blocked = false;
      this.steps[i].current = i === indexOfStep;
      i++;
    }
  }

  public setCurrentCards(route: string) {
    let currentIndex = this.steps.findIndex(step => !!step.current); // trovo la pagina attuale
    const currentStepName = this.steps[currentIndex].name;

    const destinationIndex = (this.steps.findIndex(step => step.name === route));
    if (currentIndex > destinationIndex) {
      currentIndex = destinationIndex; // sto tornando indietro
    }

    let i = 0;
    const newStepper = [];
    this.steps.forEach(step => {
      if (i < currentIndex) { // current:F -> sono prima del range del nuovo step
        newStepper.push({ name: step.name, label: step.label, enabled: step.enabled, current: false, blocked: step.blocked });
      } else if (i > destinationIndex) {  // enabled:F|current:F -> sono dopo del range del nuovo step
        newStepper.push({ name: step.name, label: step.label, enabled: false, current: false, blocked: false });
      } else if (i > currentIndex && i < destinationIndex) { // enabled:F|current:F|blocked:T -> sono stato saltato
        newStepper.push({ name: step.name, label: step.label, enabled: false, current: false, blocked: true});
      } else if (i === destinationIndex) { // enabled:T|current:T|blocked:F -> sono lo step attivo
        newStepper.push({ name: step.name, label: step.label, enabled: true, current: true, blocked: false });
      } else if (i === currentIndex) { // enabled:T|current:F|blocked:F -> step da cui sono partito
        newStepper.push({ name: step.name, label: step.label, enabled: true, current: false, blocked: false });
      }
      i++;
    });

    this.steps = newStepper;
    this.cardsList.next([...newStepper]);

    // segno come navigata -> una pagina che va avanti con il pulsante conferma
    if (destinationIndex > 0 && destinationIndex > currentIndex) {
      this.navigationHistory.set(currentStepName, true);
    }
    window.scrollTo(0, 0); // ASMC-1850 -> la barra si scorrimento laterale ritorna in cima alla pagina
  }

  getPreviousAvaiableStep(): string {
    const currentIndex = this.steps.findIndex(step => !!step.current); // trovo la pagina attuale
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (!this.steps[i].blocked) {
        return this.steps[i].name;
      }
    }
    return null;
  }

  public setMessageForPage(step: string, messages: {type: ErrorType, message: string}[]) {
    this.messageForNextPage.set(step, this.getMessagesForStep(step).concat(messages));
  }

  public getMessagesForStep(step: string): {type: ErrorType, message: string}[] {
    return !!this.messageForNextPage.get(step) ? this.messageForNextPage.get(step) : [];
  }

  /**
   *
   * @returns array of error steps
   */
  protected getErrorSteps(): string[] {
    return [this.STEP.SOGGETTI.errorId, this.STEP.DATI_AMMINISTRATIVI.errorId, this.STEP.PIP.errorId, this.STEP.DATI_DI_BENE.errorId,
      this.STEP.QUOTAZIONE.errorId, this.STEP.INVESTIMENTI.errorId, this.STEP.SOMMARIO.errorId];
  }

  public getPreviousMessages(step: number): {type: ErrorType, message: string}[] {
    let allMessages: {type: ErrorType, message: string}[] = [];
    const pages = this.getErrorSteps().slice(0, step);

    pages.forEach((page: string) => {
      if (page) {
        const msg = this.getMessage(page);
        if (!!msg) {
          allMessages = allMessages.concat(msg);
        }
      }
    });

    return allMessages;
  }

  public getMessage(page: string): {type: ErrorType, message: string}[] {
    return this.messageForNextPage.get(page);
  }

  public resetMessages(step: string) {
    this.messageForNextPage.set(step, []);
  }

  protected getSteps(): StepperConfiguration {
    return STEPS;
  }

}

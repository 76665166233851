<form [formGroup]="coInsuranceForm">
  <div class="form-group rgi-ui-grid-2">
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="coInsuranceTypes" translate>RE_ISSUE.CO_INSURANCE</label>
        <select rgiRxNativeSelect id="coInsuranceTypes" formControlName="coInsuranceTypes" data-qa="coInsuranceTypes">
          <option *ngFor="let coins  of coInsuranceTypes" [ngValue]="coins.code">{{coins?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
   <div class="rgi-ui-col rgi-ui-grid-2">
     <div *ngIf="showPercentage" class="rgi-ui-col">
       <rgi-rx-form-field>
         <label rgiRxLabel for="ourPercentage" translate>RE_ISSUE.OUR_PERCENTAGE</label>
         <input rgiRxInput id="ourPercentage" (blur)="emitValues()" formControlName="ourPercentage"
                data-qa="ourPercentage" placeholder="%">
       </rgi-rx-form-field>
     </div>
     <div *ngIf="showPercentage" class="rgi-ui-col">
       <button rgi-rx-button color="info" class="btn" (click)="openCoInsuranceModal()" data-qa="coInsuranceDep">
         <span *ngIf="isTypeThirdParty()" translate>RE_ISSUE.LEADING_INSURER</span>
         <span *ngIf="!isTypeThirdParty()" translate>RE_ISSUE.CO_INSURANCE_DEP</span>
       </button>
     </div>
   </div>
  </div>
</form>

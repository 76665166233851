import {Inject, Injectable} from '@angular/core';

import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {
  EventInstance,
  EventProvider,
  EVENTS,
  Events,
  EventType,
  RgiRxEventConfig, RgiRxEventInstance,
  RgiRxEventStatus
} from './event-api';
import {filter} from 'rxjs/operators';
import {LoggerFactory} from '../logging/logging-providers';
import {RgiRxRuntimeError} from '../rgi-rx-api';


@Injectable({
  providedIn: 'root'
})
export class RgiRxEventService implements EventProvider {
  eventRegistry = new Map<string, BehaviorSubject<EventInstance<any>> | ReplaySubject<EventInstance<any>>>();

  private readonly logger = LoggerFactory();

  constructor(@Inject(EVENTS) private eventTypes: Events[]) {
    this.provideEvents(eventTypes);
  }


  provideEvents(events?: Events[]) {
    events.forEach(
      rootEvent => rootEvent.forEach(eventRegister => {
        let subject: BehaviorSubject<any> | ReplaySubject<any>;
        if (this.isEventConfig(eventRegister) && eventRegister.replay){
          subject = new ReplaySubject<EventInstance<any>>(eventRegister.replayBuffer !== undefined ? eventRegister.replayBuffer : 1, eventRegister.replayWindowTime);
        } else {
          subject = new BehaviorSubject<EventInstance<any>>({
            event: eventRegister,
            status: 'bootstrap'
          });
        }
          this.eventRegistry.set(eventRegister.eventName, subject);
          this.logger.debug(`EventService::init registered event ${eventRegister.eventName}`);
        }
      ));
  }

  /**
   * Complete all the Events and TearDown the service.
   * This method should only be called when destroying a custom context or
   * when the application must be destroyed.
   * No events stream will be active.
   */
  destroy() {
    this.eventRegistry.forEach(
      v => v.complete()
    );
  }

  emit<T extends EventType>(lifecycleEvent: EventInstance<T>);
  emit<T>(name: string, event: T);
  emit(lifecycleEvent: string | EventInstance<any>, eventData?) {

    const eventName = typeof lifecycleEvent === 'string' ? lifecycleEvent : lifecycleEvent.event.eventName;

    if (!this.eventRegistry.has(eventName)) {
      this.logger.error(`Event is not registered ${eventName}`);
      throw new RgiRxRuntimeError(`Event is not registered ${eventName}`);
    }
    const eventSubject = this.eventRegistry.get(eventName);

    const isReplay = eventSubject instanceof ReplaySubject;
    const isBootstrap = typeof lifecycleEvent === 'string' ? false : lifecycleEvent.status === 'bootstrap';
    if (typeof lifecycleEvent !== 'string') {
      this.validateInstance(lifecycleEvent);
    }
    if (!isBootstrap) {
      eventSubject.next(
        this.mapEvent(lifecycleEvent, eventData)
      );
      this.logger.debug(`EventService::emit ${eventName}`, typeof lifecycleEvent !== 'string' ? lifecycleEvent.event : eventData);
      if (!isReplay) {
        this.clear(eventName);
      }
    }
  }


  has(event: EventType | string) {
    return this.eventRegistry.has(typeof event === 'string' ? event : event.eventName);
  }

  clear(event: EventType | string) {
    const behaviorSubject = this.eventRegistry.get(typeof event !== 'string' ? event.eventName : event);
    behaviorSubject.next({
      status: 'clear',
      event
    });
    this.logger.debug(`EventService::clear ${typeof event === 'string' ? event : event.eventName}`);
  }

  listen<T extends EventType>(event: EventType | string): Observable<EventInstance<T>>;
  listen<T>(event: EventType | string): Observable<RgiRxEventInstance<T>>;
  listen(event: EventType | string): Observable<EventInstance<any> |  Observable<RgiRxEventInstance<any>>> {
    const behaviorSubject = this.eventRegistry.get(typeof event !== 'string' ? event.eventName : event);
    if (behaviorSubject == null) {
      throw new RgiRxRuntimeError(`no event is registered for type ${typeof event === 'string' ? event : event.eventName}`);
    }
    return behaviorSubject.asObservable()
      .pipe(
        filter(evt => evt.status !== 'bootstrap' && evt.status !== 'clear')
      );
  }

  private validateInstance<T extends EventType>(lifeCycleEvent: EventInstance<T>) {
    if (lifeCycleEvent.status === undefined) {
      lifeCycleEvent.status = 'lifecycle';
    }
    if (lifeCycleEvent.status !== 'bootstrap' && !lifeCycleEvent.event && !lifeCycleEvent.event.eventName) {
      throw new RgiRxRuntimeError('EventInstance is not valid');
    }
  }

  private mapEvent(lifecycleEvent: string | EventInstance<any>, event): EventInstance<any> {

    if (typeof lifecycleEvent === 'string') {
      const eventType: EventType = {
        eventName: lifecycleEvent
      };

      return {
        event: {...eventType, ...event},
        status: 'lifecycle'
      };
    }
    return lifecycleEvent;
  }

  private isEventConfig(event: EventType): event is RgiRxEventConfig {
    return (event as RgiRxEventConfig).replay !== undefined;
  }
}

/**
 * @deprecated use RgiRxEventService
 * @see RgiRxEventService
 */
export class EventService extends RgiRxEventService {
}

import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
  ApiQuestionnaireResponse,
  ApiSubjectResponse,
  ApiSurveyRequest,
  ApiSurveyResponse
} from '../models/api-models/api-survey-filter';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireDnService implements OnDestroy {

  private baseApiUrlV2;
  private baseApiUrlV1;
  protected httpClient: HttpClient;
  private enabledIddDn = false;
  private questionnairesDnChannel: Subject<boolean> = new Subject<boolean>();
  private listQuestionnairesVerified = new Map<string, string>();
  private changeSubscriberLoc = false;
  private recalculatePremiumLoc = false;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    @Inject('enabledIdd') enabledIdd: boolean
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/passproprogw';
    this.baseApiUrlV1 = environment.api.portal.host + environment.api.portal.path;
    this.enabledIddDn = enabledIdd;
    this.httpClient = this.micHttpClient.getHttpClient();
  }

  get enabledIdd(): boolean {
    return this.enabledIddDn;
  }

  get changeSubscriber(): boolean {
    return this.changeSubscriberLoc;
  }

  set changeSubscriber(value: boolean) {
    this.changeSubscriberLoc = value;
  }

  get recalculatePremium(): boolean {
    return this.recalculatePremiumLoc;
  }

  set recalculatePremium(value: boolean) {
    this.recalculatePremiumLoc = value;
  }

  public createApiSurveyRequest(array: any[]): ApiSurveyRequest {

    const apiSurveyRequest: ApiSurveyRequest = ApiSurveyRequest.createEmptyObject();
    apiSurveyRequest.filter.uuids = array;
    return apiSurveyRequest;

  }

  public getSurvey(apiSurveyRequest: ApiSurveyRequest) {
    return this.httpClient.put<ApiSurveyResponse>(this.baseApiUrlV2 + '/questionnaires/survey/filter', apiSurveyRequest);
  }

  public getQuestionnaires(dataQuestionnaire: string, codeProduct: string) {
    let httpParams: HttpParams = new HttpParams();
    if (dataQuestionnaire) {
      httpParams = httpParams.set('date', dataQuestionnaire);
    }
    if (codeProduct) {
      httpParams = httpParams.set('products', codeProduct);
    }
    return this.httpClient.get<ApiQuestionnaireResponse>(this.baseApiUrlV2 + '/questionnaires/questionnaire', {params: httpParams});
  }

  public getSubject(idSubject: string, idNode: string) {

    let httpParams: HttpParams = new HttpParams();
    if (idNode) {
      httpParams = httpParams.set('idNode', idNode);
    }

    return this.httpClient.get<ApiSubjectResponse>(this.baseApiUrlV1 + '/anag/subject/' + idSubject, {params: httpParams});
  }

  public getQuestionnairesInfo(apiSurveyRequest: ApiSurveyRequest, dataQuestionnaire: string, codeProduct: string) {
    return forkJoin([this.getQuestionnaires(dataQuestionnaire, codeProduct), this.getSurvey(apiSurveyRequest)]);
  }

  public getSurveyVersion(idVersion: string) {
    return this.httpClient.get<ApiQuestionnaireResponse>(this.baseApiUrlV2 + '/questionnaires/survey/versions/' + idVersion, {});
  }

  public getEvaluator(idSurveyVersion: string, codeProduct: string) {

    let httpParams: HttpParams = new HttpParams();
    if (idSurveyVersion) {
      httpParams = httpParams.set('idSurveyVersion', idSurveyVersion);
    }

    if (codeProduct) {
      httpParams = httpParams.set('products', codeProduct);
    }

    httpParams = httpParams.set('showResults', 'true');

    return this.httpClient.get<ApiSubjectResponse>(this.baseApiUrlV2 + '/questionnaires/evaluator', {params: httpParams});
  }

  public getSurveyVersionFromSurveyList(results: any[]): Observable<any> {

    if (results) {

      // console.log(results[0]);
      // console.log(results[1].list);

      const listQuestionnaires = (results[0] && results[0].list ? results[0].list : []);
      const listSurvey = (results.length > 1 && results[1].list ? results[1].list : []);

      if (listSurvey.length > 0) {
        const listSurveyFilter = listSurvey.filter(survey => {
          if (listQuestionnaires.filter(questionnaire => {
            if (questionnaire.code === survey.questionnaire.code) {
              return questionnaire;
            }
          }).length > 0) {
            return survey;
          }
        });

        if (listSurveyFilter && listSurveyFilter.length > 0) {
          // found survey
          return this.getSurveyVersion(listSurveyFilter[0].id);
        }
      }
    }

    return of(null);
  }

  public getEvaluateSurveyVersionFromSurveyVersion(results: any, codeProduct: string): Observable<any> {

    if (results) {
      // console.log(results);
      const listSurveyLastVersion = this.getLastVersion(results);
      if (listSurveyLastVersion !== null) {
        return this.getEvaluator(listSurveyLastVersion.id, codeProduct);
      }

    }

    return of(null);
  }

  public getLastVersion(results): any {
    const listSurveyVersion = (results && results.list ? results.list : []);
    if (listSurveyVersion.length > 0) {
      const listSurveyFilter = listSurveyVersion.filter(survey => {
        if (survey.isLastVersion === true) {
          return survey;
        }
      });
      if (listSurveyFilter && listSurveyFilter.length > 0) {
        return listSurveyFilter[0];
      }
    }
    return null;
  }

  public getSurveyListFromResults(results): any {
    return (results && results.length > 1 && results[1].list ? results[1].list : null);
  }

  public getStartQuestionnairesDnObservable() {
    return this.questionnairesDnChannel.asObservable();
  }

  public startQuestionnairesDn(changeSubscriber: boolean) {
    if (this.enabledIddDn === true) {
      this.questionnairesDnChannel.next(changeSubscriber);
    }
  }

  public buildSurveyFilledOut(subject: any, codeProduct: string, surveyCompleted: boolean) {
    return {fromIdd: false, surveyCompleted, subject, codeProduct};
  }

  public addQuestionnaireVerified(idSubject: string, codeProduct: string) {
    if (!this.isQuestionnaireVerified(idSubject, codeProduct)) {
      this.listQuestionnairesVerified.set(idSubject, codeProduct);
    }
  }

  public isQuestionnaireVerified(idSubject: string, codeProduct: string) {
    const cp = this.listQuestionnairesVerified.get(idSubject);
    return (cp != null && cp === codeProduct);
  }

  public clearQuestionnaireVerified() {
    this.listQuestionnairesVerified.clear();
  }

  ngOnDestroy() {
    this.clearQuestionnaireVerified();
  }


}



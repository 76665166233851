<label [attr.for]="name" class="checkbox-layout" #label>
  <div class="checkbox-inner-container">
    <input class="cdk-visually-hidden"
           #input
           type="checkbox"
           (change)="onInteraction($event)"
           (click)="onClick($event)"
           [id]="id"
           [checked]="checked"
           [attr.disabled]="disabled ? '' : null"
           [attr.required]="required ? '' : null"
           [name]="name">
    <div class="checkbox-frame"></div>
    <div class="checkbox-background">
      <svg
        version="1.1"
        focusable="false"
        class="checkbox-checkmark"
        viewBox="0 0 24 24"
        xml:space="preserve">
        <path class="checkbox-checkmark-path"
              fill="none"
              stroke="white"
              d="M4.1,12.7 9,17.6 20.3,6.3"/>
      </svg>
    </div>
  </div>
  <span class="checkbox-label"><ng-content></ng-content></span>
</label>

export class PlcCurrencyUtils {
  public static fixEuroSymbol(input: string): string {
    const eurRegexp = /&euro;|EUR/g;
    if (!!input && eurRegexp.test(input)) {
      // non so se è necessario usare la regex, si potrebbe fare così in una riga
      // return (input && (input === 'EUR' || input === '&euro')) ? '€' : input;
      return input.replace(eurRegexp, '€');
    }
    return input;
  }
}

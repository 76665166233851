import { CodiceDescrizione, Outcome } from "../dsc-shared-model/dsc-model-utility";

export class PolicyAgency {
    agencyAddress: string;
    agencyId: string;
    agencyDescription: string;
    agencyCode: string;
}

export class GeneralClaimsData {
    claimNumber: string = '';
    potentialClaimNumber: string = '';
    policyHolder?: string;
    companyCode?: string;
    companyDescription?: string;
    policyNumber: string;
    productCode?: string;
    productDescription?: string;
    policyAgencyEntity?: PolicyAgency;
    administrativeRegularity?: boolean;
    technicalCoverage?: boolean;
    ministerialCode?: string;
    ministerialDescription?: string;
    coinsuranceType?: string;
    potentialClaimDate?: string;
    occurrenceDate?: string;
    occurrenceDateCertified?: string;
    claimType?: string;
    ministerialBranchParty?: CodiceDescrizione[];
    outcome?: Outcome;
}

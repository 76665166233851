import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagStateKeyData, AnagStateManagerKeyData} from '../../anag-states/key-data/anag-state-manager-key-data';
import {CORPORATE_FORM_ENUM, PERSON_TYPE_ENUM, PROFESSION_TYPE_ENUM} from '../../anag-constants/enums-constants';
import {AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {of, Subscription} from 'rxjs';
import {AnagApiParty, AnagKey, AnagPartyKey} from '../../anag-model/anag-domain/anag-api-party';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {OperatorLight} from '../../services/external-service';
import {RgiRxUserService} from '@rgi/rx/auth';
import {RgiRxEventService, PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {AnagFormFieldConfig} from '../../anag-model/anag-domain/anag-form-field-config';
import {AnagConfigService} from '../../anag-resources/anag-config.service';
import {AnagDialogComponent, ModalDialogData} from '../modal/anag-dialog/anag-dialog.component';
import {ModalService} from '@rgi/rx/ui';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagApiNode} from '../../anag-model/anag-api/anag-api-node';
import {CORE_PARTY_CONFIG} from '../../anag-constants/anag-constants';
import {AnagPartyKeyFilter} from '../../anag-model/anag-api/anag-api-party-key-config-request';
import {Agency} from '../../anag-model/anag-api/sales-network-api-agency';



@Component({
  selector: 'rgi-anag-party-create-key-data',
  templateUrl: './anag-party-key-data.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagPartyKeyDataComponent implements OnInit, OnDestroy {

  @Input()
  inEditor = false;

  @Input()
  inputParty: AnagApiParty;

  state: AnagStateKeyData;

  @Output()
  modalClose: EventEmitter<any> = new EventEmitter<any>();
  nodeCodeFitSx: string;
  nodeDescriptionFitSx: string;
  nodeFitSx: Agency;
  isNodeFitx: boolean;
  subscriptions: Subscription;
  profDetailMandatory: boolean;
  submitted: boolean;
  keyDataForm: UntypedFormGroup;
  physicalPartyForm: UntypedFormGroup;
  legalPartyForm: UntypedFormGroup;
  partyKeyForm: UntypedFormGroup;
  keyDataFormMap: Map<string, AnagFormFieldConfig>;
  initialValues: { [key: string]: any } = {};
  isVisible: boolean = true;


  constructor(
    public stateMgr: AnagStateManagerKeyData,
    protected translateService: RgiRxTranslationService,
    protected anagStorage: AnagStorageService,
    protected userService: RgiRxUserService,
    public pushMessageHandler: PushMessageHandlerService,
    public configService: AnagConfigService,
    protected modalService: ModalService,
    protected eventService: RgiRxEventService,
    protected anagIntegrationService: AnagIntegrationService,
    protected statelessService: AnagStatelessService
  ) {
    this.physicalPartyForm = new UntypedFormGroup({
      professionType: new UntypedFormControl('', ),
      professionDetail: new UntypedFormControl('', )
    });
    this.legalPartyForm = new UntypedFormGroup({
      corporateForm: new UntypedFormControl('', )
    }, {updateOn: 'blur'});
    this.keyDataForm = new UntypedFormGroup({
      salePoint: new UntypedFormControl('', Validators.required),
      nation: new UntypedFormControl(this.userService.getUser<OperatorLight>().salePoint ?
        this.userService.getUser<OperatorLight>().salePoint.context : 'IT', Validators.required),
      personType: new UntypedFormControl('', Validators.required)
    });
    this.partyKeyForm = new UntypedFormGroup({});
    this.keyDataFormMap = new Map<string, AnagFormFieldConfig>([
      ['professionType', new AnagFormFieldConfig('professionType', this.physicalPartyForm, [])],
      ['professionDetail', new AnagFormFieldConfig('professionDetail', this.physicalPartyForm, [])],
      ['corporateForm', new AnagFormFieldConfig('corporateForm', this.legalPartyForm, [])],
    ]);
  }

  ngOnInit() {
    this.pushMessageHandler.clearTag('er-tag');
    this.pushMessageHandler.clearTag('errorMsg-tag');
    this.stateMgr.initKeyData(this.inputParty);
    if (this.inputParty) {
      this.manageInputParty();
    }
    this.subscriptions = new Subscription();
    this.manageSubscriptionsOnValueChanges();
    this.subscriptions.add(this.stateMgr.getState$().pipe(take(1)).subscribe((state) => {
        this.state = state;
        this.adaptExistingPartyToForm(state, this.keyDataForm, this.physicalPartyForm, this.legalPartyForm);
        if (state.party && state.party.professionType) {
          this.stateMgr.retrieveProfessionDetails(state.party.professionType.codice);
        }
        this.stateMgr.actionUpdateConfig(this.keyDataForm.getRawValue());
        if (this.keyDataForm.get('personType').value) {
          this.homonymyManagement(this.keyDataForm.get('personType').value);
        }
        if (this.inputParty && this.inputParty.objectId && !this.state.isUmaPartyHomonymyEnable) {
          this.keyDataForm.controls.nation.disable();
        }
        if (this.inputParty && this.inputParty.objectId && !this.state.isUmaPartyHomonymyEnable) {
          this.keyDataForm.get('personType').disable();
        }
        if (this.inputParty && this.inputParty.objectId && !this.state.isUmaPartyHomonymyEnable) {
          this.keyDataForm.controls.salePoint.disable();
        }
        if (this.inputParty && this.inputParty.objectId && !this.state.anagNodeFitSx) {
          this.nodeCodeFitSx = this.state.anagNodeFitSx;
        }
      }
    ));
    // tslint:disable-next-line:max-line-length
    this.subscriptions.add(this.stateMgr.getState$().pipe(map(state => state.partyConfiguration), distinctUntilChanged()).subscribe(config => {
        if (config) {
          this.configService.setConfigToForm(this.keyDataFormMap, config);
        }
      }
    ));
    this.subscriptions.add(this.stateMgr.getState$().pipe(map(state => state.partyKeyConf), distinctUntilChanged()).subscribe(keyConfig => {
        if (keyConfig) {
          if (this.partyKeyForm.get('partyKey1')) {
            this.partyKeyForm.get('partyKey1').setValue(null);
            this.partyKeyForm.get('partyKey1').enable();
            const key1Validators = this.key1Pattern !== '' ? [Validators.pattern(this.key1Pattern)] : [];
            if (this.partyKeyForm.get('partyKey1').validator) {
              const currValidators = this.partyKeyForm.get('partyKey1').validator({} as AbstractControl);
              if (currValidators && currValidators.required) {
                key1Validators.push(Validators.required);
              }
            }
            this.partyKeyForm.get('partyKey1').setValidators(key1Validators);
          }
          if (this.partyKeyForm.get('partyKey2')) {
            const key2Validators = this.key2Pattern !== '' ? [Validators.pattern(this.key2Pattern)] : [];
            if (this.partyKeyForm.get('partyKey2').validator) {
              const currValidators = this.partyKeyForm.get('partyKey2').validator({} as AbstractControl);
              if (currValidators && currValidators.required) {
                key2Validators.push(Validators.required);
              }
            }
            this.partyKeyForm.get('partyKey2').setValidators(key2Validators);
          }
          if (this.partyKeyForm.get('partyKey3')) {
            const key3Validators = this.key3Pattern !== '' ? [Validators.pattern(this.key3Pattern)] : [];
            if (this.partyKeyForm.get('partyKey3').validator) {
              const currValidators = this.partyKeyForm.get('partyKey3').validator({} as AbstractControl);
              if (currValidators && currValidators.required) {
                key3Validators.push(Validators.required);
              }
            }
            this.partyKeyForm.get('partyKey3').setValidators(key3Validators);
          }
          if (this.profDetailMandatory) {
            this.physicalPartyForm.get('professionDetail').setValidators(Validators.required);
            this.physicalPartyForm.updateValueAndValidity();
          } else {
            this.profDetailMandatory = false;
          }
        }
      }
    ));
  }


  adaptExistingPartyToForm(state: AnagStateKeyData, keyDataForm: UntypedFormGroup, physicalPartyForm: UntypedFormGroup, legalPartyForm: UntypedFormGroup) {
    const party = state.party;
    const flowData = this.state.flowData;
    if(flowData && flowData.partyRole && flowData.partyRole === '888') {
        if (!!this.nodeCodeFitSx) {
          this.isNodeFitx = true;
          this.anagStorage.getNodesSinistri$(this.nodeCodeFitSx).subscribe(res => {
            this.nodeFitSx = res[0];
            this.state.nodes.push(this.nodeFitSx);
            this.nodeDescriptionFitSx = this.nodeFitSx.description;
          });
          this.keyDataForm.controls.salePoint.setValue(this.nodeCodeFitSx);
          this.keyDataForm.updateValueAndValidity();
        }
    }
    else  if (state.flowData && state.flowData.nodeCode) {
      const node = this.state.nodes.find(currNode => currNode.code === state.flowData.nodeCode);
      this.setControlValue(keyDataForm.get('salePoint'), node.code);
      keyDataForm.get('salePoint').disable();
    } else if (party.node && party.node.code) {
      this.setControlValue(keyDataForm.get('salePoint'), party.node.code);
    }
    if (party.partyKey && party.partyKey[0]) {
      this.setControlValue(keyDataForm.get('nation'), party.partyKey[0].country);
    }
    if (state.flowData && state.flowData.partyType) {
      this.setControlValue(keyDataForm.get('personType'), state.flowData.partyType);
      keyDataForm.get('personType').disable();
      this.managePartyType(state.flowData.partyType);
      party.personType = new AnagEntityIta(state.flowData.partyType, undefined);
    } else if (party.personType && party.personType.codice) {
      this.setControlValue(keyDataForm.get('personType'), party.personType.codice);
    }
    //[[RQP-7974]]
    if (party.personType && party.personType.codice === '1') {
      let pType: string = "";
      if(party.professionType != null &&  party.professionType.codice != null) {
        pType = party.professionType.codice;
      }
      this.setControlValue(physicalPartyForm.get('professionType'), pType);
      this.setControlValue(physicalPartyForm.get('professionDetail'), party.professionDetail ? party.professionDetail.codice : null);
    } else if (party.personType && party.personType.codice === '2') {
      this.setControlValue(legalPartyForm.get('corporateForm'), party.corporateForm ? party.corporateForm.codice : null);
    }
  }

  notifyFormValidationErrorMsg() {
    this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(stringMsg =>
      this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
        tag: 'er-tag',
        status: 'danger',
        dismissible: false
      }))
    ).unsubscribe();
  }

  private manageSubscriptionsOnValueChanges() {
    this.subscriptions.add(this.keyDataForm.valueChanges.subscribe(() => {
      this.submitted = false;

    }));

    this.subscriptions.add(this.legalPartyForm.get('corporateForm').valueChanges.subscribe(corporateForm => {
      this.pushMessageHandler.clearTag('er-tag');
      if(!this.legalPartyForm.get('corporateForm').disabled) {
        this.stateMgr.actionUpdateConfig(this.keyDataForm.getRawValue());
      }
      if (this.keyDataForm.get('personType') && this.keyDataForm.get('personType').value) {
        this.homonymyManagement(this.keyDataForm.get('personType').value);
      }
    }));

    this.subscriptions.add(this.physicalPartyForm.get('professionType').valueChanges.subscribe(professionCode => {
      this.pushMessageHandler.clearTag('er-tag');
      this.physicalPartyForm.get('professionDetail').reset();
      if (professionCode) {
        this.profDetailMandatory = true;
        this.stateMgr.retrieveProfessionDetails(professionCode);
      } else {
        this.profDetailMandatory = false;
        this.stateMgr.getCurrentState().party.professionType = null;
        this.stateMgr.getCurrentState().party.professionDetail = null;
      }
      if(!this.physicalPartyForm.get('professionType').disabled) {
        this.stateMgr.actionUpdateConfig(this.keyDataForm.getRawValue());
      }
      if (this.keyDataForm.get('personType') && this.keyDataForm.get('personType').value) {
        this.homonymyManagement(this.keyDataForm.get('personType').value);
      }
    }));


    this.subscriptions.add(this.keyDataForm.get('nation').valueChanges.subscribe(nationCode => {
      
      if(!this.keyDataForm.get('nation').disabled) {
        this.stateMgr.actionUpdateConfig(this.keyDataForm.getRawValue());
      }

      if (this.keyDataForm.get('personType') && this.keyDataForm.get('personType').value) {
        this.homonymyManagement(this.keyDataForm.get('personType').value);
      }
    }));

    this.subscriptions.add(this.keyDataForm.get('personType').valueChanges.subscribe(personType => {
      this.pushMessageHandler.clearTag('er-tag');
      this.managePartyType(personType);
      this.homonymyManagement(personType);
    }));
  }

  homonymyManagement(personType) {
    if (this.state.isUmaPartyHomonymyEnable) {
      if (personType === '1') {
        if (this.state.isUmaPartyHomonymyEnable) {
          if (!this.physicalPartyForm.get('homyDateofBirth')) {
            // tslint:disable-next-line:max-line-length
            this.physicalPartyForm.addControl('homyDateofBirth', new UntypedFormControl(this.state.party.dateOfBirth ? this.state.party.dateOfBirth : ''));
            this.keyDataFormMap.set('homyDateofBirth', new AnagFormFieldConfig('dateOfBirth', this.physicalPartyForm, []));
          }
          if (!this.physicalPartyForm.get('homyName')) {
            this.physicalPartyForm.addControl('homyName', new UntypedFormControl(this.state.party.name ? this.state.party.name : ''));
            this.keyDataFormMap.set('homyName', new AnagFormFieldConfig('name', this.physicalPartyForm, []));
          }
          if (!this.physicalPartyForm.get('homySurname')) {
            this.physicalPartyForm.addControl('homySurname', new UntypedFormControl(this.state.party.surname ? this.state.party.surname : ''));
            this.keyDataFormMap.set('homySurname', new AnagFormFieldConfig('surname', this.physicalPartyForm, []));
          }
          if (!this.physicalPartyForm.get('homyBirthName')) {
            this.physicalPartyForm.addControl('homyBirthName', new UntypedFormControl(this.state.party.birthName ? this.state.party.birthName : ''));
            this.keyDataFormMap.set('homyBirthName', new AnagFormFieldConfig('birthName', this.physicalPartyForm, []));
          }
        }
      } else {
        if (!this.legalPartyForm.get('homyCompanyName')) {
          // tslint:disable-next-line:max-line-length
          this.legalPartyForm.addControl('homyCompanyName', new UntypedFormControl(this.state.party.denomination ? this.state.party.denomination : this.state.party.surname ? this.state.party.surname : ''));
          this.keyDataFormMap.set('homyCompanyName', new AnagFormFieldConfig('denomination', this.legalPartyForm, []));
        }
      }
      if (!this.partyKeyForm.get('partyKey1')) {
        this.partyKeyForm.addControl('partyKey1', new UntypedFormControl(this.state.party.partyKey && this.state.party.partyKey.length > 0
        && this.state.party.partyKey[0].key1 ? this.state.party.partyKey[0].key1.value : ''));
        this.keyDataFormMap.set('partyKey1', new AnagFormFieldConfig('PasubjectkeyCkey1', this.partyKeyForm, []));
        this.initialValues.partyKey1 = this.partyKeyForm.get('partyKey1').value;
      }
      if (!this.partyKeyForm.get('partyKey2')) {
        this.partyKeyForm.addControl('partyKey2', new UntypedFormControl(this.state.party.partyKey && this.state.party.partyKey.length > 0
        && this.state.party.partyKey[0].key2 ? this.state.party.partyKey[0].key2.value : ''));
        this.keyDataFormMap.set('partyKey2', new AnagFormFieldConfig('PasubjectkeyCkey2', this.partyKeyForm, []));
        this.initialValues.partyKey2 = this.partyKeyForm.get('partyKey2').value;
      }
      if (!this.partyKeyForm.get('partyKey3')) {
        this.partyKeyForm.addControl('partyKey3', new UntypedFormControl(this.state.party.partyKey && this.state.party.partyKey.length > 0
        && this.state.party.partyKey[0].key3 ? this.state.party.partyKey[0].key3.value : ''));
        this.keyDataFormMap.set('partyKey3', new AnagFormFieldConfig('PasubjectkeyCkey3', this.partyKeyForm, []));
        this.initialValues.partyKey3 = this.partyKeyForm.get('partyKey3').value;
      }
    }
  }

  private manageInputParty() {
    if (this.inputParty.personType) {
      if (this.inputParty.personType.codice === '1') { // Physical
        this.enablePhysicalPartyForm();
      } else { // Legal
        this.enableLegalPartyForm();
      }
    }
  }

  protected managePartyType(personType: string) {
    if (personType === '1') { // Physical
      this.enablePhysicalPartyForm();
    } else if (personType === '2') { // Legal
      this.enableLegalPartyForm();
    }
    this.stateMgr.setPersonType(personType);
    this.stateMgr.actionUpdateConfig(this.keyDataForm.getRawValue());
  }

  protected enablePhysicalPartyForm() {
    this.keyDataForm.removeControl('legalPartyForm');
    this.keyDataForm.addControl('physicalPartyForm', this.physicalPartyForm);
  }

  protected enableLegalPartyForm() {
    this.keyDataForm.removeControl('physicalPartyForm');
    this.keyDataForm.addControl('legalPartyForm', this.legalPartyForm);
  }

  get personTypes(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(PERSON_TYPE_ENUM);
  }

  get professionTypes(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(PROFESSION_TYPE_ENUM);
  }

  get corporateForms(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(CORPORATE_FORM_ENUM);
  }

  setControlValue(control: AbstractControl, value: any) {
    if (!!control && !!value) {
      control.setValue(value);
    }
  }

  actionCancel() {
    if (this.inEditor) {
      this.modalClose.emit();
    } else {
      this.stateMgr.actionCancel();
    }
  }

  professionValid(): boolean {
    const professionType = this.physicalPartyForm.get('professionType').value;
    const professionDetail = this.physicalPartyForm.get('professionDetail').value;

    return !professionType || professionDetail;
  }

  manageConfirm() {
    this.submitted = true;
    if (this.keyDataForm.valid && this.partyKeyForm.valid && this.professionValid()) {
      if (this.state.isUmaPartyHomonymyEnable) {
        const hasK1: boolean = this.partyKeyForm.controls.partyKey1 && this.partyKeyForm.controls.partyKey1.value;
        const hasK2: boolean = this.partyKeyForm.controls.partyKey2 && this.partyKeyForm.controls.partyKey2.value;
        const hasK3: boolean = this.partyKeyForm.controls.partyKey3 && this.partyKeyForm.controls.partyKey3.value;
        const hasPartyKey = hasK1 || hasK2 || hasK3;
        const filterReq = this.filterWithHomonymy();
        if (this.state.isUmaPartyCFDuplicate && !this.state.isUmaPartyGroupCompanies && hasPartyKey) {
          this.stateMgr.duplicateParty(filterReq).subscribe(resp => {
            if (resp.result && resp.result.outcome) {
              switch (resp.result.outcome.codice) {
                case '1':
                  this.handleDuplicatePartySuccess(resp);
                  break;
                case '2':
                case '3':
                  this.notifyFormValidationErrorMsgInheritance(resp.outcome);
                  break;
                default:
                  this.findParties(filterReq);
              }
            } else {
              this.findParties(filterReq);
            }
          });
        } else {
          this.findParties(filterReq);
        }
      } else {
        this.updateOrNewParty();
      }
    } else {
      this.notifyFormValidationErrorMsg();
    }
  }

  private handleDuplicatePartySuccess(resp: any) {
    this.translateService.translate('_ANAG_._MSG_._INHERITANCE_').subscribe(stringMsg => {
      const anagModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(stringMsg, true));
      this.isVisible = false;
      anagModal.modal.onClose.subscribe(onCloseData => {
        if (onCloseData && onCloseData.confirm) {
          this.modalClose.emit();
          const messages = resp.result.messages;
          if (messages[3] === '2') {
            this.getSubject(messages[2], messages[4], null);
          } else if (messages[3] === '3') {
            this.getSubject(messages[2], null, null);
          }
        } else {
          this.isVisible = true;
        }
      });
    }).unsubscribe();
  }

  private updateOrNewParty() {
    if (this.inEditor) {
      this.stateMgr.actionUpdateParty(this.keyDataForm.getRawValue());
      this.modalClose.emit();
    } else {
      this.stateMgr.actionNewParty(this.keyDataForm.getRawValue());
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  filterWithHomonymy() {
    const party = new AnagApiParty();
    const currentState = this.stateMgr.getCurrentState();
    const physicalForm = this.physicalPartyForm;
    const legalForm = this.legalPartyForm;
    const keyForm = this.keyDataForm;
    const partyKeyForm = this.partyKeyForm;

    party.partyKey = new Array<AnagPartyKey>(this.stateMgr.getCurrentState().party.partyKey[0]);
    party.birthName = this.getFormValue(physicalForm, 'homyBirthName');
    party.surname = this.getFormValue(physicalForm, 'homySurname');
    party.name = this.getFormValue(physicalForm, 'homyName');
    party.dateOfBirth = this.getFormValue(physicalForm, 'homyDateofBirth');
    party.denomination = this.getFormValue(legalForm, 'homyCompanyName');

    if (party.partyKey && party.partyKey.length > 0) {
      const keyDataForm = this.keyDataForm;
      const partyKey = party.partyKey[0];

      partyKey.country = this.getFormValue(keyDataForm, 'nation');
      if (partyKey.key1) {
        partyKey.key1.value = this.getFormValue(partyKeyForm, 'partyKey1');
      } else if (this.partyKeyForm.get('partyKey1')) {
        partyKey.key1 = new AnagKey();
        partyKey.key1.value = this.getFormValue(partyKeyForm, 'partyKey1');
      }
      if (partyKey.key2) {
        partyKey.key2.value = this.getFormValue(partyKeyForm, 'partyKey2');
      } else if (this.partyKeyForm.get('partyKey2')) {
        partyKey.key2 = new AnagKey();
        partyKey.key2.value = this.getFormValue(partyKeyForm, 'partyKey2');
      }
      if (partyKey.key3) {
        partyKey.key3.value = this.getFormValue(partyKeyForm, 'partyKey3');
      } else if (this.partyKeyForm.get('partyKey3')) {
        partyKey.key3 = new AnagKey();
        partyKey.key3.value = this.getFormValue(partyKeyForm, 'partyKey3');
      }
    }

    const selectedNodeForm = currentState.nodes.find(node => node.code === this.getFormValue(keyForm, 'salePoint'));
    party.node = new AnagApiNode(selectedNodeForm.code, selectedNodeForm.description, '' + selectedNodeForm.id);

    party.personType = this.getEnumByCode(this.anagStorage.getEnumsByCode(PERSON_TYPE_ENUM), this.getFormValue(keyForm, 'personType'));

    const professionType = this.getFormValue(physicalForm, 'professionType');
    // tslint:disable-next-line:max-line-length
    party.professionType = professionType ? this.getEnumByCode(this.anagStorage.getEnumsByCode(PROFESSION_TYPE_ENUM), professionType) : null;

    const professionDetail = this.getFormValue(physicalForm, 'professionDetail');
    party.professionDetail = professionDetail ? currentState.professionDetails.find(detail => detail.codice === professionDetail) : null;

    party.subjectType = currentState.party.subjectType;
    party.objectId = currentState.party.objectId;

    this.stateMgr.updatePartyFromState(this.keyDataForm.getRawValue());

    return party;
  }

  getFormValue(form: any, controlName: string): any {
    const control = form.get(controlName);
    return control ? control.value : null;
  }

  getEnumByCode(enumList: any[], code: string): any {
    return enumList.find(item => item.codice === code);
  }

  findParties(filterReq) {
    this.stateMgr.actionFindParties(filterReq).subscribe(response => {
      if (response.output.totalCounter === '0') {
        this.updateOrNewParty();
      } else {
        this.modalClose.emit();
      }
    });
  }

  private getSubject(objectId: string, string2: string, param3) {
    this.stateMgr.getSubjectDetail(objectId, string2, null).subscribe(subject => {
      if (subject) {
        const newState = this.stateMgr.getCurrentState();
        const oldNode = newState.party.node;
        newState.party = subject.subject;
        newState.party.node = oldNode;
        this.stateMgr.updateState$(of(newState));
        this.updateOrNewParty();
      }
    });
  }

  notifyFormValidationErrorMsgInheritance(errorMsg) {
    this.pushMessageHandler.clearTag('errorMsg-tag');
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
        tag: 'errorMsg-tag',
        status: 'danger',
        dismissible: false
    }));
  }

  checkETypeOfKeys(key) {
    switch (key) {
      case '1':
        return '^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$';
      case '2':
        return '^\\d{11}$';
      case '6':
        return '^[ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw]\\d{7}[0-9A-Ja-j]$|^\\d{8}[A-Za-z]$';
      case '7':
        return '^[ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw]\\d{7}[0-9A-Ja-j]$';
      case '18':
        return '^756\\d{4}\\d{4}\\d{2}$';
      case '19':
        return '^[XYZxyz]\\d{7,8}[A-Za-z]$';
      default:
        break;
    }
  }

  get key1Pattern() {
    const state = this.stateMgr.getCurrentState();
    switch (this.keyDataForm.get('nation').value) {
      case 'IT':
        return state.partyKeyConf && state.partyKeyConf.key1 && state.partyKeyConf.key1.type &&
          this.stateMgr.getCurrentState().party.personType.codice === '1' ?
          this.checkETypeOfKeys(state.partyKeyConf.key1.type.codice) : '';
      case 'ES':
      case 'CH':
        return state.partyKeyConf && state.partyKeyConf.key1 && state.partyKeyConf.key1.type ?
          this.checkETypeOfKeys(state.partyKeyConf.key1.type.codice) : '';
      default:
        break;
    }
  }

  get key2Pattern() {
    const state = this.stateMgr.getCurrentState();
    switch (this.keyDataForm.get('nation').value) {
      case 'IT':
      case 'ES':
      case 'CH':
        return state.partyKeyConf && state.partyKeyConf.key2 && state.partyKeyConf.key2.type ?
          this.checkETypeOfKeys(state.partyKeyConf.key2.type.codice) : '';
      default:
        break;
    }
  }

  get key3Pattern() {
    const state = this.stateMgr.getCurrentState();
    switch (this.keyDataForm.get('nation').value) {
      case 'ES':
      case 'CH':
        return state.partyKeyConf && state.partyKeyConf.key3 && state.partyKeyConf.key3.type ?
          this.checkETypeOfKeys(state.partyKeyConf.key3.type.codice) : '';
      default:
        break;
    }
  }

  onPartyKeyChange(key) {
    if (this.partyKeyForm.get(key).value !== this.initialValues[key]) {
      this.checkPartyKeyService();
      this.initialValues[key] = this.partyKeyForm.get(key).value;
    }
  }

  checkPartyKeyService() {
    const filter = this.createRequestCheckPartyKey();
    this.stateMgr.checkPartyKey(filter).pipe(take(1)).subscribe(resp => {
      if (resp.outcome !== null) {
        this.notifyFormValidationErrorMsgInheritance(resp.outcome);
        this.partyKeyForm.setErrors(resp.outcome);
      } else {
        this.partyKeyForm.setErrors(null);
        this.pushMessageHandler.clearTag('errorMsg-tag');
      }
    });
  }

  createRequestCheckPartyKey() {
    const partyKeyConf = this.statelessService.deepClone(this.stateMgr.getCurrentState().partyKeyConf);
    const emitter = {partyKey: partyKeyConf};
    if (this.partyKeyForm.getRawValue().partyKey1) {
      emitter.partyKey.key1.value = this.partyKeyForm.getRawValue().partyKey1;
    }
    if (this.partyKeyForm.getRawValue().partyKey2) {
      emitter.partyKey.key2.value = this.partyKeyForm.getRawValue().partyKey2;
    }
    if (this.partyKeyForm.getRawValue().partyKey3) {
      emitter.partyKey.key3.value = this.partyKeyForm.getRawValue().partyKey3;
    }
    this.statelessService.extendsAnagPartyKey(this.physicalPartyForm,
      this.legalPartyForm,
      partyKeyConf);
    return emitter;

  }
}

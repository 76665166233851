<div class="rgi-ui-panel-container">
    <div class="rgi-ui-panel-header rgi-anag-p-1">
        <span class="anag-icon-m rgifont rgi-binoculars header-icon"></span>
        <span class="rgi-ui-panel-title rgi-ui-text-3">{{'_ANAG_._LABEL_._ANTI_TERRORISM_' | translate}}</span>
        <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
    </div>

     <ng-container [formGroup]="formTerrorism">
        <div class="rgi-ui-panel-content">
            <rgi-rx-form-field *ngIf="isFieldVisible('terrorismCode')">
                <label rgiRxLabel translate>_ANAG_._LABEL_._COUNTERTERRORISM_STATUS_ </label>
                <span> </span>
                <select rgiRxNativeSelect data-qa="anag-counter-terrorism-status" formControlName="terrorismCode">
                <option *ngFor="let comboTerr of detailComboTerrorismList"
                    value="{{comboTerr.codice}}">{{comboTerr.descrizione | translate}}</option>
                </select>
            </rgi-rx-form-field>


        </div>
    </ng-container>
    <div class="rgi-ui-panel-footer">
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="actionClose()" data-qa="rgi-anag-btn-cancel" translate>
            _ANAG_._BTN_._CANCEL_
        </button>
        <button class="rgi-ui-btn rgi-ui-btn-primary" [disabled]="formTerrorism.invalid"
            (click)="confirmAction()" data-qa="rgi-anag-btn-confirm" translate>
            _ANAG_._BTN_._CONFIRM_
        </button>
    </div>

</div>


import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const CA_ES: RgiRxSerializedI18n = {
  START_EMISSION_FLOW_RE: 'Flux d’emissions RE',
  START_POLICY_DATA: 'Dades de la pòlissa',
  START_ASSETS_DATA: 'Dades de bé assegurat',
  START_QUOTATION: 'Cotització',
  START_SUMMARY: 'Resum',
  START_PROPOSAL_MANAGEMENT: 'Gestió de proposta',
  START_SAVE_PROPOSAL: 'Deseu la proposta',
  START_EMISSION: 'Emissió',
  START_REPRINT_DOCUMENTS: 'Reimpressió de documents',
  START_REPRINT_MOVEMENTS: 'Reimpressió de moviments',
  START_SUBSTITUTION: 'Reemplaçament',
  // Replace space with underscore
  RE_ISSUE: {
    DATE_CREDIT_CARD: 'No és possible continuar atès que la data d’entrada en vigor no es pot endarrerir més de 360 dies a partir de la data d’avui',
    REASON: 'Motiu',
    RECALCULATE_SAVE: 'Calculeu de nou i deseu',
    CLEAR_DISCOUNTS: 'Suprimiu descomptes',
    DISCOUNT_AMOUNT: 'Import de descomptes',
    DISCOUNTS: 'Gestió de descomptes',
    WARRANTIES_SUMMARY: 'Resum de garanties',
    IS_MANDATORY: 'és obligatori',
    Country: 'País',
    POLICY_DATA: 'Dades de la pòlissa',
    ASSET_DATA: 'Dades dels béns assegurats',
    QUOTATION: 'Cotització',
    SUMMARY: 'Resum',
    VALIDATE_BRANCH: 'Valideu la sucursal',
    FIELDS_MARKED_IN_RED: 'Els camps marcats en vermell són obligatoris o contenen valors incorrectes',
    IS_REQUIRED: 'és necessari',
    COVERAGE_EXPIRE_DATE: 'Data de caducitat de la cobertura',
    SUBSCRIPTION_INST_PREMIUM: 'Prima de quota de subscripció',
    INST_SIGN: 'Termini de subscripció: ',
    MANDATORY_QUESTIONNAIRE_NOT_FILLED: 'Atenció, hi ha almenys un qüestionari obligatori sense emplenar',
    NATION: 'Nació',
    CANCEL: 'Cancel·leu',
    CONFIRM: 'Confirmeu',
    DATE_OF_BIRTH: 'Data de naixement',
    FACTORS: 'Factors',
    PLACE_OF_BIRTH: 'Lloc de naixement',
    DAMAGES_PROPOSAL_ASSET_DATA: 'Proposta de danys- dades del bé assegurat',
    SUBJECTS: 'Subjectes',
    INSURED_SUBJECTS: 'Subjecte assegurat',
    OCCUPATION: 'Professió',
    FISCAL_CODE: 'Codi fiscal',
    BACK: 'Enrere',
    ADD_ASSET: 'Afegiu bé assegurat',
    NEXT: 'Continueu',
    CONTINUE: 'Pròxim',
    NEXT_START_CARD: 'Continueu',
    EMISSION: 'Emissió',
    POLICY_N: 'Núm. De pòlissa',
    SAVED_SUCCESSFULLY: 'S’ha desat correctament',
    SUCCESSFULLY_FINALIZED: '’Ha finalitzat correctament',
    PRINT_PIN: 'És necessari imprimir i introduir el PIN per finalitzar el contracte',
    PRINT: 'Imprimiu',
    PRINT_ENTRY: 'Introducció del PIN',
    EXIT: 'Sortiu',
    ISSUE_CONFIRMATION: 'Confirmació d’emissió',
    ENTER_PIN_TO_CONFIRM_POLICY_ISSUE: 'Introduïu el PIN per confirmar l’emissió de la pòlissa',
    CONFIRM_PURCHASE: 'Confirmeu compra',
    POLICY_PREVIEW: 'Vista prèvia de la pòlissa',
    VALIDATE: 'Valideu',
    POLICYHOLDER: 'Prenedor',
    NODE: 'Node',
    NODE_START_CARD: 'Punt de venda',
    eMAIL: 'Correu electrònic',
    PRODUCT: 'Producte',
    BRANCH_OFFICE: 'Sucursal',
    FISCAL_CODE_VAT_NUMBER: 'Codi fiscal/NIF',
    RESIDENCE_ADDRESS: 'Adreça de residència',
    CONTACTS: 'Contactes',
    MOBILE_PHONE: 'Telèfon mòbil',
    EFFECTIVE_DATE: 'Data d’entrada en vigor',
    NEXT_INSTALMENT_DATE: 'Data del pròxim termini',
    EXPIRE_DATE: 'Data de venciment',
    TACIT_RENEWAL: 'Renovació tàcita',
    TECHNICAL_DATA: 'Dades tècniques',
    PAYMENT_FREQUENCY: 'Freqüència de pagament',
    AGREEMENT: 'Acord',
    PROPOSAL_MANAGEMENT: 'Gestió de proposta',
    ISSUE_DATE: 'Data d’emissió',
    POLICY_MANDATORY_DATA: 'Dades de la pòlissa obligatòries',
    POLICY_NUMBER: 'Número de pòlissa',
    MEANS_OF_PAYMENT: 'Mètodes de pagament',
    SELECT_MEANS_OF_PAYMENT: 'Seleccioneu mètode de pagament',
    COORDINATES_TO_REFUND: 'Coordinades de la devolució',
    HEADING: 'Encapçalament',
    ISSUE: 'Emissió',
    '{{header}}': '',
    '{{ parameter?.description }}': '',
    QUESTIONNAIRES: 'Qüestionaris',
    NOT_COMPILED: 'No recopilat',
    COMPILED: 'Recopilat',
    TO_BE_FILLED_IN_BY_THE_SUBJECT: 'Per emplenar pel subjecte amb el rol de contractant',
    CLAUSES: 'Clàusules',
    ASSET_CLAUSES: 'Clàusules dels béns assegurats',
    INSURABILITY_REQUIREMENTS: 'Requisits d’assegurabilitat',
    PRE_DISCOUNT_PREMIUM: 'Prima prèvia al descompte',
    GROSS: 'En brut',
    PREMIUM_INSTALLMENT: 'Prima prèvia al descompte',
    ANNUAL_PREMIUM: 'Prima anual',
    PREMIUM_RATE: 'Tipus de prima',
    RECALCULATE: 'Calculeu de nou',
    VISUALIZE: 'Visualitzeu',
    PREMIUM_DETAILS: 'Detalls de la prima',
    CONTRIBUTIONS_AND_TAXES: 'Cotitzacions i imposts',
    BOX: 'Casella',
    LIST: 'Llista',
    COMMISSIONS: 'Comissions',
    AUTHORITY_LIMIT: 'Límit d’autoritat',
    PERC_ACQUIRED_COMMISSIONS: '% de comissions obtingudes',
    PERC_ACQUIRED_CHARGES: '% de despeses contretes',
    PERC_COLLECTED_COMMISSIONS_NET: '% Comissió cobrada (neta)',
    PERC_COLLECTED_CHARGES: '% de despeses cobrades',
    APPLY: 'Apliqueu',
    EDIT_AMOUNT: 'Editar import',
    COMMISSIONS_AT_INCEPTION_DATE: 'Comissions a la data d’inici',
    ACQUIRED_COMMISSIONS: 'de comissions obtingudes',
    ACQUIRED_CHARGES: 'de despeses contretes',
    COLLECTED_COMMISSIONS_NET: 'Comissió cobrada (Neta)',
    COLLECTED_CHARGES: 'Despeses cobrades',
    TOTAL: 'Total',
    AMOUNT: 'Import',
    '{{ commission.fees ? \'Fees\' : Comissió descripció }}': '',
    NEXT_COMM_INSTALMENT: 'Pròxim termini de la com.',
    RECALCULATE_AND_SAVE: 'Calculeu de nou i deseu',
    CLOSE: 'Tanqueu',
    INSTALMENT_AT_INCEPTION_DATE: 'Termini a la data d’inici',
    TAXABLE: 'Imposable',
    INSTALMENT_AT_INCEPTION_DATE_GROSS: 'Termini brut a la data d’inici',
    INSURANCE_TAX: 'Impost sobre l’assegurança',
    RATE: 'Tarifa',
    TAXES: 'Imposts',
    ANTIRACKET: '(Inclosa la lluita contra l’extorsió)',
    NEXT_INSTALMENT: 'Nou termini',
    NEXT_INSTALMENT_GROSS: 'Pròxim termini brut',
    '{{questionnaireFlat?.descriptionType}}': '',
    QUOTATION_DETAILS: 'Informació de cotització',
    '{{ premiumDetail.sectionTitle }}': '',
    NET: 'Net',
    ACCESSORIES: 'Complementaris',
    INST_INTEREST: 'Interès a terminis',
    'Damages proposal - Quotation': 'Proposta no vida-cotització',
    '{{assetInstance.instanceDescription}}': '',
    PARAMETERS: ' Paràmetres de la pòlissa',
    PROPOSAL_N: 'Número de proposta',
    AUTHORIZATION_SEND: 'i es va enviar una sol·licitud d’autorització',
    SAVING_QUOTE: 'Deseu cotització',
    PROCEDE_TO_CHECKOUT: 'Procediu al pagament',
    CHECKOUT: 'Pagament',
    CODE: 'Codi',
    ACTION: 'Acció',
    NODE_LIST: 'Llista de nodes',
    DRAG_SELECT: 'Arrossegueu/seleccioneu',
    COMPANY: 'Companyia',
    POLICY_STATE: 'Estat de la pòlissa',
    INSURANCE_ASSETS: 'Béns assegurats',
    SAVE_QUOTATION: 'Deseu cotització',
    'Damages proposal - Summary': 'Proposta de danys-resum',
    PROPOSAL_DATA: 'Dades de la proposta',
    OTHER_POLICY_DATA: 'Altres dades de la pòlissa',
    SYSTEM_MESSAGE: 'MISSATGE DEL SISTEMA',
    AN_ERROR_OCCURRED: 'S’ha produït un error, poseu-vos en contacte amb el servei d’atenció al client',
    ERROR_DURING_SUBJECT_UPDATE: 'Error en actualitzar el subjecte',
    QUESTIONNAIRE_NOT_COMPLETE: 'Atenció, el qüestionari no està complet. Empleneu totes les respostes per continuar',
    THE_CONTRACTOR_CONFIRMS_THE_VALIDITY: 'El contractant confirma la validesa de les respostes aportades',
    PACKAGE: 'Conjunts',
    POLICY_EXPIRE_DATE: 'Data de venciment de la pòlissa',
    NORMALIZE: 'Normalitzeu',
    ASSETS_DATA: 'Dades de bé assegurat',
    REPRINT_POLICY_DOCUMENTS: 'Imprimiu de nou documents de la pòlissa- núm. de pòlissa',
    OUTCOME: 'Resultat',
    AUTHORIZATION_REQUEST: 'Petició d’autorització',
    ADDRESS: 'Adreces',
    MAIN: 'Principal',
    DESCRIPTION: 'Descripció',
    ADD_ADDRESS: 'Afegiu adreça',
    POLICYHOLDER_COMMUNICATION_ADDRESSES: 'Adreça de correu del prenedor',
    POSTAL_MAIL: 'Correu postal',
    CHANGE_ADDRESS: 'Modifiqueu l’adreça',
    EMAIL: 'Correu electrònic',
    CUSTOMER_RESERVED_AREA: 'Àrea reservada al client',
    POLICYHOLDER_EMAIL: 'Correu electrònic del prenedor',
    POLICYHOLDER_RESIDENCE_ADDRESS: 'Adreça de residència del prenedor',
    POLICYHOLDER_DELIVERY_ADDRESS: 'Adreça de lliurament del prenedor',
    OTHER_ADDRESS: 'Altres adreces',
    SUBSCRIPTION_INSTALMENT_TAXABLE: 'Quota de subscripció subjecta a impost',
    SUBSCRIPTION_INSTALMENT_GROSS: 'Quota bruta de subscripció',
    NEXT_INSTALMENT_TAXABLE: 'Termini següent subjecte a impost',
    ANNUAL_PREMIUM_TAXABLE: 'Prima anual subjecta a impost',
    ANNUAL_PREMIUM_GROSS: 'Prima anual bruta:',
    NEW: 'Nou',
    POLICY_CONTACT_ERROR: 'Introduïu almenys un contacte de prenedor',
    CLAUSE_DETAIL: 'Informació de la clàusula',
    SAVE: 'Deseu',
    PRODUCT_CODE: 'Codi de producte',
    PRODUCT_DESCRIPTION: 'Descripció de producte',
    POLICY_EFFECT_DATE: 'Data d’entrada en vigor de la pòlissa',
    EMPTY: 'Buit',
    DEBIT_MEAN_OF_PAYMENT: 'Mitjans de pagament a dèbit',
    CREDIT_MEAN_OF_PAYMENT: 'Mitjans de pagament a crèdit',
    ALTERNATIVE_POLICY_ADRESS: 'Introduïu una adreça de la pòlissa alternativa',
    YES: 'SÍ',
    MANDATORY_TEXT: 'El text de la clàusula és obligatori',
    MANDATORY: 'obligatori',
    SELECT_PAYMENT_METHOD: 'Seleccioneu mètode de pagament',
    ERROR_ON_DATE: 'No és possible continuar atès que la data d’entrada en vigor ha de ser anterior a la data del venciment',
    SELECT_CONTRACTOR: 'Seleccioneu un contractant',
    BENEFICIARIES: 'Beneficiaris',
    BENEFICIARY_TYPE: 'Tipus de beneficiari',
    BENEFICIARY: 'Beneficiari',
    PERCENTAGE: 'Percentatge',
    THIRD_PARTY_SUBJECT: 'Subjecte tercer',
    WARNING_BENEFICIARY_SUBJECT: 'El subjecte seleccionat ja ha estat enregistrat com a beneficiari, seleccioneu un altre subjecte',
    WARNING_BENEFICIARY_THIRD_PERSON: 'El subjecte seleccionat ja ha estat enregistrat com a beneficiari, seleccioneu un altre subjecte',
    WARNING_BENEFICIARY_LIMIT: 'S’ha arribat al nombre màxim de beneficiaris',
    WARNING_THIRDPERSON_SUBJECT: 'El subjecte seleccionat ja ha estat enregistrat com a subjecte tercer, seleccioneu un altre subjecte',
    USUAL_OCCASIONAL_RESIDENCE: 'RESIDÈNCIA HABITUAL/OCASIONAL',
    ANAG_BENEF: 'Registre beneficiaris',
    SPOUSE_INSURED: 'Cònjuge de l’assegurat',
    ERROR_FORMAT_DATE: 'Error format data',
    DATE1_AFTER_DATE2: 'La data de venciment és inferior a la data d’entrada en vigor',
    SELECT_NODE: 'Seleccioneu node',
    DATA_CANCELLATION_POLICY: 'Data de cancel·lació de la pòlissa',
    CANCELLATION_REASON: 'Motiu de la cancel·lació',
    CANCELLATION_PREMIUM_REPAYMENT: 'La cancel·lació preveu el reembossament de la prima',
    RIGHTS: 'Drets',
    DATA_CANCELLATION: 'Data de cancel·lació',
    CONFIRMATION_CANCELLATION: 'Confirmació de la cancel·lació',
    CANCELLATION_SUMMARY: 'Resum de la cancel·lació',
    END: 'Fi',
    DOCUMENTS: 'Documents',
    POLICY: 'Pòlissa',
    CANCELED_SUCCESSFULLY: 'cancel·lat correctament',
    CONFIRM_CANCELLATION: 'Voleu confirmar la cancel·lació de la pòlissa',
    CURRENCY: 'Moneda',
    POSTED_NOTICE_DATE: 'Data d’avís de l’anul·lació',
    ISSUED_CORRECTLY: 'S’ha emès correctament',
    PREMIUM: 'Prima',
    FEES: 'Taxes',
    SELECT_ASSET: 'SELECCIONEU EL BÉ ASSEGURAT',
    ACTIVITY: 'Activitats',
    SECONDARY_ACTIVITY: 'Activitat secundària',
    ACTIVITY_NOT_FOUND: 'No s’ha trobat l’activitat',
    SEARCH_ACTIVITY_BY: 'Cerqueu activitat per',
    VALUE: 'Valor',
    SEARCH: 'Cerqueu',
    NO_VALUE: 'No hi ha valor',
    PROPOSAL_REGULATION: 'Regulació de la proposta',
    REGULATION_EXPIRATION_DATE: 'Data de venciment de la regulació',
    REGULATION_FRACTIONING: 'Fraccionament de la regulació',
    REGULATION_TYPE: 'Tipus de regulació',
    REGULATION_DAYS: 'Dies de regulació',
    PERCENTAGE_OF_MIN_QUOTE_PREMIUM: '% de prima de cotització mínima / final',
    LPS: 'LPS',
    EU_COUNTRY: 'País de la UE',
    EXTRA_EU_COUNTRY: 'País de fora de la UE',
    CHANGE_DATE: 'Data de canvi',
    EXCHANGE_RATE: 'Tipus de canvi',
    USE_TAX: 'Impost d’ús',
    CO_INSURANCE: 'Coassegurança',
    CO_INSURANCE_DEP: 'Dep. de coassegurança',
    OUR_PERCENTAGE: 'El nostre percentatge',
    QUOTA_PERCENTAGE: 'Percentatge de quota',
    COMMISSION_PERCENTAGE: 'Percentatge de comissió',
    PERCENTAGE_TOTAL: 'Percentatge Total',
    PERCENTAGE_ERROR: 'Percentatge invàlid',
    NO_QUOTAS_ERROR: 'No s’han ingressat les quotes de la coassegurança',
    LEAD_COINS_ERROR: 'El percentatge total del coassegurador principal ha de ser 100',
    POLICY_QUOTAS: 'Quotes de Pòlissa',
    NEW_QUOTA: 'Nova quota de coassegurança',
    THIRD_PARTY_QUOTA: 'Dades de Pòlissa de l’assegurador principal',
    QUOTA: 'Quota',
    INTERMEDIARY: 'Intermediari',
    LEADING_INSURER: 'Assegurador principal',
    RECALCULATE_UPDATE: 'CALCULEU DE NOU I ACTUALITZEU',
    RECALCULATE_DEFAULT_LPS_TAXES: 'CALCULAR DE NOU ELS IMPOSTOS LPS PER DEFECTE',
    TAX_ANTI_MONEY_LAUNDERING: 'Impost antiblanqueig de capitals',
    COMMON_RISK_POLICIES: 'Pòlisses de Risc Comú',
    COMPLEMENTARY_POLICIES: 'Pòlisses complementàries',
    COMPLEMENTARY_POLICY_DATA: 'Dades de la pòlissa complementària',
    SECOND_RISK_POLICIES: 'Pòlisses de segon risc',
    LINKED_POLICIES: 'Pòlisses vinculades',
    NOTES: 'Notes',
    INDIRECT_CO_INSURANCE_POLICIES_LIST: 'Llista de pòlisses de coassegurança indirecta',
    INDIRECT_CO_INSURANCE_POLICIES: 'Pòlisses de coassegurança indirecta',
    SECOND_RISK_POLICIES_LIST: 'Llista de Pòlisses de segon risc',
    ANNUAL_GROSS_PREM_BEFORE_CHARGES: 'Prima anual (excloent els drets)',
    FLEX_DISCOUNT_WITH_EROSION: 'Flexibilitat amb l’erosió de descomptes',
    FLEX_DISCOUNT_WITHOUT_EROSION: 'Flexibilitat sense l’erosió de descomptes',
    FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR: 'Import amb l’erosió de descomptes no vàlid',
    FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR: 'Import sense l’erosió de descomptes no vàlid',
    FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR: 'Percentatge amb l’erosió de descomptes no vàlid',
    FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR: 'Percentatge sense l’erosió de descomptes no vàlid',
    FLEX_DISCOUNT_RANGE_ALLOWED_VALUE: 'Rang de valors permesos',
    FLEX_DISCOUNT_MAX_100: 'Introduïu un valor més petit que',
    DISCOUNT: 'Descompte',
    RESIDUAL_DISCOUNT: 'Descompte restant',
    SHARED_DISCOUNTS_AMOUNT: 'Compartir descomptes',
    DISCOUNT_TYPE: 'Tipus de descomptes',
    PERIOD: 'Període',
    RESIDUAL_AMOUNT: 'Import restant',
    NO: 'No',
    START: 'Inici',
    LIMIT: 'Límit',
    ADD_POLICY: 'Afegir pòlissa',
    INPUT_PREMIUM: 'Prima d’entrada',
  QUOT_COMMISSIONS: {
    LABEL: {
      ACCESSORIES: 'Accessoris',
        AUTHORITY_LIMIT: 'Límit d’autoritat',
        ALL_RISK: 'Tot risc',
        ACQUIRED_CHARGES: 'Càrrecs adquirits',
        ACQUIRED_COMMISSION: 'Comissió adquirida',
        AMOUNT: 'Import',
        ANNUAL_PREMIUM: 'Prima anual',
        ANTIRACKET: 'Impost antiblanqueig de capitals',
        COLLECTED_CHARGES: 'Càrrecs cobrats',
        COLLECTED_COMMISSION_NET: 'Comissió cobrada (Neta)',
        COMMISSIONS: 'Comissions',
        CONTRIBUTIONS_TAXES: 'Contribucions i imposts',
        GROSS: 'Brut',
        INCEPTION_INSTALLMENT: 'Quota d’inici',
        INSTALLMENT_INTEREST: 'Interès de quota',
        INSTALLMENT_PREMIUM: 'Prima de quota',
        INSURANCE_TAX: 'Impost sobre l’assegurança',
        NET: 'Net',
        PREMIUM_DETAIL: 'Detalls de prima',
        RATE: 'Taxa',
        SIGNED_COMMISSIONS: 'Comissions a la data d’inici',
        DEROGATED: 'Derogat',
        NEXT_INSTALMENT_COMMISSIONS: 'Pròxima comissió de quota',
        RIGHTS: 'Drets',
        SSN: 'SSN',
        TAXABLE: 'Gravable',
        TAXES: 'Imposts',
        TOTAL: 'Total',
        VIEW: 'Veieu',
        ASSIGN: 'Assignar',
        ACQUIRED_COMMISSIONS_MANDATE: 'Mandat de comissions adquirides'
    }
  },
  FPS_LABEL: {
    NEXT_INSTALMENT: 'Pròxima quota',
    INCEPTION_INSTALLMENT: 'Quota d’inici',
  },
  INDEXATION: 'Indexació',
    QUOTA_ALREADY_EXISTING: 'La quota ja existeix',
    CO_POLICYHOLDER_SUBSCRIBER_ERROR: 'El cotitular ha de ser una part diferent del titular de la pòlissa',
    CO_POLICYHOLDER_JOINT_SUBSCRIBER_ERROR: 'El cotitular ha de ser una part diferent dels altres cotitulars',
    COMMON_RISK_POLICIES_LIST: ' Llista de Pòlisses de Risc Comú',
    COMMON_POLICY_DATA: 'Dades comuns de la pòlissa',
    LINKED_POLICIES_LIST: 'Llista de pòlisses vinculades',
    VALID_POLICY: 'Pòlissa vàlida',
    INVALID_POLICY: 'Pòlissa invàlida',
    INVALID_POLICY_NUMBER: 'Format no vàlid número de pòlissa',
    POLICY_ALREADY_ADDED : 'La pòlissa ja ha estat agregada',
    NO_POLICY_ADDED : 'No s’ha agregat cap pòlissa',
    AMOUNT_NOT_VALID: 'Import no vàlid',
    GENERIC_SYSTEM_ERROR: 'Error intern del servidor',
    SAVE_AND_CLOSE: 'Deseu i tanqueu'
  }
};

import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LegalFeeEntity } from '../domain/legal-fee-entity';
import { TaxregimeEntity } from '../domain/taxregime-entity';
import { SubjectEntity } from '../domain/subject-entity';
import { EnumType } from '@rgi/digital-claims-common-angular';
import { ExposureEntity } from '../domain/exposure-entity';
import { TaxRegimeListEntity } from '../domain/tax-regime-list-entity';

@Component({
  selector: 'fiscal-data',
  templateUrl: './fiscal-data.component.html',
  styleUrls: ['./fiscal-data.component.scss']
})
export class FiscalDataComponent implements OnInit {
  private static PROPERTY_SXTAXD: string = 'SXTAXD';

  protected expanded: boolean = false;
  private enumService: any;
  protected newFiscalForm: UntypedFormGroup;
  protected taxRegimeList: TaxregimeEntity[];
  protected ivaRegimeList: TaxregimeEntity[];

  @Input() protected taxRegimeListEntity: TaxRegimeListEntity;
  @Input() protected feeData: LegalFeeEntity;
  @Input() protected consult: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {

    this.newFiscalForm = this.formBuilder.group({
      legalCode: new UntypedFormControl({ value: null, disabled: true }),
      nation: new UntypedFormControl({ value: null, disabled: true }),
      aggregateTaxRegime: new UntypedFormControl({ value: null, disabled: true }),
      taxRegime: new UntypedFormControl({ value: null }),
      aggregateIvaRegime: new UntypedFormControl({ value: null, disabled: true }),
      ivaRegime: new UntypedFormControl({ value: null })
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.isAccordionToExpande();
    this.initRegimes();
  }

  protected initRegimes() {
    this.taxRegimeList = this.taxRegimeListEntity.taxRegime;
    if (this.taxRegimeList && this.taxRegimeList.length === 1) {
      this.newFiscalForm.controls.taxRegime.patchValue(this.taxRegimeList[0]);
      this.newFiscalForm.controls.taxRegime.disable();
    }

    this.ivaRegimeList = this.taxRegimeListEntity.taxIvaRegime;
    if (this.ivaRegimeList && this.ivaRegimeList.length === 1) {
      this.newFiscalForm.controls.ivaRegime.patchValue(this.ivaRegimeList[0]);
      this.newFiscalForm.controls.ivaRegime.disable();
    }
  }

  onChangeRegime() {
    if (this.newFiscalForm.controls.taxIvaRegime.value) {
      this.feeData.taxIvaRegime = this.newFiscalForm.controls.taxIvaRegime.value;
    }

    if (this.newFiscalForm.controls.taxRegime.value) {
      this.feeData.taxRegime = this.newFiscalForm.controls.taxregime.value;
    }
  }

  protected isAccordionToExpande() {
    this.expanded = !(this.newFiscalForm.controls.taxRegime.value && this.newFiscalForm.controls.ivaRegime.value);
  }

  protected initForm() {
    this.newFiscalForm.controls.legalCode.patchValue(this.feeData.legalCode || null);
    this.newFiscalForm.controls.nation.patchValue(this.feeData.country);
    this.newFiscalForm.controls.aggregateTaxRegime.patchValue(this.feeData.taxRegimeAggregate ? this.feeData.taxRegimeAggregate : '-');
    this.newFiscalForm.controls.taxRegime.patchValue(this.feeData.taxRegime ? this.feeData.taxRegime.description : null);
    this.newFiscalForm.controls.aggregateIvaRegime.patchValue(this.feeData.taxIvaRegimeAggregate ? this.feeData.taxIvaRegimeAggregate : '-');
    this.newFiscalForm.controls.ivaRegime.patchValue(this.feeData.taxIvaRegime ? this.feeData.taxIvaRegime.description : null);
  }
}

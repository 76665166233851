import {Injectable} from '@angular/core';
import {Asset} from '../../models/domain-models/asset';

@Injectable({
  providedIn: 'root'
})
export class ReIssueAssetUtilityService {

  constructor() {
  }

  getPersonAsset(assets: Array<Asset>): Asset | null {
    let result = null;
    assets.forEach(val => {
      if (val.code === 'PSFIS') {
        result = val;
      }
    });
    return result;
  }
}

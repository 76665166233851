import { PortalCard, PortalCardConfig, PortalCards } from "@rgi/rx/portal";
import { ROUTES_FORFAIT_TABLE, ROUTES_PAYMENTS_VALIDATION_CONSOLIDATION_TABLE } from "../constants/routes.constants";

export const PAYMENTS_SEARCHBOARD: PortalCardConfig = {
  card: {
    title: "_CLAIMS_TITLE_TIREA_DASHBOARD_",
    name: "paymentsdashboard.search",
    category: "Claims",
    type: "main",
    functions: ['claims.tireaPayment'],
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "home",
      destination: "paymentsdashboard.search",
      label: "Search Tirea",
      type: "home",
    },
  ],
};

export const PAYMENTS_VALIDATION_CONSOLIDATION_TABLE_CARD: PortalCardConfig = {
  card: {
    title: "_CLAIMS_TITLE_TIREA_PAYMENT_VALIDATION_CONSOLIDATION_TABLE_CARD_",
    name: ROUTES_PAYMENTS_VALIDATION_CONSOLIDATION_TABLE,
    category: "Claims",
    type: "sub",
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "paymentsvalidationconsolidation",
      destination: ROUTES_PAYMENTS_VALIDATION_CONSOLIDATION_TABLE,
      label: "_CLAIMS_TITLE_PAYMENT_TABLE_TYREA",
    },
  ],
};

export const FORFAIT_TABLE_CARD: PortalCardConfig = {
  card: {
    title: "_CLAIMS_TITLE_TIREA_FORFAIT_TABLE_CARD_",
    name: ROUTES_FORFAIT_TABLE,
    category: "Claims",
    type: "sub",
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "forfait",
      destination: ROUTES_FORFAIT_TABLE,
      label: "_CLAIMS_TITLE_PAYMENT_TABLE_TYREA",
    },
  ],
};

export const PAYMENTS_DASHBOARD_CARD: PortalCards = [
  PAYMENTS_SEARCHBOARD,
  PAYMENTS_VALIDATION_CONSOLIDATION_TABLE_CARD,
  FORFAIT_TABLE_CARD
];
<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
    <span>{{title}}</span>
  </rgi-rx-panel-header>
  <div class="modal-body large-modal">
    <form [formGroup]="beneficiaryForm">
    <rgi-rx-form-field>
        <label rgiRxLabel><span translate>RE_ISSUE.BENEFICIARY_TYPE</span>
        </label>
        <select rgiRxNativeSelect formControlName="beneficiaryType">
          <option value=""></option>
          <option *ngFor="let beneficiary of unitBeneficiariesConf.beneficiary; let h = index"
                  [value]="beneficiary.code">{{beneficiary.description}}</option>
        </select>
      </rgi-rx-form-field>
      <form formArrayName="beneficiaryParties" class="rgi-ui-grid-1" *ngIf="isAnagBeneficiary">
        <div class="rgi-ui-col beneficiary"
             *ngFor="let party of getBeneficiaryParties().controls; let j = index">
          <form [formGroupName]="j">
            <div class="containerBeneficiary" attr.data-qa="beneficiaryParty_{{j}}">
              <rgi-rx-drop-container style="width: 100%;" class="beneficiary"
                                     label="{{'RE_ISSUE.BENEFICIARY' | translate}}"
                                     formControlName="beneficiaryParty" viewField="nominative"
                                     data-qa="beneficiary" [select]="'searchOrCreateSubject'"
                                     (onValueChange)="selectedBeneficiarySubject($event, j)"
                                     (onRemove)="removeBeneficiarySubject(j)">
              </rgi-rx-drop-container>
              <div style="width: 100%;" class="beneficiary" *ngIf="party.value.percentageVisibility">
                <rgi-rx-form-field>
                  <input rgiRxInput formControlName="percentageBeneficiary" data-qa="beneficiaryPerc"
                         maxlength="3" placeholder="{{'RE_ISSUE.PERCENTAGE' | translate}}" (change)="onPercentageBeneficiaryChange()">
                  <label rgiRxLabel>&nbsp;</label>
                </rgi-rx-form-field>
              </div>
            </div>
          </form>
        </div>
      </form>
    </form>
  </div>
  <div class="rgi-ui-grid">
    <div class="rgi-ui-col">
      <rgi-rx-snackbar tag="quotasError"></rgi-rx-snackbar>
      <rgi-rx-snackbar tag="minBeneficiaryError"></rgi-rx-snackbar>
      <rgi-rx-snackbar tag="duplicateBeneficiaryInfo"></rgi-rx-snackbar>
      <rgi-rx-snackbar tag="thirdPersonInfo"></rgi-rx-snackbar>
    </div>
  </div>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button data-qa="close" class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" translate>RE_ISSUE.CLOSE</button>
    <button data-qa="new" [disabled]="isError || isErrorQuotas || beneficiaryForm.invalid" class="rgi-ui-btn rgi-ui-btn-primary text-uppercase" (click)="putBeneficiaries()" translate>RE_ISSUE.CONFIRM</button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>


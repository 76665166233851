import {GenericEntity} from './generic-entity';
import {Unit} from './unit';

export class Package extends GenericEntity {
  public id: string;
  public code: string;
  public description: string;
  public selection: boolean;
  public unitList: Array<Unit>;
}

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { InvoiceSearchComponent } from './invoice-search/invoice-search.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceService } from './invoice.service';
import { RoutingService, RoutingHostDirective } from '@rgi/portal-ng-core';
import { Invoice } from './dto/invoice';

@Component({
  selector: 'claims-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [RoutingService]
})

// export class InvoiceComponent extends CardComponent implements AfterViewInit, OnInit {
export class InvoiceComponent extends CardComponent implements AfterViewInit {
    @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;
  routes: { search: any; list: any; };
  invoices: Invoice[] = [];

  constructor(
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('invoiceSearchComponent') invoiceSearchComponent,
    @Inject('invoiceListComponent') invoiceListComponent,
    private invoiceService: InvoiceService


  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      search: invoiceSearchComponent,
      list: invoiceListComponent
    };
  }

  // ngOnInit(): void {
  //   super.ngOnInit();
  //   this.routingService.routes = this.routes;
  //   this.routingService.host = this.hostViewRef.viewContainerRef;
  //   this.routingService.loadComponent(this.routes.search);
  //   (this.routingService.componentRef.instance as InvoiceSearchComponent).card = this.card;
  //   (this.routingService.componentRef.instance as InvoiceListComponent).card = this.card;
  // }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;

    this.invoices = this.invoiceService.getSessionInvoices(this.card.id);

    if (this.invoices) {
      this.routingService.loadComponent(this.routes.list);
    } else {
      this.routingService.loadComponent(this.routes.search);
    }

    (this.routingService.componentRef.instance as InvoiceSearchComponent).card = this.card;
    (this.routingService.componentRef.instance as InvoiceListComponent).card = this.card;
  }

}

import {QuestionnaireLevel} from './questionnaire-level.enum';
import {QuestionnaireType} from './questionnaire-type.enum';

export const QUESTIONNAIRE_LEVEL_TO_QUEST_TYPE: Map<QuestionnaireLevel, QuestionnaireType> =
  new Map<QuestionnaireLevel, QuestionnaireType>()
    .set(QuestionnaireLevel.FINANCIAL, QuestionnaireType.FINANCIAL)
    .set(QuestionnaireLevel.DUE_DILIGENCE, QuestionnaireType.DUE_DILIGENCE)
    .set(QuestionnaireLevel.SANITARY, QuestionnaireType.SANITARY)
    .set(QuestionnaireLevel.INSURED_SETTLEMENT, QuestionnaireType.INSURED_SETTLEMENT); // TODO by rot fix Questionnaire level;

export const PASS_QUESTIONNAIRE_TYPE_CODE = 'QPPEVO';

import {Injectable} from '@angular/core';
import {Unit} from '../../models/domain-models/unit';
import {UnitSection} from '../../models/domain-models/unit-section';
import {ExtendedUnit} from '../../models/domain-models/extended-unit';

@Injectable({
  providedIn: 'root'
})
export class UnitUtilityService {

  constructor() {
  }

  toExtendedUnit(unit: Unit, unitSection: UnitSection): ExtendedUnit {
    const extendedUnit = new ExtendedUnit();
    extendedUnit.code = unit.code;
    extendedUnit.description = unit.description;
    extendedUnit.enabled = unit.enabled;
    extendedUnit.extendedDescription = unit.extendedDescription;
    extendedUnit.extendedDescription = unit.extendedDescription;
    extendedUnit.id = unit.id;
    extendedUnit.instalment = unit.instalment;
    extendedUnit.open = unit.open;
    extendedUnit.risk = unit.risk;
    extendedUnit.selected = unit.selected;
    extendedUnit.subscriptionInstalment = unit.subscriptionInstalment;
    extendedUnit.unitSection = unitSection;
    extendedUnit.variables = unit.factors;
    extendedUnit.warrantyCode = unit.warrantyCode;
    extendedUnit.warrantyId = unit.warrantyId;
    return extendedUnit;
  }
}

import {GenericEntity} from '../../models/domain-models/generic-entity';

export class Notification {
  constructor(
    public id: string,
    public block: number,
    public errorDescription: string,
    public type: GenericEntity,
    public originEntity: string,
    public description: string
  ) {
  }
}

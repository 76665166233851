import {Component, Input, OnInit} from '@angular/core';
import {
  RGI_QUOTATION_MENU_ITEM_CODES,
  RgiQuotationMenu
} from '../../../resources/model/common/quotation/quotation-header-data';
import {PncQuotationMenuService} from '../../../services/pnc-quotation-menu.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rgi-pnc-quotation-menu',
  templateUrl: './pnc-quotation-menu.component.html'
})
export class PncQuotationMenuComponent implements OnInit {

  @Input() menuData: RgiQuotationMenu;

  constructor(
    protected menuService: PncQuotationMenuService
  ) {
  }

  ngOnInit() {
  }

  resolveAction(actionCode: RGI_QUOTATION_MENU_ITEM_CODES, ctx: any) {
    this.menuService.openModal(actionCode, ctx);
  }
}

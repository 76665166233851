import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceComponent } from './invoice.component';
import {
  CardService, DigitalClaimsCommonAngularModule, enumServiceProvider, getSystemPropertyProvider
 } from '@rgi/digital-claims-common-angular';
import { InvoiceSearchComponent } from './invoice-search/invoice-search.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceUpdateComponent } from './invoice-update/invoice-update.component';
import { InvoiceClaimsListComponent } from './invoice-claims-list/invoice-claims-list.component';
import { InvoiceSmallListComponent } from './invoice-small-list/invoice-small-list.component';
import { InvoiceAssignmentsListComponent } from './invoice-assignments-list/invoice-assignments-list.component';
import { InvoiceExpertUpdateComponent } from './invoice-expert-update/invoice-expert-update.component';
import { InvoiceTypeoflossListComponent } from './invoice-typeofloss-list/invoice-typeofloss-list.component';
import { InvoiceUploadComponent } from './invoice-upload/invoice-upload.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
import { RgiRxI18nModule } from '@rgi/rx/i18n';



@NgModule({
    declarations: [
        InvoiceComponent,
        InvoiceSearchComponent,
        InvoiceListComponent,
        InvoiceUpdateComponent,
        InvoiceClaimsListComponent,
        InvoiceSmallListComponent,
        InvoiceAssignmentsListComponent,
        InvoiceExpertUpdateComponent,
        InvoiceTypeoflossListComponent,
        InvoiceUploadComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        NgSelectModule,
        DigitalClaimsCommonAngularModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        [NgbModule],
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        InvoiceComponent
    ],
    providers: [
        { provide: 'invoiceSearchComponent', useValue: InvoiceSearchComponent },
        { provide: 'invoiceListComponent', useValue: InvoiceListComponent },
        enumServiceProvider,
        getSystemPropertyProvider
    ]
})
export class InvoiceModule {
  constructor(
    cardService: CardService,
    ) {

    // card invoice - start
    const title = '_INVOICE'; 
    const moduleName = 'invoice';

    cardService.registerCard(
      moduleName,
      title,
      InvoiceComponent,
    );
    // card invoice - end

    // subcard invoice-small-list - start
    const subCardTitle = '_INVOICES_LIST';
    const subCardModuleName = 'invoiceSmallList';
    const subCardParentCardName = 'claimsFinder';

    const routes = [{
      path: 'action', route: {
        template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
        controller: 'CardPassCtrl',
        label: 'Action'
      }
    }];

    const communication = {
      reload: 'reload'
    };

    cardService.registerSubCard(
      subCardModuleName,
      subCardTitle,
      InvoiceSmallListComponent,
      subCardParentCardName,
      null,
      'ClaimsCommunicationCtrl',
      communication,
      routes,
      false,
      true
    );
    // subcard invoice-small-list - end

  }
}

import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { questionnaireCacheServiceInjectionToken } from '@rgi/questionnaires-manager';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { OperationPropertyCode } from '../../models/operation-property-code.enum';
import {
  BeneficiaryDefinition, BeneficiaryRole, Definition,
  PaymentTypeDefinition, PostsalesError, PostsalesOperationObject
} from '../../models/postsales-operations-response.model';
import { Beneficiary } from '../../modules/lpc-beneficiary/model/beneficiary';
import { LpcRolesUtils } from '../../modules/lpc-roles-step/lpc-roles-utils';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcLayeredRuleService } from '../../services/lpc-layered-rule.service';


@Component({
  selector: 'lpc-change-beneficiaries',
  templateUrl: './change-beneficiaries.component.html',
  styleUrls: ['./change-beneficiaries.component.css'],
  providers: [
    // TODO: check if there is a better way
    // @ts-ignore
    questionnaireCacheServiceInjectionToken
  ]
})
export class ChangeBeneficiariesComponent extends AbsOperationComponent {

  protected operationDataKey = 'beneficiaries';
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  public beneficiaryData: Beneficiary[] = [];
  public beneficiaryRoles: BeneficiaryRole[] = [];
  public selectedBeneficiariesForSummary: any[];
  public thirdPartyDefinition: Definition;
  public thirdPartyData: Beneficiary;
  public feErrorsBenef: PostsalesError[] = [];

  private _paymentTypes: PaymentTypeDefinition[] = [];
  public benefPositionNumberList: Definition[] = [];

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this._paymentTypes;
  }

  public get isREG41Enabled(): boolean {
    if (this.hasOperationPropertyByCode(OperationPropertyCode.REGOLAMENTO_41)) {
      return  PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(OperationPropertyCode.REGOLAMENTO_41).booleanValue
      );
    }
    return false;
  }

  public get isBENDGEnabled(): boolean {
    if (this.hasOperationPropertyByCode(OperationPropertyCode.BENEF_DISABILE)) {
      return  PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(OperationPropertyCode.BENEF_DISABILE).booleanValue
      );
    }
    return false;
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operation: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authorizationService: AuthService,
    protected anag: AnagService,
    protected layeredRuleService: LpcLayeredRuleService
  ) {
    super(operation, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authorizationService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(this.createDraft().subscribe(result => {
      this.createDraftHandleResponse(result);
    }
    ));
    this.formGroup.valueChanges.subscribe(el => {
      this.feErrorsBenef = [];
      // this.$errors = [];
      this.feErrorsBenef = this.getFeErrors();
    });
  }

  public createDraftHandleResponse(result) {
    this.beneficiaryData = result.data.operationData.data as Beneficiary[];
    this.sortingByPositionNumber();
    this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]); // .reverse();
    this.beneficiaryRoles = result.definitions.BenefiacyRoles  as BeneficiaryRole[];
    this.thirdPartyDefinition = result.definitions.thirdParty as Definition;
    this.benefPositionNumberList = result.definitions.BenefPositionNumberList as Definition[];
    if (!!result.definitions.paymentTypes) {
      this._paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
    }
    // CREAZIONE FORMGROUP
    this.beneficiariesDefinitions.forEach(el => {
      (this.formGroup.get('beneficiaries') as UntypedFormGroup).addControl(
        'b' + el.code,
        new UntypedFormControl({
          code: null,
          subjects: [],
          irrevocable: false
        })
      );
    });
    if (!!this.thirdPartyDefinition && this.thirdPartyDefinition.visible) {
      this.formGroup.addControl(
        'thirdParty',
        new UntypedFormControl({
            value: {
              id: null,
              role: null,
              name: null,
              percentage: null,
              personType: null,
              adult: null,
              linkedSubjectsRoles: []
            },
            type: null,
            code: null,
            idAssicurato: null,
            irrevocable: false
        })
      );
    }

    // VALORIZZAZIONE FORM
    this.beneficiariesDefinitions.forEach(definition => {
      const ben: Beneficiary = this.beneficiaryData.find(el => el.type === definition.code);
      this.formGroup.get('beneficiaries').get('b' + definition.code).setValue({
        code: !!ben ? ben.code : null,
        subjects: this.beneficiaryData.filter(el => el.type === definition.code),
        irrevocable: !!ben ? ben.irrevocable : false
      });
    });

    this.thirdPartyData = this.beneficiaryData.find(b => b.type === '300015');
    if (!!this.thirdPartyDefinition && !!this.thirdPartyData) {
      this.formGroup.get('thirdParty').setValue({
          code: this.thirdPartyData.code,
          value: this.thirdPartyData.value,
          type: this.thirdPartyData.type,
          idAssicurato: this.thirdPartyData.idAssicurato,
          irrevocable: this.thirdPartyData.irrevocable
      }, {emitEvent: false});
      console.log(this.formGroup.get('thirdParty').value);
    }
  }

  private sortingByPositionNumber() {
    this.beneficiaryData.sort((a, b) => {
      if (!!a.type && !!b.type) {
        if (a.type > b.type) {
          return 1;
        } else if (a.type < b.type) {
          return -1;
        } else {
          return 0;
        }
      }
     }).sort((a, b) => {
      if (!!a.positionNumberCode && !!b.positionNumberCode) {
        if (a.positionNumberCode > b.positionNumberCode) {
          return 1;
        } else if (a.positionNumberCode < b.positionNumberCode) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  public getFeErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getBeneficiariesErrors(this.formGroup, this.translate);
  }

  public getTransformedOperationData() {
    const subjects = PlcObjectUtils.getBeneficiariesFromForm(this.beneficiariesDefinitions, this.formGroup);
    if (!!this.thirdPartyDefinition && this.thirdPartyDefinition.visible && !!this.formGroupValue.thirdParty &&
      !!this.formGroupValue.thirdParty.value && !!this.formGroupValue.thirdParty.value.id) {
      subjects.push(this.formGroupValue.thirdParty);
    }
    return subjects;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      operationRoles: new UntypedFormControl(),
      beneficiaries: new UntypedFormGroup({}),
      thirdParty : new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

  public get formGroupValue(): { [key: string]: any } {
    return this.formGroup.getRawValue();
  }

  public updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);

    if (this.isActiveStep('beneficiaries')) {
      this.handleBeneficiariesSummary();
    }
  }

  /**
   * Gestisce la visualizzazione dei beneficiari dopo che si è passato lo step
   * (ESTENDERE A PROGETTO PER CAMBIARE LA VISUALIZZAZIONE)
   */
  public handleBeneficiariesSummary() {
    this.selectedBeneficiariesForSummary = LpcBeneficiaryUtils.getBeneficiariesForSummary(
      this.beneficiariesDefinitions, this.formGroup, this.thirdPartyDefinition,
      this.translate, this.layeredRuleService, true, this.benefPositionNumberList
    );
  }

  public isQuestionnaireVisible(): boolean {
    return true;
  }

  public enableRoleCheckbox(roleCode: string) {
    return LpcRolesUtils.enableCheckbox(this.operation, LpcRolesUtils.getRoleCode(roleCode));
  }

  disableNext(): boolean {
    return this.formGroup.get('beneficiaries').invalid ||
    (!!this.thirdPartyDefinition && this.thirdPartyDefinition.visible && this.formGroup.get('thirdParty').invalid);
  }

}

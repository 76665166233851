import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {KarmaFundDefinition} from '../../model/karma-fund-definition';
import {Subscription} from 'rxjs';
import {PlcObjectUtils} from '../../../../utils/plc-object-utils';
import {KarmaFundError} from '../../model/karma-fund-error';
import { EMPTY_STR } from '../../../../models/consts/lpc-consts';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';

@Component({
  selector: 'lpc-karma-fund-buttons[totalAmount][definition]',
  templateUrl: './lpc-karma-fund-buttons.component.html',
  styleUrls: ['./lpc-karma-fund-buttons.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaFundButtonsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcKarmaFundButtonsComponent),
      multi: true
    }
  ]
})
export class LpcKarmaFundButtonsComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  public formGroup: UntypedFormGroup = this.getFormGroup();

  @Input() public definition: KarmaFundDefinition[];
  @Input() public totalAmount: number;
  @Input() public areProfiles = true;
  @Input() public sliderProperty = EMPTY_STR;
  @Output() public totalPercent: EventEmitter<number> = new EventEmitter<number>();

  private $funds: KarmaFundDefinition[];

  public get funds(): KarmaFundDefinition[] {
    if (!this.formGroup.get('search').value) {
      return this.$funds;
    } else {
      return this.$funds.filter(fund => fund.description.match(new RegExp(this.formGroup.get('search').value, 'i')));
    }
  }

  private $subscriptions: Subscription[] = [];

  private $defaultValueStructure: any;

  private $totalPercent = 0;

  public get totalAllocatedPercent(): number {
    return PlcObjectUtils.roundToDecimal(this.$totalPercent, 6);
  }

  private $errors: KarmaFundError[];

  public get errors(): KarmaFundError[] {
    return this.$errors;
  }

  public pageSize = 5;
  public pageSizes = [5, 10, 20, 30, this.translate.getImmediate('lpc_Mostra_tutti')];
  public page = 1;
  public totalItems = 5;
  public count = 0;

  constructor(protected translate: TranslationWrapperService) {
  }

  ngOnInit() {
    this.formGroup.get('count').setValue(5);
    this.$subscriptions.push(
      this.formGroup.get('funds').valueChanges.subscribe(result => {
        const obj = {};
        Object.keys(result).forEach(key => {
          obj[key] = result[key] ? result[key] : null;
        });
        this.$totalPercent = this.getTotalPercent();
        this.totalPercent.emit(this.$totalPercent);
        this.onChange(obj);
        this.onTouch();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definition) {
      this.$funds = changes.definition.currentValue;
      if (!!this.funds) {
        this.count = this.funds.length;
      }
      this.$defaultValueStructure = this.getDefaultStructure();
      this.populateFormGroup();
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  writeValue(obj: any): void {
    const convertedObj: { [key: number]: number } = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          convertedObj[+key] = isNaN(parseFloat(obj[key])) ? null : parseFloat(obj[key]);
      }
    }
    if (!!convertedObj) {
      this.formGroup.get('funds').patchValue(
        PlcObjectUtils.merge({}, this.$defaultValueStructure, convertedObj), {emitEvent: false}
      );
    } else {
      this.formGroup.get('funds').setValue(
        PlcObjectUtils.merge({}, this.$defaultValueStructure), {emitEvent: false}
      );
    }
    this.$totalPercent = this.getTotalPercent();
    this.totalPercent.emit(this.$totalPercent);
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const obj: ValidationErrors = {};
    const funds: any = {};
    if (this.totalAllocatedPercent > 1) {
      obj.max = {value: this.totalAllocatedPercent};
    } else if (this.totalAllocatedPercent < 1) {
      obj.min = {value: this.totalAllocatedPercent};
    }
    Object.keys((this.formGroup.get('funds') as UntypedFormGroup).controls).forEach(key => {
      const cont: UntypedFormControl = this.formGroup.get('funds').get(key) as UntypedFormControl;
      if (cont.errors) {
        funds[key] = cont.errors;
      }
    });
    if (!!Object.keys(funds).length) {
      obj.funds = funds;
    }
    const errors = !!Object.keys(obj).length ? obj : null;
    this.$errors = this.getErrorMessages(errors);
    return errors;
  }

  private getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      search: new UntypedFormControl(),
      funds: new UntypedFormGroup({}),
      count: new UntypedFormControl(this.pageSize)
    });
  }

  private populateFormGroup(): void {
    Object.keys((this.formGroup.get('funds') as UntypedFormGroup).controls).forEach(key => {
      (this.formGroup.get('funds') as UntypedFormGroup).removeControl(key);
    });
    this.$funds.forEach(fund => {
      (this.formGroup.get('funds') as UntypedFormGroup).addControl(fund.id.toString(), new UntypedFormControl());
    });
  }

  private getDefaultStructure(): any {
    const obj = {};
    this.$funds.forEach(fund => {
      obj[fund.id] = null;
    });
    return obj;
  }

  private getTotalPercent(): number {
    let total = 0;
    const funds = this.formGroup.getRawValue().funds;
    Object.keys(funds).forEach(key => {
      total += !!funds[key] ? parseFloat(funds[key]) : 0;
    });
    return total;
  }

  private getErrorMessages(errors: ValidationErrors): KarmaFundError[] {
    const array: KarmaFundError[] = [];
    if (errors) {
      if (errors.min || errors.max) {
        const type: 'min' | 'max' = errors.min ? 'min' : 'max';
        const difference: number = 1 - errors[type].value;
        array.push({
          description: 'TOTAL_FUNDS',
          amount: difference * this.totalAmount,
          percent: difference,
          limit: 1,
          type
        });
      }
      if (errors.funds) {
        Object.keys(errors.funds).forEach(key => {
          const fundError: any = errors.funds[key];
          const type: 'min' | 'max' = fundError.min ? 'min' : 'max';
          const difference: number = fundError[type].limit - fundError[type].value;
          array.push({
            description: this.getFundById(key).description,
            amount: difference * this.totalAmount,
            percent: difference,
            limit: fundError[type].limit,
            type
          });
        });
      }
    }
    return array;
  }

  private getFundById(id: string): KarmaFundDefinition {
    return this.$funds.find(fund => fund.id === id);
  }

  handlePageSizeChange(event) {
    this.pageSize = event;
    this.page = 1;
    if (event === 'Mostra tutti') {
      this.pageSize = this.funds.length;
    }
  }

  public handlePageChange(event) {
    this.page = event;
  }
}

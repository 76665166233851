import {EMPTY_STR, StepperConfiguration} from "../../../models/consts/lpc-consts";


export interface UndeductedPremiumsAcquisitionsStepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'roles'`
   * @stepField label `'lpc_variation_step_label'`
   * @stepField fieldId `'roles'`
   * @stepField formName `'roles'`
   */

}

export const UNDEDUCTED_PREMIUMS_ACQUISITION: UndeductedPremiumsAcquisitionsStepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates',
  },
  POLICY_FACTORS: {
    id: 'factors',
    label: 'lpc_PolicyData',
    fieldId: 'factors',
    formName: 'factors'
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR,
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR,
  },
};

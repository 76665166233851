import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxModalModule} from '../modal/rgi-rx-modal.module';
import {RgiRxDropContainerComponent} from './rgi-rx-drop-container/rgi-rx-drop-container.component';
import {RgiRxDropBodyComponent} from './rgi-rx-drop-body/rgi-rx-drop-body.component';
import {RgiRxDragActionDirective} from './rgi-rx-drag-action.directive';
import {RgiRxDragBodyComponent} from './rgi-rx-drag-body/rgi-rx-drag-body.component';
import {RgiRxDropListContainerComponent} from './rgi-rx-drop-list-container/rgi-rx-drop-list-container.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {RGI_RX_DROP_TRANSLATIONS} from './drop.i18n';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RgiRxDragRemoveDirective} from './rgi-rx-drag-remove.directive';
import {RgiRxDragLabelDirective} from './rgi-rx-drag-label.directive';
import {RgiRxDragValueDirective} from './rgi-rx-drag-value.directive';
import {RgiRxDropLabelDirective} from './rgi-rx-drop-label.directive';
import {RgiRxQualityAssuranceModule} from '@rgi/rx';
import {RgiRxTooltipModule} from '../tooltip/rgi-rx-tooltip.module';


@NgModule({
  declarations: [
    RgiRxDropContainerComponent,
    RgiRxDropListContainerComponent,
    RgiRxDropBodyComponent,
    RgiRxDragBodyComponent,
    RgiRxDragActionDirective,
    RgiRxDragRemoveDirective,
    RgiRxDragLabelDirective,
    RgiRxDragValueDirective,
    RgiRxDropLabelDirective
  ],
  imports: [
    CommonModule,
    RgiRxModalModule,
    RgiRxI18nModule.forRoot(RGI_RX_DROP_TRANSLATIONS),
    RgiRxQualityAssuranceModule,
    DragDropModule,
    RgiRxTooltipModule
  ],
  exports: [
    RgiRxDropContainerComponent,
    RgiRxDropListContainerComponent,
    RgiRxDragActionDirective,
    RgiRxDragRemoveDirective,
    RgiRxDragValueDirective,
    RgiRxDragLabelDirective,
    RgiRxDropLabelDirective
  ],
  providers: []
})
export class RgiRxDragDropModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RgiRxPortalModule } from '@rgi/rx/portal';
import { CaiOpeningComponent } from './cai-opening.component';
import { RgiRxRoutingModule } from '@rgi/rx/router';
import {
    ModalService,
  RgiRxDatePickerModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule,
  RgiRxNativeDateTimeModule,
  RgiRxStepperModule,
  RgiRxSwitchModule,
} from '@rgi/rx/ui';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { cardCaiOpening } from './cai-opening.card';
import { CAI_OPENING_ROUTES } from './cai-opening.routes';
import { AlertService, containerCacheServiceProvider } from '@rgi/digital-claims-common-angular';
import { CaiOpeningService } from './state-service/cai-opening.service';
import { DscComponentModule } from '../shared/dsc-component.module';
import { CaiOpeningSingleComponent } from './components/cai-opening-single/cai-opening-single.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
import { CaiOpeningQuestionnaireComponent } from './components/cai-opening-questionnaire/cai-opening-questionnaire.component';
import { ClaimQuestionnaireModule } from '../claim-questionnaire/claim-questionnaire.module';
import { CaiOpeningPartyUtility } from './state-service/utility/cai-opening-party.utility';
import { CaiOpeningStateService } from './state-service/cai-opening.state.manager';
import { CaiOpeningClaimUtility } from './state-service/utility/cai-opening-claim.utility';

@NgModule({
    declarations: [
        CaiOpeningComponent,
        CaiOpeningSingleComponent,
        CaiOpeningQuestionnaireComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        DscComponentModule,
        RgiRxPortalModule.forRoot([cardCaiOpening]),
        RgiRxRoutingModule.forRoot(CAI_OPENING_ROUTES),
        RgiRxStepperModule,
        RgiRxSwitchModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        ClaimQuestionnaireModule,
        RgiRxExpansionModule,
    ],
    exports: [CaiOpeningComponent],
    providers: [
        containerCacheServiceProvider,
        CaiOpeningService,
        CaiOpeningStateService,
        CaiOpeningPartyUtility,
        CaiOpeningClaimUtility
    ]
})
export class CaiOpeningModule {}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, EnumType, Outcome } from '@rgi/digital-claims-common-angular';
import { Invoice } from '../dto/invoice';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-expert-update',
  templateUrl: './invoice-expert-update.component.html',
  styleUrls: ['./invoice-expert-update.component.scss']
})
export class InvoiceExpertUpdateComponent implements OnInit {
  @Input() invoice: Invoice;
  updateExpertForm: UntypedFormGroup;
  errorMessage: string;
  validationMessages: string[];
  ignoto: string = 'IGNOTO';

  allGenders: EnumType[];
  provincia: EnumType[];
  nazione: EnumType[];

  mailString: string;
  pecString: string;

  constructor(
    @Inject('eventService') private eventService: any,
    @Inject('enumService') private enumService: any,
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private invoiceService: InvoiceService,
    private alertService: AlertService,
    public translateService: RgiRxTranslationService,
  ) { }

  ngOnInit() {
    // TODO
    this.allGenders = this.enumService.getEnumList('sinistri.Gender');
    this.provincia = this.enumService.getEnumList('anag.Provincia');
    this.nazione = this.enumService.getEnumList('anag.Nazione');

    this.initValidators();
    this.fieldsAreValid();

  }

  initValidators() {
    // campi obbligatori: taxId, nazione, sesso
    // (nome e cognome) o titolo
    this.updateExpertForm = this.formBuilder.group(
      {
        taxId: [this.invoice.invoiceExpert.taxId, Validators.required],
        nationality: [this.invoice.invoiceExpert.nationality, Validators.required],
        gender: [this.invoice.invoiceExpert.gender !== null ? this.invoice.invoiceExpert.gender.codice : null],
        name: [this.invoice.invoiceExpert.name],
        surname: [this.invoice.invoiceExpert.surname],
        title: [this.invoice.invoiceExpert.title],
        toponym: [this.invoice.invoiceExpert.toponym],
        address: [this.invoice.invoiceExpert.address],
        civicNumber: [this.invoice.invoiceExpert.civicNumber],
        city: [this.invoice.invoiceExpert.city],
        province: [this.invoice.invoiceExpert.province],
        nationAddress: [this.invoice.invoiceExpert.nationAddress],
        phone: [this.invoice.invoiceExpert.phone],
        mobilePhone: [this.invoice.invoiceExpert.mobilePhone],
        fax: [this.invoice.invoiceExpert.fax],
        email: [this.invoice.invoiceExpert.email],
        pec: [this.invoice.invoiceExpert.pec],
      },
      {
        validator: [this.expertNameSurnameOrTitleRequired(
          'name',
          'surname',
          'title',
          'gender'
        ),
        this.mailValidator(
          'email',
          'pec'
        )],
      }
    );
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  confirmExpert() {
    this.validationMessages = [];
    if (this.updateExpertForm.valid) {
      this.invoice.invoiceExpert.taxId =
        this.updateExpertForm.get('taxId').value;
      this.invoice.invoiceExpert.nationality =
        this.updateExpertForm.get('nationality').value;
      this.invoice.invoiceExpert.name =
        this.updateExpertForm.get('name').value;
      this.invoice.invoiceExpert.surname =
        this.updateExpertForm.get('surname').value;
      this.invoice.invoiceExpert.title =
        this.updateExpertForm.get('title').value;
      this.invoice.invoiceExpert.toponym =
        this.updateExpertForm.get('toponym').value;
      this.invoice.invoiceExpert.address =
        this.updateExpertForm.get('address').value;
      this.invoice.invoiceExpert.civicNumber =
        this.updateExpertForm.get('civicNumber').value;
      this.invoice.invoiceExpert.city = this.updateExpertForm.get('city').value;
      this.invoice.invoiceExpert.province =
        this.updateExpertForm.get('province').value;
      this.invoice.invoiceExpert.nationAddress =
        this.updateExpertForm.get('nationAddress').value;
      this.invoice.invoiceExpert.phone =
        this.updateExpertForm.get('phone').value;
      this.invoice.invoiceExpert.mobilePhone =
        this.updateExpertForm.get('mobilePhone').value;
      this.invoice.invoiceExpert.fax = this.updateExpertForm.get('fax').value;
      this.invoice.invoiceExpert.email =
        this.updateExpertForm.get('email').value;
      this.invoice.invoiceExpert.pec = this.updateExpertForm.get('pec').value;

      if (this.updateExpertForm.get('gender').value != null && this.updateExpertForm.get('gender').value.length > 0) {
        this.invoice.invoiceExpert.gender = {
          codice: this.updateExpertForm.get('gender').value,
          descrizione: ''
        };
      } else {
        this.invoice.invoiceExpert.gender = null;
      }

      this.saveExpert(this.invoice);
    } else {
      this.translateService.translate('_CLAIMS_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }
    return null;
  }

  saveExpert(invoice: Invoice) {
    this.invoiceService
      .saveInvoice(invoice, this.invoice.invoiceExpert.rejectType.codice)
      .subscribe(
        (response: Outcome) => {
          if (response.code === Outcome.OK) {
            this.activeModal.close(response);
            this.translateService.translate('_CLAIMS_._MESSAGE.__UPDATE_SUCCESSFUL').subscribe(res=> {
              this.alertService.translatedMessage(res);
            }
            )
          } else {
            this.validationMessages = [response.descr];
          }
          this.eventService.broadcastEvent('stop-loader');
        },
        (error: Error) => {
          console.error(error);
          this.translateService.translate('_CLAIMS_._MESSAGE_._SAVING_ERROR').subscribe(res=> {
            this.validationMessages.push(res);
          }
          )
          this.eventService.broadcastEvent('stop-loader');
        }
      );
  }

  expertNameSurnameOrTitleRequired(
    controlName: string,
    controlSurname: string,
    controlTitle: string,
    controlGender: string
  ) {
    return (formGroup: UntypedFormGroup) => {
      const name = formGroup.controls[controlName];
      const surname = formGroup.controls[controlSurname];
      const title = formGroup.controls[controlTitle];
      const gender = formGroup.controls[controlGender];

      name.setErrors(null);
      surname.setErrors(null);
      title.setErrors(null);
      gender.setErrors(null);

      this.cleanErrorStyle('name');
      this.cleanErrorStyle('surname');
      this.cleanErrorStyle('title');
      this.cleanErrorStyle('gender');

      if (
        (name.value && name.value.length > 0 && surname.value && surname.value.length > 0) ||
        (title.value && title.value.length > 0)) {

        if (name.value === this.ignoto && surname.value === this.ignoto && title.value === this.ignoto) {
          name.setErrors({ invalidIgnoto: true });
          surname.setErrors({ invalidIgnoto: true });
          title.setErrors({ invalidIgnoto: true });
          this.setErrorStyle('name');
          this.setErrorStyle('surname');
          this.setErrorStyle('title');
        }

        if (name.value &&
          name.value.length > 0 &&
          surname.value &&
          surname.value.length > 0 && (gender.value === null || gender.value === '')) {
          gender.setErrors({ genderRequired: true });
          this.setErrorStyle('gender');
        }

        if (title.value &&
          title.value.length > 0 && (gender.value !== null && gender.value !== '')) {
          gender.setErrors({ genderRequired: true });
          this.setErrorStyle('gender');
        }

      } else {

        if (name.value && name.value.length > 0) {
          surname.setErrors({ identityConstraint: true });
          this.setErrorStyle('surname');

        } else if (surname.value && surname.value.length > 0) {
          name.setErrors({ identityConstraint: true });
          this.setErrorStyle('name');

        } else {
          name.setErrors({ identityConstraint: true });
          surname.setErrors({ identityConstraint: true });
          title.setErrors({ identityConstraint: true });
          this.setErrorStyle('name');
          this.setErrorStyle('surname');
          this.setErrorStyle('title');
        }
      }
    };
  }

  fieldsAreValid() {
    if (this.updateExpertForm.valid) {

      return true;

    } else {

      this.validationMessages = [];
      this.translateService.translate('_CLAIMS_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )

      return false;

    }
  }

  setErrorStyle(fieldName: string) {
    if (document.getElementById(fieldName) != null) {
      document.getElementById(fieldName).style.cssText = 'border: 2px solid #d22323 !important;color: #d22323 !important;';
    }
  }

  cleanErrorStyle(fieldName: string) {
    if (document.getElementById(fieldName) != null) {
      document.getElementById(fieldName).style.cssText = '';
    }
  }

  mailValidator(
    controlMail: string,
    controlPec: string,
  ) {

    return (formGroup: UntypedFormGroup) => {
      const email = formGroup.controls[controlMail];
      const pec = formGroup.controls[controlPec];

      email.setErrors(null);
      pec.setErrors(null);

      this.cleanErrorStyle('email');
      this.cleanErrorStyle('pec');

      this.mailString = email.value;
      this.pecString = pec.value;

      // se esiste una pec, controllo che ci sia una sola volta la chiocciolina, , perchè devono mettere 1 solo indirizzo
      if (this.pecString) {
        if (!this.pecString.includes('@')) {
          pec.setErrors({ invalidPec: true });
          this.setErrorStyle('pec');
        } else {
          // rimuovo qualsiasi altro carattere nella stringa e controllo la lunghezza
          if (this.pecString.replace(/[^@]/g, '').length > 1) {
            pec.setErrors({ invalidPec: true });
            this.setErrorStyle('pec');
          }
        }
      }

      // se esiste una mail, controllo che ci sia una sola volta la chiocciolina, , perchè devono mettere 1 solo indirizzo
      if (this.mailString) {
        if (!this.mailString.includes('@')) {
          email.setErrors({ invalidMail: true });
          this.setErrorStyle('email');
        } else {
          // rimuovo qualsiasi altro carattere nella stringa e controllo la lunghezza
          if (this.mailString.replace(/[^@]/g, '').length > 1) {
            email.setErrors({ invalidMail: true });
            this.setErrorStyle('email');
          }
        }
      }

    };
  }
}

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {Document} from "../../resources/model/document";
import {DocumentFile} from "../../resources/model/document-file";
import {DIALOG_DATA, OnModalClose} from "@rgi/rx/ui";
import {RGI_DOCUMENTS_ACTIONS} from "../../resources/constants/actions";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {DocumentsService} from "../../services/documents.service";


export class RgiValidationFileModalData {

  title: string;
  contractId: string;
  document: Document;
  file: DocumentFile;
  arInpDocValidation: Array<any>;

  constructor(title: string, contractId: string, document: Document, file: DocumentFile, arInpDocValidation: Array<any>) {
    this.title = title;
    this.contractId = contractId;
    this.document = document;
    this.file = file;
    this.arInpDocValidation = arInpDocValidation;
  }
}
@Component({
  selector: 'rgi-document-validation-file-modal',
  templateUrl: './document-validation-file-modal.component.html'
})
export class DocumentValidationFileModalComponent  implements OnModalClose {

  modalClose: EventEmitter<RGI_DOCUMENTS_ACTIONS | string> = new EventEmitter();

  data: RgiValidationFileModalData;
  validationFileForm: UntypedFormGroup  = new UntypedFormGroup({});
  validate = false;


  constructor(
    private documentsService: DocumentsService,
    @Optional() @Inject(DIALOG_DATA) data: RgiValidationFileModalData
  ) {
    if (data) {
      this.data = data;
    }
  }

  ngOnInit() {
    const noteFormCtrl = new UntypedFormControl({}, {
      validators: [Validators.required],
      updateOn: 'change'
    });
    this.validationFileForm.addControl('note', noteFormCtrl);
  }

  close() {
    this.modalClose.emit(RGI_DOCUMENTS_ACTIONS.CANCEL);
  }

  confirmValidationFile() {
    this.data.file.note = this.validationFileForm.get('note')?.value;

    this.documentsService.validateFile(this.data.contractId, this.data.document.id, this.data.file).subscribe((file: DocumentFile) => {
        if (file) {
          const listFiles = this.data.document.files.filter((docFile: DocumentFile) => docFile.id !== file.id);
          this.data.document.files.length = 0;
          Array.prototype.push.apply(this.data.document.files, listFiles);
          this.data.document.files.push(file);
          this.checkDocumentValidation();
          this.close();
        }
      });
  }

  checkDocumentValidation() {
    let validate = true;

    this.data.document.files.forEach(file => {
      if (!file.validate) {
        validate = false;
      }
    });

    //this.componentRef.instance.arInpDocValidation[this.document.id] = validate;
  }
}

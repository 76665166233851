import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Authorization, AuthorizationRequest } from '../models/authorization.model';
import { EnviromentsService } from './enviroments.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected authorizationsEndpoint;
  protected baseApiUrl: string;
  protected baseApiUrlV2: string;

  constructor(protected http: HttpClient,
              protected environment: EnviromentsService ) {
    this.baseApiUrl = environment.baseApiURL;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';
    this.authorizationsEndpoint = this.baseApiUrlV2 + '/authorizations';
  }

  putAuthorizationStatus(authRequest: AuthorizationRequest): Observable<any> {
      let requestParams: HttpParams = new HttpParams();
      for (const key in authRequest) {
          if (key !== 'authorizationId' && authRequest[key]) {
          requestParams = requestParams.set(key, authRequest[key]);
          }
      }
      return this.http.put<Authorization>(this.authorizationsEndpoint + '/authorization/' + authRequest.authorizationId,
      {}, { params: requestParams });
  }

  getAuthorizationDetail(authorizationId: string): Observable<Authorization> {
    return this.http.get<any>(this.authorizationsEndpoint + '/authorization/' + authorizationId);
  }

}

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {GPClause} from '../../../group-policy-models/group-policy-issue-policy-data';

export interface GroupPolicyTextClauseModalResp {
  event: 'cancel' | 'confirm';
  text?: string;
  initialState?: string;
  hiddenOnApplication?: boolean;
}

@Component({
  selector: 'rgi-gp-group-policy-modal-textbox',
  templateUrl: './group-policy-modal-text-box.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})


export class GroupPolicyModalTextBoxComponent implements OnInit , OnModalClose {
  protected clauseData: GPClause;

  constructor(@Optional() @Inject(DIALOG_DATA) clause: GPClause) {
    this.clauseData = clause;
  }

  public modalClose = new EventEmitter<GroupPolicyTextClauseModalResp>();

  public modalTextForm = new UntypedFormGroup({
    textField: new UntypedFormControl(''),
    initialStateField: new UntypedFormControl(''),
    hiddenOnApplicationField: new UntypedFormControl('')
  });

  ngOnInit() {
    if (this.clauseData) {
      if (this.clauseData.initialState) {
        const initialStateField = this.modalTextForm.get('initialStateField');
        initialStateField.setValue(this.clauseData.initialState.value.code);
        const hiddenOnApplicationField = this.modalTextForm.get('hiddenOnApplicationField');
        hiddenOnApplicationField.setValue(this.clauseData.hiddenOnApplication ? 'true' : 'false');
      }
      const field = this.modalTextForm.get('textField');
      field.setValue(this.clauseData.text);
      if (this.clauseData.mandatoryText) {
        field.setValidators([Validators.required]);
      }
      if (!this.clauseData.editableText) {
        field.disable();
      }
    }
  }

  confirmClause() {
    if (this.modalTextForm.valid) {
      const value = {
        event: 'confirm',
        text: this.modalTextForm.controls.textField.value
      } as GroupPolicyTextClauseModalResp;
      if (this.modalTextForm.contains('initialStateField')) {
        value.initialState = this.modalTextForm.controls.initialStateField.value;
      }
      if (this.modalTextForm.contains('hiddenOnApplicationField')) {
        value.hiddenOnApplication = 'true' === this.modalTextForm.controls.hiddenOnApplicationField.value;
      }
      this.modalClose.emit(value);
    }
  }

  disableConfirm() {
    return this.clauseData.mandatoryText &&
      (!this.modalTextForm.controls.textField.value || this.modalTextForm.controls.textField.value.trim().length === 0);
  }

  getTextAreaLabel() {
    return this.clauseData.editableText ? '_GP_._LABEL_._FREE_TEXT_' : '_GP_._LABEL_._FIXED_TEXT_';
  }
}

import { RequestFactor } from '../models/factor.model';
import { Instalment, PostsalesOperationObject, QuotationDefinition, Risk, RiskFormulas } from '../models/postsales-operations-response.model';
import { RisksFactors } from '../models/risks-factors.model';
import { Premium } from '../modules/lpc-premium/model/premium.model';
import { CoveragesReductionRow, Quotation, WarrantyRow } from '../modules/lpc-quotation/model/quotation.model';
import { InstalmentType } from '../operation-components/dynamic-operation/dynamic-operation.model';
import { PlcObjectUtils } from './plc-object-utils';

export class PlcQuotationUtils {
  public static createQuotationDefinition(q): QuotationDefinition {
    return {
      annualPremium: q.annualQuotation,
      firstInstalment: null,
      nextInstalment: q.nextInstalmentQuotation,
      recoveredCommission: null,
      reimbursementAmount: null,
      risks: q.risksInfo,
      beneficiaries: null
    };
  }

  public static mapRisk(cd: string, ft: RequestFactor[]): RisksFactors {
    return {
      codeRisk: cd,
      factors: ft
    } as RisksFactors;
  }

  public static mapRisksForUnits(srcRisk: { [key: string]: any | any[] }, destRisk: any): Risk[] {
    srcRisk.forEach( (singleRisk: any, i) => {
      if (singleRisk) {
        singleRisk.factors.forEach((singleFactor: any, j) => {
          if (singleFactor) {
            destRisk[i].factors[j].defaultValue = singleFactor.value;
          }
        });
      }
    });

    return destRisk;
  }

  public static convertFactors(factors) {
    const factorsArray: RequestFactor[] = [];
    Object.keys(factors).forEach(key => {
      factorsArray.push({
        code: key,
        label: null,
        value: factors[key],
        userInput: false,
        typeCode: null
      });
    });
    return factorsArray;
  }

  /**
   * @description it use the operationData to get the valorization sent
   * to preval the riskDefinition (the definition object) used to view the formula on page
   */
  public static updateFormulasFromModel(result: PostsalesOperationObject) {
    const riskFormulasObject = result.data.operationData.data.riskFormulasObject as RiskFormulas[];
    const risksDefinition: Risk[] = (result.definitions.quotationDefinition as QuotationDefinition).risks;
    if (!!riskFormulasObject && !!risksDefinition && risksDefinition.length > 0) {
     riskFormulasObject.forEach(risk => {
        const riskInstance = risksDefinition.find(r => r.code === risk.code);
        if (!!riskInstance && PlcObjectUtils.isArrayEmpty(riskInstance.formulas) && PlcObjectUtils.isArrayEmpty(risk.formulas)) {
          PlcQuotationUtils.evaluateFormula(risk, riskInstance);
        } else if (riskInstance && risk.code === riskInstance.code) {
          PlcQuotationUtils.evaluateFormula(risk, riskInstance);
        } else {
          const subRiskInstance = risksDefinition.find(r => !!r.subRisks && r.subRisks.some(sr => sr.code === risk.code));
          if (!!subRiskInstance && PlcObjectUtils.isArrayEmpty(subRiskInstance.subRisks.find(sr => sr.code === risk.code).formulas)
              && PlcObjectUtils.isArrayEmpty(risk.formulas)) {
              PlcQuotationUtils.evaluateFormula(risk, subRiskInstance.subRisks.find(sr => sr.code === risk.code));
          }
        }
      });
    }
    (result.definitions.quotationDefinition as QuotationDefinition).risks = risksDefinition;
  }

  private static evaluateFormula(risk: RiskFormulas, riskInstance: Risk) {
    risk.formulas.forEach(formula => {
      const formulaInstance = riskInstance.formulas.find(f => formula.code === f.code);
      if (!!formulaInstance) {
        formulaInstance.value = formula.value.toString();
      }
    });
  }

  /**
   * @description
   * avoid null values of first instalment or next instalment or annual premium in warranties detail modal
   */
  public static checkRisk(risk: Risk, instalmentType: string) {
    if (instalmentType === InstalmentType.NEXT_INSTALMENT) {
      return !!risk.nextInstalment;
    }
    if (instalmentType === InstalmentType.FIRST_INSTALMENT) {
      return !!risk.firstInstalment;
    }
    if (instalmentType === InstalmentType.ANNUAL_PREMIUM) {
      return !!risk.annualPremium;
    }
  }

  public static findInstalment(instalmentType: string, risk: Risk): Instalment {
    let instalment: Instalment;
    if (instalmentType === InstalmentType.FIRST_INSTALMENT) {
      instalment = risk.firstInstalment;
    } else if (instalmentType === InstalmentType.NEXT_INSTALMENT) {
      instalment = risk.nextInstalment;
    } else if (instalmentType === InstalmentType.ANNUAL_PREMIUM) {
      instalment = risk.annualPremium;
    }
    return instalment;
  }


  public static instalmentToPremium(instalment: Instalment): Premium {
    let premium: Premium;
    if (!!instalment) {
      premium = {
        typeDescription: null,
        netAmount: instalment.net.toString(),
        accessorsAmount: null,
        installmentInterests: instalment.frequencyInterests.toString(),
        rightsAmount: instalment.fees.toString(),
        taxesAmount: instalment.taxes.toString(),
        taxableAmount: null,
        totalAmount: instalment.gross.toString()
      };
    } else {
      premium = null;
    }
    return premium;
  }

  public static quotationDefToQuotationData(quotationDefinition: QuotationDefinition) {
    let quotationData: Quotation = null;
    if (!quotationDefinition.firstInstalment) {
      const listWarrantyRow: WarrantyRow[] = [];
      const coveragesPolicyReduction: CoveragesReductionRow[] = [];
      quotationDefinition.risks.map(risk => {
        listWarrantyRow.push(this.createWarrantyRowFromRisk(risk));
        if (!!risk.subRisks) {
          risk.subRisks.map(subrisk => {
            listWarrantyRow.push(this.createWarrantyRowFromRisk(subrisk));
          });
        }
      });
      quotationData = {
        totalRecoveredCommission:
          quotationDefinition.recoveredCommission != null ? quotationDefinition.recoveredCommission.toString() : null,
        totalReimbursementAmount:
          quotationDefinition.reimbursementAmount != null ? quotationDefinition.reimbursementAmount.toString() : null,
        totalResidualBenefitAmount: null,
        listWarrantyRow,
        coveragesPolicyReduction
      };
    }
    return quotationData;
  }

  public static createInstalmentFromRisk(risk: Risk, instalmentType: Instalment): Instalment {
    const instalment: Instalment = {
      description: risk.description,
      extraPremium: !!instalmentType ? instalmentType.extraPremium : null,
      fees: !!instalmentType ? instalmentType.fees : null,
      frequencyInterests: !!instalmentType ? instalmentType.frequencyInterests : null,
      gross: !!instalmentType ? instalmentType.gross : null,
      loadings: !!instalmentType ? instalmentType.loadings : null,
      net: !!instalmentType ? instalmentType.net : null,
      purePremium: !!instalmentType ? instalmentType.purePremium : null,
      taxes: !!instalmentType ? instalmentType.taxes : null
    };
    return instalment;
  }

  public static createWarrantyRowFromRisk(risk: Risk): WarrantyRow {
    let warrantyRow: WarrantyRow;
    warrantyRow = {
      warrantyDescription : risk.description,
      reimbursementAmount : risk.reimbursementAmount != null ? risk.reimbursementAmount.toString() : null,
      recoveredCommission: risk.recoveredCommission != null ? risk.recoveredCommission.toString() : null,
      residualBenefitAmount: null
    };
    return warrantyRow;
  }

  public static checkProvVisibility(risks: Risk[]): boolean {
    let provFound = false;
    if (!!risks && !!risks.length) {
      risks.map(risk => {
        if (!!risk.purchaseCommissions && !!risk.purchaseCommissions.length) {
          provFound = true;
        } else {
          if (!!risk.subRisks && !!risk.subRisks.length) {
            risk.subRisks.map(subRisk => {
              if (!!subRisk.purchaseCommissions && !!subRisk.purchaseCommissions.length) {
                provFound = true;
              }
            });
          }
        }
      });
    }
    return provFound;
  }

  /** @description creating sections for warranties detail modal */
  public static createInstalmentSectionWarranties(risks: Risk[], instalmentType: string): Instalment[] {
    const sectionWarranties: Instalment[] = [];
    if (!!risks) {
      risks.map(risk => {
        let instalment = PlcQuotationUtils.findInstalment(instalmentType, risk);
        if (!!instalment && PlcQuotationUtils.checkRisk(risk, instalmentType)) {
          sectionWarranties.push(PlcQuotationUtils.createInstalmentFromRisk(risk, instalment));
        }
        if (!!risk.subRisks && !!risk.subRisks.length) {
          risk.subRisks.map(subrisk => {
            instalment = PlcQuotationUtils.findInstalment(instalmentType, subrisk);
            if (!!instalment && PlcQuotationUtils.checkRisk(subrisk, instalmentType)) {
              sectionWarranties.push(PlcQuotationUtils.createInstalmentFromRisk(subrisk, instalment));
            }
          });
        }
      });
    }
    return sectionWarranties;
  }


}


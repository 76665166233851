<div class="rgi-ui-panel-container rgi-ui-mt-lg">
  <div class="rgi-ui-col rgi-ui-grid-2">

    <div class="icon-consultation rgi-cc-flex-center rgi-ui-col-4 rgi-cc-justify-center">
      <div class="icon-consultation-container rgi-cc-flex-center rgi-cc-justify-center">
        <span class="rgi-ui-icon-document rgi-cc-flex-center rgi-cc-justify-center"></span>
      </div>
    </div>

    <div class="rgi-ui-col-8-sm-12 rgi-ui-grid-1">
      <div class="rgi-ui-col">
        <span class="rgi-ui-info title rgi-ui-title-1">{{contract?.description}}</span>
      </div>
      <div class="rgi-ui-col">
        <span class="rgi-ui-title-2">{{contract.product?.description}}</span>
      </div>
      <div class="rgi-ui-col rgi-ui-grid">
        <div class="rgi-ui-col">
            <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.MANAGEMENT_NODE'" [value]="contract?.managementNode?.description"></consultation-row>

            <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.NOMINATIVE'" [value]="contract?.contractor?.subject?.nominative"></consultation-row>

            <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.FISCAL_CODE'" [value]="contract?.contractor?.subject?.fiscalCode"></consultation-row>

            <consultation-row [type]="'BOOL'" [label]="'CONTRACT_CONSULTATION.TACIT_RENEWAL'" [value]="contract?.tacitRenewal"></consultation-row>
        </div>
          <div class="rgi-ui-col">
            <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.CONTRACT_CONDITION'" [value]="contract?.currentMovement?.contractStatus?.description"></consultation-row>

            <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.BRANCH_OFFICE'" [value]="contract?.branchOffice ? contract?.branchOffice.description : '-'"></consultation-row>

            <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.FRACTIONATION'" [value]="contract?.fractioning?.description"></consultation-row>
          </div>
      </div>
    </div>
  </div>


  <div class="title-policy rgi-ui-col rgi-ui-grid-2">
    <div class="rgi-ui-col policy-number rgi-cc-flex-center rgi-cc-justify-center">
      <span>{{contract.numPolicy ? contract.numPolicy : contract.numProposal}}</span>
      <span translate>{{ contract.numPolicy ? 'CONTRACT_CONSULTATION.POLICY_NUMBER' : 'CONTRACT_CONSULTATION.PROPOSAL_NUMBER'}}</span>
    </div>
    <div class="rgi-ui-col data rgi-cc-flex-center rgi-cc-justify-center">
      <span>{{contract.effectivePolicyDate | date: DATEFORMAT}}</span>
      <span>{{'CONTRACT_CONSULTATION.EFFECTIVE_DATE' | translate}}</span>
    </div>

  </div>
  <div class="rgi-ui-col rgi-ui-mt-lg rgi-ui-panel-footer padding-footer">
    <button rgi-rx-button (click)="back()" color="secondary" data-qa="clear" class="cc-btn">
      {{' CONTRACT_CONSULTATION.BACK' | translate}}
    </button>

    <button rgi-rx-button color="primary"
            data-qa="create-copy" class="cc-btn">
      {{'CONTRACT_CONSULTATION.CREATE_COPY' | translate}}
    </button>
    <button rgi-rx-button color="primary" class="cc-btn"
            data-qa="actions"  (click)="getPolicyOperations()">
      {{'CONTRACT_CONSULTATION.ACTION' | translate}}
    </button>
  </div>
</div>


import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MotorIssueCardHostDirective} from '../motor-issue-card-host.directive';
import {EventNotificator} from '../event-notificator';
import {RoutingService} from '../routing.service';
import {PartiesService} from '../proposal/parties.service';
import {ProposalService} from '../proposal.service';
import {ApiContract} from '../models/api-models/api-contract';
import {Subscription, throwError} from 'rxjs';
import {StateService} from '../state.service';
import {RiskCertificateService} from '../risk-certificate/risk-certificate-service';
import {QuotationService} from '../quotation/quotation.service';
import {ParameterService} from '../quotation/parameters/parameters.service';
import {QuestionnaireService} from '../questionnaires/questionnaire.service';
import {QuestionnaireDnService} from '../proposal/questionnairedn.service';
import {LiensService} from '../proposal/liens/liens.service';
import {AddressesService} from '../proposal/addresses/addresses.service';
import {InsuranceBackgroundService} from '../insurance-background/insurance-background.service';
import {VariablesService} from '../variables/variables-service';
import {ContractCacheService} from '../contract-cache.service';
import {CommissionsService} from '../quotation/commissions/commissions.service';
import {UnitService} from '../quotation/asset-unit/unit.service';
import {ClauseService} from '../clause/clause-service';
import {QuoteService} from '../quote.service';
import {ModalService} from '@rgi/portal-ng-core';
import {catchError, concatMap, map} from 'rxjs/operators';
import {MicIntegrationService} from '../mic-integration.service';
import {IvassService} from '../ivass.service';
import {MasterPolicyService} from '../master-policy.service';
import {ApiMasterPolicyRef} from '../models/api-models/api-master-policy';
import {State} from '../models/state';
import {ContractClauseService} from '../contract-clause/contract-clause-service';
import {ProductAssetModalComponent} from '../product-asset-modal/product-asset-modal-component';
import {CustomModalService} from '../custom-modal.service';
import {ActionOperation, PortalAction} from '../models/api-models/api-action-operation';
import {AmendmentService} from '../amendment/amendment.service';
import {Authorization} from '@rgi/authorizations-card';

@Component({
  selector: 'mic-base',
  templateUrl: './motor-issue-card.component.html',
  styleUrls: ['./motor-issue-card.component.scss'],
  providers: [
    ProposalService,
    AmendmentService,
    RoutingService,
    StateService,
    VariablesService,
    RiskCertificateService,
    QuotationService,
    ParameterService,
    QuestionnaireService,
    QuestionnaireDnService,
    PartiesService,
    LiensService,
    AddressesService,
    InsuranceBackgroundService,
    CommissionsService,
    UnitService,
    ClauseService,
    ContractClauseService,
    IvassService
  ]
})
export class MotorIssueCardComponent implements OnInit, OnDestroy {
  @Output() eventPropagation = new EventEmitter<string>();
  @ViewChild(MotorIssueCardHostDirective, {static: true}) micHost: MotorIssueCardHostDirective;
  @ViewChild('productAssetModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) productAssetOverlayContainerRef: ViewContainerRef;
  componentRef: ComponentRef<Component>;
  routes = null;
  // tmpid;
  private naviPosition = 'start';
  private subscriptions = new Subscription();
  protected lastResourceId: string;

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected routingService: RoutingService,
    protected partiesService: PartiesService,
    protected proposalService: ProposalService,
    protected contractCacheService: ContractCacheService,
    protected stateService: StateService,
    protected quoteService: QuoteService,
    protected masterPolicyService: MasterPolicyService,
    protected modalService: ModalService,
    protected customModalService: CustomModalService,
    protected integrationService: MicIntegrationService,
    protected amendmentService: AmendmentService,
    @Inject('startComponent') startComponent,
    @Inject('quotationComponent') quotationComponent,
    @Inject('assetComponent') assetComponent,
    @Inject('entitledPartyComponent') entitledPartyComponent,
    @Inject('proposalComponent') proposalComponent,
    @Inject('mandatoryDataComponent') mandatoryDataComponent,
    @Inject('policySummaryComponent') policySummaryComponent,
    @Inject('substitutionComponent') substitutionComponent,
    @Inject('amendmentComponent') amendmentComponent) {
    // this.tmpid = Math.random() * 100;
    // console.log('AAA costruttore mic' + this.tmpid);
    this.routes = {
      start: startComponent,
      quote: quotationComponent,
      asset: assetComponent,
      entitled: entitledPartyComponent,
      proposal: proposalComponent,
      mandatorydata: mandatoryDataComponent,
      summary: policySummaryComponent,
      substitution: substitutionComponent,
      amendment: amendmentComponent
    };
  }

  @Input()
  set authorization(authorization: Authorization) {
    // only for RAO context in post sales
    if (authorization?.policyNumber) {
      this.amendmentService.createDraftFromAuthorizationId(authorization.policyNumber, authorization.operationType, authorization.operationCode, authorization.id).pipe(
        concatMap((res) => {
          const contract = new ApiContract();
          contract.id = res.resourceId;
          return this.proposalService.getContractInCache(contract);
        }),
        map((contract: ApiContract) => {
          this.amendmentService.isAmendment = true;
          this.amendmentService.resourceId = contract.id;
          const operation = new ActionOperation(authorization.operationCode, authorization.operation, null);
          this.amendmentService.flowOriginAction = new PortalAction(null, null, null, operation, authorization.operationType);
          this.proposalService.setLoadedProposal(contract);
          this.proposalService.sendNewContractSignal(contract);
        })
      ).subscribe();
    }
  }

  @Input()
  set resourceId(resourceId) {
    if (resourceId) {
      this.lastResourceId = resourceId;
    }
  }

  @Input()
  set navi(navi) {
    if (navi) {
      this.routingService.setNaviPosition(navi);
      this.naviPosition = navi;
    }
  }

  @Input()
  set routesOverride(routes) {
    if (routes) {
      Object.assign(this.routes, routes);
    }
  }

  @Input()
  set selectedSubject(party) {
    if (party) {
      this.partiesService.setParty(party);
    }
  }

  @Input()
  set disableCollecting(disableCollecting) {
    if (disableCollecting !== undefined) {
      this.proposalService.setDisableCollectingButtons(disableCollecting);
    }
  }

  @Input()
  set contractNumber(contractNumber) {
    if (contractNumber && (contractNumber.proposalNumber || contractNumber.policyNumber)) {
      if (contractNumber.loadContract === undefined || contractNumber.loadContract) {
        const contract = new ApiContract();
        delete contractNumber.loadContract;
        contract.contractNumber = contractNumber;
        this.proposalService.setApplicationSubstitution(!!contractNumber.isApplicationSubstitution);
        this.proposalService.setIsInclusionApplication(!!contractNumber.isApplicationSubstitution);
        this.proposalService.loadContract(contract).pipe(catchError(err => {
          if (err.status === 400) {
            this.modalService.open(err.error.messages, 'Error', () => {
              this.integrationService.closeApp();
            });
          }
          return throwError(err);
        })).subscribe(loadedContract => {
          this.proposalService.setLoadedProposal(loadedContract);
          this.proposalService.sendNewContractSignal(loadedContract);
          this.proposalService.setSubClusterCode(contractNumber.subClusterCode);
          this.proposalService.setSubAssetCode(contractNumber.subAssetCode);
        });
      } else {
        if (contractNumber.policyNumber) {
          this.amendmentService.flowOriginPolicyNumber = contractNumber.policyNumber;
        }
      }
    }
  }

  @Input()
  set quoteRef(quoteRef) {
    if (quoteRef && quoteRef.quoteNumber ) {
      this.quoteService.transformQuote(quoteRef.quoteNumber, null).pipe(catchError(err => {
        if (err.status === 400) {
          this.modalService.open(err.error.messages, 'Error', () => {
            this.integrationService.closeApp();
          });
        }
        return throwError(err);
      }))
        .subscribe((contract: ApiContract) => {
          this.proposalService.editQuote = quoteRef.editQuote;
          this.proposalService.setLoadedProposal(contract);
          if (!contract.entitledOnQuote) {
            this.proposalService.setProposalState(false);
          }
          this.proposalService.sendNewContractSignal(contract);
        });

    }
  }

  @Input()
  set masterpolicyRef(masterPolicyRef: ApiMasterPolicyRef) {
    if (masterPolicyRef && !!masterPolicyRef.proposalNumber && !!masterPolicyRef.data) {
      if (masterPolicyRef.data.specialPlate) {
        this.proposalService.setInclusionData(masterPolicyRef);
        this.customModalService.openModal(this.productAssetOverlayContainerRef, ProductAssetModalComponent,
          this.eventPropagation, () => {
            this.onProductAssetModalDestroy(masterPolicyRef);
          });
      } else {
        this.includeApplication(masterPolicyRef);
      }
    }
  }

  protected includeApplication(masterPolicyRef: ApiMasterPolicyRef) {
    this.masterPolicyService.includeApplication(masterPolicyRef.proposalNumber, masterPolicyRef.data).pipe(catchError(err => {
      if (err.status === 400 || err.status === 422) {
        this.modalService.open(err.error.messages, 'Error', () => {
          this.integrationService.closeApp();
        });
      }
      return throwError(err);
    }))
      .subscribe((contract: ApiContract) => {
        this.proposalService.setLoadedProposal(contract);
        if (!contract.entitledOnQuote) {
          this.proposalService.setProposalState(false);
        }
        this.routingService.setPreviousState(State.INCLUSION_APPLICATION);
        this.stateService.setCurrentState(State.INCLUSION_APPLICATION);
        this.stateService.nextState();
        this.proposalService.sendNewContractSignal(contract);
      });
  }

  @Input()
  set survey(survey) {
    if (survey) {
      this.partiesService.setSurveyFilledOut(survey);
    }
  }

  @Input()
  set cardIdSession(idSession: string) {
    if (idSession) {
      if (idSession && idSession !== '') {
        this.routingService.setIdCardSession(idSession);
      }
    }
  }

  @Input()
  set action(action: PortalAction) {
    this.amendmentService.flowOriginAction = action;
  }

  protected onProductAssetModalDestroy(masterPolicyRef: ApiMasterPolicyRef) {
    if (this.proposalService.getProduct() && this.proposalService.getAssetType()) {
      masterPolicyRef.data.plate = this.proposalService.getPlateNumber();
      masterPolicyRef.data.plateNumber = masterPolicyRef.data.plate.plateNumber;
      this.includeApplication(masterPolicyRef);
    } else {
      this.stateService.resetState();
      this.integrationService.closeApp();
    }
  }

  ngOnInit() {
    if (this.lastResourceId) {
      const contract = this.contractCacheService.getContractFromCache(this.lastResourceId);
      this.proposalService.setDateOfBirth(contract.dateOfBirth);
      this.proposalService.setNodeDescription(contract.nodeDescription);
      this.proposalService.setProposal(contract.proposal);
      this.proposalService.setPlateNumber(contract.proposal.licensePlate);
      this.stateService.setCurrentState(contract.stateService.currentState);
      this.stateService.setPreviousState(contract.stateService.previousState);
      this.routingService.setNaviPosition(contract.routingService.naviPosition);
      this.routingService.setPreviousState(contract.routingService.previousState);
    }

    if (this.routingService.getNaviPosition()) {
      this.naviPosition = this.routingService.getNaviPosition();
      this.routingService.setNaviPosition(null);
    }
    this.load(this.naviPosition);
    this.listenToRoutingServiceEvents();
  }

  loadComponent(component: Type<any>) {
    if (!this.micHost) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.micHost.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);

    const componentEventPropagationSubscription = (this.componentRef.instance as EventNotificator).eventPropagation.subscribe((event) => {
      this.eventPropagation.emit(event);
    });
    this.subscriptions.add(componentEventPropagationSubscription);
  }

  load(position) {
    this.naviPosition = position;
    this.loadComponent(this.routes[position]);
  }

  ngOnDestroy() {
  }

  private listenToRoutingServiceEvents() {
    let routingServiceNavigationEmitterSubscription;
    let routingServiceEventPropagationSubscription;

    if (this.naviPosition !== 'start') {
      routingServiceNavigationEmitterSubscription = this.routingService.getNavigationEventEmitter().subscribe((event) => {
        this.loadComponent(this.routes[event]);
      });
    }
    if (this.naviPosition === 'start') {
      routingServiceEventPropagationSubscription = this.routingService.getPropagationEventEmitter().subscribe((event) => {
        this.eventPropagation.emit(event);
      });
    }
    this.subscriptions.add(routingServiceNavigationEmitterSubscription);
    this.subscriptions.add(routingServiceEventPropagationSubscription);
  }
}

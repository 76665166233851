import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicToolConfComponent} from './component/lic-tool-conf/lic-tool-conf.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {ReactiveFormsModule} from '@angular/forms';
import {LicKarmaFundModule} from '../lic-karma-funds/lic-karma-fund.module';
import {LicKarmaDatepickerModule} from '../lic-datepicker/lic-karma-datepicker/lic-karma-datepicker.module';
import {LicPercentFormGroupModule} from '../lic-percent-form-group/lic-percent-form-group.module';
import {LicInvestmentContractModule} from '../lic-investment-contract/lic-investment-contract.module';
import { LicDatepickerModule } from '../lic-datepicker/lic-datepicker/lic-datepicker.module';
import { LicRequiredModule } from '../utils/lic-required/lic-required.module';

@NgModule({
    declarations: [
        LicToolConfComponent
    ],
    imports: [
        CommonModule,
        LicDatepickerModule,
        PortalNgCoreModule,
        ReactiveFormsModule,
        LicKarmaFundModule,
        LicKarmaDatepickerModule,
        LicPercentFormGroupModule,
        LicInvestmentContractModule,
        LicRequiredModule
    ],
    exports: [
        LicToolConfComponent
    ]
})
export class LicToolsModule { }

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DocumentConstants {
    public METADATA_KEY_DOC_NAME = 'DOC_NAME';
    public METADATA_KEY_DOC_CAT = 'DOC_CAT';
    public METADATA_KEY_MICROCAT = 'MICRO_CATEGORIA';
    public METADATA_KEY_MACROCAT = 'CATEGORIA';
    public METADATA_COD_COMPAGNIA = 'COD_COMPAGNIA';
    public METADATA_NOME_UTENTE = 'NOME_UTENTE';
    public METADATA_AGENZIA = 'AGENZIA';
    public METADATA_ESERCIZIO = 'ESERCIZIO';
    public METADATA_ALERT_NEW_DOC = 'ALERT_NEW_DOC';
    public METADATA_CANALE_RICEZIONE = 'CANALE_RICEZIONE';
    public METADATA_ACCESSO = 'ACCESSO';
}

import {Component, Input} from '@angular/core';
import {DATEFORMAT} from '../../../constants/consultation-utility';

@Component({
  selector: 'consultation-row',
  templateUrl: './consultation-row.component.html',
  styleUrls: ['./consultation-row.component.css']
})
export class ConsultationRowComponent {
  readonly DATE_TYPE = 'DATE';
  readonly TEXT_TYPE = 'TEXT';
  readonly LIST_TYPE = 'LIST';
  readonly NUMBER_TYPE = 'NUM';
  readonly BOOL_TYPE = 'BOOL';
  protected readonly DATEFORMAT = DATEFORMAT;

  @Input() type: string;
  @Input() label: string;
  @Input() value: any;

  constructor() {
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PaymentsField} from '../../../group-policy-models/group-policy-issue-policy-data';

@Component({
  selector: 'rgi-gp-payment-fields',
  templateUrl: './group-policy-payment-fields.component.html'
})
export class GroupPolicyPaymentFieldsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() fields: PaymentsField[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}

export const JS_EVENT_EXT = {
  /**
   * @value OPEN_MOVEMENT_DETAIL: 'movementDetail'
   * @jsonField OPEN_MOVEMENT_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'movementDetail', movementDetail: {}})
   * ```
   */
  OPEN_OTP_DOCUMENTS: 'otpDocuments',
  BACK_DETAIL_DAMAGE: 'detailDamage'
};

export const JS_ROUTES_EXT = {
  OTP_DOCUMENTS_PAGE: 'digital-sign-documents',
  BACK_DETAIL_DAMAGE: 'detail-damage'
};



import {ButtonsAction} from './group-policy-issue-summary';
import {ErrorMsg} from './group-policy-issue-policy-data';

export interface IssuePolicyData {
  messages: ErrorMsg[];
  proposalNumber: string;
  policyNumber: string;
  isFromIssueContract: boolean;
  actionButtons: ButtonsAction[];
}

export type ActionTypeIssueComplete = 'C00737' | 'PC0383' | 'GPS0022' | 'PD0420' | 'PD2613' | 'END_FLOW';

export const  ACTION_ENUM_ISSUE_COMPLETED = {
  INCLUDE_APPLICATION: 'C00737' as ActionTypeIssueComplete,
  GENERATE_TITLE: 'PC0383' as ActionTypeIssueComplete,
  PRINT: 'GPS0022' as ActionTypeIssueComplete,
  DOCUMENTS: 'PD0420' as ActionTypeIssueComplete,
  INQUIRY: 'PD2613' as ActionTypeIssueComplete,
  END: 'END_FLOW' as ActionTypeIssueComplete
}

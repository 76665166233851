/**
 * native-date-time.module
 */

import {NgModule} from '@angular/core';
import {PlatformModule} from '@angular/cdk/platform';
import {DateTimeAdapter} from './date-time-adapter.class';
import {NativeDateTimeAdapter, RgiRxNativeDateParser, RgiRxNativeLocaleDateParser} from './native-date-time-adapter.class';
import {RGI_RX_DATE_TIME_FORMATS} from './date-time-format.class';
import {RGI_RX_NATIVE_DATE_TIME_FORMATS} from './native-date-time-format.class';
import {RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS, RGI_RX_DEFAULT_LOCALE_DATETIME_PARSE_FORMATS, RGI_RX_LOCALE_DATE_PARSE_FORMATS, RGI_RX_LOCALE_DATETIME_PARSE_FORMATS} from './locale.providers';

@NgModule({
  imports: [PlatformModule],
  providers: [
    {provide: DateTimeAdapter, useClass: NativeDateTimeAdapter},
  ]
})
export class NativeDateTimeModule {
}


@NgModule({
  imports: [NativeDateTimeModule],
  providers: [
    {provide: RGI_RX_DATE_TIME_FORMATS, useValue: RGI_RX_NATIVE_DATE_TIME_FORMATS},
    {provide: RGI_RX_LOCALE_DATE_PARSE_FORMATS, useValue: RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS},
    {provide: RGI_RX_LOCALE_DATETIME_PARSE_FORMATS, useValue: RGI_RX_DEFAULT_LOCALE_DATETIME_PARSE_FORMATS},
    {provide: RgiRxNativeDateParser, useClass: RgiRxNativeLocaleDateParser}
  ]
})
export class RgiRxNativeDateTimeModule {
}

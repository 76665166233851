import {
  ComponentRef,
  Directive,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  TemplateRef,
  Type,
  ViewContainerRef
} from '@angular/core';
import {
  Decorator,
  DecoratorProvider,
  DecoratorRef,
  RGI_RX_DECORATOR,
  RGI_RX_DECORATOR_REF,
  RgiRxDecoratorHost
} from '../extension.api';
import {Subscription} from 'rxjs';
import {RgiRxDecoratorService} from '../rgi-rx-decorator.service';

@Directive({
  selector: '[rgiRxDecorator]',
  standalone: true,
  providers: [RgiRxDecoratorService]
})
export class RgiRxDecoratorDirective implements OnChanges, OnDestroy, OnInit {
  private _index: number;
  private _context: any;
  private _inputs?: Record<string, unknown>
  private _outputs?: Record<string, Function>;
  private _selector: string;
  private decoratorComponentRef: ComponentRef<any>;
  private outputsSubscriptions = new Map<string, Subscription>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private decoratorService: RgiRxDecoratorService,
    @Optional() private _host: RgiRxDecoratorHost
  ) {
  }


  @Input("rgiRxDecoratorIndex")
  set index(value: number) {
    this._index = value;
  }

  @Input("rgiRxDecoratorContext")
  set context(value: any) {
    this._context = value;
  }

  @Input("rgiRxDecoratorHost") get host(): RgiRxDecoratorHost {
    return this._host;
  }
  set host(value: RgiRxDecoratorHost) {
    this._host = value;
  }

  @Input("rgiRxDecoratorInputs") get inputs(): Record<string, unknown> {
    return this._inputs;
  }

  set inputs(value: Record<string, unknown>) {
    this._inputs = value;
  }

  @Input("rgiRxDecoratorOutputs") get outputs(): Record<string, Function> {
    return this._outputs;
  }

  set outputs(value: Record<string, Function>) {
    this._outputs = value;
  }

  @Input('rgiRxDecorator') set selector(selector: string) {
    const nodeType = this.viewContainer.element.nativeElement.nodeType;
    if (!selector && nodeType === 8) {
      throw new Error(`The element must be defined when using rgiRxDecorator. Example: *rgiRxDecorator="'my-component'"`);
    }
    if (nodeType !== 8) {
      throw new Error('rgiRxDecorator must be used as structural directive. Example *rgiRxDecorator=\'element-to-decorate\'');
    }
    this._selector = selector;
  }

  ngOnInit(): void {
    const decorator = this.decoratorService.getDecorator(this._selector);
    if (decorator) {
      const ref: DecoratorRef = {
        templateRef: this.templateRef,
        context: this._context
      };
      if (this.isType(decorator.decorator)) {
        this.viewContainer.clear();
        this.decoratorComponentRef = this.viewContainer.createComponent(decorator.decorator, {
          injector: this.createInjector(ref),
          index: this._index
        });

        if (this._inputs) {
          this.bindInputs();
        }

        if (this._outputs) {
          this.bindOutputs();
        }

      } else if (typeof decorator.decorator === 'function') {
        const decoratorTemplateProvider = decorator.decorator as DecoratorProvider;
        decoratorTemplateProvider(ref, this.viewContainer);
      }

    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }


  private bindInputs(inputs: Record<string, unknown> = this._inputs) {
    Object.entries(inputs).forEach(([key, value]) => {
      this.decoratorComponentRef.setInput(key, value);
    });
  }

  private bindOutputs(outputs: Record<string, Function> = this._outputs) {
    Object.entries(outputs).forEach(([key, value]) => {
      if (this.decoratorComponentRef.instance[key] instanceof EventEmitter) {
        if (this.outputsSubscriptions.has(key)) {
          this.outputsSubscriptions.get(key).unsubscribe();
        }
        const subscribe = this.decoratorComponentRef.instance[key].subscribe((next => {
          if (!this.host) {
            value(next);
            return;
          }
          if (!this._host[value.name]) {
            throw new Error(`The function ${value.name} is not defined in the host component`);
          }
          this._host[value.name](next);
        }));
        this.outputsSubscriptions.set(key, subscribe);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.rgiRxDecorableOutputs && !changes.rgiRxDecorableOutputs.firstChange) {
      this.bindOutputs(changes.rgiRxDecorableOutputs.currentValue);
    }
    if (changes && changes.rgiRxDecorableInputs && !changes.rgiRxDecorableInputs.firstChange) {
      this.bindInputs(changes.rgiRxDecorableInputs.currentValue);
    }
  }


  private createInjector(ref: DecoratorRef): Injector {
    return Injector.create({
      parent: this.viewContainer.injector,
      providers: [
        {provide: RGI_RX_DECORATOR_REF, useValue: ref}
      ]
    });
  }


  private isType<T>(decorator: Type<T> | DecoratorProvider): decorator is Type<T> {
    return (decorator as Type<T>).prototype !== undefined;
  }

  ngOnDestroy(): void {
    this.outputsSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

}

@Directive({
  selector: '[rgiRxDecorable]',
  standalone: false,
  inputs: [
    'index: rgiRxDecorableIndex',
    'context: rgiRxDecorableContext',
    'host: rgiRxDecorableHost',
    'inputs: rgiRxDecorableInputs',
    'outputs: rgiRxDecorableOutputs',
    'selector: rgiRxDecorable'
  ],
  providers: [RgiRxDecoratorService]
})
/**
 * @deprecated use RgiRxDecoratorDirective
 */
export class RgiRxDecorableDirective extends RgiRxDecoratorDirective{}

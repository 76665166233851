import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const EU_ES: RgiRxSerializedI18n  = {
  START_EMISSION_FLOW_RE: 'RE jaulkipen-fluxua',
  START_POLICY_DATA: 'Polizaren datuak',
  START_ASSETS_DATA: 'Aseguratutako ondasunaren datuak',
  START_QUOTATION: 'Kotizazioa',
  START_SUMMARY: 'Laburpena',
  START_PROPOSAL_MANAGEMENT: 'Proposamena kudeatzea',
  START_SAVE_PROPOSAL: 'Gorde proposamena',
  START_EMISSION: 'Jaulkipena',
  START_REPRINT_DOCUMENTS: 'Dokumentuak berrinprimatzea',
  START_REPRINT_MOVEMENTS: 'Mugimenduak berrinprimatzea',
  START_SOSTITUTION: 'Ordezkapena',
  // Replace space with underscore
  RE_ISSUE: {
    DATE_CREDIT_CARD: 'Ezin da jarraitu, indarraldia ezin baita 360 egun baino gehiago atzeratu gaurtik hasita.',
    REASON: 'Arrazoia',
    RECALCULATE_SAVE: 'Birkalkulatu eta gorde',
    CLEAR_DISCOUNTS: 'Kendu deskontuak',
    DISCOUNT_AMOUNT: 'Deskontuen zenbatekoa',
    DISCOUNTS: 'Kudeatu deskontuak',
    WARRANTIES_SUMMARY: 'Bermeen laburpenak',
    IS_MANDATORY: 'derrigorrezkoa da',
    Country: 'Herrialdea',
    POLICY_DATA: 'Polizaren datuak',
    ASSET_DATA: 'Aseguratutako ondasunen datuak',
    QUOTATION: 'Kotizazioa',
    SUMMARY: 'Laburpena',
    VALIDATE_BRANCH: 'Balioztatu sukurtsala',
    FIELDS_MARKED_IN_RED: 'Gorriz markatutako eremuak derrigorrezkoak dira edo balio okerrak dituzte',
    IS_REQUIRED: 'beharrezkoa da',
    COVERAGE_EXPIRE_DATE: 'Estalduraren iraungitze-data',
    SUBSCRIPTION_INST_PREMIUM: 'Harpidetza-kuotaren prima',
    INST_SIGN: 'Harpidetza-epea: ',
    MANDATORY_QUESTIONNAIRE_NOT_FILLED: 'Adi, gutxienez derrigorrezko galdetegi bat bete gabe dago',
    NATION: 'Nazioa',
    CANCEL: 'Ezeztatu',
    CONFIRM: 'Baieztatu',
    DATE_OF_BIRTH: 'Jaiotze-data',
    FACTORS: 'Faktoreak',
    PLACE_OF_BIRTH: 'Jaioterria',
    DAMAGES_PROPOSAL_ASSET_DATA: 'Kalteen proposamena - Aseguratutako ondasunaren datuak',
    SUBJECTS: 'Subjektuak',
    INSURED_SUBJECTS: 'Subjektu aseguruduna',
    OCCUPATION: 'Lanbidea',
    FISCAL_CODE: 'Kode fiskala',
    BACK: 'Atzera',
    ADD_ASSET: 'Gehitu aseguratutako ondasuna',
    NEXT: 'Jarraitu',
    CONTINUE: 'Hurrengoa',
    NEXT_START_CARD: 'Jarraitu',
    EMISSION: 'Jaulkipena',
    POLICY_N: 'Poliza zk.',
    SAVED_SUCCESSFULLY: 'Gorde da',
    SUCCESSFULLY_FINALIZED: 'Amaitu da',
    PRINT_PIN: 'Inprimatu eta PINa sartu behar da kontratua amaitzeko',
    PRINT: 'Inprimatu',
    PRINT_ENTRY: 'Sartu PINa',
    EXIT: 'Irten',
    ISSUE_CONFIRMATION: 'Jaulkipenaren baieztapena',
    ENTER_PIN_TO_CONFIRM_POLICY_ISSUE: 'Sartu PINa polizaren jaulkipena baieztatzeko:',
    CONFIRM_PURCHASE: 'Baieztatu erosketa',
    POLICY_PREVIEW: 'Polizaren aurrebista',
    VALIDATE: 'Baliozkotu',
    POLICYHOLDER: 'Hartzailea',
    NODE: 'Nodoa',
    NODE_START_CARD: 'Salmenta-puntua',
    eMAIL: 'Helbide elektronikoa',
    PRODUCT: 'Produktua',
    BRANCH_OFFICE: 'Sukurtsala',
    FISCAL_CODE_VAT_NUMBER: 'Kode fiskala/IFZ zenbakia',
    RESIDENCE_ADDRESS: 'Bizitegiaren helbidea',
    CONTACTS: 'Kontaktuak',
    MOBILE_PHONE: 'Telefono mugikorra',
    EFFECTIVE_DATE: 'Indarraldiaren data',
    NEXT_INSTALMENT_DATE: 'Hurrengo epearen data',
    EXPIRE_DATE: 'Epemuga',
    TACIT_RENEWAL: 'Isilbidezko berritzea',
    TECHNICAL_DATA: 'Datu teknikoak',
    PAYMENT_FREQUENCY: 'Ordainketa-maiztasuna',
    AGREEMENT: 'Akordioa',
    PROPOSAL_MANAGEMENT: 'Proposamena kudeatzea',
    ISSUE_DATE: 'Jaulkipen-data',
    POLICY_MANDATORY_DATA: 'Polizaren derrigorrezko datuak',
    POLICY_NUMBER: 'Poliza-zenbakia',
    MEANS_OF_PAYMENT: 'Ordainketa moduak',
    SELECT_MEANS_OF_PAYMENT: 'Hautatu ordaintzeko modua',
    COORDINATES_TO_REFUND: 'Itzulketaren koordenatuak',
    HEADING: 'Goiburukoa',
    ISSUE: 'Jaulkipena',
    '{{header}}': '',
    '{{ parameter?.description }}': '',
    QUESTIONNAIRES: 'Galdetegiak',
    NOT_COMPILED: 'Bildu gabe',
    COMPILED: 'Bilduta',
    TO_BE_FILLED_IN_BY_THE_SUBJECT: 'Kontratatzailearen eginkizuna duen subjektuak bete beharrekoa',
    CLAUSES: 'Klausulak',
    ASSET_CLAUSES: 'Aseguratutako ondasunen klausulak',
    INSURABILITY_REQUIREMENTS: 'Aseguragarritasun-baldintzak',
    PRE_DISCOUNT_PREMIUM: 'Deskontu aurreko prima',
    GROSS: 'Gordin',
    PREMIUM_INSTALLMENT: 'Deskontu aurreko prima',
    ANNUAL_PREMIUM: 'Urteko prima',
    PREMIUM_RATE: 'Prima mota',
    RECALCULATE: 'Birkalkulatu',
    VISUALIZE: 'Ikusi',
    PREMIUM_DETAILS: 'Primaren xehetasunak',
    CONTRIBUTIONS_AND_TAXES: 'Kotizazioak eta zergak',
    BOX: 'Laukia',
    LIST: 'Zerrenda',
    COMMISSIONS: 'Komisioak',
    AUTHORITY_LIMIT: 'Aginte-muga',
    PERC_ACQUIRED_COMMISSIONS: 'Lortutako komisioen %',
    PERC_ACQUIRED_CHARGES: 'Izandako gastuen %',
    PERC_COLLECTED_COMMISSIONS_NET: 'Kobratutako komisioaren % (garbi)',
    PERC_COLLECTED_CHARGES: 'Kobratutako gastuen %',
    APPLY: 'Aplikatu',
    EDIT_AMOUNT: 'Editatu zenbatekoa',
    COMMISSIONS_AT_INCEPTION_DATE: 'Komisioak, hasiera-datan',
    ACQUIRED_COMMISSIONS: ' lortutako komisioena',
    ACQUIRED_CHARGES: ' izandako gastuena',
    COLLECTED_COMMISSIONS_NET: ' Kobratutako komisioa (garbi)',
    COLLECTED_CHARGES: ' Kobratutako gastuak',
    TOTAL: 'Guztira',
    AMOUNT: 'Zenbatekoa',
    '{{ commission.fees ? \'Fees\' : komisioa.deskribapena }}': '',
    NEXT_COMM_INSTALMENT: 'Eros. hurrengo epea.',
    RECALCULATE_AND_SAVE: 'Birkalkulatu eta gorde',
    CLOSE: 'Itxi',
    INSTALMENT_AT_INCEPTION_DATE: 'Epea, hasiera-datan',
    TAXABLE: 'Zergapegarria',
    INSTALMENT_AT_INCEPTION_DATE_GROSS: 'Epe gordina, hasiera-datan',
    INSURANCE_TAX: 'Aseguruaren gaineko zerga',
    RATE: 'Tarifa',
    TAXES: 'Zergak',
    ANTIRACKET: '(Estortsioaren aurkako borroka barne)',
    NEXT_INSTALMENT: 'Epe berria',
    NEXT_INSTALMENT_GROSS: 'Hurrengo epe gordina',
    '{{questionnaireFlat?.descriptionType}}': '',
    QUOTATION_DETAILS: 'Kotizazioaren inguruko informazioa',
    '{{ premiumDetail.sectionTitle }}': '',
    NET: 'Garbia',
    ACCESSORIES: 'Osagarrizkoak',
    INST_INTEREST: 'Epekako interesa',
    'Damages proposal - Quotation': 'Bizitza-kotizazioa ez den proposamena',
    '{{assetInstance.instanceDescription}}': '',
    PARAMETERS: 'Polizaren parametroak',
    PROPOSAL_N: 'Proposamen-zenbakia',
    AUTHORIZATION_SEND: 'eta baimen-eskaera bidali da',
    SAVING_QUOTE: 'Gorde kotizazioa',
    PROCEDE_TO_CHECKOUT: 'Ordaindu',
    CHECKOUT: 'Ordainketa',
    CODE: 'Kodea',
    ACTION: 'Ekintza',
    NODE_LIST: 'Nodo-zerrenda',
    DRAG_SELECT: 'Arrastatu/Hautatu',
    COMPANY: 'Konpainia',
    POLICY_STATE: 'Polizaren egoera',
    INSURANCE_ASSETS: 'Aseguratutako ondasunak',
    SAVE_QUOTATION: 'Gorde kotizazioa',
    'Damages proposal - Summary': 'Kalteen proposamena - Laburpena',
    PROPOSAL_DATA: 'Proposamenaren datuak',
    OTHER_POLICY_DATA: 'Polizaren beste datu batzuk',
    SYSTEM_MESSAGE: 'SISTEMAREN MEZUA',
    AN_ERROR_OCCURRED: 'Errorea gertatu da; jarri harremanetan bezeroaren arretarako zerbitzuarekin',
    ERROR_DURING_SUBJECT_UPDATE: 'Errorea subjektua eguneratzean',
    QUESTIONNAIRE_NOT_COMPLETE: 'Adi, galdetegia ez da guztiz bete. Jarraitzeko, bete erantzun guztiak',
    THE_CONTRACTOR_CONFIRMS_THE_VALIDITY: 'Kontratistak emandako erantzunak baliozkoak direla berretsi egin du',
    PACKAGE: 'Multzoak',
    POLICY_EXPIRE_DATE: 'Polizaren epemuga',
    NORMALIZE: 'Normalizatu',
    ASSETS_DATA: 'Aseguratutako ondasunaren datuak',
    REPRINT_POLICY_DOCUMENTS: 'Inprimatu berriro polizaren dokumentuak - Polizaren zk.',
    OUTCOME: 'Emaitza',
    AUTHORIZATION_REQUEST: 'Baimen-eskaera',
    ADDRESS: 'Helbideak',
    MAIN: 'Nagusia',
    DESCRIPTION: 'Deskribapena',
    ADD_ADDRESS: 'Gehitu helbidea',
    POLICYHOLDER_COMMUNICATION_ADDRESSES: 'Hartzailearen helbidea',
    POSTAL_MAIL: 'Posta',
    CHANGE_ADDRESS: 'Aldatu helbidea',
    EMAIL: 'Helbide elektronikoa',
    CUSTOMER_RESERVED_AREA: 'Bezeroarentzat erreserbatutako gunea',
    POLICYHOLDER_EMAIL: 'Hartzailearen helbide elektronikoa',
    POLICYHOLDER_RESIDENCE_ADDRESS: 'Hartzailearen bizitegiaren helbidea',
    POLICYHOLDER_DELIVERY_ADDRESS: 'Hartzailearen entrega-helbidea',
    OTHER_ADDRESS: 'Beste helbide batzuk',
    SUBSCRIPTION_INSTALMENT_TAXABLE: 'Zergapeko harpidetza-kuota',
    SUBSCRIPTION_INSTALMENT_GROSS: 'Harpidetza-kuota gordina',
    NEXT_INSTALMENT_TAXABLE: 'Zergapeko hurrengo epea',
    ANNUAL_PREMIUM_TAXABLE: 'Zergapeko urteko prima:',
    ANNUAL_PREMIUM_GROSS: 'Urteko prima gordina:',
    NEW: 'Berria',
    POLICY_CONTACT_ERROR: 'Sartu hartzailearen kontaktu bat gutxienez',
    CLAUSE_DETAIL: 'Klausularen inguruko informazioa',
    SAVE: 'Gorde',
    PRODUCT_CODE: 'Produktu-kodea',
    PRODUCT_DESCRIPTION: 'Produktuaren deskribapena',
    POLICY_EFFECT_DATE: 'Polizaren indarraldiaren data',
    EMPTY: 'Hutsik',
    DEBIT_MEAN_OF_PAYMENT: 'Zordunketa bidez ordaintzeko moduak',
    CREDIT_MEAN_OF_PAYMENT: 'Kreditu bidez ordaintzeko moduak',
    ALTERNATIVE_POLICY_ADRESS: 'Sartu hautazko polizaren helbidea',
    YES: 'Bai',
    MANDATORY_TEXT: 'Klausularen testua derrigorrezkoa da',
    MANDATORY: 'derrigorrezkoa',
    SELECT_PAYMENT_METHOD: 'Hautatu ordaintzeko modua',
    ERROR_ON_DATE: 'Ezin da jarraitu indarraldiaren-datak epemuga baino lehenagokoa izan behar duelako',
    SELECT_CONTRACTOR: 'Hautatu kontratatzailea',
    BENEFICIARIES: 'Onuradunak',
    BENEFICIARY_TYPE: 'Onuradun mota',
    BENEFICIARY: 'Onuraduna',
    PERCENTAGE: 'Ehunekoa',
    THIRD_PARTY_SUBJECT: 'Hirugarren subjektua',
    WARNING_BENEFICIARY_SUBJECT: 'Hautatutako subjektua dagoeneko onuradun gisa erregistratu da; hautatu beste subjektu bat',
    WARNING_BENEFICIARY_THIRD_PERSON: 'Hautatutako subjektua dagoeneko onuradun gisa erregistratu da; hautatu beste subjektu bat',
    WARNING_BENEFICIARY_LIMIT: 'Onuradunen gehieneko kopurura heldu da',
    WARNING_THIRDPERSON_SUBJECT: 'Hautatutako subjektua dagoeneko hirugarren subjektu gisa erregistratu da; hautatu beste subjektu bat',
    USUAL_OCCASIONAL_RESIDENCE: 'OHIKO/NOIZBEHINKAKO BIZILEKUA',
    ANAG_BENEF: 'Onuradunen erregistroa',
    SPOUSE_INSURED: 'Asegurudunaren ezkontidea',
    ERROR_FORMAT_DATE: 'Errorea data-formatuan',
    DATE1_AFTER_DATE2: 'Epemuga indarraldiaren data baino lehenagokoa da',
    SELECT_NODE: 'Hautatu nodoa',
    DATA_CANCELLATION_POLICY: 'Polizaren ezeztapen-data',
    CANCELLATION_REASON: 'Ezeztapenaren arrazoia',
    CANCELLATION_PREMIUM_REPAYMENT: 'Ezeztapenak prima itzultzea aurreikusten du',
    RIGHTS: 'Eskubideak',
    DATA_CANCELLATION: 'Ezeztapen-data',
    CONFIRMATION_CANCELLATION: 'Ezeztapena baieztatzea',
    CANCELLATION_SUMMARY: 'Ezeztapenaren laburpena',
    END: 'Amaiera',
    DOCUMENTS: 'Dokumentuak',
    POLICY: 'Poliza',
    CANCELED_SUCCESSFULLY: 'ezeztatu da',
    CONFIRM_CANCELLATION: 'Ziur poliza ezeztatu nahi duzula',
    CURRENCY: 'Moneta',
    POSTED_NOTICE_DATE: 'Baliogabetze-abisuaren data',
    ISSUED_CORRECTLY: 'jaulki da',
    PREMIUM: 'Prima',
    FEES: 'Tasak',
    SELECT_ASSET: 'HAUTATU ASEGURATUTAKO ONDASUNA',
    ACTIVITY: 'Jarduerak',
    SECONDARY_ACTIVITY: 'Bigarren mailako jarduera',
    ACTIVITY_NOT_FOUND: 'Ez da jarduera aurkitu',
    SEARCH_ACTIVITY_BY: 'Bilatu jarduera honen arabera',
    VALUE: 'Balioa',
    SEARCH: 'Bilatu',
    NO_VALUE: 'Ez dago baliorik',
    PROPOSAL_REGULATION: 'Proposamena erregulatzea',
    REGULATION_EXPIRATION_DATE: 'Erregulazioaren epemuga',
    REGULATION_FRACTIONING: 'Erregulazioa zatikatzea',
    REGULATION_TYPE: 'Erregulazio mota',
    REGULATION_DAYS: 'Erregulazio egunak',
    PERCENTAGE_OF_MIN_QUOTE_PREMIUM: 'Gutxieneko/amaierako kotizazio-primaren %',
    LPS: 'LPS',
    EU_COUNTRY: 'EBko herrialdea',
    EXTRA_EU_COUNTRY: 'EBtik kanpoko herrialdea',
    CHANGE_DATE: 'Aldaketaren data',
    EXCHANGE_RATE: 'Aldaketa mota',
    USE_TAX: 'Erabileraren gaineko zerga',
    CO_INSURANCE: 'Koaseguru',
    CO_INSURANCE_DEP: 'Koaseguru saila',
    OUR_PERCENTAGE: 'Gure ehunekoa',
    QUOTA_PERCENTAGE: 'Kuotaren ehunekoa',
    COMMISSION_PERCENTAGE: 'Komisioaren ehunekoa',
    PERCENTAGE_TOTAL: 'Ehunekoa, guztira',
    PERCENTAGE_ERROR: 'Ehunekoa baliogabea',
    NO_QUOTAS_ERROR: 'Ez da koaseguru-kuotarik sartu',
    LEAD_COINS_ERROR: 'Aseguru-kide nagusiaren guztizko ehunekoak 100 izan behar du',
    POLICY_QUOTAS: 'Poliza-kuotak',
    NEW_QUOTA: 'Koaseguru-kuota berria',
    THIRD_PARTY_QUOTA: 'Asegurudun nagusiaren polizaren datuak',
    QUOTA: 'Kuota',
    INTERMEDIARY: 'Bitartekaria',
    LEADING_INSURER: 'Asegurudun nagusia',
    RECALCULATE_UPDATE: 'BIRKALKULATU ETA EGUNERATU',
    RECALCULATE_DEFAULT_LPS_TAXES: 'BIRKALKULATU LEHENETSITAKO LPS ZERGAK',
    TAX_ANTI_MONEY_LAUNDERING: 'Diru-garbiketaren aurkako zerga',
    COMMON_RISK_POLICIES: 'Arrisku arrunterako poliza',
    COMPLEMENTARY_POLICIES: 'Poliza osagarriak',
    COMPLEMENTARY_POLICY_DATA: 'Poliza osagarriaren datuak',
    SECOND_RISK_POLICIES: 'Bigarren arriskurako poliza',
    LINKED_POLICIES: 'Lotutako polizak',
    NOTES: 'Oharrak',
    INDIRECT_CO_INSURANCE_POLICIES_LIST: 'Zeharkako koaeguruaren polizen zerrenda',
    INDIRECT_CO_INSURANCE_POLICIES: 'Zeharkako koaseguruko polizak',
    SECOND_RISK_POLICIES_LIST: 'Bigarren arriskurako polizen zerrenda',
    ANNUAL_GROSS_PREM_BEFORE_CHARGES: 'Urteko prima (eskubideak kanpo)',
    FLEX_DISCOUNT_WITH_EROSION: 'Malgutasuna deskontu-jaitsierarekin',
    FLEX_DISCOUNT_WITHOUT_EROSION: 'Malgutasuna deskontu-jaitsierarik gabe',
    FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR: 'Deskontu-jaitsieraren ondoriozko zenbatekoa ez da baliozkoa. ',
    FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR: 'Deskontu-jaitsierarik gabeko ondoriozko zenbatekoa ez da baliozkoa. ',
    FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR: 'Deskontu-jaitsieraren ondoriozko ehunekoa ez da baliozkoa. ',
    FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR: 'Deskontu-jaitsierarik gabeko ondoriozko ehunekoa ez da baliozkoa. ',
    FLEX_DISCOUNT_RANGE_ALLOWED_VALUE: 'Baimendutako balioen tartea: ',
    FLEX_DISCOUNT_MAX_100: 'Sartu hurrengoa baino txikiagoa den balioa: ',
    DISCOUNT: 'deskontua',
    RESIDUAL_DISCOUNT: 'Geratzen den deskontua',
    SHARED_DISCOUNTS_AMOUNT: 'Partekatu deskontuak',
    DISCOUNT_TYPE: 'Deskontu motak',
    PERIOD: 'Aldia',
    RESIDUAL_AMOUNT: 'Geratzen den zenbatekoa',
    NO: 'Ez',
    START: 'Hasiera',
    LIMIT: 'Muga',
    ADD_POLICY: 'Gehitu poliza',
    INPUT_PREMIUM: 'Sarrera-prima',
    QUOT_COMMISSIONS: {
      LABEL: {
        ACCESSORIES: 'Osagarriak',
        AUTHORITY_LIMIT: 'Aginte-muga',
        ALL_RISK: 'Arrisku orotakoa',
        ACQUIRED_CHARGES: 'Eskuratutako karguak',
        ACQUIRED_COMMISSION: 'Eskuratutako komisioa',
        AMOUNT: 'Zenbatekoa',
        ANNUAL_PREMIUM: 'Urteko prima',
        ANTIRACKET: 'Diru-garbiketaren aurkako zerga',
        COLLECTED_CHARGES: 'Kobratutako karguak',
        COLLECTED_COMMISSION_NET: 'Kobratutako komisioa (garbia)',
        COMMISSIONS: 'Komisioak',
        CONTRIBUTIONS_TAXES: 'Kontribuzioak eta zergak',
        GROSS: 'Gordina',
        INCEPTION_INSTALLMENT: 'Hasierako kuota',
        INSTALLMENT_INTEREST: 'Kuotaren interesa',
        INSTALLMENT_PREMIUM: 'Kuotaren prima',
        INSURANCE_TAX: 'Aseguruaren zerga',
        NET: 'Garbia',
        PREMIUM_DETAIL: 'Primaren xehetasunak',
        RATE: 'Tasa',
        SIGNED_COMMISSIONS: 'Komisioak, hasiera-datan',
        DEROGATED: 'Indargabetuta',
        NEXT_INSTALMENT_COMMISSIONS: 'Kuotaren hurrengo komisioa',
        RIGHTS: 'Eskubideak',
        SSN: 'SSN',
        TAXABLE: 'Zergapetugarria',
        TAXES: 'Zergak',
        TOTAL: 'Guztira',
        VIEW: 'Ikusi',
        ASSIGN: 'Esleitu',
        ACQUIRED_COMMISSIONS_MANDATE: 'Eskuratutako komisioen mandatua'
      }
    },
    FPS_LABEL: {
      NEXT_INSTALMENT: 'Hurrengo kuota',
      INCEPTION_INSTALLMENT: 'Hasierako kuota',
    },
    INDEXATION: 'Indexazioa',
    QUOTA_ALREADY_EXISTING: 'Kuota dagoeneko existitzen da',
    CO_POLICYHOLDER_SUBSCRIBER_ERROR: 'Titularkideak polizaren titularra ez den alderdia izan behar du',
    CO_POLICYHOLDER_JOINT_SUBSCRIBER_ERROR: 'Titularkideak beste titularkideak ez diren alderdia izan behar du',
    COMMON_RISK_POLICIES_LIST: 'Arrisku arrunterako polizen zerrenda',
    COMMON_POLICY_DATA: 'Polizaren datu arruntak',
    LINKED_POLICIES_LIST: 'Lotutako polizen zerrenda',
    VALID_POLICY: 'Poliza baliozkoa',
    INVALID_POLICY: 'Poliza baliogabea',
    INVALID_POLICY_NUMBER: 'Poliza-zenbakiaren formatua baliogabea da',
    POLICY_ALREADY_ADDED : 'Poliza dagoeneko gehitu da',
    NO_POLICY_ADDED : 'Ez da polizarik gehitu',
    AMOUNT_NOT_VALID: 'Zenbatekoa ez da baliozkoa',
    GENERIC_SYSTEM_ERROR: 'Zerbitzariaren barneko errorea',
    SAVE_AND_CLOSE: 'Gorde eta itxi'
  }
};

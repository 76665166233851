<ng-container [formGroup]="guaranteesForm" *ngIf="stateGuarantees">
  <rgi-gp-stepper (targetRoute)="callActionBack($event)" class="rgi-gp-mb-l"></rgi-gp-stepper>
  <ng-container *ngIf="stateGuarantees.quotations && (stateGuarantees.quotations | json) != '{}'">
    <rgi-gp-group-policy-quotations-component [quotations]="stateGuarantees.quotations">
    </rgi-gp-group-policy-quotations-component>
  </ng-container>

  <br>

  <rgi-gp-assets *ngIf="stateGuarantees.assets && stateGuarantees.assets.length"
                 [quotations]="stateGuarantees.quotations?.quotationGuaranteeDetails?.firstInstallment?.quotationGuarantees"
                 [assets]="stateGuarantees.assets"
                 [assetsClauses]="stateGuarantees.assetUnitClauses"
                 [accordionMap]="accordionMap"
                 [parentForm]="guaranteesForm"
                 [unitInitialStatus]="stateGuarantees.unitInitialState"
                 (updateAsset)="actionUpdateAsset($event)"
                 (deleteAsset)="actionDeleteAsset($event)"
                 (updateClauses)="actionUpdateClauses($event)"
                 (reloadAssets)="reloadAssets($event)">
  </rgi-gp-assets>

  <ng-container *ngIf="stateGuarantees.questionnaires && stateGuarantees.questionnaires.length">

    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-document"></span>
      <b translate>_GP_._TITLE_._QUESTIONNAIRES_</b>
    </div>
    <rgi-gp-group-policy-questionnaires-component [questionnaires]="stateGuarantees.questionnaires">
    </rgi-gp-group-policy-questionnaires-component>

  </ng-container>


  <div *ngIf="stateGuarantees.errors && stateGuarantees.errors.length" data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="stateGuarantees.errors"
                                          [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button rgi-rx-button
            color="secondary"
            (click)="callActionBack()"
            translate>{{'_GP_._BTN_._BACK_'}}
    </button>
    <button rgi-rx-button color="primary"
            *ngIf="!!stateGuarantees.availableAssets && stateGuarantees.availableAssets.length"
            (click)="openAssetsModal()"
            data-qa="rgi-gp-add-asset"
            translate>{{'_GP_._BTN_._ADD_ASSET_'}}
    </button>
    <button rgi-rx-button
            *ngIf="isCommissionsBtnVisible"
            color="secondary"
            [disabled]="!isCommissionBtnEnabled"
            (click)="openCommissionsModal()"
            data-qa="rgi-gp-commissions-btn" translate>{{commissionsBtnLabel}}
    </button>
    <button rgi-rx-button
            *ngIf="isDiscountsBtnVisible"
            color="secondary"
            [disabled]="!isDiscountsBtnEnabled"
            (click)="openDiscountsModal()"
            data-qa="rgi-gp-discounts-btn" translate>{{discountsBtnLabel}}
    </button>
    <button rgi-rx-button
            color="primary"
            *ngIf="evalIntermediateSaveVisibility()"
            [disabled]="guaranteesForm.invalid || !activeButton || !atLeastOneUnitSelectedForEachAsset"
            (click)="callActionSave()" data-qa="buttonsSave"
            translate>{{'_GP_._BTN_._SAVE_'}}
    </button>
    <button rgi-rx-button
            color="secondary"
            [disabled]="guaranteesForm.invalid || !activeButton || !atLeastOneUnitSelectedForEachAsset"
            (click)="callActionGoToSummary()" data-qa="buttonsContinue"
            translate>{{'_GP_._BTN_._CONTINUE_'}}
    </button>
  </div>

</ng-container>

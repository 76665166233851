import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PotentialClaimData } from './model/domain/potential-claim-data';
import { ExternalPolicy } from '../../../external-portfolio/dto/external-policy';
import { SimilarclaimsCriteriaEntity } from './dto/similarclaims-criteria-entity';

@Injectable({
  providedIn: 'root'
})
export class ClaimOpeningPolicySearchResultsService {

  private baseApiUrl: string;
  private urlservice = '/claims/potentialclaim';
  private urlUnitsProductBin = '/claims/unitsProductBin';
  private urlPolicyDataPtfExt = '/claims/policyDataPtfExt';
  private urlSimilarClaims = '/claims/similarClaims';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  initPotentialClaim(input: PotentialClaimData) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice, input);
  }

  unitsProductBin(codProduct: string, codCategory: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlUnitsProductBin +
     '?' + 'codProduct=' + codProduct +
     '&' + 'categoryCode=' + codCategory);
  }

  policyDataPtfExt(extPolicy: ExternalPolicy, incidentDateStr: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlPolicyDataPtfExt +
     '?' + 'incidentDateStr=' + incidentDateStr, extPolicy);
  }

  similarClaims(criteria: SimilarclaimsCriteriaEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlSimilarClaims , criteria);
  }
}

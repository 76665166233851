import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxMultiselectComponent} from './rgi-rx-multiselect.component';
import {RgiRxFormElementsModule} from '../form-elements/rgi-rx-form-elements.module';
import {RgiRxAutoCompleteModule} from '../auto-complete/rgi-rx-auto-complete.module';
import {FormsModule} from '@angular/forms';
import {RgiRxMultiselectContentDirective} from './rgi-rx-multiselect-content.directive';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {RgiRxMultiselectSelectedCounterDirective} from './rgi-rx-multiselect-selected-counter.directive';
import {RgiRxMultiselectRemoveTriggerDirective} from './rgi-rx-multiselect-remove-trigger-for.directive';
import {RgiRxMultiselectInputContentDirective} from './rgi-rx-multiselect-input-content.directive';
import {RgiRxBadgeModule} from '../badge/rgi-rx-badge.module';
import {RGI_RX_MULTISELECT_TRANSLATIONS} from './multiselect.i18n';
import {A11yModule} from '@angular/cdk/a11y';
import { RgiRxQualityAssuranceModule } from '@rgi/rx';
import {RgiRxSdkObserverModule} from '@rgi/rx/sdk';

@NgModule({
  declarations: [
    RgiRxMultiselectComponent,
    RgiRxMultiselectContentDirective,
    RgiRxMultiselectInputContentDirective,
    RgiRxMultiselectRemoveTriggerDirective,
    RgiRxMultiselectSelectedCounterDirective,
  ],
  imports: [
    CommonModule,
    RgiRxFormElementsModule,
    RgiRxAutoCompleteModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(RGI_RX_MULTISELECT_TRANSLATIONS),
    FormsModule,
    RgiRxFormElementsModule,
    RgiRxBadgeModule,
    RgiRxQualityAssuranceModule,
    A11yModule,
    RgiRxSdkObserverModule,
  ],
  exports: [
    RgiRxMultiselectComponent,
    RgiRxMultiselectContentDirective,
    RgiRxMultiselectInputContentDirective,
    RgiRxMultiselectRemoveTriggerDirective,
  ],
})
export class RgiRxMultiselectModule {
}

<ng-container [formGroup]="configurationPmForm" *ngIf="stateConfigurationPm">
  <rgi-gp-stepper (targetRoute)="callActionBack($event)"></rgi-gp-stepper>
  <br>
  <div *ngFor="let accordion of accordions" class="rgi-gp-mb-1">
    <rgi-rx-expansion-panel [expanded]="true">
      <div rgi-rx-expansion-panel-header>
        <div rgi-rx-expansion-panel-label>
          <span translate>{{accordion.label}}</span>
        </div>
        <ng-template rgiRxExpansionPanelContent>
          <rgi-gp-group-policy-factors-component
              [factors]="accordion.variables"
              [parentForm]="configurationPmForm"
              (updateFactors)="updateFactors($event)"
              class="rgi-gp-flex-row">
          </rgi-gp-group-policy-factors-component>
        </ng-template>
      </div>
    </rgi-rx-expansion-panel>
  </div>

  <div *ngIf="stateConfigurationPm.errors && stateConfigurationPm.errors.length" data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component
        [errors]="stateConfigurationPm.errors"
        [type]="stateConfigurationPm.type"
        [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button
        rgi-rx-button
        color="secondary"
        (click)="callActionBack()"
        data-qa="buttonsBack" translate>{{'_GP_._BTN_._BACK_'}}
    </button>
    <button
        rgi-rx-button
        color="primary"
        *ngIf="evalIntermediateSaveVisibility()"
        [disabled]="configurationPmForm.invalid"
        (click)="callActionSave()"
        data-qa="buttonsSave" translate>{{'_GP_._BTN_._SAVE_'}}
    </button>
    <button
        rgi-rx-button
        color="secondary"
        [disabled]="configurationPmForm.invalid"
        (click)="callActionGoToGuarantees()"
        data-qa="buttonsContinue" translate>{{'_GP_._BTN_._CONTINUE_'}}
    </button>
  </div>

</ng-container>

import {ModalComponent} from '@rgi/portal-ng-core';
import {downgradeComponent} from '@angular/upgrade/static';
import {MotorIssueCardComponent} from '../motor-issue-card/motor-issue-card.component';
// @ts-ignore
declare var angular: angular.IAngularStatic;

export function AjsConfigurationMotor() {
  angular.module('core').config([
    'eventServiceProvider',
    evProvider => {
      evProvider.registerEvent('gettextLanguageChanged', { type: 'i18n' });
    }
  ]);

  registerMotorIssueCard();
  registerPortfolioController();
 // registerModule();



  // downgradeModalComponent();
  angular.module('app')
      .directive(
          'pncModal',
          downgradeComponent({ component: ModalComponent })
      );

  // *********************************** */

  // function registerModule() {
  //   const modules = angular.module('app').requires;
  //   modules.push('new-motor-flow');
  //   modules.push('angular-comm-card-controllers-portfolio');
  //   angular.module('app', modules);
  // }

  // Register new motor issue card in angularJs
  function registerMotorIssueCard() {
    const motorModule = angular.module('app');
    motorModule.config(['gettext', 'cardManagementServiceProvider', (gettext, cardManagementServiceProvider) => {
      const motorIssueCard = {
        title: gettext('P&C Motor Flow'),
        tpl: '',
        name: 'newMotorContract',
        category: gettext('Contract Management'),
        type: 'main',
        subCards: [],
        subCardsActive: [],
        functions: ['newMotorFlow'],
        navigationInSession: false,
        customize: {
          enabled: false,
          hideHeader: false,
          hideContainerBody: true
        },
      };
      cardManagementServiceProvider.register(motorIssueCard);
    }]);

    // register home route for the angularjs card
    console.log('registering module new motor issue default route');
    motorModule.config(['parallelRoutingProvider', pRouter => {
      pRouter.when('newMotorContract', 'home', {
        template: 'assets/newMotorContract.tpl.html',
        label: 'Home - P&amp;C Motor Flow',
        type: 'home'
      });
    }]);

    // downgrade angular component
    angular.module('app')
        .directive(
            'micMotorIssueCard',
            downgradeComponent({component: MotorIssueCardComponent})
        );
  }

  function registerPortfolioController() {
    const cardController = angular.module('app');

    cardController.controller('AngularCommunicationPortfolioCtrl', AngularCommunicationPortfolioCtrl);
    AngularCommunicationPortfolioCtrl.$inject = ['$log', '$scope', '$controller', '$uibModal', 'eventService',
      'sessionService', 'CONTAINER_EVENT', 'gettext', 'coreResult', 'SESSION_EVENT', 'partyCoverter',
      'securitiesFinderFilterService', 'accountingSecuritiesJumpServices'];

    function AngularCommunicationPortfolioCtrl($log, $scope, $controller, $uibModal, eventService,
                                               sessionService, CONTAINER_EVENT, gettext, coreResult, SESSION_EVENT,
                                               partyCoverter, securitiesFinderFilterService, accountingSecuritiesJumpServices) {

      $controller('AngularCommunicationCoreCtrl', {$scope});
      $controller('AngularCommunicationPartyCtrl', {$scope});

      $scope.contractNumber = {};
      $scope.quoteRef = {};
      $scope.masterpolicyRef = {};
      $scope.authorization = {};

      /* Save party data for jump from anagCard */
      const partyData = coreResult.getResultSimple('partyData');
      if (partyData) {
        coreResult.setResult($scope.card.idSession, 'party', partyData.data);
        coreResult.deleteResultSimple('partyData');
      }

      if ($scope.card.integrationPass) {
        // kept for retro compatibility
        $scope.contractNumber.proposalNumber = $scope.card.integrationPass.proposalNumber;
        $scope.contractNumber.policyNumber =  $scope.card.integrationPass.policyNumber;
        $scope.contractNumber.createCopy =  $scope.card.integrationPass.createCopy;
        $scope.contractNumber.loadContract = $scope.card.integrationPass.loadContract;
        // used obj contractNumber
        if ($scope.card.integrationPass.contractNumber) {
          $scope.contractNumber = $scope.card.integrationPass.contractNumber;
        }
        $scope.quoteRef.quoteNumber = $scope.card.integrationPass.quoteNumber;
        $scope.quoteRef.editQuote = $scope.card.integrationPass.editQuote;
        $scope.masterpolicyRef = $scope.card.integrationPass.masterpolicyRef;
        $scope.resourceId = $scope.card.integrationPass.resourceId;
        $scope.action = $scope.card.integrationPass.action;
        $scope.authorization = $scope.card.integrationPass.authorization;
        $scope.card.integrationPass.naviJumpEnd = true;
      }

      $scope.wrapPortfolioEvent = event => {
        $scope.wrapCoreEvent(event);
        $scope.wrapPartyEvent(event);

        if (event.eventName && event.eventName === 'collectsAccounting') {
          collectsAccounting(event.policyNumber);
        } else if (event.eventName === 'backToContractDetail') {
          backToContractDetail();
        }
      };

      function backToContractDetail() {
        const ptfAllFinderSession = sessionService.list().find(session => session.name === 'ptfallFinder');
        const currentSession = sessionService.list().find(session => session.id === $scope.id);
        sessionService.remove(currentSession.idSession);
        if (ptfAllFinderSession) {
          sessionService.setActive(ptfAllFinderSession.idSession);
        }
      }

      function collectsAccounting(policyNumber) {
        const promise = securitiesFinderFilterService.find({
          policyNumber
        });
        promise.then(result => {
          if (result && result.output && result.output[0]) {
            openCollectsAccounting(result.output[0]);
          }
        });
      }

      function openCollectsAccounting(security) {
        const payment = securitiesFinderFilterService.getSecurityCollectionAction(security);
        const paymentFun = payment.jumpFunction;
        const subFunction = payment.subFunction;

        const modalInstance = $uibModal.open({
          templateUrl: 'accounting/cardsecuritiesmanagement/partials/accounting-card-modal.tpl.html',
          controller: 'ModalTitleCtrl',
          size: 'size-payment',
          resolve: {
            policyNumber: () => security.policyNumber,
            security: () => security,
            paymentFun: () => paymentFun,
            subFunction: () => subFunction
          }
        });

        modalInstance.result.then(moduleData => {
          const objIntegrationPass = accountingSecuritiesJumpServices.getObjIntegrationSecuritiesPlaceToModule(
            security,
            moduleData.company,
            moduleData.agencyID
          );
          // @ts-ignore
          cardPassService.createJump($scope.card, objIntegrationPass, $scope.card.title, 'finder', 'securitiesmanagement');
          $scope.reloadParent();
        });
      }

    }
  }
}

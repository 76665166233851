import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EvaluatorObjectI, EvaluatorProductI, EvaluatorSectionI} from '../../../../evaluation/models';
import {
  EvalValidationProduct,
  SurveyEvaluateState,
  SurveyEvaluateStateManagerService } from '../../../../state/state-manager-survey.module';
import { EvalProductCfg } from '../../../../state/models/eval-product-cfg';
import {createProductsSectionMapId} from '../../../../state/tools/survey-eval-products-cfg.service';
// tslint:disable
@Component({
  selector: 'survey-eval-product',
  templateUrl: './survey-eval-product.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SurveyEvalProductComponent {

  @Input('product') product: EvaluatorProductI;
  @Input('editable') isEdit: boolean = false;
  @Input() validation: EvalValidationProduct;
  @Input() productCfg: EvalProductCfg;

  @Output('clickInfoCoverage') onClickInfoCoverageEmitter = new EventEmitter<{covOrPkg:EvaluatorObjectI, isPackage:boolean}>();
  @Output('clickInfoProduct') onClickInfoProductEmitter = new EventEmitter<{product:EvaluatorProductI}>();
  @Output('select') onSelectEmitter = new EventEmitter<EvaluatorProductI>();


  constructor(private _stMng: SurveyEvaluateStateManagerService<SurveyEvaluateState>) {}


  onClickInfoCoverage({covOrPkg, isPackage}) {
    const eventData = {
      covOrPkg, isPackage
    };
    this.onClickInfoCoverageEmitter.emit(eventData);
  }


  onSelectCoverage(data: {covOrPkg: EvaluatorObjectI, isPackage: boolean, selected: boolean}) {
    this._stMng.actionSelectCoverage(data.covOrPkg, this.product, data.isPackage, data.selected);
  }


  getCovsOrPksBySectionId(sectionId: string): EvaluatorObjectI[] {
    return this.productCfg.sectionsCfg.map[sectionId].isPackage ?
        this.product.packages
        :
        this.product.sections.find( section => createProductsSectionMapId(this.product, section) == sectionId ).coverages;
    }

  isSelected() {
    return !!this.product.selected;
  }

}

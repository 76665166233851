import { PlcQuotationUtils } from './../../utils/plc-quotation-utils';
import {
  Component, forwardRef, Input, OnInit, Output, EventEmitter,
  OnDestroy, OnChanges, SimpleChanges, ViewEncapsulation, Inject, Optional } from '@angular/core';
import { Risk } from '../../models/postsales-operations-response.model';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { Factor, RequestFactor } from '../../models/factor.model';
import { Subscription } from 'rxjs';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';
import {ProductTypeCodes} from '../../models/enums/vita.enum';


interface UnitOpen {
  open: boolean;
  subUnits: Map<string, boolean>;
}

@Component({
  selector: 'lpc-unit-table',
  templateUrl: './lpc-unit-table.component.html',
  styleUrls: ['./lpc-unit-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcUnitTableComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcUnitTableComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcUnitTableComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges, Validator {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() risks: Risk[];
  @Input() showInsuredAmount = true;
  @Input() investedAmountOnQuotation: number;
  @Input() showFirstInstallment = true;
  @Input() openSelectedUnits = false;
  @Input() productTypeCode: ProductTypeCodes = null;
  @Output() saveSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateUnitTable = new EventEmitter<any>();

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    risks: new UntypedFormArray([])
  });
  public showFactors = false;
  public requestFactor: RequestFactor[] = [];
  public factorsForRisk = new Map<string, RequestFactor[]>();
  private emitSaveSelection = false;
  public numCol = 5;

  private $subscriptions: Subscription[] = [];
  public get formGroupValue(): { [key: string]: any } {
    return this.formGroup.getRawValue();
  }
  private $openUnits: Map<string, any> = new Map<string, any>();
  public get openUnits(): Map<string, any> {
    return this.$openUnits;
  }

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.numCol = this.initializeNumOfCols();
    if (!!this.risks) {
      this.risks.forEach((risk, index) => {
        (this.formGroup.get('risks') as UntypedFormArray).push(new UntypedFormGroup({
          code: new UntypedFormControl(risk.code),
          selected: new UntypedFormControl(risk.selected),
          subRisks: new UntypedFormArray([]),
          factors: new UntypedFormGroup({})
        }));
        if (!!risk.subRisks) {
          risk.subRisks.forEach((subrisk, i) => {
            (this.formGroup.get('risks').get(index.toString()).get('subRisks') as UntypedFormArray)
              .push(new UntypedFormGroup({
                code: new UntypedFormControl(subrisk.code),
                selected: new UntypedFormControl(subrisk.selected),
                factors: new UntypedFormGroup({})
              }));
            this.$subscriptions.push(this.formGroup.get('risks')
              .get(index.toString())
              .get('subRisks')
              .get(i.toString())
              .get('selected').valueChanges.subscribe(el => {
                this.emitSaveSelection = true;
              }));
          });
        }
      });
    }
    this.$subscriptions.push(this.formGroup.valueChanges.subscribe((val) => {
      this.updateValues(val);
      if (this.emitSaveSelection) {
        this.saveSelection.emit();
        this.emitSaveSelection = false;
      }
    }));
  }

  protected initializeNumOfCols(): number {
    return this.showFirstInstallment ? 5 : 4;
  }

  validate(control: AbstractControl): { [key: string]: boolean } | null {
    const errors: { [key: string]: boolean } = {};
    const risks: UntypedFormArray = this.formGroup.get('risks') as UntypedFormArray;
    let subrisks: UntypedFormArray = new UntypedFormArray([]);
    Object.keys(risks.controls).forEach(risk => {
      if (!!risks.get(risk).get('selected').value && !this.isFormValid(risks.get(risk).get('factors'))) {
        Object.assign(errors, { factorMissing: true });
      }
      subrisks = risks.get(risk).get('subRisks') as UntypedFormArray;
      Object.keys(subrisks.controls).forEach(subrisk => {
        if (!!subrisks.get(subrisk).get('selected').value && !this.isFormValid(subrisks.get(subrisk).get('factors'))) {
          Object.assign(errors, { factorMissing: true });
        }
      });
    });
    if (!!Object.keys(errors).length) {
      return errors;
    }
    return null;
  }

  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

  hasFactorsBy(risk: Risk): boolean {
    if (!!risk.factors) {
      return !!risk.factors.length;
    }
  }

  openUnitBody(risk: Risk, subRisk: Risk = null) {
    if (subRisk) {
      if (risk.selected && subRisk.selected) {
        (this.$openUnits.get(risk.code) as UnitOpen).subUnits.set(subRisk.code, true);
      }
    } else {
      (this.$openUnits.get(risk.code) as UnitOpen).open = true;
    }
  }
  closeUnitBody(risk: Risk, subRisk: Risk = null) {
    if (subRisk) {
      if (risk.selected && subRisk.selected) {
        (this.$openUnits.get(risk.code) as UnitOpen).subUnits.set(subRisk.code, false);
      }
    } else {
      (this.$openUnits.get(risk.code) as UnitOpen).open = false;
    }
  }


  isUnitBodyOpen(risk: Risk, subRisk: Risk = null) {
    if (subRisk) {
      return (this.$openUnits.get(risk.code) as UnitOpen).subUnits.get(subRisk.code).valueOf();
    } else {
      return (this.$openUnits.get(risk.code) as UnitOpen).open;
    }
  }


  public isLocked(risk: Risk): boolean {
    return !risk.modifiable && risk.selected;
  }

  private getInitialOpenStructure(risks: Risk[]): Map<string, any> {
    const openStructure = new Map<string, any>();
    risks.map((risk: Risk) => {
      openStructure.set(risk.code, {
        open: this.determinateIfUnitIsOpen(risk),
        subUnits: new Map<string, boolean>()
      });
      if (!!risk.subRisks) {
        risk.subRisks.forEach(subRisk => {
          (openStructure.get(risk.code) as UnitOpen).subUnits.set(subRisk.code,
            this.determinateIfUnitIsOpen(subRisk)
          );
        });
      }
    });
    return openStructure;
  }

  determinateIfUnitIsOpen(risk: Risk) {
    return risk.selected && (
      (this.openSelectedUnits && risk.factors && risk.factors.length > 0)
      || (this.isAnyFactorCompulsoryAndEnabled(risk.factors) && risk.selected)
    );
  }

  private isAnyFactorCompulsoryAndEnabled(factors: Factor[]): boolean {
    return !!factors ? factors.some(factor => factor.compulsory && factor.modifiable) : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.risks) {
      const currentValues: Risk[] = changes.risks.currentValue;
      this.$openUnits = this.getInitialOpenStructure(currentValues);
      /* currentValues.forEach((risk, i) => {
        if (!risk.modifiable) {
          this.removeFactors(i.toString(), risk);
        }
      }); */

    }
  }

  updateValues(val) {
    if (!!val.risks) {
      val.risks.map((value: Risk) => {
        const factors = this.factorsForRisk.get(value.code);
        if (!!value.factors && Object.keys(value.factors).length === 0) {
          value.factors = [];
        } else {
          if (!Array.isArray(value.factors)) {
            value.factors = this.getRiskFactors(factors, value, this.factorsForRisk);
            // this.factorsForRisk.size !== 0 ? !!factors ? factors : [] : this.convertFactors(value.factors);
          }
        }
        if (!!value.subRisks) {
          value.subRisks.map(subrisk => {
            const subFactors = this.factorsForRisk.get(subrisk.code);
            if (!!subrisk.factors) {
              if (Object.keys(subrisk.factors).length === 0) {
                subrisk.factors = [];
              } else {
                if (!Array.isArray(subrisk.factors)) {
                  subrisk.factors = this.getRiskFactors(subFactors, subrisk, this.factorsForRisk);
                  // this.factorsForRisk.size !== 0 ? !!subFactors ? subFactors : [] : this.convertFactors(subrisk.factors);
                }
              }
            }
          });
        }
      });
    }
    this.writeValue(val);
    this.onChange(val);
  }


  private getRiskFactors(factors: RequestFactor[], risk: Risk, factorsForRisk: Map<string, RequestFactor[]>) {
    if (factorsForRisk.size !== 0) {
      return !!factors ? factors : [];
    } else {
      return PlcQuotationUtils.convertFactors(risk.factors);
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(el => el.unsubscribe());
  }


  getRiskFactorForm(i: string, j: string = null) {
    if (j == null) {
      return this.formGroup.controls.risks.get(i.toString()).get('factors');
    } else {
      return this.formGroup.controls.risks.get(i.toString()).get('subRisks').get(j.toString()).get('factors');
    }
  }

  updateFactors(factors: RequestFactor[], code: string) {
    this.factorsForRisk.set(code, factors);
    this.updateValues(this.formGroupValue);
    this.updateUnitTable.emit(this.factorsForRisk);
  }

  buttonVisibilityForRisk(i) {
    return this.formGroup.controls.risks.get(i.toString()).get('selected').value === true;
  }

  buttonVisibilityForSubrisk(i, j) {
    return this.formGroup.controls.risks.get(i.toString()).get('subRisks').get(j.toString()).get('selected').value === true;
  }

  openFactors(i: string) {
    if (document.getElementById(i).style.display === 'contents') {
      document.getElementById(i).style.display = 'none';
    } else {
      document.getElementById(i).style.display = 'contents';
    }
  }

  removeFactors(i: string, risk: Risk) {
    if (!risk.modifiable) {
      return false;
    }
  }

  onChange(obj: Risk[]) { }

  writeValue(obj: Risk[]): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void {
  }

  shouldShowInsuredAmount(unitType: string): boolean {
    if (this.productTypeCode === ProductTypeCodes.TCM || this.productTypeCode === ProductTypeCodes.CREDITPROTECTIONINS) {
      return this.showInsuredAmount;
    } else if ( unitType === "base") {
      return false;
    } else {
      return this.showInsuredAmount;
    }
  }
}

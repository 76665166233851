import {
    SURVEY_BACK,
    SURVEY_EVALUATION_BACK,
    SURVEY_CHANGE_QUESTIONNAIRE_SELECTION,
    SURVEY_SAVED,
    SURVEY_SAVE_ERROR,
    SURVEY_SELECT_PRODUCT_EVALUATED,
    SURVEY_EVALUATION_ERROR
} from "./survey/model/survey-lifecycle";
import { Events } from '@rgi/rx';

export const SURVEY_EVENTS: Events = [SURVEY_SAVED, SURVEY_SAVE_ERROR, SURVEY_CHANGE_QUESTIONNAIRE_SELECTION, SURVEY_SELECT_PRODUCT_EVALUATED, SURVEY_BACK, SURVEY_EVALUATION_ERROR, SURVEY_EVALUATION_BACK];

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="loanRefund" label="lpc_loans" [errors]="errors" fieldId="loanRefund" (next)="onNext($event)">
    <div *ngIf="!!loanDefinitions.length" >
      <lpc-loan-refund-table
        [loanDefinitions]="loanDefinitions"
        [prevalorizedLoan]="operationDataLoans"
        [formControlName]="'loanRefund'"
        #loanRefundTable>
      </lpc-loan-refund-table>
    </div>
  </lpc-step>

  <lpc-step
    id="quotation"
    label="lpc_quotation_step"
    [errors]="errors"
    fieldId="quotation"
    [enableAuth]="isAuth"
    (confirmAccept)="onNext($event, true, null, true)"
    [isConfirmAndAccept]="isConfirmAndAccept"
    (next)="onNext($event, true)">

    <lpc-formule-quotation-table
        [label]="'Loan data'"
        [definitions]="quotationTableDefinitions"
        [operationCode]="session.operation">
    </lpc-formule-quotation-table>

  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
            (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

<rgi-rx-datatable [data]="applicationsTableData"
                  [schema]="APPLICATIONS_LIST_TABLE_SCHEMA"
                  [selectable]="false"
                  [expansionRow]="applicationDetailRow"
                  (onAction)="onActionClick($event)">
</rgi-rx-datatable>
<ng-template #applicationDetailRow let-applicationRow>
  <div class="rgi-ui-form-group">
    <div class="rgi-gp-w-33">
            <span class="rgi-ui-title-3"
                  translate>_GP_._APPLICATIONS_._MANAGEMENT_NODE_</span>:&nbsp;&nbsp;<span>{{applicationRow.managementNodeDescription}}</span>
    </div>
    <div class="rgi-gp-w-33">
            <span class="rgi-ui-title-3"
                  translate>_GP_._APPLICATIONS_._PRODUCT_DESCRIPTION_</span>:&nbsp;&nbsp;<span>{{applicationRow.productDescription}}</span>
    </div>
  </div>
  <div class="rgi-ui-form-group">
    <div class="rgi-gp-w-33">
            <span class="rgi-ui-title-3"
                  translate>_GP_._APPLICATIONS_._ISSUE_DATE_</span>:&nbsp;&nbsp;<span>{{applicationRow.issueDate | gpDate}}</span>
    </div>
    <div class="rgi-gp-w-33">
            <span class="rgi-ui-title-3"
                  translate>_GP_._APPLICATIONS_._EFFECTIVE_DATE_</span>:&nbsp;&nbsp;<span>{{applicationRow.effectiveDate | gpDate}}</span>
    </div>
    <div class="rgi-gp-w-33">
            <span class="rgi-ui-title-3"
                  translate>_GP_._APPLICATIONS_._EXPIRY_DATE_</span>:&nbsp;&nbsp;<span>{{applicationRow.expirationDate | gpDate}}</span>
    </div>
  </div>
</ng-template>

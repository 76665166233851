import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PolicyOperationPayload} from '../../../models/policy-operations';
import {ContractTypeEnum} from '../../../enumerations/contract-type.enum';
import {ContractDetails} from '../../../models/details/contract-details';
import {DATEFORMAT} from '../../../constants/consultation-utility';

@Component({
  selector: 'administrative-data',
  templateUrl: './administrative-data.component.html',
  styleUrls: ['./administrative-data.component.scss']
})
export class AdministrativeDataComponent {
  @Input() contract: ContractDetails;
  @Output() openActionModal = new EventEmitter();
  @Output() backEmitter = new EventEmitter();
  protected readonly DATEFORMAT = DATEFORMAT;

  constructor() {
  }


  transformBoolean(value: boolean): string {
    return value ? 'CONTRACT_CONSULTATION.YES' : 'CONTRACT_CONSULTATION.NO';
  }

  public getPolicyOperations(){
    const contractType = this.contract.policy ? ContractTypeEnum.POLICIES : this.contract.numProposal ? ContractTypeEnum.PROPOSALS : null;
    const policyDataOperationInput = new PolicyOperationPayload();
    if(this.contract.numPolicy || this.contract.numProposal) {
      policyDataOperationInput.productionNodeCode = this.contract.managementNode.code;
      policyDataOperationInput.authenticationNodeCode = this.contract.managementNode.code;

      policyDataOperationInput.objectId = {
        code: this.contract.numPolicy ? this.contract.numPolicy : this.contract.numProposal,
        description: contractType
      };
      this.openActionModal.emit(policyDataOperationInput);
    }

  }
  back(){
    this.backEmitter.emit();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcStepperComponent} from './component/lpc-stepper/lpc-stepper.component';
import {LpcStepComponent} from './component/lpc-step/lpc-step.component';

import {LpcNavigationButtonsModule} from '../lpc-navigation-buttons/lpc-navigation-buttons.module';
import {LpcErrorMessagesModule} from '../lpc-error-messages/lpc-error-messages.module';
import { ExtractFromArrayPipeModule } from '../../utils/pipe/extract-from-array/extract-from-array.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcStepperComponent, LpcStepComponent],
    exports: [
        LpcStepperComponent,
        LpcStepComponent
    ],
    imports: [
        CommonModule,
        LpcNavigationButtonsModule,
        LpcErrorMessagesModule,
        ExtractFromArrayPipeModule,
        RgiRxI18nModule
    ],
    providers: [{ provide: 'enableAnimation', useValue: true }]
})
export class LpcStepperModule { }

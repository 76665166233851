import {Directive, Input, TemplateRef} from '@angular/core';
import {AnswerContainerI} from "./models";
import {DynamicAnswerWhenPredicate} from "./questionnaire.api";

@Directive({
  selector: 'ng-template[ngPassProCustomAnswer]'
})
export class CustomAnswerDirective {

  constructor(public template: TemplateRef<any>) { }


  private _when: DynamicAnswerWhenPredicate;


  @Input("when") get when(): DynamicAnswerWhenPredicate {
    return this._when;
  }

  set when(predicate: DynamicAnswerWhenPredicate) {
    this._when = predicate;
  }
}

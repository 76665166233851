import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActiveRoute, RoutableComponent } from '@rgi/rx/router';
import { ModalComponent } from '@rgi/rx/ui';
import { Subject } from 'rxjs';
import { CONTRACT_STATUS } from '../../group-policy-constants/general';
import { SummaryQuotationTranslations } from '../../group-policy-models/group-policy-issue-guarantees';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import { PaymentsPayload } from '../../group-policy-models/group-policy-issue-policy-data';
import { ActionType, ACTION_ENUM } from '../../group-policy-models/group-policy-issue-summary';
import { GroupPolicyCrossService } from '../../group-policy-services/group-policy-cross.service';
import { GroupPolicyStateSummary } from '../../group-policy-state/group-policy-state';
import {
  GroupPolicyStatemanagerSummary
} from '../../group-policy-state/group-policy-statemanager-summary/group-policy-statemanager-summary';


/**
 * @author: dmasone
 * @description: Component used for summary page
 */
@Component({
  selector: 'rgi-gp-group-policy-summary',
  templateUrl: './group-policy-summary.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicySummaryComponent extends RoutableComponent implements OnInit, OnDestroy {

  public previousStepData: DataForSteps;
  public stateSummary: GroupPolicyStateSummary;
  public saveModal: ModalComponent;
  public translations = SummaryQuotationTranslations;
  public summaryForm: UntypedFormGroup;
  public contractStates = CONTRACT_STATUS;

  public paymentValidationChecker: Subject<void> = new Subject<void>();

  isPaymentOk = true;

  constructor(protected activeRoute: ActiveRoute,
              protected crossService: GroupPolicyCrossService,
              public stateManagerSummary: GroupPolicyStatemanagerSummary) {
    super();
  }

  ngOnInit(): void {
    this.summaryForm = new UntypedFormGroup({});
    this.previousStepData = this.activeRoute.getRouteData();
    this.stateManagerSummary.getState$()
      .subscribe((nextState: GroupPolicyStateSummary) => {
        this.initPage(nextState);
      });
  }

  protected initPage(nextState: GroupPolicyStateSummary) {
    this.stateSummary = nextState;
    this.paymentValidationChecker.next();
  }

  ngOnDestroy(): void { }

  public callActionEmit(code: ActionType) {
    if (this.summaryForm.valid) {
      switch (code) {
        case ACTION_ENUM.PRINTDEF:
          this.stateManagerSummary.actionSaveDraftsDocuments(this.previousStepData, { action: 'DQ' });
          break;
        case ACTION_ENUM.CONFPM:
          this.stateManagerSummary.actionProposalSave(this.previousStepData);
          break;
        case ACTION_ENUM._EMPRO:
          this.stateManagerSummary.actionProposalSave(this.previousStepData, ACTION_ENUM._EMPRO);
          break;
        case ACTION_ENUM.MODPM:
          this.stateManagerSummary.actionSaveDraftsDocuments(this.previousStepData, {});
          break;
        case ACTION_ENUM.SAVEINTERMEDIATE:
          this.callActionSave();
          break;
        case ACTION_ENUM._EMPM:
          // TODO check if are present applications befor save policy
          // this.stateManagerSummary.actionConfirmation
          this.stateManagerSummary.actionSavePolicy(this.previousStepData, this.activeRoute);
          break;
        case ACTION_ENUM.AUTHORIZATION:
          this.stateManagerSummary.actionGetAuthorizationData(this.previousStepData, this.activeRoute);
          break;
        case ACTION_ENUM.ALIGN_APPLICATIONS:
          this.stateManagerSummary.actionAlignApplications(this.previousStepData, this.activeRoute);
          break;
        case ACTION_ENUM.RECOVER_APPLICATIONS:
          this.stateManagerSummary.actionRecoverApplications(this.previousStepData, this.activeRoute);
      }
    }
  }

  public callActionSave() {
    this.stateManagerSummary.actionSave(this.previousStepData);
  }

  public callActionBack(targetRoute?: string) {
    this.stateManagerSummary.actionBack(this.previousStepData, this.activeRoute, targetRoute);
  }

  setPaymentMethod($event: PaymentsPayload) {
    this.stateManagerSummary.setPaymentMethod($event, this.previousStepData);
    this.paymentValidationChecker.next();
  }

  get isApplicationsListVisible(): boolean {
    return this.stateSummary.summary.summaryAdministrativeData.proposalNumber &&
      this.stateSummary.summary.summaryAssets &&
      this.stateSummary.summary.summaryAssets[0] &&
      this.stateSummary.summary.summaryAssets[0].structure.code !== '2';
  }
}

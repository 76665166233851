import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ModalService, OnModalClose} from '@rgi/rx/ui';
import {
  CommissionDetail,
  CommissionsWrap,
  PNC_POSTSALES_COMMISSION_TYPE
} from '../../../../models/domain-models/parameters/commission-detail';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {DecimalPipe} from '@angular/common';
import {IdentificationEntity} from '../../../../models/api-models/identification-entity';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {ERROR_TAG_SNACKBAR} from '../../../../models/pc-portfolio-models/GenericErrorPcPortfolio';

@Component({
  selector: 'lib-re-issue-commission-modal',
  templateUrl: './re-issue-commission-modal.component.html',
  styleUrls: ['./re-issue-commission-modal.component.css'],
  providers: [DecimalPipe]
})
export class ReIssueCommissionModalComponent implements OnInit, OnDestroy, OnModalClose {

  @Input() commissions: CommissionDetail[];
  @Input() commissionTotal: any;
  @Input() currency: any;
  @Input() acquiredCommissionsMandates: IdentificationEntity[];
  @Input() mandateActive: IdentificationEntity;
  @Output() modalClose = new EventEmitter();
  @Output() calculate: EventEmitter<any> = new EventEmitter<any>();
  sub: Subscription = new Subscription();
  COMMISSION_DATA = [{perc_net_acq: '', perc_acc_acq: '', perc_net_inc: '', perc_acc_inc: ''}];
  displayedColumns: string[] = ['checkboxField', 'signedCommissions', 'netPurchaseCommissions', 'feePurchaseCommissions',
    'netCollectionCommissions', 'feeCollectionCommissions', 'totCommissions'];
  nextInstalmentDisplayedColumns: string[] = ['nextInstalment.signedCommissions',
    'nextInstalment.netPurchaseCommissions', 'nextInstalment.feePurchaseCommissions',
    'nextInstalment.netCollectionCommissions', 'nextInstalment.feeCollectionCommissions', 'nextInstalment.totCommissions'];
  headerDisplayedColumns = ['derogation', 'perc_net_acq', 'perc_acc_acq', 'perc_net_inc', 'perc_acc_inc', 'functions'];
  purchComScheme: any;
  commissionForm = new UntypedFormGroup({
    edit: new UntypedFormControl(false),
    perc_net_acq: new UntypedFormControl(null, [Validators.min(0), Validators.max(100)]),
    perc_acc_acq: new UntypedFormControl(null, [Validators.min(0), Validators.max(100)]),
    perc_net_inc: new UntypedFormControl(null, [Validators.min(0), Validators.max(100)]),
    perc_acc_inc: new UntypedFormControl(null, [Validators.min(0), Validators.max(100)])
  });
  riskCommissionDetails: CommissionDetail[];
  amountEditable = false;
  formValid: boolean;
  allRiskFormValid: boolean;

  constructor(private translateService: RgiRxTranslationService,
              private decimalPipe: DecimalPipe,
              private modalService: ModalService,
              private pushMessageHandler: PushMessageHandlerService
  ) {
  }

  ngOnInit() {
    this.riskCommissionDetails = this.commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.RISK);
    this.formValid = this.commissionForm.valid;

    let errorMsgPercentage = '';
    this.translateService.translate('RE_ISSUE.PERCENTAGE_ERROR').subscribe(
      (str: string) => {
        errorMsgPercentage = str ;
      }).unsubscribe();
    let errorMsgAmount = '';
    this.translateService.translate('RE_ISSUE.AMOUNT_NOT_VALID').subscribe(
      (str: string) => {
        errorMsgAmount = str ;
      }).unsubscribe();

    this.riskCommissionDetails
      .map(commission => {
        const keys = Object.keys(commission.subscriptionInstalment);
        const riskKeysByCommission = keys.filter(k => !k.includes('tot'));
        riskKeysByCommission.map(k => {
          this.commissionForm.addControl(k + '_' + commission.risk.id, new UntypedFormControl(
            {
              value: commission.subscriptionInstalment[k],
              disabled: true
            }, Validators.min(0)));
          let errorMsg = '';
          if (this.isFieldPercentage(k)) {
            this.commissionForm.get(k + '_' + commission.risk.id).setValidators([Validators.min(0),
                                                                                Validators.max(100)]);
            errorMsg = errorMsgPercentage;
          } else {
            this.commissionForm.get(k + '_' + commission.risk.id).setValidators([Validators.min(0)]);
            errorMsg = errorMsgAmount;
          }
          this.commissionForm.get(k + '_' + commission.risk.id).statusChanges
            .pipe(distinctUntilChanged()).subscribe( (status) => {
            this.updateSnakbar(status, ERROR_TAG_SNACKBAR.COMMISSIONS_MODAL, errorMsg);
          });
        });
        this.commissionForm.addControl('checkboxField_' + commission.risk.id,
          new UntypedFormControl({value: commission.derogated, disabled: false}, Validators.required));
        this.commissionForm.get('checkboxField_' + commission.risk.id).valueChanges
          .pipe(distinctUntilChanged()).subscribe((isChecked: boolean) => {
            if (!isChecked) {  // disable risk input if checkbox is not checked
              riskKeysByCommission.map(k => {
                this.commissionForm.get(k + '_' + commission.risk.id).disable();
              });
            } else {
              riskKeysByCommission.map(k => {
                if ((!this.isFieldPercentage(k) && this.amountEditable) || (this.isFieldPercentage(k) && !this.amountEditable)) {
                  this.commissionForm.get(k + '_' + commission.risk.id).enable();
                }
              });
            }
          }
        );
      }); // end commission loop
    this.commissionForm.addControl('purchComm',
      new UntypedFormControl({value: this.mandateActive.code, disabled: false}));


    const editSub = this.commissionForm.controls.edit.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(isAmountEditable => {
        this.amountEditable = isAmountEditable;
        this.riskCommissionDetails
          .map(commission => {
            const isDerogated: boolean = !!this.commissionForm.get('checkboxField_' + commission.risk.id) ?
              this.commissionForm.get('checkboxField_' + commission.risk.id).value : commission.derogated;
            const keys = Object.keys(commission.subscriptionInstalment);
            const riskKeysByCommission = keys.filter(k => !k.includes('tot') && !k.includes('checkboxField_'));
            riskKeysByCommission.map(k => {
              if (isDerogated) {
                if ( (!this.isFieldPercentage(k) && isAmountEditable || (this.isFieldPercentage(k) && !isAmountEditable))) {
                  this.commissionForm.get(k + '_' + commission.risk.id).enable();
                } else {
                  this.commissionForm.get(k + '_' + commission.risk.id).disable();
                }
              }
            });
          });
      });
    const purchSub = this.commissionForm.controls.purchComm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        this.mandateActive = this.acquiredCommissionsMandates.find(entity => entity.code === value);
      });

    this.allRiskFormValid = true;
    this.pushMessageHandler.clearTag(ERROR_TAG_SNACKBAR.COMMISSION_MODAL_ALLRISK);

    const allRisksKeys = Object.keys(this.COMMISSION_DATA[0]);
    allRisksKeys.map(k => {
      const allPercStatusChange = this.commissionForm.get(k).statusChanges
        .pipe(distinctUntilChanged()).subscribe( (status) => {
        // set this.allRiskFormValid to false if at least one field is invalid and to true if all fields are valid
          this.allRiskFormValid = allRisksKeys.filter(key => key !== k)
                                                .map(key => this.commissionForm.get(key).valid)
                                                .every(valid => valid) && status === 'VALID';
          this.updateSnakbar(status, ERROR_TAG_SNACKBAR.COMMISSION_MODAL_ALLRISK, errorMsgPercentage);
        });
      this.sub.add(allPercStatusChange);
    });


    const validationSub = this.commissionForm.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.formValid = this.commissionForm.valid;
    });

    this.sub.add(editSub);
    this.sub.add(purchSub);
    this.sub.add(validationSub);

  }

  private updateSnakbar(status, tag, errorMsg: string) {
    this.pushMessageHandler.clearTag(tag);
    if (status === 'INVALID') {
      this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
          tag,
          status: 'danger',
          dismissible: true
        }
      ));
    }
  }

  private isFieldPercentage(k: string) {
    return k.toLowerCase().includes('perc');
  }

  close() {
    this.modalClose.emit();
  }

  recalculate() {
    this.riskCommissionDetails.forEach((commission, index) => {
      const commId = commission.risk.id;
      this.riskCommissionDetails[index].derogated = this.commissionForm.get('checkboxField_' + commId).value;
      if (!!this.riskCommissionDetails[index].derogated) {
        if (this.amountEditable) {
          this.riskCommissionDetails[index].subscriptionInstalment.netPurchaseCommissions =
            this.commissionForm.get('netPurchaseCommissions_' + commId).value;
          this.riskCommissionDetails[index].subscriptionInstalment.feePurchaseCommissions =
            this.commissionForm.get('feePurchaseCommissions_' + commId).value;
          this.riskCommissionDetails[index].subscriptionInstalment.netCollectionCommissions =
            this.commissionForm.get('netCollectionCommissions_' + commId).value;
          this.riskCommissionDetails[index].subscriptionInstalment.feeCollectionCommissions =
            this.commissionForm.get('feeCollectionCommissions_' + commId).value;
          this.riskCommissionDetails[index].subscriptionInstalment.netPercPurchaseCommissions = null;
          this.riskCommissionDetails[index].subscriptionInstalment.feePercPurchaseCommissions = null;
          this.riskCommissionDetails[index].subscriptionInstalment.netPercCollectionCommissions = null;
          this.riskCommissionDetails[index].subscriptionInstalment.feePercCollectionCommissions = null;
        } else {
          this.riskCommissionDetails[index].subscriptionInstalment.netPercPurchaseCommissions =
            this.commissionForm.get('netPercPurchaseCommissions_' + commId).value;
          this.riskCommissionDetails[index].subscriptionInstalment.feePercPurchaseCommissions =
            this.commissionForm.get('feePercPurchaseCommissions_' + commId).value;
          this.riskCommissionDetails[index].subscriptionInstalment.netPercCollectionCommissions =
            this.commissionForm.get('netPercCollectionCommissions_' + commId).value;
          this.riskCommissionDetails[index].subscriptionInstalment.feePercCollectionCommissions =
            this.commissionForm.get('feePercCollectionCommissions_' + commId).value;
        }
      }
    });
    const commissionWrap = new CommissionsWrap();
    commissionWrap.commissions = this.riskCommissionDetails;
    commissionWrap.mandateActive = this.mandateActive;
    commissionWrap.acquiredCommissionsMandates = this.acquiredCommissionsMandates;
    this.calculate.emit(commissionWrap);

    this.commissionForm.get('perc_net_acq').reset();
    this.commissionForm.get('perc_acc_acq').reset();
    this.commissionForm.get('perc_net_inc').reset();
    this.commissionForm.get('perc_acc_inc').reset();

  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  assign() {
    this.commissionForm.controls.edit.reset(false);
    const percNetAcq = this.commissionForm.get('perc_net_acq').value;
    const percAccAcq = this.commissionForm.get('perc_acc_acq').value;
    const percNetInc = this.commissionForm.get('perc_net_inc').value;
    const percAccInc = this.commissionForm.get('perc_acc_inc').value;

    Object.keys(this.commissionForm.controls).map(k => {
      if (k.includes('checkboxField_')) {
        const kChecked = this.commissionForm.get(k).value;
        if (!kChecked) {
          this.commissionForm.get(k).patchValue(true);
        }
      }
      if (!!percNetAcq && k.includes('netPercPurchaseCommissions')) {
        this.commissionForm.get(k).patchValue(percNetAcq);
        this.riskCommissionDetails.forEach((commission, index) => {
          this.riskCommissionDetails[index].subscriptionInstalment.netPercPurchaseCommissions = percNetAcq;
        });
      }
      if (!!percAccAcq && k.includes('feePercPurchaseCommissions')) {
        this.commissionForm.get(k).patchValue(percAccAcq);
        this.riskCommissionDetails.forEach((commission, index) => {
          this.riskCommissionDetails[index].subscriptionInstalment.feePercPurchaseCommissions = percAccAcq;
        });
      }
      if (!!percNetInc && k.includes('netPercCollectionCommissions')) {
        this.commissionForm.get(k).patchValue(percNetInc);
        this.riskCommissionDetails.forEach((commission, index) => {
          this.riskCommissionDetails[index].subscriptionInstalment.netPercCollectionCommissions = percNetInc;
        });
      }
      if (!!percAccInc && k.includes('feePercCollectionCommissions')) {
        this.commissionForm.get(k).patchValue(percAccInc);
        this.riskCommissionDetails.forEach((commission, index) => {
          this.riskCommissionDetails[index].subscriptionInstalment.feePercCollectionCommissions = percAccInc;
        });
      }
    });
  }

  formatValue(valueToFormat: any, type: any) {
    if (!!type && Object.values(PNC_POSTSALES_COMMISSION_TYPE).includes(type)  ) {
      return this.decimalPipe.transform(valueToFormat, '1.2-2', null);
    }
    return valueToFormat;
  }

  isEditable(key: string, isPerc: boolean) {
    if (!!key) {
      return this.commissionForm.get('checkboxField_' + key).value &&
        ((this.amountEditable && !isPerc) || (!this.amountEditable && isPerc));
    }
    return false;
  }

  public patchForm(riskCommissionDetailsUpdated: CommissionDetail[]) {
    riskCommissionDetailsUpdated
      .map(commission => {
        const keys = Object.keys(commission.subscriptionInstalment);
        keys.filter(k => !k.includes('tot')).map(k => {
          this.commissionForm.get(k + '_' + commission.risk.id)
            .patchValue(commission.subscriptionInstalment[k]);
        });
      });
  }

}


import {AbstractStateManager, StateStoreService} from "@rgi/rx/state";
import {ActiveRoute} from "@rgi/rx/router";
import {GroupPolicyStatelessOpIssueConfirmService} from "./group-policy-stateless-op-issue-confirm.service";
import {of} from "rxjs";
import {GroupPolicyIntegrationService} from "../../group-policy-services/group-policy-integration.service";
import {DataForSteps} from "../../group-policy-models/group-policy-issue-home";
import {GroupPolicyStateIssueConfirm} from '../group-policy-state';

export class GroupPolicyStateManagerIssueConfirm extends AbstractStateManager<GroupPolicyStateIssueConfirm> {

  constructor(activeRoute: ActiveRoute,
              stateStoreService: StateStoreService,
              protected statelessOps: GroupPolicyStatelessOpIssueConfirmService,
              protected integrationService: GroupPolicyIntegrationService) {
    super(activeRoute, stateStoreService);
    const stateIssueConfirmData = this.stateStoreService.has(this.activeRoute.key) ?
      this.stateStoreService.get<GroupPolicyStateIssueConfirm>(this.activeRoute.key) :
      new GroupPolicyStateIssueConfirm(this.activeRoute.key);
    const initial$ = this.statelessOps.initIssueConfirmData$(of(stateIssueConfirmData), this.activeRoute);
    this.updateState$(initial$);
  }

  actionGoToInquiry(previousStepData: DataForSteps, isFromIssuePage: boolean) {
    const st = this.getCurrentState();
    st.contractData.isFromIssueContract = isFromIssuePage;
    this.updateState$(this.statelessOps.actionGoToInquiry$(of(st), previousStepData));
  }

  actionBackToHome(activeRoute: ActiveRoute) {
    const st = this.getCurrentState();
    this.updateState$(this.statelessOps.actionBackHome$(of(st), activeRoute));
  }
}

import {Injectable} from '@angular/core';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReIssueTitleUtilityService {
  nodeDescr: string;
  productDescr: string;
  policyHolderDescr: string;
  id: string;
  policyHolderName: string;
  productName: string;
  nodeName: string;

  constructor(private translateService: RgiRxTranslationService) {
    this.translation();
  }

  setDescriptions(product, node, subject, id) {
    this.nodeDescr = node;
    this.productDescr = product;
    if (subject) {
      this.policyHolderDescr = subject.nominative;
    } else {
      this.policyHolderDescr = subject;
    }
    this.id = id;
  }


  updateNavigationTitle() {
    if (!document.getElementById('navigation-title-' + this.id)) {
      const actualElem = document.getElementById(this.id);

      if (actualElem && actualElem.parentElement.parentElement.parentElement.parentElement.parentElement) {
        const cardTitleParent = (actualElem
          .parentElement
          .parentElement
          .parentElement
          .parentElement
          .parentElement.getElementsByClassName('row card-title-container')[0]) ? (actualElem
            .parentElement
            .parentElement
            .parentElement
            .parentElement
            .parentElement.getElementsByClassName('row card-title-container')[0]
            .getElementsByClassName('card-title')[0]) :
          actualElem.parentElement.parentElement.parentElement.parentElement.children[0].children[0];

        const newElement = document.createElement('div');
        newElement.classList.add('rgi-re-attach-to-title');
        newElement.id = 'navigation-title-' + this.id;
        const newElementProduct = document.createElement('div');
        newElementProduct.setAttribute('data-qa', 'info_product');
        const product = document.createTextNode(this.productName + ': ');
        const productVal = document.createElement('strong');
        productVal.innerHTML = this.productDescr;
        newElement.appendChild(newElementProduct).appendChild(product);
        newElement.appendChild(newElementProduct).appendChild(productVal);

        if (!!this.policyHolderDescr) {
          const newElementHolder = document.createElement('div');
          newElementHolder.setAttribute('data-qa', 'info_policyholder');
          const separator = document.createTextNode(' - ');
          const label = this.policyHolderName + ': ';
          const holder = document.createTextNode(label);
          const holderVal = document.createElement('strong');
          holderVal.innerHTML = this.policyHolderDescr;
          newElement.appendChild(newElementHolder).appendChild(separator);
          newElement.appendChild(newElementHolder).appendChild(holder);
          newElement.appendChild(newElementHolder).appendChild(holderVal);
        }

        if (!!this.nodeDescr) {
          const newElementNode = document.createElement('div');
          newElementNode.setAttribute('data-qa', 'info_node');
          const separator = document.createTextNode(' - ');
          const label = this.nodeName + ': ';
          const node = document.createTextNode(label);
          const nodeVal = document.createElement('strong');
          nodeVal.innerHTML = this.nodeDescr;
          newElement.appendChild(newElementNode).appendChild(separator);
          newElement.appendChild(newElementNode).appendChild(node);
          newElement.appendChild(newElementNode).appendChild(nodeVal);
        }

        cardTitleParent.appendChild(newElement);
      }
    }
  }

  resetNavigationTitle() {
    const actualElem = document.getElementById('navigation-title-' + this.id);
    if (!!actualElem) {
      (actualElem.parentElement).removeChild(actualElem);
    }
  }

  translation() {
    const keys = of(['RE_ISSUE.PRODUCT',
      'RE_ISSUE.POLICYHOLDER',
      'RE_ISSUE.NODE']);
    keys.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.productName = next [0];
      this.policyHolderName = next[1];
      this.nodeName = next[2];
    }).unsubscribe();
  }
}

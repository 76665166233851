import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { mapObjForVcont } from '../../adapters/group-policy-utils';
import { ROUTES_GP_VCONT_DATES } from '../../group-policy-constants/routes-constants';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import { ErrorResp, Factor } from '../../group-policy-models/group-policy-issue-policy-data';
import {
    DatesPayload,
    GroupPolicyVcontRouteData,
    NewFlowPayload,
    NewFlowPostSalesResponse,
    NextRouteResponse,
    SaveDateResponse
} from '../../group-policy-models/group-policy-vcont-date';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyStateVcontDate } from '../group-policy-state';


/**
 * @author: dmasone
 * @description: Service used for render vcont date step
 */
@Injectable({
    providedIn: 'root'
})
export class GroupPolicyStatelessOpDateService {

    public readonly _VCONT = '_VCONT';

    constructor(protected groupPolicyService: GroupPolicyResourceService,
        protected routingService: RoutingService,
        protected apiRestErrorService: GroupPolicyApiRestErrorService,
        protected integrationService: GroupPolicyIntegrationService) { }

    public initDate$(groupPolicyStateDate$: Observable<GroupPolicyStateVcontDate>,
        activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontDate> {
        const previousStepData = activeRoute.getRouteData<GroupPolicyVcontRouteData>();
        return groupPolicyStateDate$.pipe(
            mergeMap((state: GroupPolicyStateVcontDate) => {
                return combineLatest(of(state),
                    this.startNewFlow$(of(state), { 'nodeCode': previousStepData.node, 'operationCode': this._VCONT }, previousStepData.proposalNumber, previousStepData.resId)
                )
            }),
            map(([state]) => {
                state.steps = [ROUTES_GP_VCONT_DATES];
                state.labels = ['_GP_._TITLE_._DATES_'];
                return state;
            })
        );
    }

    public startNewFlow$(groupPolicyStateDate$: Observable<GroupPolicyStateVcontDate>, payload: NewFlowPayload, proposalNumber: string,
        resId: string): Observable<GroupPolicyStateVcontDate> {
        return groupPolicyStateDate$.pipe(
            concatMap((state: GroupPolicyStateVcontDate) => {
                state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, 'FOOTER');
                return (resId) ? of({ uuid: resId } as NewFlowPostSalesResponse).pipe(
                    map((newFlowPostSalesGroupPolicy: NewFlowPostSalesResponse) => {
                        state.uuid = newFlowPostSalesGroupPolicy.uuid;
                        return state;
                    })
                ) : this.groupPolicyService.newFlowPostSalesGroupPolicy$(payload, proposalNumber).pipe(
                    map((newFlowPostSalesGroupPolicy: NewFlowPostSalesResponse) => {
                        state.uuid = newFlowPostSalesGroupPolicy.uuid;
                        return state;
                    }),
                    catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
                );
            }),
            concatMap((state: GroupPolicyStateVcontDate) => {
                return this.getDates$(of(state), state.uuid).pipe(
                    map((state: GroupPolicyStateVcontDate) => state),
                    catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
                );
            }),
            catchError(this.apiRestErrorService.manageStreamErrFn()),
            map((state: GroupPolicyStateVcontDate) => state)
        );
    }

    public getDates$(groupPolicyStateDate$: Observable<GroupPolicyStateVcontDate>, uuid: string): Observable<GroupPolicyStateVcontDate> {
        return groupPolicyStateDate$.pipe(
            concatMap((state: GroupPolicyStateVcontDate) => {
                return this.groupPolicyService.getDatesPostSales$(uuid).pipe(
                    map((getDatesPostSales: Factor[]) => {
                        state.factors = getDatesPostSales;
                    }),
                    catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
                );
            }),
            catchError(this.apiRestErrorService.manageStreamErrFn()),
            map((state: GroupPolicyStateVcontDate) => state)
        );
    }

    public actionGoForword$(groupPolicyStateDate$: Observable<GroupPolicyStateVcontDate>, resId: string,
        activeRoute: ActiveRoute, payload: DatesPayload[]): Observable<GroupPolicyStateVcontDate> {
        const previousStepData = activeRoute.getRouteData<GroupPolicyVcontRouteData>();
        return groupPolicyStateDate$.pipe(
            concatMap((state: GroupPolicyStateVcontDate) => {
                state.errors = this.apiRestErrorService.cleanErrorsForCode(state.errors, 'FOOTER');
                return this.groupPolicyService.saveDatesPostSales$(resId, payload).pipe(
                    map((_saveDatesPostSales: SaveDateResponse) => state),
                    catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
                );
            }),
            concatMap((state: GroupPolicyStateVcontDate) => {
                return this.groupPolicyService.actionsPostSales$(resId, 'D').pipe(
                    map((resp: ErrorResp[]) => {
                        state.errors = this.apiRestErrorService.manageErrors(resp, 'FOOTER_ACTION');
                        return state;
                    }),
                    catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
                );
            }),
            concatMap((state: GroupPolicyStateVcontDate) => {
                if (!this.apiRestErrorService.containsBlockingErrors(state.errors)) {
                    return this.groupPolicyService.nextRoutePostSales$(resId, ROUTES_GP_VCONT_DATES).pipe(
                        map((nextRoutePostSales: NextRouteResponse) => [state, nextRoutePostSales]),
                        catchError(this.apiRestErrorService.catchApiErrorFn(state, 'FOOTER'))
                    );
                } else {
                    return [of(state), of(null)];
                }
            }),
            catchError(this.apiRestErrorService.manageStreamErrFn()),
            map(([state, nextRoutePostSales]: [GroupPolicyStateVcontDate, NextRouteResponse]) => {
                if (!this.apiRestErrorService.containsBlockingErrors(state.errors)) {
                    this.integrationService.navigate(this.routingService, nextRoutePostSales.nextRoute, mapObjForVcont(resId, state.steps, state.labels, previousStepData.proposalNumber, previousStepData.idParentSession, previousStepData.firstSubjectContract), activeRoute);
                }
                return state;
            })
        );
    }

    public actionClose$(groupPolicyStateDate$: Observable<GroupPolicyStateVcontDate>, previousStepData: DataForSteps,
        activeRoute: ActiveRoute): Observable<GroupPolicyStateVcontDate> {
        return groupPolicyStateDate$.pipe(
            tap(() => {
                this.integrationService.backToInquiry(previousStepData.proposalNumber, activeRoute.id, previousStepData.idParentSession);
            })
        );
    }

}

import {RgiRxTranslations} from "@rgi/rx/i18n";
import {DE, EN, ES, FR, IT} from "./translations";
import {RgiRxi18nModuleLoadType} from "@rgi/rx/i18n/src/i18n-api";

export function loadNgPassPro18n():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(EN);
  })
}

export function loadNgPassPro18nIT():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(IT);
  })
}

export function loadNgPassPro18nFR():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(FR);
  })
}


export function loadNgPassPro18nDE():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(DE);
  })
}

export function loadNgPassPro18nES():RgiRxi18nModuleLoadType {
  return new Promise((resolve) => {
    return resolve(ES);
  })
}




export const NG_PASSPRO_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: "en",
    load: loadNgPassPro18n
  },
  {
    locale: "it",
    load: loadNgPassPro18nIT
  },
  {
    locale: "es",
    load: loadNgPassPro18nES
  },
  {
    locale: "de",
    load: loadNgPassPro18nDE
  },
  {
    locale: "fr",
    load: loadNgPassPro18nFR
  }
]

import { Component, Inject, Input, OnInit, Optional, Output, EventEmitter } from '@angular/core';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OutputChangeMail } from './claim-questionnaire-edit-data.interface';

@Component({
    selector: 'claims-questionnaire-edit-data',
    templateUrl: './claim-questionnaire-edit-data.component.html',
    styleUrls: ['./claim-questionnaire-edit-data.component.scss']
})

export class ClaimQuestionnaireEditDataComponent implements OnInit, OnModalClose {
    questionnaireDataForm: UntypedFormGroup;
    display: boolean;
    editData: string;
    data: any;
    datepickerPlaceholder: string = '';
    dataOutput: string;
    minDate = '';
    @Output() modalClose: EventEmitter<OutputChangeMail> = new EventEmitter<OutputChangeMail>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private datePipe: DatePipe,
        @Optional() @Inject(DIALOG_DATA) data: any
    ) {
        this.editData = data.errorData;
    }

    ngOnInit(): void {
        this.controlFormData();
        console.log(this.editData);
        this.display = true;
    }

    controlFormData() {
        this.questionnaireDataForm = this.formBuilder.group({
            controllDate: [new Date(), Validators.required]
        });
    }

    sendConfirm(closeType: string) {
        if (this.questionnaireDataForm.valid && closeType === 'Confirm') {
            this.closeModal(closeType);
        }
        if (closeType === 'Close') {
            this.closeModal(closeType);
        }
    }

    closeModal(closeType: string) {
        const questionnarieDate = new Date(this.questionnaireDataForm.get('controllDate').value);
        const res: OutputChangeMail = {
            typeClose: closeType,
            dateChanged: this.datePipe.transform(questionnarieDate, 'dd/MM/yyy')
        };
        this.modalClose.emit(res);
    }
}

import { PotentialPaymentEntity } from './potential-payment-entity';
import { ExposureEntity } from './exposure-entity';
import { TypePaymentEntity } from './type-payment-entity';
import { PaymentEntity } from './payment-entity';
import { AssessementEntity } from './assessement-entity';
import { LegalFeeOmniaEntity } from './legal-fee-omnia-entity';
import { CompensationEntity } from './compensation-entity';
import { SubjectEntity } from './subject-entity';
import { MethodPaymentEntity } from './method-payment-entity';


export class InitPotentialPaymentEntity {
    public uuid: string;
    public paymentType: string; // tipo pagamento
    public idCausaleOrigine: string; // Id della causale selezionata dall'utente in entrata
    public managementPayment: string; // gestione pagamento
    public receiptType: string; // tipo quietanza
    public priority: string; // urgenza
    public dueDate: Date; // data esigibilità
    public receiptDate: Date; // data quietanza
    public paymentList: Array<PotentialPaymentEntity>; // elenco delle quietanze da creare
    public exposureList: Array<ExposureEntity>; // lista coperture da completa
    public professionistList: Array<SubjectEntity>; // lista LAV,CTU,CTP
    public paymentTypeList: Array<TypePaymentEntity>;
    public paymentTypeListCombo: Array<TypePaymentEntity>;
    public managementPaymentList: Array<any>; // array di comboEntity
    public receiptTypeList: Array<any>; // array di comboEntity (combo tipo quietanza)
    public receiptTypeListCombo: Array<any>; // array di comboEntity (combo tipo quietanza)
    public priorityListCombo: Array<any>; // array di comboEntity (combo urgenza)

    // public typePaymentCurrent: Array<TypePaymentEntity>; // la configurazione corrente in base a selezione utente
    public excludedExcess: boolean; // esclusione franchigia
    public excludedDeductible: boolean; // esclusione scoperto
    public excludedCeiling: boolean; // esclusione massimale

    public legaleComeAttoreAbilitato: boolean;
    public legalProtectionEnabled: boolean;
    public invoiceDataControl: boolean;
    public exposuresLoaded: boolean;
    public receiptTypeLoaded: boolean;
    public antiFraudMessage: string;

    // dati generali
    public company: string;
    public companyCode: string;
    public claimNumber: string;
    public lossDate: Date;
    public productCode: string;
    public productDescription: string;
    public ministerialBranch: string;
    public policyNumber: string;
    public coassType: string;
    public coassTypeDescr: string;
    public agency: string;
    public policyHolder: string;
    public claimType: string; // valorizzato solo se tra le coperture c'è ramo 010 (RCA)
    public complaintDate: Date;
    public effectiveLossDate: string;
    public administrativeReg: string; // Sì/no
    public technicalReg: string;
    public actorId: string;
    public actorRoleCode: string;
    public actorNominative: string;
    public actorFcVat: string;
    public matterFound: boolean; // esiste contenzioso societario
    public requestDamagesFound: boolean;
    public ctpLawyerFound: boolean;
    public paymentPresent: boolean;
    public paymentSavedList: Array<PaymentEntity>;
    public reserved: number;
    public paid: number;
    public receiptPresent: boolean;
    public receiptSavedList: Array<PaymentEntity>;
    public numReceipt: any;
    public separateReceipt: boolean;
    public assessmentSavedList: Array<AssessementEntity>;
    public assessmentPresent: boolean;
    public actorRole: string;
    public compensationSavedList: Array<CompensationEntity>;
    public compensationPresent: boolean;
    public totalReservedCompensation: number;
    public totalCashedCompensation: number;
    public totalAssInd: number;  // totale incarichi indennizzo
    public totalAssSpLiq: number; // totale incarichi spese di liquidazione
    public totalAssSpRes: number; // totale incarichi spese di resistenza
    public procedureCode: string;
    public procedureCodeList: Array<any>;
    public subjectAnag: Map<number, MethodPaymentEntity>;
    public nodoFittizioSx: string;
    public mainSubStatus: string;

      // multicurrency
  public currencyCode: string;
  public currencyCodeDate: Date;
  public currencyAmount: number;
  public legalFeeCurrencyAmount: number;
  public idPaymentOld: string;
  public idActRef: string;

  public note: string;
}

<table style="width:100%" [formGroup]="formGroup" class="settlement-table active" [attr.data-qa]="'installment-table'">
    <thead>
      <tr>
        <th class="life-detail-table-width-5"></th>
        <th class="life-detail-table-width-35" translate>lpc_DescOperazione</th>
        <th class="life-detail-table-width-20" translate>lpc_EffectiveDate</th>
        <th class="life-detail-table-width-20" translate>lpc_issueDate</th>
        <th class="life-detail-table-width-20" translate>lpc_RateAmount</th>
      </tr>
    </thead>
    <ng-container *ngFor="let inst of installments; let i = index;">
      <tbody>
        <tr>
          <td class="type-flex">
            <input formControlName="installment" type="radio" name="installment" [value]="inst.idMovement"/>
          </td>
          <td [attr.data-qa]="'td-inst-description'" class="life-detail-table-width-35">{{inst.description}}</td>
          <td [attr.data-qa]="'td-inst-effectDate'" class="life-detail-table-width-20">{{inst.effectDate | date : 'dd/MM/yyyy'}}</td>
          <td [attr.data-qa]="'td-inst-issueDate'" class="life-detail-table-width-20">{{inst.issueDate | date : 'dd/MM/yyyy'}}</td>
          <td [attr.data-qa]="'td-inst-totalAmount'" class="life-detail-table-width-20">{{inst.totalAmount | rgiCountryLayerNumberFormat: currencyService.locale: currencyFormatterOptions}}</td>
        </tr>
      </tbody>
    </ng-container>
  </table>

  <!-- <div class="col-md-12 inactive">
    <div>
      <span for="label" translate>Liquidazione selezionata: </span>
      <span for="value">{{getSelectedSettlementSummary()}}</span>
    </div>
  </div> -->

<div class="note-container">
    <div *ngIf="data.hasLeftColumn" class="column left"></div>
    <div *ngIf="data.hasRightColumn" [ngClass]="['column', 'right', rightColumnStyle]">
        <div [ngClass]="['note', currencyStyle]">
            <span>{{ '_CLAIMS_._TOTAL_RESERVES_AMOUNT' | translate }}</span>:&nbsp;
            {{data.reserveAmount | number: '1.2-2'}}&nbsp;{{data.currencySymbol}}
            <br><span>{{ '_CLAIMS_._TOTAL_ASSIGNMENT_EXPENSE_AMOUNT' | translate }}</span>:&nbsp;
            {{data.reserveAmountSL | number: '1.2-2'}}&nbsp;{{data.currencySymbol}}
            <br><span>{{ '_CLAIMS_._TOTAL_RESERVE_FOR_LITIGATION' | translate }}</span>:&nbsp;
            {{data.reserveAmountSR | number: '1.2-2'}}&nbsp;{{data.currencySymbol}}
        </div>
    </div>
</div>
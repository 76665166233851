import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { DashboardElem } from '../model/dashboard-element';
import { DashboardCardState, DashboardManagerService } from '../state-manager/dashboard-manager.service';
import { DashboardAuthBusinessService } from '../services/dashboard-auth-business.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {Message} from '../const/msg';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {FunctionalityEnum} from '../enumerations/functionality.enum';

export enum SectionEnum {
  STORED_IDX = 0,
  NOT_STORED_IDX = 1,
}

@Component({
  selector: 'dashboard-search',
  templateUrl: './dashboard-search.component.html',
  styleUrls: ['./dashboard-search.component.scss']
})
export class DashboardSearchComponent implements OnInit {

  searchTypes: number[];
  state: DashboardCardState;
  dashboardFormGroup: UntypedFormGroup;
  validationMessages: Message[] = [];
  selectedRadioId: number;
  funTypes: DashboardElem[] = [];
  isError: boolean;
  maxDateTo: any;
  errorTextNoData: string;
  errorTextFromGreaterThenTo: string;
  errorTextMoreThen60Days: string;
  totalConsultationLabel: string;
  detailsConsultationLabel: string;
  editLabel: string;
  TAG_DATE_RANGE_ERROR = 'date_range_error';

  constructor(
    public stateManager: DashboardManagerService<DashboardCardState>,
    private formBuilder: UntypedFormBuilder,
    private authService: DashboardAuthBusinessService,
    private translateService: RgiRxTranslationService,
    protected errorService: PushMessageHandlerService
  ) {
    this.searchTypes = [SectionEnum.STORED_IDX, SectionEnum.NOT_STORED_IDX];
    this.dashboardFormGroup = this.formBuilder.group(
      {
        functionality: [null, Validators.required],
        company: [null, Validators.required],
        competenceDateFrom: [null, Validators.required],
        competenceDateTo: [null, Validators.required],
        product: [],
        productCode: [],
        status: [],
        action: [],
        mismatchAmountFrom: [],
        mismatchAmountTo: [],
        selectMode: new UntypedFormControl('single')
      }, {
        validator: [
          this.validateDate('competenceDateFrom', 'competenceDateTo')]
      });
   }

  ngOnInit() {
    this.setTranslations();
    this.stateManager.getState$().pipe(take(1)).subscribe(state => {
      this.selectedRadioId = this.searchTypes[SectionEnum.NOT_STORED_IDX];
      this.initFunctionality();
      this.state = state;
      this.stateManager.initAllData(state, this.authService.getNodeId()).subscribe(state1 => {
        if (state1.companyList && state1.companyList.length === 1) {
          this.dashboardFormGroup.patchValue({
            company: state1.companyList[0]
          });
        }
        this.stateManager.updateState$(of(state1));
      });
      this.stateManager.updateState$(of(state));
    });
  }

  setTranslations() {
    const keys$ = of([
      'ICD.Total_consultation',
      'ICD.Details_consultation',
      'ICD.Edit',
      'ICD.Fields_incorrect_values',
      'ICD.From_greater_then_to',
      'ICD.More_then_60_days'
    ]);
    keys$.pipe(
        mergeMap(r => this.translateService.translateAll(...r)) ).subscribe(next => {
      this.totalConsultationLabel = next[0];
      this.detailsConsultationLabel = next[1];
      this.editLabel = next[2];
      this.errorTextNoData = next[3];
      this.errorTextFromGreaterThenTo = next[4];
      this.errorTextMoreThen60Days = next[5];
    });
  }

  getMinDate() {
    return this.dashboardFormGroup.controls.competenceDateFrom.value;
    }

  getMaxDate() {
    return this.dashboardFormGroup.controls.competenceDateTo.value;
  }





  initFunctionality() {
    this.funTypes = [
      new DashboardElem(FunctionalityEnum.TOTAL_CONSULTATION, this.totalConsultationLabel),
      new DashboardElem(FunctionalityEnum.DETAIL_CONSULTATION, this.detailsConsultationLabel),
      new DashboardElem(FunctionalityEnum.EDIT, this.editLabel)
    ];
    this.updateFunctionality();
  }

  updateFunctionality() {
    if (this.selectedRadioId === this.searchTypes[SectionEnum.STORED_IDX]) {
      this.funTypes.pop();
      this.dashboardFormGroup.updateValueAndValidity();
    } else {
      if (this.funTypes.length < Object.keys(FunctionalityEnum).length) {
        this.funTypes.push(new DashboardElem(FunctionalityEnum.EDIT, this.editLabel));
      }
      this.dashboardFormGroup.controls.functionality.enable();
    }
    this.onFunctionChange();
  }

  onSectionSelect(event: any) {
    this.selectedRadioId = event.index;
    this.updateFunctionality();
  }

  onFunctionChange() {
    if (this.dashboardFormGroup.controls.functionality.value &&
      this.dashboardFormGroup.controls.functionality.value.id === FunctionalityEnum.TOTAL_CONSULTATION) {
        this.dashboardFormGroup.controls.status.setValue(null);
        this.dashboardFormGroup.controls.status.disable();
        this.dashboardFormGroup.controls.action.setValue(null);
        this.dashboardFormGroup.controls.action.disable();
        this.dashboardFormGroup.controls.mismatchAmountFrom.setValue(null);
        this.dashboardFormGroup.controls.mismatchAmountFrom.disable();
        this.dashboardFormGroup.controls.mismatchAmountTo.setValue(null);
        this.dashboardFormGroup.controls.mismatchAmountTo.disable();
    } else {
      this.dashboardFormGroup.controls.status.enable();
      this.dashboardFormGroup.controls.action.enable();
      this.dashboardFormGroup.controls.mismatchAmountFrom.enable();
      this.dashboardFormGroup.controls.mismatchAmountTo.enable();
    }
  }

  onProductChange() {
    this.dashboardFormGroup.controls.productCode.setValue(this.dashboardFormGroup.controls.product.value);
    this.dashboardFormGroup.updateValueAndValidity();
  }

  onProductCodeChange() {
    this.dashboardFormGroup.controls.product.setValue(this.dashboardFormGroup.controls.productCode.value);
    this.dashboardFormGroup.updateValueAndValidity();
  }

  goToNextPage() {
    this.dashboardFormGroup.markAsDirty();
    Object.keys(this.dashboardFormGroup.controls).forEach(key => {
      this.dashboardFormGroup.controls[key].markAsDirty();
    });
    this.dashboardFormGroup.markAsTouched();
    this.dashboardFormGroup.markAllAsTouched();
    this.validationMessages = [];

    if (this.dashboardFormGroup.valid) {
      this.isError = false;
      this.stateManager.updateState$(of(this.state));
      this.stateManager.goToNextPage(this.dashboardFormGroup, this.selectedRadioId);
    } else {
      this.isError = true;
      this.errorService.clearTag(this.TAG_DATE_RANGE_ERROR);
      if (this.isPeriodGreaterThen60()) {
        const msg: RgiRxPushMessage = new RgiRxPushMessage();
        const opts = {icon: 'rgi-ui-icon-alert'};
        msg.tag = this.TAG_DATE_RANGE_ERROR;
        msg.status = 'danger';
        msg.content = this.errorTextMoreThen60Days;
        msg.dismissible = false;
        msg.options = opts;
        this.errorService.notify(msg);
      }
    }
  }

  isPeriodGreaterThen60(): boolean {
    return this.dashboardFormGroup.controls.competenceDateFrom.errors &&
      this.dashboardFormGroup.controls.competenceDateFrom.errors.intervalTooBig;
  }

  clear() {
    this.dashboardFormGroup.reset();
    this.onFunctionChange();
    this.selectedRadioId = this.searchTypes[SectionEnum.NOT_STORED_IDX];
  }

  maxMonth() {
    const data = new Date(this.dashboardFormGroup.controls.competenceDateFrom.value);
    data.setMonth(data.getMonth() + 2);
    data.setDate(data.getDate() - 1);
    return  data;
  }

  validateDate(controlNameFrom: string, controlNameTo: string) {

    return (formGroup: UntypedFormGroup) => {
      const controlFrom = formGroup.controls[controlNameFrom];
      const controlTo = formGroup.controls[controlNameTo];
      if (!controlFrom.value || ! controlTo.value) {
        if (!controlFrom.value) {
          controlFrom.setErrors({ empty: true });
        } else {
          controlFrom.setErrors(null);
        }
        if (!controlTo.value) {
          controlTo.setErrors({ empty: true });
        } else {
          controlTo.setErrors(null);
        }
      } else if (controlFrom.value
          && controlTo.value
          && controlFrom.value instanceof Date
          && controlTo.value instanceof Date) {
            const inputDateFrom = controlFrom.value;
            const inputDateTo = controlTo.value;
            inputDateFrom.setHours(0, 0, 0, 0);
            inputDateTo.setHours(0, 0, 0, 0);
            if (inputDateFrom > inputDateTo) {
              controlFrom.setErrors({ fromGreaterThenTo: true });
              controlFrom.markAsDirty();
              controlTo.setErrors({ fromGreaterThenTo: true });
              controlTo.markAsDirty();
              this.maxDateTo = inputDateTo.getTime() - inputDateFrom.getTime() / (24 * 3600 * 1000) > 60;
            } else if ((inputDateTo.getTime() - inputDateFrom.getTime()) / (24 * 3600 * 1000) > 60) {
              controlFrom.setErrors({ intervalTooBig: true });
              controlFrom.markAsDirty();
              controlTo.setErrors({ intervalTooBig: true });
              controlTo.markAsDirty();
            } else {
              controlFrom.setErrors(null);
              controlTo.setErrors(null);
            }
      } else {
          controlFrom.setErrors(null);
          controlTo.setErrors(null);
      }
    };
  }
}

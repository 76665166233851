import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PolicyContact} from '../../../models/domain-models/policy-contact';
import {Observable} from 'rxjs';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioContactResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  getPolicyContact(contract: string): Observable<PolicyContact> {
    return this.http.get<PolicyContact>(this.getPolicyContactUri(contract));
  }

  putPolicyContact$(proposalId, mainAddress, mail: any, channelType: number): Observable<PolicyContact> {
    const body: PolicyContact = new PolicyContact(mainAddress, mail, channelType);

    return this.http.put<PolicyContact>(this.putPolicyContactUri(proposalId), body);
  }

  getPolicyContactUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      contract +
      '/contact';
  }

  protected putPolicyContactUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/contact';
  }

}

import { PolicyService } from './../services/policy-service';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Directive({
  selector: '[licReinvestmentNumberFormatDirective]',
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class ReinvestmentNumberFormatDirective implements OnInit {
  element: ElementRef;
  decimalNumber = 2;

  constructor(
    el: ElementRef,
    public decPipe: RgiCountryLayerNumberFormatPipe,
    protected policyService: PolicyService) {
    this.element = el;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== '') {
      this.formattingInputNumber();
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== '') {
      this.resetFormat();
    }
  }

  @HostListener('keypress', ['$event'])
  onkeydown(event) {
    const value = event.key;
    const fieldVal = this.getNewString(event);

    const regexForKeyPress = /[\d\.,]/;
    if (regexForKeyPress.test(value)) {
      const regexForValue = /^\d+([\.,]\d{0,2})?$/;
      if (regexForValue.test(fieldVal)) {
        return true;
      }
    }

    return false;
  }

  getNewString(event) {
    const valoreAttualeInput = event.srcElement.value;
    const key = event.key;
    const splitValoreAttualeInput = valoreAttualeInput.split('');
    splitValoreAttualeInput.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), key);
    const nuovoValore = splitValoreAttualeInput.join('');
    return nuovoValore;
  }

  @HostListener('keyup', ['$event.key'])
  onkeyup(value) {
    this.element.nativeElement.value = this.element.nativeElement.value.replace('.', ',');
    return true;
  }

  ngOnInit() {}

  resetFormat() {
    const regex = /[.,\s]/g;
    this.element.nativeElement.value = this.element.nativeElement.value.replace(regex, '');

    const decimal = (this.element.nativeElement.value.substring(
      this.element.nativeElement.value.length - (+this.decimalNumber),
      this.element.nativeElement.value.length));

    const integer = (this.element.nativeElement.value.substring(
      0,
      this.element.nativeElement.value.length - (+this.decimalNumber)));

    if (+decimal === 0) {
      this.element.nativeElement.value = integer;
    } else {
      this.element.nativeElement.value = integer + '.' + decimal;
    }

  }

  formattingInputNumber() {
    this.element.nativeElement.value = this.element.nativeElement.value.replace(',', '.');

    if (this.decimalNumber > 0) {
      if (+this.element.nativeElement.value !== null) {
        const options = this.policyService.getFormatterOptionsWithDecimal('decimal', '.' + this.decimalNumber + '-' + this.decimalNumber);

        this.element.nativeElement.value = this.decPipe.transform(
          +this.element.nativeElement.value, this.policyService.currentLocale, options);
      } else {
        this.element.nativeElement.value = this.decPipe.transform(+this.element.nativeElement.value, this.policyService.currentLocale);
      }
    }
  }

}

import {Inject, Pipe, PipeTransform} from '@angular/core';
import {DateTimeAdapter, RgiRxDataTablePipe} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'gpDate'
})
export class GPDatePipe implements PipeTransform {

  constructor(
    protected i18nService: RgiRxTranslationService,
    protected datePipe: DatePipe,
    @Inject(DateTimeAdapter) protected dateTimeAdapter: any,
  ) {
  }

  transform(value: string, customFormat?: string): string {
    let format = customFormat || this.dateTimeAdapter.dateParser.localeFormats[this.i18nService.currentLanguage];
    format = format === 'M/dd/yyyy' ? 'MM/dd/yyyy' : format;
    return this.datePipe.transform(value, format);
  }

}

export function gpDateTablePipe(i18nService: RgiRxTranslationService, datePipe: DatePipe, dateTimeAdapter: typeof DateTimeAdapter): RgiRxDataTablePipe<GPDatePipe> {
  return {
    name: 'gpDatePipe',
    pipe: new GPDatePipe(i18nService, datePipe, dateTimeAdapter)
  };
}

import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  OnDestroy,
  Optional,
  QueryList,
  Self,
  ViewChild
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {ModalService} from '../../modal/modal.service';
import {RgiRxAbstractDropContainer} from '../rgi-rx-drag-drop.api';
import {RgiRxDropSelectionResolverService} from '../rgi-rx-drop-selection-resolver.service';
import {RgiRxOnDragBodyRemove} from '../rgi-rx-drag-body/rgi-rx-drag-body.component';
import {CdkDragDrop, CdkDropList} from '@angular/cdk/drag-drop';
import {RgiRxDragActionDirective} from '../rgi-rx-drag-action.directive';
import {RgiRxDragRemoveDirective} from '../rgi-rx-drag-remove.directive';
import {RgiRxDropBodyComponent} from '../rgi-rx-drop-body/rgi-rx-drop-body.component';
import {RgiRxDragLabelDirective} from '../rgi-rx-drag-label.directive';
import {RgiRxDragValueDirective} from '../rgi-rx-drag-value.directive';
import {RgiRxDropLabelDirective} from '../rgi-rx-drop-label.directive';


let rgiRxDropCounter = 0;

@Component({
  selector: 'rgi-rx-drop-container',
  templateUrl: './rgi-rx-drop-container.component.html',
  host: {
    '[class.rgi-ui-disabled]': 'disabled',
    '(change)': 'onChange($event.target.value)',
    '(blur)': 'onTouched()'
  },
    queries:{
        dragActions: new ContentChildren(RgiRxDragActionDirective),
        cdkDropList: new ViewChild(CdkDropList, {static:false}),
        dragRemove: new ContentChild(RgiRxDragRemoveDirective, {static:false}),
        dropBodyComponent: new ViewChild(RgiRxDropBodyComponent, {static: false}),
        dragLabelDirective: new ContentChild(RgiRxDragLabelDirective, {static:false}),
        dragValueDirective: new ContentChild(RgiRxDragValueDirective, {static:false}),
        dropLabelDirective: new ContentChild(RgiRxDropLabelDirective, {static:false})
    },
  inputs: [
    'viewField',
    'field',
    'label',
    'disabled',
    'connectedTo',
    'predicate',
    'select',
    'selectData'
  ],
  outputs: [
    'onDragEnter',
    'onDragExit',
    'onDrop',
    'onRemove',
    'onValueChange'
  ],
  providers: [
    {
      provide: RgiRxAbstractDropContainer, useExisting: RgiRxDropContainerComponent
    },
    RgiRxDropSelectionResolverService
  ]
})
export class RgiRxDropContainerComponent extends RgiRxAbstractDropContainer<any> implements AfterViewInit, OnDestroy {
  _dropListData: any[] = [];

  @ContentChildren(RgiRxDragActionDirective) dragActions: QueryList<RgiRxDragActionDirective>;
  @ViewChild(CdkDropList, {static: false}) cdkDropList: CdkDropList;
  @ContentChild(RgiRxDragRemoveDirective, {static: false}) dragRemove: RgiRxDragRemoveDirective;
  @ViewChild(RgiRxDropBodyComponent, {static: false}) dropBodyComponent: RgiRxDropBodyComponent;
  @ContentChild(RgiRxDragLabelDirective, {static: false}) dragLabelDirective: RgiRxDragLabelDirective;
  @ContentChild(RgiRxDragValueDirective, {static: false}) dragValueDirective: RgiRxDragValueDirective;
  @ContentChild(RgiRxDropLabelDirective, {static: false}) dropLabelDirective: RgiRxDropLabelDirective;

  constructor(
    private modalService: ModalService,
    @Self() @Optional() private _ngControl?: NgControl
  ) {
    super();
    // setting the value accessor here to prevent cyclic dependency
    if (_ngControl) {
      _ngControl.valueAccessor = this;
    }
    this.id = `rgi-rx-drop-container-${rgiRxDropCounter++}`;
  }

  ngAfterViewInit(): void {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.dispose();
  }


  writeValue(obj: any) {
    super.writeValue(obj);
    if (!!obj) {
      this._dropListData = [obj];
    }
    if (!obj) {
      this._dropListData = [];
    }
  }

  updateModel(selection: any) {
    this.model = this.getModel(selection, this.field);
    this.onChange(this.model);
    this._dropListData = [this.model];
    this.onValueChange.next({
      changed: this.model
    });
  }

  resolve() {
    this.dropBodyComponent.resolve();
  }


  remove(event: RgiRxOnDragBodyRemove): void {
    if (this.dragRemove && event.origin === 'drag') {
      this.dragRemove.onRemove.emit(event);
      return;
    }
    this.model = undefined;
    this.onChange(this.model);
    this.onRemove.emit({
      event: event.event,
      origin: event.origin,
      data: event.drag.model
    });
    this._dropListData = [];
  }

  drop(event: CdkDragDrop<any>): void {
    if (event.container === event.previousContainer) {
      return;
    }
    const targetContainer = this.getContainerFromCDKDropList(event.container) as RgiRxDropContainerComponent;
    targetContainer._dropListData = [event.item.data];
    targetContainer.model = event.item.data;
    targetContainer.onChange(targetContainer.model);
    const sourceContainer = this.getContainerFromCDKDropList(event.previousContainer) as RgiRxDropContainerComponent;
    sourceContainer._dropListData = [];
    sourceContainer.model = undefined;
    sourceContainer.onChange(sourceContainer.model);

    this.onDrop.next({
      source: {
        data: sourceContainer.model,
        element: sourceContainer.cdkDropList.element
      },
      target: {
        data: targetContainer.model,
        element: targetContainer.cdkDropList.element
      }
    });
  }
}

import { PVErrorSeverity, PVErrorType } from '../postsales-operations-response.model';


/**
 * TODO finish defining step config
 * ---
 * #### DATE:
 * @stepField id `'dates'`
 * @stepField label `'lpc_date_variation_step'`
 * @stepField fieldId `'dates'`
 * @stepField formName `'dates'`
 * ---
 * #### ROLES:
 * @stepField id `'operationRoles'`
 * @stepField label `'lpc_roles_variation'`
 * @stepField fieldId `'operationRoles'`
 * @stepField formName `'operationRoles'`
 * ---
 * #### POLICY_FACTORS:
 * @stepField id `'factors'`
 * @stepField label `'lpc_policy_factors'`
 * @stepField fieldId `'factors'`
 * @stepField formName `'factors'`
 * ---
 * #### ASSET_FACTORS:
 * @stepField id `'assetFactors'`
 * @stepField label `'lpc_asset_factors'`
 * @stepField fieldId `'assetFactors'`
 * @stepField formName `'assetFactors'`
 * ---
 * QUESTS_HLT:
 * @stepField id `'questsHlt'`
 * @stepField label `'lpc_health_questionnaire'`
 * @stepField fieldId `'questsHlt'`
 * @stepField formName `'questsHlt'`
 * ---
 * #### PROFILE_INVESTMENT:
 * @stepField id `'investmentProfiles'`
 * @stepField label `'lpc_Investment_profiles'`
 * @stepField fieldId `'investmentProfiles'`
 * @stepField formName `'investmentProfiles'`
 * ---
 * #### FUND_INVESTMENT:
 * @stepField id `'investmentFunds'`
 * @stepField label `'lpc_investment_funds'`
 * @stepField fieldId `'investmentFunds'`
 * @stepField formName `'investmentFunds'`
 * ---
 * #### QUOTATION:
 * @stepField id `'quotation'`
 * @stepField label `'lpc_quotation_step'`
 * @stepField fieldId `'quotation'`
 * @stepField formName `'quotation'`
 * ---
 * #### RISKS:
 * @stepField id `'risks'`
 * @stepField label `'Risk'`
 * @stepField fieldId `'risks'`
 * @stepField formName `'risks'`
 * ---
 * #### WARNING:
 * @stepField id `'warnings'`
 * @stepField label `'lpc_warnings'`
 * @stepField fieldId `'warnings'`
 * @stepField formName `''`
 * ---
 * #### DOCUMENT:
 * @stepField id `'documents'`
 * @stepField label `'lpc_confirm_variation_step'`
 * @stepField fieldId `'documents'`
 * @stepField formName `''`
 * ---
 */
export const STEP: StepperConfiguration = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates'
  },
  ROLES: {
    id: 'operationRoles',
    label: 'lpc_roles_variation',
    fieldId: 'operationRoles',
    formName: 'operationRoles'
  },
  POLICY_FACTORS: {
    id: 'factors',
    label: 'lpc_policy_factors',
    fieldId: 'factors',
    formName: 'factors'
  },
  ASSET_FACTORS: {
    id: 'assetFactors',
    label: 'lpc_asset_factors',
    fieldId: 'assetFactors',
    formName: 'assetFactors'
  },
  QUESTS_HLT: {
    id: 'questsHlt',
    label: 'lpc_health_questionnaire',
    fieldId: 'questsHlt',
    formName: 'questsHlt'
  },
  BENEFICIARIES: {
    id: 'beneficiaries',
    label: 'lpc_life_detail_beneficiaries',
    fieldId: 'beneficiary',
    formName: 'beneficiaries'
  },
  PROFILE_INVESTMENT: {
    id: 'investmentProfiles',
    label: 'lpc_Investment_profiles',
    fieldId: 'investmentProfiles',
    formName: 'investmentProfiles'
  },
  FUND_INVESTMENT: {
    id: 'investmentFunds',
    label: 'lpc_investment_funds',
    fieldId: 'investmentFunds',
    formName: 'investmentFunds'
  },
  QUOTATION: {
    id: 'quotation',
    label: 'lpc_quotation_step',
    fieldId: 'quotation',
    formName: 'quotation'
  },
  RISKS: {
    id: 'risks',
    label: 'Risk',
    fieldId: 'risks',
    formName: 'risks'
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: ''
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: ''
  },
  QUESTS_FIN: {
    id: 'questsFin',
    label: 'lpc_financial_questionnaire_step',
    fieldId: 'questsFin',
    formName: ''
  },
  QUESTS: {
    id: 'quests',
    label: 'lpc_avc_questionnaire',
    fieldId: 'quests',
    formName: ''
  },
  CONFERMA: {
    id: 'conferma',
    label: 'lpc_confirm_variation_step',
    fieldId: 'conferma',
    formName: ''
  },
  PAYMENT: {
    id: 'payment',
    label: 'lpc_payment_data',
    fieldId: 'payment',
    formName: 'payment'
  },
  NOTES: {
    id: 'notes',
    label: 'Declarative Appendix',
    fieldId: 'notes',
    formName: 'notes'
  },
  CONCLUDE: {
    id: 'conclude',
    label: '',
    fieldId: 'conclude',
    formName: ''
  },
  FLOW: {
    id: 'flow',
    label: '',
    fieldId: 'flow',
    formName: ''
  }
};

/**
 * @description
 * Model of the Stepper which you can find the id, label, fieldId and the formName
 */
export interface StepperConfiguration {
  /**
   * @stepField id `'dates'`
   * @stepField label `'lpc_date_variation_step'`
   * @stepField fieldId `'dates'`
   * @stepField formName `'dates'`
   */
  DATE: StepConfiguration;
  /**
   * @stepField id `'operationRoles'`
   * @stepField label `'lpc_roles_variation'`
   * @stepField fieldId `'operationRoles'`
   * @stepField formName `'operationRoles'`
   */
  ROLES?: StepConfiguration;
  /**
   * @stepField id `'factors'`
   * @stepField label `'lpc_policy_factors'`
   * @stepField fieldId `'factors'`
   * @stepField formName `'factors'`
   */
  POLICY_FACTORS?: StepConfiguration;
  /**
   * @stepField id `'assetFactors'`
   * @stepField label `'lpc_asset_factors'`
   * @stepField fieldId `'assetFactors'`
   * @stepField formName `'assetFactors'`
   */
  ASSET_FACTORS?: StepConfiguration;
  /**
   * @stepField id `'investmentProfiles'`
   * @stepField label `'lpc_Investment_profiles'`
   * @stepField fieldId `'investmentProfiles'`
   * @stepField formName `'investmentProfiles'`
   */
  PROFILE_INVESTMENT?: StepConfiguration;
  /**
   * @stepField id `'investmentFunds'`
   * @stepField label `'lpc_investment_funds'`
   * @stepField fieldId `'investmentFunds'`
   * @stepField formName `'investmentFunds'`
   */
  FUND_INVESTMENT?: StepConfiguration;
  /**
   * @stepField id `'risks'`
   * @stepField label `'Risk'`
   * @stepField fieldId `'risks'`
   * @stepField formName `'risks'`
   */
  RISKS?: StepConfiguration;
  /**
   * @stepField id `'quotation'`
   * @stepField label `'lpc_quotation_step'`
   * @stepField fieldId `'quotation'`
   * @stepField formName `'quotation'`
   */
  QUOTATION?: StepConfiguration;
  /**
   * @stepField id `'warnings'`
   * @stepField label `'lpc_warnings'`
   * @stepField fieldId `'warnings'`
   * @stepField formName `''`
   */
  WARNING?: StepConfiguration;
  /**
   * @stepField id `'documents'`
   * @stepField label `'lpc_confirm_variation_step'`
   * @stepField fieldId `'documents'`
   * @stepField formName `''`
   */
  DOCUMENT: StepConfiguration;
  /**
   * @stepField id `'quests'`
   * @stepField label `'lpc_avc_questionnaire'`
   * @stepField fieldId `'quests'`
   * @stepField formName `''`
   */
  QUESTS?: StepConfiguration;
  /**
   * @stepField id `'questsFin'`
   * @stepField label `'lpc_financial_questionnaire_step'`
   * @stepField fieldId `'questsFin'`
   * @stepField formName `''`
   */
  QUESTS_FIN?: StepConfiguration;
  /**
   * @stepField id `'questsFin'`
   * @stepField label `'lpc_financial_questionnaire_step'`
   * @stepField fieldId `'questsFin'`
   * @stepField formName `''`
   * confirm step that won't be displayed
   */
  QUESTS_UNIT?: StepConfiguration;
  /**
   * @stepField id `'questsHlt'`
   * @stepField label `'lpc_health_questionnaire'`
   * @stepField fieldId `'questsHlt'`
   * @stepField formName `'questsHlt'`
   * health questionnaire step
   */
  QUESTS_HLT?: StepConfiguration;
  /**
   * @stepField id: `'beneficiaries'`
   * @stepField label: `'lpc_life_detail_beneficiaries'`
   * @stepField fieldId: `'beneficiary'`
   * @stepField formName: `'beneficiaries'`
  */
  BENEFICIARIES?: StepConfiguration;
  /**
   * @stepField id `'conferma'`
   * @stepField label `'lpc_confirm_variation_step'`
   * @stepField fieldId `'conferma'`
   * @stepField formName `''`
   * confirm step that won't be displayed
   */
  CONFERMA?: StepConfiguration;
  /**
   * @stepField id `'payment'`
   * @stepField label `'lpc_mean_of_payment'`
   * @stepField fieldId `'payment'`
   * @stepField formName `'payment'`
   */
  PAYMENT?: StepConfiguration;
  /**
   * @stepField id `'notes'`
   * @stepField label `'Declarative Appendix'`
   * @stepField fieldId `'notes'`
   * @stepField formName `'notes'`
   */
  NOTES?: StepConfiguration;
  /**
   * @stepField id `'conclude'`
   * @stepField label `''`
   * @stepField fieldId `'conclude'`
   * @stepField formName `''`
   * conclude step returned by the service
   */
  CONCLUDE?: StepConfiguration;
  /**
   * @stepField id `'flow'`
   * @stepField label `''`
   * @stepField fieldId `'flow'`
   * @stepField formName `''`
   * fake step
   */
  FLOW?: StepConfiguration;
}

export interface StepConfiguration {
  id: string;
  label: string;
  fieldId: string;
  formName: string;
}


/**
 * @description
 * list of token used all around the card.
 * @tokenField `CORE_INJECTOR` = `'$injector'`
 * @tokenField `PV_GRAPHIC_FIX` = `'plcGraphicFix'`
 * @tokenField `CORE_AUTH_SERVICE` = `'authService'`
 * @tokenField `KARMA_ENV` = `'karma.environment'`
 * @tokenField `DOCUMENT_COMPONENT` = `'documentComponent'`
 * @tokenField `ENV` = `'environment'`
 * @tokenField `SHOW_LOADER_MOBILE` = `'showMobileLoader'`
 * @tokenField `ROLES_STEP_COMPONENT` = `'lpcRoleStep'`
 * @tokenField `POSTSALES_SERVICE` = `'lifePostsalesOperationsService'`
 * @tokenField `TOOLS_HIDE_ENUMS` = `'tools_hideEnumWithOneValue'`
 * @tokenField `K4U_ENV` = `'k4u.environment'`
 * @tokenField `OPERATION_LIST_COMPONENT` = `'operationsListComponent'`
 * @tokenField `CORE_SESSION_SERVICE` = `'sessionService'`
 * @tokenField `THRESHOLD_INPUT_TYPE` = `'thresholdInputType'`
 */
export const PV_TOKEN: LPCToken = {
  CORE_INJECTOR: '$injector',
  PV_GRAPHIC_FIX: 'plcGraphicFix',
  CORE_AUTH_SERVICE: 'authService',
  KARMA_ENV: 'karma.environment',
  DOCUMENT_COMPONENT: 'documentComponent',
  ENV: 'environment',
  SHOW_LOADER_MOBILE: 'showMobileLoader',
  ROLES_STEP_COMPONENT: 'lpcRoleStep',
  POSTSALES_SERVICE: 'lifePostsalesOperationsService',
  TOOLS_HIDE_ENUMS: 'tools_hideEnumWithOneValue',
  K4U_ENV: 'k4u.environment',
  OPERATION_LIST_COMPONENT: 'operationsListComponent',
  CORE_SESSION_SERVICE: 'sessionService',
  THRESHOLD_INPUT_TYPE: 'thresholdInputType',
  RGI_ANAG_PORTAL_AJS_FLOW: 'RGI_ANAG_PORTAL_AJS_FLOW'
};

/**
 * @description
 * model of the Token, here you can find each field value
 */
export interface LPCToken {
  /** @tokenField `CORE_INJECTOR` = `'$injector'` */
  CORE_INJECTOR: string;
  /** @tokenField `PV_GRAPHIC_FIX` = `'plcGraphicFix'` */
  PV_GRAPHIC_FIX: string;
  /** @tokenField `CORE_AUTH_SERVICE` = `'authService'` */
  CORE_AUTH_SERVICE: string;
  /** @tokenField `KARMA_ENV` = `'karma.environment'` */
  KARMA_ENV: string;
  /** @tokenField `DOCUMENT_COMPONENT` = `'documentComponent'` */
  DOCUMENT_COMPONENT: string;
  /** @tokenField `ENV` = `'environment'` */
  ENV: string;
  /** @tokenField `SHOW_LOADER_MOBILE` = `'showMobileLoader'` */
  SHOW_LOADER_MOBILE: string;
  /** @tokenField `ROLES_STEP_COMPONENT` = `'lpcRoleStep'` */
  ROLES_STEP_COMPONENT: string;
  /** @tokenField `POSTSALES_SERVICE` = `'lifePostsalesOperationsService'` */
  POSTSALES_SERVICE: string;
  /** @tokenField `TOOLS_HIDE_ENUMS` = `'tools_hideEnumWithOneValue'` */
  TOOLS_HIDE_ENUMS: string;
  /** @tokenField `K4U_ENV` = `'k4u.environment'` */
  K4U_ENV: string;
  /** @tokenField `OPERATION_LIST_COMPONENT` = `'operationsListComponent'` */
  OPERATION_LIST_COMPONENT: string;
  /** @tokenField `CORE_SESSION_SERVICE` = `'sessionService'` */
  CORE_SESSION_SERVICE: string;
  /** @tokenField `THRESHOLD_INPUT_TYPE` = `'thresholdInputType'` */
  THRESHOLD_INPUT_TYPE: string;
  /** @tokenField `RGI_ANAG_PORTAL_AJS_FLOW` = `'RGI_ANAG_PORTAL_AJS_FLOW'` */
  RGI_ANAG_PORTAL_AJS_FLOW: string;
}


export const APP_SETTING = {
  REST_BASE_URL: '/v2/'
};


export const POST_SALES_SESSION_NAME = 'postsalesSessionCard';


export const JS_EVENT = {
  /**
   * @description ANAG_MANAGE field with two more field that specify the correct params to call the anag modal.
   * @example
   * ```json
   * this.eventPropagation.emit(
   *  event: {
   *    eventName: JS_EVENT.ANAG_MANAGE.SEARCH.eventName,
   *    sessionParent: JS_EVENT.ANAG_MANAGE.SEARCH.sessionParent,
   *    sessionRoute: 'home',
   *    sessionTitle: JS_EVENT.ANAG_MANAGE.SEARCH.sessionTitle,
   *    navigationDisabled: true,
   *    roleCode: target.roleCodeToAdd
   *  });
   * ```
   * @see #openSubjectModal in [anag.service.ts](./../../services/anag.service.ts)
   */
  ANAG_MANAGE: {
    SEARCH: {
      eventName: 'requireSubject',
      sessionParent: 'anagFinder',
      sessionTitle: 'Seleziona soggetto'
    },
    /**
     * @jsonField OPEN_MODIFY
     * ```json
     * {eventName: 'editSubj'}
     * ```
     */
    OPEN_MODIFY: {
      eventName: 'editSubj'
    },
    OPEN_DETAIL: 'openSubjDetail'
  },
  /** @description LOADER field with two more field that specify the correct event name to call the loader and to stop it. */
  LOADER: {
    /**  @jsonField START = `startLoader` */
    START: 'startLoader',
    /**  @jsonField STOP = `stopLoader` */
    STOP: 'stopLoader'
  },
  /**
   * @value SET_TITLE: 'setTitle'
   * @jsonField SET_TITLE
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'setTitle', title: ''})
   * ```
   */
  SET_TITLE: 'setTitle',
  /**
   * @value OPEN_CLAIM_DETAIL: 'claimDetail'
   * @jsonField OPEN_CLAIM_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'claimDetail', claimId: id})
   * ```
   */
  OPEN_CLAIM_DETAIL: 'claimDetail',
  /**
   * @value UPDATE_CLAIM_DETAIL: 'newClaim'
   * @jsonField UPDATE_CLAIM_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'newClaim', claimDetail: id})
   * ```
   */
  UPDATE_CLAIM_DETAIL: 'newClaim',
  /**
   * @value BACK_TO_CLAIM_LIST: 'backToClaimList'
   * @jsonField BACK_TO_CLAIM_LIST
   * @usage
   * ```typeScript
   * eventEmitter.emit('backToClaimList')
   * ```
   */
  BACK_TO_CLAIM_LIST: 'backToClaimList',
  /**
   * @value OPEN_FINANCIAL_LIST: 'list'
   * @jsonField OPEN_FINANCIAL_LIST
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'list', info: {}})
   * ```
   */
  OPEN_FINANCIAL_LIST: 'list',
  /**
   * @value OPEN_FUNDS_DETAIL: 'fundsDetails'
   * @jsonField OPEN_FUNDS_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'fundsDetails', compose: {}})
   * ```
   */
  OPEN_FUNDS_DETAIL: 'fundsDetails',
  /**
   * @value OPEN_PROFILE_DETAIL: 'profileDetail'
   * @jsonField OPEN_PROFILE_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'profileDetail', idCrypt: idcrypt})
   * ```
   */
  OPEN_PROFILE_DETAIL: 'profileDetail',
  /**
   * @value REMOVE_CURRENT_SESSION: 'removeCurrentSession'
   * @jsonField REMOVE_CURRENT_SESSION
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'removeCurrentSession'})
   * ```
   */
  REMOVE_CURRENT_SESSION: 'removeCurrentSession',
  /**
   * @value OPEN_PROFILE_ALLOC_SESSION: 'profileAlloc'
   * @jsonField OPEN_PROFILE_ALLOC_SESSION
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'profileAlloc', data: {}})
   * ```
   */
  OPEN_PROFILE_ALLOC_SESSION: 'profileAlloc',
  /**
   * @value BACK_TO_FINANCIAL_LIST: 'backToList'
   * @jsonField BACK_TO_FINANCIAL_LIST
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'backToList', info: {}})
   * ```
   */
  BACK_TO_FINANCIAL_LIST: 'backToList',
  /**
   * @value OPEN_TREND_LIST: 'trendDetail'
   * @jsonField OPEN_TREND_LIST
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'trendDetail', policyId: id})
   * ```
   */
  OPEN_TREND_LIST: 'trendDetail',
  /**
   * @value CLOSE_LIFE_DETAIL: 'closeDetail'
   * @jsonField CLOSE_LIFE_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit('closeDetail')
   * ```
   */
  CLOSE_LIFE_DETAIL: 'closeDetail',
  /**
   * @value OPEN_CONTRACTUAL_OPTION_DETAIL: 'optionDetail'
   * @jsonField OPEN_CONTRACTUAL_OPTION_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'optionDetail', option: {}})
   * ```
   */
  OPEN_CONTRACTUAL_OPTION_DETAIL: 'optionDetail',
  /**
   * @value OPEN_MOVEMENT_DETAIL: 'movementDetail'
   * @jsonField OPEN_MOVEMENT_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'movementDetail', movementDetail: {}})
   * ```
   */
  OPEN_MOVEMENT_DETAIL: 'movementDetail',
  /**
   * @value OPEN_FISCALDATA_DETAIL: 'fiscalDataDetail'
   * @jsonField OPEN_FISCALDATA_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'fiscalDataDetail', fiscalDataDetail: {}})
   * ```
   */
  OPEN_FISCALDATA_DETAIL: 'fiscalDataDetail',
  /**
   * @value OPEN_REINVESTDATA_DETAIL: 'reinvestDataDetail'
   * @jsonField OPEN_REINVESTDATA_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'reinvestDataDetail', reinvestDataDetail: {}})
   * ```
   */
  OPEN_REINVESTDATA_DETAIL: 'reinvestDataDetail',
  /**
   * @value OPEN_LOANTDATA_DETAIL: 'loanDataDetail'
   * @jsonField OPEN_LOANTDATA_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'loanDataDetail', loanDataDetail: {}})
   * ```
   */
  OPEN_LOANDATA_DETAIL: 'loanDataDetail',
  /**
   * @value OPEN_EXPIRYOPTION_DETAIL: 'expiryOptionDetail'
   * @jsonField OPEN_EXPIRYOPTION_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'expiryOptionDetail', loanDataDetail: {}})
   * ```
   */
  OPEN_EXPIRYOPTION_DETAIL: 'expiryOptionDetail',
  /**
   * @value OPEN_INVESTMENT_PLAN: 'investmentPlan'
   * @jsonField OPEN_INVESTMENT_PLAN
   * @usage
   * ```typeScript
   * eventEmitter.emit({'investmentPlan'})
   * ```
   */
  OPEN_INVESTMENT_PLAN_DETAIL: 'investmentPlanDetail',
  /**
   * @value JUMP_PASS_DETAIL: 'jumpToConsultation'
   * @jsonField JUMP_PASS_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({'jumpToConsultation', idPolicy: id})
   * ```
   */
  JUMP_PASS_DETAIL: 'jumpToConsultation',
  /**
   * @value CLOSE_ALL_SESSIONS: 'exit'
   * @jsonField CLOSE_ALL_SESSIONS
   * @usage
   * ```typeScript
   * eventEmitter.emit('exit')
   * ```
   */
  CLOSE_ALL_SESSIONS: 'exit',
  /**
   * @value NAV_LIFE_ISSUE_PAGE: 'goToLifeIssueResult'
   * @jsonField NAV_LIFE_ISSUE_PAGE
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'goToLifeIssueResult', ...})
   * ```
   */
  NAV_LIFE_ISSUE_PAGE: 'goToLifeIssueResult',
  /**
   * @value NAV_LIFE_MODIFY_QUOTE: 'modify_quote'
   * @jsonField NAV_LIFE_MODIFY_QUOTE
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'modify_quote', ...})
   * ```
   */
  NAV_LIFE_MODIFY_QUOTE: 'modify_quote',
  /**
   * @value NAV_LIFE_BUY_QUOTE: 'buy_quote'
   * @jsonField NAV_LIFE_BUY_QUOTE
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'buy_quote', ...})
   * ```
   */
  NAV_LIFE_BUY_QUOTE: 'buy_quote',
  /**
   * @value NAV_LIFE_MODIFY_INDEF_PROP: 'modify_indefinite_proposal'
   * @jsonField NAV_LIFE_MODIFY_INDEF_PROP
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'modify_indefinite_proposal', ...})
   * ```
   */
  NAV_LIFE_MODIFY_INDEF_PROP: 'modify_indefinite_proposal',
  /**
   * @value OPEN_BOOKING_DETAIL: 'bookingDetail'
   * @jsonField OPEN_BOOKING_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'bookingDetail', data: {}})
   * ```
   */
  OPEN_BOOKING_DETAIL: 'bookingDetail',
  /**
   * @value OPEN_PIP_DATA: 'pipData'
   * @jsonField OPEN_PIP_DATA
   * @usage
   * ```typeScript
   * eventEmitter.emit({eventName: 'pipData', data: {}})
   * ```
   */
  OPEN_PIP_DATA: 'pipData',
  /**
   * @value BACK_TO_CONTRACT_LIST: 'backToResult'
   * @jsonField BACK_TO_CONTRACT_LIST
   * @usage
   * ```typeScript
   * eventEmitter.emit('backToResult')
   * ```
   */
  BACK_TO_CONTRACT_LIST: 'backToResult',
  /**
   * @value BACK_TO_LIFE_DETAIL: 'backToDetails'
   * @jsonField BACK_TO_LIFE_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit('backToDetails')
   * ```
   */
  BACK_TO_LIFE_DETAIL: 'backToDetails',
  /**
   * @value DOWNLOAD_DOC: 'downloadDocument'
   * @jsonField DOWNLOAD_DOC
   * @usage
   * ```typeScript
   * eventEmitter.emit('downloadDocument')
   * ```
   */
  DOWNLOAD_DOC: 'downloadDocument',
  /**
   * @value OPEN_PREMIUM_DETAIL: 'openPremiumDetail'
   * @jsonField OPEN_PREMIUM_DETAIL
   * @usage
   * ```typeScript
   * eventEmitter.emit('openPremiumDetail')
   * ```
   */
  OPEN_PREMIUM_DETAIL: 'openPremiumDetail',
  /**
   * @value PASSPRO_PRO_CARDS: 'cardSurvey'
   * @jsonField PASSPRO_PRO_CARDS
   * @usage
   * ```typeScript
   * eventEmitter.emit('cardSurvey')
   * ```
   */
  PASSPRO_PRO_CARDS: 'cardSurvey'
};

export const JS_ROUTES = {
  POSTSALES_CARD: 'home',
  POSTSALES_SESSION: 'home',
  LIFE_POLICY_DETAIL: 'detail-life',
  LIFE_PIPDATA_DETAIL: 'pipData-detail',
  LIFE_MOVEMENT_DETAIL: 'movement-detail',
  LIFE_FISCALDATA_DETAIL: 'fiscal-data-detail',
  LIFE_REINVESTDATA_DETAIL: 'reinvest-data-detail',
  LIFE_LOANDATA_DETAIL: 'loan-data-detail',
  LIFE_EXPOPTION_DETAIL: 'expiry-option-detail',
  LIFE_TREND_DETAIL: 'trend-detail',
  LIFE_OPTION_DETAIL: 'option-detail',
  LIFE_CLAIMSREPORT: 'home',
  LIFE_CLAIMSLIST: 'home',
  LIFE_CLAIMS_DETAIL: 'claim-detail',
  LIFE_NEW_CLAIM: 'new-claim',
  LIFE_FINANCIAL_SEARCH_CARD: 'home',
  LIFE_FUNDS_LIST: 'home',
  LIFE_FUNDS_DETAIL: 'funds-detail',
  LIFE_PROFILES_DETAIL: 'profile-detail',
  LIFE_PROFILES_ALLOCATION: 'profile-allocation',
  LIFE_BOOKING_DETAIL: 'booking-detail',
  LIFE_PREMIUM_DETAIL: 'premium-detail',
  LIFE_INVESTMENT_PLAN_DETAIL: 'investment-plan-detail'
};

export const EMPTY_STR = '';

export const ERROR_TYPE_CURRENT: PVErrorType = 'currentStep';
export const ERROR_TYPE_PERSISTENT: PVErrorType = 'persistent';

export const SEVERITY_ERROR_BLOCKING: PVErrorSeverity = 'error';
export const SEVERITY_ERROR_AUTH: PVErrorSeverity = 'auth';
export const SEVERITY_ERROR_WARNING: PVErrorSeverity = 'warning';
export const SEVERITY_ERROR_INFO: PVErrorSeverity = 'info';

export const DEFAULT_CURRENCY_CODE = 'EUR';
export const DEFAULT_CURRENCY_SYMBOL = '€';


<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span *ngIf="!showOtherAddress" class="anag-icon-m rgi-ui-icon-placeholder"></span>
    <span *ngIf="showOtherAddress">
     <span class="anag-icon-m rgi-ui-icon-domicile"></span>
    </span>
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>{{modalLabel}}</span>
    <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
  </div>
  <div class="rgi-ui-panel-content">
    <div *ngIf="showOtherAddress">
      <form [formGroup]="otherAddressForm">
      <div rgi-rx-accordion [multi]="true">
        <rgi-rx-expansion-panel data-qa="anag-other-address">
          <rgi-rx-expansion-panel-header>
            {{'_ANAG_._LABEL_._ADDRESS_' | translate}}
          </rgi-rx-expansion-panel-header>
          <rgi-rx-form-field>
            <input style="margin-left: 10px" type="checkbox" rgiRxInput data-qa="anag-main-address" formControlName="mainAddress">
            <label rgiRxLabel translate>_ANAG_._LABEL_._PRINCIPAL_ADDRESS_</label>
          </rgi-rx-form-field>
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._TYPE_</label>
              <select rgiRxNativeSelect data-qa="anag-residence-type" formControlName="residenceType">
                <option *ngFor="let residence of typeDomicile" [value]="residence.codice">
                  {{residence.descrizione}}
                </option>
              </select>
            </rgi-rx-form-field>
          <rgi-anag-uma-address data-qa="residence" [addressType]="addressType" [inputAddress]="tmpAddress" [parentForm]="ubicationForm"
                                (outputAddress)="updateResidenceAddress($event)" [hideNormalize]="hideNormalize"
                                [emitOutputAddress]="updateAddrEmitter">
          </rgi-anag-uma-address>
        </rgi-rx-expansion-panel>
        <rgi-rx-expansion-panel data-qa="anag-availability-address">
          <rgi-rx-expansion-panel-header>
            {{'_ANAG_._LABEL_._AVAILABILITY_' | translate}}
          </rgi-rx-expansion-panel-header>
          <rgi-anag-availability-address [inputOtherAddress]="tmpOtherAddress" [parentForm]="ubicationForm" (outputOtherAddressAvailabilityValidity)="updateOtherAddressAvailabilityValidity($event)">
          </rgi-anag-availability-address>
        </rgi-rx-expansion-panel>
        <rgi-rx-expansion-panel data-qa="anag-contacts-address">
          <rgi-rx-expansion-panel-header>
            {{'_ANAG_._LABEL_._CONTACTS_' | translate}}
          </rgi-rx-expansion-panel-header>
          <rgi-anag-contact-address [inputOtherAddress]="tmpOtherAddress" [parentForm]="ubicationForm">
          </rgi-anag-contact-address>
        </rgi-rx-expansion-panel>
      </div>
      </form>
    </div>
    <div *ngIf="!showOtherAddress">
      <rgi-anag-uma-address data-qa="residence" [addressType]="addressType" [inputAddress]="tmpAddress" [parentForm]="ubicationForm"
                            (outputAddress)="updateResidenceAddress($event)" [hideNormalize]="hideNormalize"
                            [emitOutputAddress]="updateAddrEmitter" [isQuote]="data?.isQuote" [partyRole]="data?.partyRole">

      </rgi-anag-uma-address>
    </div>
  </div>
  <rgi-rx-snackbar tag="ubication-tag"></rgi-rx-snackbar>
  <rgi-rx-snackbar tag="normalization-tag"></rgi-rx-snackbar>
  <div class="rgi-ui-panel-footer">
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionConfirm()"
            data-qa="anag-btn-upd-residence" translate>_ANAG_._BTN_._CONFIRM_
    </button>
  </div>
</div>

<div class="section-title claim" *ngIf="!isDetail">
	<span *ngIf="!isSettlement">{{ '_CLAIMS_._DAMAGE_CARD' | translate }}</span>
	<button class="btn inline-btn claim pull-right" (click)="calculate()" [disabled]="!enabled || injuryCodeMissing">
{{ '_CLAIMS_._CALCULATE' | translate }}
		<span class="glyphicon glyphicon-modal-window"></span><!-- in attesa che sia disponibile il font rgi-calculator -->
	</button>
</div>

<form [formGroup]="paymentFormGroup">
	<div class="form-group row" *ngIf="enableManualExcess">
		<div class="col-sm-3 col-xs-12 form-check form-check-inline" style="margin-top: 29px">
			<input type="checkbox" class="btn-checkbox checkbox-unit" id="checkManualExcess"
				formControlName="checkManualExcess" attr.data-qa="limit-modal-chekbox-input-checkManualExcess"
				(change)="onChangeManualExcess($event)">
			<label class="form-check-label checkbox-label">{{ '_CLAIMS_._GROSS_EXCESS' | translate }}</label>
		</div>
		<div class="col-sm-3 col-xs-12">
			<label ng-bind="label">{{ '_CLAIMS_._AMOUNT' | translate }}</label>
			<input type="number" class="form-control" name="manualExcessAmount"
				attr.data-qa="limit-modal-manualExcessAmount-input" formControlName="manualExcessAmount" disabled />
		</div>
	</div>
</form>

<div class="container-fluid table-white">
    <ngb-accordion #accordion [closeOthers]="true" activeIds="ngb-panel-{{i}}" *ngFor="let tol of typeOfLossDamage; index as i">
        <ngb-panel id="ngb-panel-{{i}}">
			<ng-template ngbPanelHeader let-opened="opened">
				<div class="panel-header" [ngClass]="{'panel-opened': opened}">
					<button ngbPanelToggle class="btn btn-link btn-custom" style="font-weight: bold; text-decoration: none;" [ngStyle]="{'color': opened ? '#fff' : '#000'}"
						(click)="onClickAccordion(tol.idTol)">
						{{ tol.tolDescription | titlecase}}
					</button>
					<label class="lbl-link">
						<label class="lbl-link-label" [ngClass]="{'white-custom': opened}"> |{{ '_CLAIMS_._INPUT_AMOUNT' | translate }}: </label>
						<label class="lbl-link-value" [ngClass]="{'white-custom': opened}"> {{ tol.amountTolInput | currency:'EUR':true }} </label>
						<label class="lbl-link-label" [ngClass]="{'white-custom': opened}"> |{{ '_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate }}: </label>
						<label class="lbl-link-value" [ngClass]="{'white-custom': opened}"> {{tol.amountTolOutput || 0 | currency:'EUR':true}} </label>
						<label class="lbl-link-label" [ngClass]="{'white-custom': opened}"> |{{ '_CLAIMS_._INSURED_SETTLEMENT_AMOUNT' | translate }}: </label>
						<label class="lbl-link-value" [ngClass]="{'white-custom': opened}">
							{{tol.historicalErosionExcess.amountLiqInsured || 0 | currency:'EUR':true}} </label>
					</label>
					<button class="btn btn-warning pull-right" (click)="openDetailHistory(tol)"
					      style="height: 35px; margin-top: 2px;" *ngIf="mapTol && mapTol[tol.idTol] && mapTol[tol.idTol].enableHistoryExcess">
						{{ '_CLAIMS_._HISTORICAL_EROSION' | translate }}
						<span class="rgifont rgi-pencil"></span><!-- in attesa che sia disponibile il font rgi-calculator -->
					</button>
				</div>
			</ng-template>
			<ng-template ngbPanelContent>
				<!-- SEZIONE ADVANCE LIQ -->

				<div *ngIf="isDetail">
					<div class="container-fluid table-white">
						<div>
							<div class="col-md-3 col-sm-6 col-xs-8" *ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.excessClaimAmount > 0 && tol.historicalErosionExcess.enumAdvanceExcClLiq">
							{{ '_CLAIMS_._CLAIM_EXCESS' | translate }}: <span class="bold">{{mapEnum.get('advanceClaimExcess'+tol.idTol).descrizione}}</span>
							</div>
						</div>
						<div>
							<div class="col-md-3 col-sm-6 col-xs-8" *ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.deductiblePercentage > 0">
							{{ '_CLAIMS_._DEDUCTIBLE' | translate }}: <span class="bold">{{mapEnum.get('advanceDeductible'+tol.idTol).descrizione}}</span>
							</div>
						</div>
						<div>
							<div class="col-md-3 col-sm-6 col-xs-8" *ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.excessAggrAmount > 0 && tol.historicalErosionExcess.enumAdvanceExcAggrLiq">
						{{ '_CLAIMS_._EXCESS_AGGREGATE' | translate }}: <span class="bold">{{mapEnum.get('advanceAggregateExcess'+tol.idTol).descrizione}}</span>
							</div>
						</div>
						<div>
							<div class="col-md-3 col-sm-6 col-xs-8" *ngIf="tol.historicalErosionExcess && (tol.historicalErosionExcess.firstSirAmount > 0 || tol.historicalErosionExcess.secondSirAmount > 0)">
							{{ '_CLAIMS_._SIR' | translate }}: <span class="bold">{{mapEnum.get('advanceSir'+tol.idTol).descrizione}}</span>
							</div>
						</div>
					</div>
					<div style="margin-top:5px" class="col-md-12 col-sm-12 col-sx-12" *ngIf="mapEnum.get('isExclusionPresent'+tol.idTol)">
						<div class="col-md-3 col-sm-6 col-xs-8">{{ '_CLAIMS_._EXCLUDED_FROM_CALCULATION' | translate }}</div>

						<div *ngIf="mapEnum.get('excludeClaimExcess'+tol.idTol)" class="bold col-md-3 col-sm-6 col-xs-8">
						{{ '_CLAIMS_._CLAIM_EXCESS' | translate }}
						</div>

						<div *ngIf="mapEnum.get('excludeDeductible'+tol.idTol)" class="bold col-md-3 col-sm-6 col-xs-8">
						{{ '_CLAIMS_._DEDUCTIBLE' | translate }}
						</div>

						<div *ngIf="mapEnum.get('excludeAggregateExcess'+tol.idTol)" class="bold col-md-3 col-sm-6 col-xs-8">
						{{ '_CLAIMS_._EXCESS_AGGREGATE' | translate }}
						</div>

						<div *ngIf="mapEnum.get('excludeLimit'+tol.idTol)" class="bold col-md-3 col-sm-6 col-xs-8">
						{{ '_CLAIMS_._LIMIT' | translate }}
						</div>

						<div *ngIf="mapEnum.get('excludeFirstSir'+tol.idTol)" class="bold col-md-3 col-sm-6 col-xs-8">
						{{ '_CLAIMS_._FIRST_SIR' | translate }}
						</div>

						<div *ngIf="mapEnum.get('excludeSecondSir'+tol.idTol)" class="bold col-md-3 col-sm-6 col-xs-8">
						{{ '_CLAIMS_._SECOND_SIR' | translate }}
						</div>
					</div>
				</div>

				<form [formGroup]="typeOfLossFormGroup" *ngIf="!isDetail">
					<div class="container-fluid table-white">
						<div class="row-noborder" *ngIf="tol.historicalErosionExcess
						&& ((tol.historicalErosionExcess.enumAdvanceExcClLiq && tol.historicalErosionExcess.excessClaimAmount > 0)
						|| (tol.historicalErosionExcess.enumAdvanceExcAggrLiq && tol.historicalErosionExcess.excessAggrAmount > 0)
						|| (tol.historicalErosionExcess.enumAdvanceSir && (tol.historicalErosionExcess.firstSirAmount > 0 || tol.historicalErosionExcess.secondSirAmount))
						|| tol.historicalErosionExcess.deductiblePercentage > 0)">
							<div class="col-md-12 col-sm-12 col-xs-12">
								<label ng-bind="label" style="color: black;">{{ '_CLAIMS_._ADVANCE' | translate }} </label>
							</div>
						</div>
						<div class="row-noborder">
							<div
								*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.excessClaimAmount > 0 && tol.historicalErosionExcess.enumAdvanceExcClLiq">
								<div class="col-md-3 col-sm-6 col-xs-8">
									<label ng-bind="label">{{ '_CLAIMS_._CLAIM_EXCESS' | translate }} <pnc-required
											required="true"></pnc-required></label>
									<select class="core-select form-control"
										formControlName="advanceClaimExcess{{tol.idTol}}"
										id="advanceClaimExcess{{tol.idTol}}">
										<option></option>
										<option *ngFor="let enumAdvanceExcClLiq of enumAdvanceExcClLiqValues; "
											value={{enumAdvanceExcClLiq.codice}}>
											{{enumAdvanceExcClLiq.descrizione}}</option>
									</select>
									<span
										class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
								</div>
							</div>
							<div
								*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.deductiblePercentage > 0">
								<div class="col-md-3 col-sm-6 col-xs-8">
									<label ng-bind="label">{{ '_CLAIMS_._DEDUCTIBLE' | translate }} <pnc-required required="true">
										</pnc-required></label>
									<select class="core-select form-control"
										formControlName="advanceDeductible{{tol.idTol}}"
										id="advanceDeductible{{tol.idTol}}">
										<option></option>
										<option
											*ngFor="let enumAdvanceDeductibleLiq of enumAdvanceDeductibleLiqValues; "
											value={{enumAdvanceDeductibleLiq.codice}}>
											{{enumAdvanceDeductibleLiq.descrizione}}</option>
									</select>
									<span
										class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
								</div>
							</div>
							<div
								*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.excessAggrAmount > 0 && tol.historicalErosionExcess.enumAdvanceExcAggrLiq">
								<div class="col-md-3 col-sm-6 col-xs-8">
									<label ng-bind="label">{{ '_CLAIMS_._EXCESS_AGGREGATE' | translate }} <pnc-required
											required="true"></pnc-required></label>
									<select class="core-select form-control"
										formControlName="advanceAggregateExcess{{tol.idTol}}"
										id="advanceAggregateExcess{{tol.idTol}}">
										<option></option>
										<option *ngFor="let enumAdvanceExcAggrLiq of enumAdvanceExcAggrLiqValues; "
											value={{enumAdvanceExcAggrLiq.codice}}>
											{{enumAdvanceExcAggrLiq.descrizione}}</option>
									</select>
									<span
										class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
								</div>
							</div>
							<div
								*ngIf="tol.historicalErosionExcess && (tol.historicalErosionExcess.firstSirAmount > 0 || tol.historicalErosionExcess.secondSirAmount > 0)">
								<div class="col-md-3 col-sm-6 col-xs-8">
									<label ng-bind="label">{{ '_CLAIMS_._SIR' | translate }} <pnc-required
											required="true"></pnc-required></label>
									<select class="core-select form-control"
										formControlName="advanceSir{{tol.idTol}}"
										id="advanceSir{{tol.idTol}}">
										<option></option>
										<option *ngFor="let enumAdvanceSirlLiq of enumAdvanceSirLiqValues; "
											value={{enumAdvanceSirlLiq.codice}}>
											{{enumAdvanceSirlLiq.descrizione}}</option>
									</select>
									<span
										class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
										<label ng-bind="label" class="text-danger" style="font-size: 14px;" *ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.alwaysAdvanceCompany">
										{{ '_CLAIMS_._SIR_ADVANCED_BY_COMPANY_SOMETIMES' | translate }}:{{ '_CLAIMS_._YES' | translate }}
										</label>
								</div>
							</div>
						</div>
					</div>
					<!-- EXCLUDE CHECKS -->
					<div class="form-group row" *ngIf="economicConditionExclusion && typeOfLossFormGroup">
						<label style="display: block;">{{ '_CLAIMS_._EXCLUDE_FROM_CALCULATION' | translate }}</label>
						<div class="col-sm-2 col-xs-12 form-check form-check-inline"
							*ngIf=" tol.historicalErosionExcess.excessClaimAmount>0">
							<input type="checkbox" class="btn-checkbox checkbox-unit"
								id="excludeClaimExcess{{tol.idTol}}" formControlName="excludeClaimExcess{{tol.idTol}}"
								attr.data-qa="limit-modal-chekbox-input-checkExcludeClaimExcess">
							<label class="form-check-label checkbox-label">{{ '_CLAIMS_._CLAIM_EXCESS' | translate }}</label>
						</div>
						<div class="col-sm-2 col-xs-12 form-check form-check-inline"
							*ngIf="tol.historicalErosionExcess.deductiblePercentage>0">
							<input type="checkbox" class="btn-checkbox checkbox-unit"
								id="excludeDeductible{{tol.idTol}}" formControlName="excludeDeductible{{tol.idTol}}"
								attr.data-qa="limit-modal-chekbox-input-checkExcludeDeductible">
							<label class="form-check-label checkbox-label">{{ '_CLAIMS_._DEDUCTIBLE' | translate }}</label>
						</div>
						<div class="col-sm-2 col-xs-12 form-check form-check-inline"
							*ngIf="tol.historicalErosionExcess.excessAggrAmount>0">
							<input type="checkbox" class="btn-checkbox checkbox-unit"
								id="excludeAggregateExcess{{tol.idTol}}"
								formControlName="excludeAggregateExcess{{tol.idTol}}"
								attr.data-qa="limit-modal-chekbox-input-checkExcludeAggregateExcess">
							<label class="form-check-label checkbox-label">{{ '_CLAIMS_._EXCESS_AGGREGATE' | translate }}</label>
						</div>
						<div class="col-sm-2 col-xs-12 form-check form-check-inline" *ngIf="tol.limits && tol.limits.length">
							<input type="checkbox" class="btn-checkbox checkbox-unit" id="excludeLimit{{tol.idTol}}"
								formControlName="excludeLimit{{tol.idTol}}"
								attr.data-qa="limit-modal-chekbox-input-checkExcludeLimit">
							<label class="form-check-label checkbox-label">{{ '_CLAIMS_._LIMIT' | translate }}</label>
						</div>
						<div class="col-sm-2 col-xs-12 form-check form-check-inline"
						    *ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.firstSirAmount  > 0">
							<input type="checkbox" class="btn-checkbox checkbox-unit" id="excludeFirstSir{{tol.idTol}}"
								formControlName="excludeFirstSir{{tol.idTol}}"
								attr.data-qa="limit-modal-chekbox-input-checkExcludeFirstSir">
							<label class="form-check-label checkbox-label">{{ '_CLAIMS_._FIRST_SIR' | translate }}</label>
						</div>
						<div class="col-sm-2 col-xs-12 form-check form-check-inline"
						    *ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.secondSirAmount > 0">
							<input type="checkbox" class="btn-checkbox checkbox-unit" id="excludeSecondSir{{tol.idTol}}"
								formControlName="excludeSecondSir{{tol.idTol}}"
								attr.data-qa="limit-modal-chekbox-input-checkExcludeSecondSir">
							<label class="form-check-label checkbox-label">{{ '_CLAIMS_._SECOND_SIR' | translate }}</label>
						</div>
					</div>
				</form>
				<!-- FINE SEZIONE ADVANCE LIQ-->

				<!-- NUOVO LAYOUT FRANCHIGIA FISSA -->
				<div class="container-fluid table-white"
					*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.excessClaimAmount > 0">
					<div class="row">
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._CLAIM_EXCESS_POLICY_VALUES' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._CLAIM_EXCESS_RESIDUE' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 pull-right table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_.	' | translate }} </label>
						</div>
					</div>

					<!-- SE PRESENTE IL CAP -->
					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
							{{ '_CLAIMS_._CAP_INDICATOR' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumCapClaim.descrizione || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
							{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.resCapClAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.excessClErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.capClaimAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excessClResAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excessClaimAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
					<!-- FINE SE PRESENTE IL CAP -->

					<!-- SE NON PRESENTE IL CAP -->
					<div class="row"
						*ngIf="!(tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0)">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excessClaimAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excessClResAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.excessClErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
					<!-- FINE SE NON PRESENTE IL CAP -->

					<div class="row" *ngIf="tol.historicalErosionExcess.enumAdvanceExcessCl">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
					{{ '_CLAIMS_._ADVANCE' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumAdvanceExcessCl.descrizione || '-'}}</span>
						</div>
					</div>
				</div>

				<!-- PRIMA SIR -->
				<div class="container-fluid table-white"
					*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.firstSirAmount  > 0">
					<div class="row">
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._FIRST_SIR_POLICY_VALUES' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._FIRST_SIR_RESIDUE' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 pull-right table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._FIRST_SIR_ERODED_AMOUNT' | translate }} </label>
						</div>
					</div>

					<!-- SE PRESENTE IL CAP -->
					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._CAP_INDICATOR' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumCapClaim.descrizione || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.resCapClAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.firstSirErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.capClaimAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.firstSirResidualAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.firstSirAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
					<!-- FINE SE PRESENTE IL CAP -->

                    <!-- SE NON PRESENTE IL CAP -->
					<div class="row"
					  *ngIf="!(tol.historicalErosionExcess.enumCapClaim && tol.historicalErosionExcess.capClaimAmount > 0)">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.firstSirAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.firstSirResidualAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.firstSirErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
				    </div>
				    <!-- FINE SE NON PRESENTE IL CAP -->

					<div class="row" *ngIf="tol.historicalErosionExcess.enumAdvanceSir">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
					{{ '_CLAIMS_._ADVANCE' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumAdvanceSir.descrizione || '-'}}</span>
						</div>
					</div>
				</div>

				<!-- SCOPERTO -->
				<div class="container-fluid table-white"
					*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.deductiblePercentage > 0">

					<div class="row">
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._DEDUCTIBLE_POLICY_VALUES' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._DEDUCTIBLE_RESIDUE' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 pull-right table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._DEDUCTIBLE_ERODED_AMOUNTS' | translate }} </label>
						</div>
					</div>

					<div class="row">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._PERCENTAGE_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.deductiblePercentage || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._DEDUCTIBLE_AMOUNT_RESIDUE' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.deductibleResAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._CALCULATED_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.deductibleErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row" *ngIf="tol.historicalErosionExcess.deductibleMaxAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._MAXIMUM_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.deductibleMaxAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row" *ngIf="tol.historicalErosionExcess.deductibleMinAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._MINIMUM_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.deductibleMinAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
				</div>
				<!-- FINE SCOPERTO -->

				<!-- FRANCHIGIA AGGREGATA -->
				<div class="container-fluid table-white"
					*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.excessAggrAmount > 0">

					<div class="row">
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._EXCESS_AGGREGATE_POLICY_VALUES' | translate }}
							</label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._EXCESS_AGGREGATE_RESIDUE' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 pull-right table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._EXCESS_AGGREGATE_ERODED_AMOUNT' | translate }}
							</label>
						</div>
					</div>

					<!-- SE PRESENTE CAP -->
					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._CAP_INDICATOR' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumCapAggregate.descrizione || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.resCapAggrAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.excessAggrErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.capAggregAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excAggrResAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excessAggrAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
					<!-- FINE SE PRESENTE CAP -->

					<!-- SE NON PRESENTE CAP -->
					<div class="row"
						*ngIf="!(tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0)">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excessAggrAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.excAggrResAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._EXCESS_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.excessAggrErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
					<!-- FINE SE NON PRESENTE CAP -->

					<div class="row" *ngIf="tol.historicalErosionExcess.enumAdvanceExcessAggr">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
{{ '_CLAIMS_._ADVANCE' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumAdvanceExcessAggr.descrizione || '-'}}</span>
						</div>
					</div>
				</div>
				<!-- FINE FRANCHIGIA AGGREGATA -->

				<!-- SECONDA SIR -->
                <div class="container-fluid table-white"
					*ngIf="tol.historicalErosionExcess && tol.historicalErosionExcess.secondSirAmount > 0">

					<div class="row">
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._SECOND_SIR_POLICY_VALUES' | translate }}
							</label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._SECOND_SIR_RESIDUE' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 pull-right table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._SECOND_SIR_ERODED_AMOUNT' | translate }}
							</label>
						</div>
					</div>

					<!-- SE PRESENTE CAP -->
					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._CAP_INDICATOR' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumCapAggregate.descrizione || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.resCapAggrAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.secondSirErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._CAP_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.capAggregAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.secondSirResidualAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>

					<div class="row"
						*ngIf="tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.secondSirAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
					<!-- FINE SE PRESENTE CAP -->

					<!-- SE NON PRESENTE CAP -->
					<div class="row"
						*ngIf="!(tol.historicalErosionExcess.enumCapAggregate && tol.historicalErosionExcess.capAggregAmount > 0)">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.secondSirAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{(tol.historicalErosionExcess.secondSirResidualAmount | currency:'EUR':true) || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
						{{ '_CLAIMS_._SIR_AMOUNT' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span
								*ngIf="tol && tol.historicalErosionExcess">{{(tol.historicalErosionExcess.secondSirErodedAmount | currency:'EUR':true) || '-'}}</span>
						</div>
					</div>
					<!-- FINE SE NON PRESENTE CAP -->

					<div class="row" *ngIf="tol.historicalErosionExcess.enumAdvanceSir">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
							{{ '_CLAIMS_._ADVANCE' | translate }}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span>{{tol.historicalErosionExcess.enumAdvanceSir.descrizione || '-'}}</span>
						</div>
					</div>
				</div>

                <!-- FINE SECONDA SIR-->

				<!-- MASSIMALI -->
				<div class="container-fluid table-white" *ngIf="tol.limits && tol.limits.length > 0">

					<div class="row">
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._MAXIMUM_LEVELS_POLICY_VALUES' | translate }}
							</label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._MAXIMUM_LEVELS_RESIDUE' | translate }} </label>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-8 pull-right table-white semitable-damagecard claim">
							<label class="header-text-damagecard">{{ '_CLAIMS_._MAXIMUM_LEVELS_ERODED_AMOUNTS' | translate }}
							</label>
						</div>
					</div>

					<div class="row" *ngFor="let limit of tol.limits">
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
							{{limit.limitDescription | translate}}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span *ngIf="!(limit.isCounter)">
								{{(limit.limitValue | currency:'EUR':true) || '-'}}</span>
							<span *ngIf="limit.isCounter">
                {{limit.limitValue  || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
							{{limit.limitDescription | translate}}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span *ngIf="limit.limitAmountResidue >= 0 && !(limit.isCounter)">
								{{ (limit.limitAmountResidue | currency:'EUR':true) || '-'}}</span>
							<span *ngIf="limit.limitAmountResidue < 0 && !(limit.isCounter)" class="negative-residue">
                {{ (limit.limitAmountResidue | currency:'EUR':true) || '-'}}</span>
							<span *ngIf="limit.limitAmountResidue >= 0 && limit.isCounter">
								{{ limit.limitAmountResidue || '-'}}</span>
							<span *ngIf="limit.limitAmountResidue < 0 && limit.isCounter" class="negative-residue">
                {{ limit.limitAmountResidue  || '-'}}</span>
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9" style='padding-left:7px;'>
							{{limit.limitDescription | translate}}:
						</div>
						<div class="col-md-2 col-sm-4 col-xs-9">
							<span *ngIf="!(limit.isCounter)">
                {{(limit.limitAmountEroded | currency:'EUR':true) || '-'}}
              </span>
              <span *ngIf="limit.isCounter">
                {{ limit.limitAmountEroded  || '-'}}
              </span>
						</div>
					</div>

				</div>
				<!-- FINE MASSIMALI -->

				<!-- SPACCATURA IMPORTI COMPAGNIA/ASSICURATO -->
				<!--SOLUZIONE1-->
				<div class="row section-subtitle-doubleamount claim" style='width: 100%;'>
					<div class="col-md-3 col-sm-6 col-xs-9">
					{{ '_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate }}:
					</div>
					<div class="col-md-3 col-sm-6 col-xs-9" style="text-align: right;">
						{{tol.amountTolOutput || 0 | currency:'EUR':true}}
					</div>
					<div class="col-md-3 col-sm-6 col-xs-9">
					{{ '_CLAIMS_._INSURED_SETTLEMENT_AMOUNT' | translate }}:
					</div>
					<div class="col-md-3 col-sm-6 col-xs-9" style="text-align: right;">
						{{tol.historicalErosionExcess.amountLiqInsured || 0 | currency:'EUR':true}}
					</div>
				</div>
				<!--FS1-->
				<!-- IMPORTO TOTALE CAUSALE (CALCOLATO VIA WS) -->
				<div class="row section-subtitle claim" style='width: 100%; color: #fff;'>
					<div class="col-md-6 col-sm-8 col-xs-12">
					{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }} {{tol.tolDescription | translate}}
					</div>
					<div class="col-md-2 col-sm-3 col-xs-4 pull-right" style="text-align: right;">
						{{(tol.amountTolOutput || 0) | currency:'EUR':true}}
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<button type="button" class="btn btn-warning pull-right" style="margin-top: 20px;margin-bottom:20px"
							id="updateReserve{{tol.idTol}}" (click)="modifyReserve(tol)" *ngIf="!isDetail" translate>{{'_CLAIMS_._UPDATE_RESERVE' | translate}}</button>
					</div>
				</div>
			</ng-template>
		</ngb-panel>
	</ngb-accordion>
</div>

<!-- IMPORTO TOTALE (SOMMA LATO JS DEI TOTALI DELLE CAUSALI) -->
<div *ngIf="!isSingleCausal && totalAmount" class="row section-subtitle claim">
	<div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }}
	</div>
	<div class="col-md-4 col-sm-6 col-xs-12 text-padd-right pull-right">
		{{(totalAmount || 0) | currency:'EUR':true}}
	</div>
</div>
<br>
<div class="form-group">
	<div class="alert alert-danger" *ngIf="errorMessage!=null" role="alert">{{ errorMessage | translate }} </div>
  <div class="alert alert-warning" *ngIf="warningMessage!=null" role="alert">{{ warningMessage | translate }} </div>
</div>

<div class="modal-footer" *ngIf="enabled && !isDetail">
	<div class="btn-group btn-group-justified">
		<div class="btn-group">
			<button class="btn btn-primary colorBGBlu2" [disabled]="injuryCodeMissing" (click)="onConfirm()" data-qa="party-data-panel-confirm-button"
				translate>{{'_CLAIMS_._BUTTONS_._CONFIRM'}} </button>
		</div>
	</div>
</div>

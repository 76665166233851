<div class="rgi-ui-title-2 rgi-ui-default">
  <b>{{ 'CONTRACT_CONSULTATION.SEARCH_RESULTS' | translate }}</b>
</div>

<ng-container *ngIf="stateManager.getState$() | async as policyState">
  <h5 class="rgifont"
    *ngIf="policyState.policies.policyRows[0]?.oblivionType && policyState.policies.policyRows[0]?.oblivionType.code === '1'">
    <span class="rgi-ui-icon-info"></span>
    <span class="accordion-detail-title card-color-blue"> {{'CONTRACT_CONSULTATION.LABEL_ANONIMIZED' | translate}}</span>
  </h5>
</ng-container>

<rgi-rx-datatable [data]="tableData" [schema]="tableSchema"
                  [pageOptions]="pageOptions" [expansionModel]="customExpansionModel"
                  [expansionRow]="rowDetail">
  <ng-template rgiRxDataTableCell="description"  let-ctx>
    <button rgi-rx-button variant="dashed" color="secondary" readonly="true">{{ctx.row.description}}</button>
  </ng-template>
  <ng-template rgiRxDataTableCell="number" let-ctx>
      {{ctx.row.number}}
  </ng-template>
  <ng-template rgiRxDataTableCell="state" let-ctx>
      {{ctx.row.state.description}}
  </ng-template>
  <ng-template rgiRxDataTableCell="actions"  let-ctx>
    <button *ngIf="isCopyPolicyShowed(ctx.row)" (click)="copyPolicy(ctx.row)" color="info" class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-paste" data-qa="edit">
    </button>
    <button (click)="goToDetails(ctx.row)" color="info" class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-right-arrow" data-qa="detail">
    </button>
  </ng-template>
</rgi-rx-datatable>

<ng-template #rowDetail let-row>
  <div class="rgi-ui-grid">
    <div *ngIf="row.category.code !== CategoriesFilterEnum.LIFE" class="rgi-ui-text-5 rgi-ui-info rgi-ui-col">
      {{'CONTRACT_CONSULTATION.ANNUAL_PREMIUM_EXCEPT_RIGHTS' | translate}} {{row.premium | currency:row.currencyCode:row.currencySymbol:'1.2-2':'en'}}
    </div>
  </div>
  <div class="rgi-ui-grid">
    <div class="rgi-ui-text-5 rgi-ui-col">
      {{'CONTRACT_CONSULTATION.MANAGEMENT_NODE' | translate}}
    </div>
    <div class="rgi-ui-col">
      <b>{{row.nodeDescription}}</b>
    </div>
    <div class="rgi-ui-text-5 rgi-ui-col">
      {{'CONTRACT_CONSULTATION.PRODUCT' | translate}}
    </div>
    <div class="rgi-ui-col">
      <b>{{row.productDescrition}}</b>
    </div>
  </div>
  <div class="rgi-ui-grid">
    <div class="rgi-ui-text-5 rgi-ui-col">
      {{'CONTRACT_CONSULTATION.ISSUE_DATE' | translate}}
    </div>
    <div class="rgi-ui-col">
      <b>{{row.issueDate ? (row.issueDate  | date:'dd/MM/yyyy') : '-'}}</b>
    </div>
    <div class="rgi-ui-text-5 rgi-ui-col">
      {{'CONTRACT_CONSULTATION.EXPIRE_DATE' | translate}}
    </div>
    <div class="rgi-ui-col">
      <b>{{row.expirationDate ? (row.expirationDate | date:'dd/MM/yyyy' ) : '-'}}</b>
    </div>
  </div>
  <div class="rgi-ui-grid">
    <div class="rgi-ui-text-5 rgi-ui-col">
      {{'CONTRACT_CONSULTATION.CANCELLATION_DATE' | translate}}
    </div>
    <div class="rgi-ui-col">
      <b>{{row.cancellationDate ? (row.cancellationDate  | date:'dd/MM/yyyy') : '-'}}</b>
    </div>
    <div class="rgi-ui-text-5 rgi-ui-col">
      {{'CONTRACT_CONSULTATION.CURRENT_MOVEMENT' | translate}}
    </div>
    <div class="rgi-ui-col">
      <b>{{row.causative ? row.causative.description : '-'}}</b>
    </div>
  </div>
</ng-template>

<button rgi-rx-button class="cc-btn" color="secondary" (click)="back()" data-qa='back' translate>
  CONTRACT_CONSULTATION.BACK
</button>

import {Injectable} from '@angular/core';
import {PcVariable} from '../../../models/pc-portfolio-models/variables-models/pc-variable';
import {PolicyTechnicalData} from '../../../models/domain-models/parameters/policy-technical-data';
import {ProposalDataResponse} from '../../../models/pc-portfolio-models/proposal-models/proposal-data-response';
import {AgreementsContainer} from '../../../models/domain-models/parameters/agreements-container';
import {Agreement} from '../../../models/domain-models/parameters/agreement';
import {PaymentFrequencyContainer} from '../../../models/domain-models/parameters/payment-frequency-container';
import {PolicyDate} from '../../../models/domain-models/parameters/policy-date';
import {PcPaymentFrequency} from '../../../models/pc-portfolio-models/payment-frequency-models/pc-payment-frequency';
import {PcAgreement} from '../../../models/pc-portfolio-models/agreements-models/pc-agreement';
import {Variable} from '../../../models/domain-models/variable';
import {GenericEntity} from '../../../models/domain-models/generic-entity';
import {ProposalDataPutRequest} from '../../../models/pc-portfolio-models/proposal-models/proposal-data-put-request';
import {TacitRenewal} from '../../../models/domain-models/parameters/tacitRenewal';
import {PartyAsset, PartyRequest} from '../../../models/domain-models/party-asset';
import {ContractorDataResponse} from '../../../models/pc-portfolio-models/contractor-models/contractor-data-response';
import {ContractorDataRequest} from '../../../models/pc-portfolio-models/contractor-models/contractor-data-request';
import {Assets} from '../../../models/domain-models/assets';
import {Asset} from '../../../models/domain-models/asset';
import {AssetSection} from '../../../models/domain-models/asset-section';
import {Unit} from '../../../models/domain-models/unit';
import {AssetInstance} from '../../../models/domain-models/asset-instance';
import {PcProductAsset} from '../../../models/pc-portfolio-models/assets-models/pc-product-asset';
import {Factor} from '../../../models/pc-portfolio-models/factor-models/factor';
import {
  PcQuotAsset,
  PcQuotation,
  QuotSection,
  QuotUnit
} from '../../../models/pc-portfolio-models/quotation-models/pc-quotation';
import {
  AssetList,
  AssetProductPremium, ProposalStatus,
  Quotation,
  QuotationPremium,
  QuotationProductPremium
} from '../../../models/domain-models/quotation';
import {Instalment} from '../../../models/domain-models/instalment';
import {PcPremium, PremiumInstance} from '../../../models/pc-portfolio-models/quotation-models/pc-premium';
import {Warning} from '../../../models/domain-models/warning';
import {PcSection} from '../../../models/pc-portfolio-models/assets-models/pc-section';
import {
  PcAnswer,
  PcQuestion,
  PcQuestionnaireFlatIResponse,
  QuestionFlateResponse
} from '../../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {QuestionnaireFlatIResponse} from '../../../models/domain-models/questionnaires-flati-response';
import {QuestionFlatIResponse} from '../../../models/domain-models/question-flat-response';
import {AnswerFlatIResponse} from '../../../models/domain-models/answer-flatI-response';
import {Clause, ClausesResponse} from '../../../models/domain-models/clause';
import {PcClause} from '../../../models/pc-portfolio-models/quotation-models/pc-clause';
import {ValueFlatI} from '../../../models/domain-models/value-flatI';
import {PcUnit} from '../../../models/pc-portfolio-models/quotation-models/pc-unit';
import {Risk} from '../../../models/domain-models/risk';
import {PcVariableValue} from '../../../models/pc-portfolio-models/variables-models/pc-variable-value';
import {InstalmentPremiumGroup} from '../../../models/domain-models/instalment-premium-group';
import {Currency} from '../../../models/domain-models/parameters/currency';
import {AutomaticRenewal} from '../../../models/domain-models/automatic-renewal';
import {
  RegulationPremium,
  RegulationType
} from '../../../models/pc-portfolio-models/regulation-models/pc-regulation-premium';
import {PcUseTax} from '../../../models/pc-portfolio-models/us-taxes-models/pc-us-taxes';
import {UseTaxesContainer} from '../../../models/domain-models/parameters/use-tax-container';
import {UseTax} from '../../../models/domain-models/parameters/use-tax';
import {
  RegulationPremiumResponse
} from '../../../models/pc-portfolio-models/regulation-models/pc-regulation-premium-response';
import {IndexType} from '../../../models/domain-models/parameters/index-type';
import {Country} from '../../../models/domain-models/country';
import {ErrorMessage} from '../../../models/pc-portfolio-models/GenericErrorPcPortfolio';
import {PcAsset} from '../../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {PaymentFrequency} from '../../../models/api-models/api-payment-frequency';
import {PcInsuredAsset} from '../../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {AnagIssueApiResidence, AnagIssuePhoneNumber, AnagIssueSubject} from "../../re-issue-anag/anag-issue";

@Injectable({
  providedIn: 'root'
})
export class ReIssueRefactorService {

  constructor() {
  }

  variablesRefactor(pcVariables: Array<PcVariable>): Array<Variable> {
    const variables = new Array<Variable>();

    pcVariables.forEach((variable: PcVariable) => {
      variables.push(new Variable(
        null, // id: string,
        variable.code, // code: string,
        null, // description: string, MISSING DATA
        variable.code, // identificationCode: string, MISSING DATA
        true, // visible: boolean, MISSING DATA
        variable.mandatory, // compulsory: boolean,
        null, // dependent: boolean, MISSING DATA
        null, // transcoderCode1: string, MISSING DATA
        null, // transcoderCode2: string, MISSING DATA
        this.variablesValueRefactor(variable), // value: string,
        null, // valueDescription: string, MISSING DATA
        this.variableTypeRefactor(variable.type),
        variable.format, // format: string,
        null, // order: number,
        this.variablesValuesRefactor(variable.values), // variablesValue: Array<GenericEntity>,
        variable.description, // extendedDescription: string,
        null, // visibilityArea: number, MISSING DATA
        false, // editableInView: boolean, MISSING DATA
        null, // variableClass: number, MISSING DATA
        null, // dummyValue: string, MISSING DATA
        variable.modifiable, // editable: boolean
        variable.properties
      ));
    });

    return variables;
  }

  variablesValueRefactor(variable: PcVariable): string {
    let value = null;
    if (variable.value) {
      value = variable.value;
    } else if (variable.type === 'LIST') {
      value = '-1';
    }
    return value;
  }

  variableTypeRefactor(type: string): number {
    switch (type) {
      case 'LIST':
        return 0;
      case 'BOOL':
        return 3;
      case 'DATE':
        return 4;
      case 'NUM':
        return 2;
      case 'TEXT':
        return 5;
    }
  }

  variablesValuesRefactor(pcVariablesValues: PcVariableValue[]): GenericEntity[] {
    const variablesValues = new Array<GenericEntity>();

    if (pcVariablesValues) {
      pcVariablesValues.forEach((value: PcVariableValue) => {
        variablesValues.push(new GenericEntity(
          value.code, // id,
          null, // code MISSING DATA
          value.description // description
        ));
      });
    }

    return variablesValues;
  }

  toPcVariablesRefactor(variables: Variable[]): Array<PcVariable> {
    const pcVariables = new Array<PcVariable>();

    variables.forEach((variable: Variable) => {
      const pcVariable = new PcVariable();

      pcVariable.code = variable.code;
      pcVariable.description = variable.extendedDescription;
      pcVariable.format = variable.format;
      pcVariable.mandatory = variable.compulsory;
      pcVariable.modifiable = variable.editable;
      pcVariable.type = this.pcVariableTypeRefactor(variable.type);
      pcVariable.value = this.pcVariablesValueRefactor(variable);
      pcVariable.values = this.pcVariablesValuesRefactor(variable.values);

      pcVariables.push(pcVariable);
    });

    return pcVariables;
  }

  pcVariablesValuesRefactor(variablesValues: GenericEntity[]): Array<PcVariableValue> {
    const pcVariablesValues = new Array<PcVariableValue>();

    if (variablesValues) {
      variablesValues.forEach((value: GenericEntity) => {
        const pcVariableValue = new PcVariableValue();

        pcVariableValue.code = value.id;
        pcVariableValue.description = value.description;

        pcVariablesValues.push(pcVariableValue);
      });
    }

    return pcVariablesValues;
  }

  pcVariableTypeRefactor(type: number): string {
    switch (type) {
      case 0:
        return 'LIST';
      case 4:
        return 'DATE';
      case 3:
        return 'BOOL';
      case 2:
        return 'NUM';
      case 5:
        return 'TEXT';
    }
  }

  pcVariablesValueRefactor(variable: Variable): string {
    let value = null;
    if (variable.value && variable.value !== '-1') {
      value = variable.value;
    }
    return value;
  }

  policyTechnicalDataRefactor(
    proposalData: ProposalDataResponse,
    paymentFrequencies: Array<PcPaymentFrequency>,
    agreements: Array<PcAgreement>,
    currencies: Array<Currency>,
    useTaxes: Array<PcUseTax>,
    indexTypes: Array<IndexType>,
    lpsCountries: Array<Country>
  ): PolicyTechnicalData {
    return new PolicyTechnicalData(
      this.paymentFrequenciesRefactor(paymentFrequencies), // paymentFrequencyContainer,
      this.policyDateRefactor(proposalData.effectDate),
      proposalData.backdatingDays,
      proposalData.postdatingDays,
      proposalData.msgDateBack,
      proposalData.msgDatePost,
      this.policyDateRefactor(proposalData.policyExpiration), // expireDate
      this.policyDateRefactor(proposalData.issuingDate), // issueDate,
      proposalData.instalmentDates, // instalmentDates
      proposalData.coverageExpiration, // selectedInstalmentDate
      null, // policyTime
      this.agreementsRefactor(agreements, proposalData.agreement), // agreementContainer,
      this.useTaxesRefactor(useTaxes, proposalData.useTax),
      null, // taxTypeContainer, MISSING DATA
      null, // constraint, MISSING DATA
      null, // adjustment, MISSING DATA
      proposalData.premiumAdjustmentConfig,
      proposalData.minimumPremiumRegulationPercentage,
      null, // notes, MISSING DATA
      null, // contractAdjustment, MISSING DATA
      null, // contractContraint, MISSING DATA
      null, // contractNotDrivingDeclaration, MISSING DATA
      null, // commissionsRegime, MISSING DATA
      null, // notDrivingDeclaration, MISSING DATA
      null, // reloadVariables, MISSING DATA
      null, // recalculate, MISSING DATA
      null, // errorMessages, MISSING DATA
      null, // reloadInsuranceStatus, MISSING DATA
      null, // arrearsDays, MISSING DATA
      this.tacitRenewalRefactor(proposalData.automaticRenewal, paymentFrequencies), // tacitRenewal, MISSING DATA
      null, // paymentData, MISSING DATA
      null, // branchCode MISSING DATA
      currencies,
      proposalData.currency.code,
      proposalData.currency,
      this.regulationRefactor(proposalData.policyExpiration, proposalData.instalmentDates, proposalData.minimumPremiumRegulationPercentage, proposalData.additionalTechnicalData),
      proposalData.coInsuranceTypes,
      null,
      null,
      proposalData.binder,
      proposalData.indexationConfigPP,
      proposalData.indexType,
      this.getIndexTypes(indexTypes, proposalData.indexType),
      proposalData.coOwnerEnabled,
      proposalData.indirectCoInsuranceConfig,
      lpsCountries,
      proposalData.policyRiskCommon,
      proposalData.managerOfRelatedPolicies,
      proposalData.complementaryPolicies,
      proposalData.policySecondRisk,
      proposalData.additionalTechnicalData,
      proposalData.coInsurance,
      proposalData.subscriber ? this.proposalSubscriberToAnagIssueSubject(proposalData.subscriber) : null,
      this.proposalCoOwnersToAnagIssueSubjectCoOwners(proposalData.coOwners)
    );
  }

  private getIndexTypes(indexTypes: Array<IndexType>, indexType: IndexType): IndexType[] {
    if (indexType && indexTypes) {
      const foundIndex = indexTypes.find(index => index.code === indexType.code);
      if (foundIndex) {
        return [foundIndex];
      }
    }
    return [];
  }

  proposalToPolicyTechnicalData(
    initialData: PolicyTechnicalData,
    proposalData: ProposalDataResponse
  ): PolicyTechnicalData {
    return new PolicyTechnicalData(
      initialData.paymentFrequencyContainer, // paymentFrequencyContainer,
      this.policyDateRefactor(proposalData.effectDate),
      proposalData.backdatingDays,
      proposalData.postdatingDays, // effectiveDate,
      proposalData.msgDateBack,
      proposalData.msgDatePost,
      this.policyDateRefactor(proposalData.policyExpiration), // expireDate
      this.policyDateRefactor(proposalData.issuingDate), // issueDate,
      proposalData.instalmentDates,
      initialData.selectedInstalmentDate,
      null, // policyTime, MISSING DATA
      initialData.agreementContainer, // agreementContainer,
      initialData.useTaxContainer,
      initialData.taxTypeContainer, // taxTypeContainer,
      initialData.constraint, // constraint,
      initialData.adjustment, // adjustment,
      proposalData.premiumAdjustmentConfig,
      proposalData.minimumPremiumRegulationPercentage,
      initialData.notes, // notes,
      initialData.contractAdjustment, // contractAdjustment,
      initialData.contractContraint, // contractContraint,
      initialData.contractNotDrivingDeclaration, // contractNotDrivingDeclaration,
      initialData.commissionsRegime, // commissionsRegime,
      initialData.notDrivingDeclaration, // notDrivingDeclaration,
      initialData.reloadVariables, // reloadVariables,
      initialData.recalculate, // recalculate,
      proposalData.errorMessages, // errorMessages,
      initialData.reloadInsuranceStatus, // reloadInsuranceStatus,
      initialData.arrearsDays, // arrearsDays,
      this.tacitRenewalRefactor(proposalData.automaticRenewal, initialData.paymentFrequencyContainer.paymentFrequencyList), // tacitRenewal,
      initialData.paymentData, // paymentData,
      null, // branchCode MISSING DATA
      initialData.currencies,
      initialData.defaultCurrency,
      initialData.selectedCurrency,
      this.regulationRefactor(proposalData.policyExpiration, proposalData.instalmentDates, proposalData.minimumPremiumRegulationPercentage, proposalData.additionalTechnicalData),
      initialData.coInsuranceTypes,
      initialData.companies,
      initialData.intermediaries,
      initialData.binder,
      initialData.indexationConfigPP,
      initialData.indexType,
      initialData.indexTypes,
      initialData.coOwnerEnabled,
      initialData.indirectCoInsuranceConfig,
      initialData.lpsCountries,
      initialData.policyRiskCommon,
      initialData.managerOfRelatedPolicies,
      initialData.complementaryPolicies,
      initialData.policySecondRisk,
      initialData.additionalTechnicalData,
      initialData.coInsurance,
      initialData.subscriber,
      initialData.coOwners
    );
  }

  regulationRefactor(policyExpiration, instalmentDates, minimumRegulationPercentage , additionalTechnicalData) {
    let expirationDate;
    if(additionalTechnicalData?.regulationPremium && additionalTechnicalData?.regulationPremium?.regulation){
      return new RegulationPremiumResponse(
        additionalTechnicalData.regulationPremium,
        new Array<ErrorMessage>());
    }
    if (instalmentDates && instalmentDates.length !== 0) {
      expirationDate = instalmentDates[instalmentDates.length - 1];
    } else {
      expirationDate = policyExpiration;
    }
    return new RegulationPremiumResponse(
      new RegulationPremium(
        minimumRegulationPercentage,
        minimumRegulationPercentage,
        new RegulationType(null, null, null, null),
        null,
        '90',
        expirationDate,
        null,
        new PcPaymentFrequency(),
        null
      ),
      new Array<ErrorMessage>());
  }

  tacitRenewalRefactor(automaticRenewal: AutomaticRenewal, paymentFrequency): TacitRenewal {
    const frCode = paymentFrequency.find(x => x.selected === true);
    if (!frCode || frCode.paymentFrequencyType === 1 || frCode.paymentFrequencyType === 0) {
      return new TacitRenewal(
        automaticRenewal.value, // tacitRenewal: boolean
        automaticRenewal.status !== 2, // optional: boolean MISSING DATA
      );
    } else {
      return new TacitRenewal(
        false, // tacitRenewal: boolean
        false, // optional: boolean MISSING DATA
      );
    }
  }

  public policyDateRefactor(pcDate: any): PolicyDate {
    return new PolicyDate(
      this.formatToIsoString(pcDate), // date: string,
      null, // maxDate: number, MISSING DATA
      null, // minDate: number, MISSING DATA
      true, // modifiable: boolean, MISSING DATA
      null, // selected?: boolean, MISSING DATA
    );
  }

  formatToIsoString(date: any) {
    return new Date(date).toISOString();
  }

  paymentFrequenciesRefactor(pcPaymentFrequencies: Array<PcPaymentFrequency>): PaymentFrequencyContainer {
    const paymentFrequencyList = new Array<PaymentFrequency>();

    pcPaymentFrequencies.forEach(pcPaymentFrequency => {
      paymentFrequencyList.push(
        new PaymentFrequency(
          pcPaymentFrequency.code, // code
          pcPaymentFrequency.description, // description
          null, // instalmentNumber MISSING DATA
          null, // id MISSING DATA
          null, // intrestRate MISSING DATA
          null, // intrestRate2 MISSING DATA
          null, // instalmentType MISSING DATA
          null, // visible MISSING DATA
          null, // durationMax MISSING DATA
          null, // durationMin MISSING DATA
          false, // selected MISSING DATA
          null, // order MISSING DATA
          null, // usageOnQuotation MISSING DATA
          null, // usageOnProposal MISSING DATA
          null, // instalmentDates MISSING DATA
          pcPaymentFrequency.paymentFrequencyType,
          pcPaymentFrequency.derogationLevel
        )
      );
    });

    return new PaymentFrequencyContainer(
      paymentFrequencyList,
      true // compulsory MISSING DATA
    );
  }

  agreementsRefactor(pcAgreements: Array<PcAgreement>, agreementSelected: PcAgreement): AgreementsContainer {
    const agreements = new Array<Agreement>();

    pcAgreements.forEach(agreement => {
      agreements.push(
        new Agreement(
          null, // idAgreement
          agreement.description, // description
          agreement.code, // code
          null // selected MISSING DATA
        )
      );
    });

    return new AgreementsContainer(
      !!agreementSelected?.code ? this.updateSelectedAgreement(agreements, agreementSelected) : agreements,
      null // compulsory (TODO: missing)
    );
  }

  updateSelectedAgreement(agreements: Agreement[], pcAgreement: PcAgreement): Agreement[] {
    agreements.forEach(element => {
      element.selected = false;
      if (element.code === pcAgreement.code) {
        element.selected = true;
      }
    });
    return agreements;
  }

  useTaxesRefactor(pcUseTaxes: Array<PcUseTax>, useTaxSelected: PcUseTax): UseTaxesContainer {
    const useTaxes = new Array<UseTax>();

    pcUseTaxes.forEach(useTax => {
      useTaxes.push(
        new UseTax(
          null, // idAgreement
          useTax.description, // description
          useTax.code, // code
          useTax.identification,
          useTaxSelected ? useTax.code === useTaxSelected.code : false // selected
        )
      );
    });

    return new UseTaxesContainer(
      useTaxes,
      null // compulsory (TODO: missing)
    );
  }

  getProposalDataPutRequest(policyTechnicalData: PolicyTechnicalData): ProposalDataPutRequest {
    return new ProposalDataPutRequest(policyTechnicalData.effectiveDate.date,
      policyTechnicalData.expireDate.date,
      policyTechnicalData.issueDate.date,
      policyTechnicalData.binder);
  }

  getPaymentFrequency(policyTechnicalData: PolicyTechnicalData): PcPaymentFrequency {
    for (const paymentFrequency of policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList) {
      if (paymentFrequency.selected) {
        const paymentSelected = new PcPaymentFrequency();
        paymentSelected.code = paymentFrequency.code;
        paymentSelected.description = paymentFrequency.description;
        return paymentSelected;
      }
    }
    return null;
  }

  paymentFrequencyRefactor(paymentFrequencySelected: any): PcPaymentFrequency {
    const pcPaymentFrequency: PcPaymentFrequency = new PcPaymentFrequency();

    pcPaymentFrequency.code = paymentFrequencySelected.code;
    pcPaymentFrequency.description = paymentFrequencySelected.description;

    return pcPaymentFrequency;
  }

  getAgreement(policyTechnicalData: PolicyTechnicalData): PcAgreement {

    for (const agreement of policyTechnicalData.agreementContainer.agreements) {
      if (agreement.selected) {
        const agreementSelected = new PcAgreement();
        agreementSelected.code = agreement.code;
        agreementSelected.description = agreement.description;
        return agreementSelected;
      }
    }
    return null;
  }

  agreementRefactor(agreementSelected: any): PcAgreement {
    const pcAgreement: PcAgreement = new PcAgreement();

    pcAgreement.code = agreementSelected.code;
    pcAgreement.description = agreementSelected.description;

    return pcAgreement;
  }

  useTaxRefactor(useTaxSelected: any): PcUseTax {
    return new PcUseTax(useTaxSelected.code, useTaxSelected.description, useTaxSelected.identification);
  }

  contractorResponseRefactor(contractorData: ContractorDataResponse): PartyAsset {
    const partyAsset = new PartyAsset();

    partyAsset.party.idLatestPhotos = contractorData.snapshotId ? (contractorData.snapshotId).toString() : null;
    partyAsset.party.partyId = contractorData.id ? (contractorData.id).toString() : null;
    partyAsset.party.name = contractorData.name;
    partyAsset.party.surname = contractorData.surname;

    // TODO: MISSING DATA

    return partyAsset;
  }

  contractorRequestRefactor(data: PartyRequest): ContractorDataRequest {
    const contractorData = new ContractorDataRequest();

    contractorData.id = Number(data.parties[0].party.partyId);
    contractorData.snapshotId = Number(data.parties[0].party.idLatestPhotos);

    // TODO: MISSING DATA

    return contractorData;
  }

  productAssetsRefactor(productAssets: Array<PcProductAsset>): Assets {
    const assets = new Assets();
    assets.assets = new Array<Asset>();

    productAssets.forEach(el => {
      const asset = new Asset();

      asset.assetId = null; // MISSING DATA
      asset.code = el.code;
      asset.description = el.description;
      asset.extendedDescription = null; // MISSING DATA
      asset.instances = null; // MISSING DATA
      asset.maxInstances = null; // MISSING DATA
      asset.minInstances = null; // MISSING DATA

      assets.assets.push(asset);
    });

    return assets;
  }

  assetsResponseRefactor(data: Array<PcInsuredAsset>): Assets {
    const assets = new Assets();
    assets.assets = Array<Asset>();
    for (const pcAsset of data) {
      const asset = this.getAssetResponseFactor(pcAsset);
      assets.assets.push(asset);
    }

    return assets;
  }

  singleAssetRequestFactor(data: Asset): PcAsset {
    const request = new PcAsset();
    request.key = data.assetId;

    const assetInstance = data.instances.values[0];

    request.name = assetInstance.name;
    const sections = assetInstance.sections;
    const factors = new Array<Factor>();
    for (const unit of sections.unitList) {
      const factor = new Factor();
      factor.code = unit.code;
      factor.description = unit.description;
      factor.mandatory = unit.selected;
      factor.modifiable = unit.enabled;
      factors.push(factor);
    }

    return request;
  }

  singleAssetResponseFactor(data: PcAsset): Asset {
    const asset = new Asset();
    asset.assetId = data.key;
    const assetInstance = new AssetInstance();
    assetInstance.name = data.name;

    // sezioni
    assetInstance.sections = Array<AssetSection>();
    const assetSection = new AssetSection();
    assetSection.unitList = Array<Unit>();

    for (const factor of data.factors) {
      const unit = new Unit();
      unit.code = factor.code;
      unit.description = factor.description;
      unit.selected = factor.mandatory;
      unit.enabled = factor.modifiable;
      assetSection.unitList.push(unit);
    }

    assetInstance.sections.push(assetSection);
    return asset;
  }

  getAssetResponseFactor(data: PcInsuredAsset): Asset {
    const asset = new Asset();
    asset.assetId = data.key;
    asset.extendedDescription = data.extra;
    asset.code = data.asset.code;
    asset.description = data.asset.description;

    const assetInstance = new AssetInstance();
    assetInstance.extraDescription = data.extra;
    assetInstance.assetCode = data.asset.code;
    assetInstance.instanceDescription = data.asset.description;
    assetInstance.name = data.name;

    // sezioni
    assetInstance.sections = Array<AssetSection>();
    for (const section of data.sections) {
      const assetSection = this.sectionFactor(section);
      assetInstance.sections.push(assetSection);
    }

    asset.instances = [assetInstance];
    return asset;
  }

  sectionFactor(data: PcSection): AssetSection {
    const assetSection = new AssetSection();
    assetSection.code = data.code;
    assetSection.description = data.description;
    assetSection.open = data.selected;

    // fattori
    assetSection.unitList = Array<Unit>();
    for (const factor of data.factors) {
      const unit = new Unit();
      unit.code = factor.code;
      unit.description = factor.description;
      unit.selected = factor.mandatory;
      unit.enabled = factor.modifiable;
      assetSection.unitList.push(unit);
    }
    return assetSection;

  }

  quotationFactor(data: PcQuotation): Quotation {
    const quotation = new Quotation();
    const quotationPremium = new QuotationPremium();

    // Premi
    if (data.premiumGroup !== undefined) {
      quotationPremium.productPremium = this.premiumFactor(data.premiumGroup);
    }

    // Warnings
    const warnings = Array<Warning>();
    if (data.messages !== undefined) {
      for (const message of data.messages) {
        const warning = new Warning();
        warning.code = message.code;
        warning.description = message.description;
        warning.codeType = message.codetype;
        warnings.push(warning);
      }
    }
    quotationPremium.warnings = warnings;

    // Assets
    const assets = new Array<Asset>();
    if (data.assets !== undefined) {
      for (const quotAsset of data.assets) {
        assets.push(this.assetFactory(quotAsset));
      }
    }
    quotation.assetList = new AssetList();
    quotation.assetList.assets = assets;

    quotation.premium = quotationPremium;
    if (data.beneficiariesProduct) {
      quotation.beneficiariesProduct = data.beneficiariesProduct;
    }
    quotation.status = new ProposalStatus();
    if (data.statusDescription) {
      quotation.status.description = data.statusDescription;
    }
    if (data.status) {
      quotation.status.status = data.status;
    }

    quotation.notificationsPP = data.notificationsPP || new Array<string>();
    quotation.notes = data.notes;
    quotation.flexDiscountConfig = data.flexDiscountConfig;
    return quotation;
  }

  premiumFactor(data: PcPremium): QuotationProductPremium {

    const quotationProductPremium = new QuotationProductPremium();

    if (data.annual) {
      quotationProductPremium.annual = this.instalmentGroupFactory(data.annual);
    }

    if (data.instalment) {
      quotationProductPremium.instalment = this.instalmentGroupFactory(data.instalment);
    }

    if (data.sign) {
      quotationProductPremium.subscriptionInstalment = this.instalmentGroupFactory(data.sign);
    }

    return quotationProductPremium;

  }

  assetPremiumFactor(data: PcPremium): AssetProductPremium {

    const assetPremium = new AssetProductPremium();

    if (data.annual) {
      assetPremium.annual = this.instalmentFactory(data.annual);
    }

    if (data.instalment) {
      assetPremium.instalment = this.instalmentFactory(data.instalment);
    }

    if (data.sign) {
      assetPremium.subscriptionInstalment = this.instalmentFactory(data.sign);
    }

    return assetPremium;

  }

  instalmentGroupFactory(data: any): InstalmentPremiumGroup {
    const instalmentPremiumGroup = new InstalmentPremiumGroup();
    // SET PREMIUM INSTALMENT
    instalmentPremiumGroup.premium = this.instalmentFactory(data.premium);
    // SET AMOUNTS BY COVER
    instalmentPremiumGroup.amountByCover = data.amountByCover;
    instalmentPremiumGroup.fees = data.fees;
    instalmentPremiumGroup.lpsAdditionalFees = data.lpsAdditionalFees;
    instalmentPremiumGroup.lpsNextInstalmentAmount = data.lpsNextInstalmentAmount;
    instalmentPremiumGroup.lpsSignInstalmentAmount = data.lpsSignInstalmentAmount;
    return instalmentPremiumGroup;
  }

  instalmentFactory(data: PremiumInstance): Instalment {
    // SET PREMIUM INSTALMENT
    const instalment = new Instalment();
    instalment.gross = data.gross.toString();
    instalment.net = data.net.toString();
    instalment.taxes = data.taxes.toString();
    instalment.taxable = data.taxable.toString();
    instalment.fractioningInterest = data.fractioningInterest.toString();
    instalment.flexDiscounts = data.flexDiscounts;
    return instalment;
  }

  assetFactory(data: PcQuotAsset): Asset {
    const asset = new Asset();
    asset.instances = new Array<AssetInstance>();
    asset.assetId = data.key;
    asset.name = data.name;
    asset.roles = data.roles;
    const assetInstance = new AssetInstance();
    assetInstance.productPremium = this.assetPremiumFactor(data.premium);
    assetInstance.instanceDescription = data.key; // too do
    assetInstance.sections = new Array<AssetSection>();

    if (data.sections !== undefined) {
      for (const section of data.sections) {
        assetInstance.sections.push(this.quootationSectionFactory(section));
      }
    }
    assetInstance.assetCode = data.key;
    asset.instances.push(assetInstance);
    return asset;
  }

  quootationSectionFactory(data: QuotSection): AssetSection {
    const section = new AssetSection();
    section.id = data.code;
    section.code = data.code;
    section.description = data.description;
    section.premium = data.premium;
    if (data && data.units) {
      section.unitList = new Array<Unit>();
      for (const unit of data.units) {
        section.unitList.push(this.quotationUnitFactory(unit));
      }
    }
    return section;
  }

  quotationUnitFactory(data: QuotUnit): Unit {
    const unit = new Unit();
    unit.id = data.code;
    unit.code = data.code;
    unit.inputPremiumType = data.inputPremiumType;
    unit.inputPremium = data.inputPremium;
    unit.description = data.description;
    unit.enabled = data.enabled;
    unit.selected = data.selected;
    unit.extendedDescription = data.description;
    unit.annual = new Instalment();
    unit.annual.gross = data.premium.annual.gross.toString();
    unit.instalment = new Instalment();
    unit.instalment.gross = data.premium.instalment.gross.toString();
    unit.factors = data.factors;
    unit.beneficiaries = data.beneficiaries;
    unit.clauseUnit = data.clauseUnit;
    unit.annual.flexDiscounts = data.premium.annual.flexDiscounts;
    unit.instalment.flexDiscounts = data.premium.instalment.flexDiscounts;
    unit.discountable = data.discountable;
    return unit;
  }

  questionnaireFactor(listQuestion: PcQuestionnaireFlatIResponse): QuestionnaireFlatIResponse {
    const questionnaire = new QuestionnaireFlatIResponse();
    if (listQuestion) {
      const questions = listQuestion[0] as QuestionFlateResponse;
      questionnaire.code = questions.code;
      questionnaire.description = questions.description;
      const questionsList = new Array<QuestionFlatIResponse>();
      for (const question of questions.questions) {
        const questionList = new QuestionFlatIResponse();
        questionList.code = question.code;
        questionList.text = question.description;
        const answersList = new Array<AnswerFlatIResponse>();
        for (const answerList of question.answers) {
          const answer = new AnswerFlatIResponse();
          answer.factorCode = answerList.code;
          answer.factorDescr = answerList.description;
          answer.factorType = answerList.type;
          answer.value = answerList.value;
          answer.valueList = new Array<ValueFlatI>();
          answerList.values.forEach(element => {
            const value = new ValueFlatI();
            value.id = element[0];
            value.description = element[1];
            answer.valueList.push(value);
          });
          answer.factorType = answerList.type;
          answersList.push(answer);
        }
        questionList.answerList = answersList;
        questionsList.push(questionList);
      }
      questionnaire.questionList = questionsList;
    }
    return questionnaire;
  }

  questionnairePCFactor(listQuestion: QuestionnaireFlatIResponse): QuestionFlateResponse[] {
    const questionnaire = new QuestionFlateResponse();
    questionnaire.code = listQuestion.code;
    questionnaire.description = listQuestion.description;
    const pcQuestions = new Array<PcQuestion>();
    for (const question of listQuestion.questionList) {
      const pcQuestion = new PcQuestion();
      pcQuestion.code = question.code;
      pcQuestion.description = question.description;
      const pcAnswers = new Array<PcAnswer>();
      for (const answer of question.answerList) {
        const pcAnswer = new PcAnswer();
        pcAnswer.code = answer.factorCode;
        pcAnswer.description = answer.factorDescr;
        pcAnswer.type = answer.factorType;
        pcAnswer.value = answer.value;
        pcAnswer.values = answer.valueList;
        pcAnswers.push(pcAnswer);
      }
      pcQuestion.answers = pcAnswers;
      pcQuestions.push(pcQuestion);
    }
    questionnaire.questions = pcQuestions;
    const questionnaires = new Array<QuestionFlateResponse>();
    questionnaires.push(questionnaire);
    return questionnaires;
  }


  clausesFactory(data: PcClause[]): ClausesResponse {
    const response = new ClausesResponse();
    const clauses = new Array<Clause>();
    for (const pcClause of data) {
      const clause = this.clauseFactory(pcClause);
      clauses.push(clause);
    }
    response.clauses = clauses;
    return response;
  }

  clauseFactory(data: PcClause): Clause {
    return new Clause(data.id,
      data.assetId,
      data.code,
      data.description,
      data.selected,
      null,
      null,
      data.description,
      data.properties,
      data.description,
      data.mandatoryText,
      0,
      '',
      '',
      '',
      '',
      false,
      false,
      false,
      data.visible,
      false,
      data.clauseType,
      data.enable,
      null,
      '',
      0,
      0,
      null,
      false,
      '',
      '');
  }

  clausesResponseFactory(data: ClausesResponse): PcClause[] {
    const pcClauses = new Array<PcClause>();
    for (const clause of data.clauses) {
      const pcClause = new PcClause();
      pcClause.id = clause.id;
      pcClause.code = clause.code;
      pcClause.description = clause.description;
      pcClause.clauseType = clause.clauseType;
      pcClause.enable = clause.enable;
      pcClause.selected = clause.selected;
      pcClause.text = clause.text;
      pcClause.textType = clause.textType;
      pcClause.mandatoryText = clause.mandatoryText;
      pcClauses.push(pcClause);
    }
    return pcClauses;

  }


  pcUnitToUnit(pcUnits: PcUnit[]): Array<Unit> {
    const unitList = new Array<Unit>();
    pcUnits.forEach(pcUnit => {
      // FIXME : PACKET UNIT MANAGEMENT
      // if (pcUnit.selected === true) {
      const unit = new Unit();
      unit.code = pcUnit.code;
      unit.description = pcUnit.description;
      unit.selected = pcUnit.selected;
      /*  let foundUnit : PcUnit = pcUnits.find(objUnit => {
           return objUnit.code === unit.code;
       });*/
      unit.open = pcUnit.selected;
      unit.enabled = pcUnit.enabled;
      unit.risk = this.sectionFactorToRisk(pcUnit);
      unit.factors = this.sectionFactorToVariable(pcUnit.factors);
      unitList.push(unit);
    });
    return unitList;
  }

  sectionFactorToRisk(factor: PcUnit): Risk {
    const risk = new Risk();
    risk.code = factor.code;
    risk.description = factor.description;
    return risk;
  }

  sectionFactorToVariable(factors: Array<Factor>): Array<Variable> {
    const variables = new Array<Variable>();
    factors.forEach(factor => {
      const variable = new Variable(
        null, // id
        factor.code, // code
        factor.description, // description
        null, // identificationCode
        true, // visible
        factor.mandatory, // compulsory
        null, // dependent
        null, // transcoderCode1
        null, // transcoderCode2
        factor.value, // value
        null, // valueDescription
        // // 0: Lista, 1: Numeric, 2: Numeric (verificare), 3: Boolean (Si/No), 4: Date, 5: String
        factor.type,
        // this.getTypeFromString(factor.type), // type
        factor.format, // format
        null, // order
        factor.values, // variablesValue
        null, // extendedDescription
        // // 1 - Asset Area
        // // 2 - Party Area (Anagrafica)
        // // 3 - Extra Area
        // // 4 - Vehicle Data Area
        // // 5 - Insurance Background Area
        1, // visibilityArea
        null, // editableInView
        null, // variableClass
        null, // dummyValue
        factor.modifiable, // editable
        factor.properties
      );
      variables.push(variable);
    });
    return variables;
  }

  getTypeFromString(type: string): number {
    let typeNumber = 5;
    if (type === 'NUM') {
      typeNumber = 1;
    }
    return typeNumber;
  }

  getTypeFromNumber(type: number): string {
    let typeString = '';
    if (type === 1) {
      typeString = 'NUM';
    }
    return typeString;
  }

  unitToPcUnit(unit: Unit): PcUnit {
    const pcUnit = new PcUnit();
    pcUnit.code = unit.code;
    pcUnit.description = unit.description;
    pcUnit.selected = unit.selected;
    pcUnit.inputPremiumType = unit.inputPremiumType;
    pcUnit.factors = this.variableToSection(unit.factors);
    return pcUnit;
  }

  variableToSection(variables: Array<any>): Array<Factor> {
    const factors = new Array<Factor>();
    variables.forEach(variable => {
      const factor = new Factor();
      factor.code = variable.code;
      factor.description = variable.description;
      factor.mandatory = variable.compulsory;
      factor.value = variable.value;
      factor.type = variable.type;
      factor.format = variable.format;
      factor.values = variable.values;
      factor.modifiable = variable.modifiable;
      factors.push(factor);
    });
    return factors;
  }

  pcSectionsToFactors(sections: Array<PcSection>): Array<Factor> {
    const factors = new Array<Factor>();

    sections.forEach(section => {
      const factor = new Factor();

      factor.code = section.code;
      factor.description = section.description;
      factor.additionalLabels = section.additionalLabels;

      factors.push(factor);
    });

    return factors;
  }

  factorsToAssetSections(factors: Array<Factor>): Array<AssetSection> {
    const assetSections = new Array<AssetSection>();

    factors.forEach(factor => {
      const assetSection = new AssetSection();

      assetSection.code = factor.code;
      assetSection.description = factor.description;

      assetSections.push(assetSection);
    });

    return assetSections;
  }

  insuredAssetToPcAsset(asset: PcInsuredAsset): PcAsset {

    const pcAsset = new PcAsset();

    pcAsset.key = asset.key;
    pcAsset.code = asset.asset.code;

    return pcAsset;
  }

  proposalSubscriberToAnagIssueSubject(subscriber:any) {
    const mobile: AnagIssuePhoneNumber = {
      extensionSubject: '', internationalPrefix: '', objectId: '',
      localPrefix: '', number: subscriber?.contacts?.mobile
    };
    const residence: AnagIssueApiResidence = {
      addressAddition: undefined,
      adminLevel1: '',
      adminLevel1Short: '',
      adminLevel2: '',
      adminLevel2Short: '',
      adminLevel3: '',
      adminLevel3Short: '',
      at: '',
      cab: '',
      cap: '',
      city: '',
      country: subscriber?.residence?.country,
      countryCode: '',
      detailCode: '',
      extensionSubject: '',
      fraction: '',
      latitude: '',
      locality: '',
      longitude: '',
      normalized: false,
      number: '',
      placeAddress: '',
      postalAddress: '',
      province: undefined,
      specialMention: undefined,
      subLocality: '',
      toponym: undefined,
      formatAddress: subscriber?.residence?.formattedAddress
    };
    return {
      birthAddress: undefined,
      cityOfBirth: '',
      clientNumber: '',
      company: undefined,
      countryOfBirth: '',
      dateOfBirth: subscriber?.birthDate,
      denomination: '',
      emails: [subscriber?.contacts?.email],
      extensionSubject: undefined,
      fiscalCode: subscriber?.fiscalCode,
      vat: subscriber?.fiscalCode,
      mobilePhone: [mobile],
      name: subscriber?.name,
      nominative: subscriber?.nominative,
      partyKey: undefined,
      partyNumber: '',
      payments: undefined,
      personType: {codice: subscriber?.type?.code, descrizione: subscriber?.type?.description},
      privacyConsensus: undefined,
      professionDetail: undefined,
      professionType: undefined,
      residence: residence,
      subjectType: undefined,
      surname: subscriber?.surname,
      objectId:  subscriber?.id.toString(),
      idLatestPhotos: subscriber?.snapshotId.toString(),
      node: {
        identification: undefined,
        code: undefined,
        description: undefined
      }
    }
  }
  proposalCoOwnersToAnagIssueSubjectCoOwners(coOwners:any){
    const output = []
    coOwners.forEach(coOwner =>{
      output.push(this.proposalSubscriberToAnagIssueSubject(coOwner))
    })
    return output;
  }
}

export enum ParamConstants {
    ParamString = '1',
    ParamNumber = '2',
    ParamBool = '3',
    ParamList = '4',
    ParamDate = '5'
}

export interface paramObj{
    idParam: string ,
    idFunctionStatus: string ,
    paramLabel: string ,
    typeValue: string ,
    defualtValue: string ,
    listCode: string ,
    listValue: string ,
    required: boolean ,
    maxLength: number,
    label: string
  }

export var ParamPlaceholder = {
    response: [
        {
            idParam: '1',
            idFunctionStatus: '303',
            paramLabel:' Parametro Numerico',
            typeValue: '2',
            defualtValue: null,
            listCode: null,
            listValue: null,
            required: true,
            maxLength: 10
        },
        {
            idParam: '2',
            idFunctionStatus: '303',
            paramLabel: 'Parametro Lista',
            typeValue: '4',
            defualtValue: null,
            listCode: '1;2;3;4',
            listValue: 'uno;due;tre;quattro',
            required: true,
            maxLength: null
        },
        {
            idParam: '3',
            idFunctionStatus: '303',
            paramLabel:' Parametro Data',
            typeValue: '5',
            defualtValue: null,
            listCode: null,
            listValue: null,
            required: false,
            maxLength: null
        },
        {
            idParam: '4',
            idFunctionStatus: '303',
            paramLabel:' Parametro Stringa',
            typeValue: '1',
            defualtValue: null,
            listCode: null,
            listValue: null,
            required: false,
            maxLength: 50
        },
        {
            idParam: '5',
            idFunctionStatus: '303',
            paramLabel: 'Parametro Booleano',
            typeValue: '3',
            defualtValue: null,
            listCode: null,
            listValue: null,
            required: true,
            maxLength: null
        }
    ]
}

import { Inject, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IiabPortalExtModule, LibPortalIntegrationService } from "@rgi/iiab-portal-ext";
import { PortalConfig, RGI_RX_PORTAL_CONFIG, RgiRxPortalModule } from "@rgi/rx/portal";
import { CARDS, angularGridInstance } from "./card.configurations";
import { PortalIntegrationService } from "./services/portal-integration.service";
import { ANAG_CARD } from '@rgi/anag/portal';
import { downgradeIIABService } from "./ajs-downgrade";

@NgModule({
  declarations: [],
  imports: [
    IiabPortalExtModule,
    CommonModule,
    RgiRxPortalModule.forRoot(CARDS),
  ],
  providers: [
    {
      provide: LibPortalIntegrationService, useClass: PortalIntegrationService,
    },
    angularGridInstance
  ]
})

export class IIABPortalModule {
  constructor(
    @Inject(RGI_RX_PORTAL_CONFIG) config: PortalConfig) {
    if (ANAG_CARD.card?.subCards && ANAG_CARD.card.subCards.length > 0) {
      ANAG_CARD.card.subCards.filter((sub) => sub.route === 'detail').map((item) => {
        if (item.cards) {
          item.cards.push(
            {
              name: 'cardTotalSummary',
              route: 'totalSummary'
            }
          );
        }
      });
    }
    downgradeIIABService(config);
  }
}

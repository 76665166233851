import {Component, Inject, Input, Optional} from '@angular/core';
import {ToolData} from '../../../../models/contractual-option.model';
import {Profile} from '../../../../models/life-detail.model';
import { EMPTY_STR } from '../../../../models/consts/lpc-consts';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../../services/currency-cache.service';
import { ToolCode } from '../../../../models/enums/vita.enum';

@Component({
  selector: 'lpc-funds-table',
  templateUrl: './lpc-funds-table.component.html',
  styleUrls: ['./lpc-funds-table.component.css']
})
export class LpcFundsTableComponent {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };

  @Input() toolData: ToolData[];
  @Input() toolId = EMPTY_STR;

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  public totalPercentSummary(profiles: Profile[]): string {
    return profiles.reduce((subtotal, item) => subtotal + Number(item.percent), 0)
      .toString(10);
  }

  get isDisinvestmentFundTableActive(): boolean {
    switch (this.toolId) {
      case ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL:
        return true;
      case ToolCode.PERIODIC_COUPON:
        return true;
      default:
        return false;
    }
  }

  get isPercentAmountActive(): boolean {
    switch (this.toolId) {
      case ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL:
        return true;
      case ToolCode.PROGRESSIVE_SWITCH:
        return true;
      case ToolCode.PERIODIC_COUPON:
        return true;
      default:
        return false;
    }
  }
}

import {
  CallBackJumpInstance,
  DataForSteps,
  GroupPolicyIntegrationService,
  GroupPolicyResourceService,
  IdentificationKey,
  OPERATIONSCODE,
} from '@rgi/group-policy';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import {
  ICoreResult,
  PortalCardConfig,
  PortalCards,
  PortalConfig,
  portalControllerFactory, PortalRouteDefault,
  RGI_RX_PORTAL_TPL
} from '@rgi/rx/portal';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import {downgradeInjectable} from '@angular/upgrade/static';

export const GROUP_POLICY_ISSUE_CARD: PortalCardConfig = {
  card: {
    title: '_GP_TITLE_GROUP_POLICIES_',
    name: 'groupPolicyIssue',
    category: 'Contract Management',
    type: 'main',
    functions: ['grouppolicies.proposal.new'],
    subCards: [],
    subCardsActive: [],
    navigationInSession: true
  },
  routes: [
    {
      route: 'home',
      destination: 'grouppolicy.issue.home',
      label: '_GP_TITLE_GROUP_POLICIES_',
      type: 'home'
    } as PortalRouteDefault,
    {
      route: 'edit',
      destination: 'grouppolicy.issue.edit',
      label: '_GP_TITLE_GROUP_POLICIES_',
    } as PortalRouteDefault,
    {
      route: 'proposalIssue',
      destination: 'grouppolicy.issue.proposalissue',
      label: '_GP_TITLE_GROUP_POLICIES_',
    } as PortalRouteDefault,
    {
      route: 'policyData',
      destination: 'grouppolicy.issue.policyData',
      label: '_GP_TITLE_GROUP_POLICIES_'
    } as PortalRouteDefault,
    {
      route: 'configurationPm',
      destination: 'grouppolicy.issue.configurationPm',
      label: '_GP_TITLE_GROUP_POLICIES_',
    } as PortalRouteDefault,
    {
      route: 'guarantees',
      destination: 'grouppolicy.issue.guarantees',
      label: '_GP_TITLE_GROUP_POLICIES_',
    } as PortalRouteDefault,
    {
      route: 'summary',
      destination: 'grouppolicy.issue.summary',
      label: '_GP_TITLE_GROUP_POLICIES_',
    } as PortalRouteDefault,
    {
      route: 'issueConfirm',
      destination: 'grouppolicy.issue.confirm',
      label: '_GP_TITLE_GROUP_POLICIES_',
    } as PortalRouteDefault,
    {
      route: 'vcontOperation',
      destination: 'grouppolicy.contractchange.dates'
    } as PortalRouteDefault,
    {
      route: 'vcontOperationVariation',
      destination: 'grouppolicy.contractchange.variation'
    } as PortalRouteDefault,
    {
      route: 'vcontOperationQuestionnaire',
      destination: 'grouppolicy.contractchange.questionnaire'
    } as PortalRouteDefault,
    {
      route: 'vcontOperationConfirms',
      destination: 'grouppolicy.contractchange.confirms'
    } as PortalRouteDefault
  ]
};
ExtendedController.$inject = [
  '$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxTranslationService', 'RgiRxEventService', 'eventService', 'sessionService', 'SESSION_EVENT'
];

// @ts-ignore
// tslint:disable
export function ExtendedController($scope, $controller, coreResult: ICoreResult, rgiRxRoutingService: RoutingService, rgiRxTranslateService: RgiRxTranslationService, RgiRxEventService, ajsEventService, sessionService, SESSION_EVENT) {
  portalControllerFactory('grouppolicy.inquiry.detail', 'detail-masterpolicies').apply(this, arguments); // apply the portalControllerFactory

  $scope.onRouteChange = (_activeRoute: ActiveRoute) => {
    // do something on routeChange
  };
  $scope.$on('$destroy', () => {
    // do something on controller destroy
  });
  let title = '';
  const contractNumber = $scope.$$routeData.policyNumber ? $scope.$$routeData.policyNumber : $scope.$$routeData.proposalNumber;
  rgiRxTranslateService.translate('_GP_._TITLE_._CONTRACTDETAIL_').subscribe(text => title = text + contractNumber);

  $scope.card.title = title;


  $scope.myCustomData = {};

  $scope.activate = function () {
    RgiRxEventService.emit({ event: { eventName: 'CALLBACK_JUMP' } });
  };

  ajsEventService.listenEvent($scope, SESSION_EVENT.SESSION_ACTIVATED_EVT, function (_event, _args) {
    if (sessionService.list().length > 0) {
      sessionService.list().forEach(session => {
        if (session && session.isActive && session.currentRoute === 'detail-masterpolicies' && _event.currentScope.$$id === session.idSession) {
          RgiRxEventService.emit(new CallBackJumpInstance(session.idSession));
        }
      });
    }
  });
}

export const GROUP_POLICY_INQUIRY_CARD: PortalCardConfig = {
  card: {
    title: 'Group Policy Inquiry Card',
    name: 'groupPolicyInquiry',
    type: 'fakeHidden',
    subCards: [],
    subCardsActive: []
  },
  routes: [
    {
      route: 'detail-masterpolicies',
      destination: 'grouppolicy.inquiry.detail',
      controller: ExtendedController,
      template: RGI_RX_PORTAL_TPL
    },
    {
      route: 'action',
      destination: '',
      template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
      controller: 'CardPassCtrl'
    }
  ]
};

export const GROUP_POLICY_CARDS: PortalCards = [GROUP_POLICY_ISSUE_CARD, GROUP_POLICY_INQUIRY_CARD];

declare var angular: angular.IAngularStatic;

export function downgradeGroupPolicy(config?: PortalConfig) {
  angular.module(config.module).factory('groupPolicyResourceService', downgradeInjectable(GroupPolicyResourceService));
  angular.module(config.module).factory('groupPolicyIntegrationService', downgradeInjectable(GroupPolicyIntegrationService));
}

export function manageNewCardInPassPortal(config?: PortalConfig) {
  const module = angular.module(config.module);
  module.config(['$provide', function ($provide) {

    $provide.decorator('ptfAllFinderResultService', ['$delegate', 'CONTRACT_CATEGORIES', 'PTFALL_POLICY_TYPES',
      function FinderServiceDecorator($delegate, _contractCategories, policyTypes) {

        const baseService = $delegate;
        const extended = angular.extend({}, baseService);

        extended.getDetailRoute = function (contract) {
          if (contract.policyType.codice === policyTypes.MASTER && (contract.category.codice === '1' || contract.category.codice === '7')) {
            return 'detail-masterpolicies';
          } else {
            return baseService.getDetailRoute(contract);
          }
        };

        return extended;
      }
    ]);


    $provide.decorator('PtfDamageDetailService',
      ['$delegate', 'CONTRACT_ORIGIN', 'PtfDamageDetailServiceFilter', 'PTFALL_POLICY_TYPES', 'groupPolicyResourceService', 'groupPolicyIntegrationService',
        function DetailServiceDecorator($delegate, _CONTRACT_ORIGIN, _PtfDamageDetailServiceFilter, policyTypes, groupPolicyService, integrationService) {

          const baseService = $delegate;
          const extended = angular.extend({}, baseService);

          const superDuplicateProposal = extended.duplicateProposal;
          extended.duplicateProposal = (policyId, effectiveDate, contract) => {
            console.log(contract);

            switch (contract.policyType.codice) {
              case policyTypes.MASTER:
                const service = groupPolicyService.cloneMasterPolicy$(contract.proposalNumber);
                service.subscribe(
                  (_resp: IdentificationKey) => {
                    const nextStepParam = {
                      resId: _resp.uuid,
                      operationCode: OPERATIONSCODE.ISSUE
                    } as DataForSteps;
                    integrationService.goToEdit(nextStepParam);
                  }
                );
                break;
              default:
                superDuplicateProposal(policyId, effectiveDate, contract);
            }
          };

          return extended;
        }
      ]);

    $provide.decorator('ptfallDataTableButtonsCreateService',
      ['$delegate', 'CONTRACT_ORIGIN', 'PtfDamageDetailServiceFilter', 'PTFALL_POLICY_TYPES',
        function PtfallDataTableCreateServiceDecorator($delegate, _CONTRACT_ORIGIN, _PtfDamageDetailServiceFilter, policyTypes) {

          const baseService = $delegate;
          const extended = angular.extend({}, baseService);

          const superAddCopyButton = extended.addCopyButton;
          extended.addCopyButton = (contract, actions) => {
            //controllo se stato valido
            const isStatusOk = contract.state.codice === '1' || contract.state.codice === '3' || contract.state.codice === '11';
            const isMasterPolicyInStatusOk = contract.policyType.codice === policyTypes.MASTER && isStatusOk;
            if(contract.policyType.codice !== policyTypes.MASTER || isMasterPolicyInStatusOk){
              superAddCopyButton(contract, actions);
            }
          };

          return extended;
        }
      ]);

  }]);
  module.run(['cardManagementService', 'parallelRouting',
    function (cardManagementService, pRouter) {
      // register new route in ptfallfinder
      const _catalogCard = cardManagementService.getAvailableCardByName('ptfallFinder');
      if (_catalogCard) {
        pRouter.modify(_catalogCard.name, 'detail-masterpolicies', GROUP_POLICY_INQUIRY_CARD.routes[0]);

      }
    }]);
}

export function angularGridInstanceFactory(injector) {
  return injector.get('angularGridInstance');
}

export const angularGridInstance = {
  provide: 'angularGridInstance',
  useFactory: angularGridInstanceFactory,
  deps: ['$injector']
};


<div class="box-detail">
  <header class="box-detail-header" translate>_GP_._TITLE_._SUBJECTS_</header>
  <div class="box-detail-body">
    <table rgi-rx-table [dataSource]="contractDetails.partiesDetail">
      <ng-container rgiRxColumnDef="role">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._ACCOUNTHOLDERS_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.description}} </td>
      </ng-container>

      <ng-container rgiRxColumnDef="nominative">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <a *ngIf="!isPreventJumpToParty" style="cursor: pointer"
             (click)="openSubjectDetail(row.idParty)">{{row.companyName ? row.companyName : row.name + ' ' + row.surname}}</a>
          <span *ngIf="isPreventJumpToParty">{{row.companyName ? row.companyName : row.name + ' ' + row.surname}}</span>
          <span *ngIf="row.percentage">&nbsp;({{row.percentage}}%)</span>
        </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedPartiesColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedPartiesColumns;"></tr>
    </table>
  </div>
</div>

import { KeyValue } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import {
  ContributionPipEvent,
  PipAdvancedPayments, PipAmountForTaxation, PipContribution, PipDataResp,
  PipFiscalData, PipFiscalDataDetail, PipGeneral, PipTrasfer, PipUndeductedPremiums
} from '../../../models/pip-data.model';
import { LpcDetailUtils } from '../../../utils/lpc-detail-utils';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';
import { LpcDataTableHeaders } from '../../../models/data-table.model';

@Component({
  selector: 'lpc-life-pip-detail',
  templateUrl: './life-pip-detail.component.html',
  styleUrls: ['./life-pip-detail.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('void', style({ opacity: 0 })),
      transition(':enter', animate(200)),
      transition(':leave', animate(200))
    ]),
  ],
})

export class LifePipDetailComponent implements OnInit, AfterViewChecked {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };

  @Input() id: string;
  @Input() data: { pipDataDetail: PipDataResp };

  @Output() eventPropagation = new EventEmitter<any>();

  public pipFiscalDetails: PipFiscalDataDetail[];
  public pipGeneralDetails: PipFiscalDataDetail[];
  public pipData: PipDataResp;
  public pipContribs: PipContribution[];
  public pipTransfer: PipTrasfer[];
  public pipAdvancedPayments: PipAdvancedPayments[];
  public pipUndeductedPremiums: PipUndeductedPremiums[];
  public pipAmountForTaxation: PipFiscalDataDetail[][];
  public pipDataCardTitle: string;
  public selectedRow: any;
  public isAnimated = false;
  public openedRows: number[] = [];
  public isOpen: boolean[] = [];

  public contributionsHeaders: LpcDataTableHeaders[] = [
    'lpc_PIP_Contribution_Type',
    'lpc_frequecy',
    'lpc_PIP_Contribution_Percentage',
    'lpc_PIP_Contribution_Amount'
  ];

  public transfersHeaders: LpcDataTableHeaders[] = [
    ' ',
    'lpc_requestDate',
    'lpc_Type_Previous_Pension_Fund',
    'lpc_Previous_Pension_Fund',
    'lpc_Import_Transfert',
    'lpc_booking_state',
    'lpc_Note'
  ];

  public adavancePaymentHeaders: LpcDataTableHeaders[] = [
    'requestDate',
    'effectiveDate',
    'reasonForRequest',
    'advancePaymentAmount',
    'repaidAdvance',
  ];

  public undeductedPremiumsHeaders: LpcDataTableHeaders[] = [
    'contributionYear',
    'annualPremium',
    'undeductedPremium'
  ]

  public hasNonEmptyInfos(data: any[]): boolean {
    for (const item of data) {
      if (item.infos.length !== 0) {
        return true;
      }
    }
    return false;
  }

  public toggleData(index: number, id: string): void {
    const rowIndex = this.openedRows.indexOf(index);
    if (rowIndex > -1) {
      this.openedRows.splice(rowIndex, 1);
    } else {
      this.openedRows.push(index);
    }
    this.toggleOpenClose(index);
  }

  public toggleOpenClose(index: number): void {
    this.isOpen[index] = !this.isOpen[index];
  }

  public showAnimation() {
    this.isAnimated = true;
  }
  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    if (a.key.toString() === 'pipFiscalDataTransfer' || b.key.toString() === 'note') {
      return -1;
    } else if (b.key.toString() === 'pipFiscalDataTransfer' || a.key.toString() === 'note') {
      return 1;
    } else {
      return 0;
    }
  }

  constructor(
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected graphicFix,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.currencyFormatterOptions.currency = currencyService.currency;
    }

  ngAfterViewChecked(): void {
    this.graphicFix.fixCardGraphic(this.id);
  }

  ngOnInit() {
    this.pipData = this.data.pipDataDetail;
    this.assignPipData(this.pipData.pipCardDataType);
  }

  private extractDetailArray(obj: PipFiscalData | PipGeneral | PipAmountForTaxation): PipFiscalDataDetail[] {
  return  Object.keys(obj).map(key => {
      return {
        description: key,
        value: obj[key],
      } as PipFiscalDataDetail;
    }).filter( fiscalData => fiscalData !== undefined);
  }

  getFractionalizedPipData(pipFiscalData: PipFiscalDataDetail[]): Map<number, PipFiscalDataDetail[]> {
    return LpcDetailUtils.generateDynamicTable(pipFiscalData) as Map<number, PipFiscalDataDetail[]>;
  }

  getPipDataRows(factors: PipFiscalDataDetail[]): number[] {
    return LpcDetailUtils.composeArray(Math.ceil(factors.length / 2));
  }

  private assignPipData(pipDataCardType: ContributionPipEvent): void {
    switch (pipDataCardType) {
      case 'pipGeneral':
        // Removing irrelevant data from Fiscal Data Table, in this case we never want
        // to show any table cell if the prop value is null
        Object.keys(this.pipData.pipGeneral.pipFiscalData).forEach(
          (k) => this.pipData.pipGeneral.pipFiscalData[k] == null && delete this.pipData.pipGeneral.pipFiscalData[k]);
        this.pipFiscalDetails = this.extractDetailArray(this.pipData.pipGeneral.pipFiscalData);
        this.pipGeneralDetails = this.extractDetailArray(this.pipData.pipGeneral)
          .filter(generalData => generalData.description !== 'pipFiscalData');
        this.pipDataCardTitle = 'lpc_PIP_General';
        break;
      case 'pipContributionData':
        this.pipContribs = this.pipData.pipContributionData;
        this.pipDataCardTitle = 'lpc_PIP_Contribution';
        break;
      case 'pipTransfers':
        this.pipTransfer = this.pipData.pipTransfers;
        this.pipDataCardTitle = 'lpc_PIP_Transfer';
        break;
      case 'pipAdvancePayments':
        this.pipAdvancedPayments = this.pipData.pipAdvancePayments;
        this.pipDataCardTitle = 'lpc_PIP_AdvancedPayment';
        break;
      case 'pipUndeductedPremiums':
        this.pipUndeductedPremiums = this.pipData.pipUndeductedPremiums;
        this.pipDataCardTitle = 'lpc_PIP_UndeductedPremiums';
      case 'pipAmountForTaxation':
        this.pipAmountForTaxation = this.pipData.pipAmountForTaxation.map(el => this.extractDetailArray(el));
        this.pipDataCardTitle = 'lpc_PIP_amountForTaxation';
    }
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  getValueFromDescription(array: any[], description: string) {
    return array.find(el => el.description == description).value;
  }
}

<rgi-rx-panel>
  <!-- HEADER -->
  <rgi-rx-panel-header (onClose)="closeModal()" [closeable]="true">{{ '_ADAPTERPRINT_._LABEL_._NODE_LIST_' | translate }}</rgi-rx-panel-header>
  <!-- DATATABLE -->
  <rgi-rx-datatable  rgi-rx-qa="node_result" qa-ctx="adapterprint-context"
                    [data]="dropSelectionData.selectData" [schema]="schema"
                    [pageOptions]="[10,25,50,100]"  (onAction)="onActionClick($event)">
  </rgi-rx-datatable>
<!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeModal()" translate>_ADAPTERPRINT_._BTN_._UNDO_</button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

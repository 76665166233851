import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RGI_RX_HTTP_CONFIG, RgiRxHttpConfig, RgiRxHttpErrorService} from './http-api';
import {RgiRxModule} from '@rgi/rx';
import {GzipModule} from './gzip/gzip.module';
import {RgiRxHttpClientModule} from './client/rgi-rx-http-client.module';
import {RgiRxHttpErrorStream} from './rgi-rx-http-error-stream';

const DEFAULT_CONFIG: RgiRxHttpConfig = {
  gzip: false
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GzipModule,
    RgiRxModule,
    RgiRxHttpClientModule
  ],
  providers: [
    {provide: RGI_RX_HTTP_CONFIG, useValue: DEFAULT_CONFIG},
    {
      provide: RgiRxHttpErrorService, useExisting: RgiRxHttpErrorStream
    }
  ],
  exports: []
})
export class RgiRxHttpModule {
  static forRoot(options?: RgiRxHttpConfig): ModuleWithProviders<RgiRxHttpModule> {
    const providers: Provider[] = [options ? {provide: RGI_RX_HTTP_CONFIG, useValue: options} : {provide: RGI_RX_HTTP_CONFIG, useValue: DEFAULT_CONFIG}];
    return {
      ngModule: RgiRxHttpModule,
      providers
    };
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {ModalComponent, ModalService} from '@rgi/rx/ui';
import {GroupPolicyDateAdapter} from '../../adapters/ngbDateCustomParserFormatter ';
import {
  GroupPolicyModalAvailableAssetsComponent
} from '../../group-policy-components/group-policy-modals/group-policy-modal-available-assets/group-policy-modal-available-assets.component';
import {CONTRACT_STATUS} from '../../group-policy-constants/general';
import {GPAsset, GpAssetUnitsClauses} from '../../group-policy-models/group-policy-issue-guarantees';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {ErrorCode, ErrorResp} from '../../group-policy-models/group-policy-issue-policy-data';
import {GpCommissionDiscountBtnsService} from '../../group-policy-resources/gp-commission-discount-btns.service';
import {GroupPolicyApiRestErrorService} from '../../group-policy-services/group-policy-api-rest-error.service';
import {GroupPolicyCrossService} from '../../group-policy-services/group-policy-cross.service';
import {GroupPolicyStateGuarantees} from '../../group-policy-state/group-policy-state';
import {
  GroupPolicyStatemanagerGuarantees
} from '../../group-policy-state/group-policy-statemanager-guarantees/group-policy-statemanager-guarantees';


/**
 * @author: dmasone
 * @description: Component used for guarantees page
 */
@Component({
  selector: 'rgi-gp-group-policy-guarantees',
  templateUrl: './group-policy-guarantees.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyGuaranteesComponent extends RoutableComponent implements OnInit, OnDestroy {

  public previousStepData: DataForSteps;
  public stateGuarantees: GroupPolicyStateGuarantees;
  public tmpUnitsCode: GPAsset[] = [];
  public activeButton = true;
  public saveModal: ModalComponent;
  public contractStates = CONTRACT_STATUS;
  public openAssetModalOnIssue = true;

  public guaranteesForm = new UntypedFormGroup({});

  accordionMap = new Map<string, boolean>();

  constructor(
    protected activeRoute: ActiveRoute,
    protected dateAdapter: GroupPolicyDateAdapter,
    protected modalService: ModalService,
    protected crossService: GroupPolicyCrossService,
    protected stateManagerGuarantees: GroupPolicyStatemanagerGuarantees,
    protected gpErrorService: GroupPolicyApiRestErrorService,
    protected translationService: RgiRxTranslationService,
    protected gpCommBtnService: GpCommissionDiscountBtnsService) {
    super();
  }

  ngOnInit(): void {
    this.previousStepData = this.activeRoute.getRouteData();
    this.stateManagerGuarantees.getState$()
      .subscribe((nextState: GroupPolicyStateGuarantees) => {
        this.initPage(nextState);
      });
  }

  protected initPage(nextState: GroupPolicyStateGuarantees) {
    this.stateGuarantees = nextState;

    if (nextState.availableAssets && nextState.availableAssets.length && this.openAssetModalOnIssue &&
      (!nextState.assets || !nextState.assets.length)) {
      this.openAssetsModal();
    }

  }

  public openCommissionsModal() {
    this.stateManagerGuarantees.actionOpenCommissionsModal(this.previousStepData);
  }

  public openDiscountsModal() {
    this.stateManagerGuarantees.actionOpenDiscountsModal(this.previousStepData);
  }

  public callActionGoToSummary() {
    if (this.areAllUnitsStatesExcluded()) {
      return;
    }
    this.stateManagerGuarantees.actionGoToSummary(this.previousStepData);
  }

  public callActionSave() {
    if (this.areAllUnitsStatesExcluded()) {
      return;
    }
    this.stateManagerGuarantees.actionSave(this.previousStepData);
  }

  public callActionBack(targetRoute?: string) {
    let incompleteAsset = false;
    const assets = this.stateGuarantees.assets;
    if (assets && assets.length > 0) {
      assets.forEach(asset => {
        if (asset.structure.code === '2' && this.guaranteesForm.invalid) {
          incompleteAsset = true;
        }
      });
    }

    return incompleteAsset ? this.crossService.openBaseModal('_GP_._LABEL_._BACK_GUARANTEES_CLUSTER_ERROR_').subscribe() :
      this.stateManagerGuarantees.actionBack(this.previousStepData, this.activeRoute, targetRoute);
  }

  openAssetsModal() {
    const availableAssetsModal = this.modalService.open(GroupPolicyModalAvailableAssetsComponent, {
      availableAssets: this.stateGuarantees.availableAssets
    });
    availableAssetsModal.enableClickBackground = false;
    availableAssetsModal.onClose.subscribe((onCloseData: string[] | undefined) => {
      if (!!onCloseData && onCloseData.length) {
        this.stateManagerGuarantees.actionAddAssets(onCloseData, this.previousStepData);
      }
      else {
        this.openAssetModalOnIssue = false;
      }
    });
  }

  actionUpdateAsset(assetToUpdate: GPAsset) {
    this.stateManagerGuarantees.actionUpdateAssets([assetToUpdate], this.previousStepData);
  }

  actionDeleteAsset(assetCode: string) {
    Array.from(this.accordionMap.keys()).filter(key => key.startsWith(assetCode)).forEach(key => {
      this.accordionMap.delete(key);
    });
    Object.keys(this.guaranteesForm.controls).forEach(control => {
      if (control.startsWith(assetCode)) {
        this.guaranteesForm.removeControl(control);
      }
    });
    this.stateManagerGuarantees.actionDeleteAsset(assetCode, this.previousStepData);
  }

  get atLeastOneUnitSelectedForEachAsset(): boolean {
    const assets = this.stateGuarantees.assets;
    let foundInEveryAsset = true;
    let foundOneValid = false;

    if (assets && assets.length > 0) {
      assets.forEach(asset => {
        if (foundInEveryAsset) {
          if (asset.structure.code !== '2') {
            foundInEveryAsset = !!asset.units.find(unit => unit.unit.selected);
            foundOneValid = true;
          } else {
            foundInEveryAsset = asset.clusters.length > 0;
            foundOneValid = foundOneValid || !!asset.clusters.find(cluster => cluster.status.code !== '3');
          }
        }
      });
      return foundInEveryAsset && foundOneValid;
    } else {
      return false;
    }
  }

  actionUpdateClauses(assetUnitsClauses: GpAssetUnitsClauses) {
    this.stateManagerGuarantees.actionUpdateAssetsClauses(assetUnitsClauses, this.previousStepData);
  }

  ngOnDestroy() {
  }

  protected areAllUnitsStatesExcluded(): boolean {
    let areAllUnitExcluded = true;
    this.gpErrorService.cleanErrorsForCode(this.stateGuarantees.errors, 'FOOTER_ASSET_UPD');
    for (const asset of this.stateGuarantees.assets) {
      // tslint:disable-next-line:max-line-length
      if (asset.structure.code === '2' || asset.units.some(el => el.unit.selected && (el.unit.startStatusType !== null && el.unit.startStatusType !== 3))) {
        areAllUnitExcluded = false;
        break;
      }
    }
    if (areAllUnitExcluded) {
      this.createAssetError();
    }
    return areAllUnitExcluded;
  }

  private createAssetError() {
    this.translationService.translate('_GP_._MSG_._GUARANTEES_NOT_EXCLUDED').subscribe(label => {
      const error = {} as ErrorResp;
      error.code = 'FOOTER_ASSET_UPD';
      error.level = ErrorCode.BLOCKING;
      error.message = label;
      this.stateGuarantees.errors.push(error);
      this.stateManagerGuarantees.actionUpdateState();
    });
  }

  get isCommissionBtnEnabled(): boolean {
    return this.gpCommBtnService.isCommissionFooterBtnEnabled(this.stateGuarantees);
  }

  get isCommissionsBtnVisible(): boolean {
    return this.gpCommBtnService.isCommissionFooterBtnVisible(this.stateGuarantees);
  }

  get commissionsBtnLabel(): string {
    return this.gpCommBtnService.getCommissionFooterBtnLabel(this.stateGuarantees);
  }

  get isDiscountsBtnEnabled(): boolean {
    return this.gpCommBtnService.isDiscountFooterBtnEnabled(this.stateGuarantees);
  }

  get isDiscountsBtnVisible(): boolean {
    return this.gpCommBtnService.isDiscountFooterBtnVisible(this.stateGuarantees);
  }

  get discountsBtnLabel(): string {
    return this.gpCommBtnService.getDiscountFooterBtnLabel(this.stateGuarantees);
  }

  reloadAssets($event: boolean) {
    if (!!$event) {
      this.stateManagerGuarantees.actionGetAssets(this.previousStepData);
    }
  }

  evalIntermediateSaveVisibility() {
    return this.crossService.isContractStatusUndefined(this.previousStepData);
  }
}

<ng-container [formGroup]="parentFormGroup">
  <rgi-rx-datatable rgi-rx-qa="my-table" qa-ctx="my-app-context"
                    [data]="rows" [schema]="schema"
                    [expansionRow]="taxesDetail"
                    [expansionModel]="expansionModel"
                    [pageOptions]="[1000]"
                    class="rgipc-table-taxes-detail">

    <ng-template rgiRxDataTableCell="NET" let-ctx>
      <rgi-rx-form-field>
        <!--        <input type="text" rgiCountryLayerInputNumberFormat
                       [maximumFractionDigits]="2" [minimumFractionDigits]="2"
                       formControlName="{{ctx.row.code + '_net'}}">-->
        <input type="number" rgiRxInput formControlName="{{ctx.row.code + '_net'}}">
      </rgi-rx-form-field>
    </ng-template>

    <ng-template rgiRxDataTableCell="NET_INTERESTS" let-ctx>
      <rgi-rx-form-field>
        <!--<input type="text" rgiCountryLayerInputNumberFormat
               [maximumFractionDigits]="2" [minimumFractionDigits]="2"
               formControlName="{{ctx.row.code + '_netInterests'}}">-->
        <input type="number" rgiRxInput formControlName="{{ctx.row.code + '_netInterests'}}">
      </rgi-rx-form-field>
    </ng-template>

    <ng-template rgiRxDataTableCell="CHARGES" let-ctx>
      <rgi-rx-form-field>
        <!--        <input type="text" rgiCountryLayerInputNumberFormat
                       [maximumFractionDigits]="2" [minimumFractionDigits]="2"
                       formControlName="{{ctx.row.code + '_charges'}}">-->
        <input type="number" rgiRxInput formControlName="{{ctx.row.code + '_charges'}}">
      </rgi-rx-form-field>
    </ng-template>

    <ng-template rgiRxDataTableCell="CHARGES_INTERESTS" let-ctx>
      <rgi-rx-form-field>
        <!--        <input type="text" rgiCountryLayerInputNumberFormat
                       [maximumFractionDigits]="2" [minimumFractionDigits]="2"
                       formControlName="{{ctx.row.code + '_chargesInterests'}}">-->
        <input type="number" rgiRxInput formControlName="{{ctx.row.code + '_chargesInterests'}}">
      </rgi-rx-form-field>
    </ng-template>

    <ng-template rgiRxDataTableCell="TOTAL_TAXES" let-ctx>
      <div class="rgipsales-flex">
        <div class="rgi-ui-datatable-expansion-cell">
          <i role="button" class="rgi-ui-expansion-cell-open" *ngIf="!expansionModel.isExpanded(ctx.row)"
             [attr.aria-controls]="'rgi-rx-table-expansion-cell-'+ctx.index"
             [attr.aria-label]="'RGI_RX.DATATABLE.EXPAND_ROW_LABEL' | translate : {index: (ctx.index+1)}"
             (click)="expansionModel.expand(ctx.row)"
          ></i>
          <i role="button" class="rgi-ui-expansion-cell-close" *ngIf="expansionModel.isExpanded(ctx.row)"
             [attr.aria-controls]="'rgi-rx-table-expansion-cell-'+ctx.index"
             [attr.aria-label]="'RGI_RX.DATATABLE.COLLAPSE_ROW_LABEL' | translate : {index: (ctx.index+1)}"
             (click)="expansionModel.contract(ctx.row)"></i>
        </div>
        <div class="rgipsales-flex-gr-1">
          {{ ctx.row.TOTAL_TAXES | rgiCountryLayerNumberFormat:undefined:{
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        } }}
        </div>
      </div>
    </ng-template>

    <ng-template rgiRxDataTableCell="COLLECTED_COMMISSIONS" let-ctx>
      <rgi-rx-form-field>
        <!--        <input type="text" rgiCountryLayerInputNumberFormat
                       [maximumFractionDigits]="2" [minimumFractionDigits]="2"
                       formControlName="{{ctx.row.code + '_collectedCommissions'}}">-->
        <input type="number" rgiRxInput formControlName="{{ctx.row.code + '_collectedCommissions'}}">
      </rgi-rx-form-field>
    </ng-template>

    <ng-template rgiRxDataTableCell="PURCHASED_COMMISSIONS" let-ctx>
      <rgi-rx-form-field>
        <!--        <input type="text" rgiCountryLayerInputNumberFormat
                       [maximumFractionDigits]="2" [minimumFractionDigits]="2"
                       formControlName="{{ctx.row.code + '_purchasedCommissions'}}">-->
        <input type="number" rgiRxInput formControlName="{{ctx.row.code + '_purchasedCommissions'}}">
      </rgi-rx-form-field>
    </ng-template>

  </rgi-rx-datatable>
</ng-container>

<ng-template #taxesDetail let-row>
  <div class="rgipc-vp-l">
    <rgi-pnc-psales-taxes-detail [taxes]="row.taxes"></rgi-pnc-psales-taxes-detail>
  </div>
</ng-template>

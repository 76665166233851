import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../model/configuration';
import { CookieResponse } from '../model/cookie-response';

@Injectable({
  providedIn: 'root'
})
export class CookieAnalyticsService {

  isCookiePresent = false;

  constructor(private http: HttpClient, handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  setCookieAnalytics(configuration: Configuration): Observable<CookieResponse> {
    const url = configuration.protocolHttp + '://' + configuration.host + '/' + configuration.proxy + '/qps/user';
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + configuration.token
    );
    this.isCookiePresent = true;
    return this.http.get<CookieResponse>(url, { headers, withCredentials: true });
  }

  getTheme(configuration: Configuration, theme: string) {
    const url = configuration.protocolHttp + '://' + configuration.host + '/' + configuration.proxy  + '/resources/' +
      'assets/external/sense-themes-default/' + theme + '/theme.json';
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + configuration.token
    );
    this.isCookiePresent = true;
    return this.http.get(url, { headers, withCredentials: true });
  }
}

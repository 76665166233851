import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BenefsummaryPipe } from './benefsummary.pipe';



@NgModule({
  declarations: [BenefsummaryPipe],
  imports: [
    CommonModule
  ],
  exports: [BenefsummaryPipe],
  providers: [BenefsummaryPipe]
})
export class BenefSummaryModule { }

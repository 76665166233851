<!-- Toggle -->
<div class="row type-distribution">
  <div class="col-lg-3">
    <label translate>lpc_distribution</label>
  </div>
  <div class="col-lg-5">
    <rgi-rx-button-toggle (onToggle)="toggleSwitch($event)" [value]="isAmount" [attr.data-qa]="'distribution-toggle'">
      <button rgi-rx-button rgiRxValueOption [value]="AMOUNT">{{currencyCode}}</button>
      <button rgi-rx-button rgiRxValueOption [value]="PERCENTAGE">%</button>
    </rgi-rx-button-toggle>
  </div>
</div>
<!-- End Toggle -->

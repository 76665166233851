<form [formGroup]="contactsAddressForm">
  <div class="rgi-ui-grid-4">
    <div class="rgi-ui-col" style="padding-top: 3%;">
      <div>{{'_ANAG_._LABEL_._LANDLINE_NO_' | translate}}</div>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ANAG_._LABEL_._INTERNATIONAL_DIALLING_CODE_</label>
        <select rgiRxNativeSelect data-qa="anag-phone-number-int-prefix" [formControlName]="'phoneNumberIntPrefix'">
          <option selected value="">{{'_ANAG_._LABEL_._INT_PREFIX_' | translate}}</option>
          <option *ngFor="let prefix of internationalPrefixes"
                  [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ANAG_._LABEL_._LOCAL_AREA_DIAL_CODE_</label>
        <input rgiRxInput data-qa="anag-phone-number-prefix" [formControlName]="'phoneNumberPrefix'"
               placeholder="{{'_ANAG_._LABEL_._PREFIX_'| translate}}">
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ANAG_._LABEL_._NUMBER_</label>
        <input rgiRxInput data-qa="anag-phone-number" [formControlName]="'phoneNumber'"
               placeholder="{{'_ANAG_._LABEL_._NUMBER_'| translate}}">
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col" style="padding-top: 1%;">
      <div>{{'_ANAG_._LABEL_._FAX_NO_' | translate}}</div>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <select rgiRxNativeSelect data-qa="anag-fax-number-int-prefix" [formControlName]="'faxNumberIntPrefix'">
          <option selected value="">{{'_ANAG_._LABEL_._INT_PREFIX_' | translate}}</option>
          <option *ngFor="let prefix of internationalPrefixes"
                  [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <input rgiRxInput data-qa="anag-fax-number-prefix" [formControlName]="'faxNumberPrefix'"
               placeholder="{{'_ANAG_._LABEL_._PREFIX_'| translate}}">
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <input rgiRxInput data-qa="anag-fax-number" [formControlName]="'faxNumber'"
               placeholder="{{'_ANAG_._LABEL_._NUMBER_'| translate}}">
      </rgi-rx-form-field>
    </div>
  </div>
</form>

import { Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ApiClaim, ApiTranslatorClaimService, CardComponent, CardComponentService, SessionService
  } from '@rgi/digital-claims-common-angular';
import { ApiInvoiceFilter } from '../api/api-invoice-filter';
import { Invoice } from '../dto/invoice';
import { InvoiceDetails } from '../dto/invoice-details';
import { InvoiceExpert } from '../dto/invoice-expert';
import { InvoiceAssignmentsListComponent } from '../invoice-assignments-list/invoice-assignments-list.component';
import { InvoiceTypeoflossListComponent } from '../invoice-typeofloss-list/invoice-typeofloss-list.component';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-small-list',
  templateUrl: './invoice-small-list.component.html',
  styleUrls: ['./invoice-small-list.component.scss']
})
export class InvoiceSmallListComponent extends CardComponent implements OnInit {

  invoices: Invoice[] = [];
  filterInvoice: ApiInvoiceFilter;
  totalPages: number;
  sessionServiceInject: any;
  arrPage: number[] = [];
  errorMessage: string;
  emitter: any;
  currentPage: number;
  private claim: ApiClaim;
  // resCore: any;
  // claimCurrent: any;

  constructor(
    @Inject('sessionService') sessionServiceInject: any,
    @Inject('eventService') eventService: any,
    @Inject('containerCacheService') private cache,
    // @Inject('coreResult') coreResult,
    // @Inject('angularGridInstance') private gridInstance: any,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private invoiceService: InvoiceService,
    private modalService: NgbModal,
    private apiTranslatorClaimService: ApiTranslatorClaimService,
    public translateService: RgiRxTranslationService,
  ) {
    super(cardComponentService, sessionService);
    this.sessionServiceInject = sessionServiceInject;
    this.emitter = eventService;
    // this.resCore = coreResult;
  }

  ngOnInit(): void {
    // this.claimCurrent = this.resCore.getResult(this.card.id, 'detail');
    const jsonClaim = this.cache.getCache().get(this.card.idSession).data;
    this.claim = this.apiTranslatorClaimService.jsonClaimToApiClaim(jsonClaim);
    this.getInvoices();
  }

  getInvoices() {

    let invoicesRes = [];
    this.invoices = [];

    this.errorMessage = null;
    // this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');
    const filter = new ApiInvoiceFilter();

    filter.claimNumber = this.claim.claimNumber;

    this.invoiceService.getInvoicesList(filter)
      .subscribe((response: any) => {
        invoicesRes = response.invoiceList;
        if (invoicesRes && invoicesRes.length > 0) {

          for (const invoiceRes of invoicesRes) {
            this.invoices.push(invoiceRes);
          }

        } else {
          this.translateService.translate('_CLAIMS_._NO_RESULT').subscribe(res=> {
            this.errorMessage=res;
          }
          )
        }
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._MESSAGE_._SEARCH_ERROR').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.emitter.broadcastEvent('stop-loader');
        }
      );
  }

  hasResult() {
    if (this.invoices && this.invoices.length > 0) {
      return true;
    }
    return false;
  }

  rejectInvoice(rejectCode: string,  invoice: Invoice) {

    switch (rejectCode) {

      // Più incarichi aperti su stesso professionista
      case '8':
        const modalRef = this.modalService.open(InvoiceAssignmentsListComponent, {
          windowClass: 'basic',
          size: 'lg'
        });
        modalRef.componentInstance.invoice = invoice;
        modalRef.componentInstance.claim = this.claim;

        modalRef.result.then((result) => {
          if (result) {
            this.getInvoices();
          }
        });
        break;

      // Piu causali collegate aperte
      case '18':
        const modalRefTol = this.modalService.open(InvoiceTypeoflossListComponent, {
          windowClass: 'basic',
          size: 'lg'
        });
        modalRefTol.componentInstance.invoice = invoice;
        modalRefTol.componentInstance.claim = this.claim;
        modalRefTol.result.then((result) => {
          if (result) {
            this.getInvoices();
          }
        });
        break;

      default:
        break;
    }

  }

  rejectDetails(invoiceDetails: InvoiceDetails) {
  }

  // rejectExpert(invoice: Invoice) {

  //   switch (invoice.invoiceExpert.rejectType.codice) {

  //     // Più incarichi aperti su stesso professionista
  //     case '8':
  //       const modalRef = this.modalService.open(InvoiceAssignmentsListComponent, {
  //         windowClass: 'basic',
  //         size: 'lg'
  //       });
  //       modalRef.componentInstance.invoice = invoice;
  //       modalRef.componentInstance.claim = this.claim;

  //       modalRef.result.then((result) => {
  //         if (result) {
  //           this.getInvoices();
  //         }
  //       });
  //       break;

  //     default:
  //       break;
  //   }

  // }

  isManagedRejectType(rejectCode: string) {

    let managedRejectType = false;

    switch (rejectCode) {

      // Più incarichi aperti su stesso professionista
      case '8':
        managedRejectType = true;
        break;
      case '18':
        managedRejectType = true;
        break;

      default:
        break;
    }

    return managedRejectType;
  }

}

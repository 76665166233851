export class ExpectedDocument {
  constructor(
    public code: string,
    public description: string,
    public name: string,
    public priority: number,
    public typeCode: string,
    public original: boolean,
    public entityTypeCode: string,
    public status: string
  ) {
  }
}

import {AfterViewInit, Component, Input} from '@angular/core';
import { FundRevaluation } from '../../../../models/life-detail.model';

import {PlcCurrencyUtils} from '../../../../utils/plc-currency';

@Component({
  selector: 'lpc-revaluation-detail-table',
  templateUrl: './revaluation-detail-table.component.html',
  styleUrls: ['./revaluation-detail-table.component.css']
})
export class RevaluationDetailTableComponent implements AfterViewInit {

  @Input() revaluationData: FundRevaluation[];

  constructor() { }

  ngAfterViewInit(): void {
    this.updateClassForRevaluationData();
  }

  public getSymbol(symbol: string) {
    return PlcCurrencyUtils.fixEuroSymbol(symbol);
  }

  public openSession() {
    throw new Error('Method not implemented yet.');
  }

  public trackByFn(index, item ) {
    return Math.floor(Math.random() * (item.versione - index + 1) + index);
  }

  private updateClassForRevaluationData() {
    if (!this.revaluationData || this.revaluationData.length === 0) {
      return;
    }

    let prevIdPrestazione = this.revaluationData[0].idPrestazione;
    let prevClass = 'regular-even';

    for (const revaluation of this.revaluationData) {
      if (revaluation.idPrestazione !== prevIdPrestazione) {
        prevClass = prevClass === 'regular-odd' ? 'regular-even' : 'regular-odd';
      }

      revaluation.cssClass = prevClass;
      prevIdPrestazione = revaluation.idPrestazione;
    }
  }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Fund, Profile} from '../../model/profile';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FundPercentageError} from '../../model/FundPercentageError';

@Component({
  selector: 'lic-funds-group',
  templateUrl: './lic-funds-group.component.html',
  styleUrls: ['./lic-funds-group.component.css']
})
export class LicFundsGroupComponent implements OnInit, OnDestroy {

  @Input() funds: Fund[] = [];
  @Input() selectedFunds: Fund[] = [];
  @Input() amount: number;
  @Output() fundChanged: EventEmitter<Fund[]> = new EventEmitter<Fund[]>();
  @Output() readyChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  leftFundAmount: number;
  percentErrors: Array<FundPercentageError> = [];
  fg: UntypedFormGroup = new UntypedFormGroup({
    FAFunds: new UntypedFormArray([])
  });
  subscriptions: Subscription[] = [];
  searchPattern = '';

  constructor() { }

  ngOnInit() {
    this.leftFundAmount = this.getLeftFundAmount(this.funds);

    this.funds.forEach(p => (this.fg.get('FAFunds') as UntypedFormArray).push(new UntypedFormControl(p)));
    this.sendInitUpdate();
    this.subscriptions.push(this.fg.valueChanges.subscribe(value => {
      this.fundChanged.emit(value.FAFunds);
      this.leftFundAmount = this.getLeftFundAmount(((value.FAFunds as Fund[])));
      this.percentErrors = this.checkPercentage(value.FAFunds as Fund[]);
      this.readyChanged.emit(this.leftFundAmount === 0 && this.percentErrors.length === 0);
    }));
  }

  private sendInitUpdate() {
    const value = this.fg.get('FAFunds').value;
    this.fundChanged.emit(value);
    this.leftFundAmount = this.getLeftFundAmount(((value as Fund[])));
    this.readyChanged.emit(this.leftFundAmount === 0);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getLeftFundPercent(leftAmount: number) {
    return +((leftAmount / this.amount) * 100).toFixed(3);
  }

  getLeftFundAmount(funds: Profile[]): number {
    const sel = funds.filter(f => f.selected);
    if (sel.length > 0) {
      const allPercent = sel.every(sp => sp.isPercent);
      const totPercent = sel.map(p => +p.percent).reduce((a, c) => a + c, 0);
      if (allPercent && totPercent === 100) {
        const allExceptLast = sel
          .map(p => +p.currency)
          .reduce((a, c, i) => i < sel.length - 1 ? a + c : a, 0);
        sel[sel.length - 1].currency = this.amount - allExceptLast;
      }
      const investedAmount = +sel.map(p => p.currency ? +p.currency : 0)
        .reduce((a, c) => a + c, 0).toFixed(2);
      return this.amount - investedAmount;
    } else {
      return this.amount;
    }
  }

  getHidden(name: string): boolean {
    if (!name) { return false; }
    return !name.toLowerCase().includes(this.searchPattern.toLowerCase());
  }

  private checkPercentage(funds: Fund[]): FundPercentageError[] {
    return funds.map(f => f.percent <= f.maxPercentage && f.percent >= f.minPercentage || !f.selected ? null :
      { fundName: f.name, fundP: f.percent, fundMinP: f.minPercentage, fundMaxP: f.maxPercentage }).filter(m => !!m);
  }
}

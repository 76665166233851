<div data-qa="life-reservation-detail">

    <div class="row" id="funds-row" *ngIf="initializeViewDetailReservation">
        <!-- PRIMA COLONNA COL-8 -->
        <div class="col-md-12 col-lg-8">

            <!-- INIZIO DATI PRENOTAZIONE DINAMICO -->
            <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                            <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;" (click)="back()" data-qa="back-arrow"></span>
                            <span class="separator"> | </span>
                            <span class="menu-title" translate>lpc_booking_detail_label</span>
                        </div>
                    </div>
                </div>
                <!-- INIZIO DETTAGLI PRENOTAZIONE -->
                <div class="col-md-12 life-detail-first-info">
                    <div class="col-md-5 life-detail-img">
                        <div class="hidden-xs hidden-sm anag-user-ico">
                            <span class="rgifont life-detail-icon-padding rgi-folder"></span>
                        </div>
                    </div>
                    <div class="col-md-7 life-detail-general-info">
                        <h3 class="col-md-12 card-color-blue" style="margin-top: 0 !important;">
                            <span data-qa="movementDescription"> {{bookingData.reservationType}}</span>
                        </h3>
                        <div class="container-fluid anag-data">
                            <div *ngIf="bookingData.cause" class="col-md-12">
                                <span class="tbld_col_title" translate>lpc_booking_cause</span>
                                <span class="tbld_col_value">{{bookingData.cause}}</span>
                            </div>
                            <div *ngIf="bookingData.destinationNode" class="col-md-12">
                                <span class="tbld_col_title" translate>lpc_booking_destinationNode</span>
                                <span class="tbld_col_value">{{bookingData.destinationNode}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- FINE DETTAGLI PRENOTAZIONE -->
                <!-- INIZIO QUADRATI DETTAGLIO BLU -->
                <div class="col-md-12 padding-card">
                    <div class="col-md-6 life-detail-infobox">
                        <div class="col-md-12">
                            <span class="life-increase-font-size" data-qa="policyId">{{bookingData.reservationId}}</span>
                        </div>
                        <div class="col-md-12">
                            <span class="tbld_col_title" style="color: white; font-size: 18px;" translate>lpc_booking_number</span>
                        </div>
                    </div>
                    <div class="col-md-6 life-detail-infobox">
                        <div class="col-md-12">
                            <span class="life-increase-font-size" data-qa="policyDateValue">{{getSummaryDateValue}}</span>
                        </div>
                        <div class="col-md-12">
                            <span class="tbld_col_title" style="color: white; font-size: 18px;" translate>{{getSummaryDateDescription}}</span>
                        </div>
                    </div>
                </div>
                <!-- FINE QUADRATI DETTAGLIO BLU-->
            </div>
            <!-- FINE DATI PRENOTAZIONE DINAMICO -->

            <!-- INIZIO RISCHI -->
            <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                        <span class="header-icon rgifont "></span>
                        <span class="menu-title" translate>lpc_Rischi</span>
                        </div>
                    </div>
                </div>
                <div style="padding: 5px;">
                    <ng-container *ngFor="let unit of data.bookingDetailData.risks; index as i">
                          <div class="row" [ngClass]="{'unit-removed' : isUnitRemoved(unit.status)}">
                            <div class="col-md-6 col_title_reservation" style="width: 100% !important;" translate>
                              <span style="padding-left: 10px;">{{unit.description}}</span>
                            </div>
                            <div class="col-md-6 col_title_reservation" style="text-align: end;">
                              <span style="padding-right: 10px;">{{unit.statusDescription}}</span>
                            </div>
                          </div>
                            <!-- Fattori -->
                            <ng-container *ngIf="unit.riskFactors && unit.riskFactors.length > 0">
                              <div class="tbld">
                                <div class="tbld_row life-detail-tbld_row_header">
                                  <div class="tbld_col tbld_col_title">
                                    <span style="padding-left: 10px;" translate>lpc_RiskData</span>
                                  </div>
                                  <div class="tbld_col tbld_col_title" *ngFor="let divs of [0,1,2]"></div>
                                </div>
                                <div class="tbld_row" *ngFor="let fraction of getFactorRows(unit.riskFactors)">
                                  <ng-container *ngFor="let f of getFactorFractionized(unit.riskFactors).get(fraction)">
                                    <div class="tbld_col tbld_col_title life-detail-table-border">{{f.description}}</div>
                                    <div class="tbld_col tbld_col_value life-detail-table-border">{{formatFactorValue(f.value, f.type)}}</div>
                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                            <!-- sezione dei sovrappremi -->
                            <div class="tbld">
                              <div class="tbld_row life-detail-tbld_row_header">
                                <div class="tbld_col tbld_col_title">
                                  <span style="padding-left: 10px;" translate>lpc_extra_premiums</span>
                                </div>
                                <div class="tbld_col tbld_col_title"></div>
                                <div class="tbld_col tbld_col_title"></div>
                                <div class="tbld_col tbld_col_title"></div>
                              </div>
                              <div class="tbld_row">
                                <ng-container *ngIf="!!unit.addPremiumHealth">
                                  <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_booking_extrapremiumHealth</div>
                                  <div class="tbld_col tbld_col_value life-detail-table-border">{{unit.addPremiumHealth}}</div>
                                </ng-container>
                                <ng-container *ngIf="!!unit.addPremiumProf">
                                  <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_booking_extrapremiumProf</div>
                                  <div class="tbld_col tbld_col_value life-detail-table-border">{{unit.addPremiumProf}}</div>
                                </ng-container>
                              </div>
                              <div class="tbld_row">
                                <ng-container *ngIf="!!unit.addPremiumSport">
                                  <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_booking_extrapremiumSport</div>
                                <div class="tbld_col tbld_col_value life-detail-table-border">{{unit.addPremiumSport}}</div>
                                </ng-container>
                                <ng-container *ngIf="!!unit.addPremiumOther">
                                  <div class="tbld_col tbld_col_title life-detail-table-border" translate>lpc_booking_extrapremiumOther</div>
                                <div class="tbld_col tbld_col_value life-detail-table-border">{{unit.addPremiumOther}}</div>
                                </ng-container>
                              </div>
                            </div>

                            <hr class="booking-line" *ngIf="i !== data.bookingDetailData.risks.length && i < data.bookingDetailData.risks.length">
                    </ng-container>
                </div>
            </div>
            <!-- FINE RISCHI -->

            <!-- INIZIO QUESTIONARI -->
            <div *ngIf="surveys.length > 0" class="col-md-12 life-detail-margin-box life-detail-box" data-qa="surveys-table" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                  <div class="row life-detail-title">
                    <div class="card-title">
                      <span class="header-icon rgifont "></span>
                      <span class="menu-title" translate>lpc_questionnaire</span>
                    </div>
                  </div>
                </div>
                <div class="tbld life-detail-margin-top">
                 <div class="tbld_row life-detail-tbld_row_header">
                   <div class="tbld_col tbld_col_title" translate>lpc_questionnaire</div>
                   <div class="tbld_col tbld_col_title"></div>
                 </div>
                 <ng-container *ngFor="let survey of surveys">
                   <div class="tbld_row life-detail-investment-profile-row">
                     <div class="tbld_col tbld_col_value life-detail-table-border first_column_quest">{{survey.description}}</div>
                     <div class="tbld_col tbld_col_value life-detail-table-border" [attr.data-qa]="survey">
                       <button class="btn dt-btn" (click)="openQuest(survey.questionnarieId)">
                         <span class="rgifont rgi-chevron-right"></span>
                       </button>
                     </div>
                   </div>
                 </ng-container>
               </div>
            </div>
            <!-- FINE QUESTIONARI -->
        </div>

        <!-- SECONDA COLONNA COL-4 -->
        <div class="col-md-12 col-lg-4">
            <!-- DATI DI POLIZZA -->
            <div *ngIf="showPolicyFactors" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                            <span class="header-icon rgifont"></span>
                            <span class="menu-title" translate>lpc_booking_policyData</span>
                        </div>
                    </div>
                </div>
                <div class="tbld life-detail-margin-top">
                    <div class="tbld_row life-detail-tbld_row_header">
                        <div class="tbld_col tbld_col_title" translate>lpc_booking_policyData</div>
                        <div class="tbld_col tbld_col_title"></div>
                    </div>
                    <div *ngFor="let f of data.bookingDetailData.policyFactors" class="tbld_row">
                        <div class="tbld_col tbld_col_title life-detail-table-border">{{f.description}}</div>
                        <div class="tbld_col tbld_col_value life-detail-table-border">{{formatFactorValue(f.value, f.type)}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- FINE DATI DI POLIZZA -->
            <!-- DATI DI BENE -->
            <div *ngIf="showAssetFactors" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                            <span class="header-icon rgifont"></span>
                            <span class="menu-title" translate>lpc_booking_assetData</span>
                        </div>
                    </div>
                </div>
                <div class="tbld life-detail-margin-top">
                    <div class="tbld_row life-detail-tbld_row_header">
                        <div class="tbld_col tbld_col_title" translate>lpc_booking_assetData</div>
                        <div class="tbld_col tbld_col_title"></div>
                    </div>
                    <div *ngFor="let f of data.bookingDetailData.assetFactors" class="tbld_row">
                        <div class="tbld_col tbld_col_title life-detail-table-border">{{f.description}}</div>
                        <div class="tbld_col tbld_col_value life-detail-table-border">{{formatFactorValue(f.value, f.type)}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- FINE DATI DI BENE -->
            <!-- BENEFICIARI  -->
            <lpc-lb-subjects *ngIf="!!bookingData.beneficiaries?.length" [beneficiaries]="bookingData.beneficiaries"></lpc-lb-subjects>
            <!-- FINE BENEFICIARI -->
        </div>
    </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {Unit} from '../../models/domain-models/unit';

@Component({
  selector: 'mic-package-factors',
  templateUrl: './package-factors.component.html',
  styleUrls: ['./package-factors.component.scss']
})
export class PackageFactorsComponent implements OnInit {

  @Input() unit: Unit;

  constructor() {
  }

  ngOnInit() {
  }

}

import { formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ExposureEntity } from '../domain/exposure-entity';
import { FactorEntity } from '../domain/factor-entity';
import { NewPaymentService } from '../new-payment.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-calculator-factor',
  templateUrl: './calculator-factor.component.html',
  styleUrls: ['./calculator-factor.component.scss']
})
export class CalculatorFactorComponent implements OnInit {
  @Input() exp: ExposureEntity;
  @Input() claimNumber: string;
  @Input() newPayFormModal: UntypedFormGroup;
  public today: NgbDate;

  public factors: Array<FactorEntity>;
  public errorMessage: string;
  public vectorErrorMessage: Array<string>;
  public cssMsg = 'alert alert-danger';

  constructor(
    @Inject('eventService') private eventService: any,
    @Inject(LOCALE_ID) private locale: string,
    public activeModal: NgbActiveModal,
    public translateService: RgiRxTranslationService,
    private formBuilder: UntypedFormBuilder,
    private newPaymentService: NewPaymentService) {
      const today = new Date();
      this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
      
     }

  ngOnInit() {
    this.factors = new Array<FactorEntity>();
    this.cssMsg = 'alert alert-danger';
    this.factors = this.factors.concat(this.exp.paymentFactors.filter(el => el.type === '1' ||
                                           el.type === '3' || el.type === '0'));
    this.factors = this.factors.concat(this.exp.paymentFactors.filter(el => el.type === '4'));
    // this.factors = this.exp.paymentFactors;
    let index = 0;
    this.factors.forEach(aFact => {
      if (aFact.type === '1' || aFact.type === '3' || aFact.type === '0') {
        if (aFact.required) {
          this.newPayFormModal.addControl('factval' + index,
          new UntypedFormControl({  disabled: !aFact.editable, require: aFact.required }, Validators.required));
        } else {
          this.newPayFormModal.addControl('factval' + index,
          new UntypedFormControl({  disabled: !aFact.editable }, Validators.nullValidator));
        }
        if (!aFact.value) {
         this.newPayFormModal.controls['factval' + index].setValue(aFact.defaultValue);
        }
      } else if (aFact.type === '4') {
        if (aFact.required) {
          this.newPayFormModal.addControl('factval' + index,
          new UntypedFormControl({  disabled: !aFact.editable, require: aFact.required }, Validators.required));
        } else {
          this.newPayFormModal.addControl('factval' + index,
          new UntypedFormControl({  disabled: !aFact.editable }, Validators.nullValidator));
        }
        if (!aFact.value) {
          let  data: Date = null;
          if (aFact.defaultValue) {
            const elData = aFact.defaultValue.split('-');
            data = new Date(elData[2] + '-' + elData[1] + '-' + elData[0]);
          }
          this.newPayFormModal.controls['factval' + index].setValue(data);
        }
      }
      index++;
    });
  }

  calculate() {
    console.log('calculate Calculator - 1');

    if (this.validateContent()) {
      this.eventService.broadcastEvent('start-loader');
      this.errorMessage = null;
      this.vectorErrorMessage = null;
      this.newPayFormModal.controls['expAmount' + this.exp.idCampo].setValue(0);
      this.newPaymentService.factorsLogicVerifyCalculate(this.exp, this.claimNumber)
       .subscribe((expOut: ExposureEntity) => {
        console.log('factorsLogicVerifyCalculate - outcome:  ', expOut);
        this.exp.calculatedAmount = expOut.calculatedAmount;
        this.exp.validationMsg = expOut.validationMsg;
        this.exp.validationStatus = expOut.validationStatus;
        if (this.exp.validationMsg) {
          // è presente un errore
          this.vectorErrorMessage = new Array<string>();
          this.errorMessage = this.exp.validationMsg;
          const splErr = this.errorMessage.split('-');

          splErr.forEach(aMsg => {
            if (aMsg && aMsg.trim() !== '') {
              this.vectorErrorMessage.push(aMsg);
            }
          });

          if (this.exp.validationStatus && this.exp.validationStatus === 'OK') {
           this.cssMsg = 'alert alert-success';
          } else {
           this.cssMsg = 'alert alert-danger';
          }
        }
        this.eventService.broadcastEvent('stop-loader');
       },
       (error) => {
        this.cssMsg = 'alert alert-danger';
        this.vectorErrorMessage = new Array<string>();
        console.log('factorsLogicVerifyCalculate - ERROR');
        this.errorMessage = 'Service Error';
        this.vectorErrorMessage.push(this.errorMessage);
        this.eventService.broadcastEvent('stop-loader');
       }
      );
    }
  }

  onConfirm() {
    console.log('onConfirm Calculator - 1');
    this.newPayFormModal.controls['expAmount' + this.exp.idCampo].setValue(this.exp.calculatedAmount);
    this.newPayFormModal.controls['expSel' + this.exp.idCampo].setValue(true);
    this.activeModal.dismiss();
  }

  protected validateContent(): boolean {
    this.errorMessage = null;
    this.vectorErrorMessage = null;
    this.cssMsg = 'alert alert-danger';

    let index = 0;
    this.factors.forEach(aFact => {
      if (aFact.type === '1' || aFact.type === '3' || aFact.type === '4' || aFact.type === '0') {
        if (aFact.required &&  this.newPayFormModal.controls['factval' + index].value == null) {
          this.translateService.translate('_CLAIMS_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.vectorErrorMessage = new Array<string>();
          this.vectorErrorMessage.push(this.errorMessage);
        } else {
          if (aFact.type === '4') {
            if (this.newPayFormModal.controls['factval' + index].value) {
              const formatdate = 'dd-MM-yyyy';
              const dateString = formatDate(
                          this.newPayFormModal.controls['factval' + index].value,
                          formatdate, this.locale);
              aFact.value = dateString;
            }
          } else {
            aFact.value = this.newPayFormModal.controls['factval' + index].value;
          }
        }
      }
      index++;
    });

    return this.errorMessage == null;
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}

import {AssetInstance} from './asset-instance';
import {AnagIssueSubject} from '../../re-issue/re-issue-anag/anag-issue';


export class SubjectInstance {
  public instance: AssetInstance;
  public subject: AnagIssueSubject;
  public isContraente: boolean;
  public assetCode: string;

  constructor(instance: AssetInstance, subject: AnagIssueSubject) {
    this.instance = instance;
    this.subject = subject;
  }
}

import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxLocalStateManager, StateStoreService} from '@rgi/rx/state';
import {AuthorizationCardStateList} from "./authorization-card-state";
import {AuthorizationCardReducerService} from './authorization-card-reducer.service';


export class AuthorizationCardStateManagerList extends RgiRxLocalStateManager<AuthorizationCardStateList> {

  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, private reducer: AuthorizationCardReducerService) {
    super(activeRoute, stateStoreService);
  }


  removeSession(routeId: any) {
    this.reducer.removeSession(routeId);
  }
}

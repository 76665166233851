import {Component, Inject, ViewChild} from '@angular/core';
import {
  ReIssueEmissionState,
  ReIssueEmissionStateManagerService
} from '../re-issue-state-manager/re-issue-emission-state-manager.service';
import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {SessionService} from '../re-issue-utility/session.service';
import {PncDocumentsComponent} from '@rgi/pnc-postsales';

@Component({
  selector: 're-issue-emission',
  templateUrl: './re-issue-emission.component.html',
  styleUrls: ['./re-issue-emission.component.css']
})
export class ReIssueEmissionComponent {

  // pinConfirmed: boolean = false;
  printed = false;
  @ViewChild(PncDocumentsComponent) documentsComponent : PncDocumentsComponent;

  constructor(
    public stateManager: ReIssueEmissionStateManagerService<ReIssueEmissionState>,
    protected translate: RgiRxTranslationService,
    public activeRoute: ActiveRoute,
    @Inject('sessionService') protected portalSession: SessionService,
  ) {
  }

  close() {
    this.portalSession.remove(this.activeRoute.id);
  }

  getId(st: ReIssueEmissionState) {
    return st.policy?.contractNumber?.policy || st.resourceId;
  }

  download(documentCode: string){
    this.documentsComponent.download(this.stateManager.downloadDocuments$(documentCode));
  }
}

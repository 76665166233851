import {Mode} from '../../group-policy-models/group-policy-mode';
import {Injectable} from '@angular/core';
import {INQUIRY_READONLY_MODE} from '../../group-policy-resources/gp-inquiry-mode.cfg';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessModesInquiryService {
  private modes: Mode [] = [];

  constructor() {
    this.registerMode(INQUIRY_READONLY_MODE);
  }

  getMode(sMode: string): Mode {
    let foundMode = this._getMode(sMode);
    if (!foundMode) {
      const mode = new Mode(sMode);
      this.modes.push(mode);
      foundMode = mode;
    }
    return foundMode;
  }

  hasFunction(sMode: string, sFunction: string): boolean {
    let ret = false;
    const foundMode = this._getMode(sMode);
    if (foundMode) {
      ret = foundMode.hasFunction(sFunction);
    }
    return ret;
  }

  private _getMode(sMode: string) {
    return this.modes.find(el => el.modeName() === sMode);
  }

  registerMode(mode: Mode) {
    const existingMode = this.modes.find(m => m.modeName() === mode.modeName());
    if (!!existingMode) {
      existingMode.setFunctions(mode.getFunctions());
    } else {
      this.modes.push(mode);
    }
  }
}

import {AnagApiEntity, AnagEntityIta} from '../anag-api/anag-subject-api';
import {AnagApiAddress, AnagApiOtherAddress} from "./anag-api-party";

export class AnagTaxClassification {
  taxClassificationsData: AnagtaxClassificationData[];
  regCountry: string;
}

export class AnagtaxClassificationData {
  objectId: string;
  country: AnagEntityIta;
  type: AnagEntityIta;
  tin: string;
  riskClassification: AnagEntityIta;
  reasonClassification: AnagEntityIta;
  basisClassification: AnagEntityIta;
  classificationDate: Date;
  countryStartDate: Date;
  countryEndDate: Date;
  classificationUpdated: boolean;
  classificationDeleted: boolean;
}

export class TaxClassField {
  label: string;
  mandatory: boolean;
  name: string;
  objectId: number;
  readOnly: boolean;
  type: string;
  value: string;
}

export class AnagTaxClassData {
  tacClassificationList: AnagTaxClassification[];
  taxClassificationData: AnagtaxClassificationData;
  modalLabel: string;
  editMode: boolean;
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcErrorMessagesComponent } from './lpc-error-messages/lpc-error-messages.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';


@NgModule({
  declarations: [LpcErrorMessagesComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
  ],
  exports: [LpcErrorMessagesComponent]
})
export class LpcErrorMessagesModule { }

import {Component, Input} from '@angular/core';
import {ContractDetails} from '../../../../models/details/contract-details';

@Component({
  selector: 'lib-consultation-policy-details-admin-data',
  templateUrl: './cc-policy-details-admin-data.component.html',
  styleUrls: ['./cc-policy-details-admin-data.component.css']
})
export class CcPolicyDetailsAdminDataComponent {
  dateFormat:string ='dd/MM/yyyy';
  @Input() contract:ContractDetails;
}

import { Serialized18n } from '@rgi/rx/i18n';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_LIFE_PPEVO_DE: Serialized18n = {
 /* "Il Contraente è una persona": 'Der Versicherungsnehmer ist',
 "Data di nascita": 'Geburtsdatum',
 'La sua consistenza patrimoniale complessiva è:': 'Ihr Gesamtvermögen beträgt:',
 'Il suo reddito netto annuo è:': 'Ihr jährliches Nettoeinkommen beträgt:',
 'La sua capacità media di risparmio annuo, valutata… degli impegni finanziari già in essere è pari a:': 'Ihre durchschnittliche jährliche Sparfähigkeit, unter Berücksichtigung bestehender finanzieller Verpflichtungen, beträgt:',
 'I suoi impegni finanziari mensili sono:': 'Ihre monatlichen finanziellen Verpflichtungen betragen:',
 "E' intestatario di un mutuo o di un finanziamento?": 'Sind sie Inhaber einer Hypothek oder eines Darlehens?' */
}

import {
  ICoreResult,
  PortalCardConfig,
  PortalCards,
  PortalConfig,
  portalControllerFactory,
  RGI_RX_PORTAL_TPL
} from '@rgi/rx/portal';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {
  ANAG_TABGROUP_PARTY, AnagFlowData,
  AnagHomeFieldConfigurationService,
  AnagRouteData,
  FIELD,
  FIELD_ANAG_TAB_ACCORPAMENTO,
  FIELD_ANAG_TAB_PARTY_MANAGEMENT,
  NEW_PARTY,
  PARTY_CREATED,
  PartyCreatedEvent,
  ROUTES_DETAIL,
  ROUTES_FULL_DETAIL,
  ROUTES_HOME,
  ROUTES_PARTY_EDITOR,
  ROUTES_RESULT,
  ROUTES_RESULT_HOMONYMY,
  SUBJECT_SELECTED,
  AnagModalService
} from '@rgi/anag';
import {RgiRxEventService} from '@rgi/rx';
import {take} from 'rxjs/operators';

export const ANAG_SUBCARDS = [{
  route: 'detail',
  cards: [{
    name: 'passqqFinder',
    route: 'home'
  }, {
    name: 'ptfallFinder',
    route: 'home'
  }, {
    name: 'claimsClaimsList',
    route: 'home'
  }, {
    name: 'securitiesmanagement',
    route: 'result'
  }, {
    name: 'recommenderproducts',
    route: 'home'
  }, {
    name: 'ptfdamageProposal',
    route: 'home'
  }, {
    name: 'bondFinderBond',
    route: 'home'
  }, {
    name: 'bondFinderPolicy',
    route: 'home'
  }, {
    name: 'documentmanagerList',
    route: 'home'
  }, {
    name: 'cardSurvey',
    route: 'home'
  },
  {
    name: 'newMotorContract',
    route: 'home'
  },
  {
    name: 'authorizationsCard',
    route: 'proxy'
  }]
}];


export const ANAG_CARD: PortalCardConfig = {
  card: {
    title: '_ANAG_TITLE_PARTY_MANAGEMENT_',
    name: 'rgiAnag',
    category: 'Cross',
    type: 'main',
    subCards: ANAG_SUBCARDS,
    subCardsActive: [],
    navigationInSession: true,
    customize: {
      enabled: true,
      hideHeader: false,
      hideContainerBody: false,
      menuVoiceAdvancedSearch: true,
      fields: [ANAG_TABGROUP_PARTY]
    }
  },
  routes: [
    {
      route: 'home',
      destination: ROUTES_HOME,
      type: 'home',
      controller: HomeController,
      template: RGI_RX_PORTAL_TPL
    },
    {
      route: 'edit',
      destination: ROUTES_PARTY_EDITOR
    },
    {
      route: 'result',
      destination: ROUTES_RESULT
    },
    {
      route: 'result-homonymy',
      destination: ROUTES_RESULT_HOMONYMY
    },
    {
      route: 'detail',
      destination: ROUTES_DETAIL
    },
    {
      route: 'full-detail',
      destination: ROUTES_FULL_DETAIL
    },
    {
      route: 'customize',
      template: 'coreportal/card/directives/rgiCard-customize.tpl.html',
      label: 'Customize - Anagrafica Finder',
      destination: ''
    }
  ]
};

export function angularGridInstanceFactory(injector) {
  return injector.get('angularGridInstance');
}

export const angularGridInstance = {
  provide: 'angularGridInstance',
  useFactory: angularGridInstanceFactory,
  deps: ['$injector']
};

export const ANAG_CARDS: PortalCards = [ANAG_CARD];

declare var angular: angular.IAngularStatic;

export function manageOverrideAnagCard(config?: PortalConfig){
  const module = angular.module(config.module);
  module.run(['cardManagementService', 'parallelRouting',
    (cardManagementService, pRouter) => {
      let catalogCard: any;
      catalogCard = cardManagementService.getAvailableCardByName('anagFinder');
      if (catalogCard) {
        catalogCard.type = null;
        // TODO eventually manage the subcards drag and drop.
        // pRouter.modify(catalogCard.name, 'home', ANAG_CARD.routes[0]);
      }
    }]);
}

export function manageNewAnagCardInPassPortal(anagModalService: AnagModalService, rxEventService: RgiRxEventService, config?: PortalConfig) {
  const module = angular.module(config.module);

  module.config(['$provide', $provide => {
    $provide.decorator('dropContainerDirective',
      ['$delegate', 'eventService', 'DROP_CONTAINER', 'sessionService', 'coreResult',
        ($delegate, eventService, DROP_CONTAINER, sessionService, coreResult) => {
      const directive = $delegate[0];

      directive.compile = () => function(scope, elem, attr) {
        directive.link.apply(this, arguments);
        const superOpenCard = scope.openCard;
        scope.openCard = () => {
          if (scope.popupCardName === 'anagFinder') {
            scope.popoverIsOpen = false;
            const routeData = new AnagRouteData();
            routeData.flowData = new AnagFlowData();
            routeData.flowData.partyRole = scope.popupCardFilter && scope.popupCardFilter.role ? scope.popupCardFilter.role : '999';
            routeData.flowData.nodeCode = scope.popupCardFilter && scope.popupCardFilter.salePointId ?
              scope.popupCardFilter.salePointId : undefined;
            routeData.redirectToMainFlow = true;
            routeData.isModalFlow = true;
            const anagModal = anagModalService.openComponent('AnagModalComponent', scope.popupCardFilter);
            anagModal.modal.enableClickBackground = false;
            anagModal.modal.onClose.subscribe(onCloseData => {
              if (onCloseData && onCloseData.event) {
                const event = onCloseData.event;
                switch (event.eventName) {
                  case SUBJECT_SELECTED.eventName: {
                    const idNodeSubject = event.routeData.party.node ? event.routeData.party.node.identification : '';
                    const objName = createDraggableObj(event.routeData.party, idNodeSubject);

                    scope.onDropContainerComplete(objName);
                    break;
                  }
                  case NEW_PARTY.eventName: {
                    if (event.routeData.action === 'newParty') {
                      const idNavigation = sessionService.open(ANAG_CARD.card.name, 'edit', 'edit', true);
                      coreResult.setResult(idNavigation, 'edit', event.routeData);
                      rxEventService.listen<PartyCreatedEvent>(PARTY_CREATED).pipe(take(1)).subscribe(eventInstance => {
                        const anagSession = sessionService.list().find(session => session.id === idNavigation);
                        if (anagSession) {
                          sessionService.remove(anagSession.idSession);
                        }
                        if (scope.$parent.card) {
                          sessionService.setActive(scope.$parent.card.idSession);
                        }
                        const idNodeSubject = eventInstance.event.party.node ? eventInstance.event.party.node.identification : '';
                        const objName = createDraggableObj(eventInstance.event.party, idNodeSubject);

                        scope.onDropContainerComplete(objName);
                      });
                    }
                    break;
                  }
                  default:
                    break;
                }
              }
            });
          } else {
            superOpenCard();
          }
        };
      };

      return $delegate;
    }]);
  }]);
}


function addConfiguredField($scope, anagHomeFieldConfigurationService: AnagHomeFieldConfigurationService, field: FIELD) {
  if ($scope[field.name]) {
    anagHomeFieldConfigurationService.addField($scope.$$id, $scope[field.name]);
  }
}

HomeController.$inject = ['$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxTranslationService', 'AnagHomeFieldConfigurationService'];

// @ts-ignore
export function HomeController($scope, $controller, coreResult: ICoreResult, rgiRxRoutingService: RoutingService, rgiRxTranslateService: RgiRxTranslationService, anagHomeFieldConfigurationService: AnagHomeFieldConfigurationService) {
  portalControllerFactory(ROUTES_HOME, 'home').apply(this, arguments); // apply the portalControllerFactory
  $scope.onRouteChange = (_activeRoute: ActiveRoute) => {

  };
  $scope.$on('$destroy', () => {
    anagHomeFieldConfigurationService.clearField($scope.$$id);
  });

  FIELD_ANAG_TAB_ACCORPAMENTO.fields.forEach(field => {
    addConfiguredField($scope, anagHomeFieldConfigurationService, field);
  });
  addConfiguredField($scope, anagHomeFieldConfigurationService, FIELD_ANAG_TAB_ACCORPAMENTO);
  FIELD_ANAG_TAB_PARTY_MANAGEMENT.fields.forEach(field => {
    addConfiguredField($scope, anagHomeFieldConfigurationService, field);
  });
  addConfiguredField($scope, anagHomeFieldConfigurationService, FIELD_ANAG_TAB_PARTY_MANAGEMENT);

  $scope.myCustomData = {};
}

function createDraggableObj(party, idNodeSubject) {
  const objName = {
    id: party.objectId,
    description: party.nominative,
    draggable: true,
    type: 'subject',
    clazz: 'rgifont rgi-user',
    controller: 'AnagFinderController_result',
    method: 'GET',
    requestUrl: 'anag/subject/' + party.objectId + '?idNode=' + idNodeSubject,
    functions: [{
      child: 'open',
      method: 'openShortDetail("' + party.objectId + '","' + idNodeSubject + '", true)',
      icon: 'rgifont rgi-external-link'
    }],
    label: party.nominative,
    data: party,
  };
  return objName;
}


export class ApiElaborationRequestFilter {
  codeChain: string;
  externalKey: string;
  codeAction: string;
  creationDateStart: string;
  creationDateEnd: string;
  outcome: TypeEnum;
}

interface TypeEnum {
  eTypeCode: string;
  eTypeDescription: string;
}


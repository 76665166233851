import {Inject, Injectable} from '@angular/core';
import {Parameter} from '../../models/domain-models/parameter';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {PaymentFrequency} from '../../models/api-models/api-payment-frequency';
import {BehaviorSubject, Subject} from 'rxjs';
import {PolicyDate} from '../../models/domain-models/parameters/policy-date';
import {HttpClient} from '@angular/common/http';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {MicHttpService} from '../../http/mic-http.service';
import {CoassContainer} from '../../models/domain-models/parameters/coass-container';

@Injectable(
  {providedIn: 'root'}
)
export class ParameterService {

  private baseApiUrlV2;
  private contractEndpoint;
  protected httpClient: HttpClient;
  private paymentFrequencyValues = new Array<PaymentFrequency>();
  private instalmentDates = new BehaviorSubject<Array<PolicyDate>>(new Array<PolicyDate>());
  private recalculateParametersChannel: Subject<any> = new Subject<any>();
  private effectiveDate: Date;
  private policyTechnicalData: PolicyTechnicalData;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected datePipe: DatePipe
  ) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';
    this.contractEndpoint = this.baseApiUrlV2 + '/portfolio/contract';
    this.httpClient = this.micHttpClient.getHttpClient();
  }

  toFormGroup(parameters: Parameter[]) {
    const group: any = {};
    parameters.forEach(parameter => {
      let value;
      if (parameter.type === 0) {
        if (parameter.id === '8') {
          if (parameter.value) {
            const isValidDate = Date.parse(parameter.value);
            if (isNaN(isValidDate)) {
              value = this.datePipe.transform(new Date(Number(parameter.value)), 'dd/MM/yyyy');
            } else {
              value = this.datePipe.transform(parameter.value, 'dd/MM/yyyy');
            }
          } else {
            value = null;
          }
        } else {
          if (parameter.value) {
            value = parameter.value;
          } else {
            value = null;
          }
        }
      } else if (parameter.type === 4) {
        if (parameter.value) {
          const isValidDate = Date.parse(parameter.value);
          if (isNaN(isValidDate)) {
            value = new Date(Number(parameter.value));
          } else {
            value = new Date(parameter.value);
          }
        } else {
          value = null;
        }
      } else {
        value = parameter.value ? parameter.value.replace(',', '.') : null;
      }
      group[parameter.id] = new UntypedFormControl(value, parameter.compulsory ? Validators.required : null);
    });
    return new UntypedFormGroup(group);
  }

  addCoassToFormGroup(formGroup: UntypedFormGroup, coassContainer: CoassContainer) {

    if (coassContainer) {

        if (!formGroup.controls.coassType) {
            formGroup.addControl('coassType', new UntypedFormControl(coassContainer.coassType, Validators.required));
        }

        if (!formGroup.controls.ourCoassPercentage) {
            const ourPercentage = coassContainer.config.ourCoassPercentageRequired;
            formGroup.addControl('ourCoassPercentage', new UntypedFormControl(coassContainer.ourCoassPercentage,
                ourPercentage ? Validators.required : Validators.nullValidator));
            if (!ourPercentage) {
                formGroup.controls.ourCoassPercentage.reset();
                formGroup.controls.ourCoassPercentage.disable();
            } else {
                formGroup.controls.ourCoassPercentage.enable();
            }
        }
    }
  }


  public getInstalmentDates() {
    return this.instalmentDates.asObservable();
  }

  public setInstalmentDates(value) {
    const paymentFrequency = this.paymentFrequencyValues.find(
      (paymentFreq) => paymentFreq.id === Number(value)
    );
    if (paymentFrequency) {
      this.instalmentDates.next(paymentFrequency.instalmentDates);
    } else {
      this.instalmentDates.next([]);
    }
  }

  public setPaymentFrequencyValues(value) {
    this.paymentFrequencyValues = value;
  }

  public getPaymentFrequencyValues() {
    return this.paymentFrequencyValues;
  }

  public retrievePolicyTechnicalData(contractId: string) {
    return this.httpClient.get<PolicyTechnicalData>(this.contractEndpoint + '/' + contractId + '/policytechnicaldata');
  }

  getPolicyTechnicalData() {
    return this.policyTechnicalData;
  }

  setPolicyTechnicalData(ptd) {
    this.policyTechnicalData = ptd;
  }

  public updatePolicyTechnicalData(contractId: string, policyTechnicalData: PolicyTechnicalData) {
    return this.httpClient.put<PolicyTechnicalData>(this.contractEndpoint + '/' + contractId + '/policytechnicaldata',
      policyTechnicalData);
  }

  getRecalculateParametersSignal() {
    return this.recalculateParametersChannel.asObservable();
  }

  sendRecalculateParametersSignal() {
    this.recalculateParametersChannel.next();
  }

  getEffectiveDate() {
    return this.effectiveDate;
  }

  setEffectiveDate(date) {
    this.effectiveDate = date;
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ModalService} from '@rgi/rx/ui';
import {
  GroupPolicyPaymentsModalComponent,
  PaymentModalData
} from '../group-policy-payments-modal/group-policy-payments-modal.component';
import {
  MeanOfPayment,
  PaymentConfig,
  PaymentsPayload
} from '../../../group-policy-models/group-policy-issue-policy-data';
import {cloneDeep} from 'lodash';
import {mapPayment} from '../../../adapters/group-policy-utils';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'rgi-gp-payments-section',
  templateUrl: './group-policy-payments-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPolicyPaymentsSectionComponent implements OnInit, OnChanges {

  @Input() paymentSectionValidCheck: Observable<void> = new Observable<void>();

  @Input() paymentConfig: PaymentConfig;

  @Input() selectablePayments: MeanOfPayment[];

  @Input() isSectionDisabled = false;

  @Output() setPaymentMethod: EventEmitter<PaymentsPayload> = new EventEmitter();

  @Output() isSectionValid: EventEmitter<boolean> = new EventEmitter();

  paymentsForm: UntypedFormGroup;

  constructor(
    protected modalService: ModalService
  ) {
    this.paymentsForm = new UntypedFormGroup({
      debitRadio: new UntypedFormControl(undefined, [Validators.required]),
      creditRadio: new UntypedFormControl(undefined, [Validators.required])
    });
  }

  ngOnInit() {
    this.paymentSectionValidCheck.subscribe(() => {
      this.isSectionValid.emit(this.paymentsForm.valid);
    });
    this.paymentsForm.get('debitRadio').valueChanges.subscribe(index => {
      this.selectPayment(this.getDebitMeans()[index]);
    });
    this.paymentsForm.get('creditRadio').valueChanges.subscribe(index => {
      this.selectPayment(this.getCreditMeans()[index]);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectablePayments) {
      this.paymentsForm.get('debitRadio').setValue(this.getSelected('1'), {emitEvent: false});
      this.paymentsForm.get('creditRadio').setValue(this.getSelected('2'), {emitEvent: false});
      this.isSectionValid.emit(this.paymentsForm.valid);
    }
    if (changes.isSectionDisabled && changes.isSectionDisabled.currentValue) {
      this.paymentsForm.disable({emitEvent: false});
    }
  }

  addPaymentMethod() {
    const modalData = new PaymentModalData(this.paymentConfig);
    const debitMethods = this.getDebitMeans();
    if (debitMethods) {
      modalData.isDebitBtnDisabled = !!debitMethods.find(method => method.editable);
    }
    const creditMethods = this.getCreditMeans();
    if (creditMethods) {
      modalData.isCreditBtnDisabled = !!creditMethods.find(method => method.editable);
    }
    this.openPaymentModal(modalData);
  }

  editPaymentMethod(paymentMethod: MeanOfPayment) {
    const modalData = new PaymentModalData(this.paymentConfig, paymentMethod);
    if (paymentMethod.paymentConfig.paymentType === '1') {
      modalData.isCreditBtnDisabled = true;
    } else {
      modalData.isDebitBtnDisabled = true;
    }
    this.openPaymentModal(modalData);
  }

  openPaymentModal(modalData: PaymentModalData) {
    const paymentModal = this.modalService.openComponent(GroupPolicyPaymentsModalComponent, cloneDeep(modalData));
    paymentModal.modal.enableClickBackground = false;
    paymentModal.modal.onClose.subscribe(onCloseData => {
      if (onCloseData) {
        this.setPaymentMethod.emit(onCloseData);
      }
    });
  }

  getDebitMeans() {
    if (this.selectablePayments) {
      return this.selectablePayments.filter(payment => payment.paymentConfig.paymentType === '1');
    }
    return null;
  }

  getCreditMeans() {
    if (this.selectablePayments) {
      return this.selectablePayments.filter(payment => payment.paymentConfig.paymentType === '2');
    }
    return null;
  }

  selectPayment(mean: MeanOfPayment) {
    this.setPaymentMethod.emit(mapPayment(mean.paymentConfig.meanOfPayment.code,
      mean.paymentConfig.paymentType, 2, mean.paymentConfig.PaymentsFields));
  }

  getSelected(paymentType: string) {
    if (this.selectablePayments) {
      switch (paymentType) {
        case '1':
          const debitIndex = this.getDebitMeans().indexOf(this.getDebitMeans().find(payment => payment.selected));
          return debitIndex > -1 ? '' + debitIndex : undefined;
        case '2':
          const creditIndex = this.getCreditMeans().indexOf(this.getCreditMeans().find(payment => payment.selected));
          return creditIndex > -1 ? '' + creditIndex : undefined;
      }
    }
    return undefined;
  }

  getLabel(payment: MeanOfPayment) {
    const paymentFields = payment.paymentConfig.PaymentsFields;
    const paymentLabel = payment.paymentConfig.meanOfPayment.description;
    const holderField = paymentFields ? paymentFields.find(field => field.name === 'cholder') : undefined;
    switch (payment.paymentConfig.meanOfPayment.code) {
      case 'BNKTRN':
      case 'DDO':
        if (paymentFields) {
          const ciban = paymentFields.find(field => field.name === 'ciban');
          const holder = holderField && holderField.value ? '- ' + holderField.value + ' - ' : '';
          if (ciban) {
            const ibanLabel = ciban.label;
            const ibanValue = ciban.value;
            return `${paymentLabel} ${holder} ${ibanLabel} ${ibanValue}`;
          }
        }
        return paymentLabel;
      case 'CARD':
        const cardTypeField = paymentFields.find(field => field.name === 'idcreditcard');
        let cardDescription = '';
        if (cardTypeField) {
          const cardTypeObj = cardTypeField.values.find(value => '' + value.id === cardTypeField.value);
          cardDescription = cardTypeObj ? cardTypeObj.description : '';
        }
        const cardHolder = holderField && holderField.value ? '- ' + holderField.value + ' ' : '';
        const cardExpire = paymentFields.find(field => field.name === 'dcardexpire').value;
        const cardExpireDate = cardExpire ? new Date(cardExpire) : undefined;
        const cardMonth = cardExpireDate ? cardExpireDate.getMonth() + 1 : undefined;
        const cardYear = cardExpireDate ? cardExpireDate.getFullYear() : undefined;
        return `${cardDescription} ${cardHolder} ${cardExpireDate ? '- ' + cardMonth + '/' + cardYear : ''}`;
      default:
        return paymentLabel;
    }
  }

  canAddPayment() {
    if (this.selectablePayments) {
      const editables = this.selectablePayments.filter(payment => payment.editable);
      return !editables || editables.length < 2;
    }
    return true;
  }
}

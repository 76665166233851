import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { filter, mergeMap, tap } from "rxjs/operators";
import { RgiRxTabBodyComponent } from "../rgi-rx-tab-body/rgi-rx-tab-body.component";
import { RgiRxTabDirective } from "../rgi-rx-tab.directive";
import { BehaviorSubject, of, Subscription } from "rxjs";
import { FocusOrigin } from "@angular/cdk/a11y";
import { RgiRxTabHeaderComponent } from "../rgi-rx-tab-header/rgi-rx-tab-header.component";
import { ENTER, LEFT_ARROW, RIGHT_ARROW } from "@angular/cdk/keycodes";
import { RgiRxTabGroupTargetComponent } from "../rgi-rx-tab-group-target-component";
import { RgiRxSemanticStatus } from "@rgi/rx";

export interface RgiRxTabSelectionChange {
  event: Event;
  tab: RgiRxTabDirective;
  index: number;
}

let tabGroupCounter = 0;
@Component({
  selector: "rgi-rx-tab-group",
  templateUrl: "./rgi-rx-tab-group.component.html",
  host: {
    class: "rgi-ui-tab-container",
  },
})
export class RgiRxTabGroupComponent implements OnDestroy, AfterViewInit {
  @Input() target?: RgiRxTabGroupTargetComponent;

  @ViewChild("tabBody", { static: true }) tabBody: TemplateRef<any>;

  @ContentChildren(RgiRxTabDirective, { descendants: false })
  _allTabs: QueryList<RgiRxTabDirective>;
  @ContentChildren(RgiRxTabBodyComponent, { descendants: false })
  _allBodies: QueryList<RgiRxTabBodyComponent>;
  @ViewChild(RgiRxTabHeaderComponent, { static: true })
  header: RgiRxTabHeaderComponent;
  @Output("onTabSelectionChange") onTabSelectionChange =
    new EventEmitter<RgiRxTabSelectionChange>();

  private selectIndexChange = new BehaviorSubject<{
    index: number;
    event?: Event;
  }>(null);
  private readonly _groupId: number;

  private _selectedIndexChangeSubscription: Subscription = Subscription.EMPTY;
  private _keyManagerChangeSubscription: Subscription = Subscription.EMPTY;

  @Input() ariaLabel? = "tablist";

  @Input("selectedIndex") get selectedIndex(): number {
    return this.selectIndexChange.value
      ? this.selectIndexChange.getValue().index
      : undefined;
  }

  set selectedIndex(value: number) {
    if (typeof value === "string") {
      value = parseInt(value);
    }
    this.selectIndexChange.next({
      index: value,
    });
  }

  constructor() {
    this._groupId = tabGroupCounter++;
  }

  ngAfterViewInit(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.viewContainerRef.createEmbeddedView(
          this.tabBody,
          { $implicit: this._allTabs },
          0
        );
      });
    }
    this._selectedIndexChangeSubscription = this.selectIndexChange
      .pipe(
        filter((e) => e !== null),
        mergeMap((evt) => {
          return of({
            index: evt.index,
            event: evt.event,
            tab: this.getTabByIndex(evt.index),
          });
        }),
        tap((evt) => {
          this._allBodies
            .filter((item) => item.tab !== evt.tab)
            .forEach((item) => {
              item.active = false;
            });
          this.header.keyManager.setActiveItem(evt.index);
        })
      )
      .subscribe();

    this._keyManagerChangeSubscription = this.header.keyManager.change
      .pipe(
        tap((index) => {
          this.selectIndexChange.next({
            index,
          });
        })
      )
      .subscribe();

  }
  ngOnDestroy(): void {
    this._selectedIndexChangeSubscription.unsubscribe();
    this._keyManagerChangeSubscription.unsubscribe();
  }

  tabClick(index: number, event: Event) {
    const selected = this.getTabByIndex(index);
    if (selected && selected.disabled) {
      return;
    }
    this.header.keyManager.setActiveItem(index);
    this.emitTabSelection(index, event);
  }

  private emitTabSelection(index: number, event: Event) {
    this.onTabSelectionChange.emit({
      tab: this.getTabByIndex(index),
      event,
      index,
    });
  }

  handleKeyDown(event: KeyboardEvent, index: number) {
    switch (event.keyCode) {
      case ENTER: {
        this.header.keyManager.setActiveItem(this.header.focused);
        this.emitTabSelection(index, event);
        break;
      }
      case LEFT_ARROW: {
        this.header.keyManager.onKeydown(event);
        this.emitTabSelection(index, event);
        break;
      }
      case RIGHT_ARROW: {
        this.header.keyManager.onKeydown(event);
        this.emitTabSelection(index, event);
        break;
      }
    }
  }

  getTabByIndex(index: number): RgiRxTabDirective | undefined {
    return this._allTabs.find((item, i) => i === index);
  }

  getLabelType(tab: RgiRxTabDirective): "label" | "template" {
    return tab.labelTemplate ? "template" : "label";
  }

  getTabIndex(tab: RgiRxTabDirective, i: number): number {
    return tab.disabled ? -1 : 0;
  }

  getGroupId() {
    return `rgi-rx-tab-group-${this._groupId}`;
  }

  getTabId(index: number) {
    return `rgi-rx-tab-${this._groupId}-${index}`;
  }

  getTabBodyId(index: number): string {
    return `rgi-rx-tab-content-${this._groupId}-${index}`;
  }

  onTabFocusChange(i: number, $event: FocusOrigin) {
    this.header.focused = i;
  }
}

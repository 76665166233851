import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardUtilityService} from '../services/dashboard-utility.service';
import {Validators} from '@angular/forms';
import {DashboardCardState, DashboardManagerService} from '../state-manager/dashboard-manager.service';
import {DashboardOperation} from '../model/dashboard-operation';

@Component({
    selector: 'dashboard-detail-param',
    templateUrl: './dashboard-detail-param.component.html',
    styleUrls: ['./dashboard-detail-param.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardDetailParamComponent implements OnInit, OnDestroy {

    constructor(public utility: DashboardUtilityService,
                public stateManager: DashboardManagerService<DashboardCardState>) {
    }

    private subs: Subscription = new Subscription();
    public paramForm: any;
    @Input() overview: any;
    @Input() commissionStatus: string;
    @Input() text: string;
    @Output() clear: any = new EventEmitter();
    @Output() apply: any = new EventEmitter();
    @Output() operationSelected: EventEmitter<any> = new EventEmitter();
    @Output() noteSelected: EventEmitter<any> = new EventEmitter();
    @Output() actionSelected: EventEmitter<any> = new EventEmitter();
    @Output() blockExpansion: EventEmitter<any> = new EventEmitter();
    operationTypes: any;
    actions: any;
    notes: any;
    @Input() operation: DashboardOperation;


    ngOnInit() {
        const pCodes = this.createParamForm();
        if (this.commissionStatus !== '2') {
            this.paramForm.controls.operationType.disable();
        }
        this.subscribeParams(pCodes);
        this.blockExpansion.emit();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    subscribeParams(paramCodes: string[]) {
        // Register risk param detail subscriptions
        // TODO : Separate subscribes and remove switch
        paramCodes.forEach(code => {
            const sub = this.paramForm.controls[code].valueChanges.subscribe(value => {
                switch (code) {
                    case 'operationType':
                        this.operationSelected.emit(this.paramForm.controls[code].value);
                        this.paramForm.controls.actionType.setValue(undefined);
                        this.paramForm.controls.noteType.setValue(undefined);

                        break;
                    case 'actionType':
                        this.actionSelected.emit(this.paramForm.controls[code].value);
                        // statement
                        break;
                    case 'noteType':
                        this.noteSelected.emit(this.paramForm.controls[code].value);
                        // statement
                        break;
                }
            });
            this.subs.add(sub);
        });
    }

    createParamForm(): string[] {
        const paramCodes = [];
        const subStatusCode = 'operationType';
        const actionCode = 'actionType';
        const noteCode = 'noteType';
        paramCodes.push(subStatusCode, actionCode, noteCode);
        // TODO : disable substatus select
        this.paramForm = this.utility.toFormGroup(paramCodes, null, null);
        // actionType mandatory
        if (this.commissionStatus === '2') {
            this.paramForm.controls.actionType.setValidators(Validators.required);
        }
        return paramCodes;
    }

    cancel() {
        this.paramForm.controls.actionType.setValidators(null);
        this.paramForm.controls.actionType.setErrors(null);
        this.clear.emit();
    }

    applyValues() {
        this.apply.emit();
    }

}

import {Component, Input} from '@angular/core';
import {InstalmentContainer, InstalmentDetail, PremiumItem} from '../../../models/details/instalment';
import {Currency} from '../../../models/details/contract-details';
import {ModalService} from '@rgi/rx/ui';
import {
  ConsultationPremiumDetailModalComponent, ConsultationPremiumDetailModalData
} from './consultation-premium-detail-modal/consultation-premium-detail-modal.component';

@Component({
  selector: 'consultation-accordion-premium',
  templateUrl: './consultation-accordion-premium.component.html'
})
export class ConsultationAccordionPremiumComponent {

  @Input() nextInstalment: InstalmentContainer;
  @Input() signInstalment: InstalmentContainer;
  @Input() annualPremium: InstalmentDetail;
  @Input() currency: Currency;

  constructor(private customModalService: ModalService) {
  }

  openAnnualPremiumModal(){
    let amountsByCover: Array<PremiumItem> = [];
    for (const amount of (this.annualPremium?.amountByCover ?? [])){
      let premium: PremiumItem = new PremiumItem();
      premium.description = amount.description;
      premium.net = amount.net;
      premium.accessories = amount.accessories;
      premium.fractioningInterestsNet = amount.fractioningInterestsNet;
      premium.taxable = amount.taxable;
      premium.tax = amount.taxes;
      premium.gross = amount.gross;
      premium.fractioningInterestsNet = amount.fractioningInterestsNet;
      amountsByCover.push(premium);
    }
    this.customModalService.openComponent(ConsultationPremiumDetailModalComponent,
      new ConsultationPremiumDetailModalData(
        amountsByCover,
        this.currency,
        this.annualPremium?.premium?.gross,
        'CONTRACT_CONSULTATION.ANNUAL_PREMIUM'
      ));
  }

  openSignInstalmentModal(){
    this.customModalService.openComponent(ConsultationPremiumDetailModalComponent,
      new ConsultationPremiumDetailModalData(
        this.signInstalment?.amountsByGuarantee?.due,
        this.currency,
        this.signInstalment?.dueTotal?.gross,
        'CONTRACT_CONSULTATION.SIGN_INSTALMENT'
      ));
  }

  openNextInstalmentModal(){
    this.customModalService.openComponent(ConsultationPremiumDetailModalComponent,
      new ConsultationPremiumDetailModalData(
        this.nextInstalment?.amountsByGuarantee?.due,
        this.currency,
        this.nextInstalment?.dueTotal?.gross,
        'CONTRACT_CONSULTATION.NEXT_INSTALMENT'
      ));
  }

  openComissionModal(){
    console.log("TODO open modal");
  }

  openDiscountModal(){
    console.log("TODO open modal");
  }

}

import {toPath} from 'lodash';
import {Observable, of, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {EventEmitter} from '@angular/core';

export type SCALAR_TYPE = string | boolean | number | null;

/**
 * A function to walk an object by a give path via Reflection
 * @param path the node path
 * @param node the node to traverse
 * @example walk("root.leaf", {root: {leaf: "OK"}}) // returns OK
 */
export function walk<T>(path: string, node: T): SCALAR_TYPE | T | undefined {
  return traverseNodePath(toPath(path), node);
}

export function traverseNodePath<T>(nodes: string[], node: T): SCALAR_TYPE | T | undefined {
  return nodes.reduce((xs, x) => xs instanceof Map ? xs.get(x) : (xs as any)?.[x], node);
}

export function isScalarType(value: unknown): boolean {
  return value === null || value === undefined || !(typeof value === 'object' || typeof value === 'function' || typeof value === 'symbol');
}



export type UnionOfGenericObservable<T> = T | Observable<T>;


/**
 * @description map a union type between T and Observable<T>. When T is not observable, it will be converted to and observable
 * of that type
 * @param value the union type to project
 * @see of
 */
export function projectUnionToObservable<T>(value: UnionOfGenericObservable<T>): Observable<T> {
  if (isUnionOfGenericObservable(value)) {
    return value;
  }
  return of(value);
}

/**
 * @description type guard UnionOfGenericObservable to check if it's an Observable.
 * @param union the UnionOfGenericObservable to check
 */
export function isUnionOfGenericObservable<T>(union: UnionOfGenericObservable<T>): union is Observable<T> {
  const unionCast = union as Observable<T>;
  return unionCast.pipe !== undefined && unionCast.subscribe !== undefined;
}



export function forwardObservableToEmitter<T>(source: Observable<T>, target: EventEmitter<T>) {
  return source.pipe(
    tap((value) => target.emit(value))
  );
}



import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Injectable(
  {providedIn: 'root'}
)

export class FormService {
  constructor() {
  }

  setFieldsDirty(formGruoup: UntypedFormGroup) {
    Object.keys(formGruoup.controls).forEach(field => {
      formGruoup.get(field).markAsDirty();
    });
  }
}

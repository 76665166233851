import {GPAsset} from './group-policy-issue-guarantees';
import {BaseEntity} from './group-policy-issue-policy-data';
import {ActionTypeIssueComplete} from './group-policy-issue-confirm';

export interface Summary {
  summaryAdministrativeData: SummaryAdministrativeData;
  summaryParties: SummaryParties[];
  summaryAssets: GPAsset[];
  applicationsToAuthorize: number;
}

export interface SummaryAdministrativeData {
  managementNode: string;
  status: BaseEntity;
  productDesc: string;
  effDate: string;
  expiryDate: string;
  paymentFrequency: string;
  agreement: string;
  proposalNumber: string;
}

export interface SummaryParties {
  dateOfBirth: string;
  name: string;
  surname: string;
  companyName: string;
  personType: string;
  idPartyLock: number;
  idParty: number;
  role: string;
  description: string;
  maxInstances: number;
  showPercentage: boolean;
  minPercentage: number;
  percentage: number;
  linkedRoles: [
    null
  ];
}

export interface Documents extends BaseEntity {
  idDocument: number;
  input: boolean;
  enabled: boolean;
  selected: boolean;
  mandatory: boolean;
}

export enum MapKey {
  managementNode = '_GP_._SUMMARY_._MANAGEMENT_NODE_',
  status = '_GP_._SUMMARY_._STATUS_',
  productDesc = '_GP_._SUMMARY_._PRODUCT_DESCRIPTION_',
  effDate = '_GP_._SUMMARY_._EFFECTIVE_DATE_',
  expiryDate = '_GP_._SUMMARY_._EXPIRY_DATE_',
  paymentFrequency = '_GP_._SUMMARY_._PAYMENT_FREQUENCY_',
  agreement = '_GP_._SUMMARY_._AGREEMENT_',
  dateOfBirth = '_GP_._SUMMARY_._DATE_OF_BIRTHDAY_',
  name = '_GP_._SUMMARY_._NAME_',
  surname = '_GP_._SUMMARY_._SURNAME_',
  companyName = '_GP_._SUMMARY_._COMPANY_NAME_',
  personType = '_GP_._SUMMARY_._PERSON_TYPE_',
  idPartyLock = '_GP_._SUMMARY_._ID_PARTY_LOCK_',
  idParty = '_GP_._SUMMARY_._ID_PARTY_',
  role = '_GP_._SUMMARY_._ROLE_',
  description = '_GP_._SUMMARY_._DESCRIPTION_',
  maxInstances = '_GP_._SUMMARY_._MAXIMUM_INSTANCES_',
  showPercentage = '_GP_._SUMMARY_._SHOW_PERCENTAGE_',
  minPercentage = '_GP_._SUMMARY_._MINIMUM_PERCENTAGE_',
  percentage = '_GP_._SUMMARY_._PERCENTAGE_',
  linkedRoles = '_GP_._SUMMARY_._LINKED_ROLES_',
}

export type ActionType =
  'CONFPM'
  | '_EMPRO'
  | 'PRINTDEF'
  | 'MODPM'
  | '_PEMPO'
  | 'PMPROP'
  | '_EMPM'
  | 'SAVEINTERMEDIATE'
  | 'AUTHORIZATION | ALIGN_APPLICATIONS'
  | 'GPS0030';

export const ACTION_ENUM = {
  CONFPM: 'CONFPM' as ActionType,
  _EMPRO: '_EMPRO' as ActionType,
  PRINTDEF: 'PRINTDEF' as ActionType,
  MODPM: 'MODPM' as ActionType,
  _PEMPO: '_PEMPO' as ActionType,
  PMPROP: 'PMPROP' as ActionType,
  _EMPM: '_EMPM' as ActionType,
  SAVEINTERMEDIATE: 'SAVEINTERMEDIATE' as ActionType,
  AUTHORIZATION: 'AUTHORIZATION' as ActionType,
  ALIGN_APPLICATIONS: 'ALIGN_APPLICATIONS' as ActionType,
  RECOVER_APPLICATIONS: 'GPS0030' as ActionType
};

export interface ButtonsAction {
  code: ActionType | ActionTypeIssueComplete;
  description: string;
}

<rgi-rx-expansion-panel data-qa="movements">
  <rgi-rx-expansion-panel-header class="rgi-cc-accordion-title-header">
    <span rgi-rx-expansion-panel-label translate="CONTRACT_CONSULTATION.MOVEMENTS"></span>
  </rgi-rx-expansion-panel-header>
  <rgi-rx-datatable [data]="movements" [schema]="tableSchema" rgi-rx-qa="movements-table" qa-ctx="movements-table-context"
                    [pageOptions]="pageOptions" [expansionModel]="customExpansionModel"
                    [expansionRow]="rowDetail">
    <ng-template rgiRxDataTableCell="effect" let-ctx>
      {{ctx.row.effect | date: DATEFORMAT }} {{ctx.row.effectHour}}
    </ng-template>
    <ng-template rgiRxDataTableCell="reasonType" let-ctx>
      {{ctx.row.reason?.reasonType?.description}}
    </ng-template>
    <ng-template rgiRxDataTableCell="user" let-ctx>
      {{ctx.row.user?.description}}
    </ng-template>
<!--    <ng-template rgiRxDataTableCell="actions"  let-ctx>-->
<!--      <button (click)="goToDetails(ctx.row)" color="info" class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-right-arrow" data-qa="detail">-->
<!--      </button>-->
<!--    </ng-template>-->
  </rgi-rx-datatable>
</rgi-rx-expansion-panel>

<ng-template #rowDetail let-row>
  <div class="rgi-ui-grid">
    <div class="rgi-ui-col">
      <div class="rgi-cc-row">
        <span class="rgi-ui-text-5">{{'CONTRACT_CONSULTATION.NOTES' | translate}}: </span>
        <span class="rgi-ui-title-3">{{row.note ? row.note : '-'}}</span>
      </div>
    </div>
    <div class="rgi-ui-col">
      <div class="rgi-cc-row">
        <span class="rgi-ui-text-5">{{'CONTRACT_CONSULTATION.MIGRATED' | translate}}: </span>
        <span class="rgi-ui-title-3">{{row.migrated ? 'YES' : '-'}}</span>
      </div>
    </div>
  </div>
</ng-template>

import {Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {RoutableComponent} from '../routable-component';
import {EventNotificator} from '../event-notificator';
import {DetailClaimsNumber} from '../models/domain-models/detail-claims-number';
import {Clause} from '../models/domain-models/clause';
import {StateService} from '../state.service';
import {RoutingService} from '../routing.service';
import {ProposalService} from '../proposal.service';
import {InsuranceBackgroundService} from '../insurance-background/insurance-background.service';
import {ClauseService} from '../clause/clause-service';
import {Observable, Subscription} from 'rxjs';
import {Message} from '../models/message';
import {VariablesService} from '../variables/variables-service';
import {Variable} from '../models/domain-models/variable';
import {TranslateService} from '@ngx-translate/core';
import {ErrorMessagesService} from '../error-messages.service';
import {AssetService} from './asset.service';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {PartiesService} from '../proposal/parties.service';
import {AmendmentService} from '../amendment/amendment.service';
import {SystemProperty} from '../models/api-models/api-system-properties';
import {CommonService} from '../common.service';
import {Warning} from '../models/domain-models/warning';

@Component({
  selector: 'mic-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  validationMessages: Message[] = new Array<Message>();
  blockingMessages: Message[] = new Array<Message>();
  formMessages: Message[] = new Array<Message>();
  partyMessages: Message[] = new Array<Message>();
  nonBlockingMessages: Message[] = new Array<Message>();
  assetDataCompleted = false;
  showInsuranceBackground = false;
  showATR = false;
  submitted = false;
  showATRHeader = false;
  showATRTable = false;
  showEditAtr = true;
  variablesCompletenessErrors = false;
  assetAreaVariablesCompletenessErrors = false;
  partyAreaVariablesCompletenessErrors = false;
  insuranceStatusCompletenessErrors = false;
  riskCertificateCompletenessErrors = false;
  showFloatingMessages = false;
  enableWarningIcon = true;
  additionalDeclarationIsMandatory = false;
  clausesCalled = false;
  entitledParty: any;
  partiesConfirmed: boolean = true;
  hasSystemIntegration: boolean = false;
  hasSubscriber: boolean = false;

  @ViewChild('errorsElement')
  errorsElement: ElementRef<HTMLDivElement>;
  clausesList: Array<Clause>;

  ASSET_KEY = 'ASSET';
  PARTY_KEY = 'PARTY';
  EXTRA_KEY = 'EXTRA';

  variables: Array<Variable>;

  areaCode = 'ASSET';

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected translate: TranslateService,
    protected commonService: CommonService,
    protected proposalService: ProposalService,
    protected stateService: StateService,
    protected insuranceBackgroundService: InsuranceBackgroundService,
    protected clauseService: ClauseService,
    protected routingService: RoutingService,
    protected variablesService: VariablesService,
    protected assetService: AssetService,
    protected errorMessagesService: ErrorMessagesService,
    protected partiesService: PartiesService,
    protected amendmentService: AmendmentService) {
  }

  get isSubstitution() {
    return this.proposalService.isSubstitution;
  }

  get isAmendment() {
    return this.amendmentService.isAmendment;
  }

  ngOnInit(): void {
    this.eventPropagation.emit({
      eventName: 'setTitle',
      title: this.translate.instant('P&C New Proposal - Asset Data')
    });

    const disabledFields = this.proposalService.getContractDisabledFields();
    if (disabledFields instanceof Observable) {
      disabledFields.subscribe(data => {
        this.proposalService.setDisabledFields(data);
      });
    }

    if (this.proposalService.existAssetDataCompletenessErrors()) {
      this.assetDataCompleted = false;
    } else {
      this.assetDataCompleted = true;
    }

    this.initVariables();

    const vehicleStaticData = this.proposalService.getApiContract().vehicle.vehicleStaticData;
    if (this.assetService.isVehicleStaticDataComplete(vehicleStaticData)) {
      if (this.proposalService.isFiscalCodeNotConsistent()) {
        let message: Message;
        this.proposalService.getApiContract().correctnessErrors.forEach(elem => {
          if (elem.code === 'DIFFERENT_FISCAL_CODES') {
            message = new Message(this.areaCode,
              'CF owner not congruent with CF of the last entitled party ATRC');

          } else if (elem.code === 'DIFFERENT_FISCAL_CODES_DATE_BIRTH_INPUT') {
            message = new Message(this.areaCode,
              'The Fiscal Code of the last entitled party ATR is not consistent with the specified Date of Birth');
          }
        });
        this.nonBlockingMessages.push(message);
      }

      if (this.assetDataCompleted && !this.proposalService.existTechnicalDataCompletenessErrors()
        && !this.proposalService.existVehiclevariablesCompletenessErrors()
        && !this.proposalService.existPartyAreaVariablesCompletenessErrors()
        && !this.proposalService.existAssetDataCompletenessErrors()
        && !this.proposalService.existVehicleDataAreaVariablesCompletenessErrors()) {
        this.showInsuranceBackground = true;
        this.onVariablesCompleted(true);
      } else {
        this.showInsuranceBackground = false;
      }

      if (this.proposalService.existTechnicalDataCompletenessErrors()
        || this.proposalService.existPartyAreaVariablesCompletenessErrors()) {
        this.partyAreaVariablesCompletenessErrors = true;
      } else {
        this.partyAreaVariablesCompletenessErrors = false;
      }

      if (this.proposalService.existVehiclevariablesCompletenessErrors()
        || this.proposalService.existVehicleDataAreaVariablesCompletenessErrors()
        || this.proposalService.existAssetAreaVariablesCompletenessErrors()) {
        this.variablesCompletenessErrors = true;
      } else {
        this.variablesCompletenessErrors = false;
      }

      if (!this.isSubstitution && this.proposalService.existInsuranceStatusCompletenessErrors()) {
        this.insuranceStatusCompletenessErrors = true;
      }

      if ((this.isSubstitution && this.assetDataCompleted
        && this.showInsuranceBackground) || (this.assetDataCompleted
          && this.showInsuranceBackground
          && !this.proposalService.existInsuranceStatusCompletenessErrors())) {

        this.showATR = true;

        if (!this.proposalService.isInsuranceStatusNoLongerValid()) {
          this.onInsuranceBackgroundCompleted(true);
          this.readPreQuotationClauses();
        }
      } else {
        this.showATR = false;
      }

      this.readWarning();
      this.claimValidation();

      this.stateService.sendResetStartFormEvent();
    }
    if (this.proposalService.getApiContract().completenessErrors) {
      this.proposalService.getApiContract().completenessErrors.forEach(elem => {
        if (elem.code === 'COVERAGE_OVERLAP') {
          this.nonBlockingMessages.push(new Message(this.areaCode, elem.description));
        }
      });
    }

    // For Projects
    this.errorMessagesService.addOtherBlockingMessages(this.blockingMessages, null);
    this.errorMessagesService.addOtherNonBlockingMessages(this.nonBlockingMessages, null);

    if(this.isEntitledBeforeQuotation()) {
      this.commonService.getSystemPoperty('MotorCzidSystemIntegration').subscribe(
        (data: SystemProperty) => {
          this.hasSystemIntegration = !!data && data.value === 'Enabled'
          this.partiesConfirmed = !this.hasSystemIntegration;
        }
      );
    }
  }

  readPreQuotationClauses() {
    if (!this.clausesCalled) {
      this.clausesCalled = true;

      this.clauseService.getPreQuotationClauses(this.proposalService.getContractId()).subscribe(
        (data) => {
          this.clausesList = data;

        }
      );
    }
  }

  onHideAll(event) {
    if (event) {
      this.assetDataCompleted = false;
      this.showInsuranceBackground = false;
      this.showATR = false;
    }
  }

  async goToQuote() {
    this.submitted = true;
    await this.claimValidation();

    if (this.validationMessages.length === 0) {
      this.stateService.nextState();
    }
  }

  changeATRHeaderVisibility(event) {
    this.showATRHeader = event;
  }

  changeATRTableVisibility(event) {
    this.showATRTable = event;
    if (event && this.proposalService.isInsuranceStatusNoLongerValid()) {
      if (!this.errorMessagesService.includesMessage(this.nonBlockingMessages, new Message(this.areaCode, 'Certificate of risk expired'))) {
        this.nonBlockingMessages.push(new Message(this.areaCode, 'Certificate of risk expired'));
      }
    } else {
      if (this.errorMessagesService.includesMessage(this.nonBlockingMessages, new Message(this.areaCode, 'Certificate of risk expired'))) {
        const index = this.nonBlockingMessages.findIndex((mess) => {
          return mess.text === 'Certificate of risk expired';
        });
        if (index !== -1) {
          this.nonBlockingMessages.splice(index, 1);
        }
      }
    }

    // For Projects
    this.errorMessagesService.addOtherBlockingMessages(this.blockingMessages, this.errorMessagesService.areas.INSURANCE_BACKGROUND);
    this.errorMessagesService.addOtherNonBlockingMessages(this.nonBlockingMessages, this.errorMessagesService.areas.INSURANCE_BACKGROUND);

    this.updateMessages();
  }

  changeEditAtrVisibility(event) {
    this.showEditAtr = event;
  }

  removeSession() {
    this.stateService.resetState();
    if (this.isAmendment) {
      this.proposalService.refresh();
      this.eventPropagation.emit({
        eventName: 'backToContractDetail',
      });
    } else {
      this.submitted = false;
      this.eventPropagation.emit({
        eventName: 'backToMainPage',
      });
    }
  }

  onAssetDataCompleted(event: boolean) {

    this.assetDataCompleted = event;
    this.showATRHeader = false;
    this.showATRTable = false;

    if (event === true) {

      this.removeCompletenessErrors('ASSET_DATA_NOT_COMPLETE');

      this.blockingMessages.length = 0;

      let areAssetVariablesCompleted = true;
      let arePartyVariablesCompleted = true;

      if (this.proposalService.existTechnicalDataCompletenessErrors()
        || this.proposalService.existPartyAreaVariablesCompletenessErrors()) {
        this.blockingMessages.push(new Message(this.areaCode, 'Party Variables not complete'));

        this.partyAreaVariablesCompletenessErrors = true;
        this.submitted = true;
        arePartyVariablesCompleted = false;
      }

      if (this.proposalService.existVehiclevariablesCompletenessErrors()
        || this.proposalService.existAssetAreaVariablesCompletenessErrors()
        || this.proposalService.existVehicleDataAreaVariablesCompletenessErrors()) {
        this.blockingMessages.push(new Message(this.areaCode, 'Asset Variables not complete'));

        this.variablesCompletenessErrors = true;
        this.assetAreaVariablesCompletenessErrors = true;
        this.submitted = true;
        areAssetVariablesCompleted = false;
      }

      // For Projects
      this.errorMessagesService.addOtherBlockingMessages(this.blockingMessages, this.errorMessagesService.areas.ASSET_DATA);
      this.errorMessagesService.addOtherNonBlockingMessages(this.nonBlockingMessages, this.errorMessagesService.areas.ASSET_DATA);

      this.updateMessages();

      if (arePartyVariablesCompleted && areAssetVariablesCompleted) {
        this.onVariablesCompleted(true);
      }
    }
  }

  onVariablesCompleted(event: boolean) {
    if (!event) {
      return;
    }

    this.blockingMessages.length = 0;

    if (!this.isSubstitution && this.proposalService.existInsuranceStatusCompletenessErrors()) {
      this.blockingMessages.push(new Message(this.areaCode, 'Insurance Background not automatically set'));
      this.insuranceStatusCompletenessErrors = true;
      this.submitted = true;
    }
    if (this.proposalService.isInsuranceStatusNoLongerValid()) {
      if (!this.errorMessagesService.includesMessage(this.nonBlockingMessages, new Message(this.areaCode, 'Certificate of risk expired'))) {
        this.nonBlockingMessages.push(new Message(this.areaCode, 'Certificate of risk expired'));
      }

      this.submitted = true;
    }

    // For Projects
    this.errorMessagesService.addOtherBlockingMessages(this.blockingMessages, this.errorMessagesService.areas.ASSET_VARIABLES);
    this.errorMessagesService.addOtherNonBlockingMessages(this.nonBlockingMessages, this.errorMessagesService.areas.ASSET_VARIABLES);

    this.updateMessages();
  }

  onAssetVariablesCompleted(event: boolean) {
    if (event === true) {

      this.variablesCompletenessErrors = false;
      this.assetAreaVariablesCompletenessErrors = false;

      this.readWarning();

      if (this.partyAreaVariablesCompletenessErrors) {
        return;
      }

      this.showInsuranceBackground = true;

      this.onVariablesCompleted(event);
    }
  }

  removeCompletenessErrors(messCode: string): void {
    let completenessErrors = this.proposalService.getApiContract().completenessErrors;
    if (!completenessErrors) {
      completenessErrors = [];
    }
    const index = completenessErrors.findIndex((mess) => {
      return mess.code === messCode;
    });
    if (index !== -1) {
      completenessErrors.splice(index, 1);
    }
  }

  onPartyVariablesCompleted(event: boolean) {
    if (event === true) {

      this.partyAreaVariablesCompletenessErrors = false;

      this.readWarning();
      // remove erroe completess from contract
      this.removeCompletenessErrors('VARIABLES_AREA_PARTY_NOT_COMPLETE');

      if (this.assetAreaVariablesCompletenessErrors
        || this.variablesCompletenessErrors) {
        return;
      }

      this.showInsuranceBackground = true;

      this.onVariablesCompleted(event);


      if ((this.isSubstitution && this.assetDataCompleted
        && this.showInsuranceBackground) || (this.assetDataCompleted
          && this.showInsuranceBackground
          && !this.proposalService.existInsuranceStatusCompletenessErrors())) {
        this.showATR = true;

        if (!this.proposalService.isInsuranceStatusNoLongerValid()) {
          this.variablesService.getVariables().subscribe(
            array => {
              array.forEach(element => {
                if (element.code === '2DICAG') {
                  if (element.compulsory) {
                    this.additionalDeclarationIsMandatory = true;
                  } else {
                    this.additionalDeclarationIsMandatory = false;
                  }
                  if (!element.value && this.additionalDeclarationIsMandatory) {
                    this.onInsuranceBackgroundCompleted(false);
                  } else {
                    this.onInsuranceBackgroundCompleted(true);
                  }
                }
              });
            }
          );
        }
      }
    }
  }

  onInsuranceBackgroundCompleted(event: boolean) {
    this.showATR = event;

    if (event === true) {
      this.blockingMessages.length = 0;

      this.readWarning();

      // For Projects
      this.errorMessagesService.addOtherBlockingMessages(this.blockingMessages, this.errorMessagesService.areas.INSURANCE_BACKGROUND);
      this.errorMessagesService.addOtherNonBlockingMessages(this.nonBlockingMessages, this.errorMessagesService.areas.INSURANCE_BACKGROUND);

      this.updateMessages();

      this.insuranceStatusCompletenessErrors = false;

      this.readPreQuotationClauses();
    } else {
      this.insuranceStatusCompletenessErrors = true;
    }
  }

  claimValidation(): Promise<void> {
    return new Promise(async resolve => {
      // chiamata al servizio per recuperare i dati
      const insuranceStatusChannelSubscription = this.insuranceBackgroundService.getInsuranceStatusObs().subscribe(data => {
        this.blockingMessages = new Array<Message>();
        if (data && this.showATRTable === true) {
          for (const claimReportYears of data.claimsReportYears) {
            const detailClaimsNumber = new DetailClaimsNumber(claimReportYears.yearReal, '0', '0', '0', '0');
            if (data.claimDetails) {
              for (const claimDetail of data.claimDetails) {
                if (claimReportYears.yearReal === claimDetail.year) {
                  detailClaimsNumber.total = String(Number(detailClaimsNumber.total) + 1);
                  if (claimDetail && claimDetail.damageType) {
                    switch (String(claimDetail.damageType.id)) {
                      case '1': {
                        detailClaimsNumber.ofWhichThings = String(Number(detailClaimsNumber.ofWhichThings) + 1);
                        break;
                      }
                      case '2': {
                        detailClaimsNumber.ofWhichPerson = String(Number(detailClaimsNumber.ofWhichPerson) + 1);
                        break;
                      }
                      case '3': {
                        detailClaimsNumber.ofWhichMixed = String(Number(detailClaimsNumber.ofWhichMixed) + 1);
                        break;
                      }
                    }
                  }
                }
              }
            }
            // verificare che il numero di total per anno sia corretto --> errore
            // se è corretto verificare che le tipologie siano corrette
            if (claimReportYears.yearReal === detailClaimsNumber.id) {
              if (Number(claimReportYears.pairResponsibility.total) >= 0) {
                if (Number(claimReportYears.pairResponsibility.total) !== Number(detailClaimsNumber.total)) {
                  this.blockingMessages.push(new Message(this.areaCode,
                    this.translate.instant('Number of Claim Details not matching for year') + ' '
                    + claimReportYears.yearReal));
                } else {
                  if (Number(claimReportYears.pairResponsibility.ofWhichMixed) >= 0
                    && (Number(claimReportYears.pairResponsibility.ofWhichMixed)
                      !== Number(detailClaimsNumber.ofWhichMixed))) {
                    this.blockingMessages.push(new Message(this.areaCode,
                      'Wrong number of Claim Detail for Mixed type'));
                  }
                  if (Number(claimReportYears.pairResponsibility.ofWhichPerson) >= 0 &&
                    (Number(claimReportYears.pairResponsibility.ofWhichPerson)
                      !== Number(detailClaimsNumber.ofWhichPerson))) {
                    this.blockingMessages.push(new Message(this.areaCode,
                      'Wrong number of Claim Detail for Person type'));
                  }
                  if (Number(claimReportYears.pairResponsibility.ofWhichThings) >= 0 &&
                    (Number(claimReportYears.pairResponsibility.ofWhichThings)
                      !== Number(detailClaimsNumber.ofWhichThings))) {
                    this.blockingMessages.push(new Message(this.areaCode,
                      'Wrong number of Claim Detail for Things type'));
                  }
                }

                this.updateMessages();

              }
            }
          }
        }
        resolve();
      });
      this.subscriptions.add(insuranceStatusChannelSubscription);
    });
  }

  onRiskCertificateCompletenessErrors(event: boolean) {
    this.riskCertificateCompletenessErrors = event;
  }

  onUpdateAssetData(event) {
    this.assetDataCompleted = event;
  }

  onWarningIconClick() {
    this.showFloatingMessages = true;
    this.enableWarningIcon = false;
  }

  onCloseErrorsClick() {
    this.showFloatingMessages = false;
    this.enableWarningIcon = true;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.pageYOffset > this.errorsElement.nativeElement.offsetTop) {
      this.showFloatingMessages = false;
      this.enableWarningIcon = false;
    } else {
      this.enableWarningIcon = true;
    }
  }

  readWarning() {
    this.nonBlockingMessages = this.nonBlockingMessages.filter(warn => {
      return warn.area !== 'WARNING';
    });
    /* This block of code is used to clean up WARN messages because during the eseguiAzioni are called multiple times and the messages are repeated.
       During the execution of eseguiAzioni, checks are performed based on levels (Unit, Bene amd Dati ATR).
        For the "livello di bene", "eseguiAzioni" is executed multiple times, leading to duplicated messages.
        To fix this problem, it was decided to clean up WARN messages to avoid duplications.
    */
    this.blockingMessages = this.blockingMessages.filter(warn => {
      return warn.area !== 'WARN';
    });

    this.proposalService.getWarnings().subscribe(
      (warnList) => {
        let message: Message;
        warnList.filter(msg => msg.mandatory === false)
          .map(msg => msg.description).forEach(warn => {
            message = new Message('WARNING', warn);
            if (!this.errorMessagesService.includesMessage(this.nonBlockingMessages, message)) {
              this.nonBlockingMessages.push(new Message('WARNING', warn));
            }
          });

        warnList.filter(msg => msg.mandatory === true)
          .map(msg => msg.description).forEach(warn => {
          message = new Message(this.areaCode, warn);
          if (!this.errorMessagesService.includesMessage(this.blockingMessages, message)) {
            this.blockingMessages.push(new Message('WARN', warn));
          }
        });

        this.updateMessages();
      });
  }

  variablesRowsAsset(): Variable[][] {

    const filteredVariables = this.variablesService.getVariablesObject().filter(
      (variable) => {
        return variable.visibilityArea === 0
          || variable.visibilityArea === 1
          || variable.visibilityArea === 4;
      }
    ).sort(
      (a: Variable, b: Variable) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 0;
        }
      }
    );

    const variablesNum = filteredVariables.length;

    const variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 3));
    let i: number;
    let j = 0;

    for (i = 0; i < variablesNum; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!variablesRows[j]) {
        variablesRows[j] = [];
      }
      variablesRows[j].push(filteredVariables[i]);
    }
    return variablesRows;
  }

  updateInsuranceBackgroundMessages(event: Array<GenericEntity>) {
    const filtered = this.nonBlockingMessages.filter((msg: Message) => msg.area !== 'INSURANCE_BACKGROUND');

    this.nonBlockingMessages.length = 0;

    const messagesInsuranceBkg = new Array<Message>();
    event.forEach(msg => {
      messagesInsuranceBkg.push(new Message('INSURANCE_BACKGROUND', msg.description));
    });

    Array.prototype.push.apply(this.nonBlockingMessages, filtered);
    Array.prototype.push.apply(this.nonBlockingMessages, messagesInsuranceBkg);
  }

  isProposal() {
    return this.proposalService.isProposal();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private updateMessages() {
    if (this.validationMessages) {
      this.validationMessages.length = 0;
    } else {
      this.validationMessages = [];
    }

    Array.prototype.push.apply(this.validationMessages, this.formMessages);
    Array.prototype.push.apply(this.validationMessages, this.blockingMessages);
    Array.prototype.push.apply(this.validationMessages, this.partyMessages);
  }

  private initVariables() {

    this.variablesService.getVariables().subscribe(
      (data) => {
        this.variables = data;
        this.variablesService.setVariables(this.variables);
      });

    const variablesChannelSubscription = this.variablesService.getVariablesObserable().subscribe(
      (data) => {
        this.variables = data;
      });
    this.subscriptions.add(variablesChannelSubscription);
  }

  onVehicleChanged($event) {
    this.ngOnInit();
  }

  addPartyRolesNonBlockingMessaging(event: Message[]) {
    const filtered = this.nonBlockingMessages.filter((msg: Message) => msg.area !== 'ROLES');
    this.nonBlockingMessages.length = 0;
    Array.prototype.push.apply(this.nonBlockingMessages, filtered);
    Array.prototype.push.apply(this.nonBlockingMessages, event);
  }

  addPartyRolesMessaging(event: Message[]) {
    if (this.partyMessages) {
      this.partyMessages.length = 0;
    } else {
      this.partyMessages = [];
    }
    Array.prototype.push.apply(this.partyMessages, event);
    this.updateMessages();
  }

  onPartyDataChanged(): void {
    this.partiesService.checkParties(this.proposalService.getContractId(), false)
      .subscribe(() => this.getWarningMessages());
  }

  onSubscriberChanged(onlyEntitled: boolean = false) {
    this.entitledParty = this.partiesService.getEntitledParty();
    if (!onlyEntitled) {
      this.partiesService.sendSubscriberChanged();
    }
    this.partiesConfirmed = !this.hasSystemIntegration;
    this.hasSubscriber = true;
  }

  onSubscriberRemoved() {
    this.hasSubscriber = false;
    this.partiesConfirmed = !this.hasSystemIntegration;
  }

  protected getWarningMessages() {
    this.proposalService.getWarnings().subscribe(
      (warnList) => {
        this.nonBlockingMessages = this.nonBlockingMessages.filter(
          (msg: Message) => msg.area !== 'WARN'
        );
        this.validationMessages = this.validationMessages.filter(
          (msg: Message) => msg.area !== 'WARN'
        );
        warnList.forEach((warn: Warning) => {
          let message = new Message('WARN', warn.description);
          message.mandatory = warn.mandatory;
          if (message.mandatory) {
            this.validationMessages.push(message);
          } else {
            this.nonBlockingMessages.push(message);
          }
        });
      });
  }

  isEntitledBeforeQuotation() {
    return this.proposalService.isEntitledBeforeQuotation();
  }

  onClauseChange() {
    if (this.clausesList !== undefined) {
      this.clauseService.updateAssetClauses(this.clausesList).subscribe(
        res => {
          this.blockingMessages.length = 0;
          this.readWarning();
        }
      );
    }
  }

  isSectionEditable(sectionKey) {
    return this.proposalService.isFieldEnabled(sectionKey) === false ? false : true;
  }

  confirmParties() {
    this.partiesConfirmed = true;
  }

  onSubscriberPresetCheck(subscriberPresent: boolean){
    this.hasSubscriber = subscriberPresent;
  }
}

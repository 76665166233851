import {ComponentRef, Inject, Injectable, OnDestroy} from '@angular/core';
import {RgiRxLoadingIndicator} from '@rgi/rx';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Directionality} from '@angular/cdk/bidi';
import {ComponentPortal} from '@angular/cdk/portal';
import {RgiRxLoadingOverlayHostComponent} from './rgi-rx-loading-overlay-host/rgi-rx-loading-overlay-host.component';
import {Subscription} from 'rxjs';
import {debounceTime, delay, tap} from 'rxjs/operators';
import {RGI_RX_LOADING_OVERLAY_CONFIG, RgiRxLoadingOverlay, RgiRxLoadingOverlayConfig} from './rgi-rx-loading.api';

@Injectable()
export class RgiRxLoadingOverlayService extends RgiRxLoadingOverlay implements OnDestroy {
  private overlayRef: OverlayRef;
  private loaderHostRef: ComponentRef<RgiRxLoadingOverlayHostComponent>;
  private loadingIndicatorSubscription = Subscription.EMPTY;

  constructor(
    private loadingIndicator: RgiRxLoadingIndicator,
    private overlay: Overlay,
    private directionality: Directionality,
    @Inject(RGI_RX_LOADING_OVERLAY_CONFIG) private config: RgiRxLoadingOverlayConfig,
  ) {
    super();
  }

  isOpen() {
    return this.overlayRef && this.overlayRef.hasAttached();
  }

  show() {
    if (this.isOpen()) {
      return;
    }
    this.overlayRef = this.overlay.create(
      {
        direction: this.directionality.value,
        positionStrategy: this.overlay.position().global(),
        height: '100%',
        width: '100%',
        hasBackdrop: true,
        backdropClass: 'rgi-ui-loader-overlay-backdrop',
        panelClass: 'rgi-ui-loader-overlay-panel',
        scrollStrategy: this.overlay.scrollStrategies.block()
      }
    );
    this.loaderHostRef = this.overlayRef.attach(
      new ComponentPortal(RgiRxLoadingOverlayHostComponent)
    );
    this.loaderHostRef.changeDetectorRef.detectChanges();
  }

  hide() {
    this.dispose();
  }

  toggle() {
    if (this.isOpen()) {
      this.hide();
      return;
    }
    this.show();
  }

  protected dispose() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  observeIndicator(debounceTimeMillis?: number) {
    if (this.loadingIndicatorSubscription.closed) {
      this.loadingIndicatorSubscription.unsubscribe();
    }
    this.loadingIndicatorSubscription = this.loadingIndicator.hasPending$()
      .pipe(
        debounceTime(debounceTimeMillis !== undefined ? debounceTimeMillis : this.config.debounceTimeMillis),
        tap(hasPending => {
          if (hasPending) {
            this.show();
            return;
          }
          this.hide();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (!this.loadingIndicatorSubscription.closed) {
      this.loadingIndicatorSubscription.unsubscribe();
    }
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }


}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReIssueStartCardState} from '../../re-issue-state-manager/re-issue-start-card-state-manager.service';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {OperationsOutput} from '../re-postsale-model/re-postsales-cancellation';

@Injectable({
  providedIn: 'root'
})
export class RePostsalesCancellationStartResourceService {
  private portfolioPolicyEndpoint: any;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private apiConf: any,
    // @Inject(REST_API_BANCA_PTF_DANNI_CONF) private apiBancaPtfAllConf: RestApiBancaPtfDanniConf,
  ) {
    this.portfolioPolicyEndpoint = this.apiConf.api.portal.path + '/v2/portfolio/policy';
  }

  getCancellationReason$(st: ReIssueStartCardState, policyOperationsInput): Observable<Array<OperationsOutput>> {
    return this.httpClient.post<any>(this.getInternalCancellationReasonUri(), policyOperationsInput).pipe(share(), map(val => {
        return val.operationsOutput.operations;
      })
    );
  }

  getInternalCancellationReasonUri() {
    return 'api/rest/ptfdanni/policyoperations';
  }

}


// @ts-ignore

import { downgradeComponent } from "@angular/upgrade/static";
import { ModalComponent } from "@rgi/portal-ng-core";
import { newLifeCardExt } from "./issueExtCardsTemplates";
import { LIC_AJS_DIRECTIVE } from "@rgi/life-issue-card";
import { LifeCardExtComponent } from "../life-card-ext.component";

// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

const LIC_TPL_URL = 'assets/';
const factory = (templateKey, template) => ['$templateCache', ($templateCache) => {
  $templateCache.put(LIC_TPL_URL + templateKey, template);
}];

export function AjsConfigurationLifeExt() {
  angular.module('core').config([
    'eventServiceProvider',
     (evProvider) => {
      evProvider.registerEvent('gettextLanguageChanged', {type: 'i18n'});
    }
  ]);

  registerLifeCard();

  angular.module('app')
      .directive(
          'pncModal',
          downgradeComponent({component: ModalComponent})
      );

  // *********************************** */


  /* REGISTRAZIONE CARD FLUSSO EMISSIONE VITA */
  function registerLifeCard() {
    const lifeModule = angular.module('ptfall-card-finder');
    lifeModule.config([
      'cardManagementServiceProvider', 'gettext',
      (cardManagementServiceProvider, gettext) => {
        const LIFE_CARD = {
          title: gettext('Life Proposal'),
          tpl: '',
          name: 'ptflifeProposal',
          category: '',
          type: '',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(LIFE_CARD);
      }
    ]);

    lifeModule
    .run(factory('newLifeCard.tpl.html', newLifeCardExt))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptflifeProposal', 'anag', {
          template: 'assets/newLifeCard.tpl.html',
          label: 'Home - New Life Card',
          type: 'home',
          controller: 'AngularCommunicationCtrlIssue'
        });
      }
    ])
    .directive(LIC_AJS_DIRECTIVE.LIFE_CARD_SESSION + 'Ext', downgradeComponent({component: LifeCardExtComponent}));
  }

}

import {ActiveRoute, RgiRxURIDef} from '../router.api';
import {SubscriptionLike} from 'rxjs';
import {Location, PopStateEvent, APP_BASE_HREF, PlatformLocation, LocationStrategy} from '@angular/common';
import {RGI_RX_ROUTER_LOCATION_CONFIG, RgiRxRouterLocation, RgiRxRouterLocationConfig} from './router.location.api';
import {Inject, Injectable, Optional} from '@angular/core';
import {RgiRxUrlParser} from '../rgi-rx-url-parser';
import {LoggerFactory} from '@rgi/rx';
import {removeLeadingAndTrailingSlashes} from '../router.fns';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class RgiRxRouterHistoryLocation extends RgiRxRouterLocation {

  private readonly logger = LoggerFactory();
  constructor(private location: Location,
              private platformLocation: PlatformLocation,
              private locationStrategy: LocationStrategy,
              private urlParser: RgiRxUrlParser,
              @Inject(RGI_RX_ROUTER_LOCATION_CONFIG) private config: RgiRxRouterLocationConfig,
              private title: Title
              ) {
    super();
  }

  push(activeRoute: ActiveRoute) {
    const root = '/';
    const rgiRxSerializedRoute = activeRoute.route !== '**' ? this.urlParser.serializeActiveRoute(activeRoute) : this.current();
    activeRoute.uriDef = rgiRxSerializedRoute;
    if (activeRoute.title) {
      this.title.setTitle(activeRoute.title);
    }
    if (this.location.isCurrentPathEqualTo(`${root}${rgiRxSerializedRoute.path}`) || (activeRoute.routeOptions && !!activeRoute.routeOptions.replaceState)) {
      this.location.replaceState(rgiRxSerializedRoute.path, rgiRxSerializedRoute.query, activeRoute.routeOptions ? activeRoute.routeOptions.state : undefined);
      this.logger.debug('RgiRxRouterHistoryLocation::push replace state', {
        route: rgiRxSerializedRoute,
        activeRoute: activeRoute.getRouteData(),
        state: this.location.getState()
      });
    } else {
      this.location.go(rgiRxSerializedRoute.path, rgiRxSerializedRoute.query, activeRoute.routeOptions ? activeRoute.routeOptions.state : undefined);
      this.logger.debug('RgiRxRouterHistoryLocation::push go', {
        route: rgiRxSerializedRoute,
        activeRoute: activeRoute.getRouteData(),
        state: this.location.getState()
      });
    }
  }
  subscribe(onNext: (value: PopStateEvent) => void, onThrow?: ((exception: any) => void) | null, onReturn?: (() => void) | null): SubscriptionLike {
    return this.location.subscribe(onNext, onThrow, onReturn);
  }

  path(): string {
    return this.location.path(this.config.strategy === 'hash');
  }
  normalize(url: string) {
    return removeLeadingAndTrailingSlashes(url);
  }


  toDefinition(url: string): RgiRxURIDef {
    // todo check this
    const splitted = url.split('?');
    return  {
      path: splitted[0],
      query: splitted[1],
      url: this.urlParser.toUrl(splitted[0], splitted[1])
    };
  }


  shouldNavigateAtLaunch(): boolean {
    return this.config.initialNavigation;
  }

  href(): string {
    return this.locationStrategy.getBaseHref();
  }

  toExternalURL(url: string): string {
    return this.locationStrategy.prepareExternalUrl(url);
  }

  query(): string {
    return document.location.search;
  }

  state(): unknown {
    return this.location.getState();
  }

  back(): void {
    return this.location.back();
  }

  forward(): void {
    return this.location.forward();
  }


  current(): RgiRxURIDef {
    return {
      path: this.path(),
      query: this.platformLocation.search,
      url: this.urlParser.toUrl(this.path(), this.platformLocation.search)
    };
  }

  replaceState(state: any, targetUri?: RgiRxURIDef) {
    const rgiRxSerializedRoute = targetUri ? targetUri : this.current();
    this.location.replaceState(rgiRxSerializedRoute.path, rgiRxSerializedRoute.query, {...state});
  }

  setTitle(title: string){
    this.title.setTitle(title);
  }
}

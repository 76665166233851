<form [formGroup]="customForm">
  <div *ngIf="isEmpty(); else isFilled">
      <input  class="drop-container" [value]="select" data-qa="dashed-select-beneficiary" (click)="openAnag()" >
  <br>
  </div>


  <ng-template #isFilled>
    <div class="input-group">
      <ng-container [formGroupName]="'value'">
        <input
          value="{{fill()}}"
          type="text"
          data-qa="beneficiaryName"
          name="extraInformations_0"
          class="form-control effectiveHolder"
          readonly
          [ngClass]="{'is-invalid' : customForm.get('value').invalid && customForm.get('value').dirty && submitted}"
        >
      </ng-container>
      <input
        translate
        [formControlName]="'percentage'"
        name="cat +'percentage'"
        data-qa="cat+percentage"
        min="0.01"
        max="100"
        class="form-control compulsory percentage"
        [placeholder]="'lic_Percentage' | translate"
        required
        licPercentageDirective
        [ngClass]="{ 'is-invalid': classStyle()}"
      >
      <div class="input-group-btn">
          <button [attr.data-qa]="(!!customForm.get('value').value.surname ? customForm.get('value').value.surname : customForm.get('value').value.denomination)+'_subject_dataQa'"
              type="button" class="btn btn-default enabled" (click)="resetMethod()">
              <span class="rgifont rgi-trash"></span>
          </button>
          <button type="button" class="btn btn-default" (click)="editSubject()" [attr.data-qa]="'modify-100'">
            <span class="rgifont rgi-pencil"></span>
          </button>
      </div>
    </div>
    <br>

    <!-- EXTRA FIELD SUCH AS REG41 and SEVERE DISABILITY CHECKBOXs, THE POSITION NUMBER SELECT AND THE CEDOLA FIELDS -->
    <div class="example-row" *ngIf="REG41CheckBox" >
      <rgi-rx-form-field>
        <label rgiRxLabel>{{ 'lic_reg41_comunication' | translate }}</label>
        <input type="checkbox" rgiRxInput formControlName="_reg41" [attr.data-qa]="'checkbox_reg41'">
      </rgi-rx-form-field>
      <!-- <pnc-checkbox
        [formControlName]="'_reg41'"
        class="check"
        [attr.data-qa]="'checkbox_reg41'"
        [checked]="true">
      </pnc-checkbox>
      <label translate class="lbl_input" translate>lic_reg41_comunication</label> -->
    </div>
    <div class="example-row" *ngIf="showBenefSevereDisability()" >
      <rgi-rx-form-field>
        <label rgiRxLabel>{{ 'lic_benefSevereDisability' | translate }}</label>
        <input type="checkbox" rgiRxInput formControlName="severeDisability" [attr.data-qa]="'checkbox_SevereDisability'">
      </rgi-rx-form-field>
      <!-- <pnc-checkbox
        [formControlName]="'severeDisability'"
        class="check"
        [attr.data-qa]="'checkbox_SevereDisability'"
        [checked]="severeDisabilityCheckBox">
      </pnc-checkbox>
      <label translate class="lbl_input" translate>lic_benefSevereDisability</label> -->
    </div>

    <!-- JIRA RDDL-4273 -->
    <div class="row" *ngIf="isBenefCedola" formGroupName="paymentInfo">
      <ng-container *ngIf="!!resultPayments && !!resultPayments.credMeansOfPayment">
        <!-- <div class="col-lg-6" >
          <label class="label-core" ng-bind="label">
            {{'lic_payment_method' | translate}}
            <lic-required required="true"></lic-required>
          </label>
        </div> -->
        <div class="col-lg-6">
          <rgi-rx-form-field>
            <label rgiRxLabel>
              <span>{{'lic_payment_method' | translate}}</span>
            </label>
            <select rgiRxNativeSelect data-qa="paymentInfoCedola" name="paymentInfoCedola" formControlName="idPaymentType" (onValueChange)="paymentTypeSelected($event)">
              <option value=""></option>
              <option *ngFor="let payment of paymentTypes" [value]="payment?.identification">
                  {{payment?.description}}
              </option>
            </select>
          </rgi-rx-form-field>
        </div>
        <!-- PAGAMENTI AGGIUNTIVI RATA ALLA FIRMA-->
        <div *ngIf="!!paymentFieldDefinitions.length" class="col-lg-12" id="payment-fields">
          <lic-payment [parentForm]="customForm.get('paymentInfo')" [pagamenti]="paymentFieldDefinitions">
          </lic-payment>
        </div>
      </ng-container>
      <ng-container *ngIf="!!loadingPayments">
        <div class="row">
          <div id="loader-mobile" class="loader-card payment-cedola">
            <div class="loader-position"  id="lic-loader-section">
              <span class="loader"><span class="loader-inner"></span></span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row" *ngIf="!!(beneficiaryService.benefGroupingTypes.activeTypes | licExtractFromArray: 'code':  cat)" formGroupName="positionNumber">
        <div class="col-lg-6">
          <rgi-rx-form-field>
            <label rgiRxLabel>
              <span>{{'lic_position_number' | translate}}</span>
            </label>
            <select rgiRxNativeSelect data-qa="benef_position_number" name="positionNumberType" formControlName="positionNumberType">
              <option value=""></option>
              <option *ngFor="let position of beneficiaryService?.benefGroupingTypes?.types?.values" [value]="position?.codice">
                  {{position?.descrizione}}
              </option>
            </select>
          </rgi-rx-form-field>
        </div>
    </div>

    <!-- EXTRA FIELD SUCH AS REG41 and SEVERE DISABILITY CHECKBOXs, THE POSITION NUMBER SELECT AND THE CEDOLA FIELDS -->


    <br>
    <div *ngFor="let role of getListOfRelatedRoles()">
      <div *ngIf="true" class="related-subject-ben-section">
        <lic-related-subject-list
          [withPercentage]="role.showPercentage"
          [maxLength]="role.maxInstances"
          [minPercentage]="role.minPercentage"
          [category]="cat"
          [role]="role.partyRole"
          [benef]="getThisSubjId()"
          (eventPropagation)="openAnag($event)"
          (subjetAnagEditedEvent)="editReletedSubject($event)"
          (relatedSubjectDeletion)="deleteReletedSubject($event)"
        ></lic-related-subject-list>
      </div>
    </div>
  </ng-template>
</form>

<div class="rgi-gp-field-container" *ngFor="let factor of factors;">
  <div class="rgi-gp-field rgi-gp-variable">
    <ng-container *ngIf="factor" [ngSwitch]="factor.type">
      <ng-container *ngSwitchCase="FACTOR_TYPE.LIST">
        <rgi-gp-group-policy-factors-select-component
          [factor]="factor"
          [group]="parentForm"
          (updateFactors)="onChangeFactor($event)"
          [attr.data-qa]="factor.code"
        ></rgi-gp-group-policy-factors-select-component>
      </ng-container>
      <ng-container *ngSwitchCase="FACTOR_TYPE.NUMERIC">
        <ng-container *ngIf="factor.values.length > 0; then selectTpl else inputTpl"></ng-container>
        <ng-template #selectTpl>
          <rgi-gp-group-policy-factors-select-component
            [factor]="factor"
            [group]="parentForm"
            (updateFactors)="onChangeFactor($event)"
            [attr.data-qa]="factor.code"
          ></rgi-gp-group-policy-factors-select-component>
        </ng-template>
        <ng-template #inputTpl>
          <rgi-gp-group-policy-factors-numeric-component
            [factor]="factor"
            [group]="parentForm"
            (updateFactors)="onChangeFactor($event)"
            (customFactorAction)="customFactorAction.emit(factor)"
            [attr.data-qa]="factor.code"
          ></rgi-gp-group-policy-factors-numeric-component>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="FACTOR_TYPE.BOOLEAN">
        <rgi-gp-group-policy-factors-radio-component
          [factor]="factor"
          [group]="parentForm"
          (updateFactors)="onChangeFactor($event)"
          [attr.data-qa]="factor.code"
        ></rgi-gp-group-policy-factors-radio-component>
      </ng-container>
      <ng-container *ngSwitchCase="FACTOR_TYPE.DATE">
        <rgi-gp-group-policy-factors-datepicker-component
          [factor]="factor"
          [group]="parentForm"
          (updateFactors)="onChangeFactor($event)"
          [attr.data-qa]="factor.code"
        ></rgi-gp-group-policy-factors-datepicker-component>
      </ng-container>
      <ng-container *ngSwitchCase="FACTOR_TYPE.STRING">
        <rgi-gp-group-policy-factors-input-component
          [factor]="factor"
          [group]="parentForm"
          (updateFactors)="onChangeFactor($event)"
          [attr.data-qa]="factor.code"
        ></rgi-gp-group-policy-factors-input-component>
      </ng-container>
    </ng-container>
  </div>
</div>

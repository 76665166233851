import {Component, EventEmitter, Input, isDevMode, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {RoutingService} from '../routing.service';
import {ActiveRoute, RgiRxRouterOutlet, RoutableComponent, RouteChangeOptions} from '../router.api';
import {RgiRxRoutingHostDirective} from '../directives/rgi-rx-routing-host.directive';
import { forwardObservableToEmitter, LoggerFactory } from '@rgi/rx';


@Component({
  selector: 'rgi-rx-routing-outlet',
  templateUrl: './routing-outlet.component.html',
  providers: [{
    provide: RgiRxRouterOutlet,
    useExisting: RoutingOutletComponent
  }]
})
export class RoutingOutletComponent extends RgiRxRouterOutlet implements OnInit, OnDestroy {

  private readonly logger = LoggerFactory();

  /**
   * @deprecated the outlet will no longer accept route as input. Navigate with the routingService or the routerLink directives instead.
   */
  @Input() route?: string;
  /**
   * @deprecated the outlet will no longer accept route data as input. Pass data with the routingService or the routerLink directives instead.
   */
  @Input() data?: any;

  @Input() id?: string;
  /**
   * @deprecated the outlet will no longer accept route options as input. Pass options with the routingService or the routerLink directives instead.
   */
  @Input() options?: any;
  /**
   * @deprecated the outlet will no longer accept route change options as input. Pass options with the routingService or the routerLink directives instead.
   * RouteChangeOptions is also deprecated as no longer needed
   */
  @Input() routeChangeOpts?: RouteChangeOptions;
  @ViewChild(RgiRxRoutingHostDirective, {static: true}) host?: RgiRxRoutingHostDirective;
  @ViewChild(RoutableComponent, {static: true}) routableComponent?: RoutableComponent;

  /**
   * @description Emits when the route changes
   * @deprecated use activated instead, this method will be removed in the next major version
   */

  @Output() onChange = new EventEmitter<ActiveRoute>();

  /**
   * @description Emits when the route is activated
   */

  @Output() activated = new EventEmitter<ActiveRoute>();

  /**
   * @description Emits when the route change listener is ready
   */
  @Output() ready = new EventEmitter<void>();

  private routeSubscription: Subscription = Subscription.EMPTY;
  private forwardOnChangeSubscription: Subscription =  Subscription.EMPTY;

  constructor(
    private routingService: RoutingService) {
    super();
  }

  ngOnInit(): void {
    this.forwardOnChangeSubscription = forwardObservableToEmitter(this.activated.asObservable(), this.onChange).subscribe();
    this.routeSubscription = forwardObservableToEmitter(this.routingService.getRouteChange$(this.host, this.id, this.routeChangeOpts), this.activated).subscribe();
    this.ready.emit();
     if (this.route) {
       if (isDevMode()) {
         this.logger.warn(`The route input to initialize the first navigation is deprecated and will be removed in the next major version. Use the routingService or the routerLink directives instead and keep the <rgi-rx-routing-outlet> clean`)
       }
      this.routingService.navigate(this.route, this.data, this.id, this.options);
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.forwardOnChangeSubscription.unsubscribe();
    if (this.host) {
      this.host.viewContainerRef.clear();
    }
  }
}

import {SCALAR_TYPE} from '@rgi/rx';

export class GenericEntity {
  code: string;
  description: string;
  identification?: string;

  constructor(code: string, description: string, id?: string) {
    this.code = code;
    this.description = description;
    this.identification = id;
  }
}

export class GenericEntityId {
  code: string;
  description: string;
  id: string;

  constructor(code: string, description: string, id?: string) {
    this.code = code;
    this.description = description;
    this.id = id;
  }
}

export class LabeledEntity {
  label: string;
  description: SCALAR_TYPE;
  value?: string;
  nestedEntities?: LabeledEntity[]

  constructor(label: string, description: SCALAR_TYPE, value?: string, nestedEntities?: LabeledEntity[]) {
    this.label = label;
    this.description = description;
    this.value = value;
    this.nestedEntities = nestedEntities;
  }
}

export class SelectableEntity extends GenericEntity {
  selected: boolean;

  constructor(code: string, description: string, selected: boolean) {
    super(code, description);
    this.code = code;
    this.description = description;
    this.selected = selected;
  }
}

export function selectableEntityToLabeledEntity(selectable: SelectableEntity) : LabeledEntity {
  return {
    label: selectable.description,
    description: selectable.description,
    value: selectable.code
  };
}

import { State } from "@rgi/rx/state";
import { TableSchema } from "@rgi/rx/ui";
import { Claim } from "../../claims-elastic/dto/claim";

export class CtpDetail {
  requestNumber?:string;
  id: string;
  dcancel?: Date;
  dins?: Date;
  cplate?: string;
  doccurrence?: string;
  ccodcomp?: string;
  mergeAbsorptionDate?: Date;
  compCntPartyCod?: string;
  compCntPartyDescr?:string;
  estatus?: any;
  dsend?: Date;
  cancellationDate?: Date;
  notificationDate?: Date;
  errorcode?: string;
  ereport?: any;
  message?: string;
}
export class FivaStateModel extends State {
    claim: any = null ;
    newCtpDetail: CtpDetailNew = new CtpDetailNew();
    ctpList: CtpDetail[] = [];
}

export class CtpDetailNew{
  cclaimnumber: string;
  cnumpol: string;
  cplate: string;
  cplatecntparty: string;
  doccurrence: string;
  etypedamage: string;
  ereport: string;
  cprocedurenumber:any;
  cinstance: string;
}

export const tableSchema: TableSchema = {
  rows: [
    {
      name: 'requestNumber', 
      title: '_CLAIMS_._NUMBER_REQUEST',
    },
   
    {
      name: 'dins', title: '_CLAIMS_._INSERTION_DATE',
      format: {
          pipe: 'date',
        args: ['shortDate']
        
      }
      , description: ''
    },
    {
      name: 'cplate',
      title: '_CLAIMS_._COUNTERPARTY_PLATE_NUMBER',
    },
    {
      name: 'doccurrence',
      title: '_CLAIMS_._OCCURRENCE_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },
    
    {
      name: 'ereport',
      title: '_CLAIMS_._REPORT',
     
    },
    {
      name: 'dsend',
      title: '_CLAIMS_._SENDING_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },

    {
      name: 'action',
      title: '_CLAIMS_._ACTION',
     
    },
    {
      name: 'estatus',
      title: '_CLAIMS_._STATUS',
     
    },
    {
      name: 'dcancel',
      title: '_CLAIMS_._CANCELLATION_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },

    {
      name: 'message',
      title: '_CLAIMS_._MESSAGE_RESPONSE',
     
    },

    {
      name: 'ccodcomp',
      title: '_CLAIMS_._COMPANY',
      
    },

    {
      name: 'notificationDate',
      title: '_CLAIMS_._NOTIFICATION_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },

    {
      name: 'cancelRequestDate',
      title: '_CLAIMS_._CANCEL_REQUEST_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
    },

    {
      name: 'compCntPartyCod',
      title: '_CLAIMS_._NEW_COMPANY',
      
    }, 

    {
      name: 'compCntPartyDescr',
      title: '_CLAIMS_._COMPANY_DESCRIPTION'
    },

    {
      name: 'mergeAbsorptionDate',
      title: '_CLAIMS_._MERGE_ABSORPTION_DATE',
      format: {
        pipe: 'date',
        args: ['shortDate']
      }
      
    },
    
    {
      name: 'errorcode',
      title: '_CLAIMS_._ERROR_CODE',
     
    },


  ],
header: ['requestNumber', 'dins', 'cplate', 'doccurrence','ereport','dsend', 'action','estatus','dcancel','message','ccodcomp','notificationDate','cancelRequestDate','compCntPartyCod','compCntPartyDescr', 'mergeAbsorptionDate', 'errorcode'],
  
};
<div *ngIf="thirdPartyDefinition && thirdPartyDefinition.visible" class="col-lg-12 third-party-container">
  <div [formGroup]="formGroup">
    <label translate>{{thirdPartyDefinition.label}}</label>
    <div *ngIf="isPresent(); else empty">
      <lpc-beneficiary-control (delete)="deleteBen($event)" [showPercentage]="false"
                               formControlName="thirdParty">
      </lpc-beneficiary-control>
    </div>
    <ng-template #empty>
      <input (click)="openAnagSubjectModal()" [value]="'lpc_Seleziona' | translate" class="col-lg-12 drop-container dashedSelect" data-qa="dashed-select-thirdParty" readonly>
    </ng-template>

  </div>
</div>


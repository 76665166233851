import {Component, Input, OnInit} from '@angular/core';
import {RgiRxLogger} from '@rgi/rx';
import {ModalService} from '@rgi/rx/ui';
import * as JSZip from 'jszip';
import {PcProposal} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import {
  ReIssueCardPolicyDocumentsBusinessService
} from '../re-issue-business-services/re-issue-card-policy-documents-business.service';
import {PcPolicy} from '../../models/pc-portfolio-models/policy-models/pc-policy';
import {DocumentPrint} from '../../models/api-models/document-print';
import {
  ReIssueCardDocumentPreviewComponent
} from '../re-issue-card-document-preview/re-issue-card-document-preview.component';
import {Message} from '../../models/message';


@Component({
  selector: 'lib-re-issue-card-document',
  templateUrl: './re-issue-card-document.component.html',
  styleUrls: ['./re-issue-card-document.component.css']
})
export class ReIssueCardDocumentComponent implements OnInit {

  constructor(
    private documentsService: ReIssueCardPolicyDocumentsBusinessService,
    protected customModalService: ModalService,
    protected logger: RgiRxLogger
  ) {
  }

  documentsList = [];
  warningMessages: Message[] = [];
  errorMessages: Message[] = [];
  private stubDocumenList = false;

  @Input() isProposal: boolean;
  @Input() proposal: PcProposal;
  @Input() policy: PcPolicy;
  @Input() actionCode: string;
  @Input() movementId: number;
  @Input() isReprint: boolean;

  ngOnInit() {
    let realAct = this.actionCode;
    if (this.stubDocumenList) {

      this.documentsList.push({descrizione: 'Set Informativo', pdf: ''});
      if (!this.isProposal) {
        this.documentsList.push({descrizione: 'Lettera di benvenuto', pdf: ''});
      }
      this.documentsList.push({descrizione: 'Questionario adeguatezza', pdf: ''});
      this.documentsList.push({descrizione: 'Polizza preventivo', pdf: ''});
    } else {
      if (this.proposal) {
        if (!realAct) {
          realAct = 'PREVEL';
        }
        this.documentsService.printEmissionDocuments(
          null,
          this.proposal.contractNumber.proposal,
          realAct,
          this.isReprint,
          this.movementId
        ).subscribe(
          response => {
            if (response && response.responseInfos) {
              response.responseInfos.forEach(info => {
                if (info.responseCode === 200) {
                  this.documentsList.push({descrizione: info.documentName, pdf: info.responseByte});
                }
              });
              if (this.documentsList.length === 0 && this.isReprint) {
                this.errorMessages.push(new Message('card-document', 'No document found'));
              }
            }
          }
        );
      } else if (this.policy) {
        if (!realAct) {
          realAct = '000001';
        }
        this.documentsService.printEmissionDocuments(
          this.policy.contractNumber.policy,
          null,
          realAct,
          this.isReprint,
          this.movementId
        ).subscribe(
          response => {
            if (response && response.responseInfos) {
              response.responseInfos.forEach(info => {
                if (info.responseCode === 200) {
                  this.documentsList.push({descrizione: info.documentName, pdf: info.responseByte});
                }
              });
              if (this.documentsList.length === 0 && this.isReprint) {
                this.errorMessages.push(new Message('card-document', 'No document found'));
              }
            }
          }
        );
      }
    }
    if ((realAct === '000001' || realAct === 'PREVEL') && !this.isReprint) {
      // tslint:disable-next-line:max-line-length
      this.warningMessages.push({
        area: '',
        text: 'Si ricorda che prima di proseguire è necessario consegnare al cliente gli allegati 3 e 4 dell\'informativa contrattuale'
      });
    }
  }

  pdfPreview(event) {
    console.log(event);
    if (event) {
      const document = new DocumentPrint();
      document.sampaPdf = event.pdf;
      this.openStampModal(document);
    }
  }

  pdfDownload(event) {
    console.log(event);
  }

  openStampModal(document: DocumentPrint) {
    const {
      modal, component
    } = this.customModalService.openComponent(ReIssueCardDocumentPreviewComponent);

    component.document = document;
    // TODO: verifica cos'è il valore di encrypted
    // component.proposalNumber = this.stateManager.getCurrentState().contractNumber.proposalNumberEncrypted;
    // component.proposalNumber = this.stateManager.getCurrentState().proposal.contractNumber.proposal;
    // component.isProposal = true;

    modal.onClose.subscribe(() => {
    });
  }


  downloadAll() {
    this.logger.info('downloadAll():: downloading');

    const jszip = new JSZip();

    this.documentsList.forEach(document => {
      jszip.file(document.descrizione + '.pdf', atob(document.pdf), {binary: true});
    });

    const that = this;

    jszip.generateAsync({type: 'blob'}).then((content) => {
      that.downloadFile(content);
    });
  }

  downloadFile(file: any) {
    const blob = new Blob([file], {type: 'application/zip'});
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  b64toBin(b64Data: string): any {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return byteArrays;

    //
    // return new Blob(byteArrays, { type: contentType });
  }


  b64toBlob(b64Data: string, contentType: string): any {
    const byteArrays = this.b64toBin(b64Data);
    return new Blob(byteArrays, {type: contentType});
  }


}

<div class="row" [formGroup]="formGroup">
  <div *ngFor="let inputField of visibleInputFields; let i = index" class="col-md-6">
    <div *ngIf="inputField.listValue === null; else dateStepper">

        <lpc-datepicker
          [formControlName]="inputField.code"
          [label]="inputField.label"
          [required]="inputField.required"
          [min]="inputField.minValue"
          [max]="inputField.maxValue"
          [id]="inputField.code"
          [hidden]="!hide"
          [helpFile]="inputField.helpFile">
        </lpc-datepicker>
    </div>
    <ng-template #dateStepper>
        <lpc-date-stepper [hidden]="!hide" [formControlName]="inputField.code" [inputField]="inputField"></lpc-date-stepper>
    </ng-template>

    <div *ngIf="!hide" data-qa="summary-dates-step">
      <span for="label">{{ inputField.label }}: </span>
      <span for="value">{{ formGroup.get(inputField.code).value | date : 'dd/MM/yyyy' }}</span>
    </div>
  </div>
</div>

<div *ngIf="hide" class="next-available-dates-container">
  <div class="content-error next-available-dates" *ngFor="let nextDate of helpInfo">
    <span class="rgifont message-icon rgi-info"></span>
    <span>{{nextDate.date}}</span>
    <br>
    <span class="help-description">{{nextDate.help}}</span>
  </div>
</div>

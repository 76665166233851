import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter, HostBinding, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {RgiRxExpansionPanelContentDirective} from '../rgi-rx-expansion-panel-content.directive';
import {delay, filter, startWith, take} from 'rxjs/operators';
import {RgiRxSemanticStatus, RgiRxVirtualDOMError} from '@rgi/rx';
import {TemplatePortal} from '@angular/cdk/portal';
import {RgiRxExpansionPanelHeaderComponent} from '../expansion-panel-header/rgi-rx-expansion-panel-header.component';
import {Subscription} from 'rxjs';
import {ENTER, SPACE} from '@angular/cdk/keycodes';

let expansionPanelCounter = 0;
@Component({
  selector: 'rgi-rx-expansion-panel',
  templateUrl: './rgi-rx-expansion-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    role: 'button',
    '[attr.id]': 'id',
    '[attr.aria-expanded]': 'expanded ? "true" : "false"',
    '[attr.aria-disabled]': 'disabled ? "true" : "false"',
    '[attr.tabindex]': 'disabled ? -1 : 0',
    '[attr.aria-controls]': 'contentId',
  }
})
export class RgiRxExpansionPanelComponent implements OnChanges, AfterViewInit, AfterContentInit, OnDestroy {

  private _disabled = false;
  private _color?: RgiRxSemanticStatus | string;
  private readonly _id: string;
  private readonly _contentId: string;

  @Input() expanded = false;
  @Input() get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
  }

  /** Content that will be rendered lazily. */
  @ContentChild(RgiRxExpansionPanelContentDirective, {static: false}) _lazyContent: RgiRxExpansionPanelContentDirective;

  @ContentChild(RgiRxExpansionPanelHeaderComponent, {static: false}) header: RgiRxExpansionPanelHeaderComponent;

  @Output('toggle') toggleEvent = new EventEmitter<boolean>();

  portal: TemplatePortal;

  private toggleSubscription: Subscription = Subscription.EMPTY;
  private headerClickSubscription: Subscription = Subscription.EMPTY;


  constructor(private vc: ViewContainerRef) {
    this._id = `rgi-rx-expansion-panel-${++expansionPanelCounter}`;
    this._contentId = `${this._id}-content`;
  }


  get id(): string {
    return this._id;
  }

  get contentId(): string {
    return this._contentId;
  }

  @Input() get color(): RgiRxSemanticStatus | string {
    return this._color;
  }

  set color(value: RgiRxSemanticStatus | string) {
    this._color = value;
  }


  getClasses() {
    return {
      'rgi-ui-expansion-panel-open': !!this.expanded,
      [`rgi-ui-${this.color}`]: !!this.color
    };
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.header && !!changes.disabled && !changes.disabled.firstChange) {
      this.header.disabled = this.disabled;
    }
  }



  ngAfterContentInit(): void {
    if (!this.header) {
      throw new RgiRxVirtualDOMError('rgi-rx-expansion-panel expect RgiRxExpansionPanelHeaderComponent as children but no tags are matching rgi-rx-expansion-panel-header');
    }
    this.header.disabled = this.disabled;
  }

  ngAfterViewInit(): void {
    if (this._lazyContent) {
      this.toggleSubscription = this.toggleEvent
        .pipe(
          startWith(null),
          delay(0),
          filter(() => this.expanded),
          take(1)
        )
        .subscribe(() => {
          this.portal = new TemplatePortal(this._lazyContent.template, this.vc);
        });
    }
    this.headerClickSubscription = this.header.onClick.subscribe(
      next => {
        this.expanded = !this.expanded;
        this.toggleEvent.next(this.expanded);
      }
    );
  }

  ngOnDestroy(): void {
    this.toggleSubscription.unsubscribe();
    this.headerClickSubscription.unsubscribe();
  }


  expand() {
    this.expanded = true;
/*    this.toggleEvent.next(this.expanded); // emit event*/
  }

  contract() {
    this.expanded = false;
/*    this.toggleEvent.next(this.expanded);*/
  }

  toggle() {
    if (this.expanded) {
      this.contract();
      return;
    }
    this.expand();
    this.toggleEvent.next(this.expanded);
  }

  @HostListener('keydown', ['$event'])
  _handleKeydown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    if (this.disabled || this.vc.element.nativeElement !== event.target){
      return;
    }
    if (keyCode === ENTER || keyCode === SPACE) {
      {
        this.toggle();
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

}

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {RgiRxSnackbarNotification} from '../rgi-rx-snackbar-types';

@Component({
  selector: 'rgi-rx-snackbar-notification',
  templateUrl: './rgi-rx-snackbar-notification.component.html',
  providers: [
    {
      provide: RgiRxSnackbarNotification, useExisting: RgiRxSnackbarNotificationComponent
    }
  ],
  inputs: [
    'notification'
  ],
  outputs: [
    'remove'
  ]
})
export class RgiRxSnackbarNotificationComponent extends RgiRxSnackbarNotification implements OnInit{
  constructor(_vc: ViewContainerRef) {
    super(_vc);
  }

  ngOnInit(): void {
    if (this.notification && this.notification.options) {
      this.expanded = this.notification.options.expanded;
    }
  }


}

import { Factor, FactorValue } from '../../../models/factor.model';
import { Component, ViewEncapsulation, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-select-factor',
  templateUrl: './lpc-select-factor.component.html',
  styleUrls: ['./lpc-select-factor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LpcSelectFactorComponent implements OnInit {
  value: string;
  options: FactorValue[] = [];
  config: Factor;
  group: UntypedFormGroup;
  prevVal: string;
  modifiable: string;
  mandatory: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.prevVal = this.config.value;
    this.initSelect();

  }

  initSelect() {
    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.value === null || this.config.value === EMPTY_STR || this.config.value === '-1') {
      this.group.controls[this.config.code].setValue(null);
    } else {
      this.group.controls[this.config.code].setValue(this.config.value);
    }
    if (this.config.compulsory) {
      this.mandatory = 'Obbligatorio';
      this.group.controls[this.config.code].setValidators(Validators.required);
    } else {
      this.mandatory = 'Non obbligatorio';
    }
    if (this.config.modifiable) {
      this.modifiable = 'Modificabile';
      this.group.controls[this.config.code].enable();
    } else {
      this.modifiable = 'Non modificabile';
      this.group.controls[this.config.code].disable();
    }
  }

  addItem(item: FactorValue) {
    this.options.push(item);
  }

  setFactor(factor: Factor, form: UntypedFormGroup) {
    this.config = factor;
    this.value = this.config.description;
    for (const key in this.config.values) {
      if (key) {
        this.addItem(this.config.values[key]);
      }
    }
    this.group = form;
  }

  onChange() {
    if (this.prevVal !== this.group.controls[this.config.code].value) {
      this.el.nativeElement.dispatchEvent(new Event('changeVal', {bubbles: true}));
      this.prevVal = this.group.controls[this.config.code].value;
    }
  }

}

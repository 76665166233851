import { Injectable } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DashboardRiskDetail} from '../model/dashboard-risks-detail';
import {DashboardCardState} from '../state-manager/dashboard-manager.service';
import {formatNumber} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DashboardUtilityService {

  constructor() { }
  // transform in form group
  toFormGroup(codes: string[], values: string[] | number[], disabled: boolean): UntypedFormGroup {
    const group: any = {};
    codes.forEach((code, index) => {
      const value = values ? values[index].toString() : '';
      group[code] = new UntypedFormControl({value, disabled});
    });
    return new UntypedFormGroup(group);
  }

  getRiskTotals(id: string, commissionKeys, totalAllRisk,  state: DashboardCardState, isAddition: boolean) {
    isAddition
        ? totalAllRisk[0].idDashBoard.push(id)
        : totalAllRisk[0].idDashBoard.splice(totalAllRisk[0].idDashBoard.findIndex(x => x === id), 1);
    commissionKeys.forEach(key => {
      if (key !== commissionKeys[0]) {
        totalAllRisk[0][key] = totalAllRisk[0][key].toString().replace('.', '').replace(',', '.');
        if (isAddition) {
          if (key !== commissionKeys[5] && key !== commissionKeys[6]) {
            totalAllRisk[0][key] = Number(totalAllRisk[0][key]) + this.getSum(id, key,
                state.commissionsData.find(comm => comm.idDashBoard === id).risksDetail);
            totalAllRisk[0][key] = formatNumber(Number(totalAllRisk[0][key]), 'it', '1.2-2');
          } else {
            this.sumIntExtCommCollAmt(key, commissionKeys, totalAllRisk);
          }
        } else if (key !== commissionKeys[5] && key !== commissionKeys[6]) {
            totalAllRisk[0][key] = Number(totalAllRisk[0][key]) - this.getSum(id, key,
                state.commissionsData.find(comm => comm.idDashBoard === id).risksDetail);
            totalAllRisk[0][key] = formatNumber(Number(totalAllRisk[0][key]), 'it', '1.2-2');
        } else {
            this.sumIntExtCommCollAmt(key, commissionKeys, totalAllRisk);
        }
      }
    });
    return totalAllRisk;
  }

  sumIntExtCommCollAmt(key, commissionKeys, totalAllRisk) {
    if (key === commissionKeys[5]) {
      this.formatValue(totalAllRisk, key, 'intPurchaseCommAmt', 'intCollCommAmt');
    }
    if (key === commissionKeys[6]) {
      this.formatValue(totalAllRisk, key, 'extPurchaseCommAmt', 'extCollCommAmt');
    }
  }

  formatValue(totalAllRisk, key, value1, value2) {
    totalAllRisk[0][key] = Number(totalAllRisk[0][value1].replace('.', '').replace(',', '.'))
        + Number(totalAllRisk[0][value2].replace('.', '').replace(',', '.'));
    totalAllRisk[0][key] = formatNumber(Number(totalAllRisk[0][key]), 'it', '1.2-2');
  }

// Total risk detail
  getSum(id: string, key: string, risksDetail: DashboardRiskDetail[]): number {
    let sum = 0;
    if (risksDetail) {
      risksDetail.forEach(element => {
        sum += Number(element[key]);
      });
      return sum;
    }
  }

  // complexObj to string description => Table
  filterObjDescr(commissions: any): any {
    if (commissions && commissions.length > 0) {
      commissions.forEach(function(part, index) {
        // set status description
        if (this[index].status && this[index].status.description) {
          this[index].checkbox = this[index].idDashBoard + '_' + this[index].status.id;
          this[index].statusDescr = this[index].status.description;
        }
        if (this[index].subStatus && this[index].subStatus.description) {
          this[index].subStatusDescr = this[index].subStatus.description;
        }
        if (this[index].commCollDeltaAmt || this[index].commPurchDeltaAmt) {
          this[index].commDeltaAmt = 0;
          this[index].commDeltaAmt += this[index].commCollDeltaAmt ? parseFloat(this[index].commCollDeltaAmt) : 0;
          this[index].commDeltaAmt += this[index].commPurchDeltaAmt ? parseFloat(this[index].commPurchDeltaAmt) : 0;
          this[index].commDeltaAmt = formatNumber(this[index].commDeltaAmt, 'it', '1.2-2');
        } else {
          this[index].commDeltaAmt = formatNumber(0, 'it', '1.2-2');
        }
        if (this[index].extGrossPremiumAmt) {
          this[index].extGrossPremiumAmt = formatNumber(Number(this[index].extGrossPremiumAmt), 'it', '1.2-2');
        }

      }, commissions);
    } else {
      // Init empty table
      commissions = [];
    }

    return commissions;
  }

// Create Data for Table
  createData(data, state) {
    const commissions = state.commissionsData;
    data = this.filterObjDescr(commissions);
    return data;
  }
}

import {Observable, ReplaySubject} from 'rxjs';
import {ActiveDescendantKeyManager, Highlightable} from '@angular/cdk/a11y';
import {InjectionToken, QueryList} from '@angular/core';
import {delay, tap} from 'rxjs/operators';

export class RgiRxKeyActiveDescendantKeyManagerProvider<T = any> {
  private keyManagerSubject = new ReplaySubject<ActiveDescendantKeyManager<T>>();
  private keyManager: ActiveDescendantKeyManager<T>;

  set items(_items: QueryList<Highlightable & T> | (Highlightable & T)[]) {
    this.keyManager = new ActiveDescendantKeyManager(_items);
    this.keyManagerSubject.next(this.keyManager);
  }

  get activeDescendantKeyManager(): Observable<ActiveDescendantKeyManager<T>> {
    return this.keyManagerSubject.asObservable()
      .pipe(
        delay(0),
        tap(() => this.keyManagerSubject.complete())
      );
  }
}

export interface RgiRxOptionA11yConfig {
  useAriaPressed: boolean;
  role?: string;
}

export const RGI_RX_OPTION_A11Y_CONFIG = new InjectionToken<RgiRxOptionA11yConfig>('RGI_RX_OPTION_A11Y_CONFIG');

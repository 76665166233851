import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lpc-revisionary',
  templateUrl: './revisionary.component.html',
  styleUrls: ['./revisionary.component.css']
})
export class RevisionaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

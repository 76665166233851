export const DISCOUNT_FUND_SCHEMA = {
  rows: [

    {
      name: 'company', title: 'RE_ISSUE.COMPANY',
    },
    {
      name: 'code', title: 'RE_ISSUE.CODE',
    },
    {
      name: 'shareDiscountAmount', title: 'RE_ISSUE.SHARED_DISCOUNTS_AMOUNT',
    },
    {
      name: 'typeDiscountAmount', title: 'RE_ISSUE.DISCOUNT_TYPE',
    }
  ],
  header: ['company', 'code', 'shareDiscountAmount', 'typeDiscountAmount']
};











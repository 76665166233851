import {Component} from '@angular/core';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';

@Component({
  selector: 'rgi-gp-group-policy-pmdata-detail',
  templateUrl: './group-policy-pmdata-detail.component.html'
})
export class GroupPolicyPMDataDetailComponent extends GpInquiryComponentDetail {

  public displayedVarColumns: string[] = ['variable', 'variableVal'];

  constructor(protected stateMngr: GroupPolicyStateManagerInquiry) {
    super(stateMngr);
  }
}

import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    @Inject('claimsAlertService') private claimsAlertService: any
  ) { }

  message(message: string) {
    this.claimsAlertService.message(message);
  }

  translatedMessage(message: string) {
    this.claimsAlertService.translatedMessage(message);
  }
}

import { CustomNumberModule } from './../../../custom-number/custom-number.module';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormatPaymentDirective } from '../format-payment.directive';
import { InputPaymentComponent } from './input-payment.component';
import { AngularIbanModule } from 'angular-iban';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { LicRequiredModule } from './../../../utils/lic-required/lic-required.module';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';

@NgModule({
    declarations: [InputPaymentComponent, FormatPaymentDirective],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        AngularIbanModule,
        CustomNumberModule,
        RgiCountryLayerModule,
        LicRequiredModule,
        RgiRxFormElementsModule
    ],
    providers: [],
    exports: [InputPaymentComponent]
})
export class InputPaymentModule { }

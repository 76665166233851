import {ServiceInfo} from './api-service-info';

export class ApiSurveyUuid {
  constructor(
    public version: string
  ) {
  }
}

export class ApiSurveyFilter {
  constructor(
    public uuids: ApiSurveyUuid[]
  ) {
  }

  public static createEmptyObject(): ApiSurveyFilter {
    return new ApiSurveyFilter(null);
  }
}

export class ApiSurveyRequest {
  constructor(
    public filter: ApiSurveyFilter
  ) {
  }

  public static createEmptyObject(): ApiSurveyRequest {
    return new ApiSurveyRequest(ApiSurveyFilter.createEmptyObject());
  }
}

export class ApiSurveyQuestionnaireType {
  constructor(
    public code: string,
    public name: string,
    public id: string,
    public category: string
  ) {
  }
}

export class ApiSurveyQuestionnaire {
  constructor(
    public code: string,
    public pc: boolean,
    public name: string,
    public id: string,
    public questionnaireType: ApiSurveyQuestionnaireType,
    public life: boolean
  ) {
  }
}

export class ApiSurveyList {
  constructor(
    public questionnaire: ApiSurveyQuestionnaire,
    public isTest: boolean,
    public id: string,
    public name?: any,
    public codeFisc?: any
  ) {
  }
}

export class ApiSurveyResponse {
  constructor(
    public serviceInfo: ServiceInfo,
    public list: ApiSurveyList[]
  ) {
  }
}

export class ApiQuestionnaireResponse {
  constructor(
    public serviceInfo: ServiceInfo,
    public list: any[]
  ) {
  }
}

export class ApiSubjectResponse {
  constructor(
    public serviceInfo: ServiceInfo,
    public subject: any
  ) {
  }
}

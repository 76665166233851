<div [hidden]="!active">
  <lib-questionnaires-manager [attr.data-qa]="'questPostSales' + key" [key]="key"
                              customQuestLoader="BY_CODE"
                              class="col-lg-6"
                              [modalClass]="'life-quest-modal-flow'"
                              [questionnairesCode]="questionnairesCode"
                              (listLoad)="onListLoaded()" [f]="factors"
                              [dq]="disabledQuestions" [productStructure]="productStructure"
                              [d]="date" [p]="productCode" [q]="questType"
                              [sq]="defaultAnswers"
                              [savedIds]="savedIds"
                              (foundQuestionnaires)="onFoundQuestionnaires($event)"
                              (loaderQuest)="loaderQuestManager($event)"
                              [readOnlyCategories]="readOnlyCategories"
                              (successMessage)="onSuccessMessage($event)" #questionnaireManager></lib-questionnaires-manager>
</div>

<div *ngIf="!active" class="row">
  <div class="col-lg-6" *ngFor="let questionnaire of questionnaires">
    <div class="row">
      <div class="questionnaire-block-white col-xs-2" data-qa="questCompiled">
              <span class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok">
              </span>
      </div>
      <div class="col-xs-10 large questionnaire-block">
        <span [attr.data-qa]="questionnaire.id + 'quest_name'" translate>{{questionnaire.name}}</span>
      </div>
    </div>
  </div>
</div>


export interface DetailReopeningReactivation {
    dateReceipt: Date;
    note: string;
}
export const INIT_DETAIL_REOPEN_REACT: DetailReopeningReactivation = {
    dateReceipt: new Date(),
    note: '',
};

export interface ReasonReopening {
    code: string;
    description: string;
}

export interface IReasonReopening {
    receiptIdMotivoAnnullo: string;
    receiptDate: string;
    receiptIdRamoMinisteriale: string;
}

import {PortalNgCoreI18nKeys} from './i18n.keys';
export const IT: PortalNgCoreI18nKeys = {
  PORTAL_NG_CORE: {
    ERROR: 'Errore',
    INVALID_VALUES: 'I campi marcati con asterisco contenono valori non validi',
    NO_RESULTS: 'Nessun risultato.',
    WRONG_OBJECT_TYPE: 'errore di inizializzazione: oggetto non valido',
    WRONG_TYPE: 'errore di inizializzazione: tipo invalido'
  }
};

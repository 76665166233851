import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {PremiumDetailColumn} from '../../../resources/model/common/quotation/premium-detail';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rgi-pnc-totals-premium-modal',
  templateUrl: './pnc-totals-premium-modal.component.html'
})
export class PncTotalsPremiumModalComponent implements OnInit, OnModalClose {

  modalClose = new EventEmitter<any>();
  premiumDetails: PremiumDetailColumn[];

  constructor(@Optional() @Inject(DIALOG_DATA) data: PremiumDetailColumn[]) {
    if (data && data.length) {
      this.premiumDetails = data;
    } else {
      throw new Error('Premium detail modal is not correctly invoked, ensure to provide a PremiumDetail array');
    }
  }

  ngOnInit() {
  }

}

import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren, ElementRef,
  EventEmitter, HostListener, Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {map, switchMap, tap} from 'rxjs/operators';
import {merge} from 'rxjs';
import {RgiRxAutoCompleteContentDirective} from '../rgi-rx-auto-complete-content.directive';
import {RgiRxOptionComponent} from '../../form-elements/rgi-rx-option/rgi-rx-option.component';
import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';
import {RgiRxVirtualDOMError} from '@rgi/rx';
import {RgiRxKeyActiveDescendantKeyManagerProvider} from '../../a11y';

let _uniqueAutocompleteIdCounter = 0;

@Component({
  selector: 'rgi-rx-auto-complete',
  template: `
    <ng-template #root>
      <div #autoCompleteContainer [attr.role]="role" [id]="id" class="rgi-ui-auto-complete">
        <ng-container *ngIf="content">
          <ng-container *ngTemplateOutlet="content.template"></ng-container>
        </ng-container>
        <ng-content></ng-content>
      </div>
    </ng-template>
  `
})
export class RgiRxAutoCompleteComponent implements AfterContentInit {
  @ViewChild('root', {static: true}) rootTemplate: TemplateRef<any>;
  @ViewChild('autoCompleteContainer', {static: false}) autoCompleteContainer: ElementRef;

  @ContentChildren(RgiRxOptionComponent) options: QueryList<RgiRxOptionComponent<any>>;

  @ContentChild(RgiRxAutoCompleteContentDirective, {static: true}) content: RgiRxAutoCompleteContentDirective;

  /**
   * Trigger when an option has been clicked
   */
  @Output() onChange = new EventEmitter<any>();

  @Input() role = 'listbox';


  id = `rgi-rx-auto-complete-${_uniqueAutocompleteIdCounter++}`;
  private _keyManager: ActiveDescendantKeyManager<RgiRxOptionComponent<any>>;

  constructor(@Optional() private keyManagerProvider: RgiRxKeyActiveDescendantKeyManagerProvider) {
  }


  ngAfterContentInit(): void {
    if (!this.content) {
      throw new RgiRxVirtualDOMError('rgi-rx-auto-complete requires an ng-template with rgiRxAutoCompleteContent!');
    }
    if (this.keyManagerProvider) {
      this.keyManagerProvider.activeDescendantKeyManager.subscribe(
        next => this._keyManager = next.withWrap().withVerticalOrientation().skipPredicate(
          item => item.hidden
        )
      );
    } else {
      this._keyManager = new ActiveDescendantKeyManager(this.options).withWrap().withVerticalOrientation().skipPredicate(
        item => item.hidden
      );
    }
  }


  get keyManager(): ActiveDescendantKeyManager<RgiRxOptionComponent<any>> {
    return this._keyManager;
  }

  hasOptionWithValue(value: string) {
    return this.options.find(opt => opt.value === value);
  }

  onOptionClick$() {
    return this.options.changes.pipe(
      switchMap(options => {
        const value$ = options.map(option => option.value$);
        return merge(...value$);
      }),
      map(value => {
        return this.options.find(opt => opt.value === value);
      }),
      tap(option => {
        this.onChange.next(option.value);
      })
    );
  }

  setCurrentActiveItem(option: RgiRxOptionComponent<any>) {
    const index = this.options.toArray().indexOf(option);
    this.keyManager.setActiveItem(index);
  }
}

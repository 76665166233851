export class ApiContractNumber {

  public proposalNumber: string;
  public policyNumber: string;
  public createCopy?: boolean;
  public subClusterCode?: string;
  public subAssetCode?: string;

  constructor() {
  }
}

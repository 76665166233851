import {ActiveRoute} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {of} from 'rxjs';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {DatesPayload} from '../../group-policy-models/group-policy-vcont-date';
import {GroupPolicyStatelessOpDateService} from './group-policy-stateless-op-date.service';
import {GroupPolicyStateVcontDate} from '../group-policy-state';

/**
 * @author: dmasone
 * @description: State Manager used for render vcont date step
 */
export class GroupPolicyStateManagerDate extends AbstractStateManager<GroupPolicyStateVcontDate> {

  constructor(activeRoute: ActiveRoute,
              stateStoreService: StateStoreService,
              protected statelesOps: GroupPolicyStatelessOpDateService) {
        super(activeRoute, stateStoreService);
        const statePolicyData = this.stateStoreService.has(this.activeRoute.key) ?
            this.stateStoreService.get<GroupPolicyStateVcontDate>(this.activeRoute.key) :
            new GroupPolicyStateVcontDate(this.activeRoute.key);
        const initial$ = this.statelesOps.initDate$(of(statePolicyData), this.activeRoute);
        this.updateState$(initial$);
    }

    public actionGoForword(payload: DatesPayload[]) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionGoForword$(of(st), st.uuid, this.activeRoute, payload));
    }

    public actionClose(previousStepData: DataForSteps) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionClose$(of(st), previousStepData, this.activeRoute));
    }

}

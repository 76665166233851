import { Component, EventEmitter, Inject, Optional } from '@angular/core';
import { AnagRating } from '../../../anag-model/anag-domain/anag-rating';
import { RATING_HISTORY_LIST_MODAL_TABLE_SCHEMA } from '../../../anag-constants/rating-history-list-modal-tableschema';
import { AnagStatelessService } from '../../../anag-resources/anag-stateless.service';
import { DatePipe } from '@angular/common';
import { DIALOG_DATA, TableSchema } from '@rgi/rx/ui';
import { AnagStateManagerPartyEditor } from '../../../anag-states/party-editor/anag-state-manager-party-editor';

@Component({
  selector: 'rgi-anag-rating-history-modal',
  templateUrl: './anag-rating-history-modal.component.html',
  styleUrls: ['./anag-rating-history-modal.component.css']
})
export class AnagRatingHistoryModalComponent {

  ratingHistory: AnagRating[];
  ratingHistoryTableData = [];
  modalClose: EventEmitter<any> = new EventEmitter<any>();
  schema: TableSchema;

  constructor(public statelessService: AnagStatelessService,
              public datePipe: DatePipe,
              public stateMgr: AnagStateManagerPartyEditor,
              @Optional() @Inject(DIALOG_DATA) data: AnagRating[]) {
        this.ratingHistory = data;
  }

  ngOnInit() {
    this.translation();
    this.createSchema();
    this.createData(this.ratingHistory);
  }

  translation() {
  }

  actionClose() {
    this.modalClose.emit();
  }

  createSchema() {
    this.schema = {
      rows: [
      ],
      header: []
    };
    for (let i=0; i < RATING_HISTORY_LIST_MODAL_TABLE_SCHEMA.header.length; i++ ) {
      if (this.isFieldVisible(RATING_HISTORY_LIST_MODAL_TABLE_SCHEMA.header[i])) {
        this.schema.header.push(RATING_HISTORY_LIST_MODAL_TABLE_SCHEMA.header[i]);
        this.schema.rows.push(RATING_HISTORY_LIST_MODAL_TABLE_SCHEMA.rows[i]);
      }
    }
  }

  createData(ratingHistory: AnagRating[]) {
    return this.ratingHistoryTableData = ratingHistory.map(row => {
      let data = {};
      if (this.isFieldVisible('rating')) {
        data['rating'] = row.rating ? row.rating.descrizione : '-';
      }
      if (this.isFieldVisible('ratingPeriod')) {
        data['ratingPeriod'] = row.ratingPeriod ? this.datePipe.transform(new Date(row.ratingPeriod), this.localFormat()) : '-';
      }
      if (this.isFieldVisible('user')) {
        data['user'] = row.user ? row.user : '-';
      }
      if (this.isFieldVisible('origin')) {
        data['origin'] =  row.origin ? row.origin.descrizione : '-';
      }
      return data;
    });
  }

  isFieldVisible(fieldName: string): boolean {
    return this.stateMgr.isRatingFieldVisible(fieldName);
  }

  localFormat() {
    return this.statelessService.getLocaleDateFormat();
  }
}

<div class="core-header-icon re-issue-card-styles">
  <span class="rgi-re-header-icon rgi-re-icon-notes"></span>
  <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.NOTES</span>
  <span class="pull-right header-text"> </span>
</div>
<br>

<form [formGroup]="notesForm">
  <div class="rgi-ui-grid-2">
      <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <textarea rgiRxInput formControlName="text" maxlength="250" data-qa="notes"></textarea>
          </rgi-rx-form-field>
      </div>
  </div>
</form>

import { PortalCard, PortalCardConfig } from '@rgi/rx/portal';
import { OccurrenceDataModifyComponent } from './occurrence-data-modify.component';

export const cardOccurrenceDataModify: PortalCardConfig = {
    card: {
        title: '_EDIT_CLAIMS',
        type: 'sub',
        category: 'training',
        name: 'claimsClaimEdit',
        navigationInSession: true,
        subCards: [],
        subCardsActive: []
    } as PortalCard,
    routes: [
        {
            route: 'editClaim',
            destination: 'claimsClaimEdit', // la rappresentazione data nel router nuovo
        },
    ]
};

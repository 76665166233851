import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {CompensationUtilityService} from '../compensation-utility.service';
import {CardComponent} from '@rgi/digital-claims-common-angular';
import {RoutingService} from '@rgi/portal-ng-core';
import {CompensationEntity} from '../../new-payment/domain/compensation-entity';
import {CompensationBoardComponent} from '../compensation-board/compensation-board.component';
import {FormBuilder, FormControl, FormGroup, Validators, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {TableSchema} from '@rgi/rx/ui';

import {CompensationDiaryNoteEntity} from '../dto/compensation-diary-note-entity';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-compensation-diary',
  templateUrl: './compensation-diary.component.html',
  styleUrls: ['./compensation-diary.component.scss']
})
export class CompensationDiaryComponent extends CardComponent implements OnInit {
  routes: any = null;
  selectedCompensation: CompensationEntity;
  diaryNotes: CompensationDiaryNoteEntity[];
  public formGroupNote: UntypedFormGroup;
  error: boolean = false;
  errorMsg: string;


  constructor(
    @Inject('eventService') private eventService: any,
    @Inject('compensationBoardComponent') compensationBoardCompoent,
    public translateService: RgiRxTranslationService,
    private utilityService: CompensationUtilityService,
    private routingService: RoutingService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
    this.routes = {
      board: compensationBoardCompoent,
    };
  }

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.selectedCompensation = this.utilityService.getSelectedCompensation();
    this.diaryNotes = this.utilityService.getSelectedCompensationDiaryNotes();
    this.formGroupNote = this.formBuilder.group({
      text: ['', Validators.required]
    });
  }


  closeModal(event) {
    this.utilityService.getEventEmitter().emit('chiusuraModale');
    event.stopPropagation();
    return false;
  }

  handleGoBack() {
    this.utilityService.setSelectedCompensation(null);
    this.eventService.broadcastEvent('start-loader');
    this.routingService.loadComponent(this.routes.board);
    (this.routingService.componentRef.instance as CompensationBoardComponent).card = this.card;
    this.eventService.broadcastEvent('stop-loader');
  }


  reverseOrderNotes() {
    this.diaryNotes.reverse();
  }

  handleAddNote() {

    // on db CTESTO is 1024
    if (this.formGroupNote.get('text').value.length > 1023) {
      this.error = true;
      this.translateService.translate('_CLAIMS_._MESSAGE_._CHARACTER_LIMIT_EXCEEDED').subscribe(res=> {
        this.errorMsg=res;
      }
      )
      return;
    } else {
      this.errorMsg = '';
      this.error = false;
    }

    if (this.formGroupNote.get('text').value) {
      this.eventService.broadcastEvent('start-loader');
      // perform request
      this.utilityService.addCompensationDiaryNote(this.formGroupNote.get('text').value).subscribe(
        (res: any) => {
          if (res.code === '1') {
            // clean up error
            this.error = false;
            this.errorMsg = '';
            // perform request
            this.utilityService.getCompensationDiaryNotes().subscribe(res2 => {
              const tmpList = [];
              res2.forEach(o => {
                const tmpNote: CompensationDiaryNoteEntity = new CompensationDiaryNoteEntity();
                tmpNote.user = o.user;
                tmpNote.text = o.noteText;
                tmpNote.datetime = o.datetime;
                tmpNote.idCompensation = o.idCompensation;
                tmpList.push(tmpNote);
              });
              // update notes
              this.utilityService.setSelectedCompensationDiaryNotes(tmpList);
              this.diaryNotes = tmpList;

              this.formGroupNote.setControl('text', new FormControl(''));
              this.eventService.broadcastEvent('stop-loader');
            });
          } else {
            this.error = true;
            this.translateService.translate('_CLAIMS_._MESSAGE_._AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE').subscribe(res=> {
              this.errorMsg=res;
            }
            )
            this.eventService.broadcastEvent('stop-loader');
          }
        }
      );
    }
  }

}

<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="true" #first color="info">
    <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-12 col-md-12">
                <label ng-bind="label">
                    {{ '_CLAIMS_._DAMAGED_PARTS' | translate}}
                </label>
            </div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="demagedPartsTemplate"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion">
    <div class="row rgi-claims-sub-title-section-shared">
        <div class="col-xs-12 col-md-12">
            <label ng-bind="label">
                {{ '_CLAIMS_._DAMAGED_PARTS' | translate }}
            </label>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="demagedPartsTemplate"></ng-container>
</div>

<ng-template #demagedPartsTemplate>
    <div class="row" *ngIf="viewForm">
        <div class="col-sm-6 col-md-6">
            <div class="row">
                <div class="col-sm-12 col-md-5 col-lg-4">
                    <div class="image-container" *ngIf="damagedVehicle">
                        <span class="row">
                            <img [src]="damagedVehicle.top.damaged ? damagedVehicle.top.imgKO : damagedVehicle.top.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.top)">
                        </span>
                        <span class="row">
                            <img [src]="damagedVehicle.topLeft.damaged ? damagedVehicle.topLeft.imgKO : damagedVehicle.topLeft.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.topLeft)">
                            <img [src]="damagedVehicle.topRight.damaged ? damagedVehicle.topRight.imgKO : damagedVehicle.topRight.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.topRight)">
                        </span>
                        <span class="row">
                            <img [src]="damagedVehicle.middleLeft.damaged ? damagedVehicle.middleLeft.imgKO : damagedVehicle.middleLeft.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.middleLeft)">
                            <img [src]="damagedVehicle.middleRight.damaged ? damagedVehicle.middleRight.imgKO : damagedVehicle.middleRight.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.middleRight)">
                        </span>
                        <span class="row">
                            <img [src]="damagedVehicle.bottomLeft.damaged ? damagedVehicle.bottomLeft.imgKO : damagedVehicle.bottomLeft.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.bottomLeft)">
                            <img [src]="damagedVehicle.bottomRight.damaged ? damagedVehicle.bottomRight.imgKO : damagedVehicle.bottomRight.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.bottomRight)">
                        </span>
                        <span class="row">
                            <img [src]="damagedVehicle.bottom.damaged ? damagedVehicle.bottom.imgKO : damagedVehicle.bottom.imgOK"
                                (click)="toggleDamagedPart(damagedVehicle.bottom)">
                        </span>
                    </div>
                </div>
                <div class="col-sm-8 col-md-7 col-lg-8 d-flex">
                    <div class="col-md-12 border" *ngIf="!!propertyAddress?.city || 
                    !!propertyAddress?.province || 
                    !!propertyAddress?.postCode || 
                    propertyAddress?.toponym || 
                    !!propertyAddress?.address">
                        <div>
                            <strong>{{ '_CLAIMS_._PROPERTY_ADDRESS' | translate }}:</strong>
                            <span *ngIf="propertyAddress?.toponym" class="noComma"> {{ propertyAddress.toponym.description }}</span>
                            <span *ngIf="!!propertyAddress?.address"> {{ propertyAddress.address }}</span>
                            <span *ngIf="!!propertyAddress?.houseNumber"> {{ propertyAddress.houseNumber }}</span>
                            <span *ngIf="!!propertyAddress?.city"> {{ propertyAddress.city }}</span>
                            <span *ngIf="!!propertyAddress?.province"> {{ propertyAddress.province }}</span>
                            <span *ngIf="!!propertyAddress?.postCode"> {{ propertyAddress.postCode }}</span>
                            <span *ngIf="!!propertyAddress?.country"> {{ propertyAddress.country }}</span>
                            <span *ngIf="!!propertyAddress?.countryCode"> {{ propertyAddress.countryCode }}</span>
                        </div>
                        <div>
                            <button rgi-rx-button class="pd-0" (click)="deleteAddress()">
                              <span class="rgi-ui-icon rgi-rx-drag-delete rgi-ui-icon-delete"></span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button rgi-rx-button color="secondary" (click)="openModalAddAddress()">
                            {{ '_CLAIMS_._PROPERTY_ADDRESS' | translate }}
                            <span
                                [ngClass]="!!propertyAddress?.city || 
                                !!propertyAddress?.province || 
                                !!propertyAddress?.postCode || 
                                propertyAddress?.toponym || 
                                !!propertyAddress?.address ? 'glyphicon glyphicon-pencil' : 'glyphicon glyphicon-search'"></span>
                        </button>
                    </div>
                    <div>
                        <form [formGroup]="damagedVehicleForm" (change)="sendDamageVehicleDataFunction()" class="d-flex">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <rgi-rx-form-field>
                                            <input type="checkbox" rgiRxInput [checked]="totalDistruction"
                                                (change)="toggleTotalDistruction('TotalDistruction')"
                                                formControlName="totalDistruction">
                                            <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._TOTAL_DISTRUCTION' |translate
                                                }}</label>
                                        </rgi-rx-form-field>

                                    </div>
                                    <div class="col-md-6">
                                        <rgi-rx-form-field>
                                            <input type="checkbox" rgiRxInput
                                                (change)="toggleTotalDistruction('OtherDistruction')"
                                                formControlName="otherInvVehicles">
                                            <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._OTHER' |translate }}</label>
                                        </rgi-rx-form-field>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <rgi-rx-form-field>
                                    <textarea rgiRxInput formControlName="visibleDamages" maxlength="250"></textarea>
                                    <label rgiRxLabel>{{'_CLAIMS_._VISIBLE_DAMAGE_ON_VEHICLE' | translate}}</label>
                                </rgi-rx-form-field>
                            </div>
                            <div class="col-md-12">
                                <rgi-rx-form-field>
                                    <textarea rgiRxInput formControlName="observation"
                                        maxlength="250"></textarea>
                                    <label rgiRxLabel> {{'_CLAIMS_._OBSERVATIONS' | translate}}</label>
                                </rgi-rx-form-field>
                            </div>
                            <div class="col-md-12">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel>{{'_CLAIMS_._BLACK_BOX_COHERENCE' | translate}}</label>
                                    <select rgiRxNativeSelect formControlName="damageOption"
                                        (ngModelChange)="getVehicleOption($event)">
                                        <option [value]=""></option>
                                        <option *ngFor="let option of options" [value]="option.codice">{{
                                            option.descrizione |translate }}</option>
                                    </select>
                                </rgi-rx-form-field>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
import {SurveyVersionI} from "../../models/survey-version";
import {Source} from "@rgi/ng-passpropro/product";

class SurveySearchFilter {
  name?:string;
  startDate?: string;
  endDate?: string;
  uuids?: Array<string>;
  isTest: boolean;
}
class SurveyEvaluateFilter{
    surveyVersion: SurveyVersionI;
    idCommercialAgreement: number;
    showResults: boolean;
    product: Array<string>;
    needs: Array<string>;
    sources: Array<Source>
}

class SurveyExportFilter {
    uuid: string;
    parameters?: Array<SurveyCustomProperty>;
}

class SurveyCustomProperty {
    key: string;
    value: string;
}

class PrintParameters {
  parameters : {};
}


export {
  SurveySearchFilter,
  SurveyEvaluateFilter,
  SurveyExportFilter,
  SurveyCustomProperty,
  PrintParameters
}

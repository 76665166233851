export const MULTIPLE_SUBSTITUTION_SCHEMA = {
    rows: [
        {
            name: 'main',
            title: 'RE_ISSUE.MAIN',
        },
        {
            name: 'policyNumber',
            title: 'RE_ISSUE.POLICY_NUMBER',
        },
        {
            name: 'description',
            title: 'RE_ISSUE.DESCRIPTION',
        },
        {
            name: 'contractingSubject',
            title: 'RE_ISSUE.POLICYHOLDER',
        },
        {
            name: 'state',
            title: 'RE_ISSUE.POLICY_STATE',
        },
        {
          name: 'effectDate',
          title: 'RE_ISSUE.EFFECTIVE_DATE',
          format: {
            pipe: 'date',
            args: ['dd/MM/yyyy']
          }
        },
        {
          name: 'expirationDate',
          title: 'RE_ISSUE.EXPIRE_DATE',
          format: {
            pipe: 'date',
            args: ['dd/MM/yyyy']
          }
        },
        {
            name: 'actions',
            title: '',
            actions: [
              {
                name: 'DELETE',
                styleClass: 'rgi-ui-icon-delete'
              }
            ]
        }
    ],
    header: ['main', 'policyNumber', 'description', 'contractingSubject','state', 'effectDate', 'expirationDate', 'actions']
};

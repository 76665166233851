import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExpertUtilityService } from '../expert-utility-service.service';
import { CardComponentService } from '@rgi/digital-claims-common-angular';
import { ExpertDetailEntity } from '../dto/expert-detail-entity';
import {
  ModalComponent,
  ModalService, OnModalClose,
  RgiRxDatatableRowAction,
  RgiRxDatatableSelectionChange,
  TableSchema,
  TableSchemaFormat
} from '@rgi/rx/ui';
import { ExpertEntity } from '../dto/expert-entity';
import { RoutingService } from '@rgi/portal-ng-core';
import { ExpertsListComponent } from '../experts-list/experts-list.component';
import { PushMessageHandlerService } from '@rgi/rx';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

// dialog component delete
@Component({
  selector: 'claims-ui-modal-dialog-demo',
  templateUrl: './claims-ui-modal-dialog-demo.component.html',
})
export class UiModalDialogDeleteLinkComponent implements OnModalClose {
  modalClose: EventEmitter<any>;
  @Output() public modalDeleteEmitter: EventEmitter<any> = new EventEmitter<any>();

  onCloseModalDeleteLink() {
    console.log('ON CLOSE MODAL DELETE LINK');
    this.modalDeleteEmitter.emit({ modalMsg: 'MODAL_CLOSED' });
  }

  onDeleteLinkedExpert() {
    console.log('ON DELETE LINKED EXPERT');

    this.modalDeleteEmitter.emit({ modalMsg: 'MODAL_DELETE' });
  }
}


@Component({
  selector: 'claims-ui-modal-dialog-new-link',
  templateUrl: './claims-ui-modal-dialog-new-link.component.html',
})
export class UiModalDialogNewLinkComponent implements OnModalClose, OnInit {

  modalClose: EventEmitter<any>;

  constructor(
    private utilityServ: ExpertUtilityService,
    public formBuilder: UntypedFormBuilder,
    @Inject('eventService') private eventService: any,
    public translateService: RgiRxTranslationService
  ) {

    const obsCode$ = this.translateService.translate('_CLAIMS_._CODE');
    const obsDenomination$ = this.translateService.translate('_CLAIMS_._DENOMINATION');
    const obsSubjectIdentity$ = this.translateService.translate('_CLAIMS_._SUBJECT_IDENTITY');
    const expertType$ = this.translateService.translate('_CLAIMS_._EXPERT_TYPE');
    const obsTypeProvider$ = this.translateService.translate('_CLAIMS_._TYPE_PROVIDER');
    combineLatest([
      obsCode$,
      obsDenomination$,
      obsSubjectIdentity$,
      expertType$,
      obsTypeProvider$,
    ]).subscribe(
      ([code, denomination, subjectIdentity, expertType, typeProvider]) => {
        this.schema = {
          rows: [
            {
              name: 'code',
              title: code,
              format: {
                pipes: [
                  {
                    pipe: 'uppercase'
                  }
                ]
              },
              description: '',
              styleClass: 'my-code-class',
            },
            {
              name: 'nameBySubjectType',
              title: denomination,
              format: {
                pipes: [
                  {
                    pipe: 'uppercase'
                  }
                ]
              },
              description: '',
              styleClass: 'my-name-class',
              styles: {
                'long-name': {
                  assert: {
                    path: 'name',
                    value: /.{5,}/,
                    condition: 'match'
                  }
                }
              }
            },
            {
              name: 'subjectKeyWithDescription',
              title: subjectIdentity,
            },
            {
              name: 'expertTypeDescription',
              title: expertType,
            },
            {
              name: 'expertTypeProviderDescription',
              title: typeProvider,
            },
          ],
          header: ['code', 'nameBySubjectType', 'subjectKeyWithDescription', 'expertTypeDescription', 'expertTypeProviderDescription'],
        };
      })
  }

  @Output() public modalNewLinkEmitter: EventEmitter<any> = new EventEmitter<any>();

  public searchForm: UntypedFormGroup;
  public formConfig: any = {};
  public showError: boolean = false;
  public showNoExpertsFound: boolean = false;
  public isLegalSubject: boolean = false;
  public isUmaUniqueKeyManagementEnabled;
  public expertLinkedSearchList: ExpertEntity[];
  public expert: ExpertDetailEntity;
  public selections = new Map();
  public umaConfigKeys: any; // array of uma configs (PSSINCONFKEY)
  public linkedExperts: ExpertEntity[];
  public schema: TableSchema;

  ngOnInit(): void {
    this.expert = this.utilityServ.getExpertDetail();
    this.linkedExperts = this.utilityServ.getLinkedExpertsDetail();
    this.searchForm = this.formBuilder.group({
      country: ['IT'],
      subjectType: ['1'],
      expertCode: [],
      name: [],
      surname: [],
      businessName: [],
      vatNumber: [],
      fiscalCode: [],
      expertType: [],
    });

    // subscribe to subjectType changes, determines disabled behaviour of input name/surname -- businessName
    this.searchForm.controls.subjectType.valueChanges.subscribe(value => {
      if (value === '1') {
        this.isLegalSubject = false;
        this.searchForm.controls.businessName.setValue(null);
      } else if (value === '2') {
        this.isLegalSubject = true;
        this.searchForm.controls.name.setValue(null);
        this.searchForm.controls.surname.setValue(null);
      }
    });


    this.utilityServ.getConfigExpertsFilter().subscribe(
      (res: any) => {
        console.log('getConfigExpertsFilter INSIDE DETAIL --> ', res);
        this.isUmaUniqueKeyManagementEnabled = res.umaUniqueKeyManagementEnabled;
        this.formConfig.subjectTypes = res.subjectTypes;
        this.formConfig.expertTypes = res.expertTypes;
        this.eventService.broadcastEvent('stop-loader');
        //  depending on uma enabled or not
        if (this.isUmaUniqueKeyManagementEnabled) {
          // countries are handled only if uma enabled
          // add form fields
          for (const cn of res.countries) {
            const countryCode: string = cn.code;
            // giuridico key 1
            this.searchForm.addControl(countryCode + '-G-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key1
            this.searchForm.addControl(countryCode + '-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key 2
            this.searchForm.addControl(countryCode + '-G-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key2
            this.searchForm.addControl(countryCode + '-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key3
            this.searchForm.addControl(countryCode + '-G-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key3
            this.searchForm.addControl(countryCode + '-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
          }
          this.formConfig.countries = res.countries;
          this.umaConfigKeys = res.umaConfigKeysMap.umaConfigKeysByCountry;


        }

      });
  }

  onEmpty() {
    this.searchForm.reset();
    this.expertLinkedSearchList = [];
    this.utilityServ.setExpertLinkedSearchList([]);
    this.showError = false;
    this.showNoExpertsFound = false;
    this.isLegalSubject = false;
    this.selections = new Map();
  }

  // triggered when inside the NEW LINK EXPERT modal, upon selecting one or multiple entries in the list of experts
  onSelectChange($event: RgiRxDatatableSelectionChange<any>) {
    if ($event.removed.length > 0) {
      const tmp: ExpertEntity = $event.removed[0];
      this.selections.delete(tmp.id);
    }
    if ($event.added.length > 0) {
      const tmp: ExpertEntity = $event.added[0];
      this.selections.set(tmp.id, tmp);
    }
  }

  onFind() {
    this.utilityServ.setExpertLinkedSearchList([]);
    this.expertLinkedSearchList = [];
    this.showNoExpertsFound = false;
    // check if one of Codice,Nome, Cognome, CF, Partita iva has value
    if ((!this.searchForm.value.name || !this.searchForm.value.name.trim().length)
      && (!this.searchForm.value.surname || !this.searchForm.value.surname.trim().length)
      && (!this.searchForm.value.expertCode || !this.searchForm.value.expertCode.trim().length)
      && (!this.searchForm.value.vatNumber || !this.searchForm.value.vatNumber.trim().length)
      && (!this.searchForm.value.fiscalCode || !this.searchForm.value.fiscalCode.trim().length)
      // TODO : consider UMA cases where fields may be required
      // && (this.isUmaUniqueKeyManagementEnabled && (this.searchForm.value.))
    ) {
      this.showError = true;
    } else {
      this.showError = false;
      // perform search
      this.searchForm.addControl('isUmaUniqueKeyEnabled',
        new UntypedFormControl(this.isUmaUniqueKeyManagementEnabled, Validators.nullValidator));
      this.utilityServ.setLinkExpertsSearchFormGroup(this.searchForm);
      // determine which of the form values are actually relevant
      // this.session.open('claimsExperts', 'home', '', true);
      this.utilityServ.getExpertLinkSearchResult().subscribe((res: any[]) => {
        if (res.length > 0) {
          for (const x of res) {
            // DO NOT INCLUDE
            // - the expert currently viewed in detail
            // - already linked experts
            if (x.id === this.expert.id) {
              continue;
            }
            if (this.linkedExperts.find(exp => (exp.id === x.id))) {
              continue;
            }

            const tmp: ExpertEntity = new ExpertEntity();
            tmp.abi = x.abi;
            tmp.isAvailableCarCollection = x.availableCarCollection;
            tmp.isAvailableReplacementCar = x.availableReplacementCar;
            tmp.bankAccount = x.bankAccount;
            tmp.cabi = x.cabi;
            tmp.carBrandDescription = x.carBrandDescription;
            tmp.code = x.code;
            tmp.countryCode = x.countryCode;
            tmp.expertType = x.expertType;
            tmp.expertTypeProvider = x.expertTypeProvider;
            tmp.expertTypeDescription = x.expertTypeDescription;
            tmp.fiscalCode = x.fiscalCode;
            tmp.id = x.id;
            tmp.maxNumAssignments = x.maxNumAssignments;
            tmp.name = x.name;
            tmp.percCash = x.percCash;
            tmp.percVAT = x.percVAT;
            tmp.percWithHolding = x.percWithHolding;
            tmp.isPublicDepartment = x.publicDepartment;
            tmp.subjectBusinessName = x.subjectBusinessName;
            tmp.subjectKey1 = x.subjectKey1;
            tmp.subjectKey2 = x.subjectKey2;
            tmp.subjectKey3 = x.subjectKey3;
            tmp.surname = x.surname;
            tmp.taxRegimeDescription = x.taxRegimeDescription;
            tmp.taxRegimeId = x.tagRegimeId;
            tmp.vatNumber = x.vatNumber;
            tmp.expertTypeDescription = x.expertTypeDescription;
            tmp.expertTypeProviderDescription = x.expertTypeProviderDescription;
            tmp.subjectKeyWithDescription = x.subjectKeyWithDescription;
            tmp.nameBySubjectType = x.nameBySubjectType;
            this.utilityServ.getExpertLinkedSearchList().push(tmp);
          }
          this.expertLinkedSearchList = this.utilityServ.getExpertLinkedSearchList();
          // limit case : there are results, but they are already listed as linked experts
          if (!this.expertLinkedSearchList.length) {
            this.showNoExpertsFound = true;
          }
          // console.log(this.expertLinkedSearchList);
        } else {
          this.showNoExpertsFound = true;
        }
      });
    }
  }


  onCloseModal() {
    this.onEmpty();
    this.modalNewLinkEmitter.emit({ modalMsg: 'MODAL_CLOSED' });
  }

  onSaveNewLinkedExpert() {
    this.modalNewLinkEmitter.emit({ modalMsg: 'MODAL_SAVE', selections: this.selections });
  }


}


@Component({
  selector: 'claims-expert-details',
  templateUrl: './expert-details.component.html',
  styleUrls: ['./expert-details.component.scss']
})
export class ExpertDetailsComponent implements OnInit, OnDestroy {
  public routes: any = null;

  constructor(
    cardComponentService: CardComponentService,
    @Inject('sessionService') private session: any,
    @Inject('coreResult') private coreResult,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    @Inject('enumService') private enumService: any,
    @Inject('sessionService') private sessionServiceInject: any,
    @Inject('expertsListComponent') expertsListComponent,
    private routingService: RoutingService,
    public formBuilder: UntypedFormBuilder,
    private utilityServ: ExpertUtilityService,
    private modalService: ModalService,
    private pushMessageHandler: PushMessageHandlerService,
    public translateService: RgiRxTranslationService
    // private serviceSnackbar: RgiRxSnackbarOverlay

  ) {
    this.routes = {
      list: expertsListComponent,
    };

    const obsCode$ = this.translateService.translate('_CLAIMS_._CODE');
    const obsName$ = this.translateService.translate('_CLAIMS_._NAME');
    const obsSurname$ = this.translateService.translate('_CLAIMS_._SURNAME');
    const obsSubjectIdentity$ = this.translateService.translate('_CLAIMS_._SUBJECT_IDENTITY');
    const obsExpertType$ = this.translateService.translate('_CLAIMS_._EXPERT_TYPE');
    const obsExpTypeProvider$ = this.translateService.translate('_CLAIMS_._EXPERT_TYPE_PROVIDER');
    combineLatest([
      obsCode$,
      obsName$,
      obsSurname$,
      obsSubjectIdentity$,
      obsExpertType$,
      obsExpTypeProvider$,
    ]).subscribe(
      ([code, name, surname, subjectIdentity, expertType, expTypeProvider]) => {
        this.schema = {
          rows: [
            {
              name: 'code', title: code,
              format: {
                pipes: [
                  {
                    pipe: 'uppercase'
                  }
                ]
              },
              description: '',
              styleClass: 'my-code-class',
            },
            {
              name: 'name', title: name,
              format: {
                pipes: [
                  {
                    pipe: 'uppercase'
                  }
                ]
              },
              description: '',
              styleClass: 'my-name-class',
              styles: {
                'long-name': {
                  assert: {
                    path: 'name',
                    value: /.{5,}/,
                    condition: 'match'
                  }
                }
              }
            },
            {
              name: 'surname',
              title: surname,
              format: {
                pipes: [
                  {
                    pipe: 'uppercase'
                  },
                ]
              }
              , description: ''
            },
            {
              name: 'key1',
              title: subjectIdentity,
            },
            {
              name: 'expertType',
              title: expertType,
            },
            {
              name: 'expertTypeProvider',
              title: expTypeProvider,
              format: {} as TableSchemaFormat
            },
          ],
          header: ['code', 'name', 'surname', 'key1', 'expertType', 'expertTypeProvider'],
        };
      })
  }

  @Input()
  card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  public detailForm: UntypedFormGroup;
  public searchForm: UntypedFormGroup;
  public formConfig: any = {};
  public successfulAddExpertOperation: boolean = true;
  public showError: any = false; // boolean to show hidden div in case user performs search without valued fields
  // triggered when button FIND is clicked on search form
  public showNoExpertsFound: boolean = false; // show if upon clicking on 'find', no records are returned
  public isLegalSubject: any = false;
  public isUmaUniqueKeyManagementEnabled;
  public umaConfigKeys: any; // array of uma configs (PSSINCONFKEY)
  public modalDeleteLink: { modal: ModalComponent; component: UiModalDialogDeleteLinkComponent };
  public modalSearchNewLink: { modal: ModalComponent; component: UiModalDialogNewLinkComponent };
  public openModalDelete: boolean = false;

  public expertLinkedSearchList: ExpertEntity[];
  public linkedExperts: ExpertEntity[];
  public expert: ExpertDetailEntity;
  public selections = new Map();
  // id of expert link that has been chosen to be deleted
  public selectedExpertIdLinkToDelete: string;

  public schema: TableSchema;


  ngOnInit() {
    // initially null, will be reset to null in case the user decides not to remove the expert association
    this.selectedExpertIdLinkToDelete = null;

    this.expert = this.utilityServ.getExpertDetail();

    this.utilityServ.getConfigExpertsFilter().subscribe(
      (res: any) => {
        console.log('getConfigExpertsFilter INSIDE DETAIL --> ', res);
        this.isUmaUniqueKeyManagementEnabled = res.umaUniqueKeyManagementEnabled;
        this.formConfig.subjectTypes = res.subjectTypes;
        this.formConfig.expertTypes = res.expertTypes;
        this.eventService.broadcastEvent('stop-loader');
        //  depending on uma enabled or not
        if (this.isUmaUniqueKeyManagementEnabled) {
          // countries are handled only if uma enabled
          // add form fields
          for (const cn of res.countries) {
            const countryCode: string = cn.code;
            // giuridico key 1
            this.searchForm.addControl(countryCode + '-G-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key1
            this.searchForm.addControl(countryCode + '-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key 2
            this.searchForm.addControl(countryCode + '-G-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key2
            this.searchForm.addControl(countryCode + '-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key3
            this.searchForm.addControl(countryCode + '-G-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key3
            this.searchForm.addControl(countryCode + '-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
          }
          this.formConfig.countries = res.countries;
          this.umaConfigKeys = res.umaConfigKeysMap.umaConfigKeysByCountry;
        }

      });

    this.linkedExperts = [];
    this.utilityServ.getLinkedExperts((this.expert.id).toString()).subscribe((res: Array<any>) => {
      console.log('LINKED EXPERTS : ', res);
      for (const expert of res) {
        const tmp: ExpertEntity = new ExpertEntity();
        this.assignExpertValues(tmp, expert);
        this.linkedExperts.push(tmp);
        console.log('added. ', tmp);
      }
      this.utilityServ.setLinkedExpertsDetail(this.linkedExperts);
    });

    // detail expert form, disabled
    this.detailForm = this.formBuilder.group({
      subjectDenomination: this.expert.subjectDenomination,
      roleEnrollmentDate: this.expert.roleEnrollmentDate,
      roleEnrollmentCode: this.expert.roleEnrollmentCode,
      fiscalAddress: this.expert.fiscalAddress,
      associatedExperts: this.expert.associatedExperts,
      country: this.expert.country,
      region: this.expert.region,
      city: this.expert.city,
      zipCode: this.expert.zipCode,
      toponym: this.expert.toponym,
      houseNumber: this.expert.houseNumber,
      expertType: this.expert.expertType,
      officeAddress: this.expert.officeAddress,
    });

    this.searchForm = this.formBuilder.group({
      country: ['IT'],
      subjectType: ['1'],
      expertCode: [],
      name: [],
      surname: [],
      businessName: [],
      vatNumber: [],
      fiscalCode: [],
      expertType: [],
    });

    // subscribe to subjectType changes, determines disabled behaviour of input name/surname -- businessName
    this.searchForm.controls.subjectType.valueChanges.subscribe(value => {
      if (value === '1') {
        this.isLegalSubject = false;
        this.searchForm.controls.businessName.setValue(null);
      } else if (value === '2') {
        this.isLegalSubject = true;
        this.searchForm.controls.name.setValue(null);
        this.searchForm.controls.surname.setValue(null);
      }
    });
  }

  openModalSearchExpertNewLink() {
    this.modalSearchNewLink = this.modalService.openComponent(UiModalDialogNewLinkComponent);
    this.modalSearchNewLink.component.modalNewLinkEmitter.subscribe((res: any) => {
      if (res.modalMsg === 'MODAL_CLOSED') {
        this.modalSearchNewLink.modal.close();
      }

      if (res.modalMsg === 'MODAL_SAVE') {
        // expert id is in utility service detail
        // experts to be linked are inside {{selections}}
        // selections are passed via the EVENT EMITTER inside the new link modal component
        const selections: any = res.selections;
        // no additional checks should be needed, button is only visible when user makes at least 1 selection
        const expertId: string = this.utilityServ.getExpertDetail().id.toString();
        const linkedIds: Array<string> = [];

        for (const x of selections.keys()) {
          linkedIds.push(x.toString());
        }

        this.utilityServ.saveNewExpertLink(expertId, linkedIds).subscribe((res2: any) => {
          // console.log(res3);
          // if success
          if (res2.code === '1') {
            // update experts list
            this.linkedExperts = [];
            this.utilityServ.getLinkedExperts((this.expert.id).toString()).subscribe((res3: Array<any>) => {
              for (const expert of res3) {
                const tmp: ExpertEntity = new ExpertEntity();
                this.assignExpertValues(tmp, expert);
                this.linkedExperts.push(tmp);
              }
              // update global linked experts to this specific detail page
              this.utilityServ.setLinkedExpertsDetail(this.linkedExperts);
            });
            // show success toast
            this.modalSearchNewLink.modal.close();



            this.translateService.translate('_CLAIMS_._EXPERT_LINK_ADDED_WITH_SUCCESS')
              .subscribe(res => {
                this.pushMessageHandler.notify({
                  content: res,
                  delay: 7000, // 6 sec
                  dismissible: true, // show the cancel button
                  status: 'success',
                  // select the tag inside html
                  tag: 'tagSnackbar'
                });
              })
          } else if (res2.code === '0') {
            // FAILED TO ADD EXPERT LINK

            this.translateService.translate('_CLAIMS_._AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST')
              .subscribe(res => {
                this.pushMessageHandler.notify({
                  content: res,
                  delay: 10000, // 10 sec
                  dismissible: true, // show the cancel button
                  status: 'danger',
                  // select the tag inside html
                  tag: 'tagSnackbar'
                });
              })
          }
        });
      }
    });
  }

  assignExpertValues(tmp: ExpertEntity, expert: any) {
    tmp.id = expert.id;
    tmp.code = expert.code;
    tmp.name = expert.name;
    tmp.surname = expert.surname;
    tmp.fiscalCode = expert.fiscalCode;
    tmp.vatNumber = expert.vatNumber;
    tmp.expertType = expert.expertType;
    tmp.expertTypeDescription = expert.expertTypeDescription;
    tmp.expertTypeProvider = expert.expertTypeProvider;
    tmp.percCash = expert.percCash;
    tmp.percVAT = expert.percVAT;
    tmp.percWithHolding = expert.percWithHolding;
    tmp.abi = expert.abi;
    tmp.cabi = expert.cabi;
    tmp.bankAccount = expert.bankAccount;
    tmp.maxNumAssignments = expert.maxNumAssignments;
    tmp.carBrandDescription = expert.carBrandDescription;
    tmp.subjectBusinessName = expert.subjectBusinessName;
    tmp.countryCode = expert.countryCode;
    // TODO FIX DISCREPANCIES BETWEEN EXPERTENTITY AND EXPERT DETAIL ENTITY
    // tmp.subjectType = expert.subjectType;
    tmp.subjectKey1 = expert.subjectKey1;
    tmp.subjectKey2 = expert.subjectKey2;
    tmp.subjectKey3 = expert.subjectKey3;
    tmp.taxRegimeId = expert.taxRegimeId;
    tmp.taxRegimeDescription = expert.taxRegimeDescription;
    tmp.isPublicDepartment = expert.publicDepartment;
    tmp.isAvailableCarCollection = expert.availableCarCollection;
    tmp.isAvailableReplacementCar = expert.availableReplacementCar;
    tmp.expertTypeDescription = expert.expertTypeDescription;
    tmp.expertTypeProviderDescription = expert.expertTypeProviderDescription;
    tmp.subjectKeyWithDescription = expert.subjectKeyWithDescription;
    tmp.nameBySubjectType = expert.nameBySubjectType;
  }


  ngOnDestroy(): void {
    this.utilityServ.setExpertDetail(null);
  }

  onExitDetailComponent() {
    this.utilityServ.setExpertDetail(null);
    this.routingService.loadComponent(this.routes.list);
    (this.routingService.componentRef.instance as ExpertsListComponent).card = this.card;
  }

  onOpenModalDeleteLink() {
    this.modalDeleteLink = this.modalService.openComponent(UiModalDialogDeleteLinkComponent);
    this.modalDeleteLink.component.modalDeleteEmitter.subscribe((res: any) => {

      console.log(res);
      if (res.modalMsg === 'MODAL_CLOSED') {
        this.modalDeleteLink.modal.close();
        this.selectedExpertIdLinkToDelete = null;
      }

      if (res.modalMsg === 'MODAL_DELETE') {
        // handle deletion of linked expert
        // double check if null
        if (this.selectedExpertIdLinkToDelete == null) {
          console.log('null value given to delete');
          return;
        }
        this.utilityServ.deleteExpertLink(this.expert.id.toString(), this.selectedExpertIdLinkToDelete).subscribe(
          (res2: any) => {
            if (res2.code === '1') {
              // success
              this.linkedExperts = [];
              this.utilityServ.getLinkedExperts((this.expert.id).toString()).subscribe((res3: Array<any>) => {
                for (const expert of res3) {
                  const tmp: ExpertEntity = new ExpertEntity();
                  this.assignExpertValues(tmp, expert);
                  this.linkedExperts.push(tmp);
                }
                // update global linked experts
                this.utilityServ.setLinkedExpertsDetail(this.linkedExperts);
              });
              this.modalDeleteLink.modal.close();
              this.translateService.translate('_CLAIMS_._EXPERT_ASSOCIATION_DELETED_CORRECTLY')
                .subscribe(res => {
                  this.pushMessageHandler.notify({
                    content: res,
                    delay: 6000, // 6 sec
                    dismissible: true, // show the cancel button
                    status: 'success',
                    // select the tag inside html
                    tag: 'tagSnackbar'
                  });
                })
            } else if (res.code === '0') {
              // failure
              this.translateService.translate('_CLAIMS_._AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION')
                .subscribe(res => {
                  this.pushMessageHandler.notify({
                    content: res,
                    delay: 10000, // 10 sec
                    dismissible: true, // show the cancel button
                    status: 'danger',
                    // select the tag inside html
                    tag: 'tagSnackbar'
                  });
                })
            }
          }
        );

        this.successfulAddExpertOperation = null;
      }
    });
  }

  deleteEvent($event: any) {
    console.log('CALLED DELETE EVENT');
    console.log($event);
  }


}


import {RgiRxHttpError, RgiRxHttpErrorAdapter, rgiRxHttpErrorSeverityFromStatus} from '@rgi/rx/http';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LifeRgiRxHttpErrorAdapter extends RgiRxHttpErrorAdapter {

  private static readonly LIFE_SCOPE: string = 'life';

  constructor() {
    super();
  }

  adapt(httpError: HttpErrorResponse): RgiRxHttpError | Observable<RgiRxHttpError> {
    const message: string = httpError.error.message ? httpError.error.message : httpError.message;
    return new RgiRxHttpError(message, httpError.status, httpError.url, {
      headers: httpError.headers,
      body: httpError.error,
      statusText: httpError.statusText,
      severity: rgiRxHttpErrorSeverityFromStatus(httpError.status),
      scope: LifeRgiRxHttpErrorAdapter.LIFE_SCOPE,
      tracing: {
        id: httpError.error.executionId,
        systemMessage: httpError.error.code
      }
    });
  }

}

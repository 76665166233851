<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels" (goToIthStep)="goToIthStep($event)"></re-issue-stepper>
<div class="rgi-re-container" *ngIf="stateManager.getState$() | async as state">


    <re-issue-quotation-controls [state]="stateManager.getState$()"
                                 [recalculateAvailable]="state.quotation.isRecalculate"
                                 (recalculate)="quotation()"
                                 (unitsViewMode)="onUnitsViewModeChange($event)"
                                 (showPremiumDetails)="openPremiumDetailsModal()"
                                 (showContributionsAndTaxes)="openContributionsAndTaxesModal()"
                                 (showFPSTaxes)="openFPSTaxesModal()"
                                 (showCommissions)="openCommissionsModal()"
                                 (showWarrantiesSummary)="openWarrantiesSummaryModal()"
                                 (showDiscounts)="openDiscountsModal()"
                                 [isUnique]="isUnique"
                                 data-qa="premium_bar">
    </re-issue-quotation-controls>

    <div>

      <re-issue-clauses-and-factors *ngIf="state && state.variables && state.clause && state.clause.clauses"
                                    [variables]="state.variables"
                                    [clauses]="state.allClauses"
                                    (updateClauses)="onClauseChange($event)"
                                    (updateVariables)="onVariablesFormChange($event)">
      </re-issue-clauses-and-factors>

      <rgi-rx-expansion-panel *ngFor="let oneAsset of state.assetInstances; let i = index" [expanded]="true"
                              attr.data-qa="asset_{{i}}">
        <rgi-rx-expansion-panel-header>
          <div rgi-rx-expansion-panel-label class="rgi-ui-grid-middle">
            <div class="rgi-ui-col">
              <div class="story-grid-column-content">
                <span>{{oneAsset.asset.name}}</span>
              </div>
            </div>
          </div>
          <div class="rgi-ui-grid-right-middle">
            <div class="rgi-ui-col">
              <div class="story-grid-column-content">
                              <span>{{getSumGrossAsset(state?.quotation?.assetList?.getAsset(oneAsset.asset.key))
                                | currency: currency.code : currency.symbol:'1.2-2':'it'}}</span>
              </div>
            </div>
          </div>
        </rgi-rx-expansion-panel-header>
                <re-issue-clauses
                  *ngIf="state.assetInstances[i].asset.clauses != null && state.assetInstances[i].asset.clauses.length != 0"
                  [showTitle]="true"
                  [clausesList]="state.assetInstances[i].asset.clauses"
                  [assetKey]="oneAsset.asset.key" (updateClause)="onAssetClausesChange($event)"></re-issue-clauses>

                <re-issue-asset-unit *ngIf="oneAsset.asset.key"
                                     [sectionsPremium]="getSection(state.quotation.assetList.assets,oneAsset.asset.key)"
                                     (recalculateAvailable)="changeRecalculateAvailable($event)"
                                     (validationMessagesAsset)="verifyAsset($event)"
                                     [sections]="getSections(state.quotation.assetList.assets,oneAsset.asset.key)"
                                     [viewUnitsBox]="viewUnitsBox"
                                     [assetInstanceKey]="oneAsset.asset.key"
                                     [quotePackets]="oneAsset.asset.package" [sync]="!oneAsset.sync"
                                     (checkPackets)="checkPackets($event)"
                                     [currency]="state.policyTechnicalData.selectedCurrency"
                                     (unitSelected)="getAssetClauses($event)">
                </re-issue-asset-unit>
                <re-issue-unit-beneficiaries *ngIf="getBeneficiaryUnitVisibility(oneAsset.asset.key)" [listBeneficiariesUnit]="state.listBeneficiariesUnit"
                                             [asset]="oneAsset"
                                             [unitCode]="state.listBeneficiariesUnit[0]?.unitCode"
                                             [isBeneficiaryUnitVisibility]="getBeneficiaryUnitVisibility(oneAsset.asset.key)"></re-issue-unit-beneficiaries>
      </rgi-rx-expansion-panel>

    </div>
    <br>
<!--    <re-issue-beneficiaries [beneficiaryForm]="beneficiaryForm" [listBeneficiariesUnit]="state.listBeneficiariesUnit"-->
<!--                            [beneficiariesProduct]="state.beneficiariesProduct"-->
<!--                            [isBeneficiaryUnitVisibility]="state.isBeneficiaryUnitVisibility"></re-issue-beneficiaries>-->
    <re-issue-third-person [beneficiaryForm]="beneficiaryForm"></re-issue-third-person>



    <re-issue-parameters *ngIf="state.quotationReady"
                         (recalculatePremium)="getPremium($event)"
                         (validationMessagesParameter)="verifyParameterBlockingMessages($event)"
                         (reinitializeVariables)="initializeVariables()"
                         (nonBlockingMessagesParameter)="verifyParameterNonBlockingMessages($event)"
                         [stateObs]="stateManager.getState$()"
                         [state]="state">
    </re-issue-parameters>

    <!--    <re-issue-quotation-clause *ngIf="state.clause.clauses.length>0" [clauses]="state.clause"-->
    <!--                               (clausesChange)="onClausesChange($event)" [clauseIsValid]="state.clauseIsValid">-->
    <!--    </re-issue-quotation-clause>-->

    <re-issue-contact [addressData]="addressData" [subject]="state.subject" [contact]="state.contact"
                      (updatePolicyAddress)="updatePolicyAddress($event)"
                      (policyContactErrorMessage)="checkPolicyContactValidity($event)">
    </re-issue-contact>


    <re-issue-notes [notes]="state.quotation.notes"></re-issue-notes>


    <div class="rgi-ui-grid">
      <div class="rgi-ui-col">
        <rgi-rx-snackbar tag="validationError"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="policyContactErrors"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="warnings"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="notBlockingWarnings"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="beneficiaryErrors"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="packetErrors"></rgi-rx-snackbar>
      </div>
    </div>

    <div class="rgi-ui-grid-2 rgi-ui-grid-no-gutter">
      <!-- CLEAR -->
      <div class="rgi-ui-col btn-group">
          <button rgi-rx-button class="rgi-re-button" rgi-rx-button color="secondary" (click)="back()"
                  data-qa="back" translate>
            RE_ISSUE.BACK
          </button>
      </div>
      <!-- FIND -->
      <div class="rgi-ui-col btn-group">
          <button rgi-rx-button (click)="goToNextPage()"
                  data-qa="next" color="primary" class="rgi-re-button"
                  [disabled]="state.quotation.isRecalculate  || disableNext || state.warnings?.length > 0 ||
                  validationMessages?.length > 0 || policyContactMessages">
            <span translate>RE_ISSUE.NEXT</span>
          </button>
      </div>
    </div>
</div>

import { Component, Input } from '@angular/core';
import { SubjectReduced } from '../../dsc-shared-model/dsc-model-subject';

@Component({
  selector: 'claims-subject-info',
  templateUrl: './subject-info.component.html',
  styleUrls: ['./subject-info.component.scss']
})
export class SubjectInfoComponent {
  @Input() subject: SubjectReduced;
}

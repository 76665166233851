import {Variable} from './variable';

export class WarrantySummary {

  constructor(
    public assetInstance: string,
    public unitTitle: string,
    public sectionTitle: string,
    public installment: string,
    public variablesRows: Array<Variable>
    // public total: number
  ) {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityService, Activityconstants, CardService } from '@rgi/digital-claims-common-angular';
import { ReassignTaskComponent } from './reassign-task.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReassignTaskService } from './reassign-task.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';


@NgModule({
    declarations: [
        ReassignTaskComponent
    ],
    imports: [
        CommonModule,
        [NgbModule],
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
    ],
    providers: [
        ReassignTaskService,
        ActivityService,
        NgbActiveModal,
    ],

    exports: [
        ReassignTaskComponent
    ]
})
export class ReassignTaskModule {
  constructor(cardService: CardService) {
    const title = '_ACTIVITY';
    const moduleName = 'activity-list';
    const parentCardName = 'claimsFinder';
    const debugCardsOn = true;

    if (debugCardsOn) {
        // TODO: temporary for debug only, remove later
        cardService.registerCard(
            moduleName,
            title,
            ReassignTaskComponent);
    } else {
        cardService.registerSubCard(
            moduleName,
            title,
            ReassignTaskComponent,
            parentCardName);
    }
}
}

<div class="col-md-12 life-detail-first-info">
  <div class="col-md-5 life-detail-img">
    <div class="hidden-xs hidden-sm anag-user-ico">
      <span class="rgifont life-detail-icon-padding rgi-fogliocassa"></span>
    </div>
  </div>
  <div class="col-md-7 life-detail-general-info">
    <h3 class="col-md-12 card-color-blue" style="margin-top: 0px !important;">
      <span data-qa="fundDescription">{{!!fund?.description ? fund.description : warranty}}</span>
    </h3>
    <div class="container-fluid anag-data">
      <div class="col-md-12">
        <span class="tbld_col_title" translate>lpc_last_revaluation</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="last_revaluation_end">
          {{ !!gsFund.revaluations && !!gsFund.revaluations[0]?.ultimaRivalutazione ? (gsFund.revaluations[0]?.ultimaRivalutazione | date: 'dd/MM/yyyy') : '-'   }}
        </span>
      </div>
      <div class="col-md-12">
        <span class="tbld_col_title" translate>lpc_revalueted_benef</span>
        <span class="tbld_col_title">: </span>
        <span class="tbld_col_value" data-qa="macroCategory">
          {{ gsFund.totWarrantyBenefit| rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}
        </span>
      </div>
      <ng-container *ngFor="let item of labelValues">
        <div class="col-md-12">
          <span class="tbld_col_title">{{item.label}}</span>
          <span class="tbld_col_title">: </span>
          <span class="tbld_col_value" [attr.data-qa]="item.label">
            <ng-container [ngSwitch]="item.type">
              <span *ngSwitchCase="'AMOUNT'">
                {{ item.value | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}
              </span>
              <span *ngSwitchDefault>
                {{ item.value }}
              </span>
            </ng-container>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>


import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnagFlowData } from '@rgi/anag';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SubjectReduced } from '../../dsc-shared-model/dsc-model-subject';

@Component({
  selector: 'claims-subject-action',
  templateUrl: './subject-action.component.html',
  styleUrls: ['./subject-action.component.scss']
})
export class SubjectActionComponent {

  @Input() selectedNode: any;
  @Input() activeRouteId: any;
  @Input() disabled: boolean = false;
  @Output() selectNewSubject = new EventEmitter();
  @Output() deleteSubject = new EventEmitter();

  @Input()
  get subject(): SubjectReduced {
    return this.subject$;
  }
  set subject(value: SubjectReduced) {

    if (value) {
      this.subject$ = value;
    }
    this.editableSubjectFormGroup = new UntypedFormGroup({
      clusterName: new UntypedFormControl(this.setOwnerField())
    });
    if (this.subject.objectId === '') {
      this.editableSubjectFormGroup.reset();
    }
    if (this.disabled) {
      this.editableSubjectFormGroup.disable();
    }

  }
  private subject$: SubjectReduced = new SubjectReduced();
  public editableSubjectFormGroup: UntypedFormGroup;

  getSubjectDropContainerData() {
    const data = new AnagFlowData();
    data.partyRole = '888';
    data.nodeCode = this.selectedNode;
    data.idParentSession = this.activeRouteId;
    return data;
  }

  onPartySelected(event) {
    const dataChange = event.changed;
    const subject = new SubjectReduced();
    subject.objectId = dataChange.objectId;
    subject.givenName = dataChange.name;
    subject.surname = dataChange.surname;
    subject.fiscalCode = dataChange.fiscalCode;
    subject.nominative = dataChange.nominative;
    subject.vatCode = dataChange.vatCode;
    subject.formatAddress = dataChange.residence.formatAddress;
    subject.email = dataChange.mobilePhone.length > 0 ? dataChange.mobilePhone[0] : '-';
    subject.number = dataChange.emails.length > 0 ? dataChange.emails[0] : '-';
    subject.isMinor =this.calculateIfMinorFromdateOfBirth(dataChange.dateOfBirth);
    subject.subject.anagSubject = dataChange;
    this.selectNewSubject.emit(subject);
  }

  delete() {
    this.deleteSubject.emit(new SubjectReduced());
    this.editableSubjectFormGroup.reset();
  }

  calculateIfMinorFromdateOfBirth(birthday) {
    const dateOfBirth = new Date(birthday);
    const ageDifMs = Date.now() - dateOfBirth.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
  }


  protected setOwnerField() {
    let ownerField = null;
    ownerField = {
      nominative: this.subject.nominative
    };
    return ownerField;
  }

}

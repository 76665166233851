<div (click)="fireClickConversationEvent()" class="chat-conversation col-sm-12">
    <div class="col-sm-1 chat-user-image">
        <span class="rgifont rgi-user"></span>&nbsp;
    </div>
    <div class="col-sm-9 chat-user-name">
        {{chatSummary.otherUser.userName}}
    </div>
    <div *ngIf="chatSummary.numberOfUnreadMessages > 0" class="pull-right chat-unread-messages">
        <span>{{chatSummary.numberOfUnreadMessages}}</span>
    </div>
    <div class="chat-summary-info">
        <div class="chat-subject col-sm-9">{{chatSummary.subject}}</div>
        <div class="chat-date pull-right">{{chatSummary.sendDate | date: 'dd/MM/yyyy'}}</div>
    </div>

</div>
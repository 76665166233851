import { LicObjectUtils } from './../../lic-object-utils';
import { Component, OnInit } from '@angular/core';
import { WarrantiesTableComponent } from '../warranties-table.component';
import { PolicyService } from './../../../../life-card/services/policy-service';
import { Unit } from './../../../../life-card/models/policy.model';
import { LicQuotationUtils } from '../../lic-quotation-utils';

@Component({
  selector: 'lic-warranties-group-table',
  templateUrl: './../warranties-table.component.html',
  styleUrls: ['./../warranties-table.component.css']
})
export class WarrantiesGroupTableComponent extends WarrantiesTableComponent implements OnInit {

  unitsGrouped: {[key: string]: Unit[]} = {};

  constructor(protected policyService: PolicyService) {
    super(policyService);
  }

  ngOnInit() {
    this.unitsGrouped = this.groupUnits();
    Object.keys(this.unitsGrouped).forEach(group => this.sumRiskPremiumsByGroup(group));
    super.ngOnInit();
  }

  private groupUnits(): {[key: string]: Unit[]} {
    return LicQuotationUtils.getUnitGroupedByTranscoderCode(this.policyService.mainProposal.quote.product.assets);
  }

  /**
   * Sums the risk premiums by group.
   * @param group - The group to sum the risk premiums for.
   */
  private sumRiskPremiumsByGroup(group: string) {
    const units = this.unitsGrouped[group];
    if (units) {
      const groupedRisks = units.map(u => u.riskCode);
      const filteredRisks = this.riskPremium.filter(r => groupedRisks.includes(r.riskCode));

      if (!!filteredRisks.length) {
        const clonedRiskPremium = LicObjectUtils.cloneObject(this.riskPremium.filter(r => groupedRisks.includes(r.riskCode)));
        clonedRiskPremium.reduce((previousValue, currentValue) => {
          Object.keys(previousValue).forEach(key => {
            if (!isNaN(previousValue[key])) {
              previousValue[key] = Number(previousValue[key]) + Number(currentValue[key]);
            }
          });
          return previousValue;
        });
        const firstElementWithGroupedValues = clonedRiskPremium.find(r => groupedRisks.includes(r.riskCode));
        this.riskPremium = []
        .concat([firstElementWithGroupedValues])
        .concat(this.riskPremium.filter(r => !groupedRisks.includes(r.riskCode)));
      }
    }
  }

}

export class DamagedAsset {

    public chassis: string;
    public description: string;
    public id: string;
    public immatricolationdate: string;
    public manufacter: string;
    public manufacturerdescr: string;
    public model: string;
    public modeldescr: string;
    public vehicleplate: string;
    public nationcode: string;
    public vehicleplatetype: string;
    public third: boolean;
    public assettype: string;
    public percIP: number;
    public ggITB: number;
    public fatalDate: string;
    public selectedCountryER: string;
    public placeER: string;
    public descriptionER: string;
    public idER: string;
    public biologicalDamage: boolean;
    public surroga: string;
    public doctorIdSogg: string;
    public doctorIdSoggPA: string;
    public doctorName: string;
    public injuryDamageList: Map<string, string>;
    public moreInjury: boolean;
    public profession: string;
    public injuryCause: string;
    public vehicleTypeAnia: string;
    public vehicleType: string;
    public parked: boolean;
    public vehicleplatetypeCode: string;
    public vehicleTypeDescr: string;

    constructor() {  }
}

import { ActiveRoute } from "@rgi/rx/router";
import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { ValidationConsolidationState } from "../states/validation-consolidation.state";
import { SearchPayments } from "../models/payments/search-payments.model";
import { ValidationConsolidationService } from "../services/validation-consolidation.service";

@Injectable()
export class ValidationConsolidationStateManager extends AbstractStateManager<ValidationConsolidationState> {
    constructor(
        activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: ValidationConsolidationService
    ) {
        super(activeRoute, stateStoreService);

        const st = !!stateStoreService.get<ValidationConsolidationState>(this.storeKey)
            ? stateStoreService.get<any>(this.activeRoute.key)
            : new ValidationConsolidationState(this.storeKey);

        this.activeRoute.getRouteData<any>().dataFromRoute 
            ? this.updateState$(this.statelesOps.initState$(of(st), this.activeRoute.getRouteData<any>()?.dataFromRoute))
            : this.updateState$(of(st));
    }

    bookValidation$(searchPaymentsInputDTO: SearchPayments) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.bookValidation(of(st), searchPaymentsInputDTO));
    }

    bookConsolidation$(searchPaymentsInputDTO: SearchPayments) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.bookConsolidation(of(st), searchPaymentsInputDTO));
    }
}
<div>
    <div class="nmf_cont_titsez nmf_cont_titsez_color">
      <span *ngIf="" class="header-icon rgi-nmf rgi-nmf-info"></span>
      <h1><span translate>Amend policy n.</span> {{policyNumber}}</h1>
      <div *ngIf="" class="nmf_errorautomaticbranch_icon"><span
        class="rgifont rgi-survey_ko"></span></div>
    </div>
    <div>
      <form [formGroup]="amendmentForm">
        <div class="form-group row">
          <div class="col-sm-6 col-xs-12">
            <pnc-datepicker [customFormControlName]="'effectDate'" [customFormGroup]="amendmentForm"
                            [id]="'effectDate'" label="{{ 'Effective Date' | translate }}" >
            </pnc-datepicker>
          </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-6 col-xs-12">
              <pnc-datepicker [customFormControlName]="'operationDate'" [customFormGroup]="amendmentForm"
                              [id]="'operationDate'" label="{{ 'Operation Date' | translate }}">
              </pnc-datepicker>
            </div>
          </div>

        <div class="form-group row">
          <div class="col-sm-6 col-xs-12">
            <label>Effect Hour</label>
            <input class="form-control mic-input" formControlName="effectHour" data-qa="effectHour" type="text">
          </div>
        </div>

        <pnc-form-message *ngIf="validationMessages?.length > 0" [messages]="validationMessages" data-qa="action-error-messages"
                          detail="validationError" objectType="complex" type="error">
        </pnc-form-message>

        <div class="modal-footer">
          <div class="btn-group btn-group-justified">
            <div class="btn-group">
              <button (click)="goBack()" class="btn btn-warning" data-qa="btn-amendment-back" translate>back</button>
            </div>
            <div class="btn-group">
              <button (click)="onSubmit()" [disabled]="validationMessages?.length" class="btn btn-warning"
                      data-qa="btn-amendment-next" translate>CONFIRM
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

import { Component, OnInit, Input } from '@angular/core';
import { CacheService } from '../../cache.service';
import { ExternalPolicy } from '../../dto/external-policy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ExternalPortfolioService } from '../../external-portfolio.service';

@Component({
  selector: 'claims-external-portfolio-subjects',
  templateUrl: './external-portfolio-subjects.component.html',
  styleUrls: ['./external-portfolio-subjects.component.scss']
})
export class ExternalPortfolioSubjectsComponent implements OnInit {
  @Input() public modPolicy: ExternalPolicy;
  @Input() public tipoSogg: string;
  @Input() public modPolicyForm: UntypedFormGroup;
  @Input() public provinces: any[];
  @Input() public countries: any[];
  @Input() public comunisAss: any[];
  @Input() public comunisCnt: any[];
  public capsCnt: any[];
  public capsAss: any[];
  public attivaNomeAss = true;
  public attivaNomeCnt = true;

  constructor(
    public activeModal: NgbActiveModal,
    private servCache: CacheService,
    public formBuilder: UntypedFormBuilder,
    private policyServ: ExternalPortfolioService
  ) {
     if (this.provinces == null) {
       this.getProvinces();
     }
     if (this.countries == null) {
       this.getCountries();
     }
   }

  ngOnInit() {
    console.log(this.modPolicyForm);
    if (this.modPolicyForm.controls.province &&
      this.modPolicyForm.controls.province.value != null &&
      this.modPolicyForm.controls.province.value) {
      this.caricaComuni('CNT');
    }

    if (this.modPolicyForm.controls.provinceInsured &&
      this.modPolicyForm.controls.provinceInsured.value != null &&
      this.modPolicyForm.controls.provinceInsured.value) {
      this.caricaComuni('ASS');
    }
  }



  closeModal() {
    this.activeModal.close();
  }

  getProvinces() {
    if ( this.servCache.getProvinces == null) {
     this.policyServ.getProvincesList().subscribe( (response: Response) => {
        // this.provinces = response;
        this.servCache.setProvinces(response);
        this.provinces = this.servCache.getProvinces();
      },
      (error: Error) => console.log(error)
      );
    } else {
     this.provinces = this.servCache.getProvinces();
    }
  }


  getCountries() {
    if (this.servCache.getCountries() == null) {
      this.policyServ.getCountriesList().subscribe( (response: Response) => {
     // this.countries = response;
       this.servCache.setCountries(response);
       this.countries = this.servCache.getCountries();
      },
      (error: Error) => console.log(error)
      );
    } else {
     this.countries = this.servCache.getCountries();
    }
  }

  caricaComuni(type: string) {
    let prov = '';
    if (type === 'CNT') {
      this.policyServ.getTownListByProvince(this.modPolicyForm.controls.province.value).subscribe( (response: any[]) => {
        this.comunisCnt = response;
        if (this.modPolicyForm.controls.municipality.value !=  null &&
            this.modPolicyForm.controls.municipality.value) {
          this.caricaCap('CNT');
        }
      },
        (error: Error) => console.log(error)
      );
    } else if (type === 'ASS') {
      prov = this.modPolicyForm.controls.provinceInsured.value;
      this.policyServ.getTownListByProvince(this.modPolicyForm.controls.provinceInsured.value).subscribe( (response: any[]) => {
        this.comunisAss = response;
        if (this.modPolicyForm.controls.municipalityInsured.value !=  null &&
          this.modPolicyForm.controls.municipalityInsured.value) {
          this.caricaCap('ASS');
        }
      },
        (error: Error) => console.log(error)
      );
    }
  }

  caricaCap(type: string) {
    if (type === 'CNT') {
      for (const valore of this.comunisCnt) {
        if (valore.descrizione === this.modPolicyForm.controls.municipality.value) {
            this.capsCnt = valore.listaCap;
            if (this.capsCnt.find(aCap => aCap === this.modPolicyForm.controls.postalCode.value) == null) {
              this.capsCnt.push(this.modPolicyForm.controls.postalCode.value);
            }

            break;
        }
      }
    } else if (type === 'ASS') {
      for (const valore of this.comunisAss) {
        if (valore.descrizione === this.modPolicyForm.controls.municipalityInsured.value) {
            this.capsAss = valore.listaCap;
            if (this.capsAss.find(aCap => aCap === this.modPolicyForm.controls.postalCodeInsured.value) == null) {
              this.capsAss.push(this.modPolicyForm.controls.postalCodeInsured.value);
            }
            break;
        }
      }
    }
  }

  attivaNome(code) {
    if (code === 'CNT') {
      if (this.modPolicyForm.controls.subjectCode.value === 'G') {
        this.attivaNomeCnt = false;
        this.modPolicyForm.controls.firstName.setValue('');
      } else {
        this.attivaNomeCnt = true;
      }

    } else if ( code === 'ASS') {
      if (this.modPolicyForm.controls.subjectCodeInsured.value === 'G') {
        this.attivaNomeAss = false;
        this.modPolicyForm.controls.firstNameInsured.setValue('');
      } else {
      this.attivaNomeAss = true;
      }
    }
  }

}

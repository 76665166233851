import {PlcDateUtils} from './plc-date-utils';
import {
  InputFieldDefinition,
  PostsalesOperationObject,
  PostsalesOperationsResponse
} from '../models/postsales-operations-response.model';


export class PlcResponseUtils {

  static responseToObject(response: PostsalesOperationsResponse): PostsalesOperationObject {
    const datesMap = {};
    // se c'è un reload allora devo prendere le date da definitions
    if (response.data.reload) {
      (response.definitions.dates as InputFieldDefinition[]).map(date =>  {
        const dataDate = response.data.dates.find(d => d.code === date.code);
        datesMap[date.code] = !date.editable ?  PlcDateUtils.isoToDate(date.defaultValue) : PlcDateUtils.isoToDate(dataDate.value);
      });
    } else {
      if (response.data && response.data.dates && response.data.dates.length > 0) {
        response.data.dates.forEach(date =>  {
          const definition = (response.definitions.dates as InputFieldDefinition[]).find(d => d.code === date.code);
          const dateValue: string = date.userInput || definition === undefined ? date.value : definition.defaultValue;
          datesMap[date.code] = PlcDateUtils.isoToDate(dateValue);
        });
      }
    }

    const r: PostsalesOperationObject = {
      definitions: !!Object.keys(response.definitions).length ? response.definitions : { dates: [] },
      finalizable: response.finalizable,
      requireAuthorization: response.requireAuthorization,
      errors: response.errors,
      data: (response.data as any)
    };

    r.data.dates = datesMap as any;
    return r;
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { TypeRefund } from '../../models/enums/vita.enum';
import { LoanQuotationTableDefinitions, LoanRefundOperationData, LoanRefundRequestData, LoanTableDefinitions } from '../../models/loan.model';
import { FormuleQuotationTable, PostsalesOperationObject } from '../../models/postsales-operations-response.model';
import { LpcLoanRefundTableComponent } from '../../modules/lpc-loan-refund-table/lpc-loan-refund-table.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Component({
  selector: 'lpc-loan-grant',
  templateUrl: './loan-refund.component.html',
  styleUrls: ['./loan-refund.component.scss'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LoanRefundComponent extends AbsOperationComponent implements OnInit  {

  protected operationDataKey = 'loan-refund';
  public quotationTableDefinitions: { [key: string]: {value: string, type: string}; } = {};

  private $loanDefinitions: LoanTableDefinitions[] = [];
  public operationDataLoans: LoanRefundOperationData = null;

  public get loanDefinitions(): LoanTableDefinitions[] {
    return this.$loanDefinitions;
  }

  @ViewChild('loanRefundTable') loanRefundTable: LpcLoanRefundTableComponent;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService, plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(res => this.createDraftHandleResponse(res))
    );
  }


  createDraftHandleResponse(createResponse: PostsalesOperationObject) {
    const operationData = createResponse.data.operationData.data;
    if (!!operationData && !!operationData.loanData) {
      this.operationDataLoans = operationData.loanData as LoanRefundOperationData;
      this.valorizeLoanFormByOperationData(operationData.loanData as LoanRefundOperationData);
      this.cd.detectChanges();
    }
  }

  protected getTransformedOperationData(): any {
    const obj: any = {
      loanData: this.getRequestLoan()
    };
    return obj;
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    if (step === 'loanRefund' && !Object.values(this.getRequestLoan()).length) {
      this.setCustomFeErrors(step, 'lpc_select_refund', 'error');
      return ;
    }
    super.onNext(step, publish, context, isConfirmAndAccept);
  }

  public getRequestLoan(): LoanRefundRequestData {
    if (!!(this.formGroup.get('loanRefund') as UntypedFormGroup).value) {
      const request: LoanRefundRequestData[] = (this.formGroup.get('loanRefund') as UntypedFormGroup).value.loans;
      const filteredLoans = request.filter(selectedLoan => !!selectedLoan.selectedLoanId);
      return !!filteredLoans.length ? filteredLoans
      .reduce(loan => {
        return {
          selectedLoanId: loan.selectedLoanId,
          selectedTypeOfRefund: loan.selectedTypeOfRefund,
          refundAmount: Number(loan.refundAmount)
        };
      }) : {} as LoanRefundRequestData;
    }
    return {} as LoanRefundRequestData;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      loanRefund: new UntypedFormControl()
    });
  }

  protected updateDraft(step: string, reload: boolean = false, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      switchMap(result => {
        let quotationLoanRefund = result.definitions.quotationLoanRefund as LoanQuotationTableDefinitions[];
        if (!!quotationLoanRefund) {
          quotationLoanRefund = this.valorizeLeftRefundAmount(result, quotationLoanRefund);
          this.quotationTableDefinitions = this.adaptToQuotationTableObject(quotationLoanRefund);
        }
        if (!!result.definitions.loanDefinition) {
          this.$loanDefinitions = (result.definitions.loanDefinition as LoanTableDefinitions[]);
        }

        const operationData = result.data.operationData.data;
        if (!!operationData && !!operationData.loanData) {
          this.valorizeLoanFormByOperationData(operationData.loanData as LoanRefundOperationData);
          this.cd.detectChanges();
        }

        return of(result);
      })
    );
  }

  valorizeLoanFormByOperationData(operationData: LoanRefundOperationData) {
    (this.formGroup.get('loanRefund')).setValue({loans: [operationData]}, { emitEvent: false });
  }

  private valorizeLeftRefundAmount(result: any, quotationLoanRefund: LoanQuotationTableDefinitions[]): LoanQuotationTableDefinitions[] {
    if (result.data.operationData.data.loanData.selectedTypeOfRefund === TypeRefund.PRES_TIPO_RIMBORSO_PARZIALE) {
      if (!!this.loanRefundTable && !!this.loanRefundTable.getSelectedLoanSummary()) {
        quotationLoanRefund[2].value =
        (Number(quotationLoanRefund[2].value) - Number(this.loanRefundTable.getSelectedLoanSummary().amountRefund)).toString();
      }
    }
    return quotationLoanRefund;
  }

  adaptToQuotationTableObject(quotation: LoanQuotationTableDefinitions[]): FormuleQuotationTable {
    return quotation.reduce((group, formula) => {
      const category = formula.description;
      group[category] = !!group[category] ? group[category] : [];
      group[category] = { value: formula.value, type: formula.type };
      return group;
    }, {});
  }

}

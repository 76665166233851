import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {KarmaProfileDefinition} from '../../model/karma-profile-definition';
import {Subscription} from 'rxjs';
import {LicKarmaFundUtils} from '../../utils/lic-karma-fund-utils';
import {LicObjectUtils} from '../../../utils/lic-object-utils';

@Component({
  selector: 'lic-karma-fund-step[definition][totalAmount]',
  templateUrl: './lic-karma-fund-step.component.html',
  styleUrls: ['./lic-karma-fund-step.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicKarmaFundStepComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicKarmaFundStepComponent),
      multi: true
    }
  ]
})
export class LicKarmaFundStepComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  public formGroup: UntypedFormGroup;

  @Input() public definition: KarmaProfileDefinition[];
  @Input() public totalAmount: number;

  private $profiles: KarmaProfileDefinition[] = [];
  public get profiles(): KarmaProfileDefinition[] {
    return this.$profiles;
  }

  private $subscriptions: Subscription[] = [];

  private $defaultValueStructure: any;

  public get selectedProfiles(): KarmaProfileDefinition[] {
    const profiles: any = this.formGroup.getRawValue().profiles ? this.formGroup.getRawValue().profiles : {};
    const profileKeys = Object.keys(profiles).filter(key => !!profiles[key]);
    return this.$profiles.filter(pr => profileKeys.includes(pr.id));
  }

  constructor() {
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe(result => {
        const value: any = {};
        const profiles: any = this.formGroup.getRawValue().profiles;
        const funds: any = this.formGroup.getRawValue().funds;

        Object.keys(funds).forEach(profileKey => {
          value[profileKey] = { percent: profiles[profileKey], funds: {} };
          Object.keys(funds[profileKey]).forEach(fundKey => {
            value[profileKey].funds[fundKey] = funds[profileKey][fundKey];
          });
        });
        this.onChange(value);
        this.onTouch();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definition) {
      this.$profiles = changes.definition.currentValue;
      this.formGroup = this.getFormGroup();
      this.$defaultValueStructure = LicKarmaFundUtils.getDefaultFundStepValueOf(this.$profiles);
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (!!obj) {
      const profiles = {};
      const funds = {};
      Object.keys(obj).forEach(profileKey => {
        profiles[profileKey] = obj[profileKey].percent;
        if (obj[profileKey].funds) {
          funds[profileKey] = {};
          Object.keys(obj[profileKey].funds).forEach(fundKey => {
            funds[profileKey][fundKey] = obj[profileKey].funds[fundKey];
          });
        }
      });
      this.formGroup.setValue(
        LicObjectUtils.merge({}, this.$defaultValueStructure, { profiles, funds }),
        { emitEvent: false }
      );
    } else {
      this.formGroup.setValue(this.$defaultValueStructure, { emitEvent: false });
    }
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let hasErrors = false;
    const errors: ValidationErrors = {
      profiles: {},
      funds: {}
    };

    if (!!this.formGroup.get('profiles').errors) {
      hasErrors = true;
      errors.profiles = this.formGroup.get('profiles').errors;
    }

    Object.keys((this.formGroup.get('funds') as UntypedFormGroup).controls).forEach(key => {
      if (this.formGroup.get('funds').get(key).errors) {
        hasErrors = true;
        errors.funds[key] = this.formGroup.get('funds').get(key).errors;
      }
    });
    return hasErrors ? errors : null;
  }

  public getAmountOfProfile(id: string): number {
    const percent: number = this.formGroup.get('profiles').value[id];
    if (!!percent) {
      return this.totalAmount * percent;
    }
    return 0;
  }

  private getFormGroup() {
    const fg: UntypedFormGroup = new UntypedFormGroup({
      profiles: new UntypedFormControl(),
      funds: new UntypedFormGroup({})
    });
    this.$profiles.forEach(profile => {
      (fg.get('funds') as UntypedFormGroup).addControl(profile.id.toString(), new UntypedFormControl());
    });
    return fg;
  }

}

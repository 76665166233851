import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  isDevMode,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import {RgiCountryLayerNumberFormat} from '../number-format/rgi-country-layer-number-format.service';
import {RGI_RX_LOCALE} from '@rgi/rx/i18n';
import {
  RgiCountryLayerContext,
  RgiCountryLayerFractionDigits,
  RgiCountryLayerSignificantDigits,
  RgiRxCountryLayerNumberPrecision
} from '../rgi-country-layer.api';
import {RgiRxErrorStateMatcher, RgiRxFormControl, RgiRxFormControlDirective} from '@rgi/rx/ui';
import {ControlValueAccessor, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {BACKSPACE, ENTER, LEFT_ARROW, RIGHT_ARROW, TAB} from '@angular/cdk/keycodes';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {LoggerFactory, safeRegexpTest} from '@rgi/rx';
import {Event} from '@angular/router';


export interface RgiCountryLayerInputNumberFormatChange {
  value: number;
  formattedValue: string;
}
@Directive({
  selector: 'input[type="text"][rgiCountryLayerInputNumberFormat]',
  providers: [
    {
      provide: RgiRxFormControl,
      useExisting: RgiCountryLayerInputNumberFormatDirective
    }
  ],
  host: {
    '(blur)': '_handleBlur($event)',
  }
})
export class RgiCountryLayerInputNumberFormatDirective extends RgiRxFormControlDirective<number>
  implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {

  private readonly logger = LoggerFactory();
  @Input() locale?: RGI_RX_LOCALE;
  @Input() minimumIntegerDigits?: RgiCountryLayerSignificantDigits;
  @Input() minimumFractionDigits?: RgiCountryLayerFractionDigits;
  @Input() maximumFractionDigits: RgiCountryLayerFractionDigits = 3;
  @Input() minimumSignificantDigits?: RgiCountryLayerSignificantDigits;
  @Input() maximumSignificantDigits?: RgiCountryLayerSignificantDigits;
  @Input() precision: RgiRxCountryLayerNumberPrecision = RgiRxCountryLayerNumberPrecision.AUTO;
  @Output() valueChange = new EventEmitter<RgiCountryLayerInputNumberFormatChange>();

  private allowedKeyCodes = [BACKSPACE, ENTER, LEFT_ARROW, RIGHT_ARROW, TAB];
  private contextChangeSubscription: Subscription = Subscription.EMPTY;

  onChange: (changed: any) => void = changed => {
  }
  onTouched: () => void = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'string') {
      this.value = this.format.parseLocaleString(obj , this.currentLocale(), this.makeOptions());
    } else {
      this.value = obj;
    }
    this.setNativeInputValue(this.currentLocale(), this.makeOptions());
  }

  @Input() get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
    this.onChange(value);
  }

  constructor(
    private format: RgiCountryLayerNumberFormat,
    private context: RgiCountryLayerContext,
    elementRef: ElementRef<HTMLInputElement>,
    errorStateMatcher: RgiRxErrorStateMatcher,
    @Optional() @Self() ngControl?: NgControl,
    @Optional() parentForm?: NgForm,
    @Optional() parentFormGroup?: FormGroupDirective,
  ) {
    super(elementRef, errorStateMatcher, ngControl, parentForm, parentFormGroup);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostListener('keydown', ['$event'])
  _handleKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    // todo prevent when float digits are more than allowed
    if (!(/^\d*\.?\d*$/.test(event.key) || /^\d*,?\d*$/.test(event.key)) && !this.isAllowedKeyCode(event.keyCode)) {
      event.preventDefault();
      return;
    }
  }

  @HostListener('input', ['$event'])
  _handleInput(event: Event) {
    if (!this.isPartialFloatNumber(this.elementRef.nativeElement.value)) {
      this.parseAndSerialize(this.elementRef.nativeElement.value, this.currentLocale());
    }
    this.valueChange.next({
      formattedValue: this.elementRef.nativeElement.value,
      value: this.value
    });
  }
  private isPartialFloatNumber(value: string) {
    const decimalRegexp = this.format.getNumberParser(this.currentLocale()).decimal;
    const floats = value.split(decimalRegexp);
    const isLastZeroOfAFloat = floats[1] && floats[1].slice(-1) === '0';
    return (floats.length > 1 && safeRegexpTest(decimalRegexp, value.slice(-1))) || !!isLastZeroOfAFloat;
  }

  private subscribeToContextChange() {
    this.contextChangeSubscription.unsubscribe();
    this.contextChangeSubscription = this.context.context$.pipe(
      map(ctx => ctx.locale)
    ).subscribe(
      next => {
        if (this.elementRef.nativeElement.value) {
          this.parseAndSerialize(this.value.toString(), next);
        }
      }
    );
  }
  private parseAndSerialize(rawValue: string, locale: RGI_RX_LOCALE) {
    const options = this.makeOptions();
    const parsed = this.format.parseLocaleString(rawValue, locale, options);
    this.value = isNaN(parsed) ? null : parsed;
    this.setNativeInputValue(locale, options);
  }

  private setNativeInputValue(locale: RGI_RX_LOCALE, options: Partial<Intl.NumberFormatOptions>) {
    if (this.value === null || this.value === undefined) {
      this.elementRef.nativeElement.value = '';
    } else {
      const formatted = this.format.convertNumberByLocale(this.value, locale, options);
      if (formatted !== 'NaN') {
        this.elementRef.nativeElement.value = formatted;
      }
    }
  }

  private currentLocale() {
    return this.locale ? this.locale : this.context.locale;
  }

  private isAllowedKeyCode(keyCode: number) {
    return this.allowedKeyCodes.indexOf(keyCode) > -1;
  }

  _handleBlur($event: Event) {
    this.focused = false;
    this.onTouched();
  }

  ngOnInit(): void {
    if (!this.locale) {
      this.subscribeToContextChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.locale) {
      if (!changes.locale.firstChange) {
        if (!changes.locale.currentValue && this.contextChangeSubscription.closed) {
          this.subscribeToContextChange();
        } else if (changes.locale.currentValue !== changes.locale.previousValue && this.value) {
          this.parseAndSerialize(this.value.toString(), changes.locale.currentValue);
        }
      }

    }
  }


  ngOnDestroy(): void {
    this.contextChangeSubscription.unsubscribe();
  }

  private makeOptions(): Partial<Intl.NumberFormatOptions> {
    const options: Partial<Intl.NumberFormatOptions> = {
      minimumIntegerDigits: this.minimumIntegerDigits,
      minimumFractionDigits: this.minimumFractionDigits,
      minimumSignificantDigits: this.minimumSignificantDigits,
      maximumFractionDigits: this.maximumFractionDigits,
      maximumSignificantDigits: this.maximumSignificantDigits,
    };

    if (this.precision === RgiRxCountryLayerNumberPrecision.EXPERIMENTAL && isDevMode()) {
      this.logger.warn(`Using RgiRxCountryLayerNumberPrecision.EXPERIMENTAL!
this option try to set features that may not be supported by all browsers as roundingPriority.
please check:
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#browser_compatibility`);
      return {...options, roundingPriority: 'morePrecision'} as any;
    }
    return options;
  }
}

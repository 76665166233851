import { Component, OnInit, EventEmitter, Inject, Optional } from '@angular/core';
import { DscCirumstanceService } from '../dsc-circumstances-service/dsc-circumstances-data.service';
import { Barem, OutputBaremList } from './dsc-barems-model/dsc-barems.config';
import { DIALOG_DATA } from '@rgi/rx/ui';
import { BaremEntity } from '../../dsc-shared-model/dsc-model-utility';

@Component({
  selector: 'claims-dsc-barems-modal',
  templateUrl: './dsc-barems-modal.component.html',
  styleUrls: ['./dsc-barems-modal.component.scss']
})
export class DscBaremsModalComponent implements OnInit {

  baremsList: Barem[] = [];
  codeBaremA: string = '';
  codeBaremB: string = '';
  requiredBaremA: boolean;
  requiredBaremB: boolean;

  enableConfirmButton: boolean = false;

  modalClose = new EventEmitter<any>(); // Chiusura della modale

  constructor(
    private dscCirumstanceService: DscCirumstanceService,
    @Optional() @Inject(DIALOG_DATA) data: any
  ) {
    this.codeBaremA = data.baremEntityA;
    this.codeBaremB = data.baremEntityB;
    this.requiredBaremA = data.baremARequired;
    this.requiredBaremB = data.baremBRequired;
  }

  ngOnInit() {
    this.getAllBarem();
  }

  /**
   * Funzione che recupera la lista di barems
   */
  getAllBarem() {
    this.dscCirumstanceService.baremsList().subscribe((element: OutputBaremList) => {
      this.baremsList = element.barem;
      this.checkSelectedBarem();
    });
  }

  /**
   * Funzione utile per la visualizzazione del flag sulla checkbox attivata dai dati
   * ricevuti in input
   */
  checkSelectedBarem() {
    if (this.codeBaremA !== '') {
      this.baremsList.forEach((singleBarem: Barem) => {
        singleBarem.isSelectedA = singleBarem.objectId === this.codeBaremA;
      });
    }

    if (this.codeBaremB !== '') {
      this.baremsList.forEach((singleBarem: Barem) => {
        singleBarem.isSelectedB = singleBarem.objectId === this.codeBaremB;
      });
    }

    this.enableModalButton();
  }

  /**
   * Funzione che valorizza i parametri del barem selezionato
   * @param barem Oggetto barem selezionato
   * @param index Indice del barem selezionato
   * @param typeList Lista del barem selezionato (Veicolo A o Veicolo B)
   * @param event Utile per capire se il barem è stato selezionato o deselezionato
   */
  onValueChange(barem: Barem, index: number, typeList: string, event) {
    if (typeList === 'A') {
      this.baremsList.forEach((singleBarem: Barem) => {
        singleBarem.isSelectedA = (singleBarem.objectId === barem.objectId && event.checked);
      });
    }

    if (typeList === 'B') {
      this.baremsList.forEach((singleBarem: Barem) => {
        singleBarem.isSelectedB = (singleBarem.objectId === barem.objectId && event.checked);
      });
    }

    this.enableModalButton();
  }

  /**
   * Funzione attivata selezionando il bottone "Conferma"
   */
  confirmCircumstance() {
    const baremA = this.baremsList.find(res => res.isSelectedA);
    const baremB = this.baremsList.find(res => res.isSelectedB);

    const baremEntityA: BaremEntity = new BaremEntity();
    if (baremA) {
      baremEntityA.objectId = baremA.objectId;
      baremEntityA.barem.codice = baremA.barem.code;
      baremEntityA.barem.descrizione = baremA.barem.description;
    }

    const baremEntityB: BaremEntity = new BaremEntity();
    if (baremB) {
      baremEntityB.objectId = baremB.objectId;
      baremEntityB.barem.codice = baremB.barem.code;
      baremEntityB.barem.descrizione = baremB.barem.description;
    }

    this.closeModal({ baremEntityA, baremEntityB });
  }

  /**
   * Funzione attivata alla selezione del bottone "Annulla" o alla fine della funzione
   * attivata dal bottone "Conferma"
   * @param event Chiusura della modale
   */
  closeModal(event: any) {
    this.modalClose.emit(event);
  }

  /**
   * Funzione che attiva o disattiva il bottone "Conferma"
   */
  enableModalButton() {
    let enableButtonCondition = true;

    if (this.requiredBaremA) {
      if (!this.baremsList.find(res => res.isSelectedA)) {
        enableButtonCondition = false;
      }
    }

    if (this.requiredBaremB) {
      if (!this.baremsList.find(res => res.isSelectedB)) {
        enableButtonCondition = false;
      }
    }

    if (!this.requiredBaremA && !this.requiredBaremB &&
      (
        (this.baremsList.find(res => res.isSelectedA) && !this.baremsList.find(res => res.isSelectedB)) ||
        (this.baremsList.find(res => res.isSelectedB) && !this.baremsList.find(res => res.isSelectedA))
      )) {
      enableButtonCondition = false;
    }

    this.enableConfirmButton = enableButtonCondition;
  }

}

import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { RoutableComponent } from '@rgi/portal-ng-core';
import {
  CardComponent, CardComponentService, EnumType, SessionService,
} from '@rgi/digital-claims-common-angular';
import { ElaborationRequestDetails } from '../../dto/elaboration-request-details';
import { ElaborationRequestService } from '../../elaboration-request.service';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validator } from '@angular/forms';
import { RgiRxTranslationService } from '@rgi/rx/i18n';



enum RerunType {
  RESERVATION = '1',
  ONDEMAND = '2',
}

enum OutcomeState {
  AZIONE_PRENOTATA = '1',
  RERUN_PRENOTATA = '2',
  AZIONE_IN_ERRORE = '3',
  AZIONE_ESEGUITA = '4',
  AZIONE_ESEGUITA_CON_SCARTI = '5',
  RERUN_ANNULLATO = '6',
}

@Component({
  selector: 'claims-elaboration-request-detail',
  templateUrl: './elaboration-request-detail.component.html',
  styleUrls: ['./elaboration-request-detail.component.scss'],
})
export class ElaborationRequestDetailComponent extends CardComponent implements OnInit, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('modalSR', { static: true }) modal: any;
  r = RerunType;
  outcomeState = OutcomeState;

  public errorMessage: string = '';
  public errorMessage2: string;
  public errore: boolean;
  public today: NgbDate;
  public sessionServiceInject: any;
  public elaborationRequestService: ElaborationRequestService;
  public details: ElaborationRequestDetails;
  public status: EnumType;
  public outcome: string;
  public modalType: 'STOP' | 'RERUN';
  public rerunType: RerunType;
  public formDate: UntypedFormGroup;
  public rerunFailure: string;
  public rerunReserved: string;
  public stopSuc: string;
  public notStop: string;
  public modalError: string;
  @Output() eventSent = new EventEmitter<Response>();

  constructor(
    @Inject('sessionService') sessionServiceInject: any,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    elaborationRequestService: ElaborationRequestService,
    public formBuilder: UntypedFormBuilder,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    super(cardComponentService, sessionService);
    this.sessionServiceInject = sessionServiceInject;
    this.elaborationRequestService = elaborationRequestService;
    const today = new Date();
    this.today = new NgbDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );

    this.rxTranslationsService.translate('_CLAIMS_._RERUN_FAILURE')
    .subscribe( res => this.rerunFailure = res )
    this.rxTranslationsService.translate('_CLAIMS_._RERUN_RESERVED')
    .subscribe( res => this.rerunReserved = res )
    this.rxTranslationsService.translate('_CLAIMS_._STOPPED_SUCCESSFULLY')
    .subscribe( res => this.stopSuc = res )
    this.rxTranslationsService.translate('_CLAIMS_._NOT_STOPPED')
    .subscribe( res => this.notStop = res )
  }

  ngOnInit() {
    this.getElaborationRequestDetail();
    this.getStatus();
    this.validateDate();
  }

  getElaborationRequestDetail() {
    this.details =
      this.elaborationRequestService.getElaborationRequestDetailList();
  }

  rerun() {
    let parsedDate = null;
    if (
      this.formDate.controls.rerunDate.value &&
      this.rerunType === this.r.RESERVATION
    ) {
      parsedDate = this.formDate.controls.rerunDate.value.getTime();
    }
    this.elaborationRequestService
      .rerunRequest(
        this.details.elaborationData.elabId.toString(),
        this.rerunType,
        parsedDate
      )
      .subscribe((res: any) => {
        if (res.requestOutcome.code === 'OK') {
          this.modalOpen(this.rerunType, this.rerunReserved);
        } else {
          this.modalOpen(this.rerunType, this.rerunFailure,res.requestOutcome.description);
        }
        this.formDate.controls.rerunDate.setValue(null);
      });
  }

  validateDate() {
    this.formDate = this.formBuilder.group({
      rerunDate: [],
    });
  }

  stop(jsonId?: number) {
    this.elaborationRequestService
      .callStop(this.details.elaborationData.elabId.toString(), jsonId)
      .subscribe((res: any) => {
        if (res.requestOutcome.code === 'OK') {
          if (jsonId) {
            this.details.jsonData = this.details.jsonData.filter(
              (elem) => elem.jsonId !== jsonId
            );
          } else {
            this.details.queueData.queueActive = false;
          }
          this.modalOpen('STOP', this.stopSuc);
        } else {
          this.modalOpen('STOP', this.notStop,res.requestOutcome.description);
        }
      });
  }

  modalOpen(type, outcome, modalError?) {
    this.modalError='';
    this.outcome = outcome;
    this.modalType = type;
    this.modalError = modalError;
    this.modal.open();
  }

  modalClose() {
    this.modal.close();
    this.modalError='';
  }

  getStatus() {
    this.status = new EnumType();

    if (this.details.queueData.queueCancellationDate) {
      this.status.codice = '3';
      this.rxTranslationsService.translate('_CLAIMS_._FORCED_CLOSURE')
      .subscribe( res =>  this.status.descrizione = res )
    } else if (
      this.details.queueData.queueOutcomeState.code ===
        this.outcomeState.AZIONE_ESEGUITA ||
      this.details.queueData.queueOutcomeState.code ===
        this.outcomeState.RERUN_ANNULLATO
    ) {
      this.status.codice = '2';
      this.rxTranslationsService.translate('_CLAIMS_._CLOSED')
      .subscribe( res =>  this.status.descrizione = res )
    } else {
      this.status.codice = '1';
      this.rxTranslationsService.translate('_CLAIMS_.IN_PROGRESS')
      .subscribe( res =>  this.status.descrizione = res )
    }
  }

  back() {
    const session = this.getCurrentSession();
    this.elaborationRequestService.setElaborationRequestDetailList(null);
    this.sessionServiceInject.remove(session.idSession);
  }

  getCurrentSession() {
    return this.sessionServiceInject.list().find((el) => el.isActive);
  }
}

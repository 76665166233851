<div [formGroup]="formGroup" class="col-md-12 active">
  <div formArrayName="risks">
    <div *ngFor="let risk of risks; let i = index;" style="margin-top: 30px;">
      <div [formGroupName]="i">
      <div class="unit">
        <div class="unit-header row">
          <div class="col-sm-5 col-xs-12">
            <span [ngClass]="{ 'transparent' : !hasFactorsBy(risk)}">
              <i *ngIf="!isUnitBodyOpen(risk)" (click)="openUnitBody(risk)" class="rgifont rgi-plus"></i>
              <i *ngIf="isUnitBodyOpen(risk)" (click)="closeUnitBody(risk)"class="rgifont rgi-minus"></i>
            </span>
            <i *ngIf="isLocked(risk)" class="checkbox rgifont rgi-lock"></i>
            <pnc-checkbox
              [attr.data-qa]="risk.code"
              [disabled]="isLocked(risk)"
              [hidden]="isLocked(risk)"
              [formControlName]="'selected'" class="check"
              [checked]="risk.selected"
              [attr.disabled]="isLocked(risk) ? true : null">
              <span>{{ risk.description }}</span>
              <span>&nbsp;{{ risk.type }}</span>
              <lpc-help-icon [helpFile]="risk.helpFile" [title]="risk.description"></lpc-help-icon>
            </pnc-checkbox>
            <ng-container *ngIf="isLocked(risk)" >
              <span>{{ risk.description }}</span>
              <span>&nbsp;{{ risk.type }}</span>
              <lpc-help-icon [helpFile]="risk.helpFile" [title]="risk.description"></lpc-help-icon>
            </ng-container>
          </div>
          <div class="col-sm-7 col-xs-12 text-right unit-info row">
            <div class="col-sm-6 col-xs-6">
              <div *ngIf="!risk?.nextInstalment; else showNextInstallmentValues" >
                <ng-container *ngIf="shouldShowInsuredAmount(risk.type); else showInvestedPremium">
                  <span  translate>lpc_insured_amount</span>
                  <span>{{risk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                </ng-container>
                <ng-template #showInvestedPremium>
                  <span translate>lpc_invested_premium</span>
                  <span>{{risk?.firstInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                </ng-template>
              </div>
              <ng-template #showNextInstallmentValues>
                <ng-container *ngIf="shouldShowInsuredAmount(risk.type); else showInvestedPremium">
                  <span  translate>lpc_insured_amount</span>
                  <span>{{risk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                </ng-container>
                <ng-template #showInvestedPremium>
                  <span translate>lpc_invested_premium</span>
                  <span >{{risk.nextInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                </ng-template>
              </ng-template>

            </div>
            <div class="col-sm-6 col-xs-6" *ngIf="!risk?.nextInstalment; else showNextInstallmentGross">
              <span translate>lpc_FirstInstallment</span>
              <span>{{risk?.firstInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
            </div>
            <ng-template #showNextInstallmentGross>
              <div class="col-sm-6 col-xs-6">
                <span translate>lpc_next_installment_premium</span>
                <span>{{risk?.nextInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </div>
            </ng-template>

          </div>
        </div>
        <div class="unit-body clearfix row" *ngIf="isUnitBodyOpen(risk)" [ngClass]="{ 'transparent' : !hasFactorsBy(risk)}" >
          <lpc-factor-adapter *ngIf="!!risk?.factors" [summary]="false" #factorAdapter (changeValEmitter)="updateFactors($event, risk.code)"
          [form]="getRiskFactorForm(i)" [factors]="risk.factors">
        </lpc-factor-adapter>
        </div>
      </div>
     <div formArrayName="subRisks">
        <div class="unit subUnit" *ngFor="let subRisk of risk?.subRisks; let j = index;">
          <div [formGroupName]="j">
          <div class="unit-header row">
            <div class="col-sm-5">
              <span [ngClass]="{ 'transparent' : !hasFactorsBy(subRisk)}">
                <i *ngIf="!isUnitBodyOpen(risk,subRisk)" (click)="openUnitBody(risk, subRisk)" class="rgifont rgi-plus"></i>
                <i *ngIf="isUnitBodyOpen(risk,subRisk)"(click)="closeUnitBody(risk, subRisk)" class="rgifont rgi-minus"></i>
              </span>
              <i *ngIf="isLocked(subRisk)" class="checkbox rgifont rgi-lock"></i>
              <pnc-checkbox
                [disabled]="isLocked(subRisk)"
                [attr.data-qa]="subRisk.code"
                [formControlName]="'selected'" class="check"
                [checked]="subRisk.selected"
                [attr.disabled]="isLocked(subRisk) ? true : null">
                <span>{{subRisk.description}}</span>
                <lpc-help-icon [helpFile]="subRisk.helpFile" [title]="subRisk.description"></lpc-help-icon>
              </pnc-checkbox>
            </div>
            <div class="col-sm-7 text-right unit-info row">
              <div class="col-sm-6">
                <div *ngIf="!subRisk?.nextInstalment; else showNextInstallmentSubValues" >
                  <ng-container *ngIf="shouldShowInsuredAmount(risk.type) && subRisk.insuredAmount; else showInvestedPremium" >
                    <span translate>lpc_insured_amount</span>
                    <span>{{subRisk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                  </ng-container>
                  <ng-template #showInvestedPremium>
                    <span translate>lpc_invested_premium</span>
                    <span >{{subRisk?.firstInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                  </ng-template>
                </div>
                <ng-template #showNextInstallmentSubValues>
                  <ng-container *ngIf="shouldShowInsuredAmount(risk.type) && subRisk.insuredAmount; else showInvestedPremium">
                    <span translate>lpc_insured_amount</span>
                    <span>{{subRisk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                  </ng-container>
                  <ng-template #showInvestedPremium>
                    <span translate>lpc_invested_premium</span>
                    <span >{{subRisk?.nextInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                  </ng-template>
                </ng-template>
              </div>
              <div *ngIf="showFirstInstallment && !risk?.nextInstalment; else showNextInstallment" [attr.data-qa]="'FirstInstalment_'+risk?.firstInstalment?.gross" class="col-sm-6">
                <span translate>lpc_FirstInstallment</span>
                <span>{{subRisk?.firstInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </div>
              <ng-template #showNextInstallment>
                <div [attr.data-qa]="'FirstInstalment_'+risk?.nextInstalment?.gross" class="col-sm-6">
                  <span translate>lpc_next_installment_premium</span>
                  <span>{{subRisk?.nextInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
          <div class="unit-body clearfix row" *ngIf="isUnitBodyOpen(risk, subRisk)" [ngClass]="{ 'transparent' : !hasFactorsBy(subRisk)}">
               <lpc-factor-adapter *ngIf="!!subRisk?.factors"
                  [summary]="false" #factorAdapter1
                  (changeValEmitter)="updateFactors($event, subRisk.code)"
                  [form]="getRiskFactorForm(i,j)" [factors]="subRisk?.factors">
               </lpc-factor-adapter>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>

<!-- SUMMARY SECTION -->

<div class="col-md-12 padding-top-life-15 inactive">
  <div *ngFor="let risk of risks;">
    <div class="unit" *ngIf="risk.selected">
      <div class="unit-header row">
        <div class="col-sm-5 col-xs-12">
          <span for="value">{{ risk.description }}</span>
          <span for="value"></span>
        </div>
        <div class="col-sm-7 col-xs-12 text-right unit-info row">
          <div class="col-sm-6 col-xs-6">
            <div *ngIf="!risk?.nextInstalment; else showNextInstallmentValues" >
              <ng-container *ngIf="shouldShowInsuredAmount(risk.type); else showInvestedPremium">
                <span  translate>lpc_insured_amount</span>
                <span>{{risk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-container>
              <ng-template #showInvestedPremium>
                <span translate>lpc_invested_premium</span>
                <span>{{risk?.firstInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-template>
            </div>
            <ng-template #showNextInstallmentValues>
              <ng-container *ngIf="shouldShowInsuredAmount(risk.type); else showInvestedPremium">
                <span  translate>lpc_insured_amount</span>
                <span>{{risk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-container>
              <ng-template #showInvestedPremium>
                <span translate>lpc_invested_premium</span>
                <span >{{risk.nextInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-template>
            </ng-template>

          </div>
          <div class="col-sm-6 col-xs-6" *ngIf="!risk?.nextInstalment; else showNextInstallmentGross">
            <span translate>lpc_FirstInstallment</span>
            <span>{{risk?.firstInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
          </div>
          <ng-template #showNextInstallmentGross>
            <div class="col-sm-6 col-xs-6">
              <span translate>lpc_next_installment_premium</span>
              <span>{{risk?.nextInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
            </div>
          </ng-template>

        </div>
      </div>
    </div>

    <!-- SUBUNIT SECTION -->
    <div class="unit subUnit" *ngFor="let subRisk of risk.subRisks;">
      <div class="unit-header row" *ngIf="subRisk.selected">
        <div class="col-sm-5">
          <span for="value">{{ subRisk.description }}</span>
          <span for="value"></span>
        </div>
        <div class="col-sm-7 text-right unit-info row">
          <div class="col-sm-6">
            <div *ngIf="!risk?.nextInstalment; else showNextInstallmentSubValues" >
              <ng-container *ngIf="shouldShowInsuredAmount(risk.type) && subRisk.insuredAmount; else showInvPremium">
                <span translate>lpc_insured_amount</span>
                <span>{{subRisk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-container>
              <ng-template #showInvPremium>
                <span translate>lpc_invested_premium</span>
                <span>{{subRisk?.firstInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-template>
            </div>
            <ng-template #showNextInstallmentSubValues>
              <ng-container *ngIf="shouldShowInsuredAmount(risk.type) && subRisk.insuredAmount; else showInvPremium">
                <span translate>lpc_insured_amount</span>
                <span>{{subRisk.insuredAmount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-container>
              <ng-template #showInvPremium>
                <span translate>lpc_invested_premium</span>
                <span >{{subRisk?.nextInstalment?.purePremium | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
              </ng-template>
            </ng-template>
          </div>
          <div *ngIf="showFirstInstallment && !risk?.nextInstalment; else showNextInstallment" [attr.data-qa]="'FirstInstalment_'+risk?.firstInstalment?.gross" class="col-sm-6">
            <span translate>lpc_FirstInstallment</span>
            <span>{{subRisk?.firstInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
          </div>
          <ng-template #showNextInstallment>
            <div [attr.data-qa]="'FirstInstalment_'+risk?.nextInstalment?.gross" class="col-sm-6">
              <span translate>lpc_next_installment_premium</span>
              <span>{{subRisk?.nextInstalment?.gross | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  <!-- <div class="col-sm-7" *ngIf="!!investedAmountOnQuotation">
    <span for="label" translate>Investment amount:</span>
    <span for="value"> {{ investedAmountOnQuotation | currency:'EUR':'symbol':'1.2-2':'en-US' }}</span>
  </div> -->
</div>
</div>
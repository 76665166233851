import {GenericEntity} from './generic-entity';

export class Parameter extends GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public value: string,
    // 0: Lista, 1: Numeric, 3: Boolean (Si/No), 4: Date, 5: String, 6: Time
    public type: number,
    public parameterValues: Array<object>,
    public compulsory: boolean,
    public disabled: boolean,
    public required: boolean,
    public extraValues?: Array<object>
  ) {
    super(id, code, description);
  }
}


import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomNumberFormatDirective } from './lpc-custom-number-format.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CustomNumberFormatDirective],
  exports: [CustomNumberFormatDirective]
})
export class CustomNumberFormatModule { }

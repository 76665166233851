import {AnagTranslationsKeys} from './anag-translations-keys';
/* tslint:disable:max-line-length */
// Please keep the translation keys alphabetically ordered
export const ANAG_GL_ES: AnagTranslationsKeys = {
  _ANAG_: {
    _BTN_: {
      _ADD_: 'Engadir',
      _ADD_ADDRESS_: 'Engadir enderezo',
      _BACK_: 'Atrás',
      _CALCULATE_: 'Calcular',
      _CANCEL_: 'Cancelar',
      _CLOSE_: 'Pechar',
      _CONFIRM_: 'Confirmar',
      _DETAIL_: 'Información',
      _EDIT_: 'Editar',
      _EMPTY_: 'Baleiro',
      _FIND_: 'Atopar',
      _NEW_: 'Novo',
      _NEW_PAYMENT_: 'Novo pagamento',
      _NORMALIZE_: 'NORMALIZAR',
      _SAVE_: 'Gardar',
      _DELETE_: 'Suprimir',
      _DELETE_DEFINITIVELY_: 'Suprimir definitivamente',
      _HISTORY_: 'Historial',
      _LIMITATION_MANAGEMENT_: 'Xestión da limitación',
      _UNDO_: 'Desfacer',
      _CONTINUE_: 'Continúa'
    },
    _LABEL_: {
      _ACTIONS_: 'Accións',
      _ACTION_TERRORISM_: 'Información sobre terrorismo',
      _ADDRESS_: 'Enderezo',
      _AE_: 'IAE',
      _AGENCY_: 'Axencia',
      _ATI_ASSOCIATIONS_: 'Asociacións UTE',
      _BIRTH_DATE_: 'Data de nacemento',
      _BIRTH_SURNAME_: 'Nome propio',
      _BIRTH_PLACE_: 'Lugar de nacemento',
      _CITIZENSHIP_: 'Nacionalidade',
      _CITY_OF_BIRTH_: 'Cidade de nacemento',
      _CIVIL_STATE_: 'Estado civil',
      _CLIENT_NUMBER_: 'Número cliente',
      _COMPANY_NAME_: 'Nome da compañía',
      _CONTACTS_: 'Contactos',
      _CORPORATE_FORM_: 'Forma corporativa',
      _CORPORATE_SECTOR_: 'Sector corporativo',
      _CORPORATION_: 'Corporación',
      _COWORKER_: 'Código fiscal de compañeiro de traballo',
      _CREDIT_: 'Crédito',
      _CRITERIA_: 'Criterios',
      _DEBIT_: 'Débito',
      _DOCUMENT_: 'Documento',
      _DOCUMENT_NO_: 'Número de documento',
      _DOCUMENT_TYPE_: 'Tipo de documento',
      _EMAIL_: 'Correo electrónico',
      _EMAIL2_: 'Correo electrónico 2',
      _EMAIL3_: 'Correo electrónico 3',
      _EXPIRATION_DATE_: 'Data de vencemento',
      _FAX_NO_: 'Número de fax',
      _FEMALE_: 'Muller',
      _FILL_ID_DATA_: 'Cubra os datos de identificación da parte',
      _FISCAL_CODE_: 'Código fiscal',
      _FISCAL_CODE_VAT_NUMBER_: 'Código fiscal / NIF',
      _GENDER_: 'Xénero',
      _GENDER_IDENTITY_: 'Identidade de xénero',
      _IDENTIFICATION_DATA_: 'Datos de identificación',
      _INT_PREFIX_: 'Xur. Prefixo',
      _LANDLINE_NO_: 'Número fixo',
      _PHONE_NO_: 'Número Telefone',
      _LEADER_: 'Líder',
      _LINK_TYPE_: 'Tipo de ligazón',
      _LINKED_PARTIES_: 'Partes ligadas',
      _MALE_: 'Home',
      _MAIN_CONTACT_: 'Contacto principal',
      _MANAGEMENT_NODE_: 'Nodo de xestión',
      _MOBILE_NO_: 'Número móbil',
      _MOBILE_NO_2_: 'Número móbil',
      _MUNICIPALITY_: 'Municipio',
      _NAME_: 'Nome',
      _NATION_: 'Nación',
      _NODES_: 'Nodos',
      _NO_ADDRESS_MSG_: 'Sen enderezo',
      _NUMBER_: 'Número',
      _OPEN_DATE_: 'Data aberta',
      _PARTICIPANTS_: 'Participantes',
      _PARTIES_: 'Suxeitos',
      _PARTY_: 'Suxeito',
      _PARTY_DATA_: 'Datos da parte',
      _PARTY_MANAGEMENT_: 'Xestión da parte',
      _PARTY_MERGER_: 'Fusión de partes',
      _PARTY_NUMBER_: 'Número de parte',
      _PARTY_TYPE_: 'Tipo de parte',
      _PAYMENT_: 'Pagamento',
      _PERSON_TYPE_: 'Tipo de persoa',
      _PHYSICAL_: 'Física',
      _POTICALLY_EXP: 'Parte politicamente exposta',
      _PREFIX_: 'Prefixo',
      _PRIVACY_: 'Privacidade',
      _PRIVACY_CONSENSUS_DATE_: 'Data de aceptación da privacidade',
      _CHANGED_ON_: 'Modificacións',
      _PRODUCTION_NODES_: 'Nodos de produción',
      _PROFESSION_: 'Profesión',
      _PROFESSION_DETAIL_: 'Detalle de profesión',
      _REGISTERED_OFFICE_: 'Oficina rexistrada',
      _RELEASE_AUTHORITY_: 'Autoridade de emisión',
      _RELEASE_DATE_: 'Data de emisión',
      _RELEASE_LOCATION_: 'Localización da emisión',
      _REQUIRED_FIELD_: 'Requirido',
      _RESIDENCE_: 'Residencia',
      _ANTI_TERRORISM_: 'Loita contra o terrorismo',
      _RESIDENCE_REGISTERED_OFFICE_: 'Residencia / Oficina rexistrada',
      _SAE_: 'CNAE',
      _SALE_POINT_: 'Punto de venda',
      _SAVED_OK_: 'gardado correctamente',
      _SELECT_PARTY_: 'Seleccionar parte',
      _SEX_: 'Sexo',
      _STATUS_: 'Estado',
      _STATUS_NOT_PROCESSED_: 'Non procesado',
      _STATUS_PROCESSED_: 'Procesado',
      _SUBJECT_: 'Suxeito',
      _SUBJECT_DATA_: 'Datos da parte',
      _SUBJECT_TYPE_: 'Tipo de suxeito',
      _SUBJECT_UNIQUE_KEY_: 'Clave única de suxeito',
      _SUBJNO_: 'N.º de parte',
      _SUBSYSTEM_: 'Subsistema',
      _SUBSYSTEM_PERSON_ID_: 'ID de persoa do subsistema',
      _SURNAME_: 'Apelido',
      _SURNAMEORCOMPANY_: 'Apelido / Nome da compañía',
      _VOID_: ' ',
      _VAT_NUMBER_: 'NIF',
      _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Data editada de loita contra o terrorismo',
      _COUNTER_TERRORISM_INDICATOR_: 'Estado de loita contra o terrorismo',
      _DOMICILES_: 'Domicilios',
      _DOMICILE_: 'Domicilio',
      _PRINCIPAL_ADDRESS_: 'Enderezo principal',
      _AVAILABILITY_: 'Dispoñibilidade',
      _TYPE_: 'Tipo',
      _AVAILABILITY_START_DATE_: 'Data de inicio da dispoñibilidade',
      _AVAILABILITY_END_DATE_: 'Data de final da dispoñibilidade',
      _AVAILABLE_FROM_: 'Formulario dispoñible',
      _AVAILABLE_UNTIL_: 'Unidade dispoñible',
      _INTERNATIONAL_DIALLING_CODE_: 'Código de marcado internacional',
      _LOCAL_AREA_DIAL_CODE_: 'Código de marcado de área local',
      _YES_: 'Si',
      _NO_: 'Non',
      _COUNTERTERRORISM_STATUS_ : 'Estado de loita contra o terrorismo',
      _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_ : 'Data editada de loita contra o terrorismo',
      _ORIGIN_: 'Orixe',
      _LANGUAGE_: 'Lingua',
      _HONOR_TITLE_ : 'Título de honra',
      _SONS_: 'Número de nenos',
      _ROBINSON_LIST_: 'Listaxe Robinson',
      _CITIZENSHIP_PERMIT_: 'Permiso de residencia',
      _CITIZENSHIP_EXPIRE_: 'Residencia válida ata',
      _REG_COUNTRY_: 'País de rexistro',
      _EXTERNALS_IDENTIFIERS_: 'Identificadores externos',
      _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Identificadores Asunto Subsistema',
      _TAX_CLASSIFICATION_: 'Clasificacións fiscais',
      _RISK_CLASSIFICATION_ : 'Clasificación de risco',
      _REASON_CLASSIFICATION_ : 'Clasificación de razón',
      _BASIS_CLASSIFICATION_ : 'Clasificación de básico',
      _CLASSIFICATION_DATE_ : 'Data de clasificación',
      _COUNTRY_START_DATE_ : 'Data de inicio do país',
      _COUNTRY_END_DATE_ : 'Data de remate do país',
      _NO_TAX_CLASSIFICATIONS_ : 'Sen Clasificación Fiscal',
      _COUNTRY_ : 'País',
      _NOMINATIVE_ : 'Nominativo',
      _HISTORY_CONSULTATION_: 'consulta de historia',
      _DO_NOT_REPLY_: 'Non se establece',
      _DRIVING_LICENSE_TYPE_: 'Tipo de licenza de conducir',
      _DRIVER_QUALIFICATION_CARD_: 'Tarxeta de cualificación do condutor',
      _ADD_DRIVING_LICENSE_TYPE_: 'Agregar tipo de licenza de conducir',
      _PRIVACY_INFO_MSG_: 'gl-ES_The field consults the official advertising exclusion files known as the Robinson List (RL) and tells us whether the client is registered in the RL or not.\n' +
        ' \n' +
        'If the Robinson List is set to "Yes", we will not be able to send commercial communications.\n' +
        ' \n' +
        'If the Robinson List is set to "No", commercial communications will only be sent when the Exclude Advertising field is set to No.\n' +
        ' \n' +
        'If the client has not been consulted, the field appears with value "Do not Reply"',
       _LEI_CODE_: 'Legal Entity Identifier',
       _RATING_: 'Valoración',
      _RATING_DATE_: 'Data de valoración',
      _RATING_PERIOD_: 'Período de valoración',
      _RATING_USER_: 'Usuario',
      _RATING_ORIGIN_: 'Orixe',
      _RATING_HISTORY_: 'Historial de valoracións',
      _COWORKERS_: 'Traballadores',
      _COMPANY_: 'Entreprise'
    },
    _MSG_: {
      _ASK_DELETION_CONFIRM_: 'Confirma a eliminación do suxeito?',
      _DELETION_CONFIRMED_: 'O suxeito eliminouse correctamente',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Os campos marcados en vermello son obrigatorios ou conteñen valores incorrectos',
      _ERROR_DATE_: 'Suxeito menor de 16 anos. O consentimento ao tratamento dos datos enténdese prestado polos pais/titores legais.',
      _ERROR_DATE_SP_: 'Consentimento para o tratamento de datos persoais de menores de 14 anos',
      _INVALID_DATE_VALUE: 'Data non válida',
      _INVALID_FIELD_: 'Campo non válido',
      _LENGTHERR_: 'Lonxitude de campo non permitida',
      _LINK_ERROR_: 'Parte xa inserida',
      _LINK_ERROR_SAME_PARTY_: 'Imposible vincular unha parte a si mesma',
      _NO_ELEMENT_TO_SHOW_: 'Non hai elementos para amosar',
      _NO_LINKED_PARTIES_: 'Sen vínculos',
      _NO_RESULTS_: 'Sen resultados',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'Non se atopou o enderezo normalizado',
      _PATTERNERR_: 'O campo contén caracteres incorrectos',
      _REFINE_SEARCH_: 'A extracción é parcial. Afine a súa busca',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Complete polo menos un de: Apelido / Nome da compañía, código fiscal ou NIF, número de cliente, número de parte, número de telefone, código postal válido ou ID persoal do subsistema',
      _WRONG_INPUT_DATA_: 'O fluxo de xestión de partes invócase con valores de entrada non admitidos.',
      _INVALID_END_DATE_DOMICILE_: 'Inserir unha data de fin dispoñibilidade posterior á data de inicio de dispoñibilidade',
      _INVALID_START_DATE_DOMICILE_: 'Inserir unha data de inicio de dispoñibilidade anterior á data de fin de dispoñibilidade',
      _INHERITANCE_: 'Xa existe un rexistro coa mesma clave única na rede comercial. Prema confirmar se desexa empregar os datos do suxeito xa rexistrado no rexistro, en caso contrario non será posible continuar con esta mellora da clave única.',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'Xa existe unha clasificación fiscal válida para o país, tipo e período seleccionado',
      _INFO_HOMONYMIES_: 'Atención! Atopáronse partes que posiblemente sexan homonimias.\n' +
        'Seleccione un partido de la lista para acceder a editar sus datos o el botón CONTINÙA para continuar insertando el nuevo partido.',
      _INFO_NATURAL_PERSON_ALLOWED_: 'Persoa natural só permitido',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Só se permite entidade legal',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'A data de lanzamento debe ser anterior á data de vencemento',
      _IBAN_INVALID_ : 'Formato IBAN incorrecto',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Inserir unha data de vencemento posterior á data de emisión do documento',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: 'Confirmar a eliminación da clasificación fiscal actual?',
      _ASK_DELETION_DOCUMENT_CONFIRM_: 'Confirmar a eliminación del document actual?',
      _DOC_ALREADY_EXIST: 'Número de documento xa presente',
      _INSERT_DOC_TYPE: 'Introduce o tipo de documento antes de continuar.',
      _INVALID_FORMAT_: 'Formato no válido',
      _NORMALIZE_ERROR_MSG: 'É obrigatorio normalizar o enderezo'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Editar Parte',
  _ANAG_TITLE_PARTY_DETAIL_: 'Detalle da parte',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Xestión da parte',
  _ANAG_TITLE_PARTY_ZOOM_: 'Zoom da parte',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Resultados da busca',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: ''
};

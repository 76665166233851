<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels" (goToIthStep)="goToIthStep($event)"
                  xmlns="http://www.w3.org/1999/html"></re-issue-stepper>
<div *ngIf="stateManager.getState$() | async as assetState">
  <form [formGroup]="assetsForm" *ngIf="assetFormArray?.controls[0]">
    <form formArrayName="assets">
      <div rgi-rx-accordion>
        <rgi-rx-expansion-panel class="rgi-ui-form-group re-exp-pan-pad"
                                *ngFor="let assetInstance of assetState.assetInstances; let i = index"
                                [expanded]="i === togglePanel"
                                attr.data-qa="asset_{{i}}">
          <rgi-rx-expansion-panel-header class="panel-heading">
            <span data-qa="panel-button" class="rgifont float-left"></span>
            <span class="panel-name float-left">{{assetInstance.asset.name}}</span>
            <button *ngIf="canDeletetheInstance(assetInstance)" (click)="deleteAccordion($event,assetInstance,i)"
                    class="float-right delete-button">
              <span class="panel-delete rgifont rgi-ui-icon-delete"></span>
            </button>
          </rgi-rx-expansion-panel-header>
          <div *ngIf="assetFormArray.controls[i]">
            <form [formGroupName]="i">
              <div class="container-fluid">
                <div class="row" *ngIf="isAnagVisible(assetInstance)">
                  <div class="rgi-re-section-title">
                    <span class="rgi-ui-icon-users rgi-re-header-icon"></span>
                    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.SUBJECTS</span>
                  </div>
                  <div class="rgi-ui-grid-2">
                    <div class="rgi-ui-col">
                      <rgi-rx-drop-container formControlName="subjectName"
                                             label="{{'RE_ISSUE.INSURED_SUBJECTS' | translate}}"
                                             [select]="'searchOrCreateSubject'"
                                             [disabled]="getFirstSubjectMode(assetInstance.asset.code) == 2 && i==0"
                                             [selectData]="getSubjectDropContainerData(roleKeys.roleInsured, assetInstance)"
                                             viewField="nominative"
                                             (onValueChange)="anagSubjectSelected($event, assetInstance.asset.key, i, assetInstance.asset.code)"
                                             (onRemove)="removeSubjectFormData(i,assetInstance.asset.key, assetInstance.subject, assetInstance.asset.code)"
                      ></rgi-rx-drop-container>
                    </div>
                    <div class="rgi-ui-col">
                      <rgi-rx-form-field>
                        <label rgiRxLabel><span translate>RE_ISSUE.PLACE_OF_BIRTH</span></label>
                        <input rgiRxInput formControlName="birthPlace">
                      </rgi-rx-form-field>
                    </div>
                    <div class="rgi-ui-col">
                      <rgi-rx-form-field class="birth-date-field">
                        <div class="label-input-container">
                          <label rgiRxLabel><span translate>RE_ISSUE.DATE_OF_BIRTH</span></label>
                          <div class="input-button-container">
                            <input [rgiRxDateTimeInput]="birthDatePicker"
                                   style="flex: 1" data-qa="birthDate"
                                   formControlName="birthDate"
                                   selectMode="single"
                                   rangeSeparator="-"
                                   placeholder="gg-mm-yyyy" #inputToTriggerBirthDate="rgiRxDateTimeInput">
                            <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                                    [rgiRxDateTimeTrigger]="inputToTriggerBirthDate"></button>
                          </div>
                        </div>
                        <rgi-rx-date-time #birthDatePicker
                                          pickerType="calendar"
                                          [hour12Timer]="true"
                        ></rgi-rx-date-time>
                      </rgi-rx-form-field>
                    </div>
                    <div class="rgi-ui-col">
                      <rgi-rx-form-field>
                        <label rgiRxLabel translate>RE_ISSUE.FISCAL_CODE_VAT_NUMBER</label>
                        <input rgiRxInput formControlName="fiscalCode">
                      </rgi-rx-form-field>
                    </div>
                  </div>
                </div>
                <div *ngIf="addressCompliance && isAddressVisible(assetInstance)">
                  <div class="row">
                    <div class="rgi-re-section-title">
                      <span class="rgi-ui-icon-marker rgi-re-header-icon"></span>
                      <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.ADDRESS</span>&nbsp;
                    </div>
                  </div>
                  <div class="row" *ngIf="!cancelAddress">
                    <div class="rgi-re-address-header">
                      <span class="rgi-re-address-title addresses_min_width" translate>RE_ISSUE.MAIN</span>
                      <span class="rgi-re-address-title" translate>RE_ISSUE.DESCRIPTION</span>
                    </div>
                    <!-- TODO REDUCE TO RGI-UI CSS -->
                    <re-issue-address [addressData]="addressData[assetInstance.asset.key]"
                                      (selectedAddressEmitter)="selectedAddressEmitter($event)"
                                      [formatAddress]="formatAddress"
                                      [firstAddressMode]="getFirstAddressMode(assetInstance.asset.code)"
                                      (deleteAddressEmitter)="deleteAddressEmitter($event)"
                                      [instanceAssetKey]="assetInstance.asset.key"
                                      [isMainAsset]="i==0"
                                      (createdAddressEmitter)="createdAddressEmitter($event, i)"
                                      (updateAddressEmitter)="updateAddressEmitter($event, i)"
                                      [maxAddress]="getMaxAddress(assetInstance.asset.code)">

                    </re-issue-address>
                  </div>
                </div>

                <re-issue-asset-activities
                  *ngIf="assetInstance.asset.activityPacket != null && assetInstance.asset.activitiesMax>0"
                  [activityPacket]="assetInstance.asset.activityPacket"
                  [secondaryActivityPacket]="assetInstance.asset.secondaryActivityPacket"
                  [assetKey]="assetInstance.asset.key"
                  [activitiesMin]="assetInstance.asset.activitiesMin"
                  [activitiesMax]="assetInstance.asset.activitiesMax"
                  (formValidEmitter)="activitiesFormValid($event)"
                ></re-issue-asset-activities>

                <div *ngIf="assetInstance.asset.factors?.length > 0" class="row">
                  <div class="rgi-re-section-title">
                    <span class="rgi-ui-icon-list-bars rgi-re-header-icon"></span>
                    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.FACTORS</span>&nbsp;
                  </div>
                  <div class="rgi-ui-grid-2">
                    <div *ngFor="let factor of assetInstance.asset.factors" class="rgi-ui-col">
                      <re-issue-factors [factor]="factor"
                                        (updateFactor)="onFactorChange($event, assetInstance.asset)"
                                        [factorControlForm]="getFormAsset(i)">
                      </re-issue-factors>
                    </div>
                  </div>
                </div>
                <!-- TODO : TAG => data-qa -->
                <div *ngIf="assetInstance.asset.clauses?.length>0">
                  <re-issue-clauses [showTitle]="true"
                                    [clausesList]="assetInstance.asset.clauses"
                                    [assetKey]="assetInstance.asset.key"
                                    (updateClause)="updateClause($event)"></re-issue-clauses>
                </div>
              </div>
            </form>
          </div>
          <div class="rgi-ui-grid">
            <div class="rgi-ui-col">
              <rgi-rx-snackbar tag="{{i}}_{{TAG_FORM_ERRORS}}"></rgi-rx-snackbar>
            </div>
          </div>
        </rgi-rx-expansion-panel>
      </div>
    </form>

  </form>

  <div class="rgi-ui-grid">
    <div class="rgi-ui-col">
      <rgi-rx-snackbar tag="{{TAG_ERRORS}}"></rgi-rx-snackbar>
    </div>
  </div>
  <div class="rgi-ui-grid rgi-ui-grid-no-gutter">
    <!-- BACK -->
    <div class="rgi-ui-col btn-group">
      <div class="story-grid-column-content">
        <button class="rgi-re-button" rgi-rx-button color="secondary" (click)="back()"
                data-qa="back"
                translate>RE_ISSUE.BACK
        </button>
      </div>
    </div>
    <!-- ADD NEW ASSET -->
    <div class="rgi-ui-col btn-group" *ngIf="thereIsAtLeastOneProductToAdd()">
      <div class="story-grid-column-content">
        <button class="rgi-re-button" rgi-rx-button color="secondary" (click)="addNewAsset()"
                data-qa="add asset" translate>RE_ISSUE.ADD_ASSET
        </button>
      </div>
    </div>
    <!-- SAVE -->
    <div class="rgi-ui-col btn-group" *ngIf="assetState.bIntermediateSaving">
      <div class="story-grid-column-content">
        <button class="rgi-re-button" rgi-rx-button color="secondary" [disabled]="isSaveDisabled"
                (click)="saveProposal(false)"
                data-qa="save_proposal" translate>RE_ISSUE.SAVE
        </button>
      </div>
    </div>
    <!-- NEXT -->
    <div class="rgi-ui-col btn-group">
      <div class="story-grid-column-content">

        <button class="rgi-re-button" rgi-rx-button (click)="goToNextPage()"
                [disabled]="assetsForm.invalid  && isFormSubmitted"
                color="primary" data-qa="next" translate>RE_ISSUE.NEXT
        </button>
      </div>
    </div>
  </div>
</div>

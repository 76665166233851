import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DscDamagedPartsService } from './dsc-damaged-parts.service';
import { ModalService } from '@rgi/rx/ui';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import { VehicleData } from '../dsc-shared-model/dsc-model-vehicle';
import { ClaimDetail } from '../dsc-shared-model/dsc-model-claims';
import { DamageOption, DamageParts, DamageVehicleDetail, PartDamageVehicle } from '../dsc-shared-model/dsc-model-damage-parts';
import { OutputDamageParts } from './dsc-damaged-parts.interface';
import { AddressEntityData } from '../dsc-shared-model/dsc-model-address';
import { DscAddressModal } from '../dsc-address-modal/dsc-address-modal.component';



@Component({
  selector: 'claims-dsc-damaged-parts',
  templateUrl: './dsc-damaged-parts.component.html',
  styleUrls: ['./dsc-damaged-parts.component.scss']
})
export class DscDamagedPartsComponent implements OnInit {

  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Input() vehicle: VehicleData;
  @Input() propertyAddress: AddressEntityData;
  @Input() claim: any;
  @Input() generatedClaim: ClaimDetail;
  @Output() sendDamageVehicleData = new EventEmitter<VehicleData>();
  @Output() outputAddressData = new EventEmitter<AddressEntityData>();

  damagedVehicle: DamageVehicleDetail;
  damagedVehicleForm: UntypedFormGroup;
  isCompanyVehicle = true;
  isCounterpartVehicleSelected = true;
  countParts: number = 0;
  body?: any;
  totalDistruction: boolean = false;
  otherDistruction: boolean = false;
  damageOption: DamageOption;
  damagedparts: string = 'claims/claim/damagedparts';
  baseApiUrlV2: string;
  partsDamagesVehicle: DamageParts[] = [];
  selectedOption: any;
  isAccordion: boolean = false;
  expanded: boolean = true;
  viewForm: boolean = false;
  options: DamageOption[] = [
    {descrizione: '_CLAIMS_._MESSAGE_._S_CONSISTENT', codice: '1'},
    {descrizione: '_CLAIMS_._MESSAGE_._N_NOT_CONSISTENT', codice: '2'},
    {descrizione: '_CLAIMS_._MESSAGE_._X_TO_BE_VERIFIED', codice: '3'}
  ];

  constructor(
    private dscDamagedPartsService: DscDamagedPartsService,
    private formBilder: UntypedFormBuilder,
    private modalServiceRgi: ModalService
  ) {

  }

  ngOnInit() {
    this.viewForm = false;
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
    this.getImageDamageVehicle();
    this.initializeFormDamagePart();
  }

  initializeFormDamagePart() {
    this.damagedVehicleForm = this.formBilder.group({
      totalDistruction: [null],
      otherInvVehicles: [null],
      visibleDamages: [''],
      observation: [''],
      damageOption: [null],
    });
    if (this.vehicle) {
      this.initForm();
      if (this.vehicle.damagedParts && this.vehicle.damagedParts.length > 0) {
        this.setdamagedParts(this.vehicle.damagedParts);
      }
    }
    this.viewForm = true;
  }

  setdamagedParts(damagedParts:any[]) {
    damagedParts.forEach(part => {
      this.partsDamagesVehicle.push(part);
      this.countParts = this.countParts + 1;
    });
  }

  initForm() {
    const totalDesctr = this.vehicle.damagedParts && this.vehicle.damagedParts.length >= 8 ? true : false;
    const blackboxCoherenceCode = this.vehicle.blackboxCoherence && this.vehicle.blackboxCoherence !== null ?
    this.vehicle.blackboxCoherence.codice : '';
    this.damagedVehicleForm.get('totalDistruction').setValue(totalDesctr);
    this.totalDistruction = totalDesctr;
    this.otherDistruction = this.vehicle.otherInvVehicles === true;
    this.damagedVehicleForm.get('otherInvVehicles').setValue(this.vehicle.otherInvVehicles);
    this.damagedVehicleForm.get('damageOption').setValue(blackboxCoherenceCode);
    this.damagedVehicleForm.get('observation').setValue(this.vehicle.observation);
    this.damagedVehicleForm.get('visibleDamages').setValue(this.vehicle.visibleDamages);
    console.log(this.vehicle, 'vehicle passed to damaged parts');
  }

  toggleDamagedPart(partDamageVehicle: PartDamageVehicle) {
    partDamageVehicle.damaged = !partDamageVehicle.damaged;
    const damaged: DamageParts = {
      damagedPart: {
        code: partDamageVehicle.code
      }
    };

    if (partDamageVehicle.damaged) {
      this.partsDamagesVehicle.push(damaged);
    }
    if (!partDamageVehicle.damaged) {
      const filterDamagesVehicle = this.partsDamagesVehicle.filter(
        (res: DamageParts) => !res.damagedPart.code.includes(partDamageVehicle.code));
      this.partsDamagesVehicle = filterDamagesVehicle;
    }
    this.countParts =  partDamageVehicle.damaged ? this.countParts + 1 : this.countParts - 1;
    this.totalDistruction = this.countParts === 8 ? true : false;
    this.sendDamageVehicleDataFunction();
  }

  toggleTotalDistruction(value: string) {
    if (value === 'TotalDistruction') {
      this.totalDistruction = !this.totalDistruction;
      if (this.totalDistruction) {
        this.damagedVehicle.top.damaged = true;
        this.damagedVehicle.topLeft.damaged = true;
        this.damagedVehicle.topRight.damaged = true;
        this.damagedVehicle.middleLeft.damaged = true;
        this.damagedVehicle.middleRight.damaged = true;
        this.damagedVehicle.bottomLeft.damaged = true;
        this.damagedVehicle.bottomRight.damaged = true;
        this.damagedVehicle.bottom.damaged = true;
        this.countParts = 8;
        this.partsDamagesVehicle = [];
        const damageTop: DamageParts = {damagedPart: {code: this.damagedVehicle.top.code}};
        const damageTopLeft: DamageParts = {damagedPart: {code: this.damagedVehicle.topLeft.code}};
        const damagTopRight: DamageParts = {damagedPart: {code: this.damagedVehicle.topRight.code}};
        const damageMiddleLeft: DamageParts = {damagedPart: {code: this.damagedVehicle.middleLeft.code}};
        const damageMiddleRight: DamageParts = {damagedPart: {code: this.damagedVehicle.middleRight.code}};
        const damageBottomLeft: DamageParts = {damagedPart: {code: this.damagedVehicle.bottomLeft.code}};
        const damageBottomRight: DamageParts = {damagedPart: {code: this.damagedVehicle.bottomRight.code}};
        const damagebottom: DamageParts = {damagedPart: {code: this.damagedVehicle.bottom.code}};
        this.partsDamagesVehicle = [damageTop, damageTopLeft, damagTopRight,
          damageMiddleLeft, damageMiddleRight, damageBottomLeft, damageBottomRight,
          damagebottom];
      } else {
        this.damagedVehicle.top.damaged = false;
        this.damagedVehicle.topLeft.damaged = false;
        this.damagedVehicle.topRight.damaged = false;
        this.damagedVehicle.middleLeft.damaged = false;
        this.damagedVehicle.middleRight.damaged = false;
        this.damagedVehicle.bottomLeft.damaged = false;
        this.damagedVehicle.bottomRight.damaged = false;
        this.damagedVehicle.bottom.damaged = false;
        this.countParts = 0;
        this.partsDamagesVehicle = [];
        this.sendDamageVehicleDataFunction();
      }

    }
    if (value === 'OtherDistruction') {
      this.otherDistruction = !this.otherDistruction;
    }
  }

 /*  getFormValue() {
    this.damagedVehicleForm.get('totalDistruction').setValue(this.totalDistruction);
    this.damagedVehicleForm.get('otherDistruction').setValue(this.otherDistruction);
    this.damagedVehicleForm.get('damageOption').setValue(this.damageOption.codice);
    this.damagedVehicleForm.value.otherDistruction = this.otherDistruction;
    this.damagedVehicleForm.value.damageOption = this.damageOption;
    this.vehicle.visibleDamages = this.damagedVehicleForm.value.visibleDamages;
    this.vehicle.observation = this.damagedVehicleForm.value.observation;
    this.sendDamageVehicleDataFunction();
  } */

  getVehicleOption(event: string) {
    if (event === '1') {
      this.damageOption = {descrizione: 'S Vehicle damage consistent with black box data', codice: '1'};
    }
    if (event === '2') {
      this.damageOption = {descrizione: 'N Vehicle damage not consistent with black box data', codice: '2'};
    }
    if (event === '3') {
      this.damageOption = {descrizione: 'X Consistency of the damage with the black box data to be verified', codice: '3'};
    }
    if (event === '') {
      this.damageOption = null;
    }
  }

  getImageDamageVehicle() {
    let selectedRisk;

    this.generatedClaim.contract.assets.forEach((asset) => {
      asset.risks.forEach((risk) => {
        if (risk && (risk.risk.description.indexOf('Responsabilità Civile') !== -1 || risk.risk.description.indexOf('Mandatory Third Party Liability') !== -1)) {
          risk = risk.risk.code;
        }
      });
    });

    const damageDescriptions = [{
      asset: {
        vehicle: this.generatedClaim.claimParties[0].damageDescriptions[0].asset.vehicle
      },
      assetType: '000001',
      risk: selectedRisk
    }];


    const body = {
      party: {
        damageDescriptions: damageDescriptions,
        partyRole: this.generatedClaim.claimParties[0].partyRole,
      }
    };
    this.dscDamagedPartsService.postDamagedParts(body).subscribe((result: OutputDamageParts) => {
      this.damagedVehicle = result.damagedVehicle;
    });
  }

  sendDamageVehicleDataFunction() {
    let data = this.vehicle;
    data.damageOption = this.damageOption;
    data.blackboxCoherence = this.damageOption;
    data.otherInvVehicles = this.damagedVehicleForm.get('otherInvVehicles').value;
    data.visibleDamages = this.damagedVehicleForm.get('visibleDamages').value;
    data.observation = this.damagedVehicleForm.get('observation').value;
    data.damagedParts = this.partsDamagesVehicle;
    this.sendDamageVehicleData.emit(data);
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }

  openModalAddAddress() {
    this.modalServiceRgi.open(DscAddressModal,
      {
        address: this.propertyAddress
      })
    .onClose.subscribe((res: AddressEntityData) => {
      this.propertyAddress = res;
      this.outputAddressData.emit(this.propertyAddress);
  });
  }
  deleteAddress() {
    this.propertyAddress = new AddressEntityData();
  }
}

<rgi-rx-expansion-panel attr.data-qa="consultation-policy-details" [expanded]="false">
  <rgi-rx-expansion-panel-header class="rgi-cc-accordion-title-header">
    <span rgi-rx-expansion-panel-label translate>CONTRACT_CONSULTATION.SUBJECTS</span>
  </rgi-rx-expansion-panel-header>

  <div class="rgi-ui-col rgi-ui-grid-3">
    <div *ngFor="let subject of contract.subjects" class="rgi-ui-col">
        <consultation-row [type]="'TEXT'" [label]="subject.type.description" [value]="subject.subject.nominative"></consultation-row>
    </div>
  </div>

  <lib-consultation-assets [assets]="contract.assets"></lib-consultation-assets>
</rgi-rx-expansion-panel>


import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { ElaborationRequestSearchComponent } from './elaboration-request-search/elaboration-request-search.component';
import { RoutingService, RoutingHostDirective } from '@rgi/portal-ng-core';

@Component({
  selector: 'claims-elaboration-request',
  templateUrl: './elaboration-request.component.html',
  styleUrls: ['./elaboration-request.component.scss'],
  providers: [RoutingService]
})

// export class ElaborationRequestComponent extends CardComponent implements AfterViewInit, OnInit {
export class ElaborationRequestComponent extends CardComponent implements AfterViewInit {
  @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;
  routes: { search: any };

  constructor(
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('elaborationRequestSearchComponent') elaborationRequestSearchComponent,
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      search: elaborationRequestSearchComponent
    };
  }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;

    this.routingService.loadComponent(this.routes.search);
    (this.routingService.componentRef.instance as ElaborationRequestSearchComponent).card = this.card;

  }

}






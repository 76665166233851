import {
  PncPsalesRequiredDataStateManager
} from '../../../state-managers/required-data/required-data-state-manager.service';
import {ActiveRoute} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPostSalesOrchestratorService} from '../../../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../../../services/pnc-postsales-integration.service';
import {PncPsalesHttpErrorService} from '../../../services/pnc-postsales-http-error.service';
import {Observable, of} from 'rxjs';
import {PncPsalesRequiredDataState} from '../../../resources/states/required-data-state';
import {catchError, concatMap, map} from 'rxjs/operators';
import {PncPostSalesCreateDraftResponse} from '../../../resources/model/api/contract-operations';
import {RgiFormField} from '../../../resources/model/common/rgi-form-field';
import {PncDomainService} from '../../../resources/http/pnc-domain.service';
import {dateDtoToFormField, PncPostsalesGenericMandatoryDates, stringDtoToFormField} from '../../../resources/model/api/dates';
import {formatISO} from 'date-fns';
import {PNC_PSALES_ACTIONS} from '../../../resources/constants/actions';
import {PncPsalesForm} from '../../../resources/model/common/form';
import { SUMMARY_SECTION_DETAIL_TYPE } from '../../../resources/model/common/summary-step-detail';
import { LabeledEntity } from '../../../resources/model/common/entities';

export class PncPsalesRequiredDataStateManagerDates extends PncPsalesRequiredDataStateManager {

  routePrefix: string;
  datesFormName = 'datesForm';
  effectiveDateCode = 'EFFECTIVE_DATE';
  effectHourCode = 'EFFECT_HOUR';
  issueDateCode = 'ISSUE_DATE';
  expiryDateCode = 'EXPIRY_DATE';

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private resourceService: PncDomainService,
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
    this.routePrefix = context.apiPrefix;
    if (!this.routePrefix) {
      throw Error('Missing apiPrefix in context');
    }
    this.registerOnFormFieldChange(
      this.datesFormName,
      [this.effectiveDateCode, this.issueDateCode, this.expiryDateCode],
      (state, field, value) => this.updateDates$(of(state), field, value)
    );
  }

  initState$(state: Observable<PncPsalesRequiredDataState>): Observable<PncPsalesRequiredDataState> {
    return state.pipe(
      concatMap((st: PncPsalesRequiredDataState) => {
        this.pushMessageHandler.clearTag(this.activeRoute.route);
        return this.resourceService.createPostSalesDraft$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix).pipe(
          map((resp: PncPostSalesCreateDraftResponse) => {
            st.draftId = resp.resourceId;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesRequiredDataState) => {
        return this.resourceService.getDraftDates$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix, st.draftId).pipe(
          map((resp: PncPostsalesGenericMandatoryDates) => {
            st.dates = resp;
            let fields = [];
            fields.push(dateDtoToFormField(this.issueDateCode, '_PCPSALES_._LABEL_._ISSUE_DATE_', st.dates.issueDate));
            fields.push(dateDtoToFormField(this.effectiveDateCode, '_PCPSALES_._LABEL_._APPENDIX_EFFECT_DATE_', st.dates.effectDate));
            if (st.dates.expiryDate) {
              fields.push(dateDtoToFormField(this.expiryDateCode, '_PCPSALES_._LABEL_._APPENDIX_EXPIRY_DATE_', st.dates.expiryDate));
            }
            fields.push(stringDtoToFormField(this.effectHourCode, '_PCPSALES_._LABEL_._EFFECT_HOUR_', st.dates.effectHour));
            st.forms[this.datesFormName] = new PncPsalesForm(fields);
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
  }

  private updateDates$(state$: Observable<PncPsalesRequiredDataState>, field: string, value: any) {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    return state$.pipe(
      concatMap((st: PncPsalesRequiredDataState) => {
        if (field === this.effectiveDateCode) {
          st.dates.effectDate.value = formatISO(value, {representation: 'date'});
          st.forms[this.datesFormName].fields.find(f => f.code === this.effectiveDateCode).value = st.dates.effectDate.value;
          return this.resourceService.setDraftDates$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix, st.draftId, st.dates).pipe(
            map((resp: PncPostsalesGenericMandatoryDates) => {
             st.dates.effectHour.value = resp.effectHour.value;
              st.forms[this.datesFormName].fields.find(res => res.code === this.effectHourCode).value = resp.effectHour.value;
             return st;
            }),
            catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
           );
        } else if (field === this.issueDateCode) {
          st.dates.issueDate.value = formatISO(value, {representation: 'date'});
          st.forms[this.datesFormName].fields.find(f => f.code === this.issueDateCode).value = st.dates.issueDate.value;
          return of(st);
        } else if (field === this.expiryDateCode) {
          st.dates.expiryDate.value = formatISO(value, {representation: 'date'});
          st.forms[this.datesFormName].fields.find(f => f.code === this.expiryDateCode).value = st.dates.expiryDate.value;
          return of(st);
        } else {
          return of(st);
        }

      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
  }

  onAction(action: string): Observable<PncPsalesRequiredDataState> | void {
    switch (action) {
      case PNC_PSALES_ACTIONS.BACK:
        this.actionBack();
        break;
      case PNC_PSALES_ACTIONS.CONTINUE:
        this.actionContinue();
        break;
    }
  }

  actionContinue() {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const st$ = of(this.getCurrentState()).pipe(
      concatMap((st) => {
        return this.resourceService.setDraftDates$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix, st.draftId, st.dates)
          .pipe(
            map(() => {
              this.setSummaryData(st);
              this.orchestrator.goToNextStep(st, this.activeRoute);
              return st;
            }),
            catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
          );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
    this.updateState$(st$);
  }

  setSummaryData(st: PncPsalesRequiredDataState) {
    const expireDate = st.dates.expiryDate?.value ?
      [new LabeledEntity('_PCPSALES_._LABEL_._APPENDIX_EXPIRY_DATE_', st.dates.expiryDate.value)] : [];
    st.summaryStepDetail = {
      stepLabel: '_PCPSALES_._STEPS_._VARIATION_DATES_',
      sections: [
        {
          detail: {
            type: SUMMARY_SECTION_DETAIL_TYPE.KEYVALUES,
            data: [
              new LabeledEntity('_PCPSALES_._LABEL_._APPENDIX_EFFECT_DATE_', st.dates.effectDate.value),
              new LabeledEntity('_PCPSALES_._LABEL_._EFFECT_HOUR_', st.dates.effectHour.value),
              new LabeledEntity('_PCPSALES_._LABEL_._ISSUE_DATE_', st.dates.issueDate.value)
            ].concat(expireDate)
          }
        }
      ]
    };
  }
}

import {Brand} from './brand';

export class Model {

  constructor(
    public modelCode: string,
    public description: string,
    public precCod: string,
    public creationDate: Date,
    public updatingDate: Date,
    public brand: Brand
  ) {
  }
}

<rgi-rx-expansion-panel data-qa="substitutions" color="default">
  <rgi-rx-expansion-panel-header>
    <span rgi-rx-expansion-panel-label translate="CONTRACT_CONSULTATION.SUBSTITUTIONS"></span>
  </rgi-rx-expansion-panel-header>
  <div *ngIf="substituentsAmendments.length>0">
    <rgi-rx-datatable  [data]="substituentsAmendments"
                       [schema]="substituentsTableSchema" rgi-rx-qa="substituentsAmendments-table" qa-ctx="substituentsAmendments-table-context"
                       [pageOptions]="pageOptions">
      <ng-template rgiRxDataTableCell="contractNumber" let-ctx>
        {{ctx.row.policyNumber ? ctx.row.policyNumber : ctx.row.proposalNumber}}
      </ng-template>
      <ng-template rgiRxDataTableCell="effectDate" let-ctx>
        {{ctx.row.effectiveDate | date: DATEFORMAT }}
      </ng-template>
      <ng-template rgiRxDataTableCell="substitutionEffectDate" let-ctx>
        {{ctx.row.operationEffectiveDate | date: DATEFORMAT }}
      </ng-template>
      <ng-template rgiRxDataTableCell="product" let-ctx>
        {{ctx.row.infoProduct?.description}}
      </ng-template>
      <ng-template rgiRxDataTableCell="substitutionReason" let-ctx>
        {{ctx.row.substitutionReasonDescription}}
      </ng-template>
    </rgi-rx-datatable>


  </div>

  <div *ngIf="replacedPolicies.length>0">
    <rgi-rx-datatable [data]="replacedPolicies"
                      [schema]="replacedPoliciesSchema" rgi-rx-qa="replacedPolicies-table" qa-ctx="replacedPolicies-table-context"
                      [pageOptions]="pageOptions" >

      <ng-template rgiRxDataTableCell="contractNumber" let-ctx>
        {{ctx.row.replacedPolicyNumber}}
      </ng-template>
      <ng-template rgiRxDataTableCell="effectDate" let-ctx>
        {{ctx.row.effectiveDateReplacedPolicy | date: DATEFORMAT }}
      </ng-template>
      <ng-template rgiRxDataTableCell="product" let-ctx>
        {{ctx.row.productInfoReplacedPolicy?.description}}
      </ng-template>
      <ng-template rgiRxDataTableCell="substitutionEffectDate" let-ctx>
        {{ctx.row.effectiveDateReplacement | date: DATEFORMAT }}
      </ng-template>
      <ng-template rgiRxDataTableCell="substitutionReason" let-ctx>
        {{ctx.row.reasonDescriptionReplacement}}
      </ng-template>


    </rgi-rx-datatable>
  </div>

</rgi-rx-expansion-panel>

import {RgiRxRouteData} from './router.api';
import {every, forEach, isArray, isObject, isPlainObject, isSymbol} from 'lodash';

export function isParentLink(link: string): boolean {
  return link.startsWith('../');
}

export function removeRelativeRefsFromLink(link: string): string {
  return link.replace(/(\.\.\/){1}|(\.\/){1}/g, '');
}


export function isRelativeLink(link: string): boolean {
  return link.startsWith('./');
}


export function removeLeadingAndTrailingSlashes(link: string): string {
  let normalized = link;
  if (normalized.startsWith('/')) {
    normalized = normalized.substr(1);
  }
  if (normalized.endsWith('/')) {
    normalized = normalized.slice(0, -1);
  }
  return normalized;
}

export function cleanWildcard(path: string): string {
  return path.replace('{', '').replace('}', '');
}


export enum RgiRxRouterRouteDataValidationType {
  NESTED_OBJECT,
  NESTED_COLLECTION,
  SYMBOL
}

type RgiRxRouterRouteDataValidation = {
  [key: string]: {
    value: unknown, message: string, errorType: RgiRxRouterRouteDataValidationType
  }
}

const RgiRxRouterDataValidationMessages = {
  [RgiRxRouterRouteDataValidationType.NESTED_OBJECT]: 'Nested object not allowed',
  [RgiRxRouterRouteDataValidationType.NESTED_COLLECTION]: 'Nested collection not allowed',
  [RgiRxRouterRouteDataValidationType.SYMBOL]: 'Symbol type may be cause issues with serialization'
}

export function validateRouteData(routeData: RgiRxRouteData<unknown>): RgiRxRouterRouteDataValidation {
  if (!routeData || !isObject(routeData)) {
    return {};
  }
  let errors: RgiRxRouterRouteDataValidation = {};
  forEach(routeData, (value, key) => {
    if (isObject(value)) {

      if (isPlainObject(value)) {
        errors[key] = {
          value,
          errorType: RgiRxRouterRouteDataValidationType.NESTED_OBJECT,
          message: RgiRxRouterDataValidationMessages[RgiRxRouterRouteDataValidationType.NESTED_OBJECT]
        }
        return;
      }

      else if(isSymbol(value)) {
        errors[key] = {
          value,
          errorType: RgiRxRouterRouteDataValidationType.SYMBOL,
          message: RgiRxRouterDataValidationMessages[(RgiRxRouterRouteDataValidationType.SYMBOL)]
        }
      }
      else if(every(value, (item) => {
        return isObject(item) || isArray(item);
      })) {
        errors[key] = {
          value,
          errorType: RgiRxRouterRouteDataValidationType.NESTED_COLLECTION,
          message: RgiRxRouterDataValidationMessages[(RgiRxRouterRouteDataValidationType.NESTED_COLLECTION)]
        }
      }
    }
  });
  return errors;
}

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {
  ContractPayment,
  EnumPayment,
  EnumSettlementType,
  MeanOfPayment,
  PaymentConfig,
  PaymentsField
} from '../payments-data';
import {mapPayment} from '../adapter/utils/payments-utils';
import {TranslateService} from '@ngx-translate/core';
import {RgiCountryLayerValidators} from '@rgi/country-layer';

export class PaymentModalData {
  paymentToEdit: MeanOfPayment;
  config: PaymentConfig;
  isDebitBtnDisabled: boolean;
  isCreditBtnDisabled: boolean;

  constructor(config, paymentToEdit?) {
    this.config = config;
    this.paymentToEdit = paymentToEdit;
  }
}

@Component({
  selector: 'mic-rgi-payments-modal',
  templateUrl: './payments-modal.component.html'
})
export class PaymentsModalComponent implements OnInit, OnModalClose {
  payments: PaymentConfig;
  paymentToEdit: MeanOfPayment;
  selectedPayments: MeanOfPayment[];
  paymentFields: PaymentsField[];
  selectedPaymentMethod: any;

  editPaymentForm: UntypedFormGroup;
  paymentFieldsForm: UntypedFormGroup;

  selectedMethodLabel: string;
  isDebitBtnDisabled: boolean;
  isCreditBtnDisabled: boolean;

  modalClose: EventEmitter<any> = new EventEmitter<any>();

  invalidCardValidator: ValidatorFn = (): ValidationErrors | null => {
    const monthControl = this.paymentFieldsForm.get('dcardexpiremonth');
    const yearControl = this.paymentFieldsForm.get('dcardexpireyear');
    const monthControlValued = monthControl && monthControl.value;
    const yearControlValued = yearControl && yearControl.value;
    if (monthControlValued && yearControlValued) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (currentYear === parseInt(yearControl.value, 10) && currentMonth > parseInt(monthControl.value, 10)) {
        return {expiredCard: true};
      }
    }
    if ((monthControlValued && !yearControlValued) || (!monthControlValued && yearControlValued)) {
      return {onlyOneFieldValued: true};
    }
    return null;
  }

  constructor(
    public translateService: TranslateService,
    @Optional() @Inject(DIALOG_DATA) modalData: PaymentModalData
  ) {
    this.payments = modalData.config;
    this.paymentToEdit = modalData.paymentToEdit;
    this.isCreditBtnDisabled = modalData.isCreditBtnDisabled;
    this.isDebitBtnDisabled = modalData.isDebitBtnDisabled;
    this.editPaymentForm = new UntypedFormGroup({
      paymentMethod: new UntypedFormControl(undefined, [Validators.required]),
      payment: new UntypedFormControl(undefined, [Validators.required]),
    });
    this.paymentFieldsForm = new UntypedFormGroup({});
  }

  ngOnInit() {
    if (this.paymentToEdit) {
      this.manageInputPayment();
    }
    this.editPaymentForm.get('paymentMethod').valueChanges.subscribe(paymentMethod => {
      if (this.selectedPaymentMethod !== paymentMethod) {
        this.onPaymentMethodChange(paymentMethod);
        this.managePaymentFields(undefined);
        this.selectedPaymentMethod = paymentMethod;
      }
    });
    this.editPaymentForm.get('payment').valueChanges.subscribe(payment => {
      if (payment) {
        const mean = JSON.parse(payment);
        this.managePaymentFields(mean.paymentConfig.meanOfPayment.id);
      } else {
        this.managePaymentFields(undefined);
      }
    });
  }

  manageInputPayment() {
    const paymentMethod = this.paymentToEdit.paymentConfig.paymentType === '1' ? 'debit' : 'credit';
    const payment = this.paymentToEdit;
    this.updateLabel(paymentMethod);
    this.editPaymentForm.get('paymentMethod').setValue(paymentMethod);
    this.editPaymentForm.get('payment').setValue(JSON.stringify(payment));
    this.selectedPayments = this.paymentToEdit.paymentConfig.paymentType === '1' ? this.payments.debMeansOfPayment :
      this.payments.credMeansOfPayment;
    const paymentId = payment.paymentConfig.meanOfPayment.id;
    this.editPaymentForm.get('payment').setValue(
      this.stringify(
        this.selectedPayments.find(
          selectedPayment =>
            paymentId === selectedPayment.paymentConfig.meanOfPayment.id
        )
      )
    );
    this.managePaymentFields(paymentId, this.paymentToEdit.paymentConfig.paymentsFields);
  }

  onPaymentMethodChange(paymentMethod: string) {
    this.updateLabel(paymentMethod);
    this.selectedPayments = paymentMethod === 'debit' ? this.payments.debMeansOfPayment : this.payments.credMeansOfPayment;
    this.editPaymentForm.get('payment').reset(undefined, {emitEvent: false});
    this.editPaymentForm.get('payment').markAsUntouched();
    this.editPaymentForm.updateValueAndValidity();
  }

  protected managePaymentFields(paymentId: string, paymentFields?: PaymentsField[]) {
    if (paymentFields) {
      const editPaymentFields: PaymentsField[] = [];
      if (paymentId) {
        const configPaymentFields = this.selectedPayments.find(payment =>
          payment.paymentConfig.meanOfPayment.id === paymentId).paymentConfig.paymentsFields;
        if (configPaymentFields) {
          configPaymentFields.forEach((configPaymentField: PaymentsField) => {
            const paymentsField = paymentFields.find(paymentField => paymentField.name.toUpperCase() === configPaymentField.name.toUpperCase());
            if (paymentsField) {
              paymentsField.label = configPaymentField.label;
              editPaymentFields.push(paymentsField);
              paymentsField.mandatory = configPaymentField.mandatory;
            }
          });
        }
      }
      this.paymentFields = editPaymentFields;
    } else {
      this.paymentFields = paymentId ? this.selectedPayments.find(payment =>
        payment.paymentConfig.meanOfPayment.id === paymentId).paymentConfig.paymentsFields : undefined;
    }
    this.editPaymentForm.removeControl('paymentFields');
    this.paymentFieldsForm = new UntypedFormGroup({});
    if (this.paymentFields && this.paymentFields.length > 0) {
      this.paymentFields.forEach(field => {
        const validators = [];
        if (field.mandatory) {
          validators.push(Validators.required);
        }
        if (field.name.toUpperCase() === 'CIBAN' && (!field.value || !field.value.includes('*'))) {
          validators.push(RgiCountryLayerValidators.iban);
        }
        if (field.name.toUpperCase() === 'DCARDEXPE') {
          this.paymentFieldsForm.setValidators(this.invalidCardValidator);
          let monthValue;
          let yearValue;
          if (field.value) {
            const dateValue = new Date(field.value);
            monthValue = dateValue.getMonth() + 1;
            yearValue = dateValue.getFullYear();
          }
          this.paymentFieldsForm.setControl(field.name + '_MONTH', new UntypedFormControl(monthValue,
            {
              validators: [...validators, Validators.min(1), Validators.max(12)]
            }));
          this.paymentFieldsForm.setControl(field.name + '_YEAR', new UntypedFormControl(yearValue,
            {
              validators:
                [...validators, Validators.min(new Date().getFullYear())]
            }));
        } else {
          const paymentFormControl = new UntypedFormControl(field.value, validators);
          if (field.name.toUpperCase() === 'CIBAN' && field.value && field.value.includes('*')) {
            paymentFormControl.disable();
          }
          this.paymentFieldsForm.setControl(field.name, paymentFormControl);
        }
      });
    }
    this.paymentFieldsForm.setParent(this.editPaymentForm);
  }

  onCancel() {
    this.modalClose.emit();
  }

  onConfirm() {
    if (this.paymentFieldsForm.valid && this.editPaymentForm.valid) {
      if (this.paymentFields && this.paymentFields.length > 0) {
        this.paymentFields.forEach(paymentField => {
          if (paymentField.name === 'DCARDEXPE') {
            const monthForm = this.paymentFieldsForm.get('DCARDEXPE_MONTH').value;
            const yearForm = this.paymentFieldsForm.get('DCARDEXPE_YEAR').value;
            if (yearForm && monthForm) {
              let monthValue = '' + monthForm;
              if (monthValue.length === 1) {
                monthValue = '0' + monthValue;
              }
              paymentField.value = `${yearForm}-${monthValue}-01`;
            }
          } else {
            paymentField.value = this.paymentFieldsForm.get(paymentField.name).value;
          }
        });
      }
      const value = JSON.parse(this.editPaymentForm.get('payment').value);

      const mappedPayment: ContractPayment = mapPayment(
        value.paymentConfig.meanOfPayment,
        (this.editPaymentForm.get('paymentMethod').value === 'debit' ? EnumPayment.DEBIT : EnumPayment.CREDIT),
        EnumSettlementType.SIGN_INSTALLMENT,
        this.paymentFields);
      this.modalClose.emit(mappedPayment);
    }
  }

  getTitle() {
    return this.paymentToEdit ? 'Edit payment mean' : 'New payment mean';
  }

  updateLabel(paymentMethod: string) {
    this.selectedMethodLabel = this.translateService.instant(
      paymentMethod === 'debit' ? 'Debit payment mean' : 'Credit payment mean'
    );
  }

  stringify(mean: MeanOfPayment): string {
    return JSON.stringify(mean);
  }

}

<div *ngIf="stateManager.getState$() | async as state">
    <!-- OVERVIEW -->
    <div class="container-fluid table-white">
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{'ICD.Type' | translate}}:
                <span *ngIf="overview?.type === 0 ">{{ 'ICD.Stored' | translate }}</span>
                <span *ngIf="overview?.type === 1 ">{{ 'ICD.Not_stored' | translate }}</span>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.Function' | translate }}: <span>{{ overview?.functionality?.description || '-' }}</span>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.Product' | translate }}: <span>{{ overview?.product?.description || '-' }}</span></div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.Competence_date_from' | translate }}:
                <span>{{ overview?.competenceDateFrom | date:'dd/MM/yyyy' || '-' }}</span></div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.Competence_date_to' | translate }}:
                <span>{{ overview?.competenceDateTo | date:'dd/MM/yyyy' || '-' }}</span></div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.Status' | translate }}: <span>{{ overview?.status?.description || '-' }}</span></div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.ACTION' | translate }}: <span>{{ overview?.action?.description || '-' }}</span></div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.Mismatch_amount_from' | translate }}: <span>{{ overview?.mismatchAmountFrom || '-' }}</span>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                {{ 'ICD.Mismatch_amount_to' | translate }}: <span>{{ overview?.mismatchAmountTo || '-' }}</span>
            </div>
        </div>
    </div>

    <!-- TABLE DETAILS -->
    <div *ngIf="data && state.operationList  && state.noteList">
        <dashboard-detail-table [tableData]="data" [tableSchema]="schema" [pageOptions]="tablePageOptions"
                                [overview]="overview" [commissions]="state.commissionsData"
                                (eventChecked)="selectedCheck($event)"
                                [totalAllRisk]="totalAllRisk" [operation]="operation" [tableIsVisible]="tableIsVisible"
                                [dashBoardDetailForm]="dashBoardDetailForm" [checkBoxSelected]="checkBoxSelected">
        </dashboard-detail-table>
    </div>
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button rgi-rx-button class="btn pull-right" (click)="close()" color="secondary" translate>ICD.CLOSE</button>
        </div>
    </div>
</div>

export class AnagFlowData {
  partyRole?: string;
  nodeCode?: string;
  operation: string;
  productCode: string;
  productId: number;
  partyType: string;
  idParentSession: string;
  isQuote: boolean;

  constructor() {
  }
}

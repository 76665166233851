import {Mode} from '../group-policy-models/group-policy-mode';
import {GROUP_POLICY_MODE_FUNCTIONS, GROUP_POLICY_MODE_PROPERTIES} from '../group-policy-constants/general';

export const INQUIRY_READONLY_MODE = new Mode(GROUP_POLICY_MODE_PROPERTIES.MODES.READ_ONLY, [
  GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.ACTIONS_BUTTON_HIDE,
  GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.APP_INC_BUTTON_HIDE,
  GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.ISSUE_BUTTON_HIDE,
  GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.DUPLICATE_BUTTON_HIDE,
  GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.BACK_BUTTON_HIDE,
  GROUP_POLICY_MODE_FUNCTIONS.PARTIES.PREVENT_JUMP_TO_PARTIES,
]);

import { LabeledEntity } from "./entities";

export class PncPsalesSummaryStepDetail {
    stepLabel: string;
    sections: PncPsalesSummarySection[];
}

export class PncPsalesSummarySection {
    title?: string;
    detail: PncPsalesSectionDetail;
}

export class PncPsalesSectionDetail {
    type: SUMMARY_SECTION_DETAIL_TYPE;
    rows?: LabeledEntity[][];
    data?: LabeledEntity[];
}

export enum SUMMARY_SECTION_DETAIL_TYPE {
    ROWS = 'ROWS',
    KEYVALUES = 'KEYVALUES'
}
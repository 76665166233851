export class PanelStatus {
    public contentEnabled: boolean;
    public titleEnabled: boolean;
    public panelEnabled: boolean;
    public panelOpened: boolean;
    public state: PanelStatusState;
    public panelId: string;
}

export enum PanelStatusState {
    incomplete = 0,
    complete = 1
}

export class PanelStatusChange {
    oldStatus: PanelStatus;
    newStatus: PanelStatus;
}

import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {CdkPortalOutlet, TemplatePortal} from '@angular/cdk/portal';
import {RgiRxStepper} from '../rgi-rx-stepper-api';
import {RgiRxStepComponent} from '../rgi-rx-step/rgi-rx-step.component';
import {filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'rgi-rx-step-body',
  templateUrl: './rgi-rx-step-body.component.html',
  host: {
    role: 'tabpanel',
    '[attr.aria-expanded]': 'active'
  },
  styles: [
    `.rgi-rx-step-body-hidden {
      display: none;
    }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class RgiRxStepBodyComponent implements OnInit, OnDestroy {

  _portal: TemplatePortal;
  @ViewChild(CdkPortalOutlet) _portalOutlet: CdkPortalOutlet;
  private _step: RgiRxStepComponent;
  private _active: boolean;
  private _hidden: boolean;
  private selectionChangeSubscription: Subscription = Subscription.EMPTY;


  @Input() get step(): RgiRxStepComponent {
    return this._step;
  }

  set step(value: RgiRxStepComponent) {
    this._step = value;
  }

  @Input() get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  @Input() @HostBinding('class.rgi-rx-step-body-hidden') get hidden(): boolean {
    return this._hidden;
  }

  set hidden(value: boolean) {
    this._hidden = value;
  }

  constructor(private _stepper: RgiRxStepper, private vc: ViewContainerRef) {
  }

  ngOnInit(): void {
    if (!this._step._lazyContent) {
      this._portal = new TemplatePortal<any>(this.step.content, this.vc);
    } else if (this._stepper.selected === this.step) {
      this._portal = new TemplatePortal<any>(this.step._lazyContent.template, this.vc);
    }

    this.selectionChangeSubscription = this._stepper.selectionChange
      .pipe(
        filter(p => p.selectedStep === this.step && (!this._portal && !!this.step._lazyContent)),
        map(p => this._stepper.findStep(p.selectedStep))
      )
      .subscribe(
        next => {
          this._portal = new TemplatePortal<any>(this.step._lazyContent.template, this.vc);
        }
      );
  }

  ngOnDestroy(): void {
    this.selectionChangeSubscription.unsubscribe();
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DscCarBodyworkService {
    private baseApiUrl: string;
    urlTrustee: string;

    constructor(
        private httpClient: HttpClient,
        @Inject('environment') environment: any,
        @Inject('sessionService') private sessionService: any,
        @Inject('cardPassService') private cardPassService: any,
    ) {
        this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
        this.urlTrustee = '/claims/trustee';
    }

    goToNewGarageSession$(returnUrl: any) {
        const act = {
            jumpCommand: 'newCarBodywork',
            operation: {
                code: '001'
            }
        };
        const inquirySession = this.sessionService.list().find(res => res.name === 'newClaimsHome');
        const card = {
            integrationPass: undefined,
            id: inquirySession.id,
            idSession: inquirySession.idSession
        };
        card.integrationPass = this.getObjIntegrationDamageActions(act, null, returnUrl);
        this.cardPassService.createJump(card, card.integrationPass,
            'New Garage', 'newCarBodywork', 'newClaimsHome');
    }

    getObjIntegrationDamageActions(act, returnFunction, returnLink) {
        const paramNaviJump = {
            RGIRedirectOk: '/newGarage.navi',
        };
        const objNavi = {
            newSession: true,
            navReturnOk: returnLink,
            functionReloadCtrlParent: undefined
        };
        if (returnFunction) {
            objNavi.functionReloadCtrlParent = returnFunction;
        }
        return {
            paramsIntegrationPass : paramNaviJump,
            objNavigation : objNavi };
    }

}

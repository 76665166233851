<div [formGroup]="formGroup">
  <div class="profile-header">
    <span class="header-profile" translate>lpc_Investment_profiles</span>
  </div>
  <div>
      <lpc-karma-fund-buttons formControlName="profiles"
                              [definition]="profiles"
                              [totalAmount]="totalAmount"
                              [sliderProperty]="sliderProperty">
      </lpc-karma-fund-buttons>
  </div>
  <div formGroupName="funds">
    <div class="funds-header">
      <span class="header-funds" translate>lpc_investment_funds</span>
    </div>
    <div *ngFor="let profile of selectedProfiles">
      <div rgi-rx-accordion [multi]="true">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="accordion-text" translate>{{ profile.description }}</span>
          </div>
          <div content data-qa="panel-button">
            <lpc-karma-fund-buttons [formControlName]="profile.id.toString()"
              [definition]="profile.funds"
              [areProfiles]="false"
              [sliderProperty]="sliderProperty"
              [totalAmount]="getAmountOfProfile(profile.id.toString())">
            </lpc-karma-fund-buttons>
          </div>
        </rgi-rx-expansion-panel>
      </div>
    </div>
  </div>
</div>

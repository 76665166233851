import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';

export interface Logger {
  trace(message: any, ...optional: any[]): void;

  debug(message: any, ...optional: any[]): void;

  error(message: any, ...optional: any[]): void;

  warn(message: any, ...optional: any[]): void;

  info(message: any, ...optional: any[]): void;

  log(message: any, ...optional: any[]): void;
}


export enum LogLevel {
  TRACE,
  DEBUG,
  ERROR,
  WARN,
  INFO,
  LOG
}

export interface LoggerConfig {
  level: LogLevel;
  limit?: number;
}


export const DEFAULT_LOGGER_CONFIG: LoggerConfig = {
  level: LogLevel.ERROR,
  limit: 10
};


export function logLevelAsString(level: LogLevel): string {
  switch (level) {
    case LogLevel.DEBUG: {
      return 'debug';
    }
    case LogLevel.INFO: {
      return 'info';
    }
    case LogLevel.ERROR: {
      return 'error';
    }
    case LogLevel.TRACE: {
      return 'trace';
    }
    case LogLevel.WARN: {
      return 'warn';
    }
    default: {
      return 'log';
    }

  }
}

export abstract class LoggerStore implements ObservableLogsAware {
  /**
   * @description get all logs that matches the log level filter
   */
  abstract logs$(level?: LogLevel): Observable<LogEvent[]>;

  /**
   * @description get last log that matches the log level filter
   */
  abstract takeLast$(): Observable<LogEvent>;

}

export interface ObservableLogsAware {
  /**
   * A method that should return all the LogEvent available on the stream
   */
  logs$(level?: LogLevel): Observable<LogEvent[]>;

  /**
   * Get the last LogEvent as observable
   */
  takeLast$(): Observable<LogEvent>;
}

export interface LoggerStoreHandler extends ObservableLogsAware {
  push(message: any, level: LogLevel, ...optional: any[]);

  clear(level: LogLevel);

  clearAll();
}

export interface LogEvent {
  created: Date;
  message: any;
  options: any[];
  level: LogLevel;
}


export const RGI_RX_LOGGER_CONFIG = new InjectionToken<LoggerConfig>('RGI_RX_LOGGER_CONFIG');
export const RGI_RX_LOGGER_PLATFORM = new InjectionToken<boolean>('RGI_RX_LOGGER_PLATFORM');



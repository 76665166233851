import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiCurrencyPipe} from './rgi-currency.pipe';


@NgModule({
  declarations: [RgiCurrencyPipe],
  imports: [
    CommonModule
  ],
  exports: [
    RgiCurrencyPipe
  ]
})
export class RgiCurrencyModule { }

export class TotalTaxes {
  constructor(
    public totalTaxable: number,
    public totalSSN: number,
    public totalTaxes: number,
    public totalTaxRates: number,
    public totalGrossAmounts: number,
    public totalAntiLaunderingMoneyTaxes: number,
    public totalTaxComponentsAmount: { [key: string]: number }
  ) {
  }
}

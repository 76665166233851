import {
  ReIssuePortfolioProposalService
} from './../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal.service';
import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map, mergeMap, share, take} from 'rxjs/operators';
import moment_ from 'moment';
import {ReIssueVariablesResourceService} from '../re-issue-resources/re-issue-variables-resource.service';
import {Variable} from '../../models/domain-models/variable';
import {ReIssuePolicyDataState} from '../re-issue-state-manager/re-issue-policy-data-state';
import {RgiRxLogger} from '@rgi/rx';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {
  ReIssuePortfolioContractorService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-contractor.service';
import {ContractorDataRequest} from '../../models/pc-portfolio-models/contractor-models/contractor-data-request';
import {ContractorDataResponse} from '../../models/pc-portfolio-models/contractor-models/contractor-data-response';
import {
  ReIssuePortfolioPaymentFrequencyResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-payment-frequency-resource.service';
import {
  ReIssuePortfolioAgreementsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-agreements-resource.service';
import {
  ReIssuePortfolioWarningsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-warnings-resource.service';
import {ReIssueGeneralDataResourceService} from '../re-issue-resources/re-issue-general-data-resource.service';
import {QuestionnaireFlatI} from '../../models/domain-models/questionnaire-flatI';
import {ReIssueQuestionnaireUtilityService} from '../re-issue-utility/re-issue-questionnaire-utility.service';
import {
  ReIssuePortfolioQuestionnairesResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-questionnaires-resource.service';
import {
  ReIssuePortfolioVariablesResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-variables-resource.service';
import {ReIssueClausesService} from '../re-issue-clauses/re-issue-clauses-service';
import {Ie11UtilityService} from '../re-issue-utility/ie-11-utility.service';
import {SaveProposalResponse} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import {
  ReIssuePortfolioProductCurrenciesResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-product-currencies-resource.service';
import {Currency} from '../../models/domain-models/parameters/currency';
import {AutomaticRenewal} from '../../models/domain-models/automatic-renewal';
import {
  ReIssuePortfolioAutomaticRenewalResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-automatic-renewal-resource.service';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {
  ReIssuePortfolioInstalmentResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-instalment-resource.service';
import {PcInstalmentDate} from '../../models/pc-portfolio-models/instalment-models/pc-instalment-date';
import {
  ReIssuePortfolioRegulationResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-regulation-resource.service';
import {
  RegulationPremium,
  RegulationType
} from '../../models/pc-portfolio-models/regulation-models/pc-regulation-premium';
import {
  ReIssuePortfolioLpsDataResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-lps-data-resource.service';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {Country} from '../../models/domain-models/country';
import {PcUseTax} from '../../models/pc-portfolio-models/us-taxes-models/pc-us-taxes';
import {
  ReIssuePortfollioUseTaxesResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-use-tax-resource.service';
import {CoInsuranceDTO, Company} from '../../models/domain-models/co-insurance';
import {
  RegulationPremiumResponse
} from '../../models/pc-portfolio-models/regulation-models/pc-regulation-premium-response';
import {
  ReIssuePortfolioCoInsuranceResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-co-insurance-resource.service';
import {
  ReIssuePortfolioIndexationResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-indexation-resource.service';
import {IndexType} from '../../models/domain-models/parameters/index-type';
import {PolicyGenericDto} from '../../models/pc-portfolio-models/policy-generic-dto';
import {
  ReIssuePortfolioCommonResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-common-resource.service';
import {PcAgreement} from '../../models/pc-portfolio-models/agreements-models/pc-agreement';
import {PcPaymentFrequency} from '../../models/pc-portfolio-models/payment-frequency-models/pc-payment-frequency';
import {ReIssueTechnicalDataResourceService} from '../re-issue-resources/re-issue-technical-data-resource.service';
import {ReIssueRefactorService} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service';
import {Clause} from "../../models/domain-models/clause";

@Injectable({
  providedIn: 'root'
})
export class ReIssuePolicyDataBusinessService<T extends ReIssuePolicyDataState> {
  private readonly WARNING_LEVEL = 'PRODUCT';
  private readonly QUESTIONNAIRE_WARNING_LEVEL = 'QUESTIONNAIRE';
  visibleVariables: Variable[] = [];

  constructor(
    protected variablesResourceService: ReIssueVariablesResourceService,
    protected techincalDetailsResourceService: ReIssueTechnicalDataResourceService,
    protected generalDataResourceService: ReIssueGeneralDataResourceService,
    protected portfolioContractorService: ReIssuePortfolioContractorService,
    protected paymentFrequencyResourceService: ReIssuePortfolioPaymentFrequencyResourceService,
    protected automaticRenewalResourceService: ReIssuePortfolioAutomaticRenewalResourceService,
    protected agreementsResourceService: ReIssuePortfolioAgreementsResourceService,
    protected useTaxesResourceService: ReIssuePortfollioUseTaxesResourceService,
    protected currenciesResourceService: ReIssuePortfolioProductCurrenciesResourceService,
    protected portfolioWarningsResourceService: ReIssuePortfolioWarningsResourceService,
    protected portfolioProposalService: ReIssuePortfolioProposalService,
    protected portfolioVariablesResourceService: ReIssuePortfolioVariablesResourceService,
    protected questionnaireUtilityService: ReIssueQuestionnaireUtilityService,
    protected questionnairesPortfolioResourceService: ReIssuePortfolioQuestionnairesResourceService,
    protected instalmentResourceService: ReIssuePortfolioInstalmentResourceService,
    protected regulationResourceService: ReIssuePortfolioRegulationResourceService,
    protected lpsDataResourceService: ReIssuePortfolioLpsDataResourceService,
    protected coInsuranceResourceService: ReIssuePortfolioCoInsuranceResourceService,
    protected indexationResourceService: ReIssuePortfolioIndexationResourceService,
    protected commonResourceService: ReIssuePortfolioCommonResourceService,
    protected logger: RgiRxLogger,
    protected refactorService: ReIssueRefactorService,
    protected clausesService: ReIssueClausesService,
    protected ie11Utility: Ie11UtilityService
  ) {
  }

  public reIssuePolicyDataState(id: string): T {
    return new ReIssuePolicyDataState(id) as T;
  }

  initForm$(state: Observable<T>, resourceId: string): Observable<T> {
    return state.pipe(
      take(1),
      mergeMap(st => {
        return combineLatest(
          this.techincalDetailsResourceService.get(st.resourceId),
          this.paymentFrequencyResourceService.getPaymentFrequency(st.resourceId),
          // this.questionnairesPortfolioResourceService.getQuestionnaires(st.resourceId), // TODO: Settare in base a configurazione prodotto
          this.getAllVariables$(st),
          this.getAllClauses$(st),
          this.coInsuranceResourceService.getCompanies(st.resourceId),
          this.coInsuranceResourceService.getIntermediaries(st.resourceId),
          this.getCoOwners$(st)
        ).pipe(
          take(1),
          mergeMap(   ([
                      tech,
                      paymentFrequency,
                      questionnaires,
                      ofState,
                      companies,
                      intermediaries,
                      coOwners
                    ]) => {
            ofState.contractorSubject = tech.subscriber
            ofState.policyTechnicalData = tech;
            ofState.resourceId = resourceId;
            ofState.policyTechnicalData.companies = companies;
            ofState.policyTechnicalData.intermediaries = intermediaries;
            ofState.coInsurance = tech.coInsurance;
            ofState.coOwners = coOwners;
            this.updateAdditionalTechnicalDataState(ofState, tech);
            // ofState.questionnaireList = questionnaires;
            // ofState.questionnairesComplete = this.questionnaireUtilityService.checkQuestionnairesCompleteness(questionnaires);
            if (paymentFrequency && paymentFrequency.code) {
              ofState = this.updatePaymentFrequencyState(ofState, paymentFrequency);
            }
            return of(ofState);
          })
        );
      }),
      share()
    );
  }

  private updateAdditionalTechnicalDataState(ofState, tech) {
    var dataState = ofState.policyTechnicalData.additionalTechnicalData;
    dataState.commonRiskPoliciesChecked = tech?.additionalTechnicalData?.commonRiskPolicies?.length > 0;
    dataState.secondRiskPoliciesChecked = tech?.additionalTechnicalData?.secondRiskPolicies?.length > 0;
    dataState.linkedPoliciesChecked = tech?.additionalTechnicalData?.linkedPolicies?.length > 0;
    dataState.complementaryPoliciesChecked = tech?.additionalTechnicalData?.complementaryPolicies?.length > 0;
    dataState.isComplementaryPoliciesSet = tech?.additionalTechnicalData?.complementaryPolicies?.length > 0;
    dataState.indirectCoinsuranceChecked = tech?.additionalTechnicalData?.indirectCoinsurancePolicies?.length > 0;
  }

  getAllRegulationTypes$(state: T): Observable<T> {
    return this.regulationResourceService.getRegulationTypes(state.resourceId).pipe(
      map((response: RegulationType[]) => {
        state.regulationTypes = response;
        return state;
      })
    );
  }

  putRegulationPremium$(state: T, regulationPremium: RegulationPremium) {
    return this.regulationResourceService.putRegulationPremium(state.resourceId, regulationPremium).pipe(
      map((response: RegulationPremiumResponse) => {
        state.policyTechnicalData.regulationPremiumResponse.regulationPremium = response.regulationPremium;
        state.policyTechnicalData.regulationPremiumResponse.messages = response.messages;
        return state;
      })
    );
  }

  getAllVariables$(state: T): Observable<T> {
    return this.variablesResourceService.get(state.resourceId).pipe(
      map(variables => {
        // state.variables = res;
        state.variables = [];
        // state.agreementFactors = [];
        variables.forEach((variable: any) => {
          if (!this.isQuotationVariable(variable)) {
            state.variables.push(variable);
          }
        });
        return state;
      })
    );
  }

  getAllClauses$(state: T): Observable<T> {
    const product = 'policy';
    return this.clausesService.getClauses(state.resourceId, product).pipe(
      map(clauses => {
        // state.variables = res;
        state.clauses = [];
        // state.agreementFactors = [];
        clauses.forEach((clause: any) => {
          if (!this.isQuotationClause(clause)) {
            state.clauses.push(clause);
          }
        });
        return state;
      })
    );
  }

  isQuotationClause(clause: Clause) {
    const QUOTATION_CLAUSE_KEY = 'CLAQUO';
    return (clause.properties || []).some(property => property.key === QUOTATION_CLAUSE_KEY && property.value);
  }

  isQuotationVariable(variable: any) {
    let response = false;
    const QUOTATION_VARIABLE_KEY = 'FATQUO';

    if (variable.properties) {
      variable.properties.forEach(property => {
        if (property.key === QUOTATION_VARIABLE_KEY && property.value) {
          response = true;
        }
      });
    }

    return response;
  }

  updateProposalForm$(st: T, val: any): Observable<T> {
    if (val.effectiveDate !== undefined) {
      const moment = moment_;
      st.policyTechnicalData.effectiveDate.date = moment(val.effectiveDate).utcOffset(0, true).format();
    }

    if (val.expireDate !== undefined) {
      const moment = moment_;
      st.policyTechnicalData.expireDate.date = moment(val.expireDate).utcOffset(0, true).format();
    }
    if (val.issueDate !== undefined) {
      const moment = moment_;
      st.policyTechnicalData.issueDate.date = moment(val.issueDate).utcOffset(0, true).format();
    }

    if (st.isSubstitution && st.isToDisableFraz && st.editFunctionality && st.editMode) {
      st.policyTechnicalData.expireDate.date = st.policyTechnicalData.expireDate.date;
    }

    if (val.binder !== undefined) {
      st.policyTechnicalData.binder = val.binder;
    }

    // else if (st.policyTechnicalData) {
    //   st.policyTechnicalData.expireDate.date = st.policyTechnicalData.effectiveDate.date;
    // }


    // TODO: si può fare la put solo della data effetto, qualsiasi modifica non viene rilevata
    if (val.tacitRenewal !== undefined) {
      st.policyTechnicalData.tacitRenewal.tacitRenewal = val.tacitRenewal;
    }

    return this.techincalDetailsResourceService.put(st.resourceId, st.policyTechnicalData).pipe(
      take(1),
      map(res => {
        st.policyTechnicalData = res;
        return st;
      })
    );
  }

  putInstalmentDate(state: T, instalmentDateSelected: string): Observable<T> {
    return this.instalmentResourceService.putInstalmentDate(state.resourceId, instalmentDateSelected).pipe(
      map((instalmentDate: PcInstalmentDate) => {
        state.policyTechnicalData.selectedInstalmentDate = instalmentDateSelected;
        state = this.updateInstalmentDateState(state, instalmentDate);
        return state;
      })
    );
  }

  putPaymentFrequency(state: T, paymentFrequencySelected: any): Observable<T> {
    return this.paymentFrequencyResourceService.putPaymentFrequency(
      state.resourceId,
      this.refactorService.paymentFrequencyRefactor(paymentFrequencySelected)
    ).pipe(
      mergeMap((paymentFrequency: PcPaymentFrequency) => {
        state.policyTechnicalData.selectedInstalmentDate = null;
        state = this.updatePaymentFrequencyState(state, paymentFrequency);
        const tacitRenewal = this.updateTacitRenewal(state);
        if (tacitRenewal !== state.policyTechnicalData.tacitRenewal.tacitRenewal) {
          return this.automaticRenewalResourceService.putAutomaticRenewal(state.resourceId, tacitRenewal);
        }
        return of(new AutomaticRenewal());
      }),
      mergeMap((automaticRenewal: AutomaticRenewal) => {
        return this.portfolioProposalService.getProposal(state.resourceId);
      }),
      map(res => {
        state.policyTechnicalData = this.refactorService.proposalToPolicyTechnicalData(
          state.policyTechnicalData,
          res
        );
        return state;
      })
    );
  }

  putAutomaticRenewal(state: T, automaticRenewalSelected: any): Observable<T> {
    return this.automaticRenewalResourceService.putAutomaticRenewal(
      state.resourceId,
      automaticRenewalSelected.tacitRenewal
    ).pipe(
      mergeMap((automaticRenewal: AutomaticRenewal) => {
        return this.portfolioProposalService.getProposal(state.resourceId);
      }),
      map(res => {
        state.policyTechnicalData = this.refactorService.proposalToPolicyTechnicalData(
          state.policyTechnicalData,
          res
        );
        return state;
      })
    );
  }

  // putAgreement(state: T, agreementSelected: any): Observable<T> {
  //   return this.agreementsResourceService.putAgreement(state.resourceId, agreementSelected).pipe(
  //     mergeMap((agreement: PcAgreement) => {
  //       state = this.updateAgreementState(state, agreement);
  //       return this.variablesResourceService.get(state.resourceId);
  //     }), map(res => {
  //       state.variables = res;
  //       return state;
  //     })
  //   );
  // }

  putAgreement(state: T, agreementSelected: any): Observable<T> {
    // let agreementRequest = agreementSelected;
    // if (!agreementRequest.agreement || agreementRequest.agreement === '') {
    //   agreementRequest.agreement = new Agreement(null, null, null, null);
    // }
    return this.agreementsResourceService.putAgreement(state.resourceId, agreementSelected).pipe(
      mergeMap((agreement: PcAgreement) => {
        state = this.updateAgreementState(state, agreement);
        return this.getAllVariables$(state);
      })
    );
  }

  putUseTax(state: T, useTaxSelected: any): Observable<T> {
    return this.useTaxesResourceService.putUseTax(state.resourceId, useTaxSelected).pipe(
      mergeMap((useTax: PcUseTax) => {
        state = this.updateUseTaxState(state, useTax);
        return this.getAllVariables$(state);
      })
    );
  }

  putCurrency(state: T, currencySelected: any): Observable<T> {
    if (currencySelected) {
      return this.currenciesResourceService.putProductCurrency(state.resourceId, currencySelected).pipe(
        mergeMap((currency: Currency) => {
          state.policyTechnicalData.selectedCurrency = currency;
          state = this.updateCurrencyState(state, currency);
          return this.getAllVariables$(state);
        })
      );
    }
  }

  updateAgreementState(state: T, agreement: PcAgreement): T {
    if (agreement) {
      state.policyTechnicalData.agreementContainer.agreements.forEach(element => {
        element.selected = false;
        if (element.code === agreement.code) {
          element.selected = true;
        }
      });
    } else if (
      state.policyTechnicalData &&
      state.policyTechnicalData.agreementContainer &&
      state.policyTechnicalData.agreementContainer.agreements
    ) {
      state.policyTechnicalData.agreementContainer.agreements.forEach(element => {
        element.selected = false;
      });
    }
    return state;
  }

  updateUseTaxState(state: T, useTax: PcUseTax): T {
    if (useTax) {
      state.policyTechnicalData.useTaxContainer.useTaxes.forEach(element => {
        element.selected = false;
        if (element.code === useTax.code) {
          element.selected = true;
        }
      });
    } else if (state?.policyTechnicalData?.useTaxContainer?.useTaxes ) {
      state.policyTechnicalData.useTaxContainer.useTaxes.forEach(element => {
        element.selected = false;
      });
    }
    return state;
  }

  updateTacitRenewal(state: T) {
    let tacitRenewal;
    const frCode = state.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.find(x => x.selected === true);
    if (!frCode || frCode.paymentFrequencyType === 1 || frCode.paymentFrequencyType === 0) {
      tacitRenewal = state.policyTechnicalData.tacitRenewal.tacitRenewal;
    } else {
      tacitRenewal = false;
    }
    return tacitRenewal;
  }

  updateCurrencyState(state: T, currency: Currency): T {
    if (currency) {
      state.policyTechnicalData.currencies.forEach(element => {
        element.selected = false;
        if (element.code === currency.code) {
          element.selected = true;
        }
      });
    } else if (
      state.policyTechnicalData &&
      state.policyTechnicalData.currencies
    ) {
      state.policyTechnicalData.currencies.forEach(element => {
        element.selected = false;
      });
    }
    return state;
  }

  updateInstalmentDateState(state: T, instalmentDate: PcInstalmentDate): T {
    state.variables.forEach(stateVariable => {
      if (stateVariable.code === instalmentDate.instalmentDate) {
        stateVariable.value = instalmentDate.instalmentDate;
      }
    });
    return state;
  }

  updatePaymentFrequencyState(state: T, paymentFrequency: PcPaymentFrequency): T {
    state.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach(element => {
      element.selected = false;
      if (element.code === paymentFrequency.code) {
        element.selected = true;
      }
    });
    return state;
  }

  updateProductVariables$(
    state: T,
    variable: any
  ): Observable<T> {
    state.variables.forEach(stateVariable => {
      if (stateVariable.code === variable.code) {
        stateVariable.value = variable.value;
      }
    });

    return this.portfolioVariablesResourceService.putVariables(
      state.resourceId,
      this.refactorService.toPcVariablesRefactor(state.variables)
    ).pipe(
      map(variables => {
        // state.variables = variables;
        state.variables = [];
        console.log('variables');
        console.log(variables);
        variables.forEach((internalVariable: any) => {
          if (!this.isQuotationVariable(internalVariable)) {
            state.variables.push(internalVariable);
          }
        });
        return state;
      })
    );
  }

  putSubject(state: T): Observable<T> {
    const ndg = state.contractorSubject.clientNumber;
    return this.generalDataResourceService.putSubjects(state.contractorSubject).pipe(
      mergeMap((response: any) => {
        state.contractorSubject = response.subject;
        if (ndg) {
          state.contractorSubject.clientNumber = ndg;
        }
        const dataToSend: ContractorDataRequest = new ContractorDataRequest();
        //  TODO: MISSING DATA
        dataToSend.id = response.subject.objectId ? Number(response.subject.objectId) : null;
        dataToSend.snapshotId = response.subject.idLatestPhotos ? Number(response.subject.idLatestPhotos) : null;
        return this.portfolioContractorService.postContractor(state.resourceId, dataToSend);
        // return state;
      }),
      map(response => {
        return state;
      })
    );
  }

  putIndexType$(state: T, indexType: IndexType): Observable<T> {
    return this.indexationResourceService.putIndexType(state.resourceId, indexType).pipe(
      map((response: any) => {
        state.policyTechnicalData.indexType = response || null;
        return state;
      })
    );
  }

  getLpsData$(state: T): Observable<T> {
    return this.lpsDataResourceService.getLpsData(state.resourceId).pipe(
      map((response: LpsData) => {
        state.lpsData = response;
        return state;
      })
    );
  }

  putLpsData$(state: T, lpsData: LpsData): Observable<T> {
    return this.lpsDataResourceService.putLpsData(state.resourceId, lpsData).pipe(
      map((response: LpsData) => {
        state.lpsData = response;
        let useTax: PcUseTax;
        if (response.policyLpsData.lpsType === '1') {
          useTax = new PcUseTax('2', null, null); // Exemption tax
        } else {
          useTax = new PcUseTax('1', null, null); // Base tax
        }
        this.putUseTax(state, useTax).subscribe();
        return state;
      })
    );
  }

  getLpsCountries$(state: T): Observable<T> {
    return this.lpsDataResourceService.getLpsCountries(state.resourceId).pipe(
      map((response: Country[]) => {
        state.policyTechnicalData.lpsCountries = response;
        return state;
      })
    );
  }

  getCompanies(state: T): Observable<T> {
    return this.coInsuranceResourceService.getCompanies(state.resourceId).pipe(
      map((response: Company[]) => {
        state.policyTechnicalData.companies = response;
        return state;
      })
    );
  }

  putQuestionnaire(state: T, questionnaireFlat: QuestionnaireFlatI): Observable<T> {
    state.questionnaireList = this.questionnaireUtilityService.updatePcQuestionnaireAnswers(
      questionnaireFlat,
      state.questionnaireList
    );

    return this.questionnairesPortfolioResourceService.putQuestionnaires(state.resourceId, state.questionnaireList).pipe(
      mergeMap(questionnaires => {
        state.questionnaireList = questionnaires;
        state.questionnairesComplete = this.questionnaireUtilityService.checkQuestionnairesCompleteness(questionnaires);
        state.questionnairesAdequacy = this.questionnaireUtilityService.checkQuestionnairesAdequacy(questionnaires);
        return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.QUESTIONNAIRE_WARNING_LEVEL);
      }),
      map(warnings => {
        state.questionnairesWarnings = warnings;
        return state;
      })
    );
  }

  submitForm$(resourceId: string, st: T, msg: string): Observable<T> {
    // TODO: CHECK VARIABLES PUT
    //  const varData: Variables = new Variables();
    //  varData.variables = st.variables;
    //  return this.variablesResourceService.put(resourceId, varData).pipe(
    //    mergeMap((variable: { variables: Variable[] }) => {
    //      st.variables = variable.variables;

    return this.portfolioWarningsResourceService.postWarnings(st.resourceId, this.WARNING_LEVEL).pipe(
      map((w: any) => {
        st.warnings = w;
        return st;
      })
    );

    // return of(st);
  }

  putSelectedSubject$(resourceId: string, subject: AnagIssueSubject, st: T): Observable<T> {
    const dataToSend: ContractorDataRequest = new ContractorDataRequest();
    //  TODO: MISSING DATA
    dataToSend.id = subject.objectId ? Number(subject.objectId) : null;
    dataToSend.snapshotId = subject.idLatestPhotos ? Number(subject.idLatestPhotos) : null;
    return this.portfolioContractorService.postContractor(resourceId, dataToSend).pipe(
      map((contractorData: ContractorDataResponse) => {
        st.contractorData = contractorData;
        return st;
      })
    );
  }

  putSelectedCoOwners$(resourceId: string, subject: AnagIssueSubject, st: T): Observable<T> {
    const dataToSend: ContractorDataRequest = new ContractorDataRequest();
    dataToSend.id = subject.objectId ? Number(subject.objectId) : null;
    dataToSend.snapshotId = subject.idLatestPhotos ? Number(subject.idLatestPhotos) : null;
    return this.portfolioContractorService.postCoOwners(resourceId, dataToSend).pipe(
      map(() => {
        return st;
    })
    );
  }

  deleteCoOwners$(resourceId: string, subjectId: string) {
     return this.portfolioContractorService.deleteCoOwner(resourceId, subjectId);
  }
  getCoOwners$(state: T) {
    return this.portfolioContractorService.getCoOwners(state.resourceId).pipe(
      share()
    );
  }


  saveProposal(state: T): Observable<T> {
    return this.portfolioProposalService.saveProposal(state.resourceId, this.constructSaveProposalRequestBody(state)).pipe(
      map((proposalResponse: SaveProposalResponse) => {
        state.proposal = proposalResponse.proposal;
        return state;
      })
      // map((res: ContractNumber) => {
      //   state.contractNumber = res;
      //   return state;
      // })
    );
  }

  constructSaveProposalRequestBody(state: T): any {
    return {
      additionalLabels: null,
      extensions: null
    };
  }

  protected getValueObjectByValue(val: any): GenericEntity {
    let resp: GenericEntity;
    if (val.value && val.variablesValue && val.variablesValue.length) {
      const values: Array<GenericEntity> = val.variablesValue;
      for (const varVal of val.variablesValue) {
        if (varVal.id === val.value) {
          resp = varVal;
          break;
        }
      }
    }
    return resp;
  }

  putCoInsuranceQuotas(state: T, coInsurance: CoInsuranceDTO) {
    coInsurance.coinsuranceQuotas.forEach(x => {
      // SETTING ID TO NULL BECAUSE IS GENERATED EACH TIME BACK END
      x.objectId = null;
    });
    return this.coInsuranceResourceService.putCoInsuranceQuotas(state.resourceId, coInsurance).pipe(
      map((coInsurance: CoInsuranceDTO) => {
        state.coInsurance = coInsurance;
        return state;
      })
    );
  }

  putIndirectCoInsurance(state: T, requestPolicy: PolicyGenericDto) {
    return this.coInsuranceResourceService.putIndirectCoInsurance(state.resourceId, requestPolicy).pipe(
      map((responsePolicy: PolicyGenericDto) => {
        // REFACTOR TO TRIGGER ON_CHANGES
        state.policyTechnicalData.additionalTechnicalData.indirectCoinsurancePolicies = this.buildList(state.policyTechnicalData.additionalTechnicalData.indirectCoinsurancePolicies, requestPolicy, responsePolicy);
        return state;
      })
    );
  }

  searchCommonRiskPolicy(state: T, policyDto: PolicyGenericDto) {
    return this.commonResourceService.searchCommonRiskPolicy(state.resourceId, policyDto).pipe(
      map((policy) => {
        state.accordionErrorMessage = policy.error.description;
        state.policyTechnicalData.additionalTechnicalData.commonRiskPolicies = this.refactorSearchPolicyList(state.policyTechnicalData.additionalTechnicalData.commonRiskPolicies, policy);
        return state;
      })
    );
  }

  deleteIndirectCoInsurance$(state: T, objectId: number) {
    return this.coInsuranceResourceService.deleteIndirectCoInsurance(state.resourceId, objectId).pipe(
      map(() => {
        const policyList = [];
        state.policyTechnicalData.additionalTechnicalData.indirectCoinsurancePolicies.forEach(x => {
          if (x.objectId !== objectId) {
            policyList.push(x);
          }
        });
        state.policyTechnicalData.additionalTechnicalData.indirectCoinsurancePolicies = policyList;
        return state;
      })
    );
  }

  deleteAllIndirectCoInsurancePolicies$(state: T) {
    return this.coInsuranceResourceService.deleteAllIndirectCoInsurancePolicies(state.resourceId).pipe(
      map(() => {
        state.policyTechnicalData.additionalTechnicalData.indirectCoinsurancePolicies = [];
        return state;
      })
    );
  }

  putSecondRiskPolicy$(state: T, requestPolicy: PolicyGenericDto) {
    return this.commonResourceService.putSecondRiskPolicy(state.resourceId, requestPolicy).pipe(
      map((responsePolicy: PolicyGenericDto) => {
        state.policyTechnicalData.additionalTechnicalData.secondRiskPolicies = this.buildList(state.policyTechnicalData.additionalTechnicalData.secondRiskPolicies, requestPolicy, responsePolicy);
        return state;
      })
    );
  }

  deleteSecondRiskPolicy$(state: T, objectId: number) {
    return this.commonResourceService.deleteSecondRiskPolicy(state.resourceId, objectId).pipe(
      map(() => {
        const policyList = [];
        state.policyTechnicalData.additionalTechnicalData.secondRiskPolicies.forEach(x => {
          if (x.objectId !== objectId) {
            policyList.push(x);
          }
        });
        state.policyTechnicalData.additionalTechnicalData.secondRiskPolicies = policyList;
        return state;
      })
    );
  }

  deleteAllSecondRiskPolicies$(state: T) {
    return this.commonResourceService.deleteAllSecondRiskPolicies(state.resourceId).pipe(
      map(() => {
        state.policyTechnicalData.additionalTechnicalData.secondRiskPolicies = [];
        return state;
      })
    );
  }


  deleteCommonRiskPolicy(state: T, policyNumber: string) {
    return this.commonResourceService.deleteCommonRiskPolicy(state.resourceId, policyNumber).pipe(
      map((deletedPolicy) => {
        state.policyTechnicalData.additionalTechnicalData.commonRiskPolicies = this.refactorDeletePolicyList(state.policyTechnicalData.additionalTechnicalData.commonRiskPolicies, deletedPolicy);
        return state;
      })
    );
  }
  deleteAllCommonRiskPolicies(state: T) {
    return this.commonResourceService.deleteAllCommonRiskPolicies(state.resourceId).pipe(
      map(() => {
        state.policyTechnicalData.additionalTechnicalData.commonRiskPolicies = [];
        return state;
      })
    );
  }
  isSamePolicy(policy1: PolicyGenericDto, policy2: PolicyGenericDto) {
    return policy1.objectId === policy2.objectId;
  }
  buildList(policies: PolicyGenericDto[], requestPolicy: PolicyGenericDto, responsePolicy: PolicyGenericDto) {
    const policyList = [];
    policies.forEach(policy => {
      if (responsePolicy.objectId === policy.objectId) {
        policyList.push(responsePolicy);
      } else {
        policyList.push(policy);
      }
    });
    if (!requestPolicy.objectId) {
      policyList.push(responsePolicy);
    }
    return policyList;
  }

  searchLinkedPolicy(state: T, policyDto: PolicyGenericDto) {
    return this.commonResourceService.searchLinkedPolicy(state.resourceId, policyDto).pipe(
      map((policy) => {
        state.accordionErrorMessage = policy.error.description;
        state.policyTechnicalData.additionalTechnicalData.linkedPolicies = this.refactorSearchPolicyList(state.policyTechnicalData.additionalTechnicalData.linkedPolicies, policy);
        return state;
      })
    );
  }
  deleteLinkedPolicy(state: T, policyNumber: string) {
    return this.commonResourceService.deleteLinkedPolicy(state.resourceId, policyNumber).pipe(
      map((deletedPolicy) => {
        state.policyTechnicalData.additionalTechnicalData.linkedPolicies = this.refactorDeletePolicyList(state.policyTechnicalData.additionalTechnicalData.linkedPolicies, deletedPolicy);
        return state;
      })
    );
  }
  deleteAllLinkedPolicies(state: T) {
    return this.commonResourceService.deleteAllLinkedPolicies(state.resourceId).pipe(
      map(() => {
        state.policyTechnicalData.additionalTechnicalData.linkedPolicies = [];
        return state;
      })
    );
  }
  searchComplementaryPolicy(state: T, policyDto: PolicyGenericDto) {
    return this.commonResourceService.searchComplementaryPolicy(state.resourceId, policyDto).pipe(
      map((policy) => {
        state.accordionErrorMessage = policy.error.description;
        state.policyTechnicalData.additionalTechnicalData.complementaryPolicies = this.refactorSearchPolicyList(state.policyTechnicalData.additionalTechnicalData.complementaryPolicies, policy);
        if (state.policyTechnicalData.additionalTechnicalData.complementaryPolicies.length > 0) { state.policyTechnicalData.additionalTechnicalData.isComplementaryPoliciesSet = true; }
        return state;
      })
    );
  }

  deleteComplementaryPolicy(state: T, policyNumber: string) {
    return this.commonResourceService.deleteComplementaryPolicy(state.resourceId, policyNumber).pipe(
      map((deletedPolicy) => {
        state.policyTechnicalData.additionalTechnicalData.complementaryPolicies = this.refactorDeletePolicyList(state.policyTechnicalData.additionalTechnicalData.complementaryPolicies, deletedPolicy);
        state.policyTechnicalData.additionalTechnicalData.isComplementaryPoliciesSet = false;
        return state;
      })
    );
  }

  deleteAllComplementaryPolicies$(state: T) {
    return this.commonResourceService.deleteAllComplementaryPolicies(state.resourceId).pipe(
      map(() => {
        state.policyTechnicalData.additionalTechnicalData.complementaryPolicies = [];
        state.policyTechnicalData.additionalTechnicalData.isComplementaryPoliciesSet = false;
        return state;
      })
    );
  }

  refactorSearchPolicyList(policyList: PolicyGenericDto[], policyDto: PolicyGenericDto) {
    const outputList = [];
    policyList.forEach(policy => {
      outputList.push(policy);
    });
    if (!policyDto.error.description) {
      outputList.push(policyDto);
    }
    return outputList;
  }
  refactorDeletePolicyList(policyList: PolicyGenericDto[], deletedPolicy: PolicyGenericDto) {
    const outputList = [];
    policyList.forEach(policy => {
      if (policy.policyNumber != deletedPolicy.policyNumber) {
        outputList.push(policy);
      }
    });
    return outputList;
  }
}

import {GenericEntity} from '../domain-models/generic-entity';

export class ApiAssetType extends GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public classId: string,
    public useId: string,
    public extendedDescription: string,
    public aniaVehicleTypeCode: string
  ) {
    super(id, code, description);
  }
}

import {NgModule} from '@angular/core';
import {PassproproAuthModule} from './auth/passpropro-auth.module';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DownloadModule} from "./download/download.module";
import {API_PATHS, DEFAULT_API_PATHS} from "./core.api";
import {NotificationModule} from "./notification/notification.module";
import {EnvironmentModule} from "./environment/environment.module";
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {NG_PASSPROPRO_CORE_TRANSLATIONS} from "./i18n";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    PassproproAuthModule,
    DownloadModule,
    NotificationModule,
    EnvironmentModule,
    RgiRxI18nModule.forRoot(NG_PASSPROPRO_CORE_TRANSLATIONS)
  ],
  exports : [
    PassproproAuthModule
  ],
  providers: [
    { provide: API_PATHS, useValue: DEFAULT_API_PATHS, multi: true }
  ]
})
class NgPassproproCoreModule {
}

export {
  NgPassproproCoreModule,
};

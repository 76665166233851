<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Change Vehicle</span>
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="changeVehicleForm">
          <pnc-tabset type="tabs" justify="fill" (tabChange)="onTabChange($event)">
            <pnc-tab id="{{ChangeVehicleTabs.CHANGE_PLATE}}" title="{{'Change Plate' | translate}}">
              <ng-template pncTabContent>
                <div class="form-group row">
                  <label translate>Plate Number</label>
                  <pnc-required required="true"></pnc-required>
                  <input #licensePlate [value]="licensePlate?.value | uppercase" class="form-control mic-input"
                         data-qa="licensePlate"
                         formControlName="licensePlate" type="text">
                </div>
                <div *ngIf="needToSetAssetType">
                  <div class="form-group row">
                    <label translate>Product</label>
                    <pnc-required required="true"></pnc-required>
                    <select #product (change)="onProductChange()" class="select-nativa mic-select-product"
                            formControlName="product"
                            name="product">
                      <option *ngFor="let product of products; index as i; trackBy: genericEntitiesTrackByFn"
                              [ngValue]="product">
                        {{ product?.extendedDescription ? product?.extendedDescription : product?.description }}
                      </option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                  </div>

                  <div *ngIf="isAssetTypeVisible" class="form-group row">
                    <label translate>Asset Type</label>
                    <pnc-required required="true"></pnc-required>
                    <select #assetType (change)="onAssetTypeChange()" class="select-nativa" formControlName="assetType"
                            name="assetType">
                      <option></option>
                      <option *ngFor="let assetType of assetTypes; index as i; trackBy: genericEntitiesTrackByFn"
                              [ngValue]="assetType">
                        {{ assetType?.extendedDescription ? assetType?.extendedDescription : assetType?.description }}
                      </option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                  </div>

                  <div *ngIf="showVehicleType()" class="form-group row">
                    <label translate>Vehicle Type</label>
                    <pnc-required required="true"></pnc-required>
                    <select #vehicleType (change)="onVehicleTypeChange()" class="select-nativa"
                            formControlName="vehicleType"
                            name="vehicleType">
                      <option></option>
                      <option *ngFor="let vehicleType of vehicleTypes; index as i; trackBy: genericEntitiesTrackByFn"
                              [ngValue]="vehicleType">
                        {{vehicleType?.description}}
                      </option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                  </div>

                  <div *ngIf="isPlateTypeVisible"
                       class="form-group row">
                    <label translate>Plate Type</label>
                    <pnc-required required="true"></pnc-required>
                    <select #plateType (change)="onPlateTypeChange()" class="select-nativa" formControlName="plateType"
                            name="plateType">
                      <option></option>
                      <option *ngFor="let plateType of plateTypes; index as i; trackBy: genericEntitiesTrackByFn"
                              [ngValue]="plateType">
                        {{plateType?.description}}
                      </option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                  </div>

                  <div *ngIf="isSpecialPlateTypeVisible"
                       class="form-group row">
                    <label translate>Special Plate Type</label>
                    <pnc-required required="true"></pnc-required>
                    <select #specPlateType (change)="onSpecialPlateTypeChange()" class="select-nativa mic-select-product"
                            formControlName="specPlateType" name="specPlateType">
                      <option></option>
                      <option *ngFor="let specPlateType of specPlateTypes; index as i; trackBy: genericEntitiesTrackByFn"
                              [ngValue]="specPlateType">
                        {{specPlateType?.description}}
                      </option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                  </div>

                  <div *ngIf="otherPlates" class="form-group row">
                    <div *ngIf="isPrefixVisible" class="col-sm-6 col-xs-12">
                      <label translate>Prefix:</label>
                      <pnc-required required="true"></pnc-required>
                      <select #specPlatePrefix class="select-nativa mic-select-product" formControlName="specPlatePrefix"
                              name="specPlatePrefix">
                        <option></option>
                        <option
                          *ngFor="let specPlatePrefix of specPlatePrefixes; index as i; trackBy: genericEntitiesTrackByFn"
                          [ngValue]="specPlatePrefix">
                          {{specPlatePrefix}}
                        </option>
                      </select>
                      <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                    </div>
                    <div *ngIf="isCountryVisible"
                         class="col-sm-6 col-xs-12">
                      <label translate>Country</label>
                      <pnc-required required="true"></pnc-required>
                      <select #countries class="select-nativa mic-select-product" formControlName="countries"
                              name="countries">
                        <option></option>
                        <option *ngFor="let country of countriesList; index as i; trackBy: genericEntitiesTrackByFn"
                                [ngValue]="country">
                          {{country?.description}}
                        </option>
                      </select>
                      <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                    </div>
                    <div *ngIf="isSpecialPlateNumberVisible" class="col-sm-6 col-xs-12">
                      <label translate>Plate Number</label>
                      <pnc-required required="true"></pnc-required>
                      <input #specialLicensePlate [value]="specialLicensePlate?.value | uppercase"
                             class="form-control mic-input" data-qa="specialLicensePlate"
                             formControlName="specialLicensePlate" type="text">
                    </div>
                  </div>
                </div>
              </ng-template>
            </pnc-tab>
            <pnc-tab id="{{ChangeVehicleTabs.CHANGE_ASSET}}" title="{{'Change Asset-Same Plate' | translate}}">
              <ng-template pncTabContent>
                <div class="form-group row">
                  <label translate>Product</label>
                  <pnc-required required="true"></pnc-required>
                  <select #product (change)="onProductChange()" class="select-nativa mic-select-product"
                          formControlName="product"
                          name="product">
                    <option></option>
                    <option *ngFor="let product of products; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="product">
                      {{ product?.extendedDescription ? product?.extendedDescription : product?.description }}
                    </option>
                  </select>
                  <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                </div>

                <div *ngIf="isAssetTypeVisible" class="form-group row">
                  <label translate>Asset Type</label>
                  <pnc-required required="true"></pnc-required>
                  <select #assetType (change)="onAssetTypeChange()" class="select-nativa" formControlName="assetType"
                          name="assetType">
                    <option></option>
                    <option *ngFor="let assetType of assetTypes; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="assetType">
                      {{ assetType?.extendedDescription ? assetType?.extendedDescription : assetType?.description }}
                    </option>
                  </select>
                  <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                </div>

                <div *ngIf="showVehicleType()" class="form-group row">
                  <label translate>Vehicle Type</label>
                  <pnc-required required="true"></pnc-required>
                  <select #vehicleType (change)="onVehicleTypeChange()" class="select-nativa"
                          formControlName="vehicleType"
                          name="vehicleType">
                    <option></option>
                    <option *ngFor="let vehicleType of vehicleTypes; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="vehicleType">
                      {{vehicleType?.description}}
                    </option>
                  </select>
                  <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                </div>

                <div *ngIf="isPlateTypeVisible"
                     class="form-group row">
                  <label translate>Plate Type</label>
                  <pnc-required required="true"></pnc-required>
                  <select #plateType (change)="onPlateTypeChange()" class="select-nativa" formControlName="plateType"
                          name="plateType">
                    <option></option>
                    <option *ngFor="let plateType of plateTypes; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="plateType">
                      {{plateType?.description}}
                    </option>
                  </select>
                  <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                </div>

                <div *ngIf="isSpecialPlateTypeVisible"
                     class="form-group row">
                  <label translate>Special Plate Type</label>
                  <pnc-required required="true"></pnc-required>
                  <select #specPlateType (change)="onSpecialPlateTypeChange()" class="select-nativa mic-select-product"
                          formControlName="specPlateType" name="specPlateType">
                    <option></option>
                    <option *ngFor="let specPlateType of specPlateTypes; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="specPlateType">
                      {{specPlateType?.description}}
                    </option>
                  </select>
                  <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                </div>

                <div *ngIf="otherPlates" class="form-group row">
                  <div *ngIf="isPrefixVisible" class="col-sm-6 col-xs-12">
                    <label translate>Prefix:</label>
                    <pnc-required required="true"></pnc-required>
                    <select #specPlatePrefix class="select-nativa mic-select-product" formControlName="specPlatePrefix"
                            name="specPlatePrefix">
                      <option></option>
                      <option
                        *ngFor="let specPlatePrefix of specPlatePrefixes; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="specPlatePrefix">
                        {{specPlatePrefix}}
                      </option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                  </div>
                  <div *ngIf="isCountryVisible"
                       class="col-sm-6 col-xs-12">
                    <label translate>Country</label>
                    <pnc-required required="true"></pnc-required>
                    <select #countries class="select-nativa mic-select-product" formControlName="countries"
                            name="countries">
                      <option></option>
                      <option *ngFor="let country of countriesList; index as i; trackBy: genericEntitiesTrackByFn"
                              [ngValue]="country">
                        {{country?.description}}
                      </option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                  </div>
                  <div *ngIf="isSpecialPlateNumberVisible" class="col-sm-6 col-xs-12">
                    <label translate>Plate Number</label>
                    <pnc-required required="true"></pnc-required>
                    <input #specialLicensePlate [value]="specialLicensePlate?.value | uppercase"
                           class="form-control mic-input" data-qa="specialLicensePlate"
                           formControlName="specialLicensePlate" type="text">
                  </div>
                </div>

              </ng-template>
            </pnc-tab>
          </pnc-tabset>

        </form>
        <div *ngIf="isValidationMessagesVisible" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>

      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="cancel()" class="btn btn-warning" data-qa="btn-prd-modal-cancel" translate>CANCEL</button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" [disabled]="!changeVehicleForm?.valid" class="btn btn-warning"
                    data-qa="btn-prd-modal-confirm"
                    translate>CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

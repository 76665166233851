import {Injectable} from '@angular/core';
import {ActiveRoute, RgiRxRouteData, RoutingService} from '@rgi/rx/router';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {OPERATIONSCODE} from '../../group-policy-constants/general';
import {ROUTES_GP_POLDATA} from '../../group-policy-constants/routes-constants';
import {GroupPolicyEditRouteData} from '../../group-policy-models/group-policy-edit';
import {DataForSteps, IdentificationKey} from '../../group-policy-models/group-policy-issue-home';
import {GroupPolicyResourceService} from '../../group-policy-resources/group-policy-resource.service';
import {GroupPolicyApiRestErrorService} from '../../group-policy-services/group-policy-api-rest-error.service';
import {GroupPolicyIntegrationService} from '../../group-policy-services/group-policy-integration.service';
import {GroupPolicyStateEdit} from '../group-policy-state';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpEditService {

  constructor(protected groupPolicyService: GroupPolicyResourceService,
              protected routingService: RoutingService,
              protected integrationService: GroupPolicyIntegrationService,
              protected gpErrorService: GroupPolicyApiRestErrorService) {
  }

  initEditProposal$(stateHome$: Observable<GroupPolicyStateEdit>, activeRoute: ActiveRoute): Observable<GroupPolicyStateEdit> {
    const editRouteData = activeRoute.getRouteData<GroupPolicyEditRouteData>();
    return stateHome$.pipe(
      mergeMap((firstState) => {
        firstState.errors = this.gpErrorService.cleanErrorsForCode(firstState.errors, 'FOOTER_EDIT');
        return this.groupPolicyService.editProposal(editRouteData.proposalNumber).pipe(
          map((resp: IdentificationKey) => {
            this.integrationService.navigate(
              this.routingService, ROUTES_GP_POLDATA, this.createObjForNextStep(resp, editRouteData), activeRoute);
            return firstState;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(firstState, 'FOOTER_EDIT'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateEdit) => st)
    );
  }

  protected createObjForNextStep(respNewFlowIssue: IdentificationKey,
                                 editRouteData: RgiRxRouteData<GroupPolicyEditRouteData>): DataForSteps {
    return {
      resId: respNewFlowIssue.uuid,
      operationCode: OPERATIONSCODE.EDIT_PROPOSAL,
      idParentSession: editRouteData.idParentSession,
      inquiryProposalNumber: editRouteData.proposalNumber,
      isFromInquiry: editRouteData.isFromInquiry,
      contractStatus: editRouteData.contractStatus,
      managementNode: editRouteData.managementNode
    } as DataForSteps;
  }

}



<div class="panel-body cld-block-header">
    <span class="{{damageCategoryCssClass}} cdl-icon">&nbsp;</span>
    <div class="cld-block-title">
        {{ '_CLAIMS_._CLAIM' | translate }} {{claim.claimNumber}} - 
        {{ '_CLAIMS_._POTENTIAL_CLAIM_INQUIRY' | translate }} {{claim.reportNumber}} - 
        {{ '_CLAIMS_._TECHNICAL_STATUS' | translate }}:&nbsp;
        {{ claimTechnicalStatusDescription }}
    </div>
</div>

<div class="panel-body cld-block-content">

    <div class="container-fluid table-white">

        <div class="row">

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._POLICY_NUMBER' | translate }}:&nbsp;</label>
                <label><b>{{ claim.policyNumber }}</b></label>
            </div>

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._POLICY_HOLDER' | translate }}:&nbsp;</label>
                <label><b>{{ policy.holder }}</b></label>
            </div>

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._ADJUSTER' | translate }}:&nbsp;</label>
                <label><b>{{ claim.codeAdjuster || claim.adjuster || '-' }}</b></label>
            </div>

        </div>

        <div class="row">

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._RESERVE_AMOUNT' | translate }}:&nbsp;</label>
                <label><b>{{ (claim.reserve | currency:'EUR':true) || '-' }}</b></label>
            </div>

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._INDEMNITY_AMOUNT' | translate }}:&nbsp;</label>
                <label><b>{{ (claim.amountIndemnity | currency:'EUR':true) || '-' }}</b></label>
            </div>

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._FEES_AMOUNT' | translate }}:&nbsp;</label>
                <label><b>{{ (claim.amountFees | currency:'EUR':true) || '-' }}</b></label>
            </div>

        </div>

        <div class="row">

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}:&nbsp;</label>
                <label><b>{{ claim.occurrenceDate | date:'dd/MM/yyyy'}}</b></label>
            </div>

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._TECHNICAL_REACTIVATION_OPENING_DATE' | translate }}:&nbsp;</label>
                <label><b>{{ (claim.technicalReactivationOpeningDate | date:'dd/MM/yyyy') || '-'}}</b></label>
            </div>

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._ACCOUNTING_REACTIVATION_OPENING_DATE' | translate }}:&nbsp;</label>
                <label><b>{{ (claim.accountingReactivationOpeningDate | date:'dd/MM/yyyy') || '-'}}</b></label>
            </div>

        </div>

        <div class="row">

            <div class="col-xs-6 col-md-4">
                <label>{{ '_CLAIMS_._TECHNICAL_CLOSING_DATE' | translate }}:&nbsp;</label>
                <label><b>{{ (claim.technicalClosingDate | date:'dd/MM/yyyy') || '-'}}</b></label>
            </div>

            <div class="col-xs-6 col-md-4">
            </div>

            <div class="col-xs-6 col-md-4">
            </div>

        </div>

        
        <div class="row">

            <div class="col-xs-12 col-md-12">
                <label>{{ '_CLAIMS_._OCCURRENCE_DESCRIPTION' | translate }}:&nbsp;</label>
                <label><b>{{ (claim.occurenceDescription) || '-' }}</b></label>
            </div>

        </div>

    </div>

</div>

<div *ngFor="let party of claim.claimParties">
    <claims-involved-party [party]="party"></claims-involved-party>
</div>

<div class="btn-group btn-group-justified">
    <div class="btn-group">
        <button type="button" class="btn btn-warning pull-right" (click)="exit()">
            <div>{{ '_CLAIMS__BUTTONS_._EXIT' | translate }}</div>
        </button>
    </div>
</div>


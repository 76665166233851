import { ModuleWithProviders, NgModule } from "@angular/core";
import { ANAG_MODAL_COMPONENTS, AnagModalComponentConfig } from "./anag-modal-service";

@NgModule()
export class RgiAnagModalModule {

    static forRoot(configs: AnagModalComponentConfig[]): ModuleWithProviders<RgiAnagModalModule> {
        return {
            ngModule: RgiAnagModalModule,
            providers:  [{
                provide: ANAG_MODAL_COMPONENTS, useValue: configs, multi: true
            }]
        };
    }
}

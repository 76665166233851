import { Injectable } from '@angular/core';
import { PolicyRole } from '../models/policy.model';
import { HttpClient } from '@angular/common/http';
import { LifeSessionService } from './life-session-service';
import { Observable } from 'rxjs';
import { PARTY_COMPLETE_KO, PartyCheck, PartyCheckErrorMessages, PartyCompleted } from '../models/party-service.model';
import { SubjectConstants } from '../enum/life-issue.enum';

@Injectable({
  providedIn: 'root'
})
export class ValidationSubjectsService {
  validationMessages = new Map();
  messages: string[] = [];
  policyRolesFilled = new Map<string, PolicyRole>();
  PARTY_CHECK_MSG_CODE = 'anag_check';


  constructor(protected httpClient: HttpClient,
              protected lifeSessionService: LifeSessionService) {
  }

  addMessage(code: string, message: string): string[] {
    this.validationMessages.set(code, message);
    this.validationMessages.forEach((value: string, key: string) => {
        if (key === code && !this.checkIfAddingDuplicateMessages(value)) {
          this.messages.push(value);
        }
      });
    return this.messages;
  }

  getAnagValidationMessages(): string[] {
    const KEY_POS = 0;
    const MSG_POS = 1;
    return [...this.validationMessages].filter(v => v[KEY_POS].startsWith('anag_')).map(v => v[MSG_POS]);
  }

  removeAllAnagValidations(): string[] {
    const KEY_POS = 0;
    [...this.validationMessages]
    .filter(v => v[KEY_POS].startsWith('anag_'))
    .map(v => v[KEY_POS]).forEach(key => {
      this.validationMessages.delete(key);
    });
    this.messages = [...this.validationMessages.values()];
    return this.messages;
  }

  removeMessage(code: string): string[] {
    this.validationMessages.forEach((value: string, key: string) => {
      if (key === code) {
        this.validationMessages.delete(key);
       }
    });
    this.messages = [...this.validationMessages.values()];
    return this.messages;
  }

  removeMessageByCodeList(codes: string[]): string[] {
    codes.forEach(c => this.removeMessage(c));
    return this.messages;
  }

  removeMessageByPattern(regex: RegExp): string[] {
    this.validationMessages.forEach((value, key: string) => {
      if (regex.test(key)) {
        this.validationMessages.delete(key);
      }
    });
    this.messages = [...this.validationMessages.values()];
    return this.messages;
  }

  checkIfAddingDuplicateMessages(message: string): boolean {
    return this.messages.some(m => m === message);
  }

  clean(): string[] {
    this.validationMessages.forEach((value: string, key: string) => this.validationMessages.delete(key));
    this.messages = [];
    return this.messages;
  }

  isLegal(subject): boolean {
    if (subject) {
      if (subject instanceof PolicyRole) {
        return subject.personType === SubjectConstants.JURIDICAL_SUBJECT;
      } else {
        return subject.personType && subject.personType.codice === SubjectConstants.JURIDICAL_SUBJECT;
      }
    }
  }

  checkRole(subject: PolicyRole, index: number, policyRoles: PolicyRole[], isFromPreventive): Observable<PartyCheck> {
    let request: any = {};

    const headers = {
      RGI_idPv: this.lifeSessionService.idPv
    };
    if (!!this.lifeSessionService.executionId) {
      (headers as any).RGI_executionId = this.lifeSessionService.executionId;
    }
    request = {
      operationCode: '_EMPRO',
      roleToCheck: {
        partyRole: subject.partyRole,
        objectId: subject.objectId,
        dateOfBirth: subject.dateOfBirth,
        personType: subject.personType,
        name: subject.name,
        surname: subject.surname,
        companyName: subject.companyName,
        agencyId: subject.agencyId,
        docSubject: subject.docSubject,
        percentage: subject.percentage,
        linkedSubjectRoles: this.getLinkedRoles(subject.linkedSubjectRoles)
      },
      othersPolicyRoles: this.buildOtherPolicyRoles(policyRoles),
      managementNodeId: this.lifeSessionService.idPvManagement

    };
    if (!this.isChildRole(subject, policyRoles)) {
      const key = !!index ? subject.partyRole + '-' + index : subject.partyRole;
      this.policyRolesFilled.set(key, subject);
    }

    if (isFromPreventive) {
      return this.httpClient.post<PartyCheck>(this.lifeSessionService.baseApiUrl + '/v2/life/estimate/roles/checks', request, { headers });
    } else {
      return this.httpClient.post<PartyCheck>(this.lifeSessionService.baseApiUrl + '/v2/life/roles/checks', request, { headers });
    }

  }

  private isChildRole(subject: PolicyRole, policyRoles: PolicyRole[]): boolean {
    return policyRoles.some(
      role => role.linkedSubjectRoles !== null && role.linkedSubjectRoles !== undefined && role.linkedSubjectRoles.some(
        subrole => subrole.objectId === subject.objectId
      )
    );
  }

  private getLinkedRoles(linkedRoles: PolicyRole[]) {
    if (linkedRoles == null) {
      return [];
    }
    return linkedRoles.filter(subject => subject.objectId !== null && subject.objectId !== undefined).map(subject => {
      return {
        partyRole: subject.partyRole,
        objectId: subject.objectId,
        dateOfBirth: subject.dateOfBirth,
        personType: subject.personType,
        name: subject.name,
        surname: subject.surname,
        companyName: subject.companyName,
        agencyId: subject.agencyId,
        docSubject: subject.docSubject,
        linkedSubjectRoles: subject.linkedSubjectRoles
      };
    });
  }

  buildOtherPolicyRoles(policyRolesToCheck: PolicyRole[]): any[] {
    const othersPolicyRoles = [];
    policyRolesToCheck.map((el) => {
      const newPartyRole = {
        personType: null,
        objectId : null,
        dateOfBirth: null,
        partyRole : null,
        name: null,
        surname: null,
        companyName: null
      };
      newPartyRole.personType = el.personType;
      newPartyRole.objectId = el.objectId;
      newPartyRole.partyRole =  el.partyRole;
      newPartyRole.dateOfBirth = el.dateOfBirth;
      newPartyRole.name = el.name;
      newPartyRole.surname = el.surname;
      newPartyRole.companyName = el.companyName;
      othersPolicyRoles.push(newPartyRole);
    });
    return othersPolicyRoles;

  }

  checkRoles(policyRoles: PolicyRole[], isFromPreventive: boolean, idMasterPolicy = null): Observable<any> {
    console.log(policyRoles, 'policy roles array');
    let request: any = {};
    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv
    };
    request = {
      operationCode: '_EMPRO',
      policyRoles,
      productTypeCode: this.lifeSessionService.productType,
      managementNodeId: this.lifeSessionService.idPvManagement,
      productCode: this.lifeSessionService.productCode
    };

    if (isFromPreventive) {
      return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/estimate/roles/checksAll', request, { headers });
    } else {
      if (idMasterPolicy) {
        request.idMasterPolicy = idMasterPolicy;
      }
      return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/roles/checksAll', request, { headers });
    }
  }

  resetService() {
    this.policyRolesFilled = new Map();
    this.validationMessages = new Map();
    this.messages = [];
  }

  storePolicyRole(vector: PolicyRole[]) {
    vector.map((subj) => {
      this.policyRolesFilled.set(subj.partyRole, subj);
    });
  }

  deleteFilledRole(partyRole: string, index?: string) {
    if (!index) {
      index = '0';
    }
    this.policyRolesFilled.delete(partyRole + '-' + index);
    this.policyRolesFilled.delete(partyRole);
  }

  /**
   * Maps a PartyCompleted response to a PartyCheck object and returns it.
   * @param response The PartyCompleted response to map.
   * @param responseCheck The PartyCheck object to update.
   * @param subj The PolicyRole object to use for the mapping.
   * @returns The updated PartyCheck object.
   */
  public mapResponsePartyCompleteToCheck(response: PartyCompleted, responseCheck: PartyCheck, subj: PolicyRole): PartyCheck {
    // this.removeAllAnagValidations();

    if (response.result === PARTY_COMPLETE_KO) {
      const msg: PartyCheckErrorMessages[] = response.outcome
      .map(m => createPartyCheckMessage(subj, m));

      responseCheck.errorMessages = responseCheck.errorMessages.concat(msg);
    }

    return responseCheck;

    /**
     * Returns the nominative form of a party role based on their person type.
     * If the person type is juridical, returns the company name.
     * Otherwise, returns the full name in the format "surname name".
     * @param partyRole The party role to get the nominative form for.
     * @returns The nominative form of the party role.
     */
    function getNominative(partyRole: PolicyRole) {
      return partyRole.personType === SubjectConstants.JURIDICAL_SUBJECT ? partyRole.companyName : `${partyRole.surname} ${partyRole.name}`;
    }

    /**
     * Creates a message for party check errors.
     * @param policyRole - The policy role to check.
     * @param m - The message to include in the error.
     * @returns A new instance of PartyCheckErrorMessages.
     */
    function createPartyCheckMessage(policyRole: PolicyRole, m: string): PartyCheckErrorMessages {
      const role = policyRole.partyRoleDescription;
      const nominative = getNominative(policyRole);
      return new PartyCheckErrorMessages(`${!!role ? role + ' - ' : ''}${!!nominative ? nominative : ''} - ${m}`);
    }
  }

}

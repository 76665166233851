import { Component, Inject, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DscDriverLicenseModalService } from './dsc-driver-license-modal.service';
import { catchError } from 'rxjs/operators';
import { DriverLicenseResponse, RSDriverLicenseOutput, RSLicenseCategory, TypeCategory } from '../../dsc-shared-model/dsc-model-driver-license';


@Component({
  selector: 'claims-dsc-driver-license-modal',
  templateUrl: './dsc-driver-license-modal.component.html',
  styleUrls: ['./dsc-driver-license-modal.component.scss'],
})

export class DscDriverLicenseModalComponent implements OnModalClose {

  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private fb: UntypedFormBuilder,
    @Inject('enumService') private enumService,
    @Optional() @Inject(DIALOG_DATA) objectId: string,
    public driverLicenseModalService: DscDriverLicenseModalService,

  ) {
    this.objectId = objectId;
    this.initializeForm();
    if (this.objectId !== '') {
      this.driverLicenseModalService.getDriverLicense(this.objectId)
        .subscribe((data: DriverLicenseResponse) => {
          const driverLicense = data.driverLicense;
          if (driverLicense) {
            this.driverLicenseForm.patchValue(driverLicense);
            this.licenseCategoryAdded = driverLicense.categories;
          }
        });
    }
  }
  driverLicenseForm: UntypedFormGroup;
  categoriesForm: UntypedFormGroup;
  licenseCategoryAdded: RSLicenseCategory[] = [];
  licenseCategoryType = this.enumService.getEnumList('claims.LicenseCategoryType');
  objectId: string;
  alertValidityStartDate: boolean = false;
  alerValidityEndDate: boolean = false;
  alertNumber: boolean = false;
  alertReleaseAuthority: boolean = false;
  alertReleaseDate: boolean = false;
  alertExpirationDate: boolean = false;
  alertLicenseCategoryType: boolean = false;

  initializeForm() {
    this.driverLicenseForm = this.fb.group({
      number: ['', Validators.required],
      releaseAuthority: ['', Validators.required],
      releaseDate: [new Date(), Validators.required],
      expirationDate: [new Date(), Validators.required],
    });
    this.categoriesForm = this.fb.group({
      type: ['', Validators.required],
      validityStartDate: ['', Validators.required],
      validityEndDate: ['', Validators.required],
    });
  }

  saveDriverLicense(e) {
    e.preventDefault();
    if (this.driverLicenseForm.valid) {
      const driverLicense: RSDriverLicenseOutput = {
        driverLicense: {
          ...this.driverLicenseForm.value,
          categories: this.licenseCategoryAdded
        }
      };
      if (this.objectId !== '') {
        driverLicense.driverLicense.kpartySubject = this.objectId;
        this.driverLicenseModalService.saveDriverLicense(driverLicense).subscribe((response) => {
        },
          catchError((error) => {
            // Gestione errori
            console.error('Errore durante il salvataggio dei dati patente', error);
            throw error;
          }));
      }
    }
    this.saveDriverLicenseValidation();
  }
  closeModal() {
    this.modalClose.emit();
  }
  addCategory(e): void {
    e.preventDefault();
    if (this.categoriesForm.valid) {
      this.licenseCategoryAdded.push(this.categoriesForm.value);
      this.categoriesForm.reset();
      this.categoriesForm.controls.type.setValue('');
    }
    this.addCategoryValidation();
  }
  onTypeChange(type: TypeCategory) {
    this.categoriesForm.controls.type.setValue(type);
  }

  addCategoryValidation(): void {
    this.alertLicenseCategoryType = this.categoriesForm.controls.type.value === '';
    this.alertValidityStartDate = this.categoriesForm.controls.validityStartDate.value === '';
    this.alerValidityEndDate = this.categoriesForm.controls.validityEndDate.value === '';
  }
  saveDriverLicenseValidation(): void {
    this.alertNumber = this.driverLicenseForm.controls.number.value == null;
    this.alertReleaseAuthority = this.driverLicenseForm.controls.releaseAuthority.value ==  null;
    this.alertReleaseDate = this.driverLicenseForm.controls.releaseDate.value ==  null;
    this.alertExpirationDate = this.driverLicenseForm.controls.expirationDate.value ==  null;
  }

  removeCategory(itemCategory: RSLicenseCategory): void {
    const index = this.licenseCategoryAdded.indexOf(itemCategory);
    this.licenseCategoryAdded.splice(index, 1);
    this.categoriesForm.reset();
  }

  licenseCategoryTypeChange(ev): void {
    // console.log(ev);
  }

}

import { Component, Input, Output, EventEmitter, OnInit, ViewChild, Inject, Type, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { LpcDocumentHostDirective } from './directives/lpc-document-host.directive';
import { DocumentEvent } from '../../models/document.model';

@Component({
    selector: 'lpc-document-wrapper',
    templateUrl: './lpc-document-wrapper.component.html',
    styleUrls: ['./lpc-document-wrapper.component.css']
})
export class DocumentWrapperComponent implements OnInit {

  /*
    Questo componente utilizza un input 'config' dove è possibile passare un JSON contenente tutti
    gli input che il componente andrà ad utilizzare

    sempre all'interno di config è possibile passare, con la stringa chiave 'output', il nome
    dell'output che ci metteremo ad ascoltare per rilevare eventuali eventi che dovranno essere
    comunicati al FE dell'operazione

    ESEMPIO:
      config = {
        label: 'documenti',
        showTitle: true,
        output: 'documentEvent'
      }

    in tutte le operazioni avremo sempre l'input del config e la gestione degli eventi
      [config]="documentsConfig" (documentEvent)="handleDocumentEvent($event)"
  */

    @ViewChild(LpcDocumentHostDirective, {static: true}) micDocHost: LpcDocumentHostDirective;
    componentRef: ComponentRef<any>;

    @Input() contractId: string;
    @Input() operationCode: string;
    @Input() publishMessage: string;
    @Input() isAuth: boolean;
    @Input() authorizationId = EMPTY_STR;
    @Input() printMode: string;

    @Input() config = null;

    @Output() documentEvent = new EventEmitter<string | DocumentEvent>();

    constructor(
      protected componentFactoryResolver: ComponentFactoryResolver,
      @Inject(PV_TOKEN.DOCUMENT_COMPONENT) protected lpcDocumentComponent) {}


    ngOnInit() {
      if (this.micDocHost) {
        const component: Type<Component> = this.lpcDocumentComponent;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

        const viewContainerRef = this.micDocHost.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(componentFactory);

        if (this.config != null) {
          this.setConfigIntoComponent(this.config, this.componentRef.instance);
        } else {
          this.componentRef.instance.contractId = this.contractId;
          this.componentRef.instance.operationCode = this.operationCode;
          this.componentRef.instance.publishMessage = this.publishMessage;
          this.componentRef.instance.isAuth = this.isAuth;
          this.componentRef.instance.authorizationId = this.authorizationId;
          this.componentRef.instance.printMode = this.printMode;
          this.componentRef.instance.documentEvent.subscribe(ev => this.documentEvent.emit(ev));
        }
      }
    }

    setConfigIntoComponent(config: {}, instance: any) {
      instance.publishMessage = this.publishMessage;
      const keys = Object.keys(config); // chiavi dell'oggetto per settare le variabili nel componente
      keys.forEach((key) => {
        if (key !== 'output') {
          instance[key] = config[key];
        } else {
          instance[config[key]].subscribe(ev => this.documentEvent.emit(ev));
        }
      });
    }
}

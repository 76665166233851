import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { LicCustomProperties } from '../../../enum/life-issue.enum';
import { BeneficiaryData } from '../../../models/policy.model';
import { PolicyService } from '../../../services/policy-service';
import { LicRolesUtils } from '../../../utils/lic-roles-utils';
import { BeneficiaryService } from './../../../services/beneficiary.service';
import { Subject } from './../../../models/subject.model';

@Component({
  selector: 'lic-beneficiaries-select',
  templateUrl: './beneficiaries-select.component.html',
  styleUrls: []
})
export class BeneficiariesSelectComponent implements OnInit {

  @Input() customForm: UntypedFormGroup;
  @Input() minSubj: number;
  @Input() maxSubj: number;
  @Input() submitted: boolean;
  @Input() insuredId: string;
  @Input() cat: string;
  @Input() _REG41Property: boolean;


  @Output() message = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() deleteBen = new EventEmitter<any>();
  @Output() subjetAnagEditedEvent = new EventEmitter<{ opened: boolean, subject: Subject, role: string}>();
  @Output() deleteRelSubj = new EventEmitter<{cat: string, benef: any, subjId: string}>();

  constructor(protected policyService: PolicyService,
              protected translateService: TranslationWrapperService,
              protected beneficiaryService: BeneficiaryService) {
  }

  ngOnInit() {
    const length = this.fromAnag.length;
    for (let i = length; i < this.minSubj; i++) {
      if (this.fromAnag instanceof UntypedFormArray) {
        (this.fromAnag as UntypedFormArray).push(this.createSubjectBenefFormGroup(null, null));
      }
    }
    this.initIrrevocability();
  }

  get fromAnag() {
    return this.customForm.get('fromAnag') as UntypedFormArray;
  }

  delete(event) {
    if (this.fromAnag instanceof UntypedFormArray) {
      event.cat = this.cat;
      if (!LicRolesUtils.isThereOneEmptyBenefControl(this.fromAnag)) {
        this.fromAnag.push(this.createSubjectBenefFormGroup(null, null));
      }
      this.beneficiaryService.deleteBenef(this.cat, this.fromAnag.at(event.key).value.objectId);
      this.fromAnag.removeAt(event.key);
    }
    this.deleteBen.emit(event);
  }

  deleteReletedSubject(event) {
    this.deleteRelSubj.emit(event);
  }

  openAnag(event) {
    this.eventPropagation.emit(event);
  }

  public editAnag(event: any) {
    this.subjetAnagEditedEvent.emit(event);
}

  get irrevocabilityFieldName() {
    if (this.cat === '1') {
      return 'isBeneficiaryLifeIrrevocable';
    } else if (this.cat === '2') {
      return 'isBeneficiaryDeathIrrevocable';
    }
    return null;
  }

  get irrevocableBeneficiaryAllowed() {
    if (this.irrevocabilityFieldName !== null) {
      const property = this.policyService.getPropertyFromProduct(LicCustomProperties.IRREVOCABLE_BENEF);
      if (property != null && property.values != null) {
        return property.values.find(value => value.code === this.cat) != null;
      }
    }
    return false;
  }


  initIrrevocability() {
    if (this.irrevocableBeneficiaryAllowed) {
      let value = false;
      if (this.policyService.mainProposal.proposal.beneficiaryData != null
       && this.policyService.mainProposal.proposal.beneficiaryData[this.irrevocabilityFieldName] != null) {
          value = this.policyService.mainProposal.proposal.beneficiaryData[this.irrevocabilityFieldName];
      }
      const control = new UntypedFormControl(value);
      // the 'pnc-checkbox' component support only click event. unluckyly, the click event are
      // processed before the new value is set, so the 'updateIrrevocability' method see
      // the old value and not the value we are going to set. to avoid this design inconsistency,
      // we add the change listener manually throug the 'subscribe' method
      control.valueChanges.subscribe(nv => this.updateIrrevocability(nv));
      this.customForm.addControl('irrevocable', control);
      this.customForm.addControl('irrevocabilityFieldName', new UntypedFormControl(this.irrevocabilityFieldName));
    }
  }

  get irrevocable() {
    return this.customForm.get('irrevocable').value;
  }


  updateIrrevocability(value: boolean) {
    if (this.policyService.mainProposal.proposal.beneficiaryData == null) {
        this.policyService.mainProposal.proposal.beneficiaryData = new BeneficiaryData();
    }
    this.policyService.mainProposal.proposal.beneficiaryData[this.irrevocabilityFieldName] = value;
  }

  createSubjectBenefFormGroup(subj, scelta) {
    // serve per sovrascriverla in EXT
    return LicRolesUtils.createSubjectBenefFormGroup(subj, scelta, this.beneficiaryService.benefGroupingTypes);
  }


}

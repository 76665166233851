import { Injectable } from '@angular/core';
import { FileUpload } from '../model/file-upload';
import { ClaimDocument } from '../model/claim-document';

@Injectable({
  providedIn: 'root'
})
export class DocumentTranslatorService {

  constructor() { }

  fileUploadToClaimDocument(file: FileUpload, categoryId: string, uuid: string) {
    const document = new ClaimDocument();
    document.categoryId = categoryId;
    document.documentId = file.idDocTempStore;
    document.documentName = file.fileName;
    document.file = file.file;
    document.uuid = uuid;

    return document;
  }
}

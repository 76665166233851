import {SCALAR_TYPE} from '@rgi/rx';

export class LabeledEntity {
  label: string;
  description: SCALAR_TYPE;
  value?: string;
  nestedEntities?: LabeledEntity[]

  constructor(label: string, description: SCALAR_TYPE, value?: string, nestedEntities?: LabeledEntity[]) {
    this.label = label;
    this.description = description;
    this.value = value;
    this.nestedEntities = nestedEntities;
  }
}


<div>
    <rgi-rx-datatable [data]="paymentlistResult" [schema]="schema"  [expansionRow]="rowDetail" (onAction)="onActionClick($event)"
                      [pageOptions]="[5,10,20,50]" #tableEl>
    </rgi-rx-datatable>
</div>
<div class="btn-group btn-group-justified">
    <div class="btn-group">
        <button type="button" class="btn btn-warning pull-right" (click)="return()">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
    </div>
</div>
  
<ng-template #rowDetail let-ctx>
    <div class="panel-body cld-block-content">

        <div class="container-fluid table-white">
    
            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._INTERMEDIARY' | translate }}:&nbsp;</label>
                    <label><b>{{ctx.codPointofsale}} - {{ctx.descrPointofsale}}</b></label>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._POLICY_HOLDER' | translate }}:&nbsp;</label>
                    <label><b>{{ctx.descrPolicyholder}}</b></label>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._AGENCY_UNIQUE_KEY' | translate }}:&nbsp;</label>
                    <label><b>{{(ctx.descrBranchoffice) || '-'}}</b></label>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._INSPECTOR' | translate }}:&nbsp;</label>
                    <label><b>{{(ctx.descrChannel) || '-'}}</b></label>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._INSURED' | translate }}:&nbsp;</label>
                    <label><b>{{ (ctx.descrInsured) || '-' }}</b></label>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._BENEFICIARY' | translate }}:&nbsp;</label>
                    <label><b>{{(ctx.descrBeneficiary) || '-'}}</b></label>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._POLICY_NUMBER' | translate }}:&nbsp;</label>
                    <label><b>{{ctx.numPolicy}}</b></label>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._CLAIM_BRANCH' | translate }}:&nbsp;</label>
                    <label><b>{{(ctx.descrMinistbranch) || '-'}}</b></label>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._ADJUSTER' | translate }}:&nbsp;</label>
                    <label><b>{{ctx.descrClaimshandler}}</b></label>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._CHC' | translate }}:&nbsp;</label>
                    <label><b>{{ctx.descrChc}}</b></label>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._LOAD_DATE' | translate }}:&nbsp;</label>
                    <label><b>{{ (ctx.accountingDate | date:'dd/MM/yyyy') || '-'}}</b></label>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label>{{ '_CLAIMS_._OFFER_DATE' | translate }}:&nbsp;</label>
                    <label><b>{{ (ctx.settlementData | date:'dd/MM/yyyy') || '-'}}</b></label>
                </div>
            </div>
    
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <label>{{ '_CLAIMS_._TYPE_OF_LOSS' | translate }}:&nbsp;</label>
                    <label><b>{{ctx.descrTypeofloss}}</b></label>
                </div>
            </div>
        </div>
    </div>
</ng-template>


import {GenericEntity} from './generic-entity';
import {Unit} from './unit';

export class Section extends GenericEntity {

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public selected: boolean,
    public unitList: Array<Unit>,
    public open: boolean) {
    super(id, code, description);
  }

}

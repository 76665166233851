import { ComboEntity } from '../../new-payment/domain/combo-entity';
import { AddressEntityData } from './dsc-model-address';
import { Subject, SubjectReduced } from './dsc-model-subject';

export class RSPtfAllIdentification {
    code: string = '';
    description: string = '';
  }
export class RSClaimsInjuryBodilyLocation {
  bodilyLocation: RSPtfAllIdentification;
  extensionData: any;
}
export class RSClaimsInjuryType {
  injuryType: RSPtfAllIdentification;
  extensionData: any;
}
export class RSClaimsInjuryCause {
  injuryCause: RSPtfAllIdentification;
  extensionData: any;
}
export class RSClaimsInjurySummary {
  cause: RSClaimsInjuryCause;
  type: RSClaimsInjuryType;
  bodilyLocation: RSClaimsInjuryBodilyLocation;
  extensionData: any;
}
export class RSClaimsAffectedAssetParty {
  physician: Subject; // RSClaimParty
  emergencyRoomAddress: RSCLaimAddress;
  emergencyRoomHospital: string;
  permanentDisabilityPercentage: any;
  deceaseDate: Date;
  otherInjuries: boolean;
  replaceSocialInsurer: boolean;
  profession: RSClaimsInailProfession;
  injuryAgent: RSClaimsInjuryAgent;
  injurySummary: RSClaimsInjurySummary[];
  extensionData: any;
}
export class RSClaimsInailProfession {
  inailProfession: RSPtfAllIdentification;
  extensionData: any;
}
export class RSCLaimAddress {
  bankCodeCIN: string;
  bankCodeCab: string;
  contryCode: string;
  toponym: RSPtfAllIdentification;
  placeAddress: string;
  number: string;
  city: string;
  cityCode: string;
  suburb: string;
  province: string;
  postCode: string;
  at: string;
  phoneNumber: string;
  otherPhoneNumber: string;
  faxNumber: string;
  eMailAddress: string;
  toponymStr: string;
  isUmaAddressEnabled: boolean;
  umaAddress: AddressEntityData; // AnagAddress
}
export class RSClaimsInjuryAgent {
  group: RSClaimsInjuryAgentGroup;
  category: RSClaimsInjuryAgentCategory;
}
export class RSClaimsInjuryAgentGroup {
  injuryAgentGroup: RSPtfAllIdentification;
  extensionData: any;
}
export class RSClaimsInjuryAgentCategory {
  injuryAgentCategory: RSPtfAllIdentification;

}
export class RSClaimsAffectedAsset {
  assetType: string;
  ppAsset: RSPtfAllIdentification;
  insuredAsset: RSPtfAllIdentification;
  description: string;
  policyData: RSClaimsPolicyData;
  policyHolder: Subject;
  underage: Subject;
  address: RSCLaimAddress;
  vehicle: RSClaimsAffectedAssetVeichle;
  party: RSClaimsAffectedAssetParty;
  extensionData: any;
  estimatedAmount: number;
  creditTransfer: string;
}
export class RSClaimsPolicyData {
  agencyDescription: string;
  policyNumber: string;
  greenCardCountry: string;
  greenCardNumber: string;
  greenCardExpirationDate: string;
  notes: string;
  effectDate: Date;
  expirationDate: Date;
  company: RSAdminInsuranceCompany;
  extensionData: any;
  companyCode: string;
  isMaterialLossCovertureOnPolicy: boolean;
  address: RSCLaimAddress;
  ownerFiscalCode: string;
  contractorFiscalCode: string;
  companyData: RSCompany;
}
export class RSAdminInsuranceCompany {
  objectId: number;
  isvapCode: number;
  aniaCode: string;
  extension: any;
  code: string;
  address: string;
  province: string;
  damagesPortfolio: boolean;
  lifePortfolio: boolean;
  name: string;
  postCode: string;
}
class RSClaimsAffectedAssetVeichle {
  manufacturerCode: string;
  modelCode: string;
  modelDescription: string;
  manufacturerDescription: string;
  licensePlateType: string;
  licensePlateNumber: string;
  chassisNumber: string;
  licensePlateCountryCode: string;
  firstRegistrationDate: string;
  aniaCode: string;
  recoveryProvince: string;
  recoveryDate: Date;
  damagedParts: RSClaimsDamagedPart;
  driver: Subject;
  directlyInvolvedInCollision: boolean;
  responsabilityPercentage: number;
  bodyShopTrusteeType: string;
  bodyShopExternalKey: string;
  bodyShopCode: string;
  bodyShopSubject: string;
  CAIPosition: string;
  visibleDamages: string;
  observation: string;
  parkedVehicle: boolean;
  sicOutcome: string;
  substitution: boolean;
  theftProtection: boolean;
  insotherCompany: boolean;
  financialConstraint: string;
  otherInvVehicles: boolean;
  theftType: string;
  blackboxCoherence: string;
}
export class RSClaimsDamagedPart {
  damagedPart: RSPtfAllIdentification;
  extensionData: any;
}
export class RSCompany {
  rSCompany: number;
  companyName: string;
  companyCode: string;
  companyCodeAnia: RSCLaimAddress;
}
export class RSClaimDamageSummary {
  damagesDescriptionId: string;
  causative: string;
  dateForwardingToSettlementCentre: Date;
  dateReceivedSettlementCentre: Date;
  acceptanceDate: Date;
  managementStatus: string;
  technicalStatus: string;
  enumTypeOfLossTechnicalStatus: string;
  accountingStatus: string;
  technicalOpeningDate: Date;
  accountingOpeningDate: Date;
  technicalReactivationOpeningDate: Date;
  accountingReactivationOpeningDate: Date;
  technicalClosingDate: Date;
  accountingClosingDate: Date;
  reportDate: Date;
  inspectorate: string;
  inspectorateAddress: string;
  inspectorateTime: string;
  inspectorateTelephone: string;
  inspectorateFax: string;
  inspectorateEmail: string;
  adjuster: string;
  managementType: string;
  agreementType: string;
  enumTypeOfLossManagementType: string;
  fileAssumed: boolean;
  vehicleType: string;
  description: string;
  risk: string;
  assetType: string;
  enumAssetType: string;
  company: string;
  chassisOrPlateNumber: string;
  coverageTypeCode: string;
  coverageCode: string;
  reserveTechAmount: number;
  reserveTechInsertionDate: Date;
  reserveTechAssignmentExpenses: number;
  reserveTechAmountContantRegister: number;
  reserveAccountingAmount: number;
  reserveAccountingAssignmentExpenses: number;
  reserveAccountingAmountContantRegister: number;
  permanentDisabilityPercentage: number;
  asset: RSClaimsAffectedAsset;
  ministerialBranch: RSClaimsMinisterialBranch;
  summonsList: RSClaimSummon;
  litigationsList: RSClaimLitigation;
  mediationsList: RSClaimMediation;
  injuriesList: RSClaimInjury;
  claimDamagesList: RSConnectedClaimDamage;
  appointmentList: RSClaimAppointment;
  totalPayment: number;
  totalPaymentIndemnity: number;
  totalPaymentFees: number;
  substatus: string;
  substatusCode: string;
  updateReason: string;
  negotiationsList: RSClaimNegotiation;
  valoreInLite: string;
  deprecated: boolean;
  substatusList: RSSubstatus;
  operationsOutput: any; // RSPtfAllOperationsJumpFilterOutput
  operationsCardOutput: RSOperationsCardOutput;
}
export class RSOperationsCardOutput {
  operationCard: RSOperationCard;
}
export class RSOperationCard {
  cardInfo: RSCardInfo;
  operationInfo: RSOperationInfo;
}
export class RSCardInfo {
  cardName: string;
  cardView: string;
}
export class RSOperationInfo {
  identification: number;
  code: string;
  description: string;
}
export class RSSubstatus {
  code: string;
  description: string;
  statusDescription: string;
  styleClass: string;
}
export class RSClaimAppointment {
  appointmentNumber: string;
  appointmentState: string;
  appointmentDate: Date;
  completionDate: Date;
  expert: string;
  expertType: string;
  appointmentDescription: string;
  quickClaimSettlement: boolean;
  motivation: string;
  expertAssignmentCompleted: boolean;
  amountExpertAssignment: number;
  amountParcel: number;
  note: string;
  feeProf: RSListfeeProf;
  paymentSurveyType: string;
}
export class RSListfeeProf {
  numFeeProf: string;
  status: string;
  typeFeeProf: string;
  isInProforma: boolean;
  dateFeeProf: Date;
  amtFeeProf: number;
  totSpeseImp: number;
  totSpeseNonImp: number;
  totOnorari: number;
  speseVarieImp: number;
  speseVarieNonImp: number;
  importoCassa: number;
  inpsContribAmount: number;
  imIva: number;
  ritenutaAcc: number;
  totConIva: number;
  percIva: number;
  percRitenutaAcc: number;
  inpsContribPerc: number;
  totale: number;
  totNettoRitaAcc: number;
  percCassa: number;
  amountTax: number;
}
export class RSClaimsMinisterialBranch {
  ministerialBranch: RSPtfAllIdentification;
  extensionData: any;
}
export class RSClaimNegotiation {
  negotiationId: string;
  agreedTerm: string;
  prorogation: string;
}
export class RSClaimInjury {
  identifier: string;
  injuryType: string;
  injuryBodilyLocation: string;
}
export class RSConnectedClaimDamage {
  partyId: string;
  connectedDamagesDescriptionId: string;
  connectionType: string;
}
export class RSClaimSummon {
  court: string;
  firstHearingDate: Date;
  judicialLevel: string;
  summonsType: string;
  courtType: string;
  courtName: string;
  externalKey: string;
  outcomeDate: string;
  jurisdictionEnum: string;
}
export class RSClaimLitigation {
  proceedingsResult: string;
  decreeNumber: string;
  litigationType: string;
  courtType: string;
  courtName: string;
  externalKey: string;
  outcomeDate: string;
  jurisdictionEnumAct: string;
  jurisdictionEnumPass: string;
  courtCity: string;
  courtProvince: string;
  firstHearingDate: Date;
  courtRegistrationDate: Date;
  involvedParty: string;
  enumSummonAddressee: string;
  caseStatus: string;
  closingLitigationDate: Date;
  failExpensesLitRecovery: string;
  presumedPrescriptionDate: Date;
  recoveryAmount: number;
  agreedAmount: number;
  notes: string;
  companyLawyers: RSClaimLitigationRelatedSubject;
  counterpartLawyers: RSClaimLitigationRelatedSubject;
  atp: RSClaimLitigationRelatedSubject;
  ctuLawyers: RSClaimLitigationRelatedSubject[];
  ctpLawyers: RSClaimLitigationRelatedSubject[];
  companyCtp: RSClaimLitigationRelatedSubject[];
}
class RSClaimLitigationRelatedSubject {
  role: string;
  designation: string;
}
export class RSClaimMediation {
  mediationStatus: string;
  mediationType: string;
}
export class InjuryDamageArray {
  bodilyLocation: string;
  bodilyLocationDescr: string;
  injuryType: string;
  injuryTypeDescr: string;
}




export class InjuryAsset {
  percIP: number = 0;
  fatalDate: Date = null;
  selectedCountryER: string = '';
  placeER: string = '';
  idER: string = '';
  descriptionER: string = '';
  surroga: string = '';
  biologicalDamage: boolean = false;
  ggITB: number = 0;
  injuryCause: string = '';
  injuryNature: string = '';
  injuryPlace: string = '';
  profession: RSPtfAllIdentification = null;
  moreInjury: boolean = false;
  multiple: boolean = false;
  notCodeable: boolean = false;
  doctorSelected: Subject = null;
  descriptionDoctor: string = '';
  idDoctor: string = '';
  damageArray: InjuryDamageArray[] = [];
  damageList?: RSClaimsInjurySummary[] = [];
}
export class DamageList {
  idLoc: string;
  idNat: string;
  location: string;
  nature: string;
}

export class SurrogaAnsw {
  code: number;
  description: string;
}

export class InjuryData {
  subject: SubjectReduced;
  dataForm: InjuryAsset;
  roles?: any;
}


export class InjuryCode {
  departmentCodeSel: string = '';
  lossTypeSel: string = '';
  severityCodeSel: string = '';
  injuryCodeSel: string = '';
  allegationSel: string = '';
  allegationDescription: string = '';
  matterType: string = '';
  injuryNote: string = '';
  ministerialBranch: string = '';
  subHospital: string = '';
  isValidForm?: boolean;
}

export class InjuryEntity {
  listDepartment: Array<any>;
  listTypeofLoss: Array<any>;
  listSeverityCode: Array<any>;
  listInjuryCode: Array<any>;
  listAllegation: Array<any>;
  listTypeMatter: Array<any>;
  departmentCodeSel: string;
  lossTypeSel: string;
  severityCodeSel: string;
  injuryCodeSel: string;
  allegationSel: string;
  allegationDescription: string;
  matterType: string;
  injuryNote: string;
  ministerialBranch: string;
  subHospital: string;
 }

export class ComboInjuryEntity {
  listDepartment: Array<ComboEntity>; // Reparto
  listTypeOfDamage: Array<ComboEntity>; // Tipo perdita
  listTypeCivilPenal: Array<ComboEntity>; // Tipologia civile/penale
}

export const INIT_COMBO_INJURY: ComboInjuryEntity = {
  listDepartment: [], // Reparto
  listTypeOfDamage: [], // Tipo perdita
  listTypeCivilPenal: [] // Tipologia civile/penale
};

export const INIT_INJURY_CODE: InjuryCode = {
  departmentCodeSel: '', // Reparto selezionato
  lossTypeSel: '', // Tipo perdita selezionata
  severityCodeSel: '',
  injuryCodeSel: '',
  allegationSel: '',
  allegationDescription: '', // Descrizione
  matterType: '', // Tipologia civile/penale selezionato
  injuryNote: '', // Note
  ministerialBranch: '',
  subHospital: ''
};

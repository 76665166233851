import {AnagPartyConfiguration} from '../anag-model/anag-domain/anag-party-configuration';
import {AnagConfiguration} from '../anag-model/anag-domain/anag-configuration';
import {AnagApiParty, AnagPartyKey} from '../anag-model/anag-domain/anag-api-party';
import {
  AnagDocument
} from '../anag-model/anag-domain/anag-document';
import {AnagEntityIta} from '../anag-model/anag-api/anag-subject-api';
import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';
import {AnagFormFieldConfig} from '../anag-model/anag-domain/anag-form-field-config';
import {AnagPrivacyConsensus} from '../anag-model/anag-domain/anag-privacy-config';
import {distinctUntilChanged} from "rxjs/operators";
import {AnagIdentificationDocumentConfig} from '../anag-model/anag-domain/anag-identification-document-config';

@Injectable()
// tslint:disable:max-line-length
export class AnagConfigService {

  getFieldConfig(config: AnagConfiguration, field: string): AnagPartyConfiguration {
    return config && config.partyConfig ? config.partyConfig[field] : undefined;
  }

  isFieldMandatory(field: AnagPartyConfiguration): boolean {
    return !!field && !!field.configuration && field.configuration.codice === '1';
  }

  isFieldHidden(field: AnagPartyConfiguration): boolean {
    return !!field && !!field.configuration && field.configuration.codice === '2';
  }

  isDisabled(field: AnagPartyConfiguration): boolean {
    return !!field && !!field.configuration && field.configuration.codice === '5';
  }

  isFieldDefault(field: AnagPartyConfiguration): boolean {
    return !!field && !!field.configuration && field.configuration.codice === '99';
  }

  isFieldExclusive(field: AnagPartyConfiguration): boolean {
    return !!field && !!field.configuration && field.configuration.codice === '3';
  }

  isFieldAlternative(field: AnagPartyConfiguration): boolean {
    return !!field && !!field.configuration && field.configuration.codice === '4';
  }

  isPaConfAttrFieldMandatory(field: AnagEntityIta): boolean {
    return !!field && field.codice === '1';
  }

  isPaConfAttrFieldExclusive(field: AnagEntityIta): boolean {
    return !!field && field.codice === '3';
  }

  isPaConfAttrFieldAlternative(field: AnagEntityIta): boolean {
    return !!field && field.codice === '4';
  }

  isPaConfAttrFieldHidden(field: AnagEntityIta): boolean {
    return !!field && field.codice === '2';
  }

  isPaConfAttrFieldDisabled(field: AnagEntityIta): boolean {
    return !!field && field.codice === '5';
  }

  isKeyDataValidConfig(config: AnagConfiguration, party: AnagApiParty): boolean {
    return (!this.isFieldMandatory(config.partyConfig.countryOfBirth) || (!!party.countryOfBirth && !!party.countryOfBirth.codice)) &&
      (!!party.node && !!party.node.identification) &&
      (!config.partyConfig.subjectType || !this.isFieldMandatory(config.partyConfig.subjectType) || (!!party.personType && !!party.personType.codice))
      && (!this.isFieldMandatory(config.partyConfig.countryOfBirth) || (!!party.countryOfBirth && !!party.countryOfBirth.codice)) &&
      ((
          party.personType.codice === '1' &&
          (!this.isFieldMandatory(config.partyConfig.professionType) || (!!party.professionType && !!party.professionType.codice)) &&
          (!this.isFieldMandatory(config.partyConfig.professionDetail) || (!!party.professionDetail && !!party.professionDetail.codice))
        ) ||
        (
          party.personType.codice === '2' &&
          (!this.isFieldMandatory(config.partyConfig.corporateForm) || (!!party.corporateForm && !!party.corporateForm.codice))
        ));
  }

  isDocumentValidConfig(config: AnagConfiguration, docs: AnagDocument[], isValid: boolean) {
    const docConfig = config.partyConfig.document;
    let valid;
    if (!!docs && docs.length > 0) {
      docs?.every(doc => {
        valid =
          (!this.isFieldMandatory(docConfig.authoritiesRelease) || (!!doc && !!doc.authoritiesRelease && !!doc.authoritiesRelease.codice)) &&
          (!this.isFieldMandatory(docConfig.documentNumber) || (!!doc && !!doc.documentNumber)) &&
          (!this.isFieldMandatory(docConfig.documentType) || (!!doc && !!doc.documentType && !!doc.documentType.codice)) &&
          (!this.isFieldMandatory(docConfig.expirationDate) || (!!doc && !!doc.expirationDate)) &&
          (!this.isFieldMandatory(docConfig.locationsRelease) || (!!doc && !!doc.locationsRelease)) &&
          (!this.isFieldMandatory(docConfig.releaseDate) || (!!doc && !!doc.releaseDate)) && (!!!doc || doc && isValid)
      })
    } else {
      valid =
        this.getValidityByConf(docConfig)
    }
    return !docConfig || valid;
  }

   getValidityByConf(docConfig: AnagIdentificationDocumentConfig) {
    return (!this.isFieldMandatory(docConfig.authoritiesRelease)) &&
      (!this.isFieldMandatory(docConfig.documentNumber)) &&
      (!this.isFieldMandatory(docConfig.documentType)) &&
      (!this.isFieldMandatory(docConfig.expirationDate)) &&
      (!this.isFieldMandatory(docConfig.locationsRelease)) &&
      (!this.isFieldMandatory(docConfig.releaseDate));
  }

  isPrivacyConsensusValidConfig(config: AnagConfiguration, privacyQuestions: Array<AnagEntityIta>, privacyConsensus: Array<AnagPrivacyConsensus>) {
    let valid = true;
    if (!!privacyQuestions && privacyQuestions.length > 0) {
      if (valid) {
        privacyQuestions.forEach((question, index) => {
          valid = (!this.isFieldMandatory(config.partyConfig.privacyConsensusType) || (privacyConsensus && privacyConsensus.length > 0 && privacyConsensus[index] && !!privacyConsensus[index].privacyConsensusValue && !!privacyConsensus[index].privacyConsensusValue.codice))
            && (!this.isFieldMandatory(config.partyConfig.privacyConsensusDate) || (privacyConsensus && privacyConsensus.length > 0  && privacyConsensus[index] && !!privacyConsensus[index].privacyConsentDate))
            && (!this.isFieldMandatory(config.partyConfig.privacyConsensusOrigin) || (privacyConsensus && privacyConsensus.length > 0  && privacyConsensus[index] && !!privacyConsensus[index].privacyConsentOrigin));
        });
      }
    }
    return valid;
  }

  isContactsValidConfig(config: AnagConfiguration, contact: AnagEntityIta) {
    const mainContactConfig = config.partyConfig.mainContact;
    return !mainContactConfig || ((!this.isFieldMandatory(mainContactConfig) || (!!contact)));
  }

  isIdentificationDataValid(config: AnagConfiguration, party: AnagApiParty, context: string, citizenshipPermitTypes: any) {

    const partyKeys = !!party.partyKey && party.partyKey.length > 0 ?
      party.partyKey[party.partyKey.length - 1] : undefined;
    const key1Config = config.configurations.find(paConf => paConf.label === 'PasubjectkeyCkey1');
    const key2Config = config.configurations.find(paConf => paConf.label === 'PasubjectkeyCkey2');
    const key3Config = config.configurations.find(paConf => paConf.label === 'PasubjectkeyCkey3');
    let valid = (!this.isFieldMandatory(config.partyConfig.dateOfBirth) || !!party.dateOfBirth)
      && (!key1Config || (!this.isPaConfAttrFieldMandatory(key1Config.configuration) && this.exclusiveAndAlternativePaConfAttr(config, key1Config, partyKeys)) || (!!partyKeys && !!partyKeys.key1 && !!partyKeys.key1.value))
      && (!key2Config || (!this.isPaConfAttrFieldMandatory(key2Config.configuration) && this.exclusiveAndAlternativePaConfAttr(config, key2Config, partyKeys)) || (!!partyKeys && !!partyKeys.key2 && !!partyKeys.key2.value))
      && (!key3Config || (!this.isPaConfAttrFieldMandatory(key3Config.configuration) && this.exclusiveAndAlternativePaConfAttr(config, key3Config, partyKeys)) || (!!partyKeys && !!partyKeys.key3 && !!partyKeys.key3.value))
      && (!this.isFieldMandatory(config.partyConfig.eaGroup) || !!party.gruppoAE && !!party.gruppoAE.code)
      && (!this.isFieldMandatory(config.partyConfig.atecoCode) || !!party.codAteco && !!party.codAteco.code)
      && (!this.isFieldMandatory(config.partyConfig.language) || !!party.language && !!party.language.codice);
    if (valid) {
      if (party.personType.codice === '1') {
        const isFieldPermitEnabled = party.citizenship && party.citizenship.codice !== context && citizenshipPermitTypes && citizenshipPermitTypes.length>0;
        const isFieldExpireEnabled = party.citizenship && party.citizenship.codice !== context  && citizenshipPermitTypes && citizenshipPermitTypes.length>0;
        valid =
          (!this.isFieldMandatory(config.partyConfig.surname) || !!party.surname)
          && (!this.isFieldMandatory(config.partyConfig.name) || !!party.name)
          && (!this.isFieldMandatory(config.partyConfig.sex) || !!party.sex && !!party.sex.codice)
          && (!this.isFieldMandatory(config.partyConfig.gender) || !!party.genderIdentity && !!party.genderIdentity.codice)
          && (!this.isFieldMandatory(config.partyConfig.maritalStatus) || !!party.maritalStatus && !!party.maritalStatus.codice)
          && (!this.isFieldMandatory(config.partyConfig.citizenship) || !!party.citizenship && !!party.citizenship.codice)
          && (!this.isFieldMandatory(config.partyConfig.politicallyExp) || !!party.politicallyExp)
          && this.isFieldBirthNameValid(config, party)
          && (!this.isFieldMandatory(config.partyConfig.honoraryTitle) || !!party.salutation && !!party.salutation.codice)
          && (!this.isFieldMandatory(config.partyConfig.sonsNumber) || !!party.sonsNumber)
          && (!(this.isFieldMandatory(config.partyConfig.citizenshipPermit) && isFieldPermitEnabled) || !!party.citizenshipPermit)
          && (!(this.isFieldMandatory(config.partyConfig.citizenshipExpire) && isFieldExpireEnabled) || !!party.citizenshipExpire);
      } else {
        valid =
          (!this.isFieldMandatory(config.partyConfig.denomination) || !!party.denomination)
          && (!this.isFieldMandatory(config.partyConfig.corporateSector) || !!party.corporateSector && !!party.corporateSector.codice)
          && (!this.isFieldMandatory(config.partyConfig.regCountry) || !!party.regCountry);
      }
    }
    return valid;
  }

  exclusiveAndAlternativePaConfAttr(config: AnagConfiguration, field: AnagPartyConfiguration, partyKeys: AnagPartyKey): boolean {
    let valid;
    const myMap: { [key: string]: string } = {
      key1 : 'PasubjectkeyCkey1',
      key2 : 'PasubjectkeyCkey2',
      key3 : 'PasubjectkeyCkey3'
    };
    const isAlternative = this.isPaConfAttrFieldAlternative(field.configuration);
    const isExclusive = this.isPaConfAttrFieldExclusive(field.configuration);
    if (isAlternative || isExclusive) {
      field.alternativeLabels.forEach(alternativeLabel => {
        valid = !!(partyKeys[Object.keys(myMap).find(key => myMap[key] === alternativeLabel)]?.value ||
          partyKeys[Object.keys(myMap).find(key => myMap[key] === field.label)]?.value);
      });
    } else {
       valid = true;
    }
    return valid;
  }

  getIconBadgeCssClass(section: string, config: AnagConfiguration, party: AnagApiParty) {
    const ok = 'rgi-ui-icon-confirmation rgi-anag-badge rgi-anag-ok-badge';
    const ko = 'rgi-ui-icon-warning rgi-anag-badge rgi-anag-ko-badge';
    switch (section) {
      case 'keydata':
        return this.isKeyDataValidConfig(config, party) ? ok : ko;
    }
  }

  setConfigToForm(formMap: Map<string, AnagFormFieldConfig>, config: AnagConfiguration) {
    for (const [key, value] of formMap) {
      if (!!config && key && value.configFieldName && value.configFieldName !== '') {
        let hidden;
        let mandatoryFromConfig;
        let exclusiveFromConfig;
        let alternativeFromConfig;
        let alternativeLabels;
        let disabled;
        if (value.partyConfProperty && config.partyConfig && config.partyConfig[value.partyConfProperty] &&
          config.partyConfig[value.partyConfProperty][value.configFieldName]) {
          mandatoryFromConfig = this.isFieldMandatory(config.partyConfig[value.partyConfProperty][value.configFieldName]);
          exclusiveFromConfig = this.isFieldExclusive(config.partyConfig[value.partyConfProperty][value.configFieldName]);
          alternativeFromConfig = this.isFieldAlternative(config.partyConfig[value.partyConfProperty][value.configFieldName]);
          alternativeLabels = config.partyConfig[value.partyConfProperty][value.configFieldName].alternativeLabels;
          hidden = this.isFieldHidden(config.partyConfig[value.partyConfProperty][value.configFieldName]);
          disabled = this.isDisabled(config.partyConfig[value.partyConfProperty][value.configFieldName]);
        } else if (value.partyConfProperty && config.partyConfig && config.partyConfig[value.partyConfProperty] && value.index !== undefined && value.index !== null &&
          config.partyConfig[value.partyConfProperty][value.index][value.configFieldName]) {
          mandatoryFromConfig = this.isFieldMandatory(config.partyConfig[value.partyConfProperty][value.index][value.configFieldName]);
          exclusiveFromConfig = this.isFieldExclusive(config.partyConfig[value.partyConfProperty][value.index][value.configFieldName]);
          alternativeFromConfig = this.isFieldAlternative(config.partyConfig[value.partyConfProperty][value.index][value.configFieldName]);
          alternativeLabels = config.partyConfig[value.partyConfProperty][value.index][value.configFieldName].alternativeLabels;
          hidden = this.isFieldHidden(config.partyConfig[value.partyConfProperty][value.index][value.configFieldName]);
          disabled = this.isDisabled(config.partyConfig[value.partyConfProperty][value.index][value.configFieldName]);
        } else if (!!config.partyConfig && !!config.partyConfig[value.configFieldName] && !Array.isArray(config.partyConfig[value.configFieldName])) {
          mandatoryFromConfig = this.isFieldMandatory(config.partyConfig[value.configFieldName]);
          exclusiveFromConfig = this.isFieldExclusive(config.partyConfig[value.configFieldName]);
          alternativeFromConfig = this.isFieldAlternative(config.partyConfig[value.configFieldName]);
          alternativeLabels = config.partyConfig[value.configFieldName].alternativeLabels;
          hidden = this.isFieldHidden(config.partyConfig[value.configFieldName]);
          disabled = this.isDisabled(config.partyConfig[value.configFieldName]);
        } else if(!!config.partyConfig && !!config.partyConfig[value.configFieldName] && !value.index && Array.isArray(config.partyConfig[value.configFieldName])) {
          mandatoryFromConfig = this.isFieldMandatory(config.partyConfig[value.configFieldName][value.index]);
          exclusiveFromConfig = this.isFieldExclusive(config.partyConfig[value.configFieldName][value.index]);
          alternativeFromConfig = this.isFieldAlternative(config.partyConfig[value.configFieldName][value.index]);
          alternativeLabels = config.partyConfig[value.configFieldName][value.index].alternativeLabels;
          hidden = this.isFieldHidden(config.partyConfig[value.configFieldName][value.index]);
          disabled = this.isDisabled(config.partyConfig[value.configFieldName][value.index]);
        } else if (!!config.configurations && config.configurations.length) {
          const paConfField = config.configurations.find(c => c.label === value.configFieldName);
          if (!!paConfField && !!paConfField.configuration) {
            mandatoryFromConfig = this.isPaConfAttrFieldMandatory(paConfField.configuration);
            exclusiveFromConfig = this.isPaConfAttrFieldExclusive(paConfField.configuration);
            alternativeFromConfig = this.isPaConfAttrFieldAlternative(paConfField.configuration);
            alternativeLabels = paConfField.alternativeLabels;
            hidden = this.isPaConfAttrFieldHidden(paConfField.configuration);
            disabled = this.isPaConfAttrFieldDisabled(paConfField.configuration);
          }
        }
        if (!hidden) {
          value.validators = [];
          if (!value.validators.some(v => v === Validators.required) && mandatoryFromConfig || exclusiveFromConfig || alternativeFromConfig) {
            value.validators.push(Validators.required);
          }
          if (value.form.get(key)) {
            this.exclusiveAndAlternativeManagement(value, key, alternativeLabels, formMap, exclusiveFromConfig);
            value.form.get(key).setValidators(value.validators);
            value.form.get(key).updateValueAndValidity({onlySelf: false, emitEvent: false});
          } else {
            value.form.setControl(key, new UntypedFormControl(value.form.get(key) ? value.form.get(key).value : '',
              value.validators));
            this.exclusiveAndAlternativeManagement(value, key, alternativeLabels, formMap, exclusiveFromConfig);
          }
          if (disabled) {
            value.form.get(key).disable();
          }
        } else {
          value.form.removeControl(key);
        }
      }
    }
  }

  private exclusiveAndAlternativeManagement(value: AnagFormFieldConfig, key: string, alternativeLabels, formMap: Map<string, AnagFormFieldConfig>, exclusiveFromConfig) {
    value.form.get(key).valueChanges.pipe(distinctUntilChanged()).subscribe(configFieldName => {
      if (alternativeLabels && alternativeLabels.length > 0) {
        alternativeLabels.forEach(x => {
          for (const [key1, value1] of formMap) {
            if (value1.configFieldName === x) {
              if (exclusiveFromConfig) {
                configFieldName ? value.form.get(key1).disable() : value.form.get(key1).enable();
              }
              configFieldName ? value.form.get(key1).setValidators(null) : value.form.get(key1).setValidators(Validators.required);
              break;
            }
          }
        });
      }
    });
  }

  dateComparisonValidator(control1, control2) {
    if (control1.value && control2.value) {
      if (control1.value instanceof Date && control2.value instanceof Date) {
        if (control1.value > control2.value) {
          control2.markAsTouched();
          control2.setErrors({'_ANAG_._MSG_._EXPIRATION_DATE_AFTER_RELEASE_' : true});
        } else {
          control2.setErrors(null);
        }
      } else {
        control2.setErrors({invalidDate: true});
      }
    } else {
      const currValidators = control2.validator({} as AbstractControl);
      if (currValidators && currValidators.required) {
        control2.setErrors({required: true});
      }
    }
  }

  isFieldBirthNameValid(config: AnagConfiguration, party: AnagApiParty) {
    return (!this.isFieldMandatory(config.partyConfig.birthName) || !!party.birthName);
  }
}

import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {RgiRxDateTimeInputDirective} from './date-time-input.directive';

@Directive({
  selector: '[rgiRxDateTimeTrigger]'
})
export class RgiRxDateTimeTriggerDirective implements OnInit, OnDestroy {

  constructor(private _elementRef: ElementRef) {
  }

  @Input('rgiRxDateTimeTrigger') targetDateTimeInput: RgiRxDateTimeInputDirective<any>;

  ngOnInit(): void {
    // todo implement ngOnChanges to change the trigger handler dynamicly
    if (this.targetDateTimeInput) {
      this.targetDateTimeInput.isTriggeredBy.push(this);
    }
  }

  @HostListener('click', ['$event'])
  handleHostClick(event: Event) {
    if (this.targetDateTimeInput) {
      if (!this.targetDateTimeInput.disabled) {
        this.targetDateTimeInput.open();
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  get elementRef(): ElementRef {
    return this._elementRef;
  }

  ngOnDestroy(): void {
    if (!!this.targetDateTimeInput) {
      this.targetDateTimeInput.isTriggeredBy = this.targetDateTimeInput.isTriggeredBy.filter(f => f !== this);
    }
  }
}

import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {UnitService} from '../re-issue/re-issue-quotation/re-issue-quotation-services/unit.service';

@Directive({
  selector: '[reIssueCurrency]',
  providers: [DecimalPipe]
})
export class ReIssueCurrencyDirective implements OnInit {

  // not mandatory to set (default false)
  @Input() negativeAccepted = false;

  private digitsInfo = '1.2-2';
  private regexMaxTwoDecimals = new RegExp(/^-?\d*[.,]?\d{0,2}$/g);
  private regex = new RegExp(/^-?\d*[.,]?\d*/);
  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private unitService: UnitService,
              private decimalPipe: DecimalPipe) {
  }

  ngOnInit(): void {
    if (this.el.nativeElement.value ) {
      this.onBlur();
    }
  }

  @HostListener('blur') onBlur() {
    let inputValue = this.el.nativeElement.value;
    inputValue = inputValue.replace(',', '.');
    const formattedValue = this.formatNumber(inputValue);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }

  private formatNumber(value: string): string {
    const locale = this.unitService.getLocaleId();
    value = this.decimalPipe.transform(value, this.digitsInfo, locale);
    return value;
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home'].indexOf(e.key) !== -1 ||
      // Allow: Ctrl+A
      (e.key === 'a' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.key === 'c' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.key === 'v' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.key === 'x' && (e.ctrlKey || e.metaKey))) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const acceptedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
    if (this.negativeAccepted) {
      acceptedValues.push('-');
    }
    if (e.shiftKey ||
      acceptedValues.indexOf(e.key) === -1 ||
      ['-'].indexOf(e.key) !== -1 && current.indexOf('-') !== -1 ||
      (['.', ','].indexOf(e.key) !== -1 && (current.indexOf('.') !== -1 || current.indexOf(',') !== -1))) {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    let current: string = this.el.nativeElement.value;

    if (String(current).match(this.regex)) {
      // check decimals and delete the surplus
      const indexPoint = current.indexOf('.');
      const indexComma = current.indexOf(',');
      const decimals = current.substring((indexPoint !== -1 ? indexPoint : indexComma) + 1);
      if ((indexPoint !== -1 || indexComma !== -1) && decimals.length > 2) {
        current = current.substring(0, current.length - decimals.length + 2);
        this.el.nativeElement.value = current;
      }

      const minus = current.indexOf('-');
      if (minus !== -1 && !this.negativeAccepted) {
        this.el.nativeElement.value = '';
      }
    }
    if (!String(current).match(this.regexMaxTwoDecimals)) {
      this.el.nativeElement.value = '';
    }
  }
}

<label class="rgi-ui-form-control-label rgi-rx-drag-label">
  <ng-container
    *ngTemplateOutlet="!labelTemplate ? defaultLabel : labelTemplate; context: _getDragTemplateCtx()"></ng-container>
</label>
<div class="rgi-ui-drag-content"
[class.rgi-ui-disabled]="disabled">
  <div class="rgi-ui-drag-value rgi-rx-drag-value">
    <ng-container
      *ngTemplateOutlet="!valueTemplate ? defaultValue : valueTemplate; context: _getDragTemplateCtx()"></ng-container>
  </div>
  <div class="rgi-ui-drag-icons rgi-rx-drag-actions">
        <span *ngFor="let action of dragActions" class="rgi-ui-icon rgi-rx-drag-action"
              [rgiRxTooltip]="action.description"
              [disableTooltip]="!action.description"
              [ngClass]="action.class"
              (click)="emitAction($event,action)"></span>
    <span #removeHandle class="rgi-ui-icon rgi-rx-drag-delete" [ngClass]="getRemoveClass()"
          [rgiRxTooltip]="removeDescription"
          [disableTooltip]="!removeDescription"
          (click)="remove($event)"></span>
  </div>
</div>

<ng-template #defaultLabel let-ctx="label">
  {{ctx}}
</ng-template>
<ng-template #defaultValue let-ctx="viewModel">
  {{ctx}}
</ng-template>

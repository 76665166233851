import { Component, OnInit, Output, EventEmitter, Inject, Input, LOCALE_ID } from '@angular/core';
import { RoutableComponent, RoutingService } from '@rgi/portal-ng-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimOpeningPolicyDetailsComponent } from '../claim-opening-policy-details/claim-opening-policy-details.component';
import { ClaimOpeningService } from '../../claim-opening.service';
import {
  ApiTranslatorPolicyService,
  Policy,
  FunctionService,
  PolicyService,
  AlertService,
  Outcome,
  DamageLocation
} from '@rgi/digital-claims-common-angular';
import { TimeRequest } from '../claim-opening-policy-search/dto/time-request';
import { ClaimOpeningPolicySearchResultsService } from './claim-opening-policy-results.service';
import { PotentialClaimData } from './model/domain/potential-claim-data';
import { formatDate } from '@angular/common';
import * as uuid from 'uuid';
import { ClaimOpeningDetailsComponent } from '../claim-opening-details/claim-opening-details.component';
import { StepperSection } from '../claim-opening-details/model/stepper-section';
import { DamageCategory } from '../claim-opening-policy-search/dto/damage-categories';
import { SimilarclaimsCriteriaEntity } from './dto/similarclaims-criteria-entity';
import { SimilarClaimsComponent } from './similar-claims/similar-claims.component';
import { UtilityService } from '../../../external-portfolio/utility.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { Observable, combineLatest } from 'rxjs';

@Component({
  selector: 'claims-claim-opening-policy-results',
  templateUrl: './claim-opening-policy-results.component.html',
  styleUrls: ['./claim-opening-policy-results.component.scss']
})
export class ClaimOpeningPolicyResultsComponent implements OnInit, RoutableComponent {
  @Input() public card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  policyModifyData: any;

  policies: Policy[];
  emitter: any;
  sessionService: any;
  currentStep = StepperSection.selectPolicy;
  isServicePolicyDetailsEnded: boolean;
  covertureOk: boolean;
  coverturesFromService: any;
  inputPotentialClaim: PotentialClaimData;
  private timeRequest: TimeRequest;
  stepperLabels = [];
  damageCategory: DamageCategory;
  externalPortfolio: boolean;
  unitsProductbin: Array<any>;
  listFunz: any[];
  claimsOriginalEffectDateEnable: boolean;
  stepperSubscription: Observable<string[]>;


  constructor(
    @Inject('eventService') eventService: any,
    @Inject('sessionService') sessionService: any,
    @Inject('authService') private authService,
    @Inject(LOCALE_ID) private locale: string,
    @Inject('getSystemProperty') private getSystemProperty: any,
    private policyService: PolicyService,
    private claimOpeningService: ClaimOpeningService,
    private modalService: NgbModal,
    private translatorService: ApiTranslatorPolicyService,
    private functionService: FunctionService,
    private alertService: AlertService,
    private utilytyService: UtilityService,
    private claimOpeningPolicySearchResultsService: ClaimOpeningPolicySearchResultsService,
    private routingService: RoutingService,
    private rxTranslationsService: RgiRxTranslationService) {
    this.emitter = eventService;
    this.sessionService = sessionService;
    this.externalPortfolio = false;
    this.claimsOriginalEffectDateEnable = false;
    const obsSelPolicy$ = this.rxTranslationsService.translate('_CLAIMS_._SELECT_POLICY');
    const obsOccData$ = this.rxTranslationsService.translate('_CLAIMS_._OCCURRENCE_DATA');
    const obsDamAsset$ = this.rxTranslationsService.translate('_CLAIMS_._DAMAGED__ASSET')
    const obsSelTypeOfLoss$ = this.rxTranslationsService.translate('_CLAIMS_._SELECT_TYPE_OF_LOSS');
    const obsDoc$ = this.rxTranslationsService.translate('_CLAIMS_._DOCUMENTS');
    this.stepperSubscription = combineLatest([
      obsSelPolicy$,
      obsOccData$,
      obsDamAsset$,
      obsSelTypeOfLoss$,
      obsDoc$,
    ])

    this.stepperSubscription.subscribe(
      ([selPolicy, occData, damAsset, selTy, obsDoc]) => {
        this.stepperLabels = [selPolicy, occData, damAsset, selTy, obsDoc];
      })
  }


  ngOnInit(): void {
    this.listFunz = this.authService.getOperator().enabledFeatures;
    const that = this;
    const uniquePoliciesMap = new Map<number, Policy>();
    const promiseConfig = this.getSystemProperty.findProperty('ClaimsOriginalEffectDate');
    promiseConfig.then((result) => {
      if (result.systemProperties && result.systemProperties.length === 1) {
        that.claimsOriginalEffectDateEnable = result.systemProperties[0].value === 'Enable';
      }
    });
    if (this.card && this.card.id) {
      this.policies = this.claimOpeningService.getSessionPolicies(this.card.id);
      this.timeRequest = this.claimOpeningService.getSessionTimeRequest(this.card.id);
      this.policyModifyData = this.claimOpeningService.getPolicyModifyDataFromSession(this.card.id);
    } else {
      this.policies = this.claimOpeningService.getPolicies();
    }
    for (const policy of this.policies) {
      uniquePoliciesMap.set(policy.objectId, policy);
    }
    this.policies = Array.from(uniquePoliciesMap.values());
    this.policies.sort((a, b) => {
      return new Date(a.effectDate).getTime() - new Date(b.effectDate).getTime();
    });
    this.damageCategory = this.claimOpeningService.getCategory(this.card.id);
    this.externalPortfolio = this.claimOpeningService.getExternaPortfolio();

    if (this.externalPortfolio) {
      this.emitter.broadcastEvent('start-loader');
      this.claimOpeningPolicySearchResultsService
        .unitsProductBin(this.policies[0].product, this.damageCategory.idCategory).subscribe((res: any) => {
          console.log('unitsProductBin ' + res);
          this.unitsProductbin = res.units;
          this.policies.forEach(aExtPol => {
            aExtPol.units = res.units;
            aExtPol.claimBranchCodes = res.branches;
          });
          this.emitter.broadcastEvent('stop-loader');
        },
          (error: any) => {
            this.emitter.broadcastEvent('stop-loader');
          }
        );

    }
  }

  policyDescription(policy: Policy): string {
    if (policy.product) {
      return policy.description + ' - ' + policy.product;
    } else {
      return policy.description;
    }
  }

  hasResult(): boolean {
    return !!this.policies && this.policies.length > 0;
  }

  hasUnit(): boolean {
    for (const policy of this.policies) {
      if (policy.units) {
        return true;
      }
    }
    if (this.externalPortfolio) {
      return true;
    }

    return false;
  }

  back() {
    const session = this.getCurrentSession();
    this.sessionService.remove(session.idSession);
  }

  getCurrentSession() {
    return this.sessionService.list().find(el => el.isActive);
  }

  policyDetails(policyFromList: Policy) {

    this.emitter.broadcastEvent('start-loader');
    const req = {
      filterPolicies: null
    };

    this.policyService.getPolicyDetail(policyFromList.objectId)
      .subscribe((response: any) => {
        const contractRes = response.contract;
        console.log(contractRes);
        if (contractRes) {

          const policyDetail = this.translatorService.apiPolicyToDtoPolicy(contractRes);

          const modalRef = this.modalService.open(ClaimOpeningPolicyDetailsComponent, {
            windowClass: 'basic',
            size: 'lg'
          });
          modalRef.componentInstance.policy = policyDetail;
          modalRef.componentInstance.contract = contractRes;

        }
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.emitter.broadcastEvent('stop-loader');
        }
      );
  }

  selectPolicyDetail(policy: Policy) {
    const occurrenceDate = this.claimOpeningService.getSessionOccurrenceDate(this.card.id);
    const occurrenceTime = this.claimOpeningService.getSessionTimeRequest(this.card.id).toString();
    this.emitter.broadcastEvent('start-loader');
    // we call the two services in concurrency, the following two functions both call
    // initPotentialClaim, that, first of all, checks if policy details service has ended and if
    // the coverture check is ok. If that's the case, so both services had ended with outcome ok,
    // then we save the data and go on the next route
    if (this.claimOpeningService.getExternaPortfolio()) {
      const asset = policy.asset;
      const polExt = this.claimOpeningService.getListaExternalPolicy()
        .find(aExtPol => aExtPol.policyId + aExtPol.assetInsured === (policy.objectId + asset));
      this.claimOpeningService.setExternalPolicyCurrent(polExt);
    }

    if (!policy.claimBranchCodes) {
      this.claimOpeningPolicySearchResultsService
        .unitsProductBin(policy.product, this.damageCategory.idCategory).subscribe((res: any) => {
          console.log('unitsProductBin per simila claims - paracadute per assenza info extension da PTF' + res);
          this.callServiceSimilarClaims(policy, occurrenceDate, occurrenceTime, res.branches);
          // this.emitter.broadcastEvent('stop-loader');
        },
          (error: any) => {
            this.callServiceSimilarClaims(policy, occurrenceDate, occurrenceTime);
          }
        );
    } else {
      this.callServiceSimilarClaims(policy, occurrenceDate, occurrenceTime);
    }
  }

  callServiceSimilarClaims(policy: Policy, occurrenceDate: Date, occurrenceTime: string, branches?: string[]) {
    const criteria = new SimilarclaimsCriteriaEntity();
    this.claimOpeningService.setExistClMadePostuma(false);

    criteria.policyNumber = policy.number;
    criteria.branchesCodes = branches ? branches : policy.claimBranchCodes;
    criteria.externalPolicy = this.claimOpeningService.getExternalPolicyCurrent();
    criteria.typePtf = this.claimOpeningService.getTypePtf();
    criteria.occurrenceDate = occurrenceDate;


    this.claimOpeningPolicySearchResultsService.similarClaims(criteria)
      .subscribe((res: any) => {
        if (res.listPotentialClaims) {
          console.log('similarClaims ' + res.listPotentialClaims.length);
        } else {
          console.log('similarClaims 0');
        }

        this.claimOpeningService.setVisualizedMsgPostuma(false);
        this.claimOpeningService.setExistClMadePostuma(res.existClMadePostuma);
        if (res.listPotentialClaims && res.listPotentialClaims.length > 0) {
          this.navigation.emit('similarClaims');
          this.card.title = 'Similar complaints';
          (this.routingService.componentRef.instance as SimilarClaimsComponent).card = this.card;
          (this.routingService.componentRef.instance as SimilarClaimsComponent).policy = policy;
          (this.routingService.componentRef.instance as SimilarClaimsComponent).listPotentialClaims = res.listPotentialClaims;
          (this.routingService.componentRef.instance as SimilarClaimsComponent).policyModifyData = this.policyModifyData;

          this.emitter.broadcastEvent('stop-loader');
        } else {
          this.chargeDetail(policy, occurrenceDate, occurrenceTime);
        }
      },
        (error: any) => {
          this.chargeDetail(policy, occurrenceDate, occurrenceTime);
        }
      );
  }

  chargeDetail(policy: Policy, occurrenceDate: Date, occurrenceTime: string) {
    if (this.claimOpeningService.getExternaPortfolio()) {
      this.callPolicyDetailsCovertureExt(policy, occurrenceDate, occurrenceTime);
    } else {
      this.callPolicyDetails(policy);
      this.callVerifyCoverture(policy, occurrenceDate, occurrenceTime);
    }
  }

  callPolicyDetailsCovertureExt(policy: Policy, occurrenceDate: Date, occurrenceTime: string) {
    const polExt = this.claimOpeningService.getExternalPolicyCurrent();

    this.claimOpeningPolicySearchResultsService.policyDataPtfExt(polExt, JSON.stringify(occurrenceDate)).subscribe((res: any) => {
      console.log('policyDataPtfExt  ' + res);
      policy.assets = res.assets;
      policy.residenceHolder = new DamageLocation();
      policy.residenceHolder.formatAddress = res.formatAddress;
      policy.residenceHolder.address = res.resHolderaddress;
      policy.residenceHolder.houseNumber = res.resHolderhouseNumber;
      policy.residenceHolder.zipCode = res.resHolderzipCode;
      policy.residenceHolder.city = res.resHoldercity;
      policy.residenceHolder.adminLevel3 = res.resHolderadminLevel3;
      policy.residenceHolder.province = res.resHolderprovince;
      policy.residenceHolder.adminLevel2 = res.resHolderadminLevel2;
      policy.residenceHolder.nationAbbreviation = res.resHoldernationAbbreviation;
      this.isServicePolicyDetailsEnded = true;
      const sessionUuid = uuid.v4();
      this.claimOpeningService.setSessionUuid(this.card.id, sessionUuid);
      const formatdate = 'dd-MM-yyyy';
      const dateString = occurrenceDate ? formatDate(occurrenceDate, formatdate, this.locale) : null;

      const covertures = { administrativeCoverture: true, technicalCoverture: true };
      this.coverturesFromService = covertures;
      this.inputPotentialClaim = new PotentialClaimData(
        sessionUuid,
        policy.number,
        this.damageCategory.idCategory,
        dateString, occurrenceTime,
        covertures.technicalCoverture.toString(),
        covertures.administrativeCoverture.toString(),
        formatDate(policy.effectDate, formatdate, this.locale),
        formatDate(policy.expirationDate, formatdate, this.locale));
      this.covertureOk = true;

      this.initPotentialClaim(policy);
    });
  }

  callPolicyDetails(policy: Policy) {
    this.policyService.getPolicyDetail(policy.objectId)
      .subscribe((response: any) => {
        const contractRes = response.contract;
        console.log(contractRes);
        if (contractRes) {
          const policyDetail = this.translatorService.apiPolicyToDtoPolicy(contractRes);
          policy.assets = policyDetail.assets;
          policy.residenceHolder = policyDetail.residenceHolder;
          // policy.category = contractRes.currentMovement.category.codice;
          this.claimOpeningService.setPolicyDetail(contractRes);
        }
        this.isServicePolicyDetailsEnded = true;
        this.initPotentialClaim(policy);
      });
  }

  callVerifyCoverture(policy: Policy, occurrenceDate: Date, occurrenceTime: string) {
    combineLatest([
      this.policyService.getPolicyAdditionalData(policy.number, this.damageCategory.idCategory, occurrenceDate, occurrenceTime),
      this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY'),
      this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY')
    ]).subscribe(([additionalData, msg1, msg2]) => {
      // this.policyService.getPolicyCovertures(policy.number, occurrenceDate, occurrenceTime).subscribe(covertures => {
      const covertures = additionalData.covertures;
      const sessionUuid = uuid.v4();
      this.claimOpeningService.setSessionUuid(this.card.id, sessionUuid);
      const formatdate = 'dd-MM-yyyy';
      const dateString = occurrenceDate ? formatDate(occurrenceDate, formatdate, this.locale) : null;
      this.coverturesFromService = covertures;
      this.inputPotentialClaim = new PotentialClaimData(
        sessionUuid,
        policy.number,
        this.damageCategory.idCategory,
        dateString, occurrenceTime,
        covertures.technicalCoverture.toString(),
        covertures.administrativeCoverture.toString(),
        formatDate(policy.effectDate, formatdate, this.locale),
        formatDate(policy.expirationDate, formatdate, this.locale));

      if (!covertures.technicalCoverture &&
        this.listFunz && this.listFunz.indexOf('SXIPNV') < 0) {
        this.emitter.broadcastEvent('stop-loader');
        this.alertService.translatedMessage(msg1)
      } else if (!covertures.administrativeCoverture &&
        !this.functionService.isAuthorizedForClaimFeature('ADMINISTRATIVE_FORCING_COMPLAINT')) {
        this.emitter.broadcastEvent('stop-loader');
        this.alertService.translatedMessage(msg2)
      } else {
        this.covertureOk = true;
      }

      this.claimOpeningService.setSessionInsuredActivities(this.card.id, additionalData.insuredActivityFactors);
      this.claimOpeningService.setEnableMultiDamage(additionalData.enableMultiDamage);
      this.claimOpeningService.setGarctp(additionalData.garctp);
      this.initPotentialClaim(policy);

    });
  }

  initPotentialClaim(policy: Policy) {
    if (this.covertureOk && this.isServicePolicyDetailsEnded) {
      this.rxTranslationsService.translate('_CLAIMS_._FNOL_DATA_COLLECTION')
        .subscribe(
          res => this.card.title = res
        )
      this.claimOpeningService.setSessionSelectedPolicy(this.card.id, policy);
      this.claimOpeningService.setSessionAdministrativeCoverture(this.card.id, this.coverturesFromService.administrativeCoverture);
      // save cache
      combineLatest([
        this.claimOpeningPolicySearchResultsService.initPotentialClaim(this.inputPotentialClaim),
        this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._CACHE_ERROR')
      ])
        .subscribe(([outcome, msgCacheError]: [Outcome, string]) => {

          if (outcome.code === '1') {

            // go to detail
            this.navigation.emit('details');
            (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).card = this.card;
          } else {
            this.alertService.translatedMessage(msgCacheError);
          }
          this.emitter.broadcastEvent('stop-loader');
        },
          (error: Error) => {
            console.log(error);
            this.emitter.broadcastEvent('stop-loader');
          }
        );
    }
  }

  isPolicyEnabled(policy: Policy): boolean {
    if (policy.claimBranchCodes && !this.externalPortfolio) {
      const codesInCategory = this.damageCategory.branchesCodes;
      const interserction = policy.claimBranchCodes.filter(x => codesInCategory.includes(x));
      return interserction && interserction.length > 0;
    }
    return true;
  }

  isPolicyCanceled(policy: Policy): boolean {
    return policy.isCanceled;
  }

}






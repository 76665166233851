import {AfterContentInit, AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, OnDestroy, Output} from '@angular/core';
import {FocusableOption, FocusMonitor, FocusOrigin, Highlightable} from '@angular/cdk/a11y';
import {Subject} from 'rxjs';
import {RGI_RX_MENU, RgiRxMenu} from './rgi-rx-menu-api';
import {RgiRxQAService} from '@rgi/rx';

export interface RgiRxMenuSelectionChange {
  item: RgiRxMenuItemDirective;
  label: string;
}

@Directive({
  selector: '[rgiRxMenuItem],[rgi-rx-menu-item]',
  host: {
    class: 'rgi-ui-menu-item',
    '[attr.role]': 'role',
    '[attr.tabindex]': '_getTabIndex()',
  },
  exportAs: 'rgiRxMenuItem',
  providers: [
    RgiRxQAService
  ]
})
export class RgiRxMenuItemDirective implements FocusableOption, Highlightable, AfterViewInit, AfterContentInit, OnDestroy {

  role = 'menuitem';

  /** Stream that emits when the menu item is hovered. */
  readonly _hovered: Subject<RgiRxMenuItemDirective> = new Subject<RgiRxMenuItemDirective>();
  /** Stream that emits when the menu item is focused. */
  readonly _focused: Subject<RgiRxMenuItemDirective> = new Subject<RgiRxMenuItemDirective>();

  @Output() selected = new EventEmitter<RgiRxMenuSelectionChange>();

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _focusMonitor: FocusMonitor,
    @Inject(RGI_RX_MENU) private _menu: RgiRxMenu,
    private _qaService: RgiRxQAService
  ) {
    this._focusMonitor.monitor(this._elementRef);
  }


  private _disabled: boolean;
  private _active: boolean;
  private _label: string;

  focus(origin?: FocusOrigin, options?: FocusOptions): void {
    if (this._focusMonitor && origin) {
      this._focusMonitor.focusVia(this._elementRef.nativeElement, origin, options);
    } else {
      this._elementRef.nativeElement.focus(options);
    }
    this._focused.next(this);
  }


  @HostListener('mouseover', ['$event'])
  handleMouseover(event: Event) {
    if (!this.disabled) {
      this._hovered.next(this);
    }
  }

  @HostListener('mouseenter')
  _handleMouseEnter() {
    this._hovered.next(this);
  }

  @HostListener('click', ['$event'])
  _handleClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    const clickTarget = event.target as HTMLElement;
    if (!clickTarget.hasAttribute('rgi-rx-trigger-for')) {
      this.selected.next({
        item: this,
        label: this.label
      });
    }
  }


  ngAfterViewInit() {
    if (this._focusMonitor) {
      this._focusMonitor.monitor(this._elementRef, false);
    }
  }

  ngAfterContentInit(): void {
    this._qaService.render(this.elementRef.nativeElement, {
      id: this.getLabel(),
      value: this.getLabel(),
      type: 'menu-item'
    }, 'child');
  }


  ngOnDestroy(): void {
    if (this._focusMonitor) {
      this._focusMonitor.stopMonitoring(this._elementRef);
    }
    this._hovered.complete();
    this._focused.complete();
    this._qaService.clear(this.elementRef.nativeElement);
  }

  getLabel(): string {
    // if label is defined use it as search term
    if (!!this.label) {
      return this.label;
    }
    // try to get content of the element without fancy stuff
    const element: HTMLElement = this._elementRef.nativeElement;
    let output = '';
    if (element.childNodes) {
      const length = element.childNodes.length;
      for (let i = 0; i < length; i++) {
        if (element.childNodes[i].nodeType === 3 || element.childNodes[i].nodeType === 1) {
          output += element.childNodes[i].textContent;
        }
      }
    }
    return output.trim();
  }

  _getTabIndex(): string {
    return this.disabled ? '-1' : '0';
  }


  @Input() @HostBinding('class.rgi-ui-disabled') get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (typeof value === 'string') {
      value = value !== 'false';
    }
    this._disabled = value;
  }

  @Input() get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  @Input() @HostBinding('class.rgi-ui-active') get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  setActiveStyles(): void {
    if (!this._active) {
      this.active = true;
    }
  }

  setInactiveStyles(): void {
    if (this._active) {
      this.active = false;
    }
  }

  get parentMenu(): RgiRxMenu {
    return this._menu;
  }


  get elementRef(): ElementRef<HTMLElement> {
    return this._elementRef;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {Address} from '../models/address';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address): any {
    return address.placeAddress + ' ' +
      address.number + ' ' +
      address.cap + ' ' +
      address.city + ' - ' +
      address.country;
  }

}

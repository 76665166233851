import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CardService,
  DigitalClaimsCommonAngularModule,
  enumServiceProvider,
  getSystemPropertyProvider,
  containerCacheServiceProvider
} from '@rgi/digital-claims-common-angular';
import { CompensationListComponent } from './compensation-list.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CompensationBoardComponent } from './compensation-board/compensation-board.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {RgiRxFormElementsModule, RgiRxTableModule} from '@rgi/rx/ui';
import { CompensationDiaryComponent } from './compensation-diary/compensation-diary.component';
import {ReactiveFormsModule} from '@angular/forms';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
  declarations: [CompensationListComponent, CompensationBoardComponent, CompensationDiaryComponent],
    imports: [
        CommonModule,
        DigitalClaimsCommonAngularModule,
        [NgbModule],
        PortalNgCoreModule,
        RgiRxTableModule,
        RgiRxFormElementsModule,
        ReactiveFormsModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
  exports: [
    CompensationListComponent,
  ],
  // entryComponents : [CompensationListComponent, CompensationBoardComponent, CompensationDiaryComponent],
  providers: [
    { provide: 'compensationBoardComponent', useValue: CompensationBoardComponent},
    { provide: 'compensationDiaryComponent', useValue: CompensationDiaryComponent},
    enumServiceProvider,
    getSystemPropertyProvider,
    containerCacheServiceProvider,
  ]
})
export class CompensationListModule {
  constructor(cardService: CardService) {
    const title = '_COMPENSATION_LIST';
    const moduleName = 'compensation-list';
    const parentCardName = 'claimsFinder';
    cardService.registerSubCard(
      moduleName,
      title,
      CompensationListComponent,
      parentCardName);

  }

}

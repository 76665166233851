import {ValueFlatI} from './value-flatI';

export class AnswerFlatI {
  paramType: number;
  factorFormat: string;
  formatter: any;
  notes: any;
  factorCode: string;
  defaultValue: string;
  values: ValueFlatI[];
  factorType: number;
  value: string;
  factorDescr: string;
  multivalue: boolean;
  ppfactorCode?: string;
  trascode?: string;

  constructor() {
  }
}

<div class="ac_cont_dati">
    <div class="ac_cont_riga">
        <div>
            <div *ngIf="!subcard"  class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._REQUEST_NODE</span>
                <span>: <b>{{ elementCurrentDetail.requestNodeDescr }}</b></span>
            </div>
            <div *ngIf="subcard"  class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._REQUEST_NODE</span>
                <span>: <b>{{ elementCurrentDetail.requestNode + ' ' + elementCurrentDetail.requestNodeDescr }}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._REQUEST_NUMBER</span>
                <span>: <b>{{ elementCurrentDetail.requestCode }}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._PROPOSAL_NUMBER</span>
                <span>: <b>{{ elementCurrentDetail.proposalNumber }}</b></span>
            </div>
        </div>
    </div>
    <div class="ac_cont_riga">
        <div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._POLICY_NUMBER</span>
                <span>: <b>{{ elementCurrentDetail.policyNumber }}</b></span>
            </div>
            <div *ngIf="!subcard" class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._REQUEST_STATUS</span>
                <span>: <b>{{ elementCurrentDetail.state.description + '(' + elementCurrentDetail.workflowState.description + ')'}}</b></span>
            </div>
            <div *ngIf="subcard" class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._REQUEST_DATE</span>
                <span>: <b>{{ elementCurrentDetail.requestDate | date:'dd/MM/yyyy'}}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._SUBSCRIBER</span>
                <span>: <b>{{ elementCurrentDetail.subscriber.description }}</b></span>
            </div>
        </div>
    </div>
    <div *ngIf="!subcard" class="ac_cont_riga">
        <div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._PRIORITY</span>
                <span>: <b>{{ elementCurrentDetail.priority ? elementCurrentDetail.priority.description : ''}}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._COMPETENT_USER</span>
                <span>: <b>{{ elementCurrentDetail.competentUser }}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._PRODUCT</span>
                <span>: <b>{{ elementCurrentDetail.product.description }}</b></span>
            </div>
        </div>
    </div>
    <div *ngIf="elementCurrentDetail.operation || elementCurrentDetail.effectiveDate || elementCurrentDetail.issueDate || elementCurrentDetail.issueUser">
        <div class="ac_cont_riga">
            <b><span translate>_AUTH_._REQUEST_OPERATION</span></b>
        </div>
        <div class="ac_cont_riga">
            <div>
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span translate>_AUTH_._OPERATION</span>
                    <span>: <b>{{ elementCurrentDetail.operation }}</b></span>
                </div>
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span translate>_AUTH_._EFFECTIVE_DATE</span>
                    <span>: <b>{{ elementCurrentDetail.effectiveDate | date:'dd/MM/yyyy'}}</b></span>
                </div>
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span translate>_AUTH_._ISSUE_DATE</span>
                    <span>: <b>{{ elementCurrentDetail.issueDate | date:'dd/MM/yyyy'}}</b></span>
                </div>
            </div>
        </div>
        <div class="ac_cont_riga">
            <div>
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span translate>_AUTH_._ISSUE_USER</span>
                    <span>: <b>{{ elementCurrentDetail.issueUser }}</b></span>
                </div>
            </div>
        </div>
    </div>
</div>

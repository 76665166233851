<rgi-rx-expansion-panel *ngIf="factors.length > 0" [expanded]="false" color="default" >
  <rgi-rx-expansion-panel-header>
    <span rgi-rx-expansion-panel-label translate>CONTRACT_CONSULTATION.FACTORS</span>
  </rgi-rx-expansion-panel-header>
  <div *ngIf="factors" class="rgi-ui-col rgi-ui-grid-3">
    
      <div *ngFor="let factor of factors" [ngSwitch]="factor.factorType" class="rgi-ui-col">
        <div class="rgi-cc-row">
          <span class="rgi-ui-text-5">{{ factor.factor.description }}: </span>
          <span *ngSwitchCase="0" class="rgi-ui-title-3">{{  factor.description }}</span>
          <span *ngSwitchCase="1" class="rgi-ui-title-3">{{  factor.doubleValue }}</span>
          <span *ngSwitchCase="2" class="rgi-ui-title-3">{{  factor.description }}</span>
          <span *ngSwitchCase="3" class="rgi-ui-title-3">{{  factor.doubleValue == 1 ? 'Yes' : 'No' }}</span>
          <span *ngSwitchCase="4" class="rgi-ui-title-3">{{  factor.dateValue | date: dateFormat }}</span>
          <span *ngSwitchCase="5" class="rgi-ui-title-3">{{  factor.stringValue }}</span>
        </div>
      </div>
    

    
  </div>
</rgi-rx-expansion-panel>

<form [formGroup]="formGroup">
  <div [ngSwitch]="inputType">
    <!-- <pre>{{definition.mandatory}}</pre> -->
    <div class="factor form-group" *ngSwitchCase="'ENUM'">
      <rgi-rx-form-field [attr.data-qa]="'factor-select-' + definition.code">
        <label rgiRxLabel>{{(!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code) | translate }}</label>
        <select rgiRxNativeSelect formControlName="factor">
          <option value=""></option>
          <option *ngFor="let toolOpt of definition.values" [value]="toolOpt.id">{{ toolOpt.description }}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div class="factor form-group" *ngSwitchCase="'CHECKBOX'">
      <rgi-rx-form-field [attr.data-qa]="'factor-' + definition.code">
        <input type="checkbox" rgiRxInput formControlName="factor">
        <label rgiRxLabel>{{!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code }}</label>
      </rgi-rx-form-field>
      <!-- <pnc-checkbox formControlName="factor" [attr.data-qa]="'factor-' + definition.code">
        <span translate>{{!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code }}</span>
      </pnc-checkbox> -->
    </div>
    <div class="factor form-group" *ngSwitchCase="'DATA'">
      <lic-datepicker formControlName="factor" [required]="!!definition.mandatory" [label]="!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code "></lic-datepicker>
    </div>
    <div class="factor form-group" *ngSwitchCase="'DATE'">
      <lic-datepicker formControlName="factor" [required]="!!definition.mandatory" [label]="!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code"></lic-datepicker>
    </div>
    <div class="factor form-group" *ngSwitchCase="'DOUBLE'">
      <label class="label-core" translate>{{!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code }}</label>
      <lic-required class="hidden-sm" *ngIf="mandatoryCheck()" required="true" ></lic-required>
      <input [ngClass]="{'is-invalid': formGroup.get('factor').errors && submitted}" formControlName="factor" class="form-control"
        [attr.data-qa]="'factor-' + definition.code"
        [attr.data-mod]="'factor' + definition.code + definition.readOnly"
        [attr.data-obb]="'factor' + definition.code + true"
        licContractualOptionNumberFormatter>
    </div>
    <div class="factor form-group" *ngSwitchCase="'INT'">
      <label class="label-core" translate>{{'lic_' + definition.code}}</label>
      <lic-required class="hidden-sm" *ngIf="!!definition.mandatory" required="true" ></lic-required>
      <input [ngClass]="{'is-invalid': formGroup.get('factor').errors && submitted}" formControlName="factor" class="form-control"
        [attr.data-qa]="'factor-' + definition.code"
        [attr.data-mod]="'factor' + definition.code + definition.readOnly"
        [attr.data-obb]="'factor' + definition.code + true"
        licContractualOptionNumberFormatter>
    </div>
    <div class="factor form-group" *ngSwitchCase="'STRING'">
      <label class="label-core" translate>{{!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code }}</label>
      <lic-required *ngIf="!!definition.mandatory" required="true"></lic-required>
      <input [ngClass]="{'is-invalid': formGroup.get('factor').invalid && submitted }" formControlName="factor" class="form-control" [attr.data-qa]="'factor-' + definition.code"
        [attr.data-mod]="'factor' + definition.code + definition.readOnly"
        [attr.data-obb]="'factor' + definition.code + true">
    </div>
    <div class="factor form-group" *ngSwitchCase="'BENEFICIARY'">
      <label class="label-core" translate>{{!!definition.description ? definition.description : 'lic_Beneficiary'}}</label>
      <lic-required *ngIf="!!definition.mandatory" required="true" ></lic-required>
      <input formControlName="factor" class="form-control" [attr.data-qa]="'factor-' + definition.code"
        [attr.data-mod]="'factor' + definition.code + definition.readOnly"
        [attr.data-obb]="'factor' + definition.code + true">
    </div>
    <div class="form-group" *ngSwitchCase="'IBAN'">
        <label class="label-core" translate>{{!!definition.description ? definition.description : 'lic__CIBAN'}}</label>
        <lic-required *ngIf="!!definition.mandatory" required="true"></lic-required>
        <input formControlName="factor" class="form-control" [attr.data-qa]="'factor-' + definition.code"
          [attr.data-mod]="'factor' + definition.code + definition.readOnly"
          [attr.data-obb]="'factor' + definition.code + true">
      </div>

      <div class="form-group" *ngSwitchCase="'PARTY'">
        <label class="label-core" translate>{{!!definition.description ? definition.description : !!definition.label ? definition.label : 'lic_' + definition.code }}</label>
        <lic-required *ngIf="!!definition.mandatory" required="true"></lic-required>
        <lic-select-subject [val]="formGroup.get('subject').get('val').value" [submitted]="submitted" [wholeColumn]="true"
        [parentForm]="formGroup.get('subject')" [role]="'300011'" (eventPropagation)="openAnag($event)" (delete)="resetMethod($event)">
        </lic-select-subject>
      </div>
  </div>
</form>

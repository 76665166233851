import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddressesService, ApiEnumType, CardComponentService, ElemEnum, Party } from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../../../claim-opening.service';
import { UtilityService } from '../../../../../external-portfolio/utility.service';
import { ClaimOpeningDetailsService } from '../../claim-opening-details.service';

import { formatDate } from '@angular/common';
import { ComboEntity } from '../../../../../new-payment/domain/combo-entity';
import { RgiRxTranslationService } from '@rgi/rx/i18n';



@Component({
  selector: 'claims-vehicle-asset-detail',
  templateUrl: './vehicle-asset-detail.component.html',
  styleUrls: ['./vehicle-asset-detail.component.scss']
})
export class VehicleAssetDetailComponent implements OnInit {
  @Input() party: Party;
  @Input() addAssetForm: UntypedFormGroup;
  @Input() card: any;
  @Output() validationEventEmitter = new EventEmitter<boolean>();
  licensePlateTypeList: any[] = [];
  countryList: any[] = [];
  cities: any[] = [];
  vehicleTypeList: any[] = [];
  patternPlateList: Map<string, Array<string>>;
  errorMessages: string[] = [];
  TIPO_DEFINITIVA_NUOVA     = '1';
  TIPO_DEFINITIVA_VECCHIA   = '2';
  TIPO_TARGA_PROVA          = '3';
  TIPO_TARGA_ESTERA         = '4';
  TIPO_TARGA_PROVVISIORIA   = '5';
  TIPO_TARGA_SPECIALE       = '6';
  TIPO_TELAIO               = '7';
  TIPO_TARGA_FILOBUS        = '8';

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject('enumService')  private enumService: any,
    @Inject('eventService') private eventService: any,
    @Inject('authService') private authService,
    public addressService: AddressesService,
    private utilityService: UtilityService,
    private claimOpeningService: ClaimOpeningService,
    private claimOpeningDetService: ClaimOpeningDetailsService,
    private rxTranslationsService: RgiRxTranslationService) { }

  ngOnInit() {
    if (!this.addAssetForm.controls.vehicleType || this.addAssetForm.controls.vehicleType.value == null) {
      this.addAssetForm.addControl('vehicleType',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('vehicleTypePP',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('vehicleTypeDescr',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('licensePlateType',
      new UntypedFormControl({value: '' }, Validators.required));
      this.addAssetForm.addControl('licensePlateTypeDescr',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('licensePlate',
      new UntypedFormControl(Validators.nullValidator));
      this.addAssetForm.addControl('chassisNumber',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('brand',
      new UntypedFormControl({value: '' }, Validators.nullValidator));
      this.addAssetForm.addControl('model',
      new UntypedFormControl(Validators.nullValidator));
      this.addAssetForm.addControl('selectedCountry',
      new UntypedFormControl(Validators.nullValidator));
      this.addAssetForm.addControl('parked',
      new UntypedFormControl(Validators.nullValidator));
      this.addAssetForm.addControl('regDate',
      new UntypedFormControl(Validators.nullValidator));

      this.addAssetForm.controls.licensePlateType.setValue(null);
      this.addAssetForm.controls.licensePlateTypeDescr.setValue(null);
      this.addAssetForm.controls.vehicleType.setValue(null);
      this.addAssetForm.controls.vehicleTypePP.setValue(null);
      this.addAssetForm.controls.vehicleTypeDescr.setValue(null);
      this.addAssetForm.controls.licensePlate.setValue(null);
      this.addAssetForm.controls.chassisNumber.setValue(null);
      this.addAssetForm.controls.brand.setValue(null);
      this.addAssetForm.controls.model.setValue(null);
      this.addAssetForm.controls.selectedCountry.setValue('');
      this.addAssetForm.controls.regDate.setValue(null);
      this.addAssetForm.controls.parked.setValue(false);
    }

    if (!this.countryList || this.countryList.length === 0) {
     this.retrieveCountries('IT');
    }
    if (!this.cities || this.cities.length === 0) {
      this.retrieveCities();
    }

    if (!this.licensePlateTypeList || this.licensePlateTypeList.length === 0) {
      this.ETIPOTARGA_ALL();
    }

    if (!this.vehicleTypeList || this.vehicleTypeList.length === 0) {
      this.claimOpeningDetService.getListConfigurationVehicle().subscribe((response: any) => {
        this.vehicleTypeList = response.typeList;
        this.patternPlateList = response.patternPlateList;
      });
    }
    this.errorMessages = new Array();
  }

  ibd(event: any) {
    // const opponentVehicleData = {
    //   assetCode: '',
    //   caiPosition: '',
    //   chassisNumber: this.addAssetForm.controls.chassisNumber.value,
    //   vehiclePlateNumber: this.addAssetForm.controls.licensePlate.value,
    //   vehiclePlateType: this.addAssetForm.controls.licensePlateType.value
    // };
    // const serviceInput = {
    //   getOpponentDataInput: {
    //       chcCode: this.authService.getLiquidationCentreCode(),
    //       claim: undefined,
    //       companyCode: this.authService.getOperator().visibleCompanies[0].aniaCode,
    //       nodeCode: this.authService.getLiquidationCentreCode() ? undefined : this.authService.getSalePointCode(),
    //       opponentVehicleData: opponentVehicleData
    //   }
    // };
    const data = this.claimOpeningService.getSessionOccurrenceDate(this.card.id);
    const dateString =  data ? formatDate(data, 'dd-MM-yyyy', this.locale) : null;
    const vehicTypeCode = this.vehicleTypeList.find(el => el.code === this.addAssetForm.controls.vehicleType.value);

    this.eventService.broadcastEvent('start-loader');
    this.claimOpeningDetService.getIBDLight(
      this.addAssetForm.controls.licensePlate.value,
      this.addAssetForm.controls.chassisNumber.value,
      this.addAssetForm.controls.licensePlateType.value,
      vehicTypeCode.idObject,
      dateString).subscribe(
      (result: any) => {
        console.log('out', result);
        this.addAssetForm.controls.brand.setValue(result.brandDescription);
        if (result.firstRegistrationDate) {
         this.addAssetForm.controls.regDate.setValue(new Date(result.firstRegistrationDate));
        }
        this.addAssetForm.controls.model.setValue(result.modelDescription);

        this.eventService.broadcastEvent('stop-loader');
      },
      (error: any) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );
  }


  checkLicensePlate() {
    const plate: string = this.addAssetForm.controls.licensePlate.value ?
                            this.addAssetForm.controls.licensePlate.value.toUpperCase() : null;
    const chassisNumber: string = this.addAssetForm.controls.chassisNumber.value ?
                            this.addAssetForm.controls.chassisNumber.value.toUpperCase() : null;
    const plateType = this.addAssetForm.controls.licensePlateType.value;
    const vehicleType = this.addAssetForm.controls.vehicleType.value;
    this.errorMessages = new Array();
    let err = null;
    let errConfig: any = null;

    if (vehicleType) {
      const vehicTypeCode = this.vehicleTypeList.find(el => el.code === vehicleType);
      this.addAssetForm.controls.vehicleTypePP.setValue(vehicTypeCode.idObject);
      this.addAssetForm.controls.vehicleTypeDescr.setValue(vehicTypeCode.description);
    }

    if (plateType) {
      const temp = this.licensePlateTypeList.find(el => el.code === plateType);
      this.addAssetForm.controls.licensePlateTypeDescr.setValue(temp.description);
    }

    // la targa deve essere alfanumerica
    if (((!vehicleType ||
          !plateType) &&
          plate) ||
          (plate &&
           !this.checkValueInMatrix(plate, 'abcdefghilmnopqrstuvzwxyjk 0123456789.'))) {
       this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._INCORRECT_OR_MISSING_DATA').subscribe(
        res => err = res
      )
    } else if (plate && this.locale.toLowerCase() === 'it') {
      switch (plateType) {
        case this.TIPO_DEFINITIVA_NUOVA:
          errConfig = this.checkNewPlate(vehicleType, plate);
          break;
        case this.TIPO_DEFINITIVA_VECCHIA:
          errConfig = this.checkOldPlate(vehicleType, plate);
          break;
        case this.TIPO_TARGA_PROVVISIORIA:
          errConfig = this.checkTargaProvvisoria(vehicleType, plate);
          break;
        case this.TIPO_TARGA_PROVA:
          errConfig = this.checkTargaProva(vehicleType, plate);
          break;
        case this.TIPO_TARGA_ESTERA:
          errConfig = this.checkForeignPlate(vehicleType, plate);
          break;
        case this.TIPO_TARGA_FILOBUS:
          errConfig = this.checkTargaFilobus(vehicleType, plate);
          break;
        case this.TIPO_TARGA_SPECIALE:
          errConfig = this.checkTargaSpeciale(vehicleType, plate);
          break;
      }
    }

    if (chassisNumber && this.checkValueInMatrix(chassisNumber, 'abcdefghilmnopqrstuvzwxyjk 0123456789.')) {
      errConfig = this.checkTelaio(chassisNumber);
    }
    this.validationEventEmitter.emit(true);

    if (errConfig) {
      this.eventService.broadcastEvent('start-loader');
      this.utilityService.getTranslation(errConfig.code, errConfig.params).subscribe(
        (res: ComboEntity) => {
          this.errorMessages.push(res.description);
          this.validationEventEmitter.emit(false);
          this.eventService.broadcastEvent('stop-loader');
        },
        (error: any) => {this.eventService.broadcastEvent('stop-loader'); });

    } else if (err) {
      this.errorMessages.push(err);
      this.validationEventEmitter.emit(false);
    }
  }

  presentChar(plate: string, matrix: string) {
    for (let index = 0; index < plate.length; index++) {
      const element = plate.toLowerCase().charAt(index);
      if (matrix.toLowerCase().includes(element)) {
            return true;
      }
    }
    return false;
  }


  checkTargaSpeciale(vehicleType: string, plate: string) {
    let findMyPattern = this.patternPlateList[plate];
    let j = plate.length;
    let prefissoTarga = '';
    while (findMyPattern === null && j > 0) {
      prefissoTarga = plate.substring(0, j);
      findMyPattern = this.patternPlateList[prefissoTarga];
      j--;
    }

    if (!findMyPattern) {
      // SI3500
      return {code: 'SI2001', params: null};
      // return 'Il prefisso della targa inserita non corrisponde ad una targa speciale';
    }

    let suffixTarga = plate.substring(prefissoTarga.length, plate.length).toUpperCase(); // .replace(' ','');
    suffixTarga = suffixTarga.replace(/ /g, '');
    let found = false;
    for (const iterator of findMyPattern) {
      let corrisponde = true;
      const myPattern = iterator.replace(/ /g, '');

      if (myPattern.length !== suffixTarga.length) {
        continue;
      }

      for (let i = 0; i < myPattern.length; i++) {
        if (suffixTarga.charAt(i) &&
          (myPattern.charAt(i) === 'N' && !suffixTarga.charAt(i).match(/([0-9])$/)) ||
          (myPattern.charAt(i) === 'A' && !suffixTarga.charAt(i).match(/[A-Z]/)) ||
          (myPattern.charAt(i) === 'X' && !suffixTarga.charAt(i).match(/[0-9A-Z]/))
        ) {
          corrisponde = false;
          break;
        }
      }

      if (corrisponde) {
        found = true;
        break;
      }
    }
    if (!found) {
      // SI3499
      return {code: 'SI3499', params: prefissoTarga + ';' + findMyPattern};
      // return 'La targa inserita con prefisso ' + prefissoTarga + ' deve essere nel formato ' + findMyPattern;
    }
    return null;
  }

  checkTargaFilobus(vehicleType: string, plate: string) {
    const map = new Map();

    map.set('AN', 'CONEROBUS');
    map.set('BO', 'TPER');
    map.set('CA', 'CTM');
    map.set('CH', 'ALP');
    map.set('GE', 'AMT');
    map.set('LE', 'SGM');
    map.set('MI', 'ATM');
    map.set('MO', 'SETA');
    map.set('PR', 'TEP');
    map.set('NA', 'ANM');
    map.set('RM', 'ATAC');
    map.set('RN', 'START');
    map.set('SP', 'ATC');
    map.set('IM', 'RIVTRASP');

    if (plate.length > 16 ||
       plate.length <= 3) {
        // SI2001
        return {code: 'SI2001', params: null};
        // return 'Targa filobus non corretta: 2 lettere + 4 numeri + sigla azienda trasporti';
    }

    const plateNum = plate.substring(2, 6);

    if (!plateNum.match(/^(\d{4})$/)) {
      // SI2001
      return {code: 'SI2001', params: null};
      // return 'Targa filobus non corretta: 2 lettere + 4 numeri + sigla azienda trasporti';
    }

    const provinciaTarga = plate.substring(0, 2).toUpperCase();
    const cSigla = map.get(provinciaTarga);

    if (!cSigla) {
      // SI1971
      return {code: 'SI1971', params: null};
      // return 'La targa immessa fa riferimento ad una provincia inesistente';
    }

    if (plate.includes(cSigla)) {
      const res = plate.split(cSigla);
      if (res.length === 2 && res[1] === '') {
        return null;
      }
    }

    // SI8321
    return {code: 'SI8321', params: null};
    // return 'Sigla Trasporti inesistente';

  }

  checkForeignPlate(vehicleType: string, plate: string) {
      /*
			 * Il numero targa deve essere composto da un minimo di quattro e un massimo
			 * di otto caratteri alfanumerici.
			 */
      if (plate.length < 4 || plate.length > 8) {
        // SI1967
        return {code: 'SI1967', params: null};
        // return 'La targa deve essere composta da un minimo di quattro ed un massimo di otto caratteri alfanumerici';
      }
      /*
			 * La targa non può essere composta da cifre tutte uguali se il numero di
			 * caratteri è superiore a 4.
			 */
      if (plate.split(plate.charAt(0)).join('') === '') {
        // SI1968
        return {code: 'SI1968', params: null};
        // return 'La targa non puo\' essere composta da cifre tutte uguali';
      }

      plate = plate.padStart(8, ' ');

      /* La targa non può essere composta interamente da caratteri alfabetici. */
      if (plate.match(/^([A-Z ]{8})$/)) {
        // SI1969
        return {code: 'SI1969', params: null};
        // return 'La targa non puo\' essere composta interamente da caratteri alfabetici';
      }

      /* La targa non può essere composta da solo caratteri 0. */
      if (plate.match(/^([0 ]{8})$/)) {
       // SI1970
       return {code: 'SI1970', params: null};
      //  return 'La targa non puo\' essere composta da solo caratteri 0';
      }

      return null;
  }

  checkTelaio(chassisNumber: string) {
    if (chassisNumber.length < 4 || chassisNumber.length > 20) {
      // SI9994
      return {code: 'SI9994', params: null};
      // return 'Numero telaio non corretto: inserire tra gli 4 e i 20 caratteri alfanumerici';
    }

    if (chassisNumber.match(/^[a-zA-Z]+$/) ) {
      // SI1989
      return {code: 'SI1989', params: null};
      // return 'Il telaio non puo\' essere composto da soli caratteri alfabetici';
    }

    if (chassisNumber.match(/[Tt][Ee][Ll][Aa][Ii][Oo]/) ||
        chassisNumber.match(/[Tt][Ee][Ll]/)) {
      // SI1990
      return {code: 'SI1990', params: null};
      // return 'Non deve contenere prefissi come \'Tel.\', \'Telaio\' e simili';
    }

    if (chassisNumber.split(chassisNumber.charAt(0)).join('') === '') {
      // SI1991
      return {code: 'SI1991', params: null};
      // return 'Il telaio non puo\' essere composto da caratteri uguali';
    }
    return null;
  }

  checkTargaProva(vehicleType: string, plate: string) {

    if (!plate.match(/^([A-Z0-9]{2})(P)([A-Z0-9]{5})$/) ) {
      // SI9980
      return {code: 'SI9980', params: null};
      // return 'Targa prova non corretta: 2 caratteri alfanumerici + P + 5 caratteri alfanumerici';
    }

    // La targa non deve contenere  le lettere I, O, Q, U
    if (this.presentChar(plate, 'ioqu')) {
      // SI2000
      return {code: 'SI2000', params: null};
      // return 'La targa prova non puo\' contenere le lettere I, O, Q, U';
    }

    return null;
  }

  checkTargaProvvisoria(vehicleType: string, plate: string) {

    if (!plate.match(/^([A-Z]{2})(\d+)$/) || plate.length > 8) {
      // SI9999
      return {code: 'SI9999', params: null};
      // return 'Targa provvisoria non corretta: 2 lettere + massimo 6 numeri';
    }

    if (this.cities.filter(el => el.value === plate.substring(0, 2)).length === 0) {
      // SI1971
      return {code: 'SI1971', params: null};
      // return 'La targa immessa fa riferimento ad una provincia inesistente';
    }

    return null;
  }

  checkOldPlate(vehicleType: string, plate: string) {

    if (!plate.match(/^([A-Z]{2})([A-Z0-9]{4,6})$/)) {
      if (plate.toLowerCase().startsWith('roma')) {

        if (!plate.match(/^([A-Z]{4})([A-Z0-9]{4,6})$/)) {
          // SI9993
          return {code: 'SI9993', params: null};
          // return 'Targa vecchia non corretta: ROMA + da 4 a 6 caratteri alfanumerici';
        }
      } else {
        if (plate.toLowerCase().startsWith('rm')) {
          // SI9993
          return {code: 'SI9984', params: null};
          return 'Targa vecchia non corretta: ROMA + da 4 a 6 caratteri alfanumerici';
        } else {
          // SI9992
          return {code: 'SI9992', params: null};
          // return 'Targa vecchia non corretta: 2 lettere + da 4 a 6 caratteri alfanumerici';
        }
      }
    }
    if (!plate.toLowerCase().startsWith('roma')) {
        if (this.cities.filter(el => el.value === plate.substring(0, 2)).length === 0) {
          // SI1971
          return {code: 'SI1971', params: null};
          // return 'La targa immessa fa riferimento ad una provincia inesistente';
        }
    }

    let radixPlate = '';

    if (plate.toLowerCase().startsWith('roma')) {
        radixPlate = plate.substring(4);
    } else {
        radixPlate = plate.substring(2);
    }

    let countAlfa = 0;
    for ( let i = 0; i < radixPlate.length; i++) {
        if (radixPlate.charAt(i).match(/^([A-Z]{1})$/)) {
          countAlfa++;
        }
    }

    if (vehicleType === 'T' || vehicleType === 'S') {
        /*
			 * Il numero di targa può contenere un solo carattere alfabetico. Per le
			 * tipologie di veicolo S (macchina agricola) e T (macchina operatrice) i sei
			 * caratteri che compongono il numero di targa possono comprendere fino a due
			 * caratteri alfabetici.
			 */
      if (countAlfa > 2) {
        // SI1961
        return {code: 'SI1961', params: null};
        // return 'Permesse due sole lettere nella targa';
      }
    } else if (countAlfa > 1) {
      // SI1962
      return {code: 'SI1962', params: null};
      // return 'Permessa una sola lettera nella targa';
    } else if (vehicleType === 'A'
              || vehicleType === 'B'
              || vehicleType === 'M'
              || vehicleType === 'Q'
              || vehicleType === 'R'
              || vehicleType === 'C') {
            /*
            IR_CODICE_AUTOVETTURA_ANIA    A
            IR_CODICE_AUTOBUS_ANIA        B
            IR_CODICE_AUTOCARRO_ANIA      C
            IR_CODICE_MOTOCICLO_ANIA      M
            IR_CODICE_MOTOCARRI_ANIA      Q
            IR_CODICE_RIMORCHIO_ANIA      R
            */
      /*
			 * Se il numero è composto da meno di sei caratteri,
       *  per ogni carattere mancante aggiungere uno zero a sinistra.
			 */
        radixPlate = radixPlate.padStart(6, '0');


        /*
			 * Controllo se il veicolo appartiene alle tipologie A (autovettura), B
			 * (autobus), C (autocarro), M (motociclo), Q (motocarri), R (rimorchio).
			 * Per queste tipologie dovranno essere effettuati i seguenti controlli. Il
			 * primo carattere è numerico o alfabetico, ma non può contenere le lettere
			 * C, I, J, O, Q. Il secondo ed il terzo carattere sono numerici o
			 * alfabetici, ma non possono contenere le lettere B, C, I, J, O, Q. Il
			 * quarto ed il quinto carattere devono essere numerici. Il sesto carattere
			 * è numerico o alfabetico, ma non può contenere le lettere B, C, I, J, K,
			 * O, Q, U.
			 */

        if (radixPlate.charAt(0).match(/^(C|I|J|O|Q)$/)) {
          // SI1963
          return {code: 'SI1963', params: '1'};
          // return 'Il carattere del codice targa in posizione 1 deve essere numerico o alfabetico, ' +
          // 'ma non può contenere le lettere C, I, J, O, Q';
        } else if (radixPlate.charAt(1).match(/^(B|C|I|J|O|Q)$/)) {
          // SI1964
          return {code: 'SI1964', params: '2'};
          // return 'Il carattere del codice targa in posizione 2 deve essere numerico o alfabetico, ' +
          // 'ma non può contenere le lettere B, C, I, J, O, Q';
        } else if (radixPlate.charAt(2).match(/^(B|C|I|J|O|Q)$/)) {
          // SI1964
          return {code: 'SI1964', params: '3'};
          // return 'Il carattere del codice targa in posizione 3 deve essere numerico o alfabetico, ' +
          // 'ma non può contenere le lettere B, C, I, J, O, Q';
        } else if (!radixPlate.charAt(3).match(/^([0-9]{1})$/)) {
          // SI1965
          return {code: 'SI1965', params: '4'};
          // return 'Il carattere del codice targa in posizione 4 deve essere numerico';
        } else if (!radixPlate.charAt(4).match(/^([0-9]{1})$/)) {
          // SI1965
          return {code: 'SI1965', params: '5'};
          // return 'Il carattere del codice targa in posizione 5 deve essere numerico';
        } else if (radixPlate.charAt(5).match(/^(B|C|I|J|K|O|Q|U)$/)) {
          // SI1966
          return {code: 'SI1966', params: '6'};
          // return 'Il carattere del codice targa in posizione 6 deve essere numerico o alfabetico, ' +
          // 'ma non può contenere le lettere B, C, I, J, K, O, Q, U';
        }
      }

    return null;
  }

  checkNewPlate(vehicleType: string, plate: string) {
    if (vehicleType === 'A'
    || vehicleType === 'B'
    || vehicleType === 'N'
    || vehicleType === 'C') {
          // autoveicoli: tutti quelli che non rientrano nelle categorie rimanenti

          if (!plate.match(/^([A-Z]{2})(\d{3})([A-Z]{2})$/)) {
            // SI9984
            return {code: 'SI9984', params: null};
            // return 'Targa nuova non corretta: 2 lettere + 3 numeri + 2 lettere';
          }

          /* Il primo carattere della targa non può contenere la lettera "X" */
          if (plate.match(/^(X)([A-Z]{1})(\d{3})([A-Z]{2})$/)) {
            // SI1958
            return {code: 'SI1958', params: null};
            // return 'Il primo carattere della targa non puo\' essere X';
          }
    } else if (vehicleType === 'M' || vehicleType === 'Q') {

          if (!plate.match(/^([A-Z]{2})(\d{5})$/)) {
            // SI9986
            return {code: 'SI9986', params: null};
            // return 'Targa nuova non corretta: 2 lettere + 5 numeri';
          }

          /*  targa AANNNNN */
          const AANNNNN = plate.match(/^([A-Z]{2})(\d{5})$/);
          if (AANNNNN) {
            if (plate.substring(0, 2) !== 'BT' && this.cities.filter(el => el.value === plate.substring(0, 2)).length === 0) {
              // SI1999
              return {code: 'SI1999', params: null};
              // return 'Per le tipologie veicolo M, Q ed R con formato targa AANNNNN, i primo due caratteri alfabetici non possono ' +
              // 'coincidere con una sigla provinciale, fatta eccezione per la sigla BT';
            }
          }
    } else if (vehicleType === 'R') {
        const AANNNAA = plate.match(/^([A-Z]{2})(\d{3})([A-Z]{2})$/);
        if (AANNNAA && !plate.toLowerCase().startsWith('x', 0)) {
          // SI1958
          return {code: 'SI1958', params: null};
          // return 'Targa nuova non corretta: X + 1 lettera + 3 numeri + 2 lettere oppure 2 lettere + 5 numeri oppure 2 lettere + ' +
          //  '3 numeri + 1 lettera';
        }
        const AANNNNN = plate.match(/^([A-Z]{2})(\d{5})$/);

        if (AANNNNN) {
          if (plate.substring(0, 2) !== 'BT' && this.cities.filter(el => el.value === plate.substring(0, 2)).length === 0) {
            // SI1999
            return {code: 'SI1999', params: null};
            // return 'Per le tipologie veicolo M, Q ed R con formato targa AANNNNN, ' +
            // 'i primo due caratteri alfabetici non possono coincidere con una sigla provinciale, fatta eccezione per la sigla BT';
          }
        }

        if (!plate.match(/^(X)([A-Z]{1})(\d{3})([A-Z]{2})$/) &&
            !plate.match(/^([A-Z]{2})(\d{5})$/) &&
            !plate.match(/^([A-Z]{2})(\d{3})([A-Z]{1})$/)) {
          // SI1959
          return {code: 'SI1959', params: null};
          // return 'Targa nuova non corretta: X + 1 lettera + 3 numeri + 2 lettere oppure 2 lettere ' +
          //        '5 numeri oppure 2 lettere + 3 numeri + 1 lettera';
        }
    } else if (vehicleType === 'T') {

        if (!plate.match(/^([A-Z]{3})(\d{3})$/)) {
          // SI9990
          return {code: 'SI9990', params: null};
          // return 'Targa nuova non corretta: 3 lettere + 3 numeri';
        }
    } else if (vehicleType === 'W') {

        if (!plate.match(/^([A-Z0-9]{6})$/)) {
          // SI9988
          return {code: 'SI9988', params: null};
          // return 'Targa nuova non corretta: 6 caratteri alfanumerici';
        }

        if (!plate.match(/^(X|Y)([A-Z0-9]{5})$/)) {
          // SI1960
          return {code: 'SI1960', params: null};
          // return 'Il primo carattere della targa deve essere X oppure Y';
        }
        if (this.presentChar(plate, 'aeiouq01')) {
          // SI1998
          return {code: 'SI1998', params: null};
          // return 'Per il tipo veicolo W, non sono ammesse le lettere A, E, I, O, Q, U e le cifre 0 e 1';
        }
    } else if (vehicleType === 'X') {

        if (!plate.match(/^([A-Z0-9]{7})$/)) {
          // SI9991
          return {code: 'SI9991', params: null};
          // return 'Targa nuova non corretta: 7 caratteri alfanumerici';
        }
    } else if (vehicleType === 'S') {

        if (!plate.match(/^([A-Z]{2})(\d{3})([A-Z]{1})$/)) {
          // SI9985
          return {code: 'SI9985', params: null};
          // return 'Targa nuova non corretta: 2 lettere + 3 numeri + 1 lettera';
        }
    }
    return null;
  }

  checkValueInMatrix(plate: string, matrix: string) {
     for (let index = 0; index < plate.length; index++) {
       const element = plate.toLowerCase().charAt(index);
       if (!matrix.toLowerCase().includes(element)) {
         return false;
       }
     }
     return true;
  }

  chargePlateType() {
    this.errorMessages = new Array();
    this.ETIPOTARGA_ALL();

    if (this.addAssetForm.controls.vehicleType.value === 'S'  ||
       this.addAssetForm.controls.vehicleType.value === 'T') {
        // {EntSinbcveicolo.ENUM_ETIPOTARGA_DEFINITIVA_NUOVA, 1
        //   EntSinbcveicolo.ENUM_ETIPOTARGA_DEFINITIVA_VECCHIA, 2
        //   EntSinbcveicolo.ENUM_ETIPOTARGA_TARGA_PROVA, 3
        //   EntSinbcveicolo.ENUM_ETIPOTARGA_TARGA_ESTERA, 4
        //   EntSinbcveicolo.ENUM_ETIPOTARGA_TARGA_SPECIALE, 6
        //   EntSinbcveicolo.ENUM_ETIPOTARGA_TELAIO}; 7

        this.licensePlateTypeList = this.licensePlateTypeList.filter(el => el.code === '1' ||
        el.code === '2' ||
        el.code === '3' ||
        el.code === '4' ||
        el.code === '6' ||
        el.code === '7' );
    }
    if (this.addAssetForm.controls.licensePlate.value) {
     this.checkLicensePlate();
    }
  }

  protected retrieveCities() {
    this.addressService.getCities().subscribe(
      (response: any[]) => {
        for (const element of response) {
          const city = {value: element.siglaprovincia };
          if (!this.cities.find(el => el.value === element.siglaprovincia)) {
            this.cities.push(city);
          }
        }
      });
  }

  public ETIPOTARGA_ALL() {
    const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoTarga');
    this.licensePlateTypeList = new Array();

    enumEl.forEach(element => {
       const aRes = {
        code: element.codice,
        description: element.descrizione
       };
       this.licensePlateTypeList.push(aRes);
    });

}

  protected retrieveCountries(selectedCountry?: string) {

    this.addressService.getCountries(new Date()).subscribe(
      (response: ElemEnum[]) => {
        for (const element of response) {
          this.countryList.push({ id: element.codice, value: element.descrizione });
          if (!this.addAssetForm.controls.selectedCountry.value && (selectedCountry === element.codice)) {
            this.addAssetForm.controls.selectedCountry.setValue(element.codice);
          }
        }
      });
  }


}

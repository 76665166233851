import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

let rgiRxLabelCounter = 0;
@Directive({
  selector: 'label[rgiRxLabel]',
  exportAs: 'rgiRxLabel',
  host: {
    class: 'rgi-ui-form-control-label',
  }
})
export class RgiRxLabelDirective {
  #id: string;
  constructor(public elementRef: ElementRef<HTMLLabelElement>) {
    this.#id = `rgi-rx-label-${++rgiRxLabelCounter}`;
  }
  private _for;

  @Input() @HostBinding('attr.for') get for() {
    return this._for;
  }
  set for(value) {
    this._for = value;
  }


  @HostBinding('attr.id')
  get id(): string {
    return this.#id;
  }
}

import {RgiRxi18nModuleLoadType, RgiRxTranslations, Serialized18n} from "@rgi/rx/i18n";

export const IT: Serialized18n = {
  NG_PASSPROPRO_SURVEY: {
    "download": "Scarica",
    "view": "Visualizza",
    "evaluate": "VALUTA",
    "showresults": "MOSTRA RISULTATI",
    "showresult": "MOSTA RISULTATO",
    "print": "STAMPA",
    "name-surname": "Nome e Cognome / Denominazione",
    "required": "obbligatorio",
    "cf-piva": "CF / P.IVA",
    "type": "Tipo",
    "code": "Codice",
    "creation-date": "Data creazione",
    "validity-end-date": "Data fine validità",
    "questionnaire": "Questionario",
    "productresult": "RISULTATO PRODOTTI",
    "currency": "€",
    "details": "Dettagli",
    "targetmarket": "Target Market",
    "nottargetmarket": "Non Target market",
    "pricing": "Prezzi",
    "messages": "Messaggi",
    "information": "Informazioni",
    "low": "basso",
    "medium": "medio",
    "high": "alto",
    "formulas": "Formule",
    "variables": "Variabili",
    "packages": "Pacchetti",
    "form-survey-edit_title": "Dati generali",
    "survey-edit_title":"Check-Up Avanzato",
    "survey-evaluate_title": "Check-Up Avanzato",
    "survey-evaluate_pricestartingfrom": "da",
    "form-validation-error": "Inserire i campi obbligatori",
    "notes-title": "Note",
    "eval_modify": "PERSONALIZZA",
    "eval_save": "SALVA",
    "eval_continue": "CONTINUA",
    "package_coverages_title": "Il pacchetto include:",
    "total-price": "Premio da",
    "group-lob": "Linea di business",
    "group-product": "Prodotto",
    "eval-no-products": "Sulla base delle risposte fornite nessuna soluzione può essere proposta.",
    "product-deselect-all": "Deseleziona tutto",
    "product-select-all": "Seleziona tutto",

    "validation-products-title": "I seguenti prodotti non sono validi:",
    "validation-product-invalid-covs": "Ci sono coperture non valide",
    "validation-product-invalid-pkgs": "Ci sono pacchetti non validi",
    "validation-product-title": "Le selezioni non sono valide per ",
    "validation-notifications-title": "Validazioni",
    "product-not-selected": "Nessun prodotto selezionato, selezionare almeno un prodotto per continuare"
  }

}

export const EN: Serialized18n = {
  NG_PASSPROPRO_SURVEY: {
    "download": "Download",
    "view": "View",
    "evaluate": "EVALUATE",
    "showresults": "SHOW RESULTS",
    "showresult": "SHOW RESULT",
    "print": "PRINT",
    "name-surname": "Name and Surname / Denomination",
    "required": "compulsory",
    "cf-piva": "CF / P.IVA",
    "type": "Type",
    "code": "Code",
    "creation-date": "Creation date",
    "validity-end-date": "Validity end date",
    "questionnaire": "Questionnaire",
    "productresult": "Product result",
    "currency": "€",
    "details": "Details",
    "targetmarket": "Target Market",
    "nottargetmarket": "Non Target market",
    "pricing": "Prices",
    "messages": "Messages",
    "information": "Information",
    "low": "low",
    "medium": "medium",
    "high": "high",
    "formulas": "Formula",
    "variables": "Variables",
    "packages": "Packages",
    "form-survey-edit_title": "General data",
    "survey-edit_title":"Advanced Check-Up",
    "survey-evaluate_title": "Advanced checkup",
    "survey-evaluate_pricestartingfrom": "from",
    "form-validation-error": "Fill mandatory fields",
    "notes-title": "Note",
    "eval_modify": "PERSONALIZE",
    "eval_save": "SAVE",
    "eval_continue": "CONTINUE",
    "package_coverages_title": "Package includes:",
    "total-price": "Total price:",
    "group-lob": "Line of business",
    "group-product": "Product",
    "eval-no-products": "No products found",
    "product-deselect-all": "Deselect all",
    "product-select-all": "Select all",

    "validation-products-title": "The following products are not valid:",
    "validation-product-invalid-covs": "There are invalid coverages",
    "validation-product-invalid-pkgs": "There are invalid packages",
    "validation-product-title": "Selections are not valid for",
    "validation-notifications-title": "Validations",
    "product-not-selected": "No product selected, select at least one product to continue"
  }
}

export function loadNgPassProProSurveyIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    return resolve(IT)
  })
}

export function loadNgPassProProSurveyEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    return resolve(EN)
  })
}



export const NG_PASSPROPRO_SURVEY_TRANSLATIONS: RgiRxTranslations = [

  {
    load:loadNgPassProProSurveyIT,
    locale:"it"
  },
  {
    load:loadNgPassProProSurveyEN,
    locale: "en"
  }
]

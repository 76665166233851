import { Component, OnInit, Optional, Inject, EventEmitter } from '@angular/core';

import { DIALOG_DATA } from '@rgi/rx/ui';
import { AnagApiParty } from '../../../anag-model/anag-domain/anag-api-party';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TYPE_COUNTER_TERRORISM} from '../../../anag-constants/enums-constants';
import {AnagStorageService} from '../../../anag-resources/anag-storage.service';
import { AnagStateManagerPartyEditor } from '../../../anag-states/party-editor/anag-state-manager-party-editor';
import { AnagConfigService } from '../../../anag-resources/anag-config.service';
import { AnagFormFieldConfig } from '../../../anag-model/anag-domain/anag-form-field-config';

@Component({
  selector: 'rgi-anag-counter-terrorism',
  templateUrl: './counter-terrorism.component.html',
  host: {
    class: 'rgi-anag-style'
  }

})
export class CounterTerrorismComponent implements OnInit {

  inputParty: AnagApiParty;
  detailComboTerrorismList = this.anagStorage.getEnumsByCode(TYPE_COUNTER_TERRORISM);
  terrorismCode = new UntypedFormControl('', Validators.required);
  modalClose: EventEmitter<any> = new EventEmitter();
  originalFormValues: UntypedFormGroup;
  isFormDirty = false;
  /*formTerrorism: UntypedFormGroup = new UntypedFormGroup({
    terrorismCode: this.terrorismCode,
  });*/
  formTerrorism: UntypedFormGroup = new UntypedFormGroup({});
  terrorismFormMap = new Map<string, AnagFormFieldConfig>([
    ['terrorismCode', new AnagFormFieldConfig('antiTerrorismStatus', this.formTerrorism, [])]
  ]);

  constructor(
    public anagStorage: AnagStorageService,
    private formBuilder: UntypedFormBuilder,
    public stateMgr: AnagStateManagerPartyEditor,
    public configService: AnagConfigService,
    @Optional() @Inject(DIALOG_DATA) party: AnagApiParty,
  ) {
    this.inputParty = party;
  }

  ngOnInit() {
    this.configService.setConfigToForm(this.terrorismFormMap, this.stateMgr.getCurrentState().configuration);
    if (this.inputParty) {
      this.adaptInputTerrorismToForm();
    }
  }

  adaptInputTerrorismToForm() {
    if (this.inputParty.counterTerrorismStatus) {
      this.formTerrorism.get('terrorismCode').setValue(this.inputParty.counterTerrorismStatus.codice);
    }
  }

  confirmAction() {
    const comboSelected = this.detailComboTerrorismList.find(res => res.codice === this.formTerrorism.get('terrorismCode').value);
    this.modalClose.emit(comboSelected);
  }
  actionClose() {
    this.modalClose.emit(undefined);
  }

  isFieldVisible(field: string) {
    return this.formTerrorism.get(field);
  }
}

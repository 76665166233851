import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DigitalClaimsCommonAngularModule,
  CardService,
  enumServiceProvider,
  getSystemPropertyProvider
} from '@rgi/digital-claims-common-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutosoftBoardComponent } from './autosoft-board.component';
import { AutosoftCallsComponent } from './autosoft-calls/autosoft-calls.component';
import { AutosoftSearchComponent } from './autosoft-search/autosoft-search.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';


@NgModule({
    declarations: [
        AutosoftBoardComponent,
        AutosoftCallsComponent,
        AutosoftSearchComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        FormsModule,
        ReactiveFormsModule,
        DigitalClaimsCommonAngularModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        [NgbModule]
    ],
    exports: [
        AutosoftBoardComponent
    ],
    providers: [
        { provide: 'autosoftCallsComponent', useValue: AutosoftCallsComponent },
        { provide: 'autosoftSearchComponent', useValue: AutosoftSearchComponent },
        enumServiceProvider,
        getSystemPropertyProvider
    ]
})
export class AutosoftBoardModule {
  constructor(cardService: CardService) {
    const title = '_AUTOSOFT_DASHBOARD';
    const moduleName = 'autosoft-board';
    const communication = {};

    cardService.registerCard(
        moduleName,
        title,
        AutosoftBoardComponent,
        'ClaimsCommunicationCtrl'
    );
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ExternalPortfolioComponent } from './external-portfolio.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExternalPortfolioListComponent } from './components/external-portfolio-list/external-portfolio-list.component';
import { ExternalPortfolioDetailsComponent } from './components/external-portfolio-details/external-portfolio-details.component';
import { ExternalPortfolioSearchComponent } from './components/external-portfolio-search/external-portfolio-search.component';
import { ExternalPortfolioNewComponent } from './components/external-portfolio-new/external-portfolio-new.component';
import { CommonModule } from '@angular/common';
import { ExternalPortfolioSubjectsComponent } from './components/external-portfolio-subjects/external-portfolio-subjects.component';
import {
    claimsCardServiceProvider,
    sessionServiceProvider,
    authServiceProvider
} from '@rgi/digital-claims-common-angular';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';




@NgModule({
    declarations: [
        ExternalPortfolioComponent,
        ExternalPortfolioListComponent,
        ExternalPortfolioDetailsComponent,
        ExternalPortfolioSearchComponent,
        ExternalPortfolioNewComponent,
        ExternalPortfolioSubjectsComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        NgSelectModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        // MatInputModule,
        // MatDatepickerModule,
        // MatNativeDateModule,
        [NgbModule]
        // ExternalPortfolioModule,
    ],
    exports: [
        ExternalPortfolioComponent,
        ExternalPortfolioListComponent,
        ExternalPortfolioDetailsComponent,
        ExternalPortfolioSearchComponent,
        ExternalPortfolioNewComponent,
        ExternalPortfolioSubjectsComponent,
        FormsModule
    ],
    providers: [
        { provide: 'searchComponentExt', useValue: ExternalPortfolioSearchComponent },
        { provide: 'searchResultComponentExt', useValue: ExternalPortfolioListComponent },
        { provide: 'detailsComponentExt', useValue: ExternalPortfolioDetailsComponent },
        { provide: 'newComponent', useValue: ExternalPortfolioNewComponent },
        claimsCardServiceProvider,
        sessionServiceProvider,
        authServiceProvider
    ]
})


export class ExternalPortfolioModule {
    // constructor(cardService: CardService) {
    //     const title = 'Portafoglio RIASS Attiva';
    //     const moduleName = 'externalPortfolio';
    //     // const moduleNameSub = 'chatSubCard';
    //     // const parentCardName = 'claimsFinder';

    //     cardService.registerCard(
    //       moduleName,
    //       title,
    //       ExternalPortfolioComponent
    //     );

    //     // cardService.registerSubCard(
    //     //   moduleNameSub,
    //     //   title,
    //     //   ChatComponent,
    //     //   parentCardName
    //     // );

    //   }

}

import {Component, Inject, Input, Optional, ViewEncapsulation} from '@angular/core';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';

@Component({
  selector: 'lpc-liquidation',
  templateUrl: './lpc-liquidation.component.html',
  styleUrls: ['./lpc-liquidation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LpcLiquidationComponent {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() formGroup;
  @Input() liquidationDefinition;

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  getSelectedSettlementSummary(): any {
    let liq = null;
    if (this.liquidationDefinition != null) {
      this.liquidationDefinition.forEach(element => {
        if (!liq && element.settlementKey === this.formGroup.get('selectsettlement').value) {
          liq = element;
        }
      });
    }
    return !!liq ? liq.movementDescription + ' - ' + liq.effectiveDate : null;
  }

}

import { Injectable } from '@angular/core';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import { ActiveRoute } from '@rgi/rx/router';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {ModalService} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import { FivaService } from '../fiva-service/fiva-service';
import {CtpDetail, CtpDetailNew, FivaStateModel} from '../fiva-configuration/fiva-model'
import { FivaPlateCtpDetailModalComponent } from '../fiva-plate-ctp-detail-modal/fiva-plate-ctp-detail.modal.component';

@Injectable()
export class FivaStateManager extends AbstractStateManager<FivaStateModel> {
  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    public fivaService: FivaService,
    private modalServiceRgi: ModalService,
    public translateService: RgiRxTranslationService,
    
  ) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<FivaStateModel>(this.storeKey)
            ? stateStoreService.get<FivaStateModel>(this.activeRoute.key)
            : new FivaStateModel(this.storeKey);
    const claim = this.getClaimFromActiveSession();
    console.log(claim);
    if (claim) {
      this.updateState$(this.fivaService.initFivaState$(of(st), claim));
    } else {
      this.updateState$(of(st)); 
    }
    }

  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  isReopenFormJs: BehaviorSubject<boolean> = new BehaviorSubject(false);
  messageWarningExistingPesAlert: string = '';
  session: any;

  getSession(): any {
    return this.activeRoute.getRouteData<any>();
  }

  clearState() {
    const clearedState$ = this.fivaService.clearState$(
      of(this.getCurrentState())
    );
    this.updateState$(clearedState$);
  }


  exit() {
    this.clearState();
    this.closeCardSession();
  }
  closeCardSession() {
    this.fivaService.closeSession$(this.activeRoute.id);
  }
 
  

  saveCtpDetail$(newCtpDetail: CtpDetailNew) {       
    const st = this.getCurrentState();  
    this.updateState$(this.fivaService.saveCtpDetail(of(st), newCtpDetail));
  }

  deleteCtpDetail$(ctpDetail: CtpDetail) {       
    const st = this.getCurrentState();  
    this.updateState$(this.fivaService.deleteCtpDetail(of(st), ctpDetail));
  }

  openModal(): any {
    const  state = this.getCurrentState();
    {
      this.modalServiceRgi.open(FivaPlateCtpDetailModalComponent, {
        contentMessage: this.messageWarningExistingPesAlert,
      }).onClose.subscribe(response => {

      });
    }
  }

  getClaimFromActiveSession(){
    const sessioneAttuale = this.fivaService.getCurrentSession();
    return this.fivaService.getClaimFromSession(sessioneAttuale);
  }

}

import { Component, Inject, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BodyRequestDataSettlement, OutputCloseModal, OutputSetlementResultResponse,
  ReasonSettlement } from './new-settlement-detail.interface';
import { NewPaymentService } from '../new-payment.service';
import { DatePipe } from '@angular/common';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { UtilityService } from '../../external-portfolio/utility.service';
import { Outcome } from '@rgi/digital-claims-common-angular';
import { OutputDetailPaymentMethod } from '../payment-method/payment-method.model';
import { FeeDataEntity } from '../domain/fee-data-entity';
import { PaymentEntity } from '../domain/payment-entity';
import { MethodPaymentEntity } from '../domain/method-payment-entity';
import { combineLatest } from 'rxjs';
import { RgiRxTranslationService } from '@rgi/rx/i18n';



@Component({
  selector: 'claims-new-settlement-detail-modal',
  templateUrl: './new-settlement-detail-modal.component.html',
  styleUrls: ['./new-settlement-detail-modal.component.scss']
})
export class NewSettlementDetailModalComponent implements OnInit, OnModalClose {

  @Input() inputDetailReceipt: any;
  @Input() card: any;
  reasonSettlementList: ReasonSettlement[] = [];
  reasonSettlementSelected: ReasonSettlement;
  settlementForm: UntypedFormGroup;
  currentStep: number = 0;
  saveDisabled: boolean = true;
  afterAccess: boolean = false;
  potPayFe: InitPotentialPaymentEntity;
  detailDataMethodPayment: OutputDetailPaymentMethod;
  idSettlment: string;
  idParty: string;
  feeData: FeeDataEntity;
  claimNumber: string = '';
  idClaim: string = '';
  today = new Date();
  datepickerPlaceholder: string = '';
  isDisabledForm: boolean = false;
  bodyRequestDataSettlement: BodyRequestDataSettlement;
  claimCurrent: any;
  setlementResultResponse: OutputSetlementResultResponse;
  modalClose = new EventEmitter<any>();
  data: any;
  viewPotPay: boolean = false;
  eventService: any;
  MsgError: any = null;
  authSrv: any;
  finPays: PaymentEntity[] = [];
  totalAmount: number = 0;

  constructor(
    private paymentService: NewPaymentService,
    private utilytyService: UtilityService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public translateService: RgiRxTranslationService,
    @Inject('authService') authService,
    @Inject('eventService') eventService: any,
    @Optional() @Inject(DIALOG_DATA) data: any) {
    this.inputDetailReceipt = data.detailObject;
    this.inputDetailReceipt.selectedClaim = data.claimCurrent;
    this.card = data.card;
    this.idSettlment = data.filterSettlment.settlementId;
    this.idParty = data.filterSettlment.partyId;
    this.potPayFe = data.potPayFe;
    this.feeData = data.feeData;
    this.claimCurrent = data.claimCurrent;
    this.authSrv = authService;
    this.eventService = eventService;
    this.finPays = data.finPays;
  }

  ngOnInit() {
    this.viewPotPay = this.potPayFe ? true : false;
    this.reasonSettlementSelected = {
      code: '',
      description: ''
    };
    this.reasonSettlementList.push(this.reasonSettlementSelected);
    this.calculateTotalAmount();
    this.initForm();
    this.populateForm();
  }

  populateForm() {
    // tslint:disable-next-line:max-line-length
    this.claimNumber = this.inputDetailReceipt.selectedClaim ? this.inputDetailReceipt.selectedClaim.claimNumber ? this.inputDetailReceipt.selectedClaim.claimNumber : '' : '';
    // tslint:disable-next-line:max-line-length
    this.idClaim = this.inputDetailReceipt.selectedClaim ? this.inputDetailReceipt.selectedClaim.idClaim ? this.inputDetailReceipt.selectedClaim.idClaim : '' : '';
    this.bodyRequestDataSettlement = {
      claimNumber: this.claimNumber,
      idSettlement: this.idSettlment,
      idParty: this.idParty,
      occurrenceDate: this.dataPagamentoQuietanza(),
      settlementNumber: this.potPayFe.paymentList[0].id
    };
  }

  dataPagamentoQuietanza() {
    return this.formatDateTimezone(this.settlementForm.value.date);
  }

  initForm() {
    this.detailDataMethodPayment = new OutputDetailPaymentMethod();
    this.detailDataMethodPayment.paymentData = this.potPayFe.paymentList[0].methodPayment;
    this.settlementForm = this.formBuilder.group({
      note: ['', Validators.required],
      // combo: ['', Validators.required],
      date: [new Date(), Validators.required],
    });
  }

  closeModal(outputClose: OutputCloseModal) {
    this.modalClose.emit(outputClose);
  }

  onCloseModal() {
    const onClose: OutputCloseModal = {
      stateModal: 'Close',
      settlementNumber: this.potPayFe.paymentList[0].id,
      code: '',
      messages: null,
      methodPayment: this.detailDataMethodPayment.paymentData,
      notePayment: this.settlementForm.get('note').value
    };
    this.closeModal(onClose);
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    const dataOutput = this.datePipe.transform(miaData, 'dd-MM-yyyy');
    return dataOutput;
  }

  progress(nestStep: number) {
    // TODO: step logic here
    if (nestStep > this.currentStep) {
      this.currentStep = nestStep;
    }
  }

  saveAvailable(saveAvailable) {
    this.saveDisabled = !saveAvailable;
  }

  createDataSettlement() {
    // this.potPayFe = this.paymentService.getPotPayCache();
    if (!this.potPayFe) {
      this.potPayFe = new InitPotentialPaymentEntity();
      this.potPayFe.legaleComeAttoreAbilitato = Boolean(this.utilytyService
        .getPISystemPorperty('SinistriAbilitazioneLegAvvAsAttCoinvolto'));
      this.potPayFe.invoiceDataControl = Boolean(this.utilytyService
        .getPISystemPorperty('ClaimsInvoiceDataControl'));
      // recupero la PISYSTEMPROP
      // CONFIG_ABILITAZIONE_LEGALE_AVV_COME_ATTORE_COINVOLTO = "SinistriAbilitazioneLegAvvAsAttCoinvolto";

      this.potPayFe.uuid = this.paymentService.getSessionUuid('sessionId');
      this.paymentService.initPotentialPayment(this.potPayFe).subscribe((outcome: Outcome) => {
        console.log('initPotentialPayment - outcome:  ', outcome);
      });
    }
  }

  onConfirm() {
    // this.saveEmitter.emit(true);
    this.eventService.broadcastEvent('start-loader');
    this.MsgError = null;
    this.potPayFe.paymentList.forEach(element => {
      const id = element.id;
      const paymentMethodId = this.detailDataMethodPayment.paymentData;
      if (paymentMethodId && paymentMethodId !== null) {
        if (paymentMethodId.methodId) {
          element.methodPayment = this.detailDataMethodPayment.paymentData;
          element.note = this.settlementForm.get('note').value;
          if (paymentMethodId.methodId === '4' || paymentMethodId.methodId === '1') {
            const type: string = element.methodPayment.receiver.type;
            if (type === 'RAGE') {
              element.methodPayment.receiver.idActor = this.claimCurrent.policyAgency.agencyId;
            }
          }
        }
      } else {
        const obstr1$ = this.translateService.translate('_CLAIMS_._SETTLEMENT');
        const obstr2$ = this.translateService.translate('_CLAIMS_._MANDATORY_DATA_MISSING');
        combineLatest([
          obstr1$,
          obstr2$,
        ]).subscribe(
            ([tr1, tr2]) => {
            if(element.generatePayment){
              this.MsgError=tr1+ '-'+ tr2;
              this.eventService.broadcastEvent('stop-loader');
            }
      })
      }
    });
    if (!this.MsgError) {
      this.checkforValidity();
    }
  }

  checkforValidity() {
    const date = this.dataPagamentoQuietanza();
    this.detailDataMethodPayment.note = this.settlementForm.get('note').value;
    const body: BodyRequestDataSettlement = {
      claimNumber: this.claimNumber,
      occurrenceDate: date,
      idParty: this.idParty,
      idSettlement: this.idSettlment,
      settlementNumber: this.potPayFe.paymentList[0].id
    };
    this.paymentService
      .checkPaymentData(this.claimNumber.toString(), this.potPayFe,
        this.authSrv.getOperator().liquidationCentre.code,
        this.authSrv.getOperator().objectId)
      .subscribe((response: any) => {
        if (response.code === 'OK') {
          this.sendNewSettlementDetails(body, this.detailDataMethodPayment);
        } else {
          // this.MsgError = response.descr;
          this.eventService.broadcastEvent('stop-loader');
        }
      },
        (error: Error) => {
          if (this.setlementResultResponse) {
            this.MsgError = this.setlementResultResponse.descr;
          } else {
            // this.MsgError = this.getError(body);
            this.MsgError = error;
          }
          // this.MsgError = error.message;
        });
  }

  onChangeModelForm(event: OutputDetailPaymentMethod) {
    this.detailDataMethodPayment = event;
    let booleanDataValidation = false;
    try {
      this.detailDataMethodPayment.paymentData = event.paymentData === null ? this.potPayFe.paymentList[0].methodPayment : event.paymentData;
      this.detailDataMethodPayment.subjectSelected.codeSelected = !this.detailDataMethodPayment.subjectSelected.codeSelected ? null
        : this.detailDataMethodPayment.subjectSelected.codeSelected;
      this.detailDataMethodPayment.subjectSelected.subjectEntity = !this.detailDataMethodPayment.subjectSelected.subjectEntity ? null
        : this.detailDataMethodPayment.subjectSelected.subjectEntity;
      this.detailDataMethodPayment.note = this.settlementForm.get('note').value;
      // Verifico se nel oggeto tutti i valori sono null
      booleanDataValidation = this.detailDataMethodPayment.note !== null ? true : booleanDataValidation;
      booleanDataValidation = this.detailDataMethodPayment.paymentData !== null ? true : booleanDataValidation;
      booleanDataValidation = this.detailDataMethodPayment.subjectSelected.codeSelected !== null ? true : booleanDataValidation;
      booleanDataValidation = this.detailDataMethodPayment.subjectSelected.subjectEntity !== null ? true : booleanDataValidation;
      booleanDataValidation = this.detailDataMethodPayment.validation !== null ? true : booleanDataValidation;
      // Se booleanDataValidation disabilito il pulsante
      this.saveDisabled = booleanDataValidation ? false : true;
      // Se il controllo di  detailDataMethodPayment non va a buon fine - disabilito il pulsante
      this.saveDisabled = this.detailDataMethodPayment.validation ? false : true;
    } catch (error) {
      this.saveDisabled = true;
    }
  }

  sendNewSettlementDetails(body: BodyRequestDataSettlement, detailPayment: OutputDetailPaymentMethod) {
    const objectToSave = {
      ...body,
      methodPayment: detailPayment.paymentData,
      paymentNote: detailPayment.note
    }
    this.paymentService.seveNewSettlementWithPaymentMethod(objectToSave).subscribe(result => {
      this.eventService.broadcastEvent('stop-loader');
      try {
        this.setlementResultResponse = result;
      } catch (error) {
        console.log(error);
      }
      const outputClose: OutputCloseModal = {
        settlementNumber: body.settlementNumber,
        stateModal: 'confirm',
        code: result.code,
        messages: result.messages,
        methodPayment: this.detailDataMethodPayment.paymentData,
        notePayment: this.settlementForm.get('note').value
      };
      this.closeModal(outputClose);
    });
  }
  calculateTotalAmount() {
    this.finPays.forEach((res: PaymentEntity) => {
      this.totalAmount += res.totalAmountNet;
    });
  }
}

export class CommissionsElement {
  constructor(
    public derogate: boolean,
    public total: boolean,
    public fees: boolean,
    public description: string,
    public purchasePercentage: number,
    public purchaseAmount: number,
    public purchaseAdditionalPercentage: number,
    public purchaseAdditionalAmount: number,
    public collectionPercentage: number,
    public collectionAmount: number,
    public collectionAdditionalPercentage: number,
    public collectionAdditionalAmount: number,
    public totalPercentage: number,
    public totalAmount: number,
    public key: string,
    public idRischioPol: string
  ) {
  }
}

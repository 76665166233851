export class AnagDisplayLabel {
  label: string;
  value: any;
  dataQa: string;

  constructor(label: string, value: any, dataQa: string) {
    this.label = label;
    this.value = value;
    this.dataQa = dataQa;
  }
}

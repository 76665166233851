import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Enumerated} from './models/domain-models/enumerated';
import {GenericEntity} from './models/domain-models/generic-entity';
import {Observable} from 'rxjs';
import {SystemProperty} from './models/api-models/api-system-properties';
import {MicHttpService} from './http/mic-http.service';

@Injectable(
  {providedIn: 'root'}
)
export class CommonService {

  private baseApiUrlV2;
  protected httpClient: HttpClient;
  private baseApiUrl;
  private motorEndpoint;
  private specialPlateTypesEndpoint;
  private specialPlatePrefixesEndpoint;
  private plateTypesEndpoint;
  private plateTypeEndpoint;
  private vehicleTypesEndpoint;
  private companiesEndpoint;
  private systempropertyEndPoint;

  constructor(protected micHttpClient: MicHttpService, @Inject('environment') environment: any) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.motorEndpoint = this.baseApiUrlV2 + '/motor';
    this.specialPlateTypesEndpoint = this.baseApiUrlV2 + '/motor/specialplates/types';
    this.specialPlatePrefixesEndpoint = this.baseApiUrlV2 + '/motor/specialplates/prefixes';
    this.plateTypesEndpoint = this.baseApiUrlV2 + '/motor/plateTypes';
    this.plateTypeEndpoint = this.baseApiUrlV2 + '/motor/plateType';
    this.vehicleTypesEndpoint = this.baseApiUrlV2 + '/motor/vehicleTypes';
    this.companiesEndpoint = this.baseApiUrlV2 + '/common/companies';
    this.systempropertyEndPoint = this.baseApiUrlV2 + '/common/config';

    this.httpClient = this.micHttpClient.getHttpClient();
  }

  getEnumValues(enumKey: string, contractId: string): any {
    let httpParams: HttpParams = new HttpParams();
    if (contractId) {
      httpParams = httpParams.set('contractId', contractId);
    }
    if (enumKey === 'VEHICLE_ORIGIN') {
      return this.httpClient.get<Array<Enumerated>>(this.motorEndpoint + '/enumValues/' + enumKey, {params: httpParams});
    } else {
      return this.httpClient.get<Array<GenericEntity>>(this.motorEndpoint + '/enumValues/' + enumKey, {params: httpParams});
    }
  }
  getValues(contractId: string): any{
    let httpParams: HttpParams = new HttpParams();
    const enumKey = 'DOORS';
    if (contractId) {
      httpParams = httpParams.set('contractId', contractId);
    }
    return this.httpClient.get<Array<Enumerated>>(this.motorEndpoint + '/enumValues/' + enumKey, {params: httpParams});
  }

  getSpecialPlatesTypes(): Observable<Array<GenericEntity>> {
    return this.httpClient.get<Array<GenericEntity>>(this.specialPlateTypesEndpoint);
  }

  getSpecialPlatesPrefixes(specPlateType: GenericEntity): Observable<Array<GenericEntity>> {
    if (specPlateType) {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.set('typeId', specPlateType.id);
      return this.httpClient.get<Array<GenericEntity>>(this.specialPlatePrefixesEndpoint, {params: httpParams});
    }
  }

  getPlateTypes(plateNumber: string): Observable<Array<GenericEntity>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('plate', plateNumber);
    return this.httpClient.get<Array<GenericEntity>>(this.plateTypesEndpoint, {params: httpParams});
  }

  getPlateType(vehicleIdentifier: string, vehicleTypeCode: string, vehicleTypeDescription: string,
               nodeId: string): Observable<GenericEntity> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('vehicleTypeCode', vehicleTypeCode);
    httpParams = httpParams.set('vehicleTypeDescription', vehicleTypeDescription);
    httpParams = httpParams.set('nodeId', nodeId);
    return this.httpClient.get<GenericEntity>(this.plateTypeEndpoint + '/' + vehicleIdentifier, {params: httpParams});
  }

  getCompanies(): Observable<Array<GenericEntity>> {
    return this.httpClient.get<Array<GenericEntity>>(this.companiesEndpoint);
  }

  getSystemPoperty(key: string): Observable<SystemProperty> {
    return this.httpClient.get<SystemProperty>(this.systempropertyEndPoint + '/' + key);
  }

  getVehicleTypes(): Observable<Array<GenericEntity>> {
    return this.httpClient.get<Array<GenericEntity>>(this.vehicleTypesEndpoint);
  }
}

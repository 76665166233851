import {NgModule} from '@angular/core';
import {LifePostsalesCardComponent} from './life-postsales-card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LpcOperationsListModule} from '../../modules/lpc-operations-list/lpc-operations-list.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KarmaInterceptor } from '../../karma-service/karma-interceptor.service';
import { KarmaAuthModule } from '../../karma-service/karma-auth.module';
import { KarmaService } from '../../karma-service/karma.service';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LPC_TRANSLATIONS } from '../../i18n/translations';

@NgModule({
    declarations: [LifePostsalesCardComponent],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        LpcOperationsListModule,
        KarmaAuthModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(LPC_TRANSLATIONS)
    ],
    exports: [
        KarmaAuthModule,
        LifePostsalesCardComponent
    ],
    providers: [
        KarmaService,
        { provide: HTTP_INTERCEPTORS, useClass: KarmaInterceptor, multi: true }
    ]
})
export class LifePostsalesCardModule {
}

import { State } from "@rgi/rx/state";
import { OutputForfait } from "../models/forfait/output-forfait.model";
import { DialogueFunction } from "../models/dialogue-function.model";
import { DeleteForfait } from "../models/forfait/delete-forfait.model";

export class ForfaitState extends State {
    search: OutputForfait[] = []
    add: OutputForfait | null = null
    modify: OutputForfait | null = null
    delete: DeleteForfait | null = null

    oldIdForfait: string | null = null

    dialogueFunctions: DialogueFunction[] = []

    errorMessage: string | null = null
}
export class IndexType {
  constructor(
    public code: string,
    public description?: string | null,
    public operativity?: boolean | null,
    public indexValue?: number | null,
    public increasePerc?: number | null,
    public rebasingCoeff?: number | null,
    public referenceBase?: number | null,
    public adjustmentMinPerc?: number | null,
    public adjustmentMaxPerc?: number | null,
  ) {
  }
}

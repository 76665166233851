<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="false" #first color="info">
  <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
    <div class="row rgi-claims-title-section-shared" style="padding:5px;">
      <div class="col-xs-12 col-md-12">
        <label ng-bind="label">
          <span>{{'_CLAIMS_._VEHICLE'| translate}}</span>
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="vehicleTemplate"></ng-container>
</rgi-rx-expansion-panel>


<div *ngIf="!isAccordion">
  <div class="row rgi-claims-sub-title-section-shared">
    <div class="col-xs-12 col-md-12">
      <label ng-bind="label">
          {{ '_CLAIMS_._VEHICLE' | translate }}
      </label>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="vehicleTemplate"></ng-container>
</div>




<ng-template #vehicleTemplate>
  <fieldset>
    <h4 class="chapter">
      <span>{{'_CLAIMS_._VEHICLE'| translate}}</span>
        <span *ngIf="!isCompanyVehicle" class="btn btn-flat btn-sm" (click)="openModalGetVehicleCounterpart()"> 
        <span class="hidden-sm hidden-xs" *ngIf="vehicle.licensePlateNumber == null" translate>Compile</span>
        <span class="hidden-sm hidden-xs" *ngIf="vehicle.licensePlateNumber != null" translate>Edit</span>
        <span class="rgifont rgi-pencil"></span>
      </span>
    </h4>
  
    <div class="container-fluid table-white">
      <div class="row ptb-05">
        <div class="col-sm-6">
          {{ '_CLAIMS_._ASSET' | translate }}:
          <span>{{ vehicle?.ppAsset?.description || '-' }}</span>
        </div>
        <div class="col-sm-6">
          {{'_CLAIMS_._ASSET_DESCRIPTION' | translate}}:
          <span>{{vehicle.manufacturerDescription}} {{vehicle.modelDescription}} ({{vehicle.licensePlateNumber}})</span>
        </div>
      </div>
  
      <div class="row ptb-05">
        <div class="col-sm-6 col-xs-12">
          {{'_CLAIMS_._PLATE_TYPE' | translate}}:
          <span>{{vehicle.licensePlateType.descrizione || '-'}}</span>
        </div>
        <div class="col-sm-6 col-xs-12" *ngIf="vehicle.licensePlateNumber">
          {{'_CLAIMS_._PLATE_NUMBER' | translate}}:
          <span>{{vehicle.licensePlateNumber || '-'}}</span>
        </div>
        <div class="col-sm-6 col-xs-12" *ngIf="vehicle.chassisNumber">
          {{'_CLAIMS_._CHASSIS_NUMBER' | translate}}:
          <span>{{vehicle.chassisNumber || '-'}}</span>
        </div>
      </div>
      <div class="row ptb-05">
        <div class="col-sm-6 col-xs-12">
          {{'_CLAIMS_._MANUFACTURER' | translate}}:
          <span>{{vehicle.manufacturerDescription || '-'}}</span>
        </div>
        <div class="col-sm-6 col-xs-12">
          {{'_CLAIMS_._MODEL' | translate}}:
          <span>{{vehicle.modelDescription || '-'}}</span>
        </div>
      </div>
      <div class="row ptb-05">
        <div class="col-sm-6 col-xs-12 ">
          {{'_CLAIMS_._FIRST_REGISTRATION_DATE' | translate}}:
          <span>{{ (vehicle.firstRegistrationDate | date: 'shortDate') || '-'}}</span>
        </div>
        <div class="col-sm-6 col-xs-12 ">
          {{'_CLAIMS_._NATION' | translate}}:
          <span>{{vehicle.licensePlateCountryCode || '-'}}</span>
        </div>
      </div>
      <div class="row ptb-05">
        <div class="col-sm-6 col-xs-6 ">
          <br clear="all">
          {{'_CLAIMS_._PARKED_VEHICLE' | translate}}: </div>
        <div class="col-sm-6 col-xs-6">
          <rgi-rx-button-toggle color="default" [(ngModel)]="isParkedVehicle"
            (ngModelChange)="onNgModelChange($event)">
            <button rgi-rx-button rgiRxValueOption value="yes">{{'_CLAIMS_._YES' | translate }}</button>
            <button rgi-rx-button rgiRxValueOption value="no">{{'_CLAIMS_._NO' | translate }}</button>
          </rgi-rx-button-toggle>
        </div>
      </div>
      <div class="form-group" *ngIf="vehicle.sicOutcome && !isCompanyVehicle">
        <label for="vehicle.sicOutcome" class="control-label">{{'_CLAIMS_._SIC_OUTCOME' | translate}}</label>
        <p class="form-control-static">{{vehicle.sicOutcome}}</p>
      </div>
    </div>
  </fieldset>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';

/* eslint-disable */
@Component({
  selector: 'dq-loader-mobile',
  templateUrl: './loader-mobile.component.html',
  styleUrls: ['./loader-mobile.component.scss']
})
export class LoaderMobileComponent implements OnInit {

  @Input() loader: boolean;

  constructor() { }

  ngOnInit() {
  }

}

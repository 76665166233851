<ng-template>
  <div
    [id]="id"
    class="rgi-ui-menu"
    [ngClass]="{'rgi-ui-sub-menu': !!parentMenu}"
    tabindex="-1"
    role="menu"
    (keydown)="_handleKeydown($event)"
  >
    <ng-content></ng-content>

  </div>
</ng-template>

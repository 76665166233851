import { Directive, ElementRef, HostListener, HostBinding, OnInit } from '@angular/core';

@Directive({
  selector: '[pncDateFormatter]'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class DateFormatterDirective implements OnInit {
  private element: ElementRef;
  @HostBinding('value') inputDate: string;
  private charNotAllowed = false;

  constructor(el: ElementRef) {
    this.element = el;
  }

  ngOnInit() {
    this.inputDate = this.element.nativeElement.value;
  }

  @HostListener('keyup', ['$event.target.value'])
  onDigit(value) {
    this.inputDate = value;
    if (this.inputDate.length === 1 && (this.inputDate.startsWith('4') ||
    this.inputDate.startsWith('5') || this.inputDate.startsWith('6') ||
    this.inputDate.startsWith('7') || this.inputDate.startsWith('8') ||
    this.inputDate.startsWith('9'))) {
      this.inputDate = '0' + this.inputDate;
    }
    if (this.inputDate.length > 2 && this.inputDate.length < 5 && value.charAt(value.length - 1) >= 2) {
      this.inputDate = [
        this.inputDate.slice(0, this.inputDate.length - 1), '0',
        this.inputDate.slice(this.inputDate.length - 1, this.inputDate.length)
      ].join('');
    }
    if (this.inputDate.charAt(this.inputDate.length - 1) === '/' && this.inputDate.length === 2) {
      this.inputDate = '0' + this.inputDate;
    }
    if (this.inputDate.charAt(this.inputDate.length - 1) === '/' && this.inputDate.length === 5) {
      this.inputDate = [
        this.inputDate.slice(0, this.inputDate.length - 2), '0',
        this.inputDate.slice(this.inputDate.length - 2, this.inputDate.length)
      ].join('');
    }
    if ((this.inputDate.length === 2 || this.inputDate.length === 5) && this.inputDate.charAt(this.inputDate.length - 1) !== '/') {
      this.inputDate = this.inputDate + '/';
    }
    if (this.inputDate.length === 10 && this.inputDate.indexOf('/') === -1) {
      this.inputDate = [this.inputDate.slice(0, 2), '/', this.inputDate.slice(2, 4), '/', this.inputDate.slice(5, 9)].join('');
    }
  }

  @HostListener('keydown', ['$event'])
  onPress(event) {
    this.element.nativeElement.maxLength = 10;
    if ((!(event.keyCode >= 48 && event.keyCode <= 57) || ((event.keyCode >= 48 && event.keyCode <= 57) &&
    (event.key === '!' || event.key === '"' ||
    event.key === '£' || event.key === '$' ||
    event.key === '%' || event.key === '&' ||
    event.key === '/' || event.key === '(' ||
    event.key === ')' || event.key === '='))) &&
    event.keyCode !== 8 && event.keyCode !== 46 &&
    !(event.keyCode >= 96 && event.keyCode <= 105)) {
      event.preventDefault();
    }
  }

}

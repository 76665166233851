import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Documents} from "./resources/model/documents";
import {DocumentsService} from "./services/documents.service";
import {DocumentFile} from "./resources/model/document-file";
import {Document} from "./resources/model/document";
import {
  DocumentUploadFileModalComponent,
  RgiUploadFileModalData
} from "./modals/document-upload-file-modal/document-upload-file-modal.component";
import {ModalService} from "@rgi/rx/ui";
import {Metadata} from "./resources/model/metadata";
import {
  DocumentValidationFileModalComponent,
  RgiValidationFileModalData
} from "./modals/document-validation-file-modal/document-validation-file-modal.component";
import {
  DocumentAddModalComponent,
  RgiAddDocumentModalData
} from "./modals/document-add-modal/document-add-modal.component";
import {RGI_DOCUMENTS_ACTIONS} from "./resources/constants/actions";
import {LabeledEntity} from "../generic-detail/resources/models/entities";
import {GenericEntity} from "./resources/model/generic-entity";

@Component({
  selector: 'rgi-documents',
  templateUrl: './documents.component.html'
})
export class DocumentsComponent {

  @Input() public contractId: string = '';
  @Input() public uploadDownloadEnabled: boolean = false;
  @Input() public documents: Documents;

  @Output() public eventModifiedDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() public startDocument: EventEmitter<any> = new EventEmitter<any>();


  public arInpDocValidation: any[];
  public addDocumentEnabled: boolean;
  public deleteDocumentEnabled: boolean;
  public validationDocEnabled: boolean;
  public deleteFileEnabled: boolean;
  public uploadFileEnabled: boolean;
  public isInputDocumentsSectionVisible: boolean = false;
  public isOutputDocumentsSectionVisible: boolean = false;

  constructor(
    protected documentsService: DocumentsService,
    protected modalService: ModalService
  ) {
  }

  ngOnInit() {
    if (this.documents) {
      this.setArInpDocValidation();
      this.statusChanged(true);
    } else if (this.contractId) {
      this.documentsService.getDocumentType(this.contractId, true).subscribe((documentTypes: GenericEntity[]) => {
        if (documentTypes && documentTypes.length > 0) {
          this.isInputDocumentsSectionVisible = true;
          this.documentsService.getDocumentsObservable().subscribe((documents: Documents) => {
            this.documents = documents;
          });
          this.loadDocuments();

        } else {
          this.isInputDocumentsSectionVisible = false;
        }
      });
    }
  }

  protected loadDocuments(){
    this.documentsService.getDocuments(this.contractId).subscribe((documents: Documents) => {
      this.documents = documents;

      this.setArInpDocValidation();
      this.statusChanged(true);
      this.addDocumentEnabled = documents.documentsManageable;
      this.deleteDocumentEnabled = documents.documentsManageable;
      this.validationDocEnabled = documents.documentsManageable;
      this.deleteFileEnabled = documents.documentsDelatable;
      this.uploadFileEnabled = documents.documentsUploadable;
    });
  }

  uploadFile(documentId: string) {
    if (documentId) {
      const document = this.getInputDocument(documentId);

      if (document) {
        const file = this.instantiateFile(document);
        this.openUpFileModal(document, file, true, 'Upload new document');
      }
    }
  }


  deleteDocument(documentId: string) {
    if (documentId) {
      this.documentsService.deleteDocument(this.contractId, documentId).subscribe((docs: Documents | undefined) => {
          if(docs) {
            this.loadDocuments();
          }
        });
    }
  }

  instantiateFile(document: Document) {
    const confMetadataList = document.confMetadataList;
    const id = Math.max.apply(Math, document.files.map(
      (file: DocumentFile) => {
        return file.id ? parseInt(file.id, 10) : 0;
      }
    )) + 1;

    const fileInput = new DocumentFile();
    // @ts-ignore
    fileInput.id = id;
    fileInput.signed = false;
    fileInput.downloaded = false;
    fileInput.validate = false;
    fileInput.url = '';
    fileInput.verifyDate = new Date();

    const metadataList: Metadata[] = [];

    confMetadataList.forEach(confMetadata => {
      const metadata = new Metadata();
      metadata.code = confMetadata.code;
      metadata.description = confMetadata.description;
      metadata.value = confMetadata.value;
      metadata.valueDescription = confMetadata.valueDescription;
      metadata.listValue = confMetadata.listValue;
      metadata.visible = confMetadata.visible;
      metadata.usedForValidation = confMetadata.usedForValidation;
      metadata.mandatory = confMetadata.mandatory;
      metadata.complianceStatus = confMetadata.complianceStatus;
      metadata.type = confMetadata.propertyType;
      metadata.usedAsIndex = confMetadata.usedAsIndex;
      metadata.modifiable = confMetadata.modifiable;
      metadata.systemMetadato = confMetadata.systemMetadato;
      metadataList.push(metadata);
    });

    fileInput.metadataList = metadataList;

    return fileInput;
  }

  updateFile(documentId: string, fileId: string) {
    if (documentId && fileId) {
      const document = this.getInputDocument(documentId);
      if (document) {
        const file = this.getFile(document, fileId);

        if (file) {
          this.openUpFileModal(document, file, false, 'Update file');
        }
      }
    }
  }

  /* Funzione per upload/update del file */
  trackByIdFile(index: number, file: DocumentFile) {
    return file.idDocDMS;
  }
  openUpFileModal(document: Document, file: DocumentFile, bUploadFile: boolean, title: string) {
    if (file) {
      let modalData = new RgiUploadFileModalData(
        title,
        this.contractId,
        document,
        file,
        bUploadFile,
        this.arInpDocValidation,
        this.documents.documentsMaxKBSize,
        this.documents.documentsFileTypes
      );

      var uploadFileModal = this.modalService.openComponent(DocumentUploadFileModalComponent, modalData);
      uploadFileModal.modal.enableClickBackground = false;
      uploadFileModal.modal.onClose.subscribe((data: any) => {
        this.loadDocuments();
      });


      // instance.eventModifiedDocument.subscribe(() => {
      //   this.statusChanged();
      // });
    }
  }

  validationFile(documentId: any, fileId: any) {
    if (documentId && fileId) {
      const document = this.getInputDocument(documentId);

      if (document) {
        const file = this.getFile(document, fileId);

        if (file) {
           const modalData = new RgiValidationFileModalData(
            'Validation document',
            this.contractId,
            document,
            file,
            this.arInpDocValidation
           );

          const validationFileModal = this.modalService.openComponent(DocumentValidationFileModalComponent, modalData);
          validationFileModal.modal.enableClickBackground = false;
          validationFileModal.modal.onClose.subscribe((data: any) => {
            console.log(data);
          });
        }
      }
    }
  }

  addDocument() {
    const modalData = new RgiAddDocumentModalData(
      'Add document',
      this.contractId,
      this.documents
    );
    const comp = this.modalService.openComponent(DocumentAddModalComponent, modalData);
    comp.modal.enableClickBackground = false;
    comp.modal.onClose.subscribe((data: any) => { // addr is an AnagApiAddress object
      if(data === RGI_DOCUMENTS_ACTIONS.FILE_UPLOADED) {
        this.statusChanged();
      }
    });
  }


  download(doc: Document, body: any) {
    const byteArray = new Uint8Array(body);
    const newBlob = new Blob([byteArray], { type: this.getFileType(doc.files[0]) });
    const datalocalURL = URL.createObjectURL(newBlob);

    /* scarica il file*/
    const fileLink = document.createElement('a');
    fileLink.href = datalocalURL;
    fileLink.download = doc.description;
    fileLink.target = '_blank';
    fileLink.click();
  }

  downloadDocument(doc: Document) {
    const body = doc.files[0].body;
    if (body && body.length > 0) {
      this.download(doc, body);
    } else {
      this.documentsService.previewFile(doc.id, false).subscribe((respBody: any) => {
          doc.files[0].body = respBody;
          doc.files[0].downloaded = true;
          this.download(doc, respBody);
        },
        () => {
          console.log('Errore durante download documento');
        },
        () => {
        });
    }
  }

  previewFile(file: DocumentFile) {
    const body = file.body;
    if (body && body.length > 0) {
      this.preview(body, file);
    } else {
      this.documentsService.previewFile(file.idDocDMS, true).subscribe((respBody: any) => {
        this.preview(respBody, file);
      });
    }
  }

  protected preview(body: any, file: DocumentFile) {
    const byteArray = new Uint8Array(body);
    const newBlob = new Blob([byteArray], { type: this.getFileType(file) });
    const datalocalURL = URL.createObjectURL(newBlob);
    //
    // /* scarica il file*/
    // const fileLink = document.createElement('a');
    // fileLink.href = datalocalURL;
    // fileLink.download = file.name;
    // fileLink.target = '_blank';
    // fileLink.click();s

    // var byteCharacters = atob(body);
    // var byteNumbers = new Array(byteCharacters.length);
    // for (var i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // var byteArray = new Uint8Array(byteNumbers);
    // var newBlob = new Blob([byteArray], { type: 'application/pdf;base64' });
    // var fileURL = URL.createObjectURL(newBlob);
    // window.open(fileURL);

    /* apre in nuovo tab*/

    const openWindow = window.open('about:blank');
    // @ts-ignore
    openWindow.document.write('<!DOCTYPE html>\n<body style="margin:0px;"></body>');
    const obj = document.createElement('iframe');
    obj.setAttribute('style', 'display: block;background: #000;border: none;height: 100vh;width: 100vw;');
    obj.frameBorder = '0';
    obj.width = '100%';
    obj.height = '100%';
    obj.src = datalocalURL;
    // @ts-ignore
    openWindow.document.body.appendChild(obj);
  }

  deleteFile(documentId: string, documentCode: string, fileId: string) {
    if (documentId && documentCode && fileId) {
      this.documentsService.deleteFile(this.contractId, documentCode.toString(), fileId.toString()).subscribe(data => {
        this.loadDocuments();
      });
    } else {
      console.log('Parametri non valorizzati');
    }
  }

  getInputDocument(documentId: string) {
    const docInput = this.documents.inputDocuments.find((document: Document) => {
      return document.id === documentId;
    });
    return docInput;
  }

  getFile(document: Document, fileId: string) {
    const file = document.files.find((docFile: DocumentFile) => {
      return docFile.id === fileId;
    });
    return file;
  }

  setArInpDocValidation() {
    const docInput = this.documents.inputDocuments;

    if (docInput.length > 0) {
      this.arInpDocValidation = [];
      docInput.forEach(document => {
        if (document.toBeValidated) {
          if (document.files && document.files.length > 0) {
            this.arInpDocValidation[document.id] = true;
            document.files.forEach(file => {
              if (!file.validate) {
                this.arInpDocValidation[document.id] = false;
              }
            });
          } else {
            this.arInpDocValidation[document.id] = false;
          }
        }
      });
    }
  }

  remainingItems(file: DocumentFile) {
    if (file.metadataList) {
      const aVisibleItems = file.metadataList.filter(
        (element) => {
          return (element.visible);
        } );
      return Array(3 - (aVisibleItems.length % 3)).fill('a');
    } else {
      return [];
    }
  }

  statusChanged(start?: boolean) {
    let validated = true;
    let completed = true;

    const docInput = this.documents.inputDocuments;
    docInput.forEach((document: Document) => {
      if (document.toBeValidated && !this.arInpDocValidation[document.id]) {
        validated = false;
      }
      if (document.min > document.files.length) {
        completed = false;
      }
    });

    if (start && start === true) {
      this.startDocument.emit({
        validated,
        completed
      });
    } else {
      this.eventModifiedDocument.emit({
        validated,
        completed
      });
    }
  }

  getFileType(file: DocumentFile) {
    let contentType = 'application/pdf';

    if (file.name.toLowerCase().endsWith('.png')) {
      contentType = 'image/png';
    } else if (file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg')) {
      contentType = 'image/jpeg';
    } else if (file.name.toLowerCase().endsWith('.tif') || file.name.toLowerCase().endsWith('.tiff')) {
      contentType = 'image/tiff';
    } else if (file.name.toLowerCase().endsWith('.pdf')) {
      contentType = 'application/pdf';
    } else {
      contentType = 'application/octet-stream';
    }

    return contentType;
  }

convertToLabeledEntity(metadataList: Array<Metadata>): LabeledEntity[]  {
    return metadataList.map((metadata: Metadata) => {
      // if(metadata.type.code === 'L'){
      //
      // }
      return new LabeledEntity(metadata.description, metadata.value);
    });
  }
}

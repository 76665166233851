import {QuestionFlatI, QuestionnaireFlatI} from '@rgi/ng-passpro';
import { EventEmitter, Injectable, Output } from '@angular/core';
import {SurveyService} from '@rgi/ng-passpropro-survey';
import {SurveyVersionI} from '@rgi/ng-passpropro-survey/lib/models/survey-version';
import {PassproproAuthService, ResponseResult} from '@rgi/ng-passpropro-core';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {SurveyVersionService} from './survey-version.service';
import { Questionnaire } from '../model/questionnaire.model';
import { Survey } from '../model/survey.model';
/* eslint-disable max-len */
interface ResponseResultWithKey extends ResponseResult<SurveyVersionI> {
  cacheKey: string;
}

@Injectable()
export class QuestionnaireCacheService {

  @Output() ids = new EventEmitter<number>();
  @Output() loaderQuest = new EventEmitter<string>();

  listIds: Array<number> = new Array<number>();
  toPersist = new Array<SurveyVersionI>();
  questCompiled = true;
  dateCache: any;
  cache: Map<string, Map<Questionnaire, QuestionnaireFlatI>> = new Map<string, Map<Questionnaire, QuestionnaireFlatI>>();
  checkQuestByType = false;
  cacheSurvey = new Map<string, SurveyVersionI>();

  constructor(
    private authService: PassproproAuthService,
    private surveyService: SurveyService,
    private surveyVersionService: SurveyVersionService) {
    console.debug('QuestionnaireCacheServiceImpl - constructor');
  }

  get(key: string) {
    return this.cache.get(key);
  }

  delete(key: string) {
    return this.cache.delete(key);
  }

  clear(quest: Questionnaire) {
    this.cache.forEach(q => {
      const qAnswers = q.get(quest);
      if (qAnswers) {
        delete quest.compiled;
        delete quest.idSaved;
        qAnswers.questions.forEach(question => {
          question.answers.forEach(answer => {
            answer.value = answer.defaultValue;
          });
        });
      }
    });
  }

  clearAll() {
    this.cache.clear();
    this.dateCache = null;
  }

  set(key: string, item: Map<Questionnaire, QuestionnaireFlatI>) {
    this.cache.set(key, item);
  }

  // metodo che persiste a DB i questionari e li restituisce sotto forma di mappa
  // il questionario viene versionato quando viene compilato per la prima volta
  // quando vengono rilevate modifiche si crea una nuova versione del questionario
  persistAll(): Observable<Map<string, Array<Survey>>> {
    const postSurvayArray$: Array<Observable<ResponseResult<SurveyVersionI>>> = [];

    this.cache.forEach((questionnaireMap, key) => {
      for (const questionnaire of questionnaireMap.keys()) {
        const questionnaireCacheKEY = key + '-' + questionnaire.code;
        if (!this.cacheSurvey.get(questionnaireCacheKEY)) {
          if (!questionnaire.mandatory && !questionnaireMap.get(questionnaire)) {
            // CASO QUESTIONARIO NON OBBLIGATORIO - NON COMPILATO
            console.log(questionnaire.name + ' (not mandatory) not compiled');
          } else {
            if (!!questionnaireMap.get(questionnaire)) {
              // CREO NUOVO QUESTIONARIO
              const newSurvey = this.surveyVersionService.makeSurveyVersion(questionnaire, questionnaireMap.get(questionnaire));
              postSurvayArray$.push(
                this.surveyService.createSurvey(newSurvey).pipe(
                  map((x: any) => {
                    this.cacheSurvey.set(questionnaireCacheKEY, x.result);
                    x.cacheKey = key;
                    questionnaire.idSaved = x.result.survey.id;
                    return x;
                  })
                )
              );
            }
          }
        } else {
          // QUESTIONARIO MODIFICATO O GIA' PRESENTE
          const questCached: any = JSON.parse(JSON.stringify(this.cacheSurvey.get(questionnaireCacheKEY)));
          const cachedQuestionnaire = questionnaireMap.get(questionnaire);

          const newQuestions = !!cachedQuestionnaire ? cachedQuestionnaire.questions : null;
          const oldQuestions = questCached.lastVersion.questionnaire.questions;

          if (!!newQuestions && this.isQuestModified(newQuestions, oldQuestions)) {
            // QUESTIONARIO MODIFICATO -> NUOVA VERSIONE
            questCached.lastVersion.questionnaire.questions = newQuestions;

            delete questCached.survey.uuid;
            delete questCached.survey.versionNumber;

            postSurvayArray$.push(
              this.surveyService.updateSurvey(questCached).pipe(
                map((x: any) => {
                  this.cacheSurvey.set(questionnaireCacheKEY, x.result);
                  x.cacheKey = key;
                  return x;
                })
              )
            );
          } else {
            // QUESTIONARIO NON MODIFICATO -> RITORNO IL VECCHIO
            postSurvayArray$.push(of({
              messages: [],
              isSuccess: true,
              result: questCached,
              cacheKey: key
            }));
          }
        }
      }
    }
    );

    return postSurvayArray$.length > 0 ?
      forkJoin(postSurvayArray$).pipe(
        map((savedSurvay, index) => {
          const responseQuestionnaireMap = new Map<string, Array<Survey>>();
          savedSurvay.forEach((resp: ResponseResultWithKey) => {
            if (!responseQuestionnaireMap.get(resp.cacheKey)) {
              responseQuestionnaireMap.set(resp.cacheKey, new Array<Survey>()); // todo ARRAY di questionari
            }
            const qa: Array<Survey> = responseQuestionnaireMap.get(resp.cacheKey);
            const survey: Survey = resp.result.survey;
            survey.uuid = resp.result.uuid;
            survey.versionNumber = resp.result.lastVersion.versionNumber;
            qa.push(survey);
          });
          return responseQuestionnaireMap;
        })
      )
    : of(new Map());
  }

  isQuestModified(newQuest: QuestionFlatI[], oldQuest: QuestionFlatI[]) {
    let changeDetected = false;
    newQuest.forEach(q => {
      const oldQuestion = oldQuest.find(oldQ => oldQ.code === q.code);
      if (q.answers.length === oldQuestion.answers.length) {
        q.answers.forEach((a, index) => {
          const a1 = !!a.value ? a.value.toString() : null;
          const a2 = !!oldQuestion.answers[index].value ? oldQuestion.answers[index].value.toString() : null;
          if (a1 !== a2) {
            changeDetected = true;
          }
        });
      }
    });
    return changeDetected;
  }

  setMandatory(mandatoryMap?: Map<string, boolean>) {
    this.cache.forEach(data => {
      for (const questionnaire of data.keys()) {
        if (mandatoryMap && mandatoryMap.size > 0) {
          if (mandatoryMap.get(questionnaire.code) !== undefined) {
            questionnaire.mandatory = mandatoryMap.get(questionnaire.code);
          }
        } else {
          questionnaire.mandatory = true;
        }
      }
    });
  }

  isCompiled(questType?: string) {
    this.questCompiled = true;
    this.cache.forEach(data => {
      for (const questionnaire of data.keys()) {
        if ((!questType || (questType && questionnaire.questionnaireType.code === questType)) &&
          questionnaire.mandatory && !questionnaire.compiled) {
          this.questCompiled = false;
        }
      }
    });
  }

  checkCompile(questType?: string) {
    this.isCompiled(questType);
    return this.questCompiled;
  }

  checkCompileByKey(key: string) {
    this.questCompiled = true;
    if (!!key) {
      const map1 = this.cache.get(key);

      if (!!map1) {
        for (const questionnaire of map1.keys()) {
          if (questionnaire.mandatory && !questionnaire.compiled) {
            this.questCompiled = false;
          }
        }
      }
    }

    return this.questCompiled;
  }


  showModal(toPrint) { // TODO serve ????????????????????????????????
    console.debug(toPrint);
  }

  checkQuestionnairesList() {
    const questionnaires = Array.from(this.cache.keys());
    return questionnaires.length > 0;
  }

  checkQuestionnairesListByQuestType(questType?: string) {
    this.checkQuestByType = false;
    if (this.checkQuestionnairesList()) {
      this.cache.forEach(data => {
        for (const questionnaire of data.keys()) {
          if (questionnaire.questionnaireType.code === questType) {
            this.checkQuestByType = !this.checkQuestByType;
            break;
          }
        }
      });
    }
    return this.checkQuestByType;
  }

  getQuestionnaireScore(questionnaire: Questionnaire, questFlatI: QuestionnaireFlatI): Observable<number> {
    return this.getQuestionnaireScoreByProduct(questionnaire, questFlatI, null);
  }

  /**
   * @description
   * call's the Ephemeral and returns the score by
   * filtering the response getting the correct questionnaire by the productCode.
   * @param questionnaire `Questionnaire`
   * @param questFlatI `QuestionnaireFlatI`
   * @param productCode `string`
   * @returns `score` `number`
   */
  getQuestionnaireScoreByProduct(questionnaire: Questionnaire, questFlatI: QuestionnaireFlatI, productCode: string): Observable<number> {
    const makedSurveyVersion = this.surveyVersionService.makeSurveyVersion(questionnaire, questFlatI);
    return this.surveyService.evaluateEphemeral({
      surveyVersion: makedSurveyVersion,
      idCommercialAgreement: undefined,
      showResults: true,
      product: [],
      needs: [],
      sources: []
    }).pipe(
      map((resp) => {
          if (!!productCode) {
            return resp.result.length > 0 ? resp.result
              .filter(res => productCode === res.code)
              .map(res => res.score).pop() : 0;
          }
          return resp.result.length > 0 ? resp.result[0].score : 0;
        }
      )
    );
  }

  /**
   * @description
   * call's the Ephemeral by sending the product code to the service as request param,
   * instead of filtering only the response by getting the correct questionnaire as in the method {@link getQuestionnaireScoreByProduct}.
   * @param questionnaire `Questionnaire`
   * @param questFlatI `QuestionnaireFlatI`
   * @param productCode `string`
   * @returns `score` `number`
   */
  callEvaluationByProductCode(questionnaire: Questionnaire, questFlatI: QuestionnaireFlatI, productCode: string): Observable<number | null> {
    const makedSurveyVersion = this.surveyVersionService.makeSurveyVersion(questionnaire, questFlatI);
    return this.surveyService.evaluateEphemeral({
      surveyVersion: makedSurveyVersion,
      idCommercialAgreement: undefined,
      showResults: true,
      product: [productCode],
      needs: [],
      sources: []
    }).pipe(
      map((resp) => {
          if (!!productCode) {
            return resp.result.length > 0 ? resp.result
              .filter(res => productCode === res.code)
              .map(res => res.score).pop() : null;
          }
          return resp.result.length > 0 ? resp.result[0].score : null;
        }
      )
    );
  }
}

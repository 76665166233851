import {Role} from './role';

export class PartiesData {

  public subscriber: any;
  public roles: Array<Role>;
  public leasingInstitution: boolean;
  public entitledId: string;
  public entitledIdLatestShapshot: string;
  public tariffPartyId: string;
  public tariffPartyIdLatestShapshot: string;
  public subscriberDeletable: boolean;
  public complete: any;

  constructor() {
  }

}

import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { EditablePanelContentComponent, PanelSignal, PanelService, CardComponentService } from '@rgi/digital-claims-common-angular';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { NewPaymentService } from '../new-payment.service';
import { UntypedFormGroup } from '@angular/forms';
import { ComboEntity } from '../domain/combo-entity';
import { MethodPaymentEntity } from '../domain/method-payment-entity';
import { PotentialPaymentEntity } from '../domain/potential-payment-entity';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { SubjectEntity } from '../domain/subject-entity';
import { IbanEntity } from '../domain/iban-entity';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { OutputDetailPaymentMethod } from './payment-method.model';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent extends EditablePanelContentComponent implements OnInit {
  @Output() stepperEventEmitter = new EventEmitter<number>();
  @Output() saveEmitter = new EventEmitter<boolean>();
  @Output() detailPaymetOutput = new EventEmitter<OutputDetailPaymentMethod>();
  @Input() potPayFE: InitPotentialPaymentEntity;
  newPaymentMethodForm: UntypedFormGroup;
  paymentMethod: Array<ComboEntity> = new Array<ComboEntity>();
  methodPayEntity: MethodPaymentEntity = new MethodPaymentEntity();
  public MsgError = null;
  public needsIban: boolean[];
  public needsIbanInternational: boolean[];
  public needsIbanInternationalEuro: boolean[];
  public needsCheck: boolean[];
  public legalDataforCheck: any;
  public needsReason: boolean[];
  public isDomiciliataryEnabled: boolean = false;
  public noLegalAssociated: boolean = true;
  public resCore: any;
  public claimCurrent: any;
  public address: any;
  public stringAddress: string;
  public paymentMap: Map<number, MethodPaymentEntity> = new Map();
  public transferDescrFixMap: Map<number, string> = new Map();
  @Input() card: any;
  @Input() openOutOfStepper: boolean;
  @Input() claimInput: boolean;
  public policyAgency: any;
  public typeOfPay: any;
  public missingData: boolean = false;
  public missingDataList: Map<string, boolean> = new Map<string, boolean>();
  public authSrv: any;
  public paymentSWIFT: string = '6';
  public paymentSEPA: string = '3';
  public paymentBonifico: string = '2';
  public swifts: [];
  public sepas: [];
  public transferDescriptionOverThreshold: boolean = false;
  public listFunz: any[];
  public isEnabledEditTransferDescription: boolean = false;

  eMezzoPagBonificoEst: TypeEnum;
  eMezzoPagBonifico: TypeEnum;

  // public selReceiver: string;
  // public idSubjectLav: string;

  constructor(
    panelService: PanelService,
    private newPaymentService: NewPaymentService,
    @Inject('eventService') private eventService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('coreResult') coreResult,
    @Inject('authService') authService,
    public translateService: RgiRxTranslationService,
    private cardComponentService: CardComponentService,
    private paymentConstants: PaymentConstants
  ) {
    super(panelService);
    this.resCore = coreResult;
    this.authSrv = authService;
  }

  cleanClassic(indexPay: number, payment: PotentialPaymentEntity) {
    this.needsIban[indexPay] = false;
    this.needsReason[indexPay] = false;
    this.paymentMap.get(payment.id).iban = null;
    this.paymentMap.get(payment.id).accountHolder = null;
    this.paymentMap.get(payment.id).bankDescription = null;
    this.paymentMap.get(payment.id).bankAddress = null;
    this.paymentMap.get(payment.id).transferDescription = null;
    this.transferDescriptionOverThreshold = false;
  }

  cleanInternational(indexPay: number, payment: PotentialPaymentEntity) {
    this.needsIbanInternational[indexPay] = false;
    this.needsReason[indexPay] = false;
    this.paymentMap.get(payment.id).foreignBankAccount = null;
    this.paymentMap.get(payment.id).bic = null;
    this.paymentMap.get(payment.id).currency = null;
    this.paymentMap.get(payment.id).description = null;
    this.paymentMap.get(payment.id).uic = null;
    this.paymentMap.get(payment.id).swift = null;
    this.paymentMap.get(payment.id).transferDescription = null;
    this.transferDescriptionOverThreshold = false;
  }

  cleanInternationalEuro(indexPay: number, payment: PotentialPaymentEntity) {
    this.needsIbanInternationalEuro[indexPay] = false;
    this.paymentMap.get(payment.id).foreignBankAccount = null;
    this.paymentMap.get(payment.id).iban = null;
    this.paymentMap.get(payment.id).bankDescription = null;
    this.paymentMap.get(payment.id).uic = null;
    this.paymentMap.get(payment.id).accountHolder = null;
    this.paymentMap.get(payment.id).transferDescription = null;
    this.transferDescriptionOverThreshold = false;
  }

  cleanCheck(indexPay: number, payment: PotentialPaymentEntity) {
    this.needsCheck[indexPay] = false;
  }


  reloadDetails(payment: PotentialPaymentEntity, indexPay: number, reloadAll: boolean = true) {
    const typeOfPay = (this.paymentMap.get(payment.id)) ? this.paymentMap.get(payment.id).methodId : null;
    // this.emitPaymentDetail(null, null, null, null );
    this.missingData = false;
    this.missingDataList[payment.id] = false;
    this.MsgError = null;
    // clean this logic. for now, transfer description is re-instated only for bank transfers that require it
    // BONIFICO
    if (typeOfPay === '2') {
      this.missingData = true;
      this.missingDataList[payment.id] = true;
      this.needsIban[indexPay] = true;
      this.needsReason[indexPay] = true;
      this.needsReason[indexPay] = true;
      if (reloadAll) {
        this.cleanInternational(indexPay, payment);
        this.cleanInternationalEuro(indexPay, payment);
        this.cleanCheck(indexPay, payment);
      }
      if (this.paymentMap.get(payment.id).iban !== null) {
        this.emitInitPaymentDetail(payment.id, true, null, null);
      }
      return;
    } else {
      this.needsIban[indexPay] = false;
      this.needsReason[indexPay] = false;
      this.paymentMap.get(payment.id).iban = null;
      this.paymentMap.get(payment.id).accountHolder = null;
      this.paymentMap.get(payment.id).bankDescription = null;
      this.paymentMap.get(payment.id).bankAddress = null;
      this.paymentMap.get(payment.id).transferDescription = null;

    }
    // BONIFICO SWIFT
    if (typeOfPay === '6') {
      this.missingData = true;
      this.needsReason[payment.id] = true;
      this.missingDataList[payment.id] = true;
      this.needsIbanInternational[indexPay] = true;
      if (payment.currencyCode !== 'EUR') {
        this.paymentMap.get(payment.id).currency = payment.currencyCode;
      } else {
        this.emitInitPaymentDetail(payment.id, false, null, null);
      }

      this.needsReason[indexPay] = true;
      if (reloadAll) {
        this.cleanCheck(indexPay, payment);
        this.cleanInternationalEuro(indexPay, payment);
        this.cleanClassic(indexPay, payment);
      }
      if (this.paymentMap.get(payment.id).swift !== null &&
      this.paymentMap.get(payment.id).foreignBankAccount !== null &&
      this.paymentMap.get(payment.id).bic !== null &&
      this.paymentMap.get(payment.id).uic !== null ) {
        this.emitInitPaymentDetail(payment.id, true, null, null);
      } else {
        this.emitInitPaymentDetail(payment.id, false, null, null);
      }
      return;
    } else {
      this.needsIbanInternational[indexPay] = false;
      this.paymentMap.get(payment.id).foreignBankAccount = null;
      this.paymentMap.get(payment.id).bic = null;
      this.needsReason[indexPay] = false;
      this.paymentMap.get(payment.id).currency = null;
      this.paymentMap.get(payment.id).description = null;
      this.paymentMap.get(payment.id).uic = null;
      this.paymentMap.get(payment.id).swift = null;
      this.paymentMap.get(payment.id).transferDescription = null;
    }
    // ASSEGNO DI TRAENZA
    if (typeOfPay === '4' || typeOfPay === '1') {
      this.needsIban[indexPay] = false;
      this.needsIbanInternational[indexPay] = false;
      this.needsIbanInternationalEuro[indexPay] = false;
      this.needsReason[indexPay] = false;
      this.paymentMap.get(payment.id).receiver = payment.listPayee[0];
      this.paymentMap.get(payment.id).receiver.type = 'RSBJ';

      this.missingData = false;
      this.missingDataList[payment.id] = false;
      // this.selReceiver = null;
      this.needsCheck[indexPay] = true;
      this.legalDataforCheck = this.potPayFE.professionistList.filter(subj => subj.type === 'LAV' && subj.name);
      if (this.legalDataforCheck.length > 0) {
        this.noLegalAssociated = false;
        if (this.legalDataforCheck.length === 1) {
          this.paymentMap.get(payment.id).receiver.type = 'RLAV';
          this.paymentMap.get(payment.id).receiver.legalCode = this.legalDataforCheck[0].idSubject;
        } else {
          this.paymentMap.get(payment.id).receiver.type = '';
        }
      } else {
        this.noLegalAssociated = true;
      }
      // this.potPayFE.subjectAnag = payment.listPayee[0];
      this.claimCurrent = this.openOutOfStepper ? this.claimInput : this.resCore.getResult(this.card.id, 'detail');
      if (this.claimCurrent.policyAgencyData) {
        this.policyAgency = this.claimCurrent.policyAgencyData;
      } else {
        this.policyAgency = this.card.policyAgencyData;
      }
      // this.idSubjectAge = this.policyAgency.agencyId;
      if (this.isDomiciliataryEnabled) {
        this.receiverCheckData(this.paymentMap.get(payment.id).receiver.type, payment.id, this.paymentMap.get(payment.id).receiver);
      } else if (this.openOutOfStepper) {
        this.receiverCheckData(this.paymentMap.get(payment.id).receiver.type, payment.id, this.paymentMap.get(payment.id).receiver);
      }
      this.address = this.policyAgency.agencyAddress;
      this.stringAddress = (this.address.placeAddress ? this.address.placeAddress : '')
        + ' ' + (this.address.number ? this.address.number : '')
        + ' ' + (this.address.postCode ? this.address.postCode : '')
        + ' ' + (this.address.city ? this.address.city : '')
        + ' ' + (this.address.cityCode ? '(' + this.address.cityCode + ')' : '')
        + ' ' + (this.address.province ? '(' + this.address.province + ')' : '')
        ;

      if (!((this.address.placeAddress !== null || this.address.number !== null) && this.address.postCode != null &&
        this.address.city !== null && (this.address.cityCode !== null || this.address.province !== null) &&
        (this.address.umaAddress != null && this.address.umaAddress.countryCode !== null || this.address.countryCode !== null))) {
        this.stringAddress += ' ' +
         this.translateService.translate('_CLAIMS_._MESSAGE_._INCOMPLETE_ADDRESS')
         .subscribe(
           res => res
         )

         
      }
      if (reloadAll) {
        this.cleanClassic(indexPay, payment);
        this.cleanInternational(indexPay, payment);
        this.cleanInternationalEuro(indexPay, payment);
      }
      return;
    } else {
      this.needsCheck[indexPay] = false;
    }
    // BONIFICO ESTERO SEPA
    if (typeOfPay === '3') {
      this.missingData = true;
      this.needsReason[indexPay] = true;
      this.missingDataList[payment.id] = true;
      this.needsIbanInternationalEuro[indexPay] = true;
      if (payment.currencyCode !== 'EUR') {
        this.paymentMap.get(payment.id).currency = payment.currencyCode;
      }
      this.needsReason[indexPay] = true;
      if (reloadAll) {
        this.cleanInternational(indexPay, payment);
        this.cleanClassic(indexPay, payment);
        this.cleanCheck(indexPay, payment);
      }
      if (this.paymentMap.get(payment.id).swift !== null &&
      this.paymentMap.get(payment.id).currency !== null &&
      this.paymentMap.get(payment.id).uic !== null ) {
        this.emitInitPaymentDetail(payment.id, true, null, null);
      } else {
        this.emitInitPaymentDetail(payment.id, false, null, null);
      }
      return;
    } else {
      this.needsIbanInternationalEuro[indexPay] = false;
      this.needsReason[indexPay] = false;
      this.paymentMap.get(payment.id).foreignBankAccount = null;
      this.paymentMap.get(payment.id).iban = null;
      this.paymentMap.get(payment.id).bankDescription = null;
      this.paymentMap.get(payment.id).uic = null;
      this.paymentMap.get(payment.id).accountHolder = null;
      this.paymentMap.get(payment.id).transferDescription = null;
    }

  }

  initPaymentMethod() {
    this.needsCheck = new Array(this.potPayFE.paymentList.length);
    this.needsIban = new Array(this.potPayFE.paymentList.length);
    this.needsReason = new Array(this.potPayFE.paymentList.length);

    this.needsIbanInternational = new Array(this.potPayFE.paymentList.length);
    this.needsIbanInternationalEuro = new Array(this.potPayFE.paymentList.length);

    for (let index = 0; index < this.potPayFE.paymentList.length; index++) {
      this.needsCheck[index] = false;
      this.needsIban[index] = false;
      this.needsIbanInternational[index] = false;
      this.needsReason[index] = false;
    }
    this.paymentMethod = new Array<ComboEntity>();
    this.newPaymentService.getPaymentMethods(this.potPayFE.uuid, this.potPayFE.paymentType)
      .subscribe((paymentMethods: Array<ComboEntity>) => {
        paymentMethods.forEach(type => {
          this.paymentMethod.push(type);
        });
      }
      );
  }

  getTransferDescriptionLength(description: string, idPay: any) {
    // const prevDefault = this.translate.instant('Claim') + ' n. ';
    const prevDefault = this.transferDescrFixMap.get(idPay);
    if (this.potPayFE.claimNumber && description != null) {
      // add 1 because [CLAIM_NUMBER][SPACE][TRANSFER DESCRIPTION]
      // tslint:disable-next-line:max-line-length
      this.transferDescriptionOverThreshold = (prevDefault ? prevDefault.length : 0) + this.potPayFE.claimNumber.length + 1 + description.length > 140;
      return ((prevDefault ? prevDefault.length : 0) + this.potPayFE.claimNumber.length + 1 + description.length);
    } else {
      return (prevDefault ? prevDefault.length : 0) + this.potPayFE.claimNumber.length + 1;
    }
  }


  emitInitPaymentDetail(paymentId: number, validationPassed: boolean, subject?: SubjectEntity, code?: string) {
    const payData: MethodPaymentEntity = this.paymentMap.get(paymentId);
    const detail: OutputDetailPaymentMethod = {
      paymentData: payData,
      note: '',
      subjectSelected: {
        subjectEntity: subject,
        codeSelected: code
      },
      validation: validationPassed
    };
    this.detailPaymetOutput.emit(detail);
  }

  emitPaymentDetail(paymentDetail: MethodPaymentEntity, paymentId: string,
                    validationForm: boolean, subject?: SubjectEntity, code?: string) {
    let descriptionTrans = '';
    const paymentId10 = this.paymentMap.get(Number.parseInt(paymentId, 10));
    if (this.paymentMap && paymentId10 && this.paymentMap.get(Number.parseInt(paymentId, 10)).transferDescription) {
      descriptionTrans = this.paymentMap.get(Number.parseInt(paymentId, 10)).transferDescription;
    }
    const detail: OutputDetailPaymentMethod = {
      paymentData: paymentDetail,
      note: descriptionTrans,
      subjectSelected: {
        subjectEntity: subject,
        codeSelected: code
      },
      validation: validationForm
    };
    if (this.openOutOfStepper) {
      this.detailPaymetOutput.emit(detail);
    }
  }

  internationPaymentCheckData(paymentId: string) {
    const payment: MethodPaymentEntity = this.paymentMap.get(Number.parseInt(paymentId, 10));
    if (payment.foreignBankAccount.length > 0 && payment.foreignBankAccount.includes('*')) {
      this.missingData = false;
      this.missingDataList[paymentId] = false;
      this.MsgError = null;
      this.emitPaymentDetail(payment, paymentId, true);
    } else {
      if (payment.foreignBankAccount != null && this.isValidIBANNumber(payment.foreignBankAccount)) {
        payment.foreignBankAccount = payment.foreignBankAccount.replace(/[^A-Z0-9]/g, '');
        if (payment.bic !== '' && payment.bic != null && payment.bic !== undefined &&
          payment.uic !== '' && payment.uic !== null && payment.uic !== undefined &&
          payment.swift !== '' && payment.swift !== null && payment.swift !== undefined) {
          this.missingData = false;
          this.missingDataList[paymentId] = false;
          this.MsgError = null;
          this.emitPaymentDetail(payment, paymentId, true);
        } else {
          this.emitPaymentDetail(payment, paymentId, false);
          this.MsgError = 'DATI INCOMPLETI';
          this.missingData = true;
          this.missingDataList[paymentId] = true;
        }
        if (payment.uic && payment.uic.length > 3) {
          this.emitPaymentDetail(payment, paymentId, false);
          this.translateService.translate('_CLAIMS_._MESSAGE_._UIC_NOT_VALID__MAX_3_CHARACTER')
          .subscribe(
            res => this.MsgError=res
          )
          this.missingData = true;
          this.missingDataList[paymentId] = true;
        }
      } else {
        this.emitPaymentDetail(payment, paymentId, false);
        this.missingData = true;
        this.missingDataList[paymentId] = true;
        this.translateService.translate('_CLAIMS_._MESSAGE_._FOREIGN_BANK_ACCOUNT_INVALID')
          .subscribe(
            res => this.MsgError=res
          )
      }
      if (this.openOutOfStepper) {
          // emit output con tutti i dati;
      }
    }





    
  }

  internationPaymentCheckDataEuro(paymentId: string) {
    const payment: MethodPaymentEntity = this.paymentMap.get(Number.parseInt(paymentId, 10));
    if (payment.foreignBankAccount !== null) {
      payment.foreignBankAccount = payment.foreignBankAccount.replace(/[^A-Z0-9]/g, '');

    }
    if (payment.iban !== null) {
      payment.iban = payment.iban.replace(/[^A-Z0-9]/g, '');

    }
    if (payment.iban.includes('*')) {
      this.missingData = false;
      this.missingDataList[paymentId] = false;
      this.MsgError = null;
    } else {
      if (this.isValidIBANNumber(payment.iban)) {
        this.emitPaymentDetail(payment, paymentId, true);
        if (payment.uic !== '' && payment.uic !== null && payment.uic !== undefined &&
          payment.currency !== '' && payment.currency !== null && payment.currency !== undefined) {
          this.missingData = false;
          this.missingDataList[paymentId] = false;
          this.MsgError = null;
          if (payment.foreignBankAccount !== null && payment.foreignBankAccount.length > 0) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._FOREIGN_BANK_ACCOUNT_INVALID')
            .subscribe(
              res => this.MsgError=res
            )
          }
        } else {
          this.translateService.translate('_CLAIMS_._MESSAGE_._INCOMPLETE_DATA')
          .subscribe(
            res => this.MsgError=res
          )
        }
        if (payment.uic.length > 3) {
          this.emitPaymentDetail(payment, paymentId, false);
          this.translateService.translate('_CLAIMS_._MESSAGE_._UIC_NOT_VALID__MAX_3_CHARACTER')
          .subscribe(
            res => this.MsgError=res
          )
  
        }
      } else {
        this.emitPaymentDetail(payment, paymentId, false);
        this.translateService.translate('_CLAIMS_._MESSAGE_._IBAN_INVALID')
        .subscribe(
        res => this.MsgError=res
      )
      }
    }
  }

  ibanPaymentCheckData(event: any, paymentId: any) {
    const payment: MethodPaymentEntity = this.paymentMap.get(Number.parseInt(paymentId, 10));
    this.paymentMap.get(paymentId).iban = event.target.value;
    
    const input = event.target.value;

    const obsItIban$ = this.translateService.translate('_CLAIMS_._INSERT_A_VALID_ITALIAN_IBAN');
    const obsIban$ = this.translateService.translate('_CLAIMS_._ENTER_A_VALID_IBAN');
    const obsEsIban$ = this.translateService.translate('_CLAIMS_._INSERT_A_VALID_SPANISH_IBAN');

    if (event.target.value.toUpperCase().includes('*')) {
      this.emitPaymentDetail(payment, paymentId, true);
      this.missingData = false;
      this.missingDataList[paymentId] = false;
      this.MsgError = null;
    } else {
      combineLatest([
        obsItIban$,
        obsIban$,
        obsEsIban$
      ]).subscribe(
          ([itIban, iban, esIban]) => {
            if ((input.length < 27 || input.slice(0, 2)) !== 'IT' && this.authSrv.getDefaultContext()=='IT') {
              this.emitPaymentDetail(payment, paymentId, false);
              if (input.slice(0, 2) == 'IT') {
                this.MsgError = itIban;
              } else {
                this.MsgError = iban;
              }
            } else if ((input.length < 24 || input.slice(0, 2)) !== 'ES' && this.authSrv.getDefaultContext()=='ES') {
              this.emitPaymentDetail(payment, paymentId, false);
              this.MsgError = esIban;
            } else {
              if (this.isValidIBANNumber(input)) {
                this.emitPaymentDetail(payment, paymentId, true);
                this.missingData = false;
                this.missingDataList[paymentId] = false;
                this.MsgError = null;
              } else {
                this.emitPaymentDetail(payment, paymentId, false);
                this.missingData = true;
                this.missingDataList[paymentId] = true;
              }
            }
          })
      }
  }

  ibanForeignPaymentCheckData(event: any, paymentId: any) {
    this.paymentMap.get(paymentId).iban = event.target.value;
    if (event.target.value.toUpperCase().includes('*')) {
      this.missingData = false;
        this.missingDataList[paymentId] = false;
        this.MsgError = null;

    } else {
      this.internationPaymentCheckData(paymentId);
    // eliminating eventual blank spaces
    event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    const input = event.target.value;
    if (input.length > 0  && !this.ismissingData() ) {
      // this.missingData = false;
      // this.missingDataList[paymentId] = false;
      // this.MsgError = null;
      if (this.isValidIBANNumber(input)) {
        this.missingData = false;
        this.missingDataList[paymentId] = false;
        this.MsgError = null;
      } else {
        this.missingData = true;
        this.missingDataList[paymentId] = true;
        this.translateService.translate('_CLAIMS_._MESSAGE_._IBAN_INVALID')
        .subscribe(
          res => this.MsgError=res
        )
      }
    }
    }

    // call check for other fields
    

  }
  formatString(str: string) {
    return (str == null || str === 'null' || str === 'null null') ? '' : str;
  }

  receiverCheckData(code: string, paymentId: any, receiver?: SubjectEntity) {
    if (code === 'RAGE' ||
      code === 'RSBJ' ||
      (code === 'RLAV' && receiver.legalCode)) {
      this.emitPaymentDetail(null, paymentId, true, receiver, code);
      this.missingData = false;
      this.missingDataList[paymentId] = false;
      this.MsgError = null;
    } else {
      this.emitPaymentDetail(null, paymentId, false, receiver, code);
      this.missingData = true;
      this.missingDataList[paymentId] = true;
    }
  }

  checkforValidity() {
    this.newPaymentService.checkPaymentData(this.potPayFE.claimNumber.toString(), this.potPayFE,
      this.authSrv.getOperator().liquidationCentre.code,
      this.authSrv.getOperator().objectId).subscribe(
        (response: any) => {
          if (response.code === 'OK') {
            console.log('Andata bene');
            this.proseguiPagamento();
          } else {
            this.MsgError = response.descr;
            this.eventService.broadcastEvent('stop-loader');
          }
        },
        (error: Error) => {
          this.MsgError = error.message;
          console.error(error);
        });
  }

  isValidIBANNumber(input: string) {
    const CODE_LENGTHS = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
      AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
      SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };

    const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters

    if (iban !== input) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE')
      .subscribe(
        res => this.MsgError=res
      )
      return false;
    }
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    // match and capture (1) the country code, (2) the check digits, and (3) the rest
    let digits;
    // check syntax and length
    if (!code || input.length !== CODE_LENGTHS[code[1]] || !(input.indexOf(' ') < 0)) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._IBAN_INVALID')
      .subscribe(
        res => this.MsgError=res
      )
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter): any => {
      return letter.charCodeAt(0) - 55;
    });
    // final check
    if (this.checksum(digits) !== 1) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._IBAN_INVALID')
      .subscribe(
        res => this.MsgError=res
      )
      return false;
    }
    return true;
  }

  checksum(stringInput) {
    let checksum = stringInput.slice(0, 2);
    let fragment;
    for (let offset = 2; offset < stringInput.length; offset += 7) {
      fragment = String(checksum) + stringInput.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }

  initEnums() {
    this.eMezzoPagBonificoEst = this.paymentConstants.getSingleEMezzoPagamento(ENUMSLISTPAYMENT.EMEZZOPAGAMENTO_BONIFICO_ESTERO);
    this.eMezzoPagBonifico = this.paymentConstants.getSingleEMezzoPagamento(ENUMSLISTPAYMENT.EMEZZOPAGAMENTO_BONIFICO);
  }

  ngOnInit(): void {
    console.log(this.potPayFE);
    this.initEnums();

    // check if user is enabled to edit the 2nd section of the transfer description
    const listFunz = this.authSrv.getOperator().enabledFeatures;
    if (listFunz.indexOf('SXTRDS') >= 0) {
      this.isEnabledEditTransferDescription = true;
    }
    // extract system property to enable or disable Domiciliatary view
    this.getSystemProperty.findProperty('ClaimsDomiciliataryEnabled').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsDomiciliataryEnabled') {
            const enableDomiciliatary = variable.value;
            if (enableDomiciliatary === 'Enable') {
              this.isDomiciliataryEnabled = true;
            }
          }
        }
      }
    });
    if (this.openOutOfStepper) {
      this.panelSignalRecieved(PanelSignal.open);
    }

  }
  protected validateContent(): boolean {
    throw new Error('Method not implemented.');
  }

  protected storeData() {
    throw new Error('Method not implemented.');
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (this.acceptSignals) {
      if (panelSignal === PanelSignal.open && !this.openOutOfStepper) {
        console.log('Doing beneficiary and payee open action. 1');
        this.eventService.broadcastEvent('start-loader');
        this.initPaymentMethod();
        let i = 0;
        this.newPaymentService.setTransferDescrFixMap(null);

        this.potPayFE.paymentList.forEach(element => {

          this.newPaymentService.getBanktransferdesc(this.potPayFE.claimNumber,
                              this.potPayFE.actorId,
                              element).subscribe((response: any) => {
                                  this.transferDescrFixMap.set(element.id, response.descr);
                              },
                              (error: any) => {
                              });



          const name = element.id;
          let methodPayment = new MethodPaymentEntity();
          methodPayment.receiver = new SubjectEntity();
          if (element.currencyCode !== null && element.currencyCode !== 'EUR') {
            methodPayment.methodId = this.eMezzoPagBonificoEst.eTypeCode;
            this.paymentMap.set(name, methodPayment);
          } else {
            methodPayment.methodId = this.eMezzoPagBonifico.eTypeCode;
            this.paymentMap.set(name, methodPayment);
          }
          const payOrig = this.newPaymentService.getPaymentDB();

          if (payOrig) {
            methodPayment = payOrig.methodPayment;
            this.paymentMap.set(name, methodPayment);
            if (methodPayment.methodId === '2') {
              if (methodPayment.iban) {
                const event = { target: { value: methodPayment.iban } };
                this.ibanPaymentCheckData(event, name);
              }
              this.needsIban[i] = true;
            } else if (methodPayment.methodId === '6') {
              this.internationPaymentCheckData(name + '');
              this.needsIbanInternational[i] = true;
            }
          } else {
            this.reloadDetails(element, i);
          }

          if (!element.listPayee[0].ibans || element.listPayee[0].ibans.length === 0 ||
            !element.listPayee[0].swifts || element.listPayee[0].swifts.length === 0 ||
            !element.listPayee[0].sepas || element.listPayee[0].sepas.length === 0) {
            // ricerco gli iban pregressi
            this.newPaymentService.payMethods(element.listPayee[0], this.paymentBonifico).subscribe(
              (res: any) => {
                console.log(res);
                element.listPayee[0].ibans = res;

              },
              error => {
                console.log('paydBonifico ERRORE');
                this.eventService.broadcastEvent('stop-loader');
              }
            );
            this.newPaymentService.payMethods(element.listPayee[0], this.paymentSWIFT).subscribe(
              (res: any) => {
                console.log(res);
                element.listPayee[0].swifts = res;

              },
              error => {
                console.log('paidSWIFT ERRORE');
                this.eventService.broadcastEvent('stop-loader');
              }
            );
            this.newPaymentService.payMethods(element.listPayee[0], this.paymentSEPA).subscribe(
              (res: any) => {
                console.log(res);
                element.listPayee[0].sepas = res;
                this.eventService.broadcastEvent('stop-loader');
              },
              error => {
                console.log('paidSEPAS ERRORE');
                this.eventService.broadcastEvent('stop-loader');
              }
            );
          } else {
            this.eventService.broadcastEvent('stop-loader');
          }
          i++;
        });

        this.potPayFE.subjectAnag = this.paymentMap;

        if (!this.openOutOfStepper) {
          this.enableAndOpenContent();
          this.setPanelIncomplete();
        }
      } else if (this.openOutOfStepper) {
        this.initPaymentMethod();
        this.potPayFE.paymentList.forEach(element => {
          const name = element.id;
          this.paymentMap.set(name, element.methodPayment);
          const i = 0;
          this.reloadDetails(element, i, false);
        });
        this.initIbans();
      }
      if (panelSignal === PanelSignal.init) {
        console.log('Doing beneficiary and payee init action.');
      }
    }
  }

  initIbans() {
    this.potPayFE.paymentList.forEach(element => {
      if (!element.listPayee[0].ibans || element.listPayee[0].ibans.length === 0 ||
        !element.listPayee[0].swifts || element.listPayee[0].swifts.length === 0 ||
        !element.listPayee[0].sepas || element.listPayee[0].sepas.length === 0) {
        // ricerco gli iban pregressi
        this.newPaymentService.payMethods(element.listPayee[0], this.paymentBonifico).subscribe(
          (res: any) => {
            console.log(res);
            element.listPayee[0].ibans = res;

          },
          error => {
            console.log('paydBonifico ERRORE');
            this.eventService.broadcastEvent('stop-loader');
          }
        );
        this.newPaymentService.payMethods(element.listPayee[0], this.paymentSWIFT).subscribe(
          (res: any) => {
            console.log(res);
            element.listPayee[0].swifts = res;

          },
          error => {
            console.log('paidSWIFT ERRORE');
            this.eventService.broadcastEvent('stop-loader');
          }
        );
        this.newPaymentService.payMethods(element.listPayee[0], this.paymentSEPA).subscribe(
          (res: any) => {
            console.log(res);
            element.listPayee[0].sepas = res;
            this.eventService.broadcastEvent('stop-loader');
          },
          error => {
            console.log('paidSEPAS ERRORE');
            this.eventService.broadcastEvent('stop-loader');
          }
        );
      }
    });
  }

  visulizeLabelIban(payment: PotentialPaymentEntity) {
    return payment.listPayee.find(payee => payee.primary && payee.ibans && payee.ibans.length > 0);
  }

  proseguiPagamento() {
    if (this.MsgError == null) {
      // Cycle example
      this.setPanelComplete();
      this.disablePanelContent();
      this.stepperEventEmitter.emit(4);
      this.signalToNextPanel(PanelSignal.open);
      this.signalToNextPanel(PanelSignal.init);
      this.signalToPanel('document-choice', PanelSignal.init);
      this.eventService.broadcastEvent('stop-loader');
    } else {
      this.eventService.broadcastEvent('stop-loader');
    }
  }

  onConfirm() {
    // this.saveEmitter.emit(true);
    this.eventService.broadcastEvent('start-loader');
    this.MsgError = null;
    this.potPayFE.paymentList.forEach(element => {
      const id = element.id;

      if (this.paymentMap.get(id).methodId) {
        element.methodPayment = this.paymentMap.get(id);
        element.methodPayment.description = this.paymentMethod.find(elem => elem.idObject === element.methodPayment.methodId).description;
        if (element.methodPayment.methodId === '4' || element.methodPayment.methodId === '1') {
          const type: string = element.methodPayment.receiver.type;
          // const idSbj: string =  element.methodPayment.receiver.idSubject ;
          // const idSbjPA: string =  element.methodPayment.receiver.idSubjectPA;
          // const codLeg: string =  element.methodPayment.receiver.legalCode;
          // element.methodPayment.receiver = new SubjectEntity();
          // element.methodPayment.receiver.type = type;
          if (type === 'RAGE') {
            // element.methodPayment.receiver.type = 'RAGE';
            element.methodPayment.receiver.idActor = this.policyAgency.agencyId;
          }

          // per RSBJ l'info del soggetto ? contenuta nell'idSubject
          // per RLAV l'info transita sul legalCode

          // else if (type === 'RLAV') {
          //   // element.methodPayment.receiver.type = 'RLAV';
          //   // element.methodPayment.receiver.idSubject = codLeg;
          // } else if (type === 'RSBJ') {
          //   // element.methodPayment.receiver.type = 'RSBJ';
          //   // element.methodPayment.receiver.idSubject = idSbj;
          //   // element.methodPayment.receiver.idSubjectPA = idSbjPA;
          // }
          // if (this.selReceiver === 'LAV') {
          //  element.methodPayment.receiver.idSubject = this.idSubjectLav; // id soggetto della PASOGGETTO
          // }
          // if (this.selReceiver === 'AGE') {
          //   element.methodPayment.receiver.type = 'RAGE';
          //   element.methodPayment.receiver.idSubject = this.policyAgency.agencyId;
          //  }
          // if (this.selReceiver === 'ANG') {
          //   element.methodPayment.receiver.idSubject = this.potPayFE.subjectAnag.idSubjectPA; // id soggetto della tabella PASOGGETTO
          // }
        }
      } else {
        if (element.generatePayment) {
          // il metodo di pagamento deve essere valorizzato
          const obstr1$ = this.translateService.translate('_CLAIMS_._SETTLEMENT');
          const obstr2$ = this.translateService.translate('_CLAIMS_._MANDATORY_DATA_MISSING');
          combineLatest([
            obstr1$,
            obstr2$,
          ]).subscribe(
              ([tr1, tr2]) => {
              if(element.generatePayment){
                this.MsgError=tr1+ '-'+ tr2;
                this.eventService.broadcastEvent('stop-loader');
              }
        })
        }
      }
    });
    if (!this.MsgError) {
      this.checkforValidity();
      this.newPaymentService.setTransferDescrFixMap(this.transferDescrFixMap);
    }
  }

  openAnag(payment: PotentialPaymentEntity) {
    this.newPaymentService.paymentIdForAddBenef = this.potPayFE.paymentList.indexOf(payment);
    const nodeId = this.authSrv.getSalePointId();
    this.newPaymentService.subjectRole = 'addressee';
    this.cardComponentService.emit({
      eventName: 'add-beneficiary',
      salePointId: nodeId
    }, this.card.id);
  }

  ismissingData() {
    let block = false;
    if (this.potPayFE.paymentList) {
      this.potPayFE.paymentList.forEach(element => {
        if (this.missingDataList[element.id]) {
          block = true;
        }
      });
    }
    return block;
  }
  // onChangeModelForm(){
  // }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChatService } from '../../chat.service';
import { ChatMessage } from '../../dto/chat-message';
import { ChatUser } from '../../dto/chat-user';

@Component({
  selector: 'claims-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss']
})
export class ChatHistoryComponent implements OnInit {

  messages;
  @Input() otherUser: ChatUser;
  @Input() loggedUser: string;
  @Input() claim: { claimNumber: string };
  @Input() potentialClaim: { reportNumber: string };
  @Input() blogMode: boolean;

  constructor(private chatService: ChatService) { }

  ngOnInit() {
    this.updateHistory();
  }

  public setRead(messages: ChatMessage[]) {
    const messagesToSetAsRead: ChatMessage[] = [];
    messages.forEach((message: ChatMessage) => {
      if (!message.read && message.recipient.userName === this.loggedUser) {
        message.read = true;
        messagesToSetAsRead.push(message);
      }
    });
    this.chatService.updateChatMessages(messagesToSetAsRead).subscribe();
  }

  public onMessageSent() {
    this.updateHistory();
  }

  private updateHistory() {
    this.chatService.getChats(this.loggedUser, this.otherUser, this.claim, this.potentialClaim, this.blogMode)
      .subscribe((response: Response) => {
        this.messages = response;
        this.setRead(this.messages);
        this.setFirstMessageOfTheDay(this.messages);
      },
        (error: Error) => console.log(error)
      );
  }

  private setFirstMessageOfTheDay(messages: ChatMessage[]) {
    let lastDate: string;
    messages.forEach((message: ChatMessage) => {
      const date = new Date(message.sendDate);
      if (date.toLocaleDateString() !== lastDate) {
        lastDate = date.toLocaleDateString();
        message.isFirstOfTheDay = true;
      }
    });
  }

}

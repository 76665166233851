<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="anag-icon-m rgi-ui-icon-hands"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>{{inputDynamicObject.descrDynObject}}</span>
    <span class="rgi-ui-btn-close" (click)="actionClose()" data-qa="close-modal"></span>
  </div>
  <div class="rgi-ui-panel-content" *ngIf="stateMgr.getState$() | async as state">
    <div *ngIf="editorMode === ANAG_DYNAMIC_OBJ_MODE.VIEW">
      <div class="rgi-ui-snackbar rgi-ui-default" *ngIf="noObjToShow">
        <span class="rgi-ui-message" translate>_ANAG_._MSG_._NO_ELEMENT_TO_SHOW_</span>
      </div>
      <rgi-rx-datatable [data]="dynamicObjectTableData" *ngIf="!noObjToShow && !isIstanceOfLimitedDynamicObj"
        [schema]="PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA" (onAction)="onActionClick($event)">
      </rgi-rx-datatable>
      <rgi-rx-datatable [data]="dynamicObjectTableData" *ngIf="!noObjToShow && isIstanceOfLimitedDynamicObj"
                        [schema]="PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA_LIM" (onAction)="onActionClick($event)">
      </rgi-rx-datatable>
      <div class="rgi-anag-flex rgi-anag-tm-1">
        <button rgi-rx-button (click)="onAddObj($event)" variant="icon" class="rgi-anag-w-100" translate>
          _ANAG_._BTN_._ADD_
        </button>
      </div>
    </div>

    <div *ngIf="editorMode == ANAG_DYNAMIC_OBJ_MODE.EDIT ||  editorMode == ANAG_DYNAMIC_OBJ_MODE.INSERT">
      <form [formGroup]="editDynaObjForm" class="rgi-ui-grid-2">
        <div *ngFor="let attribute of editableDynamicObject.dynamicAttributes" class="rgi-ui-col">
          <div [ngSwitch]="attribute.typeAttribute.codice">
            <div *ngSwitchCase="'1'">
              <rgi-rx-form-field>
                <input type="checkbox" rgiRxInput [attr.data-qa]="'rgi-anag-' + attribute.descrDynAttribute | lowercase"
              [formControlName]="'formControl'+attribute.codeDynAttribute"
              [checked]="attribute.values[0].stringValue==='1'">
            {{attribute.descrDynAttribute}}
              </rgi-rx-form-field>
          </div>
            <div *ngSwitchCase="'2'">
              <rgi-rx-form-field>
                <label rgiRxLabel>
                  {{attribute.descrDynAttribute}}
            </label>
                <input rgiRxInput type="number" [attr.data-qa]="'rgi-anag-' + attribute.descrDynAttribute | lowercase"
              [formControlName]="'formControl'+attribute.codeDynAttribute">
              </rgi-rx-form-field>
          </div>
            <div *ngSwitchCase="'3'">
              <rgi-rx-form-field>
                <label rgiRxLabel>
                  {{attribute.descrDynAttribute}}
            </label>
                <input type="number" rgiRxInput [attr.data-qa]="'rgi-anag-' + attribute.descrDynAttribute | lowercase"
              [formControlName]="'formControl'+attribute.codeDynAttribute">
              </rgi-rx-form-field>
          </div>
            <div *ngIf="(attribute.codeDynAttribute==='CF'&& this.visibilityVal !=='4') || attribute.codeDynAttribute !=='CF'">
            <div *ngSwitchCase="'4'">
              <rgi-rx-form-field>
                <label rgiRxLabel>
                  {{attribute.descrDynAttribute}}
            </label>
                <input rgiRxInput type="text" [attr.data-qa]="'rgi-anag-' + attribute.descrDynAttribute | lowercase" [formControlName]="'formControl'+attribute.codeDynAttribute">
              </rgi-rx-form-field>
          </div>
            </div>
            <div *ngSwitchCase="'5'">
              <rgi-rx-form-field>
                <label rgiRxLabel>
                  {{attribute.descrDynAttribute}}
            </label>
                <textarea rgiRxInput
              [formControlName]="'formControl'+attribute.codeDynAttribute" [attr.data-qa]="attribute.descrDynAttribute | lowercase"></textarea>
              </rgi-rx-form-field>
          </div>
            <div *ngSwitchCase="'6'">
              <rgi-rx-form-field>
                <label rgiRxLabel>
                  {{attribute.descrDynAttribute}}
            </label>
                <select rgiRxNativeSelect [formControlName]="'formControl'+attribute.codeDynAttribute" [attr.data-qa]="attribute.descrDynAttribute | lowercase">
              <option *ngFor="let enumValues of attribute.enumerationValues" [value]="enumValues.codice">
                {{enumValues.descrizione}}</option>
                </select>
              </rgi-rx-form-field>
          </div>
          <div *ngSwitchCase="'8'">
            <div class="rgi-ui-form-group">
              <div *ngIf="isAddressPresent()">
                <div>
                  <label rgiRxLabel translate><b>_ANAG_._LABEL_._ADDRESS_</b></label>
                </div>
                <div class="rgi-anag-vm-m">{{getValuedAddressLabel(attribute.values[0].addressValue)}}</div>
                <div>
                  <button type="button" tabindex="0" data-qa="anag-btn-edit" (click)="onEditAddress($event);"
                    class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-edit2 rgi-anag-text-tone">
                  </button>
                  <button type="button" (click)="onDeleteAddress($event);" data-qa="anag-btn-delete" tabindex="0"
                    class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-delete rgi-anag-text-tone">
                  </button>
                </div>
              </div>
              <div *ngIf="!isAddressPresent()">
                <div>
                  <span translate><strong>_ANAG_._LABEL_._ADDRESS_</strong></span>
                </div>
              <div class="rgi-anag-vm-s">
                <span>{{'_ANAG_._LABEL_._NO_ADDRESS_MSG_' | translate}}</span>
                <button type="button" (click)="onAddAddress($event);" data-qa="anag-btn-new" tabindex="0"
                        class="rgi-anag-hm-l rgi-ui-btn rgi-ui-btn-info " translate>_ANAG_._BTN_._NEW_</button>
              </div>
              </div>
            </div>
          </div>
            <div *ngSwitchCase="'9'">
              <rgi-rx-form-field>
                <label rgiRxLabel>
                  {{attribute.descrDynAttribute}}
            </label>
                <input rgiRxInput type="text" [attr.data-qa]="attribute.descrDynAttribute | lowercase" [formControlName]="'formControl'+attribute.codeDynAttribute">
              </rgi-rx-form-field>
          </div>
            <div *ngSwitchCase="'10'">
              <rgi-rx-form-field>
                <label rgiRxLabel>
                  {{attribute.descrDynAttribute}}
            </label>
                <input rgiRxInput type="text" [attr.data-qa]="attribute.descrDynAttribute | lowercase" [formControlName]="'formControl'+attribute.codeDynAttribute">
              </rgi-rx-form-field>
          </div>
        </div>
      </div>
   </form>
  </div>
    <div *ngIf="editorMode === ANAG_DYNAMIC_OBJ_MODE.ADDRESS">
      <rgi-anag-uma-address data-qa="dynaObjAddress" [inputAddress]="tmpAddress" (outputAddress)="updateDynaObjAddress($event)" [parentForm]="ubicationForm">
      </rgi-anag-uma-address>
    </div>
  </div>
  <rgi-rx-snackbar tag="address-tag"></rgi-rx-snackbar>
  <rgi-rx-snackbar tag="error-tag"></rgi-rx-snackbar>
  <div class="rgi-ui-panel-footer" *ngIf="editorMode === ANAG_DYNAMIC_OBJ_MODE.VIEW">
    <button class="rgi-ui-btn rgi-ui-btn-secondary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
            translate>_ANAG_._BTN_._CANCEL_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" [disabled]="editDynaObjForm.invalid"
            (click)="updateDynamicObject()"
            data-qa="anag-btn-confirm" translate>_ANAG_._BTN_._CONFIRM_
    </button>
  </div>
  <div class="rgi-ui-panel-footer" *ngIf="editorMode === ANAG_DYNAMIC_OBJ_MODE.EDIT ||  editorMode == ANAG_DYNAMIC_OBJ_MODE.INSERT">
    <button class="rgi-ui-btn rgi-ui-btn-secondary" type="button" (click)="onUndoEdit($event)" data-qa="anag-btn-cancel"
            translate>_ANAG_._BTN_._CANCEL_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button"
            (click)="onConfirmEdit($event)"
            data-qa="anag-btn-confirm" translate>_ANAG_._BTN_._CONFIRM_
    </button>
  </div>
  <div class="rgi-ui-panel-footer" *ngIf="editorMode === ANAG_DYNAMIC_OBJ_MODE.ADDRESS">
    <button class="rgi-ui-btn rgi-ui-btn-secondary" type="button" data-qa="anag-btn-cancel"(click)="onUndoAddAddress($event)"
            translate>_ANAG_._BTN_._CANCEL_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button"
            (click)="onConfirmAddAddress($event)" data-qa="anag-btn-confirm"
            translate>_ANAG_._BTN_._CONFIRM_
    </button>
  </div>
</div>

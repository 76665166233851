<lic-checkbox-accordion #accordion [toolName]="definition.description"
                        (toolCheck)="onAccordionClick($event)" [formGroup]="formGroup">
  <div class="form-group" *ngIf="showThreshold; else showdisinvestmentAmount" >
        <div class="row">
          <rgi-rx-form-field>
            <label rgiRxLabel>
              <span>{{'lic_percentage_threshold' | translate}}</span>
            </label>
            <input
              *ngIf="listOfPercentage.length === 0"
              rgiRxInput
              class="col-lg-6 form-control"
              type="number"
              formControlName="percent"
              [attr.data-qa]="'threshold' + definition.id"
              [attr.data-mod]="'threshold' + definition.id"
              [attr.data-obb]="'threshold' + definition.id"
            >
            <select
              *ngIf="listOfPercentage.length > 0"
              rgiRxNativeSelect
              formControlName="percent"
              [attr.data-qa]="'threshold' + definition.id"
              [attr.data-mod]="'threshold' + definition.id"
              [attr.data-obb]="'threshold' + definition.id">
              <option value=""></option>
              <option *ngFor="let percentageElement of listOfPercentage" [value]="percentageElement.description">
                  {{percentageElement.description}}
              </option>
            </select>
          </rgi-rx-form-field>
        </div>
  </div>
  <ng-template #showdisinvestmentAmount>
    <div class="form-group">
      <div class="row">
        <div class="col-lg-12">
          <label class="label-core" translate>lic_disinvestment_amount</label>
          <lic-required required="true"></lic-required>
        </div>
        <input
          class="col-lg-6 form-control"
          type="number"
          formControlName="disinvestmentAmount"
          [attr.data-qa]="'disinvestment_amount' + definition.id"
        >
      </div>
    </div>
  </ng-template>
</lic-checkbox-accordion>

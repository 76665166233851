import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from "@rgi/rx/ui";
import {OutboundDocumentsReference} from "../../../resources/model/common/outbound-documents-reference";

@Component({
  selector: 'rgi-outbound-documents-modal',
  templateUrl: './outbound-documents-modal.component.html'
})
export class OutboundDocumentsModalComponent implements OnModalClose{

  modalClose: EventEmitter<any> = new EventEmitter<any>();
  data: OutboundDocumentsReference;

  constructor(
    @Optional() @Inject(DIALOG_DATA) data: OutboundDocumentsReference
  ) {
    if (data) {
      this.data = data;
    }
  }
}

import {Inject, Injectable} from '@angular/core';
import {RoutingService} from '@rgi/rx/router';
import {AuthorizationsSearchService, AuthorizationsCardIntegrationService, CommonService, Authorization, mapToReIssuePolicyData, Contract} from "@rgi/authorizations-card";

@Injectable()
export class AuthorizationCardPortalIntegrationService extends AuthorizationsCardIntegrationService {


    constructor(
        protected _routingService: RoutingService,
        protected commonService: CommonService,
        protected authorizationsSearchService: AuthorizationsSearchService,
        @Inject('sessionService') protected portalSession: any,
        @Inject('parallelRouting') protected portalParallelRouting: any,
        @Inject('coreResult') protected portalCoreResult: any,
        @Inject('eventService') protected eventService: any) {
        super(_routingService);
    }

    openNewReFlowSessionIssue(authorizationDetail: Authorization) {
        this.commonService.openReProposal(authorizationDetail.proposalNumber).subscribe((response: any) => {
            if (response) {
                const req = mapToReIssuePolicyData(response, authorizationDetail);
                const id = this.portalSession.open('newReContract', 'proposalManagement', '', true);
                this.portalCoreResult.setResult(id, 'proposalManagement', req);
            }
        });
    }

    openGroupPolicySession(authorizationDetail: Authorization) {
        const idSession = this.portalSession.list().find(session => session.isActive).idSession;
        const idNavigation = this.portalSession.open('groupPolicyIssue', 'edit', '', true, null, null, idSession);
        this.portalCoreResult.setResult(idNavigation, 'edit', {
            proposalNumber: authorizationDetail.proposalNumber,
            isFromInquiry: false
        });
    }

    openPassPortalStandarSession(contractId: string) {
        // eslint-disable-next-line max-len
        this.authorizationsSearchService.getDamageProposal(contractId, true).subscribe((response: any) => {
            if (response) {
                const id = this.portalSession.open('ptfdamageProposal', 'propdata', '', true);
                this.portalCoreResult.setResult(id, 'propdata', response);
            }
        });
    }

    openNMFSession(authorizationDetail: Authorization) {
        const paramsNMF = {
            route: 'proposal',
            proposalNumber: authorizationDetail.proposalNumber,
            policyNumber: undefined,
            createCopy: false
        };

        this.portalSession.open('newMotorContract', null, '', true, paramsNMF, false);
    }

    openNewReFlowSession(authorizationDetail: Authorization) {
        this.commonService.openReProposal(authorizationDetail.proposalNumber).subscribe((response: any) => {
            if (response) {
                const req = mapToReIssuePolicyData(response, authorizationDetail);
                req.contractorSubject = req.subject;
                req.isFromExternalCard = false;
                const idSession = this.portalSession.list().find(session => session.isActive).idSession;
                const id = this.portalSession.open('newReContract', 'policyData', '', true, null, null, idSession);
                this.portalCoreResult.setResult(id, 'policyData', req);
            }
        });
    }

    openNMFMandatoryDataSession(proposalNumber, policyNumber, createCopy, route, operation) {
        const paramsNMF = {
            route: undefined,
            proposalNumber: undefined,
            policyNumber: undefined,
            createCopy: undefined,
            operation: undefined,
            loadContract: true
        };

        paramsNMF.route = 'proposal';
        if (route) {
            paramsNMF.route = route;
        }

        if (operation) {
            paramsNMF.operation = operation;
            if (operation.code === 'MOTCOV') {
                paramsNMF.loadContract = false;
            }
        }

        if (policyNumber) {
            paramsNMF.policyNumber = policyNumber;
        } else if (proposalNumber) {
            paramsNMF.proposalNumber = proposalNumber;
        }
        paramsNMF.createCopy = createCopy != null ? createCopy : false;
        this.portalSession.open('newMotorContract', null, '', true, paramsNMF, false);
    }

    openNMFAuthorizationSession(createCopy: boolean, route: string, authorizationDetail: Authorization) {
        const paramsNMF = {
            route: undefined,
            proposalNumber: authorizationDetail.proposalNumber,
            policyNumber: authorizationDetail.policyNumber,
            createCopy: createCopy != null ? createCopy : false,
            operation: undefined,
            loadContract: false,
            authorization: authorizationDetail
        };

        paramsNMF.route = 'proposal';
        if (route) {
            paramsNMF.route = route;
        }

        this.portalSession.open('newMotorContract', null, '', true, paramsNMF, false);
    }

    removeSession(routeId: any) {
        this.eventService.broadcastEvent('alertMsg', { varMsg: 'Close the current session?', confirmAlert: true, strId: 'removeSession', idSession: routeId });
    }

    modifyProposalLife(output, contract: Contract, title: string, idSession: string) {
        const cardId = this.portalSession.open('ptflifeProposal', 'anag', title, true, false, false, idSession);
        this.portalCoreResult.setResult(cardId, 'proposalFromAuth', output);
    }

    modifyVariationLife(authorizationDetail: Authorization, title: string, id: string) {
        const authCardId = this.portalSession.open('postsalesSessionCard', 'home', title, true, false, false, id);
        this.portalCoreResult.setResult(authCardId, 'authorizationId', authorizationDetail.id);
        this.portalCoreResult.setResult(authCardId, 'contractId', authorizationDetail.policyNumber);
        this.portalCoreResult.setResult(authCardId, 'causeCode', authorizationDetail.operationCode);
        this.portalCoreResult.setResult(authCardId, 'operationDescription', title);
    }

    confirmVariationLife(authorizationDetail: Authorization, contract: Contract, id: string) {
        console.log('routing to other portal cards non implemented');
    }

    goToLifeIssueResult(output, title: string, id: string) {
        const cardId = this.portalSession.open('ptflifeProposal', 'anag', title, true, false, false, id);
        this.portalCoreResult.setResult(cardId, 'isSaveFromAuth', true);
        this.portalCoreResult.setResult(cardId, 'proposalFromAuth', output);

    }

    openDefaultResolution(action, contract: Contract, authorizationDetail: Authorization) {
        console.log('routing to other cards non implemented');
    }

}

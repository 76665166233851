import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TitlesUtilityService {

  private map: Map<string, string> = new Map();

  constructor() {
    this.map.set('1', 'Arretrato');
    this.map.set('2', 'Arretrato abbinato');
    this.map.set('4', 'Incassato');
    this.map.set('8', 'Annullato');
    this.map.set('16', 'Incasso parziale');
  }

  getTitleEnumMap(): Map<string, string> {
    return this.map;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcHelpIconComponent } from './lpc-help-icon/lpc-help-icon.component';
import { LpcHelpContentComponent } from './lpc-help-content/lpc-help-content.component';

@NgModule({
    declarations: [
        LpcHelpIconComponent,
        LpcHelpContentComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        LpcHelpIconComponent,
        LpcHelpContentComponent
    ]
})
export class LpcHelpModule { }

import {RgiRxTranslations, Serialized18n} from '@rgi/rx/i18n';
import {IT} from './translations_it';
import {EN} from './translations_en';
import {ES} from './translations_es';
export function load_contract_consultation_i18n_IT() {
  return new Promise<Serialized18n>(resolve => {
    resolve(IT);
  });
}

export function load_contract_consultation_i18n_EN() {
  return new Promise<Serialized18n>(resolve => {
    resolve(EN);
  });
}

export function load_contract_consultation_i18n_ES() {
  return new Promise<Serialized18n>(resolve => {
    resolve(ES);
  });
}
export const TRANSLATIONS_CONTRACT_CONSULTATION: RgiRxTranslations = [
  {
    load: load_contract_consultation_i18n_IT,
    locale: 'it'
  },
  {
    load: load_contract_consultation_i18n_EN,
    locale: 'en'
  },
  {
    load: load_contract_consultation_i18n_ES,
    locale: 'es'
  }
];

import {Inject, Injectable} from '@angular/core';
import {share} from 'rxjs/operators';
import {PolicyGenericDto} from '../../../models/pc-portfolio-models/policy-generic-dto';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';
import {HttpClient} from '@angular/common/http';
import {CommonResourceService} from '../../re-issue-address-service/common-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioCommonResourceService extends CommonResourceService {
  private CONTRACT_PATH = 'contract/';
  private PROPOSALS_PATH = 'proposals/';

  private SECOND_RISK_PATH = 'second-risk';
  private apiPcPortfolioConf: any;

  constructor(
    protected httpClient: HttpClient,
    @Inject('environment') protected apiConf: any) {
    super(httpClient, apiConf);
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;
  }

  searchCommonRiskPolicy(proposalId: string, policyDto: PolicyGenericDto) {
    return this.httpClient.put<any>(
      this.getCommonRiskPolicyUri(proposalId),
      policyDto
    ).pipe(
      share()
    );
  }
  deleteCommonRiskPolicy(proposalId: string, policyNumber: string) {
    return this.httpClient.delete<any>(
      this.deleteCommonRiskPolicyUri(proposalId, policyNumber)
    ).pipe(
      share()
    );
  }
  deleteAllCommonRiskPolicies(proposalId: string) {
    return this.httpClient.delete<any>(
      this.deleteAllCommonRiskPoliciesUri(proposalId)
    ).pipe(
      share()
    );
  }
  searchLinkedPolicy(proposalId: string, policyDto: PolicyGenericDto) {
    return this.httpClient.put<any>(
      this.getLinkedPolicyUri(proposalId),
      policyDto
    ).pipe(
      share()
    );
  }
  deleteLinkedPolicy(proposalId: string, policyNumber: string) {
    return this.httpClient.delete<any>(
      this.deleteLinkedPolicyUri(proposalId, policyNumber)
    ).pipe(
      share()
    );
  }
  deleteAllLinkedPolicies(proposalId: string) {
    return this.httpClient.delete<any>(
      this.deleteAllLinkedPoliciesUri(proposalId)
    ).pipe(
      share()
    );
  }
  searchComplementaryPolicy(proposalId: string, policyDto: PolicyGenericDto) {
    return this.httpClient.put<any>(
        this.getComplementaryPolicyUri(proposalId),
        policyDto
    ).pipe(
        share()
    );
  }

  deleteComplementaryPolicy(proposalId: string, policyNumber: string) {
    return this.httpClient.delete<any>(
        this.deleteComplementaryPolicyUri(proposalId, policyNumber)
    ).pipe(
        share()
    );
  }

  deleteAllComplementaryPolicies(proposalId: string) {
    return this.httpClient.delete<any>(
      this.deleteAllComplementaryPoliciesUri(proposalId)
    ).pipe(
      share()
    );
  }

  putSecondRiskPolicy(proposalId: string, policy: PolicyGenericDto) {
    return this.httpClient.put<PolicyGenericDto>(
      this.getContractBaseUrl(proposalId) + `/${this.SECOND_RISK_PATH}`,
      policy
    ).pipe(
      share()
    );
  }

  deleteSecondRiskPolicy(proposalId: string, objectId: number) {
    const url = this.getContractBaseUrl(proposalId) + `/${this.SECOND_RISK_PATH}/${objectId}`;
    return this.httpClient.delete(url).pipe(
      share()
    );
  }

  deleteAllSecondRiskPolicies(proposalId: string) {
    const url = this.getContractBaseUrl(proposalId) + `/second-risk-all`;
    return this.httpClient.delete(url).pipe(
      share()
    );
  }

  private getContractBaseUrl(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      proposalId;
  }

  private getCommonRiskPolicyUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      proposalId +
      '/policies-risk';
  }
  private deleteCommonRiskPolicyUri(proposalId: string, policyNumber: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      proposalId +
      '/policies-risk/' +
      policyNumber;
  }
  private deleteAllCommonRiskPoliciesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/remove-all-policies-risk';
  }
  private getLinkedPolicyUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      proposalId +
      '/linked-policies';
  }
  private deleteLinkedPolicyUri(proposalId: string, policyNumber: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.CONTRACT_PATH +
      proposalId +
      '/linked-policies/' +
      policyNumber;
  }
  private deleteAllLinkedPoliciesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/remove-all-linked-policies';
  }

  private getComplementaryPolicyUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
        this.apiPcPortfolioConf.v2 +
        this.apiPcPortfolioConf.path +
        this.CONTRACT_PATH +
        proposalId +
        '/complementary-policies';
  }

  private deleteComplementaryPolicyUri(proposalId: string, policyNumber: string): string {
    return this.apiConf.api.portal.path +
        this.apiPcPortfolioConf.v2 +
        this.apiPcPortfolioConf.path +
        this.CONTRACT_PATH +
        proposalId +
        '/complementary-policies/' +
        policyNumber;
  }

  private deleteAllComplementaryPoliciesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/remove-all-complementary-policies';
  }

}

import { Component, Inject, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardComponentService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { sample } from 'rxjs/operators';

import { UtilityService } from '../../external-portfolio/utility.service';
import { AutosoftInfoEntity } from '../dto/autosoft-info-entity';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-autosoft-search',
  templateUrl: './autosoft-search.component.html',
  styleUrls: ['./autosoft-search.component.scss']
})
export class AutosoftSearchComponent implements OnDestroy, OnInit, RoutableComponent {
  @Input() card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  public errorMessage: string = '';
  public errore: boolean;
  public today: NgbDate;
  public searchForm: UntypedFormGroup;

  constructor(
    @Inject('sessionService') private session: any,
    private cardComponentService: CardComponentService,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    public formBuilder: UntypedFormBuilder,
    private utilytyServ: UtilityService,
    private modalService: NgbModal,
    public translateService: RgiRxTranslationService) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
   }
  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.utilytyServ.setAutosoftResult(null, null);
    this.searchForm = this.formBuilder.group({
      claimNumber: [],
      assignmentNumber: [],
      event: [],
      dateFrom: [],
      dateTo: [],
      inError: [],
      typeSearch: []
    });
  }

  searchTransmission() {
    this.searchForm.controls.typeSearch.setValue('1');
    this.errore = false;
    this.errorMessage = '';
    this.utilytyServ.setAutosoftResult(null, null);
    if (!this.searchForm.controls.claimNumber.value &&
       !this.searchForm.controls.assignmentNumber.value &&
       !this.searchForm.controls.dateFrom.value &&
       !this.searchForm.controls.dateTo.value ) {
      this.errore = true;
      this.translateService.translate('_CLAIMS_._MESSAGE_._SPECIFY_CRITERIA').subscribe(res => {
        this.errorMessage = res;
      }
      )
    } else {
      this.eventService.broadcastEvent('start-loader');
      this.utilytyServ.getAutosoftTransmission(
      this.searchForm.controls.claimNumber.value,
      this.searchForm.controls.assignmentNumber.value,
      JSON.stringify(this.searchForm.controls.dateFrom.value),
      JSON.stringify(this.searchForm.controls.dateTo.value),
      this.searchForm.controls.inError.value
      ).subscribe(
        (res: AutosoftInfoEntity[]) => {
          console.log('getAutosoftTransmission --> ', res);
          // console.log(res);
          this.eventService.broadcastEvent('stop-loader');
          if (res.length > 0) {
            this.utilytyServ.setAutosoftResult(res, this.searchForm);
            this.session.open('claimsAutosoftBoard', 'home', '', true);
          } else {
            this.errore = true;
            this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(res=> {
              this.errorMessage = res;
            }
            )
          }
        },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res=> {
            this.errorMessage = res;
          }
          )
        }
      );
    }
  }

  searchEvents() {
    this.searchForm.controls.typeSearch.setValue('3');
    this.errore = false;
    this.errorMessage = '';
    if (!this.searchForm.controls.event.value) {
      this.errore = true;
      this.translateService.translate('_CLAIMS_._MESSAGE_._SPECIFY_EVENT').subscribe(res=> {
        this.errorMessage = res;
      }
      )
    } else {
      this.eventService.broadcastEvent('start-loader');
      this.utilytyServ.getAutosoftEvents(
      this.searchForm.controls.event.value,
      this.searchForm.controls.assignmentNumber.value,
      JSON.stringify(this.searchForm.controls.dateFrom.value),
      JSON.stringify(this.searchForm.controls.dateTo.value)
      ).subscribe(
        (res: AutosoftInfoEntity[]) => {
          console.log('getAutosoftEvents --> ', res);
          // console.log(res);
          this.eventService.broadcastEvent('stop-loader');
          if (res.length > 0) {
            this.utilytyServ.setAutosoftResult(res, this.searchForm);
            this.session.open('claimsAutosoftBoard', 'home', '', true);
          } else {
            this.errore = true;
            this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(res=> {
              this.errorMessage = res;
            }
            )
          }
        },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res=> {
            this.errorMessage = res;
          }
          )
        }
      );
    }
  }

  searchNoTransmission() {
    this.searchForm.controls.typeSearch.setValue('2');
    this.errore = false;
    this.errorMessage = '';
    if (!this.searchForm.controls.claimNumber.value) {
      this.errore = true;
      this.translateService.translate('_CLAIMS_._MESSAGE_._SPEFCIFY_CLAIM_NUMBER').subscribe(res=> {
        this.errorMessage = res;
      }
      )
    } else {
      this.eventService.broadcastEvent('start-loader');
      this.utilytyServ.getAutosoftNoTransmission(
      this.searchForm.controls.claimNumber.value,
      this.searchForm.controls.assignmentNumber.value,
      JSON.stringify(this.searchForm.controls.dateFrom.value),
      JSON.stringify(this.searchForm.controls.dateTo.value)
      ).subscribe(
        (res: AutosoftInfoEntity[]) => {
          console.log('getAutosoftNoTransmission --> ', res);
          // console.log(res);
          this.eventService.broadcastEvent('stop-loader');
          if (res.length > 0) {
            this.utilytyServ.setAutosoftResult(res, this.searchForm);
            this.session.open('claimsAutosoftBoard', 'home', '', true);
          } else {
            this.errore = true;
            this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(res => {
              this.errorMessage = res;
            }
            )
          }
        },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res => {
            this.errorMessage = res;
          }
          )
        }
      );
    }

  }

  clearFilter() {
    this.errore = false;
    this.errorMessage = '';
    this.searchForm = this.formBuilder.group({
      claimNumber: [],
      assignmentNumber: [],
      event: [],
      dateFrom: [],
      dateTo: [],
      inError: [],
      typeSearch: []
    });

  }
}

import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AnagStatelessOpDetailService} from './anag-stateless-op-detail.service';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {of} from 'rxjs';
import {AnagConfiguration} from '../../anag-model/anag-domain/anag-configuration';
import {
  KeyDataModalComponent
} from '../../anag-components/party-create-key-data/key-data-modal/key-data-modal.component';
import {AnagStateManagerKeyData} from '../key-data/anag-state-manager-key-data';
import {take} from 'rxjs/operators';
import {KEY_DATA_UPDATE, KeyDataUpdateEvent} from '../../anag-events/anag-event';
import {RgiRxEventService} from '@rgi/rx';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {ModalService} from '@rgi/rx/ui';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagConfigService} from '../../anag-resources/anag-config.service';

export class AnagStateDetail extends State {
  parties: Array<AnagApiParty>;
  party: AnagApiParty;
  isModal: boolean;
  isUmaPartyKeyEnable: boolean;
  isUmaPartyNumberEnable: boolean;
  isUmaPartyHomonymyEnable: boolean;
  isUmaPartyCFDuplicate: boolean;
  isUmaPartyGroupCompanies: boolean;
  errors: Array<string>;
  partyConfiguration: AnagConfiguration;
  redirectToMainFlow = false;
}

export class AnagStateManagerDetail extends RgiRxLocalStateManager<AnagStateDetail> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelessOps: AnagStatelessOpDetailService,
    protected eventService: RgiRxEventService,
    protected anagIntegrationService: AnagIntegrationService,
    protected modalService: ModalService,
    protected anagStatelessService: AnagStatelessService,
    protected configService: AnagConfigService,
    protected i18nService: RgiRxTranslationService
  ) {
    super(activeRoute, stateStoreService);
    anagIntegrationService.setPartyInContainerCache((activeRoute.getRouteData() as {party}).party, activeRoute.id);
    this.initDetail();
  }


  protected initDetail() {
    const newDetailState = new AnagStateDetail(this.activeRoute.key);
    this.updateState$(this.statelessOps.initDetail$(of(newDetailState), this.activeRoute));
  }

  get isPartyPhysical() {
    return this.getCurrentState().party.personType.codice === '1';
  }

  actionBack() {
    const currentState = this.getCurrentState();
    if (currentState.parties && currentState.parties.length > 0) {
      this.statelessOps.goToResultPage(this.activeRoute);
    } else {
      this.anagIntegrationService.backToHome(this.activeRoute);
    }
  }

  actionCreateNewParty(stateMgrKeyData: AnagStateManagerKeyData) {
    const clonedState = this.anagStatelessService.deepClone(this.getCurrentState());
    clonedState.party = new AnagApiParty();
    this.openkeyDataModal(stateMgrKeyData, clonedState.party);
  }

  actionEditParty(stateMgrKeyData: AnagStateManagerKeyData) {
    const state = this.getCurrentState();
    if (state.isUmaPartyHomonymyEnable) {
      this.openkeyDataModal(stateMgrKeyData, state.party);
    } else {
      this.anagIntegrationService.openEditSubject(this.activeRoute.getRouteData<AnagRouteData>());
    }
  }

  goToFullDetail() {
    const currentState = this.getCurrentState();
    this.updateState$(this.statelessOps.goToFullDetailPage$(of(currentState), this.activeRoute));
  }

  setTitle() {
    const name = this.getCurrentState().party.nominative;
    this.i18nService.translate('_ANAG_TITLE_PARTY_DETAIL_').subscribe(title => {
      this.anagIntegrationService.changeTitle(this.activeRoute.id, `${title} ${name}`);
    }).unsubscribe();
  }

  openkeyDataModal(stateMgrKeyData, party) {
    const keyDataModal = this.modalService.openComponent(KeyDataModalComponent, party, [
      {
        provide: AnagStateManagerKeyData,
        useValue: stateMgrKeyData
      }
    ]);
    keyDataModal.modal.enableClickBackground = false;
    keyDataModal.modal.onClose.asObservable().pipe(take(1));
    this.eventService.listen<KeyDataUpdateEvent>(KEY_DATA_UPDATE).pipe(take(1)).subscribe(eventInstance => {
      this.anagIntegrationService.openEditSubject(eventInstance.event);
    });
  }
}

import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { QuestionnairesManagerService } from '../../services/questionnaires-manager.service';

@Component({
  selector: 'lib-questionnaire-item',
  templateUrl: './questionnaire-item.component.html',
  styleUrls: ['./questionnaire-item.component.css']
})
export class QuestionnaireItemComponent {
  @Input() quest;
  @Input() isReadOnly: boolean;
  @Output() selectedQuest = new EventEmitter<void>();
  @Output() getQuest = new EventEmitter<string>();
  @Input() loader = false;

  constructor(
    @Optional() private questionnairesManagerService: QuestionnairesManagerService,
  ) {

  }

  selectQuestionnaire() {
    if ( this.questionnairesManagerService ) {
      this.getQuest.emit('startLoader');
      this.selectedQuest.emit(this.quest);
      this.questionnairesManagerService.getSelectedQuest(this.quest);
    }
  }

  emptyQuestionnaire() {
    console.log(this.quest);
    this.questionnairesManagerService.clearQuestionnaire(this.quest);
    console.log('Puuulito!');
  }

}

import {RgiRxRouterOptions, ROUTES, Routes} from '../router.api';
import {Provider} from '@angular/core';

/**
 * @description provide the routes to the application as a standalone api.
 * @param config the routes to register
 * @param opts the options for routes registration
 */
export function rgiRxRouterProvideRoutes(config: Routes, opts?: RgiRxRouterOptions): Provider {
  return {
    provide: ROUTES, useValue: opts ? {routes: config, options: opts} : config, multi: true
  };
}

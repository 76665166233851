import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { ReIssueRefactorService } from './re-issue-refactor.service';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';


@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfollioBondsResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;
  }


  putBonds(proposalId: string, bondData) {
    return this.http.put<any>(
      this.getBondUri(proposalId),
      bondData
    ).pipe(
      share()
    );
  }

  getBondUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/bond';
  }

}

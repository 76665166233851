import {TableSchema} from '@rgi/rx/ui';

export const RATING_HISTORY_LIST_MODAL_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'rating',
      title: '_ANAG_._LABEL_._RATING_'
    },
    {
      name: 'ratingPeriod',
      title: '_ANAG_._LABEL_._RATING_PERIOD_',
      format: {
        pipe: 'date',
        args: ['dd/MM/yyyy']
      }
    },
    {
        name: 'user',
        title: '_ANAG_._LABEL_._RATING_USER_',
    },
    {
        name: 'origin',
        title: '_ANAG_._LABEL_._RATING_ORIGIN_',
    }
  ],
  header: ['rating', 'ratingPeriod', 'user', 'origin']
};

import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RgiRxQualityAssuranceModule } from '@rgi/rx';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { RgiRxFormElementsModule } from '../form-elements/rgi-rx-form-elements.module';
import { RgiRxPaginatorModule } from '../paginator/rgi-rx-paginator.module';
import { RgiRxTooltipModule } from '../tooltip/rgi-rx-tooltip.module';
import { RgiRxCell, RgiRxCellDef, RgiRxColumnDef, RgiRxFooterCell, RgiRxFooterCellDef, RgiRxHeaderCell, RgiRxHeaderCellDef } from './cell-directives';
import { RGI_RX_DATA_TABLE_CONFIG, RGI_RX_DATA_TABLE_DEFAULT_CONFIG } from './data-table-api';
import { RgiRxDataTableCellDirective } from './rgi-rx-data-table-cell.directive';
import { RgiRxDatatableInputSortDirective } from './rgi-rx-datatable-input-sort.directive';
import { RgiRxDatatableComponent } from './rgi-rx-datatable/rgi-rx-datatable.component';
import { RgiRxTableHeaderSortComponent } from './rgi-rx-table-header-sort/rgi-rx-table-header-sort.component';
import { RgiRxTableComponent } from './rgi-rx-table/rgi-rx-table.component';
import { RgiRxFooterRow, RgiRxFooterRowDef, RgiRxHeaderRow, RgiRxHeaderRowDef, RgiRxRow, RgiRxRowDef } from './row-directives';
import { RGI_RX_DATA_TABLE_PIPE_PROVIDERS } from './table-providers';
import { RGI_RX_TABLE_TRANSLATIONS } from './table.i18n';

const DECLARATIONS = [
  RgiRxTableComponent,
  RgiRxDatatableComponent,
  RgiRxCell,
  RgiRxFooterCell,
  RgiRxHeaderCell,
  RgiRxColumnDef,
  RgiRxFooterCellDef,
  RgiRxHeaderCellDef,
  RgiRxCellDef,
  RgiRxRow,
  RgiRxFooterRow,
  RgiRxHeaderRow,
  RgiRxRowDef,
  RgiRxFooterRowDef,
  RgiRxHeaderRowDef,
  RgiRxDataTableCellDirective
];


@NgModule({
  declarations: [...DECLARATIONS, RgiRxDatatableInputSortDirective, RgiRxTableHeaderSortComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    PortalModule,
    FormsModule,
    RgiRxPaginatorModule,
    RgiRxQualityAssuranceModule,
    RgiRxI18nModule.forRoot(RGI_RX_TABLE_TRANSLATIONS),
    RgiRxQualityAssuranceModule,
    RgiRxTooltipModule,
    RgiRxFormElementsModule
  ],
  providers: [
    ...RGI_RX_DATA_TABLE_PIPE_PROVIDERS,
    { provide: RGI_RX_DATA_TABLE_CONFIG, useValue: RGI_RX_DATA_TABLE_DEFAULT_CONFIG }
  ],
  exports: [DECLARATIONS]
})
/**
 * UI Table module exposes table components and services
 * @since 0.3.1
 */
export class RgiRxTableModule {
}

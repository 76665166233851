import {Component, OnInit} from '@angular/core';
import {PncPsalesSummaryState} from '../../resources/states/summary-state';
import {PncPsalesSummaryStateManager} from '../../state-managers/summary/summary-state-manager.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {RgiPncPostsalesStepComponent} from '../../api/rgi-pnc-postsales-step.component';
import {FormService} from '../../services/form.service';

@Component({
  selector: 'rgi-pnc-psales-summary',
  templateUrl: './summary.component.html'
})
export class PncPsalesSummaryComponent extends RgiPncPostsalesStepComponent<PncPsalesSummaryState> implements OnInit {

  public state: PncPsalesSummaryState;
  public summaryForm = new UntypedFormGroup({notesField: new UntypedFormControl(undefined, {updateOn: 'blur'})});

  constructor(
    private stateMgr: PncPsalesSummaryStateManager,
    formService: FormService
  ) {
    super(stateMgr, formService);
  }

  ngOnInit() {
    this.stateMgr.getState$().subscribe((st: PncPsalesSummaryState) => {
      this.state = st;
      this.summaryForm.get('notesField').setValue(st.notes, {emitEvent: false});
    });

    this.summaryForm.get('notesField').valueChanges.pipe(distinctUntilChanged()).subscribe(note => {
      this.stateMgr.updateNotes$(note);
    });
  }

  continue() {
    this.stateMgr.actionContinue();
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ApiPremiumDetail} from '../../models/api-models/api-premium-detail';
import {QuotationService} from '../quotation.service';
import {CommissionStatus} from '../commissions/status';
import {CommissionsService} from '../commissions/commissions.service';

import {ApiContract} from '../../models/api-models/api-contract';
import {ProposalService} from '../../proposal.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'mic-quotation-controls',
  templateUrl: './quotation-controls.component.html',
  styleUrls: ['./quotation-controls.component.scss']
})
export class QuotationControlsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  annualPremiumValue: ApiPremiumDetail;
  @Input()
  premiumInstallment: ApiPremiumDetail;
  @Input()
  showLPSTaxes = false;
  @Input()
  showContributionAndTaxesMenu = true;
  @Input()
  showWarrantiesSummaryModal = true;
  @ViewChild('premiumElement')
  premiumElement: ElementRef<HTMLDivElement>;
  isPremiumElementFixedInPage = false;

  showViewBoxesMenu = false;

  @Input()
  recalculateAvailable: boolean;

  @Output()
  recalculate = new EventEmitter<any>();

  @Output()
  unitsViewMode: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  showPremiumDetails: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showContributionsAndTaxes: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showCommissions: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showWarrantiesSummary: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showDiscounts: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showLPSTaxesModal: EventEmitter<any> = new EventEmitter<any>();

  discountsEnabled = false;
  commissionsVisible = false;

  subscriptions: Subscription = new Subscription();

  constructor(
    protected quotationService: QuotationService,
    protected commissionService: CommissionsService,
    protected proposalService: ProposalService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.premiumInstallment || changes.annualPremiumValue ) {
      if ((changes.premiumInstallment && !changes.premiumInstallment.currentValue) ||
        (changes.annualPremiumValue && !changes.annualPremiumValue.currentValue)) {
        this.discountsEnabled = false;
      } else {
        this.init();
      }
    }
  }

  ngOnInit() {

    const apiContract: ApiContract = this.proposalService.getApiContract();
    if (apiContract && this.proposalService.getContractId()) {
      this.init();
    }

    const newContractSubscription = this.proposalService
      .getNewContractSignal()
      .subscribe(data => {
        this.init();
      });
    this.subscriptions.add(newContractSubscription);

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected init() {
    this.discountsEnabled = false;
    this.quotationService.getDiscounts().subscribe(
      (discounts) => {
        discounts.forEach(
          (discount) => {
            if (discount.flex1Enable || discount.flex2Enable) {
              this.discountsEnabled = true;
            }
          }
        );
      }
    );

    this.commissionService.getCommissionsStatus().subscribe(
      (status) => {
        this.commissionsVisible = (status === CommissionStatus.VISIBLE_AND_EDITABLE
          || status === CommissionStatus.VISIBLE_NOT_EDITABLE);
      }
    );
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.premiumElement) {
      if (window.pageYOffset > this.premiumElement.nativeElement.offsetTop) {
        this.isPremiumElementFixedInPage = true;
      } else {
        this.isPremiumElementFixedInPage = false;
      }
    }
  }

  recalculatePremium(event) {
    this.recalculate.emit(event);
  }

  viewBoxUnits() {
    this.unitsViewMode.emit('BOX');
    this.showViewBoxesMenu = false;
  }

  viewListUnits() {
    this.unitsViewMode.emit('LIST');
    this.showViewBoxesMenu = true;
  }

  openPremiumDetailsModal() {
    this.showPremiumDetails.emit();
  }

  openContributionsAndTaxesModal() {
    this.showContributionsAndTaxes.emit();
  }

  openCommissionsModal() {
    this.showCommissions.emit();
  }

  openWarrantiesSummaryModal() {
    this.showWarrantiesSummary.emit();
  }

  openLPSTaxesModal() {
    this.showLPSTaxesModal.emit();
  }

  openDiscountsModal() {
    this.showDiscounts.emit();
  }

}

<form [formGroup]="modalUploadForm">
  <rgi-rx-panel class="rgi-ui-panel-container">
    <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
      <div translate>_GP_._BTN_._INCLUDE_APPLICATION_</div>
    </rgi-rx-panel-header>

    <div class="rgi-ui-title-2" translate>_GP_._TITLE_._MOD_INCLUSION_</div>
    <rgi-rx-button-toggle formControlName="choiceUpload">
      <button rgi-rx-button rgiRxValueOption [value]="'upload'" *ngIf="showUploadFileBtn"
              data-qa="rgi-gp-btn-upload" translate>_GP_._BTN_._UPLOAD_FILE_
      </button>
      <button rgi-rx-button rgiRxValueOption [value]="'startInclusionFlow'"
              data-qa="rgi-gp-btn-new-inclusion" translate>_GP_._BTN_._ADD_NEW_INCLUSION_
      </button>
    </rgi-rx-button-toggle>
    <div *ngIf="modalUploadForm.controls.choiceUpload.value === 'upload' && showUploadFileBtn; else startNewFlow">
        <span class="rgi-ui-icon-document gp-font-size-30 rgi-gp-pt-l rgi-gp-pb-s rgi-gp-font-bold">
          <span class="rgi-ui-text-1 rgi-ui-info rgi-gp-pl-s"
                translate>_GP_._LABEL_._APPLICATION_DOCUMENT_</span>
        </span>
      <div>
        <div class="rgi-ui-title-2" rgiRxLabel translate>_GP_._TITLE_._SELECT_UPLOAD_DOCUMENT_</div>
        <rgi-rx-input-file data-qa="rgi-gp-file-input" formControlName="fileField" [accept]="['.csv', '.xlsx']">
        </rgi-rx-input-file>
      </div>
    </div>
    <ng-template #startNewFlow>
      <div *ngIf="dataInclusion.fields; else showContinue">
        <ng-container *ngIf="dataInclusion.policyTypeCluster">
          <div class="rgi-gp-flex-row">
            <div class="rgi-gp-field-container">
              <div class="rgi-gp-field">
                <rgi-rx-form-field>
                  <label rgiRxLabel>
                    <span>{{dataInclusion.assetField.label}}</span>
                  </label>
                  <select rgiRxNativeSelect formControlName="ASSET_CODE" data-qa="ASSET_CODE">
                    <option [value]="null" disabled></option>
                    <option *ngFor="let asset of dataInclusion.assetField.allowedValues" [value]="asset.code">
                      {{asset.description}}
                    </option>
                  </select>
                </rgi-rx-form-field>
              </div>
            </div>
          </div>
          <rgi-rx-datatable *ngIf="clusterListTableData" [data]="clusterListTableData"
                            [schema]="CLUSTER_LIST_APPINCLUSION_TABLE_SCHEMA"
                            [selectable]="false">
            <ng-template [rgiRxDataTableCell]="'clusterSelection'" let-ctx>
              <rgi-rx-form-field>
                <input type="radio" [value]="ctx.row.code" rgiRxInput formControlName="CLUSTER_CODE">
              </rgi-rx-form-field>
            </ng-template>
            <ng-template [rgiRxDataTableCell]="'extDescr'" let-ctx>
              <div class="rgi-ui-tooltip">
                <div class="rgi-gp-nowrap-text">{{ctx.row.extDescr}}</div>
                <span class="rgi-ui-tooltip-text" style="max-width: 400px">{{ctx.row.extDescr}}</span>
              </div>
            </ng-template>
          </rgi-rx-datatable>
        </ng-container>
        <rgi-gp-group-policy-fields [policyDataFields]="dataInclusion.fields"
                                    [parentForm]="modalUploadForm"
                                    (fieldUpdate)="onPolicyDataUpdate($event)">
        </rgi-gp-group-policy-fields>
        <ng-container *ngIf="showSpecialPlate">
          <rgi-rx-form-field>
            <label rgiRxLabel>
              <span translate>_GP_._LABEL_._SPECIAL_PLATE_</span>
            </label>
            <rgi-rx-switch formControlName="specialPlate" data-qa="specialPlate"></rgi-rx-switch>
          </rgi-rx-form-field>
        </ng-container>
      </div>
      <ng-template #showContinue class="rgi-ui-text-4 rgi-ui-default message-inclusion-application">
        <div data-qa="rgi-gp-add-inclusion-msg" translate>_GP_._LABEL_._CLICK_CONFIRM_ADD_INCLUSION_</div>
      </ng-template>
    </ng-template>

    <rgi-gp-group-policy-errors-component
      *ngIf="!!errors && !!errors.length"
      [errors]="errors">
    </rgi-gp-group-policy-errors-component>

    <rgi-rx-panel-footer class="rgi-ui-panel-footer">
      <button rgiRxButton [color]="'secondary'" data-qa="rgi-gp-btn-cancel"
              (click)="modalClose.emit()" translate>{{'_GP_._BTN_._CLOSE_'}}
      </button>
      <button rgiRxButton [color]="'primary'" data-qa="rgi-gp-btn-confirm"
              [disabled]="modalUploadForm.controls.choiceUpload.value === 'upload' &&
              (!modalUploadForm.controls.fileField.value || modalUploadForm.controls.fileField.invalid)"
              (click)="emitEvent()" translate>{{'_GP_._BTN_._CONFIRM_'}}
      </button>
    </rgi-rx-panel-footer>
  </rgi-rx-panel>
</form>



/* tslint:disable:variable-name */
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FeasibleVariationCheck} from '../models/api-models/api-feasible-variation-check';
import {VariationDates} from '../models/api-models/api-variation-dates';
import {ActionOperation, PortalAction} from '../models/api-models/api-action-operation';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class AmendmentService {
  private baseApiUrl: any;
  private baseApiUrlV2: any;
  private portfolioPolicyEndpoint: any;
  private _flowOriginPolicyNumber: string;
  private _isAmendment = false;
  private _resourceId: string;
  private _appendixNumber: string;
  protected httpClient: HttpClient;
  private motorPostsalesEntpoint: string;
  private _flowOriginAction: PortalAction;

  constructor(protected micHttpClient: MicHttpService,
              @Inject('environment') environment: any,
              protected translate: TranslateService) {
      const portfolioServicePath = environment.api.portal.nmf;

      this.httpClient = this.micHttpClient.getHttpClient();

      this.baseApiUrl  = (portfolioServicePath !== null && portfolioServicePath !== undefined)
        ? environment.api.portal.host + environment.api.portal.path + portfolioServicePath
        : environment.api.portal.host + environment.api.portal.path;

      this.baseApiUrlV2 = (portfolioServicePath !== null && portfolioServicePath !== undefined)
        ? environment.api.portal.host + environment.api.portal.path + portfolioServicePath
        : environment.api.portal.host + environment.api.portal.path + '/v2';

      this.portfolioPolicyEndpoint = this.baseApiUrlV2 + '/portfolio/policy';
      this.motorPostsalesEntpoint = this.baseApiUrlV2 + '/motor-postsales';
  }

  public preliminaryCheck(policyNumber: string, operationType: string, operationCode: string) {
    // /motor-postsales/policies/{policyNumber}/operations/variations/{operationType}/{operationCode}/drafts
    // const path = this.portfolioPolicyEndpoint + '/' + policyNumber + '/operation/' + operationCode + '/preliminarycheck';
    const path = this.motorPostsalesEntpoint + '/policies/' + policyNumber + '/operations/variations/' + operationType + '/' + operationCode + '/drafts';
    return this.httpClient.post<FeasibleVariationCheck>(path, {});
  }

  public getPolicyDates(policyNumber: string, resourceId: string, operationType: string, operationCode: string) {
    const path = this.motorPostsalesEntpoint + '/policies/' + policyNumber + '/operations/variations/' + operationType + '/' + operationCode + '/drafts/'+ resourceId + '/dates';
    return this.httpClient.get<VariationDates>(path);
  }

  public setPolicyDates(policyNumber: string, resourceId: string, operationType: string, operationCode: string, operationDates: VariationDates) {
    const path = this.motorPostsalesEntpoint + '/policies/' + policyNumber + '/operations/variations/' + operationType + '/' + operationCode + '/drafts/'+ resourceId + '/dates';
    return this.httpClient.put<any>(path, operationDates);
  }

  public saveAmendment(policyNumber: string, resourceId: string, operationType: string, operationCode: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const path = this.motorPostsalesEntpoint + '/policies/' + policyNumber + '/operations/variations/' + operationType + '/' + operationCode + '/drafts/'+ resourceId + '/execution';
    return this.httpClient.post<any>(path, {}, options);
  }

  public createDraftFromAuthorizationId(policyNumber: string, operationType: string, operationCode: string, authorizationId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const path = this.motorPostsalesEntpoint + '/policies/' + policyNumber + '/operations/variations/' + operationType + '/' + operationCode + '/authorizations/' + authorizationId + '/drafts';
    return this.httpClient.post<any>(path, {}, options);
  }

  get flowOriginAction(): PortalAction {
    return this._flowOriginAction;
  }

  set flowOriginAction(flowOriginAction: PortalAction) {
    this._flowOriginAction = flowOriginAction;
  }

  get flowOriginOperation(): ActionOperation {
    return this._flowOriginAction.operation;
  }

  get flowOriginPolicyNumber(): string {
    return this._flowOriginPolicyNumber;
  }

  set flowOriginPolicyNumber(flowOriginPolicyNumber: string) {
    this._flowOriginPolicyNumber = flowOriginPolicyNumber;
  }

  get isAmendment(): boolean {
    return this._isAmendment;
  }

  set isAmendment(isAmendment: boolean) {
    this._isAmendment = isAmendment;
  }

  get resourceId(): string {
    return this._resourceId;
  }

  set resourceId(resourceId: string) {
    this._resourceId = resourceId;
  }

  get appendixNumber(): string {
    return this._appendixNumber;
  }

  set appendixNumber(appendixNumber: string) {
    this._appendixNumber = appendixNumber;
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {SSOInterceptor} from "./sso.interceptor.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ {provide: HTTP_INTERCEPTORS, useClass: SSOInterceptor, multi: true}]
})
class SSOModule {
}

export {
  SSOModule,
  SSOInterceptor
}

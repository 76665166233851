import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {ContractNumber} from '../../models/contract-number';

@Injectable({
  providedIn: 'root'
})
export class ReIssueSummaryResourceService {
  private PROPOSAL_PATH = '/proposal';

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
  }

  put(resourceId: string, node: any): Observable<ContractNumber> {
    return this.http.put<ContractNumber>(this.putProposalUri(resourceId, node.code), {}).pipe(
      share()
    );
  }

  protected putProposalUri(resourceId: string, node: string): string {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + resourceId + this.PROPOSAL_PATH + '?authenticationNodeCode=' + node;
  }
}

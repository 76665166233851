import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Authorization, AuthorizationRequest } from '../models/authorization.model';
import { PV_TOKEN } from '../models/consts/lpc-consts';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected authorizationsEndpoint;
  protected baseApiUrl: string;
  protected baseApiUrlV2: string;
  private restBaseContractsUrl = 'life/contracts/';

  constructor(protected http: HttpClient,
              @Inject(PV_TOKEN.ENV) environment: any,
              @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authenticationService: any,
              private loaderService: LoaderService ) {
              this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
              this.baseApiUrlV2 = this.baseApiUrl + '/v2';
              this.authorizationsEndpoint = this.baseApiUrlV2 + '/authorizations';
  }

  putAuthorizationStatus(authRequest: AuthorizationRequest): Observable<any> {
      let requestParams: HttpParams = new HttpParams();
      for (const key in authRequest) {
          if (key !== 'authorizationId' && authRequest[key]) {
          requestParams = requestParams.set(key, authRequest[key]);
          }
      }
      return this.loaderService.registerBeCall(this.http.put<Authorization>(this.authorizationsEndpoint
                                              + '/authorization/' + authRequest.authorizationId, {}, { params: requestParams })
      );
  }

  getAuthorizationDetail(authorizationId: string): Observable<Authorization> {
    return this.loaderService.registerBeCall(this.http.get<any>(this.authorizationsEndpoint + '/authorization/' + authorizationId));
  }

  publishAuthorization(contractId: string, authId: string) {
    const request = {
        username: this.authenticationService.getOperator().username,
        authorizationId: authId,
        loginNode: this.authenticationService.getSalePointCode()
    };
    return this.loaderService.registerBeCall(this.http.post(this.baseApiUrlV2 + '/' + this.restBaseContractsUrl + contractId
                                            + '/authorization/published', request, {}));
  }

}

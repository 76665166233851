<rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()"></rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button rgi-rx-button color="primary" (click)="modalClose.emit()" translate>
        _GP_._BTN_._CLOSE_
      </button>
      <button rgi-rx-button color="primary" *ngFor="let btn of btns" (click)="modalClose.emit(btn.action)"
              [attr.data-qa]="btn.action" translate>
        {{btn.label}}
      </button>
    </rgi-rx-panel-footer>
    <p>{{text}}</p>
</rgi-rx-panel>

<div data-qa="life-profile-allocation" *ngIf="onInitEnd">
  <div class="row" id="profile-row">
    <div class="col-md-8">
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                (click)="back()" data-qa="back-arrow"></span>
              <span class="separator"> | </span>
              <span class="menu-title" translate>lpc_allocation_profile</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 life-detail-first-info">
          <div class="col-md-5 life-detail-img">
            <div class="hidden-xs hidden-sm anag-user-ico">
              <span class="rgifont life-detail-icon-padding rgi-fogliocassa"></span>
            </div>
          </div>
          <div class="col-md-7 life-detail-general-info">
            <h3 class="col-md-12 card-color-blue" style="margin-top: 0px !important;">
              <span data-qa="profileDescription">
                {{data.profileVersion.version.name}}
              </span>
            </h3>
            <div class="container-fluid anag-data">
              <div class="col-md-12">
                <span class="tbld_col_title" translate>lpc_status</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="status">
                  {{data.profileVersion.version.status}}
                </span>
              </div>
              <div class="col-md-12">
                <span class="tbld_col_title" translate>lpc_start_validity_date</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="validityFrom">
                  {{convert(data.profileVersion.version.validityFrom)}}
                </span>
              </div>
              <div class="col-md-12">
                <span class="tbld_col_title" translate>lpc_end_validity_date</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="validityTo">
                  {{convert(data.profileVersion.version.validityTo)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
          <div class="tbld life-detail-margin-top">
            <div class="row life-detail-card-title-container">
              <div class="row life-detail-title">
                <div class="card-title">
                  <span class="header-icon rgifont "></span>
                  <span class="menu-title" translate>lpc_Allocation</span>
                </div>
              </div>
            </div>
            <div class="tbld_row life-detail-tbld_row_header">
              <div class="tbld_col tbld_col_title" translate>lpc_ISIN_code</div>
              <div class="tbld_col tbld_col_title" translate>lpc_fund_description</div>
              <div class="tbld_col tbld_col_title" translate>lpc_Percentage</div>
            </div>
            <ng-container *ngFor="let alloc of fundDistribution |
            paginate : { itemsPerPage: pageSize, currentPage: currentPage, totalItems: allocation.fundDistribution.length }">
              <div class="tbld_row content">
                <div class="tbld_col tbld_col_value">{{alloc.isinCode}}</div>
                <div class="tbld_col tbld_col_value">{{alloc.name }}</div>
                <div class="tbld_col tbld_col_value">{{alloc.percentage | percent : '2.1-2'}}</div>
              </div>
            </ng-container>
          </div>
          <div id="pagination-footer" style="margin-top: 50px;">
            <div style="float: left">
              <span class="styled-select">
                <ng-select id="page-size" class="col-lg-12" [clearable]="false"
                           [placeholder]="pageSize.toString()" (change)="handlePageSizeChange($event)">
                  <ng-option *ngFor="let size of [10, 25, 50, 100]" [value]="size">
                      {{ size }}
                  </ng-option>
                </ng-select>
              </span>
            </div>
            <div style="float: right">
              <pagination-controls (pageChange)="handlePageChange($event)" responsive="true"
                                   previousLabel="" nextLabel=""></pagination-controls>
            </div>
          </div>
      </div>
    </div>
  </div>

</div>

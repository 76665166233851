import {Observable} from 'rxjs';
import {InjectionToken, Type} from '@angular/core';

export type RgiRxAuthToken = string;

export interface RgiRxAuthTokenRefreshProvider {
  refresh(token: string): RgiRxAuthToken | Promise<RgiRxAuthToken> | void;
}


export interface RgiRxAuthConfig {
  refreshTokenHeader?: string;
  /**
   * A provider that is responsible to update and refresh the token when needed, invoked after an HTTP Response
   */
  refreshTokenProvider?: Type<RgiRxAuthTokenRefreshProvider>;

  /**
   * A provider that is responsible to update and refresh the token when needed, invoked before an HTTP Request.
   */
  preMatchingRefreshTokenProvider?: Type<RgiRxAuthTokenRefreshProvider>;
}


export const RGI_RX_AUTH_CONFIG = new InjectionToken<RgiRxAuthConfig>('RGI_RX_AUTH_CONFIG');


export interface DefaultAuthentication {
  username: string;
  password: string;
}

export interface TokenAuthentication {
  token: string;
}

export type RgiRxAuthenticationType = DefaultAuthentication | TokenAuthentication | any;

export interface AuthenticationResult<USER = any, DATA = any> {
  user?: USER;
  data?: DATA;
}

export interface LoginOperation<USER= any, DATA= any> {
  user?: USER;
  token?: string;
  data?: DATA;
}

export interface RgiRxAuthProvider<USER, DATA> {
  authenticate(data?: RgiRxAuthenticationType): Observable<AuthenticationResult<USER, DATA> | void>;
  logout(data?: DATA): Observable< any | void>;
  get data(): DATA | undefined;
  isAuthenticated(): boolean;
}

/**
 * @deprecated use RgiRxAuthProvider
 * @see RgiRxAuthProvider
 */
export interface AuthProvider extends RgiRxAuthProvider<any, any>{}

export abstract class RgiRxLoginService<USER = any, DATA = any> {
  public abstract login(data?: RgiRxAuthenticationType): Observable<LoginOperation<USER, DATA> | void>;
  public abstract logout(data?: DATA): Observable<any | void>;
}

/**
 * @deprecated use RgiRxLoginService will be removed in 2.x
 * @see RgiRxLoginService
 */
export abstract class LoginService extends RgiRxLoginService{}


/**
 * @deprecated no longer used will be removed in 2.x
 */
export interface LogoutOperation<T> {
  data?: T;
}




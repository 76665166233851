import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { EvaluatorRuleI } from '../../../../evaluation/models';


@Component({
  selector: 'survey-eval-rule-tree',
  templateUrl: './survey-eval-rule-tree.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SurveyEvalRuleTreeComponent implements OnInit {

  @Input('rule') rule:EvaluatorRuleI;
  @Input('expand') expand:{rule:boolean,msgs:boolean, vars: boolean, forms: boolean} = {rule:false,msgs:false, vars: false, forms: false};

  constructor() { }

  ngOnInit() {
  }

}

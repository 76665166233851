<!-- formsArray and formGroup -->
<form *ngIf="riskDetailForm" [formGroup]="riskDetailForm">
    <!-- TABLE DETAIL -->
    <table rgi-rx-table [dataSource]="risks">

        <ng-container rgiRxColumnDef="Rischi Assicurati">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.INSURED_RISKS</td>
            <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.extendedRiskDescr ? row.extendedRiskDescr : row.riskDescription}} </td>
            <td rgi-rx-footer-cell *rgiRxFooterCellDef translate>ICD.TOTAL</td>
        </ng-container>

        <ng-container rgiRxColumnDef="Provv.Acquisto Banca">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_PURCH_BANK</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.intPurchaseCommAmt | currency:'':' ':'1.2-2'}} </td>
            <td class="td-amount" rgi-rx-footer-cell
                *rgiRxFooterCellDef>{{ getTotalSumRisk(idDashBoard, 'intPurchaseCommAmt') | currency:'':' ':'1.2-2'}}</td>
        </ng-container>

        <ng-container rgiRxColumnDef="Provv. Acquisto Compagnia">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_PURCH_COMPANY</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.extPurchaseCommAmt | currency:'':' ':'1.2-2'}} </td>
            <td class="td-amount" rgi-rx-footer-cell
                *rgiRxFooterCellDef>{{ getTotalSumRisk(idDashBoard, 'extPurchaseCommAmt') | currency:'':' ':'1.2-2'}}</td>
        </ng-container>

        <ng-container rgiRxColumnDef="Provv. Incasso Banca">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_COLL_BANK</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.intCollCommAmt | currency:'':' ':'1.2-2'}} </td>
            <td class="td-amount" rgi-rx-footer-cell
                *rgiRxFooterCellDef>{{ getTotalSumRisk(idDashBoard, 'intCollCommAmt') | currency:'':' ':'1.2-2'}}</td>
        </ng-container>

        <ng-container rgiRxColumnDef="Provv. Incasso Compagnia">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_COLL_COMPANY</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.extCollCommAmt | currency:'':' ':'1.2-2'}} </td>
            <td class="td-amount" rgi-rx-footer-cell
                *rgiRxFooterCellDef>{{ getTotalSumRisk(idDashBoard, 'extCollCommAmt') | currency:'':' ':'1.2-2'}}</td>
        </ng-container>
        <!-- SUMMARY MODE -->
        <div *ngIf="!editMode">
            <ng-container rgiRxColumnDef="Nuovo Importo Provv. Acquisto Banca">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_NEW_PURCH_BANK</td>
                <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row">{{row.commPurchAmt | currency:'':' ':'1.2-2'}}</td>
                <td class="td-amount" rgi-rx-footer-cell
                    *rgiRxFooterCellDef>{{ getTotalSumRisk(idDashBoard, 'commPurchAmt')| currency:'':' ':'1.2-2' }}</td>
            </ng-container>

            <ng-container rgiRxColumnDef="Nuovo importo Provv. Incasso banca">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_NEW_COLL_BANK
                </td>
                <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row">{{row.commCollAmt | currency:'':' ':'1.2-2'}}</td>
                <td class="td-amount" rgi-rx-footer-cell
                    *rgiRxFooterCellDef>{{ getTotalSumRisk(idDashBoard, 'commCollAmt') | currency:'':' ':'1.2-2'}}</td>
            </ng-container>
        </div>
        <!-- EDIT MODE -->
        <div *ngIf="editMode">
            <ng-container rgiRxColumnDef="Nuovo Importo Provv. Acquisto Banca">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_NEW_PURCH_BANK

                </td>
                <td rgi-rx-cell *rgiRxCellDef="let row">
                    <rgi-rx-form-field>
                        <input rgiRxInput class="td-amount"
                               (blur)="formatValue(idDashBoard + '_' + row.idDashRisk + '_commPurchAmt')"
                               formControlName="{{idDashBoard}}_{{row.idDashRisk}}_commPurchAmt">
                    </rgi-rx-form-field>
                </td>
                <td class="td-amount" rgi-rx-footer-cell
                    *rgiRxFooterCellDef>{{ getSumOnEditMode(idDashBoard, 'commPurchAmt')| currency:'':' ':'1.2-2' }}</td>
            </ng-container>

            <ng-container rgiRxColumnDef="Nuovo importo Provv. Incasso banca">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_NEW_COLL_BANK
                </td>
                <td rgi-rx-cell *rgiRxCellDef="let row"> 
                    <rgi-rx-form-field>
                        <input rgiRxInput class="td-amount"
                               formControlName="{{idDashBoard}}_{{row.idDashRisk}}_commCollAmt"
                               (blur)="formatValue(idDashBoard + '_' + row.idDashRisk + '_commCollAmt')">
                    </rgi-rx-form-field>
                </td>
                <td class="td-amount" rgi-rx-footer-cell
                    *rgiRxFooterCellDef>{{ getSumOnEditMode(idDashBoard, 'commCollAmt') | currency:'':' ':'1.2-2'}}</td>
            </ng-container>

        </div>
        <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
        <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
        <tr rgi-rx-footer-row *rgiRxFooterRowDef="displayedColumns"></tr>

    </table>
</form>


export class LegalFeeOmniaEntity {
    public idFee: string; // id della fattura se è già stata salvata
    public idSoggLegal: string; // id soggetto del legale di controparte
    public nominative: string; // nominativo legale
    public amountPaid: number; // importo liquidato
    public feeNumber: string; // numero fattura
    public feeYear: number;
    public feeDate: Date;
    public feeAmount: number;
    public invoiceControlPaymentBlocked: boolean;
    public claimsList: Array<string>;
}

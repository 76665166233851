<ng-container *ngIf="!!errorMessages.length">
  <pnc-form-message
            *ngIf="errorMessages.length > 0"
            type="error"
            [messages]="errorMessages"
            detail="validationError"
            data-qa="step-validation">
  </pnc-form-message>
</ng-container>


<div class="btn-group btn-group-justified">
  <div class="btn-group" *ngIf="backVisible">
    <button (click)="onBack()" class="btn btn-warning btn-secondary back"  [attr.data-qa]="'back-'+qa" type="submit" translate>{{ backLabel }}</button>
  </div>
  <div class="btn-group no-border" *ngIf="nextVisible && isConfirm">
    <button (click)="onNext()" class="btn btn-warning" [attr.data-qa]="'next-'+qa" type="submit" translate>lic_CONFIRM</button>
  </div>
  <div class="btn-group no-border" *ngIf="nextVisible && !isConfirm && saveVisible" >
    <button (click)="onSave()" class="btn btn-warning pull-left btn-secondary" [attr.data-qa]="'save'" type="submit" translate>lic_save</button>
  </div>
  <div class="btn-group no-border" *ngIf="nextVisible && !isConfirm" >
    <button (click)="onNext()" class="btn btn-warning pull-right" [attr.data-qa]="'next-'+qa" type="submit" translate>lic_NEXT</button>
  </div>
</div>

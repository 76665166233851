export class PaymentData {
  paymentTypeDebCred: PaymentType;
  paymentType: PaymentType;
  paymentFields: PaymentField[];
}

export interface PaymentField {
  name: string;
  value: string;
}

export interface PaymentType {
  id: number;
  code: string;
  description: string;
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ContactConfig} from './contact-config';
import {MicHttpService} from '../../http/mic-http.service';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressesService {
  private address: any;
  private addresses: Array<any>;
  private addressIndex: number;
  private baseApiUrl;
  private baseApiUrlV2;
  protected httpClient: HttpClient;


  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrl = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';

    this.httpClient = this.micHttpClient.getHttpClient();


  }

  getAddresses() {
    if (!this.addresses) {
      this.addresses = [];
    }
    return this.addresses;
  }

  setAddresses(addresses: any) {
    this.addresses = addresses;
  }

  addAddress(address, index) {
    if (index === null) {
      this.addresses.push(address);
    } else {
      this.addresses.splice(index, 1, address);
    }
  }

  setAddress(address: any) {
    this.address = address;
  }

  getAddress() {
    return this.address;
  }

  emptyAddress() {
    this.address = null;
  }

  setAddressIndex(index: number) {
    this.addressIndex = index;
  }

  getAddressIndex() {
    return this.addressIndex;
  }

  emptyAddressIndex() {
    this.addressIndex = null;
  }

  retrieveContactsConfig() {
    return this.httpClient.get<ContactConfig>(this.baseApiUrlV2 + '/portfolio/contacts/config');
  }

  normalizeAddress(toNormalize: any) {
    return this.httpClient.post<any>(this.baseApiUrl + '/anag/normalize', toNormalize);
  }

  getAdminLevelValues$(countryCode: string, adminLevel: number, filterValue: string): any {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('filterValue', filterValue);
    return this.httpClient.get<any>(`${this.baseApiUrl}/subapp/adminlevel/${countryCode}/${adminLevel}`,
      { params: httpParams });
  }

}

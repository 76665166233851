import { ApiEnumType } from '../api-enum-type';

export class ApiSubject {
    public objectId: number;
    public nominative: string;
    public tipoSoggetto: ApiEnumType;
    public givenName: string;
    public surname: string;
    public designation: string;

    constructor() { }
}

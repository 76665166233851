import {Inject, Injectable, Renderer2} from '@angular/core';
import {QAContext, RGI_RX_QA_RENDER_STYLE, RgiRxQAConfig} from './rgi-rx-data-qa-api';
import {LoggerFactory} from '../logging/logging-providers';
import {DOCUMENT} from '@angular/common';
import {clone} from 'lodash';
import {RgiRxQAConfigService} from './rgi-rx-qaconfig.service';

export type RgiRxQATarget = 'parent' | 'child';
const NO_CTX = '__$NOCONTEXT';

@Injectable()
export class RgiRxQAService {

  static _trackedId: Map<string, string[]> = new Map();
  private readonly logger = LoggerFactory();

  constructor(
    private renderer: Renderer2,
    private configService: RgiRxQAConfigService,
    @Inject(RGI_RX_QA_RENDER_STYLE) private renderStyle: any,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  public render(element: HTMLElement, qaCtx: QAContext, elementTarget: RgiRxQATarget = 'parent') {

    const qa = clone(qaCtx);


    if (!!this.configService.enabled) {
      this.renderer.setAttribute(element, 'data-qa', qa.id);
      if (qa.ctx) {
        this.renderer.setAttribute(element, 'data-qa-ctx', qa.ctx);
      }
      if (qa.type) {
        this.renderer.setAttribute(element, 'data-qa-type', qa.type);
      }
      this.renderer.setAttribute(element, 'data-qa-value', qa.value ? '' + qa.value : '');
    }
    if (!this.configService.debug) {
      return;
    }


    if (!!qa.ctx) {
      if (RgiRxQAService._trackedId.has(qa.ctx)) {
        if (RgiRxQAService._trackedId.get(qa.ctx).indexOf(qa.id) > -1) {
          this.logger.warn(`[rgi-rx-qa] duplicate identifier in context ${qa.ctx} for id ${qa.id}`, element);
        } else {
          const contextKeys = RgiRxQAService._trackedId.get(qa.ctx);
          contextKeys.push(qa.id);
          RgiRxQAService._trackedId.set(qa.ctx, contextKeys);
        }
      } else {

        RgiRxQAService._trackedId.set(qa.ctx, [qa.id]);
      }
    } else {
      const $NOCONTEXT = RgiRxQAService._trackedId.get(NO_CTX);
      if (!!$NOCONTEXT && $NOCONTEXT.indexOf(qa.id) > -1) {
        this.logger.warn(`[rgi-rx-qa] duplicate identifier for id ${qa.id}`, element);
      } else {
        if (!$NOCONTEXT) {
          RgiRxQAService._trackedId.set(NO_CTX, []);
        }
        RgiRxQAService._trackedId.get(NO_CTX).push(qa.id);
      }
    }

    const renderMessage = this.buildRenderMessage(qa);
    const comment = this.renderer.createComment(renderMessage);
    this.renderer.insertBefore(element.parentNode, comment, element);

    if (this.configService.debug === 'render') {
      const debugNode = this.renderer.createElement('div');
      this.renderer.setAttribute(debugNode, 'rgi-rx-qa-debug', '');
      for (const key in this.renderStyle) {
        if (this.renderStyle.hasOwnProperty(key)) {
          this.renderer.setStyle(debugNode, key, this.renderStyle[key]);
        }
      }
      debugNode.textContent = renderMessage;

      if (elementTarget === 'child') {
        this.renderer.appendChild(element, debugNode);
      } else {
        this.renderer.insertBefore(element.parentNode, debugNode, element);
      }
    } else if (this.configService.debug === 'log') {
      this.logger.debug(element, qa);
    }
  }


  clear(element: HTMLElement, target: RgiRxQATarget = 'parent') {
    if (!!this.configService.debug) {
      const rgiRxQa = element.getAttribute('data-qa');
      const rgiRxQaCtx = element.getAttribute('data-qa-ctx');
      if (!!rgiRxQa) {
        if (!!rgiRxQaCtx) {
          const ctxMap = RgiRxQAService._trackedId.get(rgiRxQaCtx);
          const index = ctxMap ? ctxMap.indexOf(rgiRxQa) : -1;
          if (index > -1) {
            RgiRxQAService._trackedId.set(rgiRxQaCtx, ctxMap.splice(index, 1));
          }
        } else {
          const $NOCONTEXT = RgiRxQAService._trackedId.get(NO_CTX);
          const index = $NOCONTEXT ? $NOCONTEXT.indexOf(rgiRxQa) : -1;
          if (index > -1) {
            RgiRxQAService._trackedId.set(NO_CTX, $NOCONTEXT.splice(index, 1));
          }
        }
      }
    }
    const elementNodeListOf = this.document.querySelectorAll('[rgi-rx-qa-debug]');
    elementNodeListOf.forEach(
      (node: HTMLElement) => {
        if (target === 'parent' && node.nextSibling === element) {
          node.remove();
        } else {
          if (node.parentElement === element) {
            node.remove();
          }
        }
      }
    );
  }

  private buildRenderMessage = (qaCtx: QAContext) => {
    let message = `[qa] ${qaCtx.id}`;
    if (!!qaCtx.value) {
      message += ` [value] ${qaCtx.value}`;
    }
    if (!!qaCtx.ctx) {
      message += ` [ctx] ${qaCtx.ctx}`;
    }
    if (!!qaCtx.type) {
      message += ` [type] ${qaCtx.type}`;
    }
    return message;
  }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {QuestionnaireI} from '../../models/questionnaire';
import {SurveyVersionI} from '../../models/survey-version';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-form-survey-edit',
  templateUrl: './form-survey-edit.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FormSurveyEditComponent implements OnInit, OnDestroy, OnChanges {

  @Input() disabled:boolean = false;
  @Input() questionnaires: Array<QuestionnaireI>;
  @Input() surveyVersion: SurveyVersionI;
  @Input() selectedQuestionnaire: QuestionnaireI;
  @Input() anonymous = false;

  @Output("selectQuestionnaire") onSelectQuestionnaire = new EventEmitter<QuestionnaireI>();

  errorsVisible: boolean = false;

  // form
  formSurveyEdit: UntypedFormGroup = this.fb.group({
                          'type': new UntypedFormControl('', [Validators.minLength(2), Validators.pattern('[A-Za-z0-9\s]*')]),
                          'code': new UntypedFormControl('', [Validators.minLength(2)]),
                          'dateCreated': new UntypedFormControl(),
                          'dateEnd': new UntypedFormControl(),
                          'questionnaire': new UntypedFormControl('', Validators.required)
                        });

  // subscriptions
  valuesChangeSubscription: Subscription;


  constructor(private fb: UntypedFormBuilder) {}


  ngOnChanges(changes: SimpleChanges): void {
    this.checkInitOnChange();

    if(changes.disabled){
      this.updateFormDisabled();
    }

    if(changes.questionnaires){
      this.updateFormQuestionnaire();
    }

    if(changes.selectedQuestionnaire){
      this.updateFormQuestionnaire();
      this.updateFormQuestionnaireInfo();
    }

    if(changes.surveyVersion){
      this.updateFormValues();
    }

    if(changes.anonymous){
      this.updateSurveyVersion();
    }

  }


  ngOnInit() {

    this.valuesChangeSubscription = this.formSurveyEdit.valueChanges.subscribe(data => {
      this.updateSurveyVersion();
    });
  }


  ngOnDestroy(): void {
    this.valuesChangeSubscription.unsubscribe();
  }


  onQuestionnaireChange(event){
    this.onSelectQuestionnaire.emit(this.questionnaire.value);
  }


  isValid():boolean{
    //https://github.com/angular/angular/issues/18678
    // se il form è disabilitato la validazione è sempre false
    return this.formSurveyEdit.disabled || this.formSurveyEdit.valid;
  }


  showErrors(){
    this.errorsVisible = true;
  }


  protected checkInitOnChange(){
    if (!this.anonymous) {
      if(!this.nameSurname){
        this.formSurveyEdit.addControl("nameSurname",new UntypedFormControl('', Validators.required));
      }
      if(!this.cfPiva){
        this.formSurveyEdit.addControl("cfPiva", new UntypedFormControl('', [Validators.minLength(2)]));
      }

    }else {
      if(this.nameSurname){
        this.formSurveyEdit.removeControl("nameSurname");
      }
      if(this.cfPiva){
        this.formSurveyEdit.removeControl("cfPiva");
      }
    }
  }


  protected updateFormValues(){
    if(!this.surveyVersion){
      return;
    }
    if (!this.anonymous && this.nameSurname && this.cfPiva) {
      this.nameSurname.setValue(this.surveyVersion.survey.name);
      this.cfPiva.setValue(this.surveyVersion.survey.codeFisc);
    }
    this.dateCreated.setValue( this.parseDate(this.surveyVersion.dateCreated));
    this.dateEnd.setValue( this.parseDate(this.surveyVersion.dateEnd) );
  }


  protected updateFormDisabled(){
    if(this.disabled){
      this.formSurveyEdit.disable();
    }else {
      this.formSurveyEdit.enable();
      this.type.disable();
      this.code.disable();
      this.dateCreated.disable();
      this.dateEnd.disable();
    }
  }


  protected updateFormQuestionnaire(){
    if( this.selectedQuestionnaire ){
      if( !this.questionnaires ){
        this.questionnaires = [this.selectedQuestionnaire];
      }
      const q = this.questionnaires.find( questionnaire => questionnaire.code === this.selectedQuestionnaire.code );
      this.questionnaire.setValue(q);

    }else {
      this.questionnaire.setValue(null);
    }
  }


  protected updateFormQuestionnaireInfo(){
    if(this.selectedQuestionnaire && this.selectedQuestionnaire.questionnaireType){
      this.type.setValue(this.selectedQuestionnaire.questionnaireType.name);
      this.code.setValue(this.selectedQuestionnaire.code);
    }else {
      this.type.setValue("");
      this.code.setValue("");
    }
  }


  protected updateSurveyVersion(){
    if (!this.anonymous && this.surveyVersion &&  this.nameSurname && this.cfPiva) {
      this.surveyVersion.survey.name = this.nameSurname.value;
      this.surveyVersion.survey.codeFisc = this.cfPiva.value;
    }
  }


  // form fields

  get nameSurname(){
    return this.formSurveyEdit.get('nameSurname')
  }

  get type(){
    return this.formSurveyEdit.get('type');
  }

  get cfPiva(){
    return this.formSurveyEdit.get('cfPiva');
  }

  get code(){
    return this.formSurveyEdit.get('code');
  }

  get dateCreated(){
    return this.formSurveyEdit.get('dateCreated');
  }

  get dateEnd() {
    return this.formSurveyEdit.get('dateEnd');
  }

  get questionnaire(){
    return this.formSurveyEdit.get('questionnaire');
  }


  protected parseDate(value){
    const t = Date.parse(value);
    return isNaN(t) ? "" : new Date(t).toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"});
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorType } from '../models/response.model';
import { Action, ActionServiceErrorMessage } from './../models/actions.model';
import { LifeSessionService } from './life-session-service';
import { PolicyService } from './policy-service';


export enum Code {
  BLOCKING,
  WARNING,
  AUTHORIZING
}

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  errorsLevels = new Map<Code, Action[]>();
  isGoodsInfo = false;
  authorization = false;
  authorizations = new Map<string, boolean>();

  errorMessagesMap = new Map<string, ActionServiceErrorMessage[]>();

  constructor(protected httpClient: HttpClient,
              protected policyService: PolicyService,
              protected lifeSessionService: LifeSessionService) {
   }

  getMsgByCodLivelloAzioni(codLivelloAzioni: string): Observable<any> {
    const property = {
      chiave: 'actions',
      valore: codLivelloAzioni
    };

    const request = {
      quote: {
        agencyID: this.lifeSessionService.idPv,
        product: this.policyService.mainProposal.quote.product,
        resourceId: this.policyService.mainProposalResourceId,
        fase: {
          properties: [property]
        }
      },
      proposal: this.policyService.mainProposal.proposal,
      codManagementNode: this.lifeSessionService.codNodeManagement,
      idManagementNode: this.lifeSessionService.idPvManagement,       // <--- Cambiare, impostato di default!
      codLoginNode: this.lifeSessionService.codLoginNode,
      userCode: this.lifeSessionService.getOperatorUsername()
    };

    // ASMC-7040 -> settare proprietà in ext estendendo il metodo
    request.proposal.extensionData.properties = this.getProperties();

    if (this.policyService.isFromPreventive || this.policyService.isFromQuoteModification) {
      return this.httpClient.post(this.lifeSessionService.baseApiURLPass + '/ptflife/estimate/executeAction/', { input: request });
    }
    return this.httpClient.post(this.lifeSessionService.baseApiURLPass + '/ptflife/proposal/executeAction/', { input: request });
  }

  protected getProperties(): Array<{chiave: string, valore: string}> {
    return this.policyService.mainProposal.proposal.extensionData.properties;
  }

  getMsgsByErrorCode(errorType: Code, errorsLevels: Map<Code, Action[]>): string[] {
    const messages = [];
    errorsLevels.forEach((value: Action[], key: Code) => {
      if (key === errorType) {
        value.map((val) => {
          messages.push(val.messageDescription);
        } );
      }
    });
    return messages;
  }

  getMessages() {
    return this.errorsLevels;
  }

  removeMessages() {
    this.errorsLevels.clear();
  }

  getWarningErrors(operator, action): string {
    const lvlDerogaUtente = operator.blockingDerogationLevel;
    const lvlDerogaUtente2 = operator.warningDerogationLevel;
    if (parseInt(action.derogationLevel, 10) !== 0 &&
       action.derogationLevel >= lvlDerogaUtente && action.derogationLevel < lvlDerogaUtente2 ) {
      return action.messageDescription;
    }
    return null;
  }

  getAuthoErrors(operator, action) {
    const lvlDerogaUtente = operator.blockingDerogationLevel;
    // check iF derogation lever != 0 to see if not blocking error
    if (parseInt(action.derogationLevel, 10) !== 0 && action.derogationLevel < lvlDerogaUtente) {
      return action.messageDescription;
    }
    return null;
  }

  getAlertErrors(operator, action): string {
    if (action.isAlert) {
      return action.messageDescription;
    }
    return null;
  }

  getBlockingErrors(action: any) {
    if (parseInt(action.derogationLevel, 10) === 0) {
      return action.messageDescription;
    }
    return null;
  }

  setAuthorization(page: string, param: boolean) {
    this.authorizations.set(page, param);
  }

  getAuthorizationsByStepId(stepId: string) {
    const val = this.errorMessagesMap.get(stepId);
    return !!val ? val.filter(msg => this.getAuthoErrors(this.lifeSessionService.getOperator(), msg)) : [];
  }

  getErrorsByTypeAndStepId(type: string, stepId: string) {
    const val = this.errorMessagesMap.get(stepId);
    if (!val) {
      return [];
    }
    if (type === ErrorType.AUTH) {
      return val.filter(msg => this.getAuthoErrors(this.lifeSessionService.getOperator(), msg));
    }
    if (type === ErrorType.WARNING) {
      return val.filter(msg => this.getWarningErrors(this.lifeSessionService.getOperator(), msg));
    }
  }

  getErrorsMapped(type, stepId): {message: string, type: string}[] {
    return this.getErrorsByTypeAndStepId(type, stepId).map(msg => {
      return {
        type,
        message: msg.messageDescription
      };
    });
  }

  isAuthorization() {
    let isAutho = false;
    this.authorizations.forEach((value: boolean, key: string) => {
      if (value === true) {
        isAutho = true;
      }
    });
    return isAutho;
  }

  checkIsAlert(action: ActionServiceErrorMessage) {
    return action.isAlert === true;
  }

  setActionErrorMessage(step, errorMessage: ActionServiceErrorMessage) {
    this.setActionErrorMessageVector(step, [errorMessage]);
  }

  setActionErrorMessageVector(step, errorMessages: ActionServiceErrorMessage[]) {
    const oldVector = this.getErrorMessagesForStep(step);
    if (!!oldVector && oldVector.length > 0) {
      this.errorMessagesMap.set(step, oldVector.concat(errorMessages));
    } else {
      this.errorMessagesMap.set(step, errorMessages);
    }
  }

  getErrorMessagesForStep(step): ActionServiceErrorMessage[] {
    return this.errorMessagesMap.get(step);
  }

  cleanErrorMessagesForStep(step) {
    this.errorMessagesMap.set(step, []);
  }

  getAllActionMessages(): ActionServiceErrorMessage[] {
    let allMessages = [];
    [...this.errorMessagesMap.values()].forEach(stepErrors => {
      allMessages = allMessages.concat(stepErrors);
    });
    return allMessages;
  }

}

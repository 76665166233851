import {AbstractControl, FormGroupDirective, NgControl, NgForm, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {RgiRxLabelDirective} from './rgi-rx-label.directive';
import {ContentChild, Directive, QueryList, ViewChildren} from '@angular/core';
import {RgiRxFormFieldErrorComponent} from './rgi-rx-form-field-error/rgi-rx-form-field-error.component';

let rgiRxFormControlCounter = 0;

export interface RgiRxControlForwardAttributes<T> {
  readonly [p: string]: T;
}

export type RgiRxControlForwardClass = string | string[];
export type RgiRxControlSkipValidationKeys = string[];


/**
 * Interface of RgiRxControl
 * RgiRxControl implementations can be used in the RgiRxFormField component as RgiRxFormControl
 * @see RgiRxFormControl
 * @see RgiRxFormFieldComponent
 */
interface RgiRxControl<T> {
  /**
   * The value of the control
   */
  value: T | undefined | null;
  /**
   * The id of the control
   */
  id: string;
  /**
   * The placeHolder of the control
   */
  placeholder: string;
  /**
   * Weather the control it's required
   */
  required: boolean;
  /**
   * Weather the control it's disabled
   */
  disabled: boolean;

  /**
   * Weather the control it's on error state
   */
  hasError(): boolean;
}

/**
 * RgiRxFormControl is the root type of any FormControl
 * @see RgiRxFormFieldComponent
 */
export abstract class RgiRxFormControl<T> implements RgiRxControl<T> {
  /**
   * Notify any changes that require a mark check for the change detection cycle
   */
  readonly stateChanges = new Subject<void>();
  protected _value: T | undefined | null;
  /**
   * an unique id to provide to input that doesn't define one
   */
  protected _uuid: string;
  protected _id: string;
  protected _placeholder: string;
  protected _required: boolean;
  protected _disabled: boolean;
  private _focused: boolean;
  private _forwardAttributes: RgiRxControlForwardAttributes<string>;
  private _forwardClasses: RgiRxControlForwardClass;
  private _skipValidationKeys: RgiRxControlSkipValidationKeys;


  private _ariaLabel?: string;
  private _ariaLabelledBy: string[] = [];
  private _ariaDescribedBy: string[] = [];

  protected constructor(
    protected _errorStateMatcher: RgiRxErrorStateMatcher,
    public ngControl?: NgControl,
    protected _parentForm?: NgForm,
    protected _parentFormGroup?: FormGroupDirective,
  ) {
    this._uuid = `rgi-rx-form-control-${++rgiRxFormControlCounter}`;
  }

  hasError(): boolean {
    const parent = this._parentFormGroup || this._parentForm;
    return this._errorStateMatcher.hasError(this.ngControl, parent);
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get id(): string {
    return this._id ? this._id : this.uuid;
  }

  set id(value: string) {
    this._id = !!value ? value : this.uuid;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value || '';
  }

  get required(): boolean {
    return this._required || (this.ngControl?.control?.hasValidator(Validators.required))
  }

  set required(value: boolean | string) {
    this._required = coerceBooleanProperty(value);
  }

  get disabled(): boolean {
    return this._disabled || this.ngControl?.control?.disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }


  get value(): T | undefined | null {
    return this._value;
  }

  set value(value: T | undefined | null) {
    this._value = value;
  }

  /**
   * Forward attributes to be bound to the RgiRxFormControl container element
   * Override the method in the implementation, it will be taken into account only during the first rendering phase,
   * changes to the forwarded attributes will be not propagated
   */
  get forwardAttributes(): RgiRxControlForwardAttributes<string> {
    return this._forwardAttributes;
  }

  /**
   * Forward classes to be bound to the RgiRxFormControl container element
   * Override the method in the implementation, it will be taken into account only during the first rendering phase,
   * changes to the forwarded classes will be not propagated
   */
  get forwardClasses(): RgiRxControlForwardClass {
    return this._forwardClasses;
  }

  /**
   * Validators keys that should not be rendered as part of the error display for this RgiRxFormControl.
   * Prevent keys from being rendered, whenever the rendering it's handled within the RgiRxFormControl itself.
   */
  get skipValidationKeys(): RgiRxControlSkipValidationKeys {
    return this._skipValidationKeys;
  }


  set skipValidationKeys(value: RgiRxControlSkipValidationKeys) {
    this._skipValidationKeys = value;
  }

  get focused(): boolean {
    return this._focused;
  }

  set focused(value: boolean) {
    this._focused = value;
  }


  get ariaLabel(): string {
    return this._ariaLabel;
  }

  set ariaLabel(value: string) {
    this._ariaLabel = value;
  }

  get ariaLabelledBy(): string[] {
    return this._ariaLabelledBy;
  }

  set ariaLabelledBy(value: string[]) {
    this._ariaLabelledBy = value;
  }


  get ariaDescribedBy(): string[] {
    return this._ariaDescribedBy;
  }

  set ariaDescribedBy(value: string[]) {
    this._ariaDescribedBy = value;
  }
}

/**
 * The error state matcher it's used to determine the logic of error state detection for controls and forms.
 * Implementing this class allow to define the logic of the ErrorState matching. By the default, RgiRxDefaultErrorStateMatcher
 * is provided.
 */
export abstract class RgiRxErrorStateMatcher {
  abstract hasError(control: AbstractControl | NgControl | null, form: FormGroupDirective | NgForm | null): boolean;
}

/**
 * Default error state matcher provided by RgiRxFormElementsModule
 * @see RgiRxFormElementsModule
 * @see RgiRxErrorStateMatcher
 */
export class RgiRxDefaultErrorStateMatcher extends RgiRxErrorStateMatcher {
  hasError(control: AbstractControl | NgControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.touched || (form && form.submitted)));
  }
}


@Directive({})
export abstract class RgiRxFormField<T= any> {

  @ContentChild(RgiRxFormControl, {static: false}) rgiRxFormControl: RgiRxFormControl<T>;
  @ContentChild(RgiRxLabelDirective, {static: false}) rgiRxLabel: RgiRxLabelDirective;
  @ViewChildren(RgiRxFormFieldErrorComponent) rgiRxFormFieldErrors: QueryList<RgiRxFormFieldErrorComponent>;

  constructor() {}


}


import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { Roles } from '../../../models/enum/lpc-subjects.enum';
import { Role, RoleDefinition } from '../../../models/postsales-operations-response.model';
import { RolesCheckboxService } from '../../../services/roles-checkbox.service';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';

@Component({
  selector: 'lpc-roles-control',
  templateUrl: './lpc-roles-control.component.html',
  styleUrls: ['./lpc-roles-control.component.scss']
})
export class LpcRolesControlComponent implements OnChanges, ControlValueAccessor {

  @Input() roleDefinitions: RoleDefinition[];
  @Input() roles: Role[] = [];
  @Input() disableButtons: boolean;

  /**
   * @description boolean input to handle the view of the checkbox for the delegate role,
   * it's valorized by the value of the systemProperty - PrevalorizationDelegate.
   * It's true when the policy holder is an adult and the property is true
   */
  @Input() enableDelegateCheckbox: boolean;
  /**
   * @description boolean input to handle the view of the checkbox for the legal guardian role
   * it's valorized by the value of the systemProperty - PrevalorizationLegalGuardian.
   * It's true when the policy holder is an adult and the property is true
   */
  @Input() enableLegalGuardianCheckbox: boolean;
  /**
   * @description boolean input to handle the view of the checkbox for the third payer role
   * it's valorized by the value of the systemProperty - PrevalorizationThirdPayer.
   * It's true when the policy holder is an adult and the property is true
   */
  @Input() enableThirdPayerCheckbox: boolean;

  @Input() contractor: Role;

  @Output() edit: EventEmitter<Role> = new EventEmitter<Role>();
  @Output() delete: EventEmitter<Role> = new EventEmitter<Role>();
  @Output() add: EventEmitter<string> = new EventEmitter<string>();
  @Output() addSub: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteSub: EventEmitter<any> = new EventEmitter<any>();
  @Output() setRolePercentage: EventEmitter<Role> = new EventEmitter<Role>();

  /**
   * @description output event to handle the prevalorization of the role (between delegate, legal guardian, third payer)
   *  with the policy holder value.
   */
  @Output() selectedRoleEqualHolder: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @description output to view a warning message when you select a role
   * between delegate, legal guardian and third payer same as the policy holder
   */
  @Output() checkboxWarning: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('deleteButton', { read: ElementRef }) deleteButton: ElementRef;

  private $nextFormGroupName: number;

  public  modalMessage = false;
  public customMessage: string;
  public role: { [key: string]: Role } = {};

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    roles: new UntypedFormArray([])
  });
  public selectLabel: string;

  public checkboxOptions = [
    {label: this.translateService.getImmediate('lpc_YES'), value: true},
    {label: this.translateService.getImmediate('lpc_NO'), value: false}
  ];

  public checkboxControlsName = new Map<string, string>([
    [Roles.DELEGATE, 'delegateCheckbox'],
    [Roles.LEGAL_GUARDIAN, 'legalGuardianCheckbox'],
    [Roles.THIRDPAYER, 'thirdPayerCheckbox']
  ]);

  public warningMsgs = new Map<string, string>([
    [Roles.DELEGATE, 'lpc_DelegateEqualToPolicyHolderWarning'],
    [Roles.LEGAL_GUARDIAN, 'lpc_LegalGuardianEqualToPolicyHolderWarning'],
    [Roles.THIRDPAYER, 'lpc_ThirdPayerEqualToPolicyHolderWarning']
  ]);

  checkWarningMsg = true;

  lastSelectedValue = true;

  get rolesFormArray(): UntypedFormControl[] {
    return (this.formGroup.get('roles') as UntypedFormArray).controls as UntypedFormControl[];
  }

  constructor(
    protected  translateService: TranslationWrapperService,
    protected rolesCheckboxService: RolesCheckboxService
    ) {
    this.selectLabel = this.translateService.getImmediate('lpc_select_label');
  }

  public getRoleCode(role): string {
    if (role === 'contractor') {
        return Roles.CONTRACTOR;
    }
    if (role === 'effectiveHolder') {
        return Roles.EFFECTIVEHOLDER;
    }
    if (role === 'delegate') {
        return Roles.DELEGATE;
    }
    if (role === 'legalGuardian') {
        return Roles.LEGAL_GUARDIAN;
    }
    if (role === 'thirdPayer') {
        return Roles.THIRDPAYER;
    } else {
        return null;
    }
}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roles && !PlcObjectUtils.equal(changes.roles.currentValue, changes.roles.previousValue)) {
      this.$nextFormGroupName = -1;
      this.formGroup = new UntypedFormGroup({
        roles: new UntypedFormArray([])
      });
      this.addCheckboxControl();
      Object.keys(changes.roles.currentValue).forEach((key, index) => {
        const role: Role = changes.roles.currentValue[key];
        (this.formGroup.get('roles') as UntypedFormArray).push(
          new UntypedFormGroup({
            id: new UntypedFormControl(role.id),
            name: new UntypedFormControl(role.name),
            percentage: new UntypedFormControl(role.percentage && Number(role.percentage) || null, [Validators.max(100), Validators.min(1)]),
            subRoles: new UntypedFormArray([])
          })
        );
        const subRoles = changes.roles.currentValue[key].linkedSubjectsRoles;
        if (!!subRoles) {
          subRoles.forEach(sr => {
            (((this.formGroup.get('roles') as UntypedFormArray).controls[index] as UntypedFormGroup)
            .get('subRoles') as UntypedFormArray).push(new UntypedFormGroup({
                id: new UntypedFormControl(sr.id),
                name: new UntypedFormControl(sr.name),
                percentage: new UntypedFormControl(sr.percentage && Number(sr.percentage) || null, [Validators.max(100), Validators.min(1)])
            }));
          });
        }
        if (!!this.enableDelegateCheckbox || !!this.enableLegalGuardianCheckbox || !!this.enableThirdPayerCheckbox) {

          this.setCheckboxValue(role);
        }
      });
      this.checkWarningMsg = false;
    }
  }

  onBlurPercentage(role: Role, percentage) {
    role.percentage = percentage;
    this.setRolePercentage.emit(role);
  }

  public showSubjectBox(role: string): boolean {
    if (role === Roles.DELEGATE) {
      return (!this.enableDelegateCheckbox ||
        (this.enableDelegateCheckbox && (this.formGroup.get('delegateCheckbox').value) === false));
    }
    if (role === Roles.LEGAL_GUARDIAN) {
      return (!this.enableLegalGuardianCheckbox ||
        (this.enableLegalGuardianCheckbox && (this.formGroup.get('legalGuardianCheckbox').value) === false));
    }
    if (role === Roles.THIRDPAYER) {
      return (!this.enableThirdPayerCheckbox ||
        (this.enableThirdPayerCheckbox && (this.formGroup.get('thirdPayerCheckbox').value) === false));
    } else {
      return true;
    }
  }

  public setCheckboxValue(role: Role) {
    const controlName = this.checkboxControlsName.get(role.role);
    if (controlName) {
      if (role.id === this.contractor.id) {
        if (!this.checkWarningMsg && !this.rolesCheckboxService.getStoredCheckboxValue(role.role)) {
          this.checkboxWarning.emit(this.warningMsgs.get(role.role));
        }
        this.formGroup.get(controlName).setValue(true);
      } else {
        this.formGroup.get(controlName).setValue(false);
      }
    }
  }

  public writeValue(obj: { [key: string]: Role }): void {
    this.role = obj;
    if (obj) {

      Object.keys(obj).forEach(key => {
        this.formGroup.get(key).setValue(obj[key], {emitEvent: false});
      });
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  public getMinCardinality(role: RoleDefinition): number {
    return Number(role.minCardinality);
  }

  public onChange = (obj: { [key: string]: Role }) => { };

  public onEdit(role: Role) {
    this.edit.emit(role);
  }

  public onDelete(role: Role, index: number = null) {
    if (role.role === Roles.CONTRACTOR) {
      this.modalMessage = true;
      this.customMessage = this.translateService.getImmediate('lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed');
    } else {
      if (index !== null) {
        (this.formGroup.get('roles') as UntypedFormArray).removeAt(index);
      }
      this.delete.emit(role);
    }
  }

  public deleteFromPopUp(role: Role) {
    this.delete.emit(role);
    this.modalMessage = false;
  }

  public closePopUp() {
    this.modalMessage = false;
  }

  public onSelect(role: string) {
    this.add.emit(role);
  }

  public getFilteredRolesOf(filter: string): Role[] {
    return this.roles.filter((role) => role.role === filter);
  }

  public isSelectable(definition: RoleDefinition): boolean {
    return Number(definition.maxCardinality) > this.roles.filter((role) => role.role === definition.code).length;
  }

  public getNextFormGroupName() {
    this.$nextFormGroupName++;
    return this.$nextFormGroupName.toString();
  }

  public addSubRole(event, role) { }

  public deleteSubRole(event, role) { }

  public getSubRolesValue(role) {
    return this.roles.find((r) => (r.id === role.id && r.role === role.role)).linkedSubjectsRoles;
  }

  public getSubRoleGroup(roleIndex): UntypedFormGroup {
    return this.formGroup.get('roles').get(roleIndex.toString()) as UntypedFormGroup;
  }

  public addSubSubject(subRoleCode, role) {
    this.addSub.emit({
      subRoleCode,
      roleCode: role.role,
      subjId: role.id
    });
  }

  public deleteSubSubject(subj, role) {
    this.deleteSub.emit({
      subRoleCode: subj.roleCode,
      subSubjId: subj.id,
      roleCode: role.role,
      subjId: role.id
    });
  }

  public onCheckboxChangeValue(controlName: string, roleCode: string) {
    const subject = this.getFilteredRolesOf(roleCode)[0];
    const subjectEqualHolder = PlcObjectUtils.clone(this.contractor);
    subjectEqualHolder.role = roleCode;
    if (!!this.formGroup.controls[controlName].value) {  // checkbox value is "YES"
      if (subject) {
        this.onDelete(subject);
      }
      this.selectedRoleEqualHolder.emit(subjectEqualHolder);
    }
    if (!this.formGroup.controls[controlName].value) {  // checkbox values is "NO"
      this.onDelete(subjectEqualHolder);
    }
    this.rolesCheckboxService.setCheckboxValueIntoMap(roleCode, this.formGroup.controls[controlName].value);
  }

  protected addCheckboxControl() {
    if (!!this.enableDelegateCheckbox) {
      this.ifDontExistControlCreate('delegateCheckbox');
    }
    if (!!this.enableLegalGuardianCheckbox) {
      this.ifDontExistControlCreate('legalGuardianCheckbox');
    }
    if (!!this.enableThirdPayerCheckbox) {
      this.ifDontExistControlCreate('thirdPayerCheckbox');
    }
  }

  /**
   * @param control name
   * @description checks if the control is already present in the group and if isn't it will create
   */
  private ifDontExistControlCreate(control: string) {
    if (!this.formGroup.controls[control]) {
      this.formGroup.addControl(control, new UntypedFormControl());
    }
    this.formGroup.controls[control].setValue(false);
  }
}

<div class="grid-item card card-height-normal card-main">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title" style="max-width: 100%">
        {{ '_CLAIMS_._REGISTRY' | translate }}
        <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
      </h3>
    </div>

    <form [formGroup]="updateExpertForm" data-qa="expert-update-form">

      <div class="row padding-top-15">

        <div class="container-fluid table-white">
            <!-- <br /> -->
          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-taxId-label">{{ "Tax ID" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-taxId-label">{{ '_CLAIMS_._TAX_ID' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="taxId" id="taxId" type="text" name="taxId"
              attr.data-qa="expert-update-taxId-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-nationality-label">{{ "Nationality" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-nationality-label">{{ '_CLAIMS_._NATIONALITY' | translate }}</label>
            <select class="core-select col-sm-6 form-control" formControlName="nationality" id="nationality" attr.data-qa="nationality-select">
              <option></option>
              <option *ngFor="let nazioneValue of nazione; " value="{{nazioneValue.codice}}">
                {{nazioneValue.descrizione}}</option>
            </select>
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-gender-label">{{ "Gender" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-gender-label">{{ '_CLAIMS_._GENDER' | translate }}</label>
            <select class="core-select form-control" formControlName="gender" id="gender" name="gender"
              attr.data-qa="gender-select">
              <option></option>
              <option *ngFor="let gender of allGenders" value="{{ gender.codice }}">
                {{ gender.descrizione }}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-name-label">{{ "Name" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-name-label">{{ '_CLAIMS_._NAME' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="name" id="name" type="text" name="name"
              attr.data-qa="expert-update-name-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-surname-label">{{ "Surname" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-surname-label">{{ '_CLAIMS_._SURNAME' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="surname" id="surname" type="text" name="surname"
              attr.data-qa="expert-update-surname-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-title-label">{{ "Denomination" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-title-label">{{ '_CLAIMS_._DENOMINATION' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="title" id="title" type="text" name="title"
              attr.data-qa="expert-update-title-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-toponym-label">{{ "Toponym" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-toponym-label">{{ '_CLAIMS_._TOPONYM' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="toponym" id="toponym" type="text" name="toponym"
              attr.data-qa="expert-update-toponym-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-address-label">{{ "Address" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-address-label">{{ '_CLAIMS_._ADDRESS' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="address" id="address" type="text" name="address"
              attr.data-qa="expert-update-address-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-civicNumber-label">{{ "Civic Number" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-civicNumber-label">{{ '_CLAIMS_._CIVIC_NUMBER' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="civicNumber" id="civicNumber" type="text" name="civicNumber"
              attr.data-qa="expert-update-civicNumber-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-city-label">{{ "City" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-city-label">{{ '_CLAIMS_._CITY' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="city" id="city" type="text" name="city"
              attr.data-qa="expert-update-city-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-province-label">{{ "Province" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-province-label">{{ '_CLAIMS_._PROVINCE' | translate }}</label>
            <select class="core-select col-sm-6 form-control" formControlName="province" id="province" attr.data-qa="province-select">
              <option></option>
              <option *ngFor="let provinciaValue of provincia; " value="{{provinciaValue.codice}}">
                {{provinciaValue.descrizione}}</option>
            </select>
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-nationAddress-label">{{ "Nation Address" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-nationAddress-label">{{ '_CLAIMS_._NATION_ADDRESS' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="nationAddress" id="nationAddress" type="text" name="nationAddress"
              attr.data-qa="expert-update-nationAddress-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-phone-label">{{ "Phone" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-phone-label">{{ '_CLAIMS_._PHONE' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="phone" id="phone" type="text" name="phone"
              attr.data-qa="expert-update-phone-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-mobilePhone-label">{{ "Mobile Phone" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-mobilePhone-label">{{ '_CLAIMS_._MOBILE_PHONE' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="mobilePhone" id="mobilePhone" type="text" name="mobilePhone"
              attr.data-qa="expert-update-mobilePhone-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-fax-label">{{ "Fax" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-fax-label">{{ '_CLAIMS_._FAX' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="fax" id="fax" type="text" name="fax"
              attr.data-qa="expert-update-fax-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-email-label">{{ "Email" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-email-label">{{ '_CLAIMS_._EMAIL' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="email" id="email" type="text" name="email"
              attr.data-qa="expert-update-email-input" />
          </div>

          <div class="col-sm-6">
            <!-- <span attr.data-qa="expertupdate-pec-label">{{ "Pec" | translate }}:</span> -->
            <label class="label-core" attr.data-qa="expertupdate-pec-label">{{ '_CLAIMS_._PEC' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="pec" id="pec" type="text" name="pec"
              attr.data-qa="expert-update-pec-input" />
          </div>
        </div>
      </div>

      <span *ngIf="validationMessages && validationMessages.length > 0" class="col-sm-12 content-error validate-error"
        attr.data-qa="invoice-update-error">
        <span class="rgifont rgi-exclamation-triangle"></span>
        <span *ngFor="let message of validationMessages; let even = even; let odd = odd; let invoiceDetailsIndex = index">
          {{message | translate}}
          <br>
        </span>
      </span>

      <div class="btn-group btn-group-justified">
        <div class="btn-group">
          <button type="submit" class="btn btn-warning pull-right" attr.data-qa="expert-update-find-btn"
            id="expert-update-submit" (click)="confirmExpert()">
            {{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="note-container">
    <div *ngIf="data.hasLeftColumn" [ngClass]="['column','left', leftColumnStyle]">
        <div [ngClass]="['note']">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 5%;">
                        <span class="glyphicon glyphicon-send" 
                        style="width:5px; height:5px; color: #dc6a0d;" *ngIf="data.mailed" title="mailed"></span>
                        <span class="glyphicon glyphicon-envelope"  *ngIf="!data.mailed"
                        style="width:5px; height:5px;" ></span></td>
                    <td >{{data.category }}</td>
                    <td style="width: 40%;text-align: center;"></td>
                </tr>
            </table>
            
             <br>
            <p (click)="getPreviewDocument()" style="cursor: pointer;color: #209ef6">{{data.documentName }}</p>
               
            <div class="footer">
             <span class="rgifont rgi-user"></span> {{ data.user }} | {{ data.date | date: 'dd/MM/yyyy - HH:mm:ss' }}
            </div>
        </div>
    </div>
    <div *ngIf="data.hasRightColumn" [ngClass]="['column', 'right', rightColumnStyle]">
        
    </div>
</div>
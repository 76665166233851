export class TechnicalData {


  constructor(
    public antiPollutionCode: string,
    public creationDate: Date,
    public cylindersNumber: number,
    public gearshiftType: string,
    public gearsNumber: number,
    public height: number,
    public length: number,
    public pitch: number,
    public powerGM: number,
    public updatingDate: Date,
    public valveNumber: number,
    public width: number,
    public engineCycleType: string,
    public torqueKGM: number,
    public torqueGM: number,
    public torqueNM: number,
    public reservoirCapacity: string,
    public cylindersArrangementDescription: string,
    public catalyzed: boolean,
    public bodyworkCod: string,
    public vehicleTypeCode: string,
    public plantTypeCode: string,
    public seatsNumber: number,
    public doorsNumber: number,
    public driveWheelType: string,
    public hybridVehice: boolean,
    public turbo: boolean,
    public maximumSpeed: number
  ) {
  }
}


import { Injectable } from '@angular/core';
import { ErrorType } from '../models/response.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuotationValidationService {
  public validationMessages: Map<string, {message: string, type: string}[]> = new Map<string, {message: string, type: string}[]>();

  validationChanged = new BehaviorSubject<boolean>(false);

  constructor() {
   }

  setMessages(key: string, messages: {message: string, type: string}[]) {
    const oldMessages = this.getMessagesByKey(key);
    this.validationMessages.set(key, oldMessages.concat(messages));
    this.validationChanged.next(true);
  }

  getMessagesByKey(key) {
    const val = this.validationMessages.get(key);
    return !!val ? val : []; // copro gli undefined
  }

  clear(key) {
    if ('benef' === key) {
      this.validationMessages.forEach((value, k) => {
        const benefSplittedKey = key.split('-');
        const cathegory = benefSplittedKey && benefSplittedKey[2] || '';
        if (k.startsWith('benef') && k.endsWith(cathegory)) {
          this.validationMessages.delete(k);
        }
      });
      return;
    }
    this.validationMessages.delete(key);
    this.validationChanged.next(true);
  }

  init() {
    this.validationMessages = new Map<string, {message: string, type: string}[]>();
    this.validationChanged.next(true);
  }

  resetMessages(key) {
    this.validationMessages.set(key, []);
    this.validationChanged.next(true);
  }

  setMessage(key: string, message: {message: string, type: string}) {
    let oldMessages = this.getMessagesByKey(key);
    if (!oldMessages) {
      oldMessages = [];
    }
    // avoid push of duplicate messages
    if (!!oldMessages.find(msg => msg.message === message.message)) {
      return;
    }
    oldMessages.push(message);
    this.validationMessages.set(key, oldMessages);
    this.validationChanged.next(true);
  }

  getBlocking(key): {message: string, type: string}[] {
    const allMsgs = this.getMessagesByKey(key);
    return allMsgs.filter(msg => msg.type === ErrorType.ERROR);
  }

  getNonBlocking(key): {message: string, type: string}[] {
    const allMsgs = this.getMessagesByKey(key);
    return allMsgs.filter(msg => msg.type === ErrorType.WARNING || msg.type === ErrorType.AUTH || msg.type === ErrorType.INFO);
  }

  getAllBlocking(): {message: string, type: string}[] {
    return this.getAllMsgByType(ErrorType.ERROR);
  }

  getAllWarning(): {message: string, type: string}[] {
    return this.getAllMsgByType(ErrorType.WARNING);
  }

  getAllInfo(): {message: string, type: string}[] {
    return this.getAllMsgByType(ErrorType.INFO);
  }

  getAllAuth(): {message: string, type: string}[] {
    return this.getAllMsgByType(ErrorType.AUTH);
  }

  private getAllMsgByType(type: string) {
    let messages = [];
    this.validationMessages.forEach((value: {message: string, type: string}[], key: string) => {
      const msg = this.getMessagesByKey(key).filter(mex => mex.type === type);
      messages = messages.concat(msg);
    });
    return messages;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardService, DigitalClaimsCommonAngularModule,
         enumServiceProvider, getSystemPropertyProvider } from '@rgi/digital-claims-common-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExpertsComponent } from './experts.component';
import { ExpertsSearchComponent } from './experts-search/experts-search.component';
import { ExpertsListComponent } from './experts-list/experts-list.component';
import {
  ExpertDetailsComponent,
 UiModalDialogDeleteLinkComponent,
  UiModalDialogNewLinkComponent
} from './expert-details/expert-details.component';
import {
  RgiRxModalModule,
  RgiRxFormElementsModule,
  RgiRxPanelModule,
  RgiRxTableModule, RgiRxExpansionModule, RgiRxSnackbarModule
} from '@rgi/rx/ui';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [
        ExpertsComponent,
        ExpertsSearchComponent,
        ExpertsListComponent,
        ExpertDetailsComponent,
        UiModalDialogDeleteLinkComponent,
        UiModalDialogNewLinkComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        FormsModule,
        ReactiveFormsModule,
        DigitalClaimsCommonAngularModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        [NgbModule],
        RgiRxFormElementsModule,
        RgiRxModalModule,
        RgiRxPanelModule,
        RgiRxTableModule,
        RgiRxFormElementsModule,
        RgiRxExpansionModule,
        RgiRxSnackbarModule,
    ],
    exports: [
        ExpertsComponent
    ],
    providers: [
        { provide: 'expertsSearchComponent', useValue: ExpertsSearchComponent },
        { provide: 'expertsListComponent', useValue: ExpertsListComponent },
        { provide: 'expertDetailsComponent', useValue: ExpertDetailsComponent },
        enumServiceProvider,
        getSystemPropertyProvider
    ]
})



export class ExpertsModule {

  constructor(cardService: CardService) {
    const title = '_EXPERTS';
    const titleSubCard = 'ExpertsSubCard';
    const moduleName = 'experts';
    const parentCardName = 'claimsFinder';
    const moduleNameSub = 'expertsListSubCard';
    const communication = {};

    cardService.registerCard(
        moduleName,
        title,
        ExpertsComponent
    );
  }


}

import { State } from "@rgi/rx/state";
import { SearchPaymentsOutput } from "../models/payments/search-payments-output.model";
import { SearchWrap } from "../models/search-wrap.model";
import { SearchPayments } from "../models/payments/search-payments.model";

export class ValidationConsolidationState extends State {
    searchPaymentsInputDTO: SearchPayments;
    searchPaymentsOutputDTO: SearchPaymentsOutput[] = [];
    
    searchWrap: SearchWrap = new SearchWrap();

    bookValidation: SearchPaymentsOutput[];
    bookConsolidation: SearchPaymentsOutput[];
}
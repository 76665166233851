<form *ngIf="stateMgr.getState$() | async as state">
<div [ngClass]="{'rgi-ui-panel-content': state.isModal}">
  <rgi-rx-datatable *ngIf="partiesTableData && partiesTableData.length"
                    [data]="partiesTableData"
                    [schema]="PARTY_LIST_MODAL_TABLE_SCHEMA"
                    [selectable]="false"
                    [expansionRow]="residenceDetailRow"
                    (onAction)="onActionClick($event)">

    <ng-template rgiRxDataTableCell="counterTerrorism" let-ctx>
      <div style="display: flex" *ngIf="isVisibleTerroristInfo(ctx.row)">
        <span [rgiRxTooltip]="returnTerroristInfoMsg(ctx.row)"
              [position]="'top'" class="rgi-ui-btn-outline rgifont rgi-binoculars iconDT rgi-anag-text-tone">
        </span>
      </div>
    </ng-template>
  </rgi-rx-datatable>
  <ng-template #residenceDetailRow let-partyRow>
    <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._RESIDENCE_</p>
    <p data-qa="party-residence">{{partyRow.residence.formatAddress}}</p>
    <p *ngIf="isTerrorismVisibile()" class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._ANTI_TERRORISM_</p>
    <div class="rgi-ui-grid-1" *ngIf="isTerrorismVisibile()">
      <div class="rgi-ui-col">
        <div class="story-grid-column-content" data-qa="partyRow-terrorism">{{'_ANAG_._LABEL_._COUNTER_TERRORISM_INDICATOR_' | translate}}:
          <b [ngStyle]="{'color' : partyRow.isTerrorist ? 'red' : '' }">{{partyRow.terrorismInfo.msg}}</b></div>
      </div>
      <div class="rgi-ui-col">
        <div class="story-grid-column-content" data-qa="dataPartyRow-terrorism">{{'_ANAG_._LABEL_._DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_' | translate}}:
          <b>{{partyRow.terrorismInfo.date | date: 'dd/MM/yyyy'}}</b><b *ngIf="!partyRow.terrorismInfo.date">-</b></div>
      </div>
    </div>
  </ng-template>
  <div class="rgi-ui-snackbar rgi-ui-danger" *ngIf="state.serviceError" data-qa="rgi-anag-httpError">
    <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
    <span class="rgi-ui-message" translate>{{state.serviceError}}</span>
  </div>
  <div class="rgi-ui-snackbar rgi-ui-default" *ngIf="showRefineSearch">
    <span class="rgi-ui-message" data-qa="partyNotFoundMsg" translate>_ANAG_._MSG_._REFINE_SEARCH_</span>
  </div>
</div>
<div class="rgi-ui-panel-footer">
  <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionGoBack()" data-qa="anag-btn-back"
          translate>_ANAG_._BTN_._BACK_
  </button>
  <button class="rgi-ui-btn rgi-ui-btn-primary" *ngIf="isNewButtonVisible()" type="button" (click)="createNewParty()"
          data-qa="anag-btn-new" translate>_ANAG_._BTN_._NEW_
  </button>
</div>
</form>

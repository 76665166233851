import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Assets} from '../../models/domain-models/assets';
import {Asset} from '../../models/domain-models/asset';
import {PartyAsset} from '../../models/domain-models/party-asset';
import {HttpClient} from '@angular/common/http';
import {map, share} from 'rxjs/operators';
import {Variables} from '../../models/domain-models/variables';

@Injectable({
  providedIn: 'root'
})
export class ReIssueAssetsResourceService {

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any
  ) {
  }

  get(contract: string): Observable<Assets> {
    return this.http.get<Assets>(this.getAssetsUri(contract)).pipe(
      share()
    );
  }

  put(contract: string, assetCode: string): Observable<Asset> {
    return this.http.put<Asset>(this.putAssetUri(contract, assetCode), null).pipe(
      share(),
      map(st => {
        return st;
      })
    );
  }

  putAssetParties(contract: string, assetId: string, passet: PartyAsset): Observable<PartyAsset> {
    return this.http.put<PartyAsset>(this.putAssetPartyUri(contract, assetId), passet).pipe(
      share(),
      map(pAsset => {
        return pAsset;
      })
    );
  }

  putAssetVariables(contract: string, assetId: string, variableList: Variables): Observable<Variables> {
    return this.http.put<Variables>(this.putAssetVariablesUri(contract, assetId), variableList).pipe(
      share(),
      map(resp => {
        return resp;
      })
    );
  }

  deleteAsset$(resourceid: string, name: string) {
    return this.http.delete<Assets>(this.deleteAssetUri(resourceid, name)).pipe(
      share(),
      map(pAsset => {
        return pAsset;
      })
    );
  }

  getAssetParties(contractId: string, assetId: string): Observable<Array<PartyAsset>> {
    return this.http.get<any>(this.getAssetPartiesUri(contractId, assetId));
  }

  getAssetsUri(contract: string): string {
    // TODO: use the config tokens and constants
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/assets';
  }

  putAssetPartyUri(contract: string, assetId: string): string {
    // TODO: use the config tokens and constants
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/' + assetId + '/party';
  }

  putAssetUri(contract: string, assetCode: string): string {
    // TODO: use the config tokens and constants
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/asset?assetCode=' + assetCode;
  }

  putAssetVariablesUri(contract: string, assetId: string) {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/' + assetId + '/variables';
  }

  deleteAssetUri(contract: string, assetCode: string) {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/' + assetCode;
  }

  getAssetPartiesUri(contract: string, assetId: string) {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/' + assetId + '/parties';
  }

  postAsset(resourceId: string, selectedAsset: any): any {
    // TODO: Method not implemented yet
    throw new Error('Method not Implemented yet');
    // return of(null);
  }
}

<div class="col-md-8 life-detail-margin-box life-detail-box" id="consulting-detail" *ngIf="!!instalments?.length">
  <div class="row life-detail-card-title-container">
    <div class="row life-detail-title">
        <div class="card-title">
            <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;" (click)="back()" data-qa="back-arrow"></span>
            <span class="separator"> | </span>
            <span class="menu-title" translate>lpc_quotation_premium_details</span>
        </div>
    </div>
  </div>
  <br>
  <div class="col-lg-12 blank lpc-warranty" *ngFor="let item of instalments">
    <div class="row header-warranties">
      <div class="col-lg-10">
        <p style="font-weight: bold" translate>{{item?.description}}</p>
      </div>
    </div>

    <table class="table-responsive table coreDT life-detail-margin-top">
      <thead>
        <tr class="head-result thDataTable life-detail-tbld_row_header">
          <th *ngFor="let header of headers; let i = index" [ngClass]="{'align-text-center': i !== 0}">{{ header.title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-row table-background" *ngFor="let inst of item?.warranties">
          <td *ngFor="let header of headers; let i = index" class="table-border" [attr.data-qa]="header" [ngClass]="{'align-text-center': i !== 0}">
             <!-- condition to view a tooltip for the fees and the extra premium with a possible action onclick -->
             <ng-container *ngIf="header.toolTip && isObjectFull(inst, header); else justNumber">
              <span lpcTooltip="lpc_Visualizza_dettaglio"
                    placement="top"
                    delay="200"
                    class="premium-link"
                    (click)="openTemplate({
                      instName: item?.description,
                      mainTitle: header.title,
                      title: inst.description,
                      data: inst.extraData[header.field === FEES_FIELD ? FEE_INFO_FIELD : EXTRAP_INFO_FIELD]
                    })"
              >
                {{ inst[header.field] | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions }}
              </span>
            </ng-container>
            <ng-template #justNumber>
              {{ inst[header.field] | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions }}
            </ng-template>
          </td>
        </tr>
        <ng-container *ngIf="!!item?.total">
          <tr class="table-row table-background" style="background: #9e9e9e73; font-weight: bold;">
            <td *ngFor="let header of headers; let i = index" class="table-border" [attr.data-qa]="'total-td' + header.field" [ngClass]="{'align-text-center': i !== 0}">
              <ng-container *ngIf="i === 0; else default">
                  {{'lpc_total' | translate}}
              </ng-container>
              <ng-template #default>
                  {{item?.total[header.field] | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>


  </div>
</div>

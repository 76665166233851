import {PolicyDate} from '../domain-models/parameters/policy-date';

export class PaymentFrequency {

  constructor(
    public code: string,
    public description: string,
    public instalmentNumber: number,
    public id: number,
    public intrestRate: number,
    public intrestRate2: number,
    public instalmentType: number,
    public visible: boolean,
    public durationMax: number,
    public durationMin: number,
    public selected: boolean,
    public order: number,
    public usageOnQuotation: boolean,
    public usageOnProposal: boolean,
    public instalmentDates: Array<PolicyDate>,
    public paymentFrequencyType: number,
    public derogationLevel:number
  ) {
  }

}

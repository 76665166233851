import {
  AfterContentInit,
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SkipSelf,
  ViewContainerRef
} from '@angular/core';
import {RgiRxMenuComponent} from './rgi-rx-menu/rgi-rx-menu.component';
import {ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {Directionality} from '@angular/cdk/bidi';
import {TemplatePortal} from '@angular/cdk/portal';
import {combineLatest, fromEvent, merge, of, Subscription} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {RgiRxMenuItemDirective} from './rgi-rx-menu-item.directive';
import {LEFT_ARROW, RIGHT_ARROW} from '@angular/cdk/keycodes';
import {RgiRxMenuTriggerEvent} from './rgi-rx-menu-api';
import {FocusOrigin} from '@angular/cdk/a11y';
import {RgiRxQAService} from '@rgi/rx';
import {DOCUMENT} from '@angular/common';


@Directive({
  selector: '[rgiRxMenuTrigger]',
  host: {
    '[attr.rgi-rx-trigger-for]': 'menu.id'
  },
  exportAs: 'rgiRxMenuTrigger',
  providers: [
    RgiRxQAService
  ]
})
export class RgiRxMenuTriggerDirective implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

  private _menu: RgiRxMenuComponent;
  private _overlayRef?: OverlayRef;
  private _portal: TemplatePortal;
  private _closingActionsSubscription: Subscription = Subscription.EMPTY;
  private _parentHoverSubscription: Subscription = Subscription.EMPTY;
  private _openedBy: FocusOrigin;
  private sourceEventsSubscription: Subscription = Subscription.EMPTY;
  private _triggerSubMenu: boolean;

  constructor(
    private _overlay: Overlay,
    private _element: ElementRef<HTMLElement>,
    private _dir: Directionality,
    private _vc: ViewContainerRef,
    private _qaService: RgiRxQAService,

    @Inject(DOCUMENT) private document: Document | any,
    @Optional() @Self() private _menuItemInstance: RgiRxMenuItemDirective,
    @Optional() @SkipSelf() private _parentMenu: RgiRxMenuComponent
  ) {
  }

  private menuCloseSubscription: Subscription = Subscription.EMPTY;


  @Input('rgiRxMenuTrigger') get menu(): RgiRxMenuComponent {
    return this._menu;
  }

  set menu(value: RgiRxMenuComponent) {
    this._menu = value;
  }

  @Input() sourceEvents: RgiRxMenuTriggerEvent[] = ['click'];


  private subscribeSourceEvents() {

    this.sourceEventsSubscription =
      combineLatest(this.sourceEvents.map(s => fromEvent(this._element.nativeElement, s)))
        .pipe(tap(
          event => {
            if (!!this._parentMenu) {
              return;
            }
            if (this._overlayRef && this._overlayRef.hasAttached()) {
              this.closeMenu();
              return;
            }
            this.openMenu();
          }
        ))
        .subscribe();
  }


  openMenu() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      return;
    }
    const overlayRef = this._createOverlay();
    overlayRef.attach(this._getPortal());
    this._qaService.render(this.menu.elementRef.nativeElement, {
      id: this.menu.id,
      type: 'menu'
    }, 'child');
    this._menu.focusFirstItem(this._openedBy);
    this._closingActionsSubscription = this._menuClosingActions().subscribe(() => this.closeMenu());
  }

  closeMenu() {
    this.menu.onClose.emit();
    this._qaService.clear(this.menu.elementRef.nativeElement, 'child');
    if (this.triggersSubmenu()) {
      this._element.nativeElement.focus();
    }
    this._closingActionsSubscription.unsubscribe();
  }

  isOpen(): boolean {
    return !!(this._overlayRef && this._overlayRef.hasAttached());
  }


  @HostBinding('class.rgi-ui-submenu-trigger') get triggerSubMenu(): boolean {
    return this._triggerSubMenu;
  }

  @HostListener('keydown', ['$event'])
  _handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    if (
      this.triggersSubmenu() &&
      ((keyCode === RIGHT_ARROW && this._dir.value === 'ltr') ||
        (keyCode === LEFT_ARROW && this._dir.value === 'rtl'))
    ) {
      this._openedBy = 'keyboard';
      this.openMenu();
    }
  }

  private triggersSubmenu() {
    return !!(this.menu && this._parentMenu);
  }

  ngOnInit(): void {
    this.menuCloseSubscription = this.menu.onClose
      .pipe(
        filter(() => this.isOpen()),
        tap(close => {
          this._overlayRef.detach();
        })
      )
      .subscribe();

  }

  ngAfterViewInit(): void {
    this.subscribeSourceEvents();
  }


  ngAfterContentInit(): void {
    if (!this._parentMenu) {
      return;
    }

    this._menu.parentMenu = this._parentMenu;
    this._parentHoverSubscription = this._parentMenu._hovered()
      .pipe(
        filter(active => active === this._menuItemInstance && !active.disabled),
        tap((active) => {
          this.openMenu();
        })
      )
      .subscribe();
    this._triggerSubMenu = this.triggersSubmenu();
  }

  ngOnDestroy(): void {
    this._destroyMenu();
    this._closingActionsSubscription.unsubscribe();
    this.menuCloseSubscription.unsubscribe();
    this.sourceEventsSubscription.unsubscribe();
  }

  private _destroyMenu() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this.getOverlayPosition(),
      backdropClass: 'rgi-ui-menu-overlay-backdrop',
      panelClass: 'rgi-ui-menu-overlay',
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      direction: this._dir,
    });
  }

  private _overlayClickOutside() {
    return fromEvent<MouseEvent>(this.document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          const notOrigin = clickTarget !== this._element.nativeElement;
          const notChildren = !Array.from(this._element.nativeElement.childNodes).find(n => n === clickTarget);
          const notOverlay = !!this._overlayRef && (this._overlayRef.overlayElement.contains(clickTarget) === false);
          const trigger = clickTarget.getAttribute('rgi-rx-trigger-for');
          const notATrigger = !!trigger;
          const sameMenu = trigger === this._menu.id;

          return notOrigin && notChildren && (notATrigger || !sameMenu);
        }),
        takeUntil(this._overlayRef.detachments())
      );
  }

  private getOverlayPosition() {
    const positions = [
      new ConnectionPositionPair(
        {originX: 'end', originY: 'top'},
        {overlayX: 'start', overlayY: 'top'}
      ),
      new ConnectionPositionPair(
        {originX: 'start', originY: 'bottom'},
        {overlayX: 'end', overlayY: 'top'}
      )
    ];
    return this._overlay
      .position()
      .flexibleConnectedTo(this._element.nativeElement)
      .withPositions(positions)
      .withDefaultOffsetY(-1)
      .withGrowAfterOpen();
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const config = this._getOverlayConfig();
      this._overlayRef = this._overlay.create(config);

      // Consume the `keydownEvents` in order to prevent them from going to another overlay.
      this._overlayRef.keydownEvents().subscribe();
    }

    return this._overlayRef;
  }


  private _getPortal(): TemplatePortal {
    if (!this._portal || this._portal.templateRef !== this.menu.templateRef) {
      this._portal = new TemplatePortal(this.menu.templateRef, this._vc);
    }

    return this._portal;
  }

  private _menuClosingActions() {
    const backdrop = this._overlayRef!.backdropClick();
    const detachments = this._overlayRef!.detachments();
    const parentClose = this._parentMenu ? this._parentMenu.onClose : of();
    const overlayClickOutside = this._overlayClickOutside();

    const hover = this._parentMenu
      ? this._parentMenu._hovered().pipe(
        filter(active => active !== this._menuItemInstance),
        filter(() => this.isOpen()),
      )
      : of();

    return merge(
      backdrop,
      parentClose,
      overlayClickOutside,
      hover,
      detachments);
  }
}

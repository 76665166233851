import { Injectable } from "@angular/core";
import { ModalService } from "@rgi/rx/ui";
import { combineLatest, Observable, of } from "rxjs";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { ConfirmComponent } from "./confirm.component";

@Injectable()
export class ConfirmService {

    constructor(private modalService: ModalService) {}

    getConfirm$(message: string): Observable<boolean> {

       return of({message}).pipe(
            mergeMap(
                m => {
                    const modalRef = this.modalService.createModalComponent(ConfirmComponent);
                    modalRef.component.message = m.message;
                    modalRef.modal.enableClickBackground = false;
                    modalRef.modal.open();
                    return combineLatest([of(modalRef), modalRef.component.getConfirm$()]);
                }
            ),
            take(1),
            tap(([modalRef]) => modalRef.modal.close()),
            map(([, confirm]) => confirm)
        )
        
    }
}
import {RgiRxi18nModuleLoadType, RgiRxTranslations, Serialized18n} from '@rgi/rx/i18n';
import {IT} from './it';
import {EN} from './en';
import { ES } from './es';

export function portalNgCommonI18nLoaderIT(): RgiRxi18nModuleLoadType {
    return new Promise<Serialized18n>((resolve) => resolve(IT));
}

export function portalNgCommonI18nLoaderEN(): RgiRxi18nModuleLoadType {
    return new Promise<Serialized18n>((resolve) => resolve(EN));
}
export function portalNgCommonI18nLoaderES(): RgiRxi18nModuleLoadType {
    return new Promise<Serialized18n>((resolve) => resolve(ES));
}

export const PORTAL_NG_COMMON_TRANSLATIONS: RgiRxTranslations = [
    {
        load: portalNgCommonI18nLoaderIT,
        locale: 'it'
    },
    {
        load: portalNgCommonI18nLoaderEN,
        locale: 'en'
    },
    {
        load: portalNgCommonI18nLoaderES,
        locale: 'es'
    }
];

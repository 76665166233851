import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PcConfigurationsResponse} from '../../../models/pc-portfolio-models/pc-configurations-response';
import {Observable} from 'rxjs';
import {PcConfigurationRequest} from '../../../models/pc-portfolio-models/pc-configuration-request';
import {share} from 'rxjs/operators';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioConfigurationResourceService {
  private apiPcPortfolioConf: any;

  constructor(
    protected httpClient: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;
  }


  postConfigurations(request: PcConfigurationRequest): Observable<PcConfigurationsResponse> {
    return this.httpClient.post<PcConfigurationsResponse>(this.getConfigurationsUri(), request).pipe(
      share()
    );
  }

  getConfigurationsUri() {
    return this.apiConf.api.portal.path + '/v2' +
      this.apiPcPortfolioConf.path +
      this.apiPcPortfolioConf.configurations;
  }

}

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {
  MeanOfPayment,
  PaymentConfig,
  PaymentsField,
  PaymentsPayload
} from '../../../group-policy-models/group-policy-issue-policy-data';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ibanRejex} from '../../../group-policy-constants/general';
import {mapPayment} from '../../../adapters/group-policy-utils';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

export class PaymentModalData {
  paymentToEdit: MeanOfPayment;
  config: PaymentConfig;
  isDebitBtnDisabled: boolean;
  isCreditBtnDisabled: boolean;

  constructor(config, paymentToEdit?) {
    this.config = config;
    this.paymentToEdit = paymentToEdit;
  }
}

@Component({
  selector: 'rgi-gp-payments-modal',
  templateUrl: './group-policy-payments-modal.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyPaymentsModalComponent implements OnInit, OnModalClose {
  payments: PaymentConfig;
  paymentToEdit: MeanOfPayment;
  selectedPayments: MeanOfPayment[];
  paymentFields: PaymentsField[];

  editPaymentForm: UntypedFormGroup;
  paymentFieldsForm: UntypedFormGroup;

  selectedMethodLabel: string;
  isDebitBtnDisabled: boolean;
  isCreditBtnDisabled: boolean;

  modalClose: EventEmitter<any> = new EventEmitter<any>();

  invalidCardValidator: ValidatorFn = (): ValidationErrors | null => {
    const monthControl = this.paymentFieldsForm.get('dcardexpiremonth');
    const yearControl = this.paymentFieldsForm.get('dcardexpireyear');
    const monthControlValued = monthControl && monthControl.value;
    const yearControlValued = yearControl && yearControl.value;
    if (monthControlValued && yearControlValued) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (currentYear === parseInt(yearControl.value, 10) && currentMonth > parseInt(monthControl.value, 10)) {
        return {expiredCard: true};
      }
    }
    if ((monthControlValued && !yearControlValued) || (!monthControlValued && yearControlValued)) {
      return {onlyOneFieldValued: true};
    }
    return null;
  };

  constructor(
    public translateService: RgiRxTranslationService,
    @Optional() @Inject(DIALOG_DATA) modalData: PaymentModalData
  ) {
    this.payments = modalData.config;
    this.paymentToEdit = modalData.paymentToEdit;
    this.isCreditBtnDisabled = modalData.isCreditBtnDisabled;
    this.isDebitBtnDisabled = modalData.isDebitBtnDisabled;
    this.editPaymentForm = new UntypedFormGroup({
      paymentMethod: new UntypedFormControl(undefined, [Validators.required]),
      paymentTypeCode: new UntypedFormControl(undefined, [Validators.required]),
    });
    this.paymentFieldsForm = new UntypedFormGroup({});
  }

  ngOnInit() {
    if (this.paymentToEdit) {
      this.manageInputPayment();
    }
    this.editPaymentForm.get('paymentMethod').valueChanges.subscribe(paymentMethod => {
      this.onPaymentMethodChange(paymentMethod);
      this.managePaymentFields(undefined);
    });
    this.editPaymentForm.get('paymentTypeCode').valueChanges.subscribe(paymentCode =>
      this.managePaymentFields(paymentCode)
    );
  }

  manageInputPayment() {
    const paymentMethod = this.paymentToEdit.paymentConfig.paymentType === '1' ? 'debit' : 'credit';
    const paymentCode = this.paymentToEdit.paymentConfig.meanOfPayment.code;
    this.updateLabel(paymentMethod);
    this.editPaymentForm.get('paymentMethod').setValue(paymentMethod);
    this.editPaymentForm.get('paymentTypeCode').setValue(paymentCode);
    this.selectedPayments = this.paymentToEdit.paymentConfig.paymentType === '1' ? this.payments.debMeansOfPayment :
      this.payments.credMeansOfPayment;
    this.managePaymentFields(paymentCode, this.paymentToEdit.paymentConfig.PaymentsFields);
  }

  onPaymentMethodChange(paymentMethod: string) {
    this.updateLabel(paymentMethod);
    this.selectedPayments = paymentMethod === 'debit' ? this.payments.debMeansOfPayment : this.payments.credMeansOfPayment;
    this.editPaymentForm.get('paymentTypeCode').reset(undefined, {emitEvent: false});
    this.editPaymentForm.get('paymentTypeCode').markAsUntouched();
    this.editPaymentForm.updateValueAndValidity();
  }

  protected managePaymentFields(paymentCode: string, paymentFields?: PaymentsField[]) {
    if (paymentFields) {
      this.paymentFields = paymentFields;
    } else {
      this.paymentFields = paymentCode ? this.selectedPayments.find(payment =>
        payment.paymentConfig.meanOfPayment.code === paymentCode).paymentConfig.PaymentsFields : undefined;
    }
    this.editPaymentForm.removeControl('paymentFields');
    this.paymentFieldsForm = new UntypedFormGroup({});
    if (this.paymentFields && this.paymentFields.length > 0) {
      this.paymentFields.forEach(field => {
        const validators = [];
        if (field.mandatory) {
          validators.push(Validators.required);
        }
        if (field.name === 'ciban') {
          validators.push(Validators.pattern(ibanRejex));
        }
        if (field.name === 'dcardexpire') {
          this.paymentFieldsForm.setValidators(this.invalidCardValidator);
          let monthValue;
          let yearValue;
          if (field.value) {
            const dateValue = new Date(field.value);
            monthValue = dateValue.getMonth() + 1;
            yearValue = dateValue.getFullYear();
          }
          this.paymentFieldsForm.setControl(field.name + 'month', new UntypedFormControl(monthValue,
            {
              validators: [...validators, Validators.min(1), Validators.max(12)]
            }));
          this.paymentFieldsForm.setControl(field.name + 'year', new UntypedFormControl(yearValue,
            {
              validators:
                [...validators, Validators.min(new Date().getFullYear())]
            }));
        } else {
          this.paymentFieldsForm.setControl(field.name, new UntypedFormControl(field.value, validators));
        }
      });
    }
    this.paymentFieldsForm.setParent(this.editPaymentForm);
  }

  onCancel() {
    this.modalClose.emit();
  }

  onConfirm() {
    if (this.paymentFieldsForm.valid && this.editPaymentForm.valid) {
      if (this.paymentFields && this.paymentFields.length > 0) {
        this.paymentFields.forEach(paymentField => {
          if (paymentField.name === 'dcardexpire') {
            const monthForm = this.paymentFieldsForm.get('dcardexpiremonth').value;
            const yearForm = this.paymentFieldsForm.get('dcardexpireyear').value;
            if (yearForm && monthForm) {
              let monthValue = '' + monthForm;
              if (monthValue.length === 1) {
                monthValue = '0' + monthValue;
              }
              paymentField.value = `${yearForm}-${monthValue}-01`;
            }
          } else {
            paymentField.value = this.paymentFieldsForm.get(paymentField.name).value;
          }
        });
      }
      const mappedPayment: PaymentsPayload = mapPayment(this.editPaymentForm.get('paymentTypeCode').value,
        this.editPaymentForm.get('paymentMethod').value === 'debit' ? '1' : '2',
        2, this.paymentFields);
      this.modalClose.emit(mappedPayment);
    }
  }

  getTitle() {
    return this.paymentToEdit ? '_GP_._TITLE_._EDIT_PAYMENT_' : '_GP_._TITLE_._NEW_PAYMENT_';
  }

  updateLabel(paymentMethod: string) {
    this.translateService.translate(paymentMethod === 'debit' ? '_GP_._LABEL_._DEBIT_PAYMENT_METHOD_' :
      '_GP_._LABEL_._CREDIT_PAYMENT_METHOD_').subscribe(label => {
      this.selectedMethodLabel = label;
    });
  }

}

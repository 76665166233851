import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {QuestionnaireFlatI} from "../models/questionnaireFlat";
import {map} from "rxjs/operators";

@Injectable()
export class PassproQuestionnaireService {

  constructor(private httpClient: HttpClient, @Inject('environment') private environment: any) { }

  getPassProQuestionnaire(code: string, language: string): Observable<QuestionnaireFlatI>{
    const path = this.environment.api.portal.host + this.environment.api.portal.path + '/passpro/questionnaire/' + code + "/"+ language;
    return this.httpClient.get<QuestionnaireFlatI>(path)
      .pipe(
        map((resp: any) => resp.questionnaire)
      );
  }
}


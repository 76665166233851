<div>
    <form [formGroup]="searchInvoiceForm" data-qa="invoice-search-form">

        <div class="col-sm-12 container-invoice-search">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="invoice-search-claim-number-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="claimNumber" id="claimNumber" type="text" name="claimNumber" attr.data-qa="invoice-search-claimnumber-input">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="invoice-search-invoicenumber-label">{{ '_CLAIMS_._INVOICE_NUMBER' | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="invoiceNumber" id="invoiceNumber" type="text" name="invoiceNumber" attr.data-qa="invoice-search-invoicenumber-input">

            <div class="col-sm-12">
                <pnc-datepicker label="{{ '_CLAIMS_._INVOICE_DATE' | translate }}" customFormControlName="invoiceDate"
                    [customFormGroup]="searchInvoiceForm" [maxDate]="today"
                    attr.data-qa="invoice-search-invoice-date-input">
                </pnc-datepicker>
            </div>


            <div class="col-sm-6">
                <pnc-datepicker label="{{ '_CLAIMS_._RECEIVED_DATE_FROM' | translate }}" customFormControlName="receivedDateFrom"
                    [customFormGroup]="searchInvoiceForm" [maxDate]="today"
                    attr.data-qa="invoice-search-received-date-from-date-input">
                </pnc-datepicker>
            </div>

            <div class="col-sm-6">
                <pnc-datepicker label="{{ '_CLAIMS_._RECEIVED_DATE_TO' | translate }}" customFormControlName="receivedDateTo"
                    [customFormGroup]="searchInvoiceForm" [maxDate]="today"
                    attr.data-qa="invoice-search-received-date-to-date-input">
                </pnc-datepicker>
            </div>

            <!--
            <div class="col-sm-12">
                <label ng-bind="label">{{ '_CLAIMS_._EXPERT' | translate }}</label>
                <select class="core-select form-control" formControlName="expert" id="expert" name="expert"
                    attr.data-qa="expert-select">
                    <option></option>
                    <option *ngFor="let expert of experts; " value={{expert.name}}|{{expert.surname}}|{{expert.title}}>
                        {{expert.surname}} {{expert.name}} {{expert.title}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
          -->

            <div class="col-sm-12">
              <label ng-bind="label">{{ '_CLAIMS_._EXPERT' | translate }}</label>
              <ng-select [multiple]="false" formControlName="expert" id="expert" name="expert" attr.data-qa="expert-select">
                <ng-option *ngFor="let expert of experts;" value={{expert.name}}|{{expert.surname}}|{{expert.title}}>
                  {{expert.surname}} {{expert.name}} {{expert.title}}
                </ng-option>
              </ng-select>
            </div>

            <div class="col-sm-12">
                <label ng-bind="label">{{ '_CLAIMS_._REJECT_TYPE' | translate }}</label>
                <select class="core-select form-control" formControlName="rejectType" id="rejectType" name="rejectType"
                    attr.data-qa="reject-type-select">
                    <option></option>
                    <option *ngFor="let rejectType of rejectTypes; " value={{rejectType.codice}}>
                        {{rejectType.descrizione}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="invoice-search-sdi-code-label">{{ '_CLAIMS_._SDI_CODE' | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="sdiCode" id="sdiCode" type="text" name="sdiCode" attr.data-qa="invoice-search-claimnumber-input">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="invoice-search-sap-code-label">{{ '_CLAIMS_._SAP_CODE' | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="sapCode" id="sapCode" type="text" name="sapCode" attr.data-qa="invoice-search-claimnumber-input">
            <br>

        </div>

        <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-search-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{errorMessage | translate}}</span>
        </span>

        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button type="button" class="btn btn-warning pull-right" (click)="emptyFields()"
                    attr.data-qa="invoice-search-empty-btn">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
            </div>
            <div class="btn-group">
                <button type="submit" class="btn btn-warning pull-right" attr.data-qa="invoice-search-find-btn"
                    id="invoice-search-submit" (click)="goToSearchResults()">{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
            </div>
        </div>

    </form>

</div>

import {FileEntity} from './file-entity';

export interface DamageRequest {
  idClaimOfDamages: string;
  idDocument: string;
  receiptDate: string;
}

export class DocumentEntity {
   isValidated: boolean;
   id: string;
   uploadNote: string;
   documentName: string;
   toSendEBDS: boolean;
   assetType: string;
   subtitle: string;
   fileType: string;
   uploadDate: string;
   uploadDateTableFormat: string;
   fileName: string;
   receptionDate: string;
   receptionDateTableFormat: string;
   userId: string;
   userName: string;
   mimetype: string;
   microCategory: string;
   microCategoryDescription: string;
   codAnagrafe: string;
   nameAnagrafe: string;
   idDocument: string;
   category: string;
   categoryDescription: string;
   numPages: number;
   file: FileEntity;
   externalVisibility: boolean;
   // shipping
   shippingChannel: string;
   shippingDate: string;
   shippingOutcome: string;
   isClaimDocument: boolean;
   //
   receptionChannel: string;
}

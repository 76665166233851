import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnviromentsService } from './enviroments.service';
import { PolicyService } from './policy-service';

@Injectable({
    providedIn: 'root'
  })
  export class BranchesService {
    protected baseApiUrl: string;

    constructor(private httpClient: HttpClient,
                protected policyService: PolicyService,
                protected environment: EnviromentsService) {
      this.baseApiUrl = environment.baseApiURL;
    }

    getBranches(nodeId: string): Observable<any> {
        return this.httpClient.get(this.baseApiUrl + '/v2/life/branches/' + nodeId );
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EvaluatorService} from "./evaluator.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [EvaluatorService],
  exports: []
})
class EvaluationModule { }

export {
  EvaluationModule,
  EvaluatorService
}

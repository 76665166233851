import {map, take} from 'rxjs/operators';
import {ReIssueSummaryBusinessService} from './../re-issue-business-services/re-issue-summary-business.service';
import {Injectable} from '@angular/core';

import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {Observable, of} from 'rxjs';
import {Asset} from '../../models/domain-models/asset';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {ReIssueProposalManagementNavigationData} from './re-issue-proposal-management-state-manager.service';
import {ReIssueQuotationNavigationDataI} from './re-issue-quotation-state-manager.service';
import {ReIssueSaveQuoteNavigationData} from './re-issue-save-quote-state-manager.service';
import {PcAssetInstance} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {ProposalStatus, Quotation} from '../../models/domain-models/quotation';
import {PolicyContact} from '../../models/domain-models/policy-contact';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {
  ReIssuePortfolioQuotationResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-quotation-resource.service';
import {ReIssuePolicyDataNavigationData} from './re-issue-policy-data-state';
import {ReissueAssetNavigationDataI} from './re-issue-asset-state-manager.service';
import {ReIssueSummaryState} from './re-issue-summary.state';

export interface ReIssueSummaryNavigationData {
  isPersonaGiuridica: boolean;
  resourceId: string;
  policyTechnicalData: PolicyTechnicalData;
  lpsData: LpsData;
  subject: AnagIssueSubject;
  allAssets: Asset[];
  productName: string;
  productId: number;
  branchOffice: string;
  productCode: string;
  proposalStatus: ProposalStatus;
  node: any;
  companyId: string;
  editMode: boolean;
  editFunctionality: boolean;
  isToDisableFraz: boolean;
  isSubstitution: boolean;
  authorize: boolean;
  contact: PolicyContact;
  bIntermediateSaving: boolean;
  clausesFromAsset: Array<PcAssetInstance>;
  assetInstances: Array<PcAssetInstance>;
  quotation: Quotation;

}

@Injectable()
export class ReIssueSummaryStateManagerService<T extends ReIssueSummaryState> extends AbstractStateManager<T> {
  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected summaryBusinessService: ReIssueSummaryBusinessService<T>,
    protected quotationResourceService: ReIssuePortfolioQuotationResourceService
  ) {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newReIssueSummaryState();

    this.updateState$(this.initSummary(st)); // this update the state
  }

  initSummary(state: T): Observable<T> {
    const req = this.activeRoute.getRouteData<ReIssueSummaryNavigationData>();
    if (req.resourceId) {
      state.resourceId = req.resourceId;
    }

    if (req.allAssets) {
      state.allAssets = req.allAssets;
    }
    state.isPersonaGiuridica = req.isPersonaGiuridica;
    if (req.productName) {
      state.productName = req.productName;
    }

    if (req.productId) {
      state.productId = req.productId;
    }

    if (req.policyTechnicalData) {
      state.policyTechnicalData = req.policyTechnicalData;
    }

    if (req.lpsData) {
      state.lpsData = req.lpsData;
    }

    if (req.subject) {
      state.subject = req.subject;
    }

    if (req.productCode) {
      state.productCode = req.productCode;
    }

    if (req.proposalStatus) {
      state.proposalStatus = req.proposalStatus;
    }

    if (req.node) {
      state.node = req.node;
    }

    if (req.companyId) {
      state.companyId = req.companyId;
    }

    if (req.assetInstances) {
      state.assetInstances = req.assetInstances;
    }

    if (req.quotation) {
      state.quotation = req.quotation;
    }

    if (req.editMode) {
      state.editMode = req.editMode;
    }

    if (req.editFunctionality) {
      state.editFunctionality = req.editFunctionality;
    }

    if (req.contact) {
      state.contact = req.contact;
    }
    if (req.clausesFromAsset) {
      state.clausesFromAsset = req.clausesFromAsset;
    }

    // state.editMode = req.editMode;
    // state.editFunctionality = req.editFunctionality;
    state.isToDisableFraz = req.isToDisableFraz;
    state.isSubstitution = req.isSubstitution;

    state.authorize = req.authorize;
    state.bIntermediateSaving = req.bIntermediateSaving;

    // return of(state);
    return this.summaryBusinessService.getProposal(state);
  }

  newReIssueSummaryState(): T {
    return new ReIssueSummaryState(this.activeRoute.key) as T;
  }

  saveProposal(): Observable<T> {
    return this.summaryBusinessService.saveProposal(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  saveAuthorization(): Observable<T> {
    return this.summaryBusinessService.saveAuthorization(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  back() {
    this.getState$().pipe(take(1)).subscribe(st => {
      if (st.contractNumber) {
        delete st.contractNumber;
        this.updateState$(of(st));
      }
      const req: ReIssueQuotationNavigationDataI = {
        resourceId: st.resourceId,
        subject: st.subject,
        policyTechnicalData: st.policyTechnicalData,
        lpsData: st.lpsData,
        branchOffice: st.policyTechnicalData.branchCode,
        productName: st.productName,
        productId: st.productId,
        productCode: st.productCode,
        node: st.node,
        companyId: st.companyId,
        editMode: st.editMode,
        editFunctionality: st.editFunctionality,
        isPersonaGiuridica: null,
        resetQuestionnaire: false,
        isSubjectModified: false,
        isSubstitution: st.isSubstitution,
        isToDisableFraz: st.isToDisableFraz,
        authorize: st.authorize,
        bIntermediateSaving: st.bIntermediateSaving,
        clausesFromAsset: st.clausesFromAsset,
      };
      this.routingService.navigate('re-issue-quotation', req, this.activeRoute.id);
    });
  }

  goToIthStep(i) {
    this.navigateToIthStep(i);
  }
  private navigateToIthStep(i) {
    this.getState$().pipe(take(1)).subscribe(st => {
      const data = this.prepareData(st, i);
      this.routingService.navigate(data.route, data.object, this.activeRoute.id);
    });
  }

  private prepareData(st: T, i: number) {
    if (st.contractNumber) {
      delete st.contractNumber;
      this.updateState$(of(st));
    }
    let data: { object: any, route: string } = { object: null, route: '' };
    switch (i) {
      case 0:
        data.object = this.prepareReIssuePolicyData(st);
        data.route = 're-issue-policy-data';
        break;
      case 1:
        data.object = this.prepareReIssueAssetData(st);
        data.route = 're-issue-asset';
        break;
      case 2:
        data.object = this.prepareReIssueQuotationData(st);
        data.route = 're-issue-quotation';
        break;
      default:
        console.log('Unknown step');
        // Additional logic for unknown steps
        break;
    }

    return data;
  }

  private prepareReIssuePolicyData(st: T) {
    const dataPolicy: ReIssuePolicyDataNavigationData = {
      contractorSubject: st.subject,
      previousContractor:st.subject,
      resourceId: st.resourceId,
      productName: st.productName,
      productId: st.productId,
      node: st.node,
      productCode: st.productCode,
      companyId: st.companyId,
      policyTechnicalData: st.policyTechnicalData,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isSubstitution: st.isSubstitution,
      backFromStep: true,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      thirdPersonContact: null
    };
    return dataPolicy;
  }

  private prepareReIssueAssetData(st: T) {
    const dataAsset: ReissueAssetNavigationDataI = {
      resourceId: st.resourceId,
      contractorSubject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: st.lpsData,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: st.productName,
      productId: st.productId,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isSubstitution: st.isSubstitution,
      isSubjectModified: false,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      bIntermediateSaving: st.bIntermediateSaving,
      thirdPersonContact: null
    };
    return dataAsset;
  }

  private prepareReIssueQuotationData(st: T): ReIssueQuotationNavigationDataI {
    const dataQuotation: ReIssueQuotationNavigationDataI = {
      resourceId: st.resourceId,
      subject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: st.lpsData,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: st.productName,
      productId: st.productId,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isPersonaGiuridica: null,
      resetQuestionnaire: false,
      isSubjectModified: false,
      isSubstitution: st.isSubstitution,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      bIntermediateSaving: st.bIntermediateSaving,
      clausesFromAsset: st.clausesFromAsset
    };
    return dataQuotation;
  }

  goToProposalManagement() {
    this.getState$().pipe(take(1)).subscribe(
      st => {
        const request: ReIssueProposalManagementNavigationData = {
          resourceId: st.resourceId,
          policyTechnicalData: st.policyTechnicalData,
          subject: st.subject,
          productCode: st.productCode,
          contractNumber: st.contractNumber,
          proposalStatus: st.proposalStatus,
          backNavigate: 're-issue-summary',
          policy: null,
          node: st.node,
          contractId: null,
          companyId: st.companyId,
          isBuyProposal: false,
          isPersonaGiuridica: st.isPersonaGiuridica,
          authorize: st.authorize,
          proposal: st.proposal,
          contact: st.contact,
          isFromExternalCard: false
        };

        this.routingService.navigate('re-issue-proposal-management', request, this.activeRoute.id);
      }
    );
  }

  goToSaveProposal(authorizationrRequest) {
    this.getState$().pipe(take(1)).subscribe(
      st => {
        const request: ReIssueSaveQuoteNavigationData = {
          resourceId: st.resourceId,
          policyTechnicalData: st.policyTechnicalData,
          subject: st.subject,
          productCode: st.productCode,
          contractNumber: st.contractNumber,
          proposalStatus: st.proposalStatus,
          node: st.node,
          companyId: st.companyId,
          isPersonaGiuridica: st.isPersonaGiuridica,
          proposal: st.proposal,
          authorize: st.authorize,
          authorizationRequest: authorizationrRequest,
          contact: st.contact
        };

        this.routingService.navigate('re-issue-save-quote', request, this.activeRoute.id);
      }
    );
  }
  putBonds(bondData): Observable<T> {
    return this.summaryBusinessService.putBonds(this.getCurrentState(), bondData).pipe(
      map(st => {
        return st;
      })
    );
  }
}

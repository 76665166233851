import {Injectable} from '@angular/core';
import {RePostsalesCancellationState} from '../re-postsale-state-manager/re-postsales-cancellation-state';
import {Observable} from 'rxjs';
import {
  RePostsalesCancellationStartResourceService
} from '../re-postsale-resources/re-postsales-cancellation-start-resource.service';
import {OperationsOutput} from '../re-postsale-model/re-postsales-cancellation';

@Injectable({
  providedIn: 'root'
})
export class RePostsalesCancellationStartBusinessService<T extends RePostsalesCancellationState> {

  constructor(
    private resourcesService: RePostsalesCancellationStartResourceService
  ) {
  }

  getCancellationReason$(st, policyOperationsInput): Observable<Array<OperationsOutput>> {
    return this.resourcesService.getCancellationReason$(st, policyOperationsInput);
  }
}

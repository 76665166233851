import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';

@Component({
  selector: 'lpc-data-transfer',
  templateUrl: './lpc-data-transfer.component.html',
  styleUrls: ['./lpc-data-transfer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcDataTransferComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcDataTransferComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcDataTransferComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() dataTransferDefinitions;
  @Output() changedTransfer = new EventEmitter<any>();

  dataTransfer: UntypedFormGroup = new UntypedFormGroup({
    data: new UntypedFormControl({}, Validators.required)
    }
  );

  dataTransferId;

  get selectedDataTransferDefinition() {
    return this.dataTransferDefinitions.filter(data => data.id === this.dataTransferId);
  }

  constructor() { }

  validate(): ValidationErrors {
    const error: ValidationErrors = {};
    if (!this.dataTransferId) {
      Object.assign(error, {required: true});
      return error;
    }
    return error;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  onChange(value: any) {}

  onValidationChange(value: any) {}

  ngOnChanges(changes: any): void {}

  onFormChanges() {}

  writeValue(obj: any): void {
    console.log(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }


  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  ngOnInit() {
    this.dataTransfer.valueChanges.subscribe((value) => {
      this.dataTransferId = value.data;
      this.onChange(value);
      this.onValidationChange(value);
      this.changedTransfer.emit(value);
    });
  }

}

<ul [attr.data-qa]="dataQa + '-radio-group'" class="radio-button-group"
  [ngClass]="{'is-invalid': (required || customFormGroup.controls[customFormControlName].dirty)
    && customFormGroup.controls[customFormControlName].invalid, 'is-disabled': disabled}"
  [formGroup]="customFormGroup">
  <li *ngFor="let option of options; let i = index"  class="col-md-4 col-xs-6">
    <input
            type="radio"
            [id]="dataQa + '-' + i"
            [attr.data-qa]="dataQa + '-radio-item-' + i"
            [name]="name"
            [value]="option.value"
            [checked]="value === option.value ? 'checked' : null"
            [formControlName]="customFormControlName"
            (click)="selectOption(i)"
    />
    <label
            for="'name-item-' + i"
            [attr.data-qa]="dataQa + '-radio-label-' + i" (click)="selectOption(i)">{{option.label}}</label>
  </li>
</ul>

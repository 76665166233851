import {DecimalPipe} from '@angular/common';
import { Injectable } from "@angular/core";

@Injectable()
export class FormatterUtils {

  public static readonly DEAFULT_LOCALE = 'it-IT';
  public static readonly DEAFULT_CURRENCY = '€';

  private decPipe: DecimalPipe;

  constructor() {
    this.decPipe = new DecimalPipe('it_IT');
  }

  setCurrencyVal(val, currency?, locale?) {
    currency = !!currency ? currency : FormatterUtils.DEAFULT_CURRENCY;
    locale = !!locale ? locale : FormatterUtils.DEAFULT_LOCALE;
    if (val != null) {
      val = this.decPipe.transform(+val, '0.2-2', locale) + (!!currency ? ' ' + currency : '');
    }
    return val;
  }

  setDecimalVal(val, decimal, locale?) {
    locale = !!locale ? locale : FormatterUtils.DEAFULT_LOCALE;
    if (val != null) {
      val = this.decPipe.transform(+val, '0.' + decimal + '-' + decimal, locale);
    }
    return val;
  }
}

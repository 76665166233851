import {PNC_POSTSALES_COMMISSION_TYPE} from '../../../enums/commission-types-enum';
import {GenericEntity, GenericEntityId} from '../../common/entities';

export class CommissionDetailAPI {
  commissionType: PNC_POSTSALES_COMMISSION_TYPE;
  derogated: boolean;
  editable: boolean;
  mandateActive: GenericEntity[];
  nextInstalment: CommissionInstalmentAPI;
  nextInstalmentFees: SubscriptionFeesAPI;
  nextInstalmentTotals: SubscriptionTotalsAPI;
  reduced: boolean;
  reducedFees: SubscriptionFeesAPI;
  reducedInstalment: CommissionInstalmentAPI;
  reducedTotals: SubscriptionTotalsAPI;
  risk: GenericEntityId;
  subscriptionFees: SubscriptionFeesAPI;
  subscriptionInstalment: CommissionInstalmentAPI;
  subscriptionTotals: SubscriptionTotalsAPI;
}

export class CommissionInstalmentAPI {
  feeCollectionCommissions: string;
  feePercCollectionCommissions: string;
  feePercPurchaseCommissions: string;
  feePurchaseCommissions: string;
  netCollectionCommissions: string;
  netPercCollectionCommissions: string;
  netPercPurchaseCommissions: string;
  netPurchaseCommissions: string;
  totCommissions: string;
  totPercCommissions: string;
}

export class SubscriptionTotalsAPI {
  accessoriesCommissionsCollection: string;
  accessoriesCommissionsPurchase: string;
  netCommissionsCollection: string;
  netCommissionsPurchase: string;
  totalInstallment: string;
}

export class SubscriptionFeesAPI {
  percAdministrationFee: string;
  netAdministrationFee: string;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'lib-re-issue-quotation-clause-modal',
  templateUrl: './re-issue-quotation-clause-modal.component.html',
  styleUrls: ['./re-issue-quotation-clause-modal.component.css']
})
export class ReIssueQuotationClauseModalComponent implements OnInit {

  @Input() clauses: any;
  @Output() modalClose = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Input() checked: string;
  isCheckedYes: number = null;
  clauseArray: string[];

  constructor() {

  }

  ngOnInit() {
    this.clauseArray = [];
    this.replaceClausesElements();
  }

  replaceClausesElements() {
    this.clauses.clauses.forEach(element => {
      element.text = element.text.replace(/<br>/g, '');
      element.text = element.text.replace(/&nbsp;/g, ' ');
      element.text = element.text.replace(/&ndash;/g, '<br>&ndash;&nbsp;');
      element.text = element.text.replace(/align = "left"/g, '');
      this.clauseArray.push(element.text);
    });
  }

  closeModal() {
    this.modalClose.emit();
  }

  confirmClause() {
    if (this.isCheckedYes != null) {
      this.confirm.emit(this.isCheckedYes);
    }
    this.closeModal();
  }

  onChangeYesNo(event: any) {
    // console.log("CLAUSOLA", event)
    // this.isCheckedYes = event == 1
    this.isCheckedYes = event === '1' ? 1 : 0;
  }

  isEmpty() {
    return (this.clauseArray.length > 0);
  }
}

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { ClaimsElasticService } from '../claims-elastic.service';
import { Bucket } from '../dto/bucket';
import { Claim } from '../dto/claim';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-claims-elastic-list',
  templateUrl: './claims-elastic-list.component.html',
  styleUrls: ['./claims-elastic-list.component.scss']
})
export class ClaimsElasticListComponent extends CardComponent implements OnInit, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  emitter: any;
  claims: Claim[] = [];
  sessionServiceInject: any;
  validationMessages: string[];
  claimsListElasticForm: UntypedFormGroup;
  expanded: boolean;
  magicString: string = '';
  claimNumber: string = '';
  partySearch: boolean = false;
  claimSearch: boolean = false;
  regexpSearch: boolean = false;

  aggregateSearch: boolean = false;
  aggregateField: string;
  buckets: Bucket[] = [];

  query: string;
  itemCheckBoxDigit: boolean;
  // abc: any;


  constructor(
    // @Inject('eventService') eventService: any,
    @Inject('sessionService') sessionServiceInject: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') private eventService: any,
    @Inject('claimsService') private claimsService: any,
    @Inject('sessionService') private sessionServiceAngJs: any,
    @Inject('coreResult') private coreResult: any,
    public translateService: RgiRxTranslationService,

    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private claimsElasticService: ClaimsElasticService,
    private formBuilder: UntypedFormBuilder
  ) {
    super(cardComponentService, sessionService);
    this.sessionServiceInject = sessionServiceInject;
    this.emitter = eventService;
  }

  ngOnInit(): void {
    this.claims = this.claimsElasticService.getSessionClaims(this.card.id);
    this.initValidators();
    this.expanded = false;
  }

  initValidators() {
    this.claimsListElasticForm = this.formBuilder.group({
      claimNumber: [],
      partyName: [],
      magicWord: [],
      expanded: []
    });
  }

  hasResult() {
    if (this.claims && this.claims.length > 0) {
      return true;
    }
    return false;
  }

  back() {
    const session = this.getCurrentSession();
    this.sessionServiceInject.remove(session.idSession);
  }

  getCurrentSession() {
    return this.sessionServiceInject.list().find(el => el.isActive);
  }

  goToClaimDetail(claim: Claim) {
  }

  prepareRequest(type: 'query' | 'mutation', myquery: string, vars: any = {}) {
    return JSON.stringify({ [type]: myquery, variables: vars });
  }


  claimsListFilterSearch() {


    this.claimSearch = false;
    this.partySearch = false;
    this.regexpSearch = false;

    // claimNumber search
    if (this.claimsListElasticForm.controls.claimNumber.value) {
      this.claimNumber = this.claimsListElasticForm.controls.claimNumber.value;
      this.claimSearch = true;
      this.query = this.claimsElasticService.getClaimSearch(this.claimNumber);
      this.claims = [];
    }

    // partyName search
    if (this.claimsListElasticForm.controls.partyName.value) {
      this.magicString = this.claimsListElasticForm.controls.partyName.value;
      this.partySearch = true;
      this.query = this.claimsElasticService.getPartySearch(this.magicString);
      this.claims = [];
    }

    // magicWord search
    if (this.claimsListElasticForm.controls.magicWord.value) {
      this.magicString = this.claimsListElasticForm.controls.magicWord.value;
      this.regexpSearch = true;
      this.query = this.claimsElasticService.getRegexpSearch(this.magicString);
      this.claims = [];
    }

    // aggregate search
    if (this.aggregateSearch) {
      this.query = this.claimsElasticService.getAggregationSearch(this.aggregateField);
    }

    this.validationMessages = [];
    // this.claims = [];
    let claimsRes: Claim[] = [];
    let singleClaim: Claim;

    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');

    const request = this.prepareRequest('query', this.query);

    this.claimsElasticService.getClaimsElasticList(request)
      .subscribe((response: any) => {

        if (this.claimSearch) {
          singleClaim = response.data.getClaimByNumber;
          claimsRes[0] = singleClaim;
        } else if (this.partySearch) {
          claimsRes = response.data.partySearch;
        } else if (this.regexpSearch) {
          claimsRes = response.data.regexpSearch;
        } else if (this.aggregateSearch) {

          // non passo l'oggetto diretto perchè c'e' under score su doc_count
          // che non piace a lint
          // this.buckets = response.data.aggregateSearch;

          this.buckets = [];
          let bucket: Bucket = null;

          for (const aggregateSearchElem of response.data.aggregateSearch) {
            bucket = new Bucket();
            bucket.key = aggregateSearchElem.key;
            bucket.count = aggregateSearchElem.doc_count;
            this.buckets.push(bucket);
          }
        }

        if (claimsRes && claimsRes.length > 0) {

          for (const claimRes of claimsRes) {
            this.claims.push(claimRes);
          }

        } else {
          if (!this.aggregateSearch) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(
              res => this.validationMessages.push(res)
            )
          }
        }
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._MESSAGE_._SEARCH_ERROR').subscribe(
            res => this.validationMessages.push(res)
          )
          this.emitter.broadcastEvent('stop-loader');
        }
      );

  }

  clearSearchFields() {
    this.claimsListElasticForm = this.formBuilder.group({
      claimNumber: [''],
      partyName: [''],
      magicWord: [''],
      expanded: []
    }
    );
  }

  keyupMagicWord() {
    if (this.itemCheckBoxDigit) {
      this.aggregateSearch = false;
      this.claimsListFilterSearch();
    }
  }

  fromListSearch() {
    this.aggregateSearch = false;
    this.claimsListFilterSearch();
  }

  aggregate(field: string) {
    this.clearSearchFields();
    this.claimSearch = false;
    this.partySearch = false;
    this.regexpSearch = false;
    this.aggregateSearch = true;
    // document.getElementById("fieldName").value;
    // const dav = this.abc.nativeElement.value;
    this.aggregateField = field;
    this.claimsListFilterSearch();
  }

  goToDetail(claimNumber: string, companyCode: string) {
    const that = this;
    this.eventService.broadcastEvent('start-loader');

    // this.claimsService.getClaimsPesDetailByNumber(companyCode, claimNumber, '6').then(
    this.claimsService.getClaimsPesDetailByNumber(companyCode, claimNumber, null, '3').then(
        (result) => {
          if (result && result.claim) {
            // const detailResult = result.claimInquiry;
            const detailResult = result.claim;
            // detailResult.pesRequestType = result.potencialClaimTypeRequest;
            // if (!this.pes) {
            // const idResult = that.sessionServiceAngJs.open('claimsFinder', 'detail-pes', '', true);
            const idResult = that.sessionServiceAngJs.open('claimsFinder', 'detail', '', true);
            detailResult.idResult = idResult;
            // that.coreResult.setResult(idResult, 'detail-pes', detailResult);
            that.coreResult.setResult(idResult, 'detail', detailResult);
            // }
            this.eventService.broadcastEvent('stop-loader');
            // that.sessionServiceAngJs.remove(that.card.idSession);
            // if (this.pes) {
            //   that.card.integrationPass.objNavigation.reloadOnSave();
            // }
          } else {
            this.eventService.broadcastEvent('alertMsg', { varMsg: 'Claim not found' });
            this.eventService.broadcastEvent('stop-loader');
          }
      });


  }
}

import {
  AfterContentChecked, Component, ElementRef, EventEmitter, Inject, Input,
  OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { EMPTY, fromEvent, Observable, Subscription, timer } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { PVErrorType, PostsalesError } from '../../../../models/postsales-operations-response.model';
import { PlcObjectUtils } from '../../../../utils/plc-object-utils';
import { LpcStepperComponent } from '../lpc-stepper/lpc-stepper.component';
import { ERROR_TYPE_PERSISTENT } from '../../../../models/consts/lpc-consts';

/**
 * @description
 * LpcStepComponent an instance of a LpcStepperComponent.
 * @param label step label.
 * @param feFieldId used to view the Front end error messages passed as input {@link feErrors}.
 * @param fieldId is emitted on the {@link onNext()} as event and it's equal to the context used in the BE.
 * @param feErrors array of some custom errors populated by application check. `default = []`.
 * @param errors array of errors sent back from the draft service. `default = []`.
 * @param disableNext boolean input to disable the sumbmit button.
 * @param enableAuth boolean to enable the authorization.
 * @param nextLabel label of the submit button.
 * @param nextVisible boolean input to handle the view of the submit button.
 * @param jump boolean input to skip a step.
 * @param id string value.
 * @param fieldTypes list of string used on {@link errors} view.
 * @param isConfirmAndAccept boolean to define if it's an authorization flow and you can confirm and accept the operation.
 */
@Component({
  selector: 'lpc-stepper > lpc-step[id]',
  templateUrl: './lpc-step.component.html',
  styleUrls: ['./lpc-step.component.scss'],
})
export class LpcStepComponent implements AfterContentChecked, OnDestroy, OnInit {

  @Input() public label: string;
  @Input() public feFieldId: string;
  @Input() public fieldId: string;
  @Input() public feErrors: PostsalesError[] = [];
  @Input() public errors: PostsalesError[] = [];
  @Input() public disableNext: boolean;
  @Input() public enableAuth: boolean;
  @Input() public nextLabel: string;
  @Input() public nextVisible = true;
  @Input() public jump = false;
  @Input() public id: string;
  @Input() public fieldTypes: string[] = [];
  @Input() public isConfirmAndAccept = false;

  @Output() public next: EventEmitter<string> = new EventEmitter<string>();
  @Output() public back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public confirmAccept: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('stepBody', { static: true }) public stepBody: ElementRef;
  @ViewChild('stepBodyContent', { static: true }) public stepBodyContent: ElementRef;
  @ViewChild('stepHeader', { static: true }) public stepHeader: ElementRef;

  stepper: LpcStepperComponent;

  index: number;

  public ERROR_TYPE_FIELD = 'type';
  public ERROR_TYPE_PERSISTENT: PVErrorType = ERROR_TYPE_PERSISTENT;

  get filteredErrors() {
    return PlcObjectUtils.removeDuplicatesFromPostSalesErrors(this.errors);
  }

  private $active = false;
  get active(): boolean {
    return this.$active;
  }

  private $opened = false;
  public get opened(): boolean {
    return this.$opened;
  }

  public get isLastStep(): boolean {
    if (this.stepper) {
      const filteredStep = this.stepper.steps.filter((s) => s.id !== 'documents');
      return filteredStep[filteredStep.length - 1] === this;
    }
    return false;
  }

  public get offsetHeight(): string {
    return this.stepBodyContent.nativeElement.offsetHeight + 'px';
  }

  private $subscriptions: Subscription[] = [];

  public get nativeElement(): any {
    return this.$elementRef.nativeElement;
  }

  constructor(
    private $elementRef: ElementRef,
    protected  translateService: TranslationWrapperService,
    @Inject('enableAnimation') protected enableAnimation: boolean
  ) {
  }

  ngOnInit() {
    this.stepHeader.nativeElement.hidden = true;
    this.$subscriptions.push(
      timer(500).pipe(
        switchMap(_ => {
          this.stepHeader.nativeElement.hidden = false;
          return this.inizializeStepperView();
        })
      ).subscribe());
  }

  // @ts-ignore
  private activate(): void {
    this.$active = true;
    this.$subscriptions.push(
      this.openStepperContentView().pipe(
        switchMap(_ => {
          return timer(500);
        }),
        tap(this.$elementRef.nativeElement.classList.add('active'))
      ).subscribe()
    );
  }

  // @ts-ignore
  private deactivate(): void {
    this.$active = false;
    this.$elementRef.nativeElement.classList.remove('active');
  }

  // @ts-ignore
  private open(): void {
    this.$opened = true;
    this.$elementRef.nativeElement.classList.add('open');
    this.stepBody.nativeElement.style.maxHeight = this.offsetHeight;
  }

  // @ts-ignore
  private close(): void {
    this.$opened = false;
    this.$elementRef.nativeElement.classList.remove('open');
    this.stepBody.nativeElement.style.maxHeight = 0;
  }

  onHeaderClick() {

    if (PlcObjectUtils.hasSystemError(this.errors)) {
      return;
    }
    if (this.opened) {
      // intermediate Steps between the clicked index and the current active step ID inclueded the current active
      // to close all
      const intermediateSteps = this.stepper.stepIds.slice(this.index + 1, this.stepper.active + 1);
      if (!!intermediateSteps.length) {
        intermediateSteps
        .reverse().map(step => this.stepper.stepIds.findIndex(findstep => findstep === step))
        .forEach(stepId => {
          if (stepId === this.index + 1) {
            this.stepper.closeCurrent(stepId);
          } else {
            this.stepper.steps[stepId].closeStepperContentView();
          }
        });
      }
      // this.stepper.closeCurrent(this.index);
    }
  }

  onNext(id: string) {
    this.next.emit(id);
  }

  onConfirmAndAccept(id: string) {
    this.confirmAccept.emit(id);
  }

  onBack() {
    if (this.isLastStep) {
      this.back.emit(true);
    } else {
      this.back.emit(false);
      this.stepper.back();
    }
  }

  ngAfterContentChecked(): void {
    if (this.opened) {
      this.stepBody.nativeElement.style.maxHeight = this.offsetHeight;
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get getNextLabel(): string {
    if (!!this.nextLabel) {
      return this.nextLabel;
    } else {
      return this.translateService.getImmediate(this.isLastStep ? 'lpc_confirm_button' : 'lpc_continue_button');
    }
  }


  /** @ANIMATIONS */
  private inizializeStepperView(): Observable<any> {
    if (!!this.enableAnimation) {
      this.stepHeader.nativeElement.classList.add('slide');

      /* this.stepHeader.nativeElement.addEventListener('animationend', () => {
        this.stepHeader.nativeElement.classList.remove('slide');
      }, { once: true }); */
      return fromEvent(this.stepBodyContent.nativeElement, 'animationend').pipe(
        tap(_ => {
          this.stepBodyContent.nativeElement.classList.remove('slide');
        }), take(1));
    } else {
      return EMPTY;
    }
  }

  public openStepperContentView(): Observable<any> {
    if (!!this.enableAnimation) {
      this.stepBodyContent.nativeElement.classList.add('step-content-open');

      return fromEvent(this.stepBodyContent.nativeElement, 'animationend').pipe(
        tap(_ => {
          this.stepBodyContent.nativeElement.classList.remove('step-content-open');
        }), take(1));
    } else {
      return EMPTY;
    }
  }

  public closeStepperContentView() {
    if (!!this.enableAnimation) {
      this.stepBodyContent.nativeElement.classList.add('step-content-close');

      this.$subscriptions.push(
        fromEvent(this.stepBodyContent.nativeElement, 'animationend').pipe(
        tap(_ => {
          this.stepBodyContent.nativeElement.classList.remove('step-content-close');
        }), take(1)).subscribe()
      );
    }
  }

  public closeStepperContentViewAndSlideBack(index: number) {
    if (!!this.enableAnimation) {
      this.stepBodyContent.nativeElement.classList.add('step-content-close');

      this.stepBodyContent.nativeElement.addEventListener('animationend', () => {
        this.stepBodyContent.nativeElement.classList.remove('step-content-close');
        this.stepper.slideTo(index, true);
      }, { once: true });
    } else {
      this.stepper.slideTo(index, true);
    }
  }

}

import {PassProfileDefinition} from './PassProfileDefinition';
import {ToolDefinition} from '../../lic-tools/model/tool-definition';
import { SelectionState } from './SelectionState';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { PolicyService } from '../../services/policy-service';

export interface PassResponse {
  options: PassResponseOption[];
  profiles: Array<PassProfileDefinition>;
}

export interface PassResponseOptions {
  options: PassResponseOption[];
}

export interface PassResponseProfile {
  profiles: Array<PassProfileDefinition>;
}

export interface PassResponseOption {
  id: string;
  name: string;
  initialState: SelectionState;
}

export interface ToolOptionStatus extends PassResponseOption {
  isToolBeingFullyHandled?: boolean;
}

export interface AuthorizedToolResponse {
  toolsConfiguration: ToolDefinition[];
}

export interface VolatilityResponse {
    volatility: number;
    errors: any[];
}

export interface SustainabilityResponse {
  ecoSustainabilityAmount: string;
  ecoSustainabilityPerc: string;
  envSustainabilityAmount: string;
  envSustainabilityPerc: string;
  paiAmount: string;
  paiPerc: string;
  socialSustainabilityAmount: string;
  socialSustainabilityPerc: string;

  errors: any[];
}

export class SustainabilityData implements SustainabilityResponse {
  ecoSustainabilityAmount: string;
  ecoSustainabilityPerc: string;
  envSustainabilityAmount: string;
  envSustainabilityPerc: string;
  paiAmount: string;
  paiPerc: string;
  socialSustainabilityAmount: string;
  socialSustainabilityPerc: string;

  errors: any[];

  /* eslint-disable */
  constructor(response: SustainabilityResponse, numberPipe: RgiCountryLayerNumberFormatPipe, service: PolicyService) {
    const locale = service.currentLocale
    this.ecoSustainabilityAmount = response.ecoSustainabilityAmount && numberPipe.transform(response.ecoSustainabilityAmount, locale, service.getFormatterOptionsWithDecimal('currency'));
    this.ecoSustainabilityPerc = response.ecoSustainabilityPerc && numberPipe.transform(Number(response.ecoSustainabilityPerc) / 100, locale, service.getFormatterOptionsWithDecimal('percent', '0.2-2'));
    this.envSustainabilityAmount = response.envSustainabilityAmount && numberPipe.transform(response.envSustainabilityAmount, locale, service.getFormatterOptionsWithDecimal('currency'));
    this.envSustainabilityPerc = response.envSustainabilityPerc && numberPipe.transform(Number(response.envSustainabilityPerc) / 100, locale, service.getFormatterOptionsWithDecimal('percent', '0.2-2'));
    this.paiAmount = response.paiAmount && numberPipe.transform(response.paiAmount, locale, service.getFormatterOptionsWithDecimal('currency'));
    this.paiPerc = response.paiPerc && numberPipe.transform(Number(response.paiPerc) / 100, locale, service.getFormatterOptionsWithDecimal('percent', '0.2-2'));
    this.socialSustainabilityAmount = response.socialSustainabilityAmount && numberPipe.transform(response.socialSustainabilityAmount, locale, service.getFormatterOptionsWithDecimal('currency'));
    this.socialSustainabilityPerc = response.socialSustainabilityPerc && numberPipe.transform(Number(response.socialSustainabilityPerc) / 100, locale, service.getFormatterOptionsWithDecimal('percent', '0.2-2'));

    this.errors = !!response.errors ? response.errors : [];
  }
}

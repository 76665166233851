import {ApiPremiumDetail} from './api-premium-detail';
import {ApiLPSTaxDetail} from './api-lps-tax-detail';

export class ApiPremiumGroup {

  constructor(
    public annual: ApiPremiumDetail,
    public instalment: ApiPremiumDetail,
    public subscriptionInstalment: ApiPremiumDetail,
    public lpsTaxDetailInstallment: ApiLPSTaxDetail,
    public lpsTaxDetailSubscriptionInstallment: ApiLPSTaxDetail
  ) {
  }

}

<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels" (goToIthStep)="goToIthStep($event)"></re-issue-stepper>

<div *ngIf="stateManager.getState$() | async as state">

  <div class="rgi-re-section-title" data-qa="parties_data_title">
    <span class="rgi-ui-icon-users rgi-re-header-icon"></span>
    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.SUBJECTS</span>&nbsp;
  </div>

  <div class="rgi-ui-grid-2" data-qa="parties_data_fields">
    <div class="rgi-ui-col">
      <table class="rgi-ui-table">
        <tbody>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.POLICYHOLDER</span> </td>
            <td class="rgi-ui-cell" > {{ state.subject.nominative }} </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="state.proposal.coOwners?.length" class="rgi-ui-col">
      <table class="rgi-ui-table">
        <tbody>
          <tr *ngFor="let coOwner of state.proposal.coOwners" class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.CO_OWNERS</span> </td>
            <td class="rgi-ui-cell" > {{ coOwner.nominative }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  <!--   <div class="rgi-ui-col" *ngIf="stateManagerPolicy.getCoOwners$() | async as coOwnerState">
      <table class="rgi-ui-table" data-qa="proposal_data_field">
        <tbody>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.POLICY_STATE</span> </td>
            <td class="rgi-ui-cell" > {{coOwnerState.coOwners }} </td>
          </tr>
        </tbody>
      </table>
    </div> -->

  </div>

  <div class="rgi-re-section-title" data-qa="proposal_data_title">
    <span class="rgi-re-header-icon rgi-ui-icon-list-bars"></span>
    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.PROPOSAL_DATA</span>&nbsp;
  </div>

  <div class="rgi-ui-grid-2" data-qa="proposal_data_field">
    <div class="rgi-ui-col">
      <table class="rgi-ui-table">
        <tbody>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.PRODUCT_DESCRIPTION</span> </td>
            <td class="rgi-ui-cell" > {{state.proposal.product.description}} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.POLICY_EFFECT_DATE</span> </td>
            <td class="rgi-ui-cell" > {{state.proposal.effectDate | date: 'dd/MM/yyyy' }} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.POLICY_EXPIRE_DATE</span> </td>
            <td class="rgi-ui-cell" > {{state.proposal.policyExpiration | date: 'dd/MM/yyyy' }} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.PAYMENT_FREQUENCY</span> </td>
            <td class="rgi-ui-cell" > {{selectedFrequency }} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.AGREEMENT</span> </td>
            <td class="rgi-ui-cell" > {{  state.proposal.agreement.code ?
                                          state.proposal.agreement.description :
                                          '-'}} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.CO_INSURANCE</span> </td>
            <td class="rgi-ui-cell" > {{  state.proposal.coInsurance.coinsuranceType.description !== 'No Coinsurance' ?
                                          state.proposal.coInsurance.coinsuranceType.description :
                                          '-'}} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.INDEXATION</span> </td>
            <td class="rgi-ui-cell" > {{  state.policyTechnicalData.indexType.code ?
                                          state.policyTechnicalData.indexType.description :
                                          '-'}} </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="rgi-ui-col">
      <table class="rgi-ui-table" data-qa="proposal_data_field">
        <tbody>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.POLICY_STATE</span> </td>
            <td class="rgi-ui-cell" > {{state.proposalStatus.description }} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.ISSUE_DATE</span> </td>
            <td class="rgi-ui-cell" > {{state.proposal.issuingDate | date: 'dd/MM/yyyy' }} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.NEXT_INSTALMENT_DATE</span> </td>
            <td class="rgi-ui-cell" > {{state.policyTechnicalData.selectedInstalmentDate | date: 'dd/MM/yyyy' }} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.TACIT_RENEWAL</span> </td>
            <td class="rgi-ui-cell" > <span translate>{{state.proposal.automaticRenewal.value ? 'RE_ISSUE.YES' : 'No'}}</span> </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.CURRENCY</span> </td>
            <td class="rgi-ui-cell" > {{state.proposal.currency.description }} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.OUR_PERCENTAGE</span> </td>
            <td class="rgi-ui-cell" > {{ state.proposal.coInsurance.coinsuranceType.description !== 'No Coinsurance' ?
                                         state.proposal.coInsurance.ourPerc :
                                         '-'}} </td>
          </tr>
          <tr class="rgi-ui-row">
            <td class="rgi-ui-cell" > <span translate>RE_ISSUE.USE_TAX</span> </td>
            <td class="rgi-ui-cell" > {{ state.proposal.useTax.description }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>



  <rgi-rx-expansion-panel #assetsSection data-qa="insurance_assets">
    <rgi-rx-expansion-panel-header class="panel-heading">
      <div rgi-rx-expansion-panel-label class="rgi-re-section-title">
        <span class="rgi-re-header-icon rgi-re-icon-form"></span>
        <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.INSURANCE_ASSETS</span>
      </div>
    </rgi-rx-expansion-panel-header>
    <div *ngFor="let oneAsset of state.assetInstances; let i = index" >
      <rgi-rx-expansion-panel [expanded]="oneAsset?.asset.selected" #oneAssetSection  attr.data-qa="asset_{{i}}">
        <rgi-rx-expansion-panel-header class="panel-heading">
          <div rgi-rx-expansion-panel-label class="rgi-ui-grid-middle">
            <div class="rgi-ui-col">
              <div class="story-grid-column-content">
                <span class="rgi-ui-title-1 rgi-re-title subheader">{{ getAssetName(state.proposal.assets[i]) | titlecase }}</span>
              </div>
            </div>
          </div>
          <div class="rgi-ui-grid-right-middle">
            <div class="rgi-ui-col">
            <span class="rgi-ui-title-1 rgi-re-title subheader">{{
              state?.quotation?.assetList?.getAsset(oneAsset.asset.key)?.productPremium?.annual?.gross |
                currency: currency.code : currency.symbol :'1.2-2':'it' }}</span>
            </div>
          </div>
        </rgi-rx-expansion-panel-header>
      <!---->

        <ng-container *ngFor="let asset of state.quotation.assetList.assets">
          <table class="rgi-ui-table">
            <tbody>
              <ng-container *ngIf="asset.assetId == oneAsset.asset.key">
                <ng-container *ngFor="let instance of asset.instances">
                  <div *ngFor="let section of instance.sections">
                    <div *ngFor="let unit of section.unitList">

                          <tr class="rgi-ui-row rgi-ui-grid" *ngIf="unit?.selected">
                            <td class="rgi-ui-cell rgi-ui-col" attr.data-qa="unit_{{unit.description}}">
                              {{unit?.extendedDescription}}
                            </td>
                            <td class="rgi-ui-cell">
                              <span >
                                {{unit?.annual?.gross | currency: currency.code : currency.symbol:'1.2-2':'it' }}
                              </span>
                            </td>
                          </tr>

                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
      </rgi-rx-expansion-panel>
    </div>
  </rgi-rx-expansion-panel>

    <!---->

    <re-issue-premium [currency]="currency" [premium]="state.quotation.premium.productPremium"
                      [fees]="state.proposal.fees"></re-issue-premium>


<div *ngIf="state.policyTechnicalData.binder.selected">
  <rgi-rx-expansion-panel [expanded]="true" data-qa="lien" >
    <rgi-rx-expansion-panel-header>
      <div rgi-rx-expansion-panel-label>
        <span class="rgi-ui-title-1" translate>RE_ISSUE.LIENS</span>
      </div>
    </rgi-rx-expansion-panel-header>
    <div class="container-fluid">

      <div *ngFor="let oneAsset of state.assetInstances; let i = index">
        <rgi-rx-expansion-panel [expanded]="expandAssetIndex === i" attr.data-qa="{{'asset_'+ i}}">
          <rgi-rx-expansion-panel-header class="panel-heading title-section">
            <div rgi-rx-expansion-panel-label class="rgi-ui-grid-middle">
              <div class="rgi-ui-col">
                <div class="story-grid-column-content">
                  <span class="rgi-ui-title-1 rgi-re-title subheader">{{ getAssetName(state.proposal.assets[i]) | titlecase }}</span>
                  <span class="rgi-re-color-red">{{getAsteriskForBindings(i)}}</span>
                </div>
              </div>
            </div>
            <div class="rgi-ui-grid-right-middle">
              <div class="rgi-ui-col">
              <span class="rgi-re-header-icon rgifont rgi-ui-icon-add nfm_font_size" [class.disabled]="showNewBond"
                    (click)="toggleNewBond(i)" data-qa="toggle_lien"></span>
              </div>
            </div>
          </rgi-rx-expansion-panel-header>
           <!-- New Bond or edit section -->
           <re-issue-bond-item (onSave)="saveNewBinding($event,oneAsset.asset.key,i)" [selectedBinding]="selectedBinding"
                               [editMode]="editMode" (onCancel)="deletNewBinding()" [productId]="state.productId"
                               *ngIf="showNewBond && showNewBondIndex === i"></re-issue-bond-item>
         <br/>
          <div *ngIf="bindings[i] && bindings[i].length > 0">
            <!----------------------------list binding-->
            <!-- Include the Binding List Component -->

            <re-issue-bond-list [bindings]="bindings[i]"  (onDelete)="deleteBinding($event,oneAsset.asset.key,i)"
                                (onEdit)="editBinding($event,i)"></re-issue-bond-list>

          </div>

          <div *ngIf="!((bindings[i] && bindings[i].length > 0) || showNewBond && showNewBondIndex === i)">
            <span translate>RE_ISSUE.EMPTY_LIST_LIEN</span>
          </div>
          <br/>

        </rgi-rx-expansion-panel>
      </div>
    </div>
  </rgi-rx-expansion-panel>
</div>
  <rgi-rx-snackbar tag="{{TAG_LIEN_NUMBER_ERRORS}}"></rgi-rx-snackbar>
  <div class="rgi-ui-grid rgi-ui-grid-no-gutter">
    <div class="rgi-ui-col btn-group">
      <div class="story-grid-column-content">
        <button rgi-rx-button color="secondary" class="rgi-re-button" (click)="back()" data-qa="back">
          <span translate>RE_ISSUE.BACK</span>
        </button>
      </div>
    </div>
      <div class="rgi-ui-col btn-group" *ngIf="isDirectionalUser && state.authorize; else standardFlow">
        <div class="story-grid-column-content">
          <button rgi-rx-button color="secondary" class="rgi-re-button" (click)="saveProposal(false)"
                  data-qa="save_proposal" color="primary"><span translate>RE_ISSUE.SAVE_AND_CLOSE</span>
          </button>
        </div>
      </div>
    <ng-template #standardFlow>
      <div class="rgi-ui-col btn-group">
        <div class="story-grid-column-content">
          <button rgi-rx-button color="secondary" class="rgi-re-button" (click)="saveProposal()"
                  data-qa="save_proposal"><span translate>RE_ISSUE.SAVE</span>
          </button>
        </div>
      </div>
      <div class="rgi-ui-col" *ngIf="!state.authorize">
        <div class="story-grid-column-content">

          <button rgi-rx-button data-qa="checkout" class="rgi-re-button"
                  [disabled]="state.warnings?.length > 0 || state.proposalStatus.status === NO_QUOTED_INSURED_PROPOSAL"
                  (click)="goToProposalManagement()" color="primary"><span translate>RE_ISSUE.CHECKOUT</span></button>
        </div>
      </div>
      <div class="rgi-ui-col" *ngIf="state.authorize">
        <div class="story-grid-column-content">
          <button rgi-rx-button data-qa="authorize"  class="rgi-re-button" (click)="saveAuthorization(true)" color="primary"
                  translate>RE_ISSUE.AUTHORIZATION_REQUEST
          </button>
        </div>
      </div>
    </ng-template>
  </div>

</div>

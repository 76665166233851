export class Lien {
  constructor(
    public id: string,
    public description: string,
    public effectiveDate: Date,
    public expirationDate: Date,
    public bindingEntity?: any,
    public bindingEntityMandatory?: boolean,
    public effectiveDateMandatory?: boolean,
    public expirationDateMandatory?: boolean) {
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { ReactiveFormsModule } from '@angular/forms';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { LpcFactorAdapterModule } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.module';
import { LpcHelpModule } from '../../modules/lpc-help/lpc-help.module';
import { LpcVprinTableComponent } from './vprin-unit-table.component';

@NgModule({
    declarations: [LpcVprinTableComponent],
    imports: [
        CommonModule,
        RgiCountryLayerModule,
        PortalNgCoreModule,
        ReactiveFormsModule,
        LpcFactorAdapterModule,
        LpcHelpModule
    ],
    exports: [LpcVprinTableComponent]
})
export class LpcVprinTableModule { }

<div *ngIf="!!visibleDefinitions" class="col-lg-12 blank">
  <h3 translate>{{label}}</h3>
  <div class="tbld postsales-table-style hidden-xs">
    <div class="tbld_row postsales-col-title">
        <div class="tbld_col" *ngFor="let definition of visibleDefinitions">{{ definition.label }}</div>

    </div>
    <div class="tbld_row postsales-row-content" *ngFor="let row of rows">
        <div class="tbld_col psvalue" *ngFor="let definition of visibleDefinitions; let i = index" [ngClass]="{'text-left' : i === 0}">
          {{ i > 0 ? (row[definition.code] | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions) : row[definition.code] }}
        </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RoutingService } from '@rgi/portal-ng-core';
import { Subscription } from 'rxjs';
import { DIALOG_DATA, OnModalClose, TableSchema } from '@rgi/rx/ui';
import { CtpDetail, CtpDetailNew } from '../fiva-configuration/fiva-model';



@Component({
  selector: "claims-fiva-plate-ctp-detail-modal",
  templateUrl: "./fiva-plate-ctp-detail.modal.component.html",
  styleUrls: ["./fiva-plate-ctp-detail.modal.component.scss"],
})
export class FivaPlateCtpDetailModalComponent implements OnModalClose {
  subscriptions: Subscription[] = [];
  fivaCtpForm: UntypedFormGroup;
  detailCtp: CtpDetail;
  claim: any = null;
  fivaTypeDamages: any[] = [];
  fivaReports: any[] = [];
  ctpPlates: any[] = [];
  errorMessage: string;
  booleano: boolean = false;

  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('enumService') private enumService: any,
    @Optional() @Inject(DIALOG_DATA) data: any,
    private formBuilder: UntypedFormBuilder,
    protected routingService: RoutingService
  ) {
    if (data) {
      this.detailCtp = data.CtpDetail;
      this.claim = data.claim;
      console.log(this.claim);
    } else {
      this.detailCtp = new CtpDetail();
    }
  }

  ngOnInit() {
    this.fivaTypeDamages = this.enumService.getEnumList(
      'sinistri.FivaTypeDamage'
    );
    this.fivaReports = this.enumService.getEnumList('sinistri.FivaReport');
    this.initForm();
  }

  initForm() {
    this.claim.claimParties.forEach((elemento) => {
      if (
        elemento.vehicleRegistrationPlate != this.claim.insuredVehiclePlate &&
        elemento.vehicleRegistrationPlate != null
      ) {
        const foundElement = this.ctpPlates.find(
          (item) => item === elemento.vehicleRegistrationPlate
        );
        if (foundElement) {
        } else {
          this.ctpPlates.push(elemento.vehicleRegistrationPlate);
        }
      }
    });

    if (this.ctpPlates.length == 1) {
      this.booleano = true;
    }

    this.fivaCtpForm = this.formBuilder.group({
      function: [],
      message: [],
      cclaimnumber: [
        { value: this.claim.claimNumber, disabled: true },
        Validators.required,
      ],
      cnumpol: [
        { value: this.claim.policyNumber, disabled: true },
        Validators.required,
      ],
      cplate: [
        { value: this.claim.insuredVehiclePlate, disabled: true },
        Validators.required,
      ],
      cplatecntparty: [
        { value: this.ctpPlates[0], disabled: this.booleano },
        Validators.required,
      ],
      doccurrence: [
        { value: this.claim.occurrenceDate, disabled: true },
        Validators.required,
      ],
      etypedamage: [null, Validators.required],
      ereport: [
        { value: this.fivaReports[0].descrizione },
        Validators.required,
      ],
      cprocedurenumber: [],
      cinstance: [],
      errorMessage: [],
    });
  }
  closeModal(typeClose: string) {
    const outputDetail = {
      value: null,
    };

    if (typeClose == 'confirm') {
      const CtpDetailNew: CtpDetailNew = {
        cclaimnumber: this.fivaCtpForm.get('cclaimnumber').value,
        cnumpol: this.fivaCtpForm.get('cnumpol').value,
        cplate: this.fivaCtpForm.get('cplate').value,
        cplatecntparty: this.fivaCtpForm.get('cplatecntparty').value,
        doccurrence: this.fivaCtpForm.get('doccurrence').value,
        etypedamage: this.fivaCtpForm.get('etypedamage').value,
        ereport: this.fivaCtpForm.get('ereport').value,
        cprocedurenumber: this.fivaCtpForm.get('cprocedurenumber').value,
        cinstance: this.fivaCtpForm.get('cinstance').value,
      };

      outputDetail.value = CtpDetailNew;
      this.errorMessage = null;

      if (this.fivaCtpForm.valid == false || this.isEmpty(this.fivaCtpForm.get('cplatecntparty').value)) {
        this.errorMessage = 'fill the form';
      } else {
        this.modalClose.emit(outputDetail);
      }
    }

    if (typeClose == 'exit') {
      this.modalClose.emit(outputDetail);
    }
  }
  isEmpty(item) {
    return (
      item === '' || item === null || item === undefined || item === 'undefined'
    );
  }
}

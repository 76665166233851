import {FlexDiscountParam} from '../pc-portfolio-models/discounts-models/flex-discount-param';

export class WarrantyDiscountSummary {
  assetCode: string;
  sectionId: string;
  name: string;
  sectionName: string;
  isUnit: boolean;
  annualGross: string;
  flexWithErosionMaxPercent: number;
  flexWithoutErosionMaxPercent: number;
  flexWithErosionMinPercent: number;
  flexWithoutErosionMinPercent: number;
  flexDiscounts: FlexDiscountParam;
  discountable: boolean;
  maxDiscountForAmount: number;
  minDiscountForAmount: number;
  valid = [true, true, true, true];
}

import {AjsPassDamageCardConstantsExt} from './ajs-pass-damage-card-constant-ext';
import {AjsPassDamageCardServicesExt} from './ajs-pass-damage-card-services-ext';
import {AjsPassDamageCardResourcesExt} from './ajs-pass-damage-card-resources-ext';

// @ts-ignore
declare var angular: angular.IAngularStatic;

export function AjsPassDamageCardExt() {
  AjsPassDamageCardConstantsExt();
  AjsPassDamageCardResourcesExt();
  AjsPassDamageCardServicesExt();

  angular.module('ptfdamage-nmf-integration', [
    'ptfdamage-nmf-integration-constant',
    'ptfdamage-nmmf-integration-resources',
    'ptfdamage-nmmf-integration-services'
  ]);
}

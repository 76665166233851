<div class="panel-body" [formGroup]="formGroup">
  <div class="row summary-container" *ngIf="!!fundsSelected.length && !areProfiles">
    <lic-karma-fund-summary [funds]="fundsSelected"></lic-karma-fund-summary>
  </div>
  <div>
    <lic-karma-fund-message *ngFor="let error of errors" [error]="error"></lic-karma-fund-message>
  </div>
  <div *ngIf="isAllocationCompleted()">
    <div class="message">
      <div class="message-ok">
        <span translate>lic_info_investment_completed</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6" *ngIf="!areProfiles">
      <span>
        <rgi-rx-form-field>
          <select class="col-sm-6 col-lg-2" id="custom" formControlName="count"
            (onValueChange)="handlePageSizeChange($event)" rgiRxNativeSelect>
            <option value=""></option>
            <option *ngFor="let size of pageSizes" [value]="size">
              {{ size }}
            </option>
          </select>
        </rgi-rx-form-field>
      </span>
      <span style="line-height: 37px;" translate>lic_elements</span>
    </div>
    <div class="col-lg-6" [ngStyle]="{'text-align': areProfiles ? 'left' : 'right'}">
      <label>
        <span translate>lic_name</span>
        <span>: </span>
        <input style="margin-left: 5px;" name="filter" formControlName="search" /></label>
    </div>
  </div>
  <br>
  <div class="row">
    <div formGroupName="funds">
      <div class="col-sm-12" *ngFor="let fund of filteredFunds | paginate: {
        id: 'pagination-'+filteredFunds.length,
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: filteredFunds.length
      }">
        <lic-karma-fund-element
          (fundSelected)="addToFunds($event)"
          [formControlName]="fund.id.toString()"
          [totalAmount]="totalAmount"
          [definition]="fund"
          [sliderProperty]="sliderProperty"
          [showSliderInput]="showSliderInput"
          (toggleChange)="onToggleClick($event)"
          #singleFinancialElement>
        </lic-karma-fund-element>
      </div>
    </div>
    <div class="row">
      <div class="right-align">
        <pagination-controls id="pagination-{{filteredFunds.length}}" previousLabel=""
        nextLabel="" (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div *ngIf="documentsList.length > 0">
  <div class="core-header-icon re-issue-card-styles">
    <span class="rgi-re-header-icon rgifont rgi-dettagli_tecnici nfm_font_size"></span>
    <span class="header-text" translate>List of documents to print</span>&nbsp;
    <span class="pull-right header-text"> </span>
  </div>
  <div class="row mb-4">
    <div class="col-12" *ngFor="let document of documentsList">
      <hr>
      <span>{{document.descrizione}}</span>
      <span class="pull-right rgifont rgi-pdf-file-format-symbol" (click)="pdfPreview(document)"></span>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-9"></div>
    <div class="col-3">
      <button type="button" class="rgi-re-button" data-qa="find-actions-btn"
              (click)="downloadAll()">
        <span class="pr-2 " translate>Download all</span>
        <span class="pull-right rgifont rgi-download"></span>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="!isProposal && warningMessages?.length > 0">
    <!--    <div class="col-12">
          <pnc-form-message type="warning" detail="validationError" [messages]="warningMessages"
                            data-qa="action-error-messages" objectType="complex"></pnc-form-message>
        </div>-->

  </div>
</div>
<div class="col-xs-12 error-message" *ngIf="errorMessages.length > 0">
  <!--  <div id="error-container" class="error-container">
      <pnc-form-message type="error" [messages]="errorMessages"
                        data-qa="reprint-movements-error-messages" objectType="complex"></pnc-form-message>
    </div>-->
</div>

import {
  ClauseDefinition, ClauseListWithInstAndRisk,
  ClauseVariationInfo, ClausesData, InsuredClauseInfo,
  PolicyClauseInfo, RiskClauseInfo } from '../../../models/postsales-operations-response.model';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { CLAUSES } from '../config/change-clauses-configuration';

export interface ClauseArray {
  policyClauses: ClauseDefinition[];
  insuredClauses: ClauseDefinition[];
  riskClauses: ClauseDefinition[];
}

/* eslint-disable max-len */
export class ChangeClauseUtils {

  public static flatMapOnClauseListByType(clauses: ClauseArray, type: string, data?: ClauseVariationInfo): ClauseListWithInstAndRisk[] {
    return (clauses[type] as ClauseDefinition[])
      .reduce((groupC, reducingClause) => {
        const mapWithInstAndRisk = PlcObjectUtils.asValidArray(reducingClause.clauseList).map(cl => {
          const clauseListWithInstAndRisk = new ClauseListWithInstAndRisk(cl, reducingClause.istanceName, reducingClause.riskCode);
          return this.prevalorizedTextAndStateByData(clauseListWithInstAndRisk, data, type);
        });
        groupC.clauseList = [].concat(mapWithInstAndRisk);
        return groupC;
      }, { clauseList: [] })
      .clauseList as ClauseListWithInstAndRisk[];
  }


  protected static prevalorizedTextAndStateByData(clause: ClauseListWithInstAndRisk, data: ClauseVariationInfo, type: string) {
    // RDDL-1818: the selection returned by the definition must be ignored till the implementation will be completed
    clause.selected = false;
    if (!!data) {
      const filteredClauseByCode = (data[type] as PolicyClauseInfo[]).filter(c => !!c && c.clauses && c.clauses.find(cl => cl.code === clause.codice))[0];
      if (!!filteredClauseByCode) {
        const foundClauseOnData = filteredClauseByCode.clauses.find(clauseInfo => clauseInfo.code === clause.codice);
        clause.testo = foundClauseOnData && foundClauseOnData.text || clause.testo;
        clause.enabled = true; // remove in case one day it will handle the enable state by PassProduct configuration
        clause.selected = !!foundClauseOnData; // decomment in case one day it will handle by PassProduct configuration ? true : returnObj.selected;
      }
    }

    return clause;
  }

  public static createRequest(clauseType: string, formRawValue): PolicyClauseInfo[] | InsuredClauseInfo[] | RiskClauseInfo[] {
    const returnObject = formRawValue.clauses[clauseType]
      .filter(clauseListByType => clauseListByType[CLAUSES.CLAUSES.formChildren.clauseState])
      .map(c => {
        return mapToClauseData();

        function mapToClauseData() {
          const returnObj: ClausesData = {
            [CLAUSES.CLAUSES.formChildren.clauseCode]: c[CLAUSES.CLAUSES.formChildren.clauseCode],
            [CLAUSES.CLAUSES.formChildren.clauseText]: c[CLAUSES.CLAUSES.formChildren.clauseText] || '',
            [CLAUSES.CLAUSES.formChildren.codRisk]: c[CLAUSES.CLAUSES.formChildren.codRisk],
            [CLAUSES.CLAUSES.formChildren.clauseInstance]: c[CLAUSES.CLAUSES.formChildren.clauseInstance]
          };
          return returnObj;
        }
      }).reduce((acc, elemento) => {
        return ChangeClauseUtils.reduceArrayByInstanceAndRiskCode(elemento, acc);
      }, {});
    return [returnObject].filter(c => !!c[CLAUSES.CLAUSES.formChildren.clauseList] && c[CLAUSES.CLAUSES.formChildren.clauseList].length > 0);
  }

  protected static reduceArrayByInstanceAndRiskCode(elemento: any, acc: any) {
    const nomeIstanza = elemento[CLAUSES.CLAUSES.formChildren.clauseInstance];
    const codRisk = elemento[CLAUSES.CLAUSES.formChildren.codRisk];
    // deleting the instance and risk code from the object
    delete elemento[CLAUSES.CLAUSES.formChildren.clauseInstance];
    delete elemento[CLAUSES.CLAUSES.formChildren.codRisk];
    // creating the clauseList
    if (acc[CLAUSES.CLAUSES.formChildren.clauseList]) {
      acc[CLAUSES.CLAUSES.formChildren.clauseList].push(elemento);
    } else {
      acc[CLAUSES.CLAUSES.formChildren.clauseList] = [elemento];
    }
    // clause Instance
    if (acc[CLAUSES.CLAUSES.formChildren.clauseInstance] && !!nomeIstanza) {
      acc[CLAUSES.CLAUSES.formChildren.clauseInstance] = nomeIstanza;
    }
    // codRisk
    if (acc[CLAUSES.CLAUSES.formChildren.codRisk] && !!codRisk) {
      acc[CLAUSES.CLAUSES.formChildren.codRisk] = codRisk;
    }
    return acc;
  }
}

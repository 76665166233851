export enum FactorCode {
  PROFI_VALUE_FREE = 'LIBERA',
  PROFI_VALUE_FIXED = 'FISSA',
  COMBIN = 'COMBIN',
  PROFILE = '_PROFI',
  PROPRI = '_PRORI',
  PERCR = '_PERCR',
  INVRF = '_INVRF',
  PRRFI = '_PRRFI',
  EXPIRY_DATE = '_VRSPG',
  EFFECT_DATE = '_1PEFF',
  FIRST_TERM_DATE = '_VDFTE',
  LINEA = '_LINEA',
  FONGS = '_FONGS',
  TRANSFER_FROM_ANOTHER_SUPPLEMENTARY_PENSION_SCHEME = '_TPECO',
  ADHERENT_ACTIVITY = '_TAADE',
  PERIODIC_CONTRIBUTION_FROM_THE_EMPLOYER = '_COPDL',
  PURCHASE_AMOUNT = '_IACQR',
  SCHEDULED_PREMIUM = '_VRPRG',
  SCHEDULED_PREMIUM_DISTRIBUTION = '_DISFO'
}

export const FACTOR_VALUE = {
  _TPECO : {
    SELF_EMPLOYED: '1',
    EMPLOYEE: '2',
    OTHER: '3',
    FREELANCER: '4'
  }
};


import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NoteComponent } from '../../objects/note.component';
import { TimelineService } from '../../timeline.service';
import { CommentsComponent } from '../comments/comments.component';

@Component({
  selector: 'claims-note',
  templateUrl: './base-note.component.html',
  styleUrls: ['./base-note.component.scss']
})
export class BaseNoteComponent implements NoteComponent, OnInit {

  @Input() data: any;
  @Input() rechargeEventEmitter: EventEmitter<boolean>;
  bulletColor = '';
  rightColumnStyle = '';
  type = '';
  show = false;
  listFunz: any;

  constructor(
    private timelineService: TimelineService,
    private modalService: NgbModal,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any) {
      this.listFunz = this.authService.getOperator().enabledFeatures;
    }

  ngOnInit() {
    this.bulletColor = this.getBulletColor();
    this.rightColumnStyle = this.getRightColumnStyle();
    this.type = this.getType();
    if ((this.data.type === 'manual' && this.listFunz
         && this.listFunz.indexOf('SXTLCM') >= 0) ||
         this.data.type !== 'manual') {
        this.show = true;
    } else {
       this.show = (this.data.user === this.authService.getOperator().username);
    }
  }

  private getBulletColor() {
    return this.data.bulletColor || '';
  }

  private getRightColumnStyle() {
    return !this.data.hasLeftColumn ? 'hasOnlyRight' : '';
  }

  private getType() {
    if (this.data.cancelDate) {
      return 'canceled';
    } else {
      return this.data.type;
    }
    // return this.data.type;
  }

  cancel() {
    this.eventService.broadcastEvent('start-loader');
    this.timelineService.cancelManualNote(this.data.claimId, this.data.noteId).subscribe(
      (res: any) => {
        this.eventService.broadcastEvent('stop-loader');
        this.rechargeEventEmitter.emit(true);
      },
      (error: any) => {
        this.eventService.broadcastEvent('stop-loader');
        this.rechargeEventEmitter.emit(false);
      }
    );
  }

  comments() {
    const modalRef = this.modalService.open(CommentsComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.claimId = this.data.claimId;
    modalRef.componentInstance.userNoteId = this.data.noteId;

    modalRef.result.then((result) => {
      if (result) {
       this.rechargeEventEmitter.emit(true);
      }
    });
  }
}

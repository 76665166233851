import { TranslationWrapperService } from './../i18n/translation-wrapper.service';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { RoutingService } from '@rgi/rx/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SurveyCommonOperations, SurveyEditState, SurveyEditStateOperations } from '@rgi/ng-passpropro-survey';

@Injectable()
export class SurveyEditStateLifeOperations<S extends SurveyEditState> extends SurveyEditStateOperations<SurveyEditState> {


    constructor(
        protected commOps: SurveyCommonOperations,
        protected datePipe: DatePipe,
        protected routingService: RoutingService,
        protected translate: TranslationWrapperService
    ) {
      super(commOps, datePipe, routingService);
    }


    override beforeUpdate$(st: S): Observable<SurveyEditState> {
        return super.beforeUpdate$(st).pipe(
          switchMap((beforeState) => {
            return this.mapQuestionsTranslated(beforeState);
          })
        );
    }


    override getSaveSurvey$(st$: Observable<S>): Observable<SurveyEditState> {
        return super.getSaveSurvey$(st$).pipe(
          switchMap(st => {
            return this.mapQuestionsTranslated(st);
          })
        )
    }

    override getMakeNewVersion$(st$: Observable<S>): Observable<SurveyEditState> {
      return super.getMakeNewVersion$(st$).pipe(
        switchMap((beforeState) => {
          return this.mapQuestionsTranslated(beforeState);
        })
      )
    }

    protected getInitUpdate$(st$: Observable<S>): Observable<SurveyEditState> {
      return super.getInitUpdate$(st$).pipe(
        switchMap((beforeState) => {
          return this.mapQuestionsTranslated(beforeState);
        })
      )
    }



  protected mapQuestionsTranslated(beforeState: SurveyEditState) {
    if (!!beforeState?.surveyVersion?.lastVersion?.questionnaire) {
      beforeState.surveyVersion.lastVersion.questionnaire.description = this.translate.getImmediate(beforeState.surveyVersion.lastVersion.questionnaire.description);
    }
    beforeState?.surveyVersion?.lastVersion?.questionnaire?.questions?.map(quest => {
      quest.description = this.translate.getImmediate(quest.description);
      quest.text = this.translate.getImmediate(quest.text);
      quest.section.name = this.translate.getImmediate(quest.section.name);
      quest.answers.map(answer => {
        answer.values.map(v => this.translate.getImmediate(v.description));
        return answer;
      });
      return quest;
    });
    return of(beforeState);
  }
}

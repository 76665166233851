<div class="rgi-ui-date-control-container">
  <ng-content select="input[rgiRxDateTimeInput]"></ng-content>
  <button style="order:1" *ngIf="showRemoveButton && (!!dateTimeInput.value || dateTimeInput.elementRef.nativeElement.value)"
          class="rgi-ui-btn rgi-ui-date-control-remove" (click)="remove($event)">
    <div class="rgi-ui-icon rgi-ui-icon-close"></div>
  </button>
  <button style="order:1" [disabled]="dateTimeInput.disabled" [rgiRxDateTimeTrigger]="dateTimeInput"
          class="rgi-ui-btn rgi-ui-date-control-open">
    <div class="rgi-ui-icon rgi-ui-icon-calendar2"></div>
  </button>
  <ng-content select="rgi-rx-date-time"></ng-content>
</div>
<div class="rgi-ui-date-control-errors" *ngIf="dateTimeInput.ngControl && dateTimeInput.ngControl.errors">
  <ng-container *ngFor="let error of dateTimeInput.ngControl.errors | keyvalue">
  <rgi-rx-form-field-error *ngIf="shouldRenderValidation(error)">{{getLabel$(error) | async}}</rgi-rx-form-field-error>
  </ng-container>
</div>



import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PolicyData } from '../dsc-shared-model/dsc-model-policy';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';

@Component({
  selector: 'claims-dsc-insurance-company',
  templateUrl: './dsc-insurance-company.component.html',
  styleUrls: ['./dsc-insurance-company.component.scss']
})
export class DscInsuranceCompanyComponent implements OnInit, AfterViewInit {

  @Input() insuranceCompany: PolicyData; // PolicyData
  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Output() outputpolicyData = new EventEmitter<PolicyData>();


  isMaterialLossCovertureOnPolicy: string;
  isAccordion: boolean = false;
  phoneList: string = '';
  mailList: string = '';

  ngOnInit(): void {
    this.isMaterialLossCovertureOnPolicy = this.insuranceCompany &&
      this.insuranceCompany.isMaterialLossCovertureOnPolicy ? 'yes' : 'no';
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
  }

  ngAfterViewInit() {
    this.setMailandPhone()
  }

  onNgModelChange(event: string): void {
    const dataChange: PolicyData = { ...this.insuranceCompany };
    dataChange.isMaterialLossCovertureOnPolicy = event === 'yes' ? true : false;
    this.outputpolicyData.emit(dataChange);
  }

  setMailandPhone() {
    if(this.insuranceCompany.phone.length > 0) {
      this.insuranceCompany.phone.forEach((res, index) => {
        this.phoneList = this.phoneList + 
          (index+1 === this.insuranceCompany.phone.length ? res : res + ',')
      })
    } else {
      this.phoneList = '-'
    }
    if(this.insuranceCompany.email.length > 0) {
      this.insuranceCompany.email.forEach((res, index) => {
        this.mailList = this.mailList + 
          (index+1 === this.insuranceCompany.email.length ? res : res + ',')
      })
    } else {
      this.mailList = '-'
    }
  }
}

import {InjectionToken, Type} from '@angular/core';
import {HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface RgiRxHttpConfig {
  gzip: boolean;
}

export const RGI_RX_HTTP_CONFIG = new InjectionToken<RgiRxHttpConfig>('RGI_RX_HTTP_CONFIG');

export abstract class GzipProvider {
  abstract gzip(data: Uint8Array | number[] | string): Uint8Array;

  abstract unzip(data: Uint8Array | number[] | string): Uint8Array;
}

/**
 * ActivationStrategy API
 * Any interceptor of this package should be activated by a proper ActivationStrategy implementation.
 * This allows for consumer of the application to selectively enable or disable interceptor by their use case.
 */
export interface ActivationStrategy {
  shouldBeActivated(request: HttpRequest<any>): boolean;
}

export abstract class GzipActivationStrategy implements ActivationStrategy {
  abstract shouldBeActivated(request: HttpRequest<any>): boolean;
}


export enum RgiRxHttpErrorSeverity {
  ERROR,
  WARN,
  INFO
}

export type RgiRxHttpErrorScope = 'global' | 'feature' | string;

export class RgiRxHttpError<CTX = any> {
  /**
   * The severity of the error
   */
  readonly severity: RgiRxHttpErrorSeverity;
  /**
   * Severity rendered as text
   */
  readonly severityText: string;
  /**
   * The message of the error
   */
  readonly message: string;

  /**
   * The url from which the error has been generated
   */
  readonly url: string;
  /**
   * Headers of the HttpResponse
   */
  readonly headers?: HttpHeaders | { [name: string]: string | string[] };
  /**
   * Status of the HttpResponse
   */
  readonly status: number;
  /**
   * StatusText of the HttpResponse
   */
  readonly statusText: string;

  /**
   * Any detail of the error
   */
  readonly details?: string[];
  /**
   * The HttpResponse body of the error
   */
  readonly errorBody?: any;
  /**
   * Tracing information like execution id, system id or any system related data
   */
  readonly tracing?: {
    id?: string | number,
    systemMessage?: string
  };
  /**
   * A mutable map of properties that can store transient data.
   * Use these properties to set any application specific data for all the catch handlers and provide custom handlers behaviors
   */
  context: {[key: string]: any} | CTX;
  /**
   * The scope of the error. Use the scope to allow a listener of the error stream to filter the errors to handle by the
   * consumer-defined scopes rule.
   */
  readonly scope: RgiRxHttpErrorScope;

  constructor(message: string,
              status: number,
              url: string,
              opts?: {
      scope?: RgiRxHttpErrorScope,
      body?: any,
      statusText?: string,
      headers?: HttpHeaders | { [p: string]: string | string[] }
      severity?: RgiRxHttpErrorSeverity,
      details?: string[];
      tracing?: {
        id?: string | number,
        systemMessage?: string
      };
      context?: {[key: string]: any};
    }) {
    this.message = message;
    this.status = status;
    this.url = url;
    this.scope = opts && opts.scope ? opts.scope : 'global';
    this.severity = opts && opts.severity ? opts.severity : RgiRxHttpErrorSeverity.ERROR;
    this.severityText = Object.keys(RgiRxHttpErrorSeverity).find(k => RgiRxHttpErrorSeverity[k] === this.severity);
    this.headers = opts && opts.headers ? opts.headers : undefined;
    this.statusText =  opts && opts.statusText ? opts.statusText : undefined;
    this.tracing = opts && opts.tracing ? opts.tracing : undefined;
    this.details = opts && opts.details ? opts.details : undefined;
    this.errorBody = opts ? opts.body : undefined;
    this.context = opts && opts.context ? opts.context : undefined;
  }
}

/**
 * Static filter configuration for the error stream
 */
export interface RgiRxHttpErrorFilterOpts {
  /**
   * Filter severity level
   */
  severity?: RgiRxHttpErrorSeverity;

  /**
   * Filter emissions by scopes
   */
  scopes?: RgiRxHttpErrorScope[];
}

/**
 * Interface for implementing a custom HttpError filter as part of RgiRxHttpErrorFilterType
 * @see RgiRxHttpErrorFilterType
 * Only the errors that match the condition of the when method shall be rendered by a stream
 */
export interface RgiRxHttpErrorFilter {
    when(error: RgiRxHttpError): Observable<boolean> | boolean;
}

/**
 * An option type for filtering error stream when using RgiRxHttpErrorService
 */
export type RgiRxHttpErrorFilterType = RgiRxHttpErrorFilterOpts | Type<RgiRxHttpErrorFilter>;

export abstract class RgiRxHttpErrorService {
  /**
   * Get the error stream
   * @param opts optional RgiRxHttpErrorFilterType to filter the stream
   */
  abstract error$(opts?: RgiRxHttpErrorFilterType): Observable<RgiRxHttpError>;
}


/**
 * @description A provider that allows to set tracing information about the application.
 */
export abstract class RgiRxHttpTracingProvider {
  abstract get traceId(): string | undefined;
}

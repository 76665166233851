import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessage } from './lpc-modal.model';

@Component({
  selector: 'lpc-modal',
  templateUrl: './lpc-modal.component.html',
  styleUrls: ['./lpc-modal.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class LpcModalComponent {
  title: string;
  message: string;
  messages: ModalMessage[];
  closeVisible = true;
  confirmVisible = true;
  confirmLabel = 'CONFIRM';

  constructor(
    protected activeModal: NgbActiveModal
  ) { }

  onClose() {
    this.activeModal.close();
  }

  onConfirm($event: MouseEvent) {
    this.activeModal.close(true);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RgiRxDatatableRowAction, TableSchema} from "@rgi/rx/ui";
import {Observable} from "rxjs";

@Component({
  selector: 'rgi-pnc-psales-movements-data-table',
  templateUrl: './movements-data-table.component.html',
})
export class MovementsDataTableComponent implements OnInit {

  constructor() { }
  @Input() schema: TableSchema;
  @Input() data: any[] | Observable<any>;
  @Output() actionDetails = new EventEmitter<any>();
  @Output() actionDelete = new EventEmitter<any>();


  ngOnInit() {
  }

  public onActionClick($event: RgiRxDatatableRowAction<any>) {
    switch ($event.name) {
      case 'DETAILS':
        this.actionDetails.emit({key: $event.row.key});
        break;
      case 'DELETE':
        this.actionDelete.emit({
          key: $event.row.key
        });
        break;
    }
  }
}

export enum RGI_DOCUMENTS_ACTIONS {
  BACK = 'BACK',
  CANCEL = 'CANCEL',
  CONTINUE = 'CONTINUE',
  END = 'END',
  FILE_UPLOADED = 'FILE_UPLOADED',
  RESET = 'RESET',
  DOWNLOAD = 'DOWNLOAD',
  SIGN = 'SIGN',//TODO DA RIVEDERE
  SEND = 'SEND',
  ARCHIVE = 'ARCHIVE'
}

export enum RGI_DOCUMENT_ALLOWED_ACTIONS {
  CAN_DOWNLOAD = 'CAN_DOWNLOAD',
  CAN_SIGN_START = 'CAN_SIGN_START',
  CAN_SIGN_STOP = 'CAN_SIGN_STOP',
  CAN_ARCHIVE = 'CAN_ARCHIVE',
  CAN_DELIVERY = 'CAN_DELIVERY'
}

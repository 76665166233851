<ng-container [formGroup]="policyDataForm">
  <div class="rgi-gp-flex-row">
    <ng-container  *ngFor="let field of visibleFields; trackBy: visibleFieldsTrackBy">
      <div class="rgi-gp-field-container" *ngIf="field.visible" >
        <div class="rgi-gp-field" [ngClass]="{'rgi-gp-flex-end': field | gpFieldsShowCustomAction}">
          <rgi-rx-form-field [attr.data-qa]="field.code" [ngClass]="{'rgi-gp-flex-70': field | gpFieldsShowCustomAction}">

            <label [attr.rgiRxLabel]="field.type !== POLICY_DATA_FIELD_TYPE.BOOLEAN">
              <span>{{field?.label}}</span>
              <span *ngIf="field?.mandatory" class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*</span>
            </label>

            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchCase="POLICY_DATA_FIELD_TYPE.NUMERIC">
                <input type="number" rgiRxInput
                       formControlName="{{field.code}}">
              </ng-container>

              <ng-container *ngSwitchCase="POLICY_DATA_FIELD_TYPE.LIST">
                <rgi-rx-button-toggle formControlName="{{field.code}}" *ngIf="field.code==='POLICY_TYPE'; else policyDataList">
                  <button rgi-rx-button rgiRxValueOption *ngFor="let value of field.allowedValues; trackBy: allowedValuesTrackBy" [value]="value.code" >
                    {{value.description}}
                  </button>
                </rgi-rx-button-toggle>
                <ng-template #policyDataList>
                  <select rgiRxNativeSelect formControlName="{{field.code}}" >
                    <option value=""></option>
                    <option *ngFor="let value of field.allowedValues; trackBy: allowedValuesTrackBy" [value]="value.code">{{value.description}}</option>
                  </select>
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchCase="POLICY_DATA_FIELD_TYPE.BOOLEAN">
                <rgi-rx-button-toggle formControlName="{{field.code}}">
                  <button rgi-rx-button rgiRxValueOption value="false" translate>
                    _GP_._BTN_._NO_
                  </button>
                  <button rgi-rx-button rgiRxValueOption value="true" translate>
                    _GP_._BTN_._YES_
                  </button>
                </rgi-rx-button-toggle>
              </ng-container>

              <ng-container *ngSwitchCase="POLICY_DATA_FIELD_TYPE.STRING">
                <input rgiRxInput formControlName="{{field.code}}">
              </ng-container>

              <ng-container *ngSwitchCase="POLICY_DATA_FIELD_TYPE.DATE">
                <rgi-rx-date-picker [openOnClick]="false">
                  <input [rgiRxDateTimeInput]="picker1"
                         [dateFilter] = "getDateFilter(field)"
                         formControlName="{{field.code}}">
                </rgi-rx-date-picker>
                <rgi-rx-date-time #picker1></rgi-rx-date-time>
              </ng-container>

              <ng-container *ngSwitchCase="POLICY_DATA_FIELD_TYPE.INSURANCE_HOUR">
                <div class="rgi-gp-flex rgi-gp-w-100">
                  <input class="rgi-gp-w-50" rgiRxInput formControlName="{{field.code}}" data-qa="HOURS">
                  <input class="rgi-gp-w-50" rgiRxInput formControlName="{{field.code + '_MINUTES'}}" data-qa="MINUTES">
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="POLICY_DATA_FIELD_TYPE.DATETIME">
                <rgi-rx-date-picker [openOnClick]="false">
                  <input [rgiRxDateTimeInput]="picker1"
                         [min]="field.minDate"
                         [max]="field.maxDate"
                         formControlName="{{field.code}}">
                </rgi-rx-date-picker>
                <rgi-rx-date-time #picker1 [pickerType]="'both'"></rgi-rx-date-time>
              </ng-container>

            </ng-container>

            <ng-container *ngIf="field.mandatory">
              <div  class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
            </ng-container>

          </rgi-rx-form-field>
          <ng-container *ngIf="field | gpFieldsShowCustomAction">
            <button rgi-rx-button color="secondary" class="uppercase rgi-gp-flex-30"
                    (click)="customAction.emit(field)" [attr.data-qa]="field.code + '-BTN'">
              {{ getCustomActionLabel(field) | async }}
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <span class="rgifont rgi-dettagli_tecnici"></span>
{{ '_CLAIMS_._HISTORICAL_EROSION' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
        </div>
        <div class="modal-body" style="padding-top: 5px">
            <div class="container-fluid policy-table" data-qa="historyErosion-list">
            <div style="float: left;">
{{ '_CLAIMS_._TOTAL' | translate }}: {{totalFilter}}
            </div>
            <div style="float: right; margin-right: 0px;">
                <input type="text" class="form-control" name="search" [(ngModel)]="searchFE" placeholder="trova..." id="search"
                (change)="search()" />
            </div>
                <table class="table-responsive table coreDT" style="display: block;">
                    <thead>
                    <tr class="head-result thDataTable">
                        <td style="width: 5%;">
                            &nbsp;
                        </td>
                        <td style="width:17%">
                        <span attr.data-qa="historyErosion-policy-label">{{ '_CLAIMS_._POLICY_NUMBER' | translate }}</span>
                        </td>

                        <td style="width:10%">
                        <span attr.data-qa="historyErosion-masterpolicy-label">{{ '_CLAIMS_._MASTER_POLICY_NUMBER' | translate }}</span>
                        </td>

                        <td style="width:20%">
                        <span attr.data-qa="historyErosion-risk-label">{{ '_CLAIMS_._RISK_DESCRIPTION' | translate }}</span>
                        </td>

                        <td style="width:13%">
                        <span attr.data-qa="historyErosion-claimnumber-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</span>
                        </td>

                        <td style="width:10%">
                        <span attr.data-qa="historyErosion-movDescr-label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</span>
                        </td>

                        <td style="width:10%">
                        <span attr.data-qa="historyErosion-insertDate-label">{{ '_CLAIMS_._INSERT_DATE' | translate }}</span>
                        </td>
                    </tr>

                    </thead>

                    <tbody style="height: 300px; display: inline-block; overflow: auto;">
                    <tr *ngFor="let aRow of rows; 
                                    let even = even; 
                                    let odd = odd;
                                    let index = index; " [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
                        class="table-row" attr.data-qa="historyErosion-result-{{index}}">
                            <td style="width: 10px;">
                            <button type="button" class="btn btn-flat btn-sm" (click)="openDetailHistory(aRow)" style="border: 0px;" >
                                <span class="glyphicon glyphicon-plus" style="width:10px; height:10px;"></span>
                            </button> 
                            </td>
                            <td style="width:20%; font-size: 12px;" >
                                {{aRow.element.policyNumber}} 
                            </td>

                            <td style="width:10%; font-size: 12px;">
                             {{aRow.element.applicationNumber}}
                            </td>

                            <td style="width:25%; font-size: 12px;">
                                {{aRow.element.unitDescription}}
                            </td>

                            <td style="width:15%; font-size: 12px;">
                                {{aRow.element.claimNumber}}
                            </td>

                            <td style="width:10%; font-size: 12px;">
                                {{aRow.element.movementDescr}}
                            </td>

                            <td style="width:10%; font-size: 12px;">
                                {{aRow.element.insDate | date:'dd/MM/yyyy'}}
                            </td>
                    </tr>
                    </tbody>
                </table>

            </div>  
        </div>
    </div>
</div>

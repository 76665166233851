import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimelineService } from '../../timeline.service';

@Component({
  selector: 'claims-add-manual-note',
  templateUrl: './add-manual-note.component.html',
  styleUrls: ['./add-manual-note.component.scss']
})
export class AddManualNoteComponent implements OnInit {
  message: string;
  @Input() claimNumber: string;

  constructor(
    public activeModal: NgbActiveModal,
    @Inject('eventService') private eventService: any,
    private timelineService: TimelineService
  ) { }

  ngOnInit() {
  }


  closeModal() {
    this.activeModal.dismiss();
  }

  onConfirm() {
    this.eventService.broadcastEvent('start-loader');
    this.timelineService.insertManualNote(this.claimNumber, this.message).subscribe(
      (res: any) => {
        this.eventService.broadcastEvent('stop-loader');
        this.activeModal.close(res);
        // event.stopPropagation();
      },
      (err: any) => {
        this.eventService.broadcastEvent('stop-loader');
      }

    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivityDto, ActivityUser } from '@rgi/digital-claims-common-angular';

@Injectable()

export class ReassignTaskService {


  private baseApiUrlV2;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }


  public getActivityUsersByGroup(groupCode: string) {
    return this.httpClient.get<ActivityUser[]>(this.getActivityUsersByGroupUri(groupCode));
  }

  private getActivityUsersByGroupUri(groupCode: string) {
    return this.baseApiUrlV2 + '/claims/activities/users?groupCode=' + groupCode;
  }


  public saveActivity(activity: ActivityDto) {
    return this.httpClient.post(this.getActivitySaveUri(), activity);
  }

  private getActivitySaveUri() {
    return this.baseApiUrlV2 + '/claims/activities';
  }

}

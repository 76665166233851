<form #ngForm="ngForm" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title" translate>Recapito</h4>
  </div>
  <div class="modal-body-full">
    <div class="core-header-icon ">
      <span class="header-icon rgifont rgi-placeholder"></span>
      <span class="header-text"> Nuovo Recapito </span>
      <span class="pull-right header-text">  </span>
    </div>
    <div class="row">
        <div class="col-sm-10">
            <label class="label-core" translate>Nation</label>
            <lpc-required *ngIf="!!formGroup.get('country').validator" required="true"></lpc-required><br>
            <ng-select
            [items]="countries"
            bindLabel="description"
            bindValue="abbreviation"
            name="'contryCode'"
            formControlName="country"
            [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('country').errors }"
            required>
            </ng-select>
        </div>
    </div>
    <div *ngIf="!!formGroup.get('country').value && formGroup.get('country').value === 'IT' && receivedLabels" >
        <div class="example-row row">
            <div class="col-sm-10">
                <rgi-rx-form-field>
                    <label rgiRxLabel translate>{{adressConfig.adminLevel2.label}}</label>
                    <input rgiRxInput
                           [rgiRxAutoComplete]="autoCompleteComponent"
                           [openOnFocus]="true"
                           name="'provinceAbbreviation'"
                           [minLength]="0"
                           displayWith="descrizione"
                           formControlName="adminLevel2"
                           [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('adminLevel2').errors }"
                           (onValueChange)="getCitiesByProvince($event)"
                           (onChange)="onAutoCompleteProvinceChange($event)">
                    <rgi-rx-auto-complete #autoCompleteComponent>
                      <ng-template rgiRxAutoCompleteContent>
                            <rgi-rx-option *ngFor="let province of (provinces$ | async)" [value]="province">{{ province.descrizione }}</rgi-rx-option>
                      </ng-template>
                  </rgi-rx-auto-complete>
                  </rgi-rx-form-field>
            </div>
        </div>
        <div class="example-row row" *ngIf="hasSelectedProvince">
            <div class="col-sm-10">
                <label class="label-core" translate>{{adressConfig.adminLevel3.label}}</label>
                <lpc-required *ngIf="!!formGroup.get('adminLevel3').validator" required="true"></lpc-required><br>
                <ng-select
                    [items]="cities"
                    name="'provinceAbbreviation'"
                    bindLabel="name"
                    bindValue="name"
                    formControlName="adminLevel3"
                    [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('adminLevel3').errors }"
                    (change)="getCapByCity($event)"
                    ><!-- custom-change="provinceChanged(subject.residence.province.codice)" -->
                </ng-select>
            </div>
        </div>

    </div>
    <div *ngIf="!!formGroup.get('country').value && formGroup.get('country').value !== 'IT' && receivedLabels">
        <div class="example-row row">
            <div class="col-sm-10">
                <label class="label-core" translate>{{adressConfig.adminLevel1.label}}</label>
                <lpc-required *ngIf="!!formGroup.get('adminLevel1').validator" required="true"></lpc-required><br>
                <input [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('adminLevel1').errors }" name="'adminLevel1'" class="form-control" formControlName="adminLevel1">
            </div>
        </div>
        <div class="example-row row">
            <div class="col-sm-10">
                <label class="label-core" translate>{{adressConfig.adminLevel2.label}}</label>
                <lpc-required *ngIf="!!formGroup.get('adminLevel2').validator" required="true"></lpc-required><br>
                <input  [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('adminLevel2').errors }" name="'adminLevel2'" class="form-control" formControlName="adminLevel2">
            </div>
        </div>
        <div class="example-row row">
            <div class="col-sm-10">
                <label class="label-core" translate>{{adressConfig.adminLevel3.label}}</label>
                <lpc-required *ngIf="!!formGroup.get('adminLevel3').validator" required="true"></lpc-required><br>
                <input [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('adminLevel3').errors }" name="'adminLevel3'" class="form-control" formControlName="adminLevel3">
            </div>
        </div>
    </div>
    <div *ngIf="(!!formGroup.get('country').value && formGroup.get('country').value !== 'IT' && receivedLabels) ||
                (!!formGroup.get('country').value && formGroup.get('country').value === 'IT' && receivedLabels &&
                !!formGroup.get('adminLevel3').value) && hasSelectedProvince">
        <div class="example-row row" *ngIf="!!formGroup.get('adminLevel3').value && !!formGroup.get('country').value && formGroup.get('country').value === 'IT'">
            <div class="col-sm-10">
                <label class="label-core" translate>Toponym</label>
                <lpc-required *ngIf="!!formGroup.get('toponimo').validator" required="true"></lpc-required><br>
                <ng-select
                    [items]="toponyms"
                    bindLabel="description"
                    bindValue="description"
                    name="'toponym'"
                    formControlName="toponimo">
                </ng-select>
            </div>
        </div>
        <div class="example-row row">
            <div class="col-sm-10">
                <label class="label-core" translate>Address</label>
                <lpc-required *ngIf="!!formGroup.get('indirizzo').validator" required="true"></lpc-required><br>
                <input [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('indirizzo').errors }" name="'adminLevel2'" class="form-control" formControlName="indirizzo">
            </div>
        </div>
        <div class="example-row row">
            <div class="col-sm-10">
                <label class="label-core" translate>{{adressConfig.houseNumber.label}}</label>
                <lpc-required *ngIf="!!formGroup.get('numeroCivico').validator" required="true"></lpc-required><br>
                <input [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('numeroCivico').errors }" name="'adminLevel2'" class="form-control" formControlName="numeroCivico">
            </div>
        </div>
        <div class="example-row row">
            <div class="col-sm-10">
                <label class="label-core" translate>Frazione</label>
                <lpc-required *ngIf="!!formGroup.get('frazione').validator"  required="true"></lpc-required><br>
                <input  name="'frazione'" class="form-control" formControlName="frazione">
            </div>
        </div>
        <div *ngIf="!!formGroup.get('adminLevel3').value && !!formGroup.get('country').value && formGroup.get('country').value === 'IT'">
            <div class="example-row row">
                <div class="col-sm-10">
                    <label class="label-core" translate>{{adressConfig.zip.label}}</label>
                    <lpc-required *ngIf="!!formGroup.get('cap').validator" required="true"></lpc-required><br>
                    <ng-select
                        [items]="selectedCityCap"
                        name="'postalCode'"
                        [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('cap').errors }"
                        formControlName="cap">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="example-row row" *ngIf="!!formGroup.get('country').value && formGroup.get('country').value !== 'IT'">
            <div class="col-sm-10">
                <label class="label-core" translate>{{adressConfig.zip.label}}</label>
                <lpc-required *ngIf="!!formGroup.get('cap').validator"  required="true"></lpc-required><br>
                <input [ngClass]="{ 'is-invalid': ngForm.submitted && !!formGroup.get('cap').errors }" name="'postalCode'" class="form-control" formControlName="cap">
            </div>
        </div>
        <div class="example-row row">
            <div class="col-sm-10">
                <label class="label-core" translate>Presso</label>
                <lpc-required *ngIf="!!formGroup.get('presso').validator" required="true"></lpc-required><br>
                <input  name="'presso'" class="form-control" formControlName="presso">
            </div>
        </div>
    </div>
    <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
  </div>
  <!-- MESSAGGI di ERRORE -->
  <pnc-floating-error-messages
  [validationMessages]="getValidationMessages">
  </pnc-floating-error-messages>

  <div class="modal-footer">
    <div class="app-modal-footer">
      <div class="btn-group btn-group-justified">
        <div class="btn-group">
          <button (click)="close()" class="btn btn-warning text-uppercase" data-qa="close-button-action" translate>cancel</button>
        </div>
        <div class="btn-group">
          <button type="submit" class="btn btn-warning text-uppercase" data-qa="close-button-action" translate>lpc_confirm</button>
        </div>
      </div>
    </div>
  </div>
</form>


export const CATEGORIES = [
  {
    description: 'Danni Auto',
    code: '1'
  },
  {
    description: 'Danni Rami Elementari',
    code: '2'
  },
  {
    description: 'Cauzioni',
    code: '3'
  },
  {
    description: 'Credito',
    code: '4'
  },
  {
    description: 'Grandine',
    code: '5'
  },
  {
    description: 'Trasporti',
    code: '6'
  },
  {
    description: 'Vita',
    code: '7'
  }
];

import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {CommonService} from './common.service';
import {Message} from './models/message';
import {catchError, map} from 'rxjs/operators';
import {GenericEntity} from './models/domain-models/generic-entity';
import {TranslateService} from '@ngx-translate/core';


// custom validator to check id DOB is after TODAY
export function PlateValidator(controlName: string) {

  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];

    if (control.value) {
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (controlName === 'plateNumber'
        && (control.value.indexOf('\\') > -1 || format.test(control.value))) {
        control.setErrors({required: true});
      }
    } else {
      const validator: any = control.validator && control.validator(new UntypedFormControl());
      if (validator && validator.required) {
        control.setErrors({required: true});
      } else {
        control.setErrors(null);
      }
    }
  };

}

export function isPlateFormatOk$(control: AbstractControl, commonService: CommonService, validationMessages: Message[],
                                 nodeId: string, area: string, translationService: TranslateService): Observable<boolean> {
  const isPlateInserted = control.value?.trim() !== '';
  if (isPlateInserted) {
    const plateNumber = String(control.value).trim().toUpperCase();
    return commonService.getPlateType(plateNumber, '', '', nodeId)
      .pipe(
        map((data: GenericEntity) => {
            if (data.id === '-1') {
              control.setErrors({invalidPlate: true});
              validationMessages.push(new Message(area, translationService.instant('Plate number format is not correct')));
              return false;
            } else {
              control.setErrors(null);
              return true;
            }
          },
        ),
        catchError(() => {
          control.setErrors({invalidPlate: true});
          validationMessages.push(new Message(area, 'Plate number format is not correct'));
          return of(false);
        })
      );
  } else {
    // trigger form validation
    control.setValue(null);
  }
  return of(false);
}

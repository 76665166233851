import { Component, EventEmitter, ViewChild, AfterViewInit, Inject, OnInit, Input, Output, LOCALE_ID } from '@angular/core';
import { RoutingService, RoutingHostDirective } from '@rgi/portal-ng-core';
import { ClaimOpeningService } from './claim-opening.service';
import {
  ApiKeyValue,
  CardComponent,
  CardComponentService,
  SessionService,
  Unit,
  Outcome,
  AlertService
} from '@rgi/digital-claims-common-angular';
import { ClaimOpeningPolicyResultsComponent } from './components/claim-opening-policy-results/claim-opening-policy-results.component';
import { ClaimOpeningPolicySearchComponent } from './components/claim-opening-policy-search/claim-opening-policy-search.component';
import { ClaimOpeningDetailsComponent } from './components/claim-opening-details/claim-opening-details.component';
import { Policy } from '@rgi/digital-claims-common-angular';
import { TimeRequest } from './components/claim-opening-policy-search/dto/time-request';
import { formatDate } from '@angular/common';
import { ClaimOpeningPolicySearchResultsService } from './components/claim-opening-policy-results/claim-opening-policy-results.service';
import { PotentialClaimData } from './components/claim-opening-policy-results/model/domain/potential-claim-data';
import * as uuid from 'uuid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  PartyDataNewAssetComponent
} from './components/claim-opening-details/party-data-panel-content/party-data-new-asset/party-data-new-asset.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-claim-opening',
  templateUrl: './claim-opening.component.html',
  styleUrls: ['./claim-opening.component.scss'],
  providers: [RoutingService]
})
export class ClaimOpeningComponent extends CardComponent implements AfterViewInit, OnInit {
  @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;

  routes = null;

  authSrv: any;
  public abilitaAperturaSenzaCausale: boolean;

  constructor(
    private routingService: RoutingService,
    @Inject('searchComponent') searchComponent,
    @Inject('searchResultComponent') searchResultComponent,
    @Inject('detailsComponent') detailsComponent,
    @Inject('similarClaimsComponent') similarClaimsComponent,
    @Inject('cardPassService') private cardPassService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('authService') authService,
    @Inject(LOCALE_ID) private locale: string,
    private claimOpeningService: ClaimOpeningService,
    cardComponentService: CardComponentService,
    private modalService: NgbModal,
    sessionService: SessionService,
    private claimOpeningPolicySearchResultsService: ClaimOpeningPolicySearchResultsService,
    private alertService: AlertService,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      search: searchComponent,
      searchResult: searchResultComponent,
      details: detailsComponent,
      similarClaims: similarClaimsComponent
    };

    this.authSrv = authService;

    const listFunz = this.authSrv.getOperator().enabledFeatures;

    if (listFunz && listFunz.indexOf('SXSCOP') >= 0) {
      this.abilitaAperturaSenzaCausale = true;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  @Input() set driver(driver: any) {
    if (driver && this.routingService.componentRef) {
      (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).driver = driver;
    }
  }

  @Input() set third(third: any) {
    const subType = this.claimOpeningService.getSubjectType();
    if (subType === 'third') {
      (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).third = third;
    } else if (subType === 'driver') {
      (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).driver = third;
    } else if (subType === 'doctor') {
      this.claimOpeningService.setDocSelected(third);
      const modalRef = this.modalService.open(PartyDataNewAssetComponent, {
        windowClass: 'basic',
        size: 'lg'
      });
      modalRef.componentInstance.nextId = this.claimOpeningService.getNextId();
      modalRef.componentInstance.typesOfLoss = this.claimOpeningService.getTypesOfLoss();
      modalRef.componentInstance.party = this.claimOpeningService.getParty();
      modalRef.componentInstance.card = this.claimOpeningService.getCard();
      modalRef.componentInstance.injuryCauseList = this.claimOpeningService.getInjuryCauseList();
      modalRef.componentInstance.injuryNatureList = this.claimOpeningService.getInjuryNatureList();
      modalRef.componentInstance.professionList = this.claimOpeningService.getProfessionList();
      modalRef.componentInstance.injuryLocationList = this.claimOpeningService.getInjuryLocationList();
      modalRef.componentInstance.comeFrom = 'DOC';
      modalRef.componentInstance.docSelected = third;

      const that = this;

      modalRef.result.then((result) => {
        if (result) {
          console.log('openNewAssetModal_BIS ->', result);
          if (result.assettype === '2') {
            this.claimOpeningService.getDamagedAssetsInjury().push(result);
          } else if (result.assettype === '1') {
            this.claimOpeningService.getDamagedAssetsVehicle().push(result);
          }  else {
            this.claimOpeningService.getDamagedAssetsMaterialDamage().push(result);
          }
          this.claimOpeningService.getDamagedAssetsWithoutDuplication().push(result);
        }
      });
    } else if (third && this.routingService.componentRef) {
      (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).third = third;
    }
  }

  @Input() set newSubject(newSubject: any) {
    if (this.claimOpeningService.getSubjectType() === 'third') {
      (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).third = newSubject;
    }
    if (this.claimOpeningService.getSubjectType() === 'driver') {
      (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).driver = newSubject;
    }
  }


  @Input() set bodywork(val: any) {
    if (val) {
      if (val.type === 'objIntegrationPass') {
        if (val.data && val.data.naviJumpEnd) {
          (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).bodyworkIntegrationObj = val;
        } else {
          if (val.data.objNavigation) {
            val.data.objNavigation.newSession = true;
            val.data.objNavigation.functionReloadCtrlParent = 'reloadBodywork()';
            val.data.objNavigation.navReturnOk = 'home';
          }
          this.cardPassService.createJump(this.card, val.data, 'Nuova Carrozzeria', 'newCarBodywork', 'claimsClaimOpening');
        }
      } else {
        if (val && this.routingService.componentRef) {
          (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).bodywork = val;
        }
      }
    }
  }

  @Input() set bodyworkfrompass(val: any) {
    if (val) {
      (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).bodyworkIntegrationObj = val;
    }
  }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;
    const card = this.card;
    let pes: any;
    const policyModifyData: any = null;
    let isFromCardAjs: false;
    let isDummyPolicy: boolean;
    this.claimOpeningService.inzializeExternaPortfolio();
    if (this.id) {
      pes = this.coreResult.getResult(this.id, 'pes');
      this.claimOpeningService.setSessionPolicyModifyData(this.id, this.coreResult.getResult(this.id, 'policyModifyData'));
      isFromCardAjs = this.coreResult.getResult(this.id, 'isFromCardAjs');
    }

    if (this.coreResult.getResult(this.id, 'result') &&
      this.coreResult.getResult(this.id, 'result').policies) {
      // il componenete angular è agganciato dalla card JS
      isDummyPolicy = this.coreResult.getResult(this.id, 'result').isDummyPolicies;
      this.mapPoliciesJs();

    }

    if (this.isMainCard() && !isFromCardAjs) {
      if (pes) {
        this.routingService.loadComponent(this.routes.details);
        card.title = 'Modify FNOL';
        (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).pes = pes;
        (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).card = card;
      } else if (this.claimOpeningService.getSessionPolicies(card.id) && this.claimOpeningService.getSessionPolicies(card.id).length > 0) {
        this.routingService.loadComponent(this.routes.searchResult);
        this.rxTranslationsService.translate('_CLAIMS_._POLICY_LIST')
        .subscribe(res =>  card.title = res);
        (this.routingService.componentRef.instance as ClaimOpeningPolicyResultsComponent).card = card;
      }

    } else if (isFromCardAjs) {
      const policies = this.claimOpeningService.getSessionPolicies(this.id);
      if (policies && isDummyPolicy) {
        this.goToPolicyDetails(policies[0]);
      } else {
        this.routingService.loadComponent(this.routes.searchResult);
        this.rxTranslationsService.translate('_CLAIMS_._POLICY_LIST')
        .subscribe(res =>  card.title = res);
        (this.routingService.componentRef.instance as ClaimOpeningPolicyResultsComponent).card = card;
      }
    } else if (!this.sessionCard) {
      this.routingService.loadComponent(this.routes.search);
      (this.routingService.componentRef.instance as ClaimOpeningPolicySearchComponent).card = card;
    } else {
      card.title = '';
    }

  }

  goToPolicyDetails(policy: Policy) {
    this.initPotentialClaim(policy);
  }

  initPotentialClaim(policy: Policy) {
    this.rxTranslationsService.translate('_CLAIMS_._FNOL_DATA_COLLECTION')
    .subscribe(res =>  this.card.title = res);
   // this.card.title = 'FNOL data collection';
    this.claimOpeningService.setSessionSelectedPolicy(this.card.id, policy);
    const formatdate = 'dd-MM-yyyy';
    const occurrenceDate = this.claimOpeningService.getSessionOccurrenceDate(this.card.id);
    const occurrenceTime = this.claimOpeningService.getSessionTimeRequest(this.card.id).toString();
    const dateString = occurrenceDate ? formatDate(occurrenceDate, formatdate, this.locale) : null;
    const sessionUuid = uuid.v4();
    this.claimOpeningService.setSessionUuid(this.card.id, sessionUuid);
    const inputPotentialClaim = new PotentialClaimData(
      sessionUuid,
      policy.number,
      this.claimOpeningService.getCategory(this.card.id).idCategory,
      dateString, occurrenceTime,
      'true',
      'true',
      formatDate(new Date(), formatdate, this.locale),
      formatDate(new Date(), formatdate, this.locale));
    /* this.claimOpeningService.setSessionAdministrativeCoverture(this.card.id, this.coverturesFromService.administrativeCoverture); */
    // save cache
    this.claimOpeningPolicySearchResultsService.initPotentialClaim(inputPotentialClaim).subscribe((outcome: Outcome) => {

      if (outcome.code === '1') {

        // go to detail
        this.routingService.loadComponent(this.routes.details);
        (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).card = this.card;
      } else {
        this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._CACHE_ERROR').subscribe(
          res =>  this.alertService.translatedMessage(res)
        )
       
      }
    },
      (error: Error) => {
        console.log(error);
      }
    );
  }

  getUnits(properties: ApiKeyValue[]): Unit[] {
    const units: Unit[] = [];
    properties.forEach(property => {
      if (property.chiave === 'DESCR_UNIT') {
        const descriptions = property.valore.split('#');
        descriptions.forEach(description => {
          const unit = new Unit();
          unit.description = description;
          units.push(unit);
        });
      }
    });
    return units;
  }

  getClaimBranchCodes(properties: ApiKeyValue[]): string[] {
    let codes: string[];
    properties.forEach(property => {
      if (property.chiave === 'RAMI_SX') {
        codes = property.valore.split('#');
      }
    });
    return codes;
  }

  mapPoliciesJs() {
    const policiesRes = this.coreResult.getResult(this.id, 'result').policies;
    const occurrenceDate: Date = this.coreResult.getResult(this.id, 'result').occurrenceDate;
    occurrenceDate.setHours(0, 0, 0, 0);
    const occurrenceTime = this.coreResult.getResult(this.id, 'result').occurrenceHour;
    const plate = this.coreResult.getResult(this.id, 'result').plate;
    const selectedCategory = this.coreResult.getResult(this.id, 'result').selectedCategory;
    const listExternalPolicyFound = this.coreResult.getResult(this.id, 'result').listExternalPolicyFound;
    const companyDetails = this.coreResult.getResult(this.id, 'result').companyDetails;
    const typePtf = companyDetails ? companyDetails.ptfTypeCode : '';
    const sessionId = this.id;
    const policies: Policy[] = [];
    const isDummyPolicy = this.coreResult.getResult(this.id, 'result').isDummyPolicies;
    let ext = false;
    for (const policyRes of  policiesRes) {
      const policy = new Policy();
      policy.number = policyRes.policyNumber;
      ext = (policyRes.external) ? policyRes.external : false;

      policy.description = policyRes.description;

      policy.effectDate = policyRes.effectDate;
      policy.expirationDate = policyRes.expirationDate;
      policy.holder = policyRes.contractingSubject;
      policy.objectId = policyRes.objectId;
      policy.product = policyRes.productCode;
      policy.nodeCode = policyRes.nodeCode;
      policy.asset = policyRes.asset;
      policy.originalEffectDate = policyRes.originalEffectDate;
      if (policyRes.extension && policyRes.extension.properties) {
        policy.units = this.getUnits(policyRes.extension.properties);
        policy.claimBranchCodes = this.getClaimBranchCodes(policyRes.extension.properties);
      }
      policy.isDummyPolicy = isDummyPolicy;
      if (policyRes.state &&  policyRes.state) {
        if (policyRes.state.codice === '13') {
          policy.isCanceled = true;
          policy.cancellationDate = policyRes.cancellationDate;
        } else {
          policy.isCanceled = false;
        }
      }

      if (!occurrenceDate) {
        if (policyRes.state && policyRes.state) {
          if (policyRes.state.codice === '13') {
            policy.isCanceled = true;
            policy.cancellationDate = policyRes.cancellationDate;
          } else {
            policy.isCanceled = false;
          }
        }
      } else {
        // data min: recupero originalEffectDate, se non c'è uso effectDate
        let dMin: Date = new Date(policyRes.effectDate);
        if (policyRes.originalEffectDate && policyRes.originalEffectDate.length > 0) {
          dMin = new Date(policyRes.originalEffectDate);
        }
        dMin.setHours(0, 0, 0, 0);

        // data max: la minore tra cancellationDate e expirationDate
        let dMax: Date = null;

        if (policyRes.expirationDate) {
          dMax = new Date(policyRes.expirationDate);
        }

        if (policyRes.cancellationDate) {
          if (dMax) {
            const tempDate: Date = new Date(policyRes.cancellationDate);
            if (tempDate < dMax) {
              dMax = tempDate;
            }
          } else {
            dMax = new Date(policyRes.cancellationDate);
          }
        }

        policy.isCanceled = true;
        // isCanceled false se non è tra data min e data max
        if (occurrenceDate >= dMin) {
          if (dMax) {
            dMax.setHours(0, 0, 0, 0);
            if (occurrenceDate <= dMax) {
              policy.isCanceled = false;
            }
          } else {
            policy.isCanceled = false;
          }
        }
      }
      // modifica

      policies.push(policy);
    }

    this.claimOpeningService.setPolicies(policies);
    this.claimOpeningService.setExternaPortfolio(ext, listExternalPolicyFound, typePtf);
    // const sessionId = this.session.open('claimsClaimOpening', 'home', '', true);
    this.claimOpeningService.setSessionPolicies(sessionId, policies);
    this.claimOpeningService.setSessionOccurrenceDate(sessionId, occurrenceDate);

    this.claimOpeningService.setSessionTimeRequest(sessionId,
      this.createTimeRequest(occurrenceTime.split(':')[0],
        occurrenceTime.split(':')[1]));
    this.claimOpeningService.setCategory(sessionId, selectedCategory);
    if (plate) {
      this.claimOpeningService.setSessionPlate(sessionId, plate.toUpperCase());
    }
  }

  createTimeRequest(hour: number, minute: number): TimeRequest {
    const time = new TimeRequest();
    time.hour = hour;
    time.minute = minute;
    return time;
  }

}

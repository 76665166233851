import {Injectable} from '@angular/core';
import * as Pako from 'pako';
import {GzipProvider} from '../http-api';

@Injectable()
export class PakoZlibProviderService extends GzipProvider {

  constructor() {
    super();
  }

  gzip(data: Uint8Array | number[] | string): Uint8Array {
    return Pako.gzip(data);
  }

  unzip(data: Uint8Array | number[] | string): any {
    return Pako.ungzip(data);
  }

}

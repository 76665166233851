import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Parameter} from '../../../models/domain-models/parameter';
import {GenericEntity} from '../../../models/domain-models/generic-entity';
import {DatePipe} from '@angular/common';
import {Agreement} from '../../../models/domain-models/parameters/agreement';
import {Subscription} from 'rxjs';
import {ParameterService} from '../parameters.service';

@Component({
  selector: 'mic-parameter-control',
  templateUrl: './parameter-control.component.html',
  styleUrls: ['parameter-control.component.scss'],
  providers: [DatePipe]
})
export class ParameterControlComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean;
  @Input() parameter: Parameter;
  @Input() parameterControlForm: UntypedFormGroup;
  @Input() disabledFields;
  @Output() updateParameter: EventEmitter<string> = new EventEmitter();
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected parameterService: ParameterService,
    protected datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    if(this.parameter.disabled){
      this.parameterControlForm.controls[this.parameter.id].disable();
    }
    if (this.parameter.id === '8') {
      const instalmentDatesSubscription = this.parameterService.getInstalmentDates().subscribe(data => {
          const newDateValues = [];
          const instalmentDates = data;
          let sortedInstalmentDates = [];

          instalmentDates.forEach(date => {
              sortedInstalmentDates.push(date.date);
            }
          );

          sortedInstalmentDates = sortedInstalmentDates.sort();

          sortedInstalmentDates.forEach(date => {
              const formattedDate = this.datePipe.transform(new Date(date), 'dd/MM/yyyy');
              newDateValues.push(new GenericEntity(formattedDate, formattedDate, formattedDate));
            }
          );
          this.parameter.parameterValues = newDateValues;
        }
      );
      this.subscriptions.add(instalmentDatesSubscription);
    }

    if (this.parameter.id === '6') {
      const agreementValues = [];
      this.parameter.parameterValues.forEach((value: Agreement) => {
          agreementValues.push(new GenericEntity(String(value.idAgreement),
            value.code, value.description));
        }
      );
      this.parameter.parameterValues = agreementValues;
    }

    if (this.parameter.id === '5') {
      const paymentFrequencyValues = this.parameterService.getPaymentFrequencyValues();
      if (paymentFrequencyValues) {
        this.parameterService.setInstalmentDates(this.parameterControlForm.controls[this.parameter.id].value);
      }
    }

    if (this.parameter.type === 4) {
      if (this.parameter.value) {
        // force reset hh to 00 (12:00 bug)
        const tmpDate = new Date(parseInt(this.parameter.value, 10));
        tmpDate.setHours(0, 0, 0, 0);
        this.parameter.value = '' + tmpDate.getTime();
      }
      if (this.parameter.disabled) {
        this.parameterControlForm.controls[this.parameter.id].disable();
      }
      const parameterValueChangesSubscription = this.parameterControlForm.controls[this.parameter.id].valueChanges.subscribe(value => {
          try {
            const date: Date = value;
            const year = date.getFullYear();
            if (year.toString().length === 4) {
              if (this.parameter.value) {
                // force reset hh to 00 (12:00 bug)
                date.setHours(0, 0, 0, 0);
                this.parameter.value = '' + date.getTime();
              }
              this.onParameterValueChange();
            }
          } catch (e) {
          }
        }
      );
      this.subscriptions.add(parameterValueChangesSubscription);
    }
    if (this.disabledFields) {
      this.disabledFields.forEach(field => {
        if (field && field.id && field.id === this.parameter.id) {
          field.disabled = this.parameter.disabled;
        }
      });
    }
  }

  onParameterValueChange(parameter?) {

    let emetti = true;

    if (!!parameter && parameter.disabled) {
      return;
    }
    if (!this.parameterControlForm.controls[this.parameter.id].value
      && this.parameter.compulsory) {
      this.updateParameter.emit(null);
      return;
    }

    const value = this.parameterControlForm.get(this.parameter.id).value;

    if (this.parameter.type === 6 && value) {
      const regExp = new RegExp(/^(0[0-9]|1[0-9]|2[0-4]):[0-5][0-9]$/);
      if (!regExp.test(value)) {
        this.parameterControlForm.get(this.parameter.id).setErrors({invalidTime: true});
        return;
      } else {
        const hoursAndMinutes = value.split(':');
        if (hoursAndMinutes[0] === '24'
          && hoursAndMinutes[1] !== '00') {
          this.parameterControlForm.get(this.parameter.id).setValue('00:' + hoursAndMinutes[1]);
        }
      }
    }

    if (this.parameter.id === '5') {
      const paymentFrequencyValues = this.parameterService.getPaymentFrequencyValues();
      if (paymentFrequencyValues) {
        this.parameterService.setInstalmentDates(this.parameterControlForm.controls[this.parameter.id].value);
      }
    }

    if (this.parameter.type === 3) {
      if (!this.parameter.disabled) {
        if (this.parameter.value === 'true') {
          this.parameter.value = 'false';
          this.parameterControlForm.controls[this.parameter.id].setValue('false');
        } else if (this.parameter.value === 'false') {
          this.parameter.value = 'true';
          this.parameterControlForm.controls[this.parameter.id].setValue('true');
        }
      } else {
        emetti = false;
      }
    }
    if (emetti) {
      this.updateParameter.emit(this.parameter.id);
    }

  }

  compareGenericEntities(ent1: any, ent2: any): boolean {
    return ent1 && ent2 ? ent1.id === ent2.id : ent1 === ent2;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

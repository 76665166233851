import {AnagApiEntity} from '../anag-api/anag-subject-api';

export class AnagIdentificationEntity extends AnagApiEntity {
  public identification: string | null;

  constructor(code, description, id) {
    super(code, description);
    this.identification = id;
  }
}

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Fund, Profile} from '../../model/profile';
import {FormatterUtils} from '../../../invest-standalone-session/utils/FormatterUtils';

@Component({
  selector: 'lic-funds-step',
  templateUrl: './lic-funds-step.component.html',
  styleUrls: ['./lic-funds-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicFundsStepComponent implements OnChanges {

  @Input() hide: boolean;
  @Input() amount: number;
  @Input() profiles: Profile[];
  @Output() readyChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedProfilFundChanged: EventEmitter<Map<string, Fund[]>> = new EventEmitter<Map<string, Fund[]>>();
  @Input() notifyComplete: boolean;

  selectedProfilesFunds: Map<string, Fund[]> = new Map<string, Fund[]>();

  readyProfilesFunds: Map<string, boolean> = new Map<string, boolean>();

  constructor(public formatter: FormatterUtils) {
  }

  onFundChanged($event: Fund[], profile: Profile) {
    this.selectedProfilesFunds.set(profile.id, $event);
    this.selectedProfilFundChanged.emit(this.selectedProfilesFunds);
  }

  onReadyChanged($event: boolean, profile: Profile) {
    this.readyProfilesFunds.set(profile.id, $event);
    if (this.profiles.length !== this.readyProfilesFunds.size) { return false; }
    let allReady = true;
    this.readyProfilesFunds.forEach((k, v) => {
      if (!k) {
        allReady = false;
      }
    });

    this.readyChanged.emit(allReady);
  }

  filterOnlyWithPercent(funds: Fund[]) {
    return funds.filter(f => f.currency > 0);
  }

  getProfileName(profileId: string) {
    return this.profiles.find( p => p.id === profileId).name;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profiles) {
      const currentValue: Profile[] = changes.profiles.currentValue;
      currentValue.forEach(profile => {
        this.selectedProfilesFunds.set(profile.id, profile.funds);
      });
      this.selectedProfilFundChanged.emit(this.selectedProfilesFunds);

      console.log(`LicFundStep: ${this.notifyComplete}`);
      if (this.notifyComplete) {
        this.callOnReadyOnChanged();
      }
    }
  }

  private callOnReadyOnChanged() {
    this.readyChanged.emit(true);
  }
}

<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    <span translate>_GP_._TITLE_._SEL_ASSETS_CAT_</span>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="secondary" (click)="modalClose.emit()" data-qa="rgi-gp-btn-back" translate>
      _GP_._BTN_._UNDO_
    </button>
    <button rgi-rx-button color="primary" [disabled]="addAssetForm.invalid"
            (click)="onConfirm()" data-qa="rgi-gp-btn-confirm" translate>
      _GP_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>
  <ng-container [formGroup]="addAssetForm">
    <div class="rgi-gp-flex-row rgi-gp-spacing-xs">
      <div class="rgi-gp-field-container" *ngFor="let asset of availableAssets">
        <div class="rgi-gp-field">
          <rgi-rx-form-field>
            <button rgiRxSwitch formControlName="{{asset.code}}"
                    class="rgi-gp-btn-switch" [attr.data-qa]="asset.code">
              {{asset.description}}
            </button>
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
  </ng-container>
</rgi-rx-panel>

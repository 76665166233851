import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RgiRxUserService<USER = any> {

  private _user = new BehaviorSubject<USER | any | undefined>(undefined);

  constructor() {}


  getUser<T = any>(): T | USER {
    return this._user.getValue();
  }

  getUser$<T = any>(): Observable<T | USER> {
    return this._user.asObservable();
  }

  setUser<T = any>(user: T | USER): void {
    this._user.next(user);
  }

  isLoggedIn(): boolean {
    return !!this._user.getValue();
  }

  logout() {
    this._user.next(undefined);
  }


  set user(user: USER) {
    this._user.next(user);
  }

}

/**
 * @deprecated use RgiRxUserService
 * @see RgiRxUserService
 */
export class UserService extends RgiRxUserService<any>{}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OnModalClose} from "@rgi/rx/ui";
import {InstalmentPremiumGroup} from '../../../../models/domain-models/instalment-premium-group';

@Component({
  selector: 'lib-re-issue-fps-taxes-modal',
  templateUrl: './re-issue-fps-taxes-modal.component.html',
  styleUrls: ['./re-issue-fps-taxes-modal.component.css']
})
export class ReIssueFpsTaxesModalComponent implements OnInit, OnModalClose {

  PREMIUM_SUBSCRIPTION_INSTALMENT_DATA;
  PREMIUM_INSTALMENT_DATA;
  signDerogationDisplayedColumns = ['description', 'taxable', 'rate', 'taxes'];
  superHeader = ['sh1', 'sh2', 'sh3'];
  superFooter = ['x1', 'x2'];
  @Output() modalClose = new EventEmitter();
  @Input() premiumInstallmentGroup: InstalmentPremiumGroup;
  @Input() premiumSubscriptionInstalmentGroup: InstalmentPremiumGroup;
  lpsSignInstalmentAmount: any;
  lpsNextInstalmentAmount: any;

  ngOnInit() {
    this.PREMIUM_SUBSCRIPTION_INSTALMENT_DATA = this.premiumSubscriptionInstalmentGroup.lpsAdditionalFees;
    this.PREMIUM_INSTALMENT_DATA = this.premiumInstallmentGroup.lpsAdditionalFees;
    this.lpsSignInstalmentAmount = this.premiumSubscriptionInstalmentGroup.lpsSignInstalmentAmount;
    this.lpsNextInstalmentAmount = this.premiumInstallmentGroup.lpsNextInstalmentAmount;
  }


  close() {
    this.modalClose.emit();
  }

}

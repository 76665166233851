import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import {AUTH_EN} from './en';
import {AUTH_IT} from './it';
import {AUTH_ES} from './es';
import {AUTH_CA_ES} from "./ca-ES";
import {AUTH_EU_ES} from "./eu-ES";
import {AUTH_GL_ES} from "./gl-ES";

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(AUTH_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(AUTH_EN);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(AUTH_ES);
  });
}
export function loadCAES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(AUTH_CA_ES);
  });
}
export function loadEUES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(AUTH_EU_ES);
  });
}
export function loadGLES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(AUTH_GL_ES);
  });
}

export const AUTH_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadCAES,
    locale: 'ca-ES'
  },
  {
    load: loadGLES,
    locale: 'gl-ES'
  },
  {
    load: loadEUES,
    locale: 'eu-ES'
  }
];

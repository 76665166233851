<ng-container *ngIf="displayType === 'ROWS'; else keyValues">
  <div *ngFor="let row of details; index as i">
    <div class="rgishared-flex">
      <div *ngFor="let item of row; index as j">
        <span class="rgi-ui-text-5">
          {{(item?.label | translate) + ':'}}
        </span>
        <span class="rgi-ui-text-5 rgi-ui-title-3">
          <b>{{isDate(item.description) ? (item.description | date:'dd-MM-yyyy') : (item.description)}}</b>
        </span>
        <span *ngIf="j !== row.length -1">&nbsp;-&nbsp;</span>
      </div>
    </div>
    <hr class="rgishared-separator" *ngIf="i !== details.length -1 && showSeparatorLine">
  </div>
</ng-container>
<ng-template #keyValues>
  <div [class]="cssClass">
    <div *ngFor="let item of details; index as i"
         class="rgi-ui-col {{!showSeparatorLine ? 'rgi-ui-mt-xs rgi-ui-mb-xs' : ''}}">
      <span class="rgi-ui-text-5">
        {{(item?.label | translate) + ':'}}
      </span>
      <span class="rgi-ui-text-5 rgi-ui-title-3">
        <b>{{ isDate(item.description) ? (item.description | date:'dd-MM-yyyy') : (item.description | translate) }}</b>
      </span>
      <hr class="rgishared-separator" *ngIf="showSeparator(i)">
    </div>
  </div>
</ng-template>

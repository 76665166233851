<div class="box-detail" *ngIf="isApplicationsListVisible">
  <header class="box-detail-header" translate>_GP_._SUMMARY_._APPLICATIONS_LIST_</header>
  <div class="box-detail-body">
    <div class="rgi-gp-w-100">
      <rgi-gp-group-policy-applications-list
        [proposalNumber]="contractDetails.proposalNumber"
        [groupPolicyMode]="currentMode"
        [idParentSession]="idParentSession"
        >
      </rgi-gp-group-policy-applications-list>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Injectable(
  { providedIn: 'root' }
)
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class TranslationService {

  constructor(private translate: RgiRxTranslationService) { }

  public translateBoolean(value) {
    const msg = (value === 'true') ? 'Yes' : 'No';
    return this.translate.translate(msg);
  }

}

<div *ngIf="enabled">
    <div class="container-fluid table-white">
        <div *ngFor="let payment of potPayFE.paymentList; let num = index;" class="row">
            <div class="row" style="font-size: 16px; font-weight: bold; padding-top: 15px;">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    {{num+1}}.{{ '_CLAIMS_._PAYMENT_TYPE' | translate }}: {{payment.paymentTypeDescr}}</div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                {{ '_CLAIMS_._RECEIPT_TYPE' | translate }}: {{payment.receiptTypeDescr}}</div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <span style="float: right;">{{ '_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate }}:
                        €{{payment.totalAmountNet}}</span>
                </div>
            </div>
            <div style="padding-left: 15px; padding-right: 15px">
                <label class="text-info bold">{{ '_CLAIMS_._BENEFICIARIES' | translate }}</label>
                <div class="container-fluid table-white">
                    <div *ngFor="let beneficiary of payment.listBeneficiary" class="row centra">
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <input type="checkbox" class="form-control btn-checkbox checkbox-unit"
                                [(ngModel)]="beneficiary.primary" (change)="onCheckboxChange(payment, beneficiary)"
                                [disabled]="lockedConfMap.get(payment)" />
                            <label class="checkbox-label">
                                {{beneficiary.lastName | titlecase}} {{beneficiary.name | titlecase}}
                            </label>
                            <label *ngIf="beneficiary!=null && isAntiFinancialCrimes(beneficiary)" class="checkbox-label" ng-bind="label" style="color: #ffff; background-color: #ff0000">{{beneficiary?.statusAntiFinancialCrimesDescr | translate}}</label>
                          </div>
                        <!--               <div *ngIf="beneficiaryTypeMap" class="col-md-3 col-sm-3 col-xs-12">
                        Tipo beneficiario: {{beneficiaryTypeMap.get(beneficiary.typeBeneficiary)}}
                    </div> -->

                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <div *ngIf="beneficiary.typeBeneficiary">
                            {{ '_CLAIMS_._TYPE' | translate }}: {{beneficiaryTypeMap.get(beneficiary.typeBeneficiary)}}
                            </div>

                            <div *ngIf="!beneficiary.typeBeneficiary">
                                <select class="form-control" id="typeBeneficiary" name="typeBeneficiary"
                                    [(ngModel)]="beneficiary.typeBeneficiary" style="height: 30px;">
                                    <option></option>
                                    <option *ngFor="let beneficiaryType of payment.beneficiaryTypeList"
                                        value={{beneficiaryType.id}}>
                                        {{beneficiaryType.description}}
                                    </option>
                                </select>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12">
                        {{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' | translate }}: {{beneficiary.fiscalCode}}
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-2">
                            <input type="checkbox" class="form-control btn-checkbox checkbox-unit"
                                [checked]="isPayee(payment,beneficiary)"
                                (change)="onPercipientChange($event, payment, beneficiary)"
                                [disabled]="lockedConfMap.get(payment)" />
                            <label class="checkbox-label">
                            {{ '_CLAIMS_._PAYEE' | translate }}
                            </label>
                        </div>
                        <!-- <div *ngIf="beneficiary!=null && isAntiFinancialCrimes(beneficiary)" class="col-md-1 col-sm-1 col-xs-1 rgifont rgi-lock">
                        </div> -->
                        <div class="col-md-1 col-sm-1 col-xs-1">
                            <div class="pull-right">
                                <button type="button" class="btn-link" (click)="subjectModify(beneficiary, $event)">
                                    <span class="action-icon rgifont rgi-pencil"
                                        style="width:10px; height:10px;"></span>
                                </button>
                                <button type="button" class="btn-link" (click)="removeBeneficiary(payment, beneficiary)"
                                    [disabled]="lockedConfMap.get(payment)">
                                    <span class="rgifont rgi-trash"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="errorMessageBen" class="content-error validate-error">
                        <span class="rgifont rgi-exclamation-triangle"></span>
                        <span>{{errorMessageBen | translate}}</span>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="btn-group">
                                <button type="submit" class="btn btn-warning pull-right"
                                    (click)="addBeneficiary(payment)" [disabled]="lockedConfMap.get(payment)">
                                    {{ '_CLAIMS_._ADD_BENEFICIARY' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showPayeeMap.get(payment)">
                    <label class="text-info bold">{{ '_CLAIMS_._PAYEES' | translate }}</label>
                    <div class="container-fluid table-white">
                        <div *ngFor="let payee of payment.listPayee" class="row">
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <input type="checkbox" class="form-control btn-checkbox checkbox-unit"
                                    [(ngModel)]="payee.primary" [disabled]="lockedConfMap.get(payment)" />
                                <label class="checkbox-label">
                                    {{payee.lastName | titlecase}} {{payee.name | titlecase}}
                                </label>

                                <label *ngIf="payee!=null && isAntiFinancialCrimes(payee)" class="checkbox-label" ng-bind="label" style="color: #ffff; background-color: #ff0000">{{payee.statusAntiFinancialCrimesDescr || ''}}</label>

                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                {{ '_CLAIMS_._TYPE' | translate }}: {{beneficiaryTypeMap.get(payee.typeBeneficiary)}}
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12">
                                {{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' | translate }}: {{payee.fiscalCode}}
                            </div>
                            <!-- <div *ngIf="payee!=null && isAntiFinancialCrimes(payee)" class="col-md-1 col-sm-1 col-xs-1 rgifont rgi-lock">
                            </div> -->
                            <div class="col-md-1 col-sm-1 col-xs-1" style="float: right;">
                                <div class="pull-right">
                                    <button type="button" class="btn-link" (click)="subjectModify(payee, $event)">
                                        <span class="action-icon rgifont rgi-pencil"
                                            style="width:10px; height:10px;"></span>
                                    </button>
                                    <button type="button" class="btn-link" (click)="removePayee(payment, payee)"
                                        [disabled]="lockedConfMap.get(payment)">
                                        <span class="rgifont rgi-trash"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="errorMessagePayee" class="content-error validate-error">
                            <span class="rgifont rgi-exclamation-triangle"></span>
                            <span>{{errorMessagePayee | translate}}</span>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <button type="submit" class="btn btn-warning text-uppercase" (click)="addPayee(payment)"
                                    [disabled]="lockedConfMap.get(payment)" >
                                    {{ '_CLAIMS_._ADD_PAYEE' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div *ngIf="errorMessageConfirm" class="content-error validate-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{errorMessageConfirm | translate}}</span>
        </div>
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button class="btn btn-primary colorBGBlu2" (click)="onConfirm()" data-qa="party-data-panel-confirm-button"
                >{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!enabled">
    <div class="container-fluid table-white">
        <div *ngFor="let payment of potPayFE.paymentList; let num = index;" class="row">
            <div class="row" style="font-size: 16px; font-weight: bold; padding-top: 15px;">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    {{num+1}}.{{ '_CLAIMS_._PAYMENT_TYPE' | translate }}: {{payment.paymentTypeDescr}}</div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    {{ '_CLAIMS_._RECEIPT_TYPE' | translate }}: {{payment.receiptTypeDescr}}</div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <span style="float: right;">{{ '_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate }}:
                        €{{payment.totalAmountNet}}</span>
                </div>
            </div>
            <div style="padding-left: 15px; padding-right: 15px">
                <label class="bold">{{ '_CLAIMS_._BENEFICIARIES' | translate }}</label>
                <div class="container-fluid table-white">
                    <div *ngFor="let beneficiary of payment.listBeneficiary" class="row">
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            <label class="checkbox-label">
                                {{beneficiary.lastName | titlecase}} {{beneficiary.name | titlecase}}
                            </label>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            <div *ngIf="beneficiary.typeBeneficiary">
                            {{ '_CLAIMS_._TYPE' | translate }}: {{beneficiaryTypeMap.get(beneficiary.typeBeneficiary)}}
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' | translate }}: {{beneficiary.fiscalCode}}
                        </div>
                    </div>
                </div>
                <div *ngIf="showPayeeMap.get(payment)">
                    <label class="bold">{{ '_CLAIMS_._PAYEES' | translate }}</label>
                    <div class="container-fluid table-white">
                        <div *ngFor="let payee of payment.listPayee" class="row">
                            <div class="col-md-4 col-sm-4 col-xs-4">
                                <label class="checkbox-label">
                                    {{payee.lastName | titlecase}} {{payee.name | titlecase}}
                                </label>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-4">
                                {{ '_CLAIMS_._TYPE' | translate }}: {{beneficiaryTypeMap.get(payee.typeBeneficiary)}}
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-4">
                                {{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' | translate }}: {{payee.fiscalCode}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import {AnagEntityIta} from '../anag-api/anag-subject-api';

export class AnagPartyPayment {
  activationDateSDD: string;
  addressHolder: string;
  bankAddress: string;
  bankName: string;
  cancelDateSDD: string;
  cartaDiCreditoNumero: string;
  cartaDiCreditoScadenza: string;
  cartaDiCreditoTipo: AnagEntityIta;
  cartaDiCreditoTitolare: string;
  cash: string;
  ccABI: string;
  ccBic: string;
  ccCAB: string;
  ccCIN: string;
  ccCINIBAN: string;
  ccCodiceNazioneIBAN: string;
  ccIBAN: string;
  ccNumeroConto: string;
  checkNumber: string;
  codeSDD: string;
  dateNotification: string;
  extensionSubject: string;
  foreignAccount: string;
  foreignCurrency: string;
  holder: string;
  ibanHolder: string;
  objectId: string;
  paymentType: string;
  paymentTypeDescription: string;
  paymentTypeID: string;
  regulationDate: string;
  rum: string;
  stateSDD: string;
  transactionDescription: string;
  transactionNumber: string;
  uic: string;
}

<div *ngIf="stateManager.getState$() | async as st">
  <!-- SUBSTITUTION FORM -->
  <form [formGroup]="substitutionForm">
    <div class="form-group rgi-ui-grid-2">
      <!-- SUBSTITUTION REASON -->
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>RE_ISSUE.REASON</label>
          <select rgiRxNativeSelect formControlName="substReason" data-qa="substReason">
            <option value=""></option>
            <option *ngFor="let substReason of st.reasonList; index as i;"
                    [ngValue]="substReason.id">{{substReason?.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <!-- EFFECTIVE DATE -->
      <div class="rgi-ui-col">
        <rgi-rx-form-field style="display: contents">
          <div style="display: block">
            <label rgiRxLabel><span translate>RE_ISSUE.POLICY_EFFECT_DATE</span></label>
            <div style="display: flex">
              <input [rgiRxDateTimeInput]="effect"
                     style="flex: 1"
                     data-qa="effectiveDate"
                     formControlName="effect"
                     selectMode="single"
                     rangeSeparator="-"
                     placeholder="gg-mm-yyyy" #inputToTriggerEffectiveDate="rgiRxDateTimeInput">
              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerEffectiveDate"></button>
            </div>
          </div>
          <rgi-rx-date-time #effect
                            pickerType="calendar"
                            [hour12Timer]="true"
          ></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>
    </div>
    <div class="form-group rgi-ui-grid-2">
      <!-- PRODUCT -->
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>RE_ISSUE.PRODUCT</label>
          <select rgiRxNativeSelect formControlName="product" data-qa="product">
            <option value=""></option>
            <option *ngFor="let product of st.productList; index as i;"
                    [ngValue]="product.id">{{product?.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
    </div>
    <multiple-substitution *ngIf="policies" [policies]="policies" (selectedPolicy)="onMainPolicyChange($event)"></multiple-substitution>

    <!-- ERRORS -->
    <br>
    <div class="row">
      <rgi-rx-snackbar tag="{{TAG_ERRORS_SUBREASON}}"></rgi-rx-snackbar>
    </div>
    <div class="row">
      <rgi-rx-snackbar tag="{{TAG_ERRORS_PRODUCT}}"></rgi-rx-snackbar>
    </div>

    <!-- BUTTONS -->
    <div class="rgi-ui-grid-2 rgi-ui-grid-no-gutter">
      <div class="rgi-ui-col btn-group">
        <button rgi-rx-button [disabled]="!isMultipleSubstitutionEnabled" color="secondary" class="rgi-re-button"  (click)="openAddContractModal()"
                data-qa="addContract" translate>RE_ISSUE.ADD_CONTRACT</button>
      </div>
      <div class="rgi-ui-col btn-group">
        <button rgi-rx-button color="primary" type="submit" class="rgi-re-button" (click)="onSubmit()"
                [disabled]="
                    st.validationMsgProductList?.length > 0 ||
                    st.validationMsgReasonList?.length > 0 ||
                    !this.substitutionMainPolicy ||
                    !substitutionForm.valid"
                data-qa="confirm" translate>RE_ISSUE.CONFIRM</button>
      </div>
    </div>
  </form>

</div>

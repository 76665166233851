<rgi-rx-tab-group
  style="display: inline-block; width: 100%;   font-family: 'DINProRegular', Arial, sans-serif  !important;"
  [selectedIndex]="selectedIndexPotClaim">
  <!--  VISTA PER ATTORI-->
  <rgi-rx-tab id="customtab" style="flex-grow: 1 !important; font-family: 'DINProMedium' !important;" label="{{ '_CLAIMS_._VIEW_FOR_ACTORS' | translate }}"
              *ngIf="this.utilityServ.getSysPropShowParties()">
    <ng-template rgiRxTabContent>
      <div rgi-rx-accordion [multi]="true" style="margin: 10px" #multiAccordion=rgiRxAccordion>
        <!--INVOLVED + NOT INVOLVED PARTIES-->
        <rgi-rx-expansion-panel *ngFor="let party of parties">
          <rgi-rx-expansion-panel-header
            style="background-color: #ebeff3; color: #145f9e; font-size: 24px; font-family: 'DINProBold'">
            <!--DENOMINATION INVOLVED PARTY-->
            <!--party denomination-->

            <div class="col-xs-1">
              <div class="circle">
                      <span class="rgifont rgi-user"
                            style="color: white; scale: 1.3; position: relative; top:15px; left:16px"></span>
                <span *ngIf="getPartyDocumentCounter(party.identifier)" class="documents-counter"
                      style="position:relative; left:15px; top:1px; color: black">{{getPartyDocumentCounter(party.identifier)}}</span>
              </div>
            </div>

            <div class="col-xs-9" style="padding-left: 3vh">
              <div class="col-xs-12">
                <span *ngIf="!party.subject.surname">{{party.subject.designation}}</span>
                <span *ngIf="party.subject.surname">{{party.subject.givenName}} {{party.subject.surname}}</span>
              </div>
              <!--TODO : update in digital-claims-common-angular class ApiParty and related function jsonClaimPartyToApiClaimParty-->
              <div class="col-xs-12" style="font-weight: 400; font-size: 15px">
                {{party.role}}
              </div>
            </div>

            <!--download button-->
            <div class="col-xs-2">
                    <span *ngIf="getPartyDocumentCounter(party.identifier)"
                          (click)="performDownloadByParty(party.identifier)" rgiRxTooltip="Download"
                          class="rgifont rgi-download" style="scale:1.2"></span>
            </div>
          </rgi-rx-expansion-panel-header>
          <!-- accordion for categories-->
          <div rgi-rx-accordion [multi]="true">
            <rgi-rx-expansion-panel
              *ngFor="let category of getCategoriesFilterDeprecated(party.identifier)">
              <!--category header-->
              <rgi-rx-expansion-panel-header
                style="font-family: 'DINProRegular'; font-size: 18px; color:#145f9e; background-color: white"
              >
                      <span class="category-symbol">
                        <span class="rgifont rgi-folder-symbol"></span>
                        <span *ngIf="getPartyCategoryDocumentCounter(party.identifier, category )"
                              class="documents-counter" style="color:black">{{getPartyCategoryDocumentCounter(party.identifier, category)}}</span>
                      </span>
                <span style="margin-left: 2vh">{{category.elementDescription}} <span
                  style="color: darkred; font-size:15px;">{{category.isDeprecated ? '_CLAIMS_._CATEGORY_DEPRECATED' : ''  | translate }}</span>  </span>
                <span *ngIf="!category.isDeprecated" style="margin-left: 83%; margin-right: auto"
                      (click)="handleUpload(party, category, null)"
                      rgiRxTooltip="Upload" class="rgifont rgi-upload"
                      style="scale:1.2; margin-right: 0; margin-left: auto;color: #5accca;"></span>
              </rgi-rx-expansion-panel-header>

              <!--files if no subcat-->
              <div *ngIf="!category.subcategories.length">
                <!--documents-->
                <div rgi-rx-accordion [multi]="true">
                  <rgi-rx-expansion-panel
                    *ngFor="let doc of getPartyCategorySubcategoryDocuments(party.identifier,category , null)">
                    <rgi-rx-expansion-panel-header class="document-name"
                                                   style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold' !important">
                      <span style="padding:1vh" class="rgifont rgi-doc-file-format"></span>  {{doc.fileName}}

                      <span style="margin-right: 0; margin-left: auto;">

                              <span
                                style="font-weight: bold;padding: 1vh">
                                <span *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA">
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-success" *ngIf="doc.isValidated">{{ '_CLAIMS_._VALIDATED' | translate }}</span>
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-danger" *ngIf="!doc.isValidated">{{ '_CLAIMS_._NO_VALIDATE' | translate }}</span>
                                </span>

                 </span>
                        <!--download-->
                              <span style="padding-left: 1.5vh; padding-right: 1.5vh" (click)="performDownload(doc.id)"
                                    class="rgifont rgi-download"
                              ></span>
                        <!--view document-->
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh; scale:1.2"
                                  (click)="viewDocument(doc.id)"
                                  class="rgifont rgi-magnifier"></span>
                        <!--menu-->
                            <span [rgiRxMenuTrigger]="menu" [sourceEvents]="['click']"
                                  class="rgifont rgi-caret-down pull-right dropdown-toggle"
                                  style="background-color: whitesmoke; padding: 1vh">

                            </span>
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh">

                              <rgi-rx-menu #menu style="position: relative">
                                <!--where is LETTERMANAGFLOW boolean?-->
                                <!--                                (click)="onValidateDocument(doc)"-->
                                <div rgi-rx-menu-item [disabled]="doc.isValidated"

                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA  ">

                                     {{ '_CLAIMS_._VALIDATED' | translate }}
                                </div>
                                <!--                                (click)="onInvalidateDocument(doc)"-->
                                <div rgi-rx-menu-item [disabled]="!doc.isValidated"

                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA ">

                                     {{ '_CLAIMS_._INVALIDATE' | translate }}
                                </div>

                                  <div rgi-rx-menu-item (click)="handleOpenEditDocument(doc, party, category, null)">
                                    {{ '_CLAIMS_._MODIFY' | translate }}
                                </div>
                              </rgi-rx-menu>
                            </span>
                            </span>

                    </rgi-rx-expansion-panel-header>
                    <div class="panel-body">
                      <!--sottocategoria-->
                      <div class="document-detail document-detail-first col-xs-6"
                           *ngIf="category.subcategories && category.subcategories.length > 0">
                           {{ '_CLAIMS_._SUBCATEGORY' | translate }} : <span
                        style="font-weight: bold"> {{category.elementDescription}} </span>
                      </div>
                      <!--canale di ricezione-->
                      <div class="document-detail document-detail-first  col-xs-6">
                        {{ '_CLAIMS_._RECEPTION_CHANNEL' | translate }}
                      </div>
                      <!--formato-->
                      <div class="document-detail  col-xs-6">
                        {{ '_CLAIMS_._FORMAT' | translate }} :<span
                        style="font-weight: bold"> {{doc.fileType ? doc.fileType.toUpperCase() : ''}} </span>
                      </div>
                      <!--dimensione-->
                      <div class="document-detail col-xs-6">
                        {{ '_CLAIMS_._SIZE' | translate }}: <span style="font-weight: bold">{{doc.file.fileSize}} KB</span>
                      </div>
                      <!--numero pagine-->
                      <div class="document-detail  col-xs-6">
                        {{ '_CLAIMS_._NUMBER_OF_PAGES' | translate }}: <span style="font-weight: bold">{{doc.numPages}} </span>
                      </div>
                      <!--data inserimento-->
                      <div class="document-detail  col-xs-6">
                        {{ '_CLAIMS_._UPLOAD_DATE' | translate }}: <span style="font-weight: bold">{{doc.uploadDate}} </span>
                      </div>
                      <!--data ricezione-->
                      <div class="document-detail  col-xs-6">
                        {{ '_CLAIMS_._RECEIIPT_DATE' | translate }}: <span style="font-weight: bold">{{doc.receptionDate}} </span>
                      </div>
                      <!--utente-->
                      <div class="document-detail  col-xs-6">
                        {{ '_CLAIMS_._USER' | translate }}: <span style="font-weight: bold;"> {{doc.userName}} </span>
                      </div>
                      <!--note-->
                      <div class="document-detail  col-xs-6">
                         {{ '_CLAIMS_._NOTE' | translate }}: <span style="font-weight: bold">{{doc.uploadNote}} </span>
                      </div>
                      <!--sottotitolo-->
                      <div class="document-detail col-xs-6">
                        {{ '_CLAIMS_._SUBTITLE' | translate }}: <span style="font-weight: bold">{{doc.subtitle}} </span>
                      </div>
                      <!--chiave doc-->
                      <div class="document-detail  col-xs-6">
                         {{ '_CLAIMS_._DOC_KEY' | translate }}: <span style="font-weight: bold">{{doc.id}} </span>
                      </div>
                      <!--postalization result-->
                      <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                        {{ '_CLAIMS_._POSTALIZATION_RESULT' | translate }}: <span
                        style="font-weight: bold">{{doc.shippingOutcome}} </span>
                      </div>

                      <!--postalization Date-->
                      <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                        {{ '_CLAIMS_._POSTALIZATION_DATE' | translate }}: <span
                        style="font-weight: bold">{{doc.shippingDate}} </span>
                      </div>
                      <!--shipping type-->
                      <div class="document-detail  col-xs-6" *ngIf="doc.shippingChannel">
                        {{ '_CLAIMS_._SHIPPING_TYPE' | translate }}: <span style="font-weight: bold">{{doc.shippingChannel}} </span>
                      </div>
                    </div>
                  </rgi-rx-expansion-panel>
                </div>
              </div>

              <!--subcategories-->
              <div *ngIf="category.subcategories.length>0">
                <div rgi-rx-accordion [multi]="true">
                  <rgi-rx-expansion-panel style="border:none" *ngFor="let subcategory of category.subcategories">
                    <rgi-rx-expansion-panel-header

                      style="font-family: 'DINProRegular'; font-size: 18px; color:#145f9e; background-color: white">
                                           <span class="category-symbol">
                        <span class="rgifont rgi-folder-symbol" style="top:0"></span>
                        <span
                          *ngIf="getPartyCategorySubcategoryDocumentCounter(party.identifier, category, subcategory)"
                          class="documents-counter" style="color:black">{{getPartyCategorySubcategoryDocumentCounter(party.identifier, category, subcategory)}}</span>
                      </span>
                      <span style="margin-left: 2vh">{{subcategory.elementDescription}}  <span
                        style="color: darkred; font-size:15px;">{{subcategory.isDeprecated ? '_CLAIMS_._SUBCATEGORY_DEPRECATED' : ''  | translate }}</span></span>
                      <span *ngIf="!subcategory.isDeprecated && !category.isDeprecated"
                            style="margin-left: 83%; margin-right: auto"
                            (click)="handleUpload(party, category, subcategory)"
                            rgiRxTooltip="Upload" class="rgifont rgi-upload"
                            style="scale:1.2; margin-right: 0; margin-left: auto;color: #5accca"></span>
                    </rgi-rx-expansion-panel-header>
                    <!--documents-->
                    <div
                      *ngIf="getPartyCategorySubcategoryDocuments(party.identifier, category, subcategory).length">
                      <div rgi-rx-accordion [multi]="true">
                        <rgi-rx-expansion-panel
                          *ngFor="let doc of getPartyCategorySubcategoryDocuments(party.identifier, category, subcategory)">
                          <rgi-rx-expansion-panel-header
                            class="document-name">
                            <span style="padding:1vh" class="rgifont rgi-doc-file-format"></span>  {{doc.fileName}}
                            <span style="margin-right: 0; margin-left: auto;">

                              <span
                                style="font-weight: bold;padding: 1vh">
                                <span *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA">
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-success" *ngIf="doc.isValidated">{{ '_CLAIMS_._VALIDATED' | translate }}</span>
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-danger" *ngIf="!doc.isValidated">{{ '_CLAIMS_._NO_VALIDATE' | translate }}</span>
                                </span>

                 </span>
                              <!--download-->
                              <span style="padding-left: 1.5vh; padding-right: 1.5vh" (click)="performDownload(doc.id)"
                                    class="rgifont rgi-download"
                              ></span>
                              <!--view document-->
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh; scale:1.2"
                                  (click)="viewDocument(doc.id)"
                                  class="rgifont rgi-magnifier"></span>
                              <!--menu-->
                            <span [rgiRxMenuTrigger]="menu" [sourceEvents]="['click']"
                                  class="rgifont rgi-caret-down pull-right dropdown-toggle"
                                  style="background-color: whitesmoke; padding: 1vh">

                            </span>
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh">

                              <rgi-rx-menu #menu style="position: relative">
                                <!--where is LETTERMANAGFLOW boolean?-->
                                <div rgi-rx-menu-item [disabled]="doc.isValidated"
                                     (click)="onValidateDocument(doc)"
                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA  ">

                                   {{ '_CLAIMS_._VALIDATED' | translate }}
                                </div>

                                <div rgi-rx-menu-item [disabled]="!doc.isValidated"
                                     (click)="onInvalidateDocument(doc)"
                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA ">

                                     {{ '_CLAIMS_._INVALIDATE' | translate }}
                                </div>

                                  <div rgi-rx-menu-item
                                       (click)="handleOpenEditDocument(doc, party, category, subcategory)">
                                    {{ '_CLAIMS_._MODIFY' | translate }}
                                </div>
                              </rgi-rx-menu>
                            </span>
                            </span>
                          </rgi-rx-expansion-panel-header>

                          <div class="panel-body">
                            <!--sottocategoria-->
                            <div class="document-detail document-detail-first col-xs-6"
                                 *ngIf="category.subcategories && category.subcategories.length > 0">
                             {{ '_CLAIMS_._SUBCATEGORY' | translate }}: <span
                              style="font-weight: bold"> {{category.elementDescription}} </span>
                            </div>
                            <!--canale di ricezione-->
                            <div class="document-detail document-detail-first  col-xs-6">
                             {{ '_CLAIMS_._RECEPTION_CHANNEL' | translate }}:
                            </div>
                            <!--formato-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._FORMAT' | translate }}:<span
                              style="font-weight: bold"> {{doc.fileType ? doc.fileType.toUpperCase() : ''}} </span>
                            </div>
                            <!--dimensione-->
                            <div class="document-detail col-xs-6">
                             {{ '_CLAIMS_._SIZE' | translate }}: <span style="font-weight: bold">{{doc.file.fileSize}} KB</span>
                            </div>
                            <!--numero pagine-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._NUMBER_OF_PAGES' | translate }}: <span style="font-weight: bold">{{doc.numPages}} </span>
                            </div>
                            <!--data inserimento-->
                            <div class="document-detail  col-xs-6">
                              {{ '_CLAIMS_._UPLOAD_DATE' | translate }}: <span style="font-weight: bold">{{doc.uploadDate}} </span>
                            </div>
                            <!--utente-->
                            <div class="document-detail  col-xs-6">
                              {{ '_CLAIMS_._USER' | translate }}: <span style="font-weight: bold;"> {{doc.userName}} </span>
                            </div>
                            <!--note-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._NOTE' | translate }}: <span style="font-weight: bold">{{doc.uploadNote}} </span>
                            </div>
                            <!--sottotitolo-->
                            <div class="document-detail col-xs-6">
                              {{ '_CLAIMS_._SUBTITLE' | translate }}: <span style="font-weight: bold">{{doc.subtitle}} </span>
                            </div>
                            <!--chiave doc-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._DOC_KEY' | translate }}: <span style="font-weight: bold">{{doc.id}} </span>
                            </div>
                            <!--postalization result-->
                            <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                              {{ '_CLAIMS_._POSTALIZATION_RESULT' | translate }}: <span
                              style="font-weight: bold">{{doc.shippingOutcome}} </span>
                            </div>

                            <!--postalization Date-->
                            <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                              {{ '_CLAIMS_._POSTALIZATION_DATE' | translate }}: <span
                              style="font-weight: bold">{{doc.shippingDate}} </span>
                            </div>
                            <!--shipping type-->
                            <div class="document-detail  col-xs-6" *ngIf="doc.shippingChannel">
                              {{ '_CLAIMS_._SHIPPING_TYPE' | translate }}: <span style="font-weight: bold">{{doc.shippingChannel}} </span>
                            </div>
                          </div>
                        </rgi-rx-expansion-panel>
                      </div>
                    </div>
                  </rgi-rx-expansion-panel>
                </div>
                <!--close accordion subcat-->
              </div>

            </rgi-rx-expansion-panel>
            <!--close accordion category-->
          </div>
        </rgi-rx-expansion-panel>
      </div>
    </ng-template>
  </rgi-rx-tab>

  <!--VISTA PER CATEGORIE    label="Vista per Categorie"-->
  <rgi-rx-tab id="customtab2" style="flex-grow: 1 !important;  font-family: 'DINProMedium' !important" >
    <ng-template rgiRxTabLabel>
      <div style="font-family: 'DINProMedium' !important;">{{ '_CLAIMS_._VIEW_BY_CATEGORIES' | translate }}</div>
    </ng-template>
    <ng-template rgiRxTabContent>
      <div rgi-rx-accordion [multi]="true">
        <rgi-rx-expansion-panel *ngFor="let category of getCategoriesFilterDeprecatedNoParty()">
          <!--category header-->
          <rgi-rx-expansion-panel-header
            style="font-family: 'DINProRegular' !important; font-size: 18px; color:#145f9e; background-color: white">
                      <span class="category-symbol">
                        <span class="rgifont rgi-folder-symbol"></span>
                        <span *ngIf="getCategoryDocumentCounter(category )"
                              class="documents-counter" style="color:black">{{getCategoryDocumentCounter(category)}}</span>
                      </span>
            <span style="margin-left: 2vh">{{category.elementDescription}} <span
              style="color: darkred; font-size:15px;">{{category.isDeprecated ? '_CLAIMS_._CATEGORY_DEPRECATED' : ''  | translate }}</span></span>
          <!--   <span *ngIf="!category.isDeprecated" style="scale:1.2; margin-right: 0; margin-left: auto;color: #5accca;"
                  (click)="handleUpload(null, category, null)"
                  rgiRxTooltip="Upload" class="rgifont rgi-upload"
                  style="scale:1.2; margin-right: 0; margin-left: auto;color: #5accca;"></span> -->
          </rgi-rx-expansion-panel-header>

          <!--ACTORS PRESENT IN SYSTEM PROPERTY-->
          <div *ngIf="this.utilityServ.getSysPropShowParties()">
            <!--NO SUBCATEGORIES-->
            <!--show actors even if no documents, to enable upload -->
            <!--&& getCategoryDocumentCounter(category.elementId)-->
            <div *ngIf="!category.subcategories.length"
                 rgi-rx-accordion [multi]="true">
              <!--show actor sub-accordion depending on system properties-->

              <rgi-rx-expansion-panel *ngFor="let party of parties">

                <rgi-rx-expansion-panel-header
                  style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold'">
                  <!--DENOMINATION INVOLVED PARTY-->
                  <!--party denomination-->
                  <div class="col-xs-1">
                    <div class="circle" style="scale: 0.6">
                            <span class="rgifont rgi-user"
                                  style="color: white; scale: 2.1; position: relative; top:35px; left:19px"></span>
                      <span *ngIf="getPartyCategoryDocumentCounter(party.identifier, category)"
                            class="documents-counter"
                            style="position:relative; left:15px; top:1px; color: black;">{{getPartyCategoryDocumentCounter(party.identifier, category)}}</span>
                    </div>
                  </div>

                  <div class="col-xs-9" style="padding-left: 3vh">
                    <div class="col-xs-12">
                      <span *ngIf="!party.subject.surname">{{party.subject.designation}}</span>
                      <span *ngIf="party.subject.surname">{{party.subject.givenName}} {{party.subject.surname}}</span>
                    </div>
                    <!--TODO : update in digital-claims-common-angular class ApiParty and related function jsonClaimPartyToApiClaimParty-->
                    <div class="col-xs-12" style="font-weight: 400; font-size: 15px">
                      {{party.role}}
                    </div>
                  </div>
                  <!--upload button-->
                  <div class="col-xs-1">
                          <span rgiRxTooltip="Upload" *ngIf="!category.isDeprecated"
                                (click)="handleUpload(party, category, null)"
                                class="rgifont rgi-upload" style="scale:1.2; color: #5accca"></span>
                  </div>
                  <!--download button-->
                  <div class="col-xs-1">
                          <span rgiRxTooltip="Download"
                                *ngIf="getPartyCategoryDocumentCounter(party.identifier, category)"
                                (click)="performDownloadByPartyCategory(party.identifier, category)"
                                class="rgifont rgi-download" style="scale:1.2"></span>
                  </div>
                </rgi-rx-expansion-panel-header>

                <!--documents-->
                <div rgi-rx-accordion [multi]="true">
                  <rgi-rx-expansion-panel
                    *ngFor="let doc of getPartyCategorySubcategoryDocuments(party.identifier, category, null)">
                    <rgi-rx-expansion-panel-header
                      style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold'"
                      class="document-name">
                      <span style="padding:1vh" class="rgifont rgi-doc-file-format"></span>  {{doc.fileName}}
                      <span style="margin-right: 0; margin-left: auto;">

                              <span
                                style="font-weight: bold;padding: 1vh">
                                <span *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA">
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-success" *ngIf="doc.isValidated">{{ '_CLAIMS_._VALIDATED' | translate }}</span>
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-danger" *ngIf="!doc.isValidated">{{ '_CLAIMS_._NO_VALIDATE' | translate }}</span>
                                </span>

                 </span>
                        <!--download-->
                              <span style="padding-left: 1.5vh; padding-right: 1.5vh" (click)="performDownload(doc.id)"
                                    class="rgifont rgi-download"
                              ></span>
                        <!--view document-->
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh; scale:1.2"
                                  (click)="viewDocument(doc.id)"
                                  class="rgifont rgi-magnifier"></span>
                        <!--menu-->
                            <span [rgiRxMenuTrigger]="menu" [sourceEvents]="['click']"
                                  class="rgifont rgi-caret-down pull-right dropdown-toggle"
                                  style="background-color: whitesmoke; padding: 1vh">

                            </span>
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh">

                              <rgi-rx-menu #menu style="position: relative">
                                <!--where is LETTERMANAGFLOW boolean?-->
                                <!--                                <div rgi-rx-menu-item [disabled]="doc.isValidated"-->
                                <!--                                     (click)="onValidateDocument(doc)"-->
                                <!--                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA  ">-->

                                <!--                                  {{'Validate' | translate}}-->
                                <!--                                </div>-->

                                <!--                                <div rgi-rx-menu-item [disabled]="!doc.isValidated"-->
                                <!--                                     (click)="onInvalidateDocument(doc)"-->
                                <!--                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA ">-->

                                <!--                                    {{'Invalidate' | translate}}-->
                                <!--                                </div>-->

                                  <div rgi-rx-menu-item (click)="handleOpenEditDocument(doc, party, category, null)">
                                    {{ '_CLAIMS_._MODIFY' | translate }}
                                </div>
                              </rgi-rx-menu>
                            </span>
                            </span>
                    </rgi-rx-expansion-panel-header>
                    <div class="panel-body">
                      <!--sottocategoria-->
                      <div class="document-detail document-detail-first col-xs-6"
                           *ngIf="category.subcategories && category.subcategories.length > 0">
                       {{ '_CLAIMS_._SUBCATEGORY' | translate }}: <span
                        style="font-weight: bold"> {{category.elementDescription}} </span>
                      </div>
                      <!--canale di ricezione-->
                      <div class="document-detail document-detail-first  col-xs-6">
                       {{ '_CLAIMS_._RECEPTION_CHANNEL' | translate }}:
                      </div>
                      <!--formato-->
                      <div class="document-detail  col-xs-6">
                         {{ '_CLAIMS_._FORMAT' | translate }}:<span
                        style="font-weight: bold"> {{doc.fileType ? doc.fileType.toUpperCase() : ''}} </span>
                      </div>
                      <!--dimensione-->
                      <div class="document-detail col-xs-6">
                       {{ '_CLAIMS_._SIZE' | translate }}: <span style="font-weight: bold">{{doc.file.fileSize}} KB</span>
                      </div>
                      <!--numero pagine-->
                      <div class="document-detail  col-xs-6">
                         {{ '_CLAIMS_._NUMBER_OF_PAGES' | translate }}: <span style="font-weight: bold">{{doc.numPages}} </span>
                      </div>
                      <!--data inserimento-->
                      <div class="document-detail  col-xs-6">
                        {{ '_CLAIMS_._UPLOAD_DATE' | translate }}: <span style="font-weight: bold">{{doc.uploadDate}} </span>
                      </div>
                      <!--utente-->
                      <div class="document-detail  col-xs-6">
                        {{ '_CLAIMS_._USER' | translate }}: <span style="font-weight: bold;"> {{doc.userName}} </span>
                      </div>
                      <!--note-->
                      <div class="document-detail  col-xs-6">
                         {{ '_CLAIMS_._NOTE' | translate }}: <span style="font-weight: bold">{{doc.uploadNote}} </span>
                      </div>
                      <!--sottotitolo-->
                      <div class="document-detail col-xs-6">
                        {{ '_CLAIMS_._SUBTITLE' | translate }}: <span style="font-weight: bold">{{doc.subtitle}} </span>
                      </div>
                      <!--chiave doc-->
                      <div class="document-detail  col-xs-6">
                         {{ '_CLAIMS_._DOC_KEY' | translate }}: <span style="font-weight: bold">{{doc.id}} </span>
                      </div>
                      <!--postalization result-->
                      <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                        {{ '_CLAIMS_._POSTALIZATION_RESULT' | translate }}: <span
                        style="font-weight: bold">{{doc.shippingOutcome}} </span>
                      </div>

                      <!--postalization Date-->
                      <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                        {{ '_CLAIMS_._POSTALIZATION_DATE' | translate }}: <span
                        style="font-weight: bold">{{doc.shippingDate}} </span>
                      </div>
                      <!--shipping type-->
                      <div class="document-detail  col-xs-6" *ngIf="doc.shippingChannel">
                        {{ '_CLAIMS_._SHIPPING_TYPE' | translate }}: <span style="font-weight: bold">{{doc.shippingChannel}} </span>
                      </div>
                    </div>
                  </rgi-rx-expansion-panel>
                </div>
              </rgi-rx-expansion-panel>
            </div>
            <!--YES SUBCATEGORIES-->
            <!-- && getCategoryDocumentCounter(category.elementId)-->
            <div *ngIf="category.subcategories.length"
                 rgi-rx-accordion [multi]="true">
              <div rgi-rx-accordion [multi]="true">
                <rgi-rx-expansion-panel
                  *ngFor="let subcategory of category.subcategories">
                  <rgi-rx-expansion-panel-header

                    style="font-family: 'DINProRegular'; font-size: 18px; color:#145f9e; background-color: white">
                      <span class="category-symbol">
                        <span class="rgifont rgi-folder-symbol"></span>
                        <span *ngIf="getCategorySubcategoryDocumentCounter(category, subcategory )"
                              class="documents-counter" style="color:black">{{getCategorySubcategoryDocumentCounter(category, subcategory)}}</span>
                      </span>
                    <span style="margin-left: 2vh">{{subcategory.elementDescription}} <span
                      style="color: darkred; font-size:15px;">{{subcategory.isDeprecated ? '_CLAIMS_._SUBCATEGORY_DEPRECATED' : ''  | translate }}</span></span>
                  </rgi-rx-expansion-panel-header>
                  <!--show actors even if no documents, to allow upload-->
                  <!--*ngIf="getCategorySubcategoryDocumentCounter(category.elementId, subcategory.elementId)"-->
                  <div rgi-rx-accordion [multi]="true"
                  >
                    <rgi-rx-expansion-panel *ngFor="let party of parties">
                      <rgi-rx-expansion-panel-header
                        style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold'">
                        <!--DENOMINATION INVOLVED PARTY-->
                        <!--party denomination-->
                        <div class="col-xs-1">
                          <div class="circle" style="scale: 0.7">
                                  <span class="rgifont rgi-user"
                                        style="color: white; scale: 2.1; position: relative; top:35px; left:19px"></span>
                            <span
                              *ngIf="getPartyCategorySubcategoryDocumentCounter(party.identifier, category, subcategory)"
                              class="documents-counter"
                              style="position:relative; left:15px; top:1px; color: black;">{{getPartyCategorySubcategoryDocumentCounter(party.identifier, category, subcategory)}}</span>
                          </div>
                        </div>

                        <div class="col-xs-9" style="padding-left: 3vh">
                          <div class="col-xs-12">
                            <span *ngIf="!party.subject.surname">{{party.subject.designation}}</span>
                            <span
                              *ngIf="party.subject.surname">{{party.subject.givenName}} {{party.subject.surname}}</span>
                          </div>
                          <!--TODO : update in digital-claims-common-angular class ApiParty and related function jsonClaimPartyToApiClaimParty-->
                          <div class="col-xs-12" style="font-weight: 400; font-size: 15px">
                            {{party.role}}
                          </div>
                        </div>

                        <!--upload button-->
                        <div class="col-xs-1" *ngIf="!subcategory.isDeprecated && !category.isDeprecated">
                          <span rgiRxTooltip="Upload" (click)="handleUpload(party, category, subcategory)"
                                class="rgifont rgi-upload" style="scale:1.2; color: #5accca"></span>
                        </div>
                        <!--download button-->
                        <div class="col-xs-1"
                             *ngIf="getPartyCategorySubcategoryDocumentCounter(party.identifier, category, subcategory)">
                          <span rgiRxTooltip="Download"
                                (click)="performDownloadByPartyCategorySubcategory(party.identifier, category, subcategory)"
                                class="rgifont rgi-download" style="scale:1.2"></span>
                        </div>
                      </rgi-rx-expansion-panel-header>

                      <!--documents-->
                      <div rgi-rx-accordion [multi]="true">
                        <rgi-rx-expansion-panel
                          *ngFor="let doc of getPartyCategorySubcategoryDocuments(party.identifier, category, subcategory)">
                          <rgi-rx-expansion-panel-header
                            style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold'"
                            class="document-name">
                            <span style="padding:1vh" class="rgifont rgi-doc-file-format"></span>  {{doc.fileName}}
                            <span style="margin-right: 0; margin-left: auto;">

                              <span
                                style="font-weight: bold;padding: 1vh">
                                <span *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA">
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-success" *ngIf="doc.isValidated">{{ '_CLAIMS_._VALIDATED' | translate }}</span>
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-danger" *ngIf="!doc.isValidated">{{ '_CLAIMS_._NO_VALIDATE' | translate }}</span>
                                </span>

                 </span>
                              <!--download-->
                              <span style="padding-left: 1.5vh; padding-right: 1.5vh" (click)="performDownload(doc.id)"
                                    class="rgifont rgi-download"
                              ></span>
                              <!--view document-->
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh; scale:1.2"
                                  (click)="viewDocument(doc.id)"
                                  class="rgifont rgi-magnifier"></span>
                              <!--menu-->
                            <span [rgiRxMenuTrigger]="menu" [sourceEvents]="['click']"
                                  class="rgifont rgi-caret-down pull-right dropdown-toggle"
                                  style="background-color: whitesmoke; padding: 1vh">

                            </span>
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh">

                              <rgi-rx-menu #menu style="position: relative">
                                <!--where is LETTERMANAGFLOW boolean?-->
                                <!--                                <div rgi-rx-menu-item [disabled]="doc.isValidated"-->
                                <!--                                     (click)="onValidateDocument(doc)"-->
                                <!--                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA  ">-->

                                <!--                                  {{'Validate' | translate}}-->
                                <!--                                </div>-->

                                <!--                                <div rgi-rx-menu-item [disabled]="!doc.isValidated"-->
                                <!--                                     (click)="onInvalidateDocument(doc)"-->
                                <!--                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA ">-->

                                <!--                                    {{'Invalidate' | translate}}-->
                                <!--                                </div>-->

                                  <div rgi-rx-menu-item
                                       (click)="handleOpenEditDocument(doc, party, category, subcategory)">
                                    {{ '_CLAIMS_._MODIFY' | translate }}
                                </div>
                              </rgi-rx-menu>
                            </span>
                            </span>
                          </rgi-rx-expansion-panel-header>
                          <div class="panel-body">
                            <!--sottocategoria-->
                            <div class="document-detail document-detail-first col-xs-6"
                                 *ngIf="category.subcategories && category.subcategories.length > 0">
                             {{ '_CLAIMS_._SUBCATEGORY' | translate }}: <span
                              style="font-weight: bold"> {{category.elementDescription}} </span>
                            </div>
                            <!--canale di ricezione-->
                            <div class="document-detail document-detail-first  col-xs-6">
                             {{ '_CLAIMS_._RECEPTION_CHANNEL' | translate }}:
                            </div>
                            <!--formato-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._FORMAT' | translate }}:<span
                              style="font-weight: bold"> {{doc.fileType ? doc.fileType.toUpperCase() : ''}} </span>
                            </div>
                            <!--dimensione-->
                            <div class="document-detail col-xs-6">
                             {{ '_CLAIMS_._SIZE' | translate }}: <span style="font-weight: bold">{{doc.file.fileSize}} KB</span>
                            </div>
                            <!--numero pagine-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._NUMBER_OF_PAGES' | translate }}: <span style="font-weight: bold">{{doc.numPages}} </span>
                            </div>
                            <!--data inserimento-->
                            <div class="document-detail  col-xs-6">
                              {{ '_CLAIMS_._UPLOAD_DATE' | translate }}: <span style="font-weight: bold">{{doc.uploadDate}} </span>
                            </div>
                            <!--utente-->
                            <div class="document-detail  col-xs-6">
                              {{ '_CLAIMS_._USER' | translate }}: <span style="font-weight: bold;"> {{doc.userName}} </span>
                            </div>
                            <!--note-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._NOTE' | translate }}: <span style="font-weight: bold">{{doc.uploadNote}} </span>
                            </div>
                            <!--sottotitolo-->
                            <div class="document-detail col-xs-6">
                              {{ '_CLAIMS_._SUBTITLE' | translate }}: <span style="font-weight: bold">{{doc.subtitle}} </span>
                            </div>
                            <!--chiave doc-->
                            <div class="document-detail  col-xs-6">
                               {{ '_CLAIMS_._DOC_KEY' | translate }}: <span style="font-weight: bold">{{doc.id}} </span>
                            </div>
                            <!--postalization result-->
                            <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                              {{ '_CLAIMS_._POSTALIZATION_RESULT' | translate }}: <span
                              style="font-weight: bold">{{doc.shippingOutcome}} </span>
                            </div>

                            <!--postalization Date-->
                            <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                              {{ '_CLAIMS_._POSTALIZATION_DATE' | translate }}: <span
                              style="font-weight: bold">{{doc.shippingDate}} </span>
                            </div>
                            <!--shipping type-->
                            <div class="document-detail  col-xs-6" *ngIf="doc.shippingChannel">
                              {{ '_CLAIMS_._SHIPPING_TYPE' | translate }}: <span style="font-weight: bold">{{doc.shippingChannel}} </span>
                            </div>
                          </div>

                        </rgi-rx-expansion-panel>
                      </div>


                    </rgi-rx-expansion-panel>
                  </div>
                </rgi-rx-expansion-panel>
              </div>

            </div>
          </div>
          <!--END PARTIES INCLUDED DIV-->


          <!--OTHER CONFIGURATION, NO ACTORS-->
          <!--there must be another way to do this more elegantly and not re-use code but for the time being ill go with this-->
          <div *ngIf="!this.utilityServ.getSysPropShowParties()">
            <!--NO SUBCATEGORIES / NO ACTORS-->
            <div *ngIf="!category.subcategories.length" rgi-rx-accordion [multi]="true">
              <rgi-rx-expansion-panel *ngFor="let doc of getCategoryDocuments(category.elementId)">
                <rgi-rx-expansion-panel-header
                  style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold'"
                  class="document-name">
                  <span style="padding:1vh" class="rgifont rgi-doc-file-format"></span>  {{doc.fileName}}
                  <span style="margin-right: 0; margin-left: auto;">

                              <span
                                style="font-weight: bold;padding: 1vh">
                                <span *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA">
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-success" *ngIf="doc.isValidated">{{ '_CLAIMS_._VALIDATED' | translate }}</span>
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-danger" *ngIf="!doc.isValidated">{{ '_CLAIMS_._NO_VALIDATE' | translate }}</span>
                                </span>

                 </span>
                    <!--download-->
                              <span style="padding-left: 1.5vh; padding-right: 1.5vh" (click)="performDownload(doc.id)"
                                    class="rgifont rgi-download"
                              ></span>
                    <!--view document-->
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh; scale:1.2"
                                  (click)="viewDocument(doc.id)"
                                  class="rgifont rgi-magnifier"></span>
                    <!--menu-->
                            <span [rgiRxMenuTrigger]="menu" [sourceEvents]="['click']"
                                  class="rgifont rgi-caret-down pull-right dropdown-toggle"
                                  style="background-color: whitesmoke; padding: 1vh">

                            </span>
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh">

                              <rgi-rx-menu #menu style="position: relative">
                                <!--where is LETTERMANAGFLOW boolean?-->
                                <!--                                <div rgi-rx-menu-item [disabled]="doc.isValidated"-->
                                <!--                                     (click)="onValidateDocument(doc)"-->
                                <!--                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA  ">-->

                                <!--                                  {{'Validate' | translate}}-->
                                <!--                                </div>-->

                                <!--                                <div rgi-rx-menu-item [disabled]="!doc.isValidated"-->
                                <!--                                     (click)="onInvalidateDocument(doc)"-->
                                <!--                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA ">-->

                                <!--                                    {{'Invalidate' | translate}}-->
                                <!--                                </div>-->

                                  <div rgi-rx-menu-item (click)="handleOpenEditDocument(doc,null, category, null)">
                                   {{ '_CLAIMS_._MODIFY' | translate }}
                                </div>
                              </rgi-rx-menu>
                            </span>
                            </span>
                </rgi-rx-expansion-panel-header>
                <div class="panel-body">
                  <!--party involved-->
                  <div class=" document-detail document-detail-first  cols-xs-6"
                       *ngIf="this.utilityServ.getSysPropShowParties()">
                    Attore: <span
                    style="font-weight: bold"> {{doc.nameAnagrafe ? doc.nameAnagrafe.toUpperCase() : ''}} </span>
                  </div>

                    <!--sottocategoria-->
                    <div class="document-detail document-detail-first col-xs-6"
                         *ngIf="category.subcategories && category.subcategories.length > 0">
                     {{ '_CLAIMS_._SUBCATEGORY' | translate }}: <span
                      style="font-weight: bold"> {{category.elementDescription}} </span>
                    </div>
                    <!--canale di ricezione-->
                    <div class="document-detail document-detail-first  col-xs-6">
                     {{ '_CLAIMS_._RECEPTION_CHANNEL' | translate }}:
                    </div>
                    <!--formato-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._FORMAT' | translate }}:<span
                      style="font-weight: bold"> {{doc.fileType ? doc.fileType.toUpperCase() : ''}} </span>
                    </div>
                    <!--dimensione-->
                    <div class="document-detail col-xs-6">
                     {{ '_CLAIMS_._SIZE' | translate }}: <span style="font-weight: bold">{{doc.file.fileSize}} KB</span>
                    </div>
                    <!--numero pagine-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._NUMBER_OF_PAGES' | translate }}: <span style="font-weight: bold">{{doc.numPages}} </span>
                    </div>
                    <!--data inserimento-->
                    <div class="document-detail  col-xs-6">
                      {{ '_CLAIMS_._UPLOAD_DATE' | translate }}: <span style="font-weight: bold">{{doc.uploadDate}} </span>
                    </div>
                  <!--data ricezione-->
                  <div class="document-detail  col-xs-6">
                    {{ '_CLAIMS_._RECEIPT_DATE' | translate }}: <span style="font-weight: bold">{{doc.receptionDate}} </span>
                  </div>
                    <!--utente-->
                    <div class="document-detail  col-xs-6">
                      {{ '_CLAIMS_._USER' | translate }}: <span style="font-weight: bold;"> {{doc.userName}} </span>
                    </div>
                    <!--note-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._NOTE' | translate }}: <span style="font-weight: bold">{{doc.uploadNote}} </span>
                    </div>
                    <!--sottotitolo-->
                    <div class="document-detail col-xs-6">
                      {{ '_CLAIMS_._SUBTITLE' | translate }}: <span style="font-weight: bold">{{doc.subtitle}} </span>
                    </div>
                    <!--chiave doc-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._DOC_KEY' | translate }}: <span style="font-weight: bold">{{doc.id}} </span>
                    </div>
                    <!--postalization result-->
                    <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                      {{ '_CLAIMS_._POSTALIZATION_RESULT' | translate }}: <span
                      style="font-weight: bold">{{doc.shippingOutcome}} </span>
                    </div>

                    <!--postalization Date-->
                    <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                      {{ '_CLAIMS_._POSTALIZATION_DATE' | translate }}: <span
                      style="font-weight: bold">{{doc.shippingDate}} </span>
                    </div>
                    <!--shipping type-->
                    <div class="document-detail  col-xs-6" *ngIf="doc.shippingChannel">
                      {{ '_CLAIMS_._SHIPPING_TYPE' | translate }}: <span style="font-weight: bold">{{doc.shippingChannel}} </span>
                    </div>

                </div>
              </rgi-rx-expansion-panel>
            </div>
            <!--YES SUBCATEGORIES / NO ACTORS-->
            <div *ngIf="category.subcategories.length" rgi-rx-accordion [multi]="true">
              <rgi-rx-expansion-panel

                *ngFor="let subcategory of category.subcategories">
                <rgi-rx-expansion-panel-header

                  style="font-family: 'DINProRegular'; font-size: 18px; color:#145f9e; background-color: white">
                      <span class="category-symbol">
                        <span class="rgifont rgi-folder-symbol"></span>
                        <span *ngIf="getCategorySubcategoryDocumentCounter(category, subcategory )"
                              class="documents-counter"style="color:black">{{getCategorySubcategoryDocumentCounter(category, subcategory)}}</span>
                      </span>
                  <span style="margin-left: 2vh">{{subcategory.elementDescription}} <span
                    style="color: darkred; font-size:15px;">{{subcategory.isDeprecated ? '(Sottocategoria deprecata)' : '' }}</span></span>
                  <!--upload button-->

                  <span rgiRxTooltip="Upload" *ngIf="!subcategory.isDeprecated && !category.isDeprecated"
                        (click)="handleUpload(null, category, subcategory)"
                        class="rgifont rgi-upload"
                        style="scale:1.2; color: #5accca; margin-right: 0; margin-left: auto"></span>

                </rgi-rx-expansion-panel-header>
                <!--show actor sub-accordion depending on system properties-->
                <rgi-rx-expansion-panel
                  *ngFor="let doc of getCategorySubcategoryDocuments(category, subcategory)">
                  <rgi-rx-expansion-panel-header
                    style="background-color: #ebeff3; color: #145f9e; font-size: 16px; font-family: 'DINProBold'"
                    class="document-name">
                    <span style="padding:1vh" class="rgifont rgi-doc-file-format"></span>  {{doc.fileName}}
                    <span style="margin-right: 0; margin-left: auto;">

                              <span
                                style="font-weight: bold;padding: 1vh">
                                <span *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA">
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-success" *ngIf="doc.isValidated">{{ '_CLAIMS_._VALIDATED' | translate }}</span>
                                  <span class="rgi-ui-badge-rounded-pill rgi-ui-danger" *ngIf="!doc.isValidated">{{ '_CLAIMS_._NO_VALIDATE' | translate }}</span>
                                </span>

                 </span>
                      <!--download-->
                              <span style="padding-left: 1.5vh; padding-right: 1.5vh" (click)="performDownload(doc.id)"
                                    class="rgifont rgi-download"
                              ></span>
                      <!--view document-->
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh; scale:1.2"
                                  (click)="viewDocument(doc.id)"
                                  class="rgifont rgi-magnifier"></span>
                      <!--menu-->
                            <span [rgiRxMenuTrigger]="menu" [sourceEvents]="['click']"
                                  class="rgifont rgi-caret-down pull-right dropdown-toggle"
                                  style="background-color: whitesmoke; padding: 1vh">

                            </span>
                            <span style="padding-left: 1.5vh; padding-right: 1.5vh">

                              <rgi-rx-menu #menu style="position: relative">
                                <!--where is LETTERMANAGFLOW boolean?-->
                                <div rgi-rx-menu-item [disabled]="doc.isValidated"
                                     (click)="onValidateDocument(doc)"
                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA  ">

                                   {{ '_CLAIMS_._VALIDATED' | translate }}
                                </div>

                                <div rgi-rx-menu-item [disabled]="!doc.isValidated"
                                     (click)="onInvalidateDocument(doc)"
                                     *ngIf="(isShippingEnabled || validationVisible) && minBranchRCA ">

                                     {{ '_CLAIMS_._INVALIDATE' | translate }}
                                </div>

                                  <div rgi-rx-menu-item
                                       (click)="handleOpenEditDocument(doc, null, category, subcategory)">
                                    {{ '_CLAIMS_._MODIFY' | translate }}
                                </div>
                              </rgi-rx-menu>
                            </span>
                            </span>
                  </rgi-rx-expansion-panel-header>
                  <div class="panel-body">
                    <!--sottocategoria-->
                    <div class="document-detail document-detail-first col-xs-6"
                         *ngIf="category.subcategories && category.subcategories.length > 0">
                     {{ '_CLAIMS_._SUBCATEGORY' | translate }}: <span
                      style="font-weight: bold"> {{category.elementDescription}} </span>
                    </div>
                    <!--canale di ricezione-->
                    <div class="document-detail document-detail-first  col-xs-6">
                     {{ '_CLAIMS_._RECEPTION_CHANNEL' | translate }}:
                    </div>
                    <!--formato-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._FORMAT' | translate }}:<span
                      style="font-weight: bold"> {{doc.fileType ? doc.fileType.toUpperCase() : ''}} </span>
                    </div>
                    <!--dimensione-->
                    <div class="document-detail col-xs-6">
                     {{ '_CLAIMS_._SIZE' | translate }}: <span style="font-weight: bold">{{doc.file.fileSize}} KB</span>
                    </div>
                    <!--numero pagine-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._NUMBER_OF_PAGES' | translate }}: <span style="font-weight: bold">{{doc.numPages}} </span>
                    </div>
                    <!--data inserimento-->
                    <div class="document-detail  col-xs-6">
                      {{ '_CLAIMS_._UPLOAD_DATE' | translate }}: <span style="font-weight: bold">{{doc.uploadDate}} </span>
                    </div>
                    <!--data ricezione-->
                    <div class="document-detail  col-xs-6">
                      {{ '_CLAIMS_._RECEIIPT_DATE' | translate }}: <span style="font-weight: bold">{{doc.receptionDate}} </span>
                    </div>
                    <!--utente-->
                    <div class="document-detail  col-xs-6">
                      {{ '_CLAIMS_._USER' | translate }}: <span style="font-weight: bold;"> {{doc.userName}} </span>
                    </div>
                    <!--note-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._NOTE' | translate }}: <span style="font-weight: bold">{{doc.uploadNote}} </span>
                    </div>
                    <!--sottotitolo-->
                    <div class="document-detail col-xs-6">
                      {{ '_CLAIMS_._SUBTITLE' | translate }}: <span style="font-weight: bold">{{doc.subtitle}} </span>
                    </div>
                    <!--chiave doc-->
                    <div class="document-detail  col-xs-6">
                       {{ '_CLAIMS_._DOC_KEY' | translate }}: <span style="font-weight: bold">{{doc.id}} </span>
                    </div>
                    <!--postalization result-->
                    <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                      {{ '_CLAIMS_._POSTALIZATION_RESULT' | translate }}: <span
                      style="font-weight: bold">{{doc.shippingOutcome}} </span>
                    </div>

                    <!--postalization Date-->
                    <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
                      {{ '_CLAIMS_._POSTALIZATION_DATE' | translate }}: <span
                      style="font-weight: bold">{{doc.shippingDate}} </span>
                    </div>
                    <!--shipping type-->
                    <div class="document-detail  col-xs-6" *ngIf="doc.shippingChannel">
                      {{ '_CLAIMS_._SHIPPING_TYPE' | translate }}: <span style="font-weight: bold">{{doc.shippingChannel}} </span>
                    </div>
                  </div>
                </rgi-rx-expansion-panel>
              </rgi-rx-expansion-panel>
            </div>
          </div>
        </rgi-rx-expansion-panel>
      </div>
    </ng-template>
  </rgi-rx-tab>

  <!--CRONOLOGIA-->
  <rgi-rx-tab id="customtab3" style="flex-grow: 1 !important; font-family: 'DINProMedium' !important;" >
    <ng-template rgiRxTabLabel>
      <div style="font-family: 'DINProMedium' !important;">{{ '_CLAIMS_._HISTORY' | translate}}</div>
    </ng-template>
    <ng-template rgiRxTabContent>
      <!--      (select)="onSelectChange($event)"-->
      <rgi-rx-datatable *ngIf="documents && documents.length"

                        [expansionRow]="rowDetail" [data]="this.datatableDocuments"
                        [selectable]="true"
                        (onAction)="onActionDataTable($event)"
                        [schema]="schema"
                        [sortHeader]="sortDef"
                        [pageOptions]="[5,10,20]"
      >
      </rgi-rx-datatable>
      <!--COLLAPSIBLE ROW DETAIL TEMPLATE-->
      <ng-template #rowDetail
                   let-ctx>

        <!--ROW 1-->
        <div class="document-detail document-detail-first  col-xs-6">
          {{ '_CLAIMS_._FORMAT' | translate }}  : <span
          style="font-weight: bold"> {{ctx.fileType ? ctx.fileType.toUpperCase() : ''}} </span>
        </div>
        <!--canale di ricezione-->
        <div class="document-detail document-detail-first  col-xs-6">
         {{ '_CLAIMS_._RECEPTION_CHANNEL' | translate }}:
        </div>

        <div class="document-detail col-xs-6">
         {{ '_CLAIMS_._SIZE' | translate }}: <span style="font-weight: bold">{{ctx.file.fileSize}} KB</span>
        </div>

        <!--ROW 2-->

        <div class="document-detail  col-xs-6">
          {{ '_CLAIMS_._USER' | translate }} : <span
          style="font-weight: bold"> {{ctx.userName ? ctx.userName.toUpperCase() : ''}} </span>
        </div>

        <!--        <div class="document-detail  col-xs-6">
                  Validazione : <span
                  style="font-weight: bold">
                  <span [innerHTML]="spanValidated" *ngIf="ctx.isValidated === 'true'"></span>
                  <span [innerHTML]="spanNotValidated" *ngIf="ctx.isValidated === 'false'"></span>
                   </span>
                </div>-->

        <!--ROW 3-->

        <div class="document-detail col-xs-6">
           {{ '_CLAIMS_._NOTE' | translate }} : <span
          style="font-weight: bold"> {{ctx.uploadNote ? ctx.uploadNote : ''}} </span>
        </div>

        <div class="document-detail col-xs-6">
          {{ '_CLAIMS_._SUBTITLE' | translate }} : <span
          style="font-weight: bold"> {{ctx.subtitle ? ctx.subtitle : ''}} </span>
        </div>

        <!--ROW 4-->

        <div class="document-detail  col-xs-6">
          {{ '_CLAIMS_._NUMBER_OF_PAGES' | translate }} : <span
          style="font-weight: bold"> {{ctx.numPages ? ctx.numPages : ''}} </span>
        </div>

        <div class="document-detail  col-xs-6">
           {{ '_CLAIMS_._DOC_KEY' | translate }} : <span
          style="font-weight: bold"> {{ctx.id}} </span>
        </div>
        <!--postalization result-->
        <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
         {{ '_CLAIMS_._POSTALIZATION_RESULT' | translate }}: <span
          style="font-weight: bold">{{ctx.shippingOutcome}} </span>
        </div>

        <!--postalization Date-->
        <div class="document-detail  col-xs-6" *ngIf="isShippingEnabled">
          {{ '_CLAIMS_._POSTALIZATION_DATE' | translate }}: <span style="font-weight: bold">{{ctx.shippingDate}} </span>
        </div>
        <!--shipping type-->
        <div class="document-detail  col-xs-6" *ngIf="ctx.shippingChannel">
           {{ '_CLAIMS_._SHIPPING_TYPE' | translate }}: <span style="font-weight: bold">{{ctx.shippingChannel}} </span>
        </div>
      </ng-template>

      <!--DOWNLOAD SELECTED DOCUMENTS ACCORDION-->
      <!--      <div class="col-xs-12" *ngIf="selectedDocumentsHistory.size">-->
      <!--        <button rgi-rx-button (click)="onClickDownloadSelectedDocuments($event)" style="width: 100%" color="primary">-->
      <!--          DOWNLOAD DOCUMENTI SELEZIONATI-->
      <!--        </button>-->
      <!--      </div>-->

    </ng-template>
  </rgi-rx-tab>

</rgi-rx-tab-group>

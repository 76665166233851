import {ComponentRef, EventEmitter, Injector, OnDestroy, TemplateRef, Type, ViewContainerRef} from '@angular/core';
import {RgiRxPushMessage, RgiRxPushMessageContent} from '@rgi/rx';
import {
  CdkPortalOutlet,
  CdkPortalOutletAttachedRef,
  ComponentPortal,
  Portal,
  TemplatePortal
} from '@angular/cdk/portal';
import {RGI_RX_SNACKBAR_DATA, RgiRxSnackbarData} from './rgi-rx-snackbar-api';

export abstract class RgiRxSnackbarNotification{

  remove = new EventEmitter<RgiRxPushMessage>();
  private _notification: RgiRxPushMessage;
  private _expanded = false;
  private _detailPortal?: Portal<any>;
  private _contentPortal?: Portal<any>;
  get notification(): RgiRxPushMessage {
    return this._notification;
  }

  set notification(value: RgiRxPushMessage) {
    this._notification = value;
    if (this.notification.detail) {
      this._detailPortal = this.getPortal(this.notification.detail, this.notification)
    }
    this._contentPortal = this.getPortal(this.notification.content, this.notification);
  }

  protected constructor(private _vc: ViewContainerRef) {
  }

  isText(content: string | TemplateRef<any> | Type<any>): content is string {
    return typeof content === 'string';
  }

  getClass(notification: RgiRxPushMessage) {
    if (notification && !!notification.status) {
      return `rgi-ui-${notification.status}`;
    }
    return 'rgi-ui-default';
  }

  protected getPortal(content: RgiRxPushMessageContent, message: RgiRxPushMessage): Portal<any> | undefined {
    if (content instanceof TemplateRef) {
      return new TemplatePortal<any>(content, this._vc, this.createContext(message));
    } else if (typeof content === 'string') {
      return undefined;
    } else {
      return new ComponentPortal(content, undefined, Injector.create(
        {
          parent: this._vc.injector,
          providers: [{
            provide: RGI_RX_SNACKBAR_DATA,
            useValue: this.createContext(message)
          }]
        }
      ));
    }
  }

  private createContext<T>(pushMessage: RgiRxPushMessage): RgiRxSnackbarData<T> {
    return {
      context: pushMessage.context,
      status: pushMessage.status,
      id: pushMessage.id,
      opts: {
        icon: pushMessage.options ? pushMessage.options.icon : undefined,
        delay: pushMessage.delay,
        tag: pushMessage.tag,
        dismissible: pushMessage.dismissible,
        expanded: pushMessage.options ? pushMessage.options.expanded : undefined,
      }
    };
  }

  get qaValue() {
    return this.notification ? this.isText(this.notification.content) ? this.notification.content : '_dynamic_' : '';
  }

  onOutletAttached($event: CdkPortalOutletAttachedRef, portalOutlet: CdkPortalOutlet, portal: Portal<any>) {
    if (portalOutlet.attachedRef instanceof ComponentRef) {
      //portalOutlet.attachedRef.changeDetectorRef.detectChanges();
    }
  }

  onRemove() {
    this.remove.emit(this.notification);
  }


  get expanded(): boolean {
    return this._expanded;
  }


  set expanded(value: boolean) {
    this._expanded = value;
  }


  get detailPortal(): Portal<any> {
    return this._detailPortal;
  }


  get contentPortal(): Portal<any> {
    return this._contentPortal;
  }
}

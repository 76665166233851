import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ToolDefinition} from '../../model/tool-definition';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {PassResponseOption} from '../../../lic-fund/model/PassResponse';
// eslint-disable-next-line max-len
import {LicCheckboxAccordionComponent} from '../../../invest-standalone-session/component/lic-checkbox-accordion/lic-checkbox-accordion.component';
import {distinctUntilChanged} from 'rxjs/operators';
import { KarmaProfileDefinition } from './../../../lic-karma-funds/model/karma-profile-definition';
import { SelectionState } from '../../../lic-fund/model/SelectionState';

@Component({
  selector: 'lic-tool-with-accordion',
  templateUrl: './lic-tool-with-accordion.component.html',
  styleUrls: ['./lic-tool-with-accordion.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicToolWithAccordionComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicToolWithAccordionComponent),
      multi: true
    }
  ]
})
export class LicToolWithAccordionComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  private $subscriptions: Subscription[] = [];

  @ViewChild('accordion', {static: true}) accordion: LicCheckboxAccordionComponent;
  @Input() toolOption: PassResponseOption;
  @Input() definition: ToolDefinition;
  @Input() investAmount: number;
  @Input() funds: any;
  @Input() investedProfiles: KarmaProfileDefinition[];
  @Output() toolCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl(false),
    tool: new UntypedFormControl(null)
  });

  public get isActive(): boolean {
    return this.formGroup.get('active').value;
  }

  get isToolActive(): boolean {
    const toolCleanedUp = this.formGroup.getRawValue().tool === null;
    // if the form control of the tool has values i'll keep the preval using the
    return !toolCleanedUp && (this.toolOption.initialState === SelectionState.MANDATORY
    || this.toolOption.initialState === SelectionState.ACTIVE);
  }

  get isToolMandatory(): boolean {
    return this.toolOption.initialState === SelectionState.MANDATORY;
  }

  constructor() { }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.get('active').valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(value => {
        if (value) {
          // this.accordion.activate();
          this.onChange(this.formGroup.get('tool').value);
        } else {
          // this.accordion.deactivate();
          this.formGroup.get('tool').clearValidators();
          this.formGroup.get('tool').setValue(null, { emitEvent: false });
          this.onChange(null);
        }
        this.onTouch();
      }),
      this.formGroup.get('tool').valueChanges.subscribe(value => {
        if (value !== null) {
          this.onChange(this.formGroup.get('tool').value);
        } else {
          this.onChange(null);
        }
        this.onTouch();
      })
    );
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formGroup.get('tool').errors;
  }

  writeValue(obj: any): void {
    if (obj !== null) {
      this.formGroup.get('tool').setValue(obj, { emitEvent: false });
      this.formGroup.get('active').setValue(true, { emitEvent: false });
      this.accordion.activate(false);
    } else {
      this.formGroup.get('tool').setValue(null, { emitEvent: false });
      this.formGroup.get('active').setValue(false, { emitEvent: false });
      this.accordion.deactivate(false);
    }
  }

  onChange(obj: number) {
  }

  onTouch() {
  }

  onAccordionClick($event: boolean) {
    this.formGroup.get('active').setValue($event);
    this.toolCheck.emit($event);
  }


}

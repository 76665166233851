import {AnagPartyConfiguration} from './anag-party-configuration';
import {AnagIdentificationEntity} from './anag-identification-entity';
import {AnagEntityIta} from '../anag-api/anag-subject-api';

export class AnagPrivacyConfig {
  privacy: AnagPartyConfiguration;
  privacyConsensusValue: AnagPartyConfiguration;
  privacyConsentDate: Date;
  privacyConsentOrigin: number;
  privacyConsentOriginDescription: string;
  privacyConsensusDateUpd : Date;
}

export class AnagPrivacyConsensus {
  privacy: AnagIdentificationEntity;
  privacyConsensusValue: AnagEntityIta;
  privacyConsentDate: Date;
  privacyConsentOrigin: string;
  privacyConsentOriginDescription: string;
  privacyConsentDateUpd : Date;

  constructor(privacy, privacyConsensusValue, privacyConsentDate, privacyConsentOrigin, privacyConsentOriginDescription) {
    this.privacy = privacy;
    this.privacyConsensusValue = privacyConsensusValue;
    this.privacyConsentDate = privacyConsentDate;
    this.privacyConsentOrigin = privacyConsentOrigin;
    this.privacyConsentOriginDescription = privacyConsentOriginDescription;
  }
}

export class ScheduledPremiumFactor {
    label: string;
    code: string;
    value: any;
    type: string;
    visible = true;
    readOnly = true;
    translate = true;
    mandatory = false;
    values: null = null;

    constructor(label: string, code: string, value: any, type: string) {
      this.label = label;
      this.code = code;
      this.value = value;
      this.type = type;
    }
  }


<div *ngIf="(searchboardState.getState$() | async) as state">
    <div class="container-fluid">
        <rgi-rx-tab-group [selectedIndex]="selectedIndex" (onTabSelectionChange)="onSelectionChange($event)">
            <rgi-rx-tab *ngFor="let tab of tabArray; let i = index" [label]="tab | translate" (change)="onTabChange($event)">
                <form [formGroup]="this.searchForm">
                    <ng-template rgiRxTabContent>
                        <div class="row">

                            <!-- CODICE FUNZIONE | Visibile: tutti -->
                            <div [class]="i != tabViewCondition.VALIDATION_CONSOLIDATION ? 'col-md-12' : 'col-md-6' ">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._FUNCTION' |
                                        translate }}</label>
                                    <select rgiRxNativeSelect class="core-select form-control" id="function"
                                        name="function" attr.data-qa="function-select" formControlName="functionCode"
                                        (change)="onChangeFun()" required>
                                        <option></option>
                                        <option
                                            *ngFor="let function of state.searchWrap.dialogueFunctionResponse; let i = index"
                                            [value]="function.functionCode">
                                            {{ function.functionCode }}</option>
                                    </select>
                                </rgi-rx-form-field>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>


                            <!-- CODICE COMPAGNIA | Visibile: validationAndUpload, paymentManage -->
                            <div *ngIf="i != tabViewCondition.FORFAIT_MANAGE" class="col-md-6">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._COMPANY' |
                                        translate }}</label>
                                    <select rgiRxNativeSelect class="core-select form-control" formControlName="company"
                                        id="company" name="company" attr.data-qa="object-select">
                                        <option></option>
                                        <option
                                            *ngFor="let company of state.searchWrap.dialogueCompResponse.internalCompany"
                                            [value]="company.code">
                                            {{company.code}} - {{company.description}}</option>
                                    </select>
                                </rgi-rx-form-field>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>


                            <!-- CODICE COMPAGNIA CONTROPARTE | Visibile: paymentManage -->
                            <div *ngIf="i == tabViewCondition.PAYMENT_MANAGE" class="col-md-6">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._CTP_COMPANY' |
                                        translate }}</label>
                                    <select rgiRxNativeSelect class="core-select form-control" id="companyCtr"
                                        name="companyCtr" attr.data-qa="object-select" formControlName="companyCtr" [required]="i == 1">
                                        <option></option>
                                        <option
                                            *ngFor="let companyCtr of state.searchWrap.dialogueCompResponse.counterpartCompany"
                                            [value]="companyCtr.code">
                                            {{companyCtr.code}} - {{companyCtr.description}}</option>
                                    </select>
                                </rgi-rx-form-field>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>
                        </div>


                        <!-- ANNO e MESE | Visibile: validationAndUpload, paymentManage -->
                        <div *ngIf="i != tabViewCondition.FORFAIT_MANAGE" class="row">

                            <div class="col-md-6">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel class="label-core">{{ '_CLAIMS_._YEAR' | translate }}</label>
                                    <select rgiRxNativeSelect class="core-select form-control" formControlName="year"
                                        id="year" name="year" attr.data-qa="object-select" (change)="onYearChange($event)">
                                        <option></option>
                                        <option *ngFor="let year of years" [value]="year">{{year}}</option>
                                    </select>
                                </rgi-rx-form-field>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>

                            <div class="col-md-6">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._MONTH' |
                                        translate }}</label>
                                    <select rgiRxNativeSelect class="core-select form-control" id="month" name="month"
                                        attr.data-qa="object-select" formControlName="month">
                                        <option></option>
                                        <option *ngFor="let month of months" [value]="month.code">{{month.description |
                                            translate}}</option>
                                    </select>
                                </rgi-rx-form-field>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>
                        </div>


                        <!-- DATA INIZIO e DATA FINE | Visibile: forfait -->
                        <div *ngIf="i == tabViewCondition.FORFAIT_MANAGE" class="row" style="margin-bottom: 4px;">
                            <div class="col-md-6">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel>{{ '_CLAIMS_._VALIDITY_START_DATE' | translate }}</label>
                                    <rgi-rx-date-picker>
                                        <input [rgiRxDateTimeInput]="dataInizioValidita" formControlName="startDate">
                                        <rgi-rx-date-time #dataInizioValidita></rgi-rx-date-time>
                                    </rgi-rx-date-picker>
                                </rgi-rx-form-field>
                            </div>
                            <div class="col-md-6" style="margin-bottom: 4px;">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel>{{ '_CLAIMS_._VALIDITY_END_DATE' | translate }}</label>
                                    <rgi-rx-date-picker>
                                        <input [rgiRxDateTimeInput]="dataFineValidita" formControlName="endDate">
                                        <rgi-rx-date-time #dataFineValidita></rgi-rx-date-time>
                                    </rgi-rx-date-picker>
                                </rgi-rx-form-field>
                            </div>
                        </div>


                        <!-- MESSAGGIO DIALOGO | Visibile: tutti -->
                        <div class="row">
                            <div class="col-md-12">
                                <rgi-rx-form-field>
                                    <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._MESSAGE' |
                                        translate }}</label>
                                    <select rgiRxNativeSelect class="core-select form-control" formControlName="msgCode"
                                        id="message" name="message" attr.data-qa="object-select">
                                        <option></option>
                                        <option *ngFor="let message of selectedFunction?.dialogueMsgData"
                                            [value]="message.dialogueMsgCode">
                                            {{ message.dialogueMsgCode }} - {{ message.dialogueMsgDescription }}
                                        </option>
                                    </select>
                                </rgi-rx-form-field>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>
                        </div>
                    </ng-template>
                </form>
            </rgi-rx-tab>
        </rgi-rx-tab-group>

        <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error"
            attr.data-qa="elaboration-request-search-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{errorMessage | translate}}</span>
        </span>

        <div class="btn-group btn-group-justified btn-group-justified-claim">
            <div class="btn-group">
                <button type="submit" (click)="this.switchFunctions(selectedIndex)" style="color: white;" rgi-rx-button
                    class="btn btn-warning pull-right text-uppercase">{{'_CLAIMS_._BUTTONS_._FIND' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LpcFundsChartModule } from '../../modules/lpc-funds-chart/lpc-funds-chart.module';
import { LpcKarmaFundModule } from '../../modules/lpc-karma-funds/lpc-karma-fund.module';
import { LoaderMobileModule } from '../../modules/lpc-loader-mobile/loader-mobile.module';
import { MessageModalComponentModule } from '../../modules/lpc-message-modal/lpc-message-modal.module';
import { LpcQuestionnaireContainerModule } from '../../modules/lpc-questionnaire-container/lpc-questionnaire-container.module';
import { TooltipModule } from '../../utils/directives/tooltip.module';
import { ToPercentage } from '../../utils/pipe/volatility/percentage.pipe';
import { PlcGraphicFix } from '../../utils/plc-graphicFix-utils';
import { ExpiryOptionDetailComponent } from './expiry-option-detail/expiry-option-detail.component';
import { FiscalDataDetailComponent } from './fiscal-data-detail/fiscal-data-detail.component';
import { LifeBookingDetailComponent } from './life-booking-detail/life-booking-detail.component';
import { LifeDetailComponent } from './life-datail/life-detail.component';
import { GsFundDetailComponent } from './life-financial-detail/gs-fund-detail/gs-fund-detail.component';
import { LifeFinancialDetailComponent } from './life-financial-detail/life-financial-detail.component';
import { RevaluationDetailTableComponent } from './life-financial-detail/revaluation-detail-table/revaluation-detail-table.component';
import { SingleFundDetailComponent } from './life-financial-detail/single-fund-detail/single-fund-detail.component';
import { LifeMovementDetailComponent } from './life-movement-detail/life-movement-detail.component';
import { LifeOptionDetailComponent } from './life-option-detail/life-option-detail.component';
import { LifeTrendDetailComponent } from './life-trend-detail/life-trend-detail.component';
import { StatusHandlerComponent } from './lpc-status-handler/status-handler/status-handler.component';
import { SummaryDetailCardComponent } from './summary-detail-card/summary-detail-card.component';
import { ReinvestmentDataDetailComponent } from './reinvestment-data-detail/reinvestment-data-detail.component';
import { LoanDataDetailComponent } from './loan-data-detail/loan-data-detail.component';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { LifePipDetailComponent } from './life-pip-detail/life-pip-detail.component';
import { LifePipDetailComponentMCComponent } from './life-datail/life-details-mini-cards/life-pip-detail/life-pip-detail-mc.component';
import { SortByDateModule } from '../../utils/pipe/dates-order/dates-order-pipe.module';
import { LbSubjectsComponent } from './life-booking-detail/lb-subjects/lb-subjects.component';
import { RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxPanelModule, RgiRxTabModule, RgiRxTableModule } from '@rgi/rx/ui';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { DataValueFormatterModule } from '../../utils/pipe/data-value-formatter/data-value-formatter.module';
import { LpcPremiumDetailComponent } from './lpc-premium-detail/lpc-premium-detail.component';
import { LpcFeeExtraModalComponent } from './lpc-premium-detail/lpc-fee-extra-modal/lpc-fee-extra-modal.component';
import { LifeMovementInstallmentPremiumDetailComponent } from './life-movement-detail/life-movement-installment-premium-detail/life-movement-installment-premium-detail.component';
import { ReserveFormulaDetailComponent } from './reserve-formula-detail/reserve-formula-detail.component';
import { LifeInvestmentPlanDetailComponent } from './life-investment-plan-detail/life-investment-plan-detail.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LPC_TRANSLATIONS } from '../../i18n/translations';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { CustomPropertyService } from '../../services/custom-property.service';
import { BuyQuoteModule } from '../../modules/buy-quote/buy-quote.module';
import { SurveyEvaluateStateOperations } from '@rgi/ng-passpropro-survey';
import { SurveyEvaluateStateLifeOperations } from '../../survey/survey-evaluate-state-operations-life.service';


@NgModule({
    declarations: [
        LifeDetailComponent,
        LifeFinancialDetailComponent,
        LifePipDetailComponent,
        StatusHandlerComponent,
        SummaryDetailCardComponent,
        LifeMovementDetailComponent,
        LifeBookingDetailComponent,
        LifeTrendDetailComponent,
        ToPercentage,
        LifeOptionDetailComponent,
        SingleFundDetailComponent,
        GsFundDetailComponent,
        RevaluationDetailTableComponent,
        FiscalDataDetailComponent,
        ReinvestmentDataDetailComponent,
        ExpiryOptionDetailComponent,
        ReserveFormulaDetailComponent,
        LoanDataDetailComponent,
        LifePipDetailComponentMCComponent,
        LbSubjectsComponent,
        LpcPremiumDetailComponent,
        LpcFeeExtraModalComponent,
        LifeMovementInstallmentPremiumDetailComponent,
        LifeInvestmentPlanDetailComponent
    ],
    imports: [
        RgiCountryLayerModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        NgSelectModule,
        PortalNgCoreModule,
        LpcFundsChartModule,
        MessageModalComponentModule,
        LpcKarmaFundModule,
        LoaderMobileModule,
        LpcQuestionnaireContainerModule,
        TooltipModule,
        SortByDateModule,
        RgiRxExpansionModule,
        RgiRxModalModule,
        RgiRxTabModule,
        DataValueFormatterModule,
        RgiRxPanelModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(LPC_TRANSLATIONS),
        LpcRequiredModule,
        BuyQuoteModule,
        RgiRxTableModule,
        RgiRxFormElementsModule
    ],
    exports: [
        LifeDetailComponent,
        LifeFinancialDetailComponent,
        LifePipDetailComponent,
        StatusHandlerComponent,
        SummaryDetailCardComponent,
        LifeMovementDetailComponent,
        LifeBookingDetailComponent,
        LifeTrendDetailComponent,
        LifeOptionDetailComponent,
        SingleFundDetailComponent,
        GsFundDetailComponent,
        RevaluationDetailTableComponent,
        FiscalDataDetailComponent,
        ReinvestmentDataDetailComponent,
        LoanDataDetailComponent,
        ExpiryOptionDetailComponent,
        ReserveFormulaDetailComponent,
        LbSubjectsComponent,
        LpcPremiumDetailComponent,
        LifePipDetailComponentMCComponent,
        LifeInvestmentPlanDetailComponent
    ],
    providers: [
        { provide: PV_TOKEN.PV_GRAPHIC_FIX, useValue: PlcGraphicFix },
        { provide: PV_TOKEN.SHOW_LOADER_MOBILE, useValue: false },
        CustomPropertyService,
        SurveyEvaluateStateLifeOperations,
        { provide: SurveyEvaluateStateOperations, useClass: SurveyEvaluateStateLifeOperations }
    ]
})
export class LifeDetailCardModule {
}

import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RoutableComponent} from '../routable-component';
import {EventNotificator} from '../event-notificator';
import {PassProductsService} from '../pass-products.service';
import {DobValidator} from '../dob.validator';
import {isPlateFormatOk$, PlateValidator} from '../plate.validator';
import {NodeValidator} from '../node.validator';
import {FilterAniaTypesByPlate, hasAssetNotFoundErrors, hasTechnicalDataErrors} from './plates-utils';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {ProductAssetModalComponent} from '../product-asset-modal/product-asset-modal-component';
import {ApiContract} from '../models/api-models/api-contract';
import {ApiCompany} from '../models/api-models/api-company';
import {ApiAgency} from '../models/api-models/api-agency';
import {ApiAssetType} from '../models/api-models/api-asset-type';
import {LicensePlate} from '../models/domain-models/license-plate';
import {NodeSearchModalComponent} from '@rgi/portal-ng-common';
import {DateElement} from '../models/domain-models/dateElement';
import {StateService} from '../state.service';
import {RoutingService} from '../routing.service';
import {forkJoin, of, Subject, Subscription} from 'rxjs';
import {ProposalService} from '../proposal.service';
import {Action} from '../models/action';
import {CustomModalService} from '../custom-modal.service';
import {Message} from '../models/message';
import {ModalService, OperatorService} from '@rgi/portal-ng-core';
import {TranslateService} from '@ngx-translate/core';
import {ContractCacheService} from '../contract-cache.service';
import {DataStorageService} from '../data-storage.service';
import {MicIntegrationService} from '../mic-integration.service';
import {PartiesService} from '../proposal/parties.service';
import {concatMap, map, mergeMap, take} from 'rxjs/operators';
import {StartService} from './start.service';
import {CommonService} from '../common.service';

@Component({
  selector: 'mic-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator {
  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();

  @ViewChild('productAssetModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) productAssetOverlayContainerRef: ViewContainerRef;
  @ViewChild('nodeSearchModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) nodeSearchModalOverlayContainerRef: ViewContainerRef;

  componentRef: any;

  startForm: UntypedFormGroup;
  submitted = false;
  validationMessages: Message[] = [];
  availableCompanies: any[] = [];
  node: any;
  company: any;
  availableAgencies: any[] = [];
  assets: GenericEntity[];
  productAssets: GenericEntity[];
  noPlateMode = false;
  salePointCode: string;
  salePoint: any;
  maxNode = 10;
  isAgency: boolean;
  minDate = new DateElement(1900, 1, 1);
  maxDate = new DateElement((new Date()).getFullYear(), 12, 31);
  doNotRefreshContractData = false;
  showBasket = false;
  showDateOfBirth = false;

  areaCode = 'START';

  selectLabel = 'SELECT';
  plateWrongFormat = false;

  protected subscriptions: Subscription = new Subscription();

  constructor(
    protected operatorService: OperatorService,
    protected resolver: ComponentFactoryResolver,
    protected formBuilder: UntypedFormBuilder,
    protected passProductsService: PassProductsService,
    protected stateService: StateService,
    protected routingService: RoutingService,
    protected proposalService: ProposalService,
    protected customModalService: CustomModalService,
    protected translationService: TranslateService,
    protected contractCacheService: ContractCacheService,
    protected dataStorageService: DataStorageService,
    protected integrationService: MicIntegrationService,
    protected partiesService: PartiesService,
    protected modalService: ModalService,
    protected startService: StartService,
    protected commonService: CommonService,
    @Inject('showEntryPointDateOfBirth') showEntryPointDateOfBirth?: boolean
  ) {
    this.showDateOfBirth = showEntryPointDateOfBirth;
  }

  ngOnInit() {

    this.validationMessages.length = 0;
    this.selectLabel = this.translationService.instant(this.selectLabel);
    this.startForm = this.formBuilder.group(
      {
        company: ['', Validators.required],
        node: ['', Validators.required],
        plateNumber: [undefined, Validators.required],
        dateOfBirth: undefined,
      }, {
        validator: [DobValidator('dateOfBirth'), PlateValidator('plateNumber'), NodeValidator('node', this.selectLabel)]
      }
    );
    this.getOperators();
    this.initializePropertyAndCasualtyCompanies();

    const startFormValueChangesSubscription = this.startForm.valueChanges.subscribe((val) => {
      if (!this.doNotRefreshContractData) {
        if (this.noPlateMode) {
          this.validateFormOnDoNotKnowPlate();
        } else {
          this.validateForm();
        }
        // When I change one data, I refresh the contract data
        this.proposalService.refresh();
      }
    });
    this.subscriptions.add(startFormValueChangesSubscription);
    this.eventPropagation.emit('layoutChanged');

    const resetStartFormSubscription = this.stateService.subscribeToResetStartForm().subscribe(
      (event) => {
        this.doNotRefreshContractData = true;
        this.resetPlateNumberAndDateOfBirth();
      }
    );
    this.subscriptions.add(resetStartFormSubscription);
  }

  getOperators() {
    this.salePoint = this.operatorService.getSalePointDefault();
    if (this.salePoint?.salePointType.codice === "3" || this.salePoint?.salePointType.codice === "4") {
      /*
       * Logged on agency.
       * Show the select with nodes (if they are less than x)
       */
      this.isAgency = true;
      this.node = this.salePoint;
      this.startForm.patchValue({ node: this.salePoint.description });
      this.initializeAgencies();
    } else {
      // Logged on group node. Management with modal
      this.isAgency = false;
      if (!this.salePoint) {
        this.salePoint = this.operatorService.getSalePointLogin();
      }
    }
    this.salePointCode = this.salePoint.code;
  }

  onSubmit(event) {
    this.stateService.resetState();
    this.startForm.get('plateNumber').setValidators([Validators.required]);
    this.startForm.get('plateNumber').updateValueAndValidity();
    this.startForm.get('node').setValidators([Validators.required]);
    this.proposalService.refresh();
    this.noPlateMode = false;
    this.submitted = true;
    this.validateForm();
    this.setFieldsDirty();
    const companyId = this.startForm.controls.company.value;
    const nodeId =  this.node.objectId ? this.node.objectId : this.node.idSp;
    const dateOfBirth = this.startForm.controls.dateOfBirth.value;
    const dateOfBirthValue = dateOfBirth ? dateOfBirth.getTime() - 60000 * dateOfBirth.getTimezoneOffset() : null;

    this.proposalService.setDateOfBirth(dateOfBirthValue);

    let plateNumber = this.startForm.controls.plateNumber.value;

    if (plateNumber) {
      plateNumber = String(plateNumber).trim().toUpperCase();
    }
    if (this.startForm.valid) {
      this.proposalService.setCompanyId(companyId);
      this.proposalService.setNodeId(nodeId);
      if (this.node) {
        this.proposalService.setNodeDescription(this.node.description);
      } else if (this.salePoint) {
        this.proposalService.setNodeDescription(this.salePoint.description);
      }
      this.proposalService.setPlateNumber(new LicensePlate(plateNumber, null, null, null, null, null));
      isPlateFormatOk$(this.startForm.controls.plateNumber, this.commonService, this.validationMessages,
        nodeId, this.areaCode, this.translationService).pipe(
        concatMap((isPlateFormatOk) => {
          if (isPlateFormatOk){
            return this.proposalService.newContract(companyId, nodeId, null, null,
              plateNumber, null, dateOfBirthValue);
          }
          return of(null);
        }),
        map((contract: ApiContract) => {
          if (contract) {
            this.manageNewContract(contract);
          }
        })
      ).subscribe();
    }
  }

  private manageNewContract(contract: ApiContract) {
    this.proposalService.setApiContract(contract);
    const plate = this.proposalService.getPlateNumber();
    if (contract.vehicle && contract.vehicle.plate) {
      plate.servicePlateType = contract.vehicle.plate.servicePlateType;
    }
    let products = contract.products;
    if (!products) {
      products = [];
    }
    if (hasAssetNotFoundErrors(contract)) {
      this.stateService.nextState(Action.DATA_IN_ANIA_NOT_FOUND_STANDARD_PLATE_WORKFLOW);
      this.openProductAssetModal();
    } else if (hasTechnicalDataErrors(contract)) {
      this.stateService.nextState(Action.DATA_IN_ANIA_NOT_FOUND_STANDARD_PLATE_WORKFLOW);
      this.openProductAssetModal();
    } else if ((products.length > 1) || (products.length === 1 && products[0].assets.length > 1)) {
      this.stateService.nextState(Action.MULTIPLE_PRODUCTS_ASSETS_FOUND_STANDARD_PLATE_WORKFLOW);
      this.proposalService.setMultipleProductsAssetTypes(products);
      this.openProductAssetModal();
    } else if (products.length === 1) {
      this.proposalService.setProduct(
        new GenericEntity
        (products[0].id,
          products[0].code, products[0].description));
      this.proposalService.setAssetType(
        new ApiAssetType(
          products[0].assets[0].id,
          products[0].assets[0].code,
          products[0].assets[0].description,
          products[0].assets[0].classId,
          products[0].assets[0].useId,
          products[0].assets[0].extendedDescription,
          products[0].assets[0].aniaVehicleTypeCode));
      this.manageCachedAnagPartyThenContinueFlow(contract, Action.SINGLE_PRODUCT_ASSET_FOUND_STANDARD_PLATE_WORKFLOW);
    }
  }

  onClear() {
    this.noPlateMode = false;
    this.submitted = false;
    this.startForm.reset();
    this.validationMessages.length = 0;
    if (this.availableCompanies.length === 1) {
      this.startForm.patchValue({company: this.company});
    }
    if (this.availableAgencies.length === 1) {
      this.startForm.patchValue({node: this.node.description});
    }
  }

  resetPlateNumberAndDateOfBirth() {
    this.noPlateMode = false;
    this.submitted = false;
    this.startForm.patchValue({plateNumber: null, dateOfBirth: null});
    this.startForm.controls.plateNumber.setErrors(null);
    this.startForm.updateValueAndValidity();
  }

  cleanNode() {
    this.startForm.patchValue({node: this.selectLabel});
    this.node = null;
    // this.showBasket = false;
  }

  selectChangeHandlerNode(event: any) {
    this.node = this.availableAgencies.find(x => x.idSp === this.startForm.get('node').value);
  }

  onPlateChange(plate: string) {
    // Risetto all'origine
    this.assets = this.productAssets;
    const filtrati: Set<string> = FilterAniaTypesByPlate(plate);
    const newAssetSet: Set<GenericEntity> = new Set<GenericEntity>();

    if (filtrati && filtrati.size > 0) {
      for (const k in this.assets) {
        if (this.assets[k].code && filtrati.has(this.assets[k].code)) {
          newAssetSet.add(this.assets[k]);
        }
      }
      this.assets = Array.from(newAssetSet);
    }
  }

  onDoNotKnowPlateNumber() {

    this.stateService.resetState();

    if (this.initForModal() === true) {
      this.stateService.nextState(Action.WITHOUT_PLATE_WORKFLOW_STARTED);
      this.openProductAssetModal();
    }
  }

  onOtherPlateTypes() {

    this.stateService.resetState();

    this.startForm.get('plateNumber').setErrors(null);
    this.startForm.get('plateNumber').clearValidators();

    if (this.initForModal() === true) {
      this.stateService.nextState(Action.ALTERNATIVE_PLATE_WORKFLOW_STARTED);
      this.openProductAssetModal();
    }
  }

  closeOverlay() {
    this.componentRef.destroy();
  }

  openNodeSearchModal() {
    this.nodeSearchModalOverlayContainerRef.clear();
    const factory = this.resolver.resolveComponentFactory(NodeSearchModalComponent);
    this.componentRef = this.nodeSearchModalOverlayContainerRef.createComponent(factory);
    this.componentRef.instance.ref = this.componentRef;
    this.componentRef.instance.codeLoginNode = this.salePointCode;
    if (!this.isAgency) {
      this.componentRef.instance.showGroupingNodes = true;
    }
    const selectedNodeSubscription = this.componentRef.instance.selectedNodeEmitter.subscribe((event) => {
      this.node = event;
      this.startForm.patchValue({node: this.node.description});
    });
    this.subscriptions.add(selectedNodeSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected setContractInServiceCache(contract: ApiContract) {
    let cachedContract: any = this.contractCacheService.getContractFromCache(contract.id);
    if (!cachedContract) {
      cachedContract = {};
    }
    cachedContract.proposal = this.proposalService.getProposal();
    cachedContract.nodeDescription = this.proposalService.getNodeDescription();
    cachedContract.dateOfBirth = this.proposalService.getDateOfBirth();
    cachedContract.stateService = {
      currentState: this.stateService.getCurrentState(),
      previousState: this.stateService.getPreviousState()
    };
    cachedContract.routingService = {
      naviPosition: this.routingService.getNaviPosition(),
      previousState: this.routingService.getPreviousState()
    };

    this.contractCacheService.setContractInCache(contract.id, cachedContract);
  }

  protected initializePropertyAndCasualtyCompanies() {
    this.dataStorageService.getAvailableCompanies().subscribe((data: Array<ApiCompany>) => {
      this.availableCompanies = data.filter(company => company.damages === true);

      if (this.availableCompanies.length === 1) {
        this.startForm.patchValue({company: this.availableCompanies[0].objectId});
        this.company = this.availableCompanies[0].objectId;
      }

      this.eventPropagation.emit('layoutChanged');
    });
  }

  protected initializeAgencies() {
    this.dataStorageService.getAvailableAgencies().subscribe((data: Array<ApiAgency>) => {
      this.availableAgencies = data;
      if (this.salePoint) {
        if (this.availableAgencies.length === 1 || this.availableAgencies.length > this.maxNode) {
          this.startForm.get('node').setValue(this.salePoint.description);
        } else {
          this.startForm.get('node').setValue(this.salePoint.objectId);
        }
        const node = this.startService.getNode(this.availableAgencies);
        this.node = node ? node : this.salePoint;
      }

      this.eventPropagation.emit('layoutChanged');
    });
  }


  protected validateForm() {
    const controls = this.startForm.controls;

    this.validationMessages.length = 0;
    if (controls.company.errors && controls.company.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Company is mandatory')));
    }
    if (controls.node.errors && controls.node.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Node is mandatory')));
    }
    if (controls.plateNumber.errors && controls.plateNumber.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('License Plate is mandatory')));
    }
    if (controls.plateNumber.errors && controls.plateNumber.errors.invalidPlate) {
      this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Plate number format is not correct')));
    }
    if (controls.dateOfBirth.errors) {
      if (controls.dateOfBirth.errors.futureDate) {
        this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Date of birth must be in the past')));
      } else if (controls.dateOfBirth.errors.invalidDate) {
        this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Date of birth may be invalid')));
      } else if (controls.dateOfBirth.errors.tooOld) {
        this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Date of birth may be invalid')));
      }
    }
    this.eventPropagation.emit('layoutChanged');
  }

  protected validateFormOnDoNotKnowPlate() {
    this.noPlateMode = true;
    const controls = this.startForm.controls;

    this.validationMessages.length = 0;

    if (controls.company.errors && controls.company.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Company is mandatory')));
    }
    /*
    if (controls.node.errors && controls.node.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Node is mandatory')));
    }
*/
    if (controls.node.errors) {
      this.validationMessages.push(new Message(this.areaCode, this.translationService.instant('Node is mandatory')));
    }
    this.eventPropagation.emit('layoutChanged');
  }

  protected setFieldsDirty() {
    Object.keys(this.startForm.controls).forEach(field => {
      this.startForm.get(field).markAsDirty();
    });
  }


  protected initForModal(): boolean {

    this.proposalService.refresh();

    const company = this.startForm.controls.company;
    const node = this.startForm.controls.node;

    this.submitted = true;

    this.validateFormOnDoNotKnowPlate();

    if (!company.value || !node.value) {
      if (!company.value) {
        company.markAsDirty();
      }
      if (!node.value) {
        node.markAsDirty();
      }
      return false;
    } else {
      this.proposalService.setCompanyId(company.value);
      this.proposalService.setNodeId(this.node.objectId ? this.node.objectId : this.node.idSp);

      return true;
    }
  }

  protected openProductAssetModal() {
    this.customModalService.openModal(this.productAssetOverlayContainerRef, ProductAssetModalComponent,
      this.eventPropagation, () => {
        this.onProductAssetModalDestroy();
      });
  }

  protected onProductAssetModalDestroy() {
    if (this.proposalService.getProduct() && this.proposalService.getAssetType()) {
      this.proposalService.createNewContract()
        .subscribe((contract: ApiContract) => {
          this.manageCachedAnagPartyThenContinueFlow(contract);
        }
      );
    } else {
      this.stateService.resetState();
    }
  }

  protected manageCachedAnagPartyThenContinueFlow(contract: ApiContract, action?: Action) {
    const continueFlow = new Subject<boolean>();
    continueFlow.pipe(take(1)).subscribe(result => {
      if (result) {
        this.proposalService.setApiContract(contract);
        this.stateService.nextState(action);
        this.setContractInServiceCache(contract);
      } else {
        this.stateService.resetState();
      }
    });
    this.setCachedAnagPartyFromIntegrationCache(contract, continueFlow);
  }

  protected setCachedAnagPartyFromIntegrationCache(contract: ApiContract, continueFlow: Subject<boolean>): void {
      const anagCache = this.integrationService.getSessionDataFromContainerCache('rgiAnag');
      if (!anagCache) {
        // no party in cache
        continueFlow.next(true);
        return;
      } else {
        const partyData = anagCache.data;
        if (contract.entitledBeforeQuotation) {
          // party in cache and skip entitled page
          forkJoin([
            this.partiesService.getParty(contract.id, partyData.objectId, partyData.idLatestPhotos),
            this.partiesService.getParties(contract.id)
          ]).pipe(
              map((partiesResults) => {
                partiesResults[1].subscriber = partiesResults[0];
                return partiesResults[1];
              }),
              mergeMap(parties => this.partiesService.updateParties(contract.id, parties)),
            ).subscribe(() => {
            continueFlow.next(true);
          });
        }
        // TODO add else case IT, branch PPPC-9055 in waiting
      }
  }

  showAlternativePlateLink(){
    return this.startService.isAlternativePlateLinkVisible();
  }

}

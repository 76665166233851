import {Injectable} from '@angular/core';
import {RgiRxRouter} from '@rgi/rx/router';
import {Observable} from 'rxjs';
import {Company} from '../../models/company';
import {StartCardNode} from '../../models/start-card-node';
import {ConsultationStartCardResourceService} from '../resource/consultation-start-card-resource.service';
import {StartCardFindDto} from '../../models/start-card-find-dto';
import {ProductsObject} from '../../models/pc-portfolio-models/genericEntityPcPortfolio';
import {ConsultationStartCardState} from '../../state-managers/states/consultation-start-card-state';
import {map, share} from 'rxjs/operators';
import {PolicyRow} from '../../models/policy';
import {PolicyOperationContainer, PolicyOperationPayload} from '../../models/policy-operations';
import {Filter, ProductsFilter} from '../../models/products-filter';


@Injectable({
  providedIn: 'root'
})
export class ConsultationStartCardBusinessService {
  constructor(
    private routingService: RgiRxRouter,
    private startCardService: ConsultationStartCardResourceService
  ) {
  }

  getCompanies$(): Observable<Array<Company>> {
    return this.startCardService.getCompanies$();
  }

  getProducts$(st: ConsultationStartCardState, node: string, productsCategory: string): Observable<ProductsObject> {
    const body: ProductsFilter = new ProductsFilter();
    body.productsFilter = new Filter(node, productsCategory, true, true);

    return this.startCardService.getProducts$(body).pipe(share(), map(val => {
      const objeProd: ProductsObject = new ProductsObject();
      val.products.forEach(element => {
        objeProd.products.push(element);

        if (!objeProd.productsType.find(obj => {
          return obj.code === element.productType.code;
        })) {
          element.productType.policyCategory = element.policyCategory;
          objeProd.productsType.push(element.productType);
        }
      });
      return objeProd;
    })
    );
  }

  getNode$(st: ConsultationStartCardState, node: string): Observable<Array<StartCardNode>> {
    return this.startCardService.getNode$(st, node).pipe(share(), map(val => {
      const starCardNode: Array<StartCardNode> = [];
      val.agencies.forEach((element => {
        starCardNode.push(element);
      }));
      return starCardNode;
    }));
  }
  getPolicyOperations(body: PolicyOperationPayload): Observable<PolicyOperationContainer> {
    return this.startCardService.getPolicyOperations(body);
  }
  validate() {
  }

  findPolicies(body: StartCardFindDto) : Observable<PolicyRow> {
    return this.startCardService.findPolicies(body);
  }

  downloadReport(extension: string, body: StartCardFindDto) {
    return this.startCardService.downloadReport(extension,body).subscribe(response=>{
      this.downloadFile(response);
    });
  }
  public downloadFile(response: any) {
    const filename = response.headers.get('Content-Disposition')?.split(';')?.[1]?.split('=')?.[1]?.slice(1, -1);
    let dataType: string = response.type;
    let binaryData = [];
    binaryData.push(response.body);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    if (filename)
      downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

}

import { Component, ViewEncapsulation, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';

@Component({
  selector: 'pnc-floating-error-messages',
  styleUrls: ['./floating-error-messages.component.css'],
  templateUrl: 'floating-error-messages.component.html',
  encapsulation: ViewEncapsulation.None
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class FloatingErrorMessagesComponent implements AfterViewInit, OnDestroy {

  @Input() validationMessages: any[];
  @Input() blockingMsgs: any[];
  @Input() warningMsgs: any[];
  @Input() authoMsgs: any[];

  // se non specificato prende la finestra intera
  @Input() containerId: string; // => lo posiziona in basso a dx e gestisce lo scroll

  @ViewChild('errorsElement')
  errorsElement: ElementRef<HTMLDivElement>;

  showFloatingMessages = false;
  enableWarningIcon = true;
  container: any;

  constructor() {
  }

  ngAfterViewInit() {
    if (this.containerId != null) {
      this.container = document.getElementById(this.containerId);
      this.container.addEventListener('scroll', () => {
        if (this.isInView()) {
          this.showFloatingMessages = false;
          this.enableWarningIcon = false;
        } else {
          this.enableWarningIcon = true;
        }
      });
    } else {
      window.addEventListener('scroll', () => {
        if (this.isInView()) {
          this.showFloatingMessages = false;
          this.enableWarningIcon = false;
        } else {
          this.enableWarningIcon = true;
        }
      });
    }
  }

  onWarningIconClick() {
    this.showFloatingMessages = true;
  }

  onCloseErrorsClick() {
    this.showFloatingMessages = false;
  }

  // verifico se il componente dei messaggi in basso è presente in view
  isInView(): boolean {
    const rect = this.errorsElement.nativeElement.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  ngOnDestroy() {
    if (this.containerId != null) {
      this.container.nativeElement.removeEventListener('scroll', () => {});
    } else {
      window.removeEventListener('scroll', () => {});
    }
  }

  /*
  calculateMaxHeight() {
    return (this.container.scrollHeight - this.container.offsetHeight)
          - (this.errorsElement.nativeElement.scrollHeight - this.errorsElement.nativeElement.offsetHeight)
          - 70; // scarto dei margini
  }
  */

}

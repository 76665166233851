import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { Observable, of } from 'rxjs';
import { Payments } from '../models/meansofpayment.model';
import { RelatedSubject, Subject, SubjectRole } from '../models/subject.model';
import { CustomProperties, DocSubject, PolicyRole } from './../models/policy.model';
import { LifeRoleService } from './life-role.service';
import { LifeSessionService } from './life-session-service';
import { PolicyService } from './policy-service';
import { BENEFICIARY_CHOICE, BeneficiaryGroupingTypeObj, Roles, SubjectConstants } from '../enum/life-issue.enum';

@Injectable({
    providedIn: 'root'
})
export class BeneficiaryService implements OnInit {

  public relatedSubject: Map<string, RelatedSubject[]>
    = new Map<string, RelatedSubject[]>();
  public relatedRoles: Map<string, SubjectRole[]> = new Map<string, SubjectRole[]>();
  public descrBenMap = new Map();

  protected benefCedolaPayment: Map<string, Payments> = new Map<string, Payments>();

  public BENEFICIARY_ROLE_CODE = Roles.BENEFICIARY;

  public benefGroupingTypes: BeneficiaryGroupingTypeObj = {activeTypes: [], types: null};
  positionNumberProp: CustomProperties = null;

  constructor(protected lifeSessionService: LifeSessionService,
              protected httpClient: HttpClient,
              protected policyService: PolicyService,
              protected translate: TranslationWrapperService,
              protected lifeRoleService: LifeRoleService) {
  }

  ngOnInit() {
  }

  getPolicyRole(subjectId: string, roleCode?: Roles): PolicyRole {
    const storedSubj = this.lifeRoleService.getStoredSubject(subjectId);
    return this.createPolicyRole(storedSubj, roleCode);
  }

  /* DESCRIPTION MAP */
  setBenefDescription(code: string, description: string) {
    this.descrBenMap.set(code, description);
  }

  getBenefDescription(code: string): string {
    return this.descrBenMap.get(code);
  }

  getValidationMsgByRoleCode(roleCode: string, cathegory: string): {code: 'previous_roles' | 'ref' | 'vinc' | 'benef', msg: string} {
    if (this.lifeRoleService.storedPolicyRoles.map(pR => pR.partyRole).some(role => role === roleCode)) {
      const policyRole = this.lifeRoleService.storedPolicyRoles.find(pR => pR.partyRole === roleCode);
      const role = this.lifeSessionService.partyRoles.find(el => el.codice === roleCode).descrizione + ' - ';
      return {code: 'previous_roles', msg: `${role} ${getNominative(policyRole)}`};
    }
    switch (roleCode) {
      case Roles.THIRDREFERENT:
        return {code: 'ref', msg: ''};
      case Roles.VINCOLATARIO:
        return {code: 'vinc', msg: ''};
      default:
        const msg: string = this.translate.getImmediate(
          'lic_beneficiary_qualification',
          {benefRole: this.translate.getImmediate('lic_Beneficiary'),
           benefCat: this.getBenefDescription(cathegory)}) + ' - ';
        return {code: 'benef', msg};
    }

    /**
     * Returns the nominative form of a party role based on their person type.
     * If the person type is juridical, returns the company name.
     * Otherwise, returns the full name in the format "surname name".
     * @param partyRole The party role to get the nominative form for.
     * @returns The nominative form of the party role.
     */
    function getNominative(partyRole: PolicyRole) {
      return partyRole.personType === SubjectConstants.JURIDICAL_SUBJECT ? partyRole.companyName : `${partyRole.surname} ${partyRole.name}`;
    }

  }

  resetService() {
    this.relatedSubject = new Map<string, RelatedSubject[]>();
    this.relatedRoles = new Map<string, any[]>();
  }

  /* RELATED ROLES */
  setRelatedRoles(category: string, benef: string, roleList: any[]) {
    this.relatedRoles.set(category + '-' + benef, roleList);
  }

  getRelatedRolesList(category: string, benef: string): SubjectRole[] {
    return this.relatedRoles.get(category + '-' + benef);
  }

  /* SUBJECT */
  setSubject(category: string, benef: string|number, role: Roles, subj: Subject, withPercentage: boolean) {
    if (typeof benef === 'number') {
      benef = benef.toString();
    }
    const relatedVector = this.getSubjectList(category, benef) ? this.getSubjectList(category, benef) : [];
    if (relatedVector != null) {
      if (relatedVector.length > 0) {
        let flag = false;
        relatedVector.map((s) => {
          if (s.subject.objectId === subj.objectId) {
            s.subject = subj;
            flag = true;
          }
        });
        if (!flag) {
          relatedVector.push({subject: subj, percentage: null, roleCode: role, withPercentage});
        }
      } else {
        relatedVector.push({subject: subj, percentage: null, roleCode: role, withPercentage});
      }
    }
    this.relatedSubject.set(category + '-' + benef, relatedVector);
  }

  setRelatedSubjectList(category: string, benef: string|number, relatedVector: RelatedSubject[]) {
    this.relatedSubject.set(category + '-' + benef, relatedVector);
  }

  createEmptyRelatedSubjectList(category: string, benef: string) {
    this.relatedSubject.set(category + '-' + benef, []);
  }

  getSubjectList(category: string, benef: string): RelatedSubject[] {
    return this.relatedSubject.get(category + '-' + benef);
  }

  getSubjectListFilterByRole(category: string, benef: string, role: string): RelatedSubject[] {
    let foundSubjects = this.relatedSubject.get(category + '-' + benef);
    if (!!foundSubjects) {
      foundSubjects = foundSubjects.filter((s) => s.roleCode === role);
    }
    return foundSubjects;
  }

  /* PERCENTAGE */
  setPercentageOfSubj(cat, ben, subj, perc) {
    this.relatedSubject.get(cat + '-' + ben).map((s) => {
      if (s.subject.objectId === subj) {
        s.percentage = perc;
      }
    });
  }

  getPercentageBySubj(cat, ben, subj): string {
    const findSubj = this.relatedSubject.get(cat + '-' + ben).find((s) => s.subject.objectId === subj);
    if (!!findSubj) {
      return findSubj.percentage;
    } else {
      return null;
    }
  }

  /* DELETE */
  deleteBenef(category: string, benef: string) {
    this.relatedSubject.delete(category + '-' + benef);
  }

  deleteRelatedSubject(category: string, benef: string, subjectId: string) {
    let relatedVector = this.getSubjectList(category, benef) ? this.getSubjectList(category, benef) : [];
    if (relatedVector != null) {
      if (relatedVector.length > 0) {
        relatedVector = relatedVector.filter((s) => s.subject.objectId !== subjectId);
      }
    }
    this.relatedSubject.set(category + '-' + benef, relatedVector);
  }

  /* REQUEST */
  getRelatedSubjectRequest(category: string, benef: string): any[] {
    const relatedVector = this.getSubjectList(category, benef) ? this.getSubjectList(category, benef) : [];
    const requestVector: any[] = [];
    if (relatedVector != null && relatedVector.length > 0) {
      relatedVector.forEach((s) => {
        const policyRole = this.getPolicyRole(s.subject.objectId);

        const rs = {
          objectId: null,
          documentDate: policyRole.docSubject.documentDate,
          physicalLegal: s.subject.personType.codice,
          percentageParty: this.getPercentageNormalized(s.percentage),
          documentType: policyRole.docSubject.documentType,
          documentNumber: policyRole.docSubject.documentNumber,
          maxIstances: policyRole.maxInstances,
          authorityRelease: policyRole.docSubject.authorityRelease,
          party: {
              mainParty: null,
              ext: null,
              assetInstanceName: null,
              partyCode: null,
              specializedPartyCode: null,
              partyRole: s.roleCode,
              extraInformations: this.fill(s.subject),
              objectID: s.subject.objectId,
              quoteID: null
          },
          expirationDate: policyRole.docSubject.expirationDate,
          locationRelease: policyRole.docSubject.locationRelease
        };

        requestVector.push(rs);
      });
    }

    return requestVector;
  }

  fill(subject: Subject): string {
    if (subject.name != null) {
      return subject.surname + ' ' + subject.name;
    } else if (subject.denomination != null) {
      return subject.denomination;
    } else {
      return null;
    }
  }

  /* POLICYROLE */
  createPolicyRole(subject: Subject, role: Roles) {
    const policyRole = new PolicyRole(role, subject);

    if (this.isDocEmpty(policyRole.docSubject)) {
      this.setDefaultDocFromAnag(policyRole);
    }
    return policyRole;
  }

  isDocEmpty(docSubject: DocSubject) {
    return docSubject == null ||
          (docSubject.authorityRelease == null &&
           docSubject.documentDate == null &&
           docSubject.documentNumber == null &&
           docSubject.documentType == null &&
           docSubject.expirationDate == null &&
           docSubject.locationRelease == null &&
           docSubject.objectId == null);
  }

  setDefaultDocFromAnag(policyRole: PolicyRole) {
    console.log('Calling full registry service');
    this.getSubjectFromAnag(policyRole.objectId, this.lifeSessionService.idPvManagement).toPromise().then((element) => {
      const sbj = this.lifeRoleService.getStoredSubject(policyRole.objectId);
      sbj.document = element.subject.document;
      this.lifeRoleService.storeSubject(sbj);
      console.log('Element full anag: ', element);

      if (element.subject && element.subject.document) {
        policyRole.docSubject.authorityRelease =
          element.subject.document.authoritiesRelease ? element.subject.document.authoritiesRelease.descrizione : null;
        policyRole.docSubject.documentDate = element.subject.document.releaseDate;
        policyRole.docSubject.documentNumber = element.subject.document.documentNumber;
        policyRole.docSubject.documentType =
          element.subject.document.documentType ? element.subject.document.documentType.codice : null;
        policyRole.docSubject.expirationDate = element.subject.document.expirationDate;
        policyRole.docSubject.locationRelease = element.subject.document.locationsRelease;
        policyRole.docSubject.objectId = element.subject.document.objectId;
      }
    });
  }

  getSubjectFromAnag(idSubj: any, idNodo: string): Observable<any> {
    if (!idSubj || !idNodo) {
      return of(null);
    }
    return this.httpClient.get(this.lifeSessionService.baseApiUrl + '/anag/subject/' + idSubj + '?idNode=' + idNodo)
    .pipe(
      tap((subjectFromAnag) => {
        // salvo il soggetto recuperato in una mappa
        this.lifeRoleService.storeSubject(subjectFromAnag.subject);
      })
    );
  }

  buildOtherPolicyRolesConverted(policyRolesToCheck: RelatedSubject[]): any[] {
    const roles = this.buildOtherPolicyRoles(policyRolesToCheck);
    const vectorOfConvertedPR = [];

    if (!!roles && roles.length > 0) {
      roles.forEach((pr) => {
        vectorOfConvertedPR.push({
          partyRole: pr.partyRole,
          objectId: pr.objectId,
          dateOfBirth: pr.dateOfBirth,
          personType: pr.personType,
          maxInstances: pr.maxInstances,
          showPercentage: pr.showPercentage,
          minPercentage: pr.minPercentage,
          agencyId: pr.agencyId,
          linkedSubjectRoles: null
        });
      });
    }

    return vectorOfConvertedPR;

  }

  buildOtherPolicyRoles(policyRolesToCheck: RelatedSubject[]): any[] {
    const othersPolicyRoles = [];
    if (!!policyRolesToCheck && policyRolesToCheck.length > 0) {
      policyRolesToCheck.map((el) => {
        const policyRoleFound: PolicyRole = this.getPolicyRole(el.subject.objectId, el.roleCode);
        if (policyRoleFound) {
          othersPolicyRoles.push(policyRoleFound);
        } else {
          console.error('PolicyRole' + el.subject.objectId + ' not found!');
        }
      });
    }

    return othersPolicyRoles;
  }

  /* SERVICES */
  getRelatedRoles(benef: PolicyRole, category: string, isFromPreventive): Observable<any> {
    let request: any = {};
    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv
    };
    request = {
      subjectRole: {
        partyRole: benef.partyRole,
        objectId: benef.objectId,
        dateOfBirth: benef.dateOfBirth,
        personType: benef.personType,
        maxInstances: benef.maxInstances,
        showPercentage: benef.showPercentage,
        minPercentage: benef.minPercentage,
        agencyId: benef.agencyId,
        linkedSubjectRoles: this.buildOtherPolicyRolesConverted(this.getSubjectList(category, benef.objectId)),
      },
      productCode: this.policyService.mainProposal.quote.product.code,
      managementNodeId: this.lifeSessionService.idPvManagement

    };

    if (isFromPreventive) {
      return of();
    } else {
      return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/role/linkedRoles', request, { headers });
    }

  }

  checkRole(subject: Subject, role: Roles, category: string, benef: string, isFromPreventive: boolean): Observable<any> {

    const policyRoleFound: PolicyRole = this.getPolicyRole(subject.objectId, role);

    let request: any = {};
    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv
    };
    request = {
      operationCode: '_EMPRO',
      roleToCheck: {
        partyRole: policyRoleFound.partyRole,
        objectId: policyRoleFound.objectId,
        dateOfBirth: policyRoleFound.dateOfBirth,
        personType: policyRoleFound.personType,
        name: policyRoleFound.name,
        surname: policyRoleFound.surname,
        companyName: policyRoleFound.companyName,
        agencyId: policyRoleFound.agencyId,
        docSubject: policyRoleFound.docSubject
      },
      othersPolicyRoles: this.buildOtherPolicyRoles(this.getSubjectList(category, benef)),
      managementNodeId: this.lifeSessionService.idPvManagement

    };

    if (isFromPreventive) {
      return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/estimate/roles/checks', request, { headers });
    } else {
      return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/roles/checks', request, { headers });
    }



  }

  checkAllRoles(haveToCheck: boolean, isFromPreventive): Observable<any> {
    if (haveToCheck) {
      const requestBenecifiaries = [];

      this.relatedSubject.forEach((relatedRoles, key) => {
        const benefCode = key.split('-')[1];
        // evita la reference del soggetto trovato con il .find()
        // const findBenef = this.getPolicyRole(benefCode, this.BENEFICIARY_ROLE_CODE);
        const benefSbj = this.lifeRoleService.getStoredSubject(benefCode);
        const findBenef = new PolicyRole(this.BENEFICIARY_ROLE_CODE, benefSbj);
        const related = [];
        if (!!relatedRoles && relatedRoles.length > 0) {
          relatedRoles.forEach((s) => {
            const relatedSbj = this.lifeRoleService.getStoredSubject(s.subject.objectId);
            const relatedPolicyRole = new PolicyRole(s.roleCode, relatedSbj);
            if (relatedPolicyRole) {
              relatedPolicyRole.showPercentage = s.withPercentage;
              relatedPolicyRole.percentage = +this.getPercentageNormalized(s.percentage);
              related.push(relatedPolicyRole);
            } else {
              console.error('policyRole ' + s.subject.objectId + ' not found');
            }
          });
        }

        findBenef.linkedSubjectRoles = related;

        requestBenecifiaries.push(findBenef);
      });

      const headers = {
        RGI_executionId: this.lifeSessionService.executionId,
        RGI_idPv: this.lifeSessionService.idPv
      };
      const request = {
        operationCode: '_EMPRO',
        policyRoles: requestBenecifiaries,
        managementNodeId: this.lifeSessionService.idPvManagement
      };

      if (isFromPreventive) {
        return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/estimate/roles/checksAll', request, { headers });
      } else {
        return this.httpClient.post(this.lifeSessionService.baseApiUrl + '/v2/life/roles/checksAll', request, { headers });
      }

    } else {
      return of({ partiescongruent: true, errorMessages: [] });
    }
  }

  public getPercentageNormalized(percentage: string): string {
     return !!percentage ? percentage.replace(',', '.').trim() : percentage;
  }

  /* CHECK */
  checkPercentage(): string[] {
    const errors = [];
    this.relatedSubject.forEach((value, key) => {
      if (!!value && value.length > 0) {
        let withPerc = true;
        let totPerc = 0;
        const relatedRoles = this.relatedRoles.get(key);
        value.forEach((subj) => {
          const foundRole = relatedRoles.find(role => role.partyRole === subj.roleCode);
          const minPerc = !!foundRole ? foundRole.minPercentage : null;
          const benefName = this.findBenefDescrByObjID(key.split('-')[1]);
          if (subj.withPercentage) {
            const subjPercentageNormalized = this.getPercentageNormalized(subj.percentage);
            if (!subjPercentageNormalized) {
              errors.push(
                this.translate.getImmediate(
                  'lic_effective_holder_perc_req',
                  {linkedName: subj.subject.nominative, benefName}
                ));
            }

            if (!!minPerc && subjPercentageNormalized && (+subjPercentageNormalized < +minPerc)) {
              // validations for the releated roles, checks the min percentage which comes from the systemProp configuration
              errors.push(
                this.translate.getImmediate(
                  'lic_effective_holder_perc',
                  {linkedName: subj.subject.nominative, benefName, linkedPerc: minPerc}
                ));
            }
            totPerc += !!subjPercentageNormalized ? +subjPercentageNormalized : 0;
          } else {
            withPerc = false;
          }
        });
        if (withPerc && totPerc > 100) {
          const splitted = key.split('-');
          const msg = errors.find(error => error.endsWith(this.descrBenMap.get(splitted[0])));
          if (!msg) { // check to not duplicate messages
            errors.push(
              this.translate.getImmediate(
                'lic_beneficiary_perc_is_over',
                {benefRole: this.translate.getImmediate('lic_Beneficiary'), benefCat: this.descrBenMap.get(splitted[0])}
              ));
          }
        }
      }
    });
    return errors;
  }

  protected findBenefDescrByObjID(objId: string) {
    const releatedBenef = this.getPolicyRole(objId);
    if (!!releatedBenef) {
      if (!!releatedBenef.companyName) {
        return releatedBenef.companyName;
      } else {
        return releatedBenef.name + ' ' + releatedBenef.surname;
      }
    } else {
      return '';
    }
  }

  reloadFromAuth() {
    const vectorOfBenef = [];
    if (!!this.policyService.mainProposal.proposal.beneficiaryData
      && !!this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData
      && this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData.length > 0
    ) {
      this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData.forEach((cat) => {
        if (cat.choiceTypeBeneficiary === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
          vectorOfBenef.push({
            category: cat.typeBeneficiary.codice,
            role: cat.beneficiaryLifeParty.party.partyRole,
            id: cat.beneficiaryLifeParty.party.objectID,
            linked: cat.beneficiaryLifeParty.linkedLifeParties
          });
        }
      });

      // ora ho il vettore con tutti i beneficiari e le informazioni che mi servono
      // chiamo il servizio dei related roles e ottengo la lista
      vectorOfBenef.forEach(benef => {
        const anagSubj = this.lifeRoleService.getStoredSubject(benef.id);
        const newBenef = this.createPolicyRole(anagSubj, Roles.BENEFICIARY);
        this.getRelatedRoles(newBenef, benef.category, this.policyService.isFromPreventive).subscribe((resp) => {
          if (!!resp) {
            this.setRelatedRoles(benef.category, newBenef.objectId, resp.subjectRole.linkedSubjectRoles);
          }
        });

        const vectorRelatedSubj = [];
        benef.linked.forEach((linkedSubj) => {
          const anagLinkedSubject = this.lifeRoleService.getStoredSubject(linkedSubj.party.objectID);
          vectorRelatedSubj.push({
            subject: anagLinkedSubject,
            percentage: linkedSubj.percentageParty,
            roleCode: linkedSubj.party.partyRole,
            withPercentage: linkedSubj.percentageParty != null
          });
        });
        this.setRelatedSubjectList(benef.category, benef.id, vectorRelatedSubj);
      });
    }
  }

  /* CEDOLA PAYMENT */
  setPaymentCedola(objectId: string, payment: Payments) {
    this.benefCedolaPayment.set(objectId, payment);
  }

  getPaymentCedola(objectId: string): Payments {
    return this.benefCedolaPayment.get(objectId);
  }

  getAllRelatedSubjects(): RelatedSubject[] {
    return [].concat.apply([], Array.from(this.relatedSubject.values()));
  }
}

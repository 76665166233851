import {DashboardRiskDetail} from './dashboard-risks-detail';

export class DashboardTotalRiskDetail {
    idDashBoard: string[] = [];
    intPurchaseCommAmt: number = Number(0);
    extPurchaseCommAmt: number = Number(0);
    intCollCommAmt: number = Number(0);
    extCollCommAmt: number = Number(0);
    sumIntPurchaseCollCommAmt: number = Number(0);
    sumExtPurchaseCollCommAmt: number = Number(0);

}

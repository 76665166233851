import {Inject, Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '@rgi/rx/auth';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PassPortalOperator} from '../typings';
import {RgiRxHttpTracingProvider} from '@rgi/rx/http';

@Injectable()
export class RgiHeadersInterceptor implements HttpInterceptor {

  constructor(private userService: UserService,  private executionIdProvider: RgiRxHttpTracingProvider) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setRgiHeaders(req));
  }

  setRgiHeaders(req: HttpRequest<any>): HttpRequest<any> {
    if (this.userService.isLoggedIn()) {
      const user: any = this.userService.getUser<PassPortalOperator>();
      const headers: {
        RGI_IDCDL?: string,
        RGI_USER?: string,
        RGI_IDPV?: string,
        RGI_EXECUTIONID?: string
      } = {
        RGI_IDPV: user.salePoint.objectId,
        RGI_USER: user.username,
        RGI_EXECUTIONID: this.executionIdProvider.traceId
      };
      if (user.liquidationCentre) {
        headers.RGI_IDCDL = user.liquidationCentre.identification;
      }
      return req.clone({
        setHeaders: headers
      });
    }
    return req;
  }
}

@Injectable()
export class RgiLangInterceptor implements HttpInterceptor {

  constructor(private translationService: RgiRxTranslationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setRgiHeaders(req)).pipe();
  }

  setRgiHeaders(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        RGI_locale: this.translationService.currentLanguage,
      }
    });
  }
}


export const RGI_RX_PASSPORTAL_INTERCEPTORS = [
  {provide: HTTP_INTERCEPTORS, multi: true, useClass: RgiHeadersInterceptor},
  {provide: HTTP_INTERCEPTORS, multi: true, useClass: RgiLangInterceptor}
];

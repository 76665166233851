import {Inject, Injectable} from '@angular/core';
import {LogEvent, LoggerConfig, LoggerStoreHandler, LogLevel, RGI_RX_LOGGER_CONFIG} from './logging-api';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class StreamLoggerStoreHandler implements LoggerStoreHandler {

  private readonly _events = new BehaviorSubject<LogEvent[]>([]);
  private readonly limit: number = 10;

  constructor(@Inject(RGI_RX_LOGGER_CONFIG) private config: LoggerConfig) {
    this.limit = config.limit ? config.limit : this.limit;
  }


  push(message: any, level: LogLevel, ...optional) {
    const value = this._events.getValue();
    value.unshift({
      created: new Date(),
      message,
      options: [...optional],
      level
    });
    const logEvents = value.slice(0, this.limit);
    this._events.next(logEvents);
  }

  clear(level: LogLevel) {
    const logEvents = this._events.getValue()
      .filter(value => value.level !== level);
    this._events.next(logEvents);
  }

  clearAll() {
    this._events.next([]);
  }

  logs$(level?: LogLevel): Observable<LogEvent[]> {
    return this._events.asObservable()
      .pipe(
        map(e => this.filterByLevel(e, level))
      );

  }

  logs(): LogEvent[] {
    return this.filterByLevel(this._events.getValue());
  }


  takeLast$(): Observable<LogEvent> {
    return this._events.asObservable()
      .pipe(
        mergeMap(value => of(value[0])),
        filter(value => !!value && value.level >= this.config.level)
      );
  }

  private filterByLevel(events: LogEvent[], level?: LogLevel): LogEvent[] {
    return events.filter(e => e.level >= (level !== undefined ? level : this.config.level));
  }
}

import {AnagApiAddress, AnagEntityIta} from '@rgi/anag';

export interface OTPDocument  {
  id: string;
  idPol: string;
  idMov: string;
  idDoc: string;
  documentTitle: string;
  idDossier: string;
  canceled: boolean;
}

export class AnagDocument {
  authoritiesRelease: AnagEntityIta;
  documentAddress: AnagApiAddress;
  documentNumber: string;
  documentType: AnagEntityIta;
  expirationDate: Date;
  extensionSubject: AnagExtensionSubject;
  locationsRelease: string;
  objectId: string;
  releaseDate: Date;
  drivingLicense: Array<AnagDrivingLicense>;
}

export class AnagDrivingLicense {
  idPartyDoc: string;
  code: string;
  descr: string;
  idTypeDrivingLicense: number;
  bdriverQualifCard: boolean;
  dstartDrivingLicense: Date;
  dendDrivingLicense: Date;
}

export class AnagDrivingLicenseTypeList {
  abbreviation: string;
  codCountry: string;
  code: string;
  description: string;
  dqcManagement: boolean;
  endDate: Date;
  idDrivingLicenseType: number;
  minimumAgeWithDQC: number;
  minimumAgeWithoutDQC: number;
  startDate: Date;
}

  class AnagExtensionSubject {
  properties: Array<{
    chiave: string,
    valore: string
  }>;
}


export interface DossierDocument {
  idDocumento: string;
  mimeType: string;
  data: string; // Supponendo che "data" contenga una rappresentazione in base64 del PDF
  type: string;
  status: 'COUNTERSIGNED' | 'ERROR';
}

type DossierStaturType = 'DORMANT' | 'COMPLETED' | 'RUNNING' | 'PENDING_COMPLETION' | 'ABORTED' | 'OTHER';

export interface DossierStatus {
  status: DossierStaturType;
  statusMessage: string;
  documents: DossierDocument[];
}

export enum DossierStates {
  DORMANT = 'DORMANT',
  COMPLETED = 'COMPLETED',
  RUNNING = 'RUNNING',
  PENDING_COMPLETION = 'PENDING_COMPLETION',
  ABORTED = 'ABORTED',
  OTHER = 'OTHER'
}

export enum OTP_ABILITATION {
  ENABLED = 'enable',
  DISABLED = 'disable'
}

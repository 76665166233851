<ng-container *ngIf="vertical">
  <ng-container *ngFor="let step of _steps; let i = index">
    <div class="rgi-ui-stepper-header">
      <ng-container *ngTemplateOutlet="stepHeader; context: {step: step, i: i, selectedIndex: selectedIndex, steps: steps, linear: linear, showIndex: showIndex}">
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="stepBody; context: {step: step, i: i, selectedIndex: selectedIndex}">
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="!vertical">
  <div class="rgi-ui-stepper-header">
    <ng-container *ngFor="let step of _steps; let i = index">
      <ng-container *ngTemplateOutlet="stepHeader; context: {step: step, i: i, selectedIndex: selectedIndex, steps: steps, linear: linear, showIndex: showIndex}">
      </ng-container>
    </ng-container>
  </div>
    <ng-container *ngFor="let step of _steps; let i = index">
      <ng-container *ngTemplateOutlet="stepBody; context: {step: step, i: i, selectedIndex: selectedIndex}">
      </ng-container>
    </ng-container>
</ng-container>

<!-- TEMPLATE -->

<ng-template #stepHeader let-step="step" let-i="i" let-selectedIndex="selectedIndex" let-steps="steps" let-linear="linear" let-showIndex="showIndex">

  <rgi-rx-step-header [state]="_getIndicatorType(i, step.state)"
    [optional]="step.optional"
    [label]="step.label"
    [stepLabel]="step.stepLabel"
    [errorMessage]="step.errorMessage"
    [active]="selectedIndex === i"
    [index]="i+1"
    [showIndex]="showIndex"
    (click)="onStepHeaderClick($event, step)"
    (keydown)="_handleKeyDown($event, step)"
    [id]="_getStepHeaderId(i)"
    [attr.tabindex]="_getFocusIndex() === i ? 0 : -1"
    [attr.aria-posinset]="i + 1"
    [attr.aria-setsize]="steps.length"
    [attr.aria-selected]="selectedIndex === i"
    [attr.aria-label]="step.ariaLabel || null"
    [attr.aria-labelledby]="(!step.ariaLabel && step.ariaLabelledby) ? step.ariaLabelledby : null"
    [ngClass]="{'rgi-ui-disabled' : !step.selectable, 'rgi-ui-error' : step.hasError, 'rgi-ui-step-completed' : linear && step.completed}"
    [hasError]="step.hasError"
    [rgi-rx-qa]="_getStepHeaderId(i)" qa-type='stepper-step-header'
    [qa-value]="step.label ? step.label : '_dynamic_'"
  ></rgi-rx-step-header>

</ng-template>

<ng-template #stepBody let-step="step" let-i="i" let-selectedIndex="selectedIndex">

  <rgi-rx-step-body
    [step]="step"
    [active]="i === selectedIndex"
    [hidden]="i !== selectedIndex"
    [id]="_getStepContentId(i)"
    [attr.aria-labelledby]="_getStepLabelId(i)"
    [rgi-rx-qa]="_getStepContentId(i)" qa-type='stepper-step-body'
  ></rgi-rx-step-body>

</ng-template>

import {Injectable} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { AnagUtilsService } from "@rgi/anag";

@Injectable({
  providedIn: 'root'
})
export class AnagUtilsServiceExt extends AnagUtilsService {

  constructor(
  ) {
    super();
  }

  checkNormalize(isNormalizeOptional: boolean, ubicationForm: UntypedFormGroup ) {
    return !this.isNormalizeOptional(isNormalizeOptional, ubicationForm) && !ubicationForm.get('addressForm').get('formatAddress').value;
  }

  isNormalizeOptional(isNormalizeOptional: boolean, ubicationForm: UntypedFormGroup) {
    let country = ubicationForm.get('addressForm').get('countryCode').value;
    return isNormalizeOptional || ( country !== 'ES' && country !== 'CH' && country!== 'PT');
  }

}

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { PV_TOKEN } from '../models/consts/lpc-consts';

@Injectable()
export class KarmaAuthService {
  public token: any;
  public ssoToken: string;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(PV_TOKEN.KARMA_ENV) protected environment: any = {apiUrl: ''}
    ) {
  }

  private getSSOAuth(): Observable<any> {
    if (!this.environment || !this.environment.apiUrl) {
      return EMPTY;
    }
    return this.httpClient.post(this.environment.apiUrl + '/auth/authorization',
    this.environment.credentials)
      .pipe(
        map((prj1: any) => {
            this.ssoToken = prj1.authorization.accessToken;
            this.token = prj1;
        })
      );
  }

  getToken(): string {
    return this.token;
  }


  getSSOToken(): string {
    return this.ssoToken;
  }

  initSSO(): Observable<any> {
    return this.getSSOAuth();
  }

 setToken(value: string) {
    this.token = value;
  }

  setSSOToken(value: string) {
    this.ssoToken = value;
  }
}

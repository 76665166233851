import { SliderProperty } from '../lic-karma-funds/model/karma-fund';
import { EnumModel } from '../models/response.model';

export enum Roles {
  CONTRACTOR = '1',
  CEDENTE = '1',
  INSURED = '4',
  VINCOLATARIO = '5',
  EFFECTIVEHOLDER = '113',
  EXECUTOR = '112',
  DELEGATE = '112',
  LEGAL_GUARDIAN = '300008',
  THIRDPAYER = '300016',
  BENEFICIARY = '100',
  COSIGNED = '400000',
  EMPLOYER= '300002',
  THIRDREFERENT = '300015',
  ADERENTE = '110',
  EFFECTIVEHOLDER_BENEF = '300018',
  EFFECTIVEHOLDER_THIRDPAYER = '300017',
  COMPANY_PENSION_FORM = '300011',
  EFFECTIVEHOLDER_CLAIMBENEF = '300018',
  EXECUTOR_CLAIMBENEF = '300019',
  THIRDPAYER_CLAIMBENEF = '300020',
  PARTY_SUBJECT_TO_TAXATION = '300010',
  SECOND_INSURED = '300022'
}

export enum PaymentFrequencyValue {
  UNICO = '3'
}

export const CAC = '300402';

export enum SaveActions {
  SAVE_FROM_AUTH = 'saveFromAuth',
  SAVE_AUTH = 'saveAuth',
  SAVE_CONTEXTUAL = 'saveContextual',
  SAVE_ACCEPT = 'saveAccept',
  SAVE_PROPOSAL = 'saveProposal',
  SAVE_ACCEPT_PROP_ISSUE = 'saveAcceptPropIssue',
  SAVE_ACCEPT_POLICY_ISSUE = 'saveAcceptPolicyIssue',
  SAVE_PREVENTIVE = 'savePreventive',
  SAVE_PROPOSAL_CAC = 'saveProposalCac',
  SAVE_ACCEPT_PROP_ISSUE_CAC = 'saveAcceptPropIssueCac',
  SAVE_UNDEFINED_PROPOSAL = 'saveUndefinedProposal'
}

export enum SubjectConstants {
  PHYSICAL_SUBJECT = '1',
  JURIDICAL_SUBJECT = '2'
}

export enum PaymentTypeOption {
  NEXT_INST = '2',
  FIRST_INST = '1'
}

export enum LicCustomProperties {
  PERCENTUALE_ATTIVAZIONE_TOOL = 'PEATTO',
  RATA_FIRMA_EQUAL_RATA_SUCCESSIVA = '_DTP1S',
  RATA_FIRMA = '_DTPG1',
  RATA_SUCCESSIVA = '_DTPAG',
  CALCOLO_VOLATILITA = '_CALVO',
  CALCOLO_SUSTAINABILITY = '_CALSO',
  MODALITA_INVESTIMENTO = '_MODIN',
  MUTUO_COINTESTATO = 'MUTCOI',
  THIRD_REF_VISIBILITY = '_REFTE',
  CONTRACTING_ENTITY_VISIBILITY = 'ABGEVI',
  SEVERE_DISABILITY = '_BENDG',
  IRREVOCABLE_BENEF = '_BEIRR',
  REGOLAMENTO_41 = '_REG41',
  PREVALORIZZAZIONE_SDD= '_PAYSD',
  LIFE_POLICY_VARIABLE_GROUPING = '_LPVGR',
  POSITION_NUMBER_FOR_BENEFICIARIES = '_POSNU',
  LIFE_POLICY_COVERTURE_GROUPING = '_GRCOV'
}

export const PROPERTY_VALUE = {
  _MODIN: {
    /** @value 'PERC' */
    PERCENTAGE: 'PERC' as SliderProperty,
    /** @value 'IMP' */
    AMOUNT: 'IMP' as SliderProperty,
    /** @value 'ENTR' */
    BOTH: 'ENTR' as SliderProperty,
    /** @value 'ALLSAM' */
    ALLSAME: 'ALLSAM' as SliderProperty
  },
  _CALSO: {
    /** @value 'NOSOST' */
    NO_SUSTAINABILITY: 'NOSOST'
  }
  // TODO add property values here
};

export enum ToolCode {
  SCHEDULED_PREMIUM = '102', // VERSAMENTO PROGRAMMATO
  SCHEDULED_PARTIAL_WITHDRAWAL = '202', // RISCATTO PROGRAMMATO
  LOCK_IN = '301',
  STOP_LOSS = '302',
  PROGRESSIVE_SWITCH = '306',
  RISK_REDUCTION = '600',
  PERIODIC_COUPON = '789'
}

// da aggiornare con i nuovi codici di PP
export enum PPToolCode {
  SCHEDULED_PREMIUM = '_QUIET', // VERSAMENTO PROGRAMMATO
  SCHEDULED_PARTIAL_WITHDRAWAL = '_SCHPS', // RISCATTO PROGRAMMATO
  LOCK_IN = '_LCKIN',
  STOP_LOSS = '_STPLS',
  PROGRESSIVE_SWITCH = '_SCHSW',
  RISK_REDUCTION = '_DERIS',
  PERIODIC_COUPON = '_CEPER'
}

export type ToolCodeIdType =
  | ToolCode.SCHEDULED_PREMIUM
  | ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL
  | ToolCode.LOCK_IN
  | ToolCode.STOP_LOSS
  | ToolCode.PROGRESSIVE_SWITCH
  | ToolCode.RISK_REDUCTION
  | ToolCode.PERIODIC_COUPON;

export type ToolCodeType =
  | PPToolCode.SCHEDULED_PREMIUM
  | PPToolCode.SCHEDULED_PARTIAL_WITHDRAWAL
  | PPToolCode.LOCK_IN
  | PPToolCode.STOP_LOSS
  | PPToolCode.PROGRESSIVE_SWITCH
  | PPToolCode.RISK_REDUCTION
  | PPToolCode.PERIODIC_COUPON;


export const operationCodeIdToOperationCode = new Map<ToolCodeIdType, ToolCodeType>(
  [
    [ToolCode.PROGRESSIVE_SWITCH, PPToolCode.PROGRESSIVE_SWITCH],
    [ToolCode.SCHEDULED_PREMIUM, PPToolCode.SCHEDULED_PREMIUM],
    [ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL, PPToolCode.SCHEDULED_PARTIAL_WITHDRAWAL],
    [ToolCode.LOCK_IN, PPToolCode.LOCK_IN],
    [ToolCode.STOP_LOSS, PPToolCode.STOP_LOSS],
    [ToolCode.RISK_REDUCTION, PPToolCode.RISK_REDUCTION],
    [ToolCode.PERIODIC_COUPON, PPToolCode.PERIODIC_COUPON]
  ]
);


export enum BeneficiaryCathegory {
  VITA = '1',
  MORTE = '2',
  CEDOLA = '3'
}

export const BENEFICIARY_CHOICE = {
  ANAGRAFICAL_BENEF: '_ALTRO',
  FREE_TEXT_BENEF: '_TSLIB'
};

/**
 * Represents the possible values for the `FactorToolCode` type.
 *
 * @remarks
 * This type is a union of several `FACTOR_TOOL_CODE` values, which are used as codes for various tool factors in a life issue card.
 */
export type FactorToolCode =
  FACTOR_TOOL_CODE.FIRSTTERM_CODE |
  FACTOR_TOOL_CODE.EFFECTDATE_CODE |
  FACTOR_TOOL_CODE.FREQUENCY_CODE |
  FACTOR_TOOL_CODE.PAYMENTMODES_CODE |
  FACTOR_TOOL_CODE.EXPIRATIONDATE_CODE |
  FACTOR_TOOL_CODE.BENEFICIARY_ID_CODE |
  FACTOR_TOOL_CODE.MIN_THRESHOLD_CODE |
  FACTOR_TOOL_CODE.MAX_THRESHOLD_CODE |
  FACTOR_TOOL_CODE.OCCURRENCE_NUMBER_CODE |
  FACTOR_TOOL_CODE.SURRENDER_AMOUNT_CODE |
  FACTOR_TOOL_CODE.MINIMAL_AMOUNT_CODE |
  FACTOR_TOOL_CODE.AMOUNT_CODE |
  FACTOR_TOOL_CODE.CURRENCY_CODE |
  FACTOR_TOOL_CODE.IBAN_CODE |
  FACTOR_TOOL_CODE.FIXED_FEES_CODE |
  FACTOR_TOOL_CODE.PERIODIC_DISCOUNT_PERCENTAGE_CODE |
  FACTOR_TOOL_CODE.HOLDER_CODE
;

export enum FACTOR_TOOL_CODE {
  FIRSTTERM_CODE = '_1TERM',
  EFFECTDATE_CODE = '_EFFOP',
  FREQUENCY_CODE = '_OPZFR',
  PAYMENTMODES_CODE = '_OPZPM',
  EXPIRATIONDATE_CODE = 'expirationDate',
  BENEFICIARY_ID_CODE = '_OPBEN',
  MIN_THRESHOLD_CODE = '_MINTS',
  MAX_THRESHOLD_CODE = '_MAXTS',
  OCCURRENCE_NUMBER_CODE = '_NUMOC',
  SURRENDER_AMOUNT_CODE = '_AMTOP',
  MINIMAL_AMOUNT_CODE = '_MALMT',
  AMOUNT_CODE = 'amount',
  CURRENCY_CODE = 'currency',
  IBAN_CODE = '_CIBAN',
  FIXED_FEES_CODE = 'fixedFees',
  PERIODIC_DISCOUNT_PERCENTAGE_CODE = '_PCOUP',
  HOLDER_CODE = 'holder'
}
export enum FACTOR_TYPE {
  LIST = '0',
  NUMERIC = '1',
  STRUCT = '2',
  BOOL = '3',
  DATE = '4',
  STRING= '5'
}

export enum FACTOR_ERROR_KEYS {
  CODE_MISSING = 'codeMissing',
  REQUIRED = 'required',
  ERROR_MSG = 'errorMessage',
  IBAN = 'iban',
}


/**
 * Frequency codes by NumRate
 *  Frazionamento | NumRate Pass | IDkelia da associare
 *  Annuale	      |       1	     |           12
 *  Semestrale	  |       2      |          	6
 *  Trimestrale	  |       4	     |            7
 *  Bimestrale	  |       6	     |            9
 *  Mensile	      |       12	   |            8
 */
export enum Frequency {
  MENSILE = 12,
  TRIMESTRALE = 4,
  SEMESTRALE = 2,
  ANNUALE = 1,
  BIMESTRALE = 6
}

export enum TypeFractionation {
  RATEIZZATO = 1
}

export enum PaymentTypeID {
  SDD = 5,
  BONIFICO = 4,
  ASSEGNO = 2
}

export enum PaymentTypeCode {
  SDD = 'DDO',
  BONIFICO = 'BNKTRN',
  ASSEGNO = 'CHECK'
}

export enum QuestionnaireCodes {
  FINANCIAL = 'FIN',
  SUSTAINABILITY = 'SOST',
  LIFE_GENERIC_KEY = 'FAKE-DA'
}


export enum ExtensionProperty {
  IS_QUOTATION_MODE_ON = 'is_quotation_mode_on',
  IS_SETINITIALSTATE_UNITS_ON = 'is_setinitialstate_units_on',
  ISSUE_INVESTMENTS = 'ISSUE_INVESTMENTS',
  ISSUE_TOOLS = 'ISSUE_TOOLS',
  ISSUE_PIPTRANSFER = 'ISSUE_PIPTRANSFER',
  ISSUE_PIPCONTRIBUTIONS = 'ISSUE_PIPCONTRIBUTIONS',
  OLD_QUOTE_NUMBER = 'old_quote_number',
  REINVEST_LIQ = 'REINVEST_LIQ',
  INTERMEDIATE_SAVE = 'intermediate_save',
  SNAPSHOT_SAVED = 'snapshot_saved',
  QUOTATION_VISITED = 'quotation_visited',
  REINVESTMENT_INITIALIZED = 'reinvestment_initialized',
  ISSUE_ASSIGMENT_HOLDER = 'ISSUE_ASSIGNMENT_HOLDER',
  ISSUE_MOV = 'issue_mov',
  SHOW_COMMON_RISK = 'show_common_risk',
  RGI_CURRENT_CONTEXT = 'RgiCurrentContext',
  ISSUE_INVESTMENTS_PLAN = 'ISSUE_INVESTMENTS_PLAN',
  BACKDATING_NOT_ALLOWED = 'backdating_not_allowed'
}

export enum OperationCode {
  VERSAMENTO_AGGIUNTIVO = '_VAGG',
  AVVISO_DI_SCADENZA_VITA = '_AVVSC',
  ANNULLO_RISCATTO_PARZIALE = '_ARISP',
  ANNULLA_RISCATTO_TOTALE = '_ARIST',
  RIATTIVAZIONE_PAGAMENTO_PREMI = '_RIAPU',
  CAMBIO = '_SWITC',
  ANNULLO_APPENDICE = '_ANAPP',
  EMISSIONE_POLIZZA = '000001',
  PREVENTIVO_VELOCE = 'PREVEL',
  RISERVA = '_RISER',
  RECESSO = '_ANREC',
  SINISTRO_MORTE = '_SIMOR',
  SINISTRO_MORTE_SENZA_PAGAMENTI = '_SINSS',
  CAMBIO_CONTRAENZA = '_VCONT',
  MODIFICA_FIGURE_ANAGRAFICHE = '_VFIGU',
  CAMBIO_BENEFICIARI = '_VBENE',
  CAMBIO_RECAPITO_POLIZZA = '_VRECP',
  CAMBIO_MODALITÀ_PAGAMENTO_VITA = '_VMODP',
  APERTURA_VINCOLO = '_AVINC',
  APERTURA_PEGNO = '_APEGN',
  CHIUSURA_VINCOLO = '_CVINC',
  CHIUSURA_PEGNO = '_CPEGN',
  MODIFICA_DOCUMENTI = '_VDOCU',
  TRASFERIMENTO_POLIZZA = '_TRFNO',
  QUIETANZAMENTO = '_QUIET',
  INSER_ESCL_CONVENZIONI = '_INESC',
  MANCATO_PERFEZIONAMENTO = '_ANPSE',
  REVOCA_INCASSO = '_REINC',
  EMETTI_PROPOSTA_VITA = '_EMPRO',
  ANNULLA_PROPOSTA_VITA = '_ANPRO',
  MODIFICA_RECUPERA_PROPOSTA = '_MODPR',
  ANNULLO_MANCATO_PERFEZIONAMENTO = '_AANPS',
  ANNULLO_RECESSO = '_AANRE',
  ANNULLA_SINISTRO_MORTE = '_ASIMO',
  RISCATTO_TOTALE = '_RISTO',
  RIVALUTAZIONE = '_RIVAL',
  RISCATTO_PARZIALE = '_RISPA',
  ATTIVAZIONE_TOOL = '_ATOOL',
  MODIFICA_TOOL = '_MTOOL',
  DISATTIVAZIONE_TOOL = '_DTOOL',
  ANNULLO_RATA = '_ARATA',
  SINISTRO_PER_MALATTIA_GRAVE = '_SIMAG',
  ANNULLO_VERSAMENTO_AGGIUNTIVO = '_AVAGG',
  ANNULLO_SINISTRO_SENZA_SEGUITO = '_ASINS',
  ATTRIBUZIONE_REBATES_UL = '_REBUL',
  CAMBIO_COMBINAZIONE = '_CCOMB',
  VARIAZIONE_BENEFICIARI_LIQUIDAZIONE = '_VBLIQ',
  MANAGEMENT_FEES = '_MFEE',
}

export enum Ramo {
  PRIMO = 'I',
  TERZO = 'III'
}

export enum SystemProperties {
  PREVAL_DELEGATE = 'PrevalorizationDelegate',
  PREVAL_THIRDPAYER = 'PrevalorizationThirdPayer',
  PREVAL_LEGALGUARDIAN = 'PrevalorizationLegalGuardian',
  PORTFOLIO_CONTRACTS = 'PortfolioContactsManagement',
  LIFE_CONTRACTS = 'LifePolicyContactsManagement',
  LIFE_CONTEXTUAL_POL_PROP = 'LifeContextualPolicyAndProposal',
  ACCEPT_PROP_IN_AUTH = 'AcceptProposalInAuthModif',
  ACCEPT_ISSUE_PROP_IN_AUTH = 'AcceptIssueProposalInAuthModif',
}

export type ENUM_KEYS = 'EPOSITIONNUMBER' | 'ETYPEBENEF';

export const ENUMS_KEYS: Record<ENUM_KEYS, { EN: string, IT: string }> = {
  EPOSITIONNUMBER: {EN: 'PVVBENEF.EPOSITIONNUMBER', IT: 'PVVBENEFICIARIO.EPOSITIONNUMBER'},
  ETYPEBENEF: {EN: 'PVVBENEF.ETYPEBENEF', IT: 'PVVBENEFICIARIO.ETIPOBENEFICIARIO'}
};

export interface BeneficiaryGroupingTypeObj {
  activeTypes: {
    code: BeneficiaryCathegory;
    description: string;
    shortDescription?: string;
  }[];
  types: EnumModel;
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pnc-reactive-advanced-select',
  templateUrl: './reactive-advanced-select.component.html',
  styleUrls: ['./reactive-advanced-select.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveAdvancedSelectComponent),
      multi: true
    }
  ]
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class ReactiveAdvancedSelectComponent
  implements OnInit, ControlValueAccessor {
  options: any[];
  selectedValue: any;

  private refresh: boolean;
  private privateItems: any[];
  @Input() firstElementValue: any;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() addEmptyOption = true;
  @Input() id: any;
  @Input() label: string;
  @Input() required: boolean;
  @Input() clearable = false;
  @Input() disable = false;
  @Output() changeValue = new EventEmitter();

  @Input()
  set items(options: any[]) {
    this.refresh = this.options !== options;
    this.options = options;
  }

  get items(): any[] {
    this.loadItems();
    return this.privateItems;
  }

  @Input() customCompareWith = undefined;
  @Input() customTrackByFn = undefined;
  propagateChange = (_: any) => {};
  onTouched = () => {};

  ngOnInit(): void {}

  private loadItems() {
    if (this.refresh) {
      this.privateItems = [...this.options];
      const obj = {};
      if (this.addEmptyOption) {
        if (this.bindValue) {
          obj[this.bindLabel] = '';
          obj[this.bindValue] = this.firstElementValue;
        } else {
          obj[this.bindLabel] = '';
        }
      }
      this.privateItems.unshift(obj);
      this.refresh = false;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  onChangeAction(event: any) {
    let value;

    if (this.bindValue) {
      value = this.selectedValue;
    } else {
      value = event[this.bindLabel] === this.firstElementValue
        ? this.firstElementValue
        : event;
    }
    this.propagateChange(value);
    this.changeValue.emit(value);

    /*if (this.bindValue) {
      this.propagateChange(this.selectedValue);
    } else {
      event[this.bindLabel] === this.firstElementValue
        ? this.propagateChange(this.firstElementValue)
        : this.propagateChange(event);
    }
    this.changeValue.emit(event); /*DA CONTROLLARE*/
  }

  getCustomTrackByFn() {
    return this.customTrackByFn
      ? this.chooseCustomTrackByFn.bind(this)
      : (item: any) => item;
  }

  chooseCustomTrackByFn = function(item: any) {
    return item[this.bindLabel] === this.firstElementValue
      ? this.firstElementValue
      : this.customTrackByFn(item);
  };

  getCustomCompareWith() {
    return this.customCompareWith
      ? this.customCompareWith.bind(this)
      : this.defaultCompareWith;
  }

  private defaultCompareWith = function(left, right) {
    if (this.trackByFn) {
      return this.trackByFn(left) === right;
    } else {
      return left === right;
    }
  };
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Section} from '../../models/domain-models/section';
import {UnitService} from './unit.service';
import {Unit} from '../../models/domain-models/unit';
import {UntypedFormGroup} from '@angular/forms';
import {Variable} from '../../models/domain-models/variable';
import {ApiPremiumSection} from '../../models/api-models/api-premium-section';
import {Subscription} from 'rxjs';
import {Message} from '../../models/message';
import {TranslateService} from '@ngx-translate/core';
import {VariablesService} from '../../variables/variables-service';

@Component({
  selector: 'mic-asset-unit',
  templateUrl: './asset-unit.component.html',
  styleUrls: ['./asset-unit.component.scss']
})
export class AssetUnitComponent implements OnInit, OnDestroy {
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() recalculateAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() validationMessagesAsset: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() updateUnitList: EventEmitter<Section[]> = new EventEmitter<Section[]>();
  @Output() unitSectionsChanged = new EventEmitter<Array<Section>>();

  @Input() sectionsPremium: Map<string, ApiPremiumSection>;
  @Input() viewUnitsBox: boolean;
  unitsBoxesRows: Array<Array<Unit>>;
  guaranteesForm: UntypedFormGroup;
  riskToSectionMap = new Map<string, Section>();
  firstUnitInSectionMap = new Map<string, string>();
  allUnits: Unit[] = new Array<Unit>();
  validationMessagesClause: Map<string, Array<string>> = new Map<string,
    Array<string>>();
  validationMessages = [];
  validationMessagesVariable: Map<string, Array<string>> = new Map<string,
    Array<string>>();
  messagesSubscription: Subscription;
  areaCode = 'UNIT';
  private unitsSections: Section[];
  private unitsNumberForRow = 4;

  constructor(
    protected unitService: UnitService,
    protected translate: TranslateService,
    protected variableService: VariablesService
  ) {
  }

  get unitSections(): Section[] {
    return this.unitsSections;
  }

  @Input()
  set unitSections(unitSections: Section[]) {
    if (unitSections) {
      this.unitsSections = unitSections;
      this.getUnitsAsset();
    }
  }

  ngOnInit() {
  }

  getUnitsAsset() {
    if (this.unitSections) {
      this.unitService.setUnitsAsset(this.unitSections);

      this.unitSections.forEach(section => {
        section.unitList.forEach(unit => {
          this.riskToSectionMap.set(unit.risk.id, section);

          if (!this.firstUnitInSectionMap.get(section.code)) {
            this.firstUnitInSectionMap.set(section.code, unit.code);
          }
        });
        section.open = true;
      });

      this.guaranteesForm = this.unitService.toFormGroup(this.unitSections);

      this.initializeUnitsBoxes();
      this.updateErrorMessageVariable();
    }
    this.returnMessages();
  }

  selectUnit(selectedUnit: Unit) {
    // Don't update the unit premium. Is the recalculate to do so.
    this.unitSections.forEach(section => {
      section.unitList.forEach(unit => {
        if (
          unit.code === selectedUnit.code &&
          unit.warrantyCode === selectedUnit.warrantyCode
        ) {
          unit.selected = !unit.selected;

          if (unit.variables) {
            unit.variables.forEach(unitVariable => {
              const variableFormControlName =
                section.code +
                '_' +
                unit.code +
                '_' +
                unit.warrantyCode +
                '_' +
                unitVariable.code;
              const variableFormControl = this.guaranteesForm.get(
                variableFormControlName
              );
              if (variableFormControl) {
                if (unit.selected) {
                  variableFormControl.setValidators(this.variableService.getVariableValidators(unitVariable, true));
                } else {
                  variableFormControl.clearValidators();
                }
                variableFormControl.updateValueAndValidity();
                this.guaranteesForm.updateValueAndValidity();
              }
            });
          }

          if (unit.selected && !unit.open) {
            this.toggleVariables(unit);
          } else if (!unit.selected && unit.open) {
            unit.open = false;
          }

          this.recalculateAvailable.emit(true);
        }
      });
    });
    this.updateUnitAsset();
  }

  toggleVariables(selectedUnit: Unit) {
    if (
      (selectedUnit.variables && selectedUnit.variables.length > 0) ||
      (selectedUnit.risk &&
        selectedUnit.risk.clauses &&
        selectedUnit.risk.clauses.length > 0)
    ) {
      this.allUnits.forEach(unit => {
        if (unit !== selectedUnit) {
          unit.open = false;
        }
      });
      selectedUnit.open = !selectedUnit.open;
    }
  }

  validateVariables(event: string) {
    this.updateErrorMessageVariable();
    this.recalculateAvailable.emit(true);
    this.updateUnitAsset();
  }

  verifyClauses(event: Message[], unit: Unit) {
    const alertClause = new Array<string>();
    event.forEach(message => {
      alertClause.push(message.text);
    });

    this.validationMessagesClause.set(unit.warrantyCode, alertClause);
    this.returnMessages();
  }

  updateErrorMessageVariable() {
    this.validationMessagesVariable.clear();
    for (const section of this.unitSections) {
      for (const unit of section.unitList) {
        if (unit.selected) {
          const unitVariable = new Array<string>();
          if (unit.variables) {
            for (const variable of unit.variables) {
              const variableFormControl = this.guaranteesForm.get(
                variable.identificationCode
              );
              if (variable.compulsory) {
                if (
                  variableFormControl.value === undefined || variableFormControl.value === null ||
                  variableFormControl.value === '' || variableFormControl.value === '-1'
                ) {
                  this.guaranteesForm
                    .get(variable.identificationCode)
                    .setErrors({required: true});
                }
              }
              if (
                variableFormControl &&
                variableFormControl.errors &&
                variableFormControl.errors.required
              ) {
                unitVariable.push(
                  variable.extendedDescription
                    ? variable.extendedDescription +
                    ' ' +
                    this.translate.instant('is required')
                    : variable.description +
                    ' ' +
                    this.translate.instant('is required')
                );
              }
            }
          }
          this.validationMessagesVariable.set(unit.warrantyCode, unitVariable);
        } else {
          if (this.validationMessagesVariable.get(unit.warrantyCode)) {
            this.validationMessagesVariable.get(unit.warrantyCode).length = 0;
          }
        }
      }
    }
    this.returnMessages();
  }

  returnMessages() {
    this.validationMessages.length = 0;

    this.validationMessagesVariable.forEach(validationMessageVariable => {
      validationMessageVariable.forEach(element => {
        this.validationMessages.push(new Message(this.areaCode, element));
      });
    });
    this.validationMessagesClause.forEach(clause => {
      clause.forEach(element => {
        this.validationMessages.push(new Message(this.areaCode, element));
      });
    });
    this.validationMessagesAsset.emit(this.validationMessages);
  }

  unitsTrackByFn(index, unit: Unit) {
    return unit.code;
  }

  variablesTrackByFn(index, variable: Variable) {
    return variable.code;
  }

  public updateUnitAsset() {
    if (this.guaranteesForm.invalid) {
      return;
    }
    this.unitSectionsChanged.emit(this.unitSections);

    this.unitService.updateUnitValues(this.unitSections, this.guaranteesForm);

    if (this.unitSections) {
      this.unitService.updateUnitAsset(this.unitSections).subscribe(
        data => {
          this.unitSections = data;

          this.updateUnitList.emit(this.unitSections);
          this.unitService.setUnitsAsset(data);
          this.unitService.updateUnitValuesValidators(
            data,
            this.guaranteesForm
          );
          this.initializeUnitsBoxes();
          this.updateErrorMessageVariable();
        }
      );
    }
  }

  calculateEmptyRow(unitsBoxesRow: Array<Unit>) {
    const arrNames: number[] = new Array(
      this.unitsNumberForRow - unitsBoxesRow.length
    );

    for (let i = 0; i < this.unitsNumberForRow - unitsBoxesRow.length; i++) {
      arrNames[i] = unitsBoxesRow.length + i;
    }
    return arrNames;
  }

  changeClauses(event: boolean) {
    if (event) {
      this.updateUnitAsset();
      this.recalculateAvailable.emit(true);
    }
  }
  updateUnitClause(event: boolean) {
    if (event) {
      this.updateUnitAsset();
    }
  }


  ngOnDestroy() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  onSelectedUnit(unitSelected: Unit) {
    this.selectUnit(unitSelected);
    this.updateErrorMessageVariable();
  }

  disabledUnit(unit: Unit) {
    if (unit.enabled) {
      return;
    }
    return true;
  }

  private initializeUnitsBoxes() {
    this.allUnits.length = 0;

    this.unitSections.forEach(section => {
      section.unitList.forEach(unit => {
        this.allUnits.push(unit);
      });
    });

    const unitsNum = this.allUnits.length;

    if (unitsNum !== 0) {
      this.unitsBoxesRows = new Array<Array<Unit>>(
        Math.ceil(unitsNum / this.unitsNumberForRow)
      );
      let i: number;
      let j = 0;
      for (i = 0; i < unitsNum; i++) {
        if (i !== 0 && i % this.unitsNumberForRow === 0) {
          j++;
        }
        if (!this.unitsBoxesRows[j]) {
          this.unitsBoxesRows[j] = [];
        }
        this.unitsBoxesRows[j].push(this.allUnits[i]);
      }
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectSubjectComponent } from './select-subject.component';

@NgModule({
    declarations: [
        SelectSubjectComponent
    ],
    exports: [
        SelectSubjectComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule

    ]
})
export class SelectSubjectModule { }

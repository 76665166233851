import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ModalService, OnModalClose, RgiRxOnContainerValueChange} from '@rgi/rx/ui';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';

import {of, Subscription} from 'rxjs';
import {mergeMap} from "rxjs/operators";
import {RgiRxTranslationService} from "@rgi/rx/i18n";


@Component({
  selector: 'lib-re-issue-new-quota-modal',
  templateUrl: './re-issue-beneficiary-modal.component.html',
  styleUrls: ['./re-issue-beneficiary-modal.component.css']
})
export class ReIssueBeneficiaryModalComponent implements OnInit, OnChanges, OnDestroy, OnModalClose {

  @Input() unitBeneficiariesConf: any;
  @Input() thirdPersonContact: any;
  @Input() title: string;
  @Input() beneficiary: any;
  modalClose: EventEmitter<any> = new EventEmitter();
  beneficiaryForm = new UntypedFormGroup({
    beneficiaryType: new UntypedFormControl('', Validators.required),
    beneficiaryParties: new UntypedFormArray([])
  });
  subscription: Subscription = new Subscription();
  benefiaciaryTypes: any;
  beneficiaryType: any;
  isAnagBeneficiary = false;
  beneficiariesUnit = [];
  beneficiaryParties = [];
  isError = false;
  isErrorQuotas = false;

  constructor(
    protected modalService: ModalService,
    public pushMessageHandler: PushMessageHandlerService,
    protected fb: UntypedFormBuilder,
    private translateService: RgiRxTranslationService
  ) {}

  ngOnInit() {
    this.clearErrors();
    this.isError = false;
    this.isErrorQuotas = false;
    this.benefiaciaryTypes = this.unitBeneficiariesConf.beneficiary;
    if (this.beneficiary) {
      this.beneficiaryForm = new UntypedFormGroup({
        beneficiaryType: new UntypedFormControl(this.beneficiary ? this.beneficiary.beneficiaryType : '', Validators.required),
        beneficiaryParties: new UntypedFormArray([])
      });
      this.beneficiary.parties.forEach(party => {
        if (party.objectId) {
          this.getBeneficiaryParties().push(this.newBeneficiaryParties());
          this.getBeneficiaryParties().controls[this.getBeneficiaryParties().length - 1].patchValue({
            beneficiaryParty: party,
            percentageBeneficiary: party.partyPercentage,
            percentageVisibility: true
          });
        }
      });
      if (this.getBeneficiaryParties().length > 0) {
        this.beneficiaryType = this.benefiaciaryTypes.find(bt => bt.code === this.beneficiary.beneficiaryType);
        this.isAnagBeneficiary = true;
        this.setPercVisibility(this.getBeneficiaryParties());
        if (this.beneficiaryType.soggettiAnagMax > this.getBeneficiaryParties().length) {
          this.getBeneficiaryParties().push(this.newBeneficiaryParties());
          // const numOfBeneficiaryParty = this.countBeneficiaryParty(this.getBeneficiaryParties());
          // this.checkMinBeneficiaryParty(numOfBeneficiaryParty);
        }
      }
      this.isError = false;
      this.isErrorQuotas = false;
    }
    this.setListeners();
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy(): void {
    this.isError = false;
    this.isErrorQuotas = false;
    this.modalClose.emit();
  }

  close() {
    this.isError = false;
    this.isErrorQuotas = false;
    this.modalClose.emit();
  }

  putBeneficiaries() {
    const beneficiariesUnit = [];
    const beneficiaryParties = [];
    this.beneficiaryForm.value.beneficiaryParties.forEach(party => {
      if (party.beneficiaryParty && party.percentageBeneficiary) {
        // tslint:disable-next-line:max-line-length
        beneficiaryParties.push({
          idLatestPhotos: party.beneficiaryParty.idLatestPhotos ? party.beneficiaryParty.idLatestPhotos : party.beneficiaryParty.snapshotId,
          nominative: party.beneficiaryParty.nominative,
          partyPercentage: party.percentageBeneficiary,
          objectId: party.beneficiaryParty.objectId
        });
      }
    });
    beneficiariesUnit.push({
      beneficiaryType: this.beneficiaryType.code,
      unitCode: this.unitBeneficiariesConf.unitCode,
      parties: beneficiaryParties
    });
    this.modalClose.emit(beneficiariesUnit);
  }

  setListeners() {
    const beneficiaryTypeListener = this.beneficiaryForm.controls.beneficiaryType.valueChanges
      .subscribe(val => {
        this.onBeneficiaryTypeChange(val);
      });
    this.subscription.add(beneficiaryTypeListener);
  }

  onBeneficiaryTypeChange(val: any) {
    this.clearErrors();
    this.isError = false;
    this.isErrorQuotas = false;
    this.beneficiaryType = this.benefiaciaryTypes.find(bt => bt.code === val);
    if (this.beneficiaryType) {
      if (this.beneficiaryType.soggettiAnagMax === 0) {
        this.isAnagBeneficiary = false;
        this.getBeneficiaryParties().clear();
      } else {
        this.isAnagBeneficiary = true;
        this.getBeneficiaryParties().push(this.newBeneficiaryParties());
        this.setPercVisibility(this.getBeneficiaryParties());
        const numOfBeneficiaryParty = this.countBeneficiaryParty(this.getBeneficiaryParties());
        this.checkMinBeneficiaryParty(numOfBeneficiaryParty);
      }
    } else {
      this.getBeneficiaryParties().clear();
    }
  }

  onPercentageBeneficiaryChange() {
    this.clearErrors();
    if (!this.checkQuotas()) {
      this.notifyQuotasMessage();
    } else {
      this.isErrorQuotas = false;
    }
  }

  notifyQuotasMessage() {
    let msg = '';
    const errorQuotasMessage = of(['RE_ISSUE.ERROR_BENEFICIARY_QUOTAS']);
    errorQuotasMessage.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(val => {
      msg = val [0];
    }).unsubscribe();
    this.putErrorMessage(msg, 'error', 'quotasError');
  }

  //

  selectedBeneficiarySubject(val: RgiRxOnContainerValueChange, index: number) {
    this.isError = false;
    this.isErrorQuotas = false;
    this.clearErrors();
    if (!this.checkSubject(val.changed)) {
      let msg = '';
      this.removeBeneficiarySubject(index);
      const errorSubjectMessage = of(['RE_ISSUE.WARNING_BENEFICIARY_SUBJECT']);
      errorSubjectMessage.pipe(mergeMap(r => {
        return this.translateService.translateAll(...r);
      })).subscribe(val => {
        msg = val [0];
      }).unsubscribe();
      this.putErrorMessage(msg, 'info', 'duplicateBeneficiaryInfo');
    } else if (!this.checkThirdPerson(val.changed)) {
      let msg = '';
      this.removeBeneficiarySubject(index);
      const errorThirdPerson = of(['RE_ISSUE.WARNING_BENEFICIARY_THIRD_PERSON']);
      errorThirdPerson.pipe(mergeMap(r => {
        return this.translateService.translateAll(...r);
      })).subscribe(val => {
        msg = val [0];
      }).unsubscribe();
      this.putErrorMessage(msg, 'info', 'thirdPersonInfo');
    } else {
      this.setPercVisibility(this.getBeneficiaryParties());
      if (this.beneficiaryType.soggettiAnagMax > this.getBeneficiaryParties().length) {
        this.getBeneficiaryParties().push(this.newBeneficiaryParties());
      }
    }
    const numOfBeneficiaryParty = this.countBeneficiaryParty(this.getBeneficiaryParties());
    this.checkMinBeneficiaryParty(numOfBeneficiaryParty);
    if (!this.checkQuotas()) {
      this.notifyQuotasMessage();
    }
  }

  checkSubject(subject: any) {
    // tslint:disable-next-line:max-line-length
    return this.getBeneficiaryParties().controls.filter(x => (x.value.beneficiaryParty.objectId === subject.objectId) || (x.value.beneficiaryParty.id === subject.objectId)).length <= 1;
  }

  checkThirdPerson(subject: any) {
    return (this.thirdPersonContact && this.thirdPersonContact.objectId) ? this.thirdPersonContact.objectId !== subject.objectId : true;
  }

  newBeneficiaryParties(): UntypedFormGroup {
    return this.fb.group({
      beneficiaryParty: [,],
      percentageBeneficiary: [,],
      percentageVisibility: [,]
    });
  }

  setPercVisibility(beneficiaryParties: UntypedFormArray) {
    beneficiaryParties.controls.forEach((beneficiaryParty: UntypedFormGroup) => {
      let setValidity = false;
      Object.keys(beneficiaryParty.controls).forEach(key => {
        if (key === 'beneficiaryParty') {
          if (beneficiaryParty.controls[key].value) {
            setValidity = true;
          }
        }
        if (key === 'percentageVisibility' && setValidity) {
          beneficiaryParty.controls[key].setValue(true);
        }
        if (key === 'percentageBeneficiary') {
          beneficiaryParty.controls[key].setValidators([Validators.pattern('^([0-9]|[1-9][0-9]|100)$'), Validators.required]);
        }
      });
    });
  }

  removeBeneficiarySubject(index: number) {
    this.isError = false;
    this.isErrorQuotas = false;
    this.clearErrors();
    this.getBeneficiaryParties().removeAt(index);
    const numOfBeneficiaryParty = this.countBeneficiaryParty(this.getBeneficiaryParties());
    this.checkMinBeneficiaryParty(numOfBeneficiaryParty);
    if (!this.checkQuotas()) {
      this.notifyQuotasMessage();
    }
    if (this.getBeneficiaryParties().length === 0 ||
      this.getBeneficiaryParties().controls.filter(x => !x.value.beneficiaryParty).length === 0) {
      this.getBeneficiaryParties().push(this.newBeneficiaryParties());
    }
  }

  getBeneficiaryParties() {
    return this.beneficiaryForm.get('beneficiaryParties') as UntypedFormArray;
  }

  putErrorMessage(message, type: string, tag: string) {
    if (message) {
      const msg: PushMessage = new PushMessage();
      const opts = type === 'info' ? {icon: 'rgi-ui-icon-warning'} : {icon: 'rgi-ui-icon-alert'};
      msg.tag = tag;
      msg.status = type === 'info' ? 'info' : 'danger';
      msg.content = message;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
    }
  }

  private countBeneficiaryParty(beneficiaryParties1: UntypedFormArray) {
    let counter = 0;
    this.getBeneficiaryParties().controls.forEach((beneficiaryParty: UntypedFormGroup) => {
      if (beneficiaryParty.value.beneficiaryParty && beneficiaryParty.value.beneficiaryParty.objectId) {
        counter++;
      }
    });
    return counter;
  }

  private checkMinBeneficiaryParty(numOfBeneficiaryParty: number) {
    if (numOfBeneficiaryParty < this.beneficiaryType.soggettiAnagMin) {
      let msg = '';
      const errorThirdPerson = of(['RE_ISSUE.ERROR_BENEFICIARY_MIN_PARTIES']);
      errorThirdPerson.pipe(mergeMap(r => {
        return this.translateService.translateAll(...r);
      })).subscribe(val => {
        msg = val [0] + ' : ' + this.beneficiaryType.soggettiAnagMin;
      }).unsubscribe();
      this.putErrorMessage(msg, 'error', 'minBeneficiaryError');
      this.isError = true;
    }
  }

  private checkQuotas() {
    if (this.getBeneficiaryParties().length > 0) {
      let quotas = 0;
      let check = false;
      this.getBeneficiaryParties().controls.forEach((beneficiaryParty: UntypedFormGroup) => {
        Object.keys(beneficiaryParty.controls).forEach(key => {
          if (key === 'percentageBeneficiary') {
            if (beneficiaryParty.controls[key].value) {
              check = true;
              // tslint:disable-next-line:radix
              quotas = quotas + parseInt(beneficiaryParty.controls[key].value);
            }
          }
        });
      });
      if (!check) {
        return true;
        this.isErrorQuotas = false;
      } else {
        if (quotas === 100) {
          return true;
          this.isErrorQuotas = false;
        } else {
          this.isErrorQuotas = true;
          return false;
        }
      }
    }
    return true;
    this.isErrorQuotas = false;
  }

  private clearErrors() {
    this.pushMessageHandler.clearTag('quotasError');
    this.pushMessageHandler.clearTag('minBeneficiaryError');
    this.pushMessageHandler.clearTag('duplicateBeneficiaryInfo');
    this.pushMessageHandler.clearTag('thirdPersonInfo');
  }
}


export enum PNC_PSALES_ACTIONS {
  BACK = 'BACK',
  CANCEL = 'CANCEL',
  CONTINUE = 'CONTINUE',
  END = 'END',
  CALCULATE = 'CALCULATE',
  RESET = 'RESET',
  FULL_SUMMARY = 'FULL_SUMMARY',
}

export enum PNC_ACTIONS {
  NODE_SELECTION = 'NODE_SELECTION',
}

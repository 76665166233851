<rgi-rx-panel>
    <rgi-rx-panel-header>
        <div class="container-flex">
            <div class="title-modal p-0-15">
                <span>{{ '_CLAIMS_._EDIT_ACCIDENTS' | translate }}</span>
            </div>
        </div>
    </rgi-rx-panel-header>
    <rgi-rx-panel-footer>
        <div class="btn-group btn-group-justified btn-group-justified-claim">
            <div class="btn-group p-0-01">
                <button rgi-rx-button color="primary" class="w-100-custom" (click)="closeModal('Close')">{{
                    '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
            </div>
            <div class="btn-group p-0-01">
                <!-- [disabled]="detailReceiptReturnForm.invalid" -->
                <button rgi-rx-button type="button" color="primary" class="w-100-custom" (click)="confirmCircumstance()"
                    [rgiRxTooltip]="dateErrorTooltipTemplate" [position]="'top'" [trigger]="'mouseenter'"
                    [disableTooltip]="enableConfirmButton" [disabled]="!enableConfirmButton">
                    {{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
            </div>
        </div>
    </rgi-rx-panel-footer>
    <ng-container>
        <div class="barem-list-container">
            <div class="barem barem-list-title">
                <div class="barem-check-A">
                    <div class="title-center">
                        <strong>A</strong>
                    </div>
                </div>
                <p class="barem-title text-center">
                    <strong>{{ '_CLAIMS_._MESSAGE_._SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION' | translate }}</strong>
                </p>
                <div class="barem-check-B">
                    <div class="title-center">
                        <strong>B</strong>
                    </div>
                </div>
            </div>
            <div class="barem" *ngFor="let barem of baremsList; index as baremIndex">
                <div class="barem-check-A">
                    <div>
                        <rgi-rx-form-field>
                            <input type="checkbox" rgiRxInput [(ngModel)]="barem.isSelectedA"
                                (change)="onValueChange(barem, baremIndex, 'A', $event.target)">
                        </rgi-rx-form-field>
                    </div>
                </div>
                <p class="barem-title text-lowercase text-center">{{barem.barem.description}}</p>
                <div class="barem-check-B">
                    <div>
                        <rgi-rx-form-field>
                            <input type="checkbox" rgiRxInput [(ngModel)]="barem.isSelectedB"
                                (change)="onValueChange(barem, baremIndex, 'B', $event.target)">
                        </rgi-rx-form-field>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #dateErrorTooltipTemplate>
            {{ '_CLAIMS_._MESSAGE_._SELECT_AT_LEAST_ONE_CAUSAL' | translate }}
        </ng-template>
    </ng-container>
</rgi-rx-panel>
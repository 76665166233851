<div *ngIf="IinjuryAsset">
    <h3 class="title-header mtb-1">{{ '_CLAIMS_._DAMAGED_PARTS' | translate }}</h3>
    <div class="mtb-1">
        <div class="row">
            <div class="col-md-6">
                <rgi-rx-form-field class="mtb-1">
                    <label rgiRxLabel>{{ '_CLAIMS_._CAUSE_OF_INJURY' | translate }}</label>
                    <select rgiRxNativeSelect [(ngModel)]="IinjuryAsset.injuryCause"
                    (ngModelChange)="chargeNature()">
                        <option [value]=""></option>
                        <option *ngFor="let list of injuryCauseList" [value]="list.code">{{list.description}}</option>
                    </select>
                </rgi-rx-form-field>
                <div class="row mtb-1">
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="mr-2">{{ '_CLAIMS_._UNCODABLE' | translate }}</label>
                            <input [(ngModel)]="IinjuryAsset.notCodeable"
                            (ngModelChange)="onNgModelChange()"
                            rgiRxInput type="checkbox">
                        </rgi-rx-form-field>
                    </div>
                    <div class="col-md-6">
                        <rgi-rx-form-field>
                            <label rgiRxLabel class="mr-2">{{ '_CLAIMS_._MULTIPLE' | translate }}</label>
                            <input [(ngModel)]="IinjuryAsset.multiple"
                            (ngModelChange)="onNgModelChange()"
                            rgiRxInput type="checkbox">
                        </rgi-rx-form-field>
                    </div>
                </div>
                <div>
                    <div class="d-flex nature-of-injury-bar">
                        <div class="col-md-4">
                            {{ '_CLAIMS_._NATURE_OF_INJURY' | translate }}
                        </div>
                        <div class="col-md-8">
                            {{ '_CLAIMS_._INJURY_LOCATION' | translate }}
                        </div>
                    </div>
                    <div
                        *ngIf="!injuryDamageArray || injuryDamageArray.length === 0; else results">
                        {{ '_CLAIMS_._MESSAGE_._NO_RESULTS' | translate }}
                    </div>
                    <ng-template #results>
                        <div *ngFor="let aDam of injuryDamageArray; 
                            let even = even;
                            let odd = odd;
                            let damIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }">
                            <span class="col-md-4">{{ aDam.injuryTypeDescr }}</span>
                            <span class="col-md-7">{{ aDam.bodilyLocationDescr }}</span>
                            <div class="col-md-1">
                                <button type="button" class="btn btn-flat btn-sm" (click)="removeDamage(aDam.bodilyLocation, damIndex)"
                                    style="border-color: white;">
                                    <span class="glyphicon glyphicon-trash" style="width:10px; height:10px;"></span>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="col-md-6 mtb-1">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._NATURE_OF_INJURY' | translate }}</label>
                    <select rgiRxNativeSelect [(ngModel)]="IinjuryAsset.injuryNature"
                    (ngModelChange)="chargeLocations()">
                        <option [value]=""></option>
                        <option *ngFor="let list of injuryNatureCombo" [value]="list.code">{{list.description}}</option>
                    </select>
                </rgi-rx-form-field>
                <div class="col-sm-8 col-xs-12 ">
                    <table border="0" cellpadding="0" cellspacing="0" align="center"
                        style="z-index: 1; position: relative;">
                        <tr>
                            <td> <!-- tabella corpo fronte-->
                                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                    <tr>
                                        <td align="center" colspan="3"><img style="margin: 0px 0px -5px 0px;"
                                                src="{{rootImg}}cranio_front.png"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" class="tooltipT"><img
                                                            style="margin: -5px 10px 0px 122px; cursor: pointer;"
                                                            src="{{mappaBody[34]}}"
                                                            (click)="addVisualDamage('34')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px 0px -60px;">{{mappaBodyDescr[34] |
                                                            translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin: -5px 12px 8px -11px; cursor: pointer;"
                                                            src="{{mappaBody[31]}}"
                                                            (click)="addVisualDamage('31')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px 0px -140px;">{{mappaBodyDescr[31] |
                                                            translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin: -5px 130px 8px -4px; cursor: pointer;"
                                                            src="{{mappaBody[32]}}"
                                                            (click)="addVisualDamage('32')" /><span
                                                            class="tooltiptextT">{{mappaBodyDescr[32] | translate
                                                            }}</span></td>
                                                    <td align="left" class="tooltipT"><img
                                                            style="margin:-5px 0px 0px -131px; cursor: pointer;"
                                                            src="{{mappaBody[33]}}"
                                                            (click)="addVisualDamage('33')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px 0px -130px;">{{mappaBodyDescr[33] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" colspan="3" class="tooltipR"><img
                                                style="margin: -10px 0px 1px 0px; cursor: pointer;"
                                                src="{{mappaBody[15]}}" (click)="addVisualDamage('15')" /><span
                                                class="tooltiptextR"
                                                style="margin: -10px 20px 0px 0px;">{{mappaBodyDescr[15] | translate
                                                }}</span></td>
                                    </tr>
                                    <tr>
                                        <td align="center" colspan="3" class="tooltipR"><img
                                                style="margin: -21px 3px -1px 0px; cursor: pointer;"
                                                src="{{mappaBody[16]}}" (click)="addVisualDamage('16')" /><span
                                                class="tooltiptextR"
                                                style="margin: -15px 30px 0px 0px;">{{mappaBodyDescr[16] | translate
                                                }}</span></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" style="position: relative; z-index: 1;"
                                                        class="tooltipT"><img
                                                            style="margin: -7px -107px 0px 39px; cursor: pointer;"
                                                            src="{{mappaBody[42]}}"
                                                            (click)="addVisualDamage('42')" /><span class="tooltiptextT"
                                                            style="margin: 0px -65px 0px 0px;">{{mappaBodyDescr[42] |
                                                            translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin: -7px -10px 1px 105px; cursor: pointer;"
                                                            src="{{mappaBody[40]}}"
                                                            (click)="addVisualDamage('40')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px 0px -120px;">{{mappaBodyDescr[40] |
                                                            translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin:-6px 75px 0px -39px; cursor: pointer;"
                                                            src="{{mappaBody[39]}}"
                                                            (click)="addVisualDamage('39')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px 0px -40px;">{{mappaBodyDescr[39] |
                                                            translate }}</span></td>
                                                    <td align="left" class="tooltipT"><img
                                                            style="margin:-7px 2px 0px -106px; cursor: pointer;"
                                                            src="{{mappaBody[41]}}"
                                                            (click)="addVisualDamage('41')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px 0px -100px;">{{mappaBodyDescr[41] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="right" class="tooltipT"><img
                                                style="margin: -8px 0px 45px 64px; cursor: pointer;"
                                                src="{{mappaBody[44]}}" (click)="addVisualDamage('44')" /><span
                                                class="tooltiptextT"
                                                style="    margin: 0px 0px 0px -100px;">{{mappaBodyDescr[44] | translate
                                                }}</span></td>
                                        <td align="center" class="tooltipR"><img
                                                style="margin: -5px 0px 0px 0px; cursor: pointer;"
                                                src="{{mappaBody[22]}}" (click)="addVisualDamage('22')" /><span
                                                class="tooltiptextR"
                                                style="margin: 0px 0px 0px 30px;">{{mappaBodyDescr[22] | translate
                                                }}</span></td>
                                        <td align="left" class="tooltipT"><img
                                                style="margin: -9px 63px 42px 0px; cursor: pointer;"
                                                src="{{mappaBody[43]}}" (click)="addVisualDamage('43')" /><span
                                                class="tooltiptextT"
                                                style="margin: 0px 0px -10px -20px;">{{mappaBodyDescr[43] | translate
                                                }}</span></td>
                                    </tr>

                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" class="tooltipT"><img
                                                            style="margin: -55px -5px 24px 59px; cursor: pointer;"
                                                            src="{{mappaBody[45]}}"
                                                            (click)="addVisualDamage('45')" /><span class="tooltiptextT"
                                                            style="margin: -40px 0px 0px -130px;">{{mappaBodyDescr[45] |
                                                            translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin: -30px -3px 14px 10px; cursor: pointer;"
                                                            src="{{mappaBody[62]}}"
                                                            (click)="addVisualDamage('62')" /><span class="tooltiptextT"
                                                            style="margin: -32px 0px 0px -129px;">{{mappaBodyDescr[62] |
                                                            translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin:-31px -6px 15px 37px; cursor: pointer;"
                                                            src="{{mappaBody[61]}}"
                                                            (click)="addVisualDamage('61')" /><span class="tooltiptextT"
                                                            style="margin: -30px 0px 0px 40px;">{{mappaBodyDescr[61] |
                                                            translate }}</span></td>
                                                    <td align="left" class="tooltipT"><img
                                                            style="margin:-56px 58px 23px 6px; cursor: pointer;"
                                                            src="{{mappaBody[46]}}"
                                                            (click)="addVisualDamage('46')" /><span class="tooltiptextT"
                                                            style="margin: -40px 0px 0px 0px;">{{mappaBodyDescr[46] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="right" class="tooltipR"><img
                                                style="margin: -40px 20px 31px 0px; cursor: pointer;"
                                                src="{{mappaBody[47]}}" (click)="addVisualDamage('47')" /><span
                                                class="tooltiptextR"
                                                style="margin: -50px 10px 0px 0px;">{{mappaBodyDescr[47] | translate
                                                }}</span></td>
                                        <td align="center" class="tooltipR"><img
                                                style="margin: -40px 0px 7px 0px; cursor: pointer;"
                                                src="{{mappaBody[21]}}" (click)="addVisualDamage('21')" /><span
                                                class="tooltiptextR"
                                                style="margin: -20px 0px 0px 20px;">{{mappaBodyDescr[21] | translate
                                                }}</span></td>
                                        <td align="left" class="tooltipR"><img
                                                style="margin: -41px 0px 30px 18px; cursor: pointer;"
                                                src="{{mappaBody[48]}}" (click)="addVisualDamage('48')" /><span
                                                class="tooltiptextR"
                                                style="margin: -30px 30px 0px 10px;">{{mappaBodyDescr[48] | translate
                                                }}</span></td>
                                    </tr>

                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" class="tooltipT"><img
                                                            style="margin: -58px 1px 36px 37px; cursor: pointer;"
                                                            src="{{mappaBody[50]}}"
                                                            (click)="addVisualDamage('50')" /><span class="tooltiptextT"
                                                            style="margin: -15px 5px 0px -110px;">{{mappaBodyDescr[50] |
                                                            translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin: -50px -1px 0px 21px; cursor: pointer;"
                                                            src="{{mappaBody[64]}}"
                                                            (click)="addVisualDamage('64')" /><span class="tooltiptextT"
                                                            style="margin: 0px 18px -50px -135px;">{{mappaBodyDescr[64]
                                                            | translate }}</span></td>
                                                    <td class="tooltipT"><img
                                                            style="margin:-49px 43px 0px -1px; cursor: pointer;"
                                                            src="{{mappaBody[63]}}"
                                                            (click)="addVisualDamage('63')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px -50px 0px;">{{mappaBodyDescr[63] |
                                                            translate }}</span></td>
                                                    <td align="left" class="tooltipT"><img
                                                            style="margin:-49px 38px 44px -28px; cursor: pointer;"
                                                            src="{{mappaBody[49]}}"
                                                            (click)="addVisualDamage('49')" /><span class="tooltiptextT"
                                                            style="margin: -15px 5px 0px -30px;">{{mappaBodyDescr[49] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" class="tooltipT"><img
                                                            style="margin: -5px 0px 4px 15px; cursor: pointer;"
                                                            src="{{mappaBody[66]}}"
                                                            (click)="addVisualDamage('66')" /><span class="tooltiptextT"
                                                            style="margin: -5px -30px 0px -90px;">{{mappaBodyDescr[66] |
                                                            translate }}</span></td>

                                                    <td align="left" class="tooltipT"><img
                                                            style="margin:-9px 5px 0px 13px; cursor: pointer;"
                                                            src="{{mappaBody[65]}}"
                                                            (click)="addVisualDamage('65')" /><span class="tooltiptextT"
                                                            style="margin: -5px 0px 0px -25px;">{{mappaBodyDescr[65] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" class="tooltipT"><img
                                                            style="margin: -5px 4px 0px -2px; cursor: pointer;"
                                                            src="{{mappaBody[68]}}"
                                                            (click)="addVisualDamage('68')" /><span class="tooltiptextT"
                                                            style="margin: 0px -30px -50px -90px;">{{mappaBodyDescr[68]
                                                            | translate }}</span></td>

                                                    <td align="left" class="tooltipT"><img
                                                            style="margin: -5px 1px 0px -1px; cursor: pointer;"
                                                            src="{{mappaBody[67]}}"
                                                            (click)="addVisualDamage('67')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px -50px -30px;">{{mappaBodyDescr[67] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" class="tooltipT"><img
                                                            style="margin: -6px 0px 0px 6px; cursor: pointer;"
                                                            src="{{mappaBody[72]}}"
                                                            (click)="addVisualDamage('72')" /><span class="tooltiptextT"
                                                            style="margin: -5px 0px -5px -80px;">{{mappaBodyDescr[72] |
                                                            translate }}</span></td>

                                                    <td align="left" class="tooltipT"><img
                                                            style="margin: -6px 0px 0px 7px; cursor: pointer;"
                                                            src="{{mappaBody[71]}}"
                                                            (click)="addVisualDamage('71')" /><span class="tooltiptextT"
                                                            style="margin: -5px 0px -10px -30px;">{{mappaBodyDescr[71] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>


                                </table>
                            </td>
                            <td> <!-- tabella corpo dorso-->
                                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center"
                                    style="position: relative; z-index: 0; margin: 37px 0px 0px -30px;">
                                    <tr>
                                        <td align="center" colspan="3" class="tooltipR"><img
                                                style="margin: 0px 0px -2px -4px; cursor: pointer;"
                                                src="{{mappaBody[11]}}" (click)="addVisualDamage('11')" /><span
                                                class="tooltiptextR">{{mappaBodyDescr[11] | translate }}</span> </td>
                                    </tr>
                                    <tr>
                                        <td align="center" colspan="3" class="tooltipR"><img
                                                style="margin: 0px 0px 0px -4px; cursor: pointer;"
                                                src="{{mappaBody[23]}}" (click)="addVisualDamage('23')" /><span
                                                class="tooltiptextR"
                                                style="margin: -10px 20px 0px 30px;">{{mappaBodyDescr[23] | translate
                                                }}</span></td>
                                    </tr>
                                    <tr>
                                        <td align="right" class="tooltipT"><img
                                                style="margin: -45px 0px 24px -4px; cursor: pointer;"
                                                src="{{mappaBody[36]}}" (click)="addVisualDamage('36')" /><span
                                                class="tooltiptextT">{{mappaBodyDescr[36] | translate }}</span> </td>

                                        <td align="center" class="tooltipR"><img
                                                style="margin: -4px 0px 0px 0px; cursor: pointer;"
                                                src="{{mappaBody[24]}}" (click)="addVisualDamage('24')" /><span
                                                class="tooltiptextR"
                                                style="margin: 0px 0px 0px 60px;">{{mappaBodyDescr[24] | translate
                                                }}</span></td>

                                        <td align="left" class="tooltipT"><img
                                                style="margin: 0px 0px 65px 0px; cursor: pointer;"
                                                src="{{mappaBody[35]}}" (click)="addVisualDamage('35')" /><span
                                                class="tooltiptextT">{{mappaBodyDescr[35] | translate }}</span> </td>
                                    </tr>

                                    <tr>
                                        <td align="center"><img style="margin: -72px -32px 1px 6px; "
                                                src="{{rootImg}}braccio_sin_dors.png"></td>
                                        <td align="center" class="tooltipT"><img
                                                style="margin: -7px 0px 0px 0px; cursor: pointer;"
                                                src="{{mappaBody[25]}}" (click)="addVisualDamage('25')" /><span
                                                class="tooltiptextT"
                                                style="margin: -8px 0px 0px 40px;">{{mappaBodyDescr[25] | translate
                                                }}</span></td>
                                        <td align="center"><img style="margin: -73px 1px 0px -43px; "
                                                src="{{rootImg}}braccio_des_dors.png"></td>
                                    </tr>
                                    <tr>
                                        <td align="center"><img style="margin: -8px -26px 5px 0px; "
                                                src="{{rootImg}}polso_sin_dors.png"></td>
                                        <td align="center" class="tooltipT"><img
                                                style="margin: -9px 0px 0px 0px; cursor: pointer;"
                                                src="{{mappaBody[26]}}" (click)="addVisualDamage('26')" /><span
                                                class="tooltiptextT"
                                                style="margin: -7px 0px 0px 40px;">{{mappaBodyDescr[26] | translate
                                                }}</span></td>
                                        <td align="center"><img style="margin: -10px 0px 2px -32px; "
                                                src="{{rootImg}}polso_des_dors.png"></td>
                                    </tr>
                                    <tr>
                                        <td align="right"><img style="margin: -6px 18px 196px 12px; "
                                                src="{{rootImg}}mano_sin_dors.png"></td>
                                        <td align="center"><img style="margin: -30px 0px 0px 0px;"
                                                src="{{rootImg}}gambe_dors.png"></td>
                                        <td align="left"><img style="margin: -4px 18px 195px 18px; "
                                                src="{{rootImg}}mano_dest_dors.png"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right" class="tooltipT"><img
                                                            style="margin: -35px -3px 34px -6px; cursor: pointer;"
                                                            src="{{mappaBody[70]}}"
                                                            (click)="addVisualDamage('70')" /><span class="tooltiptextT"
                                                            style="margin: 0px 0px 0px -90px;">{{mappaBodyDescr[70] |
                                                            translate }}</span></td>
                                                    <td align="left" class="tooltipT"><img
                                                            style="margin: -39px -24px 29px 18px; cursor: pointer;"
                                                            src="{{mappaBody[69]}}"
                                                            (click)="addVisualDamage('69')" /><span class="tooltiptextT"
                                                            style="margin: -27px 0px 0px -23px;">{{mappaBodyDescr[69] |
                                                            translate }}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <table width="100%" border="0" cellpadding="0" cellspacing="0"
                                                align="center">
                                                <tr>
                                                    <td align="right"><img style="margin: -54px 7px 25px -1px; "
                                                            src="{{rootImg}}piede_sin_dors.png"></td>
                                                    <td align="left"><img style="margin: -54px 2px 25px 5px; "
                                                            src="{{rootImg}}piede_dest_dors.png"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
                <!--- fine -->
            </div>
        </div>
        <div class="row aligne-custome mtb-1">
            <div class="col-md-6">
                <rgi-rx-form-field>
                    <label rgiRxLabel class="mr-2">{{ '_CLAIMS_._FURTHER_INJURY_INDICATOR' | translate }}</label>
                    <input [(ngModel)]="IinjuryAsset.moreInjury" rgiRxInput type="checkbox"
                        (ngModelChange)="onNgModelChange()">
                </rgi-rx-form-field>
            </div>
            <div class="col-md-6">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._PROFESSIONAL_QUALIFICATION' | translate }}</label>
                    <select rgiRxNativeSelect [(ngModel)]="IinjuryAsset.profession" (ngModelChange)="onNgModelChange()">
                        <option *ngFor="let list of professionList" [ngValue]="list.code">{{list.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="row mtb-1">
            <div class="col-md-4 col-xs-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._PERCENTAGE_OF_PERMANENT_DISABILITY' | translate }}</label>
                    <input rgiRxInput type="number" max="100" min="0"
                    [(ngModel)]="IinjuryAsset.percIP" (ngModelChange)="onNgModelChange()">
                </rgi-rx-form-field>
            </div>
            <div class="col-md-4 col-xs-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE' | translate }}</label>
                    <input rgiRxInput type="number" min="0"
                    [(ngModel)]="IinjuryAsset.ggITB" (ngModelChange)="onNgModelChange()">
                </rgi-rx-form-field>
            </div>
            <div class="col-md-4 col-xs-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{'_CLAIMS_._FATALITY_DATE' | translate}}</label>
                    <rgi-rx-date-picker  [showRemoveButton]="true">
                        <input [rgiRxDateTimeInput]="actualOccurrenceDateDatePicker"
                            data-qa="rgi-incident-data"
                            [(ngModel)]="IinjuryAsset.fatalDate" (ngModelChange)="onNgModelChange()"
                           >
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time #actualOccurrenceDateDatePicker></rgi-rx-date-time>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="row mtb-1">
            <div class="col-lg-4 col-sx-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._FIRST_AID_COUNTRY' | translate }}</label>
                    <input type="text"
                    [(ngModel)]="IinjuryAsset.selectedCountryER" rgiRxInput
                        [rgiRxAutoComplete]="autoCompleteCountry" [minLength]="0" (onChange)="onCompleteCountry($event)"
                        (onValueChange)="getCounty($event)" [onlyMatching]="onlyMatching">
                    <rgi-rx-auto-complete #autoCompleteCountry>
                        <ng-template rgiRxAutoCompleteContent>
                            <ng-container *ngIf="countries.length > 0">
                                <rgi-rx-option *ngFor="let country of countries" [value]="country.value">
                                    {{country.value}}
                                </rgi-rx-option>
                                <rgi-rx-option class="rgi-ui-title-3"
                                    *ngIf="!countries.length">{{'_CLAIMS_._MESSAGE_._NO_RESULT' |
                                    translate}}</rgi-rx-option>
                            </ng-container>
                        </ng-template>
                    </rgi-rx-auto-complete>
                </rgi-rx-form-field>
            </div>
            <div class="col-lg-4 col-sx-12" *ngIf="disableSearchProviders">
                <rgi-rx-form-field *ngIf="!disablePlaceER">
                    <label rgiRxLabel>{{ '_CLAIMS_._FIRST_AID_PLACE' | translate }}</label>
                    <input rgiRxInput [(ngModel)]="IinjuryAsset.placeER" (ngModelChange)="onNgModelChange()"
                        *ngIf="!disablePlaceER">
                </rgi-rx-form-field>
                <rgi-rx-form-field *ngIf="disablePlaceER">
                    <label rgiRxLabel>{{ '_CLAIMS_._FIRST_AID_PLACE' | translate }}</label>
                    <input rgiRxInput
                    [(ngModel)]="IinjuryAsset.placeER" (ngModelChange)="onNgModelChange()" type="text"
                        *ngIf="disablePlaceER" disabled>
                </rgi-rx-form-field>
            </div>
            <div class="col-lg-4 col-sx-12" *ngIf="!disableSearchProviders">
                <rgi-rx-form-field>
                    <label ng-bind="label">{{ '_CLAIMS_._HEALTHCARE_PROVIDERS' | translate }}</label>
                    <input rgiRxInput
                    [(ngModel)]="IinjuryAsset.descriptionER" (ngModelChange)="onNgModelChange()"
                        type="text" attr.data-qa="injury-asset-detail-descriptionER-input">
                </rgi-rx-form-field>
            </div>
            <div class="col-lg-4 col-sx-12">
                <div class="pd-1">
                    <button rgi-rx-button class="wc-100" color="primary"
                         [disabled]="disableSearchProviders"
                         (click)="searchHealthcareProviders($event)">
                             {{'_CLAIMS_._HEALTHCARE_PROVIDERS' | translate}}
                    </button>
                   <!--  <button class="btn btn-warning" (click)="searchHealthcareProviders($event)"
                        attr.data-qa="injury-asset-detail-HealthcareProviders-btn"
                        [disabled]="disableSearchProviders">{{ '_CLAIMS_._HEALTHCARE_PROVIDERS' | translate }}</button> -->
                </div>
            </div>
        </div>
        <div class="row mtb-1 aligne-custome">
            <div class="col-lg-3 col-sm-12">
                <rgi-rx-form-field>
                    <label ng-bind="label" class="mr-2">{{ '_CLAIMS_._BIOLOGICAL_DAMAGE' | translate }}</label>
                    <input rgiRxInput 
                    [(ngModel)]="IinjuryAsset.biologicalDamage" (ngModelChange)="onNgModelChange()"
                        type="checkbox" attr.data-qa="injury-asset-detail-descriptionER-input">
                </rgi-rx-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._SUBROGATION_SOCIAL_ASS_EMPLOYER' | translate }}</label>
                    <select rgiRxNativeSelect
                    [(ngModel)]="IinjuryAsset.surroga" (ngModelChange)="onNgModelChange()">
                        <option [value]=""></option>
                        <option *ngFor="let sur of surrogaAnsw" [value]="sur.code">{{sur.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-6">
                <div class="row p-doctor-row">
                    <div class="col-md-7">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._DOCTOR' | translate }}</label>
                            <input rgiRxInput disabled class="wc-100"
                                [(ngModel)]="IinjuryAsset.descriptionDoctor"
                                type="text" attr.data-qa="injury-asset-detail-descriptionER-input">
                        </rgi-rx-form-field>
                    </div>
                    <div class="col-md-1 mtb-1" *ngIf="IinjuryAsset.descriptionDoctor !== ''">
                        <button rgi-rx-button
                        color="secondary" class="rgi-ui-icon rgi-rx-drag-delete rgi-ui-icon-delete"
                        (click)="deleteDoctor()"></button>
                        <!-- <span class="rgi-ui-icon rgi-rx-drag-delete rgi-ui-icon-delete"
                        (click)="deleteDoctor()"></span> -->
                    </div>
                    <div class="col-md-4 mtb-1">
                        <button rgi-rx-button color="primary" class="wc-100"
                        (click)="searchDoctor()">
                            {{'_CLAIMS_._SEARCH_DOCTOR' | translate}}
                    </button>
                    </div>
                </div>
                <!-- <rgi-rx-drop-container [(ngModel)]="IinjuryAsset.descriptionDoctor"
                    [select]="'searchOrCreateSubject'" viewField="nominative"
                    [selectData]="getSubjectDropContainerData()" (onValueChange)="onPartySelected($event)">
                    <rgi-rx-drag-remove (onRemove)="deleteDoctor()"></rgi-rx-drag-remove>
                </rgi-rx-drop-container> -->
            </div>
        </div>
    </div>
</div>
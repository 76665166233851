import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DocumentService } from '../document.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUpload } from '../model/file-upload';
import { DocumentCategory } from '../model/document-category';
import { DocumentMetaInfo } from '../model/document-meta-info';
import { DocumentConstants } from '@rgi/digital-claims-common-angular';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  @Input() documentCategory: DocumentCategory;
  @Input() categoryId: any;
  @Input() uuid: any;
  @Input() policy?: any;

  private uploadedFile: any;

  uploadForm: UntypedFormGroup;
  fileLoaded: boolean;
  fileName: string;
  pdfType: boolean = false;
  hasError: boolean = false;
  errorMessage: string = null;
  errorMessages = [];

  constructor(
    private httpClient: HttpClient,
    private documentService: DocumentService,
    formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private documentConstants: DocumentConstants,
    @Inject('authService') private auth: any,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    this.uploadForm = formBuilder.group(
      {
        file: []
      }
    );
  }

  ngOnInit() {
  }

  fileChange(event) {
    const file = event.target.files[0];
    this.clearError();
    this.setFile(file);

    event.stopPropagation();
    return false;
  }

  unloadFile(event) {
    this.uploadedFile = null;
    this.fileLoaded = false;
    this.fileName = null;
  }

  uploadFile(event) {
    this.clearError();
    if (this.uploadedFile) {

      const file = new FileUpload();
      const that = this;
      file.file = this.uploadedFile;
      file.fileName = this.fileName;

      file.metaInfoList = [];

      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_KEY_DOC_NAME, file.fileName));
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_KEY_DOC_CAT, this.documentCategory.categoryId));
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_KEY_MICROCAT, this.documentCategory.microCategory));
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_KEY_MACROCAT, this.documentCategory.macroCategory));
      //const companyCode = this.auth.getOperator().salePoint.company ? this.auth.getOperator().salePoint.company.code : null;
      const companyCode = this.policy.company ? this.policy.company.code : null;
      console.log('uploadFile(event) - this.auth.getOperator()', this.auth.getOperator());
      if (companyCode) {
        file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_COD_COMPAGNIA, companyCode));
      }

      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_NOME_UTENTE, this.auth.getUsername()));
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_AGENZIA, this.policy.nodeCode));
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_ESERCIZIO, ''));
      // TODO: check with analisi
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_ALERT_NEW_DOC, 'false'));
      // TODO: check with analisi
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_CANALE_RICEZIONE, 'CLS'));
      file.metaInfoList.push(new DocumentMetaInfo(this.documentConstants.METADATA_ACCESSO, 'P'));

      this.documentService.uploadDocument(this.documentCategory, file).subscribe(
        (response: Response) => {
          that.activeModal.close(response);
        },
        (error: Error) => {
        }
      );
    } else {
      this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD').subscribe(
        res => this.signalError(res)
      )
    }

    event.stopPropagation();
    return false;
  }

  closeModal(event) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  fileDropped(evt: DragEvent) {
    this.clearError();
    const file = evt.dataTransfer.files[0];
    this.setFile(file);
  }

  setFile(file: any) {
    this.fileName = file.name;

    const extension = this.fileName.split('.').pop();
    if (extension && extension.toUpperCase() === 'PDF') {
      this.pdfType = true;
    } else {
      this.pdfType = false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.uploadedFile = reader.result;
      this.fileLoaded = true;
    };
    reader.readAsDataURL(file);
  }

  signalError(errStr: string) {
    this.hasError = true;
    this.errorMessage = errStr;
    this.errorMessages.push(errStr);
  }

  clearError() {
    this.hasError = false;
    this.errorMessage = null;
    this.errorMessages = [];
  }

}

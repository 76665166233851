<div>
  <div class="col-sm-12 container-letter-templates-user-list">
    <div class="col-sm-4">
      <label class="label-core">
        {{ ('_CLAIMS_._TITLE' | translate) + ': ' + getTitle() }}
      </label>
    </div>
    <div class="col-sm-4">
      <label class="label-core">
        {{ ('_CLAIMS_._DEROGATION_LEVEL' | translate) + ': ' + getLivelloDerogaDesc() }}
      </label>
    </div>
    <div class="col-sm-4">
      <label class="label-core">
        {{ ('_CLAIMS_._MANAGE_PHASE' | translate) + ': ' + getFaseGestione() }}
      </label>
    </div>
  </div>

  <rgi-rx-datatable [data]="userList" [schema]="schema" [pageOptions]="[5,10,20,50]"
                    (headerAction)="onHeaderAction($event)">

    <ng-template rgiRxDataTableCell="livelloDerogaLetteraComponent" let-ctx>
      <claims-derogation-level-selector [context]="ctx" [livelloDerogaLettera]="getLivelloDeroga()">
      </claims-derogation-level-selector>
    </ng-template>
    <ng-template rgiRxDataTableCell="userCheckerComponent" let-ctx>
      <claims-user-checker [context]="ctx" [changingValueUserCheck]="changingValueUserCheck">
      </claims-user-checker>
    </ng-template>
  </rgi-rx-datatable>

  <span *ngIf='errorMessage' class="col-sm-12 content-error validate-error"
        attr.data-qa="letter-templates-user-list-error">
              <span class="rgifont rgi-exclamation-triangle"></span>
              <span>{{errorMessage}}</span>
          </span>

  <div class="btn-group btn-group-justified padding-top-25">
    <div class="btn-group">
      <button type="button" class="btn btn-warning pull-right" (click)="back()"
              attr.data-qa="letter-templates-user-list-empty-btn">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
    </div>
    <div class="btn-group">
      <button type="submit" class="btn btn-warning pull-right" attr.data-qa="letter-templates-user-list-find-btn"
              id="letter-templates-user-list-submit" (click)="save()">{{ '_CLAIMS_._BUTTONS_._SAVE' | translate }}</button>
    </div>
  </div>
</div>


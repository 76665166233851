<table class="table table-striped">
  <thead class="head-result">
    <tr>
      <th scope="col" *ngFor="let header of arrayOfElements[0]">
        <span> {{header.description}} </span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let colElement of arrayOfElements; let i = index">
      <td scope="row" *ngFor="let element of arrayOfElements[i + 1]" [ngSwitch]="element.type">
        <span class="tbld_col_value" *ngSwitchCase="'span'">{{element.description}}</span>
        <button type="button" class="btn btn-start-node" (click)="onButtonClick(element)" *ngSwitchCase="'button'">
          <i class="{{element.customClass}}"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
import {QuotationService} from './../quotation/quotation.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {RoutableComponent} from '../routable-component';
import {EventNotificator} from '../event-notificator';
import {PartiesService} from './parties.service';
import {ParameterService} from '../quotation/parameters/parameters.service';
import {QuestionnaireService} from '../questionnaires/questionnaire.service';
import {Constraint} from '../models/domain-models/parameters/constraint';
import {LiensService} from './liens/liens.service';
import {ProposalService} from '../proposal.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalService, OperatorService} from '@rgi/portal-ng-core';
import {Message} from '../models/message';
import {Questionnaire} from '../questionnaires/questionnaire';
import {CustomModalService} from '../custom-modal.service';
import {ProposalStatusTypes} from './proposal-status-types';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {AuthorizationRequestComponent} from './authorization-request/authorization-request.component';
import {Observable, Subject, Subscription, throwError} from 'rxjs';
import {Action} from '../models/action';
import {StateService} from '../state.service';
import {State} from '../models/state';
import {RoutingService} from '../routing.service';
import {Modal} from '../modal';
import {ApiContract} from '../models/api-models/api-contract';
import {catchError, first} from 'rxjs/operators';
import {Warning} from '../models/domain-models/warning';
import {QuestionnaireDnService} from './questionnairedn.service';
import {AuthorizationFilter, AuthorizationsSearchService} from '@rgi/authorizations-card';
import {SubstitutionService} from '../substitution/substitution.service';
import {SubstEnumAssetRetention} from '../models/domain-models/substitution/enum/substitution-assetretention';
import {ProductAssetModalComponent} from '../product-asset-modal/product-asset-modal-component';
import {
  SubstEnumPolicyholderRetention
} from '../models/domain-models/substitution/enum/substitution-policyholderretention';
import {ApiProposalInfo} from '../models/api-models/api-proposal-info';
import {FieldComponent} from '../models/api-models/api-disabled-components';
import {ApiQuote} from '../models/api-models/api-quote';
import {QuoteService} from '../quote.service';
import {AmendmentService} from '../amendment/amendment.service';
import {PassProductsService} from '../pass-products.service';
import {ElaborateCustomProperties} from '../custom-properties';
import {OutboundDocumentsModalComponent, OutboundDocumentsReference} from '@rgi/shared-components';
import {ModalService as modalRx} from "@rgi/rx/ui";

@Component({
  selector: 'mic-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss']
})
export class ProposalComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator {

  @Output()
  navigation: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  substConfigUpdateEmitter: Subject<any> = new Subject<any>();
  changeAssetEmitter: Subject<any> = new Subject<any>();

  constraint: Constraint;
  contractId: string;
  questionnaires: Questionnaire[];

  proposalStatusTypes = ProposalStatusTypes;
  proposalStaus: GenericEntity;

  validationMessages: Message[] = [];
  nonBlockingMessages: Message[] = [];
  showFloatingMessages = false;
  enableWarningIcon = true;

  @ViewChild('errorsElement')
  errorsElement: ElementRef<HTMLDivElement>;

  @ViewChild('productAssetModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) productAssetOverlayContainerRef: ViewContainerRef;

  public isOpen: boolean;
  entitledParty: any;
  policyTechnicalData: any;
  viewEmailAddress = false;
  viewPostalAddresses = true;
  retrieveNewContacts: Subject<void> = new Subject<void>();
  refreshPolicyContacts: Subject<void> = new Subject<void>();
  issueCompleted = false;
  showDocuments = false;
  enableSaveProposal = true;
  enableAuthorization = true;
  areaCode = 'PROPOSAL';

  proposalNumber: string;
  showQuestionnaires = true;
  plate: string;
  contractAvailable = false;
  isFromAuthorizationFlow = false;

  policySummaryModal: Type<Modal>;

  questionnairesCompleted = false;
  uploadDocumentsChanged = false;
  isProposalSaved = false;
  documentsCompleted = true;
  surveyCompleted = false;
  documentManagerEnabled = false;
  isPurchasableProposal = true;

  salePoint: any;

  @ViewChild('modalOverlay', {read: ViewContainerRef, static: true}) modalOverlayRef: ViewContainerRef;
  private subscriptions: Subscription = new Subscription();
  disableSaveQuote: false;
  isBindingProposal: boolean = true;


  constructor(
    protected partiesService: PartiesService,
    protected questionnaireService: QuestionnaireService,
    protected parametersService: ParameterService,
    protected bondService: LiensService,
    protected proposalService: ProposalService,
    protected translate: TranslateService,
    protected customModalService: CustomModalService,
    protected modalService: ModalService,
    protected operatorervice: OperatorService,
    protected routingService: RoutingService,
    protected stateService: StateService,
    protected questionnaireDnService: QuestionnaireDnService,
    protected authorizationsSearchService: AuthorizationsSearchService,
    protected substitutionService: SubstitutionService,
    protected quoteService: QuoteService,
    protected quotationService: QuotationService,
    protected amendmentService: AmendmentService,
    protected passProductService: PassProductsService,
    protected elaborateCustomProperties: ElaborateCustomProperties,
    protected modalRx: modalRx,
    @Inject('policySummaryModalComponent') policySummaryModalComponent: Type<Modal>
  ) {
    this.policySummaryModal = policySummaryModalComponent;
  }

  get isSubstitution() {
    return this.proposalService.isSubstitution;
  }

  get isAmendment() {
    return this.amendmentService.isAmendment;
  }

  ngOnInit() {
    this.isOpen = true;
    this.bondService.clearLiensList();

    this.salePoint = this.operatorervice.getSalePointDefault();
    if (!this.salePoint) {
      this.salePoint = this.operatorervice.getSalePointLogin();
    }

    const newContractSubscription = this.proposalService.getNewContractSignal().subscribe(data => {
      this.contractAvailable = true;
      this.proposalService.setApiContract(data);
      this.proposalService.getApiContract().editProposal = true;

      this.stateService.setCurrentState(State.PROPOSAL);
      this.routingService.setPreviousState(State.PROPOSAL);

      if (this.proposalService.getApiContract().bindingProposal === false) {
        this.goToMandatoryData();
      }

      this.eventPropagation.emit({
        eventName: 'setTitle',
        title: 'P&C Proposal - Edit Proposal'
      });

      this.initPreviusPremium();

      this.init();
    });
    this.subscriptions.add(newContractSubscription);


    const apiContract: ApiContract = this.proposalService.getApiContract();
    const state: State = this.stateService.getPreviousState();
    if (apiContract) {
      this.contractId = this.proposalService.getContractId();
      this.isBindingProposal = apiContract.bindingProposal;
      if (!apiContract.contractNumber ||
        (apiContract.contractNumber &&
          (state === State.QUOTATION_STANDARD_PLATE ||
            state === State.QUOTATION_ALTERNATIVE_PLATE ||
            state === State.QUOTATION_WITHOUT_PLATE ||
            state === State.TELEMATICS)) || this.proposalService.isSubstitution) {
        this.contractAvailable = true;

        this.eventPropagation.emit({
          eventName: 'setTitle',
          title: this.translate.instant('P&C New Proposal - New Proposal')
        });

        this.init();

        if (state === State.TELEMATICS) {
          this.updateProposalStatus();
        }
      }
    }
  }

  onSubscriberChanged(onlyEntitled: boolean = false) {
    this.entitledParty = this.partiesService.getEntitledParty();
    if (!onlyEntitled) {
      this.partiesService.sendSubscriberChanged();
      this.showQuestionnaires = true;
    }
    this.enableSaveProposal = true;
    this.retrieveNewContacts.next();
    this.getWarningMessages('WARN');
  }

  onSubscriberRemoved() {
    this.showQuestionnaires = false;
  }

  editQuotation() {
    this.proposalService.updateContacts(this.proposalService.getPolicyContacts()).subscribe(savedContacts => {
      this.proposalService.setPolicyContacts(savedContacts);

      this.refreshPolicyContacts.next();
      this.stateService.nextState(Action.EDIT_QUOTATION_BUTTON_PRESSED);
    });
  }

  saveProposal() {
    this.proposalService.updateContacts(this.proposalService.getPolicyContacts()).subscribe(savedContacts => {
      this.proposalService.setPolicyContacts(savedContacts);
      this.refreshPolicyContacts.next();
      this.proposalService.saveProposal()
        .pipe(first(),
          catchError(err => {
            err.error.messages.forEach(msg => {
              this.validationMessages.push(new Message('PROPOSAL', msg));
            });
            return throwError(err);
          })
        )
        .subscribe((data: ApiProposalInfo) => {
            this.manageSaveProposalResult(data, true);
        });
    });
  }

  private manageSaveProposalResult(saveProposalResult: ApiProposalInfo, showMessages: boolean){
    if (saveProposalResult.messages) {
      saveProposalResult.messages.forEach(msg => {
        this.validationMessages.push(new Message('PROPOSAL', msg));
      });
    }
    this.proposalNumber = saveProposalResult.contractNumber.proposalNumber;

    this.proposalService.setProposalNumber(saveProposalResult.contractNumber.proposalNumber);
    this.proposalService.setProposalInfo(saveProposalResult);

    if (showMessages) {
      this.modalService.open([this.translate.instant('Proposal number {{num}} has been saved successfully',
        {num: this.proposalNumberForMessage(saveProposalResult)})], this.translate.instant('SYSTEM MESSAGE'));
    }

    if (!!saveProposalResult.warningMessages && !!saveProposalResult.warningMessages.length) {
      saveProposalResult.warningMessages.forEach(msg => {
        this.nonBlockingMessages.push(new Message('PROPOSAL', msg));
      });
      this.isPurchasableProposal = false;
    } else {
      this.updateProposalStatus();
    }
  }

  sendPrecontractualDocuments() {
    if (this.isProposalSaved) {
      this.proposalService.printDocuments(false).subscribe(printResponse => {
        if (printResponse && printResponse.messages && printResponse.messages.length) {
          this.modalService.open(printResponse.messages, this.translate.instant('SYSTEM MESSAGE'));
        }
      });
    } else {
      this.proposalService.saveProposal()
      .pipe(first(),
        catchError(err => {
          err.error.messages.forEach(msg => {
            this.validationMessages.push(new Message('PROPOSAL', msg));
          });
          return throwError(err);
        })
      )
      .subscribe((data: ApiProposalInfo) => {
          this.manageSaveProposalResult(data, false);
          this.proposalService.printDocuments(false).subscribe(printResponse => {
            if (printResponse && printResponse.messages && printResponse.messages.length) {
              this.modalService.open(printResponse.messages, this.translate.instant('SYSTEM MESSAGE'));
            }
          });
      });
    }


  }

  proposalNumberForMessage(data: any) {
    return data.contractNumber.proposalNumber;
  }

  addPartyRolesMessaging(event) {
    const filtered = this.validationMessages.filter((msg: Message) => msg.area !== 'ROLES');

    this.validationMessages.length = 0;

    Array.prototype.push.apply(this.validationMessages, filtered);
    Array.prototype.push.apply(this.validationMessages, event);
  }

  addPartyRolesNonBlockingMessaging(event) {
    const filtered = this.nonBlockingMessages.filter((msg: Message) => msg.area !== 'ROLES');

    this.nonBlockingMessages.length = 0;

    Array.prototype.push.apply(this.nonBlockingMessages, filtered);
    Array.prototype.push.apply(this.nonBlockingMessages, event);
  }

  addPartyLiensMessaging(event) {
    const filtered = this.validationMessages.filter((msg: Message) =>
      msg.area !== 'LIENS' && msg.area !== 'LIEN');

    this.validationMessages.length = 0;

    Array.prototype.push.apply(this.validationMessages, filtered);
    Array.prototype.push.apply(this.validationMessages, event);
  }

  onWarningIconClick() {
    this.showFloatingMessages = true;
    this.enableWarningIcon = false;
  }

  onCloseErrorsClick() {
    this.showFloatingMessages = false;
    this.enableWarningIcon = true;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window && this.errorsElement && this.errorsElement.nativeElement &&
      window.pageYOffset > this.errorsElement.nativeElement.offsetTop) {
      this.showFloatingMessages = false;
      this.enableWarningIcon = false;
    } else {
      this.enableWarningIcon = true;
    }
  }

  openPolicySummaryModal() {
    this.customModalService.openModal(this.modalOverlayRef, this.policySummaryModal, this.eventPropagation,
      () => {
        if (this.proposalService.isIssueCompleted()) {
          this.issueCompleted = true;
          this.stateService.nextState(Action.ISSUE_POLICY);
        } else {
          this.issueCompleted = false;
        }
      });
  }

  valuesChanged(event) {
    this.disableDocuments(event);

    this.enableSaveProposal = true;
  }

  disableDocuments(event) {
    this.showDocuments = false;
  }

  proposalPrint() {
    this.proposalService.updateContacts(this.proposalService.getPolicyContacts()).subscribe(savedContacts => {
      this.proposalService.setPolicyContacts(savedContacts);
      this.refreshPolicyContacts.next();
      if (this.showDocuments) {
        const docRef = new OutboundDocumentsReference(this.proposalService.getPolicyNumber(), this.proposalService.getProposalNumber(),
          this.proposalService.getProposalInfo().movementNumber, '');
        const modal = this.modalRx.openComponent(OutboundDocumentsModalComponent, docRef);
        modal.modal.enableClickBackground = false;
      } else {
        console.error('User does not have permission to open Outbound Documents Modal');
      }
    });
  }

  onAuthorize() {
    this.proposalService.updateContacts(this.proposalService.getPolicyContacts()).subscribe(savedContacts => {
      this.proposalService.setPolicyContacts(savedContacts);
      this.refreshPolicyContacts.next();
      this.proposalService.saveProposal()
        .pipe(first())
        .subscribe((data: ApiProposalInfo) => {
          this.proposalNumber = data.contractNumber.proposalNumber;

          this.proposalService.setProposalNumber(data.contractNumber.proposalNumber);
          this.enableSaveProposal = false;

          // Show the documents button only for the directional users or only if the proposal status is valid
          this.showDocuments = this.operatorervice.isDirectionalUser()
            || (this.proposalStaus.code === this.proposalStatusTypes.STATE_VALID_PROPOSAL);

          const modalAuth = this.customModalService.openModal(this.modalOverlayRef, AuthorizationRequestComponent, this.eventPropagation);
          modalAuth.instance.authorizationSent.subscribe(() => {
            this.enableAuthorization = false;
          });
        });
    });
  }

  verifyDocuments(event: any) {
    if (event) {
      this.enableSaveProposal = true;
      this.uploadDocumentsChanged = true;
      this.documentsCompleted = event.completed;
    }
  }

  startDocuments(event: any) {
    if (event) {
      this.documentsCompleted = event.completed;
    }
  }

  getSubstitutionConfig() {
    this.substitutionService.getSubstitutionReasonConfig(
      this.proposalService.getContractId()
    ).subscribe((data) => {
      this.proposalService.setSubstitutionConfig(data);
      this.substConfigUpdateEmitter.next();
    });
  }

  goToMandatoryData() {
    this.stateService.nextState(Action.NEXT_BUTTON_PRESSED);
  }

  initPreviusPremium() {
    this.quotationService.calculatePremium(null).subscribe(data => {
      if (data && data.productPremium) {
        this.quotationService.setPreviousAnnualPremium(data.productPremium.annual);
      }
    });
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  substitutionNext() {
    this.checkHolder().subscribe((isHolderCorrect) => {
      if (isHolderCorrect) {
        this.proposalService.updateContacts(this.proposalService.getPolicyContacts()).subscribe(savedContacts => {
          this.proposalService.setPolicyContacts(savedContacts);
          this.refreshPolicyContacts.next();
          this.substitutionService.checkPartyRoleChanged(this.proposalService.getContractId(), 3).subscribe((isOwnerChanged) => {
            const config = this.proposalService.getSubstitutionConfig();
            if (!isOwnerChanged && this.proposalService.getSubstitutionConfig().BREAK_CHAIN !== '1') {
              this.proposalService.getContractDisabledFields().components.push(new FieldComponent('editAtr', false));
            }
            if (!this.proposalService.vehicleAlreadyChanged && config.CHANGE_VEHICLE_MODE) {
              this.openProductAssetModal(isOwnerChanged);
            } else {
              isOwnerChanged ? this.stateService.nextState(Action.SUBSTITUTION_EDIT_ASSET) : this.editQuotation();
            }
          });
        });
      } else {
        this.validationMessages.push(new Message(this.areaCode,
          'Warning! Impossible to proceed with the substitution: the subscriber has not been changed!'));
      }
    });
  }

  checkHolder(): Observable<any> {
    const config = this.proposalService.getSubstitutionConfig();
    if (config.POLICYHOLDER_RETENTION === SubstEnumPolicyholderRetention.POLICYHOLDER_RETENTION_NO) {
      return this.substitutionService.checkPartyRoleChanged(this.proposalService.getContractId(), 1);
    } else {
      return new Observable<boolean>((subscriber) => {
        subscriber.next(true);
      });
    }
  }

  openProductAssetModal(isOwnerChanged) {
    const config = this.proposalService.getSubstitutionConfig();
    const productAssetmodal = this.customModalService.openModal(this.productAssetOverlayContainerRef, ProductAssetModalComponent,
      this.eventPropagation, () => {
        const instance = productAssetmodal.instance;
        // onCancel can be boolean or Action
        if (!(instance.onCancel === true)) {
          this.proposalService.vehicleAlreadyChanged = true;
          if (config.ASSET_RETENTION === SubstEnumAssetRetention.ASSET_RETENTION_NO) {
            this.changeAssetEmitter.next();
          } else if (instance.dataInAniaNotFound || config.BREAK_CHAIN === '1' || isOwnerChanged) {
            this.stateService.nextState(Action.SUBSTITUTION_EDIT_ASSET);
          } else {
            this.stateService.nextState(instance.onCancel);
          }
        }
      });
  }

  getWarningMessages(areaCode: string) {
    this.proposalService.getFullWarnings().subscribe(
      (warnings: Warning[]) => this.updateMessages(warnings, areaCode)
    );
  }

  updateMessages(messages: Warning[], areaCode: string) {
    this.nonBlockingMessages = this.nonBlockingMessages.filter(
      (msg: Message) => msg.area !== areaCode
    );
    this.validationMessages = this.validationMessages.filter(
      (msg: Message) => msg.area !== areaCode
    );
    messages.forEach((warn: Warning) => {
      let message = new Message(areaCode, warn.description);
      message.mandatory = warn.mandatory;
      if (message.mandatory) {
        this.validationMessages.push(message);
      } else {
        this.nonBlockingMessages.push(message);
      }
    });
  }

  init() {
    this.contractId = this.proposalService.getContractId();

    const disabledFields = this.proposalService.getContractDisabledFields();
    if (disabledFields instanceof Observable) {
      disabledFields.subscribe(data => {
        this.proposalService.setDisabledFields(data);
      });
    }

    if (this.proposalService.getPlateNumber()) {
      this.plate = this.proposalService.getPlateNumber().plateNumber;
    }

    this.proposalNumber = this.proposalService.getProposalNumber();

    this.parametersService.retrievePolicyTechnicalData(this.contractId).subscribe(
      (data) => {
        this.policyTechnicalData = data;
        this.constraint = data.constraint;
      },
      (err) => {
        this.constraint = null;
      }
    );

    this.entitledParty = this.partiesService.getEntitledParty();

    const questionnairesSubscription = this.questionnaireService.getQuestionnairesListObservable().subscribe(data => {
      this.questionnairesCompleted = data &&
        data.filter(quest => quest.mandatory && quest.complete === false).length === 0;

      // Remove old questionnaires messages
      const filtered = this.validationMessages.filter((msg: Message) =>
        msg.area !== 'QUESTIONNAIRES');

      this.validationMessages.length = 0;

      Array.prototype.push.apply(this.validationMessages, filtered);

      if (this.showQuestionnaires) {
        /* Notify the incomplete mandatory questionnaires */
        data.forEach(questionnaire => {
          if (questionnaire.mandatory && questionnaire.complete === false) {
            const msg = this.translate.instant('Complete the questionnaire {{descr}}', {descr: questionnaire.description});
            this.validationMessages.push(new Message('QUESTIONNAIRES', msg));
          }
        });
      }
    });
    this.subscriptions.add(questionnairesSubscription);
    this.initQuestionnaires();

    this.subscriptions.add(this.questionnaireService.getInitQuestionnaireSignal().subscribe(
      (data) => {
        this.checkAddressType();
      }
    ));

    this.subscriptions.add(this.questionnaireService.getRefreshQuestionnaireSignal().subscribe(
      (data) => {
        this.initQuestionnaires();
      }
    ));

    if (this.proposalService.isIssueCompleted()) {
      this.issueCompleted = true;
    } else {
      this.issueCompleted = false;
    }

    if (!this.proposalService.isSubstitution) {
      this.proposalService.isDocumentManagementEnabled().subscribe(
        (isEnabled) => {
          this.documentManagerEnabled = isEnabled;
        }
      );
    }

    this.proposalService.getProposalState().subscribe(data => {
      if (data) {
        this.afterGetProposalState(data);
      }
    });

    this.checkSurveyIdd();

    if (this.isSubstitution) {
      this.getSubstitutionConfig();
      this.substConfigUpdateEmitter.asObservable().subscribe(value => {
        const config = this.proposalService.getSubstitutionConfig();
        if (!this.proposalService.vehicleAlreadyChanged && config.ASSET_RETENTION === SubstEnumAssetRetention.ASSET_RETENTION_NO) {
          this.openProductAssetModal(true);
        }
      });
    }

    this.getWarningMessages('WARN');
  }

  afterGetProposalState(data) {
    this.proposalStaus = data;
    if (this.proposalNumber && this.proposalStaus.code === this.proposalStatusTypes.STATE_PROPOSED_TO_BE_AUTHORIZED) {
      const filter = new AuthorizationFilter();
      filter.userLogin = this.operatorervice.getUserCode();
      filter.nodeCode = this.salePoint.objectId;
      filter.proposalNumber = this.proposalNumber;
      filter.subtree = true;
      filter.userType = '1';
      filter.authorizationState = '1';
      filter.underSystem = '1';

      this.authorizationsSearchService.setAuthorizationFilter(filter);
      this.authorizationsSearchService.getAuthorizationsList(filter).subscribe(
        (authList) => {
          if (authList.length > 0) {
            this.enableAuthorization = false;
          }
        });
    }
  }

  private checkAddressType() {
    const questionnaires = this.questionnaireService.getQuestionnaires();
    if (questionnaires) {
      questionnaires.forEach(
        (questionnaire) => {
          if (questionnaire.code === 'DGTAL') {
            questionnaire.questionList.forEach(
              (question) => {
                if (question.code === 'DGT04') {
                  if (!question.answerList[0].selectedValue ||
                    (question.answerList[0].selectedValue && String(question.answerList[0].selectedValue) === '0')) {
                    this.viewEmailAddress = false;
                    this.viewPostalAddresses = true;
                  } else if (question.answerList[0].selectedValue && String(question.answerList[0].selectedValue) === '1') {
                    this.viewEmailAddress = true;
                    this.viewPostalAddresses = false;
                  }
                }
              }
            );
          }
        }
      );
    }
  }

  private initQuestionnaires() {
    this.questionnaireService.retrieveQuestionnaires().subscribe(
      (data) => {
        this.questionnaires = data;
        this.questionnaireService.setQuestionnaires(data);
        this.questionnaireService.setQuestionnairesList(data);
        this.checkAddressType();
      },
      (err) => {
        this.viewEmailAddress = false;
        this.viewPostalAddresses = true;
      }
    );
  }

  private checkSurveyIdd() {

    if (this.questionnaireDnService.enabledIdd === true) {
      const surveyIddSubscription = this.partiesService.getSurveyFilledOutObservable().subscribe(data => {

        if (data) {

          // Remove old questionnaires messages
          const filtered = this.validationMessages.filter((msg: Message) => msg.area !== 'QUESTIONNAIRESDN');
          this.validationMessages.length = 0;
          Array.prototype.push.apply(this.validationMessages, filtered);

          if (data.fromIdd === true) {

            if (data.product !== null && data.product.code === this.proposalService.getProduct().code) {
              this.surveyCompleted = true;

              if (data.subject !== null) {
                this.questionnaireDnService.addQuestionnaireVerified(data.subject.objectId, data.product.code);
              }

            } else {
              this.surveyCompleted = false;
              this.validationMessages.push(new Message('QUESTIONNAIRESDN', 'The product selected in the questionnaire is not correct'));
            }

            this.partiesService.startCheckProposal(this.questionnaireDnService.recalculatePremium);

          } else {

            if (data.surveyCompleted !== null) {
              this.surveyCompleted = data.surveyCompleted;
              if (this.surveyCompleted === true) {
                this.partiesService.startCheckProposal(this.questionnaireDnService.recalculatePremium);
              }
            }
            if (data.subject !== null && data.codeProduct !== null) {
              this.questionnaireDnService.addQuestionnaireVerified(data.subject.objectId, data.codeProduct);
            }

          }

        }

      });
      this.subscriptions.add(surveyIddSubscription);
    } else {
      this.surveyCompleted = true;
    }

  }

  get isEditQuote() {
    return this.proposalService.editQuote;
  }

  saveQuote() {
    const contractId = this.proposalService.getContractId();
    const quoteId = this.proposalService.getQuoteId();

    const quote = new ApiQuote(quoteId, null);

    this.quoteService.saveQuote(contractId, quote).subscribe(data => {
      /* The same message of the angularjs flow */

      this.modalService.open([this.translate.instant('Quote n.') + ' '
        + data.quoteNumber + ' ' + this.translate.instant('successfully saved')],
        this.translate.instant('SYSTEM MESSAGE'));

    });
  }

  isEntitledBeforeQuotation() {
    return this.proposalService.isEntitledBeforeQuotation();
  }

  updateProposalStatus() {
    this.enableSaveProposal = false;
    this.isProposalSaved = true;
    this.uploadDocumentsChanged = false;
    this.proposalService.getProposalState().subscribe(status => {
      if (status) {
        this.proposalStaus = status;
        // Show the documents button only for the directional users or only if the proposal status is valid
        this.showDocuments = this.operatorervice.isDirectionalUser()
          || (this.proposalStaus.code === this.proposalStatusTypes.STATE_VALID_PROPOSAL);
      }
    });
  }

  isSectionEditable(sectionKey) {
    return this.proposalService.isFieldEnabled(sectionKey) === false ? false : true;
  }

}

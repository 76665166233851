<div class="ac_cont_dati">
    <ng-container *ngIf="authorizationDetail.acceptableEvents && authorizationDetail.acceptableEvents.length">
        <div class="nmf_cont_riga">
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span>{{ '_AUTH_._OPERATOR' | translate }}<b> {{ username }}</b></span>
            </div>
        </div>
        <div class="nmf_cont_riga">
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span>{{ '_AUTH_._OPERATOR_DEROGATION_LEVEL' | translate }}<b> {{ authorizationDetail.authenticatedUserDerogationLevel || '-' }}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span>{{ '_AUTH_._AUTHORIZATION_DEROGATION_LEVEL' | translate }}<b> {{ authorizationDetail.derogationLevel || '-' }}</b></span>
            </div>
        </div>
        <div class="nmf_cont_riga">
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span>{{ '_AUTH_._OPERATOR_LEVEL' | translate }}<b> {{ authorizationDetail.authenticatedUserLevel?.description || '-' }}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span>{{ '_AUTH_._COMPETENT_LEVEL' | translate }}<b> {{ authorizationDetail.competentLevel?.description || '-' }}</b></span>
            </div>
        </div>
        <div class="ac_cont_titsez ac-sub-section-title-color">
            <h2 translate>_AUTH_._STATUS_VARIATION</h2>
        </div>
        <form [formGroup]="authForm" (ngSubmit)="onSubmit($event)">
            <div class="nmf_cont_riga">
                <div class="ac_cont_single_data">
                    <div class="form-group row">
                        <div class="col-sm-9 col-xs-9">
                            <rgi-rx-form-field>
                                <label translate>_AUTH_._POSSIBLE_ACTIONS</label>
                                <select rgiRxNativeSelect formControlName="action" name="action" (change)="populateNextAction()">
                                    <option></option>
                                    <option *ngFor="let actionGroup of actionGroups; index as i; trackBy: genericEntitiesTrackByFn"
                                            [ngValue]="actionGroup">
                                        {{actionGroup?.description}}
                                    </option>
                                </select>
                            </rgi-rx-form-field>
                        </div>
                        <div class="col-sm-2 col-xs-2 ac_auth_req_btn_padding">
                            <button rgi-rx-button  type="submit" [disabled]="submitted && !authForm?.valid" color="info" data-qa="find-actions-btn">
                                <span translate>_AUTH_._CONFIRM</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="error-container" style="margin-top: 2em"
                 *ngIf="validationMessages?.length > 0">
                <ac-authorization-error-message type="error" [messages]="validationMessages" data-qa="action-error-messages"></ac-authorization-error-message>
            </div>
            <div class="ac_cont_titsez ac-sub-section-title-color">
                <h2 translate>_AUTH_._NEXT_STATUS</h2>
            </div>
            <div class="nmf_cont_riga">
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span>{{ '_AUTH_._LAST_ACTION' | translate }}<b> {{ nextStatus?.description || '-' }}</b></span>
                </div>
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span>{{ '_AUTH_._COMPETENT_LEVEL' | translate }}<b> {{ nextStatus?.level?.description || '-' }}</b></span>
                </div>
            </div>
            <div class="nmf_cont_riga">
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span>{{ '_AUTH_._LAST_ACTION_DATE' | translate }}<b> {{ (nextStatus?.date | date:'yyyy-MM-dd')  || '-'}}</b></span>
                </div>
                <div class="ac_cont_single_data nmf_cont_single_data_color">
                    <span>{{ '_AUTH_._REQUEST_STATUS' | translate }}<b> {{ nextStatus?.state?.description || '-' }}</b></span>
                </div>
            </div>
            <div class="form-group">
                <rgi-rx-form-field>
                    <div class = "auth-request-textarea">
                        <label translate>_AUTH_._NOTES</label>
                        <textarea [(ngModel)]="counterNote" rgiRxInput formControlName="note"></textarea>
                    </div>
                    <br>
                    <div>
                        <span class="form-help">{{maxChars-counterNote.length}}  {{ '_AUTH_._CHARACTERS' | translate }}</span>
                    </div>
                </rgi-rx-form-field>
            </div>
        </form>
    </ng-container>
    <div class="nmf_cont_riga" *ngIf="authorizationDetail.acceptableEvents && authorizationDetail.acceptableEvents.length == 0">
        <span translate>_AUTH_._STATUS_CHANGES_ARE_NOT_ADMITTED_FOR_THIS_AUTHORIZATION_REQUEST</span>
    </div>
</div>

import {PcPolicy} from './../../models/pc-portfolio-models/policy-models/pc-policy';
import {PcProposal} from './../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import {PolicyContacts} from './../../models/domain-models/policy-contacts';
import {Policy} from './../../models/policyList';
import {PolicyNumber} from './../../models/policyNumber';
import {ContractNumber} from './../../models/contract-number';
import {Injectable} from '@angular/core';

import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {AbstractStateManager, State, StateStoreService} from '@rgi/rx/state';
import {Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {Warning} from '../../models/domain-models/warning';
import {MeansOfPaymentConfigInput} from '../../models/means-of-payment-config-input';
import {DataMeansOfPayment, DebMeanOfPayment, MeansOfPaymentResponse} from '../../models/means-of-payment-response';
import {
  ReIssueProposalManagementBusinessService
} from '../re-issue-business-services/re-issue-proposal-management-business.service';
import {ReIssueEmissionNavigationData} from './re-issue-emission-state-manager.service';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {BankAccountDetailsResponse} from '../../models/bank-account-details-response';
import {BankAccountDetailsRequest} from '../../models/bank-account-details-request';
import {UntypedFormGroup} from '@angular/forms';
import {PaymentData} from '../../models/domain-models/parameters/payment-data';
import {
  BankAccount, CreditCard,
  Method,
  Payment,
  PaymentDataRequest
} from '../../models/pc-portfolio-models/proposal-models/PaymentDataRequest';
import {QuestionFlateResponse} from '../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {PolicyContact} from '../../models/domain-models/policy-contact';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import { ReIssuePolicyDataNavigationData } from './re-issue-policy-data-state';
import { ReissueAssetNavigationDataI } from './re-issue-asset-state-manager.service';
import { ReIssueQuotationNavigationDataI } from './re-issue-quotation-state-manager.service';
import {ProposalStatus} from '../../models/domain-models/quotation';
import {ReIssueRefactorService} from "../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service";


export interface ReIssueProposalManagementNavigationData {
  isPersonaGiuridica: boolean;
  resourceId: string;
  policyTechnicalData: PolicyTechnicalData;
  subject: AnagIssueSubject;
  productCode: string;
  contractNumber: ContractNumber;
  proposalStatus: ProposalStatus;
  backNavigate: string;
  policy: Policy;
  contractId: string;
  node: any;
  companyId: string;
  isBuyProposal: boolean;
  authorize: boolean;
  proposal: PcProposal;
  contact: PolicyContact;
  isFromExternalCard: boolean;
}

export class ReIssueProposalManagementState extends State {
  resourceId: string;
  policyTechnicalData: PolicyTechnicalData;
  subject: AnagIssueSubject;
  productCode: string;
  meansOfPayment: MeansOfPaymentResponse = new MeansOfPaymentResponse();
  contractNumber: ContractNumber;
  warnings: Warning[] | null;
  policyNumber: PolicyNumber;
  proposalStatus: ProposalStatus;
  backNavigate: string;
  policy: Policy;
  contractId: string;
  contacts: PolicyContacts;
  contact: PolicyContact;
  node: any;
  companyId: string;
  accountDetailsResponse: BankAccountDetailsResponse;
  isBuyProposal: boolean;
  isPersonaGiuridica: boolean;
  isFromExternalCard: boolean;
  proposal: PcProposal;
  pcPolicy: PcPolicy;
}

@Injectable({
  providedIn: 'root'
})
export class ReIssueProposalManagementStateManagerService<T extends ReIssueProposalManagementState> extends AbstractStateManager<T> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected proposalManagementBusinessService: ReIssueProposalManagementBusinessService<T>,

  ) {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newReIssueProposalManagementState();

    this.updateState$(this.initProposalManagement(st)); // this update the state
    // this.postMeansOfPayment();
  }

  newReIssueProposalManagementState(): T {
    return new ReIssueProposalManagementState(this.activeRoute.key) as T;
  }

  initProposalManagement(state: T): Observable<T> {
    const req = this.activeRoute.getRouteData<ReIssueProposalManagementNavigationData>();
    this.populateStatewithRouteData(state, req);
    if (req.policyTechnicalData && !req.isFromExternalCard) {
      state.policyTechnicalData = req.policyTechnicalData;
      return of(state);
    } else {
      return this.proposalManagementBusinessService.getTechnicalData$(state);
    }
  }

  putTechnicalData(effectiveDate: Date): Observable<T> {
    const state = this.getCurrentState();
    state.policyTechnicalData.effectiveDate.date = effectiveDate.toJSON();
    // state.policyTechnicalData.expireDate.date = effectiveDate.toJSON() //TODO: da aggiungere
    // TODO: Technical data service no longer exists
    return this.proposalManagementBusinessService.putTechnicalData$(state, state.policyTechnicalData);
  }

  postWarnings() {
    const state = this.getCurrentState();
    return this.proposalManagementBusinessService.postWarnings(state);
  }

  postMeansOfPayment(state: T) {
    // TODO: check usage of empty method
    const configInput: MeansOfPaymentConfigInput = new MeansOfPaymentConfigInput();
    // const state = this.getCurrentState();
    console.log('postMeansOfPayment state ', state);
    configInput.paymentConfigInput.product = state.productCode;
    let node: string = null;
    if (state.subject && state.subject.node && state.subject.node.identification) {
      node = state.subject.node.identification;
    } else if (state.node) {
      node = state.node.idSp;
    }
    configInput.paymentConfigInput.nodeId = node;
    configInput.paymentConfigInput.function = 'CONTRACT';
    configInput.paymentConfigInput.subjectId = state.subject.objectId;
    console.log('postMeansOfPayment configInput ', configInput);
    return this.proposalManagementBusinessService.meansOfPaymentPost$(state, configInput);
  }

  issuePolicy(
    addressList: AddressApiContacts[],
    mainAddress: AddressApiContacts,
    mail: any,
    paymentForm: UntypedFormGroup,
    refundForm: UntypedFormGroup,
    selectedDeb: DebMeanOfPayment,
    selectedCred: DebMeanOfPayment,
    postalAddressChecked: boolean): Observable<T> {
    const state = this.getCurrentState();

    let policyTechData: PaymentDataRequest;
    if (selectedDeb) {
      policyTechData = this.getPaymentRequest(selectedDeb, paymentForm);
      return this.proposalManagementBusinessService.issueProposalWithPayment(state, policyTechData);
    } else {
      return this.proposalManagementBusinessService.issueProposal(state);
    }
  }

  getPaymentRequest(selectedDeb: DebMeanOfPayment, paymentForm: UntypedFormGroup): PaymentDataRequest {
    const payment = new PaymentDataRequest();
    payment.instalment = new Payment();
    payment.sign = new Payment();

    const typePayment = new Method();
    typePayment.code = selectedDeb.code;
    typePayment.description = selectedDeb.description;

    payment.instalment.method = typePayment;
    payment.sign.method = typePayment;

    const bankAccount = new BankAccount();
    if (typePayment.code === 'ADAUT') {
      bankAccount.countryCode = 'IT';
      bankAccount.cin = paymentForm.controls.CIBAN.value.substring(4, 5);
      bankAccount.cab = paymentForm.controls.CCAB.value;
      bankAccount.holder = paymentForm.controls.CHOLDER.value;
      bankAccount.iban = paymentForm.controls.CIBAN.value;
    }
    const creditCard = new CreditCard();
    if (typePayment.code === 'CARD') {
      creditCard.holder = paymentForm.controls.CHOLDER.value;
      creditCard.expiration = paymentForm.controls.DCARDEXPIRE.value;


    }

    payment.instalment.bankAccount = bankAccount;
    payment.sign.bankAccount = bankAccount;
    payment.instalment.card = creditCard;
    payment.sign.card = creditCard;


    return payment;
  }


  protected populateStatewithRouteData(state: T, req: ReIssueProposalManagementNavigationData) {
    state.resourceId = req.resourceId;
    state.subject = req.subject;
    state.contractNumber = req.contractNumber;
    state.productCode = req.productCode;
    state.proposalStatus = req.proposalStatus;
    state.backNavigate = req.backNavigate;
    state.isBuyProposal = req.isBuyProposal;
    state.isPersonaGiuridica = req.isPersonaGiuridica;
    state.isFromExternalCard = req.isFromExternalCard;
    if (req.policy) {
      state.policy = req.policy;
    }
    if (req.contractId) {
      state.contractId = req.contractId;
    }
    if (req.node) {
      state.node = req.node;
    }
    if (req.companyId) {
      state.companyId = req.companyId;
    }

    if (req.contact) {
      state.contact = req.contact;
    }

    state.proposal = req.proposal;
  }

  private getPaymentDataArray(
    selectedDeb: DebMeanOfPayment,
    paymentForm: UntypedFormGroup,
    refundForm: UntypedFormGroup,
    selectedCred: DebMeanOfPayment) {
    const paymentDataArray: PaymentData[] = [];

    const paymentDataDebit = new PaymentData();

    paymentDataDebit.paymentType = {
      code: selectedDeb.identification,
      description: selectedDeb.description,
      id: 0
    };
    paymentDataDebit.paymentTypeDebCred = {
      code: '1',
      description: 'Debito',
      id: 0
    };
    paymentDataDebit.paymentFields = [];
    Object.keys(paymentForm.controls).forEach(key => {
      paymentDataDebit.paymentFields.push({name: key, value: paymentForm.controls[key].value});
    });

    const paymentDataCredit = new PaymentData();

    paymentDataCredit.paymentType = {
      code: selectedCred.identification,
      description: selectedCred.description,
      id: 0
    };
    paymentDataCredit.paymentTypeDebCred = {
      code: '2',
      description: 'Credito',
      id: 0
    };
    paymentDataCredit.paymentFields = [];
    Object.keys(refundForm.controls).forEach(key => {
      paymentDataCredit.paymentFields.push({name: key, value: refundForm.controls[key].value});
    });

    paymentDataArray.push(paymentDataDebit);
    paymentDataArray.push(paymentDataCredit);
    return paymentDataArray;
  }

  back() {
    const st = this.getCurrentState();
    let req: any;
    if (st.backNavigate === 're-issue-summary') {
      req = {
        resourceId: st.resourceId,
        subject: st.subject,
        policyTechnicalData: st.policyTechnicalData,
        branchOffice: null,
        productName: null,
        allAssets: null,
        productCode: null,
        proposalStatus: null,
        node: null,
        companyId: null,
        contact: st.contact
      };
    } else {
      console.log('Policy', st.policy);
      req = {
        resourceId: st.contractId,
        policy: st.policy,
        contact: st.contact
      };
    }
    this.routingService.navigate(st.backNavigate, req, this.activeRoute.id);
  }

  goToIthStep(i) {
    this.navigateToIthStep(i);
  }

  private navigateToIthStep(i) {
    const st = this.getCurrentState();
    const data = this.prepareData(st, i);
    this.routingService.navigate(data.route, data.object, this.activeRoute.id);
  }

  private prepareData(st: T, i: number) {
    if (st.contractNumber) {
      delete st.contractNumber;
      this.updateState$(of(st));
    }
    let data: { object: any, route: string } = { object: null, route: '' };
    switch (i) {
      case 0:
        data.object = this.prepareReIssuePolicyData(st);
        data.route = 're-issue-policy-data';
        break;
      case 1:
        data.object = this.prepareReIssueAssetData(st);
        data.route = 're-issue-asset';
        break;
      case 2:
        data.object = this.prepareReIssueQuotationData(st);
        data.route = 're-issue-quotation';
        break;
      case 3:
        data.object = this.prepareReIssueSummarynData(st);
        data.route = 're-issue-summary';
        break;
      default:
        console.log('Unknown step');
        // Additional logic for unknown steps
        break;
    }

    return data;
  }

  private prepareReIssuePolicyData(st: T) {
    const dataPolicy: ReIssuePolicyDataNavigationData = {
      contractorSubject: st.subject,
      previousContractor:st.subject,
      resourceId: st.resourceId,
      productName: null,
      productId: null,
      node: st.node,
      productCode: st.productCode,
      companyId: st.companyId,
      policyTechnicalData: st.policyTechnicalData,
      editMode: null,
      editFunctionality: null,
      isSubstitution: null,
      backFromStep: true,
      isToDisableFraz: null,
      authorize: null,
      thirdPersonContact: null
    };
    return dataPolicy;
  }

  private prepareReIssueAssetData(st: T) {
    const dataAsset: ReissueAssetNavigationDataI = {
      resourceId: st.resourceId,
      contractorSubject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: null,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: null,
      productId: null,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: null,
      editFunctionality: null,
      isSubstitution: null,
      isSubjectModified: false,
      isToDisableFraz: null,
      authorize: null,
      bIntermediateSaving: null,
      thirdPersonContact: null
    };
    return dataAsset;
  }

  private prepareReIssueQuotationData(st: T): ReIssueQuotationNavigationDataI {
    const dataQuotation: ReIssueQuotationNavigationDataI = {
      resourceId: st.resourceId,
      subject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: null,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: null,
      productId: null,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: null,
      editFunctionality: null,
      isPersonaGiuridica: null,
      resetQuestionnaire: false,
      isSubjectModified: false,
      isSubstitution: null,
      isToDisableFraz: null,
      authorize: null,
      bIntermediateSaving: null,
      clausesFromAsset: null
    };
    return dataQuotation;
  }

  private prepareReIssueSummarynData(st: T) {
    const dataSummary = {
      resourceId: st.resourceId,
      subject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      branchOffice: null,
      productName: null,
      allAssets: null,
      productCode: null,
      proposalStatus: null,
      node: null,
      companyId: null,
      contact: st.contact
    };
    return dataSummary;
  }

  goToNextPage() {
    this.getState$().pipe(take(1)).subscribe(
      st => {
        const request: ReIssueEmissionNavigationData = {
          resourceId: st.resourceId,
          policy: st.pcPolicy
        };

        this.routingService.navigate('re-issue-emission', request, this.activeRoute.id);
      }
    );
  }

  validateBankAccountDetails(
    meanOfPaymentSelected: DataMeansOfPayment,
    operationCode: string,
    caccount: string,
    abi: string,
    cab: string,
    iban: string): Observable<T> {
    const st = this.getCurrentState();
    const bankAccountDetailsRequest = new BankAccountDetailsRequest();
    bankAccountDetailsRequest.operazione = operationCode;
    bankAccountDetailsRequest.contraente = st.subject.fiscalCode;
    bankAccountDetailsRequest.compagnia = st.companyId;
    bankAccountDetailsRequest.tipoconto = meanOfPaymentSelected.meanOfPayment.identification;
    bankAccountDetailsRequest.conto = caccount;
    bankAccountDetailsRequest.abi = abi;
    bankAccountDetailsRequest.cab = cab;
    bankAccountDetailsRequest.spt = st.policyTechnicalData.branchCode ? st.policyTechnicalData.branchCode : '';
    bankAccountDetailsRequest.Numproposta = st.proposal.contractNumber.proposal;
    if (operationCode === '04') {
      bankAccountDetailsRequest.iban = iban;
      bankAccountDetailsRequest.conto = '';
    }
    st.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach(el => {
      if (el.selected) {
        bankAccountDetailsRequest.fraz = el.code;
      }
    });
    bankAccountDetailsRequest.prodotto = '';
    if (st.productCode) {
      bankAccountDetailsRequest.prodotto = st.productCode;
    }
    return this.proposalManagementBusinessService.validateBankAccountDetails(st, bankAccountDetailsRequest);
  }

  getContacts$(): Observable<T> {
    const st = this.getCurrentState();
    return this.proposalManagementBusinessService.getContacts$(st).pipe(
      map(val => {
        st.contacts = val;
        return st;
      })
    );
  }

  getQuestionaires$(state: T): Observable<Array<QuestionFlateResponse>> {
    return this.proposalManagementBusinessService.getQuestionaires$(state, state.isPersonaGiuridica);
  }

  updateContact(contact: Observable<PolicyContact>) {
    const state = this.getCurrentState();
    this.updateState$(this.proposalManagementBusinessService.updateContact$(state, contact));
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicPercentFormGroupComponent} from './lic-percent-form-group/lic-percent-form-group.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {ReactiveFormsModule} from '@angular/forms';
import { LicRequiredModule } from '../utils/lic-required/lic-required.module';


@NgModule({
  declarations: [LicPercentFormGroupComponent],
  exports: [
    LicPercentFormGroupComponent
  ],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    ReactiveFormsModule,
    LicRequiredModule
  ]
})
export class LicPercentFormGroupModule { }

import {Component, OnInit} from '@angular/core';
import {
  RePostSalesCancellationSummaryState,
  RePostsalesStateManagerCancellationSummaryService
} from '../../re-postsale-state-manager/re-postsales-state-manager-cancellation-summary.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ReIssueMsgModalComponent} from '../../../re-issue-msg-modal/re-issue-msg-modal.component';
import {ReIssueMsgData} from '../../../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import {ModalService} from '@rgi/rx/ui';
import {of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 're-cancellation-summary',
  templateUrl: './re-cancellation-summary.component.html'
})
export class ReCancellationSummaryComponent implements OnInit {

  currentStep = 1;
  stepperLabels = ['RE_ISSUE.DATA_CANCELLATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.CONFIRMATION_CANCELLATION'];
  effectiveDate: string;
  issueDate: string;
  summaryForm: UntypedFormGroup;
  msgData: string;

  constructor(protected formBuilder: UntypedFormBuilder,
              public stateManager: RePostsalesStateManagerCancellationSummaryService<RePostSalesCancellationSummaryState>,
              protected datePipe: DatePipe,
              protected customModalService: ModalService,
              private translateService: RgiRxTranslationService) {
  }

  ngOnInit() {
    const state = this.stateManager.getCurrentState();
    this.summaryForm = this.formBuilder.group({
      note: [state.note, ],
    });
    if (state.issueDate) {
      this.issueDate = this.datePipe.transform(new Date(Number(state.issueDate)), 'dd/MM/yyy');
    }
    if (state.effectiveDate) {
      this.effectiveDate = this.datePipe.transform(new Date(Number(state.effectiveDate)), 'dd/MM/yyy');
    }
  }

  goToNextPage() {
    const state = this.stateManager.getCurrentState();
    if (this.summaryForm.valid) {
      const msgData = new ReIssueMsgData();
      const msg = of(['RE_ISSUE.CONFIRM_CANCELLATION']);
      msg.pipe(mergeMap(r => {
        return this.translateService.translateAll(...r);
      })).subscribe(next => {
        this.msgData = next [0] +
          ' ' + state.policy + '?';
      }).unsubscribe();
      msgData.msg = this.msgData;
      msgData.showButtonConfirm = true;
      const cancellationModal = this.customModalService.openComponent(ReIssueMsgModalComponent, msgData);
      cancellationModal.modal.onClose.subscribe(text => {
        if (text) {
          this.stateManager.goToNextPage(this.summaryForm);
        }
      });
    }
  }

  back() {
    this.stateManager.back(this.summaryForm);
  }
}

<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="anag-icon-m rgi-ui-icon-contacts"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>_ANAG_._LABEL_._CONTACTS_</span>
    <span class="rgi-ui-btn-close" (click)="actionClose()" data-qa="close-modal"></span>
  </div>
  <form [formGroup]="contactsForm" (submit)="updateContacts()">
    <div class="rgi-ui-panel-content" *ngIf="stateMgr.getState$() | async as state">

      <div class="rgi-ui-grid">
        <div class="rgi-ui-grid-2">
          <div class="rgi-ui-col" *ngIf="isFieldVisible('mainContact')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._MAIN_CONTACT_</label>
              <select rgiRxNativeSelect [formControlName]="'mainContact'" data-qa="mainContact">
                <option selected></option>
                <option *ngFor="let contact of mainContacts" [value]="contact.codice">{{contact.descrizione}}</option>
              </select>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-grid rgi-ui-col" *ngIf="isFieldVisible('landlineNumberIntPrefix') || isFieldVisible('landlineNumberPrefix') || isFieldVisible('landlineNumber')">
            <div class="rgi-ui-col" *ngIf="isFieldVisible('landlineNumberIntPrefix')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>_ANAG_._LABEL_._LANDLINE_NO_</label>
                <select rgiRxNativeSelect [formControlName]="'landlineNumberIntPrefix'" data-qa="landlineNumberIntPrefix">
                  <option *ngFor="let prefix of internationalPrefixes"
                          [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('landlineNumberPrefix')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'landlineNumberPrefix'"
                       data-qa="landlineNumberPrefix"
                       placeholder="{{'_ANAG_._LABEL_._PREFIX_'| translate}}">
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('landlineNumber')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'landlineNumber'" (blur)="applyFormat('landlineNumber', contactsForm.controls.landlineNumber.value, formatNumber?.get('landlineNumberIntPrefix'))" (focusin)="removeFormat('landlineNumber', contactsForm.controls.landlineNumber.value)"
                       data-qa="landlineNumber"
                       placeholder="{{'_ANAG_._LABEL_._NUMBER_'| translate}}">
              </rgi-rx-form-field>
            </div>
          </div>
          <div class="rgi-ui-grid rgi-ui-col" *ngIf="isFieldVisible('faxNumberIntPrefix') || isFieldVisible('faxNumberPrefix') || isFieldVisible('faxNumber')">
            <div class="rgi-ui-col" *ngIf="isFieldVisible('faxNumberIntPrefix')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>_ANAG_._LABEL_._FAX_NO_</label>
                <select rgiRxNativeSelect [formControlName]="'faxNumberIntPrefix'" data-qa="faxNumberIntPrefix">
                  <option *ngFor="let prefix of internationalPrefixes"
                          [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('faxNumberPrefix')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'faxNumberPrefix'"
                       data-qa="faxNumberPrefix"
                       placeholder="{{'_ANAG_._LABEL_._PREFIX_'| translate}}">
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('faxNumber')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'faxNumber'" (blur)="applyFormat('faxNumber', contactsForm.controls.faxNumber.value, formatNumber?.get('faxNumberIntPrefix'))" (focusin)="removeFormat('faxNumber', contactsForm.controls.faxNumber.value)"
                       data-qa="faxNumber"
                       placeholder="{{'_ANAG_._LABEL_._NUMBER_'| translate}}">
              </rgi-rx-form-field>
            </div>
          </div>
          <div class="rgi-ui-grid rgi-ui-col" *ngIf="isFieldVisible('phoneNumberIntPrefix') || isFieldVisible('phoneNumberPrefix') || isFieldVisible('phoneNumber')">
            <div class="rgi-ui-col" *ngIf="isFieldVisible('phoneNumberIntPrefix')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>_ANAG_._LABEL_._MOBILE_NO_</label>
                <select rgiRxNativeSelect [formControlName]="'phoneNumberIntPrefix'" data-qa="phoneNumberIntPrefix">
                  <option *ngFor="let prefix of internationalPrefixes"
                          [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('phoneNumberPrefix')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'phoneNumberPrefix'"
                       data-qa="phoneNumberPrefix"
                       placeholder="{{'_ANAG_._LABEL_._PREFIX_'| translate}}">
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('phoneNumber')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'phoneNumber'" (blur)="applyFormat('phoneNumber', contactsForm.controls.phoneNumber.value, formatNumber?.get('phoneNumberIntPrefix'))" (focusin)="removeFormat('phoneNumber', contactsForm.controls.phoneNumber.value)"
                       data-qa="phoneNumber"
                       placeholder="{{'_ANAG_._LABEL_._NUMBER_'| translate}}">
              </rgi-rx-form-field>
            </div>
          </div>
          <div class="rgi-ui-grid rgi-ui-col" *ngIf="isFieldVisible('phoneNumberIntPrefix2') || isFieldVisible('phoneNumberPrefix2') || isFieldVisible('phoneNumber2')">
            <div class="rgi-ui-col" *ngIf="isFieldVisible('phoneNumberIntPrefix2')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>_ANAG_._LABEL_._MOBILE_NO_2_</label>
                <select rgiRxNativeSelect [formControlName]="'phoneNumberIntPrefix2'" data-qa="phoneNumberIntPrefix2">
                  <option *ngFor="let prefix of internationalPrefixes"
                          [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('phoneNumberPrefix2')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'phoneNumberPrefix2'"
                       data-qa="phoneNumberPrefix2"
                       placeholder="{{'_ANAG_._LABEL_._PREFIX_'| translate}}">
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('phoneNumber2')">
              <rgi-rx-form-field>
                <label>&nbsp;</label>
                <input rgiRxInput [formControlName]="'phoneNumber2'" (blur)="applyFormat('phoneNumber2', contactsForm.controls.phoneNumber2.value, formatNumber?.get('phoneNumberIntPrefix2'))" (focusin)="removeFormat('phoneNumber2', contactsForm.controls.phoneNumber2.value)"
                       data-qa="phoneNumber2"
                       placeholder="{{'_ANAG_._LABEL_._NUMBER_'| translate}}">
              </rgi-rx-form-field>
            </div>
          </div>
          <div class="rgi-ui-col" *ngIf="isFieldVisible('email')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._EMAIL_</label>
              <input rgiRxInput [formControlName]="'email'" data-qa="email">
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="isFieldVisible('email2')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._EMAIL2_</label>
              <input rgiRxInput [formControlName]="'email2'" data-qa="email2">
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="isFieldVisible('email3')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._EMAIL3_</label>
              <input rgiRxInput [formControlName]="'email3'" data-qa="email3">
            </rgi-rx-form-field>
          </div>
        </div>
      </div>
    </div>
    <rgi-rx-snackbar tag="error-tag"></rgi-rx-snackbar>
    <div class="rgi-ui-panel-footer">
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()"
              data-qa="anag-btn-cancel" translate>_ANAG_._BTN_._CANCEL_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="submit"
              data-qa="anag-btn-confirm" translate>_ANAG_._BTN_._CONFIRM_
      </button>
    </div>
  </form>

</div>


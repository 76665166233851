import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxDateTimeTimerComponent} from './date-time-timer/timer.component';
import {RgiRxDateTimeTimerBoxComponent} from './date-time-timer-box/timer-box.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {NumberFixedLenPipe} from './numberedFixLen.pipe';


@NgModule({
  declarations: [
    RgiRxDateTimeTimerComponent,
    RgiRxDateTimeTimerBoxComponent,
    NumberFixedLenPipe
  ],
  imports: [
    CommonModule,
    RgiRxI18nModule
  ],
  exports: [
    RgiRxDateTimeTimerComponent,
    RgiRxDateTimeTimerBoxComponent
  ]
})
export class RgiRxDateTimeTimerModule {
}

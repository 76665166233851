import {Injectable, Inject} from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { DossierStates, DossierStatus, OTPDocument, OTP_ABILITATION} from '../models/otp-document.model';
import { HttpClient } from '@angular/common/http';
import { SystemPropertiesService } from '@rgi/life-issue-card';
import { PV_TOKEN } from '@rgi/life-postsales-card';
import { catchError, switchMap } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageSubType, MessageType } from '@rgi/portal-ng-core';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Injectable({
  providedIn: 'root'
})
export class OtpService {
  baseApiUrl: string;
  private enabledOtp = false;
  downloadedDocuments: DossierStatus = null;
  dossierStatus: {msg: string[], type: MessageType, detail: MessageSubType | 'warningMessages' | 'infoMessages'}[];

  set otpAbilitation(state: boolean) {
    this.enabledOtp = state;
  }

  get otpAbilitation(): boolean {
    return this.enabledOtp;
  }

  constructor(
              private httpClient: HttpClient,
              @Inject(PV_TOKEN.ENV) protected environment: any,
              protected systemPropertyService: SystemPropertiesService,
              private sanitizer: DomSanitizer,
              protected translate: RgiRxTranslationService
  ) {
    this.baseApiUrl = environment.api.portal.host;
  }

  /**
   * Retrieves the movement documents for a given policy and movement ID.
   * @param idPolicy - The ID of the policy.
   * @param idMovement - The ID of the movement.
   * @returns An Observable that emits an array of OTPDocument objects.
   *          Returns null if either idPolicy or idMovement is null.
   */
  getMovementDocuments(idPolicy: string, idMovement: number): Observable<OTPDocument[]>  {
    if (!idMovement) {
      console.error('Movement id null');
      return of(null);
    }
    if (!idPolicy) {
      console.error('Policy id null');
      return of(null);
    }
    const url = `${this.baseApiUrl}/api/rest/gel/dossiers?idMov=${idMovement}&idPol=${idPolicy}`;
    return this.httpClient.get<OTPDocument[]>(url);
  }

  /**
   * Signs the documents with the specified policy, movement, and subject ID.
   * @param idPolicy - The ID of the policy.
   * @param idMovement - The ID of the movement.
   * @param idSoggetto - The ID of the subject.
   * @returns An Observable that emits the response from the API call.
   */
  signDocuments(idPolicy: string, idMovement: string, idSoggetto: string): Observable<any> {
    if (!idPolicy) {
      return of(null);
    }
    const url = `${this.baseApiUrl}/api/rest/gel/otp/infocert?idMov=${idMovement}&idPol=${idPolicy}&idParty=${idSoggetto}`;
    return this.httpClient.get<any>(url);
  }

  downloadDossier(idPolicy: string, idMovement: string, idDoc: string): Observable<DossierStatus> {
    if (!idPolicy || !idMovement) {
      return of(null);
    }
    const url = `${this.baseApiUrl}/api/rest/gel/otp/dossier?idMov=${idMovement}&idPol=${idPolicy}`;

    let returningObs: Observable<DossierStatus> = this.httpClient.get<DossierStatus>(url);
    if (!!this.downloadedDocuments) {
      returningObs = of(this.downloadedDocuments);
    }
    return returningObs.pipe(
      catchError(() => {
        let msg = [''];
        this.translate.translate('_IIAB_._OTP_._OTP_SERVICE_KO').subscribe((translated) => msg = [translated]);
        this.dossierStatus = [{msg, type: MessageType.error, detail: MessageSubType.validationError}];
        return throwError('error');
      }),
      switchMap((res: DossierStatus) => {
        if (!res) {
          return EMPTY;
        }
        this.splitDocuments(res);
        return of(res);
      })
    );
  }

  splitDocuments(dossier: DossierStatus) {
    this.dossierStatus = this.getDocumentErrorsMapped(dossier);
    this.downloadedDocuments = dossier;
  }

  callOtpAbilitation(): Observable<boolean> {
    return this.systemPropertyService.getSystemPropByKeys(['otpAbilitation', 'OtpAbilitation']).pipe(
      switchMap((res: {systemProperties: {key: string, value: string} []}) => {
        this.otpAbilitation = !!res && !!res.systemProperties && res.systemProperties
        .map(p => p.value.toLocaleLowerCase()).includes(OTP_ABILITATION.ENABLED);
        return of(this.otpAbilitation);
      })
    );
  }

  getDocumentContent(idDoc: string, idDossier: string): SafeResourceUrl {
    if (this.downloadedDocuments.status !== DossierStates.COMPLETED) {
      return null;
    }
    const document = this.downloadedDocuments && this.downloadedDocuments.documents && this.downloadedDocuments.documents
    .find(doc => doc.idDocumento === idDoc);
    return document && this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + document.data);
  }

  /**
   * error = ABORTED | DORMANT | OTHER (#red)
   *
   * warning = PENDING_COMPLETION | RUNNING (#yellow)
   *
   * info = COMPLETED
   */
  getDocumentErrorsMapped(dossier: DossierStatus): {msg: string[], type: MessageType, detail: MessageSubType | 'infoMessages' | 'warningMessages'}[] {
    switch (dossier.status) {
      case DossierStates.COMPLETED:
        return [{msg: [dossier.statusMessage], type: MessageType.info, detail: 'infoMessages'}];

      case DossierStates.PENDING_COMPLETION:
      case DossierStates.RUNNING:
        return [{msg: [dossier.statusMessage], type: MessageType.warning, detail: 'warningMessages'}];

      case DossierStates.DORMANT:
      case DossierStates.ABORTED:
      case DossierStates.OTHER:
        return [{msg: [dossier.statusMessage], type: MessageType.error, detail: MessageSubType.validationError}];

      default:
        return [];
    }
  }

  clearDocuments() {
    this.downloadedDocuments = null;
    this.dossierStatus = [];
  }

}

export class AnagBirthAddress {
  public countryCode: string | null;
  public adminLevel1: string | null;
  public adminLevel1Short: string | null;
  public adminLevel2: string | null;
  public adminLevel2Short: string | null;
  public adminLevel3: string | null;
  public adminLevel3Short: string | null;
  public cap: string | null;
  public country: AnagEntityIta | null;
  public formatAddress: string | null;
  public id: string | null;
  public normalized: boolean;
  public locality: string | null;
  public subLocality: string | null;
  public subLocalityCode: string | null;
  public toponym: AnagEntityIta;
  public placeAddress: string | null;
  public number: string | null;
  public at: string | null;

}

export class AnagResidence {
  public country: any;
  public province: any;
}

export class AnagEntityIta {
  public descrizione?: string;
  public codice: string;

  constructor(codice, descrizione) {
    this.codice = codice;
    this.descrizione = descrizione;
  }
}

export class AnagApiEntity {
  public description?: string;
  public code: string;

  constructor(code, description) {
    this.code = code;
    this.description = description;
  }
}

export class AnagApiEntityExternal {
  public chiave: string;
  public valore: string;

  constructor(chiave, valore) {
    this.chiave = chiave;
    this.valore = valore;
  }
}

export class EntityUtils {
  static toEngEntity(entityIta: AnagEntityIta): AnagApiEntity {
    return new AnagApiEntity(entityIta.codice, entityIta.descrizione);
  }

  static toItaEntity(engEntity: AnagApiEntity): AnagEntityIta {
    return new AnagEntityIta(engEntity.code, engEntity.description);
  }
}

import {
  AfterContentInit,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  QueryList,
} from "@angular/core";
import {RgiRxTabWrapperDirective} from "../rgi-rx-tab-wrapper.directive";
import {ActiveDescendantKeyManager} from "@angular/cdk/a11y";
import {RgiRxTabHeaderClassBuilder} from '../rgi-rx-tab-builder';
import {
  RgiRxTabGroupLayoutDirective,
  RgiRxTabGroupColorDirective,
  RgiRxTabGroupLayoutChanges
} from '../rgi-rx-tab-group.semantic.directives';
import {combineLatest, EMPTY, of, Subscription} from 'rxjs';

@Component({
  selector: "rgi-rx-tab-header",
  templateUrl: "./rgi-rx-tab-header.component.html",
  host: {
    class: "rgi-ui-tab-header",
    "aria-multiselectable": "false",
    role: "tablist",
    "[attr.aria-label]": "ariaLabel",
  },
})
export class RgiRxTabHeaderComponent
  implements AfterContentInit, OnDestroy {
  @ContentChildren(RgiRxTabWrapperDirective, {descendants: true})
  _allTabWrappers: QueryList<RgiRxTabWrapperDirective>;
  private _keyManager: ActiveDescendantKeyManager<RgiRxTabWrapperDirective>;
  private _focused: number;
  @Input() ariaLabel: string;

  #computedClasses: string;
  #computedStyles: string;

  #semanticSubscription = Subscription.EMPTY;

  constructor(
    @Optional() color: RgiRxTabGroupColorDirective,
    @Optional() layout: RgiRxTabGroupLayoutDirective
  ) {
    if (color) {
      const color$ = color ? color.changes : EMPTY;
      const layout$ = layout ? layout.changes : of({} as RgiRxTabGroupLayoutChanges);
      this.#semanticSubscription = combineLatest([color$, layout$])
        .subscribe(([color, layout]) => {
          const build = RgiRxTabHeaderClassBuilder.create()
            .withColor(color)
            .withTabsRow(layout.tabsRow)
            .withMinWidthTab(layout.minWidthTab)
            .withMinWidthTabUnits(layout.minWidthTabUnits)
            .withWordBreak(layout.wordBreak)
            .withLayout(layout.layout)
            .build();
          this.#computedClasses = build.classes;
          this.#computedStyles = build.style;
        });
    }
  }

  get focused(): number {
    return this._focused;
  }

  set focused(value: number) {
    this._focused = value;
  }

  ngAfterContentInit(): void {
    this._keyManager = new ActiveDescendantKeyManager(this._allTabWrappers)
      .withHorizontalOrientation("ltr")
      .withWrap();
  }

  get keyManager(): ActiveDescendantKeyManager<RgiRxTabWrapperDirective> {
    return this._keyManager;
  }

  @HostBinding('class')
  get computedClasses(): string {
    return this.#computedClasses;
  }

  @HostBinding('style')
  get computedStyles(): string {
    return this.#computedStyles;
  }

  ngOnDestroy(): void {
    this.#semanticSubscription.unsubscribe();
  }


}

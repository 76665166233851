import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcToolConfComponent} from './component/lpc-tool-conf/lpc-tool-conf.component';
import {LpcDatepickerModule} from '../lpc-datepicker/lpc-datepicker.module';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {ReactiveFormsModule} from '@angular/forms';
import {LpcToolComponent} from './component/lpc-tool/lpc-tool.component';
import {LpcKarmaFundModule} from '../lpc-karma-funds/lpc-karma-fund.module';

import {LpcKarmaDatepickerModule} from '../lic-datepicker/lpc-karma-datepicker.module';
import {LpcCheckboxAccordionModule} from '../lpc-checkbox-accordion/lpc-checkbox-accordion.module';
import {LpcPercentFormGroupModule} from '../lpc-percent-form-group/lpc-percent-form-group.module';
import { LpcDateStepperModule } from '../lpc-date-stepper/lpc-date-stepper.module';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';

@NgModule({
  declarations: [
    LpcToolConfComponent,
    LpcToolComponent
  ],
  imports: [
    CommonModule,
    LpcDatepickerModule,
    PortalNgCoreModule,
    ReactiveFormsModule,
    LpcKarmaFundModule,
    LpcKarmaDatepickerModule,
    LpcCheckboxAccordionModule,
    LpcPercentFormGroupModule,
    LpcDateStepperModule,
    RgiCountryLayerModule,
    RgiRxI18nModule,
    LpcRequiredModule
  ],
  exports: [
      LpcToolConfComponent,
      LpcToolComponent
  ]
})
export class LpcToolsModule { }

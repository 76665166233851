import {PortalNgCommonTranslationKeys} from './translation.keys';

export const ES: PortalNgCommonTranslationKeys = {
    PORTAL_NG_COMMON: {
        ADD: 'Add',
        ADD_DOCUMENT: 'Add document',
        DOCUMENTS_MANAGER: 'Documents Manager',
        DOCUMENT_TYPE: 'Document type',
        DOCUMENT_VALIDATION_MESSAGE: 'Proceed with the validation of the document?',
        DOWNLOAD: 'DOWNLOAD',
        INPUT_DOCUMENTS: 'Input Documents',
        LOAD: 'LOAD',
        NO_FILES: 'No files',
        NOTE: 'Note',
        OUTPUT_DOCUMENTS: 'Output Documents',
        SAVE: 'SAVE',
        STATE: 'State',
        UPDATE_FILE: 'Update file',
        UPLOAD: 'UPLOAD',
        UPLOAD_NEW_DOCUMENT: 'Upload new document',
        VALIDATION: 'Validation',
        VALIDATION_DOCUMENT: 'Validation document',
        DELETE_DOC: 'Delete document',
        FILE: 'File',
        CANCEL: 'Borrar',
        CONFIRM: 'Confirm',
        CODE: 'Code',
        NODE_LIST: 'Lista de Nodos'
    }
}
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'claims-historical-erosion-detail',
  templateUrl: './historical-erosion-detail.component.html',
  styleUrls: ['./historical-erosion-detail.component.scss']
})
export class HistoricalErosionDetailComponent implements OnInit {
  @Input() historyDetail: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  visual(element: any) {
    if (element && element.descrizione) {
      return element.descrizione;
    } else {
      return '-';
    }
  }

}

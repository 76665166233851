<div class="subj" [formGroup]="parentForm" [attr.data-qa-role]="role">

  <ng-container *ngTemplateOutlet="(isEmpty) ? isNotFilled : isFilled; context:{form: parentForm}"></ng-container>

</div>

<!-- TEMPLATE -->

<ng-template #isNotFilled>
  <input  [attr.data-qa-role]="role+'-role-no-value'"
          class="drop-container"
          [ngClass]="{'dashed-100' : wholeColumn, 'dashedSelect' : !wholeColumn , 'is-invalid': parentForm.invalid && submitted}"
          [value]="select"
          readonly
          (click)="openAnag()"
  >
</ng-template>

<ng-template #isFilled let-form=form>
  <div [ngClass]="{'col-lg-12': wholeColumn, 'col-lg-6': !wholeColumn}" class="input-group" [formGroup]="form.get('val')">
    <input [ngClass]="{'percentage' : showPercentage}"
           value="{{fill()}}"
           type="text"
           name="extraInformations_0"
           class="form-control effectiveHolderName"
           readonly="" [attr.data-qa-subj]="fill()+'-role-'+role"
           [attr.data-qa-role]="role+'-role-value'"
    >
    <input *ngIf="showPercentage"
           [ngClass]="{'percentage' : showPercentage}"
           type="number"
           [placeholder]="'Percentuale'"
           class="form-control"
           [formControl]="form.get('perc')"
    >
    <div class="input-group-btn">
      <button [disabled]="isDeleteDisabled()" type="button" class="btn btn-default" (click)="resetMethod()" [attr.data-qa-role]="role+'-role-button-del'">
        <span class="rgifont rgi-trash"></span>
      </button>
      <button type="button" class="btn btn-default" (click)="editSubject(val.objectId, role)" [attr.data-qa]="'modify-' + role">
        <span class="rgifont rgi-pencil"></span>
      </button>
    </div>
  </div>
</ng-template>

import { HttpClient } from '@angular/common/http';
import { Inject, Optional, Injectable } from '@angular/core';
import { PV_TOKEN } from '../models/consts/lpc-consts';
import { KarmaAuthService } from './karma-auth.service';

@Injectable()
export class KarmaService {

    private PATH: string;
    private data;

    constructor(protected http: HttpClient,
                @Optional() @Inject(PV_TOKEN.KARMA_ENV) protected environment: any = {apiUrl: ''},
                protected authService: KarmaAuthService) {
      this.PATH = this.environment.apiUrl + '/policies?policyExt.eq=';
    }

    getData() {
        return this.data;
    }

    getPolicyKarma(numPol: string) {
        return this.authService.initSSO().toPromise().then(
            on => {
                this.data = this.authService.getToken();
                this.authService.token = on;
                return this.http.get(this.PATH + numPol).toPromise();
            }
        );
    }

    public getBankAccount(idParty: any) {
        return this.authService.initSSO().toPromise().then(
            on => {
                this.data = this.authService.getToken();
                this.authService.token = on;
                return this.http.get(this.environment.apiUrl + '/parties/' + idParty + '/bank_accounts');
            }
        );
    }
}

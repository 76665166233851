<div class="grid no-date">
  <label class="label-core">{{value}}</label>
  <lpc-required *ngIf="config.required" required="true"></lpc-required>
  <form [formGroup]="group">
    <input lpcFormatPayment
           (blur)="onBlur()"
           [paymentInput]="config"
           [formControlName]="config.code"
           class="form-control"
           [attr.data-qa]="'mic-input-payment-'+config.code">
           <br>
  </form>
</div>

import {ActiveRoute, RgiRxQueryData, RgiRxRouteData, RgiRxURIDef} from './router.api';
import {RGI_RX_WILDCARD_TEMPLATE_REGEXP, RgiRxTemplateInterpolationService} from '@rgi/rx';
import {Injectable} from '@angular/core';
import {cloneDeep, merge, omit} from 'lodash';
import {RgiRxRouteDef} from './routing.service';

@Injectable({
  providedIn: 'root'
})
export class RgiRxUrlParser {


  constructor(private templateInterpolator: RgiRxTemplateInterpolationService) {
  }

  serializeActiveRoute(activeRoute: ActiveRoute): RgiRxURIDef {
    return this.serializeRoute(activeRoute.route, activeRoute.getRouteData());
  }

  serializeRoute(route: string, data?: RgiRxRouteData<unknown>) {
    const matches = route.match(RGI_RX_WILDCARD_TEMPLATE_REGEXP);
    const matchedKeys = !!matches ? matches.map(
      k => k.replace('{', '').replace('}', '')
    ) : [];

    const routePath = this.templateInterpolator.interpolate(route, data, RGI_RX_WILDCARD_TEMPLATE_REGEXP);
    const serializedQuery = this.serializeQueryString(data, matchedKeys);
    return {
      path: routePath,
      query: serializedQuery,
      url: this.toUrl(routePath, serializedQuery)
    };
  }

  serializeRouteDef(def: RgiRxRouteDef, data?: RgiRxRouteData<unknown>): RgiRxURIDef {
    return this.serializeRoute(def.path, data);
  }
  serializeQueryString(routeData: RgiRxRouteData<any>, ignoreKeys: string[] = []) {
    if (routeData) {
      const serializeRouteData = omit(this.serializeRouteData(routeData), ignoreKeys);
      let queryString = '';
      for (const serializeRouteDataKey in serializeRouteData) {
        if (Array.isArray(serializeRouteData[serializeRouteDataKey])) {
          serializeRouteData[serializeRouteDataKey].forEach(val => {
            queryString += `${serializeRouteDataKey}=${val}&`;
          });
        } else {
          queryString += `${serializeRouteDataKey}=${serializeRouteData[serializeRouteDataKey]}&`;
        }
      }
      if (queryString.endsWith('&')) {
        queryString = queryString.substring(queryString.length - 1, 0);
      }
      return queryString;
    }
    return '';
  }

  deSerializeQueryString<T>(query: string, ignoreKeys: string[] = []): RgiRxRouteData<T> {
    const urlSearchParams = new URLSearchParams(query);
    const routeData = {};

    const exist = (key, data: any) => !!data[key];
    urlSearchParams.forEach((value, key, parent) => {
      try {
        if (exist(key, routeData)) {
          if (Array.isArray(routeData[key])) {
            routeData[key].push(JSON.parse(value));
          } else {
            routeData[key] = [].concat(routeData[key], JSON.parse(value));
          }
        } else {
          routeData[key] = JSON.parse(value);
        }
      } catch (e) {
        if (exist(key, routeData)) {
          if (Array.isArray(routeData[key])) {
            routeData[key].push(value);
          } else {
            routeData[key] = [].concat(routeData[key], value);
          }
        } else {
          routeData[key] = value;
        }
      }
    });
    return routeData as RgiRxRouteData<T>;
  }

  deserializeURLData(routeDef: RgiRxRouteDef, def: RgiRxURIDef, routeData: RgiRxRouteData<unknown> | RgiRxQueryData): RgiRxRouteData<any> {
    const routeSegments = routeDef.path.split('/');
    const pathSegments = def.path.split('/');
    const result = {};
    routeSegments.forEach(rs => {
      if (rs.match(RGI_RX_WILDCARD_TEMPLATE_REGEXP)) {
        const key = rs.replace('{', '').replace('}', '');
        const pathValue = pathSegments[routeSegments.indexOf(rs)];
        try {
          result[key] = JSON.parse(pathValue);
        } catch (e) {
          result[key] = decodeURIComponent(pathValue);
        }
      }
    });
    const pathMatchedKeys = Object.keys(result);
    return merge({}, this.reduceRouteData(routeData, pathMatchedKeys), result, this.deSerializeQueryString(def.query, pathMatchedKeys));
  }


  private reduceRouteData(routeData: RgiRxRouteData<unknown>, keys: string[]) {
    return omit(routeData, keys);
  }

  private serializeRouteData(routeData: RgiRxRouteData<any>): any {
    const routeDataClone = cloneDeep(routeData) as any;
    for (const routeDataKey in routeDataClone) {
      if (routeData.hasOwnProperty(routeDataKey)) {
        if (typeof routeData[routeDataKey] === 'object' && !Array.isArray(routeData[routeDataKey])) {
          routeDataClone[routeDataKey] = encodeURIComponent(JSON.stringify(routeData[routeDataKey]));
        }
        if (Array.isArray(routeData[routeDataKey])) {
          routeDataClone[routeDataKey] = routeDataClone[routeDataKey].map(o => {
            if (typeof o === 'object') {
              return encodeURIComponent(JSON.stringify(o));
            }
            return o;
          });
        }
      }
    }
    return routeDataClone;
  }

  toUrl(path: string, serializedQuery?: string) {
    return serializedQuery ? `${path}?${serializedQuery}` : path;
  }
}

import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import moment, { Moment } from 'moment';
import { Color, Label } from 'ng2-charts';
import { of, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { FundsDetail, FundsTrend, GsFundDetail, Revaluation } from '../../../models/life-detail.model';
import { FinancialService } from '../../../services/financial.service';
import { ProposalDetailService } from '../../../services/proposal-detail.service';
import { PlcCurrencyUtils } from '../../../utils/plc-currency';
import { PlcDateUtils } from '../../../utils/plc-date-utils';
import { Frequency } from '../life-trend-detail/life-trend-detail.component';
import { FundsTrendRequest } from './../../../models/fundsRequest.model';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Component({
  selector: 'lpc-life-financial-detail',
  templateUrl: './life-financial-detail.component.html',
  styleUrls: ['./life-financial-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LifeFinancialDetailComponent implements OnInit, OnDestroy, AfterViewChecked {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };
  public quoteNumberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 4
  };
  protected lineChartNumberFormat: Intl.NumberFormatOptions = {
    style: 'currency',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };

  // section viewer
  initializeViewDetail = false;
  initializeViewTrend = false;
  trendFound = false;

  // response container for the first section
  fundsDetail: FundsDetail;

  openMessageModal = false;
  serviceError = false;
  modalMessage = EMPTY_STR;

  // values container for chart
  marketValue: number[] = [];

  // portfolio fund display
  public portfolioFund: any;

  gsFund: GsFundDetail;

  @Output() eventPropagation = new EventEmitter<any>();
  @Input() data: any; // contains data from the previous page
  @Input() id: string;

  // ############################### CHART #####################################
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartColors: Color[] = [
    {
      borderColor: '#6290b6',
      backgroundColor: 'rgba(204, 215, 224, 0.44)',
    },
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];
  public subscription: Subscription[] = [];
  public onInitEnd = false;
  public startDate: string;
  public endDate: string;
  public frequencies: Frequency[] = [];
  public chartErrors = [];
  public isGsFund = false;
  public revaluationRates: Revaluation[];
  public prevalueDates  = true;

  formatterPipe: RgiCountryLayerNumberFormatPipe = null;

  // ####################################################################
  public lineChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return this.formatterPipe.transform(tooltipItem.value, '', this.lineChartNumberFormat);
        }
      }
    }
  };


  public formGroup = new UntypedFormGroup({
      startDate: new UntypedFormControl(null, [Validators.required]),
      endDate: new UntypedFormControl(null, [Validators.required]),
      frequency: new UntypedFormControl(null, [Validators.required]),
      startDateRevaluation: new UntypedFormControl(null),
      endDateRevaluation: new UntypedFormControl(null)
  });

  constructor(
    protected financialService: FinancialService,
    protected translate: TranslationWrapperService,
    protected proposalDetailService: ProposalDetailService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe) {
      this.formatterPipe = rgiNumberFormatter;
      this.currencyFormatterOptions.currency = currencyService.currency;
      this.lineChartNumberFormat.currency = currencyService.currency;
  }

  ngOnInit() {
    this.initFrequencies();
    this.startDate = this.translate.getImmediate('lpc_startDate');
    this.endDate = this.translate.getImmediate('lpc_end_date');
    if (!!this.data && !!this.data.finantialFunds &&
      (this.data.finantialFunds.element || this.data.finantialFunds.fund || this.data.finantialFunds.riskCode )) {
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      if (this.isGSFromPolicyNotLinked()) {
        this.gsFundServiceNotLinked();
      } else if (!!this.isGSFromPolicy()) {
        this.gsFundService(this.data.lifeContract.policyNumber);
      }
      if (!!this.isGSFromConsultation()) {
        this.isGsFund = true;
        this.fundDetailRevaluationRates(this.data.finantialFunds.element.idCrypt);
      }
      if (!!this.isNonGSFund()) {
        this.nonGSFundService();
        if (!!this.isNonGSFundFromConsultation()) {
          this.eventPropagation.emit(JS_EVENT.LOADER.START);
          this.financialService.getPortfolioFund(this.data.finantialFunds.element.idCrypt).subscribe(pf => {
            this.portfolioFund = pf;
            this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          }, error => {
            this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          });
        }
      }
      this.onInitEnd = true;
    }
  }

  fundDetailRevaluationRates(id: string) {
    this.financialService.getDetailFundByIdCrypt(id).pipe(
      concatMap(respDetailFundByIdCrypt => {
        if (!!respDetailFundByIdCrypt && !!respDetailFundByIdCrypt.id) {
          this.fixEuroSymbol(respDetailFundByIdCrypt);
          this.fundsDetail = respDetailFundByIdCrypt;
          return this.financialService.getRevaluationRates(this.fundsDetail.id);
        }
      })
    )
    .subscribe(resultRevaluation => {
      this.revaluationRates = resultRevaluation.revaluationRates;
      this.addValueToChart(this.revaluationRates);
      this.initializeViewDetail = true;
      this.plcGraphicFix.fixCardGraphic(this.id);
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      this.formGroup.removeControl('startDate');
      this.formGroup.removeControl('endDate');
      this.formGroup.removeControl('frequency');
    }, error => {
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    });
  }

  protected addValueToChart(resultRevaluation: Revaluation[]) {
    this.marketValue = resultRevaluation.map(element => +element.value);
    this.lineChartLabels = resultRevaluation.map(element => PlcDateUtils.isoToFormattedDate(element.fromDate));
    this.lineChartData.push({ data: this.marketValue });
    this.preValuedDates(resultRevaluation);
    this.trendFound = true;
  }

  protected preValuedDates(revaluations) {
    if (!!revaluations && !!revaluations.length && this.prevalueDates) {
      // The list is ordered from back-end so i can keep the first and the last element to prevalue the form dates
      this.formGroup.get('startDateRevaluation').setValue(new Date(revaluations[0].fromDate));
      this.formGroup.get('endDateRevaluation').setValue(new Date(revaluations[revaluations.length - 1].fromDate));
      // The dates are pre valued only at component on init
      this.prevalueDates = false;
    }
  }

  gsFundServiceNotLinked() {
    const contractNumber = this.data.lifeContract.policyNumber || this.data.lifeContract.proposalNumber;
    this.subscription.push(
      this.proposalDetailService
      .getBenefitVersion(contractNumber, this.data.finantialFunds.riskCode, this.data.finantialFunds.movmentId).subscribe(response => {
        this.gsFund = response;
        /* ######### booleani che attivano i componenti dopo la transizione da js ######### */
        this.initializeViewDetail = true;
        this.plcGraphicFix.fixCardGraphic(this.id);
        this.initializeViewTrend = true;
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      }, error => {
        console.log('Error to call GS fund Service');
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);

        if (!!error) {
          this.openMessageModal = true;
          this.serviceError = true;
          this.modalMessage = error.message;
        }
      }));
  }

  gsFundService(policyNumber: string) {
    this.subscription.push(this.financialService.getGsDetailFund(policyNumber)
      .subscribe(response => {
        this.gsFund = response;
        /* ######### booleani che attivano i componenti dopo la transizione da js ######### */
        this.initializeViewDetail = true;
        this.plcGraphicFix.fixCardGraphic(this.id);
        this.initializeViewTrend = true;
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      }, error => {
        console.log('Error to call GS fund Service');
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);

        if (!!error) {
          this.openMessageModal = true;
          this.serviceError = true;
          this.modalMessage = error.message;
        }
      })
    );
  }

  getDetailCardTitle(): string {
    if (!this.data.finantialFunds.gs) {
      return this.translate.getImmediate('lpc_funds_details_and_summary');
    }
    if (!!this.data.finantialFunds.gs && !this.data.finantialFunds.warrantyName) {
      return this.translate.getImmediate('lpc_separate_management_detail');
    }
    if (!!this.data.finantialFunds.gs && !!this.data.finantialFunds.warrantyName) {
      return this.translate.getImmediate('lpc_not_linked_detail', {name : this.data.finantialFunds.warrantyName });
    }
  }

  isGSFromPolicy(): boolean {
    return !!this.data.finantialFunds.gs && !!this.data.lifeContract;
  }

  // atterro sul dettaglio fondo gs da consultazione polizza selezionando una garanzia attiva
  isGSFromPolicyNotLinked(): boolean {
    return !!this.data.finantialFunds.gs && !!this.data.lifeContract && !!this.data.finantialFunds.riskCode;
  }

  // atterro sul dettaglio fondo gs da consultazione fondi e profili
  isGSFromConsultation(): boolean {
    return !!this.data.finantialFunds.gs && !!this.data.finantialFunds.element;
  }

  isNonGSFund(): boolean {
    return !this.data.finantialFunds.gs;
  }

  isNonGSFundFromPolicy(): boolean {
    return !this.data.finantialFunds.gs && !!this.data.lifeContract;
  }

  isNonGSFundFromConsultation(): boolean {
    return !this.data.finantialFunds.gs && !!this.data.finantialFunds.element;
  }

  private nonGSFundService() {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.subscription.push(this.financialService.getDetailFunds(this.getParamName())
      .pipe(switchMap(result => {
        this.fixEuroSymbol(result);
        this.fundsDetail = result;
        this.initializeViewDetail = true;
        this.plcGraphicFix.fixCardGraphic(this.id);
        return this.financialService.getFundsTrend(result.idCrypt, this.getDefaultFieldsForFundsTrend());
      }), catchError(error => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        if (!!error) {
          this.openMessageModal = true;
          this.serviceError = true;
          this.modalMessage = error.message;
          return throwError(error);
        } else {
          return of(null);
        }
      })).subscribe(secondResult => {
        if (secondResult && secondResult.fundValues && secondResult.fundValues.length > 0) {
          const fundsTrend: FundsTrend[] = secondResult.fundValues;
          this.marketValue = fundsTrend.map(element => element.marketValue);
          this.lineChartLabels = fundsTrend.map(element => PlcDateUtils.isoToFormattedDate(element.valueData));
          this.lineChartData.push({data: this.marketValue});
          this.trendFound = true;
        }
        this.initializeViewTrend = true;
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      }, error => {
        console.error(error);
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      })
    );
  }

  private getParamName(): string {
    const isinSymbol = this.data.finantialFunds?.fund.isinSymbol || this.data.finantialFunds?.element.isinSymbol;
    const fundCode = this.data.finantialFunds?.fund.code || this.data.finantialFunds?.element.code;
    if (!!isinSymbol) {
      return 'isinCode=' + isinSymbol;
    } else if (!!fundCode) {
      return 'fundCode=' + fundCode;
    }
  }


  getDefaultFieldsForFundsTrend(): FundsTrendRequest {
    const request: FundsTrendRequest = {
      startDate: (!!this.data.finantialFunds.contractData)
        ? this.data.finantialFunds.contractData.effectDate
        : this.fundsDetail.startDate,
      endDate: !!this.fundsDetail.lastDate ? this.fundsDetail.lastDate : moment().format('YYYY-MM-DD'),
      frequency: EMPTY_STR
    };
    request.startDate = this.checkDatesPrevalAndReturnEndDate(request.endDate);
    this.formGroup.get('startDate').setValue(new Date(request.startDate));
    this.formGroup.get('endDate').setValue(new Date(request.endDate));
    this.handlingFrequencies();
    request.frequency = this.formGroup.get('frequency').value.toString();
    return request;
  }

  checkDatesPrevalAndReturnEndDate(endDate: string): string {
    const sysDate = moment().format('YYYY-MM-DD');
    let minDate = EMPTY_STR;
    let startDate: string;
    const issueDate = (!!this.data.finantialFunds.contractData)
    ? this.data.finantialFunds.contractData.effectDate : null;
    minDate = PlcDateUtils.compareTwoDatesAndReturnMin(sysDate, endDate);
    if (!!issueDate) {
      minDate = PlcDateUtils.compareTwoDatesAndReturnMin(minDate, issueDate);
      startDate = PlcDateUtils.compareTwoDatesAndReturnMax(minDate, this.fundsDetail.startDate);
    } else {
      startDate = this.fundsDetail.startDate;
    }
    return startDate;
  }

  handlingFrequencies() {
    this.chartErrors = [];
    const chartGroup = this.formGroup;
    const startDate = moment(chartGroup.get('startDate').value);
    const endDate = moment(chartGroup.get('endDate').value);
    this.initFrequencies();
    const occurrenciesMap = this.occurrenciesForInterval(moment(endDate), moment(startDate), this.frequencies );
    this.setFrequencies(occurrenciesMap);
    this.setGranularFrequency(occurrenciesMap);
  }

  occurrenciesForInterval(startDate: Moment, endDate: Moment, interval: Frequency[]): any {
    const occurrenciesMap: any = {};
    if (!moment(startDate).isSame(endDate)) {
      interval.map((el: Frequency) => {
        const occurrencies = this.countOccurrencies(startDate, endDate, el.id);
        if (Math.trunc(occurrencies) > 1 && occurrencies < 50) {
          occurrenciesMap[el.id] = occurrencies;
        }
      });
    } else {
      occurrenciesMap[11] = 1;
    }
    return occurrenciesMap;
  }

  showChart() {
    return this.lineChartData.length !== 0 && this.frequencies.length !== 0;
  }

  countOccurrencies(startDate: Moment, endDate: Moment, interval: number): number {
    switch (interval) {
      case 11:  return (Math.abs(endDate.diff(startDate, 'days'))) + 1 ;         // giornaliero
      case 10:  return (Math.abs(endDate.diff(startDate, 'days')) / 7) + 1;      // settimanale
      case 8:  return Math.abs(endDate.diff(startDate, 'months')) + 1;           // mensile
      case 7:  return (Math.abs(endDate.diff(startDate, 'months')) / 3) + 1 ;     // trimestrale
      case 6:  return (Math.abs(endDate.diff(startDate, 'months')) / 6) + 1;    // semestrale
      case 12:  return Math.abs(endDate.diff(startDate, 'years')) + 1;          // annuale
    }
  }

  setGranularFrequency(occurenciesMap: any) {
    let max = occurenciesMap[Object.keys(occurenciesMap)[0]];
    Object.keys(occurenciesMap).forEach((value) => {
      const temp = occurenciesMap[value];
      max = temp > max ? temp : max;
    });
    this.formGroup.get('frequency').setValue(+Object.keys(occurenciesMap).find(el => occurenciesMap[el] === max));
  }

  setFrequencies(occurenciesMap: any) {
    const frequencyArray = [];
    Object.keys(occurenciesMap).forEach((value, key) => {
      frequencyArray.push({id : +value, description: this.frequencies.find(el => el.id === +value ).description});
    });
    this.frequencies = frequencyArray;
  }


  onChangeStartDate(event) {
    this.handlingFrequencies();
  }

  onChangeEndDate(event) {
    this.handlingFrequencies();
  }

  updateChart() {
    this.lineChartData = [];
    this.chartErrors = [];
    const chartForm = this.formGroup;
    if (chartForm.valid) {
      const request: FundsTrendRequest = {
        startDate: moment(
          this.formGroup.get('startDate').value)
          .format('YYYY-MM-DD'),
        endDate: moment(
            this.formGroup.get('endDate').value)
            .format('YYYY-MM-DD'),
        frequency: this.formGroup.get('frequency').value
      };
      this.loadFundsTrend(request);
    } else {
      this.setFeErrors();
    }
  }

  setFeErrors() {
    const startDateControl = this.formGroup.get('startDate');
    const endDateControl = this.formGroup.get('endDate');
    const frequencyControl = this.formGroup.get('frequency');
    if (startDateControl.hasError('effectDate')) {
      this.chartErrors.push(this.getEffectDateError());
    }
    if (startDateControl.hasError('dateRange')) {
      this.chartErrors.push(
        this.translate.getImmediate(
          'lpc_start_date_must_be_earlier_than_the_end_date'
        )
      );
    }
    if (
      startDateControl.hasError('required') ||
      endDateControl.hasError('required') ||
      frequencyControl.hasError('required')
    ) {
      this.chartErrors.push(
        this.translate.getImmediate('lpc_error_mandatory_fields')
      );
    }
  }

  public getEffectDateError(): string {
    return this.translate.getImmediate(
      'lpc_start_date_cannot_be_earlier_than_the_effective_date',
      {
        value: PlcDateUtils.isoToFormattedDate(
          this.data.lifeContract.effectDate
        )
      }
    );
  }

  initFrequencies() {
    this.frequencies = [
      { id: 6, description: this.translate.getImmediate('lpc_six_monthly') },
      { id: 7, description: this.translate.getImmediate('lpc_quarterly') },
      { id: 8, description: this.translate.getImmediate('lpc_monthly') },
      { id: 11, description: this.translate.getImmediate('lpc_daily') },
      { id: 10, description: this.translate.getImmediate('lpc_weekly') },
      { id: 12, description: this.translate.getImmediate('lpc_annual') }
    ];
  }

  loadFundsTrend(request: FundsTrendRequest) {
    this.trendFound = false;
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.financialService.getFundsTrend(this.fundsDetail.idCrypt, request).subscribe(secondResult => {
      if (secondResult && secondResult.fundValues && secondResult.fundValues.length > 0) {
        const fundsTrend: FundsTrend[] = secondResult.fundValues;
        this.marketValue = fundsTrend.map(element => element.marketValue);
        this.lineChartLabels = fundsTrend.map(element => PlcDateUtils.isoToFormattedDate(element.valueData));
        this.lineChartData.push({ data: this.marketValue });
        this.trendFound = true;
      }
      this.initializeViewTrend = true;
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    },
    error => {
      console.error(error);
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    });
  }

  ngOnDestroy(): void {
    if (!!this.subscription) {
      this.subscription.forEach(subscription => subscription.unsubscribe());
    }
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  back() {
    if (!!this.data.info) {
      const info = this.data.info;
      this.eventPropagation.emit({eventName: JS_EVENT.BACK_TO_FINANCIAL_LIST, info});
    } else {
      this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
    }
  }

  private fixEuroSymbol(result: FundsDetail) {
    if (!!result && !!result.currency) {
      result.currency = PlcCurrencyUtils.fixEuroSymbol(result.currency);
    }
  }

  updateRevaluationRates() {
    let startDate;
    let endDate;
    this.lineChartData = [];
    if (!!this.formGroup.get('startDateRevaluation') && !!this.formGroup.get('startDateRevaluation').value) {
      startDate = moment(this.formGroup.get('startDateRevaluation').value).format('YYYY-MM-DD');
    }
    if (!!this.formGroup.get('endDateRevaluation') && !!this.formGroup.get('endDateRevaluation').value) {
      endDate = moment(this.formGroup.get('endDateRevaluation').value).format('YYYY-MM-DD');
    }
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.trendFound = false;
    this.financialService.getRevaluationRates(this.fundsDetail.id, startDate, endDate).subscribe( response => {
      this.addValueToChart(response.revaluationRates);
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    },
    error => {
      console.error(error);
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    });
  }
}

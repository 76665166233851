import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcDefinitionTableComponent} from './lpc-definition-table/lpc-definition-table.component';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
  declarations: [LpcDefinitionTableComponent],
  exports: [
    LpcDefinitionTableComponent
  ],
  imports: [
    CommonModule,
    RgiCountryLayerModule,
    RgiRxI18nModule
  ]
})
export class LpcDefinitionTableModule { }

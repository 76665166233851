import {ApiTaxComponent} from '../api-models/api-tax-component';

export class Warranty {
  constructor(
    public title: string,
    public taxableAmount: number,
    public taxRate: number,
    public taxes: number,
    public antiMoneyLaunderingTaxes: number,
    public grossAmount: number,
    public ssn: number,
    public taxComponents: Array<ApiTaxComponent>
  ) {
  }
}

import {APP_INITIALIZER, getPlatform, Injector, PLATFORM_INITIALIZER} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {DEFAULT_LOGGER_CONFIG, Logger, LoggerConfig, LoggerStore, logLevelAsString, RGI_RX_LOGGER_CONFIG, RGI_RX_LOGGER_PLATFORM} from './logging-api';
import {RgiRxLogger} from './rgi-rx-logger';
import {StreamLoggerStoreHandler} from './logger-store-handler.service';
import {LoggerStoreService} from './logger-store.service';

export const RGI_RX_APP_LOGGER = [
  {provide: LoggerStore, useClass: LoggerStoreService, deps: [StreamLoggerStoreHandler]},
  {provide: StreamLoggerStoreHandler, useClass: StreamLoggerStoreHandler, deps: [RGI_RX_LOGGER_CONFIG]},
  {provide: RGI_RX_LOGGER_PLATFORM, useFactory: appLoggerFromPlatform, deps: [Injector]},
  {provide: APP_INITIALIZER, multi: true, useFactory: BrowserConsoleLoggerProvider, deps: [Injector]}
];


let RGI_RX_WARNING_NOTIFIED = false;

export function rgiRxLoggerFactory(injector: Injector) {
  try {
    const streamLoggerStoreHandler = injector.get(StreamLoggerStoreHandler);
    return new RgiRxLogger(streamLoggerStoreHandler);
  } catch (e) {
    if (console && !RGI_RX_WARNING_NOTIFIED) {
      console.warn('rgiRxPlatformLogger is not configured, using NOP Logger');
      RGI_RX_WARNING_NOTIFIED = true;
    }
    return NOP_LOGGER;
  }
}

export function appLoggerFromPlatform(injector: Injector): boolean {
  try {
    return !injector.get(RGI_RX_LOGGER_PLATFORM);
  } catch (e) {
    return true;
  }
}

export function rgiRxBrowserPlatformLogger(config?: LoggerConfig) {
  return [
    {provide: RGI_RX_LOGGER_CONFIG, useValue: config ? config : DEFAULT_LOGGER_CONFIG},
    {provide: StreamLoggerStoreHandler, useClass: StreamLoggerStoreHandler, deps: [RGI_RX_LOGGER_CONFIG]},
    {provide: RgiRxLogger, useClass: RgiRxLogger, deps: [StreamLoggerStoreHandler]},
    {provide: LoggerStore, useClass: LoggerStoreService, deps: [StreamLoggerStoreHandler]},
    {provide: RGI_RX_LOGGER_PLATFORM, useValue: true},
    {provide: Platform, useClass: Platform, deps: []},
    {
      provide: PLATFORM_INITIALIZER,
      multi: true,
      useFactory: BrowserConsoleLoggerProvider,
      deps: [Injector]
    }
  ];
}

export function LoggerFactory(injector?: Injector): Logger {
  if (!!injector) {
    return rgiRxLoggerFactory(injector);
  }
  const platformRef = getPlatform();
  if (!platformRef) {
    throw new Error('Cannot create Logger, no @Angular/core Platform can be resolved');
  }
  return rgiRxLoggerFactory(platformRef.injector);
}

const NOP_LOGGER: Logger = {
  debug: () => {
  },
  error: () => {
  },
  info: () => {
  },
  log: () => {
  },
  trace: () => {
  },
  warn: () => {
  },
};


export function BrowserConsoleLoggerProvider(injector: Injector) {
  const platform: Platform = injector.get(Platform);
  const enabled: boolean = injector.get(RGI_RX_LOGGER_PLATFORM);
  const store: LoggerStore = injector.get(LoggerStore);

  return () => {
    function getConsole(): Partial<Console>{
      return !platform.TRIDENT ? console : (window.console ? window.console : NOP_LOGGER);
    }

    if (platform.isBrowser && enabled) {
      const console = getConsole();
      store.takeLast$()
        .subscribe(
          event => {
            console[logLevelAsString(event.level)].call(this, event.message, ...event.options);
          }
        );
    }
  };
}

import {Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {RgiRxDragBodyComponent} from './rgi-rx-drag-body/rgi-rx-drag-body.component';

export interface RgiRxOnDragRemove {
  event?: Event;
  drag: RgiRxDragBodyComponent;
}

@Directive({
  selector: '[rgiRxDragRemove], rgi-rx-drag-remove',
})
export class RgiRxDragRemoveDirective {
  private _class;
  private _description: string | TemplateRef<any>;
  @Output() onRemove = new EventEmitter<RgiRxOnDragRemove>();
  @Input() get class() {
    return this._class;
  }
  set class(value) {
    this._class = value;
  }
  @Input() get description() {
    return this._description;
  }
  set description(value) {
    this._description = value;
  }
}

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {CoInsuranceDTO} from '../../../models/domain-models/co-insurance';

import {ModalService} from '@rgi/rx/ui';
import {
  ReIssueNewQuotaModalComponent
} from './re-issue-co-insurance-modal/re-issue-new-share-modal/re-issue-new-quota-modal.component';
import {ReIssueCoInsuranceModalComponent} from './re-issue-co-insurance-modal/re-issue-co-insurance-modal.component';
import {Subscription} from 'rxjs';
import {ReIssueCoInsuranceEnum} from '../../../enums/re-issue-co-insurance-enum';
import {formatNumber} from '@angular/common';

@Component({
  selector: 're-issue-co-insurance',
  templateUrl: './re-issue-co-insurance.component.html',
  styleUrls: ['./re-issue-co-insurance.component.css']
})
export class ReIssueCoInsuranceComponent implements OnInit, OnChanges, OnDestroy {

  readonly LOCALE_ID = 'en-EN';
  readonly DIGITS_INFO = '1.2-2';

  @Input() coInsurance: CoInsuranceDTO;
  @Input() coInsuranceTypes: any;
  @Input() companies: any;
  @Input() intermediaries: any;

  @Output() coInsuranceEmitter: EventEmitter<any> = new EventEmitter<any>();

  coInsuranceForm: UntypedFormGroup;
  showPercentage: boolean;
  subscription: Subscription = new Subscription();
  percentageTotal: number;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.initializeForm();
    this.setRequired();
    this.setListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coInsuranceTypes && changes.coInsuranceTypes.currentValue) {
      if (!this.coInsurance.coinsuranceType) {
        this.coInsurance.coinsuranceType = this.coInsuranceTypes.find(coInsTypes => coInsTypes.code == ReIssueCoInsuranceEnum.NO_COINSURANCE);
      }

      this.showPercentage = !this.isTypeNoCoInsurance();
      this.initializeForm();
      this.setRequired();
      this.setListeners();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initializeForm() {
    this.coInsuranceForm = this.formBuilder.group({
      coInsuranceTypes: [{
        value: this.coInsurance.coinsuranceType ? this.coInsurance.coinsuranceType.code : ReIssueCoInsuranceEnum.NO_COINSURANCE,
        disabled: false
      }],
      ourPercentage: [{
        value: this.coInsurance.ourPerc ? this.coInsurance.ourPerc : undefined,
        disabled: false
      }, []]
    }, {
      validator: [this.percentageValidator('ourPercentage')]
    });
  }

  openCoInsuranceModal() {
    const {modal, component} = this.isTypeThirdParty()
      ? this.modalService.openComponent(ReIssueNewQuotaModalComponent)
      : this.modalService.openComponent(ReIssueCoInsuranceModalComponent);

    component.coInsuranceQuota = this.coInsurance.coinsuranceQuotas[0];
    component.coInsuranceDTO = this.coInsurance;
    component.companies = this.companies;
    component.intermediaries = this.intermediaries;
    component.percentageTotal = this.percentageTotal;

    modal.onClose.subscribe(() => {
      this.emitValues();
    });
  }

  setRequired() {
    const control = this.coInsuranceForm.controls.ourPercentage;
    this.showPercentage ? control.setValidators(Validators.required) : control.clearValidators();
  }

  setListeners() {
    const coInsuranceTypesListener = this.coInsuranceForm.controls.coInsuranceTypes.valueChanges
      .subscribe(val => {
        this.onCoInsuranceTypeChange(val);
      });
    const ourPercentageListener = this.coInsuranceForm.controls.ourPercentage.valueChanges
      .subscribe(val => {
        this.coInsurance.ourPerc = val;
      });
    this.subscription.add(coInsuranceTypesListener);
    this.subscription.add(ourPercentageListener);
  }
  onCoInsuranceTypeChange(val: any) {
    this.coInsurance.coinsuranceType = this.coInsuranceTypes.find(coInsTypes => coInsTypes.code == val);
    this.showPercentage = val != ReIssueCoInsuranceEnum.NO_COINSURANCE;
    this.resetCoInsurance();
    this.setRequired();
    this.emitValues();
  }

  emitValues() {
    this.coInsuranceEmitter.emit({
      formValid: this.isTypeNoCoInsurance() ? true : !this.coInsuranceForm.invalid,
      percentageValid: this.isTypeNoCoInsurance() || this.isTypeThirdParty() ? true : this.checkCoInsPercentageValid(),
      quotasValid: this.isTypeNoCoInsurance() ? true : this.coInsurance.coinsuranceQuotas.length > 0
    });
  }

  calculatePercentageTotal() {
    this.percentageTotal = this.coInsurance.ourPerc ? this.parseStringToNumber(this.coInsurance.ourPerc) : 0;
    if (this.coInsurance.coinsuranceQuotas) {
      this.coInsurance.coinsuranceQuotas.forEach(quota => {
        this.percentageTotal += this.parseStringToNumber(quota.quotaPerc);
        this.percentageTotal = this.formatNumber(this.percentageTotal);
      });
    }
  }

  checkCoInsPercentageValid() {
    if (this.isTypeNoCoInsurance()) {
      return true;
    }

    const ourPerc = this.coInsuranceForm.controls.ourPercentage.value;
    let out = ourPerc != undefined && !this.coInsuranceForm.invalid;
    if (this.isTypeLeadCoInsurer()) {
      this.calculatePercentageTotal();
      out = this.percentageTotal === 100;
    }
    return out;
  }

  resetCoInsurance() {
    this.coInsuranceForm.controls.ourPercentage.reset();
    this.coInsurance.coinsuranceQuotas = [];
  }

  percentageValidator(controlNameDate: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlNameDate];
      this.calculatePercentageTotal();
      const isValid = control.value != undefined && parseInt(control.value) != 0 && parseInt(control.value) < 100 && this.percentageTotal <= 100;
      if (!isValid) {
        control.setErrors({'RE_ISSUE.PERCENTAGE_ERROR': true});
      } else {
        control.setErrors(null);
      }
    };
  }

  isTypeNoCoInsurance() {
    return this.coInsurance.coinsuranceType.code == ReIssueCoInsuranceEnum.NO_COINSURANCE;
  }

  isTypeLeadCoInsurer() {
    return this.coInsurance.coinsuranceType.code == ReIssueCoInsuranceEnum.LEAD_CO_INSURER;
  }

  isTypeThirdParty() {
    return this.coInsurance.coinsuranceType.code == ReIssueCoInsuranceEnum.THIRD_PARTY_MANDATE;
  }

  parseStringToNumber(value: string): number {
    return this.formatNumber(Number(value));
  }

  formatNumber(value: number): number {
    return Number(formatNumber(value, this.LOCALE_ID, this.DIGITS_INFO));
  }

}

import {Directive, TemplateRef} from '@angular/core';
import {CdkStepLabel} from '@angular/cdk/stepper';

@Directive({
  selector: 'ng-template[rgiRxStepLabel]',
  providers: [{provide: CdkStepLabel, useExisting: RgiRxStepLabelDirective}]
})
export class RgiRxStepLabelDirective extends CdkStepLabel {

  constructor(template: TemplateRef<any>) {
    super(template);
  }
}

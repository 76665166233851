import { Injectable } from '@angular/core';
import { ActiveRoute } from "@rgi/rx/router";
import {
    AbstractStateManager,
    StateStoreService
} from '@rgi/rx/state';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { EvaluatorObjectI } from '../evaluation/models/evaluator-object';
import { EvaluatorProductI } from '../evaluation/models/evaluator-products';
import { SurveyEvaluateRouteData } from './models/survey-evaluate-route-data';
import { BTN__MAP_EVAL, SurveyEvaluateState } from './models/survey-evaluate-state';
import { SurveyEvaluateStateOperations } from './operations/survey-evaluate-state-operations.service';


@Injectable()
export class SurveyEvaluateStateManagerService<S extends SurveyEvaluateState> extends AbstractStateManager<S>{


    constructor(
        activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        private _ops: SurveyEvaluateStateOperations<S>
    ) {

        super(activeRoute, stateStoreService);

        let st = this.initState();
        this.updateState$(of(st));

    }


    protected initState(): S {
        let st: S = this.stateStoreService.get(this.activeRoute.key) || <S>(new SurveyEvaluateState(this.activeRoute.key));
        st.activeRouteId = this.activeRoute.id;
        st.routeOptions = this.activeRoute.routeOptions;
        if (!st.navigation) {
            st.navigation = {
                stickable: true,
                map: BTN__MAP_EVAL,
            }
        }
        const routeData = <SurveyEvaluateRouteData>this.activeRoute.getRouteData();
        st.surveyVersion = routeData.surveyVersion;
        st.selectEnabled = typeof (routeData.selectEnabled) == 'boolean' ? routeData.selectEnabled : st.selectEnabled;
        st.evaluatorProducts = routeData.evaluatorProducts || st.evaluatorProducts;
        st.isEdit = typeof (routeData.isEdit) == 'boolean' ? routeData.isEdit : st.isEdit;
        st.integrationFilter = routeData.integrationFilter || st.integrationFilter;
        st.evaluationPersisted = typeof (routeData.evaluationPersisted) == 'boolean' ? routeData.evaluationPersisted : st.evaluationPersisted;
        st.forwardData = routeData.forwardData;
        st.initialized = false;
        return st;
    }


    protected emitState(st$: Observable<S>) {
        this.updateState$(
            st$.pipe(
                mergeMap(st => this._ops.beforeUpdate$(st))
            )
        );
    }


    actionInit() {
        let st: S = this.stateStoreService.get(this.activeRoute.key) || <S>(new SurveyEvaluateState(this.activeRoute.key));
        let st$: Observable<S> = this._ops.getInit$(of(st));
        this.emitState(st$);
    }


    /**
     * Override this action when need to add custom button
     * @param btnId
     * @example
     * if( btnId==='myButton') {
     *    const st$ = getDoSomeOperation$(of(this.getCurrentState()));
     *    this.emitState(st$);
     * }else {
     *    super.actionNavigationClick(btnId)
     * }
     */
    actionNavigationClick(btnId: string, validate: () => boolean) {
        let st$: Observable<S>;

        switch (btnId) {
            case "BACK":
                st$ = this._ops.getBack$(of(this.getCurrentState()));
                break;
                case "PRINT":
                    st$ = this._ops.getPrint$(of(this.getCurrentState()));
                    break;
                case "MODIFY":
                    if (validate()) {
                  st$ = this._ops.getEnableModify$(of(this.getCurrentState()));
                }
                break;
            case "SAVE":
                if (validate()) {
                  st$ = this._ops.getSave$(of(this.getCurrentState()));
                }
                    break;
                case "CONTINUE":
                    if (validate()) {
                  st$ = this._ops.getContinue$(of(this.getCurrentState()));
                }
                    break;
        }

        st$ && this.emitState(st$);

    }


    actionSelectProduct(product: EvaluatorProductI) {
        const st$: Observable<S> = this._ops.getSelectProduct$(of(this.getCurrentState()), product);
        this.emitState(st$);
    }


    actionSelectCoverage(covOrPkg: EvaluatorObjectI, product: EvaluatorProductI, isPackage: boolean, selected: boolean) {
        const st$: Observable<S> = this._ops.getSelectCoverage$(of(this.getCurrentState()), covOrPkg, product, isPackage, selected);
        this.emitState(st$);
    }


    actionToggleGroupingByLob(enabled: boolean) {
        const st$: Observable<S> = this._ops.getToggleGroupingByLob$(of(this.getCurrentState()), enabled);
        this.emitState(st$);
    }


    actionToggleVisibilityLob(code: string) {
        const st$: Observable<S> = this._ops.getToggleVisibilityLob$(of(this.getCurrentState()), code);
        this.emitState(st$);
    }


    actionToggleSelectionForCoveragesOrPackages(product: EvaluatorProductI, selected: boolean) {
        const st$: Observable<S> = this._ops.getToggleSelectionForCoveragesOrPackages$(of(this.getCurrentState()), product, selected);
        this.emitState(st$);
    }


    actionToggleProductContent(product: EvaluatorProductI){
        const st$: Observable<S> = this._ops.getToggleProductContent( of(this.getCurrentState()), product );
        this.emitState(st$);
    }
}



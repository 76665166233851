import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { RoutingHostDirective, RoutingService } from '@rgi/portal-ng-core';
import {
  CardComponent,
  CardComponentService,
  SessionService,
} from '@rgi/digital-claims-common-angular';
import { ElaborationRequestDetailComponent } from './elaboration-request-detail/elaboration-request-detail.component';
import { ElaborationRequestListComponent } from './elaboration-request-list/elaboration-request-list.component';
import { ElaborationRequestService } from '../elaboration-request.service';
import { NewElaborationRequestComponent } from './new-elaboration-request/new-elaboration-request.component';

@Component({
  selector: 'claims-elaboration-request-single-page',
  templateUrl: './elaboration-request-single-page.component.html',
  styleUrls: ['./elaboration-request-single-page.component.scss'],
  providers: [RoutingService],
})
export class ElaborationRequestSinglePageComponent extends CardComponent implements AfterViewInit {

  @ViewChild(RoutingHostDirective, { static: true })
  hostViewRef: RoutingHostDirective;
  routes: { list: any; detail: any; new: any };

  constructor(
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('elaborationRequestListComponent') elaborationRequestListComponent,
    @Inject('elaborationRequestDetailComponent')
    elaborationRequestDetailComponent,
    @Inject('newElaborationRequestComponent') newElaborationRequestComponent,
    private elaborationRequestService: ElaborationRequestService
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      list: elaborationRequestListComponent,
      detail: elaborationRequestDetailComponent,
      new: newElaborationRequestComponent,
    };
  }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;
    const elaborationRequests =
      this.elaborationRequestService.getSessionElaborationRequests(
        this.card.id
      );
    const elaborationRequestDetailList =
      this.elaborationRequestService.getElaborationRequestDetailList();

    if (elaborationRequestDetailList != null) {
      this.routingService.loadComponent(this.routes.detail);
      (
        this.routingService.componentRef
          .instance as ElaborationRequestDetailComponent
      ).card = this.card;
    } else if (elaborationRequests) {
      this.routingService.loadComponent(this.routes.list);
      (
        this.routingService.componentRef
          .instance as ElaborationRequestListComponent
      ).card = this.card;
    } else {
      this.routingService.loadComponent(this.routes.new);
      (
        this.routingService.componentRef
          .instance as NewElaborationRequestComponent
      ).card = this.card;
    }
  }
}

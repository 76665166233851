<rgi-rx-expansion-panel *ngFor="let asset of assets" [expanded]="false" color="default" >
  <rgi-rx-expansion-panel-header>
    <span rgi-rx-expansion-panel-label translate>{{ asset.description }}</span>
  </rgi-rx-expansion-panel-header>


  <lib-consultation-warranties-assets-factors [factors]="asset.factors"></lib-consultation-warranties-assets-factors>
  <lib-consultation-clauses [clauses]="asset.clauses"></lib-consultation-clauses>
  <lib-consultation-warranties-assets-subjects [subjects]="asset.subjects"></lib-consultation-warranties-assets-subjects>

</rgi-rx-expansion-panel>

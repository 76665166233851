import {PASSPROPRO_SURVEY_CUSTOM_PROPERTIES} from "./constants/survey.constants";
import {SurveyIntegrationFilter} from "../integration/integration.module";

export class SurveyPrintFactory {

  private integrationFilter: SurveyIntegrationFilter;
  private customProperties = new Array<{ key: string, value: string }>();


  private constructor(integrationFilter: SurveyIntegrationFilter) {
    this.integrationFilter = integrationFilter;

  }

  static getFactory(surveyIntegrationFilter: SurveyIntegrationFilter): SurveyPrintFactory {
    return new SurveyPrintFactory(surveyIntegrationFilter);
  }


  build(): Array<{key:string,value:string}>{
    return this.customProperties;
  }

  isKeyAvailable(key): boolean {
    return Object.values(PASSPROPRO_SURVEY_CUSTOM_PROPERTIES).find(customProperty => customProperty === key) != null;
  }

  setProperty(key, value): SurveyPrintFactory {
    /*    if (!this.isKeyAvailable(key)) {
          throw new TypeError("Invalid key " + key + " it's not available as a printable constant");
        }*/

    const filtered = this.customProperties.filter(function (filterProp) {
      return filterProp.key === key;
    });

    if (filtered[0]) {
      filtered[0].value = value;
    } else {
      this.customProperties.push({
        key: key,
        value: value
      });

    }
    return this;
  };

  setProperties = function (extraprops): SurveyPrintFactory {
    Object.keys(extraprops).forEach(key => {
      const filtered = this.customProperties.filter(filterProp => filterProp.key === key);
      if (filtered[0]) {
        filtered[0].value = extraprops[key];
      } else {
        this.customProperties.push({
          key: key,
          value: extraprops[key]
        });
      }
    });
    return this;
  }
}

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="loanGrant" *ngIf="hasRiskFactors()" label="lpc_loan_data" [errors]="errors" fieldId="loanGrant" (next)="onNext($event)">
    <div class="row" *ngIf="isActiveStep('loanGrant'); else showMaxLoanAmountDetail" >
      <div class="col-lg-6 factor-box">
        <div class="grid input-box">
          <label class="label-core" translate>lpc_maxLoanAmount</label>
          <input class="form-control" data-qa="maxLoanAmount" [value]="maxLoanAmount" disabled>
        </div>
      </div>
    </div>
    <ng-template #showMaxLoanAmountDetail>
      <div class="row">
        <div class="col-md-6">
          <span for="label" translate>lpc_maxLoanAmount</span>
          <span for="value">: {{ maxLoanAmount }}</span>
        </div>
      </div>
    </ng-template>

    <div formGroupName="factors" class="row">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateFactors($event)"
                          [form]="formGroup.controls['factors']" [factors]="loanGrantFactors">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="quotation" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event, true)">
    <lpc-formule-quotation-table
          [label]="'Loan data'"
          [definitions]="quotationTableDefinitions"
          [operationCode]="session.operation">
        </lpc-formule-quotation-table>
  </lpc-step>

  <lpc-step id="beneficiaries" [enableAuth]="isAuth" label="lpc_life_detail_beneficiaries" [errors]="errors" fieldId="beneficiaries"
    (next)="onNext($event)" feFieldId="beneficiaries" [feErrors]="feErrors"
    (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-claim-beneficiaries
        formControlName="beneficiaries" [paymentTypes]="paymentTypes"
        [beneficiaryType]="beneficiaryType"
        [idAssicurato]="idAssicurato" [summary]="!isActiveStep('beneficiaries')"
        [questionnairesCode]="getUnfilteredQuestMap('IST')"
        [questionnairesDefinitions]="questionnaireDefinitions"
        [blockBeneficiaries]="blockBeneficiaries"
        (addedSubject)="onAddedSubject($event)"
        (triggerQuestPreval)="onTriggerQuestPreval($event)"
        (loaderQuest)="loaderQuestManager($event)"
        [questFactorsArray]="questFactorsArray" [disabledQuestionArray]="disabledQuestionArray"
        #beneficiaries >
    </lpc-claim-beneficiaries>
</lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length"
            id="warnings"
            label="lpc_warnings"
            [isConfirmAndAccept]="isConfirmAndAccept"
            (confirmAccept)="publish(true)"
            (next)="publish()"
            [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    <lpc-error-messages [errors]="errors" [fieldType]="'tools'"></lpc-error-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [enableAuth]="isAuth"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

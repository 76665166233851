import { EnviromentsService } from './enviroments.service';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { BeneficiaryCathegory } from '../enum/life-issue.enum';
import { EnumErrorMessages, EnumModel, EnumValueModel, LifeEnums } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})

export class LifeSessionService {
  productType: string;
  baseApiUrl: string;
  private _baseApiURLPass: string;
  executionId: string;
  idPvManagement: string;
  codNodeManagement: string;
  partyRoles: EnumValueModel[];
  enumTypeBeneficiary: any = [
    {codice: BeneficiaryCathegory.VITA, descrizione: 'Life'},
    {codice: BeneficiaryCathegory.MORTE, descrizione: 'Death'},
    {codice: BeneficiaryCathegory.CEDOLA, descrizione: 'Coupon'}
  ];
  otherEnums: EnumModel[] = [];
  productCode: string;
  onlyQuickQuotation: boolean;
  private _idPv: string;
  private _operator;
  private _codLoginNode;
  private _idParentSession: string;

  constructor(
    @Inject('$injector') protected injector: any,
    protected http: HttpClient,
    protected environment: EnviromentsService
  ) {
    this.baseApiUrl = environment.baseApiURL;
    this._baseApiURLPass = environment.baseApiPassURL;
    this.idPv = this.authService.getSalePointId();
    this.operator = this.authService.getOperator();
    this.codLoginNode = this.authService.getSalePointCode();
  }

  private get authService() {
    return this.injector.get('authService');
  }

  public get idPv(): string {
    return this._idPv;
  }

  public set idPv(idPv: string) {
    this._idPv = idPv;
  }

  public get operator() {
    return this._operator;
  }

  public set operator(operator) {
    this._operator = operator;
  }

  public get codLoginNode() {
    return this._codLoginNode;
  }

  public set codLoginNode(codLoginNode) {
    this._codLoginNode = codLoginNode;
  }

  public set baseApiURLPass(val: string) {
    this._baseApiURLPass = val;
  }
  public get baseApiURLPass(): string {
    return this._baseApiURLPass;
  }

  public set idParentSession(val: string) {
    this._idParentSession = val;
  }
  public get idParentSession(): string {
    return this._idParentSession;
  }

  initService() {
    return forkJoin([
      this.getExecutionId(),
      this.getEnumBeneficiaries(),
      this.getPartyRolesEnum()]
    );
  }

  protected getExecutionId(): Observable<any> {
    return this.http.get(this.baseApiURLPass + '/ptflife/proposal/' + this.codLoginNode).pipe(
      tap((response: any) => {
        this.executionId = response.serviceInfo.executionId;
      }, err => {
        console.error(err);
      })
    );
  }

  public getProducts(): Observable<any> {
    const request = {
      productsFilter: {
        filterProductsCategory : '1',
        filterSalableProduct: true,
        filterSalableProductTypes: true,
        idSp: this.idPv
      }
    };
    return this.http.post(this.baseApiUrl + '/admin/products', request);
  }

  protected getEnum(codes: string[]): Observable<any> {
    const request = {
        codes
    };
    return this.http.post(this.baseApiUrl + '/v2/life/enums', {enumList: request }, {});
  }

  protected getPartyRolesEnum(): Observable<any> {
    const request = {
      codes: ['ptfall.RuoloSoggetto']
    };

    return this.http.post(this.baseApiUrl + '/system/enums', { enumList: request }).pipe(
      tap((el: any) => {
        this.partyRoles = el.enums[0].values;
      }, err => {
        console.error(err);
      })
    );
  }

  protected getEnumBeneficiaries(): Observable<any> {
    const request = {
        codes: ['PVVBENEF.ETYPEBENEF', 'PVVBENEFICIARIO.ETIPOBENEFICIARIO']
    };
    return this.http.post(this.baseApiUrl + '/v2/life/enums', {enumList: request }, {}).pipe(
      switchMap((response: LifeEnums) => {
          // calling grouping enums for the beneficiary according to the country
          return combineLatest([of(response), this.getEnum(['PVVBENEF.EPOSITIONNUMBER', 'PVVBENEFICIARIO.EPOSITIONNUMBER'])]);
      }),
      switchMap(([benefResponse, otherEnums]: LifeEnums[]) => {
        const errors: EnumErrorMessages[] = [].concat(benefResponse.errorMessages, otherEnums.errorMessages);
        if (!!otherEnums && !!otherEnums.enums) {
          this.otherEnums = this.otherEnums.concat(otherEnums.enums);
        }
        if (benefResponse.enums != null && benefResponse.enums.length > 0) {
          this.enumTypeBeneficiary = benefResponse.enums[0].values;
        }

        if (!!errors.length) {
          return throwError(errors);
        }

        return of({});
      }),
      catchError((errors: EnumErrorMessages[]) => {
        errors.forEach(msg => console.warn(msg.errorDescription));
        return of(null);
      })
    );
  }

  public getOperatorUsername(): string {
    return this.operator.username;
  }

  public getOperator(): any {
    return this.operator;
  }

  setFilterOperator(userName: any , salePointId) {
    if (userName !== undefined && salePointId !== undefined) {
      const availableOperatorFilter = {
        operatorFilter: {
          loginUser: {
            username: userName , password: ''
          },
            salePoint: {identification: salePointId}
          }
      };
      return availableOperatorFilter;
    }
  }

  getAvailableNodes(): Observable<any> {
    return this.http.get(this.baseApiUrl + '/admin/agencies?loginNodeId=' + this.idPv);
  }

  resetService() {
  }

}

<div [formGroup]="formGroup" [ngSwitch]="inputType">

  <div class="form-group" *ngSwitchCase="'ENUM'">
    <label class="label-core" translate>{{definition.code}}</label>
    <lic-required *ngIf="true" required="true"></lic-required>
    <select formControlName="factor" class="form-control" [attr.data-qa]="'factor' + definition.code"
            [attr.data-mod]="'factor' + definition.code + definition.readOnly"
            [attr.data-obb]="'factor' + definition.code + true">
      <option [disabled]="true" [ngValue]="null" translate>lic_SELEZIONA</option>
      <option [ngValue]="option.id" *ngFor="let option of definition.values">{{option.description}}</option>
    </select>
  </div>

  <div class="form-group" *ngSwitchCase="'DATE'">
    <lic-karma-datepicker formControlName="factor" [required]="true" [label]="definition.code"></lic-karma-datepicker>
  </div>

  <div class="form-group" *ngSwitchCase="'DOUBLE'">
    <label class="label-core" translate>{{definition.code}}</label>
    <lic-required *ngIf="true" required="true"></lic-required>
    <input formControlName="factor" class="form-control" [attr.data-qa]="'factor' + definition.code"
           [attr.data-mod]="'factor' + definition.code + definition.readOnly"
           [attr.data-obb]="'factor' + definition.code + true">
  </div>

  <div class="form-group" *ngSwitchCase="'BENEFICIARY'">
    <label class="label-core" translate>lic_Beneficiary</label>
    <lic-required *ngIf="true" required="true"></lic-required>
    <input formControlName="factor" class="form-control" [attr.data-qa]="'factor' + definition.code"
           [attr.data-mod]="'factor' + definition.code + definition.readOnly"
           [attr.data-obb]="'factor' + definition.code + true">
  </div>

  <div class="form-group" *ngSwitchCase="'IBAN'">
    <label class="label-core" translate>IBAN</label>
    <lic-required *ngIf="true" required="true"></lic-required>
    <input formControlName="factor" class="form-control" [attr.data-qa]="'factor' + definition.code"
           [attr.data-mod]="'factor' + definition.code + definition.readOnly"
           [attr.data-obb]="'factor' + definition.code + true">
  </div>

</div>

import {
  ICoreResult,
  PortalCard,
  PortalCardConfig,
  PortalCards,
  PortalConfig,
  portalControllerFactory,
  RGI_RX_PORTAL_TPL,
} from "@rgi/rx/portal";
import { CallBackJumpInstance, DialogueHandlerSearch } from "./dialogue-handler.model";
import { ActiveRoute, RoutingService } from "@rgi/rx/router";
import { RgiRxTranslationService } from "@rgi/rx/i18n";
//name e destination dovrebbero matchare aS
export const DIALOGUE_HANDLER_CARD_SEARCH: PortalCardConfig = {
  card: {
    title: "_CLAIMS_TITLE_DIALOGUE_DASHBOARD_",
    name: "dialoguehandler.search",
    category: "Claims",
    type: "main",
    functions: ['claims.dialogueHandler'],
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "home",
      destination: "dialoguehandler.search",
      label: "Search Gedi",
      type: "home",
    },
  ],
};
export const DIALOGUE_HANDLER_CARD_LIST_SX: PortalCardConfig = {
  card: {
    title: "_CLAIMS_TITLE_DIALOGUE_HANDLER_LIST",
    name: "dialoguehandlerSx.list",
    category: "Claims",
    type: "sub",
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "dialoguehandlerList",
      destination: "dialoguehandlerSx.list",
      label: "_CLAIMS_TITLE_DIALOGUE_HANDLER_LIST",
      type: "home"
    },
  ],
};
export const DIALOGUE_HANDLER_CARD_NEW: PortalCardConfig = {
  card: {
    title: "_CLAIMS_._TITLE_._DIALOGUE_HANDLER_NEW",
    name: "dialoguehandlerNew",
    category: "Claims",
    type: "sub",
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "dialoguehandlerNew",
      destination: "dialoguehandler.new",
      label: "_CLAIMS_._TITLE_._DIALOGUE_HANDLER_NEW",
    },
  ],
};
export const DIALOGUE_HANDLER_CARD_SUMMARY: PortalCardConfig = {
  card: {
    title: "_CLAIMS_._TITLE_._DIALOGUE_HANDLER_SUMMARY",
    name: "dialoguehandlerSummary",
    category: "Claims",
    type: "sub",
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "dialoguehandlerSummary",
      destination: "dialoguehandler.summary",
      label: "_CLAIMS_._TITLE_._DIALOGUE_HANDLER_SUMMARY",
    },
  ],
};
export const DIALOGUE_HANDLER_CARD_LIST: PortalCardConfig = {
  card: {
    title: "_CLAIMS_TITLE_DIALOGUE_HANDLER_LIST",
    name: "dialoguehandler.list",
    category: "Claims",
    type: "sub",
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
  } as PortalCard,
  routes: [
    {
      route: "list",
      destination: "dialoguehandler.list",
      label: "_CLAIMS_TITLE_DIALOGUE_HANDLER_LIST",
    },
  ],
};
export const DIALOGUE_HANDLER_CARD: PortalCards = [
  DIALOGUE_HANDLER_CARD_SEARCH,
  DIALOGUE_HANDLER_CARD_LIST_SX,
  DIALOGUE_HANDLER_CARD_SUMMARY,
  DIALOGUE_HANDLER_CARD_NEW,
  DIALOGUE_HANDLER_CARD_LIST
];

import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, take, tap } from 'rxjs/operators';
import { createMapRoleParties, createObjForNextStep, getSystemKeys } from '../../adapters/group-policy-utils';
import { ROLES } from '../../group-policy-constants/general';
import { ROUTES_GP_CONF_PM } from '../../group-policy-constants/routes-constants';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import {
  BaseEntity,
  CoinsuranceShare,
  CoinsuranceShareList,
  ContractAddressesPut,
  ContractAddressesResp, ErrorCode, ErrorResp,
  Factor, GPClause,
  GPPolicyDataField,
  GpListValues,
  GPExtensionDataSections,
  GroupPolicyContactsManagement, Indexing, MeanOfPayment, PaymentConfig, PaymentFrequency,
  PaymentsPayload,
  PortfolioContactsManagement, SubjectContract, SystemProp,
  SystemProperties,
  paymentPages
} from '../../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyCrossService } from '../../group-policy-services/group-policy-cross.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyPaymentService } from '../../group-policy-services/group-policy-payment.service';
import { GroupPolicyStatePolicyData } from '../group-policy-state';
import {AnagEditPartyResolver, AnagFlowData} from '@rgi/anag';
import {AnagResourceService} from '@rgi/anag';


@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpPolicyDataService {

  constructor(protected groupPolicyService: GroupPolicyResourceService,
              protected routingService: RoutingService,
              protected gpErrorService: GroupPolicyApiRestErrorService,
              protected paymentService: GroupPolicyPaymentService,
              protected integrationService: GroupPolicyIntegrationService,
              protected crossService: GroupPolicyCrossService,
              protected anagService: AnagResourceService,
              protected anagResolver: AnagEditPartyResolver) {
  }

  initPolicyData$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                  activeRoute: ActiveRoute): Observable<GroupPolicyStatePolicyData> {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    return groupPolicyStatePolicyData$.pipe(

      mergeMap((st: GroupPolicyStatePolicyData) =>  {
        return combineLatest(of(st),
          this.getRoles$(of(st), previousStepData),
          this.retrivePolicyData$(of(st), previousStepData),
          this.getPaymentConfigs$(of(st), previousStepData),
          this.getAvailablePayments$(of(st), previousStepData),
          this.initVariables$(of(st), previousStepData.resId, 'true', 'true', 'false'),
          this.retrieveCoinsurances$(of(st), previousStepData.resId),
          this.getClauses$(of(st), previousStepData.resId),
          this.retrieveExtensionData$(of(st), previousStepData)
        );
      }),
      mergeMap(([st]) => {
        return combineLatest(of(st),
          this.retrieveFactors$(of(st), previousStepData.resId, 'true', 'true', 'false')
        );
      }),
      mergeMap(([st]) => {
        return (!st.policyDataFields || !st.policyDataFields.length) ?
          combineLatest(of(st),
            this.retrieveIndexing$(of(st), previousStepData.resId),
            this.getPaymentFrequencies$(of(st), previousStepData),
            this.getConventions$(of(st), previousStepData)
          ) : of([st]);
      }),
      mergeMap(([st]) => {
        return (this.isPolicyHolderPresent(st)) ? this.getSystemProp$(of(st), previousStepData.resId) : of(st);
      }),
      map((st: GroupPolicyStatePolicyData) => {
        st.errors = previousStepData.errors;
        st.type = previousStepData.type;
        st.proposalNumber = null;
        st.fromInquiryInfo.isFromInquiry = previousStepData.isFromInquiry ? previousStepData.isFromInquiry
          : st.fromInquiryInfo.isFromInquiry;
        st.fromInquiryInfo.idParentSession = previousStepData.idParentSession ? previousStepData.idParentSession
          : st.fromInquiryInfo.idParentSession;
        st.fromInquiryInfo.proposalNunmber = previousStepData.inquiryProposalNumber ? previousStepData.inquiryProposalNumber
          : st.fromInquiryInfo.proposalNunmber;
        return st;
      })
    );
  }

  getRoles$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
            data: DataForSteps): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$
      .pipe(
        mergeMap(
          (st) => {
            return combineLatest(of(st), this.groupPolicyService.retrieveContractAddresses$(data.resId));
          }),
        map(
          ([st, adressResp]: [GroupPolicyStatePolicyData, ContractAddressesResp]) => {
            st.contractAddressesResp = adressResp;
            return st;
          }
        ),
        concatMap((st) => {
          st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'ROLES_GET');
          return this.groupPolicyService.getPartyRoles$(data.resId).pipe(
            map(partyRoles => {
              st.allRoleParties = createMapRoleParties(partyRoles);
              return st;
            }),
            catchError(this.gpErrorService.catchApiErrorFn(st, 'ROLES_GET'))
          );
        }),
        concatMap((st: GroupPolicyStatePolicyData) => {
          return this.getAvailablePayments$(of(st), data);
        }),
        catchError(this.gpErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStatePolicyData) => st)
      );
  }

  updateParty$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
               data: DataForSteps,
               reqSubj: SubjectContract) {
    return groupPolicyStatePolicyData$
      .pipe(
        concatMap((st) => {
          st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, ['ROLES_UPD', 'FOOTER_ROLE']);
          return this.groupPolicyService.updatePartyRole$(data.resId, reqSubj).pipe(
            map(this.manageUpdatePartyRespFn(st)),
            catchError(this.gpErrorService.catchApiErrorFn(st, 'ROLES_UPD'))
          );
        }),
        concatMap((st: GroupPolicyStatePolicyData) => {
          return this.getRoles$(of(st), data);
        }),
        concatMap((st: GroupPolicyStatePolicyData) => {
          return (ROLES.Policyholder === reqSubj.role) ?
            this.getSystemProp$(of(st), data.resId) : of(st);
        }),
        concatMap((st: GroupPolicyStatePolicyData) => {
          return (ROLES.Policyholder === reqSubj.role) ?
            this.retrivePolicyData$(of(st), data) : of(st);
        }),
        concatMap((st: GroupPolicyStatePolicyData) => {
          return (ROLES.Policyholder === reqSubj.role) ?
            this.retrieveExtensionData$(of(st), data) : of(st);
        }),
        concatMap((st: GroupPolicyStatePolicyData) => {
          return (ROLES.Policyholder === reqSubj.role) ?
            this.retrieveFactors$(of(st), data.resId, 'true', 'true', 'false') : of(st);
        }),
        catchError(this.gpErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStatePolicyData) => st)
      );
  }

  actionGoToConfigurationPm$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, activeRoute: ActiveRoute,
                             resId: string, contractAddresses: ContractAddressesPut,
                             updateContract: boolean): Observable<GroupPolicyStatePolicyData> {

    return groupPolicyStatePolicyData$.pipe(
      concatMap((st: GroupPolicyStatePolicyData) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, ['ROLES', 'FOOTER','FOOTER_ACTION','FOOTER_ROLE']);
        st.type = ErrorCode.BLOCKING;
        return this.groupPolicyService.checksAll$(resId).pipe(
          map(this.manageChecksAllRespFn(st)),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_ROLE'))
        );
      }),
      concatMap((st: GroupPolicyStatePolicyData) => {
        return this.groupPolicyService.actionCode$(resId, 'TPM').pipe(
          map((resp) => {
            st.errors = this.gpErrorService.manageErrors(resp, 'FOOTER_ACTION');
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER'))
        );
      }),
      concatMap((st: GroupPolicyStatePolicyData) => {
        if (updateContract) {
          return this.updateAddresses$(resId, contractAddresses, st);
        }
        return of(st);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => {
        if (!this.gpErrorService.containsBlockingErrors(st.errors)) {
          this.integrationService.navigate(this.routingService, ROUTES_GP_CONF_PM, createObjForNextStep(resId, st,
            activeRoute.getRouteData()), activeRoute);
        }
        return st;
      })
    );
  }

  actionSave$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
              uuid: string, nodeCode: string, contractAddresses: ContractAddressesPut,
              updateAddresses: boolean, activeRoute: ActiveRoute): Observable<GroupPolicyStatePolicyData> {

    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st: GroupPolicyStatePolicyData) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER');
        st.type = ErrorCode.BLOCKING;
        return this.groupPolicyService.actionCode$(uuid, 'TPM').pipe(
          map((resp) => {
            st.errors = this.gpErrorService.manageErrors(resp, 'FOOTER_ACTION');
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER'))
        );
      }),
      mergeMap((state: GroupPolicyStatePolicyData) => {
        if (updateAddresses) {
          return this.updateAddresses$(uuid, contractAddresses, state);
        }
        return of(state);
      }),
      mergeMap((st: GroupPolicyStatePolicyData) => {
        return this.groupPolicyService.saveAction$(uuid, nodeCode).pipe(
          map((saveResp) => {
            st.proposalNumber = saveResp.proposalNumber;
            this.crossService.showModalContractNumber(saveResp, activeRoute.id).subscribe();
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER'))
        );
      }),
      map((state: GroupPolicyStatePolicyData) => state)
    );
  }

  getPaymentConfigs$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, data: DataForSteps): Observable<any> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st: GroupPolicyStatePolicyData) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_CONF');
        return this.paymentService.getEditablePaymentConfigs$(data.resId, paymentPages.ammData, st).pipe(
          map((paymentConfig: PaymentConfig) => {
            st.editablePayments = paymentConfig;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'PAYMENTS_CONF'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => st)
    );
  }

  retrieveFactors$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, uuid: string, visible = 'true',
                   configurable = 'true', onlyApplication = 'true'): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      concatMap((state: GroupPolicyStatePolicyData) => {
        return combineLatest(of(state), this.groupPolicyService.retrieveFactors$(uuid, visible, configurable, onlyApplication));
      }),
      map(([state, factors]: [GroupPolicyStatePolicyData, any]) => {
        state.factors = factors;
        return state;
      })
    );
  }

  initVariables$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, uuid: string, visible = 'true',
                 configurable = 'true', onlyApplication = 'true'): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      concatMap((state: GroupPolicyStatePolicyData) => {
        return combineLatest(of(state), this.groupPolicyService.initVariables$(uuid, visible, configurable, onlyApplication));
      }),
      map(([state]: [GroupPolicyStatePolicyData, any]) => {
        return state;
      })
    );
  }

  updateAgreement$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, uuid: string, agreement: BaseEntity) {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((state) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'POLICYDATA_AGREEMENT');
        return this.groupPolicyService.updateAgreement$(uuid, agreement).pipe(
          map(_agreementResp => {
            state.conventions.value = agreement;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'POLICYDATA_AGREEMENT')),
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((state: GroupPolicyStatePolicyData) => state)
    );
  }

  updatePaymentFrequency$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, uuid: string, paymentFrequency: BaseEntity) {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((state) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'POLICYDATA_PAYM_FREQ');
        return this.groupPolicyService.updatePaymentFrequency$(uuid, paymentFrequency).pipe(
          map((_resp) => {
            state.paymentFrequencies.value = paymentFrequency;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'POLICYDATA_PAYM_FREQ')),
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((state: GroupPolicyStatePolicyData) => state)
    );
  }

  updateFactors$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                 uuid: string, factors: Factor[]): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      concatMap((state: GroupPolicyStatePolicyData) => {
        return combineLatest(of(state), this.groupPolicyService.updateFactors$(uuid, factors));
      }),
      map(([state, factorsResp]: [GroupPolicyStatePolicyData, any]) => {
        state.factors = factorsResp;
        return state;
      })
    );
  }

  // ???
  updateContacts$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, uuid: string,
                  payload: ContractAddressesPut): Observable<GroupPolicyStatePolicyData> {
    const updateContract$ = this.groupPolicyService.updateContractAddresses$(uuid, payload).pipe(
      catchError((err) => {
        return of(err.error);
      }));
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st: GroupPolicyStatePolicyData) => {
        st.errors = [];
        st.type = ErrorCode.BLOCKING;
        return combineLatest(of(st), updateContract$);
      }),
      mergeMap(([st, contractResp]: [GroupPolicyStatePolicyData, ErrorResp]) => {
        if (this.gpErrorService.thereIsError(contractResp)) {
          this.gpErrorService.manageError(st, contractResp, 'UPDATECONTRACT_ERROR');
          return combineLatest(of(st), of(contractResp));
        } else {
          return combineLatest(of(st), this.groupPolicyService.retrieveContractAddresses$(uuid));
        }
      }),
      map(([st, resp]: [GroupPolicyStatePolicyData, any]) => {
        if (this.gpErrorService.thereIsError(resp)) {
          this.gpErrorService.manageError(st, resp, 'CONTRACTADDRESS_ERROR');
        } else {
          st.contractAddressesResp = resp;
        }
        return st;
      })
    );
  }

  getPaymentFrequencies$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                         data: DataForSteps) {
    return groupPolicyStatePolicyData$
      .pipe(
        mergeMap(
          (st) => {
            return combineLatest(of(st), this.groupPolicyService.getPaymentFrequencies$(data.resId));
          }),
        map(
          ([st, paymentFrequency]: [GroupPolicyStatePolicyData, PaymentFrequency]) => {
            if (paymentFrequency.allowedValues && paymentFrequency.allowedValues.length > 1) {
              paymentFrequency.allowedValues.sort((a, b) => {
                return a.description.localeCompare(b.description);
              });
            }
            if (!paymentFrequency.value && paymentFrequency.allowedValues.length === 1) {
              this.updatePaymentFrequency$(of(st), data.resId, paymentFrequency.allowedValues[0]).pipe(take(1)).subscribe();
              paymentFrequency.value = paymentFrequency.allowedValues[0];
            }
            st.paymentFrequencies = paymentFrequency;
            return st;
          }
        )
      );
  }

  getConventions$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                  data: DataForSteps): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap(
        (st) => {
          return combineLatest(of(st), this.groupPolicyService.getConventions$(data.resId));
        }),
      map(
        ([st, conventions]: [GroupPolicyStatePolicyData, GpListValues]) => {
          if (conventions && conventions.allowedValues.length > 1) {
            conventions.allowedValues.sort((a, b) => {
              return a.description.localeCompare(b.description);
            });
          }
          st.conventions = conventions;
          return st;
        }
      )
    );
  }

  deleteRole$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, data: DataForSteps, role: string, idParty: string) {
    return groupPolicyStatePolicyData$
      .pipe(
        mergeMap(
          (st) => {
            st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, ['ROLES', 'FOOTER']);
            return combineLatest(of(st),
              this.groupPolicyService.deleteRole$(data.resId, role, idParty).pipe(
                catchError((err) => {
                  return of(err.error.message);
                }),
              ));
          }),
        mergeMap(
          ([st]: [GroupPolicyStatePolicyData, any]) => {
            return this.getRoles$(of(st), data);
          }),
        map(
          (st: GroupPolicyStatePolicyData) => {
            if (ROLES.Policyholder === role) {
              st.contractAddressesResp = null;
              st.groupPolicyContactsManagement = GroupPolicyContactsManagement.NONE;
              st.portfolioContactsManagement = PortfolioContactsManagement.NONE;
            }

            return st;
          }
        )
      );
  }

  public getSystemProp$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                        uuid: string): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st: GroupPolicyStatePolicyData) => {
        return combineLatest(of(st),
          this.groupPolicyService.getSystemProp$(getSystemKeys()).pipe(
            tap((systemProp: SystemProp) => {
              if (systemProp && systemProp.systemProperties) {
                systemProp.systemProperties.forEach((singleProp: SystemProperties) => {
                  if (singleProp) {
                    switch (singleProp.key) {
                      case 'GroupPolicyContactsManagement':
                        st.groupPolicyContactsManagement = +singleProp.value;
                        break;
                      case 'PortfolioContactsManagement':
                        st.portfolioContactsManagement = +singleProp.value;
                        break;
                    }
                  }
                });
              }
            })
          ));
      }),
      mergeMap(([st]: [GroupPolicyStatePolicyData, SystemProp]) => {
        return (st.groupPolicyContactsManagement !== GroupPolicyContactsManagement.NONE) ?
          combineLatest(of(st), this.groupPolicyService.retrieveContractAddresses$(uuid)) :
          combineLatest(of(st), of(null));
      }),
      map(([st, resp]: [GroupPolicyStatePolicyData, ContractAddressesResp]) => {
        st.contractAddressesResp = resp;
        return st;
      })
    );
  }

  protected isPolicyHolderPresent(st: GroupPolicyStatePolicyData) {
    return st.allRoleParties &&
      st.allRoleParties[ROLES.Policyholder] &&
      st.allRoleParties[ROLES.Policyholder][0] &&
      st.allRoleParties[ROLES.Policyholder][0].idParty;
  }

  retrieveCoinsurances$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                        uuid: string): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap(
        (st) => {
          return combineLatest(of(st), this.groupPolicyService.retrieveCoinsurances$(uuid));
        }),
      map(
        ([st, coinsurances]: [GroupPolicyStatePolicyData, CoinsuranceShareList]) => {
          st.coinsurances = coinsurances;
          return st;
        }
      )
    );
  }

  updateCoinsurances$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                      coinsuranceShares: Array<CoinsuranceShare>, data: DataForSteps) {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap(
        (st) => {
          const req = {
            coinsuranceType: st.coinsurances.coinsuranceType,
            ourPercentage: st.coinsurances.ourPercentage,
            coinsuranceShares
          } as CoinsuranceShareList;
          return combineLatest(of(st), this.groupPolicyService.updateCoinsurances$(data.resId, req));
        }),
      map(
        ([st]: [GroupPolicyStatePolicyData, any]) => {
          st.coinsurances.coinsuranceShares = coinsuranceShares;
          return st;
        }
      )
    );
  }

  retrieveIndexing$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                    uuid: string): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap(
        (st) => {
          return combineLatest(of(st), this.groupPolicyService.retrieveIndexing$(uuid));
        }),
      map(
        ([st, indexing]: [GroupPolicyStatePolicyData, Indexing]) => {
          st.indexing = indexing;
          return st;
        }
      )
    );
  }

  updateIndexing$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                  selectedIndexing: BaseEntity, uuid: string): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'POLICYDATA_INDEXING');
        return this.groupPolicyService.updateIndexing$(uuid, selectedIndexing).pipe(
          map(_resp => {
            st.indexing.value = selectedIndexing;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'POLICYDATA_INDEXING'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => st)
    );
  }

  getClauses$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, uuid: string): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$
      .pipe(
        mergeMap((st) => {
          st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'CLAUSES_GET');
          return this.groupPolicyService.getProductClauses$(uuid).pipe(
            map(clauses => {
              st.clauses = clauses;
              return st;
            }),
            catchError(this.gpErrorService.catchApiErrorFn(st, 'CLAUSES_GET')),
          );
        }),
        catchError(this.gpErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStatePolicyData) => st)
      );
  }

  updateClauses$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, clauses: GPClause[], data: DataForSteps) {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'CLAUSES_UPD');
        return this.groupPolicyService.updateProductClauses$(data.resId, clauses).pipe(
          map(updClauses => {
            st.clauses = updClauses;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'CLAUSES_UPD')),
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => st)
    );
  }

  setPaymentMethod$(state: Observable<GroupPolicyStatePolicyData>, paymentMethod: PaymentsPayload, data: DataForSteps) {
    const payload = [paymentMethod];
    return state.pipe(
      concatMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_SET');
        return this.groupPolicyService.setPayments$(data.resId, payload).pipe(
          map((_resp) => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'PAYMENTS_SET'))
        );
      }),
      concatMap((st: GroupPolicyStatePolicyData) => {
        return this.getAvailablePayments$(of(st), data);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => st)
    );
  }

  getAvailablePayments$(st$: Observable<GroupPolicyStatePolicyData>, data: DataForSteps): Observable<GroupPolicyStatePolicyData> {
    return st$.pipe(
      mergeMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'PAYMENTS_GET');
        return this.groupPolicyService.getAvailablePayments$(data.resId).pipe(
          map((selectablePayments: MeanOfPayment[]) => {
            st.selectablePayments = selectablePayments;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'PAYMENTS_GET'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => st)
    );
  }

  protected retrivePolicyData$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, previousStepData: DataForSteps) {
    return groupPolicyStatePolicyData$
      .pipe(
        mergeMap((st) => {
          st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'POLICYDATA_FIELDS_GET');
          return this.groupPolicyService.retrivePolicyData$(previousStepData.resId).pipe(
            map((policyDataFields) => {
              policyDataFields.sort((a, b) => {
                return a.order > b.order ? 1 : -1;
              });
              st.policyDataFields = policyDataFields;
              return st;
            }),
            catchError(this.gpErrorService.catchApiErrorFn(st, 'POLICYDATA_FIELDS_GET'))
          );
        }),
        catchError(this.gpErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStatePolicyData) => st)
      );
  }

  updatePolicyData$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                    fieldsToUpdate: GPPolicyDataField[], data: DataForSteps): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st) => {
        // error management will not work if fieldToUpdate's length is !== 1
        // const fieldAreaCode = 'POLICYDATA_FIELDS_UPD' + fieldsToUpdate[0].code;
        const fieldAreaCode = 'POLICYDATA_FIELDS_UPD';
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, [fieldAreaCode,'COINSURANCE_ERROR']);
        return this.groupPolicyService.updatePolicyData$(data.resId, fieldsToUpdate).pipe(
          map(_resp => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, fieldAreaCode)),
        );
      }),
      mergeMap((st: GroupPolicyStatePolicyData) => {
        const coinsuranceOurPercField = fieldsToUpdate.find(el => el.code === 'COINSURANCE_OUR_PERCENTAGE');
        if (coinsuranceOurPercField && coinsuranceOurPercField.valueNumb) {
          st.coinsurances.ourPercentage = coinsuranceOurPercField.valueNumb;
        }
        const coinsuranceTypeFieldUpdated = fieldsToUpdate.find(el => el.code === 'COINSURANCE_TYPE');
        const doReload = fieldsToUpdate.find(field => field.reloadOnChange);
        return combineLatest(
          coinsuranceTypeFieldUpdated ? this.retrieveCoinsurances$(of(st), data.resId) : of(st),
          doReload ? this.retrivePolicyData$(of(st), data) : of(st)
        );
      }),
      mergeMap(([st]) => of(st)),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => st)
    );
  }

  updateExtensionData$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                       fieldsToUpdate: GPPolicyDataField[], data: DataForSteps): Observable<GroupPolicyStatePolicyData> {
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st) => {
        // error management will not work if fieldToUpdate's length is !== 1
        // const fieldAreaCode = 'POLICYDATA_FIELDS_UPD' + fieldsToUpdate[0].code;
        const fieldAreaCode = 'FOOTER_EXTENSION_ERROR';
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, fieldAreaCode);
        return this.groupPolicyService.updateExtensionData$(data.resId, fieldsToUpdate).pipe(
          map(this.manage200ErrorsExtRespFn(st)),
          // gestisco qui gli errori tornati dal BE e li metto in st.errors
          catchError(this.gpErrorService.catchApiErrorFn(st, fieldAreaCode)),
        );
      }),
      mergeMap((st: GroupPolicyStatePolicyData) => {
        const doReload = fieldsToUpdate.find(field => field.reloadOnChange);
        return combineLatest(
          doReload ? this.retrieveExtensionData$(of(st), data) : of(st)
        );
      }),
      mergeMap(([st]) => of(st)),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStatePolicyData) => st)
    );
  }

  protected updateAddresses$(resId: string, contractAddresses: ContractAddressesPut, st: GroupPolicyStatePolicyData) {
    return this.groupPolicyService.updateContractAddresses$(resId, contractAddresses).pipe(
      map(_addressesResp => st),
      catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER'))
    );
  }

  protected manageChecksAllRespFn(st: GroupPolicyStatePolicyData) {
    return (checksAllResp) => {
      if (!!checksAllResp && !!checksAllResp.errorMessages && !!checksAllResp.errorMessages.length) {
        st.errors = st.errors.concat(this.gpErrorService.manageErrors(checksAllResp.errorMessages, 'ROLES'));
        return throwError(st);
      }
      return st;
    };
  }

  private manageUpdatePartyRespFn(st: GroupPolicyStatePolicyData) {
    return (errorMessages) => {
      if (!!errorMessages && !!errorMessages.length) {
        st.errors = st.errors.concat(this.gpErrorService.manageErrors(errorMessages, 'ROLES_UPD'));
      }
      return st;
    };
  }


  private retrieveExtensionData$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>, previousStepData: DataForSteps) {
    return groupPolicyStatePolicyData$
      .pipe(
        mergeMap((st) => {
          return this.groupPolicyService.retrieveExtensionData$(previousStepData.resId).pipe(
            map((policyDataExtensionSections: GPExtensionDataSections) => {
              st.extensionData = [];
              if (policyDataExtensionSections && policyDataExtensionSections.sections) {
                policyDataExtensionSections.sections.forEach(section => {
                  section.fields.sort((a, b) => {
                    return a.order > b.order ? 1 : -1;
                  });
                  if (!st.extensionData) {
                    st.extensionData = [];
                  }
                  st.extensionData.push(section);
                });
              }
              return st;
            }),
            catchError(this.gpErrorService.catchApiErrorFn(st, 'POLICYDATA_FIELDS_GET'))
          );
        }),
        catchError(this.gpErrorService.manageStreamErrFn()),
        map((st: GroupPolicyStatePolicyData) => st)
      );
  }

  private manage200ErrorsExtRespFn(st: GroupPolicyStatePolicyData, code?: string) {
    return (checksAllResp) => {
      if (!!checksAllResp && !!checksAllResp.length) {
        if (code) {
          st.errors = st.errors.concat(this.gpErrorService.manageErrors(checksAllResp, code));
        } else {
          st.errors = st.errors.concat(this.gpErrorService.manageErrors(checksAllResp, 'FOOTER_EXTENSION_ERROR'));
        }
      }
      return st;
    };
  }

  modifySubject$(groupPolicyStatePolicyData$: Observable<GroupPolicyStatePolicyData>,
                 idParty: string, activeRoute: ActiveRoute) {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    return groupPolicyStatePolicyData$.pipe(
      mergeMap((st: GroupPolicyStatePolicyData) => {
        return combineLatest(of(st), this.anagService.getSubjectDetail$(idParty, null, previousStepData.node));
      }),
      mergeMap(([st, partyDetail]) => {
        const partyEditorData = {} as AnagFlowData;
        partyEditorData.idParentSession = activeRoute.id;
        partyEditorData.nodeCode = previousStepData.managementNode;
        return combineLatest(of(st), this.anagResolver.editParty(partyDetail.subject, partyEditorData));
      }),
      mergeMap(([st, partyModified]) => {
        return combineLatest(of(st),
          this.groupPolicyService.updatePartyOnContract$(previousStepData.resId, partyModified));
        }
      ),
      mergeMap(([st]) => {
        return this.getRoles$(of(st), previousStepData);
      }),
      map((st) => {
          return st;
        }
      )
    );

  }
}

import {Component, ComponentRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Modal} from '@rgi/portal-ng-common';
import {Package} from '../../../models/domain-models/package';
import {UntypedFormGroup} from '@angular/forms';
import {PackagesService} from '../packages.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Unit} from '../../../models/domain-models/unit';
import {Variable} from '../../../models/domain-models/variable';
import {ApiPremiumSection} from '../../../models/api-models/api-premium-section';
import {QuotationService} from '../../quotation.service';
import {ApiQuotation} from '../../../models/api-models/api-quotation';
import {Message} from '../../../models/message';
import {FormService} from '../../../form-service';

@Component({
  selector: 'mic-package-variables',
  templateUrl: './package-variables-modal.component.html',
  styleUrls: ['./package-variables-modal.component.scss']
})
export class PackageVariablesModalComponent implements Modal, OnInit {

  @Input() title: string;
  @Input() package: Package;
  @Input() quotation: ApiQuotation;

  @Output() eventPackageModified: EventEmitter<Package> = new EventEmitter<Package>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  componentRef: ComponentRef<any>;
  submitted = false;

  guaranteesForm: UntypedFormGroup;
  validationMessagesClause: Map<string, Array<string>> = new Map<string, Array<string>>();
  validationMessages: string[] = [];
  validationMessagesVariable: Map<string, Array<string>> = new Map<string, Array<string>>();
  messagesSubscription: Subscription;
  originalPackage: Package;
  packagesPremium: Map<string, ApiPremiumSection>;

  constructor(
    protected translate: TranslateService,
    protected packagesService: PackagesService,
    protected quotationService: QuotationService,
    protected formService: FormService
  ) {
  }

  ngOnInit() {
    this.title = this.componentRef.instance.title;
    this.originalPackage = JSON.parse(JSON.stringify(this.componentRef.instance.package)) as Package;
    const pPremium = this.componentRef.instance.quotation.premium.packagesPremium;
    this.packagesPremium = JSON.parse(JSON.stringify(pPremium)) as Map<string, ApiPremiumSection>;
    this.getUnitsPackage();
  }

  onSubmit() {
    this.submitted = true;
    this.validateForm();
    this.formService.setFieldsDirty(this.guaranteesForm);
    this.guaranteesForm.updateValueAndValidity();

    if (this.guaranteesForm.valid) {
      this.validationMessages.length = 0;
      this.eventPackageModified.emit(this.originalPackage);
      this.close();
    }
  }

  getUnitsPackage() {
    this.guaranteesForm = this.packagesService.toFormGroup(this.originalPackage);
    this.updateErrorMessageVariable();
    this.returnMessages();
  }

  updateErrorMessageVariable() {
    this.validationMessagesVariable.clear();
    for (const unit of this.originalPackage.unitList) {
      if (unit.selected) {
        const unitDescr = unit.extendedDescription ? unit.extendedDescription : unit.description;
        const unitVariable = new Array<string>();
        if (unit.variables) {
          for (const variable of unit.variables) {
            const variableDescr = variable.extendedDescription ? variable.extendedDescription : variable.description;
            const variableFormControl = this.guaranteesForm.get(variable.identificationCode);
            if (variable.compulsory) {
              if (!variableFormControl.value || variableFormControl.value === '-1') {
                this.guaranteesForm.get(variable.identificationCode).setErrors({required: true});
              }
            }
            if (variableFormControl && variableFormControl.errors && variableFormControl.errors.required) {
              unitVariable.push(unitDescr + ': ' + variableDescr + ' ' + this.translate.instant('is required'));
            }
          }
        }
        this.validationMessagesVariable.set(unit.warrantyCode, unitVariable);
      } else {
        if (this.validationMessagesVariable.get(unit.warrantyCode)) {
          this.validationMessagesVariable.get(unit.warrantyCode).length = 0;
        }
      }
    }
    this.returnMessages();
  }

  validateVariables(event: string) {
    this.updateErrorMessageVariable();
    // this.recalculateAvailable.emit(true);
    this.updatePackage();
  }

  verifyClauses(event: Message[], unit: Unit) {
    const alertClause = new Array<string>();
    event.forEach(message => {
      alertClause.push(message.text);
    });

    this.validationMessagesClause.set(unit.warrantyCode, alertClause);
    this.returnMessages();
  }

  returnMessages() {
    this.validationMessages.length = 0;
    this.validationMessagesVariable.forEach(validationMessageVariable => {
      validationMessageVariable.forEach(element => {
        this.validationMessages.push(element);
      });
    });
    this.validationMessagesClause.forEach(clause => {
      clause.forEach(element => {
        this.validationMessages.push(element);
      });
    });
  }

  updatePackage() {
    if (this.guaranteesForm.invalid) {
      return;
    }
    this.packagesService.updateUnitValues(this.originalPackage, this.guaranteesForm);

    const quotation = new ApiQuotation();
    quotation.isPackage = true;
    quotation.isRecalculate = true;
    quotation.packages = this.quotation.packages.map((pk) => {
      if (pk.code === this.originalPackage.code) {
        pk = this.originalPackage;
      }
      return pk;
    });
    const quotationObservable = this.quotationService.getQuotation(quotation);
    quotationObservable.subscribe(data => {
        if (data.packages) {
          for (const pack of data.packages) {
            if (pack.code === this.originalPackage.code) {
              this.originalPackage = pack;
              this.packagesService.updateUnitValuesValidators(pack, this.guaranteesForm);
              this.updateErrorMessageVariable();
              break;
            }
          }
        }

        if (data.premium && data.premium.packagesPremium) {
          this.packagesPremium = data.premium.packagesPremium;
        } else {
          this.packagesPremium = null;
        }
      }
    );
  }

  changeClauses(event: boolean) {
    if (event) {
      this.updatePackage();
      // this.recalculateAvailable.emit(true);
    }
  }

  unitsTrackByFn(index, unit: Unit) {
    return unit.code;
  }

  variablesTrackByFn(index, variable: Variable) {
    return variable.code;
  }

  validateForm() {
    this.updateErrorMessageVariable();
    this.returnMessages();
  }

  close() {
    // this.eventPackageModified.emit(this.originalPackage);
    this.componentRef.destroy();
  }

}

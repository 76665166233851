<div>
  <lic-funds-message [hidden]="!hide" [leftPercent]="getLeftPercent()" [leftAmount]="leftAmount"></lic-funds-message>
  <form>
    <div class="row" [formGroup]="fg">
      <div formArrayName="FAProfiles" [hidden]="!hide">
        <div class="col-lg-3" *ngFor="let profile of profiles; let i = index">
          <lic-fund-button [name]="profile.name"
                           [formControlName]="i.toString()" [totalAmount]="amount"></lic-fund-button>
        </div>
      </div>
      <div *ngIf="!hide" class="input-group" id="profile-recap">
          <span>{{selectedProfileLabel}}</span>
        <ul>
          <li *ngFor="let profile of selectedProfiles" id="li-profile-recap">
            <span>{{ profile.name }}</span>
            <span>({{ formatter.setCurrencyVal(profile.currency) }})</span>
            <span>({{ formatter.setDecimalVal(getProfilePercentage(profile),3) }}%)</span>
          </li>
        </ul>
      </div>
    </div>
  </form>
</div>

import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RgiRxRoutingModule, ROUTES} from '@rgi/rx/router';
import {
  RGI_RX_DATETIME_CONFIG,
  RGI_RX_DROP_SELECTION_HANDLER,
  RgiRxAutoCompleteModule,
  RgiRxDatePickerModule,
  RgiRxDragDropModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule,
  RgiRxLoadingModule,
  RgiRxModalModule,
  RgiRxNativeDateTimeModule,
  RgiRxPanelModule,
  RgiRxSnackbarModule,
  RgiRxSwitchModule,
  RgiRxTableModule,
  RgiRxTabModule,
  RgiRxTooltipModule
} from '@rgi/rx/ui';
import {AnagHomeComponent} from './anag-flow/home/anag-home.component';
import {AnagResultComponent} from './anag-flow/result/anag-result.component';
import {ANAG_ROUTES} from './anag.routes';
import {AnagModalComponent} from './anag-components/modal/anag-modal.component';
import {ANAG_EVENTS} from './anag-events/anag-event';
import {EVENTS} from '@rgi/rx';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {ANAG_TRANSLATIONS} from './i18n/translations';
import {AnagPartyEditorComponent} from './anag-flow/party-editor/anag-party-editor.component';
import {AnagPartyKeyDataComponent} from './anag-components/party-create-key-data/anag-party-key-data.component';
import {IdentificationDataComponent} from './anag-components/identification-data/identification-data.component';
import {KeyDataModalComponent} from './anag-components/party-create-key-data/key-data-modal/key-data-modal.component';
import {AnagStorageService} from './anag-resources/anag-storage.service';
import {AnagUmaAddressComponent} from './anag-components/uma-address/anag-uma-address.component';
import {AnagIntegrationService} from './services/anag-integration.service';
import {AnagStatelessService} from './anag-resources/anag-stateless.service';
import {AnagUbicationComponent} from './anag-components/ubication-component/ubication.component';
import {AnagPrivacyComponent} from './anag-components/privacy/anag-privacy.component';
import {AnagHomeFieldConfigurationService} from './services/anag-home-field-configuration.service';
import {AnagDialogComponent} from './anag-components/modal/anag-dialog/anag-dialog.component';
import {AnagDetailComponent} from './anag-flow/detail/anag-detail.component';
import {AnagContactsComponent} from './anag-components/contacts/anag-contacts.component';
import {AnagDocumentComponent} from './anag-components/document/anag-document.component';
import {AnagPartyLinkComponent} from './anag-components/party-link/party-link.component';
import {AnagConfigService} from './anag-resources/anag-config.service';
import {AnagAtiComponent} from './anag-components/ati/ati.component';
import {AnagDynamicObjectsComponent} from './anag-components/dynamic-objects/anag-dynamic-objects.component';
import {AnagPaymentsComponent} from './anag-components/payments/anag-payments/anag-payments.component';
import {AnagPaymentSectionComponent} from './anag-components/payments/payment-section/payment-section.component';
import {DATE_FORMAT, DATE_TIME_CONFIG} from './anag-constants/anag-constants';
import {AnagDropSearchResolver} from './anag-dropcontainer/anag-drop-search-resolver';
import {AnagSearchOrCreateResolver} from './anag-dropcontainer/anag-drop-search-or-create-resolver';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AddressComponent} from './anag-components/address/address.component';
import {AvailabilityAddressComponent} from './anag-components/availability-address/availability-address.component';
import {ContactAddressComponent} from './anag-components/contact-address/contact-address.component';
import {
  CounterTerrorismComponent
} from './anag-components/counterTerrorism/counter-terrorism/counter-terrorism.component';
import { AnagTaxClassificationComponent } from './anag-components/tax-classification/anag-tax-classification/anag-tax-classification.component';
import { TaxClassificationSectionComponent } from './anag-components/tax-classification/tax-classification-section/tax-classification-section.component';
import {
  AnagUmaAddressInputComponent
} from './anag-components/uma-address/anag-uma-address-input/anag-uma-address-input.component';
import { AnagResultHomonymyComponent } from './anag-flow/result-homonymy/anag-result-homonymy.component';
import {TaxClassificationHistoryModalComponent} from "./anag-components/tax-classification/tax-classification-history-modal/tax-classification-history-modal.component";
import { AnagDocumentDrivingLicenseTypeComponent } from './anag-components/document/anag-document-driving-license-type/anag-document-driving-license-type.component';
import {AnagModalService, DEFAULT_ANAG_MODAL_CONFIG} from './anag-modal/anag-modal-service';
import { RgiAnagModalModule } from './anag-modal/anag-modal-module';
import {RgiCountryLayerModule} from "@rgi/country-layer";
import { AnagRatingComponent } from './anag-components/rating/anag-rating/anag-rating.component';
import { AnagRatingSectionComponent } from './anag-components/rating/anag-rating-section/anag-rating-section.component';
import { AnagRatingHistoryModalComponent } from './anag-components/rating/anag-rating-history-modal/anag-rating-history-modal.component';


@NgModule({
    declarations: [
        AnagHomeComponent,
        AnagResultComponent,
        AnagModalComponent,
        AnagPartyEditorComponent,
        AnagPartyKeyDataComponent,
        IdentificationDataComponent,
        KeyDataModalComponent,
        AnagUmaAddressComponent,
        AnagUbicationComponent,
        AnagPrivacyComponent,
        AnagDialogComponent,
        AnagContactsComponent,
        AnagDetailComponent,
        AnagDocumentComponent,
        AnagPartyLinkComponent,
        AnagAtiComponent,
        AnagDynamicObjectsComponent,
        AnagPaymentSectionComponent,
        AnagPaymentsComponent,
        AddressComponent,
        AvailabilityAddressComponent,
        ContactAddressComponent,
        CounterTerrorismComponent,
        AnagTaxClassificationComponent,
        TaxClassificationSectionComponent,
        CounterTerrorismComponent,
        AnagUmaAddressInputComponent,
        AnagResultHomonymyComponent,
        TaxClassificationHistoryModalComponent,
        AnagDocumentDrivingLicenseTypeComponent,
        AnagRatingComponent,
        AnagRatingSectionComponent,
        AnagRatingHistoryModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RgiRxRoutingModule.forRoot(ANAG_ROUTES),
        RgiRxModalModule,
        RgiRxTableModule,
        RgiRxLoadingModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(ANAG_TRANSLATIONS),
        RgiCountryLayerModule,
        RgiRxAutoCompleteModule,
        RgiRxFormElementsModule,
        RgiRxTabModule,
        RgiRxDragDropModule,
        FormsModule,
        RgiRxPanelModule,
        RgiRxSnackbarModule,
        RgiRxExpansionModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        BrowserAnimationsModule,
        RgiRxTooltipModule,
        RgiRxSwitchModule,
        RgiRxFormElementsModule,
        RgiAnagModalModule.forRoot(DEFAULT_ANAG_MODAL_CONFIG)
    ],
    exports: [
        AnagUmaAddressComponent,
        AnagUbicationComponent,
        AnagDialogComponent,
        AnagPrivacyComponent,
        AvailabilityAddressComponent,
        ContactAddressComponent
    ],
    providers: [
        { provide: ROUTES, useValue: ANAG_ROUTES, multi: true },
        { provide: EVENTS, useValue: ANAG_EVENTS, multi: true },
                AnagStorageService,
        AnagIntegrationService,
        AnagModalService,
        AnagHomeFieldConfigurationService,
        { provide: AnagStatelessService, useClass: AnagStatelessService },
        { provide: AnagConfigService, useClass: AnagConfigService },
        {
            provide: RGI_RX_DROP_SELECTION_HANDLER,
            useValue: { name: 'searchOrCreateSubject', handler: AnagSearchOrCreateResolver },
            multi: true
        },
        {
            provide: RGI_RX_DROP_SELECTION_HANDLER,
            useValue: { name: 'searchSubject', handler: AnagDropSearchResolver },
            multi: true
        },
        { provide: 'DATE_FORMAT', useValue: DATE_FORMAT },
        { provide: DatePipe, useClass: DatePipe },
        { provide: RGI_RX_DATETIME_CONFIG, useValue: DATE_TIME_CONFIG },
    ]
})
export class RgiAnagModule {


}

import {Condition} from './condition';

export class QuestionnaireElement {
  constructor(
    public id: string,
    public question: string,
    public answers: Array<any>,
    public selectedAnswer: any,
    public questionType: number,
    public visible: boolean,
    public condition: Condition,
    public answerCode: string,
    public required: boolean,
    public disabled: boolean
  ) {
  }
}

import {
  AjsIntegrationConfig,
} from "@rgi/ng-passpropro-core/portal";
import {PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS} from "./ajs-integration-survey";
import {
  SURVEY_BACK,
  SURVEY_SAVED,
  SURVEY_SELECT_PRODUCT_EVALUATED,
  SurveyBackEvent,
  SurveySaveEvent,
  SurveySelectProductEvaluated,
  SurveyEvaluationBack,
  SURVEY_EVALUATION_BACK,
  SurveyEvaluationBackInstance
} from "../survey/model/survey-lifecycle";
import {SurveyEditState} from '../state/models/survey-edit-state';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {EvaluationResourceDefinition} from '@rgi/ng-passpropro';
import {ProductSourceService, Source} from "@rgi/ng-passpropro/product";
import {SurveyConfig} from "../ng-passpropro-survey-api";
import {finalize, map, mergeMap} from "rxjs/operators";
import {QuestionnaireService} from "@rgi/ng-passpropro/questionnaire";
import {QuestionnaireI} from "../models";
import {QuestionnaireResourcesService} from '@rgi/ng-passpropro/questionnaire';
import {AnagSubject, Operator} from '@rgi/ng-passpropro/questionnaire';
import {SurveyEditRouteData} from "../state/state-manager-survey.module";
import {EventService} from "@rgi/rx";
import {getPortalRouteOptions, RGI_RX_PORTAL_TPL} from "@rgi/rx/portal";
import {RoutingService} from "@rgi/rx/router";
import {IPromise, IQService, IScope} from 'angular';
import {IPortalSurveyEditResult} from './models';
import {ICardScope, ICoreResult} from './portal-models';
import {EvaluatorProductI, EvaluatorSectionI} from "../evaluation/models";

declare var angular: angular.IAngularStatic;


export function registerNgPassProSurveyCard(ajsIntegrationConfig: AjsIntegrationConfig | null, surveyConfig: SurveyConfig) {

  const isPortal = (ajsIntegrationConfig && ajsIntegrationConfig.passportal) || false;


  const createSurveyBackEventSubscription = ($scope, eventService: EventService): Subscription => {
    return eventService.listen<SurveyBackEvent>(SURVEY_BACK).subscribe(
      event/*:SurveyBackEventInstance*/ => {
        if (event.status == 'bootstrap') return;
        if ($scope.card.idSession == event.event.routeId) {
          $scope.back();
        }
      }
    );
  }


  const createSurveyEvaluationBackSubscription = ($scope, sessionService, eventService: EventService) => {
    return eventService.listen<SurveyEvaluationBack>(SURVEY_EVALUATION_BACK).subscribe(
      event => {
        if (event.status == 'bootstrap') return;
        if ($scope.card.idSession == event.event.routeId) {
          if (event.event.askConfirm) {
            $scope.back();
          } else {
            sessionService.remove(event.event.routeId);
          }
        }
      }
    )
  }


  const createSurveySaveSubscription = ($scope, evs: EventService, containerCacheService, eventService, PASSPRO_PRO_SURVEY_PASSPORTAL_EVENTS): Subscription => {
    return evs.listen<SurveySaveEvent>(SURVEY_SAVED).subscribe(
      (event/*:SurveySaveEventInstance*/) => {

        if (event.status == 'bootstrap') return;
        if ($scope.card.idSession != event.event.routeId) {
          return;
        }

        const sessionId = event.event.routeOptions.idSessionParent //event.event.routeId;
        let sharedCache = containerCacheService.getCache().get(PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS.EVALUATION_BRIDGE_KEY + sessionId);
        if (!sharedCache) {
          return;
        }
        const iddBridge = sharedCache.subject;
        const uuid = event.event.surveyVersion.uuid;

        if (!Array.isArray(iddBridge.iddQuestionnaires)) {
          iddBridge.iddQuestionnaires = [];
        }
        iddBridge.iddQuestionnaires.push({ version: uuid });
        
        if (!iddBridge.iddQuestionnaires.some(id => id == uuid)) {
          eventService.broadcastEvent(PASSPRO_PRO_SURVEY_PASSPORTAL_EVENTS.UPDATE_SURVEY_SUBJECT, {
            subject: event.event.subject
          });

        }

      }
    );
  }


  const getSourcesAndResourcesIfEnabled$ = (
    productSourceService: ProductSourceService,
    questionnaireResourcesService: QuestionnaireResourcesService,
    subject?: AnagSubject,
    operator?: Operator
  ): Observable<[Source[], EvaluationResourceDefinition[]]> | null => {
    if (surveyConfig && (surveyConfig.useSources || surveyConfig.useRuleResources)) {
      const sources$ = surveyConfig.useSources ? productSourceService.getSources() : of([]);
      const resources$ = surveyConfig.useRuleResources ? questionnaireResourcesService.getResources$(subject, operator) : of([]);
      return combineLatest(sources$, resources$);
    } else {
      return null;
    }
  };


  if (!isPortal) {

    const module = angular.module("passpro-pro-survey-card");
    module.config(['parallelRoutingProvider', 'PASSPRO_PRO_CARDS', (pRouter, PASSPRO_PRO_CARDS) => {
      pRouter.when(PASSPRO_PRO_CARDS.SURVEY.name, 'edit', {
        template: RGI_RX_PORTAL_TPL,
        controller: ['$scope', 'RgiRxRoutingService', '$timeout', 'coreResult', '$controller', 'RgiRxEventService', 'sessionService', function ($scope, RoutingService, $timeout, coreResult, $controller, eventServiceAJS, sessionService) {
          $controller('CardSurveyController_edit', {$scope: $scope});
          $scope.$$id = $scope.card.idSession;

          const result = coreResult.getResult($scope.id, 'edit');

          const eventService: EventService = eventServiceAJS;
          createSurveyBackEventSubscription($scope, eventService);
          createSurveyEvaluationBackSubscription($scope, sessionService, eventService);

          let routeData: SurveyEditRouteData = {
            isEdit: !result,
            showBackButton: result && result.showBackButton !== undefined ? result.showBackButton : true
          };
          if (result) {
            routeData.surveyVersion = result.version;
            routeData.isNewVersion = !!result.isNewVersion;
            routeData.isEdit = routeData.isEdit || routeData.isNewVersion;
          }
          $scope.$$ready = true;
          $scope.cleanUpFilterFromAjsUiData = (filter: SurveyEditState): any => {
            if (filter && filter.surveyVersion && filter.surveyVersion.lastVersion.questionnaire) {
              filter.surveyVersion.lastVersion.questionnaire.questions.forEach((question: any) => {
                delete question.isFirst;
              });
            }
            return filter;
          }

          $timeout(() => {
            RoutingService.navigate("survey-edit", $scope.cleanUpFilterFromAjsUiData(routeData), $scope.card.idSession, getPortalRouteOptions($scope, "survey-edit") as any);
          }, 100);
        }],
        label: 'Survey edit'
      });
    }]);

  } else {

    const module = angular.module("passpro-pro-survey-passportal");
    module.config(['parallelRoutingProvider', 'PASSPRO_PRO_CARDS', (pRouter, PASSPRO_PRO_CARDS) => {
      pRouter.when(PASSPRO_PRO_CARDS.SURVEY.name, 'edit', {
        template: RGI_RX_PORTAL_TPL,
        controller: ['$scope', 'RgiRxRoutingService', 'coreResult', '$controller', '$q', 'containerCacheService', 'authService', 'PassProProSurveyService', 'MarketableProducts', 'RgiRxEventService', 'ProductSourceServiceAJS', 'QuestionnaireResourcesServiceAJS', 'eventService', 'PASSPRO_PRO_SURVEY_PASSPORTAL_EVENTS', 'sessionService',
          function ($scope: ICardScope, routingService: RoutingService, coreResult: ICoreResult, $controller, $q: IQService, containerCacheService, authService, PassProProSurveyService, MarketableProducts, eventServiceAJS, productSourceServiceAJS, questionnaireResourcesServiceAJS, eventServiceCard, PASSPRO_PRO_SURVEY_PASSPORTAL_EVENTS, sessionService) {

            $scope.$$id = $scope.card.idSession;
            const result: IPortalSurveyEditResult = coreResult.getResult($scope.id, 'edit');

            const questType = result && result.questType;
            const questionnairesNotFilled: QuestionnaireI[] = result && result.questionnairesNotFilled;

            let routeData: SurveyEditRouteData = {
              isEdit: !result,
              showBackButton: result && result.showBackButton !== undefined ? result.showBackButton : true
            };

            if (result) {
              //dati da inoltrare dopo il "continue" della valutazione
              routeData.forwardData = result.forwardData;

              if (result.isNew) {
                // x la card edit devo togliere il result quando è presento isNew, altrimenti si spacca. Result mi serve per passare il questType
                coreResult.setResult($scope.id, 'edit', null);
              } else {
                routeData.surveyVersion = result.version;
                routeData.isNewVersion = !!result.isNewVersion;
                routeData.isEdit = result.isEdit || routeData.isNewVersion;
              }
            }
            $controller('CardSurveyController_editPassPortal', {$scope: $scope});
            $scope.$$id = $scope.card.idSession;
            const productSourceService: ProductSourceService = productSourceServiceAJS;
            const eventService: EventService = eventServiceAJS;
            const questionnaireResourcesService: QuestionnaireResourcesService = questionnaireResourcesServiceAJS;

            createSurveyBackEventSubscription($scope, eventService);
            createSurveyEvaluationBackSubscription($scope, sessionService, eventService);
            createSurveySaveSubscription($scope, eventService, containerCacheService, eventServiceCard, PASSPRO_PRO_SURVEY_PASSPORTAL_EVENTS);

            if (result) {
              routeData.surveyVersion = result.version;
              routeData.isNewVersion = !!result.isNewVersion;
              routeData.isEdit = routeData.isEdit || routeData.isNewVersion;
            }

            $scope.cleanUpFilterFromAjsUiData = (filter: SurveyEditState): any => {
              if (filter && filter.surveyVersion && filter.surveyVersion.lastVersion.questionnaire) {
                filter.surveyVersion.lastVersion.questionnaire.questions.forEach((question: any) => {
                  delete question.isFirst;
                });
              }
              return filter;
            }

            const getQuestionniares$ = (): IPromise<QuestionnaireI[]> => {
              if (questionnairesNotFilled) {
                return $q<QuestionnaireI[]>((resolve, reject) => resolve(questionnairesNotFilled));
              } else {
                const surveys = containerCacheService.getCache().get(PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS.EVALUATION_BRIDGE_KEY + $scope.card.idSessionParent).surveys;
                return PassProProSurveyService.getQuestionnairesNotFilled(surveys, undefined, undefined, questType, $scope.card.idSession, $scope.card.idSessionParent);
              }
            };

            getQuestionniares$()
              .then(questionnaires => {
                const iddBridge = result && result.subject ? result.subject : containerCacheService.getCache().get(PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS.EVALUATION_BRIDGE_KEY + $scope.card.idSessionParent).subject;
                routeData.integrationFilter = {
                  subject: iddBridge,
                  userCode: authService.getUsername(),
                  allowContinue: true,
                  questionnaires: questionnaires.map(q => q.code),
                  products: $scope.filter.products
                };

                const operator: Operator = authService.getOperator();

                const sourcesAndResources$ = getSourcesAndResourcesIfEnabled$(productSourceService, questionnaireResourcesService, iddBridge, operator);

                if (sourcesAndResources$) {
                  sourcesAndResources$.subscribe(
                    ([sources, resources]) => {
                      routeData.integrationFilter.sources = sources;
                      routeData.integrationFilter.resources = resources;
                      $scope.$$ready = true;
                      routingService.navigate("survey-edit", $scope.cleanUpFilterFromAjsUiData(routeData), $scope.card.idSession, getPortalRouteOptions($scope, "survey-edit") as any);
                    }
                  );
                } else {
                  MarketableProducts.getBySalePoint({
                    salePointId: authService.getSalePointId(),
                    username: authService.getUsername()
                  }).$promise.then(data => {
                    routeData.integrationFilter.products = data.marketableProducts ? data.marketableProducts.products : [];
                  }).then(x => {
                    routingService.navigate("survey-edit", $scope.cleanUpFilterFromAjsUiData(routeData), $scope.card.idSession, getPortalRouteOptions($scope, "survey-edit") as any);
                  });
                }
              });
          }],
        label: 'Survey edit'
      });
    }]);

    module.run(["RgiRxEventService", 'PassProProSurveyProposalService', 'coreResult', 'sessionService', 'eventService', (eventServiceAJS, PassProProSurveyProposalService, coreResult, sessionService, eventServicePortal) => {
      const eventService: EventService = eventServiceAJS;
      const getProductSelectedList = (products: EvaluatorProductI[]): any[] => {
        let productSelectedList = [];

        products.forEach((p: EvaluatorProductI) => {

          if (p.selected) {
            productSelectedList.push(p);
          } else {
            let foundSelection = false;
            p.sections.forEach((sec: EvaluatorSectionI) => {
              if (sec.coverages.filter(cov => cov.selected).length > 0) {
                foundSelection = true;
              }
            });

            if (!foundSelection && p.packages.filter(pk => pk.selected).length > 0) {
              foundSelection = true;
            }

            if (foundSelection) {
              productSelectedList.push(p);
            }
          }
        });

        return productSelectedList;
      };

      eventService.listen<SurveySelectProductEvaluated>(SURVEY_SELECT_PRODUCT_EVALUATED)
        .pipe(
          finalize(() => eventService.clear(SURVEY_SELECT_PRODUCT_EVALUATED))
        )
        .subscribe((event) => {

          if (event.status !== 'bootstrap') {
            console.log("start proposal with", event);
            const eventData = event.event;
            let selected = getProductSelectedList(eventData.products);

            const sessionList = sessionService.list();
            const session = sessionList.find(session => session.idSession == event.event.id);
            const idSessionParent = session && session.idSessionParent;

            //sessionService.remove(event.event.id);
            const callback = () => {
              sessionService.remove(event.event.id);
              sessionService.remove(idSessionParent);
            };

            if (selected.length == 1) {
              eventServicePortal.broadcastEvent("start-loader");
              PassProProSurveyProposalService.openProposal(eventData.surveyVersion.uuid, selected[0], eventData.subject, "1" /* todo check this */, callback).then(
                () => eventServicePortal.broadcastEvent("stop-loader")
              ).catch(() => eventServicePortal.broadcastEvent("stop-loader"))
            } else if (selected.length >= 1) {
              let selectable = eventData.products.filter(v => v.selectable);
              callback();
              const id = sessionService.open('bundle', 'iframe', "Bundle", false, null, null, idSessionParent);
              coreResult.setResult(id, 'products', selectable);
              coreResult.setResult(id, 'subject', eventData.subject);
              coreResult.setResult(id, 'survey', eventData.surveyVersion);
              coreResult.setResult(id, 'forwardData', eventData.forwardData);
            } else {
              console.warn("No product has been selected SURVEY_SELECT_PRODUCT_EVALUATED cannot continue")
            }
          }
        });

    }]);
  }

  PassProProSurveyServiceDecorator.$inject = ['$delegate', 'ProductSourceServiceAJS', 'QuestionnaireServiceAJS', 'QuestionnaireResourcesServiceAJS', 'containerCacheService', 'authService'];

  function PassProProSurveyServiceDecorator($delegate, productSourceServiceAJS: ProductSourceService, questionnaireService: QuestionnaireService, questionnaireResourcesServiceAJS, containerCacheService, authService) {
    const cloned = $delegate.getQuestionnairesNotFilled;
    const productSourceService: ProductSourceService = productSourceServiceAJS;
    const questionnaireResourcesService: QuestionnaireResourcesService = questionnaireResourcesServiceAJS;

    $delegate.getQuestionnairesNotFilled = (questionnaireList, products, date, questType, idSession, idSessionParent) => {

      const filledQuestCodes = questionnaireList.map(function (quest) {
        return quest.questionnaire.code;
      });

      const getSubject = () => {
        let session = null;
        if (idSession && !idSessionParent) {
          session = containerCacheService.getCache().get(PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS.EVALUATION_BRIDGE_KEY + idSession);
        } else if (!idSession && idSessionParent) {
          session = containerCacheService.getCache().get(PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS.EVALUATION_BRIDGE_KEY + idSessionParent);
        } else if (idSession && idSessionParent) {
          const s = containerCacheService.getCache().get(PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS.EVALUATION_BRIDGE_KEY + idSession);
          const sParent = containerCacheService.getCache().get(PASSPRO_PRO_SURVEY_PASSPORTAL_CONSTANTS.EVALUATION_BRIDGE_KEY + idSessionParent);
          session = s && s.subject ? s : sParent;
        }
        if (!session || !session.subject) {
          return null;
        }
        return session.subject;
      };

      const subject = getSubject();
      const operator: Operator = authService.getOperator();

      const sourcesAndResources$ = subject ? getSourcesAndResourcesIfEnabled$(productSourceService, questionnaireResourcesService, subject, operator) : null;

      if (sourcesAndResources$) {
        return sourcesAndResources$.pipe(
          mergeMap(
            ([sources, resources]) => surveyConfig.useRuleResources ?
              questionnaireService.getQuestionnaireByResources(resources, sources)
              :
              questionnaireService.getQuestionnairesBySources(sources)
          ),
          map(
            (questionnaires: QuestionnaireI[]) => {
              const list = questionnaires.filter(q => filledQuestCodes.indexOf(q.code) == -1);
              let filteredList: QuestionnaireI[];
              if (questType) {
                filteredList = list.filter(q => q.questionnaireType.code == questType);
              } else {
                filteredList = list;
              }
              return filteredList;
            }
          ),
        ).toPromise();
      } else {
        return cloned(questionnaireList, products, date, questType);
      }
    }
    return $delegate;
  }

  if (ajsIntegrationConfig && ajsIntegrationConfig.passportal) {
    angular.module('app').decorator('PassProProSurveyService', PassProProSurveyServiceDecorator);
  }
}





<div data-qa="life-investment-plan-detail" *ngIf="showLifeInvestmentPlanDetail">

  <div class="row">
    <div class="col-lg-12">
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                    (click)="back()" data-qa="back-arrow"></span>
              <span class="separator"> | </span>
              <span class="menu-title" translate>lpc_investment_plan_detail</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 life-detail-margin-box life-detail-box" data-qa="life-investment-plan-detail-table" id="consulting-detail">
    <div class="tbld life-detail-margin-top">
      <lpc-profiles-and-funds-table [showTable]="true" [showTotal]="false" [profiles]="investmentPlan">
      </lpc-profiles-and-funds-table>
    </div>
  </div>

</div>

import {InjectionToken} from '@angular/core';

export interface RestApiConf {
    api: {
      portal: {
        host: string,
        path: string
      }
    };
}

export const REST_API_CONF = new InjectionToken<RestApiConf>('RestApiConf');

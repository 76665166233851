import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeOfLossDamageDto } from '../dto/type-of-loss-dto';
import { HistoricalErosionDetailComponent } from '../historical-erosion-detail/historical-erosion-detail.component';

@Component({
  selector: 'claims-historical-erosion',
  templateUrl: './historical-erosion.component.html',
  styleUrls: ['./historical-erosion.component.scss']
})
export class HistoricalErosionComponent implements OnInit {
  @Input() tol: TypeOfLossDamageDto;
  @Input() historyErosionList: any[];
  searchFE: string;
  totalFilter: number;
  rows: any[];
  rowsInitial: any[];

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.rowsInitial = [];
    this.rows = [];
    this.historyErosionList.forEach(el => {
      const aRow = {
        element: el,
        serachString: el.claimNumber + el.policyNumber + el.unitDescription + el.movementDescr + el.insDate
      };
      this.rowsInitial.push(aRow);
      this.rows.push(aRow);
      this.totalFilter = this.rows.length;
    });
    console.log('History erosion', this.rowsInitial);
  }

  search() {
    this.totalFilter = 0;
    this.rows = [];
    this.rowsInitial.forEach(arow => {
      const searchString: string = arow.serachString;
      // console.log(searchString.indexOf(this.searchFE));
      if (this.searchFE &&
        searchString.toUpperCase().indexOf(this.searchFE.toUpperCase()) >= 0) {
        this.rows.push(arow);
        this.totalFilter++;
      } else if (this.searchFE === '') {
        this.rows.push(arow);
        this.totalFilter++;
      }
    });

    console.log('History erosion filter', this.rows);
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  openDetailHistory(aRow: any) {

    const modalRef = this.modalService.open(HistoricalErosionDetailComponent, {
      windowClass: 'basic',
      size: 'lg'
    });

    modalRef.componentInstance.historyDetail = aRow.element;

    modalRef.result.then((result) => {
      if (result) {
        console.log('Return HistoricalErosionDetailComponent ->', result);
      }
    });
    return false;
  }
}

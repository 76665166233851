import {ReIssueRefactorService} from './../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service';
import {Injectable} from '@angular/core';
import {PartyAsset} from '../../models/domain-models/party-asset';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ReIssueWarningsResourceService} from '../re-issue-resources/re-issue-warnings-resource.service';
import {
  ReIssuePortfolioAssetsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-assets-resource.service';
import {PostAssetRequest} from '../../models/pc-portfolio-models/assets-models/post-asset-request';
import {
  AcitvityPacket,
  PcAsset,
  PcAssetInstance
} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {PcProductAsset} from '../../models/pc-portfolio-models/assets-models/pc-product-asset';
import {Factor} from '../../models/pc-portfolio-models/factor-models/factor';
import {
  ReIssuePortfolioPartyResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-party-resource.service';
import {
  ReIssuePortfolioWarningsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-warnings-resource.service';
import {PcInsuredAsset} from '../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {ApiPcAddress} from '../../models/api-models/apiPcAddress';
import {Warning} from '../../models/domain-models/warning';
import {ConsistencyResponse} from '../../models/pc-portfolio-models/warnings-models/consistency-response';
import {ConsistencyMessage} from '../../models/pc-portfolio-models/warnings-models/consistency-message';
import {SaveProposalResponse} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import {
  ReIssuePortfolioProposalService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal.service';
import {Clause} from '../../models/domain-models/clause';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {AnagIssueSubjectBusinessService} from '../re-issue-anag/anag-issue-service';
import {ReIssueAssetState} from '../re-issue-state-manager/re-issue-asset.state';


@Injectable({
  providedIn: 'root'
})
export class ReIssueAssetsBusinessService<T extends ReIssueAssetState> {


  private readonly WARNING_LEVEL = 'ASSET';
  private readonly SUBJECT_POLICY_ROLE_ASSET = '4';
  protected readonly PF_ASSET_CODE = 'PSFIS';
  protected readonly PF_CON_CODE = 'PFCON';

  constructor(
    private warningsResourceService: ReIssueWarningsResourceService,
    private subjectBusinessService: AnagIssueSubjectBusinessService,
    private portfolioAssetsResourceService: ReIssuePortfolioAssetsResourceService,
    private portfolioPartyResourceService: ReIssuePortfolioPartyResourceService,
    private portfolioWarningsResourceService: ReIssuePortfolioWarningsResourceService,
    protected portfolioProposalService: ReIssuePortfolioProposalService,
    private refactorService: ReIssueRefactorService
  ) {
  }

  getProductAssets(state: T): Observable<T> {
    return this.portfolioAssetsResourceService.getProductAssets(state.resourceId).pipe(
      map((productAssetsResponse: Array<PcProductAsset>) => {
        state.productAssets = productAssetsResponse;
        return state;
      })
    );
  }

  getAssets(state: T): Observable<T> {
    return this.portfolioAssetsResourceService.getAssets(state.resourceId).pipe(
      map((assets: Array<PcInsuredAsset>) => {
        if (assets && assets.length) {
          assets.forEach(asset => {
            const assetInstance = new PcAssetInstance();
            assetInstance.asset = this.refactorService.insuredAssetToPcAsset(asset);
            state.assetInstances.push(assetInstance);
          });
        }
        return state;
      })
    );
  }

  getAsset(contractId: string, key: string): Observable<any> {
    return this.portfolioAssetsResourceService.getAsset(contractId, key);
  }


  getAddress(contractId: string, key: string): Observable<ApiPcAddress> {
    return this.portfolioAssetsResourceService.getAddress(contractId, key);
  }

  deleteAddress(state: T, address: any, key: string) {
    return this.portfolioAssetsResourceService.deleteAddress(state.resourceId, address, key);
  }

  createAddress(state: T, address: any, instanceAssetKey: string) {
    return this.portfolioAssetsResourceService.createAddress(state.resourceId, address, instanceAssetKey);
  }

  updateAddress(resourceId: string, address: ApiPcAddress, instanceAssetKey: string) {
    return this.portfolioAssetsResourceService.updateAddress(resourceId, address, instanceAssetKey);
  }


  initializeAsset(state: T, selectedAsset: string, subject: AnagIssueSubject) {
    return this.portfolioAssetsResourceService.postAsset(state.resourceId, this.constructPostAssetBody(selectedAsset)).pipe(
      mergeMap((insuredAsset: PcAsset) => {
        if (insuredAsset) {
          const assetInstance = new PcAssetInstance();
          assetInstance.subject = subject;
          assetInstance.asset = new PcAsset(insuredAsset);

          state.assetInstances.push(assetInstance);

          return this.portfolioPartyResourceService.postAssetParty(
            state.resourceId,
            insuredAsset.key,
            this.constructAssetParty(subject));
        }
        return of(null);
      }),
      map(
        (response: PartyAsset | null) => {
          return state;
        }
      )
    );
  }

  setInsuredSubject(state: T, selectedAsset: string, subject: AnagIssueSubject): Observable<any> {
    return this.portfolioPartyResourceService.postAssetParty(
      state.resourceId,
      selectedAsset,
      this.constructAssetParty(subject)
    ).pipe(
      switchMap((partyAsset: PartyAsset) => {
        state.assetInstances.find(instance => instance.asset.key === selectedAsset).subject = subject;

        return of(state);
      })
    );
  }


  deleteAssetParty(state: T, assetKey: string, idLatestPhotos: string, roleId: string): Observable<any> {
    return this.portfolioPartyResourceService.deleteAssetParty(
      state.resourceId,
      assetKey,
      idLatestPhotos,
      roleId);

  }


  initializeNormalAsset(resourceId: string, selectedAsset: string): Observable<PcAsset> {
    return this.portfolioAssetsResourceService.postAsset(
      resourceId,
      this.constructPostAssetBody(selectedAsset)
    ).pipe(map(insuredAsset => {
      return insuredAsset;
    }));
  }

  getAssetParty(state: T, assetKey: string) {
    return this.portfolioPartyResourceService.getAssetParty(state.resourceId, assetKey).pipe(
      mergeMap((partyAsset: PartyAsset) => {
        if (partyAsset && partyAsset.party && partyAsset.party.partyId) {
          return this.subjectBusinessService.getSubject(partyAsset.party.partyId, state.node.idSp);
        }
        return of(null);
      }),
      map((subject: AnagIssueSubject) => {
          return subject;
        }
        // map((response: AnagIssueSubject | null) => {
        //   if (response) {
        //     state.assetInstances.forEach(assetInstance => {
        //       if (assetInstance.asset.key === assetKey) {
        //         assetInstance.subject = response;
        //       }
        //     });
        //   }
        //   return state;
        // })
      )
    );
    // return this.portfolioPartyResourceService.getAssetParty(state.resourceId, assetKey).pipe(
    //   mergeMap((partyAsset: PartyAsset) => {
    //     if (partyAsset) {
    //       return this.subjectBusinessService.getSubject(partyAsset.party.partyId, state.node);
    //     }
    //     return of(null);
    //   }),
    //   map((response: AnagIssueSubject | null) => {
    //     if (response) {
    //       state.assetInstances.forEach(assetInstance => {
    //         if (assetInstance.asset.key === assetKey) {
    //           assetInstance.subject = response;
    //         }
    //       });
    //     }
    //     return state;
    //   })
    // );
  }

  constructAssetParty(subject: AnagIssueSubject): PartyAsset {
    const partyAsset: PartyAsset = new PartyAsset();

    partyAsset.main = false; // TODO: MISSING DATA ?
    partyAsset.roleId = this.SUBJECT_POLICY_ROLE_ASSET; // TODO: MISSING DATA ?
    partyAsset.party.denomination = subject.denomination;
    partyAsset.party.nominative = subject.nominative;
    partyAsset.party.name = subject.name;
    partyAsset.party.surname = subject.surname;
    partyAsset.party.partyId = subject.objectId;
    partyAsset.party.idLatestPhotos = subject.idLatestPhotos;

    return partyAsset;
  }

  constructPostAssetBody(selectedAsset: string): PostAssetRequest {
    const assetRequest = new PostAssetRequest();

    assetRequest.code = selectedAsset;
    assetRequest.selected = true; // TODO: MISSING DATA ?

    return assetRequest;
  }

  updateAssetFactor(state: T, factor: Factor, asset: PcAsset): Observable<T> {
    return this.portfolioAssetsResourceService.putAsset(state.resourceId, this.setFactorInAsset(factor, asset)).pipe(
      map((response: PcAsset) => {
        state.assetInstances.find(val => {
          return val.asset.key === response.key;
        }).asset.factors = response.factors;

        return state;
      })
    );
  }

  updateAssetClause(state: T, clause: Clause, asset: PcAsset): Observable<T> {
    return this.portfolioAssetsResourceService.putAsset(state.resourceId, this.setClauseInAsset(clause, asset)).pipe(
      map((response: PcAsset) => {
        state.assetInstances.find(val => {
          return val.asset.key === response.key;
        }).asset.clauses = response.clauses;
        return state;
      })
    );
  }


  setFactorInAsset(factor: Factor, asset: PcAsset): PcAsset {
    asset.factors.forEach((internalFactor: any) => {
      if (internalFactor.code === factor.code) {
        internalFactor = factor;
      }
    });
    return asset;
  }


  setClauseInAsset(clause: Clause, asset: PcAsset): PcAsset {
    asset.clauses.forEach((internalClause, i: any) => {
      if (internalClause.code === clause.code) {
        asset.clauses[i] = clause;
      }
    });
    return asset;
  }

  // putAsset(state: T): Observable<T> {
  //   // TODO : Assumption to be confirmed
  //   // Assumption : The Input Asset is the one with a selected unit
  //   for (const asset of state.assets.assets) {
  //     for (const instance of asset.instances) {
  //       for (const section of instance.sections) {
  //         for (const unit of section.unitList) {
  //           if (unit.selected) {
  //             const pcAsset = this.refactorService.singleAssetRequestFactor(asset);
  //             return this.portfolioAssetsResourceService.putAsset(state.resourceId, pcAsset).pipe(
  //               map(resp => {
  //                 const assetModified = this.refactorService.singleAssetResponseFactor(resp);
  //                 for (let asset of state.assets.assets) {
  //                   if (asset.assetId = assetModified.assetId) {
  //                     asset = assetModified;
  //                   }
  //                 }
  //                 return state;
  //               })
  //             );
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // getAsset(state: T): Observable<T> {
  //   // TODO : Assumption to be confirmed
  //   // Assumption : The Input Asset is the one with a selected unit
  //   for (const asset of state.assets.assets) {
  //     for (const instance of asset.instances) {
  //       for (const section of instance.sections) {
  //         for (const unit of section.unitList) {
  //           if (unit.selected) {
  //             return this.portfolioAssetsResourceService.getAsset(state.resourceId, asset.assetId).pipe(
  //               map(resp => {
  //                 const assetSelected = this.refactorService.singleAssetResponseFactor(resp);
  //                 for (let asset of state.assets.assets) {
  //                   if (asset.assetId = assetSelected.assetId) {
  //                     asset = assetSelected;
  //                   }
  //                 }
  //                 return state;
  //               })
  //             );
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  deleteAssetInstance(state: T, assetKey: string) {
    return this.portfolioAssetsResourceService.deleteAsset(state.resourceId, assetKey);
  }

  // deleteAsset(state: T) {
  //   for (const asset of state.assets.assets) {
  //     for (const instance of asset.instances) {
  //       for (const section of instance.sections) {
  //         for (const unit of section.unitList) {
  //           if (unit.selected) {
  //             const pcAsset = this.refactorService.singleAssetRequestFactor(asset);
  //             this.portfolioAssetsResourceService.delete(state.resourceId, pcAsset);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // addSubjectAndAsset(state: T, subject: AnagIssueSubject, isContraente: boolean): Observable<T> {
  //   const passet = this.assetPartyBusinessService.anagIssueSubjectToPartyAsset(subject);
  //   let assetId = null;
  //   let result: Observable<T>;
  //   let assetInstance: AssetInstance;

  //   if (state.assets && state.assets.assets && state.assets.assets.length) {
  //     assetId = this.getAssetCode(isContraente);

  //     result = this.assetsResourceService.put(state.resourceId, assetId).pipe(
  //       concatMap(asset => {
  //         assetInstance = this.getMissingAsset(asset, state);

  //         return this.assetPartyBusinessService.updatePartyInAssetInstance(state.resourceId, assetInstance.name, passet);
  //       }),
  //       map(pAsset => {
  //         this.addAssetToState(state, assetInstance, subject, isContraente);
  //         return state;
  //       }),
  //     );

  //   } else {
  //     result = of(state);
  //   }

  //   return result;
  // }

  // addAssetToState(st: T, assetInstance: AssetInstance, subject: AnagIssueSubject, isContraente: boolean) {
  //   const subjectInstance: SubjectInstance = new SubjectInstance(assetInstance, subject);
  //   subjectInstance.isContraente = isContraente;
  //   subjectInstance.assetCode = this.getAssetCode(isContraente);
  //   st.subjectsInstances.push(subjectInstance);
  // }

  // getMissingAsset(asset: Asset, state: T) {
  //   let foundAssetInstance = null;

  //   for (const assetInstance of asset.instances) {
  //     let found = false;
  //     for (const subjectInstance of state.subjectsInstances) {
  //       if (assetInstance.name === subjectInstance.instance.name) {
  //         found = true;
  //         break;
  //       }
  //     }
  //     if (!found) {
  //       foundAssetInstance = assetInstance;
  //       break;
  //     }
  //   }
  //   return foundAssetInstance;
  // }

  // putAssetVariables$(state: T, assetId: string, variableList: Variables, subjectInstanceIndex: number): Observable<T> {
  //   return this.assetsResourceService.putAssetVariables(state.resourceId, assetId, variableList).pipe(
  //     map(resp => {
  //       state.subjectsInstances[subjectInstanceIndex].instance.variableList = resp;
  //       return state;
  //     })
  //   );
  // }

  // deleteAsset$(resourceid: string, name: string): Observable<Assets> {
  //   return this.assetsResourceService.deleteAsset$(resourceid, name);
  // }

  // private getAssetCode(isContraente: boolean) {
  //   let code;

  //   if (isContraente) {
  //     code = ReIssueAssetCode.PFCON;
  //   } else {
  //     code = ReIssueAssetCode.PSFIS;
  //   }

  //   return code;
  // }

  // getParty(contractId: string, assetId: string): Observable<Array<PartyAsset>> {
  //   return this.assetsResourceService.getAssetParties(contractId, assetId);
  // }

  getWarnings(state: T): Observable<T> {
    return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.WARNING_LEVEL).pipe(
      map(warnings => {
        state.warnings = warnings;
        return state;
      })
    );
  }

  getConsistencyAsset(state: T): Observable<T> {
    return this.portfolioWarningsResourceService.getConsistencyAsset(state.resourceId).pipe(
      map((val: Array<ConsistencyResponse>) => {
        val.forEach((element: ConsistencyResponse) => {
          element.messages.forEach((message: ConsistencyMessage) => {
            const war: Warning = new Warning();
            war.key = element.assetKey;
            war.description = message.description;
            war.blockingMessage = true;
            state.warnings.push(war);
          });
        });
        return state;
      })
    );
  }

  saveProposal(state: T): Observable<T> {
    return this.portfolioProposalService.saveProposal(state.resourceId, this.constructSaveProposalRequestBody(state)).pipe(
      map((proposalResponse: SaveProposalResponse) => {
        state.proposal = proposalResponse.proposal;
        return state;
      })
      // map((res: ContractNumber) => {
      //   state.contractNumber = res;
      //   return state;
      // })
    );
  }

  constructSaveProposalRequestBody(state: T): any {
    return {
      additionalLabels: null,
      extensions: null
    };
  }

  // updateSubjectInSubjectInstance$(
  //   contractId: string,
  //   subjectInstance: SubjectInstance,
  //   subject: AnagIssueSubject
  // ): Observable<SubjectInstance> {
  //   return this.assetPartyBusinessService.updateSubjectInAssetInstance(
  //     contractId,
  //     subjectInstance.instance.name,
  //     subject
  //   ).pipe(map(
  //     assetInst => {
  //       subjectInstance.subject = subject;
  //       return subjectInstance;
  //     }
  //   ));
  // }

  updateActivities(activity: AcitvityPacket, state: T, assetKey: string, isSecondary: boolean) {
    return this.portfolioAssetsResourceService.putActivities(activity, state.resourceId, assetKey, isSecondary).pipe(
      map((response: AcitvityPacket) => {
        return response;
      })
    );
  }

  searchActivity(state: T, assetKey: string, input: string, byDescription: boolean, isSecondary: boolean) {
    return this.portfolioAssetsResourceService.searchActivity(state.resourceId, assetKey, input, byDescription, isSecondary).pipe(
      map((response: AcitvityPacket) => {
        return response;
      })
    );
  }

  deleteActivity(state: T, assetKey: string, isSecondary: boolean) {
    return this.portfolioAssetsResourceService.deleteActivity(state.resourceId, assetKey, isSecondary).pipe(
      map((response: AcitvityPacket) => {
        return response;
      })
    );
  }

  getAutocomplete(state: T, assetKey: string, input: string): Observable<any> {
    return this.portfolioAssetsResourceService.getAutocomplete(state.resourceId, assetKey, input).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}

import { ComponentFactoryResolver, Directive, EventEmitter, Input,  OnInit, Output, ViewContainerRef } from '@angular/core';
import { Note } from '../../objects/note';
import { NoteComponent } from '../../objects/note.component';
import { TimelineService } from '../../timeline.service';

@Directive({
    selector: 'claims-note-host'
})
export class NoteHostDirective implements OnInit {

    @Input() private note: Note;
    @Output() rechargeEventEmitter = new EventEmitter<boolean>();

    constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.note.component);
        const viewContainer = this.viewContainerRef;

        viewContainer.clear();

        const component = viewContainer.createComponent(componentFactory);
        const noteComponent = component.instance as NoteComponent;

        noteComponent.data = this.note.data;
        noteComponent.rechargeEventEmitter = this.rechargeEventEmitter;
    }

    recharge(event: any) {
        this.rechargeEventEmitter.emit(event);
    }
}

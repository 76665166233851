import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {DocumentPrint} from '../../models/api-models/document-print';
import {REST_API_ANAG_PRINTS_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePrintsService {
  private apiPrintsConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
  ) {
    this.apiPrintsConf = REST_API_ANAG_PRINTS_CONF;
  }

  get(idPol: string): Observable<DocumentPrint> {
    const body = {numeroProposta: idPol};
    return this.http.post<DocumentPrint>(this.getAssetsUri(), body).pipe(
      share()
    );
  }

  getAssetsUri(): string {
    return this.apiConf.api.portal.path + this.apiPrintsConf.v2 + this.apiPrintsConf.path + this.apiPrintsConf.stampaJform;
  }

  getPolicy(idPol: string): Observable<DocumentPrint> {
    const body = {numeroPolizza: idPol};
    return this.http.post<DocumentPrint>(this.getAssetsPolicyUri(), body).pipe(
      share()
    );
  }

  getAssetsPolicyUri(): string {
    return this.apiConf.api.portal.path + this.apiPrintsConf.v2 + this.apiPrintsConf.path + this.apiPrintsConf.stampaJform;
  }
}

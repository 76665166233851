import {Injectable} from '@angular/core';
import {QuestionnaireFlatI} from '../../models/domain-models/questionnaire-flatI';
import {QuestionFlatI} from '../../models/domain-models/question-flat';
import {AnswerFlatI} from '../../models/domain-models/answer-flat-i';
import {ConditionFlatI} from '../../models/domain-models/condition-flatI';
import {ConditionFlatIResponse} from './../../models/domain-models/condition-flatI-response';
import {AnswerFlatIResponse} from './../../models/domain-models/answer-flatI-response';
import {QuestionFlatIResponse} from './../../models/domain-models/question-flat-response';
import {QuestionnaireFlatIResponse} from './../../models/domain-models/questionnaires-flati-response';
import {
  PcAnswer,
  PcQuestion,
  QuestionFlateResponse
} from '../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {ValueFlatI} from '../../models/domain-models/value-flatI';

@Injectable({
  providedIn: 'root'
})
export class ReIssueQuestionnaireUtilityService {

  constructor() {
  }

  convertPcQuestionnaireToQuestionnaireFlatI(pcQuestionnaire: QuestionFlateResponse): QuestionnaireFlatI {
    const questionnaireFlat = new QuestionnaireFlatI();

    // missing data
    questionnaireFlat.code = pcQuestionnaire.code;
    questionnaireFlat.compulsory = pcQuestionnaire.mandatory;
    questionnaireFlat.description = pcQuestionnaire.description;
    questionnaireFlat.descriptionType = pcQuestionnaire.description;
    questionnaireFlat.score = Number(pcQuestionnaire.number); // check if correct
    questionnaireFlat.questions = this.convertPcQuestionsToQuestionsFlat(pcQuestionnaire.questions);

    return questionnaireFlat;
  }

  convertPcQuestionsToQuestionsFlat(pcQuestions: Array<PcQuestion>): Array<QuestionFlatI> {
    const questionsFlat: Array<QuestionFlatI> = new Array<QuestionFlatI>();

    pcQuestions.forEach(pcQuestion => {
      // missing Data
      const questionFlat: QuestionFlatI = new QuestionFlatI();
      questionFlat.code = pcQuestion.code;
      questionFlat.text = pcQuestion.description;
      questionFlat.answers = this.convertPcAnswersToAnswersFlat(pcQuestion.answers);

      questionsFlat.push(questionFlat);
    });

    return questionsFlat;
  }

  convertPcAnswersToAnswersFlat(pcAnswers: Array<PcAnswer>): Array<AnswerFlatI> {
    const answersFlat: Array<AnswerFlatI> = new Array<AnswerFlatI>();

    pcAnswers.forEach(pcAnswer => {
      // missing Data
      const answerFlat: AnswerFlatI = new AnswerFlatI();
      answerFlat.defaultValue = pcAnswer.default;
      answerFlat.factorCode = pcAnswer.code;
      answerFlat.factorDescr = pcAnswer.description;
      answerFlat.factorType = pcAnswer.type;
      answerFlat.value = pcAnswer.value;
      answerFlat.values = this.convertPcValuesToValuesFlat(pcAnswer.values);

      answersFlat.push(answerFlat);
    });

    return answersFlat;
  }

  convertPcValuesToValuesFlat(pcValues: any): Array<ValueFlatI> {
    const valuesFlat: Array<ValueFlatI> = new Array<ValueFlatI>();

    pcValues.forEach(pcValue => {
      const valueFlat: ValueFlatI = new ValueFlatI();
      valueFlat.id = pcValue[0];
      valueFlat.description = pcValue[1];

      valuesFlat.push(valueFlat);
    });

    return valuesFlat;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  // TODO: REFACTOR
  updatePcQuestionnaireAnswers(questionnaireFlat: QuestionnaireFlatI, pcQuestionnaires: Array<QuestionFlateResponse>) {
    pcQuestionnaires.forEach(pcQuestionnaire => {
      if (pcQuestionnaire.code === questionnaireFlat.code) {
        pcQuestionnaire.questions.forEach(pcQuestion => {
          pcQuestion.answers.forEach(pcAnswer => {
            questionnaireFlat.questions.forEach(questionFlat => {
              questionFlat.answers.forEach(answerFlat => {
                if (pcAnswer.code === answerFlat.factorCode) {
                  pcAnswer.value = answerFlat.value ? answerFlat.value.toString() : null;
                  pcAnswer.selected = answerFlat.value ? true : false;
                }
              });
            });
          });
        });
      }
    });

    return pcQuestionnaires;
  }

  checkQuestionnairesCompleteness(questionnaires: QuestionFlateResponse[]): boolean {
    let questionnairesComplete = true;

    questionnaires.forEach(questionnaire => {
      if (!questionnaire.complete) {
        questionnairesComplete = false;
      }
    });

    return questionnairesComplete;
  }

  checkQuestionnairesAdequacy(questionnaires: QuestionFlateResponse[]): boolean {
    let questionnairesAdequacy = true;

    questionnaires.forEach(questionnaire => {
      if (!questionnaire.adequate) {
        questionnairesAdequacy = false;
      }
    });

    return questionnairesAdequacy;
  }

  // convertQuestionnaireFlatIToPcQuestionnaire(questionnaireFlat: QuestionnaireFlatI): QuestionFlateResponse {
  //   let pcQuestionnaire = new QuestionFlateResponse();

  //     //missing data
  //     pcQuestionnaire.code = questionnaireFlat.code;
  //     // questionnaireFlat.code = pcQuestionnaire.code;
  //     // questionnaireFlat.compulsory = pcQuestionnaire.mandatory;
  //     // questionnaireFlat.description = pcQuestionnaire.description;
  //     // questionnaireFlat.score = Number(pcQuestionnaire.number); //check if correct
  //     // questionnaireFlat.questions = this.convertPcQuestionsToQuestionsFlat(pcQuestionnaire.questions);

  //     return pcQuestionnaire;
  // }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  convertToQuestionnaireFlatIResponse(questionnaire: QuestionnaireFlatI): QuestionnaireFlatIResponse {
    const qst: QuestionnaireFlatIResponse = new QuestionnaireFlatIResponse();
    qst.category = questionnaire.category;
    qst.code = questionnaire.code;
    qst.codeType = questionnaire.codeType;
    qst.codeVersion = questionnaire.codeVersion;
    qst.compulsory = questionnaire.compulsory;
    qst.description = questionnaire.description;
    qst.descriptionType = questionnaire.descriptionType;
    qst.included = questionnaire.included;
    qst.openDate = questionnaire.openDate;
    if (questionnaire.questions) {
      qst.questionList = this.getQuestionsResponse(questionnaire.questions);
    }
    qst.score = questionnaire.score;

    return qst;
  }

  getQuestionsResponse(questions: QuestionFlatI[]): QuestionFlatIResponse[] {
    const questionList: Array<QuestionFlatIResponse> = new Array<QuestionFlatIResponse>();
    questions.forEach(question => {
      const qs: QuestionFlatIResponse = new QuestionFlatIResponse();

      qs.answerList = this.getAnswersResponse(question.answers);
      qs.code = question.code;
      qs.compulsory = question.compulsory;
      if (question.conditions) {
        qs.conditionList = this.getConditionsResponse(question.conditions);
      }
      qs.description = question.description;
      qs.hasNotes = question.hasNotes;
      qs.id = question.id;
      qs.order = question.order;
      qs.section = question.section;
      qs.text = question.text;
      qs.visible = question.visible;
      questionList.push(qs);
    });
    return questionList;
  }

  getConditionsResponse(conditions: ConditionFlatI[]): ConditionFlatIResponse[] {
    const conditionList: Array<ConditionFlatIResponse> = new Array<ConditionFlatIResponse>();
    conditions.forEach(condition => {
      const cond: ConditionFlatIResponse = new ConditionFlatIResponse();
      cond.status = condition.status;
      cond.termList = condition.terms;
      conditionList.push(cond);
    });
    return conditionList;
  }

  getAnswersResponse(answers: AnswerFlatI[]): AnswerFlatIResponse[] {
    const answerList: Array<AnswerFlatIResponse> = new Array<AnswerFlatIResponse>();
    answers.forEach(answer => {
      const asw: AnswerFlatIResponse = new AnswerFlatIResponse();
      asw.defaultValue = answer.defaultValue;
      asw.factorCode = answer.factorCode;
      asw.factorDescr = answer.factorDescr;
      asw.factorFormat = answer.factorFormat;
      asw.factorType = answer.factorType;
      asw.formatter = answer.formatter;
      asw.notes = answer.notes;
      asw.paramType = answer.paramType;
      asw.value = answer.value;
      asw.valueList = answer.values;
      answerList.push(asw);
    });
    return answerList;
  }

  convertToQuestionnaireFlatI(
    questionnaire: QuestionnaireFlatIResponse
  ) {
    const qst: QuestionnaireFlatI = new QuestionnaireFlatI();

    qst.category = questionnaire.category;
    qst.code = questionnaire.code;
    qst.codeType = questionnaire.codeType;
    qst.codeVersion = questionnaire.codeVersion;
    qst.compulsory = questionnaire.compulsory;
    qst.description = questionnaire.description;
    qst.descriptionType = questionnaire.descriptionType;
    qst.included = questionnaire.included;
    qst.openDate = questionnaire.openDate;
    if (questionnaire.questionList) {
      qst.questions = this.getQuestions(
        questionnaire.questionList
      );
    }
    qst.score = questionnaire.score;

    return qst;
  }

  getQuestions(
    questionList: QuestionFlatIResponse[]
  ): QuestionFlatI[] {
    const questions: Array<QuestionFlatI> = new Array<QuestionFlatI>();

    questionList.forEach(question => {
      const qs: QuestionFlatI = new QuestionFlatI();
      if (question.answerList.length > 0) {
        qs.status = question.status;
      } else {
        qs.status = 2;
      }
      qs.answers = this.getAnswers(
        question.answerList
      );
      qs.code = question.code;
      qs.compulsory = question.compulsory;
      if (question.conditionList) {
        qs.conditions = this.getConditions(question.conditionList);
      }
      qs.description = question.description;
      qs.hasNotes = question.hasNotes;
      qs.id = question.id;
      qs.order = question.order;
      qs.section = question.section;
      qs.text = question.text;
      qs.visible = question.visible;
      questions.push(qs);
    });
    return questions;
  }

  getConditions(conditionList: ConditionFlatIResponse[]): ConditionFlatI[] {
    const conditions: Array<ConditionFlatI> = new Array<ConditionFlatI>();
    conditionList.forEach(condition => {
      const cond: ConditionFlatI = new ConditionFlatI();
      cond.status = condition.status;
      cond.terms = condition.termList;
      conditions.push(cond);
    });
    return conditions;
  }

  getAnswers(
    answerList: AnswerFlatIResponse[]
  ): AnswerFlatI[] {
    const answers: Array<AnswerFlatI> = new Array<AnswerFlatI>();

    answerList.forEach(answer => {
      const asw: AnswerFlatI = new AnswerFlatI();
      asw.defaultValue = answer.defaultValue;
      asw.factorCode = answer.factorCode;
      asw.factorDescr = answer.factorDescr;
      asw.factorFormat = answer.factorFormat;
      asw.factorType = answer.factorType;
      asw.formatter = answer.formatter;
      asw.notes = answer.notes;
      asw.paramType = answer.paramType;
      if (answer.value === '-1') {
        if (asw.defaultValue === '-1') {
          asw.value = null;
        } else {
          asw.value = answer.defaultValue;
        }
      } else {
        asw.value = answer.value;
      }

      asw.values = answer.valueList;
      answers.push(asw);
    });
    return answers;
  }
}

import {Injectable} from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {ModalComponent, ModalService} from '@rgi/rx/ui';
import {cloneDeep} from 'lodash';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {FUNCTION_STATUS, OPERATIONSCODE} from '../../group-policy-constants/general';
import {IssuePolicyData} from '../../group-policy-models/group-policy-issue-confirm';
import {DataForSteps, IdentificationKey} from '../../group-policy-models/group-policy-issue-home';
import {ActionButton} from '../../group-policy-models/group-policy-issue-policy-data';
import {Mode} from '../../group-policy-models/group-policy-mode';
import {GroupPolicyResourceService} from '../../group-policy-resources/group-policy-resource.service';
import {GpRecoverApplicationsService} from '../../group-policy-services/gp-recover-applications.service';
import {GroupPolicyAuthorizationService} from '../../group-policy-services/group-policy-authorization.service';
import {GroupPolicyCrossService} from '../../group-policy-services/group-policy-cross.service';
import {GroupPolicyIntegrationService} from '../../group-policy-services/group-policy-integration.service';
import {GroupPolicyStateInquiry} from '../group-policy-state';
import {GroupPolicyStatelessModesInquiryService} from './group-policy-stateless-modes-inquiry.service';
import {
  GpConfirmationEvent
} from '../../group-policy-components/group-policy-modals/group-policy-modal-confirmation/gp-confirmation-modal.component';
import {GroupPolicyApiRestErrorService} from '../../group-policy-services/group-policy-api-rest-error.service';
import {
  GroupPolicyModalManualPolicyNumberComponent
} from "../../group-policy-components/group-policy-modals/group-policy-modal-manual-policy-number/group-policy-modal-manual-policy-number-component";

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpInquiryService {

  constructor(
    protected groupPolicyService: GroupPolicyResourceService,
    protected routingService: RoutingService,
    protected integrationService: GroupPolicyIntegrationService,
    protected crossService: GroupPolicyCrossService,
    protected gpStatelessModesInquiryService: GroupPolicyStatelessModesInquiryService,
    protected modalService: ModalService,
    protected recoverApplicationsService: GpRecoverApplicationsService,
    protected authorizationsService: GroupPolicyAuthorizationService,
    protected errorService: GroupPolicyApiRestErrorService) {

  }

  initInquiryDetail(groupPolicyStateInquiry$: Observable<GroupPolicyStateInquiry>, routeData: DataForSteps, activeRoute: ActiveRoute) {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    const proposalToSearch = (previousStepData.issuedContractData && previousStepData.issuedContractData.isFromIssueContract)
      ? previousStepData.issuedContractData.proposalNumber : routeData.proposalNumber;
    const idMovement = previousStepData.idMovement;
    return groupPolicyStateInquiry$.pipe(
      mergeMap(
        (st) => {
          return combineLatest(
            of(st),
            this.groupPolicyService.getContractDetail$(proposalToSearch, idMovement).pipe(
              catchError((err) => {
                return of(err.error.message);
              }),
            ),
            this.groupPolicyService.getMPBalanceDetail$(proposalToSearch, idMovement).pipe(
              catchError((err) => {
                return of(err.error.message);
              }),
            ),
            this.groupPolicyService.manualPolicyNumberFromProposal$(proposalToSearch).pipe(
              catchError((err) => {
                return of(err.error.message);
              }),
            )
          );
        }),
      map(
        ([st, resp, balance, manualPolicyNumber]) => {
          st.contractDetails = resp;
          st.isManualPolicyNumEnabled = FUNCTION_STATUS.ENABLED === manualPolicyNumber.message;
          // manually instantiating a new ActionButton because te response is a normal object with code and description only
          if (st.contractDetails.actionsBotton) {
            st.contractDetails.actionsBotton = st.contractDetails.actionsBotton
              .map(button => {
                  const isIncludeBtn = button.code === 'C00737';
                  const isCluster = st.contractDetails.assetsDetail.some(asset => !!asset.clusters);
                  const hasValidClusters = st.contractDetails.assetsDetail.some(asset =>
                    asset.clusters && asset.clusters.some(cluster => cluster.status.code !== '3')
                  );
                  return new ActionButton(button.code, button.description, (isIncludeBtn && isCluster) ? hasValidClusters : true);
                }
              );
          }
          st.contractDetails.balanceDetail = balance;
          st.inquiryMode = this.integrationService.getRequestedInquiryMode(previousStepData);
          return st;
        }
      )
    );
  }

  getMode(sMode: string): Mode {
    return this.gpStatelessModesInquiryService.getMode(sMode);
  }

  hasFunction(sMode: string, sFunction: string): boolean {
    return this.gpStatelessModesInquiryService.hasFunction(sMode, sFunction);
  }
  retrievePolicyOperations$(groupPolicyStateInquiry$: Observable<GroupPolicyStateInquiry>, routeData: DataForSteps) {
    return groupPolicyStateInquiry$.pipe(
      mergeMap(
        (st) => {
          return combineLatest(of(st),
            this.groupPolicyService.getPolicyOperations$((routeData.issuedContractData &&
              routeData.issuedContractData.proposalNumber) ?
              routeData.issuedContractData.proposalNumber : routeData.proposalNumber).pipe(
              catchError((err) => {
                return of(err.error.message);
              }),
            ));
        }),
      map(
        ([st, resp]) => {
          st.contractMenuOperations = resp;
          return st;
        }
      )
    );
  }

  backToPrevPage(groupPolicyStateInquiry$: Observable<GroupPolicyStateInquiry>, activeRoute: ActiveRoute, idCurrentRoute: string) {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    const idParentSession = previousStepData.idParentSession ||
    ((previousStepData.issuedContractData && previousStepData.issuedContractData.isFromIssueContract) ? previousStepData.idParentSession : null);
    return groupPolicyStateInquiry$.pipe(st => {
        this.integrationService.backToPrevPage(idCurrentRoute, idParentSession);
        return st;
      }
    );
    }


  issueValidProposal(state$: Observable<GroupPolicyStateInquiry>, routeData: DataForSteps, activeRoute: ActiveRoute) {
    const tag = 'INQUIRY_DETAIL';
    this.errorService.cleanErrorsHandlerForTag(tag);
    return state$.pipe(
      concatMap((st: GroupPolicyStateInquiry) => {
        if (!routeData.contractStatus && !!st.contractDetails.proposalSettings) {
          routeData.contractStatus = st.contractDetails.proposalSettings.contractStatus;
        }
        const isVariation = routeData.contractStatus.code === "30";
        if(st.isManualPolicyNumEnabled && !isVariation) {
          const manualPolicyNumModal: ModalComponent = this.modalService.open(GroupPolicyModalManualPolicyNumberComponent, st.contractDetails.policyNumber);
          manualPolicyNumModal.enableClickBackground = false;
          return combineLatest(of(st), manualPolicyNumModal.onClose.pipe(
            concatMap((onCloseData: string | GpConfirmationEvent) => {
              if (onCloseData !== GpConfirmationEvent.CLOSE) {
                st.manualPolicyNumber = onCloseData;
                return this.crossService.evaluateOpenModalCheckApplications(st.contractDetails.applicationsToAuthorize);
              } else {
                return of(GpConfirmationEvent.CLOSE);
              }
            }), map((canProceed: GpConfirmationEvent) => canProceed)
          ));
        }
        return combineLatest(of(st), this.crossService.evaluateOpenModalCheckApplications(st.contractDetails.applicationsToAuthorize));
      }),
      mergeMap(([st, modalConfirmationEvent]: [GroupPolicyStateInquiry, GpConfirmationEvent | null]) => {
        if (!modalConfirmationEvent || modalConfirmationEvent === GpConfirmationEvent.CONFIRM) {
          return this.groupPolicyService.savePolicyByProposalNumber$(st.contractDetails.proposalNumber, st.manualPolicyNumber).pipe(
              map((savePolicyResp: IssuePolicyData) => {
                routeData.issuedContractData = savePolicyResp;
                this.integrationService.goToIssuePolicyPage(routeData, activeRoute.id);
                return st;
              }),
            catchError(this.errorService.catchApiErrorHandlerFn(st, tag))
          );
        }
        return of(st);
      }),
      map((st: GroupPolicyStateInquiry) => st)
    );
  }

  alignApplication$(state$: Observable<GroupPolicyStateInquiry>) {
    return state$.pipe(
      mergeMap((st) => {
        return this.groupPolicyService.alignApplicationOfContract$(st.contractDetails.proposalNumber).pipe(
          map((_resp) => {
            this.crossService.openBaseModal(
              _resp[0].errorDescription)
              .subscribe();
            return st;
          })
        );
      }),
      map((st: GroupPolicyStateInquiry) => st)
    );
  }

  cloneMasterPolicy(state$: Observable<GroupPolicyStateInquiry>) {
    return state$.pipe(
      mergeMap((st) => {
        return this.groupPolicyService.cloneMasterPolicy$(st.contractDetails.proposalNumber).pipe(
          map((_resp: IdentificationKey) => {
            const nextStepParam = {
              resId: _resp.uuid,
              operationCode: OPERATIONSCODE.ISSUE,
              managementNode: st.contractDetails.proposalSettings.nodeCode
            } as DataForSteps;
            this.integrationService.goToEdit(nextStepParam);
            return st;
          })
        );
      }),
      map((st: GroupPolicyStateInquiry) => st)
    );
  }


  goToContractDetailAtMovement(st$: Observable<GroupPolicyStateInquiry>, routeData: DataForSteps, idMov: string) {
    const newRouteData = cloneDeep(routeData);
    newRouteData.idMovement = idMov;
    return st$.pipe(
      map(st => {
        this.integrationService.goToInquiry(newRouteData);
        return st;
      })
    );
  }

  manageRecoverApplications$(proposalNumber: string) {
    this.recoverApplicationsService.manageRecoverApplications$(proposalNumber).subscribe();
  }

  openRequestAuthorizationModal(st: Observable<GroupPolicyStateInquiry>, activeRoute) {
    return this.authorizationsService.getAuthorizationFromInquiry$(st, activeRoute);
  }
}

import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'consultation-start-card-report-modal',
  templateUrl: './consultation-start-card-report-modal.component.html',
  styleUrls: ['./consultation-start-card-report-modal.component.css']
})
export class ConsultationStartCardReportModalComponent {
  constructor() {
  }

  @Output() modalClose = new EventEmitter();

  closeModal(extension: string) {
    this.modalClose.emit(extension);
  }
}

import {Component, ComponentRef, EventEmitter, OnInit, Output} from '@angular/core';
import {ApiPremiumDetail} from '../../models/api-models/api-premium-detail';
import {PremiumDetail} from '../../models/domain-models/premium-detail';
import {Modal} from '../../modal';
import {QuotationService} from '../quotation.service';
import {Warranty} from '../../models/domain-models/warranty';
import {ApiWarranty} from '../../models/api-models/api-warranty';
import {TotalTaxes} from './total-taxes';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ApiTaxComponent} from '../../models/api-models/api-tax-component';

@Component({
  selector: 'mic-contributions-taxes',
  templateUrl: './contributions-taxes-modal.component.html',
  styleUrls: ['./contributions-taxes-modal.component.scss']
})
export class ContributionsAndTaxesModalComponent implements Modal, OnInit {

  componentRef: ComponentRef<any>;
  premiumDetailSections: Array<PremiumDetail> = new Array<PremiumDetail>();
  // Premio di Rata Firma
  premiumInstallment: ApiPremiumDetail;
  annualPremium: ApiPremiumDetail;
  // Premio di Rata Successiva
  premiumSubscriptionInstalment: ApiPremiumDetail;
  annualPremiumWarranties: Array<Warranty> = new Array<Warranty>();
  instalmentPremiumWarranties: Array<Warranty> = new Array<Warranty>();
  subscriptionInstalmentWarranties: Array<Warranty> = new Array<Warranty>();
  taxComponents: any = {};
  instalmentTypes = ['Instalment at Inception Date', 'Next Instalment'];

  instalmentTotalTaxes: TotalTaxes = new TotalTaxes(0, 0, 0, 0, 0, 0, {});
  subscriptionInstalmentTotalTaxes: TotalTaxes = new TotalTaxes(0, 0, 0, 0, 0, 0, {});
  isPolicyInLPS: boolean;
  lpsAmount: number;
  lpsAmountrs: number;
  lpsAmountForm: UntypedFormGroup;


  @Output() eventLpsAmountModified: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();


  constructor(protected quotationService: QuotationService, protected formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.annualPremium = this.quotationService.getPremium().productPremium.annual;
    this.premiumInstallment = this.quotationService.getPremium().productPremium.instalment;
    this.premiumSubscriptionInstalment = this.quotationService.getPremium().productPremium.subscriptionInstalment;
    this.isPolicyInLPS = this.quotationService.isPolicyLps();
    this.lpsAmountForm = this.formBuilder.group({});
    if (this.isPolicyInLPS) {
      this.lpsAmount = this.quotationService.getPremium().productPremium.lpsTaxDetailSubscriptionInstallment.lpsAmount;
      this.lpsAmountrs = this.quotationService.getPremium().productPremium.lpsTaxDetailInstallment.lpsAmount;

      this.lpsAmountForm.addControl('lpsAmount', new UntypedFormControl(this.lpsAmount));
      this.lpsAmountForm.addControl('lpsAmountrs', new UntypedFormControl(this.lpsAmountrs));
    }

    const apiWarranties: Array<ApiWarranty> = this.quotationService.getPremium().warranties;

    apiWarranties.forEach(
      (apiWarranty: ApiWarranty) => {

        const annual = apiWarranty.annual;

        if (annual) {
          this.annualPremiumWarranties.push(
            new Warranty(apiWarranty.description, annual.taxable, annual.taxRate,
              annual.taxes, annual.antiracket, annual.gross, annual.sSN, annual.taxComponents));
        }

        const instalment = apiWarranty.instalment;

        if (instalment) {
          if (instalment.taxComponents) {
            instalment.taxComponents.forEach(component => this.taxComponents[component.code] = component.description);
          }
          this.instalmentPremiumWarranties.push(
            new Warranty(apiWarranty.description, instalment.taxable, instalment.taxRate,
              instalment.taxes, instalment.antiracket, instalment.gross, instalment.sSN, instalment.taxComponents));
        }

        const subscriptionInstalment = apiWarranty.subscriptionInstalment;

        if (subscriptionInstalment) {
          this.subscriptionInstalmentWarranties.push(
            new Warranty(apiWarranty.description, subscriptionInstalment.taxable, subscriptionInstalment.taxRate,
              subscriptionInstalment.taxes, subscriptionInstalment.antiracket,
              subscriptionInstalment.gross, subscriptionInstalment.sSN, subscriptionInstalment.taxComponents));
        }
      }
    );

    this.calculateTotalTaxes();

    this.initializePremiumDetailSections();
  }

  public calculateTotalTaxes() {
    this.instalmentTotalTaxes = new TotalTaxes(0, 0, 0, 0, 0, 0, {});
    this.subscriptionInstalmentTotalTaxes = new TotalTaxes(0, 0, 0, 0, 0, 0, {});

    this.instalmentPremiumWarranties.forEach((warranty) => {
      this.instalmentTotalTaxes.totalTaxes += parseFloat(String(warranty.taxes));
      this.instalmentTotalTaxes.totalAntiLaunderingMoneyTaxes += parseFloat(String(warranty.antiMoneyLaunderingTaxes));
      this.instalmentTotalTaxes.totalGrossAmounts += parseFloat(String(warranty.grossAmount));
      this.instalmentTotalTaxes.totalSSN += parseFloat(String(warranty.ssn));
      this.instalmentTotalTaxes.totalTaxRates = 0;
      this.instalmentTotalTaxes.totalTaxable += parseFloat(String(warranty.taxableAmount));
      warranty.taxComponents.forEach(c => {
        if (this.instalmentTotalTaxes.totalTaxComponentsAmount[c.code]) {
          this.instalmentTotalTaxes.totalTaxComponentsAmount[c.code] += parseFloat(String(c.amount));
        } else {
          this.instalmentTotalTaxes.totalTaxComponentsAmount[c.code] = parseFloat(String(c.amount));
        }
      });
    });
    this.subscriptionInstalmentWarranties.forEach((warranty) => {
      this.subscriptionInstalmentTotalTaxes.totalTaxes += parseFloat(String(warranty.taxes));
      this.subscriptionInstalmentTotalTaxes.totalAntiLaunderingMoneyTaxes += parseFloat(String(warranty.antiMoneyLaunderingTaxes));
      this.subscriptionInstalmentTotalTaxes.totalGrossAmounts += parseFloat(String(warranty.grossAmount));
      this.subscriptionInstalmentTotalTaxes.totalSSN += parseFloat(String(warranty.ssn));
      this.subscriptionInstalmentTotalTaxes.totalTaxRates = 0;
      this.subscriptionInstalmentTotalTaxes.totalTaxable += parseFloat(String(warranty.taxableAmount));
      warranty.taxComponents.forEach(c => {
        if (this.subscriptionInstalmentTotalTaxes.totalTaxComponentsAmount[c.code]) {
          this.subscriptionInstalmentTotalTaxes.totalTaxComponentsAmount[c.code] += parseFloat(String(c.amount));
        } else {
          this.subscriptionInstalmentTotalTaxes.totalTaxComponentsAmount[c.code] = parseFloat(String(c.amount));
        }
      });
    });

    if (this.isPolicyInLPS) {
      this.lpsAmount = this.lpsAmountForm.get('lpsAmount').value;
      this.lpsAmountrs = this.lpsAmountForm.get('lpsAmountrs').value;
      this.instalmentTotalTaxes.totalTaxes += parseFloat(String(this.lpsAmountrs));
      this.instalmentTotalTaxes.totalGrossAmounts += parseFloat(String(this.lpsAmountrs));
      this.subscriptionInstalmentTotalTaxes.totalTaxes += parseFloat(String(this.lpsAmount));
      this.subscriptionInstalmentTotalTaxes.totalGrossAmounts += parseFloat(String(this.lpsAmount));
    }
  }

  close() {
    if (this.isPolicyInLPS && this.quotationService.getPremium().productPremium.lpsTaxDetailSubscriptionInstallment &&
      this.quotationService.getPremium().productPremium.lpsTaxDetailInstallment) {

      const valuelpsAmount = this.lpsAmountForm.get('lpsAmount').value;
      const valuelpsAmountrs = this.lpsAmountForm.get('lpsAmountrs').value;
      this.eventLpsAmountModified.emit([valuelpsAmount, valuelpsAmountrs]);
    }
    this.componentRef.destroy();
  }

  private initializePremiumDetailSections() {
    this.premiumDetailSections.push(
      new PremiumDetail('Premium Rate', (this.premiumInstallment.gross) ? this.premiumInstallment.gross : 0.00,
        (this.premiumInstallment.net) ? this.premiumInstallment.net : 0.00,
        (this.premiumInstallment.accessories) ? this.premiumInstallment.accessories : 0.00,
        (this.premiumInstallment.paymentFrequencyInterest) ? this.premiumInstallment.paymentFrequencyInterest : 0.00,
        (this.premiumInstallment.taxable) ? this.premiumInstallment.taxable : 0.00,
        (this.premiumInstallment.taxes) ? this.premiumInstallment.taxes : 0.00,
        (this.premiumInstallment.sSN) ? this.premiumInstallment.sSN : 0.00)
    );
    this.premiumDetailSections.push(
      new PremiumDetail('Annual Premium', (this.annualPremium.gross) ? this.annualPremium.gross : 0.00,
        (this.annualPremium.net) ? this.annualPremium.net : 0.00,
        (this.annualPremium.accessories) ? this.annualPremium.accessories : 0.00,
        (this.annualPremium.paymentFrequencyInterest) ? this.annualPremium.paymentFrequencyInterest : 0.00,
        (this.annualPremium.taxable) ? this.annualPremium.taxable : 0.00,
        (this.annualPremium.taxes) ? this.annualPremium.taxes : 0.00,
        (this.annualPremium.sSN) ? this.annualPremium.sSN : 0.00)
    );
    this.premiumDetailSections.push(
      new PremiumDetail('Subscription Inst. Premium',
        (this.premiumSubscriptionInstalment.gross) ? this.premiumSubscriptionInstalment.gross : 0.00,
        (this.premiumSubscriptionInstalment.net) ? this.premiumSubscriptionInstalment.net : 0.00,
        (this.premiumSubscriptionInstalment.accessories) ? this.premiumSubscriptionInstalment.accessories : 0.00,
        (this.premiumSubscriptionInstalment.paymentFrequencyInterest)
          ? this.premiumSubscriptionInstalment.paymentFrequencyInterest : 0.00,
        (this.premiumSubscriptionInstalment.taxable) ? this.premiumSubscriptionInstalment.taxable : 0.00,
        (this.premiumSubscriptionInstalment.taxes) ? this.premiumSubscriptionInstalment.taxes : 0.00,
        (this.premiumSubscriptionInstalment.sSN) ? this.premiumSubscriptionInstalment.sSN : 0.00)
    );
  }

  getWarrantiesPremiumByType(type: string): Warranty[] {
    if (type === this.instalmentTypes[0]) {
      return this.subscriptionInstalmentWarranties;
    } else {
      return this.instalmentPremiumWarranties;
    }
  }

  getInstalmentPremiumByType(type: string): ApiPremiumDetail {
    if (type === this.instalmentTypes[0]) {
      return this.premiumSubscriptionInstalment;
    } else {
      return this.premiumInstallment;
    }
  }

  getTotalTaxesByType(type: string): TotalTaxes {
    if (type === this.instalmentTypes[0]) {
      return this.subscriptionInstalmentTotalTaxes;
    } else {
      return this.instalmentTotalTaxes;
    }
  }

  getTaxComponentsKeys(): string[] {
    return Object.keys(this.taxComponents);
  }
  isTaxComponentPresent(key: string, taxComponents: ApiTaxComponent[]): boolean {
    return taxComponents.some(c => c.code === key);
  }
  getWarrantyTaxComponent(key: string, taxComponents: ApiTaxComponent[]): ApiTaxComponent {
    return taxComponents.find(c => c.code === key);
  }

  getLpsAmountFormName(type: string): string {
    if (type === this.instalmentTypes[0]) {
      return 'lpsAmount';
    } else {
      return 'lpsAmountrs';
    }
  }

  getLpsAmountDataQa(type: string): string {
    if (type === this.instalmentTypes[0]) {
      return 'lps-inception-amount';
    } else {
      return 'lps-nextinstalment-amount';
    }
  }
}

import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {KarmaFundDefinition} from '../../model/karma-fund-definition';
// eslint-disable-next-line max-len
import {LicCheckboxAccordionComponent} from '../../../invest-standalone-session/component/lic-checkbox-accordion/lic-checkbox-accordion.component';
import { PolicyService } from '../../../services/policy-service';
import { LicCustomProperties } from '../../../enum/life-issue.enum';
import { LicToolUtils } from '../../../../life-card/lic-tools/utils/lic-tool-utils';

@Component({
  selector: 'lic-disinvestment-button[definition]',
  templateUrl: './lic-disinvestment-button.component.html',
  styleUrls: ['./lic-disinvestment-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicDisinvestmentButtonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicDisinvestmentButtonComponent),
      multi: true
    }
  ],
})
export class LicDisinvestmentButtonComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  private $subscriptions: Subscription[] = [];
  public listOfPercentage = [];

  @ViewChild('accordion', {static: true}) accordion: LicCheckboxAccordionComponent;
  @Input() public definition: KarmaFundDefinition;
  @Output() toolCheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public toolId: string;
  @Input() minThreshold: number;
  @Input() maxThreshold: number;
  @Input() minDisinvestmentAmount: number;
  @Input() maxDisinvestmentAmount: number;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl(false),
    percent: new UntypedFormControl(null),
    disinvestmentAmount: new UntypedFormControl(null)
  });

  public get isActive(): boolean {
    return this.formGroup.get('active').value;
  }

  public get showThreshold(): boolean {
    return !LicToolUtils.isToolProgressiveSwitch(this.toolId);
  }

  constructor(protected policyService: PolicyService) {
    const peatto = this.policyService.getPropertyFromProduct(LicCustomProperties.PERCENTUALE_ATTIVAZIONE_TOOL);
    this.listOfPercentage = peatto ? peatto.values : [];
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.get('active').valueChanges.subscribe(value => {
        if (value && !LicToolUtils.isToolProgressiveSwitch(this.toolId)) {
          this.formGroup.get('percent').setValidators([
            Validators.required,
            Validators.min(this.minThreshold * 100),
            Validators.max(this.maxThreshold * 100)
          ]);
          this.formGroup.get('percent').setValue(0, { emitEvent: false });
          this.onChange({ percent: Number(this.formGroup.get('percent').value) / 100 });
        } else {
          this.formGroup.get('percent').clearValidators();
          this.formGroup.get('percent').setValue(null, { emitEvent: false });
          this.formGroup.get('disinvestmentAmount').setValidators([
            Validators.required,
            Validators.min(this.minDisinvestmentAmount),
            Validators.max(this.maxDisinvestmentAmount)
          ]);
          this.formGroup.get('disinvestmentAmount').setValue(0, { emitEvent: false });
          this.onChange({disinvestmentAmount: value});
        }
        this.onTouch();
      }),
      this.formGroup.get('percent').valueChanges.subscribe(value => {
        if (value !== null) {
          // this.formGroup.get('percent').setValue(0, { emitEvent: false });
          this.onChange({ percent: Number(value) });
        } else {
          this.onChange({ percent: null});
        }
        this.onTouch();
      }),
      this.formGroup.get('disinvestmentAmount').valueChanges.subscribe(value => {
        this.onChange({disinvestmentAmount: value});
      })
    );
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!LicToolUtils.isToolProgressiveSwitch(this.toolId)) {
      return this.formGroup.get('percent').errors;
    } else {
      return this.formGroup.get('disinvestmentAmount').errors;
    }
  }

  writeValue(object: {percent: number, disinvestmentAmount: number}): void {
    if (!!object && !!object.percent) {
      this.formGroup.get('percent').setValue(object.percent, { emitEvent: false });
      // this.formGroup.get('active').setValue(true, { emitEvent: false });
      this.accordion.activate(false);
    } else if (!!object && !!object.disinvestmentAmount) {
      this.formGroup.get('disinvestmentAmount').setValue(object.disinvestmentAmount, { emitEvent: false });
      // this.formGroup.get('active').setValue(false, { emitEvent: false });
      this.accordion.activate(false);
    } else {
      this.formGroup.get('disinvestmentAmount').setValue(null, { emitEvent: false });
      this.formGroup.get('percent').setValue(null, { emitEvent: false });
      this.accordion.deactivate(false);
    }
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  onAccordionClick($event: boolean) {
    this.formGroup.get('active').setValue($event);
    this.toolCheck.emit($event);
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OutputDamageParts } from './dsc-damaged-parts.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DscDamagedPartsService {

    private baseApiUrlV2: string;
    damagedparts: string = '/claims/claim/damagedparts';

    constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path;
  }

    postDamagedParts(body: any): Observable<OutputDamageParts> {
        return this.httpClient.post<OutputDamageParts>(this.baseApiUrlV2 + this.damagedparts, body);
}
}

import { Component, OnInit } from '@angular/core';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';

@Component({
  selector: 'rgi-gp-group-policy-coinsurances-detail',
  templateUrl: './group-policy-coinsurances-detail.component.html'
})
export class GroupPolicyCoinsurancesDetailComponent extends GpInquiryComponentDetail implements OnInit {

  public displayedCoinsuranceColumns: string[] = ['type', 'company', 'percentage'];
  constructor(protected stateMngr: GroupPolicyStateManagerInquiry) {
    super(stateMngr);
  }

  ngOnInit() {
  }

}

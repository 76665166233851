import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Questionnaire, ClaimNumberQuestionnair } from './claim-questionnaire/claim-questionnaire.interface';

@Injectable({
  providedIn: 'root'
})
export class ClaimQuestionnaireService {

  private baseApiUrlRs: string;
  private baseApiUrl: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrlRs = environment.api.portal.host + environment.api.portal.path;
  }

  getQuestionnaireResponse(body: ClaimNumberQuestionnair): Observable<Questionnaire> {
    return this.httpClient.post<Questionnaire>(this.baseApiUrl + '/claims/claimdetails/getQuestionnaire', body);
  }
  postSaveQuestionnaire(body: Questionnaire): Observable<Questionnaire> {
    return this.httpClient.post<Questionnaire>(this.baseApiUrl + '/claims/claimdetails/saveQuestionnaire', body);
  }

}

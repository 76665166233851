import {AnagPartyConfiguration} from './anag-party-configuration';

export class AnagAddressConfig {
  adminLevel1: AnagPartyConfiguration;
  adminLevel1Short: AnagPartyConfiguration;
  adminLevel2: AnagPartyConfiguration;
  adminLevel2Short: AnagPartyConfiguration;
  adminLevel3: AnagPartyConfiguration;
  adminLevel3Short: AnagPartyConfiguration;
  at: AnagPartyConfiguration;
  bankCode: AnagPartyConfiguration;
  cap: AnagPartyConfiguration;
  city: AnagPartyConfiguration;
  country: AnagPartyConfiguration;
  countryCode: AnagPartyConfiguration;
  detailCode: AnagPartyConfiguration;
  formatAddress: AnagPartyConfiguration;
  fraction: AnagPartyConfiguration;
  latitudine: AnagPartyConfiguration;
  locality: AnagPartyConfiguration;
  longitude: AnagPartyConfiguration;
  number: AnagPartyConfiguration;
  placeAddress: AnagPartyConfiguration;
  province: AnagPartyConfiguration;
  subLocality: AnagPartyConfiguration;
  toponym: AnagPartyConfiguration;
}

export class AnagAddressLayoutConfig {
  address: AnagAddressLayout;
  addressAddition: AnagAddressLayout;
  adminLevel1: AnagAddressLayout;
  adminLevel1Short: AnagAddressLayout;
  adminLevel2: AnagAddressLayout;
  adminLevel2Short: AnagAddressLayout;
  adminLevel3: AnagAddressLayout;
  adminLevel3Short: AnagAddressLayout;
  cco: AnagAddressLayout;
  countryCode: AnagAddressLayout;
  estensione: AnagAddressLayout;
  houseNumber: AnagAddressLayout;
  latitude: AnagAddressLayout;
  locality: AnagAddressLayout;
  longitude: AnagAddressLayout;
  postalAddress: AnagAddressLayout;
  specialMention: AnagAddressLayout;
  subLocality: AnagAddressLayout;
  toponym: AnagAddressLayout;
  zip: AnagAddressLayout;
}

export class AnagAddressLayout {
  // layout: V - visible, H - hidden
  layout: string;
  // state: O - optional, M - mandatory
  state: string;
  label: string;
  order: number | null;
}

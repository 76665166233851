import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import { LicDatepickerComponent } from './lic-datepicker.component';


@NgModule({
  declarations: [LicDatepickerComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
  ],
  exports: [LicDatepickerComponent]
})
export class LicDatepickerModule { }

import {LogLevel, logLevelAsString} from './logging-api';
import {LoggerFactory} from './logging-providers';

/**
 * Log decorators metadata
 * @see Log
 * @since 0.3.1
 */
export interface RgiRxDecoratorMetadata {
  /**
   * Log level to use. Default is DEBUG
   * @see LogLevel
   */
  level?: LogLevel;
  /**
   * a message to apply
   */
  message?: string;
}

/**
 * @Log decorator for class methods
 * @param logMethod optional configuration
 * @since 0.3.1
 */
export function Log(logMethod?: RgiRxDecoratorMetadata) {
  return function(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;
    descriptor.value = function(...args: any[]) {
      const loggerFactory = LoggerFactory();
      let level = LogLevel.DEBUG;
      let message = '';
      if (logMethod) {
        if (logMethod.level) {
          level = logMethod.level;
        }
        message = logMethod.message ? ' ' + logMethod.message : '';
      }
      let targetName = '';
      if (target.constructor) {
        targetName = `${target.constructor.name}::`;
      }
      // @ts-ignore
      loggerFactory[logLevelAsString(level)](`${targetName}${propertyKey}${message}`, ...args);
      original.apply(this, args);
    };
  };
}

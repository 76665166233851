<template #claimModal></template>
<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
                    <span translate>
                        <span *ngIf="claimsData.isNewClaim" data-qa="btn-claim-modal-add" translate>Add</span>
                        <span *ngIf="!claimsData.isNewClaim" data-qa="btn-claim-modal-edit" translate>Edit</span>
                        Equal Responsability Claim
                    </span>
          <span (click)="cancel()" class="glyphicon glyphicon-remove pull-right cursorPointer"
                data-qa="btn-claim-modal-cancel"></span>
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="claimModalForm">
          <br>
          <div class="form-group row">
            <div class="col-12">
              <label translate>Year:</label>
              <pnc-required required="true"></pnc-required>
              <select #year (change)="funzioneOnChange(year?.value)" [attr.disabled]="!claimsData.isNewClaim ? '' : null" class="select-nativa" data-qa="claim-modal-year"
                      formControlName="year"
                      name="year">
                <!--<option [value]="selectedClaimDetails.year">{{selectedClaimDetails.year}}</option>-->
                <option *ngFor="let year of claimsData.yearList">{{year}}</option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
          </div>
          <div *ngIf="!claimsData.isNewClaim && claimsData.selectedClaimDetails?.claimNumber"
               class="form-group row">
            <div class="col-12">
              <label translate>Claim Number:</label>
              <input #claimNumber [value]="claimsData.selectedClaimDetails?.claimNumber" class="form-control mic-input"
                     data-qa="claim-modal-claim-number" disabled
                     formControlName="claimNumber" type="text">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <label translate>Responsability Portion:</label>
              <pnc-required required="true"></pnc-required>
              <input #responsabilityPortion (ngModelChange)="validateForm()" [value]="claimsData.selectedClaimDetails?.responsabilityPortion"
                     class="form-control mic-input"
                     data-qa="claim-modal-responsability-portion" formControlName="responsabilityPortion"
                     type="number">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <!-- <input type="checkbox" #malus name="malus" [value]="selectedClaimDetails?.malus"
                  formControlName="malus"> -->
              <label translate>Calcolate Malus:</label>
              <div #claimMalusCheckbox (click)="onChangeMalus()" class="btn btn-checkbox checkbox-unit"
                   formControlName="malus" ngDefaultControl
                   type="checkbox">
                                <span [ngClass]="{'glyphicon-ok': claimModalForm?.controls?.malus?.value === 'true' }" class="glyphicon" data-qa="claim-modal-malus"
                                      style="color: #f68020;">
                                </span>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <label translate>Damage Type:</label>
              <pnc-required *ngIf="year?.value>=2015" required="true"></pnc-required>
              <select #damageType class="select-nativa" data-qa="claim-modal-damage-type" formControlName="damageType"
                      name="damageType">
                <option></option>
                <option
                  *ngFor="let damageTypeElement of claimsData.damageTypeList; index as i; trackBy: genericEntitiesTrackByFn"
                  [value]="damageTypeElement?.id">{{damageTypeElement?.description}}</option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <label translate>Description:</label>
              <input #description [value]="claimsData.selectedClaimDetails?.description" class="form-control mic-input" data-qa="claim-modal-description"
                     formControlName="description"
                     type="text">
            </div>
          </div>
        </form>
        <div *ngIf="(claimModalForm?.invalid && validationMessages && validationMessages?.length > 0)" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" data-qa="btn-claim-modal-confirm" translate>CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import {GpUnitClause, SingleUnit} from '../../../group-policy-models/group-policy-issue-guarantees';
import {GPClause} from '../../../group-policy-models/group-policy-issue-policy-data';

@Pipe({
  name: 'gpFilterUnitClauses'
})
export class GpFilterUnitClausesPipe implements PipeTransform {

  transform(unitClauses: GpUnitClause[], unit: SingleUnit): GPClause[] {
    const gpUnitClause = unitClauses.find(value => value.codeUnit === unit.code && value.codeSection === unit.sectionCode);
    return gpUnitClause ? gpUnitClause.unitClauses : [];
  }

}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 're-issue-enter-pin-modal',
  templateUrl: './re-issue-enter-pin-modal.component.html'
})
export class ReIssueEnterPinModalComponent implements OnInit {
  pinSubmitted = false;
  pinValue: string;
  @Output() modalClose = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  // onKeyPress(event){
  //   if (!(event.keyCode >= 48 && event.keyCode <= 57 && event.KeyCode === 8 )){
  //         event.preventDefault()
  //   }
  // }

  confirmPurchase() {
    this.pinSubmitted = true;
    this.modalClose.emit();
  }

}

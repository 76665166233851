<div *ngIf="stateManager.getState$() | async as state">
  <form [formGroup]="startCardFormGroup">
    <!-- README : https://architecture.git.ad.rgigroup.com/frontend/rgi-ui/ => GRID -->
    <div class="rgi-ui-grid-1">
      <!-- COMPANY -->
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel><span translate>RE_ISSUE.COMPANY</span>
          </label>
          <select rgiRxNativeSelect formControlName="company" data-qa="company">
            <option value=""></option>
            <option *ngFor="let company of state.companyList" [ngValue]="company">{{company.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <!-- NODE MANAGEMENT -->
      <div class="rgi-ui-col">
        <label rgiRxLabel class="label-core" for="node" [ngClass]="{'required': startCardFormGroup.get('node').errors?.required}">
          <span translate>RE_ISSUE.NODE_START_CARD</span>
        </label>
        <div [ngClass]="{'is-invalid': startCardFormGroup.get('node').invalid}">
          <rgi-rx-drop-container formControlName="node" viewField="description"
                                 select="nodeSelection" [selectData]="state.nodeList" data-qa="node">
            <ng-template rgiRxDropLabel let-ctx>
              <span translate>RE_ISSUE.SELECT_NODE</span>
            </ng-template>
          </rgi-rx-drop-container>
        </div>
      </div>
      <!-- EFFECTIVE DATE -->
      <div class="rgi-ui-col">
        <rgi-rx-form-field class="date-field">
          <div class="label-input-container">
            <label rgiRxLabel><span translate>RE_ISSUE.EFFECTIVE_DATE</span></label>
            <div class="input-button-container">
              <input [rgiRxDateTimeInput]="effectiveDatePicker"
                     style="flex: 1"
                     formControlName="effectiveDate"
                     selectMode="single"
                     rangeSeparator="/"
                     data-qa="effectiveDate"
                     placeholder="gg/mm/yyyy" #inputToTriggerEffectiveDate="rgiRxDateTimeInput">
              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerEffectiveDate"></button>
            </div>
          </div>
          <rgi-rx-date-time #effectiveDatePicker
                            pickerType="calendar"
                            [hour12Timer]="true"
          ></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>
      <!-- PRODUCT -->
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel><span translate>RE_ISSUE.PRODUCT</span>
          </label>
          <select rgiRxNativeSelect formControlName="product" data-qa="product">
            <option value=""></option>
            <option *ngFor="let product of state.productList" [ngValue]="product">{{product.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <!-- ERRORS -->
      <div class="rgi-ui-col">
        <rgi-rx-snackbar tag="errorMessage"></rgi-rx-snackbar>
      </div>
    </div>
    <!-- BUTTONS GROUP -->
    <!-- README : https://architecture.git.ad.rgigroup.com/frontend/rgi-ui/ => GRID -->
    <div class="rgi-ui-grid-2 rgi-ui-grid-no-gutter">
      <!-- CLEAR -->
      <div class="rgi-ui-col btn-group">
        <button class="rgi-re-button" rgi-rx-button color="secondary" (click)="clear()" data-qa="clear"><span
          translate>RE_ISSUE.EMPTY</span></button>
      </div>
      <!-- FIND -->
      <div class="rgi-ui-col btn-group">
        <span><button class="rgi-re-button" rgi-rx-button color="primary" (click)="goToNextPage()" data-qa="next"><span
          translate>RE_ISSUE.NEXT_START_CARD</span></button></span>
      </div>
    </div>
  </form>
</div>

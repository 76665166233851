import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ResourceService} from '../../resource/service/resource.service';
import {
  ContractPayment,
  EnumPayment,
  MeanOfPayment,
  PaymentConfig,
  PaymentConfigResp,
  PaymentConfigSelected
} from '../payments-data';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(protected resourceService: ResourceService) {
  }

  public getPaymentEnabled$(): Observable<boolean> {
    return this.resourceService.getPaymentsEnabled$();
  }

  public getEditablePaymentConfigs$(resId: string): Observable<PaymentConfig | null> {
    const getPaymentConfigDebit$ = this.resourceService.getConfigPayments$(resId, EnumPayment.DEBIT);
    const getPaymentConfigCredit$ = this.resourceService.getConfigPayments$(resId, EnumPayment.CREDIT);

    const res: PaymentConfig = new PaymentConfig();
    res.debMeansOfPayment = [];
    res.credMeansOfPayment = [];
    getPaymentConfigDebit$.subscribe((resp) => {
      for (let i = 0; resp.debMeansOfPayment && i < resp.debMeansOfPayment.length; i++) {
        res.debMeansOfPayment[i] = {
          editable: true,
          paymentConfig: resp.debMeansOfPayment[i],
          selected: true
        };
      }
    });
    getPaymentConfigCredit$.subscribe((resp) => {
      for (let i = 0; resp.credMeansOfPayment && i < resp.credMeansOfPayment.length; i++) {
        res.credMeansOfPayment[i] = {
          editable: true,
          paymentConfig: resp.credMeansOfPayment[i],
          selected: true
        };
      }
    });
    return of(res);
  }

  setMeanPayment(resId: string, contractPayments: ContractPayment[]): Observable<any> {
    return this.resourceService.setContractPayments$(resId, contractPayments);
  }

  protected resetPayments(meansOfPayment: MeanOfPayment[]) {
    meansOfPayment.forEach(meanOfPayment => {
      meanOfPayment.selected = false;
      if (meanOfPayment.paymentConfig && meanOfPayment.paymentConfig.paymentsFields
        && meanOfPayment.paymentConfig.paymentsFields.length) {
        meanOfPayment.paymentConfig.paymentsFields.forEach(field => {
          field.value = undefined;
        });
      }
    });
  }

  getAvailablePayments$(uuid: string): Observable<MeanOfPayment[]> {
    const ret = [];
    return this.resourceService.getAvailablePayments$(uuid).pipe(
      catchError(
        error => of(ret)
      ),
      map((resp: PaymentConfigSelected[]) => {
        for (let i = 0; i < resp.length; i++) {
          const payment = resp[i].contractPayment.payment;
          ret[i] = {
            editable: resp[i].editable,
            paymentConfig: {
              meanOfPayment: {
                id: payment.meanPayment.id,
                code: payment.meanPayment.code,
                description: payment.meanPayment.description
              },
              paymentType: payment.idPaymentType,
              paymentsFields: [
                { name: 'CIBAN', value: payment.iban },
                { name: 'CBIC', value: payment.bic },
                { name: 'IDCRDCARD', value: payment.creditCardId },
                { name: 'DCARDEXPE', value: payment.creditCardExpireDate },
                { name: 'CHOLDER', value: payment.holder },
                { name: 'DACTIVATIONSDD', value: payment.sddActivationDate },
                { name: 'DNOTIFICATION', value: payment.notificationDate },
                { name: 'CCHECKNUM', value: payment.checkNumber },
                { name: 'NCASH', value: payment.cash },
                { name: 'CNUMTRANSACTION', value: payment.transactionNumber },
                { name: 'CUIC', value: payment.uic },
                { name: 'CFOREIGNCURR', value: payment.foreignCurrency },
                { name: 'CFOREIGNACC', value: payment.foreignAccount },
                { name: 'DADJ', value: payment.adjustmentDate },
                { name: 'CDESCRTRANSF', value: payment.transferDescr },
                { name: 'CBANKNAME', value: payment.bankName },
                { name: 'CBANKADDRESS', value: payment.bankAddress },
                { name: 'CHOLDERKEY', value: payment.holderKey },
                { name: 'CHOLDERADDRESS', value: payment.holderAddress },
                { name: 'ESTATESDD', value: payment.sddIdState },
                { name: 'DCANCSDD', value: payment.sddCancelDate },
                { name: 'CCODSDD', value: payment.sddCode },
                { name: 'CSUBSCRIBER', value: payment.subscriber },
                { name: 'CSUBSCRIBERKEY', value: payment.subscriberKey },
                { name: 'CRUM', value: payment.rum },
                { name: 'ERRORTYPE', value: payment.errorType },
                { name: 'CERRORCODESDD', value: payment.sddErrorCode },
                { name: 'CERRORDESCSDD', value: payment.sddErrorDescr },
                { name: 'DERRORRESPONSESDD', value: payment.sddErrorResponse },
                { name: 'DRESPONSESDD', value: payment.sddResponse }
              ]
            },
            selected: resp[i].selected
          };
        }
        return ret;
      })
    );
  }

}

import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {GroupPolicyStateInquiry, GroupPolicyStateSummary} from '../group-policy-state/group-policy-state';
import {DataForSteps} from '../group-policy-models/group-policy-issue-home';
import {ActiveRoute} from '@rgi/rx/router';
import {catchError, concatMap, map, mergeMap, take} from 'rxjs/operators';
import {SaveResp} from '../group-policy-models/group-policy-issue-policy-data';
import {
  GroupPolicyModalAuthorizationDataComponent
} from '../group-policy-components/group-policy-modals/group-policy-modal-authorization-data/group-policy-modal-authorization-data.component';
import {GpProposalAuthorization} from '../group-policy-models/group-policy-authorization';
import {GroupPolicyApiRestErrorService} from './group-policy-api-rest-error.service';
import {GroupPolicyResourceService} from '../group-policy-resources/group-policy-resource.service';
import {ModalComponent, ModalService} from '@rgi/rx/ui';
import {GroupPolicyCrossService} from './group-policy-cross.service';
import {GroupPolicyIntegrationService} from './group-policy-integration.service';
import {EventService} from '@rgi/rx';
import {CallBackJumpInstance} from '../group-policy-events/group-policy-event';
import {GO_TO_INQUIRY_BTN} from '../group-policy-models/gp-modals';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyAuthorizationService {

  private authorizationModal: ModalComponent;
  constructor(
    protected groupPolicyService: GroupPolicyResourceService,
    protected gpErrorService: GroupPolicyApiRestErrorService,
    protected modalService: ModalService,
    protected crossService: GroupPolicyCrossService,
    protected integrationService: GroupPolicyIntegrationService,
    protected eventService: EventService
  ) { }

  getAuthorization$(state$: Observable<GroupPolicyStateSummary>, previousStepData: DataForSteps, activeRoute: ActiveRoute) {
    return state$.pipe(
      concatMap((state) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'AUTHORIZATION_DATA');
        return this.groupPolicyService.saveAction$(previousStepData.resId, previousStepData.node).pipe(
          map((saveResp: SaveResp) => {
            state.proposalNumber = saveResp.proposalNumber;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'AUTHORIZATION_DATA'))
        );
      }),
      concatMap((state) => {
        return this.groupPolicyService.retrieveAuthorizationData$(previousStepData.resId).pipe(
          map(authorizationData => {
            state.authorizationData =  authorizationData;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'AUTHORIZATION_DATA'))
        );
      }),
      concatMap((state) => {
        this.authorizationModal = this.modalService.open(GroupPolicyModalAuthorizationDataComponent, state.authorizationData);
        this.authorizationModal.enableClickBackground = false;

        return combineLatest(of(state), this.authorizationModal.onClose.pipe(take(1)));
      }),
      mergeMap(([state, onCloseData]: [GroupPolicyStateSummary, GpProposalAuthorization]) => {
        if (onCloseData) {
          return this.groupPolicyService.saveAuthorizationData$(previousStepData.resId, null, onCloseData).pipe(
            map((_resp) => {
              this.crossService.openBaseModalWithParams(
                '_GP_._LABEL_._REQUEST_AUTHORIZATION_DONE_',
                {proposalNumber: state.proposalNumber}, [GO_TO_INQUIRY_BTN])
                .subscribe((onCloseAction: string) => {
                  if (!onCloseAction) {
                    this.integrationService.closeSession(activeRoute.id);
                  } else {
                    this.crossService.manageGenericModalOnClose(onCloseAction, state.proposalNumber, activeRoute.id);
                  }
                });

              return state;
            }),
            catchError(this.gpErrorService.catchApiErrorFn(state, 'AUTHORIZATION_DATA'))
          );
        }
        return of(state);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  getAuthorizationFromInquiry$(state$: Observable<GroupPolicyStateInquiry>, activeRoute: ActiveRoute) {
    return state$.pipe(
      concatMap((state) => {
        return this.groupPolicyService.retrieveAuthorizationData$(null, state.contractDetails.proposalNumber).pipe(
          map(authorizationData => {
            state.authorizationData =  authorizationData;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'AUTHORIZATION_DATA'))
        );
      }),
      concatMap((state) => {
        this.authorizationModal = this.modalService.open(GroupPolicyModalAuthorizationDataComponent, state.authorizationData);
        this.authorizationModal.enableClickBackground = false;

        return combineLatest(of(state), this.authorizationModal.onClose.pipe(take(1)));
      }),
      mergeMap(([state, onCloseData]: [GroupPolicyStateInquiry, GpProposalAuthorization]) => {
        if (onCloseData) {
          return this.groupPolicyService.saveAuthorizationData$(null, state.contractDetails.proposalNumber, onCloseData).pipe(
            map((_resp) => {
              this.crossService.openBaseModalWithParams(
                '_GP_._LABEL_._REQUEST_AUTHORIZATION_DONE_',
                {proposalNumber: state.contractDetails.proposalNumber})
                .subscribe(() => {
                  this.eventService.emit(new CallBackJumpInstance(activeRoute.id));
                });

              return state;
            }),
            catchError(this.gpErrorService.catchApiErrorFn(state, 'AUTHORIZATION_DATA'))
          );
        }
        return of(state);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateInquiry) => st)
    );
  }
}

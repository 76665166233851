import {Component, Inject, Input} from '@angular/core';
import {RgiRxDataTableExpansionModel, TableSchema} from '@rgi/rx/ui';
import {Observable} from 'rxjs';
import {FormGroupDirective, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'rgi-pnc-psales-supplementary-tech-acc-table',
  templateUrl: './supplementary-tech-acc-table.component.html'
})
export class PncPsalesSupplementaryTechAccTableComponent {

  @Input() rows: Observable<any[]>;
  @Input() schema: TableSchema;
  @Input() parentFormGroup: UntypedFormGroup;

  constructor() {
  }

  public expansionModel = new RgiRxDataTableExpansionModel([], {
    multiple: true,
    canExpand: (_row) => true,
    canContract: (_row) => true
  });

}

import { ClassAsset } from './class-asset';
import { UseAsset } from './use-asset';
import { ExtensionAsset } from './extension-asset';
import { LicencePlateType } from './licence-plate-type';
import { SpecialAbbreviation } from './special-abbreviation';

export class Vehicle {
    public classAsset: ClassAsset;
    public useAsset: UseAsset;
    public extensionAsset: ExtensionAsset;
    public licencePlateType: LicencePlateType;
    public licencePlateNumber: string;
    public specialAbbreviation: SpecialAbbreviation;

    constructor() { }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxHttpClientHandler, RgiRxHttpClientInterceptorResolver} from './rgi-rx-http-handler.service';
import {HttpBackend} from '@angular/common/http';
import {RGI_RX_HTTP_CLIENT_INTERCEPTOR, RgiRxDefaultHttpErrorAdapter, RgiRxHttpErrorAdapter} from './http-client.api';
import {RgiRxHttpErrorStream} from '../rgi-rx-http-error-stream';
import {GzipRequestInterceptorService} from '../gzip/gzip-request-interceptor.service';
import {HttpPendingRequestInterceptor} from '../http-pending-request-interceptor.service';
import {RgiRxHttpClient, RgiRxHttpClientWrapper} from './rgi-rx-http-client.service';
import {RgiRxHttpClientFactory} from './rgi-rx-http-client-factory';


export function provideRgiRxHttpClientHandler(backend: HttpBackend, interceptorsResolver: RgiRxHttpClientInterceptorResolver, adapter: RgiRxHttpErrorAdapter, stream: RgiRxHttpErrorStream): RgiRxHttpClientHandler {
  return new RgiRxHttpClientHandler(backend, interceptorsResolver.interceptors, adapter, stream);
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RgiRxHttpClient,
    {provide: RgiRxHttpClientWrapper, useExisting: RgiRxHttpClient},
    RgiRxHttpClientFactory,
    RgiRxHttpClientInterceptorResolver,
    {
      provide: RgiRxHttpClientHandler,
      useFactory: provideRgiRxHttpClientHandler,
      deps: [HttpBackend, RgiRxHttpClientInterceptorResolver, RgiRxHttpErrorAdapter, RgiRxHttpErrorStream]
    },
    {
      provide: RgiRxHttpErrorAdapter,
      useClass: RgiRxDefaultHttpErrorAdapter
    },
    {
      provide: RGI_RX_HTTP_CLIENT_INTERCEPTOR,
      useClass: GzipRequestInterceptorService,
      multi: true
    },
    {
      provide: RGI_RX_HTTP_CLIENT_INTERCEPTOR,
      useClass: HttpPendingRequestInterceptor,
      multi: true
    }
  ]
})
export class RgiRxHttpClientModule { }

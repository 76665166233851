import { Injectable } from '@angular/core';
import { Factor } from '../models/policy.model';

@Injectable({
  providedIn: 'root'
})
export class FactorService {

  factors = new Map();
  messages = new Map();

  paymentFrequency: any = null;
  agreement: any = null;
  private semaphore = false;

  constructor() { }

  setFactor(code: string, value: Factor) {
    this.factors.set(code, value);
  }

  getFactor(code: string): Factor {
    if (this.factors.has(code)) {
      return this.factors.get(code);
    } else {return null; }
  }

  deleteFactor(code: string) {
    this.factors.delete(code);
  }

  setPaymentFrequency(val: any) {
    this.paymentFrequency = val;
  }

  getPaymentFrequency(): any {
    return this.paymentFrequency;
  }

  setAgreement(val: any) {
    this.agreement = val;
  }

  getAgreement(): any {
    return this.agreement;
  }

  addMessage(code: string) {
    this.messages.set(code, '');
  }

  deleteMessage(code: string) {
    this.messages.delete(code);
  }

  isOk(): boolean {
    return this.messages.size === 0;
  }

  clean() {
    this.factors.clear();
    this.messages.clear();
  }

  cleanAll() {
    this.factors.clear();
    this.messages.clear();
    this.paymentFrequency = null;
    this.agreement = null;
  }

  getFactorsArray(): Factor[] {
    const array: Factor[] = [];
    let i = 0;

    this.factors.forEach((value: Factor, key: string) => {
      array[i] = value;
      i++;
    });

    return array;
  }

  setSemaphore(semaphore: boolean): void {
    this.semaphore = semaphore;
  }

  getSemaphore(): boolean {
    return this.semaphore;
  }
}

import {NgModule} from '@angular/core';
import {AuthorizationsCardComponent} from './authorizations-card/authorizations-card.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import {PortalNgCommonModule} from '@rgi/portal-ng-common';
import {CommonModule, DecimalPipe, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StartComponent} from './start-card/start.component';
import {AuthorizationsCardHostDirective} from './authorizations-card-host.directive';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthorizationsListComponent} from './authorizations-list/authorizations-list.component';
import {
    AuthModalDialogCancelComponent,
    AuthorizationsDetailComponent
} from './authorizations-detail/authorizations-detail.component';
import {AuthMessagesComponent} from './authorizations-detail/auth-messages/auth-messages.component';
import {AuthHistoryComponent} from './authorizations-detail/auth-history/auth-history.component';
import {
    AuthModalDialogConfirmComponent,
    AuthRequestComponent
} from './authorizations-detail/auth-request/auth-request.component';
import {SearchPipe} from './authorizations-list/search.pipe';
import {AuthorizationsListDetailComponent} from './authorizations-list/authorizations-list-detail/authorizations-list-detail.component';
import { NodeContainerComponent } from './components/node-container/node-container.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import {AUTH_TRANSLATIONS} from './i18n/translations';
import {
    RgiRxDatePickerModule,
    RgiRxDragDropModule,
    RgiRxExpansionModule,
    RgiRxFormElementsModule, RgiRxModalModule,
    RgiRxPaginatorModule,
    RgiRxPanelModule,
    RgiRxTableModule
} from '@rgi/rx/ui';
import {RgiRxRoutingModule} from "@rgi/rx/router";
import {AUTHORIZATION_CARD_ROUTES} from "./routing/authorization-card.routes";
import {RgiRxHttpModule} from "@rgi/rx/http";
import {HttpClientModule} from "@angular/common/http";
import { AuthMessagesDetailComponent } from './authorizations-detail/auth-messages/auth-messages-detail/auth-messages-detail.component';
import {
    AuthHistoryDetailComponent
} from "./authorizations-detail/auth-history/auth-history-detail/auth-history-detail.component";
import { AuthorizationErrorMessageComponent } from './components/authorization-error-message/authorization-error-message.component';
import {RgiAnagModule} from "@rgi/anag";
import {AuthorizationsCardIntegrationService} from "./services/authorizations-card-integration.service";
import {RgiRxQualityAssuranceModule} from "@rgi/rx";
import { AuthorizationsListTableComponent } from './authorizations-list-table/authorizations-list-table.component';


@NgModule({
    declarations: [
        AuthorizationsCardComponent,
        StartComponent,
        AuthorizationsCardHostDirective,
        AuthorizationsListComponent,
        AuthorizationsDetailComponent,
        AuthMessagesComponent,
        AuthHistoryComponent,
        AuthRequestComponent,
        SearchPipe,
        AuthorizationsListDetailComponent,
        NodeContainerComponent,
        AuthHistoryDetailComponent,
        AuthMessagesDetailComponent,
        AuthModalDialogConfirmComponent,
        AuthModalDialogCancelComponent,
        AuthorizationErrorMessageComponent,
        AuthorizationsListTableComponent
    ],
    imports: [
        HttpClientModule,
        CommonModule,
        PortalNgCoreModule,
        PortalNgCommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(AUTH_TRANSLATIONS),
        RgiRxRoutingModule.forRoot(AUTHORIZATION_CARD_ROUTES),
        RgiRxPaginatorModule,
        RgiRxTableModule,
        RgiRxRoutingModule,
        RgiRxHttpModule,
        RgiRxFormElementsModule,
        RgiRxExpansionModule,
        RgiRxPanelModule,
        RgiRxModalModule,
        RgiRxDragDropModule,
        RgiAnagModule,
        RgiRxQualityAssuranceModule,
        RgiRxDatePickerModule,
    ],
    exports: [
        AuthorizationsCardComponent,
        StartComponent,
        AuthorizationsCardHostDirective,
        AuthorizationsDetailComponent,
        AuthMessagesComponent,
        AuthHistoryComponent,
        AuthRequestComponent,
        AuthorizationsListDetailComponent,
        NodeContainerComponent,
        SearchPipe
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        { provide: 'showDocuments', useValue: false },
        { provide: 'authorizationsStartComponent', useValue: StartComponent },
        { provide: 'authorizationsListComponent', useValue: AuthorizationsListComponent },
        { provide: 'authorizationsDetailComponent', useValue: AuthorizationsDetailComponent },
        AuthorizationsCardIntegrationService,
    ]
})
export class AuthorizationsCardModule {
}

import { Injectable } from '@angular/core';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


export abstract class LpcCurrencyCache {

  abstract set context(con: string);
  abstract get context(): string;

  abstract set currency(curr: string);
  abstract get currency(): string;

  abstract get locale(): string;

  abstract set locale(context: string);

  constructor(
    protected rxTranslationsService: RgiRxTranslationService) {}
}

@Injectable()
export class CurrencyCacheService extends LpcCurrencyCache {

  private _context = '';
  private _currency = 'EUR';
  private _locale = '';

  set context(con: string) {
    this._context = con;
  }
  get context(): string {
    return this._context;
  }

  set currency(curr: string) {
    this._currency = curr;
  }
  get currency(): string {
    return this._currency;
  }

  set locale(context: string) {
    this._locale = this.rxTranslationsService.currentLanguage + '-' + context;
  }
  
  get locale(): string {
    if(!this._locale){
      return this.rxTranslationsService.currentLanguage + '-' + (!!this.context ? this.context : this.rxTranslationsService.currentLanguage.toUpperCase());
    }
    return this._locale;
  }

constructor(protected rxTranslationsService: RgiRxTranslationService) {
    super(rxTranslationsService);
  }

}
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';
import {RgiRxValueOptionDirective} from '../rgi-rx-value-option.directive';
import {ENTER} from '@angular/cdk/keycodes';

let optionCounter = 0;

@Component({
  selector: 'rgi-rx-option',
  templateUrl: './rgi-rx-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: [
    'disabled',
    'hidden',
    'active',
    'label'
  ]
})
export class RgiRxOptionComponent<T> extends RgiRxValueOptionDirective<T> {
  id = `rgi-rx-option-${optionCounter++}`;
  constructor(private host: ElementRef, private changeDetectorRef: ChangeDetectorRef, _renderer: Renderer2) {
    super(_renderer, host, changeDetectorRef, {
      role: 'option',
      useAriaPressed: false
    });
  }

  setActiveStyles(): void {
    super.setActiveStyles();
    this.changeDetectorRef.markForCheck();
  }

  setInactiveStyles(): void {
    super.setInactiveStyles();
    this.changeDetectorRef.markForCheck();
  }
}

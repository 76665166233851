import {Component, HostBinding, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {TemplatePortal} from '@angular/cdk/portal';
import {BehaviorSubject} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {RgiRxTabDirective} from '../rgi-rx-tab.directive';

@Component({
  selector: 'rgi-rx-tab-body',
  templateUrl: './rgi-rx-tab-body.component.html',
  host: {
    class: 'rgi-ui-tab-content',
    role: 'tabpanel'
  }
})
export class RgiRxTabBodyComponent implements OnInit, OnDestroy {

  @Input() tab: RgiRxTabDirective;
  @Input() position: number;

  activeChange = new BehaviorSubject<boolean>(false);

  @Input() @HostBinding('class.rgi-ui-active') get active(): boolean {
    return this.activeChange.getValue();
  }
  @Input() @HostBinding('class.rgi-ui-hidden') get hidden(): boolean {
    return !this.activeChange.getValue();
  }


  set active(value: boolean) {
    this.activeChange.next(value);
  }

  portal: TemplatePortal;

  constructor(private vc: ViewContainerRef) {
  }

  ngOnInit() {
    this.activeChange
      .pipe(
        filter(active => !!active && !this.portal && !!(this.tab && this.tab.content)),
        tap(() => {
          this.portal = new TemplatePortal(this.tab.content.template, this.vc);
        })
      )
      .subscribe();
  }


  ngOnDestroy(): void {
    this.activeChange.unsubscribe();
  }

}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { ContractConsultationIntegrationService } from '@rgi/contract-consultation-card';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { ActiveRoute, RgiRxRouteData, RoutingService } from '@rgi/rx/router';
import {CONTRACT_CONSULTATION_CARD} from '../contract-consultation-card';


@Injectable()
export class ContractConsultationPortalIntegrationService extends ContractConsultationIntegrationService {

  constructor(protected _routingService: RoutingService,
    protected translationService: RgiRxTranslationService,
    @Inject('sessionService') private portalSession: any,
    @Inject('coreResult') private portalCoreResult: any,
    @Inject('parallelRouting') private portalParallelRouting: any) {
    super(_routingService, translationService);
  }

  override backToHome(activeRoute: ActiveRoute) {
    const settlementSession = this.portalSession.list().find((session: any) => session.idSession === activeRoute.id);
    this.portalSession.remove(settlementSession.idSession);
  }

  override navigate(routingService: RoutingService, destinationRoute: string, routeData: RgiRxRouteData<any>, activeRoute: ActiveRoute) {
    if (activeRoute && 'consultation-start-card' === activeRoute.route) {
      super.navigate(routingService, destinationRoute, routeData, activeRoute);
    } else {
      const portalRoute = CONTRACT_CONSULTATION_CARD.routes.find(route => route.destination === destinationRoute)?.route;
      const currentSession = this.portalSession.list().find((session: any) => session.idSession === activeRoute.id);
      this.portalCoreResult.setResult(currentSession.id, portalRoute, routeData);
      this.portalParallelRouting.go(currentSession.id, portalRoute);
    }
  }

  override changeTitle(id: string, title: string) {
    const currentSession = this.portalSession.list().find((session: any) => session.idSession === id);
    if (currentSession) {
      currentSession.title = title;
    }
  }

  /*
* @param {string} id current session
* @param {string} idParentSession id of the parent  origin session
*  */
  protected setActiveSession(id: string, idParentSession: string) {
    this.portalSession.setActive(idParentSession);
    this.portalSession.remove(id);
  }

  override backToPrevPage(id: string, idParentSession: string) {
    if (idParentSession) {
      this.setActiveSession(id, idParentSession);
    } else {
      const session = this.portalSession.list().find((session: any) => session.idSession === id);
      if (session) {
        try {
          this.portalParallelRouting.go(session.id, 'result'); // da capire result
        } catch (error) {
          this.portalSession.remove(id);
        }
      }
    }
  }

  override openSubjectDetail(subject: any, idSession: string) {
    const subjIdSession = this.portalSession.open('anagCardZoom', 'home', '', true);
    // this.portalCoreResult.setResult(subjIdSession, 'detail-life-subj', subject);
    // this.portalCoreResult.setResult(subjIdSession, 'detail-life-idSession', idSession);
  }
}

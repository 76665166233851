<form [formGroup]="formGroup">

  <div [ngSwitch]="this.commission.commissionType">
    <input *ngSwitchCase="PNC_POSTSALES_COMMISSION_TYPE.RISK" type="number" step=".01" rgiRxInput class="rgi-ui-comm-input"
           formControlName="{{controlName}}"
           attr.data-qa="{{controlName}}"
           (change)="setTwoNumberDecimal($event)"
           value="{{inputValue}}">
    <div *ngSwitchCase="PNC_POSTSALES_COMMISSION_TYPE.ADM_FEES" class="rgi-ui-provv-cell" attr.data-qa="{{controlName}}">
      {{this.commission.subscriptionInstalment[this.field] | number : '1.2-2'}}
    </div>
    <div *ngSwitchDefault class="rgi-ui-provv-cell" attr.data-qa="{{controlName}}">
      {{this.commission.subscriptionInstalment[this.field]}}
    </div>
  </div>
</form>

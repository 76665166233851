import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {RgiRxHttpError} from '@rgi/rx/http';
import {PncPsalesBaseState} from '../resources/states/pnc-postsales-state';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {RgiRxSnackbarOverlay} from '@rgi/rx/ui';

import {Violation} from '../resources/model/api/violation';

@Injectable({
  providedIn: 'root'
})
export class PncPsalesHttpErrorService {

  constructor(
    protected pushMessageHandler: PushMessageHandlerService,
    protected snackBarOverlay: RgiRxSnackbarOverlay
  ) {
  }

  public catchApiErrorFn(st: PncPsalesBaseState, tag: string): (err: RgiRxHttpError) => Observable<never> {
    return (err: RgiRxHttpError) => {
      if (err.status < 500) {
        if (err.errorBody && err.errorBody.violations && Array.isArray(err.errorBody.violations)) {
          this.manageViolationsArray(err.errorBody.violations, tag);
        } else {
          const error = err.errorBody && err.errorBody.description ? err.errorBody.description :
            'error found but the error structure is not implemented in FE\'s error management';
          this.pushMessageHandler.notify(new RgiRxPushMessage(error, {
              tag,
              status: 'danger',
              dismissible: true
            }
          ));
        }
        return throwError(st);
      } else {
        this.snackBarOverlay.notify(new RgiRxPushMessage('_PCPSALES_._MSG_._UNEXPECTED_ERROR_', {
          status: 'danger',
          dismissible: true
        }));
        return throwError(err);
      }
    };
  }

  public manageStreamErrFn(): (err: PncPsalesBaseState | RgiRxHttpError) => Observable<PncPsalesBaseState | never> {
    return (err: PncPsalesBaseState | RgiRxHttpError) => {
      if (err instanceof RgiRxHttpError) {
        throw Error('Unhandled Error');
      } else {
        return of(err);
      }
    };
  }

  manageViolationsArray(violations: Violation[], tag: string) {
    violations.forEach(error => {
      let level;
      switch (error.level) {
        case 'BLOCKING':
          level = 'danger';
          break;
        default:
          level = 'warning';
          break;
      }
      this.pushMessageHandler.notify(new RgiRxPushMessage(error.message, {
          tag,
          status: level,
          dismissible: true
        }
      ));
    });
  }
}

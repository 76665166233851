import {Inject, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  ANAG_CARDS,
  angularGridInstance,
  manageNewAnagCardInPassPortal,
  manageOverrideAnagCard
} from './rgi-anag.card.configurations';
import {PortalConfig, RGI_RX_PORTAL_CONFIG, RgiRxPortalModule} from '@rgi/rx/portal';
import {AnagIntegrationService, RgiAnagModule, AnagModalService} from '@rgi/anag';
import {ModalService} from '@rgi/rx/ui';
import {AnagPortalIntegrationService} from './service/anag-portal-integration.service';
import {downgradeAnagService} from './ajs-downgrade';
import {RgiRxEventService} from '@rgi/rx';
import {RgiRxI18nModule} from "@rgi/rx/i18n";

export function gettextCatalogFactory(injector) {
  return injector.get('gettextCatalog');
}

export const gettextCatalog = {
  provide: 'gettextCatalog',
  useFactory: gettextCatalogFactory,
  deps: ['$injector']
};

export function coreLangFactory(injector) {
  return injector.get('coreLang');
}

export const coreLang = {
  provide: 'coreLang',
  useFactory: coreLangFactory,
  deps: ['$injector']
};

const _REQUIRED_AJS_TRANSLATOR_PROVIDERS_ = [gettextCatalog, coreLang];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RgiAnagModule,
    RgiRxI18nModule,
    RgiRxPortalModule.forRoot(ANAG_CARDS)
  ],
  providers: [
    {provide: AnagIntegrationService, useClass: AnagPortalIntegrationService},
    angularGridInstance,
    _REQUIRED_AJS_TRANSLATOR_PROVIDERS_,
    {provide: 'RGI_ANAG_PORTAL_AJS_MODAL_OVERRIDE', useValue: false},
    {provide: 'RGI_ANAG_PORTAL_AJS_CARD_OVERRIDE', useValue: false},
  ]
})
export class RgiAnagPortalModule {
  constructor(protected anagModalService: AnagModalService,
              protected eventService: RgiRxEventService,
              @Inject(RGI_RX_PORTAL_CONFIG) config: PortalConfig,
              @Inject('RGI_ANAG_PORTAL_AJS_MODAL_OVERRIDE') portalAjsModalOverride: boolean,
              @Inject('RGI_ANAG_PORTAL_AJS_CARD_OVERRIDE') portalAjsCardOverride: boolean) {
    downgradeAnagService(config);
    if (portalAjsModalOverride) {
      manageNewAnagCardInPassPortal(anagModalService, eventService, config);
    }
    if (portalAjsCardOverride) {
      manageOverrideAnagCard(config);
    }
  }
}





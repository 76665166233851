import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit,
  Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DamageRisk, DamagesInstalments } from '../../../models/damage.model';
import { Installment, PolicyPremium } from '../../../models/policy.model';
import { PolicyService } from '../../../services/policy-service';


@Component({
  selector: 'lic-quotation-controls',
  templateUrl: './quotation-controls.component.html',
  styleUrls: ['./quotation-controls.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuotationControlsComponent implements OnInit, OnDestroy {
  public locale = 'it-IT';
  public optionCurrency: Intl.NumberFormatOptions = { style: 'currency' };

  @ViewChild('premiumElement') premiumElement: ElementRef<HTMLDivElement>;

  @Input() annualPremiumValue: PolicyPremium;
  @Input() premiumInstallment: Installment;
  @Input() damagesInstalment: DamagesInstalments[];
  @Input() damageRisk: DamageRisk[];
  @Input() disableFormule: boolean;
  @Input() disableProv: boolean;
  @Input() recalculateAvailable: boolean;
  @Input() operator;

  @Output() showCommonRisk: EventEmitter<any> = new EventEmitter<any>();
  @Output() recalculate = new EventEmitter<any>();
  @Output() detail = new EventEmitter<any>();
  @Output() garDetail = new EventEmitter<any>();
  @Output() unitsViewMode: EventEmitter<string> = new EventEmitter<string>();
  @Output() formule: EventEmitter<string> = new EventEmitter<string>();
  @Output() prov: EventEmitter<string> = new EventEmitter<string>();

  public isPremiumElementFixedInPage = false;
  public unit = true;
  public commission = true;
  public components = true;
  public showViewBoxesMenu = true;
  public disabledCommonRisk = false;

  protected $subscriptions: Subscription[] = [];

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.isPremiumElementFixedInPage = !(!!this.premiumElement && window.pageYOffset < this.premiumElement.nativeElement.offsetTop);
  }

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
    this.optionCurrency.currency = policyService.currencyCode;
  }

  ngOnInit(): void {
    const isEnabledOperator = this.operator && this.operator.enabledFeatures.length > 0 &&
      this.operator.enabledFeatures.find(feature => feature === '_VCRIC');
    /* if (isEnabledOperator) {
        this.disabledCommonRisk = true;
    } */
    this.$subscriptions.push(
      this.showcommonRisk().subscribe(propVal => {
        this.disabledCommonRisk = isEnabledOperator && propVal && this.showcommonRiskbyProduct;
      })
    );
  }

  get showcommonRiskbyProduct(): boolean {
    const commonrisk = this.policyService.mainProposal.quote.product.commonRisk;
    return Number(commonrisk) > 1;
  }

  showcommonRisk(): Observable<boolean> {
    return this.policyService.isVisibleCommonRisk().pipe(distinctUntilChanged());
  }

  public openCommonRisk(event) {
    this.showCommonRisk.emit(event);
  }

  public recalculatePremium(event) {
    this.recalculate.emit(event);
  }

  public viewBoxUnits() {
    this.unitsViewMode.emit('BOX');
    this.showViewBoxesMenu = false;
  }

  public viewListUnits() {
    this.unitsViewMode.emit('LIST');
    this.showViewBoxesMenu = true;
  }

  public openDetail(event) {
    this.detail.emit(event);
  }

  public openGarDetail(event) {
    this.garDetail.emit(event);
  }

  public openFormule(event) {
    this.formule.emit(event);
  }

  public openProv(event) {
    this.prov.emit(event);
  }

  public isQuotationDone(): boolean {
    return this.policyService.isQuotationDone();
  }

  public getPremiumInstalmentGross(): string {
    if (this.damagesInstalment == null || this.damagesInstalment.length === 0) {
      return this.premiumInstallment ? this.premiumInstallment.gross : null;
    } else {
      // ritorno la somma
      const gross = +this.damagesInstalment[0].dueAmount.premium.gross;
      return this.premiumInstallment ? (+this.premiumInstallment.gross + +gross).toString() : null; // TODO
    }
  }

  public getAnnualPremiumGross(): string {
    if (this.damagesInstalment == null || this.damagesInstalment.length === 0) {
      return this.annualPremiumValue ? this.annualPremiumValue.gross : null;
    } else {
      // ritorno la somma
      let gross = 0;
      this.damageRisk.map((risk) => {
        gross += +risk.annualPremium.gross;
      });
      return this.annualPremiumValue ? (+this.annualPremiumValue.gross + +gross).toString() : null; // TODO
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => s.unsubscribe());
  }


}

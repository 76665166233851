<template #documentModalContainerRef></template>
<div *ngIf="areThereDocuments">

  <pnc-accordion #accordion [activeIds]="activePanelLists">

    <pnc-panel class='panel-item' id="doc-panel">

      <ng-template let-isOpen="isOpen" class='panel-title' pncPanelTitle>
        <span class="rgifont" [class.rgi-plus]="!isOpen" [class.rgi-minus]="isOpen"></span>
        <span class="header-text white" translate>Print documents</span>
      </ng-template>

      <ng-template class="panel-container" pncPanelContent>
        <div *ngFor="let doc of documentList">
          <div class="document-container">
            <div class="row">
              <div class="col-md-11 col-sm-11">
                <span class="icon rgi-documenti_causali"></span>
                <b class="desc-container">{{doc.nomeFile}}</b>
              </div>
              <div class="col-md-1 col-sm-1">
                <div class="button-container">
                  <button type="button" class="btn btn-download"
                      (click)="downloadDocument($event, doc)" [disabled]="false">
                      <span class="icon rgi-download"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </pnc-panel>

  </pnc-accordion>
</div>

<ng-container *ngIf="!!state" [formGroup]="pageForm">
  <rgi-pnc-quotation-header [data]="state.quotationBarData" (onButtonAction)="onQuotationBarBtnAction($event)">
  </rgi-pnc-quotation-header>
  <div class="rgipc-mb-l">
    <rgi-pnc-psales-supplementary-tech-acc-table #insuredDT
                                                 [rows]="stateMgr.insuredRisksTableData"
                                                 [schema]="stateMgr.getInsuredRisksTableSchema()"
                                                 [parentFormGroup]="getFG(stateMgr.fgName)">
    </rgi-pnc-psales-supplementary-tech-acc-table>
  </div>
  <div class="rgipc-mb-l">

    <rgi-pnc-psales-supplementary-tech-acc-table #notInsuredDT
                                                 [rows]="stateMgr.notInsuredRisksTableData"
                                                 [schema]="stateMgr.getNotInsuredRisksTableSchema()"
                                                 [parentFormGroup]="getFG(stateMgr.fgName)">
    </rgi-pnc-psales-supplementary-tech-acc-table>
  </div>
</ng-container>


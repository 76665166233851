export const anagClaimReport = `<div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-anag-claim-report
        [id]="card.id"
        (event-propagation)="wrapEvent($event)"
        [selected-subject]="selectedSubject.received.data">
    </lpc-anag-claim-report>
</div>`;


export const bookingDetail = `
<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-booking-detail
        (event-propagation)="wrapEvent($event)" [id]="card.id" [data]="data">
    </lpc-life-booking-detail>
</div>`;


export const claimDetail = `
<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-claim-detail
        (event-propagation)="wrapEvent($event)"
        [id]="claim.id"
        [data]="data">
    </lpc-claim-detail>
</div>`;


export const claimList = `
<div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-claim-list
      (event-propagation)="wrapEvent($event)" >
    </lpc-claim-list>
</div>`;


export const financialSearchCard = `
<div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-financial-search-card
      (event-propagation)="wrapEvent($event)">
    </lpc-financial-search-card>
</div>`;


export const investStandaloneCard = `<div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlPostsales">
    <lic-invest-standalone
        (event-propagation)="wrapEvent($event)">
    </lic-invest-standalone>
</div>`;


export const investStandaloneSession = `<div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlPostsales">
    <lic-invest-standalone-session
        (event-propagation)="wrapEvent($event)">
    </lic-invest-standalone-session>
</div>`;


export const lifeDetailCard = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data"
        [idParentSession]="card.idSession">
    </lpc-life-detail>
</div>`;


export const lifeExpiryOptionDetail = `<div class="ng-card-wrapper" id="life-expiry-option-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-expiry-option-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-expiry-option-detail>
</div>`;


export const lifeFinancialDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-financial-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-life-financial-detail>
</div>`;


export const lifeFiscalDataDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-fiscal-data-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-fiscal-data-detail>
</div>`;


export const lifeFundsList = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-funds-list
        (event-propagation)="wrapEvent($event)"
        [data]="data">
    </lpc-funds-list>
</div>`;


export const lifeLoanDataDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-loan-data-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-loan-data-detail>
</div>`;


export const lifeMovementDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-movement-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-life-movement-detail>
</div>`;


export const lifeOptionDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-option-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-life-option-detail>
</div>`;


export const lifePipDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-pip-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-life-pip-detail>
</div>`;


export const lifePostSalesCard = `<div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-postsales-card
        data-qa="mic-entry-{{ card.id }}"
        [selected-subject]="selectedSubject.received.data"
        (event-propagation)="wrapEvent($event)">
    </lpc-life-postsales-card>
</div>`;


export const lifePostSalesSession = `<div class="ng-card-wrapper" id="ng-card-wrapper-{{ card.id }}" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-postsales-session
        data-qa="mic-entry-{{ card.id }}"
        [selected-subject]="selectedSubject.received.data"
        [opened-anag-session]="selectedSubject.openModifySession"
        (event-propagation)="wrapEvent($event)"
        [data]="{data, idParentSession: card.idSession}">
    </lpc-life-postsales-session>
</div>`;


export const lifeReinvestmentDataDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-reinvestment-data-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-reinvestment-data-detail>
</div>`;


export const lifeTrendDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-trend-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-life-trend-detail>
</div>`;


export const newClaim = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-claim-insert
        (event-propagation)="wrapEvent($event)"
        [id]="claim.id"
        [data]="data">
    </lpc-claim-insert>
</div>`;


export const profileAllocation = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-profile-allocation
        (event-propagation)="wrapEvent($event)" [id]="card.id" [data]="data">
    </lpc-profile-allocation>
</div>`;


export const profileDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-profile-detail
        (event-propagation)="wrapEvent($event)" [id]="card.id" [data]="data">
    </lpc-profile-detail>
</div>`;

export const premiumDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-premium-detail
        (event-propagation)="wrapEvent($event)" [id]="card.id" [instalments]="data.premiumDetailData">
    </lpc-premium-detail>
</div>`;

export const lifeInvestmentPlanDetail = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsales">
    <lpc-life-investment-plan-detail
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </lpc-life-investment-plan-detail>
</div>`;

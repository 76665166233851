import {AdapterprintTranslationsKeys} from './adapterprint-translations-keys';
export const ADAPTERPRINT_IT: AdapterprintTranslationsKeys = {
  _START_ADAPTERPRINT_: 'Esiti postalizzazione',
  _RESULT_ADAPTERPRINT_: 'Elenco esiti',
  _ADAPTERPRINT_: {
    _BTN_: {
      _BACK_: 'Indietro',
      _EMPTY_: 'Svuota',
      _FIND_: 'Trova',
      _UNDO_: 'Annulla'
    },
    _LABEL_: {
      _ACTION_: 'Azione',
      _ADDRESS_: 'Indirizzo',
      _LOT_: 'Lotto',
      _CODE_: 'Codice',
      _DATE_SENT_: 'Data invio',
      _DATE_TO_: 'Data al',
      _DESCRIPTION_: 'Descrizione',
      _DOCUMENT_TYPE_: 'Tipo documento',
      _MANAGEMENT_NODE_: 'Nodo di gestione',
      _NODE_: 'Nodo',
      _NODE_CODE_: 'Codice Nodo',
      _NODE_DESCRIPTION_: 'Descrizione Nodo',
      _NODE_LIST_: 'Lista nodi',
      _POLICY_NUMBER_: 'Numero polizza',
      _ADDRESSED_: 'Destinatario',
      _START_DATE_: 'Data dal',
      _STATUS_: 'Stato',
      _POLICY_: 'Polizza',
      _TEMPLATE_: 'Template',
      _OUTCOME_: 'Esito',
      _USER_: 'Utente'
    },
    _MSG_: {
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'I campi segnati in rosso sono obbligatori o contengono valori non corretti',
      _NO_RESULTS_: 'Nessun Risultato',
      _NOT_SENT_: 'Non inviato',
      _SENT_OK_: 'Inviato OK',
      _SENT_KO_: 'Inviato KO',
    }
  },
};


<div class="rgi-re-section-title">
  <span class="rgi-ui-icon-clipboard-list rgi-re-header-icon"></span>
  <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.POLICY_DATA</span>&nbsp;
</div>

<form [formGroup]="proposalsForm">
  <div class="rgi-ui-grid-2">
    <div class="rgi-ui-col">
      <rgi-rx-form-field>

        <div class="label-input-container">
          <label rgiRxLabel for="effectiveDate" translate>RE_ISSUE.POLICY_EFFECT_DATE</label>
          <div id="effectiveDate" class="input-button-container">
            <input [rgiRxDateTimeInput]="effectiveDatePicker" style="flex: 1" data-qa="effectiveDate"
                   formControlName="effectiveDate" selectMode="single" rangeSeparator="-" placeholder="gg-mm-yyyy"
                   #inputToTriggerEffectiveDate="rgiRxDateTimeInput" [max]="" [min]="">
            <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerEffectiveDate"></button>
          </div>
        </div>

        <rgi-rx-date-time #effectiveDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <div class="label-input-container">
          <label for="expireDate" rgiRxLabel><span translate>RE_ISSUE.EXPIRE_DATE</span></label>
          <div id="expireDate" class="input-button-container">
            <input [rgiRxDateTimeInput]="expireDatePicker" style="flex: 1" data-qa="expireDate"
                   formControlName="expireDate" selectMode="single" rangeSeparator="-" placeholder="gg-mm-yyyy"
                   #inputToTriggerExpireDate="rgiRxDateTimeInput">
            <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerExpireDate"></button>
          </div>
        </div>
        <rgi-rx-date-time #expireDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>

    <div class="rgi-ui-col">

      <rgi-rx-form-field>
        <div class="label-input-container">
          <label rgiRxLabel for="issueDate" translate>RE_ISSUE.ISSUE_DATE</label>
          <div id="issueDate" class="input-button-container">
            <input [rgiRxDateTimeInput]="issueDatePicker" style="flex: 1" data-qa="issueDate"
                   formControlName="issueDate" selectMode="single" rangeSeparator="-"
                   placeholder="gg-mm-yyyy" #inputToTriggerIssueDate="rgiRxDateTimeInput">
            <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerIssueDate"></button>
          </div>
        </div>

        <rgi-rx-date-time #issueDatePicker pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="instalmentDate" translate>RE_ISSUE.NEXT_INSTALMENT_DATE</label>
        <select rgiRxNativeSelect id="instalmentDate" formControlName="instalmentDate" data-qa="instalmentDate">
          <option *ngFor="let instalmentDate of instalmentDatesList" [ngValue]="instalmentDate">{{instalmentDate |
            date:'dd/MM/yyyy'}}</option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="paymentFrequency" translate>RE_ISSUE.PAYMENT_FREQUENCY</label>
        <select rgiRxNativeSelect id="paymentFrequency" formControlName="paymentFrequency" data-qa="paymentFrequency">
          <option value=""></option>
          <option *ngFor="let paymentFrequency of paymentFrequencyList" [ngValue]="paymentFrequency">
            {{paymentFrequency?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field class="padding-checkbox">
        <label for="tacitRenewal" rgiRxLabel>{{ 'RE_ISSUE.TACIT_RENEWAL' | translate }}</label>
        <rgi-rx-button-toggle id="tacitRenewal" formControlName="tacitRenewal">
          <button rgi-rx-button rgiRxValueOption [value]="true">{{'RE_ISSUE.YES' | translate}}</button>
          <button rgi-rx-button rgiRxValueOption [value]="false">{{'No'}}</button>
        </rgi-rx-button-toggle>
      </rgi-rx-form-field>
    </div>


    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="agreement" translate>RE_ISSUE.AGREEMENT</label>
        <select rgiRxNativeSelect id="agreement" formControlName="agreement" data-qa="agreement">
          <option value=""></option>
          <option *ngFor="let agreement of agreements" [ngValue]="agreement">{{agreement?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="useTax" translate>RE_ISSUE.USE_TAX</label>
        <select rgiRxNativeSelect id="useTax" formControlName="useTax" data-qa="useTax">
          <option *ngFor="let useTax of useTaxes" [ngValue]="useTax">{{useTax?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div *ngIf="showCurrencies" class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="currencies" translate>RE_ISSUE.CURRENCY</label>
        <select rgiRxNativeSelect id="currencies" formControlName="currencies" data-qa="currencies">
          <option *ngFor="let currency  of currencies" [ngValue]="currency">{{currency?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col"
         *ngIf="!(this.proposalsForm.get('binder.enabled').value == false && this.proposalsForm.get('binder.selected').value== false)"
         formGroupName="binder">
      <rgi-rx-form-field style="margin-left: 10px;margin-top: 17px;">

        <span> <input id="lien" type="checkbox" rgiRxInput formControlName="selected" data-qa="taxable" class="checkbox"></span>

        <span><label for="lien" rgiRxLabel style="margin-left: 12px; margin-top: 11px;" translate>RE_ISSUE.LIEN</label></span>

      </rgi-rx-form-field>

    </div>
  </div>

  <re-issue-co-insurance [coInsurance]="coInsurance" [coInsuranceTypes]="coInsuranceTypes"
                         [companies]="coInsuranceCompanies" [intermediaries]="coInsuranceIntermediaries"
                         (coInsuranceEmitter)="emitCoInsurance($event)">
  </re-issue-co-insurance>

  <div *ngIf="showIndexation" class="form-group rgi-ui-grid-2">
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel for="indexation" translate>RE_ISSUE.INDEXATION</label>
        <select rgiRxNativeSelect id="indexation" formControlName="indexation" data-qa="indexation">
          <option [ngValue]="emptyIndexType"></option>
          <option *ngFor="let indexType  of indexTypes" [ngValue]="indexType">{{indexType?.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
  </div>
</form>

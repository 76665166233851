<div class = "select-header">
  <label class = "label" *ngIf="label && label.length > 0" translate>{{ label }}</label>
  <pnc-required [required]="required"> </pnc-required>
</div>
<ng-select
  [items]="items"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [(ngModel)]="selectedValue"
  [searchable]="false"
  [clearable]="clearable"
  (change)="onChangeAction($event)"
  class="custom"
  [disabled] = "disable"
  [trackByFn] = "getCustomTrackByFn()"
  [compareWith] = "getCustomCompareWith()"
>

</ng-select>
import { Inject, Injectable } from '@angular/core';
import { DamagedAsset, Party, Policy, SelectableCovertureResponse } from '@rgi/digital-claims-common-angular';
import { TimeRequest } from './components/claim-opening-policy-search/dto/time-request';
import { DamageCategory } from './components/claim-opening-policy-search/dto/damage-categories';
import { ExternalPolicy } from '../external-portfolio/dto/external-policy';
import { HttpClient } from '@angular/common/http';
import { IRequestShortcutConfig } from 'angular';
import { UntypedFormGroup } from '@angular/forms';
import { ComboEntity } from '../new-payment/domain/combo-entity';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimOpeningService {

  // requests
  private baseApiUrl: string;
  private urlservice = '/claims';


  private policies: Policy[];
  private sessionPolicies: Policy[][];
  private sessionTimeRequests: TimeRequest[];
  private sessionOccurrenceDates: Date[];
  private sessionPlateNumber: string[];
  private sessionCategory: DamageCategory[];
  private sessionSelectedPolicy: Policy[];
  private sessionUuid: string[];
  private sessionSelectableCoverturesResponse: SelectableCovertureResponse[];
  private sessionAdministrativeCoverture: boolean[];
  private sessionEventSet: boolean[];
  private externalPortfolio: boolean = false;
  private listExternalPolicyFound: Array<ExternalPolicy>;
  private typePtf: string;
  private externalPolicyCurrent: ExternalPolicy;
  private subjectType: string;
  private policyDetail: any;
  private compliantDate: Date;
  private existClMadePostuma: boolean = false;
  private visualizedMsgPostuma: boolean = false;
  private docSelected: any;
  private damagedAssetsInjury: DamagedAsset[];
  private damagedAssetsVehicle: DamagedAsset[];
  private damagedAssetsMaterialDamage: DamagedAsset[];
  private damagedAssetsWithoutDuplication: DamagedAsset[];
  private nextId: any;
  private typesOfLoss: string;
  private party: Party;
  private card: any;
  private addAssetForm: UntypedFormGroup;
  private injuryCauseList: any[];
  private injuryNatureList: Map<string, any[]>;
  private injuryLocationList: Map<string, any[]>;
  private professionList: any[];
  private policyModifyData: any[];
  private sessionInsuredActivities: ComboEntity[];
  private enableMultiDamage: boolean;
  private garctp: string;


  constructor(
    @Inject('environment') environment: any,
    private httpClient: HttpClient

  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';

  }

  inzializeExternaPortfolio() {
    this.externalPortfolio = false;
  }




  getCompliantDate() {
    return this.compliantDate;
  }

  setCompliantDate(date: Date) {
    this.compliantDate = date;
  }

  setInjuryCauseList(injuryCauseList: any[]) {
    this.injuryCauseList = injuryCauseList;
  }

  getInjuryCauseList() {
    return this.injuryCauseList;
  }

  setInjuryNatureList(injuryNatureList: Map<string, any[]>) {
    this.injuryNatureList = injuryNatureList;
  }

  getInjuryNatureList() {
    return this.injuryNatureList;
  }

  setProfessionList(professionList: any[]) {
    this.professionList = professionList;
  }

  getProfessionList() {
    return this.professionList;
  }

  setInjuryLocationList(injuryLocationList: Map<string, any[]>) {
    this.injuryLocationList = injuryLocationList;
  }

  getInjuryLocationList() {
    return this.injuryLocationList;
  }

  setAddAssetForm(addAssetForm: UntypedFormGroup) {
    this.addAssetForm = addAssetForm;
  }

  getAddAssetForm() {
    const temp = this.addAssetForm;
    this.addAssetForm = null;
    return temp;
  }

  setNextId(nextId: any) {
    this.nextId = nextId;
  }

  getNextId() {
    return this.nextId;
  }

  setTypesOfLoss(typesOfLoss: string) {
    this.typesOfLoss = typesOfLoss;
  }

  getTypesOfLoss() {
    return this.typesOfLoss;
  }

  setParty(party: Party) {
    this.party = party;
  }

  getParty() {
    return this.party;
  }

  setCard(card: any) {
    this.card = card;
  }

  getCard() {
    return this.card;
  }

  setDocSelected(doc: any) {
    this.docSelected = doc;
  }

  getDocSelected() {
    return this.docSelected;
  }

  setDamagedAssetsMaterialDamage(damagedAssetsMaterialDamage: DamagedAsset[]) {
    this.damagedAssetsMaterialDamage = damagedAssetsMaterialDamage;
  }

  getDamagedAssetsMaterialDamage() {
    return this.damagedAssetsMaterialDamage;
  }

  setDamagedAssetsInjury(damagedAssetsInjury: DamagedAsset[]) {
    this.damagedAssetsInjury = damagedAssetsInjury;
  }

  getDamagedAssetsInjury() {
    return this.damagedAssetsInjury;
  }

  getDamagedAssetsVehicle() {
    return this.damagedAssetsVehicle;
  }

  setDamagedAssetsVehicle(damagedAssetsVehicle: DamagedAsset[]) {
    this.damagedAssetsVehicle = damagedAssetsVehicle;
  }

  setDamagedAssetsWithoutDuplication(damagedAssetsWithoutDuplication: DamagedAsset[]) {
    this.damagedAssetsWithoutDuplication = damagedAssetsWithoutDuplication;
  }

  getDamagedAssetsWithoutDuplication() {
    return this.damagedAssetsWithoutDuplication;
  }

  setExistClMadePostuma(existClMadePostuma: boolean) {
    this.existClMadePostuma = existClMadePostuma;
  }

  getExistClMadePostuma() {
    return this.existClMadePostuma;
  }

  setVisualizedMsgPostuma(visualizedMsgPostuma: boolean) {
    this.visualizedMsgPostuma = visualizedMsgPostuma;
  }

  getVisualizedMsgPostuma() {
    return this.visualizedMsgPostuma;
  }

  setPolicyDetail(contract: any) {
    this.policyDetail = contract;
  }

  getPolicyDetail() {
    return this.policyDetail;
  }

  setExternaPortfolio(ext: boolean, listExternalPolicyFound: Array<ExternalPolicy>, typePtf: string) {
    this.externalPortfolio = ext;
    this.listExternalPolicyFound = listExternalPolicyFound;
    this.typePtf = typePtf;
  }

  setSubjectType(subjectType: string) {
    this.subjectType = subjectType;
  }

  getSubjectType() {
    const temp = this.subjectType;
    this.subjectType = '';
    return temp;
  }

  setExternalPolicyCurrent(extPol: ExternalPolicy) {
    this.externalPolicyCurrent = extPol;
  }

  getExternalPolicyCurrent() {
    return this.externalPolicyCurrent;
  }

  getExternaPortfolio() {
    return this.externalPortfolio;
  }

  getTypePtf() {
    return this.typePtf;
  }

  getListaExternalPolicy() {
    return this.listExternalPolicyFound;
  }

  setSessionOccurrenceDate(idSession: string, date: Date) {
    if (!this.sessionOccurrenceDates) {
      this.sessionOccurrenceDates = [];
    }
    this.sessionOccurrenceDates[idSession] = date;
  }

  getSessionOccurrenceDate(idSession: string): Date {
    if (this.sessionOccurrenceDates) {
      return this.sessionOccurrenceDates[idSession];
    }
  }

  setSessionPlate(idSession: string, plate: string) {
    if (!this.sessionPlateNumber) {
      this.sessionPlateNumber = [];
    }
    this.sessionPlateNumber[idSession] = plate;
  }

  getPlate(idSession: string) {
    if (this.sessionPlateNumber[idSession]) {
      return this.sessionPlateNumber;
    }
  }

  setCategory(idSession: string, category: DamageCategory) {
    if (!this.sessionCategory) {
      this.sessionCategory = [];
    }
    this.sessionCategory[idSession] = category;
  }

  getCategory(idSession: string): DamageCategory {
    if (this.sessionCategory) {
      return this.sessionCategory[idSession];
    }
  }

  setPolicies(policies: Policy[]) {
    this.policies = policies;
  }

  getPolicies(): Policy[] {
    return this.policies;
  }

  setSessionPolicies(sessionId: string, policies: Policy[]) {
    if (!this.sessionPolicies) {
      this.sessionPolicies = [];
    }
    this.sessionPolicies[sessionId] = policies;
  }

  getSessionPolicies(sessionId: string): Policy[] {
    if (this.sessionPolicies) {
      return this.sessionPolicies[sessionId];
    }
  }

  setSessionTimeRequest(sessionId: string, timeRequest: TimeRequest) {
    if (!this.sessionTimeRequests) {
      this.sessionTimeRequests = [];
    }
    this.sessionTimeRequests[sessionId] = timeRequest;
  }

  getSessionTimeRequest(sessionId: string): TimeRequest {
    if (this.sessionTimeRequests) {
      return this.sessionTimeRequests[sessionId];
    }
  }

  setSessionSelectedPolicy(sessionId: string, selectedPolicy: Policy) {
    if (!this.sessionSelectedPolicy) {
      this.sessionSelectedPolicy = [];
    }
    this.sessionSelectedPolicy[sessionId] = selectedPolicy;
  }

  getSessionSelectedPolicy(sessionId: string): Policy {
    if (this.sessionSelectedPolicy) {
      return this.sessionSelectedPolicy[sessionId];
    }
  }

  setSessionUuid(idSession: string, uuid: string) {
    if (!this.sessionUuid) {
      this.sessionUuid = [];
    }
    this.sessionUuid[idSession] = uuid;
  }

  getSessionUuid(idSession: string): string {
    if (this.sessionUuid) {
      return this.sessionUuid[idSession];
    }
  }

  setSessionSelectableCoverturesResponse(sessionId: string, covertures: SelectableCovertureResponse) {
    if (!this.sessionSelectableCoverturesResponse) {
      this.sessionSelectableCoverturesResponse = [];
    }
    this.sessionSelectableCoverturesResponse[sessionId] = covertures;
  }

  getSessionSelectableCoverturesResponse(sessionId: string): SelectableCovertureResponse {
    if (this.sessionSelectableCoverturesResponse) {
      return this.sessionSelectableCoverturesResponse[sessionId];
    }
  }

  setSessionAdministrativeCoverture(sessionId: string, valid: boolean) {
    if (!this.sessionAdministrativeCoverture) {
      this.sessionAdministrativeCoverture = [];
    }
    this.sessionAdministrativeCoverture[sessionId] = valid;
  }

  getSessionAdministrativeCoverture(sessionId: string): boolean {
    if (this.sessionAdministrativeCoverture) {
      return this.sessionAdministrativeCoverture[sessionId];
    }
  }

  isSessionEventSet(sessionId: string): boolean {
    if (this.sessionEventSet) {
      return this.sessionEventSet[sessionId];
    }
  }

  setSessionEventSet(sessionId: string, value: boolean = true) {
    if (!this.sessionEventSet) {
      this.sessionEventSet = [];
    }
    this.sessionEventSet[sessionId] = value;
  }





  setSessionPolicyModifyData(sessionId: string, policyModifyData: any) {
    if (!this.policyModifyData) {
      this.policyModifyData = [];
    }
    this.policyModifyData[sessionId] = policyModifyData;
  }

  getPolicyModifyDataFromSession(sessionId: string): any {
    if (this.policyModifyData) {
      return this.policyModifyData[sessionId];
    }
  }

  getEnableMultiDamage() {
    return this.enableMultiDamage;
}

  setEnableMultiDamage(enableMultiDamage: boolean) {
    this.enableMultiDamage = enableMultiDamage;
  }

  setSessionInsuredActivities(sessionId: string, insuredActivites: ComboEntity[]) {
    if (!this.sessionInsuredActivities) {
      this.sessionInsuredActivities = [];
    }
    this.sessionInsuredActivities[sessionId] = insuredActivites;
  }

  getSessionInsuredActivities(sessionId: string): ComboEntity[] {
    if (this.sessionInsuredActivities) {
      return this.sessionInsuredActivities[sessionId];
    }
  }

  setGarctp(garctp: string) {
    this.garctp = garctp;
  }

  getGarctp() {
    return this.garctp;
  }

  getCitiesFromZipCode(zipCode: string): Observable<any> {
    if (zipCode.length === 5 &&  !isNaN(parseInt(zipCode, 10))) {
      return this.httpClient.get(this.baseApiUrl + this.urlservice + '/extractFromZipCode?zipCode=' + zipCode);
    }
  }

}

export class InjuryEntity {
 listDepartment: Array<any>;
 listTypeofLoss: Array<any>;
 listSeverityCode: Array<any>;
 listInjuryCode: Array<any>;
 listAllegation: Array<any>;
 listTypeMatter: Array<any>;
 departmentCodeSel: string;
 lossTypeSel: string;
 severityCodeSel: string;
 injuryCodeSel: string;
 allegationSel: string;
 allegationDescription: string;
 matterType: string;
 injuryNote: string;
 ministerialBranch: string;
 subHospital: string;
}

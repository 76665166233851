import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RolesCheckboxService {

  protected storedCheckboxValues = new Map();

  constructor() { }

  setCheckboxValueIntoMap(role: string, value: any) {
    if (value !== undefined && value !== null) {
      this.storedCheckboxValues.set(role, value);
    }
  }

  getStoredCheckboxValue(role: string): any {
    return this.storedCheckboxValues.get(role);
  }

}

import {Injectable, InjectionToken, Type} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {RgiRxHttpError} from '../http-api';
import {Observable, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {RgiRxHttpErrorStream} from '../rgi-rx-http-error-stream';
import {rgiRxHttpErrorSeverityFromStatus} from '../http-fns';

export const RGI_RX_HTTP_CLIENT_INTERCEPTOR = new InjectionToken<HttpInterceptor /*todo replace */>('RGI_RX_HTTP_CLIENT_INTERCEPTOR');


export abstract class RgiRxHttpClientOptions {
  basePath?: string;
}


/**
 * Implementation of the adapters should provide mapping to RgiRxHttpError from the HTTP api error model
 */
export abstract class RgiRxHttpErrorAdapter {
  abstract adapt(httpError: HttpErrorResponse): RgiRxHttpError | Observable<RgiRxHttpError>;
}

/**
 * Implementation can change the request before being sent to the server
 */
export abstract class RgiRxHttpRequestAdapter {
  abstract adapt(request: HttpRequest<any>): Observable<HttpRequest<any>> | HttpRequest<any>;
}

export class RgiRxDefaultHttpErrorAdapter extends RgiRxHttpErrorAdapter {
  adapt(httpError: HttpErrorResponse): RgiRxHttpError | Observable<RgiRxHttpError> {
    return new RgiRxHttpError(httpError.message, httpError.status, httpError.url, {
      headers: httpError.headers,
      body: httpError.error,
      statusText: httpError.statusText,
      severity: rgiRxHttpErrorSeverityFromStatus(httpError.status)
    });
  }
}

@Injectable()
export class RgiRxHttpErrorInterceptor implements HttpInterceptor {
  constructor(private adapter: RgiRxHttpErrorAdapter, private httpErrorStream: RgiRxHttpErrorStream) {
  }

  private isRgiRxHttpError(instance: RgiRxHttpError | Observable<RgiRxHttpError>): instance is RgiRxHttpError {
    const error = instance as Observable<any>;
    return error.subscribe === undefined;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const rgiRxHttpError = this.adapter.adapt(err);
          if (this.isRgiRxHttpError(rgiRxHttpError)) {
            this.httpErrorStream.push(rgiRxHttpError);
            return throwError(rgiRxHttpError);
          }
          return rgiRxHttpError.pipe(
            mergeMap(error => {
              this.httpErrorStream.push(error);
              return throwError(error);
            })
          );
        }
        return throwError(err);
      })
    );
  }
}


export interface RgiRxHttpClientFactoryOpts {
  /* withInterceptors?: HttpInterceptor[];*/

  /**
   * A request adapter type for handling outgoing request marshalling or interception
   */
  withRequestAdapter?: Type<RgiRxHttpRequestAdapter>;

  /**
   * An error adapter to be used to map http errors to RgiRxHttpError
   * If not set from a factory, will use the default provided adapter
   */
  withErrorAdapter?: Type<RgiRxHttpErrorAdapter>;
  /**
   * Set a basePath for all the requests generated for this client.
   * This allows to create client instance for specific paths and sub-paths of a particular
   * URI pathname
   */
  withBasePath?: string;

  /**
   * @description Exclude the interceptors registered with RGI_RX_HTTP_CLIENT_INTERCEPTOR token and keep only the error interceptor.
   * This is useful when you want to use the client for a specific purpose, and you don't want to include the default interceptors that are may
   * application specific.
   * @see RGI_RX_HTTP_CLIENT_INTERCEPTOR
   */

  withoutInterceptors?: boolean
}

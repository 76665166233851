import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormsStatusService {

    private channel$: (() => void)[] = [];

    public askForStatus(): any {
        for (const channelFn of this.channel$) {
            const channelResult: any = channelFn();
            if (!channelResult) {
                return false;
            } else if (typeof channelResult === 'string') {
                return channelResult;
            }
        }
        return true;
    }

    public register(fn: () => void) {
        this.channel$.push(fn);
    }

    public clearChannel() {
        this.channel$ = [];
    }
}

import { LpcDateStepperComponent } from './lpc-date-stepper.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
@NgModule({
    declarations: [LpcDateStepperComponent],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        RgiRxI18nModule,
        LpcRequiredModule
    ],
    exports: [LpcDateStepperComponent]
})
export class LpcDateStepperModule {

}


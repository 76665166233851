

import {
  ICoreResult,
  PortalCard,
  PortalCardConfig, PortalCards, portalControllerFactory,
} from '@rgi/rx/portal';
import {AuthorizationFilter,
  FIELD, FIELD_AUTH_REQUEST_CODE,
  FIELD_AUTH_REQUEST_TYPE,
  FIELD_AUTH_PRIORITY,
  FIELD_AUTH_POLICY_TYPE,
  FIELD_AUTH_USER_TYPE,
  FIELD_AUTH_COMPETENT_USER,
  FIELD_AUTH_REQUEST_STATUS,
  FIELD_AUTH_WORKFLOW_STATUS,
  FIELD_AUTH_REQUEST_DATE_FROM,
  FIELD_AUTH_REQUEST_DATE_TO,
  FIELD_AUTH_PRODUCT_TYPE,
  FIELD_AUTH_POSTSELL_CODE,
  FIELD_AUTH_DEROGATION_LEVEL,
  FIELD_AUTH_DEROGATION_LEVEL_NUMBER,
  FIELD_AUTH_LAST_ACTION,
  AuthHomeFieldConfigurationService} from "@rgi/authorizations-card";
import {RoutingService} from "@rgi/rx/router";
import {RgiRxTranslationService} from "@rgi/rx/i18n";

proxyController.$inject = ['$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxPortalCardService', 'RgiRxTranslationService', 'RgiRxTemplateInterpolationService', 'containerCacheService', 'authorizationsSearchService', 'authService', 'sessionService'];
function proxyController(scope, controller, coreResult, RgiRxRoutingService, RgiRxPortalCardService, RgiRxTranslationService, RgiRxTemplateInterpolationService, containerCacheService, authorizationsSearchService, authService, sessionService) {

  let data = containerCacheService.get(scope.card.idSession);

  const filter: AuthorizationFilter = new AuthorizationFilter();
  filter.userLogin = authService.getOperator().username;
  filter.nodeCode = authService.getOperator().salePoint.objectId;
  filter.subtree = true;
  filter.idSubject = data.data.objectId;
  scope.onSelect = (auth) => {
    authorizationsSearchService.getAuthorizationDetailById(auth.authId).subscribe(data => {
      const id = sessionService.open('authorizationsCard', 'authorizations/detail')
      coreResult.setResult(id, 'authorizations/detail', data);
    });
  };

  const subscription = authorizationsSearchService.getAuthorizationsListFromSubcard(filter).subscribe(authorizations => {
    scope.authorizations = authorizations;
  });

  scope.$on('$destroy', () => {subscription.unsubscribe()});
}

homeController.$inject = ['$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxTranslationService', 'authorizationsSearchService', 'authHomeFieldConfigurationService'];
export function homeController($scope, $controller, coreResult: ICoreResult, rgiRxRoutingService: RoutingService, rgiRxTranslateService: RgiRxTranslationService, authorizationsSearchService, authHomeFieldConfigurationService) {
  portalControllerFactory('authorizations', 'home').apply(this, arguments); // apply the portalControllerFactory
  authorizationsSearchService.setAdvancedSearch(!!$scope.isAvancedSearchMode());

  $scope.$on('$destroy', () => {
    authHomeFieldConfigurationService.clearField($scope.$$id);
  });

  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_REQUEST_CODE);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_REQUEST_TYPE);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_PRIORITY);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_POLICY_TYPE);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_USER_TYPE);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_COMPETENT_USER);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_REQUEST_STATUS);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_WORKFLOW_STATUS);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_REQUEST_DATE_FROM);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_REQUEST_DATE_TO);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_PRODUCT_TYPE);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_POSTSELL_CODE);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_DEROGATION_LEVEL);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_DEROGATION_LEVEL_NUMBER);
  addConfiguredField($scope, authHomeFieldConfigurationService, FIELD_AUTH_LAST_ACTION);

}

export const AUTHORIZATIONS_CARD: PortalCards = [{
  card: {
    title: '_AUTHORIZATION_SEARCH',
    name: 'authorizationsCard',
    category: 'Authorizations Management',
    type: 'main',
    functions: ['auth.management'],
    subCards: [],
    subCardsActive: [],
    navigationInSession: true,
    customize: {
      enabled: true,
      hideHeader: false,
      hideContainerBody: false,
      menuVoiceAdvancedSearch: true,
      fields: [
        FIELD_AUTH_REQUEST_CODE,
        FIELD_AUTH_REQUEST_TYPE,
        FIELD_AUTH_PRIORITY,
        FIELD_AUTH_POLICY_TYPE,
        FIELD_AUTH_USER_TYPE,
        FIELD_AUTH_COMPETENT_USER,
        FIELD_AUTH_REQUEST_STATUS,
        FIELD_AUTH_WORKFLOW_STATUS,
        FIELD_AUTH_REQUEST_DATE_FROM,
        FIELD_AUTH_REQUEST_DATE_TO,
        FIELD_AUTH_PRODUCT_TYPE,
        FIELD_AUTH_POSTSELL_CODE,
        FIELD_AUTH_DEROGATION_LEVEL,
        FIELD_AUTH_DEROGATION_LEVEL_NUMBER,
        FIELD_AUTH_LAST_ACTION
      ]
    }
  } as PortalCard,
  routes: [
    {
      route: 'home',
      destination: 'authorizations',
      type: 'home',
      label: '_AUTHORIZATION_SEARCH',
      controller: homeController,
    },
    {
      route: 'authorizations/list',
      destination: 'authorizations/list',
      label: '_AUTHORIZATION_SEARCH',
    },
    {
      route: 'authorizations/detail',
      destination: 'authorizations/detail',
      label: '_AUTHORIZATION_SEARCH_DETAIL',
    },
    {
      route: 'proxy',
      destination: 'home',
      label: '_AUTH_._CARD_TITLE_',
      controller: proxyController,
      template: 'proxyTemplate'
    },
    {
      route: 'customize',
      template: 'coreportal/card/directives/rgiCard-customize.tpl.html',
      label: 'Customize - Authorizations Search',
      destination: ''
    }
  ]
}];

function addConfiguredField($scope, authHomeFieldConfigurationService: AuthHomeFieldConfigurationService, field: FIELD) {
  if ($scope[field.name]) {
    authHomeFieldConfigurationService.addField($scope.$$id, $scope[field.name]);
  }
}




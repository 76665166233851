import { ApiAsset } from './api-asset';
import { ApiUnit } from './api-unit';

export class ApiPolicy {
    public policyNumber: string;
    public description: string;
    public contractingSubject: string;
    public effectDate: Date;
    public expirationDate: Date;
    public objectId: number;
    public product: string;
    public units: ApiUnit[];
    public assets: ApiAsset[];
    public nodeCode: string;
    public contractor: any;
}

<rgi-rx-expansion-panel [expanded]="expanded" #first color="info">
    <div rgi-rx-expansion-panel-header>
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-6 col-md-6">
                <label ng-bind="label">
                    {{ '_CLAIMS_._FISCAL_DATA' | translate }}
                </label>
            </div>
        </div>
    </div>
    <form [formGroup]="newFiscalForm">
        <div class="form-group row">
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field>
                    <input rgiRxInput formControlName="legalCode" [disabled]="true">
                    <label rgiRxLabel >{{ '_CLAIMS_._LEGAL_CODE' | translate }}</label>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field>
                    <input rgiRxInput formControlName="nation" [disabled]="true">
                    <label rgiRxLabel>{{ '_CLAIMS_._NATION' | translate }}</label>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field>
                    <input rgiRxInput formControlName="aggregateTaxRegime" [disabled]="true">
                    <label rgiRxLabel >{{ '_CLAIMS_._AGGREGATE_TAX_REGIME' | translate }}</label>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field  *ngIf="taxRegimeList && !consult;else noConsultTax">
                    <label rgiRxLabel>{{ '_CLAIMS_._TAX_REGIME' | translate }}</label>
                    <select rgiRxNativeSelect formControlName="taxRegime" (change)="onChangeRegime()">
                        <option [value]=""></option>
                        <option *ngFor="let regime of taxRegimeList" [value]="regime">
                            {{regime.description}}
                        </option>
                    </select>
                </rgi-rx-form-field>
                <ng-template #noConsultTax>
                    <rgi-rx-form-field>
                        <input rgiRxInput formControlName="taxRegime" [disabled]="true">
                        <label rgiRxLabel >{{ '_CLAIMS_._TAX_REGIME' | translate }}</label>
                    </rgi-rx-form-field>
                </ng-template>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field>
                    <input rgiRxInput formControlName="aggregateIvaRegime" [disabled]="true">
                    <label rgiRxLabel >{{ '_CLAIMS_._AGGREGATE_IVA_REGIME' | translate }}</label>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field *ngIf="ivaRegimeList && !consult;else noConsultIva">
                    <label rgiRxLabel>{{ '_CLAIMS_._IVA_REGIME' | translate }}</label>
                    <select rgiRxNativeSelect formControlName="ivaRegime" (change)="onChangeRegime()">
                        <option [value]=""></option>
                        <option *ngFor="let regime of ivaRegimeList" [value]="regime">
                            {{regime.description}}
                        </option>
                    </select>
                </rgi-rx-form-field>
                <ng-template #noConsultIva>
                    <rgi-rx-form-field>
                        <input rgiRxInput formControlName="ivaRegime" [disabled]="true">
                        <label rgiRxLabel >{{ '_CLAIMS_._IVA_REGIME' | translate }}</label>
                    </rgi-rx-form-field>
                </ng-template>
            </div>
        </div>
    </form>
</rgi-rx-expansion-panel>

import {BehaviorSubject} from 'rxjs';

export default class LoadingSubject {
  id: string;
  value: BehaviorSubject<boolean>

  constructor(id: string, value: boolean) {
    this.id = id;
    this.value = new BehaviorSubject(value);
  }
}

import { Component, Output, Input, ViewContainerRef, ViewRef, OnInit, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NodeService } from './node.service';
import { Node } from './node';
import { TableElement } from '@rgi/portal-ng-core';

@Component({
  selector: 'pcm-node-search-modal',
  templateUrl: './node-search-modal-component.html',
  styleUrls: ['./node-search-modal-component.css']
})
export class NodeSearchModalComponent implements OnInit {

  nodeSearchForm: UntypedFormGroup;
  nodes: Array<Node>;
  ref: ViewRef;
  nodeSearched: boolean;
  sizeNodeList: number;
  validationMessages: string[];
  @Output() selectedNodeEmitter: EventEmitter<Node> = new EventEmitter<Node>();
  @Input() codeLoginNode: string;
  idNodeLogin: string;
  minLengthSearch = 3;
  tableElement: TableElement[][];
  stopEvaluation: boolean;
  @Input() showGroupingNodes: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private nodeService: NodeService) {
  }

  ngOnInit() {
    this.nodeSearched = false;
    this.nodeSearchForm = this.formBuilder.group(
      {
        code: ['', Validators.minLength(this.minLengthSearch)],
        description: ['', Validators.minLength(this.minLengthSearch)],
      }
    );

    if (this.codeLoginNode) {
      this.nodeService.getNodes(this.codeLoginNode, '', this.codeLoginNode, this.showGroupingNodes).subscribe((data: any) => {
        const selfNodes = data.agencies;
        if (selfNodes.length === 1) {
          this.idNodeLogin = selfNodes[0].idSp;
          this.loadSelfNodes();
          this.stopEvaluation = true;
        }
      },
      err => {
        console.log('La chiamata è andata in errore');
      });
    } else {
           this.stopEvaluation = true;
    }
  }

  onButtonClicked(event: TableElement) {
    if ( event.getEvent() === 'buttonClicked' ) {
      const filteredNodes = this.nodes.filter(
        node => node.idSp === event.getId()
      );
      this.selectNode(filteredNodes[0]);
    }
  }


  createTable() {
    if (this.nodes.length > 0) {
      this.nodeSearched = true;
      // Creo l'oggetto per la table del common
      // HEADER
      this.tableElement = new Array<Array<TableElement>>();
      const valueToPush: TableElement[] = [ new TableElement('Code'), new TableElement('Description'), new TableElement('')];
      this.tableElement.push(valueToPush);
      for (const entry of this.nodes) {
        const valueToPushElement: TableElement[] = [ new TableElement( entry.code, 'span' ),
        new TableElement( entry.description, 'span' ),
        new TableElement( '', 'button', entry.idSp, '', 'glyphicon glyphicon-plus' )];
        this.tableElement.push(valueToPushElement);
      }

    } else {
      // si genera un messaggio d'errore
      this.validationMessages = [];
      this.validationMessages.push('Nessun valore trovato');
    }
  }

  loadSelfNodes() {
      this.nodeService.getNodesByLoginNodeId(
        this.idNodeLogin).subscribe((data: any) => {
          this.nodes = data.agencies;
          this.createTable();
        },
          err => {
            console.log('La chiamata è andata in errore');
          });
  }

  onSubmit() {
    this.validateForm();
    this.setFieldsDirty();
    if (this.validationMessages.length === 0) {
      this.nodeService.getNodes(this.nodeSearchForm.controls.code.value, this.nodeSearchForm.controls.description.value,
        this.codeLoginNode, this.showGroupingNodes).subscribe((data: any) => {
          this.nodes = data.agencies;
          this.createTable();
        },
          err => {
            console.log('La chiamata è andata in errore');
        });
    }
  }

  selectNode(node) {
    this.selectedNodeEmitter.emit(node);
    this.ref.destroy();
  }

  cancel() {
    this.nodeSearched = false;
    this.ref.destroy();
  }

  private validateForm() {
    const controls = this.nodeSearchForm.controls;

    this.validationMessages = [];
    if (controls.code && controls.code.value && controls.code.value.length < this.minLengthSearch) {
      this.validationMessages.push('Code min length error');
    }
    if (controls.description && controls.description.value && controls.description.value.length < this.minLengthSearch) {
      this.validationMessages.push('Description min length error');
    }
    if ((controls.code && controls.code.value === '') && (controls.description && controls.description.value === '')) {
      this.validationMessages.push('Fill in at least one of the two fields Code or Description');
    }

  }

  private setFieldsDirty() {
    Object.keys(this.nodeSearchForm.controls).forEach(field => {
      this.nodeSearchForm.get(field).markAsDirty();
    });
  }

}

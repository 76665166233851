<div>
  <!-- PACKAGES -->
  <re-issue-package *ngIf="quotePackets"
                    [quotePackets]="quotePackets"
                    (packetSelected)="selectPacket($event)"
                    [packetContract]="false"
                    [numAsset]="1"
                    [currency]="currency"></re-issue-package>

  <!-- SECTIONS -->
  <form [formGroup]="guaranteesForm">
    <div rgi-rx-accordion [multi]="true" *ngFor="let section of sections;let i = index"> <!-- fist level -->
      <rgi-rx-expansion-panel attr.data-qa="section_{{section.description}}">
        <rgi-rx-expansion-panel-header class="heading-panel section-title-header">
            <span class="rgi-ui-title-1 rgi-re-title section-font-size">{{section.description + ' '}}</span>
            <span *ngIf="sectionsPremium"
                  class="rgi-ui-title-1 rgi-re-title span-right style-header">{{getSumGrossSection(section) |
              currency: currency.code : currency.symbol :'1.2-2':'IT' }}&nbsp;</span>
        </rgi-rx-expansion-panel-header>
        <div *ngFor="let unit of section?.unitList; let i = index ">
          <!-- UNIT MANAGEMENT | LIST -->
          <div  *ngIf="!viewUnitsBox ">
              <rgi-rx-expansion-panel attr.data-qa="unit_{{unit.description}}">
                <rgi-rx-expansion-panel-header class="unit-description  style-expansion-panel" style="background-color:white" >
                  <span> <input rgiRxInput type="checkbox" formControlName="{{section.code}}_{{unit.code}}"class="unit-checkbox"></span>
                  <span class="unit-description section-font-size">{{unit.description + ' '}}</span>
                  <span class="span-right label-unit">
                    <div *ngIf="sectionsPremium">
                                {{ sectionsPremium.get(section?.code)?.getUnit(unit?.code)?.annual?.gross |
                      currency: currency.symbol:'symbol':'1.2-2':'it' }}&nbsp;&nbsp;&nbsp;
                    </div>
                  </span>
                  </rgi-rx-expansion-panel-header>
                <!-- UNIT CONTAINER -->
                <div *ngIf="unit?.factors?.length > 0  || unit?.risk?.clauses?.length > 0 ">
                  <div class="rgi-ui-grid-2" *ngIf="formReady">
                    <div *ngFor="let factor of unit?.factors" class="rgi-ui-col">
                      <re-issue-factors [factor]="factor"
                                        factorCode="{{section.code +'_' + unit.code + '_' + factor.code}}"
                                        [factorControlForm]="guaranteesForm"
                                        (updateFactor)="validateVariables(unit, section, section.code +'_' + unit.code + '_' + factor.code)">
                      </re-issue-factors>
                    </div>

                  </div>
                  <div class="rgi-ui-grid-2" *ngIf="formReady">
                    <re-issue-input-premium class="rgi-ui-col" *ngIf="!isDirectionalUser"
                                            [inputPremiumTypeId]="unit?.inputPremiumType"
                                            [inputPremiumEnabled]="unit?.selected && canEnableInputPremium(section.code, unit)"
                                            [inputPremium]="unit?.inputPremium"
                                            (updatePremium)="updateUnitPremium($event,section.code, unit?.code)">
                    </re-issue-input-premium>
                  </div>
                  <re-issue-clauses *ngIf="unit?.clauseUnit?.length>0"
                                    [showTitle]="true"
                                    [clausesList]="unit.clauseUnit"
                                    [assetKey]="assetInstanceKey"
                                    [disabled]="!unit?.selected"
                                    (updateClause)="updateUnitClause($event,section.code,unit?.code)"></re-issue-clauses>
                </div>

              </rgi-rx-expansion-panel>
      </div >
    </div>
      </rgi-rx-expansion-panel>
    </div>

  </form>
</div>

import {UntypedFormGroup, ValidatorFn} from '@angular/forms';

export class AnagFormFieldConfig {
  configFieldName: string;
  form: UntypedFormGroup;
  validators: Array<ValidatorFn>;
  partyConfProperty: string;
  index: number;

  constructor(configFieldName: string, form: UntypedFormGroup, validators: Array<ValidatorFn>, partyConfProperty?: string, index?: number) {
    this.configFieldName = configFieldName;
    this.form = form;
    this.validators = validators;
    this.partyConfProperty = partyConfProperty;
    this.index = index;
  }
}

<div class="grid-item card card-height-normal card-main" *ngIf="this.selectedCompensation" >

  <div class="modal-header">
    <h3 class="modal-title modal_title_max_width">
      {{ '_CLAIMS_._COMPENSATION_DIARY_N' | translate }} : {{this.selectedCompensation.number}}
      <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
    </h3>
  </div>

  <div class="rgi-ui-modal-content" style="margin-top:10px; margin-right: auto; margin-left: auto; margin-bottom: 20px">
    <div class="rgi-ui-text-2">{{ '_CLAIMS_._NEW_NOTE' | translate }}</div>
    <form [formGroup]="formGroupNote">
      <rgi-rx-form-field>
        <textarea style="min-height: 100px; min-width: 100%" rgiRxInput formControlName="text"></textarea>
<!--        <label rgiRxLabel></label>-->
      </rgi-rx-form-field>

      <button [disabled]="!this.formGroupNote.get('text').value && this.formGroupNote.get('text').value.trim().length === 0" style="height: 70%; margin-top:5px;" (click)="handleAddNote()" rgi-rx-button color="primary" style="height: 70%; width: 100%">
        {{ '_CLAIMS_._BUTTONS_._ADD' | translate }}
      </button>
      <div class="rgi-ui-snackbar" style="background-color: #d95f58; color: white" *ngIf="error"  >
          <span class="rgi-ui-icon-alert"></span>
          <span class="rgi-ui-message" style="color:white; font-size: 15px; font-weight: 700;">{{errorMsg | translate}}</span>
      </div>
    </form>



  </div>

<!--  elenco note-->
  <div *ngIf="this.diaryNotes && this.diaryNotes.length > 0" style="display: flex; flex-direction: column; " >
<!-- START HEADER -->
    <div class="diary-note-header-container">
    <!--    datetime header-->
    <div class="col-xs-2 diary-note-header">
      <span class="glyphicon glyphicon-time compensation-clock-icon" (click)="reverseOrderNotes()"></span>
    </div>
    <!--    user header-->
    <div class="col-xs-2 diary-note-header">
      <span class="rgifont rgi-user"></span>
    </div>

<!--    text header-->
    <div class="col-xs-8 diary-note-header">
      <span class="glyphicon glyphicon-text-background"></span>
    </div>
    </div>
<!--END HEADER-->
    <div style="max-height: 350px; overflow: auto">
    <div *ngFor="let note of diaryNotes"  >
      <div   style="border-bottom: 1px solid #6C7276; display: flex">


      <div class="col-xs-2 diary-note-element">
        {{note.datetime}}
      </div>
      <div class="col-xs-2 diary-note-element">
        {{note.user}}
      </div>
      <div class="col-xs-8 diary-note-element-text">
        {{note.text}}
      </div>
      </div>
    </div>
    </div>

  </div>

  <div class="diary-no-notes-text" *ngIf="!diaryNotes || !diaryNotes.length">
{{ '_CLAIMS_._NO_NOTES_FOR_THIS_COMPENSATION' | translate }}
  </div>


  <button style="width: 100%" (click)="handleGoBack()" rgi-rx-button color="primary">{{ '_CLAIMS_._BUTTONS_._EXIT' | translate }}</button>


</div>

import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OutputSubjectData } from '../proposal-otp-model/proposal-otp.model';
import { OutputPartyEntity } from '../proposal-otp-model/party-Interface';
import {
  AnagApiCreateSubject,
  AnagApiSaveSubjectResponse,
} from '../proposal-otp-model/save-party-interfaces';
import {EnviromentsService} from '@rgi/life-issue-card';

@Injectable({
    providedIn: 'root',
})
export class ProposalOtpService {
    hostPath: string;
    hostPathV2: string;

    constructor(
        @Inject('environment') private apiConf: any,
        private http: HttpClient,
        protected environment: EnviromentsService
    ) {
        this.hostPath = this.apiConf.api.portal.host;
        this.hostPathV2 = this.apiConf.api.portal.host + '/api/rest/v2' ;
    }
    getParties(contractId: string) {
        return this.http.get<any>(this.hostPathV2 + '/motor/contract/' + contractId + '/parties');
      }

    getSubjectData(inputParty: any): Observable<OutputSubjectData> {
        const url = this.hostPath + '/api/rest/anag/subject/' + inputParty.partyId + '?idNode=' + inputParty.node.id;
        return this.http.get<OutputSubjectData>(url);
    }

  saveParty(anagApiCreateSubject: AnagApiCreateSubject): Observable<AnagApiSaveSubjectResponse> {
    let params = new HttpParams();
    anagApiCreateSubject.subject.mainSubject = true;
    params = params.append('idNode', `${anagApiCreateSubject.subject.node.identification}`);
    if (anagApiCreateSubject.subject.links) {
      anagApiCreateSubject.subject.links.forEach(link => {
        params = params.append('idObjectLinkSubject', link.linkedPartyObjectId);
      });
    }
    const baseApiUrl = this.environment.baseApiURL;
    return this.http.post<AnagApiSaveSubjectResponse>(`${baseApiUrl}/anag/subject`, anagApiCreateSubject, {params});
  }

  updateContractParties(contractId: string, parties) {
    return this.http.put<OutputPartyEntity>(this.hostPathV2 + '/motor/contract/' + contractId + '/parties', parties);
  }

  getPartyPhotos(contractId, partyId, partyLockId) {
    const url = this.hostPathV2 + '/motor/contract/' + contractId + '/party?partyId=' + partyId + '&partyLockId=' + partyLockId;
    return this.http.get<OutputPartyEntity>(url);
  }

  getDocumentForOtp(portfolio: string, documents: any[]): any {
    let doc;
    if (documents && documents.length > 0) {
      if (portfolio !== 'Life') {
        doc = documents.find(doc=> doc.documentType && doc.documentType.codice === '1');
      }
      if (!doc) {
        doc = documents[documents.length-1];
      }
    }
    return doc;
  }

}




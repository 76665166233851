import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PortfolioMovementResponse} from '../../models/api-models/portfolio-movement-response';
import {REST_API_PORTFOLIO_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioResourceService {

  private CODICE_BANCA_MOVIMENTI = 'PRMOVB';
  private apiPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any) {
    this.apiPortfolioConf = REST_API_PORTFOLIO_CONF;
  }

  getPolicyMovementList$(policyNumber: string): Observable<PortfolioMovementResponse> {
    return this.http.get<PortfolioMovementResponse>(this.movementListUri(policyNumber));
  }

  protected movementListUri(policyNumber: string): string {
    return this.apiConf.api.portal.path + '/v2' + this.apiPortfolioConf.path + this.apiPortfolioConf.movimenti +
      '?funzione=' + this.CODICE_BANCA_MOVIMENTI + '&numPolizza=' + policyNumber;
  }

}

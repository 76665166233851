import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Questionnaire } from '../../../claim-questionnaire/claim-questionnaire/claim-questionnaire.interface';

@Component({
  selector: 'claims-cai-opening-questionnaire',
  templateUrl: './cai-opening-questionnaire.component.html',
  styleUrls: ['./cai-opening-questionnaire.component.scss'],
})
export class CaiOpeningQuestionnaireComponent implements OnInit {
  @Input() inputQuestionnaire: any;
  @Output() outputQuestionnaire: EventEmitter<Questionnaire> =
    new EventEmitter<Questionnaire>();

  ngOnInit() {}
  onChangeValueQuestionnaire(questionnaire: Questionnaire) {

    this.outputQuestionnaire.emit(questionnaire);
  }
}

<div class="anag-issue-modal issue-card-modal-content re-issue-card-styles">
  <div class="anag-issue-issue-card-issue-card-modal-header issue-card-modal-header">
    <div class="card-title">
      <span class="rgi-re-header-icon rgifont "></span>
      <span class="menu-title" translate>{{questionnaireFlat?.descriptionType}}</span>
    </div>
  </div>
  <div class="anag-issue-modal-body issue-card-modal-body">
    <!--    <div class="re-issue-questionnaire">
          <ppevo-questionnaire [questionnaireFlat]="questionnaireFlat" [disabled]="true"></ppevo-questionnaire>
        </div>
        <div [formGroup]="confermaGroup" class="re-issue-card-styles">
          <div class="form-group row">
            <div class="col-sm-6 col-xs-12 mt-2">
              <pnc-checkbox formControlName="confermaQuestionaire" required></pnc-checkbox>
              {{"RE_ISSUE.THE_CONTRACTOR_CONFIRMS_THE_VALIDITY" | translate}}
            </div>
          </div>
        </div>
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button class="btn btn-primary" [disabled]="!isConfermaEnabled" (click)="closeModal()">Conferma</button>
          </div>
        </div> -->
  </div>
</div>

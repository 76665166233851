import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentService } from '../../../services/document.service';
import {DocumentEvent} from '../../../models/document.model';
import { JS_EVENT } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-document',
  templateUrl: './lpc-document.component.html',
  styleUrls: ['./lpc-document.component.css']
})
export class LpcDocumentComponent implements OnInit {

  @Output() documentEvent = new EventEmitter<DocumentEvent>();
  @Input() contractId: string;
  @Input() operationCode: string;
  @Input() publishMessage: string;
  @Input() printMode: string;

  activePanelLists: string[] = [];
  documentList: any[];
  onInitEnd = false;


  constructor(protected documentService: DocumentService) { }


  get areThereDocuments(): boolean {
    return this.documentList.length > 0;
  }

  ngOnInit() {
    this.activePanelLists = ['doc-panel'];
    this.documentService.getDocumentList(
      this.contractId,
      this.operationCode,
      [],
      this.printMode
    ).subscribe((response) => {
      const vectorOfDoc = [];
      response.documents.forEach((element: any) => {
        vectorOfDoc.push({
          nomeFile: element.documentName,
          documentCode: element.documentCode
        });
      });

      this.documentList = vectorOfDoc;
      this.onInitEnd = true;
    });
  }

  downloadDocument(event: any, doc: any) {
    event.stopPropagation();
    this.documentEvent.emit({
      eventName: JS_EVENT.DOWNLOAD_DOC,
      document: doc,
      type: 'life'
    });
  }
}

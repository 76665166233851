import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { LpcStepComponent } from '../lpc-step/lpc-step.component';

@Component({
  selector: 'lpc-stepper',
  templateUrl: './lpc-stepper.component.html',
  styleUrls: ['./lpc-stepper.component.css']
})
export class LpcStepperComponent implements AfterViewInit, AfterContentChecked {

  @ContentChildren(LpcStepComponent, {descendants: true}) stepsQL !: QueryList<LpcStepComponent>;

  @Output() stepSlide: EventEmitter<number> = new EventEmitter<number>();

  @Input() isPublished: boolean;

  private $active;

  public get active(): number {
    return this.$active;
  }

  public get activeStep(): LpcStepComponent {
    return this.steps[this.$active];
  }

  public get steps(): LpcStepComponent[] {
    return this.stepsQL ? this.stepsQL.toArray().filter((s) => !s.nativeElement.hidden) : [];
  }

  get labels(): string[] {
    return this.steps.map(step => step.label);
  }

  get isAtLastStep(): boolean {
    if (!!this.stepsQL) {
      const filteredStep = this.steps.filter((s) => s.id !== 'documents');
      return this.$active === filteredStep.length - 1;
    }
    return false;
  }

  private $stepIds: string[];

  public get stepIds(): string[] {
    return this.$stepIds;
  }

  constructor() {
  }

  ngAfterViewInit(): void {
    this.setStepVariables();
    this.$stepIds = this.getStepIds();
    this.stepsQL.changes.pipe(
      distinctUntilChanged((prev, curr) => prev.length !== curr.length)
    ).subscribe((result) => {
      this.setStepVariables();
      this.$stepIds = this.getStepIds();
    });
  }

  private setStepVariables(): void {
    this.steps.forEach((step, index) => {
      step.stepper = this;
      step.index = index;
    });
  }

  private getStepIds(): string[] {
    const ids: string[] = [];
    this.steps.forEach((step, index) => {
      if (!!step.id && !step.nativeElement.hidden) {
        ids.push(step.id);
      }
    });
    return ids;
  }

  ngAfterContentChecked(): void {
    this.stepsQL.notifyOnChanges();
  }

  public isAfterId(id: string): boolean {
    if (!!this.activeStep) {
      const activeId: string = this.activeStep.id;
      return this.$stepIds.indexOf(id) >= 0 ? this.$stepIds.indexOf(id) < this.$stepIds.indexOf(activeId) : false;
    }
    return false;
  }

  public isCurrentStepId(id: string): boolean {
    if (!!this.activeStep) {
      const activeId: string = this.activeStep.id;
      return id === activeId;
    }
    return false;
  }

  public isBeforeId(id: string): boolean {
    if (!!this.activeStep && this.$stepIds.includes(id)) {
      const activeId: string = this.activeStep.id;
      return this.$stepIds.indexOf(id) > this.$stepIds.indexOf(activeId);
    }
    return true;
  }

  public getNextStep(id: string): string {
    const nextStep = this.steps[this.$active + 1];
    if (!!nextStep) {
      return nextStep.id;
    }
  }

  public slideTo(index: number, onBack = false, context?: string) {
    const currentScrollY: number = window.scrollY;
    this.closeAndDeactivateAllSteps();
    index = (index === null || index === undefined) && !!context ? this.getExactStepByContext(context) : index;
    if (this.steps.length > index && index >= 0) {
      this.$active = index;
      if (!!this.steps[this.$active - 1] && !onBack) {
        this.steps[this.$active - 1].closeStepperContentView();
      }
      // commented the observable handling because it seems useless, so we'll try without it
      // in case of issues on the step behavior decomment
      /* of({})
      .pipe(
        switchMap(_ => {
          const obsStepLogic: Observable<any>[] = [of(null)];*/
      this.steps.forEach((step, ind) => {
        if (ind <= index) {
          // @ts-ignore
          step.open();
          // obsStepLogic.push(of(true));
        }
      });
      // return combineLatest(obsStepLogic);
      // })).subscribe(_ => {
      // @ts-ignore
      this.steps[this.$active].activate();
      this.stepSlide.emit(this.$active);
      window.scroll(0, currentScrollY);
     // });
    }
  }


  public getExactStepByContext(context: string): number {
    const foundIndex = this.steps.findIndex(step => step.id === context);
    return foundIndex >= 0 ? foundIndex : 0;
  }

  /** @description
   * method triggerd from the LpcStepComponent when you'll click the pencil button to go backward the step to modify the previuos step
   * it calls the closeStepperContentViewAndSlideBack() with the index -1 sent from the child component that is the middle step index
   */
  public closeCurrent(index: number) {
    this.steps[index].closeStepperContentViewAndSlideBack(index - 1);
  }

  public back() {
    if (this.$active > 0) {
      this.slideTo(this.$active - 1);
      if (this.activeStep.jump) {
        this.back();
      }
    }
  }

  public next() {
    if (this.$active < this.steps.length - 1) {
      this.slideTo(this.$active + 1);
      if (this.activeStep.jump) {
        this.next();
      }
    }
  }

  private closeAndDeactivateAllSteps(): void {
    this.steps.forEach(step => {
      // @ts-ignore
      step.close();
      // @ts-ignore
      step.deactivate();
    });
  }
}

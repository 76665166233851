<div class="message" >
  <div class="message-error"*ngIf="error.fundP > error.fundMaxP" >
    <span translate>lic_fund</span>
    <span> {{error.fundName}}: </span>
    <span translate>lic_max_percentage</span>
    <span> {{error.fundMaxP}}</span>
  </div>
  <div class="message-error" *ngIf="error.fundP < error.fundMinP" >
    <span translate>lic_fund</span>
    <span> {{error.fundName}}: </span>
    <span translate>lic_min_percentage</span>
    <span> {{error.fundMinP}}</span>
  </div>
</div>

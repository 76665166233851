import {Directive, HostListener} from '@angular/core';
import {RgiRxHistoryRouter} from './rgi-rx-router-location-facade';

@Directive({
  selector: '[rgiRxRouterForwardLink]'
})
export class RgiRxRouterForwardLinkDirective {
  constructor(private router: RgiRxHistoryRouter) {}
  @HostListener('click', ['$event'])
  _handleClick(event: MouseEvent) {
    event.preventDefault();
    this.router.forward();
  }
}

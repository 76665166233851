import {ActiveRoute, Routes} from "@rgi/rx/router";
import {StateStoreService} from "@rgi/rx/state";
import {StartComponent} from "../start-card/start.component";
import {AuthorizationsListComponent} from "../authorizations-list/authorizations-list.component";
import {AuthorizationsDetailComponent} from "../authorizations-detail/authorizations-detail.component";
import {
    AuthorizationCardStateManagerStart,
} from '../authorization-card-state/authorization-card-statemanager-start';
import {
    AuthorizationCardStateManagerList,
} from '../authorization-card-state/authorization-card-statemanager-list';
import {
    AuthorizationCardStateManagerDetail,
} from '../authorization-card-state/authorization-card-statemanager-detail';
import {AuthorizationCardReducerService} from '../authorization-card-state/authorization-card-reducer.service';


export const AUTHORIZATION_CARD_ROUTES: Routes = [
    {
        route: 'authorizations',
        component: StartComponent,
        providers: [{
            provide: AuthorizationCardStateManagerStart,
            useClass: AuthorizationCardStateManagerStart,
            deps: [ActiveRoute, StateStoreService, AuthorizationCardReducerService]
        }],
        children: [{
            route: 'list',
            component: AuthorizationsListComponent,
            providers: [{
                provide: AuthorizationCardStateManagerList,
                useClass: AuthorizationCardStateManagerList,
                deps: [ActiveRoute, StateStoreService, AuthorizationCardReducerService]
            }]
        },{
            route: 'detail',
            component: AuthorizationsDetailComponent,
            providers: [{
                provide: AuthorizationCardStateManagerDetail,
                useClass: AuthorizationCardStateManagerDetail,
                deps: [ActiveRoute, StateStoreService, AuthorizationCardReducerService]
            }]
        }]
    },
];

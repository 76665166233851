import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {RgiRxDateTimeInputDirective} from '../date-time-input.directive';
import {RgiRxVirtualDOMError} from '@rgi/rx';
import {delay, mergeMap, startWith, tap} from 'rxjs/operators';
import {DateTimeAdapter} from '../adapter/date-time-adapter.class';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {RGI_RX_DATEPICKER_CONFIG, RgiRxDatePickerConfig} from '../date-picker-api';
import {KeyValue} from '@angular/common';
import {
  RgiRxFormFieldErrorComponent
} from '../../form-elements/rgi-rx-form-field-error/rgi-rx-form-field-error.component';
import {RgiRxFormFieldComponent} from '../../form-elements/rgi-rx-form-field/rgi-rx-form-field.component';

@Component({
  selector: 'rgi-rx-date-picker',
  templateUrl: './rgi-rx-date-picker.component.html',
  host: {
    class: 'rgi-ui-date-control'
  }
})
export class RgiRxDatePickerComponent<T> implements AfterViewInit, AfterContentInit, OnDestroy {

  private _openOnClick = true;
  private _showRemoveButton = false;
  private inputClickSubscription = Subscription.EMPTY;
  private errorComponentChangesSubscription = Subscription.EMPTY;

  _localValidators = [
    'rgiRxDateTimeMin',
    'rgiRxDateTimeMax',
    'rgiRxDateTimeFilter',
    'rgiRxDateTimeRange',
    'rgiRxDateTimeParse'
  ];
  @ContentChild(RgiRxDateTimeInputDirective, {static: false}) dateTimeInput?: RgiRxDateTimeInputDirective<T>;
  @ViewChildren(RgiRxFormFieldErrorComponent) errorComponents?: QueryList<RgiRxFormFieldErrorComponent>;
  @Output() removedValue = new EventEmitter<void>();

  constructor(
    private rgiRxTranslationService: RgiRxTranslationService,
    @Optional() private dateTimeAdapter?: DateTimeAdapter<T>,
    @Optional() @Inject(RGI_RX_DATEPICKER_CONFIG) config?: RgiRxDatePickerConfig,
    @Optional() private formField?: RgiRxFormFieldComponent
  ) {
    if (!!config) {
      this.openOnClick = config.openOnClick;
      this.showRemoveButton = config.showRemoveButton;
    }
  }


  @Input() get openOnClick(): boolean {
    return this._openOnClick;
  }

  set openOnClick(value: boolean) {
    this._openOnClick = value;
  }


  @Input() get showRemoveButton(): boolean {
    return this._showRemoveButton;
  }

  set showRemoveButton(value: boolean) {
    this._showRemoveButton = value;
  }

  ngAfterViewInit(): void {
    this.errorComponentChangesSubscription.unsubscribe();

    if (this.formField && this.formField.rgiRxFormFieldErrors) {
      this.errorComponentChangesSubscription = this.formField.rgiRxFormFieldErrors.changes.pipe(
        startWith(this.formField.rgiRxFormFieldErrors),
        mergeMap(() => this.errorComponents.changes),
        delay(0)
      ).subscribe(
        next => {
          this.dateTimeInput.ariaDescribedBy = this.dateTimeInput.ariaDescribedBy
            .concat(
              this.errorComponents
                .map(e => e.id)
                .filter(id => this.dateTimeInput.ariaDescribedBy.indexOf(id) === -1)
            );
        }
      );
    }
  }


  ngAfterContentInit(): void {
    if (!this.dateTimeInput) {
      throw new RgiRxVirtualDOMError('Missing input with [rgiRxDateTimeInput]');
    }
    if (this.dateTimeInput) {
      this.dateTimeInput.skipValidationKeys = this._localValidators;
    }
    this.inputClickSubscription = fromEvent(this.dateTimeInput.elementRef.nativeElement, 'click')
      .pipe(
        tap((evt: MouseEvent) => {
          if (this.openOnClick && (!this.dateTimeInput.isTriggeredBy || !!this.dateTimeInput.isTriggeredBy.find(trigger => trigger.elementRef !== this.dateTimeInput.elementRef))) {
            this.dateTimeInput.open();
            evt.stopPropagation();
          }
        })
      )
      .subscribe();
  }


  ngOnDestroy(): void {
    this.inputClickSubscription.unsubscribe();
    this.errorComponentChangesSubscription.unsubscribe();
  }

  remove($event: MouseEvent) {
    if (this.dateTimeInput.disabled) {
      $event.stopPropagation();
      return;
    }
    this.dateTimeInput.value = null;
    this.dateTimeInput.handleChanges(this.dateTimeInput.value);
    this.removedValue.emit();
    $event.stopPropagation();
  }

  getLabel$(error: { key: string, value: { [key: string]: any } }): Observable<string> {
    switch (error.key) {
      case 'rgiRxDateTimeParse': {
        return this.rgiRxTranslationService.translate('RGI_RX.DATEPICKER.PARSE_ERROR');
      }
      case 'rgiRxDateTimeMin': {
        return this.rgiRxTranslationService.translate('RGI_RX.DATEPICKER.EXCEED_MIN_ERROR', {
          actual: this.dateTimeAdapter.format(error.value.actual, this.dateTimeInput.dtPicker.formatString),
          min: this.dateTimeAdapter.format(error.value.min, this.dateTimeInput.dtPicker.formatString)
        });
      }
      case 'rgiRxDateTimeMax': {
        return this.rgiRxTranslationService.translate('RGI_RX.DATEPICKER.EXCEED_MAX_ERROR', {
          actual: this.dateTimeAdapter.format(error.value.actual, this.dateTimeInput.dtPicker.formatString),
          max: this.dateTimeAdapter.format(error.value.max, this.dateTimeInput.dtPicker.formatString)
        });
      }
      case 'rgiRxDateTimeFilter': {
        return this.rgiRxTranslationService.translate('RGI_RX.DATEPICKER.FILTER_ERROR');
      }
      case 'rgiRxDateTimeRange': {
        return this.rgiRxTranslationService.translate('RGI_RX.DATEPICKER.RANGE_ERROR');
      }
      default: {
        return this.rgiRxTranslationService.translate(error.key);
      }
    }
  }

  shouldRenderValidation(error: KeyValue<string, any>) {
    return this._localValidators.indexOf(error.key) > -1;
  }
}

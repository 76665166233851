<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">{{clauseData.extendedDescription}}</rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose.emit()" data-qa="rgi-gp-modal-close" translate>
      _GP_._BTN_._CLOSE_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="confirmClause()" data-qa="rgi-gp-modal-confirm"
            [disabled]="disableConfirm()" translate>_GP_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>
  <ng-container [formGroup]="modalTextForm">
    <div class="rgi-gp-flex-row">
      <ng-container *ngIf="clauseData.initialState">
        <div class="rgi-gp-field-container rgi-gp-mb-m">
          <div class="rgi-gp-field">
            <rgi-rx-form-field>
              <label rgiRxLabel>
                <span translate>_GP_._SUMMARY_._INITIAL_STATE</span>
              </label>
              <select rgiRxNativeSelect formControlName="initialStateField" data-qa="initialState">
                <option value=""></option>
                <option *ngFor="let initialState of clauseData.initialState.allowedValues" [value]="initialState?.code">
                  {{initialState?.description}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
        </div>
        <div class="rgi-gp-field-container rgi-gp-mb-m">
          <div class="rgi-gp-field">
            <rgi-rx-form-field>
              <label rgiRxLabel>
                <span translate >_GP_._LABEL_._HIDE_ON_APPLICATION_</span>
              </label>
              <rgi-rx-button-toggle formControlName="hiddenOnApplicationField">
                <button rgi-rx-button rgiRxValueOption value="false" translate>
                  _GP_._BTN_._NO_
                </button>
                <button rgi-rx-button rgiRxValueOption value="true" translate>
                  _GP_._BTN_._YES_
                </button>
              </rgi-rx-button-toggle>
            </rgi-rx-form-field>
          </div>
        </div>
      </ng-container>

      <div *ngIf="clauseData.textType === 'NONE'" class="rgi-gp-mb-0 rgi-gp-w-100">
        <rgi-rx-form-field data-qa="rgi-gp-modal-textarea" >
          <textarea rgiRxInput formControlName="textField"></textarea>
          <label rgiRxLabel><span translate>{{getTextAreaLabel()}}</span></label>
        </rgi-rx-form-field>
      </div>

      <div *ngIf="clauseData.textType === 'HTML'" class="rgi-gp-mb-0 rgi-gp-w-100">
        <label rgiRxLabel><span translate>{{getTextAreaLabel()}}</span></label>
        <p [innerHTML]="clauseData.text" ></p>
      </div>
    </div>
  </ng-container>
</rgi-rx-panel>

import {Pipe, PipeTransform} from '@angular/core';
import {RgiCountryLayerFormatter} from "../utils/rgi-country-layer.formatter";


@Pipe({
  name: 'rgiCountryLayerIbanFormat',
})
export class RgiCountryLayerIbanFormatPipe implements PipeTransform {


  constructor(private formatter: RgiCountryLayerFormatter) {
  }

  transform(value: any, args?: any): any {
    return this.formatter.iban(value);
  }
}

<div class="modal-header">
  <div class="modal-title" translate>{{ title }}</div>
</div>
<div class="modal-body">
  <ng-container *ngIf="!!message" >
    <div class="confirm example-row">
      <p>{{ message }}</p>
    </div>
  </ng-container>

  <ng-container *ngFor="let msg of messages" >
    <div class="modal-message-box" [ngClass]="{
      'primary-message': msg.messageType === 'primary',
      'danger-message': msg.messageType === 'danger',
      'warning-message': msg.messageType === 'warning',
      'info-message': msg.messageType === 'info',
      'success-message': msg.messageType === 'success'}"
    >
      <span [ngClass]="{
        'rgi-info': msg.messageType === 'primary',
        'rgi-close': msg.messageType === 'danger',
        'rgi-warning': msg.messageType === 'warning',
        'rgi-info-circle': msg.messageType === 'info',
        'rgi-check': msg.messageType === 'success'}">
      </span>
      <span>{{msg.message}}</span>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="btn-group btn-group-justified" style="margin-top: 0; border-right: 0 !important">
    <div class="btn-group" *ngIf="closeVisible">
      <button class="btn btn-warning btn-secondary"
              data-qa="modal-close-button"
              (click)="onClose()"
              translate>lpc_close_button
      </button>
    </div>
    <div class="btn-group" *ngIf="confirmVisible">
      <button class="btn btn-warning"
              data-qa="modal-confirm-button"
              (click)="onConfirm($event)"
              translate>{{ confirmLabel }}
      </button>
    </div>
  </div>
</div>

<div [formGroup]="formGroup">
  <div class="col-xs-12 col-sm-6 col-md-7 top-margin padding-top-life-15 padding-left-life-10">
    <rgi-rx-form-field [attr.data-qa]="definition.description + '_' +definition.id">
      <input type="checkbox" rgiRxInput formControlName="active">
      <label rgiRxLabel data-qa="fundItemLabel">{{definition.description}}</label>
    </rgi-rx-form-field>
    <!-- <pnc-checkbox formControlName="active" [attr.data-qa]="definition.description + '_' +definition.id" ></pnc-checkbox>
    <span data-qa="fundItemLabel"> {{definition.description}} </span> -->
  </div>
  <div class="col-xs-12 col-sm-6 col-md-5 padding-top-life-15">
    <div [ngClass]="{'col-xs-6 col-sm-4': showSliderInput, 'col-xs-6 col-sm-6': !showSliderInput}" class="switch-right">
      <label class="switch">
        <input style="position: fixed;" type="checkbox" formControlName="isPercent" class="form-control" (change)="onToggleClick()">
        <span class="slider" true="%" [attr.false]="currencyCode" ></span>
      </label>
    </div>
    <div [ngClass]="{'col-xs-6 col-sm-4': showSliderInput, 'col-xs-6 col-sm-6': !showSliderInput}">
      <label class="switch-value">
        <input #percent formControlName="percent" [value]="formGroup.get('percent').value" step="0.01" *ngIf="isPercent" class="form-control" placeholder="%" (blur)="roundValue('percent')" (keyup)="checkChar($event, 'percent')"/>
        <input #amount formControlName="amount" [value]="formGroup.get('amount').value" step="0.01" *ngIf="!isPercent" class="form-control" placeholder="{{currencyCode}}" (blur)="roundValue('amount')" (keyup)="checkChar($event, 'amount')"/>
      </label>
    </div>
    <div *ngIf="showSliderInput" class="col-sm-4">
      <input class="slider-top" *ngIf="isPercent" formControlName="percent" step="0.01" type="range" min="0" max="100">
      <input class="slider-top" *ngIf="!isPercent" formControlName="amount" step="0.01" type="range" min="0" max="{{totalAmount}}">
      <div class="col-md-6 col-xs-6">0</div>
      <div class="col-md-6 col-xs-6" style="text-align: right;">{{isPercent? '100' : formatter.setDecimalVal(totalAmount, 2) }}</div>
    </div>
  </div>
</div>
<div class="col-sm-12">
  <hr>
</div>

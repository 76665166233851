export const ROUTES_HOME = 'rgi.anag.home';
export const ROUTES_MODAL_HOME = 'rgi.anag.modal.home';
export const ROUTES_RESULT = 'rgi.anag.result';
export const ROUTES_MODAL_RESULT = 'rgi.anag.modal.result';
export const ROUTES_RESULT_HOMONYMY = 'rgi.anag.result.homonymy';
export const ROUTES_MODAL_RESULT_HOMONYMY = 'rgi.anag.modal.result.homonymy';
export const ROUTES_DETAIL = 'rgi.anag.detail';
export const ROUTES_FULL_DETAIL = 'rgi.anag.full.detail';
export const ROUTES_PARTY_EDITOR = 'rgi.anag.partyeditor';
export const ROUTES_KEY_DATA = 'rgi.anag.keydata';
export const ROUTES_TAX_DATA = 'rgi.anag.taxdata';

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Authorization Request</span>
        </h3>
      </div>
      <div class="modal-body">
        <ngb-tabset>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <span translate>Request</span>
            </ng-template>
            <ng-template ngbTabContent>

              <br>

              <div class="tbld tbld_contributions_taxes hidden-xs">

                <div class="tbld_row tbld_row_contributions_taxes_header">
                  <div class="tbld_col" translate>Authorization Request in insert phase</div>
                </div>

                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Request Date:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.requestDate | date:'dd/MM/yyyy' }}</b></div>
                  <div class="tbld_col" translate>Derogation Level:</div>
                  <div class="tbld_col" translate><b>{{ authorizationRequest?.derogationLevel }}</b>
                  </div>
                </div>
                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Applicant:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.applicant?.description }}</b>
                  </div>
                  <div class="tbld_col" translate>Company:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.company?.description }}</b></div>
                </div>
                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Node:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.salePoint?.description }}</b>
                  </div>
                </div>
              </div>

              <br *ngIf="authorizationRequest?.lastAction?.description
              && authorizationRequest?.competenceLevel?.description">

              <div class="tbld tbld_contributions_taxes hidden-xs"
                   *ngIf="authorizationRequest?.lastAction?.description
                   && authorizationRequest?.competenceLevel?.description">

                <div class="tbld_row tbld_row_contributions_taxes_header">
                <div class="tbld_col" translate>Request State</div>
                </div>

                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Last Action:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.lastAction?.description }}</b>
                  </div>
                  <div class="tbld_col" translate>Competence Level:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.competenceLevel?.description }}</b></div>
                </div>
                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Last Action Date:</div>
                  <div class="tbld_col" translate><b>{{ authorizationRequest?.lastActionDate }}</b>
                  </div>
                  <div class="tbld_col" translate>Request State:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.requestState?.description }}</b>
                  </div>
                </div>
                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Manual Notes:</div>
                  <div class="tbld_col" translate><b>{{ authorizationRequest?.manualNotes }}</b></div>
                </div>
              </div>

              <br>

              <div class="tbld tbld_contributions_taxes hidden-xs">

                <div class="tbld_row tbld_row_contributions_taxes_header" translate>
                  <div class="tbld_col" translate>
                    <span translate>Proposal on which the authorization has been requested:</span>
                    <span> {{ proposalNumber || '' }}</span>
                    <span translate> - </span>
                    <span translate>State:</span>
                    <span> {{ proposalState?.description || '' }} </span>
                  </div>
                </div>

                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Product Type:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.productType?.description }}</b>
                  </div>
                  <div class="tbld_col" translate>Product:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.product?.description }}</b></div>
                </div>
                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Subscriber:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.policyholder?.description }}</b>
                  </div>
                </div>
                <div class="tbld_row tbld_row_contributions_taxes">
                  <div class="tbld_col" translate>Proposal Description:</div>
                  <div class="tbld_col" translate>
                    <b>{{ authorizationRequest?.proposalDescription }}</b></div>
                </div>
              </div>

              <br>

              <div>
                <form [formGroup]="authorizationRequestForm">
                  <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                      <label translate>Priority:</label>
                      <select [compareWith]="compareGenericEntities" class="select-nativa"
                              formControlName="priority">
                        <option></option>
                        <option *ngFor="let priority of authorizationRequest?.priorities"
                                [ngValue]="priority">
                          {{ priority?.description }}</option>
                      </select>
                      <span
                        class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 col-xs-12">
                      <div>
                        <label translate>Notes: </label>
                      </div>
                      <div><textarea cols="50" formControlName="notes"
                                     rows="5">{{ authorizationRequest?.notes }}</textarea></div>
                    </div>
                  </div>
                  <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                      <button (click)="authorize()" class="btn btn-warning"
                              data-qa="btn-authorizazion-request-authorize"
                              translate>AUTHORIZE
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <br>

            </ng-template>
          </ngb-tab>

          <ngb-tab>
            <ng-template ngbTabTitle translate>
              <span translate>Notifications</span>
            </ng-template>
            <ng-template ngbTabContent>

              <br>

              <table class="table table-striped">
                <thead class="head-result">
                <tr>
                  <th scope="col">
                    <span></span>
                  </th>
                  <ng-container>
                    <th *ngFor="let header of tableHeader" scope="col">
                      <span translate> {{header.description}} </span>
                    </th>
                  </ng-container>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let colElement of arrayOfElements; let i = index">
                  <tr *ngIf="i % 2 == 0">
                    <div style="vertical-align: middle">
                      <button (click)="toggleAccordion(i)" class="btn btn-start-node" type="button">
                        <i *ngIf="!isNestedTableOpen(i)" class="glyphicon glyphicon-plus"></i>
                        <i *ngIf="isNestedTableOpen(i)" class="glyphicon glyphicon-minus"></i>
                      </button>
                    </div>
                    <td *ngFor="let element of arrayOfElements[i]" [ngSwitch]="element.type"
                        scope="row" style="vertical-align: middle">
                      <span *ngSwitchCase="'span'" class="tbld_col_value"
                            translate>{{element.description}}</span>
                    </td>
                  </tr>
                  <tr *ngIf="i % 2 > 0 && isNestedTableOpen(i)">
                    <td colspan="5">
                      <table class="table table-striped">
                        <tbody>
                        <tr *ngFor="let residualDiscounts of arrayOfElements[i]">
                          <td *ngFor="let element of residualDiscounts" [ngSwitch]="element.type"
                              scope="row"
                              style="vertical-align: middle">
                            <span *ngSwitchCase="'span'" class="tbld_col_value"
                                  translate>{{element.description}}</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-authorizazion-request-close" translate>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

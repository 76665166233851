<ng-container [formGroup]="dateForm" *ngIf="stateVcontDate">
  <rgi-gp-stepper [steps]="stateVcontDate.steps" [labels]="stateVcontDate.labels" [vertical]="vertical"
    [showIndex]="showIndex"></rgi-gp-stepper>

  <rgi-gp-group-policy-factors-component [factors]="stateVcontDate.factors" [parentForm]="dateForm"
    (updateFactors)="updateFactors($event)" class="rgi-gp-flex-row">
  </rgi-gp-group-policy-factors-component>

  <div data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="stateVcontDate.errors"
      *ngIf="!!stateVcontDate.errors && !!stateVcontDate.errors.length" [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button rgi-rx-button color="secondary" (click)="callActionClose()" data-qa="buttonsClose"
      translate>{{'_GP_._BTN_._CLOSE_'}}
    </button>
    <button rgi-rx-button color="primary" [disabled]="dateForm.invalid" (click)="callActionGoForword()"
      translate>{{'_GP_._BTN_._CONTINUE_'}}
    </button>
  </div>

</ng-container>
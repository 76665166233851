import {Injectable} from '@angular/core';
import {RoutingService} from '@rgi/rx/router';
import {Authorization} from "../resources/models/authorization";
import {Contract} from "../resources/models/contract";

@Injectable()
export class AuthorizationsCardIntegrationService {

  constructor(protected routingService: RoutingService) {
  }

  openNewReFlowSessionIssue(authorizationDetail: Authorization) {
    console.log('routing to other cards non implemented');
  }

  openGroupPolicySession(authorizationDetail: Authorization) {
    console.log('routing to other cards non implemented');
  }

  openPassPortalStandarSession(contractId: string) {
    console.log('routing to other cards non implemented');
  }

  openNMFSession(authorizationDetail: Authorization) {
    console.log('routing to other cards non implemented');
  }

  openNewReFlowSession(authorizationDetail: Authorization) {
    console.log('routing to other cards non implemented');
  }

  openNMFMandatoryDataSession(proposalNumber, policyNumber, createCopy, route, operation) {
    console.log('routing to other cards non implemented');
  }

  openNMFAuthorizationSession(createCopy: boolean, route: string, authorizationDetail: Authorization) {
    console.log('routing to other cards non implemented');
  }

  removeSession(routeId: any) {
    this.routingService.navigate(routeId);
  }

  modifyProposalLife(output, contract: Contract, title: string, idSession) {
    console.log('routing to other cards non implemented');
  }

  modifyVariationLife(authorizationDetail: Authorization, title: string, id: string) {
    console.log('routing to other cards non implemented');
  }

  confirmVariationLife(authorizationDetail: Authorization, contract: Contract, id: string) {
    console.log('routing to other cards non implemented');
  }

  goToLifeIssueResult(output, title: string, id: string) {
    console.log('routing to other cards non implemented');
  }

  openDefaultResolution(action, contract: Contract, authorizationDetail: Authorization) {
    console.log('routing to other cards non implemented');
  }
}

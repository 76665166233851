<div class="grid-item card card-height-normal card-main">
    <div class="font-size-no-modal">
        <div class="card shadow" *ngIf="this.isContextSpain">
            <fiscal-data [consult]="true" [feeData]="feeEntity"></fiscal-data>
        </div>
        <div class="card shadow">
            <div class="row">
                <div class="col-xs-12" style="font-size: 16px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ description }} </label>
                </div>
            </div>
            <div class="row">
                <!-- Numero fattura -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FEE_NUMBER' | translate }} </label>
                    <br>
                    <input type="text" class="form-control" name="feeNumberView" value="{{feeEntity.feeNumber}}"
                        disabled />
                </div>

                <!-- Data fattura -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FEE_DATE' | translate }} </label>
                    <br>
                    <span>{{feeEntity.feeDate| date:'dd/MM/yyyy'}}</span>
                </div>
            </div>

            <div class="row">
                <!-- Importo imponibile -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TAXABLE_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="taxableAmountView"
                        value="{{feeEntity.taxableAmount}}" disabled />
                </div>
                <!-- Non imponibile -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._NO_TAXABLE_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="notTaxableAmountView"
                        value="{{feeEntity.notTaxableAmount}}" disabled />
                </div>
            </div>

            <div class="row">
                <!-- IVA -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._IVA_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="ivaAmountView" value="{{feeEntity.ivaAmount}}"
                        disabled />

                </div>
                <!-- Ritenuta -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._WITH_HOLDING_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="withHoldingAmountView"
                        value="{{feeEntity.withHoldingAmount}}" disabled />
                </div>
            </div>

            <div class="row">
                <!-- Importo netto -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._NET_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="netAmountView" value="{{feeEntity.netAmount}}"
                        disabled />
                </div>
                <!-- Importo bolli -->
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="!(isLegalSpain() && this.isContextSpain)">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._DUTIES_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="bolliAmountView" value="{{feeEntity.bolliAmount}}"
                        disabled />
                </div>
            </div>

            <div class="row" *ngIf="!(isLegalSpain() && this.isContextSpain)">
                <!-- Importo cassa 1 -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._CASH_AMOUNT_1' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="cash1AmountView" value="{{feeEntity.cash1Amount}}"
                        disabled />
                </div>
                <!-- Importo cassa 2 / INPS -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._CASH_AMOUNT_2' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="cash2AmountView" value="{{feeEntity.cash2Amount}}"
                        disabled />
                </div>
            </div>

            <div class="row" *ngIf="!this.isContextSpain">
                <!-- Codice legale -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._LEGAL_CODE' | translate }} </label>
                    <br>
                    <input type="text" class="form-control" name="legalCodeView"
                        value="{{feeEntity.taxRegime.legalCode}}" disabled />
                </div>
                <!-- Regime fiscale -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FISCAL_TAX_REGIME' | translate }}
                    </label>
                    <br>
                    <input type="text" class="form-control" name="fiscalTaxRegimeView"
                        value="{{feeEntity.taxRegime.description}}" disabled />
                </div>
            </div>

            <div class="row">
                <!-- Importo totale -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="totalAmountView" value="{{feeEntity.totalAmount}}"
                        disabled />
                </div>
                <!-- Tipo fattura -->
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 2%;font-weight: bold;">{{ '_CLAIMS_._INVOICE_TYPE' | translate }}
                    </label>
                    <input type="text" class="form-control" id="feeTypeView" name="feeTypeView"
                        value="{{feeEntity.feeType}}" disabled />
                </div>
            </div>

        </div><!-- Chiusura CARD -->
    </div>
</div>
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ModalService} from '@rgi/rx/ui';
import {PaymentModalData, PaymentsModalComponent} from '../payments-modal/payments-modal.component';
import {cloneDeep} from 'lodash';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ContractPayment, EnumSettlementType, MeanOfPayment, PaymentConfig} from '../payments-data';
import {mapPayment} from '../adapter/utils/payments-utils';
import {Message} from '../../models/message';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'mic-rgi-payments-section',
  templateUrl: './payments-section.component.html',
  styleUrls: ['payments-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PaymentsSectionComponent implements OnInit, OnChanges {

  @Input() paymentConfig: PaymentConfig;
  @Input() isSectionDisabled = false;
  @Input()
  set selectablePaymentList(payments: MeanOfPayment[]) {
    this.selectablePayments = payments;
    this.creditMeans = this.getCreditMeans();
    this.debitMeans = this.getDebitMeans();
    this.setSelectedPayment();
  }

  @Output() setMeanPayment: EventEmitter<ContractPayment> = new EventEmitter();
  @Output() paymentErrorMessages: EventEmitter<Message> = new EventEmitter<Message>();

  paymentsForm: UntypedFormGroup;
  selectablePayments: MeanOfPayment[];
  creditMeans: MeanOfPayment[];
  debitMeans: MeanOfPayment[];

  constructor(
    protected modalService: ModalService,
    protected translate: TranslateService
  ) {
    this.paymentsForm = new UntypedFormGroup({
      debitRadio: new UntypedFormControl(undefined),
      creditRadio: new UntypedFormControl(undefined)
    });
  }

  paymentValidatorFn(paymentType: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const paymentsConfigured = paymentType === '1' ? this.paymentConfig.debMeansOfPayment : this.paymentConfig.credMeansOfPayment;
      if (paymentsConfigured.length === 0) {
        return null;
      }
      if (!this.selectablePayments || this.selectablePayments.length === 0){
        return {required: true};
      }
      const selectableByType = this.selectablePayments.filter(payment => payment.paymentConfig.paymentType === paymentType);

      let atLeastOneInConfig = false;
      paymentsConfigured.forEach( config => {
        const paymentFoundInConfig = selectableByType.find(payment =>
          payment.paymentConfig.meanOfPayment.id === config.paymentConfig.meanOfPayment.id);
        if (undefined !== paymentFoundInConfig && paymentFoundInConfig.selected){
          atLeastOneInConfig = true;
        }
      });
      if (!atLeastOneInConfig){
        return {meanOfPaymentNotValid: true};
      }
      return null;
    };
  }

  ngOnInit() {
    this.paymentsForm.get('debitRadio').setValidators([this.paymentValidatorFn('1')]);
    this.paymentsForm.get('creditRadio').setValidators([this.paymentValidatorFn('2')]);

    this.paymentsForm.statusChanges.subscribe(status => {
      if (status !== 'VALID') {
        const absentPaymentsmessage = new Message(
          'PAYMENTS',
          this.translate.instant('Add the mean of payment in order to proceed')
        );
        this.paymentErrorMessages.emit(absentPaymentsmessage);
      } else {
        this.paymentErrorMessages.emit();
      }
    });

    this.paymentsForm.get('debitRadio').updateValueAndValidity();
    this.paymentsForm.get('creditRadio').updateValueAndValidity();

    this.paymentsForm.get('debitRadio').valueChanges.subscribe(index => {
      this.selectPayment(this.debitMeans[index]);
    });
    this.paymentsForm.get('creditRadio').valueChanges.subscribe(index => {
      this.selectPayment(this.creditMeans[index]);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSectionDisabled && changes.isSectionDisabled.currentValue) {
      this.paymentsForm.disable({emitEvent: false});
    }
  }

  addPaymentMethod() {
    const modalData = new PaymentModalData(this.paymentConfig);
    if (modalData.config.debMeansOfPayment.length === 0 || this.debitMeans?.find(method => method.editable)) {
      modalData.isDebitBtnDisabled = true;
    }
    if (modalData.config.credMeansOfPayment.length === 0 || this.creditMeans?.find(method => method.editable)) {
      modalData.isCreditBtnDisabled = true;
    }
    this.openPaymentModal(modalData);
  }

  editPaymentMethod(paymentMethod: MeanOfPayment) {
    const modalData = new PaymentModalData(this.paymentConfig, paymentMethod);
    if (paymentMethod.paymentConfig.paymentType === '1') {
      modalData.isCreditBtnDisabled = true;
    } else {
      modalData.isDebitBtnDisabled = true;
    }
    this.openPaymentModal(modalData);
  }

  openPaymentModal(modalData: PaymentModalData) {
    const paymentModal = this.modalService.openComponent(PaymentsModalComponent, cloneDeep(modalData));
    paymentModal.modal.enableClickBackground = false;
    paymentModal.modal.onClose.subscribe(onCloseData => {
      if (onCloseData) {
        this.setMeanPayment.emit(onCloseData);
      }
    });
  }

  getDebitMeans() {
    if (this.selectablePayments) {
      return this.selectablePayments.filter(payment => payment.paymentConfig.paymentType === '1');
    }
    return [];
  }

  getCreditMeans() {
    if (this.selectablePayments) {
      return this.selectablePayments.filter(payment => payment.paymentConfig.paymentType === '2');
    }
    return [];
  }

  selectPayment(mean: MeanOfPayment) {
    this.selectablePayments = [];
    this.creditMeans = [];
    this.debitMeans = [];
    this.setMeanPayment.emit(
      mapPayment(
        mean.paymentConfig.meanOfPayment,
        mean.paymentConfig.paymentType,
        EnumSettlementType.SIGN_INSTALLMENT,
        mean.paymentConfig.paymentsFields
      )
    );
  }

  getSelected(paymentType: number) {
    if (this.selectablePayments && this.selectablePayments.length > 0) {
      switch (paymentType) {
        case 1:
          const debitIndex = this.debitMeans.findIndex(payment => payment.selected);
          return debitIndex > -1 ? '' + debitIndex : undefined;
        case 2:
          const creditIndex = this.creditMeans.findIndex(payment => payment.selected);
          return creditIndex > -1 ? '' + creditIndex : undefined;
      }
    }
    return undefined;
  }

  getLabel(payment: MeanOfPayment) {
    const paymentFields = payment.paymentConfig.paymentsFields;
    const paymentLabel = payment.paymentConfig.meanOfPayment.description;
    const holderField = paymentFields ? paymentFields.find(field => field.name.toUpperCase() === 'CHOLDER') : undefined;
    switch (payment.paymentConfig.meanOfPayment.code) {
      case 'BNKTRN':
      case 'DDO':
        if (paymentFields) {
          const ciban = paymentFields.find(field => field.name.toUpperCase() === 'CIBAN');
          const holder = holderField && holderField.value ? '- ' + holderField.value + ' - ' : '';
          if (ciban) {
            const ibanLabel = ciban.label ? ciban.label : '';
            const ibanValue = ciban.value ? ciban.value : '';
            return `${paymentLabel} ${holder} ${ibanLabel} ${ibanValue}`;
          }
        }
        return paymentLabel;
      case 'CARD':
        const cardTypeField = paymentFields.find(field => field.name.toUpperCase() === 'IDCREDITCARD');
        let cardDescription = paymentLabel;
        if (cardTypeField) {
          const cardTypeObj = cardTypeField.values.find(value => '' + value.id === cardTypeField.value);
          cardDescription += ' ' + (cardTypeObj ? cardTypeObj.description : '');
        }
        const cardHolder = holderField && holderField.value ? '- ' + holderField.value + ' ' : '';
        const cardExpire = paymentFields.find(field => field.name.toUpperCase() === 'DCARDEXPE').value;
        const cardExpireDate = cardExpire ? new Date(cardExpire) : undefined;
        const cardMonth = cardExpireDate ? cardExpireDate.getMonth() + 1 : undefined;
        const cardYear = cardExpireDate ? cardExpireDate.getFullYear() : undefined;
        return `${cardDescription} ${cardHolder} ${cardExpireDate ? '- ' + cardMonth + '/' + cardYear : ''}`;
      default:
        return paymentLabel;
    }
  }

  canAddPayment() {
    if (this.selectablePayments) {
      /**
       * for every type of payments (debit or credit) configured,
       * if there is already one selectable payment of the same type which is editable (therefore added by the user)
       * it is not possible to insert other payments method
       **/
      return !Object.keys(this.paymentConfig).every((paymTypeKey: string) => {
        let configuredPayment: MeanOfPayment = this.paymentConfig[paymTypeKey].length > 0 ? this.paymentConfig[paymTypeKey][0] : null;
        if (configuredPayment) {
          return this.selectablePayments.some(selectablePayment => {
            return configuredPayment.paymentConfig.paymentType === selectablePayment.paymentConfig.paymentType && selectablePayment.editable;
          })
        } else {
          return true;
        }
      })
    }
    return true;
  }

  setSelectedPayment() {
    this.paymentsForm.get('debitRadio').setValue(this.getSelected(1), {emitEvent: false});
    this.paymentsForm.get('creditRadio').setValue(this.getSelected(2), {emitEvent: false});
    this.paymentsForm.updateValueAndValidity();
  }

  checkAndSendErrorMessages() {
    if (!this.paymentsForm.valid) {
      const absentPaymentsmessage = new Message(
        'PAYMENTS',
        this.translate.instant('Add the mean of payment for debit and credit in order to proceed')
        );
      absentPaymentsmessage.mandatory = true;
      this.paymentErrorMessages.emit(absentPaymentsmessage);
    } else {
      this.paymentErrorMessages.emit();
    }
  }
}

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Variable} from '../../models/domain-models/variable';
import {RgiRxLogger} from '@rgi/rx';

@Component({
  selector: 're-issue-variables',
  templateUrl: './re-issue-variables.component.html'
})
export class ReIssueVariablesComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() variablesType = 'PARTY';
  @Input() variablesRows: Variable[][];

  @Output() variablesFormChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() validFormVariables: EventEmitter<any> = new EventEmitter();
  @Input() variablesValid: boolean;

  filteredVariables: Array<Variable>;
  variablesForm: UntypedFormGroup;
  internalIsSubmitted: boolean;

  private variablesInternal: Array<Variable>;

  @Input() set variables(variables: Array<Variable>) {
    this.logger.debug('ReIssueVariablesComponent - set variables', variables);
    this.variablesInternal = variables;
    this.updateVariablePresentation(variables);
  }

  get variables(): Array<Variable> {
    return this.variablesInternal;
  }

  get isSubmitted(): boolean {
    return this.internalIsSubmitted;
  }

  @Input()
  set isSubmitted(value: boolean) {
    if (value) {
      this.variablesForm.markAsTouched();
      this.variablesForm.markAllAsTouched();
    }
    this.internalIsSubmitted = value;
  }

  constructor(
    protected logger: RgiRxLogger,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    if (this.variablesForm) {
      this.validFormVariables.emit(this.variablesForm.valid);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.variables && changes.variables.currentValue && !changes.variables.previousValue) {
    //   setTimeout(() => {
    //     this.updateVariablePresentation(changes.variables.currentValue);

    //     changes.variables.currentValue.forEach(element => {
    //       this.onVariableChange(element);
    //     });
    //   }, 200);
    // }
    // const variable = changes.variables;
    // if (variable && variable.currentValue && variable.previousValue &&
    //   variable.currentValue.length < variable.previousValue.length) {

    //   this.validFormVariables.emit(false);
    // }
  }

  private updateVariablePresentation(variables: Array<Variable>) {
    // TODO: check if needed
    if (variables) {
      this.filterVariables(variables);
    }
    this.loadVariables(variables);
    if (this.variablesForm) {
      if (this.internalIsSubmitted) {
        this.variablesForm.markAsTouched();
        this.variablesForm.markAllAsTouched();
      }
      this.validFormVariables.emit(this.variablesForm.valid);
    }
  }

  protected filterVariables(vars: Array<Variable>) {
    vars.filter(
      (variable) => {
        if (!this.variablesType) {
          return true;
        } else {
          if (this.variablesType === 'ASSET') {
            return variable.visibilityArea === 0
              || variable.visibilityArea === 1
              || variable.visibilityArea === 4;
          } else if (this.variablesType === 'PARTY') {
            return variable.visibilityArea === 2;
          } else if (this.variablesType === 'EXTRA') {
            return variable.visibilityArea === 3;
          } else {
            return false;
          }
        }
      }
    );
  }

  loadVariables(vars: Array<Variable>) {
    if (vars) {
      this.variablesForm = this.toFormGroup(vars);
    } else {
      this.variablesForm = null;
    }

    const variablesNum = vars ? vars.length : 0;

    if (variablesNum === 0) {
      return;
    }

    vars.sort(
      (a: Variable, b: Variable) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 0;
        }
      }
    );

    this.variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 2));

    let i: number;
    let j = 0;

    for (i = 0; i < variablesNum; i++) {
      if (i !== 0 && i % 2 === 0) {
        j++;
      }
      if (!this.variablesRows[j]) {
        this.variablesRows[j] = [];
      }
      this.variablesRows[j].push(vars[i]);
    }
  }

  variablesTrackByFn(variable: Variable) {
    return variable.code;
  }

  getVariablesType() {

    if (this.variablesType === 'ASSET') {
      return 'Asset';
    } else if (this.variablesType === 'PARTY') {
      return 'Party';
    } else if (this.variablesType === 'EXTRA') {
      return 'Extra';
    } else {
      return '';
    }
  }

  onVariableChange(event: any) {
    this.variablesValid = this.variablesForm.valid;
    this.variablesFormChangeEmitter.emit(event);
    let isAllCompleted = true;
    Object.keys(this.variablesForm.controls).forEach(key => {
      const variable = this.variables.find(vari => {
        return vari.code === key;
      });
      if ((this.variablesForm.controls[key].value === '-1' || this.variablesForm.controls[key].value === '') && variable.compulsory) {
        isAllCompleted = false;
      }
    });

    if (!isAllCompleted) {
      this.variablesForm.markAsDirty();
      this.variablesForm.markAsTouched();
      this.variablesForm.markAllAsTouched();
    }

  }

  toFormGroup(variables: Variable[]) {
    const group: any = {};

    variables.forEach(variable => {

      variable.dependent = true;

      let value;
      if (variable.type === 0) {
        if (variable.variableClass === 7 && variable.valueDescription) {
          // CITY & PROVINCE
        } else {
          value = variable.value;
        }

      } else if (variable.type === 4) {
        if (variable.value) {
          const date: Array<string> = variable.value.split('/');
          const day = Number(date[0]);
          const month = Number(date[1]) - 1;
          const year = Number(date[2]);
          value = new Date(year, month, day);
        } else {
          value = null;
        }
      } else {
        value = variable.value != null ? variable.value.replace(',', '.') : variable.value || '';
      }
      const formControl = new UntypedFormControl(
        {
          value: value,
          disabled: !variable.editable
        }, variable.compulsory ? Validators.required : null);
      group[variable.code] = formControl;
      formControl.setValidators(this.variableValidator(variable));
    });
    return new UntypedFormGroup(group);
  }

  variableValidator(variable: Variable): any {// ValidatorFn {


    return null;
    // return (control: AbstractControl): {
    //   [key: string]: any } | null => {
    //   if (variable.compulsory) {
    //     if (!control.value || control.value === '-1') {
    //       return {required: true};
    //     }
    //     return null;
    //   }
    // };
  }

}


    <div class="modal-header">
        <div class="modal-title" translate>lpc_guarantees_oveview</div>
      </div>
      <div class="modal-body">
        <div class="row">
          <lpc-warranty-detail *ngIf="showFirstInstallment && !!instalments.totFirstInstalment" [tot]="instalments.totFirstInstalment" [instalment]="instalments.firstInstalmentSection"></lpc-warranty-detail>
          <lpc-warranty-detail *ngIf="!!instalments.totNextInstalment" [tot]="instalments.totNextInstalment" [instalment]="instalments.nextInstalmentSection"></lpc-warranty-detail>
          <lpc-warranty-detail *ngIf="!!instalments.totAnnualPremium" [tot]="instalments.totAnnualPremium" [instalment]="instalments.annualPremiumSection"></lpc-warranty-detail>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified"
          style="margin-top: 0px !important; border: 0px !important; border-right: 0px !important;">
          <div class="btn-group">
            <button class="btn btn-warning" data-qa="close-button" (click)="closeModal()" translate>lpc_close_button</button>
          </div>
        </div>
      </div>


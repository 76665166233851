import {Component, OnInit} from '@angular/core';
import {
  ReIssueReprintMovementsNavigation,
  ReIssueReprintMovementsState,
  ReIssueReprintMovementsStateManagerService,
  ReprintDocumentsStepperSection,
  reprintDocumentStepperLabels
} from '../re-issue-state-manager/re-issue-reprint-movements-state-manager.service';
import {RgiRxLogger} from '@rgi/rx';
import {PortfolioMovement} from '../../models/api-models/portfolio-movement';
import {SessionService} from '../re-issue-utility/session.service';
import {TitlesUtilityService} from '../re-issue-utility/titles-utility.service';
import {take} from 'rxjs/operators';
import {ReIssueTitlesResourceService} from '../re-issue-resources/re-issue-titles-resource.service';
import {Message} from '../../re-issue-constants/msg';
import {Contract, Movement} from '../../models/api-models/ptfdanni-resources';

@Component({
  selector: 're-issue-reprint-movements',
  templateUrl: './re-issue-reprint-movements.component.html',
  styleUrls: ['./re-issue-reprint-movements.component.css']
})
export class ReIssueReprintMovementsComponent implements OnInit {

  stepperLabels = reprintDocumentStepperLabels;
  currentStep = ReprintDocumentsStepperSection.selection;
  titleTableColumns = ['checkBox', 'description', 'effectDate', 'covertureDate', 'outcome'];
  selectedMovement: PortfolioMovement;
  errorMessages: Message[] = [];
  contract: Contract;
  movements: Movement[] = [];
  titlesMap: string[] = [];

  constructor(
    public stateManager: ReIssueReprintMovementsStateManagerService<ReIssueReprintMovementsState>,
    protected logger: RgiRxLogger,
    protected sessionService: SessionService,
    protected titlesUtilityService: TitlesUtilityService,
    protected titlesService: ReIssueTitlesResourceService
  ) {
  }

  ngOnInit() {
    this.contract = this.stateManager.activeRoute.getRouteData<ReIssueReprintMovementsNavigation>().contract;
    let titles = this.stateManager.activeRoute.getRouteData<ReIssueReprintMovementsNavigation>().titles;
    if (!titles) {
      const node = this.contract.productionNode && this.contract.productionNode.code ?
        this.contract.productionNode.code : '';
      if (this.contract.policyNumber && node) {
        const bodyContent = {
          policyNumber: this.contract.policyNumber,
          managementNodeCode: node,
          considerSubTreeOfManagementNode: true
        };
        this.titlesService.getTitles$(bodyContent).pipe(take(1)).subscribe((val: any) => {
          titles = val.output;
          this.arrangeMovementsAndTitles(titles);
        });
      }
    } else {
      this.arrangeMovementsAndTitles(titles);
    }
  }

  arrangeMovementsAndTitles(titles: any[]) {
    this.stateManager.getPolicyMovementList$().subscribe(result => {
      for (const mov of result.movimentoList) {
        for (const polMov of this.contract.allMovements) {
          if (mov.objectId + '' === polMov.objectId) {
            this.movements.push(polMov);
            break;
          }
        }
      }
      for (const el of this.movements) {
        for (const title of titles) {
          if (title.securityId === el.idSecurity) {
            this.titlesMap[el.objectId] = this.titlesUtilityService.getTitleEnumMap().get(title.statusCode);
          }
        }
      }
    });
  }

  reprintMovement() {
    if (!this.selectedMovement) {
      this.errorMessages = [];
      this.errorMessages.push(new Message('re-reprint-movements', 'Select one movement'));
    } else {
      this.stateManager.reprintMovement(this.selectedMovement);
    }
  }

  selectMovement(movement: PortfolioMovement) {
    console.log(movement);
    this.selectedMovement = movement;
  }

  exit() {
    this.sessionService.remove(this.stateManager.activeRoute.id);
  }

}

import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PncPsalesRequiredDataComponent} from './steps/required-data/required-data.component';
import {ActiveRoute, Routes, ROUTES} from '@rgi/rx/router';
import {PncPostSalesIntegrationService} from './services/pnc-postsales-integration.service';
import {FormFieldsComponent} from './components/form-fields/form-fields.component';
import {flatten, RgiRxFormModule, RgiRxPushMessageHandlerService, RgiRxQualityAssuranceModule} from '@rgi/rx';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  RGI_RX_DATA_TABLE_PIPE,
  RgiRxDatePickerModule, RgiRxDragDropModule,
  RgiRxFormElementsModule,
  RgiRxMenuModule,
  RgiRxModalModule,
  RgiRxMultiselectModule,
  RgiRxPanelModule,
  RgiRxSnackbarModule,
  RgiRxStepperModule,
  RgiRxTableModule,
  RGI_RX_DROP_SELECTION_HANDLER
} from '@rgi/rx/ui';
import {PNC_PSALES_TRANSLATIONS} from './i18n/translations';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {PncPsalesSectionTitleComponent} from './components/section-title/section-title.component';
import {PncPsalesSummaryComponent} from './steps/summary/summary.component';
import {PncPsalesGenericDetailComponent} from './components/generic-detail/generic-detail.component';
import {PncPsalesConfirmationComponent} from './steps/confirmation/confirmation.component';
import {PncQuotationHeaderComponent} from './components/pnc-quotation-header/pnc-quotation-header.component';
import {
  PncQuotationHeaderInstallmentComponent
} from './components/pnc-quotation-header/pnc-quotation-header-installment/pnc-quotation-header-installment.component';
import {
  PncQuotationMenuComponent
} from './components/pnc-quotation-header/pnc-quotation-menu/pnc-quotation-menu.component';
import {
  PncTotalsPremiumModalComponent
} from './components/pnc-quotation-modals/pnc-totals-premium-modal/pnc-totals-premium-modal.component';
import {
  PncAccountingDetailsModalComponent
} from './components/pnc-quotation-modals/pnc-accounting-detail-modal/pnc-accounting-details-modal.component';
import {
  PncCommissionsModalComponent
} from './components/pnc-quotation-modals/pnc-commissions-modal/pnc-commissions-modal/pnc-commissions-modal.component';
import {
  PncPsalesConfirmationModalComponent
} from './components/modals/pnc-confirmation-modal/confirmation-modal.component';
import {PNC_POSTSALES_OPERATIONS, PNC_PSALES_OP_CONTEXT, PostSalesOperation} from './api/rgi-pnc-postsales.api';
import {StateStoreService} from '@rgi/rx/state';
import {PncPostSalesOrchestratorService} from './orchestrator/pnc-postsales-orchestrator.service';
import {RgiPncPsalesStateManager} from './api/rgi-pnc-postsales-state.manager';
import {PncPsalesRouteWrapperComponent} from './api/pnc-route-wrapper/pnc-psales-route-wrapper.component';
import {PncPsalesHttpErrorService} from './services/pnc-postsales-http-error.service';
import {ROUTES_CONFIRMATION} from './resources/constants/routes';
import {PncPsalesTechnicalAccountingComponent} from './steps/technical-accounting/technical-accounting.component';
import {
  TechAccountingDataTableComponent
} from './components/tech-accounting-data-table/tech-accounting-data-table.component';
import {PncDocumentsComponent} from './components/pnc-documents/pnc-documents.component';
import {PncPsalesMovementsComponent} from './steps/movements/movements.component';
import {MovementsDataTableComponent} from './components/movements-data-table/movements-data-table.component';
import {
  PncPsalesSupplementaryTechAccTransactionComponent
} from './steps/supplementary-tech-acc-transaction/supplementary-tech-acc-transaction.component';
import {
  PncPsalesSupplementaryTechAccTableComponent
} from './components/supplementary-tech-acc-table/supplementary-tech-acc-table.component';
import {RgiCountryLayerContext, RgiCountryLayerModule, RgiCountryLayerNumberFormat} from '@rgi/country-layer';
import {PncPsalesTaxesDetailComponent} from './components/pnc-taxes-detail/pnc-taxes-detail.component';
import {
  PncPolicyDetailModalComponent
} from './components/pnc-quotation-modals/pnc-policy-detail-modal/pnc-policy-detail-modal.component';
import {rxCountryLayerNumberFormatPipe} from './pipes/country-layer-number-format-datatable';
import {
  PncNodeSelectionModalComponent
} from './components/modals/pnc-node-selection-modal/pnc-node-selection-modal.component';
import {PNC_ACTIONS} from './resources/constants/actions';

export function provideOperations(psalesOperations: PostSalesOperation[][]): Routes {
  const routes = [];
  flatten(psalesOperations).forEach(op => {
    op.steps.forEach((step, index) => {
      const stepCtx = {
        operationType: op.type,
        stepLabels: op.steps.map(s => s.stepLabel),
        viewComponent: step.step.component,
        currentStep: index,
        ...step.context,
      };
      const deps = [ActiveRoute, StateStoreService, PncPostSalesOrchestratorService, PncPostSalesIntegrationService,
        RgiRxPushMessageHandlerService, PncPsalesHttpErrorService, PNC_PSALES_OP_CONTEXT].concat(step.step.deps || []).concat(step.deps || []);
      let route = 'pncpsales/' + op.type + '/';
      route += op.operationCode ? op.operationCode + '/' : '';
      route += step.step.routeName;
      const component = step.step.routeName === ROUTES_CONFIRMATION ? PncPsalesConfirmationComponent : PncPsalesRouteWrapperComponent;
      const extendedMgrProvider = step.manager !== step.step.manager ? [{
        provide: step.step.manager,
        useExisting: step.manager
      }] : [];
      const providers = [
        {provide: PNC_PSALES_OP_CONTEXT, useValue: stepCtx},
        {
          provide: step.manager,
          useClass: step.manager,
          deps
        },
        {
          provide: RgiPncPsalesStateManager,
          useExisting: step.manager,
        }
      ].concat(extendedMgrProvider);
      routes.push({
        route,
        component,
        context: stepCtx,
        providers
      });
    });
  });
  return routes;
}

@NgModule({
  declarations: [
    PncPsalesRequiredDataComponent,
    FormFieldsComponent,
    PncPsalesSectionTitleComponent,
    PncPsalesSummaryComponent,
    PncPsalesGenericDetailComponent,
    PncPsalesConfirmationComponent,
    PncQuotationHeaderComponent,
    PncQuotationHeaderInstallmentComponent,
    PncQuotationMenuComponent,
    PncTotalsPremiumModalComponent,
    PncAccountingDetailsModalComponent,
    PncCommissionsModalComponent,
    PncPsalesConfirmationModalComponent,
    PncPsalesRouteWrapperComponent,
    PncPsalesTechnicalAccountingComponent,
    TechAccountingDataTableComponent,
    PncDocumentsComponent,
    PncPsalesMovementsComponent,
    MovementsDataTableComponent,
    PncPsalesSupplementaryTechAccTransactionComponent,
    PncPsalesSupplementaryTechAccTableComponent,
    PncPsalesTaxesDetailComponent,
    PncPolicyDetailModalComponent,
    PncNodeSelectionModalComponent
  ],
  exports: [
    FormFieldsComponent,
    PncPsalesSectionTitleComponent,
    PncPsalesConfirmationComponent,
    PncPsalesRequiredDataComponent,
    PncPsalesSummaryComponent,
    PncPsalesTechnicalAccountingComponent,
    PncDocumentsComponent,
    PncPsalesMovementsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RgiRxFormModule,
    RgiRxFormElementsModule,
    RgiRxDatePickerModule,
    RgiRxSnackbarModule,
    RgiRxI18nModule.forRoot(PNC_PSALES_TRANSLATIONS),
    RgiRxMenuModule,
    RgiRxPanelModule,
    RgiRxModalModule,
    RgiRxTableModule,
    RgiRxStepperModule,
    RgiRxQualityAssuranceModule,
    RgiRxMultiselectModule,
    RgiCountryLayerModule,
    RgiRxDragDropModule
  ],
  providers: [
    PncPostSalesIntegrationService,
    {
      provide: RGI_RX_DATA_TABLE_PIPE,
      multi: true,
      useFactory: rxCountryLayerNumberFormatPipe,
      deps: [RgiCountryLayerContext, RgiCountryLayerNumberFormat]
    },
    {
      provide: RGI_RX_DROP_SELECTION_HANDLER,
      useValue: {
        name: PNC_ACTIONS.NODE_SELECTION,
        handler: PncNodeSelectionModalComponent
      },
      multi: true
    }
  ]
})
export class RgiPncPostsalesModule {
  static withOperation(operations: PostSalesOperation[]): ModuleWithProviders<RgiPncPostsalesModule> {
    return {
      ngModule: RgiPncPostsalesModule,
      providers: [
        {
          provide: ROUTES,
          useFactory: provideOperations,
          deps: [PNC_POSTSALES_OPERATIONS],
          multi: true
        },
        {
          provide: PNC_POSTSALES_OPERATIONS,
          useValue: operations,
          multi: true
        }
      ]
    };
  }
}

import { Injectable } from '@angular/core';
import { AnswerFlatI, QuestionFlatI, QuestionnaireFlatI, ValueFlatI } from '../models/questionnaireFlat';
import { AnswerContainerI, AnswerType, AnswerValueI, QuestionAnswerI, QuestionnaireI } from '../models';
import { SectionQuestionI } from '../models/section-question';

@Injectable()
export class QuestionnaireConverterService {


    public convertFlatToQuestionnaire(questionnaireFlat: QuestionnaireFlatI, disabledQuestions: Array<{ code: string }>): QuestionnaireI {

        let questionnaire: QuestionnaireI = {
            sections: [],

        };

        questionnaire.sections = questionnaireFlat.questions.reduce(
            (sections: SectionQuestionI[], questionFlat: QuestionFlatI) => {
                let section = this.findSectionByQuestionFlat(questionFlat, sections);

                if (!section) {
                    section = this.convertQuestionFlatToQuestionnaireSection(questionFlat);
                    sections = [...sections, section];
                }

                let question: QuestionAnswerI = this.convertQuestionFlatToQuestionSectionVersion(questionFlat, disabledQuestions);

                question.answers = questionFlat.answers.map(this.convertAnswerFlatToQuestionnaireAnswer);
                section.questions.push(question);

                return sections;
            },
            []
        );

        return questionnaire;
    }


    protected findSectionByQuestionFlat(questionFlat: QuestionFlatI, sections: SectionQuestionI[]): SectionQuestionI {
        return sections.find(
            (questionnaireSectionVersion: SectionQuestionI) =>
                questionFlat.section && questionnaireSectionVersion.questionnaireSection.id == parseInt(questionFlat.section.id)
        );
    }


    protected convertQuestionFlatToQuestionnaireSection(questionFlat: QuestionFlatI): SectionQuestionI {
        return {
            questions: [], //Array<QuestionAnswerI>,
            text: questionFlat.text,
            order: questionFlat.order,
            questionnaireSection: {
                name: questionFlat.section ? questionFlat.section.name : undefined,
                question: {
                    code: questionFlat.code,
                    description: questionFlat.text,
                    id: parseInt(questionFlat.id),
                },
                description: questionFlat.text,
                id: questionFlat.section ? parseInt(questionFlat.section.id) : undefined,
                hasNotes: questionFlat.section && questionFlat.section.hasNotes
            }
        };
    }


    protected convertQuestionFlatToQuestionSectionVersion(questionFlat: QuestionFlatI, disabledQuestions: Array<{ code: string }>): QuestionAnswerI {
        const questionAnswer = {
            question: {
        		code: questionFlat.code/*.indexOf(".") > 0 ? questionFlat.code.split(".")[1] : questionFlat.code*/,
                description: questionFlat.text,
                id: parseInt(questionFlat.id),
            },
            text: questionFlat.text,
            answers: [], //Array<AnswerContainerI>;
            status: questionFlat.status,
            visible: questionFlat.visible,
            isValid: true,
            isDisabled: false,
            conditionsFlat: questionFlat.conditions,
            compulsory: questionFlat.compulsory,
            hasNotes: questionFlat.hasNotes
        };
    	questionAnswer.isDisabled = disabledQuestions ? disabledQuestions.some(quest => this.findExcludedQuestionsByCode(questionAnswer.question.code,quest.code)) : false;
        return questionAnswer;
    }

  protected findExcludedQuestionsByCode(questToCheck : String, questExcluded: String)
  {
    return questExcluded.indexOf(".")>0 ? questToCheck === questExcluded : questToCheck.split(".")[questToCheck.split(".").length-1] === questExcluded;
  }

    protected convertAnswerFlatToQuestionnaireAnswer(answerFlatI: AnswerFlatI): AnswerContainerI{
        return {
            answer: {
                code: answerFlatI.factorCode,
                answerValues: answerFlatI.values.map(
                    (valueFlatI: ValueFlatI) => {
                        return {
                            id: parseInt(valueFlatI.id),
                            order: valueFlatI.order,
                            value: valueFlatI.description
                        };
                    }
                ) as AnswerValueI[],

                passProFactor: '',//TODO answerFlatI.ppFactorCode,
                type: answerFlatI.factorType.toString() as AnswerType
            },
            value: answerFlatI.value,
            multivalue: answerFlatI.multivalue
        };
    }

}

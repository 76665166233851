export class Brand {

  constructor(
    public brandCode: string,
    public description: string,
    public creationDate: Date,
    public updatingDate: Date
  ) {
  }
}

export class InstalmentDate {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public selected: boolean,
  ) {
  }

}

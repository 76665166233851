import { LpcTranslationsKeys } from './lpc-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_EN: LpcTranslationsKeys = {
  lpc_Investment_profiles: 'Investment profiles',
  lpc_payment_method: 'Payment method',
  lpc_payment_type: 'Payment type',
  lpc_investment_funds: 'Investment funds',
  lpc_fund_type: 'Fund Type',
  lpc_profile_type: 'Profile Type',
  lpc_elements: ' elements',
  lpc_fund_name: 'Fund Name',
  lpc_profile_name: 'Profile Name',
  lpc_profiles: 'Profiles',
  lpc_funds: 'Funds',
  lpc_division_type: 'Division type',
  lpc_occurenceNumber: 'The Occurence number must be greater than {{min}}',
  lpc_division_description: 'Allocation description',
  lpc_start_validity_date: 'Start validity date',
  lpc_fund_description: 'Fund Description',
  lpc_ISIN_code: 'ISIN Code',
  lpc_Allocation: 'Allocation',
  lpc_fields_generic_error: 'The mandatory fields are incomplete or contain incorrect values',
  lpc_profile_details: 'Profile details',
  lpc_portfolio_fund_display: 'Portfolio fund display',
  lpc_payment_data: 'Payment data',
  lpc_Warning_you_are_deleting_the_contractor: 'Warning, you are deleting the contractor. Do you want to proceed?',
  lpc_Investment_amount: 'Investment amount:',
  lpc_mov_status: 'Status Title',
  lpc_mov_Amount: 'Title Amount',
  lpc_ticket_status_label: 'Pending valuation of quotas',
  lpc_status_investment_label: 'Investment Status',
  lpc_order_status_label: 'Order Status',
  lpc_selected_funds: 'Selected funds',
  lpc_Premium: 'Premium',
  lpc_InvestmentByFund: 'Investment by fund',
  lpc_Loading: 'Fees',
  lpc_NetPremium: 'Net Premium',
  lpc_IntFract: 'Int. Fract.',
  lpc_Fees: 'Fixed charges',
  lpc_Taxes: 'Taxes',
  lpc_GrossPremium: 'Gross Premium',
  lpc_FirstInstallment: 'First Installment',
  lpc_AnnualPremium: 'Annual Premium',
  lpc_NextInstallment: 'Next Installment',
  lpc_Guarantee: 'Cover',
  lpc_BenefitOnDate: 'Benefit on Date',
  lpc_Benefit: 'Benefit',
  lpc_AnnualSinglePremium: 'Annual/Single Premium',
  lpc_ActiveGuarantees: 'Active Covers',
  lpc_Enhancement: 'Financial data',
  lpc_CalculationData: 'Date of calculation',
  lpc_ControvaloreAllaData: 'Counter value at the date',
  lpc_AccumulationOfPrizes: 'Sum of premiums',
  lpc_TotalSurrender: 'Total surrender',
  lpc_AccumulationOfReducedPremiums: 'Sum of reduced premiums',
  lpc_TotalBalance: 'Total balance',
  lpc_EffectiveDate: 'Effective Date',
  lpc_Movement: 'Movement',
  lpc_Movements: 'Movements',
  lpc_booking_number: 'Booking number',
  lpc_booking_policyData: 'Policy Data',
  lpc_booking_assetData: 'Asset Data',
  lpc_PolicyLinked: 'Linked Policies',
  lpc_Motivo_Legame: 'Link Type',
  lpc_ProposalIssueDate: 'Proposal Issue Date',
  lpc_PolicyIssueDate: 'Policy Issue Date',
  lpc_ExpirationDate: 'Expiration Date',
  lpc_NextInstalmentDate: 'Next Installment Date',
  lpc_found_duplicate: 'Warning',
  lpc_duplicate_subject_message: 'Selected subject already present among the beneficiaries',
  lpc_SelectSettlement: 'Select Settlement',
  lpc_You_must_select_the_settlement_to_perform_the_variation: 'You must select the settlement to perform the variation',
  lpc_beneficiaryPercentage: 'Percentage for subject',
  lpc_beneficiaryPercentage_greater: 'must be greater than',
  lpc_beneficiaryPercentage_lower: 'must be lower than',
  lpc_beneficiaryDuplicate: 'Duplicate subject',
  lpc_NoOperations: 'No action available',
  lpc_confirm: 'Confirm',
  lpc_description: 'Description',
  lpc_issueDate: 'Issue Date',
  lpc_effectiveDate: 'Effective Date',
  lpc_MovementData: 'Cancel Appendix',
  lpc_Are_you_sure_you_want_to_confirm_this_claim: 'Are you sure you want to confirm this claim?',
  lpc_Are_you_sure_you_want_to_cancel_this_claim: 'Are you sure you want to cancel this claim?',
  lpc_warnings: 'Warnings',
  lpc_warning: 'Warning',
  lpc_warning_message: 'Click \'AUTHORIZATION REQUEST\' to forward an approval request for the operation',
  lpc_ClaimCanceled: 'Claim successfully canceled',
  lpc_ClaimConfirmed: 'Claim successfully confirmed',
  lpc_reserve: 'save',
  lpc_questionnaire: 'Questionnaire',
  lpc_bonus_amount: 'Bonus amount',
  lpc_life_detail_beneficiary: 'Beneficiary',
  lpc_life_detail_percentage: 'Percentage',
  lpc_life_detail_net_amount: 'Net amount',
  lpc_life_detail_questionnaire: 'Questionnaire',
  lpc_life_detail_beneficiaries: 'Beneficiaries',
  lpc_AppendixNumber: 'Appendix Number',
  lpc_nessuna_variazione: 'No variation',
  lpc_appendixLabel: 'Beneficiary Appendix',
  lpc_operation_rescission_reason: 'Rescission Reason',
  lpc_Lien_type_creditor: 'Creditor type',
  lpc_creditor: 'Creditor',
  lpc_Open_lien: 'Open lien',
  lpc_assignment_holder_type: 'Assignment holder type',
  lpc_assignment_holder: 'Assignment holder',
  lpc_assignment_amount: 'Amount',
  lpc_assignment_policy_number: 'Contract number',
  lpc_assignment_company: 'Company',
  lpc_assignment_type: 'Assignment type',
  lpc_assignment_benefit: 'Benefit',
  lpc_amount_less_of_benefit: 'Amount must be less of benefit.',
  lpc_open_assignment: 'Open assignment',
  lpc_sum_det_cancel_proposal: 'cancel proposal',
  lpc_sum_det_success_cancel: 'Proposal is successfully cancelled',
  lpc_sum_det_success_cancel_prev: 'Quote is successfully cancelled',
  lpc_sum_det_confirm_cancel: 'Do you confirm that the proposal will be cancelled?',
  lpc_sum_det_confirm_cancel_prev: 'Do you want to confirm the cancellation of this quote?',
  lpc_sum_det_confirm_prop_issue: 'Proposal will be issued. Do you confirm?',
  lpc_sum_det_confirm_pol_issue: 'Policy will be issued. Do you confirm?',
  lpc_health_questionnaire: 'Health Questionnaire',
  lpc_quotation_show_details: 'Show details',
  lpc_quotation_premium_details: 'Premium details',
  lpc_quotation_warranties_details: 'Cover Summary',
  lpc_quotation_commissions_details: 'Commission',
  lpc_Reference_Third_Party: 'Reference Third Party',
  lpc_policyHolder: 'Policyholder',
  lpc_DelegateEqualToPolicyHolder: 'Delegate equal to the Policyholder ?',
  lpc_LegalGuardianEqualToPolicyHolder: 'Legal guardian equal to the Policyholder ?',
  lpc_ThirdPayerEqualToPolicyHolder: 'Third payer equal to the Policyholder ?',
  lpc_DelegateEqualToPolicyHolderWarning: 'Subject for role Delegate equal to the PolicyHolder',
  lpc_LegalGuardianEqualToPolicyHolderWarning: 'Subject for role Legal Guardian equal to the PolicyHolder',
  lpc_ThirdPayerEqualToPolicyHolderWarning: 'Subject for role Third payer equal to the PolicyHolder',
  lpc_but_confirm_accept: 'confirm and accept',
  lpc_Date_Start_Variation: 'Date Start Variation',
  lpc_New_Frequent_Payment: 'New Frequent Payment',
  lpc_start_date_profit: 'Start date profit',
  lpc_end_date_profit: 'End date of profit',
  lpc_last_rate_profit: 'Last rate profit',
  lpc_last_profit: 'last profit',
  lpc_Fund_Code: 'Fund Code',
  lpc_OperationSynstesis: 'Operation Synthesis',
  lpc_PreviusPerceent: 'Previus Precentage',
  lpc_PreviusValue: 'Previus Value',
  lpc_NumberOfUnit: 'Previus Number of unit',
  lpc_PostPErcent: 'Post Percentage',
  lpc_PostValue: 'Post Value',
  lpc_PostNumberOfUnit: 'Post number of quote',
  lpc_Dati: 'Data',
  lpc_PIP_Data: 'Pip Data',
  lpc_PIP_General: 'General Data',
  lpc_PIP_Contribution: 'Pip Contribution',
  lpc_HolderData: 'Insured Data',
  lpc_RiskData: 'Risk Data',
  lpc_IBAN_error: 'Iban is not correct',
  lpc_BACK: 'Back',
  lpc_ACTIONS: 'Actions',
  lpc_DETAIL: 'Detail',
  lpc_CANCEL: 'Cancel Proposal',
  lpc_CONFIRM_PROPOSAL: 'Confirm Proposal',
  lpc_CONFIRM_POLICY: 'Confirm Policy',
  lpc_not_linked_detail: '{{name}} detail',
  lpc_quote_number: 'Quote number',
  lpc_valid_quote: 'Valid quote',
  lpc_BUY_PREV: 'Buy',
  lpc_EDIT_PREV: 'Edit',
  lpc_EDIT: 'Edit',
  lpc_CANCEL_PREV: 'Cancel',
  lpc_modify_quote: 'Modify quote',
  lpc_modify_proposal: 'Modify proposal',
  lpc_buy_quote: 'Buy quote',
  lpc_Close_assignment: 'Close assignment',
  lpc_Close_lien: 'Close lien',
  lpc_disinvestment_funds: 'Disinvestment funds',
  lpc_error_mandatory_fields: 'Fields marked in red are required or contain incorrect values',
  lpc_disinvestment_msg: '{{amount}} to be disinvested, or {{percent}}',
  lpc_disinvestment_allocation_msg: 'Investment allocation exceeds the available amount',
  lpc_maximum_percentage: 'Maximum accepted percentage',
  lpc_maximum_amount: 'Maximum accepted amount',
  lpc_minimum_percentage: 'Minimum accepted percentage',
  lpc_minimum_amount: 'Minimum accepted amount',
  lpc_investment_msg: '{{amount}} to be invested, or {{percent}}',
  lpc_Fields_marked_in_red_are_required: 'Fields marked in red are required',
  lpc_amount_format_validation: 'The amount is invalid, it contains incorrectly the wrong decimal separator please correct it using \'.\'',
  lpc_select_at_least_one_beneficiary: 'Select at least one beneficiary',
  lpc_quote_due_date: 'Quote Due Date',
  lpc_roles_variation: 'Roles',
  lpc_fiscal_data: 'Fiscal Data',
  lpc_data_calc: 'Calculation date',
  lpc_tipo_liquidazione: 'Settlement type',
  lpc_valore_polizza: 'Policy value',
  lpc_imponibile_imp: 'Taxable tax',
  lpc_imposta_base: 'Tax base',
  lpc_imposta_calcol: 'Calculated tax',
  lpc_imposta_da_add: 'Tax to be charged',
  lpc_imposta_add: 'Tax charged',
  lpc_imposta_bollo: 'Tax stamp',
  lpc_fiscal_data_detail: 'Fiscal Data Detail',
  lpc_quote_white_list: 'Quote white list',
  lpc_data_rif: 'Reference date',
  lpc_tipo_perc: 'WL Percentage',
  lpc_CancellInstallment: 'Summary request cancel installment',
  lpc_DescOperazione: 'Operation description',
  lpc_RateAmount: 'Total amount installment',
  lpc_OPEN_LATEST_VERSION: 'Open latest version',
  lpc_TOTAL_INVESTED: 'Total invested',
  lpc_REMAINING_AMOUNT: 'Remaining amount',
  lpc_descrizione_rischio: 'Risk Description',
  lpc_prestazione_ridotta_iniz: 'Reduction original benefit',
  lpc_prestazione_rivalutata: 'Reduction revauled benefit',
  lpc_provvigioni_da_recuperare: 'Commission to be recovered',
  lpc_reg41: 'Nominative to send communications to',
  lpc_disinvestment_amount: 'Disinvestment amount',
  lpc_occurrenceNumber: 'Occurrence Number',
  lpc_frequecy: 'Frequency',
  lpc_the_occurenceNumber_must_be_a_value: 'The Occurence number must be greater than {{min}}',
  lpc_disinvestment_range: 'Inserire un importo da disinvestire maggiore di {{max}} e minore o uguale a {{min}}',
  lpc_state: 'State',
  lpc_substate: 'Substate',
  lpc_product_type: 'Product Type',
  lpc_managementnode: 'Management node',
  lpc_subagency: 'Sub Agency',
  lpc_branch_name: 'Branch',
  lpc_agreement: 'Agreement',
  lpc_payment_frequency: 'Payment Frequency',
  lpc_mean_of_payment: 'Mean of Payment',
  lpc_Subcause: 'lpc_Subcause',
  lpc_premium_payment_suspension_date: 'Premium Payment Suspension Date',
  lpc_imposta_sostitutiva: 'Substitute tax',
  lpc_novazione_contr: 'Contractual novation',
  lpc_acc_premium_initial_2011: 'Accumulate premium 31/12/2011 initial',
  lpc_amount_intital_2011: 'Amount 31/12/2011 initial',
  lpc_acc_premium_2011: 'Accumulate premium 31/12/2011',
  lpc_amount_2011: 'Amount 31/12/2011',
  lpc_acc_premium_initial_2014: 'Accumulate premium 30/06/2014 initial',
  lpc_acc_premium_2014: 'Accumulate premium 30/06/2014',
  lpc_amount_intital_2014: 'Amount 30/06/2014 initial',
  lpc_amount_2014: 'Amount 30/06/2014',
  lpc_acc_premium_initial_2022: 'Initial sum of premiums on 31/12/2022',
  lpc_acc_premium_2022: 'Sum of premiums on 31/12/2022',
  lpc_amount_intital_2022: 'Initial principal and interest on 31/12/2022',
  lpc_amount_2022: 'Principal and interest on 31/12/2022',
  lpc_acc_premium_intial_tax_change: 'Accumulate premium tax change initial',
  lpc_amount_initial_tax_change: 'Amount tax change initial',
  lpc_acc_premium_tax_change: 'Accumulate premium tax change',
  lpc_amount_tax_change: 'Amount tax change',
  lpc_regime_change_date: 'Tax change date',
  lpc_please_enter_a_disinvestment_amount: 'Please enter a disinvestment amount for the fund: {{fund}}',
  lpc_please_select_at_least_a_disinvestment: 'Please select at least a disinvestment',
  lpc_reg41Msg: 'Recipient of communications',
  lpc_cost: 'Cost',
  lpc_paymentFrequencyInterest: 'Freq. Inter.',
  lpc_netpremium: 'Net Premium',
  lpc_grosspremium: 'Gross Premium',
  lpc_information: 'Information:',
  lpc_reinvest_data_detail: 'Reinvestment detail',
  lpc_polizza: 'Policy',
  lpc_data_effetto_mov: 'Effective date',
  lpc_netto_liquidazione: 'Settlement net amount',
  lpc_netto_reinvestire: 'Reinvestment net amount',
  lpc_stato_reinvest: 'Settlement reinvestment state',
  lpc_reinvested_settlements: 'Reinvested settlements',
  lpc_loans: 'Loans',
  lpc_elaboration_date: 'Elaboration date',
  lpc_calculation_date: 'Calculation date',
  lpc_annuity_data: 'Annuity data',
  lpc_extra_premium: 'Extra premium',
  lpc_loan_interest: 'Loan interest',
  lpc_fractionation_interest: 'Fractionation interest',
  lpc_default_interest: 'Default interest',
  lpc_reactivation_interest: 'Reactivation interest',
  lpc_installment_detail: 'Installment detail',
  lpc_effectiveAnnuityDate: 'Effective Annuity Date',
  lpc_firstInstallmentDate: 'First Installment Date',
  lpc_expiryAnnuityDate: 'Expiry Annuity Date',
  lpc_amountFirstInstallment: 'Amount First Installment',
  lpc_initialAnnuityAmount: 'Initial Annuity amount',
  lpc_periodicityInstallmentAnnuity: 'Periodicity Installment Annuity',
  lpc_typePaymentAnnuity: 'Type Payment Annuity',
  lpc_performanceType: 'Performance Type',
  lpc_annuityType: 'Annuity Type',
  lpc_annuity_detail: 'Annuity Detail',
  lpc_canceled_liquidation_detail: 'Canceled liquidation detail',
  lpc_Percentage: 'Percentage',
  lpc_loan_data: 'Loan data',
  lpc_maxLoanAmount: 'Maximum payable amount',
  lpc_loanRepaymentProcedure: 'Loan repayment procedure',
  lpc_loanAmount: 'Loan amount',
  lpc_rateType: 'Interest tax type',
  lpc_maxLoanAmountKO: '€ 0 (error in calculating the maximum payable amount)',
  lpc_grant_date: 'Grant date',
  lpc_loan_amount: 'Loan amount',
  lpc_return_way: 'Return way',
  lpc_interest_rate_type: 'Interest rate type',
  lpc_residual_amount: 'Residual amount',
  lpc_refund_type: 'Refund type',
  lpc_refund_amount: 'Refund amount',
  lpc_select_lpc_refund_type: 'Select a refund type',
  lpc_leftInsuredAmount: 'Residual insured capital',
  lpc_insuredAmount: 'Invested amount',
  lpc_reactivatedCapital: 'Reactivated Capital',
  lpc_premium_reactivation: 'premium_reactivation',
  lpc_negative_amount: 'Negative Residual amount',
  lpc_zero_amount: 'Residual amount not present, please insert an amount greater than 0',
  lpc_error_mandatory_selected_type_of_refund: 'Please select a refund type',
  lpc_select_refund: 'Please select at least a loan',
  lpc_selected_profiles: 'Selected profiles:',
  lpc_coupon_beneficiary: 'Coupon beneficiary',
  lpc_bookings: 'Bookings',
  lpc_booking_type: 'Booking type',
  lpc_booking_state: 'Status',
  lpc_booking_effective_date: 'Effective date',
  lpc_booking_extrapremiumHealth: 'Health Extra premium',
  lpc_booking_extrapremiumProf: 'Professional Extra premium',
  lpc_booking_extrapremiumSport: 'Sports Extra premium',
  lpc_booking_extrapremiumOther: 'Other Extra premium',
  lpc_booking_effectiveDate: 'Effective Date',
  lpc_booking_detail_label: 'Booking details',
  lpc_Rischi: 'Coverages',
  lpc_booking_destinationNode: 'Destination node',
  lpc_booking_cause: 'Cause',
  lpc_PolicyData: 'Policy Data',
  lpc_booking_cancellationDate: 'Cancellation Date',
  lpc_extra_premiums: 'Extra premiums',
  lpc_error_reservation_cancel: 'Error on the cancellation of the {{descr}} number {{num}}',
  lpc_success_reservation_cancel: 'The cancellation of the {{descr}} number {{num}} has been confirmed',
  lpc_incomplete_disinvestment_for_the_funds: 'Incomplete disinvestment for the funds',
  lpc_Sinistro_morte: 'Death claim',
  lpc_Sinistro_invalidita: 'Disability claim',
  lpc_Sinistro_inabilità: 'Inability claim',
  lpc_Malattia_grave: 'Serious illness claim',
  lpc_insured_form: 'Insured Form',
  lpc_other_contract_list: 'Other contract list',
  lpc_modified_personal_data_for_the_subject: 'Modified personal data for the subject',
  lpc_subject_roles: 'Party Roles',
  lpc_no_variation_modpd: 'No changes made, modify a subject',
  lpc_DeductiblePremium: 'Deductible premium',
  lpc_firstterm: 'First term',
  lpc__1TERM_ATOOL: 'Activation tool date',
  lpc__1TERM_MTOOL: 'Modify modifica tool',
  lpc__1TERM_DTOOL: 'Delete tool date',
  lpc_management_fees: 'Management fees',
  lpc_investment_not_found: 'There are no items to select',
  lpc_No_policies: 'There are no policies for the modified subject',
  lpc_variation_convention: 'Convention variation',
  lpc_andamento_polizza_alla_data: 'Policy trend to date',
  lpc_appendice_dichiarativa: 'Declaration Appendix',
  lpc_policy_number_dot: 'Policy n. ',
  lpc_investment_sum_must_be100: 'The sum of investments must equal 100%',
  lpc_Operation_detail: 'Operation details',
  lpc_Notwithstanding_any_ongoing_operations: 'Values might be subject to changes due to ongoing operations',
  lpc_percentage_max_zero: 'insert a percentage greater than zero',
  lpc_Mostra_tutti: 'Display all',
  lpc_Visualizza_dettaglio: 'View detail',
  lpc_consultazione_storica: 'Historical Consultation',
  lpc_dettaglio_movimento: 'Movement detail',
  lpc_mensile: 'monthly',
  lpc_annuale: 'yearly',
  lpc_sixmonthly: 'semestrale',
  lpc_trimestrale: 'quarterly',
  lpc_Titolo_non_incassato: 'stock not collected​',
  lpc_Rid: 'DDP​',
  lpc_In_attesa_valorizzazione_quote: 'Pending valorization of invested shares',
  lpc_switch_di_perequazione: 'Scheduled switch​',
  lpc_SELEZIONA: '-- SELECT --',
  lpc_settimanale: 'weekly',
  lpc_Valido: 'Valid',
  lpc_quotidiano: 'daily',
  lpc_Assegno: 'Check',
  lpc_Bonifico: 'Bank Transfer',
  lpc_Sdd: 'Direct debit order',
  lpc_severeDisabilityMsg: 'Beneficiary with severe disability',
  lpc_benDg: 'Disabled beneficiary',
  lpc_distribution: 'Allocation by',
  lpc_Distribution_amount_exceeds: 'Distribution amount exceeds the total settlement amount available',
  lpc_distribution_invalid_greater: 'The {{type}} is invalid, it must be greater than {{min}}',
  lpc_distribution_invalid_greater_equal: 'The {{type}} is invalid: it should be less or equal to {{max}}',
  lpc_perc: 'Percentage',
  lpc_amount: 'Amount',
  lpc_Please_insert_a_distribution: 'Please insert a distribution',
  lpc_For_the_Beneficiary: 'For the Beneficiary',
  lpc_global_distribution: 'The total amount to be settled must be less than or equal to {{totalAmount}}',
  lpc_invalid_char: 'The format of the {{type}} contains incorrect characters',
  lpc_toDistribute: 'to be distributed',
  lpc_noClaims: 'No claims were found',
  lpc_Data_sinistro: 'Date of claim',
  lpc_Data_Pervenimento_Denuncia: 'Date of receipt of claim notification',
  lpc_Data_Denuncia_Sinistro: 'Date of claim notification',
  lpc_Tipo_sinistro: 'Claim type',
  lpc_inserisci_denuncia: 'insert claim',
  lpc_Conferma_inserimento_denuncia: 'Confirmation of claim insertion',
  lpc_Denuncia_inserita_correttamente: 'Claim inserted successfully',
  lpc_Stato_denuncia: 'Claim status',
  lpc_marital_status: 'Marital Status',
  lpc_fiscal_code: 'Fiscal code',
  lpc_birthdate: 'Birth Date',
  lpc_address: 'Address',
  lpc_sex: 'Sex',
  lpc_annulla_denuncia: 'cancel claim',
  lpc_modifica_denuncia: 'modify claim',
  lpc_conferma_denuncia: 'confirm claim',
  lpc_new_beneficiary: 'New beneficiary',
  lpc_Seleziona: 'Select',
  lpc_insert_phisical_sbj_for: 'Insert a physical subject for the {{role}} role',
  lpc_insert_legal_sbj_for: 'Insert a legal subject for the {{role}} role',
  lpc_payment_incomplete: 'Incomplete payment details',
  lpc_add_mandatory_linkedroles: 'Enter the mandatory roles for the entered subject',
  lpc_beneficiary_owner_recipient_must_be_physical: 'The beneficial owner of the recipient must be physical',
  lpc_enter_the_beneficial_owners_of_the_recipient: 'Enter the beneficial owners of the recipient',
  lpc_global_percentage_must_be_a_maximum_100: 'the global percentage must be a maximum of 100',
  lpc_overall_percentage_must_equal100: 'the overall percentage must equal 100',
  lpc_Questionario_deve_essere_compilato: 'Questionnaire must be completed',
  lpc_enter_mandatory_data: 'Enter the mandatory data',
  lpc_enter_an_adult: 'Enter an adult',
  lpc_enter_a_beneficial_owner: 'Enter a beneficial owner',
  lpc_enter_a_physical_beneficial_owner: 'Enter a physical beneficial owner',
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: 'The global percentage of beneficial owners must not exceed 100',
  lpc_beneficial_owners_percentages_must_be_greater_than0: 'Beneficial owners\' percentages must be greater than 0.0',
  lpc_effective_holder_perc: '{{benefName}}\'s beneficial owner {{linkedName}}\'s percentage is below the minimum allowed of {{linkedPerc}}%',
  lpc_effective_holder_duplicated: 'Effective holder duplicated',
  lpc_enter_the_minimum_number_of_subjects: 'Enter the minimum number of subjects',
  lpc_percentage_must_be_greater_than_0: 'The percentage must be greater than 0.0',
  lpc_enter_free_text: 'Enter free text',
  lpc_beneficiary_executor: 'Beneficiary executor',
  lpc_effective_recipient: 'Effective recipient',
  lpc_recipient_owner: 'Recipient owner',
  lpc_beneficial_owner_of_beneficiary: 'Beneficial owner',
  lpc_confirmed_claim_modify: 'Confirmed claim modify',
  lpc_claim_modified_correctly: 'Claim modified correctly',
  lpc_Assicurato: 'Insured',
  lpc_Assicurati: 'Insureds',
  lpc_holderType: 'Applicant\'s Type of Employment',
  lpc_ageRetirement: 'Age at Retirement',
  lpc_accessTransfer: 'Transfer Applicant',
  lpc_emptyContribution: 'Contribution from Employer',
  lpc_firstEmployment: 'Employment Start Date',
  lpc_oldUnderWritt: 'Old enrollee',
  lpc_underWitt1993: 'Enrolled by 29/4/1993',
  lpc_maturato2001: 'Accrued by 31/12/2000',
  lpc_maturato2006: 'Accrued by 31/12/2006',
  lpc_riduzImponibiTFR: 'Reduction on taxable TFR',
  lpc_aliquotaTFR: 'TFR rate(pre-2001 taxation)',
  lpc_PIP_Contribution_Type: 'Contribution type',
  lpc_YES: 'Yes',
  lpc_PIP: 'Personal Pension Plan',
  lpc_PIP_Contribution_Percentage: 'Percentage',
  lpc_PIP_Contribution_Amount: 'Amount',
  lpc_transfer_data: 'Transfer Data',
  lpc_unit_questionnaire: 'Unit Questionnaire',
  lpc_amount_reimbured: 'Amount to be reimbursed',
  lpc_Descr_not_handled: 'Unmanaged description for this operation code',
  lpc_select_transfer: 'Transfer selection',
  lpc_fiscalFolderDataPost2006: 'Post 2006 Tax System',
  lpc_fiscalFolderData20012006: '2001 - 2006 Tax System',
  lpc_fiscalFolderDataBefore2001: 'Pre 2001 Tax System',
  lpc_filledOut: 'Filled out',
  lpc_blank: 'Blank',
  lpc_desc_previousPenFund: 'Previous pension fund',
  lpc_companyPreviousPenFund: 'Company name of previous pension fund',
  lpc_typePreviousPenFund: 'Type of previous pension fund',
  lpc_note: 'Notes',
  lpc_Benef_irrevocabile: 'Irrevocable beneficiary',
  lpc_PIP_Transfer: 'Transfers from other fund',
  lpc_requestDate: 'Request Date',
  lpc_Type_Previous_Pension_Fund: 'Type of previous pension fund',
  lpc_Previous_Pension_Fund: 'Previous pension fund',
  lpc_Import_Transfert: 'Transfer amount',
  lpc_Note: 'Notes',
  lpc_relief: 'Tax relief 2022',
  lpc_selected_settlement: 'Settlement selected',
  lpc_EDIT_ANONYMOUS_PREV: 'Edit',
  lpc_invalid_quote: 'Preventivo Annullato',
  lpc_reasonForRequest: 'Reason For Request',
  lpc_advancePaymentAmount: 'Advance Payment Amount',
  lpc_repaidAdvance: 'Repaid Advance',
  lpc_PIP_AdvancedPayment: 'Advance payments',
  lpc_requestReason: 'Reason for request',
  lpc_notRepaidAmount: 'Amount Not Yet Repaid',
  lpc_fiscalDate: 'Effective Date',
  lpc_previousPension: 'Previous Pension Plan',
  lpc_fiscalCompetence: 'Fiscal Competence',
  lpc_settlementStatus: 'Advance payment status',
  lpc_repaidAmount: 'Repaid Amount',
  lpc_advancePaymentTransfer: 'Advances from transfers',
  lpc_policy_factors: 'Policy Factors',
  lpc_asset_factors: 'Asset Factors',
  lpc_Date_Variation: 'Variation Timeframe',
  lpc_penalties_amount: 'Penalties amount',
  lpc_tax_amount: 'Taxes amount',
  lpc_warranties_detail: 'Warranties detail',
  lpc_fixedCharges: 'Fixed Charges',
  lpc_catastrophe: 'Catastrophe Extra Premium',
  lpc_health: 'Health Extra Premium',
  lpc_other: 'Other Extra Premium',
  lpc_professional: 'Professional Extra Premium',
  lpc_sports: 'Sports Extra Premium',
  lpc_collectionFee: 'Collection Fee',
  lpc_lambdaFee: 'Lambda Fee',
  lpc_managementFee: 'Management Fee',
  lpc_purchaseFee: 'Purchase Fee',
  lpc_continue_button: 'Continue',
  lpc_confirm_button: 'Confirm',
  lpc_authorization_request: 'Authorization request',
  lpc_questionSTR_invalid: 'The answer to the question: {{question}}, is not correct',
  lpc_questionNUM_invalid: 'The amount entered for the question: {{question}} is not correct',
  lpc_Beneficiaryowner: 'Beneficial owner',
  lpc_confirm_operation: 'Confirm operation',
  lpc_financial_questionnaire: 'Financial Questionnaire',
  lpc_avc_questionnaire: 'AVC Questionnaire',
  lpc_confirm_variation: 'Confirm Variation',
  lpc_Declarative_Appendix: 'Declarative Appendix',
  lpc_investment_funds_step: 'Investment Funds',
  lpc_disinvestment_profile_step: 'Disinvestment profiles',
  lpc_startDate: 'Start date',
  lpc_end_date: 'End date',
  lpc_six_monthly: 'Six-monthly',
  lpc_quarterly: 'Quarterly',
  lpc_weekly: 'Weekly',
  lpc_monthly: 'Monthly',
  lpc_annual: 'Annual',
  lpc_daily: 'Daily',
  lpc_start_date_cannot_be_earlier_than_the_effective_date: 'Start date cannot be earlier than the effective date {{value}}',
  lpc_start_date_must_be_earlier_than_the_end_date: 'Start date must be earlier than the end date',
  lpc_contract_value: 'Contract value',
  lpc_invested_amount: 'Invested amount',
  lpc_funds_details_and_summary: 'Funds details and summary',
  lpc_separate_management_detail: 'Separate Management Detail',
  lpc_denuncia_sinistro: 'Claim Notification',
  lpc_duration_in: 'Duration in',
  lpc_years: 'years',
  lpc_months: 'months',
  lpc_days: 'days',
  lpc_policy_number: 'Policy number',
  lpc_proposal_number: 'Proposal number',
  lpc_LifeBeneficiary: 'Life Beneficiary',
  lpc_DeathBeneficiary: 'Death Beneficiary',
  lpc_NO: 'No',
  lpc_claim_data: '',
  lpc_node: 'Node',
  lpc_sbj: 'Subject',
  lpc_empty: 'empty',
  lpc_search_result: 'Search results',
  lpc_show_elemets: 'Show elements',
  lpc_insured: 'Insured',
  lpc_notification_status: 'Notification Status',
  lpc_nuova_denuncia: 'new notification',
  lpc_insured_policy: 'Insured Policies',
  lpc_product: 'Product',
  lpc_find: 'Find',
  lpc_status: 'Status',
  lpc_fund_trend: 'Fund trend',
  lpc_category_fund: 'Profile/ Fund',
  lpc_equivalent_on_date: 'Equivalent on the date',
  lpc_quotes_number: 'Number of Units',
  lpc_damages_policy: 'Damages Policy',
  lpc_life_policy: 'Life Policy',
  lpc_next_installment_premium: 'Next Installment Premium',
  lpc_no_result: 'No results',
  lpc_data_value: 'Date Value Share',
  lpc_paidAmount: 'Paid amount',
  lpc_total_gross_surrender: 'Total gross surrender',
  lpc_left_premiums_paid: 'Left premiums paid',
  lpc_left_premiums_amount: 'Left premiums amount',
  lpc_portfolio_volatility_on_subscription_date: 'Portfolio volatility on subscription date',
  lpc_portfolio_volatility: 'Portfolio volatility on',
  lpc_andamento_controvalore: 'Andamento Controvalore di Polizza',
  lpc_policy_trend: 'Policy Trend',
  lpc_calculate: 'Calculate',
  lpc_frequency: 'Frequency',
  lpc_date_variation_step: 'Date variation',
  lpc_quotation_step: 'Quotation',
  lpc_confirm_variation_step: 'Confirm variation',
  lpc_financial_questionnaire_step: 'Financial Questionnaire',
  lpc_close_button: 'Close',
  lpc_Operation_of_executed_correctly: 'Operation of {{operation}} executed correctly',
  lpc_AuthorizatioPolicyForwarded: 'The request for authorization for the policy number {{policyNumber}} has been forwarded to the appropriate User',
  lpc_are_you_sure_you_want_to_cancel_this_operation: 'Are you sure you want to cancel this operation?',
  lpc_cancel_button: 'Cancel',
  lpc_cancel_reservation: 'Cancel reservation',
  lpc_system_error: 'System error',
  lpc_generic_error_title: 'Error',
  lpc_select_label: 'Select',
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: 'Warning, you are deleting the policyholder. Do you want to proceed?',
  lpc_factor_must_be_equal: 'The {{factor}} must be equal to {{amount}}',
  lpc_factor_must_be_greater_than: 'The {{factor}} must be greater than {{amount}}',
  lpc_factor_must_be_less_than: 'The {{factor}} must be less than {{amount}}',
  lpc_Please_enter_threshold_percentage_value: 'Fund: {{value}} - Please enter threshold percentage',
  lpc_please_enter_a_value_between: 'Please enter a value between {{min}} and {{max}}',
  lpc_variation_step_label: 'Variation',
  lpc_contractual_option: 'Contractual Options',
  lpc_costs_step_label: 'Costs',
  lpc_variation_detail_step_label: 'Variation Details',
  lpc_ongoing_operations: 'Values might be subject to changes due to ongoing operations',
  lpc_exchange_rate: 'Exchange rate',
  lpc_last_share_value: 'Last share value',
  lpc_movement_description: 'Movement description',
  lpc_settlement_amount: 'Settlement amount',
  lpc_Annual_SinglePremium: 'Annual/Single Premium',
  lpc_policy_address: 'Policy address',
  lpc_holders: 'Holders',
  lpc_last_revaluation: 'Last revaluation',
  lpc_revalueted_benef: 'Revaluated Benefit',
  lpc_investment: 'Investments',
  lpc_position_number: 'Position number',
  lpc_invested_premium: 'Invested Premium',
  lpc_operation_details: 'Operation details',
  lpc_contract: 'Contract',
  lpc_dis_date: 'Disinvestment date',
  lpc_gross_amount: 'Gross amount',
  lpc_net_amount: 'Net amount',
  lpc_net: 'Net',
  lpc_quote: 'Quote',
  lpc_quote_and_save: 'Quote and save',
  lpc_info_investment_complete: 'Information: investment completed',
  lpc_effective_holders: 'Beneficial owners',
  lpc_effective_holder: 'Beneficial owner',
  lpc_third_party_role: 'Third payer',
  lpc_lien_credit: 'Creditor type',
  lpc_end_validity_date: 'End validity date',
  lpc_start_date: 'Start Date',
  lpc_unit_linked_value: 'Unit Linked Value',
  lpc_allocation_profile: 'Allocation Profile',
  lpc_search_filter: 'Search filter',
  lpc_expiry_option_detail: 'Expiry option detail',
  lpc_annuity_frequency: 'Annuity frequency',
  lpc_annuity_payment_type: 'Annuity payment type',
  lpc_percentage_reversibility: 'Percentage reversibility',
  lpc_reversionary: 'Reversionary',
  lpc_certain_revenue_years: 'Certain years of revenue',
  lpc_postponement_type: 'Postponement type',
  lpc_postponement_years: 'Postponement years',
  lpc_clauses: 'Clauses',
  lpc_active_contractual_option: 'Active contractual options',
  lpc_expiry_option: 'Expiration option',
  lpc_revaluation_history: 'Revaluation history',
  lpc_monetary_fund: 'Monetary fund',
  lpc_benefit_effect_date: 'Benefit effect date',
  lpc_initial_benefit: 'Initial benefit',
  lpc_movement_update: 'Movement update',
  lpc_update_effect_date: 'Update effect date',
  lpc_net_rate: 'Net rate',
  lpc_macro_category: 'Macro category',
  lpc_quotation_frequency: 'Quotation frequency',
  lpc_volatility: 'Volatility',
  lpc_volatility_date: 'Volatility date',
  lpc_start_quotation: 'Start quotation',
  lpc_last_quotation: 'Last quotation',
  lpc_valuation: 'Valuation',
  lpc_percentage_total: 'Total percentage',
  lpc_euro_valuation_not_fund_currency: 'The valuation is expressed in € and not in the fund currency',
  lpc_disinvstements: 'Disinvestments',
  lpc_payment_amount: 'Payment amount',
  lpc_surrender_distribution: 'Surrender distribution',
  lpc_formula: 'Formula',
  lpc_roles_connected_benef: 'Roles linked to the beneficiary',
  lpc_quotation_details: 'Quotation detail',
  lpc_Disinvestmentsfunds: 'Disinvestment Funds',
  lpc_same_fund_distribution: 'Fund distribution identical to the initial payment',
  lpc_not_same_fund_distribution: 'Fund distribution modified from the initial payment',
  lpc_guarantees_oveview: 'Covers Overview',
  lpc_calculate_volatility: 'Calculate Asset Volatility',
  lpc_benef_death_not_same: 'The death beneficiary cannot be the insured subject',
  lpc_global_percentage_equal_hundred: 'The total percentage must equal 100',
  lpc_effective_holder_natural_person_req: '{{benefCat}} {{benefRole}}  - The party {{linkedName}} as beneficial owner must be a natural person',
  lpc_benefPresente_default_msg: 'The subject has already been selected as a beneficiary in the same category',
  lpc_effective_holder_duplicate: '{{linkedName}} has already been entered as {{benefName}}\'s beneficial owner',
  lpc_effective_holder_adult_req: '{{benefCat}} {{benefRole}}  - The party {{linkedName}} as beneficial owner must be an adult',
  lpc_investment_plan: 'Investment plan',
  lpc_contract_detail: 'Contract Detail',
  lpc_variation_reason: 'Variation reason',
  lpc_investment_plan_detail: 'Investment plan detail',
  lpc_revaluation_rate: 'Revaluation Rate',
  lpc_benefit_commencement_date: 'Initial Benefit',
  lpc_update_effective_date: 'Update effective date',
  lpc_last_odds_value: 'Last odds value',
  lpc_total: 'Total',
  lpc__EFFOP: 'Effective Date',
  lpc__OPZFR: 'Fractioning',
  lpc__OPZPM: 'Payment Method',
  lpc__OPBEN: 'Beneficiary',
  lpc__MINTS: 'Minimum Threshold Percentage',
  lpc__MAXTS: 'Maximum Threshold Percentage',
  lpc__NUMOC: 'Number of Occurrences',
  lpc__AMTOP: 'Redemption Amount',
  lpc__MALMT: 'Minimum Amount',
  lpc__CIBAN: 'IBAN',
  lpc__PCOUP: 'Periodic Coupon Percentage',
  lpc_threshold_percentage: 'Threshold percentage',
  lpc_pure: 'Pure Premium',
  lpc_starting_fund: 'Starting Fund',
  lpc_details: 'Details',
  lpc_treshold: 'Threshold',
  lpc_inv_date: 'Investment Date',
  lpc_beneficiary_perc_req: '{{benefCat}} {{benefRole}} {{benefName}}: Enter a percentage',
  lpc_beneficiary_perc_req_range: ' {{benefCat}} {{benefRole}} {{benefName}}: Percentage must be between 0.01 and 100',
  lpc_invalid_total_position_percent: 'The Percentage amount for the group \"{{groupType}}\" on Beneficiary {{cat}} must be 100%',
  lpc_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Enter a beneficiary from the party repository',
  lpc_benefPresente: '{{benefName}} has already been entered as {{benefCat}} {{benefRole}}',
  lpc_benMorteNoAssic: '{{benefName}} entered as {{benefCat}} {{benefRole}} has already been entered as Insured',
  lpc_life_beneficiary: 'Life Beneficiary',
  lpc_death_beneficiary: 'Death Beneficiary',
  lpc_beneficiary_position_mandatory: 'Beneficiary {{benefCat}} {{benefName}}, select a \"Position Number\"',
  lpc_reversionary_subject: 'Reversionary beneficiary',
  lpc_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lpc_gross_detail: 'Gross premium detail',
  lpc_type_of_covers: 'Type of covers',
  lpc_clausesinsuredClauses: 'Insured Clauses',
  lpc_clausespolicyClauses: 'Policy Clauses',
  lpc_clausesriskClauses: 'Risk Clauses',
  lpc_max_capital_amount: 'IMC – Maximum Capital Amount',
  lpc_fiscal_period_M1: 'M1 – Amount accrued as of 12/31/2000',
  lpc_fiscal_period_M2: 'M2 - Amount accrued from 1/1/2001 to 12/31/2006',
  lpc_fiscal_period_M3: 'M3 - Amount accrued from 1/1/2007',
  lpc_fiscal_period_capital_request_K1: 'Part of M1 requested in capital',
  lpc_fiscal_period_capital_request_K2: 'Part of M2 requested in capital',
  lpc_fiscal_period_capital_request_K3: 'Part of M3 requested in capital',
  lpc_max_amount_exceeded: 'The amount requested in capital exceeds the maximum possible.',
  lpc_max_amount_error: 'The capital amount requested must be entirely allocated to the M1 and M2 amounts.',
  lpc_performance_data: 'Performance data',
  lpc_role_is_mandatory: 'You need to enter a {{role}}',
  lpc_percentage_in_capital: '% in capital',
  lpc_issue_authorized_proposal: 'Issue authorized proposal/policy',
  lpc_confirm_prop_pol_auth: 'Confirm proposal/policy in Authorisation',
  lpc_settlement: 'Settlement',
  lpc_annuity: 'Annuity',
  lpc_insured_amount: 'Insured Amount',
  lpc_custom_field_mandatory_error: 'The field {{field}} is mandatory',
  lpc_print_docs: 'Print documents',
  lpc_revisionary_err: 'Enter reversionary subject',
  lpc_revisionary_perc_err: 'Enter percentage of the reversionary subject',
  lpc_percentage_must_be_between: 'The percentage must be between 0.01 and 100',
  lpc_PIP_UndeductedPremiums: 'Undeducted premiums',
  lpc_contributionYear: 'Contribution Year',
  lpc_annualPremium: 'Annual Premium',
  lpc_undeductedPremium : 'Undeducted Premiums',
  lpc_reversionario: "Reversionary",
  lpc_firstEnrollmentComplementaryPension: '',
  lpc_PIP_amountForTaxation: 'Amount for Taxation',
  lpc_PIP_amount: 'Amount',
  lpc_PIP_contribempl4Perc: 'Contributions not Exceeding 4%',
  lpc_PIP_reducttaxbltfr: 'Reduction taxable TFR',
  lpc_PIP_aliqtfr: 'TFR tax rate',
  lpc_PIP_premdeducted2001: 'Premiums deducted 2001',
  lpc_PIP_premdeductoffset: 'Premiums deducted offset',
  lpc_PIP_annuitytaxdeduct: 'Annuity Tax Deducted',
  lpc_pip_contributions_installment_detail: 'Contributions Details',
  lpc_contribution_type: 'Contribution type',
  lpc_contribution_amount: 'Amount',
  lpc_contribution_year: 'Year',
  lpc_contribution_month_from: 'Month (from)',
  lpc_contribution_month_to: 'Month (to)',
  lpc_contribution_thirteenth: '13^',
  lpc_contribution_fourteenth: '14^',
  lpc_incomplete_fund_investment: 'Incomplete investment for the funds',
  lpc_incomplete_profile_investment: 'Incomplete investment for the profiles',
  lpc_tax_credit: 'Tax credit',
  lpc_clausesinsuredClause: '',
  lpc_clausespolicyClause: '',
  lpc_clausesriskClause: '',
};
export default LPC_EN;

import {Inject, NgModule, ViewChild, Injectable, Directive} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnalyticsModule, CardAnalyticsHomeComponent} from '@rgi/analytics';
import {RgiRxPortalModule} from '@rgi/rx/portal';
import {ANALYTICS_CARD} from './card-analytics.card';
import {CardAnalyticsReducerService} from '@rgi/analytics';
import {ReportService} from '@rgi/analytics';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {take} from 'rxjs/operators';
import {enabledFunctions} from '@rgi/analytics';
import {LoggerService} from '@rgi/analytics';
import {
  ANALYTICS_ROUTE_DASHBOARD,
  ANALYTICS_ROUTE_EDITREPORT,
  ANALYTICS_ROUTE_NEWREPORT
} from '@rgi/analytics';

@Directive()
@Injectable()
export class CardAnalyticsReducerServicePortal extends CardAnalyticsReducerService {

  authService: any;

  @ViewChild(CardAnalyticsHomeComponent) componentB: CardAnalyticsHomeComponent;

  constructor(private routingService: RoutingService,
              reportService: ReportService,
              @Inject('sessionService') private sessionService: any,
              @Inject('authService') authService: any,
              private logger: LoggerService) {
    super(routingService, reportService);
    this.authService = authService;
  }


  getAdminEnabled() {
    return this.authService.getOperator().enabledFeatures.indexOf(enabledFunctions.ADMIN_FEATURES) !== -1;
  }

  getQlikLinkEnabled() {
    return this.authService.getOperator().enabledFeatures.indexOf(enabledFunctions.QLIK_LINK) !== -1;
  }

  openDashboard(template, activeRoute: ActiveRoute) {
    const filter = this.sessionService.list().find(f => f.name === ANALYTICS_CARD[0].card.name);
    this.logger.log(filter);
    if (filter && activeRoute.id !== filter.idSession) {
      alert('SESSIONE PRESENTE');
      return;
    }
    this.routingService.navigate(this.getFullRoute(ANALYTICS_ROUTE_DASHBOARD), template, activeRoute.id);
  }

  editReport(template, activeRoute: ActiveRoute) {
    const filter = this.sessionService.list().find(f => f.name === ANALYTICS_CARD[0].card.name);
    this.logger.log(filter);
    if (filter && activeRoute.id !== filter.idSession) {
      alert('SESSIONE PRESENTE');
      return;
    }
    this.routingService.navigate(this.getFullRoute(ANALYTICS_ROUTE_EDITREPORT), template, activeRoute.id);
  }

  backHome(activeRoute: ActiveRoute) {
    this.reportService.getAllReports().pipe(take(1)).subscribe();
    this.sessionService.remove(activeRoute.id);
  }

  deleteReport(template, activeRoute: ActiveRoute) {
    this.reportService.deleteReport(template.id).subscribe(res => {
      this.reportService.getAllReports().pipe(take(1)).subscribe(() => {
          this.sessionService.remove(activeRoute.id);
        }
      );
    });
  }

  copyReport(template, activeRoute: ActiveRoute) {
    this.reportService.getReport(template.id).subscribe(
      report => {
        const rep = report;
        delete rep.id;
        delete rep.code;
        rep.description += ' - COPY';
        rep.code = (Math.random() + 1).toString(36).substring(7);
        this.reportService.createReport(rep).subscribe(
          () => {
            this.reportService.getAllReports().pipe(take(1)).subscribe(
              () => this.sessionService.remove(activeRoute.id)
            );
          }
        );
      }
    );
  }


  openCreateReport(activeRoute: ActiveRoute) {
    const filter = this.sessionService.list().find(f => f.name === ANALYTICS_CARD[0].card.name);
    if (filter) {
      alert('SESSIONE PRESENTE');
      return;
    }
    this.routingService.navigate(this.getFullRoute(ANALYTICS_ROUTE_NEWREPORT), {}, activeRoute.id);
  }

  removeSession(activeRoute: ActiveRoute) {
    this.sessionService.remove(activeRoute.id);
  }

}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AnalyticsModule,
    RgiRxPortalModule.forRoot(ANALYTICS_CARD)
  ],
  providers: [
    {
      provide: CardAnalyticsReducerService,
      useClass: CardAnalyticsReducerServicePortal
    }
  ]
})
export class AnalyticsPortalModule {
}



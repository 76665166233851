import { Outcome } from "../dsc-shared-model/dsc-model-utility";


export class IncidentData {
    occurrenceDate: string = '';
    occurrenceTime: string = '';
    dateReported: string = '';
    insuredReportDate: string = '';
    intermediaryReceiptDate: string = '';
    chcReceiptDate: string = '';
    certifiedOccurrenceDate = false;
    actualOccurrenceDate: string  = '';
    actualOccurrenceTime: string = '';
    incidentDescription: string = '';
    additionalNotes: string = '';
    outcome: Outcome = new Outcome();
    occurrenceDateEditable: boolean;
    occurrenceTimeEditable: boolean;
    dateReportedEditable = true;
    insuredReportDateEditable = true;
    intermediaryReceiptDateEditable = true;
    chcReceiptDateEditable = true;
    actualOccurrenceDateEditable: boolean;
    actualOccurrenceTimeEditable: boolean;
    incidentDescriptionEditable = true;
    additionalNotesEditable = true;
    certifiedOccurrenceDateEditable = true;
    certifiedDataConfigurationVisible = false;
    isValidForm?: boolean;
    isOccurrenceDateChanged?: boolean;
    isOccurrenceDateFromEmptyToValued?: boolean;
}

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PlcDateUtils } from '../../utils/plc-date-utils';
import { InputFieldDefinition } from './../../models/postsales-operations-response.model';
import {EMPTY_STR} from '../../models/consts/lpc-consts';
@Component({
  selector: 'lpc-date-stepper',
  templateUrl: './lpc-date-stepper.component.html',
  styleUrls: ['./lpc-date-stepper.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcDateStepperComponent),
      multi: true
    }
  ]
})
export class LpcDateStepperComponent implements OnInit, ControlValueAccessor {

  name = EMPTY_STR;
  private arrayPos: number;
  public disabled = false;


  @Input() inputField: InputFieldDefinition;
  @Input() outputStringNotUTC: boolean;

  setNext() {
    this.getListVal(false);
  }

  setBack() {
    this.getListVal(true);
  }

  constructor() { }

  private getListVal(backward: boolean) {
    const oldPos = this.arrayPos;
    const newPos = backward ? this.arrayPos - 1 : this.arrayPos + 1;
    if (newPos >= 0 && newPos < this.inputField.listValue.length) {
      this.name = this.inputField.listValue[newPos];
      this.arrayPos = newPos;
    } else {
      this.name = this.inputField.listValue[oldPos];
    }
    if (this.outputStringNotUTC) {
      this.writeValue(PlcDateUtils.isoDateTimeToIsoDate(this.name));
      this.onChange(PlcDateUtils.isoDateTimeToIsoDate(this.name));
    } else {
      this.writeValue(PlcDateUtils.isoToDate(this.name));
      this.onChange(PlcDateUtils.isoToDate(this.name));
    }
  }

  ngOnInit() {

    const dates = this.inputField.listValue;
    this.arrayPos = dates.indexOf(this.inputField.defaultValue);
    if (this.arrayPos > -1) {
      this.name = dates[this.arrayPos];
    } else {
      this.name = null;
    }
  }

  onChange = (obj: any ) => {
  }

  writeValue(obj: any): void {
    if (obj) {

    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}

<div data-qa="life-expiry-option-detail" *ngIf="!!data && !!data.expiryOptionDetail && !!data.expiryOptionDetail?.risksExpiryOption && !!data.expiryOptionDetail?.risksExpiryOption.length">

    <div class="row">
        <div class="col-lg-12">
            <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
                <div class="row life-detail-card-title-container">
                    <div class="row life-detail-title">
                        <div class="card-title">
                            <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos"
                                style="cursor: pointer;" (click)="back()" data-qa="back-arrow"></span>
                            <span class="separator"> | </span>
                            <span class="menu-title" translate>lpc_expiry_option_detail</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12 life-detail-margin-box life-detail-box" data-qa="expiry_option_detail_table" id="consulting-detail">
        <div class="tbld life-detail-margin-top">
            <ng-container *ngFor="let risks of data.expiryOptionDetail?.risksExpiryOption">
                <div class="tbld_row life-detail-tbld_row_header">
                    <div class="tbld_col tbld_col_title">{{risks.description}}</div>
                    <div class="tbld_col tbld_col_title"></div>
                </div>
                <div *ngIf="risks.expiryOption" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_expiry_option_detail</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.expiryOption}}</div>
                </div>
                <div *ngIf="risks.status" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_status</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.status}}</div>
                </div>
                <div *ngIf="risks.annuityPaymentType" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_annuity_payment_type</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.annuityPaymentType}}</div>
                </div>
                <div *ngIf="risks.annuityFrequency" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_annuity_frequency</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.annuityFrequency}}</div>
                </div>
                <div *ngIf="risks.reversibilityPercentage" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_percentage_reversibility</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.reversibilityPercentage | rgiCountryLayerNumberFormat: currencyService.locale : numberFormatterOptions}}</div>
                </div>
                <div *ngIf="risks.reversionary" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_reversionary</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.reversionary}}</div>
                </div>
                <div *ngIf="risks.certainRevenueYears" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_certain_revenue_years</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.certainRevenueYears}}</div>
                </div>
                <div *ngIf="risks.postponementType" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_postponement_type</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.postponementType}}</div>
                </div>
                <div *ngIf="risks.postponementYears" class="tbld_row">
                    <div class="tbld_col tbld_col_value life-detail-table-border" translate>lpc_postponement_years</div>
                    <div class="tbld_col tbld_col_value life-detail-table-border">{{risks.postponementYears | rgiCountryLayerNumberFormat: currencyService.locale : numberFormatterOptions}}</div>
                </div>
            </ng-container>
        </div>
    </div>

</div>

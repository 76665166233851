import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcNavigationButtonsComponent} from './lpc-navigation-buttons.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
  declarations: [LpcNavigationButtonsComponent],
  exports: [
    LpcNavigationButtonsComponent
  ],
  imports: [
    CommonModule,
    RgiRxI18nModule
  ]
})
export class LpcNavigationButtonsModule { }

import {Component, ComponentRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Clause} from '../../models/domain-models/clause';
import {ContractClauseService} from '../contract-clause-service';
import {ClauseModalComponent} from '../../clause/clause-modal/clause-modal.component';

@Component({
  selector: 'mic-contract-clause-modal',
  templateUrl: '../../clause/clause-modal/clause-modal.component.html',
  styleUrls: ['../../clause/clause-modal/clause-modal.component.scss']
})
export class ContractClauseModalComponent extends ClauseModalComponent {

  componentRef: ComponentRef<any>;
  clause: Clause;
  clauseTextForm: UntypedFormGroup;

  constructor(
    protected clauseService: ContractClauseService,
    protected formBuilder: UntypedFormBuilder) {
    super(clauseService, formBuilder);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimsElasticComponent } from './claims-elastic.component';
import {
  CardService, containerCacheServiceProvider, enumServiceProvider, getSystemPropertyProvider
 } from '@rgi/digital-claims-common-angular';
import { ClaimsElasticSearchComponent } from './claims-elastic-search/claims-elastic-search.component';
import { ClaimsElasticListComponent } from './claims-elastic-list/claims-elastic-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [
        ClaimsElasticComponent,
        ClaimsElasticSearchComponent,
        ClaimsElasticListComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        NgbModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
    exports: [
        ClaimsElasticComponent
    ],
    providers: [
        containerCacheServiceProvider,
        { provide: 'claimsElasticSearchComponent', useValue: ClaimsElasticSearchComponent },
        { provide: 'claimsElasticListComponent', useValue: ClaimsElasticListComponent },
        enumServiceProvider,
        getSystemPropertyProvider
    ]
})
export class ClaimsElasticModule {
  constructor(
    cardService: CardService,
    ) {

    const title = '_CLAIMS_FROM_ELASTIC';
    const moduleName = 'claimsElastic';

    cardService.registerCard(
      moduleName,
      title,
      ClaimsElasticComponent,
    );


  }
}

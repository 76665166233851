<template #modalOverlay></template>
<div class="row nmf_informative_message">
  <span class="rgifont message-icon rgi-exclamation-circle">&nbsp;&nbsp;</span>
  <span *ngIf="savePolicyMessage">{{ savePolicyMessage }}</span>
</div>

<pnc-form-message *ngIf="validationMessages?.length > 0" [messages]="validationMessages" detail="validationError"
                  type="error">
</pnc-form-message>
<div class="btn-group btn-group-justified">
  <div *ngIf="showPrintButton" class="btn-group">
    <button (click)="openOutboundDocumentsModal()" class="btn btn-warning pull-right text-uppercase" data-qa="btn-policy-print"
            type="button">
      <span translate>PRINT</span>
    </button>
  </div>
  <div *ngIf="!isPrintButtonClicked && showOtp" class="btn-group">
    <button (click)="openOTP()" class="btn btn-warning pull-right text-uppercase" data-qa="btn-policy-opt"
            type="button">
      <span translate>OTP SIGNATURE</span>
    </button>
  </div>
  <div class="btn-group">
    <button (click)="collectsAccounting()" [disabled]="collectedAccounting" class="btn btn-warning pull-right text-uppercase"
            data-qa="btn-policy-collect" type="button">
      <span><span translate>COLLECT</span></span>
    </button>
  </div>
  <div class="btn-group">
    <button (click)="goToHome()" [disabled]="!isEndButtonEnabled" class="btn btn-warning pull-right text-uppercase"
            data-qa="btn-policy-end" type="button">
      <span translate>END</span>
    </button>
  </div>
</div>

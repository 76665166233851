import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { QuestionnairesManagerComponent } from '@rgi/questionnaires-manager';
import { Questionnaire, QuestionnaireCacheService, QuestionnairesManagerService } from '@rgi/questionnaires-manager';

@Component({
  selector: 'lpc-questionnaire[key][type][questionnairesCode]',
  templateUrl: './lpc-questionnaire.component.html',
  styleUrls: ['./lpc-questionnaire.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LpcQuestionnaireComponent implements OnChanges {

  @Input() public active: boolean;
  @Input() public key: string;
  @Input() public type: string;
  @Input() public questionnairesCode: Map<string, boolean>;
  @Input() public factors: any[] = [];
  @Input() public disabledQuestions: any[] = [];
  @Input() public defaultAnswers: any[] = [];
  @Input() public date;
  @Input() public productStructure;
  @Input() public productCode;
  @Input() public questType;
  @Input() public savedIds: string[];
  @Input() public customQuestLoader: string;
  @Input() public readOnlyCategories: string[];

  @Output() public loaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() foundQuestionnaires = new EventEmitter<boolean>();
  @Output() successMessage = new EventEmitter<string>();
  @Output() listLoad = new EventEmitter<void>();
  @Output() loaderQuest = new EventEmitter<string>();

  @ViewChild('questionnaireManager', {static: true}) questionnaireManager: QuestionnairesManagerComponent;

  private _questionnaires: Questionnaire[] = [];
  public get questionnaires(): Questionnaire[] {
    return this._questionnaires;
  }

  public get hasQuestionnaires(): boolean {
    return !!this.questionnaires.length;
  }

  /* private _keyType: string;
  public get keyType(): string {
    return this._keyType;
  } */

  public get questionnaireManagerService(): QuestionnairesManagerService {
    return this.questionnaireManager.questionnaireManagerService;
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isMandatoryUndefined()) {
      this.setMandatory();
    }
  }

  onListLoaded() {
    this._questionnaires = this.questionnaireManager.getQuest();
    this.loaded.emit(this.type);
  }

  onFoundQuestionnaires(event: boolean) {
    this.foundQuestionnaires.emit(event);
  }

  onSuccessMessage(event: any) {
    this.successMessage.emit(event);
  }

  loadQuestionnairesByCode(map: Map<string, Map<string, boolean>>): void {
    const subMap: Map<string, boolean> = map.get(this.type);
    this.questionnaireManager.loadQuestionnairesByCode(subMap);
  }

  listEmit(): void {
    if (this.questionnaireManager && this.questionnaireManager.questionnaireManagerService) {
      this.questionnaireManager.questionnaireManagerService.listChange.emit(
        this.questionnaireManager.questionnaireManagerService.getQuestionnaires()
      );
    }
  }

  private setMandatory() {
    // @ts-ignore
    const cacheService: QuestionnaireCacheService = this.questionnaireManager.questionnaireManagerService.cache;
    if (cacheService) {
      cacheService.setMandatory(this.questionnairesCode);
    }
  }

  private isMandatoryUndefined() {
    return this.questionnaires.length > 0 && this.questionnaires.every(x => x.mandatory === undefined);
  }

  loaderQuestManager(event: string) {
    this.loaderQuest.emit(event);
  }

  public updateQuestionnairesOnCacheBy(questionnaireKey: string, validQuestsCodeMap: Map<string, boolean> ) {
    // @ts-ignore
    const cache: Map<string, Map<Questionnaire, QuestionnaireFlatI>> = this.questionnaireManager.questionnaireManagerService.cache.cache;
    if (!!cache.get(questionnaireKey)) {
      [...cache.get(questionnaireKey).keys()].map(el => {
        if (![...validQuestsCodeMap.keys()].find(el1 => el1 === el.code)) {
         cache.get(questionnaireKey).delete(el);
        }
       });
    }
  }

  clearQuestionnaireByType(type) {
    this.questionnaireManager.clearQuestionnaireByType(type);
  }

  clearQuestionnaireByKey(key) {
    this.questionnaireManager.clearQuestionnaireByKey(key);
  }

  isQuestCompiled(key: any): boolean {
    return this.questionnaireManager.isQuestCompiled(key);
  }

  isQuestCompiledByType(type: any): boolean {
    return this.questionnaireManager.isQuestCompiledByType(type);
  }

}

import {Component, OnInit} from '@angular/core';
import {PncPsalesRequiredDataState} from '../../resources/states/required-data-state';
import {FormService} from '../../services/form.service';
import {RgiPncPostsalesStepComponent} from '../../api/rgi-pnc-postsales-step.component';
import {
  PncPsalesRequiredDataStateManager
} from '../../state-managers/required-data/required-data-state-manager.service';

@Component({
  selector: 'rgi-pnc-psales-required-data',
  templateUrl: './required-data.component.html'
})
export class PncPsalesRequiredDataComponent extends RgiPncPostsalesStepComponent<PncPsalesRequiredDataState> implements OnInit {


  constructor(
    stateMgr: PncPsalesRequiredDataStateManager,
    formService: FormService
  ) {
    super(stateMgr, formService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}

<ppevo-panel #panel [closable]="true" [showFooter]="false" (close)="close()">
  <div header>

  </div>
  <div body>
    <object *ngIf="pdfUrl" [data]="pdfUrl" type="application/pdf" width="100%" height="850">
      <p>
        It appears your Web browser is not configured to display PDF files. No worries, just <a [href]="pdfUrl">click
        here to download the PDF file.</a>
      </p>
    </object>
  </div>
</ppevo-panel>

import {Component, Inject, OnInit} from '@angular/core';
import {ActiveRoute, RoutingService} from "@rgi/rx/router";
import {TableSchema} from "@rgi/rx/ui";

@Component({
  selector: 'rgi-adapterprint-result',
  templateUrl: './adapterprint-result.component.html',
  host: {
    class: 'adapterprint-style'
  }
})
export class AdapterprintResultComponent implements OnInit {

  RESULTS: any;

  constructor(public activeRoute: ActiveRoute, private routingService: RoutingService, @Inject("sessionService") private sessionService: any) {
    this.RESULTS = this.activeRoute.getRouteData();
  }

  ngOnInit() {

  }


  schema: TableSchema = {
    rows: [
      {
        name: 'nodeDescr', title: '_ADAPTERPRINT_._LABEL_._NODE_',
      },
      {
        name: 'sendDate', title: '_ADAPTERPRINT_._LABEL_._DATE_SENT_',
      },
      {
        name: 'policy', title: '_ADAPTERPRINT_._LABEL_._POLICY_',
      },
      {
        name: 'documentType', title: '_ADAPTERPRINT_._LABEL_._DOCUMENT_TYPE_',
      },
      {
        name: 'addressed', title: '_ADAPTERPRINT_._LABEL_._ADDRESSED_',
      },
      {
        name: 'outcome', title: '_ADAPTERPRINT_._LABEL_._STATUS_',
      }
    ],
    header: ['nodeDescr', 'sendDate', 'policy', 'documentType', 'addressed','outcome']
  };

  back() {
    this.sessionService.remove(this.activeRoute.id);
  }
}

import {Inject, Injectable} from '@angular/core';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';

@Injectable({
  providedIn: 'root'
})
export class ConsultationListResourceService {
  constructor(
    private http: RgiRxHttpClientWrapper,
    @Inject('environment') private apiConf: any
  ) {}
}

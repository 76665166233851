import { Component, OnInit, Inject, Input } from '@angular/core';
import { SubjectEntity } from '../new-payment/domain/subject-entity';
import {
  ApiClaim,
  ApiTranslatorClaimService,
  CardComponent,
  CardComponentService,
  SessionService
} from '@rgi/digital-claims-common-angular';
import { UtilityService } from '../external-portfolio/utility.service';
import { NewPaymentService } from '../new-payment/new-payment.service';
import { ClaimSubjectsService } from './claim-subjects.service';
import { ClaimSubject } from './dto/claim-subject';
import { AnagFlowData, AnagIntegrationService, AnagRouteData } from '@rgi/anag';

@Component({
  selector: 'claims-claim-subjects',
  templateUrl: './claim-subjects.component.html',
  styleUrls: ['./claim-subjects.component.scss']
})
export class ClaimSubjectsComponent extends CardComponent implements OnInit {

  private claim: ApiClaim;
  claimSubjects: ClaimSubject[];
  nodeIdSxFitt: string;
  listFunz: any[];
  blockAnagCard: boolean;

  constructor(
    @Inject('containerCacheService') private cache,
    @Inject('cardPassService') private cardPassService: any,
    @Inject('claimsFinderIntegrationPassService') private claimsFinderIntegrationPassService: any,
    @Inject('AnagFinderSubject') private anagFinderSubject: any,
    @Inject('eventService') private eventService: any,
    @Inject('sessionService') private sessionServiceJs: any,
    @Inject('coreResult') private coreResult,
    @Inject('authService') private authService: any,
    @Inject('anagManageSubjectValidationService') private anagManageSubjectValidationService: any,
    private sessionServiceComm: SessionService,
    cardComponentService: CardComponentService,
    private utilityService: UtilityService,
    private claimSubjectsService: ClaimSubjectsService,
    private apiTranslatorClaimService: ApiTranslatorClaimService,
    private newPaymentService: NewPaymentService,
    public anagIntegrationService: AnagIntegrationService
  ) {
    super(cardComponentService, sessionServiceComm);
  }

  ngOnInit(): void {
    const jsonClaim = this.cache.getCache().get(this.card.idSession).data;
    this.claim = this.apiTranslatorClaimService.jsonClaimToApiClaim(jsonClaim);
    this.listFunz = this.authService.getOperator().enabledFeatures;
    // verifica abilitazione Anagrafe Modifica Direzionale del Soggetto RN
    // per attivare o meno modifica anagrafe unica su card nativa
    if (this.listFunz && (this.listFunz.indexOf('RN') < 0 && this.listFunz.indexOf('RX') < 0 && this.listFunz.indexOf('RM') < 0)) {
      this.blockAnagCard = true;
    } else {
      this.blockAnagCard = false;
    }
    this.getSubjectsFromClaim(this.claim);
  }

  getSubjectsFromClaim(claim: ApiClaim) {

    this.claimSubjectsService.getClaimSubjects(claim.idPotentialClaim, claim.idClaim)
      .subscribe((foundSubjects: ClaimSubject[]) => {

        this.claimSubjects = foundSubjects;

      });

  }

  editSubject(subject: ClaimSubject) {

    switch (subject.addresseeType) {

      // Attori accadimento
      case '1':
        // open anag card
        this.openAnagCard(subject);
        break;

      // Soggetti cruscotto
      case '2':
        this.jumpPartyModify(subject.id, 'partyDashboardModify');
        break;

      // Legali su contenziosi TL
      case '3':
        this.jumpPartyModify(subject.id, 'partyLawyerModify');
        break;

      // Fiduciari su incarichi
      case '4':
        this.jumpPartyModify(subject.id, 'partyExpertModify');
        break;

      default:
        break;
    }

  }

  openAnagCard(subject: ClaimSubject) {

    this.utilityService.getNodeSxFitt().subscribe((node: any) => {
      this.nodeIdSxFitt = node;

      const request = {
        idSubject: subject.passAnagId,
        idNode: this.nodeIdSxFitt,
        role: '888'
      };

      this.eventService.broadcastEvent('start-loader');
      this.anagFinderSubject.get(request).$promise.then(result => {
        if (result && result.subject) {
          this.createConfigurationObject(result.subject).then(configuration => {
            const flowData: AnagFlowData = new AnagFlowData();
            flowData.idParentSession = this.card.idSession;
            flowData.nodeCode = this.authService.getSalePointCode();
            flowData.partyRole='888';
            const anagRouteData: AnagRouteData = new AnagRouteData();
            anagRouteData.party = result.subject;
            anagRouteData.redirectToMainFlow = true;
            anagRouteData.flowData = flowData;
            // const idNavigation = that.sessionService.open('anagFinder', 'detail', '', true, null, null, that.card.idSession);
            this.anagIntegrationService.openEditSubject(anagRouteData, this.card.idSession);
           /*  const idNavigation = this.sessionServiceJs.open('rgiAnag', 'edit', '', true, null, null, this.card.idSession);
            const idSession = this.sessionServiceComm.idSessionFromId(idNavigation); */

            let session = this.sessionServiceJs.list().find(el => el.isActive);
            this.sessionServiceComm.registerSessionDeathCallback(session.idSession, () => {
              this.refreshParty(subject);
            });

            /*this.coreResult.setResult(idNavigation, 'newSubj', {
                subject: result.subject,
                configuration
            });

            this.coreResult.setResult(idNavigation, 'previousSession', {
              sessionId: this.card.idSession
            }); */
          });
        }
        this.eventService.broadcastEvent('stop-loader');

      });
      return false;
    });
  }

   createConfigurationObject(subject) {
    const filters = [
      { type: 'subject', data: subject }
    ];

    if (this.card.popupCardFilter) {
      filters.push({ type: 'popupCardFilter', data: this.card.popupCardFilter });
    }

    return this.anagManageSubjectValidationService.createConfigurationObject(filters);
  }

  refreshParty(subject: ClaimSubject) {

    const subjectEntity: SubjectEntity = this.fromClaimSubjectToSubjectEntity(subject);

    this.eventService.broadcastEvent('start-loader');
    this.newPaymentService.updateSubject(subjectEntity,
      this.authService.getOperator().liquidationCentre.code,
      this.authService.getOperator().objectId,
      this.claim.claimNumber).subscribe((subjectUpdated: SubjectEntity) => {
        this.reloadClaimOrPes();
      },
        (error: any) => {
          this.eventService.broadcastEvent('stop-loader');
        });

  }

  fromClaimSubjectToSubjectEntity(subject: ClaimSubject) {
    const subjectEntity: SubjectEntity = new SubjectEntity();

    // gli setto solo l'id che serve al servizio @Path("/potentialpayment/updateSubject") per estrarre il soggetto
    // anagrafico ed aggiornarlo sui sinistri
    subjectEntity.idSubjectPA = subject.passAnagId;

    return subjectEntity;
  }

  partyDashboardModify(id: string) {

    const act = {
      extension: null,
      jumpCommand: 'partyDashboardModify',
      menu: null
    };

    const filter = {
      partyId: id,
      claimId: this.claim.idClaim,
      section: 'actorActions',
      jumpFunction: ''
    };

    const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
    this.cardPassService.createJump(this.card, objIntegrationPass, 'Modifica soggetto cruscotto', 'action', 'claimsClaimsubjects');
  }

  jumpOccurrenceParty(id: string) {

    const act = {
      extension: null,
      jumpCommand: 'partyInvolvedClaim',
      menu: null,
      operation: { identification: '17', code: '000017', description: 'modifica soggetto' }
    };

    const filter = {
      partyId: id,
      section: 'actorActions',
      jumpFunction: ''
    };

    const jumpFunction = this.claimsFinderIntegrationPassService.getFunctionByJump(act, filter);
    filter.jumpFunction = jumpFunction;
    const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
    this.cardPassService.createJump(this.card, objIntegrationPass, 'Modifica Soggetto', 'home', 'claimsClaimsubjects');
  }

  reloadClaimOrPes() {

    let parameters = {};
    if (this.claim.claimNumber != null) {
      parameters = {
        idSession: this.card.idSession,
        objectReference: this.claim.claimNumber,
        objectType: 'claim',
        companyCode: this.claim.aniaCompanyCode
      };
    } else {
      parameters = {
        idSession: this.card.idSession,
        objectReference: this.claim.reportNumber,
        objectType: 'pes',
        companyCode: this.claim.aniaCompanyCode
      };
    }

    this.eventService.broadcastEvent('reloadClaim', parameters);
    this.getSubjectsFromClaim(this.claim);
  }

  // @Input() set reload(val: number) {
  //   if (val) {
  //     console.log('ricevuto evento reload: ', val);
  //     this.eventService.broadcastEvent('reloadClaim');
  //     this.reloadClaimOrPes();
  //   }
  // }

  jumpPartyModify(id: string, jumpCommandVal: string) {

    const act = {
      extension: null,
      jumpCommand: jumpCommandVal,
      menu: null
    };

    const filter = {
      partyId: id,
      claimId: this.claim.idClaim,
      section: 'actorActions',
      jumpFunction: '',
      idPotencialClaim: this.claim.idPotentialClaim,
    };

    const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
    this.cardPassService.createJump(this.card, objIntegrationPass, 'Modifica soggetto', 'action', 'claimsClaimsubjects');

  }
}

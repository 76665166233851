import {Injectable} from '@angular/core';
import {State} from './models/state';
import {Subject} from 'rxjs';
import {ProposalService} from './proposal.service';
import {Action} from './models/action';
import {ErrorMessagesService} from './error-messages.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private currentState: State = State.START;
  private previousState: State;
  private stateEmitter: Subject<State> = new Subject<State>();
  private resetStartForm = new Subject<boolean>();

  constructor(
    protected proposalService: ProposalService,
    protected errorMessagesService: ErrorMessagesService
  ) {
  }

  getCurrentState(): State {
    return this.currentState;
  }

  setCurrentState(state: State) {
    this.currentState = state;
  }

  nextState(action?: Action) {
    let nextState: State;

    switch (this.currentState) {
      case State.START: {
        if (action === Action.ALTERNATIVE_PLATE_WORKFLOW_STARTED) {
          nextState = State.PRODUCT_ASSET_SELECTION_ALTERNATIVE_PLATE;
        } else if (action === Action.WITHOUT_PLATE_WORKFLOW_STARTED) {
          nextState = State.PRODUCT_ASSET_SELECTION_WITHOUT_PLATE;
        } else if (action === Action.MULTIPLE_PRODUCTS_ASSETS_FOUND_STANDARD_PLATE_WORKFLOW) {
          nextState = State.PRODUCT_ASSET_SELECTION_STANDARD_PLATE;
        } else if (action === Action.DATA_IN_ANIA_NOT_FOUND_STANDARD_PLATE_WORKFLOW) {
          nextState = State.PRODUCT_ASSET_SELECTION_DATA_IN_ANIA_NOT_FOUND;
        } else if (action === Action.SINGLE_PRODUCT_ASSET_FOUND_STANDARD_PLATE_WORKFLOW) {
          if (this.isNotValidContract()) {
            nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
          } else {
            nextState = State.QUOTATION_STANDARD_PLATE;
          }
        } else if (action === Action.NON_STANDARD_VEHICLE_ORIGIN_IN_START_CARD_SELECTED) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        } else if (action === Action.STANDARD_VEHICLE_ORIGIN_IN_START_CARD_SELECTED) {
          nextState = State.QUOTATION_STANDARD_PLATE;
        }
        break;
      }
      case State.PRODUCT_ASSET_SELECTION_ALTERNATIVE_PLATE: {
        nextState = State.EDIT_ASSET_DATA_ALTERNATIVE_PLATE;
        break;
      }
      case State.PRODUCT_ASSET_SELECTION_DATA_IN_ANIA_NOT_FOUND: {
        nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        break;
      }
      case State.PRODUCT_ASSET_SELECTION_STANDARD_PLATE: {
        if (this.isNotValidContract() === true) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        } else {
          nextState = State.QUOTATION_STANDARD_PLATE;
        }
        break;
      }
      case State.PRODUCT_ASSET_SELECTION_WITHOUT_PLATE: {
        nextState = State.EDIT_ASSET_DATA_WITHOUT_PLATE;
        break;
      }
      case State.PRODUCT_ASSET_SELECTION_WRONG_DATA_IN_ANIA: {
        if (action === Action.CONFIRM_BUTTON_PRESSED) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        } else if (action === Action.CANCEL_BUTTON_PRESSED) {
          nextState = State.QUOTATION_STANDARD_PLATE;
        }
        break;
      }
      case State.EDIT_ASSET_DATA_WITHOUT_PLATE: {
        nextState = State.QUOTATION_WITHOUT_PLATE;
        break;
      }
      case State.EDIT_ASSET_DATA_STANDARD_PLATE: {
        nextState = State.QUOTATION_STANDARD_PLATE;
        break;
      }
      case State.EDIT_ASSET_DATA_ALTERNATIVE_PLATE: {
        nextState = State.QUOTATION_ALTERNATIVE_PLATE;
        break;
      }
      case State.QUOTATION_STANDARD_PLATE: {
        if (action === Action.NOT_MY_VEHICLE_BUTTON_PRESSED) {
          nextState = State.PRODUCT_ASSET_SELECTION_WRONG_DATA_IN_ANIA;
        } else if (action === Action.EDIT_ASSET_DATA_BUTTON_PRESSED) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        } else if (action === Action.NEXT_BUTTON_PRESSED) {
          if (this.proposalService.isProposal() === true || this.proposalService.isEntitledBeforeQuotation()) {
            nextState = State.PROPOSAL;
          } else {
            nextState = State.ENTITLED;
          }
        } else if (action === Action.SUBSTITUTION_NEXT_BUTTON_PRESSED) {
          nextState = State.TELEMATICS;
        }
        break;
      }
      case State.QUOTATION_ALTERNATIVE_PLATE: {
        if (action === Action.EDIT_ASSET_DATA_BUTTON_PRESSED) {
          nextState = State.EDIT_ASSET_DATA_ALTERNATIVE_PLATE;
        } else if (action === Action.NEXT_BUTTON_PRESSED) {
          if (this.proposalService.isProposal() === true || this.proposalService.isEntitledBeforeQuotation()) {
            nextState = State.PROPOSAL;
          } else {
            nextState = State.ENTITLED;
          }
        } else if (action === Action.SUBSTITUTION_NEXT_BUTTON_PRESSED) {
          nextState = State.TELEMATICS;
        }
        break;
      }
      case State.QUOTATION_WITHOUT_PLATE: {
        if (action === Action.EDIT_ASSET_DATA_BUTTON_PRESSED) {
          nextState = State.EDIT_ASSET_DATA_WITHOUT_PLATE;
        } else if (action === Action.NEXT_BUTTON_PRESSED) {
          if (this.proposalService.isProposal() === true || this.proposalService.isEntitledBeforeQuotation()) {
            nextState = State.PROPOSAL_WITHOUT_PLATE;
          } else {
            nextState = State.ENTITLED_WITHOUT_PLATE;
          }
        } else if (action === Action.SUBSTITUTION_NEXT_BUTTON_PRESSED) {
          nextState = State.TELEMATICS;
        } else if (action === Action.CONFIRM_BUTTON_PRESSED) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        } else if (action === Action.CANCEL_BUTTON_PRESSED) {
          nextState = State.QUOTATION_STANDARD_PLATE;
        }
        break;
      }
      case State.ENTITLED: {
        if (action === Action.CONFIRM_BUTTON_PRESSED) {
          nextState = State.PROPOSAL;
        }
        break;
      }
      case State.ENTITLED_WITHOUT_PLATE: {
        if (action === Action.CONFIRM_BUTTON_PRESSED) {
          nextState = State.PROPOSAL_WITHOUT_PLATE;
        }
        break;
      }
      case State.PROPOSAL: {
        if (action === Action.NEXT_BUTTON_PRESSED) {
          nextState = State.TELEMATICS;
        } else if (action === Action.EDIT_QUOTATION_BUTTON_PRESSED || action === Action.SUBSTITUTION_NEXT_BUTTON_PRESSED) {
          nextState = State.QUOTATION_STANDARD_PLATE;
        } else if (action === Action.SUBSTITUTION_EDIT_ASSET) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        }
        break;
      }
      case State.PROPOSAL_WITHOUT_PLATE: {
        if (action === Action.NEXT_BUTTON_PRESSED) {
          nextState = State.TELEMATICS;
        } else if (action === Action.EDIT_QUOTATION_BUTTON_PRESSED || action === Action.SUBSTITUTION_NEXT_BUTTON_PRESSED) {
          nextState = State.QUOTATION_WITHOUT_PLATE;
        } else if (action === Action.SUBSTITUTION_EDIT_ASSET) {
          nextState = State.EDIT_ASSET_DATA_WITHOUT_PLATE;
        }
        break;
      }
      case State.TELEMATICS: {
        if (action === Action.ISSUE_POLICY) {
          nextState = State.SUMMARY;
        }
        if (action === Action.EDIT_QUOTATION_BUTTON_PRESSED) {
          nextState = State.QUOTATION_STANDARD_PLATE;
        }
        if (action === Action.AMENDMENT_SAVE_BUTTON_PRESSED) {
          nextState = State.SUMMARY;
        }
        if (action === Action.EDIT_PROPOSAL) {
          if (this.proposalService.getPlateNumber()?.plateNumber) {
            nextState = State.PROPOSAL;
          } else {
            nextState = State.PROPOSAL_WITHOUT_PLATE;
          }
        }
        break;
      }
      case State.SUBSTITUTED_CONTRACTS: {
        if (action === Action.NEXT_BUTTON_PRESSED) {
          nextState = State.PROPOSAL;
        }
        break;
      }
      case State.INCLUSION_APPLICATION: {
        this.proposalService.setIsInclusionApplication(true);
        if (this.isNotValidContract() === true) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        } else {
          nextState = State.QUOTATION_STANDARD_PLATE;
        }
        break;
      }
      case State.AMENDMENT_DATES: {
        if (action === Action.NEXT_BUTTON_PRESSED) {
          nextState = State.EDIT_ASSET_DATA_STANDARD_PLATE;
        }
        break;
      }
      default: {
        break;
      }
    }

    if (nextState) {
      this.setPreviousState(this.currentState);
      this.updateState(nextState);
    }
  }

  isNotValidContract() {
    return this.proposalService.existCompletenessErrors()
      || this.proposalService.isFiscalCodeNotConsistent()
      || this.proposalService.isInsuranceStatusNoLongerValid()
      // condition below: to be moved to BE
      || this.proposalService.isEntitledBeforeQuotation();
  }

  resetState() {
    this.updateState(State.START);
  }

  updateState(state: State) {
    this.currentState = state;
    this.stateEmitter.next(this.currentState);
  }

  getStateChange() {
    return this.stateEmitter.asObservable();
  }

  public subscribeToResetStartForm() {
    return this.resetStartForm.asObservable();
  }

  public sendResetStartFormEvent() {
    this.resetStartForm.next(true);
  }

  public getPreviousState() {
    return this.previousState;
  }

  public setPreviousState(previousState: State) {
    this.previousState = previousState;
  }

}

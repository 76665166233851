import { Factor } from '../../../models/factor.model';
import { Component, ViewEncapsulation, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';


@Component({
  selector: 'lpc-input-factor',
  templateUrl: './lpc-input-factor.component.html',
  styleUrls: ['./lpc-input-factor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LpcInputFactorComponent implements OnInit {
  value: string;
  config: Factor;
  group: UntypedFormGroup;
  prevVal: string;
  showTooltip = false;
  tooltipMessage: string;
  configValue: string;
  modifiable: string;
  mandatory: string;


  constructor(protected el: ElementRef) {
  }

  ngOnInit() {
    if (this.config.value === '-1') {
      this.prevVal = null;
    } else {
      this.prevVal = this.config.value;
    }

    this.initInput();
  }

  onBlur() {
    if (this.group.controls[this.config.code].value != null && this.group.controls[this.config.code].value.trim() !== EMPTY_STR) {
      if (this.prevVal !== this.group.controls[this.config.code].value) {
        this.el.nativeElement.dispatchEvent(new CustomEvent('changeVal',  { bubbles: true, detail: this.config.code }));
        this.prevVal = this.group.controls[this.config.code].value;
      }
    } else {
      this.group.controls[this.config.code].setValue(null);
    }
  }

  initInput() {
    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.value === null || this.config.value === EMPTY_STR || this.config.value === '-1') {
      this.group.controls[this.config.code].setValue(null);
    } else {
      this.group.controls[this.config.code].setValue(this.config.value);
    }
    if (this.config.compulsory) {
      this.mandatory = 'Obbligatorio';
      this.group.controls[this.config.code].setValidators(Validators.required);
    } else {
      this.mandatory = 'Non obbligatorio';
    }
    if (this.config.modifiable) {
      this.modifiable = 'Modificabile';
      this.group.controls[this.config.code].enable();
    } else {
      this.modifiable = 'Non modificabile';
      this.group.controls[this.config.code].disable();
    }
  }

  setFactor(factor: Factor, form: UntypedFormGroup) {
    this.config = factor;
    this.value = this.config.description;
    this.group = form;
  }

  isTABNOorTABSI() {
    return this.config.code === 'TABSI' || this.config.code === 'TABNO';
  }

}

import {Injectable} from '@angular/core';
import {UnitSection} from '../../models/domain-models/unit-section';
import {Unit} from '../../models/domain-models/unit';
import {Variable} from '../../models/domain-models/variable';
import {ExtendedUnit} from '../../models/domain-models/extended-unit';

@Injectable({
  providedIn: 'root'
})
export class VariableUtilityService {

  constructor() {
  }

  getVariableId(section: UnitSection, unit: Unit, unitVariable: Variable): string {
    return section.code + '_' + unit.id + '_' + unitVariable.code;
  }

  getVariableIdFromExtended(extendedUnit: ExtendedUnit, unitVariable: Variable): string {
    return this.getVariableId(extendedUnit.unitSection, extendedUnit, unitVariable);
  }
}

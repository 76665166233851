<div class="ppevo-survey-evaluation" *ngIf="st.initialized">

    <div
        class="ppevo-survey-header"
        [class.ppevo-survey-header-sticky]="isHeaderSticky"
        rgiRxStickableChecker="top"
        (stickableCheckerChange)="isHeaderSticky=$event.isOutSideViewport"
        survey-eval-header
        [st]="st"
        *rgiRxDecorable="'survey-eval-header'; context: {st: st}"
    >
    </div>

    <survey-eval-products *ngIf="st.productsCfg.hasVisibleProds" [st]="st"></survey-eval-products>

    <div *ngIf="!st.productsCfg.hasVisibleProds" class="ppevo-survey-eval-noproducts" translate>NG_PASSPROPRO_SURVEY.eval-no-products</div>

    <ppevo-navbar (btnClick)="onBtnClick($event)" [stickable]="st.navigation.stickable" *ngIf="st.navigation">
        <ng-container *ngFor="let btn of st.navigation.map | keyvalue: preserveKeysOrder">
            <ppevo-navbtn *ngIf="btn.value.visible" [btnId]="btn.key" [ngClass]="btn.value.styleClass" translate>
                {{btn.value.label}}</ppevo-navbtn>
        </ng-container>
    </ppevo-navbar>
</div>

<pp-toast [ngClass]="'toast-validations'" showBar="true" title="ppevo-validation-notifications-title" tag="validations"></pp-toast>

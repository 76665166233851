import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '@rgi/rx/ui';
import { DscDriverLicenseModalComponent } from './dsc-driver-license-modal/dsc-driver-license-modal.component';
import { InsurancePolicy } from '../dsc-shared-model/dsc-model-policy';
import { InjuryData } from '../dsc-shared-model/dsc-model-injury';
import { Guardian, HeirsListSubjLink, SubjectData, SubjectReduced } from '../dsc-shared-model/dsc-model-subject';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import { CategoryDetail } from '../dsc-shared-model/dsc-model-utility';
import { AnagFlowData, AnagIntegrationService, AnagRouteData } from '@rgi/anag';

@Component({
  selector: 'claims-dsc-driver',
  templateUrl: './dsc-driver.component.html',
  styleUrls: ['./dsc-driver.component.scss']
})
export class DscDriverComponent implements OnInit {

  isAccordion: boolean = false;
  @Input() driver: SubjectReduced;
  @Input() roles: any;
  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Input() selectedNode: string;
  @Input() policyNode: any;
  @Input() activeRouteId: string;
  @Input() selectedPolicy: InsurancePolicy;
  @Input() categorySelected: number;
  @Input() categoryList: CategoryDetail[] = [];
  @Input() chkInjury: boolean;
  @Input() heirsList: HeirsListSubjLink;
  @Input() listMinorGuardian: Guardian[] = [];
  subjectData: SubjectData;
  subjectDataInjury: InjuryData;
  expanded: boolean = true;
  fields =
  ['chkMaterial',
  'materialDescription',
  'chkInjury'];
  localContext: any;

  @Output() outputDataForm = new EventEmitter<{subject: SubjectReduced, roles? }>();
  @Output() outputHeir = new EventEmitter<string>();

constructor(
  private modalServiceRgi: ModalService,
  @Inject('sessionService') private sessionService: any,
  @Inject('coreResult') private coreResult: any,
  public anagIntegrationService: AnagIntegrationService,
  @Inject('authService') private authService
) {
  this.localContext = authService.getDefaultContext();
}
  ngOnInit(): void {
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
  }
  outputFormChange(event: any, listMinorGuardian: Guardian[]) {
    this.chkInjury = event.chkInjury;
    const dataChange = { ...this.driver };
    dataChange.chkMaterial = event.chkMaterial;
    dataChange.materialDescription = event.materialDescription;
    dataChange.chkInjury = event.chkInjury;
    dataChange.isValidForm = event.isValidForm;
    dataChange.isIncapacitated = event.isIncapacitated;
    dataChange.isMinor = event.isMinor;
    dataChange.listMinorGuardianSubject = listMinorGuardian;
    this.subjectData = { subject: dataChange , roles: this.roles };
    this.outputDataForm.emit(this.subjectData );
  }

  outputFormChangeInjury(event: InjuryData) {
    this.subjectDataInjury = { subject: this.driver, dataForm: event.dataForm };
    this.outputDataForm.emit(this.subjectDataInjury);
  }

  selectNewSubject(event) {
    this.driver = {...event};
    if (this.driver.objectId === '') {
      this.chkInjury = false;
    }
    this.outputDataForm.emit({subject: this.driver, roles: this.roles });
  }
  deleteSubject(event) {
    this.driver = event;
    this.outputDataForm.emit({subject: this.driver, roles: this.roles });
  }
  openModalAddDriverLicense() {
    this.modalServiceRgi.open(DscDriverLicenseModalComponent,
      this.driver.objectId
    );
  }
  setAccordion() {
    this.expanded = !this.expanded;
  }
  openDetail() {
    const flowData: AnagFlowData = new AnagFlowData();
    flowData.idParentSession = this.activeRouteId;
    flowData.nodeCode = this.selectedNode;
    flowData.partyRole='888';
    const anagRouteData: AnagRouteData = new AnagRouteData();
    const subject: any = this.driver.subject.anagSubject;

    if (subject && !subject.node && this.policyNode) {
      subject.node = this.policyNode;
    }

    anagRouteData.party = subject;
    anagRouteData.redirectToMainFlow = true;
    anagRouteData.flowData = flowData;
    (this.anagIntegrationService as any).openDetailSubject(anagRouteData, this.activeRouteId);
   /*  const idNavigation = this.sessionService.open('anagFinder', 'detail', '', true, null, null, this.activeRouteId);
    this.coreResult.setResult(idNavigation, 'detail', {
      subject: this.driver.subject.anagSubject
    }); */
    
  }

listMinorGuardianChange(event) {
  this.listMinorGuardian = event;
  this.outputFormChange(null, this.listMinorGuardian)
}
  outputHeirData(event) {
    this.outputHeir.emit(event);
  }
}

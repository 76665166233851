import {Injectable} from '@angular/core';
import {EnumValues} from '../anag-model/enum-api/enums-api';
import {AnagEntityIta} from '../anag-model/anag-api/anag-subject-api';
import {AnagCountry} from '../anag-model/anag-domain/anag-country';
import {AnagAddressLayoutConfig} from '../anag-model/anag-domain/anag-address-config';
import {AnagResourceService} from './anag-resource.service';
import {AnagIdentificationEntity} from '../anag-model/anag-domain/anag-identification-entity';
import {Observable, of} from 'rxjs';
import {AnagUmaAddressService} from '../anag-components/uma-address/anag-uma-address.service';
import {map} from 'rxjs/operators';
import {AnagPartyKey} from '../anag-model/anag-domain/anag-api-party';
import {AnagApiPartyKeyConfigRequest} from '../anag-model/anag-api/anag-api-party-key-config-request';
import {ENUMCODES, PROFESSION_ENUM, RISK_CLASSIFICATION_ENUM, REASON_CLASSIFICATION_ENUM, BASIS_CLASSIFICATION_ENUM} from '../anag-constants/enums-constants';
import {
  AnagApiSystemProperties,
  AnagApiSystemPropertiesRequest
} from '../anag-model/anag-api/anag-api-system-properties';
import {PaymentConfigAnag} from '../anag-model/anag-domain/anag-payments';
import {Agency} from '../anag-model/anag-api/sales-network-api-agency';
import {ExtendedFilter} from '../anag-model/anag-api/anag-api-subapp-adminlevel';
import {AnagIntPrefixEntity} from '../anag-model/anag-domain/anag-int-prefix-entity';
import {
  AnagCheckDrivingLicenseRequest,
  AnagCheckDrivingLicenseResponse,
  AnagDrivingLicenseTypeList
} from "../anag-model/anag-domain/anag-document";
import {AnagCompany} from "../anag-model/anag-domain/anag-company";

@Injectable()
export class AnagStorageService {

  private _enums: Array<EnumValues>;
  private _countriesList: Array<AnagCountry>;
  private _economyActivityGroups: Map<string, Array<AnagEntityIta>> = new Map();
  private _addressConfigs: Map<string, AnagAddressLayoutConfig> = new Map();
  private _adminLevelCache: Map<string, Array<AnagIdentificationEntity>> = new Map();
  private _enumTable: Map<string, Array<AnagEntityIta>> = new Map();
  private _systemProperties: Map<string, AnagApiSystemProperties> = new Map();
  private _partyKeys: Map<string, AnagPartyKey> = new Map();
  private _professionDetails: Map<string, Array<AnagEntityIta>> = new Map();
  private _privacyEnums: AnagEntityIta [] = [];
  private _internationalPrefixes: Array<AnagIdentificationEntity> = [];
  private _linkOptions: Map<string, Array<AnagIdentificationEntity>> = new Map();
  private _nodes: Agency[] = [];
  private _nodesx: Agency;
  private _privacyOriginEnums: AnagEntityIta [] = [];
  private _riskClassifications: Map<string, Array<AnagEntityIta>> = new Map();
  private _reasonClassifications: Map<string, Array<AnagEntityIta>> = new Map();
  private _basisClassifications: Map<string, Array<AnagEntityIta>> = new Map();
  private _drivingLicenseTypeList: Map<string, Array<AnagDrivingLicenseTypeList>> = new Map();
  private _checkDrivingLicense: Map<string, AnagCheckDrivingLicenseResponse> = new Map();
  private _companies: AnagCompany[] = [];

  constructor(
    public anagResources: AnagResourceService,
    public addressService: AnagUmaAddressService
  ) {
    this.anagResources.getEnumsConfig$({enumList: {codes: ENUMCODES}}).subscribe(
      enumsResp => this._enums = enumsResp.enums
    );
  }

  getCountryByCode(countryCode: string): AnagCountry {
    return this._countriesList.find(country => country.abbreviation === countryCode);
  }

  get countries(): Array<AnagCountry> {
    if (!this._countriesList) {
      this._countriesList = [];
      this.anagResources.retrieveCountries$().subscribe(countriesResp => {
        if (countriesResp.countriesList) {
          this.setCountriesList(countriesResp.countriesList);
        }
      });
    }
    return this._countriesList;
  }

  setCountriesList(value: Array<AnagCountry>) {
    this._countriesList = value;
  }

  getEnumsByCode(code: string): Array<AnagEntityIta> {
    return this._enums ? this._enums.find(enumElement => {
      return enumElement.code === code;
    }).values : null;
  }

  set enums(value: Array<EnumValues>) {
    this._enums = value;
  }

  addEconomyActivityGroup(enumCode, values: Array<AnagEntityIta>) {
    this._economyActivityGroups.set(enumCode, values);
  }

  getEconomyActivityGroupBySubgroupCode(code: string): Array<AnagEntityIta> {
    return this._economyActivityGroups.get(code);
  }

  setAddressConfig(addressType: string, countryCode: string, config: AnagAddressLayoutConfig) {
    const key = `${addressType}-${countryCode}`;
    this._addressConfigs.set(key, config);
  }

  getAddressConfig(addressType: string, countryCode: string): Observable<AnagAddressLayoutConfig> {
    const key = `${addressType}-${countryCode}`;
    if (this._addressConfigs.has(key)) {
      return of(this._addressConfigs.get(key));
    } else {
      this.setAddressConfig(addressType, countryCode, new AnagAddressLayoutConfig());
      return this.addressService.getAddressConfig$(addressType, countryCode).pipe(
        map(resp => {
          this.setAddressConfig(addressType, countryCode, resp.addressConfig);
          return resp.addressConfig;
        })
      );
    }
  }

  setAdminLevelValues(addressType: string, countryCode: string, adminLevel: number, filterValue: string,
                      values: Array<AnagIdentificationEntity>, extendedFilter?: ExtendedFilter) {
    const key = this.buildCacheKey(addressType, countryCode, adminLevel, filterValue, extendedFilter);
    this._adminLevelCache.set(key, values);
  }

  private buildCacheKey(addressType: string, countryCode: string, adminLevel: number, filterValue: string, extendedFilter: ExtendedFilter) {
    let key = `${addressType}-${countryCode}-${adminLevel}-${filterValue}`;
    if (!!extendedFilter) {
      key += `_${extendedFilter.filterLvl}-${extendedFilter.filterValue}`;
    }
    return key;
  }

  getAdminLevelValues$(addressType: string, countryCode: string, adminLevel: number, filterValue: string,
                       extendedFilter?: ExtendedFilter): Observable<Array<AnagIdentificationEntity>> {
    const key = this.buildCacheKey(addressType, countryCode, adminLevel, filterValue, extendedFilter);
    if (this._adminLevelCache.has(key)) {
      return of(this._adminLevelCache.get(key));
    } else {
      this.setAdminLevelValues(addressType, countryCode, adminLevel, filterValue, [], extendedFilter);
      return this.addressService.getAdminLevelValues$(addressType, countryCode, adminLevel, filterValue, extendedFilter).pipe(
        map(resp => {
          const adminLevelValues = resp.output.map(val => {
            return !!val['adminLevel' + adminLevel] ? val['adminLevel' + adminLevel] : val;
          });
          this.setAdminLevelValues(addressType, countryCode, adminLevel, filterValue, adminLevelValues, extendedFilter);
          return adminLevelValues;
        })
      );
    }
  }

  getEnumtable$(enumTable: string): Observable<AnagEntityIta[]> {
    if (this._enumTable && this._enumTable.has(enumTable)) {
      return of(this._enumTable.get(enumTable));
    } else {
      this._enumTable.set(enumTable, null);
      return this.anagResources.getEnumtable(enumTable).pipe(
        map(resp => {
          this._enumTable.set(enumTable, resp.enumList);
          return resp.enumList;
        })
      );
    }
  }

  getSystemProperties$(property: string): Observable<AnagApiSystemProperties> {
    if (this._systemProperties && this._systemProperties.has(property)) {
      return of(this._systemProperties.get(property));
    } else {
      this._systemProperties.set(property, null);
      const request = new AnagApiSystemPropertiesRequest();
      request.filterKey = {
        keys: [property]
      };
      return this.anagResources.getSystemProperties(request).pipe(
        map(resp => {
          this._systemProperties.set(property, resp.systemProperties[0]);
          return resp.systemProperties[0];
        })
      );
    }
  }

  getPartyKeysByFilter(filter: AnagApiPartyKeyConfigRequest): Observable<AnagPartyKey> {
    return this.anagResources.getPartyKeyConfig$(filter).pipe(
      map(resp => {
        if (resp.result && resp.result.partyKey) {
          return resp.result.partyKey;
        }
      })
    );
  }

  setRiskClassifications(riskClassTypeCode: string, riskClassifications: Array<AnagEntityIta>) {
    this._riskClassifications.set(riskClassTypeCode, riskClassifications);
  }

  getRiskClassifications(riskClassTypeCode: string): Observable<Array<AnagEntityIta>> {
    if (this._riskClassifications.has(riskClassTypeCode)) {
      return of(this._riskClassifications.get(riskClassTypeCode));
    } else {
      this.setRiskClassifications(riskClassTypeCode, []);
      return this.anagResources.getEnumByCode$(RISK_CLASSIFICATION_ENUM, riskClassTypeCode).pipe(
        map(resp => {
          this.setRiskClassifications(riskClassTypeCode, resp.enumList);
          return resp.enumList;
        })
      );
    }
  }

  setReasonClassifications(reasonClassTypeCode: string, reasonClassifications: Array<AnagEntityIta>) {
    this._reasonClassifications.set(reasonClassTypeCode, reasonClassifications);
  }

  getReasonClassifications(reasonClassTypeCode: string): Observable<Array<AnagEntityIta>> {
    if (this._reasonClassifications.has(reasonClassTypeCode)) {
      return of(this._reasonClassifications.get(reasonClassTypeCode));
    } else {
      this.setReasonClassifications(reasonClassTypeCode, []);
      return this.anagResources.getEnumByCode$(REASON_CLASSIFICATION_ENUM, reasonClassTypeCode).pipe(
        map(resp => {
          this.setReasonClassifications(reasonClassTypeCode, resp.enumList);
          return resp.enumList;
        })
      );
    }
  }

  setBasisClassifications(basisClassTypeCode: string, basisClassifications: Array<AnagEntityIta>) {
    this._basisClassifications.set(basisClassTypeCode, basisClassifications);
  }

  getBasisClassifications(basisClassTypeCode: string): Observable<Array<AnagEntityIta>> {
    if (this._basisClassifications.has(basisClassTypeCode)) {
      return of(this._basisClassifications.get(basisClassTypeCode));
    } else {
      this.setBasisClassifications(basisClassTypeCode, []);
      return this.anagResources.getEnumByCode$(BASIS_CLASSIFICATION_ENUM, basisClassTypeCode).pipe(
        map(resp => {
          this.setBasisClassifications(basisClassTypeCode, resp.enumList);
          return resp.enumList;
        })
      );
    }
  }

  setProfessionDetails(professionCode: string, details: Array<AnagEntityIta>) {
    this._professionDetails.set(professionCode, details);
  }

  getProfessionDetails(professionCode: string): Observable<Array<AnagEntityIta>> {
    if (this._professionDetails.has(professionCode)) {
      return of(this._professionDetails.get(professionCode));
    } else {
      this.setProfessionDetails(professionCode, []);
      return this.anagResources.getEnumByCode$(PROFESSION_ENUM, professionCode).pipe(
        map(resp => {
          this.setProfessionDetails(professionCode, resp.enumList);
          return resp.enumList;
        })
      );
    }
  }

  getPrivacyEnums(): Observable<Array<AnagEntityIta>> {
    if (this._privacyEnums.length > 0) {
      return of(this._privacyEnums);
    } else {
      return this.anagResources.getEnumtable('PAPrivacy').pipe(
        map(resp => {
          this._privacyEnums = resp.enumList.sort((a, b) => {
            return a.codice.localeCompare(b.codice);
          });
          return this._privacyEnums;
        })
      );
    }
  }

  getOriginEnums(): Observable<Array<AnagEntityIta>> {
    if (this._privacyOriginEnums.length > 0) {
      return of(this._privacyOriginEnums);
    } else {
      return this.anagResources.getEnumtable('PAPRIVACYCONSORIG').pipe(
        map(resp => {
          this._privacyOriginEnums = resp.enumList;
          return this._privacyOriginEnums;
        })
      );
    }
  }

  getInternationalPrefixes(): Observable<Array<AnagIdentificationEntity>> {
    if (this._internationalPrefixes.length > 0) {
      return of(this._internationalPrefixes);
    } else {
      return this.anagResources.getInternationalPrefixes$().pipe(
        map(resp => {
          this._internationalPrefixes = resp.internationalPrefixes;
          return this._internationalPrefixes;
        })
      );
    }
  }
  getIntPrefixes(countryCode: string): Observable <AnagIntPrefixEntity[]> {
      return this.anagResources.getIntPrefixes$(countryCode).pipe(
        map(resp => {
          return resp[0].intPrefixes;
        })
      );
  }
  setDrivingLicenseTypeConfig(countryCode: string, config: AnagDrivingLicenseTypeList[]) {
    const key = `${countryCode}`;
    this._drivingLicenseTypeList.set(key, config);
  }

  getDrivingLicenseType(countryCode: string): Observable<Array<AnagDrivingLicenseTypeList>> {
    const key = `${countryCode}`;
    if (this._drivingLicenseTypeList.has(key)) {
      return of(this._drivingLicenseTypeList.get(key));
    } else {
      return this.anagResources.getDrivingLicenseType$(countryCode).pipe(
        map(resp => {
          this.setDrivingLicenseTypeConfig(countryCode, resp);
          return resp;
        })
      );
    }
  }
  getLinkType(codice: string, linkPersonType: string): Observable<Array<AnagIdentificationEntity>> {
    const key = `${codice}-${linkPersonType}`;
    if (this._linkOptions.has(key)) {
      return of(this._linkOptions.get(key));
    } else {
      return this.anagResources.getLinkTypes$(codice, [linkPersonType]).pipe(
        map(resp => {
          this._linkOptions.set(key, resp.availableLinkTypesList[0].availableLinkType);
          return this._linkOptions.get(key);
        })
      );
    }
  }

  retrieveMeansOfPayment(paymentFunction: string) {
    const req = {} as PaymentConfigAnag;
    req.function = paymentFunction;
    return this.anagResources.retrievePaymentTypes$(req);
  }

  setNodeId(nodeId: string) {
    return this.anagResources.setNodeId(nodeId);
  }

  getNodes$(): Observable<Agency[]> {
    if (this._nodes.length) {
      return of(this._nodes);
    } else {
      return this.anagResources.getNodes$().pipe(
        map(nodes => {
          this._nodes = nodes;
          return nodes;
        })
      );
    }
  }

  getNodesSinistri$(valueSx: string): Observable<Agency> {
    return this.anagResources.getNodesSinistri$(valueSx).pipe(
      map(node => {
        this._nodesx = node;
        return node;
      })
    );

  }


  setCheckDrivingLicense(filter: AnagCheckDrivingLicenseRequest, response: AnagCheckDrivingLicenseResponse) {
    const key = this.createKeyDrivingLicense(filter);
    this._checkDrivingLicense.set(key, response);
  }
  checkDrivingLicense(filter: AnagCheckDrivingLicenseRequest) {
    const key = this.createKeyDrivingLicense(filter);
    if (this._checkDrivingLicense.has(key)) {
      return of(this._checkDrivingLicense.get(key));
    } else {
      return this.anagResources.checkDrivingLicense$(filter).pipe(
        map(resp => {
          this.setCheckDrivingLicense(filter, resp);
          return resp;
        })
      );
    }
  }

  createKeyDrivingLicense(filter: AnagCheckDrivingLicenseRequest) {
    let key = `${filter.birthDate}-${filter.licenseExpirationDate}`;
    filter.drivingLicenses.forEach(driving => {
      key += `-${driving.idTypeDrivingLicense}-${driving.dstartDrivingLicense}-${driving.dendDrivingLicense}-${driving.dqcManagement}`
    })
    return key;
  }

  get companies() {
    return this._companies;
  }

  setCompanies(companies: Array<AnagCompany>) {
    this._companies = companies;
  }
}

<div>
  <form [formGroup]="newRequestForm" data-qa="new-request-form">

    <div class="col-sm-12 container-new-elaboration-request">

      <div class="col-sm-6">
        <label class="label-core" ng-bind="label">{{'_CLAIMS_._ACTIONS' | translate}}</label>
        <select (change)="onActionChange($event); checkMaxJson()" class="core-select form-control" formControlName="action" id="action"
          name="action" attr.data-qa="action-list-select">
          <option></option>
          <option *ngFor="let action of actionsList; let i = index;" [value]="i">
            {{action.actionCode}} - {{action.actionTitle}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>

      <div class="col-sm-1">
        <div style="padding-top: 3px;"><label></label></div>
        <!-- <input [value]="" disabled style="width: 95%;"> -->
        <span (click)="modal.open()">
            <span class="glyphicon glyphicon-info-sign" style="color: blue;"></span></span>
        <rgi-rx-modal #modal>
            <rgi-rx-panel>
                <rgi-rx-panel-header [closeable]="true" (onClose)="modal.close()"></rgi-rx-panel-header>
                <rgi-rx-panel-footer>
                    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modal.close()">close</button>
                </rgi-rx-panel-footer>
                <div *ngIf="selectedAction">
                  <div style="margin-bottom: 20px;">
                  {{selectedAction.actionDescription}}
                  </div>
                  <div>
                    {{formattedActionEntity}}
                  </div>
              </div>
            </rgi-rx-panel>
        </rgi-rx-modal>
    </div>

      <div class="col-sm-5">
        <label class="label-core" ng-bind="label">{{'_CLAIMS_._REQUEST_TYPE' | translate}}</label>
        <select (change)="checkMaxJson()" class="core-select form-control" formControlName="requestType"
          id="requestType" name="requestType" attr.data-qa="request-type-list-select">
          <option></option>
          <option *ngFor="let requestType of requestTypeList; let i = index;" [value]="i">
            {{requestType.eTypeDescription}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>

      <div class="col-sm-6">
        <label class="label-core" attr.data-qa="new-request-code-chain-label">{{'_CLAIMS_._CODE_CHAIN' | translate}}</label>
        <input class="form-control" formControlName="codeChain" id="codeChain" type="text" name="codeChain"
          attr.data-qa="new-request-codeChain-input">
      </div>

      <div class="col-sm-6">
        <pnc-datepicker [disabled]=" selectedRequestType != r.RESERVATION "
          [label]="'_CLAIMS_._ELABORATION_REQUEST_DATE' | translate" customFormControlName="elaborationRequestDate"
          [customFormGroup]="newRequestForm" [minDate]="today"
          attr.data-qa="new-request-elaboration-request-date-input">
        </pnc-datepicker>
      </div>

      <div class="col-sm-12">
        <label class="label-core" attr.data-qa="new-request-external-key-label">{{ '_CLAIMS_._EXTERNAL_KEY' |
          translate}}</label>
        <input class="form-control" formControlName="externalKey" id="externalKey" type="text" name="externalKey"
          attr.data-qa="new-request-external-key-input">
      </div>

    </div>
  </form>
</div>


<div>
  <div class="container-fluid table-white">
    <label rgiRxLabel>{{'_CLAIMS_._INPUT_PARAMETERS' | translate}}</label>
    <div class="row">
      <div class="head-result thDataTable col-md-12">
        <div align="left" class="col-md-3">
          <span class="bold">{{'_CLAIMS_._ID' | translate}}</span>
        </div>
        <div align="left" class="col-md-8">
          <span class="bold">{{'_CLAIMS_._JSON' | translate}}</span>
        </div>
        <div align="left" class="col-md-1">
          <span class="bold">{{'_CLAIMS_._ACTIONS' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid table-white" *ngFor="let json of jsonData; let even = even; let odd = odd; index as i">
    <div [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="row">
      <div class="col-md-12">
        <div class="col-md-2">
          <span>{{json.externalKey}}</span>
        </div>
        <div class="col-md-9">
          <div class="title-json-cut">{{json.parameter}}</div>
        </div>
        <div class="col-md-1">
          <div class="btn-group btn-group-justified">
            <div class="btn-group">
              <button rgi-rx-button variant="icon" type="button" color="info" (click)="deleteJson(i)"
                attr.data-qa="new-request-remove-btn"><span class="glyphicon glyphicon-remove-circle"></span></button>
            </div>
            <div class="btn-group">
              <button rgi-rx-button variant="icon" type="button" color="info" (click)="modal.open()"
                attr.data-qa="new-request-json-detail-btn" id="new-request-json-detail"><span
                  class="glyphicon glyphicon-chevron-right"></span></button>

              <rgi-rx-modal #modal>
                <rgi-rx-panel>
                  <rgi-rx-panel-header [closeable]="true" (onClose)="modal.close()"></rgi-rx-panel-header>
                  <rgi-rx-panel-footer>
                    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modal.close()">{{'_CLAIMS_._BUTTONS_._CLOSE' | translate}}</button>
                  </rgi-rx-panel-footer>
                  <p>{{json.externalKey}}</p>
                  <p>{{json.parameter}}</p>
                </rgi-rx-panel>
              </rgi-rx-modal>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid table-white ">
  <div class="row">
    <div class="col-md-12">
      <form class="textAreaForm" [formGroup]="newRequestForm" data-qa="new-request-text-area-form">
        <rgi-rx-form-field>
 
          <span class="span-container">
            <span class="span-end">
              <div class="col-md-6">
                <label class="label-core" attr.data-qa="new-request-json-external-key-label">>{{'_CLAIMS_._JSON_EXTERNAL_KEY' | translate}}</label>
                <input class="form-control" formControlName="jsonExternalKey" id="jsonExternalKey" type="text"
                  name="jsonExternalKey" attr.data-qa="new-request-json-external-key-input">
              </div>
              <button style="height: 70%;" rgi-rx-button color="secondary" [disabled]="!newRequestForm.controls.jsonTextArea.value"
                (click)="addJson()">{{'_CLAIMS_._BUTTONS_._ADD' | translate}}</button>
            </span>
          </span>
            <div class="col-md-12">
              <label rgiRxLabel> Json </label>
              <textarea style="height: 250px;" rgiRxInput formControlName="jsonTextArea"></textarea>
            </div>
       
        </rgi-rx-form-field>
      </form>
    </div>
  </div>
</div>

<div class="row">
  <div [ngClass]="{'col-md-6': showDetailButton, 'col-md-12': !showDetailButton}">
      <button type="submit" class="btn btn-warning wc-100 text-uppercase"
        (click)="saveRequest()">{{'_CLAIMS_._BUTTONS_._SAVE_NEW_REQUEST' | translate}}</button>
  </div>
  <div *ngIf="showDetailButton" class="col-md-6">
      <button type="button" class="btn btn-warning wc-100 text-uppercase"
        (click)="goToDetail()">{{'_CLAIMS_._BUTTONS_._LAST_EXECUTION_DETAILS' | translate}}</button>
  </div>

  <!-- <div *ngIf="showDetailButton" class="col-md-6">
    <button type="button" class="btn btn-warning wc-100 text-uppercase"
      (click)="handleButtonClick()">{{'_CLAIMS_._BUTTONS_._LAST_EXECUTION_DETAILS' | translate}}</button>
  </div> -->

</div>




<rgi-rx-modal #modalE>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose()"></rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose()">{{'_CLAIMS_._BUTTONS_._CLOSE' | translate}}</button>
    </rgi-rx-panel-footer>
    <p>{{outcome}}</p>
  </rgi-rx-panel>
</rgi-rx-modal>


<!-- <rgi-rx-modal #reviewModal>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="reviewModal.close()"></rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="reviewModal.close()">{{'_CLAIMS_._BUTTONS_._CLOSE' | translate}}</button>
    </rgi-rx-panel-footer>
    <div *ngIf="reviewData"> -->
      <!-- Visualizza i dati del form nella modale -->
      <!-- <p>{{ reviewData | json }}</p>
    </div>
  </rgi-rx-panel>
</rgi-rx-modal> -->

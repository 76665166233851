import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {Company} from '../../models/company';
import {ProductsObject} from '../../models/pc-portfolio-models/genericEntityPcPortfolio';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {StartCardFindDto} from '../../models/start-card-find-dto';
import {ConsultationStartCardState} from '../../state-managers/states/consultation-start-card-state';
import {PolicyOperationContainer, PolicyOperationPayload} from '../../models/policy-operations';
import {SystemProperty} from '../../models/system.property';
import {EnumsApiList, EnumsApiReq} from '../../models/enum-type';
import {PolicyRow} from '../../models/policy';
import {ProductsFilter} from '../../models/products-filter';

@Injectable({
  providedIn: 'root'
})
export class ConsultationStartCardResourceService {

  constructor(
    private http: RgiRxHttpClientWrapper,
    @Inject('environment') private apiConf: any
  ) {
  }

  getSystemProperty(): Observable<SystemProperty> {
    return this.http.get<SystemProperty>(`${this.apiConf.api.portal.path}/v2/common/config/PortafoglioSalvataggioIntermedio`).pipe(share(), map(val => {
        return val;
      })
    );
  }
  getCompanies$(): Observable<Array<Company>> {
    return this.http.get<Array<Company>>(`${this.apiConf.api.portal.path}/v2/common/companies/internal`).pipe(share(), map(val => {
        return val;
      })
    );
  }
  getPolicyOperations(body: PolicyOperationPayload): Observable<PolicyOperationContainer> {
    return this.http.post<PolicyOperationContainer>(`${this.apiConf.api.portal.path}/v2/portfolio/pc/policyoperations`, body).pipe(share(), map(val => {
        return val;
      })
    );
  }

  getEnumsByCodeList$(enums: EnumsApiReq): Observable<EnumsApiList> {
    return this.http.post<EnumsApiList>(`${this.apiConf.api.portal.path}/system/enums`, enums);
  }

  getProducts$(body:ProductsFilter): Observable<ProductsObject> {
    return this.http.post<ProductsObject>(`${this.apiConf.api.portal.path}/admin/products`, body);
  }
  getNode$(st: ConsultationStartCardState, node: string) {
    return this.http.get<any>(`${this.apiConf.api.portal.path}/admin/agencies?loginNodeId=${node}`)
  }
  findPolicies(body: StartCardFindDto) {
    return this.http.post<PolicyRow>(`${this.apiConf.api.portal.path}/v2/portfolio/pc/policies/search`, body).pipe(share(), map(val => {
        return val;
      })
    );
  }
  downloadReport(extension: string, body: StartCardFindDto){
    return this.http.post<Blob>(`${this.apiConf.api.portal.path}/v2/portfolio/pc/proposals/download/report/${extension}`, body, {responseType: 'blob' as 'json', observe: 'response'});
  }
}

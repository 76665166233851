import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {RgiRxErrorStateMatcher, RgiRxFormControl, RgiRxControlSkipValidationKeys} from '../rgi-rx-form-elements-api';
import {ControlValueAccessor, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {RgiRxFormControlDirective} from '../rgi-rx-form-control.directive';

@Component({
  selector: 'rgi-rx-input-file',
  templateUrl: './rgi-rx-input-file.component.html',
  providers: [
    {
      provide: RgiRxFormControl,
      useExisting: RgiRxInputFileComponent
    }
  ],
  host: {
    class: 'rgi-ui-upload-container'
  }
})
export class RgiRxInputFileComponent extends RgiRxFormControlDirective<File | ''> implements ControlValueAccessor {

  constructor(
    _elementRef: ElementRef,
    _errorStateMatcher: RgiRxErrorStateMatcher,
    @Optional() @Self() ngControl?: NgControl,
    @Optional() _parentForm?: NgForm,
    @Optional() _parentFormGroup?: FormGroupDirective) {
    super(_elementRef, _errorStateMatcher, ngControl, _parentForm, _parentFormGroup);
    super.inheritDefaultClass = false;
    if (!!this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @ViewChild('input', {static: true}) htmlInput: ElementRef<HTMLInputElement>;
  @Output() fileSelected = new EventEmitter<File>();
  @Output() fileRemoved = new EventEmitter<File>();

  private _selectLabel: string;

  private _accept: string[] = [];


  onChange = (changed) => {
  }
  onTouched = () => {
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @Input() @HostBinding('id') get id(): string {
    return this._id ? this._id : this.uuid;
  }

  set id(value: string) {
    this._id = !!value ? value : this.uuid;
  }

  @Input() get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }


  @Input() get value(): File | '' {
    return this._value;
  }

  set value(value: File | '') {
    this._value = value;
  }


  @Input() get accept(): string[] {
    return this._accept;
  }

  set accept(value: string[]) {
    this._accept = value;
  }


  @Input() get selectLabel(): string {
    return !!this._selectLabel ? this._selectLabel : 'RGI_RX.INPUT_FILE.SELECT';
  }

  set selectLabel(value: string) {
    this._selectLabel = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }


  onInputChange($event: Event) {
    const eventTarget = $event.target as HTMLInputElement;
    this.value = eventTarget.files[0];
    this.onChange(this.value);
    this.fileSelected.emit(this.value);
    this.onTouched();
  }

  onRemove($event: MouseEvent) {
    this.fileRemoved.emit(this.value as File);
    this.value = '';
    this.onChange(this.value);
    // clear native input it won't allow the same file again otherwise
    this.htmlInput.nativeElement.value = '';
  }

  onSelect($event: MouseEvent) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    $event.preventDefault();
    if (!!this.disabled) {
      return;
    }
    this.htmlInput.nativeElement.click();
  }


  get skipValidationKeys(): RgiRxControlSkipValidationKeys {
    return ['rgiRxAcceptFileType'];
  }

  get acceptError() {
    return {
      rgiRxAcceptFileType: this.ngControl.control.hasError('rgiRxAcceptFileType') ? this.ngControl.getError('rgiRxAcceptFileType').rgiRxAcceptFileType : this.accept.join(', ')
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewPaymentComponent } from './new-payment.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DigitalClaimsCommonAngularModule,
  CardService,
  anagFinderSubjectProvider,
  claimsFinderIntegrationPassServiceProvider,
  claimsBaseObjectServiceProvider,
  enumServiceProvider,
  getSystemPropertyProvider
} from '@rgi/digital-claims-common-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewPaymentProgressComponent } from './new-payment-progress/new-payment-progress.component';
import { PaymentDataComponent } from './payment-data/payment-data.component';
import { GeneralDataComponent } from './general-data/general-data.component';
import { QuoteEvaluationComponent } from './quote-evaluation/quote-evaluation.component';
import { BeneficiaryPayeeComponent } from './beneficiary-payee/beneficiary-payee.component';
import { NewPaymentBoardComponent } from './new-payment-board/new-payment-board.component';
import { PreControlsComponent } from './pre-controls/pre-controls.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { NewPaymentDetailComponent } from './new-payment-detail/new-payment-detail.component';
import { FeeDataModalComponent } from './fee-data-modal/fee-data-modal.component';
import { DocumentChoiceComponent } from './document-choice/document-choice.component';
import { InvoiceCtrlModalComponent } from './invoice-ctrl-modal/invoice-ctrl-modal.component';
import { PreviewDocModalComponent } from './preview-doc-modal/preview-doc-modal.component';
import { HistoricalErosionComponent } from './quote-evaluation/historical-erosion/historical-erosion.component';
import { CalculatorFactorComponent } from './calculator-factor/calculator-factor.component';
import { HistoricalErosionDetailComponent } from './quote-evaluation/historical-erosion-detail/historical-erosion-detail.component';
import { AddDocumentDataComponent } from './add-document-data/add-document-data.component';
import { blankFieldPipeFactory, PaymentListComponent } from './payment-list/payment-list.component';
import { RgiRxTableModule, RGI_RX_DATA_TABLE_PIPE, RgiRxDatePickerModule, ModalService, RgiRxPanelModule, RgiRxExpansionModule } from '@rgi/rx/ui';
import { ReceiptReturnModalComponent } from './receipt-return-modal/receipt-return-modal.component';
import { RgiRxFormElementsModule, RgiRxTooltipModule } from '@rgi/rx/ui';
import { PaymentConstants } from './domain/payment-constants';
import { RgiRxFormModule } from '@rgi/rx';
import { CancellationModalComponent } from './cancellation-modal/cancellation-modal.component';
import { SettlementReversalComponent } from './settlement-reversal/settlement-reversal.component';
import { FeeDataViewModalComponent } from './fee-data-view-modal/fee-data-view-modal.component';
import { NewSettlementDetailModalComponent } from './new-settlement-detail-modal/new-settlement-detail-modal.component';
import { RgiRxI18nModule, RgiRxTranslationService } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
import { FiscalDataComponent } from './fiscal-data/fiscal-data.component';

@NgModule({
    declarations: [
        NewPaymentComponent,
        NewPaymentProgressComponent,
        PaymentDataComponent,
        GeneralDataComponent,
        QuoteEvaluationComponent,
        BeneficiaryPayeeComponent,
        NewPaymentBoardComponent,
        PreControlsComponent,
        FeeDataModalComponent,
        InvoiceCtrlModalComponent,
        PaymentModalComponent,
        PaymentMethodComponent,
        NewPaymentDetailComponent,
        DocumentChoiceComponent,
        InvoiceCtrlModalComponent,
        PreviewDocModalComponent,
        HistoricalErosionComponent,
        CalculatorFactorComponent,
        HistoricalErosionDetailComponent,
        AddDocumentDataComponent,
        PaymentListComponent,
        ReceiptReturnModalComponent,
        CancellationModalComponent,
        SettlementReversalComponent,
        FeeDataViewModalComponent,
        NewSettlementDetailModalComponent,
        FiscalDataComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        FormsModule,
        ReactiveFormsModule,
        DigitalClaimsCommonAngularModule,
        NgbModule,
        RgiRxTableModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxExpansionModule,
        RgiRxFormModule,
        RgiRxTooltipModule,
        RgiRxPanelModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
    exports: [
        NewPaymentComponent
    ],
    providers: [
        { provide: 'newPaymentBoardComponent', useValue: NewPaymentBoardComponent },
        anagFinderSubjectProvider,
        { provide: 'newPaymentDetailComponent', useValue: NewPaymentDetailComponent },
        claimsFinderIntegrationPassServiceProvider,
        claimsBaseObjectServiceProvider,
        enumServiceProvider,
        getSystemPropertyProvider,
        { provide: RGI_RX_DATA_TABLE_PIPE, multi: true, useFactory: blankFieldPipeFactory },
        PaymentConstants,
        ModalService
    ]
})
export class NewPaymentModule {
  constructor(
    public translateService: RgiRxTranslationService,
    cardService: CardService,
  ) {
    const title = '_NEW_PAYMENT';
    const moduleName = 'new-payment';
    const communication = {
      beneficiary: 'beneficiary',
    };
    const routes = [{
      path: 'action', route: {
        template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
        controller: 'CardPassCtrl',
        label: 'Action'
      }
    }];
    const enumerates: string[] = ['sinistri.MezzoPagamento', 'sinistri.TipoPagamentoLiquidazione', 'sinistri.GestionePagamento'];

    const cardOpeningModule = cardService.registerCard(
      moduleName,
      title,
      NewPaymentComponent,
      'ClaimsNewPaymentCommunicationCtrl',
      communication,
      routes,
      enumerates,
      false
    );

    let titlePaymentList;
    this.translateService.translate('_CLAIMS_._PAYMENTS_FIND')
    .subscribe(
      res => titlePaymentList=res
    )
    const moduleNamePaymentList = 'paymentList';

    cardService.registerCard(
      moduleNamePaymentList,
      titlePaymentList,
      PaymentListComponent,
      'ClaimsNewPaymentCommunicationCtrl',
      null,
      routes,
      null,
      false
    );

    cardService.addRouteToParallelRoutingProvider(
      cardOpeningModule,
      'anagFinder',
      'result',
      {
        template: 'anag/cardfinder/anag-card-finder-result.tpl.html',
        controller: 'AnagCardFinderControllerComm_result',
        label: 'Edit - Anagrafica Finder'
      }
    );
  }
}

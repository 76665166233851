import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxAcceptFileTypeValidatorDirective} from './rgi-rx-accept-file-type-validator.directive';


@NgModule({
  declarations: [RgiRxAcceptFileTypeValidatorDirective],
  imports: [
    CommonModule
  ],
  exports: [RgiRxAcceptFileTypeValidatorDirective]
})
export class RgiRxFormModule { }

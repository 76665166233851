import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotificationService } from "../notification.service";
import { Toast } from '../toast-api';

@Component({
    selector: 'pp-toast',
    templateUrl: './toast.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ToastComponent implements OnInit {

    @Input() tag: string;
    @Input() showBar: boolean = true;
    @Input() title: string = "notifications";

    notifications: Toast[];
    hide: boolean= false;


    constructor(public notificationService: NotificationService) {
        
    }


    ngOnInit() {
        this.notificationService.get().subscribe(
            nList =>  {
                this.notifications = nList.filter( 
                    n => (this.tag && n.tag==this.tag) || (!this.tag && !n.tag)
                );
            }
        );
    }


    onClose(toast) {
        console.debug("close toast");
        this.notificationService.remove(toast);
    }
}

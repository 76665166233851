<rgi-rx-modal>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="closeModal()">
      {{ '_CLAIMS_._DRIVER_LICENSE_TITLE' | translate }}
    </rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button rgi-rx-button color="primary" class="w-100" (click)="saveDriverLicense($event)">{{ '_CLAIMS_._BUTTONS_._SAVE' |
        translate }}</button>
    </rgi-rx-panel-footer>
    <div class="container-fluid">
      <form [formGroup]="driverLicenseForm">
        <div class="form-group row">
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._NUMBER' | translate }}</label>
              <input type="text" rgiRxInput formControlName="number" (change)="saveDriverLicenseValidation()"/>
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._RELEASED_BY' | translate }}</label>
              <input type="text" rgiRxInput formControlName="releaseAuthority" (change)="saveDriverLicenseValidation()"/>
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._RELEASED_DATE' | translate }}</label>
              <rgi-rx-date-picker>
                <input [rgiRxDateTimeInput]="releaseDatePicker" formControlName="releaseDate"
                  [placeholder]="'_CLAIMS_._RELEASED_DATE' | translate" (dateTimeChange)="saveDriverLicenseValidation()">
              </rgi-rx-date-picker>
              <rgi-rx-date-time #releaseDatePicker></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}</label>
              <rgi-rx-date-picker>
                <input [rgiRxDateTimeInput]="expirationDatePicker" formControlName="expirationDate"
                  [placeholder]="'_CLAIMS_._EXPIRATION_DATE' | translate" (dateTimeChange)="saveDriverLicenseValidation()">
              </rgi-rx-date-picker>
              <rgi-rx-date-time #expirationDatePicker></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
        </div>
      </form>
      <div class="row line-box" *ngFor="let category of licenseCategoryAdded">
        <div class="col-md-3 col-xs-12 col-sm-3">
          <div>
            <strong>{{ '_CLAIMS_._CATEGORY' | translate }}</strong>
          </div>
          <div>{{ category.type.descrizione }}</div>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-3">
          <div>
            <strong>{{ '_CLAIMS_._VALIDITY_START_DATE' | translate }}</strong>
          </div>
          <div>{{ category.validityStartDate | date:'dd/MM/yyyy' }}</div>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-3">
          <div>
            <strong>{{ '_CLAIMS_._VALIDITY_END_DATE' | translate }}</strong>
          </div>
          <div>{{ category.validityEndDate | date:'dd/MM/yyyy' }}</div>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-3">
          <div class="col-md-12 col-xs-12 col-sm-6">
            <button rgi-rx-button variant="rounded" (click)="removeCategory(category)" color="secondary">
              <span class="glyphicon glyphicon-trash"></span>
            </button>
          </div>
        </div>
      </div>
      <form [formGroup]="categoriesForm" class="mrT-20">
        <div class="form-group row">
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._CATEGORY' | translate }}</label>
              <select rgiRxNativeSelect formControlName="type" (change)="addCategoryValidation()">
                <option value=""></option>
                <option *ngFor="let category of licenseCategoryType" [ngValue]="category">{{category.descrizione}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._VALIDITY_START_DATE' | translate }}</label>
              <rgi-rx-date-picker>
                <input [rgiRxDateTimeInput]="validityStartDatePicker" formControlName="validityStartDate"
                  [placeholder]="'_CLAIMS_._VALIDITY_START_DATE' | translate" (dateTimeChange)="addCategoryValidation()">
              </rgi-rx-date-picker>
              <rgi-rx-date-time #validityStartDatePicker></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <rgi-rx-form-field>
              <label rgiRxLabel>{{ '_CLAIMS_._VALIDITY_END_DATE' | translate }}</label>
              <rgi-rx-date-picker>
                <input [rgiRxDateTimeInput]="validityEndDatePicker" formControlName="validityEndDate"
                  [placeholder]="'_CLAIMS_._VALIDITY_END_DATE' | translate" [min]="categoriesForm.get('validityStartDate').value" (dateTimeChange)="addCategoryValidation()">
              </rgi-rx-date-picker>
              <rgi-rx-date-time #validityEndDatePicker></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
          <div class="col-md-6 col-xs-12 col-sm-6">
            <button type="button" rgi-rx-button color="primary" class="w-100 mrT-20" (click)="addCategory($event)">{{
              '_CLAIMS_._ADD_CATEGORY' | translate }}</button>
          </div>
        </div>
        <div *ngIf="alertLicenseCategoryType">
          <div class="alert alert-danger" role="alert">
            {{'_CLAIMS_._CATEGORY' | translate}}
            {{'_CLAIMS_._REQUIRED' | translate}}
          </div>
        </div>
        <div *ngIf="alertValidityStartDate">
          <div class="alert alert-danger" role="alert">
            {{'_CLAIMS_._VALIDITY_START_DATE' | translate}}
            {{'_CLAIMS_._REQUIRED' | translate}}
          </div>
        </div>
        <div *ngIf="alerValidityEndDate">
          <div class="alert alert-danger" role="alert">
            {{'_CLAIMS_._VALIDITY_END_DATE' | translate}}
            {{'_CLAIMS_._REQUIRED' | translate}}
          </div>
        </div>
        <div *ngIf="alertNumber">
          <div class="alert alert-danger" role="alert">
            {{ '_CLAIMS_._NUMBER' | translate }}
            {{'_CLAIMS_._REQUIRED' | translate}}
          </div>
        </div>
        <div *ngIf="alertReleaseAuthority">
          <div class="alert alert-danger" role="alert">
            {{ '_CLAIMS_._RELEASED_BY' | translate }}
            {{'_CLAIMS_._REQUIRED' | translate}}
          </div>
        </div>
        <div *ngIf="alertReleaseDate">
          <div class="alert alert-danger" role="alert">
            {{ '_CLAIMS_._RELEASED_DATE' | translate }}
            {{'_CLAIMS_._REQUIRED' | translate}}
          </div>
        </div>
        <div *ngIf="alertExpirationDate">
          <div class="alert alert-danger" role="alert">
            {{ '_CLAIMS_._EXPIRATION_DATE' | translate }}
            {{'_CLAIMS_._REQUIRED' | translate}}
          </div>
        </div>
      </form>

    </div>
  </rgi-rx-panel>
</rgi-rx-modal>
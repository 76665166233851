import { ExternalPolicySubject } from './external-policy-subject';
import { ExternalPolicyAsset } from './external-policy-asset';
import { ExternalPolicyMandato } from './external-policy-mandato';
import { ExternalPolicyResp } from './external-policy-resp';

export class ExternalPolicy {

  public policyId: string;
  public policyNumber: string;
  public company: string;
  public contractor: string;
  public assetInsured: string;
  public releaseDate: Date;
  public issueDate: Date;
  public effectDate: Date;
  public expirationDate: Date;
  public emissionDate: Date;
  public cancelDate: Date;
  public canceled: boolean;
  public origin: string;
  // Dati Contraente Inzio
  public denomination: string;
  public firstName: string;
  public subjectCode: string;
  public birthDate: Date;
  public fiscalCodeVat: string;
  public gender: string;
  public address: string;
  public postalCode: string;
  public stateCode: string;
  public municipality: string;
  public country: string;
  // Dati Contraente Fine
  // Dati Assicurato Inzio
  public denominationInsured: string;
  public firstNameInsured: string;
  public subjectCodeInsured: string;
  public birthDateInsured: Date;
  public fiscalCodeVatInsured: string;
  public genderInsured: string;
  public addressInsured: string;
  public postalCodeInsured: string;
  public stateCodeInsured: string;
  public municipalityInsured: string;
  public countryInsured: string;
  // Dati Assicurato Fine
  public productCode: string;
  public productDescription: string;
  public agency: string;
  public administrativeRegularity: string;
  public policyReplacedNumber: string;
  public policyReplacementNumber: string;
  public policyCeiling: number;
  public deductible: number;
  public uncovered: number;
  public miniDiscoveredValue: number;
  public reassWarrant: string;
  public reassQuote: number;
  public updateDate: Date;
  public user: string;
  public insertUser: string;
  public updateUser: string;
  public version: string;
  public manualVersion: string;
  public insertDate: Date;
  public policySplitting: string; // frazionamento
  public premiumFrom: Date;
  public premiumTo: Date;
  public diaria: number;
  public maxGgRp: number;
  public economicInfo: string;
  public subjectList: ExternalPolicySubject[];
  public assetList: ExternalPolicyAsset[];
  public mandatiList: ExternalPolicyMandato[];

  public listPolicyFound: ExternalPolicy[];

  public suspensionDateFrom: Date;
  public suspensionDateTo: Date;
  public businessLine: string;
  public businessLineDescr: string;
  public ggMora: number;
  public numFoglioCassa: string;
  public premioFoglioCassa: number;
  public status: string;
  public regAmm: string;
  public outRange: string;
  public branch: string;
  public ggDiaria: number;
  public originType: string;
  public externalPolicyResponse: ExternalPolicyResp;

  constructor() { }

  public policyOwner(): ExternalPolicySubject {
   if (this.subjectList !== null && this.subjectList.length > 0) {
      return this.subjectList.find(value => value.codRole === 'CNT');
   } else {
     return new ExternalPolicySubject();
   }
  }

  public insured(): ExternalPolicySubject {
    if (this.subjectList !== null && this.subjectList.length > 0) {
       return this.subjectList.find(value => value.codRole === 'ASS');
    } else {
      return new ExternalPolicySubject();
    }
  }
}

import {AddressApiContacts} from './address-api-contacts';

export class PolicyAddress {

  constructor(
    public address: AddressApiContacts,
    public main: boolean,
    public id: string
  ) {
  }

}

import {Directive, Input, OnInit} from '@angular/core';
import {RGI_RX_LOCALE, RgiRxTranslationService} from '@rgi/rx/i18n';
import {RgiRxRuntimeError, takeUntilDestroyed, Destroyable} from '@rgi/rx';
import {concatMap, mergeMap, take} from 'rxjs/operators';
import {combineLatest, of} from 'rxjs';
@Directive({
  selector: '[rgiRxLocaleSelector]',
  exportAs: 'rgiRxLocaleSelector',
  host: {
    '[attr.aria-label]': 'ariaLabel'
  }
})
@Destroyable()
export class RgiRxLocaleSelectorDirective implements OnInit {
  public readonly locales: RGI_RX_LOCALE[];
  private _currentLocale: RGI_RX_LOCALE;
  ariaLabel?: string;

  @Input()
  get currentLocale(): RGI_RX_LOCALE {
    return this._currentLocale;
  }
  set currentLocale(locale: RGI_RX_LOCALE) {
    if (this.locales?.indexOf(locale) !== -1) {
      this._currentLocale = locale;
      this.rgiRxTranslationService.setCurrentLanguage(this._currentLocale);
    } else if (this.locales) {
      throw new RgiRxRuntimeError(`Unsupported locale: ${locale}`);
    }
  }

  constructor(protected rgiRxTranslationService: RgiRxTranslationService) {
    this.currentLocale = this.rgiRxTranslationService.currentLanguage;
    this.locales = this.rgiRxTranslationService.getRegisteredTranslations();
    this.rgiRxTranslationService.translate("RGI_RX.LOCALE_SELECTOR.LABEL").pipe(take(1)).subscribe(
      ariaLabel => this.ariaLabel = ariaLabel ? ariaLabel : this.ariaLabel
    )
  }

  ngOnInit(): void {
    this.rgiRxTranslationService.getLanguageChange$()
      .pipe(
        mergeMap(locale => combineLatest([this.rgiRxTranslationService.translate("RGI_RX.LOCALE_SELECTOR.LABEL").pipe(take(1)), of(locale)])),
        takeUntilDestroyed(this)
      )
      .subscribe(([ariaLabel, locale]) => {
        this.ariaLabel = ariaLabel ? ariaLabel : this.ariaLabel;
        this.currentLocale = locale;
      });
  }

}

import { LpcPremiumModule } from './../lpc-premium/lpc-premium.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcQuotationDetailModalComponent } from './lpc-quotation-detail-modal.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LpcQuotationModule } from '../lpc-quotation/lpc-quotation.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';



@NgModule({
    declarations: [LpcQuotationDetailModalComponent],
    imports: [
        CommonModule,
        LpcPremiumModule,
        PortalNgCoreModule,
        LpcQuotationModule,
        RgiRxI18nModule
    ],
    exports: [LpcQuotationDetailModalComponent]
})
export class LpcQuotationDetailModalModule { }

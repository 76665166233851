<rgi-rx-menu #menu>
  <div rgi-rx-menu-item *ngFor="let menuItem of menuData.items"
       class="rgipc-quotation-menu-item" (click)="resolveAction(menuItem.actionCode, menuItem.ctx)" [attr.data-qa]="menuItem.actionCode">
    {{menuItem.label | translate}}
  </div>
</rgi-rx-menu>
<button class="rgi-ui-btn rgi-ui-btn-outline" [rgiRxMenuTrigger]="menu" data-qa="VIEW_MENU">
  {{menuData.label | translate}}
  <div class="rgi-ui-icon-down-arrow2"></div>
</button>

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {PcInstalmentDate} from '../../../models/pc-portfolio-models/instalment-models/pc-instalment-date';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioInstalmentResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  putInstalmentDate(proposalId: string, instalmentDate: string): Observable<PcInstalmentDate> {
    const params = new HttpParams().set('instalmentDate', instalmentDate);

    return this.http.put<PcInstalmentDate>(
      this.getInstalmentDateUri(proposalId),
      null,
      {params}
    ).pipe(
      share()
    );
  }


  getInstalmentDateUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/next-instalment';
  }
}

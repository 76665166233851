import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableElement } from './tableElement';

@Component({
  selector: 'pnc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class TableComponent implements OnInit {

  @Input() arrayOfElements: TableElement[][];
  @Output() eventEmitter: EventEmitter<TableElement> = new EventEmitter<TableElement>();

  constructor() {
  }

  ngOnInit() {
  }

  onChange() {
  }

  onButtonClick(element: TableElement) {
    element.setEvent('buttonClicked');
    this.eventEmitter.emit(element);
  }

}

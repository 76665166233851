<div class="col-md-12 active">
  <lpc-factors
    (changeValEmitter)="updateFactors()"
    [parentForm]="form"
    [fattori]="convertedFactors">
  </lpc-factors>
</div>
<div *ngIf="summary" class="inactive">
  <div class="row">
    <ng-container *ngFor="let factor of getSummaryFormFactor()">
      <div *ngIf="!!factor.value" class="col-md-6">
          <span for="label">{{ factor.label }}: </span>
          <span for="value">{{ factor.value | factorFormatter:factor.type:factor.formatter:locale }}</span>
      </div>
    </ng-container>
  </div>
</div>

import { Component, EventEmitter, Inject } from "@angular/core";
import { DIALOG_DATA, OnModalClose } from "@rgi/rx/ui";
import { OutputForfait } from "../../../models/forfait/output-forfait.model";

@Component({
    selector: 'modal-forfait',
    templateUrl: './modal-forfait.component.html'
})
export class ModalForfaitComponent implements OnModalClose {
    modalClose = new EventEmitter<any>();
    constructor(@Inject(DIALOG_DATA) public data: OutputForfait) { }
}
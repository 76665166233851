import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { ComboEntity } from './combo-entity';
import { FactorEntity } from './factor-entity';
import { LegalFeeEntity } from './legal-fee-entity';
import { LegalFeeOmniaEntity } from './legal-fee-omnia-entity';
import { SubjectEntity } from './subject-entity';
import { TaxRegimeListEntity } from './tax-regime-list-entity';

export class ExposureEntity {
  public selected: boolean;
  public disableSelection: boolean;
  public idCausaleDanno: string; // id causale danno (se null l'exposure è fittizia)
  public real: boolean; // dice se la causale esiste su DB o è stata ricreata in modo fittizio
  //  public idActor: string; // attore di riferimento potrebbe essere un attore o un soggetto
  public injured: SubjectEntity; // a chi si riferisce la copertura potrebbe non essere l'attore
  public beneficiaryDefault: SubjectEntity;
  public description: string; // descrizione causale da visualizzare
  public moreInfo: string; // datti aggiuntivi da affiancare alla descrizione
  // nominativo del legale in caso di causale di visualizzazione
  public managementType: string; // tipo gestione RCA (enumerato) deve comparire a
  // fianco della descrizione delle causali RCA tra parentesi
  public managementTypeCode: string; // tipo gestione RCA (enumerato)
  public definition: string; // totale/parziale
  public disableDefinition: boolean; // totale/parziale
  public amountTotal: number; // importo liquidato totale  importi da sottocoperture
  public amount: number; // importo liquidato  digitato da utente (LORDO)
  public amountNet: number; // importo da pagare - Importo a carico della compagnia
  public amountInsured: number; // Importo a carico dell'assicurato
  public quotaAmount: number;
  public legalFeeAmount: number; // importo spese legali per Omnia (somma in caso di exposure primaria)
  public subExposureList: Array<ExposureEntity>; // elenco delle sotto coperture
  public feeDataNeeded: boolean; // true se per la copertura si devono censire i dati di fattura
  public feeData: LegalFeeEntity;
  public branchCode: string; // ramo sinistro
  public branchDescription: string; // ramo sinistro
  public disableAmount: boolean; // disabilita campo
  public disableOmniaAmount: boolean;
  public subExposureType: string; // CTU, CTP, LAV
  public feeDataOmnia: LegalFeeOmniaEntity; // dati fattura in caso di tipo quietanza omnia
  public feeDataOmniaNeeded: boolean;
  public feeDataOmniaOK: boolean;
  public contNoSocPresent: boolean;
  public contSocAttPresent: boolean;
  public contSocPassPresent: boolean;
  public medPassPresent: boolean;
  public medAttPresent: boolean;
  public coherentDamageExpected: boolean;
  public idCoherence: string;
  public coherenceDescr: string;
  public assetDescription: string;
  public riskDescription: string;
  public idCampo: string;
  public visible: boolean;
  public branchTitle: string;
  public reserveAmount: number;

  public advanceAggregateExcess: ApiEnumType;
  public advanceClaimExcess: ApiEnumType;
  public advanceDeductible: ApiEnumType;
  public advanceSir: ApiEnumType;

  public erodedAggregateExcess: number;
  public erodedClaimExcess: number;
  public erodedDeductible: number;
  public erodedFirstSir: number;
  public erodedSecondSir: number;

  public excludeAggregateExcess: boolean;
  public excludeClaimExcess: boolean;
  public excludeDeductible: boolean;
  public excludeLimit: boolean;
  public excludeFirstSir: boolean;
  public excludeSecondSir: boolean;

  public paymentFactors: Array<FactorEntity>;
  public calculatedAmount: number;
  public validationMsg: string;
  public validationStatus: string;
  public calculationDescription: string;
  public idSubstatusSel: string;
  public substatus: string;
  public subStatusOpen: Array<ComboEntity>;
  public subStatusClose: Array<ComboEntity>;
  public idSoggettoLegal: string;
  // multicurrency
  public currencyCode: string;
  public currencyCodeDate: Date;
  public currencyAmount: number;
  public legalFeeCurrencyAmount: number;
  public heirs: Array<SubjectEntity>;
  public procedureCode: string;
  public payeeList: Array<any>;

  public taxRegimeListEntity: TaxRegimeListEntity;

}

import {State} from '@rgi/rx/state';
import {Assets} from '../../models/domain-models/assets';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {SubjectInstance} from '../../models/domain-models/subject-instance';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {Warning} from '../../models/domain-models/warning';
import {PcProposal} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import {PcProductAsset} from '../../models/pc-portfolio-models/assets-models/pc-product-asset';
import {PcAssetInstance} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';

export class ReIssueAssetState extends State {
  public resourceId: string;
  public assets: Assets;
  public contractorSubject: AnagIssueSubject;
  public insuredSubject: AnagIssueSubject;
  public subjectsInstances: Array<SubjectInstance> = new Array<SubjectInstance>();
  public policyTechnicalData: PolicyTechnicalData;
  public lpsData: LpsData;
  public productName: string;
  public productId: number;
  public productCode: string;
  public node: any;
  public companyId: string;
  public editMode: boolean;
  public warnings: Warning[];
  public isPersonaGiuridica: boolean;
  public isSubjectModified: boolean;
  public editFunctionality: boolean;
  public isSubstitution: boolean;
  isToDisableFraz: any;
  authorize: boolean;
  bIntermediateSaving: boolean;
  proposal: PcProposal;
  thirdPersonContact: any;

  public productAssets: Array<PcProductAsset> = new Array<PcProductAsset>();
  public assetInstances: Array<PcAssetInstance> = new Array<PcAssetInstance>();
}

import {Commissions} from './commissions';

export class CommissionsRegime {
  constructor(
    public contractCommissions: number,
    public commissionList: Array<Commissions>,
    public compulsory: boolean,
    public visible: boolean,
    public enabled: boolean
  ) {
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReIssuePrintsBusinessService} from '../re-issue-business-services/re-issue-prints-business.service';
import {DocumentPrint} from '../../models/api-models/document-print';

@Component({
  selector: 're-issue-pdf-quote-modal',
  templateUrl: './re-issue-pdf-quote-modal.component.html',
  styleUrls: ['./re-issue-pdf-quote-modal.component.css']
})
export class ReIssuePdfQuoteModalComponent implements OnInit {
  @Output() modalClose = new EventEmitter();
  @Input() proposalNumber: string;
  document: DocumentPrint;
  @Input() isProposal: boolean;

  constructor(
    private printsBusinessService: ReIssuePrintsBusinessService
  ) {
  }

  ngOnInit() {
    if (this.isProposal) {
      this.printsBusinessService.getPrint(this.proposalNumber).subscribe((doc: DocumentPrint) => {
        this.document = doc;
      });
    } else {
      this.printsBusinessService.getPrintPolicy(this.proposalNumber).subscribe((doc: DocumentPrint) => {
        this.document = doc;
      });
    }
  }


  close() {
    this.modalClose.emit();
  }

}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';


@Component({
  selector: 'pnc-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class MessageComponent {
  closableValue: boolean;
  classes: string[];

  @Input()
  set closable(val: boolean) {
    this.closableValue = coerceBooleanProperty(val);
  }

  @Input('status')
  private set setStatus(val: string) {
    const statusClass = val ? `${val}-message` : '';
    this.classes = ['message', statusClass];
  }

  @Output() closeEvent = new EventEmitter();

  onClose() {
    this.closeEvent.emit();
  }
}

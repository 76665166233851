export interface MeanOfPayment {
  editable: boolean;
  paymentConfig: MeanOfPaymentDetails;
  selected: boolean;
}

export interface MeanOfPaymentDetails {
  paymentsFields: PaymentsField[];
  meanOfPayment: IdentificationEntity;
  paymentType: string;
}

export class BaseEntity {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class IdentificationEntity extends BaseEntity {
  id: string;

  constructor(code: string, description: string, id: string) {
    super(code, description);
    this.id = id;
  }
}

export interface PaymentsField {
  name: string;
  readOnly: boolean;
  label: string;
  type: string;
  value: string;
  values: Array<any>;
  mandatory: boolean;
}

export class PaymentConfig {
  credMeansOfPayment: MeanOfPayment[];
  debMeansOfPayment: MeanOfPayment[];
}

export class PaymentConfigResp {
  credMeansOfPayment: MeanOfPaymentDetails[];
  debMeansOfPayment: MeanOfPaymentDetails[];
}

export interface ContractPayment {
  id?: number;
  startVer?: number;
  endVer?: number;
  idContract?: number;
  payment?: Paym;
  idSettlementType?: number;
  idRole?: number;
}

export interface Paym {
  id?: number;
  meanPayment?: MeanPayment;
  idPaymentType?: string;
  iban?: string;
  bic?: string;
  creditCardId?: number;
  creditCardExpireDate?: Date;
  holder?: string;
  sddActivationDate?: Date;
  notificationDate?: Date;
  checkNumber?: string;
  cash?: number;
  transactionNumber?: string;
  uic?: string;
  foreignCurrency?: string;
  foreignAccount?: string;
  adjustmentDate?: Date;
  transferDescr?: string;
  bankName?: string;
  bankAddress?: string;
  holderKey?: string;
  holderAddress?: string;
  sddIdState?: number;
  sddCancelDate?: Date;
  sddCode?: string;
  subscriber?: string;
  subscriberKey?: string;
  rum?: string;
  errorType?: number;
  sddErrorCode?: string;
  sddErrorDescr?: string;
  sddErrorResponse?: Date;
  sddResponse?: Date;
}

export interface MeanPayment {
  id?: string;
  code?: string;
  description?: string;
}

export enum EnumPayment {
  DEBIT = '1',
  CREDIT = '2'
}

export enum EnumSettlementType {
  SIGN_INSTALLMENT = 1,
  NEXT_INSTALlMENT = 2
}

export interface PaymentConfigSelected {
  contractPayment: ContractPayment;
  selected: boolean;
  editable: boolean;
}

export interface PolicyNumberAndHolder {
  policyNumber: string;
  holder: string;
}


import {Injectable} from '@angular/core';
import {RGI_RX_LOCALE} from '@rgi/rx/i18n';
import {RgiCountryLayerNumberParser} from './rgi-country-layer-number.parser';
import {RgiCountryLayerBigNumberFormat} from './rgi-country-layer-big-number-format';

export abstract class RgiCountryLayerNumberFormat {
    abstract convertNumberByLocale(value: number, locale: RGI_RX_LOCALE, options?: Intl.NumberFormatOptions): string;

    abstract parseLocaleString(value: string, locale: RGI_RX_LOCALE, options?: Intl.NumberFormatOptions): number;

    abstract getNumberParser(locale: RGI_RX_LOCALE): RgiCountryLayerNumberParser;


    abstract formatBigNumberString(value: string, locale: RGI_RX_LOCALE): string

    constructor() {
    }
}

@Injectable({
    providedIn: 'root'
})
export class RgiCountryLayerNumberFormatService extends RgiCountryLayerNumberFormat {

    private numberParsers = new Map<RGI_RX_LOCALE, RgiCountryLayerNumberParser>();
    private bigNumberFormat = new Map<RGI_RX_LOCALE, RgiCountryLayerBigNumberFormat>();

    constructor() {
        super();
    }

    override convertNumberByLocale(value: number, locale: RGI_RX_LOCALE, options?: Intl.NumberFormatOptions): string {
        return new Intl.NumberFormat(locale, options).format(value);
    }

    override parseLocaleString(value: string, locale: RGI_RX_LOCALE, options?: Intl.NumberFormatOptions): number {
        return this.getNumberParser(locale).parse(value);
    }

    override getNumberParser(locale: RGI_RX_LOCALE): RgiCountryLayerNumberParser {
        if (!this.numberParsers.has(locale)) {
            this.numberParsers.set(locale, new RgiCountryLayerNumberParser(locale));
        }
        return this.numberParsers.get(locale);
    }

    getFormatParts(locale: RGI_RX_LOCALE): RgiCountryLayerBigNumberFormat {
      if (!this.bigNumberFormat.has(locale)) {
        this.bigNumberFormat.set(locale, new RgiCountryLayerBigNumberFormat(locale));
      }
      return this.bigNumberFormat.get(locale);
    }

    override formatBigNumberString(value: string, locale: RGI_RX_LOCALE): string {
      return this.getFormatParts(locale).formatBigNumberString(value, locale);
    }


}

import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {RgiRxTranslationService} from './rgi-rx-translation.service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {LoggerFactory, RgiRxInterpolationParamsType} from '@rgi/rx';

@Pipe({
  name: 'translate',
  pure: false
})
export class RgiRxTranslatePipe implements PipeTransform, OnDestroy {
  private readonly logger = LoggerFactory();
  private translated: any;
  private languageChange: Subscription;
  private originalKey: any;
  private missing: boolean;
  private interpolationParams: RgiRxInterpolationParamsType;


  constructor(private translateService: RgiRxTranslationService, private _ref: ChangeDetectorRef) {
  }

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return;
    }
    if (!this.originalKey) {
      this.originalKey = value.trim();
    }
    this.parseArgs(args);
    this.dispose();
    this.translate();
    if (this.missing) {
      return this.originalKey;
    }

    this.languageChange = this.translateService.getLanguageChange$().subscribe(
      next => {
        this.translated = undefined;
        this.translate();
      }
    );
    return this.translated;
  }

  private translate() {
    this.translateService.translate(this.originalKey, this.interpolationParams)
      .pipe(
        take(1)
      )
      .subscribe(translation => {
        if (translation) {
          this.missing = false;
          this.updateValueAndTick(translation);
        } else {
          this.missing = true;
          this._ref.markForCheck();
        }
      });
  }

  private updateValueAndTick(translation: string) {
    this.translated = translation;
    this._ref.markForCheck();
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  clear() {
    this.originalKey = undefined;
    this.translated = undefined;
  }

  private dispose() {
    if (!!this.languageChange) {
      this.languageChange.unsubscribe();
      this.languageChange = undefined;
    }
  }

  private parseArgs(args: any[]) {
    try {
      if (args && args[0]) {
        const serializedParams = args[0];
        if (typeof serializedParams === 'string') {
          this.interpolationParams = JSON.parse(args[0]);
        } else {
          this.interpolationParams = serializedParams;
        }
      }
    } catch (e) {
      this.logger.error(`RgiRxTranslatePipe: \n Invalid JSON string for params: ${args[0]}, interpolation will not be performed`, e);
    }
    if (args && args[1]) {
      this.originalKey = args[1];
    }
  }
}

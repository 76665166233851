import { Component, Inject, OnInit } from '@angular/core';
import {
  ModalComponent,
  ModalService,
  RgiRxDataTableColumnSortDef,
  RgiRxDataTableDataSource,
  RgiRxDatatableRowAction, RgiRxSortComparatorFn, RgiRxSwitchChange,
  TableSchema
} from '@rgi/rx/ui';
import { ClaimDocumentsService } from '../claim-documents.service';
import {
  CardComponentService,
  SessionService,
  CardComponent,
  ApiParty,
  ApiSubject,
  ApiTranslatorClaimService
} from '@rgi/digital-claims-common-angular';
import { DocumentEntity } from '../dto/document-entity';
import { DocumentCategoryEntity, DocumentSubcategoryEntity } from '../dto/document-category-entity';
import { FileEntity } from '../dto/file-entity';
import { UiModalDialogUploadDocumentComponent } from '../dialogs/upload-document-dialog';
import { catchError } from 'rxjs/operators';
import { EMPTY, combineLatest, throwError } from 'rxjs';
import { UiModalDialogEditDocumentComponent } from '../dialogs/edit-document-dialog';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-potclaim-documents',
  templateUrl: './potclaim-documents.component.html',
  styleUrls: ['./potclaim-documents.component.scss']
})
export class PotclaimDocumentsComponent extends CardComponent implements OnInit {


  constructor(
    cardComponentService: CardComponentService,
    @Inject('eventService') private eventService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('authService') private auth,
    @Inject('containerCacheService') private cache,
    private apiTranslatorClaimService: ApiTranslatorClaimService,
    private modalService: ModalService,
    sessionService: SessionService,
    public utilityServ: ClaimDocumentsService,
    public translateService: RgiRxTranslationService
  ) {
    super();
    const obsName$ = this.translateService.translate('_CLAIMS_._NAME');
    const obsSubtitle$ = this.translateService.translate('_CLAIMS_._SUBTITLE');
    const obsUploadDate$ = this.translateService.translate('_CLAIMS_._UPLOAD_DATE');
    const obsReceiptDate$ = this.translateService.translate('_CLAIMS_._RECEIPT_DATE');
    const obsCategory$ = this.translateService.translate('_CLAIMS_._CATEGORY');
    const obsOperations$ = this.translateService.translate('_CLAIMS_._OPERATIONS');
    combineLatest([
      obsName$,
      obsSubtitle$,
      obsUploadDate$,
      obsReceiptDate$,
      obsCategory$,
      obsOperations$
    ]).subscribe(
      ([name, subtitle, uploadDate, receiptDate, category, operations]) => {
        this.schema = {
          rows: [
            {
              name: 'fileName', title: name,
              // styleClass: 'document-datatable-cell'
            },
            {
              name: 'subtitle', title: subtitle,
            },
            {
              name: 'uploadDateTableFormat', title: uploadDate,
              styleClass: 'document-datatable-cell',
              format: {
                pipe: 'date'
              }
            },
            {
              name: 'receptionDateTableFormat', title: receiptDate,
              styleClass: 'document-datatable-cell',
              format: {
                pipe: 'date'
              }
            },
            {
              name: 'categoryDescription', title: category,
              styleClass: 'document-datatable-cell'
            },
            /*      {
                    name: 'microCategoryDescription', title: 'Sottocategoria',
                    styleClass: 'document-datatable-cell'
                  },*/
            // download - visualizza - menu modifica? sintesi in solo btn modifica
            {
              name: 'action',
              title: operations,
              actions: [ // an action row is used to display action buttons instead of text. Can have many
                {
                  name: 'DOWNLOAD', // the event name that is fired upon click,
                  styleClass: 'rgi-ui-btn rgifont rgi-download pull-right',
                },
                {
                  name: 'VIEW',
                  styleClass: 'rgi-ui-btn rgifont rgi-magnifier pull-right',
                },
                {
                  name: 'EDIT',
                  styleClass: 'rgi-ui-btn rgifont rgi-pencil pull-right',
                }
              ],
              description: 'Actions for each document' // a description of the field
            }

          ],
          // tslint:disable-next-line:max-line-length
          // 'microCategoryDescription',
          header: ['fileName', 'subtitle', 'uploadDateTableFormat', 'receptionDateTableFormat', 'categoryDescription', 'action'],
        };
      }
    )
  }

  selectedIndexPotClaim = 0;
  parties: ApiParty[];
  notInvolvedParties: ApiParty[];
  listFunz: any[];
  documents: Array<DocumentEntity>;
  datatableDocuments: RgiRxDataTableDataSource<DocumentEntity>;
  categories: Array<DocumentCategoryEntity>;
  showPartiesInCategoryTab: boolean;
  isShippingEnabled: boolean;
  minBranchRCA: boolean;
  validationVisible: boolean;




  public modalUploadDocument: { modal: ModalComponent, component: UiModalDialogUploadDocumentComponent };
  public modalEditDocument: { modal: ModalComponent, component: UiModalDialogEditDocumentComponent };

  private CUSTOM_TEMPLATE_SORT_DEF: RgiRxDataTableColumnSortDef<DocumentEntity>[] = [
    {
      name: 'uploadDate', comparator: 'funcSort'
    },
    {
      name: 'custom_template', comparator: 'uploadDate'
    }
  ];

  sortDef: RgiRxDataTableColumnSortDef<DocumentEntity>[] | boolean = this.CUSTOM_TEMPLATE_SORT_DEF;

  schema: TableSchema;

  // sortDef: RgiRxSortComparatorFn<DocumentEntity>[] | boolean = this.CUSTOM_TEMPLATE_SORT_DEF;

  // @ts-ignore
  funcSort: RgiRxSortComparatorFn<DocumentEntity> = (row: DocumentEntity) => {
    const dateObj: Date = new Date(row.uploadDate);
    return dateObj.getTime();
  }


  onToggleSortHeaders($event: RgiRxSwitchChange) {

    if ($event.selected) {
      this.sortDef = this.CUSTOM_TEMPLATE_SORT_DEF;
    } else {
      this.sortDef = false;
    }
  }

  ngOnInit() {
    super.ngOnInit();

    const jsonClaim = this.utilityServ.getJsonClaim(); // this.cache.getCache().get(this.card.idSession).data;
    if (this.utilityServ.getSysPropShowParties()) {
      this.parties = this.apiTranslatorClaimService.jsonClaimPartiesToApiClaimParties(jsonClaim.claimParties);
      this.notInvolvedParties = jsonClaim.claimNotInvolvedParties ?
        this.apiTranslatorClaimService.jsonClaimPartiesToApiClaimParties(jsonClaim.claimNotInvolvedParties) : [];
      this.parties = this.parties.concat(this.notInvolvedParties);
    }

    // ministerial branch RCA
    this.minBranchRCA = jsonClaim.taxBranches && jsonClaim.taxBranches.indexOf('000010') > -1;


    // system properties
    // booleans related to DOCUMENT VALIDATION
    // isShippingEnabled
    this.getSystemProperty.findProperty('ClaimsShippingEnabled').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsShippingEnabled') {
            this.isShippingEnabled = (variable.value === 'Enable');
          }
        }
      }
    });

    // validationVisible
    this.listFunz = this.auth.getOperator().enabledFeatures;
    // verifica abilitazione validazione document
    if (this.listFunz && this.listFunz.indexOf('SXVADO') >= 0) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
    }

    if(this.utilityServ.getSysPropShowParties()) {
      const obsName$ = this.translateService.translate('_CLAIMS_._NAME');
      const obsSubtitle$ = this.translateService.translate('_CLAIMS_._SUBTITLE');
      const obsActor$ = this.translateService.translate('_CLAIMS_._ACTOR');
      const obsUploadDate$ = this.translateService.translate('_CLAIMS_._UPLOAD_DATE');
      const obsReceiptDate$ = this.translateService.translate('_CLAIMS_._RECEIPT_DATE');
      const obsCategory$ = this.translateService.translate('_CLAIMS_._CATEGORY');
      const obsOperations$ = this.translateService.translate('_CLAIMS_._OPERATIONS');

      combineLatest([
        obsName$,
        obsSubtitle$,
        obsActor$ ,
        obsUploadDate$,
        obsReceiptDate$,
        obsCategory$,
        obsOperations$
      ]).subscribe(
        ([name, subtitle, actor, uploadDate, receiptDate, category, operations]) => {

      this.schema = {
        rows: [
          {
            name: 'fileName', title: name,
            // styleClass: 'document-datatable-cell'
          },
          {
            name: 'subtitle', title: subtitle,
          },
          {
            name: 'nameAnagrafe', title: actor,
            styleClass: 'document-datatable-cell'
          },
          {
            name: 'uploadDateTableFormat', title: uploadDate,
            styleClass: 'document-datatable-cell',
            format: {
              pipe: 'date'
            }
          },
          {
            name: 'receptionDateTableFormat', title: receiptDate,
            styleClass: 'document-datatable-cell',
            format: {
              pipe: 'date'
            }
          },
          {
            name: 'categoryDescription', title: category,
            styleClass: 'document-datatable-cell'
          },
          /*          {
                      name: 'microCategoryDescription', title: 'Sottocategoria',
                      styleClass: 'document-datatable-cell'
                    },*/
          // download - visualizza - menu modifica? sintesi in solo btn modifica
          {
            name: 'action',
            title: operations,
            actions: [ // an action row is used to display action buttons instead of text. Can have many
              {
                name: 'DOWNLOAD', // the event name that is fired upon click,
                styleClass: 'rgi-ui-btn rgifont rgi-download pull-right',
              },
              {
                name: 'VIEW',
                styleClass: 'rgi-ui-btn rgifont rgi-magnifier pull-right',
              },
              {
                name: 'EDIT',
                styleClass: 'rgi-ui-btn rgifont rgi-pencil pull-right',
              }
            ],
            description: 'Actions for each document' // a description of the field
          }

        ],
        // tslint:disable-next-line:max-line-length
        //  'microCategoryDescription',
        header: ['fileName', 'subtitle', 'nameAnagrafe', 'uploadDateTableFormat',
          'receptionDateTableFormat', 'categoryDescription', 'action'],
      }
    })
    }


    const docCats = [];
    // this.categories = this.utilityServ.getDocumentCategories();
    this.utilityServ.getClaimDocumentCategories(false)
      .subscribe((res: any) => {
        const arrDocs: Array<DocumentEntity> = new Array<DocumentEntity>();
        for (const obj of res) {
          const tmp = new DocumentCategoryEntity();
          tmp.elementDescription = obj.value.descrizioneElemento;
          tmp.elementId = obj.value.idElemento;
          tmp.isDeprecated = obj.deprecated;
          const tmpArr: Array<DocumentSubcategoryEntity> = new Array<DocumentSubcategoryEntity>();
          for (const subcat of obj.subcategories) {
            const tmpSubcat: DocumentSubcategoryEntity = new DocumentSubcategoryEntity();
            tmpSubcat.elementDescription = subcat.value.descrizioneElemento;
            tmpSubcat.elementId = subcat.value.idElemento;
            tmpSubcat.isDeprecated = subcat.deprecated;
            tmpArr.push(tmpSubcat);
          }
          tmp.subcategories = tmpArr.sort((a: DocumentCategoryEntity, b: DocumentCategoryEntity) => {
            if (a.elementDescription.toLowerCase() < b.elementDescription.toLowerCase()) {
              return -1;
            } else if (a.elementDescription.toLowerCase() > b.elementDescription.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          docCats.push(tmp);
        }

        // order categories alphabetically
        const docCatsOrdered = docCats.sort((a: DocumentCategoryEntity, b: DocumentCategoryEntity) => {
          if (a.elementDescription.toLowerCase() < b.elementDescription.toLowerCase()) {
            return -1;
          } else if (a.elementDescription.toLowerCase() > b.elementDescription.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        this.utilityServ.setPotClaimDocumentCategories(docCatsOrdered);
        this.categories = docCatsOrdered;

        // now query documents
        this.utilityServ.getPotClaimDocuments().subscribe(res2 => {

          for (const obj of res2) {
            if (obj.metaInfo == null) {
              continue;
            }
            // tslint:disable-next-line:max-line-length
            const cat = this.categories.find((v, idx, arr) => v.elementId.toString().toLowerCase() === obj.metaInfo.CATEGORIA[0].toString().toLowerCase());
            // if the document belongs to a category the user is not enabled to see
            if (!cat) {
              continue;
            } else {
              // now check if the document belongs to a SUBCATEGORY the user is not enabled to see
              // tslint:disable-next-line:max-line-length
              if (cat.subcategories.length > 0 && !cat.subcategories.find((v2, idx2, arr2) => v2.elementId === obj.metaInfo.MICRO_CATEGORIA[0])) {
                continue;
              }
            }

            const tmpDoc: DocumentEntity = new DocumentEntity();
            this.assignDocValues(tmpDoc, obj);
            arrDocs.push(tmpDoc);
          }
          this.utilityServ.setPotClaimDocuments(arrDocs);
          this.utilityServ.setPotClaimDocumentsLength(arrDocs.length);
          this.documents = arrDocs;
          this.datatableDocuments = new RgiRxDataTableDataSource<DocumentEntity>(this.documents);
          this.eventService.broadcastEvent('stop-loader');
        });
      });


  }

  assignDocValues(tmpDoc: DocumentEntity, obj: any) {
    if (this.utilityServ.getSysPropShowParties()) {
      const party2: ApiSubject = this.parties.find((value, index, arr) => value.identifier === obj.metaInfo.COD_ANAGRAFE[0]).subject;
      const denomination = party2.surname ? party2.givenName + ' ' + party2.surname : party2.designation;
      tmpDoc.nameAnagrafe = denomination;
      tmpDoc.codAnagrafe = obj.metaInfo.COD_ANAGRAFE ? obj.metaInfo.COD_ANAGRAFE[0] : '';
    }
    tmpDoc.externalVisibility = obj.externalVisibility;
    tmpDoc.shippingChannel = obj.shippingChannel;
    tmpDoc.shippingOutcome = obj.shippingOutcome;
    tmpDoc.shippingDate = obj.shippingDate;
    tmpDoc.id = obj.documentId;
    tmpDoc.isValidated = obj.metaInfo.isValidated ? obj.metaInfo.isValidated[0] === 'true' : false;
    tmpDoc.uploadDate = obj.metaInfo.dataArchiviazione ? obj.metaInfo.dataArchiviazione[0] : '';
    /*    if (!tmpDoc.uploadDate) {
          tmpDoc.uploadDate = obj.metaInfo.dataRicezione ? obj.metaInfo.dataRicezione[0] : '';
        }*/
    tmpDoc.receptionDate = obj.metaInfo.dataRicezione ? obj.metaInfo.dataRicezione[0] : '';
    if (tmpDoc.uploadDate) {
      const pz = tmpDoc.uploadDate.split('/');
      let tmp;
      tmp = pz[0];
      pz[0] = pz[1];
      pz[1] = tmp;
      const resDate = pz[0].toString() + '/' + pz[1].toString() + '/' + pz[2].toString();
      tmpDoc.uploadDateTableFormat = resDate;
    }
    if (tmpDoc.receptionDate) {
      const pz = tmpDoc.receptionDate.split('/');
      let tmp;
      tmp = pz[0];
      pz[0] = pz[1];
      pz[1] = tmp;
      const resDate = pz[0].toString() + '/' + pz[1].toString() + '/' + pz[2].toString();
      tmpDoc.receptionDateTableFormat = resDate;
    }
    tmpDoc.documentName = obj.documentName;
    tmpDoc.toSendEBDS = obj.toSendEBDS;
    tmpDoc.assetType = obj.assetType;
    tmpDoc.subtitle = obj.subTitle === 'NO SUB' ? '' : obj.subTitle;
    tmpDoc.fileType = obj.metaInfo.TIPO_FILE ? obj.metaInfo.TIPO_FILE[0] : '';
    tmpDoc.fileName = obj.metaInfo.NOME_FILE ? obj.metaInfo.NOME_FILE[0] : '';
    tmpDoc.mimetype = obj.metaInfo['mime-type'] ? obj.metaInfo['mime-type'][0] : '';
    tmpDoc.userName = obj.metaInfo.NOME_UTENTE ? obj.metaInfo.NOME_UTENTE[0] : '';
    tmpDoc.userId = obj.metaInfo.ID_UTENTE ? obj.metaInfo.ID_UTENTE[0] : '';
    tmpDoc.microCategory = obj.metaInfo.MICRO_CATEGORIA[0];
    tmpDoc.category = obj.metaInfo.CATEGORIA[0];
    // tslint:disable-next-line:max-line-length
    tmpDoc.numPages = (!obj.metaInfo.NUM_PAGINE || obj.metaInfo.NUM_PAGINE[0] === 'NULL' || !obj.metaInfo.NUM_PAGINE[0]) ? '' : obj.metaInfo.NUM_PAGINE[0];
    tmpDoc.uploadNote = obj.uploadNote;
    const fileEnt = new FileEntity();
    fileEnt.binary = obj.file.binary;
    fileEnt.fileName = obj.file.filename;
    fileEnt.fileSize = obj.file.fileSize;
    fileEnt.mimeType = obj.file.mimeType;
    tmpDoc.file = fileEnt;
    // integrate element description into document entity
    // tslint:disable-next-line:max-line-length
    const tmpCat = this.categories.find((value, index, obj2) => value.elementId.toString().toLowerCase() === obj.metaInfo.CATEGORIA[0].toString().toLowerCase());
    if (tmpCat) {
      tmpDoc.categoryDescription = tmpCat.elementDescription;
      if (tmpDoc.microCategory.length && tmpDoc.microCategory !== 'NULL' && tmpCat.subcategories.length) {
        // tslint:disable-next-line:max-line-length
        const tmpSubCat: DocumentSubcategoryEntity = tmpCat.subcategories
          // @ts-ignore
          .find((value2, index2, obj3) => value2.elementId === tmpDoc.microCategory);
        // tmpDoc.microCategoryDescription =
        if (tmpSubCat && tmpSubCat.elementDescription) {
          tmpDoc.microCategoryDescription = tmpSubCat.elementDescription;
        }
      } else {
        tmpDoc.microCategoryDescription = tmpCat.elementDescription;
      }
    }
  }

  handleUpload(party: ApiParty, category: DocumentCategoryEntity, subcategory: DocumentSubcategoryEntity) {
    if (this.utilityServ.getSysPropShowParties()) {
      this.utilityServ.setUploadParty(party);
    }
    this.utilityServ.setUploadCategory(category);
    this.utilityServ.setIsUploadPotential(true);
    if (subcategory != null) {
      this.utilityServ.setUploadSubcategory(subcategory);
    }
    this.modalUploadDocument = this.modalService.openComponent(UiModalDialogUploadDocumentComponent);
    this.modalUploadDocument.component.modalUploadEmitter.subscribe((res: any) => {
      if (res.modalMsg === 'MODAL_UPLOAD_FINISHED') {
        this.modalUploadDocument.modal.close();
        // reload for activities
        this.eventService.broadcastEvent('reloadClaim');
        this.utilityServ.getPotClaimDocuments()
          .pipe(catchError((e) => {
            this.documents = new Array<DocumentEntity>();
            return throwError(e);
          }))
          .subscribe((res2: any) => {
            const arrDocs: Array<DocumentEntity> = new Array<DocumentEntity>();
            for (const obj of res2) {
              if (obj.metaInfo == null) {
                continue;
              }
              // tslint:disable-next-line:max-line-length
              const cat = this.categories.find((v, idx, arr) => v.elementId.toString().toLowerCase() === obj.metaInfo.CATEGORIA[0].toString().toLowerCase());
              // if the document belongs to a category the user is not enabled to see
              if (!cat) {
                continue;
              } else {
                // now check if the document belongs to a SUBCATEGORY the user is not enabled to see
                if (cat.subcategories.length > 0 &&
                  !cat.subcategories.find((v2, idx2, arr2) => v2.elementId === obj.metaInfo.MICRO_CATEGORIA[0])) {
                  continue;
                }
              }
              const tmpDoc: DocumentEntity = new DocumentEntity();
              // party name
              // tslint:disable-next-line:max-line-length
              this.assignDocValues(tmpDoc, obj);
              arrDocs.push(tmpDoc);
            }
            this.utilityServ.setPotClaimDocuments(arrDocs);
            this.documents = arrDocs;
            // this.datatableDocuments = of(this.documents);
            this.datatableDocuments = new RgiRxDataTableDataSource<DocumentEntity>(this.documents);
            this.utilityServ.setPotClaimDocumentsLength(arrDocs.length);
          },
            (error) => {
              console.log('ERROR CAUGHT in getClaimDocuments ', error);
            }
          );
      }

      if (res.modalMsg === 'MODAL_CLOSED') {
        this.modalUploadDocument.modal.close();
      }
    });

  }

  getPartyCategoryDocumentCounter(identifier: string, category: DocumentCategoryEntity) {
    if (this.documents) {
      return this.documents.filter((value, index, array) => {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return value.codAnagrafe === identifier && value.category.toString().toLowerCase() === category.elementId.toString().toLowerCase() &&
          value.categoryDescription.toString().toLowerCase() === category.elementDescription.toString().toLowerCase();
      }).length;
    } else {
      return 0;
    }
  }

  getPartyCategorySubcategoryDocumentCounter(identifier: string, category: DocumentCategoryEntity, subcategory: DocumentSubcategoryEntity) {
    if (this.documents) {
      return this.documents.filter((value, index, array) => {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return value.codAnagrafe === identifier && value.category.toString().toLowerCase() === category.elementId.toString().toLowerCase() &&
          value.microCategory === subcategory.elementId &&
          value.categoryDescription.toString().toLowerCase() === category.elementDescription.toString().toLowerCase() &&
          value.microCategoryDescription === subcategory.elementDescription;
      }).length;
    } else {
      return 0;
    }
  }

  getCategoryDocumentCounter(category: DocumentCategoryEntity) {
    if (this.documents) {
      return this.documents.filter((value, index, array) => {
        // @ts-ignore
        return value.category.toString().toLowerCase() === category.elementId.toString().toLowerCase() &&
          value.categoryDescription.toString().toLowerCase() === category.elementDescription.toString().toLowerCase();
      }).length;
    } else {
      return 0;
    }
  }

  getCategorySubcategoryDocumentCounter(category: DocumentCategoryEntity, subcategory: DocumentSubcategoryEntity) {
    if (this.documents) {
      return this.documents.filter((value, index, array) => {
        // @ts-ignore
        return value.category.toString().toLowerCase() === category.elementId.toString().toLowerCase() &&
          value.microCategory === subcategory.elementId &&
          value.categoryDescription.toString().toLowerCase() === category.elementDescription.toString().toLowerCase() &&
          value.microCategoryDescription === subcategory.elementDescription;
      }).length;
    } else {
      return 0;
    }
  }

  handleOpenEditDocument(doc: DocumentEntity, partyEdit: ApiParty, catEdit: DocumentCategoryEntity, subcatEdit: DocumentSubcategoryEntity) {
    // "handleOpenEditDocument(doc, party, category, -1)"
    if (this.utilityServ.getSysPropShowParties()) {
      this.utilityServ.setEditParty(partyEdit);
    }
    this.utilityServ.setEditCategory(catEdit);
    if (subcatEdit != null) {
      this.utilityServ.setEditSubcategory(subcatEdit);
    }
    this.utilityServ.setIsEditClaimDocument(false);
    this.utilityServ.setEditDocument(doc);
    this.modalEditDocument = this.modalService.openComponent(UiModalDialogEditDocumentComponent);
    this.modalEditDocument.component.modalEditEmitter.subscribe((res: any) => {
      if (res.modalMsg === 'MODAL_EDIT_FINISHED') {
        this.modalEditDocument.modal.close();
        this.utilityServ.getPotClaimDocuments()
          .pipe(catchError(() => {
            this.documents = new Array<DocumentEntity>();
            return EMPTY;
          }))
          .subscribe((res2: any) => {
            this.eventService.broadcastEvent('start-loader');
            const arrDocs: Array<DocumentEntity> = new Array<DocumentEntity>();
            for (const obj of res2) {
              if (obj.metaInfo == null) {
                continue;
              }
              // tslint:disable-next-line:max-line-length
              const cat = this.categories.find((v, idx, arr) => v.elementId.toString().toLowerCase() === obj.metaInfo.CATEGORIA[0].toString().toLowerCase());
              // if the document belongs to a category the user is not enabled to see
              if (!cat) {
                continue;
              } else {
                // now check if the document belongs to a SUBCATEGORY the user is not enabled to see
                if (cat.subcategories.length > 0 &&
                  !cat.subcategories.find((v2, idx2, arr2) => v2.elementId === obj.metaInfo.MICRO_CATEGORIA[0])) {
                  continue;
                }
              }
              const tmpDoc: DocumentEntity = new DocumentEntity();
              // party name
              this.assignDocValues(tmpDoc, obj);
              arrDocs.push(tmpDoc);
            }
            this.utilityServ.setPotClaimDocuments(arrDocs);
            this.utilityServ.setPotClaimDocumentsLength(arrDocs.length);
            this.documents = arrDocs;
            this.datatableDocuments.update(this.documents);
            // this.datatableDocuments.update(this.documents);
            this.eventService.broadcastEvent('stop-loader');
          },
          );
      } else if (res.modalMsg === 'MODAL_CLOSED') {
        this.modalEditDocument.modal.close();
      }
    }
    );


  }

  getCategoriesFilterDeprecated(partyId: string) {
    if (this.categories && this.documents) {
      return this.categories.filter((v, i, arr) => {
        return !v.isDeprecated || (v.isDeprecated && this.getPartyCategoryDocumentCounter(partyId, v));
      });
    }
  }

  getCategoriesFilterDeprecatedNoParty() {
    if (this.categories && this.documents) {
      return this.categories.filter((v, i, arr) => {
        // tslint:disable-next-line:radix
        return !v.isDeprecated || (v.isDeprecated && this.getCategoryDocumentCounter(v));
      });
    }
  }

  getCategoryDocuments(elementId: string) {
    if (this.documents) {
      return this.documents.filter((element, index, array) => {
        // @ts-ignore
        return element.category.toString().toLowerCase() === elementId.toString().toLowerCase();
      });
    } else {
      return new Array<DocumentEntity>();
    }
  }


  getPartyCategorySubcategoryDocuments(id: string, cat: DocumentCategoryEntity, subCat: DocumentSubcategoryEntity): DocumentEntity[] {
    if (this.documents) {

      let filtered: DocumentEntity[];
      if (subCat === null) {
        filtered = this.documents.filter((element, index, array) => {
          const partyId = element.codAnagrafe;
          // tslint:disable-next-line:radix
          const category = element.category;

          // @ts-ignore
          return (partyId === id && category.toString().toLowerCase() === cat.elementId.toString().toLowerCase()
            && element.categoryDescription.toString().toLowerCase() === cat.elementDescription.toString().toLowerCase());
        });
      } else {
        filtered = this.documents.filter((element, index, array) => {
          const partyId = element.codAnagrafe;
          const category = element.category;
          const microcategory = element.microCategory;
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          return (partyId === id && category.toString().toLowerCase() === cat.elementId.toString().toLowerCase() && microcategory === subCat.elementId
            && element.microCategoryDescription === subCat.elementDescription);
        });
      }
      return filtered;

    } else {
      return new Array<DocumentEntity>();
    }
  }

  getPartyDocumentCounter(identifier: string): number {
    if (this.documents) {
      return this.documents.filter((value, index, array) => {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return value.codAnagrafe === identifier && this.categories.find((v, idx, arr) => v.elementId.toString().toLowerCase() === value.category.toString().toLowerCase());
      }).length;
    } else {
      return 0;
    }
  }

  performDownload(id: string) {
    // const companyCode = this.auth.getOperator().salePoint.company ? this.auth.getOperator().salePoint.company.code : null;
    this.utilityServ.downloadDocument(id, this.utilityServ.getCompanyCode()).subscribe((res: any) => {
      const binary = res.document.file.binary;
      // const binarydecode = window.atob(binary);
      const fileType = res.document.file.mimeType;
      let mime;
      if (this.getMimeTypeByFileType(fileType) != null) {
        mime = this.getMimeTypeByFileType(fileType);
      } else {
        mime = this.getMimeTypeByFileExtension(res.document.file.fileName);
      }
      // if file type or mime type is not defined, resort to the alternative function getMimeTypeByFileExtension
      const byteCharacters = atob(binary);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mime });
      const href = URL.createObjectURL(blob);
      const tmpLink = document.createElement('a');
      // simulate click
      tmpLink.href = href;
      tmpLink.download = res.document.file.fileName;
      document.body.appendChild(tmpLink);
      tmpLink.click();
      document.body.removeChild(tmpLink);
    });
  }

  getMimeTypeByFileType(fileType: string) {
    if (fileType) {
      if (fileType.toUpperCase() === 'pdf'.toUpperCase()) {
        return 'application/pdf';
      } else if (fileType.toUpperCase() === 'txt'.toUpperCase()) {
        return 'text/plain';
      } else if (fileType.toUpperCase() === 'jpeg'.toUpperCase()) {
        return 'image/jpeg';
      } else if (fileType.toUpperCase() === 'png'.toUpperCase()) {
        return 'image/png';
      } else if (fileType.toUpperCase() === 'docx'.toUpperCase()) {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
    }
  }

  getMimeTypeByFileExtension(fileName: string) {
    const ext = fileName.split('.').pop();
    if (ext) {
      if (ext.toUpperCase() === 'pdf'.toUpperCase()) {
        return 'application/pdf';
      } else if (ext.toUpperCase() === 'txt'.toUpperCase()) {
        return 'text/plain';
      } else if (ext.toUpperCase() === 'jpeg'.toUpperCase()) {
        return 'image/jpeg';
      } else if (ext.toUpperCase() === 'png'.toUpperCase()) {
        return 'image/png';
      } else if (ext.toUpperCase() === 'docx'.toUpperCase()) {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else {
        return 'text/plan';
      }
    }
  }


  viewDocument(id: string) {
    // const companyCode = this.auth.getOperator().salePoint.company ? this.auth.getOperator().salePoint.company.code : null;
    this.utilityServ.downloadDocument(id, this.utilityServ.getCompanyCode()).subscribe((res: any) => {
      const binary = res.document.file.binary;
      // const binarydecode = window.atob(binary);
      const fileType = res.document.file.mimeType;
      let mime;
      if (this.getMimeTypeByFileType(fileType) != null) {
        mime = this.getMimeTypeByFileType(fileType);
      } else {
        mime = this.getMimeTypeByFileExtension(res.document.file.fileName);
      }
      // if file type or mime type is not defined, resort to the alternative function getMimeTypeByFileExtension
      const byteCharacters = atob(binary);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mime });
      const href = URL.createObjectURL(blob);
      window.open(href, '_blank').focus();
    });
  }

  getCategorySubcategoryDocuments(category: DocumentCategoryEntity, subcategory: DocumentSubcategoryEntity) {
    if (this.documents) {

      let filtered: DocumentEntity[];
      filtered = this.documents.filter((element, index, array) => {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return (element.category.toString().toLowerCase() === category.elementId.toString().toLowerCase() && element.categoryDescription.toString().toLowerCase() === category.elementDescription.toString().toLowerCase() && element.microCategory === subcategory.elementId && element.microCategoryDescription === subcategory.elementDescription);
      });
      return filtered;
    } else {
      return new Array<DocumentEntity>();
    }
  }

  async performDownloadByPartyCategorySubcategory(id: string, category: DocumentCategoryEntity, subcategory: DocumentSubcategoryEntity) {
    const ids = this.getPartyCategorySubcategoryDocuments(id, category, subcategory).map((v, i, arr) => v.id);
    this.utilityServ.downloadDocumentZip(ids, this.utilityServ.getCompanyCode())
      .subscribe((res: any) => {
        const binary = res.zipFile.binary;
        // const binarydecode = window.atob(binary);
        const byteCharacters = atob(binary);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'octet/stream' });
        const href = URL.createObjectURL(blob);
        const tmpLink = document.createElement('a');
        // simulate click
        tmpLink.href = href;
        tmpLink.download = 'doc.zip';
        document.body.appendChild(tmpLink);
        tmpLink.click();
        document.body.removeChild(tmpLink);

        this.eventService.broadcastEvent('stop-loader');
      },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
        });
  }

  async performDownloadByPartyCategory(id: string, category: DocumentCategoryEntity) {

    const ids = this.getPartyCategorySubcategoryDocuments(id, category, null).map((v, i, arr) => v.id);
    this.utilityServ.downloadDocumentZip(ids, this.utilityServ.getCompanyCode())
      .subscribe((res: any) => {
        const binary = res.zipFile.binary;
        // const binarydecode = window.atob(binary);
        const byteCharacters = atob(binary);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'octet/stream' });
        const href = URL.createObjectURL(blob);
        const tmpLink = document.createElement('a');
        // simulate click
        tmpLink.href = href;
        tmpLink.download = 'doc.zip';
        document.body.appendChild(tmpLink);
        tmpLink.click();
        document.body.removeChild(tmpLink);

        this.eventService.broadcastEvent('stop-loader');
      },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
        });
  }

  async performDownloadByParty(identifier: string) {
    // retrieve documents by actor
    // const ids: Array<string> = new Array<string>();
    const ids = this.getPartyDocuments(identifier).map((v, i, arr) => v.id);
    this.utilityServ.downloadDocumentZip(ids, this.utilityServ.getCompanyCode())
      .subscribe((res: any) => {
        const binary = res.zipFile.binary;
        // const binarydecode = window.atob(binary);
        const byteCharacters = atob(binary);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'octet/stream' });
        const href = URL.createObjectURL(blob);
        const tmpLink = document.createElement('a');
        // simulate click
        tmpLink.href = href;
        tmpLink.download = 'doc.zip';
        document.body.appendChild(tmpLink);
        tmpLink.click();
        document.body.removeChild(tmpLink);

        this.eventService.broadcastEvent('stop-loader');
      },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
        });
  }

  getPartyDocuments(id: string) {
    if (this.documents) {
      let filtered: DocumentEntity[];
      filtered = this.documents.filter((element, index, array) => {
        const partyId = element.codAnagrafe;
        // @ts-ignore
        return (partyId === id);
      });
      return filtered;
    } else {
      return new Array<DocumentEntity>();
    }
  }

  onActionDataTable($event: RgiRxDatatableRowAction<any>) {
    const type = $event.name;
    const doc: DocumentEntity = $event.row;
    // retrieve document from utility service to avoid inconsistencies
    // const doc = this.documents.filter((v, idx, arr) => v.id === docId)[0];

    if (type === 'DOWNLOAD') {
      this.performDownload(doc.id);
    } else if (type === 'VIEW') {
      this.viewDocument(doc.id);
    } else if (type === 'EDIT') {
      // tslint:disable-next-line:max-line-length
      // retrieve information about doc: DocumentEntity,     partyEdit: ApiParty,     catEdit: DocumentCategoryEntity,     subcatEdit: DocumentSubcategoryEntity)
      let party: ApiParty = null;
      if (this.utilityServ.getSysPropShowParties()) {
        party = this.parties.filter((v, i, arr) => v.identifier === doc.codAnagrafe)[0];
      }
      const category: DocumentCategoryEntity = this.categories.filter((c, i, arr) =>
        c.elementId.toString().toUpperCase() === doc.category.toUpperCase())[0];
      let subcategory: DocumentSubcategoryEntity = null;
      if (category.subcategories.length > 0) {
        subcategory = category.subcategories.filter((s, i, arr) => s.elementId.toString() === doc.microCategory)[0];
      }
      this.handleOpenEditDocument(doc, party, category, subcategory);
    }
  }


  onValidateDocument(doc: DocumentEntity) {
    if (doc.isValidated) {
      return;
    }


    this.eventService.broadcastEvent('start-loader');

    this.utilityServ.validateInvalidateDocument(doc, true).subscribe(
      (res) => {
        this.utilityServ.getClaimDocuments()
          .pipe(catchError((e) => {
            this.documents = new Array<DocumentEntity>();
            return throwError(e);
          }))
          .subscribe((res2: any) => {
            const arrDocs: Array<DocumentEntity> = new Array<DocumentEntity>();
            for (const obj of res2) {
              if (obj.metaInfo == null) {
                continue;
              }
              // tslint:disable-next-line:max-line-length
              const cat = this.categories.find((v, idx, arr) => v.elementId.toString().toLowerCase() === obj.metaInfo.CATEGORIA[0].toString().toLowerCase());
              // if the document belongs to a category the user is not enabled to see
              if (!cat) {
                continue;
              } else {
                // now check if the document belongs to a SUBCATEGORY the user is not enabled to see
                if (!cat.subcategories.find((v2, idx2, arr2) => v2.elementId === obj.metaInfo.MICRO_CATEGORIA[0])) {
                  continue;
                }
              }
              const tmpDoc: DocumentEntity = new DocumentEntity();
              this.assignDocValues(tmpDoc, obj);
              arrDocs.push(tmpDoc);
            }
            this.utilityServ.setDocuments(arrDocs);
            this.documents = arrDocs;
            // this.datatableDocuments = of(this.documents);
            this.datatableDocuments = new RgiRxDataTableDataSource<DocumentEntity>(this.documents);
            // this.datatableDocuments.update(this.documents);
            this.eventService.broadcastEvent('stop-loader');
          },
            (error) => {
              console.log('CAUGHT ERROR ', error);
              this.eventService.broadcastEvent('stop-loader');
            }
          );
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );
  }

  onInvalidateDocument(doc: DocumentEntity) {
    if (!doc.isValidated) {
      return;
    }
    this.eventService.broadcastEvent('start-loader');

    this.utilityServ.validateInvalidateDocument(doc, false).subscribe(
      (res) => {
        this.utilityServ.getClaimDocuments()
          .pipe(catchError((e) => {
            this.documents = new Array<DocumentEntity>();
            return throwError(e);
          }))
          .subscribe((res2: any) => {
            const arrDocs: Array<DocumentEntity> = new Array<DocumentEntity>();
            for (const obj of res2) {

              if (obj.metaInfo == null) {
                continue;
              }
              // tslint:disable-next-line:max-line-length
              const cat = this.categories.find((v, idx, arr) => v.elementId.toString().toLowerCase() === obj.metaInfo.CATEGORIA[0].toString().toLowerCase());
              // if the document belongs to a category the user is not enabled to see
              if (!cat) {
                continue;
              } else {
                // now check if the document belongs to a SUBCATEGORY the user is not enabled to see
                if (!cat.subcategories.find((v2, idx2, arr2) => v2.elementId === obj.metaInfo.MICRO_CATEGORIA[0])) {
                  continue;
                }
              }
              const tmpDoc: DocumentEntity = new DocumentEntity();
              this.assignDocValues(tmpDoc, obj);
              arrDocs.push(tmpDoc);
            }
            this.utilityServ.setDocuments(arrDocs);
            this.documents = arrDocs;
            // this.datatableDocuments = of(this.documents);
            this.datatableDocuments = new RgiRxDataTableDataSource<DocumentEntity>(this.documents);
            // this.datatableDocuments.update(this.documents);
            this.eventService.broadcastEvent('stop-loader');
          },
            (error) => {
              console.log('CAUGHT ERROR ', error);
              this.eventService.broadcastEvent('stop-loader');
            }
          );
        this.eventService.broadcastEvent('stop-loader');
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );

  }

}

<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
    <div translate>
      {{ '_COPY_REPORT_BTN_' | translate | uppercase }}
    </div>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()">
      {{'_MODAL_CLOSE_' | translate | uppercase }}
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="copyReport(copiedId, copyreport)">
      {{'_CONFIRM_BTN_' | translate | uppercase }}
    </button>
  </rgi-rx-panel-footer>
  <p>
    {{'_MODAL_COPY_REPORT_CONFIRM_' | translate | uppercase }}
  </p>
</rgi-rx-panel>

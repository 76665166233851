<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title">{{ '_CLAIMS_._REASSESS_TASK' | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
            </h3>
        </div>
        <form [formGroup]="formGroup">
            <div class="modal-body" style="padding-top: 5px">
                
                <div class="form-group row">
                    <div class="align-self-start">                        
                        <label ng-bind="label">{{ '_CLAIMS_._GROUP' | translate }} <pnc-required required="true"></pnc-required></label>
                        <select class="core-select form-control" formControlName="gruppiWF" id="gruppiWF" name="gruppiWF" 
                                    (change)="onGroupOptionSelected()" attr.data-qa="reassign-task-group-input">
                        <option></option>
                        <option *ngFor="let group of groups;" value={{group.codice}}>
                            {{group.descrizione}}</option>
                        </select>
                    <!-- <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span> -->
                    </div>
                    <br>
                    <div >
                        <div class="align-self-start">{{ '_CLAIMS_._USERS_ASSIGNABLE_TASKS' | translate }}</div>
                        <div class="align-self-center alert alert-danger" *ngIf="errorMessage" role="alert" >{{ errorMessage | translate }} </div>
                    </div>
                      <br>
                    <div class="align-self-start">                        
                        <div class="table-responsive table coreDT table-white">
                            <!-- RDC-810, commentato per futura Evolutiva per l'aggiunta dei campi high, med e low
                            <div class="row">
                                <div class="col-xs-3 col-sm-6 col-md-3col-lg-3" >
                                    <span class="bold"></span>
                                </div>                             
                                <div class="col-xs-3 col-sm-2 col-md-3col-lg-3 text-center">
                                  <div class="label label-danger label-task-priority text-uppercase">high</div>
                                </div>
                                <div class="col-xs-3 col-sm-2 col-md-3col-lg-3 text-center">
                                  <div class="label label-warning label-task-priority text-uppercase">med</div>
                                </div>
                                <div class="col-xs-3 col-sm-2 col-md-3col-lg-3 text-center">
                                  <div class="label label-success label-task-priority text-uppercase">low</div>
                                </div>
                            </div>             
                            -->
                                <div *ngFor="let user of sortBy('realName')"
                                    class="row"                              
                                    attr.data-qa="reassign-task-user-input"
                                    >                        
                                    <div class="col-xs-3 col-sm-6 col-md-3col-lg-3">
                                        <input type="radio"  required class="asset-select-radio" name="userSelected" 
                                        value="{{user.codice}}" 
                                        (click)="onClickSelectedUser(user)"
                                        style="margin-left: 2px"
                                        > &nbsp;&nbsp; <label ng-bind="label">{{ user.realName && user.codice ? user.realName + ' - ' + user.codice : user.realName || user.codice }}</label>
                                        &nbsp;&nbsp;
                                    </div>
                                    <div class="col-xs-3 col-sm-2 col-md-3col-lg-3">
                                        <span class="bold"></span>
                                    </div>
                                    <div class="col-xs-3 col-sm-2 col-md-3col-lg-3">
                                        <span class="bold"></span>
                                    </div>
                                    <div class="col-xs-3 col-sm-2 col-md-3col-lg-3">
                                        <span class="bold"></span>
                                    </div>
                                </div>
                             
                            </div>
                        <!-- <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span> -->
                    </div>
                </div>
                <!--<div id="error-container" style="margin-top: 2em" *ngIf="validationMessages && validationMessages.length > 0">
                    <pnc-form-message type="error" detail="validationError" [messages]="validationMessages"
                        data-qa="reassign-task-action-error-messages" objectType="complex"></pnc-form-message>
                </div>-->
            </div>

            <div class="modal-footer" *ngIf="enabled">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button class="btn btn-warning text-uppercase" (click)="onConfirm()" data-qa="reassign-task-confirm-button"
                            translate>{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
import {Component} from '@angular/core';

let rgiRxFormFieldErrorCounter = 0;
@Component({
  selector: 'rgi-rx-form-field-error',
  template: '<ng-content></ng-content>',
  host: {
    '[attr.id]': 'id',
    class: 'rgi-ui-error-message rgi-rx-form-field-error-message',
    role: 'alert',
  },
  standalone: true
})
export class RgiRxFormFieldErrorComponent{
  readonly id: string;
  constructor() {
    this.id = `rgi-rx-form-field-error-${++rgiRxFormFieldErrorCounter}`;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {GpUnitClause, SingleUnit} from '../../../group-policy-models/group-policy-issue-guarantees';

@Pipe({
  name: 'gpExistUnitClauses'
})
export class GpExistUnitClausesPipe implements PipeTransform {

  transform(unitClauses: GpUnitClause[], unit: SingleUnit): boolean {
    const gpUnitClause = unitClauses.find(value => value.codeUnit === unit.code && value.codeSection === unit.sectionCode);
    return gpUnitClause ? gpUnitClause.unitClauses.length > 0 : false;
  }

}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { phoneRejex } from '../../group-policy-constants/general';
import {
  ContactTypeSelected,
  ContractAddressesPut,
  ContractAddressesResp,
  GroupPolicyContactsManagement,
  PortfolioContactsManagement
} from '../../group-policy-models/group-policy-issue-policy-data';

/**
 * @author: dmasone
 * @description: Component used for contact details
 */
@Component({
  selector: 'rgi-gp-group-contact-details-component',
  templateUrl: './group-policy-contact-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPolicyContactDetailsComponent implements OnInit, OnDestroy {

  @Output() updateContacts = new EventEmitter<ContractAddressesPut>();

  @Input() form: UntypedFormGroup;
  @Input() groupPolicyContactsManagement = GroupPolicyContactsManagement.NONE;
  @Input() portfolioContactsManagement = PortfolioContactsManagement.NONE;

  @Input() set contractAddressesResp(resp: ContractAddressesResp) {
    if (resp) {
      this.contactTypeSelected = (resp.contactTypeSelected) ? resp.contactTypeSelected : ContactTypeSelected.ORDINARY_ADDRESS;
      this.form.setControl('selectedContactType', new UntypedFormControl(this.contactTypeSelected));
      this.localModel = resp;
      this.fillForm(this.contactTypeSelected);
      this.setModel();
    }
  }

  public groupPolicyContactsManagementEnum = GroupPolicyContactsManagement;
  public portfolioContactsManagementEnum = PortfolioContactsManagement;
  public contactTypeSelectedEnum = ContactTypeSelected;
  public contactTypeSelected: ContactTypeSelected;
  public localModel: ContractAddressesResp;
  public phoneRejex = phoneRejex;

  constructor() {
  }

  ngOnInit() {
    this.form.setControl('selectedContactType', new UntypedFormControl());
  }

  ngOnDestroy(): void {
    this.form.removeControl('selectedContactType');
    this.form.removeControl(this.contactTypeSelected);
    this.form.updateValueAndValidity();
  }

  public changeContactTypeSelected(sel: ContactTypeSelected) {
    this.form.removeControl(this.contactTypeSelected);
    if (this.contactTypeSelected === ContactTypeSelected.HOMEINSURANCE) {
      this.form.removeControl(ContactTypeSelected.HOMEINSURANCE + '_number');
    }
    this.fillForm(sel);
    this.contactTypeSelected = sel;
    this.setModel();
  }

  public onChangePhoneAddress(model: any, isPhoneNumber = false, setValidatior = false) {
    const control = this.form.get(ContactTypeSelected.HOMEINSURANCE + '_number');
    if (setValidatior) {
      control.setValidators([Validators.required]);
      control.enable();
    } else {
      control.clearValidators();
      control.disable();
    }
    control.updateValueAndValidity();
    this.onChangeAddress(model, isPhoneNumber);
  }

  public onChangeAddress(model: any, isPhoneNumber = false) {
    this.updateContacts.emit(this.createPayload(model, isPhoneNumber));
  }

  protected setModel() {
    switch (this.contactTypeSelected) {
      case ContactTypeSelected.EMAIL:
        const model = this.localModel.listPolicyEmails ? this.localModel.listPolicyEmails
          .filter(email => email.selected) : undefined;
        this.onChangeAddress(model && model[0] ? model[0].email : undefined);
        break;

      case ContactTypeSelected.HOMEINSURANCE:
        if (this.localModel.homeInsurance.phoneNumber.selected) {
          this.onChangeAddress(this.localModel.homeInsurance.phoneNumber.phoneNumber, true);
        } else {
          this.onChangeAddress(this.localModel.homeInsurance.emails.filter(email => email.selected)[0].email);
        }
        break;

      case ContactTypeSelected.ORDINARY_ADDRESS:
        this.onChangeAddress(this.localModel.listPolicyAddress.filter(address => address.main)[0].address);
        break;
    }
  }

  protected fillForm(sel: ContactTypeSelected) {
    if (sel === ContactTypeSelected.HOMEINSURANCE) {
      const phoneNumber = this.localModel.homeInsurance.phoneNumber.phoneNumber;
      const value = (phoneNumber) ? phoneNumber : '';
      const controls = [];
      controls.push(Validators.pattern(phoneRejex));
      if (this.localModel.homeInsurance.phoneNumber.selected) {
        controls.push(Validators.required);
      }
      const control = new UntypedFormControl(value, controls);
      this.form.addControl(ContactTypeSelected.HOMEINSURANCE + '_number', control);
      this.localModel.homeInsurance.phoneNumber.selected ? control.enable() : control.disable();

    }
    this.form.addControl(sel, new UntypedFormControl());
    this.form.updateValueAndValidity();
  }

  protected createPayload(model: any, isPhoneNumber: boolean): ContractAddressesPut {
    const res: ContractAddressesPut = { contactTypeSelected: this.contactTypeSelected };
    const control = this.form.get(ContactTypeSelected.HOMEINSURANCE + '_number');

    switch (this.contactTypeSelected) {
      case ContactTypeSelected.EMAIL:
        res.policyEmail = model;
        break;

      case ContactTypeSelected.HOMEINSURANCE:
        if (isPhoneNumber) {
          res.phoneNumber = control.value;
        } else {
          res.policyEmail = model;
        }
        break;

      case ContactTypeSelected.ORDINARY_ADDRESS:
        res.policyAddress = model;
        break;
    }

    return res;
  }

  get showEmail() {
    return this.localModel.listPolicyEmails && this.localModel.listPolicyEmails.length > 0 &&
      (this.portfolioContactsManagement === this.portfolioContactsManagementEnum.ALL ||
        this.portfolioContactsManagement === this.portfolioContactsManagementEnum.EMAIL);
  }

  get showHomeInsurance() {
    return this.portfolioContactsManagement === this.portfolioContactsManagementEnum.ALL ||
      this.portfolioContactsManagement === this.portfolioContactsManagementEnum.HOME_INSURANCE;
  }
}

import {combineLatest, concat, forkJoin, Observable, of} from 'rxjs';
import {catchError, concatMap, map, mergeMap, tap, toArray} from 'rxjs/operators';
import {ActiveRoute} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {
  PNC_PSALES_ACTIONS,
  PncPostSalesIntegrationService,
  PncPostSalesOrchestratorService,
  PncPsalesForm,
  PncPsalesHttpErrorService,
  PncPsalesRequiredDataState,
  PncPsalesRequiredDataStateManager,
  RGI_FIELD_TYPE,
  RgiFormField,
  SelectableEntity
} from '@rgi/pnc-postsales';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {MotorDomainService} from '../../../resources/http/motor-domain.service';
import {API_PREFIX_MOTOR} from '../../../resources/constants/http';


export class MotorPsalesRequiredDataStateManagerAssetsNations extends PncPsalesRequiredDataStateManager {

  private assetFormName = 'assetForm';

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private resourceService: MotorDomainService
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
    const isStored = stateStoreService.has(this.storeKey);
    if (isStored) {
      const assetForm = stateStoreService.get<PncPsalesRequiredDataState>(this.storeKey).forms[this.assetFormName];
      if (assetForm?.fields) {
        assetForm.fields.forEach(asset => {
          this.registerOnFormFieldChange(
            this.assetFormName,
            [asset.code],
            (state, field, value) => this.updateNationsForAsset$(of(state), field, value)
          );
        });
      }
    }

  }

  initState$(state: Observable<PncPsalesRequiredDataState>): Observable<PncPsalesRequiredDataState> {
    return state.pipe(
      concatMap((st: PncPsalesRequiredDataState) => {
        this.pushMessageHandler.clearTag(this.activeRoute.route);
        return this.resourceService.getAssetsList$(st.policyNumber, st.operationType, st.currentOperation.code,
          API_PREFIX_MOTOR, st.draftId)
          .pipe(
            map((resp: any[]) => { // TODO togliere any assolutamente
              const assetsFields: RgiFormField[] = resp.map(asset => {
                return {
                  code: asset.code,
                  editable: true,
                  label: asset.description,
                  mandatory: true,
                  type: RGI_FIELD_TYPE.MULTISELECT,
                  visible: true
                };
              });
              st.forms[this.assetFormName] = new PncPsalesForm(assetsFields);
              resp.forEach(asset => this.registerOnFormFieldChange(
                  this.assetFormName,
                  [asset.code],
                  (state, field, value) => this.updateNationsForAsset$(of(state), field, value)
                )
              );
              return st;
            }),
            catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
          );
      }),
      concatMap((st: PncPsalesRequiredDataState) => {
        const observables: Observable<any>[] = st.forms[this.assetFormName].fields.map(field => {
          return this.resourceService.getAssetNations$(st.policyNumber, st.operationType, st.currentOperation.code,
            API_PREFIX_MOTOR, st.draftId, field.code)
            .pipe(
              map((resp: SelectableEntity[]) => {
                field.allowedValues = resp;
                field.value = resp.filter(opt => opt.selected).map(opt => opt.code);
                return field;
              }),
              catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route)));
        });
        return combineLatest(of(st), forkJoin(observables));
      }),
      concatMap(([st, _responses]: [PncPsalesRequiredDataState, RgiFormField[]]) => {
        return of(st);
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
  }


  onAction(action: string): Observable<PncPsalesRequiredDataState> | void {
    switch (action) {
      case PNC_PSALES_ACTIONS.BACK:
        this.actionBack();
        break;
      case PNC_PSALES_ACTIONS.CONTINUE:
        this.actionContinue();
        break;
    }
  }

  actionContinue() {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const st$ = of(this.getCurrentState()).pipe(
      concatMap((st) => {
        const observables = st.forms[this.assetFormName].fields.map(field => {
          const valuedCountries: SelectableEntity[] = field.allowedValues.map(selectableCountry => {
            return {
              code: selectableCountry.code,
              description: selectableCountry.description,
              selected: (field.value as Array<string>).some(selectedCountry => selectedCountry === selectableCountry.code)
            };
          });
          return this.resourceService.setAssetNations$(st.policyNumber, st.operationType, st.currentOperation.code,
            API_PREFIX_MOTOR, st.draftId, field.code, valuedCountries);
        });
        return concat(...observables).pipe(
          toArray(),
          map(() => {
            this.orchestrator.goToNextStep(st, this.activeRoute);
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
    this.updateState$(st$);
  }

  protected updateNationsForAsset$(st$: Observable<PncPsalesRequiredDataState>, fieldCode: string,
                                   fieldNewValue: string): Observable<PncPsalesRequiredDataState> {
    return st$.pipe(
      map((st: PncPsalesRequiredDataState) => {
        st.forms[this.assetFormName].fields.find(f => f.code === fieldCode).value = fieldNewValue;
        return st;
      })
    );
  }
}

export class ActivityTask {

  public assigneeGroup: string;
  public assigneeUser: string;
  public idActTask: number;
  public taskId: string;
  public triggerEvent: number;
  public idActDict: number;
  public priority: number;
  public decoupStatus: number;
  public idUserIns: number;
  public operationType: number;
  public expirationDate: string;
  public status: number;
  public message: string;

  constructor() { }
}

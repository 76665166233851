import { PolicyService } from './../../services/policy-service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Settlement } from '../../models/settlement.model';
import { ReinvestementService } from '../../services/reinvestment.service';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { pairwise, startWith } from 'rxjs/operators';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Component({
  selector: 'lic-reinvestment-modal',
  templateUrl: './reinvestment-modal.component.html',
  styleUrls: ['./reinvestment-modal.component.css'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class ReinvestmentModalComponent implements OnInit {
  public locale = 'it-IT';
  public optionCurrency: Intl.NumberFormatOptions = { style: 'currency' };

  public showErrors = false;
  public rinvestForm: UntypedFormGroup;
  public settlements: Settlement[];
  public selectedSettlements;
  public enableConfirm: boolean;
  public total = 0;
  private totalBackup = 0;

  public pageSize = 5;
  public page = 1;

  get liqFormArray(): UntypedFormArray {
    return this.rinvestForm.get('settlementControls') as UntypedFormArray;
  }

  constructor(
    protected activeModal: NgbActiveModal,
    protected reinvestementService: ReinvestementService,
    protected fb: UntypedFormBuilder,
    public decPipe: RgiCountryLayerNumberFormatPipe,
    protected policyService: PolicyService
  ) {
    this.rinvestForm = this.fb.group({
      selectAll: [false],
      settlementControls: fb.array([])
    });
    this.rinvestForm.setValidators(this.hasReinvestements());
    this.optionCurrency.currency = policyService.currencyCode;
    this.locale = policyService.currentLocale;
  }

  public hasReinvestements(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      if (this.total <= 0) {
        group.setErrors({ noReinvestmens: true });
      }
      return;
    };
  }

  private parseImport(text) {
    if (typeof text === 'number') {
      return text;
    } else if (text) {
      if (text.charAt(text.length - 3) === '.' || text.charAt(text.length - 2) === '.') {
        return parseFloat(text);
      } else {
        return parseFloat(text.replace('.', '').replace(',', '.'));
      }
    }
    return null;
  }

  private getTotal() {
    let result = 0;
    this.liqFormArray.controls.forEach(item => {
      if (item.get('select').value) {
        result += this.parseImport(item.get('reinvest').value);
      }
    });
    return result;
  }

  private maxReinvest(max: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = this.parseImport(control.value);
      return (value > max) ? { maxReinvest: true } : null;
    };
  }

  ngOnInit() {
    let selectAll = true;
    this.settlements.forEach((item, i) => {
      if (this.selectedSettlements) {
        const res = this.selectedSettlements.find(s => s.id === item.id);
        if (res) {
          item.select = true;
          item.reinvest = res.reinvest;
        }
      }

      const group = this.fb.group({
        select: [item.select],
        reinvest: [{ value: this.decPipe.transform(item.reinvest, this.locale), disabled: !item.select },
        Validators.compose([Validators.required, this.maxReinvest(item.import)])]
      });

      const reinvestControl = group.get('reinvest');
      group.get('select').valueChanges.subscribe(value => {
        if (value && !reinvestControl.value) {
          reinvestControl.setValue(this.decPipe.transform(this.settlements[i].import, this.locale), { emitEvent: false });
        }
        if (value) {
          reinvestControl.enable({ emitEvent: false });
        } else {
          reinvestControl.disable({ emitEvent: false });
        }
        this.total = this.getTotal();
        this.enableConfirm = this.total > 0;
      });
      reinvestControl.valueChanges.pipe(startWith(this.settlements[i].import), pairwise()).subscribe(([prev, value]) => {
        if (reinvestControl.enabled && prev !== value) {
          this.total = this.getTotal();
          this.enableConfirm = this.total > 0;
        }
      });
      this.liqFormArray.push(group);

      if (item.select) {
        this.total += item.reinvest;
      } else {
        selectAll = false;
      }
    });
    this.totalBackup = this.total;
    this.enableConfirm = this.total > 0;

    this.rinvestForm.get('selectAll').setValue(selectAll);
    this.rinvestForm.get('selectAll').valueChanges.subscribe(value => {
      this.liqFormArray.controls.forEach((gr, i) => {
        if (gr.get('select').value !== value) {
          gr.get('select').setValue(value);
        }
      });
    });
  }

  handlePageChange(page) {
    this.page = page;
  }

  hasMaxErrors() {
    return this.liqFormArray.controls.filter(item => {
      const reinvestControl = item.get('reinvest');
      return reinvestControl.errors && reinvestControl.errors.maxReinvest;
    }).length > 0;
  }

  hasRequiredErrors() {
    return this.liqFormArray.controls.filter(item => {
      const reinvestControl = item.get('reinvest');
      return reinvestControl.errors && reinvestControl.errors.required;
    }).length > 0;
  }

  close() {
    this.activeModal.dismiss(this.totalBackup > 0);
  }

  confirm() {
    if (this.rinvestForm.valid && this.enableConfirm) {
      this.liqFormArray.controls.forEach((gr, i) => {
        if (gr.get('select').value) {
          this.settlements[i].select = true;
          this.settlements[i].reinvest = this.parseImport(gr.get('reinvest').value);
        } else {
          this.settlements[i].select = false;
          this.settlements[i].reinvest = null;
        }
      });
      this.activeModal.close(this.settlements);
    } else {
      this.showErrors = true;
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {GPCluster} from '../../../group-policy-models/group-policy-cluster';
import {BaseEntity} from '../../../group-policy-models/group-policy-issue-policy-data';
import {GpAssetUnitsClauses, Units} from '../../../group-policy-models/group-policy-issue-guarantees';
import {GPVariableService} from '../../../group-policy-services/gpvariable.service';

@Component({
  selector: 'rgi-gp-cluster-detail',
  templateUrl: './gpcluster-detail.component.html'
})
export class GPClusterDetailComponent implements OnInit {

  @Input() cluster: GPCluster;
  @Input() proposalNumber: string;
  @Input() assetCode: string;
  @Input() resourceId: string;
  @Input() showApplicationList = true;
  @Input() idParentSession: string;
  @Input() clauses: GpAssetUnitsClauses;

  constructor(
    protected variableService: GPVariableService
  ) {
  }

  ngOnInit() {
  }

  getClusterProps(cluster: GPCluster) {
    const details: BaseEntity[] = [];
    if (cluster.class) {
      details.push(new BaseEntity('_GP_._LABEL_._ASSET_CLASS_', cluster.class.description));
    }
    if (cluster.use) {
      details.push(new BaseEntity('_GP_._LABEL_._ASSET_USE_', cluster.use.description));
    }
    if (cluster.sector) {
      details.push(new BaseEntity('_GP_._LABEL_._ASSET_SECTOR_', cluster.sector.description));
    }
    if (cluster.extension) {
      details.push(new BaseEntity('_GP_._LABEL_._ASSET_EXTENSION_', cluster.extension.description));
    }
    return details;
  }

  getClusterVariables(cluster: GPCluster) {
    let details: BaseEntity[] = [];
    details = cluster.variables.map(variable => {
      return {
        code: variable.description,
        description: this.variableService.getVariableDisplayValue(variable)
      };
    });
    return details;
  }

  getSelectedUnits(): Units[] {
    return this.cluster.units.filter(unit => unit.unit.selected);
  }
}

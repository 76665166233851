import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MPBalanceDetail, RiskBalanceDetail} from '../../group-policy-models/group-policy-balance';
import {GPSignedNumberPipe} from '../../group-policy-pipes/gpsigned-number.pipe';

enum RiskBalanceColumns {
  RISKDESCR = 'RISKDESCR',
  GROSS = 'GROSS',
  NET = 'NET',
  TAXES = 'TAXES',
  SSN = 'SSN',
  COMMISSIONS = 'COMMISSIONS'
}

@Component({
  selector: 'rgi-gp-balance-detail',
  templateUrl: './gpbalance-detail.component.html'
})
export class GPBalanceDetailComponent implements OnChanges {

  columnNames = RiskBalanceColumns;
  displayedColumns = Object.values(this.columnNames);
  @Input() detail: MPBalanceDetail;
  riskTotals = new RiskBalanceDetail();
  constructor(protected gpNumberPipe: GPSignedNumberPipe) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.detail && Array.isArray(changes.detail.currentValue.risks)) {
      this.riskTotals = new RiskBalanceDetail();
      changes.detail.currentValue.risks.forEach(risk => {
        this.riskTotals.gross += this.gpNumberPipe.getSignedValue(risk.gross, risk.negative);
        this.riskTotals.net += this.gpNumberPipe.getSignedValue(risk.net, risk.negative);
        this.riskTotals.taxes += this.gpNumberPipe.getSignedValue(risk.taxes, risk.negative);
        this.riskTotals.ssn += this.gpNumberPipe.getSignedValue(risk.ssn, risk.negative);
        this.riskTotals.commissions += this.gpNumberPipe.getSignedValue(risk.commissions, risk.negative);
      });
    }
  }
}

import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {RGI_RX_MENU, RgiRxMenu} from '../rgi-rx-menu-api';
import {RgiRxMenuItemDirective} from '../rgi-rx-menu-item.directive';
import {startWith, switchMap} from 'rxjs/operators';
import {merge, Observable, Subscription} from 'rxjs';
import {FocusKeyManager, FocusOrigin} from '@angular/cdk/a11y';
import {DOWN_ARROW, ESCAPE, hasModifierKey, LEFT_ARROW, RIGHT_ARROW, UP_ARROW} from '@angular/cdk/keycodes';
import {Directionality} from '@angular/cdk/bidi';

let rgiRxMenuIdCounter = 0;

@Component({
  selector: 'rgi-rx-menu',
  templateUrl: './rgi-rx-menu.component.html',
  providers: [
    {
      provide: RGI_RX_MENU, useExisting: RgiRxMenuComponent
    }
  ]
})
export class RgiRxMenuComponent extends RgiRxMenu implements AfterContentInit, OnDestroy {

  @ViewChild(TemplateRef, {static: true}) templateRef: TemplateRef<any>;
  @ContentChildren(RgiRxMenuItemDirective, {descendants: true}) items: QueryList<RgiRxMenuItemDirective>;
  @Output() onClose = new EventEmitter<any>();


  directDescendants: QueryList<RgiRxMenuItemDirective> = new QueryList<RgiRxMenuItemDirective>();
  id = `rgi-rx-menu-${rgiRxMenuIdCounter++}`;
  private _keyManager: FocusKeyManager<RgiRxMenuItemDirective>;
  private _selectionSubscription: Subscription = Subscription.EMPTY;
  private _parentMenu: RgiRxMenuComponent;

  constructor(
    private _dir: Directionality,
    private _elementRef: ElementRef
  ) {
    super();
  }
  ngAfterContentInit(): void {
    this.items.changes
      .pipe(startWith(this.items))
      .subscribe((items: QueryList<RgiRxMenuItemDirective>) => {
        this.directDescendants.reset(items.filter(item => item.parentMenu === this));
        this.directDescendants.notifyOnChanges();
      });

    this._keyManager = new FocusKeyManager(this.directDescendants)
      .withWrap()
      .withTypeAhead();

    this.directDescendants.changes
      .pipe(
        startWith(this.directDescendants),
        switchMap(items => merge(...items.map((item: RgiRxMenuItemDirective) => item._focused)))
      )
      .subscribe(focusedItem => this._keyManager.updateActiveItem(focusedItem as RgiRxMenuItemDirective));
  }

  ngOnDestroy(): void {
    this._selectionSubscription.unsubscribe();
  }


  get parentMenu(): RgiRxMenuComponent {
    return this._parentMenu;
  }

  set parentMenu(value: RgiRxMenuComponent) {
    this._parentMenu = value;
  }

  _handleKeydown(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    const manager = this._keyManager;
    switch (keyCode) {
      case ESCAPE:
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this.onClose.emit('keydown');
        }
        break;
      case LEFT_ARROW:
        if (this._parentMenu && this._dir.value === 'ltr') {
          this.onClose.emit('keydown');
        }
        break;
      case RIGHT_ARROW:
        if (this._parentMenu && this._dir.value === 'rtl') {
          this.onClose.emit('keydown');
        }
        break;
      default:
        if (keyCode === UP_ARROW || keyCode === DOWN_ARROW) {
          manager.setFocusOrigin('keyboard');
        }
        manager.onKeydown(event);
        return;
    }

    event.stopPropagation();
  }


  focusFirstItem(origin: FocusOrigin = 'program') {
    const manager = this._keyManager;

    manager.setFocusOrigin(origin).setFirstItemActive();

    if (!manager.activeItem && this.directDescendants.length) {
      let element = this.directDescendants.first.elementRef.nativeElement.parentElement;

      while (element) {
        if (element.getAttribute('role') === 'menu') {
          element.focus();
          break;
        } else {
          element = element.parentElement;
        }
      }
    }
  }

  _hovered(): Observable<RgiRxMenuItemDirective> {
    const itemChanges = this.directDescendants.changes as Observable<QueryList<RgiRxMenuItemDirective>>;
    return itemChanges.pipe(
      startWith(this.directDescendants),
      switchMap(items => merge(...items.map((item: RgiRxMenuItemDirective) => item._hovered))),
    ) as Observable<RgiRxMenuItemDirective>;
  }

  _focused(): Observable<RgiRxMenuItemDirective> {
    const itemChanges = this.directDescendants.changes as Observable<QueryList<RgiRxMenuItemDirective>>;
    return itemChanges.pipe(
      startWith(this.directDescendants),
      switchMap(items => merge(...items.map((item: RgiRxMenuItemDirective) => item._focused))),
    ) as Observable<RgiRxMenuItemDirective>;
  }


  get keyManager(): FocusKeyManager<RgiRxMenuItemDirective> {
    return this._keyManager;
  }


  get elementRef(): ElementRef {
    return this._elementRef;
  }
}

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {KarmaAuthService} from './karma-auth.service';
import { PV_TOKEN } from '../models/consts/lpc-consts';

const SSO_AUTH_HEADER_MAKER = 'RGI_KARMA_SSO';

@Injectable()
export class KarmaInterceptor implements HttpInterceptor {
  constructor(
    protected authService: KarmaAuthService,
    @Optional() @Inject(PV_TOKEN.KARMA_ENV) protected environment: any = {apiUrl: ''}
    ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.environment || !this.environment.apiUrl) {
      return next.handle(req);
    }
    if (req.url.startsWith(this.environment.apiUrl) && !req.url.startsWith(this.environment.apiUrl + '/auth/authorization')) {
        console.log('Interceptor');
        const authReq = req.clone();
        // authReq.headers.delete('rgi_locale');

        return next.handle(this.process(authReq)).pipe(
             map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.headers.has(SSO_AUTH_HEADER_MAKER)) {
                        this.authService.ssoToken = event.headers.get(SSO_AUTH_HEADER_MAKER);
                     }
                }
                return event;
        })
            );
    } else {
        return next.handle(req);
    }
  }
  private process(req: HttpRequest<any>): HttpRequest<any> {
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.authService.getSSOToken()
      }
    });
    return req;
  }
}

import {BaseEntity} from './group-policy-issue-policy-data';

export class GPMotorAppPayload {
  route: string;
  masterpolicyRef: GPMotorAppPayloadMasterPolicyRef;
  contractNumber: GPMotorAppPayloadContractNumber;

  constructor(route?: string) {
    this.route = route ? route : 'quote';
  }
}

export class GPMotorAppPayloadMasterPolicyRef {
    proposalNumber: string;
    data: {
      plateNumber: string,
      assetCode: string,
      creationReason: number,
      issueDate: number,
      effectiveDate: number,
      expirationDate?: number,
      effectiveHour: string,
      clusterCode: string,
      specialPlate: boolean,
      product: BaseEntity
    };
    constructor(proposalNumber: string, plateNumber: string, assetCode: string,
                creationReason: number, issueDate: number, effectiveDate: number,
                expirationDate: number, effectiveHour: string, clusterCode: string,
                specialPlate: boolean, product: BaseEntity) {
      this.proposalNumber = proposalNumber;
      this.data = {
        plateNumber,
        assetCode,
        creationReason,
        issueDate,
        effectiveDate,
        expirationDate,
        effectiveHour,
        clusterCode,
        specialPlate,
        product
      };
    }
}

export class GPMotorAppPayloadContractNumber {
  proposalNumber: string;
  policyNumber: string;
  createCopy?: boolean;
  subClusterCode?: string;
  subAssetCode?: string;
  isApplicationSubstitution?: boolean;

  constructor(proposalNumber: string, policyNumber: string) {
    this.proposalNumber = proposalNumber;
    this.policyNumber = policyNumber;
    this.createCopy = false;
    this.subClusterCode = null;
    this.subAssetCode = null;
    this.isApplicationSubstitution = false;
  }
}

export interface GPLifeAppPayload {
  productType: string;
  nodeCode: string;
  idpv: string;
  subject: {
    objectId: string;
  };
  product: {
    code: string;
  };
  idMasterPolicy: string;

}

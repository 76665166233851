import {AnagEntityIta} from '../anag-api/anag-subject-api';
import {AnagApiAddress} from './anag-api-party';

export class AnagDynamicObj {
  descrDynObject: string;
  dynamicAttributes: AnagDynamicAttribute[];
  idDynObject: number;
  idDynObjectInstance: number[];
}

export class AnagDynamicAttribute {
  checkDynAttribute: string;
  codeDynAttribute: string;
  descrDynAttribute: string;
  enumerationValues: AnagEntityIta[];
  idDynAttribute: number;
  mandatory: boolean;
  visibilityValues: string;
  order: number;
  typeAttribute: AnagEntityIta;
  values: AnagDynAttributeValue[];
}

export class AnagDynAttributeValue {
  addressValue: AnagApiAddress;
  dataValue: number;
  enumerationValue: AnagEntityIta;
  idDynObjectInstance: number;
  indexValue: number;
  stringValue: string;

}

export class AnagRowDataDynamicObject {
  index: number;
  surname: any;
  fiscalCode: any;
  name: any;
  idObjectInstance: any;
}

export class AnagRowDataDynamicObjectLim {
  index: number;
  surname: any;
  name: any;
  idObjectInstance: any;
}

<div>
  <rgi-rx-datatable [data]="letterList" [schema]="schema" (onAction)="onActionClick($event)"
                    [pageOptions]="[5,10,20,50]" #tableEl>
  </rgi-rx-datatable>

  <div class="btn-group btn-group-justified padding-top-25">
    <div class="btn-group">
      <button type="button" class="btn btn-warning pull-right" (click)="back()"
              attr.data-qa="letter-templates-list-empty-btn">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
    </div>
    <div class="btn-group">
      <button type="submit" class="btn btn-warning pull-right" attr.data-qa="letter-templates-list-btn"
              id="letter-templates-list-submit" (click)="openNewModel()">{{ '_CLAIMS_._BUTTONS_._NEW_LETTER_MODEL' | translate }}</button>
    </div>
  </div>
</div>


export class PolicyOperationPayload {
    userCode: string;
    productionNodeCode: string;
    authenticationNodeCode: string;
    operation: {
        code: string;
        description: string
    };
    objectId: {
      code: string;
      description: string
    };
    type: string;
}
export class PolicyOperationContainer {
  operations: Array<PolicyOperationPayload>;
}


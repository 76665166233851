<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span> {{ questionnaire.description }} </span>
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="questionnaireForm">
          <div *ngFor="let question of questions; trackBy: trackFn" class="form-group row">
            <mic-question [questionnaireElementInput]="question" [questionnaireForm]="questionnaireForm">
            </mic-question>
          </div>
        </form>
        <div *ngIf="validationMessages?.length > 0" id="error-container" style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="simple" type="error"></pnc-form-message>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-questionnaire-modal-cancel" translate>
              CANCEL
            </button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" data-qa="btn-questionnaire-modal-confirm" translate>
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container [formGroup]="group">

  <rgi-rx-form-field>
    <label>
      <span>{{factor.description}}</span>
      <span *ngIf="factor.mandatory" class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*</span>
    </label>
    <rgi-rx-button-toggle formControlName="{{factor.code}}">
      <button rgi-rx-button rgiRxValueOption rgiGpFocusElement
              [attr.data-qa]="'factor' + factor.code + '_' + factor.values[0].code"
              name="{{factor.code}}"
              value="{{factor.values[0].code}}"
              class="rgi-ui-form-control">
        {{factor.values[0].description}}
      </button>
      <button rgi-rx-button rgiRxValueOption
              [attr.data-qa]="'factor' + factor.code + '_' + factor.values[1].code"
              name="{{factor.code}}"
              value="{{factor.values[1].code}}"
              class="rgi-ui-form-control">
        {{factor.values[1].description}}
      </button>
    </rgi-rx-button-toggle>
    <div *ngIf="factor.mandatory" class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
  </rgi-rx-form-field>

</ng-container>

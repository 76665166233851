<div class="grid-item card card-height-normal card-main">

  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title">
        {{ '_CLAIMS_._TITLE' | translate }}
        <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
      </h3>
    </div>
  </div>


  <div class="container-fluid table-white" data-qa="transactions-list">
    <table class="table-responsive table coreDT">
      <thead>
        <tr class="head-result thDataTable">

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
            <span attr.data-qa="tat-desc-label">{{ '_CLAIMS_._TYPE' | translate }}</span>
          </td>

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-state-label">{{ '_CLAIMS_._STATE' | translate }}</span>
          </td>

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-eff-label">{{ '_CLAIMS_._EFFECT_DATE' | translate }}</span>
          </td>

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-exp-label">{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}</span>
          </td>

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-coll-label">{{ '_CLAIMS_._COLLECTION_DATE' | translate }}</span>
          </td>

          <td class="col-md-1 col-lg-1">
            <span attr.data-qa="tot-amount-label">{{ '_CLAIMS_._AMOUNT' | translate }}</span>
          </td>

        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let transaction of transactions; let even = even; let odd = odd; let actionIndex = index"
          [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
          attr.data-qa="act-result-{{actionIndex}}">

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-type-{{actionIndex}}">{{ transaction.recordSubTypeDescription + ' ' +
              getRecordTypeDescription(transaction.recordType) }}</span>
          </td>

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-state-{{actionIndex}}">{{ transaction.statusDescription }}</span>
          </td>

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-eff-{{actionIndex}}">{{ transaction.effectDate | date:'dd/MM/yyyy'}}</span>
          </td>

          <td class="col-xl-1 col-lg-1">
            <span attr.data-qa="tat-exp-{{actionIndex}}">{{ transaction.expirationDate | date:'dd/MM/yyyy'}}</span>
          </td>

          <td class="col-md-1 col-lg-1">
            <span attr.data-qa="tat-coll-{{actionIndex}}">{{ transaction.actualCollectionDate |
              date:'dd/MM/yyyy'}}</span>
          </td>

          <td class="col-md-1 col-lg-1">
            <span attr.data-qa="tat-amount-{{actionIndex}}">{{ transaction.amount | currency:'EUR':true}}</span>
          </td>


        </tr>
      </tbody>
    </table>

  </div>
<br>
  <ul>
    <li>Pag. 
        <a *ngFor="let arrayTransactionsPage of arrayTransactions; let even = even; let odd = odd; let titoliPagerIndex = index"
        (click)="setTransactionsList(titoliPagerIndex)" class="pointer">
            <span [ngClass]="{ 'pager-current-page': currentPageIndex == titoliPagerIndex+1 }">
                {{titoliPagerIndex + 1}}
            </span>&nbsp;
        </a>&nbsp; 
    </li>&nbsp;
</ul>

</div>
<div [formGroup]="formGroup" *ngIf="isVisible">
  <div class="row" formGroupName="factors">
    <div class="col-md-6" style="height: 77px" *ngFor="let toolConfDefinition of noGroupVisibleFactors">
      <lic-generator
        [definition]="toolConfDefinition"
        [formControlName]="toolConfDefinition.code">
      </lic-generator>
    </div>
    <div *ngFor="let toolConfDefinition of groupVisibleFactors; let first = first">
      <div class="col-md-12 header-text text-capitalize" *ngIf="first" translate>{{toolConfDefinition.group}}</div>
      <div class="col-md-6" style="height: 77px">
          <lic-generator
            [definition]="toolConfDefinition"
            [formControlName]="toolConfDefinition.code">
          </lic-generator>
      </div>
    </div>
  </div>
  <div *ngIf="!!definition.disinvestmentProfiles && definition.disinvestmentProfiles.length > 0" class="row" formGroupName="disinvestment">
    <div class="disinvestmentsfunds-header">
      <span class="header-disinvestmentsfunds" translate>lic_Disinvestmentsfunds</span>
    </div>
    <div [formGroupName]="disinvestmentProfileId">
      <lic-disinvestment-button *ngFor="let fund of filteredDisinvestmentFunds;"
        [definition]="fund"
        [formControlName]="fund.id"
        [toolId]="definition.operationCodeId"
        [minThreshold]="minThreshold"
        [maxThreshold]="maxThreshold"
        [minDisinvestmentAmount]="getMinDisinvestmentsAmount()"
        [maxDisinvestmentAmount]="getMaxDisinvestmentsAmount(fund.id)">
      </lic-disinvestment-button>
    </div>
  </div>

  <rgi-rx-form-field *ngIf="showDefaultFundDistributionCheckbox" >
    <label rgiRxLabel>{{ 'lic_fund_distribution_identical' | translate }}</label>
    <input type="checkbox" rgiRxInput formControlName="defaultFunds" [disabled]="isSameDistributionMandatory() === true ? true : null">
  </rgi-rx-form-field>
  <!-- <pnc-checkbox [disabled]="isSameDistributionMandatory()" formControlName="defaultFunds">
    <span translate>lic_fund_distribution_identical</span>
  </pnc-checkbox> -->

  <div *ngIf="showProfileInvestment && showFundDistribution" class="row">
    <div class="profile-header">
      <span class="header-profile" translate>lic_investment_profile</span>
    </div>
    <lic-profiles
      [totalAmount]="totalInvestmentAmount"
      [definition]="investmentProfileDefinitions"
      [active]="true"
      [sliderProperty]="sliderProp"
      formControlName="investmentProfiles">
    </lic-profiles>
    <div formGroupName="investmentFunds">
      <ng-container *ngIf="isStopLoss || isProgressiveSwitch || isToolLockIn">
        <div class="funds-header">
        <span class="header-funds" translate>lic_investment_funds</span>
      </div>
      <lic-funds *ngFor="let profileDefinition of selectedProfileDefinitions"
        [active]="true"
        [totalAmount]="getInvestmentAmountOfProfile(profileDefinition)"
        [definition]="profileDefinition"
        [sliderProperty]="sliderProp"
        [formControlName]="profileDefinition.id"
        #funds>
      </lic-funds>
    </ng-container>
    </div>
  </div>
  <div *ngIf="(isOnlyOneFundAvailable && !showFundDistribution) && !isStopLoss" class="row lic-margin-top-15">
    <lic-karma-fund-table
      [profiles]="definition.investmentProfiles"
      [hideValueColumn]="true"
      [decimalPercentage]="true">
    </lic-karma-fund-table>
  </div>
</div>

<div *ngIf="!isVisible">
  <p translate>lic_fund_not_available</p>
</div>

export interface LiabilityParty {
  partyVehicle: string;
  licensePlate: string;
  percLiability: number;
}

export class SubsidiaryChecksData {

  // force administrative coverage data
  showForceAdmCoverage: boolean;
  effectiveCollectionDate: Date;
  expiryCollectionDate: Date;
  collectionDate: Date;
  note: string;

  // responsibility data
  showConfirmResp: boolean;
  liabilityType: string;
  insLiabilityPercentage: number;
  applyLiabilityPercentageForcing: boolean;
  forceReason: string;
  // other parties liability
  otherPartiesLiability: Map<string, number>;


  /*    constructor(
          public showForceAdmCoverage: boolean,
          public effectiveCollectionDate: Date,
          public expiryCollectionDate: Date,
          public collectionDate: Date,
          public note: string,
      ) { }*/

}

import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Risk } from '../../models/postsales-operations-response.model';



@Component({
  selector: 'lpc-formule-modal',
  templateUrl: './lpc-formule-modal.component.html',
  styleUrls: ['./lpc-formule-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcFormuleModalComponent ),
      multi: true
    }
  ]
})
export class LpcFormuleModalComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() riskFormulasObject: Risk[];

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    riskFormulasObject : new UntypedFormArray([])
  });

  constructor() { }

  ngOnInit() {
    this.riskFormulasObject.forEach((ap, index) => {
      (this.formGroup.get('riskFormulasObject') as UntypedFormArray).push(new UntypedFormGroup({
        code: new UntypedFormControl(ap.code),
        formulas: new UntypedFormArray([])
      }));
      if (!!ap.formulas) {
        ap.formulas.forEach(formula => {
          ((this.formGroup.get('riskFormulasObject') as UntypedFormArray)
            .controls[index].get('formulas') as UntypedFormArray)
            .push(new UntypedFormGroup({
              code: new UntypedFormControl(formula.code),
              value: new UntypedFormControl(formula.value)
            }));
        });
      }
    });
    this.formGroup.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes && !!changes.riskFormulasObject && !changes.riskFormulasObject.firstChange)  {
      this.riskFormulasObject.forEach((ap, index) => {
        if (!!ap.formulas) {
          ap.formulas.forEach(formula => {
            const formulaControl = this.getFormulaControl(ap, formula.code);
            if (!!formulaControl) {
              const formulaControlValue = formulaControl.get('value');
              if (!!formulaControlValue) {
                formulaControlValue.setValue(formula.value)
              }
            }
          });
        }
      });
      }
  }

  private getFormulaControl(risk: Risk, formulaCode): UntypedFormGroup {

    const riskControl = this.getRiskControl(risk);
    if (!!riskControl) {
      const formulasControl = riskControl.get('formulas') as any;
      if (!!formulasControl) {
        for (const formulaControl of formulasControl.controls ) {
          if (formulaControl.value && formulaCode === formulaControl.value.code) {
            return formulaControl as UntypedFormGroup;
          }
        }
      }
    }
    
    return null;
  }   

  private getRiskControl(risk: Risk): UntypedFormGroup {

    const containerControl =  ((this.formGroup.get('riskFormulasObject') as UntypedFormArray));
    if (!!containerControl && containerControl.controls && containerControl.controls.length) {
      for (const riskControls of containerControl.controls ) {
        if (riskControls.value && risk.code === riskControls.value.code) {
          return riskControls as UntypedFormGroup;
        }
      }
    }
    
    return null;
  }
 
  writeValue(obj: Risk[]): void {
    if (obj) {
      this.formGroup.setValue(obj, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  onChange(obj: Risk[]) {
  }

  closeFormuleModal() {
    this.closeModal.emit(true);
  }

  saveFormulas() {
    this.save.emit(true);
  }


}

import { Component, forwardRef, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyCacheService, LpcCurrencyCache } from '../../services/currency-cache.service';

@Component({
  selector: 'lpc-installments',
  templateUrl: './lpc-installments.component.html',
  styleUrls: ['./lpc-installments.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcInstallmentsComponent),
      multi: true
    }]
})
export class LpcInstallmentsComponent implements OnInit, ControlValueAccessor {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() installments: any;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    installment: new UntypedFormControl()
  });

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.formGroup.get('installment').valueChanges.subscribe(value => {
      this.writeValue(value);
      this.onChange(value);
    });
  }

  onChange = (obj: string) => {
  }

  writeValue(obj: any): void {
    console.log(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

}

import {Component, EventEmitter, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {
  DIALOG_DATA,
  OnModalClose,
  RgiRxDatatableComponent,
  RgiRxDatatableSelectionChange,
  TableSchema
} from '@rgi/rx/ui';
import {BaseEntity} from '../../../group-policy-models/group-policy-issue-policy-data';
import {SelectionModel} from '@angular/cdk/collections';


export class MultiOptionDataTable {
  selectableOptions: BaseEntity[];
  selectedOptions: string[];
  consultationMode: boolean;

  constructor(selectableOptions: BaseEntity[], selectedOptions: string[], consultationMode: boolean) {
    this.selectableOptions = selectableOptions;
    this.selectedOptions = selectedOptions;
    this.consultationMode = consultationMode;
  }
}

@Component({
  selector: 'rgi-gp-gpvariable-option-selection-modal',
  templateUrl: './group-policy-modal-option-selection.component.html'
})
export class GroupPolicyModalOptionSelectionComponent implements OnModalClose, OnInit {

  @ViewChild(RgiRxDatatableComponent, {static: true}) table: RgiRxDatatableComponent;
  modalClose: EventEmitter<any> = new EventEmitter<BaseEntity[] | void>();

  schema: TableSchema = {
    rows: [
      {
        name: 'description', title: '_GP_._LABEL_._DESCRIPTION_',
      },
    ],
    header: ['description']
  };
  data: BaseEntity[];
  selectedOptions: BaseEntity[];
  consultationMode: boolean;
  title: string;

  constructor(@Optional() @Inject(DIALOG_DATA) data: MultiOptionDataTable) {
    this.data = data.selectableOptions;
    this.consultationMode = data.consultationMode;
    this.selectedOptions = !this.consultationMode ? data.selectableOptions.filter(opt => data.selectedOptions.includes(opt.code)) : [];
    this.title = this.consultationMode ? '_GP_._TITLE_._SELECTED_VALUES_' : '_GP_._TITLE_._EDIT_SELECTION_';
  }

  ngOnInit(): void {
    this.table.selections = new SelectionModel(true, this.selectedOptions, true);
  }

  onConfirm() {
    this.modalClose.emit(this.selectedOptions);
  }

  updateSelection($event: RgiRxDatatableSelectionChange<BaseEntity>) {
    this.selectedOptions = this.selectedOptions.concat($event.added);
    $event.removed.forEach(itemToRemove => {
      this.selectedOptions = this.selectedOptions.filter(opt => opt.code !== itemToRemove.code);
    });
  }
}

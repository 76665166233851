import {Injectable} from '@angular/core';
import {
  RePostsalesCancellationSummaryBusinessService
} from '../re-postsale-services/re-postsales-cancellation-summary-business.service';
import {AbstractStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {EventService} from '@rgi/rx';
import {of} from 'rxjs';
import {RePostsalesCancellationNavigationData} from './re-postsales-cancellation-state';
import {GenericEntity} from '../re-postsale-model/substitution';
import {
  RePostSalesCancellationConfirmationNavigationDataI
} from './re-postsales-state-manager-cancellation-confirmation.service';

export interface RePostSalesCancellationSummaryNavigationDataI {
  policy: any;
  backNavigate: string;
  contract: any;
  authenticationNodeCode: string;
  productionNodeCode: string;
  node: any;
  userCode: string;
  cancellationReason: GenericEntity;
  issueDate: string;
  effectiveDate: string;
  premiumRepaymentVisibility: boolean;
  taxes: boolean;
  taxable: boolean;
  rights: string;
  note: string;
}

export class RePostSalesCancellationSummaryState extends State {
  policy: any;
  backNavigate: string;
  contract: any;
  authenticationNodeCode: string;
  productionNodeCode: string;
  node: any;
  userCode: string;
  cancellationReason: GenericEntity;
  issueDate: string;
  effectiveDate: string;
  premiumRepaymentVisibility: boolean;
  taxes: boolean;
  taxable: boolean;
  rights: string;
  note: string;
}

@Injectable()
export class RePostsalesStateManagerCancellationSummaryService<T extends RePostSalesCancellationSummaryState> extends AbstractStateManager<T> {

  constructor(activeRoute: ActiveRoute,
              stateStoreService: StateStoreService,
              protected routingService: RoutingService,
              public eventService: EventService,
              protected datesBusinessService: RePostsalesCancellationSummaryBusinessService<T>) {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newReIssuePolicyDataState();
    this.updateState$(of(this.initCancellationData(st)));
  }


  newReIssuePolicyDataState(): T {
    return new RePostSalesCancellationSummaryState(this.activeRoute.key) as T;
  }


  initCancellationData(state: T) {
    const navData = this.activeRoute.getRouteData<RePostSalesCancellationSummaryNavigationDataI>();

    if (navData.policy) {
      state.policy = navData.policy;
    }

    if (navData.backNavigate) {
      state.backNavigate = navData.backNavigate;
    }

    if (navData.contract) {
      state.contract = navData.contract;
    }

    if (navData.node) {
      state.node = navData.node;
    }

    if (navData.authenticationNodeCode) {
      state.authenticationNodeCode = navData.authenticationNodeCode;
    }

    if (navData.productionNodeCode) {
      state.productionNodeCode = navData.productionNodeCode;
    }

    if (navData.userCode) {
      state.userCode = navData.userCode;
    }

    if (navData.cancellationReason) {
      state.cancellationReason = navData.cancellationReason;
    }

    if (navData.effectiveDate) {
      state.effectiveDate = navData.effectiveDate;
    }

    if (navData.issueDate) {
      state.issueDate = navData.issueDate;
    }

    if (navData.premiumRepaymentVisibility) {
      state.premiumRepaymentVisibility = navData.premiumRepaymentVisibility;
    }

    if (navData.taxes) {
      state.taxes = navData.taxes;
    }

    if (navData.taxable) {
      state.taxable = navData.taxable;
    }

    if (navData.rights) {
      state.rights = navData.rights;
    }

    if (navData.note) {
      state.note = navData.note;
    }

    return state;
  }

  goToNextPage(summaryForm) {
    const st = this.getCurrentState();
    const request: RePostSalesCancellationConfirmationNavigationDataI = {
      policy: st.policy,
      backNavigate: st.backNavigate,
      contract: st.contract,
      authenticationNodeCode: st.authenticationNodeCode,
      productionNodeCode: st.productionNodeCode,
      node: st.node,
      userCode: st.userCode,
      cancellationReason: st.cancellationReason,
      issueDate: st.issueDate,
      effectiveDate: st.effectiveDate,
      premiumRepaymentVisibility: st.premiumRepaymentVisibility,
      taxes: st.taxes,
      taxable: st.taxable,
      rights: st.rights,
      note: summaryForm.value.note
    };
    this.routingService.navigate('re-cancellation-confirmation', request, this.activeRoute.id);
  }

  back(summaryForm) {
    const state = this.getCurrentState();
    const dataCancellation: RePostsalesCancellationNavigationData = {
      policy: state.policy,
      backNavigate: state.backNavigate,
      contract: state.contract,
      authenticationNodeCode: state.authenticationNodeCode,
      productionNodeCode: state.productionNodeCode,
      node: state.node,
      userCode: state.userCode,
      cancellationReason: state.cancellationReason,
      effectiveDate: state.effectiveDate,
      issueDate: state.issueDate,
      premiumRepaymentVisibility: state.premiumRepaymentVisibility,
      taxes: state.taxes,
      taxable: state.taxable,
      rights: state.rights,
      note: summaryForm.value.note
    };
    this.routingService.navigate('re-cancellation-start', dataCancellation, this.activeRoute.id);
  }
}

export class LpcDetailUtils {
    public static generateDynamicTable(objectsArray): Map<number, object[]> {
        let i = 0;
        const numberArray = [];
        objectsArray.map(() => {
          numberArray.push(i);
          i++;
        });
        const chunkedArray = LpcDetailUtils.chunkSize(numberArray, 2, objectsArray);
        return chunkedArray;
    }

    public static composeArray(nRow: number): number[] {
        const composedArray = Array(nRow).fill(0).map((x, i) => i);
        return composedArray;
    }

    public static chunkSize(array: number[], size: number, objectsArray): Map<number, object[]> {
        const indexMap = new Map<number, object[]>();
        let counter = 0;
        [].concat.apply([],
        array.map((i) => {
            let objectsForEachRow = [];
            if (i % size === 0) {
            array.slice(i, i + size).map((element) => objectsForEachRow.push(objectsArray[element]));
            objectsForEachRow = LpcDetailUtils.addMissingValuesForEachRow(objectsForEachRow, size);
            indexMap.set(counter, objectsForEachRow);
            counter++;
            }
        })
        );
        return indexMap;
    }

    public static addMissingValuesForEachRow(objectsForEachRow: any[], size: number) {
        if (objectsForEachRow.length < size) {
        const missingValues = size - objectsForEachRow.length;
        const arrayWithMissingValues = new Array(missingValues).fill({description: '', value: ''});
        return objectsForEachRow.concat(arrayWithMissingValues);
        }
        return objectsForEachRow;
    }
}

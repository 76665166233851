import {Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalService, OnModalClose, RgiRxTableComponent} from '@rgi/rx/ui';
import {ReIssueNewQuotaModalComponent} from './re-issue-new-share-modal/re-issue-new-quota-modal.component';
import {CoInsuranceDTO, CoInsuranceQuota, Company} from '../../../../models/domain-models/co-insurance';
import {formatNumber} from '@angular/common';

@Component({
  selector: 'lib-re-issue-co-insurance-modal',
  templateUrl: './re-issue-co-insurance-modal.component.html',
  styleUrls: ['./re-issue-co-insurance-modal.component.css']
})
export class ReIssueCoInsuranceModalComponent implements OnInit, OnDestroy, OnModalClose {

  readonly LOCALE_ID = 'en-EN';
  readonly DIGITS_INFO = '1.2-2';

  @Input() coInsuranceDTO: CoInsuranceDTO;

  @Input() companies: Company[];
  @Input() intermediaries: Company[];
  @Input() coInsuranceQuota?: CoInsuranceQuota;
  @ViewChild(RgiRxTableComponent) table: RgiRxTableComponent<any>;

  percentageTotal: number;
  modalClose: EventEmitter<any> = new EventEmitter();
  displayedColumns: string[] = ['quota', 'commission', 'company', 'intermediary', 'policynumber', 'actions'];

  tableData: CoInsuranceQuota[];

  constructor(protected modalService: ModalService) {
  }

  ngOnInit() {

    this.tableData = this.coInsuranceDTO.coinsuranceQuotas;

    this.calculatePercentageTotal();
  }


  close() {
    this.modalClose.emit();
  }

  ngOnDestroy(): void {
    this.modalClose.emit();
  }

  openModal(quota?: CoInsuranceQuota) {
    const {modal, component} = this.modalService.openComponent(ReIssueNewQuotaModalComponent);
    component.percentageTotal = this.percentageTotal;
    component.coInsuranceDTO = this.coInsuranceDTO;
    component.companies = this.companies;
    component.intermediaries = this.intermediaries;
    quota ? component.coInsuranceQuota = quota : null;

    modal.onClose.subscribe(newCoInsuranceDTO => {
      if (newCoInsuranceDTO) {
        this.coInsuranceDTO = newCoInsuranceDTO;
        this.tableData = this.coInsuranceDTO.coinsuranceQuotas;
        this.calculatePercentageTotal();
        this.table.renderRows();
      }
    });
  }

  deleteQuota(quota: CoInsuranceQuota) {
    this.coInsuranceDTO.coinsuranceQuotas.splice(this.coInsuranceDTO.coinsuranceQuotas.indexOf(quota), 1);
    this.tableData = this.coInsuranceDTO.coinsuranceQuotas;
    this.calculatePercentageTotal();
    this.table.renderRows();
  }

  calculatePercentageTotal() {
    this.percentageTotal = this.coInsuranceDTO.ourPerc ? this.parseStringToNumber(this.coInsuranceDTO.ourPerc) : 0;
    if (this.coInsuranceDTO.coinsuranceQuotas) {
      this.coInsuranceDTO.coinsuranceQuotas.forEach(quota => {
        this.percentageTotal += this.parseStringToNumber(quota.quotaPerc);
      });
    }
  }

  parseStringToNumber(value: string): number {
    return Number(formatNumber(Number(value), this.LOCALE_ID, this.DIGITS_INFO));
  }
}


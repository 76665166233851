import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LifeSessionService } from './life-session-service';
import { EnviromentsService } from './enviroments.service';


@Injectable({
  providedIn: 'root'
})
export class PassProLifeService {

  constructor(private httpClient: HttpClient,
              private lifeSessionService: LifeSessionService,
              protected environment: EnviromentsService) {
  }

  getProductsFromService(bQuickQuotation?: boolean): Observable<any> {
    const request = {
      codLoginNode: this.lifeSessionService.codLoginNode,
      userCode: this.lifeSessionService.getOperatorUsername(),
      codManagementNode: this.lifeSessionService.codNodeManagement,
      idManagementNode: this.lifeSessionService.idPvManagement,
      onlyQuickQuotation: bQuickQuotation,
      idProductList: 1,
      holderType: {
        codice: '3'
      }
    };
    return this.httpClient.post(this.environment.baseApiPassURL + '/ptflife/products', { input: request });
  }

}

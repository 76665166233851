import {FlexDiscountParam} from '../pc-portfolio-models/discounts-models/flex-discount-param';

export class PremiumDetail {

  constructor(
    public sectionTitle: string,
    public grossAmount: string,
    public netAmount: string,
    public accessories: string,
    public instalmentInterest: string,
    public taxableAmount: string,
    public taxes: string,
    public SSN: string,
    public flexDiscounts: FlexDiscountParam
  ) {
  }
}

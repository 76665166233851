import {Inject, Injectable, Optional} from '@angular/core';
import {
  isPortalRouteDefault,
  PortalCardConfig,
  PortalCards,
  PortalRoute,
  RGI_RX_PORTAL_CARDS
} from './ajs-integration-api';
import {flatten, RgiRxInterpolationParamsType, RgiRxRuntimeError, RgiRxTemplateInterpolationService} from '@rgi/rx';
import {ActiveRoute, RgiRxRouteSnapshot} from '@rgi/rx/router';
import {combineLatest, Observable, of} from 'rxjs';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {map, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
/**
 * @description A service to manage the portal cards for the interoperability layer
 */
export class RgiRxPortalCardService {
  private cards: PortalCardConfig[];

  constructor(private translate: RgiRxTranslationService,
              private interpolate: RgiRxTemplateInterpolationService,
              @Optional() @Inject(RGI_RX_PORTAL_CARDS) portalCardConfigs?: PortalCards[]) {
    this.cards = !!portalCardConfigs ? flatten<PortalCards>(portalCardConfigs) : [];
  }

  searchCardByRouteInstance(activeRoute: ActiveRoute | RgiRxRouteSnapshot): PortalCardConfig | undefined {
    const searched = this.cards
      .filter(a => {
        return !!a && !!a.routes.filter(isPortalRouteDefault).find(r => r?.destination === activeRoute.route);
      });
    return searched && searched[0] ? searched[0] : undefined;
  }

  /**
   * @description Search for a card by its name
   * @param cardName
   */

  searchCardByName(cardName: string): PortalCardConfig | undefined {
    return this.cards.find(c => c.card.name === cardName);
  }

  /**
   * @description Translate a label of a portal route. The return will contain both the interpolated value and the translated value if any
   * translation is found. The interpolated value can be used also if no translation is defined to interpolate data into the template string.
   * @param route
   * @param params
   */

  translateCardLabel(route: PortalRoute, params: RgiRxInterpolationParamsType = {}): Observable<{
    interpolated: string,
    translated: string | undefined
  }> {
    return this.translateLabel(route.label, params);
  }


  /**
   * @description Translate a label. The return will contain both the interpolated value and the translated value if any. This method can be used when resolving to any custom label or when
   * the configuration is resolved manually.
   * @param label
   * @param params
   */

  translateLabel(label: string, params: RgiRxInterpolationParamsType = {}): Observable<{
    interpolated: string,
    translated: string | undefined
  }> {
    return of({
        key: label,
        params,
        interpolated: this.interpolate.interpolate(label, params)
      }
    ).pipe(
      mergeMap(res => {
        return combineLatest([of(res), this.translate.translate(res.key, res.params)])
      }),
      map(([res, translated]) => {
          return {
            interpolated: res.interpolated,
            translated
          }
        }
      )
    );
  }
}


/**
 * @deprecated Use RgiRxPortalCardService instead
 */
export class PortalCardService extends RgiRxPortalCardService{}

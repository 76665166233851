export class Environment {
  URL: string;
  SSO: boolean;


  constructor(URL: string, SSO: boolean = false) {
    this.URL = URL;
    this.SSO = SSO;
  }
}

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {QuestionnaireService} from './questionnaire.service';
import {CustomModalService} from '../custom-modal.service';
import {Questionnaire} from './questionnaire';
import {Modal} from '../modal';
import {Subscription} from 'rxjs';
import {Warning} from '../models/domain-models/warning';
import {ProposalService} from '../proposal.service';

@Component({
  selector: 'mic-questionnaires',
  templateUrl: './questionnaires.component.html'
})
export class QuestionnairesComponent implements OnInit, OnDestroy {

  @Input()
  questionnaires = [];
  @ViewChild('questionnaireModalContainerRef', {
    read: ViewContainerRef,
    static: true
  }) questionnaireModalViewContainerRef: ViewContainerRef;
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() valuesChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() warningsEventEmitter: EventEmitter<Array<Warning>> = new EventEmitter<Array<Warning>>();
  questionnaireModal: Type<Modal>;

  questionnairesSubscription: Subscription;

  constructor(
    public questionnaireService: QuestionnaireService,
    protected modalService: CustomModalService,
    @Inject('questionnaireModalComponent') questionnaireModalComponent: Type<Modal>,
    protected proposalService: ProposalService) {
    this.questionnaireModal = questionnaireModalComponent;
  }

  ngOnInit(): void {
    this.questionnairesSubscription = this.questionnaireService.getQuestionnairesListObservable().subscribe(data => {
      this.questionnaires = data;

      this.setNullDatesToCurrentDate(this.questionnaires);

      this.questionnaireService.setQuestionnaires(data);
      this.questionnaireService.sendInitQuestionnaireSignal();
      this.valuesChanged.emit();
    });
  }

  openQuestionnaire(code: string) {
    const questionnairesList = this.questionnaireService.getQuestionnaires();

    this.questionnaires = questionnairesList;
    this.setNullDatesToCurrentDate(this.questionnaires);
    const questionnaireToOpen: Questionnaire = this.questionnaires.find(
      questionnaire => questionnaire.code === code
    );
    if (!questionnaireToOpen) {
      return;
    }
    this.questionnaireService.setCurrentQuestionnaire(questionnaireToOpen);
    this.modalService.openModal(this.questionnaireModalViewContainerRef, this.questionnaireModal,
      null, this.onQuestionnaireModalDestroy.bind(this));
  }

  onQuestionnaireModalDestroy() {
    this.warningsEventEmitter.emit();
  }

  editQuestionnaire(code: string) {
    this.openQuestionnaire(code);
  }

  deleteQuestionnaire(code) {
    this.questionnaireService.updateQuestionnaires(code, null).subscribe(
      (data) => {
        const questionnairesList = this.questionnaireService.getQuestionnaires();

        for (let i = 0; i < questionnairesList.length; i++) {
          if (questionnairesList[i].code === data.code) {
            questionnairesList[i] = data;
          }
        }

        this.questionnaireService.setQuestionnairesList(questionnairesList);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.questionnairesSubscription) {
      this.questionnairesSubscription.unsubscribe();
    }
  }

  private setNullDatesToCurrentDate(questionnaires) {
    questionnaires.forEach(
      (questionnaire) => {
        if (questionnaire.questionList) {
          questionnaire.questionList.forEach(
            (question) => {
              if (question.answerList) {
                question.answerList.forEach(
                  (answer) => {
                    if (answer.answerType === 4 && answer.selectedValue === null) {
                      answer.selectedValue = new Date();
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

}

<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="anag-icon-m rgi-ui-icon-badge"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>_ANAG_._LABEL_._PARTY_DATA_</span>
    <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
  </div>
  <rgi-anag-party-create-key-data *ngIf="inputParty" [inEditor]="true" [inputParty]="inputParty"
                                  (modalClose)="actionClose()">
  </rgi-anag-party-create-key-data>
</div>

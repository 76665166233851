import {PncPsalesSummaryStateManager} from '../../../state-managers/summary/summary-state-manager.service';
import {ActiveRoute} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPostSalesOrchestratorService} from '../../../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../../../services/pnc-postsales-integration.service';
import {PncPsalesHttpErrorService} from '../../../services/pnc-postsales-http-error.service';
import {Observable, of} from 'rxjs';
import {PncPsalesSummaryState} from '../../../resources/states/summary-state';
import {PncPostsalesInputData} from '../../../resources/model/pnc-postsales-input-data';
import {catchError, concatMap, map} from 'rxjs/operators';
import {CancellationFullProperties} from '../../../resources/model/api/cancellation-flow';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PncPsalesCancellationApiService} from '../../../resources/http/pnc-psales-cancellation-api.service';
import {ModalService} from '@rgi/rx/ui';
import {PncPsalesBaseState} from '../../../resources/states/pnc-postsales-state';

export class PncPsalesSummaryStateManagerCancellation extends PncPsalesSummaryStateManager {


  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    modalService: ModalService,
    translateService: RgiRxTranslationService,
    private resourceService: PncPsalesCancellationApiService,
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context, modalService, translateService);
  }

  initState$(_state: Observable<PncPsalesSummaryState>): Observable<PncPsalesSummaryState> {
    const routeData = this.activeRoute.getRouteData<PncPostsalesInputData>();
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    return _state.pipe(
      concatMap((st: PncPsalesSummaryState) => {
        st.stepSummaries = [];
        this.orchestrator.getPreviousRoutes(this.activeRoute).forEach(route => {
          const storedState = Array.from(this.stateStoreService.list())
            .find(storedKeyValue => storedKeyValue[0].includes(route))[1] as PncPsalesBaseState;
          if (storedState?.summaryStepDetail) {
            st.stepSummaries.push(storedState.summaryStepDetail);
          }
        });
        return this.resourceService.getDraftData$(st.policyNumber, st.operationType, st.currentOperation.code, st.draftId).pipe(
          map((resp: CancellationFullProperties) => {
            st.allProperties = resp;
            if (st.showQuotation) {
              st.quotationHeaderData = this.populateQuotationHeader(resp.commissions, resp.annualPremium, resp.installmentDetail, null);
            }
            if (resp.violations && resp.violations.length) {
              this.errorService.manageViolationsArray(resp.violations, this.activeRoute.route);
            }
            if (routeData.notes) {
              st.allProperties.cancelProperties.notes = routeData.notes;
            }
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesSummaryState) => st)
    );
  }

  protected finalizeOperation$(state: PncPsalesSummaryState) {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const stateObs = of(state).pipe(
      concatMap((st) => {
        return this.resourceService.updateDraftData$(st.policyNumber, st.operationType, st.currentOperation.code,
          st.draftId, st.allProperties.cancelProperties)
          .pipe(
            map((draftData: CancellationFullProperties) => {
              st.allProperties = draftData;
              return st;
            }),
            catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
          );
      }),
      concatMap((st) => {
        return this.resourceService.finalizeOperation$(st.policyNumber, st.operationType, st.currentOperation.code, st.draftId)
          .pipe(
            map(() => {
              this.orchestrator.goToNextStep(st, this.activeRoute);
              return st;
            }),
            catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
          );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesSummaryState) => st)
    );
    this.updateState$(stateObs);
  }
}

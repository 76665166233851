import { Premium } from './premium';
import { DamageFactor } from './damage-factor';

export class Risk {
    public premium: Premium;
    public factors: DamageFactor[];
    public description: string;

    constructor() { }

}

import { Roles } from '../../models/enum/lpc-subjects.enum';
import { SystemProperties } from '../../models/enums/vita.enum';
import { AnagSubject } from '../../models/subject.model';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';


export class LpcRolesUtils {

    public static enableCheckbox(operations: PostsalesOperationsService, code: string): boolean {
        if (code === Roles.DELEGATE) {
            return operations.getStoredSystemProp(SystemProperties.PREVAL_DELEGATE);
        }
        if (code === Roles.THIRDPAYER) {
            return operations.getStoredSystemProp(SystemProperties.PREVAL_THIRDPAYER);
        }
        if (code === Roles.LEGAL_GUARDIAN) {
            return operations.getStoredSystemProp(SystemProperties.PREVAL_LEGALGUARDIAN);
        }
    }

    public static getRoleCode(role: string): Roles {
        const rolesCodes = new Map<string, Roles>([
            ['delegate', Roles.DELEGATE],
            ['legalGuardian', Roles.LEGAL_GUARDIAN],
            ['thirdPayer', Roles.THIRDPAYER]
        ]);
        return rolesCodes.get(role);
    }

  /**
   * @description
   * used to create a party object for the life party, espaclly for the buy quote flow on the holder of the policy
  */
  public static anagSubjectToLifeParty(subject: AnagSubject, role: Roles) {
    if (subject !== null || subject !== undefined) {
      return {
        party: {
          mainParty: null,
          ext: null,
          assetInstanceName: null,
          partyCode: null,
          specializedPartyCode: null,
          partyRole: role,
          extraInformations: subject.nominative,
          objectID: subject.objectId,
          quoteID: null
        },
        percentageParty: null,
        authorityRelease: subject?.document?.authoritiesRelease?.descrizione,
        documentDate: subject.document?.releaseDate,
        documentNumber: subject.document?.documentNumber,
        documentType: subject?.document?.documentType?.codice,
        expirationDate: subject.document?.expirationDate,
        locationRelease: subject.document?.locationsRelease,
        linkedLifeParties: []
      };
    }
    return null;
  }

}

import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_CA_ES: ClaimsTranslationsKeys = {
    _CLAIMS_TITLE_CARD_CAI_: 'Nou sinistre',
    _CLAIMS_TITLE_DIALOGUE_DASHBOARD_: 'Dialogue dashboard',
    _CLAIMS_TITLE_TIREA_DASHBOARD_: 'Tirea Payment Board',
    _CLAIMS_TITLE_DIALOGUE_HANDLER_LIST: 'Dialogue List',
    _CLAIMS_TITLE_PAYMENT_TABLE_TYREA: 'Payments table Tirea',
    _INVOICE: 'Invoice',
    _NEW_PAYMENT: 'New payment',
    _CLAIMS_CONNECTOR: 'Claims Connector',
    _CHAT: 'CHAT',
    _EDIT_CLAIMS: 'Edit Claims',
    _ACTIVITY: 'Activitat',
    _CLAIMS_PILLS: 'Claims Pills',
    _AUTOSOFT_DASHBOARD: 'Autosoft Dashboard',
    _DOSSIER_MANAGEMENT: 'Dossier management',
    _CLAIM_DETAIL: 'Claim detail',
    _NEW_FNOL: 'New FNOL',
    _RCA_QUESTIONNAIRE: 'Cuestionario RCA',
    _CLAIM_SUBJECTS: 'Claim subjects',
    _LETTER_TEMPLATES: 'Plantilles de cartes',
    _LIMITS_MANAGEMENT: 'Gestió de límits',
    _CLAIMS_FROM_ELASTIC: 'Claims from elastic',
    _COMPENSATION_LIST: 'Compensation list',
    _EXPERTS: 'Experts',
    _TIMELINE: 'Timeline',
    _INVOICES_LIST: 'Llista de factures',
    _UPDATE_RESERVE: 'Update reserve',
    _CLAIMS_: {
        _TAX_REGIME: 'Régimen Fiscal',
        _IVA_REGIME: 'Régimen del IVA',
        _AGGREGATE_TAX_REGIME: 'Régimen fiscal agregado',
        _AGGREGATE_IVA_REGIME: 'Régimen de IVA agregado',
        _FISCAL_DATA: 'Fiscal Data',
        _ACCOUNTING_DATE: 'Data de comptabilitat',
        _ACT_ACTIVE: 'Acte actiu',
        _ACT_CLOSED: 'Acte tancat',
        _ACT_EVASION_DATE: 'Acte acomplert data',
        _ACTION: 'Acció',
        _ACTIVE_CHATS: 'Xats actius',
        _ACTUAL_DATE_OF_OCCURRENCE: 'Data real de l\'incident',
        _ACTUAL_TIME_OCCURRENCE: 'Hora real de l\'incident',
        _ADD_ASSET: 'Afegiu bé assegurat',
        _ADDRESS: 'Adreça',
        _ADMIN_LEVEL_1: 'Nivell administratiu 1',
        _ADMIN_LEVEL_2: 'Nivell administratiu 2',
        _ADMIN_LEVEL_3: 'Nivell administratiu 3',
        _ADMINISTRATIVE_REGULARITY: 'Regularitat administrativa',
        _AGENCY_UNIQUE_KEY: 'Clau única de l\'agencia',
        _AGENCY: 'Agència',
        _ALLEGATION_DESCRIPTION: 'Descripció de la denúncia',
        _AMOUNT_CLEARED: 'Import liquidat',
        _AMOUNT_HOLD_TAX: 'Import de la retenció a compte',
        _AMOUNT_TO_BE_PAYD: 'Import per pagar',
        _ASSET: 'Bé assegurat',
        _ASSETS_DATA: 'Dades de bé assegurat',
        _ASSET_DESCRIPTION: 'Descripció del bé assegurat',
        _ASSIGNED_TO_A_GROUP: 'Assignat a un grup',
        _ASSIGNMENT_SELECTION: 'Selecció d\'assignació',
        _BODY: 'Cos',
        _CANCELED_BY: 'Cancel·lat per',
        _CANCEL_REQUEST_DATE: 'Cancel·la la data de sol·licitud',
        _CAP: 'ZIP',
        _CERTIFIED_OCCURRENCE_DATE: 'Data de l\'incident certificada',
        _CERTIFIED_EVENT_DATE: 'Data de l\'esdeveniment certificada',
        _CHASSIS_NUMBER: 'Número de bastidor',
        _CDL_RECEIPT_DATE: 'Data de recepció de CHC',
        _CHAT_HISTORY: 'Historial del xat',
        _CHATS_WITH_ME: 'Xats amb mi',
        _CITY: 'Ciutat',
        _COMPANY_DESCRIPTION:'Descripción de la empresa',
        _CIVIC_NUMBER: 'Número del carrer',
        _CLAIM_NUMBER_RECEIVED: 'Número de sinistre rebut',
        _CLAIM_SELECTION: 'Selecció de sinistre',
        _CLAIMS: 'SINISTRES',
        _CLAIMS_TECHNICAL_STATUS: 'Estat tècnic del sinistre',
        _COMPLAINT_DATA: 'Dades de la denúncia',
        _CLAIM_NUMBER: 'Número de sinistre',
        _CLAIM_POLICY: 'Claim Policy',
        _CLICK_HERE: 'feu clic aquí',
        _CLOSE: 'Close',
        _COHERENT: 'Coherent',
        _COLLECTION_DATE: 'Data de cobrament',
        _COMMENTS: 'Comentaris',
        _COMPULSORY_QUEST: 'Compulsory question: ',
        _CONFIRM: 'Confirmeu',
        _CONTRACTOR: 'Contractista',
        _CORPORATE_LITIGATION: 'Dret corporatiu',
        _COUNT: 'Compteu',
        _COUNTERPARTY_PLATE_NUMBER: 'Matrícula solicitada',
        _CREATION: 'Creació',
        _DAMAGE_CATEGORY: 'Categoria de danys',
        _DAMAGE_POSITION: 'Posició de dany',
        _DAMAGED: 'Part perjudicada',
        _DATE_RECEIVED_INTERMEDIARY: 'Data de recepció de l\'intermediari',
        _DAMAGE_TO_THINGS: 'Danys als béns',
        _DAMAGED__ASSET: 'Part perjudicada / Bé assegurat',
        _DAMAGED__INVOLVED_ITEM_DATA: 'Dades de l\'element danyat/implicat',
        _DAMAGED_ASSETS: 'Béns assegurats danyats',
        _DAMAGED_PARTS: 'Parts danyades',
        _DEADLINE_DATA: 'Dades del termini',
        _DELETED: 'Suprimit',
        _DEROGATION_LEVEL: 'Nivell d\'autorització',
        _DESELECT_ALL: 'Desseleccioneu-ho tot',
        _DETAILS: 'Informació',
        _LAST_EXECUTION_DETAILS: 'Last execution details',
        _SAVE_NEW_REQUEST: 'Save new request',
        _DEPARTMENT: 'Departament',
        _DOCUMENT_MANAGEMENT: 'Gestió de documents',
        _DOCUMENTS_UPLOAD: 'Càrrega de documents',
        _DRIVER: 'Conductor',
        _DRIVER_MANDATORY: 'Conductor obligatori',
        _DRIVER_LICENSE_TITLE: 'Permís de conduir',
        _RELEASED_BY: 'Alliberat per',
        _RELEASED_DATE: 'Data d\'alliberament',
        _CATEGORY: 'Categoria',
        _ADD_CATEGORY: 'Afegiu categoria',
        _DUTIES_AMOUNT: 'Import dels imposts',
        _EDIT_LIMIT: 'Edició de límit',
        _EMAIL: 'Correu electrònic',
        _ERROR_CODE: 'Codi d\'error',
        _EXCLUDE_FROM_CALCULATION: 'Excloeu del càlcul',
        _EXCLUDED_FROM_CALCULATION: 'Exclòs del càlcul',
        _EXPERT: 'Expert',
        _EFFECTIVE_COMPLAINT_DATE: 'Data efectiva de denúncia',
        _EXPERT_DATA: 'Dades del pèrit',
        _EXPERT_TYPE: 'Expert type',
        _FAX: 'Fax',
        _FEES_AMOUNT: 'Import dels honoraris',
        _FIRST_REGISTRATION_DATE: 'Primera data de registre',
        _FISCAL_CODE__VAT_CODE: 'Codi fiscal/ núm. d\'IVA',
        _FNOL_DATA_COLLECTION: 'Recollida de dades FNOL',
        _GENDER: 'Gènere',
        _GENERAL_DATA: 'Dades generals',
        _GREEN_CARD_NUMBER: 'Número de carta verda',
        _GIVEN_FIRST_REGISTRATION: 'CA_ES_Given first registration',
        _GROSS: 'En brut',
        _GROSS_EXCESS: 'Brut',
        _INCONSISTENT_ROW_AT_LINE_: 'Fila incoherent a la línia ',
        _METAINFO: 'Metainfo',
        _INDEMNITY_AMOUNT: 'Import de la indemnització',
        _INITIAL_LIMIT_AMOUNT: 'Import límit inicial',
        _INITIAL_LIMIT_VALUE: 'Valor límit inicial',
        _INJURY: 'Lesió',
        _INJURY_CODE: 'Codi de lesions',
        _INCIDENT_DATA: 'Dades de l\'incident',
        _INJURY_PHYSICAL: 'Lesió física',
        _INPS_CONTRIB_AMOUNT: 'Import de contribució INPS',
        _INSERT: 'Insert',
        _INSERT_NEW_REQUEST: 'Insereix una nova sol·licitud',
        _INSERTION_DATE: 'Insertion date',
        _INSPECTOR: 'Inspector',
        _INSTANCE: 'Instancia',
        _INSURANCE_COMPANY: 'Companyia d\'assegurances',
        _INSURED_ACTIVITY: 'Activitat assegurada',
        _INSUREDS: 'Assegurats',
        _INSURED_REPORT_DATE: 'Dades dels informes dels assegurats',
        _INVALID_FIELDS: 'Camp no vàlid',
        _INVOICE: 'Factura',
        _INVOICE_AMOUNT: 'Import de la factura',
        _INVOICE_DATA: 'Dades de factura',
        _INVOICE_DETAILS: 'Dades de la factura',
        _INVOICE_NUMBER: 'Número de factura',
        _INVOICE_TYPE: 'Tipus de factura',
        _INVOICES: 'Factures',
        _LETTER_DEROGATION_LEVEL: 'Nivell d\'autorització de la carta',
        _LETTER_TEMPLATE_SAVED: 'Plantilla de carta desada',
        _LEVEL: 'Nivell',
        _LEVEL_CODE: 'Codi de nivell',
        _LIMITS_POLICY_N: 'Límits pòlissa núm.',
        _MAIN_SUBSTATUS: 'Subestat principal',
        _MANAGE_PHASE: 'Fase de gestió',
        _MANAGEMENT_NOTE: 'Nota de gestió',
        _MANDATORY_DOCUMENTS: 'Documents obligatoris',
        _MANUAL_EXCESS: 'Manual de franquícies',
        _MANUFACTURER: 'Fabricant',
        _MATERIAL_DAMAGE: 'Danys materials',
        _MERGE_ABSORPTION_DATE: 'Merge absorption date',
        _MESSAGE: 'Missatge',
        _MESSAGE_RESPONSE: 'Resposta al missatge',
        _MESSAGES: 'Missatges',
        _MINOR: 'Menor',
        _MINORGUARDIAN: 'TUTOR MENOR',
        _MINISTERIAL_BRANCH: 'Classe ministerial',
        _MOBILE_PHONE: 'Telèfon mòbil',
        _MODELS_LIST: 'Llista de plantilles',
        _MODIFY_FNOL: 'Modifiqueu FNOL',
        _MOTOR_CARD: 'Targeta del motor',
        _NAME: 'Nom',
        _NATION_ADDRESS: 'Adreça del país',
        _NATIONALITY: 'Nacionalitat',
        _NET: 'Net',
        _NETGROSS_EXCESS: 'Franquícia neta/bruta',
        _NEW_COMPANY: 'Nova empresa',
        _NEW_LETTER_MODEL: 'Nou model de carta',
        _NEW_LIMIT: 'Nou límit',
        _NEW_UPLOAD: 'Nova càrrega',
        _CASH_PREVIDENTIAL_AMOUNT: 'Import del fons de cotitzacions socials',
        _NORMALIZE: 'Normalitzeu',
        _NOT_COHERENT: 'No és coherent',
        _NOTIFICATION_DATE: 'Data de notificació',
        _NOT_TAXABLE_AMOUNT: 'Base imposable exempta',
        _OBJECT: 'Objecte',
        _OCCURRENCE_TIME: 'Hora de l\'incident',
        _OCCURRENCE_DESCRIPTION: 'Descripció de l\'incident',
        _OBSERVATIONS: 'Observacions',
        _OPENING_DATE_FROM: 'Data d\'obertura a partir de',
        _OPENING_DATE_TO: 'Data d\'obertura a',
        _OTHER_DOCUMENTS: 'Altres documents',
        _OTHER: 'Altres',
        _OWNER: 'Propietari',
        _PARTY_INVOLVED: 'Part implicada',
        _PARKED_VEHICLE: 'Vehicle estacionat',
        _PRODUCT: 'Producte',
        _PASSENGER: 'Passatger',
        _PAYMENT_AMOUNT: 'Import de pagament',
        _PEC: 'Percentatge',
        _PERC_PREV_FUND_1: 'Percentatge prev fons 1',
        _PERC_PREV_FUND_2: 'Percentatge prev fons 2',
        _PERC_WITH_HOLD_TAX: 'Percentatge de retenció a compte',
        _PERCENTAGES: 'Percentatges',
        _PERSON: 'Persona',
        _PHONE: 'Telèfon',
        _PHYSICAL_INJURY: 'Lesió física',
        _PLATE_NUMBER: 'Matrícula asegurado',
        _POLICY: 'Pòlissa',
        _POLICY_DETAIL: 'Informació de la pòlissa',
        _POLICY_HOLDER__INSURED: 'Prenedor / Assegurat',
        _POLICY_LIST: 'Llista de pòlisses',
        _PROVINCE: 'Província',
        _REAL_NAME: 'Nom real',
        _RECEIVED_DATE: 'Data de recepció',
        _RECEIVED_DATE_FROM: 'Data de recepció de',
        _RECEIVED_DATE_TO: 'Data de recepció fins a',
        _RECIPIENT: 'Destinatari',
        _REGIME: 'Règim',
        _REGISTRY: 'Registre',
        _REJECT_WORKED: 'Rebutgeu treballat',
        _REPORT: 'Informeu',
        _RESERVE: 'Reserva',
        _RESIDUE_LIMIT_AMOUNT: 'Import límit residual',
        _RESIDUE_LIMIT_VALUE: 'Valor límit residual',
        _RISK_DESCRIPTION: 'Descripció del risc',
        _ROUNDING_AMOUNT: 'Import d\'arrodoniment',
        _SAP_CODE: 'Codi SAP',
        _SAP_RECORDING_DATE: 'Data de registre SAP',
        _SDI_CODE: 'Codi SDI',
        _SEARCH_DOCTOR: 'Cerqueu metge',
        _SELECT_ALL: 'Seleccioneu-ho tot',
        _SELECT_ASSIGNMENT: 'Seleccioneu assignació',
        _SELECT_AT_LEAST_ONE_USER: 'Seleccioneu almenys un usuari',
        _SELECT_BY_DEFAULT: 'Seleccioneu per defecte',
        _SELECT_CLAIM: 'Seleccioneu sinistre',
        _SELECT_POLICY: 'Seleccioneu pòlissa',
        _SELECT_RECIPIENT: 'Seleccioneu destinatari',
        _SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST: 'Seleccioneu l\'assignació corresponent de la llista',
        _SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST: 'Seleccioneu el sinistre corresponent de la llista',
        _SELECT_THE_CORRESPONDING_TYPE_OF_LOSS_FROM_THE_LIST: 'Seleccioneu a la llista el tipus de pèrdua corresponent',
        _SELECT_TYPE_OF_LOSS: 'Seleccioneu el tipus de sinistre',
        _SELECTECT_SUBJECT: 'Seleccioneu part',
        _SENDING_DATE: 'Data d\'enviament',
        _SIC_OUTCOME: 'Resultat SIC',
        _SIMILAR_COMPLAINTS: 'Denúncies similars',
        _SIMILAR_SUBJECT: 'Comunicat similar',
        _STAMP_DUTY_AMOUNT: 'Import de l\'impost sobre actes jurídics documentats',
        _SUBJECT_NAME: 'Nom de la part',
        _SUBJECT_SURNAME_OR_DENOMINATION: 'Cognom o denominació de la part',
        _SUBTYPOLOGY: 'Subtipologia',
        _SUBTYPOLOGY_CODE: 'Codi de subtipologia',
        _SUIT_NUMBER: 'Número de demanda',
        _SURNAME: 'Cognoms',
        _SX_SUBJECT: 'SX_Subjecte',
        _TAX_ID: 'Identificació fiscal',
        _TECHNICAL_COVERAGE: 'Cobertura tècnica',
        _TAXABLE_AMOUNT: 'Base imposable',
        _TOTAL_DISTRUCTION: 'Cancel·lació',
        _THINGS: 'Béns',
        _THIRD_PARTY: 'Tercers',
        _TO_BE_EVALUATED: 'Pendent d\'avaluació',
        _TO_BE_REEVALUATED: 'Per revaluar',
        _TO_SELECT: 'seleccioneu',
        _TOPONYM: 'Nom del lloc',
        _TOTAL: 'Total',
        _TOT_AMOUNT_UNIT: 'Import unitari total',
        _TOTAL_RESERVES_AMOUNT: 'Import total de les reserves',
        _TYPE_OF_LOSS_DESCRIPTION: 'Descripció del tipus de sinistre',
        _TYPE_OF_LOSS_SELECTION: 'Selecció del tipus de sinistre',
        _TYPE_OF_DAMAGE: 'Tipus de dany',
        _TYPE_CIVIL__PENAL: 'Tipus Civil/Penal',
        _UNIQUE: 'Únic',
        _UPDATE_REASON: 'Actualitzeu motiu',
        _USER_ASSOCIATION: 'Associació d\'usuari',
        _USER_BY_WORKLOAD: 'Usuari (per càrrega de feina)',
        _USER_DATA: 'Dades de l\'usuari',
        _USER_DEROGATION_LEVEL: 'Nivell d\'autorització de l\'usuari',
        _VALIDITY_END_DATE: 'Data de fi de la validesa',
        _VALIDITY_START_DATE: 'Data d\'inici de la validesa',
        _VAT_AMOUNT: 'Import IVA',
        _VAT_RATE: 'Tipus d\'IVA',
        _VEHICLE: 'Vehicle',
        _VISIBLE_DAMAGE_ON_VEHICLE: 'Danys visibles al vehicle',
        _NUMBER_CLAIM: 'Número de sinistre',
        _OCCURRENCE_DATE: 'Data de l\'incident',
        _CLAIM_BRANCH: 'Sucursal del sinistre',
        _CLAIM_TYPE: 'Tipus de sinistre',
        _POLICY_NUMBER: 'Número de pòlissa',
        _POLICY_NR: 'Núm. De pòlissa',
        _CDL: 'CHC',
        _ADJUSTER: 'Liquidador',
        _PROCEDURE_NUMBER: 'Número de procediment',
        _COUNTERPART: 'Contrapart',
        _COUNTERPARTS: 'Counterparts',
        _INSURED: 'Assegurat',
        _COMPLAINT_DATE: 'Data de la denúncia',
        _COVERTURE_TYPE: 'Tipus de cobertura',
        _CLAIM_NUMBER_STATUS: 'Número i estat del sinistre',
        _COMPLAINT_NUMBER_STATUS: 'Número i estat de la denúncia',
        _OCCURRENCE_DATA: 'Dades de l\'incident',
        _DOCUMENTS: 'Documents',
        _CANCELLATION_DATE: 'Data de cancel·lació',
        _DESCRIPTION: 'Descripció',
        _EFFECT_DATE: 'Data de vigència',
        _EXPIRATION_DATE: 'Data de venciment',
        _INVALID_POLICY: 'Pòlissa no vàlida',
        _POLICY_HOLDER: 'Titular',
        _UNITS: 'Unitat',
        _VALID_POLICY: 'Pòlissa vàlida',
        _MANAGEMENT_NODE: 'Node de gestió',
        _CONTRACT_STATUS: 'Estat del contracte',
        _FRACTIONING: 'Pagament a terminis',
        _ISSUE_FRACTIONING: 'Emissió a terminis',
        _POLICY_TYPE: 'Tipus de pòlissa',
        _ISSUE_DATE: 'data d\'emissió',
        _COVERAGE_EXPIRATION_DATE: 'Data de caducitat de la cobertura',
        _COINSURANCE_SHARE: 'Quota de coassegurança',
        _INITIAL_EFFECTIVE_DATE: 'Data inicial d\'entrada en vigor',
        _RESCINDABILITY_DATE: 'Data de rescissió',
        _COINSURANCE_TYPE: 'Tipus de coassegurança',
        _PREMIUM_ADJUSTMENT: 'Ajust de la prima',
        _INDEXING: 'Indexació',
        _LPS: 'LPS',
        _NO: 'No',
        _YES: 'SÍ',
        _INDIRECT_COINSURANCE: 'Coassegurança indirecta',
        _MIGRATED: 'Migrat',
        _REINSURANCE: 'Reassegurança',
        _BONDED: 'Garantit',
        _TACIT_RENEWAL: 'Renovació tàcita',
        _SUBJECTS: 'Parts',
        _ARCHIVE_SUBJECTS: 'Fitxer de parts',
        _LOCATIONS: 'Ubicacions',
        _PRINCIPAL: 'Principal',
        _PROPERTY_ADDRESS: 'Adreça de la propietat',
        _SECONDARY: 'Secundari',
        _ACTIVITIES: 'Activitats',
        _VEHICLE_DATA: 'Informació del vehicle',
        _CLASS: 'Classe',
        _EXTENSION: 'Extensió',
        _SPECIAL_ABBREVIATION: 'Abreviatura especial',
        _PLATE_TYPE: 'Tipus de matrícula',
        _BRAND: 'Marca',
        _MODEL: 'Model',
        _FACTORS: 'Factors',
        _CLAUSES: 'Clàusules',
        _ADDITIONAL_DATA: 'Dades addicionals',
        _TYPE_OF_AUTHORITY: 'Tipus d\'autoritats',
        _DESCRIPTION_AUTHORITY: 'Descripció de l\'autoritat',
        _CATASTROPHIC_EVENT: 'Catàstrofe',
        _DESCRIPTION_EVENT: 'Descripció del succés',
        _ANTI_FRAUD_CATHEGORY: 'Categoria antifrau',
        _LAST_AUDIT_DATE: 'Última data de auditoria',
        _LIQUIDABLE: 'Liquidable',
        _NATION: 'País',
        _ZIP_CODE: 'Codi postal',
        _BODYWORK: 'Carrosseria',
        _SELECT: 'Seleccioneu',
        _DENOMINATION: 'Denominació',
        _TELEPHONE: 'Telèfon',
        _ACCIDENT_CIRCUMSTANCE: 'Circumstància de l\'accident',
        _BAREMS: 'Escales',
        _BLACK_BOX_COHERENCE: 'Coherència de caixa negra',
        _ACCIDENTS: 'Accidents',
        _INSURED_LIABILITY_PERC: 'Percentatge de responsabilitat assegurada',
        _EVENT_DENIAL: 'Casos de denegació',
        _SIGN_TYPE: 'Tipus de senyal',
        _INSURED_VEICHLE_POSITION: 'Posició del vehicle assegurat',
        _THIRD_PART_VEICHLE_POSITION: 'Posició del vehicle de tercers',
        _CALCULATED_LIABILITY: 'Responsabilitat calculada',
        _LIABILITY_TYPE: 'Tipus de responsabilitat',
        _COUNTERPART_LIABILITY_PERC: 'Percentatge de responsabilitat de la contrapart',
        _DECLARED_LIABILITY: 'Responsabilitat declarada',
        _ANIA_CLASSIFICATION: 'Classificació ANIA',
        _APPLY_TECH_LIABILITY_FORCED: 'Apliqueu la responsabilitat tècnica obligada',
        _NOTE: 'Nota',
        _APPLY_ANIA_LIABILITY_FORCED: 'Apliqueu la responsabilitat obligada ANIA',
        _FORCE_REASON: 'Forceu el motiu',
        _FORCED_LIABILITY: 'Forceu la responsabilitat',
        _EDIT_ACCIDENTS: 'Editeu accidents',
        _USE: 'Ús',
        _BUTTONS_: {
            _ACTIONS: 'ACCIONS',
            _BACK: 'ENRERE',
            _CLOSE: 'TANQUEU',
            _NEXT: 'SEGÜENT',
            _NEW: 'NOU',
            _CANCEL: 'CANCEL·LEU',
            _CONFIRM: 'CONFIRMEU',
            _EXIT: 'SORTIU',
            _SAVE: 'DESEU',
            _EMPTY: 'BUIT',
            _FIND: 'TROBEU',
            _SHOW: 'MOSTREU',
            _HIDE: 'OCULTEU',
            _INSERT: 'INTRODUÏU',
            _NEW_DIALOGUE: 'NEW DIALOGUE',
            _ASSOCIATE: 'ASSOCIATE',
            _REQUEST: 'REQUEST',
            _RESET_ADVANCE: 'RESET ADVANCE',
            _SAVE_PES: 'SAVE PES',
            _CORRECT: 'CORRECT',
            _ADD: 'ADD',
            _SEARCH: 'Cerqueu',
            _IBD: 'IBD',
            _OK: 'OK',
            _ADD_NOTE: 'ADD NOTE',
            _FAILED_TRANSMISSION: 'FAILED TRANSMISSION',
            _EVENTS: 'EVENTS',
            _SEND: 'Envieu',
            _DOWNLOAD_DOCUMENTI_SELEZIONATI: 'DOWNLOAD SELECTED DOCUMENTS',
            _DOWNLOAD_CLAIM_DOCUMENTS: 'DOWNLOAD CLAIM DOCUMENTS',
            _DOCVIEWER: 'DOCVIEWER',
            _DOWNLOAD_POLICY_DOCUMENTS: 'DOWNLOAD POLICY DOCUMENTS',
            _EDIT: 'Editeu',
            _NEW_REQUEST: 'New Request',
            _RERUN: 'Rerun',
            _RESERVATION: 'RESERVATION',
            _ONDEMAND: 'ONDEMAND',
            _NEW_MANUAL_DEADLINE: 'Nou termini manual',
            _UPLOAD: 'Càrrega',
            _TRANSMISSION: 'TRANSMISSION',
            _MODELS_LIST: 'Models list',
            _NEW_LETTER_MODEL: 'New letter model',
            _DELETE: 'CA_ES_DELETE',
            _ADD_ACTOR: 'CA_ES_ADD ACTOR',
            _RETRIEVE_INFORMATION: 'CA_ES_RETRIEVE INFORMATION',
            _REASSOCIATE: 'Reassociate',
            _MODIFY: 'MODIFY',
            _RESPONSE: 'RESPONSE',
        },
        _MESSAGE_: {
            _NO_RESULT: 'Cap resultat',
            _OPENED_CLAIM_DO_ISSUE: 'Sinistre obert per citació judicial',
            _NOT_IDENTIFIED_WITNESS: 'Testimoni no identificat',
            _SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION: 'Seleccioneu només les caselles d\'ús per a la descripció',
            _SELECT_AT_LEAST_ONE_CAUSAL: 'Seleccioneu almenys un motiu abans de continuar',
            _INVALID_DATE_INCIDENT: 'La data introduïda no pot ser diferent de la data comunicada',
            _AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT: 'L\'import per pagar no pot ser més petit que la suma de la base imposable i la base no imposable',
            _AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT: 'Import per pagar diferent de l\'import de la factura menys l\'import de la retenció a compte',
            _AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO: 'L\'import per pagar ha de ser superior a zero',
            _ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Advertència. Esteu obrint un sinistre sobre una pòlissa tècnicament no coberta',
            _ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY: 'Advertiment: obertura de sinistre sobre una pòlissa cancel·lada',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT: 'Advertiment: el pagament supera els imports residuals del límit',
            _CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY: 'No es pot obrir un sinistre sobre una pòlissa cancel·lada',
            _CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY: 'No es pot obrir un sinistre sobre una pòlissa sense validesa administrativa',
            _CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'L\'import del fons de cotitzacions socials no pot ser superior a la base imposable',
            _DRAG_A_FILE_TO_UPLOAD: 'Arrossegueu un fitxer per carregar-lo',
            _HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'L\'import de la retenció a compte no pot ser superior a la base imposable',
            _IMPOSSIBLE_TO_EXECUTE_THE_REQUIRED_OPERATION_CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossible executar l\'operació requerida: rebuts de sinistres pendents d\'autoritzar',
            _IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'No es pot procedir: Usuari no autoritzat per obrir un sinistre sobre una pòlissa tècnicament no coberta',
            _INSERT_A_CSV_FILE: 'Inseriu un fitxer .csv',
            _INSERT_POLICY_NUMBER_OR_PLATE: 'Inseriu número de pòlissa o matrícula',
            _INSERT_SUBJECT_SURNAME_OR_DENOMINATION: 'Inseriu cognom o denominació de la part',
            _INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT: 'Import de la factura diferent de la suma de la base imposable, la base imposable exempta, l\'import del fons de cotitzacions socials, l\'import de l\'impost sobre actes jurídics documentats i l\'import de l\'IVA',
            _INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'L\'import de la factura ha de ser superior a zero',
            _IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM: 'Impossible suprimir un documento que ja ha estat carregat al sistema de gestió dels documents',
            _MISSING_CLAIM_NUMBER_AT_LINE: 'Falta el número de sinistre a la línia ',
            _MISSING_NOT_TAXABLE_AMOUNT_AT_LINE: 'Falta la base imposable exempta a la línia ',
            _MISSING_TAXABLE_AMOUNT_AT_LINE: 'Falta la base imposable a la línia ',
            _MISSING_TOTAL_UNIT_AMOUNT_AT_LINE: 'Falta l\'import total per unitat a la línia ',
            _N_NOT_CONSISTENT: 'N Els danys del vehicle no concorden amb les dades de la caixa negra',
            _NO_ACTIVITY_TO_SHOW: 'No hi ha activitat per mostrar',
            _NO_CHANGE_MADE: 'Sense canvis',
            _NO_CHAT: 'Sense xat',
            _NO_DOCUMENTS_TO_UPLOAD: 'No hi ha documents per carregar',
            _NO_NORMALIZED_ADDRESS_FOUND: 'No s\'ha trobat cap adreça normalitzada',
            _NO_RESULTS: 'No hi ha resultats',
            _POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY: 'Pòlissa no conforme amb la categoria de danys seleccionada',
            _PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM: 'Premeu el botó de calcular abans de confirmar',
            _RESERVE_AMOUNT_NOT_CAPACIOUS_UPDATE_RESERVE_BEFORE: 'L\'import de la reserva no és elevat: actualitzeu primer la reserva',
            _POLICY_NOT_COMPLIANT: 'Pòlissa no conforme amb la categoria de danys seleccionada',
            _S_CONSISTENT: 'Y Els danys del vehicle concorden amb les dades de la caixa negra',
            _SAVING_ERROR: 'Error en desar',
            _SEARCH_ERROR: 'Error de cerca',
            _STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'L\'import de l\'impost sobre actes jurídics documentats no pot ser superior a la base imposable',
            _SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'La suma de la base imposable i de la base imposable exempta ha de ser superior a zero',
            _SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma de la base imposable exempta de les dades diferent de la base imposable exempta de la factura',
            _SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma de la base imposable de les dades diferent de la base imposable de la factura',
            _SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT: 'Suma de l\'import unitari total de les dades diferent de l\'import de la factura',
            _TAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'La base imposable ha de ser superior a zero',
            _THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE: 'El document se suprimirà de forma permanent. Voleu continuar?',
            _THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE: 'La pòlissa també cobreix els danys materials del vehicle',
            _TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST: 'El títol i el nivell d\'autorització ja existeixen',
            _TOL_CLAIMS_MADE: 'Existeixen tipus de pèrdues gestionades amb sinistres realitzats / pòstums: es faran de nou els controls de validació tècnica i administrativa a l\'apartat de tipus de pèrdua',
            _TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'L\'import total unitari ha de ser superior a zero',
            _UNABLE_TO_MAKE_THE_PAYMENT_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED: 'Impossible efectuar el pagament: import residual del límit superat',
            _UPDATE_SUCCESSFUL: 'Actualització correcta',
            _VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'L\'import de l\'IVA no pot ser superior a la base imposable',
            _WARNING_YOU_ARE_EXCEEDING_THE_CEILING: 'Advertiment: esteu sobrepassant el límit màxim',
            _X_TO_BE_VERIFIED: 'X Coherència dels danys amb les dades de la caixa negra pendents de verificar',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET: 'Heu de seleccionar almenys un bé assegurat',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY: 'Heu de seleccionar almenys una part',
            _YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD: 'Necessiteu afegir un documento per executar la càrrega',
            _TYPE_OF_BODYWORK_IS_MANDATORY: 'El tipus de carrosseria és obligatori',
            _NO_RES_FOUND: 'No s\'ha trobat cap resultat',
            _CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY: 'It is impossible to open a claim on an uncovered policy',
            _FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES: 'Fields marked in red are required or contain incorrect values',
            _INCORRECT_OR_MISSING_DATA: 'Incorrect or missing data',
            _THERE_ARE_TYPES_OF_LOSS_MANAGED_WITH_CLAIMS_MADE__POSTUMA: 'There are types of loss managed with claims made / postuma:',
            _TECHNICAL_AND_ADMINISTRATIVE_VALIDATION_CONTROLS_WILL_BE_REDONE_IN_THE_TYPE_OF_LOSS_SECTION: ' technical and administrative validation controls will be redone in the type of loss section',
            _CACHE_ERROR: 'Cache error',
            _ERROR_IN_INITIALIZATION_PROCEED_WITH_MANUAL_SELECTION: 'ERROR in initialization. Proceed with manual selection',
            _UPDATE_ERROR: 'Update error',
            _SEARCH_EXPERTS_ERROR: 'Search Experts Error',
            _SEARCH_REJECT_TYPES_ERROR: 'Search reject types error',
            _EXISTING_PES_ALERT: 'A PES/claim with same date occurred and policy is already present. Continue?',
            _UPDATE_RESERVE_SUCCESSFUL: 'Update reserve successful',
            _UPDATE_RESERVE_ERROR: 'Update reserve error',
            _HEIR_ALREADY_ADDED: 'Heir has already been added',
            _HEIR_DATE_INVALID: 'It is not possible to add fatal date for a subject chosen as an heir',
            _HEIR_IS_EQUAL_SUBJECT: 'The deceased subject cannot be added as a subject',
            _HEIR_INVALID: 'It is not possible to select a person with a fatal date as an heir',
            _HEIR_INVALID_FATAL_DATE: 'Heir removed due to death date, check heirs again',
            _ON_CONFIRMATION: 'Upon confirmation, the regularity of the policy will be checked based on the coverage selected',
            _YOU_CAN_INSERT_1_TO_2_ATTACHMENT: 'You can insert 1 to 2 attachments.',
            _ALLOWED_EXTENSION: 'Allowed extensions: txt, pdf, jpg.',
            _THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB: 'The size of an attachment cannot exceed 1 MB.',
            _COMPILING_LETTER_CONTENT: 'Compiling letter content',
            _OPENED_POST_LITIGATION: 'Opened post litigation',
            _PRESENCE_REQUEST_DAMAGE: 'Presence request damage',
            _WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT: 'Warning, the reserve has been reduced by the paid amount',
            _OF_WHICH_ATTORNEYS_FEES: 'Of which attorneys fees',
            _NO_BANK_ACCOUNT_FOR_THE_SUBJECT: 'No bank account for the subject',
            _NO_IBAN_PRESENT_OF_THE_PAYEE: 'No iban present of the payee',
            _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
            _INSERT_DATE: 'Insert date',
            _CLAIM_EXCESS_POLICY_VALUES: 'Claim excess policy values',
            _CLAIM_EXCESS_RESIDUE: 'Claim excess residue',
            _CLAIM_EXCESS_ERODED_AMOUNT: 'Claim excess eroded amount',
            _EXCESS_AGGREGATE_POLICY_VALUES: 'Excess aggregate policy values',
            _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue',
            _EXCESS_AGGREGATE_ERODED_AMOUNT: 'Excess aggregate eroded amount',
            _RECEIPT_ACCEPTED: 'Receipt accepted',
            _RECEIPT_REJECTED: 'Receipt rejected',
            _REASON_REJECTED: 'Reason rejected',
            _ONE_PAYEE_IS_REQUIRED: 'One payee is required',
            _ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED: 'One payee and one beneficiary are required',
            _ONE_PRIMARY_BENEFICIARIES_REQUIRED: 'One primary beneficiary required',
            _ONE_PRIMARY_PAYEE_REQUIRED: 'One primary payee required',
            _TAX_CODE__VAT_NUMBER_MISSING: 'Tax code/Vat number missing',
            _PAYEE_MUST_BE_ADULT: 'Payee must be an adult',
            _PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION: 'Payee not regstered, save subject with subject managment function.',
            _BEFORE_DELETE_ADD_NEW_BENEFICIARY: 'Before delete add new beneficiary',
            _BEFORE_DELETE_ADD_NEW_PAYEE: 'Before delete add new payee',
            _THE_CANCELLATION_DATE_IS_NOT_VALID: 'The cancellation date is not valid',
            _SELECT_REASON_CANCELLATION: 'Select cancellation reason',
            _METHOD_NOT_IMPLEMENTED: 'Method not implemented',
            _THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE: 'There is a payment withou associated documents, continue?',
            _SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED: 'Subject with incomplete tax data, unable to proceed',
            _OPERATION_NOT_AVAILABLE: 'Operation not available',
            _FEE_DATA_MISSING: 'Fee data missing',
            _MANDATORY_DATA_MISSING: 'Mandatory data missing',
            _SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS: 'Select the definition',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS: 'You have to select at least one type of loss',
            _THE_AMOUNT_FOR_THE_REASON_SELECTED: 'The amount for the reason selected',
            _INCOMPLETE_ADDRESS: 'Incomplete address',
            _INCOMPLETE_DATA: 'Incomplete data',
            _UIC_NOT_VALID__MAX_3_CHARACTER: 'Uic not valid, max 3 character',
            _FOREIGN_BANK_ACCOUNT_INVALID: 'Foreign bank account not valid',
            _IBAN_INVALID: 'Iban not valid',
            _INSERT_A_VALID_ITALIAN_IBAN: 'Insert a valid italian iban',
            _SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE: 'Special characters are not allowed in iban code',
            _RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE: 'Reserve amount not capacious, update reserve before',
            _IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossible to execute required operation,claim receipts to authorize',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT: 'Attention,the payment exceeds the residual amount of the limit',
            _SELECT_REASON_REVERSAL: 'Select reason reversal',
            _ENTER_NOTES: 'Enter notes',
            _THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0: 'The total amount cannot be greater than or equal to 0 ',
            _THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE: 'The credit note cannot be higher than the original invoice',
            _INVOICE_TYPE_REQUIRED: 'Invoice type required',
            _FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED: 'Fee number and fee date are required',
            _THE_REVERSAL_DATE_IS_NOT_VALID: 'The reversal date is not valid',
            _ERROR_CODE: 'Error code',
            _ERROR: 'Error',
            _SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO: 'Specify event/assignmentNumber/date from-to',
            _SPECIFY_ONLY_CLAIM_NUMBER: 'Specify only claim number',
            _SPECIFY_CRITERIA: 'Specify a criteria',
            _SPECIFY_EVENT: 'Specify event',
            _SPEFCIFY_CLAIM_NUMBER: 'Specify claim number',
            _ALERT_NEW_DOC: 'Alert new doc',
            _EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH: 'Existing Requests for damages to match',
            _NO_REQUEST_DAMAGES: 'No hay daños',
            _REQUEST_FOR_DAMAGES_RECEIPT_DATE: 'Request for damages receipt date',
            _GENERIC_ERROR_ON_SAVE_QUESTIONNARIE: 'Error genérico al guardar el cuestionario.',
            _QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL: 'El cuestionario se actualizó correctamente',
            _QUESTIONNAIRE_MODIFICATION_CANCELLED: 'Modificación del cuestionario cancelada',
            _ENTER_A_VALID_IBAN: 'Enter a valid Iban',
            _INSERT_A_VALID_SPANISH_IBAN: 'Enter a valid Spanish Iban',
            _AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE: 'An error occurred while adding the note',
            _AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION: 'An error occurred while deleting the expert association',
            _AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST: 'An error occurred while processing the request',
            _ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION: 'Are you sure you want to delete the trustee association?',
            _CHARACTER_LIMIT_EXCEEDED: 'Charcater limit exceeded',
            _EXPERT_ASSOCIATION_DELETED_CORRECTLY: ' Expert association deleted correctly',
            _EXPERT_LINK_ADDED_WITH_SUCCESS : 'Expert link added with success',
            _FISCAL_CODE__VAT_UNCORRECT: 'Fiscal Code/Vat Uncorrect',
            _FISCAL_CODE_UNCORRECT: 'Fiscal code uncorrect',
            _IMPUTE_THE_NAME_OR_THE_FISCAL_CODE: 'Impute the name or the fiscal name',
            _IN_ERROR: 'In error',
            _INCOMPLETE_COMPILATION: 'Incomplete compilation',
            _NO_NOTES_FOR_THIS_COMPENSATION: ' No notes for this compensation',
            _OPERATION_PROPERLY_TERMINATED: ' Operation propely terminated',
            _SELECT_A_CRITERIA: 'Select a criteria',
            _SELECT_A_USER_BEFORE_CONFIRMING: 'Select a user before confirming',
            _SELECT_AT_LEAST_ONE_REASON: 'Select at least one reason',
            _SPECIFY_THE_TYPE_ASSET: 'Specify the type asset',
            _VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT: 'Value at least one field between: name, surname, business name, contractor fiscal code, VAT code, code independent expert',
            _VAT_UNCORRECT: 'Vat code uncorrect',
            _FILE_IS_EMPTY: 'File is empty',
            _MAXIMUM_FILE_SIZE_EXCEEDED: 'Maximum file size exceeded',
            _NOT_GENERATED_FOR_CHOICE:'Not generated for choice',
            _GENERIC_ERROR_SAVE_ACTOR: 'CA_ES_Error encountered while saving new actor',
            _SAVE_NEW_ACTOR: 'CA_ES_Save new actor',
            _SELECT_THE_DOCUMENT_TO_ATTACH: 'Select the documents to attach to the payment',
            _KO_UPLOAD_DOC: 'An error occurred while uploading the document',
            _CANCELLATION_DIALOGUE: 'CA_ES_Are you sure you want to cancel this dialogue?',

        },
        _TITLE_: {
            _QUESTIONNAIRE_TITLE: 'Qüestionari',
            _SELECT_POLICY: 'Seleccioneu pòlissa',
            _OCCURRENCE_DATA: 'Dades de l\'incident',
            _DATA_COLLECTION: 'Recollida de dades',
            _SUMMARY: 'Resum',
            _DIALOGUE_HANDLER_LIST: 'Dialogue List',
            _DIALOGUE_HANDLER_NEW: 'CA_ES_New Demand',
            _DIALOGUE_HANDLER_SUMMARY: 'Dialogue Summary',
            _ADDRESS_MANAGEMENT: 'CA_ES_Address management'
        },
        _DATA_FORWARDING_CDL: 'Transmissió de dades a CHC',
        _INTERMEDIARY: 'Intermediari',
        _MASTER_POLICY_NUMBER: 'Número de pòlissa base',
        _APPLICATION_NUMBER: 'Número de sol·licitud',
        _COMPLAINT_STATE: 'Estat de la denúncia',
        _PLATE__CHASSIS: 'Xapa / Bastidor',
        _ACCOUNTING_COVERAGE: 'Cobertura comptable',
        _LEGAL_GUARDIAN_ROLE: 'Paper de tutor legal',
        _CAR_BODY_DETAIL: 'Informació de la carrosseria',
        _START_DATE_OF_COLLABORATION: 'Data d\'inici de la col·laboració',
        _END_DATE_COLLABORATION: 'Data de finalització de la col·laboració',
        _HOUSE_NUMBER: 'Número de casa',
        _MUNICIPALITY: 'Municipi',
        _NEW_BODYWORK: 'Carrosseria nova',
        _SUBJECT_TYPE: 'Tipus de part',
        _CODE: 'Codi',
        _BUSINESS_NAME: 'Raó social',
        _FISCAL_CODE: 'Codi fiscal',
        _VAT_CODE: 'Codi d\'IVA',
        _TYPE_OF_BODYWORK: 'Tipus de carrosseria',
        _INJURY_PHISICAL: 'Lesió física',
        _CAUSE_OF_INJURY: 'Causa de la lesió',
        _UNCODABLE: 'No es pot codificar',
        _MULTIPLE: 'Múltiple',
        _NATURE_OF_INJURY: 'Naturalesa de la lesió',
        _INJURY_LOCATION: 'Localització de la lesió',
        _FURTHER_INJURY_INDICATOR: 'Indicador de lesió addicional',
        _PROFESSIONAL_QUALIFICATION: 'Qualificació professional',
        _PERCENTAGE_OF_PERMANENT_DISABILITY: 'Percentatge d\'incapacitat permanent',
        _TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE: 'Dies d\'incapacitat temporal sense seqüeles',
        _FATALITY_DATE: 'Data de la defunció',
        _FIRST_AID_COUNTRY: 'País de primers auxilis',
        _FIRST_AID_PLACE: 'Lloc de primers auxilis',
        _HEALTHCARE_PROVIDERS: 'Proveïdors d\'assistència sanitària',
        _BIOLOGICAL_DAMAGE: 'Dany biològic',
        _SUBROGATION_SOCIAL_ASS_EMPLOYER: 'Subrogació assistència social/Empresari',
        _DOCTOR: 'Metge',
        _SEARCH: 'Cerqueu',
        _SKULL: 'CRANI',
        _SPINE_CERVICAL_COLUMN: 'COLUMNA VERTEBRAL-CERVICAL',
        _LEFT_SHOULDER: 'ESPATLLA ESQUERRA',
        _RIGHT_SHOULDER: 'ESPATLLA DRETA',
        _SPINE_THORACIC_COLUMN: 'COLUMNA VERTEBRAL-TORÀCICA',
        _SPINE_LUMBAR_COLUMN: 'COLUMNA VERTEBRAL-LUMBAR',
        _SPINE_SACRUS_AND_COCCYX: 'COLUMNA VERTEBRAL-SACRO I CÓCCIX',
        _LEFT_ANKLE: 'TURMELL ESQUERRE',
        _RIGHT_ANKLE: 'TURMELL DRET',
        _RIGHT_EAR: 'ORELLA DRETA',
        _RIGHT_EYE: 'ULL DRET',
        _LEFT_EYE: 'ULL ESQUERRE',
        _LEFT_EAR: 'ORELLA ESQUERRA',
        _FACE: 'CARA',
        _NECK: 'COLL',
        _RIGHT_CHEST_WALL: 'PARED TORÀCICA DRETA',
        _LEFT_CHEST_WALL: 'PARED TORÀCICA ESQUERRA',
        _RIGHT_ARM: 'BRAÇ DRET',
        _THORACIC_ORGANS: 'ÒRGANS TORÀCICS',
        _LEFT_ARM: 'BRAÇ ESQUERRE',
        _RIGHT_ELBOW: 'COLZE DRET',
        _ABDOMINAL_ORGANS: 'ÒRGANS ABDOMINALS',
        _LEFT_ELBOW: 'COLZE ESQUERRE',
        _RIGHT_FOREARM: 'AVANTBRAÇ DRET',
        _ABDOMINAL_WALL: 'PARED ABDOMINAL',
        _LEFT_FOREARM: 'AVANTBRAÇ ESQUERRE',
        _PELVIS___RIGHT_HIP: 'PELVIS - MALUC DRET',
        _UROGENITAL_ORGANS: 'ÒRGANS UROGENITALS',
        _PELVIS___LEFT_HIP: 'PELVIS - MALUC ESQUERRE',
        _RIGHT_WRIST: 'CANELL DRET',
        _LEFT_WRIST: 'CANELL ESQUERRE',
        _RIGHT_HAND: 'MÀ DRETA',
        _RIGHT_THIGH: 'CUIXA DRETA',
        _LEFT_THIGH: 'CUIXA ESQUERRA',
        _LEFT_HAND: 'MÀ ESQUERRA',
        _RIGHT_KNEE: 'GENOLL DRET',
        _LEFT_KNEE: 'GENOLL ESQUERRE',
        _RIGHT_LEG: 'CAMA DRETA',
        _LEFT_LEG: 'CAMA ESQUERRA',
        _RIGHT_FOOT: 'PEU DRET',
        _LEFT_FOOT: 'PEU ESQUERRE',
        _REQUIRED: 'Requerit',
        _EXTERNAL_CLAIM_NUMBER: 'Número de sinistre extern',
        _POTENTIAL_CLAIM_NUMBER: 'Número de sinistre potencial',
        _ALIGN_POLICY: 'Alineeu la pòlissa',
        _ACTIONS: 'Accions',
        _HEIR: 'Heir',
        _HEIR_ADD: 'Add heir',
        _HEIRS: 'Heirs',
        _DATE_ENTRY_FROM: 'Date entry from',
        _ELABORATION_DATE_FROM: 'Elaboration date from',
        _EXPIRY_DATE_FROM: 'Expiry date from',
        _CLAIM_DATE_FROM: 'Claim date from',
        _FUNCTION: 'Function',
        _DAMAGE_MATCH: 'Damage Match',
        _TAX_ID_CODE: 'Tax Id Code',
        _COMPLAINT: 'Complaint',
        _NOT_ASSOCIATED: 'Not Associated',
        _CTP_COMPANY: 'Ctp Company',
        _US_CAR_LICENSE_PLATE: 'License Plate Us',
        _CTP_CAR_LICENSE_PLATE: 'License Plate Ctp',
        _ACTIVE_TASK: 'Active Task',
        _ERROR_MESSAGE: 'Error Message',
        _SIMILAR_FLAG: 'Similar Flag',
        _FILTER: 'Filter',
        _NOT_ASSOCIATED_DIALOGUES: 'Not Associated Dialogues',
        _DIALOGUE_DATE_START: 'Dialogue Date Start',
        _END_DATE: 'End Date',
        _SX_DATE: 'Sinister Date',
        _DIALOGUE: 'Dialogue',
        _EXTERNAL_KEY_DAMAGED: 'External Key Damaged',
        _INTERNAL_KEY_DEF: 'Internal Key definitive',
        _INTERNAL_KEY_TEMP: 'Internal Key temporary',
        _INTERNAL_KEY_DAMAGED_DEF: 'Internal Key Damaged definitive',
        _INTERNAL_KEY_DAMAGED_TEMP: 'Internal Key Damaged temporary',
        _INTERNAL_KEY_DAMAGED: 'Internal Key Damaged',
        _US_TYPE_PLATE: 'Type Plate Us',
        _CTP_TYPE_PLATE: 'Type Plate Ctp',
        _IDENTIFIER_UNIQUE_CODE: 'Identifier Unique Code',
        _PARAMETERIZED_DIALOGUE_DATA: 'Parameterized Dialogue Data',
        _REGULATORY_EXPIRY_DATE: 'Regulatory Expiry Date',
        _LAST_UPDATE_DATE: 'Last User Date',
        _USER_UPDATE: 'User Update',
        _ACTIVITY_TYPE: ' Activity Type',
        _PARAMETERS_UPPERCASE: 'PARAMETERS',
        _REQUEST_NOTE: 'Request Note',
        _RESPONSE_NOTE: 'Response Note',
        _DOCUMENTS_UPPERCASE: 'DOCUMENTS',
        _DISCARDED_UPPERCASE: 'DISCARDS',
        _CAUSAL: 'Causal',
        _EXPIRATION: 'Expiration',
        _SENDED: 'Sended',
        _START_STATUS: 'Start Status',
        _DOCUMENT_TYPE: 'Document Type',
        _DOCUMENT_TYPE_FLUX: 'Document Type (Flux)',
        _CODE_ERROR: 'Code Error',
        _INPUT: 'Input',
        _OUTPUT: 'Output',
        _RESPONSE_NOTES: 'Response Notes',
        _DOCUMENTS_REQUIRED: 'Documents required for managing the selected answer',
        _SELECTED_DOCUMENTS: 'Select the documents to associate with the response by entering the flow document type for each',
        _ACTOR_INVOLVED: 'Actor Involved',
        _REASON: 'Reason',
        _DOCUMENT_TYPE_FLOW: 'Document Type Flow',
        _MANDATORY: 'Mandatory',
        _MATTER: 'Matter',
        _SUB_CATEGORY: 'Sub-Category',
        _LST_MSG_CODE: 'Last Message Code',
        _LST_MES_REQ_DATA: 'Request Date (LST. MS.)',
        _LST_MES_REG_EXP : 'Expiry Date (LST. MS.)',
        _START_DATE: 'Start Date',
        _INTERNAL_KEY: 'Internal Key',
        _LST_RESP_MES_CODE : 'Response Code (LST. MS.)',
        _LST_MSG_DATE: 'Last Message Date',
        _LST_MSG_INT_EXP: 'Internal Expiry Date (LST. MS.)',
        _EXT_KEY_IDEF_DAM: 'External Key Identify Damage Party',
        _ONGOING: 'ONGOING',
        _EVADE: 'EVADE',
        _INSURANCE_CODE_US: 'Insurance Code Us',
        _ACTOR_NOMINATIVE: 'Actor Nominative',
        _DETAILS_CLAIMS_DATA: 'Claims Data Detail',
        _CONSOLIDATE: 'Consolidate',
        _BALANCE: 'Balance',
        _VALIDATION_AND_CONSOLIDATION: 'Validation&Upload',
        _PAYMENT_MANAGE: 'Payment Manage',
        _FORFAIT_MANAGE: 'Forfait Manage',
        _YEAR: 'Year',
        _MONTH: 'Month',
        _JANUARY: 'January',
        _FEBRUARY: 'February',
        _MARCH: 'March',
        _APRIL: 'April',
        _MAY: 'May',
        _JUNE: 'June',
        _JULY: 'July',
        _AUGUST: 'August',
        _SEPTEMBER: 'September',
        _OCTOBER: 'October',
        _NOVEMBER: 'November',
        _DECEMBER: 'December',
        _INCAPACITATED: 'Incapacitated',
        _TYPES_OF_LOSS: 'Types of loss',
        _RISK_DATA: 'Risk data',
        _COVERTURE_DATA: 'Coverture data',
        _RULES_FOR_UPLOADING_DOCUMENTS: 'Rules for uploading documents:',
        _OCCURRENCE_HOUR: 'Occurence hour',
        _DATE_INTERMEDIARY: 'Date intermediary',
        _TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED: 'Type of authorities possibly involved',
        _CATASTROPHIC_EVENT_DESCRIPTION: 'Catastrophic event description',
        _OPENING_MODE: 'Claim opening mode',
        _PARTY: 'Party',
        _ASSET_INSURED: 'Asset insurance',
        _NEW_DAMAGED: 'New damaged',
        _TYPE_OF_LOSS: 'Daño causal',
        _NOMINATIVE: 'Nominative',
        _NO_RESULT: 'No results.',
        _NEW_ASSET: 'New asset',
        _ASSET_TYPE: 'Asset type',
        _TYPE: 'Type',
        _FOUND: 'Found',
        _COVERAGES_DETAIL: 'Coverages detail',
        _ADD_A_DAMAGED: 'Add a dameged',
        _PHYSICAL_PERSON: 'Physical person',
        _POLICY_DATA: 'Policy data',
        _ADMINISTRATIVE_VALIDITY: 'Administrative Validity',
        _LIST_OF_RELATED_CLAIMS: 'List of related claims',
        _CF__PIVA: 'CF/PIVA',
        _REFERENCE: 'Reference',
        _DOL: 'DOL',
        _DON: 'DON',
        _MAIN: 'Main',
        _ORIGINAL_EFFECT_DATE: 'Original Effect date',
        _NO_POLICY_TO_SHOW: 'No policy to show',
        _ASSIGNMENT_DATE: 'Assignment Date',
        _STATUS: 'Estat',
        _FILTERS: 'Filters',
        _CLAIMS_HANDLER: ' Claims Handler',
        _COMPANY: 'Companyia',
        _TECHNICAL_STATE: 'Technical State',
        _RESERVED: 'Reserved',
        _INVOICE_DATE: 'Invoice Date',
        _AMOUNT: 'Amount',
        _REJECT_TYPE: 'Reject Type',
        _UPLOAD_DETAILS: 'Upload Details',
        _NUMBER: 'Number',
        _ACTOR: 'Actor',
        _UPLOAD: 'Upload',
        _EXPERT_INVOICE: 'Expert Invoice',
        _TAX_CODE__VAT_NUMBER: 'Tax Code/VAT Number',
        _MANUAL_NOTE: 'Manual note',
        _INSERT_A_NEW_MANUAL_NOTE: 'Insert a new manual note',
        _COMMENTS_FORM: 'Comments form',
        _THERE_ARE_NO_COMMENTS: 'There are no comments',
        _NO_PERMISSION: 'No permission',
        _CANCELLATION: 'Cancellation',
        _COMMENT: 'Comment',
        _TOTAL_ASSIGNMENT_EXPENSE_AMOUNT: 'Monto total de gastos de asignación',
        _TOTAL_RESERVE_FOR_LITIGATION: 'Reserva total para litigios',
        _FROM: 'Desde',
        _TO: 'Para',
        _QUICK_FILTERS: 'Quick filters',
        _ASCENDING_ORDER: 'Ascending order',
        _DESCENDING_ORDER: 'Descending order',
        _MOVEMENTS: 'Movimientos',
        _ACCESSES: 'Accesses',
        _ACTIVITY: 'Activity',
        _RESERVES: 'Reserves',
        _TITLES: 'Titles',
        _STATE: 'Estado',
        _ASSIGNEE_GROUP: 'Assigna un grup',
        _ASSIGNEE_USER: 'Assigna usuari',
        _RUN_DATE: 'Data d\'execució',
        _PRIORITY: 'Prioritat',
        _SUSPENDED: 'Suspended',
        _COMPLETION_DATE: 'Completion date',
        _ESCAPE_METHODS: 'Escape methods',
        _RUN_ACTIVITY: 'Run Activity',
        _TAKE_CHARGE_ACTIVITY: 'Take charge activity',
        _REASSESS_TASK: 'Reassess task',
        _NO_SUGGESTIONS: 'No suggestions',
        _CRITERIA: 'Criteria',
        _ASSIGNMENT: 'Assignment',
        _EVENTS: 'Events',
        _R_PER: 'Return assignment (R-PER)',
        _A_COM: 'Communication (A-COM)',
        _S_RIF: 'Refuse assignment (S-RIF)',
        _R_FAT: 'Invoice  (R-FAT)',
        _TRANSMISSION_LIST: 'Trasmission list',
        _FAILED_TRANSMISSION_LIST: 'Failed trasmission list',
        _STARTER_EVENT: 'Evento starter',
        _REQUEST: 'Request',
        _ID: 'Id',
        _WS: 'WS',
        _XML: 'Xml',
        _RESPONSE: 'Response',
        _DETAIL: 'Detail',
        _VIEW_DETAIL_BLOW: 'View detail blow',
        _INSERT_DATE: 'Insert date',
        _ELABORATION_DATE: 'Elaboration date',
        _INSUFFICIENT_PERMISSIONS: 'Insufficient Permissions',
        _CLAIM: 'Reclamación',
        _POTENTIAL_CLAIM_INQUIRY: 'Investigación de reclamación potencial',
        _VALIDATE: 'Validate',
        _INVALIDATE: 'Invalidate',
        _MODIFY: 'Modify',
        _SUBCATEGORY: 'Subcategory',
        _RECEPTION_CHANNEL: 'Reception channel',
        _FORMAT: 'Format',
        _SIZE: 'Size',
        _NUMBER_OF_PAGES: 'Number of pages',
        _RECEIIPT_DATE: 'Receipt date',
        _USER: 'User',
        _SUBTITLE: 'SubTitle',
        _DOC_KEY: 'Doc key',
        _POSTALIZATION_RESULT: 'Postalization result',
        _POSTALIZATION_DATE: 'Postalization date',
        _SHIPPING_TYPE: 'Shipping type',
        _VIEW_BY_CATEGORIES: 'View by categories',
        _UPLOAD_DATE: 'Upload date',
        _SUBCATEGORY_DEPRECATED: ' Subcategory deprecated',
        _CATEGORY_DEPRECATED: ' Category deprecated',
        _HISTORY: 'History',
        _OPERATIONS: 'Operations',
        _EDIT_DOCUMENT: 'Edit document data',
        _CHANGE_PARTY: 'Change party',
        _SELECT_CATEGORY: 'Select category',
        _SELECT_SUBCATEGORY: 'Select subcategory',
        _PEOPLE: 'People',
        _EXTERNAL_VISIBILITY: 'External visibility',
        _VEICHLE: 'Veichle',
        _VALIDATED: 'Validate',
        _NO_VALIDATE: 'No validate',
        _TECHNICAL_STATUS: 'Estado técnico',
        _RESERVE_AMOUNT: 'Cantidad de Reserva',
        _TECHNICAL_REACTIVATION_OPENING_DATE: 'Reactivación/fecha de apertura técnica',
        _ACCOUNTING_REACTIVATION_OPENING_DATE: 'Fecha de apertura de reactivación contable',
        _TECHNICAL_CLOSING_DATE: 'Fecha de cierre técnico',
        _NEW_QUESTIONNAIRE_DATE: 'New Questionnaire Date',
        _ROLE: 'Role',
        _CODE_CHAIN: 'Code chain',
        _EXTERNAL_KEY: 'External Key',
        _CODE_ACTION: 'Code action',
        _DATA_ENTRY: 'Data entry',
        _OUTCOME: 'Outcome',
        _INSERTED: 'Inserted',
        _JSON_N: 'Json N°',
        _LAST_ELABORATION: 'Last elaboration',
        _CANCELLATION_REASON: 'Cancellation Reason',
        _ASSOCIATED_ENTITY: 'Associated Entity',
        _EXECUTIONS: 'Executions',
        _JSON_OK_N: 'Json OK n.',
        _JSON_KO_N: 'Json KO n.',
        _RERUN: 'Rerun',
        _RESERVATION_DATE: 'Reservation date',
        _IN_PROGRESS: 'In progress',
        _CLOSED: 'Closed',
        _FORCED_CLOSURE: 'Forced closure',
        _NOT_STOPPED: 'Not stopped',
        _STOPPED_SUCCESSFULLY: 'Stopped successfully',
        _RERUN_RESERVED: 'Rerun reserved',
        _RERUN_FAILURE: 'Rerun failure',
        _RESULTS: 'Results',
        _TITLE: 'Title',
        _INSERTION: 'Insertion',
        _ELABORATION: 'Elaboration',
        _REQUEST_TYPE: 'Request type',
        _ELABORATION_REQUEST_DATE: 'Elaboration request date',
        _INPUT_PARAMETERS: 'Input parameters',
        _JSON: 'Json',
        _JSON_EXTERNAL_KEY: 'Json External key',
        _CREATION_FAILED: 'Creation failed',
        _REQUEST_CREATED: 'Request created',
        _RESERVATION: 'Reservation',
        _CREATION_ERROR: 'Creation error',
        _TOO_MANY_JSON_WERE_UPLOADED: 'Too many json were uploaded',
        _COMPANY_SETTLEMENT_AMOUNT: 'Company settlement amount',
        _PAYEE: 'Payee',
        _PAYMENT_TYPE: ' Payment type',
        _BENEFICIARIES: ' Beneficiaries',
        _ADD_BENEFICIARY: 'Add beneficiary',
        _PAYEES: 'Payees',
        _ADD_PAYEE: 'Add Payee',
        _CALCULATE: 'Calculate',
        _REASON_CANCELLATION: 'Cancellation Reason',
        _C__O: 'C/O',
        _NO_DOCUMENTS: 'No Documents',
        _FEE_DATA: 'Fee Data',
        _FEE_NUMBER: 'Fee Number',
        _FEE_DATE: 'Fee Date',
        _IVA_AMOUNT: 'Iva amount',
        _IRPF_AMOUNT: 'IRPF amount',
        _WITH_HOLDING_AMOUNT: 'With holding amount',
        _NET_AMOUNT: 'Net amount',
        _CASH_AMOUNT_1: 'Cash amount 1',
        _CASH_AMOUNT_2: 'Cash amount 2',
        _SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED: 'Settlement blocked: % same invoice already reimbursed to insured',
        _PAID_AMOUNT: 'Paid Amount ',
        _PERCENTAGE_OF_THE_TOTAL: '% of the total ',
        _ALREADY_INSERT: 'Already insert',
        _REMAINING_AMOUNT: ' Remaining amount',
        _EVALUATION: ' Evaluation',
        _PAYMENT_DATA: 'Payment data',
        _CALCULATION_OF_THE_INSURED_FEE: 'Calculation of the insured fee',
        _BENEFICIARIES_AND_PAYEES: 'Beneficiaries and payees',
        _METHODS_OF_PAYMENT: 'Methods of Payment',
        _LETTERS_MANAGEMENT: 'Letters managment',
        _RECEIPT_SUMMARY: 'Receipt summary',
        _SETTLEMENT_SUMMARY: 'Settlement summary',
        _PARTY_ROLE: 'Party role',
        _OPERATION_USER: 'Operation user',
        _QUITTANCE_DATE: 'Quittance date',
        _CURRENCY_DATE: 'Currency date',
        _PAYMENT_TRANSACTION_TYPE: 'Payment transaction type',
        _RECEIPT_TYPE: 'Receipt type',
        _MANAGMENT_PAYMENT: 'Managment payment',
        _SEPARATE_RECEIPT: 'Separate receipt',
        _CURRENCY: 'Currency',
        _DUE_DATE: 'Due date',
        _PROCEDURE_CODE: 'Procedure code',
        _AMOUNT_QUOTA: 'Amount quota',
        _OPEN_DETAIL: 'Open detail',
        _FORFAIT_TYPE: 'Forfait type',
        _AMOUNT_QUOTA_FORFAIT: 'Amount quota forfait',
        _FORFAIT: 'Forfait',
        _IMPORT: 'Import',
        _METHOD_OF_PAYMENT: 'Method of payment',
        _BANK_TRANSFER_DESCRIPTION: 'Bank Transfer Description',
        _INCOMPLETE_ADDRESS: 'Incomplete address',
        _NOT_GENERATED_FOR_MISSING_DATA: 'Not generated for missing data',
        _GENERATED: 'Generated',
        _RETURN_OF_RECEIPT: 'Return of receipt',
        _RECEIPT_STATUS: 'Receipt status',
        _RECEIPT_DATE: 'Receipt date',
        _REASON_CANCEL_RECEIPT: 'Reason cancel receipt',
        _RECEIPT_NOTES: 'Receipt notes',
        _RETURN: 'Return',
        _BENEFICIARY: 'Beneficiary',
        _SENDING_LETTERS: 'Sending letters',
        _MANAGEMENT_PAYMENT: 'Managment payment',
        _PAYABILITY: 'Payability',
        _WAITING: 'Waiting',
        _SUBSTATUS: 'Substatus',
        _PAYMENT_DEFINITION: 'Payment Definition',
        _RESERVED_AMOUNT: 'Reserved Amount',
        _PARTIAL: 'Partial',
        _CHC: 'CHC',
        _LOAD_DATE: 'Load date',
        _OFFER_DATE: 'Offer date',
        _IBAN: 'Iban',
        _IBAN_ON_SUBJECT: 'Iban on subject',
        _ACCOUNT_HOLDER: 'Account holder',
        _BANK_DESCRIPTION: 'Bank description',
        _BANK_ADDRESS: 'Bank address',
        _SWIFT: 'Swift',
        _FOREIGN_BANK_ACCOUNT: 'Foreign Bank Account',
        _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
        _BIC: 'BIC',
        _UIC: 'UIC',
        _BANK: 'Bank',
        _LEGAL_OPPONENT: 'Legal opponent ',
        _REGISTER_SUBJECT: 'Register subject',
        _OPEN_REGISTER: 'Open register',
        _SETTLEMENTS_LIST: 'Settlements list',
        _RECEIPTS_LIST: 'Receipts list',
        _COMPENSATIONS_LIST: 'Compensation list',
        _APPOINTMENTS_LIST: 'Appointments list',
        _TYPOLOGIES_SETTLEMENT_LIST: 'Typologies settlement list',
        _ACCESSORY_CHECKS: 'Accessory checks',
        _FORCING_APPLY: 'Forcing apply',
        _ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO: 'Administrative validity overidden on policy no',
        _EFFECTIVE_DATE_OF_COLLECTION: 'Effective date of collection',
        _EXPIRY_DATE_OF_COLLECTION: 'Expiry date of collection',
        _DATE_OF_COLLECTION: 'Date of collection',
        _CONFIRM_RESPONSIBILITY: 'Confirm responsibility',
        _PERCENTAGE_INSURED_LIABILITY: 'Percentage insured liability',
        _REASON_TO_FORCE_LIABILITY_PERCENTAGE: 'Reason to force liability percentage',
        _COUNTERPART_VEHICLE: 'Counterpart vehicle',
        _CHASSIS__PLATE_NUMBER: 'Chassis plate number',
        _LIABILITY_PERCENTAGE: 'Liability percentage',
        _HISTORICAL_EROSION: 'Historical erosion',
        _INPUT_AMOUNT: 'Input amount',
        _INSURED_SETTLEMENT_AMOUNT: 'Insured settlement amount',
        _CLAIM_EXCESS: 'Claim excess ',
        _DEDUCTIBLE: 'Deductible',
        _EXCESS_AGGREGATE: 'Excess aggregate',
        _SIR: 'Sir',
        _LIMIT: 'Limit',
        _FIRST_SIR: 'First sir',
        _SECOND_SIR: 'Second sir',
        _ADVANCE: 'Advance',
        _SIR_ADVANCED_BY_COMPANY_SOMETIMES: 'Sir advanced by company sometimes',
        _CAP_INDICATOR: 'Cap indicator',
        _CAP_AMOUNT: 'Cap amount',
        _EXCESS_AMOUNT: 'Excess amount ',
        _FIRST_SIR_POLICY_VALUES: 'First sir policy values ',
        _FIRST_SIR_RESIDUE: 'First sir residue ',
        _FIRST_SIR_ERODED_AMOUNT: 'First sir eroded amount ',
        _SIR_AMOUNT: 'Sir amount',
        _DEDUCTIBLE_POLICY_VALUES: 'Deductible policy values',
        _DEDUCTIBLE_RESIDUE: 'Deductible residue',
        _DEDUCTIBLE_ERODED_AMOUNTS: 'Deductible eroded amounts',
        _PERCENTAGE_AMOUNT: 'Percentage amount',
        _DEDUCTIBLE_AMOUNT_RESIDUE: 'Deductible amount residue',
        _CALCULATED_AMOUNT: 'Calculated amount',
        _MAXIMUM_AMOUNT: 'Maximum amount',
        _MINIMUM_AMOUNT: 'Minimum amount',
        _MAXIMUM_LEVELS_POLICY_VALUES: 'Maximum levels policy values',
        _MAXIMUM_LEVELS_RESIDUE: 'Maximum levels residue',
        _MAXIMUM_LEVELS_ERODED_AMOUNTS: 'Maximum levels eroded amounts',
        _RETURN_DATE: 'Return date ',
        _SETTLEMENT_REVERSAL: 'Settlement reversal ',
        _REVERSAL_DATE: 'Reversal date ',
        _REASON_REVERSAL: 'Reason reversal ',
        _PAYMENT_NUMBER: 'Payment number ',
        _PAYMENT_SAVING_SUMMARY: 'Payment saving summary',
        _ACTIVATED: 'Activated',
        _SETTLEMENT_NUMBER: 'Settlement number ',
        _ISSUED_CORRECTLY: 'Issued correctly ',
        _RISK: 'Risk ',
        _POLICY_ID: 'Policy ID ',
        _CLAIM_EXCESS_POLICY_VALUE: 'Claim excess policy value',
        _CLAIM_CAP_AMOUNT: 'Claim cap amount ',
        _CLAIM_EXCESS_AMOUNT: 'Claim excess amount ',
        _POLICY_ID_END_DATE: 'Policy id end date ',
        _POLICY_ID_START_DATE: 'Policy id start date',
        _CLAIM_CAP_INDICATOR: 'Claim cap indicator ',
        _CLAIM_EXCESS_ADVANCE: 'Claim excess advance ',
        _CLAIM_EXCESS_RESIDUE: 'Claim excess residue ',
        _CLAIM_EXCESS_LIQUIDATOR_ADVANCE: 'Claim excess liquidator advance ',
        _CLAIM_EXCESS_AMOUNT_ADVANCE: 'Claim excess amount advance ',
        _CLAIM_CAP_AMOUNT_RESIDUE: 'Claim cap amount residue ',
        _FIRST_SIR_ADVANCE: 'First sir advance ',
        _AGGREGATE_CAP_INDICATOR: 'Aggregate cap indicator ',
        _EXCESS_AGGREGATE_POLICY_VALUE: 'Excess aggregate policy value ',
        _AGGREGATE_CAP_AMOUNT: 'Aggregate cap amount ',
        _EXCESS_AGGREGATE_AMOUNT: 'Excess aggregate amount ',
        _EXCESS_AGGREGATE_ADVANCE: 'Excess aggregate advance ',
        _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue ',
        _EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE: 'Excess aggregate liquidator advance ',
        _AGGREGATE_CAP_AMOUNT_RESIDUE: 'Aggregate cap amount residue ',
        _EXCESS_AGGREGATE_AMOUNT_RESIDUE: 'Excess aggregate amount residue ',
        _SECOND_SIR_POLICY_VALUES: 'Second sir policy values ',
        _EXCESS_SECOND_SIR: 'Excess second sir ',
        _CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR: 'Claim liquidator advance second sir',
        _SECOND_SIR_RESIDUE: 'Second sir residue ',
        _CLAIM_DATA: 'Claim data ',
        _NOTIFY_DATE: 'Notify date ',
        _SEVERITY_CODE: 'Severity code ',
        _PHISICAL_INJURY_CODE: 'Phisical injury code ',
        _CASHED: 'Cashed',
        _COLLECTED: 'Collected',
        _FISCAL_TAX_REGIME: 'Fiscal tax regime',
        _GENERATE_PAYMENT: 'Generate payment',
        _INDEMNITY_COST: 'Indemnity cost',
        _LEGAL_CODE: 'Legal code',
        _NEW_INVOICE: 'New invoice',
        _PARTY_CODE: 'Party code',
        _PRESENCE_LAWYERS: 'Presence lawyers',
        _PROTOCOLLO_SAP: 'Sap protocl',
        _PROTOCOLLO_SDI: 'SDI Protocol',
        _RECEIPTS: 'Receipts',
        _RESISTANCE_COST: 'Resistance cost',
        _SETTLEMENT_COST: 'Settlement cost',
        _SETTLEMENTS: 'Settlements',
        _TOTAL_AMOUNT: 'Total amount',
        _PAYMENTS_FIND: 'Payments find',
        _OPENING_DUE_TO_WRIT_OF_SUMMONS: 'Opening due to writ of summons',
        _APPLY_PERCENTAGE_LIABILITY_FORCING: 'Apply percentage liability forcing',
        _DAMAGE_CARD: 'Damage card',
        _SEND_TO: 'Send to',
        _CURRENT_RESERVE: 'Current reserve',
        _NOTES: 'Notes',
        _COMPENSATIONS: 'Compensations',
        _MAGIC_STRING: 'Magic string',
        _INVOLVED_PARTIES__DAMAGE_CAUSE: 'Involved parties/Damage cause',
        _SETTLEMENT_COUNTERS: 'Settlement counters',
        _TYPE_OF_LOSS_COUNTERS: 'Type of loss counters',
        _TRUSTEE_DATA: 'Trustee data',
        _SUBJECT: 'Subject',
        _ROSTER_REGISTRATION_CODE: 'Roster registration code',
        _COURT_REGISTRATION_DATE: 'Court registration date',
        _TAX_OFFICE: 'Tax office',
        _ASSOCIATED_TRUSTEES: 'Associated trustees',
        _SUBJECT_IDENTITY: 'Subject identity',
        _TYPE_PROVIDER: 'Type provider',
        _OFFICE_HEADQUARTERS: 'Office Headquarters',
        _COUNTRY: 'Country',
        _SEARCH_FILTERS: 'Search filters',
        _VAT_NUMBER: 'Vat number',
        _EXPERT_TYPE_PROVIDER: 'Expert Type Provider',
        _DATE_OF_INSERTION: 'Fecha de inserción',
        _CANCELED: 'Canceled',
        _UPDATE_TYPE: 'Actualizar tipo',
        _DATE: 'Fecha',
        _TYPOLOGY: 'Typology',
        _CREATION_DATE: 'Creation date',
        _GROUP: 'Group',
        _BY_WORKLOAD: 'By Workload',
        _USERS_ASSIGNABLE_TASKS: 'Tareas asignables a los usuarios',
        _ORIGIN: 'Origin',
        _LAST_UPDATE: 'Last update',
        _CANCELED_POLICY: 'Canceled policy',
        _EMISSION_DATE: 'Emission date',
        _POLICY_REPLACED_NUMBER: 'Policy replaced number',
        _POLICY_REPLACEMENT_NUMBER: 'Policy replacement number',
        _SUSPENSION_DATE_FROM: 'Suspension date from',
        _SUSPENSION_DATE_TO: 'Suspension date to',
        _PREMIUM_DATE_FROM: 'Premium date from',
        _PREMIUM_DATE_TO: 'Premium date to',
        _OUT_OF_RANGE: 'Out of range',
        _DIARIA_DAYS: 'Diaria days',
        _ARREARS_DAYS: 'Arrears days',
        _PRODUCT_CODE: 'Product code',
        _PRODUCT_DESCRIPTION: 'Product description',
        _DIARIA: 'Diaria',
        _LOCATION: 'Location',
        _TERM_CODE: 'Term code',
        _DAYS: 'Days',
        _SELECTABLE: 'Selectable',
        _POSTAL_CODE: 'Postal code',
        _POLICY_CONDITION: 'Policy condition',
        _POLICY_CEILING: 'Policy ceiling',
        _MINIMUM_UNCOVERED_VALUE: 'Minimum uncovered value',
        _SEARCH_RESULTS: 'Search results',
        _UPDATE_DATE: 'Update date',
        _EXCESS: 'Excess',
        _BRANCH: 'Branch',
        _SURNAME__BUSINESS_NAME: 'Surname/Business name',
        _PERSON_TYPE: 'Person type',
        _LEGAL_PERSON: 'Legal person',
        _EDIT_SUBJECT: 'Edit subject',
        _UPDATE_RESERVE: 'Update reserve',
        _REOPENING_DATA: 'Datos de Reapertura',
        _REOPENING_REASON: 'Motivo de Reapertura',
        _SETTLEMENT_COSTS: 'Settlement costs',
        _VIEW_FOR_ACTORS: 'View for Actors',
        _NEW_MANUAL_DEADLINE: 'Nova data límit del manual',
        _QUOTA_AMOUNT: 'Importe de cuota',
        _AMOUNT_ACTIVE: 'Activo importe',
        _AMOUNT_RESERVE_QUOTA: 'Cuota de reserva activa',
        _OPENING_DATE: 'Fecha de apertura',
        _CLOSING_DATE: 'Fecha de cierre',
        _REFUSED_DATE: 'Fecha rechazada',
        _COMPENSATION_DIARY_N: 'Compensation Diary N.',
        _NEW_NOTE: 'New note',
        _EXTRA_INFO: 'Extra info',
        _NO_DATA: 'No data',
        _CONV_TYPE: 'Convention Type',
        _INVALID_PARAMETER_FORM: 'CA-ES_Invalid parameter form',
        _NO_PARTIES: 'CA_ES_No parties',
        _SELECT_ACTOR_ROLE: 'CA_ES_Select actor role',
        _NEW_ACTOR_LIST: 'CA_ES_Actor list',
        _ASSETS_LIST: 'CA_ES_Assets list',
        _DAMAGED_ASSET: 'CA_ES_Damaged asset',
        _ADD_OTHER_ASSET: 'CA_ES_Add new damage',
        _ACTOR_ROLE: 'CA_ES_Actor role',
        _SOLD: 'CA_ES_Sold',
        _RISK_FACTORS: 'CA_ES_Risk Factors',
        _THING: 'CA_ES_Thing',
        _VEHICLE_ASSET: 'CA_ES_Vehicle Asset',
        _EVENT_RISK: 'CA_ES_Event risk',
        _EVENT: 'CA_ES_Event',
        _NO_REASON_MEETS_THE_SEARCH_CRITERIA: 'CA_ES_No reason meets the search criteria mettila in uppecase',
        _RESIDENCE: 'CA_ES_Residenza',
        _DOMICILE: 'CA_ES_Domicilio',
        _ADD_NEW_ADDRESS: 'CA_ES_Aggiungi nuovo indirizzo',
        _DIALOGUE_DESCRIPTION: 'Dialogue Description',
        _INSURED_PLATE_NUMBER: 'Insured Plate Number',
        _COMPANY_CODE: 'Company Code',
        _INVOLVED_PARTY_IDENTIFIER: 'Involved Party Identifier',
        _INVOLVED_PARTY_KEY: 'Involved Party Key',
        _NUMBER_REQUEST: 'Número Petició',
        _RESPONSE_IN: 'Response (In)',
        _REQUEST_OUT: 'Request (Out)',
        _REQUEST_IN: 'Request (In)',
        _RESPONSE_OUT: 'Response (Out)',
        _RESPONSE_INPUT: 'Response (Input)',
        _REQUEST_INPUT: 'Request (Input)',
        _REQUEST_OUTPUT: 'Request (Output)',
        _RESPONSE_OUTPUT: 'Response (Output)',
        _SIMILAR_DIALOGUES: 'Similar Dialogues',
        _CANCEL_MESSAGE: 'Canceled Successfully!',
        _CF_PIVA: 'CF/PIVA',
        _LOSS_DATE: 'Data de pèrdua',
        _VERSION: 'CA_ES Version',
        _CODE_MESSAGE: 'Missatge de codi',
        _PAYMENT_DELETE_CHECK_QUESTION: "Esteu segur que voleu eliminar l'element següent de la taula?",
        _ERROR_DURING_SAVING: 'Error while saving!',
        _NOT_CORRECT_ENTRY_DATA: 'The data entered are incorrect!',
        _WRONG_DATES: 'The dates entered are not valid!',
        _MANDATORY_FUNCTION_CODE: 'Mandatory function code!',
        _INCORRECT_AMOUNT: "The amount entered is not valid!",
        _ALREADY_EXISTS_RECORD: "Hi ha un Forfait en el mateix període de temps indicat",
        _NEW_FORFAIT : 'Nou forfait'
    }
};


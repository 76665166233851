import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {GPCluster} from '../../group-policy-models/group-policy-cluster';
import {Commission, GpAssetUnitsClauses, GpUnitClause} from '../../group-policy-models/group-policy-issue-guarantees';
import {GPClause} from '../../group-policy-models/group-policy-issue-policy-data';
import {GroupPolicyResourceService} from '../../group-policy-resources/group-policy-resource.service';
import {GroupPolicyApiRestErrorService} from '../../group-policy-services/group-policy-api-rest-error.service';
import {EditableCLuster, GroupPolicyStateCluster} from '../group-policy-state';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {AnagApiParty, AnagEditPartyResolver, AnagFlowData, AnagResourceService} from '@rgi/anag';
import {ActiveRoute} from '@rgi/rx/router';
import {PushMessageHandlerService} from '@rgi/rx';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpClusterService {

  constructor(protected groupPolicyService: GroupPolicyResourceService,
              protected gpErrorService: GroupPolicyApiRestErrorService,
              protected anagService: AnagResourceService,
              protected anagResolver: AnagEditPartyResolver,
              protected pushMessageHandler: PushMessageHandlerService) {
  }

  createCluster$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset;
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.createCluster$(st.resId, st.codeAsset).pipe(
          map((_resp) => {
            st.editableCluster = new EditableCLuster();
            st.editableCluster.cluster = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset;
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterAssetUnitClauses$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((_resp) => {
            st.editableCluster.clauses = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  getClusterDetail$(state$: Observable<GroupPolicyStateCluster>, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      mergeMap((st) => {
        const tag = 'assetClusters' + st.codeAsset;
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return combineLatest(
          of(st),
          this.groupPolicyService.getClusterDetails$(st.resId, st.codeAsset, clusterCode).pipe(
            catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
          ),
          this.groupPolicyService.getClusterAssetUnitClauses$(st.resId, st.codeAsset, clusterCode).pipe(
            catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
          )
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map(([st, cluster, clauses]: [GroupPolicyStateCluster, GPCluster, GpAssetUnitsClauses[]]) => {
        if(cluster.units && clauses[0] && clauses[0].unitsClauses){
          clauses[0].unitsClauses.forEach(unitClause  => {
            cluster.units.forEach(unit => {
              if(unitClause.codeUnit === unit.unit.code && unitClause.unitClauses && unitClause.unitClauses.length > 0){
                unit.unit.clauses = unitClause.unitClauses.filter(clause => clause.selected);
              }
            });
          });
        }
        if(clauses[0] && clauses[0].assetClauses){
          clauses[0].assetClauses = clauses[0].assetClauses.filter(clause => clause.selected);
        }
        const index = st.openedClusters.findIndex(c => c.cluster.code === clusterCode);
        if (index !== -1) {
          st.openedClusters.splice(index, 1, { cluster, clauses });
        } else {
          st.openedClusters.push({ cluster, clauses });
        }
        return st;
      }),
    );
  }

  getClusterDetailFromContract$(state$: Observable<GroupPolicyStateCluster>, clusterCode: string,): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((state: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + state.codeAsset;
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterDetailsFromContract$(state.proposalNumber, state.codeAsset, clusterCode, state.idMovement).pipe(
          map((cluster) => {
            if (state.openedClusters.length === 4) {
              state.openedClusters.shift();
            }
            state.openedClusters.push({ cluster, clauses: [] });
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(state, tag))
        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterAssetUnitClausesFromContract$(st.proposalNumber, st.codeAsset, clusterCode, st.idMovement).pipe(
          map((_resp) => {
            let cluster = st.openedClusters.find(cluster => cluster.cluster.code === clusterCode);
            cluster.clauses = _resp;
            if(cluster.cluster.units && cluster.clauses[0] && cluster.clauses[0].unitsClauses){
              cluster.clauses[0].unitsClauses.forEach(unitClause  => {
                cluster.cluster.units.forEach(unit => {
                  if(unitClause.codeUnit === unit.unit.code){
                    unit.unit.clauses = unitClause.unitClauses;
                  }
                });
              });
            }

            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  editCluster$(state$: Observable<GroupPolicyStateCluster>, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterDetails$(st.resId, st.codeAsset, clusterCode).pipe(
          map((_resp) => {
            st.editableCluster = new EditableCLuster();
            st.editableCluster.cluster = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterAssetUnitClauses$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((_resp) => {
            st.editableCluster.clauses = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.getClusterCommissions$(of(st).pipe(
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }


  copyCluster$(state$: Observable<GroupPolicyStateCluster>, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.copyCluster$(st.resId, st.codeAsset, clusterCode).pipe(
          map((_resp) => {
            st.editableCluster = new EditableCLuster();
            st.editableCluster.cluster = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)));
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterAssetUnitClauses$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((_resp) => {
            st.editableCluster.clauses = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.getClusterCommissions$(of(st).pipe(
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  updateCluster$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.updateCluster$(st.resId, st.codeAsset, st.editableCluster.cluster).pipe(
          map(_resp => {
            st.editableCluster.cluster = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterAssetUnitClauses$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((_resp) => {
            st.editableCluster.clauses = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.getClusterCommissions$(of(st).pipe(
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  checkCluster$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        this.pushMessageHandler.clearTag('assetClusters' + st.codeAsset + 'edit');
        return this.groupPolicyService.checkCluster$(st.resId, st.codeAsset, st.editableCluster.cluster).pipe(
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, 'assetClusters' + st.codeAsset + 'edit'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  deleteCluster$(state$: Observable<GroupPolicyStateCluster>, codeCluster: string): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset;
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.deleteCluster$(st.resId, st.codeAsset, codeCluster).pipe(
          map((_resp) => {
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset;
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getClusterDetails$(st.resId, st.codeAsset, codeCluster).pipe(
          map((_resp) => {
            const index = st.clusters.findIndex(cluster => cluster.code === codeCluster);
            if (_resp == null) {
              st.clusters.splice(index, 1);
            } else {
              st.clusters[index].status = _resp.status;
            }
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  getClasses$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getAssetClasses$(st.codeAsset).pipe(
          map((_resp) => {
            st.classes = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)));
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  getExtensions$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getAssetExtensions$(st.resId, st.codeAsset,
          st.editableCluster.cluster.class.code, st.editableCluster.cluster.use.code).pipe(
          map((_resp) => {
            st.extensions = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)));
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  getUses$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.getAssetUses$(st.codeAsset, st.editableCluster.cluster.class.code).pipe(
          map((_resp) => {
            st.uses = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)));
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  getSectors$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        // tslint:disable-next-line:max-line-length
        return this.groupPolicyService.getAssetSectors$(st.codeAsset, st.editableCluster.cluster.class.code, st.editableCluster.cluster.use.code).pipe(
          map((_resp) => {
            st.sectors = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)));
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  updateAssetClauses$(state$: Observable<GroupPolicyStateCluster>, clauses: GPClause[]) {
    return state$.pipe(
      mergeMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        // tslint:disable-next-line:max-line-length
        return combineLatest(of(st), this.groupPolicyService.updateClusterAssetClauses$(st.resId, st.codeAsset, st.editableCluster.cluster.code, clauses).pipe(
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        ));
      }),
      concatMap(([st, _updateClusterClausesResponse]) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        return this.groupPolicyService.getClusterDetails$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((_resp) => {
            st.editableCluster.cluster = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  updateUnitClauses(state$: Observable<GroupPolicyStateCluster>, unitClauses: GpUnitClause) {
    return state$.pipe(
      mergeMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        // tslint:disable-next-line:max-line-length
        return combineLatest(of(st), this.groupPolicyService.updateClusterAssetUnitClauses$(st.resId, st.codeAsset, st.editableCluster.cluster.code,
          unitClauses.codeUnit, unitClauses.codeSection, unitClauses.unitClauses).pipe(
             catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
          ));
      }),
      concatMap(([st, _updateClusterClausesResponse]) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        return this.groupPolicyService.getClusterDetails$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((_resp) => {
            st.editableCluster.cluster = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  updateClusterCommissions$(state$: Observable<GroupPolicyStateCluster>, commission: Commission[]) {
    return state$.pipe(
      mergeMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return combineLatest(of(st), this.groupPolicyService.updateClusterCommissions$(st.resId, st.codeAsset,
          st.editableCluster.cluster.code, commission).pipe(
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        ));
      }),
      concatMap(([st, _updateClusterCommissionResponse]) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        return this.groupPolicyService.getClusterDetails$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((_resp) => {
            st.editableCluster.cluster = _resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  getClusterCommissions$(state$: Observable<GroupPolicyStateCluster>): Observable<GroupPolicyStateCluster> {
    return state$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return this.groupPolicyService.retrieveClusterCommissions$(st.resId, st.codeAsset, st.editableCluster.cluster.code).pipe(
          map((resp) => {
            st.editableCluster.commissions = resp;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag)));
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateCluster) => st)
    );
  }

  actionModifyOwner$(groupPolicyStateCluster$: Observable<GroupPolicyStateCluster>, idParty: string, activeRoute: ActiveRoute) {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    return groupPolicyStateCluster$.pipe(
      concatMap((st: GroupPolicyStateCluster) => {
        const tag = 'assetClusters' + st.codeAsset + 'edit';
        this.gpErrorService.cleanErrorsHandlerForTag(tag);
        return combineLatest(of(st), this.anagService.getSubjectDetail$(idParty, null, previousStepData.node));
      }),
      concatMap(([st, partyDetail]) => {
        const partyEditorData = {} as AnagFlowData;
        partyEditorData.idParentSession = activeRoute.id;
        partyEditorData.nodeCode = previousStepData.managementNode;
        return combineLatest(of(st), this.anagResolver.editParty(partyDetail.subject, partyEditorData));
      }),
      concatMap(([st, partyModified]: [GroupPolicyStateCluster, AnagApiParty]) => {
        st.editableCluster.cluster.owner.idPartyLock = partyModified.idLatestPhotos;
        st.editableCluster.cluster.owner.description = partyModified.nominative;
        st.editableCluster.cluster.owner.idParty  = partyModified.objectId;
        st.editableCluster.isPartyEdited = true;
        return combineLatest(of(st),
            this.groupPolicyService.updatePartyOnContract$(previousStepData.resId, partyModified));
        }
      ),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st, _update) => {
          return st;
        }
      )
    );
  }
}

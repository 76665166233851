import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { DefinitionPaymentTypes, FormFieldsDefinition, PaymentTypeDefinition, PostsalesOperationObject, Values } from '../../models/postsales-operations-response.model';
import { SalesNode } from '../../models/sales-node.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { ClaimReportService } from '../../services/claim-report.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';


/**
 * @author dmasone
 * @description Component used for manage policy trasnfer
 */
@Component({
    selector: 'lpc-policy-trasnfer',
    templateUrl: './policy-transfer.component.html',
    styleUrls: ['./policy-transfer.component.css']
  })
  export class PolicyTrasnferComponent extends AbsOperationComponent {
    protected operationDataKey = 'transfer-policy';

    public paymentTypes: PaymentTypeDefinition[] = [];
    public node: FormFieldsDefinition;
    public branch: FormFieldsDefinition;
    public descriptions: SalesNode[] = [];
    public branches: SalesNode[] = [];
    isPaymentEditable = true;
    constructor(
        @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
        protected cd: ChangeDetectorRef,
        protected translate: TranslationWrapperService,
        @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
        @Optional() protected questCacheService: QuestionnaireCacheService,
        protected modalService: NgbModal,
        protected notifierService: NotifierService,
        protected plcQuestService: PlcQuestService,
        protected authorizationService: AuthService,
        protected claimReportService: ClaimReportService,
        protected anag: AnagService
      ) {
        super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
          plcQuestService, authorizationService, anag);
    }

    get selNode(): string {
      const res = this.descriptions.find((desc: SalesNode) => {
                    return desc.displayValue === this.transferPolicyFormGroup.get('node').value;
                  });
      return res && res.description;
    }

    get selBranch(): string {
      const res =  this.branches.find((desc: SalesNode) => {
                      return desc.displayValue === this.transferPolicyFormGroup.get('newBranchOffice').value;
                    });
      return res && res.description;
    }

    get transferPolicyFormGroup(): UntypedFormGroup {
      return this.formGroup.get('transferPolicy') as UntypedFormGroup;
    }

    ngOnInit(): void {
        this.initializeSession();
        this.$subscriptions.push(
            this.createDraft().subscribe((response: PostsalesOperationObject) => {
                this.paymentTypes = ((response.definitions.paymentTypes as DefinitionPaymentTypes).definitionPaymentTypes).reverse();
                this.isPaymentEditable = (response.definitions.paymentTypes as DefinitionPaymentTypes).editable;
                const paymentData = response.data.operationData.data.payment;
                if (!!paymentData) {
                  this.formGroup.get('payment').patchValue({
                    idPaymentType: paymentData.idPaymentType.toString(),
                    cchecknumber: paymentData.cchecknumber,
                    ciban: paymentData.ciban,
                    cholder: paymentData.cholder,
                    creditCardNumber: paymentData.creditCardNumber,
                    creditCardExpiredDate: paymentData.creditCardExpiredDate}, {emitEvent: false});
                }

                // valorizzazione dei dati relativi al nodo
                this.handleNodeData(response);
            }),
        );
        this.formGroup.get('payment').valueChanges.subscribe(el => {
            this.errors = [];
        });
    }

    handleNodeData(response) {
      this.node = this.mapResponse(response, 'NODE');
      this.branch = this.mapResponse(response, 'BRANCH');
      this.descriptions = this.mapNodes(this.node);
      this.transferPolicyFormGroup.get('node').setValue(this.node.value);
      if (this.branch) {
        this.branches = this.mapNodes(this.branch);
        this.transferPolicyFormGroup.get('newBranchOffice').setValue(this.branch.value);
        this.branch.mandatory ?
        this.transferPolicyFormGroup.get('newBranchOffice').setValidators([Validators.required]) :
        this.transferPolicyFormGroup.get('newBranchOffice').clearValidators();
      } else {
        this.transferPolicyFormGroup.get('newBranchOffice').clearValidators();
      }
      this.transferPolicyFormGroup.get('newBranchOffice').updateValueAndValidity();
    }

    public selectChangeHandlerNode(event: any) {
      this.transferPolicyFormGroup.get('node').setValue(event);
      this.transferPolicyFormGroup.get('newBranchOffice').setValue(null);
      if (event) {
        this.updateDraft('transferPolicy', true).subscribe((response: PostsalesOperationObject) => {
          this.branch = this.mapResponse(response, 'BRANCH');
          if (this.branch) {
            this.branches = this.mapNodes(this.branch);
            this.branch.mandatory ?
            this.transferPolicyFormGroup.get('newBranchOffice').setValidators([Validators.required]) : this.transferPolicyFormGroup.get('newBranchOffice').clearValidators();
          } else {
            this.transferPolicyFormGroup.get('newBranchOffice').clearValidators();
          }
          this.transferPolicyFormGroup.get('newBranchOffice').updateValueAndValidity();
        });
      }
    }

    protected getTransformedOperationData(): any {
      return {
        newManagementNode: {id: this.formGroupValue.transferPolicy.node ? this.formGroupValue.transferPolicy.node : null},
        newBranchOffice: {
          id: this.formGroupValue.transferPolicy.newBranchOffice ? this.formGroupValue.transferPolicy.newBranchOffice : null},
        payment: this.formGroupValue.payment ? this.formGroupValue.payment : {}
      };
    }

    protected getFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
          dates: new UntypedFormControl(),
          transferPolicy: new UntypedFormGroup({
            node: new UntypedFormControl(),
            newBranchOffice: new UntypedFormControl(),
          }),
          payment: new UntypedFormControl()
        });
      }

    private mapResponse(response: PostsalesOperationObject, code: string): FormFieldsDefinition {
      const res = (response.definitions.variationFields as FormFieldsDefinition[])
                    .filter((obj: FormFieldsDefinition) => {
                        return obj.code === code;
                    });
      return res && res[0];
    }

    private mapNodes(nodes: FormFieldsDefinition): SalesNode[] {
      return nodes.values.map((agency: Values) => {
              return {
                displayValue: agency.id,
                description: agency.description
              } as SalesNode;
        });
    }

    protected updateDraft(step: string, reload?: boolean): Observable<any> {
      return super.updateDraft(step, reload).pipe(
        tap(resp => {
          const paymentData = resp.data.operationData.data.payment;
          if (!!paymentData) {
            this.formGroup.get('payment').patchValue({
              idPaymentType: paymentData.idPaymentType.toString(),
              cchecknumber: paymentData.cchecknumber,
              ciban: paymentData.ciban,
              cholder: paymentData.cholder,
              creditCardNumber: paymentData.creditCardNumber,
              creditCardExpiredDate: paymentData.creditCardExpiredDate}, {emitEvent: false});
          } else {
            this.formGroup.get('payment').patchValue({
              idPaymentType: null,
              cchecknumber: null,
              ciban: null,
              cholder: null,
              creditCardNumber: null,
              creditCardExpiredDate: null}, {emitEvent: false});
          }
        })
      );
    }

  }

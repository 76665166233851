import {ChangeDetectorRef, Component, Inject, Optional} from '@angular/core';
import {AbsOperationComponent} from "../abs-operation-component/abs-operation.component";
import {FormControl, FormGroup, UntypedFormGroup} from "@angular/forms";
import {PV_TOKEN} from "../../models/consts/lpc-consts";
import {PostsalesOperationsService} from "../../services/postsales-operations.service";
import {TranslationWrapperService} from "../../i18n/translation-wrapper.service";
import {QuestionnaireCacheService} from "@rgi/questionnaires-manager";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotifierService} from "@rgi/portal-ng-core";
import {PlcQuestService} from "../../services/plc-quest.service";
import {AuthService} from "../../services/auth.service";
import {AnagService} from "../../services/anag.service";
import {FactorDefinition, PostsalesOperationObject} from "../../models/postsales-operations-response.model";
import {UNDEDUCTED_PREMIUMS_ACQUISITION} from "./config/undeducted-premiums-acquisitions.config";
import {RequestFactor} from "../../models/factor.model";
import {PlcObjectUtils} from "../../utils/plc-object-utils";

@Component({
  selector: 'lpc-undeducted-premiums-acquisition',
  templateUrl: './undeducted-premiums-acquisition.component.html',
})
export class UndeductedPremiumsAcquisitionComponent extends AbsOperationComponent {

  readonly UNDEDUCTED_PREMIUMS_ACQUISITION_STEP = UNDEDUCTED_PREMIUMS_ACQUISITION;

  protected operationDataKey: string;
  protected listProductFactor: FactorDefinition[] = [];
  protected requestProductFactor: RequestFactor[] = [];

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new FormGroup({
      [UNDEDUCTED_PREMIUMS_ACQUISITION.DATE.formName]: new FormControl(),
      [UNDEDUCTED_PREMIUMS_ACQUISITION.POLICY_FACTORS.formName]: new FormGroup({}),
    });
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe((result) => {
        this.listProductFactor = PlcObjectUtils.asValidArray(result.definitions.productFactors as FactorDefinition[]);
        }
      )
    );
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    this.setFeErrors(step);
    super.onNext(step, publish, context, isConfirmAndAccept);
  }

  get hasProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestProductFactor = factors;
    this.$subscriptions.push(this.onReload(UNDEDUCTED_PREMIUMS_ACQUISITION.POLICY_FACTORS.formName).subscribe());
  }

  protected getTransformedOperationData(): any {
    return {
      listProductFactor: !!this.requestProductFactor.length ? this.requestProductFactor : [],
    };
  }
  
  public updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string): void {
    this.listProductFactor = PlcObjectUtils.asValidArray(result.definitions.productFactors as FactorDefinition[]);
    super.updateDraftHandleResponse(result, step, reload, opDataType);
  }

}

import {FlexDiscountParam} from '../pc-portfolio-models/discounts-models/flex-discount-param';

export class Instalment {
  public taxable: string;
  public gross: string;
  public accessories: string;
  public antiracket: string;
  public taxes: string;
  public net: string;
  public paymentFrequencyInterestNet: string;
  public addictionalInterests: string;
  public paymentFrequencyInterestAccessories: string;
  public paymentFrequencyInterest: string;
  public sSN: string;
  public taxRate: string;
  public ssnRate: string;
  public fractioningInterest: string;
  public flexDiscounts: FlexDiscountParam;
}

export class Subject {
    constructor(
        public objectId: string,
        public idLatestPhotos: string,
        public denomination: string,
        public surname: string,
        public name: string
    ) {
    }
    
}
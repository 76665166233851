import {NgModule} from '@angular/core';

import {PassproQuestionnaireModule} from './passpro/questionnaire/questionnaire.module';
import {CommonModule} from "@angular/common";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PassproQuestionnaireModule,
  ],
  exports: [PassproQuestionnaireModule]
})
export class NgPassproModule { }

import { LicTranslationsKeys } from './lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_GL_ES: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: 'Póliza número {{num}} emitida correctamente',
  lic_ProposalCorrectlyIssued: 'Proposta número {{num}} emitida correctamente',
  lic_LifeProposalCorrectlyIssued: 'Proposta de vida número {{num}} emitida correctamente',
  lic_LifePolicyCorrectlyIssued: 'Póliza de vida número {{num}} emitida correctamente',
  lic_DamagesPolicyCorrectlyIssued: 'Póliza de danos número {{num}} emitida correctamente',
  lic_AuthorizatioProposalForwarded: 'A solicitude de autorización para a proposta número {{num}} enviouse ao usuario adecuado',
  lic_ProposalCorrectlySaved: 'Proposta número {{num}} gardada correctamente',
  lic_PreventiveCorrectlySaved: 'Cotización número {{num}} gardada correctamente',
  lic_benMorteNoAssic: 'O suxeito seleccionado como beneficiario do falecemento xa está seleccionado como asegurado, seleccione outro suxeito',
  lic_benMorteNoRef: 'O suxeito seleccionado como beneficiario do falecemento xa está seleccionado como terceira persoa de contacto',
  lic_benefPresente: 'O suxeito seleccionado como beneficiario do falecemento xa está seleccionado, seleccione outro suxeito',
  lic_refTerzoNoAssic: 'O suxeito seleccionado como terceira persoa de contacto xa está seleccionado como asegurado, seleccione outro suxeito',
  lic_refTerzoNoContr: 'O asunto seleccionado como terceira persoa de contacto xa está seleccionado como titular da póliza, seleccione outro asunto',
  lic_refTerzoPresente: 'O suxeito seleccionado como terceira persoa de contacto xa está seleccionado como beneficiario en caso de falecemento',
  lic_DelegateEqualToPolicyHolder: 'Delegado igual ao tomador?',
  lic_LegalGuardianEqualToPolicyHolder: 'Titor legal igual ao tomador?',
  lic_ThirdPayerEqualToPolicyHolder: 'Terceiro pagador igual ao tomador?',
  lic_DelegateEqualToPolicyHolderWarning: 'Suxeito para o rol delegado igual ao tomador',
  lic_LegalGuardianEqualToPolicyHolderWarning: 'Suxeito para o posto de titor legal igual ao tomador',
  lic_ThirdPayerEqualToPolicyHolderWarning: 'Suxeito para o rol terceiro pagador igual ao tomador',
  lic_MasterPolicyHolder: 'Titular principal da póliza',
  lic_SubjectXalreadyselectedfortherole: 'O suxeito {{subject}} xa está seleccionado para o rol',
  lic_InsuredAmount: 'Importe asegurado',
  lic_InvestedPremium: 'Prima de investimento',
  lic_FirstInstalment: 'Primeiro prazo',
  lic_InsuredNotEqualHolder: 'O asegurado e o titular deben ser diferentes',
  lic_SaveEstimate: 'Gardar cotización',
  lic_LifeQuote: 'Cotización de vida',
  lic_PreventiveIssue: 'Emisión da cotización',
  lic_ProposalIssue: 'Proposta de emisión',
  lic_PolicyIssue: 'Emitir póliza',
  lic_appendixLabel: 'Anexo de beneficiarios',
  lic_summary_save_and_accept: 'gardar e aceptar',
  lic_summary_save_accept_pol_issue: 'gardar, aceptar e emitir póliza',
  lic_summary_save_accept_prop_issue: 'gardar, aceptar e emitir proposta',
  lic_effectivebeneficiaryholder: 'titular beneficiario efectivo',
  lic_LifePolicy: 'Póliza de vida',
  lic_DamagesPolicy: 'Póliza de danos',
  lic_view: 'Ver',
  lic_premium_detail: 'Detalle da prima',
  lic_guarantee_summary: 'Resumo de garantías',
  lic_commissions: 'Comisións',
  lic_Parties: 'Partes',
  lic_Party: 'Parte',
  lic_Employer: 'Empregador',
  lic_SpecificPIPdata: 'Datos PIP específicos',
  lic_prev_continue: 'Próximo',
  lic_Transferdata: 'Transferir datos',
  lic_Selectatleastonecontribution: 'Seleccione polo menos unha achega',
  lic_Globalpercentagemustbe100: 'A porcentaxe global debe ser 100 %',
  lic_Selectcontributionsmandatoryfields: 'Seleccione os campos obrigatorios das achegas',
  lic_Contributiondata: 'Datos da achega',
  lic_Globalamountmustbemajorthan0: 'O importe global debe ser maior que 0',
  lic_Everycontributionselectedmustbemajorthan0: 'Todas as contribucións seleccionadas deben ser maiores que 0',
  lic_error_mandatory_fields: 'Os campos marcados en vermello son obrigatorios ou conteñen valores incorrectos',
  lic_error_iban: 'O Iban non é correcto',
  lic_authorization_request: 'PETICIÓN DE AUTORIZACIÓN',
  lic_selected_profiles_: 'Perfís seleccionados',
  lic_Disinvestmentsfunds: 'Fondos de desinvestimento',
  lic_reg41_comunication: 'Nome ao que enviar comunicacións',
  lic_disinvestment_amount: 'Importe de desinvestimento',
  lic_occurrenceNumber: 'Incidencia',
  lic_TheoccurenceNumbermustbeavalue: 'O número de incidentes debe ser maior que {{min}}',
  lic_Disinvestmentrange: 'Insira un importe para desinvestir superior a {{max}} e inferior ou igual a {{min}}',
  lic_Incompleteinvestmentforthefunds: 'Investimento incompleto, complete o investimento para os fondos',
  lic_Incompleteinvestmentfortheprofiles: 'Investimento incompleto, complete o investimento para os perfís',
  lic_Settlementstoreinvest: 'Liquidación a reinvestir',
  lic_Policynumber: 'Número de póliza',
  lic_Type: 'Tipo',
  lic_Beneficiary: 'Beneficiario',
  lic_State: 'Estado',
  lic_Net: 'Neto',
  lic_Nettoreinvest: 'Neto para reinvestir',
  lic_Reinvestmentmustbelargerthanquotation: 'O reinvestimento deber ser superior á cotización.',
  lic_Mustselectatleastonesettlementtoreinvest: 'Debe seleccionar polo menos unha liquidación para reinvestir.',
  lic_Nettoreinvestmustbeequalorlessthannet: 'O neto a reinvestir debe ser igual ou menor ao neto.',
  lic_Nosettlementstoreinvestexist: 'Non existen asentamentos para reinvestir.',
  lic_Missingvalueonnettoreinvest: 'Falta o valor no neto a reinvestir.',
  lic_information: 'Información:',
  lic_Percentage: 'Porcentaxe',
  lic_Durationin: 'Duración en',
  lic_years: 'anos',
  lic_months: 'meses',
  lic_days: 'días',
  lic_Complementary: '(Complementario)',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: 'Facilite un método de pagamento ao beneficiario do cupón',
  lic_Netavailable: 'Neto dispoñible',
  lic_Proposal_Saved: 'A proposta gardouse co número',
  lic_add_employer: 'Engadir un empregado',
  lic_AvailableGuarantees: 'Garantías dispoñibles',
  lic_error: 'Erro',
  lic_error_msg: 'Erro ao emitir a póliza',
  lic_auth_error_msg: 'Erro ao procesar a solicitude',
  lic_common_risk: 'Risco común',
  lic_no_common_risk: 'Non hai propostas ou pólizas que entren no cálculo do capital en risco para o asegurado',
  lic_assignment_holder: 'Titular da asignación',
  lic_Sustainability: 'Sustentabilidade',
  lic_ecoSustainabilityAmount: 'Importe de ecosustentabilidade',
  lic_ecoSustainabilityPerc: 'Ecosustentabilidade %',
  lic_envSustainabilityAmount: 'Importe de sustentabilidade ambiental',
  lic_envSustainabilityPerc: 'Sustentabilidade ambiental %',
  lic_paiAmount: 'Importe PAI',
  lic_paiPerc: 'PAI %',
  lic_socialSustainabilityAmount: 'Importe de sustentabilidade social',
  lic_socialSustainabilityPerc: 'Sustentabilidade social %',
  lic_benefSevereDisability: 'Beneficiario con discapacidade grave',
  lic_SecondInsured: 'Segundo asegurado',
  lic_Secondinsuredmustbeaphysicalperson: 'O segundo asegurado ten que ser unha persoa física',
  lic_Selectasecondinsured: 'Seleccione un segundo asegurado',
  lic_Aderente: 'Adherente​',
  lic_NumeroProposta: 'Número de proposta',
  lic_DescrizioneProdotto: 'Descrición do produto',
  lic_DescrizioneRischio: 'Descrición do risco',
  lic_CapitaleAssicurato: 'Importe asegurado',
  lic_SELEZIONA: '-- SELECCIONAR --',
  lic_PrNetto: '% neto da prima',
  lic_ImpAcq: 'Imposto de compra',
  lic_profileInvestmentIncomplete: 'Investimento incompleta, perfís de investimento completos',
  lic_fundsInvestmentIncomplete: 'Investimento incompleto, fondos de investimento completos',
  lic_Clauses: 'Cláusulas',
  lic_quote_type: 'Tipo de cotización',
  lic_quote_type_anonymous: 'Anónimo',
  lic_Policyholder: 'Titular da póliza',
  lic_quote_type_registered: 'Rexistrado',
  lic_text_required: '*Texto necesario',
  lic_text_required_error: 'Precísase un texto de cláusula.',
  lic_close: 'pechar',
  lic_save: 'gardar',
  lic_NEXT: 'SEGUINTE',
  lic_policyData: 'Datos da póliza',
  lic_pipData: 'Datos de PIP',
  lic_parties: 'Partes',
  lic_assets: 'Activos',
  lic_quotation: 'Cotización',
  lic_summary: 'Resumo',
  lic_issue: 'Emisión',
  lic_investments: 'Investimentos',
  lic_invest_value_range: 'Pregámoslle que insira un valor entre {{min}} e {{max}}',
  lic_disinvestment_range: 'Pregámoslle que insira un valor de desinvestimento entre {{min}} e {{max}}',
  lic_mandatory_threshold_perc: 'Fondo: {{value}} - Pregámoslle que insira a porcentaxe como limiar',
  lic_mandatory_one_fund: 'Seleccione polo menos un fondo',
  lic_invest_factor_min_validation: '{{factor}} debe ser menor que {{amount}}',
  lic_invest_factor_max_validation: '{{factor}} debe ser maior que {{amount}}',
  lic_invest_factor_eq_validation: '{{factor}} debe ser igual a {{amount}}',
  lic_calculate_asset_volatility: 'Calcular Volatilidade de Activos',
  lic_calculate_sustainability_asset: 'Calcular Sustentabilidade de Activos',
  lic_occurance_number_range: 'O número de ocorrencia debe ser maior que {{min}}',
  lic_policy_holder: 'Titular da póliza',
  lic_guarantee: 'Garantía',
  lic_co_applicant_not_same_holder: 'O co-solicitante non debe coincidir co titular da póliza',
  lic_co_applicant_physical: 'O co-solicitante debe ser unha persoa física',
  lic_co_applicant_mandatory: 'O co-solicitante é obrigatorio',
  lic_co_applicant: 'Co-solicitante',
  lic_insured_must_be_physical: 'O asegurado debe ser unha persoa física',
  lic_mandatory_product: 'Seleccione un produto',
  lic_mandatory_insured: 'Seleccione un asegurado',
  lic_mandatory_holder: 'Seleccione un titular da póliza',
  lic_contribution_frequency: 'A frecuencia de contribución {{frequency}} debe ser a mesma',
  lic_mandatory_benef: 'Beneficiario {{cathegory}}: O beneficiario é obrigatorio',
  lic_mandatory_appendix_benef: 'Beneficiario {{cathegory}}: O beneficiario do apéndice é obrigatorio',
  lic_add_benef: 'Beneficiario {{cathegory}}: Agregar beneficiario do rexistro civil',
  lic_add_benef_perc: 'Beneficiario {{cathegory}} {{nominative}}: Agregar porcentaxe',
  lic_benef_perc_range: 'Beneficiario {{cathegory}} {{nominative}}: A porcentaxe debe estar entre 0.01 e 100',
  lic_benef_total_perc: 'Beneficiario {{cathegory}}: A porcentaxe total debe ser do 100 %',
  lic_guarantee_mandatory_data: 'Garantía {{unit}}: Complete todos os datos obrigatorios',
  lic_quotation_not_done: 'Cotización non realizada: seleccione "Cotizar" para actualizar a cotización',
  lic_quotation_modified_value_quote: 'Actualizáronse os datos: seleccione "Cotizar" para actualizar a cotización',
  lic_quotation_benef_custom_msg: 'Beneficiario {{cathegory}} - {{msg}}',
  lic_product: 'Produto',
  lic_managementNode: 'Nodo de xestión',
  lic_branch: 'Sucursal',
  lic_agreement: 'Acordo',
  lic_effect_date: 'Data de efecto',
  lic_exp_date: 'Data de vencemento',
  lic_payment_frequency: 'Frecuencia de pagamento',
  lic_means_of_payment: 'Medios de pagamento',
  lic_unit_type: 'Tipo de unidade',
  lic_risk_type: 'Tipo de risco',
  lic_substate: 'Subestado',
  lic_benefit: 'Beneficio',
  lic_decreasing_frequency: 'Frecuencia decrecente',
  lic_damages: 'Danos',
  lic_effective_holder: 'Titulares beneficiarios',
  lic_insured_role: 'Asegurado',
  lic_third_contact_person: 'Terceira persoa de contacto',
  lic_quotation_benef_owner_perc_validation: '{{roleDescr}} {{name}} do beneficiario {{benefName}} non respecta a porcentaxe mínima aceptada {{minPerc}}',
  lic_subscriber: 'Subscritor',
  lic_yes: 'si',
  lic_no: 'non',
  lic_new_insured: 'Novo asegurado',
  lic_simple_select: 'Seleccionar',
  lic_first_instalment: 'Primeiro pagamento',
  lic_next_instalment_premium: 'Prima do próximo pagamento',
  lic_payment_type: 'Tipo de pagamento',
  lic_first_instalment_payment_type: 'Tipo de pagamento do primeiro pagamento',
  lic_annual_premium: 'Prima anual',
  lic_quote_button: 'Cotizar',
  lic_quote_save: 'Cotizar e gardar',
  lic_purchasing_commission: 'Comisión de compras',
  lic_beneficiaries_label: 'Beneficiarios',
  lic_general_data_label: 'Datos xerais',
  lic_contractual_option_not_allowed: 'Opcións contractuais non permitidas',
  lic_investment_value_label: 'Valor de investimento',
  lic_investment_profile: 'Perfil de investimento',
  lic_investment_funds: 'Fondos de investimento',
  lic_calculate_finantial_parameters: 'Calcular parámetros financeiros',
  lic_confirm_button: 'Confirmar',
  lic_cancel_button: 'Cancelar',
  lic_save_button: 'Gardar',
  lic_continue_button: 'Continuar',
  lic_next_button: 'Seguinte',
  lic_required: 'obrigatorio',
  lic_node: 'Nodo',
  lic_company: 'Compañía',
  lic_product_type: 'Tipo de produto',
  lic_taxes_label: 'Impostos',
  lic_fees_label: 'Tarifas',
  lic_frequency_interests: 'Frecuencia de xuros',
  lic_frequency: 'Frecuencia',
  lic_accessories: 'Accesorios',
  lic_taxable: 'Gravable',
  lic_quotation_detail_modal: 'Detalle de cotización',
  lic_guarantees_overview_modal: 'Resumo de garantías',
  lic_gross_label: 'Bruto',
  lic_int_fract_label: 'Xur. Frac.',
  lic_next_instalment_label: 'Próximo pagamento',
  lic_pure_premium_label: 'Prima pura',
  lic_extra_premium_label: 'Prima extra',
  lic_loadings_label: 'Recargas',
  lic_total_label: 'Total',
  lic_beneficiary_perc_is_over: 'A suma das porcentaxes dos roles vinculados a {{benefCat}} {{benefRole}} supera o 100',
  lic_questionSTR_invalid: 'A resposta á pregunta: {{question}}, non é correcta',
  lic_purchase_commissions: 'Comisións de compra',
  lic_sales_commissions: 'Comisións de venda',
  lic_IBAN_formally_incorrect: 'O IBAN non é válido formalmente',
  lic_holder_physical_person_req: 'O titular da póliza debe ser unha persoa física',
  lic_holder_legal_person_req: 'O titular da póliza debe ser unha persoa xurídica',
  lic_available_covers: 'Coberturas dispoñibles',
  lic_covers_overview: 'Resumo de coberturas',
  lic_covers: 'Coberturas',
  lic_cover: 'Cobertura',
  lic_config_profile_warning: 'ADVERTENCIA: Cambiaron os perfís de configuración.',
  lic_select: 'Seleccionar',
  lic_Irrevocable_beneficiary: 'Beneficiario irrevogable',
  lic_Base: '(Base)',
  lic_Accessory: '(Accesorio)',
  lic_investment_value: 'Valor de investimento',
  lic_volatility: 'Volatilidade',
  lic_name: 'Nome',
  lic_to_be_invested: 'A investir',
  lic_info_investment_completed: 'Información: investimento completado',
  lic_fund: 'Fondo',
  lic_max_percentage: 'porcentaxe máxima aceptada',
  lic_min_percentage: 'porcentaxe mínima aceptada',
  lic_selected_funds: 'Fondos seleccionados',
  lic_allocation_exceeds: 'A asignación de investimento supera o importe dispoñible',
  lic_percentage_threshold: '% Limiar',
  lic_fund_distribution_identical: 'Distribución de fondos idéntica ao pagamento inicial',
  lic_fund_not_available: 'sen fondo para a opción',
  lic_details: 'Detalles',
  lic_starting_fund: 'Fondo inicial',
  lic_fund_category: 'Perfil/ Fondo',
  lic_equivalent: 'Equivalente na data',
  lic_policy_address: 'Enderezo da póliza',
  lic_policy_master_details: 'Detalle mestre da póliza',
  lic_administrative_data: 'Datos administrativos',
  lic_ordinary_mail: 'Correo ordinario',
  lic_threshold: 'Limiar',
  lic_payment_method: 'Método de pagamento dos próximos pagamentos',
  lic_additional_payment: 'Pagamento adicional',
  lic_contractual_options: 'Opcións contractuais',
  lic_elements: 'Elementos',
  lic_position_number: 'Número de posición',
  lic_questionnaires_section: 'Cuestionarios',
  lic_fees: 'Cargos fixos',
  lic__1TERM: 'Primeiro prazo',
  lic__EFFOP: 'Data efectiva',
  lic__OPZFR: 'Frecuencia',
  lic__OPZPM: 'Modo de pagamento',
  lic_expirationDate: 'Data de expiración',
  lic__OPBEN: 'Beneficiario',
  lic__MINTS: 'Limiar mínimo',
  lic__MAXTS: 'Limiar máximo',
  lic__NUMOC: 'Número de ocorrencia',
  lic__AMTOP: 'Importe de rendición',
  lic__MALMT: 'Importe mínimo',
  lic_amount: 'Importe',
  lic_currency: 'Moeda',
  lic__CIBAN: 'Iban',
  lic_fixedFees: 'Tarifas fixas',
  lic__PCOUP: 'Porcentaxe de desconto periódico',
  lic_holder: 'Titular',
  lic_ACTIVE_STATE: 'ACTIVO',
  lic_MANDATORY_STATE: 'OBRIGATORIO',
  lic_DEACTIVATE_STATE: 'DESACTIVAR',
  lic_premium_label: 'Prima',
  lic_holders_title: 'Titulares',
  lic_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lic_invalid_total_position_percentage: 'A porcentaxe total para o grupo "{{groupType}}" no Beneficiario {{cat}} debe ser do 100 %',
  lic_beneficiary_position_mandatory: 'Beneficiario {{benefCat}} {{benefName}}, selecciona un \"Número de Posición\"',
  lic_role_is_mandatory: '{{role}} é obrigatorio',
  lic_effective_holder_duplicated: 'Titular efectivo duplicado',
  lic_beneficiary_perc_req_range: '{{benefCat}} {{benefRole}} {{benefName}}: A porcentaxe debe estar entre 0.01 e 100',
  lic_beneficiary_perc_req: ' {{benefCat}} {{benefRole}} {{benefName}}: Insire unha porcentaxe',
  lic_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Insire un beneficiario do repositorio de partes',
  lic_effective_holder_perc: 'A porcentaxe do titular efectivo {{linkedName}}, do beneficiario {{benefName}}, non cumpre coa porcentaxe mínima de {{linkedPerc}}%',
  lic_effective_holder_perc_req: '{{linkedName}} titular efectivo de {{benefName}}: Insire a porcentaxe',
  lic_or_junction: 'o',
  lic_backdating_not_allowed: 'Non se permite a data de efecto anterior',
};
export default LIC_GL_ES ;

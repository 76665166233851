import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcPercentFormGroupComponent} from './lpc-percent-form-group/lpc-percent-form-group.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';

import {ReactiveFormsModule} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';


@NgModule({
  declarations: [LpcPercentFormGroupComponent],
  exports: [
    LpcPercentFormGroupComponent
  ],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    ReactiveFormsModule,
    NgSelectModule,
    RgiRxI18nModule,
    LpcRequiredModule
  ]
})
export class LpcPercentFormGroupModule { }

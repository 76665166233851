export class MPBalanceDetail {
  currency: string;
  risks: RiskBalanceDetail[];
}

export class RiskBalanceDetail {
  riskDescription: string;
  gross: number;
  net: number;
  taxes: number;
  ssn: number;
  commissions: number;
  negative: boolean;

  constructor() {
    this.gross = 0;
    this.net = 0;
    this.taxes = 0;
    this.ssn = 0;
    this.commissions = 0;
    this.negative = false;
  }
}

<div class="ac_cont_dati">
    <div class="ac_cont_riga">
        <div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._ORIGIN_TYPE</span>
                <span>: <b>{{ elementCurrentDetail.originalEntity || '-'  }}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._COMPETENT_USER</span>
                <span>: <b>{{ elementCurrentDetail.description || '-'  }}</b></span>
            </div>
        </div>
    </div>
</div>

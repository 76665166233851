<div class="grid-item card card-height-normal card-main">
    <form [formGroup]="newPayFormModal" >
     <div class="modal-content"  *ngIf="'taxableAmount'+extension || consult">
      <div class="modal-header">
            <h3 class="modal-title">{{ '_CLAIMS_._FEE_DATA' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
            </h3>
      </div>

      <div class="modal-body" style="padding-top: 5px; height: 520px;">
        <div class="card shadow" >
            <div class="row">
                <div class="col-xs-12" style="font-size: 16px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ exp.description }} </label>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-3" style="font-size: 14px;">
                    <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._FEE_NUMBER' | translate }} </label>
                    <br>
                    <input type="text"    
                            class="form-control" 
                            name="feeNumberCtrl{{extension}}" (change)="verifyExistance()"
                            attr.data-qa="new-payment-feeNumberCtrl{{extension}}-input"
                            formControlName="feeNumberCtrl{{extension}}" *ngIf="!consult"/>
                    <input type="text"    
                        class="form-control" 
                        name="feeNumber"
                        value="{{feeOmniaData.feeNumber}}"
                        disabled
                        *ngIf="consult"
                        />
                </div>
                <div class="col-xs-3" style="font-size: 14px;" *ngIf="!consult">
                   <pnc-datepicker label="{{ '_CLAIMS_._FEE_DATE' | translate }}" customFormControlName="feeDateCtrl{{extension}}"
                    [customFormGroup]="newPayFormModal" [maxDate]="today" (change)="verifyExistance()"
                    attr.data-qa="new-payment-feeDateCtrl{{extension}}-input" *ngIf="!consult">
                    </pnc-datepicker>
                   
                </div>
                <div class="col-xs-3" style="font-size: 14px;" *ngIf="consult">
                     <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._FEE_DATE' | translate }} </label>
                     <br>
                     <span >{{feeOmniaData.feeDate| date:'dd/MM/yyyy'}}</span>
                </div>
                <div class="col-xs-3" style="font-size: 14px;">
                    <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._FEE_AMOUNT' | translate }} </label>
                    <br>
                    <input type="number"    
                            class="form-control" 
                            name="feeAmountCtrl{{extension}}" (focus)="verifyExistance()"
                            attr.data-qa="new-payment-feeAmountCtrl{{extension}}-input"
                            [disabled]="feeOmniaData.idFee && !inModify"
                            formControlName="feeAmountCtrl{{extension}}" *ngIf="!consult && !(feeOmniaData.idFee && !inModify)"/>
                    <input type="number"    
                        class="form-control" 
                        name="feeAmountCtrl"
                        value="{{feeOmniaData.feeAmount}}"
                        disabled
                        *ngIf="consult || (feeOmniaData.idFee && !inModify)"
                        />
                </div>
                <div class="col-xs-3" style="font-size: 14px; padding-top: 23px;">
                    <button class="btn btn-warning text-uppercase" (click)="modifyFee()" translate *ngIf="!consult && feeOmniaData.idFee && !blockModify && !inModify">{{'_CLAIMS_._BUTTONS_._MODIFY'}}</button>
                    &nbsp;
                    <button class="btn btn-warning text-uppercase" (click)="saveFee()" translate *ngIf="!consult && feeOmniaData.idFee && !blockModify && inModify">{{'_CLAIMS_._BUTTONS_.SAVE'}}</button>
                    &nbsp;
                    <button class="btn btn-warning text-uppercase" (click)="cancelFee()" translate *ngIf="!consult && feeOmniaData.idFee && !blockModify && inModify">{{'_CLAIMS_._BUTTONS_.CANCEL'}}</button>
                </div>
             </div>
             <div class="row">
                <div class="col-xs-12" style="font-size: 14px; padding-bottom: 10px; ">
                    <label ng-bind="label"   style="font-weight: bold; padding-left: 1%; padding-top: 1%; background-color: #d7e5f0; width: 100%;" *ngIf="!consult && !feeOmniaData.idFee">{{ '_CLAIMS_._NEW_INVOICE' | translate }} </label>
                    <label ng-bind="label"   *ngFor="let msg of feeOmniaData.claimsList"  style="font-weight: bold; padding-left: 1%; padding-top: 1%; background-color: #d7e5f0; width: 100%" >{{msg}}</label>
                    <label ng-bind="label"   style="font-weight: bold; padding-left: 1%; padding-top: 1%; background-color: #eaa470; width: 100%" *ngIf="!consult && feeOmniaData.invoiceControlPaymentBlocked">{{ '_CLAIMS_._SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED' | translate }}</label>
                </div>

             </div>
             <div class="row">
                
                <div class="col-xs-4" style="font-size: 14px;">
                    <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._PAID_AMOUNT' | translate }} </label>
                    <br>
                    
                    <input type="number"    
                        class="form-control" 
                        name="paymentAmoun"
                        value="{{valueCurrentAmount()}}"
                        disabled
                        *ngIf="!consult"
                        />
                    <input type="number"    
                        class="form-control" 
                        name="paymentAmoun"
                        value="{{exp.legalFeeAmount}}"
                        disabled
                        *ngIf="consult"
                        />
                </div>
                <div class="col-xs-4" style="font-size: 14px;">
                    <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._PERCENTAGE_OF_THE_TOTAL' | translate }} </label>
                    <br>
                    
                    <input type="number"    
                        class="form-control" 
                        name="perc1"
                        value="{{calculatePercentage(valueCurrentAmount())}}"
                        disabled
                        *ngIf="!consult"
                         />
                         
                    <input type="number"    
                         class="form-control" 
                         name="perc1"
                         value="{{calculatePercentage(exp.legalFeeAmount)}}"
                         disabled
                         *ngIf="consult"
                          />
                </div>
            </div>
            <div class="row">
                
                <div class="col-xs-4" style="font-size: 14px;">
                   <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._ALREADY_INSERT' | translate }} </label>
                    <br>
                    
                    <input type="number"    
                        class="form-control" 
                        name="amountPaid"
                        value="{{feeOmniaData.amountPaid}}"
                        disabled
                         />
                </div>
                <div class="col-xs-4" style="font-size: 14px;">
                    
                        <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._PERCENTAGE_OF_THE_TOTAL' | translate }} </label>
                        <br>
                        
                        <input type="number"    
                            class="form-control" 
                            name="perc2"
                            value="{{calculatePercentage(feeOmniaData.amountPaid)}}"
                            disabled
                             />
                    
                </div>
            </div>
            <div class="row">
                
                <div class="col-xs-4" style="font-size: 14px;">
                   <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._REMAINING_AMOUNT' | translate }} </label>
                    <br>
                    
                    <input type="number"    
                        class="form-control" 
                        name="remainderAmount"
                        value="{{valueRemainderAmount()}}"
                        disabled
                         />
                </div>
                <div class="col-xs-4" style="font-size: 14px;">
                   
                        <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._PERCENTAGE_OF_THE_TOTAL' | translate }} </label>
                        <br>
                        
                        <input type="number"    
                            class="form-control" 
                            name="perc3"
                            value="{{calculatePercentage(valueRemainderAmount())}}"
                            disabled
                             />
                    
                </div>
            </div>

            <div class="row">
                
                <div class="col-xs-4" style="font-size: 14px;">
                   <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }} </label>
                    <br>
                    
                    <input type="number"    
                        class="form-control" 
                        name="totalAmount"
                        value="{{valueTotalAmount()}}"
                        disabled
                         />
                </div>
                <div class="col-xs-4" style="font-size: 14px;">
                    
                        <label ng-bind="label"   style="font-weight: bold;">{{ '_CLAIMS_._PERCENTAGE_OF_THE_TOTAL' | translate }} </label>
                        <br>
                        <input type="number"    
                            class="form-control" 
                            name="perc4"
                            value="{{calculatePercentage( valueTotalAmount())}}"
                            disabled
                             />
                  
                </div>
            </div>    
        </div>
  </div> 
  <div class="form-group">
    <div class="alert alert-danger" *ngIf="errorMessage!=null" role="alert" >{{ errorMessage | translate }} </div>
 </div>
  <div class="modal-footer">
        <div class="btn-group btn-group-justified">
            <div class="btn-group" *ngIf="!consult">
                <button class="btn btn-warning text-uppercase" (click)="onConfirm()" 
                    >{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
            </div>
        </div>
  </div>


     </div>
    </form>
</div>

<rgi-rx-panel [ngClass]="{'rgi-psales-modal-xl': premiumDetails?.length > 2}" >
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    {{'_RGIPNC_._LABEL_._TOTAL_PREMIUMS_' | translate}}
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="primary" (click)="modalClose.emit()" translate>
      {{'_RGIPNC_._BTN_._CLOSE_' | translate}}
    </button>
  </rgi-rx-panel-footer>
  <div class="rgi-ui-grid-{{premiumDetails.length}}-lg-2-md-1">
    <div class="rgi-ui-col rgipc-mb-0" *ngFor="let installment of premiumDetails">
      <div class="rgipc-bg-info rgipc-flex-center rgipc-vp-l rgipc-hp-m">
        <div class="rgipc-flex-gr-1 rgi-ui-text-2"><b>{{installment.premiumLabel | translate}}</b></div>
        <div class="rgipc-premium-detail-amount">
          <b>{{installment.gross | rgiCountryLayerNumberFormat:undefined:{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          } }}</b>
        </div>
        <div class="rgi-ui-text-3 rgipc-pt-s"><b>{{installment.currencySymbol}}</b></div>
      </div>
      <div class="rgi-ui-text-1 rgi-ui-icon-down-arrow2 rgipc-icon-down-detail-box rgi-ui-info"></div>
      <div class="rgipc-amount-container">
        <ng-container *ngFor="let amount of installment.amounts">
          <div class="rgi-ui-text-4 rgi-ui-info rgipc-d-flex rgipc-labeled-entity">
            <div class="rgipc-flex-gr-1">{{amount.label | translate}}</div>
            <div>{{amount.description | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
          <div class="rgipc-amount-container rgipc-pt-s rgipc-pl-l" *ngIf="!!amount?.nestedEntities?.length">
            <div *ngFor="let nested of amount.nestedEntities"
                 class="rgi-ui-text-4 rgi-ui-info rgipc-d-flex rgipc-labeled-entity">
              <div class="rgipc-flex-gr-1">{{nested.label | translate}}</div>
              <div>{{nested.description | rgiCountryLayerNumberFormat:undefined:{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              } }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</rgi-rx-panel>

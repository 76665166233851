import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { ModalComponent, ModalService } from '@rgi/rx/ui';
import { LpcFeeExtraModalComponent } from './lpc-fee-extra-modal/lpc-fee-extra-modal.component';
import { InstWarrantyExtraInfos, InstWarrantyFeeInfos, InstallmentWarrantyObj, PremiumDetailInstallmentsObj } from '../../../models/life-detail.model';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { LpcDataTableHeadersObj } from '../../../models/data-table.model';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';

@Component({
  selector: 'lpc-premium-detail',
  templateUrl: './lpc-premium-detail.component.html',
  styleUrls: ['./lpc-premium-detail.component.scss']
})
export class LpcPremiumDetailComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };
  public FEES_FIELD = 'fees';
  public EXTRA_PREMIUM_FIELD = 'extraPremium';
  public FEE_INFO_FIELD = 'fee';
  public EXTRAP_INFO_FIELD = 'extra';
  modal: ModalComponent;

  @Input() instalments: PremiumDetailInstallmentsObj[];
  @Input() id: string;
  @Output() eventPropagation = new EventEmitter<any>();

  headers: LpcDataTableHeadersObj[] = [
    { title: this.translate.getImmediate('lpc_Guarantee'), field: 'description'},
    { title: this.translate.getImmediate('lpc_Premium'), field: 'purePremium'},
    { title: this.translate.getImmediate('lpc_Loading'), field: 'fees', toolTip: true },
    { title: this.translate.getImmediate('lpc_extra_premium'), field: 'extraPremium', toolTip: true },
    { title: this.translate.getImmediate('lpc_net'), field: 'net'},
    { title: this.translate.getImmediate('Int. Fraz'), field: 'installmentInterest'},
    { title: this.translate.getImmediate('lpc_fixedCharges'), field: 'fixedCharges'},
    { title: this.translate.getImmediate('lpc_Taxes'), field: 'taxes'},
    { title: this.translate.getImmediate('lpc_grosspremium'), field: 'gross'}
  ];

  constructor(
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected translate: TranslationWrapperService,
    protected modalService: ModalService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  public back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  openTemplate(data: {instName: string, mainTitle: string, title: string, data: InstWarrantyFeeInfos | InstWarrantyExtraInfos}) {
    PlcObjectUtils.merge(data.data, {catastrophe: null});
    this.modalService.open(LpcFeeExtraModalComponent, data);
  }

  isObjectFull(inst: InstallmentWarrantyObj, header: LpcDataTableHeadersObj) {
    const obj = inst.extraData[header.field === this.FEES_FIELD ? this.FEE_INFO_FIELD : this.EXTRAP_INFO_FIELD];
    return obj && Object.keys(obj).length > 0;
  }

}


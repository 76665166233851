import {HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class PassproproDownloadService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Open a resource with data to into a window by reading the BLOB.
   * Also fixes issues for MS-IE when opening blobs.
   * @param request
   * @param type String the MIME type of the resouce to be loaded. (e.g. application/pdf)
   * @param name the name of the file with the extension. In alternative resource.name is used
   */
  openBlob(request: HttpRequest<any>, type?: string, name?: string) {
    const event = this.httpClient.request(request)
      .subscribe(
        (response: HttpEvent<Blob>) => {
          if (response.type === HttpEventType.Response) {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.cssText = 'display:none';
            const url = window.URL.createObjectURL(response.body);
            a.href = url;
            a.download = this.getName(response, name, type ? type : response.body.type);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        }
      );

  }

  private hasExtension(name) {
    return name.match('([a-zA-Z0-9s_\\\\.\\-():])+(.doc|.docx|.pdf|.csv|.xlsx|.xls|.zip|.gzip)');
  }

  private mimeToExtension(type) {
    const splitted = type.split('/');
    if (splitted && splitted[1]) {
      return '.' + splitted[1];
    }
    return '.file';
  }


  private getName(resource: HttpResponse<any>, name, type) {
    let parsed;
    if (name) {
      parsed = name;
    } else if (resource.headers.has('Content-disposition')) {
      const contentDisposition = resource.headers.get('Content-disposition').split(';');
      if (contentDisposition.length === 2) {
        const filename = contentDisposition[1].trim().split('=');
        if (filename.length === 2) {
          parsed = filename[1].replace(/"/g, '').trim();
        }
      }
    } else {
      parsed = Math.random().toFixed();
    }

    if (!this.hasExtension(parsed)) {
      parsed += this.mimeToExtension(type);
    }

    return parsed;
  }

}

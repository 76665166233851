import {Injectable} from '@angular/core';
import {BaseEntity, Factor} from '../group-policy-models/group-policy-issue-policy-data';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, AbstractControl} from '@angular/forms';
import {FACTOR_TYPE} from '../group-policy-constants/factors';
import {GroupPolicyDateAdapter} from '../adapters/ngbDateCustomParserFormatter ';

@Injectable({
  providedIn: 'root'
})
export class GPVariableService {

  constructor(
    public dateAdapter: GroupPolicyDateAdapter
  ) {
  }

  public createVariablesFormGroup(variables: Factor[]) {
    let formGroup;
    if (!!variables) {
      formGroup = new UntypedFormGroup({});
      variables.forEach(variable => {
        this.addVariableToFormGroup(variable, formGroup);
      });
    }
    return formGroup ? formGroup : new UntypedFormGroup({});
  }

  updateVariablesFormGroup(variables: Factor[], formGroup: UntypedFormGroup) {
    variables.forEach(variable => {
      let fieldControl = formGroup.get(variable.code);
      if (fieldControl) {
        fieldControl.patchValue(this.getVariableValue(variable), {emitEvent: false});
        fieldControl.setValidators(this.getVariableValidators(variable, formGroup));
      } else {
        this.addVariableToFormGroup(variable, formGroup);
        fieldControl = formGroup.get(variable.code);
      }
      if (!variable.editable && fieldControl.enabled) {
        fieldControl.disable({emitEvent: false});
      } else if (variable.editable && fieldControl.disabled) {
        fieldControl.enable({emitEvent: false});
      }
    });
    Object.keys(formGroup.controls)
      .filter(key => !key.includes('_MIN') && !key.includes('_MAX') && !key.includes('_CHECK'))
      .forEach(key => {
        if (!variables.find(variable => variable.code === key)) {
          formGroup.removeControl(key);
          formGroup.removeControl(key + '_MIN');
          formGroup.removeControl(key + '_MAX');
          formGroup.removeControl(key + '_CHECK');
        }
      });
  }

  public addVariableToFormGroup(variable: Factor, formGroup) {
    const variableControl = this.getVariableControl(variable);
    variableControl.setValidators(this.getVariableValidators(variable, formGroup));
    formGroup.addControl(variable.code, variableControl);
    this.manageVariableExtraFields(variable, !variable.editable, formGroup);
  }

  public getVariableControl(variable: Factor) {
    return new UntypedFormControl(
      {value: this.getVariableValue(variable), disabled: !variable.editable},
      {updateOn: this.getUpdateOnStrategy(variable)});
  }

  private getUpdateOnStrategy(variable: Factor): 'blur' | 'change' {
    switch (variable.type) {
      case FACTOR_TYPE.NUMERIC:
      case FACTOR_TYPE.DATE:
      case FACTOR_TYPE.STRING:
        return 'blur';
      default:
        return 'change';
    }
  }

  public getVariableValue(variable: Factor) {
    let value;
    switch (variable.type) {
      case FACTOR_TYPE.NUMERIC:
        value = variable.value == null || isNaN(Number(variable.value)) || (!!variable.values && variable.values.length)
          ? variable.value : Number(variable.value);
        break;
      case FACTOR_TYPE.DATE:
        value = this.dateAdapter.fromModelNotStdRX(variable.value);
        break;
      default:
        value = variable.value;
    }
    return value;
  }

  public manageVariableExtraFields(variable: Factor, disabled: boolean, formGroup: UntypedFormGroup) {
    switch (variable.type) {
      case FACTOR_TYPE.NUMERIC:
        if (variable.minMaxVisible && variable.values.length === 0) {
          formGroup.addControl(variable.code + '_MIN', new UntypedFormControl(
            {value: variable.minValue ? Number(variable.minValue) : undefined, disabled},
            {validators: [Validators.min(0), this.minGreaterThanMaxValidatorFn(variable.code, formGroup)]}
          ));
          formGroup.addControl(variable.code + '_MAX', new UntypedFormControl(
            {value: variable.maxValue ? Number(variable.maxValue) : undefined, disabled},
            {validators: [Validators.min(0), this.maxLessThanMinValidatorFn(variable.code, formGroup)]}
          ));
        }
        break;
      case FACTOR_TYPE.STRING:
        if (variable.editableCheckVisible) {
          const isVariableNull = !formGroup.get(variable.code) || formGroup.get(variable.code).value === undefined;
          const editableCheck = new UntypedFormControl({
            value: !variable.editInApplication,
            disabled: disabled || isVariableNull
          });
          formGroup.addControl(variable.code + '_CHECK', editableCheck);
        }
        break;
    }
  }

  public getVariableValidators(variable: Factor, formGroup: UntypedFormGroup) {
    const validators = [];
    if (variable.editable) {
      if (variable.mandatory && !variable.multipleOptionsSelectable) {
        validators.push(Validators.required);
      }
      if (variable.pattern) {
        validators.push(Validators.pattern(variable.pattern));
      }
    }
    if (variable.type === FACTOR_TYPE.NUMERIC) {
      validators.push(Validators.min(0));
      if (variable.minMaxVisible) {
        validators.push(this.numericVariableInRangeValidatorFn(variable.code, formGroup));
      }
    }
    return validators;
  }

  public numericVariableInRangeValidatorFn(variableCode: string, formGroup: UntypedFormGroup): ValidatorFn {
    return (): { [key: string]: any } => {
      let err = null;
      if (this.isNumericValued(formGroup.get(variableCode).value)) {
        const defValue = Number(formGroup.get(variableCode).value);
        const minControl = formGroup.get(variableCode + '_MIN');
        const maxControl = formGroup.get(variableCode + '_MAX');
        if (minControl && this.isNumericValued(minControl.value)) {
          err = Number(minControl.value) <= defValue ? null : {'_GP_._MSG_._OUT_OF_RANGE_': true};
        }
        if (!err && maxControl && this.isNumericValued(maxControl.value)) {
          err = defValue <= Number(maxControl.value) ? null : {'_GP_._MSG_._OUT_OF_RANGE_': true};
        }
      }
      return err;
    };
  }

  public maxLessThanMinValidatorFn(variableCode: string, formGroup: UntypedFormGroup): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      this.touchControl(formGroup.get(variableCode));
      const minControl = formGroup.get(variableCode + '_MIN');
      this.touchControl(minControl);
      if (this.isNumericValued(control.value) && minControl && this.isNumericValued(minControl.value)) {
        return Number(minControl.value) <= Number(control.value) ? null : {'_GP_._MSG_._MAX_LESS_THAN_MIN_': true};
      }
      return null;
    };
  }

  public minGreaterThanMaxValidatorFn(variableCode: string, formGroup: UntypedFormGroup): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      this.touchControl(formGroup.get(variableCode));
      const maxControl = formGroup.get(variableCode + '_MAX');
      this.touchControl(maxControl);
      if (this.isNumericValued(control.value) && maxControl && this.isNumericValued(maxControl.value)) {
        return Number(maxControl.value) >= Number(control.value) ? null : {'_GP_._MSG_._MIN_GREATER_THAN_MAX_': true};
      }
      return null;
    };
  }

  private isNumericValued(value: number): boolean {
    return value !== null && value !== undefined;
  }

  private touchControl(control: AbstractControl) {
    if (control) {
      control.markAsTouched();
    }
  }

  getVariableDisplayValue(variable: Factor): string {
    switch (variable.type) {
      case FACTOR_TYPE.LIST:
      case FACTOR_TYPE.BOOLEAN:
        return variable.value ? variable.values.find(v => v.code === variable.value).description : null;
      case FACTOR_TYPE.NUMERIC:
        if (variable.values && variable.values.length) {
          return variable.value ? variable.values.find(v => v.code === variable.value).description : null;
        } else {
          return variable.value;
        }
      default:
        return variable.value;
    }
  }

  getVariableSelectableValues(variable: Factor): Array<BaseEntity> {
    switch (variable.type) {
      case FACTOR_TYPE.LIST:
      case FACTOR_TYPE.NUMERIC:
        return variable.multipleOptionsSelectable ? variable.selectedValues : null;
      default:
        return null;
    }
  }

  updateVariableByType(variableToUpdate: Factor, variableValue: any): Factor {
    if (!!variableToUpdate && variableToUpdate.editable) {
      switch (variableToUpdate.type) {
        case FACTOR_TYPE.DATE:
          variableToUpdate.value = this.dateAdapter.toApiNotStd(variableValue);
          break;
        case FACTOR_TYPE.LIST:
        case FACTOR_TYPE.NUMERIC:
          if (variableToUpdate.multipleOptionsSelectable) {
            variableToUpdate.selectedValues = variableValue;
            break;
          }
          variableToUpdate.value = variableValue;
          break;
        default:
          variableToUpdate.value = variableValue;
      }
    }
    return variableToUpdate;
  }

  updateVariableInList(variableToUpdate: Factor, formControlValue: any, variables: Factor[]) {
    if (!!variableToUpdate && !!variables) {
      variableToUpdate = this.updateVariableByType(variableToUpdate, formControlValue);
      const index = variables.findIndex(variable => variable.code === variableToUpdate.code);
      if (index !== -1) {
        variables.splice(index, 1, variableToUpdate);
      }
    }
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {isNumber} from '../../../../adapters/group-policy-utils';
import {
  QuotationDetail,
  SummaryQuotationTranslations
} from '../../../../group-policy-models/group-policy-issue-guarantees';


/**
 * @author: dmasone
 * @description: Component used for quotation detail
 */
@Component({
    selector: 'rgi-gp-group-policy-summary-quotation-detail',
    templateUrl: './group-policy-summary-quotation-detail.component.html'
})
export class GroupPolicySummaryQuotationDetailComponent implements OnInit, OnDestroy {

    @Input() quotationDetail: QuotationDetail;
    public translations = SummaryQuotationTranslations;
    public isNumber = isNumber;

    constructor() { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {QuestionnaireFlatI} from '../../../models/domain-models/questionnaire-flatI';

@Component({
  selector: 'lib-re-issue-proposal-questionaires-modal',
  templateUrl: './re-issue-proposal-questionaires-modal.component.html',
  styleUrls: ['./re-issue-proposal-questionaires-modal.component.css']
})
export class ReIssueProposalQuestionairesModalComponent implements OnInit {

  @Output() modalClose = new EventEmitter();
  @Input() questionnaireFlat: QuestionnaireFlatI;
  confermaGroup: UntypedFormGroup;
  isConfermaEnabled = false;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {

  }

  ngOnInit() {
    this.confermaGroup = this.formBuilder.group({
      confermaQuestionaire: []
    });

    this.confermaGroup.controls.confermaQuestionaire.valueChanges.subscribe(val => {
      this.isConfermaEnabled = val;
    });
  }

  closeModal() {
    this.modalClose.emit();
  }


}

import {PoliciesDetail, Policy} from '../life-detail.model';

export const MOCK_POLICY: Policy =  {
        extension: null,
        causative: {
          identification: '2',
          code: '_VAGG',
          description: 'Versamento aggiuntivo'
        },
        policyNumber: '53939',
        description: 'PRODOTTO MULTIFONDO',
        nodeDescription: 'TORINO_01',
        premium: null,
        licensePlateNumber: null,
        contractingSubject: 'SEGRETO ELENA',
        subscriptorVatNumber: null,
        state: {
          codice: '11',
          descrizione: 'Polizza valida'
        },
        issueDate: '2020-05-05',
        objectId: '26329029',
        subscriptorId: '1274336',
        subscriptorFiscalCode: 'SGRLNE89C59B157N',
        enumobliviontype: null,
        expirationDate: null,
        cancellationDate: null,
        effectDate: '2020-03-20',
        product: 'Prodotto Multifondo',
        proposalNumber: '114362',
        nodeCode: 'TO_01',
        productCode: 'MULTIF',
        policyType: {
          codice: '1',
          descrizione: 'Individuale'
        },
        category: {
          codice: '7',
          descrizione: 'Vita'
        }
};

export const MOCK_POLICY_DETAIL: PoliciesDetail =  {
  policies : [
    MOCK_POLICY
]};

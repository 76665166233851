import { Component, Input, Output, OnInit, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SelectableCovertureResponse, Policy } from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../claim-opening/claim-opening.service';
import { ExternalPolicy } from '../../external-portfolio/dto/external-policy';
import { DatePipe } from '@angular/common';
import { DscPhisicalInjuryService } from './dsc-phisical-injury.service';
import { ClaimOpeningDetailsService } from '../../claim-opening/components/claim-opening-details/claim-opening-details.service';
import { InjuryAsset, InjuryData } from '../dsc-shared-model/dsc-model-injury';
import { InsurancePolicy } from '../dsc-shared-model/dsc-model-policy';
import { HeirsListSubjLink, SubjectReduced } from '../dsc-shared-model/dsc-model-subject';
import { CategoryDetail } from '../dsc-shared-model/dsc-model-utility';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-dsc-phisical-injury',
  templateUrl: './dsc-phisical-injury.component.html',
  styleUrls: ['./dsc-phisical-injury.component.scss']
})

export class DscPhisicalInjuryComponent implements OnInit, OnDestroy {
  @Input() expanded: boolean = false;
  isAccordion = true;
  addAssetForm: UntypedFormGroup;
  hasError: boolean;
  hasErrorVehicle: boolean;
  errorMessage: string;
  errorMessages: string[] = [];
  disableTypeLoss: boolean;
  injuryCauseList: any[];
  injuryNatureList: Map<string, any[]>;
  injuryLocationList: Map<string, any[]>;
  selThings: boolean = false;
  selInjury: boolean = false;
  selVehicle: boolean = false;
  typesOfLoss: { id: string, description: string }[] = [];
  docSelected: any;
  party: any;
  professionList: any;
  isDscPhisicalInjury = true;

  policy: Policy;
  dateString: any;
  damageCategory: any;
  idTipoPortafoglio: any;
  polExt: ExternalPolicy;
  dateRefString: any;
  idBeneAss: any;
  card: any;
  idDemageCategory: string;
  dataInjuryCopy: any;
  injuryData: InjuryData;

  @Input() selectedNode: string;
  @Input() activeRouteId: string;
  @Input() categorySelected: number;
  @Input() selectedPolicy: InsurancePolicy;
  @Input() injuryAssetInput: InjuryAsset = new InjuryAsset();
  @Input() claimData: any;
  @Input() categoryList: CategoryDetail[] = [];
  @Input() heirsList: HeirsListSubjLink;
  @Input() subject: SubjectReduced;
  @Output() outputInjuryData = new EventEmitter<InjuryData>();
  @Output() outputHeir = new EventEmitter<string>();

  constructor(
    private claimOpeningService: ClaimOpeningService,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private dscPhisicalInjuryService: DscPhisicalInjuryService,
    private rxTranslationsService: RgiRxTranslationService,
  ) {}

  ngOnInit() {
    // this.setObj();
    this.addAssetFormFanction();
    // this.formatDateTimezone(this.injuryAssetInput.);
    // this.asigneData();
    this.getCovertures();
  }

  addAssetFormFanction() {
    if (!this.addAssetForm) {
      this.addAssetForm = this.formBuilder.group(
        {
          description: [, Validators.required],
          type: []
        }
      );
      this.addAssetForm.addControl('nominative', new UntypedFormControl(Validators.required));
      // this.addAssetForm.controls.nominative.setValue(this.party.description);
      this.claimOpeningService.setAddAssetForm(this.addAssetForm);
      this.selThings = false;
      if (!this.typesOfLoss || this.typesOfLoss.length === 0) {
        this.typesOfLoss = [];
        this.rxTranslationsService.translate('_CLAIMS_._THINGS')
          .subscribe(
            res =>  this.typesOfLoss.push({ id: '3', description: res })
          );
      }

      if (this.typesOfLoss.length > 1) {
        this.disableTypeLoss = false;
      } else {
        this.disableTypeLoss = true;
        this.addAssetForm.controls.type.setValue(this.typesOfLoss[0].id);
        this.assetDetail(this.addAssetForm.controls.type.value);
      }
    } else {
      this.assetDetail(this.addAssetForm.controls.type.value);
      if (this.docSelected) {
        this.addAssetForm.controls.doctorName.setValue(this.docSelected.description);
        this.addAssetForm.controls.doctorIdSoggPA.setValue(this.docSelected.id);
        this.addAssetForm.controls.doctorIdSogg.setValue(this.docSelected.data.idLatestPhotos);
      }
    }
  }

  assetDetail(value: string) {
    if (value === '3') {
      this.selThings = true;
      this.selInjury = false;
      this.selVehicle = false;
    }
    if (value === '2') {
      this.selThings = false;
      this.selInjury = true;
      this.selVehicle = false;
    }
    if (value === '1') {
      this.selThings = false;
      this.selInjury = false;
      this.selVehicle = true;
    }
  }

  getCovertures() {
    const dateRefString = this.datePipe.transform(new Date(this.selectedPolicy.issueDate), 'dd-MM-yyyy');
    const dateString = this.datePipe.transform(new Date(this.selectedPolicy.issueDate), 'dd-MM-yyyy');
    const idBeneAss = null;
    const policyNumber = this.selectedPolicy.policyNumber;
    const idTipoPortafoglio = '1'; // default portafoglio PASS
    const polExt = null;
    const idDemageCategory = this.categorySelected ? this.categorySelected : this.getIdCategory();
    return this.dscPhisicalInjuryService.getSelectableCovertures(policyNumber, dateString,
      idDemageCategory.toString(), idTipoPortafoglio, polExt, dateRefString, idBeneAss).subscribe(
        (response: SelectableCovertureResponse) => {
          this.injuryCauseList = response.injuryCauseList.map(({ code, description }) => ({ code, description }));
          this.injuryNatureList = response.injuryNatureList;
          this.injuryLocationList = response.injuryLocationList;
          this.professionList = response.professionList.map(({ code, description }) => ({ code, description }));
        });
  }
  getIdCategory() {
    const DemageCategory = this.categoryList.find(category => category.code === 'RCA');
    return DemageCategory.idCategory || null;
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    this.dataInjuryCopy.policyIssueDate = this.datePipe.transform(miaData, 'dd-MM-yyyy');
    return this.dataInjuryCopy.policyIssueDate;
  }

  getDataFormInjury(dataFormInjury: InjuryAsset) {
    this.injuryAssetInput = dataFormInjury;
    this.injuryData = {
      dataForm: this.injuryAssetInput,
      subject: this.subject,
    };

    this.emitPhisicalInjury(this.injuryData);
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }

  emitPhisicalInjury(injuryData: InjuryData) {
    this.outputInjuryData.emit(injuryData);
  }

  outputHeirData(event) {
    this.outputHeir.emit(event);
  }

  ngOnDestroy() {
    this.injuryAssetInput = new InjuryAsset();
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, ApiPager, EnumType, Outcome } from '@rgi/digital-claims-common-angular';
import { ApiClaimsListFilter } from '../api/api-claims-list-filter';
import { ClaimsHandler } from '../dto/claims-handler';
import { Invoice } from '../dto/invoice';
import { InvoiceClaimsList } from '../dto/invoice-claims-list';
import { InvoiceClaimsListFilter } from '../dto/invoice-claims-list-filter';
import { InvoiceConstants } from '../invoice-constants';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-claims-list',
  templateUrl: './invoice-claims-list.component.html',
  styleUrls: ['./invoice-claims-list.component.scss']
})
export class InvoiceClaimsListComponent implements OnInit {

  @Input() invoice: Invoice;
  // claimsListForm: FormGroup;
  errorMessage: string;
  // emitter: any;
  claimsList: InvoiceClaimsList[];
  submitted = false;
  validationMessages: string[];
  updateInvoiceForm: UntypedFormGroup;
  arrPage: number[] = [];
  currentPage: number;
  selectedClaim: string;
  expanded: boolean;
  claimsHandlers: ClaimsHandler[];
  technicalStatus: EnumType[];
  today: NgbDate;


  constructor(
    @Inject('sessionService') private session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') private eventService: any,
    @Inject('enumService') private enumService: any,
    private invoiceService: InvoiceService,
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private alertService: AlertService,
    public translateService: RgiRxTranslationService,
  ) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  ngOnInit(): void {
    const claimsListFilter = new InvoiceClaimsListFilter();
    claimsListFilter.claimNumber = '';
    this.claimsListSearch(1, claimsListFilter);
    this.getClaimsHandlers();
    this.initValidators();
    this.currentPage = 1;
    this.expanded = false;
    this.technicalStatus = this.enumService.getEnumList('sinistri.SatoTecnicoSinistro');
  }

  initValidators() {
    this.updateInvoiceForm = this.formBuilder.group({
      claim: [, Validators.required],
      filterClaimNumber: [''],
      filterSubjectSurnameOrDenomination: [''],
      filterSubjectName: [''],
      filterClaimsHandler: [''],
      filterClaimTechnicalStatus: [''],
      filterClaimOpeningDateFrom: [''],
      filterClaimOpeningDateTo: [''],
      expanded: []
    }
    );
  }

  claimsListSearch(pageNumber: number, claimsListFilter: InvoiceClaimsListFilter) {

    this.errorMessage = null;
    this.gridInstance.cards.refresh();
    this.eventService.broadcastEvent('start-loader');
    const filter = new ApiClaimsListFilter();
    filter.invoiceExpertSectorialList = [];
    filter.claimsToExclude = [];
    const pagerFilter = new ApiPager();
    // this.arrPage = [];

    for (const sectorialElem of this.invoice.invoiceExpert.invoiceExpertSectorialList) {
      filter.invoiceExpertSectorialList.push(sectorialElem);
    }

    for (const detailsElem of this.invoice.invoiceDetailsList) {
      filter.claimsToExclude.push(detailsElem.claimNumber);
    }

    pagerFilter.pageNumber = pageNumber;
    pagerFilter.pageSize = InvoiceConstants.PAGE_ELEMENTS;
    filter.pager = pagerFilter;

    if (claimsListFilter.claimNumber) {
      filter.claimNumber = claimsListFilter.claimNumber;
    }

    if (claimsListFilter.claimOpeningDateFrom) {
      filter.claimOpeningDateFrom = claimsListFilter.claimOpeningDateFrom;
    }

    if (claimsListFilter.claimOpeningDateTo) {
      filter.claimOpeningDateTo = claimsListFilter.claimOpeningDateTo;
    }

    if (claimsListFilter.codHandler) {
      filter.codHandler = claimsListFilter.codHandler;
    }

    if (claimsListFilter.subjectName) {
      filter.subjectName = claimsListFilter.subjectName;
    }

    if (claimsListFilter.subjectSurnameOrDenomination) {
      filter.subjectSurnameOrDenomination = claimsListFilter.subjectSurnameOrDenomination;
    }

    if (claimsListFilter.technicalState) {
      filter.technicalState = claimsListFilter.technicalState;
    }

    this.invoiceService.getClaimsList(filter)
      .subscribe((response: any) => {
        this.claimsList = response.claimsList;
        this.currentPage = response.currentPage;

        if (pagerFilter.pageNumber === 1) {
          this.arrPage = [];
          for (let index = 0; index < response.totalPages; index++) {
            this.arrPage.push(index);
          }
        }

        if (this.claimsList && this.claimsList.length > 0) {
        } else {
          this.translateService.translate('_CLAIMS_._NO_RESULT').subscribe(res=> {
            this.errorMessage=res;
          }
          )
        }
        this.eventService.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.translateService.translate('_CLAIMS_._MESSAGE_._SEARCH_ERROR').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.eventService.broadcastEvent('stop-loader');
        }
      );
  }

  hasResult() {
    if (this.claimsList && this.claimsList.length > 0) {
      return true;
    }
    return false;
  }

  onSelectionChange(claim: string) {
    this.selectedClaim = claim;
  }

  confirmInvoice() {
    if (this.selectedClaim && this.selectedClaim !== '') {
      this.eventService.broadcastEvent('start-loader');

      // TODO - for future proforma management (more details for itself invoice) detailsTotAmountUnit e detailsTaxableAmount manage in cicle
      this.invoice.invoiceDetailsList[0].claimNumber = this.selectedClaim;

      this.saveInvoice(this.invoice);
    } else {
      // this.validationMessages = [];
      // this.validationMessages.push('Fields marked in red are required or contain incorrect values');
      this.translateService.translate('_CLAIMS_._SELECT_CLAIM').subscribe(res=> {
        this.errorMessage=res;
      }
      )
    }
    return null;
  }

  saveInvoice(invoice: Invoice) {
    this.invoiceService.saveInvoice(invoice, this.invoice.invoiceDetailsList[0].rejectType.codice).subscribe(
      (response: Outcome) => {
        if (response.code === Outcome.OK) {
          this.activeModal.close(response);
          this.translateService.translate('_CLAIMS_._MESSAGE_._UPDATE_SUCCESSFUL').subscribe(res=> {
            this.alertService.translatedMessage(res);
          }
          )
        } else {
          this.validationMessages = [response.descr];
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error: Error) => {
        console.error(error);
        this.validationMessages = ['Saving error'];
        this.translateService.translate('_CLAIMS_._MESSAGE_._SAVING_ERROR').subscribe(res=> {
          this.validationMessages=[res];
        }
        )
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  goToPage(pageNumber: number) {
    const claimsListFilter = new InvoiceClaimsListFilter();
    claimsListFilter.claimNumber = '';
    this.claimsListSearch(pageNumber, claimsListFilter);
  }

  clearSearchFields() {
    this.updateInvoiceForm = this.formBuilder.group({
      claim: [, Validators.required],
      filterClaimNumber: [''],
      filterSubjectSurnameOrDenomination: [''],
      filterSubjectName: [''],
      filterClaimsHandler: [''],
      filterClaimTechnicalStatus: [''],
      filterClaimOpeningDateFrom: [''],
      filterClaimOpeningDateTo: [''],
      expanded: []
    }
    );

  }

  claimsListFilterSearch() {

    this.cleanErrorStyle('filterSubjectSurnameOrDenomination');
    this.validationMessages = [];

    if (this.updateInvoiceForm.get('filterSubjectName').value !== ''
      && this.updateInvoiceForm.get('filterSubjectSurnameOrDenomination').value === '') {
      this.setErrorStyle('filterSubjectSurnameOrDenomination');
      this.translateService.translate('_CLAIMS_._MESSAGE_._INSERT_SUBJECT_SURNAME_OR_DENOMINATION').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
      // this.errorMessage = this.translate.instant('Insert subject surname or denomination');
      return false;
    }

    const claimsListFilter = new InvoiceClaimsListFilter();
    claimsListFilter.claimNumber = this.updateInvoiceForm.controls.filterClaimNumber.value;
    claimsListFilter.subjectSurnameOrDenomination = this.updateInvoiceForm.controls.filterSubjectSurnameOrDenomination.value;
    claimsListFilter.subjectName = this.updateInvoiceForm.controls.filterSubjectName.value;
    claimsListFilter.codHandler = this.updateInvoiceForm.controls.filterClaimsHandler.value;
    claimsListFilter.technicalState = this.updateInvoiceForm.controls.filterClaimTechnicalStatus.value;
    claimsListFilter.claimOpeningDateFrom = this.updateInvoiceForm.controls.filterClaimOpeningDateFrom.value;
    claimsListFilter.claimOpeningDateTo = this.updateInvoiceForm.controls.filterClaimOpeningDateTo.value;

    this.claimsListSearch(1, claimsListFilter);
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  cleanErrorStyle(fieldName: string) {
    if (document.getElementById(fieldName) != null) {
      document.getElementById(fieldName).style.cssText = '';
    }
  }

  setErrorStyle(fieldName: string) {
    if (document.getElementById(fieldName) != null) {
      document.getElementById(fieldName).style.cssText = 'border: 2px solid #d22323 !important;color: #d22323 !important;';
    }
  }

  getClaimsHandlers() {
    this.invoiceService.getClaimsHandlers()
    .subscribe((response: any) => {
      this.claimsHandlers = response.claimsHandlersList;
    });
  }

}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <span class="rgifont rgi-dettagli_tecnici"></span>
            {{ '_CLAIMS_._SEARCH' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
        </div>
        <div class="modal-body" style="padding-top: 5px">

            <form [formGroup]="addAssetForm">
                <div class="container-fluid">
                    <div class="form-group row">
                        <div class="col-sm-12 col-xs-12">
                            <div class="col-sm-12 col-xs-12">
                                <label ng-bind="label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</label>
                                <input class="col-sm-12 form-control" formControlName="descriptionHospital"
                                    id="descriptionHospital" type="text" name="descriptionHospital"
                                    attr.data-qa="search-hospital-descriptionHospital-input">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <claims-select-autocomplete attr.data-qa="search-hospital-country-input"
                            label="{{ '_CLAIMS_._NATION' | translate }}" [(value)]="selectedCountry"
                            [values]="countries" [required]="false" [customFormGroup]="addAssetForm" [disabled]="true"
                            [customFormControlName]="'countryHospital'">
                        </claims-select-autocomplete>
                        <claims-select-autocomplete attr.data-qa="search-hospital-city-input"
                            label="{{ '_CLAIMS_._MUNICIPALITY' | translate }}" [(value)]="selectedMunicipality"
                            [values]="cities" [required]="false" [disabled]="false" (valueChange)="setState($event)"
                            [customFormGroup]="addAssetForm" [customFormControlName]="'municipalityHospital'">
                        </claims-select-autocomplete>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6 col-xs-12">
                            <label ng-bind="label">{{ '_CLAIMS_._PROVINCE' | translate }}</label>
                            <input class="form-control" formControlName="stateDescriptionHospital"
                                id="stateDescriptionHospital" type="text" name="stateDescriptionHospital"
                                attr.data-qa="search-hospital-province-input" disabled="true">
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <label ng-bind="label">{{ '_CLAIMS_._ZIP_CODE' | translate }}</label>
                            <select [disabled]="false" class="core-select form-control"
                                formControlName="postalCodeHospital" id="postalCodeHospital" name="zipCode"
                                attr.data-qa="search-hospital-zipCode-select">
                                <option></option>
                                <option *ngFor="let code of zipCodes; " value={{code.value}}>
                                    {{code.id}}</option>
                            </select>
                            <span
                                class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-10 col-xs-12">

                            <label ng-bind="label">{{ '_CLAIMS_._ADDRESS' | translate }}</label>
                            <input class="form-control" formControlName="addressHospital" id="addressHospital"
                                type="text" name="addressHospital" attr.data-qa="search-hospital-addressHospital-input">

                        </div>
                        <div class="col-sm-2 col-xs-12">

                            <label ng-bind="label">{{ '_CLAIMS_._NUMBER' | translate }}</label>
                            <input class="form-control" formControlName="numberHospital" id="numberHospital" type="text"
                                name="numberHospital" attr.data-qa="search-hospital-numberHospital-input">

                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="btn-group btn-group-justified">
                            <div class="btn-group">
                                <button class="btn btn-warning pull-right" (click)="searchHealthcareProviders($event)"
                                    attr.data-qa="search-hospital-btn">{{ '_CLAIMS_._HEALTHCARE_PROVIDERS' | translate | uppercase}}</button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="!hospitalList && hospitalList.length === 0">
                        <div class="col-sm-12 col-xs-12" style="text-align: center;">
                            <label ng-bind="label">{{ '_CLAIMS_._NO_RESULT' | translate }}</label>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="hospitalList && hospitalList.length !== 0">
                        <div class="col-sm-2 col-xs-12 head-result">
                            <label ng-bind="label">Sel.</label>
                        </div>
                        <div class="col-sm-10 col-xs-12 head-result">
                            <label ng-bind="label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</label>
                        </div>
                    </div>
                    <div class="form-group display-custom" *ngFor="let aStruct of hospitalList; 
                                                        let even = even; 
                                                        let odd = odd;"
                        [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" style="margin: 1px;">
                        <div class="col-sm-2 col-xs-12">
                            <button class="btn-custom-search-hospital" (click)="selectionHealthcareProviders(aStruct)"
                                attr.data-qa="sel-hospital-btn">{{ '_CLAIMS_._SELECT' | translate | uppercase}}</button>
                        </div>
                        <div class="col-sm-10 col-xs-12">
                            {{aStruct.description}}
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
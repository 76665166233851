import {Inject, Injectable} from '@angular/core';
import {RgiRxRouter} from '@rgi/rx/router';
import {ConsultationContractDetailsResourceService} from '../resource/consultation-contract-details-resource.service';
import {Observable} from 'rxjs';
import {ContractContainer} from '../../models/details/contract-details';

@Injectable({
  providedIn: 'root'
})
export class ConsultationContractDetailsBusinessService{
  constructor(
    @Inject('sessionService') private sessionService: any,
    private routingService: RgiRxRouter,
    private consultationContractDetailsResourceService: ConsultationContractDetailsResourceService,

  ) {
  }
  getContractDetail(contractNumber: string): Observable<ContractContainer>{
    return this.consultationContractDetailsResourceService.getContractDetail(contractNumber);
  }
  getPolicyOperations(body){
    return this.consultationContractDetailsResourceService.getPolicyOperations(body)
  }
}



import {AnagApiResponse} from './anag-api-response';

export class AnagApiSystemPropertiesRequest {
  public filterKey: {
    keys: Array<string>
  };
}

export class AnagApiSystemPropertiesResponse extends AnagApiResponse {
  public systemProperties: Array<AnagApiSystemProperties>;
}
export class AnagApiSystemProperties {
  key: string;
  value: string;
}

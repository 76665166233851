import {Component, ComponentRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Modal} from '../../../modal';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../common.service';
import {GenericEntity} from '../../../models/domain-models/generic-entity';
import {AddressesService} from '../addresses.service';
import {Message} from '../../../models/message';
import {OperatorService} from '@rgi/portal-ng-core';
import {map} from 'rxjs/operators';

@Component({
  selector: 'mic-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements Modal, OnInit, OnDestroy {

  componentRef: ComponentRef<any>;
  addressControlForm: UntypedFormGroup;
  countries: Array<GenericEntity>;
  validationMessages: Message[] = [];
  areaCode = 'ADDRESS_MODAL';

  changedSomethings = false;
  countriesInitialized = false;
  address: any;
  normalizedAddress: any;
  country: any;
  provinces: Array<{code: string, description: string}>;
  province: any;
  cities: Array<{code: string, description: string}>;
  zipCodes: Array<{code: string, description: string}>;
  city: any;
  zip: string;
  toponym: any;
  placeAddress: string;
  houseNumber: string;
  addressText: string;
  index: number;
  closingFromButton = false;

  showNormalize = false;
  enableNormalizeBtn = true;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected addressesService: AddressesService,
    protected commonService: CommonService,
    protected formBuilder: UntypedFormBuilder,
    protected operatorService: OperatorService
  ) {
  }

  get formValid() {
    return this.addressControlForm.valid;
  }

  ngOnInit() {
    this.addressControlForm = this.formBuilder.group(
      {
        country: ['', Validators.required],
        province: ['', Validators.required],
        city: ['', Validators.required],
        zip: ['', Validators.required],
        toponym: ['', Validators.required],
        placeAddress: ['', Validators.required],
        houseNumber: ['', Validators.required],
        addressText: ['']
      }
    );

    this.address = this.addressesService.getAddress();
    this.index = this.addressesService.getAddressIndex();

    this.commonService.getEnumValues('COUNTRIES', null).subscribe(data => {
        this.countries = data;
        this.countriesInitialized = true;
        const initialCountry = this.operatorService.getSalePointLogin().context || 'IT' ;
        this.addressControlForm.get('country').setValue(initialCountry);
        this.onCountryChange();
    }, () => {
        this.countries = [];
        this.countriesInitialized = true;
    });

    const sub = this.addressControlForm.valueChanges.subscribe(
      () => this.validateForm()
      );
    this.subscriptions.add(sub);
  }

  // TODO add code to prevalue form on edit mode
  /*valueForm(address) {
      this.addressControlForm.get('country').setValue(address.country.code);
      this.addressControlForm.get('province').setValue(address.province.code);
      this.addressControlForm.get('city').setValue(address.city);
      this.addressControlForm.get('zip').setValue(address.cap);
      this.addressControlForm.get('toponym').setValue(address.toponym.description);
      this.addressControlForm.get('placeAddress').setValue(address.placeAddress);
      this.addressControlForm.get('houseNumber').setValue(address.number);
  }*/

  updateForm() {
    this.addressControlForm.patchValue({
      country: this.address.country.code,
      province: this.address.province.code,
      city: this.address.city,
      zip: this.address.cap,
      toponym: this.address.toponym.description,
      placeAddress: this.address.placeAddress,
      houseNumber: this.address.number
    });
  }

  onSubmit() {
    this.validateForm();
    if (this.addressControlForm.valid) {
      if (this.changedSomethings && !this.address.normalized) {
        this.loadAddress();
      }
      this.addressesService.setAddress(this.address);
      this.addressesService.addAddress(this.address, this.index);
      this.close();
    }
  }

  loadAddress() {
    if (!this.address) {
      this.address = {};
    }
    this.address.country = {};
    this.address.country.code = this.addressControlForm.controls.country.value;
    this.address.countryCode = this.addressControlForm.controls.country.value;

    this.address.province = {};
    const provinceName = this.addressControlForm.controls.province.value;
    const province = this.provinces.find(el => el.description === provinceName);
    this.address.province.code = (province && province.code) ? province.code : null;
    this.address.province.description = provinceName;
    this.address.adminLevel2 = provinceName;

    this.address.city = this.addressControlForm.controls.city.value;
    this.address.cap = this.addressControlForm.controls.zip.value;
    this.address.toponym = {
      code: this.addressControlForm.controls.toponym.value,
      description: this.addressControlForm.controls.toponym.value
    };
    this.address.placeAddress = this.addressControlForm.controls.placeAddress.value;
    this.address.number = this.addressControlForm.controls.houseNumber.value;
  }

  close(fromButton?: boolean) {
    this.closingFromButton = fromButton;
    this.componentRef.destroy();
  }

  onCountryChange() {
    const country = this.addressControlForm.controls.country.value;
    this.getAdminLevel(country, 2, country).subscribe(
        provinces => {
          this.provinces = provinces.sort((a, b) => a.description - b.description);
          if (this.provinces && this.provinces.length > 0) {
            this.addressControlForm.controls.province.setValue(-1);
          } else {
            this.addressControlForm.controls.province.setValue('');
          }
        },
        () => {
          this.provinces = [];
          this.addressControlForm.controls.province.setValue('');
        });
    this.cities = [];
    this.zipCodes = [];
  }

  onProvinceChange() {
    const country = this.addressControlForm.controls.country.value;
    const province = this.addressControlForm.controls.province.value;
    this.getAdminLevel(country, 3, province).subscribe(
        cities => {
          this.cities = cities.sort((a, b) => a.description - b.description);
          if (this.cities && this.cities.length > 0) {
            this.addressControlForm.controls.city.setValue(-1);
          } else {
            this.addressControlForm.controls.city.setValue('');
          }
        },
        () => {
          this.cities = [];
          this.addressControlForm.controls.city.setValue('');
        });
    this.zipCodes = [];
  }

  onCityChange() {
    const country = this.addressControlForm.controls.country.value;
    const city = this.addressControlForm.controls.city.value;
    const cityCode = this.cities.find(c => c.description === city).code;
    this.getAdminLevel(country, 5, cityCode).subscribe(
        zipCodes => {
          this.zipCodes = zipCodes.sort((a, b) => a.code - b.code);
          if (this.zipCodes && this.zipCodes.length > 0) {
              this.addressControlForm.controls.zip.setValue(-1);
          } else {
              this.addressControlForm.controls.zip.setValue('');
          }
        },
        () => {
          this.zipCodes = [];
          this.addressControlForm.controls.zip.setValue('');
        });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChange(): void {
    this.changedSomethings = true;
    this.enableNormalizeBtn = true;
  }

  normalize() {
    this.normalizedAddress = {
      address: {
        adminLevel1: null,
        adminLevel2: null,
        adminLevel3: this.addressControlForm.controls.city.value,
        countryCode: this.addressControlForm.controls.country.value,
        cap: this.addressControlForm.controls.zip.value,
        toponym: {
          descrizione: this.addressControlForm.controls.toponym.value
        },
        placeAddress: this.addressControlForm.controls.placeAddress.value,
        number: this.addressControlForm.controls.houseNumber.value,
        locality: null
      }
    };

    const provValue = this.addressControlForm.controls.province.value;
    if (provValue) {
      const prov = this.provinces.filter(pr => pr.code === provValue);
      if (prov && prov.length > 0) {
        this.normalizedAddress.address.adminLevel2 = prov[0].description;
        this.normalizedAddress.address.locality = this.address.adminLevel2;
      }
    }
    this.showNormalize = true;

  }

  back() {
    this.showNormalize = false;
  }

  onNormalize(newAddress) {
    this.addressControlForm.get('country').setValue(newAddress.countryCode);
    this.addressControlForm.get('province').setValue(newAddress.adminLevel2Short);
    this.addressControlForm.get('city').setValue(newAddress.adminLevel3);
    this.addressControlForm.get('zip').setValue(newAddress.cap);
    this.addressControlForm.get('toponym').setValue(newAddress.toponym.description);
    this.addressControlForm.get('placeAddress').setValue(newAddress.placeAddress);
    this.addressControlForm.get('houseNumber').setValue(newAddress.number);
    this.address = Object.assign(this.address, newAddress);
    this.showNormalize = false;
    this.changedSomethings = true;
  }

  private validateForm() {
    const controls = this.addressControlForm.controls;

    this.validationMessages.length = 0;

    if (controls.country.errors && controls.country.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Country is mandatory'));
    }
    if (controls.province.errors && controls.province.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Province is mandatory'));
    }
    if (controls.city.errors && controls.city.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'City is mandatory'));
    }
    if (controls.zip.errors && controls.zip.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Zip code is mandatory'));
    }
    if (controls.toponym.errors && controls.toponym.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Toponym is mandatory'));
    }
    if (controls.placeAddress.errors && controls.placeAddress.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Place address is mandatory'));
    }
    if (controls.houseNumber.errors && controls.houseNumber.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'House number is mandatory'));
    }
    if (controls.addressText.errors && controls.addressText.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Address text is mandatory'));
    }
    this.eventPropagation.emit('layoutChanged');
  }

  private getAdminLevel(countryCode: string, adminLevel: number, filterValue: string): any {
    return this.addressesService.getAdminLevelValues$(countryCode, adminLevel, filterValue)
        .pipe(
            map((response: any) => {
              if (!response.output) {
                return [];
              }
              return response.output.map(c => c['adminLevel' + adminLevel]).filter(p => p !== null && p !== undefined);
            })
        );
  }

}

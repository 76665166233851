<rgi-rx-expansion-panel [expanded]="true" color="default" >
  <rgi-rx-expansion-panel-header>
    <span rgi-rx-expansion-panel-label translate>CONTRACT_CONSULTATION.ADMINISTRATIVE_DATA</span>
  </rgi-rx-expansion-panel-header>
  <div class="rgi-ui-col rgi-ui-grid-3">

    <div class="rgi-ui-col">
      <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.POLICY_NUMBER'" [value]="contract?.numPolicy"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.EFFECTIVE_DATE'" [value]="contract?.effectivePolicyDate"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.COVERAGE_EXPIRY_DATE'" [value]="contract?.coverageEndDate"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.PROPOSAL_VALIDITY_END_DATE'" [value]="contract?.proposalEndDate"></consultation-row>

      <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.AGREEMENT'" [value]="contract?.convention?.description"></consultation-row>
    </div>

    <div class="rgi-ui-col">
      <consultation-row [type]="'TEXT'" [label]="'CONTRACT_CONSULTATION.PROPOSAL_NUMBER'" [value]="contract?.numProposal"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.EXPIRY_DATE'" [value]="contract?.orginalExpirationDate"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.TERMINATION_DATE'" [value]="contract?.rescindibilityDate"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.SETTLEMENT_BLOCK_DATE'" [value]="contract?.receiptBlockDate"></consultation-row>
    </div>

    <div class="rgi-ui-col">
      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.ORIGINAL_EFFECTIVE_DATE'" [value]="contract?.originalEffectiveDate"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.ISSUE_DATE'" [value]="contract?.issueDatePolicy"></consultation-row>

      <consultation-row [type]="'DATE'" [label]="'CONTRACT_CONSULTATION.CANCELLATION_DATE'" [value]="contract?.cancellationDate"></consultation-row>

      <consultation-row [type]="'BOOL'" [label]="'CONTRACT_CONSULTATION.MIGRATED_POLICY'" [value]="contract?.migratedPolicy"></consultation-row>
    </div>
  </div>
</rgi-rx-expansion-panel>

  <div class="rgi-ui-grid rgi-cc-flex-center">
    <div class="rgi-ui-col-9 rgi-cc-flex-center rgi-cc-padding-bottom-0">
      <span class="rgi-ui-title-1 rgi-ui-default" translate>{{title}}</span>
      <div class="rgi-ui-btn">
        <span class="rgi-ui-icon-search rgi-ui-title-2 rgi-ui-info"></span>
      </div>
    </div>
    <div class="rgi-ui-col-3 rgi-ui-title-1 rgi-ui-info rgi-cc-padding-0 rgi-cc-avoid-overflow">
      <b>{{gross  | currency : currencyCode}}</b>
    </div>
  </div>

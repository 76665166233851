import {Injectable} from '@angular/core';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable, of} from 'rxjs';
import {ReIssuePolicyDataBusinessService} from '../re-issue-business-services/re-issue-policy-data-business.service';
import {Variable} from '../../models/domain-models/variable';
import {ReissueAssetNavigationDataI} from './re-issue-asset-state-manager.service';
import {map, mergeMap} from 'rxjs/operators';
import {ReIssuePolicyDataNavigationData, ReIssuePolicyDataState} from './re-issue-policy-data-state';
import {RgiRxLogger} from '@rgi/rx';
import {QuestionnaireFlatI} from '../../models/domain-models/questionnaire-flatI';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {RegulationPremium} from '../../models/pc-portfolio-models/regulation-models/pc-regulation-premium';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {IndexType} from '../../models/domain-models/parameters/index-type';
import {PolicyGenericDto} from "../../models/pc-portfolio-models/policy-generic-dto";
import {TechnicalDetailsForm} from "../../models/domain-models/technicalDetailsForm";


@Injectable({
  providedIn: 'root'
})
export class ReIssuePolicyDataStateManagerService<T extends ReIssuePolicyDataState> extends AbstractStateManager<T> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected policyDataBusinessService: ReIssuePolicyDataBusinessService<T>,
    protected logger: RgiRxLogger
  ) {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newReIssuePolicyDataState();
    this.updateState$(of(this.initPolicyData(st))); // this update the state
  }

  newReIssuePolicyDataState(): T {
    return new ReIssuePolicyDataState(this.activeRoute.key) as T;
  }

  initPolicyData(state: T) {
    const navData = this.activeRoute.getRouteData<ReIssuePolicyDataNavigationData>();
    state.resourceId = navData.resourceId;

    // if (navData.contractorSubject) {
    //   state.contractorSubject = navData.contractorSubject;
    // }

    if (navData.policyTechnicalData) {
      state.policyTechnicalData = navData.policyTechnicalData;
    }

    if (navData.isToDisableFraz !== null) {
      state.isToDisableFraz = navData.isToDisableFraz;
    }

    if (navData.productName && !state.productName) {
      state.productName = navData.productName;
    }

    if (navData.productCode && !state.productCode) {
      state.productCode = navData.productCode;
    }

    if (navData.node && navData.node.description && !state.nodeName) {
      state.nodeName = navData.node.description;
    }

    if (navData.node && navData.node.idSp && !state.nodeId) {
      state.nodeId = navData.node.idSp;
    }

    if (navData.node) {
      state.node = navData.node;
    }

    if (navData.companyId) {
      state.companyId = navData.companyId;
    }

    // if (navData.contractorSubject) {
    //   state.contractorSubject = navData.contractorSubject;
    // }

    if (navData.previousContractor) {
      state.previousContractor = navData.previousContractor;
    }

    if (navData.productId) {
      state.productId = navData.productId;
    }

    if (navData.thirdPersonContact) {
        state.thirdPersonContact = navData.thirdPersonContact;
    }

    state.editMode = navData.editMode;
    state.editFunctionality = navData.editFunctionality;
    state.backFromStep = navData.backFromStep;
    state.isSubstitution = navData.isSubstitution;
    state.authorize = navData.authorize;

    return state;
  }

  public initForm() {
    this.updateState$(
      this.policyDataBusinessService.initForm$(
        this.getState$(), this.getCurrentState().resourceId)
    );
  }

  getAllVariables(): Observable<T> {
    const state = this.getCurrentState();
    return this.policyDataBusinessService.getAllVariables$(state);
  }

  goToNextPage(st: T) {
    const req: ReissueAssetNavigationDataI = {
      resourceId: st.resourceId,
      contractorSubject: st.contractorSubject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: st.lpsData,
      productName: st.productName,
      productId: st.productId,
      branchOffice: st.policyTechnicalData.branchCode,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isSubstitution: st.isSubstitution,
      isSubjectModified: st.isSubjectModified,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      bIntermediateSaving: st.bIntermediateSaving,
      thirdPersonContact: st.thirdPersonContact,
    };
    this.routingService.navigate('re-issue-asset', req, this.activeRoute.id);
  }

  goToAnagSearch() {
    this.routingService.navigate('anag-issue-search-form', {}, this.activeRoute.id);
  }

  putRegulationPremium(regulationPremium: RegulationPremium) {
    const state = this.getCurrentState();
    return this.policyDataBusinessService.putRegulationPremium$(state, regulationPremium).pipe(map(st => {
      this.updateState$(of(st));
      return st.policyTechnicalData.regulationPremiumResponse.messages
    }));
  }

  getAllRegulationTypes() {
    const state = this.getCurrentState();
    return this.policyDataBusinessService.getAllRegulationTypes$(state)
  }

  public updateProposalForm(val: TechnicalDetailsForm) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.updateProposalForm$(state, val)
    );
  }

  putIndexType(indexType: IndexType) {
    const state = this.getCurrentState();
    return this.policyDataBusinessService.putIndexType$(state, indexType);
  }

  getLpsData() {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.getLpsData$(state)
    );
  }

  putLpsData(lpsData: LpsData) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.putLpsData$(state, lpsData)
    );
  }

  getLpsCountries() {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.getLpsCountries$(state)
    );
  }

  public putInstalmentDate(event: any) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.putInstalmentDate(state, event)
    );
  }

  public putPaymentFrequency(event: any) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.putPaymentFrequency(state, event)
    );
  }

  public putAutomaticRenewal(event: any) {
    const state = this.getCurrentState();
    if (state.policyTechnicalData.tacitRenewal.tacitRenewal !== event) {
      this.updateState$(this.policyDataBusinessService.putAutomaticRenewal(state, event));
    }
  }

  public putAgreement(event: any) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.putAgreement(state, event)
    );
  }

  public putUseTax(event: any) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.putUseTax(state, event)
    );
  }

  public putCurrency(event: any) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.putCurrency(state, event)
    );
  }

  public updateProductVariables(val: Array<Variable>) {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.updateProductVariables$(state, val)
    );
  }

  public submitForm(msg: string): Observable<T> {
    const state = this.getCurrentState();
    return this.policyDataBusinessService.submitForm$(state.resourceId, state, msg).pipe(map(st => {
      this.updateState$(of(st));
      return st;
    }));
  }

  putSubject(state: T) {
    this.updateState$(
      this.policyDataBusinessService.putSubject(state)
    );
  }

  public putSelectedSubject(subject: AnagIssueSubject) {
    this.getState$().subscribe(st => {
      st.contractorSubject = subject;
      this.updateState$(
        this.policyDataBusinessService.putSelectedSubject$(st.resourceId, subject, st).pipe(
          mergeMap(st1 => {
            return this.updateVariablesPostSubjectSelection(st1);
          })
        )
      );
    }).unsubscribe();
  }

  public putSelectedCoOwners(subject: AnagIssueSubject) {
    this.getState$().subscribe(st => {
      this.updateState$(
        this.policyDataBusinessService.putSelectedCoOwners$(st.resourceId, subject, st).pipe(
          mergeMap(st1 => {
            return this.updateVariablesPostCoOwnerSelection(st1);
          })
        )
      );
    }).unsubscribe();
  }

  public deleteCoOwner(resourceId: string, subjectId: string) {
   return this.policyDataBusinessService.deleteCoOwners$(resourceId,subjectId).pipe(
        map(() => {
          return true;
        })
      );
  }

  public getCoOwners() {
    const state = this.getCurrentState();
    this.updateState$(
      this.policyDataBusinessService.getCoOwners$(state).pipe(map(coOwners => {
          state.coOwners = coOwners;
          return state;
        }))
    );
  }

  putQuestionnaire(questionnaireFlat: QuestionnaireFlatI) {
    // this.updateState$(
    return this.policyDataBusinessService.putQuestionnaire(this.getCurrentState(), questionnaireFlat);
    // );
  }

  updateVariablesPostSubjectSelection(state: T): Observable<T> {
    this.logger.debug('ReIssuePolicyDataStateManagerService::updateVariablesPostSubjectSelection() - state', state);

    return this.policyDataBusinessService.getAllVariables$(state).pipe(
      map(st => {
        st.variables.forEach(element => {
          if (element.code === '1PROF') {
            if (st.contractorSubject &&
              st.contractorSubject.professionDetail &&
              st.contractorSubject.professionDetail.descrizione
            ) {
              element.value = st.contractorSubject.professionDetail.codice;
            }
          }
        });
        return st;
      })
    );
  }

  updateVariablesPostCoOwnerSelection(state: T): Observable<T> {
    this.logger.debug('ReIssuePolicyDataStateManagerService::updateVariablesPostCoSubjectSelection() - state', state);
    return this.policyDataBusinessService.getAllVariables$(state).pipe(
      map(st => {
        st.variables.forEach(element => {
          if (element.code === '1PROF') {
            if (st.coOwners &&
              st.coOwners[st.coOwners.length-1].professionDetail &&
              st.coOwners[st.coOwners.length-1].professionDetail.descrizione
            ) {
              element.value = st.coOwners[st.coOwners.length-1].professionDetail.codice;
            }
          }
        });
        return st;
      })
    );
  }

  saveProposal(): Observable<T> {
    return this.policyDataBusinessService.saveProposal(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }


  putCoInsurance() {
    return this.policyDataBusinessService.putCoInsuranceQuotas(this.getCurrentState(), this.getCurrentState().coInsurance).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  searchCommonRiskPolicy(policyDto:PolicyGenericDto) {
    return this.policyDataBusinessService.searchCommonRiskPolicy(this.getCurrentState(), policyDto).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  putIndirectCoInsurance(indirectCoInsurance:PolicyGenericDto) {
    const st = this.getCurrentState();
    return this.policyDataBusinessService.putIndirectCoInsurance(st, indirectCoInsurance).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  deleteCommonRiskPolicy(policyNumber:string) {
    return this.policyDataBusinessService.deleteCommonRiskPolicy(this.getCurrentState(), policyNumber).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  deleteAllCommonRiskPolicies() {
    return this.policyDataBusinessService.deleteAllCommonRiskPolicies(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }
  public deleteIndirectCoInsurance(resourceId: string,objectId:number) {
    return this.policyDataBusinessService.deleteIndirectCoInsurance$(this.getCurrentState(),objectId).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  public deleteAllIndirectCoInsurancePolicies() {
    return this.policyDataBusinessService.deleteAllIndirectCoInsurancePolicies$(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  putSecondRiskPolicy(policy:PolicyGenericDto) {
    const st = this.getCurrentState();
    return this.policyDataBusinessService.putSecondRiskPolicy$(st, policy).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  public deleteSecondRiskPolicy(resourceId: string,objectId:number) {
    return this.policyDataBusinessService.deleteSecondRiskPolicy$(this.getCurrentState(),objectId).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  public deleteAllSecondRiskPolicies() {
    return this.policyDataBusinessService.deleteAllSecondRiskPolicies$(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }


  searchLinkedPolicy(policyDto:PolicyGenericDto) {
    return this.policyDataBusinessService.searchLinkedPolicy(this.getCurrentState(), policyDto).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  deleteLinkedPolicy(policyNumber:string) {
    return this.policyDataBusinessService.deleteLinkedPolicy(this.getCurrentState(), policyNumber).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }
  deleteAllLinkedPolicies() {
    return this.policyDataBusinessService.deleteAllLinkedPolicies(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }
  searchComplementaryPolicy(policyDto: PolicyGenericDto) {
    return this.policyDataBusinessService.searchComplementaryPolicy(this.getCurrentState(), policyDto).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  deleteComplementaryPolicy(policyNumber: string) {
    return this.policyDataBusinessService.deleteComplementaryPolicy(this.getCurrentState(), policyNumber).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  deleteComplementaryPolicies() {
    return this.policyDataBusinessService.deleteAllComplementaryPolicies$(this.getCurrentState()).pipe(
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

}

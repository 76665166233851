import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { EMPTY_STR } from '../../models/consts/lpc-consts';
import { SectionInfo } from '../../models/life-detail.model';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

/* Example
<div class="tooltip-example">
      <div tooltip="tootip on left" placement="left" delay="500">tootip on left</div>
      <div tooltip="tootip on top" placement="top" delay="500">tootip on top</div>
      <div tooltip="tootip on bottom" placement="bottom" delay="500">tootip on bottom</div>
      <div tooltip="tootip on right" placement="right" delay="500">tootip on right</div>
</div>
*/


/**
 * @param tooltipTitle: tooltip text
 * @param placement: position where the tooltip is to be displayed (left, top, bottom, right)
 * @param delay: delay of the tooltip display
 */
@Directive({
  selector: '[lpcTooltip]'
})
export class TooltipDirective {
  @Input('lpcTooltip') tooltipTitle: string;
  @Input() placement: string;
  @Input() delay: string;
  @Input() stringOBJ: SectionInfo[];
  @Input() customId = EMPTY_STR;

  public tooltip: HTMLElement;
  public offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2, protected translate: RgiRxTranslationService) { }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) { this.show(); }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) { this.hide(); }
  }

  @HostListener('click') onClick() {
    if (this.tooltip) { this.hide(); }
  }

  public show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  public hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    window.setTimeout(() => {
      if (!!this.tooltip) {
        this.renderer.removeChild(document.body, this.tooltip);
        this.tooltip = null;
      }
    }, +this.delay);
  }

  public create() {
    if (!!this.stringOBJ) {
      if (!!this.stringOBJ && !!this.stringOBJ.length) {
        this.tooltip = this.renderer.createElement('textarea');
        this.stringOBJ.forEach(val => {
          this.renderer.setAttribute(this.tooltip, 'rows', this.stringOBJ.length.toString());
          this.renderer.setAttribute(this.tooltip, 'cols', '30');
          this.renderer.appendChild(this.tooltip, this.renderer.createText(`${val.label} ${val.value} \r\n`));
        });
        this.renderer.setStyle(this.tooltip, 'border', 'none');
        this.renderer.setStyle(this.tooltip, 'overflow', 'hidden');
        this.renderer.setStyle(this.tooltip, 'width', '100%');
        this.renderer.setStyle(this.tooltip, 'resize', 'none');
      }
    } else {
      this.tooltip = this.renderer.createElement('span');
      this.translate.translate(this.tooltipTitle).subscribe(value => {
        this.renderer.appendChild(this.tooltip, this.renderer.createText(value)); // textNode
      });
    }


    this.renderer.appendChild(document.body, this.tooltip);
    // this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
    this.renderer.setAttribute(this.tooltip, 'id', this.customId);

    // delay
    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  public setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // eslint-disable-next-line one-var
    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}

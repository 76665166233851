import {NgModule} from "@angular/core";
import {SurveyEditComponent} from "./survey-edit/survey-edit.component";
import {SurveyEvaluateComponent} from "./survey-evaluate/survey-evaluate.component";
import {FormSurveyEditComponent} from "./form-survey-edit/form-survey-edit.component";
import {CommonModule} from "@angular/common";
import {SurveyModule} from "../survey/survey.module";
import {NavigationModule, NotificationModule, PanelModule} from '@rgi/ng-passpropro-core';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PassproQuestionnaireModule} from '@rgi/ng-passpro';
import { SurveyEvalProductComponent } from './survey-evaluate/components/survey-eval-product/survey-eval-product.component';
import { SurveyEvalSectionComponent } from './survey-evaluate/components/survey-eval-section/survey-eval-section.component';
import { SurveyEvalCoverageComponent } from './survey-evaluate/components/survey-eval-coverage/survey-eval-coverage.component';
import { SurveyEvalInfoComponent } from './survey-evaluate/components/survey-eval-info/survey-eval-info.component';
import { SurveyEvalScoreComponent } from './survey-evaluate/components/survey-eval-score/survey-eval-score.component';
import { SurveyEvalMarketTreeComponent } from './survey-evaluate/components/survey-eval-market-tree/survey-eval-market-tree.component';
import { SurveyEvalRuleTreeComponent } from './survey-evaluate/components/survey-eval-rule-tree/survey-eval-rule-tree.component';
import { SurveyEvalProductsComponent } from './survey-evaluate/components/survey-eval-products/survey-eval-products.component';
import { StateManagerSurveyModule } from '../state/state-manager-survey.module';
import { SurveyEvalProductBarComponent } from './survey-evaluate/components/survey-eval-product-bar/survey-eval-product-bar.component';
import {RgiRxEventModule, RgiRxExtensionModule} from "@rgi/rx";
import {RgiRxModalModule, RgiRxPanelModule, RgiRxStickableModule, RgiRxTableModule} from "@rgi/rx/ui";
import { SurveyEvalHeaderComponent } from "./survey-evaluate/components/survey-eval-header/survey-eval-header.component";
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import { SurveyEvalDocumentModalComponent } from "./survey-evaluate/components/survey-eval-document-modal/survey-eval-document-modal.component";

@NgModule({
    declarations: [
        SurveyEditComponent,
        SurveyEvaluateComponent,
        FormSurveyEditComponent,
        SurveyEvalProductComponent,
        SurveyEvalSectionComponent,
        SurveyEvalCoverageComponent,
        SurveyEvalInfoComponent,
        SurveyEvalScoreComponent,
        SurveyEvalMarketTreeComponent,
        SurveyEvalRuleTreeComponent,
        SurveyEvalProductsComponent,
        SurveyEvalProductBarComponent,
        SurveyEvalHeaderComponent,
        SurveyEvalDocumentModalComponent
    ],
    exports: [
        SurveyEditComponent,
        SurveyEvaluateComponent,
        FormSurveyEditComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RgiRxEventModule,
        SurveyModule,
        PassproQuestionnaireModule,
        NavigationModule,
        RgiRxI18nModule,
        PanelModule,
        RgiRxModalModule,
        StateManagerSurveyModule,
        RgiRxStickableModule,
        RgiRxExtensionModule,
        RgiRxPanelModule,
        NotificationModule,
        RgiRxTableModule
    ],
    providers: []
})
class SurveyComponentsModule { }

export {
  SurveyComponentsModule,
  SurveyEditComponent,
  SurveyEvaluateComponent,
  FormSurveyEditComponent
}

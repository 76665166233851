import { Inject, Injectable } from '@angular/core';
import { UserService } from '@rgi/rx/auth';
import { ClaimDetail, ClaimParties } from '../../../shared/dsc-shared-model/dsc-model-claims';
import { InjuryAsset } from '../../../shared/dsc-shared-model/dsc-model-injury';
import { AddressEntityData } from '../../../shared/dsc-shared-model/dsc-model-address';
import { Heir } from '../../../shared/dsc-shared-model/dsc-model-subject';
import { CaiOpeningSingle, CaiOpeningState } from '../../config-model/cai-opening-config.module';
import { PTFALL_SUBJECT_ROLES_NEW, assetsList$ } from './cai-opening.model.config';


@Injectable()
export class CaiOpeningPartyUtility {
  private baseApiUrlV2: string;
  private baseApiUrl: string;
  public authSrv: any;

  constructor(
    protected userService: UserService,
    @Inject('authService') authService,
    @Inject('environment') environment: any,
    @Inject('enumService') private enumService: any
  ) {
    this.baseApiUrlV2 =
      environment.api.portal.host + environment.api.portal.path + '/v2/';
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.authSrv = authService;
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    miaData.setHours(0);
    miaData.setMinutes(0);
    miaData.setSeconds(0);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    return miaData.toISOString();
    /* const dataOutput = this.datePipe.transform(miaData, 'yyyy-MM-dd hh:mm:ss');
    return dataOutput; */
  }

  setClaimDetail(currState: CaiOpeningState, IClaim: ClaimDetail): Promise<ClaimDetail> {
    return new Promise<ClaimDetail>((resolve) => {
      const policyData$: any = {
        company: {
          damagesPortfolio: true,
          lifePortfolio: false,
        },
      };
      const newClaim = IClaim;
      const selectedCoverture = IClaim.selectedCoverture;
      const contract = IClaim.contract;
      const contractDetail = IClaim.contractDetail;

      newClaim.reportDate = this.formatDateTimezone(currState.detailNewClaimInfo.occurrenceDate.toISOString());
      newClaim.occurrenceDate = this.formatDateTimezone(currState.detailNewClaimInfo.occurrenceDate.toISOString());
      newClaim.occurrenceDate = IClaim.occurrenceDate;
      newClaim.occurrenceHour = IClaim.occurrenceHour;
      if (selectedCoverture && selectedCoverture !== null) {
        newClaim.claimBranch =
          selectedCoverture.covertureBranch.claimBranchDescription;
        newClaim.claimBranchCodes =
          selectedCoverture.covertureBranch.claimBranchCode;
        newClaim.ministerialBranchCodes =
          selectedCoverture.covertureBranch.ministerialBranchCode;
        newClaim.coverTypeCode =
          selectedCoverture.covertureType.covertureTypeCode;
      }
      if (contract && contract !== null) {
        newClaim.contractor = contract.contractingSubject;
        newClaim.intermediary = contract.nodeDescription;
        newClaim.policyCancellationDate = contract.cancellationDate;
        newClaim.policyNumber = contract.policyNumber;
        newClaim.productType = contract.product.description;

        policyData$.effectDate = contract.effectDate;
        policyData$.expirationDate = contract.expirationDate;
        policyData$.policyNumber = contract.policyNumber;
      }
      if (contractDetail && contractDetail !== null) {
        newClaim.aniaCompanyCode = contractDetail.company.code;
        newClaim.contractorUniqueId = contractDetail.contractor.subject.vat
          ? contractDetail.contractor.subject.vat
          : contractDetail.contractor.subject.fiscalCode;
        newClaim.policyNode = contractDetail.managementNode.code;
        newClaim.policyType = contractDetail.policyType.descrizione;
        newClaim.insuredVehiclePlate =
          contractDetail.assets[0].vehicle[0].licencePlateNumber;
        newClaim.vehicleManufacturerAndModel =
          contractDetail.assets[0].vehicle[0].modelDescription;
        newClaim.contractorDateOfBirth =
          contractDetail.contractor.subject.dateOfBirth;
        newClaim.coverageEndDate = contractDetail.coverageEndDate;
        newClaim.coinsuranceType = contractDetail.coinsuranceType.codice;
        newClaim.product = contractDetail.product.description;
        newClaim.subscriptorFiscalCode =
          contractDetail.contractor.subject.fiscalCode;
        newClaim.policyIssueDate = contractDetail.issueDate;

        policyData$.company.name = contractDetail.company.description;
        policyData$.companyCode = contractDetail.company.code;

        contractDetail.assets.forEach((asset) => {
          if (asset.vehicle) {
            policyData$.greenCardNumber = asset.vehicle.greenCardNumber;
            const party: any = {
              partyType: '1',
            };
            const damageDescription: any = {
              asset: {
                vehicle: {
                  chassisNumber: asset.vehicle[0].chassisNumber,
                  directlyInvolvedInCollision: true,
                  firstRegistrationDate: asset.vehicle[0].registrationDate,
                  licensePlateNumber: asset.vehicle[0].licencePlateNumber,
                  licensePlateType: asset.vehicle[0].licencePlateType,
                  manufacturerDescription: asset.vehicle[0].brandDescription,
                  modelDescription: asset.vehicle[0].modelDescription
                },
                policyData: policyData$,
              },
              ministerialBranch: {
                ministerialBranch: {
                  code: !currState.reopenFromJs.isReopenedFromJs ? currState.listCovertureBranches.claimBranchCode
                    : currState.reopenFromJs.returnClaim.ministerialBranch,
                  description: !currState.reopenFromJs.isReopenedFromJs ? currState.listCovertureBranches.claimBranchCode
                    : currState.reopenFromJs.returnClaim.ministerialBranch
                }
              },
              assetType: asset.asset.code,
            };

            damageDescription.enumAssetType = this.enumService.getEnum(
              'sinistri.TipoBene',
              '1'
            );
            damageDescription.asset.assetType = this.enumService.getEnum(
              'sinistri.TipoBene',
              '1'
            );
            damageDescription.asset.policyHolder = this._adaptSubject$(
              contractDetail.contractor
            );
            damageDescription.asset.vehicle.caiposition =
              this.enumService.getEnum('sinistri.CAIPosition', '1');

            if (
              damageDescription.asset.vehicle.licensePlateType &&
              damageDescription.asset.vehicle.licensePlateType.codice !== '4'
            ) {
              damageDescription.asset.vehicle.licensePlateCountryCode = newClaim.context ? newClaim.context : this.authSrv.getDefaultContext();
            }

            if (asset.asset && asset.asset.code) {
              const ppAsset = this._getPPAsset$(asset.asset.code);

              if (ppAsset) {
                damageDescription.asset.ppAsset = {
                  code: ppAsset.code,
                  description: ppAsset.description,
                };

                damageDescription.asset.vehicle.aniaCode = ppAsset.aniaCode;
              } else {
                damageDescription.asset.ppAsset = {
                  code: asset.asset.code,
                  description: asset.description,
                };
              }
            }

            asset.risks.forEach((risk) => {
              if (risk && (risk.risk.description.indexOf('Responsabilità Civile') !== -1 || risk.risk.description.indexOf('Mandatory Third Party Liability') !== -1)) {
                damageDescription.risk = risk.risk.code;
              }
            });

            asset.subjects.forEach((subject) => {
              if (
                subject.roleType.codice ===
                PTFALL_SUBJECT_ROLES_NEW.PROPRIETARIO_VEICOLO &&
                subject.principal
              ) {
                party.subject = this._adaptSubject$(subject);
              } else if (
                subject.roleType.codice === PTFALL_SUBJECT_ROLES_NEW.CONDUCENTE
              ) {
                damageDescription.asset.vehicle.driver =
                  this._adaptSubject$(subject);
              }
            });

            party.damageDescriptions = [damageDescription];
            newClaim.claimParties = [party];

            this._setPartyRole$(party, true);
          }
        });
        /*
        *  controparte
        * */
        const caiPosition = this.enumService.getEnum('sinistri.CAIPosition', '2');
        const counterpartParty = this._createCounterpartParty$(
          newClaim.claimParties[0].damageDescriptions[0].ministerialBranch, caiPosition);

        newClaim.claimParties.push(counterpartParty);

      }
      return resolve(newClaim);
    });
  }

  _getPPAsset$(code) {
    const filtered$ = assetsList$.filter((ppAsset) => ppAsset.code === code);
    return filtered$.length ? filtered$[0] : null;
  }

  _adaptSubject$(ptfSubject) {
    if (ptfSubject) {
      const claimSubject: any = {
        anagSubject: ptfSubject.subject,
        fiscalCode: ptfSubject.subject.fiscalCode,
        gender: ptfSubject.subject.sex,
        givenName: ptfSubject.subject.name,
        objectId: ptfSubject.subject.objectId,
        surname: ptfSubject.subject.surname,
        tipoSoggetto: ptfSubject.subject.subjectType,
        vatCode: ptfSubject.subject.vat,
      };

      claimSubject.enumSubjectType = this.enumService.getEnum(
        'sinistri.TipoSoggetto',
        ptfSubject.subject.subjectType.codice
      );

      return claimSubject;
    }
  }
  _createCounterpartParty$(IMinisterialBranch, caiPosition) {
    const party = {
      damageDescriptions: [
        {
          asset: {
            assetType: {
              codice: '1',
            },
            vehicle: {
              caiposition: caiPosition,
            },
          },
          ministerialBranch: IMinisterialBranch
        },
      ],
    };

    this._setPartyRole$(party, false);
    return party;
  }

  _setPartyRole$(party, isCompanyVehicle) {
    if (!party) {
      return;
    }
    const vehicleTypeOfLoss = this._getVehicleTypeOfLoss$(party);
    const driver =
      vehicleTypeOfLoss &&
        vehicleTypeOfLoss.asset &&
        vehicleTypeOfLoss.asset.vehicle
        ? vehicleTypeOfLoss.asset.vehicle.driver
        : null;
    const partySubject = party.subject;
    const isDriver =
      partySubject && driver && partySubject.objectId === driver.objectId;
    /*
        -CONDUCENTE PROPRIETARIO NOSTRO VEICOLO = 3
        -PROPRIETARIO NON PRESENTE NOSTRO VEICOLO = 9

        -CONDUCENTE PROPRIETARIO VEICOLO CONTROPARTE = 4
        -PROPRIETARIO NON PRESENTE CONTROPARTE = 10
        */
    let enumCode;
    if (isDriver) {
      enumCode = isCompanyVehicle ? '3' : '4';
    } else {
      enumCode = isCompanyVehicle ? '9' : '10';
    }
    party.partyRole = this.enumService.getEnum(
      'sinistri.RuoloAttore',
      enumCode
    );
  }

  _getVehicleTypeOfLoss$(party) {
    return this._getTypeOfLossByAssetTypeCode$(party, '1');
  }
  _getTypeOfLossByAssetTypeCode$(party, assetTypeCode) {
    return (
      party &&
      party.damageDescriptions.find(
        (damageDescription) =>
          damageDescription.asset &&
          damageDescription.asset.assetType.codice === assetTypeCode
      )
    );
  }

  setClaimFromState(IClaim: ClaimDetail, reopenFromJs: boolean): Promise<CaiOpeningSingle> {
    return new Promise(resolve => {
      let newContractDescription = '';
      if (IClaim.contract && IClaim.contract.description) {
        newContractDescription = IClaim.contract.description;
      }
      const openingSingle = new CaiOpeningSingle();
      const claimParties = IClaim.claimParties;
      const newClaim = claimParties[0];
      const asset = newClaim.damageDescriptions[0].asset;
      let policyHolder = asset.policyHolder;
      let owner = newClaim.subject;
      const contractorResidence = IClaim.contract.contractor.subject.residence;
      let searchOwner = IClaim.claimParties.filter(res => res.partyRole.codice === '9');
      if (searchOwner.length > 0) {
        const indexOwner = IClaim.claimParties.findIndex(res => res.partyRole.codice === '9');
        owner = IClaim.claimParties[indexOwner].subject;
        // estrazione bodyshop
        const indexDamage = searchOwner[indexOwner].damageDescriptions.findIndex(
          res => res.assetType === 'Veicoli' || res.assetType === '000001' || res.enumAssetType?.codice === '1');
        const damageDescription = searchOwner[indexOwner].damageDescriptions[indexDamage];
        if (damageDescription) {
          const carBodyworkDriver = damageDescription.asset.vehicle.bodyShopSubject ?
            damageDescription.asset.vehicle.bodyShopSubject : undefined;
          if (carBodyworkDriver) {
            openingSingle.carBodywork = {
                objectId: damageDescription.asset.vehicle.bodyShopSubject.objectId,
                designation: carBodyworkDriver.designation ? carBodyworkDriver.designation : '',
                givenName: carBodyworkDriver.givenName ? carBodyworkDriver.givenName : '',
                surname: carBodyworkDriver.surname ? carBodyworkDriver.surname : '',
                fiscalCode: carBodyworkDriver.fiscalCode ? carBodyworkDriver.fiscalCode : '',
                vatCode: carBodyworkDriver.vatCode ? carBodyworkDriver.vatCode : '',
                phoneNumber: carBodyworkDriver.claimsAddress.phoneNumber ? carBodyworkDriver.claimsAddress.phoneNumber : '',
                email: carBodyworkDriver.claimsAddress.emailAddress ? carBodyworkDriver.claimsAddress.emailAddress : '',
                claimsAddress: carBodyworkDriver.claimsAddress ? carBodyworkDriver.claimsAddress : null,
                code: carBodyworkDriver.bodyShopCode ? carBodyworkDriver.bodyShopCode : ''
              };
          }
        }
      } else {
        searchOwner = IClaim.claimParties.filter(res => res.partyRole.codice === '7');
        if (owner.length > 0) {
          const indexOwner = IClaim.claimParties.findIndex(res => res.partyRole.codice === '7');
          owner = IClaim.claimParties[indexOwner].subject;
          const indexDamage = searchOwner[indexOwner].damageDescriptions.findIndex(
            res => res.assetType === 'Veicoli' || res.assetType === '000001' || res.enumAssetType?.codice==='1');
          const damageDescription = searchOwner[indexOwner].damageDescriptions[indexDamage]; 
          if (damageDescription) {
          const carBodyworkDriver = damageDescription.asset.vehicle.bodyShopSubject ?
            damageDescription.asset.vehicle.bodyShopSubject : undefined;
            if (carBodyworkDriver) {
              openingSingle.carBodywork = {
                objectId: damageDescription.asset.vehicle.bodyShopSubject.objectId,
                designation: carBodyworkDriver.designation ? carBodyworkDriver.designation : '',
                givenName: carBodyworkDriver.givenName ? carBodyworkDriver.givenName : '',
                surname: carBodyworkDriver.surname ? carBodyworkDriver.surname : '',
                fiscalCode: carBodyworkDriver.fiscalCode ? carBodyworkDriver.fiscalCode : '',
                vatCode: carBodyworkDriver.vatCode ? carBodyworkDriver.vatCode : '',
                phoneNumber: carBodyworkDriver.claimsAddress.phoneNumber ? carBodyworkDriver.claimsAddress.phoneNumber : '',
                email: carBodyworkDriver.claimsAddress.emailAddress ? carBodyworkDriver.claimsAddress.emailAddress : '',
                claimsAddress: carBodyworkDriver.claimsAddress ? carBodyworkDriver.claimsAddress : null,
                code: carBodyworkDriver.bodyShopCode ? carBodyworkDriver.bodyShopCode : ''
              };
            }
          }
        }
      }
      let driver;
      let searchDriver = IClaim.claimParties.filter(res => res.partyRole.codice === '3');
      if (searchDriver.length > 0) {
        const indexDriver = IClaim.claimParties.findIndex(res => res.partyRole.codice === '3');
        driver = IClaim.claimParties[indexDriver].subject;
        const indexDamage = IClaim.claimParties[indexDriver].damageDescriptions.findIndex(
          res => res.asset.assetType.codice === '1' || res.assetType === '000001');
        const carBodyworkDriver = IClaim.claimParties[indexDriver].damageDescriptions[indexDamage].asset.vehicle.bodyShopSubject ?
          IClaim.claimParties[indexDriver].damageDescriptions[indexDamage].asset.vehicle.bodyShopSubject : undefined;
        if (carBodyworkDriver) {
          openingSingle.carBodywork = {
            objectId: IClaim.claimParties[indexDriver].damageDescriptions[indexDamage].asset.vehicle.bodyShopSubject.objectId,
            designation: carBodyworkDriver.designation ? carBodyworkDriver.designation : '',
            givenName: carBodyworkDriver.givenName ? carBodyworkDriver.givenName : '',
            surname: carBodyworkDriver.surname ? carBodyworkDriver.surname : '',
            fiscalCode: carBodyworkDriver.fiscalCode ? carBodyworkDriver.fiscalCode : '',
            vatCode: carBodyworkDriver.vatCode ? carBodyworkDriver.vatCode : '',
            phoneNumber: carBodyworkDriver.claimsAddress.phoneNumber ? carBodyworkDriver.claimsAddress.phoneNumber : '',
            email: carBodyworkDriver.claimsAddress.emailAddress ? carBodyworkDriver.claimsAddress.emailAddress : '',
            claimsAddress: carBodyworkDriver.claimsAddress ? carBodyworkDriver.claimsAddress : null,
            code: carBodyworkDriver.bodyShopCode ? carBodyworkDriver.bodyShopCode : ''
          };
        }
        openingSingle.driver = {
          objectId: driver.objectId,
          givenName: driver.givenName,
          surname: driver.surname,
          nominative: driver.anagSubject.nominative,
          fiscalCode: driver.fiscalCode,
          vatCode: driver.vatCode,
          formatAddress: driver.anagSubject.residence.formatAddress,
          number: driver.anagSubject.mobilePhone.length > 0 ? driver.anagSubject.mobilePhone[0] : '',
          email: driver.anagSubject.emails.length > 0 ? driver.anagSubject.emails[0] : '',
          chkMaterial: driver.chkMaterial,
          materialDescription: driver.materialDescription,
          chkInjury: driver.chkInjury,
          subjectDataFormInjury: driver.chkInjury && driver.subjectInjuryAsset && driver.subjectInjuryAsset !== null ?
            driver.subjectInjuryAsset : new InjuryAsset(),
          subject: driver ? driver : null,
          isMinor: driver.anagSubject.dateOfBirth ? this.calculateIfMinorFromdateOfBirth(driver.anagSubject.dateOfBirth) : false,
          isIncapacitated: driver.isIncapacitated ? driver.isIncapacitated : false,
          listMinorGuardianSubject: driver.listMinorGuardianSubject ? driver.listMinorGuardianSubject : []
        };
        openingSingle.roles.driver.objectId = openingSingle.driver.objectId;
        openingSingle.driver.isOwner = openingSingle.driver.objectId === openingSingle.owner.objectId;
        // setto heir a false se non già settato dalla response
        openingSingle.driver.subject.heir ? driver.subject.heir : false;
          // setto i rispettivi nodeId per la lista dei potenziali eredi
      openingSingle.heirsList.driver.nodeId =  driver.anagSubject.node && driver.anagSubject.node.identification ? driver.anagSubject.node.identification : '';

      } else {
        searchDriver = IClaim.claimParties.filter(res => res.partyRole.codice === '1');
        if (searchDriver.length > 0) {
          const indexDriver = IClaim.claimParties.findIndex(res => res.partyRole.codice === '1');
          driver = IClaim.claimParties[indexDriver].subject;
          openingSingle.driver = {
            objectId: driver.objectId,
            givenName: driver.givenName,
            surname: driver.surname,
            nominative: driver.anagSubject.nominative,
            fiscalCode: driver.fiscalCode,
            vatCode: driver.vatCode,
            formatAddress: driver.anagSubject.residence.formatAddress,
            number: driver.anagSubject.mobilePhone.length > 0 ? driver.anagSubject.mobilePhone[0] : '',
            email: driver.anagSubject.emails.length > 0 ? driver.anagSubject.emails[0] : '',
            chkMaterial: driver.chkMaterial,
            materialDescription: driver.materialDescription,
            chkInjury: driver.chkInjury,
            subjectDataFormInjury: driver.chkInjury && driver.subjectInjuryAsset && driver.subjectInjuryAsset !== null ?
              driver.subjectInjuryAsset : new InjuryAsset(),
            subject: driver ? driver : null,
            isMinor: driver.anagSubject.dateOfBirth? this.calculateIfMinorFromdateOfBirth(driver.anagSubject.dateOfBirth) : false,
            isIncapacitated: driver.isIncapacitated ? driver.isIncapacitated : false,
            listMinorGuardianSubject: driver.listMinorGuardianSubject ? driver.listMinorGuardianSubject : []
          };
          openingSingle.roles.driver.objectId = openingSingle.driver.objectId;
          openingSingle.driver.isOwner = openingSingle.driver.objectId === openingSingle.owner.objectId;
          // setto heir a false se non già settato dalla response
          openingSingle.driver.subject.heir ? driver.subject.heir : false;
          openingSingle.heirsList.driver.nodeId =  driver.anagSubject.node && driver.anagSubject.node.identification ? driver.anagSubject.node.identification : '';
        } else {
          driver = asset.vehicle.driver;
          if (driver) {
            openingSingle.driver = {
              objectId: driver.objectId,
              givenName: driver.givenName,
              surname: driver.surname,
              nominative: driver.anagSubject.nominative,
              fiscalCode: driver.fiscalCode,
              vatCode: driver.vatCode,
              formatAddress: driver.anagSubject.residence.formatAddress,
              number:  driver.anagSubject.mobilePhone && driver.anagSubject.mobilePhone.length > 0 ? driver.anagSubject.mobilePhone[0] : '',
              email: driver.anagSubject.emails && driver.anagSubject.emails.length > 0 ? driver.anagSubject.emails[0] : '',
              chkMaterial: driver.chkMaterial,
              materialDescription: driver.materialDescription,
              chkInjury: driver.chkInjury,
              subjectDataFormInjury: driver.chkInjury && driver.subjectInjuryAsset && driver.subjectInjuryAsset !== null ?
                driver.subjectInjuryAsset : new InjuryAsset(),
              subject: driver ? driver : null,
            };
          }
        }
      }
      /*  policyHolder.subjectDataFormInjury = policyHolder.subjectDataFormInjury && policyHolder.subjectDataFormInjury !== null ?
       policyHolder.subjectDataFormInjury : new InjuryAsset();
       owner.subjectDataFormInjury = owner.subjectDataFormInjury && owner.subjectDataFormInjury !== null ?
       owner.subjectDataFormInjury : new InjuryAsset();
       driver.subjectDataFormInjury = driver.subjectDataFormInjury && driver.subjectDataFormInjury !== null ?
       driver.subjectDataFormInjury : new InjuryAsset(); */
      const dataInjury = {
        claim: newClaim,
        idDamageCategory: IClaim.idDamageCategory,
        policyIssueDate: IClaim.policyIssueDate,
        contractDescription: newContractDescription
      };

      if (policyHolder) {
        if (owner && (policyHolder.objectId === owner.objectId)) {
          policyHolder = { ...owner };
        }
        if (driver && (policyHolder.objectId === driver.objectId && policyHolder !== owner.objectId)) {
          policyHolder = { ...driver };
        }

        openingSingle.policyHolder = {
          objectId: policyHolder.objectId,
          givenName: policyHolder.givenName,
          surname: policyHolder.surname,
          nominative: policyHolder.anagSubject.nominative,
          fiscalCode: policyHolder.fiscalCode,
          vatCode: policyHolder.vatCode,
          formatAddress: policyHolder.anagSubject.residence.formatAddress,
          number: policyHolder.anagSubject.mobilePhone.length > 0 ? policyHolder.anagSubject.mobilePhone[0] : '',
          email: policyHolder.anagSubject.emails.length > 0 ? policyHolder.anagSubject.emails[0] : '',
          isMinor: policyHolder.anagSubject.dateOfBirth ? this.calculateIfMinorFromdateOfBirth(policyHolder.anagSubject.dateOfBirth) : false,
          chkMaterial: policyHolder.chkMaterial,
          materialDescription: policyHolder.materialDescription,
          chkInjury: policyHolder.chkInjury,
          subjectDataFormInjury: policyHolder.chkInjury && policyHolder.subjectInjuryAsset && policyHolder.subjectInjuryAsset !== null ?
            policyHolder.subjectInjuryAsset : new InjuryAsset(),
          subject: policyHolder,
          isOwner: true,
          isIncapacitated: policyHolder.isIncapacitated ? policyHolder.isIncapacitated : false,
          listMinorGuardianSubject: policyHolder.listMinorGuardianSubject ? policyHolder.listMinorGuardianSubject : []
        };
        // setto heir a false se non già settato dalla response
        openingSingle.policyHolder.subject.heir ? policyHolder.subject.heir : false;
        openingSingle.roles.holder.objectId = openingSingle.policyHolder.objectId;
        openingSingle.heirsList.policyHolder.nodeId = policyHolder.anagSubject.node && policyHolder.anagSubject.node.identification ? policyHolder.anagSubject.node.identification : '';
      }
      if (asset.vehicle) {
        openingSingle.damagedParts = {
          ...asset.vehicle
        };
        openingSingle.vehicle = {
          ...asset.vehicle,
          ppAsset: {
            description: asset.ppAsset.description
          },
          description: asset.description,
        };
      }
      if (contractorResidence) {
        openingSingle.propertyAddress = {
          country: contractorResidence.country !== null ? contractorResidence.country.codice : 'IT',
          countryCode: contractorResidence.countryCode ? contractorResidence.countryCode : 'IT',
          postCode: contractorResidence.cap,
          city: contractorResidence.city,
          cityCode: '',
          address: contractorResidence.placeAddress,
          houseNumber: contractorResidence.number,
          adminLevel1: contractorResidence.adminLevel1,
          adminLevel2: contractorResidence.adminLevel2,
          adminLevel3: contractorResidence.adminLevel3,
          toponym: contractorResidence.toponym,
          province: contractorResidence.province !== null ? contractorResidence.province.codice : '',
          isValidForm: false,
          editability: true,
          umaAddress: null,
          isRequiredAddress: false,
          isRequiredHouseNumber: false
        }
        if (this.checkPropertyExists(openingSingle.propertyAddress)) {
          openingSingle.propertyAddress.isValidForm = true
        } else {
          openingSingle.propertyAddress = new AddressEntityData();
        }
      } else {
        openingSingle.propertyAddress = new AddressEntityData();
      }
      if (IClaim.companyDetailObject && IClaim.companyDetailObject !== null) {
        openingSingle.insuranceCompany = IClaim.companyDetailObject;
      }
      /*   if (asset.policyData) {
          openingSingle.insuranceCompany = {
            ...asset.policyData
          };
        } */
      if (owner) {
        openingSingle.owner = {
          objectId: owner.objectId,
          givenName: owner.givenName,
          surname: owner.surname,
          nominative: owner.anagSubject.nominative,
          fiscalCode: owner.fiscalCode,
          vatCode: owner.vatCode,
          formatAddress: owner.anagSubject.residence.formatAddress,
          number: owner.anagSubject.mobilePhone.length > 0 ? owner.anagSubject.mobilePhone[0] : '',
          email: owner.anagSubject.emails.length > 0 ? owner.anagSubject.emails[0] : '',
          isMinor: owner.anagSubject.dateOfBirth ? this.calculateIfMinorFromdateOfBirth(owner.anagSubject.dateOfBirth) : false,
          chkMaterial: owner.chkMaterial,
          materialDescription: owner.materialDescription,
          chkInjury: owner.chkInjury,
          subjectDataFormInjury: owner.chkInjury && owner.subjectInjuryAsset && owner.subjectInjuryAsset !== null ?
            owner.subjectInjuryAsset : new InjuryAsset(),
          isPassenger: reopenFromJs ? this.isPassenger(newClaim.partyRole.codice) : false,
          subject: newClaim.subject ? newClaim.subject : null,
          isOwner: true,
          isIncapacitated: owner.isIncapacitated ? owner.isIncapacitated : false,
          listMinorGuardianSubject: owner.listMinorGuardianSubject ? owner.listMinorGuardianSubject : []
        };
        // setto heir a false se non già settato dalla response
        openingSingle.owner.subject.heir ? owner.subject.heir : false;
        openingSingle.roles.owner.objectId = openingSingle.owner.objectId;
        openingSingle.policyHolder.isOwner = openingSingle.policyHolder.objectId === openingSingle.owner.objectId;
        openingSingle.heirsList.owner.nodeId = owner.anagSubject.node && owner.anagSubject.node.identification ? owner.anagSubject.node.identification : '';
      }
      // setto i rispettivi id per la lista degli eredi
      openingSingle.heirsList.driver.objectId = openingSingle.driver.objectId;
      openingSingle.heirsList.policyHolder.objectId = openingSingle.policyHolder.objectId;
      openingSingle.heirsList.owner.objectId = openingSingle.owner.objectId;
      openingSingle.claim = newClaim;
      openingSingle.dataInjury = dataInjury;
      openingSingle.roles.partyRole = newClaim.partyRole;
      if (reopenFromJs) {
        if (openingSingle.policyHolder.objectId !== '' && openingSingle.policyHolder.objectId === openingSingle.roles.owner.objectId) {
          openingSingle.policyHolder.isOwner = true;
          openingSingle.policyHolder.isPassenger = openingSingle.owner.isPassenger;
        }
        if (openingSingle.policyHolder.objectId !== '' && openingSingle.policyHolder.objectId === openingSingle.roles.driver.objectId) {
          openingSingle.policyHolder.isDriver = true;
        }
        if (openingSingle.owner.objectId !== '' && openingSingle.owner.objectId === openingSingle.roles.driver.objectId) {
          openingSingle.owner.isDriver = true;
        }
        openingSingle.propertyAddress = asset.address;
        const heirInParties = IClaim.claimParties.filter((party: ClaimParties) => party.subject.heir === true);
        const heirInNotInvolvedParties = IClaim.claimNotInvolvedParties.filter((party: ClaimParties) => party.subject.heir === true);
        const totalHeir = heirInParties.concat(heirInNotInvolvedParties);
        if (totalHeir.length > 0) {
          openingSingle.heirsList.policyHolder.potentialHeirs = this.mapHeirsList(totalHeir, openingSingle.policyHolder.subject.heirsList)
          openingSingle.heirsList.owner.potentialHeirs = this.mapHeirsList(totalHeir, openingSingle.owner.subject.heirsList)
          openingSingle.heirsList.driver.potentialHeirs = this.mapHeirsList(totalHeir, openingSingle.driver.subject.heirsList)
        }
      }
      resolve(openingSingle);
    });
  }

  checkPropertyExists(propertyAddress: AddressEntityData): boolean {
    for (let key in propertyAddress) {
      if (key !== 'adminLevel1' &&
        key !== 'adminLevel2' &&
        key !== 'adminLevel3' &&
        key !== 'editability' &&
        key !== 'country' &&
        key !== 'countryCode' &&
        propertyAddress[key] !== null &&
        propertyAddress[key] !== false &&
        propertyAddress[key] !== '') {
        return true;
      }
    }
    return false;
  }

  isPassenger(subjectRole: string): boolean {
    const passengerRole = [
      '5', // TRASPORTATO NON PROPRIETARIO NOSTRO VEICOLO
      '7', // TRASPORTATO PROPRIETARIO NOSTRO VEICOLO
    ];
    return passengerRole.includes(subjectRole);
  }

  updateValClaim(IClaim: ClaimDetail): Promise<ClaimDetail> {
    return new Promise((resolve) => {
      /*
            aggiungere metodi per aggiornare il claim del service
      resolve(IClaim);
      */
    });
  }

  calculateIfMinorFromdateOfBirth(birthday) {
    const dateOfBirth = new Date(birthday);
    const ageDifMs = Date.now() - dateOfBirth.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
  }

  mapHeirsList(heir: ClaimParties[], heirsList: string[]): Heir[] {


    const heirFromResponse = heir.filter((element: ClaimParties) => heirsList.includes(element.subject.anagSubject.objectId))
    return heirFromResponse.map(item => (
      {
        notes: null,
        linkSubjectNominative: item.subject.anagSubject.nominative,
        linkType: null,
        linkedPartyUniqueKey: '',
        linkedPartyObjectId: item.subject.anagSubject.objectId,
        idSubjectLinkType: '',
        checked: true
      }
    ))
  }
}

import {Component} from '@angular/core';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {map} from 'rxjs/operators';
import {GroupPolicyResourceService} from '../../../group-policy-resources/group-policy-resource.service';
import {GroupPolicyIntegrationService} from '../../../group-policy-services/group-policy-integration.service';
import {GroupPolicyInquiryComponent} from '../../group-policy-inquiry.component';
import { GROUP_POLICY_MODE_FUNCTIONS } from '../../../group-policy-constants/general';

@Component({
  selector: 'rgi-gp-group-policy-parties-detail',
  templateUrl: './group-policy-parties-detail.component.html'
})
export class GroupPolicyPartiesDetailComponent extends GpInquiryComponentDetail {

  public displayedPartiesColumns: string[] = ['role', 'nominative'];

  isPreventJumpToParty: boolean;

  constructor(
    protected stateMngr: GroupPolicyStateManagerInquiry,
    protected parent: GroupPolicyInquiryComponent,
    protected resourceApi: GroupPolicyResourceService,
    protected integrationService: GroupPolicyIntegrationService,
    ) {
    super(stateMngr);

    this.isPreventJumpToParty = stateMngr.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.PARTIES.PREVENT_JUMP_TO_PARTIES);
  }

  public openSubjectDetail(idParty: string) {
    this.resourceApi.getSubjectDetail$(idParty).pipe(
      map(
        (resp) => {
          if (resp && resp.subject) {
            this.integrationService.openSubjectDetail(resp.subject, this.parent.id);
          }
        }
      )
    ).subscribe();
  }

}

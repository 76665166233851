import { Component, OnInit, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { NewPaymentService } from '../new-payment.service';
import { LiabilityParty, SubsidiaryChecksData } from './dto/subsidiary-checks-data';
import { Outcome } from '@rgi/digital-claims-common-angular';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-pre-controls',
  templateUrl: './pre-controls.component.html',
  styleUrls: ['./pre-controls.component.scss']
})
export class PreControlsComponent implements OnInit {

  @Input() potPayFE: InitPotentialPaymentEntity;
  @Input() subsidiaryChecks: Map<string, string>;
  @Input() claimCurrent: any;
  @Input() enteLogIn: string;
  @Input() idUserLogIn: string;

  formGroup: UntypedFormGroup;
  showForceAdmCoverage: boolean;
  showConfirmResp: boolean;

  minCollectionDate: NgbDate;
  maxCollectionDate: NgbDate;
  claimDate: Date;
  occurrenceDate: Date;
  reportDate: Date;

  // FORM CONTROL VARIABLE
  effectiveCollectionDate: Date;
  expiryCollectionDate: Date;
  collectionDate: Date;
  minCollectionDateValue: Date;
  maxCollectionDateValue: Date;
  note: string;
  sumPercentages: number = 0;

  errorMessage: string;
  otherPartiesLiability: Map<string, LiabilityParty>;

  constructor(
    @Inject('eventService') private eventService: any,
    public translateService: RgiRxTranslationService,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private newPaymentService: NewPaymentService
    

  ) {
    this.initDataForm();
  }

  ngOnInit() {
    console.log(this.claimCurrent);
    const keySubsidiaryChecks = 'showForceAdmCoverage';
    const keyShowConfirmResp = 'showConfirmResp';
    this.showForceAdmCoverage = this.subsidiaryChecks[keySubsidiaryChecks] === 'true' ? true : false;
    this.showConfirmResp = this.subsidiaryChecks[keyShowConfirmResp] === 'true' ? true : false;

    // effectiveCollectionDate > input > expiry<CollectionDate
    if (this.showForceAdmCoverage) {
      this.effectiveCollectionDate = new Date(this.claimCurrent.policyEffectDate);
      let year = this.effectiveCollectionDate.getFullYear();
      let month = this.effectiveCollectionDate.getMonth() + 1;
      let day = this.effectiveCollectionDate.getDate();
      this.minCollectionDateValue = this.effectiveCollectionDate;
      this.minCollectionDate = new NgbDate(year, month, day);

      this.expiryCollectionDate = new Date(this.claimCurrent.policyExpirationDate);
      year = this.expiryCollectionDate.getFullYear();
      month = this.expiryCollectionDate.getMonth() + 1;
      day = this.expiryCollectionDate.getDate();
      this.maxCollectionDateValue = this.expiryCollectionDate;
      this.maxCollectionDate = new NgbDate(year, month, day);

      // use DOL for loss occurrence
      this.claimDate = new Date(this.claimCurrent.occurrenceDate);

      if (this.claimCurrent.isClaimMade) {
        // use DON for claims made
        this.reportDate = new Date(this.claimCurrent.reportDate);
        this.claimDate = this.reportDate;
      }

      // collection date default value
      if (this.claimCurrent.forcedCoverageSecurityCollectionDate) {
        this.collectionDate = new Date(this.claimCurrent.forcedCoverageSecurityCollectionDate);
      }

      this.note = this.claimCurrent.administrativeForcingNote;
    }


    // extract data about other party liability from claim json
    if (this.showConfirmResp) {
      this.otherPartiesLiability = new Map<string, LiabilityParty>();
      const otherParties: any[] = this.claimCurrent.liabilityMonitor.civilLiability.otherPartysVehicles;
      const insPerc = parseInt(this.claimCurrent.liabilityMonitor.civilLiability.insuredLiabilityPercentage, 10);
      let sumOthers = 0;
      if (otherParties.length) {
        //TODO: IIAB-4479 - funziona per due veicoli , da gestire x tre o piu
        otherParties[0].liabilityPercentage = 100-insPerc;

        otherParties.forEach((value: any) => {
                  const plate = value.otherPartyVehicle.split(' - ')[1];
                  const liabTmp: LiabilityParty = {
                    percLiability: parseInt(value.liabilityPercentage, 10),
                    partyVehicle: value.otherPartyVehicle.split(' - ')[1] + ' - ' + value.otherPartyVehicle.split(' - ')[2],
                    licensePlate: plate,
                  };
                  this.otherPartiesLiability.set(plate, liabTmp);
                  sumOthers += parseInt(value.liabilityPercentage, 10);

                });
              }
              this.sumPercentages = Math.round(insPerc + sumOthers);
            }



    this.initDataForm();
    this.fillFormData();
  }

  fillFormData() {

    if (this.showForceAdmCoverage) {
      this.formGroup.controls.effectiveCollectionDate.setValue(this.effectiveCollectionDate);
      this.formGroup.controls.expiryCollectionDate.setValue(this.expiryCollectionDate);
      this.formGroup.controls.collectionDate.setValue(this.collectionDate);
      this.formGroup.controls.note.setValue(this.note);
    }

    if (this.showConfirmResp) {
      // this.formGroup.controls.liabilityTy
    }

  }


  formInputsValidator() {
    const that = this;
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls.collectionDate;

      if (control.value) {
        if (control.value instanceof Date) {
          control.setErrors(null);
          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);

          if (that.minCollectionDateValue && that.minCollectionDateValue > inputDate) {
            control.setErrors({tooEarly: true});
          } else if (that.maxCollectionDateValue && that.maxCollectionDateValue < inputDate) {
            control.setErrors({tooOld: true});
          } else {
            control.setErrors(null);
          }

        } else {
          control.setErrors({invalidDate: true});
        }
      } else {
        const validator: any = control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({required: true});
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  initDataForm() {
    const today = new Date();

    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }
    if (this.showForceAdmCoverage) {
        this.formGroup.setControl('effectiveCollectionDate', new UntypedFormControl([]));
        this.formGroup.setControl('expiryCollectionDate', new UntypedFormControl([]));
        this.formGroup.setControl('collectionDate', new UntypedFormControl([], [this.formInputsValidator]));
        this.formGroup.setControl('note', new UntypedFormControl([], [Validators.required]));

        this.formGroup.get('effectiveCollectionDate').disable();
        this.formGroup.get('expiryCollectionDate').disable();
    }
      /*      this.formGroup = this.formBuilder.group({
              effectiveCollectionDate: [],
              expiryCollectionDate: [],
              collectionDate: [, [Validators.required]],
              note: new FormControl({ value: null, disabled: false },
                Validators.required)
            }, {
              validator: [this.formInputsValidator()]
            });*/
    if (this.showConfirmResp) {
        // add form controls if necessary
        const insPerc = parseInt(this.claimCurrent.liabilityMonitor.civilLiability.insuredLiabilityPercentage, 10);
        let sumOthers = 0;
        this.formGroup.addControl('liabilityType', new UntypedFormControl({value: '', disabled: true}));
        this.formGroup.addControl('insLiabilityPercentage', new UntypedFormControl({value: insPerc, disabled: true},
          [Validators.min(0), Validators.max(100)]));
        this.formGroup.addControl('applyLiabilityPercentageForcing', new UntypedFormControl({value: false, disabled: false}));
        this.formGroup.addControl('forceReason', new UntypedFormControl({value: '', disabled: true}));
        const mapTmp: Map<string, number> = new Map<string, number>();
        this.otherPartiesLiability.forEach((value, key, map2) => {
          mapTmp[value.licensePlate] = value.percLiability;
          // @ts-ignore
          sumOthers += parseInt(value.percLiability, 10);
        });
        this.formGroup.addControl('otherPartiesLiability', new UntypedFormControl(mapTmp));

        // determine initial liability type

        if (insPerc > sumOthers) {
          this.formGroup.controls.liabilityType.setValue('MAGGIORITARIO');
        } else if (insPerc === sumOthers) {
          this.formGroup.controls.liabilityType.setValue('PARITARIO');
        } else {
          this.formGroup.controls.liabilityType.setValue('MINORITARIO');
        }

      }
  }

    resetResponsibility() {
        const insPerc = parseInt(this.claimCurrent.liabilityMonitor.civilLiability.insuredLiabilityPercentage, 10);
        let sumOthers = 0;
        /*this.formGroup.addControl('liabilityType', new FormControl({value: '', disabled: true}));
        this.formGroup.addControl('insLiabilityPercentage', new FormControl({value: insPerc, disabled: true},
          [Validators.min(0), Validators.max(100)]));
        this.formGroup.addControl('applyLiabilityPercentageForcing', new FormControl(false));
        this.formGroup.addControl('forceReason', new FormControl({value: '', disabled: true}));*/
        this.formGroup.controls.insLiabilityPercentage.setValue(insPerc);
        this.formGroup.controls.insLiabilityPercentage.disable();

        this.formGroup.controls.applyLiabilityPercentageForcing.setValue(false);
        /*    this.formGroup.controls.applyLiabilityPercentageForcing.disable();*/
        this.formGroup.controls.forceReason.setValue('');
        this.formGroup.controls.forceReason.disable();

        const mapTmp: Map<string, number> = new Map<string, number>();
        const otherParties: any[] = this.claimCurrent.liabilityMonitor.civilLiability.otherPartysVehicles;
        if (otherParties.length) {
            otherParties.forEach((value: any) => {
                const plate = value.otherPartyVehicle.split(' - ')[1];
                const liabTmp: LiabilityParty = {
                    percLiability: parseInt(value.liabilityPercentage, 10),
                    partyVehicle: value.otherPartyVehicle.split(' - ')[1] + ' - ' + value.otherPartyVehicle.split(' - ')[2],
                    licensePlate: plate,
                };
                this.otherPartiesLiability.set(plate, liabTmp);
                mapTmp[plate] = liabTmp.percLiability;
                // @ts-ignore
                sumOthers += parseInt(liabTmp.percLiability, 10);

            });
        }
        this.formGroup.controls.otherPartiesLiability.setValue(mapTmp);

        // determine initial liability type

        if (insPerc > sumOthers) {
            this.formGroup.controls.liabilityType.setValue('MAGGIORITARIO');
        } else if (insPerc === sumOthers) {
            this.formGroup.controls.liabilityType.setValue('PARITARIO');
        } else {
            this.formGroup.controls.liabilityType.setValue('MINORITARIO');
        }
    }

    onConfirm() {
        if (this.showForceAdmCoverage) {
            const input = new Date(this.formGroup.controls.collectionDate.value);
            if (input < this.claimDate) {
                this.formGroup.controls.collectionDate.setErrors({tooOld: true});
            }
        }

        this.errorMessage = null;
        if (this.formGroup.invalid) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES')
            .subscribe(
              res => this.errorMessage=res
            )
            return;
        }

        const subsidiaryChecksData = new SubsidiaryChecksData();

        /* const subsidiaryChecksData = new SubsidiaryChecksData(this.showForceAdmCoverage,
           this.formGroup.controls.effectiveCollectionDate.value,
           this.formGroup.controls.expiryCollectionDate.value,
           this.formGroup.controls.collectionDate.value,
           this.formGroup.controls.note.value);*/
        if (this.showForceAdmCoverage) {
            subsidiaryChecksData.showForceAdmCoverage = this.showForceAdmCoverage;
            subsidiaryChecksData.effectiveCollectionDate = this.formGroup.controls.effectiveCollectionDate.value;
            subsidiaryChecksData.expiryCollectionDate = this.formGroup.controls.expiryCollectionDate.value;
            subsidiaryChecksData.collectionDate = this.formGroup.controls.collectionDate.value;
            subsidiaryChecksData.note = this.formGroup.controls.note.value;
        }
        if (this.showConfirmResp) {
            subsidiaryChecksData.showConfirmResp = this.showConfirmResp;
            subsidiaryChecksData.liabilityType = this.formGroup.controls.liabilityType.value;
            subsidiaryChecksData.applyLiabilityPercentageForcing = this.formGroup.controls.applyLiabilityPercentageForcing.value;
            subsidiaryChecksData.insLiabilityPercentage = this.formGroup.controls.insLiabilityPercentage.value;
            subsidiaryChecksData.forceReason = this.formGroup.controls.forceReason.value;
            subsidiaryChecksData.otherPartiesLiability = this.formGroup.controls.otherPartiesLiability.value;
        }

        this.eventService.broadcastEvent('start-loader');
        this.newPaymentService.postSubsidiaryChecks(this.claimCurrent.idClaim, subsidiaryChecksData,
            this.enteLogIn, this.idUserLogIn).subscribe(
            (response: any) => {
                this.activeModal.close(response);
                this.eventService.broadcastEvent('stop-loader');
            },
            (error: Error) => {
                console.error(error);
                this.translateService.translate('_CLAIMS_._MESSAGE_._UPLOAD_ERROR')
                .subscribe(
                  res => this.errorMessage=res
                )
                this.eventService.broadcastEvent('stop-loader');
                // this.activeModal.close(error);
            });
    }

    closeModal() {
        this.activeModal.dismiss();
    }

    onInsuredLiabilityPercentageChange($event: any) {

        this.sumPercentages = 0;
        let sumOthers = 0;
        let insPerc = 0;
        // tslint:disable-next-line:radix
        const newPerc = parseInt($event.target.value);
        if (newPerc > 100) {
            this.formGroup.controls.insLiabilityPercentage.setValue(100);
        } else if (newPerc < 0) {
            this.formGroup.controls.insLiabilityPercentage.setValue(0);
        } else {
            // const tmpMap: Map<string, number> = this.formGroup.controls.otherPartiesLiability.value;
            this.formGroup.controls.insLiabilityPercentage.setValue(newPerc);
            // console.log(this.formGroup.controls.otherPartiesLiability.value);
        }
        insPerc = parseInt(this.formGroup.controls.insLiabilityPercentage.value, 10);
        const currentPercs: Map<string, number> = this.formGroup.controls.otherPartiesLiability.value;
        /*    // determine sum of other percentages
            currentPercs.forEach((value, key, others) => {
              // @ts-ignore
              sumOthers += parseInt(value, 10);
            });*/
        Object.keys(currentPercs).forEach((value, idx, array) => {
            const insPercOther = currentPercs[value];
            sumOthers += insPercOther;
        });

        if (insPerc > sumOthers) {
            this.formGroup.controls.liabilityType.setValue('MAGGIORITARIO');
        } else if (insPerc === sumOthers) {
            this.formGroup.controls.liabilityType.setValue('PARITARIO');
        } else {
            this.formGroup.controls.liabilityType.setValue('MINORITARIO');
        }
        this.sumPercentages = Math.round(insPerc + sumOthers);
        console.log('insured sum ', this.sumPercentages);
    }

    onOtherVehicleLiabilityPercentageChange($event: any, vehicle: LiabilityParty) {
        this.sumPercentages = 0;
        let sumOthers = 0;
        let currentPercs: Map<string, number>;
        // check validity of number
        const perc = parseInt($event.target.value, 10);
        const tmpMap: Map<string, number> = this.formGroup.controls.otherPartiesLiability.value;
        if (perc > 100) {
            tmpMap[vehicle.licensePlate] = 100;
            this.formGroup.controls.otherPartiesLiability.setValue(tmpMap);

        } else if (perc < 0) {
            tmpMap[vehicle.licensePlate] = 0;
            this.formGroup.controls.otherPartiesLiability.setValue(tmpMap);
        } else {
            vehicle.percLiability = parseInt($event.target.value, 10);
            // const tmpMap: Map<string, number> = this.formGroup.controls.otherPartiesLiability.value;
            tmpMap[vehicle.licensePlate] = vehicle.percLiability;
            this.formGroup.controls.otherPartiesLiability.setValue(tmpMap);
            // determine new liability type
        }
        currentPercs = this.formGroup.controls.otherPartiesLiability.value;
        // determine sum of other percentages
        /*    currentPercs.forEach((value, key, others) => {
              // @ts-ignore
              sumOthers += parseInt(value, 10);
            });*/
        Object.keys(currentPercs).forEach((value, idx, array) => {
            const insPercOther = currentPercs[value];
            sumOthers += insPercOther;
        });

        const insPerc = parseInt(this.formGroup.controls.insLiabilityPercentage.value, 10);
        // if only 1 other party

        // const insPerc: number = this.formGroup.controls.insLiabilityPercentage.value;
        if (insPerc > sumOthers) {
            this.formGroup.controls.liabilityType.setValue('MAGGIORITARIO');
        } else if (insPerc < sumOthers) {
            this.formGroup.controls.liabilityType.setValue('MINORITARIO');
        } else if (insPerc === sumOthers) {
            this.formGroup.controls.liabilityType.setValue('PARITARIO');
        }

        // tslint:disable-next-line:radix
        this.sumPercentages = Math.round(sumOthers + insPerc);
        console.log('sum is ', this.sumPercentages);
// determine new liability type

    }


    onLiabilityPercentageForcingChange($event: any) {
        console.log('CHECKBOX IS NOW ', this.formGroup.controls.applyLiabilityPercentageForcing.value);
        const pastPerc = this.formGroup.controls.insLiabilityPercentage.value;
        // recover initial data from claim json
        // this.claimCurrent.

        if (!this.formGroup.controls.applyLiabilityPercentageForcing.value) {
            // no force, initial state
            /* const otherParties: any[] = this.claimCurrent.liabilityMonitor.civilLiability.otherPartysVehicles;
             const insPerc = parseInt(this.claimCurrent.liabilityMonitor.civilLiability.insuredLiabilityPercentage, 10);*/
            // this.formGroup.controls.insLiabilityPercentage.disable();
            // this.formGroup.controls.insLiabilityPercentage.setValue(insPerc);
            /*      this.formGroup.controls.forceReason.disable();
                  this.formGroup.controls.forceReason.setValue('');*/
            this.resetResponsibility();
            // const tmpMap = new Map<string, LiabilityParty>();
        } else {
            // yes force
            this.formGroup.controls.insLiabilityPercentage.enable();
            // this.formGroup.controls.insLiabilityPercentage.setValue(100);
            this.formGroup.controls.forceReason.enable();
            // this.formGroup.controls.forceReason.setValue('');

        }

        console.log($event.target.value);
    }



    /*recalculateSumPercentages();
    {
      let sum = 0;
      const currentPercs: Map<string, number> = this.formGroup.controls.otherPartiesLiability.value;
      // determine sum of other percentages
      currentPercs.forEach((value, key, others) => {
        sum += value;
      });
      sum += this.formGroup.controls.insLiabilityPercentage.value;
      sum = Math.round(sum);
      return sum;
    }*/
}

import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  OnInit
} from '@angular/core';
import { PolicyServiceExt } from './life-card/services/policy-service-ext';
import {
  ActionsService, AuthService, BeneficiaryService, CardsNavigationService, EnviromentsService, FromJsService,
  LifeCardComponent, LifeRoleService, LifeSessionService, MasterPolicyService,
  NavigationSummaryService, PassProLifeService, PolicyService, ReinvestementService, SystemPropertiesService,
  TranslationWrapperService, ValidationSubjectsService } from '@rgi/life-issue-card';
import { AnagSearchOrCreateResolver } from '@rgi/anag';
import { QuestionnaireValuesService, PassproQuestionnaireService } from '@rgi/ng-passpro';
import { questionnaireCacheServiceInjectionToken } from '@rgi/questionnaires-manager';

@Component({
    selector: 'lic-life-card-ext',
    templateUrl: './life-card-ext.component.html',
    styleUrls: [],
    providers: [
      PolicyServiceExt,
      {provide: PolicyService, useExisting: PolicyServiceExt},
      /*  EXT */
      LifeSessionService,
      SystemPropertiesService,
      ActionsService,
      QuestionnaireValuesService,
      LifeRoleService,
      FromJsService,
      NavigationSummaryService,
      PassProLifeService,
      CardsNavigationService,
      PassproQuestionnaireService,
      AuthService,
      MasterPolicyService,
      ValidationSubjectsService,
      BeneficiaryService,
      ReinvestementService,
      TranslationWrapperService,
      questionnaireCacheServiceInjectionToken
    ]
})
export class LifeCardExtComponent extends LifeCardComponent implements OnInit {

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected lifeRoleService: LifeRoleService,
    protected policyService: PolicyServiceExt,
    protected validationSubjectService: ValidationSubjectsService,
    protected beneficiaryService: BeneficiaryService,
    protected cardsNavigationService: CardsNavigationService,
    protected fromJsService: FromJsService,
    protected lifeSessionService: LifeSessionService,
    protected systemPropertiesService: SystemPropertiesService,
    protected navigationSummaryService: NavigationSummaryService,
    protected changeDetector: ChangeDetectorRef,
    protected http: HttpClient,
    @Inject('issueComponent') issueComponent,
    @Inject('lifeRolePageComponent') lifeRolePageComponent,
    @Inject('datiAmmComponent') datiAmmComponent,
    @Inject('pipDataComponent') pipDataComponent,
    @Inject('goodsInfoComponent') goodsInfoComponent,
    @Inject('licQuotationComponent') quotationComponent,
    @Inject('investmentsComponent') investmentsComponent,
    @Inject('summaryComponent') summaryComponent,
    @Inject('cardsNavigationComponent') cardsNavigationComponent,
    @Inject('$scope') scope,
    protected environment: EnviromentsService,
    @Inject('RGI_ANAG_PORTAL_AJS_FLOW') protected anagInJs: boolean,
    protected anagSearchorCreateResolver: AnagSearchOrCreateResolver) {
    super(
      componentFactoryResolver,
      lifeRoleService, policyService, validationSubjectService,
      beneficiaryService, cardsNavigationService, fromJsService,
      lifeSessionService, systemPropertiesService, navigationSummaryService,
      changeDetector, http, issueComponent, lifeRolePageComponent,
      datiAmmComponent, pipDataComponent, goodsInfoComponent, quotationComponent,
      investmentsComponent, summaryComponent, cardsNavigationComponent, scope,
      environment, anagInJs, anagSearchorCreateResolver);
  }

  ngOnInit() {
    this.policyService.idSession = this.data.idParentSession;
    super.ngOnInit();
  }

}


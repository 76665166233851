<div [ngSwitch]="type">
  <div *ngSwitchCase="TEXT_TYPE" class="rgi-cc-row">
    <div >
      <span class="rgi-ui-text-5">{{label | translate}}: </span>
      <span class="rgi-ui-title-3">{{value ? value : "-"}}</span>
    </div>
  </div>
  <div *ngSwitchCase="BOOL_TYPE" class="rgi-cc-row">
    <span class="rgi-ui-text-5">{{label | translate}}: </span>
    <span class="rgi-ui-title-3">{{(value ? 'CONTRACT_CONSULTATION.YES' : 'CONTRACT_CONSULTATION.NO') | translate}}</span>
  </div>
  <div *ngSwitchCase="DATE_TYPE" class="rgi-cc-row">
    <span class="rgi-ui-text-5">{{label | translate}}: </span>
    <span class="rgi-ui-title-3">{{value ? (value | date: DATEFORMAT ): "-"}}</span>
  </div>
</div>

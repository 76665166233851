import { Subject } from '../../../models/subject.model';
import { EventNotificator } from '../../../event-notificator';
import { LifeRoleService } from './../../../services/life-role.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';

@Component({
  selector: 'lic-select-cosigned',
  templateUrl: './select-cosigned.component.html',
  styleUrls: ['./select-cosigned.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectCosignedComponent implements OnInit, EventNotificator  {

  @Input() parentForm: UntypedFormGroup;
  disabled: boolean;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Input() role: string;
  @Input() val: Subject;

  label: string;
  select;

  constructor(
    protected translateService: TranslationWrapperService,
    protected lifeRoleService: LifeRoleService) {
    this.select = this.translateService.getImmediate('lic_select');
  }

  ngOnInit() {
    this.label = 'Cointestario';
  }

  fill() {
    return this.lifeRoleService.fill(this.val);
  }

  public isEmpty() {
    return this.val == null;
  }

  resetMethod() {
    this.delete.emit(this.val);
    this.val = null;
  }

  public openAnag() {
    this.eventPropagation.emit({
      eventName: 'requireSubject',
      sessionParent: 'anagFinder',
      sessionRoute: 'home',
      sessionTitle: 'Seleziona soggetto',
      navigationDisabled: true,
      roleCode: this.role
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthorizationsCardModule, AuthorizationsSearchService } from '@rgi/authorizations-card';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { RgiRxDatePickerModule, RgiRxDragDropModule, RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxPaginatorModule, RgiRxPanelModule, RgiRxTableModule } from '@rgi/rx/ui';
import { AuthorizationsDetailExtComponent } from './authorizations-detail/authorizations-detail-ext.component';
import { AuthorizationsListExtComponent } from './authorizations-list/authorizations-list-ext.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNgCommonModule } from '@rgi/portal-ng-common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { HttpClientModule } from '@angular/common/http';
import { RgiRxRoutingModule } from '@rgi/rx/router';
import { RgiRxHttpModule } from '@rgi/rx/http';
import { RgiAnagModule } from '@rgi/anag';
import { RgiRxQualityAssuranceModule } from '@rgi/rx';
import { AuthorizationsSearchExtService } from './services/authorizations-search-ext.service';

@NgModule({
  declarations: [
    AuthorizationsDetailExtComponent,
    AuthorizationsListExtComponent
  ],
  imports: [
    AuthorizationsCardModule,
    HttpClientModule,
    CommonModule,
    PortalNgCoreModule,
    PortalNgCommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RgiRxI18nModule,
    RgiRxPaginatorModule,
    RgiRxTableModule,
    RgiRxRoutingModule,
    RgiRxHttpModule,
    RgiRxFormElementsModule,
    RgiRxExpansionModule,
    RgiRxPanelModule,
    RgiRxModalModule,
    RgiRxDragDropModule,
    RgiAnagModule,
    RgiRxQualityAssuranceModule,
    RgiRxDatePickerModule
  ],
  exports: [
    AuthorizationsDetailExtComponent,
    AuthorizationsListExtComponent
  ],
  providers: [
    DatePipe,
    {
      provide: AuthorizationsSearchService, useClass: AuthorizationsSearchExtService
    }
  ]
})
export class AuthorizationsExtModule {
  constructor() {
  }
}

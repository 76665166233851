import { Component, Inject, OnInit } from '@angular/core';
import { DialogueHandlerListStateManager } from '../dialogue-handler-service/dialogue-handler-list.state.manager';
import { ActiveRoute } from '@rgi/rx/router';
import { RgiRxDataTableColumnSortDef, RgiRxDatatableRowAction, RgiRxSortComparatorFn, TableSchema } from '@rgi/rx/ui';
import { LoggerFactory } from '@rgi/rx';
import { schemaTableSx } from './schema-table-list/schema-table-list.model';
import { ListDialogueViewConfiguration } from '../constants/listEnumConstants';
import { DialogueSummaryObject } from '../dialogue-handler-configuration/dh-summary.model';
import { DialogueHandlerListResponse } from '../dialogue-handler-configuration/dialogue-handler-list.model';
import { ResponseFromSearchState } from '../dialogue-handler-configuration/dialogue-handler.model';

export enum MatrixIndex {
  SX_LIST = 0,
  NOT_ASSOCIETED_LIST = 1
}
const CUSTOM_TEMPLATE_SORT_DEF: RgiRxDataTableColumnSortDef<DialogueHandlerListResponse>[] = [];

@Component({
  selector: 'claims-dialogue-handler-list',
  templateUrl: './dialogue-handler-list.component.html',
  styleUrls: ['./dialogue-handler-list.component.scss']
})
export class DialogueHandlerListComponent implements OnInit {

  totalPages: number;
  sessionServiceInject: any;
  arrPage: number[] = [];
  errorMessage: string;
  emitter: any;
  currentPage: number;
  matrixIndex = MatrixIndex;
  responseDialogeList: DialogueHandlerListResponse[];
  viewCond = {
    fromSearchCond: false,
    doubleListCond: false,
  }

  btnCond = {
    canOpenNew: false,
  }

  constructor(
    public stateList: DialogueHandlerListStateManager,
    public activeRoute: ActiveRoute,
    @Inject('authService') private authService,
  ) { }

  private readonly logger = LoggerFactory();
  // schemaTableAss: TableSchema = schemaTableAss;
  // schemaTableSearch: TableSchema = schemaTableSearch;
  // schemaTableNotAss: TableSchema = schemaTableNotAss;
  schemaTableSx: TableSchema = schemaTableSx;

  ngOnInit(): void {
    const listFunz = this.authService.getOperator().enabledFeatures;
    this.checkPermissions(listFunz);

    this.stateList.getState$().subscribe((st: ResponseFromSearchState) => {
      console.log("VALORE DI state nella new =>", st);
      this.responseDialogeList = this.sortDialogueHandlerList(st.responseFromSearchList)
      console.log("this.responseDialogeList =>", this.responseDialogeList);
      switch (st.viewConfig) {
        case ListDialogueViewConfiguration.SEARCH_LIST_VIEW:
          this.viewCond.fromSearchCond = true;
          break;
        case ListDialogueViewConfiguration.DOUBLE_LIST_VIEW:
        default:
          this.viewCond.doubleListCond = true;
          break;
      }
    });
  }

  checkPermissions(listFunz){
    console.log(listFunz);
    if (listFunz && listFunz.indexOf('PSNDNW') >= 0) {
       this.btnCond.canOpenNew = true;
    }
  }


  openPartialNewDialogue() {
    this.stateList.goToPartialNewDialogue$();
  }

  openCompleteNewDialogue() {
    this.stateList.goToCompleteNewDialogue$();
  }

  onActionClick(event: RgiRxDatatableRowAction<any>) {
    if (event.name === "detail") {
      console.log('test del onActionClick');
      console.log(event.row)
      this.logger.info(event.row);
    }
  }

  guideMeToSummary(ctx, matrixIndex?: number) {
    console.log(ctx)
    this.stateList.goToDialogueHandlerSummary$(ctx,matrixIndex);
  }

  sortDialogueHandlerList(list: DialogueHandlerListResponse[]): DialogueHandlerListResponse[] {
    return list.sort((a, b) => {
      if (a.lastMsgRegExpire === null && b.lastMsgRegExpire === null) return 0;
        if (a.lastMsgRegExpire === null) return 1;
        if (b.lastMsgRegExpire === null) return -1;

        return b.lastMsgRegExpire - a.lastMsgRegExpire;
    });
}


}

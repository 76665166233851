import {RgiRxOnContainerValueChange} from '@rgi/rx/ui';
import {NEW_PARTY, SUBJECT_SELECTED} from '../anag-events/anag-event';
import {AbstractControl} from '@angular/forms';

export function onDropSubjectChangeNgModel($event: RgiRxOnContainerValueChange, subjectSelectedModelName: string) {
  switch ($event.changed.eventName) {
    case SUBJECT_SELECTED.eventName:
      if ($event.changed.routeData.party) {
        this[subjectSelectedModelName] = $event.changed.routeData.party;
      }
      break;
    case NEW_PARTY.eventName:
      this.anagIntegration.openEditSubject($event.changed.routeData);
      break;
  }
}

export function onDropSubjectChangeFormControl($event: RgiRxOnContainerValueChange, subjectFormControl: AbstractControl) {
  switch ($event.changed.eventName) {
    case SUBJECT_SELECTED.eventName:
      if ($event.changed.routeData.party) {

      }
      break;
    case NEW_PARTY.eventName:
      this.anagIntegration.openEditSubject($event.changed.routeData);
      break;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { PassproQuestionnaireService, QuestionnaireComponent } from '@rgi/ng-passpro';
import { PolicyService } from '../../services/policy-service';
import { Questionnaire, QuestionnaireValue } from './../../models/policy.model';
import { LifeRoleService } from './../../services/life-role.service';
import { RgiRxPushMessage, RgiRxPushMessageHandlerService } from '@rgi/rx';


@Component({
  selector: 'lic-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionnairesComponent implements OnInit, OnChanges {
  @ViewChild(QuestionnaireComponent) questionnaireComp: QuestionnaireComponent;

  @Input() questionnairesList: Questionnaire[];
  @Input() customFormGroup: UntypedFormGroup;

  validationMessages: string[] = [];

  public setblockingMsgs() {
    this.pushMessageHandler.clearTag('validation');
    this.pushMessageHandler.notify(
      ...(this.validationMessages).map(m => new RgiRxPushMessage(m, { tag: 'validation', status: 'danger', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  questionnaireFlat: any;
  viewQ = false;

  compiledQuest = new Map<string, QuestionnaireValue>();

  @Output() emitSave = new EventEmitter<QuestionnaireValue>();
  @Output() emitSaveOnInit = new EventEmitter<QuestionnaireValue>();
  @Output() emitDelete = new EventEmitter<any>();
  @Output() loaderQuest = new EventEmitter<string>();

  constructor(private questService: PassproQuestionnaireService,
              private policyService: PolicyService,
              private lifeRoleService: LifeRoleService,
              private translateService: TranslationWrapperService,
              protected pushMessageHandler: RgiRxPushMessageHandlerService) { }

  ngOnInit(): void {
    this.initQuestionnairesForm();
  }

  private initQuestionnairesForm() {
    if (this.questionnairesList.length > 0) {
      this.questionnairesList.map((quest) => {
        const questFromProposal = this.policyService.mainProposal.proposal.questionnaires.find((q) => q.questionnaire.code === quest.code);
        if (questFromProposal) {
          this.compiledQuest.set(quest.code, questFromProposal);
          this.emitSaveOnInit.emit(questFromProposal);
          this.customFormGroup.addControl(quest.code, new UntypedFormControl('COMPILED'));
        } else {
          this.customFormGroup.addControl(quest.code, new UntypedFormControl(undefined));
        }

        if (quest.compulsory) {
          this.customFormGroup.get(quest.code).setValidators(Validators.required);
          this.customFormGroup.get(quest.code).updateValueAndValidity();
        } else {
          this.customFormGroup.get(quest.code).clearValidators();
          this.customFormGroup.get(quest.code).updateValueAndValidity();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionnairesList && changes.questionnairesList.currentValue &&
      changes.questionnairesList.currentValue.length > 0) {
      this.questionnairesList = changes.questionnairesList.currentValue;
      this.initQuestionnairesForm();
    }
  }

  setVisibilityOfQuest(bool: boolean) {
    this.viewQ = bool;
  }

  openQuest(questCode: string) {
    this.validationMessages = [];
    this.setblockingMsgs();
    const questCompiled = this.compiledQuest.get(questCode);
    if (questCompiled != null) {
      const temp: any =  JSON.parse(JSON.stringify(questCompiled.questionnaire));

      temp.questions.map((q, index) => {
        q.section = {
          hasNotes: null,
          name: ' ',
          id: index.toString(),
          visibilita: null,
          type: null
        };

        q.answers.map((answ) => {
          if (answ.factorType.toString() === '4') {
            if (answ.value) {
              const d = answ.value.split('/');
              const parsedData = new Date(d[2], d[1] - 1, d[0]);
              answ.value = parsedData.getTime();
            }
          }
        });
      });

      this.questionnaireFlat = temp;

      this.setVisibilityOfQuest(true);
    } else {
      this.loaderQuest.emit('startLoader');
      this.questService.getPassProQuestionnaire(questCode, 'it').subscribe((resp) => {
        console.log(resp);

        resp.questions.map((q, index) => {
          q.section = {
            hasNotes: null,
            name: ' ',
            id: index.toString(),
            visibilita: null,
            type: null
          };
        });

        this.questionnaireFlat = resp;
        this.setVisibilityOfQuest(true);
        this.loaderQuest.emit('stopLoader');
      });
    }
  }

  saveQuest(questCode: string) {
    const valid = this.questionnaireComp.validate();
    if (valid) {
      const qu = {
        isCompiled: true,
        score: '0',
        questionnaire: this.questionnaireComp.questionnaireFlat,
        assetInstanceName: !!this.lifeRoleService.getPolicyInsured() ? this.lifeRoleService.getPolicyInsured().objectId : null
      };

      qu.questionnaire.questions.map((question) => {
        question.answers.map((answ) => {
          if (answ.factorType.toString() === '4') {
            if (answ.value) {
              answ.value = this.formatDate(+answ.value);
            }
          } else if (answ.factorType.toString() === '5' && answ.value === 'null') {
            answ.value = null;
          } else if (answ.factorType.toString() === '1' && answ.value === 'null' ) {
            answ.value = null;
          }
        });
        delete question.section;
      });

      this.compiledQuest.set(questCode, qu);
      this.customFormGroup.get(questCode).setValue('COMPILED');

      // this.policyService.mainProposal.proposal.questionnaires.push(qu);

      console.log('questionario', qu);
      this.emitSave.emit(qu);
      this.setVisibilityOfQuest(false);
    } else {
      this.validationMessages = [];
      this.validationMessages.push(this.translateService.getImmediate('lic_error_mandatory_fields'));
      this.setblockingMsgs();
    }
  }

  deleteQuest(questCode: string) {
    this.compiledQuest.delete(questCode);
    this.customFormGroup.get(questCode).setValue(undefined);
    this.emitDelete.emit(questCode);
    this.questionnaireFlat = null;
  }

  closeQuest() {
    this.setVisibilityOfQuest(false);
    this.questionnaireFlat = null;
  }

  formatDate(date: number) {

    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [day, month, year].join('/');
  }
}

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step label="{{BACKPACK_TRANSFER_STEP.DATE.label}}" id="{{BACKPACK_TRANSFER_STEP.DATE.id}}" [errors]="errors" fieldId="{{BACKPACK_TRANSFER_STEP.DATE.fieldId}}" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="BACKPACK_TRANSFER_STEP.DATE.formName" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>


  <lpc-step label="{{BACKPACK_TRANSFER_STEP.SELECT_TRANSFER.label}}" [errors]="errors" fieldId="{{BACKPACK_TRANSFER_STEP.SELECT_TRANSFER.fieldId}}" id="{{BACKPACK_TRANSFER_STEP.SELECT_TRANSFER.id}}" (next)="onNext($event, true)">
    <div *ngIf="dataTransferDefinitions">
      <lpc-data-transfer
        [dataTransferDefinitions]="dataTransferDefinitions"
        [formControlName]="BACKPACK_TRANSFER_STEP.SELECT_TRANSFER.formName"
        (changedTransfer)="changedTransfer($event)">
      </lpc-data-transfer>
    </div>
  </lpc-step>

  <lpc-step id="{{BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.id}}" label="{{BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.label}}" [errors]="errors" fieldId="{{BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.fieldId}}"
    (next)="onNext($event)">
    <div *ngIf="fiscalDataFolder?.length && isActiveOrPassedStep(BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.id)" class="row">
    <lpc-form-field-handler
      [isActive]="isActiveStep(BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.id)"
      [isOpen]="isActiveOrPassedStep(BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.id)"
      formControlName="{{BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.formName}}"
      [definitions]="fiscalDataFolder">
    </lpc-form-field-handler>
      <ng-container *ngFor="let data of fiscalData[0] | keyvalue">
        <div class="mb-2" *ngIf="isActiveStep(BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.id); else fiscalFolderResume">
          <rgi-rx-expansion-panel>
            <div rgi-rx-expansion-panel-header>
              <div class="row">
                <span translate>lpc_{{data.key}}</span>
              </div>
            </div>
            <ng-container rgiRxExpansionPanelContent >
              <lpc-form-field-handler [isActive]="isActiveStep(BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.id)"
              [isOpen]="isActiveOrPassedStep(BACKPACK_TRANSFER_STEP.FISCAL_DATA_HEADER.id)" [formControlName]="data.key" [definitions]="data.value"></lpc-form-field-handler>
            </ng-container>
          </rgi-rx-expansion-panel>
        </div>
        <ng-template #fiscalFolderResume>
          <div translate>{{'lpc_'+data.key}}: {{!!formGroup.get(data.key).value?.length ? ('lpc_blank' ) : ('lpc_filledOut')}}</div>
        </ng-template>
      </ng-container>
    </div>
  </lpc-step>

  <lpc-step
      *ngIf="isAuth || !!errors && !!errors.length"
      id="{{BACKPACK_TRANSFER_STEP.WARNING.id}}" label="{{BACKPACK_TRANSFER_STEP.WARNING.label}}"
      (next)="publish()"
      [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
      (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="{{BACKPACK_TRANSFER_STEP.DOCUMENT.label}}" [errors]="errors" fieldId="{{BACKPACK_TRANSFER_STEP.DOCUMENT.fieldId}}" id="{{BACKPACK_TRANSFER_STEP.DOCUMENT.id}}"
    [nextVisible]=false>
    <lpc-document-wrapper *ngIf="!!publishMessage" [contractId]="getContractId" [operationCode]="getOperationCode"
      [publishMessage]="publishMessage" [isAuth]="isAuth" [authorizationId]="authorizationId" [printMode]="printMode"
      [config]="documentsConfig" (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>
    <lpc-navigation-buttons id="last-step-buttons" (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'"
      [backVisible]="true" [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep(BACKPACK_TRANSFER_STEP.DOCUMENT.id)">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

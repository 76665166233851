<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate> Edit Address </span>
        </h3>
      </div>

      <div class="modal-body">
        <form [formGroup]="addressControlForm" [ngClass]="{'display-none': showNormalize}">
          <div class="form-group">
            <label translate>Nation</label>
            <pnc-required required="true"></pnc-required>
            <ng-container *ngIf="countries && countries?.length > 0; else countryInput">
              <select (change)="onCountryChange();onChange()" class="select-nativa" formControlName="country" name="country">
                <option [value]="-1"></option>
                <option *ngFor="let country of countries" [ngValue]="country.code">
                  {{ country?.description }}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </ng-container>
            <ng-template #countryInput>
              <div class="form-group">
                <input (change)="onChange()" class="form-control mic-input"
                       formControlName="country" id="country" type="text">
              </div>
            </ng-template>
          </div>

          <div class="form-group">
            <label translate>Province</label>
            <pnc-required required="true"></pnc-required>
            <ng-container *ngIf="provinces && provinces?.length > 0; else provinceInput">
              <select (change)="onProvinceChange();onChange()" class="select-nativa"
                      formControlName="province" name="province">
                <option [value]="-1"></option>
                <option *ngFor="let province of provinces" [ngValue]="province.description">
                  {{ province?.description }}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </ng-container>
            <ng-template #provinceInput>
              <div class="form-group">
                <input (change)="onChange()" class="form-control mic-input"
                       formControlName="province" id="province" type="text">
              </div>
            </ng-template>
          </div>

          <div class="form-group">
            <label translate>City</label>
            <pnc-required required="true"></pnc-required>
            <ng-container *ngIf="cities && cities?.length > 0; else cityInput">
              <select (change)="onCityChange();onChange()" class="select-nativa"
                      formControlName="city" name="city">
                <option [value]="-1"></option>
                <option *ngFor="let city of cities" [ngValue]="city.description">
                  {{ city.description }}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </ng-container>
            <ng-template #cityInput>
              <div class="form-group">
                <input (change)="onChange()" class="form-control mic-input"
                       formControlName="city" id="city" type="text">
              </div>
            </ng-template>
          </div>

          <div class="form-group">
            <label translate>Postal Code</label>
            <pnc-required required="false"></pnc-required>
            <ng-container *ngIf="zipCodes && zipCodes?.length > 0; else zipCodeInput">
            <select (change)="onChange()" class="select-nativa"
                    formControlName="zip" name="zip">
              <option [value]="-1"></option>
              <option *ngFor="let zipCode of zipCodes" [ngValue]="zipCode.code">
                {{ zipCode.code }}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </ng-container>
            <ng-template #zipCodeInput>
              <div class="form-group">
                <input (change)="onChange()" class="form-control mic-input"
                       formControlName="zip" id="zip" type="text">
              </div>
            </ng-template>
          </div>

          <div class="form-group">
            <label translate>Toponym</label>
            <pnc-required required="true"></pnc-required>
            <input (change)="onChange()" class="form-control mic-input"
                   formControlName="toponym" id="toponym" type="text">
          </div>

          <div class="form-group">
            <label translate>Address</label>
            <pnc-required required="true"></pnc-required>
            <input (change)="onChange()" class="form-control mic-input"
                   formControlName="placeAddress" id="placeAddress" type="text">
          </div>

          <div class="form-group">
            <label translate>House Number</label>
            <pnc-required required="false"></pnc-required>
            <input (change)="onChange()" class="form-control mic-input"
                   formControlName="houseNumber" id="houseNumber" type="text">
          </div>

          <div class="form-group">
            <label translate>Text</label>
            <input (change)="onChange()" class="form-control mic-input"
                   formControlName="addressText" id="addressText" type="text">
          </div>
        </form>
        <mic-address-normalize (changedAddress)="onNormalize($event)" *ngIf="showNormalize"
                               [address]="normalizedAddress"></mic-address-normalize>
        <button (click)="normalize()" *ngIf="!showNormalize" [disabled]="!enableNormalizeBtn || !formValid" class="btn btn-primary w-100"
                data-qa="normalize-btn" translate type="button">NORMALIZE
        </button>
        <div *ngIf="(addressControlForm?.invalid && validationMessages && validationMessages?.length > 0)" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>
      </div>

      <div class="modal-footer">
        <div *ngIf="!showNormalize" class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close(true)" class="btn btn-warning" translate>CANCEL</button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" translate>CONFIRM</button>
          </div>
        </div>
        <div *ngIf="showNormalize" class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="back()" class="btn btn-warning" translate>CANCEL</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

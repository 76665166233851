import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {share} from 'rxjs/operators';
import {Currency} from '../../../models/domain-models/parameters/currency';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioProductCurrenciesResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
    private refactorService: ReIssueRefactorService
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  getProductCurrencies(proposalId: string): Observable<Array<Currency>> {
    return this.http.get<Array<Currency>>(this.getProductCurrenciesUri(proposalId)).pipe(
      share()
    );
  }

  putProductCurrency(proposalId: string, currency: Currency): Observable<Currency> {
    return this.http.put<Currency>(
      this.getProductCurrencyUri(proposalId),
      currency
    ).pipe(
      share()
    );
  }

  getProductCurrency(proposalId: string): Observable<Currency> {
    return this.http.get<Currency>(
      this.getProductCurrencyUri(proposalId)
    ).pipe(
      share()
    );
  }

  getProductCurrenciesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/product-currencies';
  }

  getProductCurrencyUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/currency';
  }
}

import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map, share} from 'rxjs/operators';
import {QuestionnaireFlatIResponse} from '../../../models/domain-models/questionnaires-flati-response';
import {Quotation} from '../../../models/domain-models/quotation';
import {PcQuotation} from '../../../models/pc-portfolio-models/quotation-models/pc-quotation';
import {
  PcQuestionnaireFlatIResponse,
  QuestionFlateResponse
} from '../../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {ReIssueQuotationResourceService} from '../re-issue-quotation-resource.service';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {Clause, ClausesResponse} from '../../../models/domain-models/clause';
import {PcClause} from '../../../models/pc-portfolio-models/quotation-models/pc-clause';
import {Package, PcInsuredAsset} from '../../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {PcUnit} from '../../../models/pc-portfolio-models/quotation-models/pc-unit';
import {PolicyContact} from '../../../models/domain-models/policy-contact';
import {CommissionsWrap} from '../../../models/domain-models/parameters/commission-detail';
import {InputPremium} from '../../../models/domain-models/inputPremium';
import {Notes} from '../../../models/domain-models/notes';
import {BeneficiaryDTO} from '../../../models/domain-models/BeneficiaryDTO';
import {GenericEntityPcPortfolio} from '../../../models/pc-portfolio-models/genericEntityPcPortfolio';
import {ErrorManagementService} from '../../../re-issue-core/error-management.service';
import {FlexDiscountParam} from '../../../models/pc-portfolio-models/discounts-models/flex-discount-param';
import {FlexDiscountUnit} from '../../../models/pc-portfolio-models/discounts-models/flex-discount-unit';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';
import {DiscountFund} from '../../../models/pc-portfolio-models/discounts-models/discount-fund';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioQuotationResourceService extends ReIssueQuotationResourceService {

  private PROPOSALS_PATH = 'proposals/';
  private DISABLE_ERR_INTERCEPT_HEADER = 'X-Disable-Error-Interceptor';
  private apiPcPortfolioConf: any;

  constructor(
    protected http: HttpClient,
    protected errorHandler: ErrorManagementService,
    @Inject('environment') protected apiConf: any,
    private refactorService: ReIssueRefactorService
  ) {
    super(http, apiConf);
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;
  }

  getPcQuestionnaires(contract: string): Observable<PcQuestionnaireFlatIResponse> {
    return this.http.get<PcQuestionnaireFlatIResponse>(this.getQuestionnairesUri(contract)).pipe(
      share()
    );
  }

  getQuestionnaires(contract: string): Observable<QuestionnaireFlatIResponse> {
    return this.http.get<PcQuestionnaireFlatIResponse>(this.getQuestionnairesUri(contract)).pipe(
      share(),
      map(res => this.refactorService.questionnaireFactor(res))
    );
  }

  putQuestionnaire(contract: string, questionnaireFlat: QuestionnaireFlatIResponse, code: string): Observable<QuestionnaireFlatIResponse> {
    return this.http.put<PcQuestionnaireFlatIResponse>(
      this.getQuestionnairesUri(contract), this.refactorService.questionnairePCFactor(questionnaireFlat)
    ).pipe(
      share(),
      map(res => this.refactorService.questionnaireFactor(res))
    );
  }

  putPcQuestionnaire(contract: string, questionnaireFlat: QuestionnaireFlatIResponse): Observable<Array<QuestionFlateResponse>> {
    return this.http.put<Array<QuestionFlateResponse>>(
      this.getQuestionnairesUri(contract), this.refactorService.questionnairePCFactor(questionnaireFlat)
    ).pipe(
      share()
    );
  }

  putQuotation(contract: string): Observable<Quotation> {
    return this.http.put<PcQuotation>(this.getQuotationUri(contract), null).pipe(
      share(),
      map(res => this.refactorService.quotationFactor(res))
    );
  }

  getClause(contract: string): Observable<ClausesResponse> {
    return this.http.get<PcClause[]>(this.getClausesUri(contract)).pipe(
      share(),
      map((output) => {
        return this.refactorService.clausesFactory(output);
      }));
  }

  getAllClauses(contract: string): Observable<Array<Clause>> {
    return this.http.get<Array<Clause>>(this.getAllClausesUri(contract)
    ).pipe(
      share(),
    );
  }

  getPolicyContact(contract: string) {
    return this.http.get<PolicyContact>(this.getPolicyContactUri(contract));
  }

  putAssetClauses(resourceId: string, clause: PcClause[]) {
    return this.http.put<PcClause[]>(this.putClausesUri(resourceId), clause).pipe(
      share(),
      map((output) => {
        return this.refactorService.clausesFactory(output);
      }));
  }

  putClauses(resourceId: string, clause: PcClause[]) {
    return this.http.put<PcClause[]>(this.getAllClausesUri(resourceId), clause).pipe(
      share(),
      map((output) => {
        return this.refactorService.clausesFactory(output);
      }));
  }

  getUnit(proposalId: string, assetKey: string, sectionID: string): Observable<PcUnit[]> {
    return this.http.get<PcUnit[]>(
      this.getUnitUri(proposalId, assetKey, sectionID)
    ).pipe(
      share(),
      map(res => {
        return res;
      })
    );
  }

  putUnitSelected(proposalId: string, assetKey: string, sectionID: string, unitSelected: PcUnit): Observable<PcUnit[]> {
    const pcUnits: PcUnit[] = new Array<PcUnit>();
    pcUnits.push(unitSelected);
    return this.http.put<PcUnit[]>(
      this.getUnitUri(proposalId, assetKey, sectionID), pcUnits
    );
  }

  putPackets(proposalId: string, assetKey: string, packets: Package[]): Observable<Package[]> {
    return this.http.put<Package[]>(
      this.putPacketUri(proposalId, assetKey), packets
    );
  }


  putFlexDiscountOnTotal(proposalId: string, flexDiscount: FlexDiscountParam): Observable<GenericEntityPcPortfolio> {
    return this.http.put<GenericEntityPcPortfolio>(
      this.putFlexDiscountOnTotalUri(proposalId), flexDiscount
    );
  }

  putFlexDiscountOnSingleUnits(proposalId: string, flexDiscountUnits: FlexDiscountUnit[]): Observable<GenericEntityPcPortfolio> {
    return this.http.put<GenericEntityPcPortfolio>(
      this.putFlexDiscountOnSingleUnitUri(proposalId), flexDiscountUnits
    );
  }

  getDiscountFund(proposalId: string): Observable<DiscountFund[]> {
    return this.http.get<DiscountFund[]>(
      this.getDiscountFundUri(proposalId));
  }

  putInputPremium(proposalId: string, inputPremium: InputPremium): Observable<any> {
    return this.http.put(this.putInputPremiumUri(proposalId), inputPremium);
  }

  putBeneficiariesByUnit(proposalId: string, beneficiary) {
    return this.http.put<any>(
      this.putBeneficiariesByUnitUri(proposalId), beneficiary
    );
  }

  putUnitClauses(proposalId: string, assetKey: string, unitCode: string, clauses: Clause[]) {
    return this.http.put<Array<Clause>>(this.putUnitClausesUri(proposalId, assetKey, unitCode), clauses);
  }
  putPacketUri(proposalId: string, assetKey: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/assets/' +
      assetKey +
      '/packages';
  }

  putBeneficiariesByUnitUri(proposalId) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      proposalId +
      '/beneficiariesbyunit';
  }

  getUnitUri(proposalId: string, assetKey: string, sectionID: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/assets/' +
      assetKey +
      '/product/sections/' +
      sectionID +
      '/units';
  }

  putInputPremiumUri(proposalId: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'proposals/' +
      proposalId +
      '/inputPremium';
  }


  getUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contract;
  }

  getQuestionnairesUri(contract: string): string {
    return this.getUri(contract) + '/questionnaires';
  }

  getQuotationUri(contract: string): string {
    return this.getUri(contract) + '/quotation';
  }

  getClausesUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      contract +
      '/clauses' +
      '?level=quotation';
  }

  getAllClausesUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      contract +
      '/clauses';
  }

  putClausesUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      contract +
      '/clauses' +
      '?level=quotation';
  }

  getPolicyContactUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      contract +
      '/contact';
  }

  getSectionProduct(proposalId: string, assetKey: string): Observable<PcInsuredAsset> {
    return this.http.get<PcInsuredAsset>(
      this.getSectionProductUti(proposalId, assetKey)
    ).pipe(
      share(),
      map(res => {
        return res;
      })
    );
  }

  getSectionProductUti(proposalId: string, assetKey: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/assets/' +
      assetKey +
      '/product/sections';
  }

  getAssetsWithSections(proposalId: string): Observable<PcInsuredAsset[]> {
    return this.http.get<PcInsuredAsset[]>(
      this.getAssetsWithSectionsUri(proposalId)
    ).pipe(
      share()
    );
  }

  getAssetsWithSectionsUri(proposalId: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/assets?sections=true&packages=true';
  }

  putPolicyContact$(proposalId, mainAddress, mail: any) {
    const body: PolicyContact = new PolicyContact(mainAddress, mail, null);

    return this.http.put<PolicyContact>(this.putPolicyContactUri(proposalId), body);
  }

  getProposalCommissions(proposalId: string): Observable<CommissionsWrap> {
    return this.http.get<CommissionsWrap>(
      this.getProposalCommissionsUri(proposalId)
    ).pipe(
      share(),
      map(res => {
        return res;
      })
    );
  }

  putProposalCommissions(proposalId: string, inputCommissions: CommissionsWrap, tag: string): Observable<GenericEntityPcPortfolio> {
    return this.http.put<GenericEntityPcPortfolio>(
      this.getProposalCommissionsUri(proposalId), inputCommissions).pipe(
      catchError(this.errorHandler.handleErrorWithSnackBar(tag) )
    );
  }

  getProposalCommissionsUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      contract +
      '/commissions';
  }

  protected putPolicyContactUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/contact';
  }
  protected putUnitClausesUri(proposalId: string, assetKey: string, unitCode: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      proposalId +
      '/clauses/asset/' +
      assetKey +
      '/unit/' +
      unitCode;
  }

  putNotes(proposalId: string, notes): Observable<Notes> {
    return this.http.put<Notes>(
      this.getBaseUri(proposalId) + '/notes',
      notes
    ).pipe(
      share()
    );
  }

  private getBaseUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId;
  }

  putBeneficiariesByAssetAndByUnit(proposalId: string, asseKey: string, unitCode: string, beneficiary) {
    return this.http.put<any>(
      this.putBeneficiariesByAssetAndByUnitUri(proposalId, asseKey, unitCode), beneficiary
    );
  }

  putThirdContactPerson(resourceId: string, emitter: {
    idLatestPhotosThirdContactPerson: any
  }) {
    return this.http.put<any>(
      this.putThirdContactPersonUri(resourceId), emitter
    );
  }

  getBeneficiariesByAssetAndByUnit(proposalId: string, asseKey: string, unitCode: string) {
    return this.http.get<BeneficiaryDTO>(
      this.putBeneficiariesByAssetAndByUnitUri(proposalId, asseKey, unitCode)
    ).pipe(
      share(),
      map(res => {
        return res;
      })
    );
  }

  deleteBeneficiariesByAssetAndByUnit(proposalId: string, asseKey: string, unitCode: string) {
    return this.http.delete<any>(
      this.putBeneficiariesByAssetAndByUnitUri(proposalId, asseKey, unitCode));
  }

  deleteThirdContactPerson(resourceId: string) {
    return this.http.delete<any>(
      this.putThirdContactPersonUri(resourceId));
  }

  putBeneficiariesByAssetAndByUnitUri(proposalId, assetkey, unitCode) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      proposalId +
      '/beneficiaries/assets/' +
      assetkey +
      '/units/' +
      unitCode;
  }

  private putThirdContactPersonUri(resourceId: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      resourceId +
      '/beneficiaries/third-contact-person';
  }

  putFlexDiscountOnTotalUri(proposalId: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/discounts/';
  }

  putFlexDiscountOnSingleUnitUri(proposalId: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/discount-units/';
  }

  getDiscountFundUri(proposalId: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/discount-fund/';
  }
}

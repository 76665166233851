import { AfterViewChecked, ChangeDetectorRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { QuestionnaireFlatI } from '@rgi/ng-passpro';
import { NotifierService } from '@rgi/portal-ng-core';
import { Questionnaire, QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { combineLatest, EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/internal/operators';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';
import { Authorization } from '../../models/authorization.model';
import { EMPTY_STR, ERROR_TYPE_CURRENT, JS_EVENT, PV_TOKEN, STEP, StepperConfiguration } from '../../models/consts/lpc-consts';
import { Policy } from '../../models/life-detail.model';
import { NOTIFY_AFTER_PUBLISH, NOTIFY_ON_CLOSE_AFTER_PUBLISH } from '../../models/notify-model';
import { OperationPropertyCode } from '../../models/operation-property-code.enum';
import {
  ConfirmOperationDefinition,
  InputFieldDefinition, OperationProperty,
  PV_ERROR_SEVERITY_VALUE,
  PostsalesError,
  PostsalesOperationObject,
  PostsalesOperationsResponse,
  QuestionnaireDefinition,
  Role,
  RoleDefinition
} from '../../models/postsales-operations-response.model';
import { PostsalesQuestionnaire } from '../../models/postsales-questionnaires.model';
import { PostsalesSession } from '../../models/postsales-session';
import { AnagSubject, PARTY_COMPLETE_KO, PARTY_COMPLETE_OK } from '../../models/subject.model';
import { LpcModalComponent } from '../../modules/lpc-modal/lpc-modal/lpc-modal.component';
import { LpcQuestionnaireComponent } from '../../modules/lpc-questionnaire/lpc-questionnaire/lpc-questionnaire.component';
import { LpcRolesStepWrapperComponent } from '../../modules/lpc-roles-step/lpc-roles-wrapper/lpc-roles-step-wrapper.component';
import { LpcStepperComponent } from '../../modules/lpc-stepper/component/lpc-stepper/lpc-stepper.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PostsalesOperations } from '../../services/postsales-operations.interface';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { PlcQuestionnairesUtils } from '../../utils/plc-questionnaires-utils';
import { PVErrorSeverity } from './../../models/postsales-operations-response.model';
import { PlcQuestService } from './../../services/plc-quest.service';
import {_DOCUMENT_, DocumentEvent} from '../../models/document.model';
import { RoleType } from '../../models/enum/lpc-subjects.enum';

@Directive()
export abstract class AbsOperationComponent implements OnInit, OnDestroy, AfterViewChecked {

  public static readonly FE_ERROR_ID = '_feERROR';
  public static readonly FE_GENERIC_ERROR = 'lpc_fields_generic_error';
  public static readonly FIN = 'FIN';
  public static readonly ADVER = 'ADVER';
  public static readonly OPERATION_ROLES = 'operationRoles';
  public static readonly ERROR = 'error';
  public static readonly HLT = 'HLT';
  public static readonly QUEST_FIN = 'questsFin';
  public static readonly QUEST_HLT = 'questsHlt';
  public static readonly ADD = 'add';
  public static readonly ADD_SUB = 'addSub';
  public static readonly DELETE = 'delete';
  public static readonly DELETE_SUB = 'deleteSub';
  public static readonly CLEAN_ROLE = 'cleanRole';
  public static readonly SELECTED_ROLE_EQUAL_HOLDER = 'selectedRoleEqualHolder';
  public static readonly CHECK_BOX_WARNING = 'checkboxWarning';
  public static readonly _1OEFF = '_1OEFF';
  /**
   * @description
   * an object of type StepperConfiguration.
   * please check out the documentation of the [StepperConfiguration](./../../models/consts/lpc-consts.ts) to see the object structure.
   */
  public STEP: StepperConfiguration = STEP;

  @Output() eventPropagation = new EventEmitter<any>();
  @ViewChild(LpcStepperComponent, {static: true}) stepper: LpcStepperComponent;

  @ViewChild('financialQuestionnaire') financialQuestionnaire: LpcQuestionnaireComponent;
  @ViewChild('healthQuestionnaire') healthQuestionnaire: LpcQuestionnaireComponent;
  @ViewChild('avcQuestionnaire') avcQuestionnaire: LpcQuestionnaireComponent;
  /**
   * @description
   * OPERATION ROLES STEP
   * lo step si istanzia da solo quando deve essere visibile, basta inserire nell'HTML
   * @example
   * ```html
   * <lpc-step ...>
   *    <ng-template #lpcRoleStep></ng-template>
   *  </lpc-step>
   * ```
   * per estendere lo step estendere LpcRolesStepComponent e aggiungere il provide a progetto
   */
  @ViewChild(LpcRolesStepWrapperComponent) stepRoles: LpcRolesStepWrapperComponent;

  public key;
  public isOperationRoleStepPresent = false;
  public operationRolesAdmitted: RoleDefinition[] = [];
  public operationRoles: Role[] = [];
  public operationRolesCreateValue: Role[] = [];
  public roleCodeToAdd: RoleType;
  public subRoleCodeToAdd: RoleType;
  public subjectToAdd: string;
  // blocca l'eliminazione dei beneficiari inseriti e non permette altri inserimenti
  public blockBeneficiaries: boolean;
  public dateDefinitions: InputFieldDefinition[] = [];
  public nextLabelModal = null;
  public authorizationId = EMPTY_STR;
  // PPEVO Questionaries
  public showSectionQuest = false;
  public showSectionQuestFin = false;
  public showSectionQuestHlt = false;
  public showSectionQuestFinAfterInit = true;
  public showSectionQuestHltAfterInit = true;
  public showSectionQuestAfterInit = true;
  public questionnairesForBackend: PostsalesQuestionnaire[] = [];
  public questionnairesFromAuthorization: string[] = [];
  public questFactorsArray = [];
  public disabledQuestionArray = [];
  public defaultAnswersArray = [];
  // flag auth
  public isAuth = false;
  public printMode: string = null;
  public isConfirmAndAccept = false;
  public effectiveDate;
  public questError: PostsalesError = {
    context: EMPTY_STR,
    errorId: EMPTY_STR,
    errorMessage: EMPTY_STR,
    severity: AbsOperationComponent.ERROR,
    type: ERROR_TYPE_CURRENT
  };
  public formGroup: UntypedFormGroup = this.getFormGroup();
  protected $subscriptions: Subscription[] = [];
  protected $session: PostsalesSession;
  protected _questionnaireDefinitions: QuestionnaireDefinition[] = [];
  protected $modifiedInputs: { [key: string]: boolean } = {};
  protected abstract operationDataKey: string;
  protected _createOperationData: { [p: string]: any };
  protected $operationProperties: OperationProperty[] = [];
  // per attivare i messaggi di errore all'interno di uno step è necessario inserire l'input [feErrors]="feErrors"
  // che attiva i messaggi FE che verranno svuotati ad ogni update
  protected $feErrors: PostsalesError[] = [];
  protected $errors: PostsalesError[] = [];
  protected $activeStep: number;
  protected $serviceError = false;
  protected $modalMessage: string;
  protected $openMessageModal = false;
  protected $policy: Policy;
  protected $publishMessage: string;
  protected _validQuestsCode: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  protected _validUnfilteredQuestCode: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  private $lastMovementId: string;

  public get formGroupValue(): { [key: string]: any } {
    return this.formGroup.getRawValue();
  }

  /** @description get method that returns the current session  */
  public get session(): PostsalesSession {
    return this.$session;
  }

  /** @description get method that returns the stocked operation properties during the update */
  public get operationProperties(): OperationProperty[] {
    return this.$operationProperties;
  }

  /** @description get method that returns all the front-end errors */
  public get feErrors(): PostsalesError[] {
    return this.$feErrors;
  }

  /** @description set method to valorize the array of errors */
  public set errors(errors: PostsalesError[]) {
    this.$errors = errors ? errors : [];
  }

  /** @description get method that returns all the errors which also concatenating the front-end error array */
  public get errors(): PostsalesError[] {
    // decomment if you want to returns an array without duplicated messages
    /* return Array.from(
      new Set(this.$errors.concat(this.$feErrors).map(a => a.errorMessage))
    ).map(msgs => this.$errors.concat(this.$feErrors).find(a => a.errorMessage === msgs)); */
    return this.$errors.concat(this.$feErrors);
  }

  /** @description get method that returns the current active step */
  public get activeStep(): number {
    return this.$activeStep;
  }

  public get serviceError(): boolean {
    return this.$serviceError;
  }

  public get modalMessage(): string {
    return this.$modalMessage;
  }

  public get openMessageModal(): boolean {
    return this.$openMessageModal;
  }

  public get isAtLastStep(): boolean {
    return this.stepper && this.stepper.isAtLastStep;
  }

  public get policy(): Policy {
    return this.$policy;
  }

  public get publishMessage(): string {
    return (this.$publishMessage && this.$publishMessage !== EMPTY_STR) ? this.$publishMessage : null;
  }

  public get productCode(): string {
    return this.$policy ? this.$policy.productCode : null;
  }

  public set productCode(productCode) {
    if (this.$policy) {
      this.$policy.productCode = productCode;
    }
  }

  /** @description booleano che indica se il flusso corrente è una richiesta di autorizzazione */
  public get isAuthFlow() {
    return !!this.session.authorizationId;
  }

  get getContractId(): string {
      return this.$policy && this.$policy.objectId;
  }

  get getOperationCode(): string {
    return this.$session && this.$session.operation;
  }

  public get questionnaireDefinitions(): QuestionnaireDefinition[] {
    return this._questionnaireDefinitions;
  }

  get validQuestsCode(): Map<string, Map<string, boolean>> {
    return this._validQuestsCode;
  }

  get validUnfilteredQuestCode(): Map<string, Map<string, boolean>> {
    return this._validUnfilteredQuestCode;
  }

  get documentsConfig() {
    return this.operations && this.operations.documentsConfig;
  }

  public get activeStepId(): string {
    return this.stepper && this.stepper.activeStep && this.stepper.activeStep.id;
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperations,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    protected injector: any,
    protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    this.productCode = EMPTY_STR;
  }

  ngAfterViewChecked(): void {
    this.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubscriptions();
    if (this.questCacheService) {
      this.questCacheService.clearAll();
    }
  }

  abstract ngOnInit(): void;

  public showQuestionnaireByType(type: string): boolean {
    const isInDefinition = this._validQuestsCode.has(type);
    let showPpevoQuestionnaire;
    if (type === AbsOperationComponent.FIN) {
      showPpevoQuestionnaire = this.showSectionQuestFin;
    } else if (type === AbsOperationComponent.ADVER) {
      showPpevoQuestionnaire = this.showSectionQuest;
    } else {
      showPpevoQuestionnaire = this.showSectionQuestHlt;

    }
    return isInDefinition || showPpevoQuestionnaire;
  }

  /** @description Method used to return back on the previuos step */
  public onBack(): void {
    this.stepper.back();
  }

  /** @description Checks if the current step is active */
  public isActiveStep(step: string): boolean {
    const currentStep = this.stepper.steps.find(s => s.id === step);
    return currentStep ? currentStep.active : false;
  }

  /** @description returns the id of the next step accordin to the id passed as a parameter */
  public getNextStep(step: string): string {
    return this.stepper.getNextStep(step);
  }

  /** @description Determine if the the current step is after the id passed as parameter */
  public isAfterId(id: string): boolean {
    return this.stepper.isAfterId(id);
  }

  /** @description Determine if the the current step is before the id passed as parameter */
  public isBeforeId(id: string): boolean {
    return this.stepper.isBeforeId(id);
  }

  /** @description Checks if the step passed as parameter is the current active or if it's passed */
  public isActiveOrPassedStep(step: string) {
    return this.isActiveStep(step) || this.isAfterId(step);
  }

  /**
   * @description Method that checks if you are navigating from a questionnaire step or from the roles step,
   * for the first case will be called the {@link nextOnQuestionary()} for the second case {@link nextOnOperationRoles()}
   * otherwise it calls the {@link nextDefault()}.
   * @param step type: `string`. the id of the step
   * @param publish type: `boolean`. which determine whether to call the publish or not.
   *  default is false, should be deprecated because the publish boolean logic is calculated on {@link nextDefault()},
   *  with this following pice of code:
   * ```java
   * const toBePublished = (isConfirmAndAccept && !bErrors) || this.stepper.isAtLastStep;
   * ```
   * @param context type: `string`. it's default value is the same as the param {@link step}.
   * @param isConfirmAndAccept type: `boolean`. the default is false.
   *  It's a boolean parameter that calls the confirm and accept service for the authorization and then calls the publish of the variation.
   */
  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    // this.empty_feERROR(step);
    if (this.isQuestStep(step)) {
      this.nextOnQuestionary(step, publish, context, isConfirmAndAccept); // richiama nextDefault
    } else if (step === AbsOperationComponent.OPERATION_ROLES) {
      this.nextOnOperationRoles(step); // richiama nextDefault
    } else {
      this.nextDefault(step, publish, context, isConfirmAndAccept);
    }
  }

  /**
   * @description
   * Main method used to navigate through the stepper.
   * First it will check if there's any systemError, if not it will checks if the form is valid and there's not feErrors in that context,
   * if not first it will calls the valorization for the PPEVO questionnaires then it will calls the {@link updateDraft()},
   * if it's the last step it will calls automatically the {@link publish()}
   * for the parameters is same as {@link onNext()}. Otherwise it will show you an error on the flow.
   */
  public nextDefault(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false, opDataType?: string) {
    if (PlcObjectUtils.hasSystemError(this.errors)) {
      return;
    }
    if (this.isUpdateToCall(step)) {
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, step, this).pipe(
          switchMap((result: any[]) => {
            this.questFactorsArray = result;
            this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
            this.defaultAnswersArray = this.plcQuestService.defaultAnswerBySec(this.operationDataKey, step, this);
            return this.updateDraft(step, false, opDataType);
          })
        ).subscribe((resultUpdate: PostsalesOperationObject) => {
          this.detectChanges();
          const bErrors = resultUpdate.errors && resultUpdate.errors.length > 0;
          const toBePublished = (isConfirmAndAccept && !bErrors) || this.stepper.isAtLastStep;
          this.mapErrorsOnUpdate(toBePublished, resultUpdate);
          if (!this.hasBlockingErrorsOnSteps(context)) {
            this.doPublishOrNext(toBePublished, isConfirmAndAccept);
          }
        })
      );
    } else {
      this.setFeErrors(step);
    }
  }

  protected mapErrorsOnUpdate(toBePublished: boolean, resultUpdate: PostsalesOperationObject) {
    if (toBePublished && !resultUpdate.finalizable) {
      resultUpdate.errors.map(error => {
        this.mapError(error);
      });
      this.errors = resultUpdate.errors;
    }
  }

  public nextOnOperationRoles(step = AbsOperationComponent.OPERATION_ROLES, publish = false, context = step, isConfirmAndAccept = false) {
    const errorMessages = this.stepRoles.checkRoles(); // check interno dello step
    if (errorMessages && errorMessages.length > 0) {
      this.setCustomFeErrorsVector(step, errorMessages, AbsOperationComponent.ERROR);
    } else {  // check esterno del service
      this.$subscriptions.push(
        this.operations.checkOperationRolesOnNext(this.formGroupValue.operationRoles).subscribe(errorMessagesResponse => {
          if (errorMessagesResponse && errorMessagesResponse.length > 0) {
            this.setCustomFeErrorsVector(step, errorMessagesResponse, AbsOperationComponent.ERROR);
          } else {
            this.nextDefault(step, publish, context, isConfirmAndAccept);
          }
        })
      );
    }
  }

  /* private empty_feERROR(step: string) { // li svuoto in quanto la condizione del isUpdateToCall si occupera di reinserire gli errori
    this.$feErrors = this.$feErrors.filter(error => error.context === step && error.errorId === AbsOperationComponent.FE_ERROR_ID);
  } */

  protected orderQuestionaries() {
    if (!!this.avcQuestionnaire) {
      this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService
        .checkQuestionnariesOrder(this.avcQuestionnaire);
    }
  }

  /**
   * @description
   * se è presente un form con il nome dello step attuale, controllo se è disabilitato o valido per proseguire
   * se non è presente proseguo comunque
   */
  protected isUpdateToCall(step: string) {
    this.$feErrors = this.$feErrors.filter(error => error.context === step && error.errorId === AbsOperationComponent.FE_ERROR_ID);
    const fgStep = this.formGroup.get(step);
    return (!fgStep || fgStep.disabled) ? true : this.formGroup.get(step).valid;
  }

  public nextOnQuestionary(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false, opDataType?: string) {
    if (this.questCacheService &&
      (step === AbsOperationComponent.QUEST_FIN && this.showSectionQuestFinAfterInit) ||
      (step === AbsOperationComponent.QUEST_HLT && this.showSectionQuestHltAfterInit) ||
      (step === STEP.QUESTS.id && this.showSectionQuestAfterInit)
    ) {
      const questType = this.getQuestTypeFromStep(step);
      if (this.questCacheService.checkCompile(questType)) {
        const questCache = this.questCacheService.get(this.composeKey(this.key, step));
        this.$subscriptions.push(
          this.operations.checkQuestionnaireOnNext(this, questCache, step)
          .subscribe(errorMessages => {
            if (errorMessages && errorMessages.length > 0) {
              this.setCustomFeErrorsVector(step, errorMessages, AbsOperationComponent.ERROR);
            } else {
              this.nextDefault(step, publish, context, isConfirmAndAccept, opDataType);
            }
          })
        );
      } else {
        this.setQuestionaryError(step);
      }
    }
  }

  public getQuestTypeFromStep(step: string) {
    switch (step) {
      case AbsOperationComponent.QUEST_FIN:
        return AbsOperationComponent.FIN;
      case AbsOperationComponent.QUEST_HLT:
        return AbsOperationComponent.HLT;
      case STEP.QUESTS.id:
        return AbsOperationComponent.ADVER;
    }
  }

  public doPublishOrNext(publish: boolean, isConfirmAndAccept = false): void {
    // i'll render the warning step in case of errors and i'm at the last step
    if (publish && this.isAtLastStep && !!this.errors.length) {
      this.detectChanges();
      this.stepper.next();
      if (this.stepper.isCurrentStepId(STEP.WARNING.id)) {
        return ;
      }
    }
    if (isConfirmAndAccept && publish) {
      this.publish(isConfirmAndAccept);
    } else if (publish) {
      this.publish();
    } else {
      this.stepper.next();
    }
  }

  public handleDocumentEvent(event: string | DocumentEvent) {
    if (typeof event !== 'string') {
      return this.handleCommonDocumentEvent(event);
    }
    if (event === 'exit') {
      this.closeCardWithoutModal();
    } else if (event === 'endDocLoading') {
      this.notifierService.notifyComponent('END_DOCUMENTS_LOAD');
    } else {
      this.eventPropagation.emit(event); // other events (like start/stop loader)
    }
  }

  public handleCommonDocumentEvent(docEvent: DocumentEvent) {
    const documentService = this.operations.getDocumentService();
    if (!documentService) {
      return;
    }
    let fileUrl = '';
    documentService.downloadDocument(this.$lastMovementId, docEvent.document.documentCode).pipe(
      switchMap((resp: {fileUrl: string, code: string}) => {
        if (!resp) {
          return of(null);
        }
        try {
          if (!!this.injector.get('fileDownload')) {
            const apiConf = this.injector.get('API_CONF');
            const coreConf = this.injector.get('CORE_CONF');
            // "/api",  "/rest",  "/system",   resp.fileUrl
            fileUrl = resp.fileUrl;
            this.injector.get('fileDownload').getFile(apiConf.server, apiConf.base_url, coreConf.system_area, `${resp.fileUrl}`);
            return of(null);
          }
        } catch (error) {
          console.error('KO injection');
          return of({error: 'KO_injection', fileUrl: resp.fileUrl});
        }
      }),
      switchMap((firstTryKo: {error: string, fileUrl: string}) => {
        if (firstTryKo && firstTryKo.error === 'KO_injection') {
          return documentService.callDocument(firstTryKo.fileUrl);
        }
        return of(null);
      })
    ).subscribe(arraybuffer => {
      if (!!arraybuffer) {
        const contentType = documentService.getDocumentContentTypeByURL(fileUrl);
        const fileName = fileUrl.split('/').pop();
        const extension = fileName.split('.').pop();
        const windowTarget = '_blank';
        const windowFeatures = 'name=' + fileName;
        const blob = new Blob([arraybuffer], {type: contentType});
        const file = new File([blob], fileName, {type: contentType});
        const URL = window.URL;
        const downloadUrl = URL.createObjectURL(file);
        if (extension === _DOCUMENT_.EXTENSION_PDF || extension === _DOCUMENT_.EXTENSION_HTML) {
          window.open(downloadUrl, windowTarget, windowFeatures);
        } else {
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
        URL.revokeObjectURL(downloadUrl);
      }
    });
  }

  public setFeErrors(step: string): PostsalesError[] {
    this.$feErrors = [];
    const formErrors: Array<string | boolean> = this.getFormControlsErrors(step);
    if (!!formErrors.length) {
      formErrors.forEach(error => {
        error = error === true ? AbsOperationComponent.FE_GENERIC_ERROR : error;
        this.$feErrors.push({
          context: step,
          isFe: true,
          errorId: AbsOperationComponent.FE_ERROR_ID,
          errorMessage: this.translate.getImmediate((error as string)),
          severity: AbsOperationComponent.ERROR,
          type: ERROR_TYPE_CURRENT
        });
      });
    } else {
      this.$feErrors = [{
        context: step,
        isFe: true,
        errorId: AbsOperationComponent.FE_ERROR_ID,
        errorMessage: this.translate.getImmediate(AbsOperationComponent.FE_GENERIC_ERROR),
        severity: AbsOperationComponent.ERROR,
        type: ERROR_TYPE_CURRENT
      }];
    }
    return this.$feErrors;
  }

 protected getFormControlsErrors(step: string): Array<string | boolean> {
    const formControlErrors: any[] = PlcObjectUtils.getAllErrorMsgFromForm(this.formGroup);
    // returns only the custom errors
    const errors = formControlErrors.map(err => err.required || err.error).filter(el => !!el);
    return PlcObjectUtils.removeDuplicatesFromArray(errors);
  }

  public setCustomFeErrors(step: string, errorMessage: string, severity: PVErrorSeverity): PostsalesError[] {
    return this.$feErrors = [{
      context: step,
      isFe: true,
      errorId: '_fe' + severity.toUpperCase(),
      errorMessage: this.translate.getImmediate(errorMessage),
      severity,
      type: ERROR_TYPE_CURRENT
    }];
  }

  public setCustomFeErrorsVector(step: string, errorMessage: string[], severity: PVErrorSeverity, errorId = null) {
    const errorVector = [];
    errorMessage.forEach(msg => {
      errorVector.push({
        context: step,
        isFe: true,
        errorId: errorId || '_fe' + severity.toUpperCase(),
        errorMessage: this.translate.getImmediate(msg),
        severity
      });
    });
    this.$feErrors = this.$feErrors.concat(errorVector);
  }

  public onSlide(index: number) {
    this.$activeStep = index;
    if (this.isOperationRoleStepPresent) {
      this.stepRoles.loadOperationRoleStep(); // aggiorno i valori dello step
    }
  }

  public onDateChange(event) {
    this.$feErrors = [];
    this.$modifiedInputs[event.code] = true;
    if (event.code === AbsOperationComponent._1OEFF) {
      this.showSectionQuest = true;
      this.showSectionQuestFin = true;
      this.showSectionQuestHlt = true;
      this.effectiveDate = event.value;
    }
  }

  public hasBlockingErrorsOnSteps(...steps: string[]): boolean {
    return !!this.errors.filter(
      error => error.severity === AbsOperationComponent.ERROR && (error.context === STEP.FLOW.id || !!steps.includes(error.context))
    ).length;
  }

  public publish(publishAndAccept: boolean = false): void {
    this.$subscriptions.push(
      this.operations.publish(this.session, this.formGroupValue.note, publishAndAccept).pipe(
        switchMap((response: PostsalesOperationsResponse) => {
          if (publishAndAccept) {
            // sono nel flusso di salva accetta emetti -> devo fare delle chiamate in più
            // il ritorno sarà in ogni caso la response del publish qua sopra
            return this.publishAndAcceptCalls(response);
          } else {
            this.notifierService.notifyComponent(NOTIFY_AFTER_PUBLISH);
            return of(response);
          }
        }),
        switchMap((finalResponse: PostsalesOperationsResponse | any) => {
          // se non è finalizzabile non è confermata e non serve andare oltre
          // l'errore verrà visualizzato da goNextStep
          if (finalResponse.finalizable) {
            return this.operations.onEndPublish(finalResponse, publishAndAccept);
          } else {
            return of(finalResponse);
          }
        })
      ).subscribe((responsePublish: PostsalesOperationsResponse | any) => {
        this.notifierService.notifyComponent('MODIFY_VARIATION_OK'); // TODO: da spostare nel metodo goNextStep?
        this.goNextSteps(responsePublish);
      }, error => {
        this.openErrorModal(error);
      })
    );
  }

  public publishAndAcceptCalls(respNormalPubish): Observable<any> {
      const publishAuthorizationId = respNormalPubish.data.authorizationId;
      if (!!publishAuthorizationId) {
        // chiamata per recuperare il dettaglio dell'autorizzazione (contenente le azioni possibili)
        return this.authService.getAuthorizationDetail(publishAuthorizationId).pipe(
          concatMap((respAuthDetail: Authorization) => {
            if (!!respAuthDetail) {
              // controllo se è presente l'accetta autorizzazione
              const acceptAction = respAuthDetail.acceptableEvents.find(accept => accept.id === '20');
              if (!!acceptAction) {
                // chiamata che setta il nuovo stato dell'autorizzazione in accettata
                return this.authService.putAuthorizationStatus(
                  {authorizationId: publishAuthorizationId , eventId: acceptAction.id, manualNotes: null});
              } else {
                // non posso accettare l'autorizzazione -> salto la publish
                // non dovrebbe essere possibile perchè a quel punto non comparirebbe il pulsante di salva accetta emetti
                return of(null);
              }
            } else {
              return of(null);
            }
          }),
          concatMap(respAuthStatus => {
            if (!!respAuthStatus) {
              // chiamata che esegue la publish dell'autorizzazione e genera un movimento
              return this.authService.publishAuthorization(this.$policy.objectId, publishAuthorizationId);
            } else {
              return of(null);
            }
          }),
          switchMap(respAuthPublish => {
            if (!!respAuthPublish) {
              // il movimento va riportato da qua nella prima response (sarà da restituire quella)
              respNormalPubish.data.lastMovementId = respAuthPublish.lastMovementId;
            }
            return of(respNormalPubish);
          })
        );
      } else {
        // se non ho l'idAuth allora ritorno la publish normale (qualcosa è andato storto)
        // non dovrei entrare nel flusso di salva accetta emetti senza l'idAuth
        return of(respNormalPubish);
      }
    }

  // controllo se l'operazione è stata confermata e procedo allo step successivo
  protected goNextSteps(response: PostsalesOperationsResponse, isPublishAndAccept?: boolean) {
    if (response.finalizable) {
      this.setPublishMessage(response);
      this.setLastMovementId(response);
      this.authorizationId = !!response.data.authorizationIdGen ? response.data.authorizationIdGen : EMPTY_STR;
      this.printMode = !!response.data.printMode ? response.data.printMode : EMPTY_STR;
      this.stepper.next(); // document's step
    } else {
      response.errors.map(error => {
        this.mapError(error);
      });
      this.errors = response.errors;
    }
  }

  // questo metodo serve per settare il messaggio alla fine del flusso
  // viene passata la response della publish -> serve in EXT per recuperare alcune info che possono generare messaggi aggiuntivi
  public setPublishMessage(response: PostsalesOperationsResponse) {
    if (!this.isAuth) {
      this.$publishMessage =
        this.translate.getImmediate('lpc_Operation_of_executed_correctly',
          {operation: this.session.operationDescription});
    } else {
      this.$publishMessage =
        this.translate.getImmediate('lpc_AuthorizatioPolicyForwarded',
          {policyNumber: this.session.policyNumber});
    }
  }

  public setLastMovementId(response: PostsalesOperationsResponse) {
    this.$lastMovementId = response.data.lastMovementId;
  }

  /** @description maps the error with 'flow' context */
  public mapError(error: PostsalesError) {
    if (error.context === STEP.PAYMENT.id || error.context === STEP.QUESTS.id || error.context === STEP.CONFERMA.id
      || error.context === STEP.CONCLUDE.id || error.context === STEP.FUND_INVESTMENT.id) {
      error.context = STEP.FLOW.id;
    }
  }

  /** @description closes the post sales flow by opening a modal to confirm the action */
  public closeCard(confirm = true): void {
    if (confirm) {
      this.$subscriptions.push(
        fromPromise(
          this.openModal('Confirm', 'lpc_are_you_sure_you_want_to_cancel_this_operation', true).result
        ).subscribe(result => {
          if (result) {
            this.$closeCard();
          }
        })
      );
    } else {
      this.$closeCard();
    }
  }

  /** @description closes the post sales flow without any confirm modal */
  public closeCardWithoutModal(): void {
    this.$closeCard();
    this.notifierService.notifyComponent(NOTIFY_ON_CLOSE_AFTER_PUBLISH);
  }

  private $closeCard() {
    const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
    const s = sessionService.list().find(ss => ss.id === this.session.sessionId);
    if (s) {
      sessionService.remove(s.idSession);
    } else {
      throw new Error('Unable to find session ' + this.session.sessionId + ' with card');
    }
  }

  public updateData() {
    this.$subscriptions.push(this.onReload(STEP.DATE.id).subscribe());
  }

  public onReload(step: string): Observable<any> {
    this.$feErrors = [];
    return this.updateDraft(step, true);
  }

  protected unsubscribeSubscriptions() {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  protected initializeSession(): void {
    this.$session = Object.assign({}, this.operations.session);
    this.key = this.$session.sessionId + this.$session.policyNumber + this.$session.operation;
  }

  protected setSession(draft: string, contractor: string, managementNode: string): void {
    this.$session.draft = draft;
    this.$session.contractor = contractor;
    this.$session.managementNode = managementNode;
    this.anag.managementNode = managementNode;
  }

  protected createDraft(): Observable<PostsalesOperationObject> {
    this.formGroup = this.getFormGroup();
    return this.operations.createDraft(this.session).pipe(
      switchMap((result: PostsalesOperationObject) => {
        this.setSession(result.data.id, result.data.contractorId, result.data.managementNodeId);
        this.$operationProperties = PlcObjectUtils.asValidArray(
          result.definitions.operationProperties as OperationProperty[]
        );
        // ASMC-3603 questionnaires on createDraft -> authorizations flow + renewal-booking rinavigation
        if (result.data.questionnaires != null) {
          this.questionnairesFromAuthorization = result.data.questionnaires.map(q => q.id);
        }
        this._questionnaireDefinitions = this.getQuestionnaireDefinitions(
          result.definitions.questionnaires as QuestionnaireDefinition[]
        );

        this.errors = result.errors;
        this.fillDates(result.definitions.dates);
        this.detectChanges();
        this.formGroup.get(STEP.DATE.id)
          .setValue(
            PlcObjectUtils.mapInputFieldsAsDate(result.definitions.dates as InputFieldDefinition[]),
            {emitEvent: false}
          );

        // blocca l'eliminazione dei beneficiari inseriti e non permette altri inserimenti
        if (result.data.operationData.data) {
          this.blockBeneficiaries = !!result.data.operationData.data.blockBeneficiaries;
        }

        this._createOperationData = result.data.operationData.data;
        if (!!result.definitions.confirmOperation) {
          this.isConfirmAndAccept = (result.definitions.confirmOperation as ConfirmOperationDefinition).confirmEnable;
        }
        this.handleOperationRolesCreate(result);
        this.detectChanges();
        this.stepper.slideTo(0);
        return combineLatest([of(result), this.operations.getPolicy(result.data.contractId, this.session.managementNode)]);
      }),
      switchMap(([res, result]: [PostsalesOperationObject, Policy]) => {
        this.$policy = result;
        this.checkCacheServiceAndDisplayQMSteps();
        return of(res);
      }),
      catchError(err => {
        this.openErrorModal(err, true);
        return throwError(err);
      })
    );
  }

  protected detectChanges() {
    // @ts-ignore
    if (!this.cd.destroyed) {
      this.cd.detectChanges();
    }
  }

    public saveQuestAnd(step: string): Observable<any> {
      const questCacheKey = this.composeKey(this.key, step);
      if (this.questCacheService && this.isQuestStep(step) && this.questCacheService.get(questCacheKey)) {
        PlcQuestionnairesUtils.setQuestionnaireScore(this.questCacheService, AbsOperationComponent.FIN, this.productCode);
        const questionnaires: Map<string, PostsalesQuestionnaire> = new Map<string, PostsalesQuestionnaire>();
        // FIXME loader caller without the emit event
        this.eventPropagation.emit(JS_EVENT.LOADER.START);
        return this.questCacheService.persistAll().pipe(
          map((d) => { // FIXME rename d to something useful
            d.forEach((savedQuestionnaires) => {
              savedQuestionnaires.forEach((survey) => {
                const questionnairesScoreByFeId = this.getQuestionnairesScoreByQuestId(this.questCacheService.cache);
                this.questCacheService.cache.get(questCacheKey).forEach((questFlat, quest) => {
                  // fixme maybe we can always use questionnairesScoreByFeId
                  const score = quest.id.toString() === survey.questionnaire.id.toString()
                    ? questFlat.score : this.getScoreForPreviousSteps(survey.questionnaire.id, questionnairesScoreByFeId);
                  questionnaires.set(survey.uuid,
                      {
                        id: survey.uuid,
                        code: survey.questionnaire.code,
                        type: survey.questionnaire.questionnaireType.code,
                        descr: survey.questionnaire.name,
                        score
                      }
                    );
                });
              });
            });
            this.questionnairesForBackend = [].concat(...Array.from(questionnaires.values()));
            this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          })
        );
      } else {
        return of({});
      }
    }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<PostsalesOperationObject> {
    this.$feErrors = [];

    const effectiveOperationData = this.getEffectiveOperationData();
    const updateDraft$ = this.updateDraftService(effectiveOperationData, step, reload, opDataType)
    .pipe(
      tap((result: PostsalesOperationObject) => {
        this.updateDraftHandleResponse(result, step, reload, opDataType);
      }),
      catchError(error => {
        this.$openMessageModal = true;
        this.$serviceError = true;
        this.$modalMessage = error.error;
        return throwError(error);
      })
    );

    return this.saveQuestAnd(step).pipe(
      switchMap(() => {
        return updateDraft$;
      }
    ));
  }

  public updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    this.formGroup.get(STEP.DATE.id).setValue(result.data.dates, {emitEvent: false});
    this.errors = result.errors;
    this.isAuth = result.requireAuthorization;
    if (!!result.definitions) {
      if (!!result.definitions.confirmOperation) {
        this.isConfirmAndAccept = (result.definitions.confirmOperation as ConfirmOperationDefinition).confirmEnable;
      }
      if (!!result.definitions.operationProperties) {
        this.$operationProperties = PlcObjectUtils.asValidArray(
          result.definitions.operationProperties as OperationProperty[]
        );
      }
      this.handleOperationRolesValues(result);
    }

    // aggiorno i questionari che ottengo dalle definitions
    // in alcuni casi dei quest diventeranno visibili durante il flusso
    this._questionnaireDefinitions = this.getQuestionnaireDefinitions(
      result.definitions.questionnaires as QuestionnaireDefinition[]
    );

    const nextStep = this.stepper.getNextStep(step);
    this.checkCacheServiceAndDisplayQMSteps();
    if (!reload && this.isQuestStep(nextStep)) {
      const financialKey = this.composeKey(this.key, AbsOperationComponent.QUEST_FIN);
      const healthKey = this.composeKey(this.key, AbsOperationComponent.QUEST_HLT);
      const avcKey = this.composeKey(this.key, STEP.QUESTS.id);
      this._validQuestsCode.forEach((value, key1, map1) => {
        if (map1.size > 0) {
          if (!!this.financialQuestionnaire && key1 === this.financialQuestionnaire.type && nextStep === AbsOperationComponent.QUEST_FIN) {
            this.financialQuestionnaire.updateQuestionnairesOnCacheBy(financialKey, value);
            this.financialQuestionnaire.loadQuestionnairesByCode(map1);
          }
          if (!!this.avcQuestionnaire && key1 === this.avcQuestionnaire.type && nextStep === STEP.QUESTS.id) {
            this.avcQuestionnaire.updateQuestionnairesOnCacheBy(avcKey, value);
            this.avcQuestionnaire.loadQuestionnairesByCode(map1);
          }
          if (this.healthQuestionnaire && key1 === this.healthQuestionnaire.type && nextStep === AbsOperationComponent.QUEST_HLT) {
            this.healthQuestionnaire.updateQuestionnairesOnCacheBy(healthKey, value);
            this.healthQuestionnaire.loadQuestionnairesByCode(map1);
          }
        }
      });
    }
  }

  protected updateDraftService(effectiveOperationData, step: string, reload?: boolean, opDataType?: string)
  : Observable<PostsalesOperationObject> {
    return this.operations.updateDraft(this.session, this.formGroupValue.dates, this.formGroupValue.operationRoles,
      effectiveOperationData, this.$modifiedInputs, step, reload, opDataType, this.questionnairesForBackend);
  }

  // gestione della response per lo step dei ruoli dell'operazione
  // condiziona anche la visibilità dello step in base alla presenza delle definitions
  public handleOperationRolesValues(result) {
    if (!!result.definitions.operationRolesAdmitted) {
      this.isOperationRoleStepPresent = true;
      this.operationRolesAdmitted = (result.definitions.operationRolesAdmitted as RoleDefinition[])
      .sort((a, b) => {
        return Number(a.position) - Number(b.position);
      });
      this.operationRoles = result.data.operationRoles ? (result.data.operationRoles as Role[]) : [];
      this.formGroup.get(AbsOperationComponent.OPERATION_ROLES).setValue(this.operationRoles, {emitEvent: false});
      if (this.stepRoles) {
        this.stepRoles.updateOperationRoleStepData(this.operationRoles, this.operationRolesAdmitted, null);
      }
    }
  }

  public handleOperationRolesCreate(result) {
    this.operationRolesCreateValue = result.data.operationRoles ? (result.data.operationRoles as Role[]) : [];
    this.handleOperationRolesValues(result);
    if (this.isOperationRoleStepPresent) {
      // eseguo se lo step operationRoles è presente (usato in EXT)
      this.operations.handleOperationRolesCreate(result.data.operationRoles);
    }
  }

  protected removeQuestionnaireFromCache() {}

  protected getEffectiveOperationData() {
    return this.getTransformedOperationData();
  }

  public fillDates(dates, update = false) {
    this.dateDefinitions = (dates as InputFieldDefinition[]).map(dateDefinition => {
      if (!update) {
        this.$modifiedInputs[dateDefinition.code] = false;
      }
      return dateDefinition;
    });
  }

  protected getTransformedOperationData(): any {
    return (this.formGroupValue[this.operationDataKey]) ? this.formGroupValue[this.operationDataKey] : {};
  }

  protected abstract getFormGroup(): UntypedFormGroup;

  private checkCacheServiceAndDisplayQMSteps() {
    if (this.questCacheService) {
      this.showSectionQuest = true;
      this.showSectionQuestFin = true;
      this.showSectionQuestHlt = true;
    } else {
      this.showSectionQuestFinAfterInit = false;
      this.showSectionQuestAfterInit = false;
      this.showSectionQuestHltAfterInit = false;
    }
  }

  public countQuest(questType: string) {
    if (this.areOperationRolesChanged()) {
      this.checkAndCleanAdverQuestionnaire();
    }

    if (questType === AbsOperationComponent.ADVER) {
      this.showSectionQuest = this.avcQuestionnaire.hasQuestionnaires;
      this.showSectionQuestAfterInit = this.showSectionQuest;
    } else if (questType === AbsOperationComponent.FIN) {
      this.showSectionQuestFin = this.financialQuestionnaire.hasQuestionnaires;
      this.showSectionQuestFinAfterInit = this.showSectionQuestFin;
    } else if (AbsOperationComponent.HLT) {
      this.showSectionQuestHlt = this.healthQuestionnaire.hasQuestionnaires;
      this.showSectionQuestHltAfterInit = this.showSectionQuestHlt;
    }

    this.orderQuestionaries();
  }

  public checkAndCleanAdverQuestionnaire() {
    if (this.avcQuestionnaire.isQuestCompiledByType(AbsOperationComponent.ADVER)) {
      this.avcQuestionnaire.clearQuestionnaireByType(AbsOperationComponent.ADVER);
      this.setCustomFeErrors(STEP.QUESTS.id, 'Soggetti modificati. E\' necessario ricompilare il questionario.', 'warning');
    }
    // svuoto il questionario se sono cambiate
    // imposto questi ruoli come quelli iniziali per sistemare { indietro -> cambioRuoli-> avanti }
    this.operationRolesCreateValue = this.operationRoles;
  }

  public setQuestionaryError(step: string) {
    this.questError.context = step;
    this.questError.errorId = AbsOperationComponent.FE_ERROR_ID;
    this.questError.errorMessage = this.translate.getImmediate('lpc_error_mandatory_fields');
    this.questError.severity = AbsOperationComponent.ERROR;
    if (!this.$feErrors.find(error => error.context === step && error.errorId === AbsOperationComponent.FE_ERROR_ID)) {
      this.operations.errors.push(this.questError);
      this.$feErrors.push(this.questError);
      console.error(this.questError.errorMessage);
    }
  }

  public closePopUp() {
    this.nextLabelModal = this.translate.getImmediate('lpc_confirm');
    this.$modalMessage = this.translate.getImmediate('lpc_are_you_sure_you_want_to_cancel_this_operation');
    this.$openMessageModal = false;
  }

  public getQuestHlt(): Questionnaire[] {
    return (!!this.healthQuestionnaire) ? this.healthQuestionnaire.questionnaires : [];
  }

  public getQuestFin(): Questionnaire[] {
    return (!!this.financialQuestionnaire) ? this.financialQuestionnaire.questionnaires : [];
  }

  public getQuests(): Questionnaire[] {
    return (!!this.avcQuestionnaire) ? this.avcQuestionnaire.questionnaires : [];
  }

  /**
   * TODO: Metodo da rimuovere quando tutte le operazioni che
   * mostrano i questionari  avranno la gestione dei questaionari lato server (Oggetto capiente)
   */
  public isQuestionnaireVisible(): boolean {
    return false;
  }

  private openErrorModal(error: any, fatal: boolean = false): void {
    const message: string = error.error && error.error.message ? error.error.message : error.message;
    const modalRef: NgbModalRef = this.openModal('lpc_system_error', message, true, true);
    this.$subscriptions.push(
      fromPromise(modalRef.result).subscribe(_ => {
        this.closeCard(!fatal);
      })
    );
  }

  protected openModal(title: string, message: string, confirm = false, error = false, cancel = false): NgbModalRef {
    const modalRef: NgbModalRef = this.modalService.open(LpcModalComponent, {
      centered: true,
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    modalRef.componentInstance.title = this.translate.getImmediate(title);
    modalRef.componentInstance.message = this.translate.getImmediate(message);
    modalRef.componentInstance.confirmVisible = confirm;
    modalRef.componentInstance.closeVisible = cancel;
    modalRef.componentInstance.serviceError = error;
    if (!cancel) {
      modalRef.componentInstance.confirmLabel = this.translate.getImmediate('lpc_close_button');
    }
    return modalRef;
  }

  protected isQuestStep(step: string): boolean {
    return step === STEP.QUESTS.id || step === AbsOperationComponent.QUEST_FIN || step === AbsOperationComponent.QUEST_HLT;
  }

  public getOperationPropertyByCode(code: OperationPropertyCode | string): OperationProperty | undefined {
    return this.$operationProperties.find(property => property.code === code);
  }

  public hasOperationPropertyByCode(code: OperationPropertyCode | string): boolean {
    return !!this.getOperationPropertyByCode(code);
  }

  public getQuestMap(key: string): Map<string, boolean> {
    return this._validQuestsCode.get(key);
  }

  public getUnfilteredQuestMap(key: string): Map<string, boolean> {
    return this._validUnfilteredQuestCode.get(key);
  }

  protected getQuestionnaireDefinitions(questionnaireDefinitions: QuestionnaireDefinition[]): QuestionnaireDefinition[] {
    if (!!questionnaireDefinitions) {
      const definitions: QuestionnaireDefinition[] = questionnaireDefinitions;
      const filteredDefinitions: QuestionnaireDefinition[] = PlcQuestionnairesUtils.getPassQuestionnaireDefinitions(
        definitions
      );
      this._validQuestsCode = this.getValidQuestCodeFrom(filteredDefinitions);
      this._validUnfilteredQuestCode = this.getValidQuestCodeFrom(definitions);
      return filteredDefinitions;
    } else {
      return [];
    }
  }

  public getValidQuestCodeFrom(questionnaireDefinitions: QuestionnaireDefinition[]): Map<string, Map<string, boolean>> {
    const questMap: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
    questionnaireDefinitions.forEach(definition => {
      const mainKey: string = PlcQuestionnairesUtils.getQuestTypeByLevel(definition.level);
      if (!questMap.has(mainKey)) {
        questMap.set(mainKey, new Map<string, boolean>());
      }
      const mainMap: Map<string, boolean> = questMap.get(mainKey);
      mainMap.set(definition.code, definition.required);
    });
    return questMap;
  }

  public composeKey(key, ext: string): string {
    return `${key}${ext}`;
  }

  public loaderQuestManager(event: string) {
    this.eventPropagation.emit(event);
  }

  private getScoreForPreviousSteps(questId: number, scoreMapById: any) {
    const score = scoreMapById[questId];
    return score === undefined ? null : score;
  }

  private getQuestionnairesScoreByQuestId(cacheByKey: Map<string, Map<Questionnaire, QuestionnaireFlatI>>) {
    const caches: Map<Questionnaire, QuestionnaireFlatI>[] = Array.from(cacheByKey.values());
    return caches
      .map(x => Array.from(x.entries()))
      .reduce((acc, v) => [...acc, ...v], [])
      .reduce((acc, [k, v]) => ({...acc, [k.id]: v ? v.score : null}), {});
  }

  public openAnagSubjectModal() {
    this.anag.openSubjectModal(this);
  }

  // GESTIONE STEP RUOLI -----
  public handleRolesEvent(event) {
    switch (event.eventName) {
      case AbsOperationComponent.ADD:
        this.addRole(event.data);
        break;
      case AbsOperationComponent.ADD_SUB:
        this.addSubRole(event.data);
        break;
      case AbsOperationComponent.DELETE:
        this.deleteRole(event.data);
        break;
      case AbsOperationComponent.DELETE_SUB:
        this.deleteSubRole(event.data);
        break;
      case AbsOperationComponent.CLEAN_ROLE:
        this.cleanRole(event.data);
        break;
      case AbsOperationComponent.SELECTED_ROLE_EQUAL_HOLDER:
        this.operationRoles.push(event.data);
        break;
      case AbsOperationComponent.CHECK_BOX_WARNING:
        this.setCustomFeErrors(AbsOperationComponent.OPERATION_ROLES, event.data, 'warning');
        break;
      default:
        this.eventPropagation.emit(event.data);
    }
  }

  public propagateEventDefault(event) {
    this.eventPropagation.emit(event);
  }

  // se sono valorizzati subRoleCodeToAdd e subjectToAdd allora si tratta di un iserimento di un sottoRuolo
  // subRoleCodeToAdd -> codice del sottoRuolo da aggiungere
  // roleCodeToAdd -> codice del ruolo padre
  // subjectToAdd -> soggetto a cui aggiungere il nuovo subj
  public receiveAnagSubjectFromModal(subject: AnagSubject) {
    const subjRole = this.subRoleCodeToAdd != null && this.subjectToAdd != null ? this.subRoleCodeToAdd : this.roleCodeToAdd;

    this.updateOperationRolesStepDataCall(subjRole, subject).subscribe(this.cleanRoleInputFlags);
  }

  protected pushNewSubjToRolesArray(subject: AnagSubject) {
    let subjRole: Role = null;
    if (this.subRoleCodeToAdd != null && this.subjectToAdd != null) {
      // SUBROLE
      subjRole = AnagService.subjectToRole(subject, this.subRoleCodeToAdd);
      this.operationRoles.forEach((r) => {
        if (r.id === this.subjectToAdd || r.role === this.roleCodeToAdd) {
          if (!r.linkedSubjectsRoles) {
            r.linkedSubjectsRoles = [];
          }
          r.linkedSubjectsRoles.push(subjRole);
        }
      });
    } else {
      subjRole = AnagService.subjectToRole(subject, this.roleCodeToAdd);
      this.operationRoles.push(subjRole);
    }
  }

  public cleanRoleInputFlags() {
    this.roleCodeToAdd = null;
    this.subRoleCodeToAdd = null;
    this.subjectToAdd = null;
  }

  public addRole(roleType: RoleType) {
      this.roleCodeToAdd = roleType;
      this.subRoleCodeToAdd = null;
      this.subjectToAdd = null;
      this.openAnagSubjectModal();
  }

  public addSubRole(event: { subRoleCode: RoleType, roleCode: RoleType, subjId: string }) {
      this.roleCodeToAdd = event.roleCode;
      this.subRoleCodeToAdd = event.subRoleCode;
      this.subjectToAdd = event.subjId;
      this.openAnagSubjectModal();
  }

  public deleteRole(event: Role) {
    const opLength = this.operationRoles.length;
    this.operationRoles = this.operationRoles.filter(role => role.id !== event.id || role.role !== event.role);
    this.formGroup.get(AbsOperationComponent.OPERATION_ROLES).setValue(this.operationRoles, {emitEvent: false});
    if (opLength !== this.operationRoles.length) {
      this.updateOperationRolesStepDataCall().subscribe(this.cleanRoleInputFlags); // aggiorno solo se è cambiato il numero di soggetti
    }
  }

  public deleteSubRole(event: { subRoleCode: string, subSubjId: string, roleCode: string, subjId: string }) {
    let isSomethingChanged = false;
    this.operationRoles.forEach((role) => {
      if (role.role === event.roleCode) {
        if (role.linkedSubjectsRoles != null && role.linkedSubjectsRoles.length > 0) {
          const opLength = role.linkedSubjectsRoles.length;
          role.linkedSubjectsRoles = role.linkedSubjectsRoles.filter(sr => !(sr.role === event.subRoleCode && sr.id === event.subSubjId));
          isSomethingChanged = isSomethingChanged || (opLength !== role.linkedSubjectsRoles.length);
        }
      }
    });
    if (isSomethingChanged) {
      this.updateOperationRolesStepDataCall().subscribe(); // aggiorno solo se è cambiato il numero di soggetti
    }
  }

  public cleanRole(roleCode: string) {
    const opLength = this.operationRoles.length;
    this.operationRoles = this.operationRoles.filter(el => el.role !== roleCode);
    this.formGroup.get(AbsOperationComponent.OPERATION_ROLES).setValue(this.operationRoles, {emitEvent: false});
    if (opLength !== this.operationRoles.length) {
      this.updateOperationRolesStepDataCall().subscribe(); // aggiorno solo se è cambiato il numero di soggetti
    }
  }

  public updateOperationRolesStepDataCall(subjRole?: RoleType, subject?: AnagSubject): Observable <any> {
    const obsToCall = !!subjRole && !!subject ? this.anag.checkPartyCompleted(
      Number(subject.objectId),
      Number(subject.idLatestPhotos),
      subjRole,
      Number(this.session.managementNode)
    ) : of({result: PARTY_COMPLETE_OK, outcome: []});
      return obsToCall.pipe(
        switchMap(res => {
          if (res.result === PARTY_COMPLETE_KO) {
            const roleDescr = this.operationRolesAdmitted.find(ar => ar.code === subjRole).label;
            this.setCustomFeErrorsVector(
              AbsOperationComponent.OPERATION_ROLES,
              res.outcome.map(m => `${roleDescr} ${subject.nominative}: ${m}`),
              PV_ERROR_SEVERITY_VALUE.ERROR
            );
            return EMPTY;
          }
          if (!!subject) {
            this.pushNewSubjToRolesArray(subject);
          }
          return this.updateDraft(AbsOperationComponent.OPERATION_ROLES, true);
        })
    );
  }

  public areOperationRolesChanged() {
    if (this.isOperationRoleStepPresent) {
      if (this.isOperationRoleStepPresent && this.operationRolesCreateValue && this.operationRoles) {
        return !(
          this.operationRolesCreateValue.length > 0 &&
          this.operationRoles.length > 0 &&
          PlcObjectUtils.areIdRoleVectorsEquals(this.operationRolesCreateValue, this.operationRoles)
        );
      }
    }
    return false;
  }
  // GESTIONE STEP RUOLI -----

}

import {InjectionToken} from '@angular/core';
import {RgiRxTemplateInterpolationService, uuidv4} from '@rgi/rx';
import {IControllerService} from 'angular';
import {ActiveRoute, RgiRxRouteData, RoutingService} from '@rgi/rx/router';
import {PortalCardService} from './portal-card.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {Subscription} from 'rxjs';
import {ICoreResult} from './typings';


export const RGI_RX_PORTAL_TPL = 'templates/rgi-rx-routing-outlet.html';
export const RGI_RX_PORTAL_TPL_CONTENT = `
    <div id="rgi-rx-routing-wrapper-{{ card.id }}" data-qa="{{card.id}}" data-qa-type="portal-route" data-qa-ctx="{{card.name}}" data-qa-value="{{currentRoute}}">
        <rgi-rx-routing-outlet [id]="$$id" (ready)="__$onReady($event)"></rgi-rx-routing-outlet>
    </div>
`;


export interface PortalRouteOptions {
  idSessionParent?: string;

  navigationInSession: boolean;
  origin: string;
  card: {
    name: string;
  };
  isSubCard: boolean;
  isPopupCard: boolean;
}

/**
 * PassPortal Controller factory to allow binding with @rgi/rx/router API.
 * The factory takes in coreResult and pass it as the ActiveRoute data.
 * It sets the following scope properties:
 * - $$id : the id of the route (session id)
 * - $$route : the route to target into `@rgi/rx/router`
 * - $$routeData: any data to pass as ActiveRoute data
 * - $$options: any options to pass as ActiveRoute options
 * @see ActiveRoute
 * @see RoutingOutletComponent
 * @see RoutingService
 * @param destinationRoute the target route name in @rgi/rx/portal
 * @param originRoute the source route in PassPortal
 * @param data any data to override instead of using coreResult
 */
export function portalControllerFactory(destinationRoute: string, originRoute: string, data?: any) {
  RgiRxPortalCtrl.$inject = ['$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxPortalCardService', 'RgiRxTranslationService', 'RgiRxTemplateInterpolationService', 'sessionService'];

  function RgiRxPortalCtrl($scope,
                           $controller: IControllerService,
                           coreResult: ICoreResult,
                           rgiRxRoutingService: RoutingService,
                           portalCardService: PortalCardService,
                           translationService: RgiRxTranslationService,
                           templateInterpolationService: RgiRxTemplateInterpolationService,
                           sessionService: any
  ) {
    let translateLabelSub = Subscription.EMPTY;
    let subscribeRouteChange = Subscription.EMPTY
    $controller('CardBaseCtrl', {$scope});
    $scope.$$id = $scope.card.idSession && !$scope.card.isSubCard ? $scope.card.idSession : uuidv4();
    $scope.$$route = destinationRoute;
    $scope.$$routeData = data ? data : coreResult.getResult($scope.id, originRoute);
    $scope.$$options = getPortalRouteOptions($scope, originRoute);

    /**
     * @description a function to be called when the route changes
     * @param activeRoute
     */
    $scope.onRouteChange = (activeRoute: ActiveRoute) => {};
    /**
     * @description subscribe to the route change
     */
    subscribeRouteChange = rgiRxRoutingService.activeRouteChange$($scope.$$id).subscribe(activeRoute => {
      if ($scope.currentRoute === 'home') {
        return;
      }
      const portalCardConfig = portalCardService.searchCardByRouteInstance(activeRoute);
      if (portalCardConfig) {
        const portalRoute = portalCardConfig.routes.filter(isPortalRouteDefault).find(r => r.destination === activeRoute.route);
        if (!!portalRoute) {
          $scope.currentRoute = portalRoute.route;
          if (!$scope.card.isSubCard) {
            sessionService.setSubCardActiveByTemplate($scope.card, portalRoute.route);
          }
          if (portalRoute.label) {
            translateLabelSub = portalCardService.translateCardLabel(portalRoute, activeRoute.getRouteData()).subscribe(
              next => {
                $scope.card.title = next.interpolated;
                if (!!next.translated) {
                  $scope.card.title = next.translated;
                }
              }
            );
          }
        }
      }
      $scope.onRouteChange(activeRoute);
    })

    /**
     * @description a function to be called when the outlet is ready and can receive route changes.
     * Please do not override it.
     */
    $scope.__$onReady = () => {
      rgiRxRoutingService.navigate(destinationRoute, $scope.$$routeData, $scope.$$id, $scope.$$options);
    }



    $scope.$on('$destroy', () => {
      rgiRxRoutingService.destroy($scope.$$id);
      translateLabelSub.unsubscribe();
      subscribeRouteChange.unsubscribe();
    });
  }

  // portal does try to fetch the card by controller name as string
  RgiRxPortalCtrl.toLowerCase = () => {
    return RgiRxPortalCtrl.name.toLowerCase();
  };
  return RgiRxPortalCtrl;
}


export interface RgiRxPortalScope {
  $$id: string;
  $$route: string;
  $$routeData?: RgiRxRouteData<any>;
  $$options?: PortalRouteOptions;
  $$routeChangeOpts?: {
    destroyOrigin?: boolean
  };
  onRouteChange?: (activeRoute: ActiveRoute) => {};
}


/**
 * @description a function that can generate the PortalRouteOptions from the $scope and the origin route. Those options
 * are parsed and used by the ActiveRoute for the @rgi/rx/router.
 * @param $scope scope of the controller inheriting from CardBaseCtrl
 * @param origin
 */

export function getPortalRouteOptions($scope: any, origin: string): PortalRouteOptions {
  return {
    idSessionParent: $scope.card.idSessionParent,
    navigationInSession: !!$scope.card.navigationInSession,
    origin,
    card: {
      name: $scope.card.name
    },
    isSubCard: $scope.card.isSubCard,
    isPopupCard: $scope.card.isPopupCard
  };
}

/**
 * Basic type of PassPortal Card
 */
export interface PortalCard {
  title: string;
  tpl?: string;
  name: string;
  category: string;
  type: 'main' | 'sub';
  subCards?: any[];
  subCardsActive?: any[];
  /**
   * Whether the transition from the route type home to another route should create a new session
   */
  navigationInSession: boolean;
  functions?: string[];
  customize?: any;
  headerIconClass?: string;
}

/**
 * Definition of PassPortal route
 */
export type PortalRoute = PortalRouteWithCustomBindings | PortalRouteDefault;

interface PortalRouteBase {
  /**
   * The route name to bind in PassPortal
   */
  route: string;
  /**
   * The route type binding in PassPortal
   */
  type?: 'home' | 'sub';
  /**
   * The label binding in PassPortal
   */
  label?: string;
}

/**
 * @description a route that is bound with a custom controller and a custom template
 */

export interface PortalRouteWithCustomBindings extends PortalRouteBase{
  /**
   * The template binding in PassPortal.
   * Only set if you need to change the route template
   * if not defined RGI_RX_PORTAL_TPL is used
   * @see RGI_RX_PORTAL_TPL
   * @see PortalControllerFactory
   */
  template: string;
  /**
   * @description The controller binding in for the legacy PassPortal route.
   * this may bind inputs and outputs from a downgraded component
   *
   */
  controller: Function | any[] | string;
}

/**
 * @description a route that is bound using the default portalControllerFactory
 * @see portalControllerFactory
 */
export interface PortalRouteDefault extends PortalRouteBase{
  /**
   * The route destination in @rgi/rx/router
   * @see RoutingModule
   * @see Route
   * @see RoutingService
   */
  destination: string;

  /**
   * The controller binding in PassPortal
   * Only set if you need to add custom behaviour in your AngularJS PassPortal controller
   * if not defined PortalControllerFactory is used
   * @see PortalControllerFactory
   */
  controller?: Function | any[] | string;
}

export function isPortalRouteDefault(portalRoute: PortalRoute): portalRoute is PortalRouteDefault {
 return (portalRoute as PortalRouteDefault).destination !== undefined && (portalRoute as PortalRouteWithCustomBindings).template === undefined
}


/**
 * A definition for creating a card to be associated with
 * @rgi/rx framework
 */
export interface PortalCardConfig {
  card: PortalCard | any;
  routes: PortalRoute[];
}

export type PortalCards = PortalCardConfig[];

export const RGI_RX_PORTAL_CARDS = new InjectionToken<PortalCards>('RGI_RX_PORTAL_CARDS');


export abstract class UserBindingProvider<T> {
  abstract getUser(data: any): T;
}

export class PortalBindingProvider extends UserBindingProvider<any> {
  getUser(data: { getOperator(): any }): any {
    return data.getOperator();
  }
}



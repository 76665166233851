import { DatePipe } from '@angular/common';
import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { RgiCountryLayerNumberFormatService } from '@rgi/country-layer';
import moment from 'moment';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';

@Pipe({
  name: 'dataValueFormatterPipe'
})
export class DataValueFormatterPipe implements PipeTransform {

  private formats = [
    moment.ISO_8601,
    'DD/MM/YYYY  :)  HH*mm*ss'
  ];

  private numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2
  };

  constructor(
    public translate: TranslationWrapperService,
    public datePipe: DatePipe,
    public rgiNumberFormatter: RgiCountryLayerNumberFormatService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
  }

  transform(value?: any): any {
    // Handle null first, to avoid weird formatting like null dates coming out as 0,00
    if (value === null || value === undefined) {
      return '-';
    } else if (typeof value === 'boolean') {
      return this.translate.getImmediate(value ? 'lpc_YES' : 'lpc_NO');
    } else if (moment(value, this.formats, true).isValid()) {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    } else if (value !== '' && !isNaN(Number(value))) {
      return this.rgiNumberFormatter.convertNumberByLocale(value, this.currencyService.locale, this.numberFormatterOptions);
    }
    return value;
  }
}

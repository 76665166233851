<div *ngIf="isBeneficiaryUnitVisibility">
  <!-- end icon -->
  <div class="core-header-icon re-issue-card-styles">
    <span class="rgi-re-header-icon rgifont rgi-value nfm_font_size"></span>
    <span class="header-text" translate>RE_ISSUE.BENEFICIARIES</span>&nbsp;
    <span class="pull-right header-text"> </span>
  </div>
  <!-- end icon -->
  <!-- section unit -->
  <div class="col-sm-6 col-xs-12" *ngFor="let unitBeneficiariesConf of listBeneficiariesUnitFiltered; let i = index">
    <div class="container-fluid" attr.data-qa="{{unitBeneficiariesConf.section + ' ' + unitBeneficiariesConf.unit}}">
      <div class="row">
        <div class="core-header-icon col-sm-12 col-xs-12 beneficiaryTitle">
                  <span class="header-text beneficiaryTitle"
                        translate>{{unitBeneficiariesConf.section + ' ' + unitBeneficiariesConf.unit}}</span>&nbsp;
          <span class="pull-right header-text"> </span>
        </div>
      </div>
      <div *ngIf="beneficiary && beneficiary.beneficiaryType" class="row">
        <table rgi-rx-table [dataSource]="tableData">

          <ng-container rgiRxColumnDef="party">
            <th style="padding-left: 10px" rgi-rx-header-cell *rgiRxHeaderCellDef translate><span>RE_ISSUE.BENEFICIARY</span></th>
            <td rgi-rx-cell *rgiRxCellDef="let row">
              {{row.nominative}}
            </td>
          </ng-container>

          <ng-container rgiRxColumnDef="percentage">
            <th style="padding-left: 10px" rgi-rx-header-cell *rgiRxHeaderCellDef translate><span>RE_ISSUE.PERCENTAGE</span></th>
            <td rgi-rx-cell *rgiRxCellDef="let row">
              {{row.partyPercentage}}
            </td>
          </ng-container>
        <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
        <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div class="row" *ngIf="!beneficiary || !beneficiary.beneficiaryType">
        <div class=" col-sm-12 col-xs-12">
          <rgi-rx-snackbar tag="beneficiaryTypeError_{{asset.asset.key}}_{{unitCode}}"></rgi-rx-snackbar>
        </div>
      </div>
      <br>
      <div *ngIf="!beneficiary || !beneficiary.beneficiaryType" class="row">
          <button data-qa="beneficiaryAdd" rgi-rx-button color="info" class="btn" (click)="openBeneficiaryModal(unitBeneficiariesConf, null)">
            <span translate>RE_ISSUE.ADD_BENEFICIARIES</span>
          </button>
      </div>
      <div *ngIf="beneficiary && beneficiary.beneficiaryType" class="row">
        <button  data-qa="beneficiaryEdit" rgi-rx-button color="info" class="btn" (click)="openBeneficiaryModal(unitBeneficiariesConf, beneficiary)">
          <span translate>RE_ISSUE.EDIT_BENEFICIARIES</span>
        </button>
        <span>&nbsp;</span>
        <button  data-qa="beneficiaryDel" rgi-rx-button color="info" class="btn" (click)="deleteBeneficiaries(unitBeneficiariesConf)">
          <span translate>RE_ISSUE.EMPTY</span>
        </button>
      </div>
    </div>
  </div>
  <!-- end section unit -->
</div>


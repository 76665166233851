import {RgiRxi18nModuleLoadType, RgiRxSerializedI18n, RgiRxTranslations} from '@rgi/rx/i18n';
import {IT} from './it';
import {EN} from './en';

export function rgiPortalComponentsI18nLoaderIT(): RgiRxi18nModuleLoadType {
    return new Promise<RgiRxSerializedI18n>((resolve) => resolve(IT));
}

export function rgiPortalComponentsI18nLoaderEN(): RgiRxi18nModuleLoadType {
    return new Promise<RgiRxSerializedI18n>((resolve) => resolve(EN));
}

export const RGI_PORTAL_COMPONENTS_TRANSLATIONS: RgiRxTranslations = [
    {
        load: rgiPortalComponentsI18nLoaderIT,
        locale: 'it'
    },
    {
        load: rgiPortalComponentsI18nLoaderEN,
        locale: 'en'
    }
];

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RgiRxToolTip} from '../rgi-rx-tooltip-api';

let rgiRxTooltipCounter = 0;
@Component({
  selector: 'rgi-rx-tooltip',
  templateUrl: './rgi-rx-tooltip.component.html',
  host: {
    class: 'rgi-ui-tooltip rgi-rx-tooltip',
    '[attr.aria-hidden]': 'visibility ? "true" : false',
    role:'tooltip'
  },
  providers: [
    {
      provide: RgiRxToolTip,
      useExisting: RgiRxTooltipComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: [
    'content'
  ]
})
export class RgiRxTooltipComponent extends RgiRxToolTip {
  visibility: string;
  private readonly _id: string;

  constructor() {
    super();
    this._id = `rgi-rx-tooltip-${++rgiRxTooltipCounter}`;
  }

  isTextContent() {
    return typeof this.content === 'string';
  }


  show() {
    this.visibility = 'visible';
  }

  hide() {
    this.visibility = 'hidden';
  }


  get id(): string {
    return this._id;
  }
}

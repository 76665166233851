import {RGI_RX_LOCALE} from '@rgi/rx/i18n';

/**
 * @description Provide format capability on large number strings representation that may be truncated or rounded by Intl.NumberFormat
 * Limited to x.y where x and y is the max number that can be represented without precision loose by NumberFormat.
 * @see Intl.NumberFormat
 */
export class RgiCountryLayerBigNumberFormat {
  #decimal: string;
  #decimalParts: Intl.NumberFormatPart[];

  constructor(protected locale: RGI_RX_LOCALE) {
    const format = new Intl.NumberFormat(locale);
    const floatParts = format.formatToParts(0.123456789123456789);
    this.#decimal = floatParts.find(f => f.type === 'decimal')!.value
    this.#decimalParts = format.formatToParts(1234567891234567891);
  }

  /**
   * @description format a long number represented by a string to the specified locale. This allows to overcome the limitation of
   * RgiCountryLayerNumberFormat when using format to big numbers represented in string that cannot be parsed by a float and are subject
   * to rounding.
   * @param locale
   * @param numberString
   */
  formatBigNumberString(numberString: string, locale?: RGI_RX_LOCALE): string {
    locale = locale || this.locale;
    const numberSplit = numberString.split('.');
    const left = numberSplit[0];
    const decimals = numberSplit.length > 1 ? `${this.#decimal}${numberSplit[1]}` : ``;
    const format = new Intl.NumberFormat(locale);
    let integers = ''+format.format(+left);

    // todo implement decimal parse
    // this.#decimalParts.forEach(value => {})

    return integers+decimals;
  }
}

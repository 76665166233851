import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxLogger} from './rgi-rx-logger';
import {rgiRxLoggerFactory} from './logging-providers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: RgiRxLogger, useFactory: rgiRxLoggerFactory, deps: [Injector]}
  ]
})
export class RgiRxLoggingModule {
}

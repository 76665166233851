import {Extention} from '../assets-models/pc-insured-asset';
import {Factor} from '../factor-models/factor';
import {AdditionalLabel} from '../proposal-models/additional-label';
import {Message} from './pc-quotation';
import {Clause} from "../../domain-models/clause";

export class PcUnit {
  code?: string;
  description?: string;
  factors?: Array<Factor>;
  selected?: boolean;
  messages?: Message[];
  extentions?: Extention[];
  additionalLabels?: AdditionalLabel[];
  enabled?: boolean;
  inputPremiumType?: any;
  risks
  clauseUnit:Clause[];
}

import {InjectionToken, Type} from '@angular/core';
import {
  ROUTES_GP_INQUIRY_APPCONF,
  ROUTES_GP_INQUIRY_APPLIST,
  ROUTES_GP_INQUIRY_ASSET_CLUSTERS,
  ROUTES_GP_INQUIRY_BALANCE_DETAIL,
  ROUTES_GP_INQUIRY_COINSURANCES,
  ROUTES_GP_INQUIRY_CONTRACT_DETAIL,
  ROUTES_GP_INQUIRY_MOVEMENTS,
  ROUTES_GP_INQUIRY_PARTIES,
  ROUTES_GP_INQUIRY_PMDATA,
  ROUTES_GP_INQUIRY_PRODUCT_CLAUSES,
  ROUTES_GP_INQUIRY_SUBSTITUTED_POLICIES,
  ROUTES_GP_INQUIRY_UNITS
} from '../group-policy-constants/routes-constants';

export interface GpInquiryComponentInterface {
  title: string;
  route: string;
  displayOnSide: boolean;
  order: number;
  component?: Type<any>;
}

export const GP_INQUIRY_COMPONENTS = new InjectionToken<GpInquiryComponentInterface[]>('GP_INQUIRY_COMPONENTS');


export const GP_INQUIRY_COMPONENTS_LIST: GpInquiryComponentInterface[] = [
  {
    title: 'contractDetail',
    route: ROUTES_GP_INQUIRY_CONTRACT_DETAIL,
    displayOnSide: false,
    order: 10
  },
  {
    title: 'assetFullDetail',
    route: ROUTES_GP_INQUIRY_ASSET_CLUSTERS,
    displayOnSide: false,
    order: 20
  },
  {
    title: 'applicationList',
    route: ROUTES_GP_INQUIRY_APPLIST,
    displayOnSide: false,
    order: 30
  },
  {
    title: 'applicationConf',
    route: ROUTES_GP_INQUIRY_APPCONF,
    displayOnSide: false,
    order: 40
  },
  {
    title: 'pmMovements',
    route: ROUTES_GP_INQUIRY_MOVEMENTS,
    displayOnSide: false,
    order: 50
  },
  {
    title: 'substitutedPolicy',
    route: ROUTES_GP_INQUIRY_SUBSTITUTED_POLICIES,
    displayOnSide: false,
    order: 70
  },
  {
    title: 'pmBalanceDetail',
    route: ROUTES_GP_INQUIRY_BALANCE_DETAIL,
    displayOnSide: false,
    order: 60
  },
  {
    title: 'parties',
    route: ROUTES_GP_INQUIRY_PARTIES,
    displayOnSide: true,
    order: 10
  },
  {
    title: 'coinsurances',
    route: ROUTES_GP_INQUIRY_COINSURANCES,
    displayOnSide: true,
    order: 11
  },
  {
    title: 'assetUnits',
    route: ROUTES_GP_INQUIRY_UNITS,
    displayOnSide: true,
    order: 20
  },
  {
    title: 'pmdata',
    route: ROUTES_GP_INQUIRY_PMDATA,
    displayOnSide: true,
    order: 30
  },
  {
    title: 'productClauses',
    route: ROUTES_GP_INQUIRY_PRODUCT_CLAUSES,
    displayOnSide: true,
    order: 40
  }
];

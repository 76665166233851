import { DIALOG_DATA } from '@rgi/rx/ui';
import { DatePipe, formatDate } from '@angular/common';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NewPaymentService } from '../new-payment.service';
import { NgbActiveModal, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@rgi/digital-claims-common-angular';
import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  DetailReversalReturn, IComboListReversal, INIT_DETAIL_REVERSAL, ReasonReversal,
  ResponseReasonReversal
} from './settlement-reversal.interface';
import { ExposureEntity } from '../domain/exposure-entity';
import { LegalFeeEntity } from '../domain/legal-fee-entity';
import { LegalFeeOmniaEntity } from '../domain/legal-fee-omnia-entity';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-settlement-reversal',
  templateUrl: './settlement-reversal.component.html',
  styleUrls: ['./settlement-reversal.component.scss']
})
export class SettlementReversalComponent implements OnInit {

  @Input() inputDetailReversal: any; // Dati inviati dalla modale padre

  detailReversalForm: UntypedFormGroup; // Form in cui inputare i dati
  detailReversal: DetailReversalReturn = INIT_DETAIL_REVERSAL; // Valorizzazione dei dati da inviare al BE
  newPayFormModal: UntypedFormGroup; // Form utilizzato per il passaggio dei parametri al componente fee-data-modal

  today = new Date(); // Data odierna
  currentReceiptDate: Date; // Data della quietanza

  exposures: ExposureEntity[] = []; // Lista contenente i dati
  exposureView: LegalFeeEntity; // Oggetto contenente i dati fattura
  feeDataReversal: LegalFeeEntity; // Oggetto contenente il clone dei dati fattura
  legalFeeOmniaEntity: LegalFeeOmniaEntity;

  modalClose = new EventEmitter<any>(); // Chiusura della modale

  isLoadingData = false; // Variabile che indica se i dati sono stati caricati

  amountTitle: number = 0; // Variabile contenente il valore dell'importo totale (Title Bar)
  feeModalData: LegalFeeEntity = null;

  idClaim: string = ''; // Id del sinistro
  receiptId: string = ''; // Id della quietanza
  claimNumber: string = ''; // Numero del sinistro
  receiptDate: string = ''; // Data del sinistro
  descErrOrTooltip: string = ''; // Descrizione errore / tooltip
  enableButton: boolean = false; // Abilitazione del bottone "Conferma"
  datepickerPlaceholder: string = ''; // Campo calendario del form
  ministerialBranchCodes: string = ''; // Ramo ministeriale

  feeTypeRequired = false;
  minDatePersonalized: NgbDate = null;

  reasonReversalList: ReasonReversal[] = []; // Elenco delle motivazione di annullamento Quietanza/Liquidazione

  constructor(
    public translateService: RgiRxTranslationService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    private paymentService: NewPaymentService,
    @Optional() @Inject(DIALOG_DATA) data: any) {

    this.inputDetailReversal = data.detailObject; // Dati ricevuti dalla modale padre
    this.inputDetailReversal.selectedClaim = data.claimCurrent; // Dati del sinistro ricevuti dalla modale padre
    this.currentReceiptDate = data.currentReceiptDate; // Data della quietanza ricevuta dalla modale padre
  }

  ngOnInit() {
    this.initForm();
    this.reversalDataRecovery();
    this.populateVariables();
    this.enableConfirmButton();
  }

  /**
   * Inizializzazione del form con i relativi validatori
   */
  initForm() {
    this.detailReversalForm = this.formBuilder.group({
      note: [''],
      reasonCancelled: [''],
      dateReversal: [new Date(), [Validators.required, Validators.max, Validators.min]]
    });
  }

  /**
   * Conferma dello storno. Passaggio dei parametri per il salvataggio dei dati
   */
  confirmSettlementReversal() {
    if (this.validationForm() && this.detailReversalForm.valid) {
      this.detailReversal.dateReceipt = this.formatDateTimezone(this.detailReversalForm.get('dateReversal').value);
      this.detailReversal.note = this.detailReversalForm.get('note').value;
      this.detailReversal.reasonCancelledSelected = this.detailReversalForm.get('reasonCancelled').value === ''
        ? null : this.detailReversalForm.get('reasonCancelled').value;
      this.detailReversal.claimNumber = this.claimNumber;
      this.detailReversal.receiptId = this.receiptId; // Id quietanza

      this.exposures[0].amountNet *= -1;
      this.exposures[0].currencyAmount *= -1;
      this.exposures[0].quotaAmount *= -1;

      if (this.feeModalData !== null) {
        this.exposures[0].feeData.taxableAmount = this.feeModalData.taxableAmount; // Importo imponibile
        this.exposures[0].feeData.ivaAmount = this.feeModalData.ivaAmount; // IVA
        this.exposures[0].feeData.withHoldingAmount = this.feeModalData.withHoldingAmount; // Ritenuta
        this.exposures[0].feeData.netAmount = this.feeModalData.netAmount; // Importo netto
        this.exposures[0].feeData.cash1Amount = this.feeModalData.cash1Amount; // Importo cassa 1
        this.exposures[0].feeData.cash2Amount = this.feeModalData.cash2Amount; // Importo cassa 2 / Inps
        this.exposures[0].feeData.totalAmount = this.feeModalData.totalAmount; // Importo totale
        this.exposures[0].feeData.feeNumber = this.feeModalData.feeNumber; // Numero fattura
        this.exposures[0].feeData.feeDate = this.feeModalData.feeDate; // Data fattura
        this.exposures[0].feeData.notTaxableAmount = this.feeModalData.notTaxableAmount; // Non imponibile
        this.exposures[0].feeData.bolliAmount = this.feeModalData.bolliAmount; // Importo bolli
        this.exposures[0].feeData.feeType = this.feeModalData.feeType; // Tipo fattura
        this.exposures[0].feeData.totalAmount = this.feeModalData.totalAmount; // Importo totale
      }

      this.detailReversal.exposureList = this.exposures;
      this.closeModal(this.detailReversal);
    }
  }

  /**
   * Chiusura della modale
   * @param responseBody Dati inviati al chiamante per l'elaborazione del BE
   */
  closeModal(responseBody: any) {
    this.modalClose.emit(responseBody);
  }

  /**
   * Validazione dei dati inseriti nel form
   * @returns Ritorna true se il form è valido altrimenti false
   */
  validationForm(): boolean {
    this.detailReversalForm.get('dateReversal').setValidators(Validators.required);
    const dateValue = this.detailReversalForm.get('dateReversal').value;
    if (dateValue === null || dateValue === '' || !this.checkDate(dateValue)) {
      return false;
    }
    if (this.reasonReversalList.length > 0) {
      this.detailReversalForm.get('reasonCancelled').setValidators(Validators.required);
      this.detailReversalForm.get('note').setValidators(Validators.nullValidator);
      this.detailReversalForm.get('note').setErrors(null);
      if (this.detailReversalForm.get('reasonCancelled').value === '') {
        this.translateService.translate('_CLAIMS_._MESSAGE_._SELECT_REASON_REVERSAL')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
        return false;
      }
    } else {
      this.detailReversalForm.get('note').setValidators(Validators.required);
      if (this.detailReversalForm.get('note').value === '') {
        this.translateService.translate('_CLAIMS_._MESSAGE_._ENTER_NOTES')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
        return false;
      }
    }

    if (this.feeModalData) {
      const importoTotale = this.feeModalData.totalAmount && this.feeModalData.totalAmount < 0
        ? this.feeModalData.totalAmount * -1 : this.feeModalData.totalAmount;
      if (this.feeModalData.totalAmount > 0) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
        return false;
      } else if (importoTotale > this.exposureView.totalAmount) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
        return false;
      }

      if (this.feeModalData.feeType === '') {
        this.translateService.translate('_CLAIMS_._MESSAGE_._INVOICE_TYPE_REQUIRED')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
        return false;
      } else if ((this.feeModalData.feeType === '1' || this.feeModalData.feeType === '2')
        && (this.feeModalData.feeNumber === '' || this.feeModalData.feeDate === null)) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
        return false;
      } else if (this.feeModalData.feeNumber !== '' && this.feeModalData.feeDate !== null && this.feeModalData.idSoggLegal !== '') {
        this.paymentService.dataFeeVerify(this.feeModalData).subscribe((response: any) => {
          if (response.descr !== '') {
            this.descErrOrTooltip = response.descr;
            return false;
          }
        });
      } else if (this.feeModalData.feeDate !== null) {
        return this.checkDateFeeModal(this.feeModalData.feeDate, this.exposureView.feeDate);
      }
    }
    return true;
  }

  /**
   * Recupero dei dati da visualizzare per lo storno della liquidazione
   */
  reversalDataRecovery(): void {
    this.populateVariables();
    const body: IComboListReversal = {
      receiptIdMotivoAnnullo: '12',
      receiptDate: this.formatDateTimezone(this.receiptDate),
      receiptIdRamoMinisteriale: this.ministerialBranchCodes,
      receiptId: this.receiptId
    };
    this.paymentService.listReasonsSettlementsReversal(body, this.claimNumber).subscribe((res: ResponseReasonReversal) => {
      if (res) {
        this.exposures = res.exposureList;

        this.exposureView = { ...res.exposureList[0].feeData };

        this.isLoadingData = true;
        if (this.exposures.length > 0) {
          this.exposures.forEach((element, index) => {
            this.amountTitle += element.quotaAmount;

            this.feeDataReversal = element.feeData;

            if (this.feeDataReversal !== null) {
              const minDate = new Date(this.exposureView.feeDate);
              this.minDatePersonalized = new NgbDate(minDate.getFullYear(),
                minDate.getMonth() + 1, minDate.getDate());

              this.exposures[index].feeData.taxableAmount =
                this.checkFieldValorization(this.feeDataReversal.taxableAmount); // Importo imponibile
              this.exposures[index].feeData.ivaAmount = this.checkFieldValorization(this.feeDataReversal.ivaAmount); // IVA
              this.exposures[index].feeData.withHoldingAmount =
                this.checkFieldValorization(this.feeDataReversal.withHoldingAmount); // Ritenuta
              this.exposures[index].feeData.netAmount = this.checkFieldValorization(this.feeDataReversal.netAmount); // Importo netto
              this.exposures[index].feeData.cash1Amount = this.checkFieldValorization(this.feeDataReversal.cash1Amount); // Importo cassa 1
              this.exposures[index].feeData.cash2Amount =
                this.checkFieldValorization(this.feeDataReversal.cash2Amount); // Importo cassa 2 / Inps
              this.exposures[index].feeData.totalAmount = this.checkFieldValorization(this.feeDataReversal.totalAmount); // Importo totale
              this.exposures[index].feeData.feeType = ''; // Tipo fattura
              this.exposures[index].feeData.feeDate = null; // Data fattura
              this.exposures[index].feeData.feeNumber = ''; // Numero fattura

              this.populateForm();
            }
          });
        }

        this.reasonReversalList.push(...this.initComboReasonReversalList(res));
        this.validationForm();
      }
    });
  }

  /**
   * Popolazione della variabile, utilizzata nel form, contenente l'elenco delle motivazioni di annullamento
   * @param lista Contiene il JSON di risposta del servizio esposto
   * @returns Ritorna la lista delle motivazioni di annullamento
   */
  initComboReasonReversalList(lista: ResponseReasonReversal): ReasonReversal[] {
    const reasonCancelledComboList: ReasonReversal[] = [];
    const items = lista.reasonCancellation;
    if (items.length > 0 && items[0] !== undefined && items[0] !== null && items[0].length > 0) {
      this.initReasonReversalList();

      const codeReason = items[0];
      const valueReason = items[1];

      codeReason.forEach((element, index) => {
        const combo: ReasonReversal = {
          code: element,
          description: valueReason[index]
        };
        reasonCancelledComboList.push(combo);
      });
    }
    return reasonCancelledComboList;
  }

  /**
   * Inizializzazione della lista delle motivazioni di annullamento
   */
  initReasonReversalList() {
    this.reasonReversalList.push(
      {
        code: '',
        description: ''
      }
    );
  }

  /**
   * Valorizzazione delle variabili utili alla modale
   */
  populateVariables() {
    if (this.inputDetailReversal.selectedClaim) {
      this.idClaim = this.inputDetailReversal.selectedClaim.idClaim ? this.inputDetailReversal.selectedClaim.idClaim : '';
      this.receiptDate = this.inputDetailReversal.selectedClaim.reportDate ? this.inputDetailReversal.selectedClaim.reportDate : '';
      this.claimNumber = this.inputDetailReversal.selectedClaim.claimNumber
        ? this.inputDetailReversal.selectedClaim.claimNumber : '';
      this.ministerialBranchCodes = this.inputDetailReversal.selectedClaim.ministerialBranchCodes
        ? this.inputDetailReversal.selectedClaim.ministerialBranchCodes : '';
      this.receiptId = this.inputDetailReversal.paramsIntegrationPass.NAVI_PAR_SETTLEMENTID
        ? this.inputDetailReversal.paramsIntegrationPass.NAVI_PAR_SETTLEMENTID : '';
    }
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    const dataOutput = this.datePipe.transform(miaData, 'dd-MM-yyyy');
    return dataOutput;
  }

  /**
   * Abilita/Disabilita il bottone "Conferma" presente nel form
   */
  enableConfirmButton() {
    if (this.validationForm()) {
      this.enableButton = true;
    } else {
      this.enableButton = false;
    }
  }

  /**
   * Esegue la validazione delle date dopo averle formattate secondo il formato 'yyyy-MM-dd'
   * @param data Riceve in input una stringa contenente la data selezionata nel calendario
   * @returns Ritorna true se la data rispetta il range imposto (valida) altrimenti false (non valida)
   */
  checkDate(data: string): boolean {
    const calendarDate = formatDate(new Date(data), 'yyyy-MM-dd', 'it_IT');
    const todayDate = formatDate(this.today, 'yyyy-MM-dd', 'it_IT');
    const minDate = formatDate(this.currentReceiptDate, 'yyyy-MM-dd', 'it_IT');

    if (calendarDate <= todayDate && calendarDate >= minDate) {
      this.detailReversalForm.get('dateReversal').setErrors(null);
      this.descErrOrTooltip = '';
      return true;
    } else {
      this.detailReversalForm.get('dateReversal').setErrors({ require: true });
      this.translateService.translate('_CLAIMS_._MESSAGE_._THE_REVERSAL_DATE_IS_NOT_VALID')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
      return false;
    }
  }

  /**
   * Funzione utilizzata per verificare se la data inserita nel form della modale fee-data-modal
   * rispetta le condizioni di validazione
   * @param data Data inserita nel form
   * @param minimumDate Data minima valida per essere accetata
   * @returns Ritora true se le condizioni sono rispettate, altrimenti false
   */
  checkDateFeeModal(data: Date, minimumDate: Date): boolean {
    const calendarDate = formatDate(data, 'yyyy-MM-dd', 'it_IT');
    const todayDate = formatDate(this.today, 'yyyy-MM-dd', 'it_IT');
    const minDate = formatDate(minimumDate, 'yyyy-MM-dd', 'it_IT');

    if (calendarDate <= todayDate && calendarDate >= minDate) {
      this.descErrOrTooltip = '';
      return true;
    } else {
      this.translateService.translate('_CLAIMS_._MESSAGE_._THE_REVERSAL_DATE_IS_NOT_VALID')
        .subscribe(
          res => this.descErrOrTooltip=res
        )
      return false;
    }
  }

  /**
   * Esegue il popolamento del form con i relativi campi valorizzati da passare come parametro alla
   * modale fee-data-modal
   */
  populateForm() {
    this.newPayFormModal = this.formBuilder.group({
      feeNumberClaims: [null], // Numero fattura
      feeDateClaims: [null], // Data fattura
      taxableAmountClaims: [this.feeDataReversal.taxableAmount, Validators.max(0)], // Importo imponibile
      noTaxableAmountClaims: [0], // Non imponibile
      ivaAmountClaims: [this.feeDataReversal.ivaAmount], // IVA
      withHoldingAmountClaims: [this.feeDataReversal.withHoldingAmount], // Ritenuta
      netAmountClaims: [this.feeDataReversal.netAmount], // Importo netto
      bolliAmountClaims: [0], // Importo bolli
      cash1AmountClaims: [this.feeDataReversal.cash1Amount], // Importo cassa 1
      cash2AmountClaims: [this.feeDataReversal.cash2Amount], // Importo cassa 2 / Inps
      legalCodeClaims: [
        this.feeDataReversal && this.feeDataReversal.taxRegime ?
          this.feeDataReversal.taxRegime.legalCode : 0
      ], // Codice legale
      fiscalTaxRegimeClaims: [
        this.feeDataReversal && this.feeDataReversal.taxRegime ?
          this.feeDataReversal.taxRegime.description : 0
      ], // Regime fiscale
      totalAmountClaims: [this.feeDataReversal.totalAmount], // Importo totale
      feeTypeClaims: [null, Validators.required], // Tipo fattura
    });
  }

  /**
   * @param item Metodo utilizzato per rendere i dati numerici negativi
   * @returns Ritorna un numero
   */
  checkFieldValorization(item: number): number {
    if (item && item > 0) {
      return (item * -1);
    }
    return 0;
  }

  /**
   * Funzione utilizzata per catturare l'EventEmitter (Output) della modale fee-data-modal
   * @param event Oggeto contenente i dati del form con i dati calcolati
   */
  valueChangeForm(event: LegalFeeEntity) {
    this.feeModalData = event;
    this.enableConfirmButton();
  }

}

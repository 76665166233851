import {RGI_FIELD_TYPE, RgiFormField} from '../common/rgi-form-field';
import {formatISO} from 'date-fns';

export class CancellationField {
  value: string;
  modifiable: boolean;
  visible: boolean;
  reimbursement: boolean;
  valueMin?: string;
  valueMax?: string;
  mandatory?: boolean | false;
}

export function cancellationFieldToRgiFormField(cancellationField: CancellationField, code: string,
                                                label: string, type: RGI_FIELD_TYPE, mandatory?: boolean): RgiFormField {
  const field = new RgiFormField();
  field.code = code;
  field.label = label;
  field.type = type;
  field.value = type === RGI_FIELD_TYPE.CHECKBOX ? cancellationField.reimbursement : cancellationField.value;
  field.editable = cancellationField.modifiable;
  field.minValue = cancellationField.valueMin;
  field.maxValue = cancellationField.valueMax;
  field.mandatory = mandatory || false;
  field.visible = cancellationField.visible;
  return field;
}

export function rgiFormFieldToCancellationField(field: RgiFormField): CancellationField {
  let value;
  switch (field.type) {
    case RGI_FIELD_TYPE.DATE:
    case RGI_FIELD_TYPE.DATETIME:
      value = typeof field.value === 'string' ? field.value : formatISO(field.value as Date, {representation: 'date'});
      break;
    case RGI_FIELD_TYPE.CHECKBOX:
      value = undefined;
      break;
    default:
      value = '' + field.value;
  }

  return {
    modifiable: field.editable,
    reimbursement: field.type === RGI_FIELD_TYPE.CHECKBOX ? !!field.value : undefined,
    value,
    visible: field.visible
  };
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountingTransactionsComponent } from './accounting-transactions.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [
        AccountingTransactionsComponent
    ],
    imports: [
        CommonModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS)
    ],
    exports: [
        AccountingTransactionsComponent
    ]
})
export class AccountingTransactionsModule {


 }

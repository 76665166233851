<div *ngIf="addressesList && addressesList.length">
  <div class="d-flex" style="margin-bottom: 1rem;">
    <select (change)="setPage(0)" [(ngModel)]="itemsForPage" [disabled]="!addressesList || addressesList.length < 6"
            style="padding: 0px 1rem; margin-right: 2rem">
      <option selected value="5">5</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
    </select>
    Elements
    <div class="flex-grow-1"></div>
    <input (keyup)="filterAddresses()" [(ngModel)]="search" id="search" name="search" placeholder="Search"
           type="text">
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr style="background-color: #AFBBC6; color: white; font-weight: bold;">
        <th translate>Address</th>
        <th translate>Actions</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let address of getItemsToShow(); index as i">
        <tr [ngStyle]="{'background-color': i % 2 !== 0 ? '#EBEFF3' : 'white' }">
          <td data-qa="address-results">{{ address.formatAddress }}</td>
          <td class="text-right">
            <button (click)="select(address)" class="btn btn-outline-primary font-weight-bold" data-qa="select-subject-btn"
                    translate type="button"><i aria-hidden="true" class="rgifont rgi-chevron-right"></i>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div style="display: inline-flex; margin-top: 1rem;">
    <button (click)="setPage(page-1)" [disabled]="page === 0" class="btn btn-outline-primary"><i
      aria-hidden="true" class="rgifont rgi-chevron-left"></i></button>
    <button (click)="setPage(n)" *ngFor="let pageNumber of getPages(); index as n"
            [disabled]="n === page" [ngClass]="{'font-weight-bold':n === page}"
            class="btn btn-outline-primary">{{ n + 1 }}</button>
    <button (click)="setPage(page+1)" [disabled]="page+1 === getPages().length"
            class="btn btn-outline-primary"><i aria-hidden="true" class="rgifont rgi-chevron-right"></i>
    </button>
  </div>
</div>
<div *ngIf="loaded && (!addressesList || addressesList.length === 0)">
  <p translate>No addresses found</p>
</div>

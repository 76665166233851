
<h3 class="detail-title"><span translate>{{ '_CLAIMS_._SEARCH_RESULTS' | translate }}</span></h3>

<div *ngIf="hasResult()" class="container-fluid" data-qa="external-policy-list">
    <table class="table-responsive table coreDT" >
      <thead>
        <tr class="head-result thDataTable">
            <td style="width: 5%;">
              <span >&nbsp;&nbsp;&nbsp;</span>
            </td>

            <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="width: 12%;">
                <span attr.data-qa="external-policy-number-label" class="text-uppercase">{{ '_CLAIMS_._POLICY_NUMBER' | translate }}</span>
            </td>

            <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="width: 19%;">
                <span attr.data-qa="external-policy-company-label">{{ '_CLAIMS_._COMPANY' | translate }}</span>
            </td>

            <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="width: 18%;">
              <span attr.data-qa="external-policy-company-label" class="text-uppercase">{{ '_CLAIMS_._CONTRACTOR' | translate }}</span>
            </td>
            
            <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="width: 15%;" >
                <span attr.data-qa="external-policy-assetInsured-label" class="text-uppercase">{{ '_CLAIMS_._ASSET_INSURED' | translate }}</span>
            </td>
            <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 8%;">
              <span attr.data-qa="external-policy-issueDate-label">{{ '_CLAIMS_._ISSUE_DATE' | translate }}</span>
            </td>
         
            <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 7%;">
                <span attr.data-qa="external-policy-effectdate-label">{{ '_CLAIMS_._EFFECT_DATE' | translate }}</span>
            </td>

            <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 7%;">
                <span attr.data-qa="external-policy-expirationdate-label">{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}</span>
            </td>

            <td class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <span attr.data-qa="external-policy-origin-label">{{ '_CLAIMS_._ORIGIN' | translate }}</span>
            </td>
            <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <span attr.data-qa="external-policy-button-label">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </td>

        </tr>

      </thead>
      
      <tbody>
        <tr *ngFor="let row of rows; let even = even; let odd = odd; let externalPolicyIndex = index;" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
          class="table-row" attr.data-qa="external-policy-result-{{externalPolicyIndex}}">
        <td colspan="11" class="table-row">
        <table style="width: 100%;">
          <tr>
          <td style="width: 4%;" >
            <span class="rgifont iconDTleft rgi-plus" [ngClass]="{'rgi-plus': !row.expanded, 'rgi-minus':row.expanded}" (click)=ctrDetails(externalPolicyIndex)></span>
          </td>
          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 12%;"   >
            <span attr.data-qa="external-policy-number-{{externalPolicyIndex}}" class="text-uppercase">{{ row.externalPolicy.policyNumber }}</span>
          </td>
          
          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 18%; text-align: left;" >
            <span attr.data-qa="external-policy-company-{{externalPolicyIndex}}">{{ row.externalPolicy.company  }} - {{ row.companyDescr  }}</span>
          </td>

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 17%; text-align: left;"   >
            <span attr.data-qa="external-policy-company-{{externalPolicyIndex}}" class="text-uppercase">{{ row.policyOwner.lastBusinessName }} {{ row.policyOwner.firstName }}</span>
          </td>

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1"  style="width: 16%; text-align: left;" >
            <span attr.data-qa="external-policy-assetInsured-{{externalPolicyIndex}}" style="margin-left: 10px;" class="text-uppercase">{{ row.externalPolicy.assetInsured }}</span>
          </td>

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 8%; text-align: left; "  >
            <span attr.data-qa="external-policy-issueDate-{{externalPolicyIndex}}">{{ row.externalPolicy.issueDate | date:'dd/MM/yyyy'  }} &nbsp;</span>
          </td>

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 8%; text-align: left;">
            <span attr.data-qa="external-policy-effectDate-{{externalPolicyIndex}}">{{ row.externalPolicy.effectDate | date:'dd/MM/yyyy'  }}&nbsp;</span>
          </td>

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 7%; text-align: left;"  >
            <span attr.data-qa="external-policy-expirationDate-{{externalPolicyIndex}}">{{ row.externalPolicy.expirationDate | date:'dd/MM/yyyy'  }}&nbsp;</span>
          </td>

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="width: 5%; text-align: center;" >
            <span attr.data-qa="external-policy-origin-{{externalPolicyIndex}}">{{ row.externalPolicy.origin  }}</span>
          </td>


          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1"   style="width: 100px; text-align: right;">
            <span attr.data-qa="external-policy-actions-{{externalPolicyIndex}}">     
                  <button type="button" class="btn btn-flat btn-sm" (click)="selectModPolicyDetail(row.externalPolicy,true)" >
                    <span class="action-icon rgifont rgi-chevron-right" style="width:10px; height:10px;"></span>
                  </button> 
                  <button *ngIf="modificaAbilitata" type="button" class="btn btn-flat btn-sm" (click)="selectModPolicyDetail(row.externalPolicy,false)" >
                    <span class="action-icon rgifont rgi-pencil" style="width:10px; height:10px;" ></span>
                  </button>     
            </span>
          </td>
          </tr>
          <tr *ngIf="row.expanded">
          <td  colspan="11">
            <table style="width: 100%; border: 1px solid gray;">
            <tr>
              <td style="font-weight: bold;" class="text-uppercase">{{ row.policyOwner.lastBusinessName }} {{ row.policyOwner.firstName }}</td>
              <td></td>
              <td >{{ '_CLAIMS_._PRODUCT_CODE' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.productCode  }}</td>
              <td >{{ '_CLAIMS_._POLICY_CEILING' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.policyCeiling  }}</td>
              <td >{{ '_CLAIMS_._UPDATE_DATE' | translate }}</td>
              <td style="font-weight: bold;">{{ row.updateDate |  date:'dd/MM/yyyy'  }}</td>
            </tr>
            <tr>
              <td >{{ 'FC/Vat Numb.' | translate }}</td>
              <td style="font-weight: bold;" class="text-uppercase">{{ row.policyOwner.fiscalCode }} {{ row.policyOwner.vat }}</td>
              <td >{{ '_CLAIMS_._PRODUCT' | translate }}</td>
              <td style="font-weight: bold;" class="text-uppercase">{{ row.externalPolicy.productDescription  }}</td>
              <td >{{ '_CLAIMS_._EXCESS' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.deductible  }}</td>
              <td >{{ '_CLAIMS_._USER' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.updateUser  }}</td>
            </tr>
            <tr>
              <td >{{ '_CLAIMS_._AGENCY' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.agency  }}</td>
              <td >%{{ '_CLAIMS_._DEDUCTIBLE' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.uncovered  }}</td>
              <td >{{ '_CLAIMS_._POLICY_REPLACED_NUMBER' | translate }}</td>
              <td style="font-weight: bold;" class="text-uppercase">{{ row.externalPolicy.policyReplacedNumber  }}</td>
              <td >{{ '_CLAIMS_._POLICY_REPLACEMENT_NUMBER' | translate }}</td>
              <td style="font-weight: bold;" class="text-uppercase">{{ row.externalPolicy.policyReplacementNumber  }}</td>
             
            </tr>
            <tr>
              <td >{{ '_CLAIMS_._ADDRESS' | translate }}</td>
              <td style="font-weight: bold;">{{ row.policyOwner.address  }} - {{ row.policyOwner.municipality  }} ({{ row.policyOwner.stateCode  }}) {{ row.policyOwner.postalCode  }} </td>
              <td >{{ '_CLAIMS_._MINIMUM_UNCOVERED_VALUE' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.miniDiscoveredValue  }}</td>
              <td >{{ '_CLAIMS_._ADMINISTRATIVE_REGULARITY' | translate }}</td>
              <td style="font-weight: bold;">{{ row.externalPolicy.regAmm  }}</td>
              <td colspan="2"></td>
            </tr>
            </table>
          </td>
          </tr>
        </table>
        </td>
        </tr>
        
      </tbody>
    </table>
</div>

<div *ngIf="!hasResult()">
  <div>
    <span class="icon-attention-sign"></span>
    <span data-qa="no-policies-show" translate class="notasks-message">{{ '_CLAIMS_._NO_POLICY_TO_SHOW' | translate }}</span>
    <span class="icon-notepad"></span>
  </div>
</div>

<div class="btn-group btn-group-justified btn-group-justified-claim">
  <div class="btn-group">
    <button  class="btn btn-warning pull-right"  (click)="back()">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button> 
  </div>		
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {concatMap, map, share} from 'rxjs/operators';
import {RePostsalesSubstitutionState} from '../re-postsale-state-manager/re-postsales-substitution-state';
import {
  RePostsalesSubstitutionResourceService
} from '../re-postsale-resources/re-postsales-substitution-resource.service';
import {GenericEntity, PolicySubstitutionRequest, SubReason} from '../re-postsale-model/substitution';
import {Message} from '../../../models/message';
import {Company} from "../../../models/domain-models/co-insurance";
import {Filter, ProductsFilter} from "../../../models/productsFilter";
import {SearchCardDto} from "../re-postsale-model/search-card-dto";

@Injectable({
  providedIn: 'root'
})
export class RePostsalesSubstitutionBusinessService<T extends RePostsalesSubstitutionState> {
  areaCode = 'SUBSTITUTION';

  constructor(
    private resourcesService: RePostsalesSubstitutionResourceService
  ) {
  }

  /*
  "subReasons": [
    {
      "id": 2,
      "code": "002",
      "description": "Sost. polizza annuale",
      "effectiveDate": 1626645600000,
      "effectiveDateEnabled": false
    },
    {
      "id": 1,
      "code": "001",
      "description": "Sost. polizza annuale",
      "effectiveDate": 1657317600000,
      "effectiveDateEnabled": false
    }
  ],
  "messages": []
  */

  initForm$(st: T): Observable<T> {
    st.validationMsgReasonList = [];
    return this.resourcesService.getSubstitutionReasons(st.policyNumber).pipe(
      share(),
      map(reason => {
        st.reasonList = [];
        reason.subReasons.forEach((data: SubReason) => {
          st.reasonList.push(data);
        });
        if (reason.messages) {
          reason.messages.forEach((msg: any) => {
            st.validationMsgReasonList.push(new Message(this.areaCode, msg));
          });
        }
        return st;
      })
    );
  }

  /*compatibleProducts
  "": [
    {
      "id": 1,
      "code": "30103",
      "description": "MY CARE SALUTE UPGRADE"
    }
  ],
  "messages": []
  */

  getCompanies$(): Observable<Array<Company>> {
    return this.resourcesService.getCompanies();
  }

  getProducts$(state: Observable<T>, node: any, productsCategory: any) {
    return state.pipe(
      concatMap(st => {
        st.products = [];
        st.productTypes = [];
        const body: ProductsFilter = new ProductsFilter();
        body.productsFilter = new Filter(node, productsCategory, true, true);
        return this.resourcesService.getProducts(body).pipe(
          share(),
          map(response => {
            response.products.forEach(product => {
              st.products.push(product.product);
              if (!st.productTypes.find(type => {
                return type.code === product.productType.code;
              })) {
                st.productTypes.push(product.productType);
              }
            })
            return st;
          })
        )
      })
    )
  }

  getNodes$(state: Observable<T>, node: string) {
    return state.pipe(
      concatMap(st => {
        st.nodeList = [];
        return this.resourcesService.getNode$(node).pipe(share(), map(val => {
          val.agencies.forEach((element => {
            st.nodeList.push(element);
          }));
          return st.nodeList;
        }));
      })
    )
  }

  getCompatibleProducts$(state: Observable<T>, policyNumber: string, effectDate: number): Observable<T> {
    return state.pipe(
      concatMap(st => {
        st.validationMsgProductList = [];
        return this.resourcesService.getCompatibleProducts(policyNumber, effectDate).pipe(
          share(),
          map(product => {
            st.productList = [];
            if (product.compatibleProducts && product.compatibleProducts.length > 0) {
              product.compatibleProducts.forEach((data: GenericEntity) => {
                st.productList.push(data);
              });
            }
            if (product.messages && product.messages.length > 0) {
              product.messages.forEach((msg: any) => {
                st.validationMsgProductList.push(new Message(this.areaCode, msg));
              });
            }
            return st;
          })
        );
      })
    );
  }

  /*
  "inputSubstitutingProposal": {
      "policyNumber": "783",
      "authenticationNodeCode": "00200",
      "productionNodeCode": "00200",
      "userCode": "ITB",
      "effectiveDate": "2021-07-19T01:00:00.000Z",
      "substitutedPoliciesNumbers": [
        "783"
      ],
      "productCode": "30103",
      "substitutionReasonId": "2"
    }
    */
  createSubstituentContract(policySubstitutionRequest: PolicySubstitutionRequest, policyNumber: string): Observable<any> {
    return this.resourcesService.sendSubstituentContract(policySubstitutionRequest, policyNumber).pipe(
      share(),
      map(data => {
        return data;
      })
    );
  }
  public findPolicies(body: SearchCardDto){
    return this.resourcesService.findPolicies(body);
  }
}

import { DISTRIBUTION_AMOUNT, DISTRIBUTION_PERC } from './../distribution-toggle.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DistributionToggle } from '../distribution-toggle.model';
import { CurrencyCacheService } from '../../../services/currency-cache.service';
import { RgiCountryLayerCurrencyFormatPipe  } from '@rgi/country-layer';
import { DEFAULT_CURRENCY_SYMBOL } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-distribution-toggle',
  templateUrl: './distribution-toggle.component.html',
  styleUrls: ['./distribution-toggle.component.scss'],
  providers: [RgiCountryLayerCurrencyFormatPipe ]
})
export class LpcDistributionToggleComponent implements OnInit {

  AMOUNT = DISTRIBUTION_AMOUNT;
  PERCENTAGE = DISTRIBUTION_PERC;

  @Input() distributionDefault: DistributionToggle;
  @Output() clickEvent: EventEmitter<DistributionToggle> = new EventEmitter<DistributionToggle>();

  /**
   * @description
   * boolean value for the toggle.
   * if the value is true it means that's amount otherwise is percentage
   */
  isAmount = this.PERCENTAGE;

  currencyCode = DEFAULT_CURRENCY_SYMBOL;

  constructor(
    protected currencyService: CurrencyCacheService,
    protected currencySymbolPipe: RgiCountryLayerCurrencyFormatPipe) {
    this.currencyCode = !!this.currencyService.currency ? currencySymbolPipe.transform(this.currencyService.currency) : this.currencyCode;
  }

  ngOnInit() {
    if (!!this.distributionDefault) {
      this.isAmount = this.distributionDefault;
    }
  }


  toggleSwitch(toggle: DistributionToggle) {
    this.isAmount = toggle;
    this.clickEvent.emit(toggle);
  }


}

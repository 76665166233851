import {DecimalPipe} from '@angular/common';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { Injectable } from "@angular/core";

@Injectable()
export class PlcFormatterUtils {

  public static readonly DEAFULT_LOCALE = 'it-IT';
  public static readonly DEAFULT_CURRENCY = '€';

  private decPipe: DecimalPipe;

  constructor() {
    this.decPipe = new DecimalPipe('it_IT');
  }

  /** @deprecated */
  public static getPercentValueConverted(value: number): number {
    if (value === 1) {return value * 100; }
    if (PlcObjectUtils.countDigit(value) === 0) {
      return value * 100;
    }
    return value;
  }

  /** @deprecated */
  setCurrencyVal(val, currency?, locale?) {
    currency = !!currency ? currency : PlcFormatterUtils.DEAFULT_CURRENCY;
    locale = !!locale ? locale : PlcFormatterUtils.DEAFULT_LOCALE;
    if (val != null) {
      val = this.decPipe.transform(+val, '0.2-2', locale) + (!!currency ? ' ' + currency : EMPTY_STR);
    }
    return val;
  }

  /** @deprecated */
  setDecimalVal(val, decimal, locale?) {
    locale = !!locale ? locale : PlcFormatterUtils.DEAFULT_LOCALE;
    if (val != null) {
      val = this.decPipe.transform(+val, '0.' + decimal + '-' + decimal, locale);
    }
    return val;
  }


}

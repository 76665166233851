import {Component, Input, OnInit} from '@angular/core';
import {RgiRxDatasource, RgiRxDataTableCell} from '@rgi/rx/ui';
import {UserAssociatedLetterEntity} from '../../../dto/user-associated-letter-entity.entity';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';

@Component({
  selector: 'claims-user-checker',
  templateUrl: './user-checker.component.html',
  styleUrls: ['./user-checker.component.scss']
})
export class UserCheckerComponent extends RgiRxDataTableCell<UserAssociatedLetterEntity> implements OnInit {

  @Input() changingValueUserCheck: Subject<boolean>;
  userCheck: UntypedFormGroup;

  constructor(private dataSource: RgiRxDatasource<UserAssociatedLetterEntity>,
              private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.userCheck = this.formBuilder.group({
      userCheckBox: [this.context.row.associatoLettera, Validators.required],
    });

    this.changingValueUserCheck.subscribe(v => {
      this.listnerUpdateValue(v);
    });
  }

  listnerUpdateValue(val: boolean) {
    this.userCheck.get('userCheckBox').setValue(val);
    this.context.row.associatoLettera = val;
  }

  updateSelect($event: Event) {
    this.context.row.associatoLettera = this.userCheck.get('userCheckBox').value;
  }
}

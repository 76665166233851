import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Parameter} from '../../../../models/domain-models/parameter';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {GenericEntity} from '../../../../models/domain-models/generic-entity';
import {Agreement} from '../../../../models/domain-models/parameters/agreement';
import {ParametersService} from '../parameters.service';
import {Ie11UtilityService} from '../../../re-issue-utility/ie-11-utility.service';
import {State} from '@rgi/rx/state';

// TODO: fix all commented stuff

@Component({
  selector: 're-issue-parameter-control',
  templateUrl: './re-issue-parameter-control.component.html',
  styleUrls: ['./re-issue-parameter-control.component.css']
})
export class ReIssueParameterControlComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() parameter: Parameter;
  @Input() parameterControlForm: UntypedFormGroup;
  @Input() stateObs: Observable<State>;
  @Output() updateParameter: EventEmitter<string | null> = new EventEmitter();
  protected subscriptions: Subscription = new Subscription();
  protected state: State;

  constructor(
    protected parameterService: ParametersService,
    protected ie11Utility: Ie11UtilityService
  ) {
  }

  ngOnInit(): void {
    this.stateObs.subscribe((value) => {
      this.state = value;
    });

    if (this.parameter.id === '6') {
      const agreementValues = [];

      this.parameter.parameterValues.forEach((value: Agreement) => {
          agreementValues.push(new GenericEntity(String(value.idAgreement),
            value.code, value.description));
        }
      );

      this.parameter.parameterValues = agreementValues;
    }

    if (this.parameter.id === ParametersService.ID_INSTALMENT_DATES) {
      this.parameter.code = ParametersService.CODE_INSTALMENT_DATES;
      const instalmentDateValues = this.parameterService.getInstalmentDateValues();
      if (instalmentDateValues) {
        instalmentDateValues.forEach(instalmentDate => {
          if (instalmentDate.selected) {
            this.parameterControlForm.controls[this.parameter.id].setValue(instalmentDate.code);
          }
        });
      }
    }

    if (this.parameter.id === ParametersService.ID_PAYMENT_FREQUECY) {
      this.parameter.code = ParametersService.CODE_PAYMENT_FREQUECY;
      const paymentFrequencyValues = this.parameterService.getPaymentFrequencyValues();
      if (paymentFrequencyValues) {
        paymentFrequencyValues.forEach(paymentFrequency => {

          if (paymentFrequency.selected) {

            this.parameterControlForm.controls[this.parameter.id].setValue(paymentFrequency.code);

          }

        });
      }
    }

    if (this.parameter.type === 4) {
      if (this.parameter.disabled) {
        this.parameterControlForm.controls[this.parameter.id].disable();
      }
      this.parameterControlForm.controls[this.parameter.id].setValue(new Date(this.ie11Utility.ie11FixDateString(this.parameter.value)));
      const parameterValueChangesSubscription = this.parameterControlForm.controls[this.parameter.id].valueChanges.subscribe(value => {
          try {
            const date: Date = value;
            const year = date.getFullYear();
            if (year.toString().length === 4) {
              this.onParameterValueChange();
            }
          } catch (e) {
          }
        }
      );
      this.subscriptions.add(parameterValueChangesSubscription);
    }
  }

  onParameterValueChange() {


    let emetti = true;

    if (!this.parameterControlForm.controls[this.parameter.id].value
      && this.parameter.compulsory) {
      this.updateParameter.emit(null);
      return;
    }


    const value = this.parameterControlForm.get(this.parameter.id).value;


    if (this.parameter.type === 6 && value) {
      const regExp = new RegExp(/^(0[0-9]|1[0-9]|2[0-4]):[0-5][0-9]$/);
      if (!regExp.test(value)) {
        this.parameterControlForm.get(this.parameter.id).setErrors({invalidTime: true});
        return;
      } else {
        const hoursAndMinutes = value.split(':');
        if (hoursAndMinutes[0] === '24'
          && hoursAndMinutes[1] !== '00') {
          this.parameterControlForm.get(this.parameter.id).setValue('00:' + hoursAndMinutes[1]);
        }
      }
    }

    if (this.parameter.type === 3) {
      if (!this.parameter.disabled) {
        if (this.parameter.value === 'true') {
          this.parameter.value = 'false';
          this.parameterControlForm.controls[this.parameter.id].setValue('false');
        } else if (this.parameter.value === 'false') {
          this.parameter.value = 'true';
          this.parameterControlForm.controls[this.parameter.id].setValue('true');
        }
      } else {
        emetti = false;
      }
    }
    if (emetti) {
      this.updateParameter.emit(this.parameter.id);
    }

  }

  compareGenericEntities(ent1: any, ent2: any): boolean {
    return ent1 && ent2 ? ent1.id === ent2.id : ent1 === ent2;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

export class ApiCompany {

  constructor(
    public objectId: string,
    public aniaCode: string,
    public extension: string,
    public code: string,
    public damages: boolean,
    public life: boolean,
    public description: string
  ) {
  }

}

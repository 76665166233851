<div class="scheduled-premium-step" [formGroup]="formGroup">
    <div class="col-12 d-flex flex-wrap p-3" *ngIf="active === 2">
        <ng-container *ngFor="let factor of scheduledPremiumFactors">
            <ng-container [ngSwitch]="factor.type">
                <div class="col-md-6 factor form-group" *ngSwitchCase="'STRING'">
                    <label class="label-core" translate>{{!!factor.label ? factor.label : factor.code}}</label>
                    <lic-required *ngIf="!!factor.mandatory" required="true"></lic-required>
                    <input disabled class="form-control" [value]="!!factor.value ? factor.value : ''" [attr.data-qa]="'factor-' + factor.code"
                        [attr.data-mod]="'factor' + factor.code + factor.readOnly"
                        [attr.data-obb]="'factor' + factor.code + true">
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="p-2 mt-3 p-2 mt-3 d-flex flex-column" *ngIf="active > 2">
        <ng-container *ngFor="let factor of scheduledPremiumFactors">
            <span translate>{{factor.label}}: {{factor.value}}</span>
        </ng-container>
    </div>
    <ng-container *ngIf="!isDistributionSameAsInitial">
      <lic-profiles
        [active]="active === 2"
        [totalAmount]="mainProposal.proposal.policyPremium.successiveInstalmentGross"
        [definition]="karmaProfileDefinitions"
        formControlName="profiles"
        [sliderProperty]="sliderProperty">
      </lic-profiles>
      <lic-funds *ngFor="let profileDefinition of selectedScheduledProfileDefinitions"
        [active]="active === 2"
        [totalAmount]="mainProposal.proposal.policyPremium.successiveInstalmentGross"
        [definition]="profileDefinition"
        formControlName="funds"
        [sliderProperty]="sliderProperty"
        [showSliderInput]="true">
      </lic-funds>
    </ng-container>
  </div>

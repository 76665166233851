export const fiscalPeriods = {
    BEFORE_2001: 'BEFORE_2001',
    AFTER_2001: 'AFTER_2001',
    AFTER_2006: 'AFTER_2006',
  };



  // Extracted constant for period mappings
export const KEY_TO_PERIOD_MAPPING: Record<string, string> = {
    K1: 'BEFORE_2001',
    K2: 'AFTER_2001',
    K3: 'AFTER_2006'
  };


export const REVERSIONARY: string = 'reversionary'
export const PAYMENT_METHOD: string = 'paymentMethod'

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {
  AnagQuestionnaireVersionSaveRequest
} from "./model/integration-models";
import {  AnagQuestionnaireSaveEvent,
  AnagQuestionnaireSaveEventInstance} from "@rgi/ng-passpropro/questionnaire";
import {EventService} from '@rgi/rx';
import {SURVEY_SAVED, SurveySaveEvent} from "../survey/model/survey-lifecycle";

@Injectable()
export class CommonIddService {

  private BASE_PATH: String;

  constructor(private httpClient: HttpClient, @Optional() @Inject('environment') private environment: any, private eventService: EventService) {
    if (environment) {
      this.BASE_PATH = environment.api && environment.api.portal ? environment.api.portal.host + environment.api.portal.path : null;
      if (this.BASE_PATH == null) {
        console.error("Invalid environment supplied for integration Services");
      }
    }
  }

  register(){
    this.eventService.listen<SurveySaveEvent>(SURVEY_SAVED).subscribe(
      (next) => {
        // todo validate input
        if (next.event && next.event.surveyVersion) {
          const subject = next.event.subject;
          this.saveIddQuestionnaire({
              surveyVersion: next.event.surveyVersion.uuid,
              idParty: subject.objectId,
              idSalePoint: subject.node.identification,
              questCode: next.event.questCode,
              userCode: next.event.userCode
            }
          )
        }
      }
    )
  }


  saveIddQuestionnaire(anagQuestionnaireVersionSaveRequest: AnagQuestionnaireVersionSaveRequest): void{
    this.httpClient.post(this.BASE_PATH + '/anag/iddquestionnaire', {
      filter:  anagQuestionnaireVersionSaveRequest
    })
      .subscribe(
        on => this.eventService.emit<AnagQuestionnaireSaveEvent>(new AnagQuestionnaireSaveEventInstance(true))
      );
  }
}

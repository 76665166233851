import {Component, Input} from '@angular/core';
import {ContractDetails} from '../../../../models/details/contract-details';

@Component({
  selector: 'lib-consultation-delivery-address',
  templateUrl: './consultation-delivery-address.component.html',
  styleUrls: ['./consultation-delivery-address.component.css']
})
export class ConsultationDeliveryAddressComponent {
  @Input() contract:ContractDetails;

}

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

    <lpc-step label="lpc_date_variation_step" [errors]="errors" fieldId="dates" id="dates" (next)="onNext($event)">
        <lpc-dates-step [inputFields]="dateDefinitions"
                        formControlName="dates"
                        (dateChange)="onDateChange($event)"
                        (reloadRequest)="updateData()"
                        [hide]="activeStep<1">
        </lpc-dates-step>
    </lpc-step>

    <lpc-step [enableAuth]="isAuth" label="lpc_variation_step_label" [errors]="errors" fieldId="transferPolicy" id="transferPolicy" (next)="onNext($event)">
        <div *ngIf="!isAfterId('transferPolicy')" class="row" formGroupName="transferPolicy">
            <div class="col-lg-12" *ngIf="node && !branch">
                <div class="col-lg-6">
                    <label class="label-core">{{node.label}}</label>
                    <lpc-required [required]="node.mandatory"></lpc-required>
                    <ng-select [ngClass]="{ 'is-invalid': formGroup.get('transferPolicy').get('node').errors }"
                               (change)="selectChangeHandlerNode($event)"
                               data-qa="new-life-proposal-node"
                               name="node"
                               formControlName="node"
                               [required]="node.mandatory">
                        <ng-option *ngFor="let sNode of descriptions" [value]="sNode.displayValue">
                            {{sNode.description}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <ng-container *ngIf="branch">
                <div class="col-lg-6">
                    <label class="label-core">{{node.label}}</label>
                    <lpc-required [required]="node.mandatory"></lpc-required>
                    <ng-select [ngClass]="{ 'is-invalid': formGroup.get('transferPolicy').get('node').errors }"
                            (change)="selectChangeHandlerNode($event)"
                            data-qa="new-life-proposal-node"
                            name="node"
                            formControlName="node"
                            [required]="node.mandatory">
                        <ng-option *ngFor="let sNode of descriptions" [value]="sNode.displayValue">
                            {{sNode.description}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-lg-6">
                    <label class="label-core">{{branch.label}}</label>
                    <lpc-required [required]="branch.mandatory"></lpc-required>
                    <ng-select [ngClass]="{ 'is-invalid': formGroup.get('transferPolicy').get('newBranchOffice').errors }"
                            data-qa="new-life-proposal-node"
                            name="newBranchOffice"
                            formControlName="newBranchOffice"
                            [required]="branch.mandatory">
                        <ng-option *ngFor="let branch of branches" [value]="branch.displayValue">
                            {{branch.description}}
                        </ng-option>
                    </ng-select>
                </div>
            </ng-container>
        </div>
        <div *ngIf="isAfterId('transferPolicy')" class="row" [formGroup]="formGroup">
            <div class="row">
                <div class="col-md-6" *ngIf="node && selNode">
                    <span for="label">{{node.label}}</span>
                    <span for="value">: {{ selNode }}</span>
                </div>
                <div class="col-md-6" *ngIf="branch && selBranch">
                    <span for="label">{{branch.label}}</span>
                    <span for="value">: {{ selBranch }}</span>
                </div>
            </div>
        </div>
    </lpc-step>

    <lpc-step [enableAuth]="isAuth" label="lpc_mean_of_payment" [errors]="errors" fieldId="payment" id="payment" (next)="onNext($event)">
        <lpc-payment-step
            [required]="true"
            [paymentTypeDefinitions]="paymentTypes"
            formControlName="payment"
            [summary]="isAfterId('payment')"
            [isPaymentTypeEditable]="isPaymentEditable">
        </lpc-payment-step>
    </lpc-step>

    <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'">
        <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    </lpc-step>

    <lpc-step [enableAuth]="isAuth" label="lpc_confirm_variation_step" [errors]="errors" fieldId="documents" id="documents" [nextVisible]=false>

        <lpc-document-wrapper
            *ngIf="!!publishMessage"
            [contractId]="getContractId"
            [operationCode]="getOperationCode"
            [publishMessage]="publishMessage"
            [isAuth]="isAuth"
            [authorizationId]="authorizationId"
            [printMode]="printMode"
            [config]="documentsConfig"
            (documentEvent)="handleDocumentEvent($event)">
        </lpc-document-wrapper>

        <lpc-navigation-buttons
            id="last-step-buttons"
            (back)="closeCardWithoutModal()"
            [backLabel]="'lpc_close_button'"
            [backVisible]="true"
            [nextVisible]="false"
        >
        </lpc-navigation-buttons>

    </lpc-step>

</lpc-stepper>

<div *ngIf="!isActiveStep('documents')" class="btn-group btn-group-justified external">
    <lpc-navigation-buttons (back)="closeCard()"
                            [nextVisible]="false"
                            [qa]="'annulla'">
    </lpc-navigation-buttons>
</div>

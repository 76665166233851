import { Pipe, PipeTransform } from '@angular/core';
import {GPPolicyDataField} from '../../../group-policy-models/group-policy-issue-policy-data';

@Pipe({
  name: 'gpFieldsShowCustomAction'
})
export class GpFieldsShowCustomActionPipe implements PipeTransform {

  transform(field: GPPolicyDataField): any {
    switch (field.code) {
      case 'COINSURANCE_OUR_PERCENTAGE':
        return field.editable;
      default:
        return false;
    }
  }

}

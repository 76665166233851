import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, AfterViewInit} from '@angular/core';
import {fromEvent, merge, Subscription} from 'rxjs';
import {auditTime} from 'rxjs/operators';

@Directive({
  selector: '[rgiRxMultiselectSelectedCounter]',
  exportAs: 'rgiRxMultiselectSelectedCounter',
})
export class RgiRxMultiselectSelectedCounterDirective implements OnInit, OnDestroy {

  visible = true;

  @Output()
  onVisibilityChange = new EventEmitter<void>();

  private windowResizeSubscription = Subscription.EMPTY;
  observer: MutationObserver;

  constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.windowResizeSubscription.unsubscribe();
    this.windowResizeSubscription = fromEvent(window, 'resize').pipe(auditTime(250))
      .subscribe(() => this.hideIfOverflown());
  }

  hideIfOverflown() {
    const currentVisibility = this.visible;
    if (!currentVisibility) {
      this.renderer.removeStyle(this.elementRef.nativeElement, 'display');
    }
    setTimeout(() => {
      const elementRight = this.elementRef.nativeElement.getBoundingClientRect().right;
      if (elementRight && elementRight > this.elementRef.nativeElement.parentElement.parentElement.getBoundingClientRect().right - 40) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        this.visible = false;
      } else {
        this.visible = true;
      }
      if (this.visible !== currentVisibility) {
        this.onVisibilityChange.emit();
      }
    });
  }

  ngOnDestroy(): void {
    this.windowResizeSubscription.unsubscribe();
  }
}

import {Inject, Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Clause} from '../../models/domain-models/clause';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {ReIssueRefactorService} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources/re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueClausesService {

  protected baseApiUrlV2;
  protected contractEndpoint;
  protected clause: Clause;
  private refactorService: ReIssueRefactorService;
  protected apiPcPortfolioConf: any;

  constructor(
    protected http: HttpClient,
    @Inject('environment') private apiConf: any,
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;
  }

  toFormGroup(clauseList: Clause[]): UntypedFormGroup {
    const group: any = {};

    clauseList.forEach(clause => {
      group[clause.code] = new UntypedFormControl(clause.selected, clause.compulsory ? Validators.required : null);
    });
    return new UntypedFormGroup(group);
  }


  getClauses(contract: string, level: string): Observable<Array<Clause>> {
    const query = '?level=' + level;
    return this.http.get<Array<Clause>>(this.getClausesUri(contract) + query).pipe(share());
  }

  putClauses(contract: string, clauses: Array<Clause>): Observable<Array<Clause>> {
    return this.http.put<Array<Clause>>(this.getClausesUri(contract), clauses).pipe(
      share()
    );
  }

  getClausesUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'contract/' +
      contract +
      '/clauses';
  }

  setClause(clause: Clause) {
    this.clause = clause;
  }

  getClause() {
    return this.clause;
  }
}

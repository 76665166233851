<div class="grid-item card card-height-normal card-main">
  <div class="modal-content">
      <div class="modal-header">
          <span class="rgifont rgi-dettagli_tecnici"></span>
          {{ '_CLAIMS_._LIST_OF_RELATED_CLAIMS' | translate }}
      </div>

      <div class="container-fluid similar-claims-table table-window-height" data-qa="similar-claims-list">
          <table class="table-responsive table coreDT">
            <thead>
              <tr class="head-result thDataTable">
        
                <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                  <span> </span>
                </td>
        
                <td class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <span attr.data-qa="subject-label">{{ '_CLAIMS_._SX_SUBJECT' | translate }}</span>
                </td>
        
                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span attr.data-qa="cf-piva-label">{{ '_CLAIMS_._CF__PIVA' | translate }}</span>
                </td>
        
                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span attr.data-qa="reference-label">{{ '_CLAIMS_._REFERENCE' | translate }}</span>
                </td>
        
                <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                  <span attr.data-qa="dol-label">{{ '_CLAIMS_._DOL' | translate }}</span>
                </td>
        
                <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                  <span attr.data-qa="don-label">{{ '_CLAIMS_._DON' | translate }}</span>
                </td> 

                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span attr.data-qa="policy-number-label">{{ '_CLAIMS_._POLICY' | translate }}</span>
                </td>
              </tr>
        
            </thead>
            
            <tbody>
              <tr *ngFor="let claimByActor of claimList; let even = even; let odd = odd; let claimIndex = index"
                [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
                attr.data-qa="similar-claim-by-actor-{{claimIndex}}">
        
                <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                  <span attr.data-qa="claimByActor-full-match-{{claimIndex}}" class="claims-popover icon rgifont rgi-survey_ok"
                    [ngClass]="{'green-color': claimByActor.subjectId == selectedSubjectId, 'yellow-color': claimByActor.subjectId != selectedSubjectId}">
                    <span class="claims-popover-container">
                      <div  *ngIf="claimByActor.subjectId == selectedSubjectId">{{ '_CLAIMS_._SELECTECT_SUBJECT' | translate }}</div>
                      <div  *ngIf="claimByActor.subjectId != selectedSubjectId">{{ '_CLAIMS_._SIMILAR_SUBJECT' | translate }}</div>
                    </span>
                  </span>
                </td>
        
                <td class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <span
                    attr.data-qa="claimByActor-description-{{claimIndex}}">{{ claimByActor.partyDesc }}</span>
                </td>
        
                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span attr.data-qa="claimByActor-cfOrPiva-{{claimIndex}}">{{ claimByActor.cfOrPiva }}</span>
                </td>
        
                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span attr.data-qa="claimByActor-claimNumber-{{claimIndex}}">{{ claimByActor.claimNumber || claimByActor.potentialClaimNumber  }}</span>
                </td>
        
                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span
                    attr.data-qa="claimByActor-dol-{{claimIndex}}">{{ claimByActor.occurrenceDate | date:'dd/MM/yyyy'  }}</span>
                </td>
        
                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span
                    attr.data-qa="claimByActor-don-{{claimIndex}}">{{ claimByActor.inquiryDate | date:'dd/MM/yyyy'  }}</span>
                </td>
        
                <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <span
                    attr.data-qa="claimByActor-policy-number-{{claimIndex}}">{{ claimByActor.policyDesc }}</span>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>

      <div class="modal-footer">
          <div class="btn-group btn-group-justified">
              <div class="btn-group">
                  <button class="btn btn-warning pull-right" (click)="closeModal($event)"
                      attr.data-qa="claim-opening-policy-details-close-btn">{{ '_CLAIMS_._OK' | translate | uppercase}}</button>
              </div>
          </div>
      </div>
  </div>
</div>

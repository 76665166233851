import {Binder} from '../../domain-models/parameters/binder';
import {AutomaticRenewal} from '../../domain-models/automatic-renewal';
import {CoInsuranceDTO} from '../../domain-models/co-insurance';
import {GenericEntityPcPortfolio} from '../genericEntityPcPortfolio';
import {IndexType} from '../../domain-models/parameters/index-type';
import {PolicyGenericDto} from '../policy-generic-dto';
import {RegulationPremium} from '../regulation-models/pc-regulation-premium';
import {AnagIssueSubject} from '../../../re-issue/re-issue-anag/anag-issue';

export class ProposalData {
  additionalLabels: any;
  product: any;
  company: any;
  managementNode: any;
  currency: any;
  agreement: any;
  useTax: any;
  premium: any;
  premiumAdjustmentConfig: number;
  minimumPremiumRegulationPercentage: number;
  indexationConfigPP: number;
  indexType: IndexType;
  contractNumber: any;
  contractStatus: any;
  assets: any;
  resourceId: string;
  issuingDate: any;
  effectDate: any;
  public backdatingDays: Date;
  public postdatingDays: Date;
  msgDateBack: string;
  msgDatePost: string;
  instalmentDates: Array<string>;
  automaticRenewal: AutomaticRenewal;
  policyExpiration: any;
  proposalExpiration: any;
  renewal: any;
  authorize: boolean;
  fees: any;
  coOwnerEnabled: boolean;
  indirectCoInsuranceConfig: number;
  policyRiskCommon: number;
  managerOfRelatedPolicies: number;
  complementaryPolicies: number;
  policySecondRisk: number;
  coInsurance:CoInsuranceDTO;
  subscriber: any;
  coOwners: AnagIssueSubject[];
  additionalTechnicalData: AdditionalTechnicalData;

  public coInsuranceTypes: GenericEntityPcPortfolio[];

  constructor() {
  }
}

export class ProposalDataResponse extends ProposalData {
  id: string;
  coverageExpiration: any;
  premiumAdjustment: boolean;
  premiumAdjustmentConfig: number;
  minimumPremiumRegulationPercentage: number;
  indexationConfigPP: number;
  indexType: IndexType;
  durationInDays: number;
  durationInYears: number;
  authorize: boolean;
  errorMessages: boolean;
  public binder: Binder;

}

export class ProposalsApiRespose {
  id: string;
  proposalSummary: ProposalData;
}

export class AdditionalTechnicalData {
  commonRiskPolicies: Array<PolicyGenericDto>;
  linkedPolicies: Array<PolicyGenericDto>;
  complementaryPolicies: Array<PolicyGenericDto>;
  indirectCoinsurancePolicies: Array<PolicyGenericDto>;
  secondRiskPolicies: Array<PolicyGenericDto>;
  commonRiskPoliciesChecked: boolean = null;
  indirectCoinsuranceChecked: boolean = null;
  complementaryPoliciesChecked: boolean = null;
  isComplementaryPoliciesSet: boolean = false;
  linkedPoliciesChecked: boolean = null;
  secondRiskPoliciesChecked: boolean = null;
}

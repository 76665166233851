<div class="step-header" #stepHeader>
  <span class="rgifont rgi-check icon-sel-card" id="bordered-icon-sel" data-qa="step-checked" *ngIf="opened && !active" ></span>
  <span class="rgifont rgi-chevron-down center" style="color:white" data-qa="step-opened" *ngIf="active"></span>
  <span class="glyphicon glyphicon-chevron-right step" *ngIf="!active"></span>
  <span class="header-step" translate>{{ label }}</span>
  <span id="step-pencil"
        class="rgifont rgi-pencil icon-sel-card" (click)="onHeaderClick()"
        *ngIf="opened && !active && !jump && !stepper.isPublished" [attr.data-qa]="label"></span>
</div>
<div #stepBody class="step-body" [attr.data-qa]="'step-'+id">
  <div #stepBodyContent>
    <ng-content></ng-content>
    <lpc-error-messages *ngIf="active && feFieldId" [errors]="feErrors" [fieldType]="feFieldId"></lpc-error-messages>
    <!-- old management to keep the errors visible on the next step -->
    <lpc-error-messages *ngIf="active && !!fieldTypes.length && !!feFieldId" [errors]="filteredErrors" [fieldType]="feFieldId" [fieldTypes]="fieldTypes"></lpc-error-messages>
    <lpc-error-messages *ngIf="active && !fieldTypes.length" [errors]="filteredErrors" [fieldType]="fieldId"></lpc-error-messages>
    <!-- end. old management to keep the errors visible on the next step -->

    <!-- new management to keep the errors visible on the specific step -->
    <lpc-error-messages *ngIf="!active && !!(filteredErrors | extractFromArray : ERROR_TYPE_FIELD: ERROR_TYPE_PERSISTENT)" [errors]="[filteredErrors | extractFromArray : ERROR_TYPE_FIELD: ERROR_TYPE_PERSISTENT]" [fieldType]="fieldId"></lpc-error-messages>
    <!-- end. new management to keep the errors visible on the specific step -->
    <lpc-navigation-buttons (back)="onBack()" (next)="onNext(fieldId)"
                            (confirmAndAccept)="onConfirmAndAccept(fieldId)"
                            [nextLabel]="getNextLabel"
                            [backVisible]="false" [qa]="fieldId"
                            [nextVisible]="nextVisible"
                            [disableNext]="disableNext"
                            *ngIf="active" [isConfirm]="isLastStep"
                            [isConfirmAndAccept]="isConfirmAndAccept && isLastStep">
    </lpc-navigation-buttons>
  </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'pnc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class SelectComponent implements OnInit {

  @Input() id: string;
  @Input() name: string;
  @Input() options: any[];
  @Input() optionLabel = 'label';
  @Input() label = '';
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() selectedOption: any;

  @Output() selectedEvent = new EventEmitter();

  constructor() {
  }

  getLabel(option) {
    return option[this.optionLabel];
  }
  ngOnInit() {
    this.selectedEvent.emit(this.selectedOption);
  }

  onChange() {
    this.selectedEvent.emit(this.selectedOption);
  }
}

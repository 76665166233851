import {ChangeDetectorRef, Inject, Injectable, LOCALE_ID, Provider} from '@angular/core';
import {DatePipe, LowerCasePipe, TitleCasePipe, UpperCasePipe} from '@angular/common';
import {TableSchemaFormatChain, TableSchemaFormatSingle} from './table-api';
import {RgiRxTranslatePipe, RgiRxTranslationService} from '@rgi/rx/i18n';
import {LoggerFactory} from '@rgi/rx';
import {RGI_RX_DATA_TABLE_PIPE, RGI_RX_DATA_TABLE_PIPE_VIEW, RgiRxDataTablePipe} from './data-table-api';


/**
 * Factory to create the uppercase pipe
 */
export function uCasePipe(): RgiRxDataTablePipe<UpperCasePipe> {
  return {
    pipe: new UpperCasePipe(),
    name: 'uppercase'
  };
}

/**
 * Factory to create the lowercase pipe
 */
export function lCasePipe(): RgiRxDataTablePipe<LowerCasePipe> {
  return {
    pipe: new LowerCasePipe(),
    name: 'lowercase'
  };
}

/**
 * Factory to create the titlecase pipe
 */
export function titleCasePipe(): RgiRxDataTablePipe<TitleCasePipe> {
  return {
    pipe: new TitleCasePipe(),
    name: 'titlecase'
  };
}

/**
 * Factory to create the date pipe
 * @param locale the LOCALE_ID in use
 */
export function datePipe(locale: string): RgiRxDataTablePipe<DatePipe> {
  return {
    pipe: new DatePipe(locale),
    name: 'date'
  };
}

export function translatePipe(translationService: RgiRxTranslationService, changeDetectorRef: ChangeDetectorRef): RgiRxDataTablePipe<RgiRxTranslatePipe> {
  const rgiRxTranslatePipe = new RgiRxTranslatePipe(translationService, changeDetectorRef);
  return {
    pipe: rgiRxTranslatePipe,
    name: 'translate',
    dispose: () => {
      rgiRxTranslatePipe.clear();
    }
  };
}

/**
 * A provider that collects the RgiRxDataTablePipe by name to allow invoking pipes at runtime
 */
@Injectable()
export class RgiRxDataTablePipeProvider {

  private readonly logger = LoggerFactory();

  private pipes = new Map<string, RgiRxDataTablePipe<any>>();

  constructor(
    @Inject(RGI_RX_DATA_TABLE_PIPE) pipes: RgiRxDataTablePipe<any>[],
    @Inject(RGI_RX_DATA_TABLE_PIPE_VIEW) viewPipes: RgiRxDataTablePipe<any>[]
  ) {
    pipes.forEach(p => {
      this.pipes.set(p.name, p);
    });

    viewPipes.forEach(p => {
      this.pipes.set(p.name, p);
    });

    this.logger.debug('RgiRxDataTablePipeProvider::init registered pipes', this.pipes);
  }

  /**
   * Transform the value using the selected pipe
   * @param value the value to transform
   * @param pipe the pipe to use
   * @param args any pipe argument
   */
  transformWith(value: any, pipe: string, args: any[] = []): any {
    if (!this.pipes.has(pipe)) {
      throw new Error(`${pipe} pipe is not registered as RgiRxDataTablePipe`);
    }
    const tablePipe = this.pipes.get(pipe);
    const transformed = tablePipe.pipe.transform(value, ...args);

    if (typeof tablePipe.dispose === 'function') {
      tablePipe.dispose();
    }
    return transformed;
  }

  transform(value: any, format?: TableSchemaFormatSingle | TableSchemaFormatChain) {
    if (format.hasOwnProperty('pipe')) {
      const schemaFormat = format as TableSchemaFormatSingle;
      return this.transformWith(value, schemaFormat.pipe, schemaFormat.args);
    } else if (format.hasOwnProperty('pipes')) {
      const schemaFormat = format as TableSchemaFormatChain;
      // accumulator value
      let lastValue = value;
      schemaFormat.pipes.forEach(
        pipeFormat => {
          lastValue = this.transformWith(lastValue, pipeFormat.pipe, pipeFormat.args);
        }
      );
      return lastValue;
    }
    return value;
  }

}


export const RGI_RX_DATA_TABLE_PIPE_PROVIDERS: Provider[] = [
  {provide: RGI_RX_DATA_TABLE_PIPE, multi: true, useFactory: uCasePipe},
  {provide: RGI_RX_DATA_TABLE_PIPE, multi: true, useFactory: lCasePipe},
  {provide: RGI_RX_DATA_TABLE_PIPE, multi: true, useFactory: titleCasePipe},
  {provide: RGI_RX_DATA_TABLE_PIPE, multi: true, useFactory: datePipe, deps: [LOCALE_ID]},
];

export const RGI_RX_DATA_TABLE_PIPE_VIEW_PROVIDERS: Provider[] = [
  {
    provide: RGI_RX_DATA_TABLE_PIPE_VIEW,
    multi: true,
    useFactory: translatePipe,
    deps: [RgiRxTranslationService, ChangeDetectorRef]
  },
  {provide: RgiRxDataTablePipeProvider, useClass: RgiRxDataTablePipeProvider}
];



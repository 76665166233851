import { Injectable } from '@angular/core';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import {
  CaiOpeningSingle,
  CaiOpeningState,
  DetailMotorFlow,
  OutputDataFormSubjectHolder, OutputSimilarClaims, ReopenFromJs
} from '../config-model/cai-opening-config.module';
import { ActiveRoute } from '@rgi/rx/router';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { OutputShowDetail } from '../../shared/dsc-similar-claims/dsc-similar-claims.config';
import { IncidentData } from '../../shared/dsc-incident-data/dsc-incident-data.model';
import { AdditionalData } from '../../shared/dsc-additional-data/dsc-additional-data.model';
import { CircumstanceIncident } from '../../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import {ModalService} from '@rgi/rx/ui';
import {GlobalModalConfirmComponent} from '../../shared/modal/global-modal-confirm/global-modal-confirm.component';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import { VehicleData } from '../../shared/dsc-shared-model/dsc-model-vehicle';
import { InsurancePolicy, PolicyData } from '../../shared/dsc-shared-model/dsc-model-policy';
import { ClaimDetail } from '../../shared/dsc-shared-model/dsc-model-claims';
import { Guardian, Heir } from '../../shared/dsc-shared-model/dsc-model-subject';
import { CodiceDescrizione } from '../../shared/dsc-shared-model/dsc-model-utility';
import { CarBodywork } from '../../shared/dsc-shared-model/dsc-model-bodywork';
import { AddressEntityData } from '../../shared/dsc-shared-model/dsc-model-address';
import { CaiOpeningService } from './cai-opening.service';
import { CaiOpeningPartyUtility } from './utility/cai-opening-party.utility';
import { QuestionnaireDetail } from '../config-model/cai-opening-questionnaire.model';
import { OutputSetClaim } from '../config-model/cai-opening-claims.module';


@Injectable()
export class CaiOpeningStateService extends AbstractStateManager<CaiOpeningState> {
  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    public caiOpeningService: CaiOpeningService,
    private modalServiceRgi: ModalService,
    public translateService: RgiRxTranslationService,
    public caiOpeningClaimUtility: CaiOpeningPartyUtility
  ) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<CaiOpeningState>(this.storeKey)
        ? stateStoreService.get<CaiOpeningState>(this.activeRoute.key)
        : new CaiOpeningState(this.storeKey); // get existing state or create a new one
    if (this.activeRoute.getRouteData<any>().reopenFromJs &&
        this.activeRoute.getRouteData<any>().reopenFromJs.isReopenedFromJs) {
        /*
        ** entro se sto ritornando dal riepilogo sinistro e setto i dati presi da angular js
        */
        this.updateState$(this.caiOpeningService.setClaimFromReopenJs$(
          of(st), this.activeRoute.getRouteData<any>().reopenFromJs, this.activeRoute.key));
    } else if (this.activeRoute.getRouteData<any>().detailNewClaimInfo &&
        this.activeRoute.getRouteData<any>().detailNewClaimInfo !== null) {
        /*
        ** entro se sto aprendo un nuovo sinistro dalla home, setto i dati presi dal filtro applicato
        */
        this.updateState$(this.caiOpeningService.getDetailStepper$(of(st),
        this.initObjectState(
            this.activeRoute.getRouteData<any>().detailNewClaimInfo,
            this.activeRoute.getRouteData<any>().reopenFromJs),
            this.activeRoute.key));
    } else {
      this.updateState$(of(st));
    }
    this.caiOpeningService.isReopenCaiModule.subscribe((res) => {
      if (res) {
        this.isReopenFormJs.next(true);
      }
    });
    this.translateService.translate('_CLAIMS_._MESSAGE_._EXISTING_PES_ALERT').subscribe(res => {
      this.messageWarningExistingPesAlert = res;
    });
  }

  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  isReopenFormJs: BehaviorSubject<boolean> = new BehaviorSubject(false);
  messageWarningExistingPesAlert: string = '';
  messageWarningCompulsoryQuest: string = '';

  private COVERTURE_RCA: string = '000010';
  private COVERTURE_RCNATANTI: string = '000012';

  getSession(): any {
    return this.activeRoute.getRouteData<any>();
  }

  clearState() {
    const clearedState$ = this.caiOpeningService.clearState$(
      of(this.getCurrentState())
    );
    this.updateState$(clearedState$);
  }

  getAllPolicy(): any[] {
    return this.getCurrentState().detailNewClaimInfo.policiesList;
  }

  exit() {
    this.clearState();
    this.closeCardSession();
  }

  getAllSimilarClaims(selectedPolicy: InsurancePolicy) {
    this.updateState$(
      this.caiOpeningService.getAllSimilarClaims(
        of(this.getCurrentState()),
        selectedPolicy
      )
    );
  }

  resetSimilarClaims() {
    const st = this.getCurrentState();
    st.similarClaims = new OutputSimilarClaims();
    this.updateState$(
      this.caiOpeningService.resetSimilarClaims$(of(this.getCurrentState()))
    );
  }

  generateClaim(selectedPolicy: InsurancePolicy): boolean {
    const  state = this.getCurrentState();

    if (state.listCovertureBranches?.claimBranchCode
      && (state.listCovertureBranches.claimBranchCode === this.COVERTURE_RCA
      || state.listCovertureBranches.claimBranchCode === this.COVERTURE_RCNATANTI)
      && state.similarClaims?.listPotentialClaims?.find((el) =>
        state.detailNewClaimInfo.occurrenceDate.toDateString() === new Date(el.dataAccadimento).toDateString())) {
      this.modalServiceRgi.open(GlobalModalConfirmComponent, {
        contentMessage: this.messageWarningExistingPesAlert,
      }).onClose.subscribe(response => {
        if (response && response !== 'Close') {
          this.updateState$(
            this.caiOpeningService.generateClaim$(
              of(this.getCurrentState()),
              selectedPolicy
            )
          );
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.updateState$(
        this.caiOpeningService.generateClaim$(
          of(this.getCurrentState()),
          selectedPolicy
        )
      );
      return true;
    }
  }

  updateClaimGenerated(newClaim: ClaimDetail) {
    this.updateState$(
      this.caiOpeningService.updateContractState$(
        of(this.getCurrentState()),
        newClaim
      )
    );
  }

  updatePolicyHolder(policyHolder: OutputDataFormSubjectHolder) {
    this.updateState$(
      this.caiOpeningService.updatePolicyHolder$(
        policyHolder,
        of(this.getCurrentState())
      )
    );
  }
  updatePartyRole(partyRole: CodiceDescrizione) {
    this.updateState$(
      this.caiOpeningService.updatePartyRole$(
        partyRole,
        of(this.getCurrentState())
      )
    );
  }
  updateVehicle(vehicle: VehicleData) {
    this.updateState$(
      this.caiOpeningService.updateVehicle$(vehicle, of(this.getCurrentState()))
    );
    // aggiornamento di generaldata.claim
  }
  updatePolicyData(policyData: PolicyData) {
    this.updateState$(
      this.caiOpeningService.updatePolicyData$(
        policyData,
        of(this.getCurrentState())
      )
    );
  }
  updateOwner(owner: OutputDataFormSubjectHolder) {
    this.updateState$(
      this.caiOpeningService.updateOwner$(owner, of(this.getCurrentState()))
    );
  }
  updateDriver(driver: OutputDataFormSubjectHolder) {
    this.updateState$(
      this.caiOpeningService.updateDriver$(driver, of(this.getCurrentState()))
    );
  }
  updateDamagedParts(damagedVehicle: VehicleData) {
    this.updateState$(
      this.caiOpeningService.updateDamagedParts$(
        damagedVehicle,
        of(this.getCurrentState())
      )
    );
  }
  updateCarBodywork(carBodywork: CarBodywork) {
    this.updateState$(
      this.caiOpeningService.updateCarBodywork$(
        carBodywork,
        of(this.getCurrentState())
      )
    );
  }
  updateQuestionnaireState(questionnaire: QuestionnaireDetail) {
    this.updateState$(
      this.caiOpeningService.updateQuestionnaireState$(
        questionnaire,
        of(this.getCurrentState())
      )
    );
  }
  updateIncidentData(incidentData: IncidentData) {
    this.updateState$(
      this.caiOpeningService.updateIncidentData$(
        incidentData,
        of(this.getCurrentState())
      )
    );
  }
  updateAddress(address: AddressEntityData) {
    this.updateState$(
      this.caiOpeningService.updateAddress$(address, of(this.getCurrentState()))
    );
  }
  updateAdditionalData(additionalData: AdditionalData) {
    this.updateState$(
      this.caiOpeningService.updateAdditionalData$(additionalData, of(this.getCurrentState()))
    );
  }
  updateCircumstance(circumnstance: CircumstanceIncident) {
    this.updateState$(
      this.caiOpeningService.updateCircumstance$(circumnstance, of(this.getCurrentState()))
    );
  }
  outputHolderHeir(heir: Heir) {
    this.updateState$(
      this.caiOpeningService.updatePolicyHolderHeirs$(
        heir,
        of(this.getCurrentState())
      )
    );
  }
  outputOwnerHeir(heir: Heir) {
    this.updateState$(
      this.caiOpeningService.updateOwnerHeirs$(
        heir,
        of(this.getCurrentState())
      )
    );
  }
  outputDriverHeir(heir: Heir) {
    this.updateState$(
      this.caiOpeningService.updateDriverHeirs$(
        heir,
        of(this.getCurrentState())
      )
    );
  }
  updateAddressDataDamagedParts(addressData: AddressEntityData) {
    this.updateState$(
      this.caiOpeningService.updateAddressDataDamagedParts$(
        addressData,
        of(this.getCurrentState())
      )
    );
  }
  goToSummaryFromQuestionnaire() {
    this.caiOpeningService
      .setQuestionnaire$(this.getCurrentState())
      .subscribe((res) => {
        if (res) {
          this.caiOpeningService.goToSummary$(this.getCurrentState());
        }
      });
  }
  setQuestionnaire(): Observable<OutputSetClaim> {
    return this.caiOpeningService
      .setQuestionnaire$(this.getCurrentState());
  }

  initObjectState(claimDetail: any, reopenFromJs: ReopenFromJs): DetailMotorFlow {
    const initDetail = new DetailMotorFlow();
    initDetail.detailNewClaimInfo = claimDetail;
    initDetail.reopenFromJs = reopenFromJs;
    return initDetail;
  }

  goToDetailFromSimilarClaims(event: OutputShowDetail) {
    this.caiOpeningService.goToDetailClaim$(
      this.getCurrentState(),
      event.numeroDenuncia,
      this.activeRoute.id
    );
  }

  goToCaiModule(selectedPolicy: InsurancePolicy, signType: CodiceDescrizione) {
    this.caiOpeningService.goToCaiModuleJs$(
      this.activeRoute.id,
      selectedPolicy,
      this.getCurrentState(),
      signType
    );
    this.closeCardSession();
  }

  closeCardSession() {
    this.caiOpeningService.closeSession$(this.activeRoute.id);
  }

  setStateClaim(motorFlowSingle: CaiOpeningSingle) {
    this.updateState$(
      this.caiOpeningService.updateClaimForCAI$(
        motorFlowSingle,
        of(this.getCurrentState())
      )
    );
  }

  saveDraftClaims(isDraft: boolean) {
    this.caiOpeningService
      .saveDraftClaims$(this.getCurrentState(), true)
      .then((resolve: boolean) => {
        if (resolve) {
          this.closeCardSession();
        }
      });
  }

  getAllCovertureBranches(policyNumber: string) {
    this.updateState$(
      this.caiOpeningService.getCovertureBranch$(
        policyNumber,
        of(this.getCurrentState())
      )
    );
  }

  nextStepToCaiModule(currentState: CaiOpeningState): Promise<boolean>{
    return new Promise(resolveNextStep => {
      this.caiOpeningClaimUtility.setClaimDetail(currentState, currentState.generatedClaim.claim).then(resolve => {
        this.updateClaimGenerated(resolve);
        this.setQuestionnaire().subscribe((res: OutputSetClaim)=>{
          if(res.claim && res.claim.extension && res.claim.extension.properties && res.claim.extension.properties[0].chiave === 'WRONG_ANSWER'){
                 this.translateService.translate('_CLAIMS_._COMPULSORY_QUEST').subscribe(res => {this.messageWarningCompulsoryQuest = res; });
                 this.caiOpeningService.alertMessage(this.messageWarningCompulsoryQuest + res.claim.extension.properties[0].valore);
          } else if (res.openClaim) {
                // vado al vecchio modulo cai
                this.goToCaiModule(currentState.selectedPolicy, res.claim.signType);
                resolveNextStep(true);
            } else {
                // vado al nuovo modulo cai
                currentState.generatedClaim.claim.signType = res.claim.signType;
                this.caiOpeningClaimUtility.setClaimFromState(currentState.generatedClaim.claim, false).then(resolveSingle => {
                    resolveSingle.insuranceCompany = currentState.singleCai.insuranceCompany;
                    resolveSingle.insuranceCompany.isMaterialLossCovertureOnPolicy =
                    currentState.generatedClaim.claim.isMaterialLossCovertureOnPolicy === null ? false :
                    currentState.generatedClaim.claim.isMaterialLossCovertureOnPolicy;
                    this.setStateClaim(resolveSingle);
                    resolveNextStep(true);
                });
            }
        });
      });
    });
  }

  /* heirsAreValid(st: OuputRouteMotorFlow): Promise<boolean> {
    return new Promise(heirsValid => {
      const policyHolder = st.singleCai.policyHolder;
      const owner = st.singleCai.owner;
      const driver = st.singleCai.driver;
      const holderHeirs =  st.singleCai.heirsList.policyHolder.potentialHeirs.filter(el => el.checked);
      const ownerHeirs =  st.singleCai.heirsList.owner.potentialHeirs.filter(el => el.checked);
      const driverHeirs =  st.singleCai.heirsList.driver.potentialHeirs.filter(el => el.checked);
      const holderHasFatalDate = !!policyHolder.subjectDataFormInjury.fatalDate;  // se la data � presente  restituisce true
      const ownerHasFatalDate = !!owner.subjectDataFormInjury.fatalDate;  // se la data � presente  restituisce true
      const driverHasFatalDate = !!driver.subjectDataFormInjury.fatalDate;  // se la data � presente  restituisce true
      const totalHeir = this.getTotalHeir(
          holderHeirs,
          ownerHeirs,
          driverHeirs
      );
      // controllo che nessuno tra i soggetti scelti come erede abbia la data decesso
      if (holderHasFatalDate && totalHeir.includes(policyHolder.objectId)) {
          this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID').subscribe(
              res => {
                  this.alertService.translatedMessage(res);
              })
              heirsValid(false);
      } else if (ownerHasFatalDate && totalHeir.includes(owner.objectId)) {
          this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID').subscribe(
              res => {
                  this.alertService.translatedMessage(res);
              })
              heirsValid(false);
      } else if (driverHasFatalDate && totalHeir.includes(driver.objectId)) {
          this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID').subscribe(
              res => {
                  this.alertService.translatedMessage(res);
              })
              heirsValid(false);
      } else {
        heirsValid(true);
      }

    })
  }

  getTotalHeir(heirs1: Heir[], heirs2: Heir[], heirs3: Heir[]): string[] {
      const mergedHeirs = [...new Set([...heirs1, ...heirs2, ...heirs3])];
      return mergedHeirs.map((heir: Heir)=> heir.linkedPartyObjectId);
  } */

  verifyAuthorizedForClaim(state: CaiOpeningState, selectedPolicy: InsurancePolicy): Promise<boolean> {
    state.selectedPolicy = selectedPolicy;
    return new Promise((resolve)=>{
      this.caiOpeningService.getPolicyAdditionalData$(state).subscribe(responseAdditionalData => {
        state.additionalInfoCoverture = responseAdditionalData;
        this.updateState$(of(state));
        resolve(true);
      }), (error) => {
        resolve(false);
      }
    })
  }

  getFunctionService(functionString: string): boolean {
    return this.caiOpeningService.isAuthorizedForClaimFeature$(functionString);
  }
}

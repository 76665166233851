import {
    PNC_POSTSALES_OPERATIONS_TYPES,
    PncDomainService,
    PncPsalesConfirmationStateManager,
    PncPsalesRequiredDataStateManagerDates,
    PncPsalesSummaryStateManagerVariations,
    PostSalesOperation,
    RGI_PNC_POSTSALES_STEPS
  } from '@rgi/pnc-postsales';
  import {API_PREFIX_MOTOR} from '../resources/constants/http';
  import {MOTOR_POSTSALES_OPERATIONS_CODE} from '../resources/constants/motor-operations';

  // Estensione Carte Verde - Green Card Extension
  export const MOTOR_PSALES_ESCIS: PostSalesOperation = {
    type: PNC_POSTSALES_OPERATIONS_TYPES.ASSETS_DATA_AMENDMENTS,
    operationCode: MOTOR_POSTSALES_OPERATIONS_CODE.ISSUE_GREEN_CARD,
    sessionTitle: '_MOTORPSALES_._SESSION_TITLE_._ISSUE_GREEN_CARD_',
    steps: [
      {
        step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
        manager: PncPsalesRequiredDataStateManagerDates,
        deps: [PncDomainService],
        stepLabel: '_PCPSALES_._STEPS_._VARIATION_DATES_',
        context: {apiPrefix: API_PREFIX_MOTOR}
      },
      {
        step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
        manager: PncPsalesSummaryStateManagerVariations,
        deps: [PncDomainService],
        stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
        context: {apiPrefix: API_PREFIX_MOTOR}
      },
      {
        step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
        manager: PncPsalesConfirmationStateManager,
        deps: [],
        stepLabel: ''
      }
    ]
  };

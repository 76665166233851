<div *ngIf="variables.length > 0 || clauses.length > 0">
  <div class="rgi-re-section-title">
    <span class="rgi-re-header-icon rgi-ui-icon-list-type"></span>
    <span class="rgi-ui-title-1 rgi-re-title"
          translate>RE_ISSUE.PRE_QUOTATION_CLAUSES_AND_FACTORS</span>
  </div>

  <re-issue-variables [variables]="variables"
                      (variablesFormChangeEmitter)="onVariablesChange($event)"
                      (validFormVariables)="onValidFormVariables($event)">
  </re-issue-variables>

  <re-issue-clauses [showTitle]="false"
                    [clausesList]="clauses"
                    (updateClause)="onClausesChange($event)">
  </re-issue-clauses>
</div>

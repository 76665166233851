import { Injectable } from '@angular/core';
import { ApiEnumType } from '../model/api/api-enum-type';
import { EnumType } from '../dto/enum-type';

@Injectable({
  providedIn: 'root'
})
export class ApiTranslatorService {

  apiEnumTypeToDtoEnumType(apiEnumType: ApiEnumType): EnumType {
    const enumType = new EnumType();
    enumType.codice = apiEnumType.codice;
    enumType.descrizione = apiEnumType.descrizione;
    return enumType;
  }

  constructor() { }
}

import { ApiParty } from './api-party';

export class ApiClaim {
    public claimParties: ApiParty[];
    public taxBranches: string[];
    public claimNumber: string;
    public reportNumber: string;
    public idClaim: number;
    public aniaCompanyCode: string;
    public policyNumber: string;
    public policyType: string;
    public idPotentialClaim: number;
    public exercise: number;
    public agencyCode: string;

    public getInvolvedPartyFromId(involvedPartyId: number) {
        for (const party of this.claimParties) {

            if (party.identifier === involvedPartyId.toString()) {
                if (party.subject.tipoSoggetto.codice === '1') {
                    // physical
                    return party.subject.givenName + ' ' + party.subject.surname;
                } else {
                    // juridical
                    return party.subject.designation;
                }
            }
        }
        return null;
    }

    public getTypeOfLossFromId(typeOfLossId: number) {
        for (const party of this.claimParties) {
            for (const typeOfLoss of party.damageDescriptions) {
                if (typeOfLoss.damagesDescriptionId === typeOfLossId.toString()) {
                    if (this.isRCA()) {
                        return typeOfLoss.assetType;
                    } else {
                        return typeOfLoss.causative;
                    }
                }
            }
        }
        return null;
    }

    public isRCA() {
        return this.taxBranches && this.taxBranches.indexOf('000010') > -1;
    }

}

import {Component, EventEmitter, Injector, Input, OnChanges, OnInit, Optional, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {QuestionnaireFlatI} from '@rgi/ng-passpro';
import {QuestionnaireEditComponent} from './questionnaire-edit/questionnaire-edit.component';
import {QuestionnairesManagerService} from './services/questionnaires-manager.service';
import {DatePipe} from '@angular/common';
import {SurveyVersionService} from './services/survey-version.service';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService, PassproproAuthService} from '@rgi/ng-passpropro-core';
import {SurveyService} from '@rgi/ng-passpropro-survey';
import { QuestionnaireService } from '@rgi/ng-passpropro/questionnaire';
import { QuestionnaireCacheService } from './services/questionnaires-manager-cache.service';
import { Questionnaire } from './model/questionnaire.model';

export function questServiceProvider(authService, questService, cache, datePipe, surveyService, surveyVersion, httpClient, env) {
  return new QuestionnairesManagerService(authService, questService, cache, datePipe, surveyService, surveyVersion, httpClient, env);
}

@Component({
  selector: 'lib-questionnaires-manager',
  templateUrl: './questionnaires-manager.component.html',
  styles: ['.tnone { -webkit-transform: none; transform: none; }'],
  providers: [
    {provide: QuestionnairesManagerService,
      useFactory: questServiceProvider,
      deps: [PassproproAuthService, QuestionnaireService, QuestionnaireCacheService, DatePipe, SurveyService, SurveyVersionService,
        HttpClient, EnvironmentService]}
  ]
})
export class QuestionnairesManagerComponent implements OnInit, OnChanges {
  modalRef: NgbModalRef;
  questList: Array<Questionnaire> = new Array<Questionnaire>();
  @Input('p') productCode;
  @Input('q') questType;
  @Input('key') key;
  @Input('d') date;
  @Input('modalClass') modalClassToAdd = 'lib-qm';
  @Input('productStructure') productStructure;
  @Input('dq') disabledQuest: any[];
  @Input('readOnlyCategories') readOnlyCategories: string[];
  @Input('savedIds') savedIds: string[];
  @Input('customQuestLoader') customQuestLoader: string;
  @Input('questionnairesCode') questionnairesCode: Map<string, boolean>;
  @Output() foundQuestionnaires = new EventEmitter<boolean>();
  @Output() successMessage = new EventEmitter<string>();
  @Output() listLoad = new EventEmitter<void>();
  @Output() loaderQuest = new EventEmitter<string>();
  @Output() selectQuestOnEdit = new EventEmitter<string>();

  loader = false;

  // public questionnaireManagerService: QuestionnairesManagerService;

  @Input('f') set factors(factorVector: any[]) {
    if (factorVector != null && factorVector.length > 0) {
      this.questionnaireManagerService.factors = factorVector;
    } else {
      this.questionnaireManagerService.factors = [];
    }
  }

  @Input('sq') set answPrevalBySecs(list: any[]) {
    if (list != null && list.length > 0) {
      this.questionnaireManagerService.setDefaultAnswers(list);
    } else {
      this.questionnaireManagerService.setDefaultAnswers([]);
    }
  }

  constructor(
    @Optional() public questionnaireManagerService: QuestionnairesManagerService,
    private injector: Injector,
    private modalService: NgbModal) {  }

  ngOnInit(): void {
    console.debug('QuestionnairesManagerComponent - ngOnInit');
    if (this.questionnaireManagerService) {
      console.debug('QuestionnairesManagerComponent - ngOnInit - QuestionnairesManagerService implementation found');
      this.questionnaireManagerService.key = this.key;
      this.questionnaireManagerService.productCode = this.productCode;
      this.questionnaireManagerService.questType = this.questType;
      // Delete this cast when this inclusion formula managemnet wil be relocated on ng-passpropro-survey lib
      this.questionnaireManagerService.productStructure = this.productStructure;
      if (this.date) {
        this.questionnaireManagerService.date = this.date;
      }

      if (this.customQuestLoader) {
        this.questionnaireManagerService.customQuestLoader = this.customQuestLoader;
      }

      if (this.questionnairesCode) {
        console.debug(`QuestionnairesManagerComponent on init codes: ${JSON.stringify(this.questionnairesCode)}`);
        this.questionnaireManagerService.questionnairesCode = this.questionnairesCode;
      }
      // when the list chage => redrw the questionnaire
      this.questionnaireManagerService.listChange.subscribe(list => {
        console.debug('QuestionnairesManagerComponent - ngOnInit - questionnaireManagerService.listChange.subscribed');
        this.questList = list;
        this.listLoad.emit();
      });

      this.questionnaireManagerService.versionQuest.subscribe(d => {
        this.openModal(d.quest, d.version);
        this.loaderQuest.emit('stopLoader');
      });
      this.questList = this.questionnaireManagerService.getQuestionnaires();

      this.loader = true;
      this.questionnaireManagerService.init(this.savedIds).subscribe(d => {
        this.loader = false;
      }, (_ => {
        this.loader = false;
      }));
    }
  }

  ngOnChanges() {
    if (this.questionnaireManagerService) {
      this.questionnaireManagerService.foundQuestionnaires.subscribe(bol => {
        this.foundQuestionnaires.emit(bol);
      });
    }
  }

  getQuest(): Array<Questionnaire> {
    return this.questList;
  }

  openModal(quest: Questionnaire, questFlat: QuestionnaireFlatI): void {
    if (this.questionnaireManagerService) {
      this.modalRef = this.modalService.open(QuestionnaireEditComponent, {
        centered: true,
        windowClass: 'in tnone ' + this.modalClassToAdd,
        backdropClass: 'light-blue-backdrop in'
      });
      this.modalRef.componentInstance.key = this.key;
      this.modalRef.componentInstance.disabledQuest = this.disabledQuest;
      this.modalRef.componentInstance.quest = quest;
      this.modalRef.componentInstance.questFlat = questFlat;

      // all'apertura del questionario comunico alla modale se il questionario è da disabilitare
      if (!!this.readOnlyCategories && this.readOnlyCategories.length > 0) {
        const findQuest = this.readOnlyCategories.find(q => q === quest.questionnaireType.code);
        this.modalRef.componentInstance.readOnly = !!findQuest;
      }

      this.modalRef.componentInstance.saving.subscribe(d => {
        this.loaderQuest.emit('startLoader');
        this.successMessage.emit(this.modalRef.componentInstance.successMessage);
        this.questionnaireManagerService.updateQuestionnaire(d.quest, d.version, true);
        this.loaderQuest.emit('stopLoader');
        this.modalRef.componentInstance.close();
      });
    }
  }

  loadQuestionnaires(productCode?: string, questType?: string, effectiveDate?: any, key?: string): void {
    if (this.questionnaireManagerService) {
      this.questionnaireManagerService.loadQuestionnaires(productCode, questType, effectiveDate, key);
    }
  }

  loadQuestionnairesByCode(codes?: Map<string, boolean>): void {
    if (this.questionnaireManagerService) {
      this.questionnaireManagerService.loadQuestionnaireByCode(codes);
    }
  }

  getSelectedQuest(event: string) {
    this.loaderQuest.emit(event);
  }

  clearQuestionnaireByKey(key) {
    const allQuest = this.getQuest();
    allQuest.forEach((q) => {
      if (q.code === key) {
        this.questionnaireManagerService.clearQuestionnaire(q);
      }
    });
  }

  clearQuestionnaireByType(type) {
    const allQuest = this.getQuest();
    allQuest.forEach((q) => {
      if (q.questionnaireType.code === type) {
        this.questionnaireManagerService.clearQuestionnaire(q);
      }
    });
  }

  isQuestCompiled(key) {
    const allQuest = this.getQuest();
    const find = allQuest.find((q) => q.code === key && !!q.compiled);
    return !!find;
  }

  isQuestCompiledByType(type) {
    const allQuest = this.getQuest();
    const find = allQuest.find((q) => q.questionnaireType.code === type && !!q.compiled);
    return !!find;
  }

  // emette un evento che contiene il questionario da aprire
  selectedQuest(event) {
    this.selectQuestOnEdit.emit(event);
  }

}


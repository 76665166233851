import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Settlement } from '../models/settlement.model';
import { EnviromentsService } from './enviroments.service';

@Injectable({
    providedIn: 'root'
})
export class ReinvestementService {
    protected baseApiUrl: string;
    constructor(
        protected http: HttpClient,
        protected environment: EnviromentsService,
    ) {
        this.baseApiUrl = environment.baseApiURL;
    }

    getConfig(): Observable<any> {
        return this.http.get(this.baseApiUrl + '/v2/life/reinvestmentConfig');
    }

    getUnfilteredSettlements(idCompany, idBenefic): Observable<any> {
        return this.http.post(this.baseApiUrl + '/v2/life/settlements/search', {
            company: idCompany,
            settlementState: ['1', '2'],
            idBeneficiary: idBenefic
        });
    }

    getSettlements(idCompany, idBeneficiary, idContract: number): Observable<Settlement[]> {
        return forkJoin([
            this.getConfig(),
            this.getUnfilteredSettlements(idCompany, idBeneficiary)
        ]).pipe(map(resultList => {
            const config = resultList[0].reinvestmentConfig;
            const data = resultList[1];
            const result: Settlement[] = [];

            data.forEach(item => {
                if (config[item.productCode] && item.netRemaining > 0 ||
                    (item.reinvestmentDetails.originPolicyId && parseInt(item.reinvestmentDetails.originPolicyId, 10) === idContract)) {
                    if (config[item.productCode].includes(item.operationCode)) {
                        result.push({
                            id: item.idSetBenef,
                            policyNumber: item.policyNumber,
                            type: item.type.abbreviation,
                            beneficiary: item.beneficiary.name,
                            state: item.state.description,
                            import: item.netRemaining ? item.netRemaining : item.amount,
                            select: false,
                            reinvest: null,
                            idReinvestedPolicy: item.reinvestmentDetails.originPolicyId
                        });
                    }
                }
            });

            return result.sort((a, b) => +a.policyNumber - +b.policyNumber);
        }));
    }
}

import {IssueProposalResponse} from './../../models/pc-portfolio-models/proposal-models/issue-proposal-response';
import {MeansOfPaymentConfigInput} from './../../models/means-of-payment-config-input';
import {PolicyTechnicalData} from './../../models/domain-models/parameters/policy-technical-data';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  ReIssueProposalManagementState
} from '../re-issue-state-manager/re-issue-proposal-management-state-manager.service';
import {map, mergeMap} from 'rxjs/operators';
import {ReIssueTechnicalDataResourceService} from '../re-issue-resources/re-issue-technical-data-resource.service';
import {
  ReIssueProposalManagementResourceService
} from '../re-issue-resources/re-issue-proposal-management-resource.service';
import {ReIssueWarningsResourceService} from '../re-issue-resources/re-issue-warnings-resource.service';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {PolicyContacts} from '../../models/domain-models/policy-contacts';
import {BankAccountDetailsRequest} from '../../models/bank-account-details-request';
import {ReIssueQuotationResourceService} from '../re-issue-resources/re-issue-quotation-resource.service';
import {ConditionFlatIResponse} from '../../models/domain-models/condition-flatI-response';
import {ConditionFlatI} from '../../models/domain-models/condition-flatI';
import {QuestionFlatI} from '../../models/domain-models/question-flat';
import {QuestionFlatIResponse} from '../../models/domain-models/question-flat-response';
import {AnswerFlatIResponse} from '../../models/domain-models/answer-flatI-response';
import {AnswerFlatI} from '../../models/domain-models/answer-flat-i';
import {QuestionnaireFlatIResponse} from '../../models/domain-models/questionnaires-flati-response';
import {QuestionnaireFlatI} from '../../models/domain-models/questionnaire-flatI';
import {
  ReIssuePortfolioProposalService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal.service';
import {PaymentDataRequest} from '../../models/pc-portfolio-models/proposal-models/PaymentDataRequest';
// tslint:disable-next-line:max-line-length
import {
  ReIssuePortfolioProposalManagementResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal-management-resource.service';
// tslint:disable-next-line:max-line-length
import {
  ReIssuePortfolioWarningsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-warnings-resource.service';
// tslint:disable-next-line:max-line-length
import {
  ReIssuePortfolioQuestionnairesResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-questionnaires-resource.service';
import {QuestionFlateResponse} from '../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {
  ReIssuePortfolioContactResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-contact-resource.service';

@Injectable({
  providedIn: 'root'
})
export class ReIssueProposalManagementBusinessService<T extends ReIssueProposalManagementState> {

  private readonly WARNING_LEVEL = 'PROPOSAL_MANAGEMENT';

  constructor(
    protected technicalDataResourceService: ReIssueTechnicalDataResourceService,
    protected proposalManagementResourceService: ReIssueProposalManagementResourceService<T>,
    protected portfolioProposalService: ReIssuePortfolioProposalService,
    protected warningsResourceService: ReIssueWarningsResourceService,
    protected quotationResourceService: ReIssueQuotationResourceService,
    protected proposalManagementService: ReIssuePortfolioProposalManagementResourceService,
    protected portfolioWarningsResourceService: ReIssuePortfolioWarningsResourceService,
    protected questionaireResourceService: ReIssuePortfolioQuestionnairesResourceService,
    protected portfolioContactResourceService: ReIssuePortfolioContactResourceService
  ) {
  }

  putTechnicalData$(state: T, policyTechnicalData: PolicyTechnicalData): Observable<T> {
    if (policyTechnicalData.paymentData) {
      policyTechnicalData.paymentData.forEach(element => {
        element.paymentFields.forEach(el => {
          if (!el.value) {
            el.value = '';
          }
        });
      });
    }
    return this.technicalDataResourceService.put(state.resourceId, policyTechnicalData).pipe(
      mergeMap(techData => {
        console.log('Technical Data On Put', techData);
        state.policyTechnicalData = techData;
        return this.postWarnings(state);
      })
    );
    //     return this.warningsResourceService.get(state.resourceId);
    //   }),
    //   map(ws => {
    //     state.warnings = ws;
    //     return state;
    //   })
    // );
  }

  postWarnings(state: T) {
    return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.WARNING_LEVEL).pipe(
      map(warnings => {
        state.warnings = warnings;
        return state;
      })
    );
  }

  getWarnings(state: T) {
    return this.warningsResourceService.get(state.resourceId).pipe(
      map(ws => {
        state.warnings = ws;
        return state;
      })
    );
  }

  getTechnicalData$(state: T): Observable<T> {
    return this.technicalDataResourceService.get(state.resourceId).pipe(
      map(techData => {
        console.log('Dati Tecnici', techData);
        state.policyTechnicalData = techData;
        return state;
      })
    );
  }

  getQuestionaires$(state: T, isPersonaGiuridica: boolean): Observable<Array<QuestionFlateResponse>> {
    return this.questionaireResourceService.getQuestionnaires(state.resourceId).pipe(map(val => {
      const res = val;
      return res;
    }));
  }

  convertToQuestionnaireFlatI(
    questionnaire: QuestionnaireFlatIResponse
  ) {
    const qst: QuestionnaireFlatI = new QuestionnaireFlatI();

    qst.category = questionnaire.category;
    qst.code = questionnaire.code;
    qst.codeType = questionnaire.codeType;
    qst.codeVersion = questionnaire.codeVersion;
    qst.compulsory = questionnaire.compulsory;
    qst.description = questionnaire.description;
    qst.descriptionType = questionnaire.descriptionType;
    qst.included = questionnaire.included;
    qst.openDate = questionnaire.openDate;
    if (questionnaire.questionList) {
      qst.questions = this.getQuestions(
        questionnaire.questionList
      );
    }
    qst.score = questionnaire.score;

    return qst;
  }

  getQuestions(
    questionList: QuestionFlatIResponse[]
  ): QuestionFlatI[] {
    const questions: Array<QuestionFlatI> = new Array<QuestionFlatI>();

    questionList.forEach(question => {
      const qs: QuestionFlatI = new QuestionFlatI();
      if (question.answerList.length > 0) {
        qs.status = question.status;
      } else {
        qs.status = 2;
      }
      qs.answers = this.getAnswers(
        question.answerList
      );
      qs.code = question.code;
      qs.compulsory = question.compulsory;
      if (question.conditionList) {
        qs.conditions = this.getConditions(question.conditionList);
      }
      qs.description = question.description;
      qs.hasNotes = question.hasNotes;
      qs.id = question.id;
      qs.order = question.order;
      qs.section = question.section;
      qs.text = question.text;
      qs.visible = question.visible;
      questions.push(qs);
    });
    return questions;
  }

  getConditions(conditionList: ConditionFlatIResponse[]): ConditionFlatI[] {
    const conditions: Array<ConditionFlatI> = new Array<ConditionFlatI>();
    conditionList.forEach(condition => {
      const cond: ConditionFlatI = new ConditionFlatI();
      cond.status = condition.status;
      cond.terms = condition.termList;
      conditions.push(cond);
    });
    return conditions;
  }

  getAnswers(
    answerList: AnswerFlatIResponse[]
  ): AnswerFlatI[] {
    const answers: Array<AnswerFlatI> = new Array<AnswerFlatI>();

    answerList.forEach(answer => {
      const asw: AnswerFlatI = new AnswerFlatI();
      asw.defaultValue = answer.defaultValue;
      asw.factorCode = answer.factorCode;
      asw.factorDescr = answer.factorDescr;
      asw.factorFormat = answer.factorFormat;
      asw.factorType = answer.factorType;
      asw.formatter = answer.formatter;
      asw.notes = answer.notes;
      asw.paramType = answer.paramType;
      asw.value = answer.value ? '' + parseInt(answer.value, 10) : answer.value;
      asw.values = answer.valueList;
      answers.push(asw);
    });
    return answers;
  }


  meansOfPaymentPost$(state: T, configInput: MeansOfPaymentConfigInput): Observable<T> {
    return this.proposalManagementResourceService.meansOfPaymentPost(configInput).pipe(
      map(res => {
        state.meansOfPayment = res;
        return state;
      })
    );
  }

  policyPost(state: T): Observable<T> {
    return this.proposalManagementResourceService.policyPost(state.resourceId).pipe(
      map(res => {
        state.policyNumber = res;
        return state;
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  putContacts$(st: T, addressList: AddressApiContacts[], mainAddress: AddressApiContacts, mail: any, postalMailChecked: boolean): Observable<any> {
    return this.proposalManagementResourceService.putContacts$(st, addressList, mainAddress, mail, postalMailChecked).pipe(
      map(res => {
        return res;
      })
    );
  }

  getContacts$(st: T): Observable<PolicyContacts> {
    return this.proposalManagementResourceService.getContacts$(st);
  }

  validateBankAccountDetails(st: T, bankAccountDetailsRequest: BankAccountDetailsRequest): Observable<T> {
    return this.proposalManagementResourceService.validateBankAccountDetails(bankAccountDetailsRequest).pipe(
      map(res => {
          st.accountDetailsResponse = res;
          // if (!st.accountDetailsResponse.cin){
          //   st.accountDetailsResponse.cin = "";
          // }
          return st;
        }
      )
    );
  }

  putPolicyContact(state: T): any {
    throw new Error('Method not implemented.');
  }

  issueProposalWithPayment(state: T, paymentDataRequest: PaymentDataRequest): Observable<T> {
    return this.proposalManagementService.putPayments(state.resourceId, paymentDataRequest).pipe(
      mergeMap(payments => {
        return this.portfolioProposalService.issueProposal(state.resourceId, this.constructIssueProposalRequest()).pipe(
          map((issueProposalResponse: IssueProposalResponse) => {
            state.pcPolicy = issueProposalResponse.policy;
            return state;
          })
        );
      })
    );
  }

  issueProposal(state: T): Observable<T> {
    return this.portfolioProposalService.issueProposal(state.resourceId, this.constructIssueProposalRequest()).pipe(
      map((issueProposalResponse: IssueProposalResponse) => {
        state.pcPolicy = issueProposalResponse.policy;
        return state;
      })
    );
  }

  // TODO: CONSTRUCT BODY REQUEST
  constructIssueProposalRequest(): any {
    return {};
  }

  updateContact$(
    state: T,
    variable: any
  ): Observable<T> {
    return this.portfolioContactResourceService.putPolicyContact$(state.resourceId, variable.mainAddress, variable.mail, variable.channelType)
      .pipe(
        map(contact => {
          state.contact = contact;
          return state;
        }));
  }
}

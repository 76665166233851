<ng-container [formGroup]="variationForm" *ngIf="stateVcontVariation">
  <rgi-gp-stepper (targetRoute)="callActionBack($event)" [steps]="stateVcontVariation.steps" [vertical]="vertical"
    [showIndex]="showIndex" [labels]="stateVcontVariation.labels">
  </rgi-gp-stepper>

  <div style="width: 50%; flex-direction: column">
    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-users"></span>
      <b translate>_GP_._TITLE_._SUBJECTS_</b>
    </div>
    <div formGroupName="rolesPartyForm">
      <ng-container *ngFor="let role of stateVcontVariation.allRoleParties | keyvalue">
        <div *ngFor="let roleValue of role.value; index as i"
          [ngClass]="{'rgi-gp-flex rgi-gp-fl-align-center': roleValue.showPercentage}">

          <rgi-rx-drop-container *ngIf="variationForm.get('rolesPartyForm').get(roleValue.role + '_' + i)"
            formControlName="{{roleValue.role + '_' + i}}"
            label="{{roleValue === role.value[0] ? roleValue.description : ''}}" [select]="'searchOrCreateSubject'"
            viewField="nominative" [selectData]="getSubjectDropContainerData(role.key)"
            [attr.data-qa]="'btn_add_role_' + role.key" (onValueChange)="onPartySelected($event, role.key)"
            [ngClass]="{'rgi-gp-flex-70': (roleValue.idParty && roleValue.showPercentage) || (renderSubcauses && +role.key === 1),
                                 'rgi-gp-w-100': !(roleValue.showPercentage && roleValue.idParty) || !(renderSubcauses && +role.key === 1)}">
            <rgi-rx-drag-remove (onRemove)="deleteSubject(role.key, roleValue.idParty)"></rgi-rx-drag-remove>
          </rgi-rx-drop-container>

          <rgi-rx-form-field class="rgi-gp-flex-30"
            *ngIf="variationForm.get('rolesPartyForm').get(roleValue.role + '_' + i) && roleValue.idParty && roleValue.showPercentage">
            <label rgiRxLabel></label>
            <input type="number" rgiRxInput formControlName="{{roleValue.role}}_{{roleValue.idParty}}_percentage"
              [attr.data-qa]="'linked_' + roleValue.role + '_' + roleValue.idParty + '_percentage'">
          </rgi-rx-form-field>

          <rgi-rx-form-field class="rgi-gp-flex-30" *ngIf="renderSubcauses && +role.key === 1">
            <label rgiRxLabel>
              <span translate>_GP_._LABEL_._SUBCAUSES_</span>
              <span class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*</span>
            </label>
            <select rgiRxNativeSelect attr.data-qa="subcause" (change)="onChangeSubcause()" class="rgi-ui-form-control"
              formControlName="subcause">
              <option value=""></option>
              <option *ngFor="let subcause of stateVcontVariation.subcauses" [value]="subcause.code">
                {{subcause.description}}
              </option>
            </select>
            <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
          </rgi-rx-form-field>

          <ng-container *ngFor="let linkedRole of roleValue.linkedRoles | keyvalue">
            <div *ngFor="let linkedRoleValue of linkedRole.value; index as j"
              [ngClass]="{'rgi-gp-flex': linkedRoleValue.showPercentage}">

              <rgi-rx-drop-container *ngIf="variationForm.get('rolesPartyForm').get(linkedRoleValue.role + '_' + j)"
                formControlName="{{roleValue.role + '_' + linkedRoleValue.role + '_' + j}}"
                label="{{linkedRoleValue === linkedRole.value[0] ? linkedRoleValue.description : ''}}"
                [select]="'searchOrCreateSubject'" viewField="nominative"
                [selectData]="getSubjectDropContainerData(role.key)" [attr.data-qa]="'btn_add_role_' + linkedRole.key"
                (onValueChange)="onPartySelected($event, linkedRole.key)" [ngClass]="{'rgi-gp-flex-70': linkedRoleValue.showPercentage,
                                      'rgi-w-100-perc': !linkedRoleValue.showPercentage || !linkedRoleValue.idParty}">
                <rgi-rx-drag-remove (onRemove)="deleteSubject(linkedRole.key, linkedRoleValue.idParty)">
                </rgi-rx-drag-remove>

              </rgi-rx-drop-container>

              <rgi-rx-form-field class="rgi-gp-flex-30"
                *ngIf="linkedRoleValue.idParty && linkedRoleValue.showPercentage">
                <label rgiRxLabel></label>
                <input type="number" rgiRxInput
                  formControlName="linked_{{linkedRoleValue.role}}_{{linkedRoleValue.idParty}}_percentage"
                  [attr.data-qa]="'linked_' + linkedRoleValue.role + '_' + linkedRoleValue.idParty + '_percentage'">
              </rgi-rx-form-field>

            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div data-qa="errorMessage">
      <rgi-gp-group-policy-errors-component *ngIf="!!stateVcontVariation.errors && !!stateVcontVariation.errors.length"
        [errors]="stateVcontVariation.errors" [areaCode]="'ROLES'">
      </rgi-gp-group-policy-errors-component>
    </div>
    <div data-qa="errorMessage">
      <rgi-gp-group-policy-errors-component *ngIf="!!stateVcontVariation.errors && !!stateVcontVariation.errors.length"
        [errors]="stateVcontVariation.errors" [areaCode]="'ROLES_SUBCAUSE'">
      </rgi-gp-group-policy-errors-component>
    </div>

  </div>

  <rgi-gp-payments-section *ngIf="stateVcontVariation.editablePayments"
    [paymentConfig]="stateVcontVariation.editablePayments" [selectablePayments]="stateVcontVariation.selectablePayments"
    [paymentSectionValidCheck]="paymentValidationChecker" (setPaymentMethod)="setPaymentMethod($event)"
    (isSectionValid)="isPaymentOk = $event">
  </rgi-gp-payments-section>

  <div data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="stateVcontVariation.errors"
      *ngIf="!!stateVcontVariation.errors && !!stateVcontVariation.errors.length" [areaCode]="'PAYMENTS'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="stateVcontVariation.errors"
      *ngIf="!!stateVcontVariation.errors && !!stateVcontVariation.errors.length" [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button rgi-rx-button color="secondary" (click)="callActionClose()" data-qa="buttonsClose"
      translate>{{'_GP_._BTN_._CLOSE_'}}
    </button>
    <!-- TODO: Add "variationForm.invalid ||" in case it helps -->
    <button rgi-rx-button color="primary" [disabled]="!stateVcontVariation.allRoleParties[1][0].idParty || !isPaymentOk"
      (click)="callActionGoForword()" translate>{{stateVcontVariation.nextRoute.button}}
    </button>
  </div>

</ng-container>
<div *ngIf="!!onInitEnd" class="example-row" style="padding:30px" id="issue-page">
  <div class="core-header-icon" >
    <span class="header-text">{{titleOfIssue}}</span>
    <span class="pull-right header-text"></span>
  </div>
  <div>
    <div [ngClass]="{'issue-container': !areThereDocuments, 'issue-container-min': areThereDocuments}" >
      <div class="middle-div-message" data-qa="life-number">
        <span>{{label}}</span>
      </div>
      <div *ngIf="!!damageLabel" class="middle-div-message" data-qa="damage-number">
        <span>{{damageLabel}}</span>
      </div>
    </div>

    <lic-document-list
      [documentList]="documentList"
      (actionHandler)="downloadDocument($event)">
    </lic-document-list>

    <div *ngIf="!!otpSectionMessage">
      <div class="core-header-icon" >
        <span class="header-text" translate>_IIAB_._OTP_._TITLE_</span>
        <span class="pull-right header-text"></span>
      </div>
      <div [ngClass]="{'issue-container': !areThereDocuments, 'issue-container-min': areThereDocuments}" >
        <!--<div class="middle-div-message" data-qa="life-number">
          <span>{{label}}</span>
        </div>-->
        <div *ngIf="!!otpSectionMessage" class="middle-div-message">
          <span>{{otpSectionMessage}}</span>
        </div>
      </div>
    </div>

    <div class="btn-group btn-group-justified">
      <div *ngIf="isSubmitAvailable(); else close" style="margin-top: 50px;" class="btn-group" data-qa="policyIssue-button">
        <button (click)="onSubmit()" [disabled]="isError" class="btn btn-warning pull-right text-uppercase" translate>{{buttonLabel}}</button>
      </div>
      <div class="btn-group" data-qa="policyIssue-button" *ngIf="signVisible()">
        <button (click)="sign()" [disabled]="isError || digitalSigned" class="btn btn-warning pull-right text-uppercase" translate>_IIAB_._OTP_._BTN_._SIGN_DOCS_</button>
      </div>
      <ng-template #close>
        <div style="margin-top: 50px;" class="btn-group" data-qa="policyIssue-button">
          <button (click)="onClose()" class="btn btn-warning pull-right text-uppercase" translate>lic_close</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

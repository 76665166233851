import { UntypedFormGroup } from '@angular/forms';
import { Payments } from './meansofpayment.model';

const mappa = new Map();

export const PAYMENT_FIELDS_CODES = {
  CHECK_NUMBER: 'cchecknumber',
  CARD_EXPIRY: 'dcardexpire',
  IBAN: 'ciban',
  HOLDER: 'cholder',
  CREDIT_CARD: 'creditCardNumber',
  CREDIT_CARD_EXPIRY: 'creditCardExpiredDate'
};
export class AdapterPayments {

  cchecknumber: string;
  ciban: string;
  cholder: string;
  idcreditcard: string;
  dcardexpire: string;

  constructor() {
    mappa.set(PAYMENT_FIELDS_CODES.CHECK_NUMBER, 'checkNumber');
    mappa.set(PAYMENT_FIELDS_CODES.CARD_EXPIRY, 'cardExpirationDate');
    mappa.set(PAYMENT_FIELDS_CODES.IBAN, 'iban');
    mappa.set(PAYMENT_FIELDS_CODES.HOLDER, 'bankAccountOwner');
    /*
    mappa.set('cholder', 'creditCardOwner');
    mappa.set('bankCABCode');
    mappa.set('ciniban');
    mappa.set('bankCINCode');
    mappa.set('fiscalCodeOfTheAccountOwner');
    mappa.set('bankABICode');
    mappa.set('bankTransferActivationReasonCode');
    mappa.set('countryCodeIBAN');
    mappa.set('accountNumber');
    */
  }

  getPaymentFieldName(form: UntypedFormGroup, payment: Payments) {
    const arrayPagamenti: Payments[] = [payment];

    Object.keys(form.controls).forEach( (key, value) => {
      if (mappa.get(key)) {
        console.log(key, ' imposta il campo ' + mappa.get(key));
        arrayPagamenti.forEach(p => {

            p[mappa.get(key)] = form.controls[key].value;

        });
      }
    });
    return payment;
  }

  getMeanOfPaymentFieldName(form: UntypedFormGroup, payment: Payments) {
    mappa.forEach((key, value) => {
      if (form.get(value)) {
        form.controls[value].setValue(payment[key]);
        return;
      }
    });
  }
}








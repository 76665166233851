import { InvestmentDataResponse, ToolDataResponse } from './../../lic-karma-funds/model/karma-profile';
import {Fund, Profile} from '../../lic-fund/model/profile';
import {PassProfileData} from '../../lic-fund/model/PassProfileData';
import {PassProfileDefinition} from '../../lic-fund/model/PassProfileDefinition';
import {Factor, PolicyModel} from '../../models/policy.model';
import {FactorCode} from './factor-code.enum';
import {DatePipe} from '@angular/common';
import {KarmaProfileDefinition} from '../../lic-karma-funds/model/karma-profile-definition';
import {KarmaFundDefinition} from '../../lic-karma-funds/model/karma-fund-definition';
import {FUND_TYPE, PassFundDefinition} from '../../lic-fund/model/PassFundDefinition';
import { LicObjectUtils } from '../../utils/lic-object-utils';
import { LicToolUtils } from '../../lic-tools/utils/lic-tool-utils';
import { ToolCode, ToolCodeIdType, operationCodeIdToOperationCode, FACTOR_TOOL_CODE } from '../../enum/life-issue.enum';
import { InboundTools, Tool } from '../../lic-investment-contract/model/tool-definition';

/* eslint-disable max-len */
// @dynamic
export class PassUtils {

  public static mapToPassdata(profiles: any, funds: any, totalAmount: number): Array<PassProfileData> {
    if (!!profiles) {
      return Object.keys(profiles)
        .filter(profileKey => !!profiles[profileKey])
        .map(profileKey => {
          // profiles[profileKey];
          const profilePercent = this.getProfilePercent(profiles, profileKey);
          const profileAmount: number = profilePercent * totalAmount;
          return {
            id: profileKey,
            percentage: profilePercent * 100,
            amount: profileAmount,
            funds: !funds[profileKey] ? [] : Object.keys(funds[profileKey])
              .filter(fundKey => !!funds[profileKey][fundKey])
              .map(fundKey => {
                const fundPercent: number = funds[profileKey][fundKey];
                const fundAmount: number = fundPercent * profileAmount;
                return {
                  id: fundKey,
                  percentage: fundPercent * 100,
                  amount: fundAmount,
                  investmentType: 1
                };
              })
          };
        });
    } else {
      return [];
    }
  }

  public static addFundTypeIdOnRequest(request: PassProfileData[], investmentProfileDefinitions: PassProfileDefinition[]) {
    return request
    .map(profile => {
      const profileDef = investmentProfileDefinitions.find(profDef => profDef.profileId === profile.id);
      return profile.funds.map(fund => {
        if (!!profileDef && !!profileDef.funds) {
          const fundDef = profileDef.funds.find(fDef => fDef.id === fund.id);
          fund.fundTypeId = !!fundDef ? fundDef.fundTypeId : null;
        }
        return fund;
      });
    });
  }

  private static getProfilePercent(profiles: any, profileKey: string) {
    // se profiles è della forma {idProfilo: percentualeProfilo}
    // con percentualeProfilo di tipo stringa, va restituita percentualeProfilo
    // convertita in numero
    if (typeof profiles[profileKey] === 'string') {
      return parseFloat(profiles[profileKey]);
    }
    // altrimenti può essere della forma {idProfilo: { ..., percentage: percentualeProfilo, ...}}
    // in questo caso si restituisce il valore di percentage
    if (!!Object.keys(profiles[profileKey]).length) {
      return profiles[profileKey].percentage / 100;
    } else {
      // altrimenti è {idProfilo: percentualeProfilo}
      // ma con il valore già numero
      return profiles[profileKey];
    }
  }

  public static getAmountFromPercent(percent: number, totalAmount: number): string {
    return ((totalAmount * percent) / 100).toFixed(2);
  }

  public static mapToInboundTools(toolsValue: any, profiles: any, funds: any, totalAmount: number, iban: string, holder: string): any {

    // Una schifezza da sistemare, come più o meno tutto il resto di questo frontend
    let disinvestment = null;
    let toolProfiles = null;
    const tools: any[] = Object.keys(toolsValue).filter(key => !!toolsValue[key]).map((key: ToolCodeIdType) => {
      // extract factors from the form by the tool code
      const factors: any[] = this.evalToolFactors(toolsValue, key);
      // in case of the presence of the factor amount overrides the totalAmount with the amount of the factor
      const factorAmount = factors.find(f => f.code === FACTOR_TOOL_CODE.AMOUNT_CODE);
      totalAmount = !!factorAmount && !!Number(factorAmount.value) ? factorAmount.value : totalAmount;
      // using the iban specified on dati amm
      if (!factors.find(i => i.code === FACTOR_TOOL_CODE.IBAN_CODE)) {
        factors.push({
          code: FACTOR_TOOL_CODE.IBAN_CODE,
          value: iban
        });
      }
      // using the holder of the policy
      factors.push({
        code: FACTOR_TOOL_CODE.HOLDER_CODE,
        value: holder
      });
      if (!!toolsValue[key].disinvestment && !!Object.keys(toolsValue[key].disinvestment).length) {
        disinvestment = this.extractToolsDisinvestmentByKey(toolsValue, key);
        const investmentProfiles = Object.keys(toolsValue[key].investmentProfiles);
        toolProfiles = PassUtils.extractToolProfilesFromForm(investmentProfiles, toolsValue, key, toolProfiles, totalAmount);
      } else {
        if (!!toolsValue[key].defaultFunds) {
          toolProfiles = PassUtils.mapToPassdata(profiles, funds, totalAmount);
        } else {
          toolProfiles = PassUtils.mapToPassdata(
            toolsValue[key].investmentProfiles,
            toolsValue[key].investmentFunds,
            totalAmount
          );
        }
      }
      return {
        operationCodeId: key,
        operationCode: operationCodeIdToOperationCode.get(key),
        factors,
        profiles: toolProfiles,
        disinvestment
      };
    });

    return {
      tools
    };
  }

  /** used on @see mapToInboundTools */
  private static extractToolProfilesFromForm(investmentProfiles: string[], toolsValue: any, key: ToolCodeIdType, toolProfiles: any, totalAmount: number) {
    investmentProfiles.map(profileKey => {
      const toolFunds = toolsValue[key].investmentProfiles[profileKey].funds;
      if (!!toolsValue[key].defaultFunds && key !== ToolCode.PROGRESSIVE_SWITCH) {
        toolProfiles = this.mapProfiles(key, toolsValue);
      } else {
        if (toolFunds.length <= 1) {
          toolProfiles = this.mapProfilesFlat(key, toolsValue);
        } else {
          toolProfiles = PassUtils.mapToPassdata(
            toolsValue[key].investmentProfiles,
            toolsValue[key].investmentFunds,
            totalAmount
          );
        }
      }
    });
    return toolProfiles;
  }

  /** used on @see mapToInboundTools */
  private static evalToolFactors(toolsControlValue, toolId): {code: string, value: string | number}[] {
    const returnObj = Object.keys(toolsControlValue[toolId].factors).map(factorKey => {
      return {
        code: factorKey,
        value: toolsControlValue[toolId].factors[factorKey]
      };
    });
    return returnObj;
  }

  /** used on @see mapToInboundTools */
  private static extractToolsDisinvestmentByKey(toolsValue, key: ToolCodeIdType) {
    const disinvestmentObj = Object.keys(toolsValue[key].disinvestment)
      .map(profileKey => {
        let totalFundAmount = 0;
        const disinvestmentFunds = Object.keys(toolsValue[key].disinvestment[profileKey])
          .filter(fundKey => !!toolsValue[key].disinvestment[profileKey][fundKey])
          .map(fundKey => {
            const fundObj: any = {
              id: fundKey,
              percentage: PassUtils.getDisinvestmentPercentage(toolsValue[key].disinvestment[profileKey][fundKey]),
              investmentType: 1
            };
            if (LicToolUtils.isToolProgressiveSwitch(key)) {
              fundObj.amount = PassUtils.getDisinvestmentAmount(toolsValue[key].disinvestment[profileKey][fundKey]);
              totalFundAmount += fundObj.amount;
            }
            return fundObj;
          }).filter(fund => !!fund.percentage || !!fund.amount);
        return {
          id: profileKey,
          funds: disinvestmentFunds,
          amount: totalFundAmount
        };
    });
    return disinvestmentObj;
  }


  public static getDisinvestmentPercentage(disinvControl: {disinvestmentAmount?: number, percent?: number}): number | null {
    return !!disinvControl.percent ? disinvControl.percent : null;
  }

  public static getDisinvestmentAmount(disinvControl: {disinvestmentAmount?: number, percent?: number}): number | null {
    return !!disinvControl.disinvestmentAmount ? disinvControl.disinvestmentAmount : null;
  }

  public static mapProfilesFlat(key, value) {
    return  Object.keys(value[key].investmentProfiles)
      .map(profileKey => {
        const funds: any[] = (value[key].investmentProfiles[profileKey].funds as any[])
          .map(fund => {
            return {
              id: fund.id,
              percentage: 100,
              investmentType: 1
            };
          });
        return {
          id: profileKey,
          percentage: value[key].investmentProfiles[profileKey].percentage,
          amount: value[key].investmentProfiles[profileKey].amount,
          funds,
        };
      });
  }

  public static mapProfiles(key, value) {
    const profiles = Object.keys(value[key].investmentProfiles)
      .filter(profileKey => value[key].investmentProfiles[profileKey].percentage > 0)
      .map(profileKey => {
        const funds: any[] = (value[key].investmentProfiles[profileKey].funds as any[])
          .filter(fund => fund.percent > 0 || fund.currency > 0)
          .map(fund => {
            return {
              id: fund.id,
              percentage: fund.percent,
              amount: fund.currency,
              investmentType: 1
            };
          });
        return {
          id: profileKey,
          percentage: value[key].investmentProfiles[profileKey].percentage,
          amount: value[key].investmentProfiles[profileKey].amount,
          funds,
        };
      });

    return profiles;
  }

  public static areAllProfilesReadonly(profiles: Array<Profile>): boolean {
    return profiles.every(profile => profile.readOnly);
  }

  public static areAllFundsReadonlyInProfiles(profiles: Array<Profile>): boolean {
    return profiles.every(profile => profile.funds.every(fund => fund.readOnly));
  }

  public static getAmountOf(profile: Profile, investmentTotal: number): string {
    return Math.abs(((profile.percent / 100) * investmentTotal)).toFixed(2);
  }

  public static getProfilesFromDefinitions(profiles: PassProfileDefinition[]): Profile[] {
    if (!!profiles) {
      return profiles.map(profile => ({
        id: profile.id,
        profileId: !!profile.profileId ? profile.profileId : profile.id,
        name: profile.description,
        funds: profile.funds.map(fund => ({
          id: fund.id,
          profileId: !!profile.profileId ? profile.profileId : profile.id,
          name: fund.description,
          percent: 0,
          selected: false,
          currency: 0,
          funds: [],
          isPercent: true,
          minPercentage: parseFloat(fund.minPercentAllocation as string) * 100,
          maxPercentage: parseFloat(fund.maxPercentAllocation as string) * 100,
          readOnly: false
        })),
        selected: false,
        percent: 0,
        currency: 0,
        isPercent: true,
        minPercentage: 0,
        maxPercentage: 100,
        readOnly: false
      }));
    } else {
      return [];
    }
  }

  public static onlyOneElementIn(array: any[]): boolean {
    return Array.isArray(array) && array.length === 1;
  }

  public static getFactorByCodeFrom(factors: Factor[], code: FactorCode | string): Factor | undefined {
    return factors.find(factor => factor.code === code);
  }

  public static getInvestmentTotalFrom(policy: PolicyModel): number {
    let total = 0;
    policy.quote.product.assets.forEach(asset => {
      asset.instances.forEach(instance => {
        instance.sections.forEach(section => {
          section.units.forEach(unit => {
            if (PassUtils.onlyOneElementIn(unit.instances)) {
              const invrFactor: Factor = PassUtils.getFactorByCodeFrom(unit.instances[0].factors, FactorCode.INVRF);
              if (!!invrFactor) {
                total += Number(invrFactor.value);
              }
            }
          });
        });
      });
    });
    return total;
  }

  public static getExpiryDateFrom(policy: PolicyModel): string | undefined {
    let date: string;
    policy.quote.product.assets.forEach(asset => {
      asset.instances.forEach(instance => {
        instance.sections.forEach(section => {
          section.units.forEach(unit => {
            if (PassUtils.onlyOneElementIn(unit.instances)) {
              const expiryDateFactor = PassUtils.getFactorByCodeFrom(unit.instances[0].factors, FactorCode.EXPIRY_DATE);
              if (!!expiryDateFactor && !date) {
                date = expiryDateFactor.value;
              }
            }
          });
        });
      });
    });
    return date;
  }

  public static getIsoDateFromString(date: string): string | null {
    if (!!date) {
      return new DatePipe('en-US').transform(new Date(date), 'yyyy-MM-dd');
    } else {
      return null;
    }
  }

  public static getIsoDateValueFromFactorByCode(factors: Factor[], code: FactorCode | string): string | null {
    const factor: Factor = PassUtils.getFactorByCodeFrom(factors, code);
    if (!!factor) {
      return PassUtils.getIsoDateFromString(factor.value);
    }
    return null;
  }

  public static getNumberValueFromFactorByCode(factors: Factor[], code: FactorCode | string): number | null {
    const factor: Factor = PassUtils.getFactorByCodeFrom(factors, code);
    if (!!factor) {
      return Number(factor.value);
    }
    return null;
  }

  public static getRecalculatedFunds(funds: Fund[], totalAmount: number) {
    if (funds.length === 1) {
      funds[0].percent = 100;
      funds[0].selected = true;
      funds[0].readOnly = true;
    }
    funds.forEach(fund => {
      if (fund.minPercentage === fund.maxPercentage) {
        fund.selected = fund.minPercentage !== 0;
        fund.percent = fund.minPercentage;
        fund.readOnly = true;
        fund.isPercent = true;
      }
      fund.currency = +PassUtils.getAmountFromPercent(fund.percent, totalAmount);
      fund.funds = PassUtils.getRecalculatedFunds(fund.funds, fund.currency);
    });
    return funds;
  }

  public static getKarmaProfileDefinitionFrom(definition: PassProfileDefinition): KarmaProfileDefinition {
    return {
      profileId: definition.profileId,
      description: definition.description,
      id: definition.id,
      minPercentAllocation: 0,
      maxPercentAllocation: 1,
      funds: definition.funds.map(fund => PassUtils.getKarmaFundDefinitionFrom(fund))
    };
  }

  public static getKarmaFundDefinitionFrom(definition: PassFundDefinition): KarmaFundDefinition {
    return {
      id: definition.id,
      description: definition.description,
      minPercentAllocation: Number(definition.minPercentAllocation),
      maxPercentAllocation: Number(definition.maxPercentAllocation)
    };
  }

  public static getKarmaProfileWithGSFund(definition: PassProfileDefinition): PassProfileDefinition {
    // this.investmentProfileDefinitions.map(p => p.funds.filter(f => f.fundTypeId==='4'))
    return {
      profileId: definition.profileId,
      description: definition.description,
      id: definition.id,
      funds: definition.funds.filter(f => f.fundTypeId === FUND_TYPE.GS)
    };
  }

  public static mapInvestmentPropToInvestmentDataResponse(obj: {profiles: PassProfileData[]}): InvestmentDataResponse {
    const profiles = obj.profiles
            .reduce((pProfile, cProfile) => {
              pProfile[cProfile.id] = cProfile.percentage / 100;
              return pProfile;
            }, {});
    const funds = obj.profiles
            .reduce((pProfile, cProfile) => {
              pProfile[cProfile.id] = cProfile.funds.reduce((pFund, cFund) => {
                pFund[cFund.id] = cFund.percentage / 100;
                return pFund;
              }, {});
              return pProfile;
            }, {});
    return {
      funds,
      profiles,
      tools: {}
    };
  }

  /* eslint-disable max-len */
  /**
   * @notUsed
   * @description it returns the merged value between the current form value (intended as the value set post certain logic)
   * @param currentFormValue is the current value of the form
   * @param responseData is the value sent by the service as a string on the extensionData
   */
  public static mergeObjectForAuthFlow(currentValue: PassProfileData[], responseData: InvestmentDataResponse): InvestmentDataResponse {
    const currentFormValue = PassUtils.mapInvestmentPropToInvestmentDataResponse({profiles: currentValue});
    const sameProf = LicObjectUtils.equal(responseData.profiles, currentFormValue.profiles);
    const sameFunds = LicObjectUtils.equal(responseData.funds, currentFormValue.funds);
    if (!sameProf) {
      return !!currentValue.length ? currentFormValue : responseData;
    } else if (!sameFunds) { // the funds are changed
      const extractFundsValues = PassUtils.getFundsArrayWithValue(currentFormValue);
      const profiles = Object.values(currentFormValue.profiles);
      // calculating the total percent of the funds if its < to the length of how many profiles are
      // present in page it means that you have to merge the two object
      // e.g. 2 profiles the fundsTotal must be 2 thats equal to 200% because you have invested correctly 100% for each profile funds
      const fundsTotal = LicObjectUtils.arrSum(extractFundsValues);
      if (fundsTotal < profiles.length) {
        const returnObj = LicObjectUtils.merge(LicObjectUtils.cloneObject(responseData), LicObjectUtils.cloneObject(currentFormValue));
        return returnObj;
      }
      return currentFormValue;
    }
    return responseData;
  }

  /**
   * @notUsed
   * @description returns an array of the investments percentage on the funds
   */
  private static getFundsArrayWithValue(currentFormValue: InvestmentDataResponse) {
    // extracting the funds investment distribution (percentage) from the currentFormValue
    const extractFunds = Object.values(currentFormValue.funds);
    return Object.keys(extractFunds).map(profKey => {
      const fundsPerProfile = extractFunds[profKey];
      const fundsKeys = Object.keys(fundsPerProfile);
      return fundsKeys.map(fkey => {
        const fundsValues = extractFunds[profKey][fkey];
        return fundsValues;
      });
    }).filter(v => v);
  }

  public static convertPropertyExtensionDataTool(inboundTools: InboundTools): {[key: string]: ToolDataResponse} {
    const result  = inboundTools.tools.reduce((g, r) => {
      g[r.operationCodeId] = {};
      g[r.operationCodeId].factors = this.mapToInboundToolFactors(r);
      g[r.operationCodeId].investmentProfiles = this.mapToInboundToolInvestmentProfiles(r);
      g[r.operationCodeId].investmentFunds = this.mapToInboundToolInvestmentFunds(r);
      g[r.operationCodeId].disinvestment = this.mapToInboundToolDisinvestment(r);
      return g;
    }, {});
    return result;
  }

  private static mapToInboundToolInvestmentProfiles(r: Tool): any {
    return r.profiles.reduce((gp, prof) => {
      gp[prof.id] = {
        funds: prof.funds,
        percentage: Number(prof.percent || prof.percentage)
      };
      return gp;
    }, {});
  }

  private static mapToInboundToolInvestmentFunds(r: Tool): any {
    return r.profiles.reduce((gp, prof) => {
      gp[prof.id] = prof.funds.reduce((gf, fund) => {
        gf[fund.id] = Number(fund.percent || fund.percentage) / 100;
        return gf;
      }, {});
      return gp;
    }, {});
  }

  private static mapToInboundToolDisinvestment(r: Tool): any {
    return r.disinvestment.reduce((gp, dis) => {
      gp[dis.id] = dis.funds.reduce((gf, fund) => {
        gf[fund.id] = {
          disinvestmentAmount: fund.amount,
        };
        return gf;
      }, {});
      return gp;
    }, {});
  }

  private static mapToInboundToolFactors(r: Tool): any {
    return r.factors.reduce((gr, fac) => {
      gr[fac.code] = fac.value;
      return gr;
  }, {});
  }
}

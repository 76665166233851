<rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
        {{ (!!this.dataFromFather.idForfait ? '_CLAIMS_._BUTTONS_._EDIT'  : '_CLAIMS_._NEW_FORFAIT') | translate}}
    </rgi-rx-panel-header>

    <form [formGroup]="this.form" (ngSubmit)="onSubmit()">
        <div *ngIf="!(!!this.dataFromFather.idForfait)" style="margin-bottom: 16px;">
            <div class="row">
                <div class="col-md-12">
                    <rgi-rx-form-field style="margin-bottom: 16px;">
                        <label rgiRxLabel translate>{{ '_CLAIMS_._FUNCTION' | translate }}</label>
                        <input rgiRxInput type="text" value="CICOS" disabled required>
                    </rgi-rx-form-field>
                </div>
            </div>
            <div class="row" style="margin-bottom: 16px;">
                <div class="col-md-12">
                    <rgi-rx-form-field>
                        <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._MESSAGE' |
                            translate }}</label>
                        <select rgiRxNativeSelect class="core-select form-control" formControlName="codiceMessaggio"
                            id="message" name="message" attr.data-qa="object-select" required>
                            <option></option>
                            <option *ngFor="let message of this.dialogueFunctions" [value]="message.dialogueMsgCode">
                                {{ message.dialogueMsgCode }} - {{ message.dialogueMsgDescription }}</option>
                        </select>
                    </rgi-rx-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel translate>{{ '_CLAIMS_._VALIDITY_START_DATE' | translate }}</label>
                        <rgi-rx-date-picker>
                            <input [rgiRxDateTimeInput]="startDate" formControlName="dataInizioValidita" required>
                            <rgi-rx-date-time #startDate></rgi-rx-date-time>
                        </rgi-rx-date-picker>
                    </rgi-rx-form-field>
                </div>
                <div class="col-md-6">
                    <rgi-rx-form-field>
                        <label rgiRxLabel translate>{{ '_CLAIMS_._VALIDITY_END_DATE' | translate }}</label>
                        <rgi-rx-date-picker>
                            <input [rgiRxDateTimeInput]="endDate" formControlName="dataFineValidita" required>
                            <rgi-rx-date-time #endDate></rgi-rx-date-time>
                        </rgi-rx-date-picker>
                    </rgi-rx-form-field>
                </div>
            </div>
            <rgi-rx-date-time pickerType="calendar" #auditPicker></rgi-rx-date-time>
        </div>

        <div class="row" style="margin-bottom: 16px;">
            <div class="col-md-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel translate>{{ '_CLAIMS_._IMPORT' | translate }}</label>
                    <input type="numeric" rgiRxInput formControlName="importo" required>
                </rgi-rx-form-field>
            </div>
        </div>


        <div class="row" style="margin-bottom: 16px;">
            <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error"
                attr.data-qa="elaboration-request-search-error">
                <span class="rgifont rgi-exclamation-triangle"></span>
                <span> {{errorMessage}}</span>
            </span>
        </div>

        <rgi-rx-panel-footer>
            <button rgi-rx-button (click)="modalClose.emit()">{{'_CLAIMS_._BUTTONS_._CANCEL' | translate}}</button>
            <button type="submit" rgi-rx-button color="primary">{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
        </rgi-rx-panel-footer>
    </form>
</rgi-rx-panel>

export class NotDrivingDeclaration {
  constructor(
    public visible: boolean,
    public preselected: boolean,
    public selected: boolean,
    public previousValue: boolean,
    public readOnly: boolean
  ) {
  }
}

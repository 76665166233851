import {Inject, Injectable} from '@angular/core';
import {ApiQuote} from './models/api-models/api-quote';
import {HttpParams} from '@angular/common/http';
import {MicHttpService} from './http/mic-http.service';
import {Observable} from 'rxjs';
import {MicHttpDisableErrorInterceptorService} from './http/mic-http-disable-error-interceptor.service';
import {CommonService} from './common.service';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  private baseApiUrl: string;
  private baseApiUrlV2: string;
  private storedAutoSaveQuote: boolean;
  private storedDeliveryOfTransaparencyDocuments: boolean;
  private autoSaveQuoteSystemPropertyKey = 'PortafoglioSalvataggioPreventivoAutomatico';
  private deliveryOfTransaparencyDocuments = 'DeliveryOfTransaparencyDocuments ';

  constructor(protected micHttpClient: MicHttpService,
              protected commonService: CommonService,
              @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';
  }

  saveQuote(contractId: string, quote: ApiQuote) {
    return this.micHttpClient.getHttpClient().post<ApiQuote>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/quote',
      quote);
  }

  transformQuote(quoteId: string, nodeId: string): Observable<any> {
    let httpParams: HttpParams = new HttpParams();
    if (nodeId) {
      httpParams = httpParams.set('nodeId', nodeId);
    }
    const headers = MicHttpDisableErrorInterceptorService.getErrorInterceptorDisable();
    return this.micHttpClient.getHttpClient().get(
      this.baseApiUrlV2 + '/motor/quotes/' + quoteId + '/proposal',
      {params: httpParams, headers}
    );
  }

  async isAutoSaveQuoteEnabled(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.storedAutoSaveQuote) {
        resolve(this.storedAutoSaveQuote);
      }
      this.commonService.getSystemPoperty(this.autoSaveQuoteSystemPropertyKey).pipe(take(1)).subscribe(resp => {
        this.storedAutoSaveQuote = resp.value === '1';
        resolve(this.storedAutoSaveQuote);
      }, () => {
        resolve(false);
      });
    });
  }

  async isDeliveryOfTransaparencyDocuments(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.storedDeliveryOfTransaparencyDocuments) {
        resolve(this.storedDeliveryOfTransaparencyDocuments);
      }
      this.commonService.getSystemPoperty(this.deliveryOfTransaparencyDocuments).subscribe(resp => {
        this.storedDeliveryOfTransaparencyDocuments = resp.value === '1';
        resolve(this.storedDeliveryOfTransaparencyDocuments);
      }, () => {
        resolve(false);
      });
    });
  }
}

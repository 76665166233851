<div>
    <form [formGroup]="newPayForm">

        <div class="top-banner">
            <div class="card shadow table-white" *ngIf="docPresent">
                <div *ngFor="let payment of potPayFE.paymentList; let num = index;" class="row">
                    <div class="row" style="font-size: 16px; font-weight: bold; padding-top: 15px;">
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            {{num+1}}.{{ '_CLAIMS_._PAYMENT_TYPE' | translate }}: {{payment.paymentTypeDescr}}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                        {{ '_CLAIMS_._RECEIPT_TYPE' | translate }}: {{payment.receiptTypeDescr}}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <span style="float: right;">{{ '_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate }}:
                                €{{payment.totalAmountNet}}</span>
                        </div>
                    </div>
                    <h5 class="text-info bold" *ngIf="enabled">{{'Select the documents to attach to the payment' |
                        translate }}</h5>
                    <div *ngFor="let aDoc of payment.documentList; let num = indexDoc;" class="row">
                        <div class="col-md-1 col-sm-1" style="padding-top: 5px;">
                            <input type="checkbox" class="btn btn-checkbox" style="padding-left: 50px"
                                name="docSel{{aDoc.documentId}}" id="docSel{{aDoc.documentId}}"
                                formControlName="docSel{{aDoc.documentId}}" *ngIf="enabled">

                            <input type="checkbox" class="btn btn-checkbox" style="padding-left: 50px"
                                name="docSel{{aDoc.documentId}}" id="docSel{{aDoc.documentId}}"
                                formControlName="docSel{{aDoc.documentId}}" *ngIf="!enabled" disabled>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12" style="padding-top: 10px;">
                            <label class="checkbox-label">
                                {{aDoc.documentName | titlecase}}
                            </label>
                        </div>
                        <div class="col-md-1 col-sm-1 col-xs-12">
                            <button type="submit" class="btn btn-warning" (click)="additionalData(aDoc)" *ngIf="aDoc.clauses && enabled">
                            {{ '_CLAIMS_._ADDITIONAL_DATA' | translate }} 
                                    <span class="rgi rgi-pencil"></span>
                            </button>
                        </div>
                        <div class="col-md-1 col-sm-1 col-xs-12" *ngIf="legalPresent && enabled" style="padding-top: 10px;">
                            <label class="checkbox-label">
                            {{ '_CLAIMS_._C__O' | translate }}
                            </label>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="legalPresent && enabled">
                            <select class="core-select form-control" id="legalAt{{aDoc.documentId}}"
                               name="legalAt{{aDoc.documentId}}" formControlName="legalAt{{aDoc.documentId}}">
                            <option></option>
                            <option *ngFor="let lav of legalList" value={{lav.idSubject}}>
                                    {{lav.lastName}} {{lav.name}} - {{formatString(lav.address)}}
                                    {{formatString(lav.number)}} {{formatString(lav.postalCode)}}
                                    {{formatString(lav.municipality)}} ({{formatString(lav.state)}})</option>
                                   
                            </select>
                            <span
                            class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow table-white" *ngIf="!docPresent">
                <div class="row">
                    <h5 class="text-info bold">{{ '_CLAIMS_._NO_DOCUMENTS' | translate }}</h5>
                </div>
            </div>
        </div>




        <div class="modal-footer" *ngIf="enabled">
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button class="btn btn-primary colorBGBlu2" (click)="onConfirm()" data-qa="party-data-panel-confirm-button"
                        >{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</div>
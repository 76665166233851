<div class="questionnaire-page">
  <div class="modal in" tabindex="-1" [ngStyle]="{'display': 'block', 'opacity': 1}" data-qa="questModal">
    <div class="modal-dialog quest-modal">
      <div class="modal-content" style="font-family: DINProMedium">
        <div class="modal-header">
          <div class="app-modal-header">
            <div class="modal-title" translate>
              Questionnaire
            </div>
          </div>
        </div>
        <div id="modalBody" class="modal-body" style="padding-top: 5px">
          <div class="app-modal-body app-modal-quest">
            <ppevo-questionnaire [questionnaireFlat]="questFlat" [disabled]="readOnly" [disabledQuestions]="disabledQuest"></ppevo-questionnaire>
          </div>
          <div *ngIf="showErrors" class="col-sm" data-qa="app-form-message">
            <div *ngFor="let error of errormessages" class="content-error validate-error" role="alert">
              <span _ngcontent-fxl-c10="" class="rgifont message-icon rgi-exclamation-triangle"
                ng-reflect-klass="rgifont message-icon" ng-reflect-ng-class="[object Object]"></span>
                {{error}}
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="app-modal-footer">
            <div class="btn-group btn-group-justified"
              style="margin-top: 0px !important; border: 0px !important; border-right: 0px !important;">
              <div class="btn-group">
                <button class="btn btn-warning btn-secondary" (click)="close()" data-qa='cancel-button' translate>CANCEL</button>
              </div>
              <div class="btn-group">
                <button class="btn btn-warning" (click)="save()" data-qa='confirm-button' ngbAutofocus translate>CONFIRM</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

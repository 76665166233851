import {Inject, Injectable, Optional} from '@angular/core';
import {Observable, of} from "rxjs";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {NG_PASSPROPRO_CONFIG, NgPassProProConfig} from "../core.api";
import {map} from "rxjs/operators";
import {Environment} from "./environment-api";

const DEFAULT_ENV_PATH = "/api/rest/passpropro/environment/json";

export interface EnvironmentProvider {
  environment: Environment;
  environmentUrl: string;
}

@Injectable()
export class EnvironmentService implements EnvironmentProvider {

  private _environment: Environment;
  private httpClient: HttpClient;

  constructor(private httpBackend: HttpBackend, @Inject(NG_PASSPROPRO_CONFIG) @Optional() private ngPassProProConfig: NgPassProProConfig) {
    this.httpClient = new HttpClient(httpBackend);
  }

  init(): Observable<Environment>{
    if (!this.ngPassProProConfig) {
      return this.initDynamic(DEFAULT_ENV_PATH);
    }
    else if(this.ngPassProProConfig.dynamicEnvironmentUrl){
      return this.initDynamic(this.ngPassProProConfig.dynamicEnvironmentUrl);
    }
    this._environment = new Environment(this.ngPassProProConfig.environment.host + this.ngPassProProConfig.environment.path);
    return of(this._environment);
  }

  private initDynamic(url: string) {
    return this.httpClient.get<string>(url)
      .pipe(
        map((res: any) => this._environment = res)
      );
  }

  get environment(): Environment {
    return this._environment;
  }

  get environmentUrl(): string {
    return this._environment.URL;
  }


  set environment(value: Environment) {
    this._environment = value;
  }
}

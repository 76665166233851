import {State} from '@rgi/rx/state';
import {GenericEntity} from '../re-postsale-model/substitution';

export class RePostsalesState extends State { // this is the model of the state
  operation: { code: string, description: string };
  policyNumber: string;
  resourceId: string;
  contract: any;
  isNoteDisabled: boolean;
  // previewVariations: PreviewVariationResponse;
  // commissions: CommissionTable;

  // stepFlow: Step[];
  // datesForm: DatesForm;

  errorMessages: string[] | null;
  isOperationFeasible: boolean;
  note: string;
  company: any;
  modifiedImport: any;
  isModifyProposalEnabled: boolean;
  cancellationProperties: any;
  cancellationReasons: Array<GenericEntity>;
}

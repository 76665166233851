import {AnagApiResponse} from './anag-api-response';
import {AnagIdentificationEntity} from '../anag-domain/anag-identification-entity';

export class AnagApiSubappAdminlevel extends AnagApiResponse {
  public output: Array<LocationConfig>;
}

export class LocationConfig {
  public adminLevel1: AnagIdentificationEntity | null;
  public adminLevel2: AnagIdentificationEntity | null;
  public adminLevel3: AnagIdentificationEntity | null;
  public adminLevel5: AnagIdentificationEntity | null;
  public code: string;
  public description?: string;
  public identification: string | null;

}


export class ExtendedFilter {
  public filterLvl: number;
  public filterValue: string;

  constructor(filterLvl: number, filterValue: string) {
    this.filterLvl = filterLvl;
    this.filterValue = filterValue;
  }
}

<template #documentModalContainerRef></template>

<div class="issue-container-min">
  <div class="middle-div" data-qa="proposalNumber-fromAuthModify-life" translate>
    <span>{{publishMessage}}</span>
  </div>
</div>

<div *ngIf="onInitEnd && areThereDocuments">
  
<div rgi-rx-accordion [multi]="true">
  <rgi-rx-expansion-panel [expanded]="true" color="default">
    <div rgi-rx-expansion-panel-header>
      <span class="header-text white" translate>lpc_print_docs</span>
    </div>
    <div content data-qa="panel-button">
      <div *ngFor="let doc of documentList">
        <div class="document-container">
          <div class="row">
            <div class="col-md-11 col-sm-11">
              <span class="icon rgi-documenti_causali"></span>
              <b class="desc-container">{{doc.nomeFile}}</b>
            </div>
            <div class="col-md-1 col-sm-1">
              <div class="button-container">
                <button type="button" class="btn btn-download"
                    (click)="downloadDocument($event, doc)" [disabled]="false">
                    <span class="icon rgi-download"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </rgi-rx-expansion-panel>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalComponent, ModalService } from '@rgi/rx/ui';
import { LinkedPolicyQuotation } from '../../group-policy-models/group-policy-issue-guarantees';
import {
    GroupPolicyQuotationDetailModalComponent
} from '../group-policy-modals/group-policy-modal-quotation-detail-component/group-policy-modal-quotation-detail.component';
import {
    GroupPolicyQuotationGuaranteeDetailsModalComponent
} from '../group-policy-modals/group-policy-modal-quotation-guarantee-details-component/group-policy-modal-quotation-guarantee-details.component';

@Component({
    selector: 'rgi-gp-group-policy-quotations-component',
    templateUrl: './group-policy-quotations.component.html'
})
export class GroupPolicyQuotationsComponent implements OnInit, OnDestroy {

    @Input() quotations: LinkedPolicyQuotation;
    public quotationGuaranteeDetailsModal: ModalComponent;
    public quotationDetailModal: ModalComponent;
    public showDropDown = false;

    constructor(protected modalService: ModalService) { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    public openQuotationGuaranteeDetailsModal() {
        this.quotationGuaranteeDetailsModal = this.modalService.open(GroupPolicyQuotationGuaranteeDetailsModalComponent,
            { quotationGuaranteeDetails: this.quotations.quotationGuaranteeDetails });
        this.quotationGuaranteeDetailsModal.enableClickBackground = false;
    }

    public openQuotationDetailModal() {
        this.quotationDetailModal = this.modalService.open(GroupPolicyQuotationDetailModalComponent,
            { quotationDetail: this.quotations.quotationDetail });
        this.quotationDetailModal.enableClickBackground = false;
    }

}

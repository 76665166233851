export class TermFlatI {
  questionDescription: string;
  factorCode: string;
  questionCode: string;
  sectionCode: string;
  factorDescription: string;
  sectionDescription: string;
  operatorCode: any;
  type: string;
  value: any;
  operator: number;

  isResult?: boolean;

  static copy(src: TermFlatI): TermFlatI {
    const t = new TermFlatI();
    t.factorCode = src.factorCode;
    t.factorDescription = src.factorDescription;
    t.operator = src.operator;
    t.operatorCode = src.operatorCode;
    t.questionCode = src.questionCode;
    t.questionDescription = src.questionDescription;
    t.sectionCode = src.sectionCode;
    t.sectionDescription = src.sectionDescription;
    t.type = src.type;
    t.value = src.value;

    return t;
  }
}

<h3 class=" title-header">{{ '_CLAIMS_._LIMITS_POLICY_N' | translate}} {{ policyNumber }}</h3><br>
<div class="grid-item card card-height-normal card-main">
  <div *ngFor="let limit of limits">
    <div *ngIf="limit.active">
      <ngb-accordion #accordion [closeOthers]="true">
        <ngb-panel>
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="panel-header" [ngClass]="{'panel-opened': opened}">
              <button ngbPanelToggle class="btn btn-link" style="font-weight: bold; text-decoration: none;"
                (click)="getLimistDetails(limit.id)">
                {{ limit.name | titlecase}}
              </button>
              <label class="lbl-link">
                <label class="lbl-link-label">{{ '_CLAIMS_._LEVEL' | translate }}: </label>
                <label class="lbl-link-value"> {{ limit.level.descrizione }} </label>
                <label class="lbl-link-label"> |{{ '_CLAIMS_._TYPOLOGY' | translate }}: </label>
                <label class="lbl-link-value"> {{ limit.type.descrizione }} </label>
                <label class="lbl-link-label" *ngIf="limit.subtype.codice"> |{{ '_CLAIMS_._SUBTYPOLOGY' | translate }}:
                </label>
                <label class="lbl-link-value" *ngIf="limit.subtype.descrizione"> {{ limit.subtype.descrizione }}
                </label>
                <label class="lbl-link-label" *ngIf="limit.unique"> | </label>
                <label class="lbl-link-value" *ngIf="limit.unique">{{ '_CLAIMS_._UNIQUE' | translate }} </label>
                <label class="lbl-link-label" *ngIf="!limit.active"> | </label>
                <label class="lbl-link-value" *ngIf="!limit.active">{{ '_CLAIMS_._CANCELED' | translate }} </label>
              </label>
              <button class="btn-edit" *ngIf="isAuthorizedForEditLimit && limit.active" (click)="cancelLimit(limit)">
                {{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }} <span class="rgifont rgi-cancel"></span>
              </button>
              <button class="btn-edit" *ngIf="isAuthorizedForEditLimit && limit.active"
                (click)="openLimitModal($event, limit, 'EDIT')">
                {{ '_CLAIMS_._BUTTONS_._EDIT' | translate }} <span class="rgifont rgi-pencil"></span>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <!-- GENERAL DATA (SERVICE 1) -->
            <div class="container-fluid table-white">
              <div class="row">
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._INITIAL_LIMIT_AMOUNT' | translate
                  }}:
                  <span>{{ limit.initialAmount | currency:'EUR':true }}</span>
                </div>
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._RESIDUE_LIMIT_AMOUNT' | translate
                  }}:
                  <span>{{ limit.remainingAmount | currency:'EUR':true }}</span>
                </div>
              </div>
              <div class="row" *ngIf="limit.startValidityDate">
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._VALIDITY_START_DATE' | translate
                  }}:
                  <span>{{ limit.startValidityDate | date: formatValidityDate || '-'}}</span>
                </div>
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._VALIDITY_END_DATE' | translate
                  }}:
                  <span>{{ limit.endValidityDate | date: formatValidityDate || '-'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._NETGROSS_EXCESS' | translate }}:
                  <span *ngIf="limit.grossExcess">{{ '_CLAIMS_._NET' | translate }}</span>
                  <span *ngIf="!limit.grossExcess">{{ '_CLAIMS_._GROSS' | translate }}</span>
                </div>
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._REGIME' | translate }}:
                  <span>{{ limit.regime.descrizione }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._PRODUCT' | translate }}:
                  <span>{{ limit.productDesc }}</span>
                </div>
                <div *ngIf="limit.level.codice !== '1'" class="col-md-6 col-sm-8 col-xs-12 text-padd-right">
                  {{ limit.level.descrizione }}:
                  <span>{{ limit.levelCode.descrizione }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._UNIQUE' | translate }}:
                  <span>{{ limit.unique ? 'Si' : 'No' }}</span>
                </div>
                <div *ngIf="showSubtypologyCode(limit)" class="col-md-6 col-sm-8 col-xs-12 text-padd-right">
                  {{ limit.subtype.descrizione }}:
                  <span>{{ limit.subtypeCode.descrizione }}</span>
                </div>
              </div>
            </div>
            <!-- FINE GENERAL DATA -->
            <!-- TABS DETAILS -->
            <div class="col-xs-2 col-sm-2 col-md-4 col-lg-4"
              style="font-size: 12px; margin-top: 30px; margin-bottom: 20px;">
              <label class="btn rgi-radio-btn btn-primary" [ngClass]="activeDetail"
                (click)="onTabClick('detail', limit.id)">{{ '_CLAIMS_._DETAIL' | translate }}</label>
              <label *ngIf="limit.type.codice !== '1'" class="btn rgi-radio-btn btn-primary" [ngClass]="activeClaims"
                (click)="onTabClick('claims', limit.id)" translate>{{ '_CLAIMS_._CLAIMS' | translate }}</label>
            </div>
            <!-- FINE TABS DETAILS-->
            <!-- TABELLA DETTAGLIO (SERVICE 2) -->
            <div *ngIf="hasResult() && !isClaims()" class="container-fluid policy-table" data-qa="detail-list">
              <table class="table-responsive table coreDT">
                <thead>
                  <tr class="head-result thDataTable">

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="limit-detail-updatetype-label">{{ '_CLAIMS_._UPDATE_TYPE' | translate
                        }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="limit-detail-date-label">{{ '_CLAIMS_._DATE' | translate }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="limit-detail-amount-label">{{ '_CLAIMS_._AMOUNT' | translate }}</span>
                    </td>

                    <td *ngIf="limit.masterPolicy" class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="limit-detail-application-label">{{ '_CLAIMS_._APPLICATION' | translate
                        }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="limit-detail-claim-label">{{ '_CLAIMS_._CLAIM' | translate }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="limit-detail-reasonupdate-label">{{ '_CLAIMS_._UPDATE_REASON' | translate
                        }}</span>
                    </td>

                  </tr>
                </thead>

                <tbody>
                  <tr
                    *ngFor="let detail of limit.limitDetailList; let even = even; let odd = odd; let detailIndex = index"
                    [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
                    attr.data-qa="detail-result-{{detailIndex}}">

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="detail-type-{{detailIndex}}">{{ detail.updateType }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="detail-date-{{detailIndex}}">{{ detail.startDate | date: formatStartDate
                        }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="detail-amount-{{detailIndex}}">{{ detail.amount | currency:'EUR':true
                        }}</span>
                    </td>

                    <td *ngIf="limit.masterPolicy" class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="detail-application-{{detailIndex}}">{{ detail.applicationNumber }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="detail-claim-{{detailIndex}}">{{ detail.claimNumber || '-'}}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="detail-reasonupdate-{{detailIndex}}">{{ detail.updateReason }}</span>
                    </td>

                  </tr>
                </tbody>

              </table>
            </div>
            <!-- FINE TABELLA DETTAGLIO -->
            <!-- TABELLA SINISTRI (SERVICE 3) -->
            <div *ngIf="hasResult() && isClaims()" class="container-fluid policy-table" data-qa="claims-list">
              <table class="table-responsive table coreDT">
                <thead>
                  <tr class="head-result thDataTable">

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="claim-claimnumber-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="claim-reservedamount-label">{{ '_CLAIMS_._RESERVED_AMOUNT' | translate
                        }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="claim-amountcleared-label">{{ '_CLAIMS_._AMOUNT_CLEARED' | translate }}</span>
                    </td>

                    <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                      <span attr.data-qa="claim-technicalstate-label">{{ '_CLAIMS_._TECHNICAL_STATE' | translate
                        }}</span>
                    </td>

                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let claim of limit.limitClaimList; let even = even; let odd = odd; let claimIndex = index"
                    [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
                    attr.data-qa="claim-result-{{claimIndex}}">

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="claim-claimnumber-{{claimIndex}}">{{ claim.claimNumber }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="claim-reservedamount-{{claimIndex}}">{{ claim.reserved | currency:'EUR':true
                        }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="claim-clearedamount-{{claimIndex}}">{{ claim.paid | currency:'EUR':true
                        }}</span>
                    </td>

                    <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <span attr.data-qa="claim-technicalstate-{{claimIndex}}">{{ claim.technicalStatus }}</span>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
            <!-- FINE TABELLA SINISTRI -->
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <h5></h5>
    </div>
  </div>


  <div *ngFor="let limit of limitsCounter">
    <ngb-accordion #accordion [closeOthers]="true">
      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="panel-header" [ngClass]="{'panel-opened': opened}">
            <button ngbPanelToggle class="btn btn-link" style="font-weight: bold; text-decoration: none;"
              (click)="getLimitCounterDetails(limit.id)">
              {{ limit.name | titlecase}}
            </button>
            <label class="lbl-link">
              <label class="lbl-link-label">{{ '_CLAIMS_._LEVEL' | translate }}: </label>
              <label class="lbl-link-value"> {{ limit.level.descrizione }} </label>
              <label class="lbl-link-label"> |{{ '_CLAIMS_._TYPOLOGY' | translate }}: </label>
              <label class="lbl-link-value"> {{ limit.type.descrizione }} </label>
            </label>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <!-- GENERAL DATA (SERVICE 1) -->
          <div class="container-fluid table-white">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._INITIAL_LIMIT_VALUE' | translate
                }}:
                <span>{{ limit.initialAmount }}</span>
              </div>
              <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._RESIDUE_LIMIT_VALUE' | translate
                }}:
                <span>{{ limit.remainingAmount }}</span>
              </div>
            </div>
            <div class="row" *ngIf="limit.startValidityDate">
              <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._VALIDITY_START_DATE' | translate
                }}:
                <span>{{ limit.startValidityDate | date: formatValidityDate || '-'}}</span>
              </div>
              <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._VALIDITY_END_DATE' | translate }}:
                <span>{{ limit.endValidityDate | date: formatValidityDate || '-'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ '_CLAIMS_._PRODUCT' | translate }}:
                <span>{{ limit.productDesc }}</span>
              </div>
              <div class="col-md-6 col-sm-8 col-xs-12 text-padd-right">{{ limit.level.descrizione }}:
                <span>{{ limit.levelCode.descrizione }}</span>
              </div>
            </div>
          </div>
          <!-- FINE GENERAL DATA -->
          <!-- TABS DETAILS -->
          <div class="col-xs-2 col-sm-2 col-md-4 col-lg-4"
            style="font-size: 12px; margin-top: 30px; margin-bottom: 20px;">
            <label class="btn rgi-radio-btn btn-primary" [ngClass]="activeDetail"
              (click)="onTabClick('detail', limit.id)">{{ '_CLAIMS_._DETAIL' | translate }}</label>
            <label *ngIf="limit.type.codice !== '1'" class="btn rgi-radio-btn btn-primary" [ngClass]="activeClaims"
              (click)="onTabCounterClick('claims', limit.id)">{{ '_CLAIMS_._CLAIMS' | translate }}</label>
          </div>
          <!-- FINE TABS DETAILS-->
          <!-- TABELLA DETTAGLIO (SERVICE 2) -->
          <div *ngIf="hasResult() && !isClaims()" class="container-fluid policy-table" data-qa="detail-list">
            <table class="table-responsive table coreDT">
              <thead>
                <tr class="head-result thDataTable">

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="limit-detail-updatetype-label">{{ '_CLAIMS_._UPDATE_TYPE' | translate }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="limit-detail-date-label">{{ '_CLAIMS_._DATE' | translate }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="limit-detail-amount-label">{{ '_CLAIMS_._COUNT' | translate }}</span>
                  </td>

                  <td *ngIf="limit.type.descrizione === 'Polizza Madre'" class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="limit-detail-application-label">{{ '_CLAIMS_._APPLICATION' | translate }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="limit-detail-claim-label">{{ '_CLAIMS_._CLAIM' | translate }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="limit-detail-reasonupdate-label">{{ '_CLAIMS_._UPDATE_REASON' | translate
                      }}</span>
                  </td>

                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="let detail of limit.limitDetailList; let even = even; let odd = odd; let detailIndex = index"
                  [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
                  attr.data-qa="detail-result-{{detailIndex}}">

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="detail-type-{{detailIndex}}">{{ detail.updateType }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="detail-date-{{detailIndex}}">{{ detail.startDate | date: formatStartDate
                      }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="detail-amount-{{detailIndex}}">{{ detail.amount }}</span>
                  </td>

                  <td *ngIf="limit.descrizione === 'Polizza Madre'" class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="detail-application-{{detailIndex}}">{{ detail.applicationNumber }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="detail-claim-{{detailIndex}}">{{ detail.claimNumber || '-'}}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="detail-reasonupdate-{{detailIndex}}">{{ detail.updateReason || '-' }}</span>
                  </td>

                </tr>
              </tbody>

            </table>
          </div>
          <!-- FINE TABELLA DETTAGLIO -->
          <!-- TABELLA SINISTRI (SERVICE 3) -->
          <div *ngIf="hasResult() && isClaims()" class="container-fluid policy-table" data-qa="claims-list">
            <table class="table-responsive table coreDT">
              <thead>
                <tr class="head-result thDataTable">

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="claim-claimnumber-label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="claim-reservedamount-label">{{ '_CLAIMS_._RESERVED_AMOUNT' | translate }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="claim-amountcleared-label">{{ '_CLAIMS_._AMOUNT_CLEARED' | translate }}</span>
                  </td>

                  <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                    <span attr.data-qa="claim-technicalstate-label">{{ '_CLAIMS_._TECHNICAL_STATE' | translate }}</span>
                  </td>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let claim of limit.limitClaimList; let even = even; let odd = odd; let claimIndex = index"
                  [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
                  attr.data-qa="claim-result-{{claimIndex}}">

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="claim-claimnumber-{{claimIndex}}">{{ claim.claimNumber }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="claim-reservedamount-{{claimIndex}}">{{ claim.reserved | currency:'EUR':true
                      }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="claim-clearedamount-{{claimIndex}}">{{ claim.paid | currency:'EUR':true
                      }}</span>
                  </td>

                  <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <span attr.data-qa="claim-technicalstate-{{claimIndex}}">{{ claim.technicalStatus }}</span>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          <!-- FINE TABELLA SINISTRI -->
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <h5></h5>
  </div>
  <!-- BOTTONE NUOVO -->
  <div class="btn-group btn-group-justified" *ngIf="isAuthorizedForNewLimit">
    <div class="btn-group">
      <button class="btn btn-warning text-uppercase" (click)="openLimitModal($event, null, 'NEW')"
        data-qa="limits-management-new-button">{{ '_CLAIMS_._BUTTONS_._NEW' | translate }}</button>
    </div>
  </div>
</div>
import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'lpc-message-modal',
    templateUrl: './lpc-message-modal.component.html',
    styleUrls: ['./lpc-message-modal.component.css'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LpcMessageModalComponent),
        multi: true
      }
    ]
  })
  export class LpcMessageModalComponent {


    @Input() showMessageModalOperation = false;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() confirm: EventEmitter<any> = new EventEmitter<any>();
    @Input() serviceError = false;
    @Input() customMessage: string;
    @Input() title?: string;

    @Input() backLabel = 'lpc_close_button';
    @Input() nextLabel = 'lpc_confirm';

    OnClose() {
        this.close.emit(true);
        this.showMessageModalOperation = false;
    }

    onConfirm(event: any) {
      this.confirm.emit(event);
    }

  }

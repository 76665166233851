import { ActiveRoute, Routes } from "@rgi/rx/router";
import { FIVA_PLATE_CTP_DETAIL } from "./route-constants";
import { FivaPlateCtpDetailComponent } from "./fiva-plate-ctp-detail/fiva-plate-ctp-detail.component";
import { FivaStateManager } from "./state-manager/fiva-state-manager";
import { StateStoreService } from "@rgi/rx/state";
import { FivaService } from "./fiva-service/fiva-service";
import { ModalService } from "@rgi/portal-ng-core";
import { RgiRxTranslationService } from "@rgi/rx/i18n";

export const FIVA_ROUTES: Routes = [
 
    {
        route: FIVA_PLATE_CTP_DETAIL,
        component: FivaPlateCtpDetailComponent,
         providers: [{
          provide: FivaStateManager,
           useClass: FivaStateManager,
          deps: [ActiveRoute, StateStoreService, FivaService, ModalService, RgiRxTranslationService]
        }]
    },]
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardComponentService } from './card-component.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'claims-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() public card: any;
  @Input() public id: any;
  @Input() public targetRoute: any;
  @Input() public extendedCard: boolean;
  @Input() public sessionCard: boolean;
  @Input() public subcard: boolean;
  private reloadCounter: number;

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private cardComponentService?: CardComponentService,
    private sessionService?: SessionService
  ) {
    if (!this.cardComponentService) {
      this.cardComponentService = new CardComponentService();
    }
  }

  ngOnInit() {
    if (this.card && this.card.id) {
      this.cardComponentService.addEventEmitter(this.eventPropagation, this.card.id);
    }
  }

  emitCommunication(event: any) {
    if (this.card && this.card.id) {
      this.cardComponentService.emit(event, this.card.id);
    }
  }

  @Input() set deadsession(deadSessionId: any) {
    if (this.sessionService && deadSessionId) {
      this.sessionService.signalDeadSession(deadSessionId);
    }
  }

  public isMainCard(): boolean {
    return !(typeof this.targetRoute === 'undefined' || this.targetRoute === null);
  }


  @Input() set reload(val: number) {
    if (val) {

      if (!this.reloadCounter || val > this.reloadCounter) {
        console.log('ricevuto evento reload: ', val);
        this.reloadCounter = val;
        this.reloadClaimOrPes();
      }

      // this.eventService.broadcastEvent('reloadClaim');
    }

  }

  reloadClaimOrPes() {}

}

import {Inject, Injectable} from '@angular/core';
import {Variable} from '../../models/domain-models/variable';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {VariablesService} from '../../variables/variables-service';
import {Package} from '../../models/domain-models/package';
import {Unit} from '../../models/domain-models/unit';
import {MicHttpService} from '../../http/mic-http.service';


@Injectable({
  providedIn: 'root'
})
export class PackagesService {

  private baseApiUrlV2;
  private contractEndpoint;
  private packet;
  protected httpClient: HttpClient;


  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    private variableService: VariablesService
  ) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.contractEndpoint = this.baseApiUrlV2 + '/portfolio/contract';

    this.httpClient = this.micHttpClient.getHttpClient();
  }

  toFormGroup(packet: Package): UntypedFormGroup {
    const group: any = {};
    packet.unitList.forEach(unit => {
      group[packet.code + '_' + unit.code] = new UntypedFormControl(unit);
      if (unit && unit.variables) {
        const variablesUnit = unit.variables;
        if (variablesUnit.length === 0) {
          return;
        }

        variablesUnit.sort(
          (a: Variable, b: Variable) => {
            if (a.order > b.order) {
              return 1;
            } else if (a.order < b.order) {
              return -1;
            } else {
              return 0;
            }
          }
        );

        variablesUnit.forEach(variable => {
          const name = packet.code + '_' + unit.code + '_' + unit.warrantyCode + '_' + variable.code;
          group[name] = this.variableService.getVariableFormControl(variable, unit.selected);
        });
      }
    });

    return new UntypedFormGroup(group);
  }

  updateUnitValues(packet: Package, form: UntypedFormGroup): Unit[] {
    packet.unitList.forEach(unit => {
      unit = form.controls[packet.code + '_' + unit.code].value;

      const variablesUnit = unit.variables;
      if (variablesUnit && variablesUnit.length !== 0) {

        variablesUnit.sort(
          (a: Variable, b: Variable) => {
            if (a.order > b.order) {
              return 1;
            } else if (a.order < b.order) {
              return -1;
            } else {
              return 0;
            }
          }
        );

        variablesUnit.forEach(variable => {
          const key = packet.code + '_' + unit.code + '_' + unit.warrantyCode + '_' + variable.code;

          const value = form.controls[key].value;
          variable.value = value != null ? ('' + value).replace('.', ',') : '';
        });
      }
    });
    return packet.unitList;
  }

  updateUnitValuesValidators(packet: Package, form: UntypedFormGroup): Unit[] {
    packet.unitList.forEach(unit => {
      const variablesUnit = unit.variables;

      const keyUnit = packet.code + '_' + unit.code;

      if (!form.controls[keyUnit]) {
        form.addControl(keyUnit, new UntypedFormControl(unit));
      }

      if (variablesUnit && variablesUnit.length !== 0) {

        variablesUnit.sort(
          (a: Variable, b: Variable) => {
            if (a.order > b.order) {
              return 1;
            } else if (a.order < b.order) {
              return -1;
            } else {
              return 0;
            }
          }
        );

        variablesUnit.forEach(variable => {

          const key = packet.code + '_' + unit.code + '_' + unit.warrantyCode + '_' + variable.code;

          if (!form.controls[key]) {
            form.addControl(key, new UntypedFormControl(variable.value, variable.compulsory ? Validators.required : null));
          }

          const controlVariable = form.controls[key];
          controlVariable.setValue(variable.value);

          if (variable.compulsory && unit.selected) {
            controlVariable.setValidators([Validators.required]);
            controlVariable.updateValueAndValidity();
          } else {
            controlVariable.clearValidators();
            controlVariable.updateValueAndValidity();
          }
          controlVariable.markAsTouched();
        });
      }
    });
    return packet.unitList;
  }

  setUnitsPacket(packet: Package) {
    this.packet = packet;
  }

  getUnitsPacket(): Package {
    return this.packet;
  }

}

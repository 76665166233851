import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxExtensionModule} from './extension/rgi-rx-extension.module';
import {RgiRxReactionModule} from './reaction/rgi-rx-reaction.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RgiRxReactionModule
  ],
  exports: [RgiRxExtensionModule, RgiRxReactionModule]
})
export class RgiRxModule {
}

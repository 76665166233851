<tr *ngFor="let row of rows; let rowIndex = index" role="row">
  <td *ngFor="let item of row; let colIndex = index"
      class="rgi-ui-datepicker-calendar-cell {{item.cellClass}}"
      [tabindex]="isActiveCell(rowIndex, colIndex) ? 0 : -1"
      [class.rgi-ui-datepicker-calendar-cell-active]="isActiveCell(rowIndex, colIndex)"
      [class.rgi-ui-datepicker-calendar-cell-disabled]="!item.enabled"
      [class.rgi-ui-datepicker-calendar-cell-in-range]="isInRange(item.value)"
      [class.rgi-ui-datepicker-calendar-cell-range-from]="isRangeFrom(item.value)"
      [class.rgi-ui-datepicker-calendar-cell-range-to]="isRangeTo(item.value)"
      [attr.aria-label]="item.ariaLabel"
      [attr.aria-disabled]="!item.enabled || null"
      [style.width.%]="100 / numCols"
      [style.paddingTop.%]="50 * cellRatio / numCols"
      [style.paddingBottom.%]="50 * cellRatio / numCols"
      (click)="selectCell(item)">
        <span class="rgi-ui-datepicker-calendar-cell-content"
              [ngClass]="{
                'rgi-ui-datepicker-calendar-cell-out': item.out,
                'rgi-ui-datepicker-calendar-cell-today': item.value === todayValue,
                'rgi-ui-datepicker-calendar-cell-selected': isSelected(item.value)
              }">
            {{item.displayValue}}
        </span>
  </td>
</tr>

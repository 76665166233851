import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { DIALOG_DATA, OnModalClose } from "@rgi/rx/ui";
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UtilityMethod } from "../../../constants/forfait.constants";
import { ModifyForfait } from "../../../models/forfait/modify-forfait.model";
import { AddForfait } from "../../../models/forfait/add-forfait.model";
import { DialogueMsg } from "../../../models/dialogue-msg.model";
import { RgiRxTranslationService } from "@rgi/rx/i18n";
import { ForfaitStateManager } from "../../../state-managers/forfait.state.manager";
import { Subscription } from "rxjs";
import { ForfaitState } from "../../../states/forfait.state";

@Component({
    selector: 'add-edit-forfait',
    templateUrl: './add-edit-forfait.component.html'
})
export class AddEditForfaitComponent implements OnModalClose, OnInit {
    modalClose = new EventEmitter<any>()
    subscriptions: Subscription[] = [];

    protected errorMessage: string

    protected form: UntypedFormGroup = this.formBuilder.group({
        codiceMessaggio: [[], Validators.required],
        dataInizioValidita: ["", Validators.required],
        dataFineValidita: ["", Validators.required],
        importo: [0.00, Validators.required]
    });

    protected dialogueFunctions: DialogueMsg[]

    constructor(
        @Inject(DIALOG_DATA) public dataFromFather: { idForfait: string, importo: string, stateManager: ForfaitStateManager },
        private formBuilder: UntypedFormBuilder,
        public translateService: RgiRxTranslationService
    ) { }

    ngOnInit() {
        this.checkState();

        if(!!this.dataFromFather.idForfait) {
            this.form = this.formBuilder.group({
                importo: new FormControl(this.dataFromFather.importo, Validators.required)
            });
        }
    }

    checkState() {
        this.subscriptions.push(
            this.dataFromFather.stateManager.getState$().subscribe((state: ForfaitState) => {
                if (state) {
                    if (state.errorMessage) {
                        this.translateService.translate(state.errorMessage).subscribe(res => {
                            this.errorMessage = res;
                        })
                    }

                    if (state.dialogueFunctions) {
                        this.dialogueFunctions = state.dialogueFunctions
                            .find(dialogFunction => dialogFunction.functionCode == "CICOS")
                            .dialogueMsgData;
                    }

                    if (state.add) {
                        this.modalClose.emit()
                    }

                    if (state.modify) {
                        this.modalClose.emit()
                    }
                }
            })
        )
    }

    onSubmit(): void {
        this.errorMessage = null;
        const rawValue = this.form.getRawValue();

        if (this.form.invalid) {
            return;
        }

        if (this.dataFromFather.idForfait == null) {
            const isDateOk: boolean = this.controlloDate(rawValue);

            if (isDateOk) {
                const isImportoOk: boolean = this.controlloImporto(rawValue);

                if (isImportoOk) {
                    this.confirm(rawValue)
                }
            }
        } else {
            const isImportoOk: boolean = this.controlloImporto(rawValue);

            if (isImportoOk) {
                this.confirm(rawValue)
            }
        }


        this.translateService.translate(this.errorMessage).subscribe(res => {
            this.errorMessage = res;
        })
    }

    private controlloImporto(rawValue: any): boolean {
        const regexImporto: RegExp = new RegExp('^[0-9]{1,}(\\.[0-9]{0,2})?$');
        let importoAsString: string = rawValue.importo;

        if (!regexImporto.test(importoAsString)) {
            this.errorMessage = '_CLAIMS_._INCORRECT_AMOUNT';
            return false;
        }

        return true;
    }

    private controlloDate(rawValue: any): boolean {
        if (rawValue.dataInizioValidita != null && rawValue.dataFineValidita != null) {
            const dataInizio: Date = new Date(rawValue.dataInizioValidita);
            const dataFine: Date = new Date(rawValue.dataFineValidita);

            if (dataFine <= dataInizio) {
                this.errorMessage = '_CLAIMS_._WRONG_DATES'
                return false
            }

            rawValue.dataInizioValidita = UtilityMethod.transform(rawValue.dataInizioValidita);
            rawValue.dataFineValidita = UtilityMethod.transform(rawValue.dataFineValidita);
            return true
        }

        this.errorMessage = '_CLAIMS_._NOT_CORRECT_ENTRY_DATA'
        return false
    }

    private confirm(rawValue: any): void {
        if (this.errorMessage == null) {
            if (!!this.dataFromFather.idForfait) {
                const modifyForfait: ModifyForfait = {
                    idForfait: this.dataFromFather.idForfait,
                    importo: Number(rawValue.importo)
                }

                this.dataFromFather.stateManager.modifyForfait$(modifyForfait)
            } else {
                const addForfait: AddForfait = {
                    codiceFunzione: "CICOS",
                    codiceMessaggio: rawValue.codiceMessaggio,
                    dataInizioValidita: rawValue.dataInizioValidita,
                    dataFineValidita: rawValue.dataFineValidita,
                    importo: rawValue.importo
                }

                this.dataFromFather.stateManager.addForfait$(addForfait);
            }
        }
    }

    unsubscribeState() {
        this.subscriptions.forEach((sub) => {
            if (sub) {
                sub.unsubscribe();
                sub = null;
            }
        })
    }
}

<div class="rgi-gp-section-title rgi-gp-mb-m">
  <b translate>_GP_._TITLE_._ASSET_LIST_</b>
</div>
<div class="rgi-gp-mb-l" *ngFor="let asset of assets; trackBy: trackAsset">
  <rgi-rx-expansion-panel (toggle)="updateAccordionMap($event, asset)" [attr.data-qa]="asset.codeAsset"
                          [expanded]="assets.length === 1 || isAssetExpanded(asset)" class="rgi-gp-asset-accordion">
    <div rgi-rx-expansion-panel-header>
      <div class="rgi-gp-flex rgi-gp-w-100 rgi-gp-fl-align-center">
        <div class="rgi-gp-flex-gr-1 rgi-gp-font-family" [attr.data-qa]="asset.descrAsset" rgi-rx-expansion-panel-label>
          {{asset.descrAsset}}
        </div>
        <div *ngIf="asset.deletable" data-qa="rgi-gp-del-asset-btn">
          <span class="rgi-ui-icon-delete" (click)="deleteAsset.emit(asset.codeAsset)"></span>
        </div>
      </div>
    </div>
    <ng-template rgiRxExpansionPanelContent>

      <ng-container *ngIf="asset.structure?.code!='2'">
        <rgi-gp-group-policy-factors-component *ngIf="asset.variables && asset.variables.length"
                                               [factors]="asset.variables"
                                               [parentForm]="assetVariablesFGMap.get(asset.codeAsset)"
                                               (updateFactors)="onVariableUpdate($event, asset.codeAsset)"
                                               class="rgi-gp-flex-row">
        </rgi-gp-group-policy-factors-component>
        <div class="rgi-gp-section-title rgi-gp-mb-m" *ngIf="asset.units?.length>0">
          <b translate>_GP_._TITLE_._GUARANTEES_</b>
        </div>
        <rgi-gp-unit *ngFor="let unit of asset.units; trackBy: trackUnit"
                     [unit]="unit.unit"
                     [unitsClause]="assetsClauses | gpFilterAssetUnitsClauses:asset:unit.unit"
                     [linkedUnits]="unit.linkedUnit"
                     [accordionMap]="accordionMap"
                     [accordionPrefix]="asset.codeAsset + '_'"
                     [parentForm]="parentForm"
                     [unitInitialStatuses]="unitInitialStatus"
                     (updateUnit)="updateUnit($event, asset)"
                     (updateUnitClauses)="onUpdateUnitClauses($event, asset)">
        </rgi-gp-unit>
        <rgi-gp-group-policy-clause
          *ngIf="!!assetsClauses"
          [clauses]="assetsClauses | gpFilterAssetClauses:asset"
          [parentForm]="parentForm"
          (formChange)="parentForm=$event"
          (updateClauses)="onUpdateAssetClauses($event, asset)"
        ></rgi-gp-group-policy-clause>
        <!--    -->

      </ng-container>
      <ng-container *ngIf="asset.structure?.code==='2'">
        <rgi-gp-group-policy-clusters [codeAsset]="asset.codeAsset" [parentForm]="parentForm" [stateManagerCluster]="stateMng"
                                      [unitInitialStatuses]="unitInitialStatus" [accordionMap]="accordionMap" showApplicationList="false"
                                      (reloadAssets)="reloadAsset($event)">

        </rgi-gp-group-policy-clusters>
      </ng-container>
    </ng-template>
  </rgi-rx-expansion-panel>
</div>

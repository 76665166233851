import {QuestionnaireI} from './questionnaire';

export interface SurveyI {
  questionnaire: QuestionnaireI;
  isTest: boolean;
  name: string;
  id: number;
  codeFisc: string;
}

export const SURVEY_VERSION_STATUS =
  {
    ISSUED: 'ISSUED',
    DRAFT: 'DRAFT'
  };

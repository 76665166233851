import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  TableSchema
} from '@rgi/rx/ui';
import { CarData, CarDataMapped, DataTable } from '../dsc-car-bodywork-filter.model';

@Component({
  selector: 'claims-dsc-car-bodywork-table',
  templateUrl: './dsc-car-bodywork-table.component.html',
  styleUrls: ['./dsc-car-bodywork-table.component.scss']
})
export class DscCarBodyworkTableComponent implements OnInit {

  private dataTable$: DataTable;
  private result$: CarData[] = [];
  resultMapped: CarDataMapped[] = [];
  @Output() outputCarBodywork = new EventEmitter<CarData>();
  schema: TableSchema = {
    rows: [
      {
        name: 'code', title: 'Code',
        format: {
          pipes: [
            {
              pipe: 'uppercase'
            }
          ]
        },
      },
      {
        name: 'name', title: 'Name',
        format: {
          pipes: [
            {
              pipe: 'uppercase'
            }
          ]
        }
      },
      {
        name: 'nominative',
        title: 'Cognome/Ragione sociale',
        format: {
          pipes: [
            {
              pipe: 'uppercase'
            }
          ]
        }
      },
      {
        name: 'type',
        title: 'Tipo carrozzeria',
      },
      {
        name: 'custom_template',
        title: 'Action'
      },
    ],
    header: ['code', 'name', 'nominative', 'type', 'custom_template'],
  };

  constructor() { }

  ngOnInit() {
  }
  get result(): DataTable {
    return this.dataTable$;
  }
  @Input()
  set result(value: DataTable) {
    if (value.data.length > 0) {
      this.result$ = value.data;
      this.resultMapped = value.data.map((el) => {
        return {
          objectId: el.objectId,
          code: el.code,
          name: el.driver ? el.driver.givenName : '-',
          nominative: el.driver ? el.driver.anagSubject.nominative : '-',
          type: value.arrayBodyworkTypes.find((t) => t.codice === el.trusteeType).descrizione,
          startDate: el.startDate,
          endDate: el.endDate,
          driver: el.driver,
        };
      });
    }
  }

  updateNameViaInput(item) {
    const rowSelected = item.row;
    const selected = this.result$.find((el) => el.objectId === rowSelected.objectId);
    this.outputCarBodywork.emit(selected);
  }

}

<div class="note-container" *ngIf="show">
    <div *ngIf="data.hasLeftColumn" class="column left"></div>
    <div *ngIf="data.hasRightColumn" [ngClass]="['column','right', rightColumnStyle]">
        <div [ngClass]="['note', bulletColor, type]">
            {{data.text}}
            <div class="footer">
                <span class="rgifont rgi-user"></span> {{ data.user }} | {{ data.date | date: 'dd/MM/yyyy - HH:mm:ss' }}
            </div>
            <div class="subFooter">
                <div *ngIf="type!=='canceled'; then buttonBlock else cancelBlock"></div>
            </div>
            <ng-template #buttonBlock>
                <div style="display:flex;">
                    <button class="button-left" (click)="comments()">
                        <span class="rgifont rgi-comment"></span>&nbsp;
                        <span>{{ '_CLAIMS_._COMMENTS' | translate }}</span>&nbsp;({{data.comments.length}})
                    </button>
                    <button class="button-right" *ngIf="type==='manual'" (click)="cancel()">
                        <span class="rgifont rgi-cancel"></span>&nbsp;
                        <span>{{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</span>
                    </button>
                </div>
            </ng-template>
            <ng-template #cancelBlock>
                <span>{{ '_CLAIMS_._CANCELED_BY' | translate }}</span>: {{ data.cancelUser }}&nbsp;|&nbsp;
                <span class="thin-text">{{ data.cancelDate | date: 'dd/MM/yyyy - HH:mm:ss' }}</span>
            </ng-template>
        </div>
    </div>
</div>
import {StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPostSalesOrchestratorService} from '../../orchestrator/pnc-postsales-orchestrator.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {PncPostSalesIntegrationService} from '../../services/pnc-postsales-integration.service';
import {RgiPncPsalesStateManager} from '../../api/rgi-pnc-postsales-state.manager';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPsalesHttpErrorService} from '../../services/pnc-postsales-http-error.service';
import {catchError, concatMap, map} from 'rxjs/operators';
import {PncDomainService} from '../../resources/http/pnc-domain.service';
import {
  SupplementaryTechnicalAccounting,
  SupplementaryTechnicalAccountingAmount
} from '../../resources/model/api/technical-accounting';
import {TableSchema} from '@rgi/rx/ui';
import {PncPsalesSupplementaryTechAccState} from '../../resources/states/supplementary-tech-acc-state';
import {PncPsalesForm} from '../../resources/model/common/form';
import {RGI_FIELD_TYPE, RgiFormField} from '../../resources/model/common/rgi-form-field';
import {
  ConfiguredAmount,
  EDITABLE_SUPPL_TECH_ACC_AMOUNTS,
  SUPPL_TECH_ACC_MODEL_ENUM,
  SUPPLEMENTARY_TECH_ACC_CONFIGURED_FIELDS
} from '../../resources/model/common/supplementary-tech-acc';
import {InstallmentHeader, RgiQuotationHeaderData} from '../../resources/model/common/quotation/quotation-header-data';
import {PncPsalesButton} from '../../resources/model/common/button';
import {PNC_PSALES_ACTIONS} from '../../resources/constants/actions';
import {createDefaultButtonBar} from '../../resources/constants/buttons';
import {SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP} from '../../resources/model/common/quotation/quotation-table-schemas';

export class PncPsalesSupplementaryTechAccStateManager extends RgiPncPsalesStateManager<PncPsalesSupplementaryTechAccState> {

  readonly fgName = 'supplementaryTechAccounting';
  private insuredRisksTableData$ = new BehaviorSubject<any>([]);
  private notInsuredRisksTableData$ = new BehaviorSubject<any>([]);

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private resourceService: PncDomainService
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
    if (!context.apiPrefix) {
      throw new Error('Api Prefix is not provided for supplementary technical accounting step');
    }
    if (stateStoreService.has(this.storeKey)) {
      const state = stateStoreService.get<PncPsalesSupplementaryTechAccState>(this.storeKey);
      if (state?.forms[this.fgName]?.fields?.length) {
        this.registerAmountsUpdateCallback(state);
      }
    }
  }

  initState$(state: Observable<PncPsalesSupplementaryTechAccState>): Observable<PncPsalesSupplementaryTechAccState> {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    return state.pipe(
      concatMap((st: PncPsalesSupplementaryTechAccState) => {
        return this.getSupplementaryTechAcc$(st);
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesSupplementaryTechAccState) => {
        this.registerAmountsUpdateCallback(st);
        st.footerBtns = createDefaultButtonBar([new PncPsalesButton('_PCPSALES_._BTN_._RESET_', PNC_PSALES_ACTIONS.RESET)]);
        return st;
      })
    );
  }

  protected registerAmountsUpdateCallback(st: PncPsalesSupplementaryTechAccState) {
    const fieldCodes = st.forms[this.fgName].fields.map(field => field.code);
    this.registerOnFormFieldChange(
      this.fgName,
      fieldCodes,
      (state, field, value) => this.updateAmount$(of(state), field, value)
    );
  }

  protected getSupplementaryTechAcc$(st: PncPsalesSupplementaryTechAccState): Observable<PncPsalesSupplementaryTechAccState> {
    return this.resourceService.getSupplementaryTechAccounting$(st.policyNumber, st.operationType, st.currentOperation.code, this.context.apiPrefix, st.draftId).pipe(
      map((resp: SupplementaryTechnicalAccounting) => {
        st.supplementaryTechAcc = resp;
        const formFields: RgiFormField[] = [];
        st.supplementaryTechAcc.amounts.forEach((amount: SupplementaryTechnicalAccountingAmount) => {
          const editableAmounts: string[] = Object.keys(amount)
            .filter(key => {
              const configuredKey: ConfiguredAmount = SUPPLEMENTARY_TECH_ACC_CONFIGURED_FIELDS.get(key);
              return EDITABLE_SUPPL_TECH_ACC_AMOUNTS.includes(configuredKey) && st.supplementaryTechAcc.visibleAmounts.includes(configuredKey);
            });
          editableAmounts.forEach((col) => {
            formFields.push({
              code: amount.riskCode + '_' + col,
              type: RGI_FIELD_TYPE.STRING,
              editable: true,
              mandatory: false,
              visible: true,
              value: parseFloat(Number(amount[col]).toFixed(2))
            });
          });
        });
        st.forms[this.fgName] = new PncPsalesForm(formFields);
        st.quotationBarData = new RgiQuotationHeaderData();
        st.quotationBarData.headerInstallments = this.getQuotationHeaderInstallments(st);
        st.quotationBarData.buttons = [new PncPsalesButton('_PCPSALES_._BTN_._CALCULATE_', PNC_PSALES_ACTIONS.CALCULATE)];
        return st;
      }),
      catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
    );
  }

  actionContinue() {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const st$ = this.getState$().pipe(
      concatMap((st: PncPsalesSupplementaryTechAccState) => {
        return this.setSupplementaryTechAccTransaction$(st);
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesSupplementaryTechAccState) => {
        this.orchestrator.goToNextStep(st, this.activeRoute);
        return st;
      })
    );
    this.updateState$(st$);
  }

  updateSupplementaryTechAccData(reset?: boolean) {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const st$ = of(this.getCurrentState()).pipe(
      concatMap((st: PncPsalesSupplementaryTechAccState) => {
        return this.setSupplementaryTechAccTransaction$(st, reset);
      }),
      concatMap((st: PncPsalesSupplementaryTechAccState) => {
        return this.getSupplementaryTechAcc$(st);
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesSupplementaryTechAccState) => st)
    );
    this.updateState$(st$);
  }

  private setSupplementaryTechAccTransaction$(st: PncPsalesSupplementaryTechAccState, reset?: boolean): Observable<PncPsalesSupplementaryTechAccState> {
    return this.resourceService.setSupplementaryTechAccounting$(st.policyNumber, st.operationType,
      st.currentOperation.code, this.context.apiPrefix, st.draftId, st.supplementaryTechAcc, reset).pipe(
      map(() => st),
      catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
    );
  }

  updateInsuredRisksTableData() {
    const insuredRisks = this.getCurrentState()
      .supplementaryTechAcc.amounts.filter((amount: SupplementaryTechnicalAccountingAmount) => amount.presentInContract);
    this.insuredRisksTableData$.next(this.getTableDataRows(insuredRisks));
  }

  get insuredRisksTableData() {
    return this.insuredRisksTableData$.asObservable();
  }

  get notInsuredRisksTableData() {
    return this.notInsuredRisksTableData$.asObservable();
  }

  updateNotInsuredRisksTableData() {
    const insuredRisks = this.getCurrentState()
      .supplementaryTechAcc.amounts.filter((amount: SupplementaryTechnicalAccountingAmount) => !amount.presentInContract);
    this.notInsuredRisksTableData$.next(this.getTableDataRows(insuredRisks));
  }

  private getTableDataRows(risks: SupplementaryTechnicalAccountingAmount[]): any[] {
    return risks.map((row: SupplementaryTechnicalAccountingAmount) => {
      return {
        code: row.riskCode,
        riskDescr: row.riskDescription,
        NET: row.net,
        NET_INTERESTS: row.netInterests,
        CHARGES: row.charges,
        CHARGES_INTERESTS: row.chargesInterests,
        TAXABLE: row.taxable,
        TOTAL_TAXES: row.totalTaxes,
        GROSS: row.gross,
        COLLECTED_COMMISSIONS: row.collectedCommissions,
        PURCHASED_COMMISSIONS: row.purchasedCommissions,
        taxes: row.taxes
      };
    });
  }

  getInsuredRisksTableSchema(): TableSchema {
    return this.getSchema(SUPPL_TECH_ACC_MODEL_ENUM.INSURED_RISKS);

  }

  getNotInsuredRisksTableSchema(): TableSchema {
    return this.getSchema(SUPPL_TECH_ACC_MODEL_ENUM.NOT_INSURED_RISKS);
  }

  protected getSchema(riskType: string): TableSchema {
    const st = this.getCurrentState();
    const schema = {
      rows: [SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(riskType)],
      header: [SUPPL_TECH_ACC_MODEL_ENUM.RISK_DESCR]
    };
    st.supplementaryTechAcc.visibleAmounts.forEach((amount: ConfiguredAmount) => {
      schema.rows.push(SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(amount));
      schema.header.push(amount);
    });
    return schema;
  }

  private updateAmount$(st$: Observable<PncPsalesSupplementaryTechAccState>, formControlName: string, value: number): Observable<PncPsalesSupplementaryTechAccState> {
    return st$.pipe(
      map((st: PncPsalesSupplementaryTechAccState) => {
        const riskCode = formControlName.split('_')[0];
        const propToUpdate = formControlName.split('_')[1];
        const riskToUpdate = st.supplementaryTechAcc.amounts.find(risk => risk.riskCode === riskCode);
        const formattedValue = isNaN(value) ? 0 : parseFloat(value.toFixed(2));
        riskToUpdate[propToUpdate] = formattedValue;
        st.forms[this.fgName].fields.find(field => field.code === formControlName).value = formattedValue;
        return st;
      })
    );
  }

  protected getQuotationHeaderInstallments(st: PncPsalesSupplementaryTechAccState): InstallmentHeader[] {
    return [
      {
        installmentType: '_PCPSALES_._LABEL_._OPERATION_PREMIUM_',
        amountType: '_RGIPNC_._LABEL_._GROSS_',
        amount: st.supplementaryTechAcc.operationPremium.toString(),
        boldSection: true,
      },
      {
        installmentType: '_RGIPNC_._LABEL_._ANNUAL_PREMIUM_',
        amountType: '_RGIPNC_._LABEL_._GROSS_',
        amount: st.supplementaryTechAcc.annualPremium.toString(),
        boldSection: false,
      },
    ];
  }

  onAction(action: string) {
    if (action === PNC_PSALES_ACTIONS.CALCULATE) {
      this.updateSupplementaryTechAccData();
    } else if (action === PNC_PSALES_ACTIONS.RESET) {
      this.updateSupplementaryTechAccData(true);
    } else {
      super.onAction(action);
    }
  }
}

<rgi-rx-datatable [data]="resultMapped" [schema]="schema" disableSearch="false" disablePaginator="false"
    [pageOptions]="[5,10,20]" [expansionRow]="rowDetail">
    <ng-template rgiRxDataTableCell="custom_template" let-bodyWork>
        <div class="w-100 text-center">
            <button type="button" class="btn btn-flat btn-sm" (click)="updateNameViaInput(bodyWork)">
                <span class="action-icon rgifont rgi-chevron-right"></span>
            </button>
        </div>
    </ng-template>
</rgi-rx-datatable>
<ng-template #rowDetail let-bodyWork>
    <div class="container-fluid">
        <div class="dl-element">
            <label>{{'_CLAIMS_._CAR_BODY_DETAIL' | translate}}</label>
            <div></div>
        </div>
        <div class="dl-element">
            <label>{{'_CLAIMS_._START_DATE_OF_COLLABORATION' | translate}}</label>
            <div>{{(bodyWork.startDate | date:'shortDate') || '-'}}</div>
        </div>
        <div class="dl-element">
            <label>{{'_CLAIMS_._END_DATE_COLLABORATION' | translate}}</label>
            <div>{{(bodyWork.endDate | date:'shortDate') || '-'}}</div>
        </div>
        <div class="dl-element">
            <label>{{'_CLAIMS_._ADDRESS'| translate}}</label>
            <div>{{bodyWork?.driver?.claimsAddress?.toponymStr || '-'}}{{bodyWork?.driver?.claimsAddress?.placeAddress
                || '-'}}</div>
        </div>
        <div class="dl-element">
            <label>{{'_CLAIMS_._HOUSE_NUMBER'| translate}}</label>
            <div>{{bodyWork?.driver?.claimsAddress?.number || '-'}}</div>
        </div>
        <div class="dl-element">
            <label>{{'_CLAIMS_._CAP' | translate}}</label>
            <div>{{bodyWork?.driver?.claimsAddress?.postCode || '-'}}</div>
        </div>
        <div class="dl-element">
            <label>{{'_CLAIMS_._MUNICIPALITY' | translate}}</label>
            <div>{{bodyWork?.driver?.claimsAddress?.city || '-'}}</div>
        </div>
        <div class="dl-element">
            <label>{{'_CLAIMS_._PROVINCE' | translate}}</label>
            <div>{{bodyWork?.driver?.claimsAddress?.province || '-'}}</div>
        </div>
        <div class="dl-element">
            <label >{{'_CLAIMS_._NATION' | translate}}</label>
            <div>{{bodyWork?.driver?.claimsAddress?.contryCode || '-'}}</div>
        </div>
    </div>
</ng-template>
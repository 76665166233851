<div class="container-fluid" data-qa="activity-list">
  <div *ngIf="card.isSubCard">
    <button class="btn btn-section col-6" (click)="onChangeTab(true)" [ngClass]="{'active': tabActive }">{{ '_CLAIMS_._ACT_ACTIVE' | translate }}</button>
    <button class="btn btn-section col-6" (click)="onChangeTab(false)"
      [ngClass]="{'active': !tabActive }">{{ '_CLAIMS_._ACT_CLOSED' | translate }}</button>
  </div>
  <div *ngIf="tabActive">
    <table *ngIf="hasResult()" class="table-responsive table coreDT">
      <thead>
        <tr class="head-result thDataTable">

          <td class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <span attr.data-qa="act-dictionary-description-label">{{ '_CLAIMS_._ACTIVITY' | translate }}</span>
          </td>

          <td class="col-xl-3 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-involved-party-label">{{ '_CLAIMS_._ASSIGNEE_GROUP' | translate }}</span>
          </td>

          <td class="col-xl-2 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-type-of-loss-label">{{ '_CLAIMS_._ASSIGNEE_USER' | translate }}</span>
          </td>

          <td class="col-xl-3 hidden-xs">
            <span attr.data-qa="act-involved-party-label">{{ '_CLAIMS_._ACTOR' | translate }}</span>
          </td>

          <td class="col-xl-2 hidden-xs">
            <span attr.data-qa="act-type-of-loss-label">{{ '_CLAIMS_._TYPE_OF_LOSS' | translate }}</span>
          </td>

          <td class="col-md-2 col-lg-2" *ngIf="card.isExtend">
            <span attr.data-qa="act-ins-date-label">{{ '_CLAIMS_._RUN_DATE' | translate }}</span>
          </td>

          <td class="col-md-2 col-lg-2">
            <span attr.data-qa="act-expiration-date-label">{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}</span>
          </td>

          <td class="col-md-2 col-lg-2">
            <span attr.data-qa="act-expiration-date-label">{{ '_CLAIMS_._PRIORITY' | translate }}</span>
          </td>

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
            <span attr.data-qa="act-actions-label"></span>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let activity of currentList; let even = even; let odd = odd; let actionIndex = index"
          [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
          attr.data-qa="act-result-{{actionIndex}}">

          <td class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <span attr.data-qa="act-dictionary-description-{{actionIndex}}">{{
              activity.actDictionaryBean.dictionaryDescription }}</span>
            <span *ngIf="activity.documentKey" attr.data-qa="act-dictionary-description-{{actionIndex}}"> ({{
              activity.documentKey }})</span>
            <div class="claims-activity-popover"
              *ngIf="activity.actTaskBean[0] && activity.actTaskBean[0].message">
              <input type="checkbox" id="btnControl" />
              <span class="rgifont rgi-info-circle float-right pull-right" for="btnControl">
                <span class="claims-activity-popover-container">
                  <div class="activity-message">{{ activity.actTaskBean[0].message | translate }}</div>
                </span>
              </span>
            </div>
          </td>

          <td class="col-xl-3 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-involved-party-{{actionIndex}}">{{ activity.actTaskBean[0].assigneeGroup }}</span>
          </td>

          <td class="col-xl-2 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-type-of-loss-{{actionIndex}}">{{ activity.actTaskBean[0].assigneeUser }}</span>
          </td>

          <td class="col-xl-3 hidden-xs">
            <span attr.data-qa="act-involved-party-{{actionIndex}}">{{ activity.involvedParty }}</span>
          </td>

          <td class="col-xl-2 hidden-xs">
            <span attr.data-qa="act-type-of-loss-{{actionIndex}}">{{ activity.typeOfLoss }}</span>
          </td>

          <td class="col-md-2 col-lg-2" *ngIf="card.isExtend">
            <span attr.data-qa="act-ins-date-{{actionIndex}}">{{ activity.insDate | date:'dd/MM/yyyy' }}</span>
          </td>

          <td class="col-md-2 col-lg-2" *ngIf="dateGreaterThenToday(activity) && activity.status !== 4">
            <span attr.data-qa="act-expiration-date-{{actionIndex}}">{{ activity.expirationDate | date:'dd/MM/yyyy'
              }}</span>
          </td>
          <td class="col-md-2 col-lg-2 red-color" *ngIf="!dateGreaterThenToday(activity) && activity.status !== 4">
            <span attr.data-qa="act-expiration-date-{{actionIndex}}">{{ activity.expirationDate | date:'dd/MM/yyyy'
              }}</span>
          </td>
          <td class="col-md-2 col-lg-2" *ngIf="activity.status === 4">
            <span attr.data-qa="act-expiration-date-{{actionIndex}}">{{ '_CLAIMS_._SUSPENDED' | translate }}</span>
          </td>

          <td class="col-xl-2 hidden-xs">
            <span attr.data-qa="act-priority-{{actionIndex}}" *ngIf="activity.priority!==null">{{ activity.descrPriority }}</span>
          </td>

          

          <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
            <span class="column-name-xs visible-xs-block pull-left">Actions</span>
            <div>
              <claims-activity-menu [activity]="activity" [card]="card"></claims-activity-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!hasResult()">
      <div class="noaction">
        <span class="icon-attention-sign"></span>
        <span data-qa="no-activity-show" translate class="notasks-message">{{ '_CLAIMS_._MESSAGE_._NO_ACTIVITY_TO_SHOW' | translate }}</span>
        <span class="icon-notepad"></span>
      </div>
    </div>
  </div>
  <div *ngIf="!tabActive">
    <table *ngIf="hasClosedResult()" class="table-responsive table coreDT">
      <thead>
        <tr class="head-result thDataTable">
          <td class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <span attr.data-qa="act-dictionary-description-label">{{ '_CLAIMS_._ACTIVITY' | translate }}</span>
          </td>
          <td class="col-xl-3 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-involved-party-label">{{ '_CLAIMS_._ASSIGNEE_GROUP' | translate }}</span>
          </td>
          <td class="col-xl-2 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-type-of-loss-label">{{ '_CLAIMS_._ASSIGNEE_USER' | translate }}</span>
          </td>
          <td class="col-xl-3 hidden-xs">
            <span attr.data-qa="act-involved-party-label">{{ '_CLAIMS_._ACTOR' | translate }}</span>
          </td>
          <td class="col-xl-2 hidden-xs">
            <span attr.data-qa="act-type-of-loss-label">{{ '_CLAIMS_._TYPE_OF_LOSS' | translate }}</span>
          </td>
          <td class="col-md-2 col-lg-2">
            <span attr.data-qa="act-expiration-date-label">{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}</span>
          </td>
          <td class="col-md-2 col-lg-2">
            <span attr.data-qa="act-expiration-date-label">{{ '_CLAIMS_._COMPLETION_DATE' | translate }}</span>
          </td>
          <td class="col-md-2 col-lg-2">
            <span attr.data-qa="act-priority-label">{{ '_CLAIMS_._PRIORITY' | translate }}</span>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let activity of closedActivityList; let even = even; let odd = odd; let actionIndex = index"
          [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
          attr.data-qa="act-result-{{actionIndex}}">
          <td class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <span attr.data-qa="act-dictionary-description-{{actionIndex}}">
                <ngb-accordion>
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <span attr.data-qa="act-dictionary-description-{{actionIndex}}"> + </span>
                        <span attr.data-qa="act-dictionary-description-{{actionIndex}}">{{ activity.actDictionaryBean.dictionaryDescription }}</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <span>{{ '_CLAIMS_._NOTE' | translate }}: </span>
                        <span >{{activity.note}}</span>
                      </div>
                      <div *ngIf="activity.endNewDocDesc && activity.endNewDocDesc!=='' " class="row">
                        <span>{{ '_CLAIMS_._ESCAPE_METHODS' | translate }}:</span>
                        <span> {{activity.endNewDocDesc}}</span>
                      </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </span>
            <span *ngIf="activity.documentKey" attr.data-qa="act-dictionary-description-{{actionIndex}}"> ({{
              activity.documentKey
              }})</span>
            <div class="claims-activity-popover"
              *ngIf="activity.actTaskBean[0] && activity.actTaskBean[0].message">
              <input type="checkbox" id="btnControl" />
              <span class="rgifont rgi-info-circle float-right pull-right" for="btnControl">
                <span class="claims-activity-popover-container">
                  <div class="activity-message">{{ activity.actTaskBean[0].message | translate }}</div>
                </span>
              </span>
            </div>
          </td>
          <td class="col-xl-3 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-involved-party-{{actionIndex}}">{{ activity.actTaskBean[0].assigneeGroup }}</span>
          </td>
          <td class="col-xl-2 hidden-xs" *ngIf="card.isExtend">
            <span attr.data-qa="act-type-of-loss-{{actionIndex}}">{{ activity.actTaskBean[0].assigneeUser }}</span>
          </td>
          <td class="col-xl-3 hidden-xs">
            <span attr.data-qa="act-involved-party-{{actionIndex}}">{{ activity.involvedParty }}</span>
          </td>
          <td class="col-xl-2 hidden-xs">
            <span attr.data-qa="act-type-of-loss-{{actionIndex}}">{{ activity.typeOfLoss }}</span>
          </td>
          <td class="col-md-2 col-lg-2" *ngIf="dateGreaterThenToday(activity)">
            <span attr.data-qa="act-expiration-date-{{actionIndex}}">{{ activity.expirationDate | date:'dd/MM/yyyy'
              }}</span>
          </td>
          <td class="col-md-2 col-lg-2 red-color" *ngIf="!dateGreaterThenToday(activity)">
            <span attr.data-qa="act-expiration-date-{{actionIndex}}">{{ activity.expirationDate | date:'dd/MM/yyyy'
              }}</span>
          </td>
          <td class="col-md-2 col-lg-2">
            <span *ngIf="activity.updDate" attr.data-qa="act-ins-date-{{actionIndex}}">{{ activity.updDate | date:'dd/MM/yyyy'}}</span>
          </td>
          <td class="col-xl-2 hidden-xs">
            <span attr.data-qa="act-priority-{{actionIndex}}" *ngIf="activity.priority !== null">{{ activity.descrPriority }}</span>
          </td>
        </tr>

      </tbody>
    </table>
    <div *ngIf="!hasClosedResult()">
      <div class="noaction">
        <span class="icon-attention-sign"></span>
        <span data-qa="no-activity-show" class="notasks-message">{{ '_CLAIMS_._MESSAGE_._NO_ACTIVITY_TO_SHOW' | translate }}</span>
        <span class="icon-notepad"></span>
      </div>
    </div>
  </div>
</div>

<div class="btn-group btn-group-justified">
  <div class="btn-group">
    <button type="button" (click)="manualDeadline($event)" class="btn btn-warning pull-right text-uppercase"
      data-qa="manual-deadline-btn"><span>{{ '_CLAIMS_._BUTTONS_._NEW_MANUAL_DEADLINE' | translate }}</span></button>
  </div>
</div>

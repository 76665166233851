import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Documents } from './documents';
import { DocumentFile } from './document-file';
import { Metadata } from './metadata';
import { GenericEntity } from '../generic-entity';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private baseApiUrlV2;
  private documentsEndpoint;
  private documentsChannel: Subject<Documents> = new Subject<Documents>();

  constructor(
    public httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {

    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
    ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
    : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.documentsEndpoint = this.baseApiUrlV2 + '/documents';
  }

  getDocuments(contractId: string) {
    return this.httpClient.get<Documents>(this.documentsEndpoint + '/contract/' + contractId + '/documents');
  }

  getDocumentType(contractId: string) {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('mandatory', 'false');
    requestParams = requestParams.set('included', 'false');

    const endpoint = this.documentsEndpoint + '/contract/' + contractId + '/documentTypes';
    return this.httpClient.get<Array<GenericEntity>>(endpoint, { params: requestParams });
  }

  addDocument(contractId: string, documentType: GenericEntity) {
    const endpoint = this.documentsEndpoint + '/contract/' + contractId + '/document';
    return this.httpClient.post<Documents>(endpoint, documentType);
  }

  uploadFile(contractId: string, documentId: string, fileDoc: DocumentFile) {
    const endpoint = this.documentsEndpoint + '/contract/' + contractId + '/document/' + documentId + '/file/body';
    return this.httpClient.post<DocumentFile>(endpoint, fileDoc);
  }

  validateFile(contractId: string, documentId: string, file: DocumentFile) {
    const endpoint = this.documentsEndpoint + '/contract/' + contractId + '/document/' + documentId + '/validate';
    return this.httpClient.put<DocumentFile>(endpoint, file);
  }

  previewFile(fileId: string, input: boolean) {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('input', input ? 'true' : 'false');
    const endpoint = this.documentsEndpoint + '/document/file/' + fileId + '/body';
    return this.httpClient.get<any>(endpoint, { params: requestParams });
  }

  updateFileMetadata(contractId: string, documentId: string, fileId: string, listMetadata: Array<Metadata>) {
    const endpoint = this.documentsEndpoint + '/contract/' + contractId + '/document/' + documentId +
      '/file/' + fileId + '/metadata';
    return this.httpClient.put<Array<Metadata>>(endpoint, listMetadata);
  }

  deleteFile(contractId: string, documentId: string, fileId: string) {
    const endpoint = this.documentsEndpoint + '/contract/' + contractId + '/document/' + documentId +
      '/file/' + fileId;
    return this.httpClient.delete(endpoint);
  }

  deleteDocument(contractId: string, documentId: string) {
    const endpoint = this.documentsEndpoint + '/contract/' + contractId + '/document/' + documentId;
    return this.httpClient.delete<Documents>(endpoint);
  }

  public getDocumentsObservable() {
    return this.documentsChannel.asObservable();
  }

  public setDocumentsSignal(newDocuments: Documents) {
    this.documentsChannel.next(newDocuments);
  }

}

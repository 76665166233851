export class VehicleProperties {

  constructor(
    public requirePlateType: boolean,
    public requirePlateChassis: boolean,
    public optionalPlateChassis: boolean,
    public isBoat: boolean,
    public plateChassisNotRequired: boolean,
    public registrationDateJuly: boolean,
    public engineNumberMandatory: boolean,
    public engineNumberVisible: boolean,
    public adjustsInsuredValue: boolean,
    public isInternalMagazine: boolean,
    public isEquipmentVisible?: boolean,
    public isEquipmentMandatory?: boolean,
    public isManufacturerModelVisible?: boolean) {
  }
}

import { PAYMENT_FIELDS_CODES } from './../../../models/adapterPayments.model';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidatorService } from 'angular-iban';
import { PaymentFields } from '../../../models/meansofpayment.model';

@Component({
  selector: 'lic-input-payment',
  templateUrl: './input-payment.component.html',
  styleUrls: ['./input-payment.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InputPaymentComponent implements OnInit, OnDestroy {
  label: string;
  config: PaymentFields;
  disableField: any ;
  group: UntypedFormGroup;
  prevVal: string;
  @Output() messageIban: EventEmitter<string> = new EventEmitter<string>();

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this.config.value === '-1') {
      this.prevVal = null;
    } else if (!!this.group.get(this.config.name).value) {
      this.prevVal = this.group.get(this.config.name).value;
    } else {
      this.prevVal = this.config.value;
    }

    this.initInput();
  }

  onBlur() {
    if (this.prevVal !== this.group.controls[this.config.name].value) {
      if (this.config.name === PAYMENT_FIELDS_CODES.IBAN && this.group.controls[this.config.name].errors !== null) {
        const iban = this.group.controls[this.config.name].value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        this.group.controls[this.config.name].setValue(iban);
        this.el.nativeElement.dispatchEvent(new Event('errorMessage', {bubbles: true}));
        this.prevVal = iban;
      } else {
        this.el.nativeElement.dispatchEvent(new Event('changeValore', {bubbles: true}));
        this.prevVal = this.group.controls[this.config.name].value;
      }
    }
  }


  initInput() {
    if (!this.group.contains(this.config.name)) {
      this.group.addControl(this.config.name, new UntypedFormControl());
    }
    if (this.group.get(this.config.name).value === null ||
        this.group.get(this.config.name).value === '' ||
        this.group.get(this.config.name).value === '-1') {
      this.group.controls[this.config.name].setValue(null);
    } else {
      this.group.controls[this.config.name].setValue(this.group.get(this.config.name).value);
    }
    if (this.config.mandatory) {
      if (this.config.mandatory && this.config.name === PAYMENT_FIELDS_CODES.IBAN) {
        this.group.controls[this.config.name].setValidators([Validators.required, Validators.pattern('^\\S+(?:\\S+)*$'),
        ValidatorService.validateIban]);
      } else {
        this.group.controls[this.config.name].setValidators([Validators.required, Validators.pattern('^(?!\\s*$).+')]);
      }
    }
    if (this.config.readOnly === false && this.group.controls[this.config.name].disabled === false) {
      this.group.controls[this.config.name].enable();
    } else {
      this.group.controls[this.config.name].disable();
    }
    if ( this.disableField)  {
      this.group.controls[this.disableField].disable();

    }
  }

  setPayment(payment: PaymentFields, form: UntypedFormGroup , disableField = null ) {
    this.disableField = disableField;
    this.config = payment;
    this.label = this.config.label;
    this.group = form;
  }

  ngOnDestroy() {
    if (this.group.controls.idPaymentType != null) {
      this.group.controls[this.config.name].clearValidators();
      this.group.controls[this.config.name].updateValueAndValidity();
    }
  }

}


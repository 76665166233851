import {Component, HostBinding, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {tap} from 'rxjs/operators';
import {RgiRxAbstractDropContainer} from '../rgi-rx-drag-drop.api';
import {Observable, Subject, Subscription} from 'rxjs';
import {RgiRxDropSelectionResolverService} from '../rgi-rx-drop-selection-resolver.service';
import {ENTER, ESCAPE, SPACE} from '@angular/cdk/keycodes';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'rgi-rx-drop-body',
  templateUrl: './rgi-rx-drop-body.component.html',
  host: {
    class: 'rgi-ui-drop rgi-rx-drop',
    '[style.display]': 'hidden ? "none" : "block"'
  }
})
export class RgiRxDropBodyComponent implements OnDestroy {

  private _hidden = false;
  private _disabled = false;
  private _resolverSubscription: Subscription = Subscription.EMPTY;
  private _dropChange = new Subject<any>();

  constructor(
    public dropContainer: RgiRxAbstractDropContainer<any>,
    private resolver: RgiRxDropSelectionResolverService
  ) {
  }

  @Input() @HostBinding('class.rgi-ui-disabled') get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }


  @Input() @HostBinding('class.rgi-rx-drop-body-hidden') get hidden(): boolean {
    return this._hidden;
  }

  set hidden(value: boolean) {
    this._hidden = value;
  }

  @HostListener('click', ['$event'])
  _handleSelect(event: Event) {
    if (!this.dropContainer.select) {
      return;
    }
    if (this.disabled) {
      return;
    }
    this.resolve();
  }

  @HostListener('keydown', ['$event'])
  _handleKeyDown(event: KeyboardEvent) {
    const keyCode = event.keyCode;

    if (keyCode === ENTER || keyCode === SPACE) {
      {
        this._handleSelect(event);

      }
    } else if (keyCode === ESCAPE) {
      {
        const target = event.target as HTMLElement;
        target.blur();
      }
    }

  }

  resolve() {
    this._resolverSubscription.unsubscribe();
    this._resolverSubscription = this.resolver.getSelection$(this.dropContainer.select, this.dropContainer.model, this.dropContainer.selectData)
      .pipe(
        tap(
          selection => {
            if (!!selection) {
              this._dropChange.next(selection);
            }
          }
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._resolverSubscription.unsubscribe();
    this._dropChange.complete();
  }

  get dropChange(): Observable<any> {
    return this._dropChange.asObservable();
  }
}

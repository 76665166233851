import {RgiRxGlobalOverlayPositionStrategy} from '../rgi-ui-api';
import {GlobalPositionStrategy, Overlay} from '@angular/cdk/overlay';

/**
 * Convert the RgiRxGlobalOverlayPositionStrategy to CDK GlobalPositionStrategy
 * @param config the configuration of the overlay
 * @param overlay the CDK overlay instance
 */
export function rgiRxGlobalOverlayConfigToCDKPosition(config: RgiRxGlobalOverlayPositionStrategy, overlay: Overlay): GlobalPositionStrategy {
  const positionStrategy = overlay.position().global();
  if (!!config.centerHorizontally) {
    positionStrategy.centerHorizontally(typeof config.centerHorizontally === 'string' ? config.centerHorizontally : undefined);
  }
  if (!!config.centerVertically) {
    positionStrategy.centerVertically(typeof config.centerVertically === 'string' ? config.centerVertically : undefined);
  }
  if (config.top) {
    positionStrategy.top(config.top);
  }
  if (config.right) {
    positionStrategy.right(config.right);
  }
  if (config.bottom) {
    positionStrategy.bottom(config.bottom);
  }
  if (config.left) {
    positionStrategy.left(config.left);
  }
  return positionStrategy;
}

import {TaxDetailAPI} from '../tax-detail';
import {DiscountDetailAPI, FlexDiscountParamAPI} from './discounts-api';
import {GenericEntity} from '../../common/entities';

export class PremiumDetailAPI {
  discounts: DiscountDetailAPI[];
  fractioningInterest: string;
  flexDiscounts: FlexDiscountParamAPI;
  gross: string;
  incidentals: string;
  net: string;
  ssn: string;
  taxable: string;
  taxes: string;
  taxesDetails: TaxDetailAPI[];
}

export class AssetPremiumDetailAPI extends GenericEntity {
  premiumDetails: PremiumDetailAPI;
  riskPremiumDetails: EntityPremiumDetail[];
}

export class EntityPremiumDetail extends GenericEntity {
  premiumDetails: PremiumDetailAPI;
}

export class AnnualPremiumAPI {
  policyPremiumDetails: PremiumDetailAPI;
  assetPremiumDetails: AssetPremiumDetailAPI[];
}

import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { EvaluatorProductI } from '../../../../evaluation/models';
import { EvaluatorInfoI } from '../../../../evaluation/models/evaluator-info';
import { EvalProductCfg, EvalProductsCfg } from '../../../../state/models/eval-product-cfg';
import { SurveyEvaluateState, SurveyEvaluateStateManagerService } from '../../../../state/state-manager-survey.module';
import { SurveyEvalInfoComponent } from '../survey-eval-info/survey-eval-info.component';
import { SurveyEvalProductComponent } from '../survey-eval-product/survey-eval-product.component';

@Component({
    selector: 'survey-eval-products',
    templateUrl: './survey-eval-products.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SurveyEvalProductsComponent {

    @Input() st: SurveyEvaluateState;

    @ViewChild(SurveyEvalInfoComponent, { static: true }) infoOverlay: SurveyEvalInfoComponent;
    @ViewChildren(SurveyEvalProductComponent) surveyProductComponent: QueryList<SurveyEvalProductComponent>;


    constructor(private _stMng: SurveyEvaluateStateManagerService<SurveyEvaluateState>) {

    }


    onClickInfoCoverage({covOrPkg, isPackage}, productCode: string) {
        this.showInfo(covOrPkg, isPackage, this.st.productsCfg.prodsCfgMap[productCode]);
    }


    onClickInfoProduct({product }: {product: EvaluatorProductI}) {
        this.showInfo(product, false, this.st.productsCfg.prodsCfgMap[product.code]);
    }


    onSelectProduct(product: EvaluatorProductI) {
        this._stMng.actionSelectProduct(product);
    }


    showHideContent(code: string) {
        this._stMng.actionToggleVisibilityLob(code);
    }


    protected showInfo(info: EvaluatorInfoI, isPackage: boolean, prodCfg: EvalProductCfg) {
        this.infoOverlay.info = info;

        this.infoOverlay.pkgPricing = isPackage ?
            prodCfg.pkgsCfg.pkgsMap[info.package.id + ''].pkgPricing :
            null;
        this.infoOverlay.open();
    }


    getProductByCode(code: string): EvaluatorProductI {
        return this.st.evaluatorProducts.find( prod => prod.code === code );
    }
}

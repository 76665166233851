export class BankAccountDetailsRequest {
  operazione: string;
  abi: string;
  cab: string;
  conto: string;
  tipoconto: string;
  impadb?: any;
  canale: string;
  iban: string;
  prodotto: string;
  compagnia: string;
  contraente: string;
  Numproposta: string;
  spt?: any;
  fraz?: string;

  constructor() {
  }
}

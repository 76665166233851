<div class="dragged">
    <div class="row title-header" style="padding:5px;">
        <div class="col-xs-6 col-md-6">
            <label ng-bind="label">
            {{ '_CLAIMS_._CLAIM_NUMBER' | translate }}: {{potPayFE.claimNumber}}
            </label>
        </div>
        <div class="col-xs-6 col-md-6">
            <label ng-bind="label">
                {{potPayFE.companyCode}} - {{potPayFE.company}}
            </label>
        </div>
        <!--         <div class="col-xs-4 col-md-4" style="color:grey;font-size: 16px;  font-weight: bold;">
            <table style="width: 100%;">
                <tr>
                    <td style="color:grey;font-size: 16px;  font-weight: bold;"> <label ng-bind="label"
                            style="margin-right: 1%; color:grey;">{{ '_CLAIMS_._POLICY_NUMBER' | translate }}:</label>
                    </td>
                    <td style="color:grey;font-size: 14px;  font-weight: bold;"> <label ng-bind="label"
                            style="margin-right: 1%; color:grey;"><label ng-bind="label">{{potPayFE.policyNumber}}
                            </label></label></td>
                    <td style="color:grey;font-size: 16px;  font-weight: bold;"> &nbsp;</td>



                    <td style="color:grey;font-size: 16px;  font-weight: bold;"><label ng-bind="label"
                            style="margin-right: 1%; color:grey;">{{ '_CLAIMS_._COMPLAINT_DATE' | translate }}:</label>
                    </td>
                    <td style="color:grey;font-size: 14px;  font-weight: bold;"><label ng-bind="label"
                            style="color:grey;">{{potPayFE.complaintDate| date:'dd/MM/yyyy'}} </label>
                    </td>



                </tr>
                <tr>
                    <td style="color:grey;font-size: 16px;  font-weight: bold; "><label ng-bind="label"
                            style="margin-right: 1%; ">{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}:</label></td>
                    <td style="color:grey;font-size: 14px;  font-weight: bold;"><label
                            ng-bind="label">{{potPayFE.lossDate| date:'dd/MM/yyyy'}} </label></td>
                    <td style="color:grey;font-size: 16px;  font-weight: bold;"> &nbsp;</td>


                    <td style="color:grey;font-size: 16px;  font-weight: bold; "><label ng-bind="label"
                            style="margin-right: 1%; "
                            *ngIf="potPayFE.effectiveLossDate">{{ '_CLAIMS_._EFFECTIVE_COMPLAINT_DATE' | translate }}:</label>
                    </td>
                    <td style="color:grey;font-size: 14px;  font-weight: bold;"><label ng-bind="label"
                            *ngIf="potPayFE.effectiveLossDate">{{potPayFE.effectiveLossDate| date:'dd/MM/yyyy'}}
                        </label></td>


                    <td style="color:grey;font-size: 16px;  font-weight: bold;"> &nbsp;</td>
                </tr>
            </table>
        </div> -->
    </div>
    <claims-new-payment-progress [currentStep]="currentStep"></claims-new-payment-progress>
</div>
<br>
<claims-general-data [potPayFE]="potPayFE"></claims-general-data>
<form [formGroup]="newPayForm">
    <!-- <div class="details-container"> -->
    <div class="top-banner">
        <div class="card shadow table-white">
            <div class="row colorBlu14" style="vertical-align: middle;">
                <label ng-bind="label" style="color:white;  height:45px; width: 100%; 
                                         font-size: 20px;  font-weight: bold; vertical-align: middle;">
                    &nbsp;&nbsp;{{ '_CLAIMS_._EVALUATION' | translate }}
                    <button class="btn pull-right colorBlu14" (click)="settlementList($event)"
                        style="margin:2px 4px 1px; color:White; border-color:white; font-size: 14px; ">{{ '_CLAIMS_._TYPOLOGIES_SETTLEMENT_LIST' | translate }}</button>
                    &nbsp;&nbsp;
                </label>
            </div>
        </div>
    </div>
    <pnc-accordion [closeOthers]="false" [destroyOnHide]="false" #pncAccordion="AccordionComponent">
        <pnc-panel id="payment-data">
            <ng-template pncPanelTitle class="panel-header">
                <claims-editable-panel-title enabled="true" title="{{ '_CLAIMS_._PAYMENT_DATA' | translate }}"
                    panelId="payment-data" hideModifyBeforeComplete="true" hideModifyOnDisable="true"
                    [pncAccordion]="pncAccordion" style="width: 100%;">
                </claims-editable-panel-title>
            </ng-template>
            <ng-template pncPanelContent>
                <claims-payment-data panelId="payment-data" [card]="card" nextPanelId="quote-evaluation"
                    acceptSignals="true" (stepperEventEmitter)="progress($event)" (saveEmitter)="saveAvailable($event)"
                    [potPayFE]="potPayFE" [newPayForm]="newPayForm" [valueBar]="valueBar" 
                    [exposuresLoaded] = "potPayFE.exposuresLoaded" [receiptTypeLoaded] = "potPayFE.receiptTypeLoaded">
                </claims-payment-data>
            </ng-template>
        </pnc-panel>

        <pnc-panel id="quote-evaluation" disabled="true">
            <ng-template pncPanelTitle class="panel-header">
                <claims-editable-panel-title title="{{ '_CLAIMS_._CALCULATION_OF_THE_INSURED_FEE' | translate }}"
                    panelId="quote-evaluation" hideModifyOnDisable="true" [pncAccordion]="pncAccordion">
                </claims-editable-panel-title>
            </ng-template>
            <ng-template pncPanelContent>
                <claims-quote-evaluation panelId="quote-evaluation" [card]="card" nextPanelId="beneficiary-payee"
                    (stepperEventEmitter)="progress($event)" acceptSignals="true"
                    [potPayFE]="potPayFE" [newPayForm]="newPayForm">
                </claims-quote-evaluation>
            </ng-template>
        </pnc-panel>

        <pnc-panel id="beneficiary-payee" disabled="true">
            <ng-template pncPanelTitle class="panel-header">
                <claims-editable-panel-title title="{{ '_CLAIMS_._BENEFICIARIES_AND_PAYEES' | translate }}"
                    panelId="beneficiary-payee" hideModifyOnDisable="true" [pncAccordion]="pncAccordion">
                </claims-editable-panel-title>
            </ng-template>
            <ng-template pncPanelContent>
                <claims-beneficiary-payee panelId="beneficiary-payee" nextPanelId="payment-method"
                    (stepperEventEmitter)="progress($event)" acceptSignals="true"
                    [potPayFE]="potPayFE" [card]="card" [pncAccordion]="pncAccordion">
                </claims-beneficiary-payee>
            </ng-template>
        </pnc-panel>

        <pnc-panel id="payment-method" disabled="true">
            <ng-template pncPanelTitle class="panel-header">
                <claims-editable-panel-title title="{{ '_CLAIMS_._METHODS_OF_PAYMENT' | translate }}" panelId="payment-method"
                    hideModifyBeforeComplete="true" hideModifyOnDisable="true" [pncAccordion]="pncAccordion"
                    style="width: 100%;">
                </claims-editable-panel-title>
            </ng-template>
            <ng-template pncPanelContent>
                <claims-payment-method panelId="payment-method" [card]="card" nextPanelId="document-choice"
                    (stepperEventEmitter)="progress($event)" (saveEmitter)="saveAvailable($event)" acceptSignals="true"
                    [potPayFE]="potPayFE" [pncAccordion]="pncAccordion">
                </claims-payment-method>
            </ng-template>
        </pnc-panel>

        <pnc-panel id="document-choice" disabled="true">
            <ng-template pncPanelTitle class="panel-header">
                <claims-editable-panel-title title="{{ '_CLAIMS_._LETTERS_MANAGEMENT' | translate }}" panelId="document-choice"
                    hideModifyBeforeComplete="true" hideModifyOnDisable="true" [pncAccordion]="pncAccordion"
                    style="width: 100%;">
                </claims-editable-panel-title>
            </ng-template>
            <ng-template pncPanelContent>
                <claims-document-choice panelId="document-choice" [card]="card" nextPanelId="damage-category"
                    (stepperEventEmitter)="progress($event)" (saveEmitter)="saveAvailable($event)" acceptSignals="true"
                    [potPayFE]="potPayFE" [newPayForm]="newPayForm" [pncAccordion]="pncAccordion">
                </claims-document-choice>
            </ng-template>
        </pnc-panel>

    </pnc-accordion>

    <div [ngClass]="classMsg" *ngIf="msgPresent" role="alert">{{ msg | translate }} </div>
    <div class="btn-group btn-group-justified btn-group-justified-claim">
        <div class="btn-group">
            <button (click)="save()" class="btn btn-warning btn-secondary pull-right text-uppercase"
                [disabled]="saveDisabled" *ngIf="exitDisabled" translate>{{'_CLAIMS_._BUTTONS_._SAVE' | translate}}</button>
            <button (click)="exit()" class="btn btn-warning btn-secondary pull-right text-uppercase"
                *ngIf="!exitDisabled" >{{'_CLAIMS_._BUTTONS_._EXIT' | translate}}</button>
        </div>
    </div>
</form>

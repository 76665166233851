export enum VehicleType {

  // Codici Tipo Veicolo ANIA

  A = 'Autoveicolo',
  B = 'Autobus',
  C = 'Autocarro',
  M = 'Motoveicolo',
  Q = 'Motocarro',
  R = 'Rimorchi',
  T = 'Trattici',
  S = 'Macchine agricole',
  X = 'Targhe prova',
  Y = 'Targhe provvisorie',
  W = 'Ciclomotore'

}

import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { EvaluatorObjectI } from '../../../../evaluation/models';
import { EvalCovCfg, EvalPkgCfg, EvalValidationItem } from '../../../../state/state-manager-survey.module';


@Component({
    selector: 'survey-eval-coverage',
    templateUrl: './survey-eval-coverage.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SurveyEvalCoverageComponent implements OnInit {

    @Input("coverage") coverage: EvaluatorObjectI;
    @Input("editable") isEdit: boolean = false;
    @Input("isPackage") isPackage: boolean = false;
    @Input() validation: EvalValidationItem;
    @Input() cfg: EvalCovCfg|EvalPkgCfg;


    @Output("clickInfo") onClickInfoEmitter = new EventEmitter<{ covOrPkg: EvaluatorObjectI, isPackage: boolean }>();
    @Output("select") onSelectEmitter = new EventEmitter<{ covOrPkg: EvaluatorObjectI, isPackage: boolean, selected: boolean }>();


    dataQaType: string;
    price: number;
    showPrice: boolean = false;


    constructor() { }


    ngOnInit() {
        this.dataQaType = (this.isPackage ? 'package ' : 'coverage ') + this.coverage.name;

        this.price = this.cfg.pricing.price.value;
        this.showPrice = this.price > 0;
    }


    onClickInfo() {
        const eventData = {
            covOrPkg: this.coverage,
            isPackage: this.isPackage
        }
        this.onClickInfoEmitter.emit(eventData);
    }


    onSelect(selected:boolean) {
        this.onSelectEmitter.emit({ covOrPkg: this.coverage, isPackage: this.isPackage, selected});
    }


    isDisabled() {
        return !this.isEdit || !this.cfg.editable;
    }

}

import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

/**
 * @author: dmasone
 * @description: Used for custom date format
 */
@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  public parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');

      const dateObj: NgbDateStruct = { day: null as any, month: null as any, year: null as any };
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || null as any;
      });
      return dateObj;
    }
    return null;
  }

  public format(date: NgbDateStruct | null): string {
    return date ?
      `${padNumber(date.day)}/${padNumber(date.month)}/${date.year || ''}` :
      '';
  }
}


@Injectable({
  providedIn: 'root'
})
export class GroupPolicyDateAdapter extends NgbDateAdapter<string> {

  public toApi(isoString: string): string {
    if (!isoString || isoString === '') {
      return null;
    } else {
      const d = new Date(isoString);
      return d.getFullYear() + '-' + (d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
        '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
    }
  }

  public fromModel(value: string | null): NgbDateStruct | null {
    if (value != null) {
      const d = new Date(value);
      return {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
    }
    return null;
  }

  public toApiNotStd(isoString: string): string {
    if (!isoString || isoString === '') {
      return null;
    }
    const d = new Date(isoString);
    return (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) + '-' + (d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
      '-' + d.getFullYear();
  }

  public fromModelNotStd(value: string | null): string | null {
    if (value != null) {
      const dateParts = value.split('/');
      return (`${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`);
    }
    return null;
  }

  public fromModelNotStdRX(value: string | null): Date | null {
    if (value != null) {
      const dateParts = value.split('/');
      return new Date(`${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`);
    }
    return null;
  }

  public toModel(date: NgbDateStruct | null): string | null {
    if (!date) {
      return null;
    }
    const d = new Date(date.year, date.month - 1, date.day);
    return d.toISOString();
  }

}

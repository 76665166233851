<ng-container [formGroup]="form">
  <div class="rgi-gp-flex-row">
    <div class="rgi-gp-field-container" *ngFor="let paymentField of fields">
      <div class="rgi-gp-field">
        <rgi-rx-form-field [attr.data-qa]="paymentField.name">

          <label rgiRxLabel>
            <span>{{paymentField?.label}}</span>
            <span *ngIf="paymentField?.mandatory" class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*</span>
          </label>

          <div *ngIf="paymentField.type === 'L';then select else input"></div>

          <ng-template #input>
            <div *ngIf="paymentField.name === 'dcardexpire';then cardExpire else text"></div>
            <ng-template #cardExpire>
              <div class="rgi-gp-flex">
                <input type="number" class="rgi-gp-w-50" rgiRxInput formControlName="{{paymentField.name + 'month'}}"
                       placeholder="mm">
                <input type="number" class="rgi-gp-w-50" rgiRxInput formControlName="{{paymentField.name + 'year'}}"
                       placeholder="yyyy">
              </div>

            </ng-template>
            <ng-template #text>
              <input rgiRxInput formControlName="{{paymentField.name}}">
            </ng-template>
          </ng-template>

          <ng-template #select>
            <select rgiRxNativeSelect formControlName="{{paymentField.name}}">
              <option value=""></option>
              <option *ngFor="let value of paymentField.values" [value]="value.id">{{value.description}}</option>
            </select>
          </ng-template>
          <div *ngIf="paymentField?.mandatory" class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>

        </rgi-rx-form-field>
      </div>
    </div>
  </div>
</ng-container>

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsService {

  constructor() {}

  generateWarningMessage(nomeCognome: string, hasHits: boolean): string {
    if (hasHits) {
      return `Nice Actimize has identified potential risks for the party ${nomeCognome}. Please check him/her in Nice Actimize system`;
    } else {
      return `Nice Actimize has identified no risks on the party ${nomeCognome}`;
    }
  }

  // Metodo per ottenere il warning aggiuntivo in base al valore di hasHits
  getAuthorizationWarning(nomeCognome: string, hasHits: boolean): Observable<string> {
    const warningMessage = this.generateWarningMessage(nomeCognome, hasHits);
    return new Observable<string>(observer => {
      observer.next(warningMessage);
      observer.complete();
    });
  }

}

import {State} from '@rgi/rx/state';
import {GpProposalAuthorization} from '../group-policy-models/group-policy-authorization';
import {GPCluster, GPLightCluster} from '../group-policy-models/group-policy-cluster';
import {GPIdentificationEntity} from '../group-policy-models/group-policy-domain-types';
import {ContractDetails, InquiryNavigationInfo, MenuOperation} from '../group-policy-models/group-policy-inquiry';
import {IssuePolicyData} from '../group-policy-models/group-policy-issue-confirm';
import {
  Commission,
  GPAsset,
  GpAssetUnitsClauses,
  LinkedPolicyQuotation,
  Surveys
} from '../group-policy-models/group-policy-issue-guarantees';
import {Agency, Company} from '../group-policy-models/group-policy-issue-home';
import {
  BaseEntity,
  CoinsuranceShareList,
  ContractAddressesResp,
  ErrorCode,
  ErrorResp,
  Factor,
  GPClause,
  GPExtensionDataSection,
  GpListValues,
  GPPolicyDataField,
  GroupPolicyContactsManagement,
  Indexing,
  MeanOfPayment,
  PartyRoleState,
  PaymentConfig,
  PaymentFrequency,
  PortfolioContactsManagement,
  SubjectsRoleState
} from '../group-policy-models/group-policy-issue-policy-data';
import {ButtonsAction, Documents, Summary} from '../group-policy-models/group-policy-issue-summary';
import {NextRouteResponse} from '../group-policy-models/group-policy-vcont-date';
import {SubcauseModel} from '../group-policy-models/group-policy-vcont-variation';

export class GroupPolicyStateConfigurationPm extends State {
  factors: Factor[];
  proposalNumber: string;
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
}

export class GroupPolicyStateVcontConfirms extends State {
  documents: Documents[];
  steps: Array<string> = [];
  labels: Array<string> = [];
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
}

export class GroupPolicyStateVcontDate extends State {
  uuid: string;
  factors: Factor[];
  steps: Array<string> = [];
  labels: Array<string> = [];
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
}

export class GroupPolicyStateEdit extends State {
  errors: ErrorResp[] = [];
  type = ErrorCode.BLOCKING;
}

export class GroupPolicyStateCluster extends State {
  constructor(id: string, resId: string, nodeId: string, idParentSession: string, codeAsset: string, clusters: GPLightCluster[]) {
    super(id);
    this.codeAsset = codeAsset;
    this.clusters = clusters;
    this.resId = resId;
    this.nodeId = nodeId;
    this.idParentSession = idParentSession;
  }
  resId: string;
  nodeId: string;
  idParentSession: string;
  codeAsset: string;
  editableCluster: EditableCLuster;
  openedClusters = new Array<{ cluster: GPCluster, clauses: GpAssetUnitsClauses[] }>();
  classes: BaseEntity[];
  uses: BaseEntity[];
  sectors: BaseEntity[];
  extensions: BaseEntity[];
  clusters: GPLightCluster[];
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
  proposalNumber?: string;
  idMovement?: string;
}

export class EditableCLuster {
  isPartyEdited: boolean;
  cluster: GPCluster;
  clauses: GpAssetUnitsClauses[];
  commissions: Commission[];
}

export class GroupPolicyStateGuarantees extends State {
  units: GPAsset[];
  assets: GPAsset[];
  availableAssets: BaseEntity[];
  assetUnitClauses: GpAssetUnitsClauses[];
  unitInitialState: BaseEntity[];
  proposalNumber: string;
  commissions: Commission[];
  quotations: LinkedPolicyQuotation;
  questionnaires: Surveys[];
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
  errorsCommissions: string[] = [];
  typeCommissions: ErrorCode = ErrorCode.BLOCKING;
  productCategory: GPIdentificationEntity;
  isClusterContract: boolean;

}

export class GroupPolicyStateHome extends State {
  nodes: Agency[] = [];
  companies: Company[] = [];
  products: any[];
  issueDate: string;
  effectiveDate: string;
  selectedNode: string;
  selectedCompany: string;
  selectedProduct: string;
  targetId: number | undefined;
  productTypeCode: string | undefined;
  codeSP: string | undefined;
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
}

export class GroupPolicyStateInquiry extends State {
  contractDetails: ContractDetails;
  contractMenuOperations: MenuOperation[];
  inquiryMode: string | undefined;
  authorizationData: GpProposalAuthorization;
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
  isManualPolicyNumEnabled: boolean;
  manualPolicyNumber: string;
}

export class GroupPolicyStateIssueConfirm extends State {
  contractData: IssuePolicyData;
}

export class GroupPolicyStatePolicyData extends State {
  paymentFrequencies: PaymentFrequency;
  factors: Factor[];
  conventions: GpListValues;
  allRoleParties: SubjectsRoleState<PartyRoleState>;
  editablePayments: PaymentConfig;
  selectablePayments: MeanOfPayment[];
  proposalNumber: string;
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
  contractAddressesResp: ContractAddressesResp;
  groupPolicyContactsManagement = GroupPolicyContactsManagement.NONE;
  portfolioContactsManagement = PortfolioContactsManagement.NONE;
  fromInquiryInfo = {} as InquiryNavigationInfo;
  disablePolicyHolder: boolean;
  coinsurances: CoinsuranceShareList;
  indexing: Indexing;
  clauses: GPClause[];
  policyDataFields: GPPolicyDataField[];
  policyDataExtProp: GPIdentificationEntity[];
  extensionData: GPExtensionDataSection[];
}

export class GroupPolicyStateProposalIssue extends State {
  errors: ErrorResp[] = [];
  type = ErrorCode.BLOCKING;
}

export class GroupPolicyStateVcontQuestionnaire extends State {
  documents: Documents[];
  nextRoute: NextRouteResponse;
  questionnaires: Surveys[];
  steps: Array<string> = [];
  labels: Array<string> = [];
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
}

export class GroupPolicyStateSummary extends State {
  summary: Summary;
  policyNumber: string;
  proposalNumber: string;
  summaryActions: ButtonsAction[];
  quotations: LinkedPolicyQuotation;
  documents: Documents[];
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
  editablePayments: PaymentConfig;
  selectablePayments: MeanOfPayment[];
  authorizationData: GpProposalAuthorization;
  isManualPolicyNumEnabled: boolean;
}

export class GroupPolicyStateVcontVariation extends State {
  documents: Documents[];
  showSubcauses: boolean;
  nextRoute: NextRouteResponse;
  allRoleParties: SubjectsRoleState<PartyRoleState>;
  editablePayments: PaymentConfig;
  selectablePayments: MeanOfPayment[];
  firstSubjectContract: PartyRoleState;
  subcause: SubcauseModel;
  subcauses: SubcauseModel[];
  steps: Array<string> = [];
  labels: Array<string> = [];
  errors: ErrorResp[] = [];
  type: ErrorCode = ErrorCode.BLOCKING;
}

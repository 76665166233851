import { Warning } from './warning';
import { Event } from './event';

export class Authorization {

    public id: string;
    public requestCode: string;
    public nodeCode: string;
    public policyNumber: string;
    public proposalNumber: string;
    public workflowState: any;
    public state: any;
    public derogationLevel: string;
    public requestDate: Date;
    public responseDate: Date;
    public requestUser: any;
    public requestNode: any;
    public requestType: any;
    public company: any;
    public subscriber: any;
    public product: any;
    public productType: any;
    public competentUser: any;
    public competentLevel: any;
    public lastEvent: any;
    public subsystem: any;
    public priority: any;
    public lastActionDate: Date;
    public note: string;
    public updatedSuccessfully: boolean;
    public errorMessage: string;
    public warnings: Array<Warning>;
    public events: Array<Event>;
    public acceptableEvents: Array<Event>;
    public unavailableEvents: Array<Event>;
    public authenticatedUserLevel: any;
    public authenticatedUserDerogationLevel: number;
    public actions: Array<string>;
    public operation: string;
    public effectiveDate: Date;
    public operationNote: string;
    public issueDate: Date;
    public issueUser: string;
    public operationCode: string;
    public operationType: string;
    public policyType: any;
    public masterPolicyNumber: string;
    public masterProposalNumber: string;
    public plate: string;
    constructor() { }
  }

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title">{{ '_LIMITS_MANAGEMENT' | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
            </h3>
        </div>
        <form [formGroup]="formGroup">
            <div class="modal-body" style="padding-top: 5px">
                <h4 *ngIf="!isEditMode" class="text-info bold">{{ '_CLAIMS_._NEW_LIMIT' | translate }}</h4>
                <h4 *ngIf="isEditMode" class="text-info bold">{{ '_CLAIMS_._EDIT_LIMIT' | translate }}</h4>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._DESCRIPTION' | translate }} <pnc-required required="true">
                            </pnc-required></label>
                        <input type="text" class="form-control" name="description"
                            attr.data-qa="limit-modal-description-input" formControlName="description" />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._AMOUNT' | translate }} (&euro;) <pnc-required required="true">
                            </pnc-required></label>
                        <input type="number" class="form-control" name="amount" attr.data-qa="limit-modal-amount-input"
                            formControlName="amount" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._LEVEL' | translate }} <pnc-required required="true"></pnc-required>
                        </label>
                        <select class="core-select form-control" formControlName="level" id="level" name="level"
                            attr.data-qa="limit-modal-category-input" (change)="onLevelIdChange($event)">
                            <option></option>
                            <option *ngFor="let level of levels; " value={{level.codice}}>
                                {{level.descrizione}}</option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._LEVEL_CODE' | translate }} <pnc-required required="true">
                            </pnc-required></label>
                        <select class="core-select form-control" formControlName="levelCode" id="levelCode"
                            name="levelCode" attr.data-qa="limit-modal-levelCode-input">
                            <option></option>
                            <option *ngFor="let levelCode of levelCodes; " value={{levelCode.codice}}>
                                {{levelCode.descrizione}}</option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._TYPOLOGY' | translate }} <pnc-required required="true">
                            </pnc-required></label>
                        <select class="core-select form-control" formControlName="typology" id="typology"
                            name="typology" attr.data-qa="limit-modal-typology-input"
                            (change)="onTypologyChange($event)">
                            <option></option>
                            <option *ngFor="let typology of typologies; " value={{typology.codice}}>
                                {{typology.descrizione}}</option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._REGIME' | translate }} <pnc-required required="true"></pnc-required>
                        </label>
                        <select class="core-select form-control" formControlName="regime" id="regime" name="regime"
                            attr.data-qa="limit-modal-regime-input">
                            <option></option>
                            <option *ngFor="let regime of regimes; " value={{regime.codice}}>
                                {{regime.descrizione}}</option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._SUBTYPOLOGY' | translate }} </label>
                        <select class="core-select form-control" formControlName="subtypology" id="subtypology"
                            name="subtypology" attr.data-qa="limit-modal-subtypology-input"
                            (change)="onSubtypologyChange($event)">
                            <option></option>
                            <option *ngFor=" let subtypology of subtypologies; " value={{subtypology.codice}}>
                                {{subtypology.descrizione}}</option>
                        </select>
                        <span class=" pull-right arrow-select-native rgifont pnc-select
                            pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._SUBTYPOLOGY_CODE' | translate }} <pnc-required
                                *ngIf="isSubtypologyCodeRequired()" required="true">
                            </pnc-required></label>
                        <select class="core-select form-control" formControlName="subtypologyCode" id="subtypologyCode"
                            name="subtypologyCode" attr.data-qa="limit-modal-subtypologyCode-input">
                            <option></option>
                            <option *ngFor="let subtypologyCode of subtypologyCodes; " value={{subtypologyCode.codice}}>
                                {{subtypologyCode.descrizione}}</option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12 form-check form-check-inline">
                        <input type="checkbox" class="btn-checkbox checkbox-unit" id="netGrossExcess"
                            formControlName="netGrossExcess" attr.data-qa="limit-modal-chekbox-input-netGrossExcess">
                        <label class="form-check-label checkbox-label">{{ '_CLAIMS_._GROSS_EXCESS' | translate }}</label>
                    </div>
                </div>
                <div *ngIf="errorMessage" class="content-error validate-error">
                    <span class="rgifont rgi-exclamation-triangle"></span>
                    <span>{{errorMessage | translate}}</span>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button class="btn btn-warning text-uppercase" (click)="onConfirm()"
                            data-qa="limit-modal-confirm-button">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
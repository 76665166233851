<div class="anag-w-100 anag-section-card" *ngIf="stateMgr.getState$() | async as state">
    <div class="anag-section-card-header">
      <span class="anag-section-card-icon rgi-ui-icon-stars-rating"></span>
      <span data-qa="tax-cl-badge" *ngIf="stateMgr.isRatingMandatory()" class="rgi-anag-badge" [ngClass]="{
            'rgi-ui-icon-confirmation rgi-anag-ok-badge': this.stateMgr.isRatingValid(),
            'rgi-ui-icon-warning rgi-anag-ko-badge': !this.stateMgr.isRatingValid()}"></span>
      <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._RATING_' | translate}}</span>
      <button class="anag-section-edit-button" data-qa="edit-rating" (click)="editRating()"
          *ngIf="canEditRating && state.editorModeEnable" [disabled]="state.blockedFlow">
          <span class="rgi-ui-icon-edit2 anag-edit-icon"></span>
        </button>
      <button *ngIf="!state.editorModeEnable" class="anag-section-edit-button" data-qa="rating-history" (click)="openModalRatingHistory()">
        <span class="rgi-ui-icon-history anag-edit-icon"> </span>
      </button>
    </div>
    <div class="anag-section-content">
        <div class="rgi-anag-w-100 rgi-ui-text-3 anag-section-label" *ngFor="let ratingData of ratingLabels">
            <div class="rgi-anag-flex">
              <span>{{ratingData.label | translate}}</span>
              <b class="anag-ml-m" [attr.data-qa]='ratingData.dataQa'>{{ ratingData.value }}</b>
            </div>
            <hr class="anag-section-separator">
        </div>
    </div>
  </div>


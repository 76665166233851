import {PostSalesOperation} from '../api/rgi-pnc-postsales.api';
import {
  PNC_POSTSALES_OPERATIONS_CODE,
  PNC_POSTSALES_OPERATIONS_TYPES
} from '../resources/enums/pnc-postsales-operations-types';
import {PncDomainService} from '../resources/http/pnc-domain.service';
import {API_PREFIX_PORTFOLIO} from '../resources/constants/rest-api-conf';
import {PncPsalesConfirmationStateManager} from '../state-managers/confirmation/confirmation-state-manager.service';
import {
  PncPsalesSummaryStateManagerVariations
} from '../ops-managers/variations/summary/summary-state-manager-variations';
import {RGI_PNC_POSTSALES_STEPS} from '../resources/constants/steps';
import {
  PncPsalesRequiredDataPolicyTransferStateManager
} from '../ops-managers/variations/required-data/required-data-policy-transfer-state-manager';
import {RgiRxUserService} from '@rgi/rx/auth';
import {OperatorLight} from '../resources/model/api/operator-light';
// Trasferimento polizza - Transfer policy
export const PNC_PSALES_VD0007: PostSalesOperation = {
  type: PNC_POSTSALES_OPERATIONS_TYPES.ASSETS_DATA_AMENDMENTS,
  operationCode: PNC_POSTSALES_OPERATIONS_CODE.POLICY_TRANSFER,
  sessionTitle: '_PCPSALES_._SESSION_TITLE_._POLICY_TRANSFER_',
  steps: [
    {
      step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
      manager: PncPsalesRequiredDataPolicyTransferStateManager,
      deps: [PncDomainService, RgiRxUserService<OperatorLight>],
      stepLabel: '_PCPSALES_._STEPS_._POLICY_TRANSFER_DATA_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
      manager: PncPsalesSummaryStateManagerVariations,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
      manager: PncPsalesConfirmationStateManager,
      deps: [],
      stepLabel: ''
    }
  ]
};

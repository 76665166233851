import {GenericEntityPcPortfolio} from '../pc-portfolio-models/genericEntityPcPortfolio';

export class CoInsuranceDTO {
  coinsuranceType: GenericEntityPcPortfolio;
  ourPerc: string;
  coinsuranceQuotas: CoInsuranceQuota[] = [];
}

export class CoInsuranceQuota {
  objectId: string;
  policyNumber: string;
  company: Company;
  intermediary: Company;
  commissionsPerc: string;
  quotaPerc: string;
}

export class Company {
  code: string;
  identification: string;
  description: string;
}

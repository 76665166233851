import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcSubjectComponent } from './lpc-subject.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';

@NgModule({
    declarations: [LpcSubjectComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
    ],
    exports: [LpcSubjectComponent]
})
export class LpcSubjectModule { }

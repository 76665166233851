import {PartyAsset, PartyRequest} from './../../models/domain-models/party-asset';
import {HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, share} from 'rxjs/operators';
import {Inquiry} from '../../models/domain-models/inquiry';

import {PolicyContacts} from '../../models/domain-models/policy-contacts';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {PolicyAddress} from '../../models/domain-models/policy-address';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {CheckPartyComplete} from '../../models/domain-models/check-party-complete';
import {ErrorManagementService} from '../../re-issue-core/error-management.service';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {
  REST_API_CONTACT_CONF,
  REST_API_POLICY_DATA_CONF, REST_API_PORTFOLIO_PC_CONF, REST_API_SUBJECT_CONF,
  REST_API_VERIFY_CONF
} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueGeneralDataResourceService {
  private SUBJECT_PATH = '/parties'; // FOR MOCK SERVICE ONLY
  private INQUIRY_PATH = '/banca/inquirySportello';
  private DISABLE_ERR_INTERCEPT_HEADER = 'X-Disable-Error-Interceptor';
  private apiPolicyDataConf: any;
  private apiContactConf: any;
  private apiSubjectConf: any;
  private apiVerifyConf: any;
  protected apiPcPortfolioConf: any;

  constructor(
    protected http: RgiRxHttpClientWrapper,
    protected errorHandler: ErrorManagementService,
    @Inject('environment') protected apiConf: any,
  ) {
    this.apiPolicyDataConf = REST_API_POLICY_DATA_CONF;
    this.apiContactConf = REST_API_CONTACT_CONF;
    this.apiSubjectConf = REST_API_SUBJECT_CONF;
    this.apiVerifyConf = REST_API_VERIFY_CONF;
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;
  }

  put(resourceId: string, data: PartyRequest): Observable<PartyAsset> {
    return this.http.put<PartyAsset>(this.subjectUri(resourceId), data).pipe(
      share()
    );
  }

  protected subjectUri(resourceId: string): string {
    return this.apiConf.api.portal.path + this.apiPolicyDataConf.path + resourceId + this.SUBJECT_PATH;
  }

  validateBranchGet(sportello: string): Observable<Inquiry> {
    return this.http.get<Inquiry>(this.validateBranchUri(sportello));
  }

  protected validateBranchUri(sportello: string): string {
    return this.apiConf.api.portal.path + this.apiPolicyDataConf.pathV2 + this.INQUIRY_PATH + '?spo=' + sportello + '&canale=';
  }

  putContacts(subject: AnagIssueSubject, resourceId: string): Observable<PolicyContacts> {
    let address = new AddressApiContacts();

    if (subject.residence.country) {
      const stateAddress = subject.residence;
      address.city = stateAddress.city;
      address.country = new GenericEntity('', stateAddress.country.codice, stateAddress.country.descrizione);
      address.adminLevel1 = stateAddress.adminLevel1;
      address.adminLevel1Short = stateAddress.adminLevel1Short;
      address.adminLevel2 = stateAddress.adminLevel2;
      address.adminLevel2Short = stateAddress.adminLevel2Short;
      address.adminLevel3 = stateAddress.adminLevel3;
      address.adminLevel3Short = stateAddress.adminLevel3Short;
      address.cap = stateAddress.cap;
      address.formatAddress = stateAddress.formatAddress;
      address.number = stateAddress.number;
      address.placeAddress = stateAddress.placeAddress;
      address.province = new GenericEntity('', stateAddress.province.codice, stateAddress.province.descrizione);
      address.toponym = new GenericEntity('', stateAddress.toponym.codice, stateAddress.toponym.descrizione);
      address.locality = stateAddress.locality;
      address.fraction = stateAddress.fraction;
      address.countryCode = stateAddress.countryCode;
      address.normalized = false;
    } else {
      address = null;
    }
    const policyAddress = new PolicyAddress(address, true, null);

    const body: PolicyContacts =
      new PolicyContacts([], [], [], Array(policyAddress), subject.mobilePhone[0].number, subject.emails[0], null, null);

    return this.http.put<PolicyContacts>(this.putContactsUri(resourceId), body);
  }

  protected putContactsUri(resourceId: string): string {
    return this.apiConf.api.portal.path + this.apiContactConf.path + resourceId + this.apiContactConf.contactPath;
  }

  putSubjects(subject: any) {
    // TODO: fix response type
    return this.http.put<any>(this.getUriSubjects(subject.objectId, subject.node.identification), {subject});
  }

  getUriSubjects(subjectId: string, nodeId: string): string {
    return this.apiConf.api.portal.path + this.apiSubjectConf.path + subjectId + '?idNode=' + nodeId;
  }

  verifyBankProduct(sportello: string, fiscalCode: string) {
    return this.http.post<any>(this.verifyBankProductUri(sportello, fiscalCode), null);
  }

  verifyBankProductUri(sportello: string, fiscalCode: string): string {
    return this.apiConf.api.portal.path + this.apiVerifyConf.v2 +
      this.apiVerifyConf.banca + this.apiVerifyConf.verificaProdottoBanca
      + '?CodSportello=' + sportello + '&CodFiscIva=' + fiscalCode;
  }

  checkPartyComplete(body: CheckPartyComplete) {
    const httpOptions = {
      headers: new HttpHeaders().set(this.DISABLE_ERR_INTERCEPT_HEADER, 'true')
    };
    return this.http.post<any>(this.checkPartyCompleteUri(), body, httpOptions).pipe(
      catchError(this.errorHandler.handleError)
    );
  }

  checkPartyCompleteUri(): string {
    return this.apiConf.api.portal.path + this.apiVerifyConf.v2 +
      this.apiPcPortfolioConf.path + 'anag/partycompleted';
  }

}

import { Component, OnInit, Input, OnChanges, AfterViewInit } from '@angular/core';
import { Field } from '../../../dto/claim/field';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'claims-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss']
})
export class DynamicFieldComponent implements OnInit, AfterViewInit {

  constructor() { }

  @Input() field: Field;
  @Input() formGroup: UntypedFormGroup;
  @Input() disableAll: boolean;

  step: number;

  ngOnInit() {
    this.step = this.calculateStep();
  }

  ngAfterViewInit() {
    this.field.enableStatusChange.subscribe((event: boolean) => {
      if (this.formGroup.get(this.field.id)) {
        if (event) {
          this.formGroup.get(this.field.id).enable();
        } else {
          this.formGroup.get(this.field.id).disable();
        }
      }
    });
  }

  calculateStep(): number {
    const numberofdecimals = +this.field.numberofdecimals;
    let step = 1;

    for (let i = 0; i < numberofdecimals; i++) {
      step = step / 10;
    }
    return step;
  }


}

import {QuestionnaireFlatI} from './../../../models/domain-models/questionnaire-flatI';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '@rgi/rx/ui';
import {
  ReIssueQuestionnaireModalComponent
} from '../re-issue-quotation-modal/re-issue-questionnaire-modal/re-issue-questionnaire-modal.component';
import {take} from 'rxjs/operators';
import {
  QuestionFlateResponse
} from '../../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {ReIssueQuestionnaireUtilityService} from '../../re-issue-utility/re-issue-questionnaire-utility.service';

@Component({
  selector: 're-issue-questionnaires',
  templateUrl: './re-issue-questionnaires.component.html'
})
export class ReIssueQuestionnairesComponent implements OnInit {
  questionnaireFlat: QuestionnaireFlatI;

  @Input() questionnaire: QuestionFlateResponse;
  @Input() questionnaireValid: boolean;
  @Input() questionnaireComplete: boolean;
  @Input() questionnaireAdequate: boolean;
  @Input() questionnaireSubmitted: boolean;

  @Output() saving: EventEmitter<QuestionnaireFlatI> = new EventEmitter();
  @Output() questionnaireValidChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    protected customModalService: ModalService,
    protected questionnaireUtilityService: ReIssueQuestionnaireUtilityService
  ) {

  }

  ngOnInit() {
  }

  openQuestionnaireModal() {
    const {modal, component} = this.customModalService.openComponent(ReIssueQuestionnaireModalComponent);

    component.questionnaireFlat = this.questionnaireUtilityService.convertPcQuestionnaireToQuestionnaireFlatI(this.questionnaire);

    modal.onClose.pipe(take(1)).subscribe(() => {

    });
  }
}

import {GenericEntity} from './generic-entity';
import {Variable} from './variable';
import {Risk} from './risk';

export class Unit extends GenericEntity {

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public selected: boolean,
    public enabled: boolean,
    public warrantyCode: string,
    public warrantyId: number,
    public risk: Risk,
    public variables: Array<Variable>,
    public open: boolean,
    public extendedDescription: string,
    public helpFile: string
  ) {
    super(id, code, description);
  }
}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Outcome } from '@rgi/digital-claims-common-angular';
import { ExtraInfo } from '../../dto/external-policy-extrainfo';
import { ExtraInfoHeader } from '../../dto/external-policy-extrainfo-header';
import { ExternalPortfolioExtrainfoService } from './external-portfolio-extrainfo.service';

@Component({
  selector: 'claims-external-portfolio-extrainfo',
  templateUrl: './external-portfolio-extrainfo.component.html',
  styleUrls: ['./external-portfolio-extrainfo.component.scss']
})
export class ExternalPortfolioExtrainfoComponent implements OnInit {

  public listExtraInfo: Array<ExtraInfo> = new Array<ExtraInfo>();
  public listExtraInfoHeader: Array<ExtraInfoHeader> = new Array<ExtraInfoHeader>();
  public listUnitRischi: Map<string, string> = new Map<string, string>();
  public arHeader: Array<string> = new Array<string>();
  public isConfermaDisabled: boolean = true;
  idDenuncia: string;
  selection: string;
  reqUnit: string;
  reqRischio: string;
  public isCompExtraInfoManage: ConstrainBooleanParameters;
  [testata: string]: any;



  @Input() set datiExtraInfo(datiExtraInfo) {
    this.idDenuncia = datiExtraInfo[0];
    this.isCompExtraInfoManage = datiExtraInfo[1];
  }


  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private externalPortfolioExtrainfoService: ExternalPortfolioExtrainfoService,
    private activeModal: NgbActiveModal,
    @Inject('eventService') private eventService: any,
  ) { }

  ngOnInit() {

    this.externalPortfolioExtrainfoService.getListExtrainfo(this.idDenuncia)
      .subscribe((retListExtraInfo: Array<Map<string, Array<ExtraInfo>>>) => {
        for (const mExtraInfo of retListExtraInfo) {
          Object.keys(mExtraInfo).forEach(key => {
            this.arHeader.push(key);
          });

          // for (const m_ExtraInfoKey of m_ExtraInfo.keys()) {
          //   this.ar_Header.push(m_ExtraInfoKey);
          // }
        }

        for (const mExtraInfoKey of this.arHeader) {
          for (const mExtraInfo of retListExtraInfo) {
            Object.keys(mExtraInfo).forEach(key => {
              if (mExtraInfoKey === key) {
                let arExtraInfo = new Array<ExtraInfo>();
                const extraIH = new ExtraInfoHeader();
                let descTestata = new Array();
                descTestata = key.split('|');
                extraIH.descrizione = 'Unit: ' + descTestata[0] + ' - Rischio: ' + descTestata[1];
                this.listExtraInfo = new Array<ExtraInfo>();
                let unit: string;
                let rischio: string;
                Object.values(mExtraInfo).forEach(value => {


                  arExtraInfo = value;
                  if (arExtraInfo != null && arExtraInfo.length > 0) {
                    for (const entExtraInfo of arExtraInfo) {
                      if (entExtraInfo.level >= 3) {
                        this.listExtraInfo.push(entExtraInfo);
                      } else if (entExtraInfo.level === 1) {
                        unit = entExtraInfo.code;
                      } else if (entExtraInfo.level === 2) {
                        rischio = entExtraInfo.code;
                      }
                    }
                  } else {
                    const voidExtraInfo = new ExtraInfo();
                    this.listExtraInfo.push(voidExtraInfo);
                  }
                });
                extraIH.listExtraInfo = new Array<ExtraInfo>();
                extraIH.listExtraInfo = this.listExtraInfo;
                this.listExtraInfoHeader.push(extraIH);
                this.listUnitRischi.set(key, unit + ';' + rischio);
                extraIH.chiave = key;
              }
            });
          }
        }
      });
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    this.eventPropagation.emit('chiusuraModale');
    return false;
  }




  onSelectionChange(selection: string, chiave: any) {
    this.selection = selection;
    const unitrischio: string = this.listUnitRischi.get(chiave);
    const aunitrischio: string[] = unitrischio.split(';');
    this.reqUnit = aunitrischio[0];
    this.reqRischio = aunitrischio[1];
    this.isConfermaDisabled = false;

  }

  confirmExtraInfo() {
    if (this.selection != null) {
      this.externalPortfolioExtrainfoService.updateExtraInfo(this.selection, this.reqUnit, this.reqRischio)
        .subscribe((response: Outcome) => {

          if (response.code === 'OK') {
            this.activeModal.close(response);
            this.eventPropagation.emit('OK');
            this.eventService.broadcastEvent('alertMsg', { varMsg: 'Extra info salvata correttamente' });
          } else if (response.code === 'KO') {
            this.eventService.broadcastEvent('alertMsg', { varMsg: 'Errore ' + response.descr });
            this.eventPropagation.emit('KO');
          }

        });
    }
  }

}

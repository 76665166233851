import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxLoggingModule} from '@rgi/rx';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RgiRxLoggingModule
  ],
  providers: []
})
export class RgiRxStatemanagerModule {
}

<ng-container
  *ngTemplateOutlet="isTextContent() ? textContent : templateContent; context: {$implicit: content}"></ng-container>

<ng-template #textContent let-content>
  <div class="rgi-ui-tooltip-text"  [style.visibility]="visibility" style="position: static">{{content}}</div>
</ng-template>

<ng-template #templateContent let-content>
  <div class="rgi-ui-tooltip-text" [style.visibility]="visibility"  style="position: static">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</ng-template>

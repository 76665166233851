import { Component, OnInit, Output, Input, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { ExternalPortfolioService } from '../../external-portfolio.service';
import { ExternalPolicy } from '../../dto/external-policy';
import { Subscription } from 'rxjs';
import { CacheService } from '../../cache.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ExternalPolicyWarrenty } from '../../dto/external-policy-warrenty';
import { ExternalPolicyAsset } from '../../dto//external-policy-asset';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { CardComponent, CardComponentService } from '@rgi/digital-claims-common-angular';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-external-portfolio-new',
  templateUrl: './external-portfolio-new.component.html',
  styleUrls: ['./external-portfolio-new.component.scss']
})
export class ExternalPortfolioNewComponent extends CardComponent implements OnInit, OnDestroy, RoutableComponent {
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  public errorMessage: string = '';
  public errore: boolean;
  public externalErrorMessage: string = '';
  public externalError: boolean;
  public classMsg: string = 'alert alert-danger';
  public newPolicy: ExternalPolicy = new ExternalPolicy();
  public newWarrent: ExternalPolicyWarrenty = new ExternalPolicyWarrenty();
  public newAsset: ExternalPolicyAsset = new ExternalPolicyAsset();
  private subscription: Subscription;
  session: any;
  card: any;
  public today: NgbDate;
  public companies: any;
  public provinces: any;
  public countries: any;
  public comunisAss: any;
  public comunisCnt: any;
  public capsCnt: any;
  public capsAss: any;
  authSrv: any;
  newPolicyForm: UntypedFormGroup;
  public outRangeVal: boolean;
  public attivaOutRangeS = '';
  public attivaOutRangeN = '';
  public attivaNomeAss = true;
  public attivaNomeCnt = true;
  public valueBar = 0;
  // public sexCnt: string;
  // public sexAss: string;
  // public attivaUomoCnt='';
  // public attivaDonnaCnt='';
  // public attivaAziendaCnt='';
  // public attivaUomoAss='';
  // public attivaDonnaAss='';
  // public attivaAziendaAss='';

  @Output() eventSent = new EventEmitter<Response>();

  constructor(
    @Inject('sessionService') session: any,
    cardComponentService: CardComponentService,
    @Inject('eventService') private eventService: any,
    @Inject('authService') authService,
    private cacheServ: CacheService,
    public formBuilder: UntypedFormBuilder,
    public translateService: RgiRxTranslationService,
    // private alertService: AlertService,
    private policyServ: ExternalPortfolioService) {
      super(cardComponentService, session);
      const today = new Date();
      this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
      this.session = session;
      this.card = cardComponentService;
      this.authSrv = authService;

      this.companies = this.authSrv.getOperator().visibleCompanies;

      if (this.companies) {
        this.companies = this.companies.filter(this.isCompanyExt);
      }

  }

  isCompanyExt(element, index, array) {
    return (element.aniaCode !== '142');
  }

  ngOnInit() {
    // this.getCompanies();
    this.getProvinces();
    this.getCountries();
    this.valOutRange('N');
    this.newPolicyForm = this.formBuilder.group({
      company: [, Validators.required],
      policyNumber: [, Validators.required],
      // reassWarrant: [],
      // reassQuote: [],
      effectDate: [, Validators.required],
      expirationDate: [, Validators.required],
      releaseDate: [],
      policyReplacedNumber: [],
      policyReplacementNumber: [],
      productCode: [],
      productDescription: [],
      agency: [],
      assetInsured: [],
      denomination: [, Validators.required],
      firstName: [],
      fiscalCodeVat: [, Validators.required],
      subjectCode: [, Validators.required],
      // birthDate: [],
      // gender: [],
      address: [],
      municipality: [],
      postalCode: [],
      province: [],
      denominationInsured: [],
      firstNameInsured: [],
      fiscalCodeVatInsured: [],
      subjectCodeInsured: [],
      // birthDateInsured: [],
      // genderInsured: [],
      addressInsured: [],
      municipalityInsured: [],
      postalCodeInsured: [],
      provinceInsured: [],
      policyCeiling: [, Validators.required],
      uncovered: [],
      miniDiscoveredValue: [],
      deductible: [],
      emissionDate: [],
      regAmm: [],
      suspensionDateFrom: [],
      suspensionDateTo: [],
      premiumFrom: [],
      premiumTo: [],
      ggMora: [],
      // outRange: [],
      policySplitting: [],
      branch: [],
      codTerm: [],
      riskObject: [],
      diaria: [],
      ggDiaria: [],
      assetInsuredType: ['ALTRO', ],
      country: [],
      countryInsured: []
    }
    // ,{
    //   validator: [
    //     this.validateComplaintDate('effectDate'),
    //     this.validateComplaintDate('expirationDate')
    //   ]
    // }
    );
    this.newPolicy.assetList =  new Array(1);
    this.newAsset.warrentyList = new Array(1);
    // this.sexAss = '';
    // this.sexCnt = '';
  }


  protected validateComplaintDate(controlName: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];

      if (control.value) {
        if (control.value instanceof Date) {
          if (control.value.getFullYear() - 1900 < 100) {
            control.setErrors({ invalidDate: true });
          }
        }
      } else {
        const validator: any = control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  confirmNewPolicy() {
    this.eventService.broadcastEvent('start-loader');
    this.valueBar = 20;
    this.newPolicy.company = this.newPolicyForm.controls.company.value;
    this.newPolicy.policyNumber = this.newPolicyForm.controls.policyNumber.value;
    // this.newPolicy.reassWarrant = this.newPolicyForm.controls.reassWarrant.value;
    // this.newPolicy.reassQuote  = this.newPolicyForm.controls.reassQuote.value;
    this.newPolicy.effectDate  = this.newPolicyForm.controls.effectDate.value;
    this.newPolicy.expirationDate = this.newPolicyForm.controls.expirationDate.value;
    this.newPolicy.suspensionDateTo = this.newPolicyForm.controls.suspensionDateTo.value;
    this.newPolicy.suspensionDateFrom = this.newPolicyForm.controls.suspensionDateFrom.value;
    this.newPolicy.premiumFrom = this.newPolicyForm.controls.premiumFrom.value;
    this.newPolicy.premiumTo = this.newPolicyForm.controls.premiumTo.value;
    this.newPolicy.releaseDate = this.newPolicyForm.controls.releaseDate.value;
    this.newPolicy.ggMora = this.newPolicyForm.controls.ggMora.value;
    this.newPolicy.outRange = this.outRangeVal.toString();
    this.newPolicy.policyReplacedNumber = this.newPolicyForm.controls.policyReplacedNumber.value;
    this.newPolicy.policyReplacementNumber = this.newPolicyForm.controls.policyReplacementNumber.value;
    this.newPolicy.productCode = this.newPolicyForm.controls.productCode.value;
    this.newPolicy.productDescription  = this.newPolicyForm.controls.productDescription.value;
    this.newWarrent.branch = this.newPolicyForm.controls.branch.value;
    this.newWarrent.codTerm = this.newPolicyForm.controls.codTerm.value;
    this.newWarrent.riskObject = this.newPolicyForm.controls.riskObject.value;
    this.newPolicy.diaria = this.newPolicyForm.controls.diaria.value;
    this.newPolicy.ggDiaria = this.newPolicyForm.controls.ggDiaria.value;
    this.newPolicy.agency = this.newPolicyForm.controls.agency.value;
    this.newPolicy.assetInsured = this.newPolicyForm.controls.assetInsured.value;
    this.newAsset.description = this.newPolicyForm.controls.assetInsured.value;
    this.newAsset.type = this.newPolicyForm.controls.assetInsuredType.value;
    this.newPolicy.denomination = this.newPolicyForm.controls.denomination.value;
    this.newPolicy.firstName   = this.newPolicyForm.controls.firstName.value;
    this.newPolicy.fiscalCodeVat = this.newPolicyForm.controls.fiscalCodeVat.value;
    this.newPolicy.subjectCode = this.newPolicyForm.controls.subjectCode.value;
    // this.newPolicy.birthDate   = this.newPolicyForm.controls.birthDate.value;
    this.newPolicy.policySplitting = this.newPolicyForm.controls.policySplitting.value;
    // this.newPolicy.gender = this.newPolicyForm.controls.gender.value;
    // this.newPolicy.gender = this.sexCnt;
    this.newPolicy.country     = this.newPolicyForm.controls.country.value;
    this.newPolicy.countryInsured     = this.newPolicyForm.controls.countryInsured.value;
    this.newPolicy.address     = this.newPolicyForm.controls.address.value;
    this.newPolicy.municipality = this.newPolicyForm.controls.municipality.value;
    this.newPolicy.postalCode  = this.newPolicyForm.controls.postalCode.value;
    this.newPolicy.stateCode   = this.newPolicyForm.controls.province.value;
    this.newPolicy.denominationInsured = this.newPolicyForm.controls.denominationInsured.value;
    this.newPolicy.firstNameInsured    = this.newPolicyForm.controls.firstNameInsured.value;
    this.newPolicy.subjectCodeInsured  = this.newPolicyForm.controls.subjectCodeInsured.value;
    this.newPolicy.fiscalCodeVatInsured = this.newPolicyForm.controls.fiscalCodeVatInsured.value;
    // this.newPolicy.birthDateInsured    = this.newPolicyForm.controls.birthDateInsured.value;
    // this.newPolicy.genderInsured = this.newPolicyForm.controls.genderInsured.value;
    // this.newPolicy.genderInsured = this.sexAss;
    this.newPolicy.addressInsured = this.newPolicyForm.controls.addressInsured.value;
    this.newPolicy.municipalityInsured = this.newPolicyForm.controls.municipalityInsured.value;
    this.newPolicy.postalCodeInsured   = this.newPolicyForm.controls.postalCodeInsured.value;
    this.newPolicy.stateCodeInsured    = this.newPolicyForm.controls.provinceInsured.value;
    this.newPolicy.policyCeiling = this.newPolicyForm.controls.policyCeiling.value;
    this.newPolicy.uncovered   = this.newPolicyForm.controls.uncovered.value;
    this.newPolicy.miniDiscoveredValue = this.newPolicyForm.controls.miniDiscoveredValue.value;
    this.newPolicy.deductible  = this.newPolicyForm.controls.deductible.value;
    this.newPolicy.emissionDate = this.newPolicyForm.controls.emissionDate.value;
    this.newPolicy.regAmm = this.newPolicyForm.controls.regAmm.value;

    this.errore = false;
    this.errorMessage = '';
    this.externalError = false;
    this.externalErrorMessage = '';
    this.classMsg = 'alert alert-danger';
    this.valueBar = 30;
    this.validazioneDati();
    this.validazioneDatiImporti();
    this.valueBar = 50;

    if (!this.errore) {
        this.newAsset.warrentyList[0] = this.newWarrent;
        this.newPolicy.assetList[0] = this.newAsset;
        this.valueBar = 70;
        this.subscription = this.policyServ.newPolicy(this.newPolicy).subscribe( (response: any) => {
        this.eventSent.emit(response);
        this.valueBar = 100;
        if ((response.status && response.status !== 200) || (response.retCode && response.retCode === 'ERRORE') ||
            (response.retCode && response.retCode !== '000')) {
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._UPLOAD_ERROR').subscribe(res=> {
            this.errorMessage=res + ': ' + (response.status ? response.status : '') + '  '
            + (response.retCode ? response.retCode : '') + '  ' + (response.errori ? response.errori : '') + '  '
            + (response.message ? response.message : '');
          }
          )
        } else if (response.retCode && response.retCode === '003') {
          this.externalError = true;
          this.externalErrorMessage = response.errori;
        } else {
          this.errore = true;
          this.classMsg = 'alert alert-success';
          this.errorMessage = 'Operation properly terminated';
        }
        this.eventService.broadcastEvent('stop-loader');
        // this.alertService.translatedMessage('Polizza esterna salvata');
      },
      (error: HttpErrorResponse) => {
        this.eventSent.emit(null);
        this.errore = true;
        this.errorMessage = error.message;
        this.valueBar = 100;
        this.eventService.broadcastEvent('stop-loader');
      });
    } else {
      this.valueBar = 0;
      this.eventService.broadcastEvent('stop-loader');
    }

  }

  valOutRange(code: string) {
    if (code === 'S') {
       this.attivaOutRangeS = 'active';
       this.attivaOutRangeN = '';
       this.outRangeVal = true;
    } else {
      this.attivaOutRangeS = '';
      this.attivaOutRangeN = 'active';
      this.outRangeVal = false;
    }
  }

  // valSex(type, sex) {
  //   if(type === 'CNT'){
  //     this.attivaUomoCnt='';
  //     this.attivaDonnaCnt='';
  //     this.attivaAziendaCnt='';
  //     this.sexCnt = sex;
  //     if(sex === 'M'){ this.attivaUomoCnt='active';}
  //     if(sex === 'F'){ this.attivaDonnaCnt='active';}
  //     if(sex === 'A'){ this.attivaAziendaCnt='active';}

  //   } else if(type === 'ASS'){
  //     this.attivaUomoAss='';
  //     this.attivaDonnaAss='';
  //     this.attivaAziendaAss='';
  //     this.sexAss = sex;
  //     if(sex === 'M'){ this.attivaUomoAss='active';}
  //     if(sex === 'F'){ this.attivaDonnaAss='active';}
  //     if(sex === 'A'){ this.attivaAziendaAss='active';}
  //   }
  // }

  // getCompanies() {
  //   if (this.cacheServ.companies == null) {
  //     this.policyServ.getCompanyList().subscribe( (response: Response) => {
  //       // this.companies = response;
  //       this.cacheServ.companies = response;
  //       this.companies = this.cacheServ.companies;
  //     },
  //     (error: Error) => console.log(error)
  //     );
  //   } else {
  //    this.companies = this.cacheServ.companies;
  //   }
  // }

  getProvinces() {
    if (this.cacheServ.getProvinces() == null) {
     this.policyServ.getProvincesList().subscribe( (response: Response) => {
        // this.provinces = response;
        this.cacheServ.setProvinces(response);
        this.provinces = this.cacheServ.getProvinces();
      },
      (error: Error) => console.log(error)
      );
    } else {
     this.provinces = this.cacheServ.getProvinces();
    }
  }


  getCountries() {
    if (this.cacheServ.getCountries() == null) {
      this.policyServ.getCountriesList().subscribe( (response: Response) => {
     // this.countries = response;
       this.cacheServ.setCountries(response);
       this.countries = this.cacheServ.getCountries();
      },
      (error: Error) => console.log(error)
      );
    } else {
     this.countries = this.cacheServ.getCountries();
    }
  }

  clearFilter() {
    this.valueBar = 0;
    this.errorMessage = '';
    this.errore = false;
    this.externalErrorMessage = '';
    this.externalError = false;
    this.newPolicy = new ExternalPolicy();
    this.newAsset = new ExternalPolicyAsset();
    this.newWarrent = new ExternalPolicyWarrenty();
    this.newPolicy.assetList =  new Array(1);
    this.newAsset.warrentyList = new Array(1);
    this.newPolicyForm = this.formBuilder.group({
      company: [, Validators.required],
      policyNumber: [, Validators.required],
      // reassWarrant: [],
      // reassQuote: [],
      effectDate: [, Validators.required],
      expirationDate: [, Validators.required],
      releaseDate: [],
      policyReplacedNumber: [],
      policyReplacementNumber: [],
      productCode: [],
      productDescription: [],
      agency: [],
      assetInsured: [],
      denomination: [, Validators.required],
      firstName: [],
      fiscalCodeVat: [],
      subjectCode: [, Validators.required],
      // birthDate: [],
      // gender: [],
      address: [],
      municipality: [],
      postalCode: [],
      province: [],
      denominationInsured: [],
      firstNameInsured: [],
      fiscalCodeVatInsured: [],
      subjectCodeInsured: [],
      // birthDateInsured: [],
      // genderInsured: [],
      addressInsured: [],
      municipalityInsured: [],
      postalCodeInsured: [],
      provinceInsured: [],
      policyCeiling: [, Validators.required],
      uncovered: [],
      miniDiscoveredValue: [],
      deductible: [],
      emissionDate: [],
      regAmm: [],
      suspensionDateFrom: [],
      suspensionDateTo: [],
      premiumFrom: [],
      premiumTo: [],
      ggMora: [],
      // outRange: [],
      policySplitting: [],
      branch: [],
      codTerm: [],
      riskObject: [],
      diaria: [],
      ggDiaria: [],
      assetInsuredType: ['ALTRO', ],
      country: [],
      countryInsured: []
    });
  }

  back() {
    const session = this.getCurrentSession();
    this.session.remove(session.idSession);
  }

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }

  genderMale() {
    this.newPolicy.gender = '1';
  }

  genderFemale() {
    this.newPolicy.gender = '2';
  }

  caricaComuni(type: string) {
    let prov = '';
    if (type === 'CNT') {
      this.policyServ.getTownListByProvince(this.newPolicyForm.controls.province.value).subscribe( (response: Response) => {
        this.comunisCnt = response;
      },
        (error: Error) => console.log(error)
      );
    } else if (type === 'ASS') {
      prov = this.newPolicyForm.controls.provinceInsured.value;
      this.policyServ.getTownListByProvince(this.newPolicyForm.controls.provinceInsured.value).subscribe( (response: Response) => {
        this.comunisAss = response;
      },
        (error: Error) => console.log(error)
      );
    }
  }

  caricaCap(type: string) {
    if (type === 'CNT') {
      for (const valore of this.comunisCnt) {
        if (valore.descrizione === this.newPolicyForm.controls.municipality.value) {
            this.capsCnt = valore.listaCap;
            break;
        }
      }
    } else if (type === 'ASS') {
      for (const valore of this.comunisAss) {
        if (valore.descrizione === this.newPolicyForm.controls.municipalityInsured.value) {
            this.capsAss = valore.listaCap;
            break;
        }
      }
    }
  }

  validazioneDatiImporti() {
    const n1 = this.newPolicy.uncovered ? parseFloat(this.newPolicy.uncovered + '') : 0;
    const n2 = this.newPolicy.deductible ? parseFloat(this.newPolicy.deductible + '') : 0;
    const n3 = this.newPolicy.miniDiscoveredValue ? parseFloat(this.newPolicy.miniDiscoveredValue + '') : 0;
    const n4 = this.newPolicy.policyCeiling ? parseFloat(this.newPolicy.policyCeiling + '') : 0;

    if (isNaN(n1.valueOf()) || isNaN(n2.valueOf()) || isNaN(n3.valueOf()) || isNaN(n4.valueOf())) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._INCOMPLETE_COMPILATION').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.errore = true;
    }

    if (this.newPolicy.uncovered < 0 || this.newPolicy.deductible < 0 ||
      this.newPolicy.miniDiscoveredValue < 0 || this.newPolicy.policyCeiling < 0 ||
      this.newPolicy.uncovered > 100) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._INCOMPLETE_COMPILATION').subscribe(res=> {
          this.errorMessage=res;
        }
        )
      this.errore = true;
    }
  }


  validazioneDati() {
    if (
      (!this.newPolicy.company || this.newPolicy.company == null) ||
      (!this.newPolicy.policyNumber || this.newPolicy.policyNumber == null) ||
      (!this.newPolicy.effectDate || this.newPolicy.effectDate == null) ||
      (!this.newPolicy.expirationDate || this.newPolicy.expirationDate == null) ||
      (!this.newPolicy.subjectCode || this.newPolicy.subjectCode == null) ||
      // (!this.newPolicy.subjectCodeInsured || this.newPolicy.subjectCodeInsured == null) ||
      (!this.newPolicy.denomination || this.newPolicy.denomination == null) ||
      // (!this.newPolicy.denominationInsured || this.newPolicy.denominationInsured == null) ||
      // (!this.newPolicy.fiscalCodeVat || this.newPolicy.fiscalCodeVat == null) ||
      // (!this.newPolicy.fiscalCodeVatInsured || this.newPolicy.fiscalCodeVatInsured == null) ||
       (this.newPolicy.policyCeiling == null)
      ) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._MANDATORY_DATA_MISSING').subscribe(res=> {
          this.errorMessage=res;
        }
        )
      this.errore = true;
    }

    if ((this.newPolicy.policyReplacedNumber != null && this.newPolicy.policyReplacementNumber == null) ||
       (this.newPolicy.policyReplacedNumber == null && this.newPolicy.policyReplacementNumber != null) ||
       (this.newPolicy.subjectCode === 'F' && this.newPolicy.firstName == null) ||
       (this.newPolicy.subjectCodeInsured === 'F' && this.newPolicy.firstNameInsured == null)) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._MANDATORY_DATA_MISSING').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
    }

    if (this.newPolicy.country === 'IT' && this.newPolicy.fiscalCodeVat) {
      this.verifyFiscalCode(this.newPolicy.fiscalCodeVat);
    }

    if (this.newPolicy.countryInsured === 'IT' && this.newPolicy.fiscalCodeVatInsured) {
      this.verifyFiscalCode(this.newPolicy.fiscalCodeVatInsured);
    }

    if (this.errore) {
        this.newPolicy.assetList =  new Array(1);
        this.newAsset.warrentyList = new Array(1);
        return;
    }

  }

  verifyFiscalCode(cfPiva) {
    if (cfPiva.length !== 0 && cfPiva.length !== 16 && cfPiva.length !== 11 &&  cfPiva.length !== 7) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE__VAT_UNCORRECT').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.errore = true;
    }

    if (cfPiva.length === 7) {
      // PIVA SANMARINO
      const n1 = parseFloat(cfPiva.substring(0, 2));
      const n2 = parseFloat(cfPiva.substring(2, cfPiva.length));

      if (!isNaN(n1.valueOf()) || isNaN(n2.valueOf())) {
        console.log('KO PIVA SM');
        this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_UNCORRECT').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
      } else { console.log('OK PIVA SM'); }

    }

    if (cfPiva.length === 11) {
      // PIVA ITALIANA
      const n = parseFloat(cfPiva);
      console.log(n);

      if (isNaN(n.valueOf())) {
        console.log('KO PIVA');
        this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_UNCORRECT').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
      } else { console.log('OK PIVA'); }

    }

    if (cfPiva.length === 16) {
      // CODICE FISCALE ITALIANO
      for (let i = 0; i < cfPiva.length; i++) {
        const aChar = cfPiva.charAt(i);
        console.log(aChar);
        const n = parseFloat(aChar);
        console.log(n);

        if (i < 6 || i === 8 || i === 11 || i === 15) {
          // il carattene non deve essere numerico
          if ( !isNaN(n.valueOf()) ) {
            console.log('KO');
            this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE_UNCORRECT').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            this.errore = true;
          } else { console.log('OK'); }
        }
        if ((i >= 6 && i < 8) || (i >= 9 && i < 11) || (i >= 12 && i < 15)) {
          // il carattene non deve essere numerico
          if (isNaN(n.valueOf())) {
            console.log('KO');
            this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE_UNCORRECT').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            this.errore = true;
          } else { console.log('OK'); }
        }
      }
    }
  }

  attivaNome(code) {
    if (code === 'CNT') {
       if (this.newPolicyForm.controls.subjectCode.value === 'G') {
         this.attivaNomeCnt = false;
         this.newPolicyForm.controls.firstName.setValue('');
       } else {
        this.attivaNomeCnt = true;
       }

    } else if ( code === 'ASS') {
      if (this.newPolicyForm.controls.subjectCodeInsured.value === 'G') {
        this.attivaNomeAss = false;
        this.newPolicyForm.controls.firstNameInsured.setValue('');
      } else {
       this.attivaNomeAss = true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
       this.subscription.unsubscribe();
    }
    this.valueBar = 0;
  }
}



import barChart from '@nebula.js/sn-bar-chart';
import pieChart from '@nebula.js/sn-pie-chart';
import comboChart from '@nebula.js/sn-combo-chart';
import kpi from '@nebula.js/sn-kpi';
import lineChart from '@nebula.js/sn-line-chart';
import table from '@nebula.js/sn-table';
import mekko from '@nebula.js/sn-mekko-chart';
import waterfall from '@nebula.js/sn-waterfall';
import pivotTable from '@nebula.js/sn-pivot-table';
import scatterplot from '@nebula.js/sn-scatter-plot';
import boxplot from '@nebula.js/sn-boxplot';
import treemap from '@nebula.js/sn-treemap';
import filterpane from '@nebula.js/sn-filter-pane';
import text from '@nebula.js/sn-text';


export const NEBULA_CHARTS = [
    {
      name: 'barchart',
      load: () => barChart,
    },
    {
      name: 'piechart',
      load: () => pieChart,
    },
    {
      name: 'kpi',
      load: () => kpi,
    },
    {
      name: 'combochart',
      load: () => comboChart
    },
    {
      name: 'linechart',
      load: () => lineChart
    },
    {
      name: 'table',
      load: () => table
    },
    {
      name: 'mekko',
      load: () => mekko
    },
    {
      name: 'waterfall',
      load: () => waterfall
    },
    {
      name: 'pivot-table',
      load: () => pivotTable
    },
    {
      name: 'scatterplot',
      load: () => scatterplot
    },
    {
      name:'treemap',
      load: () => treemap
    },
    {
      name: 'boxplot',
      load: () => boxplot
    },
    {
      name:'filterpane',
      load: () => filterpane
    },
    {
      name:'text',
      load: () => text
    }
];


export const NEBULA_CONF = {
    load: null,
    context: {
      // theme: 'myTheme',
      language: 'it-IT',
      constraints: {
        active: false,
        passive: false,
        select: false,
      },
      theme: 'myTheme'
    },
    themes: [],
    types: [
      {
        name: 'barchart',
        load: () => barChart,
      },
      {
        name: 'piechart',
        load: () => pieChart,
      },
      {
        name: 'kpi',
        load: () => kpi,
      },
      {
        name: 'combochart',
        load: () => comboChart
      },
      {
        name: 'linechart',
        load: () => lineChart
      },
      {
        name: 'table',
        load: () => table
      },
      {
        name: 'mekko',
        load: () => mekko
      },
      {
        name: 'waterfall',
        load: () => waterfall
      },
      {
        name: 'pivot-table',
        load: () => pivotTable
      },
      {
        name: 'scatterplot',
        load: () => scatterplot
      },
      {
        name: 'boxplot',
        load: () => boxplot
      },
      {
        name:'treemap',
        load: () => treemap
      },
      {
        name:'filterpane',
        load: () => filterpane
      },
      {
        name:'text',
        load: () => text
      }
    ]
  };

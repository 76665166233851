import { NgModule, Inject } from '@angular/core';
import { CardModule } from './components/card/card.module';
import { PanelModule } from './components/panel/panel.module';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from './directives/directives.module';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import {
  constClaimsFeaturesProvider,
  claimsAlertServiceProvider,
  claimsServiceProvider,
  constFunctionListServiceProvider,
  constBoardBaseServiceProvider,
  constCardManagementServiceProvider,
  constActivityWorkflowProvider,
  claimsActivityCommonServiceProvider,
  claimsActivityServiceProvider,
  claimsLoaderServiceProvider,
  eventServiceProvider,
  sessionServiceProvider,
  authServiceProvider,
  gettextCatalogProvider,
  pRouterProvider,
  coreResultProvider,
  cardNavigationWFServiceProvider,
  containerCacheServiceProvider,
  claimsCardServiceProvider,
  angularGridInstanceProvider,
  workflowTaskServiceProvider,
  coreAuthServiceProvider,
  loadingHistoricalErosionExcessProvider
} from './providers/ajs-upgraded-providers';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete.component';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { StepperComponent } from './components/stepper/stepper.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_COMMON_TRANSLATIONS } from '../assets/i18n/translations';
// import { ClaimsMergeTranslationsLoader, ClaimsLoggingMissingTranslationHandler } from './providers/translation/translations-config';

@NgModule({
  declarations: [SelectAutocompleteComponent, StepperComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    PanelModule,
    CardModule,
    DirectivesModule,
    FormsModule,
    NgbTypeaheadModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(CLAIMS_COMMON_TRANSLATIONS),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useClass: ClaimsMergeTranslationsLoader
    //   },
    //   missingTranslationHandler: {
    //     provide: MissingTranslationHandler,
    //     useClass: ClaimsLoggingMissingTranslationHandler
    //   }
    // })
  ],
  exports: [
    CardModule,
    PanelModule,
    DirectivesModule,
    SelectAutocompleteComponent,
    StepperComponent
  ],
  providers: [
    constClaimsFeaturesProvider,
    claimsAlertServiceProvider,
    claimsServiceProvider,
    constFunctionListServiceProvider,
    constBoardBaseServiceProvider,
    constCardManagementServiceProvider,
    constActivityWorkflowProvider,
    claimsActivityCommonServiceProvider,
    claimsActivityServiceProvider,
    claimsLoaderServiceProvider,
    eventServiceProvider,
    sessionServiceProvider,
    authServiceProvider,
    gettextCatalogProvider,
    pRouterProvider,
    coreResultProvider,
    cardNavigationWFServiceProvider,
    containerCacheServiceProvider,
    claimsCardServiceProvider,
    angularGridInstanceProvider,
    workflowTaskServiceProvider,
    coreAuthServiceProvider,
    loadingHistoricalErosionExcessProvider
  ]
})
export class DigitalClaimsCommonAngularModule {
}

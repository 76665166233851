import {Component, OnInit} from '@angular/core';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {BALANCE_SUMMARY_TAG} from '../../../group-policy-resources/gp-tags-constants';
import {GroupPolicyStateInquiry} from '../../../group-policy-state/group-policy-state';
import {MPBalanceDetail} from '../../../group-policy-models/group-policy-balance';
import {productCategories} from '../../../group-policy-models/group-policy-application';

@Component({
  selector: 'rgi-gp-balance-detail-inquiry',
  templateUrl: './gpbalance-detail-inquiry.component.html'
})
export class GPBalanceDetailInquiryComponent extends GpInquiryComponentDetail implements OnInit {
  footerTag = BALANCE_SUMMARY_TAG;

  balanceDetail: MPBalanceDetail;
  isVisible: boolean;

  constructor(
    protected stateMngr: GroupPolicyStateManagerInquiry,
    protected pushMessageHandler: PushMessageHandlerService,
    protected i18nService: RgiRxTranslationService
  ) {
    super(stateMngr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.stateMngr.getState$().subscribe((nextState: GroupPolicyStateInquiry) => {
      this.balanceDetail = nextState.contractDetails.balanceDetail;
      this.pushMessageHandler.clearTag(this.footerTag);
      this.isVisible = this.balanceDetail && nextState.contractDetails.category.code !== productCategories.LIFE;
      if (this.isVisible) {
        if (!this.balanceDetail.risks || this.balanceDetail.risks.length === 0) {
          this.i18nService.translate('_GP_._MSG_._BALANCE_SUMMARY_WARNING_').subscribe(msg => {
            this.pushMessageHandler.notify(new RgiRxPushMessage(msg, {
              tag: BALANCE_SUMMARY_TAG,
              status: 'warning',
              dismissible: false
            }));
          });
        } else if (!nextState.contractDetails.policyNumber || nextState.contractDetails.policyNumber.trim() === '') {
          this.i18nService.translate('_GP_._MSG_._BALANCE_RECALCULATE_WARNING_').subscribe(msg => {
            this.pushMessageHandler.notify(new RgiRxPushMessage(msg, {
              tag: BALANCE_SUMMARY_TAG,
              status: 'info',
              dismissible: false
            }));
          });
        }
      }
    });
  }
}

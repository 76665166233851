import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OperationsListComponent} from './operations-list/operations-list.component';
import { LoaderMobileModule } from '../lpc-loader-mobile/loader-mobile.module';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RgiRxI18nModule } from '@rgi/rx/i18n';



@NgModule({
    declarations: [OperationsListComponent],
    imports: [
        CommonModule,
        LoaderMobileModule,
        RgiRxI18nModule
    ],
    exports: [OperationsListComponent],
    providers: [
        { provide: PV_TOKEN.OPERATION_LIST_COMPONENT, useValue: OperationsListComponent },
        { provide: PV_TOKEN.SHOW_LOADER_MOBILE, useValue: false }
    ]
})
export class LpcOperationsListModule { }

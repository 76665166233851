<div class="box-detail" *ngIf="!contractDetails.showAssetFullDetail">
  <header class="box-detail-header" translate>_GP_._TITLE_._GUARANTEES_</header>
  <div class="box-detail-body" *ngFor="let asset of contractDetails.assetsDetail">
    <table rgi-rx-table [dataSource]="asset.units">
      <ng-container rgiRxColumnDef="unit">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef>{{asset.descrAsset}}</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.unit.description}} </td>
      </ng-container>
      <ng-container rgiRxColumnDef="unitCode">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          {{row.unit.code}}
        </td>
      </ng-container>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedUnitColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedUnitColumns;"></tr>
    </table>
  </div>
</div>

import {Company} from '../domain-models/co-insurance';
import {GenericEntityPcPortfolio} from './genericEntityPcPortfolio';

export class PolicyGenericDto {
  public policyNumber: string;
  public effectDate: string;
  public expireDate: Date;
  public active: string;
  public notes: string;
  public company: Company;
  public objectId: number;
  public limit: number;
  public error: GenericEntityPcPortfolio;
}

import { ApiPager } from '@rgi/digital-claims-common-angular';
import { InvoiceExpertSectorial } from '../dto/invoice-expert-sectorial';

export class ApiClaimsListFilter {
    invoiceExpertSectorialList: InvoiceExpertSectorial[];
    claimsToExclude: string[];
    pager: ApiPager;
    claimNumber: string;
    technicalState: number;
    claimOpeningDateFrom: Date;
    claimOpeningDateTo: Date;
    subjectSurnameOrDenomination: string;
    subjectName: string;
    codHandler: string;
}

<div style="height:10px"></div>
<claims-stepper [currentStep]="currentStep" [labels]="stepperLabels"></claims-stepper>
<div class="container-fluid policy-table" data-qa="similarClaims-list">
  <div style="float: left;">
    {{ '_CLAIMS_._TOTAL' | translate }}: {{totalFilter}}
  </div>
  <div>
    <claims-dsc-similar-claims [similarClaimsList]="listPotentialClaims" [legalProtectionActive]="legalProtectionActive" [showTitleTotal]="true" [showTitle]="false"(outputShowDetail)="showDetailClaim($event)"></claims-dsc-similar-claims>
  </div>
  <!-- <div style="float: right; margin-right: 10px;">
    <input type="text" class="form-control" name="search" [(ngModel)]="searchFE" placeholder="trova..." id="search"
      (change)="search()" />
  </div> -->
  <!-- <table class="table-responsive table coreDT" style="display: block;" *ngIf="legalProtectionActive">
    <thead>
      <tr class="head-result thDataTable">
        <td style="width:50px;">
        </td>
        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-pesnumber-label">{{ 'Complaint number and status' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-claimnumber-label">{{ 'Claim number and status' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-lossdate-label">{{ 'Occurrence date' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-coverturetype-label">{{ 'Coverture Type' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-complaintdate-label">{{ 'Complaint date' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-policynumber-label">{{ 'Policy number' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-insured-label">{{ 'Insured' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-counterparty-label">{{ 'Counterpart' | translate }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="similar-procnumber-label">{{ 'Procedure Number' | translate }}</span>
        </td>

        <td *ngIf="!policyModifyData" style="width:135px;">
          <span attr.data-qa="similar-actions-label">{{ 'Actions' | translate }}</span>
        </td>

      </tr>

    </thead>

    <tbody style="height: 500px; display: inline-block; overflow: auto;">
      <tr *ngFor="let aRow of rows;
                    let even = even;
                    let odd = odd;
                    let similarClaimIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
        class="table-row" attr.data-qa="similarClaim-result-{{similarClaimIndex}}">
        <td colspan="11" *ngIf="aRow.filtered">
          <table>
            <tr>
              <td style="width:50px;">
                <span class="rgifont iconDTleft rgi-plus"
                  [ngClass]="{'rgi-plus': !aRow.expanded, 'rgi-minus':aRow.expanded}"
                  (click)=ctrDetails(similarClaimIndex)></span>
              </td>
              <td style="width:300px;">
                {{aRow.similCl.numeroDenuncia}} ({{aRow.similCl.stato.descrizione}})
              </td>

              <td style="width:300px;">
                {{aRow.similCl.numberClaim? aRow.similCl.numberClaim : '-'}}
              </td>

              <td style="width:150px;">
                {{aRow.similCl.dataAccadimento.data | date:'dd/MM/yyyy'}}
              </td>

              <td style="width:200px;">
                {{aRow.similCl.descrTipoCopertura}}
              </td>

              <td style="width:150px;">
                {{aRow.similCl.dataDenuncia? (aRow.similCl.dataDenuncia.data | date:'dd/MM/yyyy') : '-'}}
              </td>

              <td style="width:200px;">
                {{aRow.similCl.numeroPolizza}}
              </td>

              <td style="width:300px;">
                {{aRow.similCl.nominativoAssicurato}}
              </td>

              <td style="width:270px;">
                {{aRow.similCl.nominativoControparte}}
              </td>

              <td style="width:100px;">
                {{aRow.similCl.numeroProcedimento}}
              </td>

              <td *ngIf="!policyModifyData" style="width:135px;">
                <button type="button" class="btn btn-flat btn-sm" *ngIf="aRow.actionPES"
                  (click)="goToSummary(aRow.similCl.numeroDenuncia, aRow.similCl.companyCode)">
                  <span class="action-icon rgifont rgi-chevron-right"></span>
                </button>
              </td>

            </tr>
            <tr *ngIf="aRow.expanded">
              <td colspan="11">
                <table style="width: 100%; border: 1px solid gray;">
                  <tr>
                    <td colspan="6" style="color:#4981b4; font-weight: bold;">{{'Complaint data' | translate }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'Complaint date' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.dataDenuncia? (aRow.similCl.dataDenuncia.date |  date:'dd/MM/yyyy') : '-' }}</td>
                    <td>{{ 'Claim branch' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.claimBranch  }}</td>
                    <td>{{ 'Company' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.companyCode }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'Handling Centre' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.ispettorato }}</td>
                    <td>{{ 'Policy holder' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.contraente  }}</td>
                    <td>{{ 'Adjuster' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.liquidatore }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'License plate' | translate }}</td>
                    <td style="font-weight: bold;">{{ valuateStringFieldForVisual(aRow.similCl.targaTelaio) }}</td>
                    <td>{{ 'Occurence description' | translate }}</td>
                    <td style="font-weight: bold;">{{ valuateStringFieldForVisual(aRow.similCl.descrizioneAccadimento) }}</td>
                    <td>{{ 'Product' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.prodotto }}</td>
                  </tr>

                  <tr>
                    <td>{{ 'Info Causa' | translate }}</td>
                    <td>
                      <tr *ngFor="let infoCausa of valuateArrayFieldForVisual(aRow.similCl.lawsuitInfo)"
                      class="infoCausa">
                        <td >
                            {{ infoCausa }}
                        </td>
                      </tr>
                    </td>
                  </tr>

                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </tbody>
  </table>


  <table class="table-responsive table coreDT" style="display: block;" *ngIf="!legalProtectionActive">
    <thead>
      <tr class="head-result thDataTable">
        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
        </td>
        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-pesnumber-label">{{ 'Complaint number' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-claimnumber-label">{{ 'Claim number and status' | translate }}</span>
        </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <span attr.data-qa="similar-lossdate-label">{{ 'Occurrence date' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-branch-label">{{ 'Claim branch' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-claimtype-label">{{ 'Claim type' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-policynumber-label">{{ 'Policy number' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <span attr.data-qa="similar-adjuster-label">{{ 'Adjuster' | translate }}</span>
        </td>

        <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span attr.data-qa="similar-cdl-label">{{ 'CDL' | translate }}</span>
          </td>

        <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
            <span attr.data-qa="similar-actions-label">{{ 'Actions' | translate }} </span>
        </td>

      </tr>

    </thead>

    <tbody>
      <ng-container *ngFor="let aRow of rows;
      let even = even;
      let odd = odd;
      let similarClaimIndex = index">
            <tr *ngIf="aRow.filtered" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }"
            class="table-row" attr.data-qa="similarClaim-result-{{similarClaimIndex}}">
              <td style="width:50px;">
                <span class="rgifont iconDTleft rgi-plus"
                  [ngClass]="{'rgi-plus': !aRow.expanded, 'rgi-minus':aRow.expanded}"
                  (click)=ctrDetails(similarClaimIndex)></span>
              </td>
              <td style="width:300px;">
                {{aRow.similCl.numeroDenuncia}}
              </td>

              <td style="width:300px;">
                {{aRow.similCl.numberClaim}}
              </td>

              <td style="width:150px;">
                {{aRow.similCl.dataAccadimento.data | date:'dd/MM/yyyy'}}
              </td>

              <td style="width:200px;">
                {{aRow.similCl.claimBranch}}
              </td>

              <td style="width:300px;">
                {{aRow.similCl.claimType}}
              </td>

              <td style="width:300px;">
                {{aRow.similCl.numeroPolizza}}
              </td>

              <td style="width:150px;">
                {{aRow.similCl.liquidatore}}
              </td>

              <td style="width:120px;">
                {{aRow.similCl.ispettorato}}
              </td>

              <td style="width:100px;">
                <button type="button" class="btn btn-flat btn-sm" *ngIf="aRow.actionPES"
                  (click)="goToSummary(aRow.similCl.numeroDenuncia, aRow.similCl.companyCode)">
                  <span class="action-icon rgifont rgi-chevron-right"></span>
                </button>
              </td>

            </tr>
            <tr *ngIf="aRow.expanded">
              <td colspan="9">
                <table style="width: 100%; border: 1px solid gray;">
                  <tr>
                    <td colspan="6" style="color:#4981b4; font-weight: bold;">{{'Complaint data' | translate }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'Complaint date' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.dataDenuncia? (aRow.similCl.dataDenuncia.data |  date:'dd/MM/yyyy') : '-'}}</td>
                    <td>{{ 'Complaint state' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.stato.descrizione  }}</td>
                    <td>{{ 'Data forwarding CDL' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.dataInoltroCdl? (aRow.similCl.dataInoltroCdl.data |  date:'dd/MM/yyyy' ) : '-' }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'Intermediary' | translate }}</td>
                    <td style="font-weight: bold;">{{ valuateBooleanFieldForVisual(aRow.intermediary) | translate }}</td>
                    <td>{{ 'Master policy number' | translate }}</td>
                    <td style="font-weight: bold;">{{valuateStringFieldForVisual(aRow.similCl.numeroPolizzaMadre)}}</td>
                    <td>{{ 'Application number' | translate }}</td>
                    <td style="font-weight: bold;">{{valuateStringFieldForVisual(aRow.similCl.numeroApplicazione)}}</td>
                  </tr>
                  <tr>
                    <td>{{ 'License plate' | translate }}</td>
                    <td style="font-weight: bold;">{{valuateStringFieldForVisual(aRow.similCl.targaTelaio) }}</td>
                    <td>{{ 'Accounting coverage' | translate }}</td>
                    <td style="font-weight: bold;">{{valuateBooleanFieldForVisual(aRow.similCl.coperturaAmministrativa.boolean) | translate  }}</td>
                    <td>{{ 'Technical coverage' | translate }}</td>
                    <td style="font-weight: bold;">{{valuateBooleanFieldForVisual(aRow.similCl.coperturaTecnica.boolean) | translate}}</td>
                  </tr>
                  <tr>
                    <td>{{ 'Product' | translate }}</td>
                    <td style="font-weight: bold;">{{ aRow.similCl.prodotto  }}</td>
                    <td colspan="4"></td>
                  </tr>
                </table>
              </td>
        </ng-container>
    </tbody>
  </table> -->



</div>
<div class="btn-group btn-group-justified btn-group-justified-claim">
  <!-- <div class="btn-group">
      <button  class="btn btn-warning pull-right"  (click)="back()">{{ 'BACK' | translate }}</button>
  </div> -->
  <div class="btn-group">
    <button class="btn btn-warning pull-right" (click)="newClaim()">{{ (!policyModifyData ? '_CLAIMS_._BUTTONS_._NEW': '_CLAIMS_._BUTTONS_._NEXT') | translate }}</button>
  </div>
</div>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcFormuleModalComponent} from './lpc-formule-modal.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {ReactiveFormsModule} from '@angular/forms';
import { CustomNumberFormatModule } from './utils/lpc-custom-number-format.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
    declarations: [LpcFormuleModalComponent],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        ReactiveFormsModule,
        CustomNumberFormatModule,
        RgiRxI18nModule
    ],
    exports: [LpcFormuleModalComponent]
})
export class LpcFormuleModalModule {
}

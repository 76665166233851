<div *ngIf="showDivider" class="rgi-ui-datepicker-timer-divider" aria-hidden="true"></div>
<button class="rgi-ui-datepicker-control-button rgi-ui-datepicker-control-arrow-button"
        type="button" tabindex="-1"
        [disabled]="upBtnDisabled"
        [attr.aria-label]="upBtnAriaLabel"
        (click)="upBtnClicked()">
    <span class="rgi-ui-datepicker-control-button-content rgi-ui-icon-up-arrow" tabindex="-1">
    </span>
</button>
<label class="rgi-ui-datepicker-timer-content">
  <input class="rgi-ui-datepicker-timer-input" maxlength="2"
         [value]="displayValue | numberFixedLen : 2"
         (input)="handleInputChange(valueInput.value)" #valueInput>
  <span class="rgi-ui-hidden-accessible">{{inputLabel}}</span>
</label>
<button class="rgi-ui-datepicker-control-button rgi-ui-datepicker-control-arrow-button"
        type="button" tabindex="-1"
        [disabled]="downBtnDisabled"
        [attr.aria-label]="downBtnAriaLabel"
        (click)="downBtnClicked()">
    <span class="rgi-ui-datepicker-control-button-content rgi-ui-icon-down-arrow" tabindex="-1">

    </span>
</button>

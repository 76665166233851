import {ApiPremiumGroup} from './api-premium-group';

export class ApiPremiumSection {

  constructor(
    public premium: ApiPremiumGroup,
    public unitsPremium: Map<string, ApiPremiumGroup>
  ) {
  }
}

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>{{ title }}</span>
          <span (click)="close()" class="glyphicon glyphicon-remove pull-right cursorPointer"></span>
        </h3>
      </div>
      <div class="modal-body">
        <div class="unit-container selected">
          <label>Medium coverage</label>
          <span *ngIf="packagesPremium && packagesPremium[originalPackage?.code] && packagesPremium[originalPackage?.code].premium"
                class="price price-unit">
                     {{packagesPremium[originalPackage?.code].premium?.instalment?.gross | currency:'EUR':'symbol':'1.2-2':'it' }}
                    </span>
          <span *ngIf="!packagesPremium" class="price price-unit">--</span>
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="guaranteesForm">
          <div *ngFor="let unit of originalPackage?.unitList" [attr.data-qa]="'unit_'+unit.warrantyCode"
               class="panel no-border">

            <!--  INIZIO UNIT container -->
            <div [ngClass]="{'pk-unit-selected': unit.selected, 'pk-unit-disabled': !unit.selected}"
                 class="panel-heading panel-heading-pk-unit">
              {{ unit?.extendedDescription ? unit?.extendedDescription : unit?.description }}
              <span class="price">
                            <span
                              *ngIf="packagesPremium && packagesPremium[originalPackage?.code] && packagesPremium[originalPackage?.code].unitsPremium">
                                {{packagesPremium[originalPackage?.code].unitsPremium[unit?.code + '_' + unit?.warrantyCode].instalment?.gross | currency:'EUR':'symbol':'1.2-2':'it' }}
                            </span>
                            <span *ngIf="!packagesPremium">--</span>
                        </span>
            </div>

            <!--  INIZIO UNIT container -->
            <div *ngIf="unit?.variables?.length > 0  || unit?.risk?.clauses?.length > 0 " class="row"
                 id="{{unit?.risk?.code}}">
              <div *ngFor="let variable of unit?.variables; index as i; trackBy: variablesTrackByFn"
                   class="col-sm-6">
                <mic-variable-control (updateVariable)="validateVariables($event)"
                                      [variableControlForm]="guaranteesForm"
                                      [variable]="variable">
                </mic-variable-control>
              </div>
              <mic-clause (clauseChange)="changeClauses($event)" (validationMessagesClause)="verifyClauses($event,unit)"
                          [clauseList]="unit?.risk?.clauses" [isUnitEnabled]="unit?.selected"
                          [viewError]=false></mic-clause>
            </div>
          </div>
        </form>
        <div id="error-container">
          <pnc-form-message *ngIf="(guaranteesForm?.invalid && submitted && validationMessages?.length > 0)"
                            [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" translate type="button">CONFIRM AND RECALCULATE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {AfterContentInit, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, SkipSelf} from '@angular/core';
import {SCALAR_TYPE} from '../reflection';
import {RgiRxQAService} from './rgi-rx-qa.service';
import {QAContext} from './rgi-rx-data-qa-api';

@Directive({
  selector: '[rgi-rx-qa]',
  providers: [
    RgiRxQAService
  ]
})
export class RgiRxQualityAssuranceDirective implements OnInit, AfterContentInit, OnChanges, OnDestroy {

  private _id: string;
  private _value: SCALAR_TYPE;
  private _context: string;
  private _type: string;

  constructor(
    private qaService: RgiRxQAService,
    private elementRef: ElementRef,
    @Optional() @SkipSelf() private qaParent?: RgiRxQualityAssuranceDirective
  ) {
  }


  @Input('rgi-rx-qa') get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  @Input('qa-value') get value(): SCALAR_TYPE {
    return this._value;
  }

  set value(value: SCALAR_TYPE) {
    this._value = !value ? '' : value;
  }


  @Input('qa-ctx') get context(): string {
    return this._context;
  }

  set context(value: string) {
    this._context = value;
  }

  @Input('qa-type') get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get qa(): QAContext {
    return {
      id: this.id,
      value: this.value,
      ctx: this.context,
      type: this.type
    };
  }

  ngOnInit(): void {
    if (!this.context && !!this.qaParent) {
      this.context = this.qaParent.context;
    }
  }

  ngAfterContentInit(): void {

    if (!this.type) {
      this.type = this.nativeElement.tagName;
    }
    this.qaService.render(this.nativeElement, this.qa, 'parent');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.id) {
      return;
    }
    if (changes.id.isFirstChange()) {
      return;
    }
    if (changes.id.currentValue !== changes.id.previousValue) {
      this.qaService.clear(this.nativeElement);
      this.qaService.render(this.nativeElement, this.qa, 'parent');
    }
  }

  ngOnDestroy(): void {
    this.qaService.clear(this.nativeElement);
  }


}

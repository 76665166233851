<div class="document-header row">
    <div class="document-type col-sm-1 col-xs-12">
        <span class="rgifont document-icon"
            [ngClass]="{ 'rgi-doc-file-format': !pdfType, 'rgi-pdf-file-format-symbol': pdfType}">
        </span>
    </div>

    <div class="document-name col-sm-8 col-xs-12">
        <span class="document-name-text">
            {{document.documentName}}
        </span>
    </div>

    <div class="col-sm-3 col-xs-12">
        <!--
        <span *ngIf="document.validated">{{ 'Validato' | translate }}</span>
        <span *ngIf="!document.validated">{{ 'Non Validato' | translate }}</span>
        -->
        <button type="button" class="rgifont rgi-trash pull-right delete-icon" (click)="deleteDocument($event)"
            [ngClass]="{'claims-popover': document.externalServiceDocumentId}"
            [disabled]="document.externalServiceDocumentId">
            <span *ngIf="document.externalServiceDocumentId" class="claims-popover-container">
                <div>
                    {{ '_CLAIM_._MESSAGE_._IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM' | translate }}
                </div>
            </span>
        </button>
        <button type="button" class="rgifont rgi-magnifier pull-right preview-icon" (click)="previewDocument($event)">
        </button>
    </div>
</div>
import {Routes} from '@rgi/rx/router';
import {
  ROUTES_GP_INQUIRY_APPCONF,
  ROUTES_GP_INQUIRY_APPLIST,
  ROUTES_GP_INQUIRY_ASSET_CLUSTERS,
  ROUTES_GP_INQUIRY_BALANCE_DETAIL,
  ROUTES_GP_INQUIRY_COINSURANCES,
  ROUTES_GP_INQUIRY_CONTRACT_DETAIL,
  ROUTES_GP_INQUIRY_MOVEMENTS,
  ROUTES_GP_INQUIRY_PARTIES,
  ROUTES_GP_INQUIRY_PMDATA,
  ROUTES_GP_INQUIRY_PRODUCT_CLAUSES,
  ROUTES_GP_INQUIRY_SUBSTITUTED_POLICIES,
  ROUTES_GP_INQUIRY_UNITS
} from '../group-policy-constants/routes-constants';
import {
  GroupPolicyAppConfigurationDetailComponent
} from './inquiry-components/group-policy-app-configuration-detail/group-policy-app-configuration-detail.component';
import {
  GroupPolicyMovementsDetailComponent
} from './inquiry-components/group-policy-movements-detail/group-policy-movements-detail.component';
import {
  GroupPolicyContractDetailComponent
} from './inquiry-components/group-policy-contract-detail/group-policy-contract-detail.component';
import {
  GroupPolicyPartiesDetailComponent
} from './inquiry-components/group-policy-parties-detail/group-policy-parties-detail.component';
import {
  GroupPolicyUnitDetailComponent
} from './inquiry-components/group-policy-unit-detail/group-policy-unit-detail.component';
import {
  GroupPolicyPMDataDetailComponent
} from './inquiry-components/group-policy-pmdata-detail/group-policy-pmdata-detail.component';
import {
  GroupPolicyApplicationsListInquiryComponent
} from './inquiry-components/group-policy-applications-list-inquiry/group-policy-applications-list-inquiry.component';
import {
  GroupPolicyInquiryAssetsDetailsComponent
} from './inquiry-components/group-policy-inquiry-assets-details/group-policy-inquiry-assets-details.component';
import {
  GPBalanceDetailInquiryComponent
} from './inquiry-components/gpbalance-detail-inquiry/gpbalance-detail-inquiry.component';
import {
  GroupPolicyCoinsurancesDetailComponent
} from './inquiry-components/group-policy-coinsurances-detail/group-policy-coinsurances-detail.component';
import {
  GpSubstitutedPoliciesInquiryComponent
} from './inquiry-components/gpsubstituted-policies-inquiry-component/gp-substituted-policies-inquiry.component';
import {
  GpProductClausesInquiryComponent
} from "./inquiry-components/gp-product-clauses-inquiry/gp-product-clauses-inquiry.component";

export const GROUP_POLICY_INQUIRY_ROUTES: Routes = [
  {
    route: ROUTES_GP_INQUIRY_APPLIST,
    component: GroupPolicyApplicationsListInquiryComponent
  },
  {
    route: ROUTES_GP_INQUIRY_CONTRACT_DETAIL,
    component: GroupPolicyContractDetailComponent
  },
  {
    route: ROUTES_GP_INQUIRY_APPCONF,
    component: GroupPolicyAppConfigurationDetailComponent
  },
  {
    route: ROUTES_GP_INQUIRY_MOVEMENTS,
    component: GroupPolicyMovementsDetailComponent
  },
  {
    route: ROUTES_GP_INQUIRY_PARTIES,
    component: GroupPolicyPartiesDetailComponent
  },
  {
    route: ROUTES_GP_INQUIRY_UNITS,
    component: GroupPolicyUnitDetailComponent
  },
  {
    route: ROUTES_GP_INQUIRY_PMDATA,
    component: GroupPolicyPMDataDetailComponent
  },
  {
    route: ROUTES_GP_INQUIRY_ASSET_CLUSTERS,
    component: GroupPolicyInquiryAssetsDetailsComponent
  },
  {
    route: ROUTES_GP_INQUIRY_BALANCE_DETAIL,
    component: GPBalanceDetailInquiryComponent
  },
  {
    route: ROUTES_GP_INQUIRY_COINSURANCES,
    component: GroupPolicyCoinsurancesDetailComponent
  },
  {
    route: ROUTES_GP_INQUIRY_SUBSTITUTED_POLICIES,
    component: GpSubstitutedPoliciesInquiryComponent
  },
  {
    route: ROUTES_GP_INQUIRY_PRODUCT_CLAUSES,
    component: GpProductClausesInquiryComponent
  }
];

<div [formGroup]="formGroup" class="btn-fund" [ngClass]="{'btn-selected': isSelected}">
  <div>{{definition.description}}</div>
  <div *ngIf="isSelected" class="input-container">
    <label class="switch-value">
      <input #percent formControlName="percent" *ngIf="isPercent" class="form-control" placeholder="%"
             (blur)="roundValue('percent')"/>
      <input #amount formControlName="amount" *ngIf="!isPercent" class="form-control" placeholder="{{currencyCode}}"
             (blur)="roundValue('amount')"/>
    </label>
    <label class="switch">
      <input type="checkbox" formControlName="isPercent" class="form-control">
      <span class="slider" true="%" [attr.false]="currencyCode"></span>
    </label>
  </div>
</div>

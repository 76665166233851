import { State } from "@rgi/rx/state";

export class DialogueHandlerListResponse {
    idClnotifcard: number = null;
    claimNumberPass: string = '';
    potClaimNumberPass: string = '';
    lossDate: number = null;
    licensePlateUS: string = '';
    licensePlateOther: string = '';
    insuranceCodeOther: Etype;
    nameParty: string = '';
    functionCode: Etype;
    dialogueSubjectCode: Etype;
    roleCompany: Etype;
    startDate: number = null;
    lastMsgDate: number = null;
    endDate: number= null;
    cancelDate: number = null;
    // expireDate?: number = null;
    idDialogueMsg: number = null;
    externalKey: string = '';
    externalIdentifyDamageParty: string = '';
    internalKey: string = '';
    internalIndentifyDamageParty: string = '';
    lastMsgCode: string = '';
    lastRespMsgCode: string = '';
    lastMsgUser: string = '';
    lastMsgInternalExpire: number = null;
    lastMsgRegExpire: number = null;
    lastMsgReqData: number = null;
    
    insuranceCodeUS: Etype;
    // associatedActor: string = '';
    
    identifierUniqueCode: string = '';
    typeInsPlate: Etype;
    typePlateCtnp: Etype;
  }
  
  export class Etype {
    code: string = '';
    description: string = '';
  }
  
export class DHOutcome {
  requestOutcome: Etype;
  response: DialogueHandlerListResponse[];
}

export class ClaimModel{
  claim: string = '';
  insuranceCompanyUs: Etype;
  actorInvolved: string = '';
}
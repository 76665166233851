<div class="grid boolean-box"   [attr.reuired]="config?.compulsory">
  <form [formGroup]="group">
    <label class="label-core">
      <lpc-help-icon [helpFile]="config.helpFile" [title]="value"></lpc-help-icon>
      <span>{{value}}</span>
      <lpc-required *ngIf="config?.compulsory" required="true"></lpc-required>
    </label>
    <rgi-rx-button-toggle
      [(ngModel)]="selectedValue"
      [formControlName]="config.code"
      [attr.data-qa]="'factor' + config.code"
      [attr.data-mod] = "'factor' + config.code + modifiable"
      [attr.data-obb] = "'factor' + config.code + mandatory"
      rgiRxGrid="2" style="width: 100%" (onToggle)="onClick()">
      <div rgiRxGridCol *ngFor="let option of options" style="width: 50%">
        <button style="width: 100%" rgi-rx-button rgiRxValueOption [value]="option.value">{{option.label}}</button>
      </div>
  </rgi-rx-button-toggle>
  </form>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import { DocumentWrapperComponent } from './lpc-document-wrapper.component';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LpcDocumentComponent } from './lpc-document/lpc-document.component';
import { LpcDocumentHostDirective } from './directives/lpc-document-host.directive';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { RgiRxExpansionModule, RgiRxPanelModule } from '@rgi/rx/ui';


@NgModule({
    declarations: [
        DocumentWrapperComponent, LpcDocumentHostDirective, LpcDocumentComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        RgiRxI18nModule,
        RgiRxPanelModule,
        RgiRxExpansionModule
    ],
    exports: [
        DocumentWrapperComponent,
        LpcDocumentComponent
    ]
})
export class DocumentModule { }

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AnagFlowData} from '../anag-model/anag-domain/anag-flow-data';
import {AnagRouteData} from '../anag-model/anag-domain/anag-route-data';
import {AnagApiParty} from '../anag-model/anag-domain/anag-api-party';
import {PARTY_CREATED, PartyCreatedEvent} from '../anag-events/anag-event';
import {take} from 'rxjs/operators';
import {RgiRxEventService} from '@rgi/rx';
import {AnagIntegrationService} from "../services/anag-integration.service";

@Injectable({
  providedIn: 'root'
})
export class AnagEditPartyResolver {

  constructor(
    protected eventService: RgiRxEventService,
    protected anagIntegrationService: AnagIntegrationService) {
  }

  editParty(party: AnagApiParty, flowData: AnagFlowData): Observable<any> {
    const party$ = new Subject();
    const routeData = new AnagRouteData();
    routeData.flowData = flowData;
    routeData.party = party;
    routeData.redirectToMainFlow = true;
    this.anagIntegrationService.openEditSubject(routeData);
    this.eventService.listen<PartyCreatedEvent>(PARTY_CREATED).pipe(take(1)).subscribe(eventInstance => {
      const idSessionOld = flowData.idParentSession;
      this.anagIntegrationService.changeSession(eventInstance.event.idSession, idSessionOld);
      party$.next(eventInstance.event.party);
    });
    return party$;
  }
}

<div class="row title-header" style="padding:5px;">
    <div class="col-xs-6 col-md-6">
        <label ng-bind="label">
            {{ '_CLAIMS_._CLAIM_NUMBER' | translate }}: {{claim.claimNumber}}
        </label>
    </div>
    <div class="col-xs-6 col-md-6">
        <label ng-bind="label">
            {{claim.aniaCompanyCode}} - {{companyDescription}}
        </label>
    </div>

</div>
<div class="card shadow table-white">

    <div class="row" style="background-color: #b1cce3; height: 40px;">
        &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
        <label ng-bind="label" style="color:white; font-size: 20px;">{{ '_CLAIMS_._GENERAL_DATA' | translate }}</label>
    </div>
    <div class="container-fluid table-white">
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._POLICY_NUMBER' |
                    translate
                    }}:</label>
                <label ng-bind="label">{{claim.policyNumber}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CONTRACTOR' |
                    translate
                    }}:</label>
                <label ng-bind="label">{{claim.contractor}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PRODUCT' | translate
                    }}:</label>
                <label ng-bind="label">{{claim.product}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._AGENCY' | translate
                    }}:</label>
                <label ng-bind="label">{{claim.intermediary}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._ADMINISTRATIVE_REGULARITY' |
                    translate }}:</label>
                <label ng-bind="label">{{(claim.forcingAdministrative ?'_CLAIMS_._NO': '_CLAIMS_._YES') | translate }}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._TECHNICAL_COVERAGE' |
                    translate }}:</label>
                <label ng-bind="label">{{(claim.technicalCoverage ? '_CLAIMS_._NO': '_CLAIMS_._YES')  | translate}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MINISTERIAL_BRANCH' |
                    translate }}:</label>
                <label ng-bind="label">{{claim.taxBranches}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COINSURANCE_TYPE' |
                    translate }}:</label>
                <label ng-bind="label">{{coinsurenceTypeDef}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COMPLAINT_DATE' | translate
                    }}:</label>
                <label ng-bind="label">{{claim.accountingOpeningDate | date:'dd/MM/yyyy'}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._OCCURRENCE_DATE' | translate
                    }}:</label>
                <label ng-bind="label">{{claim.occurrenceDate| date:'dd/MM/yyyy'}} </label>
            </div>
            <div class="col-xs-3 col-md-3" *ngIf="claim.effectiveLossDate" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._EFFECTIVE_COMPLAINT_DATE' |
                    translate }}:</label>
                <label ng-bind="label">{{claim.occurrenceDateCertified | date:'dd/MM/yyyy'}} </label>
            </div>
            <div class="col-xs-3 col-md-3" *ngIf="claim.claimType" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CLAIM_TYPE' | translate
                    }}:</label>
                <label ng-bind="label">{{claim.claimType}} </label>
            </div>
        </div>
    </div>
    <div *ngIf="UPDATE_RESERVE_OPERATION==='reopeningReactivation'" class="row"
        style="background-color: #b1cce3; height: 40px;">
        &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
        <label ng-bind="label" style="color:white; font-size: 20px;">{{ '_CLAIMS_._REOPENING_DATA' | translate }}
            &nbsp;</label>
    </div>
    <div class="row " *ngIf="UPDATE_RESERVE_OPERATION==='reopeningReactivation'">
        <form [formGroup]="reopenReactivationForm" *ngIf="showFormReopeningReactivation">
            <div class="col-xs-4 customPadding">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._UPDATE_DATE' | translate }}</label>
                    <rgi-rx-date-picker [openOnClick]="false">
                        <input [rgiRxDateTimeInput]="consDate" formControlName="dataReopenReactivation"
                            data-qa="rgi-update-reserve-reopen-date" placeholder="{{datepickerPlaceholder}}"
                            [max]="today" disabled="disabled">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time #consDate></rgi-rx-date-time>
                </rgi-rx-form-field>
            </div>
            <div class="col-xs-4 customPadding" *ngIf="reasonReopeningList.length > 0">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._REOPENING_REASON' | translate }} </label>
                    <select rgiRxNativeSelect formControlName="reasonReopeningCode">
                        <option *ngFor="let reason of reasonReopeningList; " value={{reason.code}}>
                            {{reason.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>
            <div class="col-xs-4 customPadding">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                    <textarea rgiRxInput formControlName="reasonReopeningNote"></textarea>
                </rgi-rx-form-field>
            </div>
        </form>
    </div>

    <div class="row" style="background-color: #b1cce3; height: 40px;">
        &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
        <label ng-bind="label" style="color:white; font-size: 20px;">{{ '_CLAIMS_._SUBJECT' | translate }}
            &nbsp;</label>
    </div>
    <div class="container-fluid table-white" *ngFor="let actor of typeoflossDamageFromActor">
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._NOMINATIVE' | translate
                    }}:</label>
                <label ng-bind="label">{{actor.partyName}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._TAX_CODE__VAT_NUMBER' |
                    translate }}:</label>
                <label ng-bind="label">{{actor.partyCodFisc}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PARTY_ROLE' | translate
                    }}:</label>
                <label ng-bind="label">{{actor.partyRole}} </label>
            </div>
        </div>

        <form [formGroup]="form" (submit)="onSubmit()" class="mbc-20">
            <div *ngFor="let damage of actor.sxTypeOfLossDamage">
                <div class="row center-align">
                    <div style="padding-top: 10px;" class="col-md-3 col-sm-3 col-xs-3 d-flex">
                        <input type='checkbox' checked (focusout)="reflectChangeonForm()" name="selectedTol"
                            class="btn btn-checkbox" formControlName="selectedTol{{damage.idTol}}"
                            id="selectedTol{{damage.idTol}}" validateSelectedTol>
                        <label class="checkbox-label m-0">
                            <ng-container *ngIf="damage.tolDescription">{{ damage.tolDescription }}</ng-container>
                            <ng-container *ngIf="damage.agreementTypeDescription"> - {{ damage.agreementTypeDescription
                                }}</ng-container>
                            <ng-container *ngIf="damage.managementTypeDescription"> - {{
                                damage.managementTypeDescription }}</ng-container>
                        </label>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 h-63"
                        *ngIf="UPDATE_RESERVE_OPERATION !== 'reopeningReactivation'">
                        <div style="font-size: 14px;">
                            <pnc-datepicker (focusout)="reflectChangeonForm()"
                                label="{{ '_CLAIMS_._UPDATE_DATE' | translate }}"
                                customFormControlName="updateDate{{damage.idTol}}" [customFormGroup]="form"
                                attr.data-qa="reserve-data-panel-update-date" [maxDate]="today"
                                id="updateDate{{damage.idTol}}" (dateSelect)="onDateSelect()"
                                [minDate]="generateMinDate(damage.updateDate?.data)"></pnc-datepicker>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._RESERVE_AMOUNT' | translate
                            }}</label>
                        <pnc-required required="true" *ngIf="tolMap.get(damage.idTol)"></pnc-required>
                        <input (focusout)="reflectChangeonForm()" (change)="changeReserveAmount($event.target.value)"
                            [readonly]="!tolMap.get(damage.idTol) || damage.amountTolInput === '0.0'" type="number"
                            step='0.01' class="form-control" formControlName="amountTolInput{{damage.idTol}}"
                            attr.data-qa="external-portfolio-ggDiaria-input" />
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        <label ng-bind="label" style="margin-right: 1%;white-space:nowrap ">{{
                            '_CLAIMS_._SETTLEMENT_COSTS' | translate }}</label>
                        <pnc-required required="true"
                            *ngIf="assignmentExpensesAmountEnabled(damage.idTol)"></pnc-required>
                        <input (focusout)="reflectChangeonForm()" (change)="changeSettlementCosts($event.target.value)"
                            [readonly]="!assignmentExpensesAmountEnabled(damage.idTol) || damage.assignmentExpensesAmount === '0.0'"
                            type="number" step='0.01' class="form-control"
                            formControlName="assignmentExpensesAmount{{damage.idTol}}"
                            attr.data-qa="external-portfolio-ggDiaria-input" />
                    </div>
                </div>
            </div>
        </form>
        <hr>
    </div>
    <div style="margin-top: 20px;" *ngIf="notValidTol &&(form.dirty || form.touched)" class="alert alert-danger">
        <strong> {{ '_CLAIMS_._MESSAGE_._ERROR' | translate }}!</strong> {{ '_CLAIMS_._MESSAGE_._SELECT_AT_LEAST_ONE_REASON' | translate }}!
    </div>
    <div class="container-fluid table-white mbc-20" *ngIf="limits && limits.length > 0 && isManageDamageCard">
        <div class="row">
            <div class="col-md-6 col-sm-9 col-xs-12 table-white semitable-damagecard claim">
                <label class="header-text-damagecard">{{ '_CLAIMS_._MAXIMUM_LEVELS_POLICY_VALUES' | translate }}
                </label>
            </div>
            <div class="col-md-6 col-sm-9 col-xs-12 table-white semitable-damagecard claim">
                <label class="header-text-damagecard">{{ '_CLAIMS_._MAXIMUM_LEVELS_RESIDUE' | translate }} </label>
            </div>
        </div>
        <div class="row" *ngFor="let limit of limits">
            <div class="col-md-3 col-sm-6 col-xs-13" style='padding-left:7px;'>
                {{limit.limitDescription | translate}}:
            </div>

            <div class="col-md-3 col-sm-6 col-xs-13">
                <span *ngIf="!(limit.isCounter.boolean)">{{(limit.limitValue | currency:'EUR') || '-'}}</span>
                <span *ngIf="limit.isCounter.boolean">{{limit.limitValue | number:'1.0-0'|| '-'}}</span>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-13" style='padding-left:7px;'>
                {{limit.limitDescription | translate}}:
            </div>

            <div class="col-md-3 col-sm-6 col-xs-13">
                <span *ngIf="limit.limitAmountResidue >= 0 && !(limit.isCounter.boolean)">{{(limit.limitAmountResidue |
                    currency:'EUR')
                    || '-'}}</span>
                <span *ngIf="limit.limitAmountResidue < 0 && !(limit.isCounter.boolean)"
                    class="negative-residue">{{(limit.limitAmountResidue |
                    currency:'EUR') || '-'}}</span>
                <span *ngIf="limit.limitAmountResidue >= 0 && limit.isCounter.boolean">{{limit.limitAmountResidue |
                    number:'1.0-0'
                    || '-'}}</span>
                <span *ngIf="limit.limitAmountResidue < 0 && limit.isCounter.boolean"
                    class="negative-residue">{{limit.limitAmountResidue | number:'1.0-0' || '-'}}</span>
            </div>
        </div>
    </div>
    <div class="btn-header-claim containerButtons">
        <div class="btn-group btn-group-justified btn-group-justified-claim">
            <div class="btn-group">
                <button rgi-rx-button color="primary" (click)="exit('back')" class="w-100-custom">
                    {{ '_CLAIMS_._BUTTONS_._BACK' | translate }}
                    </button>
            </div>
            <div class="btn-group">
              <button rgi-rx-button color="primary" [disabled]="
                (
                    (UPDATE_RESERVE_OPERATION === 'reopeningReactivation'
                        && reopenReactivationForm.invalid)
                        ||
                    (UPDATE_RESERVE_OPERATION !== 'reopeningReactivation'
                    &&
                    (!formValid || notValidTol))
                    && !amountValid
                )
                " class="w-100-custom" type="submit"
                      (click)="onSubmit()"
                      translate> {{'_CLAIMS_._BUTTONS_._SAVE'}}</button>
            </div>
        </div>
    </div>
</div>

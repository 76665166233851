import { Category, State } from "../../shared/dsc-shared-model/dsc-model-utility";

export class FilterApplied {
  contractType: string;
  searchParentNodes: boolean;
  salePointId: string;
  salePointCode: string;
  contractTodayEffectDate: boolean;
  policyType: string;
  categories: Category[] = [];
  effectDateTo: null | string;
  expirationDateFrom: null | string;
  states: State[] = [];
  policyNumber: string;
  referenceDate: string;
}




export class OutputGetContext {
  code: string;
  description: string;
  id: string;
}
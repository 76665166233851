<div class="modal">
  <div class="modal-dialog nmf_withDialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Discounts Management</span>
        </h3>
      </div>
      <div class="modal-body">

        <p class="text-modal-quote-flex"><span translate>Annual gross premium (before charges)</span> (€)
        </p>
        <div [formGroup]="discountsForm" *ngIf="discountsForm"
             class="container-fluid quote-flex-table quote-proposal-flex padding-bottom-10">
          <div class="row bg-blue2 hidden-xs">
            <div class="col-sm-2"></div>
            <div class="col-sm-2"><span translate>Premium</span></div>
            <div class="col-sm-4"><span translate>Flexibility with discount</span></div>
            <div class="col-sm-4"><span translate>Flexibility without discount</span></div>
          </div>

          <div class="row bg-grey hidden-xs">
            <div class="col-sm-2"></div>
            <div class="col-sm-2"><span translate>Gross</span></div>
            <div class="col-sm-4 no-border">
              <div class="row">
                <div class="col-sm-4">%&nbsp;<span translate>max</span></div>
                <div class="col-sm-4">%&nbsp;<span translate>discount</span></div>
                <div class="col-sm-4">€&nbsp;<span translate>discount</span></div>
              </div>
            </div>
            <div class="col-sm-4 no-border">
              <div class="row">
                <div class="col-sm-4">%&nbsp;<span translate>max</span></div>
                <div class="col-sm-4">%&nbsp;<span translate>discount</span></div>
                <div class="col-sm-4">€&nbsp;<span translate>discount</span></div>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let section of sectionsDiscounts">
            <div>
              <div class="row bg-grey">
                <div class="col-sm-12 hidden-xs bg-blue20">{{ section.sectionDescription }}</div>
                <div class="col-x2-12 nmf_visible-xs-block bg-blue2">{{ section.sectionDescription }}</div>
              </div>
              <ng-container *ngFor="let unit of section.unitDiscounts">
                <div class="row bg-grey">
                  <div class="col-sm-2 hidden-xs flexRowMinHeight">{{ unit.unitDescription }}</div>
                  <div class="col-xs-12 nmf_visible-xs-block bg-blue20">{{ unit.unitDescription }}</div>

                  <div class="col-xs-12 nmf_visible-xs-block"><strong translate>Premium</strong></div>
                  <div class="col-xs-12 col-sm-2">
                    <div class="nmf_visible-xs-block pull-left"><span translate="">Gross</span></div>
                    <div class="pull-right lineMinHeight">
                      {{ unit.grossPremium | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;
                    </div>
                  </div>

                  <div class="col-xs-12 nmf_visible-xs-block"><strong
                    translate>Flexibility with discount</strong></div>
                  <div class="col-xs-12 col-sm-4 no-border">
                    <div class="row">
                      <div class="col-xs-12 col-sm-4">
                        <div class="nmf_visible-xs-block pull-left">%&nbsp;<span
                          translate>max</span>
                        </div>
                        <div *ngIf="unit.flex1Enable" class="pull-right lineMinHeight">
                          {{ unit.flex1Max | number : '1.2-2':'it' }}&nbsp;%
                        </div>
                        <div *ngIf="!unit.flex1Enable" class="pull-right lineMinHeight">
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-4">
                        <div class="nmf_visible-xs-block pull-left">%&nbsp;<span
                          translate>discount</span>
                        </div>
                        <div *ngIf="!unit.flex1Enable" class="pull-right lineMinHeight">
                        </div>
                        <div *ngIf="unit.flex1Enable && editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          {{ unit.flex1Perc | number : '1.2-2':'it'}}&nbsp;
                        </div>
                        <div *ngIf="unit.flex1Enable && !editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          <div class="lineMinHeight">
                            <input [formControlName]="'flex1Perc-' + section.sectionCode + '-' + unit.unitCode" [value]="unit.flex1Perc"
                                   class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                   [max]="getMaximumDiscount(unit, 'flex1Perc')"
                                   step=".01"
                                   type="number">
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12 col-sm-4">
                        <div class="nmf_visible-xs-block pull-left">&nbsp;<span
                          translate>discount</span>
                        </div>
                        <div *ngIf="!unit.flex1Enable" class="pull-right lineMinHeight">
                        </div>
                        <div *ngIf="unit.flex1Enable && editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          {{ unit.flex1Taxable | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;
                        </div>
                        <div *ngIf="unit.flex1Enable && !editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          <div class="lineMinHeight">
                            <input [formControlName]="'flex1Taxable-' + section.sectionCode + '-' + unit.unitCode"
                                   [value]="unit.flex1Taxable"
                                   class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                   step=".01"
                                   type="number"
                                   [max]="getMaximumDiscount(unit, 'flex1Taxable')">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 nmf_visible-xs-block"><strong
                    translate>Flexibility withoutdiscount</strong></div>
                  <div class="col-xs-12 col-sm-4 no-border">
                    <div class="row">
                      <div class="col-xs-12 col-sm-4">
                        <div class="nmf_visible-xs-block pull-left">%&nbsp;<span
                          translate>max</span>
                        </div>
                        <div *ngIf="!unit.flex2Enable" class="pull-right lineMinHeight">
                        </div>
                        <div *ngIf="unit.flex2Enable" class="pull-right lineMinHeight">
                          {{ unit.flex2Max > 0 ? unit.flex2Max : unit.flex2Min | number : '1.2-2' :'it' }}&nbsp;%
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-4">
                        <div class="nmf_visible-xs-block pull-left">%&nbsp;<span
                          translate>discount</span>
                        </div>
                        <div *ngIf="!unit.flex2Enable" class="pull-right lineMinHeight">
                        </div>
                        <div *ngIf="unit.flex2Enable && editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          {{ unit.flex2Perc | number : '1.2-2' :'it'}}&nbsp;
                        </div>
                        <div *ngIf="unit.flex2Enable && !editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          <div class="lineMinHeight">
                            <input [formControlName]="'flex2Perc-' + section.sectionCode + '-' + unit.unitCode" [value]="unit.flex2Perc"
                                   class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                   [max]="getMaximumDiscount(unit, 'flex2Perc')"
                                   step=".01"
                                   type="number">
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-4">
                        <div class="nmf_visible-xs-block pull-left">&nbsp;<span
                          translate>discount</span>
                        </div>
                        <div *ngIf="!unit.flex2Enable" class="pull-right lineMinHeight">
                        </div>
                        <div *ngIf="unit.flex2Enable && editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          {{ unit.flex2Taxable | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;
                        </div>
                        <div *ngIf="unit.flex2Enable && !editOfTotalValuesEnabled"
                             class="pull-right lineMinHeight">
                          <div class="lineMinHeight">
                            <input [formControlName]="'flex2Taxable-' + section.sectionCode + '-' + unit.unitCode"
                                   [value]="unit.flex2Taxable"
                                   class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                   step=".01"
                                   type="number"
                                   [max]="getMaximumDiscount(unit, 'flex2Taxable')">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="row bg-grey">
            <div class="col-sm-2 col-xs-12 bg-blue2 flexRowMinHeight"><strong translate>Total</strong>
            </div>

            <div class="col-xs-12 nmf_visible-xs-block"><strong translate>Premium</strong></div>
            <div class="col-xs-12 col-sm-2 bg-blue2 bg-xs-grey">
              <div class="nmf_visible-xs-block pull-left"><span translate>Gross</span></div>
              <div class="pull-right lineMinHeight"><strong
                class="color-xs-blue2">{{ totalDiscounts.totalGrossPremium | currency:'EUR':'symbol':'1.2-2':'it'  }}&nbsp;</strong>
              </div>
            </div>

            <div class="col-xs-12 nmf_visible-xs-block"><strong translate>Flexibility with
              discount</strong></div>
            <div class="col-xs-12 col-sm-4 no-border bg-blue2 bg-xs-grey">
              <div class="row">
                <div class="col-xs-12 col-sm-4">
                  <div class="nmf_visible-xs-block pull-left">%&nbsp;<span translate>max</span>
                  </div>
                  <div class="pull-right lineMinHeight">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                  <div class="nmf_visible-xs-block pull-left">%&nbsp;<span translate>discount</span>
                  </div>
                  <div  class="pull-right lineMinHeight">
                    <div class="lineMinHeight" *ngIf="editOfTotalValuesEnabled">
                      <input (change)="onDiscountChange('totalPerc1')" [formControlName]="'totalPerc1'" [value]="totalDiscounts.totalPerc1"
                             class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                             max="100"
                             step=".01"
                             type="number">
                    </div>
                  </div>

                </div>
                <div class="col-xs-12 col-sm-4">
                  <div class="nmf_visible-xs-block pull-left">&nbsp;<span translate>discount</span>
                  </div>
                  <div *ngIf="!editOfTotalValuesEnabled" class="pull-right lineMinHeight">
                    <span *ngIf="!proposalService.getIsInclusionApplication()">
                      {{ totalDiscounts.totalAmount1| currency:'EUR':'symbol':'1.2-2':'it'   }}
                    </span>
                  </div>
                  <div *ngIf="editOfTotalValuesEnabled" class="pull-right lineMinHeight">
                    <div class="lineMinHeight">
                      <input (change)="onDiscountChange('totalAmount1')" [formControlName]="'totalAmount1'" [value]="totalDiscounts.totalAmount1"
                             class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                             step=".01"
                             type="number">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 nmf_visible-xs-block"><strong
              translate>Flexibility without discount</strong></div>
            <div class="col-xs-12 col-sm-4 no-border bg-blue2 bg-xs-grey">
              <div class="row">
                <div class="col-xs-12 col-sm-4">
                  <div class="nmf_visible-xs-block pull-left">%&nbsp;<span translate>max</span>
                  </div>
                  <div class="pull-right lineMinHeight">
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                  <div class="nmf_visible-xs-block pull-left">%&nbsp;<span translate>discount</span>
                  </div>
                  <div class="pull-right lineMinHeight">
                    <div class="lineMinHeight" *ngIf="editOfTotalValuesEnabled">
                      <input (change)="onDiscountChange('totalPerc2')" [formControlName]="'totalPerc2'" [value]="totalDiscounts.totalPerc2"
                             class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                             max="100"
                             step=".01"
                             type="number">
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                  <div class="nmf_visible-xs-block pull-left">&nbsp;<span translate>discount</span>
                  </div>
                  <div *ngIf="!editOfTotalValuesEnabled" class="pull-right lineMinHeight">
                    {{ totalDiscounts.totalAmount2 | currency:'EUR':'symbol':'1.2-2':'it'  }}
                  </div>
                  <div *ngIf="editOfTotalValuesEnabled" class="pull-right lineMinHeight">
                    <div class="lineMinHeight">
                      <input (change)="onDiscountChange('totalAmount2')" [formControlName]="'totalAmount2'" [value]="totalDiscounts.totalAmount2"
                             class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                             step=".01"
                             type="number">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nmf_cont_moredetails" *ngIf="!proposalService.getIsInclusionApplication()">
          <div class="unit-container selected">
                        <span (click)="toggleAccordion()" [attr.aria-controls]="1" [attr.aria-expanded]="open"
                              [class.rgi-minus]="open" [class.rgi-plus]="!open" class="icon rgifont"></span>

            <b (click)="toggleAccordion()" [attr.aria-controls]="1" [attr.aria-expanded]="open"
               class="selected" style="font-size: 18px; padding-left: 10px;"
               translate>Residual Discounts</b>
          </div>
          <div [ngbCollapse]="!open" id="1">
            <mic-residual-discount [arrayOfElements]="tableElement" [nestedTableHeader]="nestedTableHeader"
                                   [tableHeader]="tableHeader">
            </mic-residual-discount>
            <mic-residual-discount *ngIf="tableElementInherited && tableElementInherited.length > 0"
                                   [arrayOfElements]="tableElementInherited"
                                   [tableHeader]="tableHeaderInherited"
                                   [viewAccordion]="false">
            </mic-residual-discount>
          </div>
        </div>
        <div *ngIf="validationMessages.length > 0" id="error-container" style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            type="error"></pnc-form-message>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="save()" class="btn btn-warning text-uppercase" translate>Calculate</button>
          </div>
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning text-uppercase" translate>Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
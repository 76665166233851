import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RgiRxTranslationService } from "@rgi/rx/i18n";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class CacheService {
 
  private _cache: Map<string,any>;
 
  constructor(
    private httpClient: HttpClient,
    protected translate: RgiRxTranslationService,
    ) {
    this._cache = new Map<string,any>();
  }

  private getContext(context?: string): string {
    const contextPrefix =  '_cachecontext_';
    return !!context ? contextPrefix + context : contextPrefix + 'global';
  } 
  private isInContext(uri: string, context?: string): boolean {
    return !!uri && uri.endsWith(this.getContext(context));
  } 

  private getUri(url: string, context?: string)  {
    return url + this.getContext(context);
  }

  public getResource(url: string, context?: string, idMsgErrDefault?: string): Observable<any> {

    let result: Observable<any> = this.get(url,context);
    if (!result) {
      result = this.httpClient.get<any>(url)
    } else {
      return of(result);
    }

    return result.pipe(
      catchError(error => throwError(error))
      ,switchMap((res: any) => {
        if (!res) {
          return EMPTY;
        }
        if (!!res.error) {
          return of(res);
        } else {
          this.set(url,res,context)
          return of(this.get(url,context));
        }
      })
    );
  }

  public set(id: string, value: any, context?: string) {
    this._cache.set(this.getUri(id,context),value);
  }

  public get(id: string,  context?: string): any {
    return this._cache.get(this.getUri(id,context));
  }

  public delete(id: string, context?: string) {
    this._cache.delete(this.getUri(id,context));
  }

  public clear() {
    this._cache.clear();
  }

  public clearContext(context: string) {

    for (const [key, _] of this._cache) {
      if (this.isInContext(key, context)) {
        this._cache.delete(key);
      }
    }
  }

}

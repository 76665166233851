import { ActiveRoute, Routes } from '@rgi/rx/router';
import { CardAnalyticsHomeComponent } from './card-analytics-home/card-analytics-home.component';
import { CardAnalyticsDashboardComponent } from './card-analytics-dashboard/card-analytics-dashboard.component';
import { CardAnalyticsStateManagerHome } from './card-analytics-state/card-analytics-state-manager-home';
import { StateStoreService } from '@rgi/rx/state';
import { CardAnalyticsReducerService } from './card-analytics-state/card-analytics-reducer.service';
import { CardAnalyticsNewReportComponent } from './card-analytics-new-report/card-analytics-new-report.component';
import { CardAnalyticsEditReportComponent } from './card-analytics-edit-report/card-analytics-edit-report.component';
import { CardAnalyticsStateManagerDashboard } from './card-analytics-state/card-analytics-state-manager-dashboard';
import { ReportService } from './services/report.service';
import { CardAnalyticsStateManagerNewReport } from './card-analytics-state/card-analytics-state-manager-newreport';
import { CardAnalyticsStateManagerEditReport } from './card-analytics-state/card-analytics-state-manager-editreport';
import {
  ANALYTICS_ROUTE_DASHBOARD,
  ANALYTICS_ROUTE_EDITREPORT,
  ANALYTICS_ROUTE_HOME,
  ANALYTICS_ROUTE_NEWREPORT
} from './constants/analytics-routes-constants';


export const CARD_ANALYTICS_ROUTES: Routes = [
  {
    route: ANALYTICS_ROUTE_HOME,
    component: CardAnalyticsHomeComponent,
    providers: [
      {
          deps: [ActiveRoute, StateStoreService, CardAnalyticsReducerService, ReportService],
          provide: CardAnalyticsStateManagerHome,
          useClass: CardAnalyticsStateManagerHome
      }
    ],
    children: [
      {
        route: ANALYTICS_ROUTE_DASHBOARD,
        component: CardAnalyticsDashboardComponent,
        providers: [
          {
            provide: CardAnalyticsStateManagerDashboard,
            useClass: CardAnalyticsStateManagerDashboard,
            deps: [ActiveRoute, StateStoreService, CardAnalyticsReducerService]
          }
        ]
      },
      {
        route: ANALYTICS_ROUTE_NEWREPORT,
        component: CardAnalyticsNewReportComponent,
        providers: [
          {
            provide: CardAnalyticsStateManagerNewReport,
            useClass: CardAnalyticsStateManagerNewReport,
            deps: [ActiveRoute, StateStoreService, CardAnalyticsReducerService]
          }
        ]
      },
      {
        route: ANALYTICS_ROUTE_EDITREPORT,
        component: CardAnalyticsEditReportComponent,
        providers: [
          {
            provide: CardAnalyticsStateManagerEditReport,
            useClass: CardAnalyticsStateManagerEditReport,
            deps: [ActiveRoute, StateStoreService, CardAnalyticsReducerService, ReportService]
          }
        ]
      },
    ]
  }
];

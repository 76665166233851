export class GPRecoverAppsPayload {
  substitutionType: GPRecoverAppsSubstitutionType;
  renumberApps: boolean;
  generateTechAcc: boolean;
}

export enum GPRecoverAppsSubstitutionType {
  FULL = 'FULL',
  INCREMENTAL = 'INCREMENTAL'
}

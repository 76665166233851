import {downgradeInjectable} from '@angular/upgrade/static';
import {PortalConfig} from '@rgi/rx/portal';
import {PncPostSalesOrchestratorService} from '@rgi/pnc-postsales';

declare var angular: angular.IAngularStatic;

export function downgradePncPostsalesService(config?: PortalConfig) {
  angular.module(config.module)
    .factory('PncPostSalesOrchestratorService', downgradeInjectable(PncPostSalesOrchestratorService));
}

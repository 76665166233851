<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="true" #first color="info">
  <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
    <div class="row rgi-claims-title-section-shared" style="padding:5px;">
      <div class="col-xs-12 col-md-12">
        <label ng-bind="label">
          {{ ('_CLAIMS_._POLICY_HOLDER' | translate) +'/'+('_CLAIMS_._INSURED' | translate) }}
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="subject"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion">
  <div class="row rgi-claims-sub-title-section-shared">
    <div class="col-xs-12 col-md-12">
      <label ng-bind="label">
        {{ '_CLAIMS_._POLICY_HOLDER' | translate }}
      </label>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="subject"></ng-container>
</div>

<ng-template #subject>
  <div class="row">
    <div class="col-sm-6 col-xs-12">
      <claims-subject-action [subject]="policyHolder" [selectedNode]="selectedNode" [activeRouteId]="activeRouteId"
        disabled="true"></claims-subject-action>
    </div>
    <div class="col-sm-6 col-xs-12" *ngIf="policyHolder?.subject?.anagSubject">
      <button rgi-rx-button color="secondary" (click)="openDetail()">
        {{ '_CLAIMS_._DETAILS' | translate }}
        <span class="glyphicon glyphicon-pencil"></span>
      </button>
    </div>
  </div>
  <claims-subject-info [subject]="policyHolder"></claims-subject-info>
  <claims-subject-form [fields]="fields" [selectedNode]="selectedNode"
    [activeRouteId]="activeRouteId"  [subject]="{
      isOwner: policyHolder.isOwner,
      isDriver: policyHolder.isDriver,
      isPassenger: policyHolder.isPassenger,
      chkMaterial: policyHolder.chkMaterial,
      materialDescription: policyHolder.materialDescription,
      chkInjury: policyHolder.chkInjury,
      isIncapacitated: policyHolder.isIncapacitated,
      isMinor: policyHolder.objectId != '' && policyHolder.objectId == roles.owner.objectId && policyHolder.isMinor
    }" 
    [listMinorGuardian]="listMinorGuardian" (outputDataForm)="outputFormChange($event, listMinorGuardian)"
    (outputListMinorGuardian)="listMinorGuardianChange($event)">
  </claims-subject-form>
  <div *ngIf="chkInjury">
    <claims-dsc-phisical-injury 
      [expanded]="chkInjury"
      [injuryAssetInput]="policyHolder.subjectDataFormInjury" [selectedNode]="selectedNode"
      [activeRouteId]="activeRouteId" [selectedPolicy]="selectedPolicy" [categorySelected]="categorySelected"
      [categoryList]="categoryList" [subject]="policyHolder"
      [heirsList]="heirsList"
      (outputHeir)="outputHeirData($event)"
      (outputInjuryData)="outputFormChangeInjury($event)"></claims-dsc-phisical-injury>
  </div>
</ng-template>
import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditablePanelContentComponent, PanelService, PanelSignal } from '@rgi/digital-claims-common-angular';
import { AddDocumentDataComponent } from '../add-document-data/add-document-data.component';
import { ClauseEntity } from '../domain/clause-entity';
import { DocumentEntity } from '../domain/document-entity';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { SubjectEntity } from '../domain/subject-entity';
import { NewPaymentService } from '../new-payment.service';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-document-choice',
  templateUrl: './document-choice.component.html',
  styleUrls: ['./document-choice.component.scss']
})
export class DocumentChoiceComponent extends EditablePanelContentComponent implements OnInit {
  @Input() card: any;
  @Input() potPayFE: InitPotentialPaymentEntity;
  @Input() newPayForm: UntypedFormGroup;
  @Output() saveEmitter = new EventEmitter<boolean>();
  @Output() stepperEventEmitter = new EventEmitter<number>();
  public docPresent: boolean = false;
  public legalPresent: boolean = false;
  public legalList: Array<SubjectEntity> = [];
  tipoPagFattura: TypeEnum;
  tipoPagNoFattura: TypeEnum;

  constructor(
    panelService: PanelService,
    @Inject('eventService') private eventService: any,
    public translateService: RgiRxTranslationService,
    private modalService: NgbModal,
    private newPaymentService: NewPaymentService,
    private paymentConstants: PaymentConstants
  ) {
    super(panelService);
  }

  initEnums() {
    this.tipoPagFattura = this.paymentConstants.getSingleTipoPagFattura(ENUMSLISTPAYMENT.TIPO_PAG_FATTURA);
    this.tipoPagNoFattura = this.paymentConstants.getSingleTipoPagFattura(ENUMSLISTPAYMENT.TIPO_PAG_NO_FATTURA);
  }

  ngOnInit() {
    this.initEnums();
  }

  formatString(str: string) {
    return (str == null || str === 'null' || str === 'null null') ? '' : str;
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (this.acceptSignals) {
      if (panelSignal === PanelSignal.open) {
        console.log('creazione componente lettere - open');

        this.enableAndOpenContent();
        this.setPanelIncomplete();
      }
      if (panelSignal === PanelSignal.init) {
        console.log('creazione componente lettere - init');

        this.eventService.broadcastEvent('start-loader');
        let numPay = 0;
        this.legalPresent = false;
        if (this.potPayFE.professionistList) {
          this.legalList = this.potPayFE.professionistList.filter(subj => subj.type === 'LAV' && subj.name);
          if (this.legalList && this.legalList.length > 0) {
            this.legalPresent = true;
          }
        }
        this.potPayFE.paymentList.forEach(aPotPay => {
          numPay++;
          const typePay = this.potPayFE.paymentTypeList.find(el => el.eTypeCode === aPotPay.paymentType).fee ?
            this.tipoPagFattura.eTypeCode :
            this.tipoPagNoFattura.eTypeCode;

          this.newPaymentService
            .getPotentialDocuments(this.potPayFE.uuid,
              typePay,
              this.potPayFE.claimNumber).subscribe((res: Array<DocumentEntity>) => {
                aPotPay.documentList = res;
                let i = 0;
                aPotPay.documentList.forEach(aDoc => {
                  aDoc.clauses = new Array<ClauseEntity>();
                  // si deve richiamare il servizio per la ricerca delle clausole (campi dinamici)
                  this.newPaymentService.getPrintableClauses(aDoc.code).subscribe(
                    (result: any) => {
                      if (result && result.questionnaire && result.questionnaire != null) {
                        result.questionnaire.questions.forEach(quest => {
                          if (quest.answers && quest.answers.length > 0) {
                            const clause = new ClauseEntity();
                            clause.text = quest.text;
                            const answ5 = quest.answers.find(el => el.factorType === '5');
                            const answ3 = quest.answers.find(el => el.factorType === '3');
                            clause.answerCode = (answ3) ? answ3.factorCode : answ5.factorCode;
                            clause.type = (answ5) ? answ5.factorType : answ3.factorType;
                            clause.answerDescr = (answ5) ? answ5.factorDescr : answ3.factorDescr;
                            clause.questionCode = quest.code;
                            aDoc.clauses.push(clause);
                          }
                        });
                      }

                      if (aDoc.clauses.length === 0) {
                        aDoc.clauses = null;
                      }
                    },
                    (error: any) => {

                    }
                  );

                  aDoc.documentId = aPotPay.id + '*' + i;
                  this.newPayForm.addControl('docSel' + aDoc.documentId,
                    new UntypedFormControl({ value: aDoc.selected, disabled: false }, Validators.nullValidator));
                  i++;
                  this.newPayForm.addControl('legalAt' + aDoc.documentId,
                    new UntypedFormControl({ vaule: null, disabled: false }, Validators.nullValidator));
                  this.newPayForm.controls['legalAt' + aDoc.documentId].setValue(null);
                  if (this.legalList && this.legalList.length === 1) {
                    this.newPayForm.controls['legalAt' + aDoc.documentId].setValue(this.legalList[0].idSubject);
                  }
                  i++;
                  this.docPresent = true;
                });
                if (numPay === this.potPayFE.paymentList.length) {
                  if (!this.docPresent) {
                    this.onConfirm();
                  }
                  this.eventService.broadcastEvent('stop-loader');
                }

              },
                (error: any) => {
                  this.eventService.broadcastEvent('stop-loader');
                });
        });
      }
    }
  }

  additionalData(aDoc: DocumentEntity) {
    const modalRef = this.modalService.open(AddDocumentDataComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.document = aDoc;
  }

  protected validateContent(): boolean {
    this.translateService.translate('_CLAIMS_._MESSAGE_._METHOD_NOT_IMPLEMENTED').subscribe(
      res =>  {  
        return new Error(res)
      }
    )
    throw new Error('')
  }
  protected storeData() {
    this.translateService.translate('_CLAIMS_._MESSAGE_._METHOD_NOT_IMPLEMENTED').subscribe(res=> {
      throw new Error(res);
    }
    )
  }

  onConfirm() {
    let visConfirm = false;
    this.potPayFE.paymentList.forEach(aPotPay => {
      let aDocSel = false;
      aPotPay.documentList.forEach(aDoc => {
        aDoc.selected = this.newPayForm.controls['docSel' + aDoc.documentId].value;
        aDoc.subjectAt = this.legalList.find(el => el.idSubject === this.newPayForm.controls['legalAt' + aDoc.documentId].value);
        if (aDoc.selected) {
          aDocSel = true;
        }
      });
      if (aPotPay.documentList.length > 0 && !aDocSel) {
        visConfirm = true;
      }
    });


     let confirm: string = '';
     this.translateService.translate('_CLAIMS_._MESSAGE_._THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE').subscribe(res=> {
      confirm = res;
    }
  )
    if (!visConfirm ||
      (visConfirm && confirm))
        {
      this.saveEmitter.emit(true);
      this.eventService.broadcastEvent('start-loader');

      // Cycle example
      this.setPanelComplete();
      this.disablePanelContent();
      this.stepperEventEmitter.emit(5);
      this.signalToNextPanel(PanelSignal.open);
      this.signalToNextPanel(PanelSignal.init);
      // this.signalToPanel('payment-method', PanelSignal.init);
      this.eventService.broadcastEvent('stop-loader');
    }
  }

}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PV_TOKEN } from '../models/consts/lpc-consts';
import { HttpClient } from '@angular/common/http';
import { ErrorType, OwnerType, PropertyType } from '../models/enums/vita.enum';

export interface CustomPropertyData {
  booleanValue?: boolean;
  errorMessages: {
    errorDescription: string, errorType: ErrorType
  }[];
}

@Injectable()
export class CustomPropertyService {

  private readonly baseApiUrl: string;

  constructor(
    @Inject(PV_TOKEN.ENV) environment: any,
    private httpClient: HttpClient
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  /**
   * Retrieves a custom property based on the provided parameters.
   *
   * @param propertyCode - The code of the custom property.
   * @param propertyType - The type of the custom property.
   * @param ownerType - The type of the owner.
   * @param prodCode - The code of the product.
   * @returns An Observable that emits the custom property data.
   */
  public getCustomProperty(propertyCode: string, propertyType: PropertyType, prodCode: string, ownerType: OwnerType): Observable<CustomPropertyData> {
    let request = '/v2/life/properties?codiceProprieta=' + propertyCode;
    if (ownerType) {
      request += request.endsWith('&') ? 'tipoOwner=' + ownerType : '&tipoOwner=' + ownerType;
    }
    if (prodCode) {
      request += request.endsWith('&') ? 'codiceProdotto=' + prodCode : '&codiceProdotto=' + prodCode;
    }
    if (propertyType) {
      request += request.endsWith('&') ? 'propertyType=' + propertyType : '&propertyType=' + propertyType;
    }
    return this.httpClient.get<CustomPropertyData>(this.baseApiUrl + request);
  }

}

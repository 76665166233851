<div class="container-fluid">

  <re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels"></re-issue-stepper>

  <lib-re-issue-card-document [isProposal]="false" [policy]="policy" [movementId]="movementId"
                              [isReprint]="true"></lib-re-issue-card-document>

  <div class="col-xs-12 mt-3">
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button class="btn btn-secondary pull-right" type="submit"
                (click)="goToClientDetails()">{{ 'Go to client details' | translate | uppercase }}</button>
      </div>
    </div>
  </div>
</div>

import {Directive, ElementRef, Input} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Directive({
    selector: 'ppevo-navbtn'
  })
  export class PPEVONavigationBtnDirective {

    @Input() btnId:string;
    @Input() disabled:boolean = false;
    @Input() ngClass:any;
    @Input() ngStyle:any;
    label:SafeHtml;

    constructor(public hostElement: ElementRef, private sanitizer:DomSanitizer) {

    }


    ngAfterViewInit(){

      setTimeout(
        ()=>{
          this.label = this.sanitizer.bypassSecurityTrustHtml(this.hostElement.nativeElement.innerHTML || this.btnId )
        }
      );

    }


  }

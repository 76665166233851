import {DatePipe} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {DashboardNavigationData} from '../state-manager/dashboard-data-state';
import {DashboardCardState} from '../state-manager/dashboard-manager.service';
import {DashboardSearchData} from '../model/dashboard-search-data';
import {DashboardSummary} from '../model/dashboard-summary';
import {DashboardSummaryData} from '../model/dashboard-summary-data';
import {DashboardElem} from '../model/dashboard-element';
import {Company} from '../const/company';
import {ProductsObject} from '../model/products-object';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardResourceService {

  private baseApiUrlV2;

  constructor(protected http: RgiRxHttpClientWrapper,
              protected datePipe: DatePipe,
              @Inject('environment') protected apiConf: any) {
    this.baseApiUrlV2 = apiConf.api.portal.path + '/v2';
  }

  getCompaniesUri() {
    return '/api/rest/admin/companies';
  }


  getCompanies$(): Observable<Array<Company>> {
    return this.http.get<any>(this.getCompaniesUri()).pipe(share(), map(val => {
      return val.companies;
    })
    );
  }

  getProducts$(st: DashboardCardState, node: string): Observable<ProductsObject> {
    const body = {
      productsFilter: {
        idSp: node,
        filterProductsCategory: '1', // questo sarebbe in base a individuale,collettivo (non avendo collettivo è statico)
        filterOnlyDamagesProducts: true,
        filterSalableProduct: true,
        filterSalableProductTypes: true,
        effectiveDate: new Date()
      }
    };
    return this.http.post<any>(this.getProductsUri(), body).pipe(share(), map(val => {
      const objeProd: ProductsObject = new ProductsObject();
      val.products.forEach(element => {
        objeProd.products.push(element.product);
        objeProd.productsType.push(element.productType);
      });
      return objeProd;
    })
    );
  }

  getOperations$(st: DashboardCardState, selectable: boolean): Observable<Array<DashboardElem>> {
    const queryString = '?onlySelectable=' + selectable;
    return this.http.get<any>(this.getOperationsUri() + queryString).pipe(share(), map(operations => {
          return operations;
        })
    );
  }

  getActions$(st: DashboardCardState): Observable<Array<DashboardElem>> {
    return this.http.get<any>(this.getActionsUri()).pipe(share(), map(actions => {
          return actions;
        })
    );
  }

  getNotes$(st: DashboardCardState): Observable<Array<DashboardElem>> {
    return this.http.get<any>(this.getNotesUri()).pipe(share(), map(notes => {
          return notes;
        })
    );
  }

  getOperationsUri() {
    return this.baseApiUrlV2 + '/icd/commissiondashboard/operations';
  }

  getActionsUri() {
    return this.baseApiUrlV2 + '/icd/commissiondashboard/actions';
  }
  getNotesUri() {
    return this.baseApiUrlV2 + '/icd/commissiondashboard/notes';
  }

  getProductsUri() {
    return this.apiConf.api.portal.path + '/admin/products';
  }

  getStartDataUri() {
    return this.baseApiUrlV2 + '/icd/commissiondashboard/start-data';
  }

  getSummaryUri() {
    return this.baseApiUrlV2 + '/icd/commissiondashboard/summary';
  }

  getCommissionsUri() {
    return this.baseApiUrlV2 + '/icd/commissiondashboard/commissions';
  }

  getRiskDetailUri(idDashBoard: string) {
    return this.baseApiUrlV2 + '/icd/commissiondashboard/commissions/' + idDashBoard + '/riskcommissions';
  }

  getStartData$(): Observable<DashboardSearchData> {
    return this.http.get<any>(this.getStartDataUri()).pipe(share(), map(val => {
      return val;
    })
    );
  }

  getCommissions$(formData: DashboardNavigationData, offset: string, limit: string): Observable<any> {

    let queryString = '?company=' + formData.company.objectId;
    queryString += '&functionality=' + formData.functionality.id;
    if (formData.action) {
      queryString += '&action=' + formData.action.id;
    }
    if (formData.company.objectId) {
      queryString += '&company=' + formData.company.objectId;
    }
    if (formData.mismatchAmountFrom) {
      queryString += '&mismatchAmountFrom=' + formData.mismatchAmountFrom;
    }
    if (formData.mismatchAmountTo) {
      queryString += '&mismatchAmountTo=' + formData.mismatchAmountTo;
    }
    if (formData.product) {
      queryString += '&productCode=' + formData.product.code;
    }
    if (formData.status) {
      queryString += '&status=' + formData.status.id;
    }
    if (formData.competenceDateFrom) {
      queryString += '&competenceDateFrom=' + this.datePipe.transform(new Date(Number(formData.competenceDateFrom)), 'yyyy-MM-dd');
    }
    if (formData.competenceDateTo) {
      queryString += '&competenceDateTo=' + this.datePipe.transform(new Date(Number(formData.competenceDateTo)), 'yyyy-MM-dd');
    }
    if (formData.type != null) {
      queryString += '&type=' + formData.type;
    }
    if (offset != null) {
      queryString += '&offset=' + offset;
    }
    if (limit != null) {
      queryString += '&limit=' + limit;
    }

    return this.http.get<any>(this.getCommissionsUri() + queryString).pipe(share(), map(val => {
      return val;
    }));

  }

  putCommissions$(bodyRequest) {
    return this.http.put<any>(this.getCommissionsUri(), bodyRequest).pipe(share(), map(val => {
      return val;
    }));
  }

  getSummaryRiskDetail$(idDashBoard: string, type: string): Observable<any> {
    const queryString = '?type=' + type;
    return this.http.get<any>(this.getRiskDetailUri(idDashBoard) + queryString).pipe(share(), map(val => {
      return val;
    }));

  }

  getSummary$(formData: DashboardNavigationData): Observable<DashboardSummary> {

    let queryString = '?company=' + formData.company.objectId;
    queryString += '&functionality=' + formData.functionality.id;
    queryString += '&action=' + formData.action;
    if (formData.company.objectId) {
      queryString += '&company=' + formData.company.objectId;
    }
    if (formData.mismatchAmountFrom) {
      queryString += '&mismatchAmountFrom=' + formData.mismatchAmountFrom;
    }
    if (formData.mismatchAmountTo) {
      queryString += '&mismatchAmountTo=' + formData.mismatchAmountTo;
    }
    if (formData.product) {
      queryString += '&productCode=' + formData.product.code;
    }
    if (formData.status) {
      queryString += '&status=' + formData.status;
    }
    if (formData.competenceDateFrom) {
      queryString += '&competenceDateFrom=' + this.datePipe.transform(new Date(Number(formData.competenceDateFrom)), 'yyyy-MM-dd');
    }
    if (formData.competenceDateTo) {
      queryString += '&competenceDateTo=' + this.datePipe.transform(new Date(Number(formData.competenceDateTo)), 'yyyy-MM-dd');
    }
    if (formData.type != null) {
      queryString += '&type=' + formData.type;
    }

    return this.http.get<any>(this.getSummaryUri() + queryString).pipe(share(), map(val => {
      return val;
    }));

  }


    // TODO: MOCKED
  /*  const summary = new DashboardSummary();
    const companyMainData = new DashboardSummaryData();
    companyMainData.movements = 58;
    companyMainData.grossPremium = 165602.92;
    companyMainData.commission = 32404.12;
    const bankMainData = new DashboardSummaryData();
    bankMainData.movements = 57;
    bankMainData.grossPremium = 165102.92;
    bankMainData.commission = 30085.19;
    summary.companyMainData = companyMainData;
    summary.bankMainData = bankMainData;
    const totData = new DashboardSummaryData();
    totData.grossPremium = 167102.92;
    totData.commission = 32904.12;
    totData.description = 'Rendiconto / appendice da Compagnia';
    summary.totData = totData;
    const details = this.mockedDetails();
    summary.details = details;
    return of(summary);
  } */

  mockedDetails(): Array<DashboardSummaryData> {
    const details = new Array<DashboardSummaryData>();
    const abbinatiPerfettamente = new DashboardSummaryData();
    details.push(abbinatiPerfettamente);
    const abbinatiConAbbuono = new DashboardSummaryData();
    details.push(abbinatiConAbbuono);
    const abbinatiConDatiTecniciDifferenti = new DashboardSummaryData();
    details.push(abbinatiConDatiTecniciDifferenti);
    const abbinatiForzatiBanca = new DashboardSummaryData();
    details.push(abbinatiForzatiBanca);
    const abbinatiForzatiCompagnia = new DashboardSummaryData();
    details.push(abbinatiForzatiCompagnia);
    const abbinatiForzatoManualmente = new DashboardSummaryData();
    details.push(abbinatiForzatoManualmente);
    const nonAbbinatoPerDatobancaMancante = new DashboardSummaryData();
    details.push(nonAbbinatoPerDatobancaMancante);
    const nonAbbinatoPerDatoBancaMancanteAbb = new DashboardSummaryData();
    details.push(nonAbbinatoPerDatoBancaMancanteAbb);
    const nonAbbinatoPerDatoBancaMancanteAnalizzato = new DashboardSummaryData();
    details.push(nonAbbinatoPerDatoBancaMancanteAnalizzato);
    const nonAbbinatoPerDatoCompMancante = new DashboardSummaryData();
    details.push(nonAbbinatoPerDatoCompMancante);
    const nonAbbinatoPerDatoCompMancanteAbb = new DashboardSummaryData();
    details.push(nonAbbinatoPerDatoCompMancanteAbb);
    const nonAbbinatoPerDatoCompMancanteAnalizzato = new DashboardSummaryData();
    details.push(nonAbbinatoPerDatoCompMancanteAnalizzato);
    const provvDiverse = new DashboardSummaryData();
    details.push(provvDiverse);
    const provvDiverseDaAnalizzare = new DashboardSummaryData();
    details.push(provvDiverseDaAnalizzare);
    const provvDiverseInAnalisi = new DashboardSummaryData();
    details.push(provvDiverseInAnalisi);
    const delta = new DashboardSummaryData();
    details.push(delta);

    abbinatiPerfettamente.movements = 46;
    abbinatiPerfettamente.grossPremium = 162102.92;
    abbinatiPerfettamente.commission = 29085.19;
    abbinatiPerfettamente.description = 'Abbinati perfettamente';
    abbinatiPerfettamente.id = 1;

    abbinatiConAbbuono.movements = 1;
    abbinatiConAbbuono.commission = 1;
    abbinatiConAbbuono.description = 'Abbinati con abbuono';
    abbinatiConAbbuono.id = 2;

    abbinatiConDatiTecniciDifferenti.movements = 2;
    abbinatiConDatiTecniciDifferenti.commission = 1;
    abbinatiConDatiTecniciDifferenti.description = 'Abbinati con dati tecnici differenti';
    abbinatiConDatiTecniciDifferenti.id = 3;

    abbinatiForzatiBanca.movements = 1;
    abbinatiForzatiBanca.commission = 100;
    abbinatiForzatiBanca.description = 'Abbinati forzato banca';
    abbinatiForzatiBanca.id = 4;

    abbinatiForzatiCompagnia.movements = 1;
    abbinatiForzatiCompagnia.commission = 200;
    abbinatiForzatiCompagnia.description = 'Abbinati forzato compagnia';
    abbinatiForzatiCompagnia.id = 5;

    abbinatiForzatoManualmente.movements = 1;
    abbinatiForzatoManualmente.commission = 500;
    abbinatiForzatoManualmente.description = 'Abbinati forzati manualmente';
    abbinatiForzatoManualmente.id = 6;

    nonAbbinatoPerDatobancaMancante.description = 'Non abbinato per dato banca mancante';
    nonAbbinatoPerDatobancaMancante.id = 7;

    nonAbbinatoPerDatoBancaMancanteAbb.movements = 2;
    nonAbbinatoPerDatoBancaMancanteAbb.commission = 1000;
    nonAbbinatoPerDatoBancaMancanteAbb.grossPremium = 2000;
    nonAbbinatoPerDatoBancaMancanteAbb.description = 'a) da analizzare';
    nonAbbinatoPerDatoBancaMancanteAbb.id = 8;
    nonAbbinatoPerDatoBancaMancanteAbb.parent = 7;

    nonAbbinatoPerDatoBancaMancanteAnalizzato.commission = 0;
    nonAbbinatoPerDatoBancaMancanteAnalizzato.description = 'b) in corso di analisi';
    nonAbbinatoPerDatoBancaMancanteAnalizzato.id = 9;
    nonAbbinatoPerDatoBancaMancanteAnalizzato.parent = 7;

    nonAbbinatoPerDatoCompMancante.description = 'Non abbinato per dato compagnia mancante';
    nonAbbinatoPerDatoCompMancante.id = 10;

    nonAbbinatoPerDatoCompMancanteAbb.movements = -1;
    nonAbbinatoPerDatoCompMancanteAbb.commission = -1500;
    nonAbbinatoPerDatoCompMancanteAbb.grossPremium = -500;
    nonAbbinatoPerDatoCompMancanteAbb.description = 'a) da analizzare';
    nonAbbinatoPerDatoCompMancanteAbb.id = 11;
    nonAbbinatoPerDatoCompMancanteAbb.parent = 10;

    nonAbbinatoPerDatoCompMancanteAnalizzato.commission = 0;
    nonAbbinatoPerDatoCompMancanteAnalizzato.description = 'b) in corso di analisi';
    nonAbbinatoPerDatoCompMancanteAnalizzato.id = 12;
    nonAbbinatoPerDatoCompMancanteAnalizzato.parent = 10;

    provvDiverse.description = 'Provv. diverse';
    provvDiverse.id = 13;

    provvDiverseDaAnalizzare.movements = 8;
    provvDiverseDaAnalizzare.grossPremium = 1018.92;
    provvDiverseDaAnalizzare.description = 'a) da analizzare';
    provvDiverseDaAnalizzare.id = 14;
    provvDiverseDaAnalizzare.parent = 13;

    provvDiverseInAnalisi.commission = 0;
    provvDiverseInAnalisi.description = 'b) in corso di analisi';
    provvDiverseInAnalisi.id = 15;
    provvDiverseInAnalisi.parent = 13;

    delta.movements = 1;
    delta.commission = 1518.93;
    delta.grossPremium = 500;
    delta.description = 'Delta compagnia vs Banca';
    delta.id = 16;

    /* tot.commission = 32.904,12;
    tot.grossPremium = 167.102,92;
    tot.description = 'Rendiconto / appendice da Compagnia'; */

    return details;
  }
}

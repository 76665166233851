import { ActiveRoute, Routes } from '@rgi/rx/router';
import { OccurrenceDataModifyComponent } from './occurrence-data-modify.component';
import { OccurrenceDataModifyState } from './state-service/occurrence-data-modify.state.manager';
import { StateStoreService } from '@rgi/rx/state';
import { OccurrenceDataModifyService } from './state-service/occurrence-data-modify.service';

/*
il router è fondamentale perché oltre a definire la rotta in cui atterrerò ed il component che verrà aperto.
ho la psosibilità di definire i providere e le dependence del component. in questo modo non ho bisogno di ridichiararle all'interno.
ad esempio deps: [ActiveRoute, StateStoreService, OccurrenceDataModifyService]
non ho bisogno di importare i moduli di activeRoute e stateStoreService nel mio modulo avendole inserite qui.
*/

export const OCCURRENCE_DATA_ROUTES: Routes = [
    {
        route: 'claimsClaimEdit',
        component: OccurrenceDataModifyComponent,
        providers: [
            {
                provide: OccurrenceDataModifyState,
                useClass: OccurrenceDataModifyState,
                deps: [ActiveRoute, StateStoreService, OccurrenceDataModifyService]
            }
        ]
    }
];

import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcRequiredComponent } from './lpc-required.component';

@NgModule({
    imports: [
        CommonModule,
        RgiRxI18nModule
    ],
    exports: [LpcRequiredComponent],
    declarations: [LpcRequiredComponent]
})
export class LpcRequiredModule { }

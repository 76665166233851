<form [formGroup]="variableControlForm">

  <div [ngSwitch]="variable?.type">

    <div *ngSwitchCase="'0'">
      <rgi-rx-form-field>
        <label rgiRxLabel
               translate>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
        <!--
                <pnc-required required="true" *ngIf="variable?.compulsory"></pnc-required>
        -->
        <select rgiRxNativeSelect (change)="onVariableValueChange()" [id]="variableCode"
                [formControlName]="variableCode"
                [attr.disabled]="isVariableDisabled(variable)"
                *ngIf="!(variable?.variableClass === 8 || variable?.variableClass === 7)">
          <option [value]="-1"></option>
          <option *ngFor="let value of variable?.values; index as i; trackBy: genericEntitiesTrackByFn"
                  [value]="value?.id">
            {{ value?.description }}</option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'1'">
      <rgi-rx-form-field>
        <label rgiRxLabel
               translate>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
        <!--
                <pnc-required required="true" *ngIf="variable?.compulsory"></pnc-required>
        -->

        <input rgiRxInput (blur)="onVariableValueChange()" [id]="variableCode" type="string"
               [formControlName]="variableCode"
               [attr.disabled]="isVariableDisabled(variable)"
               *ngIf="showFormattedInput(variable)" [value]="formattedVariableValue(variable)">

        <input rgiRxInput (blur)="onVariableValueChange()" [id]="variableCode" type="number"
               [formControlName]="variableCode"
               [attr.disabled]="isVariableDisabled(variable)"
               *ngIf="showUnFormattedInput(variable)">

        <select rgiRxNativeSelect (change)="onVariableValueChange()" [id]="variableCode"
                [formControlName]="variableCode"
                [attr.disabled]="isVariableDisabled(variable)"
                *ngIf="(variable.values?.length>0 && !variable.format)">
          <option [value]="-1"></option>
          <option *ngFor="let value of variable?.values; index as i; trackBy: genericEntitiesTrackByFn"
                  [value]="value?.id">
            {{ value?.description }}</option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'2'">
      <rgi-rx-form-field>
        <label rgiRxLabel
               translate>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
        <!--
                <pnc-required required="true" *ngIf="variable?.compulsory"></pnc-required>
        -->
        <input rgiRxInput (blur)="onVariableValueChange()" [id]="variableCode" type="number"
               [formControlName]="variableCode"
               [attr.disabled]="isVariableDisabled(variable)"
               *ngIf="(variable.values?.length==0)" [required]="variable?.compulsory">

        <select rgiRxNativeSelect (change)="onVariableValueChange()" [id]="variableCode"
                [formControlName]="variableCode"
                [attr.disabled]="isVariableDisabled(variable)"
                *ngIf="(variable.values?.length>0)">
          <option [value]="-1"></option>
          <option *ngFor="let value of variable?.values; index as i; trackBy: genericEntitiesTrackByFn"
                  [value]="value?.id">
            {{ value?.description }}</option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'3'">
      <rgi-rx-form-field>
        <label rgiRxLabel
               translate>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
        <!--
                <pnc-required required="true" *ngIf="variable?.compulsory"></pnc-required>
        -->
        <select rgiRxNativeSelect (change)="onVariableValueChange()" [id]="variableCode"
                [formControlName]="variableCode"
                [attr.disabled]="isVariableDisabled(variable)">

          <option [value]="-1"></option>
          <option
            *ngFor="let variableValue of variable?.values; index as i; trackBy: genericEntitiesTrackByFn"
            [value]="variableValue?.id">
            {{variableValue?.description}}
          </option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'4'">
      <rgi-rx-form-field class="rgi-re-display-contents">
        <div class="rgi-re-display-block">
          <label rgiRxLabel translate>
            {{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}
          </label>
          <div class="rgi-re-display-flex">
            <input [rgiRxDateTimeInput]="variableDatePicker"
                   style="flex: 1" [id]="variableCode"
                   [formControlName]="variableCode"
                   selectMode="single"
                   rangeSeparator="/"
                   data-qa="variableDate"
                   placeholder="gg/mm/yyyy" #inputToTriggerVariableDate="rgiRxDateTimeInput">
            <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerVariableDate"></button>
          </div>
        </div>
        <rgi-rx-date-time #variableDatePicker
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'5'">
      <rgi-rx-form-field>
        <label rgiRxLabel
               translate>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
        <!--
                <pnc-required required="true" *ngIf="variable?.compulsory"></pnc-required>
        -->
        <input rgiRxInput (blur)="onVariableValueChange()" [id]="variableCode" type="string"
               [formControlName]="variableCode"
               [attr.disabled]="isVariableDisabled(variable)"
               [required]="variable?.compulsory">
      </rgi-rx-form-field>
    </div>

  </div>

</form>

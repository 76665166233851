<!-- <div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{ '_CLAIMS_._VERIFICHE_ACCESSORIE' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
        </h3>
    </div>
    <form [formGroup]="formGroup">
        <div class="modal-body container-fluid">
            <div *ngIf="showForceAdmCoverage">
                <span class="header-text">Forzatura Scopertura Amministrativa</span>
            </div>
            <div>
                <span class="header-text">Conferma Responsabilità</span>
        </div>
    </form>
</div>



</div>
<div class="modal-footer">
    <div class="btn-group btn-group-justified btn-group-justified-claim">
        <div class="btn-group">
            <button class="btn btn-warning pull-right" (click)="closeModal()"
                attr.data-qa="claim-opening-policy-details-close-btn">{{ '_CLAIMS_._CONFERMA' | translate }}</button>
        </div>
    </div>
</div> -->
<div class="grid-item card card-height-normal card-main">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title">{{ '_CLAIMS_._ACCESSORY_CHECKS' | translate }}
        <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
      </h3>
    </div>
    <form [formGroup]="formGroup">
      <div class="modal-body" style="padding-top: 5px">
        <div *ngIf="showForceAdmCoverage">
            <h4 class="text-info bold">{{ '_CLAIMS_._FORCING_APPLY' | translate }}</h4>
            <label>{{ '_CLAIMS_._ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO' | translate }}:
              {{claimCurrent.policyNumber}}</label>
            <div class="form-group row">
              <div class="col-sm-6 col-xs-12">
                <pnc-datepicker label="{{ '_CLAIMS_._EFFECTIVE_DATE_OF_COLLECTION' | translate }}"
                                customFormControlName="effectiveCollectionDate" [customFormGroup]="formGroup"
                                [disabled]="true" id="effectiveCollectionDate">
                </pnc-datepicker>
              </div>
              <div class="col-sm-6 col-xs-12">
                <pnc-datepicker label="{{ '_CLAIMS_._EXPIRY_DATE_OF_COLLECTION' | translate }}"
                                customFormControlName="expiryCollectionDate" [customFormGroup]="formGroup"
                                [disabled]="true" id="expiryCollectionDate">
                </pnc-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-6 col-xs-12">
                <pnc-datepicker label="{{ '_CLAIMS_._DATE_OF_COLLECTION' | translate }}"
                                customFormControlName="collectionDate" [customFormGroup]="formGroup"
                                [minDate]="minCollectionDate" [maxDate]="maxCollectionDate" id="collectionDate">
                </pnc-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-6 col-xs-12">
                <label ng-bind="label">{{ '_CLAIMS_._NOTES' | translate }}</label>
                <pnc-required required="true"></pnc-required>
                <textarea class="col-sm-16 form-control" formControlName="note" id="description" rows="3"
                          type="text" name="description"></textarea>
              </div>
            </div>
        </div>
        <div *ngIf="showConfirmResp">
            <h4 class="text-info bold">{{ '_CLAIMS_._CONFIRM_RESPONSIBILITY' | translate }}</h4>
            <div class="form-group row">
              <div class="col-sm-6 col-xs-12">
                <rgi-rx-form-field>
                <label rgiRxLabel style="margin-right: 1%; ">{{ '_CLAIMS_._PERCENTAGE_INSURED_LIABILITY' | translate }}</label>
                <input rgiRxInput [disabled]="!this.formGroup.controls.applyLiabilityPercentageForcing || !this.formGroup.controls.applyLiabilityPercentageForcing.value" type="number"
                      class="form-control"
                      (input)="onInsuredLiabilityPercentageChange($event)"
                      formControlName="insLiabilityPercentage"/>
                </rgi-rx-form-field>
              </div>
              <div class="col-sm-6 col-xs-12">
                <label ng-bind="label">{{ '_CLAIMS_._LIABILITY_TYPE' | translate }}</label>
                <select [value]="this.formGroup.controls.liabilityType.value" class="core-select form-control" formControlName="liabilityType">
                  <option value="MAGGIORITARIO">MAGGIORITARIO</option>
                  <option value="MINORITARIO">MINORITARIO</option>
                  <option value="PARITARIO">PARITARIO</option>

                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-6 col-xs-12" style="display: flex; align-items: center">
                <input (change)="onLiabilityPercentageForcingChange($event)" type="checkbox" class="form-control btn-checkbox checkbox-unit"
                      formControlName="applyLiabilityPercentageForcing">
                <label class="checkbox-label" style="margin-left: 1vw">
{{ '_CLAIMS_._APPLY_PERCENTAGE_LIABILITY_FORCING' | translate }}</label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-xs-12">
                <rgi-rx-form-field>
                <label rgiRxLabel>{{ '_CLAIMS_._REASON_TO_FORCE_LIABILITY_PERCENTAGE' | translate }}</label>
                <textarea required="{{this.formGroup.controls.applyLiabilityPercentageForcing}}" rgiRxInput [disabled]="!this.formGroup.controls.applyLiabilityPercentageForcing" class="col-sm-16 form-control" formControlName="forceReason"
                          rows="3" type="text"></textarea>
                </rgi-rx-form-field>
              </div>
            </div>

            <!--veicoli controparte-->
            <div class="col-xs-12">
              <label ng-bind="label">{{ '_CLAIMS_._COUNTERPART_VEHICLE' | translate }}</label>
            </div>
            <div class="col-xs-12" style="font-family: DINPro-Medium" *ngFor="let vehicle of this.otherPartiesLiability | keyvalue" >
              <!--targa/telaio-->
              <div class="col-xs-6">
                <!--label-->
                <div class="col-xs-12">
                  <label rgiRxLabel>
                  {{ '_CLAIMS_._CHASSIS__PLATE_NUMBER' | translate }}
                  </label>
                </div>

                <div class="col-xs-12" style="margin-top:1vh">
                  <div class="col-xs-12">
                    {{vehicle.value.partyVehicle}}
                  </div>
                </div>

                </div>

              <div class="col-xs-6">
                <label rgiRxLabel>{{ '_CLAIMS_._LIABILITY_PERCENTAGE' | translate }} (%)</label>
                <input [disabled]="!this.formGroup.controls.applyLiabilityPercentageForcing.value" type="number"
                      class="form-control"
                      [value]="this.formGroup.controls.otherPartiesLiability.value[vehicle.value.licensePlate]"
                      (input)="onOtherVehicleLiabilityPercentageChange($event, vehicle.value)"

                />
              </div>
            </div>

            <!-- <div class="col-xs-12">
                <progress max="100" value="{{recalculateSumPercentages()}}"></progress>
              </div> -->
        </div>
        <div *ngIf="errorMessage" class="content-error validate-error">
          <span class="rgifont rgi-exclamation-triangle"></span>
          <span>{{errorMessage | translate}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group" *ngIf="this.formGroup.controls.applyLiabilityPercentageForcing">
            <!--[disabled]="(this.formGroup.controls.liabilityType && !this.formGroup.controls.liabilityType.value)"-->
            <button [disabled]="(this.showConfirmResp && this.sumPercentages.toString()!== '100' ||
            (this.formGroup.controls.applyLiabilityPercentageForcing.value &&
            (!this.formGroup.controls.forceReason.value ||
            this.formGroup.controls.forceReason.value?.replaceAll('\s','') === ''))) || (this.showForceAdmCoverage && !this.showConfirmResp)"
                    class="btn btn-warning text-uppercase" (click)="onConfirm()" translate>{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}
            </button>
          </div>
          <div class="btn-group" *ngIf="!this.formGroup.controls.applyLiabilityPercentageForcing">
            <!--[disabled]="(this.formGroup.controls.liabilityType && !this.formGroup.controls.liabilityType.value)"-->
            <button
                    class="btn btn-warning text-uppercase" (click)="onConfirm()" translate>{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

import { Component, EventEmitter, Inject, Optional } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';
import { BaseEntity } from '../../../group-policy-models/group-policy-issue-policy-data';

@Component({
  selector: 'rgi-gp-group-policy-available-assets-modal',
  templateUrl: './group-policy-modal-available-assets.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyModalAvailableAssetsComponent implements OnModalClose {

  modalClose: EventEmitter<any> = new EventEmitter<string[] | void>();
  availableAssets: BaseEntity[];
  addAssetForm: UntypedFormGroup;
  atLeastOneAssetValidatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let atLeastOneAsset = false;
    const formGroup = control as UntypedFormGroup;
    if (formGroup && formGroup.controls) {
      Object.keys(formGroup.controls).forEach(asset => {
        if (formGroup.get(asset).value) {
          atLeastOneAsset = true;
        }
      });
      return !atLeastOneAsset ? { atLeastOneAsset: true } : null;
    }
    return null;
  }

  constructor(
    @Optional() @Inject(DIALOG_DATA) data: any,
  ) {
    this.addAssetForm = new UntypedFormGroup({}, { validators: this.atLeastOneAssetValidatorFn });
    this.availableAssets = data.availableAssets;
    if (this.availableAssets && this.availableAssets.length) {
      this.availableAssets.forEach(asset => {
        this.addAssetForm.addControl(asset.code, new UntypedFormControl(undefined));
      });
    }
  }

  onConfirm() {
    const form = this.addAssetForm;
    if (form) {
      const assetCodes: string[] = [];
      Object.keys(form.controls).forEach(asset => {
        if (form.get(asset).value) {
          assetCodes.push(asset);
        }
      });
      this.modalClose.emit(assetCodes);
    }
  }

}

import { Component, EventEmitter, forwardRef, Inject, Input, Optional, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Risk } from '../../models/postsales-operations-response.model';
import { LpcUnitTableComponent } from './../lpc-unit-table/lpc-unit-table.component';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';


export interface UnitOpen {
  open: boolean;
  subUnits: Map<string, boolean>;
}

@Component({
  selector: 'lpc-vprin-unit-table',
  templateUrl: './vprin-unit-table.component.html',
  styleUrls: ['./vprin-unit-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcVprinTableComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcVprinTableComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcVprinTableComponent extends LpcUnitTableComponent {

  @Input() risks: Risk[];
  @Input() investedAmountOnQuotation: number;
  @Input() openSelectedUnits = false;
  @Output() saveSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateUnitTable = new EventEmitter<any>();

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    super(currencyService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /** @Override */
  initializeNumOfCols() {
    return 4;
  }

}

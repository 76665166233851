import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActiveRoute, RoutableComponent } from '@rgi/rx/router';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs/operators';
import { GroupPolicyDateAdapter } from '../../adapters/ngbDateCustomParserFormatter ';
import { FACTOR_TYPE } from '../../group-policy-constants/factors';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import { Factor } from '../../group-policy-models/group-policy-issue-policy-data';
import { DatesPayload } from '../../group-policy-models/group-policy-vcont-date';
import { GroupPolicyStateVcontDate } from '../../group-policy-state/group-policy-state';
import { GroupPolicyStateManagerDate } from '../../group-policy-state/group-policy-statemanager-date/group-policy-stateless-op-date';
;


/**
 * @author: dmasone
 * @description: Component used for render vcont date step
 */
@Component({
  selector: 'rgi-gp-group-policy-vcont-date',
  templateUrl: './group-policy-vcont-date.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyVcontDateComponent extends RoutableComponent implements OnInit, OnDestroy {

    public vertical = true;
    public showIndex = false;
    public stateVcontDate: GroupPolicyStateVcontDate;
    public tmpFactorsCode: Factor[] = [];
    public payload: DatesPayload[] = [];
    public previousStepData: DataForSteps;
    public reload = true;
    public dateForm = new UntypedFormGroup({});

    constructor(protected activeRoute: ActiveRoute,
        protected dateAdapter: GroupPolicyDateAdapter,
        protected stateManagerVcontDate: GroupPolicyStateManagerDate) {
        super();
    }

    ngOnInit(): void {
        this.previousStepData = this.activeRoute.getRouteData();
        this.stateManagerVcontDate.getState$().pipe(
            tap((state: GroupPolicyStateVcontDate) => {
                console.log(state);
            })
        ).subscribe((nextState: GroupPolicyStateVcontDate) => {
            this.initPage(nextState);
        });
    }

    ngOnDestroy(): void { }

    protected initPage(nextState: GroupPolicyStateVcontDate) {
        this.stateVcontDate = nextState;
        if (this.stateVcontDate.factors && this.reload) {
            this.deleteFactorsFromForm();
            this.addFactorsToForm(this.stateVcontDate.factors);
            this.reload = false;
        }
    }

    public updateFactors(factor: Factor) {  // TODO: To be changed if factors in page become dynami
        console.log(factor);
    }

    protected fillPayload() {
        this.tmpFactorsCode.forEach((singleFactor: Factor, index) => {
            if (singleFactor && singleFactor.code) {
                this.tmpFactorsCode[index].value = (singleFactor.type === FACTOR_TYPE.DATE) ?
                    this.dateAdapter.toApi(this.dateForm.get(singleFactor.code).value) :
                    this.dateForm.get(singleFactor.code).value;
            }
            this.payload.push({ code: this.tmpFactorsCode[index].code, value: this.tmpFactorsCode[index].value } as DatesPayload);
        });
    }

    public callActionGoForword() {
        this.payload = [];
        this.fillPayload();
        this.stateManagerVcontDate.actionGoForword(this.payload);
    }

    public callActionClose() {
        this.stateManagerVcontDate.actionClose(this.previousStepData);
    }

    protected deleteFactorsFromForm() {
        this.tmpFactorsCode.forEach((factor: Factor) => {
            if (factor && factor.code) {
                this.dateForm.removeControl(factor.code);
            }
        });
    }

    protected addFactorsToForm(factors: Factor[]) {
        this.tmpFactorsCode = [];
        factors.forEach((factor: Factor) => {
            const value = (factor.type === FACTOR_TYPE.DATE) ? this.dateAdapter.fromModelNotStdRX(factor.value) : factor.value;
            this.tmpFactorsCode.push(cloneDeep(factor));
            this.dateForm.addControl(
                factor.code, new UntypedFormControl(
                    { value, disabled: !factor.editable },
                    { updateOn: factor.type === 'DATE' ? 'blur' : 'change' })
            );
            const validators = [];
            if (factor.mandatory) {
                validators.push(Validators.required);
            }
            if (factor.pattern) {
                validators.push(Validators.pattern(factor.pattern));
            }
            this.dateForm.controls[factor.code].setValidators(validators);
        });
    }

}

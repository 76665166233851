import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { UtilitiesService } from '../utilities.service';
import { AlertService } from '../../services/alert.service';
import { formatDate } from '@angular/common';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Injectable()
/**
 * @deprecated libraries should not provide interceptors for handling errors
 */
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    public translateService: RgiRxTranslationService,
    @Inject(LOCALE_ID) private locale: string) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => { }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          let message = `${err.message}`;
          if (err.headers.get('rgi_executionid')) {
            const format = 'yyyy-MM-dd HH:mm:ss';
            const now = formatDate(new Date(), format, this.locale);
            message = err.headers.get('rgi_executionid') + ' - ' + now;
          }
          console.log(UtilitiesService.isErrorInterceptorDisable(req));
          if (err.status === 400 && err.error.hasOwnProperty('messages')) {
            message = err.error.messages;
          }
          if (!(UtilitiesService.isErrorInterceptorDisable(req) && err.status === 400)) {
            console.log('error interceptor disabled by request');
            this.alertService.translatedMessage(this.translateService.translate('_CLAIMS_COMMON_._MESSAGES_._GENERIC_ERROR') +
              ': ' + message);
          }
        }
      }));
  }

}

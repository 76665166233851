import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Warning} from '../../models/domain-models/warning';
import {share} from 'rxjs/operators';
import {REST_API_ANAG_WARNINGS_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueWarningsResourceService {
  protected apiWrningsConf: any;

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any,
  ) {
    this.apiWrningsConf = REST_API_ANAG_WARNINGS_CONF;
  }

  get(resourceId: string): Observable<Warning[]> {
    return this.http.get<Warning[]>(this.variablesUri(resourceId)).pipe(share());
  }

  protected variablesUri(resourceId: string): string {
    return this.apiConf.api.portal.path + this.apiWrningsConf.path + resourceId + this.apiWrningsConf.warningsPath;
  }

  put(resourceId: string, level: string): Observable<Warning[]> {
    return this.http.put<Warning[]>(this.warningUri(resourceId, level), null).pipe(share());
  }

  protected warningUri(resourceId: string, level: string): string {
    return this.apiConf.api.portal.path + this.apiWrningsConf.path + resourceId + this.apiWrningsConf.warningsPath + '?level=' + level;
  }

}

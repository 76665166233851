import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ClauseModalComponent} from './clause-modal/clause-modal.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Clause, ClauseOutput} from '../../models/domain-models/clause';
import {ModalService} from '@rgi/rx/ui';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';

@Component({
  selector: 're-issue-clauses',
  templateUrl: './re-issue-clauses.component.html',
  styleUrls: ['./re-issue-clauses.component.css']
})

export class ReIssueClausesComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() showTitle: boolean;
  @Input() clausesList: Array<Clause>;
  @Input() assetKey?: string;
  @Input() disabled?: boolean;
  @Output() updateClause: EventEmitter<ClauseOutput> = new EventEmitter<ClauseOutput>();
  clausesForm: UntypedFormGroup;
  errorMsg = 'RE_ISSUE.MANDATORY_TEXT';

  constructor(protected customModalService: ModalService,
              protected errorService: PushMessageHandlerService) {
  }

  ngOnInit() {
    this.createClausesForm();

    this.clausesList.forEach(clause => {
      this.clausesForm.controls[clause.code + '_selected']?.valueChanges.subscribe(selected => {
        clause.selected = selected;
        this.updateTextMandatory(clause);
        this.updateClause.emit(new ClauseOutput(clause, this.assetKey, this.clausesForm.valid));
      });
    });

  }

  ngAfterViewInit() {
    this.updateClause.emit(new ClauseOutput(null, this.assetKey, this.clausesForm.valid));
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes.disabled && this.clausesForm){
      this.clausesList.forEach(clause => {
        if(this.disabled){
          this.clausesForm.controls[clause.code + '_selected']?.disable();
        } else {
          this.clausesForm.controls[clause.code + '_selected']?.enable();
        }
      });
    }
    if(changes.clausesList && !changes?.clausesList.firstChange &&
      changes?.clausesList.currentValue?.length != changes?.clausesList.previousValue?.length){
      this.updateClausesForm();
    }
    this.updateClauseErrorMessage();
  }

  createClausesForm() {
    const group: any = {};
    this.clausesList.forEach(clause => {
      this.addFormControlToFormGroup(clause, group) });
    this.clausesForm = new UntypedFormGroup(group);
  }

  updateClausesForm() {
    // add new controls
    this.clausesList.forEach(clause => {
      if (!this.clausesForm.controls[clause.code + '_selected']) {
        this.addFormControlToFormGroup(clause, this.clausesForm.controls);
        this.clausesForm.controls[clause.code + '_selected']?.valueChanges.subscribe(selected => {
          clause.selected = selected;
          this.updateTextMandatory(clause);
          this.updateClause.emit(new ClauseOutput(clause, this.assetKey, this.clausesForm.valid));
        });
      }
    });
    // remove controls
    Object.keys(this.clausesForm.controls).filter(k => k.includes('_selected')).forEach(k => {
      const code = k.split('_')[0];
      if(!this.clausesList.find(c => c.code === code)){
        this.clausesForm.removeControl(k);
        this.clausesForm.removeControl(code + '_text');
      }
    });
  }

  addFormControlToFormGroup(clause: Clause, group: any) {
    group[clause.code + '_selected'] = new UntypedFormControl({
      value: clause.selected,
      disabled: !clause.enable && clause.selected || this.disabled
    }, clause.compulsory ? Validators.required : null);
    group[clause.code + '_text'] = new UntypedFormControl(clause.text, clause.selected && clause.mandatoryText ? Validators.required : null);
  }

  openClause(clause: Clause) {
    const {modal, component} = this.customModalService.openComponent(ClauseModalComponent);
    component.clause = clause;
    modal.onClose.subscribe(text => {
      if (text) {
        clause.text = text;
        this.clausesForm.controls[clause.code + '_text']?.patchValue(text);
        this.updateClause.emit(new ClauseOutput(clause, this.assetKey, this.clausesForm.valid));
      }
    });

  }

  updateClauseErrorMessage() {
    if (this.clausesList && this.clausesForm) {
      this.clausesList.forEach(clause => {
        if (clause.selected && !clause.text && clause.mandatoryText) {
          this.clausesForm.controls[clause.code + '_text']?.setErrors({errors: true});
          const msg: PushMessage = new PushMessage();
          const opts = {icon: 'rgi-ui-icon-alert'};
          msg.tag = clause.code;
          msg.status = 'danger';
          msg.content = this.errorMsg;
          msg.dismissible = false;
          msg.options = opts;
          this.errorService.notify(msg);
        } else {
          this.clausesForm.controls[clause.code + '_text']?.setErrors(null);
          this.errorService.clearTag(clause.code);
        }
      });
    }
  }

  updateTextMandatory(clause: Clause) {
    if (clause.selected && !clause.text && clause.mandatoryText) {
      this.clausesForm.controls[clause.code + '_text']?.setValidators([Validators.required]);
      this.clausesForm.controls[clause.code + '_text']?.setErrors({errors: true});
    } else {
      this.clausesForm.controls[clause.code + '_text']?.clearValidators();
      this.clausesForm.controls[clause.code + '_text']?.setErrors(null);
      this.errorService.clearTag(clause.code);
    }
  }
}

import {Directive, Input} from '@angular/core';
import {RgiRxFormControl} from './rgi-rx-form-elements-api';
import {RgiRxFormControlDirective} from './rgi-rx-form-control.directive';

@Directive({
  host: {
    class: 'rgi-ui-form-control',
    '[id]': 'id',
    '[attr.required]': 'required || null',
    '[attr.disabled]': 'disabled || null',
    '[class.rgi-ui-error]': 'hasError()',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false',
    '(click)': '_handleClick()'
  },
  inputs: [
    'id',
    'required',
    'disabled',
    'ariaLabelledBy',
    'ariaDescribedBy'
  ],
  selector: 'select[rgiRxNativeSelect]',
  providers: [
    {
      provide: RgiRxFormControl,
      useExisting: RgiRxNativeSelectDirective
    }
  ]
})
/**
 * Directive that provides rgi/rx bindings for native select
 * @see RgiRxFormControl
 * @see RgiRxErrorStateMatcher
 */
export class RgiRxNativeSelectDirective extends RgiRxFormControlDirective<any> {


  @Input() get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.elementRef.nativeElement.value = this._value;
  }
}

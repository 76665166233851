import {PostSalesOperation} from '../api/rgi-pnc-postsales.api';
import {
  PNC_POSTSALES_OPERATIONS_CODE,
  PNC_POSTSALES_OPERATIONS_TYPES
} from '../resources/enums/pnc-postsales-operations-types';
import {PncDomainService} from '../resources/http/pnc-domain.service';
import {
  PncPsalesRequiredDataStateManagerDates
} from '../ops-managers/variations/required-data/required-data-state-manager-dates';
import {
  PncPsalesTechAccountingStateManager
} from '../state-managers/technical-accounting/tech-accounting-state-manager.service';
import {API_PREFIX_PORTFOLIO} from '../resources/constants/rest-api-conf';
import {PncPsalesConfirmationStateManager} from '../state-managers/confirmation/confirmation-state-manager.service';
import {
  PncPsalesSummaryStateManagerVariations
} from '../ops-managers/variations/summary/summary-state-manager-variations';
import {RGI_PNC_POSTSALES_STEPS} from '../resources/constants/steps';
import {
  PncPsalesSupplementaryTechAccStateManager
} from '../state-managers/technical-accounting/supplementary-tech-acc-state-manager.service';
// Appendice con Titolo Contabile - Technical Accounting Appendix
export const PNC_PSALES_VD0059: PostSalesOperation = {
  type: PNC_POSTSALES_OPERATIONS_TYPES.ASSETS_DATA_AMENDMENTS,
  operationCode: PNC_POSTSALES_OPERATIONS_CODE.TECHNICAL_ACCOUNTING_APPENDIX,
  sessionTitle: '_PCPSALES_._SESSION_TITLE_._TECHNICAL_ACCOUNTING_APPENDIX_',
  steps: [
    {
      step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
      manager: PncPsalesRequiredDataStateManagerDates,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._VARIATION_DATES_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.TECHNICAL_ACCOUNTING,
      manager: PncPsalesTechAccountingStateManager,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._TECHNICAL_ACCOUNTING_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUPPLEMENTARY_TECH_ACC,
      manager: PncPsalesSupplementaryTechAccStateManager,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._SUPPLEMENTARY_TECH_ACC_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
      manager: PncPsalesSummaryStateManagerVariations,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
      manager: PncPsalesConfirmationStateManager,
      deps: [],
      stepLabel: ''
    }
  ]
};


export class TypeOfLossDamageSave {
    idTol: any;
    updateDate: string;
    assignmentExpensesAmount: number;
    amountTolInput: number;
    tolDescription?: string;
    idMotivazione?: string;
    idParty?: string;
}

import {RePostsalesState} from './re-postsales-state';
import {GenericEntity, SubReason} from '../re-postsale-model/substitution';
import {Message} from '../../../re-issue-constants/msg';

export interface RePostsalesCancellationNavigationData {
  policy: any; // TODO: set interface
  backNavigate: string;
  contract: any;
  authenticationNodeCode: string;
  productionNodeCode: string;
  node: any;
  userCode: string;
  cancellationReason: GenericEntity;
  issueDate: string;
  effectiveDate: string;
  premiumRepaymentVisibility: boolean;
  taxes: boolean;
  taxable: boolean;
  rights: string;
  note: string;
}

export class RePostsalesCancellationState extends RePostsalesState {
  policy: any; // TODO: set interface
  backNavigate: string;
  reasonList: Array<SubReason> = new Array<SubReason>();
  productList: Array<GenericEntity> = new Array<GenericEntity>();
  validationMsgReasonList: Array<Message> = new Array<Message>();
  validationMsgProductList: Array<Message> = new Array<Message>();
  authenticationNodeCode: string;
  productionNodeCode: string;
  node: any;
  userCode: string;
  cancellationReason: GenericEntity;
  issueDate: string;
  effectiveDate: string;
  premiumRepaymentVisibility: boolean;
  taxes: boolean;
  taxable: boolean;
  rights: string;
  note: string;
}

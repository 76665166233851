import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MotorIssueCardComponent} from './motor-issue-card/motor-issue-card.component';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {StartComponent} from './start-card/start.component';
import {QuotationComponent} from './quotation/quotation.component';
import {MotorIssueCardHostDirective} from './motor-issue-card-host.directive';
import {VehicleDataComponent} from './vehicle-data/vehicle-data.component';
import {ProductAssetModalComponent} from './product-asset-modal/product-asset-modal-component';
import {QuotationControlsComponent} from './quotation/quotation-controls/quotation-controls.component';
import {VariablesComponent} from './variables/variables.component';
import {VariablesModalComponent} from './variables/variables-modal/variables-modal.component';
import {VariableControlComponent} from './variables/variable-control/variable-control.component';
import {RiskCertificateComponent} from './risk-certificate/risk-certificate.component';
import {
  RiskCertificateModalComponent
} from './risk-certificate/risk-certificate-modal/risk-certificate-modal.component';
import {PortalNgCommonModule} from '@rgi/portal-ng-common';
import {
  EqualResponsabilityClaimsDetailsComponent
} from './risk-certificate/equal-resp-claims-det/equal-resp-claims-det.component';
import {InsuranceBackgroundComponent} from './insurance-background/insurance-background.component';
import {ClaimModalComponent} from './risk-certificate/claim-modal/claim-modal.component';
import {AssetComponent} from './asset/asset.component';
import {ClauseComponent} from './clause/clause.component';
import {ClauseModalComponent} from './clause/clause-modal/clause-modal.component';
import {ContractClauseModalComponent} from './contract-clause/clause-modal/contract-clause-modal.component';
import {ContractClauseComponent} from './contract-clause/contract-clause.component';
import {ParametersComponent} from './quotation/parameters/parameters.component';
import {ParameterControlComponent} from './quotation/parameters/parameter-control/parameter-control.component';
import {QuotationDetailsModalComponent} from './quotation/quotation-details/quotation-details-modal.component';
import {
  ContributionsAndTaxesModalComponent
} from './quotation/contributions-and-taxes/contributions-taxes-modal.component';
import {CommissionsModalComponent} from './quotation/commissions/commissions-modal.component';
import {WarrantiesSummaryModalComponent} from './quotation/warranties-summary/warranties-summary-modal.component';
import {DiscountsModalComponent} from './quotation/discounts/discounts-modal.component';
import {ProposalComponent} from './proposal/proposal.component';
import {ResidualDiscountComponent} from './quotation/discounts/residual-discount/residual-discount.component';
import {EntitledPartyComponent} from './proposal/entitled-party/entitled-party.component';
import {AddressesComponent} from './proposal/addresses/addresses.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {AddressModalComponent} from './proposal/addresses/address-modal/address-modal.component';
import {PartiesComponent} from './proposal/parties/parties.component';
import {LienComponent} from './proposal/liens/lien/lien.component';
import {QuestionnairesComponent} from './questionnaires/questionnaires.component';
import {QuestionnaireModalComponent} from './questionnaires/questionnaire-modal.component';
import {QuestionComponent} from './questionnaires/questions/question.component';
import {QuotationComparisonModalComponent} from './proposal/quotation-comparison/quotation-comparison-modal.component';
import {
  ContractVariablesModalComponent
} from './contract-variables/contract-variables-modal/contract-variables-modal.component';
import {ContractVariablesComponent} from './contract-variables/contract-variables.component';
import {DocumentsModalComponent} from './documents/documents-modal.component';
import {PolicySummaryModalComponent} from './policy-summary/modal/policy-summary-modal.component';
import {PolicySummaryComponent} from './policy-summary/policy-summary.component';
import {LiensComponent} from './proposal/liens/liens.component';
import {AuthorizationRequestComponent} from './proposal/authorization-request/authorization-request.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AssetUnitComponent} from './quotation/asset-unit/asset-unit.component';
import {ExpectedDocumentsModalComponent} from './documents/expected-documents/expected-documents-modal.component';
import {ErrorMessagesService} from './error-messages.service';
import {PartyVariablesComponent} from './party-variables/party-variables.component';
import {PartyVariablesModalComponent} from './party-variables/party-variables-modal/party-variables-modal.component';
import {MandatoryDataComponent} from './mandatory-data/mandatory-data.component';
import {InstallerModalComponent} from './mandatory-data/installer-modal/installer-modal.component';
import {PackagesComponent} from './quotation/packages/packages.component';
import {PackageVariablesModalComponent} from './quotation/packages/package-variables/package-variables-modal.component';
import {PackagesService} from './quotation/packages/packages.service';
import {FormService} from './form-service';
import {InstallerComponent} from './mandatory-data/installer/installer.component';
import {SubstitutionComponent} from './substitution/substitution.component';
import {ModalService, PortalNgCoreModule} from '@rgi/portal-ng-core';
import {HelpComponent} from './help/help.component';
import {PackageFactorsComponent} from './quotation/package-factors/package-factors.component';
import {PackageFactorComponent} from './quotation/package-factor/package-factor.component';
import {LpsTaxesModalComponent} from './quotation/lps-taxes/lps-taxes-modal.component';
import {
  NormalizeAddressComponent
} from './proposal/addresses/address-modal/normalize-address/normalize-address.component';
import {AddressesListComponent} from './proposal/addresses/addresses-list/addresses-list.component';
import {micHttpInterceptorProviders} from './interceptors/register-interceptors';
import {MicIntegrationService} from './mic-integration.service';
import {ChangeVehicleModalComponent} from './change-vehicle-modal/change-vehicle-modal.component';
import {
  SubstitutionModalComponent
} from './substitution/substitution-modal/substitution-modal/substitution-modal.component';
import {FormsStatusService} from './forms-status.service';
import {CoinsuranceComponent} from './quotation/coinsurance/coinsurance.component';
import {QuotaCoinsuranceModalComponent} from './quotation/coinsurance-quote/coinsurance-quote.modal.component';
import {
  QuotaCoinsuranceListModalComponent
} from './quotation/coinsurance-quote-list/coinsurance-quote-list.modal.component';
import {PaymentFieldsComponent} from './payments/payment-fields-component/payment-fields.component';
import {PaymentsSectionComponent} from './payments/payments-section/payments-section.component';
import {PaymentsModalComponent} from './payments/payments-modal/payments-modal.component';
import {RgiRxDatePickerModule, RgiRxFormElementsModule, RgiRxNativeDateTimeModule, RgiRxPanelModule} from '@rgi/rx/ui';
import {IbanCheckModalComponent} from './payments/iban-check-modal/iban-check-modal.component';
import {AmendmentComponent} from './amendment/amendment.component';
import {RgiCountryLayerFormsModule, RgiCountryLayerModule} from '@rgi/country-layer';
import {RgiSharedComponentsModule} from '@rgi/shared-components';
import {MicDatePipe} from './pipes/micdate.pipe';

@NgModule({
    declarations: [
        MotorIssueCardComponent,
        StartComponent,
        QuotationComponent,
        VehicleDataComponent,
        ProductAssetModalComponent,
        QuotationControlsComponent,
        PartyVariablesComponent,
        ContractVariablesComponent,
        ContractVariablesModalComponent,
        VariablesComponent,
        VariablesModalComponent,
        PartyVariablesModalComponent,
        VariableControlComponent,
        RiskCertificateComponent,
        RiskCertificateModalComponent,
        EqualResponsabilityClaimsDetailsComponent,
        ClaimModalComponent,
        MotorIssueCardHostDirective,
        InsuranceBackgroundComponent,
        AssetComponent,
        ContractClauseComponent,
        ContractClauseModalComponent,
        ClauseComponent,
        ClauseModalComponent,
        AssetUnitComponent,
        ParametersComponent,
        ParameterControlComponent,
        QuotationDetailsModalComponent,
        ContributionsAndTaxesModalComponent,
        CommissionsModalComponent,
        WarrantiesSummaryModalComponent,
        DiscountsModalComponent,
        ProposalComponent,
        ResidualDiscountComponent,
        ProposalComponent,
        EntitledPartyComponent,
        AddressesComponent,
        AddressModalComponent,
        NormalizeAddressComponent,
        PartiesComponent,
        LienComponent,
        LiensComponent,
        QuestionnaireModalComponent,
        QuestionnairesComponent,
        QuestionComponent,
        QuotationComparisonModalComponent,
        DocumentsModalComponent,
        PolicySummaryModalComponent,
        PolicySummaryComponent,
        AuthorizationRequestComponent,
        ExpectedDocumentsModalComponent,
        MandatoryDataComponent,
        InstallerModalComponent,
        PackagesComponent,
        PackageVariablesModalComponent,
        InstallerComponent,
        SubstitutionComponent,
        HelpComponent,
        InstallerComponent,
        PackageFactorsComponent,
        PackageFactorComponent,
        LpsTaxesModalComponent,
        AddressesListComponent,
        ChangeVehicleModalComponent,
        SubstitutionModalComponent,
        CoinsuranceComponent,
        QuotaCoinsuranceModalComponent,
        QuotaCoinsuranceListModalComponent,
        PaymentFieldsComponent,
        PaymentsModalComponent,
        PaymentsSectionComponent,
        IbanCheckModalComponent,
        AmendmentComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        PortalNgCommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule.forRoot(),
        EditorModule,
        BrowserAnimationsModule,
        RgiRxFormElementsModule,
        // RgiRxI18nModule,
        // RgiRxI18nModule.forRoot(MOTOR_ISSUE_CARD_TRANSLATIONS),
        RgiRxPanelModule,
        RgiCountryLayerFormsModule,
        RgiCountryLayerModule,
        RgiSharedComponentsModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule
    ],
    exports: [
        MotorIssueCardComponent,
        StartComponent,
        QuotationComponent,
        VehicleDataComponent,
        ProductAssetModalComponent,
        QuotationControlsComponent,
        PartyVariablesComponent,
        ContractVariablesComponent,
        ContractVariablesModalComponent,
        VariablesComponent,
        VariablesModalComponent,
        PartyVariablesModalComponent,
        VariableControlComponent,
        RiskCertificateComponent,
        RiskCertificateModalComponent,
        EqualResponsabilityClaimsDetailsComponent,
        ClaimModalComponent,
        MotorIssueCardHostDirective,
        InsuranceBackgroundComponent,
        AssetComponent,
        ContractClauseComponent,
        ContractClauseModalComponent,
        ClauseComponent,
        ClauseModalComponent,
        AssetUnitComponent,
        ParametersComponent,
        ParameterControlComponent,
        QuotationDetailsModalComponent,
        ContributionsAndTaxesModalComponent,
        CommissionsModalComponent,
        WarrantiesSummaryModalComponent,
        DiscountsModalComponent,
        ResidualDiscountComponent,
        ProposalComponent,
        EntitledPartyComponent,
        AddressesComponent,
        AddressModalComponent,
        NormalizeAddressComponent,
        PartiesComponent,
        LienComponent,
        LiensComponent,
        QuestionnaireModalComponent,
        QuestionnairesComponent,
        QuestionComponent,
        QuotationComparisonModalComponent,
        DocumentsModalComponent,
        PolicySummaryModalComponent,
        PolicySummaryComponent,
        QuotationControlsComponent,
        ExpectedDocumentsModalComponent,
        MandatoryDataComponent,
        InstallerModalComponent,
        PackagesComponent,
        PackageVariablesModalComponent,
        InstallerComponent,
        HelpComponent,
        AddressesListComponent,
        ChangeVehicleModalComponent,
        SubstitutionModalComponent,
        PaymentsSectionComponent
    ],
    providers: [
        MicDatePipe,
        DatePipe,
        DecimalPipe,
        ModalService,
        CurrencyPipe,
        ErrorMessagesService,
        PackagesService,
        FormService,
        MicIntegrationService,
        micHttpInterceptorProviders,
        FormsStatusService,
        { provide: 'startComponent', useValue: StartComponent },
        { provide: 'quotationComponent', useValue: QuotationComponent },
        { provide: 'assetComponent', useValue: AssetComponent },
        { provide: 'entitledPartyComponent', useValue: EntitledPartyComponent },
        { provide: 'proposalComponent', useValue: ProposalComponent },
        { provide: 'mandatoryDataComponent', useValue: MandatoryDataComponent },
        { provide: 'policySummaryComponent', useValue: PolicySummaryComponent },
        { provide: 'questionnaireModalComponent', useValue: QuestionnaireModalComponent },
        { provide: 'policySummaryModalComponent', useValue: PolicySummaryModalComponent },
        { provide: 'riskCertificateModalComponent', useValue: RiskCertificateModalComponent },
        { provide: 'quotationDetailsModalComponent', useValue: QuotationDetailsModalComponent },
        { provide: 'quotationComparisonModalComponent', useValue: QuotationComparisonModalComponent },
        { provide: 'variablesModalComponent', useValue: VariablesModalComponent },
        { provide: 'contractVariablesModalComponent', useValue: ContractVariablesModalComponent },
        { provide: 'partyVariablesModalComponent', useValue: PartyVariablesModalComponent },
        { provide: 'discountsModalComponent', useValue: DiscountsModalComponent },
        { provide: 'showTacitRenewal', useValue: true },
        { provide: 'showInfocarCodeInQuotationPage', useValue: false },
        { provide: 'viewUnitsBox', useValue: true },
        { provide: 'saveQuoteButtonEnabled', useValue: true },
        { provide: 'showEntryPointDateOfBirth', useValue: true },
        { provide: 'showOtpButton', useValue: false },
        { provide: 'installerModalComponent', useValue: InstallerModalComponent },
        { provide: 'enabledIdd', useValue: false },
        { provide: 'packageVariablesModalComponent', useValue: PackageVariablesModalComponent },
        { provide: 'substitutionComponent', useValue: SubstitutionComponent },
        { provide: 'amendmentComponent', useValue: AmendmentComponent },
        { provide: 'RGI_ANAG_PORTAL_AJS_FLOW', useValue: true },
        { provide: 'SHOW_CHANGE_VEHICLE_ASSET_PAGE', useValue: true }
    ]
})
export class MotorIssueCardModule {
}

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step [enableAuth]="isAuth" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" id="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions"
                    [formControlName]="'dates'"
                    (dateChange)="onDateChange($event)"
                    (reloadRequest)="updateData()"
                    [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="lpc_contractual_option" [errors]="errors" fieldId="tools" id="tools" (next)="onNext($event, false)"
            (confirmAccept)="onNext($event, true)" [isConfirmAndAccept]="isConfirmAndAccept" >
    <div formGroupName="tools">
      <lpc-checkbox-accordion *ngFor="let tool of toolDefinitions"
                              [toolName]="tool.toolName"
                              (toolCheck)="onToggleTool(tool.operationCodeId, $event)"
                              [initialOpen]="isToolOpen(tool.operationCodeId)"
                              [active]="isActiveStep('tools')"
                              [mandatory]="tool.selectionState === 'MANDATORY'"
                              [isSelected]="activeTools[tool.operationCodeId]">
        <lpc-tool
          [definition]="tool"
          [totalAmount]="getTotalAmountOfTool(tool.operationCodeId)"
          [formControlName]="tool.operationCodeId"
          [showFundDistribution]="isToolWithFlag(tool.operationCodeId)"
          [withValidation]="isToolOpen(tool.operationCodeId)"
          [operationCode]="getOperationCode"
          [policyProfiles]="policyProfiles"
          (factorReload)="onFactorReload($event)"
          [active]="isActiveStep('tools')">
        </lpc-tool>
      </lpc-checkbox-accordion>
    </div>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" [isConfirmAndAccept]="isConfirmAndAccept" (confirmAccept)="publish(true)" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    <lpc-error-messages [errors]="errors" [fieldType]="'tools'"></lpc-error-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()"
                          [nextVisible]="false"
                          [qa]="'annulla'"></lpc-navigation-buttons>
</div>

import { EMPTY_STR, StepperConfiguration } from '../../../models/consts/lpc-consts';

export interface PensionBuyBackStepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'roles'`
   * @stepField label `'lpc_variation_step_label'`
   * @stepField fieldId `'roles'`
   * @stepField formName `'roles'`
   */
  PERFORMANCE_DATA: {
    id: string,
    label: string,
    fieldId: string,
    formName: string
  },
  ANNUITY_DATA: {
    id: string,
    label: string,
    fieldId: string,
    formName: string
  },
  BENEFICIARIES: {
    id: string,
    label: string,
    fieldId: string,
    formName: string
  },
}

export const PENSION_BUYBACK: PensionBuyBackStepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates'
  },
  POLICY_FACTORS: {
    id: 'factors',
    label: 'lpc_PolicyData',
    fieldId: 'factors',
    formName: 'factors'
  },
  PERFORMANCE_DATA: {
    id: 'performanceData',
    label: 'lpc_performance_data',
    fieldId: 'performanceData',
    formName: 'performanceData'
  },
  ANNUITY_DATA: {
    id: 'annuity',
    label: 'lpc_annuity_data',
    fieldId: 'annuity',
    formName: 'annuity'
  },
  QUESTS_FIN: {
    id: 'questsFin',
    label: 'lpc_financial_questionnaire_step',
    fieldId: 'questsFin',
    formName: EMPTY_STR
  },
  ASSET_FACTORS: {
    id: 'assetFactors',
    label: 'lpc_asset_factors',
    fieldId: 'assetFactors',
    formName: 'assetFactors'
  },
  PAYMENT: {
    id: 'payment',
    label: 'lpc_payment_data',
    fieldId: 'payment',
    formName: 'payment'
  },
  QUOTATION: {
    id: 'quotation',
    label: 'lpc_quotation_step',
    fieldId: 'quotation',
    formName: 'quotation'
  },
  BENEFICIARIES: {
    id: 'beneficiaries',
    label: 'lpc_life_detail_beneficiaries',
    fieldId: 'beneficiaries',
    formName: 'beneficiaries'
  },
  RISKS: {
    id: EMPTY_STR,
    label: EMPTY_STR,
    fieldId: EMPTY_STR,
    formName: 'risks'
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR
  },
};

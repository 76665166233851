export class FlexDiscountParamAPI {
  flexWithErosion: FlexDiscountAPI;
  flexWithoutErosion: FlexDiscountAPI;
}

export class FlexDiscountAPI {
  percent: number;
  amount: number;
}

export class DiscountDetailAPI {
  code: string;
  perc: number;
  net: number;
  taxes: number;
  gross: number;
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxMenuComponent} from './rgi-rx-menu/rgi-rx-menu.component';
import {RgiRxMenuItemDirective} from './rgi-rx-menu-item.directive';
import {RgiRxMenuTriggerDirective} from './rgi-rx-menu-trigger.directive';
import {OverlayModule} from '@angular/cdk/overlay';
import {RgiRxQualityAssuranceModule} from '@rgi/rx';


@NgModule({
  declarations: [RgiRxMenuComponent, RgiRxMenuItemDirective, RgiRxMenuTriggerDirective],
  imports: [
    CommonModule,
    OverlayModule,
    RgiRxQualityAssuranceModule
  ],
  exports: [RgiRxMenuComponent, RgiRxMenuItemDirective, RgiRxMenuTriggerDirective]
})
export class RgiRxMenuModule {
}

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {CardAnalyticsStateHome, CardAnalyticsStateManagerHome} from '../card-analytics-state/card-analytics-state-manager-home';
import {StateManagedComponent} from '@rgi/rx/state';
import {Configuration} from '../model/configuration';
import {CookieAnalyticsService} from '../services/cookie.service';
import {ConfigurationService} from '../services/configuration.service';
import {ReportService} from '../services/report.service';
import {ReportArea} from '../model/report-area';
import {take} from 'rxjs/operators';
import {AdminFunctionsService} from '../services/admin-functions.service';
import {ModalComponent, ModalService, RgiRxAccordionDirective} from '@rgi/rx/ui';
import {CopyReportModalComponent} from '../modals/copy-report-modal/copy-report-modal.component';
import {CopyReportModalConfirmComponent} from '../modals/copy-report-modal-confirm/copy-report-modal-confirm.component';
import {DeleteReportModalComponent} from '../modals/delete-report-modal/delete-report-modal.component';
import {DeleteReportModalConfirmComponent} from '../modals/delete-report-modal-confirm/delete-report-modal-confirm.component';
import {LoggerService} from '../services/logger.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {Subscription} from "rxjs";

@Component({
  selector: 'analytics-home',
  templateUrl: './card-analytics-home.component.html',
  host: {
    class: 'rgi-analytics'
  },
  changeDetection: ChangeDetectionStrategy.Default
})

/*
* Analytics Home component
*/

export class CardAnalyticsHomeComponent extends StateManagedComponent<CardAnalyticsStateHome, CardAnalyticsStateManagerHome>
  implements OnInit, OnDestroy {

  configuration: Configuration;
  copiedId: any;
  isAdminFunctionEnabled: boolean;
  isCookiePresent = false;
  isQlikLinkFunctionEnabled: boolean;
  modal: ModalComponent;
  reportAreas: ReportArea[];
  reports: any;
  reportsLoaded = false;
  state: CardAnalyticsStateHome;
  private reportAccordionsSubscription: Subscription;
  @ViewChild("accordionRef", {static: false}) multiAccordion: RgiRxAccordionDirective;

  constructor(private adminFunctionsService: AdminFunctionsService,
              private configurationService: ConfigurationService,
              private cookieAnalyticsService: CookieAnalyticsService,
              private logger: LoggerService,
              private manager: CardAnalyticsStateManagerHome,
              private modalService: ModalService,
              private reportService: ReportService,
              private translateService: RgiRxTranslationService) {
    super(manager);
  }

  ngOnInit() {
    // get the current state of reports
    this.manager.getState$().pipe(take(1)).subscribe(state => {
      this.state = state;
    });

    // checks if admin functionalities are enabled from portal
    this.isAdminFunctionEnabled = this.manager.getAdminEnabled();
    this.isQlikLinkFunctionEnabled = this.manager.getQlikLinkEnabled();

    this.adminFunctionsService.setAdminEnabled(this.isAdminFunctionEnabled);
    // get report areas from db
    this.reportService.getReportAreas().pipe(take(1)).subscribe(
      res => {
        this.reportAreas = res;
        this.reportsLoaded = true;
        this.tickHostChange();
      },
      err => {
        this.logger.log(err);
      }
    );
  }

  /**
   * on expanded or not accordion invoke tick host change
   */
  onPanelExpanded() {
    this.logger.log('Panel expanded:');
    this.tickHostChange();
  }

  ngOnDestroy() {
    // Unsubscribe from the QueryList changes to avoid memory leaks
    if (this.reportAccordionsSubscription) {
      this.reportAccordionsSubscription.unsubscribe();
    }
  }

  /*
  * This method opens a qlik dashboard in a new page, authenticating the user
  */
  openQlik() {
    // GET SESSION COOKIE FROM QLIK
    if (!this.isCookiePresent) {
      this.configurationService.getConfiguration().pipe(take(1)).subscribe(
        resultConfiguration => {
          this.configuration = resultConfiguration;
          this.logger.log(this.configuration);
          this.cookieAnalyticsService.setCookieAnalytics(this.configuration).subscribe(data => {
            const qlikUrl = this.configuration.protocolHttp + '://' + this.configuration.host + '/' + this.configuration.proxy + '/hub';
            this.isCookiePresent = true;
            window.open(qlikUrl, '_blank');
          }, err => {
            alert(this.translateService.translate('_QLIK_SERVER_ERROR_'));
            this.logger.error(err);
          });
        },
        err => {
          this.logger.error(err);
        }
      );
    } else {
      // session cookie already exists
      const qlikUrl = this.configuration.protocolHttp + '://' + this.configuration.host + '/' + this.configuration.proxy + '/hub';
      window.open(qlikUrl, '_blank');
    }
  }

  openDashboard(template) {
    this.manager.actionOpen(template);
  }

  copyReport(data) {
    this.manager.copyReport(data);
    const {modal, component} = this.modalService.openComponent(CopyReportModalConfirmComponent);
    modal.onClose.subscribe(() => {
      this.logger.log('Closed successfully');
    });
  }

  copyReportModal(data) {
    this.copiedId = data;
    const {modal, component} = this.modalService.openComponent(CopyReportModalComponent);
    modal.onClose.subscribe((next) => {
      if (next.copied) {
        this.copyReport(data);
      }
    });
  }

  deleteReport(data) {
    this.manager.deleteReport(data);
    const {modal, component} = this.modalService.openComponent(DeleteReportModalConfirmComponent);
    modal.onClose.subscribe(() => {
      this.logger.log('Closed successfully');
    });
  }

  deleteReportModal(data) {
    this.copiedId = data;
    const {modal, component} = this.modalService.openComponent(DeleteReportModalComponent);
    modal.onClose.subscribe((next) => {
      if (next.deleted) {
        this.deleteReport(data);
      }
    });
  }

  editReport(template) {
    this.manager.editReport(template);
  }

  closeTemplate() {
    this.modal.close();
  }

  openNewReport() {
    this.stateManager.newReport();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Guardian } from '../../dsc-shared-model/dsc-model-subject';


export enum Role {
  OWNER = 'owner',
  DRIVER = 'driver',
  PASSENGER = 'passenger'
}

interface FormValue {
  isOwner?: boolean,
  isDriver?: boolean,
  isPassenger?: boolean,
  chkMaterial: boolean,
  materialDescription?: string,
  chkInjury?: boolean,
  isMinor: boolean,
  isIncapacitated: boolean,
}

@Component({
  selector: 'claims-subject-form',
  templateUrl: './subject-form.component.html',
  styleUrls: ['./subject-form.component.scss']
})
export class SubjectFormComponent {

  constructor(private fb: UntypedFormBuilder) { }
  showMinorGuardian: boolean = false;
  @Input() driverIsNotHolderOrOwner: boolean;
  isSubjectMinor: boolean = false;
  @Input() selectedNode: string;
  @Input() activeRouteId: string;
  @Input() listMinorGuardian: Guardian[];
  @Input() fields =
    [
      'chkMaterial',
      'materialDescription',
      'chkInjury'];
  @Input()
  get subject(): FormValue {
    return this.Ivalue;
  }
  set subject(value: FormValue) {
    this.Ivalue = value;
    this.isSubjectMinor = this.Ivalue.isMinor
    this.buildForm();
  }

  private Ivalue: FormValue;

  @Output() outputDataForm = new EventEmitter<any>();
  @Output() outputListMinorGuardian = new EventEmitter<Guardian[]>();
  dataForm: UntypedFormGroup;
  RoleEnum = Role;

  buildForm() {
    this.dataForm = this.fb.group(
      {
        isOwner: [false],
        isDriver: [false],
        isPassenger: [false],
        chkMaterial: [false],
        materialDescription: [''],
        chkInjury: [false],
        isMinor: [{value: false, disabled: true}],
        isIncapacitated: [false],
      }
    );

    this.dataForm.patchValue(this.subject);
    this.dataForm.controls.isMinor.setValue(this.subject.isMinor)
    if (this.dataForm.controls.chkMaterial.value) {
      this.enableDescription();
    } else {
      this.disableDescription();
    }
    if (this.isFieldVisible('isOwner')) {
    this.disablePassenger();
    }
  }
  
  // field Visibility
  isFieldVisible(field: string): boolean {
    return this.fields.includes(field);
  }

  isDamageToThingsVisible(dataForm): boolean {
    return (dataForm.controls.isOwner && dataForm.controls.isOwner.value) ||
      (dataForm.controls.isDriver && dataForm.controls.isDriver.value) ||
      (dataForm.controls.isPassenger && dataForm.controls.isPassenger.value);
  }

  isPhisicalInjuryVisible(dataForm): boolean {
    return (dataForm.controls.isDriver && dataForm.controls.isDriver.value) ||
      (dataForm.controls.isPassenger && dataForm.controls.isPassenger.value);
  }

  disablePassenger(): void {
    if (this.dataForm.controls.isOwner.value) {
      this.dataForm.controls.isPassenger.enable();
    } else {
      this.dataForm.controls.isPassenger.disable();
      this.dataForm.controls.isPassenger.setValue(false);
    }
    this.outputFormChange();
  }

  setDriverOrPassenger(role: string, event): void {
    const driverControl = this.dataForm.controls.isDriver;
    const passengerControl = this.dataForm.controls.isPassenger;
    if (role === this.RoleEnum.DRIVER && event.target.checked) {
      passengerControl.setValue(false);
      this.resetPhisicalInjury();
    }
    if (role === this.RoleEnum.PASSENGER && event.target.checked) {
      driverControl.setValue(false);
      this.resetPhisicalInjury();
    }
    this.outputFormChange();

  }

  isDamageToThings(event: any): void {
    const isDamageToThings = event.target.checked;
    if (isDamageToThings) {
      this.enableDescription();
    } else {
      this.disableDescription();
    }
    this.outputFormChange();
  }

  enableDescription(): void {
    this.dataForm.controls.materialDescription.enable();
    this.dataForm.controls.materialDescription.setValidators(Validators.required);
  }

  disableDescription(): void {
    this.dataForm.controls.materialDescription.disable();
    this.dataForm.controls.materialDescription.setValue('');
    this.dataForm.controls.materialDescription.setValidators(null);
  }

  resetDamageToThing(): void {
    this.disableDescription();
    this.dataForm.controls.chkMaterial.setValue(false);
  }

  resetPhisicalInjury(): void {
    this.dataForm.controls.chkInjury.setValue(false);
  }
  isIncapacitatedChange(event): void {
    const isIncapacitated = event.target.checked;
    if (!isIncapacitated) {
      this.listMinorGuardian = [];
    }
    this.outputFormChange();
  }

  outputFormChange(): void {
    const dataChange = { ...this.dataForm.value };
    this.outputDataForm.emit(dataChange);
  }

  listMinorGuardianChange(event): void {
    this.outputListMinorGuardian.emit(event);
  }

}

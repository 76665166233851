import { NgModule } from '@angular/core';
import { AdapterprintHomeComponent } from './adapterprint-home/adapterprint-home.component';
import {RgiRxRoutingModule} from '@rgi/rx/router';
import {CARD_ADAPTERPRINT_ROUTES} from './adapterprint-card.routes';
import { AdapterprintResultComponent } from './adapterprint-result/adapterprint-result.component';
import {
  RgiRxAutoCompleteModule, RgiRxDatePickerModule,
  RgiRxDragDropModule,
  RgiRxFormElementsModule, RgiRxNativeDateTimeModule, RgiRxPaginatorModule, RgiRxPanelModule,
  RgiRxSnackbarModule,
  RgiRxSwitchModule,
  RgiRxTableModule
} from '@rgi/rx/ui';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {CommonModule, DatePipe} from '@angular/common';
import {NgbPopoverModule, NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {HttpClientModule} from '@angular/common/http';
import { AdapterprintHomeNodeManagementComponent } from './adapterprint-home/adapterprint-home-node-management/adapterprint-home-node-management.component';
import {ADAPTERPRINT_TRANSLATIONS} from "./i18n/translations";



@NgModule({
    declarations: [AdapterprintHomeComponent, AdapterprintResultComponent, AdapterprintHomeNodeManagementComponent],
  imports: [
    RgiRxRoutingModule.forRoot(CARD_ADAPTERPRINT_ROUTES),
    RgiRxDragDropModule,
    FormsModule,
    RgiRxI18nModule,
    CommonModule,
    RgiRxFormElementsModule,
    RgiRxSwitchModule,
    ReactiveFormsModule,
    RgiRxTableModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    RgiRxAutoCompleteModule,
    PortalNgCoreModule,
    DragDropModule,
    HttpClientModule,
    RgiRxSnackbarModule,
    RgiRxPaginatorModule,
    NgbModule,
    RgiRxPanelModule,
    RgiRxDatePickerModule,
    RgiRxNativeDateTimeModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(ADAPTERPRINT_TRANSLATIONS),
  ],
    exports: [],
    providers: [
        DatePipe
    ]
})
export class AdapterprintCardModule { }

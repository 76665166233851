import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Factor, RequestFactor } from '../../models/factor.model';
import {
  BeneficiaryDefinition, FactorDefinition, Instalment, PostsalesOperationObject,
  QuotationDefinition, Risk
} from '../../models/postsales-operations-response.model';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { Premium } from '../../modules/lpc-premium/model/premium.model';
import { Instalments } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail/lpc-instalments.model';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from '@rgi/portal-ng-core';
import { ComponentWithQuotation } from '../../interfaces/component-with-quotation';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RisksFactors } from '../../models/risks-factors.model';
import { Beneficiary } from '../../modules/lpc-beneficiary/model/beneficiary';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcQuotationDetailModalComponent } from '../../modules/lpc-quotation-detail-modal/lpc-quotation-detail-modal.component';
import { Quotation } from '../../modules/lpc-quotation/model/quotation.model';
import { LpcWarrantyDetailModalComponent } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail-modal.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PlcQuotationUtils } from '../../utils/plc-quotation-utils';
import { InstalmentType } from '../dynamic-operation/dynamic-operation.model';

@Component({
    selector: 'lpc-renewal-booking',
    templateUrl: './renewal-booking-component.html',
    styleUrls: ['./renewal-booking-component.css']
  })
export class RenewalBookingComponent extends AbsOperationComponent implements ComponentWithQuotation {

  protected operationDataKey = 'renewal-booking';
  public listProductFactor: FactorDefinition[] = [];
  public listGoodsFactor: FactorDefinition[] = [];
  public risks: Risk[] = [];
  public requestFactor: RequestFactor[] = [];
  public requestGoodFactor: RequestFactor[] = [];
  public showFormuleModal = false;
  public beneficiaryDefinitions: BeneficiaryDefinition[] = [];
  public beneficiaryData: Beneficiary[] = [];
  public selectedBeneficiariesForSummary: any[];
  public quotationDefinition: QuotationDefinition;
  public quotationData: Quotation;
  public risksForUnits: Risk[] = [];

  private reqUnitFactors: RisksFactors[] = [];

  // QUOTATION DETAIL MODAL
  public annualPremium: Premium;
  public firstInstalment: Premium;
  public nextInstalment: Premium;

  // WARRANTIES DETAIL MODAL
  public instalments: Instalments;

  // QUOTATION CONTROLS BUTTONS
  public disableQuote = true;
  public disableDetails = true;
  public disableNext: boolean;

  public disableBeneficiary: boolean;

  get hasListProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  get atSummaryProductFactor(): boolean {
    return this.stepper.active > 1;
  }

  get hasListGoodsFactor(): boolean {
    return !!this.listGoodsFactor && !!this.listGoodsFactor.length;
  }

  get atSummaryGoodsFactor(): boolean {
    if (this.hasListProductFactor) {
      return this.stepper.active > 2;
    } else {
      return this.stepper.active > 1;
    }
  }

  private $currentStep: string;
  get currentStep(): string {
    return this.$currentStep;
  }
  get hasBeneficiaryDefinitions(): boolean {
    return !!this.beneficiaryDefinitions && !!this.beneficiaryDefinitions.length;
  }

  @ViewChild('factorAdapter')
  factorAdapter: LpcFactorAdapterComponent;

  @ViewChild('goodsFactorAdapter')
  goodsFactorAdapter: LpcFactorAdapterComponent;

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      assetFactors: new UntypedFormGroup({}),
      quotation: new UntypedFormControl(),
      beneficiaries: new UntypedFormGroup({}),
      risks: new UntypedFormControl()
    });
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.initializeInstalments();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.commonResponseHandling(result as any);
        this.prevalUnitForm();
      })
    );
    this.formGroup.get('beneficiaries').valueChanges.subscribe(el => {
      this.getFeErrors();
    });
  }

  private prevalUnitForm() {
    const riskObj = { risks: [] };
    this.risks.forEach((risk, index) => {
      const risks = {
        code: risk.code,
        selected: risk.selected,
        subRisks: [],
        factors: !!risk.factors && !!risk.factors.length ? this.convertFactors(risk.factors) : []
      };
      if (!!risk.subRisks) {
        risk.subRisks.forEach((subrisk, i) => {
          risks.subRisks.push({
              code: subrisk.code,
              selected: subrisk.selected,
              factors: !!subrisk.factors && !!subrisk.factors.length ? this.convertFactors(subrisk.factors) : []
            });
        });
      }
      riskObj.risks.push(risks);
    });
    (this.formGroup.get('risks')).setValue(riskObj);
  }

  private convertFactors(factors: Factor[]): RequestFactor[] {
    const factorsArray: RequestFactor[] = [];
    Object.keys(factors).forEach(key => {
      factorsArray.push({
        code: factors[key].code,
        label: null,
        value: factors[key].value,
        userInput: false,
        typeCode: null
      });
    });
    return factorsArray;
  }

  initializeInstalments() {
    this.instalments = {
      firstInstalmentSection: [],
      nextInstalmentSection: [],
      annualPremiumSection: [],
      totAnnualPremium: null,
      totFirstInstalment: null,
      totNextInstalment: null
    };
  }

  updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe());
  }

  updateGoodsFactors(factors: RequestFactor[]) {
    this.requestGoodFactor = factors;
    this.$subscriptions.push(this.onReload('assetFactors').subscribe());
  }

  /** @Override */
  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    this.$currentStep = step;
    if (this.currentStep === 'quotation' || this.currentStep === 'recalculateQuote') {
      if (this.hasBeneficiaryDefinitions) {
        this.handlingBeneficiaries(result);
      }
      PlcQuotationUtils.updateFormulasFromModel(result);
    }

    if (this.isAfterId('beneficiaries')) {
      this.handleBeneficiariesSummary();
    }

    this.commonResponseHandling(result);
  }

  /**
   * Gestisce la visualizzazione dei beneficiari dopo che si è passato lo step
   * (ESTENDERE A PROGETTO PER CAMBIARE LA VISUALIZZAZIONE)
   */
  handleBeneficiariesSummary() {
    this.selectedBeneficiariesForSummary = LpcBeneficiaryUtils.getBeneficiariesForSummary(
      this.beneficiaryDefinitions, this.formGroup, null, this.translate
    );
  }

  recalculate(disable) {
    if (this.formGroup.get('risks').valid) {
      this.updateOnQuotation(disable);
    } else {
      this.setFeErrors('quotation');
    }
  }

  selectionUnit() {
    this.disableNext = true;
    this.disableQuote = false;
  }

  updateOnQuotation(disable) {
    this.$subscriptions.push(
      this.updateDraft('recalculateQuote', true).subscribe(result => {
        // this.commonResponseHandling(result);
        this.disableNext = disable;
        this.disableQuote = true;
      })
    );
  }

  commonResponseHandling(response: PostsalesOperationObject) {
    this.listProductFactor = response.definitions.productFactors as FactorDefinition[];
    this.listGoodsFactor = response.definitions.goodsFactors as FactorDefinition[];
    this.quotationDefinition = response.definitions.quotationDefinition as QuotationDefinition;
    this.beneficiaryDefinitions = response.definitions.beneficiaries as BeneficiaryDefinition[];
    this.risks = this.createAllRisksArray(this.quotationDefinition.risks, true);
    this.risksForUnits = this.quotationDefinition.risks;
    this.updateModals(this.quotationDefinition);
    this.detectChanges();
  }

  handlingBeneficiaries(response) {
    const beneficiaries: Beneficiary[] = response.data.operationData.data.beneficiaries as Beneficiary[];
    const beneficiariesDefinitions = (response.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    beneficiariesDefinitions.forEach(el => {
      (this.formGroup.get('beneficiaries') as UntypedFormGroup).addControl(
        'b' + el.code,
        new UntypedFormControl({
          code: null,
          subjects: [],
          irrevocable: false
        })
      );
    });
    this.beneficiaryDefinitions = beneficiariesDefinitions;
    this.beneficiaryData = beneficiaries;
    beneficiariesDefinitions.forEach(definition => {
      if (!!this.beneficiaryData) {
        const ben: Beneficiary = this.beneficiaryData.find(el => el.type === definition.code);
        this.formGroup.get('beneficiaries').get('b' + definition.code).enable({emitEvent: false});
        this.formGroup.get('beneficiaries').get('b' + definition.code).setValue({
          code: !!ben ? ben.code : null,
          subjects: this.beneficiaryData.filter(el => el.type === definition.code),
          irrevocable: !!ben ? ben.irrevocable : false
        }, {emitEvent: false});
        if (!definition.editable) {
          this.formGroup.get('beneficiaries').get('b' + definition.code).disable({emitEvent: false});
        }
      }
    });
  }

  public updateUnit(riskFactors: Map<string, RequestFactor[]>) {
    this.reqUnitFactors = [];
    riskFactors.forEach((value: RequestFactor[], key: string) => {
      this.reqUnitFactors.push(PlcQuotationUtils.mapRisk(key, value));
    });
    this.$subscriptions.push(this.onReload('quotation').subscribe());
  }

  createAllRisksArray(risks: Risk[], isForFormulas: boolean): Risk[] {
    const riskArray: Risk[] = [];
    if (!!risks && risks.length > 0) {
      risks.forEach(risk => {
        if (isForFormulas) {
          if (!!risk.formulas) {
            riskArray.push(risk);
          }
        } else {
          riskArray.push(risk);
        }
        if (!!risk.subRisks) {
          risk.subRisks.forEach(subrisk => {
            if (isForFormulas) {
              if (!!subrisk.formulas) {
                riskArray.push(subrisk);
              }
            } else {
              riskArray.push(subrisk);
            }
          });
        }
      });
    }

    return riskArray;
  }

  public closeFormuleModal(event) {
    this.showFormuleModal = false;
  }

  updateWarrantiesModal(quotationDefinition: QuotationDefinition) {
    this.instalments.firstInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.FIRST_INSTALMENT
    );
    this.instalments.nextInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.NEXT_INSTALMENT
    );
    this.instalments.annualPremiumSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.ANNUAL_PREMIUM
    );
    if (!!quotationDefinition.annualPremium) {
      quotationDefinition.annualPremium.description = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    if (!!quotationDefinition.firstInstalment) {
      quotationDefinition.firstInstalment.description = this.translate.getImmediate(InstalmentType.FIRST_INSTALMENT);
    }
    if (!!quotationDefinition.nextInstalment) {
      quotationDefinition.nextInstalment.description = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }

    this.instalments.totFirstInstalment = quotationDefinition.firstInstalment;
    this.instalments.totAnnualPremium = quotationDefinition.annualPremium;
    this.instalments.totNextInstalment = quotationDefinition.nextInstalment;
  }

  updateModals(quotationDefinition: QuotationDefinition) {
    this.updatePremiumModal(quotationDefinition);
    this.updateWarrantiesModal(quotationDefinition);
  }

  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

  openFormuleModal($event) {
    this.showFormuleModal = true;
  }

  public get showopenFormuleModalButton() {
    return !!this.risks && !this.risks.filter(risk => !!risk.formulas).some(v => !!v);
  }

  saveFormulas(event) {
    this.updateDraft('quotation').subscribe(result => {
      this.disableNext = false;
    });
  }


  openQuotationModal(event) {
    const modalRef = this.modalService.open(LpcQuotationDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.firstInstalment = this.firstInstalment;
    modalRef.componentInstance.nextInstalment = this.nextInstalment;
    modalRef.componentInstance.annualPremium = this.annualPremium;
    /* modalRef.componentInstance.quotationData = this.quotationData; */
    modalRef.componentInstance.showFirstInstallment = false;
  }

  public get showOpenQuotationModalButton() {
    return !!this.firstInstalment || !!this.nextInstalment || !!this.annualPremium;
  }

  openWarrantiesModal(event) {
    const modalRef = this.modalService.open(LpcWarrantyDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    modalRef.componentInstance.instalments = this.instalments;
    modalRef.componentInstance.showFirstInstallment = false;
  }

  public get showopenWarrantiesModalButton() {
    return !!this.instalments;
  }

  updatePremiumModal(quotationDefinition: QuotationDefinition) {
    this.annualPremium = PlcQuotationUtils.instalmentToPremium(quotationDefinition.annualPremium);
    if (this.annualPremium) {
      this.annualPremium.typeDescription = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    this.firstInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.firstInstalment);
    if (!!this.firstInstalment) {
      this.firstInstalment.typeDescription = this.translate.getImmediate(InstalmentType.FIRST_INSTALMENT);
    }
    this.nextInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.nextInstalment);
    if (!!this.nextInstalment) {
      this.nextInstalment.typeDescription = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }
    this.quotationData = PlcQuotationUtils.quotationDefToQuotationData(quotationDefinition);
  }

  // creating sections for warranties detail modal
  createInstalmentSectionWarranties(risks: Risk[], instalmentType: string): Instalment[] {
    return PlcQuotationUtils.createInstalmentSectionWarranties(risks, instalmentType);
  }


  getBeneficiaries(): Beneficiary[] {
    if (this.hasBeneficiaryDefinitions) {
      let subjects: Beneficiary [] = [];
      Object.keys(this.formGroupValue.beneficiaries).forEach(key => {
        subjects = subjects.concat(this.formGroupValue.beneficiaries[key].subjects);
      });
      return subjects;
    } else {
      return [];
    }
  }

  /** @Override */
  protected getTransformedOperationData(): any {
    return  {
          beneficiaries: !!this.formGroupValue.beneficiaries ? this.getBeneficiaries() : [],
          listGoodFactor: this.requestGoodFactor,
          listProductFactor: this.requestFactor,
          risks: !!this.formGroupValue.risks ? this.formGroupValue.risks.risks : [],
          riskFormulasObject: this.formGroupValue.quotation
            ? this.formGroupValue.quotation.riskFormulasObject
            : []
    };
  }

  getFeErrors() {
    LpcBeneficiaryUtils.getBeneficiariesErrors(this.formGroup, this.translate);
  }


}

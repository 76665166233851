import {Component, Input, OnInit} from '@angular/core';
import {RgiRxDatasource, RgiRxDataTableCell} from '@rgi/rx/ui';
import {UserAssociatedLetterEntity} from '../../../dto/user-associated-letter-entity.entity';
import {DerogationLevelEntity} from '../../../dto/derogation-level.entity';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LetterTemplatesService} from '../../../letter-templates.service';

@Component({
  selector: 'claims-derogation-level-selector',
  templateUrl: './derogation-level-selector.component.html',
  styleUrls: ['./derogation-level-selector.component.scss']
})
export class DerogationLevelSelectorComponent extends RgiRxDataTableCell<UserAssociatedLetterEntity> implements OnInit {

  livelliDeroga: DerogationLevelEntity[];
  livelloDerogaForm: UntypedFormGroup;

  @Input()
  livelloDerogaLettera: string;

  constructor(private dataSource: RgiRxDatasource<UserAssociatedLetterEntity>,
              private formBuilder: UntypedFormBuilder,
              private letterTemplatesService: LetterTemplatesService) {
    super();
  }

  ngOnInit() {
    this.livelliDeroga = this.letterTemplatesService.getLivelliDeroga();
    const derogUserLetterLevelForCurrentUser =
      this.letterTemplatesService.getUserLetterDerogationLevelForCurrentEditByUserId(this.context.row.userId);

    this.livelloDerogaForm = this.formBuilder.group({
      livelloDerogaUser:
        [derogUserLetterLevelForCurrentUser ? derogUserLetterLevelForCurrentUser : this.livelloDerogaLettera, Validators.required],
    });
  }

  updateLevel() {
    this.context.row.livelloDerogaLettera = this.livelloDerogaForm.get('livelloDerogaUser').value;
  }
}

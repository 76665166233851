import { Component, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DIALOG_DATA, OnModalClose, RGI_RX_DROP_SELECTION_HANDLER } from '@rgi/rx/ui';
import { BuyQuoteData } from './config/buy-quote-data';
import { AnagFlowData, AnagSearchOrCreateResolver } from '@rgi/anag';
import { Roles } from '../../models/enum/lpc-subjects.enum';
import { PV_ERROR_SEVERITY_VALUE, PostsalesError } from '../../models/postsales-operations-response.model';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { Subscription } from 'rxjs';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'lpc-buy-quote',
  templateUrl: './buy-quote.component.html',
  styleUrls: ['./buy-quote.component.scss'],
  providers: [
    {
      provide: RGI_RX_DROP_SELECTION_HANDLER,
      useValue: {
        name: 'PersonDragSelectionModal',
        handler: AnagSearchOrCreateResolver
      },
      multi: true
    }
  ]
})
export class BuyQuoteComponent implements OnInit, OnModalClose, OnDestroy {

  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();

  formGroup = new UntypedFormGroup({
    holder: new UntypedFormControl(null, Validators.required),
    node: new UntypedFormGroup({}, Validators.required),
  });

  anagDropContainerSelectData: AnagFlowData;
  idParentSession: string;
  errors: PostsalesError[] = [];
  protected subscriptions: Subscription[] = [];

  constructor(
    @Optional() @Inject(DIALOG_DATA) protected data: BuyQuoteData,
    protected translate: TranslationWrapperService
  ) { }


  ngOnInit() {
    this.createAnagFlowData();
    const isAnonymousQuote = !!this.data?.subject?.nominative;
    this.inizializeForm(isAnonymousQuote);
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe(val => {
        this.errors = [];
      })
    );
  }


  private inizializeForm(isAnonymousQuote: boolean) {
    this.errors = [];
    this.formGroup = new UntypedFormGroup({
      holder: new UntypedFormControl(
        {
          value: this.data?.subject,
          disabled: isAnonymousQuote
        },
        Validators.required
      ),
      node: new UntypedFormControl({
            value: this.data.node,
            disabled: true
      })
    });
  }

  private createAnagFlowData() {
    this.anagDropContainerSelectData = new AnagFlowData();
    this.anagDropContainerSelectData.idParentSession = this.idParentSession;
    // this.anagDropContainerSelectData.nodeCode = this.data.node.code;
    // this.anagDropContainerSelectData.isQuote = true;
    this.anagDropContainerSelectData.partyRole = Roles.CONTRACTOR;
  }

  onClose() {
    this.errors = [];
    this.modalClose.emit(false);
  }

  onConfirm() {
    this.errors = [];
    if (!this.formGroup.invalid) {
      this.modalClose.emit(this.formGroup.getRawValue());
    } else {
      this.errors = this.createPostsalesErrorByFormControl();
    }
  }

  createPostsalesErrorByFormControl(): PostsalesError[] {
    return Object.keys(this.formGroup.controls)
    .filter(controlName => this.formGroup.get(controlName).invalid)
    .map(controlName => {
      let field = this.translate.getImmediate('lpc_policyHolder');
      if (controlName === 'nodeDescription') {
        field = this.translate.getImmediate('lpc_managementnode');
      }
      return {
        context: 'modal',
        errorMessage: this.translate.getImmediate('lpc_custom_field_mandatory_error', { field }),
        severity: PV_ERROR_SEVERITY_VALUE.ERROR,
        isFe: true,
        type: 'persistent'
      } as PostsalesError;
    })
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }
}

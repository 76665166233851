<div class="btn-group btn-group-justified">
  <div class="input-group">
    <label>Polizza
      <input type="text" [(ngModel)]="policyNumber" name="policyNumber" data-qa="input-policyNumber">
    </label>
  </div>
  <div class="btn-group">
    <button (click)="onSubmit()" class="btn btn-warning" data-qa='new-life-postSales' translate type="submit">
      NEXT
    </button>
  </div>
</div>

<table class="rgi-ui-datepicker-calendar-table rgi-ui-datepicker-calendar-month-table"
       [class.rgi-ui-datepicker-calendar-only-current-month]="hideOtherMonths">
  <thead class="rgi-ui-datepicker-calendar-header">
  <tr class="rgi-ui-datepicker-weekdays">
    <th *ngFor="let weekday of weekdays"
        [attr.aria-label]="weekday.long"
        class="rgi-ui-datepicker-weekday" scope="col">
      <span>{{weekday.short}}</span>
    </th>
  </tr>
  <tr>
    <th class="rgi-ui-datepicker-calendar-table-divider" aria-hidden="true" colspan="7"></th>
  </tr>
  </thead>
  <tbody rgi-rx-calendar-body role="grid"
         [rows]="days" [todayValue]="todayDate"
         [selectedValues]="selectedDates"
         [selectMode]="selectMode"
         [activeCell]="activeCell"
         (keydown)="handleCalendarKeydown($event)"
         (select)="selectCalendarCell($event)">
  </tbody>
</table>

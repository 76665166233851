import {VehicleVersion} from './vehicleVersion';
import {Model} from './model';

export class VehicleMagazine {

  constructor(
    public vehicleVersion: VehicleVersion,
    public model: Model
  ) {
  }
}

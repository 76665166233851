import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxOptionComponent} from './rgi-rx-option/rgi-rx-option.component';
import {FormsModule} from '@angular/forms';
import {RgiRxButtonDirective} from './rgi-rx-button/rgi-rx-button-directive';
import {RgiRxQualityAssuranceModule} from '@rgi/rx';
import {RgiRxButtonToggleComponent} from './rgi-rx-button-toggle/rgi-rx-button-toggle.component';
import {RgiRxValueOptionDirective} from './rgi-rx-value-option.directive';
import {RgiRxFormFieldComponent} from './rgi-rx-form-field/rgi-rx-form-field.component';
import {RgiRxInputDirective} from './rgi-rx-input.directive';
import {RgiRxLabelDirective} from './rgi-rx-label.directive';
import {RgiRxDefaultErrorStateMatcher, RgiRxErrorStateMatcher} from './rgi-rx-form-elements-api';
import {RgiRxInputFileComponent} from './rgi-rx-input-file/rgi-rx-input-file.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {RGI_RX_FORM_ELEMENTS_TRANSLATIONS} from './form-elements.i18n';
import {RgiRxNativeSelectDirective} from './rgi-rx-native-select.directive';
import {RgiRxFormControlDirective} from './rgi-rx-form-control.directive';
import {RgiRxFormFieldErrorComponent} from './rgi-rx-form-field-error/rgi-rx-form-field-error.component';

@NgModule({
  declarations: [
    RgiRxOptionComponent,
    RgiRxButtonDirective,
    RgiRxButtonToggleComponent,
    RgiRxValueOptionDirective,
    RgiRxFormFieldComponent,
    RgiRxInputDirective,
    RgiRxLabelDirective,
    RgiRxInputFileComponent,
    RgiRxNativeSelectDirective,
    RgiRxFormControlDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    RgiRxI18nModule.forRoot(RGI_RX_FORM_ELEMENTS_TRANSLATIONS),
    RgiRxQualityAssuranceModule,
    RgiRxFormFieldErrorComponent
  ],
  exports: [
    RgiRxOptionComponent,
    RgiRxButtonDirective,
    RgiRxButtonToggleComponent,
    RgiRxValueOptionDirective,
    RgiRxFormFieldComponent,
    RgiRxInputDirective,
    RgiRxLabelDirective,
    RgiRxInputFileComponent,
    RgiRxNativeSelectDirective,
    RgiRxFormControlDirective,
  ],
  providers: [
    {
      provide: RgiRxErrorStateMatcher,
      useClass: RgiRxDefaultErrorStateMatcher
    },
  ]
})
export class RgiRxFormElementsModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LinkPartyEvent} from '../party-link/party-link.component';
import {AnagPartyLink} from '../../anag-model/anag-domain/anag-party-link';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AnagDialogComponent, ModalDialogData} from '../modal/anag-dialog/anag-dialog.component';
import {ModalService} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagStateManagerPartyEditor} from '../../anag-states/party-editor/anag-state-manager-party-editor';

@Component({
  selector: 'rgi-anag-ati',
  templateUrl: './ati.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagAtiComponent implements OnInit {

  @Input()
  party: AnagApiParty;

  @Output()
  linkUpd: EventEmitter<LinkPartyEvent> = new EventEmitter<LinkPartyEvent>();

  atiLinksForm = new UntypedFormGroup({
    leaderDropContainer: new UntypedFormControl(),
    participantDropContainer: new UntypedFormControl([]),
  });


  constructor(
    protected modalService: ModalService,
    protected translateService: RgiRxTranslationService,
    public stateMgr: AnagStateManagerPartyEditor
  ) {

  }

  ngOnInit() {
    if (!!this.party && !!this.party.links && !!this.party.links.length) {
      this.atiLinksForm.setControl('participantDropContainer', new UntypedFormControl(this.getParticipants()));
      const valuedLeader = this.party.links.find(l => l.linkType.codice === '23') as any;
      if (!!valuedLeader) {
        valuedLeader.routeData = {
          party: {
            nominative: valuedLeader.linkSubjectNominative
          }
        };
        this.atiLinksForm.get('leaderDropContainer').setValue(valuedLeader);
      }
    }

    if (!this.stateMgr.getCurrentState().editorModeEnable) {
      this.atiLinksForm.get('leaderDropContainer').disable();
      this.atiLinksForm.get('participantDropContainer').disable();
    }
  }

  onDropSubjectSelection(event, linkType: string) {
    const partySelected = event.changed;
    if (partySelected) {
      const alreadyExists = this.party.links &&
        this.party.links.find(link => link.linkedPartyObjectId === partySelected.objectId);
      if (partySelected.objectId === this.party.objectId || alreadyExists) {
        let msg;
        this.translateService.translate('_ANAG_._MSG_._LINK_ERROR_').subscribe(translated => msg = translated).unsubscribe();
        this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(msg, false));
        if (linkType === '23') {
          // this.atiLinksForm.get('leaderDropContainer').reset();
          this.atiLinksForm.removeControl('leaderDropContainer');
          this.atiLinksForm.updateValueAndValidity();
          this.atiLinksForm.setControl('leaderDropContainer', new UntypedFormControl(undefined));
          this.atiLinksForm.updateValueAndValidity();
        } else {
          const cleanedArray = this.atiLinksForm.get('participantDropContainer').value.filter(el => {
            if (
              (el.routeData && el.routeData.party && el.routeData.party.objectId === partySelected.objectId) ||
              (el.linkedPartyObjectId === partySelected.objectId)
            ) {
              return false;
            }
            return true;
          });
          this.atiLinksForm.get('participantDropContainer').setValue(cleanedArray);
        }
      } else {
        const link = new AnagPartyLink();
        link.linkSubjectNominative = partySelected.nominative;
        link.linkedPartyObjectId = partySelected.objectId;
        link.linkType = linkType;
        this.linkUpd.emit(new LinkPartyEvent('addLink', link));
      }
    }
  }

  getParticipants(): Array<any> {
    return this.party.links.filter(link => link.linkType.codice !== '23').map(link => {
      return {
        idSubjectLinkType: link.idSubjectLinkType,
        linkSubjectNominative: link.linkSubjectNominative,
        linkType: link.linkType,
        linkedPartyObjectId: link.linkedPartyObjectId,
        linkedPartyUniqueKey: link.linkedPartyUniqueKey,
        nominative: link.linkSubjectNominative
      };
    });
  }

  removeLink($event) {
    const linkToDelete = new AnagPartyLink();
    linkToDelete.linkedPartyObjectId = $event.data.linkedPartyObjectId ? $event.data.linkedPartyObjectId :
      $event.data.routeData.party.objectId;
    this.linkUpd.emit(new LinkPartyEvent('deleteLink', linkToDelete));
  }
}

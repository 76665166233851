import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxPanelComponent} from './rgi-rx-panel/rgi-rx-panel.component';
import {RgiRxPanelHeaderComponent} from './rgi-rx-panel-header/rgi-rx-panel-header.component';
import {RgiRxPanelFooterComponent} from './rgi-rx-panel-footer/rgi-rx-panel-footer.component';
import {RgiRxQualityAssuranceModule} from '@rgi/rx';


@NgModule({
  declarations: [RgiRxPanelComponent, RgiRxPanelHeaderComponent, RgiRxPanelFooterComponent],
  imports: [
    CommonModule,
    RgiRxQualityAssuranceModule
  ],
  exports: [RgiRxPanelComponent, RgiRxPanelHeaderComponent, RgiRxPanelFooterComponent]
})
export class RgiRxPanelModule {
}

import { forwardRef, OnChanges, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { LpcKarmaDisinvestmentFundsComponent } from '../../lpc-disinvestments/lpc-karma-disinvestment-funds/lpc-karma-disinvestment-funds.component';


@Component({
  selector: 'lpc-karma-disinvestment-full-funds',
  templateUrl: '../../lpc-disinvestments/lpc-karma-disinvestment-funds/lpc-karma-disinvestment-funds.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFullFundsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcKarmaDisinvestmentFullFundsComponent),
      multi: true
    }
  ]
})
export class LpcKarmaDisinvestmentFullFundsComponent extends LpcKarmaDisinvestmentFundsComponent implements
  OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  ngOnInit(): void {
    super.ngOnInit(); // Call the ngOnInit method from the parent component
  }

  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    this.formGroup.get('funds').updateValueAndValidity();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const obj: ValidationErrors = {};
    let funds: any = [];
    if (!!control.value && Object.keys(control.value).every(el => control.value[el] == null)) {
      obj.required = true;
    }
    funds = this.populateFundsErrorField();
    if (!!Object.keys(funds).length) {
      obj.funds = funds;
    }
    const errors = !!Object.keys(obj).length ? obj : null;
    this.$errors = this.getErrorMessages(errors);
    return errors;
  }

  }

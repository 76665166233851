import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-re-issue-proposal-management-payments',
  templateUrl: './re-issue-proposal-management-payments.component.html',
  styleUrls: ['./re-issue-proposal-management-payments.component.css']
})
export class ReIssueProposalManagementPaymentsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

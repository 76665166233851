<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
    <span translate>RE_ISSUE.CO_INSURANCE</span>
  </rgi-rx-panel-header>

  <div class="modal-body large-modal">
    <span class="title-content-card"
          *ngIf="coInsuranceDTO.coinsuranceType?.code === ReIssueCoINsuranceEnum.THIRD_PARTY_MANDATE" translate>RE_ISSUE.THIRD_PARTY_QUOTA</span>
    <span class="title-content-card"
          *ngIf="coInsuranceDTO.coinsuranceType?.code != ReIssueCoINsuranceEnum.THIRD_PARTY_MANDATE" translate>RE_ISSUE.NEW_QUOTA</span>
    <br>
    <br>
    <form [formGroup]="newQuotaForm">

      <rgi-rx-form-field>
        <label rgiRxLabel translate>RE_ISSUE.COMPANY</label>
        <select rgiRxNativeSelect attr.data-qa="company" formControlName="company" (change)="resetFlag()">
          <option value=""></option>
          <option *ngFor="let comp of companies" [ngValue]="comp.identification">{{comp.description}}</option>
        </select>
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel translate>RE_ISSUE.INTERMEDIARY</label>
        <select rgiRxNativeSelect attr.data-qa="intermediary" formControlName="intermediary" (change)="resetFlag()">
          <option value=""></option>
          <option *ngFor="let inter of intermediaries" [ngValue]="inter.identification">{{inter.description}}</option>
        </select>
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel translate>RE_ISSUE.QUOTA_PERCENTAGE</label>
        <input rgiRxInput formControlName="quotaPerc" data-qa="quotaPerc">
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel translate>RE_ISSUE.COMMISSION_PERCENTAGE</label>
        <input rgiRxInput formControlName="commissionPerc" data-qa="commissionPerc">
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel translate>RE_ISSUE.POLICY_NUMBER</label>
        <input rgiRxInput formControlName="policyNumber" data-qa="policyNumber">
      </rgi-rx-form-field>

    </form>
  </div>

  <rgi-rx-snackbar tag="{{TAG_QUOTA_ERRORS}}"></rgi-rx-snackbar>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button data-qa="cancel" class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" translate>RE_ISSUE.CANCEL</button>
    <button [disabled]="!closeModalQuote" data-qa="confirm" class="rgi-ui-btn rgi-ui-btn-primary" (click)="submit()"
            translate> RE_ISSUE.CONFIRM
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>


export const restEndPoint = {
    fillInSearchFunMes: '/claims/getFunctionListForPayment', /* riempie le select di function e message nella search */
    fillInSearchCompany: '/claims/companyList', /* riempie le select delle compagnie nella search*/
    searchPayments: '/claims/searchPayments',

    deleteForfait:'/claims/deleteForfait',
    searchForfait:'/claims/searchForfait',
    modifyForfait:'/claims/modifyForfait',
    addForfait:'/claims/addForfait',

    bookValidation: '/claims/bookValidation',
    bookConsolidation: '/claims/bookConsolidation'
}
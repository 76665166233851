import {Injectable} from '@angular/core';
import {
  RePostsalesCancellationSummaryResourceService
} from '../re-postsale-resources/re-postsales-cancellation-summary.resource.service';
import {
  RePostSalesCancellationSummaryState
} from '../re-postsale-state-manager/re-postsales-state-manager-cancellation-summary.service';

@Injectable({
  providedIn: 'root'
})
export class RePostsalesCancellationSummaryBusinessService<T extends RePostSalesCancellationSummaryState> {

  constructor(
    private resourcesService: RePostsalesCancellationSummaryResourceService
  ) {
  }
}

import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { ROUTES_GP_CONF_PM, ROUTES_GP_SUMMARY } from '../../group-policy-constants/routes-constants';
import { GPIdentificationEntity } from '../../group-policy-models/group-policy-domain-types';
import {
  Commission,
  GPAsset,
  GpAssetUnitsClauses,
  LinkedPolicyQuotation,
  Surveys
} from '../../group-policy-models/group-policy-issue-guarantees';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import { BaseEntity, ErrorCode } from '../../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyCrossService } from '../../group-policy-services/group-policy-cross.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyStateGuarantees } from '../group-policy-state';


@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpGuaranteesService {

  constructor(
    protected groupPolicyService: GroupPolicyResourceService,
    protected routingService: RoutingService,
    protected gpErrorService: GroupPolicyApiRestErrorService,
    protected integrationService: GroupPolicyIntegrationService,
    protected crossService: GroupPolicyCrossService) {
  }

  public initGuaranteesData$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>,
    activeRoute: ActiveRoute): Observable<GroupPolicyStateGuarantees> {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    return groupPolicyStateGuarantees$.pipe(
      mergeMap((st: GroupPolicyStateGuarantees) => {
        return combineLatest(of(st),
          this.getAssets$(of(st), previousStepData.resId),
          this.retrieveCommissions$(of(st), previousStepData.resId),
          this.retrieveQuestionnaires$(of(st), previousStepData.resId, '2'),
          this.retrieveLinkedPolicyQuotation$(of(st), previousStepData.resId),
          this.getUnitInitialStatuses$(of(st)),
          this.retrieveProductCategory$(of(st), previousStepData.resId)
        );
      }),
      map(([st]) => {
        st.errors = previousStepData.errors;
        st.type = previousStepData.type;
        st.proposalNumber = null;
        return st;
      })
    );
  }

  getAssets$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>,
    uuid: string): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      mergeMap((state: GroupPolicyStateGuarantees) => {
        return combineLatest(
          of(state),
          this.groupPolicyService.getAssets$(uuid),
          this.groupPolicyService.getAvailableAssets$(uuid),
          this.groupPolicyService.getAssetUnitsClauses$(uuid));
      }),
      map((
        [state, assets, availableAssets, assetUnitClauses]:
          [GroupPolicyStateGuarantees, GPAsset[], BaseEntity[], GpAssetUnitsClauses[]]
      ) => {
        state.assets = assets;
        state.availableAssets = availableAssets;
        state.assetUnitClauses = assetUnitClauses;
        if (assets && assets.length > 0) {
          state.isClusterContract = assets[0].structure.code === '2';
        }
        return state;
      })
    );
  }

  deleteAsset$(state$: Observable<GroupPolicyStateGuarantees>, assetCode: string, uuid: string): Observable<GroupPolicyStateGuarantees> {
    return state$.pipe(
      mergeMap((state: GroupPolicyStateGuarantees) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_DEL_ASSET');
        return this.groupPolicyService.deleteAsset$(uuid, assetCode).pipe(
          map(_resp => {
            state.errors = this.gpErrorService.manageErrors(_resp, 'FOOTER_DEL_ASSET');
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_DEL_ASSET'))
        );
      }),
      mergeMap((st: GroupPolicyStateGuarantees) => {
        return this.getAssets$(of(st), uuid);
      }),
      mergeMap((st: GroupPolicyStateGuarantees) => {
        return this.retrieveCommissions$(of(st), uuid);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

  public retrieveCommissions$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>,
    uuid: string): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      concatMap((state: GroupPolicyStateGuarantees) => {
        return combineLatest(of(state), this.groupPolicyService.retrieveCommissions$(uuid));
      }),
      map(([state, commissions]: [GroupPolicyStateGuarantees, Commission[]]) => {
        state.commissions = commissions;
        state.proposalNumber = null;
        return state;
      })
    );
  }

  public retrieveLinkedPolicyQuotation$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>,
    uuid: string): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      concatMap((state: GroupPolicyStateGuarantees) => {
        return combineLatest(of(state), this.groupPolicyService.retrieveLinkedPolicyQuotation$(uuid));
      }),
      map(([state, quotations]: [GroupPolicyStateGuarantees, LinkedPolicyQuotation]) => {
        state.quotations = quotations.quotationDetail || quotations.quotationGuaranteeDetails
          || quotations.summaryQuotation ? quotations : undefined;
        state.proposalNumber = null;
        return state;
      })
    );
  }

  // should avoid GET calls concatenation?
  public updateAssets$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>, uuid: string,
    assets: GPAsset[]): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      mergeMap((st: GroupPolicyStateGuarantees) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_ASSET_UPD');
        return this.groupPolicyService.updateAssets$(uuid, assets).pipe(
          map(_resp => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_ASSET_UPD'))
        );
      }),
      mergeMap((st: GroupPolicyStateGuarantees) => {
        return combineLatest(this.getAssets$(of(st), uuid), this.groupPolicyService.retrieveCommissions$(uuid));
      }),
      mergeMap(([state, commissions]: [GroupPolicyStateGuarantees, Commission[]]) => {
        state.commissions = commissions;
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_QUESTIONNAIRES_GET');
        return this.groupPolicyService.retrieveQuestionnaires$(uuid, '2').pipe(
          map(questionnaires => {
            state.questionnaires = questionnaires;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_QUESTIONNAIRES_GET'))
        );
      }),
      mergeMap((st: GroupPolicyStateGuarantees) => {
        return combineLatest(of(st), this.groupPolicyService.retrieveLinkedPolicyQuotation$(uuid));
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map(([state, quotations]: [GroupPolicyStateGuarantees, LinkedPolicyQuotation]) => {
        state.quotations = quotations.quotationDetail || quotations.quotationGuaranteeDetails
          || quotations.summaryQuotation ? quotations : undefined;
        return state;
      })
    );
  }

  public updateCommissions$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>, uuid: string,
    commissions: Commission[]): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      concatMap((st: GroupPolicyStateGuarantees) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_COMMISSION');
        return this.groupPolicyService.updateCommissions$(uuid, commissions).pipe(
          map((_resp) => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_COMMISSION'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

  public retrieveQuestionnaires$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>, uuid: string,
    level: '1' | '2'): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      concatMap((state: GroupPolicyStateGuarantees) => {
        return combineLatest(of(state), this.groupPolicyService.retrieveQuestionnaires$(uuid, level));
      }),
      map(([state, questionnaires]: [GroupPolicyStateGuarantees, Surveys[]]) => {
        state.questionnaires = questionnaires;
        return state;
      })
    );
  }

  public updateQuestionnaires$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>, uuid: string,
    questionnaire: Surveys[]): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      concatMap((st: GroupPolicyStateGuarantees) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_QUESTIONNAIRE_UPD');
        return this.groupPolicyService.updateQuestionnaires$(uuid, questionnaire).pipe(
          map((_resp) => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_QUESTIONNAIRE_UPD'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

  public actionBack$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>, previousStepData: DataForSteps,
    activeRoute: ActiveRoute, targetRoute = ROUTES_GP_CONF_PM): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      tap(() => {
        previousStepData.errors = [];
        previousStepData.type = ErrorCode.BLOCKING;
        this.integrationService.navigate(this.routingService, targetRoute, previousStepData, activeRoute);
      })
    );
  }

  public actionSave$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>,
    uuid: string, nodeCode: string, activeRoute: ActiveRoute): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      concatMap((state: GroupPolicyStateGuarantees) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_ACTION');
        return this.groupPolicyService.actionCode$(uuid, 'UPM').pipe(
          map((resp) => {
            state.errors = this.gpErrorService.manageErrors(resp, 'FOOTER_ACTION');
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_ACTION'))
        );
      }),
      concatMap((st: GroupPolicyStateGuarantees) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_SAVE');
        return this.groupPolicyService.saveAction$(uuid, nodeCode).pipe(
          map((saveResp) => {
            st.proposalNumber = saveResp.proposalNumber;
            this.crossService.showModalContractNumber(saveResp, activeRoute.id).subscribe();
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_SAVE'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

  public actionGoToSummary$(
    groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>,
    previousStepData: DataForSteps, activeRoute: ActiveRoute): Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      mergeMap((st: GroupPolicyStateGuarantees) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_ACTION');
        return this.groupPolicyService.actionCode$(previousStepData.resId, 'UPM').pipe(
          map((resp) => {
            st.errors = this.gpErrorService.manageErrors(resp, 'FOOTER_ACTION');
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_ACTION'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => {
        if (!this.gpErrorService.containsBlockingErrors(st.errors)) {
          this.integrationService.navigate(this.routingService, ROUTES_GP_SUMMARY, previousStepData, activeRoute);
        }
        return st;
      })
    );
  }

  addAssets$(state: Observable<GroupPolicyStateGuarantees>, assets: string[], uuid: string): Observable<GroupPolicyStateGuarantees> {
    return state.pipe(
      mergeMap(st => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_ACTION');
        return this.groupPolicyService.addAssets$(uuid, assets).pipe(
          map((_resp) => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_ACTION'))
        );
      }),
      mergeMap((st: GroupPolicyStateGuarantees) => {
        return this.getAssets$(of(st), uuid);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

  updateAssetClauses$(
    state: Observable<GroupPolicyStateGuarantees>, assetUnitsClauses: GpAssetUnitsClauses,
    data: DataForSteps): Observable<GroupPolicyStateGuarantees> {
    let updateClauses$;
    if (assetUnitsClauses.assetClauses && assetUnitsClauses.assetClauses.length) {
      updateClauses$ = this.groupPolicyService.updateAssetClauses$(
        data.resId,
        assetUnitsClauses.codeAsset,
        assetUnitsClauses.assetClauses
      );
    } else if (assetUnitsClauses.unitsClauses && assetUnitsClauses.unitsClauses.length) {
      const gpUnitClause = assetUnitsClauses.unitsClauses[0];
      updateClauses$ = this.groupPolicyService.updateAssetUnitClauses$(
        data.resId,
        assetUnitsClauses.codeAsset,
        gpUnitClause.codeUnit,
        gpUnitClause.codeSection,
        gpUnitClause.unitClauses
      );
    }

    return state.pipe(
      concatMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_CLAUSES_UPD');
        return updateClauses$.pipe(
          map(_resp => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_CLAUSES_UPD'))
        );
      }),
      concatMap((st: GroupPolicyStateGuarantees) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_CLAUSES_GET');
        return this.groupPolicyService.getAssetUnitsClauses$(data.resId).pipe(
          map(assetUnitClauses => {
            st.assetUnitClauses = assetUnitClauses;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_CLAUSES_GET'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

  public getUnitInitialStatuses$(groupPolicyStateGuarantees$: Observable<GroupPolicyStateGuarantees>)
    : Observable<GroupPolicyStateGuarantees> {
    return groupPolicyStateGuarantees$.pipe(
      mergeMap((state: GroupPolicyStateGuarantees) => {
        return this.groupPolicyService.getUnitInitialState$().pipe(
          map((resp: BaseEntity[]) => {
            state.unitInitialState = resp;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_INITIAL_STATUSES'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

  public retrieveProductCategory$(st$: Observable<GroupPolicyStateGuarantees>, uuid: string): Observable<GroupPolicyStateGuarantees> {
    return st$.pipe(
      mergeMap((state: GroupPolicyStateGuarantees) => {
        return this.groupPolicyService.getProductCategoryFromDraft$(uuid).pipe(
          map((productCategory: GPIdentificationEntity) => {
            state.productCategory = productCategory;
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_INITIAL_STATUSES'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateGuarantees) => st)
    );
  }

}

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Product and Asset Selection</span>
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="productAssetForm">

          <div *ngIf="!isSubstitution" class="form-group row">
            <label translate>Product</label>
            <pnc-required required="true"></pnc-required>
            <select #product (change)="onProductChange()" class="select-nativa mic-select-product" formControlName="product"
                    name="product">
              <option></option>
              <option *ngFor="let product of products; index as i; trackBy: genericEntitiesTrackByFn"
                      [ngValue]="product">
                {{ product?.extendedDescription ? product?.extendedDescription : product?.description }}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
          </div>

          <div *ngIf="productAssetForm?.controls?.product?.value && assetTypes?.length > 1" class="form-group row">
            <label translate>Asset Type</label>
            <pnc-required required="true"></pnc-required>
            <select #assetType (change)="onAssetTypeChange()" class="select-nativa" formControlName="assetType"
                    name="assetType">
              <option></option>
              <option *ngFor="let assetType of assetTypes; index as i; trackBy: genericEntitiesTrackByFn"
                      [ngValue]="assetType">
                {{ assetType?.extendedDescription ? assetType?.extendedDescription : assetType?.description }}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
          </div>

          <div *ngIf="showVehicleType()" class="form-group row">
            <label translate>Vehicle Type</label>
            <pnc-required required="true"></pnc-required>
            <select #vehicleType (change)="onVehicleTypeChange()" class="select-nativa" formControlName="vehicleType"
                    name="vehicleType">
              <option></option>
              <option *ngFor="let vehicleType of vehicleTypes; index as i; trackBy: genericEntitiesTrackByFn"
                      [ngValue]="vehicleType">
                {{vehicleType?.description}}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
          </div>

          <div *ngIf="((productAssetForm?.controls?.product?.value || isSubstitution) && (wrongDataFromAnia || dataInAniaNotFound || otherPlates) && plateTypes.length > 1) || isTestPlate"
               class="form-group row">
            <label translate>Plate Type</label>
            <pnc-required required="true"></pnc-required>
            <select #plateType (change)="onPlateTypeChange()" class="select-nativa" formControlName="plateType"
                    name="plateType">
              <option></option>
              <option *ngFor="let plateType of plateTypes; index as i; trackBy: genericEntitiesTrackByFn"
                      [ngValue]="plateType">
                {{plateType?.description}}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
          </div>

          <div *ngIf="isSubstitution && !otherPlates" class="form-group row">
            <div class="col-sm-12 col-xs-12">
              <label translate>Plate Number</label>
              <pnc-required required="true"></pnc-required>
              <input #licensePlate [value]="licensePlate?.value | uppercase" class="form-control mic-input" data-qa="licensePlate"
                     formControlName="licensePlate" type="text">
            </div>
          </div>

          <div *ngIf="otherPlates && productAssetForm?.controls?.plateType?.value?.code === '6'"
               class="form-group row">
            <label translate>Special Plate Type</label>
            <pnc-required required="true"></pnc-required>
            <select #specPlateType (change)="onSpecialPlateTypeChange()" class="select-nativa mic-select-product"
                    formControlName="specPlateType" name="specPlateType">
              <option></option>
              <option *ngFor="let specPlateType of specPlateTypes; index as i; trackBy: genericEntitiesTrackByFn"
                      [ngValue]="specPlateType">
                {{specPlateType?.description}}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
          </div>

          <div *ngIf="otherPlates" class="form-group row">
            <div *ngIf="productAssetForm?.controls?.specPlateType?.value" class="col-sm-6 col-xs-12">
              <label translate>Prefix:</label>
              <pnc-required required="true"></pnc-required>
              <select #specPlatePrefix class="select-nativa mic-select-product" formControlName="specPlatePrefix"
                      name="specPlatePrefix">
                <option></option>
                <option *ngFor="let specPlatePrefix of specPlatePrefixes; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="specPlatePrefix">
                  {{specPlatePrefix}}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
            <div *ngIf="productAssetForm?.controls?.plateType?.value?.code === '2' || productAssetForm?.controls?.plateType?.value?.code === '4'"
                 class="col-sm-6 col-xs-12">
              <label translate>Country</label>
              <pnc-required required="true"></pnc-required>
              <select #countries class="select-nativa mic-select-product" formControlName="countries" name="countries">
                <option></option>
                <option *ngFor="let country of countriesList; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="country">
                  {{country?.description}}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
            <div *ngIf="productAssetForm?.controls?.specPlateType?.value
              || productAssetForm?.controls?.countries?.value" class="col-sm-6 col-xs-12">
              <label translate>Plate Number</label>
              <pnc-required required="true"></pnc-required>
              <input #licensePlate [value]="licensePlate?.value | uppercase" class="form-control mic-input" data-qa="licensePlate"
                     formControlName="licensePlate" type="text">
            </div>
          </div>

        </form>
        <div *ngIf="(productAssetForm?.invalid && submitted && validationMessages && validationMessages?.length > 0) || ( plateTypeValidityChecked && !isPlateTypeValid )" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>

      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="cancel()" class="btn btn-warning" data-qa="btn-prd-modal-cancel" translate>CANCEL</button>
          </div>
          <div *ngIf="!isSubstitution" class="btn-group">
            <button (click)="onSubmit()" [disabled]="(submitted && !productAssetForm?.valid) || ( ( wrongDataFromAnia || dataInAniaNotFound ) && !isPlateTypeValid)" class="btn btn-warning"
                    data-qa="btn-prd-modal-confirm"
                    translate>CONFIRM
            </button>
          </div>
          <div *ngIf="isSubstitution" class="btn-group">
            <button (click)="substitutionSubmit()" class="btn btn-warning" data-qa="btn-prd-modal-confirm"
                    translate>CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {AssetInstance} from './asset-instance';

export class Asset {
  public assetId: string;
  public code: string;
  public description: string;
  public extendedDescription: string;
  public maxInstances: string;
  public minInstances: string;
  public name: string;
  public instances: Array<AssetInstance>;
  public roles: any;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActiveRoute } from '@rgi/rx/router';
import { RgiRxStepperComponent } from '@rgi/rx/ui';
import {
  ROUTES_GP_CONF_PM,
  ROUTES_GP_GUARANTEES,
  ROUTES_GP_POLDATA,
  ROUTES_GP_SUMMARY
} from '../../group-policy-constants/routes-constants';

@Component({
  selector: 'rgi-gp-stepper',
  templateUrl: './group-policy-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPolicyStepperComponent implements OnInit {

  @ViewChild('stepper', { static: true }) stepper: RgiRxStepperComponent;

  @Input() vertical = false;
  @Input() showIndex = true;
  @Input() disabled = false;
  @Input() steps: Array<string> = [
    ROUTES_GP_POLDATA,
    ROUTES_GP_CONF_PM,
    ROUTES_GP_GUARANTEES,
    ROUTES_GP_SUMMARY,
  ];
  @Input() labels: Array<string> = [
    '_GP_._TITLE_._POLICY_DATA_',
    '_GP_._TITLE_._MP_CONFIGURATION_',
    '_GP_._TITLE_._ASSET_GUARANTEES_',
    '_GP_._TITLE_._SUMMARY_',
  ];

  @Output() targetRoute = new EventEmitter<string>();

  constructor(protected route: ActiveRoute) { }

  ngOnInit(): void {
    this.stepper.selectedIndex = this.steps.indexOf(this.route.route);
  }

}

import { Inject, Injectable, Optional } from '@angular/core';
import { PV_TOKEN } from '../models/consts/lpc-consts';
import { BeneficiaryCathegory, BeneficiaryCathegoryCH } from '../modules/lpc-beneficiary/model/lpc-beneficiary-roles.enum';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';


// TODO move this service on a different project when the architect team will implement
@Injectable({
  providedIn: 'root'
})
export class LpcLayeredRuleService {

  constructor(
    protected translate: TranslationWrapperService,
    @Optional() @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any
  ) {
  }

  // TODO Add other context as readOnly for the switch case
  private readonly CONTEXT_CH = 'CH';

  private _context = 'it';
  get context() {
    return this._context;
  }
  set context(c) {
    this._context = c;
  }

  public isRequiredChekRolesOnIssuePolicy(context?: string): boolean {
    if (!!context) {
      this.context = context;
    }
    switch (this.context.toLocaleUpperCase()) {
      case this.CONTEXT_CH:
        return true;

      default:
        return false;
    }
  }

  public getDeathBeneficiaryDesc(category: string): string {


    let result = null;
    switch (this.context.toLocaleUpperCase()) {
      case this.CONTEXT_CH:
        const label3a3b = BeneficiaryCathegoryCH.MORTE_3A === category ? '3a' : BeneficiaryCathegoryCH.MORTE_3B === category ? '3b' : null;
        if (label3a3b) {
          result = this.translate.getImmediate(
            'lpc_beneficiary_qualification',
            {benefRole: this.translate.getImmediate('lpc_life_detail_beneficiary'),
             benefCat: label3a3b});
        }
        break;
      default:
        result = null;
      }

    return result;
  }



  public isDeathBeneficiary(category: string): boolean {


    // Il beneficiario morte corrisponde a '2' sul layer italia. Su svizzera (CH) i codici vengono estesi con '4' e '5' (3a, 3b)
    let result = false;
    switch (this.context.toLocaleUpperCase()) {
      case this.CONTEXT_CH:
        result = (BeneficiaryCathegory.MORTE === category ||
          BeneficiaryCathegoryCH.MORTE_3A === category ||
          BeneficiaryCathegoryCH.MORTE_3B === category);
        break;

      default:
        result = (BeneficiaryCathegory.MORTE === category);
      }

    return result;
  }

}

import {
  ReIssueWarningsResourceService
} from '../re-issue-resources/re-issue-warnings-resource.service';
import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map, mergeMap, share, switchMap} from 'rxjs/operators';
import {ReIssueAssetUtilityService} from './re-issue-asset-utility.service';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {RgiRxLogger} from '@rgi/rx';
import {
  ReIssuePortfolioQuotationResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-quotation-resource.service';
import {PaymentFrequency} from '../../models/api-models/api-payment-frequency';
import {
  ReIssuePortfolioPaymentFrequencyResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-payment-frequency-resource.service';
import {
  PcPaymentFrequency
} from '../../models/pc-portfolio-models/payment-frequency-models/pc-payment-frequency';
import {
  ReIssueRefactorService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service';
import {
  Package,
  PcInsuredAsset
} from '../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {
  PcAsset,
  PcAssetInstance
} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {PcUnit} from '../../models/pc-portfolio-models/quotation-models/pc-unit';
import {
  ReIssuePortfolioWarningsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-warnings-resource.service';
import {
  ReIssueQuestionnaireUtilityService
} from '../re-issue-utility/re-issue-questionnaire-utility.service';
import {Asset} from '../../models/domain-models/asset';
import {Unit} from '../../models/domain-models/unit';
import {
  ReIssuePortfolioProposalService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal.service';
import {
  ReIssueVariablesResourceService
} from '../re-issue-resources/re-issue-variables-resource.service';
import {
  ReIssuePortfolioVariablesResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-variables-resource.service';
import {Warning} from '../../models/domain-models/warning';
import {
  ReIssuePortfolioContactResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-contact-resource.service';
import {Beneficiaries} from '../../models/domain-models/beneficiaries';
import {Beneficiary} from '../../models/domain-models/beneficiary';
import {
  PcInstalmentDate
} from '../../models/pc-portfolio-models/instalment-models/pc-instalment-date';
import {
  ReIssuePortfolioInstalmentResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-instalment-resource.service';
import {Clause} from '../../models/domain-models/clause';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {
  ReIssuePortfolioLpsDataResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-lps-data-resource.service';
import {CommissionsWrap} from '../../models/domain-models/parameters/commission-detail';
import {InputPremium} from '../../models/domain-models/inputPremium';
import {Notes} from '../../models/domain-models/notes';
import {GenericEntityPcPortfolio} from '../../models/pc-portfolio-models/genericEntityPcPortfolio';
import {
  FlexDiscountParam
} from '../../models/pc-portfolio-models/discounts-models/flex-discount-param';
import {
  FlexDiscountUnit
} from '../../models/pc-portfolio-models/discounts-models/flex-discount-unit';
import {DiscountFund} from '../../models/pc-portfolio-models/discounts-models/discount-fund';
import {ReIssueQuotationState} from '../re-issue-state-manager/re-issue-quotation.state';
import {PcClause} from "../../models/pc-portfolio-models/quotation-models/pc-clause";

@Injectable({
  providedIn: 'root'
})
export class ReIssueQuotationBusinnessService<T extends ReIssueQuotationState> {
  protected readonly PRE_QUOTATION_WARNING_LEVEL = 'PRE_QUOTATION';
  protected readonly POST_QUOTATION_WARNING_LEVEL = 'POST_QUOTATION';

  constructor(
    protected quotationResourceService: ReIssuePortfolioQuotationResourceService,
    protected issueAssetUtilityService: ReIssueAssetUtilityService,
    protected warningsResourceService: ReIssueWarningsResourceService,
    protected portfolioWarningsResourceService: ReIssuePortfolioWarningsResourceService,
    protected questionnaireUtilityService: ReIssueQuestionnaireUtilityService,
    protected refactorService: ReIssueRefactorService,
    protected logger: RgiRxLogger,
    protected paymentFrequencyResourceService: ReIssuePortfolioPaymentFrequencyResourceService,
    protected portfolioProposalService: ReIssuePortfolioProposalService,
    protected variablesResourceService: ReIssueVariablesResourceService,
    protected portfolioVariablesResourceService: ReIssuePortfolioVariablesResourceService,
    protected portfolioContactResourceService: ReIssuePortfolioContactResourceService,
    protected instalmentResourceService: ReIssuePortfolioInstalmentResourceService,
    protected lpsDataResourceService: ReIssuePortfolioLpsDataResourceService,
  ) {
  }

  getQuotation(state: T): Observable<T> {

    return this.quotationResourceService.putQuotation(state.resourceId).pipe(
      mergeMap(quotation => {
        state.quotation = quotation;
        state.proposalStatus = quotation.status;
        state.beneficiariesProduct = quotation.beneficiariesProduct;
        state.personAsset = this.issueAssetUtilityService.getPersonAsset(quotation.assetList.assets);
        state.allAssets = quotation.assetList.assets;
        // state.assetInstances.forEach(assetinstance => {
        //   assetinstance.asset.factors.forEach(factor => {
        //     factor.open = true;
        //     factor.unitList =
        //       this.getQuotationUnit(quotation.assetList.assets, assetinstance.asset.key, factor.code);
        //   });
        // });
        state.listBeneficiariesUnit = [];
        state.allAssets.forEach(asset => {
          asset.instances.forEach(instance => {
            instance.sections.forEach(section => {
              section.unitList.forEach((unit, index) => {
                if (unit.beneficiaries && unit.beneficiaries.length > 0) {
                  if (unit.selected) {
                    const beneficiaries = new Beneficiaries();
                    beneficiaries.section = section.description;
                    beneficiaries.unit = unit.description;
                    beneficiaries.unitCode = unit.code;
                    beneficiaries.assetKey = instance.assetCode;
                    beneficiaries.beneficiary = unit.beneficiaries;
                    state.listBeneficiariesUnit.push(beneficiaries);
                    state.isBeneficiaryUnitVisibility = true;
                  }
                }
              });
            });
          });
        });
        state.quotationReady = true;
        return combineLatest(
          this.getAssetsWithSections(state.resourceId),
          this.portfolioProposalService.getProposal(state.resourceId),
          this.variablesResourceService.get(state.resourceId),
          this.portfolioContactResourceService.getPolicyContact(state.resourceId)
        );
      }),
      mergeMap(([assets, proposal, variables, contact]: any) => {
        state.beneficiarySelected = new Array<Beneficiary>();
        proposal.assets.forEach(asset => {
          asset.roles.forEach(role => {
            if (role.type.code === '100') {
              const beneficiary = new Beneficiary();
              beneficiary.beneficiaryType = role.beneficiaryType.code;
              beneficiary.percentage = role.quota;
              beneficiary.subject = role.subject;
              state.beneficiarySelected.push(beneficiary);
            }
          });
          if (state.beneficiarySelected.length === 0 && asset.beneficiaryType) {
            const beneficiary = new Beneficiary();
            beneficiary.beneficiaryType = asset.beneficiaryType.code;
            state.beneficiarySelected.push(beneficiary);
          }
        });
        if (assets && assets.length) {
          state.assetInstances = [];
          assets.forEach((insuredAsset, i) => {
            const assetInstance = new PcAssetInstance();
            assetInstance.asset = this.refactorService.insuredAssetToPcAsset(insuredAsset);
            const asset = state.allAssets.find(val => val.assetId === assetInstance.asset.key);
            assetInstance.asset.name = asset.name;
            assetInstance.sections = insuredAsset.sections;
            state.assetInstances.push(assetInstance);
          });
        }
        let packages: Package[];
        state.assetInstances.forEach(assetInstance => {
          const asset = assets.find(result => result.key === assetInstance.asset.key);
          assetInstance.asset.factors = this.refactorService.pcSectionsToFactors(asset.sections);
          assetInstance.asset.factors.forEach(factor => {
            factor.open = true;
            factor.unitList =
              this.getQuotationUnit(state.quotation.assetList.assets, assetInstance.asset.key, factor.code);
          });
          assetInstance.asset.package = asset.packages;
          assetInstance.sync = asset.sync;
          state.sync = asset.sync;
          assets.forEach(element => {
            if (!packages) {
              if (element.packages) {
                packages = JSON.parse(JSON.stringify(element.packages));
              }
            }
          });
        });
        state.packages = packages;

        state.proposal = proposal;
        state.authorize = proposal.authorize;

        state.contact = contact;

        state.variables = [];
        variables.forEach((variable: any) => {
          if (this.isQuotationVariable(variable)) {
            state.variables.push(variable);
          }
        });
        return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.POST_QUOTATION_WARNING_LEVEL);
      }),
      map(warnings => {
        state.warnings = this.getBlockingWarnings(warnings);
        state.nonBlockingWarnings = this.getNonBlockingWarnings(warnings);
        return state;
      }),
      switchMap(() => {
        return this.quotationResourceService.getAllClauses(state.resourceId).pipe(
          map(clauses => {
            state.allClauses = clauses;
            return state;
          })
        );
      })
  );
  }

  updateProductVariables$(
    state: T,
    variable: any
  ): Observable<T> {
    state.variables.forEach(stateVariable => {
      if (stateVariable.code === variable.code) {
        stateVariable.value = variable.value;
      }
    });

    return this.portfolioVariablesResourceService.putVariables(
      state.resourceId,
      this.refactorService.toPcVariablesRefactor(state.variables)
    ).pipe(
      map(variables => {
        // state.variables = variables;
        state.variables = [];
        variables.forEach((internalVariable: any) => {
          if (this.isQuotationVariable(internalVariable)) {
            state.variables.push(internalVariable);
          }
        });
        return state;
      })
    );
  }

  postWarnings(state: T) {
    return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.POST_QUOTATION_WARNING_LEVEL).pipe(
      map(warnings => {
        state.warnings = this.getBlockingWarnings(warnings);
        state.nonBlockingWarnings = this.getNonBlockingWarnings(warnings);
        return state;
      })
    );
  }

  isQuotationVariable(variable: any) {
    let response = false;
    const QUOTATION_VARIABLE_KEY = 'FATQUO';

    if (variable.properties) {
      variable.properties.forEach(property => {
        if (property.key === QUOTATION_VARIABLE_KEY && property.value) {
          response = true;
        }
      });
    }

    return response;
  }

  // putQuestionnaire(state: T, questionnaireFlat: QuestionnaireFlatI): Observable<T> {
  //   const questionnaireCode = state.isPersonaGiuridica ? '000002' : '000001';
  //   const questionnaireFlatIResponse: QuestionnaireFlatIResponse = this.questionnaireUtilityService.convertToQuestionnaireFlatIResponse(
  //     questionnaireFlat
  //   );
  //   return this.quotationResourceService.putQuestionnaire(state.resourceId, questionnaireFlatIResponse, questionnaireCode).pipe(
  //     map(questionnaire => {
  //       state.questionnaireCommitted = true;
  //       const questionnaireFlatI: QuestionnaireFlatI = this.questionnaireUtilityService.convertToQuestionnaireFlatI(
  //         questionnaire
  //       );
  //       state.questionnaire = questionnaireFlatI;
  //       return state;
  //     })
  //   );
  // }

  getAssetClauses(st: T) {
    return this.quotationResourceService.getClause(st.resourceId).pipe(
      map(clauses => {
        st.clause = clauses;
        return clauses;
      }));
  }

  putAssetClauses(clause: Clause, asset: PcAsset, state: T) {
    return this.quotationResourceService.putAssetClauses(state.resourceId, this.setClauseInAsset(clause, asset, state.clausesFromAsset)).pipe(
      map(res => {

        state.assetInstances.find(val => {
          return val.asset.key === asset.key;
        }).asset.clauses = res.clauses;

        return state;
      })
    );
  }

  putClauses(clause: Array<PcClause>, state: T) {
    return this.quotationResourceService.putClauses(state.resourceId, clause).pipe(
      map(clausesResponse => {
        state.clause = clausesResponse;
        return state;
      })
    );
  }

  setClauseInAsset(clause: Clause, asset: PcAsset, clausesFromAsset: PcAssetInstance[]): Clause[] {
    asset.clauses.forEach(internalClause => {
      if (internalClause.code === clause.code) {
        internalClause.selected = clause.selected;
      }
    });
    const c = clausesFromAsset.find(assetInstance => {
      return assetInstance.asset.key === asset.key;
    });

    return [...asset.clauses, ...c.asset.clauses];
  }

  // convertToQuestionnaireFlatIResponse(questionnaire: QuestionnaireFlatI): QuestionnaireFlatIResponse {
  //   const qst: QuestionnaireFlatIResponse = new QuestionnaireFlatIResponse();
  //   qst.category = questionnaire.category;
  //   qst.code = questionnaire.code;
  //   qst.codeType = questionnaire.codeType;
  //   qst.codeVersion = questionnaire.codeVersion;
  //   qst.compulsory = questionnaire.compulsory;
  //   qst.description = questionnaire.description;
  //   qst.descriptionType = questionnaire.descriptionType;
  //   qst.included = questionnaire.included;
  //   qst.openDate = questionnaire.openDate;
  //   if (questionnaire.questions) {
  //     qst.questionList = this.getQuestionsResponse(questionnaire.questions);
  //   }
  //   qst.score = questionnaire.score;

  //   return qst;
  // }

  // getQuestionsResponse(questions: QuestionFlatI[]): QuestionFlatIResponse[] {
  //   const questionList: Array<QuestionFlatIResponse> = new Array<QuestionFlatIResponse>();
  //   questions.forEach(question => {
  //     const qs: QuestionFlatIResponse = new QuestionFlatIResponse();

  //     qs.answerList = this.getAnswersResponse(question.answers);
  //     qs.code = question.code;
  //     qs.compulsory = question.compulsory;
  //     if (question.conditions) {
  //       qs.conditionList = this.getConditionsResponse(question.conditions);
  //     }
  //     qs.description = question.description;
  //     qs.hasNotes = question.hasNotes;
  //     qs.id = question.id;
  //     qs.order = question.order;
  //     qs.section = question.section;
  //     qs.text = question.text;
  //     qs.visible = question.visible;
  //     questionList.push(qs);
  //   });
  //   return questionList;
  // }

  // getConditionsResponse(conditions: ConditionFlatI[]): ConditionFlatIResponse[] {
  //   const conditionList: Array<ConditionFlatIResponse> = new Array<ConditionFlatIResponse>();
  //   conditions.forEach(condition => {
  //     const cond: ConditionFlatIResponse = new ConditionFlatIResponse();
  //     cond.status = condition.status;
  //     cond.termList = condition.terms;
  //     conditionList.push(cond);
  //   });
  //   return conditionList;
  // }

  // getAnswersResponse(answers: AnswerFlatI[]): AnswerFlatIResponse[] {
  //   const answerList: Array<AnswerFlatIResponse> = new Array<AnswerFlatIResponse>();
  //   answers.forEach(answer => {
  //     const asw: AnswerFlatIResponse = new AnswerFlatIResponse();
  //     asw.defaultValue = answer.defaultValue;
  //     asw.factorCode = answer.factorCode;
  //     asw.factorDescr = answer.factorDescr;
  //     asw.factorFormat = answer.factorFormat;
  //     asw.factorType = answer.factorType;
  //     asw.formatter = answer.formatter;
  //     asw.notes = answer.notes;
  //     asw.paramType = answer.paramType;
  //     asw.value = answer.value;
  //     asw.valueList = answer.values;
  //     answerList.push(asw);
  //   });
  //   return answerList;
  // }

  // convertToQuestionnaireFlatI(
  //   questionnaire: QuestionnaireFlatIResponse,
  //   state: T
  // ) {
  //   const qst: QuestionnaireFlatI = new QuestionnaireFlatI();

  //   qst.category = questionnaire.category;
  //   qst.code = questionnaire.code;
  //   qst.codeType = questionnaire.codeType;
  //   qst.codeVersion = questionnaire.codeVersion;
  //   qst.compulsory = questionnaire.compulsory;
  //   qst.description = questionnaire.description;
  //   qst.descriptionType = questionnaire.descriptionType;
  //   qst.included = questionnaire.included;
  //   qst.openDate = questionnaire.openDate;
  //   if (questionnaire.questionList) {
  //     qst.questions = this.getQuestions(
  //       questionnaire.questionList,
  //       state
  //     );
  //   }
  //   qst.score = questionnaire.score;

  //   return qst;
  // }

  // getQuestions(
  //   questionList: QuestionFlatIResponse[],
  //   state: T
  // ): QuestionFlatI[] {
  //   const questions: Array<QuestionFlatI> = new Array<QuestionFlatI>();

  //   questionList.forEach(question => {
  //     const qs: QuestionFlatI = new QuestionFlatI();
  //     if (question.answerList.length > 0) {
  //       qs.status = question.status;
  //     } else {
  //       qs.status = 2;
  //     }
  //     qs.answers = this.getAnswers(
  //       question.answerList,
  //       state
  //     );
  //     qs.code = question.code;
  //     qs.compulsory = question.compulsory;
  //     if (question.conditionList) {
  //       qs.conditions = this.getConditions(question.conditionList);
  //     }
  //     qs.description = question.description;
  //     qs.hasNotes = question.hasNotes;
  //     qs.id = question.id;
  //     qs.order = question.order;
  //     qs.section = question.section;
  //     qs.text = question.text;
  //     qs.visible = question.visible;
  //     questions.push(qs);
  //   });
  //   return questions;
  // }

  // getConditions(conditionList: ConditionFlatIResponse[]): ConditionFlatI[] {
  //   const conditions: Array<ConditionFlatI> = new Array<ConditionFlatI>();
  //   conditionList.forEach(condition => {
  //     const cond: ConditionFlatI = new ConditionFlatI();
  //     cond.status = condition.status;
  //     cond.terms = condition.termList;
  //     conditions.push(cond);
  //   });
  //   return conditions;
  // }

  // getAnswers(
  //   answerList: AnswerFlatIResponse[],
  //   state: T
  // ): AnswerFlatI[] {
  //   const answers: Array<AnswerFlatI> = new Array<AnswerFlatI>();

  //   answerList.forEach(answer => {
  //     const asw: AnswerFlatI = new AnswerFlatI();
  //     asw.defaultValue = answer.defaultValue;
  //     asw.factorCode = answer.factorCode;
  //     asw.factorDescr = answer.factorDescr;
  //     asw.factorFormat = answer.factorFormat;
  //     asw.factorType = answer.factorType;
  //     asw.formatter = answer.formatter;
  //     asw.notes = answer.notes;
  //     asw.paramType = answer.paramType;
  //     if (answer.value === '-1') {
  //       if (asw.defaultValue === '-1') {
  //         asw.value = null;
  //       } else {
  //         asw.value = answer.defaultValue;
  //       }
  //     } else {
  //       asw.value = answer.value;
  //     }

  //     asw.values = answer.valueList;
  //     answers.push(asw);
  //   });
  //   return answers;
  // }

  recalculatePut$(state: T): Observable<T> {
    return this.quotationResourceService.putQuotation(state.resourceId).pipe(
      mergeMap(quotation => {
        state.quotation = quotation;
        state.proposalStatus = quotation.status;
        state.personAsset = this.issueAssetUtilityService.getPersonAsset(quotation.assetList.assets);
        state.allAssets = quotation.assetList.assets;
        state.assetInstances.forEach(assetinstance => {
          assetinstance.asset.factors.forEach(factor => {
            factor.unitList =
              this.getQuotationUnit(quotation.assetList.assets, assetinstance.asset.key, factor.code);
          });
        });
        state.quotationReady = true;
        //   return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.WARNING_LEVEL);
        // }),
        // map(warnings => {
        //   state.warnings = warnings;
        //   return state;
        // })
        // return this.getAssetsWithSections(state.resourceId).pipe(
        //   mergeMap(assets => {
        //     if (assets && assets.length) {
        //       state.assetInstances = [];
        //       assets.forEach(insuredAsset => {
        //         const assetInstance = new PcAssetInstance();
        //         assetInstance.asset = this.refactorService.insuredAssetToPcAsset(insuredAsset);

        //         state.assetInstances.push(assetInstance);
        //       });
        //     }
        //     let packages: Package[];
        //     state.assetInstances.forEach(assetInstance => {
        //       const asset = assets.find(result => result.key === assetInstance.asset.key);
        //       assetInstance.asset.factors = this.refactorService.pcSectionsToFactors(asset.sections);
        //       assetInstance.asset.package = asset.packages;
        //       assetInstance.sync = asset.sync;
        //       state.sync = asset.sync;
        //       assets.forEach(element => {
        //         if (!packages) {
        //           packages = JSON.parse(JSON.stringify(element.packages));
        //         }
        //       });
        //     });
        //     state.packages = packages;
        return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.POST_QUOTATION_WARNING_LEVEL).pipe(
          map(warnings => {
            state.warnings = this.getBlockingWarnings(warnings);
            state.nonBlockingWarnings = this.getNonBlockingWarnings(warnings);
            return state;
          })
        );
      })
      // )
      // })
    );

    // return this.quotationResourceService.putQuotation(state.resourceId).pipe(
    //   mergeMap(quotation => {
    //     state.quotation = quotation;
    //     state.proposalStatus = quotation.status;
    //     state.personAsset = this.issueAssetUtilityService.getPersonAsset(quotation.assetList.assets);
    //     state.allAssets = quotation.assetList.assets;
    //     state.quotationReady = true;
    //     return state;
    //   }));
  }

  getLpsData$(state: T): Observable<T> {
    return this.lpsDataResourceService.getLpsData(state.resourceId).pipe(
      map((response: LpsData) => {
        state.lpsData = response;
        return state;
      })
    );
  }

  putLpsData$(state: T, lpsData: LpsData): Observable<T> {
    return this.lpsDataResourceService.putLpsData(state.resourceId, lpsData).pipe(
      map((response: LpsData) => {
        state.lpsData = response;
        return state;
      })
    );
  }

  getNonBlockingWarnings(warnings: any): Array<Warning> {
    const nonBlockingWarnings = new Array<Warning>();

    warnings.forEach((warning: Warning) => {
      if (warning.authorizationMessage || warning.warningMessage) {
        nonBlockingWarnings.push(warning);
      }
    });

    return nonBlockingWarnings;
  }

  getBlockingWarnings(warnings: any): Array<Warning> {
    const blockingWarnings = new Array<Warning>();

    warnings.forEach((warning: Warning) => {
      if (warning.blockingMessage) {
        blockingWarnings.push(warning);
      }
    });

    return blockingWarnings;
  }

  policyTechnicalDataPut$(state: T): Observable<PolicyTechnicalData> {
    return this.quotationResourceService.policyTechnicalDataPut$(state.policyTechnicalData, state.resourceId);
  }

  // isToEmptyQuestionnaire(state: T) {
  //   return state.editMode && !state.questionnaireCommitted;
  // }

  isToEmptyClauses(state: T) {
    return state.editMode && !state.clauseCommitted;
  }

  paymentFrequencyPut$(state: T, paymentFrequencySelected: PaymentFrequency) {
    return this.paymentFrequencyResourceService.putPaymentFrequency(
      state.resourceId,
      this.paymentFrequencyRefactor(paymentFrequencySelected)
    ).pipe(mergeMap(res => {
      return this.portfolioProposalService.getProposal(state.resourceId).pipe(
        map(proposal => {
          const selectedInstalmentDate = proposal.instalmentDates[proposal.instalmentDates.length - 1];
          state.policyTechnicalData.selectedInstalmentDate = selectedInstalmentDate;
          this.instalmentResourceService.putInstalmentDate(state.resourceId, selectedInstalmentDate).subscribe();
          state.proposal = proposal;
          return state;
        })
      );
    }));
  }

  putInstalmentDate(state: T, instalmentDateSelected: string) {
    return this.instalmentResourceService.putInstalmentDate(state.resourceId, instalmentDateSelected).pipe(
      map((instalmentDate: PcInstalmentDate) => {
        state.policyTechnicalData.selectedInstalmentDate = instalmentDateSelected;
        state = this.updateInstalmentDateState(state, instalmentDate);
        return state;
      })
    );
  }

  updateInstalmentDateState(state: T, instalmentDate: PcInstalmentDate): T {
    state.variables.forEach(stateVariable => {
      if (stateVariable.code === instalmentDate.instalmentDate) {
        stateVariable.value = instalmentDate.instalmentDate;
      }
    });
    return state;
  }

  paymentFrequencyRefactor(paymentFrequencySelected: PaymentFrequency): PcPaymentFrequency {
    const pcPaymentFrequency: PcPaymentFrequency = new PcPaymentFrequency();

    pcPaymentFrequency.code = paymentFrequencySelected.code;
    pcPaymentFrequency.description = paymentFrequencySelected.description;

    return pcPaymentFrequency;
  }

  getUnits(proposalid: string, assetKey: string, sectionID: string): Observable<PcUnit[]> {
    return this.quotationResourceService.getUnit(proposalid, assetKey, sectionID).pipe(
      share(),
      map((output) => {
        return output;
      }));
  }

  putUnitSelected(proposalid: string, assetKey: string, sectionId: string, unitSelected: PcUnit): Observable<PcUnit[]> {
    return this.quotationResourceService.putUnitSelected(proposalid, assetKey, sectionId, unitSelected);
  }

  getSectionProduct(proposalid: string, assetKey: string): Observable<PcInsuredAsset> {
    return this.quotationResourceService.getSectionProduct(proposalid, assetKey).pipe(
      share(),
      map((output) => {
        return output;
      }));
  }

  getAssetsWithSections(proposalid: string): Observable<PcInsuredAsset[]> {
    return this.quotationResourceService.getAssetsWithSections(proposalid).pipe(
      share()
    );
  }

  putPackege(proposalid: string, assetKey: string, packages: Package[]): Observable<Package[]> {
    return this.quotationResourceService.putPackets(proposalid, assetKey, packages);
  }

  putBeneficiariesByUnit(proposalid: string, beneficiary) {
    return this.quotationResourceService.putBeneficiariesByUnit(proposalid, beneficiary);
  }

  putInputPremium(state: T, assetInstanceKey: string, sectionCode: string, inputPremium: InputPremium) {
    return this.quotationResourceService.putInputPremium(state.resourceId, inputPremium).pipe(
      share(),
      map(res => {

        const unit = state.quotation.assetList.assets.find(asset => asset.assetId === assetInstanceKey)
          .instances.find(instance => instance.assetCode === assetInstanceKey)
          .sections.find(section => section.code === sectionCode)
          .unitList.find(unit => unit.code === inputPremium.unitCode);

        unit.inputPremium = res.inputPremium;

        return state;
      })
    );
  }

  getQuotationUnit(assets: Asset[], assetkey: string, sectionCode: string): Unit[] {
    let unitList = null;
    assets.forEach(asset => {
      if (asset.assetId === assetkey) {
        if (asset.instances[0].sections && asset.instances[0].sections.length) {
          asset.instances[0].sections.forEach(section => {
            if (section.code === sectionCode) {
              unitList = section.unitList;
            }
          });
        }
      }
    });
    return unitList;
  }

  updateContact$(
    state: T,
    variable: any
  ): Observable<T> {
    return this.portfolioContactResourceService.putPolicyContact$(state.resourceId, variable.mainAddress, variable.mail, variable.channelType)
    .pipe(
      map(contact => {
        state.contact = contact;
        return state;
      }));
  }

  getProposalCommissions(proposalId: string): Observable<CommissionsWrap> {
    return this.quotationResourceService.getProposalCommissions(proposalId);

  }

  putProposalCommissions(proposalId: string, inputCommissions: CommissionsWrap, tag: string): Observable<GenericEntityPcPortfolio> {
    return this.quotationResourceService.putProposalCommissions(proposalId, inputCommissions, tag);
  }

  putUnitClauses(state: T, assetKey: string, sectionCode: string, unitCode: string, clauses: Clause[]) {
    return this.quotationResourceService.putUnitClauses(state.resourceId, assetKey, unitCode, clauses).pipe(
      share(),
      map(res => {
        const unit = state.allAssets.find(asset => asset.assetId === assetKey)
        .instances.find(instance => instance.assetCode === assetKey)
        .sections.find(section => section.code === sectionCode)
        .unitList.find(unit => unit.code === unitCode);
        unit.clauseUnit = res;

        return state;
      })
    );
  }

  putNotes$(state: T, notes: Notes): Observable<T> {
    return this.quotationResourceService.putNotes(state.resourceId, notes).pipe(
      map((response: Notes) => {
        state.quotation.notes = response;
        return state;
      })
    );
  }

  putBeneficiariesByAssetAndByUnit(proposalid: string, assetKey: string, unitCode: string, beneficiary) {
    return this.quotationResourceService.putBeneficiariesByAssetAndByUnit(proposalid, assetKey, unitCode, beneficiary);
  }

  putThirdContactPerson(resourceId: string, emitter: {
    idLatestPhotosThirdContactPerson: any
  }) {
    return this.quotationResourceService.putThirdContactPerson(resourceId, emitter);
  }

  getBeneficiariesByAssetAndByUnit(proposalid: string, assetKey: string, unitCode: string) {
    return this.quotationResourceService.getBeneficiariesByAssetAndByUnit(proposalid, assetKey, unitCode).pipe(
      share());
  }

  deleteBeneficiariesByAssetAndByUnit(proposalid: string, assetKey: string, unitCode: string) {
    return this.quotationResourceService.deleteBeneficiariesByAssetAndByUnit(proposalid, assetKey, unitCode);
  }

  deleteThirdContactPerson(resourceId: string) {
    return this.quotationResourceService.deleteThirdContactPerson(resourceId);
  }

  putFlexDiscountOnTotal(proposalId: string, flexDiscount: FlexDiscountParam): Observable<GenericEntityPcPortfolio> {
    return this.quotationResourceService.putFlexDiscountOnTotal(proposalId, flexDiscount);
  }

  putFlexDiscountOnSingleUnits(proposalId: string, flexDiscountUnits: FlexDiscountUnit[]): Observable<GenericEntityPcPortfolio> {
    return this.quotationResourceService.putFlexDiscountOnSingleUnits(proposalId, flexDiscountUnits);
  }

  getDiscountFund(proposalId: string): Observable<DiscountFund[]> {
    return this.quotationResourceService.getDiscountFund(proposalId);
  }

}

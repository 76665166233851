import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { ModalComponent, ModalService } from '@rgi/rx/ui';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import {
  GpConfirmationEvent
} from '../../group-policy-components/group-policy-modals/group-policy-modal-confirmation/gp-confirmation-modal.component';
import {
  GroupPolicyModalManualPolicyNumberComponent
} from '../../group-policy-components/group-policy-modals/group-policy-modal-manual-policy-number/group-policy-modal-manual-policy-number-component';
import { FUNCTION_STATUS } from "../../group-policy-constants/general";
import { ROUTES_GP_GUARANTEES, ROUTES_GP_ISSUE_CONFIRM } from '../../group-policy-constants/routes-constants';
import { GO_TO_INQUIRY_BTN } from '../../group-policy-models/gp-modals';
import { IssuePolicyData } from '../../group-policy-models/group-policy-issue-confirm';
import { LinkedPolicyQuotation } from '../../group-policy-models/group-policy-issue-guarantees';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import {
  ErrorCode,
  ErrorResp,
  MeanOfPayment,
  PaymentConfig,
  PaymentsPayload,
  ResultMessage,
  SaveResp,
  paymentPages
} from '../../group-policy-models/group-policy-issue-policy-data';
import { ButtonsAction, Summary } from '../../group-policy-models/group-policy-issue-summary';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GpRecoverApplicationsService } from '../../group-policy-services/gp-recover-applications.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyAuthorizationService } from '../../group-policy-services/group-policy-authorization.service';
import { GroupPolicyCrossService } from '../../group-policy-services/group-policy-cross.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import { GroupPolicyPaymentService } from '../../group-policy-services/group-policy-payment.service';
import { GroupPolicyStateSummary } from '../group-policy-state';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpSummaryService {

  public authorizationModal: ModalComponent;

  constructor(protected groupPolicyService: GroupPolicyResourceService,
              protected routingService: RoutingService,
              protected integrationService: GroupPolicyIntegrationService,
              protected gpErrorService: GroupPolicyApiRestErrorService,
              protected paymentService: GroupPolicyPaymentService,
              protected crossService: GroupPolicyCrossService,
              protected recoverApplicationService: GpRecoverApplicationsService,
              protected authorizationService: GroupPolicyAuthorizationService,
              protected modalService: ModalService) {
  }

  public initSummaryData$(groupPolicyStateSummary$: Observable<GroupPolicyStateSummary>,
                          activeRoute: ActiveRoute): Observable<GroupPolicyStateSummary> {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    return groupPolicyStateSummary$.pipe(
      concatMap((st: GroupPolicyStateSummary) => {
        return this.groupPolicyService.checkDerogations$(previousStepData.resId).pipe(
          map(_resp => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'DEROGATIONS'))
        );
      }),
      mergeMap((st: GroupPolicyStateSummary) => {
        return combineLatest(of(st),
          this.groupPolicyService.getNotifications$(previousStepData.resId),
          this.groupPolicyService.retrieveSummary$(previousStepData.resId),
          this.groupPolicyService.retrieveActions$(previousStepData.resId, previousStepData.operationCode),
          this.groupPolicyService.retrieveLinkedPolicyQuotation$(previousStepData.resId),
          this.paymentService.getEditablePaymentConfigs$(previousStepData.resId, paymentPages.postIssue, st),
          this.groupPolicyService.getAvailablePayments$(previousStepData.resId),
          this.groupPolicyService.manualPolicyNumber$(previousStepData.resId)
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map(([st, notifications, summary, actions, quotations, paymentConfig, availablePayments, manualPolicyNumber]:
             [GroupPolicyStateSummary, ErrorResp[], Summary, ButtonsAction[], LinkedPolicyQuotation, PaymentConfig, MeanOfPayment[], ResultMessage]) => {
        st.summary = summary;
        st.proposalNumber = null;
        st.summaryActions = actions;
        st.quotations = quotations;
        st.errors = !!previousStepData.errors ? previousStepData.errors.concat(notifications) : notifications;
        st.type = previousStepData.type;
        st.editablePayments = paymentConfig;
        st.selectablePayments = availablePayments;
        st.isManualPolicyNumEnabled = FUNCTION_STATUS.ENABLED === manualPolicyNumber.message;
        return st;
      })
    );
  }

  public actionBack$(groupPolicyStateSummary$: Observable<GroupPolicyStateSummary>, previousStepData: DataForSteps,
                     activeRoute: ActiveRoute, targetRoute = ROUTES_GP_GUARANTEES): Observable<GroupPolicyStateSummary> {
    return groupPolicyStateSummary$.pipe(
      tap(() => {
        previousStepData.errors = [];
        previousStepData.type = ErrorCode.BLOCKING;
        if (!previousStepData.isFromInquiry) {
          this.integrationService.navigate(this.routingService, targetRoute, previousStepData, activeRoute);
        } else {
          this.integrationService.backToInquiry(previousStepData.inquiryProposalNumber, activeRoute.id, previousStepData.idParentSession);
        }
      })
    );
  }

  public actionSave$(groupPolicyStateSummary$: Observable<GroupPolicyStateSummary>,
                     uuid: string, nodeCode: string, activeRoute: ActiveRoute): Observable<GroupPolicyStateSummary> {
    return groupPolicyStateSummary$.pipe(
      mergeMap((state: GroupPolicyStateSummary) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_ACTION');
        return this.groupPolicyService.saveAction$(uuid, nodeCode).pipe(
          map((saveResp: SaveResp) => {
            state.proposalNumber = saveResp.proposalNumber;
            this.crossService.showModalContractNumber(saveResp, activeRoute.id).subscribe();
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_ACTION'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  public actionProposalSave$(groupPolicyStateSummary$: Observable<GroupPolicyStateSummary>,
                             uuid: string, prevStepData: DataForSteps, activeRoute: ActiveRoute,
                             operationCode?: string): Observable<GroupPolicyStateSummary> {
    return groupPolicyStateSummary$.pipe(
      concatMap(this.retriveveDocsOnStateFn$(uuid, operationCode)),
      concatMap(this.updateDocsFn$(uuid, operationCode)),
      concatMap((state: GroupPolicyStateSummary) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_SAVE');
        return this.groupPolicyService.saveProposalActions$(uuid, prevStepData.node).pipe(
          map(saveResp => {
            state.proposalNumber = saveResp.proposalNumber;
            prevStepData.issuedContractData = {} as IssuePolicyData;
            prevStepData.issuedContractData.proposalNumber = saveResp.proposalNumber;
            if (!operationCode) {
              this.crossService.showModalContractNumber(saveResp, activeRoute.id).subscribe();
            } else {
              this.integrationService.navigate(this.routingService, ROUTES_GP_ISSUE_CONFIRM, prevStepData, activeRoute);
            }
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_SAVE'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  public actionSaveDraftsDocuments$(groupPolicyStateSummary$: Observable<GroupPolicyStateSummary>,
                                    uuid: string, nodeCode: string, payload, activeRoute: ActiveRoute): Observable<GroupPolicyStateSummary> {
    return groupPolicyStateSummary$.pipe(
      concatMap(this.retriveveDocsOnStateFn$(uuid)),
      concatMap(this.updateDocsFn$(uuid)),
      concatMap((state: GroupPolicyStateSummary) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_SAVE');
        return this.groupPolicyService.saveContractDrafts$(uuid, nodeCode, payload).pipe(
          map(saveResp => {
            state.proposalNumber = saveResp.proposalNumber;
            this.crossService.showModalContractNumber(saveResp, activeRoute.id).subscribe();
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_SAVE'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  public setPaymentMethod$(state: Observable<GroupPolicyStateSummary>, paymentMethod: PaymentsPayload, data: DataForSteps) {
    const payload = [paymentMethod];
    return state.pipe(
      concatMap((st: GroupPolicyStateSummary) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_PAYMETH_SET');
        return this.groupPolicyService.setPayments$(data.resId, payload).pipe(
          map((_resp) => st),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_PAYMETH_SET'))
        );
      }),
      concatMap((st: GroupPolicyStateSummary) => {
        return this.getAvailablePayments$(of(st), data);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  public getAvailablePayments$(st$: Observable<GroupPolicyStateSummary>, data: DataForSteps): Observable<GroupPolicyStateSummary> {
    return st$.pipe(
      concatMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_AVAILABLE_PAYM');
        return this.groupPolicyService.getAvailablePayments$(data.resId).pipe(
          map((availablePayments: MeanOfPayment[]) => {
            st.selectablePayments = availablePayments;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_AVAILABLE_PAYM'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  public savePolicy$(st$: Observable<GroupPolicyStateSummary>, prevStepData: DataForSteps,
                     activeRoute: ActiveRoute): Observable<GroupPolicyStateSummary> {
    const tag = 'ISSUE_SUMMARY';
    this.gpErrorService.cleanErrorsHandlerForTag(tag);
    return st$.pipe(
      concatMap((st: GroupPolicyStateSummary) => {
        const isVariation =  st.summary.summaryAdministrativeData.status.code === "30";
        const isContractStatusUndefined: boolean = this.crossService.isContractStatusUndefined(prevStepData);
        if(st.isManualPolicyNumEnabled && (isContractStatusUndefined || !isVariation)) {
          const manualPolicyNumModal: ModalComponent = this.modalService.open(GroupPolicyModalManualPolicyNumberComponent, st.policyNumber);
          manualPolicyNumModal.enableClickBackground = false;
          return combineLatest(of(st), manualPolicyNumModal.onClose.pipe(
            concatMap((onCloseData: string | GpConfirmationEvent) => {
              if (onCloseData !== GpConfirmationEvent.CLOSE) {
                st.policyNumber = onCloseData;
                return this.crossService.evaluateOpenModalCheckApplications(st.summary.applicationsToAuthorize);
              } else {
                return of(GpConfirmationEvent.CLOSE);
              }
            }), map((canProceed: GpConfirmationEvent) => canProceed)
          ));
        }
        return combineLatest(of(st), this.crossService.evaluateOpenModalCheckApplications(st.summary.applicationsToAuthorize));

      }),
      concatMap(([st, modalConfirmationEvent]: [GroupPolicyStateSummary, GpConfirmationEvent]) => {
        if (!modalConfirmationEvent || modalConfirmationEvent === GpConfirmationEvent.CONFIRM) {
              return this.groupPolicyService.savePolicy$(prevStepData.resId, st.policyNumber).pipe(
                map((savePolicyResp: IssuePolicyData) => {
                  prevStepData.issuedContractData = savePolicyResp;
                  this.integrationService.navigate(this.routingService, ROUTES_GP_ISSUE_CONFIRM, prevStepData, activeRoute);
                  return st;
                }),
                catchError(this.gpErrorService.catchApiErrorHandlerFn(st, tag))
        );
        }
        return of(st);
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  protected updateDocsFn$(uuid: string, operationCode?: string) {
    return (state: GroupPolicyStateSummary) => {
      state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_DOCS_UPD');
      const filteredDocs = state.documents.filter(doc => !doc.input);
      return this.groupPolicyService.saveDocuments$(uuid, filteredDocs, operationCode).pipe(
        map((_resp) => state),
        catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_DOCS_UPD'))
      );
    };
  }

  protected retriveveDocsOnStateFn$(uuid: string, operationCode?: string) {
    return (state: GroupPolicyStateSummary) => {
      state.documents = [];
      state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_DOCS_GET');
      return this.groupPolicyService.retrieveDocuments$(uuid, operationCode).pipe(
        map(documentsResp => {
          state.documents = documentsResp;
          return state;
        }),
        catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_DOCS_GET'))
      );
    };
  }

  getAuthorization$(state$: Observable<GroupPolicyStateSummary>, previousStepData: DataForSteps, activeRoute: ActiveRoute) {
    return this.authorizationService.getAuthorization$(state$, previousStepData, activeRoute);
  }

  alignApplications(state$: Observable<GroupPolicyStateSummary>, prevStepData: DataForSteps, activeRoute: ActiveRoute) {
    return state$.pipe(
      concatMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'ALIGN_APPLICATIONS');
        return this.groupPolicyService.alignApplicationOfContractDraft$(prevStepData.resId).pipe(
          map((_resp) => {
            const buttons = [GO_TO_INQUIRY_BTN];
            this.crossService.openBaseModal(_resp[0].errorDescription, undefined, undefined, buttons)
              .subscribe((onCloseAction: string) => {
                if (!onCloseAction) {
                  this.integrationService.closeSession(activeRoute.id);
                } else {
                  this.crossService.manageGenericModalOnClose(onCloseAction,
                    st.summary.summaryAdministrativeData.proposalNumber, activeRoute.id);
                }
              });
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'ALIGN_APPLICATIONS'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateSummary) => st)
    );
  }

  recoverApplications$(st$: Observable<GroupPolicyStateSummary>, previousStepData: DataForSteps,
                       activeRoute: ActiveRoute): Observable<GroupPolicyStateSummary> {
    return st$.pipe(
      concatMap((st: GroupPolicyStateSummary) => {
        return this.groupPolicyService.saveAction$(previousStepData.resId, previousStepData.node).pipe(
          map((saveResp: SaveResp) => {
            st.proposalNumber = saveResp.proposalNumber;
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_ACTION'))
        );
      }),
      concatMap((st: GroupPolicyStateSummary) => {
        return this.recoverApplicationService.manageRecoverApplications$(st.proposalNumber, activeRoute.id).pipe(
          map(() => {
            return st;
          })
        );
      }),
      map((st: GroupPolicyStateSummary) => st)
    );
  }
}

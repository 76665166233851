import {Component, Inject, OnInit} from '@angular/core';
import {RgiRxDataPaginator, TableSchema} from '@rgi/rx/ui';
import {DashboardCardState, DashboardManagerService} from '../state-manager/dashboard-manager.service';
import {DashboardNavigationData} from '../state-manager/dashboard-data-state';
import {ActiveRoute} from '@rgi/rx/router';
import {of} from 'rxjs';
import {COMMISSIONS_SCHEMA} from '../mock';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DashboardTotalRiskDetail} from '../model/dashboard-total-risk-detail';
import {DashboardOperation} from '../model/dashboard-operation';
import {DashboardUtilityService} from '../services/dashboard-utility.service';
import {PushMessageHandlerService} from '@rgi/rx';
import {AsyncDataSource} from '../services/async-datasource';
import {take, tap} from 'rxjs/operators';
import {FunctionalityEnum} from '../enumerations/functionality.enum';

@Component({
    selector: 'dashboard-detail',
    templateUrl: './dashboard-detail.component.html',
    styleUrls: ['./dashboard-detail.component.css']
})
export class DashboardDetailComponent implements OnInit {
    data: AsyncDataSource;
    schema: TableSchema;
    overview: any;
    dashBoardDetailForm: UntypedFormGroup;
    checkBoxSelected = [];
    START_PAGE = '1';
    tableIsVisible: boolean;
    totalAllRisk: DashboardTotalRiskDetail[] = [new DashboardTotalRiskDetail()];
    operation: DashboardOperation = new DashboardOperation();
    tablePageOptions: number[] = [10, 20, 50];

    constructor(public stateManager: DashboardManagerService<DashboardCardState>,
                public activeRoute: ActiveRoute,
                private formBuilder: UntypedFormBuilder,
                public utility: DashboardUtilityService,
                public pushMessageHandler: PushMessageHandlerService,
                @Inject('sessionService') private portalSession: any) {
    }

    ngOnInit() {
        this.tableIsVisible = false;
        this.overview = this.stateManager.activeRoute.getRouteData<DashboardNavigationData>();
        if (this.overview.functionality.id === FunctionalityEnum.EDIT) {
            this.dashBoardDetailForm = this.formBuilder.group({
                operationType: [, ]
            });
            this.dashBoardDetailForm.controls.operationType.disable();
        }
        // pipe to init data
        const state = this.stateManager.getCurrentState();
        this.stateManager.getCommissions(state, this.START_PAGE, String(this.tablePageOptions[0]))
            .pipe(
                tap(state1 => {
                    // Update state by child component
                    this.stateManager.updateState$(of(state1));
                    // Adjust table format data
                    const dataSource = this.utility.createData(this.data, state1);
                    this.data = new AsyncDataSource(dataSource, new RgiRxDataPaginator(), this.stateManager, this.utility);
                }),
                take(1)
            ).subscribe();

        this.stateManager.initAllDataDetail(state, true).subscribe(state1 => {
            // Update state by child component
            this.stateManager.updateState$(of(state1));
        });
        // Create table
        this.createTable();
    }

// Create table Schema
    createTable() {
        this.schema = COMMISSIONS_SCHEMA;
    }

    selectedCheck(event: any) {
       if (event.selected === true) {
           this.checkBoxSelected.push(event.idDashBoard);
           if (event.status === '2') {
               this.dashBoardDetailForm.controls.operationType.enable();
           }
           this.tableIsVisible = true;
       } else if (event.selected === false) {
           if (this.checkBoxSelected.length > 0) {
               this.checkBoxSelected.splice(this.checkBoxSelected.findIndex(x => x.idDashBoard === event.idDashBoard), 1);
           }
        } else if (event.reset) {
           this.checkBoxSelected = [];
       }
       if (this.checkBoxSelected.length === 0) {
           this.dashBoardDetailForm.controls.operationType.reset();
           this.dashBoardDetailForm.controls.operationType.disable();
           this.tableIsVisible = false;
           this.pushMessageHandler.clearTag(event.operationError);
        }
    }

    close() {
        this.portalSession.remove(this.activeRoute.id);
    }
}

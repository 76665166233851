<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Policy Summary</span>
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="policySummaryForm">
          <div class="form-group row">
            <div *ngIf="isPolicyNumberEnabled" class="col-sm-6 col-xs-12">
              <label translate>Policy Number:</label>
              <input class="form-control mic-input" formControlName="policyNumber" type="text">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-6 col-xs-12">
              <pnc-datepicker [customFormGroup]="policySummaryForm" [disabled]="true"
                              customFormControlName="issueDate" label="{{'Issue Date' | translate}}">
              </pnc-datepicker>
            </div>
            <div class="col-sm-6 col-xs-12">
              <pnc-datepicker [customFormGroup]="policySummaryForm"
                              [disabled]="true" [value]="effectiveDate"
                              customFormControlName="effectiveDate" label="{{'Effective Date' | translate}}">
              </pnc-datepicker>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-policy-summary-modal-close" translate>CLOSE
            </button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" data-qa="btn-policy-summary-modal-confirm" translate>
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="rgi-ui-drop-container rgi-rx-drop-list-container"
     [id]="id"
     cdkDropList
     [cdkDropListData]="model"
     (cdkDropListDropped)="drop($event)"
     (cdkDropListSorted)="sort($event)"
     (cdkDropListEntered)="onDragEntered($event)"
     (cdkDropListExited)="onDragExited($event)"
     [cdkDropListSortingDisabled]="!sortable"
     [cdkDropListDisabled]="disabled"
     [rgi-rx-qa]="id" qa-type="drop-list-container"
     #container
>
  <rgi-rx-drag-body
    cdkDrag
    *ngFor="let item of model; let i = index"
    [labelTemplate]="dragLabelDirective ? dragLabelDirective.template : undefined"
    [valueTemplate]="dragValueDirective ? dragValueDirective.template : undefined"
    [model]="item"
    [disabled]="disabled"
    [label]="label"
    [cdkDragData]="item"
    [viewModel]="getViewModelValue(item)"
    (onRemove)="remove($event)"
    [dragActions]="dragActions"
    [removeClass]="dragRemove ? dragRemove.class : undefined"
    [removeDescription]="dragRemove ? dragRemove.description : undefined"
    [rgi-rx-qa]="id +'-drag-body-'+(i+1)" [qa-value]="getViewModelValue(item)" qa-type="drag-body"
  >
    <div class="rgi-ui-drag-preview rgi-rx-drag-preview" *cdkDragPreview>
      <div [style.width]="container.offsetWidth + 'px'">
        <ng-container
          *ngTemplateOutlet="dragValueDirective ? dragValueDirective.template : defaultPreview; context: _getTemplateCtx(item)">
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="sortable">
      <div class="rgi-ui-drag-placeholder rgi-rx-drag-placeholder" *cdkDragPlaceholder></div>
    </ng-container>
  </rgi-rx-drag-body>

  <rgi-rx-drop-body [disabled]="disabled"></rgi-rx-drop-body>
</div>

<ng-template #defaultPreview let-ctx="viewModel">
  {{ctx}}
</ng-template>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PolicyAddress} from '../policy-address';
import {AddressType} from '../address.type';
import {Address} from '../address';

@Component({
  // tslint:disable-next-line
  selector: '[mic-addresses-list]',
  templateUrl: './addresses-list.component.html',
  styleUrls: ['../addresses.component.scss']
})
export class AddressesListComponent {

  @Input() addressesList: Array<PolicyAddress>;
  @Input() addressesType: AddressType;
  @Input() areAddressesEnabled = true;
  @Output() addressSelectedEvnt: EventEmitter<any> = new EventEmitter<any>();
  @Output() addressEditEvnt: EventEmitter<any> = new EventEmitter<any>();
  @Output() addressDeleteEvnt: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  public get addressTypeEnum(): typeof AddressType {
    return AddressType;
  }

  selectMainAddress(selectedAddress: PolicyAddress) {
    this.addressSelectedEvnt.emit({
      selectedAddress,
      addressType: this.addressesType
    });
  }

  editAddress(addressToEdit: Address, i: number) {
    this.addressEditEvnt.emit({
      address: addressToEdit,
      index: i
    });
  }

  delAddress(index: number) {
    this.addressDeleteEvnt.emit(index);
  }

}

import {GenericEntity} from '../domain-models/generic-entity';
import {ApiSetup} from './api-setup';
import {ChassisVehicle} from "../domain-models/chassis-vehicle";

export class ApiVehicleStaticData {

  constructor(
    public vehicleClass: GenericEntity,
    public vehicleUse: GenericEntity,
    public extension: GenericEntity,
    public registrationDate: Date,
    public fuel: GenericEntity,
    public doors: GenericEntity,
    public power: GenericEntity,
    public brand: GenericEntity,
    public model: GenericEntity,
    public setup: ApiSetup,
    public chassis: ChassisVehicle,
    public engineNumber: string,
    public approvalCode: string,
    public clusterInherit: boolean,
    public vehicleCodes: string[]
  ) {
  }

}

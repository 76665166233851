import { Output, EventEmitter, Directive } from '@angular/core';

@Directive()
export class Field {

    public label: string;
    public type: string;
    public id: string;
    public combovalues: string[];
    private valueInternal: any;
    public numberofdecimals: string;
    public children: Field[][] = [];
    public mandatory: boolean;
    public disabled: boolean;
    public parents: Field[] = [];
    public invalid: boolean;

    @Output() enableStatusChange = new EventEmitter<boolean>();

    constructor() { }

    set value(val: string) {
        this.valueInternal = val;
        if (!this.disabled) {
            this.manageDependencies();
        }
    }

    get value() {
        return this.valueInternal;
    }

    activate() {
        this.disabled = false;
        this.enableStatusChange.emit(true);
    }

    deactivate() {
        this.disabled = true;
        this.value = null;
        this.enableStatusChange.emit(false);
    }

    private manageDependencies() {

        for (const childKey in this.children) {
            if (this.children.hasOwnProperty(childKey)) {

                const element = this.children[childKey];

                element.forEach((field: Field) => {
                    field.deactivate();
                    if (childKey === String(this.valueInternal)) {
                        field.activate();
                    }
                });
            }
        }
    }

    addChild(value: any, child: Field) {
        if (!this.children) {
            this.children = [];
        }
        if (!this.children[value]) {
            this.children[value] = [];
        }
        this.children[value].push(child);
        child.addParent(this);
    }

    addParent(parent: Field) {
        this.parents.push(parent);
    }

    activateIfOrphan() {
        if (!this.parents.length) {
            this.activate();
        }
    }

    init() {
        this.manageDependencies();
    }
}

import { RgiRxi18nModuleLoadType, RgiRxTranslations } from '@rgi/rx/i18n';
import { IIAB_EN } from './en';
import { IIAB_IT } from './it';
import { IIAB_ES } from './es';
import { IIAB_DE } from './de';
import { IIAB_FR } from './fr';
import { IIAB_LIFE_PPEVO_DE } from './ppevo-questionnaire/de';
import { IIAB_LIFE_PPEVO_ES } from './ppevo-questionnaire/es';
import { IIAB_LIFE_PPEVO_EN } from './ppevo-questionnaire/en';
import { IIAB_LIFE_PPEVO_IT } from './ppevo-questionnaire/it';
import { IIAB_LIFE_PPEVO_FR } from './ppevo-questionnaire/fr';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_EN);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_ES);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_FR);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_DE);
  });
}


export const IIAB_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadFR,
    locale: 'fr'
  },
  {
    load: loadDE,
    locale: 'de'
  }
];


export function loadENPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_LIFE_PPEVO_EN)
  })
}

export function loadESPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_LIFE_PPEVO_ES)
  })
}

export function loadDEPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_LIFE_PPEVO_DE)
  })
}

export function loadITPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_LIFE_PPEVO_IT)
  })
}

export function loadFRPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(IIAB_LIFE_PPEVO_FR)
  })
}

export const LPC_TRANSLATIONS_PPEVO_QUEST_LIFE: RgiRxTranslations = [
  {
    load: loadENPPEVOLIFE,
    locale: 'en'
  },
  {
    load: loadESPPEVOLIFE,
    locale: 'es'
  },
  {
    load: loadDEPPEVOLIFE,
    locale: 'de'
  },
  {
    load: loadITPPEVOLIFE,
    locale: 'it'
  },
  {
    load: loadFRPPEVOLIFE,
    locale: 'fr'
  }
];

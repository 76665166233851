import {Component, Input, OnInit} from '@angular/core';
import {ReplacedPolicy, SubstituentPolicy} from '../../../../models/details/contract-details';
import {DATEFORMAT} from '../../../../constants/consultation-utility';
import {TableSchema} from '@rgi/rx//ui';
@Component({
  selector: 'consultation-substitutions',
  templateUrl: './consultation-substitutions.component.html',
  styleUrls: ['./consultation-substitutions.component.css']
})
export class ConsultationSubstitutionsComponent implements OnInit{
  @Input() substituentsAmendments:SubstituentPolicy[];
  @Input() replacedPolicies:ReplacedPolicy[];

  pageOptions: number[] = [10, 20, 50];
  substituentsTableSchema: TableSchema;
  replacedPoliciesSchema:TableSchema;
  protected readonly DATEFORMAT:string = DATEFORMAT;

  constructor() {
  }
  ngOnInit() {
    let isProposal:boolean = this.substituentsAmendments.filter(e=>{return !e.policyNumber}).length>0;

    this.substituentsTableSchema = this.generateTableSchema(isProposal, isProposal? 'CONTRACT_CONSULTATION.SUBSTITUENT_PROPOSALS' : 'CONTRACT_CONSULTATION.SUBSTITUENT_POLICIES' );
    this.replacedPoliciesSchema = this.generateTableSchema(false,'CONTRACT_CONSULTATION.SUBSTITUTED_POLICIES');
  }
  generateTableSchema(isProposal:boolean, label:string):TableSchema{
    return {
      rows: [
        {
          name: 'contractNumber',
          title: isProposal ? 'CONTRACT_CONSULTATION.PROPOSAL_NUMBER' : 'CONTRACT_CONSULTATION.POLICY_NUMBER',
        },
        {
          name: 'effectDate',
          title: 'CONTRACT_CONSULTATION.EFFECTIVE_DATE',
        },
        {
          name: 'substitutionEffectDate',
          title: 'CONTRACT_CONSULTATION.SUBSTITUTION_EFFECTIVE_DATE',
        },
        {
          name: 'product',
          title: 'CONTRACT_CONSULTATION.PRODUCT',
        },
        {
          name: 'substitutionReason',
          title: 'CONTRACT_CONSULTATION.SUBSTITUTION_REASON',
        },
        {
          name: 'actions',
          title: '',
          actions: [
            {
              name: 'DETAILS',
              styleClass: 'rgi-ui-icon-right-arrow'
            }
          ]
        }
      ], header: ['contractNumber', 'effectDate', 'substitutionEffectDate', 'product', 'substitutionReason', 'actions'],
      headerActions:[{
        when:'always',
        name:'',
        label: label,
        styleClass: 'rgi-cc-table-title'
      }]
    };
  }
}

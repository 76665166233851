export enum OperationPropertyCode {
  VOLATILITY_CALCULATION = '_CALVO',
  INVESTMENT_MODE = '_MODIN',
  DISINVESTMENT_MODE = '_MODDI',
  POST_SALES_PAYMENT_TYPES = '_DTPPV',
  INV_PROFILES_MODE = '_PROFI',
  REGOLAMENTO_41 = '_REG41',
  BENEF_DISABILE = '_BENDG'
}

export enum InvestmentMode {
  PERCENT = 'PERC',
  AMOUNT = 'IMP',
  BOTH = 'ENTR',
  ALLSAME = 'ALLSAM'
}

export enum InvestmentProfileMode {
  FIXED = 'FISSA',
  FREE = 'LIBERA'
}

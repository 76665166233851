export class DetailClaimsNumber {

  constructor(
    public id: number,
    public total: string,
    public ofWhichThings: string,
    public ofWhichPerson: string,
    public ofWhichMixed: string
  ) {
  }

}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-re-issue-quotation-save',
  templateUrl: './re-issue-quotation-save.component.html',
  styleUrls: ['./re-issue-quotation-save.component.css']
})
export class ReIssueQuotationSaveComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

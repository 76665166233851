import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {GpUnitClause, QuotationGuarantees, SingleUnit} from '../../group-policy-models/group-policy-issue-guarantees';
import {UntypedFormGroup} from '@angular/forms';
import {BaseEntity, Factor, GPClause} from '../../group-policy-models/group-policy-issue-policy-data';
import {Subscription} from 'rxjs';
import {GPVariableService} from '../../group-policy-services/gpvariable.service';
import {GroupPolicyDateAdapter} from '../../adapters/ngbDateCustomParserFormatter ';

export class UpdateUnitEvent {
  unit: SingleUnit;
  linkedToUnitCode: string;

  constructor(unit: SingleUnit, linkedToUnitCode?: string) {
    this.unit = unit;
    this.linkedToUnitCode = linkedToUnitCode;
  }
}

@Component({
  selector: 'rgi-gp-unit',
  templateUrl: './group-policy-unit.component.html'
})
export class GroupPolicyUnitComponent implements OnInit, OnDestroy, OnChanges {

  @Input() unit: SingleUnit;
  @Input() unitInitialStatuses: BaseEntity[];
  @Input() unitsClause: GpUnitClause;
  @Input() linkedUnits: SingleUnit[];
  @Input() quotations: QuotationGuarantees[];
  @Input() accordionMap: Map<string, boolean>;
  @Input() accordionPrefix: string;
  @Input() parentForm: UntypedFormGroup;
  @Output() updateUnit = new EventEmitter<UpdateUnitEvent>();

  @Output() updateUnitClauses = new EventEmitter<GpUnitClause>();

  unitPremium: string = undefined;

  unitFormGroup: UntypedFormGroup;

  subscriptions: Subscription;

  constructor(
    public variableService: GPVariableService,
    public dateAdapter: GroupPolicyDateAdapter) {
  }

  ngOnInit() {
    this.subscriptions = new Subscription();
    if (this.quotations) {
      const guarantyQuote = this.quotations.find((el) => el.riskCode === this.unit.riskCode);
      this.unitPremium = (guarantyQuote) ? guarantyQuote.gross.toString() : '';
    }
    this.addUnitToParentForm(this.unit, this.accordionPrefix + this.unit.code);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.unit && !changes.unit.isFirstChange()
      && JSON.stringify(changes.unit.currentValue) !== JSON.stringify(changes.unit.previousValue)) {
      this.addUnitToParentForm(changes.unit.currentValue, this.accordionPrefix + this.unit.code);
    }
  }

  manageUnitCheckBox() {
    this.unit.selected = !this.unit.selected;
    this.addUnitToParentForm(this.unit, this.accordionPrefix + this.unit.code);
    this.updateUnit.emit(new UpdateUnitEvent(this.unit));
  }

  updateLinked($event: UpdateUnitEvent) {
    $event.linkedToUnitCode = this.unit.code;
    this.updateUnit.emit($event);
  }

  onVariableUpdate(variableToUpdate: Factor) {
    const variableControl = this.unitFormGroup.get(variableToUpdate.code);
    this.variableService.updateVariableInList(variableToUpdate, variableControl.value, this.unit.listVariable);
    this.updateUnit.emit(new UpdateUnitEvent(this.unit));
  }

  isUnitExpanded(): boolean {
    return this.accordionMap.get(this.accordionPrefix + this.unit.code);
  }

  updateAccordionMap(expanded: boolean) {
    this.accordionMap.set(this.accordionPrefix + this.unit.code, expanded);
  }

  updateClauses(event: GPClause[]) {
    const unitCLauses: GpUnitClause = {
      unitClauses: event,
      codeUnit: this.unit.code,
      codeSection: this.unit.sectionCode,
    };
    this.updateUnitClauses.emit(unitCLauses);
  }

  onInitialStateChange(state: string) {
    this.unit.startStatusType = parseInt(state, 10);
    this.unit.startStatusDescr = this.unitInitialStatuses.find(s => s.code === state).description;
    this.updateUnit.emit(new UpdateUnitEvent(this.unit));
  }

  protected addUnitToParentForm(unit: SingleUnit, unitFormGroupName: string) {
    if (!this.unitFormGroup) {
      this.unitFormGroup = this.variableService.createVariablesFormGroup(unit.listVariable);
      this.parentForm.addControl(unitFormGroupName, this.unitFormGroup);
    } else {
      this.unitFormGroup.enable({emitEvent: false});
      this.variableService.updateVariablesFormGroup(unit.listVariable, this.unitFormGroup);
    }
    if (!unit.selected) {
      this.unitFormGroup.disable({emitEvent: false});
    }
  }

  ngOnDestroy() {
    Object.keys(this.unitFormGroup.controls).forEach(fc => {
      this.unitFormGroup.removeControl(fc);
    });
    this.parentForm.removeControl(this.accordionPrefix + this.unit.code);
    this.subscriptions.unsubscribe();
  }

  trackLinkedUnit(_index: number, linked: SingleUnit) {
    return linked.code;
  }

}

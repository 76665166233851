import {  Component, EventEmitter, Input, Output } from '@angular/core';
import { PIPConfig } from './../../../../../models/pip-data.model';

@Component({
  selector: 'lpc-life-pip-detail-mc',
  templateUrl: './life-pip-detail-mc.component.html',
})
export class LifePipDetailComponentMCComponent {

  @Input() pipDataConfig: PIPConfig[];

  @Output() pipDetailSessionEvent: EventEmitter<string> = new EventEmitter<string>( );

  emitPipEvent(pipEvent: string) {
    this.pipDetailSessionEvent.emit(pipEvent);
  }

}

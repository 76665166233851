import { ActiveRoute } from '@rgi/rx/router';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import { of } from 'rxjs';
import { AnagApiSubject, PaymentsPayload, Role, SubjectContract } from '../../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyVcontRouteData } from '../../group-policy-models/group-policy-vcont-date';
import { SubcauseModel } from '../../group-policy-models/group-policy-vcont-variation';
import { GroupPolicyStateVcontVariation } from '../group-policy-state';
import { GroupPolicyStatelessOpVariationService } from './group-policy-stateless-op-variation.service';
// tslint:disable:max-line-length

/**
 * @author: dmasone
 * @description: State Manager used for render vcont variation step
 */
export class GroupPolicyStateManagerVariation extends AbstractStateManager<GroupPolicyStateVcontVariation> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: GroupPolicyStatelessOpVariationService) {
    super(activeRoute, stateStoreService);
    const statePolicyData = this.stateStoreService.has(this.activeRoute.key) ?
      this.stateStoreService.get<GroupPolicyStateVcontVariation>(this.activeRoute.key) :
      new GroupPolicyStateVcontVariation(this.activeRoute.key);
    const initial$ = this.statelesOps.initVariation$(of(statePolicyData), this.activeRoute);
    this.updateState$(initial$);
  }

  /**
   * @param subj party to set
   * @param role assigned to subj
   * @param disablePolicyHolder flag to disable policyholder if the subj arrive
   * for example from questionnaire
   */
  public actionSetPartyRole(subj: AnagApiSubject, previousStepData: GroupPolicyVcontRouteData, role: string, showSubcauses = false) {
    const st = this.getCurrentState();
    const reqSubj = {} as SubjectContract;
    reqSubj.idParty = subj.objectId;
    reqSubj.role = role;
    this.updateState$(this.statelesOps.updateParty$(of(st), previousStepData, reqSubj, showSubcauses));
  }

  public actionSetPercentageOnRole(roleParty: Role, percentage: number, previousStepData: GroupPolicyVcontRouteData, subCauseVisible = false) {
    const st = this.getCurrentState();
    const reqSubj = {} as SubjectContract;
    reqSubj.idParty = roleParty.idParty;
    reqSubj.role = roleParty.role;
    reqSubj.percentage = percentage;
    this.updateState$(this.statelesOps.updateParty$(of(st), previousStepData, reqSubj, subCauseVisible));
  }

  public actionDeleteRole(data: GroupPolicyVcontRouteData, role: string, idParty: number, delSubcauses = false) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.deleteRole$(of(st), data, role, idParty, delSubcauses));
  }

  public setPaymentMethod(paymentMethod: PaymentsPayload, previousStepData: GroupPolicyVcontRouteData) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.setPaymentMethod$(of(st), paymentMethod, previousStepData));
  }
  public actionSetSubcause(payload: SubcauseModel, previousStepData: GroupPolicyVcontRouteData) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.actionSetSubcausePostSales$(of(st), previousStepData.resId, payload));
  }

  public actionGoForword(resId: string) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.actionGoForword$(of(st), resId, this.activeRoute));
  }

  public actionBack(previousStepData: GroupPolicyVcontRouteData, activeRoute: ActiveRoute, targetRoute: string) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.actionBack$(of(st), previousStepData, activeRoute, targetRoute));
  }

  public actionClose(previousStepData: GroupPolicyVcontRouteData) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.actionClose$(of(st), previousStepData, this.activeRoute));
  }

}

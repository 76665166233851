<rgi-rx-modal #infoModal *ngIf="info">
    <rgi-rx-panel #infoPanel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="infoModal.close()" class="ppevo-survey-eval-info-title">
            <span class="ppevo-icon-info ppevo-icon"></span>
            <span class="ppevo-info"></span>
            <span class="ppevo-name">{{info.name}}</span>
        </rgi-rx-panel-header>

        <div class="ppevo-survey-eval-info-body">
            <div *ngIf="info.description && info.description.length" class="ppevo-description">{{info.description}}</div>

            <div  *ngIf="canShowMessages()" class="ppevo-messages">
                <div class="ppevo-title" translate>NG_PASSPROPRO_SURVEY.messages</div>
                <div class="ppevo-message" *ngFor="let message of info.messages">{{message}}</div>
                <ng-container *ngIf="info.pricing && info.pricing.messages">
                    <div class="ppevo-message" *ngFor="let message of info.pricing.messages">{{info.pricing.messages}}</div>
                </ng-container>
            </div>

            <div *ngIf="pkgPricing && pkgPricing.hasCoveragePricing" class="ppevo-package-coverages-box">
                <div class="ppevo-title" translate>NG_PASSPROPRO_SURVEY.package_coverages_title</div>
                <div class="ppevo-package-coverages">
                    <table class="ppevo-table">
                        <tbody>
                            <tr *ngFor="let coverage of info.package.coverages">
                                <td>{{coverage.name}}</td>
                                <td *ngIf="pkgPricing.covPricingMap[coverage.code].price.value>0" style="white-space: nowrap;">
                                    {{pkgPricing.covPricingMap[coverage.code].price.value | number : '1.2-2'}} {{'NG_PASSPROPRO_SURVEY.currency'|translate}}
                                </td>
                                <td *ngIf="pkgPricing.covPricingMap[coverage.code].price.value==0"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="canShowDetails()" class="ppevo-details-box">
                <div class="ppevo-tree-item" (click)="expandDetails = !expandDetails">
                    <span [ngClass]="expandDetails?'ppevo-tree-close':'ppevo-tree-open'"></span>
                    <span class="ppevo-name" translate>NG_PASSPROPRO_SURVEY.details</span>
                </div>

                <div class="ppevo-tree-body" [hidden]="!expandDetails">

                    <div *ngIf="info.targetmarket && info.targetmarket.length">
                        <div class="ppevo-market-label" translate>NG_PASSPROPRO_SURVEY.targetmarket</div>
                        <survey-eval-market-tree [markets]="info.targetmarket" [expand]="defaultShowDetailsChild"></survey-eval-market-tree>
                    </div>

                    <div *ngIf="info.notargetmarket && info.notargetmarket.length">
                        <div class="ppevo-market-label" translate>NG_PASSPROPRO_SURVEY.nottargetmarket</div>
                        <survey-eval-market-tree [markets]="info.notargetmarket" [expand]="defaultShowDetailsChild"></survey-eval-market-tree>
                    </div>

                    <div *ngIf="info.pricing">
                        <div class="ppevo-market-label" translate>NG_PASSPROPRO_SURVEY.pricing</div>
                        <survey-eval-rule-tree [rule]="info.pricing" [expand]="{rule:defaultShowDetailsChild,msgs:defaultShowDetailsChild, vars: defaultShowDetailsChild, forms: defaultShowDetailsChild}"></survey-eval-rule-tree>
                    </div>
                </div>
            </div>

        </div>
    </rgi-rx-panel>
</rgi-rx-modal>

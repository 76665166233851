<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()"><span translate>RE_ISSUE.CONTRIBUTIONS_AND_TAXES</span>
  </rgi-rx-panel-header>

  <div class="modal-body large-modal">

    <!-- SUMMARY_SIGN_INSTALMENT_TABLE -->
    <div class="contributions-taxes-summary hidden-xs" data-qa="contribution_taxes_summary_sign_instalment">
      <div class="summary-net-row">
        <span translate>RE_ISSUE.SUBSCRIPTION_INSTALMENT_TAXABLE</span>
        <span
          class="amount">{{ premiumSubscriptionInstalmentGroup.premium.taxable | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
      </div>
      <div class="summary-gross summary-net-row">
        <span translate>RE_ISSUE.SUBSCRIPTION_INSTALMENT_GROSS</span>
        <span
          class="amount">{{ premiumSubscriptionInstalmentGroup.premium.gross | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
      </div>
    </div>
    <div data-qa="contribution_taxes_summary_sign_instalment_section">
      <re-issue-table #signInstalmentTable
                      [dataSource]="SUMMARY_SIGN_INSTALMENT_TABLE"
                      [columnsToDisplay]="displayedColumns"
                      [superHeader]="superHeader"
                      [footerData]="premiumSubscriptionInstalmentGroup"
                      [lpsTax]="lpsSignInstalmentAmount"
                      [totalAntiRacket]="totalSignAntiRacket">
      </re-issue-table>
    </div>
    <br><br><br>

    <!-- SUMMARY_NEXT_INSTALMENT_TABLE -->
    <div class="contributions-taxes-summary hidden-xs" data-qa="contribution_taxes_summary_next_instalment">
      <div class="summary-net-row">
        <span translate>RE_ISSUE.NEXT_INSTALMENT_TAXABLE</span>
        <span
          class="amount">{{ premiumInstallmentGroup.premium.taxable | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
      </div>
      <div class="summary-gross summary-net-row">
        <span translate>RE_ISSUE.NEXT_INSTALMENT_GROSS</span>
        <span
          class="amount">{{ premiumInstallmentGroup.premium.gross | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
      </div>
    </div>
    <div data-qa="contribution_taxes_summary_next_instalment_section">
      <re-issue-table #nextInstalmentTable
                      [dataSource]="SUMMARY_NEXT_INSTALMENT_TABLE"
                      [columnsToDisplay]="displayedColumns"
                      [superHeader]="superHeader"
                      [footerData]="premiumInstallmentGroup"
                      [lpsTax]="lpsNextInstalmentAmount"
                      [totalAntiRacket]="totalNextAntiRacket">
      </re-issue-table>
    </div>
    <br><br><br>

    <!-- SUMMARY_ANNUAL_PREMIUM_TABLE -->
    <div class="contributions-taxes-summary hidden-xs" data-qa="contribution_taxes_summary_annual_premium">
      <div class="summary-net-row">
        <span translate>RE_ISSUE.ANNUAL_PREMIUM_TAXABLE</span>
        <span
          class="amount">{{ annualPremiumGroup.premium.taxable | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
      </div>
      <div class="summary-gross summary-net-row">
        <span translate>RE_ISSUE.ANNUAL_PREMIUM_GROSS</span>
        <span
          class="amount">{{ annualPremiumGroup.premium.gross | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;</span>
      </div>
    </div>
    <div data-qa="contribution_taxes_summary_annual_premium_section">
      <re-issue-table [dataSource]="SUMMARY_ANNUAL_PREMIUM_TABLE"
                      [columnsToDisplay]="displayedColumns"
                      [superHeader]="superHeader"
                      [footerData]="annualPremiumGroup"
                      [totalAntiRacket]="totalAnnualAntiRacket">
      </re-issue-table>
    </div>

  </div>

  <rgi-rx-panel-footer>
    <button *ngIf="lpsData.policyLpsData.lpsType !== '0'" class="rgi-ui-btn rgi-ui-btn-primary"
            (click)="calculateAndUpdate()" data-qa="recalculateUpdate" translate>
      RE_ISSUE.RECALCULATE_UPDATE
    </button>
    <button *ngIf="lpsData.policyLpsData.lpsType !== '0'" class="rgi-ui-btn rgi-ui-btn-primary"
            (click)="calculateDefaultLps()" data-qa="recalculateDefaultLpsFees" translate>
      RE_ISSUE.RECALCULATE_DEFAULT_LPS_TAXES
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" data-qa="close" translate>
      RE_ISSUE.CLOSE
    </button>
  </rgi-rx-panel-footer>

</rgi-rx-panel>

import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaginatorChange, RgiRxDataPaginator } from '../paginator-api';

@Component({
  selector: 'rgi-rx-paginator',
  templateUrl: './rgi-rx-paginator.component.html',
  viewProviders: [{
    provide: RgiRxDataPaginator, useClass: RgiRxDataPaginator
  }],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'rgi-ui-pagination',
    role: 'group'
  }
})
export class RgiRxPaginatorComponent implements OnInit, OnDestroy {

  @Input('count') elementCount: number;
  @Input('pageSize') pageSize = 10;
  @Input('maxCounter') maxCounter = 5;
  @Input('pageOptions') pageOption: number[];
  @Output() changes = new EventEmitter<PaginatorChange>();

  pageCounter: number[] = [];

  private _disabled = false;
  private paginatorChangesSubscription = Subscription.EMPTY;

  get paginator(): RgiRxDataPaginator {
    return this._paginator;
  }

  set paginator(value: RgiRxDataPaginator) {
    this._paginator = value;
  }

  @Input() @HostBinding('class.rgi-ui-disabled') get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }

  constructor(public _paginator: RgiRxDataPaginator) { }

  ngOnInit(): void {
    this._paginator.pageSize = this.pageSize;
    if (this.elementCount) {
      this._paginator.elementCount = this.elementCount;
    }
    this.pageCounter = this._paginator.getPageOptions(this.maxCounter);
    this.paginatorChangesSubscription.unsubscribe();
    this.paginatorChangesSubscription = this._paginator.changes.subscribe(
      next => {
        this.changes.emit(next);
        this.pageCounter = this._paginator.getPageOptions(this.maxCounter);
      }
    );
  }

  onChangeOptions(pageSize: string) {
    this._paginator.pageSize = parseInt(pageSize, 10);
  }

  ngOnDestroy(): void {
    this.paginatorChangesSubscription.unsubscribe();
  }

}

import { StepConfiguration } from '../../../models/consts/lpc-consts';

export interface DynamicStepperConfig {
  /**
   * @stepField id `'beneficiaries'`
   * @stepField label `'Beneficiaries'`
   * @stepField fieldId `'beneficiaries'`
   * @stepField formName `'beneficiaries'`
   */
  BENEFICIARIES: StepConfiguration;
}

export const DYNAMIC_OP_STEPS: DynamicStepperConfig = {
  BENEFICIARIES: {
    id: 'beneficiaries',
    label: 'lpc_life_detail_beneficiaries',
    fieldId: 'beneficiaries',
    formName: 'beneficiaries'
  }
};

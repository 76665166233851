import { Inject, Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { ROUTES_PAYMENTS_VALIDATION_CONSOLIDATION_TABLE, ROUTES_FORFAIT_TABLE } from '../constants/routes.constants';
import { ValidationConsolidationDataFromRoute } from '../models/validation/validation-consolidation-data-from-route.model';
import { ForfaitDataFromRoute } from '../models/forfait/forfait-data-from-route.model';

@Injectable()
export class PaymentsDashboardIntegrationService {

  constructor(
    protected routingService: RoutingService,
    @Inject('sessionService') protected portalSession: any,
    @Inject('coreResult') protected portalCoreResult: any,
  ) { }

  closeSession(id) {
    this.routingService.destroy(id);
  }

  backToPrevPage(_id, _idParentSession) {
    this.routingService.destroy(_id);
  }

  navigate(routingService: RoutingService, destinationRoute: string, routeData: any, activeRoute?: ActiveRoute) {
    routingService.navigate(destinationRoute, routeData, activeRoute ? activeRoute.id : null);
  }

  goToCardList(validationConsolidationDataFromRoute: ValidationConsolidationDataFromRoute, activeRoute: ActiveRoute) {
    this.navigate(this.routingService, ROUTES_PAYMENTS_VALIDATION_CONSOLIDATION_TABLE, { dataFromRoute: validationConsolidationDataFromRoute }, activeRoute);
  }

  goToCardForfait(forfaitDataFromRoute: ForfaitDataFromRoute, activeRoute: ActiveRoute) {
    this.navigate(this.routingService, ROUTES_FORFAIT_TABLE, { dataFromRoute: forfaitDataFromRoute }, activeRoute);
  }
}
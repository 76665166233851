export class PolicyDate {
  constructor(
    public date: string,
    public maxDate: number,
    public minDate: number,
    public modifiable: boolean,
    public selected?: boolean,
  ) {
  }
}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <span class="rgifont rgi-dettagli_tecnici"></span>
            {{ '_CLAIMS_._NEW_UPLOAD' | translate }} {{documentCategory.categoryName}}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
        </div>
        <div class="modal-body" style="padding-top: 5px">
            <form [formGroup]="uploadForm" data-qa="document-upload-form">
                <div class="container-fluid">
                    <div class="form-group">
                        <div class="box-input" *ngIf="!fileLoaded" claimsDragAndDrop (dndDrop)="fileDropped($event)">
                            <input type="file" (change)="fileChange($event)" placeholder="Upload file"
                                formControlName="file" id="id" name="id" class="box-file">
                            <label for="id" class="box-upload-label">
                                <span class="clearfix"><span
                                        class="box-upload-icon glyphicon glyphicon-cloud-upload"></span></span>
                                <span class="box-dragndrop clearfix">{{ '_CLAIMS_._MESSAGE_._DRAG_A_FILE_TO_UPLOAD' | translate
                                    }}</span>
                                <span><strong class="text-blue-upload" translate="">{{ '_CLAIMS_._CLICK_HERE' | translate }}</strong>
                                    {{ '_CLAIMS_._TO_SELECT' | translate }} </span>
                            </label>
                        </div>

                        <div class="upload-file-block" *ngIf="fileLoaded">
                            <div class="row upload-file-block-inside">
                                <div class="col-sm-1">
                                    <div class="document-upload-type">
                                        <span class="rgifont document-icon"
                                            [ngClass]="{ 'rgi-doc-file-format': !pdfType, 'rgi-pdf-file-format-symbol': pdfType}">
                                        </span>
                                    </div>
                                </div>

                                <div class="col-sm-10">
                                    <div class="document-upload-name">{{fileName}}</div>
                                </div>

                                <div class="col-sm-1">
                                    <button type="button" class="btn btn-link" (click)="unloadFile($event)">
                                        <span class="rgifont rgi-trash upload-doc-font"></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="document-rules-block">
                            <div class="row">
                                <div class="col-sm-12">
                                   {{ '_CLAIMS_._RULES_FOR_UPLOADING_DOCUMENTS' | translate }} <br />
                                    <ul>
                                        <li>{{ '_CLAIMS_._MESSAGE_._YOU_CAN_INSERT_1_TO_2_ATTACHMENT' | translate }}</li>
                                        <li>{{ '_CLAIMS_._MESSAGE_._ALLOWED_EXTENSION' | translate }}</li>
                                        <li>{{ '_CLAIMS_._MESSAGE_._THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB' | translate }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="hasError" class="error-container">
                            <pnc-form-message type="error" detail="validationError" [messages]="errorMessages"
                                data-qa="upload-modal-error-messages"></pnc-form-message>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button class="btn btn-warning pull-right" (click)="uploadFile($event)"
                        attr.data-qa="claim-opening-policy-details-close-btn">{{ 'Upload' | translate | uppercase}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
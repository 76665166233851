import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LetterTemplatesService} from '../../letter-templates.service';
import {DerogationLevelEntity} from '../../dto/derogation-level.entity';
import {ManagePhaseEntity} from '../../dto/manage-phase.entity';
import {RoutableComponent, RoutingService} from '@rgi/portal-ng-core';
import {CardComponent, CardComponentService, SessionService} from '@rgi/digital-claims-common-angular';
import {LetterTemplatesUsersListComponent} from '../letter-templates-users-list/letter-templates-users-list.component';
import {LetterTemplateEntity} from '../../dto/letter-template.entity';
import {LetterTemplatesListComponent} from '../letter-templates-list/letter-templates-list.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'claims-new-letter-templates',
  templateUrl: './new-letter-templates.component.html',
  styleUrls: ['./new-letter-templates.component.scss']
})
export class NewLetterTemplatesComponent extends CardComponent implements OnInit, RoutableComponent {
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  routes: { letterTemplatesUsersList: any; letterTemplatesList: any };

  errorMessage: string;

  // eventiale lettera da modificare
  letterTemplateEntity: LetterTemplateEntity;
  originalLetterDerogationLevel: string;

  // oggetti input
  livelliDeroga: DerogationLevelEntity[];
  fasiGestione: ManagePhaseEntity[];

  newLetterTemplateForm: UntypedFormGroup;

  constructor(
    private letterTemplatesService: LetterTemplatesService,
    @Inject('sessionService') private sessionServiceInject: any,
    @Inject('eventService') private emitter: any,
    @Inject('angularGridInstance') private gridInstance: any,
    private formBuilder: UntypedFormBuilder,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('letterTemplatesUsersList') letterTemplatesUsersList,
    @Inject('letterTemplatesList') letterTemplatesList,
    private routingService: RoutingService,
    public translateService: RgiRxTranslationService
  ) {
    super(cardComponentService, sessionService);

    this.routes = {
      letterTemplatesUsersList,
      letterTemplatesList,
    };
  }

  ngOnInit() {
    const obsNLM$ = this.translateService.translate('_CLAIMS_._NEW_LETTER_MODEL');
    const obsCreation$ = this.translateService.translate('_CLAIMS_._CREATION');
    combineLatest([
      obsNLM$,
      obsCreation$,
    ]).subscribe(
      ([nlm, creation]) => {
        this.card.title = nlm + ' - ' + creation;
      })
    this.initValidators();

    this.livelliDeroga = this.letterTemplatesService.getLivelliDeroga();
    this.fasiGestione = this.letterTemplatesService.getFasiGestione();
  }

  initValidators() {
    if (!this.newLetterTemplateForm) {
      this.newLetterTemplateForm = this.formBuilder.group({
        id: [this.letterTemplateEntity ? this.letterTemplateEntity.id : null],
        titolo: [this.letterTemplateEntity ? this.letterTemplateEntity.titolo : '', Validators.required],
        livelloDeroga: [this.letterTemplateEntity ? this.letterTemplateEntity.idLivelloDeroga : '', Validators.required],
        idFaseGestione: [this.letterTemplateEntity ? this.letterTemplateEntity.idFaseGestione : '', Validators.required],
        corpoLettera: [this.letterTemplateEntity ? this.letterTemplateEntity.corpoLettera : '', Validators.required]
      });

      this.originalLetterDerogationLevel = this.letterTemplateEntity ? this.letterTemplateEntity.idLivelloDeroga : '';
    }
  }

  getCurrentSession() {
    return this.sessionServiceInject.list().find(el => el.isActive);
  }

  back() {
    if (!this.letterTemplateEntity) {
      const session = this.getCurrentSession();
      this.sessionServiceInject.remove(session.idSession);
    } else {
      this.routingService.loadComponent(this.routes.letterTemplatesList);
      (this.routingService.componentRef.instance as LetterTemplatesListComponent).card = this.card;
    }
  }

  continue() {
    this.errorMessage = '';

    if (this.newLetterTemplateForm.valid) {
      if (this.newLetterTemplateForm.get('livelloDeroga').value !== this.originalLetterDerogationLevel) {
        if (this.letterTemplateEntity) {
          for (const user of this.letterTemplateEntity.listUsers) {
            user.livelloDerogaLettera = this.newLetterTemplateForm.get('livelloDeroga').value;
          }
        }
      }

      this.routingService.loadComponent(this.routes.letterTemplatesUsersList);
      (this.routingService.componentRef.instance as LetterTemplatesUsersListComponent).card = this.card;
      (this.routingService.componentRef.instance as LetterTemplatesUsersListComponent).newLetterTemplateForm = this.newLetterTemplateForm;
      (this.routingService.componentRef.instance as LetterTemplatesUsersListComponent).letterTemplateEntity = this.letterTemplateEntity;
      // tslint:disable-next-line:max-line-length
    } else {
      this.translateService.translate('_CLAIMS_._INVALID_FIELDS')
      .subscribe(res => this.errorMessage = res + ': ')
      if (!this.newLetterTemplateForm.get('title') || !this.newLetterTemplateForm.get('title').valid) {
        this.translateService.translate('_CLAIMS_._TITLE')
        .subscribe(res => this.errorMessage = res + ' ')
      }
      if (!this.newLetterTemplateForm.get('livelloDeroga') || !this.newLetterTemplateForm.get('livelloDeroga').valid) {
        this.translateService.translate('_CLAIMS_._DEROGATION_LEVEL')
        .subscribe(res => this.errorMessage = res + ' ')
      }
      if (!this.newLetterTemplateForm.get('idFaseGestione') || !this.newLetterTemplateForm.get('idFaseGestione').valid) {
        this.translateService.translate('_CLAIMS_._MANAGE_LEVEL')
        .subscribe(res => this.errorMessage += res + ' ')
      }
      if (!this.newLetterTemplateForm.get('corpoLettera') || !this.newLetterTemplateForm.get('corpoLettera').valid) {
        this.translateService.translate('_CLAIMS_._BODY')
      }
    }
  }
}

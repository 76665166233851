import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const IT: RgiRxSerializedI18n  = {
  START_EMISSION_FLOW_RE: 'Flusso emissione RE',
  START_POLICY_DATA: 'Proposta Danni - Dati polizza',
  START_ASSETS_DATA: 'Proposta Danni - Dati bene',
  START_QUOTATION: 'Proposta Danni - Quotazione',
  START_SUMMARY: 'Proposta Danni - Riepilogo',
  START_PROPOSAL_MANAGEMENT: 'Proposta Danni - Gestione proposta',
  START_SAVE_PROPOSAL: 'Proposta Danni - Salva Proposta',
  START_EMISSION: 'Proposta Danni - Emissione',
  START_REPRINT_DOCUMENTS: 'Ristampa Documenti',
  START_REPRINT_MOVEMENTS: 'Ristampa Movimenti',
  START_SUBSTITUTION: 'Sostituzione',
  // Replace space with underscore
  RE_ISSUE: {
    DATE_CREDIT_CARD: 'La data inserita non è valida',
    DATE_ERROR: 'Nessuna Versione valida per il Prodotto selezionato',
    REASON: 'Causale',
    RECALCULATE_SAVE: 'Ricalcola e Salva',
    CLEAR_DISCOUNTS: 'Azzera sconti',
    DISCOUNT_AMOUNT: 'Residuo montesconti',
    DISCOUNTS: 'Gestione sconti',
    WARRANTIES_SUMMARY: 'Riepilogo garanzie',
    IS_MANDATORY: 'è obbligatorio',
    Country: 'Nazione',
    POLICY_DATA: 'Dati polizza',
    ASSET_DATA: 'Dati bene assicurato',
    QUOTATION: 'Quotazione',
    SUMMARY: 'Riepilogo',
    VALIDATE_BRANCH: 'Per favore, verificare lo sportello',
    FIELDS_MARKED_IN_RED: 'I campi marcati in rosso sono richiesti o contengono valori errati',
    IS_REQUIRED: 'è richiesto',
    COVERAGE_EXPIRE_DATE: 'Data Scadenza Copertura',
    SUBSCRIPTION_INST_PREMIUM: 'Premio rata alla Firma',
    INST_SIGN: 'Rata Firma: ',
    MANDATORY_QUESTIONNAIRE_NOT_FILLED: 'Attenzione, è presente almeno un questionario obbligatorio non compilato.',
    NATION: 'Nazione',
    CANCEL: 'Cancella',
    CONFIRM: 'Conferma',
    DATE_OF_BIRTH: 'Data di nascita',
    FACTORS: 'Fattori',
    PLACE_OF_BIRTH: 'Luogo di nascita',
    DAMAGES_PROPOSAL_ASSET_DATA: 'Proposta Danni - Dati Bene',
    SUBJECTS: 'Soggetti',
    INSURED_SUBJECTS: 'Soggetto assicurato',
    OCCUPATION: 'Professione',
    FISCAL_CODE: 'Codice fiscale',
    BACK: 'Indietro',
    ADD_ASSET: 'Aggiungi bene assicurato',
    NEXT: 'Continua',
    CONTINUE: 'Prosegui',
    NEXT_START_CARD: 'Avanti',
    EMISSION: 'Emissione',
    POLICY_N: 'Polizza n.',
    SAVED_SUCCESSFULLY: 'è stata salvata con successo.',
    SUCCESSFULLY_FINALIZED: 'finalizzata correttamente',
    PRINT_PIN: ' è necessario stampare e inserire il PIN presente sulla stampa per procedere alla finalizzazione del contratto',
    PRINT: 'Stampa',
    PIN_ENTRY: 'Inserimento PIN',
    EXIT: 'Esci',
    ISSUE_CONFIRMATION: 'Conferma Emissione',
    ENTER_PIN_TO_CONFIRM_POLICY_ISSUE: 'Inserisci il PIN per confermare l\'emissione della polizza:',
    CONFIRM_PURCHASE: 'Conferma l\'Acquisto',
    POLICY_PREVIEW: 'Anteprima polizza',
    VALIDATE: 'Verifica',
    POLICYHOLDER: 'Contraente',
    CO_OWNERS: 'Cointestatari',
    NODE: 'Nodo',
    NODE_START_CARD: 'Nodo',
    eMAIL: 'Email',
    PRODUCT: 'Prodotto',
    BRANCH_OFFICE: 'Sportello',
    FISCAL_CODE_VAT_NUMBER: 'Codice Fiscale/P.IVA',
    RESIDENCE_ADDRESS: 'Indirizzo Residenza',
    CONTACTS: 'Contatti',
    MOBILE_PHONE: 'Numero Telefono',
    EFFECTIVE_DATE: 'Data Effetto',
    NEXT_INSTALMENT_DATE: 'Data Prossima Rata',
    EXPIRE_DATE: 'Data scadenza',
    TACIT_RENEWAL: 'Tacito Rinnovo',
    PAYMENT_FREQUENCY: 'Frazionamento',
    AGREEMENT: 'Convenzione',
    PROPOSAL_MANAGEMENT: 'Gestione proposta',
    ISSUE_DATE: 'Data Emissione',
    POLICY_MANDATORY_DATA: 'Dati Obbligatori di Polizza',
    POLICY_NUMBER: 'Numero polizza',
    MEANS_OF_PAYMENT: 'Metodi di pagamento',
    SELECT_MEANS_OF_PAYMENT: 'Seleziona metodo di pagamento',
    COORDINATES_TO_REFUND: 'Coordinate per rimborso',
    HEADING: 'Intestazione',
    ISSUE: 'Emetti',
    '{{header}}': '',
    '{{ parameter?.description }}': '',
    QUESTIONNAIRES: 'Questionari',
    NOT_COMPILED: 'Non compilato',
    COMPILED: 'Compilato',
    TO_BE_FILLED_IN_BY_THE_SUBJECT: 'Da compilarsi a cura del soggetto con ruolo Contraente',
    CLAUSES: 'Clausole',
    ASSET_CLAUSES: 'Clausole di Bene',
    INSURABILITY_REQUIREMENTS: 'Requisiti di assicurabilità',
    PRE_DISCOUNT_PREMIUM: 'Premio ante sconto',
    GROSS: 'Lordo',
    PREMIUM_INSTALLMENT: 'Premio rata alla firma',
    ANNUAL_PREMIUM: 'Premio annuo',
    PREMIUM_RATE: 'Premio di Rata',
    RECALCULATE: 'Ricalcola',
    VISUALIZE: 'Visualizza',
    PREMIUM_DETAILS: 'Dettaglio premio',
    CONTRIBUTIONS_AND_TAXES: 'Contributi e Imposte',
    BOX: 'Griglia',
    LIST: 'Lista',
    COMMISSIONS: 'Provvigioni',
    AUTHORITY_LIMIT: 'Limiti autorità',
    PERC_ACQUIRED_COMMISSIONS: '% Provvigioni acquisto',
    PERC_ACQUIRED_CHARGES: '% addebito acquisto',
    PERC_COLLECTED_COMMISSIONS_NET: '% Provvigioni raccolte',
    PERC_COLLECTED_CHARGES: '% addebiti raccolti',
    APPLY: 'Applica',
    EDIT_AMOUNT: 'Modifica importo',
    COMMISSIONS_AT_INCEPTION_DATE: 'Provvigioni alla data inserimento',
    ACQUIRED_COMMISSIONS: 'Provvigioni acquisite',
    ACQUIRED_CHARGES: 'Addebiti acquisiti',
    COLLECTED_COMMISSIONS_NET: 'Commisioni raccolte',
    COLLECTED_CHARGES: 'addebiti raccolti',
    TOTAL: 'Totale',
    AMOUNT: 'Ammontare',
    '{{ commission.fees ? \'Fees\' : commission.description }}': '',
    NEXT_COMM_INSTALMENT: 'Prossima rata comm',
    RECALCULATE_AND_SAVE: 'Ricalcola e Salva',
    CLOSE: 'Chiudi',
    ISSUE_STEPPER: 'Emissione',
    INSTALMENT_AT_INCEPTION_DATE: 'Rata alla data di lancio',
    TAXABLE: 'Imponibile',
    INSTALMENT_AT_INCEPTION_DATE_GROSS: 'Rata alla data di lancio lorda:',
    INSURANCE_TAX: 'Imposta d\'Assicurazione',
    RATE: 'Aliquota',
    TAXES: 'Tasse',
    ANTIRACKET: '(di cui Antiracket)',
    NEXT_INSTALMENT: 'Prossima rata',
    NEXT_INSTALMENT_GROSS: 'Prossima rata lorda:',
    '{{questionnaireFlat?.descriptionType}}': '',
    QUOTATION_DETAILS: 'Dettaglio premio',
    '{{ premiumDetail.sectionTitle }}': '',
    NET: 'Netto',
    ACCESSORIES: 'Accessori',
    INST_INTEREST: 'Interessi di frazionamento',
    'Damages proposal - Quotation': 'Proposta Danni -  Quotazione',
    '{{assetInstance.instanceDescription}}': '',
    PARAMETERS: 'Parametri di polizza',
    PROPOSAL_N: 'La Proposta n°',
    AUTHORIZATION_SEND: 'e una richiesta di autorizzazione è stata inviata',
    SAVING_QUOTE: 'Salvataggio preventivo',
    PROCEDE_TO_CHECKOUT: 'CONTINUA CON L\'ACQUISTO',
    CHECKOUT: 'Acquista',
    CODE: 'Codice',
    ACTION: 'Azione',
    NODE_LIST: 'Lista nodi',
    DRAG_SELECT: 'Drag/Selezion',
    COMPANY: 'Compagnia',
    POLICY_STATE: 'Stato contratto',
    INSURANCE_ASSETS: 'Beni Assicurati',
    SAVE_QUOTATION: 'Salva Preventivo',
    'Damages proposal - Summary': 'Proposta danni - Riepilogo',
    PROPOSAL_DATA: 'Dati amministrativi',
    OTHER_POLICY_DATA: 'Altri Dati di Polizza',
    SYSTEM_MESSAGE: 'MESSAGGIO DI SISTEMA',
    AN_ERROR_OCCURRED: 'Si è verificato un errore contattare il servizio clienti',
    ERROR_DURING_SUBJECT_UPDATE: 'Errore durante l\'update del soggetto',
    QUESTIONNAIRE_NOT_COMPLETE: 'Attenzione, il questionario non risulta completo. Compilare tutte le risposte per proseguire.',
    THE_CONTRACTOR_CONFIRMS_THE_VALIDITY: 'Il Contraente conferma la validità delle risposte fornite',
    PACKAGE: 'Pacchetti',
    POLICY_EXPIRE_DATE: 'Data Scadenza Polizza',
    NORMALIZE: 'Normalizza',
    ASSETS_DATA: 'Dati bene',
    REPRINT_POLICY_DOCUMENTS: 'Ristampa documenti Polizza n.',
    OUTCOME: 'Esito',
    AUTHORIZATION_REQUEST: 'Richiesta Autorizzazione',
    ADDRESS: 'Indirizzi',
    MAIN: 'Principale',
    DESCRIPTION: 'Descrizione',
    ADD_ADDRESS: 'Aggiungi indirizzo',
    POLICYHOLDER_COMMUNICATION_ADDRESSES: 'Recapito di Polizza',
    POSTAL_MAIL: 'POSTALE',
    CHANGE_ADDRESS: 'Modifica',
    EMAIL: 'DIGITALE',
    CUSTOMER_RESERVED_AREA: 'AREA RISERVATA CLIENTE',
    POLICYHOLDER_EMAIL: 'Email Contraente',
    POLICYHOLDER_RESIDENCE_ADDRESS: 'Residenza contraente',
    POLICYHOLDER_DELIVERY_ADDRESS: 'Indirizzo di consegna',
    OTHER_ADDRESS: 'Altro indirizzo',
    SUBSCRIPTION_INSTALMENT_TAXABLE: 'Rata Firma Imponibile :',
    INSTALEMENT_INCEPTION_DATE: 'Rata Firma',
    FPS_TAXES: 'Imposte LPS',
    SUBSCRIPTION_INSTALMENT_GROSS: 'Rata Firma Lordo :',
    NEXT_INSTALMENT_TAXABLE: 'Rata Successiva Imponibile :',
    ANNUAL_PREMIUM_TAXABLE: 'Premio Annuo Imponibile :',
    ANNUAL_PREMIUM_GROSS: 'Premio Annuo Lordo :',
    NEW: 'Nuovo',
    POLICY_CONTACT_ERROR: 'Inserire almeno un recapito di polizza.',
    POLICY_CONTACT_RESERVED_AREA: 'La documentazione sarà depositata nell\'area riservata cliente, senza invii né a mezzo posta ordinaria né tramite posta elettronica.',
    CLAUSE_DETAIL: 'Dettaglio Clausola',
    SAVE: 'SALVA',
    SAVE_CHANGES: 'Salva Modifiche',
    BODY_Binding_Mandatory: 'L\'ente vincolante è obbligatorio',
    PRODUCT_CODE: 'Codice prodotto',
    PRODUCT_DESCRIPTION: 'Descrizione prodotto',
    POLICY_EFFECT_DATE: 'Data effetto polizza',
    EMPTY: 'Svuota',
    DEBIT_MEAN_OF_PAYMENT: 'Mezzo di pagamento a debito',
    CREDIT_MEAN_OF_PAYMENT: 'Mezzo di pagamento a credito',
    ALTERNATIVE_POLICY_ADRESS: 'Inserire un recapito polizza alternativo',
    VALID_POLICY_EMAIL: 'Inserire un recapito email valido',
    YES: 'Si',
    MANDATORY_TEXT: 'Il testo della clausola è obbligatorio',
    MANDATORY: 'obbligatorio',
    SELECT_PAYMENT_METHOD: 'Seleziona un metodo di pagamento',
    ERROR_ON_DATE: 'Non è possibile proseguire in quanto la data di decorrenza deve essere antecedente alla data scadenza.',
    SELECT_CONTRACTOR: 'Seleziona un contraente',
    MANDATORY_REGULATION: 'Valorizzzare tutti i fattori obbligatori per la regolazione premio',
    BENEFICIARIES: 'Beneficiari',
    BENEFICIARY_TYPE: 'Tipo beneficiario',
    BENEFICIARY: 'Beneficiario',
    ADD_BENEFICIARIES: 'Aggiungi Beneficiari',
    ERROR_BENEFICIARY_NOT_SELECTED: 'Selezionare la tipologia di beneficiario',
    INSURED_THIRD_PERSON_ERROR: 'L\'assicurato deve essere un soggetto diverso dal referente rerzo',
    SUBSCRIBER_THIRD_PERSON_ERROR: 'Il contraente deve essere un soggetto diverso dal referente terzo',
    EDIT_BENEFICIARIES: 'Modifica Beneficiari',
    DELETE_BENEFICIARIES: 'Cancella Beneficiari',
    ERROR_BENEFICIARY_QUOTAS: 'La percentuale totale delle quote deve essere 100',
    ERROR_BENEFICIARY_MIN_PARTIES: 'Numero minimo di beneficiari richiesto',
    PERCENTAGE: 'Percentuale',
    THIRD_PARTY_SUBJECT: 'Referente Terzo',
    WARNING_BENEFICIARY_SUBJECT: 'Il soggetto selezionato è già stato censito come beneficiario, selezionare un altro soggetto.',
    WARNING_BENEFICIARY_THIRD_PERSON: 'Il soggetto selezionato è già stato censito come referente terzo, selezionare un altro soggetto.',
    WARNING_BENEFICIARY_LIMIT: 'Raggiunto numero massimo di beneficiari',
    WARNING_THIRDPERSON_SUBJECT: 'Il soggetto selezionato come Referente Terzo è già stato selezionato come contraente/assicurato/beneficiario, selezionare un altro soggetto.',
    USUAL_OCCASIONAL_RESIDENCE: 'RES.PRINCIPALE/SECONDARIA',
    INVALID_NUMBER: 'Formato non accettato',
    ANAG_BENEF: 'Beneficiari d\'Anagrafe',
    SPOUSE_INSURED: 'Coniuge dell\'assicurato',
    ERROR_FORMAT_DATE: 'Errore formato data',
    DATE1_AFTER_DATE2: 'la data scandenza è minore della data effetto',
    SELECT_NODE: 'Seleziona nodo',
    DATA_CANCELLATION_POLICY: 'Dati Annullo Polizza',
    CANCELLATION_REASON: 'Motivo Annullo',
    CANCELLATION_PREMIUM_REPAYMENT: 'L\'annullo prevede il rimborso premio',
    RIGHTS: 'Diritti',
    DATA_CANCELLATION: 'Dati Annullo',
    CONFIRMATION_CANCELLATION: 'Conferma Annullo',
    CANCELLATION_SUMMARY: 'Riepilogo Annullo',
    END: 'Fine',
    DOCUMENTS: 'Documenti',
    POLICY: 'Polizza',
    CANCELED_SUCCESSFULLY: 'annullata correttamente',
    TAXES_REPAYMENT: 'Rimborso tasse',
    TAXABLE_REPAYMENT: 'Rimborso Imponibile',
    CONFIRM_CANCELLATION: 'Confermi l\'annullo della polizza',
    CONFIRM_BENEFICIARIES_DELETE: 'Confermi l\'eliminazione dei beneficiari?',
    CURRENCY: 'Valuta',
    LIEN: 'Vincolo',
    LIENS: 'Vincoli',
    EMPTY_LIST_LIEN: 'Nessun elemento da visualizzare',
    ERROR_MESSAGE_DATES : 'La data effetto deve essere antecedente alla data di scadenza.',
    ERROR_MESSAGE_TYPE_LIEN : 'Soggetto non valido, Ente vincolante deve essere di tipo legale',
    ERROR_MESSAGE_NUMBER_BOND : 'Impossibile proseguire, aggiungere almeno un vincolo',
    NEW_LIEN: 'Nuovo Vincolo',
    EDIT_LIEN: 'Modifica Vincolo',
    BODY_BINDING: 'Ente vincolante',
    EFFECT_DATE: 'Data effetto',
    EXPIRY_DATE: 'Data scadenza',
    POSTED_NOTICE_DATE: 'Data Invio comunicazione Annullo',
    ISSUED_CORRECTLY: 'emessa correttamente',
    PREMIUM: 'Premio',
    FEES: 'Diritti',
    SELECT_ASSET: 'Seleziona bene',
    DELETE_ASSET: 'Eliminare il bene',
    ACTIVITY: 'Attività',
    SECONDARY_ACTIVITY: 'Attività Secondaria',
    ACTIVITY_NOT_FOUND: 'Nessuna attività trovata',
    SEARCH_ACTIVITY_BY: 'Cerca Attività per',
    VALUE: 'Valore',
    SEARCH: 'Cerca',
    NO_VALUE: '',
    PROPOSAL_REGULATION: 'Regolazione premio',
    REGULATION_EXPIRATION_DATE: 'Scadenza regolazione',
    REGULATION_FRACTIONING: 'Frazione di regolazione',
    REGULATION_TYPE: 'Tipo regolazione',
    REGULATION_DAYS: 'Giorni attesa ricezione dati',
    PERCENTAGE_OF_MIN_QUOTE_PREMIUM: '% premio min preventivo/consuntivo',
    LPS: 'LPS',
    EU_COUNTRY: 'Nazioni LPS',
    EXTRA_EU_COUNTRY: 'Paese extra UE',
    CHANGE_DATE: 'Data cambio',
    EXCHANGE_RATE: 'Tasso di cambio',
    USE_TAX: 'Uso imposta',
    CO_INSURANCE: 'Coassicurazione',
    CO_INSURANCE_DEP: 'Riparti Coass.',
    OUR_PERCENTAGE: 'Percentuale nostra',
    QUOTA_PERCENTAGE: 'Quota Percentuale',
    COMMISSION_PERCENTAGE: 'Provvigioni Percentuale',
    PERCENTAGE_TOTAL: 'Totale Percentuale',
    PERCENTAGE_ERROR: 'Percentuale non valida',
    POLICY_QUOTAS: 'Quote Polizza',
    NEW_QUOTA: 'Nuova Quota Coassicurazione',
    THIRD_PARTY_QUOTA: 'Dati polizza delegataria',
    QUOTA: 'Quota',
    INTERMEDIARY: 'Intermediario',
    LEADING_INSURER: 'Delegataria',
    NO_QUOTAS_ERROR: 'Non è stata inserita alcuna quota per la coassicurazione',
    LEAD_COINS_ERROR: 'La percentuale totale delle quote coassicurazione per Delega Nostra deve essere 100',
    RECALCULATE_UPDATE: 'Ricalcola e Aggiorna',
    RECALCULATE_DEFAULT_LPS_TAXES: 'Ricalcola imposte LPS default',
    TAX_ANTI_MONEY_LAUNDERING: 'Tassa anti riciclaggio',
    COMMON_RISK_POLICIES: 'Polizze Rischio Comune',
    COMPLEMENTARY_POLICIES: 'Polizze Complementari',
    COMPLEMENTARY_POLICY_DATA: 'Dati Polizza comune',
    SECOND_RISK_POLICIES: 'Polizze Secondo Rischio',
    LINKED_POLICIES: 'Polizze Collegate',
    NOTES: 'Note',
    INDIRECT_CO_INSURANCE_POLICIES_LIST: 'Elenco Polizze Indirette in Coassicurazione',
    INDIRECT_CO_INSURANCE_POLICIES: 'Polizze Coassicurazione Indiretta',
    SECOND_RISK_POLICIES_LIST: 'Elenco Polizze Secondo Rischio',
    ANNUAL_GROSS_PREM_BEFORE_CHARGES: 'Premio annuo (esclusi diritti)',
    FLEX_DISCOUNT_WITH_EROSION: 'Flessibilità con erosione montesconti',
    FLEX_DISCOUNT_WITHOUT_EROSION: 'Flessibilità senza erosione montesconti',
    FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR: 'Importo con erosione montesconti non valido. ',
    FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR: 'Importo senza erosione montesconti non valido. ',
    FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR: 'Percentuale con erosione montesconti non valida. ',
    FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR: 'Percentuale senza erosione montesconti non valida. ',
    FLEX_DISCOUNT_RANGE_ALLOWED_VALUE: 'Range di valori consentiti: ',
    FLEX_DISCOUNT_MAX_100: 'Inserire un valore minore del ',
    DISCOUNT: 'sconto',
    RESIDUAL_DISCOUNT: 'Sconto residuo',
    SHARED_DISCOUNTS_AMOUNT: 'Condividi montesconti',
    DISCOUNT_TYPE: 'Tipologia montesconti',
    PERIOD: 'Periodo',
    RESIDUAL_AMOUNT: 'Importo residuo',
    NO: 'No',
    START: 'Inizio',
    LIMIT: 'Massimale',
    ADD_POLICY: 'Aggiungi polizza',
    INPUT_PREMIUM: 'Modifica Premio',
    DELETE:'Elimina',
    ADD_CONTRACT:'Aggiungi Contratto',
    SUBSTITUTION_ERROR: 'Attenzione! Impossibile proseguire con la sostituzione: il contraente non è stato cambiato!',
    QUOT_COMMISSIONS: {
      LABEL: {
        ACCESSORIES: 'Accessori',
        AUTHORITY_LIMIT: 'Deroga Provvigioni',
        ALL_RISK: 'Tutti i rischi',
        ACQUIRED_CHARGES: 'Acc. Acq.',
        ACQUIRED_COMMISSION: 'Netto Acq.',
        AMOUNT: 'Importo',
        ANNUAL_PREMIUM: 'Premio Annuo',
        ANTIRACKET: '(Di cui antiracket)',
        COLLECTED_CHARGES: 'Acc. Inc.',
        COLLECTED_COMMISSION_NET: 'Netto Inc.',
        COMMISSIONS: 'Provvigioni',
        CONTRIBUTIONS_TAXES: 'Contributi e tasse',
        GROSS: 'Lordo',
        INCEPTION_INSTALLMENT: 'Rata Firma',
        INSTALLMENT_INTEREST: 'Interessi di frazionamento',
        INSTALLMENT_PREMIUM: 'Premio di rata',
        INSURANCE_TAX: 'Imposta d\'Assicurazione',
        NET: 'Netto',
        PREMIUM_DETAIL: 'Dettaglio Premio alla firma',
        RATE: 'Aliquota',
        RIGHTS: 'Diritti',
        SIGNED_COMMISSIONS: 'Provvigioni alla firma',
        DEROGATED: 'Der.',
        NEXT_INSTALMENT_COMMISSIONS: 'Provvigioni rate succ.',
        SSN: 'SSN',
        TAXABLE: 'Imponibile',
        TAXES: 'Tasse',
        TOTAL: 'Totale',
        VIEW: 'Visualizza',
        ASSIGN: 'Assegna',
        ACQUIRED_COMMISSIONS_MANDATE: 'Regime Provv. d\'Acquisto'
      }
    },
    FPS_LABEL: {
      NEXT_INSTALMENT: 'Rata successiva',
      INCEPTION_INSTALLMENT: 'Rata Firma',
    },
    INDEXATION: 'Indicizzazione',
    QUOTA_ALREADY_EXISTING: 'Quota già esistente',
    CO_POLICYHOLDER_SUBSCRIBER_ERROR: 'Il cointestatario deve essere un soggetto diverso dal Contraente',
    CO_POLICYHOLDER_JOINT_SUBSCRIBER_ERROR: 'Il cointestatario deve essere un soggetto diverso dai Cointestatari',
    COMMON_RISK_POLICIES_LIST: 'Elenco Polizze Rischio Comune',
    COMMON_POLICY_DATA: 'Dati Polizza comune',
    LINKED_POLICIES_LIST: 'Elenco Polizze Collegate',
    VALID_POLICY: 'Polizza Valida',
    INVALID_POLICY: 'Polizza Non Valida',
    INVALID_POLICY_NUMBER: 'Il formato di numero polizza inserito non è valido',
    POLICY_ALREADY_ADDED_INDIRECT_COINSURANCE : 'La polizza è già stata aggiunta In Coassicurazione Indiretta',
    POLICY_ALREADY_ADDED_SECOND_RISK : 'La polizza è già stata aggiunta in Secondo Rischio',
    POLICY_ALREADY_ADDED : 'La polizza è già stata aggiunta',
    NO_POLICY_ADDED : 'Non è stata aggiunta alcuna polizza',
    AMOUNT_NOT_VALID: 'Valore importo non valido',
    GENERIC_SYSTEM_ERROR: 'Errore interno al sistema',
    PRE_QUOTATION_CLAUSES_AND_FACTORS: 'Fattori e Clausole di Pre-quotazione',
    SAVE_AND_CLOSE: 'Salva e chiudi',
    FIND: 'TROVA',
    SELECT_A_PACKAGE: 'Selezionare un pacchetto',
  }
};

import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[licPercentageDirective]'
})
export class PercentageDirective implements OnInit {
  element: ElementRef;

  constructor(el: ElementRef) {
    this.element = el;
  }

  ngOnInit() {
    if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== '') {
      this.element.nativeElement.value = this.element.nativeElement.value.replace('.', ',');
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== '') {
      this.element.nativeElement.value = this.element.nativeElement.value.replace('.', ',');
    } /* else {
      this.element.nativeElement.value = '0,0';
    } */
  }

  @HostListener('keypress', ['$event'])
  onkeydown(event) {
    const value = event.key;
    const fieldVal = this.getNewString(event);
    console.log(fieldVal);
    const regexForKeyPress = /[\d\.,]/;
    if (regexForKeyPress.test(value)) {
      const regexForValue = /^(\d{0,2})+([\.,]\d{0,2})?$/;
      if (regexForValue.test(fieldVal)) {
        /* if (+fieldVal.replace(',', '.') <= 100 && +fieldVal.replace(',', '.') > 0) { */
          return true;
        /* } */
      }
    }

    return false;
  }

  getNewString(event) {
    const valoreAttualeInput = event.srcElement.value;
    const key = event.key;
    const splitValoreAttualeInput = valoreAttualeInput.split('');
    splitValoreAttualeInput.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), key);
    const nuovoValore = splitValoreAttualeInput.join('');
    return nuovoValore;
  }

  @HostListener('keyup', ['$event.key'])
  onkeyup(value) {
    this.element.nativeElement.value = this.element.nativeElement.value.replace('.', ',');
    return true;
  }

}

// dialog component delete
import {OnModalClose, RgiRxDateTimeChange} from '@rgi/rx/ui';
import {ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {DocumentCategoryEntity, DocumentSubcategoryEntity} from '../dto/document-category-entity';
import {DamageRequest, DocumentEntity} from '../dto/document-entity';
import {ClaimDocumentsService} from '../claim-documents.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RgiRxFileReaderService, RgiRxValidators} from '@rgi/rx';
import {Observable, of, Subscription} from 'rxjs';
import {FileEntity} from '../dto/file-entity';
import {ApiClaim, ApiParty} from '@rgi/digital-claims-common-angular';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-ui-modal-dialog-upload-document',
  templateUrl: './upload-document-dialog.component.html',
})
export class UiModalDialogUploadDocumentComponent implements OnModalClose, OnInit, OnDestroy {
  modalClose: EventEmitter<any>;
  uploadForm: FormGroup;
  party: ApiParty;
  category: DocumentCategoryEntity;
  subcategory: DocumentSubcategoryEntity;
  ngModelImageURL: string;
  uploadedFiles: Array<File>;
  claim: ApiClaim;
  isUploadPotential: boolean;
  filteredDamageRequests: DamageRequest[];
  presentDamageRequests: boolean = false;
  claimCurrent: any;
  maxFileSize: number;
  isReceptionDateRequired: boolean;
  isReceptionDateForLegal: boolean;
  isDamageRequestCategory: boolean;
  private readFileSubscription: Subscription = Subscription.EMPTY;

  SUBCAT_TESTIMONIANZA_DI_PARTE: string = 'C012';
  SUBCAT_TESTIMONIANZA_DI_CONTROPARTE: string = 'C015';

  TESTIMONE_A_FAVORE: string = '11';
  TESTIMONE_DI_CONTROPARTE: string = '12';
  ngModelDatePicker: any;
  isReceptionDateValid: boolean = false;

  receiptDateForm = new FormGroup({
    receiptDatePickerFormField: new FormControl(new Date()),
    // datePickerTriggered: new FormControl(new Date()),
  });


  constructor(
    @Inject('authService') private auth,
    @Inject('eventService') private eventService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    public utilityService: ClaimDocumentsService,
    public formBuilder: FormBuilder,
    private fileReader: RgiRxFileReaderService,
    private cdr: ChangeDetectorRef,
    public translateService: RgiRxTranslationService
  ) {
  }

  @Output() public modalUploadEmitter: EventEmitter<any> = new EventEmitter<any>();

  onCloseModalUploadDocument() {
    this.modalUploadEmitter.emit({modalMsg: 'MODAL_CLOSED'});
  }

  ngOnDestroy(): void {
    this.utilityService.setUploadSubcategory(null);
    this.utilityService.setUploadCategory(null);
    if (this.utilityService.getSysPropShowParties()) {
      this.utilityService.setUploadParty(null);
    }
  }

  // before opening modal check witness role
  checkWitnessDocSubcategory(partyId: string, subcategory: string) {
    // extract party from json claim
    const invParties: any = this.claimCurrent.claimParties;
    const notInvParties: any = this.claimCurrent.claimNotInvolvedParties ? this.claimCurrent.claimNotInvolvedParties : [];
    const objParties = invParties.concat(notInvParties);
    const chosenParty = objParties.filter((v, idx, arr) => v.identifier === this.party.identifier)[0];

    if (chosenParty && subcategory) {
      if ((chosenParty.partyRole.codice !== this.TESTIMONE_A_FAVORE &&
          subcategory.toString() === this.SUBCAT_TESTIMONIANZA_DI_PARTE) ||
        (chosenParty.partyRole.codice !== this.TESTIMONE_DI_CONTROPARTE &&
          subcategory.toString() === this.SUBCAT_TESTIMONIANZA_DI_CONTROPARTE)) {
        return true;
      }
    }
  }

  getValidSubcategories(x: DocumentCategoryEntity) {
    return x.subcategories.filter((value, idx, arr) => !value.isDeprecated);
  }


  onDateTimeChange($event: any) {
    console.log($event);
    // check su validità formato
    const tmp = $event.value.toLocaleDateString();
    // check 3 sections
    const tmpArr = tmp.split('/');
    // tslint:disable-next-line:max-line-length
    // const reg = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
    // eng format MM-DD-YYYY
/*    let reg = /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/(?:[0-9]{2})?[0-9]{2}/;
    if (navigator.language.includes('it')) {
      reg = /^(3[01]|[12][0-9]|0?[1-9])\/(1[0-2]|0?[1-9])\/(?:[0-9]{2})?[0-9]{2}/;
    }
    if (!reg.test(tmp)) {
      this.isReceptionDateValid = false;
      return;
    }*/

    const val: Date | Date[] = ($event.value);
    if (val instanceof Date) {
      if ($event.source.value > $event.source.max) {
        this.isReceptionDateValid = false;
        return;
      }

      const day = val.getDate().toString().padStart(2, '0');
      const month = (val.getMonth() + 1).toString().padStart(2, '0');
      const year = val.getFullYear().toString();
      const res = day + '/' + month + '/' + year;
      this.uploadForm.setControl('receiptDateFormatted', new FormControl(res));
      this.isReceptionDateValid = true;
    }

  }

  onDateTimeInput($event: any) {
    console.log($event);
    const val: Date | Date[] = ($event.value);
    if (val instanceof Date) {
      const day = val.getDate().toString().padStart(2, '0');
      const month = (val.getMonth() + 1).toString().padStart(2, '0');
      const year = val.getFullYear().toString();
      const res = day + '/' + month + '/' + year;
      this.uploadForm.setControl('receiptDateFormatted', new FormControl(res));
      // this.uploadForm.setControl('receiptDate', new FormControl(res));
    }
  }

  checkSubcategoryFiles() {
    if (this.uploadedFiles.length === 0 || this.subcategory == null) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {

    this.isUploadPotential = this.utilityService.getIsUploadPotential();

    const catTemp: DocumentCategoryEntity = this.utilityService.getUploadCategory();
    // deep copy. we dont want the upload dialog to mismanage the category and subcategory object
    this.category = new DocumentCategoryEntity();
    this.category.elementDescription = catTemp.elementDescription;
    this.category.subcategories = catTemp.subcategories.map(o => o);
    this.category.elementId = catTemp.elementId;
    this.category.isDeprecated = Boolean(catTemp.isDeprecated);
    this.category.type = String(catTemp.type);
    const tmpSubcat: DocumentSubcategoryEntity = this.utilityService.getUploadSubcategory();
    if (tmpSubcat) {
      this.subcategory = new DocumentSubcategoryEntity();
      this.subcategory.elementId = tmpSubcat.elementId;
      this.subcategory.type = String(tmpSubcat.type);
      this.subcategory.elementDescription = String(tmpSubcat.elementDescription);
      this.subcategory.isDeprecated = Boolean(tmpSubcat.isDeprecated);
    }
    // end deep copy

    // if the upload is called from a category (and not a subcategory) that has subcategories
    if (this.subcategory == null && this.category.subcategories.length) {
    } else if (this.subcategory == null && !this.category.subcategories.length) {
      // if the upload is called from a category (and not a subcategory) that has NO subcategories
       // this.subcategory = null;
    } else if (this.subcategory != null) {
      this.category.subcategories = this.category.subcategories
        .filter((value, idx, arr) => value.elementId !== this.subcategory.elementId);
    }
    this.claimCurrent = this.utilityService.getJsonClaim();

    if (this.utilityService.getSysPropShowParties()) {
      this.party = this.utilityService.getUploadParty();

      // select party
      const invParties: any = this.claimCurrent.claimParties;
      const notInvParties: any = this.claimCurrent.claimNotInvolvedParties ? this.claimCurrent.claimNotInvolvedParties : [];
      const objParties = invParties.concat(notInvParties);
      const chosenParty = objParties.filter((v, idx, arr) => v.identifier === this.party.identifier)[0];
      let damagesRequests = [];
      this.filteredDamageRequests = [];
      if (chosenParty) {
        damagesRequests = chosenParty ? chosenParty.damagesRequests : this.claimCurrent.requestsDamages;
        if (damagesRequests) {
          for (const dmg of damagesRequests) {
            // console.log(dmg);
            if (!dmg.idDocument) {
              if (!this.claimCurrent.linkedDamageRequests ||
                this.claimCurrent.indexOf(dmg.idClaimOfDamages) !== -1) {
                // damagesRequests.push(dmg);
                const dmgReq: DamageRequest = {
                  idDocument: dmg.idDocument,
                  receiptDate: dmg.receiptDate,
                  idClaimOfDamages: dmg.idClaimOfDamages
                };
                this.filteredDamageRequests.push(dmgReq);
                this.presentDamageRequests = true;
              }
            }
          }
        }

        console.log('FILTERED DAMAGE REQUESTS ', this.filteredDamageRequests);
        for (const dmg of this.filteredDamageRequests) {
          console.log(dmg);
        }

      }
    }

    // check if party has related requestsDamage
    // extract from clain object

    if (this.utilityService.getUploadSubcategory() && this.utilityService.getSysPropShowParties()) {
      if (this.checkWitnessDocSubcategory(this.party.identifier,
        this.utilityService.getUploadSubcategory().elementId)) {
        this.eventService.broadcastEvent('alertMsg',
          {varMsg: 'In order to attach witness declaration document must be selected a party with witness role'});
        this.modalUploadEmitter.emit({modalMsg: 'MODAL_CLOSED'});

      }
    }


    // get max file size
    this.getSystemProperty.findProperty('ClaimsDossierMaxByteUpload').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsDossierMaxByteUpload') {
            this.maxFileSize = variable.value;
          }
        }
      }
    });

    // check if reception date is required
    this.getSystemProperty.findProperty('ClaimsDocCatWithReceptionDate').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsDocCatWithReceptionDate') {
            const codes = variable.value.split(';');
            for (const code of codes) {
              if (this.category.elementId.toString() === code) {
                this.isReceptionDateRequired = true;
                if (this.uploadForm) {
                  this.uploadForm.setControl('receiptDate', new FormControl(''));
                  this.uploadForm.setControl('receiptDateFormatted', new FormControl(''));
                }
              }
            }
          }
        }
      }
    });

    // check if is damage request category
    this.getSystemProperty.findProperty('ClaimsDamageReqDocCategoryCode').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsDamageReqDocCategoryCode') {
            const codes = variable.value.split(';');
            for (const code of codes) {
              if (this.category.elementId.toString() === code) {
                this.isDamageRequestCategory = true;
                if (this.uploadForm) {
                  this.uploadForm.setControl('linkedDamage', new FormControl(''));
                }
              }
            }
          }
        }
      }
    });


    /*if (this.subcategory != null) {
      this.category.subcategories = this.category.subcategories
        .filter((value, idx, arr) => value.elementId !== this.subcategory.elementId);
    } else {
      // if the subcategory is null, it's the category itself
      this.subcategory = this.category;
    }*/
    this.uploadForm = this.formBuilder.group({
      subcategory: this.subcategory ? this.subcategory.elementId : [],
      uploadNote: [],
      subtitle: [],
      numPages: [],
      assetType: [],
      files: [],
      toSendEBDS: false,
      alertNewDoc: false,
      receptionDate: [],
    });
    if (this.isReceptionDateRequired || this.isReceptionDateForLegal) {
      this.uploadForm.addControl('receiptDate', new FormControl([]));
      // initialize with today's date
      const today = new Date();
      const day = today.getDate().toString().padStart(2, '0');
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const year = today.getFullYear().toString();
      const res = day + '/' + month + '/' + year;
      this.uploadForm.addControl('receiptDateFormatted', new FormControl(res));
      this.isReceptionDateValid = true;
    }

    this.uploadedFiles = new Array<File>();
  }

  onNgModelChange($event: any) {

    this.readFileSubscription = this.fileReader.readAsDataURL($event).pipe(
    ).subscribe(
      next => {
        if (next.status === 'complete') {
          this.ngModelImageURL = next.data;
          console.log(next);
        }
      }
    );
  }

  allowDrop(ev: Event) {
    ev.preventDefault();
  }

  drag(ev) {
    console.log(ev.target);
    // ev.dataTransfer.setData("text", ev.target.id);
  }

  drop(ev: DragEvent) {
    ev.preventDefault();
    // const f: File = ev.dataTransfer.files[0];
    // @ts-ignore
    for (const x: File of ev.dataTransfer.files) {
      if (x.size > this.maxFileSize) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._MAXIMUM_FILE_SIZE_EXCEEDED')
        .subscribe(
          res =>  this.eventService.broadcastEvent('alertMsg', {varMsg: res})
        );
      return;
      }
      this.uploadedFiles.push(x);
    }

  }

  onFileRemoved($event: File) {
    this.uploadedFiles = this.uploadedFiles.filter((value, index, array) => value.name !== $event.name);
  }

  onFileUploaded($event: Event) {
    const file = (($event.target) as HTMLInputElement).files[0];

    // check file size
    if (file.size > this.maxFileSize) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._MAXIMUM_FILE_SIZE_EXCEEDED')
        .subscribe(
          res =>  this.eventService.broadcastEvent('alertMsg', {varMsg: res})
        );
      return;
    }

    if (file.size === 0) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._FILE_IS_EMPTY')
      .subscribe(
        res =>    this.eventService.broadcastEvent('alertMsg', {varMsg: res})
      );
    return;
    }

    this.uploadedFiles.push(file);
    const target = event.target as HTMLInputElement;
    target.value = null;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  })

  async onConfirmUploadFiles() {
    // convert files to document-entity
    this.eventService.broadcastEvent('start-loader');
    const companyCode = this.utilityService.getCompanyCode();
    const jsonClaim = this.utilityService.getJsonClaim();
    // check if activity notifytakeincharge is available
    let hasTaskNotifyTakeInCharge = false;
    if (jsonClaim.workflowProcess != null && jsonClaim.workflowProcess.length) {
      const processes = jsonClaim.workflowProcess;
      hasTaskNotifyTakeInCharge = processes.some(o => o.processName === 'NotifyTakeInCharge');
    }

    // tslint:disable-next-line:max-line-length
    const setDocumentViewed = (this.utilityService.getIntegrationWF() && this.utilityService.getIntegrationWF().params.filter(o => o.name === 'NomeProcesso' && o.stringValue === 'NotifyTakeInCharge')) ? true : false;

    const user = this.auth.getUsername() ? this.auth.getUsername() : null;
    const arr: Promise<DocumentEntity>[] = this.uploadedFiles.map(async (value, index, array) => {
      const doc = new DocumentEntity();
      const file = new FileEntity();
      doc.numPages = this.uploadForm.controls.numPages.value;
      doc.uploadNote = this.uploadForm.controls.uploadNote.value;
      if (this.uploadForm.controls.receptionDateFormatted && this.uploadForm.controls.receptionDateFormatted.value){
        doc.receptionDate = this.uploadForm.controls.receiptDateFormatted.value;
      }
      doc.subtitle = this.uploadForm.controls.subtitle.value;
      doc.assetType = this.uploadForm.controls.assetType.value;
      doc.toSendEBDS = this.uploadForm.controls.toSendEBDS.value;
      doc.fileName = value.name;
      if (this.utilityService.getSysPropShowParties()) {
        doc.codAnagrafe = this.party.identifier;
      }
      doc.category = this.category.elementId.toString();
      doc.mimetype = value.type;
      doc.microCategory = this.uploadForm.controls.subcategory.value;
      file.fileSize = value.size;
      file.mimeType = value.type;
      file.fileName = value.name;
      const binary: any = await this.toBase64(value);
      if (binary) {
        const indexSub = binary.indexOf('base64,') + 'base64,'.length;
        const binarySub = binary.substr(indexSub);
        file.binary = binarySub;
      }
      doc.file = file;
      return doc;
    });

    const resolved = await Promise.all(arr);

    this.utilityService.uploadDocuments(resolved, this.uploadForm, companyCode, user, setDocumentViewed).subscribe(
      (res) => {
        // if damage request has been associated with a document, must reload claim
        this.modalUploadEmitter.emit({modalMsg: 'MODAL_UPLOAD_FINISHED', response: res});
        // set documents viewed if task is present and we are in workflow
        if (setDocumentViewed) {
          this.eventService.broadcastEvent('DocumentViewedChanged', {viewed: true});
          this.utilityService.getFormViewDocuments().setControl('areDocumentsViewed', new FormControl(true));
          this.utilityService.setAreDocumentsViewed(true);
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  getMaxDay(): Date {
    return new Date();
  }

  handleDateTimeChange($event: RgiRxDateTimeChange<Date>) {
    console.log($event);
    const val: Date | Date[] = ($event.value);
    if (val instanceof Date) {
      const day = val.getDate().toString().padStart(2, '0');
      const month = (val.getMonth() + 1).toString().padStart(2, '0');
      const year = val.getFullYear().toString();
      const res = day + '/' + month + '/' + year;
      this.uploadForm.setControl('receiptDateFormatted', new FormControl(res));
      // this.uploadForm.setControl('receiptDate', new FormControl(res));
    }

  }


  handleKeyDown($event: KeyboardEvent) {
    $event.preventDefault();
  }
}

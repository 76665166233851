<div [ngClass]="{'nav-container': !isResponsive}" (window:resize)="onResize($event)">

    <ng-container *ngIf="!isResponsive">
        <div class="lic-cards-box">
            <div class="card-item" 
                 [attr.data-qa]="'routes-'+i" 
                 *ngFor="let step of routes; let i = index" 
                 [ngClass]="{'active': step.enabled, 'inactive': !step.enabled, 'step-current': step.current, 'step-blocked': step.blocked, 'step-completed': currentRouteName === 'issue'}" 
                 (click)="navigateTo(step.name)">
                <a class="card-link" data-slide-index="i">
                    <span class="lic-step-number">{{i + 1}}</span>
                    <span class="lic-step-title">{{step.label}}</span>
                </a>
                <div class="step-arrow" *ngIf="!isLastStep(i)"></div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isResponsive">
        <div class="mobile-box">
            <div *ngFor="let step of routes; let i = index">
                <ng-container *ngIf="step.current">
                    <div class="mobileActive">
                        <div class="mobileStep" (click)="navigateTo(step.name)">
                            <a class="card-link" data-slide-index="i">
                                <span class="lic-step-number">{{i + 1}}</span>
                                <span class="lic-step-title">{{step.label}}</span>
                            </a>
                            <!--<span class="openNavbar" (click)="toggleResponsiveMenu()">cliccami</span>-->
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="displayPreviousStep(step.name)">
                    <div class="mobileInactive">
                        <div class="mobileStep" (click)="navigateTo(step.name)">
                            <a class="card-link" data-slide-index="i">
                                <span class="lic-step-number">{{i + 1}}</span>
                                <span class="lic-step-title">{{step.label}}</span>
                            </a>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
    </ng-container>

</div>

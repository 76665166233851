<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    {{'_RGIPNC_._LABEL_._POLICY_DETAILS_'| translate}}
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="primary" (click)="modalClose.emit()" translate>
      {{'_RGIPNC_._BTN_._CLOSE_' | translate}}
    </button>
  </rgi-rx-panel-footer>

  <rgi-rx-datatable rgi-rx-qa="my-table" qa-ctx="my-app-context"
                    [data]="data.tableRows" [schema]="data.schema"
                    [expansionRow]="taxesDetail"
                    [expansionModel]="expansionModel"
                    [pageOptions]="[data.tableRows.length]"
                    class="rgipc-table-taxes-detail">

    <ng-template rgiRxDataTableCell="ASSETS_RISKS" let-ctx>
      <div [ngClass]="{'rgipc-pl-l': ctx.row.isRisk}">
        {{ ctx.row.ASSETS_RISKS }}
      </div>
    </ng-template>

    <ng-template rgiRxDataTableCell="TOTAL_TAXES" let-ctx>
      <div class="rgipsales-flex">
        <div class="rgi-ui-datatable-expansion-cell">
          <i role="button" class="rgi-ui-expansion-cell-open" *ngIf="!expansionModel.isExpanded(ctx.row)"
             [attr.aria-controls]="'rgi-rx-table-expansion-cell-'+ctx.index"
             [attr.aria-label]="'RGI_RX.DATATABLE.EXPAND_ROW_LABEL' | translate : {index: (ctx.index+1)}"
             (click)="expansionModel.expand(ctx.row)"
          ></i>
          <i role="button" class="rgi-ui-expansion-cell-close" *ngIf="expansionModel.isExpanded(ctx.row)"
             [attr.aria-controls]="'rgi-rx-table-expansion-cell-'+ctx.index"
             [attr.aria-label]="'RGI_RX.DATATABLE.COLLAPSE_ROW_LABEL' | translate : {index: (ctx.index+1)}"
             (click)="expansionModel.contract(ctx.row)"></i>
        </div>
        <div class="rgipsales-flex-gr-1">
          {{ ctx.row.TOTAL_TAXES | rgiCountryLayerNumberFormat:undefined:{
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        } }}
        </div>
      </div>
    </ng-template>


  </rgi-rx-datatable>

  <ng-template #taxesDetail let-row>
    <div class="rgipc-vp-l">
      <rgi-pnc-psales-taxes-detail [taxes]="row.taxesDetail"></rgi-pnc-psales-taxes-detail>
    </div>
  </ng-template>

</rgi-rx-panel>

<div class="row" *ngIf="showTitle">
  <div class="card-title">
    <span class="menu-title" *ngIf="showTitleTotal">{{ '_CLAIMS_._SIMILAR_COMPLAINTS' | translate }} </span>
  </div>
  <div class="card-title-total">
    <span>{{ '_CLAIMS_._TOTAL' | translate }}: {{ totalClaims }} </span>
  </div>
</div>

<rgi-rx-datatable
  [data]="dataSourceSimilarClaims" [schema]="schema"
  (onAction)="onActionClick($event)"
  [selectable]="false"
  [disablePaginator]="false"
  [expansionRow]="rowDetail"
  [pageOptions]="[5,10,20,50]">
</rgi-rx-datatable>
<ng-template #rowDetail let-claim>
  <div class="row mbc-2">
    <div class="col-xs-12 col-md-12">
      <label ng-bind="label" class="labelBold titleSection">{{ '_CLAIMS_._COMPLAINT_DATA' | translate }}:</label>
    </div>
  </div>


  <div class="row">
    <div class="col-xs-6 col-md-6">
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._COMPLAINT_DATE' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.dataDenuncia ? (claim.dataDenuncia |  date:'dd/MM/yyyy') : '/' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._DATA_FORWARDING_CDL ' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.dataInoltroCdl ? (claim.dataInoltroCdl |  date:'dd/MM/yyyy') : '/' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._INTERMEDIARY' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.nodoPolizza ? claim.nodoPolizza : '-' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._MASTER_POLICY_NUMBER' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.numeroPolizzaMadre ? claim.numeroPolizzaMadre : '-' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._APPLICATION_NUMBER' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.numeroApplicazione ? claim.numeroApplicazione : '-' }}
          </label>
        </div>
      </div>
    </div>
    <div class="col-xs-6 col-md-6 lineSmall">
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._COMPLAINT_STATE ' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.stato ? claim.stato.descrizione : '-' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._PLATE__CHASSIS' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.targaTelaio ? claim.targaTelaio : '-' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._PRODUCT' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.prodotto ? claim.prodotto : '-' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._TECHNICAL_COVERAGE' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.coperturaTecnica === null ? '-' : claim.coperturaTecnica ? 'Si' : 'No' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._ACCOUNTING_COVERAGE' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{ claim.coperturaAmministrativa === null ? '-' : claim.coperturaAmministrativa ? 'Si' : 'No' }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-3 col-md-3">
          <label ng-bind="label mrc-0-4">
            {{ '_CLAIMS_._COUNTERPARTS' | translate }}:</label>
        </div>
        <div class="col-xs-8 col-md-8">
          <label ng-bind="label" class="labelBold">
            {{claim.controparti}}
          </label>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="row">
    <div class="col-xs-2 col-md-2">
      <label ng-bind="label mrc-0-4">
        {{ 'Complaint date' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ claim.dataDenuncia? (claim.dataDenuncia.date |  date:'dd/MM/yyyy') : '/' }}
      </label>
    </div>
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'Claim branch' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ claim.claimBranch }}
      </label>
    </div>
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'Company' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ claim.companyCode }}
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'Handling Centre' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ claim.ispettorato }}
      </label>
    </div>
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'Policy holder' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ claim.contraente }}
      </label>
    </div>
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'Adjuster' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ claim.liquidatore }}
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'License plate' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ valuateStringFieldForVisual(claim.targaTelaio) }}
      </label>
    </div>
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'Occurence description' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ valuateStringFieldForVisual(claim.descrizioneAccadimento) }}
      </label>
    </div>
    <div class="col-xs-4 col-md-4">
      <label ng-bind="label mrc-0-4">
        {{ 'Product' | translate}}:</label>
      <label ng-bind="label" class="labelBold">
        {{ claim.prodotto }}
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <label ng-bind="label mrc-0-4">
        {{ 'Info Causa' | translate}}:</label>
      <label ng-bind="label" *ngFor="let infoCausa of valuateArrayFieldForVisual(claim.lawsuitInfo)"
      class="infoCausa">
        {{ infoCausa }}
      </label>
    </div>
  </div> -->
</ng-template>

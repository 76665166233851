import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AnagAddressLayout, AnagAddressLayoutConfig} from '../../anag-model/anag-domain/anag-address-config';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagCountry} from '../../anag-model/anag-domain/anag-country';
import {AnagUmaAddressService} from './anag-uma-address.service';
import {
  ADDRESS_FORM_CONFIG,
  ADDRESS_LAYOUT_VISIBLE,
  ADDRESS_STATE_MANDATORY,
  CORE_ADDRESS_FUNCTION
} from '../../anag-constants/anag-constants';
import {AnagIdentificationEntity} from '../../anag-model/anag-domain/anag-identification-entity';
import {AnagApiEntity, AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {TOPONYMS_ENUM} from '../../anag-constants/enums-constants';
import {AnagApiAddress} from '../../anag-model/anag-domain/anag-api-party';
import {PushMessageHandlerService, RgiRxEventService} from '@rgi/rx';
import {Observable, of, Subscription} from 'rxjs';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {NORMALIZED_ADDRESSES_TABLE_SCHEMA} from './anag-uma-address-tableschema';
import {RgiRxDatatableRowAction, TableSchema} from '@rgi/rx/ui';
import {distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import {OperatorLight} from '../../services/external-service';
import {RgiRxUserService} from '@rgi/rx/auth';
import {ExtendedFilter} from '../../anag-model/anag-api/anag-api-subapp-adminlevel';

@Component({
  selector: 'rgi-anag-uma-address',
  templateUrl: './anag-uma-address.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagUmaAddressComponent implements OnInit, OnDestroy {

  @Input()
  addressType: string = CORE_ADDRESS_FUNCTION.partySubjectResidence;

  @Input()
  inputAddress: AnagApiAddress;

  @Input()
  isQuote: boolean = false;

  @Input()
  partyRole: string;

  @Output()
  outputAddress: EventEmitter<AnagApiAddress> = new EventEmitter();

  @Input()
  reloadEvent: Observable<void>;

  @Input()
  addressReqListener: Observable<any>;

  @Input()
  hideNormalize: boolean;

  @Input()
  emitOutputAddress: Observable<void>;

  @Input() parentForm: UntypedFormGroup;

  private subscriptions = new Subscription();

  addressConfig: AnagAddressLayoutConfig = new AnagAddressLayoutConfig();
  orderedAddressInputMap: Map<string, AnagAddressLayout>;
  orderedAddressInputMapClone = new Map<string, AnagAddressLayout>();
  orderedAddressInputKeyList: Array<string> = [];
  adminLevel1Values: Array<AnagIdentificationEntity>;
  adminLevel2Values: Array<AnagIdentificationEntity>;
  adminLevel3Values: Array<AnagIdentificationEntity>;
  zipCodes: Array<AnagIdentificationEntity>;
  filteredLvl1Values: Array<AnagIdentificationEntity>;
  filteredLvl2Values: Array<AnagIdentificationEntity>;
  filteredLvl3Values: Array<AnagIdentificationEntity>;
  filteredZipCodes: Array<AnagIdentificationEntity>;
  filteredValuesMap: Map<string, Array<AnagApiEntity | AnagEntityIta>>;

  normalizedAddresses: Array<AnagApiAddress>;
  normalizerServiceError: string;
  noNormalizedAddressFounded: boolean;
  normalizedAddressesTableData: any [] = [];
  showNormalizedAddressesResults = false;
  offsetAlternativeRole = 0;
  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  NORMALIZED_ADDRESSES_TABLE_SCHEMA: TableSchema = NORMALIZED_ADDRESSES_TABLE_SCHEMA;
  ADDRESS_FORM_CONFIG = ADDRESS_FORM_CONFIG;


  addressForm = new UntypedFormGroup({
    countryCode: new UntypedFormControl(undefined),
    adminLevel1: new UntypedFormControl(undefined),
    adminLevel2: new UntypedFormControl(undefined),
    adminLevel3: new UntypedFormControl(undefined),
    adminLevel3Short: new UntypedFormControl(undefined),
    zip: new UntypedFormControl(undefined),
    locality: new UntypedFormControl(undefined),
    subLocality: new UntypedFormControl(undefined),
    subLocalityCode: new UntypedFormControl(undefined),
    toponym: new UntypedFormControl(undefined),
    address: new UntypedFormControl(undefined),
    postalAddress: new UntypedFormControl(undefined),
    houseNumber: new UntypedFormControl(undefined),
    cco: new UntypedFormControl(undefined),
    estensione: new UntypedFormControl(undefined),
    specialMention: new UntypedFormControl(undefined),
    addressAddition: new UntypedFormControl(undefined),
    formatAddress: new UntypedFormControl(undefined)
  });

  defaultCountryCode: string;

  constructor(
    public anagStorage: AnagStorageService,
    public addressService: AnagUmaAddressService,
    public eventService: RgiRxEventService,
    public anagStatelessService: AnagStatelessService,
    protected userService: RgiRxUserService,
    public pushMessageHandler: PushMessageHandlerService
  ) {
    this.defaultCountryCode = this.userService.getUser<OperatorLight>().salePoint ?
      this.userService.getUser<OperatorLight>().salePoint.context : 'IT';
  }

  async ngOnInit() {
    // filteredValuesMap must have all AUTOCOMPLETE or SELECT types defined on ADDRESS_FORM_CONFIG
    this.filteredValuesMap = new Map<string, Array<AnagApiEntity | AnagEntityIta>>([
      ['adminLevel1', this.filteredLvl1Values],
      ['adminLevel2', this.filteredLvl2Values],
      ['adminLevel3', this.filteredLvl3Values],
      ['zip', this.filteredZipCodes],
      ['toponym', this.toponyms]]);

    this.offsetAlternativeRole = await this.addressService.offsetAlternativeRole();

    if (this.parentForm) {
      this.parentForm.setControl('addressForm', this.addressForm);
    }
    if (!this.inputAddress && this.defaultCountryCode) {
      this.inputAddress = new AnagApiAddress();
      this.inputAddress.countryCode = this.defaultCountryCode;
    }
    if (this.inputAddress) {
      this.inputAddress = this.anagStatelessService.deepClone(this.inputAddress);
      this.manageDefaultCountry();
      this.manageInputAddress();
      if (this.reloadEvent) {
        this.subscriptions.add(this.reloadEvent.subscribe(updatedAddr => {
          this.inputAddress = this.anagStatelessService.deepClone(updatedAddr);
          this.manageDefaultCountry();
          this.manageInputAddress();
        }));
      }
      if (this.emitOutputAddress) {
        this.subscriptions.add(this.emitOutputAddress.subscribe(() => {
          this.outputAddress.emit(this.adaptFormToApiAddress(this.addressForm.getRawValue()));
        }));
      }
    } else {
      this.manageSubscriptions();
    }
  }

  manageSubscriptions() {
    this.subscriptions.add(this.addressForm.valueChanges.subscribe(formRawData => {
      this.addressForm.get('formatAddress').setValue(undefined, {emitEvent: false});
      this.emitAddress(this.adaptFormToApiAddress(formRawData), this.isAddressValid(formRawData));
    }));
    this.subscriptions.add(this.addressForm.get('countryCode').valueChanges.pipe(distinctUntilChanged()).subscribe(countryCode => {
      this.pushMessageHandler.clearTag('ubication-tag');
      this.pushMessageHandler.clearTag('normalization-tag');
      if (countryCode) {
        let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' + this.getPartyRole() : this.addressType;
        this.anagStorage.getAddressConfig(addressTypeForConfig, countryCode).subscribe(config => {
          this.addressConfig = config;
          this.addressForm.reset({countryCode: this.addressForm.get('countryCode').value}, {emitEvent: false});
          this.orderedAddressInputMap = this.getOrderedVisibleAddressInputMap();
          this.setOrderedInputMapEntries();
          this.setMandatoryByConfig();
          this.resetLevelsToDefault(countryCode);
          this.addressForm.updateValueAndValidity();
        });
      }
    }));
    this.subscriptions.add(this.addressForm.get('adminLevel1').valueChanges.pipe(distinctUntilChanged()).subscribe(aL1formValue => {
      this.onLvl1Change(aL1formValue);
    }));
    this.subscriptions.add(this.addressForm.get('adminLevel2').valueChanges.pipe(distinctUntilChanged()).subscribe(aL2formValue => {
      this.onLvl2Change(aL2formValue);
    }));
    this.subscriptions.add(this.addressForm.get('adminLevel3').valueChanges.pipe(distinctUntilChanged()).subscribe(aL3formValue => {
      this.onLvl3Change(aL3formValue);
    }));
    this.subscriptions.add(this.addressForm.get('zip').valueChanges.pipe(distinctUntilChanged()).subscribe(zip => {
      this.onZipChange(zip);
    }));
  }

  private clearAdminLevelValues() {
    this.filteredLvl1Values = null;
    this.filteredLvl2Values = null;
    this.filteredLvl3Values = null;
    this.filteredZipCodes = null;
    this.adminLevel1Values = null;
    this.adminLevel2Values = null;
    this.adminLevel3Values = null;
    this.zipCodes = null;
    this.filteredValuesMap.set('adminLevel1', null);
    this.filteredValuesMap.set('adminLevel2', null);
    this.filteredValuesMap.set('adminLevel3', null);
  }

  manageInputAddress() {
    this.adaptInputAddressToForm(this.inputAddress);
    const countryCode = this.inputAddress.countryCode;
    if (!countryCode) {
      this.manageSubscriptions();
    } else {
      let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' + this.getPartyRole() : this.addressType;
      this.anagStorage.getAddressConfig(addressTypeForConfig, countryCode).pipe(
        mergeMap((config: AnagAddressLayoutConfig) => {
          this.addressConfig = config;
          this.setMandatoryByConfig();
          if (this.isFieldVisible('adminLevel1')) {
            let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
            return this.anagStorage.getAdminLevelValues$(addressTypeForConfig, countryCode, 1, countryCode);
          }
          return of(undefined);
        }),
        mergeMap((lvl1Values?: AnagIdentificationEntity[]) => {
          let lvl1ValueExists = false;
          if (lvl1Values && lvl1Values.length > 0) {
            this.adminLevel1Values = lvl1Values;
            this.filteredLvl1Values = this.filterAdminLevelValues(this.inputAddress.adminLevel1, this.adminLevel1Values);
            lvl1ValueExists = this.inputAddress.adminLevel1 && lvl1Values.some(lvl1El => {
              return lvl1El.description.toUpperCase() === this.inputAddress.adminLevel1.toUpperCase();
            });
          }
          if (this.isFieldVisible('adminLevel2')) {
            if (this.inputAddress.adminLevel1 && lvl1ValueExists) {
              let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
              return this.anagStorage.getAdminLevelValues$(addressTypeForConfig, countryCode, 2, this.inputAddress.adminLevel1);
            }
            let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
            return this.anagStorage.getAdminLevelValues$(addressTypeForConfig, countryCode, 2, countryCode);
          }
          return of(undefined);
        }),
        mergeMap((lvl2Values?: AnagIdentificationEntity[]) => {
          let lvl2ValueExists = false;
          if (lvl2Values && lvl2Values.length > 0) {
            this.adminLevel2Values = lvl2Values;
            this.filteredLvl2Values = this.filterAdminLevelValues(this.inputAddress.adminLevel2, this.adminLevel2Values);
            lvl2ValueExists = this.inputAddress.adminLevel2 && lvl2Values.some(lvl2El => {
              return lvl2El.description.toUpperCase() === this.inputAddress.adminLevel2.toUpperCase();
            });
          }
          if (this.isFieldVisible('adminLevel3') && this.isFieldVisible('adminLevel2')
            && this.inputAddress.adminLevel2 && lvl2ValueExists) {
            let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
            return this.anagStorage.getAdminLevelValues$(addressTypeForConfig, this.countryCode, 3, this.inputAddress.adminLevel2);
          }
          return of(undefined);
        }),
        mergeMap((lvl3Values?: AnagIdentificationEntity[]) => {
          let lvl3Value: AnagIdentificationEntity;
          if (lvl3Values && lvl3Values.length > 0) {
            this.adminLevel3Values = lvl3Values;
            this.filteredLvl3Values = this.filterAdminLevelValues(this.inputAddress.adminLevel3, this.adminLevel3Values);
            lvl3Value = this.inputAddress.adminLevel3 && lvl3Values.find(lvl3El => {
              return lvl3El.description.toUpperCase() === this.inputAddress.adminLevel3.toUpperCase();
            });
          }
          if (this.isFieldVisible('zip') && this.isFieldVisible('adminLevel3')
            && this.inputAddress.adminLevel3 && lvl3Value) {
            let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
            return this.anagStorage.getAdminLevelValues$(addressTypeForConfig, countryCode, 5, lvl3Value.code);
          }
          return of(undefined);
        }),
        map((zipCodes?: AnagIdentificationEntity[]) => {
          this.zipCodes = zipCodes && zipCodes.length > 0 ? zipCodes : null;
          this.onZipChange(this.inputAddress.cap);
        })
      ).subscribe(() => {
        this.manageSubscriptions();
        this.filteredValuesMap.set('adminLevel1', this.filteredLvl1Values);
        this.filteredValuesMap.set('adminLevel2', this.filteredLvl2Values);
        this.filteredValuesMap.set('adminLevel3', this.filteredLvl3Values);
        this.filteredValuesMap.set('zip', this.filteredZipCodes);
        this.filteredValuesMap.set('toponym', this.toponyms);
        this.orderedAddressInputMap = this.getOrderedVisibleAddressInputMap();
        this.setOrderedInputMapEntries();
      });
    }
  }
  
  private getPartyRole(): string {
    return this.partyRole ? this.partyRole : '';
  }


  get countryCode() {
    return this.addressForm.get('countryCode').value;
  }

  get countriesList(): Array<AnagCountry> {
    return this.anagStorage.countries;
  }

  get toponyms(): Array<AnagEntityIta> {
    return this.anagStorage.getEnumsByCode(TOPONYMS_ENUM);
  }

  isFieldMandatory(field: string): boolean {
    if (this.addressConfig && this.addressConfig[field]) {
      return this.addressConfig[field].state === ADDRESS_STATE_MANDATORY;
    }
    return false;
  }

  isFieldVisible(field: string): boolean {
    if (this.addressConfig && this.addressConfig[field]) {
      return this.addressConfig[field].layout === ADDRESS_LAYOUT_VISIBLE;
    }
    return false;
  }

  protected emitAddress(apiAddress: AnagApiAddress, addressValid: boolean) {
    if (addressValid) {
      this.outputAddress.emit(apiAddress);
    }
  }

  protected adaptFormToApiAddress(formRawData: any): AnagApiAddress {
    const apiAddress = new AnagApiAddress();
    apiAddress.placeAddress = formRawData.address;
    apiAddress.adminLevel1 = formRawData.adminLevel1 ? formRawData.adminLevel1.toUpperCase() : formRawData.adminLevel1;
    apiAddress.adminLevel2 = formRawData.adminLevel2 ? formRawData.adminLevel2.toUpperCase() : formRawData.adminLevel2;
    apiAddress.adminLevel3 = formRawData.adminLevel3 ? formRawData.adminLevel3.toUpperCase() : formRawData.adminLevel3;
    apiAddress.adminLevel3Short = this.inputAddress ? this.inputAddress.adminLevel3Short : null;
    apiAddress.at = formRawData.cco;
    apiAddress.countryCode = formRawData.countryCode;
    apiAddress.extensionSubject = formRawData.estensione;
    apiAddress.number = formRawData.houseNumber;
    apiAddress.locality = formRawData.locality;
    apiAddress.postalAddress = formRawData.postalAddress;
    apiAddress.specialMention = formRawData.specialMention;
    apiAddress.subLocality = formRawData.subLocality;
    apiAddress.subLocalityCode = formRawData.subLocalityCode;
    apiAddress.addressAddition = formRawData.addressAddition;
    apiAddress.formatAddress = formRawData.formatAddress;
    apiAddress.toponym = this.toponyms.find(toponym => {
      return toponym.codice === formRawData.toponym;
    });
    apiAddress.cap = formRawData.zip;
    if (this.adminLevel1Values) {
      const selectedLvl1 = this.adminLevel1Values.find(al1 => al1.description === apiAddress.adminLevel1);
      if (selectedLvl1) {
        apiAddress.adminLevel1Short = selectedLvl1.code;
      }
    }
    else{
      apiAddress.adminLevel1 = '';
    }
    if (this.adminLevel2Values) {
      const selectedLvl2 = this.adminLevel2Values.find(al2 => al2.description === apiAddress.adminLevel2);
      if (selectedLvl2) {
        apiAddress.adminLevel2Short = selectedLvl2.code;
      }
    }

    return apiAddress;
  }

  private isAddressValid(formRawData: any): boolean {
    if (this.isFieldVisible('address') && this.isFieldMandatory('address') && this.isFieldEmpty(formRawData.address)) {
      return false;
    }
    if (this.isFieldVisible('adminLevel1') && this.isFieldMandatory('adminLevel1')) {
      const adminLevel1Valid: boolean = this.adminLevel1Values ? this.adminLevel1Values.find(
        el => el.description === formRawData.adminLevel1) !== undefined : !this.isFieldEmpty('adminLevel1');
      if (!adminLevel1Valid) {
        return false;
      }
    }
    if (this.isFieldVisible('adminLevel2') && this.isFieldMandatory('adminLevel2')) {
      const adminLevel2Valid: boolean = this.adminLevel2Values ? this.adminLevel2Values.find(
        el => el.description === formRawData.adminLevel2) !== undefined : !this.isFieldEmpty('adminLevel2');
      if (!adminLevel2Valid) {
        return false;
      }
    }
    if (this.isFieldVisible('adminLevel3') && this.isFieldMandatory('adminLevel3')) {
      const adminLevel3Valid: boolean = this.adminLevel3Values ? this.adminLevel3Values.find(
        el => el.description === formRawData.adminLevel3) !== undefined : !this.isFieldEmpty('adminLevel3');
      if (!adminLevel3Valid) {
        return false;
      }
    }
    if (this.isFieldVisible('zip') && this.isFieldMandatory('zip')) {
      const zipValid: boolean = this.zipCodes ? this.zipCodes.find(
        el => el.code === formRawData.zip) !== undefined : !this.isFieldEmpty('zip');
      if (!zipValid) {
        return false;
      }
    }
    if (this.isFieldVisible('cco') && this.isFieldMandatory('cco') && this.isFieldEmpty(formRawData.cco)) {
      return false;
    }
    if (this.isFieldVisible('countryCode') && this.isFieldMandatory('countryCode') && this.isFieldEmpty(formRawData.countryCode)) {
      return false;
    }
    if (this.isFieldVisible('estensione') && this.isFieldMandatory('estensione') && this.isFieldEmpty(formRawData.estensione)) {
      return false;
    }
    if (this.isFieldVisible('houseNumber') && this.isFieldMandatory('houseNumber') && this.isFieldEmpty(formRawData.houseNumber)) {
      return false;
    }
    if (this.isFieldVisible('locality') && this.isFieldMandatory('locality') && this.isFieldEmpty(formRawData.locality)) {
      return false;
    }
    if (this.isFieldVisible('postalAddress') && this.isFieldMandatory('postalAddress') && this.isFieldEmpty(formRawData.postalAddress)) {
      return false;
    }
    if (this.isFieldVisible('specialMention') && this.isFieldMandatory('specialMention') && this.isFieldEmpty(formRawData.specialMention)) {
      return false;
    }
    if (this.isFieldVisible('subLocality') && this.isFieldMandatory('subLocality') && this.isFieldEmpty(formRawData.subLocality)) {
      return false;
    }
    if (this.isFieldVisible('toponym') && this.isFieldMandatory('toponym') && this.isFieldEmpty(formRawData.toponym)) {
      return false;
    }
    if (this.isFieldVisible('addressAddition') && this.isFieldMandatory('addressAddition') && this.isFieldEmpty(formRawData.addressAddition)) {
      return false;
    }
    return true;
  }

  isFieldEmpty(value: any) {
    return !value || value === '';
  }

  onLvl1Change(aL1formValue) {
    this.filteredLvl1Values = this.filterAdminLevelValues(aL1formValue, this.adminLevel1Values);
    this.addressForm.get('adminLevel2').setValue(undefined, {emitEvent: false});
    this.addressForm.get('adminLevel3').setValue(undefined, {emitEvent: false});
    this.addressForm.get('zip').setValue(undefined, {emitEvent: false});
    if (aL1formValue && aL1formValue.length > 0) {
      const lvl1Found = this.adminLevel1Values && this.adminLevel1Values.find(lvl1El => {
        return lvl1El.description.toUpperCase() === aL1formValue.toUpperCase();
      }) !== undefined;
      if (lvl1Found) {
        if (this.isFieldVisible('adminLevel2')) {
          let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
          this.anagStorage.getAdminLevelValues$(addressTypeForConfig, this.countryCode, 2, aL1formValue.toUpperCase())
            .subscribe(adminLevel2List => {
                this.adminLevel2Values = adminLevel2List && adminLevel2List.length > 0 ? adminLevel2List : null;
                this.filteredLvl2Values = adminLevel2List;
                this.filteredValuesMap.set('adminLevel2', this.filteredLvl2Values);
              }
            );
        } else if (this.isFieldVisible('adminLevel3')) {
          let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
          this.anagStorage.getAdminLevelValues$(addressTypeForConfig, this.countryCode, 3, null,
            new ExtendedFilter(1, aL1formValue.toUpperCase()))
            .subscribe(adminLevel3List => {
                this.adminLevel3Values = adminLevel3List && adminLevel3List.length > 0 ? adminLevel3List : null;
                this.filteredLvl3Values = adminLevel3List;
                this.filteredValuesMap.set('adminLevel3', this.filteredLvl3Values);
              }
            );
        }
      }
    }
  }

  onLvl2Change(aL2formValue) {
    this.filteredLvl2Values = this.filterAdminLevelValues(aL2formValue, this.adminLevel2Values);
    this.addressForm.get('adminLevel3').setValue(undefined, {emitEvent: false});
    this.addressForm.get('zip').setValue(undefined, {emitEvent: false});
    if (this.isFieldVisible('adminLevel3') && aL2formValue && aL2formValue.length > 0) {
      const lvl2Found = this.adminLevel2Values && this.adminLevel2Values.find(lvl2El => {
        return lvl2El.description.toUpperCase() === aL2formValue.toUpperCase();
      }) !== undefined;
      if (lvl2Found) {
        let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
        this.anagStorage.getAdminLevelValues$(addressTypeForConfig, this.countryCode, 3, aL2formValue.toUpperCase())
          .subscribe(adminLevel3List => {
              this.adminLevel3Values = adminLevel3List && adminLevel3List.length > 0 ? adminLevel3List : null;
              this.filteredLvl3Values = adminLevel3List;
              this.filteredValuesMap.set('adminLevel3', this.filteredLvl3Values);
            }
          );
      }
    }
  }

  onLvl3Change(aL3formValue) {
    if (!this.isFieldVisible('adminLevel2') && aL3formValue.length > 2) {
      let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
      this.addressService.getAdminLevelValues$(addressTypeForConfig, this.countryCode, 3, null,
        new ExtendedFilter(3, aL3formValue)).subscribe(values => {
        if (values.output && values.output.length > 0) {
          this.adminLevel3Values = values.output.map(val => val.adminLevel3);
          this.filteredLvl3Values = this.filterAdminLevelValues(aL3formValue, this.adminLevel3Values);
        }
      });
    } else {
      this.filteredLvl3Values = this.filterAdminLevelValues(aL3formValue, this.adminLevel3Values);
    }
    if (this.isFieldVisible('zip') && aL3formValue && aL3formValue.length > 0) {
      const lvl3Found = this.adminLevel3Values && this.adminLevel3Values.find(lvl3El => {
        return lvl3El.description.toUpperCase() === aL3formValue.toUpperCase();
      });
      if (lvl3Found) {
        let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
        this.anagStorage.getAdminLevelValues$(addressTypeForConfig, this.countryCode, 5, lvl3Found.code)
          .subscribe(zipCodes => {
              this.zipCodes = zipCodes && zipCodes.length > 0 ? zipCodes : null;
              this.filteredZipCodes = zipCodes;
              this.filteredValuesMap.set('zip', this.filteredZipCodes);
              this.addressForm.updateValueAndValidity();
            }
          );
      }
    }
  }

  onZipChange($zip) {
    if (this.zipCodes) {
      this.filteredZipCodes = this.zipCodes.filter(el => el.code.startsWith($zip))
        .concat(this.zipCodes.filter(el => !el.code.startsWith($zip) && el.code.includes($zip)))
        .concat(this.zipCodes.filter(el => !el.code.includes($zip)));
    }
  }

  updateLevelsByZip($zip: string) {
    if (!!$zip && (this.isFieldVisible('adminLevel1') || this.isFieldVisible('adminLevel2') ||
      this.isFieldVisible('adminLevel3'))) {
      let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
      this.addressService.getAdminLevelValues$(addressTypeForConfig, this.countryCode, 3, null,
        new ExtendedFilter(5, $zip)).subscribe(values => {
        if (values.output && values.output.length === 1) {
          const adminLevel1found = values.output.map(val => val.adminLevel1)[0].description;
          this.addressForm.get('adminLevel1').setValue(adminLevel1found, {emitEvent: false});
          const adminLevel2found = values.output.map(val => val.adminLevel2)[0].description;
          this.addressForm.get('adminLevel2').setValue(adminLevel2found, {emitEvent: false});
          const adminLevel3found = values.output.map(val => val.adminLevel3)[0].description;
          this.addressForm.get('adminLevel3').setValue(adminLevel3found);
          this.filteredValuesMap.set('adminLevel3', null);
        } else if (values.output) {
          // this.addressForm.get('adminLevel3').setValue('');
          // limit use-case : a zip should be associated with only one city for each country
          // if more than one city is associated with the zip, the first level1 and level2 are taken
          if (this.isFieldVisible('adminLevel1')) {
            const adminLevel1found = values.output.map(val => val.adminLevel1)[0].description;
            this.addressForm.get('adminLevel1').setValue(adminLevel1found, {emitEvent: false});
          }
          if (this.isFieldVisible('adminLevel2')) {
            const adminLevel2found = values.output.map(val => val.adminLevel2)[0].description;
            this.addressForm.get('adminLevel2').setValue(adminLevel2found, {emitEvent: false});
          }
          if (this.isFieldVisible('adminLevel3')) {
            this.adminLevel3Values = values.output.map(val => val.adminLevel3);
            this.filteredLvl3Values = this.adminLevel3Values;
            if (!this.filteredLvl3Values.some(values => values.description === this.addressForm.get('adminLevel3').value)) {
              this.addressForm.get('adminLevel3').setValue(null);
            }
            this.filteredValuesMap.set('adminLevel3', this.filteredLvl3Values);
          }
        } else {
          this.filteredValuesMap.set('adminLevel3', null);
          this.addressForm.get('adminLevel3').setValue(null);
        }
      });
    }
  }

  private resetLevelsToDefault(countryCode) {
    this.addressForm.get('adminLevel1').setValue(undefined, {emitEvent: false});
    this.addressForm.get('adminLevel2').setValue(undefined, {emitEvent: false});
    this.addressForm.get('adminLevel3').setValue(undefined, {emitEvent: false});
    this.clearAdminLevelValues();
    if (this.isFieldVisible('adminLevel1')) {
      let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
      this.anagStorage.getAdminLevelValues$(addressTypeForConfig, countryCode, 1, countryCode).subscribe(values => {
        if (values && values.length > 0) {
          this.adminLevel1Values = values;
          this.filteredLvl1Values = values;
          this.filteredValuesMap.set('adminLevel1', this.filteredLvl1Values);
        }
      });
    }
    if (this.isFieldVisible('adminLevel2')) {
      let addressTypeForConfig = (this.isQuote && (this.offsetAlternativeRole > 0)) ? this.addressType + 'Quote' : this.addressType;
      this.anagStorage.getAdminLevelValues$(addressTypeForConfig, countryCode, 2, countryCode).subscribe(values => {
        if (values && values.length > 0) {
          this.adminLevel2Values = values;
          this.filteredLvl2Values = values;
          this.filteredValuesMap.set('adminLevel2', this.filteredLvl2Values);
        }
      });
    }
  }

  filterAdminLevelValues(value: string, adminLevelValues: Array<AnagIdentificationEntity>): Array<AnagIdentificationEntity> {
    // starts with + contains + others (all options can be selected anyway)
    if (adminLevelValues && value) {
      value = value.toUpperCase();
      return adminLevelValues.filter(el => el.description.startsWith(value))
        .concat(adminLevelValues.filter(el => !el.description.startsWith(value) && el.description.includes(value)))
        .concat(adminLevelValues.filter(el => !el.description.includes(value)));
    }
    return adminLevelValues;
  }

  protected updateFormField(control: AbstractControl, value: string) {
    if (control && value !== control.value) {
      control.setValue(value, {emitEvent: false});
    }
  }

  private adaptInputAddressToForm(inputAddress: AnagApiAddress) {
    this.updateFormField(this.addressForm.get('address'), inputAddress.placeAddress);
    this.updateFormField(this.addressForm.get('adminLevel1'), inputAddress.adminLevel1);
    this.updateFormField(this.addressForm.get('adminLevel2'), inputAddress.adminLevel2);
    this.updateFormField(this.addressForm.get('adminLevel3'), inputAddress.adminLevel3);
    this.updateFormField(this.addressForm.get('cco'), inputAddress.at);
    this.updateFormField(this.addressForm.get('countryCode'), inputAddress.countryCode);
    this.updateFormField(this.addressForm.get('estensione'), inputAddress.extensionSubject);
    this.updateFormField(this.addressForm.get('houseNumber'), inputAddress.number);
    this.updateFormField(this.addressForm.get('locality'), inputAddress.locality);
    this.updateFormField(this.addressForm.get('postalAddress'), inputAddress.postalAddress);
    this.updateFormField(this.addressForm.get('specialMention'), inputAddress.specialMention);
    this.updateFormField(this.addressForm.get('subLocality'), inputAddress.subLocality);
    this.updateFormField(this.addressForm.get('formatAddress'), inputAddress.formatAddress);
    if (inputAddress.addressAddition) {
      this.updateFormField(this.addressForm.get('addressAddition'), inputAddress.addressAddition);
    }
    this.updateFormField(this.addressForm.get('subLocalityCode'), inputAddress.subLocalityCode);
    if (inputAddress.toponym) {
      if (inputAddress.toponym.codice && inputAddress.toponym.codice.length > 0) {
        this.updateFormField(this.addressForm.get('toponym'), inputAddress.toponym.codice);
      } else if (inputAddress.toponym.descrizione && inputAddress.toponym.descrizione.length > 0) {
        const selectedToponym = this.toponyms.find(
          toponym => toponym.descrizione === inputAddress.toponym.descrizione.toUpperCase());
        if (selectedToponym) {
          this.updateFormField(this.addressForm.get('toponym'), selectedToponym.codice);
        }
      }
    }
    this.updateFormField(this.addressForm.get('zip'), inputAddress.cap);
  }

  normalize() {
    this.normalizerServiceError = null;
    this.normalizedAddresses = null;
    this.addressService.normalizeAddress$(this.adaptFormToApiAddress(this.addressForm.getRawValue())).subscribe(resp => {
       if (resp.result && resp.result.normalizedAddresses && resp.result.normalizedAddresses.length > 0) {
        this.normalizedAddresses = resp.result.normalizedAddresses;
        this.showNormalizedAddressesResults = true;
         this.noNormalizedAddressFounded = false;
        this.fillNormalizedAddressesTableData();
      }
      else {
        this.noNormalizedAddressFounded = true;
      }
    });
  }

  protected fillNormalizedAddressesTableData() {
    this.normalizedAddressesTableData = this.normalizedAddresses.map(address => {
      return {
        formatAddress: address.formatAddress,
      };
    });
  }

  onActionClick($event: RgiRxDatatableRowAction<any>) {
    switch ($event.name) {
      case 'SELECT':
        const selectedAddresses = this.normalizedAddresses.find(normalizedAddresses => {
          return normalizedAddresses.formatAddress === $event.row.formatAddress;
        });
        this.inputAddress = selectedAddresses;
        this.adaptInputAddressToForm(selectedAddresses);
        this.showNormalizedAddressesResults = false;
        break;
    }
  }

  get isNormalizationVisibile() {
    return !this.hideNormalize &&
      CORE_ADDRESS_FUNCTION.partyBirthAddress !== this.addressType &&
      CORE_ADDRESS_FUNCTION.subjectFinder !== this.addressType;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private manageDefaultCountry() {
    if (!this.inputAddress || !this.inputAddress.countryCode) {
      this.inputAddress.countryCode = this.defaultCountryCode;
    }
  }

  setMandatoryByConfig() {
    const keys = Object.keys(this.addressConfig);
    keys.forEach(key => {
      if (this.isFieldMandatory(key)) {
        if (this.addressForm.get(key)) {
          this.addressForm.get(key).setValidators(Validators.required);
        }
      } else {
        if (this.addressForm.get(key)) {
          this.addressForm.get(key).clearValidators();
          this.addressForm.get(key).setErrors(null);
        }
      }
    });
  }

  private getOrderedVisibleAddressInputMap(): Map<string, AnagAddressLayout> {
    const entries = Object.entries(this.addressConfig).filter(entry => !!entry[1] && !!entry[1].order);
    if (entries.length === 0) {
      return new Map(Object.entries(this.addressConfig).filter(entry => this.isFieldVisible(entry[0])));
    }
    return new Map(entries.filter(entry => this.isFieldVisible(entry[0])).sort((a, b) => a[1].order - b[1].order));
  }

  setOrderedInputMapEntries() {
    this.orderedAddressInputKeyList.splice(0, this.orderedAddressInputKeyList.length);
    // this.orderedAddressInputMapClone.clear();
    for (const key of this.orderedAddressInputMap.keys()) {
      this.orderedAddressInputKeyList.push(key);
      this.orderedAddressInputMapClone.set(key, this.orderedAddressInputMap.get(key));
    }
  }

}

import { ActiveRoute, Routes } from '@rgi/rx/router';
import { StateStoreService } from '@rgi/rx/state';
import {
  ROUTES_GP_CONF_PM,
  ROUTES_GP_EDIT_ISSUE,
  ROUTES_GP_GUARANTEES,
  ROUTES_GP_HOME,
  ROUTES_GP_INQUIRY_DETAIL,
  ROUTES_GP_ISSUE_CONFIRM,
  ROUTES_GP_POLDATA,
  ROUTES_GP_PROP_ISSUE,
  ROUTES_GP_SUMMARY,
  ROUTES_GP_VCONT_CONFIRMS,
  ROUTES_GP_VCONT_DATES,
  ROUTES_GP_VCONT_QUESTIONNAIRE,
  ROUTES_GP_VCONT_VARIATION
} from './group-policy-constants/routes-constants';
import { GroupPolicyEditComponent } from './group-policy-edit/group-policy-edit.component';
import { GroupPolicyInquiryComponent } from './group-policy-inquiry/group-policy-inquiry.component';
import {
  GroupPolicyStatelessOpConfigurationPmService
} from './group-policy-state/group-policy-statemanager-configuration-pm/group-policy-stateless-op-configuration-pm.service';
import {
  GroupPolicyStatemanagerConfigurationPm
} from './group-policy-state/group-policy-statemanager-configuration-pm/group-policy-statemanager-configuration-pm';
import {
  GroupPolicyStateManagerConfirms
} from './group-policy-state/group-policy-statemanager-confirms/group-policy-stateless-op-confirms';
import {
  GroupPolicyStatelessOpConfirmsService
} from './group-policy-state/group-policy-statemanager-confirms/group-policy-stateless-op-confirms.service';
import {
  GroupPolicyStateManagerDate
} from './group-policy-state/group-policy-statemanager-date/group-policy-stateless-op-date';
import {
  GroupPolicyStatelessOpDateService
} from './group-policy-state/group-policy-statemanager-date/group-policy-stateless-op-date.service';
import {
  GroupPolicyStatelessOpEditService
} from './group-policy-state/group-policy-statemanager-edit/group-policy-stateless-op-edit.service';
import {
  GroupPolicyStatemanagerEdit
} from './group-policy-state/group-policy-statemanager-edit/group-policy-statemanager-edit';
import {
  GroupPolicyStatelessOpGuaranteesService
} from './group-policy-state/group-policy-statemanager-guarantees/group-policy-stateless-op-guarantees.service';
import {
  GroupPolicyStatemanagerGuarantees
} from './group-policy-state/group-policy-statemanager-guarantees/group-policy-statemanager-guarantees';
import {
  GroupPolicyStatelessOpHomeService
} from './group-policy-state/group-policy-statemanager-home/group-policy-stateless-op-home.service';
import {
  GroupPolicyStatemanagerHome
} from './group-policy-state/group-policy-statemanager-home/group-policy-statemanager-home';
import {
  GroupPolicyStatelessOpInquiryService
} from './group-policy-state/group-policy-statemanager-inquiry/group-policy-stateless-op-inquiry.service';
import {
  GroupPolicyStateManagerInquiry
} from './group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {
  GroupPolicyStatelessOpIssueConfirmService
} from './group-policy-state/group-policy-statemanager-issue-confirm/group-policy-stateless-op-issue-confirm.service';
import {
  GroupPolicyStateManagerIssueConfirm
} from './group-policy-state/group-policy-statemanager-issue-confirm/group-policy-statemanager-issue-confirm';
import {
  GroupPolicyStatelessOpPolicyDataService
} from './group-policy-state/group-policy-statemanager-policy-data/group-policy-stateless-op-policy-data.service';
import {
  GroupPolicyStatemanagerPolicyData
} from './group-policy-state/group-policy-statemanager-policy-data/group-policy-statemanager-policy-data';
import {
  GroupPolicyStatelessOpProposalissueService
} from './group-policy-state/group-policy-statemanager-proposalissue/group-policy-stateless-op-proposalissue.service';
import {
  GroupPolicyStatemanagerProposalissue
} from './group-policy-state/group-policy-statemanager-proposalissue/group-policy-statemanager-proposalissue';
import {
  GroupPolicyStateManagerQuestionnaire
} from './group-policy-state/group-policy-statemanager-questionnaire/group-policy-stateless-op-questionnaire';
import {
  GroupPolicyStatelessOpQuestionnaireService
} from './group-policy-state/group-policy-statemanager-questionnaire/group-policy-stateless-op-questionnaire.service';
import {
  GroupPolicyStatelessOpSummaryService
} from './group-policy-state/group-policy-statemanager-summary/group-policy-stateless-op-summary.service';
import {
  GroupPolicyStatemanagerSummary
} from './group-policy-state/group-policy-statemanager-summary/group-policy-statemanager-summary';
import {
  GroupPolicyStateManagerVariation
} from './group-policy-state/group-policy-statemanager-variation/group-policy-stateless-op-variation';
import {
  GroupPolicyStatelessOpVariationService
} from './group-policy-state/group-policy-statemanager-variation/group-policy-stateless-op-variation.service';
import {
  GroupPolicyConfigurationPmComponent
} from './group-policy-steps/group-policy-configuration-pm/group-policy-configuration-pm.component';
import {
  GroupPolicyGuaranteesComponent
} from './group-policy-steps/group-policy-guarantees/group-policy-guarantees.component';
import { GroupPolicyHomeComponent } from './group-policy-steps/group-policy-home/group-policy-home.component';
import {
  GroupPolicyIssueConfirmComponent
} from './group-policy-steps/group-policy-issue-confirm/group-policy-issue-confirm.component';
import {
  GroupPolicyPolicyDataComponent
} from './group-policy-steps/group-policy-policy-data/group-policy-policy-data.component';
import {
  GroupPolicyProposalIssueComponent
} from './group-policy-steps/group-policy-proposal-issue/group-policy-proposal-issue.component';
import { GroupPolicySummaryComponent } from './group-policy-steps/group-policy-summary/group-policy-summary.component';
import { GroupPolicyVcontConfirmsComponent } from './group-policy-vcont-steps/group-policy-vcont-confirms/group-policy-vcont-confirms.component';
import { GroupPolicyVcontDateComponent } from './group-policy-vcont-steps/group-policy-vcont-date/group-policy-vcont-date.component';
import { GroupPolicyVcontQuestionnaireComponent } from './group-policy-vcont-steps/group-policy-vcont-questionnaire/group-policy-vcont-questionnaire.component';
import { GroupPolicyVcontVariationComponent } from './group-policy-vcont-steps/group-policy-vcont-variation/group-policy-vcont-variation.component';
import {
  GroupPolicyStatelessOpClusterService
} from './group-policy-state/group-policy-sub-statemanagers/group-policy-stateless-op-cluster.service';
import {ModalService} from '@rgi/rx/ui';
import {
  GroupPolicyApplicationInclusionService
} from './group-policy-services/group-policy-application-inclusion.service';


export const GROUP_POLICY_ROUTES: Routes = [
  {
    route: ROUTES_GP_HOME,
    component: GroupPolicyHomeComponent,
    providers: [{
      provide: GroupPolicyStatemanagerHome,
      useClass: GroupPolicyStatemanagerHome,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpHomeService]
    }]
  },
  {
    route: ROUTES_GP_EDIT_ISSUE,
    component: GroupPolicyEditComponent,
    providers: [{
      provide: GroupPolicyStatemanagerEdit,
      useClass: GroupPolicyStatemanagerEdit,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpEditService]
    }]
  },
  {
    route: ROUTES_GP_PROP_ISSUE,
    component: GroupPolicyProposalIssueComponent,
    providers: [{
      provide: GroupPolicyStatemanagerProposalissue,
      useClass: GroupPolicyStatemanagerProposalissue,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpProposalissueService]
    }]
  },
  {
    route: ROUTES_GP_POLDATA,
    component: GroupPolicyPolicyDataComponent,
    providers: [{
      provide: GroupPolicyStatemanagerPolicyData,
      useClass: GroupPolicyStatemanagerPolicyData,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpPolicyDataService]
    }]
  },
  {
    route: ROUTES_GP_CONF_PM,
    component: GroupPolicyConfigurationPmComponent,
    providers: [{
      provide: GroupPolicyStatemanagerConfigurationPm,
      useClass: GroupPolicyStatemanagerConfigurationPm,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpConfigurationPmService]
    }]
  },
  {
    route: ROUTES_GP_GUARANTEES,
    component: GroupPolicyGuaranteesComponent,
    providers: [{
      provide: GroupPolicyStatemanagerGuarantees,
      useClass: GroupPolicyStatemanagerGuarantees,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpGuaranteesService, GroupPolicyStatelessOpClusterService, ModalService]
    }]
  },
  {
    route: ROUTES_GP_SUMMARY,
    component: GroupPolicySummaryComponent,
    providers: [{
      provide: GroupPolicyStatemanagerSummary,
      useClass: GroupPolicyStatemanagerSummary,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpSummaryService, GroupPolicyStatelessOpClusterService]
    }]
  },
  {
    route: ROUTES_GP_ISSUE_CONFIRM,
    component: GroupPolicyIssueConfirmComponent,
    providers: [{
      provide: GroupPolicyStateManagerIssueConfirm,
      useClass: GroupPolicyStateManagerIssueConfirm,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpIssueConfirmService]
    }]
  },
  {
    route: ROUTES_GP_INQUIRY_DETAIL,
    component: GroupPolicyInquiryComponent,
    providers: [{
      provide: GroupPolicyStateManagerInquiry,
      useClass: GroupPolicyStateManagerInquiry,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpInquiryService, GroupPolicyStatelessOpClusterService,
        ModalService, GroupPolicyApplicationInclusionService]
    }]
  },
  {
    route: ROUTES_GP_VCONT_DATES,
    component: GroupPolicyVcontDateComponent,
    providers: [{
      provide: GroupPolicyStateManagerDate,
      useClass: GroupPolicyStateManagerDate,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpDateService]
    }]
  },
  {
    route: ROUTES_GP_VCONT_VARIATION,
    component: GroupPolicyVcontVariationComponent,
    providers: [{
      provide: GroupPolicyStateManagerVariation,
      useClass: GroupPolicyStateManagerVariation,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpVariationService]
    }]
  },
  {
    route: ROUTES_GP_VCONT_QUESTIONNAIRE,
    component: GroupPolicyVcontQuestionnaireComponent,
    providers: [{
      provide: GroupPolicyStateManagerQuestionnaire,
      useClass: GroupPolicyStateManagerQuestionnaire,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpQuestionnaireService]
    }]
  },
  {
    route: ROUTES_GP_VCONT_CONFIRMS,
    component: GroupPolicyVcontConfirmsComponent,
    providers: [{
      provide: GroupPolicyStateManagerConfirms,
      useClass: GroupPolicyStateManagerConfirms,
      deps: [ActiveRoute, StateStoreService, GroupPolicyStatelessOpConfirmsService]
    }]
  }
];

export class ContractorDataResponse {
  additionalLabels: any[];
  id: number;
  snapshotId: number;
  uuid: string;
  name: string;
  surname: string;
  birthDate: string;
  extensions: any[];

  constructor() {
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingIndicatorService, RgiRxLoadingIndicatorService} from './rgi-rx-loading-indicator.service';
import {RGI_RX_DEFAULT_PUSH_OPTS, RGI_RX_PUSH_OPTS, RgiRxLoadingIndicator} from './reaction-api';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: RgiRxLoadingIndicator,
      useExisting: RgiRxLoadingIndicatorService
    },
    {
      provide: LoadingIndicatorService,
      useExisting: RgiRxLoadingIndicatorService
    },
    {provide: RGI_RX_PUSH_OPTS, useValue: RGI_RX_DEFAULT_PUSH_OPTS}
  ]
})
export class RgiRxReactionModule {
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActiveRoute, RoutableComponent } from "@rgi/rx/router";
import { tap } from "rxjs/operators";
import { GroupPolicyVcontRouteData } from "../../group-policy-models/group-policy-vcont-date";
import { GroupPolicyStateVcontConfirms } from '../../group-policy-state/group-policy-state';
import {
    GroupPolicyStateManagerConfirms
} from "../../group-policy-state/group-policy-statemanager-confirms/group-policy-stateless-op-confirms";


/**
 * @author: dmasone
 * @description: Component used for render vcont confirms step
 */
@Component({
  selector: 'rgi-gp-group-policy-vcont-confirms',
  templateUrl: './group-policy-vcont-confirms.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyVcontConfirmsComponent extends RoutableComponent implements OnInit, OnDestroy {

    public vertical = true;
    public showIndex = false;
    public disabled = true;
    public stateVcontConfirms: GroupPolicyStateVcontConfirms;
    public previousStepData: GroupPolicyVcontRouteData;
    public confirmsForm = new UntypedFormGroup({});

    constructor(protected activeRoute: ActiveRoute,
        protected stateManagerVcontConfirms: GroupPolicyStateManagerConfirms) {
        super();
    }


    ngOnInit(): void {
        this.previousStepData = this.activeRoute.getRouteData();
        this.stateManagerVcontConfirms.getState$().pipe(
            tap((state: GroupPolicyStateVcontConfirms) => {
                console.log(state);
            })
        ).subscribe((nextState: GroupPolicyStateVcontConfirms) => {
            this.initPage(nextState);
        });
    }

    ngOnDestroy(): void { }

    protected initPage(nextState: GroupPolicyStateVcontConfirms) {
        this.stateVcontConfirms = nextState;
    }

    public callActionBack(targetRoute?: string) {
        this.stateManagerVcontConfirms.actionBack(this.previousStepData, this.activeRoute, targetRoute);
    }

    public callActionClose() {
        this.stateManagerVcontConfirms.actionClose(this.previousStepData);
    }

}

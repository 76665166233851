import {Inject, Injectable} from '@angular/core';
import {
  AmendmentModalData,
  DataActions,
  DataForSteps,
  GpAmendmentModalComponent,
  GroupPolicyActionIntegrationService,
  GroupPolicyCrossService,
  GroupPolicyIntegrationService,
  GroupPolicyResourceService,
  Operation,
  OPERATIONSCODE
} from '@rgi/group-policy';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {RoutingService} from '@rgi/rx/router';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GROUP_POLICY_INQUIRY_CARD, GROUP_POLICY_ISSUE_CARD} from './group-policy.card.configurations';
import {ModalService} from '@rgi/rx/ui';

@Injectable()
export class GroupPolicyPortalActionIntegrationService extends GroupPolicyActionIntegrationService {

  constructor(
    protected _routingService: RoutingService,
    protected _policyResourceService: GroupPolicyResourceService,
    protected _crossService: GroupPolicyCrossService,
    protected _integrationService: GroupPolicyIntegrationService,
    protected translationService: RgiRxTranslationService,
    protected modalService: ModalService,
    @Inject('cardPassService') protected portalCardPassService: any,
    @Inject('sessionService') protected portalSession: any,
    @Inject('coreResult') protected portalCoreResult: any) {
    super(_routingService, _policyResourceService, _crossService, _integrationService, modalService);
  }

  executeAction(_operation: Operation, _data: DataActions) {
    if (_operation.jumpCommand) {
      const act = {
        jumpCommand: _operation.jumpCommand,
        operation: {
          code: _operation.code
        }
      };
      const inquirySession = this.portalSession.list().find(session => session.idSession === _data.id);
      const card = {
        integrationPass: undefined,
        id: inquirySession.id,
        idSession: inquirySession.idSession
      };
      card.integrationPass = this.getObjIntegrationDamageActions(act, _data.proposalNumber, _data.idPolicy, 'activate()');
      this.portalCardPassService.createJump(card, card.integrationPass,
        _operation.description, 'action', GROUP_POLICY_INQUIRY_CARD.card.name);

    } else {
      switch (_operation.code) {
        case 'PMPROP':
          this.openEditProposal(_data);
          break;
        case '_EMPRO':
          this.openProposalIssue(_data.proposalNumber, _data.id);
          break;
        case '_PEMPO':
          this._policyResourceService.askBatch$('000001', _data.proposalNumber).pipe(
            catchError((err) => {
              return of(err.error && err.error[0] && err.error[0].errorDescription);
            }),
          ).subscribe((resp: any) => {
            if (typeof resp === 'string') {
              this.crossService.openBaseModal(resp).subscribe();
            } else if (!resp) {
              this.crossService.openBaseModal('_GP_._MODAL_._OPERATION_').subscribe();
            }
          });
          break;
        case '_REVPR':
          this.policyResources.contractRevocation$(_data.proposalNumber).pipe(
            catchError((err) => {
              return of(err.error);
            }),
          ).subscribe((resp) => {
            if (typeof resp === 'string') {
              this.crossService.openBaseModal(resp).subscribe();
            }
            if (!resp) {
              this.crossService.openBaseModal('_GP_._MODAL_._OPERATION_').subscribe();
            }
          });
          break;
        case '_VCONT':
          this.openVcontOperation(_data);
          break;
        case 'LMVAPM':
          this.openMasterPolicyAmendment(_data);
          break;
        case 'DI05':
          this.openMasterPolicySubstitution(_data);
          break;
      }
    }
  }

  protected openEditProposal(_data: DataActions) {
    const inquirySession = this.portalSession.list().find(session => session.idSession === _data.id);
    let title = '';
    //(parent, route, title, navigationDisabled, navigationParams, advancedSearch, idSessionParent, origin)
    this.translationService.translate('_GP_._FLOW_._EDIT_PROPOSAL').subscribe(text => title = text + +_data.proposalNumber);
    const idNavigation = this.portalSession.open(GROUP_POLICY_ISSUE_CARD.card.name, 'edit', title, true, null, null, inquirySession.idSession);
    this.portalCoreResult.setResult(idNavigation, 'edit',
      {
        proposalNumber: _data.proposalNumber,
        idParentSession: inquirySession.idSession,
        isFromInquiry: true,
        contractStatus: _data.contractStatus,
        managementNode: _data.managementNode
      } as DataForSteps
    );
  }

  protected openProposalIssue(_proposalNumber: string, _id: string) {
    const inquirySession = this.portalSession.list().find(session => session.idSession === _id);
    let title = '';
    this.translationService.translate('_GP_._FLOW_._ISSUE_PROPOSAL_').subscribe(text => title = text + +_proposalNumber);
    const idNavigation = this.portalSession.open(GROUP_POLICY_ISSUE_CARD.card.name, 'proposalIssue', title, true);
    this.portalCoreResult.setResult(idNavigation, 'proposalIssue',
      {
        proposalNumber: _proposalNumber,
        idParentSession: inquirySession.idSession,
        isFromInquiry: true
      }
    );
  }

  protected openVcontOperation(_data: DataActions) {
    const inquirySession = this.portalSession.list().find(session => session.idSession === _data.id);
    let title = '';
    this.translationService.translate('_GP_._FLOW_._VCONT_').subscribe(text => title = text + +_data.policyNumber);
    const idNavigation = this.portalSession.open(
      GROUP_POLICY_ISSUE_CARD.card.name, 'vcontOperation', title, true, null, null, inquirySession.idSession);
    this.portalCoreResult.setResult(idNavigation, 'vcontOperation',
      {
        proposalNumber: _data.proposalNumber,
        idParentSession: inquirySession.idSession,
        node: _data.node,
        isFromInquiry: true
      }
    );
  }

  protected getObjIntegrationDamageActions(act, policyNumber, contractId, returnFunction) {
    const paramsIntegrationPass = {
      RGIRedirectOk: '/' + act.jumpCommand,
      NAVI_PAR_POLICY_NUMBER: policyNumber,
      NAVI_PAR_POLICYID: String(contractId),
      NAVI_PAR_REASON_CODE: act.operation.code
    };
    const objNavigation = {
      newSession: true,
      functionReloadCtrlParent: undefined
    };
    if (returnFunction) {
      objNavigation.functionReloadCtrlParent = returnFunction;
    }
    return { paramsIntegrationPass, objNavigation };
  }

  protected openMasterPolicyAmendment(_data: DataActions) {
    const amendModal = this.modalService.open(GpAmendmentModalComponent, new AmendmentModalData(_data.lastMovEffDate, _data.expireDate));
    amendModal.enableClickBackground = false;
    amendModal.onClose.subscribe(amendmentEffDate => {
      if (amendmentEffDate) {
        this.policyResources.createAmendmentProposal$(_data.proposalNumber, amendmentEffDate).subscribe(resp => {
            if (resp) {
              const nextStepParam = {
                resId: resp.uuid,
                operationCode: OPERATIONSCODE.ISSUE,
                contractStatus: _data.contractStatus,
                managementNode: _data.managementNode
              } as DataForSteps;
              this._integrationService.goToEdit(nextStepParam);
            }
          }
        );
      }
    });
  }

  protected openMasterPolicySubstitution(_data: DataActions) {
    this._policyResourceService.masterPolicySubstitution$(_data.proposalNumber).subscribe(resp => {
        if (resp) {
          const nextStepParam = {
            resId: resp.uuid,
            operationCode: OPERATIONSCODE.ISSUE
          } as DataForSteps;
          this._integrationService.goToEdit(nextStepParam);
        }
      }, error => {
        if (error.status > 400 && error.status < 500) {
          this.crossService.openBaseModal(error.errorBody.messages[0]).subscribe();
        }
      }
    );
  }
}

import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {QuestionnaireDefinition} from '../models/postsales-operations-response.model';
import {
  PASS_QUESTIONNAIRE_TYPE_CODE,
  QUESTIONNAIRE_LEVEL_TO_QUEST_TYPE
} from '../modules/lpc-questionnaire/model/questionnaire-constants';
import {QuestionnaireLevel} from '../modules/lpc-questionnaire/model/questionnaire-level.enum';

export class PlcQuestionnairesUtils {

  public static setQuestionnaireScore(questCacheService: QuestionnaireCacheService, questType: string, productCode: string) {
    if (questCacheService) {
      questCacheService.cache.forEach(
        map => {
          for (const questionnaire of Array.from(map.keys())) {
            if (questType === 'FIN' && questionnaire.questionnaireType.code === questType) {
              const questFlatI = map.get(questionnaire);
              if (questFlatI) {
                // @ts-ignore
                questCacheService.callEvaluationByProductCode(questionnaire, questFlatI, productCode).subscribe(resp => {
                  questFlatI.score = resp;
                });
                console.log(questFlatI);
              }
            }
          }
        }
      );
    }
  }

  public static getQuestTypeByLevel(level: QuestionnaireLevel): string {
    if (QUESTIONNAIRE_LEVEL_TO_QUEST_TYPE.has(level)) {
      return QUESTIONNAIRE_LEVEL_TO_QUEST_TYPE.get(level);
    } else {
      throw new Error('Unknown questionnaire level: ' + level);
    }
  }

  public static getPassQuestionnaireDefinitions(questionnaireDefinitions: QuestionnaireDefinition[]): QuestionnaireDefinition[] {
    const arr: QuestionnaireDefinition[] = questionnaireDefinitions
      .filter(definition => definition.typeCode === PASS_QUESTIONNAIRE_TYPE_CODE);
    return arr;
  }

  /* public static persistQuestionnaires(step, persistBeneficiaries: Function): Observable<any> {
    if (step === 'beneficiaries' && persistBeneficiaries) {
      return persistBeneficiaries();
    }

    return of(null);
  }

  public static checkAllQuestionnaires(step, checkAllQuestionnaires: Function, onError) {
    if (step === 'beneficiaries' && checkAllQuestionnaires()) {
      onError(step);
      return false;
    }
    return true;
  } */
}

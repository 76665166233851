import { Injectable } from '@angular/core';
import { Product } from '../models/policy.model';
import { EnviromentsService } from '../services/enviroments.service';
import { LifeSessionService } from './../services/life-session-service';

@Injectable({
    providedIn: 'root'
})
export class PassProMockService {

    protected baseApiUrl: string;
    public products: Product[] = [];

    private productsMocked = [
        {
            id: '5',
            code: '950',
            description: 'Assicredit',
            operations: []
        },
        {
            id: '6',
            code: '000006',
            description: 'TCM E',
            operations: []
        },
        {
            id: '11',
            code: '384C',
            description: 'Duo Assimoco Ed. 02/2017',
            operations: []
        },
        {
            id: '13',
            code: '902',
            description: 'Più protezione Assimoco',
            operations: []
        },
        {
          id: '15',
          code: '974',
          description: 'Sogni Sicuri',
          operations: []
        },
        {
          id: '20',
          code: '384D',
          description: 'Duetto',
          operations: []
        },
        {
          id: '21',
          code: '426',
          description: 'TCM Gruppo morte e invalidità',
          operations: []
        },
        {
          id: '18',
          code: '320E',
          description: 'Assolo Moderato',
          operations: []
        },
        {
          id: '22',
          code: '952',
          description: 'Assicredit prova Vita/Danni 1',
          operations: []
        },
        {
          id: '23',
          code: '953',
          description: 'Assicredit prova Vita/Danni 2',
          operations: []
        },
        {
          id: '26',
          code: '320C',
          description: 'Passo Libero Assimoco Ed. 03/2018',
          operations: []
        }
    ];

    constructor(private lifeSessionService: LifeSessionService,
                protected environment: EnviromentsService) {
        this.baseApiUrl = environment.baseApiURL;
    }


    getProducts(): Product[] {
        this.products = [];

        this.productsMocked.map((element) => {
          if ( this.lifeSessionService.getOperatorUsername() === 'ADMIN' ||
               this.lifeSessionService.getOperatorUsername() === 'AGENTE' ||
               element.code === '902' ||
               element.code === '974') {
            this.products.push(element);
          }
        });

        return this.products;

    }
}

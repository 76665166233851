import { LicObjectUtils } from './../../../../utils/lic-object-utils';
import { LicQuotationUtils } from './../../../../utils/lic-quotation-utils';
import {Component, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {UnitsComponent} from '../units.component';
import {Benefit, RiskInstallment, Unit} from '../../../../models/policy.model';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { TranslationWrapperService } from '../../../../../i18n/translation-wrapper.service';
import { PolicyService } from './../../../../services/policy-service';

@Component({
  selector: 'lic-units-select-group',
  templateUrl: './units-group.component.html',
  styleUrls: ['../units.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnitsGroupComponent extends UnitsComponent implements OnInit, OnChanges {

  unitsGrouped: {[key: string]: Unit[]} = {};

  constructor(
    protected policyService: PolicyService,
    protected translateService: TranslationWrapperService,
    protected rgiFormatter: RgiCountryLayerNumberFormatPipe
  ) {
    super(policyService, translateService, rgiFormatter);
  }

  ngOnInit() {
    this.unitsGrouped = this.groupUnits();
    super.ngOnInit();
  }

  private groupUnits() {
    return LicQuotationUtils.getUnitGroupedByTranscoderCode(this.policyService.mainProposal.quote.product.assets);
  }

  showUnit(unit: Unit) {
    const trancod: string = unit.transcoderCode1;
    if (trancod) {
      const units: Unit[] = this.unitsGrouped[trancod];
      return units.length && units[0].code === unit.code;
    }
    return false;
  }

  /** @Override */
  getPrestazione(riskcode: string, unit?: Unit) {
    if (!!unit && LicQuotationUtils.foundRiskOnGroup(this.unitsGrouped, riskcode, unit)) {
      const objExtracted = this.extractInstallmentsAndBenefits(LicQuotationUtils.getRisksOnGroup(this.unitsGrouped, unit));
      const prestazione = objExtracted
      .map(b => ('initialPerformance' in b) ? Number(b.initialPerformance) : 0)
      .reduce((acc, v) => acc += v, 0);
      const options: Intl.NumberFormatOptions = this.policyService.getFormatterOptionsWithDecimal('currency', '.2-2');
      return !!prestazione ? this.rgiFormatter.transform(prestazione, this.policyService.currentLocale, options) : '- - -';
    }
    return super.getPrestazione(riskcode);
  }

  /** @Override */
  getRataFirma(riskcode: string, unit?: Unit) {
    if (!!unit && LicQuotationUtils.foundRiskOnGroup(this.unitsGrouped, riskcode, unit)) {
      const objExtracted = this.extractInstallmentsAndBenefits(LicQuotationUtils.getRisksOnGroup(this.unitsGrouped, unit));
      const firstInstalment = objExtracted.map(r => ('totalPremium' in r) ? Number(r.totalPremium) : 0).reduce((acc, v) => acc += v, 0);
      const options: Intl.NumberFormatOptions = this.policyService.getFormatterOptionsWithDecimal('currency', '.2-2');
      return !!firstInstalment ? this.rgiFormatter.transform(firstInstalment, this.policyService.currentLocale, options) : '- - -';
    }
    return super.getRataFirma(riskcode);
  }

  /**
   * Extracts installments and benefits for the given risk codes.
   * @param riskcodes An array of risk codes to filter the installments and benefits.
   * @returns An array of RiskInstallment or Benefit objects that match the given risk codes.
   */
  private extractInstallmentsAndBenefits(riskcodes: string[]): (RiskInstallment | Benefit)[] {
    const installmentAndBenefits = (this.policyService.mainProposal.proposal.installment || [])
      .map(installment => [].concat(installment.riskInstallment || []).concat(this.policyService.mainProposal.proposal.benefit));
    return LicObjectUtils.cloneObject(!!installmentAndBenefits.length &&
    installmentAndBenefits
      .reduce((p, r) => p.concat(r))
      .filter(r => riskcodes.includes(r.riskCode)) ||
    []);
  }

}

import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicRequiredComponent } from './lic-required.component';

@NgModule({
    imports: [
        CommonModule,
        RgiRxI18nModule
    ],
    exports: [LicRequiredComponent],
    declarations: [LicRequiredComponent]
})
export class LicRequiredModule { }

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, tap} from "rxjs/operators";
import {EnvironmentService} from "../environment/environment.service";
import {
  DefaultAuthentication,
  LoginOperation,
  LoginService,
  LogoutOperation,
  UserAuthorizationService
} from "@rgi/rx/auth";

@Injectable()
export class PassproproAuthService extends LoginService {
  private _token: string;
  private _SSOtoken: string;

  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService, private userAuthorizations: UserAuthorizationService) {
    super()
  }

  private getSSOAuth(): Observable<any> {
    return this.httpClient.get(this.environmentService.environmentUrl + '/passpro/auth/sso')
      .pipe(
        map((data:any) => {
          this._SSOtoken = data.token;
          this.userAuthorizations.putAuthorizations(data.user.authorizations.map(p => p.authorization.auth));
          return this._SSOtoken
        })
      );
  }
  getSSOToken(): string {
    return this._SSOtoken;
  }

  $getSSOToken(): Observable<string> {
    if(this._SSOtoken){
      return of(this._SSOtoken);
    }
    return this.getSSOAuth();
  }

  initSSO(): Observable<any> {
    return this.getSSOAuth();
  }


  set token(value: string) {
    this._token = value;
  }

  set SSOtoken(value: string) {
    this._SSOtoken = value;
  }

  login(data: DefaultAuthentication): Observable<LoginOperation<any>> {
    return this.httpClient.post(this.environmentService.environmentUrl + "/passpro/auth",{
      password: data.password,
      username: data.username
    })
      .pipe(
        map((data: any) => {
          return {
            user: data.user,
            token: data.token
          }
        }),
        tap(data => {
          this.userAuthorizations.putAuthorizations(data.user.authorizations.map(p => p.authorization.auth));
        })
      )
  }

  logout(data: any): Observable<LogoutOperation<any>> {
    return of();
  }


}

import { ModalComponent } from '@rgi/portal-ng-core';
import { LifeProposalCardComponent } from '../life-card/life-proposal-card/life-proposal-card.component';
import { LicPreventiveCardComponent } from '../life-preventive-card/lic-preventive-card.component';
import {downgradeComponent} from '@angular/upgrade/static';
import { LifeCardComponent } from '../life-card/life-card.component';
import { lifePreventiveCard, newLifeCard, newLifeProposal } from './assets/issueCardsTemplates';


export enum LIC_AJS_DIRECTIVE {
  /** LIFE_PROPOSAL_CARD = 'licLifeProposalCard' */
  LIFE_PROPOSAL_CARD = 'licLifeProposalCard'/*  as 'licLifeProposalCard' */,

  /** LIFE_CARD_SESSION = 'licLifeCard' */
  LIFE_CARD_SESSION = 'licLifeCard' /* as 'licLifeCard' */,

  /** LIFE_PREVENTIVE_CARD = 'licPreventiveCard' */
  LIFE_PREVENTIVE_CARD = 'licPreventiveCard' /* as 'licPreventiveCard' */,
}

/** please use the consts declared on the Object {@link LIC_AJS_DIRECTIVE} */
export function getLifeIssueJSDirective(directive: LIC_AJS_DIRECTIVE) {
  return !!directive ? directive + 'Directive' : '';
}


// @ts-ignore
// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

const LIC_TPL_URL = 'assets/';

const factory = (templateKey, template) => ['$templateCache', ($templateCache) => {
  $templateCache.put(LIC_TPL_URL + templateKey, template);
}];

export function AjsConfigurationLife() {
  angular.module('core').config([
    'eventServiceProvider',
     (evProvider) => {
      evProvider.registerEvent('gettextLanguageChanged', {type: 'i18n'});
    }
  ]);


  registerLifeProposalCard();
  registerLifeCard();
  registerPreventiveCard();

  angular.module('app')
      .directive(
          'pncModal',
          downgradeComponent({component: ModalComponent})
      );

  // *********************************** */


  // Registrazione card life proposal
  function registerLifeProposalCard() {
    const lifeModule = angular.module('ptfall-card-finder');
    lifeModule.config([
      'gettext', 'cardManagementServiceProvider',
      (gettext, cardManagementServiceProvider) => {

        const LIFE_PROPOSAL_CARD = {
          title: gettext('Life Proposal'),
          tpl: '',
          name: 'newLifeProposal',
          functions: ['ptflife.proposal.new'],
          category: gettext('Contract Management'),
          type: 'main',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(LIFE_PROPOSAL_CARD);
      }
    ]);

    // register home route for the angularjs card
    console.log('registering module new life proposal route');
    lifeModule
    .run(factory('newLifeProposal.tpl.html', newLifeProposal))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('newLifeProposal', 'home', {
          template: 'assets/newLifeProposal.tpl.html',
          label: 'Home - New Life proposal',
          type: 'home',
          controller: 'AngularCommunicationCtrlIssue'
        });
      }
    ])
    .directive(
      LIC_AJS_DIRECTIVE.LIFE_PROPOSAL_CARD,
      downgradeComponent({component: LifeProposalCardComponent})
    );
  }

  /* REGISTRAZIONE CARD FLUSSO EMISSIONE VITA */
  function registerLifeCard() {
    const lifeModule = angular.module('ptfall-card-finder');
    lifeModule.config([
      'cardManagementServiceProvider', 'gettext',
      (cardManagementServiceProvider, gettext) => {
        const LIFE_CARD = {
          title: gettext('Life Proposal'),
          tpl: '',
          name: 'ptflifeProposal',
          category: '',
          type: '',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(LIFE_CARD);
      }
    ]);

    lifeModule
    .run(factory('newLifeCard.tpl.html', newLifeCard))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptflifeProposal', 'anag', {
          template: 'assets/newLifeCard.tpl.html',
          label: 'Home - New Life Card',
          type: 'home',
          controller: 'AngularCommunicationCtrlIssue'
        });
      }
    ])
    .directive(LIC_AJS_DIRECTIVE.LIFE_CARD_SESSION, downgradeComponent({component: LifeCardComponent}));
  }


  function registerPreventiveCard() {
    const lifeModule = angular.module('ptfall-card-finder');
    lifeModule.config([
      'gettext', 'cardManagementServiceProvider',
      (gettext, cardManagementServiceProvider) => {
        const PREVENTIVE_CARD = {
          title: gettext('Life Quote'),
          tpl: '',
          name: 'ptflifePreventive',
          category: 'Contract Management',
          type: 'main',
          functions: ['ptflife.preventive.issue'],
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(PREVENTIVE_CARD);
      }
    ])
    .run(factory('lifePreventiveCard.tpl.html', lifePreventiveCard))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptflifePreventive', 'home', {
          template: 'assets/lifePreventiveCard.tpl.html',
          label: 'Home - Life Preventive Card',
          type: 'home',
          controller: 'AngularCommunicationCtrlIssue'
        });
      }
    ])
    .directive(
      LIC_AJS_DIRECTIVE.LIFE_PREVENTIVE_CARD,
      downgradeComponent({component: LicPreventiveCardComponent})
    );
  }
}

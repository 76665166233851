import {Serialized18n} from "@rgi/rx/i18n";

export const EN: Serialized18n = {
  NG_PASSPROPRO_CORE: {
    "error": "error",
    "success": "success",
    "warning": "warning",
    "message": "message",
    "back": "BACK",
    "save": "SAVE",
    "newversion": "NEW VERSION",
    "edit": "MODIFY",
    "modified": "MODIFIED",
    "id": "Id",
    "uuid": "Uuid",
    "code": "Code",
    "name": "Name",
    "description": "Description",
    "required": "Required",
    "action": "Action",
    "no_matching_data": "No data matching",
    "open": "OPEN",
    "delete": "DELETE",
    "deleted": "DELETED",
    "find": "FIND",
    "new": "NEW",
    "select": "Select",
    "actions": "Actions",
    "confirm": "CONFIRM",
    "cancel": "CANCEL",
    "system_message": "SYSTEM MESSAGE",
    "input_date": "dd/mm/yyyy",
    "validity_start_date": "Validity start date",
  }
}

export const IT: Serialized18n = {
  NG_PASSPROPRO_CORE: {
    "error": "errore",
    "success": "successo",
    "warning": "attenzione",
    "message": "messaggio",
    "back": "INDIETRO",
    "save": "SALVA",
    "newversion": "NUOVA VERSIONE",
    "edit": "MODIFICA",
    "modified": "MODIFICATO",
    "id": "Id",
    "uuid": "Uuid",
    "code": "Codice",
    "name": "Nome",
    "description": "Descrizione",
    "required": "Obbligatorio",
    "action": "Azione",
    "no_matching_data": "Nessun dato trovato",
    "open": "APRI",
    "delete": "CANCELLA",
    "deleted": "CANCELLATO",
    "find": "TROVA",
    "new": "NUOVO",
    "select": "Seleziona",
    "actions": "Azioni",
    "confirm": "CONFERMA",
    "cancel": "ANNULLA",
    "system_message": "MESSAGGIO DI SISTEMA",
    "input_date": "gg/mm/aaaa",
    "validity_start_date": "Data inizio validità",
  }
}


export const FR: Serialized18n = {
  NG_PASSPROPRO_CORE: {
    "error": "Erreur",
    "success": "succès",
    "warning": "attention",
    "message": "message"
  }
}

export const DE: Serialized18n = {
  NG_PASSPROPRO_CORE: {
    "error": "error",
    "success": "erfolg",
    "warning": "attention",
    "message": "botschaft"
  }
}
export const ES: Serialized18n = {
  NG_PASSPROPRO_CORE: {
    "error": "error",
    "success": "éxito",
    "warning": "advertencia",
    "message": "mensaje"
  }
}

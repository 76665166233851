export enum StateTypes {

    STATE_FORWARDED = 'FORWARDED',
    STATE_REQUEST_PASS = 'REQUEST_PASS',
    STATE_TAKING_CHARGE = 'TAKING_CHARGE',
    STATE_RELEASED = 'RELEASED',
    STATE_ACCEPTED = 'ACCEPTED',
    STATE_REFUSED = 'REFUSED'

}

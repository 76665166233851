import {Component, ComponentRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Modal} from '../../modal';
import {Discount} from './discount';
import {QuotationService} from '../quotation.service';
import {SectionDiscounts} from './discount-section';
import {UnitDiscounts} from './discount-unit';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DiscountService} from './discount.service';
import {TotalDiscounts} from './total-discounts';
import {TableElement} from '@rgi/portal-ng-core';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {DiscountFund} from './discount-fund';
import {TranslateService} from '@ngx-translate/core';
import {ProposalService} from '../../proposal.service';

@Component({
  selector: 'mic-discounts',
  templateUrl: './discounts-modal.component.html',
  styleUrls: ['./discounts-modal.component.scss']
})
export class DiscountsModalComponent implements Modal, OnInit {
  componentRef: ComponentRef<any>;
  discounts: Array<Discount>;
  sectionsDiscounts: Array<SectionDiscounts> = [];
  discountsForm: UntypedFormGroup;
  @Input() @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  totalDiscounts: TotalDiscounts;
  tableElement: TableElement[][] = new Array<Array<TableElement>>();
  tableElementInherited: TableElement[][] = new Array<Array<TableElement>>();
  open = false;
  nestedTableHeader: TableElement[];
  residualDiscounts: Array<DiscountFund>;
  tableHeader: TableElement[];
  tableHeaderInherited: TableElement[];
  discountsHaveBeenChanged = false;
  editOfTotalValuesEnabled = false;
  validationMessages = [];

  constructor(
    protected quotationService: QuotationService,
    protected translate: TranslateService,
    protected discountService: DiscountService,
    protected datePipe: DatePipe,
    protected decimalPipe: DecimalPipe,
    public proposalService: ProposalService,
    protected currencyPipe?: CurrencyPipe
  ) {
  }

  ngOnInit(): void {
    this.totalDiscounts = new TotalDiscounts(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    this.initializeDiscounts();
    if (!this.proposalService.getIsInclusionApplication()) {
      this.initializeResidualDiscounts();
    }
  }

  save() {
    this.discountsHaveBeenChanged = false;
    this.validationMessages.length = 0;

    if (this.editOfTotalValuesEnabled) {
      const totalAmount1 = this.discountsForm.get('totalAmount1').value ? this.discountsForm.get('totalAmount1').value : 0;
      const totalAmount2 = this.discountsForm.get('totalAmount2').value ? this.discountsForm.get('totalAmount2').value : 0;
      const totalPerc1 = this.discountsForm.get('totalPerc1').value ? this.discountsForm.get('totalPerc1').value : 0;
      const totalPerc2 = this.discountsForm.get('totalPerc2').value ? this.discountsForm.get('totalPerc2').value : 0;

      const totalDiscountElement = this.discounts.find(element => {
        return element.productTotal === true;
      });

      if (totalDiscountElement) {
        if (totalDiscountElement.flex1Taxable !== Number(totalAmount1)) {
          this.discountsHaveBeenChanged = true;
          totalDiscountElement.flex1Taxable = totalAmount1;
          totalDiscountElement.flex1DiscountType = 2;
        }
      }
      if (totalDiscountElement) {
        if (totalDiscountElement.flex2Taxable !== Number(totalAmount2)) {
          this.discountsHaveBeenChanged = true;
          totalDiscountElement.flex2Taxable = totalAmount2;
          totalDiscountElement.flex2DiscountType = 2;
        }
      }
      if (totalDiscountElement) {
        if (totalDiscountElement.flex1Perc !== Number(totalPerc1)) {
          this.discountsHaveBeenChanged = true;
          totalDiscountElement.flex1Perc = totalPerc1;
          totalDiscountElement.flex1DiscountType = 3;
        }
      }
      if (totalDiscountElement) {
        if (totalDiscountElement.flex2Perc !== Number(totalPerc2)) {
          this.discountsHaveBeenChanged = true;
          totalDiscountElement.flex2Perc = totalPerc2;
          totalDiscountElement.flex2DiscountType = 3;
        }
      }
    } else {

      // let validForm = true;
      //
      // Object.keys(this.discountsForm.controls).forEach(field => {
      //   if (this.discountsForm.get(field).value === null
      //     || this.discountsForm.get(field).value === undefined) {
      //     this.discountsForm.get(field).setErrors({required: true});
      //     validForm = false;
      //   } else {
      //     const discountValue = Number(this.discountsForm.get(field).value);
      //     if ((field.startsWith('flex1Perc') || field.startsWith('flex2Perc'))
      //       && (discountValue < -100 || discountValue > 100)) {
      //       this.discountsForm.get(field).setErrors({outOfBounds: true});
      //       validForm = false;
      //     }
      //   }
      // });

      if (this.discountsForm.invalid) {
        this.validationMessages.push('Value/Values out of bounds.');
        return;
      }


      Object.keys(this.discountsForm.controls).forEach(field => {
        const sectionCode = field.split('-')[1];
        const unitCode = field.split('-')[2];
        const discountElement = this.discounts.find(element => {
          return element.sectionCode === sectionCode && element.unitCode === unitCode;
        });

        const discountValue = Number(this.discountsForm.get(field).value);

        if (field.startsWith('flex1Perc')) {
          if (discountElement) {
            if (discountElement.flex1Perc !== discountValue) {
              this.discountsHaveBeenChanged = true;
              discountElement.flex1Perc = discountValue;
              discountElement.flex1DiscountType = 3;
            }
          }
        } else if (field.startsWith('flex2Perc')) {
          if (discountElement) {
            if (discountElement.flex2Perc !== discountValue) {
              this.discountsHaveBeenChanged = true;
              discountElement.flex2Perc = this.discountsForm.get(field).value;
              discountElement.flex2DiscountType = 3;
            }
          }
        } else if (field.startsWith('flex1Taxable')) {
          if (discountElement) {
            if (discountElement.flex1Taxable !== discountValue) {
              this.discountsHaveBeenChanged = true;
              discountElement.flex1Taxable = this.discountsForm.get(field).value;
              discountElement.flex1DiscountType = 2;
            }
          }
        } else if (field.startsWith('flex2Taxable')) {
          if (discountElement) {
            if (discountElement.flex2Taxable !== discountValue) {
              this.discountsHaveBeenChanged = true;
              discountElement.flex2Taxable = this.discountsForm.get(field).value;
              discountElement.flex2DiscountType = 2;
            }
          }
        }
      });

    }

    this.quotationService.updateDiscounts(this.discounts).subscribe(
      (discounts) => {
        this.quotationService.calculatePremium(null).subscribe(
          (premium) => {
            this.initializeDiscounts();
          }
        );
      }
    );
  }

  close() {
    if (this.discountsHaveBeenChanged) {
      this.quotationService.sendRecalculatePremiumSignal();
    }
    this.componentRef.destroy();
  }

  toggleAccordion() {
    this.open = !this.open;
  }

  onDiscountChange(fieldName) {
    const discountValue = Number(this.discountsForm.get(fieldName).value);
    if (discountValue < -100 || discountValue > 100) {
      this.discountsForm.get(fieldName).setErrors({outOfBounds: true});
      return;
    }
  }

  getMaximumDiscount(unit: UnitDiscounts, field: string): number {
    switch (field) {
      case 'flex1Taxable':
        return unit.grossPremium * (unit.flex1Max / 100);
      case 'flex2Taxable':
        return unit.grossPremium * (unit.flex2Max / 100);
      case 'flex1Perc':
        return unit.flex1Max;
      case 'flex2Perc':
        return unit.flex2Max;
      default:
        return Infinity;
    }
  }

  private initializeDiscounts() {

    if (this.discountsForm) {
      this.discountsForm.reset();
    }

    if (this.sectionsDiscounts) {
      this.sectionsDiscounts.length = 0;
    }

    this.totalDiscounts.totalGrossPremium = 0;
    this.totalDiscounts.totalAmount1 = 0;
    this.totalDiscounts.totalAmount2 = 0;
    this.totalDiscounts.totalEffective1 = 0;
    this.totalDiscounts.totalEffective2 = 0;
    this.totalDiscounts.totalMax1 = 0;
    this.totalDiscounts.totalMax2 = 0;
    this.totalDiscounts.totalPerc1 = 0;
    this.totalDiscounts.totalPerc2 = 0;
    this.totalDiscounts.totalMin1 = 0;
    this.totalDiscounts.totalMin2 = 0;

    this.quotationService.getDiscounts().subscribe(
      (discounts) => {
        this.discounts = discounts;
        this.discounts.forEach(
          (discount) => {

            if (discount.productTotal) {

              this.editOfTotalValuesEnabled = true;

              this.totalDiscounts.totalGrossPremium = discount.grossPremium;
              this.totalDiscounts.totalAmount1 = discount.flex1Taxable;
              this.totalDiscounts.totalAmount2 = discount.flex2Taxable;
              this.totalDiscounts.totalEffective1 = discount.flex1DiscountType === 2
                ? discount.flex1EffectiveTaxable : discount.flex1EffectivePerc;
              this.totalDiscounts.totalEffective2 = discount.flex2DiscountType === 2
                ? discount.flex2EffectiveTaxable : discount.flex2EffectivePerc;
              this.totalDiscounts.totalMax1 = discount.flex1Max;
              this.totalDiscounts.totalMax2 = discount.flex2Max;
              this.totalDiscounts.totalPerc1 = discount.flex1Perc;
              this.totalDiscounts.totalPerc2 = discount.flex2Perc;
              this.totalDiscounts.totalMin1 = discount.flex1Min;
              this.totalDiscounts.totalMin2 = discount.flex2Min;

            } else {

              let section: SectionDiscounts = this.sectionsDiscounts.find(
                (sectionDiscounts) => sectionDiscounts.sectionCode === discount.sectionCode
              );

              if (!section) {
                section = new SectionDiscounts(discount.sectionId, discount.sectionCode, discount.sectionDescription,
                  discount.productTotal, null, false);
                this.sectionsDiscounts.push(section);
              }

              let units: Array<UnitDiscounts> = section.unitDiscounts;
              if (!units) {
                units = [];
                section.unitDiscounts = units;
              }
              units.push(new UnitDiscounts(discount.unitId, discount.unitCode, discount.unitDescription, discount.flex1Min,
                discount.flex1Max, discount.flex1Perc, discount.flex1Taxable, discount.flex1EffectiveTaxable, discount.flex1EffectivePerc,
                discount.flex1DiscountType, discount.flex1Enable, discount.flex2Min,
                discount.flex2Max, discount.flex2Perc, discount.flex2Taxable, discount.flex2EffectiveTaxable, discount.flex2EffectivePerc,
                discount.flex2DiscountType, discount.flex2Enable, discount.grossPremium));

              if (discount.flex1Enable || discount.flex2Enable) {
                section.enabled = true;
              }

            }
          }
        );
        this.discountsForm = this.discountService.toFormGroup(this.sectionsDiscounts, this.proposalService.getIsInclusionApplication());

        if (this.editOfTotalValuesEnabled) {
          this.discountsForm.addControl('totalPerc1', new UntypedFormControl(this.totalDiscounts.totalPerc1));
          this.discountsForm.addControl('totalPerc2', new UntypedFormControl(this.totalDiscounts.totalPerc2));
          this.discountsForm.addControl('totalAmount1', new UntypedFormControl(this.totalDiscounts.totalAmount1));
          this.discountsForm.addControl('totalAmount2', new UntypedFormControl(this.totalDiscounts.totalAmount2));
        }

        if (!this.editOfTotalValuesEnabled) {
        this.sectionsDiscounts.forEach(
          (section) => {
            section.unitDiscounts.forEach(
              (unit) => {
                this.totalDiscounts.totalGrossPremium = this.totalDiscounts.totalGrossPremium + unit.grossPremium;
                this.totalDiscounts.totalAmount1 = this.totalDiscounts.totalAmount1 + unit.flex1Taxable;
                this.totalDiscounts.totalAmount2 = this.totalDiscounts.totalAmount2 + unit.flex2Taxable;
                this.totalDiscounts.totalEffective1 = this.totalDiscounts.totalEffective1 + unit.flex1EffectiveTaxable;
                this.totalDiscounts.totalEffective2 = this.totalDiscounts.totalEffective2 + unit.flex2EffectiveTaxable;
                this.totalDiscounts.totalMax1 = this.totalDiscounts.totalMax1 + unit.flex1Max;
                this.totalDiscounts.totalMax2 = this.totalDiscounts.totalMax2 + unit.flex2Max;
                this.totalDiscounts.totalPerc1 = this.totalDiscounts.totalPerc1 + unit.flex1Perc;
                this.totalDiscounts.totalPerc2 = this.totalDiscounts.totalPerc2 + unit.flex2Perc;
                this.totalDiscounts.totalMin1 = this.totalDiscounts.totalMin1 + unit.flex1Min;
                this.totalDiscounts.totalMin2 = this.totalDiscounts.totalMin2 + unit.flex2Min;
              }
            );
          }
        );
        }
      }
    );
  }

  private initializeResidualDiscounts() {

    this.nestedTableHeader = [new TableElement(this.translate.instant('Period')), new TableElement(this.translate.instant('Amount')),
      new TableElement(this.translate.instant('Residual Amount'))];

    this.tableHeader = [new TableElement(this.translate.instant('Company')), new TableElement(this.translate.instant('Code')),
      new TableElement(this.translate.instant('Share Residual Discounts')),
      new TableElement(this.translate.instant('Residual Discounts Type'))];

    this.tableHeaderInherited = [new TableElement(this.translate.instant('Discounts Inherited From Parents')),
      new TableElement('')];

    const idPvContract = this.quotationService.getProposalService().getNodeId();

    this.quotationService.getResidualDiscount().subscribe(
      (data) => {
        this.residualDiscounts = data;
        this.residualDiscounts.forEach(
          (residualDiscount) => {

            if (Number(idPvContract) === residualDiscount.pvId) {
              const tableRow: TableElement[] = [
                new TableElement(String(residualDiscount.companyDesc + ' - ' + residualDiscount.discountFundBranchDesc),
                  'span', String(residualDiscount.objectId)),
                new TableElement(String(residualDiscount.discountFundBranchCode), 'span', String(residualDiscount.objectId)),
                new TableElement(String(residualDiscount.shareDiscountFund ?
                  'Yes' : 'No'), 'span', String(residualDiscount.objectId)),
                new TableElement(String(residualDiscount.discountType), 'span', String(residualDiscount.objectId))];
              this.tableElement.push(tableRow);
              const companyResidualDiscount = this.residualDiscounts.find(
                (element) => element.objectId === Number(residualDiscount.objectId));

              const residualDiscountPeriods = [];

              if (companyResidualDiscount && companyResidualDiscount.discountFundPeriods) {
                companyResidualDiscount.discountFundPeriods.forEach(
                  (residualDiscountPeriod) => {
                    const startDate = residualDiscountPeriod.startDate
                      ? this.datePipe.transform(new Date(residualDiscountPeriod.startDate), 'dd/MM/yyyy') : '-';
                    const endDate = residualDiscountPeriod.endDate
                      ? this.datePipe.transform(new Date(residualDiscountPeriod.endDate), 'dd/MM/yyyy') : '-';
                    const residual = this.currencyPipe.transform(residualDiscountPeriod.residualAmount, 'EUR', 'symbol', '1.2-2', 'it');
                    const residualAmount = this.currencyPipe.transform(residualDiscountPeriod.amount, 'EUR', 'symbol', '1.2-2', 'it');

                    const valueToPushElement: TableElement[] = [
                      new TableElement(String((this.translate.instant('Start') + ': ' +
                          startDate) + ' - ' + this.translate.instant('End') + ': '
                        + endDate), 'span'),
                      new TableElement(String(residualAmount), 'span'),
                      new TableElement(String(residual), 'span')];

                    residualDiscountPeriods.push(valueToPushElement);
                  }
                );
              }
              this.tableElement.push(residualDiscountPeriods);
            } else {
              // Residual Discount Inherited
              const tableRow: TableElement[] = [
                new TableElement(String(residualDiscount.companyDesc + ' - ' + residualDiscount.discountFundBranchDesc),
                  'span', String(residualDiscount.objectId)),
                new TableElement(String(this.translate.instant('Inherited From: ') + residualDiscount.pvDesc),
                  'span', String(residualDiscount.objectId))];

              this.tableElementInherited.push(tableRow);
            }
          }
        );
      }
    );
  }

}

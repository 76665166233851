<div class="error-card-home" data-qa="app-form-message">
  <div class="content-error"
       [ngClass]="{
         'validate-error': type == messageType.error,
         'validate-warning': type == messageType.warning,
         'validate-autho': type == messageType.authorization,
         'validate-information': type == messageType.info
       }">
    <ul class="messages" *ngFor="let message of messages;let i = index">
      <li class="message" attr.data-qa="message-{{i}}" *ngIf="objectType === messageObjectType.simple">
        <span class="rgifont message-icon"
              [ngClass]="{
                'rgi-exclamation-triangle': type == messageType.error,
                'rgi-exclamation-circle': type == messageType.warning,
                'rgi-refresh': type == messageType.authorization,
                'rgi-info': type == messageType.info
              }"></span>
        {{(message.text || message) | translate}}
      </li>
      <li class="message" attr.data-qa="message-{{i}}" *ngIf="objectType === messageObjectType.complex">
      <span class="rgifont message-icon"
            [ngClass]="{'rgi-exclamation-triangle': type == messageType.error, 'rgi-exclamation-circle': type == messageType.warning}"></span>
        {{(message.text || message) | translate}}
      </li>
    </ul>
  </div>
</div>

import {AdditionalProperty} from "../../domain-models/additional-property";

export class PcClause {
  id?: string;
  assetId?: string;
  code?: string;
  description?: string;
  clauseType?: string;
  selection?: boolean;
  textType?: string;
  text?: string;
  properties?: Array<AdditionalProperty>;
  enable?: boolean;
  selected?: boolean;
  mandatoryText?: boolean;
  visible?: boolean;

}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicTableUtilService {

  constructor() {
  }

  generateDynamicTable(objectsArray): Map<number, object[]> {
    let i = 0;
    const numberArray = [];
    objectsArray.map(() => {
      numberArray.push(i);
      i++;
    });
    return this.chunkSize(numberArray, 3, objectsArray);
  }

  composeArray(nRow: number): number[] {
    return Array(nRow).fill(0).map((x, i) => i);
  }

  chunkSize(array: number[], size: number, objectsArray): Map<number, object[]> {
    const indexMap = new Map<number, object[]>();
    let counter = 0;
    [].concat.apply([],
      array.map((i) => {
        const objectsForEachRow = [];
        if (i % size === 0) {
          array.slice(i, i + size).map((element) => objectsForEachRow.push(objectsArray[element]));
          indexMap.set(counter, objectsForEachRow);
          counter++;
        }
      })
    );
    return indexMap;
  }

  putDataOnDynamicTable(table: object[], key: string, value: string) {
    if (value) {
      table.push({ chiave: key, valore: value });
    }
  }

  deepClone(obj): any {
    // If it's not an array or an object, returns null
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    let cloned;
    // Handle: Date
    if (obj instanceof Date) {
      cloned = new Date(obj.getTime());
      return cloned;
    }
    // Handle: array
    // @ts-ignore
    if (obj instanceof Array) {
      cloned = [];
      obj.forEach(el => cloned.push(this.deepClone(el)));
      return cloned;
    }
    // Handle: object
    cloned = {};
    let i;
    for (i in obj) {
      if (obj.hasOwnProperty(i)) {
        cloned[i] = this.deepClone(obj[i]);
      }
    }
    return cloned;
  }
}

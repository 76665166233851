import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ApiKeyValue, ApiPolicyFilter, CardComponent,
  CardComponentService, Party, Policy,
  PolicyService, SessionService } from '@rgi/digital-claims-common-angular';
import { DamageCategory } from '../claim-opening/components/claim-opening-policy-search/dto/damage-categories';
import { DamageCategoryService } from '../claim-opening/damage-category.service';

@Component({
  selector: 'claims-claim-limited-detail',
  templateUrl: './claim-limited-detail.component.html',
  styleUrls: ['./claim-limited-detail.component.scss']
})
export class ClaimLimitedDetailComponent extends CardComponent implements OnInit {

  claim: any;
  party: Party;
  display = true;
  policy = new Policy();
  salesPointCode: number;
  salesPointId: number;
  companyCode: string;
  errorMessage: string;
  damageCategories = null;
  damageCategoryCssClass: string;
  claimTechnicalStatusDescription: string;


  constructor(
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private policyService: PolicyService,
    private damageCategoryService: DamageCategoryService,
    @Inject('coreResult') private coreResult: any,
    @Inject('authService') auth: any,
    @Inject('sessionService') private session: any,
  ) {
    super(cardComponentService, sessionService);
    this.salesPointCode = auth.getOperator().salePoint.code;
    this.salesPointId = auth.getOperator().salePoint.objectId;
    this.companyCode = auth.getOperator().salePoint.company ? auth.getOperator().salePoint.company.code : null;

  }

  ngOnInit() {
    super.ngOnInit();
    this.claim = this.coreResult.getResult(this.id, 'claim');
    // this.getPolicyDetail(this.claim);
    this.policy.holder = this.claim.contractor;

    if (this.claim.idDamageCategory) {
      this.damageCategoryService.getCategory(this.claim.idDamageCategory).subscribe(
        (damageCategory: DamageCategory) => {
          this.damageCategoryCssClass = damageCategory.cssClass;
        },
        (error: Error) => {
          console.log(error);
        }
      );
    }

    if (this.claim.status && this.claim.status.descrizione) {
      // pes
      this.claimTechnicalStatusDescription = this.claim.status.descrizione;
    } else if (this.claim.claimTechnicalStatus) {
      // claim
      this.claimTechnicalStatusDescription = this.claim.claimTechnicalStatus;
    } else {
      this.claimTechnicalStatusDescription = '-';
    }

  }

  // getPolicyDetail(policyNumber: string) {

  //   // TODO avvia rotator nuova su singolo campo se si puo..
  //   const req = {
  //     filterPolicies: null
  //   };
  //   const filter = new ApiPolicyFilter();
  //   filter.policyNumber = policyNumber;
  //   filter.salePointCode = this.salesPointCode;
  //   filter.salePointId = this.salesPointId;
  //   filter.extension = { properties: [new ApiKeyValue('GET_INFO_SX', '1')] };
  //   req.filterPolicies = filter;

  //   let policiesRes = [];
  //   // const policies: Policy[] = [];
  //   this.policyService.getPoliciesList(req)
  //     .subscribe((response: any) => {
  //       policiesRes = response.policies;
  //       if (policiesRes && policiesRes.length > 0) {
  //         policiesRes.forEach(policyRes => {
  //           // const policy = new Policy();
  //           // policy.number = policyRes.policyNumber;
  //           // policy.description = policyRes.description;
  //           // policy.effectDate = policyRes.effectDate;
  //           // policy.expirationDate = policyRes.expirationDate;
  //           this.policy.holder = policyRes.contractingSubject;
  //           // policy.objectId = policyRes.objectId;
  //           // policy.product = policyRes.product;
  //           // policy.nodeCode = policyRes.nodeCode;
  //           // policy.originalEffectDate = policyRes.originalEffectDate;
  //           // if (policyRes.extension) {
  //           //   policy.units = this.getUnits(policyRes.extension.properties);
  //           //   policy.claimBranchCodes = this.getClaimBranchCodes(policyRes.extension.properties);
  //           // }
  //           // policies.push(policy);
  //         });
  //       } else {
  //         // this.errorMessage = 'No results.';
  //       }
  //       // TODO stoppa rotator
  //     },
  //       (error: Error) => {
  //         console.log(error);
  //         // this.errorMessage = 'Search error';
  //       // TODO stoppa rotator
  //     }
  //     );

  // }

  exit() {
    const session = this.getCurrentSession();
    this.session.remove(session.idSession);
  }

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AngularCommunicationService {

  private $communication: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public get communication(): Observable<string> {
    return this.$communication.asObservable();
  }

  public communicate(param: any): void {
    this.$communication.next(param);
  }

}

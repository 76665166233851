import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'rgi-rx-loading-overlay-host',
  template: ``,
})
export class RgiRxLoadingOverlayHostComponent {

  private _loaderClass = 'rgi-ui-loader';


  @HostBinding('class')
  @Input() get loaderClass(): string {
    return this._loaderClass;
  }

  set loaderClass(value: string) {
    this._loaderClass = value;
  }
}

import { EventEmitter, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';


import { Subscription } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { ClaimType, Insured } from '../../../models/claim-report.model';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { RequestFactor } from '../../../models/factor.model';
import { InputFieldDefinition } from '../../../models/postsales-operations-response.model';
import { LpcFactorAdapterComponent } from '../../../modules/lpc-factor-adapter/lpc-factor-adapter.component';

import { ClaimReportService } from '../../../services/claim-report.service';
import { CustomModalService } from '../../../services/custom-modal.service';
import { PlcDateUtils } from '../../../utils/plc-date-utils';


@Component({
  selector: 'lpc-claim-insert',
  templateUrl: './claim-insert.component.html',
  styleUrls: ['./claim-insert.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimInsertComponent implements OnInit {

  @ViewChild('factorAdapter') child: LpcFactorAdapterComponent;

  @Input() data: any;
  public insured: Insured;
  public errors: string[] = [];
  public claimTypes: ClaimType[];
  private userId: any;
  private username: any;
  public factors: InputFieldDefinition[];
  public formGroup: UntypedFormGroup;
  public requestFactors = [];
  public updateMode = false;
  public newClaimVisible = false;

  private $subscription: Subscription[] = [];
  @Output() eventPropagation = new EventEmitter<any>();

  constructor(
    protected claimService: ClaimReportService,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any,
    protected translateService: TranslationWrapperService,
    protected customModalService: CustomModalService
  ) { }

  ngOnInit() {
    if (!!this.data) {
      this.updateMode = !!this.data.claimDetail;
    }
    this.userId = this.authService.getOperator().objectId;
    this.username = this.authService.getOperator().username;
    this.createForm();
    this.eventPropagation.emit({
      eventName: JS_EVENT.SET_TITLE,
      title: this.translateService.getImmediate('lpc_denuncia_sinistro')
    });
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.$subscription.push(this.claimService.getNewClaim(this.claimService.getSubjectId(), this.claimService.getNodeId())
    .subscribe(response => {
      this.insured = response.insured as Insured;
      this.claimTypes = response.claimFactorConfiguration as ClaimType[];
      this.newClaimVisible = true;
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    }));
    this.formGroup.get('data').valueChanges.subscribe(el => {
      if (this.formGroup.get('data').valid) {
        this.errors = [];
      }
    });
  }

  ngOnDestroy(): void {
    this.$subscription.forEach(subscription => subscription.unsubscribe());
  }

  public createForm() {
    const claimDetail = this.data.claimDetail;
    this.formGroup = new UntypedFormGroup({
      data: new UntypedFormGroup({
        notificationDate: new UntypedFormControl(!!claimDetail ? PlcDateUtils.isoToDate(claimDetail.notificationDate) : null, {
          validators: [Validators.required]
        }),
        reportDate: new UntypedFormControl(!!claimDetail ? PlcDateUtils.isoToDate(claimDetail.reportDate) : null, {
          validators: [Validators.required]
        }),
        claimDate: new UntypedFormControl(!!claimDetail ? PlcDateUtils.isoToDate(claimDetail.claimDate) : null, {
          validators: [Validators.required]
        }),
        claimTypeId: new UntypedFormControl(!!claimDetail ? claimDetail.type.codice : null, {
          validators: [Validators.required]
        }),
        factors: new UntypedFormGroup({}),
        idUser: new UntypedFormControl(this.userId),
        username: new UntypedFormControl(this.username),
        idSubject: new UntypedFormControl(this.claimService.getSubjectId())
      })
    });
    if (!!claimDetail) {
      this.factors = claimDetail.factors.map(factor => {
        factor.editable = true;
        return factor;
      });
    }
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_CLAIM_LIST);
  }

  backToDetail() {
    this.eventPropagation.emit({eventName: JS_EVENT.OPEN_CLAIM_DETAIL, claimId:  this.data.claimDetail});
  }

  onChange(event) {
    this.resetFactors();
    this.formGroup.get('data').get('claimTypeId').setValue(event);
    this.filterFactorsByIdClaim(event);
  }

  public resetFactors() {
    const data = this.formGroup.controls.data as UntypedFormGroup;
    const factors = data.controls.factors as UntypedFormGroup;
    Object.keys(factors.controls).forEach((key) => {
      (factors).removeControl(key);
    });
  }

  filterFactorsByIdClaim(idClaim: string) {
    const config = this.claimTypes.find(claim => claim.type.codice === idClaim);
    this.factors =  !!config ?  config.factors : [];
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestFactors = factors;
  }

  onSave() {
    this.errors = [];
    if (this.formGroup.get('data').valid) {
      const request =  this.createRequest();
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      this.claimService.saveClaim(request).subscribe(response => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        if (!response.errors.length) {
          this.$subscription.push(
            fromPromise(
              this.customModalService.openModal(
                this.translateService.getImmediate('lpc_Conferma_inserimento_denuncia'),
                this.getClaimConfirmationMessage(response), true
              ).result).subscribe(result => {
              if (result) {
                this.eventPropagation.emit(JS_EVENT.BACK_TO_CLAIM_LIST);
              }
            })
          );
        } else {
          this.errors = this.errors.concat(response.errors.map(error => error.errorDescription));
        }
      });
    } else {
      this.formGroup.get('data').updateValueAndValidity();
      // ciclo il form e setto i dirty
      /* Object.keys((this.formGroup.get('data') as FormGroup).controls).forEach(key => {
        this.formGroup.get('data').get(key).markAsDirty();
        if (key === 'factors') {
          Object.keys((this.formGroup.get('data').get(key) as FormGroup).controls).forEach(factor => {
            this.formGroup.get('data').get(key).get(factor).markAsDirty();
          });
        }
      }); */
      this.errors.push(
        this.translateService.getImmediate('lpc_error_mandatory_fields')
      );
    }
  }

  protected getClaimConfirmationMessage(response: any): string {
    return this.translateService.getImmediate('lpc_Denuncia_inserita_correttamente');
  }

  onUpdate() {
    console.log(this.formGroup.get('data').value, 'rawvalue');
    this.errors = [];
    if (this.formGroup.get('data').valid) {
      const request = this.createRequest();
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      this.claimService.updateClaim(this.data.claimDetail.id, request).subscribe(response => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        if (!response.errors.length) {
          this.$subscription.push(
            fromPromise(
              this.customModalService.openModal(
                this.translateService.getImmediate('lpc_confirmed_claim_modify'),
                this.translateService.getImmediate('lpc_claim_modified_correctly'), true).result
            ).subscribe(result => {
              if (result) {
                this.eventPropagation.emit(JS_EVENT.BACK_TO_CLAIM_LIST);
              }
            })
          );
        } else {
          this.errors = this.errors.concat(response.errors.map(error => error.errorDescription));
        }
      });
    } else {
      this.errors.push(
        this.translateService.getImmediate('lpc_error_mandatory_fields')
      );
    }
  }

  createRequest(): {} {
    return  {
      idUser: this.userId,
      userName: this.username,
      claimDate: this.formGroup.get('data').get('claimDate').value,
      reportDate: this.formGroup.get('data').get('reportDate').value,
      notificationDate: this.formGroup.get('data').get('notificationDate').value,
      claimTypeId: this.formGroup.get('data').get('claimTypeId').value,
      factors: this.requestFactors,
      idSubject: this.claimService.getSubjectId(),
      idNode: this.claimService.getNodeId()
    };
  }

}

<form [formGroup]="occurrenceDataForm">
  <div class="form-group row">
    <div class="col-sm-6 col-xs-12">
      <pnc-datepicker label="{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}" customFormControlName="occurrenceDate"
        [customFormGroup]="occurrenceDataForm" [disabled]="true" attr.data-qa="occurrence-data-panel-occurrence-date"
        id="occurrenceDate">
      </pnc-datepicker>
    </div>
    <div class="col-sm-6 col-xs-12">
      <div>
        <label class="label-core" attr.data-qa="policy-search-plate-label">{{ '_CLAIMS_._OCCURRENCE_HOUR' | translate }}
        </label>
      </div>
      <input class="col-sm-12 form-control" formControlName="occurrenceTime" [disabled]="true" type="text"
        name="occurrenceTime" attr.data-qa="occurrence-data-panel-hour">
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-4 col-xs-12">
      <pnc-datepicker label="{{ '_CLAIMS_._COMPLAINT_DATE' | translate }}" customFormControlName="complaintDate"
        [customFormGroup]="occurrenceDataForm" attr.data-qa="occurrence-data-panel-complaint-date" [minDate]="minDate"
        [maxDate]="maxDate" [disabled]="!enabled" id="complaintDate" (change)="defMinDateIntermediary()"
        (blur)="defMinDateIntermediary()" (keyup)="defMinDateIntermediary()" (click)="defMinDateIntermediary()">
        <pnc-required required="true"></pnc-required>
      </pnc-datepicker>
    </div>
    <div class="col-sm-4 col-xs-12" *ngIf="this.manualInsertionIntermediaryDateEnabled">
      <pnc-datepicker label="{{ '_CLAIMS_._DATE_INTERMEDIARY' | translate }}" customFormControlName="intermediaryDate"
        [customFormGroup]="occurrenceDataForm" attr.data-qa="occurrence-data-panel-intermediary-Date"
        [minDate]="minDateIntermediary" [maxDate]="maxDate" [disabled]="!enabled" id="intermediaryDate">
        <pnc-required required={{manualInsertionIntermediaryDateEnabled}}></pnc-required>
      </pnc-datepicker>
    </div>
    <div class="col-sm-4 col-xs-12">
      <label ng-bind="label">{{ '_CLAIMS_._TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED' | translate }} <pnc-required
          required="true">
        </pnc-required></label>
      <select class="core-select form-control" formControlName="authority" id="authority" name="authority" (change)="codeAuthorityF()"
        attr.data-qa="occurrence-data-panel-authority-input">
        <option></option>
        <option *ngFor="let authority of authorities; " value={{authority.codeAuthority}} [ngValue]="authority.codeAuthority" >
          {{authority.descriptionAuthority}}</option>
      </select>
      <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6 col-xs-12">
      <label class="label-core" attr.data-qa="occurrence-data-panel-externalnumber-label">{{
        '_CLAIMS_._EXTERNAL_CLAIM_NUMBER' | translate }}</label>
      <input class="form-control" formControlName="externalNumber" id="externalNumber" type="text" name="externalNumber"
        attr.data-qa="occurrence-data-panel-externalNumber-input" maxlength="30">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-4 col-xs-12" *ngIf="isCategoriaRE && openinModeEnable">
      <pnc-datepicker label="{{ '_CLAIMS_._LAST_AUDIT_DATE' | translate }}" customFormControlName="auditDate"
        [customFormGroup]="occurrenceDataForm" attr.data-qa="occurrence-data-panel-auditDate-input"
        [disabled]="!enabled" id="auditDate">
      </pnc-datepicker>
    </div>
    <div class="col-sm-4 col-xs-12" *ngIf="catastrEventEnable">
      <label ng-bind="label">{{ '_CLAIMS_._CATASTROPHIC_EVENT_DESCRIPTION' | translate }} </label>
      <select class="core-select form-control" formControlName="idCatastrEvent" id="idCatastrEvent"
        name="idCatastrEvent" attr.data-qa="occurrence-data-panel-idCatastrEvent-input">
        <option></option>
        <option *ngFor="let catEv of catastrEvents; " value={{catEv.code}}>
          {{catEv.description}}</option>
      </select>
      <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>
    <div class="col-sm-4 col-xs-12" *ngIf="openinModeEnable">
      <label ng-bind="label">{{ '_CLAIMS_._OPENING_MODE' | translate }}</label> <pnc-required
        required={{openinModeEnable}}></pnc-required>
      <select class="core-select form-control" formControlName="idOpenMode" id="idOpenMode" name="idOpenMode"
        attr.data-qa="occurrence-data-panel-idOpenMode-input">
        <option></option>
        <option *ngFor="let opm of openingModes; " value={{opm.code}}>
          {{opm.description}}</option>
      </select>
      <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-4 col-xs-12" *ngIf="insuredActivities?.length > 0">
      <label ng-bind="label">{{ '_CLAIMS_._INSURED_ACTIVITY' | translate }}</label>
      <select class="core-select form-control" formControlName="insuredActivity" id="insuredActivity"
        name="insuredActivity" attr.data-qa="occurrence-data-panel-insuredActivity-input">
        <option></option>
        <option *ngFor="let insuredAct of insuredActivities; " value={{insuredAct.idObject}}>
          {{insuredAct.description}}</option>
      </select>
      <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>
  </div>

  <div *ngIf="damageCategory?.certifiedOccurrence && certifiedLossDateEnable" class="form-group row">
    <div class="col-sm-6 col-xs-12">
      <input type="checkbox" class="form-control btn-checkbox checkbox-unit" formControlName="certifiedOccurrence"
        attr.data-qa="dynamic-field-checkbox-certifiedOccurrence">
      <label class="checkbox-label" for="{{damageCategory.certifiedOccurrence}}">
        {{ '_CLAIMS_._CERTIFIED_OCCURRENCE_DATE' | translate }}</label>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6 col-xs-12">
      <input type="checkbox" class="form-control btn-checkbox checkbox-unit" formControlName="corporateLitigation" />
      <label class="checkbox-label" for="corporateLitigation" *ngIf="showCorporateLitigation">
        {{ '_CLAIMS_._CORPORATE_LITIGATION' | translate }}
      </label>
      <label class="checkbox-label" for="corporateLitigation" *ngIf="!showCorporateLitigation">
        {{ '_CLAIMS_._OPENING_DUE_TO_WRIT_OF_SUMMONS' | translate }}
      </label>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-12 col-xs-12">
      <label ng-bind="label">{{ '_CLAIMS_._OCCURRENCE_DESCRIPTION' | translate }}:</label>
      <textarea class="col-sm-12 form-control" formControlName="occurrenceDescription" id="occurrenceDescription"
        rows="3" type="text" name="occurrenceDescription" maxlength="255"
        attr.data-qa="occurrence-data-panel-description-input"></textarea>
    </div>
  </div>

  <div class="form-group row" *ngIf="showNoteDirection">
    <div class="col-sm-12 col-xs-12">
      <label ng-bind="label">{{ '_CLAIMS_._MANAGEMENT_NOTE' | translate }}:</label>
      <textarea class="col-sm-12 form-control" formControlName="noteDirection" id="noteDirection" rows="3" type="text"
        name="noteDirection" attr.data-qa="occurrence-data-panel-description-input"></textarea>
    </div>
  </div>

  <claims-dsc-address-detail-component [typeOpenComponent]="enumTypeIsTitleComponent"
        [titleAddress]=""
        [addressData]="addressData" (outputFormAddress)="outputFormAddress($event)">
  </claims-dsc-address-detail-component>

  <div class="form-group row">
    <div class="col-sm-12 col-xs-12" *ngIf="enabled">
      <claims-injury-code *ngIf="injuryCodeEnable" [injuryEntity]="injuryCodeEntity" [dataForm]="occurrenceDataForm"
        [enabled]="true"></claims-injury-code>
    </div>
    <div class="col-sm-12 col-xs-12" *ngIf="!enabled">
      <claims-injury-code *ngIf="injuryCodeEnable" [injuryEntity]="injuryCodeEntity" [dataForm]="occurrenceDataForm"
        [enabled]="false"></claims-injury-code>
    </div>
  </div>

  <div id="error-container" style="margin-top: 2em" *ngIf="validationMessages && validationMessages.length > 0">
    <pnc-form-message type="error" detail="validationError" [messages]="validationMessages"
      data-qa="occurrence-data-panel-action-error-messages" objectType="complex"></pnc-form-message>
  </div>

  <div class="modal-footer" *ngIf="enabled">
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button class="btn btn-primary colorBGBlu2" (click)="onConfirm()" data-qa="occurrence-data-panel-confirm-button">
          {{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}
        </button>
      </div>
    </div>
  </div>

</form>
import {Inject, Injectable, Optional} from '@angular/core';
import {RgiRxUserAuthorizationService, RgiRxUserService} from "@rgi/rx/auth";

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained.
 * Use @rgi/rx/auth RgiRxUserService instead or the @rgi/admin RgiAdminUserService if you're working in a @rgi/portal
 * application.
 * Internally uses RgiRxUserService, RgiRxUserAuthorizationService and authService to provide the operator facade.
 */
export class OperatorService {

  constructor(private userService: RgiRxUserService,
              private userAuthorization: RgiRxUserAuthorizationService,
              @Optional() @Inject('authService') private authService?: any
  ) {}



  getUserCode(): string {
    return this.userService.getUser<any>().username;
  }

  getSalePointLogin(): any {
    return this.userService.getUser<any>().salePoint;
  }

  getEnabledFeatures(): string[] {
    return this.userAuthorization.getAuthorizations();
  }

  getSalePointDefault(): any {
    if (!this.authService) {
      throw new Error('OperatorService: authService is not provided in the DI. Probably you are not in the context of an Hybrid Portal application!.');
    }
    return this.authService.getSalePointDefault();
  }

  isDirectionalUser(): boolean {
    return this.userService.getUser<any>().isDirectionalUser;
  }


}

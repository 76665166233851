import {GenericEntity} from '../../../re-issue/re-postsale/re-postsale-model/substitution';
import {IdentificationEntity} from '../../api-models/identification-entity';

export class CommissionsWrap {
  acquiredCommissionsMandates: IdentificationEntity[];
  mandateActive: IdentificationEntity;
  commissions: CommissionDetail[];
}

export class CommissionDetail {
  commissionType: PNC_POSTSALES_COMMISSION_TYPE;
  derogated: boolean;
  reduced: boolean;
  editable: boolean;
  risk: GenericEntity;
  subscriptionInstalment: CommissionInstalment;
  nextInstalment: CommissionInstalment;
  reducedInstalment: CommissionInstalment;
  subscriptionTotals: SubscriptionTotals;
  subscriptionFees: SubscriptionFees;
  nextInstalmentFees: SubscriptionFees;
}

export class CommissionInstalment {
  netPercPurchaseCommissions: string;
  netPurchaseCommissions: string;
  feePercPurchaseCommissions: string;
  feePurchaseCommissions: string;
  netPercCollectionCommissions: string;
  netCollectionCommissions: string;
  feePercCollectionCommissions: string;
  feeCollectionCommissions: string;
  totPercCommissions: string;
  totCommissions: string;

}

export class SubscriptionTotals {
  accessoriesCommissionsCollection: string;
  accessoriesCommissionsPurchase: string;
  netCommissionsCollection: string;
  netCommissionsPurchase: string;
  totalInstallment: string;
}

export class SubscriptionFees {
  percAdministrationFee: string;
  netAdministrationFee: string;

}

export enum PNC_POSTSALES_COMMISSION_TYPE {
  RISK = 1,
  ADM_FEES,
  TOTALS
}

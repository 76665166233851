import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_EN: ClaimsTranslationsKeys = {
    _CLAIMS_COMMON_: {
        _EDIT_: 'Modify',
        _MESSAGES_: {
            _GENERIC_ERROR: 'It was an error, please contact customer service'
          },
          _NO: 'No',
          _YES: 'Yes',
    }
};


export class Country {

  code: string;
  description: string;
  identification: string;

  constructor(
    code: string,
    description: string,
    identification: string
  ) {
    this.code = code;
    this.description = description;
    this.identification = identification;
  }

}

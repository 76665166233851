import { InvestmentDataResponse } from './../../../lic-karma-funds/model/karma-profile';
import { LifeRoleService } from './../../../services/life-role.service';
import { InvestmentUtils } from './../../utils/lic-investments-utils';
import { FUND_TYPE } from './../../../lic-fund/model/PassFundDefinition';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PassFundDefinition } from '../../../lic-fund/model/PassFundDefinition';
import { SliderProperty } from '../../../lic-karma-funds/model/karma-fund';
import { CardsNavigationService } from '../../../cards-navigation/cards-navigation.service';
import {
  ExtensionProperty, FACTOR_TOOL_CODE, LicCustomProperties,
  OperationCode, PROPERTY_VALUE, ToolCode } from '../../../enum/life-issue.enum';
import { LicFundsStepComponent } from '../../../lic-fund/component/lic-funds-step/lic-funds-step.component';
import { PassProfileData } from '../../../lic-fund/model/PassProfileData';
import { PassProfileDefinition } from '../../../lic-fund/model/PassProfileDefinition';
import {
  PassResponseOption, PassResponseProfile, SustainabilityData,
  SustainabilityResponse, ToolOptionStatus, VolatilityResponse
} from '../../../lic-fund/model/PassResponse';
import { Fund, Profile } from '../../../lic-fund/model/profile';
import { FundService } from '../../../lic-fund/service/fund.service';
import { ToolDefinition } from '../../../lic-investment-contract/model/tool-definition';
import { KarmaProfileDefinition } from '../../../lic-karma-funds/model/karma-profile-definition';
import { CustomProperties, Factor, Party, PolicyModel, Value } from '../../../models/policy.model';
import { ErrorType, LifeIssueMessage } from '../../../models/response.model';
import { ActionsService } from '../../../services/actions.service';
import { LicCacheService } from '../../../services/lic-cache.service';
import { LifeSessionService } from '../../../services/life-session-service';
import { PolicyService } from '../../../services/policy-service';
import { LicErrorsUtils } from '../../../utils/lic-errors-utils';
import { LicObjectUtils } from '../../../utils/lic-object-utils';
import { FactorCode } from '../../utils/factor-code.enum';
import { FormatterUtils } from '../../utils/FormatterUtils';
import { PassUtils } from '../../utils/PassUtils';
import { LicStepperComponent } from '../lic-stepper/lic-stepper.component';
import { InvestmentContext, INVEST_STEPS } from '../../../../life-card/life-issue-card/lic-consts';
import { LicToolUtils } from '../../../..//life-card/lic-tools/utils/lic-tool-utils';
import { SelectionState, ToolStatusOnCache } from './../../../lic-fund/model/SelectionState';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { LicKarmaFundUtils } from '../../../lic-karma-funds/utils/lic-karma-fund-utils';
import { InvestmentPlanCacheService } from '../../../services/lic-scheduled-premium-cache.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import {LifeHttpErrorMessageHandlerService} from '../../../services/http/handlers/life-http-error-message-handler.service';
import {MessageHandlerService} from '../../../services/message-handler.service';
import {ErrorLevel, ErrorMessageFromService} from '../../../models/error-message.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LicInvestmentsModalErrorComponent} from '../lic-investments-modal-error/lic-investments-modal-error.component';
import { RgiRxPushMessage, RgiRxPushMessageHandlerService } from '@rgi/rx';

/* eslint-disable max-len */

@Component({
  selector: 'lic-invest-standalone-session',
  templateUrl: './lic-investments.component.html',
  styleUrls: ['./lic-investments.component.css'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LicInvestmentsComponent implements OnInit, RoutableComponent, OnDestroy {
  public formatterOption: Intl.NumberFormatOptions = { style: 'currency'};
  public locale = 'it-IT';
  public proposalNumber: string;
  protected _stepErrors: {msg: string, context: InvestmentContext}[] = [];
  protected readonly INVESTMENT_COMPLETED = 1;
  INVEST_STEPS = INVEST_STEPS;
  HTTP_STATUS_CODE_CHECK_INVESTMENT_DATE = 422;

  private mapModInv: Map<string, string> = new Map<string, SliderProperty>()
  .set('1', PROPERTY_VALUE._MODIN.PERCENTAGE)
  .set('2', PROPERTY_VALUE._MODIN.AMOUNT)
  .set('3', PROPERTY_VALUE._MODIN.BOTH)
  .set('4', PROPERTY_VALUE._MODIN.ALLSAME);
  fundsValidationErrors: string[];

  public get stepErrors(): {msg: string, context: InvestmentContext}[] {
    this._stepErrors.forEach(s => {
      this.translate.translate(s.msg).subscribe(m => s.msg = m);
    });
    return this._stepErrors;
  }

  public get assetVolatility(): VolatilityResponse {
    return this._assetVolatility;
  }

  private _sustainability: SustainabilityResponse;
  public get sustainability(): SustainabilityResponse {
    return this._sustainability;
  }

  public get criticalCalculationErrors(): string[] {
    const assetVolatility = !!this._assetVolatility && this._assetVolatility.errors ? this._assetVolatility.errors : [{}];
    return assetVolatility.concat(this._sustainability && this._sustainability.errors)
      .filter(error => error && error.errorType === '0')
      .map(error => error.errorDescription)
      .filter(err => !!err);
  }

  public get warningCalculationErrors(): string[] {
    const assetVolatility = !!this._assetVolatility && this._assetVolatility.errors ? this._assetVolatility.errors : [{}];
    return assetVolatility.concat(this._sustainability && this._sustainability.errors)
      .filter(error => error && error.errorType === '1')
      .map(error => error.errorDescription)
      .filter(err => !!err);
  }

  public get hasCriticalCalculationErrors(): boolean {
    return !!this.criticalCalculationErrors && !!this.criticalCalculationErrors.length;
  }


  public get withVolatility(): boolean {
    const prop: CustomProperties = this.mainProposal.quote.product.customProperties
      .find((property) => property.code === LicCustomProperties.CALCOLO_VOLATILITA);

    return prop && prop.value === 'true';
  }

  public get withSustainability(): boolean {
    const prop: CustomProperties = this.mainProposal.quote.product.customProperties
      .find((property) => property.code === LicCustomProperties.CALCOLO_SUSTAINABILITY);
    if (prop) {
      return !prop.values.find(val => val.code === PROPERTY_VALUE._CALSO.NO_SUSTAINABILITY);
    }
    return false;
  }

  public get isFinantialParamsCorrect(): boolean {
    return (this.withVolatility || this.withSustainability)
      ? (this.withVolatility && !!this._assetVolatility) || (this.withSustainability && !!this.sustainability)
      : true;
  }

  public get contractorId(): number {
    const parties: Party[] = this.mainProposal.proposal.lifeParty.map(x => x.party);
    const partyID: string = parties.find(x => x.partyRole === '1').objectID;
    return parseFloat(partyID);
  }

  public get managementNode(): string {
    return this.mainProposal.codManagementNode;
  }

  public get mainProposal(): PolicyModel {
    return this.policyService.mainProposal;
  }

  public get totalProfileAllocation(): any {
    if (!!this.formGroup.get('profiles').value) {
      return Object.keys(this.formGroup.get('profiles').value).map(el => {
        return +this.formGroup.get('profiles').value[el];
      })
      .reduce((accumulator: number, currentValue: number) => {
        return +accumulator + +currentValue;
      });
    }
    return 0;
  }

  public get cacheSession(): any {
    return this.cache.getSession(this.policyService.mainProposalResourceId);
  }

  public get activeStep(): number {
    return this.$activeStep;
  }

  public ERROR_MESSAGE_FROM_SERVICE_LEVEL = ErrorLevel;

  @ViewChild(LicStepperComponent, {static: true}) stepper: LicStepperComponent;

  @ViewChild(LicFundsStepComponent) fundsStepComponent: LicFundsStepComponent;

  @Output() eventPropagation = new EventEmitter<string>();
  @Output() navigation = new EventEmitter<string>();
  @Input() typeU: string;


  get getWarningMessages(): string[] {
    return this.warningMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.WARNING)
    .map(msg => msg.message)).filter(msg => !!msg);
  }

  get getBlockingMessages(): string[] {
    return this.errors.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.ERROR)
    .map(msg => msg.message)).filter(msg => !!msg);
  }

  get getAuthMessages(): string[] {
    return this.authoMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.AUTH)
    .map(msg => msg.message)).filter(msg => !!msg);
  }


  public setblockingMsgs() {
    this.pushMessageHandler.clearTag('blocking');
    this.pushMessageHandler.notify(
      ...(this.getBlockingMessages).map(m => new RgiRxPushMessage(m, { tag: 'blocking', status: 'danger', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setwarningMsgs() {
    this.pushMessageHandler.clearTag('warning');
    this.pushMessageHandler.notify(
      ...(this.getWarningMessages).map(m => new RgiRxPushMessage(m, { tag: 'warning', status: 'default', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setauthoMsgs() {
    this.pushMessageHandler.clearTag('auth');
    this.pushMessageHandler.notify(
      ...(this.getAuthMessages).map(m => new RgiRxPushMessage(m, { tag: 'auth', status: 'warning', dismissible: false, options: {icon: 'rgi-ui-icon-info'} }))
    );
  }



  private numberOfRequests = 0;
  public profileWarnings = [];

  public toolOptions: ToolOptionStatus[] = [];
  ready = false;
  profiles: Array<Profile> = [];
  selectedProfiles: Array<Profile>;
  allReady = false;
  investAmount = 0;
  subscriptions: Subscription[] = [];
  skipProfiles = false;
  skipFunds = false;
  avoidStep = false;


  formGroup: UntypedFormGroup = new UntypedFormGroup({
    profiles: new UntypedFormControl(),
    funds: new UntypedFormGroup({}),
    tools: new UntypedFormGroup({}),
    scheduledPremium: new UntypedFormGroup({
      funds: new UntypedFormControl(),
      profiles: new UntypedFormControl()
    }),
  });

  public toolsDefinitionMap: Map<string, ToolDefinition> = new Map<string, ToolDefinition>();
  public selectedProfilesFunds: Map<string, Fund[]> = new Map<string, Fund[]>();
  protected $activeStep: number;
  private investmentProfileDefinitions: PassProfileDefinition[];
  errors: string[] = [];
  public warningMsgs = [];
  public authoMsgs = [];
  public defaultValue = true;

  messageFromPreviousPage: LifeIssueMessage[] = [];

  protected _assetVolatility: VolatilityResponse;

  private $karmaProfileDefinitions: KarmaProfileDefinition[] = [];

  public get karmaProfileDefinitions(): KarmaProfileDefinition[] {
    return this.$karmaProfileDefinitions;
  }

  private $errorMessagesFromServices: ErrorMessageFromService[] = [];

  public get errorMessagesFromServices(): ErrorMessageFromService[] {
    return this.$errorMessagesFromServices;
  }

  public areThereErrorMessagesFromServices(): boolean {
    return this.$errorMessagesFromServices && this.$errorMessagesFromServices.length > 0;
  }

  // used to define which profile is selected in investment step
  public get selectedProfileDefinitions(): KarmaProfileDefinition[] {
    if (!!this.formGroup.get('profiles').value) {
      return this.$karmaProfileDefinitions.filter(profileDefinition => {
        return !!this.formGroup.get('profiles').value[profileDefinition.id];
      });
    } else {
      return [];
    }
  }

  // used to define which profile is selected in the scheduled premium step
  public get selectedScheduledProfileDefinitions(): KarmaProfileDefinition[] {
    const scheduledProfilesValue = this.formGroup.get(['scheduledPremium', 'profiles']).value;
    if (scheduledProfilesValue) {
      return this.$karmaProfileDefinitions.filter(profileDefinition => {
        return !!scheduledProfilesValue[profileDefinition.id];
      });
    } else {
      return [];
    }
  }

  public getEffectiveDate(): string {
    return this.mainProposal.quote.effectiveDate;
  }

  public getIdProduct(): string {
    return this.mainProposal.quote.productID;
  }

  public getProductCode(): string {
    return this.mainProposal.quote.product.code;
  }

  public getNetPremiumAmount(): number {
    return parseFloat(
      this.mainProposal.proposal.policyPremium.instalmentNet
    );
  }

  public showSaveButton() {

    return !this.policyService.hideSaveButton(true);
  }

  public getSliderProperty(): string | null {
    const operation = this.mainProposal.quote.product.operations.find(op => op.code === OperationCode.EMISSIONE_POLIZZA);
    const _MODIN = operation.customProperties.find(prop => prop.code === LicCustomProperties.MODALITA_INVESTIMENTO);
    return !!_MODIN ? this.mapModInv.get(_MODIN.value) : null;
  }

  constructor(
    @Inject('$injector') protected injector: any,
    protected cardsNavigationService: CardsNavigationService,
    protected policyService: PolicyService,
    public formatter: FormatterUtils,
    protected datePipe: DatePipe,
    protected fund: FundService,
    public translate: RgiRxTranslationService,
    protected actionsService: ActionsService,
    protected lifeSessionService: LifeSessionService,
    protected cache: LicCacheService,
    protected scheduledPremiumCache: InvestmentPlanCacheService,
    protected cd: ChangeDetectorRef,
    protected numberPipe: RgiCountryLayerNumberFormatPipe ,
    protected lifeRoleService: LifeRoleService,
    protected lifeHttpErrorMessageHandlerService: LifeHttpErrorMessageHandlerService,
    protected modalService: NgbModal,
    protected pushMessageHandler: RgiRxPushMessageHandlerService
  ) {
    this.$activeStep = 0;
    this.formatterOption.currency = this.policyService.currencyCode;
    this.locale = this.policyService.currentLocale;
  }

  ngOnInit() {
    this.messageFromPreviousPage =  this.cardsNavigationService.getPreviousMessages(this.getErrorStep());
    this.investAmount = InvestmentUtils.getTotalBenefitPerformance(this.policyService);

    // If the plan is in the cache, populate the scheduledPremium part of the form
    const cachedInvestmentPlan = this.scheduledPremiumCache.getInvestmentPlan(this.policyService.mainProposalResourceId);
    if (cachedInvestmentPlan) {
      const scheduledPremiumFormGroup = this.formGroup.get('scheduledPremium') as UntypedFormGroup;

      const cachedProfilesValue = {[cachedInvestmentPlan.profiles[0].id]: cachedInvestmentPlan.profiles[0].percentage};
      const cachedFundsValue = cachedInvestmentPlan.profiles[0].funds.reduce((acc, item) => {
        acc[item.id] = parseFloat(item.percentage) / 100;
        return acc;
      }, {});

      scheduledPremiumFormGroup.get('profiles').patchValue(cachedProfilesValue);
      scheduledPremiumFormGroup.get('funds').patchValue(cachedFundsValue);
    }
    this.subscriptions.push(
      this.showLoaderWhileHandling(
        this.initializePage()
      ).subscribe((toolDefinitions: ToolDefinition) => {
        // PREVALORIZZAZIONE TOOLS
        // TODO: forse servirebbe un refactor per semplificare
        console.log('TOOL DEFINITIONS', toolDefinitions);
        const tools = {};
        this.handleToolDefinitions(toolDefinitions, tools);
        if (!!this.cacheSession && !!this.cacheSession.investments) {
          if (!LicObjectUtils.equal(this.cacheSession.investments, this.formGroup.getRawValue())) {
            this.formGroup.patchValue(this.cacheSession.investments, { emitEvent: false });
          }
        }
        this.addPushMessages();
        this.cd.detectChanges();
      }, error => {
        return throwError(error);
      }),
      this.formGroup.valueChanges.pipe(
        distinctUntilChanged((prev, curr) => LicObjectUtils.equal(prev, curr))
      ).subscribe((value) => {
        this.errors = [];
        this.getFeErrors();
        this.addPushMessages();
      }),
      this.onInvestmentProfileStepChanging(),
      this.onInvestmentFundsStepChanging()
    );
  }

  private addPushMessages() {
    this.setblockingMsgs();
    this.setwarningMsgs();
    this.setauthoMsgs();
  }

  private initializePage(): Observable<any> {
    return this.policyService.getProfilesAvailable().pipe(
        tap(data => {
          this.initializeInvestmentProfiles(data.profiles);
        }),
        switchMap((data1) => {
          if (this.policyService.isFromAuth) {
            return this.callInvestmentDataForAuthFlow(data1);
          } else {
            // in case the steps of profiles and funds are all prevalorized we should save all into the cache
            if (!this.cacheSession) {
              this.saveIntoCache();
            }
            return of(data1.profiles);
          }
        }),
        switchMap(() => {
          if (this.formGroup.get('funds').valid && this.hasInvestmentsCompleted()) {
            return this.recalculateEffectDateAndVolatility();
          } else {
            // returning Empty to avoid the switchMap to call the next step
            return EMPTY;
          }
        }),
        switchMap(() => {
          if (this.formGroup.get('funds').valid && this.hasInvestmentsCompleted()) {
            return this.policyService.getOptionsAvailable().pipe(
              tap(data => {
                this.initializeToolOptions(data.options);
                this.saveIntoCache();
              })
            );
          } else {
            // returning Empty to avoid the switchMap to call the next step
            return EMPTY;
          }
        }),
        mergeMap(() => {
          const tools = this.callToolDefinitions();
          this.nextAdditionalPayment(INVEST_STEPS.SCHEDULED_PREMIUM.context);
          return tools;
        })
    );
  }

  protected onInvestmentFundsStepChanging(): Subscription {
    return this.formGroup.get('funds').valueChanges.pipe(
      distinctUntilChanged((prev, curr) => LicObjectUtils.equal(prev, curr)),
      switchMap(() => {
        this._stepErrors = [];
        this.resetTools();
        if (this.formGroup.get('funds').valid && this.hasInvestmentsCompleted()) {
          return this.showLoaderWhileHandling(this.recalculateEffectDateAndVolatility());
        } else {
          return EMPTY;
        }
      }),
      mergeMap(() => {
        return this.callToolDefinitions();
      })
    ).subscribe(toolDefinition => {
      this.handleToolDefinitions(toolDefinition, {});
      if (!!this.cacheSession && !!this.cacheSession.investments) {
        if (!LicObjectUtils.equal(this.cacheSession.investments.tools, this.formGroup.getRawValue().tools)) {
          this.formGroup.get('tools').patchValue(this.cacheSession.investments.tools, { emitEvent: false });
        }
      }
      this.cd.detectChanges();
    });
  }

  protected onInvestmentProfileStepChanging(): Subscription {
    return this.formGroup.get('profiles').valueChanges.pipe(
      distinctUntilChanged((prev, curr) => LicObjectUtils.equal(prev, curr))
    ).subscribe(() => {
      this._stepErrors = [];
      this._assetVolatility = null;
      this._sustainability = null;
      if (!this.policyService.isFromAuth) {
        this.resetValidationsOnFunds();
        const funds = LicObjectUtils.merge(
          Object.assign({}, this.formGroup.get('funds').value),
          Object.assign({}, this.getFundsData())
        );
        this.formGroup.get('funds').setValue(funds, { emitEvent: false });
      }
      this.resetTools();
    });
  }

  protected callInvestmentDataForAuthFlow(data1: PassResponseProfile): Observable<InvestmentDataResponse> {
    return this.policyService.getInvestmentData(
      this.policyService.mainProposal.proposal, this.getSaveInvestmentRequest()).pipe(
        tap((data2) => {
          this.cd.detectChanges();
          if (this.checkProfilesAndFundsDiff(data2, data1.profiles) && !this.hasAssetAllocationChanged()) {
            this.valorizeFormByExtensionDataValue(data2);
          } else {
            this.translate.translate('lic_config_profile_warning').subscribe(m => !!m && this.profileWarnings.push(m));
          }
        })
      );
  }

  protected valorizeFormByExtensionDataValue(data2: InvestmentDataResponse) {
    console.log(data2, 'data2 log');
    this.formGroup.patchValue(data2, { emitEvent: true });
    this.handleTools();
    this.saveIntoCache();
  }

  protected handleToolDefinitions(toolDefinitions: any, tools: {}) {
    toolDefinitions.forEach(toolDefinition => {
      this.initializeTool(toolDefinition);
      const toolTypeId = toolDefinition.operationCodeId;
      const definitionToolData = this.getToolData(toolDefinition); // qua dentro c'è la logica per i vari tools
      const cacheToolData = this.cacheSession.investments.tools[toolTypeId];
      const shouldLoadFromCache = this.shouldLoadFromCache(toolTypeId, definitionToolData, cacheToolData);
      tools[toolTypeId] = shouldLoadFromCache ? cacheToolData : definitionToolData;
      this.toolOptions.forEach(opt => opt.id === toolDefinition.operationCodeId || this.policyService.isFromAuth ? opt.isToolBeingFullyHandled = true : false) ;
    });
    if (!!this.cacheSession && !!this.cacheSession.investments) {
      LicObjectUtils.merge(this.cacheSession.investments.tools, tools);
    }
  }

  protected callToolDefinitions() {
    if (!!this.cacheSession && !!this.cacheSession.investments) {
      const optionObservables: Observable<ToolDefinition | typeof EMPTY>[] = this.toolOptions
        .filter(option => this.checkToolOnCacheAndAuthToolCached(option))
        .map(option => {
          return this.callToolDefByCacheStatus(option);
        });
      return forkJoin(optionObservables.filter(valorized => valorized));
    } else {
      return forkJoin([]);
    }
  }

  /**
   * Checks if the specified tool is present in the cache and if the tool is cached on auth flow.
   *
   * @param option - The tool option status to check.
   * @returns A boolean value indicating whether the tool is present in the cache also on auth flow.
   */
  private checkToolOnCacheAndAuthToolCached(option: ToolOptionStatus): unknown {
    return !!this.cacheSession && !!this.cacheSession.investments.tools.hasOwnProperty(option.id) && (this.policyService.isFromAuth ? !!this.cacheSession.investments.tools[option.id] : true);
  }

  protected callToolDefByCacheStatus(option: ToolOptionStatus): Observable<ToolDefinition> {
    const toolStatusOnCache = this.cache.getToolStatusByUserInteraction(option.id);
    // guard check: if we are in auth flow you must call the definition no mather the initialState is deactivated
    if (this.policyService.isFromAuth) {
      return this.policyService.getToolDefinition(option.id);
    }
    if (toolStatusOnCache === ToolStatusOnCache.CHECKED) {
      return this.policyService.getToolDefinition(option.id);
    } else if (toolStatusOnCache === ToolStatusOnCache.REMOVED && option.initialState !== SelectionState.DEACTIVATED) {
      return;
    } else if (toolStatusOnCache === null && (option.initialState === SelectionState.MANDATORY || option.initialState === SelectionState.ACTIVE)) {
      return this.policyService.getToolDefinition(option.id);
    }
    return;
  }

  protected getErrorStep(): number {
    return this.cardsNavigationService.STEP.INVESTIMENTI.pos;
  }

  public getProfilesForSummary(): KarmaProfileDefinition[] {
    const profiles = [];
    if (!!this.formGroup.get('profiles').value) {
      Object.keys(this.formGroup.get('profiles').value).forEach(prf => {
        const profile = this.selectedProfileDefinitions.find(defnitionProfile => defnitionProfile.id === prf);
        if (!!profile) {
          const percent = this.formGroup.get('profiles').value[prf];
          profiles.push(
            {
              id: profile.id,
              description : profile.description,
              percent,
              funds: []
            }
          );
        }
      });
    }
    return profiles;
  }

  public getProfilesAndFundsForSummary(): KarmaProfileDefinition[] {
    const profilesForSummary = this.getProfilesForSummary();
    if (!!profilesForSummary.length) {
      profilesForSummary.map(summaryProfile => {
        if (!!this.formGroup.get('funds').value[summaryProfile.id]) {
          Object.keys(this.formGroup.get('funds').value[summaryProfile.id]).forEach(fnd => {
            this.selectedProfileDefinitions.map(profile => {
              const fund = profile.funds.find(definitionFund => definitionFund.id === fnd);
              if (!!fund) {
                const percent = this.formGroup.get('funds').value[summaryProfile.id][fnd];
                if (!!percent) {
                  if (!summaryProfile.funds.find(fundAlreadyPresent => fundAlreadyPresent.id === fund.id)) {
                    summaryProfile.funds.push(
                      {
                        id: fund.id,
                        description: fund.description,
                        percent,
                        amount: LicObjectUtils.roundToDecimal(this.investAmount * summaryProfile.percent * percent, 2)
                      }
                    );
                  }
                }
              }});
          });
        }
      });
    }
    return profilesForSummary;
  }

  private shouldLoadFromCache(toolTypeId: string, definitionToolData: any, cacheToolData: any): boolean {
    if (!cacheToolData) {
      return false;
    }
    const cacheFactors = cacheToolData.factors;
    const definitionFactors = definitionToolData.factors;
    return this.getFactorsToCheck(toolTypeId).every(factorCode =>
      // eslint-disable-next-line eqeqeq
      definitionFactors[factorCode] == cacheFactors[factorCode] // == IS needed to check for null vs undefined and strings vs integers
    );
  }

  private getFactorsToCheck(toolTypeId: string) {
    if (toolTypeId === ToolCode.SCHEDULED_PREMIUM) {
      // o Il factor “Amount” deve essere valorizzato con il valore del fattore _PRRFI
      // o Il factor “Frequency” deve essere valorizzato con la transcodifica nel codice di kelia
      // o Il factor “First Term” deve essere valorizzato con il  valore del fattore _VDFTE
      // o Il factor “Expiration date” deve essere valorizzato con il valore del fattore _VRSPG
      return [FACTOR_TOOL_CODE.AMOUNT_CODE, FACTOR_TOOL_CODE.FIRSTTERM_CODE, FACTOR_TOOL_CODE.EXPIRATIONDATE_CODE];
    }
    return [];
  }

  private resetTools(): void {
    this.toolOptions.forEach(toolOption => {
      const toolStatusOnCache = this.cache.getToolStatusByUserInteraction(toolOption.id);
      toolOption.isToolBeingFullyHandled = (SelectionState.DEACTIVATED === toolOption.initialState && toolStatusOnCache === null)
        || toolStatusOnCache === ToolStatusOnCache.REMOVED;
      if (toolOption.initialState !== SelectionState.MANDATORY && toolOption.initialState !== SelectionState.ACTIVE) { // i'll reset just the tools not mandatory
        this.formGroup.get('tools').get(toolOption.id).setValue(null, {emitEvent: false});
      }
    });
  }

  protected handleTools() {
    const tools = this.formGroup.get('tools');
    const toolCodes = [
      ToolCode.SCHEDULED_PREMIUM,
      ToolCode.LOCK_IN,
      ToolCode.STOP_LOSS,
      ToolCode.PROGRESSIVE_SWITCH,
      ToolCode.RISK_REDUCTION,
      ToolCode.PERIODIC_COUPON
    ];

    for (const code of toolCodes) {
      const tool = tools.get(code);
      if (tool && tool.value) {
        this.setDefaultFunds(tool.value.investmentProfiles, tool.value.investmentFunds);
      }
    }
  }

  protected setDefaultFunds(investmentProfiles: any, investmentFunds: any) {
    let profileCheck = false;
    let fundCheck = false;
    const profiles = this.formGroup.get('profiles').value;
    Object.keys(profiles).forEach(profileKey => {
      const toolProfile = investmentProfiles[profileKey];
      if (!!toolProfile) {
        if (toolProfile === profiles[profileKey] ) {
          profileCheck = true;
        }
      }
    });
    if (profileCheck) {
      Object.keys(this.formGroup.get('funds').value).forEach(profileKey => {
        const funds = investmentFunds[profileKey];
        if (!!funds) {
          Object.keys(funds).forEach(fundKey => {
            const profileFunds = this.formGroup.get('funds').value[profileKey];
            Object.keys(profileFunds).forEach(fk => {
              if (profileFunds[fk] === funds[fundKey]) {
                fundCheck = true;
              }
            });
          });
          }
      });
    }
    this.defaultValue = profileCheck && fundCheck;
  }

  protected hasAssetAllocationChanged(): boolean {
    // check fattore modalità gestione nei dati amministrativi
    const proposalFromAuth = this.policyService.coreResultData.proposalFromAuth;
    const currentProposal = this.policyService.mainProposal;
    const currentModGes = currentProposal.quote.product.factors.find(factor => factor.code === 'MODGES');
    const modGesFromAuth = proposalFromAuth.quote.product.factors.find(factor => factor.code === 'MODGES');
    const policyDataCheck = !!currentModGes && !!modGesFromAuth ? currentModGes.value !== modGesFromAuth.value : false;

    // check fattore modalità gestione nelle units
    let unitsCheck = false;
    const currentUnits = {};
    const authUnits = {};
    currentProposal.quote.product.assets[0].instances[0].sections[0].units.map(currentUnit => {
      const proriFactor = currentUnit.instances[0].factors.find(factor => factor.code === '_PRORI');
      if (!!proriFactor) {
        currentUnits[currentUnit.code] = proriFactor.value;
      }
    });
    proposalFromAuth.quote.product.assets[0].instances[0].sections[0].units.map(authUnit => {
      const proriFactor = authUnit.instances[0].factors.find(factor => factor.code === '_PRORI');
      if (!!proriFactor) {
        authUnits[authUnit.code] = proriFactor.value;
      }
    });

    Object.keys(currentUnits).forEach(currentUnitKey => {
       Object.keys(authUnits).forEach(authUnitKey => {
         if (!!authUnits[currentUnitKey]) {
          if (authUnits[authUnitKey] === currentUnits[currentUnitKey] ) {
            unitsCheck = true;
          }
         }
       });
    });
    return policyDataCheck && unitsCheck;
  }

  private hasInvestmentsCompleted(): boolean {
    // filter through profiles that has any values and extract from fund from the investment value of the fund itself
    // and check if the investment is completed (100% allocation = 1)
    const selectedProfiles = this.formGroup.get('profiles').value;

    if (selectedProfiles === null) {
      console.log('Selected profiles is null.');
      return false;
    }

    const allProfilesCompleted = Object.keys(selectedProfiles).every(profKey => {
      const fundsObject = this.formGroup.get('funds').get(profKey).value;

      if (!fundsObject) {
        return null;
      }
      const fundsSumTimes100 = Object.values(fundsObject).reduce((acc, fundValue) => {
          const totalFundValueTimes100 = Math.floor(Number(fundValue) * 1000); // Convert to integer
          return Number(acc) + totalFundValueTimes100;
      }, 0);

      console.log(`Profile ${profKey}: Total Percentage Funds Sum (times 100): ${fundsSumTimes100}`);

      // get back to decimal number
      return (Number(fundsSumTimes100) / 1000) === this.INVESTMENT_COMPLETED;
    });

    return allProfilesCompleted;
  }

  protected resetValidationsOnFunds(): void {
    Object.keys((this.formGroup.get('funds') as UntypedFormGroup).controls).forEach(controlKey => {
      this.formGroup.get('funds').get(controlKey).clearValidators();
    });
    if (this.formGroup.get('profiles').value) {
      Object.keys((this.formGroup.get('profiles').value)).forEach(profileKey => {
        this.formGroup.get('funds').get(profileKey).setValidators(Validators.required);
      });
    }
  }

  public recalculateEffectDateAndVolatility(): Observable<[any, any]> {
    return this.onReadyChanged(this.formGroup.get('funds').valid).pipe(
      switchMap(_ => combineLatest([this._doVolatilityCall, this._doSustainabilityCall])),
      tap(([volatility, sustainability]: [VolatilityResponse, SustainabilityData]) => {
        this._sustainability = sustainability;
        this._assetVolatility = volatility;
      })
    );
  }

  private get _doVolatilityCall(): Observable<any> {
    if (this.withVolatility && this.formGroup.get('funds').valid) {
      this._assetVolatility = null;
      return this.calculateVolatility();
    }
    return of({});
  }

  private get _doSustainabilityCall(): Observable<any> {
    if (this.withSustainability && this.formGroup.get('funds').valid) {
      this._sustainability = null;
      return this.calculateSustainability();
    }
    return of({});
  }

  public onToolClick(toolCode: string, checked: boolean) {
    if (checked) {
      this.subscriptions.push(
        this.showLoaderWhileHandling(
          this.policyService.getToolDefinition(toolCode)
        ).subscribe(result => {
          this.cache.setToolStatusByUserInteraction(toolCode, ToolStatusOnCache.CHECKED);
          this.toolsDefinitionMap.set(result.operationCodeId, result);
          this.formGroup.get('tools').get(result.operationCodeId)
            .setValue(this.getToolData(LicObjectUtils.merge({}, result)), {emitEvent: false});
        })
      );
    } else {
      this.toolsDefinitionMap.delete(toolCode);
      this.formGroup.get('tools').get(toolCode).setValue(null);
      this.policyService.mainProposal.proposal.extensionData.properties = [];
      this.cache.setToolStatusByUserInteraction(toolCode, ToolStatusOnCache.REMOVED);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  onNext(step: string) {
    if (this.hasInvestmentsCompleted()) {
      this.subscriptions.push(
          this.getOptionsTool(step).subscribe((toolDefinitions) => {
            this.handleToolDefinitions(toolDefinitions, {});
            if (!!this.cacheSession && !!this.cacheSession.investments) {
              if (!LicObjectUtils.equal(this.cacheSession.investments, this.formGroup.getRawValue())) {
                this.formGroup.patchValue(this.cacheSession.investments, { emitEvent: false });
              }
            }
            this.cd.detectChanges();
        }, error => {
          return throwError(error);
        })
      );
    } else {
      this.onNextStepper(step);
    }
  }

  getOptionsTool(step: string): Observable<any> {
    return this.policyService.getOptionsAvailable().pipe(
      mergeMap((data) => {
        this.initializeToolOptions(data.options);
        this.saveIntoCache();
        const tools = this.callToolDefinitions();
        this.onNextStepper(step);
        return tools;
      })
    );
  }

  onNextStepper(step: string) {
    this._stepErrors = InvestmentUtils.getStepErrors(step, this.formGroup, this.selectedProfileDefinitions);
    if (!!this.stepErrors.length) {
      return;
    }
    // get cached investment plan
    const cachedInvestmentPlan = this.scheduledPremiumCache.getInvestmentPlan(this.policyService.mainProposalResourceId);
    // on scheduled premium
    if (step === INVEST_STEPS.SCHEDULED_PREMIUM.context && !this.isDistributionSameAsInitial()) {
      const profiles = this.formGroup.get(INVEST_STEPS.SCHEDULED_PREMIUM.formName).value.profiles;
      const funds = this.formGroup.get(INVEST_STEPS.SCHEDULED_PREMIUM.formName).value.funds;
      const investmentPlan = LicKarmaFundUtils.createInvestmentProfileData(profiles, funds);
      // post new investment plan
      this.policyService.lifeScheduledPremiumUpdateInvestmentPlan(investmentPlan).subscribe();
      // if cache empty save investment plan
      if (!cachedInvestmentPlan) {
        this.scheduledPremiumCache.registerInvestmentPlan(this.policyService.mainProposalResourceId, investmentPlan);
        this.policyService.pushExtensionProperty(ExtensionProperty.ISSUE_INVESTMENTS_PLAN, JSON.stringify(investmentPlan));
      }
    }
    this.saveIntoCache();
    this.stepper.next();
  }

  nextAdditionalPayment(step: string) {
    this._stepErrors = InvestmentUtils.getStepErrors(step, this.formGroup, this.selectedProfileDefinitions);
    if (!!this.stepErrors.length) {
      return;
    }
    // get cached investment plan
    const cachedInvestmentPlan = this.scheduledPremiumCache.getInvestmentPlan(this.policyService.mainProposalResourceId);
    // on scheduled premium
    if (step === INVEST_STEPS.SCHEDULED_PREMIUM.context && this.isDistributionSameAsInitial()) {
      const profiles = this.formGroup.get(INVEST_STEPS.PROFILES.formName).value;
      let funds;
      Object.entries(profiles).map(([profileId, profilePercentage]) => funds = this.formGroup.get(INVEST_STEPS.FUNDS.formName).value[profileId]);
      const investmentPlan = LicKarmaFundUtils.createInvestmentProfileData(profiles, funds);
      // post policy investment plan
      this.policyService.lifeScheduledPremiumUpdateInvestmentPlan(investmentPlan).subscribe();
      // if cache empty save investment plan
      if (!cachedInvestmentPlan) {
        this.scheduledPremiumCache.registerInvestmentPlan(this.policyService.mainProposalResourceId, investmentPlan);
        this.policyService.pushExtensionProperty(ExtensionProperty.ISSUE_INVESTMENTS_PLAN, JSON.stringify(investmentPlan));
      }
      this.stepper.next();
    }
    this.saveIntoCache();
  }

  get isScheduledPremiumComplete(): boolean {
    const cachedInvestmentPlan = this.scheduledPremiumCache.getInvestmentPlan(this.policyService.mainProposalResourceId);
    const scheduledPremiumCurrentValue = this.formGroup.get(INVEST_STEPS.SCHEDULED_PREMIUM.formName).value;
    const currentProfiles = scheduledPremiumCurrentValue.profiles;
    const currentFunds = scheduledPremiumCurrentValue.funds;
    const currentInvestmentPlan = LicKarmaFundUtils.createInvestmentProfileData(currentProfiles, currentFunds);

    // At least one condition is met
    return (!!cachedInvestmentPlan || !!currentInvestmentPlan);
  }


  onSlide(index: number) {
    this.$activeStep = index;
  }

  getLeftAmount(): number {
    if (this.selectedProfiles) {
      const allPercent = this.selectedProfiles.every(sp => sp.isPercent);
      const totPercent = this.selectedProfiles.map(p => +p.percent).reduce((a, c) => a + c, 0);
      if (allPercent && totPercent === 100) {
        const allExceptLast = this.selectedProfiles
          .map(p => +p.currency)
          .reduce((a, c, i) => i < this.selectedProfiles.length - 1 ? a + c : a, 0);
        this.selectedProfiles[this.selectedProfiles.length - 1].currency = Number((this.investAmount - allExceptLast).toFixed(2));
      }
      const investedAmount = +this.selectedProfiles.map(p => p.currency ? +p.currency : 0)
        .reduce((a, c) => a + c, 0).toFixed(2);
      return this.investAmount - investedAmount;
    } else {
      return this.investAmount;
    }
  }

  onProfileChanged($event: Profile[]) {
    this.selectedProfiles = $event.filter(p => p.selected);
  }

  onSelectedProfileFundChanged($event: Map<string, Fund[]>) {
    this._assetVolatility = null;
    this._sustainability = null;
    this.selectedProfilesFunds.clear();
    $event.forEach((value, key) => {
      this.selectedProfilesFunds.set(key, value.filter(fund => !!Number(fund.percent)));
    });
  }

  onReadyChanged($event: boolean): Observable<any> {
    this.allReady = $event;
    this.avoidStep = false;
    return this.recalculateDate(this.allReady);
  }

  checkProfilesAndFundsDiff(issue: any, karmaProfiles: PassProfileDefinition[] ): boolean {
    let equals = false;
    Object.keys(issue.funds).forEach(profileKey => {
      const profileFound = karmaProfiles.find(profile => profile.id === profileKey );
      if (!!profileFound) {
        Object.keys(issue.funds[profileKey]).forEach(fundKey => {
          const fundFound = profileFound.funds.find(fund => fund.id === fundKey);
          if (!!fundFound) {
            equals = true;
          } else {
            equals = false;
          }
        });
      } else {
        equals = false;
      }
    });
    return equals;
  }


  getFeErrors() {

    Object.keys((this.formGroup.get('tools') as UntypedFormGroup).controls).forEach(el => {
      const controlErrors = this.formGroup.get('tools').get(el).errors;
      if (!!controlErrors) {
        if (controlErrors.minMaxThreshold) {
          this.translate.translate('lic_invest_value_range', {
            min: controlErrors.min * 100,
            max: controlErrors.max * 100
          }).subscribe(m => this.errors.push(m));
        } else if (controlErrors.minMaxDisinvestmentsAmount) {
          this.translate.translate('lic_disinvestment_range', {
            min: controlErrors.min,
            max: controlErrors.max
          }).subscribe(m => this.errors.push(m));
        } else if (controlErrors.thresholdMissing) {
          this.translate.translate('lic_mandatory_threshold_perc', {value: controlErrors.fund}).subscribe(m => this.errors.push(m));
        } else if (controlErrors.fundsNotSelected) {
          this.translate.translate('lic_mandatory_one_fund').subscribe(m => this.errors.push(m));
        } else if (controlErrors.codeMissing) {
          this.translate.translate('lic_error_mandatory_fields').subscribe(m => this.errors.push(m));
        } else if (controlErrors.ibanError) {
          this.translate.translate('lic_error_iban').subscribe(m => this.errors.push(m));
        } else if (controlErrors.operator === 'LE') {
          this.translate.translate(
            'lic_invest_factor_min_validation', {factor: controlErrors.factor.replace(/_/g, ' '), amount: controlErrors.value}).subscribe(m => this.errors.push(m));
        } else if (controlErrors.operator === 'GE') {
          this.translate.translate(
            'lic_invest_factor_max_validation', {factor: controlErrors.factor.replace(/_/g, ' '), amount: controlErrors.value}).subscribe(m => this.errors.push(m));
        } else if (controlErrors.operator === 'EQ') {
          this.translate.translate(
            'must be', {factor: controlErrors.factor.replace(/_/g, ' '), amount: controlErrors.value}).subscribe(m => this.errors.push(m));
        } else if (!!controlErrors.errorMessage) {
          this.errors.push(controlErrors.errorMessage);
        } else if (!!controlErrors.investmentFunds) {
          this.translate.translate(controlErrors.investmentFunds).subscribe(m => this.errors.push(m));
        } else if (!!controlErrors.investmentProfile) {
          this.translate.translate(controlErrors.investmentProfile).subscribe(m => this.errors.push(m));
        }
      }
    });
  }

  save(modal: any = {}) {
    this.next(true, modal);
  }

  next(saveUndefinedProposal: boolean = false, modal: any = {}) {
    // prima di proseguire chiamo esplicitamente il controllo degli errori per non perdermi nulla
    this.errors = [];
    this.getFeErrors();

    if (this.errors.length === 0) {
      const onSubmitFirstCall = !!this.policyService.investmentsData ? of(null) : this.recalculateDate(this.allReady);
      this.subscriptions.push(
        this.showLoaderWhileHandling(
          onSubmitFirstCall.pipe(
            switchMap(_ => {
              return this.policyService.tools(PassUtils.mapToInboundTools(
                this.formGroup.getRawValue().tools,
                this.formGroup.getRawValue().profiles,
                this.formGroup.getRawValue().funds,
                this.investAmount,
                this.policyService.getIban(),
                this.policyService.getHolderBankAccount()
              ));
            }),
            switchMap((responseTools) => {
              if (!!responseTools) {
                this.policyService.toolsData = responseTools.proposal.extensionData.properties.find(prop => {
                  return prop.chiave === ExtensionProperty.ISSUE_TOOLS;
                });
              }

              // cerco se la proprietà è già presente e la aggiorno, altrimenti la inserisco in coda con una push
              this.policyService.addExtensionProperty(ExtensionProperty.ISSUE_TOOLS, this.policyService.toolsData);

              this.saveIntoCache();
              return this.showLoaderWhileHandling(this.actionsService.getMsgByCodLivelloAzioni('PT')
              );
            })
          )
        ).subscribe(response => {
          const nonBlockingMessages = [];
          const operator = this.lifeSessionService.getOperator();
          this.actionsService.cleanErrorMessagesForStep('tools');
          this.cardsNavigationService.resetMessages(this.cardsNavigationService.STEP.INVESTIMENTI.errorId);
          this.actionsService.setAuthorization(this.cardsNavigationService.STEP.INVESTIMENTI.errorId, false);
          response.errorMessages.map((action) => {
            this.actionsService.setActionErrorMessage('tools', action);
            // check blocking errors
            if (!!this.actionsService.getBlockingErrors(action)) {
              this.errors.push(action.messageDescription);
              this.addPushMessages();
            }
            // check warning errors
            if (!!this.actionsService.getWarningErrors(operator, action)) {
              nonBlockingMessages.push({ type: ErrorType.WARNING, message: this.actionsService.getWarningErrors(operator, action) });
            }
            // check auth errors
            if (!!this.actionsService.getAuthoErrors(operator, action)) {
              this.actionsService.setAuthorization(this.cardsNavigationService.STEP.INVESTIMENTI.errorId, true);
              nonBlockingMessages.push({ type: ErrorType.AUTH, message: this.actionsService.getAuthoErrors(operator, action) });
            }
          });
          this.cardsNavigationService.setMessageForPage(this.cardsNavigationService.STEP.INVESTIMENTI.errorId, nonBlockingMessages);
          if (this.errors.length === 0) {
            if (saveUndefinedProposal) {
              this.saveUndefinedProposal(modal);
            } else {
              this.goToSummary();
            }
          }
        }, error => {
          throwError(error);
        }
      ));
    }
  }

  public saveUndefinedProposal(modal: any = {}) {

    this.policyService.pushExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE, 'true');

    this.eventPropagation.emit('startLoader');

    this.policyService.saveFromQuote(false).pipe(
      tap((response: any) => {
        this.policyService.mainProposal.proposal = response.output.proposal;
        this.eventPropagation.emit('stopLoader');
        modal && modal.open();
        this.proposalNumber = this.policyService.mainProposal.proposal.contractData[0].proposalNumber;
      })
    ).subscribe();
  }

  protected goToSummary(): void {
    this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.SOMMARIO.route);
    this.navigation.emit(this.cardsNavigationService.STEP.SOMMARIO.route);
  }

  protected saveIntoCache(): void {
    const session: any = this.cache.registerSession(this.policyService.mainProposalResourceId);
    session.investments = Object.assign({}, this.formGroup.getRawValue());
    console.log(session.investments);
  }

  public getInvestmentAmountByTool(toolId: string, investAmount: number): number {
    if (LicToolUtils.isToolScheduledPremium(toolId)) {
      let investmentTotal = 0;
      const mainProposal = this.mainProposal;
      if (!!mainProposal.quote.product.assets) {
        mainProposal.quote.product.assets.forEach(asset => {
          asset.instances.forEach(instance => {
            instance.sections.forEach(section => {
              section.units.forEach(unit => {
                if (unit.instances && 1 === unit.instances.length) {
                  const firstInstance1 = unit.instances[0];

                  const invrfFactor = firstInstance1.factors.find(f => f.code === FactorCode.INVRF);
                  if (!!invrfFactor) {
                    investmentTotal += Number(invrfFactor.value);
                  }
                }
              });
            });
          });
        });
      }
      return investmentTotal;
    }

    return investAmount;
  }

  protected initializeInvestmentProfiles(profiles: PassProfileDefinition[]) {
    this.investmentProfileDefinitions = profiles;
    this.$karmaProfileDefinitions = this.investmentProfileDefinitions.map(def => PassUtils.getKarmaProfileDefinitionFrom(def));
    let slideTo = 0;
    let investmentProfiles = PassUtils.getProfilesFromDefinitions(this.investmentProfileDefinitions);

    if (this.isWithFixedProfilePolicy()) {
      this.skipProfiles = true;
      investmentProfiles = this.setBlockedProfile(investmentProfiles, this.getFixedProfileId());

      if (!investmentProfiles.length) {
        investmentProfiles = PassUtils.getProfilesFromDefinitions(this.investmentProfileDefinitions);
        investmentProfiles = this.policyService.getMainProposalProfileIntersectionOf(investmentProfiles);
      }

      slideTo = 1;
    }

    // RELOAD PROFILI E FONDI
    if (this.hasAlreadyBeenCompiled()) {
      // se li ho nella proposal li ricarico dal JSON -> dalla pagina summary (indietro)
      const oldData = JSON.parse(this.policyService.investmentsData.valore);
      investmentProfiles = this.mergeProfilesFromPolicy(investmentProfiles, oldData.profiles);
      slideTo = 2; // TODO: devo sempre saltare al 2 perchè provengo dalla pagina di summary?
    } else if (this.cacheSession && this.cacheSession.investments) {
      // se li ho nella cache li ricarico da li -> dalla pagina di quotazione (indietro/avanti)
      let profileFlag = false;
      let fundFlag = false;

      investmentProfiles.forEach(profileFromPolicy => {
        const profileFromKarma = this.cacheSession.investments.profiles[profileFromPolicy.id.toString()];
        if (!!profileFromKarma) {
          profileFromPolicy.percent = +profileFromKarma * 100;
          profileFromPolicy.isPercent = true;
          profileFromPolicy.selected = true;
          profileFlag = true;
        }

        profileFromPolicy.funds.forEach(fundFromPolicy => {
          const profileFundFromKarma = this.cacheSession.investments.funds[profileFromPolicy.id.toString()];
          const fundFromKarma = !!profileFundFromKarma ? profileFundFromKarma[(fundFromPolicy.id).toString()] : null;
          if (fundFromKarma) {
            fundFromPolicy.percent = +fundFromKarma * 100;
            fundFromPolicy.isPercent = true;
            fundFromPolicy.selected = true;
            fundFlag = true;
          }
        });
      });

      // flag che indicano se abbiamo caricato profili e/o fondi
      // profili selezionati -> salto primo step
      // fondi selezionati -> salto secondo step
      if (profileFlag) {
        if (fundFlag) {
          slideTo = 2;
        } else {
          slideTo = 1;
        }
      }
    }

    this.profiles = PassUtils.getRecalculatedFunds(investmentProfiles, this.investAmount);

    if (slideTo < 1 && PassUtils.areAllProfilesReadonly(investmentProfiles)) {
      slideTo = 1;
      this.skipProfiles = true;
    }

    // This is the part where the data from Alex is converted to Reactive Form Data

    this.investmentProfileDefinitions.forEach(profileDefinition => {
      (this.formGroup.get('funds') as UntypedFormGroup).addControl(profileDefinition.id, new UntypedFormControl());
    });

    this.formGroup.get('profiles').setValue(this.getProfilesData(), { emitEvent: false });
    this.resetValidationsOnFunds();
    this.formGroup.get('funds').setValue(this.getFundsData(), { emitEvent: false });

    if (slideTo <= 2 && this.skipProfiles && PassUtils.areAllFundsReadonlyInProfiles(investmentProfiles)) {
      slideTo = 2;
      this.skipFunds = true;
      this.avoidStep = true;
    }

    this.stepper.slideTo(slideTo);
    this.ready = true;
  }

  private getProfilesData(): any {
    const profiles: any = {};
    this.investmentProfileDefinitions.forEach(profileDefinition => {
      if (!!this.getProfilePercentById(profileDefinition.id)) {
        profiles[profileDefinition.id] = this.getProfilePercentById(profileDefinition.id);
      }
    });
    return !!Object.keys(profiles).length ? profiles : null;
  }

  private getFundsData(): any {
    const funds: any = {};
    this.investmentProfileDefinitions.forEach(profileDefinition => {
      funds[profileDefinition.id] = null;
      profileDefinition.funds.forEach(fundDefinition => {
        if (!!this.getFundPercentById(profileDefinition.id, fundDefinition)) {
          if (!funds[profileDefinition.id]) {
            funds[profileDefinition.id] = {};
          }
          funds[profileDefinition.id][fundDefinition.id] = Number(this.getFundPercentById(profileDefinition.id, fundDefinition).toFixed(3));
        }
      });
    });
    return funds;
  }

  private getProfilePercentById(profileId: string): number {
    const profile = this.profiles.find(prf => prf.id === profileId);
    if (!!profile) {
      return !!profile.percent ? profile.percent / 100 : 0;
    }
    return 0;
  }

  private getFundPercentById(profileId: string, fundDef: PassFundDefinition): number {
    const profile = this.profiles.find(prf => prf.id === profileId);
    if (!!profile) {
      const fund = profile.funds.find(fnd => fnd.id === fundDef.id);
      if (!!fund) {
        const fundPercent = !!fund.percent ? fund.percent / 100 : 0;
        if (fundDef.fundTypeId && fundDef.fundTypeId === FUND_TYPE.GS) {
          const PRESTAZIONE_INIZIALE_GS = InvestmentUtils.getGSBenefitPerformance(this.policyService);
          this.disableGSFund(profileId, fund);
          if (!profile.currency && !fundPercent) {
            profile.currency = this.formGroup.get('profiles').value && this.formGroup.get('profiles').value[profileId] * this.investAmount;
          }
          return !!profile.currency ? (PRESTAZIONE_INIZIALE_GS) / profile.currency : fundPercent;
        }
        return fundPercent;
      }
    }
    return 0;
  }

  private disableGSFund(profileId: string, fund: Fund) {
    this.$karmaProfileDefinitions.map(p => {
      if (p.id === profileId) {
        p.funds.find(f => f.id === fund.id).disabled = true;
      }
    });
  }

  protected initializeToolOptions(options: PassResponseOption[]): void {
    options.forEach(toolOption => {
      (this.formGroup.get('tools') as UntypedFormGroup).addControl(toolOption.id, new UntypedFormControl());
    });
    this.toolOptions = options.map(option => {
      return {
        id: option.id,
        name: option.name,
        initialState: option.initialState,
        isToolBeingFullyHandled: this.cache.getToolStatusByUserInteraction(option.id) === ToolStatusOnCache.REMOVED || option.initialState === SelectionState.DEACTIVATED
      };
    });
  }

  private initializeTool(toolDefinition: ToolDefinition): void {
    // this.getToolData(toolDefinition);
    this.toolsDefinitionMap.set(toolDefinition.operationCodeId, toolDefinition);
    (this.formGroup.get('tools') as UntypedFormGroup)
      .addControl(toolDefinition.operationCodeId, new UntypedFormControl(this.getToolData(toolDefinition)));

   /* RDDL-2434 First Term factor at the moment is keeped like configured in Kelia, NOT CALCULATED.
      Uncomment to calculate adding fractiong selected in page: TOFIX the first fractioning selected in not calculated.
    if (LicToolUtils.isToolScheduledPartialWithdrawal(toolDefinition.operationCodeId)) {
      this.subscriptions.push(
        this.formGroup.get('tools').get(toolDefinition.operationCodeId).valueChanges.subscribe(value => {
          if (null !== value.factors.frequency) {
            const numRate = [...this.frequencyConversion.entries()]
              .filter(({1: v}) => v === Number(value.factors.frequency))
              .map(([k]) => k);
            value.factors.firstTerm = this.calculateFirstTerm(value.factors.effectDate, numRate[0]);
            this.formGroup.get('tools').get(toolDefinition.operationCodeId).setValue(value, {emitEvent: false});
          }
        })
      );
    }
   */
  }

  protected hasAlreadyBeenCompiled(): boolean {
    return this.policyService.investmentsData !== null && this.policyService.investmentsData.value !== null;
  }

  protected mergeProfilesFromPolicy(profilesFromKarma: Array<Profile>, profilesFromPolicy: Array<PassProfileData>): Array<Profile> {
    profilesFromPolicy.forEach(profileFromPolicy => {
      const profileFromKarma = profilesFromKarma.find(pk => pk.id === profileFromPolicy.id);
      profileFromKarma.percent = profileFromPolicy.percentage;
      profileFromKarma.selected = true;
      profileFromKarma.isPercent = true;
      profileFromPolicy.funds.forEach(fundFromPolicy => {
        const fundFromKarma = profileFromKarma.funds.find(pk => pk.id === fundFromPolicy.id);
        fundFromKarma.percent = fundFromPolicy.percentage;
        fundFromKarma.isPercent = true;
        fundFromKarma.selected = true;
      });
    });
    return profilesFromKarma;
  }

  private setBlockedProfile(profiles: Profile[], fixedProfileId: string): Profile[] {
    if (!!profiles) {
      profiles = profiles.filter(p => p.profileId === fixedProfileId);
      profiles.forEach(p => {
        p.selected = true;
        p.percent = 100;
        p.readOnly = true;
      });
      return profiles;
    }
    return [];
  }

  // @ts-ignore
  private checkIfToolOptChecked(value): boolean {
    return Object.keys(this.formGroup.getRawValue().tools).some(key => !!this.formGroup.getRawValue().tools[key]);
  }

  private updateProposal(response) {
    if (response.proposal && response.proposal.policyVersion) {
      this.mainProposal.proposal.policyVersion.effectiveDate = response.proposal.policyVersion.effectiveDate;
      const updatedPeffFactor = response.quote.product.factors.find(f => f.code === '_1PEFF');
      if (updatedPeffFactor) {
        const old = this.mainProposal.quote.product.factors.find(f => f.code === '_1PEFF');
        old.value = updatedPeffFactor.value;
        old.values = updatedPeffFactor.values;
      }
    }
  }

  private isWithFixedProfilePolicy(): boolean {
    const profiCp = this.policyService.getPropertyFromProduct(FactorCode.PROFILE);
    return profiCp && !!profiCp.values.find(v => v.code === FactorCode.PROFI_VALUE_FIXED);
  }

  private getFixedProfileId(): string {
    // RDDL-5015 given priority to the _LINEA factor
    const listFactor = this.getListFactor(this.mainProposal.quote.product.factors);
    if (!!listFactor) {
      const value = listFactor.values.find(v => v.value === listFactor.value);
      return this.getProfileId(value, listFactor);
    }
    return null;
  }

  getListFactor(factors: Factor[]): Factor {
    // _COMBIN management is only for external financial management
    // to indicate the profiles ID by hand.
    const lineaFactor = factors.find(f => f.code === FactorCode.LINEA);
    if (!!lineaFactor) {
      return lineaFactor;
    }
    const combinFactor = factors.find(f => f.code === FactorCode.COMBIN);
    return combinFactor;
  }

  getProfileId(value: Value, factor: Factor) {
    let profileId = null;
    if (!!value) {
      profileId = factor.code === FactorCode.LINEA ? value.value : value.transcoderCode1;
    }
    return profileId;
  }

  // in base al tool vengono prevalorizzati i dati che si possiedono
  protected getToolData(toolDefinition: ToolDefinition): any {
    const mainProposal: PolicyModel = this.mainProposal;

    if (LicToolUtils.isToolScheduledPremium(toolDefinition.operationCodeId)) {
      return this.getScheduledPremiumResponse(mainProposal, toolDefinition);
    } else if (LicToolUtils.isToolRiskReduction(toolDefinition.operationCodeId)) {
      return this.getRiskReductionData(toolDefinition, mainProposal);
    } else if (LicToolUtils.isToolPeriodicCoupon(toolDefinition.operationCodeId)) {
      return this.getScheduledPeriodicCoupon(toolDefinition, mainProposal);
    } else if (LicToolUtils.isToolScheduledPartialWithdrawal(toolDefinition.operationCodeId)) {
      return this.getScheduledPartialWithdrawal(toolDefinition, mainProposal);
    } else if (LicToolUtils.isToolProgressiveSwitch(toolDefinition.operationCodeId)) {
      return this.getProgressiveSwitchResponse(toolDefinition, mainProposal);
    } else if (
      LicToolUtils.isToolLockIn(toolDefinition.operationCodeId) ||
      LicToolUtils.isToolStopLoss(toolDefinition.operationCodeId)
    ) {
      return this.getStopLossAndOtherChangeMyNamePlease(toolDefinition, mainProposal);
    }
  }

  private getRiskReductionData(toolDefinition: ToolDefinition, mainProposal: PolicyModel) {
    return {
      factors: LicToolUtils.getFactorStrutture(toolDefinition, mainProposal, this.lifeRoleService),
      investmentProfiles: [],
      defaultFunds: false
    };
  }

  private getProgressiveSwitchResponse(toolDefinition: ToolDefinition, mainProposal: PolicyModel) {
    const profiles: { [key: string]: any } = LicToolUtils.getDefaultFormValueOf(
      toolDefinition, true, true, this.selectedProfilesFunds
    ).investmentProfiles;
    const profile = this.toolsDefinitionMap.get(toolDefinition.operationCodeId).investmentProfiles[0];
    const selectedFund = profile.funds;

    profiles[profile.id] = {
      percentage: 100,
      funds: selectedFund
    };

    return {
      factors: LicToolUtils.getFactorStrutture(toolDefinition, mainProposal, this.lifeRoleService),
      investmentProfiles: profiles,
      defaultFunds: true
    };
  }

  private getStopLossAndOtherChangeMyNamePlease(toolDefinition: ToolDefinition, mainProposal: PolicyModel) {
    const profiles: { [key: string]: any } = LicToolUtils.getDefaultFormValueOf(
      toolDefinition, true, true, this.selectedProfilesFunds
    ).investmentProfiles;
    const profile = this.toolsDefinitionMap.get(toolDefinition.operationCodeId).investmentProfiles[0];
    const selectedFund = profile.funds;

    profiles[profile.id] = {
      percentage: 100,
      funds: selectedFund
    };

    return {
      factors: LicToolUtils.getFactorStrutture(toolDefinition, mainProposal, this.lifeRoleService),
      investmentProfiles: profiles,
      defaultFunds: true
    };
  }

  private getScheduledPeriodicCoupon(toolDefinition: ToolDefinition, mainProposal: PolicyModel) {
    return {
      factors: LicToolUtils.getFactorStrutture(toolDefinition, mainProposal, this.lifeRoleService),
      investmentProfiles: [],
      defaultFunds: false
    };
  }

    private getScheduledPartialWithdrawal(toolDefinition: ToolDefinition, mainProposal: PolicyModel) {
    return {
      factors: LicToolUtils.getFactorStrutture(toolDefinition, mainProposal, this.lifeRoleService),
      investmentProfiles: [],
      defaultFunds: false
    };
  }

  private getScheduledPremiumResponse(mainProposal: PolicyModel, toolDefinition: ToolDefinition) {
    const profiles: { [key: string]: any } = LicToolUtils.getDefaultFormValueOf(
      toolDefinition, true, true, this.selectedProfilesFunds
    ).investmentProfiles;

    return {
      factors: LicToolUtils.getFactorStrutture(toolDefinition, mainProposal, this.lifeRoleService),
      investmentProfiles: profiles,
      defaultFunds: true,
    };
  }

// @ts-ignore
  private calculateFirstTerm(effectDate: string, numRate: number): string {
    const num = 12 / numRate;
    const computeFirstTerm = new Date(effectDate);

    computeFirstTerm.setMonth(computeFirstTerm.getMonth() + num);
    return this.datePipe.transform(computeFirstTerm, 'yyyy-MM-dd');
  }

  onCalculateFinantialParam() {
    this.subscriptions.push(
      this.showLoaderWhileHandling(
        combineLatest([this.calculateVolatility(), this.calculateSustainability()])
      ).subscribe(([volatility, sustainability]: [VolatilityResponse, SustainabilityData]) => {
        this._sustainability = sustainability;
        this._assetVolatility = volatility;
      })
    );
  }


  protected calculateSustainability(): Observable<SustainabilityData> {
    if (!this.withSustainability) {
      return of(null);
    }
    return this.fund.calculateSustainability(
      PassUtils.mapToPassdata(
        this.formGroup.get('profiles').value,
        this.formGroup.get('funds').value,
        this.investAmount
      ),
      this.getEffectiveDate(),
      this.getProductCode(),
      this.getNetPremiumAmount(),
      this.managementNode
    ).pipe(
      map(resp => new SustainabilityData(resp, this.numberPipe, this.policyService)),
      catchError(err => {
        return of (new SustainabilityData({errors: [err]} as SustainabilityData, this.numberPipe, this.policyService));
      })
    );
  }

  protected calculateVolatility(): Observable<VolatilityResponse> {
    if (!this.withVolatility) {
      return of(null);
    }
    return this.fund.calculateVolatility(
      PassUtils.mapToPassdata(
        this.formGroup.get('profiles').value,
        this.formGroup.get('funds').value,
        this.investAmount
      ),
      this.getEffectiveDate(),
      this.getIdProduct(),
      this.getNetPremiumAmount(),
      this.contractorId,
      this.managementNode
    ).pipe(
      catchError(err => {
        return of ({errors: [err]} as VolatilityResponse);
      })
    );
  }

  /**
   * @description this method is called recalculateDate because it updates the dates value by the response of the invest call.
   * @see {@link updateProposal} which updates the dates factor value.
   */
  private recalculateDate(isToCall: boolean): Observable<any> {
    this.$errorMessagesFromServices = [];
    if (isToCall && !!this.formGroup.get('profiles').value && !!this.formGroup.get('funds').value) {
      const request = this.getSaveInvestmentRequest();
      return this.policyService.invest(request).pipe(
        tap(responseInvestment => {
          if (!!responseInvestment) {
            this.policyService.investmentsData = responseInvestment.proposal.extensionData.properties.find(
              prop => prop.chiave === ExtensionProperty.ISSUE_INVESTMENTS);
            this.updateProposal(responseInvestment);
          }
        }),
        catchError((err: any) => {
          return this.handlesErrorInvestmentsCall(err);
        })
      );
    } else {
      return of({});
    }
  }

  protected handlesErrorInvestmentsCall(err: any): Observable<never> {
    if (this.HTTP_STATUS_CODE_CHECK_INVESTMENT_DATE === err.status) {
      const descriptionErrorMessage = this.lifeHttpErrorMessageHandlerService.getDescriptionErrorMessageByCatchError(err);
      const errorInPage = MessageHandlerService.createSimpleErrorMessage(descriptionErrorMessage);
      this.$errorMessagesFromServices.push(errorInPage);
      return EMPTY;
    }
    if (this.HTTP_STATUS_CODE_CHECK_INVESTMENT_DATE !== err.status
      && (this.policyService.useRgiRxHttpClientWrapper() || this.policyService.getExcludeErrorInterceptor())) {
      const dialog = this.modalService.open(LicInvestmentsModalErrorComponent, {
        centered: true,
        size: 'lg',
        windowClass: 'in',
        backdropClass: 'light-blue-backdrop in'
      });
      dialog.componentInstance.messages = this.lifeHttpErrorMessageHandlerService.getModalErrorMessagesByCatchError(err);
    }

    return throwError(err);
  }

  protected getSaveInvestmentRequest() {
    const request = PassUtils.mapToPassdata(
      this.formGroup.get('profiles').value,
      this.formGroup.get('funds').value,
      this.investAmount
    );
    PassUtils.addFundTypeIdOnRequest(request, this.investmentProfileDefinitions);
    return request;
  }

  protected showLoaderWhileHandling(obs: Observable<any>): Observable<any> {
    if (this.numberOfRequests === 0) {
      this.eventPropagation.emit('startLoader');
    }
    this.numberOfRequests++;
    return obs.pipe(
      finalize(() => {
        this.numberOfRequests--;
        if (this.numberOfRequests === 0) {
          this.eventPropagation.emit('stopLoader');
        }
      })
    );
  }

  isToolCached(id: string): boolean {
    const session = this.cache.getSession(this.policyService.mainProposalResourceId);
    if (!!session) {
      return !!session.investments.tools[id];
    }
    return false;
  }

  // determines if the scheduled premium step will be visible or not
  isScheduledPremiumActive(): boolean {
    const factor = this.mainProposal.quote.product.factors.find(el => el.code === FactorCode.SCHEDULED_PREMIUM);
    return factor && factor.value === '1';
  }

  // if this is active profiles and funds will be hidden inside of scheduled premium step and the investmentPlan will be de same of the policy
  isDistributionSameAsInitial(): boolean {
    const factor = this.mainProposal.quote.product.factors.find(el => el.code === FactorCode.SCHEDULED_PREMIUM_DISTRIBUTION);
    return factor && factor.value === '1';
  }

  getInvestmentAmountOfProfile(definition: KarmaProfileDefinition, step: string) {
    if (step === 'investment') {
      if (!!this.formGroup.get('profiles').value) {
        return this.investAmount * this.formGroup.get('profiles').value[definition.id];
      }
    } else if (step === 'scheduledPremium') {
      return this.formGroup.get(['scheduledPremium', 'amountToInvest']).value * this.formGroup.get('scheduledPremium').value[definition.id];
    }
    return 0;
  }
}

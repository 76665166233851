<div class="anag-issue-modal issue-card-modal-content re-issue-card-styles">
  <div class="anag-issue-issue-card-issue-card-modal-header issue-card-modal-header">
    <div class="card-title">
      <span class="rgi-re-header-icon rgifont "></span>
      <span class="menu-title" translate>{{questionnaire?.descriptionType}}</span>
    </div>
    <span class="rgifont rgi-close pull-right" (click)="closeModal()"></span>
  </div>
  <div class="anag-issue-modal-body issue-card-modal-body">
    <!--    <div class="re-issue-questionnaire">
          <ppevo-questionnaire [questionnaireFlat]="questionnaire" [disabled]="false"></ppevo-questionnaire>
        </div>-->

    <!--    <div>
          <div id="error-container" class="error-container"
               *ngIf="questionnaireSubmitted && validationMessages?.length > 0">
            <pnc-form-message type="error" detail="validationError" [messages]="validationMessages"
                              data-qa="action-error-messages" objectType="complex"></pnc-form-message>
          </div>
        </div>-->

    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button class="btn btn-primary" (click)="closeModal()">Annulla</button>
      </div>
      <div class="btn-group">
        <button class="btn btn-primary" (click)="save()">Conferma</button>
      </div>
    </div>
  </div>
</div>

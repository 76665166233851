import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataValueFormatterPipe } from './data-value-formatter.pipe';
import { RgiCountryLayerModule, RgiCountryLayerNumberFormatService} from '@rgi/country-layer';

@NgModule({
  imports: [
    RgiCountryLayerModule,
    CommonModule
  ],
  declarations: [DataValueFormatterPipe],
  exports: [DataValueFormatterPipe],
  providers: [
    RgiCountryLayerNumberFormatService
  ]
})
export class DataValueFormatterModule { }

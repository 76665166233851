import {Component, Inject, Input, Optional, ViewEncapsulation} from '@angular/core';
import {Premium} from '../model/premium.model';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../services/currency-cache.service';

@Component({
  selector: 'lpc-premium-table',
  templateUrl: './lpc-premium-table.component.html',
  styleUrls: ['./lpc-premium-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LpcPremiumTableComponent {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() premium: Premium;

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

}

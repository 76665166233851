import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Inject, Injectable, Optional} from '@angular/core';
import {DatePipe} from "@angular/common";
import {QUEST_CFG, QuestionnaireCfg} from "../../questionnaire-cfg";


@Injectable(
  {providedIn: "root"}
)
export class DateFormatter extends NgbDateParserFormatter {


  private sep = "-";

  constructor(private datePipe: DatePipe, @Optional() @Inject(QUEST_CFG) private questionnaireConfig?: QuestionnaireCfg) {
    super();
    if (questionnaireConfig && questionnaireConfig.dateSeparator) {
      this.sep = questionnaireConfig.dateSeparator;
    }
  }

  format(date: NgbDateStruct): string {
        if(!date){
            return "";
        }
        const month = (date.month<10 ? "0" : "")+(date.month);
        const day = (date.day<10 ? "0" : "")+(date.day);
        return `${day}${this.sep}${month}${this.sep}${date.year}` ;
    }

    parse(value: string): NgbDateStruct{
      //let d = new Date(this.datePipe.transform(value, `yyyy${this.sep}MM${this.sep}dd`));
      if(typeof value == "string")
        {
            const parts = value.split(this.sep);
            if(parts.length === 3){
                const d: Date = new Date(parts[2] + "-"+parts[1]+"-"+parts[0]);
                if(!isNaN(d.getTime())){
                    return {
                        year:  d.getFullYear(),
                        month: d.getMonth()+1,
                        day: d.getDate()
                    }
                }
            }

        }
        return null;

    }
}

import {NgModule} from '@angular/core';
import {ReIssueModule} from './re-issue/re-issue.module';
import {RgiRxRoutingModule} from '@rgi/rx/router';
import {RE_ROUTES} from './re-issue/re-issue-routes';
import {DatePipe} from '@angular/common';
import {ReIssueCoreModule} from './re-issue-core/re-issue-core.module';
import {RgiRxDatePickerModule} from '@rgi/rx/ui';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {TRANSLATIONS_RE_ISSUE} from './translate_i18n/i18n';

@NgModule({
  declarations: [],
  imports: [
    ReIssueModule,
    ReIssueCoreModule,
    RgiRxDatePickerModule,
    RgiRxI18nModule.forRoot(TRANSLATIONS_RE_ISSUE),
    RgiRxRoutingModule.forRoot(RE_ROUTES)
  ],
  exports: [
    ReIssueModule,
    ReIssueCoreModule,
  ],
  providers: [
    DatePipe
  ]
})
export class ReIssueCardModule {
}

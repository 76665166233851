import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {RgiCountryLayerContext} from '../rgi-country-layer.api';

// fixme this directive should detect changes!
@Directive({
  selector: '[rgiCountryLayerCurrencyFormat]' // rgiCountyCurrencyFormatter
})
export class RgiCountryLayerCurrencyFormatDirective implements AfterViewInit {
  /* eslint-disable @angular-eslint/no-input-rename */
  @Input('currencyLocale') locale = '';
  @Input('currencyFormatOptions') options: Intl.NumberFormatOptions = {};

  constructor(
    private el: ElementRef,
    private context: RgiCountryLayerContext
  ) {}


  ngAfterViewInit(): void {
    const value = parseFloat(this.el.nativeElement.textContent);
    const LOCALE = !this.locale ? this.locale : this.context.locale;
    if (!isNaN(value)) {
      this.el.nativeElement.textContent = new Intl.NumberFormat(LOCALE, this.options).format(value); // fixme use the format service
    }
  }
}

import {InsuranceStatus} from '../../models/domain-models/insurance-status';
import {ApiPremiumDetail} from '../../models/api-models/api-premium-detail';

export class EntitledQuotation {

  constructor(
    public premium: ApiPremiumDetail,
    public insuranceStatus: InsuranceStatus
  ) {
  }

}

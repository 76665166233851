import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '@rgi/rx/ui';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {AnagUbicationComponent, CORE_ADDRESS_FUNCTION} from '@rgi/anag';

class AnagAddressData {
  modalLabel: string;
  addressType: string;
  hideNormalize: boolean;
  address: any;
}

@Component({
  selector: 're-issue-address',
  templateUrl: './re-issue-address.component.html',
  styleUrls: ['./re-issue-address.component.css']
})
export class ReIssueAddressComponent implements OnInit {
  @Input()
  addressData: AddressApiContacts[];
  @Input()
  formatAddress: string;
  @Input()
  isDeleteEnabled: boolean;
  @Input()
  instanceAssetKey: string;
  @Input()
  maxAddress: number;
  @Input()
  firstAddressMode: number;
  @Input()
  isMainAsset: boolean;
  @Output() createdAddressEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateAddressEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteAddressEmitter: EventEmitter<any> = new EventEmitter<any>();
  radioButtonSelected: AddressApiContacts;
  @Output() selectedAddressEmitter: EventEmitter<AddressApiContacts> = new EventEmitter<AddressApiContacts>();

  constructor(protected customModalService: ModalService) {
  }

  ngOnInit() {
  }


  addAddress() {
    const ubicationData = new AnagAddressData();
    ubicationData.modalLabel = 'Indirizzo'; // can be whatever you want, please use translation keys
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.portfolioSubject;
    // default value, you can change it to rietreve different address configuration (check exported CORE_ADDRESS_FUNCTION)
    ubicationData.hideNormalize = false; // optional, hides the normalize button when set to true. default false
    const ubicationModal = this.customModalService.openComponent(AnagUbicationComponent, ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(address => { // addr is an AnagApiAddress object
      if (address) {
        const emitted = {address, instanceAssetKey: this.instanceAssetKey};
        this.createdAddressEmitter.emit(emitted);
      }
    });
  }

  changeAddressData(addressIn: any) {
    const ubicationData = new AnagAddressData();
    ubicationData.address = addressIn;
    ubicationData.modalLabel = 'Indirizzo'; // can be whatever you want, please use translation keys
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.portfolioSubject;
    // default value, you can change it to rietreve different address configuration (check exported CORE_ADDRESS_FUNCTION)
    ubicationData.hideNormalize = false; // optional, hides the normalize button when set to true. default false
    const ubicationModal = this.customModalService.openComponent(AnagUbicationComponent, ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(address => { // addr is an AnagApiAddress object
      if (address) {
        const emitted = {address, instanceAssetKey: this.instanceAssetKey};
        this.updateAddressEmitter.emit(emitted);
      }
    });
  }

  deleteAddress(event, address) {
    const emitedd = {address, instanceAssetKey: this.instanceAssetKey};
    this.deleteAddressEmitter.emit(emitedd);
  }

  updateAddressMain(event: Event, i: number) {
    this.addressData.forEach((address, index) => {
      if (index === i) {
        address.main = true;
        (event.target as HTMLInputElement).checked = true;
      } else {
        address.main = false;
        (event.target as HTMLInputElement).checked = false;
      }
    });
  }
}

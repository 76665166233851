import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClauseEntity } from '../domain/clause-entity';
import { DocumentEntity } from '../domain/document-entity';

@Component({
  selector: 'claims-add-document-data',
  templateUrl: './add-document-data.component.html',
  styleUrls: ['./add-document-data.component.scss']
})
export class AddDocumentDataComponent implements OnInit {
  @Input() document: DocumentEntity;
  public clausesTemp: Array<ClauseEntity>;
  public classtext: string = 'col-xs-12';

  constructor(
    public activeModal: NgbActiveModal,
    @Inject('eventService') private eventService: any,
    private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.clausesTemp = [];
    this.document.clauses.forEach(el => {
     this.clausesTemp.push(Object.assign({}, el));
    });
  }

  valTypeCss12(clause: ClauseEntity) {
    return clause.type === '5';
  }

  onConfirm() {
    this.document.clauses.forEach(el => {
      if (el.type === '3') {
        if (Boolean(el.value).valueOf()) {
          el.value = '1';
        } else {
          el.value = null;
        }
      }
     });
    this.activeModal.close('OK');
  }

  closeModal() {
    this.document.clauses = this.clausesTemp;
    this.activeModal.dismiss();
  }

}

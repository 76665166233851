import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiEnumType, EditablePanelContentComponent, FunctionService, PanelService, PanelSignal } from '@rgi/digital-claims-common-angular';
import { ExposureEntity } from '../domain/exposure-entity';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { PaymentEntity } from '../domain/payment-entity';
import { PotentialPaymentEntity } from '../domain/potential-payment-entity';
import { NewPaymentService } from '../new-payment.service';
import { DamageCardDto } from './dto/damage-card-dto';
import { ExcessDamageDto } from './dto/excess-damage-dto';
import { PaymentDto } from './dto/payment-dto';
import { ReserveCapacityObj } from './dto/reservecapacityobj';
import { TypeOfLossDamageDto } from './dto/type-of-loss-dto';
import { HistoricalErosionComponent } from './historical-erosion/historical-erosion.component';
import { DamageObj } from './wsobj/damageobj';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-quote-evaluation',
  templateUrl: './quote-evaluation.component.html',
  styleUrls: ['./quote-evaluation.component.scss']
})
export class QuoteEvaluationComponent extends EditablePanelContentComponent implements OnInit {

  @Input() card: any;
  @Input() potPayFE: InitPotentialPaymentEntity;
  @Input() newPayForm: UntypedFormGroup;
  @Input() isDetail?: boolean = false;
  @Input() payment: PaymentEntity;
  @Output() stepperEventEmitter = new EventEmitter<number>();
  @Output() blockMsgEmitter: EventEmitter<string> = new EventEmitter<string>();

  paymentFormGroup: UntypedFormGroup;
  typeOfLossFormGroup: UntypedFormGroup;

  enumAdvanceExcAggrLiqValues: ApiEnumType[];
  enumAdvanceExcClLiqValues: ApiEnumType[];
  enumAdvanceSirLiqValues: ApiEnumType[];
  enumAdvanceDeductibleLiqValues: ApiEnumType[];

  economicConditionExclusion: boolean = false;
  enabled: boolean = true;
  isLoading: boolean = true;
  isSettlement: boolean = false;
  isSingleCausal: boolean = true;
  totalAmount: number;
  enableManualExcess: boolean = false;
  typeOfLossDamage: TypeOfLossDamageDto[] = [];

  errorMessage: string = null;
  warningMessage: string = null;
  reserveCapacity: boolean = false;
  reserveAuthorization = false;
  injuryCodeMissing: boolean = false;

  private calculated: boolean = false;
  private claimCurrent: any;
  private party: any;
  mapTol: Map<string, any> = new Map<string, any>();
  mapEnum: Map<string, any> = new Map<string, any>();
  eTipoQuietanzaOmniaCompresi: TypeEnum;

  constructor(
    panelService: PanelService,
    @Inject('enumService') private enumService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('eventService') private eventService: any,
    @Inject('authService') private authService,
    @Inject('LoadingHistoricalErosionExcess') private loadingHistoricalErosionExcess,
    @Inject('getSystemProperty') private getSystemProperty: any,
    public translateService: RgiRxTranslationService,
    private formBuilder: UntypedFormBuilder,
    private functionService: FunctionService,
    private newPaymentService: NewPaymentService,
    private modalService: NgbModal,
    private paymentConstants: PaymentConstants,
  ) {
    super(panelService);
  }

  initEnums() {
    this.eTipoQuietanzaOmniaCompresi = this.paymentConstants.getSingleEtipoQuietanza(ENUMSLISTPAYMENT.ETIPOQUIETANZA_OMNIA_COMPRESI);
  }

  ngOnInit() {
    this.initEnums();
    this.economicConditionExclusion = this.functionService.isAuthorizedForClaimFeature('CLAIMS_ECONOMIC_CONDITION_EXCLUSION');

    this.initDataForm();
    this.claimCurrent = this.coreResult.getResult(this.card.id, 'detail');
    this.party = this.coreResult.getResult(this.card.id, 'party');
    if (this.isDetail) {
      this.claimCurrent = {claimNumber: this.potPayFE.claimNumber};
      this.loadingDamageCard(this.claimCurrent.claimNumber, this.party.identifier, 'newOfferClaim');
    }
  }

  initDataForm() {

    this.paymentFormGroup = this.formBuilder.group({
      checkManualExcess: new UntypedFormControl(),
      manualExcessAmount: new UntypedFormControl()
    }, {});
  }

  addTolToForm(idTol) {
    this.typeOfLossFormGroup.addControl('advanceAggregateExcess' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('advanceSir' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('advanceClaimExcess' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('advanceDeductible' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('excludeAggregateExcess' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('excludeClaimExcess' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('excludeDeductible' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('excludeLimit' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('excludeFirstSir' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.addControl('excludeSecondSir' + idTol, new UntypedFormControl());
    this.typeOfLossFormGroup.valueChanges.subscribe(() => {
      this.calculated = false;
    });
  }

  loadingDamageCard(claimNumber: string, idParty: number, reasonUpdate: string) {
    this.eventService.broadcastEvent('start-loader');
    this.injuryCodeMissing = false;
    console.log('loadingDamageCard 6');

    this.newPaymentService.loadingDamageCard(claimNumber, idParty, reasonUpdate)
      .subscribe((damageObjResult: DamageObj) => {

        if (damageObjResult && damageObjResult !== null) {
          const injuryCode = (damageObjResult.damageCardPortal &&
                             damageObjResult.damageCardPortal.generalData &&
                             damageObjResult.damageCardPortal.generalData.injuryCode) ?
                             damageObjResult.damageCardPortal.generalData.injuryCode : '';

          this.getSystemProperty.findProperty('ClaimInjuryCode').then((result) => {
            if (result && result.systemProperties && result.systemProperties.length) {
              for (const variable of result.systemProperties) {
                if (variable.key === 'ClaimInjuryCode') {
                 const injuryCodeEnable = variable.value;
                 if (injuryCodeEnable === 'Enable' && injuryCode === '0001'
                     && this.claimCurrent.ministerialBranchCodes === '000013') {
                   this.injuryCodeMissing = true;
                   this.errorMessage = 'Injury Code is Missing: you will not able to proceed with the new offer!';
                 }
                }
              }
            }
          });

          this.enumAdvanceExcAggrLiqValues = this.enumService.getEnumList('claims.AdvanceExcessAggrLiq');
          this.enumAdvanceExcClLiqValues = this.enumService.getEnumList('claims.AdvanceExcClLiq');
          this.enumAdvanceSirLiqValues = this.enumService.getEnumList('claims.AdvanceSirLiq');
          this.enumAdvanceDeductibleLiqValues = this.enumService.getEnumList('claims.AdvanceDeductibleLiq');

          this.typeOfLossDamage = [];
          const typeOfLossDamageLoading = damageObjResult.damageCardPortal && damageObjResult.damageCardPortal !== null ?
            damageObjResult.damageCardPortal.typeOfLossDamage : [];
          this.typeOfLossFormGroup = new UntypedFormGroup({});
          typeOfLossDamageLoading.forEach((tol) => {
            let tolTotalInputAmount = 0.0;
            let isTolToShow = false;
            if (this.potPayFE && this.potPayFE.paymentList) {
              this.potPayFE.paymentList.forEach((potentialPayment) => {
                if (potentialPayment.exposureList) {
                  potentialPayment.exposureList.forEach((exposureElement) => {
                    if (exposureElement.selected && exposureElement.idCausaleDanno === tol.idTol.toString(0)) {
                      tolTotalInputAmount += exposureElement.amount;
                      isTolToShow = true;
                    }
                  });
                }
              });
              if (isTolToShow) {
                tol.amountTolInput = + tolTotalInputAmount;
                this.addTolToForm(tol.idTol);
                if (tol.historicalErosionExcess.enumAdvanceExcessAggr) {
                 this.typeOfLossFormGroup.controls['advanceAggregateExcess' + tol.idTol]
                                   .setValue(tol.historicalErosionExcess.enumAdvanceExcessAggr.codice);
                }
                if (tol.historicalErosionExcess.enumAdvanceExcessCl) {
                 this.typeOfLossFormGroup.controls['advanceClaimExcess' + tol.idTol]
                                   .setValue(tol.historicalErosionExcess.enumAdvanceExcessCl.codice);
                }
                if (tol.historicalErosionExcess.enumAdvanceSir) {
                 this.typeOfLossFormGroup.controls['advanceSir' + tol.idTol]
                                   .setValue(tol.historicalErosionExcess.enumAdvanceSir.codice);
                }
                if (tol.historicalErosionExcess.enumAdvanceDeductibleLiq) {
                 this.typeOfLossFormGroup.controls['advanceDeductible' + tol.idTol]
                                   .setValue(tol.historicalErosionExcess.enumAdvanceDeductibleLiq.codice);
                }
                this.typeOfLossDamage.push(tol);
                this.chargeExcessHistory(claimNumber, tol, this.mapTol);
              }
            }
            if (tol.enableManualExcess) {
              this.enableManualExcess = true;
            }
          });

          this.isSingleCausal = this.typeOfLossDamage.length < 2;
        }
        if (this.isDetail) {
          if (!this.potPayFE.receiptPresent) {
            const idPayment = this.payment.idPayment ? this.payment.idPayment : this.potPayFE.numReceipt;
            if (idPayment) {
              this.getCalculateServiceInput();
              this.newPaymentService.getFormDamagePhoto(idPayment).subscribe(
                (damageCardOutput: DamageCardDto) => {
                  if (damageCardOutput) {
                    this.calculated = true;
                    this.totalAmount = 0;
                    // this.typeOfLossDamage = damageCardOutput.payments[0].typeOfLosses;
                    this.typeOfLossDamage = [];

                    this.potPayFE.paymentList.forEach(
                      payment => {
                        damageCardOutput.payments.forEach(
                          paymentOutput => {
                            if (payment.id === paymentOutput.tempId) {
                              payment.totalAmountNet = paymentOutput.companyAmount;
                              this.totalAmount += (paymentOutput.companyAmount + paymentOutput.insuredAmount);

                              if (paymentOutput.typeOfLosses) {
                                paymentOutput.typeOfLosses.forEach(
                                  tol => {
                                    this.typeOfLossDamage.push(tol);
                                    const currentTol = payment.exposureList.find(
                                      stol => {
                                        return +stol.idCausaleDanno === +tol.idTol;
                                      }
                                    );
                                    currentTol.amountInsured = tol.historicalErosionExcess.amountLiqInsured;
                                    currentTol.amountNet = tol.amountTolOutput;
                                    currentTol.advanceClaimExcess = tol.advanceClaimExcess;
                                    currentTol.advanceAggregateExcess = tol.advanceAggregateExcess;
                                    currentTol.advanceDeductible = tol.advanceDeductible;
                                    currentTol.erodedAggregateExcess = tol.historicalErosionExcess.excessAggrErodedAmount;
                                    currentTol.erodedClaimExcess = tol.historicalErosionExcess.excessClErodedAmount;
                                    currentTol.erodedDeductible = tol.historicalErosionExcess.deductibleErodedAmount;
                                    currentTol.excludeClaimExcess = tol.excludeClaimExcess;
                                    currentTol.excludeDeductible = tol.excludeDeductible;
                                    currentTol.excludeAggregateExcess = tol.excludeAggregateExcess;
                                    currentTol.excludeLimit = tol.excludeLimit;
                                    currentTol.excludeFirstSir = tol.excludeFirstSir;
                                    currentTol.excludeSecondSir = tol.excludeSecondSir;
                                    currentTol.advanceSir = tol.advanceSir;
                                    currentTol.erodedFirstSir = tol.historicalErosionExcess.firstSirErodedAmount;
                                    currentTol.erodedSecondSir = tol.historicalErosionExcess.secondSirErodedAmount;
                                  }
                                );
                              }
                            }
                          }
                        );
                      }
                    );
                  }

                  this.eventService.broadcastEvent('stop-loader');
                },
                (error: any) => {
                  console.log('calculateSaveDamageCard - outcome: ERRORE\r\n' + error);
                  this.eventService.broadcastEvent('stop-loader');
                }
              );
            } else {
              this.eventService.broadcastEvent('stop-loader');
            }
          } else {
            this.calculate();
          }
        } else {
          this.eventService.broadcastEvent('stop-loader');
        }
      },
        (error: any) => {
          console.log('loadingDamageCard - outcome: ERRORE\r\n' + error);
          this.eventService.broadcastEvent('stop-loader');
        });
  }

  calculate() {
    this.errorMessage = null;
    this.eventService.broadcastEvent('start-loader');

    const damageCardDto = this.getCalculateServiceInput();

    this.newPaymentService.calculateSaveDamageCard(this.claimCurrent.claimNumber, damageCardDto)
      .subscribe(
        (damageCardOutput: DamageCardDto) => {

          if (damageCardOutput) {

            this.calculated = true;
            this.totalAmount = 0;
            // this.typeOfLossDamage = damageCardOutput.payments[0].typeOfLosses;
            this.typeOfLossDamage = [];

            this.potPayFE.paymentList.forEach(
              payment => {
                damageCardOutput.payments.forEach(
                  paymentOutput => {
                    if (payment.id === paymentOutput.tempId) {
                      payment.totalAmountNet = paymentOutput.companyAmount;
                      this.totalAmount += (paymentOutput.companyAmount + paymentOutput.insuredAmount);

                      paymentOutput.typeOfLosses.forEach(
                        tol => {
                          this.typeOfLossDamage.push(tol);
                          const currentTol = payment.exposureList.find(
                            stol => {
                              return +stol.idCausaleDanno === +tol.idTol;
                            }
                          );
                          currentTol.amountInsured = tol.historicalErosionExcess.amountLiqInsured;
                          currentTol.amountNet = tol.amountTolOutput;
                          currentTol.advanceClaimExcess = tol.advanceClaimExcess;
                          currentTol.advanceAggregateExcess = tol.advanceAggregateExcess;
                          currentTol.advanceDeductible = tol.advanceDeductible;
                          currentTol.erodedAggregateExcess = tol.historicalErosionExcess.excessAggrErodedAmount;
                          currentTol.erodedClaimExcess = tol.historicalErosionExcess.excessClErodedAmount;
                          currentTol.erodedDeductible = tol.historicalErosionExcess.deductibleErodedAmount;
                          currentTol.excludeClaimExcess = tol.excludeClaimExcess;
                          currentTol.excludeDeductible = tol.excludeDeductible;
                          currentTol.excludeAggregateExcess = tol.excludeAggregateExcess;
                          currentTol.excludeLimit = tol.excludeLimit;
                          currentTol.excludeFirstSir = tol.excludeFirstSir;
                          currentTol.excludeSecondSir = tol.excludeSecondSir;
                          currentTol.advanceSir = tol.advanceSir;
                          currentTol.erodedFirstSir = tol.historicalErosionExcess.firstSirErodedAmount;
                          currentTol.erodedSecondSir = tol.historicalErosionExcess.secondSirErodedAmount;
                        }
                      );
                    }
                  }
                );
              }
            );
          }
          if (this.isDetail && !this.limitControl()) {
            this.blockMsgEmitter.emit(this.errorMessage);
          }
          this.eventService.broadcastEvent('stop-loader');
        },
        (error: any) => {
          console.log('calculateSaveDamageCard - outcome: ERRORE\r\n' + error);
          this.eventService.broadcastEvent('stop-loader');
        }
      );
  }

  verifyReserveCapacity() {
    this.eventService.broadcastEvent('start-loader');
    let x = 0;
    let y = 0;
    this.potPayFE.paymentList.forEach((potentialPayment) => {
      if (potentialPayment.exposureList) {
        x += potentialPayment.exposureList.length;
      }
    });
    this.potPayFE.paymentList.forEach((potentialPayment) => {
      if (potentialPayment.exposureList) {
        potentialPayment.exposureList.forEach((exposureElement) => {
          const reserveCapacityObj = new ReserveCapacityObj();
          reserveCapacityObj.claimNumber = this.claimCurrent.claimNumber;
          reserveCapacityObj.tolId = exposureElement.idCausaleDanno;
          reserveCapacityObj.amount = exposureElement.amountNet.toString();
          reserveCapacityObj.definitionType = exposureElement.definition;
          reserveCapacityObj.generatePayment = potentialPayment.generatePayment;
          this.newPaymentService.verifyReserveCapacity(reserveCapacityObj)
            .subscribe((response: any) => {
              if (response.code === '0') {
                this.reserveCapacity = false;
              }
              if (response.code === '1') {
                this.reserveCapacity = true;
              }
              if (response.code === '2') {
                this.reserveAuthorization = true;
                this.reserveCapacity = false;
              }
              y++;
              if (x === y) {
                if (!this.reserveCapacity) {
                  this.translateService.translate('_CLAIMS_._MESSAGE_._RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE')
                .subscribe(
                  res => this.errorMessage=res
                )
                  document.getElementById('updateReserve' + reserveCapacityObj.tolId).style.display = 'block';
                } else if (this.reserveAuthorization) {
                  this.translateService.translate('_CLAIMS_._MESSAGE_._IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE')
                  .subscribe(
                    res => this.errorMessage=res
                  )
                } else {
                  this.setPanelComplete();
                  this.disablePanelContent();
                  this.enabled = false;
                  this.stepperEventEmitter.emit(2);
                  this.signalToNextPanel(PanelSignal.open);
                }
              }
            },
              (error: any) => {
                console.log('verifyReserveCapacity - outcome: ERROR ');
                this.reserveCapacity = false;
              });
        });
      }
    });
    this.eventService.broadcastEvent('stop-loader');
  }

  onClickAccordion(idTol: number) {
    // GTODO Cosa dovrebbe succedere?
  }

  protected validateContent(): boolean {
    throw new Error('Method not implemented.');
  }

  protected storeData() {
    throw new Error('Method not implemented.');
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (this.acceptSignals) {
      if (panelSignal === PanelSignal.open) {

        this.loadingDamageCard(this.claimCurrent.claimNumber, this.party.identifier, 'newOfferClaim');

        if (this.typeOfLossDamage && this.typeOfLossDamage.length > 1) {
          this.isSingleCausal = false;
        }


        this.enabled = true;
        this.calculated = false;
        this.errorMessage = null;
        this.enableAndOpenContent();
        this.setPanelIncomplete();
      }
    }
  }

  onConfirm() {
    this.errorMessage = null;
    if (!this.calculated) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM')
      .subscribe(
        res => this.errorMessage=res
      )
    } else {
      this.limitControl();
      if (!this.errorMessage) {
        this.verifyReserveCapacity();
      }
    }
  }

  private limitControl() {
    if (this.existsNegativeLimit()) {
      const listFunz: string[] = this.authService.getOperator().enabledFeatures;
      // SXLQOM => Claims - Enabling Limits Forcing
      if (listFunz && listFunz.includes('SXLQOM')) {
        this.eventService.broadcastEvent('alertMsg',
          { varMsg: this.translateService.translate('_CLAIMS_._MESSAGE_._ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT')
          .subscribe(
            res => res
          ) });
          this.translateService.translate('_CLAIMS_._MESSAGE_._ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT')
                  .subscribe(
                    res => this.errorMessage=res
                  )
          
      } else {
        this.translateService.translate('_CLAIMS_._MESSAGE_._UNABLE_TO_MAKE_THE_PAYMENT__THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED')
        .subscribe(
          res => this.errorMessage=res
        )
        
        return false;
      }
    }
    return true;
  }

  private existsNegativeLimit() {
    let existsNegativeLimit = false;
    this.typeOfLossDamage.forEach(tol => {
      if (tol.limits && tol.limits.some(limit => limit.limitAmountResidue < 0)) {
        existsNegativeLimit = true;
      }
    });
    return existsNegativeLimit;
  }

  modifyReserve(tol: any) {
    document.getElementById('updateReserve' + tol.idTol).style.display = 'none';
    tol.damagesDescriptionId = tol.idTol;
    this.errorMessage = null;
    this.reserveCapacity = true;
    this.reserveAuthorization = false;
    const idNavigation = this.sessionService.open('claimsDamageCard', 'home', '', true, null, null, this.card.idSession);
    this.coreResult.setResult(idNavigation, 'operationJump', {
      jumpCommand: 'modifyReserve',
    });
    this.coreResult.setResult(idNavigation, 'filter', {});
    this.coreResult.setResult(idNavigation, 'lastSession', {
      sessionId: this.card.idSession,
      component: 'quote-evalutation'
    });
    this.coreResult.setResult(idNavigation, 'detail', this.claimCurrent);
    this.coreResult.setResult(idNavigation, 'party', this.party);
    this.coreResult.setResult(idNavigation, 'causal', tol);
  }

  private getCalculateServiceInput() {

    const checkManualExcessField = this.paymentFormGroup.get('checkManualExcess');
    const manualExcessAmountField = this.paymentFormGroup.get('manualExcessAmount');
    const damageCardDto = new DamageCardDto();

    damageCardDto.payments = [];

    this.potPayFE.paymentList.forEach(payment => {

      const paymentInput = new PaymentDto();

      paymentInput.paymentType = new ApiEnumType(payment.paymentType, '');
      paymentInput.tempId = payment.id;
      paymentInput.typeOfLosses = this.getTypeOfLossFromPayment(payment);

      damageCardDto.payments.push(paymentInput);

      if (checkManualExcessField.value) {
        damageCardDto.manualExcess = manualExcessAmountField.value;
      }
    });

    return damageCardDto;
  }

  private setSubtypeCodes(payment: PotentialPaymentEntity, typeOfLoss: ExposureEntity, damageCardTypeOfLoss: TypeOfLossDamageDto) {

    enum ESUBTYPE {
      NUMERO_PROCEDIMENTO = '3',
      DOMICILIATARI = '4',
      GRADO_DI_GIUDIZIO = '5'
    }

    const subtypeCodes: ApiEnumType[] = [];

    const selectedProcedureCode = this.potPayFE.procedureCode;
    if (this.potPayFE.procedureCodeList) {
      const procedureCode = this.potPayFE.procedureCodeList.find(
        procCode => procCode.code === selectedProcedureCode
      );
      if (procedureCode) {
        // GRADO_DI_GIUDIZIO
        subtypeCodes.push(new ApiEnumType(ESUBTYPE.GRADO_DI_GIUDIZIO, procedureCode.moreInfo));
        // NUMERO_PROCEDIMENTO
        subtypeCodes.push(new ApiEnumType(ESUBTYPE.NUMERO_PROCEDIMENTO, selectedProcedureCode));
      }
    }

    // DOMICILIATARI
    enum DOMICILIATARIO {
      LIBERA_SCELTA = '8',
      CONTROPARTE = '10'
    }
    const rolesDomiciliatario = [DOMICILIATARIO.LIBERA_SCELTA.toString(), DOMICILIATARIO.CONTROPARTE.toString()];
    let existDomiciliatario = false;
    if (payment.receiptType === this.eTipoQuietanzaOmniaCompresi.eTypeCode) {

      typeOfLoss.subExposureList.filter(subExp => subExp.selected && rolesDomiciliatario.includes(subExp.injured.subjectLitigationType))
        .forEach(subExp => {
          damageCardTypeOfLoss.amountTolOmniaCompresiDomiciliatari += subExp.legalFeeAmount;
          existDomiciliatario = true;
        });

    } else if ( typeOfLoss.injured && rolesDomiciliatario.includes(typeOfLoss.injured.subjectLitigationType)) {
      existDomiciliatario = true;
    }
    if (existDomiciliatario) {
      subtypeCodes.push(new ApiEnumType(ESUBTYPE.DOMICILIATARI, ''));
    }

    damageCardTypeOfLoss.subtypeCodes = subtypeCodes;
  }

  private getTypeOfLossFromPayment(payment: PotentialPaymentEntity) {

    return payment.exposureList
      .filter(typeOfLoss => typeOfLoss.selected)
      .map(typeOfLoss => this.paymentToDamageCardObject(payment, typeOfLoss));
  }

  // It trasnforms payment type of loss object to the damage card type of loss object
  private paymentToDamageCardObject(payment: PotentialPaymentEntity, paymentTypeOfLoss: ExposureEntity) {

    const damageCardTypeOfLoss = new TypeOfLossDamageDto(paymentTypeOfLoss);
    const historicalErosionExcess = new ExcessDamageDto();
    const idTol = damageCardTypeOfLoss.idTol;

    const advanceAggregateExcessField = this.typeOfLossFormGroup.get('advanceAggregateExcess' + idTol);
    const advanceAdvanceSirField = this.typeOfLossFormGroup.get('advanceSir' + idTol);
    const advanceClaimExcessField = this.typeOfLossFormGroup.get('advanceClaimExcess' + idTol);
    const advanceDeductibleField = this.typeOfLossFormGroup.get('advanceDeductible' + idTol);

    const excludeAggregateExcessField = this.typeOfLossFormGroup.get('excludeAggregateExcess' + idTol);
    const excludeClaimExcessField = this.typeOfLossFormGroup.get('excludeClaimExcess' + idTol);
    const excludeDeductibleField = this.typeOfLossFormGroup.get('excludeDeductible' + idTol);
    const excludeLimitField = this.typeOfLossFormGroup.get('excludeLimit' + idTol);
    const excludeFirstSir = this.typeOfLossFormGroup.get('excludeFirstSir' + idTol);
    const excludeSecondSir = this.typeOfLossFormGroup.get('excludeSecondSir' + idTol);
    if (this.typeOfLossDamage.length > 0 &&
        this.typeOfLossDamage
          .find(typeOfLoss => typeOfLoss.idTol.toString() === paymentTypeOfLoss.idCausaleDanno)) {
      const savedHistorycalErosionExcess = this.typeOfLossDamage
        .find(typeOfLoss => typeOfLoss.idTol.toString() === paymentTypeOfLoss.idCausaleDanno).historicalErosionExcess;
      damageCardTypeOfLoss.advanceAggregateExcess = advanceAggregateExcessField ?
        new ApiEnumType(advanceAggregateExcessField.value, '') : savedHistorycalErosionExcess.enumAdvanceExcAggrLiq;
      damageCardTypeOfLoss.advanceClaimExcess = advanceClaimExcessField ?
        new ApiEnumType(advanceClaimExcessField.value, '') : savedHistorycalErosionExcess.enumAdvanceDeductibleLiq;
      damageCardTypeOfLoss.advanceDeductible = advanceDeductibleField ?
        new ApiEnumType(advanceDeductibleField.value, '') : savedHistorycalErosionExcess.enumAdvanceExcClLiq;
      damageCardTypeOfLoss.advanceSir = advanceAdvanceSirField ?
        new ApiEnumType(advanceAdvanceSirField.value, '') : savedHistorycalErosionExcess.enumAdvanceSir;
      // damageCardTypeOfLoss.advanceSirLiq = advanceAdvanceSirField ?
      //   new ApiEnumType(advanceAdvanceSirField.value, '') : savedHistorycalErosionExcess.enumAdvanceSirLiq;

    }

    damageCardTypeOfLoss.excludeAggregateExcess = excludeAggregateExcessField ? excludeAggregateExcessField.value : null;
    damageCardTypeOfLoss.excludeClaimExcess = excludeClaimExcessField ? excludeClaimExcessField.value : null;
    damageCardTypeOfLoss.excludeDeductible = excludeDeductibleField ? excludeDeductibleField.value : null;
    damageCardTypeOfLoss.excludeLimit = excludeLimitField ? excludeLimitField.value : null;
    damageCardTypeOfLoss.excludeFirstSir = excludeFirstSir ? excludeFirstSir.value : null;
    damageCardTypeOfLoss.excludeSecondSir = excludeSecondSir ? excludeSecondSir.value : null;

    if (this.isDetail) {
      if (paymentTypeOfLoss) {
        damageCardTypeOfLoss.advanceAggregateExcess = this.fillEnumDescription(paymentTypeOfLoss.advanceAggregateExcess);
        this.mapEnum.set('advanceAggregateExcess' + idTol, paymentTypeOfLoss.advanceAggregateExcess);
        damageCardTypeOfLoss.advanceClaimExcess = this.fillEnumDescription(paymentTypeOfLoss.advanceClaimExcess);
        this.mapEnum.set('advanceClaimExcess' + idTol, paymentTypeOfLoss.advanceClaimExcess);
        damageCardTypeOfLoss.advanceDeductible = this.fillEnumDescription(paymentTypeOfLoss.advanceDeductible);
        this.mapEnum.set('advanceDeductible' + idTol, paymentTypeOfLoss.advanceDeductible);
        damageCardTypeOfLoss.advanceSir = this.fillEnumDescription(paymentTypeOfLoss.advanceSir);
        this.mapEnum.set('advanceSir' + idTol, paymentTypeOfLoss.advanceSir);

        damageCardTypeOfLoss.excludeAggregateExcess = paymentTypeOfLoss.excludeAggregateExcess;
        this.mapEnum.set('excludeAggregateExcess' + idTol, paymentTypeOfLoss.excludeAggregateExcess);
        damageCardTypeOfLoss.excludeClaimExcess = paymentTypeOfLoss.excludeClaimExcess;
        this.mapEnum.set('excludeClaimExcess' + idTol, paymentTypeOfLoss.excludeClaimExcess);
        damageCardTypeOfLoss.excludeDeductible = paymentTypeOfLoss.excludeDeductible;
        this.mapEnum.set('excludeDeductible' + idTol, paymentTypeOfLoss.excludeDeductible);
        damageCardTypeOfLoss.excludeLimit = paymentTypeOfLoss.excludeLimit;
        this.mapEnum.set('excludeLimit' + idTol, paymentTypeOfLoss.excludeLimit);
        damageCardTypeOfLoss.excludeFirstSir = paymentTypeOfLoss.excludeFirstSir;
        this.mapEnum.set('excludeFirstSir' + idTol, paymentTypeOfLoss.excludeFirstSir);
        damageCardTypeOfLoss.excludeSecondSir = paymentTypeOfLoss.excludeSecondSir;
        this.mapEnum.set('excludeSecondSir' + idTol, paymentTypeOfLoss.excludeSecondSir);

        this.mapEnum.set('isExclusionPresent' + idTol, paymentTypeOfLoss.excludeAggregateExcess ||
          paymentTypeOfLoss.excludeClaimExcess ||
          paymentTypeOfLoss.excludeDeductible ||
          paymentTypeOfLoss.excludeLimit ||
          paymentTypeOfLoss.excludeFirstSir ||
          paymentTypeOfLoss.excludeSecondSir);
      }
    }

    damageCardTypeOfLoss.historicalErosionExcess = historicalErosionExcess;

    this.setSubtypeCodes(payment, paymentTypeOfLoss, damageCardTypeOfLoss);

    return damageCardTypeOfLoss;
  }

  fillEnumDescription(enumType: ApiEnumType): ApiEnumType {
    const obstr1$ = this.translateService.translate('_CLAIMS_._COMPANY');
    const obstr2$ = this.translateService.translate('_CLAIMS_._INSURED');
    combineLatest([
      obstr1$,
      obstr2$,
    ]).subscribe(
      ([tr1,tr2]) =>{
        if (enumType && enumType.codice && !enumType.descrizione){
          enumType.descrizione = enumType.codice === '1' ? tr1 : tr2
        }
        
      }
      
    )
    return enumType;
  }

  onChangeManualExcess(event: any) {

    const manualExcessField = this.paymentFormGroup.get('manualExcessAmount');
    const checkedManualExcess = event.target.checked;

    if (checkedManualExcess) {
      manualExcessField.enable();
    } else {
      manualExcessField.disable();
      manualExcessField.setValue('');
    }
  }

  // It trasnforms old output type of loss object to the new input type of loss object
  private copyAndClean(typeOfLosses) {

    const ignoredPropertiesTypeOfLoss = [
      'amountExpertCTPInput',
      'amountExpertInput',
      'assignmentExpensesAmount',
      'enableAssignmentExpensesAmount',
      'enumReasonUpdate',
      'selectedTol',
      'tolFactor',
      'updateDate',
    ];
    const ignoredPropertiesExcessHistory = [
      'amountLiq',
      'amountLiqCompany',
      'applicationNumber',
      'causeDescription',
      'claimNumber',
      'firstSirAmount',
      'idCause',
      'idInjuryCode',
      'idPartyClaiming',
      'idServerityCode',
      'idTypeOfLoss',
      'incidentDate',
      'injuryCode',
      'insuredEndDate',
      'insuredStartDate',
      'insDate',
      'movementDescr',
      'notifyDate',
      'partyClaiming',
      'policyId',
      'policyNumber',
      'secondSirAmount',
      'severityCode',
      'tolDescription',
      'unitDescription',
      'validityEndDate',
      'validityStartDate'
    ];
    const ignoredPropertiesLimit = [
      'isInRetroLimitErosionGross',
      'isLimitErosionGross'
    ];

    return typeOfLosses.map(typeOfLoss => {
      const currentTol = { ...typeOfLoss };
      ignoredPropertiesTypeOfLoss.forEach(ignoredProperty => {
        delete currentTol[ignoredProperty];
      });
      ignoredPropertiesExcessHistory.forEach(ignoredProperty => {
        delete currentTol.historicalErosionExcess[ignoredProperty];
      });
      typeOfLoss.limits.forEach(limit => {
        ignoredPropertiesLimit.forEach(ignoredProperty => {
          delete limit[ignoredProperty];
        });
      });
      return currentTol;
    });
  }

  chargeExcessHistory(clnum: string, tol: TypeOfLossDamageDto, mapTol: Map<string, any> ) {
      const enableHistory = tol.historicalErosionExcess
      && (tol.historicalErosionExcess.excessClaimAmount > 0 ||
          tol.historicalErosionExcess.excessAggrAmount > 0 ||
          tol.historicalErosionExcess.firstSirAmount > 0 ||
          tol.historicalErosionExcess.secondSirAmount > 0);

      mapTol[tol.idTol] = {
            historyErosionList: null,
            enableHistoryExcess: false
          };

      if (enableHistory) {
        this.loadingHistoricalErosionExcess.loading({ claimNumber: clnum, idTol: tol.idTol })
            .$promise.then((result: any) => {
              if (result && result.historicalErosionExcess) {
                const elements = result.historicalErosionExcess;
                mapTol[tol.idTol] = {
                  historyErosionList: elements,
                  enableHistoryExcess: true
                };
          }
        }).finally();
      }
  }

  openDetailHistory(tol: TypeOfLossDamageDto) {

    const modalRef = this.modalService.open(HistoricalErosionComponent, {
      windowClass: 'basic',
      size: 'lg'
    });

    modalRef.componentInstance.tol = tol;
    modalRef.componentInstance.historyErosionList = this.mapTol[tol.idTol].historyErosionList;

    modalRef.result.then((result) => {
      if (result) {
        console.log('Return HistoricalErosionComponent ->', result);
      }
    });
    return false;
  }
}

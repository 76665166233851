import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

@Directive({
  selector: '[rgiRxSdkElementObserver]'
})
export class RgiRxSdkElementObserverDirective implements AfterViewInit, OnDestroy {
  private resizeObserver: any;

  @Output()
  readonly onElementRendered = new EventEmitter<void>();

  @Output()
  readonly onElementResize = new EventEmitter<ResizeObserverEntry>();

  constructor(private elementRef: ElementRef<HTMLElement>) {}
  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      const changes = entries[0];
      this.onElementResize.emit(changes);

      const contentRect = changes.contentRect;
      if (contentRect.width > 0 && contentRect.height > 0) {
        setTimeout( () => {
          this.onElementRendered.emit(); // fixme this can emit multiple times is width is set to 0 and again resized
          if (this.onElementResize.observers.length === 0) {
            this.resizeObserver.disconnect();
          }
        });
      }
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }
}

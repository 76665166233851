import {PortalCard, PortalCards, PortalConfig} from '@rgi/rx/portal';


export const RE_ISSUE_CARD: PortalCards = [

  {

    card: {

      name: 'newReContract',

      type: 'main',

      category: 'Contract Management',

      title: 'START_EMISSION_FLOW_RE',

      navigationInSession: true,

      subCards: [],


    } as PortalCard,
    routes: [
      {
        type: 'home',
        route: 'home',
        label: 'START_EMISSION_FLOW_RE',
        destination: 're-issue-start-card'
      },
      {
        route: 'policyData',
        label: 'START_POLICY_DATA',
        destination: 're-issue-policy-data'
      },
      {
        route: 'assetData',
        label: 'START_ASSETS_DATA',
        destination: 're-issue-asset'
      },
      {
        route: 'quotation',
        label: 'START_QUOTATION',
        destination: 're-issue-quotation'
      },
      {
        route: 'summary',
        label: 'START_SUMMARY',
        destination: 're-issue-summary'
      },
      {
        route: 'proposalManagement',
        label: 'START_PROPOSAL_MANAGEMENT',
        destination: 're-issue-proposal-management'
      },
      {
        route: 'saveProposal',
        label: 'START_SAVE_PROPOSAL',
        destination: 're-issue-save-quote'
      },
      {
        route: 'issue',
        label: 'START_EMISSION',
        destination: 're-issue-emission'
      },
      {
        route: 'reprintDocuments',
        label: 'START_REPRINT_DOCUMENTS',
        destination: 're-issue-reprint-documents'
      },
      {
        route: 'reprintMovements',
        label: 'START_REPRINT_MOVEMENTS',
        destination: 're-issue-reprint-movements'
      },
      {
        route: 'substitution',
        label: 'START_SUBSTITUTION',
        destination: 're-substitution-start'
      },
      {
        route: 'cancellation',
        destination: 're-cancellation-start'
      },
      {
        route: 'cancellationSummary',
        destination: 're-cancellation-summary'
      },
      {
        route: 'cancellationConfirmation',
        destination: 're-cancellation-confirmation'
      }
    ]

  }
];

declare var angular: angular.IAngularStatic;

export function manageNewCardInPassPortal(config?: PortalConfig) {
  const module = angular.module(config.module);
  module.config(['$provide', function($provide) {

    $provide.decorator('PtfDamageDetailService',
      ['$delegate', 'sessionService', 'coreResult', 'authService', 'gettext',
        function FinderServiceDecorator($delegate, sessionService, coreResult, authService, gettext) {

          // $controller('PtfAllActionsModalCtrl', {
          //   $uibModalInstance: $uibModalInstance,
          // });

          const baseService = $delegate;
          const extended = angular.extend({}, baseService);

          const superActionSubstitute = extended.actionSubstitute;
          extended.actionSubstituteRE = ($scope, scopeCard, idResult, contract, go, $uibModalInstance) => {
            const req = {
              // policyOperationRequest: policyOperationRequest,
              policy: contract.policyNumber,
              // backNavigate: 're-issue-proposal-view',
              contract: contract,
              authenticationNodeCode: authService.getOperator().salePoint.code,
              productionNodeCode: authService.getOperator().salePoint.code,
              userCode: authService.getOperator().username,
              node: {
                "code": contract.productionNode.code,
                "idSp": contract.productionNode.identification,
                "description": contract.productionNode.description,
              },
            };
            const title = gettext('Sostituzione Polizza') + ' n. ' + contract.policyNumber;
            // tslint:disable-next-line:max-line-length
            // const scopeCardCopy = angular.copy(scopeCard);
            // scopeCardCopy.card.title = title;
            // tslint:disable-next-line:max-line-length
            const sessionId = sessionService.open('newReContract', 'substitution', title, true, null, null, scopeCard.card.idSession, scopeCard.card.id);
            coreResult.setResult(sessionId, 'substitution', req);
            sessionService.remove(scopeCard.card.idSession);
            $uibModalInstance.close(null);
          };
          extended.actionCancellationRE = ($scope, scopeCard, idResult, contract, $uibModalInstance) => {
            const req = {
              // policyOperationRequest: policyOperationRequest,
              policy: contract.policyNumber,
              // backNavigate: 're-issue-proposal-view',
              contract,
              authenticationNodeCode: authService.getOperator().salePoint.code,
              productionNodeCode: authService.getOperator().salePoint.code,
              userCode: authService.getOperator().username,
              node: {
                code: contract.productionNode.code,
                idSp: contract.productionNode.identification,
                description: contract.productionNode.description,
              },
            };
            const title = gettext('Annullo polizza') + ' n. ' + contract.policyNumber;
            // tslint:disable-next-line:max-line-length
            // const scopeCardCopy = angular.copy(scopeCard);
            // scopeCardCopy.card.title = title;
            // tslint:disable-next-line:max-line-length
            const sessionId = sessionService.open('newReContract', 'cancellation', title, true, null, null, scopeCard.card.idSession, scopeCard.card.id);
            coreResult.setResult(sessionId, 'cancellation', req);
            sessionService.remove(scopeCard.card.idSession);
            $uibModalInstance.close(null);
          };
          return extended;
        }
      ]);
  }]);
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductSourceService} from "./product-source.service";
import {ProductService} from "./product.service";



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ProductSourceService, ProductService]
})
export class ProductResourcesModule { }

export class DamageCategoryCheckBox {
    constructor(
        public codeBranch: string,
        public codeCovertureType: string,
        public codeCoverture: string,
        public riskcode: string,
        public description: string,
        public id: string,
        public riskFactors: string,
        public covertureFactors: string,
        public dShortage: string,
        public existingCoverage: boolean,
        public forceCheck?: boolean
    ) { }
}

import moment from 'moment';
import { FormFieldType } from '../../../models/enums/vita.enum';
import { FormFieldsDefinition } from '../../../models/postsales-operations-response.model';

export class LpcFormFieldHandlerUtil {
  public static mapFormFieldData(x: FormFieldsDefinition): {
    [x: string]: any;
  } {
    if (x.type === FormFieldType.PARTY) {
      return x.values[0]
        ? {
            [x.code]: {
              id: x.values[0].id,
              name: x.values[0].description,
            },
          }
        : { [x.code]: null };
    }
    return {
      [x.code]: x.value,
    };
  }

  public static extractFormFieldData(dataTransfer: any, partiesKey?: string[]) {
    const extractedData = [];
    for (const obj of dataTransfer) {
    const key = Object.keys(obj)[0];
    const value = obj[key];

    const dateFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';
    const isDate = moment(value, dateFormat, true).isValid();
    const dateValue = isDate ? value : null;


    let fieldValue;
    if (isDate) {
      fieldValue = { code: key, dateValue };
    } else if (partiesKey && partiesKey.includes(key)) {
      fieldValue = { code: key, value: value && value.id ? value.id : null };
    } else {
      fieldValue = { code: key, value };
    }
    extractedData.push(fieldValue);
    }
    return extractedData;
    }
  }

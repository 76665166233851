import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxLocalStateManager, StateStoreService} from '@rgi/rx/state';
import {AuthorizationCardStateDetail} from './authorization-card-state';
import {AuthorizationCardReducerService} from './authorization-card-reducer.service';
import {Authorization} from "../resources/models/authorization";
import {Contract} from "../resources/models/contract";


export class AuthorizationCardStateManagerDetail extends RgiRxLocalStateManager<AuthorizationCardStateDetail> {

  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, private reducer: AuthorizationCardReducerService) {
    super(activeRoute, stateStoreService);
  }

  openGroupPolicySession(authorizationDetail: Authorization) {
    this.reducer.openGroupPolicySession(authorizationDetail);
  }

  openPassPortalStandardSession(contractId: any) {
    this.reducer.openPassPortalStandardSession(contractId);
  }

  openNMFSession(authorizationDetail: Authorization) {
    this.reducer.openNMFSession(authorizationDetail);
  }

  openNMFMandatoryDataSession(proposalNumber: string, policyNumber: any, createCopy: boolean, route: string, operation: any) {
    this.reducer.openNMFMandatoryDataSession(proposalNumber, policyNumber, createCopy, route, operation);
  }

  openNMFAuthorizationSession(createCopy: boolean, route: string, authorizationDetail: Authorization) {
    this.reducer.openNMFAuthorizationSession(createCopy, route, authorizationDetail);
  }

  openNewReFlowSession(authorizationDetail: Authorization) {
    this.reducer.openNewReFlowSession(authorizationDetail);
  }

  openNewReFlowSessionIssue(authorizationDetail: Authorization) {
    this.reducer.openNewReFlowSessionIssue(authorizationDetail);
  }

  modifyProposalLife(output, contract: Contract, title: string, idSession: string) {
    this.reducer.modifyProposalLife(output, contract, title, idSession);
  }

  modifyVariationLife(authorizationDetail: Authorization, title: string, id: string) {
    this.reducer.modifyVariationLife(authorizationDetail, title, id);
  }

  confirmVariationLife(authorizationDetail: Authorization, contract: Contract, id: string) {
    this.reducer.confirmVariationLife(authorizationDetail, contract, id);
  }

  goToLifeIssueResult(output, title: string, id: string) {
    this.reducer.goToLifeIssueResult(output, title, id);
  }

  openDefaultResolution(action, contract: Contract, authorizationDetail: Authorization) {
    this.reducer.openDefaultResolution(action, contract, authorizationDetail);
  }
}

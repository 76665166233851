import {Injectable} from '@angular/core';
import {RePostsalesCancellationNavigationData, RePostsalesCancellationState} from './re-postsales-cancellation-state';
import {RePostsalesStateManagerService} from './re-postsales-state-manager.service';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {EventService} from '@rgi/rx';
import {Observable, of} from 'rxjs';
import {
  RePostsalesCancellationStartBusinessService
} from '../re-postsale-services/re-postsales-cancellation-start-business.service';
import {RePostSalesCancellationSummaryNavigationDataI} from './re-postsales-state-manager-cancellation-summary.service';
import {UntypedFormGroup} from '@angular/forms';
import {OperationsOutput} from '../re-postsale-model/re-postsales-cancellation';

@Injectable()
export class RePostsalesStateManagerCancellationStartService<T extends RePostsalesCancellationState> extends RePostsalesStateManagerService<T> {

  constructor(
    activeRoute: ActiveRoute,
    stStoreService: StateStoreService,
    protected routingService: RoutingService,
    public eventService: EventService,
    protected datesBusinessService: RePostsalesCancellationStartBusinessService<T>) {
    super(activeRoute, stStoreService, routingService, eventService);
    const st =
      !!stStoreService.get<T>(this.activeRoute.id) ?
        stStoreService.get<T>(this.activeRoute.id) : this.newDamagesPostsalesCancellationState();
    this.updateState$(this.initDamagesPostSalesCancellation(st));
  }

  initAllData(st: T, policyOperationsInput): Observable<Array<OperationsOutput>> {
    return this.datesBusinessService.getCancellationReason$(st, policyOperationsInput);
  }

  newDamagesPostsalesCancellationState(): T {
    return new RePostsalesCancellationState(this.activeRoute.id) as T;
  }

  initDamagesPostSalesCancellation(st: T): Observable<T> {
    const req = this.activeRoute.getRouteData<RePostsalesCancellationNavigationData>();
    if (req.policy) {
      st.policy = req.policy;
    }

    if (req.backNavigate) {
      st.backNavigate = req.backNavigate;
    }

    if (req.contract) {
      st.contract = req.contract;
    }

    if (req.node) {
      st.node = req.node;
    }

    if (req.authenticationNodeCode) {
      st.authenticationNodeCode = req.authenticationNodeCode;
    }

    if (req.productionNodeCode) {
      st.productionNodeCode = req.productionNodeCode;
    }

    if (req.userCode) {
      st.userCode = req.userCode;
    }

    if (req.cancellationReason) {
      st.cancellationReason = req.cancellationReason;
    }

    if (req.issueDate) {
      st.issueDate = req.issueDate;
    }

    if (req.effectiveDate) {
      st.effectiveDate = req.effectiveDate;
    }

    if (req.premiumRepaymentVisibility) {
      st.premiumRepaymentVisibility = req.premiumRepaymentVisibility;
    }

    if (req.taxes) {
      st.taxes = req.taxes;
    }

    if (req.taxable) {
      st.taxable = req.taxable;
    }

    if (req.rights) {
      st.rights = req.rights;
    }

    if (req.note) {
      st.note = req.note;
    }

    return of(st);
  }

  goToNextPage(cancellationForm: UntypedFormGroup, premiumRepaymentForm: UntypedFormGroup) {
    const st = this.getCurrentState();
    const request: RePostSalesCancellationSummaryNavigationDataI = {
      policy: st.policy,
      backNavigate: st.backNavigate,
      contract: st.contract,
      authenticationNodeCode: st.authenticationNodeCode,
      productionNodeCode: st.productionNodeCode,
      node: st.node,
      userCode: st.userCode,
      cancellationReason: cancellationForm.value.cancellationReason,
      issueDate: cancellationForm.value.issueDate,
      effectiveDate: cancellationForm.value.effectDate,
      premiumRepaymentVisibility: st.premiumRepaymentVisibility,
      taxes: premiumRepaymentForm.value.taxes,
      taxable: premiumRepaymentForm.value.taxable,
      rights: premiumRepaymentForm.value.rights,
      note: st.note
    };
    this.routingService.navigate('re-cancellation-summary', request, this.activeRoute.id);
  }

}

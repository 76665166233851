import {PolicyAddress} from './policy-address';

export class PolicyContacts {

  constructor(
    public availableAddress: Array<PolicyAddress>,
    public availablePhoneNumbers: Array<string>,
    public availableEmails: Array<string>,
    public addressList: Array<PolicyAddress>,
    public mobilePhone: string,
    public email: string,
    public channel: number
  ) {
  }

}

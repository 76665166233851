import { FACTOR_TOOL_CODE, ToolCode } from '../../../models/enums/vita.enum';
import { InputFieldDefinition } from '../../../models/postsales-operations-response.model';
import { PlcDateUtils } from '../../../utils/plc-date-utils';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { LpcKarmaFundUtils } from '../../lpc-karma-funds/utils/lpc-karma-fund-utils';
import { ToolConfDefinition } from '../model/tool-conf-definition';
import { ToolDefinition } from '../model/tool-definition';

export class LpcToolUtils {

  public static getDefaultFormValueOf(
    toolDefinition: ToolDefinition, valorized: boolean, defaultFunds: boolean, hideEnumWithOneValue: boolean
  ): any {
    const obj: any = {
      factors: {},
      investmentProfiles: {},
      disinvestmentProfiles: {},
      defaultFunds
    };
    toolDefinition.factors.forEach(factor => {
      if (factor.code === FACTOR_TOOL_CODE.FIRSTTERM_CODE && factor.type === 'DATELIST') {
        factor.value = PlcDateUtils.isoDateTimeToIsoDate(factor.value);
        factor.values = factor.values.map(date => {
          return {
            id : date.id,
            description: PlcDateUtils.isoDateTimeToIsoDate(date.description)
          };
        });
      }

      // nascondo e valorizzo gli enum con un solo possibile valore (se attivo il flag hideEnumWithOneValue)
      if (factor.type === 'ENUM' && factor.values && factor.values.length === 1) {
        factor.value = factor.values[0].id;
        factor.visible = !!hideEnumWithOneValue ? false : factor.visible;
        factor.readOnly = !!hideEnumWithOneValue || factor.readOnly
      }

      obj.factors[factor.code] = valorized ? factor.value : null;
    });
    obj.investmentProfiles = LpcKarmaFundUtils.getProfileFormValueOf(
      PlcObjectUtils.asValidArray(toolDefinition.investmentProfiles)
    );
    obj.disinvestmentProfiles = LpcKarmaFundUtils.getProfileFormValueOf(
      PlcObjectUtils.asValidArray(toolDefinition.disinvestmentProfiles), toolDefinition.operationCodeId, 'disinvest'
    );
    return obj;
  }

  // TODO implement this method to fix the prevalorization of the lpc_investment_funds
  public static evalInvestFundsAccordingToDefaultFunds(defaultFunds: boolean, investmentProfiles: any[]) {
    if (defaultFunds) {
      /* TODO correct evaluation
      investmentProfiles.forEach((profile: {funds: any[]}) => {
        profile.funds.forEach((fund: {fund: {value: number} }) => {
          fund.fund.value = 0;
        });
      }); */
    }
    return investmentProfiles;
  }

  public static factorToolToInputField(_toolConfDef: ToolConfDefinition): InputFieldDefinition {
    /*
    * INPUT: obj: ToolConfDefinition
    * OUTPUT: obj: InputFieldDefinition with .listValue populated with
    *              an array of the input of ToolConfDefinition.value.description
    */
    const inputDef = {
      code: !!_toolConfDef.code ? _toolConfDef.code : null,
      listValue: [],
      defaultValue: !!_toolConfDef.value ? _toolConfDef.value : null
    } as InputFieldDefinition;

    if (!!_toolConfDef && !!_toolConfDef.values && !!_toolConfDef.values.length) {
      _toolConfDef.values.map(_listValue => {
        if (!!_listValue.description) {
          inputDef.listValue.push(_listValue.description);
        }
      });
    }

    return inputDef;
  }

  public static isToolScheduledPremium(toolCode: string): boolean {
    return toolCode === ToolCode.SCHEDULED_PREMIUM;
  }

  public static isToolScheduledPartialWithdrawal(toolCode: string): boolean {
    return toolCode === ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL;
  }

  public static isToolLockIn(toolCode: string): boolean {
    return toolCode === ToolCode.LOCK_IN;
  }

  public static isToolStopLoss(toolCode: string): boolean {
    return toolCode === ToolCode.STOP_LOSS;
  }

  public static isToolProgressiveSwitch(toolCode: string): boolean {
    return toolCode === ToolCode.PROGRESSIVE_SWITCH;
  }

}

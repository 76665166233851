<div [formGroup]="formGroup" class="tool">
  <div [ngClass]="{'tool-head' : active }">
    <div *ngIf="active">
      <div id="checkbox_min_plus_accordion">
        <span class="rgi-plus" *ngIf="!isToolBodyOpen()"></span>
        <span class="rgi-minus" *ngIf="isToolBodyOpen()"></span>
      </div>
      <pnc-checkbox class="float-tool-name" formControlName="active"></pnc-checkbox>
    </div>
    <div *ngIf="active || !active && isSelected" translate>{{ toolName }}</div>
  </div>
  <div *ngIf="active || !active && isSelected" [ngClass]="{'tool-body': active, 'tool-body-inactive': !active, 'active' : isToolBodyOpen() }">
    <div #toolBodyContent>
      <ng-content></ng-content>
    </div>
  </div>
</div>

import {AgreementsContainer} from '../../../models/domain-models/parameters/agreements-container';
import {TacitRenewal} from '../../../models/domain-models/parameters/tacitRenewal';
import {PaymentFrequencyContainer} from '../../../models/domain-models/parameters/payment-frequency-container';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Agreement} from '../../../models/domain-models/parameters/agreement';
import {PaymentFrequency} from '../../../models/api-models/api-payment-frequency';
import {PolicyDate} from '../../../models/domain-models/parameters/policy-date';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Ie11UtilityService} from '../../re-issue-utility/ie-11-utility.service';
import {PolicyTechnicalData} from '../../../models/domain-models/parameters/policy-technical-data';
import {DateValidatorUtilityService} from '../../re-issue-utility/date-validator-utility.service';
import {Currency} from '../../../models/domain-models/parameters/currency';
import {UseTax} from '../../../models/domain-models/parameters/use-tax';
import {UseTaxesContainer} from '../../../models/domain-models/parameters/use-tax-container';
import {RgiRxPushMessage, PushMessageHandlerService, RgiRxLogger} from '@rgi/rx';
import {CoInsuranceDTO} from '../../../models/domain-models/co-insurance';
import {Binder} from '../../../models/domain-models/parameters/binder';
import {IndexType} from '../../../models/domain-models/parameters/index-type';

@Component({
  selector: 're-issue-technical-details',
  templateUrl: './re-issue-technical-details.component.html',
  styleUrls: ['./re-issue-technical-details.component.css']
})
export class ReIssueTechnicalDetailsComponent implements OnInit, OnChanges {

  @Input() set policyTechnicalData(val: any) {
    if (this.subscriptions) {
      this.unsubscribeAll();
    }
  }

  @Input() set formSubmitted(val: boolean) {
    this.isFormSubmitted = val;
    if (val) {
      this.markFormAsDirtyAndTouched();
    }
  }

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected dateValidatorUtilityService: DateValidatorUtilityService,
    protected logger: RgiRxLogger,
    protected ie11Utility: Ie11UtilityService,
    protected errorService: PushMessageHandlerService,
  ) {
    this.initializeForm();
  }

  proposalsForm: UntypedFormGroup;

  effectiveDate: Date;
  backdatingDays: string;
  postdatingDays: string;
  msgDateBack: string;
  msgDatePost: string;
  expireDate: Date;
  issueDate: Date;
  instalmentDatesList: Array<string>;
  minExpireDate: Date;
  maxExpireDate: Date;
  tacitRenewal = false;
  paymentFrequencyList: PaymentFrequency[];
  showAgreements = false;
  showUseTaxes = false;
  agreements: Array<Agreement>;
  useTaxes: Array<UseTax>;
  currencies: Array<Currency>;
  showCurrencies = false;
  showIndexation = true;
  indexTypes: Array<IndexType> = [];
  subscriptions: Subscription;
  binder: any;
  coInsuranceTypes: any;
  coInsuranceCompanies: any;
  coInsuranceIntermediaries: any;
  effectiveDateChangeSub: Subscription;
  expireDateChangeSub: Subscription;
  issueDateChangeSub: Subscription;
  instalmentDateChangeSub: Subscription;
  paymentFrequencyChangeSub: Subscription;
  tacitRenewalChangeSub: Subscription;
  agreementsChangeSub: Subscription;
  useTaxesChangeSub: Subscription;
  currenciesChangeSub: Subscription;
  indexationChangeSub: Subscription;
  binderChangeSub: Subscription;
  isFormSubmitted: boolean;
  isSubFirstTime = true;
  regulationFormValid = true;
  emptyIndexType = new IndexType(null);

  @Input() isDirectionalUser:boolean;
  @Input() technicalDetailsValid: boolean;
  @Input() effectDateValid = true;
  @Input() expireDateValid = true;
  @Input() editFunctionality: boolean;
  @Input() backFromStep: boolean;
  @Input() isToDisableFraz: boolean;
  @Input() isSubstitution: boolean;
  @Input() coInsurance: CoInsuranceDTO;

  @Output() coInsuranceEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() formChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() paymentFrequencyChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() automaticRenewalChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() agreementsChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() useTaxesChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() currencyChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValidEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() effectDateChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() instalmentDateChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() expireDateChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() regulationPremiumChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() indexationChangeEmitter: EventEmitter<any> = new EventEmitter<any>();


  protected unsubscribeAll() {
    this.subscriptions.unsubscribe();
    this.effectiveDateChangeSub.unsubscribe();
    this.expireDateChangeSub.unsubscribe();
    this.issueDateChangeSub.unsubscribe();
    this.instalmentDateChangeSub.unsubscribe();
    this.paymentFrequencyChangeSub.unsubscribe();
    this.tacitRenewalChangeSub.unsubscribe();
    this.agreementsChangeSub.unsubscribe();
    this.useTaxesChangeSub.unsubscribe();
    this.currenciesChangeSub.unsubscribe();
    this.indexationChangeSub.unsubscribe();
  }

  markFormAsDirtyAndTouched() {
    this.proposalsForm.markAsTouched();
    this.proposalsForm.markAllAsTouched();

  }

  protected initializeForm() {
    this.proposalsForm = this.formBuilder.group({
      effectiveDate: ['', [Validators.required]],
      expireDate: ['', [Validators.required, this.expireDateValidator.bind(this)]],
      issueDate: ['', Validators.required],
      instalmentDate: [{value: '', disabled: true}, Validators.required],
      paymentFrequency: ['', Validators.required],
      tacitRenewal: [],
      agreement: [],
      useTax: [],
      currencies: ['', Validators.required],
      binder: this.formBuilder.group({
        enabled: ['',],
        selected: ['',]
      }),
      indexation: ['']
    }, {
      validator: [
        this.dateValidatorUtilityService.dateComparisonValidator('effectiveDate', 'expireDate'),
        this.dateValidatorUtilityService.dateRangeValidator('expireDate', this.minExpireDate, this.maxExpireDate),
        //    this.dateValidatorUtilityService.formatDateValidator('effectiveDate', this.effectDateValid )
      ]
    });

    // this.proposalsForm.controls.effectiveDate.setErrors({ngbDate: null});
    // this.proposalsForm.controls.expireDate.disable();
  }

  expireDateValidator(control: AbstractControl): ValidationErrors | null {
    const dateStr = control.value;
    const expireDateValid = this.isDateValid(dateStr);
    if (!expireDateValid) {
    }
    return null;
  }


  ngOnInit() {
    // Subscribe to changes in the 'enabled' control
    this.proposalsForm.get('binder.enabled').valueChanges.subscribe((enabledValue) => {
      const selectedControl = this.proposalsForm.get('binder.selected');

      if (enabledValue === false) {
        // If 'enabled' is false, disable the 'selected' control
        selectedControl.disable();
      } else {
        // If 'enabled' is true, enable the 'selected' control
        selectedControl.enable();
      }
    });
  }

  subscribeToValueChanges(): Subscription {
    this.subscriptions = new Subscription();

    this.effectiveDateChangeSub = this.proposalsForm.get('effectiveDate').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(effectiveDate => {
      this.proposalsValuesChangedFunction({effectiveDate});
    });

    this.binderChangeSub = this.proposalsForm.get('binder').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(binder => {
      this.proposalsValuesChangedFunction({binder});
    });

    this.expireDateChangeSub = this.proposalsForm.get('expireDate').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(expireDate => {
      this.proposalsValuesChangedFunction({expireDate});
    });

    this.issueDateChangeSub = this.proposalsForm.get('issueDate').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(issueDate => {
      this.proposalsValuesChangedFunction({issueDate});
    });


    this.tacitRenewalChangeSub = this.proposalsForm.get('tacitRenewal').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(tacitRenewal => {
      this.automaticRenewalValuesChangedFunction({tacitRenewal});
    });

    if (this.proposalsForm.get('instalmentDate')) {
      this.instalmentDateChangeSub = this.proposalsForm.get('instalmentDate').valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(instalmentDate => {
        this.instalmentDateValueChangedFunction(instalmentDate);
      });
    }

    this.paymentFrequencyChangeSub = this.proposalsForm.get('paymentFrequency').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(paymentFrequency => {
      this.paymentFrequencyValueChangedFunction(paymentFrequency);
    });

    this.agreementsChangeSub = this.proposalsForm.get('agreement').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(agreement => {
      this.agreementsValueChangedFunction(agreement);
    });

    this.useTaxesChangeSub = this.proposalsForm.get('useTax').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(useTax => {
      this.useTaxesValueChangedFunction(useTax);
    });

    this.currenciesChangeSub = this.proposalsForm.get('currencies').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(currencies => {
      this.currenciesValueChangedFunction(currencies);
    });

    this.indexationChangeSub = this.proposalsForm.get('indexation').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(indexType => {
      this.indexationValueChangedFunction(indexType);
    });

    return this.subscriptions
      .add(this.effectiveDateChangeSub)
      .add(this.expireDateChangeSub)
      .add(this.issueDateChangeSub)
      .add(this.instalmentDateChangeSub)
      .add(this.paymentFrequencyChangeSub)
      .add(this.agreementsChangeSub)
      .add(this.useTaxesChangeSub)
      .add(this.currenciesChangeSub)
      .add(this.indexationChangeSub)
      .add(this.binderChangeSub);
  }

  instalmentDateValueChangedFunction(val) {
    this.validateForm();
    if (val.instalmentDate !== '' && val.instalmentDate !== null) {
      this.instalmentDateChangeEmitter.emit(val);
    }
  }

  paymentFrequencyValueChangedFunction(val) {
    if (val === '') {
      this.disableInstalmentDate();
    }
    this.validateForm();
    // TODO: MANDATORY DATA MISSING
    if (val.paymentFrequency !== '' && val.paymentFrequency !== null) {
      this.paymentFrequencyChangeEmitter.emit(val);
    }
    if (val.code === '000008' || val.code === '000007') {
      this.proposalsForm.controls.indexation.setValue(this.emptyIndexType);
      this.proposalsForm.controls.indexation.disable();
    } else {
      if (this.proposalsForm.controls.indexation.value === null) {
        this.proposalsForm.controls.indexation.setValue(this.emptyIndexType);
      }
      this.proposalsForm.controls.indexation.enable();

    }
  }

  disableInstalmentDate() {
    this.proposalsForm.controls.instalmentDate.setValue('');
    this.proposalsForm.controls.instalmentDate.disable();
  }

  automaticRenewalValuesChangedFunction(val) {
    this.validateForm();
    if (val) {
      this.automaticRenewalChangeEmitter.emit(val);
    }
  }

  agreementsValueChangedFunction(val) {
    this.validateForm();
    // TODO: MANDATORY DATA MISSING - code non può essere null (servizio fallisce)
    // if (val.agreement !== '' && val.agreement !== null) {
    this.agreementsChangeEmitter.emit(val);
    // }
  }

  useTaxesValueChangedFunction(val) {
    this.validateForm();
    // TODO: MANDATORY DATA MISSING - code non può essere null (servizio fallisce)
    // if (val.useTax !== '' && val.useTax !== null) {
    this.useTaxesChangeEmitter.emit(val);
    // }
  }

  currenciesValueChangedFunction(val) {
    this.validateForm();
    // TODO: MANDATORY DATA MISSING - code non può essere null (servizio fallisce)
    // if (val.agreement !== '' && val.agreement !== null) {
    this.currencyChangeEmitter.emit(val);
    // }
  }

  indexationValueChangedFunction(val) {
    this.validateForm();
    this.indexationChangeEmitter.emit(val);
  }

  proposalsValuesChangedFunction(val) {
    this.validateForm();
    this.validateEffectDate();
    this.validateExpireDate();
    this.formChangeEmitter.emit(val);
  }

  onRegulationPremiumChange(val) {
    this.validateForm();
    this.regulationPremiumChangeEmitter.emit(val);
  }

  onRegulationFormValidChange(val) {
    this.regulationFormValid = val;
  }

  initializeEffectiveDate(val: PolicyDate) {
    if (!val.modifiable) {
      this.proposalsForm.controls.effectiveDate.disable();
    }
    this.effectiveDate = new Date(this.ie11Utility.ie11FixDateString(val.date));
  }

  initializeIssueDate(val: PolicyDate) {
    this.issueDate = new Date(this.ie11Utility.ie11FixDateString(val.date));
  }

  initializeBinder(val: Binder) {
    this.binder = val;
    if (val) {
      this.proposalsForm.get('binder.selected').setValue(val.selected);
      this.proposalsForm.get('binder.enabled').setValue(val.enabled);
    }
  }

  initializeInstalmentDatesList(val: PolicyTechnicalData) {
    if (val && val.instalmentDates) {
      this.instalmentDatesList = val.instalmentDates.reverse();
      this.proposalsForm.controls.instalmentDate.enable();
      if (val.selectedInstalmentDate) {
        this.proposalsForm.controls.instalmentDate.setValue(val.selectedInstalmentDate);
      } else {
        this.proposalsForm.controls.instalmentDate.setValue(this.instalmentDatesList[0]);
        this.instalmentDateValueChangedFunction(this.instalmentDatesList[0]);
      }
    }
  }

  initializeExpireDate(val: PolicyDate) {
    // if (!val.modifiable) {
    //   this.proposalsForm.controls.expireDate.disable();
    // }
    this.expireDate = new Date(this.ie11Utility.ie11FixDateString(val.date));
    if (val.maxDate && val.minDate) {
      this.maxExpireDate = new Date(this.ie11Utility.ie11FixDateString(val.maxDate));
      this.maxExpireDate = new Date(this.ie11Utility.ie11FixDateString(val.minDate));
    }
  }

  initializePaymentFrequencyList(val: PaymentFrequencyContainer) {
    if(val){
      const paym: PaymentFrequency = val?.paymentFrequencyList?.find(value => {
        return value.selected;
      });
      if (this.isSubFirstTime && !paym) {
        // set as default annual paymentFrequency
        const pfdefaultAnnual = val.paymentFrequencyList.find(pf => pf.code === '000001');
        if (!!pfdefaultAnnual) {
          pfdefaultAnnual.selected = true;
          this.paymentFrequencyChangeEmitter.emit(pfdefaultAnnual);
        }
      }
      if (this.isSubstitution && paym.description.toLocaleLowerCase() === 'unico' && this.isSubFirstTime) {
        if (this.isToDisableFraz) {
          this.proposalsForm.get('paymentFrequency').disable();
          // this.paymentFrequencyForm.controls.paymentFrequency.disable();
        }
      }
      this.isSubFirstTime = false;
      if(this.isDirectionalUser){
        this.paymentFrequencyList = val.paymentFrequencyList;
      } else {
        this.paymentFrequencyList = val.paymentFrequencyList.filter(x=>{return x.derogationLevel === 0});
      }
    }
  }

  initializeAgreementsContainer(val: AgreementsContainer) {
    this.showAgreements = false;
    if (val?.agreements?.length) {
      this.agreements = val.agreements;
      this.showAgreements = true;
    }
  }

  initializeUseTaxesContainer(val: UseTaxesContainer) {
    this.showUseTaxes = false;
    if (val?.useTaxes?.length) {
      this.useTaxes = val.useTaxes;
      this.showUseTaxes = true;
    }
  }

  initializeCurrencies(val: PolicyTechnicalData) {
    if(val?.currencies){
      const selected = val.currencies.find(value => value.selected === true);
      const selectedCurrency = selected ? selected.code : val.defaultCurrency;
      this.showCurrencies = val.currencies.length > 1;

      if(!this.showCurrencies){
        // this.proposalsForm.controls.currencies.disable();
        const defaultCurrency = new Currency(val.defaultCurrency, val.defaultCurrency, '', true);
        this.proposalsForm.controls.currencies.setValue(defaultCurrency);
      }

      if (selectedCurrency && val.currencies && val.currencies.length > 0) {
        val.currencies.forEach(currency => {
          currency.selected = selectedCurrency === currency.code;
        });
      }
      this.currencies = val.currencies;
    }
  }

  initializeTacitRenewal(val: TacitRenewal) {
    if (val) {
      this.tacitRenewal = val.tacitRenewal;
      if (val?.optional) {
        this.proposalsForm.controls.tacitRenewal.enable();
      } else {
        this.proposalsForm.controls.tacitRenewal.disable();
      }
    }
  }

  initializeIndexation(val: PolicyTechnicalData, backFromStep: boolean) {
    if (val.indexTypes) {
      this.indexTypes = val.indexTypes;
      this.configureIndexationVisibility(val, backFromStep);
    }
  }

  private configureIndexationVisibility(state: PolicyTechnicalData, backFromStep: boolean) {
    if (state && state.indexationConfigPP != null) {
      switch (state.indexationConfigPP) {
        case 0:
          this.noIndexation();
          break;
        case 1:
          this.noOptionalIndexation();
          break;
        case 2:
          this.yesIndexation(state.indexType, state, backFromStep);
          break;
        case 3:
          this.yesOptionalIndexation(state.indexType, state);
          break;
      }
      this.setIndexationIfbackFromStep(state, backFromStep);
    }
  }

  private noIndexation() {
    this.showIndexation = false;
  }

  private noOptionalIndexation() {
    this.proposalsForm.controls.indexation.setValue(null);
  }

  private yesIndexation(indexType: IndexType, state: PolicyTechnicalData, backFromStep: boolean) {
    this.proposalsForm.controls.indexation.setValidators(Validators.required);
    if (indexType == null) {
      this.proposalsForm.controls.indexation.setValue(null);
    } else {
      if (backFromStep !== true) {
        this.proposalsForm.controls.indexation.disable();
        this.proposalsForm.controls.indexation.setValue(this.getIndexTypeByCode(state.indexTypes, state.indexType.code));
      }
    }
  }

  private yesOptionalIndexation(indexType: IndexType, state: PolicyTechnicalData) {
    if (indexType == null) {
      this.proposalsForm.controls.indexation.setValue(null);
    } else {
      this.proposalsForm.controls.indexation.setValue(this.getIndexTypeByCode(state.indexTypes, state.indexType.code));
    }
  }

  private setIndexationIfbackFromStep(state: PolicyTechnicalData, backFromStep: boolean) {
    if (state.indexType != null && backFromStep === true) {
      this.proposalsForm.controls.indexation.setValue(this.getIndexTypeByCode(state.indexTypes, state.indexType.code));
    }
  }

  private getIndexTypeByCode(list: Array<IndexType>, code: string) {
    const foundIndex = list.find(indexType => indexType.code === code);
    return foundIndex || (code === '' ? list.find(indexType => indexType.code === '1') : null);
  }

  setTechnicalDataValues() {
    if (this.effectDateValid && !isNaN(this.effectiveDate?.getTime())) {
      this.proposalsForm.controls.effectiveDate.setValue(this.effectiveDate);
    }
    if (this.expireDateValid && !isNaN(this.expireDate?.getTime())) {
      this.proposalsForm.controls.expireDate.setValue(this.expireDate);
    }
    if(!!this.issueDate){
      this.proposalsForm.controls.issueDate.setValue(this.issueDate);
      this.proposalsForm.controls.issueDate.disable();
    }
    this.proposalsForm.controls.tacitRenewal.setValue(this.tacitRenewal);

    this.paymentFrequencyList?.forEach(paymentFrequency => {
      if (paymentFrequency.selected) {
        this.proposalsForm.get('paymentFrequency').setValue(paymentFrequency);
        // this.paymentFrequencyForm.controls.paymentFrequency.setValue(paymentFrequency);
      }
    });
    if (this.agreements) {
      this.agreements.forEach(agreement => {
        if (agreement.selected) {
          this.proposalsForm.get('agreement').setValue(agreement);
          // this.agreementsForm.controls.agreement.setValue(agreement);
        }
      });
    }

    this.setUseTaxValue();

    // TODO: CREATE INTERFACE WITH BOOLEAN 'SELECTED'
    if (this.currencies) {
      this.currencies.forEach(currency => {
        if (currency.selected) {
          this.proposalsForm.get('currencies').setValue(currency);
        }
      });
    }

    this.subscriptions = this.subscribeToValueChanges();
    this.proposalsForm.updateValueAndValidity();
    this.validateForm();
    this.validateEffectDate();
    this.validateExpireDate();
  }

  private setUseTaxValue() {
    if(this.useTaxes){
      const useTax = this.useTaxes?.filter(useTax => useTax.selected);
      if (useTax.length === 0) {
        const tax = this.useTaxes.filter(useTax => {
          return useTax.code === '1';
        });
        // set default value
        this.proposalsForm.get('useTax').setValue(tax[0]);
      } else {
        this.proposalsForm.get('useTax').setValue(useTax[0]);
      }
    }
  }

  validateForm() {
    this.technicalDetailsValid = this.proposalsForm.valid && this.regulationFormValid;
    setTimeout(()=>{
      this.formValidEmitter.emit(this.technicalDetailsValid);
    });
  }

  notifybackDate() {
    const errorMsg = new RgiRxPushMessage();
    errorMsg.status = 'danger';
    errorMsg.dismissible = false;
    errorMsg.options = {icon: 'rgi-ui-icon-alert'};
    errorMsg.content = this.msgDateBack;
    this.errorService.notify(errorMsg);
  }

  notifypostDate() {
    const errorMsg = new RgiRxPushMessage();
    errorMsg.status = 'danger';
    errorMsg.dismissible = false;
    errorMsg.options = {icon: 'rgi-ui-icon-alert'};
    errorMsg.content = this.msgDatePost;
    this.errorService.notify(errorMsg);
  }

  validateEffectDate() {
    const dateStr = this.proposalsForm.controls.effectiveDate?.value;
    if(dateStr && this.backdatingDays && this.postdatingDays){
      const replaceDateBack = new Date(this.backdatingDays.replace('-', '/'));
      const replaceDatePost = new Date(this.postdatingDays.replace('-', '/'));
      this.effectDateValid = this.isDateValid(dateStr) && this.effectiveDate && dateStr >= replaceDateBack && dateStr < replaceDatePost;
      if (this.effectDateValid) {
        this.proposalsForm.controls.effectiveDate.setErrors(null);
      } else if (!this.isDateValid(dateStr) && !this.effectDateValid) {
        this.proposalsForm.controls.effectiveDate.setErrors({'RE_ISSUE.ERROR_FORMAT_DATE': true});
        this.proposalsForm.markAsDirty();
      } else if (dateStr < replaceDateBack) {
        this.notifybackDate();
      } else if (dateStr > replaceDatePost) {
        this.notifypostDate();
      }
    }
    // if(this.effectDateValid && this.controlDate(dateStr)){
    //   this.proposalsForm.controls.effectiveDate.setErrors(null);
    // }else if(dateStr < minDate){
    //   this.proposalsForm.controls.effectiveDate.setErrors({'RE_ISSUE.DATE_ERROR': true});
    //   this.proposalsForm.markAsDirty();
    // }else if(this.effectDateValid && this.controlDate(dateStr)){
    //   this.proposalsForm.controls.effectiveDate.setErrors(null);
    // }else{
    //   this.proposalsForm.controls.effectiveDate.setErrors({'RE_ISSUE.DATE_ERROR_360 ': true});
    //   this.proposalsForm.markAsDirty();
    // }

    this.effectDateChangeEmitter.emit(this.effectDateValid);
  }


  validateExpireDate() {
    const dateStr = this.proposalsForm.controls.expireDate.value;
    if(dateStr){
      this.expireDateValid = this.isDateValid(dateStr);
      if (!this.expireDateValid) {
        this.proposalsForm.controls.expireDate.setErrors({'RE_ISSUE.ERROR_FORMAT_DATE': true});
        this.proposalsForm.controls.expireDate.markAsDirty();
      }
      this.expireDateChangeEmitter.emit(this.expireDateValid);
    }
  }

  isDateValid(dateStr: string): boolean {
    if (dateStr !== null) {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateStrFormatted = ('0' + day.toString()).slice(-2) + '/' + ('0' + month.toString()).slice(-2) + '/' + year.toString();
      const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
      if (!dateRegex.test(dateStrFormatted)) {
        return false;
      }
      // const minDate = new Date('2014-01-01');
      // const maxDate = new Date('2030-12-31');
      // if (date < minDate || date > maxDate) {
      //  return false;
      // }
      return true;
    }
    return false;
  }


  isFrazUnique(tech: PolicyTechnicalData) {
    return tech.paymentFrequencyContainer.paymentFrequencyList.find(val => {
      return val.selected;
    }).description.toLocaleLowerCase() === 'unico';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.policyTechnicalData) {
      if (changes.policyTechnicalData.currentValue) {
        const expireDateOld = changes.policyTechnicalData.currentValue.expireDate;


        // const effectiveDateModified: PolicyDate = new PolicyDate(new Date().toDateString(), null, null, true, false);
        // if (this.editFunctionality && this.isFirstTime && !this.backFromStep) {
        //   this.isFirstTime = false;
        //   if (!this.isSubstitution) {
        //     this.initializeEffectiveDate(effectiveDateModified);
        //     // TODO: CHECK IF CORRECT
        //     // this.proposalsValuesChangedFunction({ effectiveDate: this.effectiveDate });
        //   } else {
        //     if (this.isFrazUnique(changes.policyTechnicalData.currentValue)) {
        //       if (new Date(this.ie11Utility.ie11FixDateString(effectiveDateModified.date)).getTime() >=
        //         new Date(this.ie11Utility.ie11FixDateString(changes.policyTechnicalData.currentValue.effectiveDate.date)).getTime()) {
        //         this.initializeEffectiveDate(effectiveDateModified);
        //         this.formChangeEmitter.emit({ effectiveDate: this.effectiveDate });
        //       } else {
        //         this.initializeEffectiveDate(changes.policyTechnicalData.currentValue.effectiveDate);
        //       }
        //     } else {
        //       this.initializeEffectiveDate(changes.policyTechnicalData.currentValue.effectiveDate);
        //     }
        //   }

        // } else {
        this.initializeEffectiveDate(changes.policyTechnicalData.currentValue.effectiveDate);
        this.initializeBinder(changes.policyTechnicalData.currentValue.binder);
        this.initializeIssueDate(changes.policyTechnicalData.currentValue.issueDate);
        // }
        // this.initializeBackDate(changes.policyTechnicalData.currentValue.backdatingDays);
        this.backdatingDays = changes.policyTechnicalData.currentValue.backdatingDays;
        this.postdatingDays = changes.policyTechnicalData.currentValue.postdatingDays;
        this.msgDateBack = changes.policyTechnicalData.currentValue.msgDateBack;
        this.msgDatePost = changes.policyTechnicalData.currentValue.msgDatePost;
        this.initializeExpireDate(changes.policyTechnicalData.currentValue.expireDate);
        this.initializePaymentFrequencyList(changes.policyTechnicalData.currentValue?.paymentFrequencyContainer);
        this.initializeInstalmentDatesList(changes.policyTechnicalData.currentValue);
        this.initializeTacitRenewal(changes.policyTechnicalData.currentValue?.tacitRenewal);
        this.initializeAgreementsContainer(changes.policyTechnicalData.currentValue?.agreementContainer);
        this.initializeUseTaxesContainer(changes.policyTechnicalData.currentValue?.useTaxContainer);
        this.initializeCurrencies(changes.policyTechnicalData.currentValue);
        this.initializeCoInsuranceData(changes.policyTechnicalData.currentValue);
        this.initializeIndexation(changes.policyTechnicalData.currentValue, changes.backFromStep ? changes.backFromStep.currentValue : false);
        this.setTechnicalDataValues();
      }
    }
  }

  initializeCoInsuranceData(val: PolicyTechnicalData) {
    this.coInsuranceTypes = val.coInsuranceTypes;
    this.coInsuranceCompanies = val.companies;
    this.coInsuranceIntermediaries = val.intermediaries;
  }

  emitCoInsurance(val: boolean) {
    this.coInsuranceEmitter.emit(val);
  }

}

import {PNC_PSALES_ACTIONS} from './actions';
import {PncPsalesButton} from '../model/common/button';

export const PNC_PSALES_BTN_CANCEL: PncPsalesButton = {
  action: PNC_PSALES_ACTIONS.CANCEL,
  color: 'secondary',
  disabled: false,
  label: '_PCPSALES_._BTN_._BACK_',
  visible: true
};

export const PNC_PSALES_BTN_BACK: PncPsalesButton = {
  action: PNC_PSALES_ACTIONS.BACK,
  color: 'secondary',
  disabled: false,
  label: '_PCPSALES_._BTN_._BACK_',
  visible: true
};

export const PNC_PSALES_BTN_CONTINUE: PncPsalesButton = {
  action: PNC_PSALES_ACTIONS.CONTINUE,
  color: 'primary',
  disabled: false,
  label: '_PCPSALES_._BTN_._CONTINUE_',
  visible: true
};

export const PNC_PSALES_BTN_END: PncPsalesButton = {
  action: PNC_PSALES_ACTIONS.END,
  color: 'secondary',
  disabled: false,
  label: '_PCPSALES_._BTN_._END_',
  visible: true
};

export function createDefaultButtonBar(additionalBtns?: PncPsalesButton[]): PncPsalesButton[] {
  additionalBtns = additionalBtns ? additionalBtns : [];
  return [PNC_PSALES_BTN_BACK, ...additionalBtns, PNC_PSALES_BTN_CONTINUE];
}

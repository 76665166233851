<div class="form-group row dynamic-row" [ngClass]="{'dynamic-checkbox': field.type === 'CHECKBOX'}">
    <label *ngIf="field.type === 'TEXT' || field.type === 'NUMERIC' || field.type === 'SELECT'"
        ng-bind="label">{{ field.label }}:</label>
    <pnc-required
        *ngIf="(field.type === 'TEXT' || field.type === 'NUMERIC' || field.type === 'SELECT') && field.mandatory && !field.disabled"
        required="true"></pnc-required>

    <input [ngClass]="{'invalid': field.invalid}" type="text" class="form-control" name="field.id"
        [disabled]="field.disabled || disableAll" attr.data-qa="dynamic-field-textarea-{{ field.id }}"
        [(ngModel)]="field.value" *ngIf="field.type === 'TEXT'">

    <input type="number" class="form-control" name="field.id" [disabled]="field.disabled || disableAll"
        attr.data-qa="dynamic-field-numericarea-{{ field.id }}" [(ngModel)]="field.value"
        *ngIf="field.type === 'NUMERIC'" min="0" step="{{step}}" [ngClass]="{'invalid': field.invalid}">

    <div *ngIf="field.type === 'CHECKBOX'" class="col-sm-12 col-xs-12 dynamic-checkbox-container">
        <input [ngClass]="{'invalid': field.invalid}" type="checkbox" class="form-control btn-checkbox checkbox-unit"
            name="field.id" [disabled]="field.disabled || disableAll"
            attr.data-qa="dynamic-field-checkbox-{{ field.id }}" [(ngModel)]="field.value">
        <label class="form-check-label dynamic-checkbox-label" ng-bind="label" for="{{field.id}}">
            {{ field.label }}</label>
    </div>

    <select [ngClass]="{'invalid': field.invalid}" class="core-select form-control" id="{{ field.id }}" name="field.id"
        [(ngModel)]="field.value" attr.data-qa="dynamic-field-select-{{ field.id }}"
        [disabled]="field.disabled || disableAll" *ngIf="field.type === 'SELECT'">
        <option></option>
        <option *ngFor="let value of field.combovalues; " value={{value[0]}}>
            {{value[1]}}</option>
    </select>
    <span *ngIf="field.type === 'SELECT'"
        class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>

    <pnc-datepicker [ngClass]="{'invalid': field.invalid}" *ngIf="field.type === 'DATE'" label="{{ field.label }}"
        customFormControlName="{{ field.id }}" [customFormGroup]="formGroup" [disabled]="field.disabled || disableAll"
        attr.data-qa="dynamic-field-data-{{ field.id }}" id="field.id">
        <pnc-required *ngIf="field.mandatory && !field.disabled" required="true"></pnc-required>
    </pnc-datepicker>

</div>
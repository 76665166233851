import {ComponentRef, Inject, Injectable, OnDestroy} from '@angular/core';
import {NoopScrollStrategy, Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {RgiRxPushOptions, RGI_RX_PUSH_OPTS, RgiRxPushMessage} from '@rgi/rx';
import {ComponentPortal} from '@angular/cdk/portal';
import {RgiRxSnackbarHostComponent} from './rgi-rx-snackbar-host/rgi-rx-snackbar-host.component';
import {Directionality} from '@angular/cdk/bidi';
import {RGI_RX_SNACKBAR_OVERLAY_CONFIG, RgiRxSnackbarOverlayConfig} from './rgi-rx-snackbar-api';
import {rgiRxGlobalOverlayConfigToCDKPosition} from '../overlay/overlay-fns';
import {Subscription} from 'rxjs';
import {rgiRxPushMessageFactory} from '@rgi/rx';

@Injectable()
/**
 * A service for creating Snackbar elements on the DOM using an overlay
 */
export class RgiRxSnackbarOverlay implements OnDestroy {

  private _overlayRef?: OverlayRef;
  private componentPortal: ComponentPortal<RgiRxSnackbarHostComponent>;
  private hostRef: ComponentRef<RgiRxSnackbarHostComponent>;

  private overlayDetachmentSubscription = Subscription.EMPTY;

  constructor(
    private overlay: Overlay,
    private direction: Directionality,
    @Inject(RGI_RX_SNACKBAR_OVERLAY_CONFIG) private config: RgiRxSnackbarOverlayConfig,
    @Inject(RGI_RX_PUSH_OPTS) private pushOptions: RgiRxPushOptions
  ) {
  }


  /**
   * Render pushMessages with the overlay.
   * If no overlay is available, it creates the overlay container
   * @param pushMessages messages to be rendered
   */
  notify(...pushMessages: RgiRxPushMessage[]) {
    if (!this._overlayRef || !this._overlayRef.hasAttached()) {
      this._overlayRef = this.overlay.create(this.createOverlayConfig(this.config));
      this.overlayDetachmentSubscription.unsubscribe();
      this.overlayDetachmentSubscription = this._overlayRef.detachments().subscribe(
        next => {
          this.componentPortal = undefined;
          this.hostRef.destroy();
        }
      );
    }
    if (!this.componentPortal) {
      this.componentPortal = new ComponentPortal(RgiRxSnackbarHostComponent);
      this.hostRef = this._overlayRef.attach(this.componentPortal);
    }

    this.hostRef.instance.add(
      ...pushMessages.map(msg => rgiRxPushMessageFactory(
          msg.content,
          msg.status,
          this.pushOptions,
          {
            detail: msg.detail,
            context: msg.context,
            tag: msg.tag,
            icon: msg.options ? msg.options.icon : undefined,
            expanded: msg.options ? msg.options.expanded : undefined,
            dismissible: msg.dismissible,
            delay: msg.delay
          }
        )
      )
    );
    this.hostRef.changeDetectorRef.detectChanges();
  }

  clear() {
    if (this.hostRef) {
      this.hostRef.instance.clear();
    }
  }

  private createOverlayConfig(config: RgiRxSnackbarOverlayConfig): OverlayConfig {
    return {
      scrollStrategy: new NoopScrollStrategy(),
      positionStrategy: rgiRxGlobalOverlayConfigToCDKPosition(config.positionStrategy, this.overlay),
      direction: this.direction.value,
      disposeOnNavigation: config.options.disposeOnNavigation,
      width: config.options.width,
      height: config.options.height,
      maxWidth: config.options.maxWidth,
      maxHeight: config.options.maxHeight,
      hasBackdrop: config.options.backDrop,
      backdropClass: config.options.backDropClass,
      panelClass: config.options.panelClass,
      minWidth: config.options.minWidth,
      minHeight: config.options.minHeight
    };
  }

  /**
   * Get the overlay ref if any
   */
  get overlayRef(): OverlayRef | undefined {
    return this._overlayRef;
  }

  /**
   * Dispose the overlay
   */
  dispose() {
    if (this.overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = undefined;
    }
  }

  ngOnDestroy(): void {
    this.overlayDetachmentSubscription.unsubscribe();
  }


}

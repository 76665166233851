import { Pipe, PipeTransform } from '@angular/core';
import { BeneficiaryContainer } from '../../../models/postsales-operations-response.model';


@Pipe({
  name: 'benefsummary'
})
export class BenefsummaryPipe implements PipeTransform {

  transform(benef: BeneficiaryContainer): string {
    let benefValue = benef.benefType + ' ';
    if (!!benef.benefName ) {
      if (!!benef.benefPositionNumberLabel) {
        benefValue += benef.benefPositionNumberLabel + ': ';
      }
      benefValue += benef.benefName + ' ';
    }
    if (!!benef.benefLabel && !!!benef.benefName ) {
      benefValue += benef.benefLabel + ' ';
    }
    if (!!benef.benefPercentage ) {
      benefValue += benef.benefPercentage + '%';
    }
    return benefValue;
  }

}

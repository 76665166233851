import { ContentChild, Directive, ElementRef, HostListener, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';

/* Example
<div lpcCustomTooltipDirective>Some text
  <ng-template #tooltipTemplate>
      <div class='tooltipCustom'>
          Some text
      </div>
  </ng-template>
</div>
*/

/**
 * @author dmasone
 */
@Directive({ selector: '[lpcCustomTooltipDirective]' })
export class CustomTooltipDirective {

  constructor(
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private viewContainerRef: ViewContainerRef) { }

  @ContentChild('tooltipTemplate') private tooltipTemplateRef: TemplateRef<any>;

  @HostListener('mouseenter')  onMouseEnter(): void {
    const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
    view.rootNodes.forEach(node =>
      this.renderer.appendChild(this.elementRef.nativeElement, node));
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }

  @HostListener('click') onClick(): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }

}

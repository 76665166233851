    import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {RgiRxSemanticStatus} from '@rgi/rx';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Subject, Subscription} from 'rxjs';


@Directive({
  selector: 'button[rgi-rx-button],button[rgiRxButton],a[rgi-rx-button],a[rgiRxButton]',
})
export class RgiRxButtonDirective implements OnInit, OnDestroy {

  private _color: RgiRxSemanticStatus | string = 'default';
  private _disabled: boolean;
  private _variant?: | 'dashed' | 'rounded' | 'icon' | string;
  private classList: string;
  private _changes = new Subject<void>();
  private _changeSubscription: Subscription = Subscription.EMPTY;


  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this._changeSubscription = this._changes.subscribe(
      next => {
        this.classList = this.computeClassList();
      }
    );
    this._changes.next();
  }

  ngOnDestroy(): void {
    this._changeSubscription.unsubscribe();
  }


  @HostBinding('class') get elementClasses() {
    return this.classList;
  }

  @Input() get color(): RgiRxSemanticStatus | string {
    return `${this._color}`;
  }

  set color(color: RgiRxSemanticStatus | string) {
    this._color = color;
    this._changes.next();
  }

  @Input() get disabled(): boolean {
    return this._elementRef.nativeElement.disabled;
  }

  set disabled(value: boolean) {
    const disabledValue = coerceBooleanProperty(value);
    const isButtonElement = this._elementRef.nativeElement instanceof HTMLButtonElement;
    if (isButtonElement) {
      if (disabledValue) {
        this.renderer.setAttribute(this._elementRef.nativeElement, 'disabled', 'true');
        return;
      }
      this.renderer.removeAttribute(this._elementRef.nativeElement, 'disabled');
      return;
    }
    this.renderer.setAttribute(this._elementRef.nativeElement, 'tabindex', disabledValue ? '-1' : '0');
    this.renderer.setStyle(this._elementRef.nativeElement, 'pointerEvents', disabledValue ? 'none' : 'auto');
    if (disabledValue) {
      this.renderer.addClass(this._elementRef.nativeElement, 'rgi-ui-disabled');
    } else {
      this.renderer.removeClass(this._elementRef.nativeElement, 'rgi-ui-disabled');
    }

  }


  @Input() get variant(): 'dashed' | 'rounded' | 'icon' | string {
    return this._variant;
  }

  set variant(value: 'dashed' | 'rounded' | 'icon' | string) {
    this._variant = value;
    this._changes.next();
  }

  private getElementClassList(): string[] {
    if (!this._elementRef) {
      return [];
    }
    const classList = this._elementRef.nativeElement.classList as DOMTokenList;
    const classes = [];
    classList.forEach(value => {
      if (!value.includes('rgi-ui-btn')) {
        classes.push(value);
      }
    });
    return classes;
  }

  private computeClassList() {
    const classes = ['rgi-ui-btn'];
    if (!!this._color) {
      classes.push(`rgi-ui-btn-${this._color}`);
    }
    if (!!this._variant) {
      classes.push(`rgi-ui-btn-${this._variant}`);
    }
    if (this.getElementClassList().length > 0) {
      classes.push(...this.getElementClassList());
    }
    return classes.join(' ');
  }
}


/*
 * Public API Surface of ng-passpropro-product
 */
export * from './ng-passpropro-product.module';
export * from './resources/product-resources.module';
export * from './resources/product-source.service';
export * from './resources/product.service';
export * from './model/source';
export * from './model/product';

export * from './product-api';
export * from "./ajs-integration/ng-passpropro-product-ajs.module";
export * from "./ajs-integration/ajs-configuration";

import { Injectable, Inject } from '@angular/core';
import {RgiRxHttpClientFactory} from "@rgi/rx/http";
import {RgiRxUserService} from "@rgi/rx/auth";
import { AuthorizationsSearchService } from '@rgi/authorizations-card';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationsSearchExtService extends AuthorizationsSearchService{

    constructor(
        protected httpClientFactory: RgiRxHttpClientFactory,
        protected userService: RgiRxUserService,
        @Inject('environment') environment: any,
        protected translate: RgiRxTranslationService
    ) {
        super(httpClientFactory,userService,environment,translate);
    }

    override getFilterValues() {
        return this.httpClient.get<any>(this.authorizationsEndpoint + '/filter').pipe(
            map(filter => {
                filter.requestStatusList = filter.requestStatusList.filter(item => item.code !== '90000');
                return filter;
            })
        );
    }

    getAuthForList(auth: any, isSubcard: boolean) {
        let stateDescr = auth.state.description;
        if (!isSubcard) {
            stateDescr +=  ' (' + auth.workflowState.description + ')'
        }
        return {
            authId: auth.id,
            authCode: auth.requestCode,
            authRequestType: auth.requestType.description,
            authContractType: auth.policyType ? auth.policyType.description : null,
            authOperation: auth.operation,
            authContractNumber: auth.policyNumber ? auth.policyNumber : auth.proposalNumber,
            authDescription: this.evaluteDescription(auth),
            authReference: this.evaluateReference(auth),
            authLastEvent: auth.lastEvent.description,
            authRequestDate: auth.requestDate,
            authNodeCode: auth.requestNode.code,
            authStatus: stateDescr,
            authOrigin: auth.origin
        };
    }

}

<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels"></re-issue-stepper>

<div class="rgi-re-container" *ngIf="stateManager.getState$() | async as policyDataState">

    <re-issue-general-data [productName]="policyDataState.productName"
                           [anagSubject]="policyDataState.contractorSubject"
                           [anagCoOwners]="policyDataState.coOwners"
                           [nodeId]="policyDataState.nodeId"
                           [nodeObject]="policyDataState.node" [formSubmitted]="formSubmitted"
                           [nodeName]="policyDataState.nodeName"
                           [productId]="policyDataState.productId"
                           [coOwnerEnabled]="policyDataState.policyTechnicalData?.coOwnerEnabled"
                           [thirdPersonContact]="policyDataState.thirdPersonContact"
                           [stateObs]="stateManager.getState$()"
                           (subjectSelectedEmitter)="onSubjectSelected($event)"
                           (coOwnerSelectedEmitter)="onCoOwnerSelected($event)"
                           (coOwnerDeletedEmitter)="onCoOwnerDeleted($event)"
                           (formValidEmitter)="onGeneralDataValidForm($event)"
                           (contactsDataEmitter)="onContactsDataChanges($event)">
    </re-issue-general-data>

    <re-issue-technical-details #technicalDetailsComponent
                                (formChangeEmitter)="onTechnicalDetailsFormChange($event)"
                                (formValidEmitter)="onTechnicalDetailsValidForm($event)"
                                (effectDateChangeEmitter)="onEffectDateValidForm($event)"
                                (instalmentDateChangeEmitter)="onInstalmentDateChange($event)"
                                (expireDateChangeEmitter)="onExpireDateValidForm($event)"
                                (paymentFrequencyChangeEmitter)="onPaymentFrequencyChange($event)"
                                (automaticRenewalChangeEmitter)="onAutomaticRenewalChange($event)"
                                (agreementsChangeEmitter)="onAgreementsChange($event)"
                                (useTaxesChangeEmitter)="onUseTaxesChange($event)"
                                (currencyChangeEmitter)="onCurrencyChange($event)"
                                (indexationChangeEmitter)="onIndexationChange($event)"
                                (regulationPremiumChangeEmitter)="onRegulationPremiumChange($event)"
                                (coInsuranceEmitter)="putCoInsurance($event)"
                                [policyTechnicalData]="policyDataState.policyTechnicalData"
                                [technicalDetailsValid]="technicalDetailsValid"
                                [formSubmitted]="formSubmitted" [editFunctionality]="policyDataState.editFunctionality"
                                [backFromStep]="policyDataState.backFromStep"
                                [isSubstitution]="policyDataState.isSubstitution"
                                [isToDisableFraz]="policyDataState.isToDisableFraz"
                                [coInsurance]="policyDataState.coInsurance"
                                [isDirectionalUser]="isDirectionalUser">
    </re-issue-technical-details>

    <re-issue-proposal-regulation *ngIf="policyDataState.policyTechnicalData?.premiumAdjustmentConfig && policyDataState.policyTechnicalData?.premiumAdjustmentConfig != HIDDEN"
                                  [paymentFrequencyList]="policyDataState.policyTechnicalData?.paymentFrequencyContainer?.paymentFrequencyList"
                                  [premiumAdjustmentConfig]="policyDataState.policyTechnicalData?.premiumAdjustmentConfig"
                                  [regulationPremiumResponse]="policyDataState.policyTechnicalData?.regulationPremiumResponse"
                                  [stateObs]="stateManager.getState$()"
                                  (regulationPremiumChange)="onRegulationPremiumChange($event)"
                                  (regulationFormValid)="onRegulationFormValidChange($event)"
                                  (checkboxEmitter)="proposalRegulationCheckboxChange($event)" #proposalRegulation>
    </re-issue-proposal-regulation>

    <re-issue-generic-accordion *ngIf="policyDataState.policyTechnicalData?.policyRiskCommon != HIDDEN"
                                [type]="'COMMON_RISK_POLICIES'"
                                [subTitle]="'COMMON_RISK_POLICIES_LIST'"
                                [tableData]="getAdditionalTechnicalData()?.commonRiskPolicies"
                                [checkboxConfig]="policyDataState.policyTechnicalData?.policyRiskCommon"
                                [isChecked]="getAdditionalTechnicalData()?.commonRiskPoliciesChecked"
                                [stateObs]="stateManager.getState$()"
                                (searchPolicy)="searchCommonRiskPolicy($event)"
                                (deletePolicy)="deleteCommonRiskPolicy($event)"
                                (checkboxEmitter)="commonRiskCheckboxChange($event)" #commonRiskPolicies>
    </re-issue-generic-accordion>

    <re-issue-generic-accordion *ngIf="policyDataState.policyTechnicalData?.managerOfRelatedPolicies != HIDDEN"
                                [type]="'LINKED_POLICIES'"
                                [subTitle]="'LINKED_POLICIES_LIST'"
                                [tableData]="getAdditionalTechnicalData()?.linkedPolicies"
                                [checkboxConfig]="policyDataState.policyTechnicalData?.managerOfRelatedPolicies"
                                [isChecked]="getAdditionalTechnicalData()?.linkedPoliciesChecked"
                                [stateObs]="stateManager.getState$()"
                                (searchPolicy)="searchLinkedPolicy($event)"
                                (deletePolicy)="deleteLinkedPolicy($event)"
                                (checkboxEmitter)="linkedPoliciesCheckboxChange($event)" #linkedPolicies>
    </re-issue-generic-accordion>

    <re-issue-generic-accordion  *ngIf="policyDataState.policyTechnicalData?.complementaryPolicies != HIDDEN"
                                [type]="'COMPLEMENTARY_POLICIES'"
                                [subTitle]="'COMPLEMENTARY_POLICY_DATA'"
                                [tableData]="getAdditionalTechnicalData()?.complementaryPolicies"
                                [checkboxConfig]="policyDataState.policyTechnicalData?.complementaryPolicies"
                                [isChecked]="getAdditionalTechnicalData()?.complementaryPoliciesChecked"
                                [disableSearchButton]="getAdditionalTechnicalData()?.isComplementaryPoliciesSet"
                                 [stateObs]="stateManager.getState$()"
                                 (searchPolicy)="searchComplementaryPolicy($event)"
                                (deletePolicy)="deleteComplementaryPolicy($event)"
                                (checkboxEmitter)="complementaryPoliciesCheckboxChange($event)" #complementaryPolicies>
    </re-issue-generic-accordion>

    <re-issue-generic-accordion-with-modal *ngIf="policyDataState.policyTechnicalData?.indirectCoInsuranceConfig !== HIDDEN"
                                           [type]="'INDIRECT_CO_INSURANCE_POLICIES'"
                                           [tableData]="getAdditionalTechnicalData()?.indirectCoinsurancePolicies"
                                           [companies]="policyDataState.policyTechnicalData?.companies"
                                           [checkboxConfig]="policyDataState.policyTechnicalData?.indirectCoInsuranceConfig"
                                           [isChecked]="getAdditionalTechnicalData()?.indirectCoinsuranceChecked"
                                           (searchPolicy)="putIndirectCoInsurance($event)"
                                           (deletePolicy)="deleteIndirectCoInsurance($event)"
                                           (updatePropertiesForm)="updatePropertiesIndirectCoInsuranceForm($event)"
                                           (deleteAllPolicies)="deleteAllIndirectCoInsurancePolicies()">
    </re-issue-generic-accordion-with-modal>

    <re-issue-generic-accordion-with-modal *ngIf="policyDataState.policyTechnicalData?.policySecondRisk !== HIDDEN"
                                             [type]="'SECOND_RISK_POLICIES'"
                                           [tableData]="getAdditionalTechnicalData()?.secondRiskPolicies"
                                           [companies]="policyDataState.policyTechnicalData?.companies"
                                           [checkboxConfig]="policyDataState.policyTechnicalData?.policySecondRisk"
                                           [isChecked]="getAdditionalTechnicalData()?.secondRiskPoliciesChecked"
                                           (searchPolicy)="putSecondRiskPolicy($event)"
                                           (deletePolicy)="deleteSecondRiskPolicy($event)"
                                           (updatePropertiesForm)="updatePropertiesSecondRiskForm($event)"
                                           (deleteAllPolicies)="deleteAllSecondRiskPolicies()">
    </re-issue-generic-accordion-with-modal>


    <div class="rgi-re-section-title" *ngIf="policyDataState.variables?.length">
      <span class="rgi-ui-icon-list-bars rgi-re-header-icon"></span>
      <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.OTHER_POLICY_DATA</span>
    </div>

    <!-- TODO : TAG => data-qa -->
    <re-issue-variables [variables]="policyDataState.variables"
                        (variablesFormChangeEmitter)="onVariablesFormChange($event)"
                        (validFormVariables)="onValidFormVariables($event)" [variablesValid]="variablesValid"
                        [isSubmitted]="formSubmitted">
    </re-issue-variables>

    <!-- TODO : TAG => data-qa -->
    <div *ngIf="policyDataState.clauses?.length>0">

      <re-issue-clauses [showTitle]="true"
                        [clausesList]="policyDataState.clauses"
                        (updateClause)="updateClause($event)"></re-issue-clauses>
    </div>

    <div *ngIf="policyDataState.policyTechnicalData?.lpsCountries && policyDataState.lpsData">
      <re-issue-lps [euCountriesList]="policyDataState.policyTechnicalData.lpsCountries"
                    [lpsData]="policyDataState.lpsData"
                    [isDirectionalUser]="isDirectionalUser"
                    (lpsDataOutput)="updateLpsDataOutput($event)">
      </re-issue-lps>
    </div>

    <!-- TODO : TAG => data-qa -->
    <div *ngFor="let questionnaire of policyDataState.questionnaireList">
      <re-issue-questionnaires [questionnaireComplete]="questionnaire.complete"
                               [questionnaireAdequate]="questionnaire.adequate" [questionnaireValid]="true"
                               [questionnaireSubmitted]="questionnaireSubmitted"
                               [questionnaire]="questionnaire"
                               (questionnaireValidChange)="onQuestionnaireValidChange($event)"
                               (saving)="onQuestionnaireSubmit($event)">
      </re-issue-questionnaires>
    </div>


  <div class="rgi-ui-grid">
      <div class="rgi-ui-col">
        <rgi-rx-snackbar tag="{{TAG_FORM_ERRORS}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_INSURED_REQUIRED_ERROR}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_CTR_ERRORS}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_DATE_ERROR}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_COINSURANCE_ERROR}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_REGULATION_ERROR}}"></rgi-rx-snackbar>
        <rgi-rx-snackbar tag="{{TAG_ACCORDION_ERROR}}"></rgi-rx-snackbar>
      </div>
  </div>

  <div class="rgi-ui-grid-2 rgi-ui-grid-no-gutter">
    <!-- SAVE -->
    <div class="rgi-ui-col btn-group" *ngIf="bIntermediateSaving">
      <button class="rgi-re-button" rgi-rx-button color="secondary" [disabled]="isSaveDisabled"
              (click)="saveProposal(false)" data-qa="save_proposal"
              translate>RE_ISSUE.SAVE
      </button>
      </div>
    <!-- FIND -->
    <div class="rgi-ui-col btn-group">
      <button class="rgi-re-button" rgi-rx-button color="primary"
              (click)="submitForm()"
              data-qa="Continue"
              [disabled]="(!technicalDetailsValid || !variablesValid || !generalDataValid) && formSubmitted"
              translate>RE_ISSUE.NEXT
      </button>
      </div>
    </div>
</div>

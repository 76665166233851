<div *ngIf="lpsData.policyLpsData.lpsUse !== '0'">
  <div class="rgi-re-section-title">
    <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.LPS</span>&nbsp;
  </div>

  <form [formGroup]="lpsForm">
    <div class="rgi-ui-grid-2">
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel for="euCountry" translate>RE_ISSUE.EU_COUNTRY</label>
          <select rgiRxNativeSelect id="euCountry" formControlName="euCountry">
            <option value=""></option>
            <option *ngFor="let euCountry of euCountriesList"
                    [ngValue]="euCountry.identification">{{euCountry.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
    </div>

    <div class="rgi-ui-grid-2" *ngIf="isDirectionalUser">
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel for="extraEuCountry" translate>RE_ISSUE.EXTRA_EU_COUNTRY</label>
          <input rgiRxInput id="extraEuCountry" formControlName="extraEuCountry">
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel for="currency" translate>RE_ISSUE.CURRENCY</label>
          <input rgiRxInput id="currency" formControlName="currency">
        </rgi-rx-form-field>
      </div>

      <div class="rgi-ui-col">
        <rgi-rx-form-field class="change-date-field">
          <div class="label-input-container">
            <label rgiRxLabel for="changeDate" translate>RE_ISSUE.CHANGE_DATE</label>
            <div id="changeDate" class="input-button-container">
              <input [rgiRxDateTimeInput]="changeDatePicker"
                     formControlName="changeDate"
                     data-qa="changeDate"
                     rangeSeparator="/"
                     placeholder="gg/mm/yyyy"
                     style="flex: 1"
                     #inputToTriggerChangeDate="rgiRxDateTimeInput">

              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerChangeDate"></button>
            </div>
          </div>


          <rgi-rx-date-time #changeDatePicker
                            pickerType="calendar"
                            [hour12Timer]="true">
          </rgi-rx-date-time>
        </rgi-rx-form-field>

      </div>
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel for="exchangeRate" translate>RE_ISSUE.EXCHANGE_RATE</label>
          <input id="exchangeRate" type="number" rgiRxInput formControlName="exchangeRate">
        </rgi-rx-form-field>
      </div>
    </div>

  </form>

</div>


import { Injectable } from '@angular/core';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import { OutputOccurrenceData, UpdatePolicy } from './occurrence-config.model';
import { map } from 'rxjs/operators';
import { ActiveRoute } from '@rgi/rx/router';
import { OccurrenceDataModifyService } from './occurrence-data-modify.service';
import { Observable, Subject, of } from 'rxjs';
import { AdditionalData } from '../../shared/dsc-additional-data/dsc-additional-data.model';
import { IncidentData } from '../../shared/dsc-incident-data/dsc-incident-data.model';
import {
    CircumstanceIncident,
    OutputLiabilityDetermination
} from '../../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import { AddressEntityData } from '../../shared/dsc-shared-model/dsc-model-address';
import { InjuryCode } from '../../shared/dsc-shared-model/dsc-model-injury';
import { Outcome } from '../../shared/dsc-shared-model/dsc-model-utility';

@Injectable()
/*
il mio state lo estendo con AbstractStateManager in modo da avere i metodi per salvare nella cache lo stato
lo state manager gestirà ogni iterazione con i miei component e chiamerà il service ove necessario (api rest)
*/
export class OccurrenceDataModifyState extends AbstractStateManager<OutputOccurrenceData> {

    constructor(
        activeRoute: ActiveRoute, stateStoreService: StateStoreService,
        public occurrenceStateService: OccurrenceDataModifyService
    ) {
        /*
        richiamo activeRoute e stateStoreService dall'AbstractStateManager
        poi controllo se tramite la chiave dello store è presente la lo stato, se non lo è ne creo uno nuovo
        basato sull'interfaccia OutputOccurrenceData che estende lo stato
        */
        super(activeRoute, stateStoreService);
        const st = !!stateStoreService.get<OutputOccurrenceData>(this.storeKey)
            ? stateStoreService.get<OutputOccurrenceData>(this.activeRoute.key)
            : new OutputOccurrenceData(this.storeKey); // get existing state or create a new one

        /*
        nel costruttore vado a prendere dal route i dati passati da angular js durante l'apertura.
        se vengono passate allora aggiorno lo stato tramite il metodo di AbstractStateManager updateState$
        passandogli un observable (che mi ritorna dal servizio e la chiave generata dalla sessione)
        altrimenti genero un nuovo state con oggetto vuoto
        */

        if (this.activeRoute.getRouteData<any>().claim) {
            this.updateState$(this.occurrenceStateService.getDetailClaims$(of(st), this.activeRoute.getRouteData<any>().claim));
        } else {
            this.updateState$(of(st));
        }
    }

    resetFormSubject: Subject<boolean> = new Subject<boolean>();

    getSession(): any {
        return this.activeRoute.getRouteData<any>();
    }

    clearState() {
        const clearedState$ = this.occurrenceStateService.clearState$(of(this.getCurrentState()));
        this.updateState$(clearedState$);
        this.verifyConditionSave();
    }

    updateIncidentState(incidentData: IncidentData) {
        if (incidentData.isOccurrenceDateChanged) {
            // Se la data denuncia è valorizzata e viene svuotata
            this.resetValueFormOnChangeOccurrenceData();
        } else if (incidentData.isOccurrenceDateFromEmptyToValued) {
            // Se la data denuncia era vuota e viene popolata
            this.resetValueFormIsOccurrenceDateFromEmptyToValued();
        } else {
            this.updateState$(this.occurrenceStateService.updateIncidentDate$(incidentData, of(this.getCurrentState())));
        }
        this.verifyConditionSave();
    }

    updateAddressState(addressData: AddressEntityData) {
        this.updateState$(this.occurrenceStateService.updateAddressEntity$(addressData, of(this.getCurrentState())));
        this.verifyConditionSave();
    }

    updateInjuryCode(injuryCode: InjuryCode) {
        this.updateState$(this.occurrenceStateService.updateInjuryCode$(injuryCode, of(this.getCurrentState())));
        this.verifyConditionSave();
    }

    updateAdditionalData(additionalData: AdditionalData) {
        this.updateState$(this.occurrenceStateService.updateAdditionalData$(additionalData, of(this.getCurrentState())));
        this.verifyConditionSave();
    }

    updateCircumstanceIncident(circumstance: CircumstanceIncident) {
        this.updateState$(this.occurrenceStateService.updateCircumstance$(circumstance, of(this.getCurrentState())));
        this.verifyConditionSave();
    }

    verifyConditionSave() {
      const stateDataModify = this.getCurrentState();
      const validationInjury = stateDataModify.injuryCode ? stateDataModify.injuryCode.isValidForm : true;
      const validationCircumstance = stateDataModify.circumstanceIncident ? stateDataModify.circumstanceIncident.isValidForm : true;
      const allFormAreValid = stateDataModify.additionalData.isValidForm &&
        stateDataModify.addressEntity.isValidForm &&
        stateDataModify.incidentData.isValidForm &&
        validationCircumstance &&
        validationInjury;
      if (allFormAreValid) {
        stateDataModify.isEnabledButtonSave = true;
        this.updateState$(this.occurrenceStateService.updateAllState$(stateDataModify, of(this.getCurrentState())));
      } else {
        stateDataModify.isEnabledButtonSave = false;
        this.updateState$(this.occurrenceStateService.updateAllState$(stateDataModify, of(this.getCurrentState())));
      }
    }

    saveOccurrenceData(occurrenceData: OutputOccurrenceData): Observable<Outcome> {
        const claimNumber = occurrenceData.parameterInputData.claimNumber;
        const updateOccurenceData$ = claimNumber ?
            this.occurrenceStateService.updateIncidentDataClaim$(occurrenceData) :
            this.occurrenceStateService.updateIncidentDataPotentialClaim$(occurrenceData);
        return updateOccurenceData$;
    }

    // Se la data denuncia assicurato è valorizzata e viene svuotata
    resetValueFormOnChangeOccurrenceData() {
        const currState = this.getCurrentState();

        if(currState.circumstanceIncident) {
            currState.circumstanceIncident.isOccurrenceDateChanged = true;
            currState.circumstanceIncident.isOccurrenceDateFromEmptyToValued = false;

            // Reset Barem A non obbligatorio
            currState.circumstanceIncident.baremEntityARequired = false;

            // Reset Barem B non obbligatorio
            currState.circumstanceIncident.baremEntityBRequired = false;

            this.calculateLiabilityState(currState.circumstanceIncident);

            // Ragione / Torto definita da Sistema protetto
            currState.circumstanceIncident.liabilityBySystemEnabled = false;

            // Tipo responsabilità protetto
            currState.circumstanceIncident.liabilityTypeEnabled = false;

            // Percentuale responsabilità assicurato protetto se flag civilistica non selezionato altrimenti deve essere abilitato
            currState.circumstanceIncident.percInsuredLiabilityEnabled = currState.circumstanceIncident.forcingTechnicalResponsibility;
            currState.circumstanceIncident.percInsuredLiability = (100 - currState.circumstanceIncident.percOtherPartyLiability);

            // Percentuale responsabilità controparte protetto
            currState.circumstanceIncident.percOtherPartyLiabilityEnabled = false;

            // "Responsabilità dichiarata" vuota e protetta
            currState.circumstanceIncident.liabilityDeclared = '';
            currState.circumstanceIncident.liabilityDeclaredEnabled = false;
            currState.circumstanceIncident.liabilityDeclaredRequired = false;

            // "Applica forzatura percentuale responsabilità (civilistica)" abilitato e selezionabile
            currState.circumstanceIncident.forcingTechnicalResponsibilityEnabled = true;

            // Reset "Applica forzatura responsabilità dichiarata ad Ania" (vuota e protetta)
            currState.circumstanceIncident.forcingAniaResponsibility = false;
            currState.circumstanceIncident.forcingAniaResponsibilityEnabled = false;
            currState.circumstanceIncident.forcingAniaResponsibilityRequired = false;
            currState.circumstanceIncident.aniaNotes = null;
            currState.circumstanceIncident.reasonToForceLiability = '';
            currState.circumstanceIncident.forcedLiability = '';
            currState.circumstanceIncident.comboForcedLiabilityEnabled = false;

            this.updateState$(this.occurrenceStateService.updateAllState$(currState, of(this.getCurrentState())));
            this.resetFormSubject.next(true);
            this.verifyConditionSave();
        }
    }

    // Se la data denuncia assicurato era vuota e viene valorizzata
    resetValueFormIsOccurrenceDateFromEmptyToValued() {
        const currState = this.getCurrentState();

        currState.circumstanceIncident.isOccurrenceDateChanged = false;
        currState.circumstanceIncident.isOccurrenceDateFromEmptyToValued = true;

        this.calculateLiabilityState(currState.circumstanceIncident);

        // Reset Barem A
        currState.circumstanceIncident.baremEntityARequired = true;

        // Reset Barem B
        currState.circumstanceIncident.baremEntityBRequired = true;

        // Reset "Percentuale responsabilità assicurato"
        currState.circumstanceIncident.percInsuredLiabilityEnabled = false;
        currState.circumstanceIncident.percInsuredLiabilityRequired = true;

        // Reset "Percentuale responsabilità controparte"
        currState.circumstanceIncident.percOtherPartyLiabilityEnabled = false;
        currState.circumstanceIncident.percOtherPartyLiabilityRequired = true;

        // Reset "Responsabilità dichiarata"
        currState.circumstanceIncident.liabilityDeclaredEnabled = false;
        currState.circumstanceIncident.liabilityDeclaredRequired = true;

        // "Applica forzatura percentuale responsabilità (civilistica)" abilitato e selezionabile
        currState.circumstanceIncident.forcingTechnicalResponsibilityEnabled = true;

        // Reset "Applica forzatura responsabilità dichiarata ad Ania" (vuota e protetta)
        currState.circumstanceIncident.forcingAniaResponsibilityEnabled = true;

        // Ragione / Torto definita da Sistema protetto
        currState.circumstanceIncident.liabilityBySystemEnabled = false;

        // Tipo responsabilità protetto
        currState.circumstanceIncident.liabilityTypeEnabled = false;

        this.updateState$(this.occurrenceStateService.updateAllState$(currState, of(this.getCurrentState())));
        this.resetFormSubject.next(true);
        this.verifyConditionSave();
    }

    saveIncidentDataClaim() {
        let currentState = this.getCurrentState();
        this.updateState$(this.occurrenceStateService.saveIncidentDataClaim$(of(currentState)));
        // return this.saveOccurrenceData(currentState).pipe(map((resp: Outcome) => {
        //     return resp;
        // }));
    }

    alignPolicy(): Observable<any> {
        let currentState = this.getCurrentState();
        currentState = this.updateTimerValueIncident(currentState);
        let updatePolicy: UpdatePolicy = new UpdatePolicy();
        updatePolicy.claimNumber = currentState.generalData.claimNumber ? currentState.generalData.claimNumber : null;
        updatePolicy.potentialClaimNumber = currentState.generalData.potentialClaimNumber ? currentState.generalData.potentialClaimNumber : null;
        updatePolicy.occurenceDate = currentState.generalData.occurrenceDate ? currentState.generalData.occurrenceDate : null;
        updatePolicy.certificatedOccurenceDate = currentState.generalData.occurrenceDateCertified ?  currentState.generalData.occurrenceDateCertified : null;
        return this.occurrenceStateService.updatePolicy$(updatePolicy).pipe(map((resp: Outcome) => {
            return resp;
        }));
    }

    updateTimerValueIncident(currentState: OutputOccurrenceData): OutputOccurrenceData {
        // modifico il dato dei time da inviare ai servizi be per incident data
        const actualOccurrenceTime = new Date(currentState.incidentData.actualOccurrenceTime);
        let actualOccurrenceTimeHours = actualOccurrenceTime.getHours().toString();
        let actualOccurrenceTimeMin = actualOccurrenceTime.getMinutes().toString();
        actualOccurrenceTimeHours = actualOccurrenceTimeHours.length === 1
            ? '0' + actualOccurrenceTimeHours.toString() : actualOccurrenceTimeHours.toString();
        actualOccurrenceTimeMin = actualOccurrenceTimeMin.length === 1
            ? '0' + actualOccurrenceTimeMin.toString() : actualOccurrenceTimeMin.toString();
        const occurrenceTime = new Date(currentState.incidentData.occurrenceTime);
        let occurrenceTimeHours = occurrenceTime.getHours().toString();
        let occurrenceTimeMin = occurrenceTime.getMinutes().toString();
        occurrenceTimeHours = occurrenceTimeHours.length === 1
            ? '0' + occurrenceTimeHours.toString() : occurrenceTimeHours.toString();
        occurrenceTimeMin = occurrenceTimeMin.length === 1
            ? '0' + occurrenceTimeMin.toString() : occurrenceTimeMin.toString();
        currentState.incidentData.actualOccurrenceTime = actualOccurrenceTimeHours + ':' + actualOccurrenceTimeMin;
        currentState.incidentData.occurrenceTime = occurrenceTimeHours + ':' + occurrenceTimeMin;
        return currentState;
    }

    calculateLiabilityState(circumstanceIncident: CircumstanceIncident) {
        const codeBaremA = circumstanceIncident.baremEntityA ? circumstanceIncident.baremEntityA.objectId : undefined;
        const codeBaremB = circumstanceIncident.baremEntityB ? circumstanceIncident.baremEntityB.objectId : undefined;
        if (codeBaremA && codeBaremB) {
            let liabilityDetermination: OutputLiabilityDetermination = new OutputLiabilityDetermination();
            this.occurrenceStateService
                .calculateLiability(codeBaremA, codeBaremB)
                .subscribe((response: OutputLiabilityDetermination) => {
                    liabilityDetermination = response;

                    circumstanceIncident.percInsuredLiability = parseInt(liabilityDetermination.result.liability.liabilityPercentage, 10);

                    circumstanceIncident.percOtherPartyLiability = (100 - circumstanceIncident.percInsuredLiability);

                    circumstanceIncident.liabilityBySystem = liabilityDetermination.result.liability.determinatedLiability.codice;

                    circumstanceIncident.liabilityType = liabilityDetermination.result.liability.liabilityType.codice;
                }, err => {
                    console.log('State Manager - Barems: Errore durante il calcolo della responsabilità');
                });
        }
    }
}

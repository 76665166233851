<ng-container *ngIf="(newDialogueState.getState$() | async) as newDialogueState">
<div class="container-fluid " [formGroup]="newDialogueForm">
  <div *ngIf="viewCond.functionCond" class="row">

    <div class="col-md-3">
      <rgi-rx-form-field>
      <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._FUNCTION' | translate }}</label>
      <select rgiRxNativeSelect class="core-select form-control" formControlName="idFunStatusSelected" id="function"
        name="function" attr.data-qa="function-select"  (change)="onFunctionSelected()">
        <option></option>
        <option *ngFor="let function of newDialogueState.searchWrapperObject.dialogueFunctionResponse ; let i = index " [value]="function.functionCode">
              {{function.functionDescription}}</option>
      </select>
    </rgi-rx-form-field>
      <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>

    <!-- Codice e descrizione richiesta -->
    <div class="col-md-1">
      <rgi-rx-form-field>
        <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-request-label">
          {{ '_CLAIMS_._REQUEST' | translate }}</label>
        <input rgiRxInput disabled class="form-control" id="request" type="text" name="request"
          attr.data-qa="new-dialogue-request-input" formControlName="dialogueMsgCode">
      </rgi-rx-form-field>
    </div>

    <!-- Descrizione richiesta -->
    <div class="col-md-3">
      <br>
      <rgi-rx-form-field>
        <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-request-label"> </label>
        <input rgiRxInput disabled class="form-control" id="request" type="text" name="request"
          attr.data-qa="new-dialogue-request-input" formControlName="dialogueMsgDescription">
      </rgi-rx-form-field>
    </div>


    <div class="col-md-3">
      <rgi-rx-form-field>
        <label rgiRxLabel class="label-core">{{ '_CLAIMS_._OBJECT' | translate }}</label>
        <select rgiRxNativeSelect class="core-select form-control" formControlName="idDialogueSubject" id="object" name="object"
        attr.data-qa="object-select" [disabled]="onbjectIndex <= 0 || descrObject" (change)="onObjectSelected()">
        <option *ngIf = "descrObject">{{descrObject}}</option>
        <option></option>
        <option *ngFor="let object of newDialogueState.searchWrapperObject.dialogueObjectResponse[onbjectIndex]?.dialogueSubject" [value]="object.code">
          {{ object.description }}
        </option>
</select>

      </rgi-rx-form-field>
      <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>


    <div class="col-md-2">
    <rgi-rx-form-field>
      <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._ROLE' | translate }}</label>
      <select rgiRxNativeSelect class="core-select form-control" formControlName="roleCompanyUs" id="role" name="role"
        attr.data-qa="role-select" (change)="onObjectSelected()" [disabled]="role">
        <option *ngIf = "role">{{role.description}}</option>
        <option></option>
        <option *ngFor="let role of newDialogueState.newWrapperObject.responseRoleList.data; let i = index " [value]="role.code">
          {{role.description}}</option>
      </select>
    </rgi-rx-form-field>
      <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
    </div>

    <div *ngIf="viewCond.companyCond">

      <div class="row">
        <div class="col-md-5">
          <rgi-rx-form-field>
            <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._COMPANY' | translate }}</label>
            <select rgiRxNativeSelect class="core-select form-control" formControlName="insuranceCodeUs"
              id="ctpCompany" name="ctpCompany" attr.data-qa="object-select">
              <option></option>
              <option
                *ngFor="let company of newDialogueState.searchWrapperObject.dialogueCompResponse.internalCompany; let i = index "
                [value]="company.code">
                {{company.description}}</option>
            </select>
          </rgi-rx-form-field>
          <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>

        <div class="col-md-2">
          <rgi-rx-form-field>
            <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-ctp-car-license-plate-label">
              {{ '_CLAIMS_._PLATE_NUMBER' | translate }}</label>
            <input rgiRxInput class="form-control" formControlName="licensePlateUs" id="ctpCarLicensePlate"
              type="text" name="ctpCarLicensePlate" attr.data-qa="new-dialogue-ctpCarLicensePlate-input">
          </rgi-rx-form-field>
        </div>

        <div class="col-md-5">
          <rgi-rx-form-field>
            <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-nominative-label">
              {{ '_CLAIMS_._CF_PIVA' | translate }}</label>
            <input rgiRxInput class="form-control" formControlName="externalIdentifyDamageParty" id="nominative"
              type="text" name="nominative" attr.data-qa="new-dialogue-nominative-input">
          </rgi-rx-form-field>
        </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <rgi-rx-form-field>
          <label rgiRxLabel class="label-core" ng-bind="label">{{ '_CLAIMS_._CTP_COMPANY' | translate }}</label>
          <select rgiRxNativeSelect class="core-select form-control" formControlName="insuranceCodeOther"
            id="ctpCompany" name="ctpCompany" attr.data-qa="object-select">
            <option></option>
            <option
              *ngFor="let company of newDialogueState.searchWrapperObject.dialogueCompResponse.counterpartCompany; let i = index "
              [value]="company.code">
              {{company.code}} - {{company.description}}</option>
          </select>
        </rgi-rx-form-field>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>

      <div class="col-md-2">
        <rgi-rx-form-field>
          <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-ctp-car-license-plate-label">
            {{ '_CLAIMS_._CTP_CAR_LICENSE_PLATE' | translate }}</label>
          <input rgiRxInput class="form-control" formControlName="licensePlateOther" id="ctpCarLicensePlate"
            type="text" name="ctpCarLicensePlate" attr.data-qa="new-dialogue-ctpCarLicensePlate-input">
        </rgi-rx-form-field>
      </div>

      <div class="col-md-5" >
        <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._LOSS_DATE' | translate }}</label>
            <rgi-rx-date-picker [openOnClick]="false">
                <input rgiRxInput [rgiRxDateTimeInput]="lossDate"
                    [max]="today" formControlName="lossDate">
            </rgi-rx-date-picker>
            <rgi-rx-date-time [pickerType]="'calendar'" #lossDate></rgi-rx-date-time>
        </rgi-rx-form-field>
    </div>
    </div>
  </div>

    <!-- <rgi-rx-.able [data]="newDialogueState.newWrapperObject.responseActCausOutcome" [schema]="schemaTableSearch" [pageOptions]="[5,10,20,50]"
    [expansionRow]="rowDetailSearch" (onAction)="onActionClick($event)"> -->

    <!-- DATATABLE attoreCausaleBySinistroList-->
    <div>
      <rgi-rx-datatable *ngIf="viewCond.causalCond && newDialogueState.newWrapperObject?.responseActCausOutcome?.response?.length > 0 && viewTable"
      [data]="newDialogueState.newWrapperObject.responseActCausOutcome.response"
      [schema]="schemaActor">
    
        <ng-template rgiRxDataTableCell="selected" let-ctx>
          <div>
            <rgi-rx-form-field>
              <input rgiRxInput type="checkbox" (change)="onCheckboxChange(ctx, newDialogueState.newWrapperObject.responseActCausOutcome.response)"
             [disabled]="ctx.row.selected" [(ngModel)]="ctx.row.selected" [ngModelOptions]="{standalone: true}">
            </rgi-rx-form-field>
          </div>
        </ng-template>
    
        <!-- formControlName="" -->
      </rgi-rx-datatable>
    </div>

  </div>

</div>


<div *ngIf="viewCond.responseCond" class="row padding-top-10" [formGroup]="newDialogueForm">

  <div class="col-md-2">
      <label rgiRxLabel class="label-core">
        {{(newDialogueState?.dialogueSummaryObject?.roleCompany.code == roleCompany.CREDITOR)?(('_CLAIMS_._RESPONSE_INPUT') | translate):(('_CLAIMS_._REQUEST_INPUT') | translate)}}</label>
    <input disabled rgiRxInput class="form-control" [value]="newDialogueState?.messageList?.inputRequestCode">
  </div>

  <div class="col-md-4">
    <label rgiRxLabel class="label-core"></label>
    <input disabled rgiRxInput class="form-control" [value]="newDialogueState?.messageList?.inputRequestDescription">
  </div>

  <div class="col-md-2">
      <label rgiRxLabel class="label-core">
        {{(newDialogueState?.dialogueSummaryObject?.roleCompany.code == roleCompany.CREDITOR)?(('_CLAIMS_._REQUEST_OUTPUT') | translate):(('_CLAIMS_._RESPONSE_OUTPUT') | translate)}}</label>
      <select rgiRxNativeSelect (change)="retrieveCascadeData()" formControlName="idFunStatusSelected" class="core-select form-control" 
      attr.data-qa="response-select" >
      <option></option>
      <option *ngFor="let resp of newDialogueState?.responseObject?.possibleAnswers; let i = index " [value]="resp.idFunctionStatus">
            {{resp.status}} </option>
    </select>
  <!-- </rgi-rx-form-field> -->
    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
  </div>

  <div class="col-md-4">
  <!-- <rgi-rx-form-field> -->
    <label rgiRxLabel class="label-core"></label>
    <!-- <input rgiRxInput class="form-control" formControlName="" id="" type="text"
      name="" attr.data-qa="new-dialogue-input"> -->
      <input disabled rgiRxInput class="form-control"
      [value]="selectedResponse?.descStatus"
      >
  <!-- </rgi-rx-form-field> -->
  </div>
</div>

<div style="padding: 7px;" class="blue-section">{{'_CLAIMS_._PARAMETERS_UPPERCASE' | translate}}</div>

<div [formGroup]="newDialogueForm" style="margin-bottom: 10px;" class="container-fluid">
  <div class="row">

    <div class="col-md-12">
    <!-- <rgi-rx-form-field> -->
      <!-- -->
      <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-response-notes-label">{{'_CLAIMS_._RESPONSE_NOTES' |
        translate}}</label>
      <textarea  formControlName="note" class="form-control" id="note" type="text" name="responseNotes"
        attr.data-qa="new-dialogue-response-notes-input"></textarea>
      <!-- </rgi-rx-form-field> -->
    </div>
  </div>
</div>

  <br>
  <ng-container *ngIf="dynamicParameters && dynamicParameters?.length>0" >
    <ng-container *ngIf="paramForm" [formGroup]="paramForm">
  <div  *ngFor="let parameterGroup of dynamicParameters; let i = index " class="row">
    <!-- TODO rendere dinamico parameter -->
    <div *ngFor="let parameter of parameterGroup; let j = index " class="col-md-4">
      <!-- formControlName="parameter"  -->
      <label rgiRxLabel class="label-core" attr.data-qa="new-dialogue-parameter-label">
        {{parameter.paramLabel}}</label>

      <input *ngIf="paramConst.ParamString == parameter.typeValue" rgiRxInput [formControlName]="parameter.label"
        class="form-control" [id]="parameter.label" type="text" [name]="parameter.label"
        [maxlength]="parameter.maxlength" [(ngModel)]="parameter.defualtValue" attr.data-qa="new-dialogue-parameter-input">

      <input *ngIf="paramConst.ParamNumber == parameter.typeValue" rgiRxInput [formControlName]="parameter.label"
        class="form-control" [id]="parameter.label" type="number" [name]="parameter.label" [(ngModel)]="parameter.defualtValue"
        [maxlength]="parameter.maxlength" attr.data-qa="new-dialogue-parameter-input">

      <rgi-rx-form-field *ngIf="paramConst.ParamDate == parameter.typeValue">
        <rgi-rx-date-picker [openOnClick]="false">
          <input rgiRxInput [rgiRxDateTimeInput]="creationDatePicker" data-qa="dialogue-handler-new-creation-date-input"
            [formControlName]="parameter.label" [(ngModel)]="parameter.defualtValue">
        </rgi-rx-date-picker>
        <rgi-rx-date-time [pickerType]="'calendar'" #creationDatePicker></rgi-rx-date-time>
      </rgi-rx-form-field>

      <input *ngIf="paramConst.ParamBool == parameter.typeValue" rgiRxInput [formControlName]="parameter.label"
        class="form-control" type="checkbox" [id]="parameter.label" [name]="parameter.label"
        [maxlength]="parameter.maxlength" attr.data-qa="new-dialogue-parameter-input" [(ngModel)]="parameter.defualtValue">

      <div *ngIf="paramConst.ParamList == parameter.typeValue">
        <select rgiRxNativeSelect class="core-select form-control" [formControlName]="parameter.label"
          [id]="parameter.label" [name]="parameter.label" attr.data-qa="param-select" [(ngModel)]="parameter.defualtValue">
          <option></option>
          <option *ngFor="let param of parameter.listValue; let i = index" [value]="parameter.listCode[i]">
            {{ param }}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>
    </div>
  </div>
</ng-container>
</ng-container>


<ng-container *ngIf="viewCond.documentationCond">
<div style="padding: 7px;" class="blue-section">{{'_CLAIMS_._DOCUMENTS_UPPERCASE' | translate}}</div>

<div style="margin: 0px 5px;" *ngIf="newDialogueState?.newWrapperObject?.responseFunctionDoc?.response">
  <p style="margin: 20px 0 4px 0">{{'_CLAIMS_._DOCUMENTS_REQUIRED' | translate}}</p>
<!-- DATATABLE functionDoc-->
  <rgi-rx-datatable *ngIf="newDialogueState?.newWrapperObject?.responseFunctionDoc?.response"
  [data]="newDialogueState.newWrapperObject.responseFunctionDoc.response ? newDialogueState?.newWrapperObject?.responseFunctionDoc?.response : []"
  [schema]="schemaFlow">


  <ng-template rgiRxDataTableCell="compulsory" let-ctx>
    <span (click)="stampa(ctx)">{{(ctx.row.compulsory)?('_CLAIMS_._YES' | translate):('_CLAIMS_._NO' | translate)}}</span>
    <!-- <label rgiRxLabel class="label-core">{{(state.dialogueSummaryObject.messageList.length)?('Internal Key (definitive)' | translate):('Internal Key (temporary)' | translate)}}</label> -->
  </ng-template>

</rgi-rx-datatable>
</div>


<div  *ngIf="newDialogueState?.newWrapperObject?.responseActDoc?.response" style="margin: 0px 5px;">
  <p style="margin: 20px 0 4px 0">
  {{'_CLAIMS_._SELECTED_DOCUMENTS' | translate}}</p>
<!-- DATATABLE attoreDocumenti-->
  <rgi-rx-datatable
  [data]="newDialogueState.newWrapperObject.responseActDoc.response"
  (select)="onSelectChange($event)"
  [schema]="schemaFlux" [selectable]="true" class="search-table">
    <ng-template rgiRxDataTableCell="docId" let-ctx>
      <div>
        <select rgiRxNativeSelect  class="core-select form-control" attr.data-qa="object-select">
          <option></option>
          <!-- formControlName="" id="" name="" -->
          <!-- <option *ngFor="let outcome of outcomeList; let i = index " [value]="i">
          {{outcome.eTypeCode}} - {{outcome.eTypeDescription}}</option> -->
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>
    </ng-template>
  </rgi-rx-datatable>
</div>
</ng-container>

<span *ngIf="errorMessage" class="col-sm-12 content-error validate-error">
<span class="rgifont rgi-exclamation-triangle"></span>
<span>{{errorMessage}}</span>
</span>

<div class="btn-group btn-group-justified padding-top-10">
  <div class="btn-group">
    <button rgi-rx-button type="submit" class="btn btn-warning pull-right" attr.data-qa="new-dialogue-save-btn"
      id="new-dialogue-submit" (click)="save()" >{{ '_CLAIMS_._BUTTONS_._SAVE' | translate }}  </button>
  </div>
  <div class="btn-group">
    <button rgi-rx-button type="submit" class="btn btn-warning pull-right" attr.data-qa="new-dialogue-save-btn"
      id="new-dialogue-submit" (click)="goBack()" >{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}  </button>
  </div>
</div>

<rgi-rx-modal #modalOutcome>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose()"></rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button rgi-rx-button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose()">{{'_CLAIMS_._BUTTONS_._CLOSE' |
        translate}}</button>
    </rgi-rx-panel-footer>
    <p>{{modalType}}</p>
    <p>{{outcome}}</p>
  </rgi-rx-panel>
</rgi-rx-modal>

</ng-container>


import {
  EMPTY_STR,
  StepperConfiguration,
} from '../../../models/consts/lpc-consts';

export interface TaxReliefConfig2022StepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'roles'`
   * @stepField label `'lpc_variation_step_label'`
   * @stepField fieldId `'roles'`
   * @stepField formName `'roles'`
   */
  VARIATION: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
  };
}

export const TAX_RELIEF: TaxReliefConfig2022StepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates',
  },
  VARIATION: {
    id: 'variation',
    label: 'lpc_relief',
    fieldId: 'variation',
    formName: EMPTY_STR,
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR,
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR,
  },
};

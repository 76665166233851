import {Inject, Injectable, Injector, Optional} from '@angular/core';
import {RgiRxTokenProvider} from './token-provider.service';
import {RGI_RX_AUTH_CONFIG, RgiRxAuthConfig, RgiRxAuthToken, RgiRxAuthTokenRefreshProvider} from './auth-api';
import {HttpEvent, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {combineLatest, from, Observable, of} from 'rxjs';

@Injectable()
export class AuthHttpService {

  constructor(private tokenProvider: RgiRxTokenProvider, @Optional() @Inject(RGI_RX_AUTH_CONFIG) private authConfig: RgiRxAuthConfig, private injector: Injector) {
  }

  handleRefreshTokenResponseHeaders = (event: HttpEvent<any>) => {
    if (event.type === HttpEventType.Response) {
      if (this.authConfig && event instanceof HttpResponse && !!this.authConfig.refreshTokenHeader && event.headers.has(this.authConfig.refreshTokenHeader)) {
        this.tokenProvider.token = event.headers.get(this.authConfig.refreshTokenHeader);
      }
    }
  }

  handleRefreshTokenFn = (event: HttpEvent<any>): Observable<HttpEvent<any>> => {
    if (!!this.authConfig && event instanceof HttpResponse && !!this.authConfig.refreshTokenProvider) {
      const rgiRxAuthTokenRefreshProvider = this.injector.get<RgiRxAuthTokenRefreshProvider>(this.authConfig.refreshTokenProvider);
      const refreshTokenFnResult = rgiRxAuthTokenRefreshProvider.refresh(this.tokenProvider.token);
      if (!refreshTokenFnResult) {
        return of(event);
      }
      if (typeof refreshTokenFnResult === 'string') {
        this.tokenProvider.token = refreshTokenFnResult;
      } else {
        return combineLatest([from(refreshTokenFnResult), of(event)])
          .pipe(
            map(([tokenResult, evt]) => {
              this.tokenProvider.token = tokenResult;
              return evt;
            })
          );
      }
    }
    return of(event);
  }

  handlePreMatchingRefreshTokenFn$ = (): Observable<RgiRxAuthToken | undefined> => {
    if (!this.authConfig || !this.authConfig.preMatchingRefreshTokenProvider) {
      return of(undefined);
    }
    const rgiRxAuthTokenPreMatchingRefreshProvider = this.injector.get<RgiRxAuthTokenRefreshProvider>(this.authConfig.preMatchingRefreshTokenProvider);
    const refreshTokenFnResult = rgiRxAuthTokenPreMatchingRefreshProvider.refresh(this.tokenProvider.token);
    if (!refreshTokenFnResult) {
      return of(undefined);
    } else if (typeof refreshTokenFnResult === 'string') {
      this.tokenProvider.token = refreshTokenFnResult;
      return of(refreshTokenFnResult);
    }
    return from(refreshTokenFnResult)
      .pipe(
        map(tokenResult => {
          this.tokenProvider.token = tokenResult;
          return tokenResult;
        })
      );
  }

  setAuthorization(req: HttpRequest<any>, token?: string): HttpRequest<any> {
    const currentToken = token ? token : this.tokenProvider.token;
    if (!currentToken){
      return req;
    }
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${currentToken}`
      }
    });
  }
}

import {AnagPartyKey, AnagPhoneNumber} from '../anag-domain/anag-api-party';
import {AnagEntityIta} from './anag-subject-api';
import {AnagStateKeyData} from "../../anag-states/key-data/anag-state-manager-key-data";
import {AnagExtensionSubject} from "../anag-domain/anag-extension-subject";

export class AnagSubjectsFilter {
  filter: {
    companyCode: string,
    address: {
      baseAddress: any;
    },
    vat: string;
    fiscalCode: string;
    partyType: AnagEntityIta;
    clientNumber: string;
    birthName: string;
    personTypeCode: string;
    partyNumber: string;
    username: string;
    surnameOrCompanyName: string;
    name: string;
    partyKey: AnagPartyKey | null;
    subsysReference: { chiave; valore };
    nodeId: string;
    nodeCode: string;
    policyRole: string | undefined;
    fiscalCodeDynamicObj: string | undefined;
    homonymySearch: boolean | undefined;
    birthDate: Date | undefined;
    objectId: string | undefined;
    emailAddress: string | undefined;
    phoneNumber: AnagPhoneNumber | undefined;
    extensionSubject: AnagExtensionSubject | undefined;
  };

  // TODO: find solution for circular dependency
  public static adaptFromState(st: any): AnagSubjectsFilter {
    return {
      filter: {
        companyCode: st.companyAniaCode ? st.companyAniaCode : undefined,
        address: {
          baseAddress: st.party.residence,
        },
        surnameOrCompanyName: st.party.surname ? st.party.surname : st.party.denomination,
        name: st.party.name,
        partyKey: st.partyKey,
        vat: st.party.vat,
        fiscalCode: st.party.fiscalCode,
        partyType: st.party.subjectType,
        clientNumber: st.clientNumber,
        birthName: st.party.birthName,
        personTypeCode: st.partyType,
        partyNumber: st.party.partyNumber,
        username: undefined,
        subsysReference: {
          chiave: st.subsystemCode,
          valore: st.subsystemIdentifier
        },
        nodeId: st.searchNodeId,
        nodeCode: st.searchNodeCode,
        policyRole: undefined,
        fiscalCodeDynamicObj: st.fiscalCodeDynamicObj,
        homonymySearch: st.isHomonymySearch,
        birthDate: st.party.dateOfBirth,
        objectId: st.party.objectId,
        emailAddress : st.email,
        phoneNumber : st.phoneNumber,
        extensionSubject : st.extensionSubject
      }
    };
  }

  public static adaptFromParty(st: AnagStateKeyData, party): AnagSubjectsFilter {
    return {
      filter: {
        companyCode: party.company ? party.company.codice : undefined,
        address: {
          baseAddress: party.residence,
        },
        surnameOrCompanyName: party.surname ? party.surname : party.denomination,
        name: party.name,
        partyKey: st.partyKey,
        vat: party.vat,
        fiscalCode: party.fiscalCode,
        partyType: party.subjectType,
        clientNumber: party.clientNumber,
        birthName: party.birthName,
        personTypeCode: party.personType.codice,
        partyNumber: party.partyNumber,
        username: undefined,
        subsysReference: {
          chiave: party.subsystemCode,
          valore: party.subsystemIdentifier
        },
        nodeId: party.node.identification,
        nodeCode: party.node.code,
        policyRole: undefined,
        fiscalCodeDynamicObj: party.fiscalCodeDynamicObj,
        homonymySearch: st.isHomonymySearch,
        birthDate: party.dateOfBirth,
        objectId: party.objectId,
        emailAddress : party.email,
        phoneNumber : party.mobilePhone,
        extensionSubject : party.extensionSubject
      }
    };
  }
}

<div class="panel-body" [formGroup]="formGroup">
  <div *ngIf="formGroup.touched && !!errors.length">
    <lic-karma-fund-message *ngFor="let error of errors" [error]="error"></lic-karma-fund-message>
  </div>
  <div class="input-group">
    <label>
      <span translate>Name: </span><input style="margin-left: 5px;" name="filter" formControlName="search" /></label>
  </div>
  <div class="row">
    <div formGroupName="funds">
      <div class="col-sm-6 col-md-4 col-lg-3 fund-button-container" *ngFor="let fund of funds;">
        <lic-karma-fund-button [isMinimumRequired]="false" [formControlName]="fund.id.toString()"
                               [totalAmount]="totalAmount" [definition]="fund" [sliderProperty]="sliderProperty"></lic-karma-fund-button>
      </div>
    </div>
  </div>
</div>

import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_IT: ClaimsTranslationsKeys = {
    _CLAIMS_COMMON_: {
        _EDIT_: 'Modifica',
        _MESSAGES_: {
            _GENERIC_ERROR: `Riscontrato un errore, perfavore contattare l'assistenza clienti`
        },
        _NO: 'No',
        _YES: 'Si',
    }
};

import {ElementRef, EventEmitter, InjectionToken, QueryList, Type} from '@angular/core';
import {OnModalClose} from '../modal/modal-on-close';
import {RgiRxDragActionDirective} from './rgi-rx-drag-action.directive';
import {ControlValueAccessor} from '@angular/forms';
import {isScalarType, Log, RgiRxVirtualDOMError, SCALAR_TYPE, walk} from '@rgi/rx';
import {Observable, Subscription} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CdkDrag, CdkDragDrop, CdkDragEnter, CdkDragExit, CdkDropList} from '@angular/cdk/drag-drop';
import {RgiRxDragRemoveDirective} from './rgi-rx-drag-remove.directive';
import {RgiRxOnDragBodyRemove} from './rgi-rx-drag-body/rgi-rx-drag-body.component';
import {RgiRxDropBodyComponent} from './rgi-rx-drop-body/rgi-rx-drop-body.component';
import {tap} from 'rxjs/operators';
import {RgiRxDragLabelDirective} from './rgi-rx-drag-label.directive';
import {RgiRxDragValueDirective} from './rgi-rx-drag-value.directive';
import {RgiRxDropLabelDirective} from './rgi-rx-drop-label.directive';

export interface RgiRxDropContainerSelectData<T, K> {
  data: T;
  selectData: K;
}

export interface RgiRxOnSelectNode<SOURCE, DATA, TARGET> {
  onSelect$(model: RgiRxDropContainerSelectData<SOURCE, DATA>): Observable<TARGET>;
}

export type RgiRxDropSelectionFn<SOURCE, DATA, TARGET> = (model: RgiRxDropContainerSelectData<SOURCE, DATA>) => Observable<TARGET>;

export interface RgiRxDropSelectionModal extends OnModalClose, Type<any> {
}

export interface RgiRxDropSelectionService<SOURCE, DATA, TARGET> extends RgiRxOnSelectNode<SOURCE, DATA, TARGET>, Type<any> {
}

export type RgiRxDropSelectionType = RgiRxDropSelectionFn<any, any, any> | Type<RgiRxOnSelectNode<any, any, any>> | Type<OnModalClose>;

export type RgiRxDropSelectionConcreteType =
  RgiRxDropSelectionService<any, any, any>
  | RgiRxDropSelectionModal
  | RgiRxDropSelectionFn<any, any, any>;

export interface RgiRxDropSelectionHandler {
  name: string;
  handler: RgiRxDropSelectionType;
}

export type RgiRxDropSelectionHandlers = RgiRxDropSelectionHandler[];

export const RGI_RX_DROP_SELECTION_HANDLER = new InjectionToken<RgiRxDropSelectionHandlers>('RGI_RX_DROP_SELECTION_HANDLER');
export const RGI_RX_DROP_SELECTION_DATA = new InjectionToken<RgiRxDropContainerSelectData<any, any>>('RGI_RX_DROP_SELECTION_DATA');

export interface RgiRxDropContainerTemplateCtx {
  $implicit?: any;
  label: string;
  viewModel: SCALAR_TYPE;
}

export interface RgiRxDragEvent {
  data: any;
  element: ElementRef;
}

export type RgiRxDropEnterPredicate = (
  source: RgiRxDragEvent,
  target: RgiRxDragEvent
) => boolean;

export interface RgiRxOnDragEnter {
  currentIndex: number;
  source: RgiRxDragEvent;
  target: RgiRxDragEvent;
}

export interface RgiRxOnDragExit {
  source: RgiRxDragEvent;
  target: RgiRxDragEvent;
}

export interface RgiRxOnDragSort {
  previousIndex: number;
  currentIndex: number;
  source: RgiRxDragEvent;
  target: RgiRxDragEvent;
}

export interface RgiRxOnDragSortEnded {
  source: RgiRxDragEvent;
  target: RgiRxDragEvent;
}

export interface RgiRxOnDrop {
  source: RgiRxDragEvent;
  target?: RgiRxDragEvent;
}

export interface RgiRxOnRemove {
  event: Event;
  origin: string;
  data: any;
  index?: number;
}

export interface RgiRxOnContainerValueChange {
  changed: any;
}


export abstract class RgiRxDropModel<T> {
  private _model: T;
  private _label: string;
  private _field: string;
  private _viewField: string;
  private _disabled;


  get model(): T {
    return this._model;
  }

  set model(value: T) {
    this._model = value;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  get field(): string {
    return this._field;
  }

  set field(value: string) {
    this._field = value;
  }

  get viewField(): string {
    return this._viewField;
  }

  set viewField(value: string) {
    this._viewField = value;
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
  }
}


export abstract class RgiRxAbstractDropContainer<T> extends RgiRxDropModel<T> implements ControlValueAccessor {


  protected constructor() {
    super();
    // keep track of active containers
    RgiRxAbstractDropContainer._dropContainers.push(this);
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get connectedTo(): (RgiRxAbstractDropContainer<any> | string)[] | RgiRxAbstractDropContainer<any> | string {
    return this._connectedTo;
  }

  set connectedTo(value: (RgiRxAbstractDropContainer<any> | string)[] | RgiRxAbstractDropContainer<any> | string) {
    this._connectedTo = value;
  }


  get predicate(): RgiRxDropEnterPredicate {

    return this._predicate;
  }

  set predicate(value: RgiRxDropEnterPredicate) {
    this._predicate = value;
  }


  get isEntered(): boolean {
    return this._isEntered;
  }

  set isEntered(value: boolean) {
    this._isEntered = value;
  }


  get selectData(): any {
    return this._selectData;
  }

  set selectData(value: any) {
    this._selectData = value;
  }

  private static _dropContainers: RgiRxAbstractDropContainer<any>[] = [];

  /** inputs **/
  select: RgiRxDropSelectionType | string;

  /** outputs **/
  onDragEnter = new EventEmitter<RgiRxOnDragEnter>();
  onDragExit = new EventEmitter<RgiRxOnDragExit>();
  onDrop = new EventEmitter<RgiRxOnDrop>();
  onRemove = new EventEmitter<RgiRxOnRemove>();
  onValueChange = new EventEmitter<RgiRxOnContainerValueChange>();

  /** Query content **/
  abstract dragActions: QueryList<RgiRxDragActionDirective>;
  abstract dragRemove: RgiRxDragRemoveDirective;
  abstract dropBodyComponent: RgiRxDropBodyComponent;
  abstract dragLabelDirective?: RgiRxDragLabelDirective;
  abstract dragValueDirective?: RgiRxDragValueDirective;
  abstract dropLabelDirective?: RgiRxDropLabelDirective;
  abstract cdkDropList: CdkDropList;

  /** inputs **/
  private _connectedTo: (RgiRxAbstractDropContainer<any> | string)[] | RgiRxAbstractDropContainer<any> | string;
  private _predicate: RgiRxDropEnterPredicate;
  private _selectData: any;

  /** queries **/
  private _cdkDropList: any;

  /** state **/
  private _id: string;
  private _isEntered: boolean;

  /** subscriptions **/
  private dropContainerSubscription: Subscription = Subscription.EMPTY;
  private cdkDropListEnteredSubscription: Subscription = Subscription.EMPTY;
  private cdkDropListExitedSubscription: Subscription = Subscription.EMPTY;
  private cdkDropListDroppedSubscription: Subscription = Subscription.EMPTY;

  /**
   * onChange callback
   * @see ControlValueAccessor
   * @param changed
   */
  onChange = (changed) => {
  };

  /**
   * onTouched callback
   * @see ControlValueAccessor
   */
  onTouched = () => {
  };


  /**
   * Get the viewModel that is displayed on the view.
   * @param model
   */
  getViewModelValue(model: any): SCALAR_TYPE {
    if (!model) {
      return;
    }
    const node = this.getModel(model, this.viewField) as SCALAR_TYPE;
    if (!isScalarType(node) && !this.dragValueDirective) {
      throw new RgiRxVirtualDOMError('Model is not a scalar value, set the field attribute for object models to resolve the displayed text node');
    }
    return node;
  }

  /**
   * Wrapper of CdkDropEnterPredicate
   * @see CdkDropEnterPredicate
   * @param drag
   * @param drop
   */
  _dropEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    return this.predicate({
      data: drag.data,
      element: drag.element
    }, {
      data: drop.data,
      element: drop.element
    });
  };


  /**
   * Get the model and walk the model to resolved child nodes when field its defined
   * @param model the model Object
   * @param path the property path including descendant props
   */
  protected getModel(model: any, path?: string): SCALAR_TYPE | T | undefined {
    if (!path) {
      return model;
    }
    return walk<T>(path, model);
  }

  abstract remove(event: RgiRxOnDragBodyRemove): void;

  /**
   * Register onChange
   * @see ControlValueAccessor
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Register on touched
   * @see ControlValueAccessor
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * set disabled state
   * @see ControlValueAccessor
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Write the model from outside
   * @see ControlValueAccessor
   * @param obj
   */
  writeValue(obj: T): void {
    this.model = obj;
  }

  /**
   * The implementation must provide a concrete update model operation.
   * This will need also to invoke onChange callback
   * @param selection
   */
  abstract updateModel(selection: any): void;

  /**
   * Consume the select observable
   */
  abstract resolve(): void;

  /**
   * React to a drop event
   * @see CdkDragDrop
   * @param event
   */
  abstract drop(event: CdkDragDrop<any>): void;

  /**
   * initialize subscription and bindings
   * must be invoked when the view has been initialized, like ngAfterViewInit
   * @protected
   */
  protected initialize() {
    this.subscribeDropBodyChange();
    this.initConnectedTo();
    this.initPredicate();

    this.cdkDropListEnteredSubscription = this.cdkDropList.entered.pipe(
      tap((entered) => {
        this.isEntered = true;
      })
    ).subscribe();
    this.cdkDropListExitedSubscription = this.cdkDropList.exited.pipe(
      tap((entered) => {
        this.isEntered = false;
      })
    ).subscribe();

    this.cdkDropListDroppedSubscription = this.cdkDropList.dropped.pipe(
      tap((entered) => {
        this.isEntered = false;
      })
    ).subscribe();
  }

  /**
   * Wrap CdkDrag enter into RgiRxOnDragEnter
   * @see CdkDropList
   * @param event
   */
  @Log({
    message: 'RgiRxDragEntered'
  })
  onDragEntered(event: CdkDragEnter<any[]>) {
    this.onDragEnter.emit({
      currentIndex: event.currentIndex,
      source: {
        data: event.item.data,
        element: event.item.element
      },
      target: {
        data: event.container.data,
        element: event.container.element
      }
    });
  }

  /**
   * Wrap CdkDrag enter into CdkDragExit
   * @see CdkDropList
   * @param event
   */
  @Log({
    message: 'RgiRxDragExited'
  })
  onDragExited(event: CdkDragExit<any[]>) {
    this.onDragExit.emit({
      source: {
        data: event.item.data,
        element: event.item.element
      },
      target: {
        data: event.container.data,
        element: event.container.element
      }
    });
  }

  /**
   * Get a template ctx to be provided in ng-templates as context
   * @param dragData
   */
  _getTemplateCtx(dragData?: any): RgiRxDropContainerTemplateCtx {
    return {
      $implicit: dragData,
      viewModel: this.getViewModelValue(dragData),
      label: this.label
    };
  }

  /**
   * Parse the connectedTo properties and maps to CdkDropList.connectedTo
   * @see CdkDropList
   */
  private initConnectedTo() {
    if (this.connectedTo) {
      if (Array.isArray(this.connectedTo)) {
        this.cdkDropList.connectedTo = this.connectedTo.map(
          c => {
            return this.mapDropListToCdkDropList(c);
          }
        );
      } else {
        this.cdkDropList.connectedTo = this.mapDropListToCdkDropList(this.connectedTo);
      }
    }
  }

  /**
   * Map a single RgiRxAbstractDropContainer to a CdkDropList
   * @param container
   * @see CdkDropList
   */
  private mapDropListToCdkDropList(container: RgiRxAbstractDropContainer<any> | string) {
    if (typeof container === 'string') {
      return RgiRxAbstractDropContainer._dropContainers.find(d => d.id == container).cdkDropList;
    } else if (container instanceof RgiRxAbstractDropContainer) {
      const dropList = container as RgiRxAbstractDropContainer<any>;
      return dropList.cdkDropList;
    }
  }

  /**
   * Init predicate if exist and pass the reference to the CdkDropList
   * @see CdkDropList
   */
  private initPredicate() {
    if (this.predicate) {
      this.cdkDropList.enterPredicate = this._dropEnterPredicate;
    }
  }

  /**
   * Subscribe to RgiRxDropBodyComponent dropChange to update the model
   */
  private subscribeDropBodyChange() {
    this.dropContainerSubscription.unsubscribe();
    this.dropContainerSubscription = this.dropBodyComponent.dropChange
      .pipe(
        tap(change => {
          this.updateModel(change);
        })
      )
      .subscribe();
  }

  /**
   * Clear any track of this container and unsubscribe from all observables.
   * Must be called in ngOnDestroy
   */
  protected dispose() {
    const dropIndex = RgiRxAbstractDropContainer._dropContainers.indexOf(this);
    if (dropIndex > -1) {
      RgiRxAbstractDropContainer._dropContainers = RgiRxAbstractDropContainer._dropContainers.splice(dropIndex, 1);
    }
    this.dropContainerSubscription.unsubscribe();
    this.cdkDropListExitedSubscription.unsubscribe();
    this.cdkDropListEnteredSubscription.unsubscribe();
    this.cdkDropListDroppedSubscription.unsubscribe();
  }

  /**
   * Resolve the RgiRxAbstractDropContainer from the CdkDropList by reverse mapping
   * @param list
   */
  protected getContainerFromCDKDropList(list: CdkDropList): RgiRxAbstractDropContainer<any> {
    return RgiRxAbstractDropContainer._dropContainers.find(d => d.cdkDropList === list);
  }
}



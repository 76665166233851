import { Component, EventEmitter, Inject, Optional } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PushMessageHandlerService, RgiRxPushMessage } from '@rgi/rx';
import { Subject, Subscription } from 'rxjs';
import { AnagFormFieldConfig } from '../../../anag-model/anag-domain/anag-form-field-config';
import { DIALOG_DATA, ModalService } from '@rgi/rx/ui';
import { AnagUtilsService } from '../../../anag-resources/anag-utils.service';
import { AnagApiParty} from '../../../anag-model/anag-domain/anag-api-party';
import { AnagStateManagerPartyEditor } from '../../../anag-states/party-editor/anag-state-manager-party-editor';
import { AnagStorageService } from '../../../anag-resources/anag-storage.service';
import { AnagStatelessService } from '../../../anag-resources/anag-stateless.service';
import { AnagConfigService } from '../../../anag-resources/anag-config.service';
import { RATING_ORIGIN } from '../../../anag-constants/enums-constants';
import { ANAG_RATING_OBJ_MODE } from '../../../anag-constants/anag-constants';
import { AnagRating } from '../../../anag-model/anag-domain/anag-rating';
import { AnagEntityIta } from '../../../anag-model/anag-api/anag-subject-api';
import { RgiRxUserService } from '@rgi/rx/auth';

@Component({
  selector: 'rgi-anag-rating',
  templateUrl: './anag-rating.component.html',
  styleUrls: ['./anag-rating.component.css']
})
export class AnagRatingComponent {

  modalClose: EventEmitter<any> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  inputParty: AnagApiParty;
  tmpRating: AnagRating;
  editorMode = ANAG_RATING_OBJ_MODE.EDIT;
  ANAG_DOCUMENT_OBJ_MODE = ANAG_RATING_OBJ_MODE;
  submitted = false;
  editMode = false;
  updateRatingEmitter: Subject<void> = new Subject<void>();
  errorMsg: string;
  originalFormValues: UntypedFormGroup;
  isFormDirty = false;

  ratingForm: UntypedFormGroup = new UntypedFormGroup({});

  ratingFormMap = new Map<string, AnagFormFieldConfig>([
    ['rating', new AnagFormFieldConfig('rating', this.ratingForm, [])],
    ['ratingPeriod', new AnagFormFieldConfig('ratingPeriod', this.ratingForm, [])]
  ]);

  constructor(
    public pushMessageHandler: PushMessageHandlerService,
    public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public statelessService: AnagStatelessService,
    public configService: AnagConfigService,
    protected modalService: ModalService,
    protected anagUtilsService: AnagUtilsService,
    protected userService: RgiRxUserService,
    @Optional() @Inject(DIALOG_DATA) data: AnagRating) {
      if (data) {
        this.tmpRating = this.statelessService.deepClone(data);
      }
  }

  ngOnInit() {
    this.configService.setConfigToForm(this.ratingFormMap, this.stateMgr.getCurrentState().configuration);
    if (this.tmpRating) {
      this.adaptPartyToForm();
    }
    this.pushMessageHandler.clearTag('error-msg-tag');
    this.originalFormValues = this.ratingForm.value;
    this.ratingForm.valueChanges.subscribe(value => {
      this.isFormDirty = JSON.stringify(value) !== JSON.stringify(this.originalFormValues);
    });
  }

  adaptPartyToForm() {
    const rating = this.tmpRating;
    if (rating.rating) {
      this.setControlValue(this.ratingForm.get('rating'), this.getRatingTypeByCode(rating.rating.codice));
    }

    if (rating.ratingPeriod) {
      this.setControlValue(this.ratingForm.get('ratingPeriod'), new Date(rating.ratingPeriod));
    }
  }

  getRatingTypeByCode(code) {
    let a = null;
    this.anagStorage.getEnumtable$('it.rgi.dbobjects.EntRatingtype').subscribe((resp) => {
       let ratignType = resp.find(elem=> elem.codice===code);
       if (ratignType) {
         a = ratignType;
       }
     }
     );
 
     return a;
   }

  setControlValue(control: AbstractControl, value: any) {
    if (control && value) {
      control.setValue(value);
    }
  }

  get ratingOrigin() {
    return this.anagStorage.getEnumsByCode(RATING_ORIGIN);
  }

  actionClose() {
    this.modalClose.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateRating() {
    this.pushMessageHandler.clearTag('error-msg-tag');
    this.submitted = true;
    if (this.ratingForm.valid) {
      this.loadRatingToSave();
      this.updateRatingEmitter.next();
      if (this.tmpRating) {
        this.modalClose.emit(this.tmpRating);
      } else {
        this.actionClose();
      }
    } else {
      this.notifyErrorMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_');
    }
  }

  loadRatingToSave() {
    if (!this.tmpRating) {
      this.tmpRating = new AnagRating();
    }

    if (this.isFormDirty) {
      this.tmpRating.objectId = null;
      this.tmpRating.rating = this.ratingForm.get('rating').value;
      this.tmpRating.ratingPeriod = this.ratingForm.get('ratingPeriod').value;
      this.tmpRating.user = this.userService.getUser().username;
      const originManual = this.stateMgr.getEnumsByCode(RATING_ORIGIN).find(origin => origin.codice === '2');
      this.tmpRating.origin = originManual;
    } else {
      this.tmpRating = null;
    }
  }

  notifyErrorMsg(errorMsg) {
    this.pushMessageHandler.clearTag('error-tag');
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
      tag: 'error-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  isFieldVisible(field: string) {
    return this.ratingForm.get(field);
  }

  get datepickerPlaceholder() {
    return this.statelessService.getDatePickerPlaceholderLabel();
  }

  genericEntitiesTrackByFn(index, genericEntity: AnagEntityIta) {
    return genericEntity.codice;
  }

}

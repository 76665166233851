import {Inject, Injectable, LOCALE_ID, Optional} from '@angular/core';
import {RGI_RX_LOCALE, RgiRxTranslationService} from '@rgi/rx/i18n';
import {LoggerFactory, RgiRxRuntimeError} from '@rgi/rx';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RGI_RX_DATETIME_CONFIG, RgiRxDateTimeConfig} from './locale.providers';

export interface RgiRxDateTimeLocalization {
  locale: RGI_RX_LOCALE;
  language: RGI_RX_LOCALE;
}

@Injectable({
  providedIn: 'root'
})
export class RgiRxDateTimeLocaleResolver {
  private current: RgiRxDateTimeLocalization;
  private readonly logger = LoggerFactory();

  constructor(
    @Inject(RGI_RX_DATETIME_CONFIG) private readonly config: RgiRxDateTimeConfig,
    private translationService: RgiRxTranslationService,
    @Optional() @Inject(LOCALE_ID) private readonly localeId?: string,
  ) {
    if (!this.localeId && this.config.locale === 'LOCALE_ID') {
      throw new RgiRxRuntimeError(`Using LOCALE_ID with RGI_RX_DATETIME_CONFIG requires LOCALE_ID to be defined. Please set the LOCALE_ID according to the Angular framework specs`);
    }

    switch (this.config.locale) {
      case 'LOCALE_ID': {
        this.current = {
          locale: this.stripLocaleIdentifierToBaseLanguage(this.localeId),
          language: this.translationService.currentLanguage
        };
        break;
      }
      case 'browser': {
        this.current = {
          locale: this.stripLocaleIdentifierToBaseLanguage(navigator.language),
          language: this.translationService.currentLanguage
        };
        break;
      }
      default: {
        this.current = {
          locale: this.translationService.currentLanguage,
          language: this.translationService.currentLanguage
        };
      }
    }

    this.getLocale$().subscribe(
      next => {
        this.logger.debug('RgiRxDateTimeLocaleResolver language changed', next);
      }
    );
  }

  getCurrent(): RgiRxDateTimeLocalization {
    return this.current;
  }

  getLocale$(): Observable<RgiRxDateTimeLocalization> {
    const languageChange$ = this.translationService.getLanguageChange$();
    switch (this.config.locale) {
      case 'LOCALE_ID': {
        return languageChange$.pipe(
          map((locale) => {
            this.logger.debug(`RgiRxDateTimeLocaleResolver has detected a language change but will use the LOCALE_ID: ${this.localeId} as locale`);
            this.current = {
              locale: this.stripLocaleIdentifierToBaseLanguage(this.localeId),
              language: locale
            };
            return this.current;
          }),
        );
      }
      case 'browser': {
        return languageChange$.pipe(
          map((locale) => {
            this.logger.debug(`RgiRxDateTimeLocaleResolver has detected a language change but will use the browser navigator: ${navigator.language} as locale`);
            this.current = {
              locale: this.stripLocaleIdentifierToBaseLanguage(navigator.language),
              language: locale
            };
            return this.current;
          }));
      }
      default: {
        return languageChange$.pipe(
          map((locale) => {
            this.current = {
              locale,
              language: locale
            };
            return this.current;
          }));
      }
    }
  }

  private stripLocaleIdentifierToBaseLanguage(language: string): RGI_RX_LOCALE {
    return language.split('-')[0].toLowerCase();
  }
}

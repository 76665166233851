<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()" translate>
    _GP_._TITLE_._MP_AMENDMENT_
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="primary" data-qa="rgi-gp-btn-cancel"
            (click)="modalClose.emit()"
            translate>
      _GP_._BTN_._UNDO_
    </button>
    <button rgi-rx-button color="primary" data-qa="rgi-gp-btn-confirm"
            (click)="confirm()"
            translate>
      _GP_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>
  <ng-container class="rgi-ui-grid-2" [formGroup]="amendmentFG">
    <div class="rgi-ui-col">
      <rgi-rx-form-field data-qa="rgi-gp-amendment-eff-date">
        <label rgiRxLabel translate>_GP_._LABEL_._AMENDMENT_EFF_DATE_</label>
        <rgi-rx-date-picker [openOnClick]="false">
          <input [dateFilter] = "getDateFilter(modalData)"
                 [rgiRxDateTimeInput]="picker1"
                 formControlName="amendmentEffDate">
        </rgi-rx-date-picker>
        <rgi-rx-date-time #picker1
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
  </ng-container>
</rgi-rx-panel>

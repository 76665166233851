import { Injectable } from '@angular/core';
import { RoutingService } from '@rgi/rx/router';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Injectable()
export class LibPortalIntegrationService {

  constructor(protected routingService: RoutingService,
    protected i18nService: RgiRxTranslationService) {
  }
}

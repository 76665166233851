import {EventInstance, EventType} from '@rgi/rx'

interface AnagQuestionnaireSaveEvent extends EventType{
  result?: boolean;
  readonly eventName;
}
const ANAG_QUESTIONNAIRE_SAVE_EVENT: AnagQuestionnaireSaveEvent = {eventName: "PPPRO_ANAG_QUESTIONNAIRE_SAVE_EVENT"};

class AnagQuestionnaireSaveEventInstance implements EventInstance<AnagQuestionnaireSaveEvent>{
  event: AnagQuestionnaireSaveEvent;

  constructor(eventData: boolean) {
    this.event = ANAG_QUESTIONNAIRE_SAVE_EVENT;
    this.event.result = eventData;
  }

}

interface AnagSubjectCodiceDescr {
  codice: string,
  descrizione: string
}

interface AnagSubjectPartyKey {
  label: any,
  state: any,
  type: any,
  value: string
}

interface AnagSubject {

  objectId: string,
  sex: any,
  vat: string,
  dateOfBirth: string,
  subjectType: AnagSubjectCodiceDescr,
  denomination: any,
  emails: any[],
  node: {
      identification: string,
      code: string,
      description: string
  },
  cityOfBirth: any,
  mobilePhone: any[],
  countryOfBirth: any,
  extensionSubject: any,
  surname: string,
  fiscalCode: string,
  name: string,
  partyKey:
      {
          objectId: string,
          key1: AnagSubjectPartyKey,
          country: string,
          key2: AnagSubjectPartyKey,
          extensionSubject: any,
          key: AnagSubjectPartyKey,
          main: boolean
      }[]
  ,
  personType: AnagSubjectCodiceDescr,
  residence: {
      country: AnagSubjectCodiceDescr,
      city: string,
      cab: any,
      normalized: boolean,
      latitude: string,
      placeAddress: string,
      number: string,
      toponym: any,
      cap: string,
      province: AnagSubjectCodiceDescr,
      addressAddition: any,
      specialMention: any,
      countryCode: string,
      adminLevel3Short: any,
      longitude: string,
      formatAddress: string,
      adminLevel3: string,
      adminLevel1: any,
      subLocality: any,
      adminLevel2: string,
      locality: any,
      detailCode: any,
      adminLevel2Short: string,
      fraction: any,
      at: any,
      postalAddress: any,
      extensionSubject: any,
      adminLevel1Short: any
  },
  privacyConsensus: any[],
  partyNumber: any,
  idLatestPhotos: string,
  nominative: string,
  corporateSector: AnagSubjectCodiceDescr
}

interface Operator {
  objectId: string,
  extension: any,
  productsSellableTypes: any,
  liquidationCentre: any,
  listAboveActiveTreeWithGrouping: [],
  blockingDerogationLevel: string,
  isUserSalesNetwork: boolean,
  isDirectionalUser: boolean,
  warningDerogationLevel: string,
  company: any,
  isNodeUser: boolean,
  channelCode: string,
  listAboveActiveTreeWithoutGrouping: [],
  enabledFeatures: [],
  isUserNetworkClaims: boolean,
  productsSellableList: string,
  listUnderActiveTreeWithGrouping: [],
  operationDerogationLevel: string,
  salePoint: any,
  realName: any,
  visibleCompanies: [],
  isSubNodeUser: boolean,
  listUnderActiveTreeWithoutGrouping: [],
  username: string,
  qqsellableProductTypes:[]
}


export {
  ANAG_QUESTIONNAIRE_SAVE_EVENT,
  AnagQuestionnaireSaveEvent,
  AnagQuestionnaireSaveEventInstance,
  AnagSubjectCodiceDescr,
  AnagSubjectPartyKey,
  AnagSubject,
  Operator
}






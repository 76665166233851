import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import {ADAPTERPRINT_EN} from './en';
import {ADAPTERPRINT_IT} from './it';
import {ADAPTERPRINT_FR} from './fr';
import {ADAPTERPRINT_ES} from './es';
import {ADAPTERPRINT_DE} from './de';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ADAPTERPRINT_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ADAPTERPRINT_EN);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ADAPTERPRINT_FR);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ADAPTERPRINT_ES);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ADAPTERPRINT_DE);
  });
}

export const ADAPTERPRINT_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadFR,
    locale: 'fr'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadDE,
    locale: 'de'
  }
];

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lpc-subject',
  templateUrl: './lpc-subject.component.html',
  styleUrls: ['./lpc-subject.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcSubjectComponent),
      multi: true
    }
  ]
})
export class LpcSubjectComponent implements OnInit, ControlValueAccessor {

  @Output() public delete: EventEmitter<any> = new EventEmitter<any>();
  @Input() public customClass: string;
  @Input('data-qa') public dataQa: string;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(),
    id: new UntypedFormControl()
  });

  constructor() { }

  ngOnInit(): void { }

  deleteSbj() {
    this.delete.emit();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  writeValue(obj: any): void {
    if (!!obj) {
      this.formGroup.setValue(obj, {emitEvent: false});
    } else {
      this.formGroup.setValue( {id: null, name: null }, {emitEvent: false});
    }
  }

  onChange(obj: any) {
  }

}

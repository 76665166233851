
import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { PaymentFieldDefinition } from '../../../models/postsales-operations-response.model';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Directive({
    selector: '[lpcFormatPayment]',
    providers: [RgiCountryLayerNumberFormatPipe]
})
export class LpcFormatPaymentDirective implements OnInit {
  @Input () paymentInput: PaymentFieldDefinition;
  element: ElementRef;

  constructor( el: ElementRef, public decPipe: RgiCountryLayerNumberFormatPipe, protected renderer: Renderer2) {
    this.element = el;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if ((this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
    this.element.nativeElement.value !== EMPTY_STR) && this.paymentInput.type === 'R') {
      this.formattingInputNumber();
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if ((this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
    this.element.nativeElement.value !== EMPTY_STR) && this.paymentInput.type === 'R') {
      this.resetFormat();
    }
  }

  @HostListener('keypress', ['$event.key'])
  onKeyPress(value) {
    if (this.paymentInput.type === 'R' || this.paymentInput.type === 'N') {
      const regexForKeyPress = /[\d\.,]/;
      // const regexForValue = /^\d+([\.,]\d{0,2})?$/;
      if (regexForKeyPress.test(value)) {
          return true;
      }
    } else {
      return true;
    }
    return false;
  }

  @HostListener('keyup', ['$event.key'])
  onkeyup(value) {
    if (this.paymentInput.type === 'R' || this.paymentInput.type === 'N') {
      const regexForValue = /^\d+([\.,]\d{0,2})?$/;
      if (regexForValue.test(this.element.nativeElement.value)) {
          return true;
      }
    } else {
      return true;
    }
    return false;
  }

  ngOnInit() {
    if (this.paymentInput.type === 'R') {
      if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== EMPTY_STR) {
        this.formattingInputNumber();
      }
    }
  }

  resetFormat() {
    const regex = /[.,\s]/g;
    this.element.nativeElement.value = this.element.nativeElement.value.replace(regex, EMPTY_STR);
    const decimal = (this.element.nativeElement.value.substring(
      this.element.nativeElement.value.length - (+2),
      this.element.nativeElement.value.length));
    const integer = (this.element.nativeElement.value.substring(
      0,
      this.element.nativeElement.value.length - (+2)));
    if (+decimal === 0) {
      this.element.nativeElement.value = integer;
    } else {
      this.element.nativeElement.value = integer + '.' + decimal;
    }
  }

  formattingInputNumber() {
    this.element.nativeElement.value = this.element.nativeElement.value.replace(',', '.');
    const decimal = +2;
    if (decimal > 0) {
      if (+this.element.nativeElement.value !== null) {
        const decimalNumberFormatterOptions: Intl.NumberFormatOptions = {
          style: 'decimal',
          maximumFractionDigits: decimal,
          minimumFractionDigits: decimal
        };
        this.element.nativeElement.value = this.decPipe.transform(+this.element.nativeElement.value, '', decimalNumberFormatterOptions);
      } else {
        this.element.nativeElement.value = this.decPipe.transform(+this.element.nativeElement.value);
      }
    }
  }


}

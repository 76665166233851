<div class="container-fluid table-white" attr.data-qa="party-panel-checkboxes">
    <div class="container-fluid table-white">
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"> <input type="checkbox"
                    class="form-control btn-checkbox checkbox-unit" name="holderDriver" [(ngModel)]="holderDriver"
                    [disabled]="disabled" />
                {{ '_CLAIMS_._DRIVER' | translate }}
            </div>
        </div>
    </div>
</div>
import { DISTRIBUTION_AMOUNT } from './../../modules/distribution-toggle/distribution-toggle.model';
import { UntypedFormControl, ValidatorFn, ValidationErrors, AbstractControlOptions, UntypedFormGroup } from '@angular/forms';
import { DistributionToggle } from '../../modules/distribution-toggle/distribution-toggle.model';
import { Role } from '../../models/postsales-operations-response.model';

  /**
   * @description
   * It's a Custom Validators utils, where you can create as the name says a custom validator
   * on your form control or on your formGroup to check the value and set an error on the control which
   * it'll be desplayed on the submit of the step.
   *
   * --N.B set the errors obj with a key called "error" or "required" and a string as the value if you  want to desplay it--
   */
export const customAmountValidator: ValidatorFn | ValidatorFn[] | AbstractControlOptions =
  (control: UntypedFormControl): ValidationErrors | null => {
    const value = control.value;
    let errors = null;
    if (!!value) {
      // checks if it contains any "," on an input number value
      if (value.toString().indexOf(',') > -1) {
        // TODO create the translation mapping for the messages
        errors = {
          error: 'The amount is invalid, it contains incorrectly the wrong decimal separator please correct it using ' + '\'.' + '\''
        };
      }
      // checks if it contains more than a "."
      if (!!value.match( /\./g) && value.match( /\./g).length > 1) {
        // TODO create the translation mapping for the messages
        errors = { error: 'The amount value contains more than a decimal separator sign' };
      }
    } else {
      return { required: 'Fields marked in red are required' };
    }
    return errors;
};

export const percentageSumEqual100Validator: ValidatorFn =
  (fg: UntypedFormGroup): ValidationErrors | null => {
    const obj: ValidationErrors = {};
    let sum = 0;
    Object.keys(fg.controls).forEach(fc => {
      if (!!fg.get(fc)) {
        sum += !!fg.get(fc).value ? +fg.get(fc).value : 0;
      }
    });
    sum = Number(parseFloat(sum.toString()).toFixed(4));
    if (sum !== 1) {
      obj.percentageSum = {
        error: true,
        value: sum
      };
    }
    return obj;
};

/* eslint-disable max-len */
export function customDistributionValidator(min: number, max: number, type: DistributionToggle, benef: Role, total: number, transl, pipe): ValidatorFn | ValidatorFn[] {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const value = control.value;
    let errors = null;
    if (!!value) {
      const typeDescr = type === DISTRIBUTION_AMOUNT ? 'amount' : 'perc';
      // Guard clause
      if (isNaN(value)) {
        return {
          benefName: benef.name,
          errorDescription: transl.getImmediate('lpc_invalid_char', {type: transl.getImmediate(typeDescr)}),
          isAmount: type === DISTRIBUTION_AMOUNT,
          totalDistribution: max
        };
      }

      const numberType = type === DISTRIBUTION_AMOUNT ? 'currency' : 'percentage';
      // checks if the amount is < min
      if (Number(value) <= min) {
        errors = {
          benefName: benef.name,
          errorDescription: transl.getImmediate('lpc_distribution_invalid_greater', {type: transl.getImmediate(typeDescr), min: pipe.transform(min, numberType)}),
          amount: min,
          isAmount: type === DISTRIBUTION_AMOUNT,
          errorType: 'min',
          totalDistribution: max
        };
      }
      // checks if > max
      if (Number(value) > max) {
        errors = {
          benefName: benef.name,
          errorDescription: transl.getImmediate('lpc_distribution_invalid_greater_equal', {type: transl.getImmediate(typeDescr), max: pipe.transform(max, numberType)}),
          max,
          isAmount: type === DISTRIBUTION_AMOUNT,
          errorType: 'max',
          totalDistribution: max
        };
      }
    } else {
      errors = {
        benefName: benef.name,
        requiredError: transl.getImmediate('lpc_Please_insert_a_distribution'),
        isAmount: type === DISTRIBUTION_AMOUNT,
        totalDistribution: total
      };
    }
    return errors;
  };
}


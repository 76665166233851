import { SelectableCoverture } from './selectable-coverture';
import { DomainField } from '../../model/domain/claim/domain-field';
import { Party } from './party';

export class SelectableCovertureResponse {
    public covertures: SelectableCoverture[];
    public fields: DomainField[];
    public bodyworkrequest: string;
    public driverrequest: string;
    public claimsMade: boolean;
    public postuma: boolean;
    public injuryCauseList: any[];
    public injuryNatureList: Map<string, any[]>;
    public injuryLocationList: Map<string, any[]>;
    public professionList: any[];
    public policyHolder: Party;
    public enableMultiDamage: boolean;
}

import { EnumType } from '../enum-type';
import { Subject } from '../anag/subject';

export class DamageSubject {
    public idSubjectLock: number;
    public subject: Subject;
    public roleType: EnumType;
    public personType: EnumType;
    public denomination: string;
    public name: string;
    public surname: string;

    constructor() { }
}

<div *ngIf="stateManager.getState$() | async as state">
  <form [formGroup]="startCardFormGroup" class="rgi-ui-grid-1">

    <div class="rgi-ui-col search-parameters">

      <!-- CONTRACT BRANCH -->
      <div *ngIf="isFieldVisible(TABGROUP_CC_BRANCH)" class="rgi-ui-col">
        <label rgiRxLabel for="contractBranch" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.CONTRACT_BRANCH
        </label>
        <rgi-rx-button-toggle id="contractBranch" class="rgi-ui-col rgi-ui-grid-2" formControlName="contractBranch" >
          <button class="rgi-ui-col button-padding" *ngIf="isFieldVisible(FIELD_CC_TABALL)" rgi-rx-button rgiRxValueOption
                  value="{{ContractBranchEnum.ALL}}" data-qa="all" translate>CONTRACT_CONSULTATION.ALL</button>
          <button class="rgi-ui-col button-padding" *ngIf="isFieldVisible(FIELD_CC_TABPC)" rgi-rx-button rgiRxValueOption
                  value="{{ContractBranchEnum.PC}}" data-qa="pc" translate>CONTRACT_CONSULTATION.PC</button>
          <button class="rgi-ui-col button-padding" *ngIf="isFieldVisible(FIELD_CC_TABLIFE)" rgi-rx-button rgiRxValueOption
                  value="{{ContractBranchEnum.LIFE}}" data-qa="life" translate>CONTRACT_CONSULTATION.LIFE</button>
        </rgi-rx-button-toggle>
      </div>

      <!-- TYPE -->
      <div *ngIf="isFieldVisible(TABGROUP_CC_TYPE)" class="rgi-ui-col">
        <label rgiRxLabel for="type" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.TYPE
        </label>
        <rgi-rx-button-toggle id="type" class="rgi-ui-col rgi-ui-grid-2" formControlName="type" (onToggle)="resetNumberValues();resetProductFiled();">
          <button *ngIf="typeIndividualVisibility && isFieldVisible(FIELD_CC_TABINDIVIDUAL)" class="rgi-ui-col button-padding"
                  rgi-rx-button rgiRxValueOption value="{{TypeEnum.INDIVIDUAL}}" data-qa="individual" translate>CONTRACT_CONSULTATION.INDIVIDUAL</button>
          <button *ngIf="typeCollectiveVisibility && isFieldVisible(FIELD_CC_TABCOLLECTIVE)" class="rgi-ui-col button-padding"
                  rgi-rx-button rgiRxValueOption value="{{TypeEnum.COLLECTIVE}}" data-qa="collective" translate>CONTRACT_CONSULTATION.COLLECTIVE</button>
        </rgi-rx-button-toggle>
      </div>

      <!-- SUBTYPE -->
      <div *ngIf="isFieldVisible(TABGROUP_CC_SUBTYPE) && startCardFormGroup.value.type === TypeEnum.COLLECTIVE" class="rgi-ui-col">
        <label rgiRxLabel for="subtype" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.SUBTYPE
        </label>
        <rgi-rx-button-toggle id="subtype" class="rgi-ui-col rgi-ui-grid-2" formControlName="subtype" (onToggle)="resetNumberValues()">
          <button class="rgi-ui-col button-padding" *ngIf="isFieldVisible(FIELD_CC_TABMASTERPOLICY)" rgi-rx-button rgiRxValueOption
                  value="{{SubtypeEnum.MASTER_POLICY}}" data-qa="masterPolicy" translate>CONTRACT_CONSULTATION.MASTER_POLICY</button>
          <button class="rgi-ui-col button-padding" *ngIf="isFieldVisible(FIELD_CC_TABAPPLICATION)" rgi-rx-button rgiRxValueOption
                  value="{{SubtypeEnum.APPLICATION}}" data-qa="application" translate>CONTRACT_CONSULTATION.APPLICATION</button>
        </rgi-rx-button-toggle>
      </div>

      <!-- LIMITATION -->
      <div *ngIf="isFieldVisible(TABGROUP_CC_LIMITATIONS)" class="rgi-ui-col">
        <label rgiRxLabel for="limitation" class="rgi-ui-title-3" translate>
        CONTRACT_CONSULTATION.LIMITATION
        </label>
        <rgi-rx-button-toggle id="limitation" class="rgi-ui-col rgi-ui-grid-2" formControlName="limitation">
          <button class="rgi-ui-col button-padding" *ngIf="isFieldVisible(FIELD_CC_TAB_NOT_ANONYMIZED)" rgi-rx-button rgiRxValueOption
                  value="{{LimitationEnum.NOT_ANONYMIZED}}" data-qa="notAnonymized" translate>CONTRACT_CONSULTATION.NOT_ANONYMIZED</button>
          <button class="rgi-ui-col button-padding" *ngIf="isFieldVisible(FIELD_CC_TAB_ANONYMIZED)" rgi-rx-button rgiRxValueOption
                  value="{{LimitationEnum.ANONYMIZED}}" data-qa="anonymized" translate>CONTRACT_CONSULTATION.ANONYMIZED</button>
        </rgi-rx-button-toggle>
      </div>

      <!-- POINT OF SALE -->
      <div class="rgi-ui-col" >
        <label rgiRxLabel class="rgi-ui-title-3" for="salePoint" translate>
          CONTRACT_CONSULTATION.POINT_OF_SALE
        </label>
        <div [ngClass]="{'is-invalid': startCardFormGroup.get('salePoint').invalid}">
          <rgi-rx-drop-container id="salePoint" formControlName="salePoint" viewField="description"
                                 select="nodeSelection" [selectData]="state.nodeList" data-qa="salePoint">
            <ng-template rgiRxDropLabel let-ctx>
              <span translate>CONTRACT_CONSULTATION.SELECT_NODE</span>
            </ng-template>
          </rgi-rx-drop-container>
        </div>
      </div>

      <!-- SUBNET -->
      <div class="subnet">
        <input rgiRxInput formControlName="subnet" id="checkbox" type="checkbox" data-qa="subnet">
        <label rgiRxLabel for="checkbox" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.SEARCH_UNDER_SUBNET
        </label>
      </div>

      <!-- WHOLE SALES NETWORK -->
      <div class="subnet">
        <input rgiRxInput formControlName="wholeSalesNetwork" id="checkbox1" type="checkbox" data-qa="wholeSalesNetwork">
        <label rgiRxLabel for="checkbox1" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.WHOLE_SALES_NETWORK
        </label>
      </div>

      <!-- COMPANY -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_COMPANY)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="company" translate>
            CONTRACT_CONSULTATION.COMPANY
          </label>
          <select rgiRxNativeSelect id="company" formControlName="company" data-qa="company">
            <option value=""></option>
            <option *ngFor="let company of state.companyList" [ngValue]="company">{{company.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>

      <!-- INDEPENDENT AGENT -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_INDIPENDENTAGENT) &&
          !(startCardFormGroup.value.type === TypeEnum.COLLECTIVE && startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION) ">
        <rgi-rx-form-field>
          <label rgiRxLabel for="independentAgent" translate>
            CONTRACT_CONSULTATION.INDEPENDENT_AGENT
          </label>
          <select rgiRxNativeSelect id="independentAgent" formControlName="independentAgent" data-qa="independentAgent">
            <option value=""></option>
             <option *ngFor="let agent of independentAgentList" [ngValue]="agent">{{agent.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>

      <!-- BRANCH OFFICE -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_BRANCHOFFICE) &&
            !(startCardFormGroup.value.type === TypeEnum.COLLECTIVE && startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="branchOffice" translate>
            CONTRACT_CONSULTATION.BRANCH_OFFICE
          </label>
          <select rgiRxNativeSelect id="branchOffice" formControlName="branchOffice" data-qa="branchOffice">
            <option value=""></option>
            <option *ngFor="let branch of branchesList" [ngValue]="branch">{{branch.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>

      <!-- CONTRACT TYPE -->
      <div class="rgi-ui-col"  *ngIf="isFieldVisible(FIELD_CC_TAB_CONTRACTTYPE)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="contractType" translate>
            CONTRACT_CONSULTATION.CONTRACT_TYPE
          </label>
          <select rgiRxNativeSelect id="contractType" formControlName="contractType" data-qa="contract" (change)="resetNumberValues()">
            <option value=""></option>
            <option *ngFor="let type of CONTRACT_TYPES" [ngValue]="type.code" translate>CONTRACT_CONSULTATION.{{type.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>

      <!-- PROPOSAL NUMBER -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_PROPOSALNUMBER) && isProposalNumVisible()">
        <rgi-rx-form-field>
          <label *ngIf="startCardFormGroup.value.type === TypeEnum.COLLECTIVE && startCardFormGroup.value.subtype === SubtypeEnum.MASTER_POLICY"
                 rgiRxLabel for="proposalNumber" translate>
            CONTRACT_CONSULTATION.MASTER_PROPOSAL_NUMBER
          </label>
          <label *ngIf="!(startCardFormGroup.value.type === TypeEnum.COLLECTIVE && startCardFormGroup.value.subtype === SubtypeEnum.MASTER_POLICY)"
                 rgiRxLabel for="proposalNumber" translate>
            CONTRACT_CONSULTATION.PROPOSAL_NUMBER
          </label>
          <input rgiRxInput id="proposalNumber" formControlName="proposalNumber" data-qa="proposalNumber">
        </rgi-rx-form-field>
      </div>

      <!-- POLICY NUMBER -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_POLICYNUMBER) && isPolicyNumVisible()">
        <rgi-rx-form-field>
          <label *ngIf="!isMasterPolicyVisible()" rgiRxLabel for="policyNumber" translate>
            CONTRACT_CONSULTATION.POLICY_NUMBER
          </label>
          <label *ngIf="isMasterPolicyVisible()" rgiRxLabel for="policyNumber" translate>
            CONTRACT_CONSULTATION.MASTER_POLICY_NUMBER
          </label>
          <input rgiRxInput id="policyNumber" formControlName="policyNumber" data-qa="policyNumber">
        </rgi-rx-form-field>
      </div>

      <!-- QUOTE NUMBER -->
      <div class="rgi-ui-col" *ngIf="isQuoteNumberVisible() && isFieldVisible(FIELD_CC_QUOTENUMBER)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="quoteNumber" translate>
            CONTRACT_CONSULTATION.QUOTE_NUMBER
          </label>
          <input rgiRxInput id="quoteNumber" formControlName="quoteNumber" data-qa="quoteNumber">
        </rgi-rx-form-field>
      </div>

      <!-- APPLICATION NUMBER -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_APPLICATIONNUMBER) && isApplicationNumberVisible()">
        <rgi-rx-form-field>
          <label rgiRxLabel for="applicationNumber" translate>
            CONTRACT_CONSULTATION.APPLICATION_NUMBER
          </label>
          <input rgiRxInput id="applicationNumber" formControlName="applicationNumber" data-qa="applicationNumber">
        </rgi-rx-form-field>
      </div>

      <!-- PRODUCT TYPE -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_PRODUCTTYPE) && startCardFormGroup.value.subtype !== SubtypeEnum.APPLICATION
            && startCardFormGroup.value.limitation !== LimitationEnum.ANONYMIZED">
        <rgi-rx-form-field>
          <label rgiRxLabel for="productType" translate>
            CONTRACT_CONSULTATION.PRODUCT_TYPE
          </label>
          <select rgiRxNativeSelect id="productType" formControlName="productType" data-qa="productType">
            <option value=""></option>
            <option *ngFor="let productType of productTypeList" [ngValue]="productType">{{productType.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>

      <!-- PRODUCT -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_PRODUCT) && isProductVisible()">
        <rgi-rx-form-field>
          <label rgiRxLabel for="product" translate>
            CONTRACT_CONSULTATION.PRODUCT
          </label>
          <select rgiRxNativeSelect id="product" formControlName="product" data-qa="product">
            <option value=""></option>
            <option *ngFor="let object of productList" [ngValue]="object">{{object.product.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>

      <!-- ISSUE DATE -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_ISSUEDATE)">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>CONTRACT_CONSULTATION.ISSUE_DATE</label>
          <rgi-rx-date-picker [openOnClick]="false">
            <input [rgiRxDateTimeInput]="issueDate" selectMode="range" rangeSeparator="-"
                   formControlName="issueDate" data-qa="issueDate">
          </rgi-rx-date-picker>
          <rgi-rx-date-time #issueDate></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>

      <!-- EFFECTIVE DATE -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_EFFECTIVEDATE)">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>CONTRACT_CONSULTATION.EFFECTIVE_DATE</label>
          <rgi-rx-date-picker [openOnClick]="false">
            <input [rgiRxDateTimeInput]="effectiveDate" selectMode="range" rangeSeparator="-"
                   formControlName="effectiveDate" data-qa="effectiveDate">
          </rgi-rx-date-picker>
          <rgi-rx-date-time #effectiveDate></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>

      <!-- EXPIRATION DATE -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_EXPIRATIONDATE)">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>CONTRACT_CONSULTATION.EXPIRE_DATE</label>
          <rgi-rx-date-picker [openOnClick]="false">
            <input [rgiRxDateTimeInput]="expirationDate"  selectMode="range" rangeSeparator="-"
                   formControlName="expirationDate" data-qa="expirationDate">
          </rgi-rx-date-picker>
          <rgi-rx-date-time #expirationDate></rgi-rx-date-time>
        </rgi-rx-form-field>
      </div>

      <!-- PLATE NUMBER -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_NUMBERPLATE)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="plateNumber" translate>
            CONTRACT_CONSULTATION.PLATE_NUMBER
          </label>
          <input rgiRxInput id="plateNumber" formControlName="plateNumber" data-qa="plateNumber">
        </rgi-rx-form-field>
      </div>

      <!-- SHIP NAME/ IMO CODE -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_SHIPNAME)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="imoShipName" translate>
            CONTRACT_CONSULTATION.SHIP_NAME
          </label>
          <input rgiRxInput id="imoShipName" formControlName="imoShipName" data-qa="imoShipName">
        </rgi-rx-form-field>
      </div>

      <!-- CONTRACT CONDITION -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_CONTRACTCONDITION)">
        <span class="rgi-ui-title-3" translate >CONTRACT_CONSULTATION.CONTRACT_CONDITION</span>
        <rgi-rx-button-toggle class="rgi-ui-col rgi-ui-grid-2" formControlName="condition">
          <button  class="rgi-ui-col button-padding"  rgi-rx-button rgiRxValueOption value="true" data-qa="atPresentDay" translate>CONTRACT_CONSULTATION.AT_PRESENT_DAY</button>
          <button  class="rgi-ui-col button-padding"  rgi-rx-button rgiRxValueOption value="false" data-qa="last" translate>CONTRACT_CONSULTATION.LAST</button>
        </rgi-rx-button-toggle>
      </div>

      <!-- SUBJECT -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_SUBJECT) &&
            startCardFormGroup.value.limitation === LimitationEnum.NOT_ANONYMIZED &&
            startCardFormGroup.value.contractType !== ContractTypeEnum.ANONYMOUS_QUOTE">
        <label rgiRxLabel for="subject" class="rgi-ui-title-3" translate>
          CONTRACT_CONSULTATION.SUBJECT
        </label>
        <rgi-rx-drop-container id="subject" formControlName="subject" viewField="nominative"
                               select="searchOrCreateSubject" [selectData]="getSubjectDropContainerData(roleKeys.rolePolicyHolder)"
                               (onValueChange)="anagSubjectSelected($event)"
                               (onRemove)="removeSubjectFormData()" data-qa="subject">
        </rgi-rx-drop-container>
      </div>

      <!--Subject Roles-->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_SUBJECT) && startCardFormGroup.value.subject">
        <rgi-rx-form-field>
          <label rgiRxLabel for="subjectRoles" translate>CONTRACT_CONSULTATION.SUBJECT_ROLE</label>
          <rgi-rx-multiselect id="subjectRoles" formControlName="subjectRoles">
            <rgi-rx-option *ngFor="let subjectRole of subjectRoles" [value]="subjectRole"
                           [label]="subjectRole.description">{{subjectRole.description}}
            </rgi-rx-option>
          </rgi-rx-multiselect>
        </rgi-rx-form-field>
      </div>

      <!-- CONTRACT STATES -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_CONTRACTSTATES)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="contractState" translate>CONTRACT_CONSULTATION.CONTRACT_STATES</label>
          <rgi-rx-multiselect id="contractState" formControlName="contractState" data-qa="contractState">
            <rgi-rx-option *ngFor="let state of contractStates" [value]="state"
                           [label]="state.description">{{state.description}}
            </rgi-rx-option>
          </rgi-rx-multiselect>
        </rgi-rx-form-field>
      </div>

      <!-- CONTRACT SUBSTATES -->
      <div class="rgi-ui-col" *ngIf="isFieldVisible(FIELD_CC_CONTRACTSUBSTATES)">
        <rgi-rx-form-field>
          <label rgiRxLabel for="contractSubstate" translate>CONTRACT_CONSULTATION.CONTRACT_SUBSTATES</label>
          <rgi-rx-multiselect id="contractSubstate" formControlName="contractSubstate" data-qa="contractSubstate">
            <rgi-rx-option *ngFor="let substate of contractSubstates" [value]="substate"
                           [label]="substate.description">{{substate.description}}
            </rgi-rx-option>
          </rgi-rx-multiselect>
        </rgi-rx-form-field>
      </div>


    </div>

    <!-- ERRORS -->
    <div class="rgi-ui-col">
      <rgi-rx-snackbar tag="consultationErrorMessage"></rgi-rx-snackbar>
    </div>

    <!-- BUTTONS GROUP -->
    <div class="rgi-ui-col rgi-ui-grid-no-gutter" [ngClass]="startCardFormGroup.value.contractBranch !== ContractBranchEnum.ALL ? 'rgi-ui-grid-4' : 'rgi-ui-grid-3'">
      <div class="rgi-ui-col">
        <!-- CLEAR -->
        <button rgi-rx-button (click)="emptyForm()" color="secondary" class="cc-btn" data-qa="clear" translate>
          CONTRACT_CONSULTATION.EMPTY
        </button>
      </div>
      <div class="rgi-ui-col">
        <!-- FIND -->
        <button rgi-rx-button color="primary" class="cc-btn"
                data-qa="next" (click)="findPolicy()"
                [disabled]="startCardFormGroup.invalid" translate>
          CONTRACT_CONSULTATION.FIND
        </button>
      </div>
      <div class="rgi-ui-col">
        <!-- REPORT -->
        <button rgi-rx-button color="secondary" class="cc-btn"
                data-qa="report" (click)="openReportModal()" translate>
          CONTRACT_CONSULTATION.REPORT
        </button>
      </div>
      <div class="rgi-ui-col" *ngIf="startCardFormGroup.value.contractBranch !== ContractBranchEnum.ALL && actionButtonVisibility">
        <!-- ACTIONS -->
        <button rgi-rx-button color="primary" class="cc-btn"
                data-qa="actions" (click)="openActionsModal()" translate>
          CONTRACT_CONSULTATION.ACTIONS
        </button>
      </div>
    </div>
  </form>
</div>

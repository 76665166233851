import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AnagFlowData } from '@rgi/anag';
import { ActiveRoute } from '@rgi/rx/router';
import { ModalService, RgiRxOnContainerValueChange } from '@rgi/rx/ui';
import { CheckPartyComplete } from '../../../../models/domain-models/check-party-complete';
import { AnagIssueSubjectBusinessService } from '../../../re-issue-anag/anag-issue-service';
import { ReIssueMsgData } from '../../../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import { ReIssueGeneralDataResourceService } from '../../../re-issue-resources/re-issue-general-data-resource.service';
import { ReIssueMsgModalComponent } from '../../../re-issue-msg-modal/re-issue-msg-modal.component';
import { AnagIssueSubject } from '../../../re-issue-anag/anag-issue';
import { ReIssuePolicyDataConstants } from '../../../../re-issue-constants/re-issue-policy-data-constants';
import { Bond } from '../../../../models/domain-models/bond';
import { PushMessage, PushMessageHandlerService } from '@rgi/rx';


@Component({
  selector: 're-issue-bond-item',
  templateUrl: './re-issue-bond-item.component.html',
  styleUrls: ['./re-issue-bond-item.component.css']
})
export class ReIssueBondItemComponent implements OnChanges {
  // Other component properties and methods...

  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Input() editMode: boolean = false;
  @Input() selectedBinding: boolean = false;
  @Input() productId: number;
  @Output() subjectSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  subjectSelected = false;
  newBondForm: UntypedFormGroup;
  showNewBond: boolean = false;
  partyRole: string;
  roleKeys: any;
  bond: Bond;
  TAG_DATES_ERROR = 'form_date_errors';
  TAG_TYPE_LIEN_ERROR = 'form_type_lien_errors';
  constructor(private fb: UntypedFormBuilder, public activateRoute: ActiveRoute,
    protected subjectBusinessService: AnagIssueSubjectBusinessService,
    protected generalDataResourceService: ReIssueGeneralDataResourceService,
    public modalService: ModalService, protected errorService: PushMessageHandlerService,) {
    this.newBondForm = this.fb.group({
      description: [''],
      effectDate: ['', Validators.required],
      expiryDate: ['', Validators.required],
      bodyBinding: [, Validators.required],
    });
    this.roleKeys = ReIssuePolicyDataConstants;
  }

  getSubjectDropContainerData(role) {
    const data = new AnagFlowData();
    data.partyRole = '' + role;
    this.partyRole = data.partyRole;
    data.idParentSession = this.activateRoute.id;
    // only legal party (1 = fisical; 2 = legal
    data.partyType = '2';
    return data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedBinding) {
      // Check if the 'selectedBinding' input has changed
      const newSelectedBinding = changes.selectedBinding.currentValue;
      if (newSelectedBinding !== null) {
        this.newBondForm.patchValue(newSelectedBinding);
      }
    }
  }

  notifyErrorMessage(tag: string, content: string) {
    const errorMsg = new PushMessage();
    errorMsg.tag = tag;
    errorMsg.status = 'danger';
    errorMsg.dismissible = false;
    errorMsg.options = { icon: 'rgi-ui-icon-alert' };
    errorMsg.content = content;
    this.errorService.notify(errorMsg);
  }

  saveNewBinding() {
    this.clearTags();
    const formData = this.newBondForm.value;
    if (formData.effectDate > formData.expiryDate) {
      this.notifyErrorMessage(this.TAG_DATES_ERROR, "RE_ISSUE.ERROR_MESSAGE_DATES")
    } else if (formData.bodyBinding.personType.codice === "1") {
      this.notifyErrorMessage(this.TAG_TYPE_LIEN_ERROR, "RE_ISSUE.ERROR_MESSAGE_TYPE_LIEN")
    } else {
      this.onSave.emit(formData);
      this.newBondForm.reset();
    }

  }

  private clearTags() {
    this.errorService.clearTag(this.TAG_DATES_ERROR);
    this.errorService.clearTag(this.TAG_TYPE_LIEN_ERROR);
  }

  cancelNewBinding() {
    this.clearTags();
    this.onCancel.emit();
    this.newBondForm.reset();
  }

  anagSubjectSelected(val: RgiRxOnContainerValueChange) {
    if (val !== null) {
      this.subjectBusinessService.getSubject(val.changed.objectId, val.changed.node.identification)
        .subscribe(subj => {
          const c: CheckPartyComplete = new CheckPartyComplete(
            subj.idLatestPhotos,
            this.partyRole,
            this.productId.toString(),
            subj.node.identification
          );

          this.generalDataResourceService.checkPartyComplete(c)
            .subscribe(
              (val2) => {
                if (val2.outcome) {
                  const msgData = new ReIssueMsgData();
                  msgData.msg = val2.outcome[0];
                  msgData.showButtonContinue = false;
                  const confirmationModal = this.modalService.openComponent(ReIssueMsgModalComponent, msgData);

                  confirmationModal.modal.onClose.subscribe(() => {
                    this.subjectSelected = false;
                    this.newBondForm.controls.bodyBinding.reset();
                  });
                } else {
                  this.clearTags();
                  this.subjectSelected = true;
                  this.patchSubjectDataAndValidateForm(subj);
                  this.subjectSelectedEmitter.emit(subj);
                }
              },
              error => {
                this.showErrorModal(error, subj);
              }
            );
        });
    } else {
      this.subjectSelected = false;
      this.subjectSelectedEmitter.emit(val);
    }
  }

  showErrorModal(error, subj) {
    const msgData = new ReIssueMsgData();
    msgData.msg = error.violations[0].message;
    const violationLevel = error.violations[0].level;
    if (violationLevel === 'WARNING') {
      msgData.showButtonConfirm = true;
    } else if (violationLevel === 'BLOCKING') {
      msgData.showButtonConfirm = false;
      this.resetPolicyholderValues();
    }
    const confirmationModal = this.modalService.openComponent(ReIssueMsgModalComponent, msgData);
    confirmationModal.modal.onClose.subscribe((confirm) => {
      if (!confirm && violationLevel !== 'BLOCKING') {
        this.resetPolicyholderValues();
      }
      if (confirm && violationLevel !== 'BLOCKING') {
        this.subjectSelected = true;
        this.patchSubjectDataAndValidateForm(subj);
        this.subjectSelectedEmitter.emit(subj);
      }
    });
  }

  private resetPolicyholderValues() {
    this.removeSubjectFormData();
    this.subjectSelected = false;
    this.newBondForm.controls.bodyBinding.reset();
  }

  patchSubjectDataAndValidateForm(subject: AnagIssueSubject) {
    subject ? this.addSubjectFormData(subject) : this.removeSubjectFormData();
    this.newBondForm.updateValueAndValidity();
  }
  addSubjectFormData(subject: AnagIssueSubject) {
    this.newBondForm.patchValue({
      bodyBinding: subject,

    });

  }
  removeSubjectFormData() {

  }


}



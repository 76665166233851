import {Inject, Injectable} from '@angular/core';
import {MicHttpService} from './http/mic-http.service';
import {Observable} from 'rxjs';
import {MicHttpDisableErrorInterceptorService} from './http/mic-http-disable-error-interceptor.service';
import {ApiApplicationInclusionData} from './models/api-models/api-master-policy';

@Injectable({
  providedIn: 'root'
})
export class MasterPolicyService {

  private baseApiUrl: string;
  private baseApiUrlV2: string;

  constructor(protected micHttpClient: MicHttpService,
              @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';
  }

  includeApplication(masterPolicyProposalNumber: string, masterpolicyRef: ApiApplicationInclusionData): Observable<any> {
    const headers = MicHttpDisableErrorInterceptorService.getErrorInterceptorDisable();
    return this.micHttpClient.getHttpClient().post(`${this.baseApiUrlV2}/motor/master-policy/${masterPolicyProposalNumber}/application`,
      masterpolicyRef,
      {headers}
    );
  }

}

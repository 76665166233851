import {AfterContentInit, Directive, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, Renderer2, ViewContainerRef} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import {LoadingIndicatorService} from '@rgi/rx';
import {DOCUMENT} from '@angular/common';


@Directive({
  selector: '[rgiRxAnimatedLoading]',
  providers: []
})
export class AnimatedLoadingDirective implements OnInit, AfterContentInit, OnDestroy {
  private host: any;
  private display: string;
  private subscription: Subscription = Subscription.EMPTY;
  private originFocusedElement?: HTMLElement;


  constructor(private viewContainerRef: ViewContainerRef,
              private renderer: Renderer2,
              @Optional() @Inject(DOCUMENT) private document?: any) {
  }

  /**
   * (optional) An observable of type Observable<boolean>
   *  if no observable reference its passed, an instance of LoadingIndicatorService
   *  is expected
   */
  @Input('rgiRxAnimatedLoading') trigger?: Observable<boolean>;
  /**
   * The class to be used for the loader
   * default rgi-ui-loader
   */
  @Input('loaderClass') class = 'rgi-ui-loader';
  /**
   * A delay for the activation
   * default 0
   */
  @Input('delay') delay = 0;

  /**
   * Restore the focused element after loading has ended
   */

  @Input() restoreFocus = true;

  /**
   * Emitted each time the loader has completed
   */
  @Output() loaded = new EventEmitter<void>();


  ngOnInit(): void {
    this.trigger = this.trigger ? this.trigger : this.viewContainerRef.injector.get(LoadingIndicatorService).hasPending$();
  }

  ngAfterContentInit(): void {
    this.originFocusedElement = this.document ? this.document.activeElement as HTMLElement : undefined;
    const nativeElement: HTMLElement = this.viewContainerRef.element.nativeElement;
    this.display = nativeElement.style.display;
    this.host = this.renderer.createElement('div');
    this.renderer.setAttribute(this.host, 'rgiRxLoaderHost', '');
    this.renderer.insertBefore(nativeElement.parentNode, this.host, nativeElement);


    this.subscription = this.trigger
      .pipe(
        tap((trigger) => {
          if (!!trigger) {
            this.originFocusedElement = this.document ? this.document.activeElement as HTMLElement : undefined;
            this.renderer.setStyle(nativeElement, 'display', 'none');
            this.renderer.addClass(this.host, this.class);
          }
        }),
        delay(this.delay),
        tap((trigger) => {
          if (!trigger) {
            this.renderer.removeClass(this.host, this.class);
            this.renderer.setStyle(nativeElement, 'display', this.display);
            if (this.restoreFocus && this.originFocusedElement) {
              this.originFocusedElement.focus();
            }
            this.loaded.emit();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (!!this.host) {
      this.renderer.removeChild(this.viewContainerRef.element.nativeElement.parentNode, this.host);
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Warning } from '../../resources/models/warning';
import {Observable, of} from 'rxjs';
import {TableSchema} from "@rgi/rx/ui";
import {AuthWarning} from "../../resources/models/auth-warning";

@Component({
  selector: 'ac-auth-messages',
  templateUrl: './auth-messages.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class AuthMessagesComponent implements OnInit {

  @Input()  warnings = Array<AuthWarning>();

  public warningsTableData: Observable<any[]>;

  constructor() { }

  ngOnInit() {
    this.warningsTableData = of(this.warnings.map(warning => {

      return {
        id: warning.id,
        level: warning.level,
        error: warning.error,
        description: warning.type.description,
      };
    }));
  }

  WARNINGGS_LIST_TABLE_SCHEMA: TableSchema = {
    rows: [
      {
        name: 'level',
        title: '_AUTH_._BLOCK'
      },
      {
        name: 'error',
        title: '_AUTH_._ERROR'
      },
      {
        name: 'description',
        title: '_AUTH_._TYPE'
      }
    ],
    header: ['level', 'error', 'description']
  };

  getDetailModel(applicationRow: any) {
    return this.warnings.find(event => event.id === applicationRow.id);
  }

}

<div *ngIf="!!onInitEnd" class="example-row summary-page" id="summary-page">
  <div class="table-summary panel-body">
    <div class="core-header-icon ">
      <span class="header-icon rgifont rgi-dettagli_tecnici"></span>
      <span class="header-text" attr.data-qa="dati-generali" translate>lic_general_data_label</span>
    </div>
    <pnc-dynamic-table [rows]="rowsGeneralData" [objectMap]="generalDataMap" ></pnc-dynamic-table>
  </div>

  <div class=" table-summary panel-body">
    <div class="core-header-icon ">
      <span class="header-icon rgifont rgi-riparti_coass"></span>
      <span class="header-text" translate>lic_Parties</span>
      <span class="pull-right header-text">  </span>
    </div>
    <pnc-dynamic-table [sectionTitle]="('lic_holders_title' | translate)" [rows]="rowsSubject" [objectMap]="accountHoldersMap" ></pnc-dynamic-table>
    <iiab-mic-proposal-otp [portfolio]="'Life'" ></iiab-mic-proposal-otp>
  </div>
  <div class="table-summary panel-body" *ngIf="effectiveHoldersMap.size !== 0">
    <pnc-dynamic-table [sectionTitle]="('lic_effective_holder' | translate)" [rows]="rowsEffectiveHolders" [objectMap]="effectiveHoldersMap" ></pnc-dynamic-table>
  </div>
  <div class="table-summary panel-body">
    <pnc-dynamic-table [sectionTitle]="('lic_beneficiaries_label' | translate)" [rows]="rowsBeneficiaries" [objectMap]="beneficiariesMap" ></pnc-dynamic-table>
  </div>
  <br><br>

  <!-- Accordion Premi -->
  <pnc-accordion #accordion activeIds="panel-2">
    <pnc-panel class='panel-item' id="panel-premium" >
      <ng-template let-isOpen="isOpen" class='panel-title' pncPanelTitle>
        <span class="rgifont" [class.rgi-plus]="!isOpen" [class.rgi-minus]="isOpen"></span>
        <span data-qa="panel-button" class="rgifont"></span>
        <span class="header-icon rgifont rgi-pagamenti"></span>
        <span class="header-text" translate>lic_premium_label</span>
        <span class="pull-right header-text"></span>
      </ng-template>
      <ng-template class="panel-container" pncPanelContent>
        <div class="panel-body">
          <lic-premium-table
            [primaRata]="primaRata" [policyPremium]="policyPremium"
            [ifNotSingleFrequency]=" getPaymentFrequencyCode()"
            [damagesInstalment]="damagesInstalment"
            [damageRisk]="damageRisk">
          </lic-premium-table>
        </div>
      </ng-template>
    </pnc-panel>
  </pnc-accordion>

  <!-- Accordion Garanzie -->
  <pnc-accordion #accordion activeIds="panel-2">
    <pnc-panel class='panel-item' id="panel-garanzie" >
      <ng-template let-isOpen="isOpen" class='panel-title' pncPanelTitle>
        <span class="rgifont" [class.rgi-plus]="!isOpen" [class.rgi-minus]="isOpen"></span>
        <span data-qa="panel-button" class="rgifont"></span>
        <span class="header-icon rgifont rgi-clausole"></span>
        <span class="header-text" translate>lic_covers</span>
        <span class="pull-right header-text"></span>
      </ng-template>
      <ng-template class="panel-container" pncPanelContent>
        <div *ngFor="let titolo of titoloUnit; let i = index" class="table-summary panel-body">
          <pnc-dynamic-table [sectionTitle]="titolo" [rows]="countNumOfRows(dynamicTableUnit[i])" [objectMap]="arrayUnitContainer[i]" ></pnc-dynamic-table>
          <br>
        </div>
      </ng-template>
    </pnc-panel>
  </pnc-accordion>



















  <rgi-rx-snackbar tag="warning"></rgi-rx-snackbar>
  <rgi-rx-snackbar tag="auth"></rgi-rx-snackbar>

  <div class="btn-group btn-group-justified">
    <div class="row">
      <button [ngClass]="{'col-sm-6 col-xs-12': (isSaveAndAcceptAuthProp && isFromAuth()) || 2 === flowButtons.length,
        'col-sm-4 col-xs-12': 3 === flowButtons.length,
        'col-sm-12 col-xs-12': !isSaveAndAcceptAuthProp ||  1 === flowButtons.length,
        'btn-secondary': 0 !== i%2}"
              *ngFor="let item of flowButtons; let i = index"
              type="submit"
              [disabled]="item.disabled || isError"
              (click)="onSubmit(item, proposalSaveModal)"
              [attr.data-qa]="item.code + '-button'"
              class="btn btn-warning pull-right text-uppercase"
              translate>
        {{ item.label }}
      </button>
    </div>
  </div>

  
  <rgi-rx-modal #proposalSaveModal>
    <rgi-rx-panel>
      <rgi-rx-panel-header [closeable]="true" (onClose)="proposalSaveModal.close()">{{'lic_NumeroProposta' | translate}}</rgi-rx-panel-header>
      <rgi-rx-panel-footer>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="proposalSaveModal.close()" translate>lic_close</button>
      </rgi-rx-panel-footer>
      <span translate>lic_NumeroProposta</span> {{temporaryProposalNumber}}
    </rgi-rx-panel>
  </rgi-rx-modal>

  <!-- Dialog per il salvataggio intermedio della proposta -->
  <rgi-rx-modal #proposalSaveModal>
    <rgi-rx-panel>
      <rgi-rx-panel-header [closeable]="true" (onClose)="proposalSaveModal.close()">{{'lic_NumeroProposta' | translate}}</rgi-rx-panel-header>
      <rgi-rx-panel-footer>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="proposalSaveModal.close()" translate>lic_close</button>
      </rgi-rx-panel-footer>
      <span translate>lic_NumeroProposta</span> {{temporaryProposalNumber}}
    </rgi-rx-panel>
  </rgi-rx-modal>


</div>

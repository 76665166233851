import {TableSchema} from '@rgi/rx/ui';

export const TAX_CLASSIFICATIONS_HISTORY_LIST_MODAL_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'typeDescr',
      title: '_ANAG_._LABEL_._TYPE_',
    },
    {
      name: 'countryDescr',
      title: '_ANAG_._LABEL_._COUNTRY_'
    },
    {
      name: 'countryStartDate',
      title: '_ANAG_._LABEL_._COUNTRY_START_DATE_',
      format: {
        pipe: 'date',
        args: ['dd/MM/yyyy']
      }
    },
    {
      name: 'countryEndDate',
      title: '_ANAG_._LABEL_._COUNTRY_END_DATE_',
      format: {
        pipe: 'date',
        args: ['dd/MM/yyyy']
      }
    }
  ],
  header: ['typeDescr', 'countryDescr', 'countryStartDate', 'countryEndDate' ]
};

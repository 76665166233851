export const NOTIFY_ON_CLOSE_AFTER_PUBLISH = 'on-close-after-publish';
export const NOTIFY_CLOSE_BUY_QUOTE = 'on-close-buy-quote';
export const NOTIFY_AFTER_PUBLISH = 'after-publish';
export const LIFE_PREVENTIVE_OK = 'LIFE_PREVENTIVE_OK';
export const LIFE_ISSUE_PROPOSAL_OK = 'LIFE_ISSUE_PROPOSAL_OK';


export class Notify {
  id: string;

  constructor(id: string) {
    this.id = id;
  }
}


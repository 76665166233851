export class SalesNode {
  displayValue: string;
  description: string;
  constructor(agency: Agency) {
    this.displayValue = agency.idSp;
    this.description = agency.description;
  }
}

export interface Agency {
  channel: any;
  code: string;
  description: string;
  extension: any;
  idSp: string;
  level: string;
  parentNode: ParentNodeObj;
}

export interface ParentNodeObj {
  code: string;
  description: string;
  identification: string;
}

import {Injectable} from '@angular/core';

/**
 * @deprecated Ie11 non supportato da Angular 13
 */
@Injectable({
  providedIn: 'root'
})
export class Ie11UtilityService {

  constructor() {
  }

  isIE11(): boolean {
    let isIE11;

    if (navigator.appVersion.toString().indexOf('.NET') < 0) {
      isIE11 = false;
    } else {
      isIE11 = true;
    }

    return isIE11;
  }

  ie11FixDateString(dateValue: any): any {
    let result = dateValue;

    if (typeof dateValue === 'string') {
      result = dateValue.replace(/([+-]\d{2})(\d{2})$/g, '$1:$2');
    }

    return result;
  }

  printIE11File(file) {
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(file);
    // }
  }
}

export enum BeneficiaryRole {
    ESECUTORE_DEL_BENEFICIARIO = '300019',
    EFFETTIVO_PERCIPIENTE = '300020',
    TITOLARE_EFFETTIVO_DEL_PERCIPIENTE = '300021',
    TITOLARE_EFFETTIVO_DEL_BENEFICIARIO = '300018'
}

export enum BeneficiaryCathegory {
  VITA = '1',
  MORTE = '2',
  CEDOLE = '3'
}

export enum BeneficiaryCathegoryCH {
  MORTE_3A = '4',
  MORTE_3B = '5'
}

export enum BeneficiaryType {
  PHISICAL = '1',
  GIURIDICAL = '2',
  BOTH = '3',
  PHYSICAL_ADULT = '4',
  PHYSICAL_UNDERAGE = '5'
}

export enum BeneficiaryChoiceType {
  ENUM_ETIPOSCELTA_SOGGETTO_ANAGRAFICO = '_ALTRO',
  EREDI_LEGITTIMI_O_TESTAMENTARI = '000001'
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toPercentage'})
export class ToPercentage implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '-';
    }
    const percentage = value * 100;
    let percentageStr = percentage.toFixed(2);
    percentageStr = percentageStr.replace('.', ',');
    return `${percentageStr} %`;
  }
}

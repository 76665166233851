<div #premiumElement class="quotation-controls quotation-controls2"
     [ngClass]="{'fix-to-top': isPremiumElementFixedInPage}">
  <div class="quotation-top-control center-block">
    <div>
      <div class="pull-left hidden-xs">
        <div class="box-premium pull-left">
          <span class="title-premium" translate>lic_first_instalment<br></span>
          <div class="mrg-top" *ngIf="premiumInstallment">
            <!-- <span class="premium-quick-summary-label">&euro;</span> -->
            <span class="currency-premium" id="premio-firma">{{getPremiumInstalmentGross() | rgiCountryLayerNumberFormat : locale: optionCurrency}}</span>
          </div>
          <div>
            <span class="currency-premium currency-premium2 bold" *ngIf="!premiumInstallment">--</span>
          </div>
        </div>
        <div class="sep pull-left hidden-sm ">&nbsp;</div>
        <div class="box-premium pull-left">
          <span class="title-premium" id="premioAnnuo" translate>lic_annual_premium<br></span>
          <div class="mrg-top" *ngIf="annualPremiumValue">
            <!-- <span class="premium-quick-summary-label">&euro;</span> -->
            <span class="currency-premium" id="premio-annuo">{{getAnnualPremiumGross() | rgiCountryLayerNumberFormat : locale: optionCurrency}}</span>
          </div>
          <div>
            <span class="currency-premium currency-premium2 bold" *ngIf="!annualPremiumValue">--</span>
          </div>
        </div>
      </div>

      <div class="pull-right btn-toolbar btn-quotation-box nmf_cont_button_menunit">
        <div class="btn-group">
          <button class="btn btn-style btn-warning recalculate-btn" (click)="recalculatePremium($event)"
                  [disabled]="!recalculateAvailable" data-qa="recalculate">
            <span class="hidden-xs hidden-sm text-uppercase" translate>lic_quote_button</span>
            <span class="controls-icon visible-xs visible-sm rgifont rgi-refresh"></span>
          </button>
        </div>

        <!--<div class="btn-group">
          <button
            id="details"
            class="btn btn-default btn-quotation-detail"
            (click)="openDetail($event)"
            [disabled]="!isQuotationDone()">
            <span class="hidden-xs hidden-sm" translate>DETAILS</span>
            <span class="controls-icon visible-xs visible-sm rgifont rgi-info"></span>
          </button>

          <button
            id="unit"
            class="btn btn-default btn-quotation-detail nmf_button_headerecalculate"
            (click)="openGarDetail($event)"
            [disabled]="!isQuotationDone()" data-qa="riepilogo-garanzie">
            <span id="img-unit" class=" rgifont rgi-profili"></span>
          </button>

          <button
            id="commission"
            class="btn btn-default btn-quotation-detail nmf_button_headerecalculate"
            (click)="openProv($event)"
            [disabled]="!isQuotationDone() || disableProv">
            <span class="rgifont rgi-percent"></span>
          </button>


          <button
            id="components"
            class="btn btn-default btn-quotation-detail nmf_button_headerecalculate"
            (click)="openFormule($event)"
            [disabled]="!isQuotationDone() || disableFormule">
            <span class="rgifont rgi-view_square"></span>
          </button>

        </div>-->
        <div class="btn-group">
          <button
            class="btn btn-default btn-quotation-detail nmf_button_headerecalculate"
            (click)="openFormule($event)"
            *ngIf="!(!isQuotationDone() || disableFormule)">
            <span class="rgifont rgi-pencil"></span>
          </button>
        </div>

        <div ngbDropdown class="dropdown">
          <button class="btn btn-outline-primary dropdown-button" id="dropdownBasic2" ngbDropdownToggle
                  [disabled]="!isQuotationDone()" translate>lic_view</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <button ngbDropdownItem (click)="openDetail($event)" translate>lic_premium_detail</button>
            <button ngbDropdownItem (click)="openGarDetail($event)" translate>lic_guarantee_summary</button>
            <button ngbDropdownItem (click)="openProv($event)" *ngIf="!disableProv" translate>lic_commissions</button>
            <button ngbDropdownItem (click)="openCommonRisk($event)" *ngIf="disabledCommonRisk" translate>lic_common_risk</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

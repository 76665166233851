import { Component, Inject, Input, Optional } from '@angular/core';
import { ReservationSubjects } from '../../../../models/life-detail.model';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../../services/currency-cache.service';

@Component({
  selector: 'lpc-lb-subjects',
  templateUrl: './lb-subjects.component.html',
})
export class LbSubjectsComponent {
  // formatter options
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService){}
  @Input() beneficiaries: ReservationSubjects[];
}

import { TableSchema } from "@rgi/rx/ui";

// export const schemaTableSearch: TableSchema = {
//     rows: [
//       {
//         name: 'claimNumberPass',
//         title: '_CLAIMS_._CLAIM',
//       },
//       {
//         name: 'lossDate',
//         title: '_CLAIMS_._SX_DATE',
//         format: {
//           pipe: 'date',
//           args: ['shortDate']
//         }
//       },
//       {
//         name: 'potClaimNumberPass',
//         title:'_CLAIMS_._DIALOGUE',
//       },
//       {
//         name: 'licensePlateUS',
//         title: '_CLAIMS_._US_CAR_LICENSE_PLATE',
//       },
//       {
//         name: 'licensePlateOther',
//         title: '_CLAIMS_._CTP_CAR_LICENSE_PLATE'
//       },
//       {
//         name: 'insuranceCodeOther',
//         title: '_CLAIMS_._CTP_COMPANY'
//       },
//       {
//         name: 'nameParty',
//         title: '_CLAIMS_._NOMINATIVE'
//       },
//       {
//         name: 'startDate',
//         title: '_CLAIMS_._DIALOGUE_DATE_START',
//         format: {
//           pipe: 'date',
//           args: ['shortDate']
//         }
//       },
//       {
//         name: 'endDate',
//         title: '_CLAIMS_._END_DATE',
//         format: {
//           pipe: 'date',
//           args: ['shortDate']
//         }
//       },
//       {
//         name: 'errorMsgCode',
//         title: '_CLAIMS_._ERROR_MESSAGE'
//       },
//       {
//         name: 'actions',
//         title: '',
//         actions: [
//           {
//             name: 'detail',
//           },
//         ]
//       }
//     ],
//     header: ['claimNumberPass', 'lossDate', 'potClaimNumberPass',
//       'licensePlateUS', 'licensePlateOther', 'insuranceCodeOther',
//       'nameParty', 'startDate', 'endDate', 'errorMsgCode',
//       'actions']
//   };

// export const schemaTableAss: TableSchema = {
// rows: [
//     {
//     name: 'lossDate',
//     title: '_CLAIMS_._SX_DATE',
//     format: {
//         pipe: 'date',
//         args: ['shortDate']
//     }
//     },
//     {
//     name: 'potClaimNumberPass',
//     title: '_CLAIMS_._DIALOGUE',
//     },
//     {
//     name: 'licensePlateOther',
//     title: '_CLAIMS_._CTP_CAR_LICENSE_PLATE',
//     },
//     {
//     name: 'insuranceCodeOther',
//     title: '_CLAIMS_._CTP_COMPANY',
//     },
//     {
//     name: 'nameParty',
//     title: '_CLAIMS_._NOMINATIVE',
//     },
//     {
//     name: 'startDate',
//     title: '_CLAIMS_._DIALOGUE_DATE_START',
//     format: {
//         pipe: 'date',
//         args: ['shortDate']
//     }
//     },
//     {
//     name: 'endDate',
//     title: '_CLAIMS_._END_DATE',
//     format: {
//         pipe: 'date',
//         args: ['shortDate']
//     }
//     },
//     {
//     name: 'actions',
//     title: '',
//     actions: [
//         {
//         name: 'detail',
//         },
//     ]
//     }
// ],
// header: ['lossDate', 'potClaimNumberPass', 'licensePlateOther',
//     'insuranceCodeOther', 'nameParty',
//     'startDate', 'endDate', 'actions']
// };

// export const schemaTableNotAss: TableSchema = {
//     rows: [
//       {
//         name: 'claimNumberPass',
//         title: '_CLAIMS_._COMPLAINT',
//       },
//       {
//         name: 'licensePlateUS',
//         title: '_CLAIMS_._US_CAR_LICENSE_PLATE',
//       },
//       {
//         name: 'lossDate',
//         title: '_CLAIMS_._SX_DATE',
//         format: {
//           pipe: 'date',
//           args: ['shortDate']
//         }
//       },
//       {
//         name: 'potClaimNumberPass',
//         title: '_CLAIMS_._DIALOGUE',
//       },
//       {
//         name: 'licensePlateOther',
//         title: '_CLAIMS_._CTP_CAR_LICENSE_PLATE',
//       },
//       {
//         name: 'insuranceCodeOther',
//         title: '_CLAIMS_._CTP_COMPANY',
//       },
//       {
//         name: 'nameParty',
//         title: '_CLAIMS_._NOMINATIVE',
//       },
//       {
//         name: 'startDate',
//         title: '_CLAIMS_._DIALOGUE_DATE_START',
//         format: {
//           pipe: 'date',
//           args: ['shortDate']
//         }
//       },
//       {
//         name: 'endDate',
//         title: '_CLAIMS_._END_DATE',
//         format: {
//           pipe: 'date',
//           args: ['shortDate']
//         }
//       },
//       {
//         name: 'actions',
//         title: '',
//         actions: [
//           {
//             name: 'detail',
//           },
//         ]
//       }
//     ],
//     header: ['claimNumberPass', 'licensePlateUS',
//       'lossDate', 'potClaimNumberPass', 'licensePlateOther',
//       'insuranceCodeOther', 'nameParty',
//       'startDate', 'endDate', 'actions']
//   };

  export const schemaTableSx: TableSchema = {
    rows: [
      {
        name: 'functionCode',
        title: '_CLAIMS_._FUNCTION',
      },
      {
        name: 'lastMsgCode',
        title: '_CLAIMS_._LST_MSG_CODE',
      },
      {
        name: 'lastMsgReqData',
        title: '_CLAIMS_._LST_MES_REQ_DATA',
        format: {
          pipe: 'date',
          args: ['shortDate']
        }
      },
      {
        name: 'lastMsgRegExpire',
        title: '_CLAIMS_._LST_MES_REG_EXP',
        format: {
          pipe: 'date',
          args: ['shortDate']
        }
      },
      {
        name: 'lossDate',
        title: '_CLAIMS_._START_DATE',
        format: {
          pipe: 'date',
          args: ['shortDate']
        }
      },
      {
        name: 'roleCompany',
        title: '_CLAIMS_._ROLE',
      },
      {
        name: 'licensePlateUS',
        title: '_CLAIMS_._US_CAR_LICENSE_PLATE',
      },
      {
        name: 'licensePlateOther',
        title: '_CLAIMS_._CTP_CAR_LICENSE_PLATE',
      },
      {
        name: 'insuranceCodeOther',
        title: '_CLAIMS_._CTP_COMPANY',
      },
      {
        name: 'internalKey',
        title: '_CLAIMS_._INTERNAL_KEY',
      },
      {
        name: 'lastRespMsgCode',
        title: '_CLAIMS_._LST_RESP_MES_CODE',
      },
      {
        name: 'actions',
        title: '',
      },
    ],
    header: ['functionCode','lastMsgCode','lastMsgReqData','lastMsgRegExpire','lossDate','roleCompany',
    'licensePlateUS','licensePlateOther','insuranceCodeOther','internalKey','lastRespMsgCode','actions']
  }

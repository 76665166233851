import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'pnc-advanced-select',
  templateUrl: './advanced-select.component.html',
  styleUrls: ['./advanced-select.component.css']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class AdvancedSelectComponent implements OnInit {

  @Input() customControlName: string;
  @Input() customForm: UntypedFormGroup;
  @Input() customLabel: string;
  @Input() customValue: string;
  @Input() compulsory: boolean;
  @Input() selectedValue: any;
  @Input() enableAutoSelect: boolean;


  options: any[];

  @Output() changeVal = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  onChangeAction(event) {
    console.log(event);
    this.changeVal.emit(event);
  }

  @Input()
  set optionList(opts: any[]) {
    this.options = opts;

    if (this.enableAutoSelect) {
      if (this.options.length === 1 && this.compulsory) {
        this.selectedValue = this.options[0][this.customValue];
        console.log('ho un elemento obbligatorio e lo setto');
        this.customForm.get(this.customControlName).disable();
        console.log(this.customForm);
      }

      let find = false;
      this.options.map((opt) => {
        if (opt[this.customValue] === this.selectedValue) {
          find = true;
        }
      });

      if (!find) {
        this.selectedValue = null;
      }
    }
  }

  get optionList(): any[] { return this.options; }



}

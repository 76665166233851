<div class="box-detail" *ngIf="contractDetails?.confPm">
  <header class="box-detail-header" translate>_GP_._TITLE_._APPLICATION_CONFIGURATION_</header>
  <div class="box-detail-body">
    <div class="rgi-gp-w-100" *ngIf="contractDetails.confPm.applicationData?.length">
      <rgi-rx-expansion-panel>
        <rgi-rx-expansion-panel-header>
          <label translate>_GP_._LABEL_._DATIAP_</label>
        </rgi-rx-expansion-panel-header>
        <div class="rgi-ui-expansion-panel-content ">
          <div class="box-detail-internal-panel">
            <div *ngFor="let element of contractDetails.confPm.applicationData" class="rgi-gp-w-50">
              {{element.label}}: <b>{{element.value}}</b>
            </div>
          </div>
        </div>
      </rgi-rx-expansion-panel>
    </div>
    <div class="rgi-gp-w-100" *ngIf="contractDetails.confPm.executionBatch?.length">
      <rgi-rx-expansion-panel>
        <rgi-rx-expansion-panel-header>
          <label translate>_GP_._LABEL_._BATCH_EXECUTION_</label>
        </rgi-rx-expansion-panel-header>
        <div class="rgi-ui-expansion-panel-content ">
          <div class="box-detail-internal-panel">
            <div *ngFor="let element of contractDetails.confPm.executionBatch" class="rgi-gp-w-50">
              {{element.label}}: <b>{{element.value}}</b>
            </div>
          </div>
        </div>
      </rgi-rx-expansion-panel>
    </div>
    <div class="rgi-gp-w-100" *ngIf="contractDetails.confPm.regulation?.length">
      <rgi-rx-expansion-panel>
        <rgi-rx-expansion-panel-header>
          <label translate>_GP_._LABEL_._REGULATION_</label>
        </rgi-rx-expansion-panel-header>
        <div class="rgi-ui-expansion-panel-content ">
          <div class="box-detail-internal-panel">
            <div *ngFor="let element of contractDetails.confPm.regulation" class="rgi-gp-w-50">
              {{element.label}}: <b>{{element.value}}</b>
            </div>
          </div>
        </div>
      </rgi-rx-expansion-panel>
    </div>
    <div class="rgi-gp-w-100" *ngIf="contractDetails.confPm.tarifManagement?.length">
      <rgi-rx-expansion-panel>
        <rgi-rx-expansion-panel-header>
          <label translate>_GP_._LABEL_._TARIFF_MANAGEMENT_</label>
        </rgi-rx-expansion-panel-header>
        <div class="rgi-ui-expansion-panel-content ">
          <div class="box-detail-internal-panel">
            <div *ngFor="let element of contractDetails.confPm.tarifManagement" class="rgi-gp-w-50">
              {{element.label}}: <b>{{element.value}}</b>
            </div>
          </div>
        </div>
      </rgi-rx-expansion-panel>
    </div>
    <div class="rgi-gp-w-100" *ngIf="contractDetails.confPm.techAcc?.length">
      <rgi-rx-expansion-panel>
        <rgi-rx-expansion-panel-header>
          <label translate>_GP_._LABEL_._TECH_ACC_</label>
        </rgi-rx-expansion-panel-header>
        <div class="rgi-ui-expansion-panel-content ">
          <div class="box-detail-internal-panel">
            <div *ngFor="let element of contractDetails.confPm.techAcc" class="rgi-gp-w-50">
              {{element.label}}: <b>{{element.value}}</b>
            </div>
          </div>
        </div>
      </rgi-rx-expansion-panel>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {AnagIssueSubject} from '../components/consultation-anag/consultation-anag';
import {AnagIssueSubjectResourceService} from './resource/consultation-anag-resource';


@Injectable({
  providedIn: 'root'
})
export class ConsultationAnagSubjectBusinessService {

  constructor(private subjectResourceService: AnagIssueSubjectResourceService) {
  }

  getSubject(subjectId: string, nodeId: string): Observable<AnagIssueSubject> {
    return this.subjectResourceService.getSubject(
      subjectId,
      nodeId
    ).pipe(
      share(),
      map(resSubj => {
        resSubj.fiscalCode = this.getFiscalCode(resSubj);
        return resSubj;
      })
    );
  }

  public getFiscalCode(subject: AnagIssueSubject): string {
    let result = subject.fiscalCode;
    if (!
        result
      && subject.partyKey
      && subject.partyKey[0]
      && subject.partyKey[0].key1
      && subject.partyKey[0].key1.type
      && subject.partyKey[0].key1.type.codice === '1'
    ) {
      result = subject.partyKey[0].key1.value;
    }
    return result ? result : '';
  }
}

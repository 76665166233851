
export class InputPremium {
  inputPremium: number;
  codeType: string;
  net: string;
  gross: string;
  taxable: string;
  unitCode: string;
  assetInstanceKey: string;

  constructor() {

  }

}

<ng-container *ngIf="(stateMgr.getState$() | async) as state">
  <div style="min-height: 45vh">
    <rgi-rx-stepper [showIndex]="true" [selectedIndex]="ctx.currentStep" [vertical]="false">
      <rgi-rx-step *ngFor="let step of steps; index as i" [label]="step" [selectable]="false">
        <ng-template rgiRxStepContent>
          <div style="padding-top: 2vh">
            <ng-container *ngComponentOutlet="ctx.viewComponent"></ng-container>
          </div>
        </ng-template>
      </rgi-rx-step>
    </rgi-rx-stepper>
  </div>

  <div class="rgi-ui-grid-1">
    <rgi-rx-snackbar class="rgi-ui-col" [tag]="activeRoute.route"></rgi-rx-snackbar>
  </div>
  <rgi-rx-panel-footer>
    <ng-container *ngFor="let btn of state.footerBtns">
      <button rgi-rx-button *ngIf="btn.visible"
              [color]="btn.color"
              (click)="onAction(btn.action)"
              [disabled]="btn.disabled"
              [attr.data-qa]="btn.label">{{btn.label | translate}}
      </button>
    </ng-container>
  </rgi-rx-panel-footer>
</ng-container>

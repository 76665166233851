import {ChangeDetectorRef, Component, Inject, Optional} from '@angular/core';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifierService} from '@rgi/portal-ng-core';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { ToolManagerComponent } from '../tool-manager/tool-manager.component';
import { AuthService } from '../../services/auth.service';
import { AnagService } from '../../services/anag.service';
import { ToolDefinition } from '../../modules/lpc-tools/model/tool-definition';
import { EMPTY_STR, ERROR_TYPE_CURRENT, PV_TOKEN, SEVERITY_ERROR_BLOCKING } from '../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-delete-tool',
  templateUrl: './delete-tool.component.html',
  styleUrls: ['./delete-tool.component.css']
})
export class DeleteToolComponent extends ToolManagerComponent {

  protected operationDataKey: string;

  public validateUntouchedForm(step: string) {
    this.$feErrors = [];
    const toolStatus = Object.values(this.formGroup.get('tools').value);
    if (step === 'tools' && toolStatus.every(tool => !tool)) {
      const msg = this.translate.getImmediate('lpc_nessuna_variazione');
      this.$feErrors.push(
        {
          context: 'tools',
          errorId: EMPTY_STR,
          errorMessage: msg,
          severity: SEVERITY_ERROR_BLOCKING,
          type: ERROR_TYPE_CURRENT
        });
    } else {
      this.$feErrors = [];
      this.$errors = [];
    }
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authorizationService: AuthService,
    protected anag: AnagService,
    @Optional() @Inject(PV_TOKEN.TOOLS_HIDE_ENUMS) protected hideEnumWithOneValue: boolean
    ) {
      super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
        plcQuestService, authorizationService, anag, hideEnumWithOneValue);
  }

/*   protected getTransformedOperationData(): any {
    const selectedTools: any[] = [];
    Object.keys(this.formGroup.get('tools').value).forEach(key => {
      if (this.formGroup.get('tools').value[key]) {
        const id: string = key.substr(5);
        const toolDefinition: ToolDefinition = this.toolDefinitions.find(td => td.id === id);
        if (!!toolDefinition) {
          selectedTools.push({
            id,
            toolName: toolDefinition.toolName,
            operationCodeId: toolDefinition.operationCodeId
          });
        }
      }
    });
    return selectedTools;
  } */

  showSummary(tool: ToolDefinition): boolean {
    return this.isActiveStep('tools') ||
    !this.isActiveStep('tools') &&
    !!this.formGroup.get('tools') &&
    !!this.formGroup.get('tools').get('tool-' + tool.id) &&
    !!this.formGroup.get('tools').get('tool-' + tool.id).value;
  }

}

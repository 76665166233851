<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  
  <lpc-step id="{{ADVANCE_PAYMENT_STEP.DATE.id}}" label="{{ADVANCE_PAYMENT_STEP.DATE.label}}" [errors]="errors" fieldId="{{ADVANCE_PAYMENT_STEP.DATE.fieldId}}" (next)="onNext($event)">
    <lpc-dates-step
      [inputFields]="dateDefinitions"
      [formControlName]="'dates'"
      (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="{{ADVANCE_PAYMENT_STEP.POLICY_FACTORS.id}}" *ngIf="hasProductFactors" label="{{ADVANCE_PAYMENT_STEP.POLICY_FACTORS.label}}" [errors]="errors" fieldId="{{ADVANCE_PAYMENT_STEP.POLICY_FACTORS.fieldId}}" (next)="onNext($event)">
    <div formGroupName="{{ADVANCE_PAYMENT_STEP.POLICY_FACTORS.formName}}" class="row mb-3">
      <lpc-factor-adapter #factorAdapter
        (changeValEmitter)="updateProductFactors($event)"
        [form]="formGroup.controls[ADVANCE_PAYMENT_STEP.POLICY_FACTORS.formName]"
        [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
      <div *ngIf="advancePaymentData" class="mb-2">
        <rgi-rx-expansion-panel *ngIf="activeStep <= 1; else advancePaymentResume" >
          <div rgi-rx-expansion-panel-header>
            <div class="row">
              <span translate>lpc_PIP_AdvancedPayment</span>
            </div>
          </div>
          <ng-container rgiRxExpansionPanelContent >
            <lpc-data-grid [headers]="advancePaymentHeaders" [data]="advancePaymentData" [dataQa]="'advancePaymentData'"></lpc-data-grid>
          </ng-container>
        </rgi-rx-expansion-panel>
      </div>
      <div *ngIf="advancePaymentTransferData">
        <rgi-rx-expansion-panel *ngIf="activeStep <= 1; else advancePaymentTransferResume" class="mb-2" >
          <div rgi-rx-expansion-panel-header>
            <div class="row">
              <span translate>lpc_advancePaymentTransfer</span>
            </div>
          </div>
          <ng-container rgiRxExpansionPanelContent >
            <lpc-data-grid [headers]="advancePaymentTransferHeaders" [data]="advancePaymentTransferData" [dataQa]="'advancePaymentTransfer'"></lpc-data-grid>
          </ng-container>
        </rgi-rx-expansion-panel>
      </div>
      <ng-template #advancePaymentResume>
        <div class="row">
          <div class="col-md-6">{{'lpc_advancePaymentTransfer'}}: <span translate>lpc_filledOut</span></div>
        </div>
      </ng-template>
      <ng-template #advancePaymentTransferResume>
        <div class="row">
          <div class="col-md-6" translate>{{'lpc_advancePaymentTransfer'}}: <span translate>lpc_filledOut</span></div>
        </div>
      </ng-template>
  </lpc-step>

  <lpc-step
    id="{{ADVANCE_PAYMENT_STEP.QUESTS_UNIT.id}}" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')"
    fieldId="{{ADVANCE_PAYMENT_STEP.QUESTS_UNIT.fieldId}}" label="{{ADVANCE_PAYMENT_STEP.QUESTS_UNIT.label}}" (next)="onNext($event)">
    <lpc-questionnaire
        [active]="!isAfterId(ADVANCE_PAYMENT_STEP.QUESTS_UNIT.id)" [key]="composeKey(key, ADVANCE_PAYMENT_STEP.QUESTS_UNIT.id)"
        [questionnairesCode]="getQuestMap('FIN')" type="FIN"
        [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
        [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
        (loaderQuest)="loaderQuestManager($event)"
        (loaded)="countQuest($event)"
        #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step id="{{ADVANCE_PAYMENT_STEP.ASSET_FACTORS.id}}" *ngIf="hasAssetFactors" label="{{ADVANCE_PAYMENT_STEP.ASSET_FACTORS.label}}" [errors]="errors" fieldId="{{ADVANCE_PAYMENT_STEP.ASSET_FACTORS.fieldId}}" (next)="onNext($event)">
    <div formGroupName="{{ADVANCE_PAYMENT_STEP.ASSET_FACTORS.formName}}" class="row">
      <lpc-factor-adapter #factorAdapter
        (changeValEmitter)="updateAssetFactors($event)"
        [form]="formGroup.controls[ADVANCE_PAYMENT_STEP.ASSET_FACTORS.formName]"
        [factors]="listGoodFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step *ngIf="showDisinvestment && !!formGroup.get(ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.formName).value" [errors]="errors"  id="{{ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.id}}" label="{{ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.label}}"
    [jump]="skipDisinvestment" [nextVisible]="formGroup.get(ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.formName).valid" fieldId="{{ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.fieldId}}" (next)="onNext($event)">
    <lpc-karma-disinvestment-funds
            [investmentMode]="getInvestmentInsertMode()"
            *ngIf="!isAfterId(ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.id)"
            formControlName="{{ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.formName}}"
            [totalAmount]="totalAmountToInvest"
            [definition]="disinvestmentProfiles"
            [investedProfiles]="investedProfiles"
            [checksOnOverallAlloc]="false">
    </lpc-karma-disinvestment-funds>
    <div *ngIf="isAfterId(ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.id)">
      <ul>
        <li [attr.data-qa]="profile.id + 'info_investment_profile'" *ngFor="let profile of selectedDisinvestmentProfiles">
          <span for="label">{{ profile.description }} </span>
          <span
            for="label">{{ getProfileAmount(profile.id.toString()).toString() | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}
          </span>
          <span for="value">({{ getProfilePercent(profile.id.toString()).toString() | rgiCountryLayerNumberFormat: currencyService.locale: percentFormatterOptions }})</span>
        </li>
      </ul>
    </div>
  </lpc-step>

  <lpc-step id={{ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.id}} [errors]="errors"  [jump]="skipDisinvestment" *ngIf="!!disinvestmentProfiles.length && !!formGroup.get(ADVANCE_PAYMENT_STEP.PROFILE_DISININVESTMENT.formName).value"
    label="lpc_investment_funds" [nextVisible]="formGroup.get(ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.formName).valid" fieldId={{ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.fieldId}}
    (next)="onNext($event)">
    <div formGroupName={{ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.formName}} *ngIf="!isAfterId(ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.id)">
      
    <div rgi-rx-accordion [multi]="true" *ngFor="let profile of selectedDisinvestmentProfiles">
      <rgi-rx-expansion-panel [expanded]="true" color="default">
        <div rgi-rx-expansion-panel-header>
          <span class="accordion-text" translate>{{ profile.description }}</span>
        </div>
        <div content data-qa="panel-button">
          <lpc-karma-disinvestment-funds
            [investmentMode]="getInvestmentInsertMode()"
            [formControlName]="profile.id.toString()"
            [definition]="profile.funds"
            [investedProfiles]="getInvestedFundsByProfileId(profile.id)"
            [totalAmount]="getDisinvestmentAmountWithProfileId(profile.id)"
            [checksOnOverallAlloc]="false"
            [isprofile]="false"
            >
          </lpc-karma-disinvestment-funds>
        </div>
      </rgi-rx-expansion-panel>
    </div>
    </div>
    <div *ngIf="isAfterId(ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.id)">
      <lpc-karma-fund-table
        [profiles]="disinvestmentProfiles"
        [totalAmount]="totalAmountToInvest"
        [enableOpenListDefault]="true">
      </lpc-karma-fund-table>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <lpc-volatility-button
          *ngIf="isWithVolatility() && formGroup.get(ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.id).valid && formGroup.get(ADVANCE_PAYMENT_STEP.FUND_DISININVESTMENT.id).touched  || isWithVolatility() && skipDisinvestment"
          [volatility]="volatility" [disabled]="!!volatility" (calculate)="onCalculateVolatility()">
        </lpc-volatility-button>
      </div>
    </div>
  </lpc-step>

  <lpc-step id="{{ADVANCE_PAYMENT_STEP.QUOTATION.id}}" [enableAuth]="isAuth" label="{{ADVANCE_PAYMENT_STEP.QUOTATION.label}}" [errors]="errors" fieldId="{{ADVANCE_PAYMENT_STEP.QUOTATION.fieldId}}" (next)="onNext($event, true)">
    <div formGroupName="{{ADVANCE_PAYMENT_STEP.QUOTATION.formName}}" class="row">
      <div *ngFor="let insuredSettlement of insuredSettlements">
        <p *ngIf="!!insuredSettlement.insured && insuredSettlement.insured.length > 1;else singleInsured" class="insured">
          <label translate>lpc_Assicurati</label>
          <label>: {{ insuredSettlement.insured[0] + ' - ' + insuredSettlement.insured[1] }}</label>
        </p>
        <ng-template #singleInsured>
          <p class="insured">
            <label translate>lpc_Assicurato</label>
            <label>: {{ insuredSettlement.insured[0] }}</label>
          </p>
        </ng-template>
        <lpc-definition-table
          [label]="'lpc_life_policy'"
          [definitions]="tableDefinitions"
          [rows]="insuredSettlement.settlement">
        </lpc-definition-table>
        <br>
      </div>
    </div>
  </lpc-step>

  <lpc-step
        id="{{ADVANCE_PAYMENT_STEP.BENFICIARIES.id}}" [enableAuth]="isAuth" label="{{ADVANCE_PAYMENT_STEP.BENFICIARIES.label}}"
        [errors]="errors" fieldId="{{ADVANCE_PAYMENT_STEP.BENFICIARIES.fieldId}}"
        (next)="onNext($event, false, ADVANCE_PAYMENT_STEP.BENFICIARIES.id)" [feErrors]="feErrors"
        feFieldId="{{ADVANCE_PAYMENT_STEP.BENFICIARIES.fieldId}}" (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-claim-beneficiaries
      formControlName="{{ADVANCE_PAYMENT_STEP.BENFICIARIES.formName}}" [paymentTypes]="paymentTypes"
      [beneficiaryType]="beneficiaryType"
      [idAssicurato]="idAssicurato" [summary]="!isActiveStep(ADVANCE_PAYMENT_STEP.BENFICIARIES.id)"
      [questionnairesCode]="getUnfilteredQuestMap('IST')"
      [questionnairesDefinitions]="questionnaireDefinitions"
      [blockBeneficiaries]="blockBeneficiaries"
      (addedSubject)="onAddedSubject($event)"
      (triggerQuestPreval)="onTriggerQuestPreval($event)"
      (loaderQuest)="loaderQuestManager($event)"
      [questFactorsArray]="questFactorsArray"
      [disabledQuestionArray]="disabledQuestionArray"
      [validateMandatoryBeneficiary]="isMandatoryBeneficiaries"
      #beneficiaries >
    </lpc-claim-beneficiaries>
  </lpc-step>

  <lpc-step
        *ngIf="isAuth || !!errors && !!errors.length" id="{{ADVANCE_PAYMENT_STEP.WARNING.id}}" label="{{ADVANCE_PAYMENT_STEP.WARNING.label}}" (next)="publish()"
        [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
        (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages
        *ngIf="!!errors"
        [errors]="errors">
    </lpc-warning-messages>
  </lpc-step>


  <lpc-step label="{{ADVANCE_PAYMENT_STEP.DOCUMENT.label}}" [errors]="errors" fieldId="{{ADVANCE_PAYMENT_STEP.DOCUMENT.fieldId}}" id="{{ADVANCE_PAYMENT_STEP.DOCUMENT.id}}" [nextVisible]=false>
    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep(ADVANCE_PAYMENT_STEP.DOCUMENT.id)">
  <lpc-navigation-buttons
    (back)="closeCard()"
    [nextVisible]="false"
    [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiCountryLayerCurrencyFormatDirective} from './rgi-country-layer-currency-format.directive';
import {RgiCountryLayerCurrencyFormatPipe} from './rgi-country-layer-symbol-format.pipe';


@NgModule({
  declarations: [
    RgiCountryLayerCurrencyFormatDirective,
    RgiCountryLayerCurrencyFormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RgiCountryLayerCurrencyFormatDirective,
    RgiCountryLayerCurrencyFormatPipe
  ]
})
export class RgiCountryLayerCurrencyModule { }

import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NodeSearchModalComponent} from './node-search-modal/node-search-modal-component';
import {DocumentsComponent} from './documents/documents.component';
import {
    DocumentUploadFileModalComponent
} from './documents/document-upload-file-modal/document-upload-file-modal.component';
import {
    DocumentValidationFileModalComponent
} from './documents/document-validation-file-modal/document-validation-file-modal.component';
import {DocumentAddModalComponent} from './documents/document-add-modal/document-add-modal.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {PORTAL_NG_COMMON_TRANSLATIONS} from './i18n/translations';

@NgModule({
    declarations: [
        NodeSearchModalComponent,
        DocumentsComponent,
        DocumentUploadFileModalComponent,
        DocumentValidationFileModalComponent,
        DocumentAddModalComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(PORTAL_NG_COMMON_TRANSLATIONS)
    ],
    exports: [
        NodeSearchModalComponent,
        DocumentsComponent,
        DocumentUploadFileModalComponent,
        DocumentValidationFileModalComponent,
        DocumentAddModalComponent
    ],
    providers: [
        { provide: 'documentUploadFileModalComponent', useValue: DocumentUploadFileModalComponent }
    ]
})
/**
 * @deprecated
 */
export class PortalNgCommonModule {
}

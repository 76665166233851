
import { Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import { Instalment } from '../../models/postsales-operations-response.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Quotation } from '../lpc-quotation/model/quotation.model';


@Component({
  selector: 'lpc-quotation-detail-modal',
  templateUrl: './lpc-quotation-detail-modal.component.html',
  styleUrls: ['./lpc-quotation-detail-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LpcQuotationDetailModalComponent implements OnInit {

  @Input() firstInstalment: Instalment;
  @Input() nextInstalment: Instalment;
  @Input() annualPremium: Instalment;
  @Input() quotationData: Quotation;
  @Input() showFirstInstallment = true;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }
}

import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalService} from '@rgi/rx/ui';
import {StepperSection} from '../../models/stepper-section';
import {
  ReIssueQuotationStateManagerService
} from '../re-issue-state-manager/re-issue-quotation-state-manager.service';
import {
  ReIssueQuotationDetailModalComponent
} from './re-issue-quotation-modal/re-issue-quotation-detail-modal/re-issue-quotation-detail-modal.component';
import {
  ReIssueWarrantyModalComponent
} from './re-issue-quotation-modal/re-issue-warranty-modal/re-issue-warranty-modal.component';
import {AssetInstance} from '../../models/domain-models/asset-instance';
import {
  ReIssueContributionTaxesModalComponent
} from './re-issue-quotation-modal/re-issue-contribution-taxes-modal/re-issue-contribution-taxes-modal.component';
import {Message} from '../../models/message';
import {map, mergeMap, take} from 'rxjs/operators';
import {Asset} from '../../models/domain-models/asset';
import {AssetSection} from '../../models/domain-models/asset-section';
import {PaymentFrequency} from '../../models/api-models/api-payment-frequency';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {of} from 'rxjs';
import {Currency} from '../../models/domain-models/parameters/currency';
import {
  ReIssueDiscountModalComponent
} from './re-issue-quotation-modal/re-issue-discount-modal/re-issue-discount-modal.component';
import {
  ReIssueCommissionModalComponent
} from './re-issue-quotation-modal/re-issue-commission-modal/re-issue-commission-modal.component';
import {
  ReIssueFpsTaxesModalComponent
} from './re-issue-quotation-modal/re-issue-fps-taxes-modal/re-issue-fps-taxes-modal.component';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {
  CommissionDetail,
  CommissionInstalment,
  CommissionsWrap,
  PNC_POSTSALES_COMMISSION_TYPE
} from '../../models/domain-models/parameters/commission-detail';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {ReIssueNotesComponent} from './re-issue-notes/re-issue-notes.component';
import {Notes} from '../../models/domain-models/notes';
import {ERROR_TAG_SNACKBAR} from '../../models/pc-portfolio-models/GenericErrorPcPortfolio';
import {
  FlexDiscountParam
} from '../../models/pc-portfolio-models/discounts-models/flex-discount-param';
import {FlexDiscountUnit} from '../../models/pc-portfolio-models/discounts-models/flex-discount-unit';
import {ParametersService} from './re-issue-parameters/parameters.service';
import {ReIssueQuotationState} from '../re-issue-state-manager/re-issue-quotation.state';
import {PcClause} from "../../models/pc-portfolio-models/quotation-models/pc-clause";
import {Variable} from "../../models/domain-models/variable";

@Component({
  selector: 'rgi-re-issue-quotation',
  templateUrl: './re-issue-quotation.component.html',
  styleUrls: ['./re-issue-quotation.component.css'],
})
export class ReIssueQuotationComponent implements OnInit {

  currentStep = StepperSection.quotation;
  stepperLabels = ['RE_ISSUE.POLICY_DATA', 'RE_ISSUE.ASSETS_DATA', 'RE_ISSUE.QUOTATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.ISSUE_STEPPER'];
  policyContactMessages: string;
  submitted = false;
  packetsErrors: boolean;
  currency: Currency;

  disableNext: boolean;

  validationMessages: any;
  isOpen = false;

  premium: any;
  viewUnitsBox: any;
  addressData: AddressApiContacts[] = [];
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter();
  isUnique: boolean;
  beneficiaryForm: UntypedFormGroup;
  errorMessage: string;
  validationError = [];
  beneficiaryErrorMessage: string;
  isPacketContract: boolean;

  @ViewChild(ReIssueNotesComponent) notesComponent: ReIssueNotesComponent;

  constructor(
    public stateManager: ReIssueQuotationStateManagerService<ReIssueQuotationState>,
    protected customModalService: ModalService,
    protected translate: RgiRxTranslationService,
    public pushMessageHandler: PushMessageHandlerService,
    protected fb: UntypedFormBuilder,
    private translateService: RgiRxTranslationService,
  ) {
    this.beneficiaryForm = this.fb.group({
      beneficiary: this.fb.array([])
    });
  }

  ngOnInit() {
    this.clearAllMessages();
    this.validateWarningMessages();
    this.checkBeneficiaryValidity(null);
    this.setTranslations();

    this.stateManager.getState$().pipe(
      take(1),
      map(st => {
        if (st.quotationReady) {
          // UNIT PACKETS MANAGEMENT
          const instances = st.assetInstances;
          this.currency = st.policyTechnicalData.selectedCurrency;
          const instWithPackets = instances.find(i => i.asset.package && i.asset.package.length > 0);
          this.isPacketContract = !!instWithPackets;
          if (this.isPacketContract) {
            this.checkPacketsValidity();
          }
        }
      })
    ).subscribe();
  }

  checkPacketsValidity() {
    const state = this.stateManager.getCurrentState();
    state.assetInstances.forEach(instance => {
      const pSelected = instance.asset.package.find(p => p.selected === true);
      if (!pSelected && this.isPacketContract) {
        this.checkPackets(false);
        this.packetsErrors = true;
      } else {
        this.packetsErrors = false;
      }
    });
  }

  clearAllMessages() {
    const tags = ['validationError', 'policyContactErrors', 'warnings', 'notBlockingWarnings', 'beneficiaryErrors', 'packetErrors'];
    tags.forEach(tag => {
      this.pushMessageHandler.clearTag(tag);
    });
  }

  quotation() {
    const state = this.stateManager.getCurrentState();
    if (state.assetInstances.find(instance => !!instance.asset.selectedPackage)) {
      this.calculatePremiumAndPackets();
    } else {
      this.calculatePremium();
    }
  }

  calculatePremium() {
    const lpsData = this.stateManager.getCurrentState().lpsData;

    if (lpsData.policyLpsData.lpsType !== '4' && lpsData.policyLpsData.lpsType !== '0') {
      this.stateManager.putLpsData(this.stateManager.getCurrentState().lpsData).subscribe(() => {
        this.recalculatePremium();
      });
    } else {
      this.recalculatePremium();
    }
  }

  recalculatePremium() {
    this.stateManager.recalculatePut().pipe(take(1)).subscribe(st => {
      this.validateWarningMessages();
    });
    this.disabilitaRicalcola();
  }

  recalculatePremiumForDiscountsModal(component: ReIssueDiscountModalComponent) {
    this.stateManager.recalculatePut().pipe(take(1)).subscribe(st => {
      this.validateWarningMessages();
      this.setDiscountsModalData(component);
      component.reloadForm();
    });
    this.disabilitaRicalcola();
  }

  calculatePremiumAndPackets() {
    this.stateManager.getCurrentState().assetInstances.forEach(assetInstance => {
      const sections = assetInstance.asset.factors;
      const packs = assetInstance.asset.package;
      packs.forEach(packet => {
        packet.units.forEach(packUnit => {
          // Get section unit
          const section = sections.find(s => s.code === packUnit.section.code);
          const unit = section.unitList.find(u => u.code === packUnit.code);
          // Copy factors in unit package
          packUnit.factors = unit.factors;
        });
      });
      this.stateManager.putPackage(this.stateManager.getCurrentState(),
        this.stateManager.getCurrentState().resourceId, assetInstance.asset.key, assetInstance.asset.package
      ).subscribe(
        state1 => {
          if (state1.assetInstances[0].asset.selectedPackage.selected === false) {
            state1.assetInstances[0].asset.selectedPackage = undefined;
          }
          this.stateManager.updateState$(of(state1));
          this.calculatePremium();
        }
      );
    });
  }

  validateWarningMessages() {
    this.stateManager.getState$().pipe(take(1)).subscribe(st => {
      // CLEAR MESSAGES
      const warnings = 'warnings';
      this.pushMessageHandler.clearTag(warnings);
      const notBlockingWarnings = 'notBlockingWarnings';
      this.pushMessageHandler.clearTag(notBlockingWarnings);
      const blockingWarnings = 'blockingWarnings';
      this.pushMessageHandler.clearTag(blockingWarnings);

      // QUOTATION WARNINGS
      if (st.quotation.notificationsPP && st.quotation.notificationsPP.length) {
        st.quotation.notificationsPP
          .filter(msg => !st.nonBlockingWarnings.find(warning=>warning.description==msg))
          .filter(msg => !st.warnings.find(warning=>warning.description==msg))
          .forEach((notification => {
          const msg: RgiRxPushMessage = new RgiRxPushMessage();
          const opts = {icon: 'rgi-ui-icon-alert'};
          msg.tag = warnings;
          msg.status = 'danger';
          msg.content = notification;
          msg.dismissible = false;
          msg.options = opts;
          this.pushMessageHandler.notify(msg);
        }));
      }

      // WARNINGS
      if (st.warnings && st.warnings.length) {
        st.warnings.forEach((warning => {
          const msg: RgiRxPushMessage = new RgiRxPushMessage();
          const opts = {icon: 'rgi-ui-icon-alert'};
          msg.tag = warnings;
          msg.status = 'danger';
          msg.content = warning.description;
          msg.dismissible = false;
          msg.options = opts;
          this.pushMessageHandler.notify(msg);
        }));
      }

      // NOT BLOCKING WARNINGS
      if (st.nonBlockingWarnings && st.nonBlockingWarnings.length) {
        st.nonBlockingWarnings.forEach((warning => {
          const msg: RgiRxPushMessage = new RgiRxPushMessage();
          const opts = {icon: 'rgi-ui-icon-alert'};
          msg.tag = warnings;
          msg.status = 'default';
          msg.content = warning.description;
          msg.dismissible = false;
          msg.options = opts;
          this.pushMessageHandler.notify(msg);
        }));
      }
    });
  }

  onUnitsViewModeChange(event: any) {
    if (event === 'BOX') {
      this.viewUnitsBox = true;
    } else if (event === 'LIST') {
      this.viewUnitsBox = false;
    }
  }

  openPremiumDetailsModal() {
    const {modal, component} = this.customModalService.openComponent(ReIssueQuotationDetailModalComponent);
    const state = this.stateManager.getCurrentState();

    component.premiumInstallment = state.quotation.premium.productPremium.instalment.premium;
    component.annualPremium = state.quotation.premium.productPremium.annual.premium;
    component.premiumSubscriptionInstalment = state.quotation.premium.productPremium.subscriptionInstalment.premium;
    component.currency = this.currency;
    modal.onClose.subscribe(() => {

    });
  }

  openContributionsAndTaxesModal() {
    const state = this.stateManager.getCurrentState();
    const {modal, component} = this.customModalService.openComponent(ReIssueContributionTaxesModalComponent);
    component.premiumInstallmentGroup = state.quotation.premium.productPremium.instalment;
    component.annualPremiumGroup = state.quotation.premium.productPremium.annual;
    component.premiumSubscriptionInstalmentGroup = state.quotation.premium.productPremium.subscriptionInstalment;
    component.currency = this.currency;
    component.lpsData = state.lpsData;
    component.lpsDataOutput.subscribe((lpsDataOutput: LpsData) => {
      this.stateManager.putLpsData(lpsDataOutput).subscribe(() => {
          this.quotation();
          this.stateManager.getState$().subscribe((st) => {
            component.updatePremiumInstallmentGroup(st.quotation.premium.productPremium.instalment);
            component.updateAnnualPremiumGroup(st.quotation.premium.productPremium.annual);
            component.updatePremiumSubscriptionInstalmentGroup(st.quotation.premium.productPremium.subscriptionInstalment);
          });
        }
      );
    });
    modal.onClose.subscribe(() => {
    });
  }

  openFPSTaxesModal() {
    const {modal, component} = this.customModalService.openComponent(ReIssueFpsTaxesModalComponent);
    const state = this.stateManager.getCurrentState();
    component.premiumInstallmentGroup = state.quotation.premium.productPremium.instalment;
    component.premiumSubscriptionInstalmentGroup = state.quotation.premium.productPremium.subscriptionInstalment;
    modal.onClose.subscribe(() => {
    });
  }

  openCommissionsModal() {
    const state = this.stateManager.getCurrentState();
    this.stateManager.getProposalCommissions(state.resourceId).subscribe(commissionWrap => {
      const {modal, component} = this.customModalService.openComponent(ReIssueCommissionModalComponent);
      this.setCommissionsModalData(component, commissionWrap);
      component.currency = this.currency;
      component.calculate.subscribe((commissionInputEvent) => {
        this.reloadCommissions(commissionInputEvent, component);
      });

      modal.onClose.subscribe(() => {
      });
    });

  }

  openWarrantiesSummaryModal() {
    const {modal, component} = this.customModalService.openComponent(ReIssueWarrantyModalComponent);
    const state = this.stateManager.getCurrentState();
    component.allAsset = state.assetInstances;
    component.assetsQuotation = state.quotation.assetList;
    component.currency = this.currency;
    modal.onClose.subscribe(() => {
    });
  }

  openDiscountsModal() {

    const {modal, component} = this.customModalService.openComponent(ReIssueDiscountModalComponent);
    this.setDiscountsModalData(component);
    component.calculateDiscountOnTotal.subscribe((discount: FlexDiscountParam) => {
      this.reloadDiscountOnTotal(discount, component);
    });

    component.calculateDiscountOnSingleUnits.subscribe((flexDiscounts: FlexDiscountUnit[]) => {
      this.reloadDiscountOnSingleUnits(flexDiscounts, component);
    });

    modal.onClose.subscribe( () => {
      this.validateWarningMessages();
    });
  }

  private setDiscountsModalData(component: ReIssueDiscountModalComponent) {
    const state = this.stateManager.getCurrentState();
    const annualPremium = state.quotation.premium.productPremium.annual.premium;
    component.currency = this.currency;
    component.allAssets = state.assetInstances;
    component.quotationAssets =  state.quotation.assetList;
    component.totalGrossPremium = annualPremium.gross;
    component.flexDiscountConfig = state.quotation.flexDiscountConfig;
    component.flexDiscounts = annualPremium.flexDiscounts;
    component.discountsAmountShowed = false;
    this.stateManager.getDiscountFund().subscribe(results => {
      component.discountsFund = results;
      component.discountsAmountShowed = true;
    });
  }


  changeRecalculateAvailable(value: boolean) {

    if (value) {
      this.abilitaRicalcola();
    } else {
      this.disabilitaRicalcola();
    }
  }


  private abilitaRicalcola() {
    const state = this.stateManager.getCurrentState();
    state.quotation.isRecalculate = true;
    this.stateManager.updateState$(of(state));
  }

  private setCommissionsModalData(component: ReIssueCommissionModalComponent, commissionsWrap: CommissionsWrap) {
    const subHeader = new CommissionDetail();
    subHeader.subscriptionInstalment = new CommissionInstalment();
    subHeader.nextInstalment = new CommissionInstalment();
    const rightsRow = new CommissionDetail();
    rightsRow.subscriptionInstalment = new CommissionInstalment();
    rightsRow.nextInstalment = new CommissionInstalment();
    const totals = commissionsWrap.commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.TOTALS);
    const rights = commissionsWrap.commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.ADM_FEES);
    let amountLabel = '';
    this.translateService.translate('RE_ISSUE.QUOT_COMMISSIONS.LABEL.AMOUNT').subscribe(label => {
      amountLabel = label;
    }).unsubscribe();
    this.setSubHeaders(subHeader.subscriptionInstalment, amountLabel);
    this.setSubHeaders(subHeader.nextInstalment, amountLabel);

    component.commissions = [subHeader].concat(
      commissionsWrap.commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.RISK));
    if (!!totals && totals[0]) {
      component.commissionTotal = totals[0];
    }
    if (!!rights && rights[0] && rights[0].subscriptionFees) {
      this.translateService.translate('RE_ISSUE.QUOT_COMMISSIONS.LABEL.RIGHTS').subscribe(label => {
        rightsRow.risk = new GenericEntity('', '', label);
      }).unsubscribe();
      rightsRow.subscriptionInstalment.netCollectionCommissions = rights[0].subscriptionFees.netAdministrationFee;
      rightsRow.subscriptionInstalment.netPercCollectionCommissions = rights[0].subscriptionFees.percAdministrationFee;
      rightsRow.nextInstalment.netCollectionCommissions = rights[0].nextInstalmentFees.netAdministrationFee;
      rightsRow.nextInstalment.netPercCollectionCommissions = rights[0].nextInstalmentFees.percAdministrationFee;
      rightsRow.commissionType = PNC_POSTSALES_COMMISSION_TYPE.ADM_FEES;
      component.commissions.push(rightsRow);
    }
    component.acquiredCommissionsMandates = commissionsWrap.acquiredCommissionsMandates;
    if (!!commissionsWrap.mandateActive) {
      component.mandateActive = commissionsWrap.mandateActive;
    }
  }

  private setSubHeaders(subHeaderInstalment: CommissionInstalment, amountLabel: string) {
    subHeaderInstalment.netPercPurchaseCommissions = '%';
    subHeaderInstalment.netPurchaseCommissions = amountLabel;
    subHeaderInstalment.feePercPurchaseCommissions = '%';
    subHeaderInstalment.feePurchaseCommissions = amountLabel;
    subHeaderInstalment.netPercCollectionCommissions = '%';
    subHeaderInstalment.netCollectionCommissions = amountLabel;
    subHeaderInstalment.feePercCollectionCommissions = '%';
    subHeaderInstalment.feeCollectionCommissions = amountLabel;
    subHeaderInstalment.totPercCommissions = '%';
    subHeaderInstalment.totCommissions = amountLabel;
  }

  protected getIfIsUnique(state: ReIssueQuotationState) {
    if (state.policyTechnicalData && state.policyTechnicalData.paymentFrequencyContainer
      && state.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList) {
      state.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach(val => {
        if (val.code === '100008' && val.selected) {
          this.isUnique = true;
        } else if (val.code === '100008') {
          this.isUnique = false;
        }
      });

    }
  }

  protected disabilitaRicalcola() {
    const state = this.stateManager.getCurrentState();
    state.quotation.isRecalculate = false;
    this.stateManager.updateState$(of(state));
  }


  goToNextPage() {
    this.submitted = true;
    this.validateWarningMessages();
      if (this.validateBeneficiaries()) {
      const notes: Notes = this.notesComponent.getNotes();
      this.stateManager.putNotes(notes, this.notesComponent.notesForm.dirty).subscribe(response => {
        this.stateManager.goToNextPage();
      });
    }
  }

  checkPolicyContactValidity(policyContactErrorMessage: string) {
    const tag = 'policyContactErrors';
    this.pushMessageHandler.clearTag(tag);
    this.policyContactMessages = policyContactErrorMessage;
    if (policyContactErrorMessage) {
      const msg: RgiRxPushMessage = new RgiRxPushMessage();
      const opts = {icon: 'rgi-ui-icon-alert'};
      msg.tag = tag;
      msg.status = 'danger';
      msg.content = policyContactErrorMessage;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
    }
  }

  checkBeneficiaryValidity(beneficiaryErrorMessage: string) {
    const tag = 'beneficiaryErrors';
    this.pushMessageHandler.clearTag(tag);
    this.beneficiaryErrorMessage = beneficiaryErrorMessage;
    if (beneficiaryErrorMessage) {
      const msg: RgiRxPushMessage = new RgiRxPushMessage();
      const opts = {icon: 'rgi-ui-icon-alert'};
      msg.tag = tag;
      msg.status = 'danger';
      msg.content = beneficiaryErrorMessage;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
    }
  }

  updatePolicyAddress(event) {
    this.stateManager.updateContact(event);
  }

  onVariablesFormChange(event: Array<Variable>) {
    this.stateManager.updateVariables(event);
    // this.validateForms();
  }

  verifyAsset(messages: Message[]) {
    const tag = 'validationError';
    this.pushMessageHandler.clearTag(tag);
    this.validationMessages = messages;
    messages.forEach((validationMsg => {
      const msg: RgiRxPushMessage = new RgiRxPushMessage();
      const opts = {icon: 'rgi-ui-icon-alert'};
      msg.tag = tag;
      msg.status = 'danger';
      msg.content = validationMsg.area + ' ' + validationMsg.text;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
    }));
  }


  getPremium(event: any) {
    const state = this.stateManager.getCurrentState();
    switch (event.id) {
      case ParametersService.ID_PAYMENT_FREQUECY:
        let paymentFrequency: PaymentFrequency;
        state.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach(element => {
          if (element.code === event.value) {
            element.selected = true;
            paymentFrequency = element;
          } else {
            element.selected = false;
          }
        });
        this.stateManager.putPaymentFrequency(state, paymentFrequency);
        break;
      case ParametersService.ID_INSTALMENT_DATES:
        this.stateManager.putInstalmentDate(state, event.value);
        break;
    }

    this.abilitaRicalcola();
  }

  verifyParameterBlockingMessages(event: any) {

  }

  initializeVariables() {

  }

  verifyParameterNonBlockingMessages(event: any) {
  }

  back() {
    this.validateWarningMessages();
    if (!this.beneficiaryErrorMessage) {
      const notes: Notes = this.notesComponent.getNotes();
      this.stateManager.putNotes(notes, this.notesComponent.notesForm.dirty).subscribe(response => {
        this.stateManager.back();
      });
    }
  }

  goToIthStep(i){
    this.stateManager.goToIthStep(i);
  }

  updateBeneficiary() {
    if (this.beneficiaryForm.valid) {
      let emitter = {beneficiaries: []};
      if (this.stateManager.getCurrentState().listBeneficiariesUnit.length > 0) {
        emitter = this.createBeneficiaryBodyRequest();
      }
      this.stateManager.putBeneficiariesByUnit(this.stateManager.getCurrentState().resourceId, emitter).subscribe(
        val => {
          if (val.outcome === 'KO') {
            this.submitted = false;
            this.checkBeneficiaryValidity(val.message);
          }
          this.validateWarningMessages();
          if (!this.beneficiaryErrorMessage) {
            const notes: Notes = this.notesComponent.getNotes();
            this.stateManager.putNotes(notes, this.notesComponent.notesForm.dirty).subscribe(response => {
              this.stateManager.back();
            });
          }
        }
      );
    } else {
      this.submitted = false;
      this.beneficiaryForm.markAllAsTouched();
      this.checkBeneficiaryValidity(this.errorMessage);
    }
  }

  getSection(assets: Array<Asset>, key: string): AssetInstance {
    const assetInstance = assets.find(asset => asset.assetId === key);
    return assetInstance.instances[0];
  }

  getSections(assets: Array<Asset>, key: string): Array<AssetSection> {
    const assetInstance = assets.find(asset => asset.assetId === key);
    return assetInstance.instances[0].sections;
  }

  getSumGrossAsset(asset) {
    let result = 0;
    asset.sections.forEach(section => {
      section.unitList.forEach(unit => {
        if (unit.selected) {
          result += +unit.annual.gross;
        }
      });
    });
    return result.toString();
  }

  putBeneficiary() {
    const emmiter = {idLatestPhotosThirdContactPerson: null, beneficiaries: null};

    if (this.stateManager.getCurrentState().thirdPersonContact) {
      emmiter.idLatestPhotosThirdContactPerson = this.stateManager.getCurrentState().thirdPersonContact.idLatestPhotos;
    }

    if (this.stateManager.getCurrentState().listBeneficiariesUnit.length > 0) {
      if (this.beneficiaryForm.valid) {
        this.beneficiaryErrorMessage = null;
        const beneficiariesUnit = [];
        this.beneficiaryForm.value.beneficiary.forEach(beneficiary => {
          const beneficiaryParties = [];
          beneficiary.beneficiaryParties.forEach(party => {
            if (party.beneficiaryParty && party.percentageBeneficiary) {
              // tslint:disable-next-line:max-line-length
              beneficiaryParties.push({
                idLatestPhotos: party.beneficiaryParty.idLatestPhotos ? party.beneficiaryParty.idLatestPhotos : party.beneficiaryParty.snapshotId,
                partyPercentage: party.percentageBeneficiary
              });
            }
          });
          beneficiariesUnit.push({
            beneficiaryType: beneficiary.beneficiaryType,
            unitCode: beneficiary.unitCode,
            parties: beneficiaryParties
          });
        });
        emmiter.beneficiaries = beneficiariesUnit;

      } else {
        this.submitted = false;
        this.beneficiaryForm.markAllAsTouched();
        this.checkBeneficiaryValidity(this.errorMessage);
      }
    }

    this.stateManager.putBeneficiariesByUnit(this.stateManager.getCurrentState().resourceId, emmiter).subscribe(
      val => {
        if (val.outcome === 'KO') {
          this.submitted = false;
          this.checkBeneficiaryValidity(val.message);
        }
        this.validateWarningMessages();
        if (!this.beneficiaryErrorMessage) {
          this.stateManager.goToNextPage();
        }
      }
    );
  }

  createBeneficiaryBodyRequest() {
    this.beneficiaryErrorMessage = null;
    const beneficiariesUnit = [];
    this.beneficiaryForm.value.beneficiary.forEach(beneficiary => {
      const beneficiaryParties = [];
      beneficiary.beneficiaryParties.forEach(party => {
        if (party.beneficiaryParty && party.percentageBeneficiary) {
          // tslint:disable-next-line:max-line-length
          beneficiaryParties.push({
            idLatestPhotos: party.beneficiaryParty.idLatestPhotos ? party.beneficiaryParty.idLatestPhotos : party.beneficiaryParty.snapshotId,
            partyPercentage: party.percentageBeneficiary
          });
        }
      });
      beneficiariesUnit.push({
        beneficiaryType: beneficiary.beneficiaryType,
        unitCode: beneficiary.unitCode,
        parties: beneficiaryParties
      });
    });
    return {beneficiaries: beneficiariesUnit};
  }

  private setTranslations() {

    const keys$ = of(['RE_ISSUE.FIELDS_MARKED_IN_RED']);
    keys$.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.errorMessage = next [0];
    }).unsubscribe();
  }

  checkPackets(selected: boolean) {
    const tag = 'packetErrors';
    this.pushMessageHandler.clearTag(tag);
    if (!selected) {
      const msg: RgiRxPushMessage = new RgiRxPushMessage();
      const opts = {icon: 'rgi-ui-icon-alert'};
      msg.tag = tag;
      msg.status = 'danger';
      msg.content = 'RE_ISSUE.SELECT_A_PACKAGE';
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
      this.disableNext = true;
    } else {
      this.disableNext = false;
    }
  }

  private reloadDiscountOnTotal(flexDiscount: FlexDiscountParam, component: ReIssueDiscountModalComponent) {
    this.stateManager.putFlexDiscountOnTotal(flexDiscount).subscribe(result => {
      this.recalculatePremiumForDiscountsModal(component);
    });
  }

  private reloadDiscountOnSingleUnits(flexDiscounts: FlexDiscountUnit[], component: ReIssueDiscountModalComponent) {
    this.stateManager.putFlexDiscountOnSingleUnits(flexDiscounts).subscribe(result => {
      this.recalculatePremiumForDiscountsModal(component);
    });
  }

  private reloadCommissions(commissionInputs: CommissionsWrap, component: any) {
    const state = this.stateManager.getCurrentState();
    this.pushMessageHandler.clearTag(ERROR_TAG_SNACKBAR.COMMISSIONS_MODAL);

    this.stateManager.putProposalCommissions(state.resourceId, commissionInputs, ERROR_TAG_SNACKBAR.COMMISSIONS_MODAL )
      .subscribe(
        // in any case reload commissions
        () => {},
        () => {},
          () => {
        this.stateManager.getProposalCommissions(state.resourceId).subscribe(updatedCommissions => {
          this.setCommissionsModalData(component, updatedCommissions);
          component.patchForm(updatedCommissions.commissions.filter(com => com.commissionType === PNC_POSTSALES_COMMISSION_TYPE.RISK));
        });
    });
  }

  getAssetClauses(assetKey: string) {
    const assetInstance = this.stateManager.getCurrentState().assetInstances.find(x => x.asset.key === assetKey);
    this.stateManager.getAssetClauses().subscribe(clauses => {
      assetInstance.asset.clauses = clauses.clauses.filter(clause => clause.assetId == assetKey);
      }
    );
  }

  onAssetClausesChange(event: any) {
    if (event && event.clause && event.assetKey) {
      this.stateManager.putAssetClauses(event.clause, event.assetKey).pipe(
        take(1),
        map(st => {
          return st;
        })).subscribe(st => {
        this.abilitaRicalcola();
        this.stateManager.updateState$(of(st));
      });
    }
  }

  onClauseChange(event: Array<PcClause>) {
    if (event) {
      this.stateManager.putClauses(event).subscribe(() => {
        this.abilitaRicalcola();
      });
    }
  }

  getBeneficiaryUnitVisibility(key: string) {
    const state = this.stateManager.getCurrentState();
    if (state.isBeneficiaryUnitVisibilityArray) {
      const instancekeyUnitCode = state.isBeneficiaryUnitVisibilityArray.find(val => {
        return val.split('_')[0] === key;
      });
      if (instancekeyUnitCode) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private validateBeneficiaries() {
    let isValid = true;
    const state = this.stateManager.getCurrentState();
    state.allAssets.forEach(asset => {
      asset.instances.forEach(instance => {
        instance.sections.forEach(section => {
          section.unitList.forEach(unit => {
            if (unit.beneficiaries && unit.beneficiaries.length > 0 && unit.selected) {
              unit.beneficiaries.forEach(beneficiary => {
                const beneficiariesSselected = state.beneficiariesSelected.find(beneficiaryParty =>
                  beneficiaryParty.split('_')[0] === instance.assetCode && beneficiaryParty.split('_')[1] === unit.code);
                if (!beneficiariesSselected) {
                  let content = '';
                  const msgContent = of(['RE_ISSUE.ERROR_BENEFICIARY_NOT_SELECTED']);
                  msgContent.pipe(mergeMap(r => {
                    return this.translateService.translateAll(...r);
                  })).subscribe(next => {
                    content = section.description + ' ' +
                      unit.description + ' (' + asset.name + '): ' + next[0];
                  }).unsubscribe();
                  const tag = 'beneficiaryErrors';
                  this.pushMessageHandler.clearTag(tag);
                  const msg: RgiRxPushMessage = new RgiRxPushMessage();
                  const opts = {icon: 'rgi-ui-icon-alert'};
                  msg.tag = tag;
                  msg.status = 'danger';
                  msg.content = content;
                  msg.dismissible = false;
                  msg.options = opts;
                  this.pushMessageHandler.notify(msg);
                  isValid = false;
                }
              });
            }
          });
        });
      });
    });
    return isValid;
  }
}


<div *ngIf="hasResult()" class="container-fluid table-white">
    <div class="head-result thDataTable row">
       
        <div align="left" class="col-sm-2">
            <span class="bold">{{ '_CLAIMS_._EXPERT' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1" *ngIf="card.isExtend">
            <span class="bold">{{ '_CLAIMS_._INVOICE_DATE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2" *ngIf="!card.isExtend">
            <span class="bold">{{ '_CLAIMS_._NUMBER' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2" *ngIf="!card.isExtend">
            <span class="bold">{{ '_CLAIMS_._AMOUNT' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1" *ngIf="card.isExtend">
            <span class="bold">{{ '_CLAIMS_._NUMBER' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1" *ngIf="card.isExtend">
            <span class="bold">{{ '_CLAIMS_._AMOUNT' | translate }}</span>
        </div>
        <div align="left" class="col-sm-2" *ngIf="card.isExtend">
            <span class="bold">{{ '_CLAIMS_._SAP_CODE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-4">
            <span class="bold">{{ '_CLAIMS_._REJECT_TYPE' | translate }}</span>
        </div>
        <div align="left" class="col-sm-1">
            <span class="bold"></span>
        </div>
    
    </div>
</div>


<span *ngFor="let invoice of invoices; let even = even; let odd = odd; let invoiceIndex = index">

    <div class="container-fluid table-white">

        <!-- invoice start -->
        <div class="row" *ngIf="invoice.rejectType">
            <div class="col-sm-2">
                <span class="bold">{{invoice.invoiceExpert.name}} {{invoice.invoiceExpert.surname}} {{invoice.invoiceExpert.title}}</span>
            </div>
			<div class="col-sm-1" *ngIf="card.isExtend">
                <span class="bold">{{invoice.invoiceDate | date:'dd/MM/yyyy' }}</span>
            </div>


			<div class="col-sm-2" *ngIf="!card.isExtend">
				<span class="bold">{{invoice.invoiceNumber}}</span>
			</div>
			<div class="col-sm-2" *ngIf="!card.isExtend">
				<span class="bold">{{invoice.invoiceAmount | currency:'EUR'}}</span>
            </div>
            
			<div class="col-sm-1" *ngIf="card.isExtend">
				<span class="bold">{{invoice.invoiceNumber}}</span>
			</div>
			<div class="col-sm-1" *ngIf="card.isExtend">
				<span class="bold">{{invoice.invoiceAmount | currency:'EUR'}}</span>
            </div>
            
            
			<div class="col-sm-2" *ngIf="card.isExtend">
				<span class="bold">{{invoice.sapCode}}</span>
            </div>
			<div class="col-sm-4">
				<span class="bold">{{invoice.rejectType.descrizione}}</span>
            </div>

			<div class="col-sm-1" *ngIf="invoice.rejectType && isManagedRejectType(invoice.rejectType.codice)">

                <!-- <div class="row"> -->
                    <!-- TODO - for future proforma management (more details for itself invoice) manage in cicle -->
                    <!-- <div *ngIf="invoice.status.codice==5" class="col-sm-1 green-check"> -->
                    <div *ngIf="invoice.status.codice==5" class="green-check">
                            <span class="rgifont rgi-survey_ok green-color ico-dimension-small" title="{{ '_CLAIMS_._REJECT_WORKED' | translate }}"></span>
                    </div>
                    <!-- <span class="col-sm-1 bold"> -->
                        <button type="button" class="btn dt-btn pull-right claims-margin-right-top padding-right-5" (click)="rejectInvoice(invoice.rejectType.codice, invoice)"
                            title="{{ '_CLAIMS_._EDIT' | translate }}" tooltip-append-to-body="true">
                            <span class="rgifont rgi-pencil iconDT modal-icon"></span>
                        </button>
                    <!-- </span> -->
                <!-- </div> -->
            
            </div>

        </div>
        <!-- invoice end -->

        <!-- details start -->
        <div class="row" *ngFor="let invoiceDetails of invoice.invoiceDetailsList">

            <div *ngIf="invoiceDetails.rejectType">
                <div class="col-sm-2 padding-zero">
                    <span class="bold">{{invoice.invoiceExpert.name}} {{invoice.invoiceExpert.surname}} {{invoice.invoiceExpert.title}}</span>
                </div>
                <div class="col-sm-1 padding-zero" *ngIf="card.isExtend">
                    <span class="bold">{{invoice.invoiceDate | date:'dd/MM/yyyy' }}</span>
                </div>


                <div class="col-sm-2 padding-zero" *ngIf="!card.isExtend">
                    <span class="bold">{{invoice.invoiceNumber}}</span>
                </div>
                <div class="col-sm-2 padding-zero" *ngIf="!card.isExtend">
                    <span class="bold">{{invoice.invoiceAmount | currency:'EUR'}}</span>
                </div>
                
                <div class="col-sm-1 padding-zero" *ngIf="card.isExtend">
                    <span class="bold">{{invoice.invoiceNumber}}</span>
                </div>
                <div class="col-sm-1 padding-zero" *ngIf="card.isExtend">
                    <span class="bold">{{invoice.invoiceAmount | currency:'EUR'}}</span>
                </div>
                
                
                <div class="col-sm-2 padding-zero" *ngIf="card.isExtend">
                    <span class="bold">{{invoice.sapCode}}</span>
                </div>
                <div class="col-sm-4 padding-zero">
                    <span class="bold">{{invoiceDetails.rejectType.descrizione}}</span>
                </div>

                <div class="col-sm-1 padding-zero" *ngIf="invoiceDetails.rejectType && isManagedRejectType(invoiceDetails.rejectType.codice)">

                    <!-- <div class="row"> -->
                        <!-- TODO - for future proforma management (more details for itself invoice) manage in cicle -->
                        <!-- <div *ngIf="invoiceDetails.status.codice==5" class="col-sm-1 green-check"> -->
                        <div *ngIf="invoiceDetails.status.codice==5" class="green-check">
                                <span class="rgifont rgi-survey_ok green-color ico-dimension-small" title="{{ '_CLAIMS_._REJECT_WORKED' | translate }}"></span>
                        </div>
                        <!-- <span class="col-sm-1 bold"> -->
                            <button type="button" class="btn dt-btn pull-right claims-margin-right-top padding-right-5" (click)="rejectInvoice(invoiceDetails.rejectType.codice, invoice)"
                                title="{{ '_CLAIMS_._EDIT' | translate }}" tooltip-append-to-body="true">
                                <span class="rgifont rgi-pencil iconDT modal-icon"></span>
                            </button>
                        <!-- </span> -->
                    <!-- </div> -->
                
                </div>

            </div>
        </div>
        <!-- details end -->

        <!-- expert start -->
        <div class="row" *ngIf="invoice.invoiceExpert.rejectType">
            <div class="col-sm-2">
                <span class="bold">{{invoice.invoiceExpert.name}} {{invoice.invoiceExpert.surname}} {{invoice.invoiceExpert.title}}</span>
            </div>
			<div class="col-sm-1" *ngIf="card.isExtend">
                <span class="bold">{{invoice.invoiceDate | date:'dd/MM/yyyy' }}</span>
            </div>


			<div class="col-sm-2" *ngIf="!card.isExtend">
				<span class="bold">{{invoice.invoiceNumber}}</span>
			</div>
			<div class="col-sm-2" *ngIf="!card.isExtend">
				<span class="bold">{{invoice.invoiceAmount | currency:'EUR'}}</span>
            </div>
			<div class="col-sm-1" *ngIf="card.isExtend">
				<span class="bold">{{invoice.invoiceNumber}}</span>
			</div>
			<div class="col-sm-1" *ngIf="card.isExtend">
				<span class="bold">{{invoice.invoiceAmount | currency:'EUR'}}</span>
            </div>


			<div class="col-sm-2" *ngIf="card.isExtend">
				<span class="bold">{{invoice.sapCode}}</span>
            </div>
			<div class="col-sm-4">
				<span class="bold">{{invoice.invoiceExpert.rejectType.descrizione}}</span>
            </div>

			<div class="col-sm-1" *ngIf="invoice.invoiceExpert.rejectType && isManagedRejectType(invoice.invoiceExpert.rejectType.codice)">

                <!-- <div class="row"> -->

                    <!-- <div *ngIf="invoice.invoiceExpert.status.codice==5" class="col-sm-1 green-check"> -->
                    <div *ngIf="invoice.invoiceExpert.status.codice==5" class="green-check">
                            <span class="rgifont rgi-survey_ok green-color ico-dimension-small" title="{{ '_CLAIMS_._REJECT_WORKED' | translate }}"></span>
                    </div>

                    <!-- <span class="col-sm-1 bold"> -->
                        <button type="button" class="btn dt-btn pull-right claims-margin-right-top padding-right-5" (click)="rejectInvoice(invoice.invoiceExpert.rejectType.codice, invoice)"
                            title="{{ '_CLAIMS_._EDIT' | translate }}" tooltip-append-to-body="true">
                            <span class="rgifont rgi-pencil iconDT modal-icon"></span>
                        </button>
                    <!-- </span> -->
                
                <!-- </div> -->
            
            </div>
            <!-- expert end -->

        </div>
        
    </div>
</span>


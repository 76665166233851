import { RgiRxTranslations } from "@rgi/rx/i18n";


export const RGI_RX_SNACKBAR_i18n = {
  it: {
    RGI_RX: {
      SNACKBAR: {
        CLOSE_LABEL: 'Chiudi'
      }
    }
  },
  en: {
    RGI_RX: {
      SNACKBAR: {
        CLOSE_LABEL: 'Close'
      }
    }
  },
  es: {
    RGI_RX: {
      SNACKBAR: {
        CLOSE_LABEL: 'Cerrar'
      }
    }
  },
  fr: {
    RGI_RX: {
      SNACKBAR: {
        CLOSE_LABEL: 'Fermer'
      }
    }
  },
  de: {
    RGI_RX: {
      SNACKBAR: {
        CLOSE_LABEL: 'Schließen'
      }
    }
  }
};

export const RGI_RX_SNACKBAR_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: () => new Promise(resolve => resolve(RGI_RX_SNACKBAR_i18n.it))
  },
  {
    locale: 'en',
    load: () => new Promise(resolve => resolve(RGI_RX_SNACKBAR_i18n.en))
  },
  {
    locale: 'es',
    load: () => new Promise(resolve => resolve(RGI_RX_SNACKBAR_i18n.es))
  },
  {
    locale: 'fr',
    load: () => new Promise(resolve => resolve(RGI_RX_SNACKBAR_i18n.fr))
  },
  {
    locale: 'de',
    load: () => new Promise(resolve => resolve(RGI_RX_SNACKBAR_i18n.de))
  }
]

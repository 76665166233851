import {GenericEntity} from './generic-entity';
import {AdditionalProperty} from "./additional-property";

export class ClausesResponse {
  public clauses: Array<Clause>;
}

export class ClauseOutput {
  constructor(
    public clause: Clause,
    public assetKey: string,
    public formValid: boolean
  ) {
  }
}

export class Clause extends GenericEntity {

  constructor(
    public id: string,
    public assetId: string,
    public code: string,
    public description: string,
    public selected: boolean,
    public order: number,
    public compulsory: boolean,
    public text: string,
    public properties: Array<AdditionalProperty>,
    public extendedDescription: string,
    public mandatoryText: boolean,
    public textFormat: number,
    public propertySelectionGroup: string,
    public propertySelectionType: string,
    public paramPP: string,
    public paramCurrent: string,
    public preQuotation: boolean,
    public editableText: boolean,
    public secondary: boolean,
    public visible: boolean,
    public propCollapseText: boolean,
    public clauseType: string,
    public enable: boolean,
    public dependentsList: boolean,
    public textType: string,
    public numCols: number,
    public numRows: number,
    public helpFile: string,
    public propCLACL: boolean,
    public propGSELC: string,
    public propCLASEL: string
  ) {
    super(id, code, description);
  }
}

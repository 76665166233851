<rgi-rx-panel class="rgi-psales-modal-xl">
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    {{'Titolo Commissioni' | translate}}
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="primary" (click)="modalClose.emit()" translate>
      {{'_RGIPNC_._BTN_._CLOSE_' | translate}}
    </button>
  </rgi-rx-panel-footer>
  <div style="overflow:auto; white-space: nowrap">
    <table rgi-rx-table [dataSource]="inputData.commissions">

      <ng-container rgiRxColumnDef="signedCommissions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>_RGIPNC_._LABEL_._SIGNED_COMMISSIONS_</th>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row?.risk?.description}}</td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell" translate>_RGIPNC_._LABEL_._TOTAL_</td>
      </ng-container>


      <ng-container rgiRxColumnDef="netPurchaseCommissions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
          _RGIPNC_._LABEL_._ACQUIRED_COMMISSION_
        </th>
        <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.netPercPurchaseCommissions}}</div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.netPurchaseCommissions | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell"></div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{inputData.subscriptionTotals.netCommissionsPurchase | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="feePurchaseCommissions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
          _RGIPNC_._LABEL_._ACQUIRED_CHARGES_
        </th>
        <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.feePercPurchaseCommissions}}</div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.feePurchaseCommissions | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell"></div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{inputData.subscriptionTotals.accessoriesCommissionsPurchase | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="netCollectionCommissions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
          _RGIPNC_._LABEL_._COLLECTED_COMMISSION_NET_
        </th>
        <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.netPercCollectionCommissions}}</div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.netCollectionCommissions | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell"></div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{inputData.subscriptionTotals.netCommissionsCollection | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="feeCollectionCommissions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>
          _RGIPNC_._LABEL_._COLLECTED_CHARGES_
        </th>
        <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.feePercCollectionCommissions}}</div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.feeCollectionCommissions | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell"></div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{inputData.subscriptionTotals.accessoriesCommissionsCollection | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="totCommissions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef class="rgipc-text-center" translate>_RGIPNC_._LABEL_._TOTAL_</th>
        <td rgi-rx-cell *rgiRxCellDef="let row" class="rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.totPercCommissions}}</div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{row.subscriptionInstalment.totCommissions | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
        <td rgi-rx-footer-cell *rgiRxFooterCellDef class="rgi-ui-cell rgipc-p-0">
          <div class="rgipc-d-flex rgipc-2cell-col">
            <div class="rgi-ui-cell"></div>
            <div
              class="rgi-ui-cell rgipc-text-right">{{inputData.subscriptionTotals.totalInstallment | rgiCountryLayerNumberFormat:undefined:{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            } }}</div>
          </div>
        </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
      <tr rgi-rx-footer-row *rgiRxFooterRowDef="displayedColumns" class="rgi-ui-row rgipc-bg-info"></tr>
    </table>
  </div>

</rgi-rx-panel>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Factor} from '../../../group-policy-models/group-policy-issue-policy-data';

/**
 * @author: dmasone
 * @description: Component used for manage numeric factors
 */
@Component({
  selector: 'rgi-gp-group-policy-factors-numeric-component',
  templateUrl: './group-policy-factors-numeric.component.html'
})
export class GroupPolicyPolicyFactorsNumericComponent implements OnInit, OnDestroy {

  @Output() updateFactors = new EventEmitter<Factor>();
  @Output() customFactorAction = new EventEmitter<Factor>();
  @Input() factor: Factor;
  @Input() group: UntypedFormGroup;
  constructor() {
  }

  ngOnInit(): void {
    this.group.get(this.factor.code).valueChanges.subscribe(_newValue => {
      this.onChangeFactor();
    });
    if (this.factor.minMaxVisible) {
      this.group.get(this.factor.code + '_MIN').valueChanges.subscribe(_newValue => {
        this.onChangeFactor();
      });
      this.group.get(this.factor.code + '_MAX').valueChanges.subscribe(_newValue => {
        this.onChangeFactor();
      });
    }
  }

  ngOnDestroy(): void {
  }

  public onChangeFactor() {
    const variableControl = this.group.get(this.factor.code);
    variableControl.updateValueAndValidity({emitEvent: false});
    if (this.factor.minMaxVisible) {
      const minControl = this.group.get(this.factor.code + '_MIN');
      const maxControl = this.group.get(this.factor.code + '_MAX');
      if ((minControl.value === undefined && maxControl.value === undefined && variableControl.value !== undefined) ||
        (variableControl.value !== undefined && variableControl.valid && minControl.value !== undefined && minControl.valid &&
          maxControl.value !== undefined && maxControl.valid)) {
        this.factor.minValue = minControl.value;
        this.factor.maxValue = maxControl.value;
        this.updateFactors.emit(this.factor);
      }
    } else if (variableControl.valid) {
      this.updateFactors.emit(this.factor);
    }
  }

  showCustomAction() {
    switch (this.factor.code) {
      case '1PCOAS':
        return this.factor.editable;
      default:
        return false;
    }
  }

  getCustomActionLabel() {
    switch (this.factor.code) {
      case '1PCOAS':
        return this.group.get('_1COAS').value === '2' ? '_GP_._BTN_._COINSURANCE_SHARES_' : '_GP_._BTN_._COINSURANCE_DELEGATED_';
      default:
        return '';
    }
  }
}

import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ContractPayment, EnumPayment, PaymentConfigResp, PaymentConfigSelected} from '../../payments/payments-data';
import {RgiRxHttpClientFactory, RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {RestApiConf} from '@rgi/anag/lib/anag-constants/rest-api-conf';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  protected hostPath: string;

  protected httpClient: RgiRxHttpClientWrapper;

  constructor(
    protected httpClientFactory: RgiRxHttpClientFactory,
    @Inject('environment') protected apiConf: RestApiConf) {
    this.httpClient = this.httpClientFactory.buildWrapper();
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path;
  }

  getPaymentsEnabled$(): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.hostPath}/v2/portfolio/payments/enabled`);
  }

  getConfigPayments$(uuid: string, enumPayment?: EnumPayment): Observable<PaymentConfigResp> {
    const payload = {
      function: 'CONTRACT',
      paymentType: enumPayment ? enumPayment : null
    };
    return this.httpClient.post<PaymentConfigResp>(`${this.hostPath}/v2/portfolio/payments/config/contract-draft/${uuid}`, payload);
  }

  setContractPayments$(uuid: string, contractPayments: ContractPayment[]): Observable<any> {
    return this.httpClient.put<any>(`${this.hostPath}/v2/portfolio/payments/contract-draft/${uuid}`, contractPayments);
  }

  getContractPayments$(uuid: string): Observable<any> {
    return this.httpClient.get<PaymentConfigResp>(`${this.hostPath}/v2/portfolio/payments/contract-draft/${uuid}`);
  }

  getAvailablePayments$(uuid: string): Observable<PaymentConfigSelected[]> {
    const payload = {
      function: 'CONTRACT',
      paymentType: null
    };
    return this.httpClient.post<PaymentConfigSelected[]>(
      `${this.hostPath}/v2/portfolio/payments/contract-draft/${uuid}/available`,
      payload
    );
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxSdkDelayedDestroyDirective} from './rgi-rx-sdk-delayed-destroy.directive';
import {RgiRxSdkElementObserverDirective} from './rgi-rx-sdk-element-observer.directive';


@NgModule({
  declarations: [RgiRxSdkDelayedDestroyDirective, RgiRxSdkElementObserverDirective],
  imports: [
    CommonModule
  ],
  exports: [RgiRxSdkDelayedDestroyDirective, RgiRxSdkElementObserverDirective]
})
export class RgiRxSdkObserverModule {
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { PV_TOKEN } from '../../../models/consts/lpc-consts';
import { ClaimType } from '../../../models/enums/vita.enum';
import { Policy } from '../../../models/life-detail.model';
import { _OPERATION_CODES } from '../../../modules/lpc-operations-list/model/lpc-operations-constants';
import { PostsalesOperationsService } from '../../../services/postsales-operations.service';
import { PlcDateUtils } from '../../../utils/plc-date-utils';
import { PlcOperationUtils } from '../../../utils/plc-operations-utils';
import { PostsalesOperationKey } from '../../../models/postsales-session';


@Component({
  selector: 'lpc-claim-policies',
  templateUrl: './claim-policies.component.html',
  styleUrls: ['./claim-policies.component.css']
})
export class ClaimPoliciesComponent implements OnInit {

  @Input() policies: Policy[];
  @Input() claimType: string;
  @Input() sessionIdFrom: string;
  translate: TranslationWrapperService;
  public parentSessionId: string;

  constructor(@Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
              @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
              translate: TranslationWrapperService,
              ) { this.translate = translate; }

  ngOnInit() {
  }

  public formatDate(dateValue: string): string {
    if (!!dateValue) {
      return PlcDateUtils.isoToFormattedDate(PlcDateUtils.isoDateTimeToIsoDate(dateValue));
    }
  }

  public showPolicyDetail(statusId: string, operationAvalaible: boolean) {
    return statusId === '11' && operationAvalaible;
  }

  public getOperationCode() {
    if (this.claimType === ClaimType.DisabilityClaim) {
        return _OPERATION_CODES.DISABILITYCLAIM;
    } else if (this.claimType === ClaimType.SeriousIllnessClaim) {
        return _OPERATION_CODES.SERIOUSILLNESSCLAIM;
    } else if (this.claimType === ClaimType.InabilityClaim) {
        return _OPERATION_CODES.INABILITYCLAIM;
    } else if (this.claimType === ClaimType.DeathClaim) {
      return _OPERATION_CODES.DEATHCLAIM;
    }
  }

  getDescriptionByCode(code: PostsalesOperationKey): string {
    if (_OPERATION_CODES.DEATHCLAIM === code) {
      return 'Sinistro_morte';
    } else if (_OPERATION_CODES.DISABILITYCLAIM === code) {
      return 'Sinistro_invalidita';
    } else if (_OPERATION_CODES.INABILITYCLAIM === code) {
      return 'Sinistro_inabilità';
    } else if (_OPERATION_CODES.SERIOUSILLNESSCLAIM === code) {
      return 'Malattia_grave';
    } else {
      return 'Descr_not_handled';
    }
  }

  public openAction(policyNumber: string) {
    const operationCode = this.getOperationCode();
    const title = this.translate.getImmediate(this.getDescriptionByCode(operationCode));
    const sessionInfo = {sessionId: this.sessionIdFrom, isFromClaimDetail: true};
    this.openSession(operationCode, title, false, policyNumber, sessionInfo);
  }

  openSession(operationSlug: PostsalesOperationKey, operationDescription: string, isFinancial: boolean,
              policyNumber: string, sessionInfo: any) {
    const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
    if (!!sessionService) {
      const title = this.translate.getImmediate('lpc_policy_number_dot');
      this.operations.setSession({
        policyNumber,
        operation: operationSlug,
        operationDescription,
        isFinancial,
        sessionInfo,
        sessionId: sessionService.open(
          PlcOperationUtils.getOperationConstants().get(operationSlug), 'home',
          operationDescription + ' - ' + title + policyNumber,
          true, null, null, this.parentSessionId
        ),
        productId: null,
        managementNodeCode: null
      });
    }
  }

}

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import {
  EMPTY_STR,
  ERROR_TYPE_CURRENT,
  SEVERITY_ERROR_BLOCKING,
} from '../../../models/consts/lpc-consts';
import { PersonType } from '../../../models/enum/lpc-subjects.enum';
import {
  BeneficiaryContainer,
  BeneficiaryRole,
  PostsalesError,
  Role,
} from '../../../models/postsales-operations-response.model';
import { AbsOperationComponent } from '../../../operation-components/abs-operation-component/abs-operation.component';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { DistributionToggleError } from '../../distribution-toggle/distribution-toggle.model';
import { Beneficiary } from '../model/beneficiary';
import { ClaimBeneficiary } from '../model/claim-beneficiary';
import { BeneficiaryType } from '../model/lpc-beneficiary-roles.enum';
import { LpcLayeredRuleService } from '../../../services/lpc-layered-rule.service';

export class LpcBeneficiaryUtils {
  public static beneficiaryRoles: BeneficiaryRole[] = [];

  // (moved from DeathClaimComponent and ChangeSettlementBenefComponent)
  // todo refactor to map
  public static getSettlementBeneficiariesErrors(
    formGroup: UntypedFormGroup,
    translate: TranslationWrapperService
  ): PostsalesError[] {
    const errors: PostsalesError[] = [];
    if (!!formGroup.get('beneficiaries').errors) {
      Object.keys(formGroup.get('beneficiaries').errors).forEach((errorKey) => {
        if (errorKey === 'beneficiaries') {
          (
            formGroup.get('beneficiaries').errors.beneficiaries as any[]
          ).forEach((ben) => {
            if (ben.physical === 1) {
              errors.push({
                context: 'beneficiaries',
                errorId: AbsOperationComponent.FE_ERROR_ID,
                errorMessage: translate.getImmediate(
                  'lpc_insert_phisical_sbj_for',
                  {
                    role: translate.getImmediate(
                      BENEFICIARY_ROLE_MAP[ben.code]
                    ),
                  }
                ),
                severity: SEVERITY_ERROR_BLOCKING,
                isFe: true,
                type: ERROR_TYPE_CURRENT,
              });
            }
            if (ben.physical === 2) {
              errors.push({
                context: 'beneficiaries',
                errorId: AbsOperationComponent.FE_ERROR_ID,
                errorMessage: translate.getImmediate(
                  'lpc_insert_legal_sbj_for',
                  {
                    role: translate.getImmediate(
                      BENEFICIARY_ROLE_MAP[ben.code]
                    ),
                  }
                ),
                severity: SEVERITY_ERROR_BLOCKING,
                isFe: true,
                type: ERROR_TYPE_CURRENT,
              });
            }
            if (ben.roles) {
              errors.push({
                context: 'beneficiaries',
                errorId: AbsOperationComponent.FE_ERROR_ID,
                errorMessage:
                  'lpc_add_mandatory_linkedroles',
                severity: SEVERITY_ERROR_BLOCKING,
                isFe: true,
                type: ERROR_TYPE_CURRENT,
              });
            }
            if (!!ben.paymentMethod) {
              errors.push({
                context: 'beneficiaries',
                errorId: AbsOperationComponent.FE_ERROR_ID,
                errorMessage: translate.getImmediate('lpc_payment_incomplete'),
                severity: SEVERITY_ERROR_BLOCKING,
                isFe: true,
                type: ERROR_TYPE_CURRENT,
              });
            }
            if (!!ben.percentage) {
              const error = ben.percentage;
              const errorMsg1 = translate.getImmediate(
                'lpc_beneficiaryPercentage'
              );
              const errorMsg2 = translate.getImmediate(
                error.type === 'max'
                  ? 'lpc_beneficiaryPercentage_lower'
                  : 'lpc_beneficiaryPercentage_greater'
              );
              errors.push({
                context: 'beneficiaries',
                errorId: AbsOperationComponent.FE_ERROR_ID,
                errorMessage: `${errorMsg1} ${error.subject} ${errorMsg2} ${error.value}`,
                severity: SEVERITY_ERROR_BLOCKING,
                isFe: true,
                type: ERROR_TYPE_CURRENT,
              });
            }
            if (!!ben.duplicates) {
              errors.push({
                context: 'beneficiaries',
                errorId: AbsOperationComponent.FE_ERROR_ID,
                errorMessage: translate.getImmediate('lpc_beneficiaryDuplicate'),
                severity: SEVERITY_ERROR_BLOCKING,
                isFe: true,
                type: ERROR_TYPE_CURRENT,
              });
            }
            if (!!ben.sub) {
              if (!!ben.sub.personType) {
                errors.push({
                  context: 'beneficiaries',
                  errorId: AbsOperationComponent.FE_ERROR_ID,
                  errorMessage:
                    'lpc_beneficiary_owner_recipient_must_be_physical',
                  severity: SEVERITY_ERROR_BLOCKING,
                  isFe: true,
                  type: ERROR_TYPE_CURRENT,
                });
              } else if (!!ben.sub.empty) {
                errors.push({
                  context: 'beneficiaries',
                  errorId: AbsOperationComponent.FE_ERROR_ID,
                  errorMessage:
                    'lpc_enter_the_beneficial_owners_of_the_recipient',
                  severity: SEVERITY_ERROR_BLOCKING,
                  isFe: true,
                  type: ERROR_TYPE_CURRENT,
                });
              }
            }
            if (!!ben.subRole && ben.subRole.amount) {
              LpcBeneficiaryUtils.addDistributionMessages(
                errors,
                ben.subRole.amount as DistributionToggleError,
                translate
              );
            }
            if (!!ben.partycomplete) {
              (
                ben.partycomplete as {
                  subjIdPlusrole: string;
                  messages: string[];
                }[]
              )
                .reduce(
                  (accumulator, item) => accumulator.concat(item.messages),
                  []
                )
                .forEach((errorMessage) => {
                  errors.push({
                    context: 'beneficiaries',
                    errorId: 'anagPartyKO',
                    errorMessage,
                    severity: SEVERITY_ERROR_BLOCKING,
                    isFe: true,
                    type: ERROR_TYPE_CURRENT,
                  });
                });
            }
          });
        } else if (errorKey === 'totalPercent') {
          errors.push({
            context: 'beneficiaries',
            errorId: AbsOperationComponent.FE_ERROR_ID,
            errorMessage: translate.getImmediate('lpc_global_percentage_must_be_a_maximum_100'),
            severity: SEVERITY_ERROR_BLOCKING,
            isFe: true,
            type: ERROR_TYPE_CURRENT,
          });
        } else if (errorKey === 'minTotalPercent') {
          errors.push({
            context: 'beneficiaries',
            errorId: AbsOperationComponent.FE_ERROR_ID,
            errorMessage: translate.getImmediate('lpc_overall_percentage_must_equal100'),
            severity: SEVERITY_ERROR_BLOCKING,
            type: ERROR_TYPE_CURRENT,
          });
        }
        if (errorKey === 'mandatoryBen') {
          errors.push({
            context: 'beneficiaries',
            errorId: AbsOperationComponent.FE_ERROR_ID,
            errorMessage: translate.getImmediate(
              'lpc_select_at_least_one_beneficiary'
            ),
            severity: SEVERITY_ERROR_BLOCKING,
            isFe: true,
            type: ERROR_TYPE_CURRENT,
          });
        } else if (errorKey === 'questionnaire') {
          errors.push({
            context: 'beneficiaries',
            errorId: AbsOperationComponent.FE_ERROR_ID,
            errorMessage: translate.getImmediate('lpc_Questionario_deve_essere_compilato'),
            severity: SEVERITY_ERROR_BLOCKING,
            isFe: true,
            type: ERROR_TYPE_CURRENT,
          });
        } else if (errorKey === 'totalAmount') {
          const totalAmount =
            formGroup.get('beneficiaries').errors.totalAmount.amount;
          errors.push({
            context: 'beneficiaries',
            errorId: AbsOperationComponent.FE_ERROR_ID,
            errorMessage: translate.getImmediate('lpc_global_distribution', {
              totalAmount,
            }),
            severity: SEVERITY_ERROR_BLOCKING,
            isFe: true,
            type: ERROR_TYPE_CURRENT,
          });
        } else if (errorKey === 'partycomplete') {
          (
            formGroup.get('beneficiaries').errors.partycomplete as {
              subjIdPlusrole: string;
              messages: string[];
            }[]
          )
            .reduce(
              (accumulator, item) => accumulator.concat(item.messages),
              []
            )
            .forEach((errorMessage) => {
              errors.push({
                context: 'beneficiaries',
                errorId: 'anagPartyKO',
                errorMessage,
                severity: SEVERITY_ERROR_BLOCKING,
                isFe: true,
                type: ERROR_TYPE_CURRENT,
              });
            });
        }
      });
    }
    return errors;
  }

  private static addDistributionMessages(
    errors: PostsalesError[],
    message: DistributionToggleError,
    translate: TranslationWrapperService
  ) {
    const errorMessages = !!message.errorDescription
      ? message.errorDescription
      : message.requiredError;
    errors.push({
      context: 'beneficiaries',
      errorId: AbsOperationComponent.FE_ERROR_ID,
      errorMessage: `${translate.getImmediate('lpc_For_the_Beneficiary')} ${
        message.benefName
      } ${errorMessages}`,
      severity: SEVERITY_ERROR_BLOCKING,
      isFe: true,
      type: ERROR_TYPE_CURRENT,
    });
  }

  public static getBeneficiariesErrors(
    formGroup: UntypedFormGroup,
    translate: TranslationWrapperService
  ): PostsalesError[] {
    const errors: PostsalesError[] = [];
    const feErrors: [
      {
        [key: string]:
          | string
          | { subjIdPlusrole: string; messages: string[] }[];
      }
    ] = [{}];
    Object.keys(formGroup.controls).forEach((el) => {
      const ben = formGroup.get(el) as UntypedFormGroup;
      if (ben instanceof UntypedFormGroup) {
        Object.keys(ben.controls).forEach((key) => {
          const error = (ben.get(key) as UntypedFormControl).errors;
          if (error !== null) {
            feErrors.push(error);
          }
        });
      }
      if (el === 'thirdParty') {
        const thirdParty = formGroup.get(el) as UntypedFormControl;
        if (!!thirdParty.errors) {
          feErrors.push(thirdParty.errors);
        }
      }
    });
    feErrors.map((error) => {
      if (error.codeMissing) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'codeMissing',
          errorMessage: translate.getImmediate('lpc_enter_mandatory_data'),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.underAgeLinkedSubjectRoles) {
        const linked = error.underAgeLinkedSubjectRoles as unknown as any;
        errors.push({
          context: 'beneficiaries',
          errorId: 'underAgeLinkedSubjectRoles',
          errorMessage: translate.getImmediate('lpc_effective_holder_adult_req', {
            linkedName: linked.name,
            linkedRole: linked.role,
            benefCat: linked.benefCat,
            benefRole: linked.benefRole,
          }),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.minLinkedSubjectRoles) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'minLinkedSubjectRoles',
          errorMessage: translate.getImmediate('lpc_enter_a_beneficial_owner'),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.giuridicalLinkedSubjectRoles) {
        const linked = error.giuridicalLinkedSubjectRoles as unknown as any;
        errors.push({
          context: 'beneficiaries',
          errorId: 'giuridicalLinkedSubjectRoles',
          errorMessage: translate.getImmediate(
            'lpc_effective_holder_natural_person_req',
            {
              linkedName: linked.name,
              linkedRole: linked.role,
              benefCat: linked.benefCat,
              benefRole: linked.benefRole,
            }
          ),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.linkedSubjectRolesPercentageAmount) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'linkedSubjectRolesPercentageAmount',
          errorMessage: translate.getImmediate('lpc_enter_the_beneficial_owners_of_the_recipient'),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.missingPercentageLinkedSubjectRoles) {
        const linked =
          error.missingPercentageLinkedSubjectRoles as unknown as any;
        errors.push({
          context: 'beneficiaries',
          errorId: 'missingPercentageLinkedSubjectRoles',
          errorMessage: translate.getImmediate('lpc_beneficiary_perc_req', {
            benefCat: linked.benefCat,
            benefRole: linked.benefRole,
            benefName: linked.name,
          }),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.errorMinPercentageLinkedSubjectRoles) {
        (
          error.errorMinPercentageLinkedSubjectRoles as unknown as any[]
        ).forEach((linked) => {
          errors.push({
            context: 'beneficiaries',
            errorId: 'errorMinPercentageLinkedSubjectRoles',
            errorMessage: translate.getImmediate('lpc_effective_holder_perc', {
              linkedName: linked.name,
              benefName: linked.benef,
              linkedPerc: linked.minPerc,
            }),
            severity: SEVERITY_ERROR_BLOCKING,
            isFe: true,
            type: ERROR_TYPE_CURRENT,
          });
        });
      }

      if (error.duplicatesLinkedSubjectRoles) {
        const linked = error.duplicatesLinkedSubjectRoles as unknown as any;
        errors.push({
          context: 'beneficiaries',
          errorId: 'duplicatesLinkedSubjectRoles',
          errorMessage: translate.getImmediate('lpc_effective_holder_duplicate', {
            linkedName: linked.name,
            benefName: linked.benef,
          }),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.minSubjects) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'minSubjects',
          errorMessage: error.minSubjectsMessage
            ? (error.minSubjectsMessage as string)
            : 'lpc_enter_the_minimum_number_of_subjects',
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.percentageMissing) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'percentageMissing',
          errorMessage: error.percentageMissingMessage
            ? (error.percentageMissingMessage as string)
            : 'lpc_percentage_must_be_greater_than_0',
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.percentageOutOfRange) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'percentageOutOfRange',
          errorMessage: error.percentageOutOfRangeMessage
            ? (error.percentageOutOfRangeMessage as string)
            : 'lpc_percentage_must_be_between',
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.benMorteNoAssic) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'benMorteNoAssic',
          errorMessage: error.benMorteNoAssicMessage
            ? (error.benMorteNoAssicMessage as string)
            : 'lpc_benef_death_not_same',
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      // IIAB-462 start
      if (error.benefPresente) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'benefPresente',
          errorMessage: error.benefPresenteMessage
            ? (error.benefPresenteMessage as string)
            : 'lpc_benefPresente_default_msg',
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }
      // IIAB-462 end

      if (error.percentageAmount) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'percentageAmount',
          errorMessage: translate.getImmediate('lpc_overall_percentage_must_equal100'),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }

      if (error.freeTextMissing) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'freeTextMissing',
          errorMessage: translate.getImmediate('lpc_enter_free_text'),
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }
      if (error.partycomplete) {
        (
          error.partycomplete as {
            subjIdPlusrole: string;
            messages: string[];
          }[]
        )
          .reduce((accumulator, item) => accumulator.concat(item.messages), [])
          .forEach((errorMessage) => {
            errors.push({
              context: 'beneficiaries',
              errorId: 'anagPartyKO',
              errorMessage,
              severity: SEVERITY_ERROR_BLOCKING,
              isFe: true,
              type: ERROR_TYPE_CURRENT,
            });
          });
      }
      if (!!error.groupingMissing) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'groupingMissing',
          errorMessage: error.groupingMissing as string,
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }
      if (!!error.totalPerGroupError) {
        errors.push({
          context: 'beneficiaries',
          errorId: 'totalPerGroupError',
          errorMessage: error.totalPerGroupError as string,
          severity: SEVERITY_ERROR_BLOCKING,
          isFe: true,
          type: ERROR_TYPE_CURRENT,
        });
      }
    });
    return errors;
  }

  public static toClaimBeneficiary(
    deathClaimBeneficiary: any
  ): ClaimBeneficiary {
    const obj: ClaimBeneficiary = {
      subject: deathClaimBeneficiary.beneficiary,
      paymentMethod: !!deathClaimBeneficiary.beneficiary.creditPayment
        ? deathClaimBeneficiary.beneficiary.creditPayment
        : null,
      questionnaireData: deathClaimBeneficiary.questionnaireData,
      editable:
        deathClaimBeneficiary.editable !== undefined ||
        deathClaimBeneficiary.editable !== null
          ? deathClaimBeneficiary.editable
          : true,
      idBenefSettlement: deathClaimBeneficiary.idBenefSettlement,
    };
    delete obj.subject.creditPayment;
    return obj;
  }

  public static filterLinkedRolesByParentRoleAndSbj(
    parentRole: string,
    subject: any
  ): BeneficiaryRole[] {
    const { personType, adult } = subject;
    const personTypeCode = !!personType.codice ? personType.codice : personType;
    const filters = [
      {
        availForType: BeneficiaryType.PHYSICAL_ADULT,
        condition: personTypeCode === PersonType.PHYSICAL && adult,
      },
      {
        availForType: BeneficiaryType.PHYSICAL_UNDERAGE,
        condition: personTypeCode === PersonType.PHYSICAL && !adult,
      },
      {
        availForType: BeneficiaryType.GIURIDICAL,
        condition: personTypeCode === PersonType.LEGAL,
      },
      { availForType: BeneficiaryType.BOTH, condition: true },
    ];
    if (this.beneficiaryRoles != null ) {
      const filteredRoles = this.beneficiaryRoles.filter(
        (role) =>
          role.availForRole.toString() === parentRole &&
          filters.some(
            ({ availForType, condition }) =>
              role.availForType === Number(availForType) && condition
          )
      );
      return filteredRoles;
    }
  }

  public static setBeneficiaryRoles(beneficiaryRoles: BeneficiaryRole[]) {
    this.beneficiaryRoles = beneficiaryRoles;
  }

  public static getBeneficiaryRoles(): BeneficiaryRole[] {
    const beneficiaries = this.beneficiaryRoles;
    return beneficiaries;
  }

  public static convertToBeneficiaryRequest(
    claimBeneficiary: any,
    amount?: number
  ): {} {
    const beneficiary = claimBeneficiary.subject;
    if (!!amount) {
      LpcBeneficiaryUtils.calculateDistribution(beneficiary, amount);
    }
    const linkedRoles = claimBeneficiary.linkedRoles;
    if (!!linkedRoles) {
      beneficiary.value.linkedSubjectsRoles = [];
      linkedRoles.map((role) => {
        const subjects: any[] = role.subjects;
        if (subjects !== null) {
          subjects.forEach((subject) => {
            const roleSubject = subject.subject.value;
            const found = beneficiary.value.linkedSubjectsRoles.find(
              (arrayRole) =>
                arrayRole.id === roleSubject.id &&
                arrayRole.role === roleSubject.role
            );
            if (!found) {
              beneficiary.value.linkedSubjectsRoles.push(roleSubject);
              roleSubject.linkedSubjectsRoles = [];
            }
            if (!!subject.linkedRoles) {
              const parent = beneficiary.value.linkedSubjectsRoles.find(
                (x) => x.id === roleSubject.id && x.code === roleSubject.code
              );
              subject.linkedRoles.forEach(
                (subRole) =>
                  !!parent && parent.linkedSubjectsRoles.push(subRole.value)
              );
            }
          });
        }
      });
    }
    beneficiary.creditPayment = claimBeneficiary.paymentMethod;
    const idBenefSettlement = claimBeneficiary.idBenefSettlement;
    const editable = claimBeneficiary.editable;

    // @ts-ignore
    const questionnaireData = claimBeneficiary.questionnaireData;
    return { beneficiary, questionnaireData, idBenefSettlement, editable };
  }

  private static calculateDistribution(beneficiary: Beneficiary, totalAmount) {
    const benef = beneficiary.value as Role;
    /* eslint-disable */
    if (!!benef.percentage) {
      benef.amount = (
        !!totalAmount
          ? PlcObjectUtils.roundToDecimal(
              (+benef.percentage * totalAmount) / 100,
              2
            )
          : 0
      ).toString();
    }
    if (!!benef.amount) {
      benef.percentage = (
        !!totalAmount
          ? PlcObjectUtils.roundToDecimal(
              (+benef.amount / totalAmount) * 100,
              2
            )
          : 0
      ).toString();
    }
  }

  public static getBeneficiariesForSummary(
    beneficiariesDefinitions,
    formGroup,
    thirdPartyDefinition,
    translateService,
    layeredRuleService = null,
    isReqLinkedSubjectRole = false,
    benefPositionNumberList = []
  ): any[] {
    const selectedBenef: BeneficiaryContainer[] = [];
    beneficiariesDefinitions.forEach((beneficiaryDefinition) => {
      if (beneficiaryDefinition.admittedBeneficiaries.length > 0) {
        const benef = formGroup
          .get("beneficiaries")
          .get("b" + beneficiaryDefinition.code).value.code;
        const beneficiaries = formGroup
          .get("beneficiaries")
          .get("b" + beneficiaryDefinition.code).value.subjects;
        if (!!beneficiaryDefinition.admittedBeneficiaries && !!benef) {
          const bFound = beneficiaryDefinition.admittedBeneficiaries.find(
            (element) => benef === element.code
          );
          beneficiaries.forEach((beneficiary) => {
            const isWithLinkedSubjectRole =
              isReqLinkedSubjectRole &&
              !!beneficiary.value &&
              !!beneficiary.value.linkedSubjectsRoles &&
              !!beneficiary.value.linkedSubjectsRoles.length;
            let selectedBenefLinkedSubjectsRoles: BeneficiaryContainer[] = [];
            if (isWithLinkedSubjectRole) {
              selectedBenefLinkedSubjectsRoles =
                beneficiary.value.linkedSubjectsRoles.map((linkedSubject) => {
                  return {
                    benefType: this.typeBenf(
                      linkedSubject.role,
                      EMPTY_STR,
                      translateService,
                      layeredRuleService
                    ),
                    benefName: linkedSubject.name,
                    benefPercentage: linkedSubject.percentage,
                    benefLabel: null,
                    visible: beneficiaryDefinition.visible,
                  };
                });
            }
            let positionNumberLabel = null;
            const isPositionNumber = !!(
              benefPositionNumberList &&
              benefPositionNumberList.length &&
              beneficiary.positionNumberCode
            );
            if (isPositionNumber) {
              const pnFound = benefPositionNumberList.find(
                (pn) => pn.code === beneficiary.positionNumberCode
              );
              positionNumberLabel = pnFound ? pnFound.label : null;
            }

            selectedBenef.push({
              benefType: this.typeBenf(
                beneficiaryDefinition.code,
                beneficiaryDefinition.label,
                translateService,
                layeredRuleService
              ),
              benefName: benef === "_ALTRO" ? beneficiary.value.name : null,
              benefPercentage:
                benef === "_ALTRO" ? beneficiary.value.percentage : null,
              benefLabel: !!bFound ? bFound.label : null,
              visible: beneficiaryDefinition.visible,
              benefPositionNumberLabel: positionNumberLabel,
              linkedSubjectsRoles: isWithLinkedSubjectRole
                ? selectedBenefLinkedSubjectsRoles
                : null,
            });
          });
        }
      }
    });
    LpcBeneficiaryUtils.sortingByPositionNumber(selectedBenef, translateService);
    if (
      !!thirdPartyDefinition &&
      thirdPartyDefinition.visible &&
      !!formGroup.get("thirdParty") &&
      !!formGroup.get("thirdParty").value &&
      !!formGroup.get("thirdParty").value.value.id
    ) {
      const thirdParties = formGroup.get("thirdParty").value;
      selectedBenef.push({
        benefType: this.typeBenf(
          thirdPartyDefinition.code,
          EMPTY_STR,
          translateService,
          layeredRuleService
        ),
        benefName: thirdParties.value.name,
        benefPercentage: thirdParties.value.percentage,
        benefLabel: null,
        visible: true,
      });
    }
    return selectedBenef.filter((benef) => benef.visible);
  }

  private static sortingByPositionNumber(selectedBenef: BeneficiaryContainer[], translateService: any) {
    selectedBenef.sort((a, b) => {
      if (!!a.benefType && !!b.benefType) {
	      if (a.benefType === translateService.getImmediate("lpc_life_beneficiary")+':' ) {
          return -1;
        } else if (b.benefType === translateService.getImmediate("lpc_life_beneficiary")+':') {
          return 1;
        } else if (a.benefType > b.benefType) {
          return 1;
        } else if (a.benefType < b.benefType) {
          return -1;
        } else {
          if (!!a.benefPositionNumberLabel && !!b.benefPositionNumberLabel) {
            if (a.benefPositionNumberLabel > b.benefPositionNumberLabel) {
              return 1;
            } else if (a.benefPositionNumberLabel < b.benefPositionNumberLabel) {
              return -1;
            } else {
              return 0;
            }
          }
          return 0;
        }
      }
     });
  }

  public static getAnagBeneficiariesForSummary(
    beneficiaryDefinition,
    formGroup,
    selectedBenef,
    translateService,
    layeredRuleService
  ) {
    formGroup
      .get("beneficiaries")
      .get("b" + beneficiaryDefinition.code)
      .value.subjects.forEach((beneData) => {
        if (beneData.idAssicurato != null) {
          selectedBenef.push({
            benefType: this.typeBenf(
              beneData.type,
              beneficiaryDefinition.label,
              translateService,
              layeredRuleService
            ),
            benefLabel:
              beneData.value.name + " " + beneData.value.percentage + "%",
            visible: beneficiaryDefinition.visible,
          });
        }
      });
  }

  private static typeBenf(
    type: any,
    label: string,
    translateService: any,
    layeredRuleService: LpcLayeredRuleService
  ): string {
    let btype =
      layeredRuleService && layeredRuleService.getDeathBeneficiaryDesc(type);
    if (!!btype) {
      return btype + ":";
    }

    if (type === "1") {
      btype = translateService.getImmediate("lpc_life_beneficiary");
    } else if (type === "2") {
      btype = translateService.getImmediate("lpc_death_beneficiary");
    } else if (type === "6") {
      btype =
        translateService.getImmediate("lpc_life_detail_beneficiary") +
        " " +
        label;
    } else if (type === "300015") {
      btype = translateService.getImmediate("lpc_Reference_Third_Party");
    } else if (type === "3") {
      btype = translateService.getImmediate("lpc_coupon_beneficiary");
    } else if (type === "300018") {
      btype = translateService.getImmediate("lpc_Beneficiaryowner");
    }

    return !!btype ? btype + ":" : EMPTY_STR;
  }
}

export const BENEFICIARY_ROLE_MAP = {
  300019: "lpc_beneficiary_executor",
  300020: "lpc_effective_recipient",
  300021: "lpc_recipient_owner",
  300018: "lpc_Beneficiaryowner",
};

export const areBeneficiariesChanged = (
  currentBeneficiaries: ClaimBeneficiary[],
  nextBeneficiaries: ClaimBeneficiary[]
): boolean => {
  // fixme e se ne cambia solo uno e un altro no?! si rompe lo stesso?
  if (nextBeneficiaries.length !== currentBeneficiaries.length) {
    return true;
  }

  let beneficiariesChanged = false;
  currentBeneficiaries.forEach((currBeneficiary) => {
    if (!beneficiariesChanged) {
      const nextBeneficiary: ClaimBeneficiary = getNextBeneficiaryForSubject(
        nextBeneficiaries,
        currBeneficiary
      );
      const subjectChanged = isSubjectChanged(currBeneficiary, nextBeneficiary);
      const paymentMethodChanged = isPaymentChanged(
        currBeneficiary,
        nextBeneficiary
      );
      // removed check because of weird behaviors causign linkedRoles to disappear from one step to another
      // const linkedRolesChanged = areLinkedRolesChanged(currBeneficiary, nextBeneficiary);
      beneficiariesChanged = subjectChanged || paymentMethodChanged; // || linkedRolesChanged;
    }
  });
  return beneficiariesChanged;
};

const getNextBeneficiaryForSubject = (
  nextBeneficiaries: ClaimBeneficiary[],
  currBeneficiary: ClaimBeneficiary
): ClaimBeneficiary => {
  const currentRole = currBeneficiary.subject.value as Role;
  return nextBeneficiaries.find((x) => {
    const role = x.subject.value as Role;
    return role.id === currentRole.id && role.role === currentRole.role;
  });
};

const isSubjectChanged = (
  nextBeneficiary: ClaimBeneficiary,
  currBeneficiary: ClaimBeneficiary
): boolean => {
  const SUBJECT_KEYS_TO_CHECK = ["type", "code", "idAssicurato", "irrevocable"];
  const getSubject = (obj) => getOrNull(obj, "subject");
  const currentSubject = getSubject(currBeneficiary);
  const nextSubject = getSubject(nextBeneficiary);

  const subjectValueChanged: boolean = !PlcObjectUtils.equal(
    getOrNull(nextSubject, "value"),
    getOrNull(currentSubject, "value")
  );
  const otherValuesChanged: boolean = SUBJECT_KEYS_TO_CHECK.map(
    (key) => getOrNull(currentSubject, key) !== getOrNull(nextSubject, key)
  ).reduce((p, v) => (p ? p : v), false);
  return subjectValueChanged || otherValuesChanged;
};
const isPaymentChanged = (
  nextBeneficiary: ClaimBeneficiary,
  currBeneficiary: ClaimBeneficiary
): boolean => {
  const getPaymentMethod = (obj) => getOrNull(obj, "paymentMethod");
  return !PlcObjectUtils.equal(
    getPaymentMethod(nextBeneficiary),
    getPaymentMethod(currBeneficiary)
  );
};
// @ts-ignore
const areLinkedRolesChanged = (
  nextBeneficiary: ClaimBeneficiary,
  currBeneficiary: ClaimBeneficiary
): boolean => {
  const getLinkedRoles = (obj) => getOrNull(obj, "linkedRoles");
  return !PlcObjectUtils.equal(
    getLinkedRoles(nextBeneficiary),
    getLinkedRoles(currBeneficiary)
  );
};
const getOrNull = (obj, prop) => (obj == null ? null : obj[prop]);

import {Inject, Injectable} from '@angular/core';
import {MicIntegrationService} from '../../mic-integration.service';

@Injectable()
export class MicPortalIntegrationService extends MicIntegrationService {

  constructor(@Inject('sessionService') private portalSession: any,
              @Inject('coreResult') private coreResult: any,
              @Inject('containerCacheService') private containerCacheService: any
  ) {
    super();
  }

  closeApp() {
    const activeSession = this.portalSession.list().find(session => session.isActive);
    this.portalSession.remove(activeSession.idSession);
  }

  getSessionDataFromContainerCache(sessionName: string): any {
    const activeSession = this.portalSession.list().find(session => session.isActive);
    if (activeSession && activeSession.name === sessionName) {
      return this.containerCacheService.getCache().get(activeSession.idSession);
    }
    return null;
  }
}

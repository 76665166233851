import {RgiRxi18nModuleLoadType, RgiRxTranslations, Serialized18n} from "@rgi/rx/i18n";

export const IT: Serialized18n = {
    NG_PASSPROPRO_QUESTIONNAIRE: {
        "rules": "Regole",
        "rule_type": "Tipo di Regola",
        "no_rules": "Al momento non sono presenti regole",
        "add_adequacy_rule": "AGGIUNGI ADEQUACY",
        "add_inclusion_rule": "AGGIUNGI INCLUSION",
        "edit_icon": "Modifica Regola",
        "delete_icon":"Cancella Regola",
        "delete_rule": "Sei sicuro di voler cancellare la seguente regola ?",
    }

}

export const EN: Serialized18n = {
    NG_PASSPROPRO_QUESTIONNAIRE: {
        "rules": "Rules",
        "rule_type": "Rule Type",
        "no_rules": "There are currently no rules",
        "add_adequacy_rule": "ADD ADEQUACY",
        "add_inclusion_rule": "ADD INCLUSION",
        "edit_icon":"Edit Rule",
        "delete_icon":"Delete Rule",
        "delete_rule": "Are you sure you want to delete the following rule ?",
    }
}

export function loadNgPassProProQuestionnaireIT(): RgiRxi18nModuleLoadType {
    return new Promise(resolve => {
        return resolve(IT)
    })
}

export function loadNgPassProProQuestionnaireEN(): RgiRxi18nModuleLoadType {
    return new Promise(resolve => {
        return resolve(EN)
    })
}



export const NG_PASSPROPRO_QUESTIONNAIRE_TRANSLATIONS: RgiRxTranslations = [

    {
        load:loadNgPassProProQuestionnaireIT,
        locale:"it"
    },
    {
        load:loadNgPassProProQuestionnaireEN,
        locale: "en"
    }
]

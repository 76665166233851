import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {
  RegulationPremium,
  RegulationType
} from '../../../models/pc-portfolio-models/regulation-models/pc-regulation-premium';
import {
  RegulationPremiumResponse
} from '../../../models/pc-portfolio-models/regulation-models/pc-regulation-premium-response';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioRegulationResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  putRegulationPremium(proposalId: string, regulationPremium: RegulationPremium): Observable<RegulationPremiumResponse> {
    return this.http.put<RegulationPremiumResponse>(
      this.getRegulationPremiumUri(proposalId),
      regulationPremium
    ).pipe(
      share()
    );
  }

  getRegulationTypes(proposalId: string): Observable<RegulationType[]> {
    return this.http.get<RegulationType[]>(
      this.getRegulationTypesUri(proposalId)
    ).pipe(
      share()
    );
  }

  getRegulationPremiumUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/regulation-premium';
  }

  getRegulationTypesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/regulation-types';
  }
}

import {Injectable} from '@angular/core';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';

import {Observable, of} from 'rxjs';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {ReIssueQuotationBusinnessService} from '../re-issue-business-services/re-issue-quotation-businness.service';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {map, share, take} from 'rxjs/operators';
import {Clause} from '../../models/domain-models/clause';

import {RgiRxLogger} from '@rgi/rx';
import {PaymentFrequency} from '../../models/api-models/api-payment-frequency';
import {Package, PcInsuredAsset} from '../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {PcUnit} from '../../models/pc-portfolio-models/quotation-models/pc-unit';
import {Variable} from '../../models/domain-models/variable';
import {PolicyContact} from '../../models/domain-models/policy-contact';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {CommissionsWrap} from '../../models/domain-models/parameters/commission-detail';
import {InputPremium} from '../../models/domain-models/inputPremium';
import {Notes} from '../../models/domain-models/notes';
import {GenericEntityPcPortfolio} from '../../models/pc-portfolio-models/genericEntityPcPortfolio';
import {FlexDiscountParam} from '../../models/pc-portfolio-models/discounts-models/flex-discount-param';
import {FlexDiscountUnit} from '../../models/pc-portfolio-models/discounts-models/flex-discount-unit';
import {DiscountFund} from '../../models/pc-portfolio-models/discounts-models/discount-fund';
import {ReIssueQuotationState} from './re-issue-quotation.state';
import {PcClause} from "../../models/pc-portfolio-models/quotation-models/pc-clause";

export interface ReIssueQuotationNavigationDataI {
  isSubstitution: boolean;
  isToDisableFraz: boolean; // this is the interface of the navigiation params
  resourceId: string;
  policyTechnicalData: PolicyTechnicalData;
  lpsData: LpsData;
  subject: AnagIssueSubject;
  productName: string;
  productId: number;
  branchOffice: string;
  productCode: string;
  node: any;
  companyId: string;
  editMode: boolean;
  editFunctionality: boolean;
  isPersonaGiuridica: boolean;
  resetQuestionnaire: boolean;
  isSubjectModified: boolean;
  authorize: boolean;
  bIntermediateSaving: boolean;
  clausesFromAsset: any;
  // assetInstances: Array<PcAssetInstance>;
}

@Injectable()
export class ReIssueQuotationStateManagerService<T extends ReIssueQuotationState> extends AbstractStateManager<T> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected quotationBusinnessService: ReIssueQuotationBusinnessService<T>,
    protected logger: RgiRxLogger
  ) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<T>(this.activeRoute.key) ?
      stateStoreService.get<T>(this.activeRoute.key) :
      this.newReIssueQuotationState(); // get existing state or create a new one

    this.updateState$(this.initQuotation(st));
  }

  newReIssueQuotationState(): T {
    return new ReIssueQuotationState(this.activeRoute.key) as T;
  }

  goToNextPage() {
    this.getState$().pipe(take(1)).subscribe(
      st => {
        const request: any = {
          resourceId: st.resourceId,
          policyTechnicalData: st.policyTechnicalData,
          lpsData: st.lpsData,
          subject: st.subject,
          allAssets: st.allAssets,
          branchOffice: st.policyTechnicalData.branchCode,
          productName: st.productName,
          productId: st.productId,
          productCode: st.productCode,
          proposalStatus: st.proposalStatus,
          node: st.node,
          companyId: st.companyId,
          editMode: st.editMode,
          editFunctionality: st.editFunctionality,
          isPersonaGiuridica: st.isPersonaGiuridica,
          isToDisableFraz: st.isToDisableFraz,
          isSubstitution: st.isSubstitution,
          assetInstances: st.assetInstances,
          quotation: st.quotation,
          authorize: st.authorize,
          contact: st.contact,
          bIntermediateSaving: st.bIntermediateSaving,
          clausesFromAsset: st.clausesFromAsset
        };

        this.routingService.navigate('re-issue-summary', request, this.activeRoute.id);
      }
    );
  }

  back() {
    const st = this.getCurrentState();
    const req: any = {
      resourceId: st.resourceId,
      contractorSubject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: st.lpsData,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: st.productName,
      productId: st.productId,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isSubstitution: st.isSubstitution,
      isSubjectModified: false,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      bIntermediateSaving: st.bIntermediateSaving,
      thirdPersonContact: st.thirdPersonContact
    };
    this.routingService.navigate('re-issue-asset', req, this.activeRoute.id);
  }

  goToIthStep(i) {
    this.navigateToIthStep(i);
  }

  private navigateToIthStep(i) {
    const st = this.getCurrentState();
    const data = this.prepareData(st, i);
    this.routingService.navigate(data.route, data.object, this.activeRoute.id);
  }

  private prepareData(st: T, i: number) {

    let data: { object: any, route: string } = { object: null, route: '' };
    switch (i) {
      case 0:
        data.object = this.prepareReIssuePolicyData(st);
        data.route = 're-issue-policy-data';
        break;
      case 1:
        data.object = this.prepareReIssueAssetData(st);
        data.route = 're-issue-asset';
        break;
      default:
        console.log('Unknown step');
        // Additional logic for unknown steps
        break;
    }

    return data;
  }

  private prepareReIssuePolicyData(st: T) {
    const dataPolicy: any = {
      contractorSubject: st.subject,
      resourceId: st.resourceId,
      productName: st.productName,
      productId: st.productId,
      node: st.node,
      productCode: st.productCode,
      companyId: st.companyId,
      policyTechnicalData: st.policyTechnicalData,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isSubstitution: st.isSubstitution,
      backFromStep: true,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      thirdPersonContact: null
    };
    return dataPolicy;
  }

  private prepareReIssueAssetData(st: T) {
    const dataAsset: any = {
      resourceId: st.resourceId,
      contractorSubject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: st.lpsData,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: st.productName,
      productId: st.productId,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: st.editMode,
      editFunctionality: st.editFunctionality,
      isSubstitution: st.isSubstitution,
      isSubjectModified: false,
      isToDisableFraz: st.isToDisableFraz,
      authorize: st.authorize,
      bIntermediateSaving: st.bIntermediateSaving,
      thirdPersonContact: null
    };
    return dataAsset;
  }

  initQuotation(state: T): Observable<T> {
    const req = this.activeRoute.getRouteData<any>();
    state.resourceId = req.resourceId;
    state.policyTechnicalData = req.policyTechnicalData;
    state.lpsData = req.lpsData;
    state.subject = req.subject;
    state.productName = req.productName;
    state.productId = req.productId;
    state.productCode = req.productCode;
    state.node = req.node;
    state.companyId = req.companyId;
    state.editMode = req.editMode;
    state.editFunctionality = req.editFunctionality;
    state.isSubjectModified = req.isSubjectModified;
    state.isToDisableFraz = req.isToDisableFraz;
    state.isSubstitution = req.isSubstitution;
    state.authorize = req.authorize;
    state.bIntermediateSaving = req.bIntermediateSaving;
    state.clausesFromAsset = req.clausesFromAsset;
    if (req.isPersonaGiuridica !== null) {
      state.isPersonaGiuridica = req.isPersonaGiuridica;
    }

    // this.manageNavigationForQuestionnaire(state, req);

    this.logger.debug('ReIssueQuotationStateManagerService::initQuotation() - state', state);

    return this.quotationBusinnessService.getQuotation(state);
  }

  // putQuestionnaire(questionnaireFlat: QuestionnaireFlatI): Observable<T> {
  //   return this.quotationBusinnessService.putQuestionnaire(this.getCurrentState(), questionnaireFlat);
  // }

  recalculatePut(): Observable<T> {
    const state = this.getCurrentState();
    return this.quotationBusinnessService.recalculatePut$(state).pipe(
      take(1),
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  getLpsData() {
    const state = this.getCurrentState();
    this.updateState$(
      this.quotationBusinnessService.getLpsData$(state)
    );
  }

  putLpsData(lpsData: LpsData) {
    const state = this.getCurrentState();
    return this.quotationBusinnessService.putLpsData$(state, lpsData)
      .pipe(take(1), map(st => {
        this.updateState$(of(st));
        return st;
      }));
  }

  putPolicyData(state: T) {
    this.quotationBusinnessService.policyTechnicalDataPut$(state).pipe(take(1)).subscribe(policyTech => {
      state.policyTechnicalData = policyTech;
      this.updateState$(of(state));
    });

  }

  // onQuestionnaireValidChange(event: any): Observable<T> {
  //   return this.getState$().pipe(
  //     take(1),
  //     map(st => {
  //       st.questionnaireValid = event;
  //       this.updateState$(of(st));
  //       return st;
  //     })
  //   );
  // }

  putAssetClauses(clause: Clause, assetKey: string) {
    return this.quotationBusinnessService.putAssetClauses(clause, this.getCurrentState().assetInstances.find(x => x.asset.key == assetKey).asset, this.getCurrentState())
      .pipe(
        take(1),
        map(st => {
          this.updateState$(of(st));
          return st;
        })
      );
  }

  putClauses(clause: Array<PcClause>) {
    return this.quotationBusinnessService.putClauses(clause, this.getCurrentState())
    .pipe(
      take(1),
      map(st => {
        this.updateState$(of(st));
        return st;
      })
    );
  }

  getAssetClauses() {
    const st = this.getCurrentState();
    return this.quotationBusinnessService.getAssetClauses(st);
  }

  updateVariables(val: Array<Variable>) {
    const state = this.getCurrentState();
    this.updateState$(
      this.quotationBusinnessService.updateProductVariables$(state, val)
    );
  }

  updateContact(contact: Observable<PolicyContact>) {
    const state = this.getCurrentState();
    this.updateState$(this.quotationBusinnessService.updateContact$(state, contact));
  }

  postWarnings(state: T) {
    return this.quotationBusinnessService.postWarnings(state);
  }

  // protected manageNavigationForQuestionnaire(
  //   state: T,
  //   request: ReIssueQuotationNavigationDataI
  // ) {

  //   // if (request.resetQuestionnaire) {
  //   //   state.questionnaireCommitted = false;
  //   // } else {
  //   //   state.questionnaireCommitted = true;
  //   // }

  //   this.logger.debug('ReIssueQuotationStateManagerService::manageNavigationForQuestionnaire() - state', state);

  //   if (this.quotationBusinnessService.isToEmptyQuestionnaire(state) || state.isSubjectModified) {
  //     state.questionnaireValid = null;
  //   }
  //   if (this.quotationBusinnessService.isToEmptyClauses(state) || state.isSubjectModified) {
  //     state.clauseIsValid = null;
  //   }
  // }

  putPaymentFrequency(state: T, paymentFrequency: PaymentFrequency) {
    this.updateState$(this.quotationBusinnessService.paymentFrequencyPut$(state, paymentFrequency));
  }

  putInstalmentDate(state: T, instalmentDate: string) {
    this.updateState$(this.quotationBusinnessService.putInstalmentDate(state, instalmentDate));
  }

  getUnits(proposalid: string, assetKey: string, sectionId: string): Observable<PcUnit[]> {
    return this.quotationBusinnessService.getUnits(proposalid, assetKey, sectionId).pipe(
      share(),
      map((output) => {
        return output;
      }));
  }

  putUnitSelected(proposalid: string, assetKey: string, sectionId: string, unitSelected: PcUnit): Observable<PcUnit[]> {
    return this.quotationBusinnessService.putUnitSelected(proposalid, assetKey, sectionId, unitSelected);
  }

  getSectionProduct(proposalid: string, assetKey: string): Observable<PcInsuredAsset> {
    return this.quotationBusinnessService.getSectionProduct(proposalid, assetKey).pipe(
      map(res => {
        return res;
      })
    );
  }

  // getSection(proposalid: string) {
  //   return this.quotationBusinnessService.getSection(proposalid).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }
  putInputPremium(state: T, assetInstanceKey: string, sectionCode: string, inputPremium: InputPremium) {
    return this.quotationBusinnessService.putInputPremium(state, assetInstanceKey, sectionCode, inputPremium);
  }

  putPackage(state: T, proposalid: string,  assetKey: string, packages: Package[]): Observable<T> {
    return this.quotationBusinnessService.putPackege(proposalid, assetKey, packages).pipe(
      share(),
      map(packages => {
        state.assetInstances.find(instance => instance.asset.key === assetKey).asset.package = packages;
        return state;
      })
    );
  }

  putBeneficiariesByUnit(proposalid: string, beneficiary) {
    return this.quotationBusinnessService.putBeneficiariesByUnit(proposalid, beneficiary);
  }

  getProposalCommissions(proposalId: string): Observable<CommissionsWrap> {
    return this.quotationBusinnessService.getProposalCommissions(proposalId);

  }

  putProposalCommissions(proposalId: string, inputCommissions: CommissionsWrap, tag: string): Observable<GenericEntityPcPortfolio> {
    return this.quotationBusinnessService.putProposalCommissions(proposalId, inputCommissions, tag);
  }
  putUnitClauses(state: T, assetKey: string, sectionCode: string, unitCode: string, clauses: Clause[]) {
    return this.quotationBusinnessService.putUnitClauses(state, assetKey, sectionCode, unitCode, clauses);
  }

  putNotes(notes: Notes, areNotesChanged: boolean) {
    const state = this.getCurrentState();
    return of(areNotesChanged ? this.updateState$(this.quotationBusinnessService.putNotes$(state, notes)) : null);
  }

  putBeneficiariesByAssetAndByUnit(proposalid: string, assetKey: string, unitCode: string, beneficiary) {
    return this.quotationBusinnessService.putBeneficiariesByAssetAndByUnit(proposalid, assetKey, unitCode, beneficiary);
  }

  getBeneficiariesByAssetAndByUnit(proposalid: string, assetKey: string, unitCode: string) {
    return this.quotationBusinnessService.getBeneficiariesByAssetAndByUnit(proposalid, assetKey, unitCode);
  }

  putThirdContactPerson(resourceId: string, emitter: { idLatestPhotosThirdContactPerson: any }) {
    return this.quotationBusinnessService.putThirdContactPerson(resourceId, emitter);
  }

  deleteBeneficiariesByAssetAndByUnit(proposalid: string, assetKey: string, unitCode: string) {
    return this.quotationBusinnessService.deleteBeneficiariesByAssetAndByUnit(proposalid, assetKey, unitCode);
  }

  deleteThirdContactPerson(resourceId: string) {
    return this.quotationBusinnessService.deleteThirdContactPerson(resourceId);
  }

  putFlexDiscountOnTotal(flexDiscount: FlexDiscountParam) {
    return this.quotationBusinnessService.putFlexDiscountOnTotal(this.getCurrentState().resourceId, flexDiscount);
  }

  putFlexDiscountOnSingleUnits(flexDiscountUnits: FlexDiscountUnit[]) {
    return this.quotationBusinnessService.putFlexDiscountOnSingleUnits(this.getCurrentState().resourceId, flexDiscountUnits);
  }

  getDiscountFund(): Observable<DiscountFund[]> {
    return this.quotationBusinnessService.getDiscountFund(this.getCurrentState().resourceId);
  }
}


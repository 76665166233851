import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, Outcome } from '@rgi/digital-claims-common-angular';
import { EnumType } from '@rgi/digital-claims-common-angular';
import { Invoice } from '../dto/invoice';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-update',
  templateUrl: './invoice-update.component.html',
  styleUrls: ['./invoice-update.component.scss']
})
export class InvoiceUpdateComponent implements OnInit, AfterViewInit {

  @Input() invoice: Invoice;
  updateInvoiceForm: UntypedFormGroup;
  errorMessage: string;
  today: NgbDate;
  validationMessages: string[];
  // allInvoiceTypes: EnumType[];
  expanded: boolean;

  constructor(
    @Inject('eventService') private eventService: any,
    @Inject('enumService') private enumService: any,
    private formBuilder: UntypedFormBuilder,
    private invoiceService: InvoiceService,
    private activeModal: NgbActiveModal,
    private alertService: AlertService,
    public translateService: RgiRxTranslationService,
  ) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }


  ngOnInit(): void {

    // this.allInvoiceTypes = this.enumService.getEnumList('sinistri.InvoiceType');
    this.initValidators();
    this.fieldsAreValid();
    this.expanded = false;
    // this.fillupdateInvoiceForm(this.invoice);
  }

  ngAfterViewInit(): void {
    // lasciare anche qui la chiamata al fieldsAreValid
    // altrimenti se lanciato solo dall'ngOnInit restituisce expressionchangedafterithasbeencheckederror
    this.fieldsAreValid();
  }

  initValidators() {

    // ATTENZIONE : se inizializzo il campo con null o '', non funziona il Validators.required,
    // Se non sono obbligatori, togliere il Validators.required su quelli
    // Se sono obbligatori, fare a mano i controlli nel caso in cui campi null su db siano obbligatori sulla modale
    // impostarli eventualmente in formalCtrl() o farne uno equivalente per gli obbligatori
    // settando a mano lo stile col bordo rosso

    this.updateInvoiceForm = this.formBuilder.group({

      // taxableAmount: [this.invoice.taxableAmount, Validators.compose([Validators.required, Validators.min(1)])],
      taxableAmount: [this.fromDbFormat(this.invoice.taxableAmount), Validators.required],

      notTaxableAmount: [this.fromDbFormat(this.invoice.notTaxableAmount), Validators.required],
      percWithHoldTax: [this.fromDbFormat(this.invoice.percWithHoldTax)], // , Validators.required],
      amountHoldTax: [this.fromDbFormat(this.invoice.amountHoldTax), Validators.required],
      percPrevFund1: [this.fromDbFormat(this.invoice.percPrevFund1)], // , Validators.required],
      cashPrevidAmount: [this.fromDbFormat(this.invoice.cashPrevidAmount), Validators.required],
      percPrevFund2: [this.fromDbFormat(this.invoice.percPrevFund2)], // , Validators.required],
      inpsContribAmount: [this.fromDbFormat(this.invoice.inpsContribAmount)], // , Validators.required],
      vatRate: [this.fromDbFormat(this.invoice.vatRate)], // , Validators.required],
      vatAmount: [this.fromDbFormat(this.invoice.vatAmount), Validators.required],
      stampDutyAmount: [this.fromDbFormat(this.invoice.stampDutyAmount)], // , Validators.required],
      nRoundingCMount: [this.fromDbFormat(this.invoice.nRoundingCMount)], // , Validators.required],

      invoiceAmount: [this.fromDbFormat(this.invoice.invoiceAmount), Validators.required],
      amountToBePayd: [this.fromDbFormat(this.invoice.amountToBePayd), Validators.required],

      paymentAmount: [this.fromDbFormat(this.invoice.paymentAmount), Validators.required],

      // TODO - for future proforma management (more details for itself invoice)
      // detailsTotAmountUnit e detailsTaxableAmount manage in cicle, note :
      // aggiungere il control dopo la creazione del formGroup come fa la funzione addTolToForm del quote-evaluation.component.ts
      // this.typeOfLossFormGroup.addControl('advanceAggregateExcess' + idTol, new FormControl());
      // poi c'e' la funzione setValidator con la quale puoi settare la validazione per il control appena creato


      totAmountUnitDetails: [this.fromDbFormat(this.invoice.invoiceDetailsList[0].totAmountUnit), Validators.required],
      // TEST format number
      // totAmountUnitDetails: [this.invoice.invoiceDetailsList[0].totAmountUnit,
      // Validators.compose([Validators.required, Validators.pattern('[0-9][0-9.]*[0-9]')])],
      // Validators.pattern("^[0-9]*$")

      taxableAmountDetails: [this.fromDbFormat(this.invoice.invoiceDetailsList[0].taxableAmount), Validators.required],
      notTaxableAmountDetails: [this.fromDbFormat(this.invoice.invoiceDetailsList[0].notTaxableAmount), Validators.required],
      claimNumberDetails: [this.invoice.invoiceDetailsList[0].claimNumber, Validators.required],

    }
      // , {
      //   validator: [
      //     this.invoiceService.validateDate('invoiceDate')
      //   ]
      // }
    );
  }

  // isMissingInvoiceNumber() {
  // }

  // isMissingInvoiceDate() {
  // }

  // isMissingTest(element: object) {
  //   const dav = 'ide';
  // }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  confirmInvoice() {
    if (this.fieldsAreValid()) {

        this.eventService.broadcastEvent('start-loader');

        this.invoice.taxableAmount = this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value);

        this.invoice.notTaxableAmount = this.formatNumber(this.updateInvoiceForm.get('notTaxableAmount').value);
        this.invoice.percWithHoldTax = this.formatNumber(this.updateInvoiceForm.get('percWithHoldTax').value);
        this.invoice.amountHoldTax = this.formatNumber(this.updateInvoiceForm.get('amountHoldTax').value);
        this.invoice.percPrevFund1 = this.formatNumber(this.updateInvoiceForm.get('percPrevFund1').value);
        this.invoice.cashPrevidAmount = this.formatNumber(this.updateInvoiceForm.get('cashPrevidAmount').value);
        this.invoice.percPrevFund2 = this.formatNumber(this.updateInvoiceForm.get('percPrevFund2').value);
        this.invoice.inpsContribAmount = this.formatNumber(this.updateInvoiceForm.get('inpsContribAmount').value);
        this.invoice.vatRate = this.formatNumber(this.updateInvoiceForm.get('vatRate').value);
        this.invoice.vatAmount = this.formatNumber(this.updateInvoiceForm.get('vatAmount').value);
        this.invoice.stampDutyAmount = this.formatNumber(this.updateInvoiceForm.get('stampDutyAmount').value);
        this.invoice.nRoundingCMount = this.formatNumber(this.updateInvoiceForm.get('nRoundingCMount').value);

        this.invoice.invoiceAmount = this.formatNumber(this.updateInvoiceForm.get('invoiceAmount').value);
        this.invoice.amountToBePayd = this.formatNumber(this.updateInvoiceForm.get('amountToBePayd').value);

        this.invoice.paymentAmount = this.formatNumber(this.updateInvoiceForm.get('paymentAmount').value);

        // TODO - for future proforma management (more details for itself invoice)
        // detailsTotAmountUnit e detailsTaxableAmount manage in cicle
        this.invoice.invoiceDetailsList[0].totAmountUnit = this.formatNumber(this.updateInvoiceForm.get('totAmountUnitDetails').value);
        this.invoice.invoiceDetailsList[0].taxableAmount = this.formatNumber(this.updateInvoiceForm.get('taxableAmountDetails').value);
        this.invoice.invoiceDetailsList[0].notTaxableAmount =
          this.formatNumber(this.updateInvoiceForm.get('notTaxableAmountDetails').value);
        this.invoice.invoiceDetailsList[0].claimNumber = this.updateInvoiceForm.get('claimNumberDetails').value;

        this.saveInvoice(this.invoice);

      }

  }

  fieldsAreValid() {
    if (this.updateInvoiceForm.valid) {

      if (this.formalCtrl()) {

        return true;

      }
    } else {

      this.validationMessages = [];
      this.translateService.translate('_CLAIMS_._FIELD_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
      return false;

    }

    return false;
  }

  saveInvoice(invoice: Invoice) {
    this.invoiceService.saveInvoice(invoice, this.invoice.rejectType.codice).subscribe(
      (response: Outcome) => {
        if (response.code === Outcome.OK) {
          this.activeModal.close(response);
          this.translateService.translate('_CLAIMS_._MESSAGE_._UPDATE_SUCCESSFUL').subscribe(res=> {
            this.alertService.translatedMessage(res);
          }
          )
        } else {
          this.validationMessages = [response.descr];
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error: Error) => {
        console.error(error);
        this.translateService.translate('_CLAIMS_._MESSAGE_._SAVING_ERROR').subscribe(res=> {
          this.validationMessages.push(res);
        }
        )
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  // protected fillupdateInvoiceForm(invoice: Invoice) {
  //   // this.updateInvoiceForm.get('invoiceDate').setValue(new Date(invoice.invoiceDate));
  //   this.updateInvoiceForm.get('invoiceNumber').setValue(invoice.invoiceNumber);
  // }

  formalCtrl() {
    this.validationMessages = [];
    this.cleanErrorStyle('amountToBePayd');
    this.cleanErrorStyle('amountHoldTax');
    this.cleanErrorStyle('cashPrevidAmount');
    this.cleanErrorStyle('stampDutyAmount');
    this.cleanErrorStyle('vatAmount');
    this.cleanErrorStyle('invoiceAmount');
    this.cleanErrorStyle('notTaxableAmount');
    this.cleanErrorStyle('taxableAmount');
    // TODO - for future proforma management (more details for itself invoice)
      // detailsTotAmountUnit e detailsTaxableAmount manage in cicle
    this.cleanErrorStyle('totAmountUnitDetails');
    this.cleanErrorStyle('taxableAmountDetails');
    this.cleanErrorStyle('notTaxableAmountDetails');


    // IMPORTO IMPONIBILE (NTAXABLEAMOUNT) + IMPORTO NON IMPONIBILE (NNOTTAXABLEAMOUNT) maggiore di zero
    if (this.invoiceService.round((this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value) +
      this.formatNumber(this.updateInvoiceForm.get('notTaxableAmount').value)), 2) <= 0) {
      this.setErrorStyle('taxableAmount');
      this.setErrorStyle('notTaxableAmount');
      // Somma di importo imponibile ed importo non imponibile deve essere maggiore di zero
      this.translateService.translate('_CLAIMS_._MESSAGE_._SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // IMPORTO FATTURA - NINVOICEAMOUNT a zero
    if (this.formatNumber(this.updateInvoiceForm.get('invoiceAmount').value) <= 0) {
      this.setErrorStyle('invoiceAmount');
      // Importo fattura deve essere maggiore di zero
      this.translateService.translate('_CLAIMS_._MESSAGE_._INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // IMPORTO DA PAGARE - NAMOUNTTOBEPAYD a zero
    if (this.formatNumber(this.updateInvoiceForm.get('amountToBePayd').value) <= 0) {
      this.setErrorStyle('amountToBePayd');
      // Importo da pagare deve essere maggiore di zero
      this.translateService.translate('_CLAIMS_._MESSAGE_._AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // TODO - for future proforma management (more details for itself invoice)
      // detailsTotAmountUnit e detailsTaxableAmount manage in cicle
    // COSTO TOTALE UNITARIO - NTOTUNITAMOUNT a zero
    if (this.formatNumber(this.updateInvoiceForm.get('totAmountUnitDetails').value) <= 0) {
      this.setErrorStyle('totAmountUnitDetails');
      // Costo totale unitario deve essere maggiore di zero
      this.translateService.translate('_CLAIMS_._MESSAGE_._TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // IMPORTO IMPONIBILE (NTAXABLEAMOUNT) + IMPORTO NON IMPONIBILE (NNOTTAXABLEAMOUNT) maggiore di zero
    if (this.invoiceService.round((this.formatNumber(this.updateInvoiceForm.get('taxableAmountDetails').value) +
      this.formatNumber(this.updateInvoiceForm.get('notTaxableAmountDetails').value)), 2) <= 0) {
      this.setErrorStyle('taxableAmountDetails');
      this.setErrorStyle('notTaxableAmountDetails');
      // Somma di importo imponibile ed importo non imponibile deve essere maggiore di zero
      this.translateService.translate('_CLAIMS_._MESSAGE_._SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // IMPORTO DA PAGARE amounttobepayd < (IMPORTO IMPONIBILE taxableamount + IMPORTO NON IMPONIBILE notTaxableAmount)
    if (this.formatNumber(this.updateInvoiceForm.get('amountToBePayd').value) <
      this.invoiceService.round((this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value) +
      this.formatNumber(this.updateInvoiceForm.get('notTaxableAmount').value)), 2)) {
      this.setErrorStyle('amountToBePayd');
      this.setErrorStyle('taxableAmount');
      this.setErrorStyle('notTaxableAmount');
      // Importo da pagare non puo essere minore della somma di importo imponibile ed importo non imponibile
      this.translateService.translate('_CLAIMS_._MESSAGE_._AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // IMPORTO RITENUTA NAMOUNTHOLDTAX > IMPORTO IMPONIBILE taxableamount
    if (this.formatNumber(this.updateInvoiceForm.get('amountHoldTax').value) >
      this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value)) {
      this.setErrorStyle('amountHoldTax');
      this.setErrorStyle('taxableAmount');
      // Importo ritenuta non puo essere maggiore di importo imponibile
      this.translateService.translate('_CLAIMS_._MESSAGE_._HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // Importo cassa previdenziale (NCASHPREVIDAMOUNT) > importo imponibile (NTAXABLEAMOUNT)
    if (this.formatNumber(this.updateInvoiceForm.get('cashPrevidAmount').value) >
      this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value)) {
      this.setErrorStyle('cashPrevidAmount');
      this.setErrorStyle('taxableAmount');
      // Importo cassa previdenziale non puo essere maggiore di importo imponibile
      this.translateService.translate('_CLAIMS_._MESSAGE_._CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // Importo bolli (NSTAMPDUTYAMOUNT) > importo imponibile (NTAXABLEAMOUNT)
    if (this.formatNumber(this.updateInvoiceForm.get('stampDutyAmount').value) >
      this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value)) {
      this.setErrorStyle('stampDutyAmount');
      this.setErrorStyle('taxableAmount');
      // Importo bolli non puo essere maggiore di importo imponibile
      this.translateService.translate('_CLAIMS_._MESSAGE_._STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )

    }

    // Importo iva (NVATAMOUNT) > importo imponibile (NTAXABLEAMOUNT)
    if (this.formatNumber(this.updateInvoiceForm.get('vatAmount').value) >
      this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value)) {
      this.setErrorStyle('vatAmount');
      this.setErrorStyle('taxableAmount');
      // Importo iva non puo essere maggiore di importo imponibile
      this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // Importo totale documento (NINVOICEAMOUNT) <>
    // ∑(importo imponibile (NTAXABLEAMOUNT) + importo non imponibile (NNOTAXABLEAMOUNT)
    // + importo cassa previdenziale (NCASHPREVIDAMOUNT)
    // + importo bolli (NSTAMPDUTYAMOUNT) + importo iva (NVATAMOUNT))
    // aggiungo un Math.round perchè javascript sballa le somme complesse, ad esempio in una somma ha dato 349.15000000000003
    if (this.formatNumber(this.updateInvoiceForm.get('invoiceAmount').value) !==
        (this.invoiceService.round(this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value) +
        this.formatNumber(this.updateInvoiceForm.get('notTaxableAmount').value) +
        this.formatNumber(this.updateInvoiceForm.get('cashPrevidAmount').value) +
        this.formatNumber(this.updateInvoiceForm.get('stampDutyAmount').value) +
        this.formatNumber(this.updateInvoiceForm.get('vatAmount').value), 2))) {
      this.setErrorStyle('invoiceAmount');
      this.setErrorStyle('taxableAmount');
      this.setErrorStyle('notTaxableAmount');
      this.setErrorStyle('cashPrevidAmount');
      this.setErrorStyle('stampDutyAmount');
      this.setErrorStyle('vatAmount');
      // Importo fattura diverso da somma di importo imponibile, importo non imponibile,
      // importo cassa previdenziale, importo bolli, importo iva
      this.translateService.translate('_CLAIMS_._MESSAGE_._INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )

    }

    // Importo da pagare (NAMOUNTTOBEPAYD) <> (importo totale documento (NINVOICEAMOUNT) – importo ritenuta (NAMOUNTHOLDTAX))
    if (this.formatNumber(this.updateInvoiceForm.get('amountToBePayd').value) !==
        (this.invoiceService.round(this.formatNumber(this.updateInvoiceForm.get('invoiceAmount').value) -
        this.formatNumber(this.updateInvoiceForm.get('amountHoldTax').value), 2))) {
      this.setErrorStyle('amountToBePayd');
      this.setErrorStyle('invoiceAmount');
      this.setErrorStyle('amountHoldTax');
      // Importo da pagare diverso da importo fattura meno importo ritenuta
      this.translateService.translate('_CLAIMS_._MESSAGE_._AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // TODO - for future proforma management (more details for itself invoice)
      // detailsTotAmountUnit e detailsTaxableAmount manage in cicle
    // La ∑ dell’importo imponibile di tutti i dettagli della fattura (NTAXABLEAMOUNT)
    // <> dall’importo imponibile della fattura (NTAXABLEAMOUNT)
    if (this.formatNumber(this.updateInvoiceForm.get('taxableAmountDetails').value) !==
        this.formatNumber(this.updateInvoiceForm.get('taxableAmount').value)) {
      this.setErrorStyle('taxableAmountDetails');
      this.setErrorStyle('taxableAmount');
      // Somma importo imponibile dei dettagli diverso da importo imponibile fattura
      this.translateService.translate('_CLAIMS_._MESSAGE_._SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
      
    }

    // TODO - for future proforma management (more details for itself invoice)
      // detailsTotAmountUnit e detailsTaxableAmount manage in cicle
    // La ∑ dell’importo non imponibile di tutti i dettagli della fattura (NNOTAXABLEAMOUNT)
    // <> dall’importo non imponibile della fattura (NNOTAXABLEAMOUNT)
    if (this.formatNumber(this.updateInvoiceForm.get('notTaxableAmountDetails').value) !==
        this.formatNumber(this.updateInvoiceForm.get('notTaxableAmount').value)) {
      this.setErrorStyle('notTaxableAmountDetails');
      this.setErrorStyle('notTaxableAmount');
      // Somma importo non imponibile dei dettagli diverso da importo non imponibile fattura
      this.translateService.translate('_CLAIMS_._MESSAGE_._SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    // TODO - for future proforma management (more details for itself invoice)
      // detailsTotAmountUnit e detailsTaxableAmount manage in cicle
    // La ∑ dell’importo costo totale unitario di tutti i dettagli della fattura (NTOTUNITAMOUNT)
    // <> dall’importo totale documento della fattura (NINVOICEAMOUNT)
    if (this.formatNumber(this.updateInvoiceForm.get('totAmountUnitDetails').value) !==
        this.formatNumber(this.updateInvoiceForm.get('invoiceAmount').value)) {
      this.setErrorStyle('totAmountUnitDetails');
      this.setErrorStyle('invoiceAmount');
      // Somma importo costo totale unitario dei dettagli diverso da importo fattura
      this.translateService.translate('_CLAIMS_._MESSAGE_._SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT').subscribe(res=> {
        this.validationMessages.push(res);
      }
      )
    }

    if (this.validationMessages.length > 0) {
      return false;
    }
    return true;
  }

  setErrorStyle(fieldName: string) {
    if (document.getElementById(fieldName) != null) {
      document.getElementById(fieldName).style.cssText = 'border: 2px solid #d22323 !important;color: #d22323 !important;';
    }
  }

  cleanErrorStyle(fieldName: string) {
    if (document.getElementById(fieldName) != null) {
      // console.log(fieldName);
      // console.log(document.getElementById(fieldName));
      document.getElementById(fieldName).style.cssText = '';
    }
  }

  formatNumber(field: string) {
    return parseFloat(String(field).replace(',', '.'));
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.updateInvoiceForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  fromDbFormat(field: number): any {

    if (field != null) {
      return String(field).replace('.', ',');
    }

    return field;
  }

  nullToEmpty(field: any) {
    if (field === null) {
      return '';
    }
    return field;
  }

}

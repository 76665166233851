<div class="row">
  <form [formGroup]="dataForm">
    <div class="col-md-6 col-xs-12 col-sm-12">
      <div class="form-group row">
        <div class="col-md-3 col-xs-12 col-sm-3" *ngIf="isFieldVisible('isOwner')">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="isOwner" (change)="disablePassenger()">
            <label rgiRxLabel class="pr-5">{{ '_CLAIMS_._OWNER'|translate }}</label>
          </rgi-rx-form-field>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-3" *ngIf="isFieldVisible('isDriver')">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="isDriver"
              (change)="setDriverOrPassenger(RoleEnum.DRIVER, $event)">
            <label rgiRxLabel class="pr-5">{{ '_CLAIMS_._DRIVER'|translate }}</label>
          </rgi-rx-form-field>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-3" *ngIf="isFieldVisible('isPassenger')">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="isPassenger"
              (change)="setDriverOrPassenger(RoleEnum.PASSENGER, $event)">
            <label rgiRxLabel class="pr-5">{{ '_CLAIMS_._PASSENGER'|translate }}</label>
          </rgi-rx-form-field>
        </div>

      </div>
    </div>
    <div class="col-md-12 col-xs-12 col-sm-12">
      <div class="form-group row d-flex-center"
        *ngIf="isFieldVisible('chkMaterial') && (isDamageToThingsVisible(dataForm) || driverIsNotHolderOrOwner)">
        <div class="col-md-6 col-xs-12 col-sm-6">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="chkMaterial" (change)="isDamageToThings($event)">
            <label rgiRxLabel class="pr-5">{{ '_CLAIMS_._DAMAGE_TO_THINGS'|translate }}</label>
          </rgi-rx-form-field>
        </div>
        <div class="col-md-6 col-xs-12 col-sm-6" *ngIf="isFieldVisible('materialDescription')">
          <rgi-rx-form-field>
            <input rgiRxInput formControlName="materialDescription" (blur)="outputFormChange()">
            <label rgiRxLabel>{{ '_CLAIMS_._DESCRIPTION'|translate }}</label>
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="form-group row d-flex-center">
        <div class="col-md-6 col-xs-12 col-sm-6">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="isIncapacitated" (change)="isIncapacitatedChange($event)">
            <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._INCAPACITATED'|translate }}</label>
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="form-group row d-flex-center" *ngIf="dataForm.controls.isMinor.value">
        <div class="col-md-6 col-xs-12 col-sm-6">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="isMinor">
            <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._MINOR'|translate }}</label>
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="row" *ngIf="dataForm.controls.isMinor.value || dataForm.controls.isIncapacitated.value">
        <div class="col-md-6 col-xs-12 col-sm-12">
          <claims-subject-add-minor-guardian [selectedNode]="selectedNode" [activeRouteId]="activeRouteId"
            [listMinorGuardian]="listMinorGuardian"
            (outputDataForm)="listMinorGuardianChange($event)"></claims-subject-add-minor-guardian>
        </div>
      </div>
      <div class="form-group row d-flex-center"
        *ngIf="isFieldVisible('chkInjury') && (isPhisicalInjuryVisible(dataForm) || driverIsNotHolderOrOwner)">
        <div class="col-md-6 col-xs-12 col-sm-6">
          <rgi-rx-form-field>
            <input type="checkbox" rgiRxInput formControlName="chkInjury" (change)="outputFormChange()">
            <label rgiRxLabel class="pr-5">{{ '_CLAIMS_._PHYSICAL_INJURY'|translate }}</label>
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {Notification} from './notification';

export class AuthorizationRequest {

  constructor(
    public id: string,
    public type: string,
    public dossierNumber: string,
    public requestDate: Date,
    public derogationLevel: number,
    public applicant: GenericEntity,
    public company: GenericEntity,
    public salePoint: GenericEntity,
    public salePointAddress: string,
    public lastAction: GenericEntity,
    public competenceLevel: GenericEntity,
    public lastActionDate: Date,
    public requestState: GenericEntity,
    public manualNotes: string,
    public productType: GenericEntity,
    public product: GenericEntity,
    public policyholder: GenericEntity,
    public fiscalCode: string,
    public proposalDescription: string,
    public referenceUser: GenericEntity,
    public referenceUsers: Array<GenericEntity>,
    public priority: GenericEntity,
    public priorities: Array<GenericEntity>,
    public notes: string,
    public notifications: Array<Notification>
  ) {
  }
}

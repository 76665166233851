<div class="anag-w-100 anag-section-card" xmlns="http://www.w3.org/1999/html">
  <div class="anag-section-card-header">
      <span class="anag-section-card-icon rgi-ui-icon-domicile">
    </span>
    <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._DOMICILES_' | translate}}</span>
  </div>
  <div class="anag-section-content">
    <span *ngIf="!otherAddressParty || otherAddressParty?.length === 0">{{'_ANAG_._LABEL_._NO_ADDRESS_MSG_' | translate}}</span>
    <div class="rgi-ui-grid">
      <div class="rgi-ui-col">
        <div rgi-rx-accordion>
          <rgi-rx-expansion-panel color="default" *ngFor="let otherAddress of otherAddressParty; let i = index" [attr.data-qa]="'anag-other-address_'+i">
            <rgi-rx-expansion-panel-header>
              {{getValuedAddressLabel(otherAddress.baseAddress) | uppercase}}
            </rgi-rx-expansion-panel-header>
            <div class="rgi-ui-grid-2">
              <div class="rgi-ui-col">
                <div>{{'_ANAG_._LABEL_._PRINCIPAL_ADDRESS_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col">
                <b translate>{{otherAddress.mainAddress ? '_ANAG_._LABEL_._YES_' : '_ANAG_._LABEL_._NO_'}}</b>
              </div>
              <div class="rgi-ui-col">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._TYPE_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col">
                <b>{{otherAddress.addressType ? otherAddress.addressType.descrizione : '-'}}</b>
              </div>
              <div class="rgi-ui-col">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._FAX_NO_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col">
                <b>{{otherAddress.fax.number ? otherAddress.fax.internationalPrefix + ' ' + otherAddress.fax.localPrefix + ' ' + otherAddress.fax.number : '-'}}</b>
              </div>
              <div class="rgi-ui-col">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._LANDLINE_NO_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col">
                <b>{{otherAddress.phone.number ? ' ' + otherAddress.phone.internationalPrefix + ' ' + otherAddress.phone.localPrefix + ' ' + otherAddress.phone.number : '-'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availabilityStartDate">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._AVAILABILITY_START_DATE_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availabilityStartDate">
                <b>{{otherAddress.availabilityStartDate | date : 'dd/MM/yyyy'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availabilityEndDate">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._AVAILABILITY_END_DATE_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availabilityEndDate">
                <b>{{otherAddress.availabilityEndDate | date : 'dd/MM/yyyy'}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availableFrom">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._AVAILABLE_FROM_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availableFrom">
                <b>{{otherAddress.availableFrom}}</b>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availableUntil">
                <div class="story-grid-column-content">{{'_ANAG_._LABEL_._AVAILABLE_UNTIL_' | translate}}:</div>
              </div>
              <div class="rgi-ui-col" *ngIf="otherAddress.availableUntil">
                <b>{{otherAddress.availableUntil}}</b>
              </div>
            </div>
            <div class="rgi-ui-col rgi-ui-grid-2">
              <button type="button" (click)="editOtherAddress(otherAddress, i)" [disabled]="!canEdit"
                      class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-edit2 rgi-anag-text-tone"></button>
              <button type="button" (click)="deleteOtherAddress(i)" [disabled]="!canEdit"
                      class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-delete rgi-anag-text-tone"></button>
            </div>
          </rgi-rx-expansion-panel>
        </div>
      </div>
    </div>
    <div class="rgi-ui-grid-1">
      <div class="rgi-ui-col">
        <div class="rgi-ui-panel-footer">
          <button type="button" class="rgi-ui-btn rgi-ui-btn-outline rgi-anag-text-tone" (click)="addOtherAddress()"
                  [disabled]="!canEdit" data-qa="anag-btn-add-address" translate>_ANAG_._BTN_._ADD_ADDRESS_
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

export class ErrorMessage {
  code: string;
  description: string;
  title: string;
  type: string;
}

export class GenericError {
  constructor(
    public code: string | null,
    public messages: Array<ErrorMessage>) {
  }
}

export class Violation {
  code: string;
  message: string;
  level: string;
  constraintType: string;
}

export const ERROR_TAG_SNACKBAR = {
  COMMISSIONS_MODAL: 'COMMISSIONS_MODAL',
  COMMISSION_MODAL_ALLRISK: 'COMMISSION_MODAL_ALLRISK'
};

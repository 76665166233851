import {Component, OnInit} from '@angular/core';
import {
  ReIssueReprintDocumentsNavigation,
  ReIssueReprintDocumentsState,
  ReIssueReprintDocumentsStateManagerService
} from '../re-issue-state-manager/re-issue-reprint-documents-state-manager.service';
import {
  ReprintDocumentsStepperSection,
  reprintDocumentStepperLabels
} from '../re-issue-state-manager/re-issue-reprint-movements-state-manager.service';
import {PcPolicy} from '../../models/pc-portfolio-models/policy-models/pc-policy';

@Component({
  selector: 're-issue-reprint-documents',
  templateUrl: './re-issue-reprint-documents.component.html',
  styleUrls: ['./re-issue-reprint-documents.component.css']
})
export class ReIssueReprintDocumentsComponent implements OnInit {

  movementId: number;
  policy: PcPolicy;
  stepperLabels = reprintDocumentStepperLabels;
  currentStep = ReprintDocumentsStepperSection.documents;

  constructor(
    public stateManager: ReIssueReprintDocumentsStateManagerService<ReIssueReprintDocumentsState>,
  ) {
  }

  ngOnInit() {
    const navigationData = this.stateManager.activeRoute.getRouteData<ReIssueReprintDocumentsNavigation>();
    this.policy = new PcPolicy();
    this.policy.contractNumber = {policy: navigationData.policyNumber};
    this.movementId = navigationData.movement.objectId;
  }

  goToClientDetails() {
    console.log('goToClientDetails clicked');
  }

}

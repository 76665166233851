import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class ModalService {

  private messagesSentSource = new Subject<{ messages: Array<string>, title?: string, onClose?: () => void, onConfirm?: () => void }>();
  private messagesSent: Observable<{ messages: Array<string>, title?: string, onClose?: () => void, onConfirm?: () => void }> =
    this.messagesSentSource.asObservable();

  private messagesReadSource = new Subject();
  private messagesRead: Observable<any> = this.messagesReadSource.asObservable();

  constructor() { }

  open(messages: Array<string>, title?: string, onClose?: () => void, onConfirm?: () => void) {
    this.messagesSentSource.next({
      messages,
      title,
      onClose,
      onConfirm
    });
  }

  close() {
    this.messagesReadSource.next();
  }

  observeMessagesSent(): Observable<{ messages: Array<string>, title?: string, onClose?: () => void, onConfirm?: () => void }> {
    return this.messagesSent;
  }

  observeMessagesRead(): Observable<any> {
    return this.messagesRead;
  }
}

import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { EvaluatorObjectI } from '../../../../evaluation/models';
import { EvalProductCfg, EvalSectionCfg } from '../../../../state/models/eval-product-cfg';
import { EvalValidationItem } from '../../../../state/state-manager-survey.module';

@Component({
  selector: 'survey-eval-section',
  templateUrl: './survey-eval-section.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SurveyEvalSectionComponent implements OnInit {

  @Input() coverages:EvaluatorObjectI[];
  @Input() sectionCfg: EvalSectionCfg;
  @Input("editable") isEdit: boolean = false;
  @Input() productCfg: EvalProductCfg;
  @Input() validation:{ [coverageCode: string]: EvalValidationItem };

  @Output("clickInfo") onClickInfoEmitter = new EventEmitter<{covOrPkg:EvaluatorObjectI, isPackage:boolean}>();
  @Output("select") onSelectCoverageEmitter = new EventEmitter<{covOrPkg:EvaluatorObjectI, isPackage:boolean, selected:boolean}>();


  constructor() {

  }


  ngOnInit() {
    this.coverages.sort(this.compareByNameHandler);
  }


  onClickInfo(eventData:{covOrPkg:EvaluatorObjectI, isPackage:boolean}){
    this.onClickInfoEmitter.emit(eventData);
  }


  onSelectCoverage(data:{covOrPkg:EvaluatorObjectI, isPackage:boolean, selected: boolean}){
    this.onSelectCoverageEmitter.emit(data);
  }


  isCoverageHidden(covOrPkg: EvaluatorObjectI):boolean{
    
    const cfg = this.sectionCfg.isPackage ?
        this.productCfg.pkgsCfg.pkgsMap[covOrPkg.package.id]:
        this.productCfg.covsCfg.covsMap[covOrPkg.code]
    
    return !cfg.visible;
  }


  getValidation(cov:EvaluatorObjectI){
    if(!this.validation) return null;
    if(this.sectionCfg.isPackage){
      return this.validation[cov.package.code];
    }else {
      return this.validation[cov.code];
    }
  }


  protected compareByNameHandler(o1:EvaluatorObjectI, o2:EvaluatorObjectI){
    const n1 = o1.name.toUpperCase(), n2 = o2.name.toUpperCase();
    if( n1 > n2 ) {
      return 1;
    }else if( n1 < n2) {
      return -1;
    }
    return 0;
  }

  

}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'rgiCurrency'
})
export class RgiCurrencyPipe implements PipeTransform {

  transform(value: number): string {
    const rounded: string[] = (Math.round(value * 100) / 100).toString().split('.');
    rounded[1] = this.toTwoDigits(rounded[1]);
    return '€ ' + rounded[0] + ',' + rounded[1];
  }

  private toTwoDigits(value: string): string {
    value = value ? value : '';
    if (value.length > 1) {
      return value;
    } else {
      value = value + '0';
      return this.toTwoDigits(value);
    }
  }

}

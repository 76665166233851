import {ContractDetails, MenuOperation} from '../../group-policy-models/group-policy-inquiry';
import {
  GroupPolicyStateManagerInquiry
} from '../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import { OnInit, Directive } from '@angular/core';
import {GroupPolicyStateInquiry} from '../../group-policy-state/group-policy-state';

@Directive()
export abstract class GpInquiryComponentDetail implements OnInit {
  contractDetails: ContractDetails;
  contractMenuOperations: MenuOperation[];
  inquiryMode: string;

  protected constructor(protected stateMngr: GroupPolicyStateManagerInquiry) {
    this.contractDetails = stateMngr.getCurrentState().contractDetails;
    this.contractMenuOperations = stateMngr.getCurrentState().contractMenuOperations;
    this.inquiryMode = stateMngr.getCurrentState().inquiryMode;
  }

  ngOnInit(): void {
    this.stateMngr.getState$().subscribe((nextState: GroupPolicyStateInquiry) => {
      this.contractDetails = nextState.contractDetails;
      this.contractMenuOperations = nextState.contractMenuOperations;
      this.inquiryMode = nextState.inquiryMode;
    });
  }
}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title">{{ '_CLAIMS_._RETURN_OF_RECEIPT' | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal('Close')"></span>
            </h3>
        </div>

        <!-- Corpo del form -->
        <div class="modal-body" style="padding-top: 5px; height: 520px;">
            <div class="card shadow">
                <!-- Riga contenente le checkbox -->
                <div class="row">
                    <div class="col-xs-6 pc1" style="font-size: 14px;">
                        <rgi-rx-form-field>
                            <input type="checkbox" rgiRxInput [(ngModel)]="detailReceiptReturn.acceptedCheckbox"
                                [ngModelOptions]="{standalone: true}" (ngModelChange)="checkboxCheckUncheck('accept')">
                            <label rgiRxLabel class="checkbox-label mlc1">{{ '_CLAIMS_._RECEIPT_ACCEPTED' | translate }}</label>
                        </rgi-rx-form-field>
                    </div>

                    <div class="col-xs-6 pc1" style="font-size: 14px;">
                        <rgi-rx-form-field>
                            <input type="checkbox" rgiRxInput [(ngModel)]="detailReceiptReturn.rejectedCheckbox"
                                [ngModelOptions]="{standalone: true}" (ngModelChange)="checkboxCheckUncheck('reject')">
                            <label rgiRxLabel class="checkbox-label mlc1">{{ '_CLAIMS_._RECEIPT_REJECTED' | translate }}</label>
                        </rgi-rx-form-field>
                    </div>
                </div>

                <!-- Riga contenente la data -->
                <form [formGroup]="detailReceiptReturnForm">
                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <rgi-rx-form-field>
                                <label rgiRxLabel>{{ '_CLAIMS_._RETURN_DATE' | translate }}</label>
                                <rgi-rx-date-picker [openOnClick]="false">
                                    <input [rgiRxDateTimeInput]="consDate" formControlName="dateReceipt"
                                        data-qa="rgi-update-reserve-reopen-date" placeholder="{{datepickerPlaceholder}}"
                                        [max]="today">
                                </rgi-rx-date-picker>
                                <rgi-rx-date-time #consDate></rgi-rx-date-time>
                            </rgi-rx-form-field>
                        </div>
                        
                        <div class="col-xs-6"
                            *ngIf="detailReceiptReturn.rejectedCheckbox && reasonRejectedList !== undefined && reasonRejectedList.length > 0 && reasonRejectedList[0] !== undefined">
                            <rgi-rx-form-field>
                                <label rgiRxLabel>{{ '_CLAIMS_._REASON_REJECTED' | translate }} </label>
                                <select rgiRxNativeSelect formControlName="reasonRejected">
                                    <option *ngFor="let reason of reasonRejectedList; " value={{reason.code}}>
                                        {{reason.description}}</option>
                                </select>
                            </rgi-rx-form-field>
                        </div>
                    </div>
                    <!-- Riga contenente il campo note -->
                    <div class="row">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                            <textarea rgiRxInput formControlName="note" (ngModelChange)="checkValidatorsForm()"></textarea>
                        </rgi-rx-form-field>
                    </div>
                </form>

            </div>
        </div>
        <div class="btn-group btn-group-justified btn-group-justified-claim">
            <div class="btn-group ">
                <button rgi-rx-button color="primary" class="w-100-custom" (click)="closeModal('Close')">{{ '_CLAIMS_._BUTTONS_._CLOSE' |
                    translate }}</button>
            </div>
            <div class="btn-group">
                <button rgi-rx-button color="primary" [disabled]="invalidConfirmButton || detailReceiptReturnForm.invalid" class="w-100-custom"
                    (click)="confirmReceiptReturn()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
            </div>
        </div>
    </div>
</div>
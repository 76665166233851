import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {
  QuestionFlateResponse
} from '../../../models/pc-portfolio-models/quotation-models/pc-questionnaire-flat-iresponse';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioQuestionnairesResourceService {

  protected readonly PROPOSALS_PATH = 'proposals/';
  protected readonly QUESTIONNAIRES_PATH = '/questionnaires';
  private apiPcPortfolioConf: any;

  constructor(
    protected http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  getQuestionnaires(resourceId: string): Observable<Array<QuestionFlateResponse>> {
    return this.http.get<Array<QuestionFlateResponse>>(this.getQuestionnairesUri(resourceId)).pipe(
      share()
    );
  }

  putQuestionnaires(resourceId: string, questionnaire: Array<QuestionFlateResponse>): Observable<Array<QuestionFlateResponse>> {
    return this.http.put<Array<QuestionFlateResponse>>(this.getQuestionnairesUri(resourceId), questionnaire).pipe(
      share()
    );
  }

  getQuestionnairesUri(resourceId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      resourceId +
      this.QUESTIONNAIRES_PATH;
  }
}

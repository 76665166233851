<rgi-rx-stepper #RxStepper #customNavi *ngIf="(caiOpeningState.getState$() | async) as detailStep"
    (selectionChange)="onSelectionChange($event)" [vertical]="vertical" [showIndex]="showIndex">
    <rgi-rx-step [selectable]="false" label="{{'_CLAIMS_._TITLE_._SELECT_POLICY' | translate}}">
        <div class="container-policy">
            <div *ngIf="viewListPolicy">
                <div class="row mbc-2 mtc-2">
                    <claims-dsc-policy-list [policyList]="detailStep.detailNewClaimInfo.policiesList"
                        [selectedCategories]="detailStep.detailNewClaimInfo.filterApplied.categories"
                        (searchSimilarClaims)="searchSimilarClaims($event)" [showTitle]="true"></claims-dsc-policy-list>
                </div>
                <div class="btn-bottom btn-group-justified">
                    <div class="btn-group">
                        <button rgi-rx-button class="wc-100" color="primary" (click)="caiOpeningState.exit()"
                            rgiRxStepperPrevious>
                            {{'_CLAIMS_._BUTTONS_._BACK' | translate}}
                        </button>
                    </div>
                    <!-- *ngIf="customNavi.selectedIndex > 0" -->
                </div>
            </div>
            <div *ngIf="viewSimilarClaims && detailStep.similarClaims">
                <div class="row mbc-2 mtc-2">
                    <claims-dsc-similar-claims [similarClaimsList]="detailStep.similarClaims.listPotentialClaims"
                        (outputShowDetail)="showDetailClaim($event)"
                        [showTitleTotal]="true"></claims-dsc-similar-claims>
                </div>
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button rgi-rx-button class="wc-100" color="primary" (click)="backToPolicyList()"
                            rgiRxStepperPrevious>
                            {{'_CLAIMS_._BUTTONS_._BACK' | translate}}
                        </button>
                    </div>
                    <div class="btn-group">
                        <button rgi-rx-button class="wc-100" color="primary" (click)="initQuestionnaire()">
                            {{'_CLAIMS_._BUTTONS_._NEW' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </rgi-rx-step>
    <rgi-rx-step [selectable]="false" label="{{'_CLAIMS_._TITLE_._QUESTIONNAIRE_TITLE' | translate}}">
        <div class="row ptc-1" *ngIf="detailStep.questionnaire && detailStep.questionnaire.items.length > 0">
            <claims-cai-opening-questionnaire [inputQuestionnaire]="detailStep.questionnaire"
            (outputQuestionnaire)="changeQuestionnaireStatus($event)"
            ></claims-cai-opening-questionnaire>
        </div>
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button rgi-rx-button class="wc-100" color="primary" rgiRxStepperPrevious>
                    {{'_CLAIMS_._BUTTONS_._BACK' | translate}}
                </button>
            </div>
            <div class="btn-group">
                <button rgi-rx-button class="wc-100" color="primary" [disabled]="!detailStep.questionnaire.isValid"
                    (click)="goToCaiModule()">
                    {{'_CLAIMS_._BUTTONS_._NEXT' | translate}}
                </button>
            </div>
        </div>
    </rgi-rx-step>
    <rgi-rx-step [selectable]="false" label="{{'_CLAIMS_._TITLE_._DATA_COLLECTION' | translate}}">
        <div class="row" *ngIf="showSingleCai">
            <claims-cai-opening-single
                [activeRouteId]="activeRoute.id"
                [selectedNode]="detailStep.selectedNode"
                [caiOpeningState$]="caiOpeningState.getState$()"
                (outputPolicyHolder)="updatePolicyHolder($event)"
                (outputVehicle)="updateVehicle($event)"
                (outputPolicyData)="updatePolicyData($event)"
                (outputOwner)="updateOwner($event)"
                (outputDriver)="updateDriver($event)"
                (outputDamagedParts)="updateDamagedParts($event)"
                (outputCarBodywork)="updateCarBodywork($event)"
                (outputIncidentData)="updateIncidentData($event)"
                (outputAdditionalData)="updateAdditionalData($event)"
                (outputCircumstance)="updateCircumstance($event)"
                (outputAddress)="updateAddress($event)"
                (outputPartyRole)="outputPartyRole($event)"
                (outputAddressDataDamagedParts)="updateAddressDataDamagedParts($event)"
                (outputHolderHeir)="outputHolderHeirData($event)"
                (outputOwnerHeir)="outputOwnerHeirData($event)"
                (outputDriverHeir)="outputDriverHeirData($event)">
            >
            </claims-cai-opening-single>
        </div>
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button rgi-rx-button class="wc-100" color="primary" rgiRxStepperPrevious>
                    {{'_CLAIMS_._BUTTONS_._BACK' | translate}}
                </button>
            </div>
            <div class="btn-group">
                <button rgi-rx-button class="wc-100" color="primary" [disabled]="(!detailStep.occurrenceData.incidentData.isValidForm
                || !detailStep.occurrenceData.addressEntity.isValidForm || ( !detailStep.occurrenceData.circumstanceIncident.isValidForm &&  detailStep.occurrenceData.enableAccordionCircumstanceData))
                || (
                    detailStep.singleCai.roles.holder.objectId === ''
                    || detailStep.singleCai.roles.owner.objectId === ''
                    )" (click)="confirmModule(true)">
                    {{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}
                </button>
            </div>
        </div>
    </rgi-rx-step>
</rgi-rx-stepper>

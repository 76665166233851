import {Injectable} from '@angular/core';
import {EvaluatedItem} from '@rgi/ng-passpropro';
import {NotificationService, Toast} from '@rgi/ng-passpropro-core';
import {EvaluatorProductI} from '../../evaluation/models/evaluator-products';
import {EvalProductsCfg} from '../models/eval-product-cfg';
import {
  EvalValidationItem,
  EvalValidationMap,
  EvalValidationMessage,
  EvalValidationProduct
} from '../models/eval-validation';


@Injectable()
export class SurveyEvalValidationTool {

    readonly NOTIFICATION_TAG = 'validations';


    constructor(
        private notificationService: NotificationService
    ){

    }


    createValidationMap(products: EvaluatorProductI[], evaluatorValidations: EvaluatedItem[]): EvalValidationMap {

        if (!evaluatorValidations || !evaluatorValidations.length) {
            return this.createValidValidationMap();
        }

        const roots: EvalValidationItem[] = this.createRootsValidationItems(evaluatorValidations);

        const productsMap = this.createProductsMap(evaluatorValidations, products);

        this.fillMessagesForPackages(evaluatorValidations, productsMap, products);

        this.fillMessagesForCoverages(evaluatorValidations, productsMap, products);

        const validationMap: EvalValidationMap = this.makeValidationMap(roots, productsMap);

        return validationMap;
    }

    isMessageNotifiable(message: EvalValidationMessage):boolean{
        return true;
    }

    hasNotifiableMessages(product: EvalValidationProduct):boolean{
        return true;
    }

    notifyValidation(validation: EvalValidationMap, productsCfg: EvalProductsCfg){
        this.clearNotifications();

        validation.roots.forEach(
            root => {
                root.messages.forEach(
                    message => {
                        if (this.isMessageNotifiable(message)) {
                            const toast = new Toast();
                            toast.autoHide = false;
                            toast.status = 'danger';
                            toast.delay = 0;
                            toast.body = message.msg;
                            toast.header = null;
                            toast.tag = this.NOTIFICATION_TAG
                            this.notificationService.push(toast);
                        }
                    }
                )
            }
        );
        Object.values(validation.products).forEach(
            product => {
                if (this.hasNotifiableMessages(product)) {
                    const toast = new Toast();
                    toast.autoHide = false;
                    toast.status = 'danger';
                    toast.delay = 0;
                    toast.body = 'NG_PASSPROPRO_SURVEY.validation-product-title' + productsCfg.prodsCfgMap[product.code].label; // fixme was instant
                    toast.header = null;
                    toast.tag = 'validations';
                    this.notificationService.push(toast);
                }
            }
        );
    }


    clearNotifications(){
        this.notificationService.clearTag(this.NOTIFICATION_TAG);
    }



    protected createValidValidationMap():EvalValidationMap{
        return {
            isValid: true,
            roots: [],
            products: {},
            hasInvalidProducts: false
        }
    }


    protected makeValidationMap(roots: EvalValidationItem[],productsMap: { [productCode: string]: EvalValidationProduct; }):EvalValidationMap{
        const hasInvalidProducts = Object.keys(productsMap).length > 0;
        const hasInvalidRoots = roots.length > 0;
        const isValid = !hasInvalidRoots && !hasInvalidProducts;

        const validationMap: EvalValidationMap = {
            isValid,
            roots,
            products: productsMap,
            hasInvalidProducts
        }

        return validationMap;
    }



    protected createRootsValidationItems(evaluatorValidations:EvaluatedItem[]):EvalValidationItem[]{
        return evaluatorValidations
            .filter(this.invalidFilterFor(null))
            .map(
                validation => (
                    {
                        messages: [ this.createValidationMessage(validation) ],
                        validation
                    }
                )
            );
    }


    protected fillMessagesForPackages(evaluatorValidations:EvaluatedItem[], productsMap:{ [productCode: string]: EvalValidationProduct; }, products: EvaluatorProductI[]){
        evaluatorValidations
            .filter(this.invalidFilterFor('package'))
            .forEach(
                validation => {
                    const productsWithPkg = products.filter(p => p.packages.some(pkg => pkg.package.code == validation.resource.id));
                    this.fillMessagesFor('packages', productsMap, productsWithPkg, validation);
                }
            );
    }


    protected fillMessagesForCoverages(evaluatorValidations:EvaluatedItem[], productsMap:{ [productCode: string]: EvalValidationProduct; }, products: EvaluatorProductI[]){
        evaluatorValidations
            .filter(this.invalidFilterFor('coverage'))
            .forEach(
                validation => {
                    const [sectionCode, coverageCode] = validation.resource.id.split('.');
                    const productsWithCov = products
                        .filter(p => {
                            const section = p.sections.find(s => s.code == sectionCode);
                            if (!section) return false;
                            return section.coverages.some(cov => cov.code == coverageCode)
                        });
                    this.fillMessagesFor('coverages', productsMap, productsWithCov, validation);
                }
            );
    }


    protected createProductsMap(evaluatorValidations:EvaluatedItem[], prods: EvaluatorProductI[]):{ [prodCode: string]: EvalValidationProduct; }{
        return evaluatorValidations
            .filter(this.invalidFilterFor('product'))
            .reduce((map, validation) => {
                const prod = prods.find( p => p.code==validation.resource.id );
                if(!prod){
                    return map;
                }
                this.ensureProductInMap(map, prod);
                this.fillMessagesInItem(map[validation.resource.id], validation);
                return map;
            },
                {}
            );
    }


    protected createValidationMessage(validation:EvaluatedItem):EvalValidationMessage|null{
        if(validation.result.message==''){
            return null;
        }
        let message = validation.result.message;
        return {
            msg: message,
            styleClass: "",
            validation
        }
    }


    protected ensureProductInMap = (productsMap: { [productCode: string]: EvalValidationProduct; }, prod: EvaluatorProductI) => {
        if (productsMap[prod.code]) return;
        productsMap[prod.code] = {
            code: prod.code,
            label: prod.name,
            coverages: {},
            packages: {},
            hasInvalidCoverages: false,
            hasInvalidPackages: false,
            messages: [],
        }
    }


    /**
     *
     * @param resourceType null for root
     */
    protected invalidFilterFor = (resourceType: 'product' | 'package' | 'coverage' | null) =>
        (validation: EvaluatedItem) =>
            ((!resourceType && !validation.resource) || (validation.resource && validation.resource.type == resourceType))
            && validation.result.value === false;


    protected fillMessagesInItem(itemInMap: EvalValidationItem, validation: EvaluatedItem): EvalValidationItem  {
        if (!itemInMap) {
            itemInMap = { messages: [] };
        }

        const validationMessage = this.createValidationMessage(validation);
        if (validationMessage) {
            itemInMap.messages.push(validationMessage);
        }

        return itemInMap;
    }


    protected fillMessagesFor = (mapType: 'coverages' | 'packages', productsMap, productsFiltered: EvaluatorProductI[], validation: EvaluatedItem) => {
        productsFiltered.forEach(
            product => {
                this.ensureProductInMap(productsMap, product);
                const productMap = productsMap[product.code];
                const key = mapType == 'packages' ? validation.resource.id : validation.resource.id.split('.')[1];
                productMap[mapType][key] = this.fillMessagesInItem(productMap[mapType][key], validation);
                productMap[mapType == 'coverages' ? 'hasInvalidCoverages' : 'hasInvalidPackages'] = true;
            }
        );
    }


}

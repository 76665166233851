import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, ApiClaim, CardComponent, CardComponentService, Outcome, SessionService } from '@rgi/digital-claims-common-angular';
import { ApiAppointment } from '@rgi/digital-claims-common-angular/lib/model/api/api-appointment';
import { Invoice } from '../dto/invoice';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-assignments-list',
  templateUrl: './invoice-assignments-list.component.html',
  styleUrls: ['./invoice-assignments-list.component.scss']
})
// export class InvoiceAssignmentsListComponent extends CardComponent implements OnInit {
export class InvoiceAssignmentsListComponent implements OnInit {

  @Input() invoice: Invoice;
  @Input() claim: ApiClaim;
  errorMessage: string;
  validationMessages: string[];
  updateInvoiceForm: UntypedFormGroup;
  selectedAssignment: string;
  resCore: any;
  claimCurrent: any;
  assignments: ApiAppointment[] = [];
  submitted = false;

  constructor(
    @Inject('sessionService') private session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') private eventService: any,
    // @Inject('coreResult') coreResult,
    private invoiceService: InvoiceService,
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    public translateService: RgiRxTranslationService,
    private alertService: AlertService
    // cardComponentService: CardComponentService,
    // sessionService: SessionService
  ) {
    // super(cardComponentService, sessionService);
    // this.resCore = coreResult;
  }

  // ngAfterViewInit(): void {
  //   this.claimCurrent = this.resCore.getResult(this.card.id, 'detail');
  // }

  ngOnInit(): void {
    // this.claimCurrent = this.resCore.getResult(this.card.id, 'detail');
    this.assignmentsListSearch();
    this.initValidators();
  }

  assignmentsListSearch() {


    this.claim.claimParties.forEach(party => {
      party.damageDescriptions.forEach(caus => {

        caus.appointmentList.forEach(ass => {

          this.assignments.push(ass);

        });
      });
    });
  }

  initValidators() {
    this.updateInvoiceForm = this.formBuilder.group({
      assignment: [, Validators.required]
    }
    );
  }

  confirmInvoice() {
    if (this.updateInvoiceForm.valid) {
      this.eventService.broadcastEvent('start-loader');

      // TODO - for future proforma management (more details for itself invoice) detailsTotAmountUnit e detailsTaxableAmount manage in cicle
      this.invoice.invoiceDetailsList[0].codeAssignment = this.selectedAssignment;

      this.saveInvoice(this.invoice);
    } else {
      // this.validationMessages = [];
      // this.validationMessages.push('Fields marked in red are required or contain incorrect values');
      this.translateService.translate('_CLAIMS_._SELECT_ASSIGNMENT').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      
    }
    return null;
  }

  saveInvoice(invoice: Invoice) {
    this.invoiceService.saveInvoice(invoice, this.invoice.invoiceDetailsList[0].rejectType.codice).subscribe(
      (response: Outcome) => {
        if (response.code === Outcome.OK) {
          this.activeModal.close(response);
          this.translateService.translate('_CLAIMS_._MESSAGE_._UPDATE_SUCCESSFUL').subscribe(res=> {
            this.alertService.translatedMessage(res);
          }
          )
        } else {
          this.validationMessages = [response.descr];
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error: Error) => {
        console.error(error);
        this.translateService.translate('_CLAIMS_._MESSAGE_._SAVING_ERROR').subscribe(res=> {
          this.validationMessages.push(res);
        }
        )
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  onSelectionChange(selectedAssignment: string) {
    this.selectedAssignment = selectedAssignment;
  }

  hasResult() {
    if (this.assignments && this.assignments.length > 0) {
      return true;
    }
    return false;
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

}

import { DamageCategoryEntity } from './damage-category-entity';

export class DamageCategoriesCacheEntity {
    constructor(
        public uuid: string,
        public damagecategories: DamageCategoryEntity[],
        public regAmm: boolean,
        public regTech: boolean
    ) { }
}

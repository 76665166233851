export const path = {
    production: false,
    API_URL: '',
    REPORT_URL: '/analytics',
    REST_QLIK_TOKEN: '/qlik-token',
};

export const env = {
  host: 'http://localhost:8080/',
  apiPath: 'api/rest/v2/',
};

<div [formGroup]="formGroup">
  <div class="example-row row override-acc" *ngIf="active">
    <rgi-rx-expansion-panel>
      <div rgi-rx-expansion-panel-header>
          <span class="accordion-icon"></span>
          <span data-qa="panel-button" class="rgifont"></span>
          <div rgi-rx-expansion-panel-label>{{ definition.description }}</div>
          <span class="pull-right header-text">  </span> 
        </div>
        <lic-karma-fund-elements
          formControlName="funds"
          [definition]="definition.funds"
          [totalAmount]="TOTAL_AMOUNT"
          [sliderProperty]="sliderProperty"
          [showSliderInput]="showSliderInput"
          [selectedFundsSummary]="selectedFundsSummary">
        </lic-karma-fund-elements>
    </rgi-rx-expansion-panel>
  </div>
<!--   <div *ngIf="!active" class="profile-recap">
    <div>{{ definition.description }}:</div>
    <ul>
      <li *ngFor="let fund of selectedFunds()">
        <span for="label">{{ fund.name }} </span>
        <span for="label">{{ fund.amount | currency:'EUR':'symbol':'1.2-2':'en-US' }} </span>
        <span for="value">({{ fund.percent | percent: '1.3-3' }})</span>
      </li>
    </ul>
  </div> -->
</div>


export enum ErrorType {
    ERROR = '0',
    WARNING = '1',
    AUTH = '2',
    INFO = '3'
}

export class LifeIssueMessage {
    type: ErrorType;
    message: string;

    constructor(type: ErrorType, message: string) {
        this.type = type;
        this.message = message;
    }
}


export interface LifeEnums {
  enums: EnumModel[];
  errorMessages?: EnumErrorMessages[];
}

export interface EnumModel {
  code: string;
  values: EnumValueModel[];
}
export interface EnumValueModel {
  codice: string;
  descrizione: string;
}

export interface EnumErrorMessages {
  errorDescription: string;
  errorType: string;
}

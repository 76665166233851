import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {IndexType} from '../../../models/domain-models/parameters/index-type';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioIndexationResourceService {

  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(private http: HttpClient,
              @Inject('environment') private apiConf: any) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  putIndexType(proposalId: string, indexType: IndexType): Observable<IndexType> {
    return this.http.put<IndexType>(
      this.getIndexTypeUri(proposalId),
      indexType
    ).pipe(
      share()
    );
  }

  getIndexTypes(proposalId: string): Observable<Array<IndexType>> {
    return this.http.get<Array<IndexType>>(
      this.getIndexTypesUri(proposalId)
    ).pipe(
      share()
    );
  }

  private getIndexTypeUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/index-type';
  }

  private getIndexTypesUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/index-types';
  }
}

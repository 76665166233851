import {Component} from '@angular/core';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {GROUP_POLICY_MODE_PROPERTIES} from '../../../group-policy-constants/general';

@Component({
  selector: 'rgi-gp-group-policy-movements-detail',
  templateUrl: './group-policy-movements-detail.component.html'
})
export class GroupPolicyMovementsDetailComponent extends GpInquiryComponentDetail {

  public displayedMovColumns: string[] = ['movDescr', 'movEffectDate', 'movAccTransAmount', 'movAccTransState'];
  public latestMovId: string;

  constructor(protected stateMngr: GroupPolicyStateManagerInquiry) {
    super(stateMngr);
    if (this.inquiryMode !== GROUP_POLICY_MODE_PROPERTIES.MODES.READ_ONLY) {
      this.displayedMovColumns.push('action');
      this.latestMovId = this.contractDetails.movements[this.contractDetails.movements.length - 1].idMov;
    }
  }

  showMovementDetail(idMov: string) {
    this.stateMngr.goToInquiryAtMovement(idMov);
  }
}


<!-- al caricamento del modulo appena viene richiamato tramite routing popolo lo state (vedi service) qui nell'html 
richiamo direttamente il metodo di getState, essendo un observable mettendo | async attendo la risposta e recupero cosi sempre
l'ultimo stato salvato del servizio -->
<div *ngIf="(occurrenceService.getState$() | async) as detail">
    <div class="claims-container-occurrence">
        <div class="row mbc-2">
            <claims-dsc-general-detail [typeOpenComponent]="enumTypeOpenAccordion" [inputObject]="detail.generalData">
            </claims-dsc-general-detail>
        </div>
    
        <div class="row mbc-2">
            <claims-dsc-incident-data
            [typeOpenComponent]="enumTypeOpenAccordion"
            [incidentData]="detail.incidentData"
            [isNewClaim]="enumTypeIsNewClaim"
            [isPot]="detail.generalData.claimNumber === null ? enumIsPot : enumIsClaim"
            (outputFormIncidentData)="outputFormIncidentData($event)"></claims-dsc-incident-data>
        </div>
        <div class="row mbc-2">
            <claims-dsc-address-detail-component [typeOpenComponent]="enumTypeOpenAccordion"
                [addressData]="detail.addressEntity" (outputFormAddress)="outputFormAddress($event)">
            </claims-dsc-address-detail-component>
        </div>
        <div class="row mbc-2" *ngIf="detail.injuryCode && detail.injuryCode !== null">
            <claims-dsc-injury-code [typeOpenComponent]="enumTypeOpenAccordion"
                (outputFormInjuryCode)="outputFormInjuryCode($event)" [ministerialBranchcode]="detail.ministerialBranchcode"
                [disabledForm]="false" [injuryCode]="detail.injuryCode"></claims-dsc-injury-code>
        </div>
    
        <div class="row mbc-2" *ngIf="detail.circumstanceIncident && detail.circumstanceIncident !== null">
            <claims-dsc-circumstances-data 
            [resetFormSubject]="occurrenceService.resetFormSubject.asObservable()"
            [circumstanceIncident]="detail.circumstanceIncident" [generalData]="detail.generalData"
            [typeOpenComponent]="enumTypeOpenAccordion" (outputFormcircumstanceIncident)="outputFormCircumstanceIncident($event)"></claims-dsc-circumstances-data>
        </div>
        <div class="row mbc-2">
            <claims-dsc-additional-data [additionalData]="detail.additionalData" [generalData]="detail.generalData" [incidentData]="detail.incidentData"
                (outputFormAdditionalData)="outputFormAdditionalData($event)"></claims-dsc-additional-data>
        </div>
    </div>
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button rgi-rx-button color="primary" class="w-100" (click)="save()" [disabled]="!detail.isEnabledButtonSave"
                >{{'_CLAIMS_._BUTTONS_._SAVE' | translate}}</button>
        </div>
        <div class="btn-group">
            <button rgi-rx-button color="primary" class="w-100" (click)="alignPolicy()">{{'_CLAIMS_._ALIGN_POLICY' | translate | uppercase}}</button>
        </div>
        <div class="btn-group">
            <button rgi-rx-button color="primary" class="w-100" (click)="exit()">{{'_CLAIMS_._BUTTONS_._EXIT' | translate}}</button>
        </div>
    </div>

</div>
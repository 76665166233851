import { QuestionnaireFlatI } from '@rgi/ng-passpro';
import { DamageRisk, DamagesInstalments } from './damage.model';
import { PaymentFrequency, Payments } from './meansofpayment.model';
import { Subject } from './subject.model';
import { Roles } from '../enum/life-issue.enum';

// PROPOSAL
export class QuestionnairesManager {
  questionnaires: QuestionnaireManagerElement[];
}

export class QuestionnaireManagerElement {
  assetInstanceName: string;
  idQuest: string;
  questCode: string;
  questDescription: string;
  questScore?: number;

  constructor(assetInstanceName: string, idQuest: string, questCode: string, questDescription: string, questScore?: number) {
    this.assetInstanceName = assetInstanceName;
    this.idQuest = idQuest;
    this.questCode = questCode;
    this.questDescription = questDescription;
    if (!!questScore) {
      this.questScore = questScore;
    }
  }
}

export class Proposal {
  lifeParty?: LifeParty[];
  beneficiaryData?: BeneficiaryData;
  payments?: Payments[];
  postalAddress?: Residence;
  installment?: Installment[];
  benefit?: Benefit[];
  policyPremium?: PolicyPremium;
  riskPremium?: RiskPremium[];
  riskPremiumDisplay?: RiskPremium[];
  policyVersion?: PolicyVersion;
  idMasterPolicy?: string;
  formula?: Formula[];
  policyIssueDate?: string;
  proposalIssueDate?: string;
  extensionData?: ExtensionData;
  contractData?: ContractData[];
  questionnaires?: QuestionnaireValue[];
  questionnairesManager?: QuestionnairesManager;
  lifeRiskInsuredData?: LifeRiskInsuredData[];
  quotationMessages?: QuotationMessage[];
  damagesInstalments?: DamagesInstalments[];
  damageRisks?: DamageRisk[];
  iddAdequateWarranties?: string[];
  demandAndNeedsVersionInfo?: DemandAndNeedsVersionInfo;
  riskCommission?: RiskCommission[];
  authorizationId?: string;
  typeAdhesionCode?: string;
  demandAndNeedsData?: any;
  anonymousEstimate?: boolean;
  clauses?: ProposalClause[];

  constructor(lifeParty: LifeParty[]) {
    this.lifeParty = lifeParty;
  }
}

export class ProposalClause {
  code: string;
  text: string;
}

export class DemandAndNeedsVersionInfo {
  demandAndNeedsScore: string;
  demandAndNeedsEvaluation: string;
  ciddSurveyVersion: string;
}

export class BenefitVersion {
  borrowingDecreasingRate: string;
}

export class LifeRiskInsuredData {
  objectId: number;
  insuredRisks: InsuredRisk;
  assetInstanceName: string;
  riskCode: string;
}

export class QuotationMessage {
  messageDescription?: string;
}

export class InsuredRisk {
  dataDecorrenza: string;
  codiceRischio: string;
  riskType: RiskType;
  tipoUnit: TipoUnit;
  initialPerformanceSum: string;
  description: string;
  version: RiskVersion;
  tipoFiscalita: TipoFiscalita;
}

export class TipoFiscalita {
  codice: string;
  descrizione: string;
}

export class RiskVersion {
  dataScadenza: string;
  state: State;
  substate: SubState;
  frazionamentoDecrescenza?: FrazionamentoDecrescenza;
}

export class FrazionamentoDecrescenza {
  code: string;
  description: string;
  numRate: string;
  objectId: string;
  selected: boolean;
  typeFractionation: string;
}

export class QuestionnaireValue {
  isCompiled: boolean;
  score: string;
  assetInstanceName: string;
  questionnaire: QuestionnaireFlatI;
}

export class RiskType {
  codice: string;
  descrizione: string;
}

export class TipoUnit {
  codice: string;
  descrizione: string;
}

export class State {
  codice: string;
  descrizione: string;
}

export class SubState {
  codice: string;
  descrizione: string;
}

export class PolicyVersion {
  duration: string;
  durationDays: string;
  durationMonths: string;
  branch: Branch;
  managmentNode: ManagementNode;
  effectiveDate: string;
  expirationDate: string;
  fractionation: Fractionation;
  convention: Convention;
}

export class Branch {
  objectId: string;
}

export class Convention {
  code: string;
  description: string;
}

export class Fractionation {
  code: string;
  description: string;
  numRate: string;
  typeFractionation: Typefractionation;
}

export class Typefractionation {
  codice: string;
  descrizione: string;
}

export class ManagementNode {
  extension: string;                /*Aggiungere model corretto*/
  code: string;
  level: string;
  channel: string;
  idSp: string;
  description: string;
  parentNode: string;
}


export class ContractData {
  proposalNumber: string;
  policyNumber: string;
  contractId: string;
  objectId: string;

  constructor(propN: string, polN: string) {
    this.proposalNumber = propN;
    this.policyNumber = polN;
  }
}

export class LifeSavePolicyResponse {
  proposalNumber: string;
  lifePolicyNumber: string;
  damagePolicyNumber: string;
  proposalId: string;
  lifePolicyId: string;
  damagePolicyId: string;
  errorMessages: [
    {
      errorCode: string;
      errorDescription: string;
      errorType: number;
    }
  ];
  lifeMovementId?: string;
  damageMovementId?: string;
  productId?: string;
  policyNumber?: string;
}

export class RiskPremium {
  riskCode: string;
  /* PURO */
  purePremium: string;
  instalmentPurePremium: string;
  successiveInstalmentPurePremium: string;
  /* CARICAMENTI */
  policyLoadings: string;
  instalmentLoadings: string;
  successiveInstalmentLoadings: string;
  /* SOVRAPPREMI */
  instalmentHealthExtrapremium: string;
  instalmentOtherExtrapremium: string;
  instalmentProfessionExtrapremium: string;
  instalmentSportsExtrapremium: string;
  healthExtrapremium: string;
  otherExtrapremium: string;
  professionExtrapremium: string;
  sportsExtrapremium: string;
  successiveInstalmentHealthExtrapremium: string;
  successiveInstalmentOtherExtrapremium: string;
  successiveInstalmentProfessionExtrapremium: string;
  successiveInstalmentSportsExtrapremium: string;
  /* NETTO */
  net: string;
  instalmentNet: string;
  successiveInstalmentNet: string;
  /* INTERESSI DI FRAZIONAMENTO */
  interestsForFractioning: string;
  instalmentInterestForFractioning: string;
  successiveInstalmentInterestForFractioning: string;
  /* DIRITTI */
  fees: string;
  instalmentFees: string;
  successiveInstalmentFees: string;
  /* IMPOSTE */
  taxes: string;
  instalmentTaxes: string;
  successiveInstalmentTaxes: string;
  /* LORDO */
  gross: string;
  instalmentGross: string;
  successiveInstalmentGross: string;
}

export class PolicyPremium {
  /* PURO */
  purePremium: string;
  instalmentPurePremium: string;
  successiveInstalmentPurePremium: string;
  /* CARICAMENTI */
  policyLoadings: string;
  instalmentLoadings: string;
  successiveInstalmentLoadings: string;
  /* SOVRAPPREMI */
  instalmentHealthExtrapremium: string;
  instalmentOtherExtrapremium: string;
  instalmentProfessionExtrapremium: string;
  instalmentSportsExtrapremium: string;
  healthExtrapremium: string;
  otherExtrapremium: string;
  professionExtrapremium: string;
  sportsExtrapremium: string;
  successiveInstalmentHealthExtrapremium: string;
  successiveInstalmentOtherExtrapremium: string;
  successiveInstalmentProfessionExtrapremium: string;
  successiveInstalmentSportsExtrapremium: string;
  /* NETTO */
  net: string;
  instalmentNet: string;
  successiveInstalmentNet: string;
  /* INTERESSI DI FRAZIONAMENTO */
  interestsForFractioning: string;
  instalmentInterestForFractioning: string;
  successiveInstalmentInterestForFractioning: string;
  /* DIRITTI */
  fees: string;
  instalmentFees: string;
  successiveInstalmentFees: string;
  /* IMPOSTE */
  taxes: string;
  instalmentTaxes: string;
  successiveInstalmentTaxes: string;
  /* LORDO */
  gross: string;
  instalmentGross: string;
  successiveInstalmentGross: string;
}

export class Installment {
  collectionDate: string;
  deductedPremium: string;
  effectiveDate: string;
  expenseLoading: string;
  fixedAmount: string;
  fractioningInterestsAmount: string;
  gross: string;
  incidentalsAmount: string;
  instalmentType: string;
  interestsFractionation: string;
  isLifeInstalment: boolean;
  lateInterests: string;
  loanInterests: string;
  movementId: string;
  net: string;
  objectId: string;
  policyExtrapremiums: string;
  properties: string;
  reinstatementFees: string;
  rights: string;
  riskInstallment: RiskInstallment[];
  secondNode: boolean;
  securityStatus: string;
  taxes: string;
  totalFixedAmount: string;
  constructor() { }
}


export class Benefit {
  riskCode: string;
  initialPerformance: string;
  benefitVersion: BenefitVersion;
}

export class RiskInstallment {
  diritti: string;
  increasedPremiumPurchaseCommissionsAmount: string;
  netPremium: string;
  purchaseCommissionsAmount: string;
  purchaseLoadings: string;
  riskCode: string;
  riskDescription: string;
  totalPremium: string;
  properties?: Properties[];
}





export class SalesNode {
  displayValue: string;
  description: string;
}

export type InstalmentTypeDescription = 'lic_first_instalment' | 'lic_next_instalment_premium' | '';
export class InstalmentType {
  codice: string;
  descrizione: InstalmentTypeDescription;

  constructor(codice: string, descrizione: InstalmentTypeDescription) {
    this.codice = codice;
    this.descrizione = descrizione;
  }
  setCodice(codice: string) {
    this.codice = codice;
  }
  getCodice() {
    return this.codice;
  }
  setDescrizione(descrizione: InstalmentTypeDescription) {
    this.descrizione = descrizione;
  }
}



// PRODOTTO
export class Product {
  agreementCode?: string;
  agreements?: Agreement[];
  assetManagementMode?: string;
  objectId?: number;
  productCode?: string;
  productType?: string;
  productTypeCode?: string;
  productId?: string;
  isTransformationAllowed?: any;
  isJumpPIExpected?: any;
  productDescription?: string;
  type?: ProductType;
  code: string;
  description: string;
  assets?: Asset[];
  beneficiaries?: Beneficiary[];
  paymentFrequencyCode?: string;
  paymentMethodCode?: string;
  questionnaires?: any[];
  commonRisk?: string;
  applicationCertificateManagement?: boolean;
  factors?: Factor[];
  paymentFrequencies?: PaymentFrequency[];
  customProperties?: CustomProperties[];
  operationCode?: string;
  operations: Operations[];
  warranties?: Warranties[];
  clauses?: Clauses[];
  currencyCode?: string;
  holderType?: number;
}

export interface Clauses {
  code: string;
  visible: boolean;
  description: string;
  derogationLevel: number;
  textType: number;
  shortDescription: string | null;
  type: {
    code: string;
    description: string;
  };
  enabled: boolean;
  textFormat: number;
  customProperties: CustomProperty[];
  children: any[];
  transcoderCode1: string | null;
  manuallySet: boolean;
  transcoderCode2: string | null;
  text: string;
  helpFile: string | null;
  compulsoryText: boolean;
  selected: boolean;
  order: number;
  parents: any[];
}

export interface CustomProperty {
  code: string;
  values: PropertyValue[];
  format: any;
  description: string;
  shortDescription: string | null;
  type: number;
  value: string;
}

export interface PropertyValue {
  code: string;
  description: string;
  shortDescription: string | null;
}

export class Warranties {
  code: string;
  description: string;
  discountRepositoryCode; any;
  discountRepositoryDescription: any;
  discountedPremium: any;
  extendedDescription: any;
  id: string;
  ministerialDivisionCode: string;
  ministerialDivisionDescription: string;
  premium: any;
  selected: boolean;
  taxTypeCode: string;
}

export class Operations {
  code: string;
  customProperties: CustomProperties[];
  derogationLevel: string;
  description: string;
}

export class ProductType {
  code: string;
  description: string;
  shortDescription: string;
  category: string;
}

export class Questionnaire {
  category?: number;
  code?: string;
  codeType?: string;
  codeVersion?: string;
  compulsory?: boolean; //
  description?: string;
  descriptionType?: string;
  included?: boolean; //
  openDate?: string;
  questions?: Question[];
  score?: number;
  customProperties?: any; //
}

export class Question {
  compulsory: boolean;
  code: string;
  visible: boolean;
  answers: any[];
  description: string;
  text: string;
  conditions: any[];
  order: number;
  status: any;

  hasNotes: boolean;
  id: string;
}


export class Agreement {
  code: string;
  transcoderCode1: any;
  description: string;
  transcoderCode2: any;
  constructor(code = null, description = null) {
    this.code = code;
    this.description = description;
  }
}

// QUOTE
export class Quote {
  resourceId: string;         // ID della risorsa salvata nel gateway
  agencyID: string;
  companyCode: string;
  policyExpirationDate: string;
  effectiveDate: string;
  productID: string;
  fase: any;
  product: Product = new Product();
}

// ASSET
export class Asset {
  code: string;
  instances: Instance[];
}

// INSTANCE
export class Instance {
  extraDescription: string;
  assetCode: string;
  showClauses: any;
  secondaryActivityPacket: any;
  clauses: [];
  activityPacket: any;
  lifePremium: any;
  extensionCode: any;
  enoughDataForQuotation: any;
  packetUnitCode: any;
  instanceDescription: string;
  sections: Section[];
  packets: [];
  factors: Factor[];
  vehicle: any;
  questionnaires: Questionnaire[];
  extensions: [];
  premium: any;
  discountedPremium: any;
  name: string;
}

// SECTION
export class Section {
  code: string;
  description: string;
  units: Unit[];
  order: string;
  factors: Factor[];
}

// UNIT
export class Unit {
  description: string;
  shortDescription: string;
  warrantyCode: string;

  base: boolean;
  baseCode: string;
  accessory: boolean;
  complementary: boolean;

  enable: boolean;
  selection: boolean;
  visible: boolean;
  customProperties: CustomProperties[];
  code: string;
  inputPremiumType: string;
  discountable: boolean;
  editablePremium: boolean;
  manuallySet: boolean;
  instances: Instance[];
  riskCode: string;
  life: boolean;

  subUnits?: Unit[];

  beneficiaries: Beneficiary[];
  helpFile?: any;
  transcoderCode1?: string;
}

export interface IUnitGroupWrapper  {
  riskCode: string;
  groupingValue: string;
  description: string;
  dynamicTableUnit: any;
}

// BENEFICIARY
export class Beneficiary {
  maxSubjectsAnagraphicalNumber: string;
  code: string;
  beneficiaryType: string;
  description: string;
  extendedDescription: string;
  minSubjectsAnagraphicalNumber: string;
  defaultValue: boolean;

  constructor() {
  }
}

// POLICY
export class PolicyModel {
  codLoginNode: string;
  codManagementNode: string;
  idManagementNode: string;
  idLoginNode: string;
  userCode: string;

  quote: Quote = new Quote();
  lifeParties?: LifeParty[];
  proposal: Proposal;

  constructor() {
    this.proposal = new Proposal(this.lifeParties);
  }
}

export class ExtensionData {
  properties: Properties[];
}

export class Properties {
  chiave: string;
  valore: string;
}

export class Formatter {
  pad: string;
  multiline: boolean;
  precision: string;
  format: any; /* TODO capire il tipo */
  html: boolean;
  alignment: string;
  grouping: boolean;
  maxRowLen: string;
  maxLen: string;
}

export class Factor {
  code: string;
  visible?: boolean;
  visibleOnConsultation?: boolean;
  values?: Value[];
  description?: string;
  type?: string;
  extraValue?: string;
  formatter?: Formatter = new Formatter();
  derogationLevelValuesList?: string;
  compulsory?: boolean;
  customProperties?: CustomProperties[];
  derogationValueList?: boolean;
  children?: string;
  visibleOnQuote?: boolean;
  factorClass?: string;
  manuallySet?: boolean;
  transcoderCode1?: string;
  transcoderCode2?: string;
  helpFile?: string;
  modifiable?: boolean;
  dependent?: boolean;
  value?: string;
  order?: number;
  applicationFactor?: boolean;
}

export class Value {
  code: any;
  transcoderCode1: string;
  transcoderCode2: string;
  description: string;
  value: string;
  order: string;
}



export class CustomProperties {
  code: string;
  values?: Value[];
  format: string;
  description: string;
  type: string;
  value: string;
  shortDescription: string;
}



export class Adequacy {
  codice: string;
  descrizione: string;
}
// -----
// -----


// LIFE PARTY
export class LifeParty {
  objectId?: any;
  documentDate?: string;
  physicalLegal?: string;
  documentType?: DocumentType;
  documentNumber?: string;
  showPercentage?: any;
  minPercentage?: any;
  authorityRelease?: string;
  showDocument?: boolean;
  linkedLifeParties?: any[];
  locationRelease?: string;
  percentageParty?: any;
  maxIstances?: string;
  party: Party;
  expirationDate?: string;

  constructor(party: Party) {
    this.party = party;
  }

}

// PARTY
export class Party {
  mainParty?: any;
  ext?: any;
  assetInstanceName?: any;
  partyCode?: any;
  specializedPartyCode?: string;
  partyRole?: string;
  extraInformations?: string;
  objectID?: string;
  quoteID?: any;

  constructor() {
  }

  setExtraInformations(extraInformations: string) {
    this.extraInformations = extraInformations;
  }

  setPartyRole(partyRole: string) {
    this.partyRole = partyRole;
  }

  setObjectId(objectId: string) {
    this.objectID = objectId;
  }
}

// BENEFICIARY DATA
export class BeneficiaryData {
  choiceBeneficiaryData: ChoiceBeneficiaryData[];
  isBeneficiaryDeathIrrevocable: boolean;
  isBeneficiaryLifeIrrevocable: boolean;
}

export class ExtProperties {
  chiave: string;
  valore: any ;
}

export class ChoiceBeneficiaryData {
  choiceTypeBeneficiary: string;
  typeBeneficiary: TypeBeneficiary;
  insuredId: string;
  beneficiaryLifeParty: BeneficiaryLifeParty;
  extProperties: ExtProperties[];
}

export class TypeBeneficiary {
  codice: string;
}

export class BeneficiaryLifeParty {
  party: Party;
  percentageParty: string;
  physicalLegal: string;
  linkedLifeParties: any;
}

export class SummaryModel {
  generalData: GeneralData;
  subjects: SubjectModel[];
  insured: SubjectModel[];
  refTerzo: SubjectModel;
  effectiveHolders: SubjectModel[];
  beneficiaries: BeneficiaryModel[];
  unit: UnitModel[];
}

export class BeneficiaryModel {
  namesAndPerc: string[];
  percentage: string;
  roleDescription: string;
  choiceBeneficiary: string;
}

export class GeneralData {
  product: string;
  managementNode: string;
  branch: string;
  policyHolder: string;
  agreement: Agreement;
  proposalDate: string;
  policyDate: string;
  effectiveDate: string;
  policyDuration: string;
  policyExpirationDate: string;
  paymentFrequency: string;
  debtModePrize: string;
}

export class UnitModel {
  tipoUnit: string;
  tipoRischio: string;
  datadecorrenza: string;
  dataScadenza: string;
  stato: string;
  sottoStato: string;
  prestazioneAllaData?: number;
  controlvaloreAllaData?: number;
  frazionamentoDecrescenza?: string;
  tassoDecMutuo?: string;
}

export class SubjectModel {
  roleDescription: string;
  name: string;
  percentage?: string;
}

export class DocSubject {
  authorityRelease: string;
  documentDate: string;
  expirationDate: string;
  objectId: string;
  locationRelease: string;
  documentNumber: string;
  documentType: string;
}

export class PolicyRole {
  dateOfBirth: string;
  personType: string;
  objectId: string;
  agencyId: string;
  partyRole: Roles;
  partyRoleDescription?: string;
  maxInstances: number;
  showPercentage: boolean;
  minPercentage: number;
  percentage: number;
  docSubject: DocSubject;
  linkedSubjectRoles: PolicyRole[];
  name?: string;
  surname?: string;
  companyName?: string;


  constructor(role: Roles, subject: Subject) {
    this.partyRole = role;
    this.dateOfBirth = subject.dateOfBirth;
    this.personType = subject.personType && subject.personType.codice ? subject.personType.codice : null;
    this.objectId = subject.objectId;
    this.name = subject.name;
    this.surname = subject.surname;
    this.companyName = subject.denomination;
    this.agencyId = subject.node ? subject.node.identification : null;

    this.docSubject = new DocSubject();
    if (subject.document) {
      this.docSubject.authorityRelease = subject.document.authoritiesRelease ? subject.document.authoritiesRelease.descrizione : null;
      this.docSubject.documentDate = subject.document.releaseDate;
      this.docSubject.documentNumber = subject.document.documentNumber;
      this.docSubject.documentType = subject.document.documentType ? subject.document.documentType.codice : null;
      this.docSubject.expirationDate = subject.document.expirationDate;
      this.docSubject.locationRelease = subject.document.locationsRelease;
      this.docSubject.objectId = subject.document.objectId;
    }
  }
}

export class EnumsType {
  codice: string;
  descrizione: string;
}

// postal address

export class Residence {
  country: Country;
  city: string;
  normalized: boolean;
  latitude: string;
  placeAddress: string;
  number: string;
  toponym?: string;
  cap: string;
  province: Province;
  countryCode: string;
  adminLevel3Short?: string;
  longitude: string;
  formatAddress: string;
  adminLevel3?: string;
  adminLevel1?: string;
  subLocality?: string;
  adminLevel2?: string;
  locality?: string;
  detailCode?: string;
  adminLevel2Short?: string;
  fraction?: string;
  at?: string;
  adminLevel1Short?: string;
}

export class Country {
  codice: string;
  descrizione: string;
}

export interface Province {
  codice: string;
  descrizione: string;
}

export class Formula {
  code: string;
  visible: boolean;
  assetInstanceName: string;
  riskDescription: string;
  description: string;
  prersistent: boolean;
  riskCode: string;
  resetted: boolean;
  typeFormula: string;
  modifiable: boolean;
  value: string | number;
}

export interface DocumentType {
  codice: string;
  descrizione: string;
}

export interface RiskCommission {
  typeCommission: string;
  riskCode: string;
  commissionFormula: CommissionFormula[];
}

export interface CommissionFormula {
  code: string;
  visibility: string;
  derogation: string;
  description: string;
  value: string;
}

export interface CommonRiskResponse {
  'data': {
    'idParty': string;
    'risks': Risks[];
  };
}

export interface CommonRiskRow {
  policyNumber: string;
  proposalNumber: string;
  productDesc: string;
  riskDesc: string;
  insuredAmount: string;
}

export interface Risks {
  'typeRisk': string;
  'code': string;
  'total': string;
  'riskDescription': string;
  'typeRiskDescription': string;
  'productCode': string;
  'productDescription': string;
  'policies': Policies[];
}

export interface Policies {
  'numberPolicy': string;
  'numberProposal': string;
  'value': string;
}

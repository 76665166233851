<div class="panel-body" [formGroup]="formGroup">
  <div *ngIf="formGroup.touched && !!errors.length">
    <lpc-karma-fund-message *ngFor="let error of errors" [error]="error"></lpc-karma-fund-message>
  </div>
  <!-- <div class="input-group">
    <label>
      <span translate>Name: </span><input name="filter" formControlName="search" /></label>
  </div> -->
  <div class="row">
    <div class="col-sm-6" *ngIf="!areProfiles">
      <span>
        <ng-select class="col-sm-6 col-lg-2" id="custom" [clearable]="false" formControlName="count"
          (change)="handlePageSizeChange($event)">
          <ng-option *ngFor="let size of pageSizes" [value]="size">
            {{ size }}
          </ng-option>
        </ng-select>
      </span>
      <span style="line-height: 37px;" translate>elements</span>
    </div>
    <div class="col-lg-6" [ngStyle]="{'text-align': areProfiles ? 'left' : 'right'}">
      <label>
        <span translate>Name: </span><input style="margin-left: 5px;" name="filter" formControlName="search" /></label>
    </div>
  </div>
  <br>
  <div class="row">
    <div formGroupName="funds">
      <div class="col-lg-12 fund-line" *ngFor="let fund of funds | paginate: {
        id: 'pagination-'+funds.length,
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
      }">
        <lpc-karma-fund-button
          [formControlName]="fund.id.toString()"
          [totalAmount]="totalAmount"
          [definition]="fund"
          [sliderProperty]="sliderProperty"
          [preselect]="funds.length === 1">
        </lpc-karma-fund-button>
      </div>
    </div>
    <div class="row">
      <div class="right-align">
        <pagination-controls id="pagination-{{funds.length}}" previousLabel=""
        nextLabel="" (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>
      </div>
    </div>
  </div>
</div>

import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {LicObjectUtils} from '../../utils/lic-object-utils';

@Component({
  selector: 'lic-percent-form-group',
  templateUrl: './lic-percent-form-group.component.html',
  styleUrls: ['./lic-percent-form-group.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicPercentFormGroupComponent),
      multi: true
    }
  ]
})
export class LicPercentFormGroupComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    percent: new UntypedFormControl()
  });

  @Input() label: string;

  @Input() required = false;

  private $subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe(_ => {
        this.onChange(LicObjectUtils.roundToDecimal(this.formGroup.getRawValue().percent, 2) / 100);
        this.onTouch();
      })
    );
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }
  }

  writeValue(percent: number): void {
    const fixRoundNumber = LicObjectUtils.roundToDecimal(percent, 2);
    this.formGroup.get('percent').setValue(fixRoundNumber * 100);
  }

  onChange(obj: number) {
  }

  onTouch() {
  }

  roundValue() {
    const value: number = LicObjectUtils.roundToDecimal(this.formGroup.get('percent').value, 2);
    this.formGroup.get('percent').setValue(value);
  }
}

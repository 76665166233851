import { Component, OnInit, Output, Input, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FilterPolicy } from '../../dto/filter-policy';
import { CacheService } from '../../cache.service';
import { ExternalPortfolioService } from '../../external-portfolio.service';
import { ExternalPolicy } from '../../dto/external-policy';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../utility.service';
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { CardComponent, CardComponentService } from '@rgi/digital-claims-common-angular';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-external-portfolio-search',
  templateUrl: './external-portfolio-search.component.html',
  styleUrls: ['./external-portfolio-search.component.scss']
})

export class ExternalPortfolioSearchComponent extends CardComponent implements OnDestroy, OnInit, RoutableComponent {
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  public errorMessage: string = '';
  public errore: boolean;
  public externalErrorMessage: string = '';
  public externalError: boolean;
  public today: NgbDate;
  public filter: FilterPolicy = new FilterPolicy();
  public listPolicies: Array<ExternalPolicy> = new Array<ExternalPolicy>();
  session: any;
  card: any;
  authSrv: any;
  private subsription: Subscription;
  searchPolicyForm: UntypedFormGroup;
  public companies: any[] ;
  public typeAssets: any ;
  public abilitaNew: boolean = false;
  public disableCompany: boolean = false;

  constructor(
    @Inject('sessionService') session: any,
    cardComponentService: CardComponentService,
    @Inject('authService') authService,
    @Inject('eventService') private eventService: any,
    public translateService: RgiRxTranslationService,
    private cacheServ: CacheService,
    private policyServ: ExternalPortfolioService,
    public formBuilder: UntypedFormBuilder,
    private utilytyServ: UtilityService) {
      super(cardComponentService, session);
      const today = new Date();
      this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
      this.session = session;
      this.card = cardComponentService;
      this.authSrv = authService;

      this.companies = this.authSrv.getOperator().visibleCompanies;

      if (this.companies) {
        this.companies = this.companies.filter(this.isCompanyExt);
      }

      this.typeAssets = utilytyServ.getTypeAssets();
      const listFunz = this.authSrv.getOperator().enabledFeatures;

      if (listFunz && listFunz.indexOf('SXPEIN') >= 0) {
      this.abilitaNew = true;
      }

      if (this.companies && this.companies.length === 1) {
        this.filter.company = this.companies[0].aniaCode;
        this.disableCompany = true;
      }
  }

  isCompanyExt(element, index, array) {
    return (element.aniaCode !== '142');
  }

  ngOnInit() {
    this.searchPolicyForm = this.formBuilder.group({
      company: [],
      policyNumber: [],
      occurrenceDate: [],
      typeAsset: [],
      insuredAsset: [],
      surBusinessName: [],
      firstName: [],
      fiscalCode: []
    });
  }

  newPolicy() {
    this.cacheServ.setNewPolicy(true);
    this.session.open(this.card.getCard().name, '', '', true);
  }

  searchPolicy() {
    this.eventService.broadcastEvent('start-loader');
    this.filter.company = this.searchPolicyForm.controls.company.value;
    this.filter.policyNumber = this.searchPolicyForm.controls.policyNumber.value;
    this.filter.lossDate = this.searchPolicyForm.controls.occurrenceDate.value;
    this.filter.typeAsset = this.searchPolicyForm.controls.typeAsset.value;
    this.filter.insuredAsset = this.searchPolicyForm.controls.insuredAsset.value;
    this.filter.surBusinessName = this.searchPolicyForm.controls.surBusinessName.value;
    this.filter.firstName = this.searchPolicyForm.controls.firstName.value;
    this.filter.fiscalCodeVat = this.searchPolicyForm.controls.fiscalCode.value;

    console.log('searchPolicy');
    console.log(this.filter);

    this.errore = false;
    this.errorMessage = '';
    this.externalError = false;
    this.externalErrorMessage = '';
    this.controls();
    console.log(this.errore);
    console.log(this.errorMessage);

    if (!this.errore) {

        this.subsription = this.policyServ.getPolicies(this.filter).subscribe(
          (foundPolicy: ExternalPolicy) => {
            // console.log('CHIAMATO SERVIZIO RICERCA');
            this.listPolicies = foundPolicy.listPolicyFound;
            console.log(this.listPolicies);

            if (this.listPolicies !== null && this.listPolicies.length > 0) {
              console.log('Trovato');
              this.cacheServ.setListExternalPolicy(this.listPolicies);
              this.session.open(this.card.getCard().name, '', '', true);
            } else {
              if (foundPolicy && foundPolicy.externalPolicyResponse.code === '003') {
                this.externalError = true;
                this.externalErrorMessage = foundPolicy.externalPolicyResponse.message;
              } else {
                console.log('Non Trovato');
                this.translateService.translate('_CLAIMS_._NO_RESULT').subscribe(res=> {
                  this.errorMessage=res;
                }
                )
                
                this.errore = true;
              }
            }
            this.eventService.broadcastEvent('stop-loader');
          },
          error => {
            console.log('ERRORE');
            this.translateService.translate('_CLAIMS_._MESSAGE_._UPLOAD_ERROR').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            this.errore = true;
            this.eventService.broadcastEvent('stop-loader');
          }
        );

    } else {
      this.eventService.broadcastEvent('stop-loader');
    }
  }

  clearFilter() {
    console.log('clearFilter');
    this.filter = new FilterPolicy();
    this.searchPolicyForm = this.formBuilder.group({
      company: [],
      policyNumber: [],
      occurrenceDate: [],
      typeAsset: [],
      insuredAsset: [],
      surBusinessName: [],
      firstName: [],
      fiscalCode: []
    });
    this.errorMessage = '';
    this.errore = false;
    this.externalError = false;
    this.externalErrorMessage = '';
  }

  controls() {

    if (// (this.filter.company == null || this.filter.company === '') &&
        (this.filter.policyNumber == null || this.filter.policyNumber === '') &&
        (this.filter.insuredAsset == null  || this.filter.insuredAsset === '') &&
        (this.filter.firstName == null  || this.filter.firstName === '') &&
        (this.filter.surBusinessName == null  || this.filter.surBusinessName === '') &&
        (this.filter.fiscalCodeVat == null || this.filter.fiscalCodeVat === '')
        // (this.filter.lossDateFrom == null || this.filter.lossDateFrom === null) &&
        // (this.filter.lossDateTo == null || this.filter.lossDateTo === null)
        ) {
          this.translateService.translate('_CLAIMS_._MESSAGE_._SELECT_A_CRITERIA').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          this.errore = true;
        }

    if ((this.filter.firstName != null && this.filter.firstName !== '') &&
        (this.filter.surBusinessName == null || this.filter.surBusinessName === '') &&
        (this.filter.fiscalCodeVat == null || this.filter.fiscalCodeVat === '')) {
        this.errorMessage = 'Impute the name or the fiscal code';
        this.translateService.translate('_CLAIMS_._MESSAGE_._IMPUTE_THE_NAME_OR_THE_FISCAL_CODE').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
    }

    if ((this.filter.typeAsset == null || this.filter.typeAsset === '') &&
        ( this.filter.insuredAsset != null && this.filter.insuredAsset !== '')) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._SPECIFY_THE_TYPE_ASSET').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
    }
    if (this.filter.fiscalCodeVat != null) {
       this.verifyFiscalCode(this.filter.fiscalCodeVat);
    }

  }

  verifyFiscalCode(cfPiva) {
    if (cfPiva.length !== 0 && cfPiva.length !== 16 && cfPiva.length !== 11 &&  cfPiva.length !== 7) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE__VAT_CODE_UNCORRECT').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.errore = true;
    }

    if (cfPiva.length === 7) {
      // PIVA SANMARINO
      const n1 = parseFloat(cfPiva.substring(0, 2));
      const n2 = parseFloat(cfPiva.substring(2, cfPiva.length));

      if (!isNaN(n1.valueOf()) || isNaN(n2.valueOf())) {
        console.log('KO PIVA SM');
        this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_UNCORRECT').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
      } else { console.log('OK PIVA SM'); }

    }

    if (cfPiva.length === 11) {
      // PIVA ITALIANA
      const n = parseFloat(cfPiva);
      console.log(n);

      if (isNaN(n.valueOf())) {
        console.log('KO PIVA');
        this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_UNCORRECT').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
      } else { console.log('OK PIVA'); }

    }

    if (cfPiva.length === 16) {
      // CODICE FISCALE ITALIANO
      for (let i = 0; i < cfPiva.length; i++) {
        const aChar = cfPiva.charAt(i);
        console.log(aChar);
        const n = parseFloat(aChar);
        console.log(n);

        if (i < 6 || i === 8 || i === 11 || i === 15) {
          // il carattene non deve essere numerico
          if ( !isNaN(n.valueOf()) ) {
            console.log('KO');
            this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE_UNCORRECT').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            this.errore = true;
          } else { console.log('OK'); }
        }
        if ((i >= 6 && i < 8) || (i >= 9 && i < 11) || (i >= 12 && i < 15)) {
          // il carattene non deve essere numerico
          if (isNaN(n.valueOf())) {
            console.log('KO');
            this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_UNCORRECT').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            this.errore = true;
          } else { console.log('OK'); }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subsription) {
       this.subsription.unsubscribe();
    }
  }

}

<div>
  <div class="row" *ngIf="hide">
    <div *ngFor="let profile of profiles">
      <pnc-accordion #accordion activeIds="" [destroyOnHide]="false">
        <pnc-panel class='panel-item'>
          <ng-template let-isOpen="isOpen" class='panel-title' pncPanelTitle>
            <span class="accordion-icon" [class.rgi-plus]="!isOpen" [class.rgi-minus]="isOpen"></span>
            <span data-qa="panel-button" class="rgifont"></span>
            <span class="accordion-text" translate>{{ profile.name }}</span>
            <span class="pull-right header-text">  </span>
          </ng-template>
          <ng-template class="panel-container" pncPanelContent>
            <lic-funds-group [funds]="profile.funds" (fundChanged)="onFundChanged($event, profile)"
                             (readyChanged)="onReadyChanged($event, profile)" [amount]="profile.currency"></lic-funds-group>
          </ng-template>
        </pnc-panel>
      </pnc-accordion>
    </div>
  </div>
  <div *ngIf="!hide" class="profile-recap">
    <div *ngFor="let profileId of selectedProfilesFunds.keys() " style="margin-bottom: 10px">
      <div>{{ getProfileName(profileId) }}:</div>
      <ul>
        <li *ngFor="let fund of filterOnlyWithPercent(selectedProfilesFunds.get(profileId))">
          <span for="label">{{ fund.name }} </span>
          <span for="label">{{ formatter.setCurrencyVal(fund.currency) }} </span>
          <span for="value">({{ formatter.setDecimalVal(fund.percent,3) }}%)</span>
        </li>
      </ul>
    </div>
  </div>
</div>

import {PcVariableValue} from './pc-variable-value';

export class PcVariable {
  code: string;
  description: string;
  help?: any;
  type: string;
  format?: any;
  modifiable: boolean;
  mandatory: boolean;
  tag?: any;
  sticky?: any;
  properties: any[];
  value?: any;
  values: PcVariableValue[];
  additionalLabels: any[];

  constructor() {
  }
}

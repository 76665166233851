<ng-container *ngIf="quotationDetail">

    <div style="display: flex; flex-direction: row;">
        <div style="flex: 33%;">
            <ng-container *ngTemplateOutlet="singleEl; context: {title:'_GP_._QUOTATION_._FIRST_INSTALLMENT_', model:quotationDetail.firstInstallment, translations:translations}">
            </ng-container>
        </div>
        <div style="margin-left: 10px; flex: 33%;">
            <ng-container *ngTemplateOutlet="singleEl; context: {title:'_GP_._QUOTATION_._NEXT_INSTALLMENT_', model:quotationDetail.nextInstallment, translations:translations}">
            </ng-container>
        </div>
        <div style="margin-left: 10px; flex: 33%;">
            <ng-container *ngTemplateOutlet="singleEl; context: {title:'_GP_._QUOTATION_._ANNUAL_PREMIUM', model:quotationDetail.annualPremium, translations:translations}">
            </ng-container>
        </div>
    </div>

</ng-container>

<!-- Template -->

<ng-template #singleEl let-title="title" let-model="model" let-translations="translations">

    <div class="rgi-ui-text-4 rgi-ui-info">
        <span translate>{{title}}</span>
    </div>
    <ng-container *ngFor="let item of model | keyvalue">
        <div style="display: flex; flex-direction: row;">
            <div style="flex: 40%;">
                <span translate>{{translations[item.key]}}</span>
            </div>
            <div style="text-align: right; flex: 40%;">
                <label>{{ isNumber(item.value) ? (item.value | currency:'EUR':true) : item.value }}</label>
            </div>
        </div>
        <hr>
    </ng-container>

</ng-template>
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { Subscription } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { ClaimReportDetail } from '../../../models/claim-report.model';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { Notify, NOTIFY_ON_CLOSE_AFTER_PUBLISH } from '../../../models/notify-model';
import { LpcFactorAdapterComponent } from '../../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { ClaimReportService } from '../../../services/claim-report.service';
import { CustomModalService } from '../../../services/custom-modal.service';
import { PlcDateUtils } from '../../../utils/plc-date-utils';

export enum StatusCode {
  OPEN = '1',
  CONFIRMED = '2',
  CANCELED = '3'
}

@Component({
  selector: 'lpc-claim-detail',
  templateUrl: './claim-detail.component.html',
  styleUrls: ['./claim-detail.component.css']
})

export class ClaimDetailComponent implements OnInit, AfterViewChecked {

  @Output() eventPropagation = new EventEmitter<any>();
  @Input() data: any;
  @Input() id: any;

  @ViewChild('factorAdapter')
  factorAdapter: LpcFactorAdapterComponent;
  operator: any;
  public claimDetailVisible = false;
  public errors: string[] = [];
  private $subscription: Subscription[] = [];

  get cancel(): boolean {
    if (!!this.claimDetail && !!this.claimDetail.availableClaimOperations) {
      return this.claimDetail.availableClaimOperations.find(op => op.key === 'cancellationClaim').value;
    }
  }

  get confirm(): boolean {
    if (!!this.claimDetail && !!this.claimDetail.availableClaimOperations) {
      return this.claimDetail.availableClaimOperations.find(op => op.key === 'confirmClaim').value;
    }
  }

  get update(): boolean {
    if (!!this.claimDetail && !!this.claimDetail.availableClaimOperations) {
      return this.claimDetail.availableClaimOperations.find(op => op.key === 'modifyClaim').value;
    }
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    dates: new UntypedFormGroup({
      notificationDate: new UntypedFormControl(),
      reportDate: new UntypedFormControl(),
      claimDate: new UntypedFormControl()
    }),
    factors: new UntypedFormGroup({})
  });
  public claimDetail: ClaimReportDetail;

  constructor(protected  translateService: TranslationWrapperService,
              @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
              protected claimService: ClaimReportService,
              protected customModalService: CustomModalService,
              @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any,
              protected notifierService: NotifierService,
  ) {
  }

  ngOnInit() {
    if (!!this.data.claimId) {
      this.claimDetailVisible = true;
      this.formGroup.get('dates').disable();
      this.eventPropagation.emit({
        eventName: JS_EVENT.SET_TITLE,
        title: this.translateService.getImmediate('lpc_denuncia_sinistro')
      });
      this.getClaimDetail();
      this.$subscription.push(this.notifierService.onNotify().subscribe((notify: Notify) => {
        if (notify) {
          switch (notify.id) {
            case NOTIFY_ON_CLOSE_AFTER_PUBLISH: {
              if (!!this.data && this.data.claimId && this.data.claimId.id) {
                this.getClaimDetail();
              }
              break;
            }
            default: {
              console.log(`From notificationService: ${notify.id}`);
              break;
            }
          }
        }
      }));
    }
  }

  private getClaimDetail() {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    const request = {
      idNode: this.claimService.getNodeId(),
      userName: this.authService.getOperator().username,
      idSubject: this.claimService.getSubjectId()
    };
    this.claimService.getClaimDetail(this.data.claimId.id, request).subscribe(el => {
      this.claimDetail = el;
      this.formGroup.get('dates').get('reportDate').setValue(new Date(this.claimDetail.reportDate));
      this.formGroup.get('dates').get('notificationDate').setValue(new Date(this.claimDetail.notificationDate));
      this.formGroup.get('dates').get('claimDate').setValue(new Date(this.claimDetail.claimDate));
      this.showClaimInfo();
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    });
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_CLAIM_LIST);
  }

  public formatDate(dateValue: string): string {
    if (!!dateValue) {
      return PlcDateUtils.isoToFormattedDate(PlcDateUtils.isoDateTimeToIsoDate(dateValue));
    }
  }

  showClaimInfo() {
    if (!document.getElementById('navigation-summary')) {
      const actualElem = document.getElementById('claim-detail-page');

      const cardTitleParent = actualElem.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
        .children[0].children[0].children[0].children[0];
      const newElement = document.createElement('div');
      newElement.id = 'navigation-summary';
      newElement.style.border = '1px solid #596772';
      newElement.style.marginLeft = '25px';
      newElement.style.height = '22px';
      newElement.style.lineHeight = '0px';
      newElement.style.marginTop = '-8px';
      newElement.style.padding = '14px';

      const prodotto = document.createTextNode(this.translateService.getImmediate('lpc_Stato_denuncia') + ': ');
      const prodottoVal = document.createElement('strong');
      if (!!this.claimDetail) {
        prodottoVal.innerHTML = this.claimDetail.status.descrizione;
        if (this.claimDetail.status.codice === StatusCode.OPEN) {
          prodottoVal.style.color = 'orange';
        }
        if (this.claimDetail.status.codice === StatusCode.CONFIRMED) {
          prodottoVal.style.color = 'green';
        }
        if (this.claimDetail.status.codice === StatusCode.CANCELED) {
          prodottoVal.style.color = '#e61919';
        }
      }
      newElement.appendChild(prodotto);
      newElement.appendChild(prodottoVal);
      cardTitleParent.appendChild(newElement);

    }
  }

  changeStatus(statusCode: string) {
    const request = {
      idNode: this.claimService.getNodeId(),
      userName: this.authService.getOperator().username,
      idUser: this.authService.getOperator().objectId,
      statusCode
    };
    this.$subscription.push(
      fromPromise(
        this.customModalService.openModal(
          this.translateService.getImmediate('lpc_confirm'),
          statusCode === StatusCode.CONFIRMED ?
          this.translateService.getImmediate('lpc_Are_you_sure_you_want_to_confirm_this_claim') :
          this.translateService.getImmediate('lpc_Are_you_sure_you_want_to_cancel_this_claim'),
          true, false, true).result
      ).subscribe(result => {
        if (result) {
          this.eventPropagation.emit(JS_EVENT.LOADER.START);
          this.claimService.changeStatus(this.data.claimId.id, request).subscribe(response => {
            this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
            if (!response.errors.length) {
              this.$subscription.push(
                fromPromise(
                  this.customModalService.openModal('lpc_confirm_operation',
                  statusCode === StatusCode.CONFIRMED ? this.translateService.getImmediate('lpc_ClaimConfirmed') :
                  this.translateService.getImmediate('lpc_ClaimCanceled'), true).result
                ).subscribe(rs => {
                  if (rs) {
                    this.eventPropagation.emit(JS_EVENT.BACK_TO_CLAIM_LIST);
                  }
                })
              );
            } else {
              this.errors = this.errors.concat(response.errors.map(error => error.errorDescription));
            }
          });
        }
      })
    );

  }

  ngOnDestroy(): void {
    this.$subscription.forEach(subscription => subscription.unsubscribe());
  }

  updateClaim() {
    this.eventPropagation.emit({eventName: JS_EVENT.UPDATE_CLAIM_DETAIL, claimDetail: this.claimDetail});
  }

}

import {Country} from '../country';

export class PolicyLpsData {
  bManuallySet: boolean;
  currency: string;
  exchangeDate: string;
  exchangeRate: string;
  extraEUCountry: string;
  lpsAmount: string;
  lpsPolicyIdentification: Country;
  lpsType: string;
  lpsUse: string;
  rsAmount: string;

  constructor(bManuallySet,
              currency,
              exchangeDate,
              exchangeRate,
              extraEUCountry,
              lpsAmount,
              lpsPolicyIdentification,
              lpsType,
              lpsUse,
              rsAmount) {
    this.bManuallySet = bManuallySet;
    this.currency = currency;
    this.exchangeDate = exchangeDate;
    this.exchangeRate = exchangeRate;
    this.extraEUCountry = extraEUCountry;
    this.lpsAmount = lpsAmount;
    this.lpsPolicyIdentification = lpsPolicyIdentification;
    this.lpsType = lpsType;
    this.lpsUse = lpsUse;
    this.rsAmount = rsAmount;
  }

}

export class LpsData {
  lps: boolean;
  policyLpsData: PolicyLpsData;

  constructor(lps,
              policyLpsData) {
    this.lps = lps;
    this.policyLpsData = policyLpsData;
  }
}

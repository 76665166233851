import {PostsalesError} from '../../../models/postsales-operations-response.model';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';

@Component({
  selector: 'lpc-error-messages',
  templateUrl: './lpc-error-messages.component.html',
  styleUrls: ['./lpc-error-messages.component.css']
})
export class LpcErrorMessagesComponent implements OnChanges {

  @Input() errors: PostsalesError[] = [];
  @Input() fieldType: string;
  @Input() fieldTypes: string[] = [];

  protected $errors: PostsalesError[] = [];

  /*
    - i messaggi di errore saranno sempre visibili all'interno dello step (o con il typo flow)

    - i messaggi di auth e warning verranno mostrati sono con isFe settato a true, in questo modo
      verranno ignorati i messaggi del BE che verranno mostrati alla fine
  */

  get errorMessages(): string[] {
    return this.errors
    .filter(error => error.severity === 'error')
    .filter(err => err.context === this.fieldType || this.fieldTypes.includes(err.context) || err.context === 'flow')
    .map(error => error.errorMessage);
  }
  get infoMessages(): string[] {
    return this.errors
      .filter(error => error.severity === 'info')
      .filter(err => err.context === this.fieldType || this.fieldTypes.includes(err.context) || err.context === 'flow')
      .map(error => error.errorMessage);
  }
  get warningMessages(): string[] {
    return this.errors
    .filter(error => error.severity === 'warning' && error.isFe)
    .filter(err => err.context === this.fieldType || this.fieldTypes.includes(err.context) || err.context === 'flow')
    .map(error => error.errorMessage);
  }
  get authMessages(): string[] {
    return this.errors
    .filter(error => error.severity === 'auth' && error.isFe)
    .filter(err => err.context === this.fieldType || this.fieldTypes.includes(err.context) || err.context === 'flow')
    .map(error => error.errorMessage);
  }

  constructor(protected translate: TranslationWrapperService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errors) {
      this.$errors = (changes.errors.currentValue as PostsalesError[]).map(
        error => {
          return {
            context: error.context,
            errorId: error.errorId,
            errorMessage: this.translate.getImmediate(error.errorMessage),
            severity: error.severity,
            isFe: error.isFe,
             type: error.type
          };
        }
      );
    }
  }

}

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ApiEnumType, CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { ApiElaborationRequestFilter } from '../api/api-elaboration-request-filter';
import { ElaborationRequest } from '../dto/elaboration-request';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ElaborationRequestService } from '../elaboration-request.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { ApiActionList } from '../api/api-action-list';


@Component({
  selector: 'claims-elaboration-request-search',
  templateUrl: './elaboration-request-search.component.html',
  styleUrls: ['./elaboration-request-search.component.scss'],
})
export class ElaborationRequestSearchComponent extends CardComponent implements OnInit, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  searchElaborationRequestForm: UntypedFormGroup;
  errorMessage: string;
  emitter: any;
  requests: [];
  today: NgbDate;
  outcomeList: TypeEnum[];
  public actionsList: ApiActionList[];

  constructor(
    @Inject('sessionService') private session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') eventService: any,
    @Inject('enumService') private enumService: any,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private elaborationRequestService: ElaborationRequestService,
    private formBuilder: UntypedFormBuilder,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    super(cardComponentService, sessionService);
    this.emitter = eventService;
  }

  ngOnInit(): void {
    this.getActionsList();
    this.getOutcome();
    this.initValidators();
    const today = new Date();
    this.today = new NgbDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
  }

  initValidators() {
    this.searchElaborationRequestForm = this.formBuilder.group({
      codeChain: [],
      externalKey: [],
      codeAction: [],
      creationDateStart: [],
      creationDateEnd: [],
      outcome: [],
    });
  }

  getOutcome() {
    this.outcomeList = [];

    const enumEl: ApiEnumType[] = this.enumService.getEnumList(
      'claims.queueOutcome'
    );
    if (enumEl) {
      enumEl.forEach((respEnum: ApiEnumType) => {
        const list = {
          eTypeCode: respEnum.codice,
          eTypeDescription: respEnum.descrizione,
        };
        this.outcomeList.push(list);
      });
    }
  }

  goToSearchResults() {
    this.elaborationRequestService.setElaborationRequestDetailList(null);
    this.startLoader();
    const filter = new ApiElaborationRequestFilter();

    if (this.searchElaborationRequestForm.controls.codeChain.value) {
      filter.codeChain =
        this.searchElaborationRequestForm.controls.codeChain.value;
    }
    if (this.searchElaborationRequestForm.controls.externalKey.value) {
      filter.externalKey =
        this.searchElaborationRequestForm.controls.externalKey.value;
    }
    if (this.searchElaborationRequestForm.controls.codeAction.value) {
      filter.codeAction =
        this.searchElaborationRequestForm.controls.codeAction.value;
    }
    if (this.searchElaborationRequestForm.controls.outcome.value) {
      filter.outcome = this.outcomeList[Number(this.searchElaborationRequestForm.controls.outcome.value)];
    }
    if (this.searchElaborationRequestForm.controls.creationDateStart.value) {
      filter.creationDateStart =
        this.searchElaborationRequestForm.controls.creationDateStart.value
          .getTime()
          .toString();
    }
    if (this.searchElaborationRequestForm.controls.creationDateEnd.value) {
      filter.creationDateEnd =
        this.searchElaborationRequestForm.controls.creationDateEnd.value
          .getTime()
          .toString();
    }

    this.elaborationRequestService.getElaborationRequestsList(filter).subscribe(
      (res: any) => {

        if (res.response && res.response.length > 0) {
          const elaborationRequests = res.response.map(
            (request) => new ElaborationRequest(request)
          );
          const sessionId = this.session.open(
            'claimsElaborationrequestsinglepage',
            'home',
            '',
            true
          );
          this.elaborationRequestService.setSessionElaborationRequestFilter(
            sessionId,
            filter
          );
          this.elaborationRequestService.setSessionElaborationRequests(
            sessionId,
            elaborationRequests
          );
        } else {
          this.rxTranslationsService.translate('_CLAIM._MESSAGE_._NO_RESULTS')
          .subscribe( res => this.errorMessage = res )
        }

        this.emitter.broadcastEvent('stop-loader');
      },
      (error: Error) => {
        console.log(error);
        this.rxTranslationsService.translate('_CLAIM._SEARCH_ERROR')
        .subscribe( res => this.errorMessage = res )
        this.emitter.broadcastEvent('stop-loader');
      }
    );
  }

  emptyFields() {
    this.searchElaborationRequestForm.reset();
  }

  validateDate(controlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const min: Date = new Date(1990, 1, 1);

      if (control.value) {
        if (control.value instanceof Date) {
          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if (inputDate > currentDate) {
            control.setErrors({ futureDate: true });
          } else if (inputDate < min) {
            control.setErrors({ tooOld: true });
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({ invalidDate: true });
        }
      } else {
        const validator: any =
          control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  openNewModel() {
    this.elaborationRequestService.setElaborationRequestDetailList(null);
    this.elaborationRequestService.setSessionElaborationRequests(null, null);
    this.startLoader();
    const sessionId = this.session.open(
      'claimsElaborationrequestsinglepage',
      'home',
      '',
      true
    );
  }

  private startLoader() {
    this.errorMessage = null;
    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');
  }


  getActionsList() {
    this.elaborationRequestService.getActionsList().subscribe((res: any) => {
      this.actionsList = res.response;
    });
  }
}

interface TypeEnum {
  eTypeCode: string;
  eTypeDescription: string;
}

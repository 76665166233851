<template #modalOverlay></template>
<template #quotationComparisonModalOverlay></template>

<div *ngIf="contractAvailable">

  <div class="mb-1" *ngIf="showParties && !isAmendment">
    <div class="nmf_cont_titsez nmf_cont_titsez_color">
      <span class="header-icon rgifont rgi-riparti_coass nfm_font_size"></span>
      <h3 class="header-text" translate>Parties</h3>
    </div>
    <mic-parties
      [showQuotationModal]="false" (eventPropagation)="eventPropagation.emit($event)"
      (valuesChanged)="onPartyDataChanges()" (subscriberChanged)="onSubscriberChanged($event)"
      (validationMessagesRoles)="addPartyRolesMessaging($event)"
      [disableOffset]="'true'">
    </mic-parties>
  </div>

  <!-- MANDATORY DATA ------------------------------------------------------------------------->
  <div class="core-header-icon ">
    <span class="header-icon rgifont rgi-dettagli_tecnici nfm_font_size"></span>
    <span class="header-text" translate>Mandatory Data</span>&nbsp;
    <span class="pull-right header-text"> </span>
  </div>
  <form
    (submit)="onIssuing()"
    *ngIf="mandatoryDataForm"
    [formGroup]="mandatoryDataForm"
    role="form"
  >
    <div formGroupName="mandatoryData">
      <div *ngIf="isSubstitution" class="form-group row">
        <div *ngIf="isPolicyNumberEnabled" class="col-sm-6 col-xs-12">
          <label translate>Policy Number:</label>
          <input class="form-control mic-input" formControlName="policyNumber" type="text">
        </div>
        <div class="col-sm-6 col-xs-12">
          <label translate>Coinsurance:</label>
          <input [disabled]="true" class="form-control mic-input" formControlName="coinsurance"
                 type="text">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label for="issueDate" translate>Issue Date:</label>
          <input
            #issueDate
            class="form-control mic-input"
            data-qa="issueDate"
            formControlName="issueDate"
            name="issueDate"
            type="text"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label for="effectiveDate" translate>Effective Date:</label>
          <input
            #effectiveDate
            class="form-control mic-input"
            data-qa="effectiveDate"
            formControlName="effectiveDate"
            name="effectiveDate"
            type="text"
          />
        </div>
        <div class="col-sm-6 col-xs-12">
          <label for="policyTime" translate>Effective Time:</label>
          <input
            #effectiveDate
            class="form-control mic-input"
            data-qa="policyTime"
            formControlName="policyTime"
            name="policyTime"
            type="text"
          />
        </div>
      </div>
    </div>

    <div *ngIf="documentManagerEnabled && isSubstitution" class="nmf_cont_page">
      <div class="nmf_cont_sez">
        <rgi-documents [contractId]="contractId"
                       [uploadDownloadEnabled]="proposalNumber!==undefined"
                       (startDocument)="startDocuments($event)"
                       (eventModifiedDocument)="verifyDocuments($event)">

        </rgi-documents>
      </div>
    </div>


    <!-- VOUCHER ACCORDION ----------------------------------------------------------------------------->
    <div *ngIf="showVoucher">
      <div class="nmf_cont_moredetails" formGroupName="voucherData">
        <pnc-accordion
          #voucherAccordion
          activeIds="panel-0"
          class="nmf_cont_moredetails"
        >
          <pnc-panel class="panel-item">
            <ng-template class="panel-title" let-isOpen="isOpen" pncPanelTitle>
              <span
                [class.rgi-minus]="isOpen"
                [class.rgi-plus]="!isOpen"
                class="rgifont"
              ></span>
              <span class="rgifont" data-qa="panel-button"></span>
              <span class="header-icon rgifont rgi-folder nfm_font_size"></span>
              <span class="panel-name" translate>Voucher Data</span>
            </ng-template>

            <ng-template class="panel-container" pncPanelContent>
              <div class="title-content-card ng-scope">
                <span translate>Holder</span>
              </div>

              <div class="mb-4">
                <div class="d-flex">
                  <div class="col-sm-4 col-xs-6">
                    <span translate>Applicant:</span>
                    <b *ngIf="entitledParty"> {{ entitledParty.nominative }}</b>
                  </div>
                  <div class="col-sm-4 col-xs-6">
                    <span translate>Fiscal Code:</span>
                    <b *ngIf="entitledParty"> {{ entitledParty.fiscalCode }}</b>
                  </div>
                  <div class="col-sm-4 col-xs-6">
                    <span translate>Zip Code:</span>
                    <b *ngIf="entitledParty && entitledParty.residence">
                      {{ entitledParty.residence.cap }}</b
                    >
                  </div>
                </div>
                <hr class="my-1"/>
                <div>
                  <span translate> City: </span>
                  <b *ngIf="entitledParty && entitledParty.residence">
                    {{ entitledParty.residence.city }}</b
                  >
                </div>
              </div>

              <div formGroupName="contacts">
                <div class="title-content-card ng-scope">
                  <span translate>Contacts</span>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 col-xs-12">
                    <label for="surname" translate>Surname:</label>
                    <pnc-required required="true"></pnc-required>
                    <input
                      #surname
                      class="form-control mic-input"
                      data-qa="surname"
                      formControlName="surname"
                      name="surname"
                      type="text"
                    />
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <label for="name" translate>Name:</label>
                    <pnc-required required="true"></pnc-required>
                    <input
                      #name
                      class="form-control mic-input"
                      data-qa="name"
                      formControlName="name"
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 col-xs-12">
                    <label for="mobilePhone" translate>Phone Number:</label>
                    <pnc-required required="true"></pnc-required>
                    <input
                      #mobilePhone
                      class="form-control mic-input"
                      data-qa="mobilePhone"
                      formControlName="mobilePhone"
                      name="mobilePhone"
                      type="text"
                    />
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <label for="secondaryPhone" translate
                    >Secondary Phone Number:</label
                    >
                    <input
                      #secondaryPhone
                      class="form-control mic-input"
                      data-qa="secondaryPhone"
                      formControlName="secondaryPhone"
                      name="secondaryPhone"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 col-xs-12">
                    <label for="otherPhone" translate
                    >Other Phone Number:</label
                    >
                    <input
                      #otherPhone
                      class="form-control mic-input"
                      data-qa="otherPhone"
                      formControlName="thirdPhone"
                      name="otherPhone"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div formGroupName="vehicle">
                <div class="title-content-card ng-scope">
                  <span translate>Vehicle</span>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 col-xs-12">
                    <label for="plate" translate>Plate:</label>
                    <pnc-required required="true"></pnc-required>
                    <input
                      #plate
                      class="form-control mic-input"
                      data-qa="plate"
                      formControlName="plate"
                      name="plate"
                      type="text"
                    />
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <label for="chassis" translate>Chassis:</label>
                    <pnc-required required="true"></pnc-required>
                    <input
                      #name
                      class="form-control mic-input"
                      data-qa="chassis"
                      formControlName="chassis"
                      name="chassis"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 col-xs-12">
                    <label for="colour" translate>Colour:</label>
                    <pnc-required required="true"></pnc-required>
                    <select
                      #colour
                      class="form-control mic-select"
                      data-qa="colour"
                      formControlName="colour"
                      name="colour"
                    >
                      <option [value]="null" disabled selected></option>
                      <option
                        *ngFor="let color of vehicleColors"
                        [value]="color.codice"
                      >
                        {{ color.descrizione }}
                      </option>
                      <span
                        class="pull-right arrow-select-native rgifont rgi-chevron-down"
                      ></span>
                    </select>
                  </div>
                </div>
              </div>
              <mic-installer
                (installerChange)="installerChange($event)"
                *ngIf="mandatoryDataForm?.get('voucherData')"
                [events]="updateInstallerEvent.asObservable()"
                [parentForm]="mandatoryDataForm?.get('voucherData')"
                [voucherSubstituted]="voucherSubstituted"
              ></mic-installer>
            </ng-template>
          </pnc-panel>
        </pnc-accordion>
      </div>
    </div>

    <!-- DATI DI PAGAMENTO ----------------------------------------------------------------------------->
    <mic-rgi-payments-section *ngIf="isPaymentEnabled && areAnyConfiguredPayment()"
                              [paymentConfig]="editablePayments"
                              [selectablePaymentList]="selectablePayments"
                              (setMeanPayment)="setMeanPayment($event)"
                              (paymentErrorMessages)="updatePaymentMessages($event)">
    </mic-rgi-payments-section>

    <!-- SUMMARY ACCORDION ----------------------------------------------------------------------------->
    <div class="nmf_cont_moredetails">
      <pnc-accordion
        #summaryAccordion
        activeIds="panel-0"
        class="nmf_cont_moredetails"
      >
        <pnc-panel class="panel-item">
          <ng-template class="panel-title" let-isOpen="isOpen" pncPanelTitle>
            <span
              [class.rgi-minus]="isOpen"
              [class.rgi-plus]="!isOpen"
              class="rgifont"
            ></span>
            <span class="rgifont" data-qa="panel-button"></span>
            <span class="header-icon rgifont rgi-folder nfm_font_size"></span>
            <span class="panel-name" translate>Policy Summary</span>
          </ng-template>
          <ng-template class="panel-container" pncPanelContent>
            <!-- TITLE SECTION ----------------------------------------------------------------------------->
            <h3 class="pnc_cont_doc_titsez pnc_cont_titsez_color" translate>
              General Data
            </h3>
            <!-- GENERAL DATA ----------------------------------------------------------------------------->
            <div class="nmf_cont_dati">
              <div *ngFor="let policyGeneralDataRow of policyGeneralDataRows" class="nmf_cont_riga">
                <div *ngFor="let data of policyGeneralDataRow" class="nmf_cont_single_data nmf_cont_single_data_color">
                  <div>
                    <span>{{ data?.label }}: <b>{{ data?.value }}</b></span>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <ng-container *ngIf="!showParties">
              <!-- TITLE SECTION ----------------------------------------------------------------------------->
              <h3 class="pnc_cont_doc_titsez pnc_cont_titsez_color" translate>
                Parties
              </h3>
              <!-- PARTIES ----------------------------------------------------------------------------->
              <div class="nmf_cont_dati">
                <div *ngFor="let intestatariDataRow of intestatariDataRows" class="nmf_cont_riga">
                  <div *ngFor="let data of intestatariDataRow" class="nmf_cont_single_data nmf_cont_single_data_color">
                    <span translate>{{ data?.label }}</span>
                    <span>: <b>{{ data?.value }}</b></span>
                  </div>
                </div>
                <div *ngFor="let beneficiariDataRow of beneficiariDataRows" class="nmf_cont_riga">
                  <div *ngFor="let data of beneficiariDataRow" class="nmf_cont_single_data nmf_cont_single_data_color">
                    <span translate>{{ data?.label }}</span>
                    <span>: <b>{{ data?.value }}</b></span>
                  </div>
                </div>
                <div *ngFor="
                  let titolariEffettiviDataRow of titolariEffettiviDataRows
                " class="nmf_cont_riga">
                  <div *ngFor="let data of titolariEffettiviDataRow" class="nmf_cont_single_data nmf_cont_single_data_color">
                    <span translate>{{ data?.label }}</span>
                    <span>: <b>{{ data?.value }}</b></span>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </ng-container>
            <!-- TITLE SECTION ----------------------------------------------------------------------------->
            <h3 class="pnc_cont_doc_titsez pnc_cont_titsez_color" translate>
              Premium
            </h3>
            <div *ngIf="grossPremium" class="contributions-taxes-summary">
              <div class="summary-net d-flex pl-0">
                <span class="flex-grow-1" style="align-self: center" translate
                >Subscription Instalment</span
                >
                <div class="amount">
                  {{
                  grossPremium | currency: "EUR":"symbol":"1.2-2":"it"
                  }}&nbsp;
                </div>
              </div>
            </div>
            <!-- PREMIUM DATA ----------------------------------------------------------------------------->
            <div *ngIf="premiumDataRows" class="nmf_cont_dati">
              <div
                *ngFor="let premiumDataRow of premiumDataRows"
                class="nmf_cont_riga"
              >
                <div
                  *ngFor="let data of premiumDataRow"
                  class="nmf_cont_single_data nmf_cont_single_data_color"
                >
                  <span translate>{{ data?.label }}</span>
                  <span *ngIf="data && data.value"
                  >:
                    <b>{{
                      data?.value | currency: "EUR":"symbol":"1.2-2":"it"
                      }}</b></span
                  >
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <h3 class="pnc_cont_doc_titsez pnc_cont_titsez_color" translate>
              Purchased Covers
            </h3>
            <div class="nmf_cont_moredetails">
              <div *ngFor="let warranty of warranties" class="panel-body">
                <div class="coverage-header">
                  <b style="color: #0d497c"
                  >{{ warranty.unitTitle }} - ({{ warranty.sectionTitle }})</b
                  >
                </div>
                <div class="nmf_cont_dati">
                  <div *ngFor="let variablesRow of warranty.variablesRows">
                    <div
                      *ngFor="let variable of variablesRow"
                      class="nmf_cont_single_data nmf_cont_single_data_color"
                    >
                      <span translate>{{
                        variable.extendedDescription
                          ? variable.extendedDescription
                          : variable.description
                        }}</span>
                      <span
                      >:
                        <b>{{ variable.valueDescription }}</b></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
          </ng-template>
        </pnc-panel>
      </pnc-accordion>
      <div class="nmf_cont_dati">
        <span *ngIf="proposalStaus" translate>Proposal status: </span><span
        translate>{{this.translate.instant(proposalStaus.description)}}</span>
      </div>
      <pnc-form-message *ngIf="nonBlockingMessages?.length > 0"
                        [messages]="nonBlockingMessages"
                        data-qa="action-warning-messages"
                        detail="validationError"
                        objectType="complex" type="warning">
      </pnc-form-message>
      <pnc-form-message
        *ngIf="validationMessages?.length > 0"
        [messages]="validationMessages"
        detail="validationError"
        type="error"
      >
      </pnc-form-message>
      <pnc-form-message
        *ngIf="policyMessages?.length > 0"
        [messages]="policyMessages"
        detail="validationError"
        type="error"
      >
      </pnc-form-message>
    </div>
    <div class="btn-group btn-group-justified">
      <div *ngIf="isEditProposalVisible && !formChanged && !isQuotationRecalculated && !isSubstitution" class="btn-group">
        <button (click)="goToEditProposal()"
                class="btn btn-warning pull-right text-uppercase" data-qa="back-actions-btn"
                type="button">
          <span translate>EDIT PROPOSAL</span>
        </button>
      </div>

      <div *ngIf="isSubstitution" class="btn-group">
        <button (click)="goToEditQuotation()"
                class="btn btn-warning pull-right text-uppercase" data-qa="back-actions-btn"
                type="button">
          <span translate>EDIT QUOTATION</span>
        </button>
      </div>

      <div *ngIf="formChanged && !isAmendment" class="btn-group">
        <button (click)="recalculateQuotation()"
                [disabled]="isSubscriberMissing"
                class="btn btn-warning pull-right text-uppercase" data-qa="go-quotation-actions-btn"
                type="button">
          <span translate>RECALCULATE PREMIUM</span>
        </button>
      </div>

      <div *ngIf="!isAmendment" class="btn-group">
        <button (click)="saveProposal()"
                class="btn btn-warning pull-right text-uppercase" data-qa="save-prop-actions-btn"
                type="button">
          <span translate>SAVE PROPOSAL</span>
        </button>
      </div>

      <div *ngIf="isAmendment" class="btn-group">
        <button (click)="saveAmendment()" [disabled]="validationMessages?.length > 0"
                class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="save-amendment-actions-btn"
                type="button">
          <span translate>SAVE AMENDMENT</span>
        </button>
      </div>

      <div *ngIf="isReg51Allowed" class="btn-group">
        <button (click)="callReg51()" [disabled]="validationMessages?.length > 0 || isReg51BtnDisable"
                class="btn btn-warning pull-right text-uppercase" data-qa="ivass-quote-actions-btn"
                type="button">
          <span translate>Recovery IVASS Quote</span>
        </button>
      </div>

      <div class="btn-group" *ngIf="(proposalStatus.code === proposalStatusTypes.STATE_VALID_PROPOSAL ||
            proposalStatus.code === proposalStatusTypes.STATE_PROPOSED_TO_BE_AUTHORIZED) && !isAmendment">
        <button *ngIf="proposalStatus.code === proposalStatusTypes.STATE_VALID_PROPOSAL" [disabled]="isIssueBtnDisabled"
          class="btn btn-warning pull-right text-uppercase" data-qa="issue-actions-btn" type="submit">
          <span *ngIf="!requireDigitalSignature" translate>ISSUE</span>
          <span *ngIf="requireDigitalSignature" translate>DIGITALLY SIGN AND ISSUE</span>
        </button>
        <button
          *ngIf="(!isBindingProposal || isSubstitution) && proposalStatus.code === proposalStatusTypes.STATE_PROPOSED_TO_BE_AUTHORIZED"
          (click)="onAuthorize()" [disabled]="!enableAuthorization || checkValidationMessages() || !documentsCompleted"
          class="btn btn-warning pull-right text-uppercase" data-qa="issue-actions-btn" type="button">
          <span translate>AUTHORIZATION REQUEST</span>
        </button>
      </div>
    </div>
  </form>
</div>

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged } from 'rxjs/operators';
// { transform: 'translateZ(-100px) rotateX(  90deg)'} FLIP STYLE
@Component({
  selector: 'lpc-navigation-buttons',
  templateUrl: './lpc-navigation-buttons.component.html',
  styleUrls: ['./lpc-navigation-buttons.component.css'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0})),
      transition('void => *', [
        animate(500)
      ]),
      transition('* => void', [
        animate(500)
      ])
    ])
  ]
})
export class LpcNavigationButtonsComponent implements OnInit {

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirmAndAccept: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() qa: string;

  @Input() backLabel = 'lpc_cancel_button';
  @Input() nextLabel = 'lpc_continue_button';
  @Input() confirmAcceptLabel = 'lpc_but_confirm_accept';
  @Input() isConfirm = false;

  @Input() backVisible = true;
  @Input() nextVisible = true;
  @Input() disableNext = false;
  @Input() isConfirmAndAccept = false;

  labels: Observable<string[]>;

  public get buttonClass(): string[] {
    const array: string[] = ['btn', 'btn-warning'];
    if (!this.isConfirm) {
      array.push('continue');
    }
    return array;
  }

  constructor() {
  }

  ngOnInit() {
    this.labels = combineLatest([of(this.nextLabel), of(this.backLabel)]).pipe(
      delay(800),
      distinctUntilChanged()
    );
  }


  onBack() {
    this.back.emit(true);
  }

  onNext() {
    this.next.emit(true);
  }

  onNextConfAccept() {
    this.confirmAndAccept.emit(true);
  }

}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ComponentWithAnagModal } from '../interfaces/component-with-anag-modal';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../models/consts/lpc-consts';
import { PolicyContactOptions } from '../models/policy-contact-options';
import { Role } from '../models/postsales-operations-response.model';
import { AnagSubject, Document, PartyCheck, PartyCompleted, RootObject } from '../models/subject.model';
import { AddressUtils } from '../modules/lpc-policy-contact/utils/address-utils';
import { PlcObjectUtils } from '../utils/plc-object-utils';
import { LoaderService } from './loader.service';
import { AnagEditPartyResolver, AnagFlowData, AnagSearchOrCreateResolver } from '@rgi/anag';
import { PostsalesOperationsService } from './postsales-operations.service';
import { RoleType, Roles } from '../models/enum/lpc-subjects.enum';
import { LpcLayeredRuleService } from './lpc-layered-rule.service';
import { SubjectData } from '../models/life-detail.model';

@Injectable({
  providedIn: 'root'
})
export class AnagService {
  private readonly baseApiUrl: string;
  private openAnagTarget: ComponentWithAnagModal;

  private $modalOpen: BehaviorSubject<any> = new BehaviorSubject<any>(true);

  public subjectMap = new Map<string, AnagSubject>();
  private _nodeCode: string;
  private _parentSessionId: string;
  private _managementNode: string;

  public get modalOpening(): Observable<any> {
    return this.$modalOpen.asObservable();
  }

  public static subjectToRole(subject: AnagSubject, role: RoleType = EMPTY_STR): Role {
    return {
      id: subject.objectId,
      role,
      linkedSubjectsRoles: [],
      name: subject.nominative,
      percentage: null,
      amount: null,
      personType: subject.personType.codice,
      adult: this.calculateAge(subject.dateOfBirth) >= 18
    };
  }

  public static calculateAge(birthdate: string): number {
    if (birthdate) {
      const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
      return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    } else {
      return 0;
    }
  }

  public storeAnagSubject(subj: AnagSubject) {
    this.subjectMap.set(subj.objectId, subj);
  }

  public getAnagSubject(id: string): AnagSubject {
    return this.subjectMap.get(id);
  }

  get nodeCode(): string {
    return this._nodeCode;
  }
  set nodeCode(node) {
    this._nodeCode = node;
  }
  get parentSessionId(): string {
    return this._parentSessionId;
  }
  set parentSessionId(id) {
    this._parentSessionId = id;
  }

  set managementNode(nodeId) {
    this._managementNode = nodeId;
  }
  get managementNode(): string {
    return this._managementNode;
  }


  constructor(
    protected httpClient: HttpClient,
    @Inject(PV_TOKEN.ENV) environment: any,
    protected loaderService: LoaderService,
    protected anagSearchorCreateResolver: AnagSearchOrCreateResolver,
    protected anagEditResolver: AnagEditPartyResolver,
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    @Inject(PV_TOKEN.RGI_ANAG_PORTAL_AJS_FLOW) protected anagInJs: boolean,
    @Optional() @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any,
    protected layeredRuleService: LpcLayeredRuleService
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  getLayeredRuleService() {
    return this.layeredRuleService;
  }

  public checkRole(holder: SubjectData, currentContext?: string): Observable<{checkMsg: string }> {
    if (!this.layeredRuleService.isRequiredChekRolesOnIssuePolicy(currentContext)) {
      return of({checkMsg: ''});
    }

    const managementNodeId = this.authService.getOperator().salePoint.objectId;
    return this.getSubject(holder.id, managementNodeId).pipe(
      switchMap(res => {
        return this.checkSubjectDOC(res.subject);
      }),
      map((res: {errorMessages: {errorDescription: string}[]}) => {
        if (!!res.errorMessages && !!res.errorMessages.length) {
          return {
              checkMsg: res.errorMessages.filter(m => m.errorDescription)
                        .map(m => m.errorDescription)
                        .reduce((msg, m) => m += msg, ' ').replace(/,/g, ' ').trim()
          };
        } else {
            return {checkMsg: ''};
        }
      })
    );
  }


  getSubject(idSubj: any, idNodo: string): Observable<RootObject> {
    if (!!this.getAnagSubject(idSubj)) {
      return of({subject: this.getAnagSubject(idSubj)});
    }
    return this.loaderService.registerBeCall(
      this.httpClient.get<RootObject>(this.baseApiUrl + '/anag/subject/' + idSubj + '?idNode=' + idNodo).pipe(
        tap((subjectFromAnag) => {
          // salvo il soggetto recuperato in una mappa
          this.storeAnagSubject(subjectFromAnag.subject);
        })
      )
    );
  }

  forceReloadSubjectFromAnag(idNode: string): Observable<any> {
    const callVector = [];

    this.subjectMap.forEach((s) => {
      callVector.push(this.getSubject(s.objectId, idNode));
    });

    if (callVector.length > 0) {
      return forkJoin(callVector).pipe(tap(response => console.log(response)));
    } else {
      return of(null);
    }
  }

  getSubjectContactOptions(idSubj: any, idNodo: string): Observable<PolicyContactOptions> {
    return this.getSubject(idSubj, idNodo).pipe(
      map(rootObject => {
        const subjectContact = {
          emails: [],
          postalAddresses: [],
          phonesOrEmails: []
        };

        rootObject.subject.emails.forEach(email => {
          PlcObjectUtils.addToSet(
            subjectContact.emails,
            email
          );
        });

        rootObject.subject.otherAddress.forEach(residence => {
          PlcObjectUtils.addToSet(
            subjectContact.postalAddresses,
            AddressUtils.fromResidenceToAddress(residence.baseAddress)
          );
        });

        PlcObjectUtils.addToSet(
          subjectContact.postalAddresses,
          AddressUtils.fromResidenceToAddress(rootObject.subject.residence)
        );

        rootObject.subject.mobilePhone.forEach(phone => {
          PlcObjectUtils.addToSet(
            subjectContact.phonesOrEmails,
            phone.localPrefix + phone.number
          );
        });

        subjectContact.phonesOrEmails = subjectContact.phonesOrEmails.concat(subjectContact.emails);

        return subjectContact;
      })
    );
  }

  public resetModalOpen() {
    this.$modalOpen = new BehaviorSubject<any>(true);
  }

  getAnagFlowData(target): AnagFlowData {
    const anagFlowData = new AnagFlowData();
    anagFlowData.partyRole = target.roleCodeToAdd;
    anagFlowData.partyType = target.roleTypeToAdd;
    if (this.operations.session && this.operations.session.idParentSession) {
      anagFlowData.nodeCode = this.operations.getNodeCode();
      anagFlowData.idParentSession = this.operations.session.idParentSession;
    } else {
      anagFlowData.nodeCode = !!this.nodeCode ? this.nodeCode :  this.authService.getSalePointCode();
      anagFlowData.idParentSession = this.parentSessionId;
    }
    // anagFlowData.operation =

    return anagFlowData;
  }

  public openSubjectModal(target: ComponentWithAnagModal): void {
    this.openAnagTarget = target;
    if (this.anagInJs) {
      this.$modalOpen.next({
        roleCodeToAdd: target.roleCodeToAdd,
        event: {
          eventName: JS_EVENT.ANAG_MANAGE.SEARCH.eventName,
          sessionParent: JS_EVENT.ANAG_MANAGE.SEARCH.sessionParent,
          sessionRoute: 'home',
          sessionTitle: JS_EVENT.ANAG_MANAGE.SEARCH.sessionTitle,
          navigationDisabled: true,
          roleCode: target.roleCodeToAdd
        }
      });
    } else {
      if (!!target.roleCodeToAdd) {
        this.anagSearchorCreateResolver.searchOrCreateSubject(this.getAnagFlowData(target)).subscribe(subject => {
          if (!!subject && subject.objectId != null) {
            this.receiveSubjectFromModalX(subject);
          }
        });
      }
    }
  }

  openSubjectModifyModal(target: ComponentWithAnagModal, subject: AnagSubject, role: string) {
    this.openAnagTarget = target;
    if (this.anagInJs) {
      this.$modalOpen.next({
        event: {
          eventName: JS_EVENT.ANAG_MANAGE.OPEN_MODIFY.eventName,
          subject,
          role
        }
      });
    } else {
      return this.anagEditResolver.editParty(subject as any, this.getAnagFlowData(target)).subscribe(editedParty => {
        return this.openedAnagModifySession({ opened: true, subject: editedParty, role });
      });
    }
  }


  openSubjectDetail(subjId: string): Observable<{anagJs: boolean, subject: AnagSubject}> {
    const returnObj = {anagJs: this.anagInJs, subject: null};
    return this.getSubject(subjId, this.authService.getOperator().salePoint.objectId).pipe(
      map(respSubject => {
        if (!!respSubject && !!respSubject.subject) {
          returnObj.subject = respSubject.subject;
        }
        return returnObj;
      })
    );
  }


  // storeAnagSubject deve essere la prima istruzione da fare quando arriva un nuovo soggetto
  // quando richiamo il receiveAnagSubjectFromModal sul target infatti il soggetto deve essere già salvato nella mappa
  public receiveSubjectFromModalX(subject: AnagSubject): void {
    if (!!subject && !!this.openAnagTarget) {
      this.storeAnagSubject(subject);
      this.openAnagTarget.receiveAnagSubjectFromModal(subject);
      this.openAnagTarget = null;
    }
  }

  public openedAnagModifySession(obj: { opened: boolean, subject: AnagSubject, role}): void {
    if (!!this.openAnagTarget) {
      this.openAnagTarget.openedAnagModifySession(obj);
      this.openAnagTarget = null;
    }
  }

  public checkPartyCompleted(idParty: number, idPartyPicture: number, partyRole: RoleType, nodeId: number): Observable<PartyCompleted> {
    const request = {
      checkPartyCompletedInput: {
        idParty,
        idPartyPicture,
        partyRole,
        nodeId
      }
    };
    return this.httpClient.post<PartyCompleted>(this.baseApiUrl + '/anag/partycompleted', request);
  }

  public checkSubjectDOC(subject: AnagSubject): Observable<PartyCheck> {
    const operator = this.authService.getOperator();
    const request = {
      operationCode: '_EMPRO',
      roleToCheck: {
        partyRole: Roles.CONTRACTOR,
        objectId: subject.objectId,
        dateOfBirth: subject.dateOfBirth,
        personType: subject.personType.codice,
        name: subject.name,
        surname: subject.surname,
        companyName: subject.denomination,
        agencyId: subject.node && subject.node.identification,
        docSubject: mapDocument(subject.documents),
        linkedSubjectRoles: []
      },
      othersPolicyRoles: [],
      managementNodeId: operator && operator.salePoint && operator.salePoint.objectId

    };
    return this.httpClient.post<PartyCheck>(this.baseApiUrl + '/v2/life/roles/checks', request);

    function mapDocument(document: Document[]) {
      let doc = null;
      // taking the last document
      if(document && document.length > 0) {
        doc = document[document.length - 1];
      }
      return {
        authorityRelease: doc?.authoritiesRelease?.codice,
        documentDate: doc?.releaseDate,
        expirationDate: doc?.expirationDate,
        objectId : doc?.objectId,
        locationRelease : doc?.locationsRelease,
        documentNumber: doc?.documentNumber,
        documentType: doc?.documentType?.codice
      };
    }
  }

}

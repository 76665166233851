<rgi-rx-expansion-panel attr.data-qa="consultation-policy-details" [expanded]="true">
  <rgi-rx-expansion-panel-header class="rgi-cc-accordion-title-header">
    <span rgi-rx-expansion-panel-label translate>{{contract?.policy ? 'CONTRACT_CONSULTATION.POLICY_DETAILS' : 'CONTRACT_CONSULTATION.PROPOSAL_DETAILS'}} </span>
  </rgi-rx-expansion-panel-header>

  <lib-consultation-policy-details-admin-data [contract]="contract"></lib-consultation-policy-details-admin-data>
  <consultation-substitutions  *ngIf="contract?.substituentsAmendments.length > 0 || contract?.replacedPolicies.length > 0"
                               [substituentsAmendments]="contract?.substituentsAmendments"
                               [replacedPolicies]="contract?.replacedPolicies">
  </consultation-substitutions>
  <lib-consultation-policy-details-factors [factors]="contract.factors"></lib-consultation-policy-details-factors>
  <lib-consultation-clauses [clauses]="contract.clauses"></lib-consultation-clauses>

  <lib-consultation-delivery-address *ngIf="contract?.contactPolicy?.communicationChannel?.code !='2'" [contract]="contract"></lib-consultation-delivery-address>
  <lib-consultation-warnings *ngIf="contract?.warnings.length > 0" [warnings]="contract.warnings"></lib-consultation-warnings>
</rgi-rx-expansion-panel>


import { ActiveRoute, Routes } from "@rgi/rx/router";
import { DialogueHandlerSearchComponent } from "./dialogue-handler-search/dialogue-handler-search.component";

import { StateStoreService } from "@rgi/rx/state";
import { DialogueHandlerIntegrationService } from "./dialogue-handler-configuration/dialogue-handler-integration.service";

import { DialogueHandlerSearchService } from "./dialogue-handler-service/dialogue-handler-search.service";
import { DialogueHandlerListService } from "./dialogue-handler-service/dialogue-handler-list.service";
import { ROUTES_DIALOGUE_HANDLER_LIST, ROUTES_DIALOGUE_HANDLER_LIST_ISSUE, ROUTES_DIALOGUE_HANDLER_NEW, ROUTES_DIALOGUE_HANDLER_SEARCH, ROUTES_DIALOGUE_HANDLER_SUMMARY_ISSUE } from "./constants/routes-constants";
import { DialogueHandlerListComponent } from "./dialogue-handler-list/dialogue-handler-list.component";
import { DialogueHandlerSearchStateManager } from "./dialogue-handler-service/dialogue-handler-search.state.manager";
import { DialogueHandlerListStateManager } from "./dialogue-handler-service/dialogue-handler-list.state.manager";
import { NewDialogueComponent } from "./new-dialogue/new-dialogue.component";
import { DialogueHandlerNewDialogueStateManager } from "./dialogue-handler-service/dialogue-handler-new-dialogue.state.manager";
import { DialogueHandlerNewDialogueService } from "./dialogue-handler-service/dialogue-handler-new-dialogue.service";
import { DialogueHandlerSummaryStateManager } from "./dialogue-handler-service/dialogue-handler-summary.state.manager";
import { DialogueHandlerSummaryService } from "./dialogue-handler-service/dialogue-handler-summary.service";
import { DialogueSummaryComponent } from "./dialogue-summary/dialogue-summary.component";

export const DIALOGUE_HANDLER_ROUTES: Routes = [

    {
        route: ROUTES_DIALOGUE_HANDLER_SEARCH,
        component: DialogueHandlerSearchComponent,
        providers: [{
          provide: DialogueHandlerSearchStateManager,
          useClass: DialogueHandlerSearchStateManager,
          deps: [ActiveRoute, StateStoreService, DialogueHandlerSearchService, DialogueHandlerIntegrationService]
        }]
    },
    {
        route: ROUTES_DIALOGUE_HANDLER_LIST_ISSUE,
        component: DialogueHandlerListComponent,
        providers: [{
          provide: DialogueHandlerListStateManager, // cambiato lo state
          useClass: DialogueHandlerListStateManager,
          deps: [ActiveRoute, StateStoreService, DialogueHandlerListService, DialogueHandlerIntegrationService]
        }]
    },
    {
        route: ROUTES_DIALOGUE_HANDLER_NEW,
        component: NewDialogueComponent,
        providers: [{
          provide: DialogueHandlerNewDialogueStateManager, // cambiato lo state
          useClass: DialogueHandlerNewDialogueStateManager,
          deps: [ActiveRoute, StateStoreService, DialogueHandlerNewDialogueService, DialogueHandlerIntegrationService]
        }]
    },
    {
      route: ROUTES_DIALOGUE_HANDLER_SUMMARY_ISSUE,
      component: DialogueSummaryComponent,
      providers: [{
        provide: DialogueHandlerSummaryStateManager, // cambiato lo state
        useClass: DialogueHandlerSummaryStateManager,
        deps: [ActiveRoute, StateStoreService, DialogueHandlerSummaryService, DialogueHandlerIntegrationService]
      }]
  },
  {
    route: ROUTES_DIALOGUE_HANDLER_LIST,
    component: DialogueHandlerListComponent,
    providers: [{
      provide: DialogueHandlerListStateManager, // cambiato lo state
      useClass: DialogueHandlerListStateManager,
      deps: [ActiveRoute, StateStoreService, DialogueHandlerListService, DialogueHandlerIntegrationService]
    }]
  }
];
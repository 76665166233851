import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RgiRxSDKExpandableHost, RgiRxSDKExpandableNode} from './expand-api';



@NgModule({
  declarations: [RgiRxSDKExpandableHost, RgiRxSDKExpandableNode],
  imports: [
    CommonModule
  ],
  exports: [RgiRxSDKExpandableHost, RgiRxSDKExpandableNode]
})
export class RgiRxSdkExpandModule { }

import { ActiveRoute } from "@rgi/rx/router";
import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { ForfaitService } from "../services/forfait.service";
import { DeleteForfait } from "../models/forfait/delete-forfait.model";
import { ModifyForfait } from "../models/forfait/modify-forfait.model";
import { ForfaitState } from "../states/forfait.state";
import { AddForfait } from "../models/forfait/add-forfait.model";

@Injectable()
export class ForfaitStateManager extends AbstractStateManager<ForfaitState> {
    constructor(
        activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: ForfaitService
    ) {
        super(activeRoute, stateStoreService);

        const st = !!stateStoreService.get<ForfaitState>(this.storeKey)
            ? stateStoreService.get<any>(this.activeRoute.key)
            : new ForfaitState(this.storeKey);

        
        if(!!this.activeRoute && !!this.activeRoute.getRouteData<any>() && !!this.activeRoute.getRouteData<any>().dataFromRoute) {
            this.updateState$(this.statelesOps.initState$(of(st), this.activeRoute.getRouteData<any>().dataFromRoute))
        } else {
            this.updateState$(of(st))
        }
    }

    addForfait$(addForfait: AddForfait) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.addForfait(of(st), addForfait));
    }

    deleteForfait$(deleteForfait: DeleteForfait) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.deleteForfait(of(st), deleteForfait));
    }

    modifyForfait$(modifyForfait: ModifyForfait) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.modifyForfait(of(st), modifyForfait));
    }
}
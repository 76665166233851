<div class="col-md-12 life-detail-first-info">
    <div class="core-header-icon">
        <span class="header-icon rgifont rgi-folder"></span>
        <span class="header-text" translate>lpc_insured_policy</span>
        <span class="pull-right header-text"></span>
    </div>
</div>
<div class="row">
    <div class="tbld life-detail-margin-top">
        <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_policy_number</div>
            <div class="tbld_col tbld_col_title" translate>lpc_description</div>
            <div class="tbld_col tbld_col_title" translate>lpc_policyHolder</div>
            <div class="tbld_col tbld_col_title" translate>lpc_EffectiveDate</div>
            <div class="tbld_col tbld_col_title state-column" translate>lpc_state</div>
            <div class="tbld_col tbld_col_title" translate>lpc_ACTIONS</div>
        </div>
        <ng-container *ngFor="let policy of policies">
            <div class="tbld_row bolder">
                <div class="tbld_col tbld_col_value life-detail-table-border">{{policy.policyNumber}}
                </div>
                <div class="tbld_col tbld_col_value life-detail-table-border" [attr.data-qa]="policy.description">
                    <div>
                        {{policy.description}}
                    </div>
                </div>
                <div class="tbld_col tbld_col_value life-detail-table-border"
                    [attr.data-qa]="policy.policyHolder">{{policy.policyHolder}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">{{formatDate(policy.effectDate)}}
                </div>
                <div class="tbld_col tbld_col_value life-detail-table-border">
                    {{policy.state.descrizione}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border">
                    <button *ngIf="showPolicyDetail(policy.state.codice, policy.operationAvailable)" class="btn dt-btn" (click)="openAction(policy.policyNumber)">
                        <span class="rgifont rgi-chevron-right"></span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

import {PortalCard, PortalCards} from '@rgi/rx/portal';
import {
  ANALYTICS_ROUTE_DASHBOARD, ANALYTICS_ROUTE_EDITREPORT,
  ANALYTICS_ROUTE_HOME,
  ANALYTICS_ROUTE_NEWREPORT
} from '@rgi/analytics';

export const ANALYTICS_CARD: PortalCards = [
  {
    card: {
      name: 'newCardAnalytics',
      type: 'main',
      category: 'Analytics',
      title: 'Analytics',
      navigationInSession: true,
      subCards: [],
      functions: ['analytics.new']
    } as PortalCard,
    routes: [
      {
        type: 'home',
        route: 'home',
        destination: ANALYTICS_ROUTE_HOME
      },
      {
        route: 'dashboard',
        destination: ANALYTICS_ROUTE_HOME + '/' + ANALYTICS_ROUTE_DASHBOARD
      },
      {
        route: 'newReport',
        destination: ANALYTICS_ROUTE_HOME + '/' + ANALYTICS_ROUTE_NEWREPORT
      },
      {
        route: 'edit',
        destination: ANALYTICS_ROUTE_HOME + '/' + ANALYTICS_ROUTE_EDITREPORT
      }
    ]
  }
];

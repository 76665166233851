/**
 * date-time-input.directive
 */

import {AfterContentInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, forwardRef, Inject, InjectFlags, Injector, Input, OnDestroy, OnInit, Optional, Output, Renderer2} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormGroupDirective, NG_VALIDATORS, NgControl, NgForm, ValidationErrors, Validator, ValidatorFn, Validators} from '@angular/forms';
import {DOWN_ARROW} from '@angular/cdk/keycodes';
import {RgiRxDateTimeComponent} from './date-time/date-time-picker.component';
import {DateTimeAdapter} from './adapter/date-time-adapter.class';
import {RGI_RX_DATE_TIME_FORMATS, RgiRxDateTimeFormats} from './adapter/date-time-format.class';
import {Subscription} from 'rxjs';
import {RgiRxDateTimeSelectMode} from './date-time.class';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  RgiRxControlSkipValidationKeys,
  RgiRxErrorStateMatcher,
  RgiRxFormControl
} from '../form-elements/rgi-rx-form-elements-api';
import {RgiRxFormControlDirective} from '../form-elements/rgi-rx-form-control.directive';
import {RgiRxDateTimeTriggerDirective} from './rgi-rx-date-time-trigger.directive';
import {RgiRxDateTimeChange, RgiRxDateTimeFilterFn, RgiRxDateTimeFilterValidationError, RgiRxDateTimeInRangeValidationError, RgiRxDateTimeMaxValidationError, RgiRxDateTimeMinValidationError, RgiRxParseValidationError} from './date-picker-api';


export const RGI_RX_DATETIME_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RgiRxDateTimeInputDirective),
  multi: true
};

@Directive({
  selector: 'input[rgiRxDateTimeInput]',
  exportAs: 'rgiRxDateTimeInput',
  host: {
    class: 'rgi-ui-form-control',
    '[id]': 'id',
    '[attr.aria-haspopup]': 'true',
    '[attr.aria-owns]': 'dateTimePickerAriaOwns',
    '[attr.min]': 'minIso8601',
    '[attr.max]': 'maxIso8601',
    '[attr.required]': 'required || null',
    '[attr.disabled]': 'disabled || null',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[class.rgi-ui-error]': 'hasError()',
    '[class.rgi-ui-datepicker-trigger-disabled]': 'disabled',
    '(keydown)': 'handleKeydownOnHost($event)',
    '(blur)': 'handleBlurOnHost($event)',
    '(input)': 'handleInputOnHost($event)',
    '(change)': 'handleChangeOnHost($event)',
    '(click)': 'handleHostClick($event)',
    '(focus)': 'focused = true',
  },
  providers: [
    RGI_RX_DATETIME_VALIDATORS,
    {
      provide: RgiRxFormControl,
      useExisting: RgiRxDateTimeInputDirective
    }
  ],
})
export class RgiRxDateTimeInputDirective<T> extends RgiRxFormControlDirective<any>
  implements OnInit,
    AfterContentInit,
    OnDestroy,
    ControlValueAccessor,
    Validator {

  /** Emits when the value changes (either due to user input or programmatic change). */
  public valueChange = new EventEmitter<T[] | T | null>();
  /** The date-time component connected to the input */
  public dtPicker: RgiRxDateTimeComponent<T>;

  private _stateChangesSub = Subscription.EMPTY;
  private lastValueValid = true;
  private dtPickerSub: Subscription = Subscription.EMPTY;
  private localeSub: Subscription = Subscription.EMPTY;
  /** The triggers connected to the input if any */
  private _isTriggeredBy: RgiRxDateTimeTriggerDirective[] = [];

  /** The minimum valid date. */
  private _min: T | null;
  /** The maximum valid date. */
  private _max: T | null;
  private _values: T[] = [];
  /**
   * The picker's select mode
   */
  private _selectMode: RgiRxDateTimeSelectMode = 'single';


  private _dateTimeFilter: RgiRxDateTimeFilterFn<T>;

  /**
   * Callback to invoke when the dateTime value changes
   */
  @Output()
  dateTimeChange = new EventEmitter<RgiRxDateTimeChange<T>>();

  /**
   * Callback to invoke when an input event has been fired on the input element
   */
  @Output()
  dateTimeInput = new EventEmitter<RgiRxDateTimeChange<T>>();

  /**
   * The character to separate the 'from' and 'to' in input value
   */
  @Input()
  rangeSeparator = '~';


  private onChange = (changed) => {
  };
  private onTouched = () => {
  };
  private validatorOnChange = () => {
  };

  constructor(elmRef: ElementRef,
              private renderer: Renderer2,
              _errorStateMatcher: RgiRxErrorStateMatcher,
              private _injector: Injector,
              private changeDetector: ChangeDetectorRef,
              @Optional() _parentForm?: NgForm,
              @Optional() _parentFormGroup?: FormGroupDirective,
              @Optional() private dateTimeAdapter?: DateTimeAdapter<T>,
              @Optional() @Inject(RGI_RX_DATE_TIME_FORMATS) private dateTimeFormats?: RgiRxDateTimeFormats
  ) {


    super(elmRef, _errorStateMatcher, null, _parentForm, _parentFormGroup);
    if (!this.dateTimeAdapter) {
      throw Error(
        `RgiRxDateTime: No provider found for DateTimeAdapter. You must import one of the following ` +
        `modules at your application root: RgiRxNativeDateTimeModule, RgiRxMomentDateTimeModule, or provide a ` +
        `custom implementation.`
      );
    }

    if (!this.dateTimeFormats) {
      throw Error(
        `RgiRxDateTime: No provider found for RGI_RX_DATE_TIME_FORMATS. You must import one of the following ` +
        `modules at your application root: RgiRxNativeDateTimeModule, RgiRxMomentDateTimeModule, or provide a ` +
        `custom implementation.`
      );
    }

    this.localeSub = this.dateTimeAdapter.getLocaleChanges().subscribe(() => {
      this.value = this.value;
    });
  }

  /**
   * The date time picker that this input its associated with.
   */
  @Input()
  get rgiRxDateTimeInput(): RgiRxDateTimeComponent<T> {
    return this.dtPicker;
  }

  set rgiRxDateTimeInput(value: RgiRxDateTimeComponent<T>) {
    this.registerDateTimePicker(value);
  }

  /**
   * A function to filter date time
   */
  @Input()
  set dateFilter(filter: RgiRxDateTimeFilterFn<T>) {
    this._dateTimeFilter = filter;
    this.validatorOnChange();
  }


  get dateTimeFilter() {
    return this._dateTimeFilter;
  }


  @Input() get disabled(): boolean {
    return this._disabled;
  }

  /** Whether the date time picker's input is disabled. */

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input() get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = value;
    this.stateChanges.next();
  }

  @Input()
  get min(): T | null {
    return this._min;
  }

  set min(value: T | null) {
    this._min = this.getValidDate(this.dateTimeAdapter.deserialize(value));
    this.validatorOnChange();
  }

  @Input()
  get max(): T | null {
    return this._max;
  }

  set max(value: T | null) {
    this._max = this.getValidDate(this.dateTimeAdapter.deserialize(value));
    this.validatorOnChange();
  }


  @Input()
  get selectMode() {
    return this._selectMode;
  }

  set selectMode(mode: RgiRxDateTimeSelectMode) {
    if (
      mode !== 'single' &&
      mode !== 'range' &&
      mode !== 'rangeFrom' &&
      mode !== 'rangeTo'
    ) {
      throw Error('RgiRxlDateTime Error: invalid selectMode value!');
    }

    this._selectMode = mode;
  }


  @Input() get value() {
    return this._value;
  }

  set value(value: T | null) {
    value = this.dateTimeAdapter.deserialize(value);
    this.lastValueValid = !value || this.dateTimeAdapter.isValid(value);
    value = this.getValidDate(value);
    const oldDate = this._value;
    this._value = value;

    // set the input property 'value'
    this.formatNativeInputValue();

    // check if the input value changed
    if (!this.dateTimeAdapter.isEqual(oldDate, value)) {
      this.valueChange.emit(value);
    }
  }


  @Input()
  get values() {
    return this._values;
  }

  set values(values: T[]) {
    if (values && values.length > 0) {
      this._values = values.map(v => {
        v = this.dateTimeAdapter.deserialize(v);
        return this.getValidDate(v);
      });
      this.lastValueValid =
        (!this._values[0] ||
          this.dateTimeAdapter.isValid(this._values[0])) &&
        (!this._values[1] ||
          this.dateTimeAdapter.isValid(this._values[1]));
    } else {
      this._values = [];
      this.lastValueValid = true;
    }

    // set the input property 'value'
    this.formatNativeInputValue();

    this.valueChange.emit(this._values);
  }

  get isInSingleMode(): boolean {
    return this._selectMode === 'single';
  }

  get isInRangeMode(): boolean {
    return (
      this._selectMode === 'range' ||
      this._selectMode === 'rangeFrom' ||
      this._selectMode === 'rangeTo'
    );
  }


  get dateTimePickerAriaOwns(): string {
    return (this.dtPicker.opened && this.dtPicker.id) || null;
  }

  get minIso8601(): string {
    return this.min ? this.dateTimeAdapter.toIso8601(this.min) : null;
  }

  get maxIso8601(): string {
    return this.max ? this.dateTimeAdapter.toIso8601(this.max) : null;
  }

  get isTriggeredBy(): RgiRxDateTimeTriggerDirective[] {
    return this._isTriggeredBy;
  }

  set isTriggeredBy(value: RgiRxDateTimeTriggerDirective[]) {
    this._isTriggeredBy = value;
  }


  public ngOnInit(): void {
    /**
     * We need to resolve ngControl from the injector lazy, since there's not api to allow @Optional @Self ngControl when the control provide validators via NG_VALIDATORS
     */
    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    this.ngControl = this._injector.get(NgControl, null, InjectFlags.Self | InjectFlags.Optional);
    if (!!this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    if (!this.dtPicker) {
      throw Error(
        `RgiRxDateTimePicker: the picker input doesn't have any associated rgi-rx-date-time component`
      );
    }

    this._stateChangesSub = this.stateChanges.subscribe(next => {
      this.changeDetector.markForCheck();
    });
  }

  public ngAfterContentInit(): void {
    this.dtPickerSub = this.dtPicker.confirmSelectedChange.subscribe(
      (selecteds: T[] | T) => {
        if (Array.isArray(selecteds)) {
          this.values = selecteds;
        } else {
          this.value = selecteds;
        }

        this.onChange(selecteds);
        this.onTouched();
        this.dateTimeChange.emit({
          source: this,
          value: selecteds,
          input: this.elementRef.nativeElement
        });
        this.dateTimeInput.emit({
          source: this,
          value: selecteds,
          input: this.elementRef.nativeElement
        });
      }
    );
  }

  public ngOnDestroy(): void {
    this.dtPickerSub.unsubscribe();
    this.localeSub.unsubscribe();
    this.valueChange.complete();
    this.stateChanges.unsubscribe();
  }

  public writeValue(value: any): void {
    if (this.isInSingleMode) {
      this.value = value;
    } else {
      this.values = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return this.validator ? this.validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.validatorOnChange = fn;
  }

  /**
   * Open the picker when user hold alt + DOWN_ARROW
   */
  public handleKeydownOnHost(event: KeyboardEvent): void {
    if (event.altKey && event.keyCode === DOWN_ARROW) {
      this.dtPicker.open();
      event.preventDefault();
    }
  }

  public handleBlurOnHost(event: Event): void {
    this.onTouched();
    this.focused = false;
  }

  public handleInputOnHost(event: any): void {
    const value = event.target.value;
    this.handleChanges(value);
  }

  public handleChanges(value) {
    if (this._selectMode === 'single') {
      this.changeInputInSingleMode(value);
    } else if (this._selectMode === 'range') {
      this.changeInputInRangeMode(value);
    } else {
      this.changeInputInRangeFromToMode(value);
    }
  }

  public handleChangeOnHost(event: any): void {

    let v;
    if (this.isInSingleMode) {
      v = this.value;
    } else if (this.isInRangeMode) {
      v = this.values;
    }

    this.dateTimeChange.emit({
      source: this,
      value: v,
      input: this.elementRef.nativeElement
    });
  }

  /**
   * Set the native input property 'value'
   */
  public formatNativeInputValue(): void {
    if (this.isInSingleMode) {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        this._value
          ? this.dateTimeAdapter.format(
            this._value,
            this.dtPicker.formatString
          )
          : ''
      );
    } else if (this.isInRangeMode) {
      if (this._values && this.values.length > 0) {
        const from = this._values[0];
        const to = this._values[1];

        const fromFormatted = from
          ? this.dateTimeAdapter.format(
            from,
            this.dtPicker.formatString
          )
          : '';
        const toFormatted = to
          ? this.dateTimeAdapter.format(
            to,
            this.dtPicker.formatString
          )
          : '';

        if (!fromFormatted && !toFormatted) {
          this.renderer.setProperty(
            this.elementRef.nativeElement,
            'value',
            null
          );
        } else {
          if (this._selectMode === 'range') {
            this.renderer.setProperty(
              this.elementRef.nativeElement,
              'value',
              fromFormatted +
              ' ' +
              this.rangeSeparator +
              ' ' +
              toFormatted
            );
          } else if (this._selectMode === 'rangeFrom') {
            this.renderer.setProperty(
              this.elementRef.nativeElement,
              'value',
              fromFormatted
            );
          } else if (this._selectMode === 'rangeTo') {
            this.renderer.setProperty(
              this.elementRef.nativeElement,
              'value',
              toFormatted
            );
          }
        }
      } else {
        this.renderer.setProperty(
          this.elementRef.nativeElement,
          'value',
          ''
        );
      }
    }

    return;
  }

  public handleHostClick(event: Event): void {
    if (this.dtPicker && this.isTriggeredBy.length === 0) {
      this.open();
      event.stopPropagation();
    }
    if (!this.focused) {
      this.focus();
    }
  }

  open() {
    if (this.dtPicker) {
      this.dtPicker.open();
    }
  }

  /**
   * Check if the two value is the same
   */
  private isSameValue(first: T | null, second: T | null): boolean {
    if (first && second) {
      return this.dateTimeAdapter.compare(first, second) === 0;
    }
    return first == second;
  }

  /** The form control validator for whether the input parses. */
  private parseValidator: ValidatorFn = (): ValidationErrors | null => {
    return this.lastValueValid ? null : {rgiRxDateTimeParse: {text: this.elementRef.nativeElement.value}} as RgiRxParseValidationError;
  };

  /** The form control validator for the min date. */
  private minValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    if (this.isInSingleMode) {
      const controlValue = this.getValidDate(
        this.dateTimeAdapter.deserialize(control.value)
      );
      return !this.min ||
      !controlValue ||
      this.dateTimeAdapter.compare(this.min, controlValue) <= 0 ? null : {rgiRxDateTimeMin: {min: this.min, actual: controlValue}} as RgiRxDateTimeMinValidationError;
    } else if (this.isInRangeMode && control.value) {
      const controlValueFrom = this.getValidDate(
        this.dateTimeAdapter.deserialize(control.value[0])
      );
      const controlValueTo = this.getValidDate(
        this.dateTimeAdapter.deserialize(control.value[1])
      );
      return !this.min ||
      !controlValueFrom ||
      !controlValueTo ||
      this.dateTimeAdapter.compare(this.min, controlValueFrom) <= 0
        ? null
        : {
          rgiRxDateTimeMin: {min: this.min, actual: [controlValueFrom, controlValueTo]}
        } as RgiRxDateTimeMinValidationError;
    }
  };

  /** The form control validator for the max date. */
  private maxValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    if (this.isInSingleMode) {
      const controlValue = this.getValidDate(
        this.dateTimeAdapter.deserialize(control.value)
      );
      return !this.max ||
      !controlValue ||
      this.dateTimeAdapter.compare(this.max, controlValue) >= 0 ? null : {rgiRxDateTimeMax: {max: this.max, actual: controlValue}} as RgiRxDateTimeMaxValidationError;
    } else if (this.isInRangeMode && control.value) {
      const controlValueFrom = this.getValidDate(
        this.dateTimeAdapter.deserialize(control.value[0])
      );
      const controlValueTo = this.getValidDate(
        this.dateTimeAdapter.deserialize(control.value[1])
      );
      return !this.max ||
      !controlValueFrom ||
      !controlValueTo ||
      this.dateTimeAdapter.compare(this.max, controlValueTo) >= 0
        ? null
        : {
          rgiRxDateTimeMax: {
            max: this.max,
            actual: [controlValueFrom, controlValueTo]
          }
        } as RgiRxDateTimeMaxValidationError;
    }
  };

  /** The form control validator for the date filter. */
  private filterValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const controlValue = this.getValidDate(
      this.dateTimeAdapter.deserialize(control.value)
    );
    return !this._dateTimeFilter ||
    !controlValue ||
    this._dateTimeFilter(controlValue)
      ? null
      : {rgiRxDateTimeFilter: true} as RgiRxDateTimeFilterValidationError;
  };

  /**
   * The form control validator for the range.
   * Check whether the 'before' value is before the 'to' value
   */
  private rangeValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    if (this.isInSingleMode || !control.value) {
      return null;
    }

    const controlValueFrom = this.getValidDate(
      this.dateTimeAdapter.deserialize(control.value[0])
    );
    const controlValueTo = this.getValidDate(
      this.dateTimeAdapter.deserialize(control.value[1])
    );

    return !controlValueFrom ||
    !controlValueTo ||
    this.dateTimeAdapter.compare(controlValueFrom, controlValueTo) <= 0
      ? null
      : {rgiRxDateTimeRange: true} as RgiRxDateTimeInRangeValidationError;
  };

  /** The combined form control validator for this input. */
  private validator: ValidatorFn | null = Validators.compose([
    this.parseValidator,
    this.minValidator,
    this.maxValidator,
    this.filterValidator,
    this.rangeValidator
  ]);

  /**
   * Register the relationship between this input and its picker component
   */
  private registerDateTimePicker(picker: RgiRxDateTimeComponent<T>) {
    if (picker) {
      this.dtPicker = picker;
      this.dtPicker.registerInput(this);
    }
  }

  /**
   * Convert a given obj to a valid date object
   */
  private getValidDate(obj: any): T | null {
    return this.dateTimeAdapter.isDateInstance(obj) &&
    this.dateTimeAdapter.isValid(obj)
      ? obj
      : null;
  }

  /**
   * Convert a time string to a date-time string
   * When pickerType is 'timer', the value in the picker's input is a time string.
   * The dateTimeAdapter parse fn could not parse a time string to a Date Object.
   * Therefore we need this fn to convert a time string to a date-time string.
   */
  private convertTimeStringToDateTimeString(
    timeString: string,
    dateTime: T
  ): string | null {
    if (timeString) {
      const v = dateTime || this.dateTimeAdapter.now();
      const dateString = this.dateTimeAdapter.format(
        v,
        this.dateTimeFormats.datePickerInput,
        this.dateTimeAdapter.locale
      );
      return dateString + ' ' + timeString;
    } else {
      return null;
    }
  }

  /**
   * Handle input change in single mode
   */
  private changeInputInSingleMode(inputValue: string): void {
    let value = inputValue;
    if (this.dtPicker.pickerType === 'timer') {
      value = this.convertTimeStringToDateTimeString(value, this.value);
    }

    let result = this.dateTimeAdapter.parse(
      value,
      this.dateTimeFormats.parseInput
    );
    this.lastValueValid = !result || this.dateTimeAdapter.isValid(result);
    result = this.getValidDate(result);

    // if the newValue is the same as the oldValue, we intend to not fire the valueChange event
    // result equals to null means there is input event, but the input value is invalid
    if (!this.isSameValue(result, this._value) || result === null) {
      this._value = result;
      this.valueChange.emit(result);
      this.onChange(result);
      this.dateTimeInput.emit({
        source: this,
        value: result,
        input: this.elementRef.nativeElement
      });
    }
  }

  /**
   * Handle input change in rangeFrom or rangeTo mode
   */
  private changeInputInRangeFromToMode(inputValue: string): void {
    if (!inputValue) {
      this.emptyRangeValues();
      return;
    }
    const originalValue =
      this._selectMode === 'rangeFrom'
        ? this._values[0]
        : this._values[1];

    if (this.dtPicker.pickerType === 'timer') {
      inputValue = this.convertTimeStringToDateTimeString(
        inputValue,
        originalValue
      );
    }

    let result = this.dateTimeAdapter.parse(
      inputValue,
      this.dateTimeFormats.parseInput
    );
    this.lastValueValid = !result || this.dateTimeAdapter.isValid(result);
    result = this.getValidDate(result);

    // if the newValue is the same as the oldValue, we intend to not fire the valueChange event
    if (
      (this._selectMode === 'rangeFrom' &&
        this.isSameValue(result, this._values[0]) &&
        result) ||
      (this._selectMode === 'rangeTo' &&
        this.isSameValue(result, this._values[1]) &&
        result)
    ) {
      return;
    }

    this._values =
      this._selectMode === 'rangeFrom'
        ? [result, this._values[1]]
        : [this._values[0], result];
    this.valueChange.emit(this._values);
    this.onChange(this._values);
    this.dateTimeInput.emit({
      source: this,
      value: this._values,
      input: this.elementRef.nativeElement
    });
  }

  /**
   * Handle input change in range mode
   */
  private changeInputInRangeMode(inputValue: string): void {

    if (!inputValue) {
      this.emptyRangeValues();
      return;
    }

    const selecteds = inputValue.split(this.rangeSeparator);
    let fromString = this.trimDate(selecteds[0]);
    let toString = this.trimDate(selecteds[1]);

    if (this.dtPicker.pickerType === 'timer') {
      fromString = this.convertTimeStringToDateTimeString(
        fromString,
        this.values[0]
      );
      toString = this.convertTimeStringToDateTimeString(
        toString,
        this.values[1]
      );
    }

    let from = this.dateTimeAdapter.parse(
      fromString,
      this.dateTimeFormats.parseInput
    );
    let to = this.dateTimeAdapter.parse(
      toString,
      this.dateTimeFormats.parseInput
    );
    this.lastValueValid =
      (!from || this.dateTimeAdapter.isValid(from)) &&
      (!to || this.dateTimeAdapter.isValid(to));
    from = this.getValidDate(from);
    to = this.getValidDate(to);

    // if the newValue is the same as the oldValue, we intend to not fire the valueChange event
    if (
      !this.isSameValue(from, this._values[0]) ||
      !this.isSameValue(to, this._values[1]) ||
      (from === null && to === null)
    ) {
      this._values = [from, to];
      this.valueChange.emit(this._values);
      this.onChange(this._values);
      this.dateTimeInput.emit({
        source: this,
        value: this._values,
        input: this.elementRef.nativeElement
      });
    }
  }


  private emptyRangeValues() {
    this.values = [];
    this.onChange(this._values);
    this.dateTimeChange.emit({
      source: this,
      value: this._values,
      input: this.elementRef.nativeElement
    });
  }

  private trimDate(value: string) {
    if (!!value) {
      return value.trim();
    }
    return value;
  }
}

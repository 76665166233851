import {Directive, EventEmitter, Input, OnInit, Optional, Output, TemplateRef, ViewContainerRef} from '@angular/core';
import {delay, filter, tap} from 'rxjs/operators';
import {of} from 'rxjs';


@Directive({
  selector: '[rgiRxSdkDelayedDestroy]'
})
export class RgiRxSdkDelayedDestroyDirective implements OnInit {
  private _delay: number;

  @Input('rgiRxSdkDelayedDestroy') set delay(delay: number) {
    this._delay = delay;
  }

  @Output() onDestroy = new EventEmitter<void>();

  constructor(private vc: ViewContainerRef, @Optional() private templateRef?: TemplateRef<any>) {
  }

  ngOnInit(): void {
    if (this.vc.element.nativeElement.nodeType === 8 && this.templateRef) {
      this.vc.createEmbeddedView(this.templateRef);
    }
    of(this._delay)
      .pipe(
        filter(d => d > 0),
        delay(this._delay),
        tap(next => {
          this.vc.clear();
          this.onDestroy.emit();
        })
      )
      .subscribe();
  }
}


export class Party {

    public address: string;
    public description: string;
    public fiscalcode: string;
    public id: string;
    public mail: string;
    public name: string;
    public phoneNumber: string;
    public piva: string;
    public surname: string;
    public type: string;
    public tax: string;
    public third: boolean;
    public insured: boolean;
    public injured: boolean;
    public idAssetInsured: string;
    public policyHolder: boolean;
    public statusDescription: string;
    public statusAntiFinancialCrimes: string;
    public statusAntiFinancialCrimesDescr: string;
    constructor() { }
}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import { Guardian, HeirsListSubjLink, SubjectData, SubjectReduced } from '../dsc-shared-model/dsc-model-subject';
import { InsurancePolicy } from '../dsc-shared-model/dsc-model-policy';
import { InjuryAsset, InjuryData } from '../dsc-shared-model/dsc-model-injury';
import { CategoryDetail } from '../dsc-shared-model/dsc-model-utility';
import { AnagFlowData, AnagIntegrationService, AnagRouteData } from '@rgi/anag';



@Component({
  selector: 'claims-dsc-owner',
  templateUrl: './dsc-owner.component.html',
  styleUrls: ['./dsc-owner.component.scss']
})
export class DscOwnerComponent implements OnInit {
  constructor(
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: any,
    public anagIntegrationService: AnagIntegrationService
  ) {}
  @Input() owner: SubjectReduced;
  @Input() roles: any;
  @Input() policyNode: string;
  @Input() selectedNode: string;
  @Input() activeRouteId: string;
  @Input() listMinorGuardian: Guardian[];
  @Input() categorySelected: number;
  @Input() selectedPolicy: InsurancePolicy;
  @Input() categoryList: CategoryDetail[] = [];
  @Input() chkInjury: boolean;
  @Input() heirsList: HeirsListSubjLink;
  subjectData: SubjectData;
  subjectDataInjury: InjuryData;
  checkFatalityDate: boolean = false;
  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Output() outputDataForm = new EventEmitter<{ subject: SubjectReduced, dataForm?: InjuryAsset  }>();
  @Output() outputHeir = new EventEmitter<string>();
  passengerRoles;
  fields =
    [
      'isDriver',
      'isPassenger',
      'chkMaterial',
      'materialDescription',
      'chkInjury'];
  isAccordion: boolean = false;
  ngOnInit(): void {
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
  }

  outputFormChange(event: any, listMinorGuardian: Guardian[]) {
    const dataChange = { ...this.owner };
    this.chkInjury = event.chkInjury && (event.isDriver || event.isPassenger);
    dataChange.chkMaterial = event.chkMaterial;
    dataChange.materialDescription = event.materialDescription;
    dataChange.chkInjury = event.chkInjury;
    dataChange.isValidForm = event.isValidForm;
    dataChange.isDriver = event.isDriver;
    dataChange.isPassenger = event.isPassenger;
    dataChange.isIncapacitated = event.isIncapacitated;
    dataChange.isMinor = event.isMinor;
    dataChange.listMinorGuardianSubject = listMinorGuardian;
    this.subjectData = { subject: dataChange , roles: this.roles };
    this.outputDataForm.emit({ subject: dataChange, dataForm: this.owner.subjectDataFormInjury});
  }
  selectNewSubject(event) {
    this.owner = {...event};
    this.outputDataForm.emit({ subject: this.owner, dataForm: this.owner.subjectDataFormInjury  });
  }
  deleteSubject(event) {
    this.owner = event;
    if (this.owner.objectId === '') {
      this.chkInjury = false;
    }
    this.outputDataForm.emit({ subject: this.owner, dataForm: this.owner.subjectDataFormInjury  });
  }

  outputFormChangeInjury(event: InjuryData) {
    this.subjectDataInjury = { subject: this.owner, dataForm: event.dataForm };
    this.outputDataForm.emit(this.subjectDataInjury);
  }

  calculateAge(birthday) {
    const dateOfBirth = new Date(birthday);
    const ageDifMs = Date.now() - dateOfBirth.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
}
openDetail() {
  const flowData: AnagFlowData = new AnagFlowData();
  flowData.idParentSession = this.activeRouteId;
  flowData.nodeCode = this.selectedNode;
  flowData.partyRole='888';
  const anagRouteData: AnagRouteData = new AnagRouteData();
  const subject: any = this.owner.subject.anagSubject;

  if (subject && !subject.node && this.policyNode) {
    subject.node = this.policyNode;
  }

  anagRouteData.party = subject;
  anagRouteData.redirectToMainFlow = true;
  anagRouteData.flowData = flowData;
  (this.anagIntegrationService as any).openDetailSubject(anagRouteData, this.activeRouteId);
  
  /* const idNavigation = this.sessionService.open('anagFinder', 'detail', '', true, null, null, this.activeRouteId);
  this.coreResult.setResult(idNavigation, 'detail', {
    subject: this.owner.subject.anagSubject
  }); */
}
listMinorGuardianChange(event) {
  this.listMinorGuardian = event;
  this.outputFormChange(null, this.listMinorGuardian)
}
outputHeirData(event) {
  this.outputHeir.emit(event);
}
}

<label *ngIf="label">{{label | translate}}</label>
<!--<pnc-required [required]="required"></pnc-required>-->
<input type="hidden" #input [required]="required">
<div class="form-control" style="height: auto;" [ngClass]="{'is-disabled': disabled }">
    <div [ngClass]="{'drop-item': currentNode?.description || currentNode?.value}">
        <div class="drop-item-content">
            <div (click)=onClick() class="drop-item-title node-title">
                <span>{{ currentNode?.description || currentNode?.value || placeHolder | translate}}</span>
            </div>
            <div [hidden]="!(currentNode?.description || currentNode?.value)" class="drop-item-delete">
                <button *ngIf="showResetButton" [hidden]="!(currentNode?.description || currentNode?.value)"
                    type="button" class="drag-del-item form-control-button"
                    (click)="resetItem(); $event.preventDefault()">
                    <span class="glyphicon glyphicon-refresh"></span>
                </button>
                <button *ngIf="showEditButton" [hidden]="!(currentNode?.description || currentNode?.value)"
                    type="button" class="drag-del-item form-control-button"
                    (click)="editItem(); $event.preventDefault()">
                    <span class="glyphicon glyphicon-transfer"></span>
                </button>
                <button *ngIf="showDeleteButton" [hidden]="!(currentNode?.description || currentNode?.value)"
                    type="button" class="drag-del-item form-control-button"
                    (click)="deleteItem(); $event.preventDefault()">
                    <span class="glyphicon glyphicon-trash"></span>
                </button>
            </div>
        </div>
    </div>
</div>

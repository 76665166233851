import { Component, Inject, Input, OnInit, Optional, Output, EventEmitter  } from '@angular/core';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';

@Component({
    selector: 'claims-global-modal-confirm',
    templateUrl: './global-modal-confirm.component.html',
    styleUrls: ['./global-modal-confirm.component.scss']
  })

  export class GlobalModalConfirmComponent implements OnInit, OnModalClose {

    display: boolean;
    modalTextContent: string = '';
    data: any;
    @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        @Optional() @Inject(DIALOG_DATA) data: any
    ) {
        this.modalTextContent = data.contentMessage;
    }

    ngOnInit(): void {
        this.display = true;
    }

    closeModal(closeType: string) {
        this.modalClose.emit(closeType);
    }
}

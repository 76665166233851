import { LicTranslationsKeys } from './lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_CA_ES: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: 'Pòlissa número {{num}} emesa correctament',
  lic_ProposalCorrectlyIssued: 'Proposta número {{num}} emesa correctament',
  lic_LifeProposalCorrectlyIssued: 'Proposta de vida número {{num}} emesa correctament',
  lic_LifePolicyCorrectlyIssued: 'Pòlissa de vida número {{num}} emesa correctament',
  lic_DamagesPolicyCorrectlyIssued: 'Pòlissa de danys número {{num}} emesa correctament',
  lic_AuthorizatioProposalForwarded: 'La sol·licitud d\'autorització per a la proposta {{num}} s\'ha enviat a l\'usuari adient',
  lic_ProposalCorrectlySaved: 'Proposta número {{num}} desada correctament',
  lic_PreventiveCorrectlySaved: 'Cotització número {{num}} desada correctament',
  lic_benMorteNoAssic: 'El subjecte seleccionat com a beneficiari de la mort ja està seleccionat com a assegurat, seleccioneu un altre subjecte',
  lic_benMorteNoRef: 'El subjecte seleccionat com a beneficiari de la mort ja està seleccionat com a tercera persona de contacte',
  lic_benefPresente: 'El subjecte seleccionat com a beneficiari de la mort ja està seleccionat, seleccioneu un altre subjecte',
  lic_refTerzoNoAssic: 'El subjecte seleccionat com a tercera persona de contacte ja està seleccionat com a assegurat, seleccioneu un altre subjecte',
  lic_refTerzoNoContr: 'El subjecte seleccionat com a tercera persona de contacte ja està seleccionat com a titular de la pòlissa, seleccioneu un altre subjecte',
  lic_refTerzoPresente: 'El subjecte seleccionat com a tercera persona de contacte ja està seleccionat com a beneficiari en cas de mort',
  lic_DelegateEqualToPolicyHolder: 'Delegat igual a prenedor?',
  lic_LegalGuardianEqualToPolicyHolder: 'Tutor legal igual a prenedor?',
  lic_ThirdPayerEqualToPolicyHolder: 'Tercer pagador igual a prenedor?',
  lic_DelegateEqualToPolicyHolderWarning: 'Subjecte per al rol de delegat igual a prenedor',
  lic_LegalGuardianEqualToPolicyHolderWarning: 'Subjecte per al rol de tutor legal igual a prenedor',
  lic_ThirdPayerEqualToPolicyHolderWarning: 'Subjecte per al rol de tercer pagador igual a prenedor',
  lic_MasterPolicyHolder: 'Titular principal de la pòlissa',
  lic_SubjectXalreadyselectedfortherole: 'El subjecte {{subject}} ja està seleccionat per al rol',
  lic_InsuredAmount: 'Import assegurat',
  lic_InvestedPremium: 'Prima d\'inversió',
  lic_FirstInstalment: 'Primer termini',
  lic_InsuredNotEqualHolder: 'L\'assegurat i el titular han de ser diferents',
  lic_SaveEstimate: 'Deseu cotització',
  lic_LifeQuote: 'Cotització de vida',
  lic_PreventiveIssue: 'Emissió de la Cotització',
  lic_ProposalIssue: 'Proposta d\'emissió',
  lic_PolicyIssue: 'Emeteu la pòlissa',
  lic_appendixLabel: 'Annex de beneficiaris',
  lic_summary_save_and_accept: 'deseu i accepteu',
  lic_summary_save_accept_pol_issue: 'deseu, accepteu i emeteu la pòlissa',
  lic_summary_save_accept_prop_issue: 'deseu, accepteu i emeteu la proposta',
  lic_effectivebeneficiaryholder: 'titular beneficiari efectiu',
  lic_LifePolicy: 'Pòlissa de vida',
  lic_DamagesPolicy: 'Pòlissa de danys',
  lic_view: 'Veieu',
  lic_premium_detail: 'Detall de la prima',
  lic_guarantee_summary: 'Resum de garanties',
  lic_commissions: 'Comissions',
  lic_Parties: 'Parts',
  lic_Party: 'Part',
  lic_Employer: 'Ocupador',
  lic_SpecificPIPdata: 'Dades PIP específiques',
  lic_prev_continue: 'Pròxim',
  lic_Transferdata: 'Transferiu dades',
  lic_Selectatleastonecontribution: 'Seleccioneu almenys una aportació',
  lic_Globalpercentagemustbe100: 'El percentatge global ha de ser 100 %',
  lic_Selectcontributionsmandatoryfields: 'Seleccioneu els camps obligatoris de les aportacions',
  lic_Contributiondata: 'Dades de l\'aportació',
  lic_Globalamountmustbemajorthan0: 'L\'import global ha de ser més gran que 0',
  lic_Everycontributionselectedmustbemajorthan0: 'Totes les contribucions seleccionades han de ser més grans que 0',
  lic_error_mandatory_fields: 'Els camps marcats en vermell són obligatoris o contenen valors incorrectes',
  lic_error_iban: 'L\'IBAN no és correcte',
  lic_authorization_request: 'PETICIÓ d\'AUTORITZACIÓ',
  lic_selected_profiles_: 'Perfils seleccionats',
  lic_Disinvestmentsfunds: 'Fons de desinversió',
  lic_reg41_comunication: 'Nom a qui enviar comunicacions',
  lic_disinvestment_amount: 'Import de desinversió',
  lic_occurrenceNumber: 'Incidència',
  lic_TheoccurenceNumbermustbeavalue: 'El nombre d\'incidents ha de ser més gran que {{min}}',
  lic_Disinvestmentrange: 'Introduïu un import per desinvertir superior a {{max}} i inferior o igual que {{min}}',
  lic_Incompleteinvestmentforthefunds: 'Inversió incompleta completeu la inversió per als fons',
  lic_Incompleteinvestmentfortheprofiles: 'Inversió incompleta completeu la inversió per als perfils',
  lic_Settlementstoreinvest: 'Liquidació per reinvertir',
  lic_Policynumber: 'Número de pòlissa',
  lic_Type: 'Tipus',
  lic_Beneficiary: 'Beneficiari',
  lic_State: 'Estat',
  lic_Net: 'Net',
  lic_Nettoreinvest: 'Net per reinvertir',
  lic_Reinvestmentmustbelargerthanquotation: 'La reinversió ha de ser més gran que la cotització.',
  lic_Mustselectatleastonesettlementtoreinvest: 'Heu de seleccionar almenys una liquidació per reinvertir.',
  lic_Nettoreinvestmustbeequalorlessthannet: 'El net a reinvertir ha de ser igual o més petit que el net.',
  lic_Nosettlementstoreinvestexist: 'No hi ha assentaments per reinvertir.',
  lic_Missingvalueonnettoreinvest: 'Falta el valor en el net per reinvertir.',
  lic_information: 'Informació:',
  lic_Percentage: 'Percentatge',
  lic_Durationin: 'Durada en',
  lic_years: 'anys',
  lic_months: 'mesos',
  lic_days: 'dies',
  lic_Complementary: '(Complementari)',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: 'Faciliteu un mètode de pagament per al beneficiari del cupó',
  lic_Netavailable: 'Net disponible',
  lic_Proposal_Saved: 'La proposta s\'ha desat amb el número',
  lic_add_employer: 'Afegiu un empleat',
  lic_AvailableGuarantees: 'Garanties disponibles',
  lic_error: 'Error',
  lic_error_msg: 'Error en emetre la pòlissa',
  lic_auth_error_msg: 'Error en processar la sol·licitud',
  lic_common_risk: 'Risc comú',
  lic_no_common_risk: 'No hi ha propostes o pòlisses que entrin en el càlcul del capital en risc per a l\'assegurat',
  lic_assignment_holder: 'Titular de l\'assignació',
  lic_Sustainability: 'Sostenibilitat',
  lic_ecoSustainabilityAmount: 'Import d\'ecosostenibilitat',
  lic_ecoSustainabilityPerc: 'Ecosostenibilitat %',
  lic_envSustainabilityAmount: 'Import de sostenibilitat ambiental',
  lic_envSustainabilityPerc: 'Sostenibilitat ambiental %',
  lic_paiAmount: 'Import PAI',
  lic_paiPerc: 'PAI %',
  lic_socialSustainabilityAmount: 'Import de sostenibilitat social',
  lic_socialSustainabilityPerc: 'Sostenibilitat social %',
  lic_benefSevereDisability: 'Beneficiari amb discapacitat greu',
  lic_SecondInsured: 'Segon assegurat',
  lic_Secondinsuredmustbeaphysicalperson: 'El segon assegurat ha de ser una persona física',
  lic_Selectasecondinsured: 'Seleccioneu un segon assegurat',
  lic_Aderente: 'Adherent',
  lic_NumeroProposta: 'Número de proposta',
  lic_DescrizioneProdotto: 'Descripció de producte',
  lic_DescrizioneRischio: 'Descripció del risc',
  lic_CapitaleAssicurato: 'Import assegurat',
  lic_SELEZIONA: '--SELECCIONEU--',
  lic_PrNetto: '% de la prima',
  lic_ImpAcq: 'Impost de compra',
  lic_profileInvestmentIncomplete: 'Inversió incompleta, perfils d\'inversió complets',
  lic_fundsInvestmentIncomplete: 'Inversió incompleta, fons d\'inversió complets',
  lic_Clauses: 'Clàusules',
  lic_quote_type: 'Tipus de cotització',
  lic_quote_type_anonymous: 'Anònim',
  lic_Policyholder: 'Titular de la pòlissa',
  lic_quote_type_registered: 'Enregistrat',
  lic_text_required: 'Text necessari',
  lic_text_required_error: 'Cal un text de clàusula.',
  lic_close: 'tanqueu',
  lic_save: 'deseu',
  lic_NEXT: 'SEGÜENT',
  lic_policyData: 'Dades de la pòlissa',
  lic_pipData: 'Dades de PIP',
  lic_parties: 'Parts',
  lic_assets: 'Actius',
  lic_quotation: 'Cotització',
  lic_summary: 'Resum',
  lic_issue: 'Emissió',
  lic_investments: 'Inversions',
  lic_invest_value_range: 'Introduïu un valor entre {{min}} i {{max}}',
  lic_disinvestment_range: 'Introduïu un valor de desinversió entre {{min}} i {{max}}',
  lic_mandatory_threshold_perc: 'Fons: {{value}} - introduïu el percentatge de llindar',
  lic_mandatory_one_fund: 'Seleccioneu almenys un fons',
  lic_invest_factor_min_validation: '{{factor}} ha de ser més petit que {{amount}}',
  lic_invest_factor_max_validation: '{{factor}} ha de ser més gran que {{amount}}',
  lic_invest_factor_eq_validation: '{{factor}} ha de ser igual que {{amount}}',
  lic_calculate_asset_volatility: 'Calculeu la volatilitat d\'actius',
  lic_calculate_sustainability_asset: 'Calculeu la sostenibilitat d\'actius',
  lic_occurance_number_range: 'El nombre d\'ocurrències ha de ser més gran que {{min}}',
  lic_policy_holder: 'Titular de la pòlissa',
  lic_guarantee: 'Garantia',
  lic_co_applicant_not_same_holder: 'El cosol·licitant no ha de ser el mateix que el titular de la pòlissa',
  lic_co_applicant_physical: 'El cosol·licitant ha de ser una persona física',
  lic_co_applicant_mandatory: 'El cosol·licitant és obligatori',
  lic_co_applicant: 'Cosol·licitant',
  lic_insured_must_be_physical: 'L\'assegurat ha de ser una persona física',
  lic_mandatory_product: 'Seleccioneu un producte',
  lic_mandatory_insured: 'Seleccioneu un assegurat',
  lic_mandatory_holder: 'Seleccioneu un titular de la pòlissa',
  lic_contribution_frequency: 'La freqüència de contribució {{frequency}} ha de ser la mateixa',
  lic_mandatory_benef: 'Beneficiari {{cathegory}}: el Beneficiari és obligatori',
  lic_mandatory_appendix_benef: 'Beneficiari {{cathegory}}: el beneficiari de l\'apèndix és obligatori',
  lic_add_benef: 'Beneficiari {{cathegory}}: afegiu beneficiari del registre civil',
  lic_add_benef_perc: 'Beneficiari {{cathegory}} {{nominative}}: Agregeu percentatge',
  lic_benef_perc_range: 'Beneficiari {{cathegory}} {{nominative}}: el percentatge ha d\'estar entre 0,01 i 100',
  lic_benef_total_perc: 'Beneficiari {{cathegory}}: el percentatge total ha de ser del 100 %',
  lic_guarantee_mandatory_data: 'Garantia {{unit}}: \'Empleneu totes les dades obligatòries',
  lic_quotation_not_done: 'Cotització no efectuada: seleccioneu "cotitzar" per actualitzar la cotització',
  lic_quotation_modified_value_quote: 'Les dades s\'han actualitzat: seleccioneu "cotitzar" per actualitzar la cotització',
  lic_quotation_benef_custom_msg: 'Beneficiari {{cathegory}} - {{msg}}',
  lic_product: 'Producte',
  lic_managementNode: 'Node de gestió',
  lic_branch: 'Sucursal',
  lic_agreement: 'Acord',
  lic_effect_date: 'Data d\'efecte',
  lic_exp_date: 'Data de venciment',
  lic_payment_frequency: 'Freqüència de pagament',
  lic_means_of_payment: 'Mitjans de pagament',
  lic_unit_type: 'Tipus d\'unitat',
  lic_risk_type: 'Tipus de risc',
  lic_substate: 'Subestat',
  lic_benefit: 'Benefici',
  lic_decreasing_frequency: 'Freqüència decreixent',
  lic_damages: 'Danys',
  lic_effective_holder: 'Titulats beneficiaris',
  lic_insured_role: 'Assegurat',
  lic_third_contact_person: 'Tercera persona de contacte',
  lic_quotation_benef_owner_perc_validation: '{{roleDescr}} {{name}} del beneficiari {{benefName}} no respecta el percentatge mínim acceptat {{minPerc}}',
  lic_subscriber: 'Subscriptor',
  lic_yes: 'sí',
  lic_no: 'no',
  lic_new_insured: 'Nou assegurat',
  lic_simple_select: 'Seleccioneu',
  lic_first_instalment: 'Primer pagament',
  lic_next_instalment_premium: 'Prima del pròxim pagament',
  lic_payment_type: 'Tipus de pagament',
  lic_first_instalment_payment_type: 'Tipus de pagament del primer pagament',
  lic_annual_premium: 'Prima anual',
  lic_quote_button: 'Cotitzeu',
  lic_quote_save: 'Cotitzeu i deseu',
  lic_purchasing_commission: 'Comissió de compres',
  lic_beneficiaries_label: 'Beneficiaris',
  lic_general_data_label: 'Dades generals',
  lic_contractual_option_not_allowed: 'Opcions contractuals no permeses',
  lic_investment_value_label: 'Valor d\'inversió',
  lic_investment_profile: 'Perfil d\'inversió',
  lic_investment_funds: 'Fons d\'inversió',
  lic_calculate_finantial_parameters: 'Calculeu paràmetres financers',
  lic_confirm_button: 'Confirmeu',
  lic_cancel_button: 'Cancel·leu',
  lic_save_button: 'Deseu',
  lic_continue_button: 'Continueu',
  lic_next_button: 'Següent',
  lic_required: 'obligatori',
  lic_node: 'Node',
  lic_company: 'Companyia',
  lic_product_type: 'Tipus de producte',
  lic_taxes_label: 'Imposts',
  lic_fees_label: 'Tarifes',
  lic_frequency_interests: 'Freqüència d\'interessos',
  lic_frequency: 'Freqüència',
  lic_accessories: 'Accessoris',
  lic_taxable: 'Gravable',
  lic_quotation_detail_modal: 'Detall de cotització',
  lic_guarantees_overview_modal: 'Resum de garanties',
  lic_gross_label: 'Brut',
  lic_int_fract_label: 'Int. frac.',
  lic_next_instalment_label: 'Pròxim pagament',
  lic_pure_premium_label: 'Prima pura',
  lic_extra_premium_label: 'Prima extra',
  lic_loadings_label: 'Recàrrecs',
  lic_total_label: 'Total',
  lic_beneficiary_perc_is_over: 'La suma dels percentatges dels rols vinculats a {{benefCat}} {{benefRole}} supera el 100',
  lic_questionSTR_invalid: 'La resposta a la pregunta: {{question}}, no és correcta',
  lic_purchase_commissions: 'Comissions de compra',
  lic_sales_commissions: 'Comissions de venda',
  lic_IBAN_formally_incorrect: 'L’IBAN no és vàlid en forma',
  lic_holder_physical_person_req: 'El titular de la pòlissa ha de ser una persona física',
  lic_holder_legal_person_req: 'El titular de la pòlissa ha de ser una persona jurídica',
  lic_available_covers: 'Cobertures disponibles',
  lic_covers_overview: 'Resum de cobertures',
  lic_covers: 'Cobertures',
  lic_cover: 'Cobertura',
  lic_config_profile_warning: 'ADVERTIMENT: els perfils de configuració han variat.',
  lic_select: 'Seleccioneu',
  lic_Irrevocable_beneficiary: 'Beneficiari irrevocable',
  lic_Base: '(Base)',
  lic_Accessory: '(Accessori)',
  lic_investment_value: 'Valor d\'inversió',
  lic_volatility: 'Volatilitat',
  lic_name: 'Nom',
  lic_to_be_invested: 'A invertir',
  lic_info_investment_completed: 'Informació: inversió completada',
  lic_fund: 'Fons',
  lic_max_percentage: 'percentatge màxim acceptat',
  lic_min_percentage: 'percentatge mínim acceptat',
  lic_selected_funds: 'Fons seleccionats',
  lic_allocation_exceeds: 'L\'assignació d\'inversió supera l\'import disponible',
  lic_percentage_threshold: '% Llindar',
  lic_fund_distribution_identical: 'Distribució de fons idèntica al pagament inicial',
  lic_fund_not_available: 'sense fons per a l’opció',
  lic_details: 'Detalls',
  lic_starting_fund: 'Fons inicial',
  lic_fund_category: 'Perfil/Fons',
  lic_equivalent: 'Equivalent en la data',
  lic_policy_address: 'Adreça de la pòlissa',
  lic_policy_master_details: 'Detall mestre de la pòlissa',
  lic_administrative_data: 'Dades administratives',
  lic_ordinary_mail: 'Correu postal',
  lic_threshold: 'Llindar',
  lic_payment_method: 'Mètode de pagament dels pròxims pagaments',
  lic_additional_payment: 'Pagament addicional',
  lic_contractual_options: 'Opcions contractuals',
  lic_elements: 'Elements',
  lic_position_number: 'Número de posició',
  lic_questionnaires_section: 'Qüestionaris',
  lic_fees: 'Càrrecs fixos',
  lic__1TERM: 'Primer termini',
  lic__EFFOP: 'Data efectiva',
  lic__OPZFR: 'Freqüència',
  lic__OPZPM: 'Mètode de pagament',
  lic_expirationDate: 'Data d\'expiració',
  lic__OPBEN: 'Beneficiari',
  lic__MINTS: 'Llindar mínim',
  lic__MAXTS: 'Llindar màxim',
  lic__NUMOC: 'Número d\'ocurrència',
  lic__AMTOP: 'Import de rendició',
  lic__MALMT: 'Import mínim',
  lic_amount: 'Import',
  lic_currency: 'Moneda',
  lic__CIBAN: 'IBAN',
  lic_fixedFees: 'Tarifes fixes',
  lic__PCOUP: 'Percentatge de descompte periòdic',
  lic_holder: 'Titular',
  lic_ACTIVE_STATE: 'ACTIU',
  lic_MANDATORY_STATE: 'OBLIGATORI',
  lic_DEACTIVATE_STATE: 'DESACTIVAR',
  lic_premium_label: 'Prima',
  lic_holders_title: 'Titulars',
  lic_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lic_invalid_total_position_percentage: 'El percentatge total per al grup "{{groupType}}" al Beneficiari {{cat}} ha de ser del 100 %',
  lic_beneficiary_position_mandatory: 'Beneficiari {{benefCat}} {{benefName}}, seleccioneu un \"Número de Posició\"',
  lic_role_is_mandatory: '{{role}} és obligatori',
  lic_effective_holder_duplicated: 'Titular efectiu duplicat',
  lic_beneficiary_perc_req_range: '{{benefCat}} {{benefRole}} {{benefName}}: el percentatge ha d\'estar entre 0,01 i 100',
  lic_beneficiary_perc_req: ' {{benefCat}} {{benefRole}} {{benefName}}: introduïu un percentatge',
  lic_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Introduïu un beneficiari del repositori de parts',
  lic_effective_holder_perc: 'El percentatge del titular efectiu {{linkedName}}, del beneficiari {{benefName}}, no compleix amb el percentatge mínim de {{linkedPerc}}%',
  lic_effective_holder_perc_req: '{{linkedName}} titular efectiu de {{benefName}}: introduïu el percentatge',
  lic_or_junction: 'o',
  lic_backdating_not_allowed: 'No es permet la retroactivitat',
};
export default LIC_CA_ES ;

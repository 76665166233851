<div class="ppevo-survey-eval-rule-tree" *ngIf="rule">
    <div class="ppevo-tree-item" (click)="expand.rule=!expand.rule" title="rule">
        <span *ngIf="(rule.messages && rule.messages.length) || (rule.variables && rule.variables.length) || (rule.formulas && rule.formulas.length)"
            [ngClass]="expand.rule?'ppevo-tree-close':'ppevo-tree-open'"></span>
        <span class="ppevo-name">{{rule.description}}</span>
        <span class="ppevo-score">[score: {{rule.score}}]</span>
    </div>
    <div class="ppevo-tree-body" [hidden]="!expand.rule">
        <div *ngIf="rule.messages && rule.messages.length" >
            <div class="ppevo-tree-item" (click)="expand.msgs=!expand.msgs">
                <span [ngClass]="expand.msgs?'ppevo-tree-close':'ppevo-tree-open'"></span>
                <span class="ppevo-name" translate>NG_PASSPROPRO_SURVEY.messages</span>
            </div>
            <div class="ppevo-tree-body" [hidden]="!expand.msgs">
                <div class="ppevo-message" *ngFor="let message of rule.messages">{{message}}</div>
            </div>

        </div>
        <div *ngIf="rule.variables && rule.variables.length">
            <div class="ppevo-tree-item" (click)="expand.vars=!expand.vars">
                <span [ngClass]="expand.vars?'ppevo-tree-close':'ppevo-tree-open'"></span>
                <span class="ppevo-name" translate>NG_PASSPROPRO_SURVEY.variables</span>
            </div>
            <div class="ppevo-tree-body" [hidden]="!expand.vars">
                <table class="ppevo-table">
                    <tbody>
                        <tr *ngFor="let variable of rule.variables">
                            <td>{{variable.description}}</td>
                            <td>{{variable.value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="rule.formulas && rule.formulas.length">
            <div class="ppevo-tree-item" (click)="expand.forms=!expand.forms">
                <span  [ngClass]="expand.forms?'ppevo-tree-close':'ppevo-tree-open'"></span>
                <span class="ppevo-name" translate>NG_PASSPROPRO_SURVEY.formulas</span>
            </div>
            <div class="ppevo-tree-body" [hidden]="!expand.forms">
                <table class="ppevo-table">
                    <tbody>
                        <tr *ngFor="let formula of rule.formulas">
                            <td>{{formula.description}}</td>
                            <td>{{formula.value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

import {Component, ComponentRef, OnDestroy, OnInit} from '@angular/core';
import {QuestionnaireElement} from './questionnaire-element';
import {QuestionnaireService} from './questionnaire.service';
import {UntypedFormGroup} from '@angular/forms';
import {Modal} from '../modal';
import {Questionnaire} from './questionnaire';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mic-questionnaire-modal',
  templateUrl: './questionnaire-modal.component.html',
  styleUrls: ['questionnaire-modal.component.scss']
})
export class QuestionnaireModalComponent implements OnInit, Modal, OnDestroy {

  componentRef: ComponentRef<any>;
  questions: QuestionnaireElement[] = new Array<QuestionnaireElement>();
  questionnaireForm: UntypedFormGroup;
  validationMessages = [];
  answerCodeToValueMap = new Map<string, string>();
  questionnaire: Questionnaire;
  stateSubscription: Subscription;

  constructor(public questionnaireService: QuestionnaireService) {
  }

  ngOnInit() {
    this.initializeQuestionnaire();
    this.stateSubscription = this.questionnaireService.getQuestionnairesListObservable().subscribe(data => {
      this.close();
    });
  }

  close() {
    this.questionnaireService.sendRefreshQuestionnaireSignal();
    this.componentRef.destroy();
  }

  onSubmit() {

    this.validationMessages.length = 0;

    this.questionnaireService.sendConfirmQuestionnaireSignal();

    const questionnaires = this.questionnaireService.getQuestionnaires();
    const currentQuestionnaire = this.questionnaireService.getCurrentQuestionnaire();

    questionnaires.forEach(
      (questionnaire) => {
        if (questionnaire.code === currentQuestionnaire.code) {
          questionnaire = currentQuestionnaire;
        }
      }
    );

    let missingRequiredAnswers = false;

    currentQuestionnaire.questionList.forEach(
      (question) => {
        if (question.answerList) {
          question.answerList.forEach(
            (answer) => {
              if (question.status === 1
                && (answer.selectedValue === null
                  || answer.selectedValue === undefined
                  || String(answer.selectedValue) === '-1'
                  || ((String(answer.selectedValue)) === '0'
                    && answer.answerType === 0))) {
                missingRequiredAnswers = true;
                if (this.questionnaireForm.get(question.code)) {
                  this.questionnaireForm.get(question.code).setErrors({required: true});
                  this.questionnaireForm.get(question.code).markAsDirty();
                }
              }
            }
          );
        }
      }
    );
    if (missingRequiredAnswers) {
      this.validationMessages.push('Missing Required Answer/s');
      return;
    }

    this.questionnaireService.updateQuestionnaires(currentQuestionnaire.code, currentQuestionnaire).subscribe(
      (data) => {
        this.onQuestionnairesUpdated(questionnaires, data);
      },
      (err) => {
        this.close();
      }
    );
  }

  isAnswerValueSelected(answer) {
     return answer && answer.selectedValue !== null && answer.selectedValue !== undefined && answer.selectedValue !== -1;
  }

  onQuestionnairesUpdated(questionnaires, data) {
    for (let i = 0; i < questionnaires.length; i++) {
      if (questionnaires[i].code === data.code) {
        questionnaires[i] = data;
      }
    }

    this.questionnaireService.setQuestionnaires(questionnaires);
    this.questionnaireService.setQuestionnairesList(questionnaires);

    this.questionnaireService.sendInitQuestionnaireSignal();
  }

  trackFn(index, item) {
    if (item) {
      return item.id;
    }
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  protected initializeQuestionnaire() {
    this.questionnaire = this.questionnaireService.getCurrentQuestionnaire();
    this.questionnaire.questionList.forEach(
      (question) => {
        if (question.answerList && question.answerList.length > 0) {

          if (!this.isAnswerValueSelected(question.answerList[0])) {
            question.answerList[0].selectedValue = question.answerList[0].defaultValue;
          }

          this.answerCodeToValueMap.set(question.answerList[0].code,
            String(question.answerList[0].selectedValue));
        }
      }
    );

    this.questionnaire.questionList.forEach(
      (question) => {
        if (question.answerList && question.answerList.length > 0) {
          let visible: boolean;
          let isAConditionedQuestionWithoutAnEnablingQuestion = false;
          const conditions = question.answerList[0].conditionList;
          if (conditions && conditions.length > 0) {
            isAConditionedQuestionWithoutAnEnablingQuestion = this.answerCodeToValueMap.get(conditions[0].answerCode)
              ? false : true;
            if (this.answerCodeToValueMap.get(conditions[0].answerCode)
              === String(conditions[0].value)) {
              visible = true;
            } else {
              visible = false;
            }
          } else {
            visible = true;
            if (question.status === 3) {
              visible = false;
            }
          }
          let required = false;
          if (question.status === 1 && !isAConditionedQuestionWithoutAnEnablingQuestion) {
            required = true;
          }
          if (isAConditionedQuestionWithoutAnEnablingQuestion) {
            question.status = 3;
          }
          this.questions.push(new QuestionnaireElement(
            question.code, question.description ? question.description : question.code,
            question.answerList[0].valueList, question.answerList[0].selectedValue,
            question.answerList[0].answerType, visible,
            question.answerList[0].conditionList ? question.answerList[0].conditionList[0] : null,
            question.answerList[0].code, required, question.answerList[0].disabled)
          );
        } else {
          if (question.status === 2) {
            this.questions.push(new QuestionnaireElement(
              question.code, question.description ? question.description : question.code,
              null, null, -1, true, null, null, false, null)
            );
          }
        }
      }
    );

    this.questionnaireForm = this.questionnaireService.toFormGroup(this.questions);
  }

}

<form [formGroup]="group">
  <div class="grid">
    <pnc-datepicker
      [label]="label"
      [customFormControlName]="config.name"
      [customFormGroup]="group"
      [required]="config.mandatory"
      (change)="onChange()"
      (dateSelect)="onChange()"
      data-qa="mic-datepicker-payment"
    >
    </pnc-datepicker>
  </div>
</form>

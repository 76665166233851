import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';


export interface Token {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class RgiRxTokenProvider {

  private readonly _token = new BehaviorSubject<Token>(null);

  constructor() {}

  get token(): string | undefined {
    return this._token.getValue() ? this._token.getValue().token : undefined;
  }

  set token(token: string|undefined) {
    this._token.next({
      token
    });
  }
  get token$(): Observable<string> {
    return this._token.pipe(filter(t => !!t), map(t => t.token));
  }
}

/**
 * @deprecated use RgiRxTokenProvider
 * @see RgiRxTokenProvider
 */
export class TokenProviderService extends RgiRxTokenProvider {}


<div class="" *ngIf="documents">
  <!-- Input Documents -->
  <div *ngIf="isInputDocumentsSectionVisible">
    <div class="rgishared-flex-center rgishared-mb-s">
      <div class="rgi-ui-title-1 rgishared-mr-s" translate>RGI_SHARED_COMPONENTS.INPUT_DOCUMENTS</div>
      <button rgi-rx-button variant="outline"
              *ngIf="addDocumentEnabled"
              (click)="addDocument()"
              [disabled]="!uploadDownloadEnabled"
              translate>
        RGI_SHARED_COMPONENTS.ADD
        <span class="rgi-ui-icon-plus"></span>
      </button>
    </div>
    <div class="rgishared-mb-s" *ngFor="let document of documents.inputDocuments">
    <rgi-rx-expansion-panel color="default" >
      <div rgi-rx-expansion-panel-header>
        <div class="rgishared-flex-center rgishared-flex-gr-1">
          <div rgi-rx-expansion-panel-label>
            <span class="rgi-ui-text-4 rgi-ui-icon-document rgi-ui-primary"></span> {{document.description}}
          </div>
          <rgi-rx-badge class="rgishared-ml-m" color="secondary"
                        translate
                        [translateParams]="{filesLength: document.files.length, fileMax: document.max}">
            RGI_SHARED_COMPONENTS.NUMBER_OF_FILES_INSERTED</rgi-rx-badge>
        </div>
        <div class="rgishared-flex-center">
          <div class="rgi-ui-text-4 rgishared-mr-s">{{ 'RGI_SHARED_COMPONENTS.STATE' | translate }}:</div>
          <span class="rgi-ui-text-4 rgi-ui-icon-warning rgi-ui-danger" *ngIf="document.files.length < document.min"></span>
          <span class="rgi-ui-text-4 rgi-ui-icon-confirmation rgi-ui-success" *ngIf="document.files.length >= document.min"></span>
          <div class="rgishared-vertical-separator"></div>
          <div>
            <button rgi-rx-button color="info"
                    *ngIf="uploadFileEnabled"
                    (click)="uploadFile(document.id)"
                    [disabled]="!uploadDownloadEnabled || document.files.length == document.max">
              {{ 'RGI_SHARED_COMPONENTS.UPLOAD' | translate }}
              <span class="rgi-ui-icon-upload"></span>
            </button>
          </div>
          <div class="rgishared-ml-s">
            <button rgi-rx-button color="info"
                    *ngIf="deleteDocumentEnabled && !document.mandatory"
                    (click)="deleteDocument(document.code)">
              {{ 'RGI_SHARED_COMPONENTS.DELETE_DOC' | translate }}
              <span class="rgi-ui-icon-delete"></span>
            </button>
          </div>
        </div>
      </div>
      <div>
        <div *ngFor="let file of document.files; trackBy: trackByIdFile">
          <!-- files-->
          <div class="rgishared-flex-center">
            <div class="rgishared-flex-gr-1">
              <span class="rgi-ui-title-3">{{ file.name }}</span>
            </div>
            <div class="rgishared-flex">
              <div class="rgishared-ml-s">
                <button rgi-rx-button color="info"
                        class="rgishared-ml-s"
                        *ngIf="(validationDocEnabled && document.toBeValidated)"
                        (click)="validationFile(document.id, file.id)">
                  {{ 'RGI_SHARED_COMPONENTS.VALIDATION' | translate     }}
                  <span [ngClass]="{'pnc_element_validated' : file.validate}"
                    class="rgi-ui-icon-seal"> </span>
                </button>
              </div>
              <div class="rgishared-ml-s">
                <button rgi-rx-button variant="rounded" color="info"
                        (click)="previewFile(file);">
                  <span class="rgi-ui-icon-search"></span>
                </button>
              </div>
              <div class="rgishared-ml-s">
                <button rgi-rx-button variant="rounded" color="info"
                        (click)="updateFile(document.id, file.id)"
                        [disabled]="file.validate">
                  <span class="rgi-ui-icon-edit2"></span>
                </button>
              </div>
              <div class="rgishared-ml-s">
                <button rgi-rx-button variant="rounded" color="info"
                        *ngIf="deleteFileEnabled"
                        (click)="deleteFile(document.id, document.code, file.idDocDMS)"
                        [disabled]="file.validate">
                  <span class="rgi-ui-icon-delete2"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="">
            <rgi-generic-detail
              [details]="convertToLabeledEntity(file.metadataList)">
            </rgi-generic-detail>
          </div>
          <hr class="rgishared-separator">
        </div>

        <div *ngIf="!document.files || document.files.length==0"><span translate>RGI_SHARED_COMPONENTS.NO_FILES</span></div>
      </div>
    </rgi-rx-expansion-panel>
  </div>
  </div>
  <div *ngIf="isOutputDocumentsSectionVisible">
    <!-- Output Documents -->
    <div class=""
         *ngIf="documents.outputDocuments && documents.outputDocuments.length">
      <h1 translate>RGI_SHARED_COMPONENTS.OUTPUT_DOCUMENTS</h1>
    </div>
    <div *ngFor="let document of documents.outputDocuments">
      <!-- intestazione -->
      <div class="">
        <div class="">
          <div class="">
            <span class=""></span>
            <b class="">{{ document.description }}</b>
          </div>
          <div class="">
            <div class="">
              <span class=""><span translate>RGI_SHARED_COMPONENTS.STATE</span>:</span>
              <span class=""
                    *ngIf="!document.files[0] || !document.files[0].downloaded"></span>
              <span class=""
                    *ngIf="document.files[0] && document.files[0].downloaded"></span>
              <button type="button" class=""
                      (click)="downloadDocument(document)" [disabled]="!uploadDownloadEnabled">
                {{ 'RGI_SHARED_COMPONENTS.DOWNLOAD' | translate }}&nbsp;<span class=""></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {ActiveRoute, RgiRxRouteOptions, RgiRxURIDef} from '../router.api';
import {PopStateEvent} from '@angular/common';
import {SubscriptionLike} from 'rxjs';
import {InjectionToken} from '@angular/core';
import {RgiRxRouteDef} from '../routing.service';


export abstract class RgiRxRouterLocation {
  /**
   * Handle the history push state from the ActiveRoute
   * @param activeRoute the current active route transition
   */
  abstract push(activeRoute: ActiveRoute);

  abstract subscribe(onNext: (value: PopStateEvent) => void, onThrow?: ((exception: any) => void) | null, onReturn?: (() => void) | null): SubscriptionLike;

  abstract path(): string;

  abstract normalize(url: string): string;

  abstract toDefinition(url: string): RgiRxURIDef;

  abstract shouldNavigateAtLaunch(): boolean;

  abstract href(): string;

  abstract query(): string;

  abstract state(): unknown;

  abstract forward(): void;

  abstract back(): void;

  abstract current(): RgiRxURIDef;

  abstract toExternalURL(url: string): string;

  /**
   * Replace the current history state
   * @param state a serializable object representing the current state
   * @param targetUri a target URI to push. If not set will use the current URI
   */
  abstract replaceState(state: any, targetUri?: RgiRxURIDef);

  abstract setTitle(title: string);
}

export const RGI_RX_ROUTER_LOCATION_DEFAULT_CONFIG: RgiRxRouterLocationConfig = {
  strategy: 'path',
  initialNavigation: true
};

export interface RgiRxRouterLocationConfig {
  strategy: 'hash' | 'path';
  initialNavigation: boolean;
}

export const RGI_RX_ROUTER_LOCATION_CONFIG = new InjectionToken<RgiRxRouterLocationConfig>('RGI_RX_ROUTER_LOCATION_CONFIG');


export interface RgiRxURLRouteDef {
  uriDef: RgiRxURIDef;
  routeDef: RgiRxRouteDef;
}


export interface RgiRxRouteLocationOptions extends RgiRxRouteOptions {
  /**
   * Preserve existing query string during transition
   */
  preserveQueryString?: boolean;
  /**
   * True when it's the first navigation from URL detection
   */
  initialNavigation?: boolean;

  /**
   * Replace the history state after when performing this transition
   */
  replaceState?: boolean;

/*  /!**
   * The query params handling strategy when routing to the same route
   * todo we might implement those to allow more flexibility
   *!/
  queryParamsHandling?: 'merge' | 'preserve';*/
}

export const RGI_RX_ROUTER_LOCATION_GUARD = new InjectionToken<boolean>('RGI_RX_ROUTER_LOCATION_GUARD');

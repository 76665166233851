import {RgiCountryLayerContext, RgiCountryLayerNumberFormat, RgiCountryLayerNumberFormatPipe} from '@rgi/country-layer';
import {RgiRxDataTablePipe} from '@rgi/rx/ui';

export function rxCountryLayerNumberFormatPipe(context: RgiCountryLayerContext, format: RgiCountryLayerNumberFormat): RgiRxDataTablePipe<RgiCountryLayerNumberFormatPipe> {
  return {
    name: 'rgiCountryLayerNumberFormat',
    pipe: new RgiCountryLayerNumberFormatPipe(context, format)
  };
}

export const numberPipe = {
  pipe: 'rgiCountryLayerNumberFormat',
  args: [undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}]
};

import {AdapterprintTranslationsKeys} from './adapterprint-translations-keys';
export const ADAPTERPRINT_EN: AdapterprintTranslationsKeys = {
  _START_ADAPTERPRINT_: 'Adapter Print',
  _RESULT_ADAPTERPRINT_: 'Adapter Print List',
  _ADAPTERPRINT_: {
    _BTN_: {
      _BACK_: 'Back',
      _EMPTY_: 'Empty',
      _FIND_: 'Find',
      _UNDO_: 'Undo'
    },
    _LABEL_: {
      _ACTION_: 'Action',
      _ADDRESS_: 'Address',
      _LOT_: 'Lot',
      _CODE_: 'Code',
      _DATE_SENT_: 'Date sent',
      _DATE_TO_: 'Date to',
      _DESCRIPTION_: 'Description',
      _DOCUMENT_TYPE_: 'Document type',
      _MANAGEMENT_NODE_: 'Management node',
      _NODE_: 'Node',
      _NODE_CODE_: 'Node code',
      _NODE_DESCRIPTION_: 'Node Description',
      _NODE_LIST_: 'Node list',
      _POLICY_NUMBER_: 'Policy number',
      _ADDRESSED_: 'Addressed',
      _START_DATE_: 'Date from',
      _STATUS_: 'Status',
      _POLICY_: 'Policy',
      _TEMPLATE_: 'Template',
      _OUTCOME_: 'Outcome',
      _USER_: 'User'
    },
    _MSG_: {
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Fields marked in red are required or contain incorrect values',
      _NO_RESULTS_: 'No Result',
      _NOT_SENT_: 'Not sent',
      _SENT_OK_: 'Posted OK',
      _SENT_KO_: 'Posted KO',
    }
  },
};

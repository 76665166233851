import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { switchMap, tap } from 'rxjs/operators';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import { Definition, FactorDefinition, PaymentTypeDefinition } from '../../models/postsales-operations-response.model';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-termination-individual-policy',
  templateUrl: './termination-individual-policy.component.html',
  styleUrls: ['./termination-individual-policy.component.css']
})
export class TerminationIndividualPolicyComponent extends AbsOperationComponent {

  @ViewChild('factorAdapter') child: LpcFactorAdapterComponent;

  protected operationDataKey = 'terminationCause';

  public stepPaymentFieldId = 'payment';

  private $paymentTypes: PaymentTypeDefinition[] = [];

  public terminationCauseDefinitions: FactorDefinition;

  public productFactorsDefinitions: FactorDefinition[] = [];

  public requestFactor: RequestFactor[] = [];

  public quotationData: any;
  public damageQuotationData: any;

  public showCreditPayment: boolean;

  public quotationDefinitions: Definition[] = [
    {
      code: 'warrantyDescription',
      label: 'Description',
      visible: true
    },
    {
      code: 'reimbursementAmount',
      label: 'Column 1',
      visible: true
    },
    {
      code: 'recoveredCommission',
      label: 'Column 2',
      visible: true
    }
  ];

  public get checkLastStepDates(): boolean {
    return !this.withListProductFactors && !this.showCreditPayment && (!!this.quotationData && !this.quotationData.visible);
  }

  public get withListProductFactors(): boolean {
    return !!this.productFactorsDefinitions && !!this.productFactorsDefinitions.length;
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  ngOnInit() {
    this.initializeSession();
    this.formGroup = this.getFormGroup();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.terminationCauseDefinitions = result.definitions.terminationCause as FactorDefinition;
        if (!!this.terminationCauseDefinitions && this.terminationCauseDefinitions.required) {
          this.formGroup.get('terminationCause').setValidators([Validators.required]);
        }
        this.productFactorsDefinitions = result.definitions.productFactors as FactorDefinition[];
        this.formGroup.get('payment').setValue(result.data.operationData.data.creditPayment, {emitEvent: false});
        if (!!result.data.operationData.data.terminationCause) {
          this.formGroup.get('terminationCause').setValue(result.data.operationData.data.terminationCause.code);
        }
        this.detectChanges();
        if (this.terminationCauseDefinitions && this.terminationCauseDefinitions.visible) {
          this.stepper.slideTo(0);
        }
        if (result.definitions.paymentTypes != null) {
          this.showCreditPayment = true;
        } else {
          this.showCreditPayment = false;
        }
        this.quotationData = result.data.operationData.data.quotationData;
      })
    );
  }

  getDescription(code: string): string {
    const foundTerminationCause = this.terminationCauseDefinitions && this.terminationCauseDefinitions.listValue.find(e => e.code === code);
    return foundTerminationCause && foundTerminationCause.description;
  }

  updateProductFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
  }

  protected getTransformedOperationData(): any {
    const obj: any = {
      terminationCause: { code: this.formGroupValue.terminationCause },
      listProductFactor: !!this.requestFactor && this.requestFactor.length > 0 ? this.requestFactor : [],
      creditPayment: !!this.formGroupValue[this.stepPaymentFieldId] ? this.formGroupValue[this.stepPaymentFieldId] : {}
    };

    return obj;
  }
  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      terminationCause: new UntypedFormControl(),
      dates: new UntypedFormControl({}),
      factors: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      payment: new UntypedFormControl()
    });
  }

  isFormValid(form): boolean {
        return form.disabled ? true : form.valid;
  }

  onNext(step, publish = false, context = null, isConfirmAndAccept = false) {
    /* if (step === 'factors') {
      this.child.updateFactors(true);
    } */
    if (this.isFormValid(this.formGroup.get(step))) {
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, step, this).pipe(
        tap(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
          if (!!this.avcQuestionnaire) {
            this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(this.avcQuestionnaire);
          }
        }),
        switchMap(() => {
          return this.updateDraft(step);
        })
      ).subscribe(result => {
          this.productFactorsDefinitions = result.definitions.productFactors as FactorDefinition[];
          this.quotationData = result.data.operationData.data.quotationData;
          this.damageQuotationData = result.data.operationData.data.damageQuotationData;
          this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
          this.detectChanges();
          if (!this.hasBlockingErrorsOnSteps(step)) {
            this.doPublishOrNext(publish, isConfirmAndAccept);
          }
        })
      );
    } else {
      this.setFeErrors(step);
    }
  }
}

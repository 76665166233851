import {Injectable} from '@angular/core';
import {EventService} from '@rgi/rx';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {Observable, of} from 'rxjs';
import {RePostsalesSubstitutionNavigationData, RePostsalesSubstitutionState} from './re-postsales-substitution-state';
import {RePostsalesStateManagerService} from './re-postsales-state-manager.service';
import {
  RePostsalesSubstitutionBusinessService
} from '../re-postsale-services/re-postsales-substitution-business.service';
import {PolicySubstitutionRequest} from '../re-postsale-model/substitution';
import {SearchCardDto} from "../re-postsale-model/search-card-dto";

@Injectable()
export class RePostsalesStateManagerSubstitutionService<T extends RePostsalesSubstitutionState> extends RePostsalesStateManagerService<T> {

  constructor(
    activeRoute: ActiveRoute,
    stStoreService: StateStoreService,
    protected routingService: RoutingService,
    public eventService: EventService,
    protected datesBusinessService: RePostsalesSubstitutionBusinessService<T>,
  ) {
    super(activeRoute, stStoreService, routingService, eventService);
    const st =
      !!stStoreService.get<T>(this.activeRoute.id) ?
        stStoreService.get<T>(this.activeRoute.id) : this.newDamagesPostsalesSubstitutionState();
    this.updateState$(this.initDamagesPostSalesSubstitution(st));
  }

  newDamagesPostsalesSubstitutionState(): T {
    return new RePostsalesSubstitutionState(this.activeRoute.id) as T;
  }

  initDamagesPostSalesSubstitution(st: T): Observable<T> {
    const req = this.activeRoute.getRouteData<RePostsalesSubstitutionNavigationData>();
    if (req.policy) {
      st.policy = req.policy;
    }

    if (req.backNavigate) {
      st.backNavigate = req.backNavigate;
    }

    if (req.contract) {
      st.contract = req.contract;
    }

    if (req.node) {
      st.node = req.node;
    }

    if (req.authenticationNodeCode) {
      st.authenticationNodeCode = req.authenticationNodeCode;
    }

    if (req.productionNodeCode) {
      st.productionNodeCode = req.productionNodeCode;
    }

    if (req.userCode) {
      st.userCode = req.userCode;
    }

    return this.datesBusinessService.initForm$(st);
  }

  public getCompatibleProducts(effectDate: number) {
    return this.datesBusinessService.getCompatibleProducts$(
      of(this.getCurrentState()), this.getCurrentState().policyNumber, effectDate);
  }

  public findPolicies(body: SearchCardDto){
    return this.datesBusinessService.findPolicies(body);
  }

  public createSubstituentContract(policySubstitutionRequest: PolicySubstitutionRequest, policyNumber: string) {
    return this.datesBusinessService.createSubstituentContract(policySubstitutionRequest, policyNumber);
  }

  getCompanies() {
    return this.datesBusinessService.getCompanies$();
  }

  getProducts(productCategory: string) {
    return this.datesBusinessService.getProducts$(of(this.getCurrentState()), this.getCurrentState().node.idSp, productCategory);
  }

  getNodes() {
    return this.datesBusinessService.getNodes$(of(this.getCurrentState()), this.getCurrentState().node.idSp);
  }

  back() {
    const st = this.getCurrentState();
    let req: any;
    if (st.backNavigate === 're-issue-proposal-view') {
      req = {
        policy: st.policy
      };
    }
    this.routingService.navigate('re-issue-proposal-view', req, this.activeRoute.id);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'gpSignedNumber'
})
export class GPSignedNumberPipe implements PipeTransform {

  constructor(protected decimalPipe: DecimalPipe) {
  }
  transform(value: number, isNegative: boolean): any {
    const transformed = this.getSignedValue(value, isNegative);
    return this.decimalPipe.transform(transformed, '1.2-2');
  }

  getSignedValue(value: number, isNegative: boolean): number {
    return Number(isNegative && value > 0 ? value * -1 : value);
  }

}

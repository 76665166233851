<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step *ngIf="isOperationRoleStepPresent" id="operationRoles" label="lpc_roles_variation" [errors]="errors" fieldId="operationRoles" (next)="onNext($event)">
    <lpc-roles-step-wrapper
      [active]="isActiveStep('operationRoles')"
      [roles]="operationRoles"
      [operationCode]="session.operation"
      [roleDefinition]="operationRolesAdmitted"
      [enableDelegateCheckbox]="enableRoleCheckbox('delegate')"
      [enableLegalGuardianCheckbox]="enableRoleCheckbox('legalGuardian')"
      [enableThirdPayerCheckbox]="enableRoleCheckbox('thirdPayer')"
      (roleEvent)="handleRolesEvent($event)">
    </lpc-roles-step-wrapper>
  </lpc-step>

  <lpc-step id="questsFin" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" fieldId="questsFin" label="lpc_financial_questionnaire_step" (next)="onNext($event)">
    <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                       [questionnairesCode]="getQuestMap('FIN')" type="FIN"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #financialQuestionnaire></lpc-questionnaire>
  </lpc-step>

  <lpc-step id="beneficiaries" label="lpc_variation_step_label"
            [errors]="errors" feFieldId="beneficiaries"
            fieldId="beneficiaries" (next)="onNext($event, true)" [feErrors]="feErrorsBenef"
            [disableNext]="disableNext()"
            (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div *ngIf="isActiveStep('beneficiaries')">
      <div formGroupName="beneficiaries">
        <div class="col-lg-12">
            <div *ngFor="let beneficiaryDefinition of beneficiariesDefinitions">
              <div class="col-lg-6" [hidden]="!beneficiaryDefinition.visible">
                  <lpc-beneficiary
                    [beneficiaryRoles]="beneficiaryRoles"
                    [formControlName]="'b' + beneficiaryDefinition.code"
                    [beneficiaryDefinition]="beneficiaryDefinition"
                    [isREG41Enabled]="isREG41Enabled"
                    [isBENDGEnabled]="isBENDGEnabled"
                    [paymentTypes]="paymentTypes"
                    [benefPositionNumberList]="benefPositionNumberList">
                  </lpc-beneficiary>
              </div>
              </div>
        </div>

      </div>
      <div class="row">
          <div *ngIf="!!thirdPartyDefinition && thirdPartyDefinition.visible" class="col-lg-12">
            <div class="col-lg-6">
                <lpc-third-party
                  [thirdPartyDefinition]="thirdPartyDefinition"
                  formControlName="thirdParty">
                </lpc-third-party>
            </div>
            </div>
      </div>
    </div>
    <div *ngIf="!isActiveStep('beneficiaries') || !!modalMessage" class="row">
      <div class="col-lg-12" *ngFor="let benef of selectedBeneficiariesForSummary">
        <div  class="col-lg-6" >
          <span>{{benef | benefsummary}}</span>
        </div>
        <div *ngIf="benef.linkedSubjectsRoles" class="col-lg-6">
          <div class="col-lg-12" *ngFor="let benefSubj of benef.linkedSubjectsRoles">
            <span syle="margin-left: 10px;">{{benefSubj | benefsummary}}</span>
          </div>
        </div>
        <div style="margin-bottom: 20px;" class="col-lg-12"></div>
      </div>
    </div>
  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" id="quests" [errors]="errors" [enableAuth]="isAuth" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')"
            fieldId="quests" label="lpc_avc_questionnaire" (next)="onNext($event, true)" feFieldId="quests"
            (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-questionnaire
                       [active]="!isAfterId('quests')" [key]="composeKey(key, 'quests')"
                       [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #avcQuestionnaire></lpc-questionnaire>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
            (confirmAccept)="publish(true)" [isConfirmAndAccept]="isConfirmAndAccept" >
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>


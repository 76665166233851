import {Component, Input} from '@angular/core';

@Component({
  selector: 'consultation-premium-tab',
  templateUrl: './consultation-premium-tab.component.html',
  styleUrls: ['./consultation-premium-tab.component.scss']
})
export class ConsultationPremiumTabComponent {

  @Input() title: string;
  @Input() gross: number;
  @Input() currencyCode: string;
}

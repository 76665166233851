import { Component, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, ValidatorFn, UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { Factor } from '../../../models/factor.model';

@Component({
  selector: 'lpc-datepicker-factor',
  templateUrl: './lpc-datepicker-factor.component.html',
  styleUrls: ['./lpc-datepicker-factor.component.css']
})
export class LpcDatepickerFactorComponent implements OnInit {
  value: string;
  label: string;
  config: Factor;
  group: UntypedFormGroup;
  prevVal: string;
  regex = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}$');
  modifiable: string;
  mandatory: string;

  constructor(protected el: ElementRef) {}

  ngOnInit() {
    this.prevVal = this.config.value;
    this.initDate();

    this.regex.test('12/05/2019');

  }

  parseYMD(dataYMD): Date {
    // console.log(dataYMD);
    return new Date(dataYMD);
  }

  initDate() {
    const validatori: ValidatorFn[] = [this.validatoreData];
    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.value !== null && this.config.value !== EMPTY_STR && this.config.value !== '-1') {
      this.group.controls[this.config.code].setValue(this.parseYMD(this.config.value));
    } else {
      this.group.controls[this.config.code].setValue(null);
    }
    if (this.config.modifiable) {
      this.modifiable = 'Modificabile';
      this.group.controls[this.config.code].enable();
    } else {
      this.modifiable = 'Non modificabile';
      this.group.controls[this.config.code].disable();
    }
    if (this.config.compulsory) {
      this.mandatory = 'Obbligatorio';
      validatori.push(Validators.required);
    } else {
      this.mandatory = 'Non obbligatorio';
    }
    this.group.controls[this.config.code].setValidators(validatori);
  }

  setFactor(factor: Factor, form: UntypedFormGroup) {
    this.config = factor;
    this.label = this.config.description;
    this.group = form;
  }

  onChange() {
    if (this.prevVal !== this.group.controls[this.config.code].value) {
      this.el.nativeElement.dispatchEvent(new Event('changeVal', {bubbles: true}));
      this.prevVal = this.group.controls[this.config.code].value;
    }
  }

  validatoreData: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (this.config.compulsory && control.value instanceof Date) {
      if (control.value.getFullYear() <= 1900) {
        return {'Data non valida': true};
      }
    }
    return null;
  }
}

import {StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPostSalesOrchestratorService} from '../../orchestrator/pnc-postsales-orchestrator.service';
import {Observable} from 'rxjs';
import {PncPostSalesIntegrationService} from '../../services/pnc-postsales-integration.service';
import {RgiPncPsalesStateManager} from '../../api/rgi-pnc-postsales-state.manager';
import {RgiRxPushMessage, RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPsalesHttpErrorService} from '../../services/pnc-postsales-http-error.service';
import {PncPsalesTechAccountingState} from '../../resources/states/technical-accounting-state';
import {catchError, concatMap, map} from 'rxjs/operators';
import {PncDomainService} from '../../resources/http/pnc-domain.service';
import {TechnicalAccountingList} from '../../resources/model/api/technical-accounting';
import {TECH_ACCOUNTING_TABLE_SCHEMA} from '../../resources/constants/table-schemas/tech-accounting-list-schema';
import {TableSchema} from '@rgi/rx/ui';
import { SUMMARY_SECTION_DETAIL_TYPE } from '../../resources/model/common/summary-step-detail';
import { LabeledEntity } from '../../resources/model/common/entities';

export class PncPsalesTechAccountingStateManager extends RgiPncPsalesStateManager<PncPsalesTechAccountingState> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private resourceService: PncDomainService
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
    if (!context.apiPrefix) {
      throw new Error('Api Prefix is not provided for technical accounting step');
    }
  }

  initState$(state: Observable<PncPsalesTechAccountingState>): Observable<PncPsalesTechAccountingState> {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    return state.pipe(
      concatMap((st: PncPsalesTechAccountingState) => {
        return this.resourceService.getTechAccountingList$(st.policyNumber, st.operationType, st.currentOperation.code, this.context.apiPrefix, st.draftId).pipe(
          map((resp: TechnicalAccountingList) => {
            st.techAccounting = resp;
            if (!st.techAccounting.length) {
              this.pushMessageHandler.notify(new RgiRxPushMessage('_PCPSALES_._MSG_._NO_TECH_ACC_FOUND_', {
                  tag: this.activeRoute.route,
                  status: 'info',
                  dismissible: true
                }
              ));
            }
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesTechAccountingState) => st)
    );
  }

  getTableDataRows() {
    return this.getCurrentState().techAccounting.map( (row) => {
      return {
        amount: row.amount,
        commissions: row.commissionsAmount,
        currency: row.currency.description,
        effect: row.effect,
        node: row.node.description,
        status: row.status.description,
        type: row.type.description,
        appendix: row.appendix,
      };
    });
  }

  getTableDataSchema(): TableSchema {
    return TECH_ACCOUNTING_TABLE_SCHEMA;
  }

  actionContinue() {
    this.setSummaryData(this.getCurrentState());
    super.actionContinue();
  }

  setSummaryData(st: PncPsalesTechAccountingState) {
    let rows = st.techAccounting.map( (row) => {
      return [
        new LabeledEntity('_RGIPNC_._LABEL_._NODE_', row.node.description),
        new LabeledEntity('_RGIPNC_._LABEL_._TYPE_', row.type.description),
        new LabeledEntity('_RGIPNC_._LABEL_._STATUS_', row.status.description),
        new LabeledEntity('_RGIPNC_._LABEL_._AMOUNT_', row.amount),
        new LabeledEntity('_RGIPNC_._LABEL_._EFFECT_DATE_', row.effect),
      ]
    });

    st.summaryStepDetail = {
      stepLabel: '_PCPSALES_._STEPS_._TECHNICAL_ACCOUNTING_',
      sections: [
        {
          title:'',
          detail: {
            type: SUMMARY_SECTION_DETAIL_TYPE.ROWS,
            rows
          }
        }
      ]
    };
  }
}

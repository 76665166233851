import {Directive, HostBinding, HostListener, Input} from '@angular/core';
import {RgiRxOptionComponent} from '../form-elements/rgi-rx-option/rgi-rx-option.component';
import {RgiRxMultiSelect} from './rgi-rx-multiselect.providers';

@Directive({
  selector: '[rgiRxMultiselectRemoveTriggerFor]'
})
export class RgiRxMultiselectRemoveTriggerDirective {

  @Input('rgiRxMultiselectRemoveTriggerFor') option: RgiRxOptionComponent<any>;

  constructor(private multiSelect: RgiRxMultiSelect) {}


  @HostBinding('style.cursor')
  get cursor() {
    return this.option.disabled ? 'default' : '';
  }

  @HostListener('mousedown', ['$event'])
  _handleMouseDown(event: MouseEvent) {
    event.preventDefault();
    if (!this.option.disabled && this.option.active && event.button === 0) {
      this.multiSelect.removeOption(this.option);
    }
  }
}

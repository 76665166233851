import { Input, Component, OnInit, ComponentRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Document } from '../document';
import { DocumentFile } from '../document-file';
import { DocumentsService } from '../documents.service';
import { LoaderService } from '@rgi/portal-ng-core';

@Component({
  selector: 'pcm-document-validation-file-modal',
  templateUrl: './document-validation-file-modal.component.html',
  styleUrls: ['./document-validation-file-modal.component.css']
})
export class DocumentValidationFileModalComponent implements OnInit {

  @Input() title: string;
  @Input() contractId: string;
  @Input() document: Document;
  @Input() file: DocumentFile;
  @Input() arInpDocValidation: Array<any>;
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  validationFileForm: UntypedFormGroup;
  componentRef: ComponentRef<any>;
  validate = false;


  constructor(
      private loaderService: LoaderService,
      public documentsService: DocumentsService,
      private formBuilder: UntypedFormBuilder
  ) { }


  ngOnInit() {
      this.title = this.componentRef.instance.title;
      this.file = this.componentRef.instance.file;
      this.validationFileForm = this.formBuilder.group({
        note: [ '', Validators.required ]
      });
  }

  close() {
    this.componentRef.destroy();
  }

  confirmValidationFile() {
    this.loaderService.startLoader(this.eventPropagation, true);
    this.file.note = this.validationFileForm.controls.note.value;

    this.documentsService.validateFile(this.contractId, this.document.id, this.file).subscribe((file: DocumentFile) => {
        if (file) {
            const listFiles = this.document.files.filter((docFile: DocumentFile) => docFile.id !== file.id);
            this.document.files.length = 0;
            Array.prototype.push.apply(this.document.files, listFiles);
            this.document.files.push(file);
            this.checkDocumentValidation();
        }
    },
    () => {
      console.log('Errore durante la validazione del documento');
    },
    () => {
      this.loaderService.stopLoader(this.eventPropagation, true);
      this.close();
    });
  }

  checkDocumentValidation() {
    let validate = true;

    this.document.files.forEach(file => {
      if (!file.validate) {
        validate = false;
      }
    });

    this.componentRef.instance.arInpDocValidation[this.document.id] = validate;
  }

}

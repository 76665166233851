
export class FilterTATDetails {

    // companyCode: string;
    // nodeId: any;
    // nodeCode: any;
    // subcriptorId: any;
    // managementNodeId: any;
    // managementNodeCode: any;
    policyNumber: string;
    considerSubTreeOfManagementNode: boolean;
    // securityState: any;
    // endorsementNumber: any;
    // productId: any;
    // productCode: any;
    // accountingSubType: any;
    // paymentType: any;
    // paymentStatus: any;
    // amountFrom: any;
    // amountTo: any;
    // expirationDateFrom: any;
    // expirationDateTo: any;
    // effectiveDateFrom: any;
    // effectiveDateTo: any;

    // contractType: number;
    // searchParentNodes: boolean;
    // salePointId: number;
    // salePointCode: number;
    // contractTodayEffectDate: boolean;
    // categories: ApiEnumType[];
    // companyId: string;
    // effectDateTo: Date;
    // expirationDateFrom: Date;
    // states: ApiEnumType[];
    // policyNumber: string;
    // numberPlate: string;
    // extension: {
    //     properties: ApiKeyValue[];
    // };

}

import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Notes} from "../../../models/domain-models/notes";

@Component({
  selector: 're-issue-notes',
  templateUrl: './re-issue-notes.component.html',
  styleUrls: ['./re-issue-notes.component.css']
})
export class ReIssueNotesComponent implements OnInit {
  notesForm: UntypedFormGroup;
  notes$:Notes;

  @Input() set notes(notes: Notes) {
    this.notes$ = notes;
    this.notesForm.patchValue(notes ? notes : new Notes());
  }

  get notes(): Notes {
    return this.notes$;
  }

  constructor(protected formBuilder: UntypedFormBuilder) {
    this.notesForm = this.formBuilder.group({
      text: [null,]
    });
  }

  ngOnInit() {
  }

  getNotes():Notes{
    return this.notesForm.getRawValue();
  }

}

import {ActiveRoute} from '@rgi/rx/router';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {of} from 'rxjs';
import {adaptStateFromForm} from '../../adapters/group-policy-utils';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {
  AnagApiSubject,
  BaseEntity,
  CoinsuranceShare,
  ContractAddressesPut,
  ErrorCode,
  Factor,
  GPClause,
  GPPolicyDataField,
  PaymentsPayload,
  SubjectContract
} from '../../group-policy-models/group-policy-issue-policy-data';
import {GroupPolicyStatelessOpPolicyDataService} from './group-policy-stateless-op-policy-data.service';
import {GroupPolicyStatePolicyData} from '../group-policy-state';
import {PartyRoleParentData} from '../../group-policy-models/gp-party-role';


export class GroupPolicyStatemanagerPolicyData extends AbstractStateManager<GroupPolicyStatePolicyData> {

  constructor(activeRoute: ActiveRoute,
              stateStoreService: StateStoreService,
              protected statelesOps: GroupPolicyStatelessOpPolicyDataService) {
    super(activeRoute, stateStoreService);
    const statePolicyData = this.stateStoreService.has(this.activeRoute.key) ?
      this.stateStoreService.get<GroupPolicyStatePolicyData>(this.activeRoute.key) :
      new GroupPolicyStatePolicyData(this.activeRoute.key);
    const initial$ = this.statelesOps.initPolicyData$(of(statePolicyData), this.activeRoute);
    this.updateState$(initial$);
  }

  /**
   * @param subj party to set
   * @param role assigned to subj
   * @param disablePolicyHolder flag to disable policyholder if the subj arrive
   * for example from questionnaire
   */
  actionSetPartyRole(subj: AnagApiSubject, previousStepData: DataForSteps, role: string, disablePolicyHolder?: boolean) {
    const st = this.getCurrentState();
    const reqSubj = {} as SubjectContract;
    reqSubj.idParty = subj.objectId;
    reqSubj.role = role;
    st.disablePolicyHolder = disablePolicyHolder;
    this.updateState$(this.statelesOps.updateParty$(of(st), previousStepData, reqSubj));
  }

  actionDeleteRole(data: DataForSteps, role: string, idParty: string) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.deleteRole$(of(st), data, role, idParty));
  }


  actionUpdateAgreement(data: DataForSteps, agreementCode: string) {
    const st = this.getCurrentState();
    if (!agreementCode) {
      agreementCode = '';
    }
    const agreement = { code: agreementCode } as BaseEntity;
    this.updateState$(this.statelesOps.updateAgreement$(of(st), data.resId, agreement));
  }

  actionUpdatePaymentFrequency(data: DataForSteps, paymentFrequencyCode: string) {
    const st = this.getCurrentState();
    if (!paymentFrequencyCode) {
      paymentFrequencyCode = '';
    }
    const paymentFrequency = { code: paymentFrequencyCode } as BaseEntity;
    this.updateState$(this.statelesOps.updatePaymentFrequency$(of(st), data.resId, paymentFrequency));
  }

  actionUpdateFactors(data: DataForSteps, factors: Factor[]) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.updateFactors$(of(st), data.resId, factors));
  }

  getSystemProp(data: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.getSystemProp$(of(st), data.resId));
  }

  actionGoToConfigurationPm(resId: string, contractAddresses: ContractAddressesPut, updateContract: boolean) {
    const st = this.getCurrentState();
    adaptStateFromForm(st);
    this.updateState$(this.statelesOps.actionGoToConfigurationPm$(of(st), this.activeRoute, resId, contractAddresses, updateContract));
  }

  actionSave(previousStepData: DataForSteps, contractAddresses: ContractAddressesPut, updateAddresses: boolean) {
    const st = this.getCurrentState();
    st.errors = [];
    st.type = ErrorCode.BLOCKING;
    adaptStateFromForm(st);
    this.updateState$(this.statelesOps.actionSave$(of(st), previousStepData.resId, previousStepData.node, contractAddresses,
      updateAddresses, this.activeRoute));
  }

  actionSetPercentageOnRole(roleParty: PartyRoleParentData, previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    const reqSubj = {} as SubjectContract;
    reqSubj.idParty = roleParty.idParty;
    reqSubj.role = roleParty.role;
    reqSubj.percentage = roleParty.percentage;
    this.updateState$(this.statelesOps.updateParty$(of(st), previousStepData, reqSubj));
  }

  actionSetCoinsurancesShareData(coinsuranceShares: Array<CoinsuranceShare>, previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.updateCoinsurances$(of(st), coinsuranceShares, previousStepData));
  }

  actionSetIndexing(selectedIndexingCode: string, previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    const selectedIndexing = st.indexing.allowedValues.find(value => value.code === selectedIndexingCode);
    this.updateState$(this.statelesOps.updateIndexing$(of(st), selectedIndexing, previousStepData.resId));
  }

  actionUpdateClauses(clauses: GPClause[], previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.updateClauses$(of(st), clauses, previousStepData));
  }

  setPaymentMethod(paymentMethod: PaymentsPayload, previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.setPaymentMethod$(of(st), paymentMethod, previousStepData));
  }

  getAvailablePayments(previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.getAvailablePayments$(of(st), previousStepData));
  }

  actionUpdatePolicyData(fields: GPPolicyDataField[], previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.updatePolicyData$(of(st), fields, previousStepData));
  }

  actionUpdateExtensionData(fields: GPPolicyDataField[], previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.updateExtensionData$(of(st), fields, previousStepData));
  }

  actionModifySubject(idParty: string) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.modifySubject$(of(st), idParty, this.activeRoute));
  }
}

import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import {
  BeneficiaryDefinition,
  BeneficiaryRole,
  Definition,
  FactorDefinition,
  PaymentTypeDefinition,
  PostsalesError,
  Role
} from '../../models/postsales-operations-response.model';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';

@Component({
  selector: 'lpc-death-claim',
  templateUrl: './death-claim.component.html',
  styleUrls: ['./death-claim.component.scss']
})
export class DeathClaimComponent extends AbsOperationComponent {

  protected operationDataKey = 'death-claim';
  protected anagSubj = null;
  public listProductFactor: FactorDefinition[] = [];
  public requestFactor: RequestFactor[] = [];

  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];

  public damageData: any;

  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];

  get beneficiaryType(): string {
    if (!!this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  public action: string;

  public get hasProductFactors(): boolean {
    return !!this.listProductFactor ? !!this.listProductFactor.length : false;
  }

  private $paymentTypes: PaymentTypeDefinition[] = [];

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  private _beneficiaryData: any[];
  get idAssicurato(): string {
    if (this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected anagService: AnagService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anagService);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {
          LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
          this.insuredSettlements = result.data.operationData.data.insuredSettlements;
          this.tableDefinitions = result.definitions.settlement as Definition[];
          this.action = result.data.operationData.data.action;
          this.listProductFactor = PlcObjectUtils.asValidArray(result.definitions.productFactors as FactorDefinition[]);
          this._beneficiaryData = result.data.operationData.data.deathClaimBeneficiary;
          if (!!this._beneficiaryData) {
            this.formGroup.get('beneficiaries').setValue(
              (this._beneficiaryData as any[]).map(deathClaimBeneficiary => {
                return LpcBeneficiaryUtils.toClaimBeneficiary(deathClaimBeneficiary);
              }),
              {emitEvent: false}
            );
          }
        })
      ).subscribe()
    );
  }

  onNext(step, publish = false, context = step, isConfirmAndAccept = false) {
    // const beStep: string = step === 'dates' ? 'factors' : step;
    if (step === 'factors') {
      this.requestFactor = this.factorAdapter.getRequestFactor();
    }
    if (this.isFormValid(this.formGroup.get(this.stepper.activeStep.id)) &&
      this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
      this.$subscriptions.push(
        this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id)
          .pipe(
            switchMap(_ => {
              return this.plcQuestService.prevalQuest(this.operationDataKey, step, this);
            }),
            switchMap(result => {
              this.questFactorsArray = result;
              this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
              if (!!this.avcQuestionnaire) {
                this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(this.avcQuestionnaire);
              }
              return this.updateDraft(step);
            })
          ).subscribe(result => {
            this.detectChanges();
            const bErrors = result.errors && result.errors.length > 0;
            const toBePublished = (isConfirmAndAccept && !bErrors) || this.stepper.isAtLastStep;
            this.insuredSettlements = result.data.operationData.data.insuredSettlements;
            this.tableDefinitions = result.definitions.settlement as Definition[];
            this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
            LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);

            if (result.definitions.beneficiaries != null) {
              this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
            }

            if (result.data.operationData.data.damageQuotation != null) {
              // metodo che converte la quotazione danni nell'oggetto che utilizziamo nella tabella quotation-recess
              // quando verrà riadattato il servizio facendogli restituire la quotazione danni riformattata non sarà più necessario
              this.setDamageQuotation(result.data.operationData.data.damageQuotation);
            }

            if (!this.hasBlockingErrorsOnSteps(context)) {
              this.doPublishOrNext(toBePublished, isConfirmAndAccept);
            }
            this.cd.detectChanges();
        })
      );
    } else {
      this.setFeErrors(step);
    }
  }

  setDamageQuotation(quotationData) {
    const riskVector = [];
    quotationData.totalAmountsByRisk.map((risk) => {
      riskVector.push({
        warrantyDescription: risk.risk.descrizione,
        residualBenefitAmount: null,
        reimbursementAmount: risk.premium.lordo != null && risk.premium.lordo.real != null ?
          risk.premium.lordo.real.toString().replace('-', EMPTY_STR) : '0',
        recoveredCommission: risk.commissions != null && risk.commissions.real != null ?
          risk.commissions.real.toString().replace('-', EMPTY_STR) : '0'
      });
    });

    this.damageData = {
      listWarrantyRow: riskVector,
      totalReimbursementAmount: quotationData.totalAmount.gross != null && quotationData.totalAmount.gross.real != null ?
        quotationData.totalAmount.gross.real.toString().replace('-', EMPTY_STR) : '0',
      totalRecoveredCommission: quotationData.fees.provvigioni != null && quotationData.fees.provvigioni.real != null ?
        quotationData.fees.provvigioni.real.toString().replace('-', EMPTY_STR) : '0'
    };
  }

  updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe((result) => {
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    }));
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      operationRoles: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      beneficiaries: new UntypedFormControl([])
    });
  }

  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

  protected getTransformedOperationData(): any {
    const obj: any = {
      deathClaimBeneficiary: this.formGroup.getRawValue().beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        }),
      action: this.action
    };
    if (this.requestFactor.length > 0) {
      obj.listProductFactor = this.requestFactor;
    }
    return obj;
  }

  confirmButtonVisibility() {
    return !this.hasBlockingErrorsOnSteps('quotation') && !!this.insuredSettlements && !!this.insuredSettlements.length;
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      tap(result => {
        this.$paymentTypes = result.definitions.paymentTypes;
      })
    );
  }

  getBeneficiaryDefinition(): BeneficiaryDefinition {
    if (!!this.beneficiariesDefinitions && 1 === this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0];
    }

    return {} as BeneficiaryDefinition;
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

 onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
        })
      );
    }
  }

  public changeRouteCloseSession(showModal: boolean) {
    if (showModal) {
      this.$subscriptions.push(
        fromPromise(
          this.openModal('Confirm', 'Are you sure you want to cancel this operation?', true).result
        ).subscribe(result => {
          if (result) {
            this.closeCardAndSession();
          }
        })
      );
    } else {
      this.closeCardAndSession();
    }
  }

  private closeCardAndSession() {
    const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
    if (!!this.$session.sessionInfo && !!this.$session.sessionInfo.sessionId
        && !!this.$session.sessionInfo.isFromClaimDetail && !!sessionService) {
      const sessionStore = sessionService.list();
      for (const session of sessionStore) {
        if (session.id === this.$session.sessionInfo.sessionId) {
          sessionService.setActive(session.idSession);
        }
      }
    }
    this.closeCardWithoutModal();
  }
}

import {Inject, NgModule, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {registerNgPassProSurveyCard} from "./card-routing";
import {NgPassproproSurveyModule} from "../ng-passpropro-survey.module";
import {NgPassproproProductAjsModule} from '@rgi/ng-passpropro/product';
import {AJS_NG_PASSPROPRO_CONFIG,AjsIntegrationConfig} from "@rgi/ng-passpropro-core/portal"
import {NG_PASSPROPRO_SURVEY_CONFIG, SurveyConfig} from "../ng-passpropro-survey-api";
import {downgrade} from "./ajs-configuration";
import {IntegrationModule} from "../integration/integration.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgPassproproSurveyModule,
    IntegrationModule,
    NgPassproproProductAjsModule
  ],
  exports: [
    NgPassproproSurveyModule
  ],
  providers: [
  ]
})
export class NgPassproproSurveyAjsModule {

  constructor(@Inject(AJS_NG_PASSPROPRO_CONFIG) @Optional() ajsIntegrationSurveyConfig: any|AjsIntegrationConfig,
              @Inject(NG_PASSPROPRO_SURVEY_CONFIG) @Optional() surveyConfig: SurveyConfig) {

    downgrade();
    registerNgPassProSurveyCard(ajsIntegrationSurveyConfig, surveyConfig);
  }
}


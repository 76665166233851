import {PortalNgCommonTranslationKeys} from './translation.keys';

export const IT: PortalNgCommonTranslationKeys = {
    PORTAL_NG_COMMON: {
        ADD: 'Aggiungi',
        ADD_DOCUMENT: 'Aggiungi documento',
        DOCUMENTS_MANAGER: 'Gestione Documenti',
        DOCUMENT_TYPE: 'Tipo documento',
        DOCUMENT_VALIDATION_MESSAGE: 'Procedere con la validazione del documento?',
        DOWNLOAD: 'DOWNLOAD',
        INPUT_DOCUMENTS: 'Documenti Input',
        LOAD: 'CARICA',
        NO_FILES: 'Nessun file',
        NOTE: 'Note',
        OUTPUT_DOCUMENTS: 'Documenti Output',
        SAVE: 'SALVA',
        STATE: 'Stato',
        UPDATE_FILE: 'Modifica file',
        UPLOAD: 'UPLOAD',
        UPLOAD_NEW_DOCUMENT: 'Upload nuovo documento',
        VALIDATION: 'Validazione',
        VALIDATION_DOCUMENT: 'Validazione documento',
        FILE: 'File',
        DELETE_DOC: 'Cancella documento',
        CANCEL: 'Cancella',
        CONFIRM: 'Conferma',
        CODE: 'Codice',
        NODE_LIST: 'Lista Nodi'
    }
};

<div class="modal-header">
  <h4 class="modal-title" translate>{{title}}</h4>
</div>
<div class="modal-body" translate>{{message}}</div>
<div class="modal-footer">
  <div class="app-modal-footer">
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button (click)="onClose()" class="btn btn-secondary" data-qa="close-button-action" translate>
          lic_close
        </button>
      </div>
    </div>
  </div>
</div>

<pnc-modal-basic dialogClass="custom-modal-small">
  <div class="app-modal-header">
    <div class="modal-title" translate>
      lpc_quotation_premium_details
    </div>
  </div>
  <div class="app-modal-body">
    <div [formGroup]="formGroup">
      <div formArrayName="riskFormulasObject">
        <div *ngFor="let ins of riskFormulasObject; let j = index" class="tbld_col_title_premi formule-unit-class">
          <div [formGroupName]="j" [hidden]="!ins.selected">
            <div class="row header-unit-modal">
              <b>{{ins.description}}</b>
            </div>
            <div formArrayName="formulas">
              <div *ngFor="let formula of ins.formulas; let i = index;" class="row row-formule-modal">
                <div [formGroupName]="i">
                  <div class="col-lg-10">
                    <b style="font-size: 15px" [attr.data-qa]="'formula-name'">{{formula.description}}</b>
                  </div>
                  <div class="col-lg-2">
                    <input lpcCustomNumberFormatDirective
                      formControlName="value"
                      [readonly]="!formula.modifiable"
                      class="form-control input-modal-formula"
                      [value]="formula.value"
                      [attr.data-qa]="'forumla-'+formula.description+'-value'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="app-modal-footer">
    <div class="btn-group btn-group-justified"
      style="margin-top: 0px !important; border: 0px !important; border-right: 0px !important;">
      <div class="btn-group">
        <button class="btn btn-warning btn-secondary text-uppercase" (click)="closeFormuleModal()" translate>lpc_close_button</button>
      </div>
      <div class="btn-group">
        <button class="btn btn-warning text-uppercase" (click)="saveFormulas()"  translate>lpc_quote_and_save</button>
      </div>
    </div>
  </div>
</pnc-modal-basic>

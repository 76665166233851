<div class="anag-w-100 anag-section-card">
  <div class="anag-section-card-header">
    <span class="anag-section-card-icon rgi-ui-icon-link"></span>
    <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._LINKED_PARTIES_' | translate}}</span>
  </div>
  <div class="anag-section-content">
    <div class="rgi-ui-text-4"><b translate>_ANAG_._LABEL_._PARTIES_</b></div>

    <div class="rgi-ui-snackbar rgi-ui-default" *ngIf="!party.links || party.links.length === 0">
      <span class="rgi-ui-message" data-qa="noLinkedPartiesMsg" translate>_ANAG_._MSG_._NO_LINKED_PARTIES_</span>
    </div>
    <form [formGroup]="linksForm" class="rgi-ui-grid-2">
      <div *ngFor="let link of party.links; let i = index" class="rgi-anag-flex">
        <div class="rgi-ui-col link-width" [class.link-margin-top]="!edit">
          <label class="rgi-ui-form-control-label" *ngIf="edit">&nbsp;</label>
          <rgi-rx-drop-container viewField="linkSubjectNominative" [disabled]="!edit" data-qa="anag-link-party-drop"
                                 formControlName="{{link.linkedPartyObjectId}}-drop"
                                 (onValueChange)="onDropSubjectSelection($event)">
            <rgi-rx-drag-remove (onRemove)="onLinkRemove(link)"></rgi-rx-drag-remove>
          </rgi-rx-drop-container>
        </div>
        <div class="rgi-ui-col">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>_ANAG_._LABEL_._LINK_TYPE_</label>
            <select rgiRxNativeSelect class="rgi-ui-form-control" [attr.data-qa]="'anag-link-type_'+i"
                    (change)="onLinkChange($event, link)" formControlName="{{link.linkedPartyObjectId}}">
              <option selected></option>
              <option *ngFor="let type of getlinkTypes(link.linkedPartyObjectId)" [value]="type.code">
                {{type.description}}</option>
            </select>
          </rgi-rx-form-field>
        </div>
      </div>
    </form>

    <span>{{'_ANAG_._LABEL_._PARTY_' | translate}}</span>

    <rgi-rx-drop-container [ngModel]="tmpLinkModel" viewField="nominative" [select]="'searchSubject'"
                           [disabled]="!edit" data-qa="anag-link-party-drop" *ngIf="showFirstLink"
                           (onValueChange)="onDropSubjectSelection($event)"></rgi-rx-drop-container>

    <rgi-rx-drop-container [ngModel]="tmpLinkModel2" viewField="nominative" [select]="'searchSubject'"
                           [disabled]="!edit" data-qa="anag-link-party-drop" *ngIf="showSecondLink"
                           (onValueChange)="onDropSubjectSelection($event)"></rgi-rx-drop-container>

  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'licExtractFromArray'
})
export class LicExtractFromArrayPipe implements PipeTransform {

  transform(arr: any[], key: string, value: any): any {
    if (!Array.isArray(arr)) {
      return arr;
    }
    return arr.find(field => field && field[key] === value);
  }
}

import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { ApiPager } from '@rgi/digital-claims-common-angular';
import { ApiExpertFilter } from './api-expert-filter';

export class ApiInvoiceFilter {
    claimNumber: string; // NUMERO SINISTRO
    invoiceNumber: string; // NUMERO FATTURA
    invoiceDate: Date; // DATA FATTURA
    expert: ApiExpertFilter; // PROFESSIONISTA
    rejectType: ApiEnumType; // TIPO SCARTO
    sdiCode: string;
    sapCode: string;
    receivedDateFrom: Date; // DATA MINIMA RICEVIMENTO
    receivedDateTo: Date; // DATA MASSIMA RICEVIMENTO
    pager: ApiPager;
}

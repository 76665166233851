import {PncPsalesButton} from '../button';

export class RgiQuotationHeaderData {
  headerInstallments: InstallmentHeader[];
  menu: RgiQuotationMenu;
  buttons: PncPsalesButton[];
}

export class InstallmentHeader {
  amount: string;
  amountType: string; // gross, net..
  installmentType: string; // annual, first installment..
  boldSection: boolean; // set to true if you want the installment font bold
}

export class RgiQuotationMenu {
  label: string;
  items: RgiQuotationMenuItem[];
}

export class RgiQuotationMenuItem {
  label: string;
  actionCode: RGI_QUOTATION_MENU_ITEM_CODES;
  ctx?: any;
}

export enum RGI_QUOTATION_MENU_ITEM_CODES {
  TOTALS_PREMIUM = 'TOTALS_PREMIUM',
  ACCOUNTING_DETAILS = 'ACCOUNTING_DETAILS',
  COMMISSIONS = 'COMMISSIONS',
  POLICY_DETAILS = 'POLICY_DETAIL'
}

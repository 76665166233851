<div class="box-detail">
  <header class="box-detail-header" translate>_GP_._TITLE_._DATA_</header>
  <div class="box-detail-body">
    <table rgi-rx-table [dataSource]="contractDetails.variables">
      <ng-container rgiRxColumnDef="variable">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._POLICY_GENERAL_DATA_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.description}} </td>
      </ng-container>
      <ng-container rgiRxColumnDef="variableVal">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          {{row.value}}
        </td>
      </ng-container>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedVarColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedVarColumns;"></tr>
    </table>
  </div>
</div>

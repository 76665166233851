<div [formGroup]="formGroup">
  <div data-qa="claim-list" *ngIf="!showMessage; else noClaims">
      <div class="title-content-card" translate>lpc_search_result</div>
    <div class="col-sm-6">
      <span class="styled-select">
        <ng-select class="col-lg-12" id="custom" [clearable]="false" formControlName="count" (change)="handlePageSizeChange($event)">
        <ng-option *ngFor="let size of pageSizes" [value]="size">
            {{ size }}
        </ng-option>
      </ng-select>
      </span>
      <span translate>lpc_show_elements</span>
    </div>
    <div class="col-sm-6">
      <input placeholder="{{'Find'}}" formControlName="search" name="filter">
    </div>

    <div class="row" style="margin-top: 50px;">
      <div class="col-md-12">
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_insured</div>
            <div class="tbld_col tbld_col_title" translate>lpc_Tipo_sinistro</div>
            <div class="tbld_col tbld_col_title" translate>lpc_Data_sinistro</div>
            <div class="tbld_col tbld_col_title" translate>lpc_Data_Denuncia_Sinistro</div>
            <div class="tbld_col tbld_col_title" translate style="min-width: 200px">lpc_Data_Pervenimento_Denuncia</div>
            <div class="tbld_col tbld_col_title" translate style="min-width: 115px">lpc_notification_status</div>
            <div class="tbld_col tbld_col_title" translate>lpc_ACTIONS</div>
          </div>
          <ng-container *ngFor="let claim of availableClaims | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count }">
            <div class="tbld_row">
              <div class="tbld_col tbld_col_value">{{claim.insured}}</div>
              <div class="tbld_col tbld_col_value">{{claim.type}}</div>
              <div class="tbld_col tbld_col_value">{{formatDate(claim.claimDate)}}</div>
              <div class="tbld_col tbld_col_value">{{formatDate(claim.reportDate)}}</div>
              <div class="tbld_col tbld_col_value">{{formatDate(claim.notificationDate)}}</div>
              <div class="tbld_col tbld_col_value">{{claim.status}}</div>
              <div class="tbld_col tbld_col_value">
                <button class="btn dt-btn" (click)="openClaimDetailSession(claim)">
                  <span class="rgifont rgi-chevron-right"></span>
                </button>
              </div>
            </div>
          </ng-container>

        </div>

      </div>

    </div>
    <div class="right-align">
      <pagination-controls previousLabel=""
      nextLabel="" (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>
  </div>

  </div>

  <ng-template #noClaims>
    <p class="text-center" translate>lpc_noClaims</p>
  </ng-template>

  <div class="btn-group btn-group-justified">
    <div class="btn-group">
      <button type="button" (click)="back()" class="btn btn-warning btn-secondary" translate>lpc_BACK</button>
    </div>
    <div *ngIf="showNewClaim" class="btn-group">
      <button type="button" (click)="openNewClaimSession()" class="btn btn-warning" translate>lpc_nuova_denuncia</button>
    </div>
  </div>
</div>

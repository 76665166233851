import {SurveyEditComponent} from "./components/survey-edit/survey-edit.component";
import {SurveyEvaluateComponent} from "./components/survey-evaluate/survey-evaluate.component";
import {ApiPaths} from '@rgi/ng-passpropro-core';
import { SurveyEditStateManagerService } from './state/survey-edit-state-manager.service';
import { SurveyEditStateOperations } from './state/operations/survey-edit-state-operations.service';
import { SurveyEvaluateStateManagerService } from './state/survey-evaluate-state-manager.service';
import { SurveyEvaluateStateOperations } from './state/operations/survey-evaluate-state-operations.service';
import {Routes,ActiveRoute} from "@rgi/rx/router"
import {StateStoreService} from "@rgi/rx/state";

export const SURVEY_ROUTES: Routes = [
  {
    route: 'survey-edit', component: SurveyEditComponent,
    providers: [
      {
        provide: SurveyEditStateManagerService,
        useClass: SurveyEditStateManagerService,
        deps: [ActiveRoute, StateStoreService, SurveyEditStateOperations]
      }
    ]
  },
  {
    route: 'survey-evaluate', component: SurveyEvaluateComponent,
    providers: [
      {
        provide: SurveyEvaluateStateManagerService,
        useClass: SurveyEvaluateStateManagerService,
        deps: [ActiveRoute, StateStoreService, SurveyEvaluateStateOperations]
      }
    ]
  }
];

export const SURVEY_API_PATHS: ApiPaths = ['v2/survey', 'v2/questionnaire'];

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputPremium} from '../../../models/domain-models/inputPremium';
import {formatNumber} from '@angular/common';
import {UnitService} from '../re-issue-quotation-services/unit.service';

@Component({
  selector: 're-issue-input-premium',
  templateUrl: './re-issue-input-premium.component.html',
  styleUrls: ['./re-issue-input-premium.component.css']
})

export class InputPremiumComponent implements OnInit {
  @Input() inputPremiumTypeId: string;
  @Input() inputPremiumEnabled: boolean;
  @Input()  set inputPremium(value : number){
    this.input = value ? this.formatInput(value) : null;
  }
  @Output() updatePremium: EventEmitter<InputPremium> = new EventEmitter<InputPremium>();

  inputPremiumLabel: string;
  input:string;
  numberRegex : RegExp = /[+-]?([0-9]*[.])?[0-9]+/
  constructor(private unitService: UnitService) {}

  ngOnInit() {
    switch (this.inputPremiumTypeId) {
      case '1':
        this.inputPremiumLabel = 'RE_ISSUE.GROSS';
        break;
      case '2':
        this.inputPremiumLabel = 'RE_ISSUE.NET';
        break;
      case '3':
        this.inputPremiumLabel = 'RE_ISSUE.TAXABLE';
        break;
    }
  }

  onInputPremiumChange() {
    if((this.input && this.numberRegex.test(this.input)) || this.input === ''){
      const object = new InputPremium();
      this.input = this.formatInput(this.input);
      object.inputPremium = this.unitService.parseToNumberAndFormat(this.input);
      this.updatePremium.emit(object);
    }
  }
  formatInput(input:any){
    const separatore = ',';
    let result;
    result = this.unitService.parseToNumberAndFormat(input);
    result = result.split(separatore).join('');
    return formatNumber(result, 'it-IT', '1.2-2');
  }
}


<div class="containerParty" *ngFor="let subject of claimSubjects">

    <div>
        <span class="rgifont rgi-user btn-header-right iconUser"></span>
    </div>
    <div class="partyDescr col-sm-8 col-xs-7">
        <div>
            <span *ngIf="subject.subjectType == 1">{{subject.name}} {{subject.surName}}</span>
            <span *ngIf="subject.subjectType == 2">{{subject.businnessName}}</span>
        </div>
        <div class="partyRole"><span>{{ '_CLAIMS_._ROLE' | translate }} :</span> {{subject.roleDescr}}</div>
    </div>

    <button type="button" *ngIf="(subject.addresseeType != '1' || (subject.addresseeType == '1' && !blockAnagCard))" 
    class="btn dt-btn pull-right claims-margin-right-top" (click)="editSubject(subject)" 
    title="{{ '_CLAIMS_._BUTTONS_._EDIT' | translate }}" tooltip-append-to-body="true">
        <span class="rgifont rgi-pencil iconDT modal-icon"></span>
    </button>

</div>
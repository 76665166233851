import { LicCheckboxAccordionComponent } from './lic-checkbox-accordion.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { ReactiveFormsModule } from '@angular/forms';
import { RgiRxFormElementsModule, RgiRxExpansionModule } from '@rgi/rx/ui';

@NgModule({
    declarations: [LicCheckboxAccordionComponent],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        ReactiveFormsModule,
        RgiRxFormElementsModule,
        RgiRxExpansionModule
    ],
    exports: [LicCheckboxAccordionComponent]
})
export class LicCheckboxAccordionModule { }

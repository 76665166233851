import { EtypeDto } from "../etype-dto.model"

export class SearchPaymentsOutput {
    idTireaPayment: string = ''
    idRecordFlow: string = ''
    idFunStatus: string = ''
    codFunction: EtypeDto = new EtypeDto()
    lotto: string = ''
    sequence: string = ''
    registerType: string = ''
    codMessage: string = ''
    sendingFlowDate: string = ''
    year: string = ''
    month: string = ''
    operationCentre: string = ''
    companyCodeUs: EtypeDto = new EtypeDto()
    companyCodeCnt: EtypeDto = new EtypeDto()
    operationCentreCnt: string = ''
    internalKey: string = ''
    externalKey: string = ''
    lossDate: string = ''
    role: EtypeDto = new EtypeDto()
    state: EtypeDto = new EtypeDto()
    errorCode: string = ''
    error: string = ''
    idDialogue: string = ''
    internalKeyDlg: string = ''
    roleDlg: string = ''
    paveeDlg: string = ''
    claimNumberDlg: EtypeDto = new EtypeDto()
    lossDateDlg: string = ''
    companyCtrDlg: string = ''
    plateCtrDlg: string = ''
    idTypeOfLossDlg: string = ''
    idSettlement: string = ''
    settlementNumber: string = ''
    plateDlg: string = ''
    importForfait: string = ''
    paymentType: string = ''
    forced: boolean = false
    skipped: boolean = false
    dIns: string = ''
}
import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {LetterTemplateMainClass, LetterTemplatesService} from '../letter-templates.service';
import {RoutableComponent} from '@rgi/portal-ng-core';
import {LetterResponseEntity} from '../dto/letter-response.entity';
import {CardComponent, CardComponentService, SessionService} from '@rgi/digital-claims-common-angular';
import {LetterTemplateEntity} from '../dto/letter-template.entity';
import {flatMap} from 'rxjs/internal/operators';

@Component({
  selector: 'claims-letter-templates-card',
  templateUrl: './letter-templates-card.component.html',
  styleUrls: ['./letter-templates-card.component.scss']
})
export class LetterTemplatesCardComponent extends CardComponent implements OnInit, RoutableComponent {
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  errorMessage: string;

  constructor(
    private letterTemplatesService: LetterTemplatesService,
    @Inject('sessionService') private session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') private emitter: any,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
  ) {
    super(cardComponentService, sessionService);
  }

  ngOnInit() {
  }

  openNewModel() {
    this.startLoader();

    this.letterTemplatesService.getModelInit().subscribe(
      (response: LetterResponseEntity) => {
        const sessionId = this.session.open('claimsLettertemplatessinglepagecard', 'home', '', true);

        this.letterTemplatesService.setMainClass(LetterTemplateMainClass.NEW_LETTER_TEMPLATE);
        this.letterTemplatesService.setRawValues(response);
        this.emitter.broadcastEvent('stop-loader');
      }
    );
  }

  openLetterModelList() {
    this.startLoader();

    this.letterTemplatesService.getModelInit().pipe(flatMap((responseInit: LetterResponseEntity) => {
      this.letterTemplatesService.setRawValues(responseInit);

      return this.letterTemplatesService.getLetterTemplatesList();
    })).subscribe((response: LetterTemplateEntity[]) => {
      const sessionId = this.session.open('claimsLettertemplatessinglepagecard', 'home', '', true);

      this.letterTemplatesService.setMainClass(LetterTemplateMainClass.LETTER_TEMPLATE_LIST);
      this.letterTemplatesService.setLetterList(response);
      this.emitter.broadcastEvent('stop-loader');
    });
  }

  private startLoader() {
    this.errorMessage = null;
    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');
  }
}

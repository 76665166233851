export class DamageCategory {
    constructor(
        public idCategory: string,
        public cssClass: string,
        public description: string,
        public branchesCodes: string[],
        public ministerialBranchCodes: string[],
        public certifiedOccurrence: boolean
    ) { }
}

import {
  ChangeDetectionStrategy,
  Component, HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'input[pnc-input]',
  styleUrls: ['./input.component.scss'],
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class InputComponent {
  constructor() {
    console.warn('pnc-input is deprecated. use a plain input');
  }
  @HostBinding ('class.pnc-input') inputClass = 'true';
  @HostBinding ('class.input-error') inputError = 'hasErrors';
  @Input() hasErrors: boolean;
}

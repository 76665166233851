import {StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPsalesRequiredDataState} from '../../../resources/states/required-data-state';
import {PncPostSalesOrchestratorService} from '../../../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../../../services/pnc-postsales-integration.service';
import {RGI_FIELD_TYPE, RgiFormField} from '../../../resources/model/common/rgi-form-field';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {
  PncPostSalesCreateDraftResponse,
  PolicyOperationsResponse
} from '../../../resources/model/api/contract-operations';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPsalesHttpErrorService} from '../../../services/pnc-postsales-http-error.service';
import {CancellationFullProperties, CancelProperties} from '../../../resources/model/api/cancellation-flow';
import {
  premiumRepaymentFieldsMap,
  repaymentFieldsCodes,
  REQUIRED_DATA_FIELD_CODES,
  REQUIRED_DATA_LABELS,
  requiredDataFieldsCodes,
  requiredDataFieldsMap
} from '../../../resources/constants/required-data-fields-map';
import {
  CancellationField,
  cancellationFieldToRgiFormField,
  rgiFormFieldToCancellationField
} from '../../../resources/model/api/cancellation-fields';
import {PNC_PSALES_ACTIONS} from '../../../resources/constants/actions';
import {
  PncPsalesRequiredDataStateManager
} from '../../../state-managers/required-data/required-data-state-manager.service';
import {PncPsalesCancellationApiService} from '../../../resources/http/pnc-psales-cancellation-api.service';
import {PncPsalesForm} from '../../../resources/model/common/form';
import {LabeledEntity} from '../../../resources/model/common/entities';
import {SUMMARY_SECTION_DETAIL_TYPE} from '../../../resources/model/common/summary-step-detail';

export class PncPsalesRequiredDataStateManagerCancellation extends PncPsalesRequiredDataStateManager {

  public readonly requiredDataFgName = 'requiredData';
  public readonly premiumRepaymentFgName = 'premiumRepayment';
  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private resourceService: PncPsalesCancellationApiService,
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);

    this.registerOnFormFieldChange(
      this.requiredDataFgName,
      [context.operationParams.reasonFieldCode],
      (state, field, value) => this.updateCancellationReason$(of(state), field, value)
    );
    this.registerOnFormFieldChange(
      this.requiredDataFgName,
      requiredDataFieldsCodes,
      (state, field, value) => this.updateRequiredData$(of(state), field, value)
    );
    this.registerOnFormFieldChange(
      this.premiumRepaymentFgName,
      repaymentFieldsCodes,
      (state, field, value) => this.updateRepayment$(of(state), field, value)
    );
  }
  initState$(state: Observable<PncPsalesRequiredDataState>): Observable<PncPsalesRequiredDataState> {
    return state.pipe(
      concatMap((st: PncPsalesRequiredDataState) => {
        this.pushMessageHandler.clearTag(this.activeRoute.route);
        return this.resourceService.getPolicyOperations$(this.activeRoute.getRouteData<any>().contractId).pipe(
          map((resp: PolicyOperationsResponse) => {
            if (!!resp) {
              const operations = resp.operationsOutput.operations
                .filter(op => op.type === this.activeRoute.getRouteData<any>().operationType)
                .map((operation) => {
                  return operation.operation;
                });
              const cancellationReasonField: RgiFormField = {
                code: this.context.operationParams.reasonFieldCode,
                label: this.context.operationParams.reasonFieldLabel,
                type: RGI_FIELD_TYPE.LIST,
                mandatory: true,
                visible: true,
                editable: true,
                allowedValues: operations
              };
              if (st.currentOperation?.code) {
                cancellationReasonField.value = st.currentOperation.code;
              }
              st.forms[this.requiredDataFgName] = new PncPsalesForm(cancellationReasonField);
              st.operations = operations;
            }
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesRequiredDataState) => {
        if (st.currentOperation?.code) {
          return this.updateCancellationReason$(of(st), this.context.operationParams.reasonFieldCode, st.currentOperation.code);
        }
        return of(st);
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
  }

  onAction(action: string): Observable<PncPsalesRequiredDataState> | void {
    switch (action) {
      case PNC_PSALES_ACTIONS.BACK:
        this.actionBack();
        break;
      case PNC_PSALES_ACTIONS.CONTINUE:
        this.actionContinue();
        break;
    }
  }

  actionContinue() {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const st$ = of(this.getCurrentState()).pipe(
      concatMap((st) => {
        const cancellationReason = '' + this.getFormFields(this.requiredDataFgName, st).find(f => f.code === this.context.operationParams.reasonFieldCode).value;
        return this.resourceService.updateDraftData$(st.policyNumber, st.operationType, cancellationReason, st.draftId, st.cancelProperties)
          .pipe(
            map(() => {
              st.currentOperation = st.operations.find(o => o.code === cancellationReason);
              st.showQuotation = !!this.getFormFields(this.premiumRepaymentFgName, st) && !!this.getFormFields(this.premiumRepaymentFgName, st).find(f => !!f.value);
              this.setSummaryData(st);
              this.orchestrator.goToNextStep(st, this.activeRoute);
              return st;
            }),
            catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
          );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
    this.updateState$(st$);
  }

  protected updateCancellationReason$(st$: Observable<PncPsalesRequiredDataState>, fieldCode: string,
                                      cancellationReason: string): Observable<PncPsalesRequiredDataState> {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    return st$.pipe(
      concatMap((st) => {
        st.forms[this.requiredDataFgName].fields.find(f => f.code === fieldCode).value = cancellationReason;
        return this.resourceService.createDraft$(st.policyNumber, st.operationType, cancellationReason, st.draftId).pipe(
          map((resp: PncPostSalesCreateDraftResponse) => {
            st.draftId = resp.resourceId;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesRequiredDataState) => {
        return this.resourceService.getDraftData$(st.policyNumber, st.operationType, cancellationReason, st.draftId).pipe(
          map((resp: CancellationFullProperties) => {
            this.manageFieldsFromCancelProps(st, resp.cancelProperties);
            st.cancelProperties = resp.cancelProperties;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
  }

  protected updateRepayment$(st$: Observable<PncPsalesRequiredDataState>, fieldCode: string,
                             fieldNewValue: string): Observable<PncPsalesRequiredDataState> {
    return st$.pipe(
      map((st: PncPsalesRequiredDataState) => {
        const propCode = premiumRepaymentFieldsMap.get(fieldCode);
        if (propCode && st.cancelProperties.premiumReimbursement && st.cancelProperties.premiumReimbursement[propCode]) {
          const formModelField = this.getFormFields(this.premiumRepaymentFgName, st).find(f => f.code === fieldCode);
          formModelField.value = fieldNewValue;
          st.cancelProperties.premiumReimbursement[propCode] = rgiFormFieldToCancellationField(formModelField);
        }
        return st;
      })
    );
  }

  protected manageFieldsFromCancelProps(st: PncPsalesRequiredDataState, cancelProps: CancelProperties) {
    const newReqDataFields: RgiFormField[] = [];
    this.transformCancellationField(cancelProps.issueDate, newReqDataFields, REQUIRED_DATA_FIELD_CODES.ISSUE_DATE,
      REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.ISSUE_DATE), RGI_FIELD_TYPE.DATE);
    this.transformCancellationField(cancelProps.effectDate, newReqDataFields, REQUIRED_DATA_FIELD_CODES.EFFECT_DATE,
      REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.EFFECT_DATE), RGI_FIELD_TYPE.DATE, true);
      this.transformCancellationField(cancelProps.effectHour, newReqDataFields, REQUIRED_DATA_FIELD_CODES.EFFECT_HOUR,
      REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.EFFECT_HOUR), RGI_FIELD_TYPE.STRING, true);
    this.transformCancellationField(cancelProps.notificationDate, newReqDataFields, REQUIRED_DATA_FIELD_CODES.NOTIFICATION_DATE,
      this.context.operationParams.notificationDateFieldLabel, RGI_FIELD_TYPE.DATE);
    const reqDataForm = this.getFormByName(this.requiredDataFgName, st);
    this.cleanOldFieldsNotMandatory(reqDataForm.fields, newReqDataFields);
    this.overwriteNewFields(reqDataForm.fields, newReqDataFields);
    if (cancelProps.premiumReimbursement) {
      const premiumRepaymentFields: RgiFormField[] = [];
      this.transformCancellationField(cancelProps.premiumReimbursement.rights, premiumRepaymentFields, REQUIRED_DATA_FIELD_CODES.RIGHTS,
        REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.RIGHTS), RGI_FIELD_TYPE.NUMERIC);
      this.transformCancellationField(cancelProps.premiumReimbursement.rcaNetPremium, premiumRepaymentFields,
        REQUIRED_DATA_FIELD_CODES.RCA_NET, REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.RCA_NET), RGI_FIELD_TYPE.CHECKBOX);
      this.transformCancellationField(cancelProps.premiumReimbursement.taxablePremium, premiumRepaymentFields,
        REQUIRED_DATA_FIELD_CODES.TAXABLE, REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.TAXABLE), RGI_FIELD_TYPE.CHECKBOX);
      this.transformCancellationField(cancelProps.premiumReimbursement.ssn, premiumRepaymentFields, REQUIRED_DATA_FIELD_CODES.SSN,
        REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.SSN), RGI_FIELD_TYPE.CHECKBOX);
      this.transformCancellationField(cancelProps.premiumReimbursement.tax, premiumRepaymentFields, REQUIRED_DATA_FIELD_CODES.TAX,
        REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.TAX), RGI_FIELD_TYPE.CHECKBOX);
      if (premiumRepaymentFields && premiumRepaymentFields.length) {
        st.forms[this.premiumRepaymentFgName] = new PncPsalesForm(premiumRepaymentFields, '_PCPSALES_._TITLE_._PREMIUM_REPAYMENT_');
      } else {
        delete st.forms[this.premiumRepaymentFgName];
      }
    }
  }

  protected transformCancellationField(field: CancellationField, rgiFields: RgiFormField[], code: string,
                                       label: string, type: RGI_FIELD_TYPE, mandatory?: boolean) {
    if (field && field.visible) {
      rgiFields.push(cancellationFieldToRgiFormField(field, code, label, type, mandatory));
    }
  }

  protected cleanOldFieldsNotMandatory(oldFields: RgiFormField[], newFields: RgiFormField[]) {
    oldFields.forEach(oldField => {
      const newField = newFields.find(f => f.code === oldField.code);
      if (!newField && !oldField.mandatory) {
        oldFields.splice(oldFields.indexOf(oldField), 1);
      }
    });
  }

  protected overwriteNewFields(oldFields: RgiFormField[], newFields: RgiFormField[]) {
    newFields.forEach(newField => {
      const oldField = oldFields.find(f => f.code === newField.code);
      if (!!oldField) {
        oldFields.splice(oldFields.indexOf(oldField), 1, newField);
      } else {
        oldFields.push(newField);
      }
    });
  }

  updateRequiredData$(st$: Observable<PncPsalesRequiredDataState>, fieldCode: string, fieldNewValue: any): Observable<PncPsalesRequiredDataState> {
    return st$.pipe(
      map((st: PncPsalesRequiredDataState) => {
        const propCode = requiredDataFieldsMap.get(fieldCode);
        if (propCode && st.cancelProperties[propCode]) {
          const formModelField = this.getFormByName(this.requiredDataFgName, st).fields.find((f: RgiFormField) => f.code === fieldCode);
          formModelField.value = fieldNewValue;
          st.cancelProperties[propCode] = rgiFormFieldToCancellationField(formModelField);
        }
        return st;
      })
    );
  }

  setSummaryData(st: PncPsalesRequiredDataState) {
    st.summaryStepDetail = {
      stepLabel: '_PCPSALES_._STEPS_._CANCELLATION_DATA_',
      sections: [
        {
          detail: {
            type: SUMMARY_SECTION_DETAIL_TYPE.KEYVALUES,
            data: [
              new LabeledEntity(this.context.operationParams.reasonFieldLabel, st.currentOperation.description),
              new LabeledEntity(REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.ISSUE_DATE), st.cancelProperties.issueDate.value),
              new LabeledEntity(REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.EFFECT_DATE), st.cancelProperties.effectDate.value),
              new LabeledEntity(REQUIRED_DATA_LABELS.get(REQUIRED_DATA_FIELD_CODES.EFFECT_HOUR), st.cancelProperties.effectHour.value),
            ]
          }
        }
      ]
    };
  }
}

import { JS_EVENT, JS_ROUTES } from '../../models/consts/lpc-consts';

// @ts-ignore
// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

export function AngularCommunicationCtrlPostsales(
      $log, $scope, $controller, eventService,
      sessionService, CONTAINER_EVENT, gettext, $uibModal, pRouter, coreResult,
      ANAG_CARD_ZOOM, cardPassService, anagManageSubjectValidationService, ANAG_CARD_MANAGE, CORE_EVENT, PASSPRO_PRO_CARDS, SubjectSurveyUtil, containerCacheService
) {
        if (!$scope.data) {
          $scope.data = {};
        }

        $scope.navi = null;
        $scope.selectedSubject = {};

        // Data from AngularJS should be stored in the coreResult service and read here.
        // In this way you can have access at those data in the $scope.data field and send them in Angular using downgraded card template.
        if (coreResult) {
          $scope.data = {
            anag: coreResult.getResult($scope.id, 'anag'),
            newFlow: coreResult.getResult($scope.id, 'newFlow'),
            subject: coreResult.getResult($scope.id, 'subject'),
            forwardData: coreResult.getResult($scope.id, 'forwardData'),
            product: coreResult.getResult($scope.id, 'product'),
            productEvaluation: coreResult.getResult($scope.id, 'productEvaluation'),
            lifeContract: coreResult.getResult($scope.id, JS_ROUTES.LIFE_POLICY_DETAIL),
            finantialFunds: coreResult.getResult($scope.id, JS_EVENT.OPEN_FUNDS_DETAIL),
            pipDataDetail: coreResult.getResult($scope.id, JS_EVENT.OPEN_PIP_DATA),
            movementDetail: coreResult.getResult($scope.id, JS_EVENT.OPEN_MOVEMENT_DETAIL),
            fiscalDataDetail: coreResult.getResult($scope.id, JS_EVENT.OPEN_FISCALDATA_DETAIL),
            reinvestDataDetail: coreResult.getResult($scope.id, JS_EVENT.OPEN_REINVESTDATA_DETAIL),
            loanDataDetail: coreResult.getResult($scope.id, JS_EVENT.OPEN_LOANDATA_DETAIL),
            expiryOptionDetail: coreResult.getResult($scope.id, JS_EVENT.OPEN_EXPIRYOPTION_DETAIL),
            claimId: coreResult.getResult($scope.id, JS_EVENT.OPEN_CLAIM_DETAIL),
            isFromPreventive: coreResult.getResult($scope.id, 'isFromPreventive'),
            claimDetail: coreResult.getResult($scope.id, JS_EVENT.UPDATE_CLAIM_DETAIL),
            optionDetail: coreResult.getResult($scope.id, JS_EVENT.OPEN_CONTRACTUAL_OPTION_DETAIL),
            investmentPlan: coreResult.getResult($scope.id, JS_EVENT.OPEN_INVESTMENT_PLAN_DETAIL),
            info: coreResult.getResult($scope.id, JS_EVENT.OPEN_FINANCIAL_LIST),
            idCrypt: coreResult.getResult($scope.id, JS_EVENT.OPEN_PROFILE_DETAIL),
            profileVersion: coreResult.getResult($scope.id, JS_EVENT.OPEN_PROFILE_ALLOC_SESSION),
            isFromConsultation: coreResult.getResult($scope.id, 'isFromConsultation'),
            proposalFromDetail: coreResult.getResult($scope.id, 'proposalFromDetail'),
            authorizationId : coreResult.getResult($scope.id, 'authorizationId'),
            contractId : coreResult.getResult($scope.id, 'contractId'),
            causeCode : coreResult.getResult($scope.id, 'causeCode'),
            operationDescription : coreResult.getResult($scope.id, 'operationDescription'),
            quoteFromDetail: coreResult.getResult($scope.id, 'quoteFromDetail'),
            bookingDetailData: coreResult.getResult($scope.id, JS_EVENT.OPEN_BOOKING_DETAIL),
            premiumDetailData: coreResult.getResult($scope.id, JS_EVENT.OPEN_PREMIUM_DETAIL),
            isFromQuoteModification: coreResult.getResult($scope.id, 'isFromQuoteModification'),
            isAnonymous: coreResult.getResult($scope.id, 'isAnonymous'),
            sessionId: $scope.id
          };
        }

        $log.debug('Create angular event communication controller');

        const unlistenEvent = eventService.listenGlobalEvent('angular-card-event', (event, evtDetails) => {
          if (evtDetails.cardId === $scope.card.id) {
            if (evtDetails.subEvent === 'start-progress-bar') {
              $scope.startProgressBar();
              return;
            }
            if (evtDetails.subEvent === 'stop-progress-bar') {
              $scope.completeProgressBar();
              return;
            }
          }
        });
        $scope.$on('$destroy', unlistenEvent);

        $scope.wrapEvent = (event) => {
          if (event === JS_EVENT.CLOSE_ALL_SESSIONS) {
            $scope.removeSession($scope.card.idSession);
          } else if (event === 'layoutChanged') {
            // Update the grid of the card
            $scope.updateGrid();
          } else if (event.eventName && event.eventName === 'leaveMainPage') {
            sessionService.open(event.sessionParent, event.sessionRoute, event.sessionTitle, event.navigationDisabled);
          } else if (event.eventName && event.eventName === JS_EVENT.ANAG_MANAGE.SEARCH.eventName) {
            openAnagFinderInPopup();
          } else if (event.eventName && event.eventName === 'leaveMainPage') {
            if (event.navi) {
              $scope.navi = event.navi;
            }
          } else if (event.eventName && event.eventName === JS_EVENT.JUMP_PASS_DETAIL) {
            const paramsIntegrationPass = {RGIRedirectOk: '/lifeJumpAgreementDetail.navi', NAVI_PAR_POLICYID: event.idPolicy};
            const objNavigation = {newSession: true};
            cardPassService.createJump(
              $scope.card,
              {
                paramsIntegrationPass,
                objNavigation
              },
              'Consultazione',
              'pass',
              $scope.card.name
            );
          } else if (event === JS_EVENT.LOADER.START) {
            eventService.broadcastEvent(CONTAINER_EVENT.START_LOADER_EVENT);
          } else if (event === JS_EVENT.LOADER.STOP) {
            eventService.broadcastEvent(CONTAINER_EVENT.STOP_LOADER_EVENT);
          } else if (event.eventName && event.eventName === JS_EVENT.SET_TITLE) {
            $scope.card.title = gettext(event.title);
          } else if (event.eventName === JS_EVENT.BACK_TO_CONTRACT_LIST) {
            pRouter.go($scope.id, 'result');
            sessionService.remove(event.id);
          } else if (event === JS_EVENT.CLOSE_LIFE_DETAIL) {
            $scope.removeSession($scope.card.idSession);
          } else if (event === JS_EVENT.BACK_TO_CLAIM_LIST) {
            pRouter.go($scope.id, 'home');
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_FUNDS_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_FUNDS_DETAIL, event.compose);
            pRouter.go($scope.id, JS_ROUTES.LIFE_FUNDS_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_PIP_DATA) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_PIP_DATA , event.data);
            pRouter.go($scope.id, JS_ROUTES.LIFE_PIPDATA_DETAIL);
          } else if (event === JS_EVENT.BACK_TO_LIFE_DETAIL) {
            pRouter.go($scope.id, JS_ROUTES.LIFE_POLICY_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.UPDATE_CLAIM_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.UPDATE_CLAIM_DETAIL, event.claimDetail);
            pRouter.go($scope.id, JS_ROUTES.LIFE_NEW_CLAIM);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_MOVEMENT_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_MOVEMENT_DETAIL, event.movementDetail);
            pRouter.go($scope.id, JS_ROUTES.LIFE_MOVEMENT_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_FISCALDATA_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_FISCALDATA_DETAIL, event.fiscalDataDetail);
            pRouter.go($scope.id, JS_ROUTES.LIFE_FISCALDATA_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_REINVESTDATA_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_REINVESTDATA_DETAIL, event.reinvestDataDetail);
            pRouter.go($scope.id, JS_ROUTES.LIFE_REINVESTDATA_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_LOANDATA_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_LOANDATA_DETAIL, event.loanDataDetail);
            pRouter.go($scope.id, JS_ROUTES.LIFE_LOANDATA_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_EXPIRYOPTION_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_EXPIRYOPTION_DETAIL, event.expiryOptionDetail);
            pRouter.go($scope.id, JS_ROUTES.LIFE_EXPOPTION_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_CLAIM_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_CLAIM_DETAIL, event.claimId);
            pRouter.go($scope.id, JS_ROUTES.LIFE_CLAIMS_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_CONTRACTUAL_OPTION_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_CONTRACTUAL_OPTION_DETAIL, event.option);
            pRouter.go($scope.id, JS_ROUTES.LIFE_OPTION_DETAIL);
          } else if (event.eventName && event.eventName === 'backToMainPage') {
            console.log('backToMainPage event received');
            const sessionStore = sessionService.list();
            sessionStore.forEach(session => {
              console.log('Removing session: ' + session.idSession);
              sessionService.remove(session.idSession);
            });
          } else if (event.eventName && event.eventName === 'removeCurrentSession') {
            sessionService.remove($scope.card.idSession);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_TREND_LIST) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_TREND_LIST, event.policyId);
            pRouter.go($scope.id, JS_ROUTES.LIFE_TREND_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.BACK_TO_FINANCIAL_LIST) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_FINANCIAL_LIST, event.info);
            pRouter.go($scope.id, 'home');
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_FINANCIAL_LIST) {
            const id = sessionService.open('fundListSession', 'home', '', true);
            coreResult.setResult(id, JS_EVENT.OPEN_FINANCIAL_LIST, event.info);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_PROFILE_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_PROFILE_DETAIL, event.idCrypt);
            pRouter.go($scope.id, JS_ROUTES.LIFE_PROFILES_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_PROFILE_ALLOC_SESSION) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_PROFILE_ALLOC_SESSION, event.data);
            pRouter.go($scope.id, JS_ROUTES.LIFE_PROFILES_ALLOCATION);
          } else if (event.eventName && event.eventName === JS_EVENT.ANAG_MANAGE.OPEN_DETAIL) {
            const subjIdSession =  sessionService.open(ANAG_CARD_ZOOM.CARD_NAME, 'home', '', true, null, null, $scope.card.idSession, null);
            coreResult.setResult(subjIdSession, 'detail-life-subj', event.subject);
            coreResult.setResult(subjIdSession, 'detail-life-idSession', $scope.card.idSession);
          } else if (event.eventName && event.eventName === JS_EVENT.ANAG_MANAGE.OPEN_MODIFY) {
            editSubject(event.subject, event.role);
          } else if (event.eventName && event.eventName === 'setSubjectOnCoreResult') {
            coreResult.setResult(event.id, 'subject', event.subject);
            coreResult.setResult(event.id, 'product', {code: event.product});
            coreResult.setResult(event.id, 'isFromPreventive', true);
            coreResult.setResult(event.id, 'anag', event.anag);
            coreResult.setResult(event.id, 'lifeProposalSettings', event.data);
          } else if (event.eventName && JS_EVENT.NAV_LIFE_MODIFY_INDEF_PROP === event.eventName) {
            const idSession = $scope.card.idSession;
            const cardId = sessionService.open(event.parent, event.route, event.title, true, false, false, idSession);
            coreResult.setResult(cardId, 'isFromProposalModification', true);
            coreResult.setResult(cardId, 'proposalFromDetail', event.proposal);
          } else if (event.eventName === JS_EVENT.OPEN_BOOKING_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_BOOKING_DETAIL, event.data);
            pRouter.go($scope.id, JS_ROUTES.LIFE_BOOKING_DETAIL);
          } else if (event.eventName && JS_EVENT.NAV_LIFE_MODIFY_QUOTE === event.eventName) {
            const idSessionQuoteModify = $scope.card.idSession;
            const cardIdQuoteModify = sessionService.open(event.parent, event.route, event.title, true, false, false, idSessionQuoteModify);
            coreResult.setResult(cardIdQuoteModify, 'isFromQuoteModification', true);
            coreResult.setResult(cardIdQuoteModify, 'quoteFromDetail', event.proposal);
            coreResult.setResult(cardIdQuoteModify, 'isFromPreventive', event.isFromPreventive);
            coreResult.setResult(cardIdQuoteModify, 'isAnonymous', event.isAnonymous);
          } else if (event.eventName && JS_EVENT.NAV_LIFE_BUY_QUOTE === event.eventName) {
            const idSessionQuoteBuy = $scope.card.idSession;
            const cardIdQuoteBuy = sessionService.open(event.parent, event.route, event.title, true, false, false, idSessionQuoteBuy);
            coreResult.setResult(cardIdQuoteBuy, 'isFromBuyQuote', true);
            coreResult.setResult(cardIdQuoteBuy, 'quoteFromDetail', event.proposal);
          } else if (event.eventName && JS_EVENT.OPEN_PREMIUM_DETAIL === event.eventName) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_PREMIUM_DETAIL, event.data);
            pRouter.go($scope.id, JS_ROUTES.LIFE_PREMIUM_DETAIL);
          } else if (event.eventName && event.eventName === JS_EVENT.NAV_LIFE_ISSUE_PAGE) {
            const idSessionLifeResult = $scope.card.idSession;
            const cardIdLifeResult = sessionService.open(event.parent, event.route, event.title, true, false, false, idSessionLifeResult);
            coreResult.setResult(cardIdLifeResult, 'isSaveFromAuth', true);
            coreResult.setResult(cardIdLifeResult, 'isFromConsultation', event.origin === 'consultation' ? true : false);
            coreResult.setResult(cardIdLifeResult, 'proposalFromAuth', event.proposal);
          } else if (event.eventName && event.eventName === JS_EVENT.OPEN_INVESTMENT_PLAN_DETAIL) {
            coreResult.setResult($scope.id, JS_EVENT.OPEN_INVESTMENT_PLAN_DETAIL, event.investmentPlan);
            pRouter.go($scope.id, JS_ROUTES.LIFE_INVESTMENT_PLAN_DETAIL);
          } else if (event.eventName === JS_EVENT.PASSPRO_PRO_CARDS) {
            const detailSessionId = $scope.card.idSession;
            coreResult.setResult(detailSessionId, 'isFromBuyQuote', true);
            coreResult.setResult(detailSessionId, 'quoteFromDetail', event.proposal);
            containerCacheService.getCache().set(detailSessionId, {data: event.holder, type: 'subject'});
            eventService.broadcastEvent(CONTAINER_EVENT.START_LOADER_EVENT);
            SubjectSurveyUtil.findSurveyListByCardScope($scope).then(function(resultList){
              const surveyID = sessionService.open(PASSPRO_PRO_CARDS.SURVEY.name, 'result', PASSPRO_PRO_CARDS.SURVEY.title, false, null, null, detailSessionId, null);
              coreResult.setResult(surveyID, 'result', resultList);
              coreResult.setResult(surveyID, 'createNewSurveyVisible', true);
              eventService.broadcastEvent(CONTAINER_EVENT.STOP_LOADER_EVENT);
            }).catch(function(error){
              eventService.broadcastEvent(CONTAINER_EVENT.STOP_LOADER_EVENT);
            });
          }
        };

        $scope.selectedSubject = {};

        $scope.onDropContainerComplete = (received) => {
          $scope.selectedSubject.received = received;
        };

        function editSubject(subject, role) {
          if (subject == null) {
              subject = $scope.subject;
          }
          let subjectRole = null;
          if ($scope.card.popupCardFilter) {
              subjectRole = $scope.card.popupCardFilter.role;
          }
          if (subject.limitation === false) {
            const _constraints = {
                node: !$scope.enableSalePoint
            };
            const modalInstance = $uibModal.open({
                templateUrl: 'anag/cardmanage/partials/modal-add-subject-enter.tpl.html',
                controller: 'AnagCardManageController_party',
                size: 'lg',
                resolve: {
                    subject: () => {
                        return subject;
                    },
                    configuration: () => {
                        const filters = [
                            { type: 'subject', data: subject }
                        ];
                        if ($scope.card.popupCardFilter) {
                            filters.push({ type: 'popupCardFilter', data: $scope.card.popupCardFilter });
                        }
                        return anagManageSubjectValidationService.createConfigurationObject(filters);
                    },
                    constraints: () => {
                        return _constraints;
                    },
                    subjectRole: () => {
                        return subjectRole;
                    },
                    category: () => {
                        return null;
                    },
                    dynamicObject: () => {
                        return null;
                    },
                    dynamicObjectInstances: () => {
                        return null;
                    },
                    callInternationalPrefixes: () => {
                        return false;
                    },
                    parentSession: () => {
                      if ($scope.card.idSession) {
                        return $scope.card.idSession;
                      }
                      return null;
                    }
                }
            });

            modalInstance.result.then((res) => {
                $scope.selectedSubject.openModifySession = {opened: true, subject: res.subject, role};
                const idNavigation = sessionService.open(
                  ANAG_CARD_MANAGE.CARD_NAME,
                  'home',
                  '',
                  true,
                  null,
                  null,
                  $scope.card.idSession,
                  $scope.card.id
                );
                coreResult.setResult(idNavigation, 'newSubj', {
                    subject: res.subject,
                    configuration: res.configuration,
                    constraints: _constraints
                });
            });
            modalInstance.closed.then((res) => {
              $scope.selectedSubject.openModifySession = {opened: false, subject: res.subject , role };
            });
          } else if (subject.limitation === true) {
              eventService.broadcastEvent(CORE_EVENT.ALERT_EVENT, {
                  applicationArea: 'anag/finder',
                  strId: 'anag_modifySubject_limited'
              });
          }
        }

        function openAnagFinderInPopup() {
          $uibModal.open({
            animation: true,
            templateUrl: 'coreportal/draggable/partials/modal-card.tpl.html',
            windowTemplateUrl: 'coreportal/draggable/partials/modal-container.tpl.html',
            controller: 'DraggableModalCardCtrl',
            size: 'drag-popup-card',
            backdropClass: 'drag-popup-backdrop',
            scope: $scope,
            resolve: {
              popupCardName: () => {
                return 'anagFinder';
              },
              modalDrag: () => {
                return false;
              },
              popupCardFilter: () => {
                return {};
              }
            },
            parentSession: () => {
              if ($scope.card.idSession) {
                return $scope.card.idSession;
              }
              return null;
            }
          });
        }
}

AngularCommunicationCtrlPostsales.$inject = ['$log', '$scope', '$controller', 'eventService',
    'sessionService', 'CONTAINER_EVENT', 'gettext', '$uibModal', 'parallelRouting', 'coreResult',
    'ANAG_CARD_ZOOM', 'cardPassService', 'anagManageSubjectValidationService', 'ANAG_CARD_MANAGE', 'CORE_EVENT', 'PASSPRO_PRO_CARDS', 'SubjectSurveyUtil', 'containerCacheService'
];

export function AngularCommunicationPostsales() {
  // 'use strict';
  angular.module('angular-comm-card-controllers')
  /* .config(['eventServiceProvider', 'parallelRoutingProvider', (evProvider, pRouter) => {
    evProvider.registerEvent('angular-card-event', {type: 'card-event'});
    pRouter.when('ptfallFinder', 'pass', {
      template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
      controller: 'CardPassCtrl',
      label: 'pass'
    });
  }]) */
  .controller('AngularCommunicationCtrlPostsales', AngularCommunicationCtrlPostsales);
}

import {InjectionToken, TemplateRef} from '@angular/core';


export type RgiRxTooltipTrigger = 'click' | 'mouseenter';
export type RgiRxTooltipPosition = 'top' | 'bottom' | 'right' | 'left';

export abstract class RgiRxToolTip {
  content: string | TemplateRef<any>;
  abstract show(): void;
  abstract hide(): void;
  abstract get id(): string;
}

/**
 * Type to define
 */
export interface RgiRxToolTipConfig {
  position?: RgiRxTooltipPosition;
  panelClass?: string | string[];
  dismissDelay?: number;
  triggerDebounce?: number;
  trigger?: RgiRxTooltipTrigger;
}

export const RGI_RX_TOOLTIP_DEFAULT_CONFIG: RgiRxToolTipConfig = {
  position: 'top',
  dismissDelay: 0,
  trigger: 'mouseenter',
  panelClass: '',
  triggerDebounce: 0
};

export const RGI_RX_TOOLTIP_CONFIG = new InjectionToken<RgiRxToolTipConfig>('RGI_RX_TOOLTIP_CONFIG');

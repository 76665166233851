import {Inject, Injectable} from '@angular/core';
import {RestApiConf} from '../../../conf/rest-api-conf';
import {RgiRxHttpClient} from '@rgi/rx/http';
import {Observable} from 'rxjs';
import {ApiDocument} from '../resources/model/api/document';
import {OutboundDocument} from '../resources/model/common/outbound-document';
import {
  ARCHIVE_DOC_ACTION,
  DOWNLOAD_DOC_ACTION,
  OUTBOUND_DOCS_TABLESCHEMA,
  SEND_DOC_ACTION,
  SIGN_DOC_ACTION
} from '../resources/constants/outbound-documents-table-schema';
import {RGI_DOCUMENT_ALLOWED_ACTIONS, RGI_DOCUMENTS_ACTIONS} from '../resources/constants/actions';
import {DocumentsService} from './documents.service';
import {map} from 'rxjs/operators';
import {RGI_DOCUMENT_STATUS} from '../resources/constants/status';
import {cloneDeep} from 'lodash';
import {LabeledEntity} from '../../generic-detail/resources/models/entities';
import {DocumentType} from '../resources/constants/type';
import {GenericEntity} from '../../../resources/models/generic-entity';
import {DocumentFile} from '../resources/model/api/document-file';

@Injectable({
  providedIn: 'root'
})
export class OutboundDocumentsService {

  protected hostPath: string;
  protected API_PREFIX_DOCUMENTS = 'documents';

  constructor(
    @Inject('environment') protected apiConf: RestApiConf,
    public httpClient: RgiRxHttpClient,
    public documentsService: DocumentsService,
  ) {
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path + '/v2';
  }

  getOutboundDocuments$(proposalNumber: string, movementId: string): Observable<ApiDocument[]> {
    return this.httpClient.get<ApiDocument[]>(`${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${proposalNumber}/movements/${movementId}/documents`);
  }


  getOutboundDocumentsSchema(documents: OutboundDocument[]) {
    const schema = cloneDeep(OUTBOUND_DOCS_TABLESCHEMA);
    if (documents.some(doc => doc.isDownloadable)) {
      schema.rows.push(DOWNLOAD_DOC_ACTION);
      schema.header.push('download');
    }
    if (documents.some(doc => doc.isArchivable)) {
      schema.rows.push(ARCHIVE_DOC_ACTION);
      schema.header.push('archive');
    }
    if (documents.some(doc => doc.isSignable)) {
      schema.rows.push(SIGN_DOC_ACTION);
      schema.header.push('sign');
    }
    if (documents.some(doc => doc.isSendable)) {
      schema.rows.push(SEND_DOC_ACTION);
      schema.header.push('send');
    }
    return schema;
  }

  getOutboundDocumentsDataSource$(proposalNumber: string, movementId: string): Observable<OutboundDocument[]> {

    return this.getOutboundDocuments$(proposalNumber, movementId).pipe(
      map((documents: ApiDocument[]) => {
        //this.mockDocuments(documents);
        return documents.map((doc: ApiDocument) => {
          const signData: { signIcon: string; signTooltipDescr: string } = this.getSignData(doc);
          return {
            id: doc.id,
            description: doc.description,
            isDownloadable: !!doc.allowedActions.find(action => action === RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_DOWNLOAD),
            isSignable: !!doc.allowedActions.find(action => action === RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_SIGN_START || action === RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_SIGN_STOP),
            isArchivable: !!doc.allowedActions.find(action => action === RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_ARCHIVE),
            isSendable: !!doc.allowedActions.find(action => action === RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_DELIVERY),
            statusCode: doc.state.code,
            statusColor: this.getBadgeColor(doc.state.code),
            statusDescription: this.getDocStatusDescription(doc),
            typeCode: doc.type.code,
            typeDescription: doc.type.description,
            details: this.getDocumentDetails(doc),
            file: doc.files[0],
            signIcon: signData.signIcon,
            signTooltipDescr: signData.signTooltipDescr,
          };
        });
      })
    );
  }

  onDocumentAction$(action: string, proposalNumber: string, movementId: string, document: OutboundDocument): Observable<void> {
    const path = `${this.hostPath}/${this.API_PREFIX_DOCUMENTS}/contract/${proposalNumber}/movements/${movementId}/documents/${document.id}`;
    switch (action) {
      case RGI_DOCUMENTS_ACTIONS.DOWNLOAD:
        return this.documentsService.downloadDocument$(document.file.idDocDMS, document.description, document.file);
      case RGI_DOCUMENTS_ACTIONS.ARCHIVE:
        return this.httpClient.post<void>(`${path}/archive`, {});
      case RGI_DOCUMENTS_ACTIONS.SIGN:
        return this.httpClient.put<void>(`${path}/file/${document.file.idDocDMS}/digitalSignature`, {});
      case RGI_DOCUMENTS_ACTIONS.SEND:
        return this.httpClient.put<void>(`${path}/file/${document.file.idDocDMS}/delivery`, {});
    }

  }

  private getBadgeColor(code: string): string {
    switch (code) {
      case RGI_DOCUMENT_STATUS.INVALID:
      case RGI_DOCUMENT_STATUS.ERROR:
        return 'rgi-ui-danger';
      case RGI_DOCUMENT_STATUS.IN_PROGRESS:
      case RGI_DOCUMENT_STATUS.PENDING_SIG:
        return 'rgi-ui-primary';
      case RGI_DOCUMENT_STATUS.VALID:
        return 'rgi-ui-success';
    }
  }

  private getDocumentDetails(doc: ApiDocument): LabeledEntity[] {
    const entities = [];

    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.TYPE', doc.type.description);
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.STATE', this.getDocStatusDescription(doc));
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.FILE', doc?.files[0]?.name);
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.CREATED', doc?.insertionDate);
    this.addElementToDocDetails(entities, this.defineUploadTranslationByType(doc?.type?.code), doc?.files[0]?.uploadTime);
    this.addElementToDocDetails(entities, this.defineSendingUserTranslationByType(doc?.type?.code), doc?.files[0]?.userIns?.description);
    this.addElementToDocDetails(entities, this.defineValidationTranslationByType(doc?.type?.code), doc?.validationDate);
    this.addElementToDocDetails(entities,this.defineValidatingUserTranslationByType(doc?.type?.code),doc?.userValidator?.description);
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.SUBJECT_SENDING_DATE', doc?.files[0]?.deliveryTime);
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.DELIVERY_TYPE', doc?.deliveryType?.description);
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.DELIVERY_ADDRESS', doc?.deliveryAddress);
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.DELIVERY_PARTY', doc?.deliveryParty?.description);
    if (doc.state.code === 'RGI_DOCUMENT_STATUS.ERROR'){
      this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.ERROR', doc?.errorType.description);
    }
    this.addElementToDocDetails(entities, 'RGI_SHARED_COMPONENTS.NOTE', doc?.files[0]?.note);

    return entities;
  }

  private  defineUploadTranslationByType(docTypeCode: string) : string {
    switch (docTypeCode) {
      case DocumentType.INBOUND:
      case DocumentType.INBOUND_TO_BE_VALIDATED:
        return 'RGI_SHARED_COMPONENTS.UPLOADED';
      case DocumentType.OUTBOUND:
        return 'RGI_SHARED_COMPONENTS.ARCHIVED'
      default:
        return 'RGI_SHARED_COMPONENTS.UPLOADED';
    }
  }

  private  defineSendingUserTranslationByType(docTypeCode: string) : string {
    switch (docTypeCode) {
      case DocumentType.INBOUND:
      case DocumentType.INBOUND_TO_BE_VALIDATED:
        return 'RGI_SHARED_COMPONENTS.UPLOADING_USER';
      case DocumentType.OUTBOUND:
        return 'RGI_SHARED_COMPONENTS.ARCHIVING_USER';
      default:
        return 'RGI_SHARED_COMPONENTS.UPLOADING_USER';
    }
  }

  private  defineValidationTranslationByType(docTypeCode: string) : string {
    switch (docTypeCode) {
      case DocumentType.INBOUND_TO_BE_VALIDATED:
        return 'RGI_SHARED_COMPONENTS.VALIDATED';
      case DocumentType.OUTBOUND_CAN_BE_DELIVERED:
        return 'RGI_SHARED_COMPONENTS.DELIVERED'
      default:
        return 'RGI_SHARED_COMPONENTS.VALIDATED';
    }
  }
  private  defineValidatingUserTranslationByType(docTypeCode: string) : string {
    switch (docTypeCode) {
      case DocumentType.INBOUND_TO_BE_VALIDATED:
        return 'RGI_SHARED_COMPONENTS.VALIDATING_USER';
      case DocumentType.OUTBOUND_CAN_BE_DELIVERED:
        return 'RGI_SHARED_COMPONENTS.DELIVERY_USER'
      default:
        return 'RGI_SHARED_COMPONENTS.VALIDATING_USER';
    }
  }

  private addElementToDocDetails(entities: any[], label: string, prop: any) {
    if (!!prop) {
      entities.push(new LabeledEntity(label, prop));
    }
  }

  private getSignData(doc: ApiDocument) {
    const docState = doc.state?.code;
    const isToSign = doc.allowedActions.find(action => action === RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_SIGN_START);
    const isPendingSig = docState === RGI_DOCUMENT_STATUS.PENDING_SIG;
    const isSigned = docState === RGI_DOCUMENT_STATUS.VALID && doc.requireSignature;
    return {
      signIcon: isToSign ? 'rgi-ui-icon-edit' : isPendingSig ? 'rgi-ui-icon-note' : 'rgi-ui-icon-check',
      signTooltipDescr: 'RGI_SHARED_COMPONENTS.' + (isToSign ? 'SIGN_DOCUMENT' : isPendingSig ? 'PENDING_SIGNATURE_TOOLTIP' : isSigned ? 'SIGNED_DOCUMENT' : 'NO_SIGN_NEEDED')
    };
  }

  private mockDocuments(documents: ApiDocument[]) {
    const file = new DocumentFile();
    file.id = '1';
    file.name = 'test.pdf';
    file.uploadTime = new Date().toISOString();
    const docToSign = cloneDeep(documents[0]);
    docToSign.type = new GenericEntity();
    docToSign.type.code = DocumentType.OUTBOUND_TO_BE_DIGITALLY_SIGNED;
    docToSign.files = [file];
    docToSign.state.code = RGI_DOCUMENT_STATUS.IN_PROGRESS;
    docToSign.allowedActions = [RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_SIGN_START, RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_ARCHIVE, RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_DOWNLOAD, RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_DELIVERY];
    documents.push(docToSign);
    const docPendingSig = cloneDeep(documents[0]);
    docPendingSig.type = new GenericEntity();
    docPendingSig.type.code = DocumentType.OUTBOUND_TO_BE_DIGITALLY_SIGNED;
    docPendingSig.files = [file];
    docPendingSig.state.code = RGI_DOCUMENT_STATUS.PENDING_SIG;
    docPendingSig.allowedActions = [RGI_DOCUMENT_ALLOWED_ACTIONS.CAN_SIGN_STOP];
    documents.push(docPendingSig);
  }

  protected getDocStatusDescription(doc: ApiDocument) {
    const type = doc.type?.code;
    const status = doc.state?.code;

    const statusMapping = {
      [DocumentType.INBOUND]: {
        [RGI_DOCUMENT_STATUS.IN_PROGRESS]: doc.files[0]?.idDocDMS ? 'RGI_SHARED_COMPONENTS.ARCHIVED' : 'RGI_SHARED_COMPONENTS.TO_BE_UPLOADED',
        [RGI_DOCUMENT_STATUS.VALID]: 'RGI_SHARED_COMPONENTS.UPLOADED'
      },
      [DocumentType.INBOUND_TO_BE_VALIDATED]: {
        [RGI_DOCUMENT_STATUS.IN_PROGRESS]: 'RGI_SHARED_COMPONENTS.TO_BE_UPLOADED',
        [RGI_DOCUMENT_STATUS.VALID]: 'RGI_SHARED_COMPONENTS.UPLOADED'
      },
      [DocumentType.OUTBOUND]: {
        [RGI_DOCUMENT_STATUS.IN_PROGRESS]: 'RGI_SHARED_COMPONENTS.TO_BE_GENERATED',
        [RGI_DOCUMENT_STATUS.VALID]: 'RGI_SHARED_COMPONENTS.ARCHIVED'
      },
      [DocumentType.OUTBOUND_TO_BE_DIGITALLY_SIGNED]: {
        [RGI_DOCUMENT_STATUS.IN_PROGRESS]: 'RGI_SHARED_COMPONENTS.TO_BE_GENERATED',
        [RGI_DOCUMENT_STATUS.VALID]: 'RGI_SHARED_COMPONENTS.SIGNED',
        [RGI_DOCUMENT_STATUS.INVALID]: 'RGI_SHARED_COMPONENTS.NOT_SIGNED'
      },
      [DocumentType.OUTBOUND_CAN_BE_DELIVERED]: {
        [RGI_DOCUMENT_STATUS.IN_PROGRESS]: 'RGI_SHARED_COMPONENTS.TO_BE_GENERATED',
        [RGI_DOCUMENT_STATUS.VALID]: 'RGI_SHARED_COMPONENTS.DELIVERED',
        [RGI_DOCUMENT_STATUS.INVALID]: 'RGI_SHARED_COMPONENTS.NOT_DELIVERED'
      }
    };

    return statusMapping[type]?.[status] || doc.state.description;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GzipActivationStrategy, GzipProvider} from '../http-api';
import {DefaultGzipActivationStrategy} from './default-gzip-activation-strategy';
import {PakoZlibProviderService} from './pako-zlib-provider.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: GzipProvider, useClass: PakoZlibProviderService},
    {provide: GzipActivationStrategy, useClass: DefaultGzipActivationStrategy}
  ]
})
export class GzipModule {
}

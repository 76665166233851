<ng-container>
    <div class="mtb-2">
        <div class="container-custom sub-title-section-shared">
            <label ng-bind="label" class="title-heir">
                <h3 class="title-header mtb-1">{{ '_CLAIMS_._HEIRS' | translate }}</h3>
            </label>
            <button rgi-rx-button color="secondary" (click)="addHeir()">
                {{ '_CLAIMS_._HEIR_ADD' | translate }}
            </button>
        </div>
        <div *ngIf="heirsList.potentialHeirs">
            <div *ngFor="let heir of heirsList.potentialHeirs" class="mrb-5">
                <rgi-rx-form-field>
                  <input type="checkbox" rgiRxInput [checked]="heir.checked" (change)="toggleHeir(heir)">
                  <label rgiRxLabel class="pr-5">{{ heir.linkSubjectNominative }} - {{'_CLAIMS_._HEIR' | translate}}</label>
                </rgi-rx-form-field>
            </div>
        </div>
    </div>
</ng-container>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

// CHECK LISTINO FUNZIONI
export class AdminFunctionsService {

  isAdminEnabled$ = new BehaviorSubject<boolean>(false);
  constructor() { }

  getAdminEnabled(): Observable<boolean> {
    return this.isAdminEnabled$.asObservable();
  }

  setAdminEnabled(isAdminFunctionEnabled: boolean) {
    this.isAdminEnabled$.next(isAdminFunctionEnabled);
  }
}

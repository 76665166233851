import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import {
  BeneficiaryDefinition,
  BeneficiaryRole,
  Definition,
  InputFieldDefinition, PaymentTypeDefinition,
  PostsalesError,
  PostsalesOperationObject,
  Role
} from '../../models/postsales-operations-response.model';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcRolesUtils } from '../../modules/lpc-roles-step/lpc-roles-utils';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';


@Component({
    selector: 'lpc-recess',
    templateUrl: './recess.component.html',
    styleUrls: ['./recess.component.css']
  })
export class RecessComponent extends AbsOperationComponent {

  public get beneficiaryDefinition(): BeneficiaryDefinition {
    if (!!this.beneficiariesDefinitions && 1 === this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0];
    }
    return {} as BeneficiaryDefinition;
  }

  get beneficiaryType(): string {
    if (!!this.beneficiaryDefinition) {
      return this.beneficiaryDefinition.code;
    }
  }

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  public get isWithSettlements(): boolean {
    return !!this.tableDefinitions && !!this.tableDefinitions.length;
  }

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.filterPaymentTypes(this.$paymentTypes);
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected anag: AnagService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  protected operationDataKey = 'payment';
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  public lifeQuotation: any;
  public damageQuotation: any;
  public lifeDamageProduct: boolean;
  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];
  public showCreditPayment: boolean;

  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  private $paymentTypes: PaymentTypeDefinition[] = [];

  private _beneficiaryData: any[] = [];

  // filtra i pagamenti -> usato in ext
  public filterPaymentTypes(ptVector: PaymentTypeDefinition[]): PaymentTypeDefinition[] {
    return ptVector;
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {
          this.createDraftResponse(result);
        })
      ).subscribe()
    );
  }

  createDraftResponse(result) {
    LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
    this.insuredSettlements = result.data.operationData.data.insuredSettlements;
    this.tableDefinitions = result.definitions.settlement as Definition[];
    this.formGroup.get('dates')
      .setValue(PlcObjectUtils.mapInputFieldsAsDate(result.definitions.dates as InputFieldDefinition[]), { emitEvent: false });
    this._beneficiaryData = result.data.operationData.data.beneficiaries;
    if (!!this._beneficiaryData) {
      this.formGroup.get('beneficiaries').setValue(
        (this._beneficiaryData as any[]).map(beneficiary => {
          return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
        }),
        {emitEvent: false}
      );
    }
    this.formGroup.get('payment').setValue(result.data.operationData.data.creditPayment, {emitEvent: false});
    if (result.definitions.paymentTypes != null) {
      this.showCreditPayment = true;
    } else {
      this.showCreditPayment = false;
    }
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<PostsalesOperationObject> {
    return super.updateDraft(step, reload, opDataType).pipe(
      tap(result => {
        this.lifeQuotation = result.data.operationData.data.lifeQuotation;
        this.damageQuotation = result.data.operationData.data.damageQuotation;
        if (!!this.isWithSettlements) {
          this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        }
        this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
        LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
        if (result.definitions.paymentTypes != null) {
          this.showCreditPayment = true;
        } else {
          this.showCreditPayment = false;
        }
      })
    );
  }

  onNext(step, publish = false, context = step, isConfirmAndAccept = false) {
    if (step === 'operationRoles') {
      this.nextOnOperationRoles(step); // richiama nextDefault
    } else if (this.formGroup.get(step).valid && this.checkAllQuestionnaires(this.stepper.activeStep.id)) {
      this.nextDefault(step, publish, context, isConfirmAndAccept);
    } else {
      this.setFeErrors(step);
    }
  }

  nextDefault(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false, opDataType?: string) {
    this.$subscriptions.push(
      this.persistQuestionnaires(this.stepper.activeStep.id)
        .pipe(
          switchMap(_ => {
            return this.plcQuestService.prevalQuest(this.operationDataKey, step, this);
          }),
          switchMap(result => {
            this.questFactorsArray = result;
            this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
            this.defaultAnswersArray = this.plcQuestService.defaultAnswerBySec(this.operationDataKey, step, this);
            if (!!this.avcQuestionnaire) {
              this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(this.avcQuestionnaire);
            }
            return this.updateDraft(step);
          })
        ).subscribe(result => {
          this.detectChanges();
          const bErrors = result.errors && result.errors.length > 0;
          const toBePublished = (isConfirmAndAccept && !bErrors) || this.stepper.isAtLastStep;
          this.insuredSettlements = result.data.operationData.data.insuredSettlements;
          this.tableDefinitions = result.definitions.settlement as Definition[];
          LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
          if (!!this.isWithSettlements) {
            this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
          }
          if (!this.hasBlockingErrorsOnSteps(context)) {
            this.doPublishOrNext(toBePublished, isConfirmAndAccept);
          }
          this.cd.detectChanges();
      })
    );
  }

  protected getFormGroup() {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      operationRoles: new UntypedFormControl(),
      quotation: new UntypedFormControl({}),
      beneficiaries: new UntypedFormControl([]),
      revocation: new UntypedFormControl(),
      payment: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

  protected getTransformedOperationData() {
    const obj: any = {
      beneficiaries: this.formGroup.getRawValue().beneficiaries.map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        }),
      creditPayment: !!this.formGroupValue[this.operationDataKey] ? this.formGroupValue[this.operationDataKey] : {}
    };
    return obj;
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }
  private persistQuestionnaires(step): Observable<any> {
    return this.beneficiaryService.persistQuestionnaires(step);
  }

  private checkAllQuestionnaires(step): boolean {
    return this.beneficiaryService.checkAllQuestionnaires(step, s => this.setQuestionaryError(s))
  }

  onDelete(beneficiary: any) {
  }

  onTriggerQuestPreval($event: any): any {
  }

  enableRoleCheckbox(roleCode: string): boolean {
    return LpcRolesUtils.enableCheckbox(this.operations, LpcRolesUtils.getRoleCode(roleCode));
  }

}

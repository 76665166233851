import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable} from 'rxjs';
import {StartCardNode} from '../../models/domain-models/start-card-node';
import {ReIssueStartCardState} from '../re-issue-state-manager/re-issue-start-card-state-manager.service';
import {ReIssuePolicyDataNavigationData} from '../re-issue-state-manager/re-issue-policy-data-state';
import {DatePipe} from '@angular/common';
import {
  ReIssuePortfolioProposalService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal.service';
import {ProposalDataResponse} from '../../models/pc-portfolio-models/proposal-models/proposal-data-response';
import {ReIssueStartCardResourceService} from '../re-issue-resources/re-issue-start-card-resource.service';
import {ProposalDataRequest} from '../../models/pc-portfolio-models/proposal-models/proposal-data-request';
import {Company} from '../../models/check-pin-response';
import {ProductsObject} from "../../models/pc-portfolio-models/genericEntityPcPortfolio";


@Injectable({
  providedIn: 'root'
})
export class ReIssueStartCardBusinessService {
  constructor(
    private routingService: RoutingService,
    private startCardService: ReIssueStartCardResourceService,
    protected portfolioProposal: ReIssuePortfolioProposalService,
    protected datePipe: DatePipe
  ) {
  }

  createProposal(activeRoute: ActiveRoute, form: UntypedFormGroup) {
    const companyId = form.controls.company.value.aniaCode;
    const nodeId = form.controls.node.value.code;
    const productCode = form.controls.product.value.code;
    const dateIssue = this.datePipe.transform(new Date(Number(form.controls.effectiveDate.value)), 'yyyy-MM-dd');
    const dateEffect = dateIssue;
    // this.startCardService.getInitContract(companyId, nodeId, productCode).pipe(
    //   take(1)
    // ).subscribe(
    //   (initContract: InitContract) => {
    //     const req: ReIssuePolicyDataNavigationData = {
    //       contractorSubject: null, // TODO: CHECK IF CORRECT
    //       policyTechnicalData: null,
    //       resourceId: initContract.id,
    //       productName: form.controls.product.value.description,
    //       node: form.controls.node.value,
    //       companyId: form.controls.company.value.code,
    //       productCode: form.controls.product.value.code,
    //       editMode: false,
    //       editFunctionality: false,
    //       isSubstitution: false,
    //       backFromStep: false,
    //       isToDisableFraz: null
    //     };
    //     this.goToNextPage(activeRoute, req);
    //   }
    // );
    this.portfolioProposal.initProposal(this.getRequest(productCode, companyId, nodeId, dateIssue, dateEffect)
    ).subscribe(
      (initProposal: ProposalDataResponse) => {
        console.log('ReIssueSpdLandingStateManagerService::initProposal', initProposal);
        const req: ReIssuePolicyDataNavigationData = {
          contractorSubject: null,
          previousContractor:null,
          isToDisableFraz: false,
          resourceId: initProposal.id,
          productName: form.controls.product.value.description,
          node: form.controls.node.value,
          productCode: form.controls.product.value.code,
          policyTechnicalData: null,
          companyId: form.controls.company.value.code,
          editMode: false,
          editFunctionality: false,
          isSubstitution: false,
          backFromStep: false,
          authorize: false,
          productId: form.controls.product.value.identification,
          thirdPersonContact: null
        };
        this.goToNextPage(activeRoute, req);
      });
  }

  getRequest(productCode: string, companyId: string, nodeId: string, dateIssue: string, dateEffect: string): ProposalDataRequest {
    const proposalRequest = new ProposalDataRequest();
    proposalRequest.additionalLabels = null;
    proposalRequest.productCode = productCode;
    proposalRequest.companyCode = companyId;
    proposalRequest.managementNodeCode = nodeId;
    proposalRequest.issuingDate = dateIssue;
    proposalRequest.effectDate = dateEffect;
    proposalRequest.from = null;
    return proposalRequest;
  }

  getCompanies$(st: ReIssueStartCardState): Observable<Array<Company>> {
    return this.startCardService.getCompanies$(st);
  }

  getProducts$(st: ReIssueStartCardState, node: string): Observable<ProductsObject> {
    return this.startCardService.getProducts$(st, node);
  }

  getNode$(st: ReIssueStartCardState, node: string): Observable<Array<StartCardNode>> {
    return this.startCardService.getNode$(st, node);
  }

  validate() {
  }

  goToNextPage(activeRoute: ActiveRoute, reIssuePolicyDataNavigationData: ReIssuePolicyDataNavigationData) {
    this.routingService.navigate('re-issue-policy-data', reIssuePolicyDataNavigationData, activeRoute.id);
  }
}

import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutingHostDirective, RoutingService } from '@rgi/portal-ng-core';
import { ExpertsSearchComponent } from './experts-search/experts-search.component';
import { ExpertUtilityService } from './expert-utility-service.service';
import {ExpertsListComponent} from './experts-list/experts-list.component';
import {ExpertDetailsComponent} from './expert-details/expert-details.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-experts',
  templateUrl: './experts.component.html',
  styleUrls: ['./experts.component.scss'],
  providers: [RoutingService]
})
export class ExpertsComponent extends CardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;
  routes = null;


  constructor(
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private utilityServ: ExpertUtilityService,
    @Inject('authService') private authService,
    @Inject('expertsSearchComponent') expertsSearchComponent,
    @Inject('expertsListComponent') expertsListComponent,
    @Inject('expertDetailsComponent') expertDetailsComponent,
    private rxTranslationsService: RgiRxTranslationService
  ) {
      super(cardComponentService, sessionService);
      this.routes = {
        board: expertsSearchComponent,
        list : expertsListComponent,
        detail : expertDetailsComponent,
      };
    }

  ngAfterViewInit(): void {
    console.log('ACCESSO FIDUCIARI');

    const listFunz = this.authService.getOperator().enabledFeatures;

    if (listFunz && listFunz.indexOf('SXFIDU') >= 0) {
      this.routingService.routes = this.routes;
      this.routingService.host = this.hostViewRef.viewContainerRef;
      console.log('BEFORE RAW VALUE FORM? ');

/*      this.routingService.loadComponent(this.routes.detail);
      (this.routingService.componentRef.instance as  ExpertDetailsComponent).card = this.card;
      */
      if (!this.utilityServ.getExperts() || this.utilityServ.getExperts().length === 0
        || !this.utilityServ.getExpertSearchFormGroup()) {
        // console.log('experts : ', this.utilityServ.getExpertSearchResult().length);
        this.routingService.loadComponent(this.routes.board);
        (this.routingService.componentRef.instance as ExpertsSearchComponent).card = this.card;
      } else if (this.utilityServ.getExperts().length > 0 && !this.utilityServ.getExpertDetail()) {
        this.routingService.loadComponent(this.routes.list);
        (this.routingService.componentRef.instance as ExpertsListComponent).card = this.card;
      } else if (this.utilityServ.getExperts().length > 0 && this.utilityServ.getExpertDetail() != null) {
        this.routingService.loadComponent(this.routes.detail);
        (this.routingService.componentRef.instance as  ExpertDetailsComponent).card = this.card;
      }
    } else {
      this.rxTranslationsService.translate('_CLAIMS_._INSUFFICIENT_PERMISSIONS')
      .subscribe(res =>  this.card.title = res);
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }


  ngOnDestroy() {

  }
}

import { Inject, Injectable } from '@angular/core';
import { InvalidFieldError } from '../../error/invalid-field.error';
import { ServiceError } from '../../error/service-error';
import { Activity } from '../../model/domain/activity/activity';
import {ApiActivityDetailsRequest, ActivityDetailsRequest} from '../../model/api/activity/api-activity-details-request';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private loaderService: any;
  private claimsActivityService: any;
  private authService: any;
  private claimsActivityCommonService: any;

  constructor(
    @Inject('claimsLoaderService') loaderService: any,
    @Inject('claimsActivityService') claimsActivityService: any,
    @Inject('authService') authService: any,
    @Inject('claimsActivityCommonService') claimsActivityCommonService
  ) {
    this.loaderService = loaderService;
    this.claimsActivityService = claimsActivityService;
    this.authService = authService;
    this.claimsActivityCommonService = claimsActivityCommonService;
  }

  getTaskFunctions(activity: Activity): Promise<any> {
    const taskId = activity.actTaskBean[0].taskId != null ? activity.actTaskBean[0].taskId.toLowerCase() : '';
    const area = activity.actDictionaryBean.area;

    if (taskId !== '' || area === 6 || area === 7 || area === 3) {

      const params = { idActivityTask: activity.actTaskBean[0].idActTask, idUser: this.authService.getOperator().username };

      try {

        return this.claimsActivityService.getActivityFunctions(params);
      } catch (e) {
        throw new ServiceError('Get Tasks Functions Exception:' + e);
      }
    } else {
      throw new InvalidFieldError('Task id field is missing.');
    }
  }

  activityDetailsRequest(): ActivityDetailsRequest {
    /*
    TODO: base request
    let request = {
        "activityDetailsInput": {
            "idActDictionary": paramFromCount.idActDictionary,
            "idActTriggerEvent": paramFromCount.idActTriggerEvent,
            "group": group,
            "user":  usr,
            "pager": $scope.serviceInfo.pagerRequest,
            "ordering": $scope.serviceInfo.ordering,
            "type": paramFromCount.type,
            "priorityType": paramFromCount.priorityType,
            "activityType": paramFromCount.page,
            "chiamante":paramFromCount.chiamante
    }
    */
    return new ActivityDetailsRequest();
  }

  related(activity: Activity, callbackFunction: any) {
    // let stopCommand = this.loaderService.startWithCommand();
    const serviceInfoRequest = new ActivityDetailsRequest();
    serviceInfoRequest.activityDetailsInput.pager.pageNumber = 1;
    serviceInfoRequest.activityDetailsInput.claimNumber = activity.claimNumber;

    return this.claimsActivityService.getActivityDetails(serviceInfoRequest, callbackFunction);
  }

  assign(activity: Activity) {
    this.claimsActivityCommonService.assignTaskModal(activity.actTaskBean[0].taskId, activity.actTaskBean[0].idActTask);
  }

  updateAssignee(activity: Activity) {
    const stopCommand = this.loaderService.startWithCommand();
    const username = this.authService.getUsername();
    const takeChargePromise = this.claimsActivityService.updateAssigneeUserInTask(activity.actTaskBean[0].idActTask, username);

    takeChargePromise.then((result) => {
      // TODO: check if the event is needed in this particular case
      // eventService.broadcastEvent(WORKFLOW_CARDTASKLIST_EVENTS.TASKLIST_CHANGED);
    });
    takeChargePromise.finally(stopCommand.execute);
  }

  runActivity(activity: Activity, callbackFunction: any, card: any) {

    const stopCommand = this.loaderService.startWithCommand();
    const username = this.authService.getUsername();
    const firstActTask = activity.actTaskBean[0];
    const taskId = firstActTask.taskId != null ? firstActTask.taskId.toLowerCase() : '';
    const area = activity.actDictionaryBean.area;
    const parameters = {
      assignee: firstActTask.assigneeUser,
      assigneeGroup: firstActTask.assigneeGroup,
      id: activity.idAct,
      idActDictionary: activity.actDictionaryBean.idActDict,
      idActTask: firstActTask.idActTask,
      taskId
    };

    if (taskId !== '' || area === 6 || area === 7 || area === 3) {
      if (activity.assigneeUser) {
        const takeChargePromise = this.claimsActivityService.updateAssigneeUserInTask(taskId, username);

        try {

          takeChargePromise.then((result) => {
            this.claimsActivityService.runActivity(
              parameters,
              card.idSession,
              card,
              callbackFunction
            );
          }).finally(stopCommand.execute);
        } catch (e) {
          throw new ServiceError('Run Activity Exception:' + e);
        }

      } else {
        try {
          this.claimsActivityService.runActivity(
            parameters,
            card.idSession,
            card,
            callbackFunction
          )
            .finally(stopCommand.execute);
        } catch (e) {
          throw new ServiceError('Run Activity Exception:' + e);
        }
      }
    } else {
      throw new InvalidFieldError();
    }
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';
@Component({
  selector: 'lpc-fee-extra-modal',
  templateUrl: './lpc-fee-extra-modal.component.html',
  styleUrls: ['./lpc-fee-extra-modal.component.css'],
  animations: [
    trigger('fadeAnimation', [
      state('void', style({ opacity: 0 })),
      transition(':enter', animate(200)),
      transition(':leave', animate(200))
    ]),
  ]
})
export class LpcFeeExtraModalComponent implements OnInit, OnModalClose {

  @Output() modalClose = new EventEmitter();
  data: {instName?: string, mainTitle?: string, title?: string, data?: {}} = {};

  constructor(@Optional() @Inject(DIALOG_DATA) data: LpcFeeExtraModalComponent) {
    this.data = data;
  }

  ngOnInit() {
  }

}

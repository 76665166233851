import { Component, Inject, Input, OnInit, Output, EventEmitter, LOCALE_ID } from '@angular/core';
import {
  AlertService, ApiTranslatorPolicyService,
  PolicyService, DamageLocation, FunctionService,
  Policy, Outcome
} from '@rgi/digital-claims-common-angular';
import { RoutableComponent, RoutingService } from '@rgi/portal-ng-core';
import { ClaimOpeningService } from '../../../claim-opening.service';
import { ClaimOpeningPolicySearchResultsService } from '../claim-opening-policy-results.service';
import * as uuid from 'uuid';
import { formatDate } from '@angular/common';
import { PotentialClaimData } from '../model/domain/potential-claim-data';
import { DamageCategory } from '../../claim-opening-policy-search/dto/damage-categories';
import { StepperSection } from '../../claim-opening-details/model/stepper-section';
import { ClaimOpeningDetailsComponent } from '../../claim-opening-details/claim-opening-details.component';
import { OutputShowDetail } from '../../../../shared/dsc-similar-claims/dsc-similar-claims.config';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-similar-claims',
  templateUrl: './similar-claims.component.html',
  styleUrls: ['./similar-claims.component.scss']
})
export class SimilarClaimsComponent implements OnInit, RoutableComponent {
  @Input() public card: any;
  @Input() public policy: Policy;
  @Input() listPotentialClaims: Array<any>;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Input() policyModifyData: any;

  isServicePolicyDetailsEnded: boolean;
  coverturesFromService: any;
  inputPotentialClaim: PotentialClaimData;
  damageCategory: DamageCategory;
  externalPortfolio: boolean;
  unitsProductbin: Array<any>;
  currentStep = StepperSection.selectPolicy;
  stepperLabels = [];
  covertureOk: boolean;
  rows: Array<any>;
  searchFE: string;
  totalFilter: number;
  legalProtectionActive: boolean;
  listFunz: any[];

  constructor(
    @Inject('sessionService') private session: any,
    @Inject('eventService') private eventService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('claimsService') private claimsService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('authService') private authService,
    @Inject(LOCALE_ID) private locale: string,
    private policyService: PolicyService,
    private translatorService: ApiTranslatorPolicyService,
    private functionService: FunctionService,
    private alertService: AlertService,
    private routingService: RoutingService,
    private claimOpeningPolicySearchResultsService: ClaimOpeningPolicySearchResultsService,
    private claimOpeningService: ClaimOpeningService,
    private rxTranslationsService: RgiRxTranslationService) {
      if (this.stepperLabels.length == 0) {
        this.rxTranslationsService.translate('_CLAIMS_._SELECT_POLICY').subscribe(
          res => this.stepperLabels.push(res)
        )
        this.rxTranslationsService.translate('_CLAIMS_._OCCURRENCE_DATA').subscribe(
          res => this.stepperLabels.push(res)
        )
        this.rxTranslationsService.translate('_CLAIMS_._DAMAGED__ASSET').subscribe(
          res => this.stepperLabels.push(res)
        )
        this.rxTranslationsService.translate('_CLAIMS_._SELECT_TYPE_OF_LOSS').subscribe(
          res => this.stepperLabels.push(res)
        )
        this.rxTranslationsService.translate('_CLAIMS_._DOCUMENTS').subscribe(
          res => this.stepperLabels.push(res)
        )
      }
  }

  ngOnInit() {
    this.rows = new Array();
    this.listFunz = this.authService.getOperator().enabledFeatures;
    this.listPotentialClaims.forEach(element => {
      this.legalProtectionActive = Boolean(element.isLegalProtectionEnabled.boolean);




      const aRow = {
        expanded: false,
        filtered: true,
        similCl: element,
        intermediary: element.nodoPolizza === this.policy.nodeCode,
        actionPES: element.numberClaim ? false : true,
        serachString: element.numberClaim + '*' +
          element.numeroDenuncia + '*' +
          element.stato.descrizione + '*' +
          element.numeroPolizza + '*' +
          JSON.stringify(element.dataAccadimento.data) + '*' +
          element.nominativoAssicurato + '*' +
          element.nominativoControparte + '*' +
          element.claimBranch + '*' +
          element.claimType + '*' +
          element.liquidatore + '*' +
          element.ispettorato + '*' +
          element.numeroProcedimento
      };
      this.rows.push(aRow);
    });
    this.totalFilter = this.rows.length;
    this.damageCategory = this.claimOpeningService.getCategory(this.card.id);

    this.externalPortfolio = this.claimOpeningService.getExternaPortfolio();

    if (this.claimOpeningService.getExistClMadePostuma()) {
      this.claimOpeningService.setVisualizedMsgPostuma(true);


      this.rxTranslationsService.translate('_CLAIMS_._TOL_CLAIMS_MADE').subscribe(
        res => {
          this.eventService.broadcastEvent('alertMsg',
          {
            varMsg: res
          });
        }
      )
    }
  }

  valuateStringFieldForVisual(field: string) {
    if (field) {
      return field;
    } else {
      return '-';
    }
  }

  valuateBooleanFieldForVisual(field: boolean) {
    if (field) {
      return 'YES';
    } else {
      return 'NO';
    }
  }


  valuateArrayFieldForVisual(field: string[]) {
    if (field && field.length > 1) {
      return field;
    } else {
      const ret: string[] = ['-'];
      return ret;
    }
  }

  search() {
    this.totalFilter = 0;
    this.rows.forEach(arow => {
      const searchString: string = arow.serachString;
      // console.log(searchString.indexOf(this.searchFE));
      if (this.searchFE &&
        searchString.toUpperCase().indexOf(this.searchFE.toUpperCase()) < 0) {
        arow.filtered = false;
      } else {
        arow.filtered = true;
        this.totalFilter++;
      }
    });
  }

  ctrDetails(index) {
    console.log('INDICE SELEZIONATO');
    console.log(index);
    if (this.rows[index].expanded) {
      this.rows[index].expanded = false;
    } else {
      this.rows[index].expanded = true;
    }
  }

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }

  back() {
    // const session = this.getCurrentSession();
    // this.session.remove(session.idSession);
    this.navigation.emit('searchResult');
    this.card.title = 'Policy list';
  }


  newClaim() {
    const occurrenceDate = this.claimOpeningService.getSessionOccurrenceDate(this.card.id);
    const occurrenceTime = this.claimOpeningService.getSessionTimeRequest(this.card.id).toString();
    this.eventService.broadcastEvent('start-loader');
    // we call the two services in concurrency, the following two functions both call
    // initPotentialClaim, that, first of all, checks if policy details service has ended and if
    // the coverture check is ok. If that's the case, so both services had ended with outcome ok,
    // then we save the data and go on the next route
    this.chargeDetail(this.policy, occurrenceDate, occurrenceTime);
  }

  chargeDetail(policy: Policy, occurrenceDate: Date, occurrenceTime: string) {
    if (this.claimOpeningService.getExternaPortfolio()) {
      this.callPolicyDetailsCovertureExt(policy, occurrenceDate, occurrenceTime);
    } else {
      this.callPolicyDetails(policy);
      this.callVerifyCoverture(policy, occurrenceDate, occurrenceTime);
    }
  }

  callPolicyDetailsCovertureExt(policy: Policy, occurrenceDate: Date, occurrenceTime: string) {
    const polExt = this.claimOpeningService.getExternalPolicyCurrent();

    this.claimOpeningPolicySearchResultsService.policyDataPtfExt(polExt, JSON.stringify(occurrenceDate)).subscribe((res: any) => {
      console.log('policyDataPtfExt  ' + res);
      policy.assets = res.assets;
      policy.residenceHolder = new DamageLocation();
      policy.residenceHolder.formatAddress = res.formatAddress;
      policy.residenceHolder.address = res.resHolderaddress;
      policy.residenceHolder.houseNumber = res.resHolderhouseNumber;
      policy.residenceHolder.zipCode = res.resHolderzipCode;
      policy.residenceHolder.city = res.resHoldercity;
      policy.residenceHolder.adminLevel3 = res.resHolderadminLevel3;
      policy.residenceHolder.province = res.resHolderprovince;
      policy.residenceHolder.adminLevel2 = res.resHolderadminLevel2;
      policy.residenceHolder.nationAbbreviation = res.resHoldernationAbbreviation;
      this.isServicePolicyDetailsEnded = true;
      const sessionUuid = uuid.v4();
      this.claimOpeningService.setSessionUuid(this.card.id, sessionUuid);
      const formatdate = 'dd-MM-yyyy';
      const dateString = occurrenceDate ? formatDate(occurrenceDate, formatdate, this.locale) : null;

      const covertures = { administrativeCoverture: true, technicalCoverture: true };
      this.coverturesFromService = covertures;
      this.inputPotentialClaim = new PotentialClaimData(
        sessionUuid,
        policy.number,
        this.damageCategory.idCategory,
        dateString, occurrenceTime,
        covertures.technicalCoverture.toString(),
        covertures.administrativeCoverture.toString(),
        formatDate(policy.effectDate, formatdate, this.locale),
        formatDate(policy.expirationDate, formatdate, this.locale));
      this.covertureOk = true;

      this.initPotentialClaim(policy);
    });
  }

  callPolicyDetails(policy: Policy) {
    this.policyService.getPolicyDetail(policy.objectId)
      .subscribe((response: any) => {
        const contractRes = response.contract;
        console.log(contractRes);
        if (contractRes) {
          const policyDetail = this.translatorService.apiPolicyToDtoPolicy(contractRes);
          policy.assets = policyDetail.assets;
          policy.residenceHolder = policyDetail.residenceHolder;
          // policy.category = contractRes.currentMovement.category.codice;
          this.claimOpeningService.setPolicyDetail(contractRes);
        }
        this.isServicePolicyDetailsEnded = true;
        this.initPotentialClaim(policy);
      });
  }

  callVerifyCoverture(policy: Policy, occurrenceDate: Date, occurrenceTime: string) {
    this.policyService.getPolicyAdditionalData(policy.number,
      this.damageCategory.idCategory,
      occurrenceDate, occurrenceTime).subscribe(additionalData => {
        // this.policyService.getPolicyCovertures(policy.number, occurrenceDate, occurrenceTime).subscribe(covertures => {
        const covertures = additionalData.covertures;
        const sessionUuid = uuid.v4();
        this.claimOpeningService.setSessionUuid(this.card.id, sessionUuid);
        const formatdate = 'dd-MM-yyyy';
        const dateString = occurrenceDate ? formatDate(occurrenceDate, formatdate, this.locale) : null;
        this.coverturesFromService = covertures;
        this.inputPotentialClaim = new PotentialClaimData(
          sessionUuid,
          policy.number,
          this.damageCategory.idCategory,
          dateString, occurrenceTime,
          covertures.technicalCoverture.toString(),
          covertures.administrativeCoverture.toString(),
          formatDate(policy.effectDate, formatdate, this.locale),
          formatDate(policy.expirationDate, formatdate, this.locale));


        if (!covertures.technicalCoverture &&
          this.listFunz && this.listFunz.indexOf('SXIPNV') < 0) {
          this.eventService.broadcastEvent('stop-loader');
          this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY').subscribe(
            res =>  this.alertService.translatedMessage(res)
          )
        } else if (!covertures.administrativeCoverture &&
          !this.functionService.isAuthorizedForClaimFeature('ADMINISTRATIVE_FORCING_COMPLAINT')) {
          this.eventService.broadcastEvent('stop-loader');
          this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY').subscribe(
            res =>  this.alertService.translatedMessage(res)
          )
        } else {
          this.covertureOk = true;
        }
        this.claimOpeningService.setSessionInsuredActivities(this.card.id, additionalData.insuredActivityFactors);
        this.claimOpeningService.setEnableMultiDamage(additionalData.enableMultiDamage);
        this.claimOpeningService.setGarctp(additionalData.garctp);
        this.initPotentialClaim(policy);

      });
  }

  initPotentialClaim(policy: Policy) {
    if (this.covertureOk && this.isServicePolicyDetailsEnded) {

      this.card.title = 'FNOL data collection';
      this.claimOpeningService.setSessionSelectedPolicy(this.card.id, policy);
      this.claimOpeningService.setSessionAdministrativeCoverture(this.card.id, this.coverturesFromService.administrativeCoverture);
      // save cache
      this.claimOpeningPolicySearchResultsService.initPotentialClaim(this.inputPotentialClaim).subscribe((outcome: Outcome) => {

        if (outcome.code === '1') {

          // go to detail
          this.navigation.emit('details');
          (this.routingService.componentRef.instance as ClaimOpeningDetailsComponent).card = this.card;

        } else {
          this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._CACHE_ERROR').subscribe(
            res =>  this.alertService.translatedMessage(res)
          )
        }
        this.eventService.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.eventService.broadcastEvent('stop-loader');
        }
      );
    }
  }

  isPolicyEnabled(policy: Policy): boolean {
    if (policy.claimBranchCodes && !this.externalPortfolio) {
      const codesInCategory = this.damageCategory.branchesCodes;
      const interserction = policy.claimBranchCodes.filter(x => codesInCategory.includes(x));
      return interserction && interserction.length > 0;
    }
    return true;
  }

  goToSummary(claimNumber: string, companyCode: string) {
    const that = this;

    this.eventService.broadcastEvent('start-loader');
    const comp = companyCode && companyCode.indexOf('-') ? companyCode.split('-')[0] : companyCode;
    this.claimsService.getClaimsPesDetailByNumber(comp, claimNumber, '6').then(
      (result) => {
        const detailResult = result.claimInquiry;
        detailResult.pesRequestType = '6';
        if (result.potencialClaimTypeRequest) {
          detailResult.pesRequestType = result.potencialClaimTypeRequest;
        }
        const idResult = that.sessionService.open('claimsFinder', 'detail-pes', '', true);
        detailResult.idResult = idResult;
        that.coreResult.setResult(idResult, 'detail-pes', detailResult);
        that.eventService.broadcastEvent('stop-loader');
        that.sessionService.remove(that.card.idSession);
      });
  }

  showDetailClaim(showClaim: OutputShowDetail) {
    this.goToSummary(showClaim.numeroDenuncia, showClaim.companyCode);
  }
}

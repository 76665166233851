import {GroupPolicyTranslationsKeys} from './gp-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const GROUP_POLICY_IT: GroupPolicyTranslationsKeys = {
  _GP_: {
    _BTN_: {
      _ADD_ASSET_: 'Aggiungi bene',
      _ADD_PAYMENT_: 'Aggiungi mezzo di pagamento',
      _BACK_: 'Indietro',
      _CLOSE_: 'Chiudi',
      _COMMISSIONS_: 'Provvigioni',
      _COMMISSIONS_DISCOUNT_: 'Provvigioni / Sconti',
      _CONTINUE_: 'Continua',
      _CONFIGURE_: 'Configura',
      _EMPTY_: 'Svuota',
      _LEAVE_: 'Esci',
      _NEW_: 'Nuovo',
      _SAVE_: 'Salva',
      _ORDINARY_ADDRESS_: 'Indirizzo',
      _EMAIL_: 'Email',
      _HOME_INSURANCE_: 'Home Insurance',
      _INCLUDE_APPLICATION_: 'Includi Applicazione',
      _UPLOAD_FILE_: 'Upload File',
      _ADD_NEW_INCLUSION_: 'Nuovo inserimento',
      _UNDO_: 'Cancella',
      _CONFIRM_: 'Conferma',
      _COINSURANCE_SHARES_: 'Riparti Coass.',
      _COINSURANCE_DELEGATED_: 'Delegataria',
      _DEBIT_: 'Debito',
      _CREDIT_: 'Credito',
      _YES_: 'Si',
      _NO_: 'No',
      _CREATE_CLUSTER_: 'Crea Cluster',
      _SAVE_CLUSTER_: 'Salva Cluster',
      _ASSIGN_: 'Assegna',
      _GOTO_INQUIRY_: 'Consulta PM'
    },
    _COMMON_: {
      _MASTER_POLICY_: 'Polizza madre',
      _PROPOSAL_: 'Proposta'
    },
    _LABEL_: {
      _ACCOUNTHOLDERS_: 'Soggetti di Polizza',
      _AMENDMENT_EFF_DATE_: 'Data effetto variazione',
      _COMMISSIONS_: 'Provvigioni',
      _COMPANY_: 'Compagnia',
      _CONVENTION_: 'Convenzione',
      _CONTACT_DETAILS_: 'Recapito Di Polizza',
      _CREDIT_: 'Credito',
      _CREDIT_PAYMENT_: 'Pagamento a credito',
      _DEBIT_: 'Debito',
      _DEBIT_PAYMENT_: 'Pagamento a debito',
      _EFFECTIVE_DATE_: 'Data Effetto',
      _MOVEMENT_EFFECTIVE_DATE_: 'Data Effetto Movimento',
      _INDEXING_: 'Tipo indicizzazione',
      _ISSUE_DATE_: 'Data Emissione',
      _NODE_: 'Nodo',
      _NOT_EDITABLE_: 'Non modificabile',
      _OTHER_DATA_: 'Altri Dati ...',
      _PAYMENT_FREQUENCY_: 'Frazionamento',
      _POLICY_HOLDER_: 'Contraente',
      _PRODUCT_: 'Prodotto',
      _PROPOSAL_NUMBER_: 'Numero Proposta',
      _POLICY_NUMBER_: 'Numero Polizza',
      _REQUIRED_: 'Obbligatorio',
      _PHONE_NUMBER_: 'Numero Cellulare',
      _DATIAP_: 'Dati Applicazione',
      _REGOPM_: 'Regolazione',
      _TECHNICAL_ACC_TRANSACTIONS_: 'Titoli',
      _BATCHP_: 'Esecuzione Batch',
      _TARIPM_: 'Gestione Tariffa',
      _NO_ACTION_FOUND_: 'Nessuna azione disponibile',
      _ACC_TRANS_AMOUNT_: 'Importo del titolo',
      _ACC_TRANS_STATE_: 'Stato del titolo',
      _DESCRIPTION_: 'Descrizione',
      _APPLICATION_DOCUMENT_: 'Documentazione applicazioni',
      _CLICK_CONFIRM_ADD_INCLUSION_: 'Clicca conferma per includere una nuova applicazione​',
      _FILE_UPLOAD_OK_: 'File caricato correttamente',
      _SHARE_PERC_: '% Condivisione',
      _COMMISSIONS_PERC_: '% Commissioni',
      _INTERMEDIARY_: 'Intermediario',
      _OUR_PERC_: 'Percentuale',
      _TOTAL_PERC_: 'Percentuale totale',
      _TOTAL_PERC_ERROR_: 'Percentuale globale superiore al 100%',
      _TOTAL_PERC_COMMISSIONS_ERROR_: 'Percentuale globale delle commissioni superiore al 100%',
      _ACTIONS_: 'Azioni',
      _PAYMENT_TYPE_: 'Tipo pagamento',
      _DEBIT_PAYMENT_METHOD_: 'Metodo di pagamento a debito',
      _CREDIT_PAYMENT_METHOD_: 'Metodo di pagamento a credito',
      _START_DATE_: 'Data Inizio',
      _END_DATE_: 'Data Fine',
      _FREE_TEXT_: 'Testo libero',
      _HIDE_ON_APPLICATION_: 'Nascondi su applicazione',
      _SUBCAUSES_: 'Motivo variazione',
      _SELECT_: 'Seleziona',
      _CLUSTER_NAME_: 'Nome',
      _CLUSTER_OWNER_: 'Proprietario',
      _CLUSTER_NUMBER_: 'N° Cluster',
      _ASSET_CLASS_: 'Classe veicolo',
      _ASSET_USE_: 'Uso veicolo',
      _ASSET_SECTOR_: 'Settore veicolo',
      _ASSET_EXTENSION_: 'Estensione veicolo',
      _REQUEST_: 'Richiesta',
      _NOTIFICATIONS_: 'Segnalazioni',
      _APPLICANT_: 'Richiedente',
      _REQUEST_DATE_: 'Data Richiesta',
      _AUTHORIZATION_REQUEST_INSERT_: 'Richiesta di Autorizzazione in fase di inserimento',
      _DEROGATION_LEVEL_: 'Livello Deroga',
      _REQUEST_STATE_: 'Stato della Richiesta',
      _LAST_ACTION_: 'Ultima Azione',
      _COMPETENCE_LEVEL_: 'Livello Competente',
      _LAST_ACTION_DATE_: 'Data Ultima Azione',
      _MANUAL_NOTE_: 'Note Manuali',
      _PRODUCT_TYPE_: 'Tipo Prodotto',
      _PROPOSAL_DESCRIPTION_: 'Descrizione Proposta',
      _PRIORITY_: 'Priorità',
      _PROPOSAL_AUTHORIZATION_INFO_: 'Proposta su cui è stata richiesta l\'autorizzazione',
      _USER_APPLICANT_: 'Utente Competente',
      _NOTE_: 'Note',
      _BLOCK_LEVEL_: 'Blocco',
      _ERROR_: 'Errore',
      _TYPE_: 'Tipo',
      _ORIGIN_ENTITY_: 'Entità Origine',
      _REQUEST_AUTHORIZATION_DONE_: 'La pratica numero {{proposalNumber}} è stata salvata con inoltro ' +
        'della Richiesta di Autorizzazione all\'utente competente.',
      _BACK_GUARANTEES_CLUSTER_ERROR_: 'Attenzione prima di proseguire è necessario salvare il cluster o eliminarlo',
      _VOID_: ' ',
      _ALIGN_APPLICATION_IN_PROGRESS_: 'Allineamento applicazioni in corso',
      _USER_: 'Utente',
      _BATCH_EXECUTION_: 'Esecuzione Batch',
      _REGULATION_: 'Regolazione',
      _TARIFF_MANAGEMENT_: 'Gestione Tariffa',
      _TECH_ACC_: 'Titoli',
      _BALANCE_ON_TODAY_: 'Saldo alla data odierna',
      _SUBSTITUTION_TYPE_: 'Tipo Sostituzione',
      _SUBST_TYPE_INCREMENTAL_: 'Incrementale',
      _SUBST_TYPE_FULL_: 'Completo',
      _RENUMBER_APPS_: 'Rienumera Applicazioni',
      _GENERATE_TECH_ACC_: 'Genera Titolo PM',
      _SPECIAL_PLATE_: 'Targa speciale / targa estera',
      _SUBSTITUITED_: 'Sostituita',
      _SUBSTITUENT_: 'Sostituente',
      _GENERIC_VARIATION_: 'Variazione Generica',
      _RENEWAL_PROPOSAL_: 'Proposta di rinnovo',
      _TO_RENEW_: 'Contratto da rinnovare',
      _FIXED_TEXT_: 'Testo fisso'
    },
    _MODAL_: {
      _OPERATION_: 'Operazione effettuata correttamente',
      _SAVED_: 'salvata correttamente',
      _SAVED_NO_APP_UPDATE_: 'salvata correttamente. Le applicazioni collegate alla PM non saranno aggiornate automaticamente.'
    },
    _PLACEHOLDER_: {
      _CLICK_HERE_: 'Clicca qui per aggiungere'
    },
    _TITLE_: {
      _APPLICATION_CONFIGURATION_: 'Configurazione Applicazione',
      _MP_AMENDMENT_: 'Variazione Polizza Madre',
      _BALANCE_SUMMARY_: 'Riepilogo Saldo',
      _SUBSTITUTED_POLICIES_LIST: 'Sostituzioni / Variazioni',
      _CONTRACTDETAIL_: 'Dettaglio Contratto n° ',
      _CLAUSES_: 'Clausole',
      _GUARANTEES_: 'Garanzie',
      _MEANS_PAYMENT_: 'Metodi di Pagamento',
      _POLICY_GENERAL_DATA_: 'Dati Di Polizza',
      _SUBJECTS_: 'Soggetti',
      _QUESTIONNAIRES_: 'Questionari',
      _DOCUMENTS_: 'Documenti',
      _APPLICATIONS_LIST_: 'Lista Applicazioni',
      _ACTIONS_: 'Azioni',
      _MOVEMENTS_: 'Movimenti',
      _DATA_: 'Dati',
      _MOD_INCLUSION_: 'Modalità inclusione',
      _SELECT_UPLOAD_DOCUMENT_: 'Seleziona e carica documento',
      _COINSURANCES_: 'Coassicurazioni',
      _COINSURANCE_SHARES_: 'Policy shares',
      _COINSURANCE_DELEGATED_: 'Policy Delegated',
      _EDIT_PAYMENT_: 'Modifica mezzo di pagamento',
      _NEW_PAYMENT_: 'Nuovo mezzo di pagamento',
      _POLICY_DATA_: 'Dati di polizza',
      _MP_CONFIGURATION_: 'Configurazioni',
      _ASSET_GUARANTEES_: 'Dati di bene',
      _SUMMARY_: 'Riepilogo',
      _ISSUE_: 'Emissione',
      _SEL_ASSETS_CAT_: 'Seleziona categorie bene',
      _INS_POL_NUM_: 'Inserimento numero polizza',
      _DATES_: 'Variazione Date',
      _VARIATION_: 'Variazione',
      _QUESTIONNAIRE_: 'Questionario',
      _CONFIRMS_: 'Conferma',
      _EDIT_SELECTION_: 'Modifica selezione',
      _ASSET_LIST_: 'Lista Beni',
      _CLUSTER_LIST_: 'Lista Cluster',
      _CLUSTER_DATA_: 'Dati Cluster',
      _VARIABLES_: 'Fattori',
      _ASSET_GUARANTEES_DATA_: 'Dati Bene e Garanzie',
      _AUTHORIZATION_REQUEST_: 'Richiesta Autorizzativa',
      _SELECTED_VALUES_: 'Valori selezionati',
      _COMMISSION_PERCENTAGE_FIRST_YEAR_: '% Provv. 1 anno',
      _COMMISSION_PERCENTAGE_NEXT_YEAR_: '% Prov. anni successivi',
      _COMMISSION_DISCOUNT_: 'Sconto',
      _COMMISSION_DISCOUNTS_: 'Sconti',
      _COMMISSION_RISK_: 'Rischi',
      _SYSTEM_MESSAGE_: 'Messaggio di sistema',
      _SUBSTITUTION_DATA_: 'Dati sostituzione'
    },
    _SUMMARY_: {
      _MANAGEMENT_NODE_: 'Nodo di gestione',
      _STATUS_: 'Stato',
      _PRODUCT_DESCRIPTION_: 'Descrizione del prodotto',
      _EFFECTIVE_DATE_: 'Data effettiva',
      _EXPIRY_DATE_: 'Data di Scadenza',
      _PAYMENT_FREQUENCY_: 'Frazionamento',
      _AGREEMENT_: 'Accordo',
      _DATE_OF_BIRTHDAY_: 'Data di nascita',
      _NAME_: 'Nome',
      _SURNAME_: 'Cognome',
      _COMPANY_NAME_: 'Nome compagnia',
      _PERSON_TYPE_: 'Tipo persona',
      _ID_PARTY_LOCK_: 'Id Party Lock',
      _ID_PARTY_: 'Id Party',
      _ROLE_: 'Ruolo',
      _DESCRIPTION_: 'Descrizione',
      _MAXIMUM_INSTANCES_: 'Istanze massime',
      _SHOW_PERCENTAGE_: 'Mostra Percentuale',
      _MINIMUM_PERCENTAGE_: 'Percentuale minima',
      _PERCENTAGE_: 'Percentuale',
      _LINKED_ROLES_: 'Linked Roles',
      _INITIAL_STATE: 'Stato Iniziale',
      _APPLICATIONS_LIST_: 'Lista Applicazioni'
    },
    _APPLICATIONS_: {
      _MANAGEMENT_NODE_: 'Nodo di gestione',
      _STATUS_: 'Stato',
      _PRODUCT_DESCRIPTION_: 'Descrizione del prodotto',
      _EFFECTIVE_DATE_: 'Data effetto',
      _EXPIRY_DATE_: 'Data di Scadenza',
      _ISSUE_DATE_: 'Data emissione',
      _POLICY_DESCRIPTION_: 'Descrizione',
      _POLICY_REFERENCE_: 'Numero Proposta / Polizza',
      _OPEN_DETAIL_: 'Apri dettaglio'
    },
    _QUOTATION_: {
      _GUARANTEE_: 'Garanzia',
      _PURE_PREMIUM_: 'Premio puro',
      _LOADING: 'Caricamenti',
      _OVER_PREMIUM_: 'Sovrappremi',
      _NET_: 'Netto',
      _INIT_PAYMENT_FREQ_: 'Interessi Di Frazionamento',
      _TAX_: 'Imposte',
      _RIGHT_: 'Diritti',
      _GROSS_: 'Premio Lordo',
      _TOTAL_: 'Totale',
      _FIRST_INSTALLMENT_: 'Rata Alla Firma',
      _NEXT_INSTALLMENT_: 'Rata Successiva',
      _ANNUAL_PREMIUM: 'Premio annuale',
      _DETAIL_: 'Dettaglio quotazione',
      _GUARANTEE_DETAILS_: 'Riepilogo Garanzie',
      _VIEW_: 'Visualizza',
      _GROSS_TITLE_: 'Rata alla Firma',
      _UNIT_INITIAL_STATUS_: 'Stato iniziale',
      _SSN_: 'SSN',
    },
    _FLOW_: {
      _EDIT_PROPOSAL: 'Modifica proposta n° ',
      _ISSUE_PROPOSAL_: 'Emissione proposta n° ',
      _VCONT_: 'Cambio Contraenza '
    },
    _MSG_: {
      _GUARANTEES_NOT_EXCLUDED: 'Selezionare almeno una garanzia con stato diverso da esclusa',
      _MANDATORY_FIELD_: 'Campo obbligatorio',
      _INVALID_FIELD_: 'Campo non valido',
      _ISSUED_CORRECTLY_: 'emessa correttamente',
      _OUT_OF_RANGE_: 'Valore fuori range',
      _MAX_LESS_THAN_MIN_: 'Inferiore al minimo',
      _MIN_GREATER_THAN_MAX_: 'Maggiore del massimo',
      _UNIT_SELECTION_WARNING_: 'Selezionare almeno una garanzia',
      _BALANCE_RECALCULATE_WARNING_: 'Il calcolo potrebbe non essere aggiornato. ' +
        'Allineare le applicazioni e ricalcolare il saldo per visualizzare i dati aggiornati',
      _BALANCE_SUMMARY_WARNING_: 'Saldo non disponibile. Assicurarsi di aver incluso almeno un applicazione ' +
        'ed eseguire il calcola saldo per visualizzare i dati.',
      _SUBST_INCREMENTAL_DISCLAIMER_: 'Attenzione! Le applicazioni verranno aggiunte a quelle già in essere.',
      _SUBST_FULL_DISCLAIMER_: 'Attenzione! Le applicazioni già presenti verranno cancellate.',
      _BATCH_SCHEDULED_OK_: 'Il sistema ha schedulato il processo batch correttamente.',
      _BATCH_SCHEDULED_KO_: 'Il sistema ha riscontrato un problema nella schedulazione del processo batch.',
      _CONFIRM_CHECK_APPLICATION_: 'Questo contratto ha applicazioni da autorizzare, vuoi continuare comunque con l\'emissione?',
      _CONFIRM_CLUSTER_CANCELLATION_: 'Attenzione, tutte le proposte e le polizza con effetto futuro verranno annullate.'
    }
  },
  _GP_TITLE_GROUP_POLICIES_: 'Emissione Polizza Madre',
};

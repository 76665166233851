<div class="ppevo-survey-eval-coverage" [ngClass]="cfg.styleClassScore" [class.ppevo-selected]="coverage.selected" [attr.data-qa]="dataQaType">

    <div class="ppevo-title">{{coverage.name}}</div>
    <div *ngIf="cfg.canShowInfo" class="ppevo-icon-info ppevo-info" (click)="onClickInfo()" title="{{'NG_PASSPROPRO_CORE.information'|translate}}"></div>
    <div class="ppevo-score-box" survey-eval-score [scoreObj]="coverage" [scoreVisible]="cfg.canShowScore" [scoreStyleClass]="cfg.styleClassScore"></div>

    <div class="ppevo-break"></div>

    <div *ngIf="validation" class="ppevo-validation-cov-pkg">
        <div *ngFor="let valMsg of validation.messages"
            class="ppevo-validation-message"
            [ngClass]="valMsg.styleClass"
        >{{valMsg.msg}}</div>
    </div>

    <div *ngIf="showPrice" class="ppevo-price-box">
        <div class="ppevo-label" translate>NG_PASSPROPRO_SURVEY.survey-evaluate_pricestartingfrom</div>
        <div class="ppevo-price">
            <span class="ppevo-value">{{price | number : '1.2-2'}}</span>
            <span class="ppevo-currency" translate>NG_PASSPROPRO_SURVEY.currency</span>
        </div>
    </div>

    <div [ngClass]="{'ppevo-non-selectable':!cfg.selectionVisible}" class="ppevo-select-box">
        <input type="checkbox" [checked]="coverage.selected" (change)="onSelect($event.target.checked)" [disabled]="isDisabled()"/>
    </div>

</div>

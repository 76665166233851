import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OnModalClose} from '@rgi/rx/ui';
import {InstalmentPremiumGroup} from '../../../../models/domain-models/instalment-premium-group';
import {AmountByCover} from '../../../../models/domain-models/amount-by-cover';
import {Currency} from '../../../../models/domain-models/parameters/currency';
import {LpsData} from '../../../../models/domain-models/parameters/policy-lps-data';
import {TableRow} from './re-issue-table/re-issue-table.component';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 'lib-re-issue-contribution-taxes-modal',
  templateUrl: './re-issue-contribution-taxes-modal.component.html',
  styleUrls: ['./re-issue-contribution-taxes-modal.component.css']
})
export class ReIssueContributionTaxesModalComponent implements OnInit, OnModalClose {

  @ViewChild('signInstalmentTable') signInstalmentTable: any;
  @ViewChild('nextInstalmentTable') nextInstalmentTable: any;

  @Input() premiumInstallmentGroup: InstalmentPremiumGroup;
  @Input() annualPremiumGroup: InstalmentPremiumGroup;
  @Input() premiumSubscriptionInstalmentGroup: InstalmentPremiumGroup;
  @Input() currency: Currency;
  @Input() lpsData: LpsData;
  @Output() lpsDataOutput = new EventEmitter();
  @Output() modalClose = new EventEmitter();

  totalSignAntiRacket: number;
  totalNextAntiRacket: number;
  totalAnnualAntiRacket: number;

  lpsSignInstalmentAmount: number;
  lpsNextInstalmentAmount: number;

  superHeader = ['emptyHeader', 'taxableHeader', 'insuranceTaxHeader', 'grossHeader'];
  displayedColumns = ['label', 'taxable', 'rate', 'taxes', 'taxAntiMoneyLaundering', 'gross'];

  SUMMARY_SIGN_INSTALMENT_TABLE: TableRow[] = [];
  SUMMARY_NEXT_INSTALMENT_TABLE: TableRow[] = [];
  SUMMARY_ANNUAL_PREMIUM_TABLE: TableRow[] = [];

  RIGHTS_LABEL: string;
  LPS_LABEL: string;

  constructor(private translateService: RgiRxTranslationService) {
  }

  ngOnInit() {
    this.totalSignAntiRacket = this.calculateAntiRacket(this.premiumSubscriptionInstalmentGroup.amountByCover);
    this.totalNextAntiRacket = this.calculateAntiRacket(this.premiumInstallmentGroup.amountByCover);
    this.totalAnnualAntiRacket = this.calculateAntiRacket(this.annualPremiumGroup.amountByCover);
    this.lpsSignInstalmentAmount = this.premiumSubscriptionInstalmentGroup.lpsSignInstalmentAmount;
    this.lpsNextInstalmentAmount = this.premiumInstallmentGroup.lpsNextInstalmentAmount;

    this.translateService.translate('RE_ISSUE.RIGHTS').subscribe(label => {
      this.RIGHTS_LABEL = label;
    });
    this.translateService.translate('RE_ISSUE.LPS').subscribe(label => {
      this.LPS_LABEL = label;
    }).unsubscribe();

    this.initializeSignInstalmentTable();
    this.initializeNextInstalmentTable();
    this.initializeAnnualPremiumTable();
  }

  private initializeSignInstalmentTable() {
    this.SUMMARY_SIGN_INSTALMENT_TABLE = this.createSummaryTable(
      this.premiumSubscriptionInstalmentGroup.amountByCover,
      this.premiumSubscriptionInstalmentGroup.fees.commissions,
      this.lpsSignInstalmentAmount
    );
  }

  private initializeNextInstalmentTable() {
    this.SUMMARY_NEXT_INSTALMENT_TABLE = this.createSummaryTable(
      this.premiumInstallmentGroup.amountByCover,
      this.premiumInstallmentGroup.fees.commissions,
      this.lpsNextInstalmentAmount
    );
  }

  private initializeAnnualPremiumTable() {
    this.SUMMARY_ANNUAL_PREMIUM_TABLE = this.createSummaryTable(
      this.annualPremiumGroup.amountByCover,
      this.annualPremiumGroup.fees.commissions,
      0
    );
  }

  private createSummaryTable(coverList: Array<AmountByCover>, commissions: string, lpsAmount: number) {
    const summaryTable: TableRow[] = coverList.map(amount => ({
      label: amount.description,
      taxable: amount.taxable,
      rate: amount.rate + '%',
      taxes: amount.taxes,
      taxAntiMoneyLaundering: amount.antiracket,
      gross: amount.gross,
    }));

    summaryTable.push({
      label: this.RIGHTS_LABEL,
      taxable: commissions,
      rate: null,
      taxes: commissions,
      taxAntiMoneyLaundering: null,
      gross: commissions,
    });

    if (this.hasLps(lpsAmount)) {
      summaryTable.push({
        label: this.LPS_LABEL,
        taxable: null,
        rate: null,
        taxes: lpsAmount.toString(),
        taxAntiMoneyLaundering: null,
        gross: lpsAmount.toString(),
      });
    }

    return summaryTable;
  }

  private hasLps(lpsAmount: number): boolean {
    return this.lpsData.policyLpsData.lpsType !== '4' && this.lpsData.policyLpsData.lpsType !== '0' && lpsAmount > 0;
  }

  calculateAntiRacket(coverList: Array<AmountByCover>): number {
    return coverList.reduce((total, cover) => total + Number(cover.antiracket), 0);
  }

  updatePremiumInstallmentGroup(group: InstalmentPremiumGroup) {
    this.premiumInstallmentGroup = group;
    this.totalNextAntiRacket = this.calculateAntiRacket(this.premiumInstallmentGroup.amountByCover);
    this.lpsNextInstalmentAmount = this.premiumInstallmentGroup.lpsNextInstalmentAmount;
    this.initializeNextInstalmentTable();
  }

  updatePremiumSubscriptionInstalmentGroup(group: InstalmentPremiumGroup) {
    this.premiumSubscriptionInstalmentGroup = group;
    this.totalSignAntiRacket = this.calculateAntiRacket(this.premiumSubscriptionInstalmentGroup.amountByCover);
    this.lpsSignInstalmentAmount = this.premiumSubscriptionInstalmentGroup.lpsSignInstalmentAmount;
    this.initializeSignInstalmentTable();
  }

  updateAnnualPremiumGroup(group: InstalmentPremiumGroup) {
    this.annualPremiumGroup = group;
    this.totalAnnualAntiRacket = this.calculateAntiRacket(this.annualPremiumGroup.amountByCover);
  }

  calculateAndUpdate() {
    if (this.signInstalmentTable && this.nextInstalmentTable) {
      this.lpsData.policyLpsData.rsAmount = this.nextInstalmentTable.lpsTax.toString();
      this.lpsData.policyLpsData.lpsAmount = this.signInstalmentTable.lpsTax.toString();
      this.lpsDataOutput.emit(this.lpsData);
    }
  }

  calculateDefaultLps() {
    this.lpsData.policyLpsData.rsAmount = null;
    this.lpsData.policyLpsData.lpsAmount = null;
    this.lpsDataOutput.emit(this.lpsData);
  }

  close() {
    this.modalClose.emit();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { RgiCountryLayerModule } from '@rgi/country-layer';
import { LpcLiquidationComponent } from './lpc-liquidation.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcLiquidationComponent],
    exports: [LpcLiquidationComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RgiCountryLayerModule,
        RgiRxI18nModule
    ]
})
export class LpcLiquidationModule { }

<!-- <div class="re-issue-modal"> -->
<div class="issue-card-modal-content">
  <div class="container-fluid">
    <div class="issue-card-modal-header">
      <h3 class="modal-title">
        <span translate>RE_ISSUE.ISSUE_CONFIRMATION</span>
      </h3>
    </div>
    <div class="issue-card-modal-body">
      <div class="row" style="text-align: center;">
        <!-- <div class="core-header-icon"> -->
        <div class="col-sm-12 col-xs-12" style="text-align: center;">
                            <span class="rgi-re-header-icon rgifont rgi-pagamento nfm_font_size">
                                <img height="30" width="30"
                                     class="credit-card-payment-image" src="../../../../assets/img/pin.svg" alt="pin"/></span>
          <span class="header-text" translate>RE_ISSUE.PIN_ENTRY</span>&nbsp;
          <span class="pull-right header-text"> </span>
        </div>
        <!-- </div> -->
        <!-- <div class="col-sm-3 col-xs-3"></div> -->
      </div>
      <div class="row" style="text-align: center;">
        <div class="col-sm-12 col-xs-12">
          <h4 translate> RE_ISSUE.ENTER_PIN_TO_CONFIRM_POLICY_ISSUE <span
            style="color: red;">*</span></h4>
        </div>
      </div>
      <div class="row" style="text-align: center;">
        <div class="col-sm-5 col-xs-5"></div>
        <div class="col-sm-2 col-xs-2">
          <input #pinInput type="text" pattern="[0-9]*" minlength="1" maxlength="4" [(ngModel)]="pinValue"
                 class="form-control mic-input">
          <!-- <h4 style="text-align: center;" translate> Enter your PIN to confirm policy issue: <h4 style="color: red;">*</h4> </h4> -->
        </div>
        <!-- <div class="col-sm-5 col-xs-5"></div> -->
      </div>
      <div class="row mt-4">
        <div class="col-sm-12 col-xs-12">
          <div class="btn-group btn-group-justified">
            <div class="btn-group">
              <button rgi-rx-button type="button" color="primary" class="rgi-re-button"
                      (click)="confirmPurchase()" translate> RE_ISSUE.CONFIRM_PURCHASE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->

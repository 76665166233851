import { Injectable } from '@angular/core';
import { DomainField } from '../../model/domain/claim/domain-field';
import { FieldDependency } from '../../model/domain/claim/domain-field-dependency';
import { Field } from '../../dto/claim/field';

@Injectable({
  providedIn: 'root'
})
export class DomainTranslatorFieldService {

  constructor() { }

  toFields(domainFields: DomainField[]) {
    const fields = [];
    const tempFields = [];
    const that = this;

    domainFields.forEach((domainField) => {
      const field = that.toField(domainField);
      tempFields[field.id] = field;
      fields.push(field);
    });

    this.manageDependencies(domainFields, tempFields);

    return fields;
  }

  private manageDependencies(domainFields: DomainField[], tempFields: Field[]) {
    domainFields.forEach((domainField) => {
      const parent: Field = tempFields[domainField.id];
      if (domainField.visualizationdependencies) {
        domainField.visualizationdependencies.forEach((dependency: FieldDependency) => {
          parent.addChild(dependency.value, tempFields[dependency.fieldid]);
        });
      }
    });
  }

  toDomainFields(fields: Field[]): DomainField[] {
    const domainFields = [];
    const that = this;

    fields.forEach((field: Field) => {
      const domainField = that.toDomainField(field);
      domainFields.push(domainField);
    });

    return domainFields;
  }

  toField(el: DomainField) {
    const field = new Field();

    field.id = el.id;
    field.type = el.type;
    field.label = el.label;
    field.combovalues = el.comboValues;
    field.mandatory = el.mandatory === 'Y';
    field.numberofdecimals = el.numberOfDecimals;
    field.value = el.value && !(el.type === 'CHECKBOX' && el.value === 'false') ? el.value : null;
    // TODO complete fields
    return field;
  }

  toDomainField(el: Field): DomainField {
    const domainField = new DomainField();

    domainField.id = el.id;
    domainField.value = el.value;
    domainField.label = el.label;
    domainField.type = el.type;
    if (el.type === 'CHECKBOX' && !el.disabled && !el.value) {
      domainField.value = 'false';
    }
    return domainField;
  }

}

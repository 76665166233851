import { POST_SALES_SESSION_NAME } from '../models/consts/lpc-consts';
import { PostsalesOperationKey, PostsalesOperationUrl } from '../models/postsales-session';

export class PlcOperationUtils {

public static getOperationUrls(): PostsalesOperationUrl {
    return {
      _VRECP: 'change-contact',
      _VTEXT: 'declarative-appendix',
      _VCONT: 'manage-roles',
      _VBENE: 'beneficiary',
      _ANPSE: 'non-completion',
      _VAGG: 'additional-payment',
      _RISPA: 'partial-surrender',
      _VMODP: 'payment',
      _ANREC: 'revocation',
      _ESANT: 'total-early-repurchase',
      _ESANP: 'partial-early-repurchase',
      _SIMOR: 'death-claim',
      _VARFR: 'payment-frequency',
      _VGARI: 'insert-warranties',
      _SOVRP: 'additional-premium',
      _VRESI: 'termination',
      _VGARD: 'remove-warranties',
      _VVARC: 'amount-variation',
      _VPRIN: 'renewal-reservation',
      _VPRIR: 'reservation-termination',
      _ARESI: 'terminate-rescission',
      _DTOOL: 'delete-tool',
      _ATOOL: 'activate-tool',
      _MTOOL: 'modify-tool',
      _CCOMB: 'change-combination',
      _AANPS: 'cancel-non-completion',
      _VBLIQ: 'change-settlement-benef',
      _ANAPP: 'endorsement-cancel',
      _APEGN: 'lien-open',
      _AVINC: 'assignment-open',
      _RISTO: 'total-surrender',
      _CVINC: 'assignment-close',
      _CPEGN: 'assignment-close',
      _SWITC: 'switch',
      _TRFNO: 'transfer-policy',
      _VFIGU: 'change-anag-roles',
      _SCOPZ: 'expiry-options-choice',
      _ARATA: 'cancel-installment',
      _RIDUZ: 'reduction',
      _INPAG: 'premium-payment-suspension',
      _AANRE: 'withdrawal-cancellation',
      _RIAPU: 'premium-payment-reactivation',
      _ASIMO: 'death-claim-cancellation',
      _VPRFR: 'premium-payment-variation',
      _RECOS: 'confirmation-chosen-rent',
      _ASCAD: 'cancel-expiry',
      _SINVA: 'generic-claim',
      _SIMAG: 'generic-claim',
      _SINAB: 'generic-claim',
      _CPRES: 'loan-grant',
      _ARIST: 'total-surrender-cancellation',
      _ARIDU: 'reduction-cancellation',
      _RIARI: 'reduction-reactivation',
      _RPRES: 'loan-refund',
      _ARISP: 'partial-surrender-cancellation',
      _CCACC: 'change-coupon-credit',
      _AVGAD: 'remove-warranty-cancellation',
      _AVGAI: 'insert-warranty-cancellation',
      _RIARE: 'termination-reactivation',
      _ASINV: 'disability-claim-cancellation',
      _ADIFF: 'cancel-deferment',
      _MDATI: 'update-party',
      _ASINA: 'inability-claim-cancellation',
      _ASIMA: 'serious-illness-claim-cancellation',
      _INESC: 'convention-insertion-exclusion',
      _RTANT: 'early-total-surrender',
      _TRRIS: 'reserve-transfer',
      _TRAZA: 'fiscal-data-transfer',
      _ARINV: 'renewal-cancellation',
      _AFV22: 'tax-relief-2022',
      _TROUT: 'transfer-to-other-fund',
      _ANTIC: 'advance-payment',
      _VVCLA: 'clauses-variation',
      _ARTAN: 'artan-cancellation',
      _RTQUI: 'pension-buyback',
      _AANTI: 'advance-payment-cancellation',
      _ATROU: 'transfer-to-other-funds-cancellation',
      _ATRRI: 'reserve-transfer-cancellation',
      _ARTQU: 'pension-buyback-cancellation',
      _ACPND: 'undeducted-premiums-acquisition',
    };
  }


  public static getOperationConstants(): Map<PostsalesOperationKey, string> {
    return new Map([
      ['_VRECP', POST_SALES_SESSION_NAME],
      ['_VTEXT', POST_SALES_SESSION_NAME],
      ['_VCONT', POST_SALES_SESSION_NAME],
      ['_ANPSE', POST_SALES_SESSION_NAME],
      ['_VBENE', POST_SALES_SESSION_NAME],
      ['_VAGG', POST_SALES_SESSION_NAME],
      ['_RISPA', POST_SALES_SESSION_NAME],
      ['_RISTO', POST_SALES_SESSION_NAME],
      ['_SWITC', POST_SALES_SESSION_NAME],
      ['_VMODP', POST_SALES_SESSION_NAME],
      ['_ANREC', POST_SALES_SESSION_NAME],
      ['_ESANT', POST_SALES_SESSION_NAME],
      ['_ESANP', POST_SALES_SESSION_NAME],
      ['_SIMOR', POST_SALES_SESSION_NAME],
      ['_VARFR', POST_SALES_SESSION_NAME],
      ['_VGARI', POST_SALES_SESSION_NAME],
      ['_SOVRP', POST_SALES_SESSION_NAME],
      ['_VRESI', POST_SALES_SESSION_NAME],
      ['_VGARD', POST_SALES_SESSION_NAME],
      ['_VVARC', POST_SALES_SESSION_NAME],
      ['_VPRIN', POST_SALES_SESSION_NAME],
      ['_VPRIR', POST_SALES_SESSION_NAME],
      ['_ARESI', POST_SALES_SESSION_NAME],
      ['_DTOOL', POST_SALES_SESSION_NAME],
      ['_ATOOL', POST_SALES_SESSION_NAME],
      ['_MTOOL', POST_SALES_SESSION_NAME],
      ['_CCOMB', POST_SALES_SESSION_NAME],
      ['_AANPS', POST_SALES_SESSION_NAME],
      ['_VBLIQ', POST_SALES_SESSION_NAME],
      ['_ANAPP', POST_SALES_SESSION_NAME],
      ['_APEGN', POST_SALES_SESSION_NAME],
      ['_AVINC', POST_SALES_SESSION_NAME],
      ['_CPEGN', POST_SALES_SESSION_NAME],
      ['_CVINC', POST_SALES_SESSION_NAME],
      ['_TRFNO', POST_SALES_SESSION_NAME],
      ['_VFIGU', POST_SALES_SESSION_NAME],
      ['_SCOPZ', POST_SALES_SESSION_NAME],
      ['_ARATA', POST_SALES_SESSION_NAME],
      ['_RIDUZ', POST_SALES_SESSION_NAME],
      ['_INPAG', POST_SALES_SESSION_NAME],
      ['_AANRE', POST_SALES_SESSION_NAME],
      ['_RIAPU', POST_SALES_SESSION_NAME],
      ['_VPRFR', POST_SALES_SESSION_NAME],
      ['_ASIMO', POST_SALES_SESSION_NAME],
      ['_RECOS', POST_SALES_SESSION_NAME],
      ['_ASCAD', POST_SALES_SESSION_NAME],
      ['_SINVA', POST_SALES_SESSION_NAME],
      ['_SIMAG', POST_SALES_SESSION_NAME],
      ['_SINAB', POST_SALES_SESSION_NAME],
      ['_CPRES', POST_SALES_SESSION_NAME],
      ['_ARIST', POST_SALES_SESSION_NAME],
      ['_ARIDU', POST_SALES_SESSION_NAME],
      ['_RIARI', POST_SALES_SESSION_NAME],
      ['_RPRES', POST_SALES_SESSION_NAME],
      ['_ARISP', POST_SALES_SESSION_NAME],
      ['_CCACC', POST_SALES_SESSION_NAME],
      ['_AVGAD', POST_SALES_SESSION_NAME],
      ['_AVGAI', POST_SALES_SESSION_NAME],
      ['_RIARE', POST_SALES_SESSION_NAME],
      ['_ASINV', POST_SALES_SESSION_NAME],
      ['_ADIFF', POST_SALES_SESSION_NAME],
      ['_MDATI', POST_SALES_SESSION_NAME],
      ['_ASINA', POST_SALES_SESSION_NAME],
      ['_ASIMA', POST_SALES_SESSION_NAME],
      ['_INESC', POST_SALES_SESSION_NAME],
      ['_RTANT', POST_SALES_SESSION_NAME],
      ['_TRRIS', POST_SALES_SESSION_NAME],
      ['_TRAZA', POST_SALES_SESSION_NAME],
      ['_ARINV', POST_SALES_SESSION_NAME],
      ['_AFV22', POST_SALES_SESSION_NAME],
      ['_TROUT', POST_SALES_SESSION_NAME],
      ['_ANTIC', POST_SALES_SESSION_NAME],
      ['_VVCLA', POST_SALES_SESSION_NAME],
      ['_ARTAN', POST_SALES_SESSION_NAME],
      ['_RTQUI', POST_SALES_SESSION_NAME],
      ['_AANTI', POST_SALES_SESSION_NAME],
      ['_ATROU', POST_SALES_SESSION_NAME],
      ['_ATRRI', POST_SALES_SESSION_NAME],
      ['_ARTQU', POST_SALES_SESSION_NAME],
      ['_ACPND', POST_SALES_SESSION_NAME]
    ]);
  }
}











<div class="example-row goods-info-page" id="goods-info-page">
  <form [formGroup]="form">

    <div rgi-rx-accordion [multi]="true">
      <div *ngFor="let insured of insuredIstances; let i = index">
        <ng-container *ngIf="insured.factors.length > 0">

          <rgi-rx-expansion-panel [expanded]="true" color="default">
            <div rgi-rx-expansion-panel-header>
              <span data-qa="panel-button" class="rgifont"></span>
              <span attr.data-qa="dati-amm-label" class="header-text"> <span translate>lic_insured_role</span>{{insured.extraDescription ? ': ' + insured.extraDescription : '' }} </span>
            </div>
            <div content>
              <div class="panel-body">
                <!-- FATTORI -->
                <lic-factors (changeValEmitter)="updateFactors(false)"
                            [parentForm]="form.controls[insured.name]"
                            [fattori]="getInsuredFactor(insured)">
                </lic-factors>
              </div>
            </div>
          </rgi-rx-expansion-panel>
        </ng-container>
        <br><br>

        <div *ngIf="showAccordionQuestionnaires && (showSectionQuest || showQuestPPEVO)" class="example-row" id="panel-quest">
          <rgi-rx-expansion-panel [expanded]="true" color="default">
            <div rgi-rx-expansion-panel-header>
              <span data-qa="panel-button" class="rgifont"></span>
              <span class="header-icon rgifont rgi-questionari"></span>
              <span class="header-text" data-qa="accordion-questionnaires" translate>lic_questionnaires_section</span>
            </div>
            <div content>
              <div class="panel-body">
                <lic-questionnaires
                  (emitSave)="saveQuestionnaire($event)"
                  (emitSaveOnInit)="saveQuestionnaire($event, true)"
                  (emitDelete)="deleteQuestionnaire($event)"
                  [questionnairesList]="questionnairesList"
                  [customFormGroup]="questForm"
                  (loaderQuest)="loaderQuestManager($event)">
                </lic-questionnaires>
                <div *ngFor="let insured of insuredIstances; let i = index">
                  <lib-questionnaires-manager
                      [modalClass]="'life-quest-modal-flow'"
                      data-qa="questAsset"
                      class="col-lg-6"
                      key={{insured.name}}
                      d={{effectiveDate}}
                      customQuestLoader="BY_CODE"
                      [questionnairesCode]="validQuestsCode"
                      [savedIds]="getSavedUUIDs(insured)"
                      (listLoad)="countQuest()"
                      (loaderQuest)="loaderQuestManager($event)">
                  </lib-questionnaires-manager>
                </div>
              </div>
            </div>
          </rgi-rx-expansion-panel>
        </div>

      </div>
    </div>

<rgi-rx-modal #proposalSaveModal>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="proposalSaveModal.close()">{{'lic_NumeroProposta' | translate}}</rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="proposalSaveModal.close()" translate>lic_close</button>
    </rgi-rx-panel-footer>
    <span translate>lic_Proposal_Saved</span> {{proposalNumber}}
  </rgi-rx-panel>
</rgi-rx-modal>


    <!-- MESSAGGI di ERRORE -->
    <!-- <pnc-floating-error-messages [validationMessages]="getValidationMessages"
                                 [blockingMsgs]="getBlockingMessages"
                                 [warningMsgs]="getWarningMessages"
                                 [authoMsgs]="getAuthMessages">
    </pnc-floating-error-messages> -->

    <rgi-rx-snackbar tag="validation"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="blocking"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="warning"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="auth"></rgi-rx-snackbar>

      <div class="btn-group btn-group-justified">
        <div class="btn-group" *ngIf="showSaveButton()">
          <button type="submit"
                  class="btn btn-warning pull-left btn-secondary text-uppercase"
                  (click)="onSubmit(true, proposalSaveModal.open())" translate>lic_save</button>
      </div>
        <div class="btn-group">
          <button type="submit"
                  (click)="onSubmit()"
                  data-qa="lic-goods-submit"
                  class="btn btn-warning pull-right text-uppercase"
                  translate>
                  lic_NEXT
          </button>
        </div>
      </div>

  </form>
</div>

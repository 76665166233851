import { Injectable, OnInit } from '@angular/core';
import { IDDProduct } from '../models/iddProduct.model';

@Injectable({
    providedIn: 'root'
  })

export class IddProductService implements OnInit {

    protected iddProduct: IDDProduct = null;

    constructor() { }

    ngOnInit(): void { }

    setIddProduct(iddProduct: IDDProduct) {
        this.iddProduct = iddProduct;
    }

    getAdequateWarranties(): string[] {
      const adequateWarranties: string[] = [];

      if (this.iddProduct && this.iddProduct.sections && this.iddProduct.sections.length > 0) {
        this.iddProduct.sections.forEach(sec => {

            if (sec.coverages && sec.coverages.length > 0) {
              sec.coverages.forEach(coverage => {

                if (coverage.targetmarket && coverage.targetmarket.length > 0) {
                    coverage.targetmarket.forEach(tm => {

                        if (tm && tm.score > 0) {
                            adequateWarranties.push(tm.description);
                        }
                    });
                }
              });
          }
        });
      }
      return adequateWarranties;
    }
}


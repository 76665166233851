import {TableSchema} from '@rgi/rx/ui';
import {numberPipe} from '../../../pipes/country-layer-number-format-datatable';

export const TECH_ACCOUNTING_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'node',
      title: '_RGIPNC_._LABEL_._NODE_'
    },
    {
      name: 'appendix',
      title: '_RGIPNC_._LABEL_._APPENDIX_'
    },
    {
      name: 'status',
      title: '_RGIPNC_._LABEL_._STATUS_'
    },
    {
      name: 'type',
      title: '_RGIPNC_._LABEL_._TYPE_'
    },
    {
      name: 'currency',
      title: '_RGIPNC_._LABEL_._CURRENCY_'
    },
    {
      name: 'amount',
      title: '_RGIPNC_._LABEL_._AMOUNT_',
      format: numberPipe
    },
    {
      name: 'commissions',
      title: '_RGIPNC_._LABEL_._COMMISSIONS_',
      format: numberPipe
    },
    {
      name: 'effect',
      title: '_RGIPNC_._LABEL_._EFFECT_DATE_'
    }
  ],
  header: ['node', 'appendix', 'status', 'type', 'currency', 'amount', 'commissions', 'effect']
};

import { Toponym } from './toponym';

export class DamageLocation {
    public principal: boolean;
    public formatAddress: string;
    public address: string;
    public houseNumber: string;
    public zipCode: string;
    public city: string;
    public adminLevel3: string;
    public province: string;
    public adminLevel2: string;
    public nationAbbreviation: string;
    public idToponym: Toponym;
    constructor() { }
}

import {HttpHeaders, HttpRequest} from '@angular/common/http';

export class MicHttpDisableErrorInterceptorService {

  constructor() { }


  static DISABLE_ERR_INTERCEPT_HEADER = 'X-Disable-Error-Interceptor';

  static getErrorInterceptorDisable(): HttpHeaders {
    return new HttpHeaders().append(MicHttpDisableErrorInterceptorService.DISABLE_ERR_INTERCEPT_HEADER, 'true');
  }

  static isErrorInterceptorDisable(request: HttpRequest<any>): boolean {
    return request.headers.has(MicHttpDisableErrorInterceptorService.DISABLE_ERR_INTERCEPT_HEADER);
  }

}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {AutomaticRenewal} from '../../../models/domain-models/automatic-renewal';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioAutomaticRenewalResourceService {
  private PROPOSALS_PATH = 'proposals/';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;
  }

  putAutomaticRenewal(proposalId: string, automaticRenewal: boolean): Observable<AutomaticRenewal> {
    const body = {value: automaticRenewal, status: null};
    return this.http.put<AutomaticRenewal>(
      this.getAutomaticRenewalUri(proposalId),
      body
    ).pipe(
      share()
    );
  }

  getAutomaticRenewalUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/renewal';
  }
}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <span class="rgifont rgi-dettagli_tecnici"></span>
{{ '_CLAIMS_._UPLOAD' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
        </div>
        <div class="modal-body" style="padding-top: 5px">
            <form [formGroup]="uploadForm" data-qa="document-upload-form">
                <div class="container-fluid">
                    <div class="form-group">
                        <div class="box-input" *ngIf="!fileLoaded" claimsDragAndDrop (dndDrop)="fileDropped($event)">
                            <input type="file" (change)="fileChange($event)" placeholder="Upload file"
                                formControlName="file" id="id" name="id" class="box-file">
                            <label for="id" class="box-upload-label">
                                <span class="clearfix"><span
                                        class="box-upload-icon glyphicon glyphicon-cloud-upload"></span></span>
                                <span class="box-dragndrop clearfix">{{ '_CLAIMS_._MESSAGE_._DRAG_A_FILE_TO_UPLOAD' | translate }}</span>
                                <span><strong class="text-blue-upload">{{'_CLAIMS_._CLICK_HERE' | translate }}</strong>
                                    {{'_CLAIMS_._TO_SELECT' | translate }} </span>
                            </label>
                        </div>

                        <div class="upload-file-block" *ngIf="fileLoaded">
                            <div class="row upload-file-block-inside">
                                <div class="col-sm-1">
                                    <div class="document-upload-type">
                                        <span class="rgifont document-icon"
                                            [ngClass]="{ 'rgi-doc-file-format': !pdfType, 'rgi-pdf-file-format-symbol': pdfType}">
                                        </span>
                                    </div>
                                </div>

                                <div class="col-sm-10">
                                    <div class="document-upload-name">{{fileName}}</div>
                                </div>

                                <div class="col-sm-1">
                                    <button type="button" class="btn btn-link" (click)="unloadFile($event)">
                                        <span class="rgifont rgi-trash upload-doc-font"></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="hasError" class="error-container">
                            <pnc-form-message type="error" detail="validationError" [messages]="errorMessages"
                                data-qa="upload-modal-error-messages"></pnc-form-message>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!-- invoice fields start -->
        <div class="container-fluid table-white">
	
            <div class="party-list">
                <div class="col-md-12 col-sm-12 col-xs-12 panel-heading wf-party-header">
                    <span class="rgifont rgi-user"></span>
                         {{'_CLAIMS_._EXPERT_INVOICE' | translate}} -
                    <!-- <span class="bold">{{legalCode}}</span> - -->
                    <span class="bold">{{invoice.invoiceExpert.name}} {{invoice.invoiceExpert.surname}} {{invoice.invoiceExpert.title}}</span>
                       -  {{'_CLAIMS_._TAX_CODE__VAT_NUMBER' | translate}}  
                    <span class="bold">{{invoice.invoiceExpert.taxId}}</span>
                </div>
        
                <div class="table-white wf-list">
                
                    <div class="row overflowVisible">
                    
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._INVOICE_NUMBER' | translate}}<span>: {{invoice.invoiceNumber}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._INVOICE_DATE' | translate}}<span>: {{invoice.invoiceDate | date:'shortDate' }}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._INVOICE_TYPE' | translate}}<span>: {{invoice.invoiceType.descrizione}}</span>
                        </div>
    
                    </div>
    
                    <div class="row overflowVisible">
                    
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._SAP_CODE' | translate}}<span>: {{invoice.sapCode}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._SAP_RECORDING_DATE' | translate}}<span>: {{invoice.sapRecordingDate | date:'shortDate' }}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4" ng-if="invoice.invoiceType.codice==1">
                            {{'_CLAIMS_._SDI_CODE' | translate}}<span>: {{invoice.sdiCode}}</span>
                        </div>
    
                    </div>
    
                    <div class="row overflowVisible">
                    
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._TAXABLE_AMOUNT' | translate}}<span>: {{(invoice.taxableAmount | currency:'EUR':true) || '-'}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._NOT_TAXABLE_AMOUNT' | translate}}<span>: {{(invoice.notTaxableAmount | currency:'EUR':true) || '-'}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._AMOUNT_HOLD_TAX' | translate}}<span>: {{(invoice.amountHoldTax | currency:'EUR':true) || '-'}}</span>
                        </div>
    
                    </div>
    
                    <div class="row overflowVisible">
                    
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._CASH_PREVIDENTIAL_AMOUNT' | translate}}<span>: {{(invoice.cashPrevidAmount | currency:'EUR':true) || '-'}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._VAT_AMOUNT'| translate}}<span>: {{(invoice.vatAmount | currency:'EUR':true) || '-'}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._STAMP_DUTY_AMOUNT' | translate}}<span>: {{(invoice.stampDutyAmount | currency:'EUR':true) || '-'}}</span>
                        </div>
    
                    </div>
    
                    <div class="row overflowVisible">
                    
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._INVOICE_AMOUNT' | translate}}<span>: {{(invoice.invoiceAmount | currency:'EUR':true) || '-'}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._AMOUNT_TO_BE_PAYD'| translate}}<span>: {{(invoice.amountToBePayd | currency:'EUR':true) || '-'}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._INPS_CONTRIB_AMOUNT' | translate}}<span>: {{(invoice.inpsContribAmount | currency:'EUR':true) || '-'}}</span>
                        </div>
    
                    </div>
    
                    <div class="row overflowVisible">
                    
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            {{'_CLAIMS_._ROUNDING_AMOUNT' | translate}}<span>: {{(invoice.nRoundingCMount | currency:'EUR':true) || '-'}}</span>
                        </div>
                        
                        <div class="col-md-4 col-sm-4 col-xs-4">
                        </div>
    
                        <div class="col-md-4 col-sm-4 col-xs-4">
                        </div>
    
                    </div>
    
                </div>
            </div>
            
        </div>
        <!-- invoice fields end -->
        <br>

        <span *ngIf="validationMessages && validationMessages.length > 0" class="col-sm-12 content-error validate-error" attr.data-qa="invoice-update-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span *ngFor="let message of validationMessages; let even = even; let odd = odd; let invoiceDetailsIndex = index">
                {{message | translate}}
                <br>
            </span>
        </span>

        <div *ngIf="validationMessages && validationMessages.length == 0" class="modal-footer">
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button class="btn btn-warning pull-right" (click)="uploadFile($event)"
                        attr.data-qa="claim-opening-policy-details-close-btn">{{ '_CLAIMS_._UPLOAD' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AddressAdminLevel, AddressApiConfi} from '../../models/domain-models/address-api';
import {SystemProperty} from '../../models/system-properties/api-system-properties';
import {AnagIssueCountry} from '../re-issue-anag/anag-issue';

@Injectable({
  providedIn: 'root'
})
export class CommonResourceService {

  private baseApiUrlV2;
  private motorEndpoint;
  private specialPlateTypesEndpoint;
  private specialPlatePrefixesEndpoint;
  private plateTypesEndpoint;
  private plateTypeEndpoint;
  private companiesEndpoint;
  private systempropertyEndPoint;

  constructor(
    protected httpClient: HttpClient,
    @Inject('environment') protected apiConf: any) {
    this.baseApiUrlV2 = apiConf.api.portal.path + '/v2';
    // this.motorEndpoint = this.baseApiUrlV2 + '/motor';
    this.specialPlateTypesEndpoint = this.baseApiUrlV2 + '/motor/specialplates/types';
    this.specialPlatePrefixesEndpoint = this.baseApiUrlV2 + '/motor/specialplates/prefixes';
    this.plateTypesEndpoint = this.baseApiUrlV2 + '/motor/plateTypes';
    this.plateTypeEndpoint = this.baseApiUrlV2 + '/motor/plateType';
    this.companiesEndpoint = this.baseApiUrlV2 + '/common/companies';
    this.systempropertyEndPoint = this.baseApiUrlV2 + '/common/config';
  }

  getEnumValues(enumKey: string, contractId: string): any {
    let httpParams: HttpParams = new HttpParams();
    if (contractId) {
      httpParams = httpParams.set('contractId', contractId);
    }

    return this.httpClient.get<Array<AnagIssueCountry>>(this.apiConf.api.portal.path + '/subapp/countries');
  }

  getAddressConfig(country: string): Observable<AddressApiConfi> {
    const queryString = '?function=PortfolioArchiveSubject';
    return this.httpClient.get<AddressApiConfi>(this.apiConf.api.portal.path + '/subapp/addressconfig/' + country + queryString);
  }

  getAdminLevel(country: string, param: string, level: number): Observable<AddressAdminLevel> {
    if (!param || !level) {
      return null;
    }

    const queryString = '?filterValue=' + param;
    return this.httpClient.get<AddressAdminLevel>(this.apiConf.api.portal.path + '/subapp/adminlevel/' + country + '/' + level + queryString);
  }


  getSystemPoperty(key: string): Observable<SystemProperty> {
    return this.httpClient.get<SystemProperty>(this.systempropertyEndPoint + '/' + key);
  }

}

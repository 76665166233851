import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DIALOG_DATA, ModalService} from '@rgi/rx/ui';
import {Company} from '../../../../../models/domain-models/co-insurance';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PolicyGenericDto} from '../../../../../models/pc-portfolio-models/policy-generic-dto';
import {DatePipe} from '@angular/common';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {UnitService} from '../../../../re-issue-quotation/re-issue-quotation-services/unit.service';

@Component({
  selector: 'lib-re-issue-add-policy-modal',
  templateUrl: './re-issue-add-policy-modal.component.html',
  styleUrls: ['./re-issue-add-policy-modal.component.css']
})
export class ReIssueAddPolicyModalComponent implements OnInit, OnDestroy {

  @Output() modalClose = new EventEmitter();

  addPolicyForm: UntypedFormGroup;
  companies: Company[] = [];
  tableData: PolicyGenericDto[] = [];
  row: PolicyGenericDto;
  type: string;
  msgData: string;
  TAG_POLICY_DUPLICATED_ERROR = 'duplicated_error';
  constructor(private formBuilder: UntypedFormBuilder,
              @Inject(DIALOG_DATA) data: any,
              private translateService: RgiRxTranslationService,
              private modalService: ModalService,
              private datePipe: DatePipe,
              private pushMessageHandler: PushMessageHandlerService,
              private unitService: UnitService) {
    this.companies = data.companies;
    this.tableData = data.tableData;
    this.row = data.row;
    this.type = data.type;
    this.addPolicyForm = this.formBuilder.group({
      company: [null, Validators.required],
      policyNumber: null,
      expireDate: null,
      notes: null,
      limit: null
    });
    this.addPolicyForm.patchValue(this.row);
  }

  ngOnInit() {
    this.addPolicyForm.valueChanges.subscribe(() => {
      this.pushMessageHandler.clearTag(this.TAG_POLICY_DUPLICATED_ERROR);
    });
  }

  ngOnDestroy(): void {
    this.pushMessageHandler.clearTag(this.TAG_POLICY_DUPLICATED_ERROR);
  }

  close() {
    this.modalClose.emit();
  }

  confirm() {
    if (this.isSamePolicy()) {
      const message = this.type === 'SECOND_RISK_POLICIES' ? 'RE_ISSUE.POLICY_ALREADY_ADDED_SECOND_RISK' : 'RE_ISSUE.POLICY_ALREADY_ADDED_INDIRECT_COINSURANCE';
      this.notifyErrorMessage(this.TAG_POLICY_DUPLICATED_ERROR, message );
    } else {
      const policy: PolicyGenericDto = this.addPolicyForm.getRawValue();
      policy.objectId = this.row.objectId;
      if (policy.expireDate) {
        policy.expireDate = new Date(this.datePipe.transform(policy.expireDate, 'yyyy-MM-dd' + ' UTC'));
      }
      const limitValue = this.getLimitValue();
      policy.limit = this.unitService.parseToNumberAndFormat(limitValue);
      this.modalClose.emit(policy);
    }
  }
  isSamePolicy() {
    const company: Company = this.addPolicyForm.get('company').value;
    const policyNumber: string = this.addPolicyForm.get('policyNumber').value;
    const result = this.tableData.filter(policy => (policy.policyNumber === policyNumber && policy.company.identification === company.identification && policy.objectId !== this.row.objectId));
    return result.length > 0;
  }
  compareCompanies(t1: Company, t2: Company): boolean {
    return t1 && t2 ? t1.identification === t2.identification : t1 === t2;
  }

  notifyErrorMessage(tag: string, content: string) {
      const errorMsg = new RgiRxPushMessage();
      errorMsg.tag = tag;
      errorMsg.status = 'danger';
      errorMsg.dismissible = false;
      errorMsg.options = {icon: 'rgi-ui-icon-alert'};
      errorMsg.content = content;
      this.pushMessageHandler.notify(errorMsg);
  }

  canShowLimit() {
    return this.type === 'SECOND_RISK_POLICIES';
  }

  getLimitValue() {
    return this.addPolicyForm.get('limit').value;
  }
}

import {Injectable, Injector, Type} from '@angular/core';
import {RgiRxRuntimeError} from '@rgi/rx';
import { RgiRxRouteSnapshot } from '@rgi/rx/router';
import {RgiRxStateManager, State, StateManagedComponent} from './state-manager-api';

@Injectable({
  providedIn: 'root'
})
/**
 * @description Resolve the @rgi/rx/state RgiRxStateManager instance from the context.
 * Use this service to retrieve the instance from a specific context, with or without knowing the actual implementation.
 * Only works when the state manager has been provided via provideStateManager, when the RgiRxStateManager token
 * is provided locally or when the component instance of a snapshot extends StateManagedComponent.
 * When those condition are missing, you can both qualify the Type<T> of the StateManager class and eventually specify the
 * injector to use.
 * @see provideStateManager
 * @see StateManagedComponent
 */
export class RgiRxStateManagerResolver<T extends RgiRxStateManager<S>, S extends State | any = any> {
  constructor() {}


  /**
   * @description Resolve the StateManger
   * @param context the route snapshot of an activated route or the injector to use
   * @param qualifier specify the token of the state manager type to resolve
   */
  resolve(context: RgiRxRouteSnapshot | Injector,  qualifier?: Type<T>): T {
    const inject = context instanceof Injector ? context : context.componentRef.injector;
    try {
      if (qualifier) {
        return inject.get<T>(qualifier);
      }
      return inject.get(RgiRxStateManager) as T;
    } catch (e) {
      if (!(context instanceof Injector) && context.componentRef.instance instanceof StateManagedComponent) {
        return context.componentRef.instance.stateManager;
      }
      throw new RgiRxRuntimeError('Cannot resolve a state manager for this RgiRxRouteSnapshot with the supplied arguments!');
    }
  }
}

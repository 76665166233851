import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxEventService} from '@rgi/rx';
import {Observable, of} from 'rxjs';
import {AnagStatelessOpHomeService} from './anag-stateless-op-home.service';
import {AnagApiAddress, AnagApiParty, AnagPartyKey, AnagPhoneNumber} from '../../anag-model/anag-domain/anag-api-party';
import {KEY_DATA_UPDATE, KeyDataUpdateEvent, NewPartyEvent, NewPartyEventInstance} from '../../anag-events/anag-event';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagCountry} from '../../anag-model/anag-domain/anag-country';
import {AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {ANAG_STATES} from '../../anag-constants/anag-constants';
import {SUBJECT_TYPE_ENUM} from '../../anag-constants/enums-constants';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {
  KeyDataModalComponent
} from '../../anag-components/party-create-key-data/key-data-modal/key-data-modal.component';
import {AnagStateManagerKeyData} from '../key-data/anag-state-manager-key-data';
import {take} from 'rxjs/operators';
import {ModalService} from '@rgi/rx/ui';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {Agency} from '../../anag-model/anag-api/sales-network-api-agency';
import {AnagFlowData} from '../../anag-model/anag-domain/anag-flow-data';
import {AnagExtensionSubject} from "../../anag-model/anag-domain/anag-extension-subject";
import {AnagCompany} from "../../anag-model/anag-domain/anag-company";

export class AnagStateHome extends State {
  party: AnagApiParty;
  partyKey: AnagPartyKey;
  flowData: AnagFlowData;
  fiscalCodeDynamicObj: string;
  clientNumber: string;
  birthName: string;
  partyType: string;
  searchNodeId: string;
  searchNodeCode: string;
  subsystemIdentifier: string;
  subsystemCode: string;
  isUmaPartyKeyEnable: boolean;
  isUmaPartyNumberEnable: boolean;
  partyNotFound: boolean;
  serviceError: string;
  isModalFlow = false;
  canCreateParty = true;
  nodes: Agency[];
  redirectToMainFlow = false;
  isHomonymySearch ? = false;
  email: string
  phoneNumber: AnagPhoneNumber;
  birthDate: Date;
  extensionSubject?: AnagExtensionSubject;
  isMultiCompagnia: boolean;
  companyAniaCode: string;
}

export class AnagStateManagerHome extends RgiRxLocalStateManager<AnagStateHome> {
  statusList = ANAG_STATES;

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelessOps: AnagStatelessOpHomeService,
    protected eventService: RgiRxEventService,
    protected anagStorage: AnagStorageService,
    protected anagIntegrationService: AnagIntegrationService,
    protected modalService: ModalService,
    protected statelessService: AnagStatelessService
  ) {
    super(activeRoute, stateStoreService);
    this.initHome();
  }

  initHome() {
    const newStateHome = new AnagStateHome(this.activeRoute.key);
    const routeData = this.activeRoute.getRouteData<AnagRouteData>();
    newStateHome.party = routeData && routeData.party ? routeData.party : new AnagApiParty();
    newStateHome.isModalFlow = routeData ? routeData.isModalFlow : false;
    newStateHome.canCreateParty = routeData ? !routeData.isSearchFlow : true;
    newStateHome.redirectToMainFlow = routeData ? routeData.redirectToMainFlow : false;
    newStateHome.flowData = routeData ? routeData.flowData : undefined;
    const initHome$ = this.statelessOps.initHome$(of(newStateHome));
    this.updateState$(initHome$);
  }

  clearState() {
    const clearedState$ = this.statelessOps.clearState$(of(this.getCurrentState()));
    this.updateState$(clearedState$);
  }

  actionFindParties(formRawData: any, residenceAddress: AnagApiAddress) {
    const currentState = this.getCurrentState();
    currentState.partyNotFound = false;
    currentState.serviceError = undefined;
    this.adaptFormToState(currentState, formRawData);
    if (residenceAddress && residenceAddress.cap) {
      currentState.party.residence = residenceAddress;
    }
    this.updateState$(this.statelessOps.findParties$(of(currentState), this.activeRoute));
  }

  adaptFormToState(state: AnagStateHome, form: any) {
    state.party = new AnagApiParty();
    state.searchNodeId = undefined;
    state.party.name = form.name ? form.name.trim() : undefined;
    state.party.surname = form.surname ? form.surname.trim() : undefined;
    const partyKey = state.partyKey;
    if (partyKey) {
      if (partyKey.key1) {
        form.partyKey1 && form.partyKey1.length > 0 ? partyKey.key1.value = form.partyKey1 : partyKey.key1.value = null;
      }
      if (partyKey.key2) {
        form.partyKey2 && form.partyKey2.length > 0 ? partyKey.key2.value = form.partyKey2 : partyKey.key2.value = null;
      }
      if (partyKey.key3) {
        form.partyKey3 && form.partyKey3.length > 0 ? partyKey.key3.value = form.partyKey3 : partyKey.key3.value = null;
      }
      state.party.partyKey = [];
      state.party.partyKey.push(partyKey);
    }
    state.party.vat = form.vatNumber ? form.vatNumber : undefined;
    state.party.fiscalCode = form.fiscalCode ? form.fiscalCode : undefined;
    state.party.partyNumber = form.partyNumber ? form.partyNumber : undefined;
    state.party.subjectType = form.partyType ? {codice: form.partyType} : undefined;
    state.clientNumber = form.clientNumber ? form.clientNumber : undefined;
    state.fiscalCodeDynamicObj = form.coworker ? form.coworker : undefined;
    state.party.birthName = form.birthName ? form.birthName : undefined;
    state.subsystemCode = form.subsystemCode ? form.subsystemCode : undefined;
    state.subsystemIdentifier = form.subsystemIdentifier ? form.subsystemIdentifier : undefined;
    state.searchNodeId = state.nodes.find(node => node.code === form.node) ? '' +
      state.nodes.find(node => node.code === form.node).id : null;
    state.email = form.email ? form.email : undefined;
    if (form.phoneNumber) {
      state.phoneNumber = new AnagPhoneNumber();
      state.phoneNumber.internationalPrefix = form.internationalPrefix ? form.internationalPrefix : undefined;
      state.phoneNumber.localPrefix = form.localPrefix ? form.localPrefix : undefined;
      state.phoneNumber.number = form.phoneNumber;
    }
    state.party.dateOfBirth = form.birthDate;
    state.party.company = form.company ? {codice: form.company.id} : undefined;
    state.companyAniaCode = form.company ? form.company.aniaCode : undefined;
  }

  get countriesList(): Array<AnagCountry> {
    return this.anagStorage.countries;
  }

  get companies(): Array<AnagCompany> {
    return this.anagStorage.companies;
  }

  get partyTypes(): Array<AnagEntityIta> {
    return this.anagStorage.getEnumsByCode(SUBJECT_TYPE_ENUM);
  }

  get criteriaList(): Observable<AnagEntityIta[]> {
    return this.anagStorage.getEnumtable$('it.rgi.dbobjects.EntConfcriteri');
  }

  get subsystemList(): Observable<AnagEntityIta[]> {
    return this.anagStorage.getEnumtable$('it.rgi.dbobjects.EntSubsystems');
  }

  getPartyKeysByCountry(countryCode: string) {
    const currentState = this.getCurrentState();
    this.updateState$(this.statelessOps.getPartyKeyConfig$(of(currentState), countryCode));
  }

  actionCreateParty(stateMgrKeyData: AnagStateManagerKeyData, formData: any) {
    const currentState = this.getCurrentState();
    this.adaptFormToState(currentState, formData);
    this.updateState$(of(currentState));
    if (currentState.isModalFlow) {
      this.eventService.emit<NewPartyEvent>(new NewPartyEventInstance({
        action: 'keyData',
        party: this.statelessService.deepClone(currentState.party),
        lastAction: 'searchFlow',
        isModalFlow: true,
        flowData: currentState.flowData,
        redirectToMainFlow: currentState.redirectToMainFlow
      }));
    } else {
      const keyDataModal = this.modalService.openComponent(KeyDataModalComponent, this.statelessService.deepClone(currentState.party), [
        {
          provide: AnagStateManagerKeyData,
          useValue: stateMgrKeyData
        }
      ]);
      keyDataModal.modal.enableClickBackground = false;
      keyDataModal.modal.onClose.asObservable().pipe(take(1));
      this.eventService.listen<KeyDataUpdateEvent>(KEY_DATA_UPDATE).pipe(take(1)).subscribe(eventInstance => {
        this.anagIntegrationService.openEditSubject(eventInstance.event);
      });
    }

  }

  getNodeCode(): string {
    return this.statelessOps.getNodeCode(this.getCurrentState());
  }
}

export const CONTRACT_TYPES = [
  {
    description: 'POLICIES_OR_PROPOSALS',
    code: '0'
  },
  {
    description: 'POLICIES',
    code: '1'
  },
  {
    description: 'PROPOSALS',
    code: '2'
  },
  {
    description: 'QUOTE_W_REG_PARTY',
    code: '3'
  },
  {
    description: 'ANONYMOUS_QUOTE',
    code: '4'
  }
];

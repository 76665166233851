import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { LimitClaimDto } from './dto/limit-claim-dto';
import { LimitDetailDto } from './dto/limit-detail-dto';
import { LimitDto } from './dto/limit-dto';

@Injectable({
  providedIn: 'root'
})
export class LimitsManagementService {

  private baseApiUrlV2;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  // SERVICE 1 - GENERAL DATA
  public getLimitsGeneralDataByIdClaim(idClaim: number) {
    return this.httpClient.get<LimitDto[]>(this.getLimitsGeneralDataByIdClaimUri(idClaim));
  }

  private getLimitsGeneralDataByIdClaimUri(idClaim: number) {
    return this.baseApiUrlV2 + '/claims/' + idClaim + '/limits/';
  }

  public getLimitsCounterGeneralDataByIdClaim(idClaim: number) {
    return this.httpClient.get<LimitDto[]>(this.getLimitsCounterGeneralDataByIdClaimUri(idClaim));
  }

  private getLimitsCounterGeneralDataByIdClaimUri(idClaim: number) {
    return this.baseApiUrlV2 + '/claims/' + idClaim + '/limits-counter/';
  }

  // SERVICE 2 - DETAIL TABLE
  public getLimitDetailsByIdLimit(idLimit: number) {
    return this.httpClient.get<LimitDetailDto[]>(this.getLimitDetailsByIdLimitUri(idLimit));
  }

  public getLimitCounterDetailsByIdLimitCounter(idLimitCounter: number) {
    return this.httpClient.get<LimitDetailDto[]>(this.getLimitCounterDetailsByIdLimitCounterUri(idLimitCounter));
  }

  private getLimitDetailsByIdLimitUri(idLimit: number) {
    return this.baseApiUrlV2 + '/claims/limits/' + idLimit + '/details/';
  }

  private getLimitCounterDetailsByIdLimitCounterUri(idLimitCounter: number) {
    return this.baseApiUrlV2 + '/claims/limits-counter/' + idLimitCounter + '/details/';
  }

  // SERVICE 3 - CLAIMS TABLE
  public getLimitCumulativeClaimsByIdLimit(idLimit: number, claimIdToExclude: string) {
    return this.httpClient.get<LimitClaimDto[]>(this.getLimitCumulativeClaimsByIdLimitUri(idLimit, claimIdToExclude));
  }

  public getLimitCounterCumulativeClaimsByIdLimitCounter(idLimitCounter: number, claimIdToExclude: string) {
    return this.httpClient.get<LimitClaimDto[]>(this.getLimitCounterCumulativeClaimsByIdLimitCounterUri(idLimitCounter, claimIdToExclude));
  }

  private getLimitCumulativeClaimsByIdLimitUri(idLimit: number, claimIdToExclude: string) {
    return this.baseApiUrlV2 + '/claims/limits/' + idLimit + '/claims?claimIdToExclude=' + claimIdToExclude;
  }

  private getLimitCounterCumulativeClaimsByIdLimitCounterUri(idLimitCounter: number, claimIdToExclude: string) {
    return this.baseApiUrlV2 + '/claims/limits-counter/' + idLimitCounter + '/claims?claimIdToExclude=' + claimIdToExclude;
  }

  // MODAL SERVICES
  public getLimitTypes(claimId: number, limitId: number) {
    return this.httpClient.get<ApiEnumType[]>(this.getLimitTypesUri(claimId, limitId));
  }

  private getLimitTypesUri(claimId: number, limitId: number) {
    return this.baseApiUrlV2 + '/claims/limits/' + claimId + '/' + limitId + '/types';
  }

  public getLimitRegimes(claimId: number) {
    return this.httpClient.get<ApiEnumType[]>(this.getLimitRegimesUri(claimId));
  }

  private getLimitRegimesUri(claimId: number) {
    return this.baseApiUrlV2 + '/claims/limits/' + claimId + '/regimes';
  }

  public getLimitLevelCode(claimId: number, levelid: number) {
    return this.httpClient.get<ApiEnumType[]>(this.getLimitLevelCodeUri(claimId, levelid));
  }

  private getLimitLevelCodeUri(claimId: number, levelid: number) {
    return this.baseApiUrlV2 + '/claims/limits/' + claimId + '/' + levelid;
  }

  public newLimit(claimId: number, limit: LimitDto) {
    return this.httpClient.put(this.newLimitUri(claimId), limit);
  }

  private newLimitUri(claimId: number) {
    return this.baseApiUrlV2 + '/claims/' + claimId + '/limit';
  }

  public getLimitSubtypesForNewLimit() {
    return this.httpClient.get<ApiEnumType[]>(this.getLimitSubtypesForNewLimitUri());
  }

  private getLimitSubtypesForNewLimitUri() {
    return this.baseApiUrlV2 + '/claims/limits/subtypes';
  }

  public getLimitSubtypesByType(typeId: number) {
    return this.httpClient.get<ApiEnumType[]>(this.getLimitSubtypesByTypeUri(typeId));
  }

  private getLimitSubtypesByTypeUri(typeId: number) {
    return this.baseApiUrlV2 + '/claims/limits/' + typeId + '/subtypes';
  }

  public getLimitSubtypeCodesBySubtype(claimId: number, subtypeId: number) {
    return this.httpClient.get<ApiEnumType[]>(this.getLimitSubtypeCodesBySubtypeUri(claimId, subtypeId));
  }

  private getLimitSubtypeCodesBySubtypeUri(claimId: number, subtypeId: number) {
    return this.baseApiUrlV2 + '/claims/limits/' + claimId + '/' + subtypeId + '/subtypeCodes';
  }

  public getLimitLevelBySubtype(subtypeId: number) {
    return this.httpClient.get<ApiEnumType>(this.getLimitLevelBySubtypeUri(subtypeId));
  }

  private getLimitLevelBySubtypeUri(subtypeId: number) {
    return this.baseApiUrlV2 + '/claims/limits/' + subtypeId + '/level';
  }

}

import { Inject, Injectable } from '@angular/core';
import { UserService } from '@rgi/rx/auth';
import { CaiOpeningState } from '../../config-model/cai-opening-config.module';
import { DatePipe } from '@angular/common';
import { ClaimDetail } from '../../../shared/dsc-shared-model/dsc-model-claims';
import { Heir, SubjectReduced } from '../../../shared/dsc-shared-model/dsc-model-subject';
import { RSClaimsAffectedAssetParty } from '../../../shared/dsc-shared-model/dsc-model-injury';
import { NewClaim } from '../../config-model/cai-opening-claims.module';
import { Item } from '../../config-model/cai-opening-questionnaire.model';



@Injectable()
export class CaiOpeningClaimUtility {
  private baseApiUrlV2: string;
  private baseApiUrl: string;
  public authSrv: any;

  constructor(
    protected userService: UserService,
    @Inject('authService') authService,
    @Inject('environment') environment: any,
    @Inject('enumService') private enumService: any,
    private datepipe: DatePipe
  ) {
    this.baseApiUrlV2 =
      environment.api.portal.host + environment.api.portal.path + '/v2/';
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.authSrv = authService;
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    miaData.setHours(0);
    miaData.setMinutes(0);
    miaData.setSeconds(0);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    return miaData.toISOString();
    /* const dataOutput = this.datePipe.transform(miaData, 'yyyy-MM-dd hh:mm:ss');
    return dataOutput; */
  }

  _transformObjectToClaim(currentClaim: CaiOpeningState, isDraft: boolean = false): Promise<NewClaim> {
    return new Promise<NewClaim>(resolve => {
      const stateList: CaiOpeningState[] = [];
      stateList.push(currentClaim);
      const state = stateList[0];
      const heirsAccumulator = [];
      let getAllHeirs: Heir[] = [];
      state.claimToService.claim = { ...stateList[0].generatedClaim.claim };
      state.claimToService.claim.selectedPolicy = currentClaim.selectedPolicy;
      state.claimToService.claim.policyNumber = currentClaim.selectedPolicy.policyNumber;
      state.claimToService.claim.selectedEventCategory = currentClaim.detailNewClaimInfo.selectedEventCategory;
      if (!state.singleCai.owner.subject.tipoSoggetto) {
        state.singleCai.owner.subject.tipoSoggetto = state.singleCai.owner.subject.anagSubject.subjectType;
      }
      state.claimToService.claim.claimParties[0].subject = state.singleCai.owner.subject;
      state.claimToService.claim.claimParties[0].partyType = '1';
      state.claimToService.claim.claimParties[0].partyRole = state.singleCai.policyHolder.partyRole;
      if (state.claimToService.claim.claimParties[0].subject.setChkInjury) {
        state.claimToService.claim.claimParties[0].subject.chkInjury = true;
      }

      state.generatedClaim.claim.claimParties[0].subject.isIncapacitated = state.singleCai.owner.isIncapacitated;
      state.generatedClaim.claim.claimParties[0].subject.isMinor = state.singleCai.owner.isMinor;
      state.generatedClaim.claim.claimParties[0].subject.listMinorGuardianSubject = 
      state.singleCai.owner.listMinorGuardianSubject;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.policyHolder.isIncapacitated = state.singleCai.policyHolder.isIncapacitated;


      state.generatedClaim.claim.claimParties[0].subject.enumSubjectType =
        state.singleCai.owner.subject.anagSubject.personType ? state.singleCai.owner.subject.anagSubject.personType
          : state.singleCai.owner.subject.anagSubject.personType;
      state.generatedClaim.claim.claimParties[0].subject.fiscalCode =
        state.singleCai.owner.subject.anagSubject.fiscalCode;
      state.generatedClaim.claim.claimParties[0].subject.gender =
        state.singleCai.owner.subject.anagSubject.genderIdentity;
      state.generatedClaim.claim.claimParties[0].subject.givenName =
        state.singleCai.owner.subject.anagSubject.name;
      state.generatedClaim.claim.claimParties[0].subject.objectId =
        state.singleCai.owner.subject.anagSubject.objectId;
      state.generatedClaim.claim.claimParties[0].subject.surname =
        state.singleCai.owner.subject.anagSubject.surname;
      // gestione driver salvato
      if(state.singleCai.driver.objectId !== '') {
      if (state.singleCai.driver.subject && !state.singleCai.driver.subject.tipoSoggetto) {
        state.singleCai.driver.subject.tipoSoggetto = state.singleCai.driver.subject.anagSubject.subjectType;
      }
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver = state.singleCai.driver.subject;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.chkInjury =
        state.singleCai.driver.chkInjury;

      if (state.singleCai.driver.chkMaterial && state.singleCai.driver.materialDescription !== '') {
        state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.chkMaterial =
          state.singleCai.driver.chkMaterial;
        state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.materialDescription =
          state.singleCai.driver.materialDescription;
      }
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.tipoSoggetto =
        state.singleCai.driver.subject.anagSubject.subjectType;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.enumSubjectType =
        state.singleCai.driver.subject.anagSubject.personType;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.partyType = '1';
      
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.isIncapacitated = state.singleCai.driver.isIncapacitated;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.isMinor = state.singleCai.driver.isMinor;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.listMinorGuardianSubject = 
        state.singleCai.driver.listMinorGuardianSubject;      
        state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.fiscalCode =
        state.singleCai.driver.subject.anagSubject.fiscalCode;
      
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.fiscalCode =
        state.singleCai.driver.subject.anagSubject.fiscalCode;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.gender =
        state.singleCai.driver.subject.anagSubject.genderIdentity;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.givenName =
        state.singleCai.driver.subject.anagSubject.name;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.objectId =
        state.singleCai.driver.subject.anagSubject.objectId;
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.surname =
        state.singleCai.driver.subject.anagSubject.surname;
        if(state.singleCai.heirsList.owner.potentialHeirs
            .some(el=> el.linkedPartyObjectId === state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.objectId)) {
            state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.heir = true;
          } else {
            state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.heir = false;
          }
    }
      //circostanze e dati aggiuntivi
      state.generatedClaim.claim.forcedTechLiabilityPercentage = state.occurrenceData.circumstanceIncident.percInsuredLiability ?
        state.occurrenceData.circumstanceIncident.percInsuredLiability.toString() : null;
      state.generatedClaim.claim.technicalResponsabilityPercentage = state.occurrenceData.circumstanceIncident.percInsuredLiability ?
        state.occurrenceData.circumstanceIncident.percInsuredLiability.toString() : null;
      state.generatedClaim.claim.signType = 
        {
          codice: state.occurrenceData.circumstanceIncident.signatureType,
          descrizione: ''
        };
      state.generatedClaim.claim.externalClaimNumber = state.occurrenceData.additionalData.externalClaimNumberValue;
      // verificare queste if gestendo i flag in modo corretto escludendo eventuali duplicati
      if (state.singleCai.roles.owner.objectId !== state.singleCai.roles.driver.objectId) {
        // entro se driver è diverso da owner
        if (state.singleCai.owner.chkInjury) {
          // se l'owner ha lesioni
          state.generatedClaim.claim.claimParties[0].subject.chkInjury = true;
          state.generatedClaim.claim.claimParties[0].subject.subjectInjuryAsset =
            state.singleCai.owner.subjectDataFormInjury;
          if(!this.areInjurySetted(state.singleCai.owner.subjectDataFormInjury)) {
            state.generatedClaim.claim.claimParties[0].subject.chkInjury = false;
          }
          
          // salva eredi
          const ownerHeirs = state.singleCai.heirsList.owner.potentialHeirs.filter(el=> el.checked === true)
          state.generatedClaim.claim.claimParties[0].subject.heirsList = ownerHeirs.map((h:Heir) => h.linkedPartyObjectId);
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.policyHolder.heirsList = ownerHeirs.map((h:Heir) => h.linkedPartyObjectId);
          if(state.singleCai.heirsList.driver.potentialHeirs
            .some(el=> el.linkedPartyObjectId === state.generatedClaim.claim.claimParties[0].subject.objectId)) {
              state.generatedClaim.claim.claimParties[0].subject.heir = true;
          } else {
            state.generatedClaim.claim.claimParties[0].subject.heir = false;
          }
        }
        if (state.singleCai.driver.chkInjury) {
          // se driver ha lesioni
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.chkInjury = true;
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.subjectInjuryAsset =
            state.singleCai.driver.subjectDataFormInjury;
            if(!this.areInjurySetted(state.singleCai.driver.subjectDataFormInjury)) {
              state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.chkInjury = false;
            }
          // salva eredi
          const driverHeirs = state.singleCai.heirsList.driver.potentialHeirs.filter(el=> el.checked === true)
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.heirsList = driverHeirs.map((h:Heir) => h.linkedPartyObjectId);
        }
        // imposto danni materiali se presenti
        if (state.singleCai.owner.chkMaterial && state.singleCai.owner.materialDescription !== '') {
          // se l'owner ha danni materiali
          state.generatedClaim.claim.claimParties[0].subject.chkMaterial = true;
          state.generatedClaim.claim.claimParties[0].subject.materialDescription =
            state.singleCai.owner.materialDescription;
        }
        if (state.singleCai.driver.chkMaterial && state.singleCai.driver.materialDescription !== '') {
          // se driver ha danni materiali
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.chkMaterial = true;
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.materialDescription =
            state.singleCai.driver.materialDescription;
        }
        
      } else if (state.singleCai.roles.owner.objectId === state.singleCai.roles.driver.objectId) {
        // se ha lesioni
        if (state.singleCai.owner.chkInjury) {
          state.generatedClaim.claim.claimParties[0].subject.chkInjury = true;
          state.generatedClaim.claim.claimParties[0].subject.subjectInjuryAsset =
            state.singleCai.owner.subjectDataFormInjury;
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.chkInjury = true;
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.subjectInjuryAsset =
            state.singleCai.driver.subjectDataFormInjury;
            if(!this.areInjurySetted(state.singleCai.driver.subjectDataFormInjury)) {
              state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.chkInjury = false;
            }
          // salva eredi
          const ownerHeirs = state.singleCai.heirsList.owner.potentialHeirs.filter(el=> el.checked === true)
          state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver.heirsList = 
          ownerHeirs.map((h:Heir) => h.linkedPartyObjectId);
        }
        // se ha danni materiali
        if (state.singleCai.owner.chkMaterial && state.singleCai.owner.materialDescription !== '') {
          // se l'owner ha danni materiali
          state.generatedClaim.claim.claimParties[0].subject.chkMaterial = true;
          state.generatedClaim.claim.claimParties[0].subject.materialDescription = state.singleCai.owner.materialDescription;
        }
      }
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.damagedParts =
        state.singleCai.damagedParts.damagedParts;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.observation =
        state.singleCai.damagedParts.observation;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.otherInvVehicles =
        state.singleCai.damagedParts.otherInvVehicles;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.visibleDamages =
        state.singleCai.damagedParts.visibleDamages;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.blackboxCoherence =
        state.singleCai.damagedParts.damageOption;

        state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.policyHolder.isIncapacitated =
        state.singleCai.policyHolder.isIncapacitated;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.policyHolder.isMinor = 
      state.singleCai.policyHolder.isMinor;
        state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.policyHolder.listMinorGuardianSubject = 
        state.singleCai.policyHolder.listMinorGuardianSubject;


      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.policyHolder =
        state.singleCai.policyHolder.subject;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.parkedVehicle =
        state.singleCai.vehicle.parkedVehicle;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.bodyShopSubject =
        state.singleCai.carBodywork;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.bodyShopCode =
        state.singleCai.carBodywork.code;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.bodyShopExternalKey =
        state.singleCai.carBodywork.objectId;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.damagedParts =
        state.singleCai.damagedParts.damagedParts;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.observation =
        state.singleCai.damagedParts.observation;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.otherInvVehicles =
        state.singleCai.damagedParts.otherInvVehicles;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.vehicle.visibleDamages =
        state.singleCai.damagedParts.visibleDamages;
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.party =
        this._transformObjectToPhisicalInjury(state.singleCai.policyHolder);
      state.claimToService.claim.claimParties[0].damageDescriptions[0].asset.policyData.isMaterialLossCovertureOnPolicy =
        state.singleCai.insuranceCompany.isMaterialLossCovertureOnPolicy;
      state.claimToService.claim.claimAddress = {
        city: state.occurrenceData.addressEntity.city,
        cityCode: state.occurrenceData.addressEntity.cityCode,
        contryCode: state.occurrenceData.addressEntity.countryCode,
        number: state.occurrenceData.addressEntity.houseNumber ? state.occurrenceData.addressEntity.houseNumber.toString() : '' ,
        placeAddress: state.occurrenceData.addressEntity.address,
        postCode: state.occurrenceData.addressEntity.postCode,
        province: state.occurrenceData.addressEntity.province,
        toponym: state.occurrenceData.addressEntity.toponym,
        toponymStr: state.occurrenceData.addressEntity.toponym ? state.occurrenceData.addressEntity.toponym.description : null,
        umaAddress: state.occurrenceData.addressEntity.umaAddress
        // umaAddress: {
        //   adminLevel2: state.occurrenceData.addressEntity.adminLevel1,
        //   adminLevel2Short: state.occurrenceData.addressEntity.adminLevel1
        //     ? state.occurrenceData.addressEntity.adminLevel1.substring(0, 2)
        //     : '',
        //   adminLevel3: state.occurrenceData.addressEntity.adminLevel3,
        //   cap: state.occurrenceData.addressEntity.postCode,
        //   countryCode: state.occurrenceData.addressEntity.countryCode,
        //   normalized: false, // da aggiungere quando inseriremo normalizzatore nella funzione dcs-address
        //   number: state.occurrenceData.addressEntity.houseNumber,
        //   placeAddress: state.occurrenceData.addressEntity.address,
        // },
      };
      state.claimToService.claim.reportDate = this.formatDateTimezone(state.occurrenceData.incidentData.dateReported);
      state.claimToService.claim.occurrenceDate = this.formatDateTimezone(state.occurrenceData.incidentData.occurrenceDate);
      state.claimToService.claim.dateReceivedIntermediary = this.formatDateTimezone(state.occurrenceData.incidentData.intermediaryReceiptDate);
      // state.claimToService.claim.dateReceivedCHL = state.occurrenceData.incidentData.chcReceiptDate;
      state.claimToService.claim.dateReceivedInsured = this.formatDateTimezone(state.occurrenceData.incidentData.insuredReportDate);
      state.claimToService.claim.occurenceDescription = state.occurrenceData.incidentData.incidentDescription;
      state.claimToService.claim.incidentAdminNote = state.occurrenceData.incidentData.additionalNotes;
      state.claimToService.claim.dateReportedEditable = state.occurrenceData.incidentData.dateReportedEditable;
      state.claimToService.claim.intermediaryReceiptDateEditable = state.occurrenceData.incidentData.intermediaryReceiptDateEditable;
      state.claimToService.claim.incidentDescriptionEditable = state.occurrenceData.incidentData.incidentDescriptionEditable;
      state.claimToService.claim.additionalNotesEditable = state.occurrenceData.incidentData.additionalNotesEditable;
      // Additional Data
      state.claimToService.claim.percInsuredLiability = state.occurrenceData.circumstanceIncident.percInsuredLiability ?
      state.occurrenceData.circumstanceIncident.percInsuredLiability : null;
      state.claimToService.claim.eventDenial = state.occurrenceData.circumstanceIncident.eventDenial;
      state.claimToService.claim.externalClaimNumber = state.occurrenceData.additionalData.externalClaimNumberValue;
      state.claimToService.claim.authorityDescriptionValue = state.occurrenceData.additionalData.authorityDescriptionValue;
      state.claimToService.claim.typeOfAuthorityInvolvedValue = state.occurrenceData.additionalData.typeOfAuthorityInvolvedValue;
      state.claimToService.claim.lastAuditDateValue = state.occurrenceData.additionalData.lastAuditDateValue;
      state.claimToService.claim.openedClaimDuetoSummonsIssuedValue = state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedValue;
      state.claimToService.claim.notIdentifiedWitnessValue = state.occurrenceData.additionalData.notIdentifiedWitnessValue;
      state.claimToService.claim.toBeSettledValue = state.occurrenceData.additionalData.toBeSettledValue;
      // END Additional Data
      const occTime = new Date(state.occurrenceData.incidentData.occurrenceTime);
      const occTimeStr = this.datepipe.transform(occTime, 'HH:mm');
      state.claimToService.claim.occurrenceHour = occTimeStr;
      const ministerialCode = state.listCovertureBranches.ministerialBranchCode
        ? state.listCovertureBranches.ministerialBranchCode
        : '000010';
      state.claimToService.claim.ministerialBranchCodes = ministerialCode;
      state.claimToService.claim.ministerialBranch = ministerialCode;
      state.claimToService.claim.coverTypeCode =
        state.selectedCovertureBranch.covertureTypeCode;

      const questItems = [];
      state.questionnaire.items.forEach((item: Item) => {
        const itemToSave = {
          valueResponse: Number(item.answers[0].valueResponse),
          answerCode: item.answers[0].answerCode,
          question: item.question.questionCode,
          answer: item.answers[0].questionnaireCode,
          questionCode: item.question.questionCode,
          answerTextValue: item.answers[0].valuesResponse.find(
            (valRes) => valRes.id === item.answers[0].valueResponse
          ) ? item.answers[0].valuesResponse.find(
            (valRes) => valRes.id === item.answers[0].valueResponse
          ).description : "",
        };
        questItems.push(itemToSave);
      });
      state.claimToService.claim.questionnaireItems = questItems;
      state.claimToService.claim.claimParties.splice(1, 1);

      if (state.singleCai.roles.driver.objectId === state.singleCai.roles.owner.objectId) {
        state.claimToService.claim.claimParties[0].partyRole = {
            descrizione: 'Conducente proprietario nostro veicolo',
            codice: '3'
        };
      } else if (
        state.singleCai.policyHolder.isPassenger || state.singleCai.owner.isPassenger) {
        state.claimToService.claim.claimParties[0].partyRole = {
          descrizione: 'Trasportato proprietario nostro veicolo',
          codice: '7'
        };
      } else {
        state.claimToService.claim.claimParties[0].partyRole = {
          descrizione: 'Proprietario non presente nostro veicolo',
          codice: '9'
        };
      }
      state.generatedClaim.claim.claimParties[0].damageDescriptions[0].asset.address = {
        city: state.singleCai.propertyAddress.city,
        cityCode: state.singleCai.propertyAddress.cityCode,
        contryCode: state.singleCai.propertyAddress.countryCode,
        number: state.singleCai.propertyAddress.houseNumber.toString(),
        placeAddress: state.singleCai.propertyAddress.address,
        postCode: state.singleCai.propertyAddress.postCode,
        province: state.singleCai.propertyAddress.province,
        toponym: state.singleCai.propertyAddress.toponym,
        toponymStr: state.singleCai.propertyAddress.toponym ? state.singleCai.propertyAddress.toponym.description : null,
        umaAddress: state.singleCai.propertyAddress.umaAddress
        // umaAddress: {
        //   adminLevel2: state.singleCai.propertyAddress.adminLevel1,
        //   adminLevel2Short: state.singleCai.propertyAddress.adminLevel1
        //     ? state.singleCai.propertyAddress.adminLevel1.substring(0, 2)
        //     : '',
        //   adminLevel3: state.singleCai.propertyAddress.adminLevel3,
        //   cap: state.singleCai.propertyAddress.postCode,
        //   countryCode: state.singleCai.propertyAddress.countryCode,
        //   normalized: false, // da aggiungere quando inseriremo normalizzatore nella funzione dcs-address
        //   number: state.singleCai.propertyAddress.houseNumber,
        //   placeAddress: state.singleCai.propertyAddress.address,
        // },
      };
      console.log(state.claimToService.claim)
      const newClaimSaveInput = {
        newClaimSaveInput: {
          chcCode: this.authSrv.getLiquidationCentreCode(),
          claim: state.claimToService.claim,
          draft: isDraft,
          temporary: true,
        },
      };
      resolve(newClaimSaveInput);
    });
  }

  _transformObjectToClaimJs(state: CaiOpeningState): Promise<ClaimDetail> {
    return new Promise<ClaimDetail>((resolve) => {
      state.generatedClaim.claim.companyDetailObject = state.singleCai.insuranceCompany;
      state.generatedClaim.claim.companyDetailObject.isMaterialLossCovertureOnPolicy = state.generatedClaim.claim.isMaterialLossCovertureOnPolicy;
      state.generatedClaim.claim.occurrenceDate =
        this.formatDateTimezone(state.detailNewClaimInfo.occurrenceDate.toISOString());
      state.generatedClaim.claim.reportDate =
        this.formatDateTimezone(state.detailNewClaimInfo.occurrenceDate.toISOString());
      state.generatedClaim.claim.occurrenceHour = '00:01';
      const ministerialCode = state.listCovertureBranches.ministerialBranchCode
        ? state.listCovertureBranches.ministerialBranchCode
        : '000010';
      state.generatedClaim.claim.ministerialBranchCodes = ministerialCode;
      state.generatedClaim.claim.ministerialBranch = ministerialCode;
      state.generatedClaim.claim.coverTypeCode =
        state.selectedCovertureBranch.covertureTypeCode;

      const questItems = [];
      state.questionnaire.items.forEach((item: Item) => {
        const itemToSave = {
          valueResponse: Number(item.answers[0].valueResponse),
          answerCode: item.answers[0].answerCode,
          question: item.question.questionCode,
          answer: item.answers[0].questionnaireCode,
          questionCode: item.question.questionCode,
          answerTextValue: item.answers[0].valuesResponse.find(
            (valRes) => valRes.id === item.answers[0].valueResponse
          ) ? item.answers[0].valuesResponse.find(
            (valRes) => valRes.id === item.answers[0].valueResponse
          ).description : "",
        };
        questItems.push(itemToSave);
      });
      state.generatedClaim.claim.questionnaireItems = questItems;
      resolve(state.generatedClaim.claim);
    });
  }

  getAllHeir(array1: Heir[], array2: Heir[]): Heir[] {
    // Concatena gli array di eredi ed elimina gli eventuali duplicati
    const allHeirs = array1.concat(array2);
    const arrayWithoutDuplicate = allHeirs.filter((heir, index, heirs) => {
      return heirs.indexOf(heir) === index;
    });
    return arrayWithoutDuplicate;
  }


  _transformObjectToPhisicalInjury(policyHolder: SubjectReduced): RSClaimsAffectedAssetParty {
    const formInjury = policyHolder.subjectDataFormInjury;
    const rSClaimsAffectedAssetParty: RSClaimsAffectedAssetParty = {
      physician: null,
      emergencyRoomAddress: null,
      emergencyRoomHospital: '',
      permanentDisabilityPercentage: null,
      deceaseDate: null,
      otherInjuries: null,
      replaceSocialInsurer: false,
      profession: {
        inailProfession: null,
        extensionData: null
      },
      injuryAgent: null,
      injurySummary: [],
      extensionData: null
    };
    if (formInjury) {
      rSClaimsAffectedAssetParty.physician = formInjury.doctorSelected;
      rSClaimsAffectedAssetParty.emergencyRoomHospital = formInjury.descriptionER;
      rSClaimsAffectedAssetParty.permanentDisabilityPercentage = formInjury.percIP;
      rSClaimsAffectedAssetParty.deceaseDate = formInjury.fatalDate;
      rSClaimsAffectedAssetParty.otherInjuries = formInjury.moreInjury;
      rSClaimsAffectedAssetParty.replaceSocialInsurer = formInjury.surroga === '1';
      rSClaimsAffectedAssetParty.injurySummary = formInjury.damageList;
    }
    return rSClaimsAffectedAssetParty;
  }

  areInjurySetted(injuryAsset): boolean {
    if (Boolean(injuryAsset['injuryCause']) || Boolean(injuryAsset['fatalDate'])) { 
      return true;
     }
  }

}

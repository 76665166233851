import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicHelpIconComponent } from './lic-help-icon/lic-help-icon.component';
import { LicHelpContentComponent } from './lic-help-content/lic-help-content.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [
        LicHelpIconComponent,
        LicHelpContentComponent
    ],
    imports: [
        CommonModule,
        RgiRxI18nModule
    ],
    exports: [
        LicHelpIconComponent,
        LicHelpContentComponent
    ]
})
export class LicHelpModule { }

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Octo Telematics - Installer Data</span>
        </h3>
      </div>
      <div class="modal-body">
        <div *ngIf="installers && installers.length">
          <div class="d-flex align-items-center" style="margin-bottom: 1rem;">
            <select (change)="setPage(0)" [(ngModel)]="itemsForPage" style="padding: 0px 1rem; margin-right: 2rem">
              <option value="5">5</option>
              <option selected value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            <div translate>Elements</div>
            <div class="flex-grow-1"></div>
            <input (keyup)="filterInstallers()" [(ngModel)]="search" id="search" name="search" placeholder="{{ 'Search' | translate }}"
                   type="text">
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr style="background-color: #AFBBC6; color: white; font-weight: bold;">
                  <th></th>
                  <th translate>Description</th>
                  <th translate>Code</th>
                  <th translate>Address</th>
                  <th translate>City</th>
                  <th *ngIf="isInstallerSelectionButtonVisible"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let installer of getItemsToShow(); index as i">
                  <tr [ngStyle]="{'background-color': i % 2 !== 0 ? '#EBEFF3' : 'white' }">
                    <td>
                      <button type="button" class="btn btn-link" (click)="visible[i] = !visible[i]"
                        [attr.aria-expanded]="visible[i]" [attr.aria-controls]="'collapse' + i">
                        <i class="rgifont" [ngClass]="{'rgi-plus': !visible[i], 'rgi-minus': visible[i]}"></i>
                      </button>
                    </td>
                    <td data-qa="installer-description">{{ installer.businessName }}</td>
                    <td data-qa="installer-code">{{ installer.installerCode }}</td>
                    <td data-qa="installer-address">{{ installer.address }}</td>
                    <td data-qa="installer-city">{{ installer.city }}</td>
                    <td class="text-right"
                        *ngIf="isInstallerSelectionButtonVisible"
                    >
                      <button [disabled] = "!selectableModal" type="button" class="btn btn-outline-primary font-weight-bold" (click)="select(installer)"
                        data-qa="select-subject-btn" translate><i class="rgifont rgi-chevron-right" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" style="padding: 0px;">
                      <div [id]="'collapse' + i" [ngbCollapse]="!visible[i]" style="padding: 1rem;">
                        <div class="h4 text-primary" translate>Holder</div>
                        <div class="d-flex">
                          <span class="flex-grow-1">{{ 'Primary Phone' | translate }}:
                            <b>{{ installer.phoneNumber1 }}</b></span>
                        <span class="flex-grow-1">{{ 'Secondary Phone' | translate }}:
                            <b>{{ installer.phoneNumber2 }}</b></span>
                      </div>
                      <hr style="margin: 5px 0px">
                      <span class="col-6">{{ 'Fax' | translate }}: <b>{{ installer.faxNumber }}</b></span>
                    </div>

                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div style="display: inline-flex; margin-top: 1rem;">
            <button (click)="setPage(page-1)"
                    [disabled]="page === 0"
                    class="btn btn-outline-primary"
                    type="button"
            >
              <i aria-hidden="true" class="rgifont rgi-chevron-left"></i>
            </button>
            <button (click)="setPage(n)"
                    *ngFor="let pageNumber of getPages(); index as n"
                    [disabled]="n === page"
                    [ngClass]="{'font-weight-bold':n === page}"
                    class="btn btn-outline-primary"
                    type="button"
            >
              {{ n + 1 }}
            </button>
            <button (click)="setPage(page+1)"
                    [disabled]="page+1 === getPages().length"
                    class="btn btn-outline-primary"
                    type="button">
              <i aria-hidden="true" class="rgifont rgi-chevron-right"></i>
            </button>
          </div>
        </div>
        <div *ngIf="!installers || installers.length === 0">
          <span translate>No installer found for zip code</span><span> {{ zipCode }}</span>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-installer-modal-close" translate>Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

import {GenericEntity} from './entities';
import {PNC_ACTIONS} from '../../constants/actions';

export class RgiFormField {
  allowedValues?: GenericEntity[];
  code: string;
  editable: boolean;
  label?: string;
  mandatory: boolean;
  maxValue?: string;
  minValue?: string;
  order?: number;
  pattern?: string;
  type: RGI_FIELD_TYPE;
  value?: string | number | boolean | Date | any[] | any;
  visible: boolean;
  dropContainerLabel?: string;
  dropContainerViewField?: string;
  dropContainerAction?: PNC_ACTIONS | string;
  dropContainerInputData?: any[];
}

export enum RGI_FIELD_TYPE {
  LIST = 'LIST',
  NUMERIC = 'NUM',
  BOOLEAN = 'BOOL',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  STRING = 'STRING',
  INSURANCE_HOUR = 'INSURANCE_HOUR',
  CHECKBOX = 'CHECKBOX',
  MULTISELECT = 'MULTISELECT',
  DROPCONTAINER = 'DROPCONTAINER',
}

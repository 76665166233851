<div class="modal-header">
    <h4 class="modal-title" translate>{{title}}</h4>
</div>
<div class="modal-body" [innerHTML]="html"></div>
<div class="modal-footer">
    <div class="app-modal-footer">
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button (click)="onClose()" class="btn btn-warning" data-qa="close-button-action" translate>
                    lpc_close_button
                </button>
            </div>
        </div>
    </div>
</div>
export enum PNC_POSTSALES_OPERATIONS_TYPES {
  CANCELLATION = '3',
  EXCLUSION = '10',
  COMM_DATA_AMENDMENTS = '4',
  ASSETS_DATA_AMENDMENTS = '5'
}

export const pncPostsalesOperationDescMap = new Map<PNC_POSTSALES_OPERATIONS_TYPES | string, string>(
  [
    [PNC_POSTSALES_OPERATIONS_TYPES.CANCELLATION, 'standard-cancellation'],
    [PNC_POSTSALES_OPERATIONS_TYPES.EXCLUSION, 'standard-exclusion']
  ]
);

export enum PNC_POSTSALES_OPERATIONS_CODE {
  POLICY_REACTIVATION = 'VDRIMV',
  MOVEMENTS_REVERSAL = 'VD0062',
  TECHNICAL_ACCOUNTING_APPENDIX = 'VD0059',
  POLICY_TRANSFER = 'VD0007'
}

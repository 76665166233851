<div class="box-detail" *ngIf="contractDetails?.movements">
  <header class="box-detail-header" translate>_GP_._TITLE_._MOVEMENTS_</header>
  <div class="box-detail-body">
    <table rgi-rx-table [dataSource]="contractDetails.movements">
      <ng-container rgiRxColumnDef="movDescr">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._DESCRIPTION_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.description}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="movEffectDate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._EFFECTIVE_DATE_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.effectDate | gpDate}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="movAccTransAmount">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._ACC_TRANS_AMOUNT_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          {{(row.accTransAmount != 0) ? (row.accTransAmount | currency:'EUR':true) : '-'}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="movAccTransState">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._ACC_TRANS_STATE_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.accTransState ? row.accTransState : '-'}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="action">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._ACTIONS_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <button *ngIf="row.idMov !== latestMovId" rg-rx-button class="rgi-ui-btn-outline rgi-ui-icon-search"
                  (click)="showMovementDetail(row.idMov)"></button>
        </td>
      </ng-container>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedMovColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedMovColumns;"></tr>
    </table>
  </div>
</div>

import {Subject} from 'rxjs';
import {OnDestroy, Type} from '@angular/core';

/**
 * @deprecated use @Destroyable instead. This will be removed in the next major version.
 * @description since this implementation does not allow to define the destroy$ property name, it is not possible to use safely.
 * Also, it does not allow to use the auto unsubscribe feature neither works with instances defining their own ngOnDestroy.
 * @param Base - the base class to extend
 */
export function WithSubscriptions<T extends Type<{}>>(Base: T = (class {} as any)): any {
  return class extends Base implements OnDestroy {
    protected destroy$  = new Subject<void>();

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }
  };
}



import {Inject, Injectable, Injector, Optional} from '@angular/core';
import {
  RGI_RX_DROP_SELECTION_DATA,
  RGI_RX_DROP_SELECTION_HANDLER,
  RgiRxDropContainerSelectData,
  RgiRxDropSelectionConcreteType,
  RgiRxDropSelectionFn,
  RgiRxDropSelectionHandler,
  RgiRxDropSelectionHandlers,
  RgiRxDropSelectionModal,
  RgiRxDropSelectionService,
  RgiRxDropSelectionType,
  RgiRxOnSelectNode
} from './rgi-rx-drag-drop.api';
import {flatten, LoggerFactory, RgiRxVirtualDOMError} from '@rgi/rx';
import {Observable} from 'rxjs';
import {ModalService} from '../modal/modal.service';

@Injectable()
export class RgiRxDropSelectionResolverService {
  private readonly dropSelectionTokens = new Map<string, RgiRxDropSelectionHandler>();
  private readonly logger = LoggerFactory();

  constructor(
    private modalService: ModalService,
    private injector: Injector,
    @Optional() @Inject(RGI_RX_DROP_SELECTION_HANDLER) _dropSelectionDialogToken?: RgiRxDropSelectionHandlers[]
  ) {
    if (_dropSelectionDialogToken) {
      flatten(_dropSelectionDialogToken).forEach(
        token => {
          if (this.dropSelectionTokens.has(token.name)) {
            this.logger.debug(`RgiRxDropSelectionResolverService::init ${token.name} has been override by a new configuration`, {
              old: this.dropSelectionTokens.get(token.name),
              current: token
            });
          }
          this.dropSelectionTokens.set(token.name, token);
        }
      );
    }
  }


  private resolveSelectionComponent(selection: RgiRxDropSelectionType | string): RgiRxDropSelectionConcreteType {
    if (typeof selection === 'string') {
      if (!this.dropSelectionTokens.has(selection)) {
        throw new RgiRxVirtualDOMError(`The specified DropSelectionComponent ${selection} cannot be resolve. Please register it as a RGI_RX_DROP_SELECTION_DIALOG`);
      }
      return this.dropSelectionTokens.get(selection).handler as RgiRxDropSelectionConcreteType;
    }
    return selection as RgiRxDropSelectionConcreteType;
  }


  getSelection$(selectDialog: RgiRxDropSelectionType | string, model: any, selectData: any): Observable<any> {
    const resolvedType = this.resolveSelectionComponent(selectDialog);
    const selectionData: RgiRxDropContainerSelectData<any, any> = {
      selectData,
      data: model
    };
    if (this.isFn(resolvedType)) {
      return resolvedType(selectionData);
    } else if ('onSelect$' in new resolvedType()) {
      return this.injector.get<RgiRxOnSelectNode<any, any, any>>(resolvedType).onSelect$(selectionData);
    }
    return this.modalService.openComponent(resolvedType, model, [
      {
        provide: RGI_RX_DROP_SELECTION_DATA,
        useValue: selectionData
      }
    ])
      .modal
      .onClose
      .asObservable();
  }


  private isFn(fn: RgiRxDropSelectionModal | RgiRxDropSelectionService<any, any, any> | RgiRxDropSelectionFn<any, any, any>): fn is RgiRxDropSelectionFn<any, any, any> {
    return (fn as RgiRxDropSelectionFn<any, any, any>).prototype === undefined;
  }
}

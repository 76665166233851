import {Injectable} from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {PNC_POSTSALES_OPERATIONS_TYPES} from '../resources/enums/pnc-postsales-operations-types';

@Injectable()
export class PncPostSalesIntegrationService {

  constructor(protected routingService: RoutingService) { }

  closeSession(id: string) {
    this.routingService.destroy(id);
  }

  backToPrevPage(_id: string, _idParentSession: string) {
    this.routingService.destroy(_id);
  }

  navigate(destinationRoute: string, routeData: any, routingService: RoutingService, activeRoute?: ActiveRoute, processIsStarting?: boolean, sessionTitle?: string) {
    routingService.navigate(destinationRoute, routeData, activeRoute ? activeRoute.id : null);
  }

  endNavigation(activeRouteId: string, idParentSession: string) {
    this.closeSession(activeRouteId);
    this.closeSession(idParentSession);
  }

  changeTitle(id: string, msg: string, policyNumber: string) {
  }

  openPolicyDetail(id: string, contractId: string, policyNumber: string) {
  }

  openPolicyTemporaryDetail(id: string, resourceId: string, policyNumber: string) {
  }

}

import { PolicyService } from './../../services/policy-service';
import { DamageRisk, DamagesInstalments } from './../../models/damage.model';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Installment, PolicyPremium } from '../../models/policy.model';

@Component({
  selector: 'lic-premium-table',
  templateUrl: './premium-table.component.html',
  styleUrls: ['./premium-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PremiumTableComponent implements OnInit {
  public locale = 'it-IT';
  public optionCurrency: Intl.NumberFormatOptions = { style: 'currency' };

  @Input() primaRata: Installment;
  @Input() policyPremium: PolicyPremium;
  @Input() ifNotSingleFrequency: boolean;

  @Input() damagesInstalment: DamagesInstalments[];
  @Input() damageRisk: DamageRisk[];
  danni: boolean;

  annualPremium = null;

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
    this.optionCurrency.currency = policyService.currencyCode;
  }

  ngOnInit() {
    this.danni = this.damagesInstalment != null && this.damagesInstalment.length !== 0;

    this.calculateAnnualPremium();

  }

  calculateAnnualPremium() {
    this.annualPremium = {
      accessories: 0,
      net: 0,
      gross: 0,
      fractioningInterestsAccessories: 0,
      fractioningInterestsNet: 0,
      taxes: 0,
    };

    this.damageRisk.map((risk) => {
      this.annualPremium.accessories += +risk.annualPremium.accessories;
      this.annualPremium.net += +risk.annualPremium.net;
      this.annualPremium.gross += +risk.annualPremium.gross;
      this.annualPremium.fractioningInterestsAccessories += +risk.annualPremium.fractioningInterestsAccessories;
      this.annualPremium.fractioningInterestsNet += +risk.annualPremium.fractioningInterestsNet;
      this.annualPremium.taxes += +risk.annualPremium.taxes;
    });
  }

  sommaFractioningInterests(ex1, ex2): string {
    return ((+ex1) + (+ex2)).toString();
  }

  sommaImponibile(ex1, ex2, ex3, ex4): string {
    return ((+ex1) + (+ex2) + (+ex3) + (+ex4)).toString();
  }

}

	import { LpcTranslationsKeys } from '../lpc-translations-keys';
	
	/**
	 * !! IMPORTANT !!
	 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
	 *
	 * @description
	 * This is the list of all the translations keys used in the application for the life-postsales-card library.
	 */
	export const LPC_CA_ES: LpcTranslationsKeys = {
		lpc_Investment_profiles: 'Perfils d\'inversió',
		lpc_payment_method: 'Mètodes de pagament',
		lpc_payment_type: 'Tipus de pagament',
		lpc_investment_funds: 'Fons d\'inversió',
		lpc_fund_type: 'Tipus de fons',
		lpc_profile_type: 'Tipus de perfil',
		lpc_elements: 'elements',
		lpc_fund_name: 'Nom del fons',
		lpc_profile_name: 'Nom del perfil',
		lpc_profiles: 'Perfils',
		lpc_funds: 'Fons',
		lpc_division_type: 'Tipus de divisió',
		lpc_division_description: 'Descripció de l\’assignació',
		lpc_start_validity_date: 'Data d\'inici de la validesa',
		lpc_fund_description: 'Descripció del fons',
		lpc_ISIN_code: 'Codi ISIN',
		lpc_Allocation: 'Assignació',
		lpc_profile_details: 'Detalls del perfil',
		lpc_fields_generic_error: '',
		lpc_portfolio_fund_display: 'Visualització de la cartera de fons d\'inversió',
		lpc_payment_data: 'Dades de pagament',
		lpc_Warning_you_are_deleting_the_contractor: 'Avís, esteu eliminant la part contractant. Voleu continuar?',
		lpc_Investment_amount: 'Quantitat d\'inversió:',
		lpc_mov_status: 'Títol d\'estat',
		lpc_mov_Amount: 'Import del títol',
		lpc_ticket_status_label: 'Valoració pendent de les quotes',
		lpc_occurenceNumber: '',
		lpc_status_investment_label: 'Estat de la inversió',
		lpc_order_status_label: 'Estat ordre',
		lpc_selected_funds: 'Fons seleccionats',
		lpc_Premium: 'Prima',
		lpc_InvestmentByFund: 'Inversió per fons',
		lpc_Loading: 'Taxes',
		lpc_NetPremium: 'Prima neta',
		lpc_IntFract: 'Fraccionament dels interessos',
		lpc_Fees: 'Càrrecs fixos',
		lpc_Taxes: 'Imposts',
		lpc_GrossPremium: 'Prima bruta',
		lpc_FirstInstallment: 'Primera quota',
		lpc_AnnualPremium: 'Prima anual',
		lpc_NextInstallment: 'Quota següent',
		lpc_Guarantee: 'Garantia',
		lpc_BenefitOnDate: 'Benefici a data',
		lpc_Benefit: 'Benefici',
		lpc_AnnualSinglePremium: 'Prima anual/única',
		lpc_ActiveGuarantees: 'Garanties actives',
		lpc_Enhancement: 'Dades financeres',
		lpc_CalculationData: 'Dades de càlculs',
		lpc_ControvaloreAllaData: 'Valor borsari a data',
		lpc_AccumulationOfPrizes: 'Suma de les primes',
		lpc_TotalSurrender: 'Total del rescat',
		lpc_AccumulationOfReducedPremiums: 'Suma de les primes reduïdes',
		lpc_TotalBalance: 'Balanç total',
		lpc_EffectiveDate: 'Data d\'entrada en vigor',
		lpc_Movement: 'Moviment',
		lpc_Movements: 'Moviments',
		lpc_booking_number: 'Números de la reserva',
		lpc_booking_policyData: 'Dades de la pòlissa',
		lpc_booking_assetData: 'Dades del bé assegurat',
		lpc_PolicyLinked: 'Pòlisses vinculades',
		lpc_Motivo_Legame: 'Motivació dels bons',
		lpc_ProposalIssueDate: 'Data d\'emissió de la proposta',
		lpc_PolicyIssueDate: 'Data d\'emissió de la pòlissa',
		lpc_ExpirationDate: 'Data de caducitat',
		lpc_NextInstalmentDate: 'Data de la quota següent',
		lpc_found_duplicate: 'Avís',
		lpc_duplicate_subject_message: 'El subjecte seleccionat ja hi és entre els beneficiaris',
		lpc_SelectSettlement: 'Escolliu la liquidació',
		lpc_You_must_select_the_settlement_to_perform_the_variation: 'Heu de seleccionar la liquidació per executar la variació',
		lpc_beneficiaryPercentage: 'Percentatge per al subjecte',
		lpc_beneficiaryPercentage_greater: 'Ha de ser més gran que',
		lpc_beneficiaryPercentage_lower: 'Ha de ser més petit que',
		lpc_beneficiaryDuplicate: 'Dupliqueu el subjecte',
		lpc_NoOperations: 'No hi ha accions disponibles',
		lpc_confirm: 'Confirmeu',
		lpc_description: 'Descripció',
		lpc_issueDate: 'Data d\'emissió',
		lpc_effectiveDate: 'Data d\'entrada en vigor',
		lpc_MovementData: 'Cancel·leu l\'apèndix',
		lpc_Are_you_sure_you_want_to_confirm_this_claim: 'Voleu confirmar aquest sinistre?',
		lpc_Are_you_sure_you_want_to_cancel_this_claim: 'Voleu cancel·lar aquest sinistre?',
		lpc_warnings: 'Avisos',
		lpc_warning: 'Avís',
		lpc_warning_message: 'Seleccioneu \'PETICIÓ d\'AUTORITZACIÓ\' per enviar una sollicitud d\'aprovació per fer l\’operació',
		lpc_ClaimCanceled: 'El sinistre s’ha cancel·lat correctament',
		lpc_ClaimConfirmed: 'El sinistre s’ha confirmat correctament',
		lpc_reserve: 'deseu',
		lpc_questionnaire: 'Qüestionari',
		lpc_bonus_amount: 'IMPORT DE LA BONIFICACIÓ',
		lpc_life_detail_beneficiary: 'Beneficiari',
		lpc_life_detail_percentage: 'Percentatge',
		lpc_life_detail_net_amount: 'Quantitat neta',
		lpc_life_detail_questionnaire: 'Qüestionari',
		lpc_life_detail_beneficiaries: 'Beneficiaris',
		lpc_AppendixNumber: 'Número de l’apèndix',
		lpc_nessuna_variazione: 'Sense variació',
		lpc_appendixLabel: 'Apèndix del beneficiari',
		lpc_operation_rescission_reason: 'Motiu de la rescissió',
		lpc_Lien_type_creditor: 'Tipus de creditor',
		lpc_creditor: 'Creditor',
		lpc_Open_lien: 'Dret de retenció obert',
		lpc_assignment_holder_type: 'Tipus de titular de l’assignació',
		lpc_assignment_holder: 'Titular de l’assignació',
		lpc_assignment_amount: 'Import',
		lpc_assignment_policy_number: 'Número de contracte',
		lpc_assignment_company: 'Societat',
		lpc_assignment_type: 'Tipus d\'assignació',
		lpc_assignment_benefit: 'Benefici',
		lpc_amount_less_of_benefit: 'L’import ha de ser més petit que el benefici',
		lpc_open_assignment: 'Assignació oberta',
		lpc_sum_det_cancel_proposal: 'Cancel·leu la proposta',
		lpc_sum_det_success_cancel: 'La proposta s’ha cancel·lat correctament',
		lpc_sum_det_success_cancel_prev: 'El pressupost s’ha cancel·lat correctament',
		lpc_sum_det_confirm_cancel: 'Voleu confirmar la cancel·lació de la proposta?',
		lpc_sum_det_confirm_cancel_prev: 'Voleu confirmar la cancel·lació d\'aquest pressupost?',
		lpc_sum_det_confirm_prop_issue: 'El pressupost s’emetrà. Voleu confirmar?',
		lpc_sum_det_confirm_pol_issue: 'La pòlissa s’emetrà. Voleu confirmar?',
		lpc_health_questionnaire: 'Qüestionari de salut',
		lpc_quotation_show_details: 'Mostreu la informació',
		lpc_quotation_premium_details: 'Informació de la prima',
		lpc_quotation_warranties_details: 'Informació de les garanties',
		lpc_quotation_commissions_details: 'Comissions',
		lpc_Reference_Third_Party: 'Tercers de referència',
		lpc_policyHolder: '',
		lpc_DelegateEqualToPolicyHolder: 'Delegat igual a l’assegurat?',
		lpc_LegalGuardianEqualToPolicyHolder: 'Representant legal igual a l’assegurat?',
		lpc_ThirdPayerEqualToPolicyHolder: 'Tercer pagador igual que l\’assegurat?',
		lpc_DelegateEqualToPolicyHolderWarning: 'Subjecte amb rol de delegat igual a l’assegurat',
		lpc_LegalGuardianEqualToPolicyHolderWarning: 'Subjecte amb rol de representant legal a l’assegurat',
		lpc_ThirdPayerEqualToPolicyHolderWarning: 'Subjecte amb rol de tercer pagador igual a l’assegurat',
		lpc_but_confirm_accept: 'Conformeu i accepteu',
		lpc_Date_Start_Variation: 'Data d\'inici de la variació',
		lpc_New_Frequent_Payment: 'Nou pagament freqüent',
		lpc_start_date_profit: 'Data d\'inici dels beneficis',
		lpc_end_date_profit: 'Data de finalització dels beneficis',
		lpc_last_rate_profit: 'Darrera taxa de beneficis',
		lpc_last_profit: 'Darrers beneficis',
		lpc_Fund_Code: 'Codi del fons',
		lpc_OperationSynstesis: 'Síntesi de les operacions',
		lpc_PreviusPerceent: 'Percentatge previ',
		lpc_PreviusValue: 'Valor previ',
		lpc_NumberOfUnit: 'Número previ d\'unitat',
		lpc_PostPErcent: 'Percentatge del lloc',
		lpc_PostValue: 'Valor del lloc',
		lpc_PostNumberOfUnit: 'Número de lloc del pressupost',
		lpc_Dati: 'Dades',
		lpc_PIP_Data: 'Dades de PIP',
		lpc_PIP_General: 'Dades generals',
		lpc_PIP_Contribution: 'Contribució PIP',
		lpc_HolderData: 'Dades assegurades',
		lpc_RiskData: 'Dades de risc',
		lpc_IBAN_error: 'L’IBAN no és correcte',
		lpc_BACK: 'Enrere',
		lpc_ACTIONS: 'Accions',
		lpc_DETAIL: 'Informació',
		lpc_CANCEL: 'Cancel·leu la proposta',
		lpc_CONFIRM_PROPOSAL: 'Confirmeu la proposta',
		lpc_CONFIRM_POLICY: 'Confirmeu la pòlissa',
		lpc_not_linked_detail: 'Informació de {{name}}',
		lpc_quote_number: 'Número de pressupost',
		lpc_valid_quote: 'Pressupost vàlid',
		lpc_BUY_PREV: 'Compreu',
		lpc_EDIT_PREV: 'Editeu',
		lpc_EDIT: 'Editeu',
		lpc_CANCEL_PREV: 'Suprimiu',
		lpc_modify_quote: 'Modifiqueu el pressupost',
		lpc_modify_proposal: 'Modifiqueu la proposta',
		lpc_buy_quote: 'Pressupost de compres',
		lpc_Close_assignment: 'Assignació tancada',
		lpc_Close_lien: 'Dret de retenció tancat',
		lpc_disinvestment_funds: 'Fons de desinversió',
		lpc_error_mandatory_fields: 'Els camps en vermell són obligatoris o contenen valors incorrectes',
		lpc_disinvestment_msg: '{{amount}} per desinvertir o {{percent}}',
		lpc_disinvestment_allocation_msg: 'La asignación de inversión supera el importe disponible',
		lpc_maximum_percentage: 'Porcentaje máximo aceptado',
		lpc_maximum_amount: 'Cantidad máxima aceptada',
		lpc_minimum_percentage: 'Porcentaje mínimo aceptado',
		lpc_minimum_amount: 'Cantidad mínima aceptada',
		lpc_investment_msg: '{{amount}} per invertir o {{percent}}',
		lpc_Fields_marked_in_red_are_required: 'Els camps en vermell són obligatoris',
		lpc_amount_format_validation: 'L’import no és vàlid, el separador decimal és incorrecte. \'Feu servir \'.\' per corregir-lo',
		lpc_select_at_least_one_beneficiary: 'Seleccioneu almenys un beneficiari',
		lpc_quote_due_date: 'Data d\'emissió del pressupost',
		lpc_roles_variation: 'Rols',
		lpc_fiscal_data: 'Dades fiscals',
		lpc_data_calc: 'Data del càlcul',
		lpc_tipo_liquidazione: 'Tipus de liquidació',
		lpc_valore_polizza: 'Valor de la pòlissa',
		lpc_imponibile_imp: 'Impost imposable',
		lpc_imposta_base: 'Base imposable',
		lpc_imposta_calcol: 'Impost calculat',
		lpc_imposta_da_add: 'Impost que s’ha de pagar',
		lpc_imposta_add: 'Impost meritat',
		lpc_imposta_bollo: 'Timbre fiscal',
		lpc_fiscal_data_detail: 'Informació de dades fiscals',
		lpc_quote_white_list: 'Llista blanca de pressupost',
		lpc_data_rif: 'Data de referència',
		lpc_tipo_perc: 'Percentatge de la llista blanca',
		lpc_CancellInstallment: 'Resum de la sol·licitud de l’anul·lació de la quota',
		lpc_DescOperazione: 'Descripció de l’operació',
		lpc_RateAmount: 'Import total de la quota',
		lpc_OPEN_LATEST_VERSION: 'Darrera versió oberta',
		lpc_TOTAL_INVESTED: 'Total invertit',
		lpc_REMAINING_AMOUNT: 'Import romanent',
		lpc_descrizione_rischio: 'Descripció dels riscs',
		lpc_prestazione_ridotta_iniz: 'Reducció de la prestació original',
		lpc_prestazione_rivalutata: 'Reducció de la revalorització del benefici',
		lpc_provvigioni_da_recuperare: 'Comissió que s’ha de recuperar',
		lpc_reg41: 'Nominatiu amb qui comunicar-se',
		lpc_disinvestment_amount: 'Import de desinversió',
		lpc_occurrenceNumber: 'Número d\'incidència',
		lpc_frequecy: 'Freqüència',
		lpc_the_occurenceNumber_must_be_a_value: 'El Número d\'incidència ha de ser més gran que {{min}}',
		lpc_disinvestment_range: 'Introduïu un import per desinvertir superior a {{max}} i inferior o igual que {{min}}',
		lpc_state: 'Estat',
		lpc_substate: 'Subestat',
		lpc_product_type: 'Tipus de producte',
		lpc_managementnode: 'Node de gestió',
		lpc_subagency: 'Subagència',
		lpc_branch_name: 'Marca',
		lpc_agreement: 'Acord',
		lpc_payment_frequency: 'Freqüència de pagament',
		lpc_mean_of_payment: 'Mitjans de pagament',
		lpc_Subcause: 'Subcausa',
		lpc_premium_payment_suspension_date: 'Data de suspensió del pagament de la prima',
		lpc_imposta_sostitutiva: 'Impost substitutiu',
		lpc_novazione_contr: 'Novació contractual',
		lpc_acc_premium_initial_2011: 'Acumulació de la prima 31/12/2011 inicial',
		lpc_amount_intital_2011: 'Import 31/12/2011 inicial',
		lpc_acc_premium_2011: 'Acumulació de la prima 31/12/2011',
		lpc_amount_2011: 'Import 31/12/2011',
		lpc_acc_premium_initial_2014: 'Acumulació de la prima 30/06/2014 inicial',
		lpc_acc_premium_2014: 'Acumulació de la prima 30/06/2014',
		lpc_amount_intital_2014: 'Import 30/06/2014 inicial',
		lpc_amount_2014: 'Import 30/06/2014',
		lpc_acc_premium_initial_2022: 'Suma inicial de les primes al 31/12/2022',
		lpc_acc_premium_2022: 'Suma de les primes al 31/12/2022',
		lpc_amount_intital_2022: 'Capital inicial i interessos al 31/12/2022',
		lpc_amount_2022: 'Capital i interessos al 31/12/2022',
		lpc_acc_premium_intial_tax_change: 'Modificació inicial en l’acumulació de l’impost sobre les primes',
		lpc_amount_initial_tax_change: 'Import inicial del canvi fiscal',
		lpc_acc_premium_tax_change: 'Canvi fiscal de l’acumulació de la prima',
		lpc_amount_tax_change: 'Import del canvi fiscal',
		lpc_regime_change_date: 'Data de canvi fiscal',
		lpc_please_enter_a_disinvestment_amount: 'Introduïu un import de desinversió del fons {{fund}}',
		lpc_please_select_at_least_a_disinvestment: 'Seleccioneu almenys una desinversió',
		lpc_reg41Msg: 'Receptor de les comunicacions',
		lpc_cost: 'Cost',
		lpc_paymentFrequencyInterest: 'Freq. inter.',
		lpc_netpremium: 'Prima neta',
		lpc_grosspremium: 'Prima bruta',
		lpc_information: 'Informació:',
		lpc_reinvest_data_detail: 'Informació sobre les reinversions',
		lpc_polizza: 'Pòlissa',
		lpc_data_effetto_mov: 'Data d\'entrada en vigor',
		lpc_netto_liquidazione: 'Import net de la liquidació',
		lpc_netto_reinvestire: 'Import net de la reinversió',
		lpc_stato_reinvest: 'Estat de la liquidació de la reinversió',
		lpc_reinvested_settlements: 'Liquidacions reinvertides',
		lpc_loans: 'Préstecs',
		lpc_elaboration_date: 'Data d\'elaboració',
		lpc_calculation_date: 'Data del càlcul',
		lpc_annuity_data: 'Informació de la renda vitalícia',
		lpc_extra_premium: 'Prima extra',
		lpc_loan_interest: 'Interessos del préstec',
		lpc_fractionation_interest: 'Interessos de fraccionament',
		lpc_default_interest: 'Interès per falta de pagament',
		lpc_reactivation_interest: 'Interès de reactivació',
		lpc_installment_detail: 'Informació de la quota',
		lpc_effectiveAnnuityDate: 'Data d\'entrada en vigor de l’anualitat',
		lpc_firstInstallmentDate: 'Data de la primera quota',
		lpc_expiryAnnuityDate: 'Data de venciment de l’anualitat',
		lpc_amountFirstInstallment: 'Import de la primera quota',
		lpc_initialAnnuityAmount: 'Import inicial de l’anualitat',
		lpc_periodicityInstallmentAnnuity: 'Periodicitat de l’anualitat de la quota',
		lpc_typePaymentAnnuity: 'Tipus de pagament de l’anualitat',
		lpc_performanceType: 'Tipus de rendiment',
		lpc_annuityType: 'Tipus d\'anualitat',
		lpc_annuity_detail: 'Informació de l’anualitat',
		lpc_canceled_liquidation_detail: 'Informació sobre la cancel·lació de la liquidació',
		lpc_Percentage: 'Percentatge',
		lpc_loan_data: 'Informació sobre el préstec',
		lpc_maxLoanAmount: 'Import màxim pagable',
		lpc_loanRepaymentProcedure: 'Procediment de reembossament del préstec',
		lpc_loanAmount: 'Import del préstec',
		lpc_rateType: 'Tipus d\'interès del préstec',
		lpc_maxLoanAmountKO: '0 € (error en el càlcul de l’import màxim pagable)',
		lpc_grant_date: 'Data de la concessió',
		lpc_loan_amount: 'Import del préstec',
		lpc_return_way: 'Mètode de la devolució',
		lpc_interest_rate_type: 'Tipus d\'interès',
		lpc_residual_amount: 'Import residual',
		lpc_refund_type: 'Tipus del reembossament',
		lpc_refund_amount: 'Import del reembossament',
		lpc_select_lpc_refund_type: 'Seleccioneu un tipus de reembossament',
		lpc_leftInsuredAmount: 'Capital assegurat residual',
		lpc_insuredAmount: 'Import invertit',
		lpc_reactivatedCapital: 'Capital reactivat',
		lpc_premium_reactivation: 'reactivació_prima',
		lpc_negative_amount: 'Import residual negatiu',
		lpc_zero_amount: 'No es troba l’import residual Introduïu un Import superior a 0',
		lpc_error_mandatory_selected_type_of_refund: 'Seleccioneu un tipus de reembossament',
		lpc_select_refund: 'Seleccioneu almenys un préstec',
		lpc_selected_profiles: 'Perfils seleccionats:',
		lpc_coupon_beneficiary: 'Beneficiari del cupó',
		lpc_bookings: 'Reserves',
		lpc_booking_type: 'Tipus de reserves',
		lpc_booking_state: 'Estats',
		lpc_booking_effective_date: 'Data d\'entrada en vigor de la reserva',
		lpc_booking_extrapremiumHealth: 'Prima extra de salut',
		lpc_booking_extrapremiumProf: 'Prima extra professional',
		lpc_booking_extrapremiumSport: 'Prima extra esportiva',
		lpc_booking_extrapremiumOther: 'Una altra prima extra',
		lpc_booking_effectiveDate: 'Data d\'entrada en vigor',
		lpc_booking_detail_label: '',
		lpc_Rischi: 'Cobertures',
		lpc_booking_destinationNode: 'Node de destí',
		lpc_booking_cause: 'Causa',
		lpc_PolicyData: 'Dades de la pòlissa',
		lpc_booking_cancellationDate: 'Data de cancel·lació',
		lpc_extra_premiums: 'Primes extres',
		lpc_error_reservation_cancel: 'Error en la cancel·lació del {{descr}} número {{num}}',
		lpc_success_reservation_cancel: 'La cancel·lació del {{descr}} número {{num}} s’ha confirmat',
		lpc_incomplete_disinvestment_for_the_funds: 'Desinversió incompleta dels fons',
		lpc_Sinistro_morte: 'Sinistre per mort',
		lpc_Sinistro_invalidita: 'Sinistre d\'invalidesa',
		lpc_Sinistro_inabilità: 'Sinistre d\'incapacitat',
		lpc_Malattia_grave: 'Sinistre per malaltia greu',
		lpc_insured_form: 'Formulari per a l’assegurat',
		lpc_other_contract_list: 'Una altra llista de contractes',
		lpc_modified_personal_data_for_the_subject: 'Dades personals modificades del subjecte',
		lpc_subject_roles: 'Rols del subjecte',
		lpc_no_variation_modpd: 'No s’han fet modificacions, modifiqueu el subjecte',
		lpc_DeductiblePremium: 'Prima deduïble',
		lpc_firstterm: 'Primer trimestre',
		lpc__1TERM_ATOOL: 'Data d\'activació de l’eina',
		lpc__1TERM_MTOOL: 'Modifiqueu l’eina de modificació',
		lpc__1TERM_DTOOL: 'Data d\'eliminació l’eina',
		lpc_management_fees: 'Comissions de gestió',
		lpc_investment_not_found: 'No hi ha elements per seleccionar',
		lpc_No_policies: 'No hi ha pòlisses per al subjecte modificat',
		lpc_variation_convention: 'Variació de la convenció',
		lpc_andamento_polizza_alla_data: 'Evolució de la Pòlissa fins a la data',
		lpc_appendice_dichiarativa: 'Apèndix de la declaració',
		lpc_policy_number_dot: 'Núm. de la pòlissa',
		lpc_investment_sum_must_be100: 'La suma de les inversions ha de ser igual al 100 %',
		lpc_Operation_detail: 'Informació de les operacions',
		lpc_Notwithstanding_any_ongoing_operations: 'Els valors poden estar subjectes a modificacions a causa de les operacions en curs',
		lpc_percentage_max_zero: 'Introduïu un percentatge superior a 0',
		lpc_Mostra_tutti: 'Veieu-ho tot',
		lpc_Visualizza_dettaglio: 'Veieu la informació',
		lpc_consultazione_storica: 'Consulta històrica',
		lpc_dettaglio_movimento: 'Informació del moviment',
		lpc_mensile: 'mensual',
		lpc_annuale: 'anual',
		lpc_sixmonthly: 'semestral',
		lpc_trimestrale: 'quadrimestral',
		lpc_Titolo_non_incassato: 'Existències sense recollir',
		lpc_Rid: 'DDP',
		lpc_In_attesa_valorizzazione_quote: 'Valoració pendent de les participacions invertides',
		lpc_switch_di_perequazione: 'Modificació programada',
		lpc_SELEZIONA: '--SELECCIONEU--',
		lpc_settimanale: 'setmanalment',
		lpc_Valido: 'Vàlid',
		lpc_quotidiano: 'diari',
		lpc_Assegno: 'Verifiqueu',
		lpc_Bonifico: 'Transferència bancària',
		lpc_Sdd: 'Ordre de domiciliació bancària',
		lpc_severeDisabilityMsg: 'Beneficiari amb discapacitat greu',
		lpc_benDg: 'Beneficiari amb discapacitat',
		lpc_distribution: 'Assignació',
		lpc_Distribution_amount_exceeds: 'L’import de la distribució supera l’import total de la liquidació disponible',
		lpc_distribution_invalid_greater: 'El {{type}} no es vàlid, ha de ser superior a {{min}}',
		lpc_distribution_invalid_greater_equal: 'El {{type}} no es vàlid: ha de ser inferior o igual a {{max}}',
		lpc_perc: 'Percentatge',
		lpc_amount: 'Import',
		lpc_Please_insert_a_distribution: 'Introduïu una distribució',
		lpc_For_the_Beneficiary: 'Per al beneficiari',
		lpc_global_distribution: 'Import total per liquidar ha de ser inferior o igual a {{totalAmount}}',
		lpc_invalid_char: 'El format de {{type}} té caràcters incorrectes',
		lpc_toDistribute: 'Per distribuir',
		lpc_noClaims: 'No s’han trobat sinistres',
		lpc_Data_sinistro: 'Data del sinistre',
		lpc_Data_Pervenimento_Denuncia: 'Data en la qual es va rebre la notificació del sinistre',
		lpc_Data_Denuncia_Sinistro: 'Data de la notificació del sinistre',
		lpc_Tipo_sinistro: 'Tipus de sinistre',
		lpc_inserisci_denuncia: 'Introduïu un sinistre',
		lpc_Conferma_inserimento_denuncia: 'Confirmació de la introducció del sinistre',
		lpc_Denuncia_inserita_correttamente: 'El sinistre s’ha introduït correctament',
		lpc_Stato_denuncia: 'Estat del sinistre',
		lpc_marital_status: 'Estat civil',
		lpc_fiscal_code: 'Codi fiscal',
		lpc_birthdate: 'Data de naixement',
		lpc_address: 'Adreça',
		lpc_sex: 'Sexe',
		lpc_annulla_denuncia: 'Cancel·leu el sinistre',
		lpc_modifica_denuncia: 'Modifiqueu el sinistre',
		lpc_conferma_denuncia: 'Confirmeu el sinistre',
		lpc_new_beneficiary: 'Nou beneficiari',
		lpc_Seleziona: 'Seleccioneu',
		lpc_insert_phisical_sbj_for: 'Introduïu un subjecte físic per al rol {{role}}',
		lpc_insert_legal_sbj_for: 'Introduïu un subjecte legal per al rol {{role}}',
		lpc_payment_incomplete: 'Informació de pagament incompleta',
		lpc_add_mandatory_linkedroles: 'Introduïu rols obligatoris per al subjecte introduït',
		lpc_beneficiary_owner_recipient_must_be_physical: 'El beneficiari efectiu ha de ser una persona física',
		lpc_enter_the_beneficial_owners_of_the_recipient: 'Introduïu els beneficiaris efectius del destinatari',
		lpc_global_percentage_must_be_a_maximum_100: 'El percentatge global ha de ser 100 com a màxim',
		lpc_overall_percentage_must_equal100: 'El percentatge Total ha de ser igual a 100',
		lpc_Questionario_deve_essere_compilato: 'S’ha d\'emplenar el qüestionari',
		lpc_enter_mandatory_data: 'Introduïu la informació obligatòria',
		lpc_enter_an_adult: 'Introduïu un adult',
		lpc_enter_a_beneficial_owner: 'Introduïu un beneficiari efectiu',
		lpc_enter_a_physical_beneficial_owner: 'Introduïu un beneficiari efectiu físic',
		lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: 'El percentatge global dels beneficiaris efectius no pot superar el 100',
		lpc_beneficial_owners_percentages_must_be_greater_than0: 'El percentatge del beneficiari efectiu ha de superar el 0,0',
		lpc_effective_holder_perc: '',
		lpc_effective_holder_duplicated: 'Titular efectiu duplicat',
		lpc_enter_the_minimum_number_of_subjects: 'Introduïu el nombre mínim de subjectes',
		lpc_percentage_must_be_greater_than_0: 'El percentatge ha de superar el 0,0',
		lpc_enter_free_text: 'Introduïu un text lliure',
		lpc_beneficiary_executor: 'Marmessor beneficiari',
		lpc_effective_recipient: 'Receptor efectiu',
		lpc_recipient_owner: 'Propietari receptor',
		lpc_beneficial_owner_of_beneficiary: 'Propietari beneficiari',
		lpc_confirmed_claim_modify: 'Modificació del sinistre confirmada',
		lpc_claim_modified_correctly: 'Sinistre modificat correctament',
		lpc_Assicurato: 'Assegurat',
		lpc_Assicurati: 'Assegurats',
		lpc_holderType: 'Tipus de feina del sol·licitant',
		lpc_ageRetirement: 'Edat en el moment de la jubilació',
		lpc_accessTransfer: 'Sol·licitant de trasllat',
		lpc_emptyContribution: 'Contribució de l’ocupador',
		lpc_firstEmployment: 'Data d\'inici de l’ocupació',
		lpc_oldUnderWritt: 'Antic reclutament',
		lpc_underWitt1993: 'Reclutat el 29/4/1993',
		lpc_maturato2001: 'Meritat a 31/12/2000',
		lpc_maturato2006: 'Meritat a 31/12/2006',
		lpc_riduzImponibiTFR: 'Reducció sobre la TFG imposable',
		lpc_aliquotaTFR: 'Tipus TFR (fiscalitat anterior a 2001)',
		lpc_PIP_Contribution_Type: 'Tipus de contribució',
		lpc_YES: 'SÍ',
		lpc_PIP: 'Pla de pensions individual',
		lpc_PIP_Contribution_Percentage: 'Percentatge',
		lpc_PIP_Contribution_Amount: 'Import',
		lpc_transfer_data: 'Informació de la transferència',
		lpc_unit_questionnaire: 'Qüestionari de la unitat',
		lpc_amount_reimbured: 'Import per reembossar',
		lpc_Descr_not_handled: 'Descripció no gestionada per aquest codi d\'operació',
		lpc_select_transfer: 'Selecció de la transferència',
		lpc_fiscalFolderDataPost2006: 'Sistema fiscal posterior a 2006',
		lpc_fiscalFolderData20012006: 'Sistema fiscal del 2001-2006',
		lpc_fiscalFolderDataBefore2001: 'Sistema fiscal anterior a 2001',
		lpc_filledOut: 'Emplenament',
		lpc_blank: 'Blanc',
		lpc_desc_previousPenFund: 'Fons de pensions anterior',
		lpc_companyPreviousPenFund: 'Nom de la companyia anterior al fons de pensions',
		lpc_typePreviousPenFund: 'Tipus de fons de pensions anterior',
		lpc_note: 'Notes',
		lpc_Benef_irrevocabile: 'Beneficiari irrevocable',
		lpc_PIP_Transfer: 'Transferència a un altre fons',
		lpc_requestDate: 'Data de la sol·licitud',
		lpc_Type_Previous_Pension_Fund: 'Tipus de fons de pensions anterior',
		lpc_Previous_Pension_Fund: 'Fons de pensions anterior',
		lpc_Import_Transfert: 'Import de la transferència',
		lpc_Note: 'Notes',
		lpc_relief: 'Desgravacions fiscals de 2022',
		lpc_selected_settlement: 'Liquidació seleccionada',
		lpc_EDIT_ANONYMOUS_PREV: 'Editeu',
		lpc_invalid_quote: 'Pressupost no vàlid',
		lpc_reasonForRequest: 'Motiu de la sol·licitud',
		lpc_advancePaymentAmount: 'Import de la bestreta del pagament',
		lpc_repaidAdvance: 'Bestreta reemborsada',
		lpc_PIP_AdvancedPayment: 'Bestreta dels pagaments',
		lpc_requestReason: 'Motiu de la sol·licitud',
		lpc_notRepaidAmount: 'L’import encara no ha estat reemborsat',
		lpc_fiscalDate: 'Data d\'entrada en vigor',
		lpc_previousPension: 'Pla de pensions anterior',
		lpc_fiscalCompetence: 'Competència fiscal',
		lpc_settlementStatus: 'Estat de la bestreta del pagament',
		lpc_repaidAmount: 'Import que s’ha reembossat',
		lpc_advancePaymentTransfer: 'Bestreta de les transferències',
		lpc_policy_factors: 'Factors de les pòlisses',
		lpc_asset_factors: 'Factors dels béns assegurats',
		lpc_Date_Variation: 'Línia temporal de la variació',
		lpc_penalties_amount: 'Import de les penalitzacions',
		lpc_tax_amount: 'Import dels imposts',
		lpc_warranties_detail: 'Informació de les garanties',
		lpc_fixedCharges: 'Càrrecs fixos',
		lpc_catastrophe: 'Prima extra de catàstrofes',
		lpc_health: 'Prima extra de salut',
		lpc_other: 'Una altra prima extra',
		lpc_professional: 'Prima extra professional',
		lpc_sports: 'Prima extra esportiva',
		lpc_collectionFee: 'Taxa de cobrament',
		lpc_lambdaFee: 'Taxa de Lambda',
		lpc_managementFee: 'Taxa de gestió',
		lpc_purchaseFee: 'Taxa de compra',
		lpc_continue_button: 'Continueu',
		lpc_confirm_button: 'Confirmeu',
		lpc_authorization_request: 'Solicitud de autorización',
		lpc_questionSTR_invalid: 'La resposta a la pregunta: {{question}}, no és correcta',
		lpc_questionNUM_invalid: 'La quantitat ingressada per a la pregunta {{question}} no és correcta',
		lpc_Beneficiaryowner: 'Beneficiari propietari',
		lpc_confirm_operation: 'Confirmeu l’operació',
		lpc_financial_questionnaire: 'Qüestionari financer',
		lpc_avc_questionnaire: 'Qüestionari AVC',
		lpc_confirm_variation: 'Confirmeu la variació',
		lpc_Declarative_Appendix: 'Apèndix declaratiu',
		lpc_investment_funds_step: 'Fons d\'inversió',
		lpc_disinvestment_profile_step: 'Perfils de desinversió',
		lpc_startDate: 'Data d\'inici',
		lpc_end_date: 'Data de finalització',
		lpc_six_monthly: 'Semestral',
		lpc_quarterly: 'Trimestral',
		lpc_weekly: 'Setmanal',
		lpc_monthly: 'Mensual',
		lpc_annual: 'Anual',
		lpc_daily: 'Diari',
		lpc_start_date_cannot_be_earlier_than_the_effective_date: 'La data d\'inici no pot ser anterior a la data efectiva {{value}}',
		lpc_start_date_must_be_earlier_than_the_end_date: 'La data d\'inici ha de ser anterior a la data de finalització',
		lpc_contract_value: 'Valor del contracte',
		lpc_invested_amount: 'Import invertit',
		lpc_funds_details_and_summary: 'Detalls i resum dels fons',
		lpc_separate_management_detail: 'Detall de gestió separada',
		lpc_denuncia_sinistro: 'Notificació de reclamació',
		lpc_duration_in: 'Durada en',
		lpc_years: 'anys',
		lpc_months: 'mesos',
		lpc_days: 'dies',
		lpc_policy_number: 'Número de pòlissa',
		lpc_proposal_number: 'Número de proposta',
		lpc_LifeBeneficiary: 'Beneficiari en vida',
		lpc_DeathBeneficiary: 'Beneficiari en cas de mort',
		lpc_NO: 'No',
		lpc_claim_data: '',
		lpc_node: 'Node',
		lpc_sbj: 'Assumpte',
		lpc_empty: 'buit',
		lpc_search_result: 'Resultat de la cerca',
		lpc_show_elemets: 'Mostreu els elements',
		lpc_insured: 'Assegurat',
		lpc_notification_status: 'Estat de la notificació',
		lpc_nuova_denuncia: 'Nova notificació',
		lpc_insured_policy: 'Pòlisses assegurades',
		lpc_product: 'Producte',
		lpc_find: 'Cerqueu',
		lpc_status: 'Estat',
		lpc_fund_trend: 'Tendència del fons',
		lpc_category_fund: 'Perfil/Fons',
		lpc_equivalent_on_date: 'Equivalent en la data',
		lpc_quotes_number: 'Nombre d\'unitats',
		lpc_damages_policy: 'Pòlissa de danys',
		lpc_life_policy: 'Pòlissa de vida',
		lpc_next_installment_premium: 'Prima de la pròxima quota',
		lpc_no_result: 'Sense resultats',
		lpc_data_value: 'Data valor participació',
		lpc_paidAmount: 'Import pagat',
		lpc_total_gross_surrender: 'Total de la devolució bruta',
		lpc_left_premiums_paid: 'Primes pagades restants',
		lpc_left_premiums_amount: 'Import de les primes pagades restants',
		lpc_portfolio_volatility_on_subscription_date: 'Volatilitat de la cartera a la data de la subscripció',
		lpc_portfolio_volatility: 'Volatilitat de la cartera',
		lpc_andamento_controvalore: 'Evolució del valor nominal',
		lpc_policy_trend: 'Evolució de la pòlissa',
		lpc_calculate: 'Calculeu',
		lpc_frequency: 'Freqüència',
		lpc_date_variation_step: 'Data de la variació',
		lpc_quotation_step: 'Cotització',
		lpc_confirm_variation_step: 'Confirmeu la variació',
		lpc_financial_questionnaire_step: 'Qüestionari financer',
		lpc_close_button: 'Tanqueu',
		lpc_Operation_of_executed_correctly: 'Operació {{operation}} feta correctament',
		lpc_AuthorizatioPolicyForwarded: 'La sol·licitud d\'autorització per al número de pòlissa {{policyNumber}} s’ha enviat a l’usuari corresponent',
		lpc_are_you_sure_you_want_to_cancel_this_operation: 'Voleu cancel·lar aquesta operació?',
		lpc_cancel_button: 'Cancel·leu',
		lpc_cancel_reservation: 'Cancel·leu la reserva',
		lpc_system_error: 'Error del sistema',
		lpc_generic_error_title: 'Error',
		lpc_select_label: 'Seleccioneu',
		lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: 'Atenció, esteu eliminant el titular de la pòlissa. Voleu continuar?',
		lpc_factor_must_be_equal: 'El {{factor}} ha de ser igual que {{amount}}',
		lpc_factor_must_be_greater_than: 'El {{factor}} ha de ser més gran que {{amount}}',
		lpc_factor_must_be_less_than: 'El {{factor}} ha de ser més petit que {{amount}}',
		lpc_Please_enter_threshold_percentage_value: 'Fons: {{value}} - introduïu el percentatge de llindar',
		lpc_please_enter_a_value_between: 'Introduïu un valor entre {{min}} i {{max}}',
		lpc_variation_step_label: 'Variació',
		lpc_contractual_option: 'Opció contractual',
		lpc_costs_step_label: 'Costs',
		lpc_variation_detail_step_label: 'Detalls de la variació',
		lpc_ongoing_operations: 'Els valors podrien estar subjectes a modificacions a causa de les operacions en curs',
		lpc_exchange_rate: 'Tipus de canvi',
		lpc_last_share_value: 'Darrer valor de l’acció',
		lpc_movement_description: 'Descripció del moviment',
		lpc_settlement_amount: 'Import de l’acord',
		lpc_Annual_SinglePremium: 'Prima anual/única',
		lpc_policy_address: 'Adreça de la pòlissa',
		lpc_holders: 'Titulars',
		lpc_last_revaluation: 'Darrera revalorització',
		lpc_revalueted_benef: 'Benefici revalorat',
		lpc_investment: 'Inversions',
		lpc_position_number: 'Número de posició',
		lpc_invested_premium: 'Prima invertida',
		lpc_operation_details: 'Detalls de l’operació',
		lpc_contract: 'Contracte',
		lpc_dis_date: 'Data de desinversió',
		lpc_gross_amount: 'Import brut',
		lpc_net_amount: 'Import net',
		lpc_net: 'Net',
		lpc_quote: 'Calculeu de nou',
		lpc_quote_and_save: 'Calculeu de nou i deseu',
		lpc_info_investment_complete: 'Informació: \'Inversió completa',
		lpc_effective_holders: 'Titulars efectius',
		lpc_effective_holder: 'Titular efectiu',
		lpc_third_party_role: 'Tercer pagador',
		lpc_lien_credit: 'Motiu de la rescissió',
		lpc_end_validity_date: 'Data de fi de la validesa',
		lpc_start_date: 'Data d\'inici',
		lpc_unit_linked_value: 'Valor unitat vinculada',
		lpc_allocation_profile: 'Perfil de l’assignació',
		lpc_search_filter: 'Filtre de cerca',
		lpc_expiry_option_detail: 'Detall de l’opció de venciment',
		lpc_annuity_frequency: 'Freqüència de l’anualitat',
		lpc_annuity_payment_type: 'Tipus de pagament de l’anualitat',
		lpc_percentage_reversibility: 'Percentatge de reversibilitat',
		lpc_reversionary: 'Reversionari',
		lpc_certain_revenue_years: 'Anys certs d\'ingressos',
		lpc_postponement_type: 'Tipus d\'ajornament',
		lpc_postponement_years: 'Anys d\'ajornament',
		lpc_clauses: 'Clàusules',
		lpc_active_contractual_option: 'Opcions contractuals actives',
		lpc_expiry_option: 'Opció d\'expiració',
		lpc_revaluation_history: 'Historial de revalorització',
		lpc_monetary_fund: 'Fons monetari',
		lpc_benefit_effect_date: 'Data d\'efecte del benefici',
		lpc_initial_benefit: 'Benefici inicial',
		lpc_movement_update: 'Actualització de moviment',
		lpc_update_effect_date: 'Data d\'efecte de l’actualització',
		lpc_net_rate: 'Taxa neta',
		lpc_macro_category: 'Macrocategoria',
		lpc_quotation_frequency: 'Freqüència de cotització',
		lpc_volatility: 'Volatilitat',
		lpc_volatility_date: 'Data de volatilitat',
		lpc_start_quotation: 'Inici de la cotització',
		lpc_last_quotation: 'Darrera cotització',
		lpc_valuation: 'Valoració',
		lpc_percentage_total: 'Percentatge Total',
		lpc_euro_valuation_not_fund_currency: 'La valoració s’expressa en € i no en la moneda del fons',
		lpc_disinvstements: 'Desinversions',
		lpc_payment_amount: 'Import del pagament',
		lpc_surrender_distribution: 'Distribució de rendició',
		lpc_formula: 'Fórmula',
		lpc_roles_connected_benef: 'Rols vinculats al beneficiari',
		lpc_quotation_details: 'Detall de cotització',
		lpc_Disinvestmentsfunds: 'Fons de desinversió',
		lpc_same_fund_distribution: 'Distribució de fons idèntica al dipòsit inicial',
		lpc_not_same_fund_distribution: 'Distribució de fons modificada en relació amb el pagament inicial',
		lpc_guarantees_oveview: 'Resum de garanties',
		lpc_calculate_volatility: 'Calculeu la volatilitat de l’actiu',
		lpc_benef_death_not_same: 'El beneficiari per mort no pot ser el subjecte assegurat',
		lpc_global_percentage_equal_hundred: 'El percentatge global ha de ser igual a 100',
		lpc_effective_holder_natural_person_req: 'El subjecte {{linkedName}} en el rol del titular real d\'un {{benefRole}} {{benefCat}} ha de ser una persona física',
		lpc_benefPresente_default_msg: 'El subjecte ja ha estat seleccionat com a beneficiari en la mateixa categoria',
		lpc_effective_holder_duplicate: 'El subjecte {{linkedName}} ja ha estat seleccionat en el rot de titular efectiu de {{benefName}}',
		lpc_effective_holder_adult_req: 'El subjecte {{linkedName}} en el rol del titular efectiu d\'un {{benefRole}} {{benefCat}} ha de ser un adult.',
		lpc_investment_plan: 'Pla d\'inversió',
		lpc_contract_detail: 'Detall del contracte',
		lpc_variation_reason: 'Motiu de la variació',
		lpc_investment_plan_detail: 'Detall del pla d\'inversió',
		lpc_revaluation_rate: 'Taxa de revalorització',
		lpc_benefit_commencement_date: 'Data d\'inici de la prestació',
		lpc_update_effective_date: 'Data d\'efecte de l’actualització',
		lpc_last_odds_value: 'Darrer valor de la quota',
		lpc_total: 'Total',
		lpc__EFFOP: 'Data efectiva',
		lpc__OPZFR: 'Fraccionament',
		lpc__OPZPM: 'Mètodes de pagament',
		lpc__OPBEN: 'Beneficiari',
		lpc__MINTS: 'Percentatge llindar mínim',
		lpc__MAXTS: 'Percentatge llindar màxim',
		lpc__NUMOC: 'Nombre d\'ocurrències',
		lpc__AMTOP: 'Import del rescat',
		lpc__MALMT: 'Import mínim',
		lpc__CIBAN: 'IBAN',
		lpc__PCOUP: 'Percentatge de cupó periòdic',
		lpc_threshold_percentage: 'Percentatge llindar',
		lpc_pure: 'Premi pur',
		lpc_starting_fund: 'Fons inicial',
		lpc_details: 'Detalls',
		lpc_treshold: 'Llindar',
		lpc_inv_date: 'Data d\'inversió',
		lpc_beneficiary_perc_req: '{{benefCat}} {{benefRole}} {{benefName}}: \'Introduïu un percentatge\'',
		lpc_beneficiary_perc_req_range: ' {{benefRole}} {{benefCat}} {{benefName}}: El percentatge ha d\'estar comprès entre 0,01 i 100\'',
		lpc_invalid_total_position_percent: 'El percentatge total per a l’agrupament "{{groupType}}" al Beneficiari {{cat}} ha de ser del 100 %',
		lpc_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Introduïu un beneficiari del repositori de parts',
		lpc_benefPresente: 'El {{benefName}} ja ha estat introduït com a {{benefCat}} {{benefRole}}',
		lpc_benMorteNoAssic: '{{benefName}} seleccionat com a {{benefRole}} {{benefCat}} ja ha estat registrat com a assegurat',
		lpc_life_beneficiary: 'Beneficiari vida',
		lpc_death_beneficiary: 'Beneficiari defunció',
		lpc_beneficiary_position_mandatory: 'Beneficiari {{benefCat}} {{benefName}}, seleccioneu un "Número de Posició"',
		lpc_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
		lpc_reversionary_subject: 'Subjecte reversionari',
		lpc_gross_detail: '',
		lpc_type_of_covers: '',
		lpc_clausesinsuredClause: 'Clàusules de l’assegurat',
		lpc_clausespolicyClause: 'Clàusules de la pòlissa',
		lpc_clausesriskClause: 'Clàusules del registre',
		lpc_max_capital_amount: 'IMC – Importe Máximo de Capital',
		lpc_fiscal_period_M1: 'M1 – Monto acumulado al 31/12/2000',
		lpc_fiscal_period_M2: 'M2 - Monto acumulado desde el 1/1/2001 hasta el 31/12/2006',
		lpc_fiscal_period_M3: 'M3 - Monto acumulado desde el 1/1/2007',
		lpc_fiscal_period_capital_request_K1: 'Parte de M1 solicitada en capital',
		lpc_fiscal_period_capital_request_K2: 'Parte de M2 solicitada en capital',
		lpc_fiscal_period_capital_request_K3: 'Parte de M3 solicitada en capital',
		lpc_max_amount_exceeded: 'El importe solicitado en capital supera el máximo posible.',
		lpc_max_amount_error: 'El importe en capital solicitado debe ser totalmente asignado a los montos M1 y M2.',
		lpc_performance_data: 'Datos de rendimient',
		lpc_role_is_mandatory: '{{role}} es obligatorio',
		lpc_issue_authorized_proposal: '',
		lpc_confirm_prop_pol_auth: 'Confirmar propuesta/política en Autorización',
		lpc_settlement: 'Liquidación',
		lpc_annuity: 'Renta vitalicia',
		lpc_percentage_in_capital: '% en capital',
		lpc_insured_amount: 'Importe invertido',
		lpc_custom_field_mandatory_error: 'El campo {{field}} es obligatorio',
		lpc_print_docs: 'Imprimir documentos',
		lpc_revisionary_err: 'Ingresar sujeto reversionario',
		lpc_revisionary_perc_err: 'Ingresar porcentaje del sujeto reversionario',
		lpc_percentage_must_be_between: 'El porcentaje debe estar entre 0.01 y 100',
		lpc_PIP_UndeductedPremiums: '',
		lpc_contributionYear: '',
		lpc_annualPremium: '',
		lpc_undeductedPremium : '',
		lpc_reversionario: "Reversionary",
		lpc_firstEnrollmentComplementaryPension: '',
		lpc_PIP_amountForTaxation: '',
		lpc_PIP_amount: '',
		lpc_PIP_contribempl4Perc: '',
		lpc_PIP_reducttaxbltfr: '',
		lpc_PIP_aliqtfr: '',
		lpc_PIP_premdeducted2001: '',
		lpc_PIP_premdeductoffset: '',
		lpc_PIP_annuitytaxdeduct: '',
		lpc_pip_contributions_installment_detail: 'ES_Contributions Details',
		lpc_contribution_type: 'ES_Contribution type',
		lpc_contribution_amount: 'ES_Amount',
		lpc_contribution_year: 'ES_Year',
		lpc_contribution_month_from: 'ES_Month (from)',
		lpc_contribution_month_to: 'ES_Month (to)',
		lpc_contribution_thirteenth: 'ES_13^',
		lpc_contribution_fourteenth: 'ES_14^',
		lpc_incomplete_fund_investment: 'Inversión incompleta para los fondos',
		lpc_incomplete_profile_investment: 'Inversión incompleta para los perfiles',
		lpc_tax_credit: '',
		lpc_clausesinsuredClauses: '',
		lpc_clausespolicyClauses: '',
		lpc_clausesriskClauses: '',
	  };
	  export default LPC_CA_ES;


<h3 class="title-header">{{ '_CLAIMS_._DAMAGE_CATEGORY' | translate }}: {{ damageDescription }}
</h3><br>

<h3 class="title-header">{{ '_CLAIMS_._POLICY_DATA' | translate }}</h3><br>

<div class="container-fluid table-white">
    <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right" data-qa="policy-data-panel-number">
            {{ '_CLAIMS_._POLICY_NUMBER' | translate }}: <span>{{policy.number || '-'}}</span></div>
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right" data-qa="policy-data-panel-effectdate">
            {{ '_CLAIMS_._EFFECT_DATE' | translate }}: <span>{{ policy.effectDate | date:'dd/MM/yyyy' }}</span></div>
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right" data-qa="policy-data-panel-expirationdate">
            {{ '_CLAIMS_._EXPIRATION_DATE' | translate }}: <span>{{ policy.expirationDate | date:'dd/MM/yyyy' }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right" data-qa="policy-data-panel-product">
            {{ '_CLAIMS_._PRODUCT' | translate }}: <span>{{ policy.product }} {{ policy.description }}</span></div>
        <div class="col-md-8 col-sm-6 col-xs-12 text-padd-right" data-qa="policy-data-panel-administrative-validity">
            {{ '_CLAIMS_._ADMINISTRATIVE_VALIDITY' | translate }}: <span>{{ (validity ? '_CLAIMS_._YES' :
                '_CLAIMS_._NO') | translate }}</span></div>
    </div>
</div>
<ng-container *ngIf="paginator.pagesCount > 1">
  <div class="rgi-ui-pagination-controls">
    <ng-container *ngIf="paginator.current > 0">
      <button role="button" [attr.aria-label]="'RGI_RX.PAGINATOR.START' | translate" class="rgi-ui-pagination-control-first" [disabled]="disabled" (click)="paginator.first()"></button>
      <button role="button" [attr.aria-label]="'RGI_RX.PAGINATOR.PREVIOUS' | translate" class="rgi-ui-pagination-control-prev" [disabled]="disabled" (click)="paginator.back()"></button>
    </ng-container>
    <button *ngFor="let counted of pageCounter" role="button" class="rgi-ui-pagination-control-page-number"
            [attr.aria-label]="'RGI_RX.PAGINATOR.PAGE' | translate : {index: counted + 1}"
            [class.rgi-ui-active]="counted === paginator.current"
            [disabled]="disabled"
            (click)="paginator.pageIndex = counted">{{counted + 1}}</button>
    <ng-container *ngIf="paginator.current < paginator.pagesCount - 1">
      <button [attr.aria-label]="'RGI_RX.PAGINATOR.NEXT' | translate" role="button" class="rgi-ui-pagination-control-next" [disabled]="disabled" (click)="paginator.next()"></button>
      <button [attr.aria-label]="'RGI_RX.PAGINATOR.END' | translate" role="button" class="rgi-ui-pagination-control-last" [disabled]="disabled" (click)="paginator.last()"></button>
    </ng-container>
  </div>
  <div class="rgi-ui-pagination-status">
    <span class="rgi-ui-pagination-page-current">{{paginator.current + 1 }}</span>
    <span class="rgi-ui-pagination-page-count">{{paginator.pagesCount}}</span>
  </div>
</ng-container>
<div class="rgi-ui-pagination-page-items">
  <select class="rgi-ui-form-control" [attr.aria-label]="'RGI_RX.PAGINATOR.PAGE_SIZE' | translate" [disabled]="disabled" [(ngModel)]="pageSize" (ngModelChange)="onChangeOptions($event)">
    <option *ngFor="let opt of pageOption">{{opt}}</option>
  </select>
</div>

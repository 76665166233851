<div module='ui-module' component="ui-panel-demo" markdown="ui/panel" *ngIf="displayButtons">
    <rgi-rx-panel class="rgi-ui-panel-container" *ngIf="display">
        <ng-container class="rgi-ui-panel-content" qa-type="panel-content">
            <div class="row" *ngFor="let item of questionnair.items; let even = even">
                <div class="row-q-container" [ngClass]="{'row-result-even': even, 'row-result-odd': !even}">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 ptb-1">
                        <label rgiRxLabel>{{ item.question.questionValue | translate }}</label>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center"
                    [ngClass]="questionnaireEdit ? 'edit-field-questionnaire' : 'view-field-questionnaire'">
                        <div *ngIf="!questionnaireEdit; else editQuestionnaireInput" class="ptb-1">
                            <div *ngFor="let questionnaireValueResponse of item.answers[0].valuesResponse">
                                <label rgiRxLabel
                                    *ngIf="questionnaireValueResponse.id.toString() === item.answers[0].valueResponse.toString()">{{
                                    questionnaireValueResponse.description }}</label>
                            </div>
                        </div>
                        <ng-template #editQuestionnaireInput>
                            <rgi-rx-form-field>
                                <select rgiRxNativeSelect [(ngModel)]="item.answers[0].valueResponse"
                                (ngModelChange)="onChangeValue()">
                                    <option
                                        *ngFor="let questionnaireValueResponse of item.answers[0].valuesResponse"
                                        [value]="questionnaireValueResponse.id">
                                        {{ questionnaireValueResponse.description }}</option>
                                </select>
                            </rgi-rx-form-field>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
    </rgi-rx-panel>
    <rgi-rx-panel-footer class="rgi-ui-panel-footer">
        <div class="btn-group btn-group-justified btn-group-justified-claim text-center" *ngIf="!externalOpen">
            <div *ngIf="!questionnaireEdit; else editMenButton">
                <div class="row mbc-1">
                    <button type="button" class="rgi-ui-btn rgi-ui-btn-primary w-100-custom"
                        (click)="editQuestionnaire()">{{ '_CLAIMS_._BUTTONS_._EDIT' | translate }}</button>
                </div>
            </div>
            <ng-template #editMenButton>
                <div class="row mbc-1">
                    <div class="col-md-6 text-center">
                        <button type="button" class="rgi-ui-btn rgi-ui-btn-primary w-100-custom"
                            (click)="saveQuestionnaire()">{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                    </div>
                    <div class="col-md-6 text-center">
                        <button type="button" class="rgi-ui-btn rgi-ui-btn-primary w-100-custom"
                            (click)="cancelQuestionnaire()">{{'_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </rgi-rx-panel-footer>
</div>
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {AdapterprintHomeNodeModel} from "../../model/adapterprint-home-node-model";
import {
  OnModalClose,
  RGI_RX_DROP_SELECTION_DATA,
  RgiRxDatatableRowAction,
  RgiRxDropContainerSelectData,
  TableSchema
} from "@rgi/rx/ui";

@Component({
  selector: 'adapterprint-home-node-management',
  templateUrl: './adapterprint-home-node-management.component.html',
  host: {
    class: 'adapterprint-style'
  }
})
export class AdapterprintHomeNodeManagementComponent implements OnInit, OnModalClose {

  // EVENT EMMITTER ON CLOSE
    @Output() modalClose = new EventEmitter();

  constructor(@Inject(RGI_RX_DROP_SELECTION_DATA) public dropSelectionData: RgiRxDropContainerSelectData<any, any>) {
  }


  ngOnInit() {
  }

  // CLOSE MODAL EVENT

  closeModal(){
    this.modalClose.emit();
  }

  // SELECT NODE EVENT

  onActionClick(event: RgiRxDatatableRowAction<AdapterprintHomeNodeModel>) {
    if (event.name === 'selectNode') {
      this.modalClose.emit(event.row);
    }
  }

  // TABLE SCHEMA

  schema: TableSchema = {
    rows: [
      {
        name: 'code', title: '_ADAPTERPRINT_._LABEL_._CODE_',
      },
      {
        name: 'description', title: '_ADAPTERPRINT_._LABEL_._DESCRIPTION_',
      },
      {
        name: 'action', title: '_ADAPTERPRINT_._LABEL_._ACTION_',
        actions: [{
          name: 'selectNode',
          label: '',
          styleClass: 'rgi-ui-icon-check'
        }]
      }
    ],
    header: ['code', 'description', 'action']
  };
}

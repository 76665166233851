import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {isNumber} from '../../../adapters/group-policy-utils';
import {
  QuotationDetail,
  SummaryQuotationTranslations
} from '../../../group-policy-models/group-policy-issue-guarantees';

/**
 * @author: dmasone
 * @description: Modal used for quotation detail
 */
@Component({
  selector: 'rgi-gp-group-policy-modal-quotation-detail',
  templateUrl: './group-policy-modal-quotation-detail.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyQuotationDetailModalComponent implements OnModalClose {

    public modalClose = new EventEmitter<any>();
    public quotationDetail: QuotationDetail;
    public translations = SummaryQuotationTranslations;
    public isNumber = isNumber;

    constructor(@Optional() @Inject(DIALOG_DATA) data: any) {
        this.quotationDetail = data.quotationDetail;
    }

}

import {
  Component, ComponentRef,
  EventEmitter,
  Inject,
  Injector,
  Input, OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateFormatter} from './dateFormatter';
import {NgPassProProDateAdapter} from "./date.adapter";
import {AnswerContainerI, AnswerType, AnswerValueI} from '../../models';
import {QUEST_CFG, QuestionnaireCfg} from '../../questionnaire-cfg';
import {DatePipe} from "@angular/common";
import {DynamicAnswerRenderService} from "../../services/dynamic-answer-render.service";
import {NgPassProAnswer} from "../../questionnaire.api";
import {CustomAnswerDirective} from "../../custom-answer.directive";

/**
 * @see from ppproQuestion
 */
@Component({
  selector: 'ppevo-answer',
  templateUrl: './answer.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NgbDateParserFormatter, useClass: DateFormatter},
    {provide: NgbDateAdapter, useClass: NgPassProProDateAdapter},
    DatePipe
  ],

})
export class AnswerComponent implements OnInit, OnChanges, NgPassProAnswer {

  @Input() answer: AnswerContainerI;
  @Input() isRequired: boolean;
  @Input() isValid: boolean;
  @Input() disabled: boolean = false;
  @Output() change = new EventEmitter();

  @ViewChild('d', { read: NgbDatepicker, static: true }) d: NgbDatepicker;

  answerType = AnswerType;
  formAnswer: UntypedFormGroup;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  dateSep='-';
  datePlaceholder="dd-mm-yyyy";
  reDate:RegExp;

  dynamic: 'template' | 'component' | undefined = undefined;

  @ViewChild("dynamicHost", {static: true, read: ViewContainerRef}) dynamicHost?: ViewContainerRef;

  dynamicAnswer: CustomAnswerDirective;
  private dynamicComponentRef?: ComponentRef<NgPassProAnswer>;

  constructor(
    private dynamicQuestionRenderer: DynamicAnswerRenderService,
    private _viewContainerRef: ViewContainerRef,
    @Optional() @Inject(QUEST_CFG) public cfg?:QuestionnaireCfg,
   ) {

    if(cfg){
      this.dateSep = cfg.dateSeparator;
      this.datePlaceholder = "dd"+this.dateSep+"mm"+this.dateSep+"yyyy";

    }
    this.reDate = new RegExp(`^(\\d{1,2})(\\${this.dateSep}?)(\\d{0,2})(\\${this.dateSep}?)(\\d{0,4})$`);
  }

  onBlurDate(modelDate){
    if(modelDate.status == "INVALID"){
      this.answer.value = null;
    }
    this.onInputChange(this.answer.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bindPropsToDynamicComponent();
  }



  ngOnInit(): void {

    this.dynamicAnswer = this.dynamicQuestionRenderer.getMatchingAnswer(this.answer);
    if (this.dynamicAnswer){
      this.dynamic = "template";
      return;
    }

    let dynamicFactory = this.dynamicQuestionRenderer.getFactory(this.getDynamicAnswer());
    if (dynamicFactory){
      this.dynamicComponentRef = this.dynamicHost.createComponent(dynamicFactory,0, Injector.create({
        parent: this._viewContainerRef.injector,
        providers: [],
      }));
      this.bindPropsToDynamicComponent();
      this.dynamic = "component";
      this.dynamicComponentRef.changeDetectorRef.detectChanges();
      return;
    }



    const now = new Date(), minDateDefault = new Date(1900, 0, 1), maxDateDefault = new Date( now.getFullYear()+100, 0, 1 );
    this.minDate = this.date2NgbDateStruct( this.getDateByCfg('getMinDate', minDateDefault) );
    this.maxDate = this.date2NgbDateStruct( this.getDateByCfg('getMaxDate', maxDateDefault) );

    this.formAnswer = new UntypedFormGroup(
      {
        "answerDate": new UntypedFormControl()
      }
    );

    if (this.answer && this.answer.answer.type === AnswerType.DATE) {
      this.ensureDateValue();
      this.formAnswer.controls['answerDate'].setValue(this.answer.value, 0);
    }
  }

  private bindPropsToDynamicComponent() {
    if (!!this.dynamicComponentRef) {
      // bind props
      this.dynamicComponentRef.instance.answer = this.answer;
      this.dynamicComponentRef.instance.change = this.change;
      this.dynamicComponentRef.instance.disabled = this.disabled;
      this.dynamicComponentRef.instance.isValid = this.isValid;
      this.dynamicComponentRef.instance.isRequired = this.isRequired;
      this.dynamicComponentRef.changeDetectorRef.detectChanges();
    }
  }

  private getDateByCfg(cfgFunctName:string, defaultDate:Date): Date{
    if( this.cfg && this.cfg[cfgFunctName]){
      if( typeof(this.cfg[cfgFunctName])=='function'  ){
        let d = this.cfg[cfgFunctName](this.answer, defaultDate);
        if(!d){
          console.error("ng-passpro: '"+cfgFunctName+"' return undefined value, default used: ", defaultDate);
          return defaultDate;
        }
        return d;
      }else {
        console.error("ng-passpro: '"+cfgFunctName+"' must be a function' (see QuestionCfg interface), default used: ", defaultDate);
        return defaultDate;
      }
    }else {
      return defaultDate;
    }
  }

  private date2NgbDateStruct(d:Date):NgbDateStruct{
    return {
      year:  d.getFullYear(),
      month: d.getMonth()+1,
      day: d.getDate()
    };
  }

  onInputChange(value) {
    //fix perchè su crome fa onblur anche se è il controllo è disabilitato subito in rendering con il valore vuoto
    if(this.disabled){
      return;
    }

    if(this.answer.answer.type==AnswerType.LIST || this.answer.answer.type==AnswerType.BOOLEAN){
      this.answer.value = value;
    }else if(this.answer.answer.type==AnswerType.DATE){
      this.ensureDateValue();
    }else if(this.answer.answer.type==AnswerType.NUMERIC){
      const valueNum = parseInt(value);
      if(isNaN(valueNum)){
        this.answer.value = null;
      }
      else{
        this.answer.value = valueNum;
      }
    }
    this.change.emit(this.answer.value);
  }


  private ensureDateValue(){
    if(this.answer.value && typeof(this.answer.value)=='object' && this.answer.value.getTime && isNaN(this.answer.value.getTime())){
      this.answer.value = null;
    }
  }

  onKeyPressNumeric(event:KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }


  onKeyDate( {target: el} ){

    el.value = el.value.trim()
    if(el.value.length>10){
      el.value = el.value.slice(0,10);
    }

    const m = el.value.match(this.reDate);
    if(!m){
      return el.value = "";
    }
    const lastChar = el.value.slice(-1);

    let s = "", d = parseInt(m[1]), addDash = true;

    if(d<10 ){
      if( m[1].length==2){
        s += "0"+d;
      }else if(m[2]==this.dateSep|| d>3){
        s += "0"+d;
      }else {
        s += d;
        addDash = false;
      }
    }else if(d>31){
      s += "03";
      m[3] = m[1][1]+ m[3];
    }else {
      s += d;
    }

    if( addDash && m[3]){
      s += this.dateSep;
    }

    addDash = false;

    if(m[3]){
      let mm = parseInt(m[3]);

      if(mm<10){
        if(mm==0){
          s += mm;
        }else if(mm>1){
          s += "0"+mm;
          addDash = true;
        }else if(m[4]==this.dateSep){
          s += "0"+mm;
          addDash = true;
        }else {
          s += mm;
        }

      }else if(mm>12){
        s += "12";
        addDash = true;
      }else {
        s += mm;
        addDash = true;
      }
    }

    if(addDash && m[5]){
      s += this.dateSep;
    }

    if(m[5]){
      s += m[5];
    }

    if(lastChar==this.dateSep && s.slice(-1)!=this.dateSep ){
      s += this.dateSep;
    }
    el.value = s;
  }


  sortAnswerValues = (values: AnswerValueI[]):AnswerValueI[] => values.sort((v1, v2)=> v1.order-v2.order);

  getDynamicAnswer(): NgPassProAnswer {
    return {
        answer: this.answer,
        isRequired: this.isRequired,
        isValid: this.isValid,
        disabled: this.disabled,
        change: this.change
      }
  }
}

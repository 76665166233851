<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels" (goToIthStep)="goToIthStep($event)"></re-issue-stepper>
<div *ngIf="stateManager.getState$()| async as state">
  <div class="alert-warning">
    <span translate>RE_ISSUE.PROPOSAL_N</span>
    <span>&nbsp;{{state?.proposal?.contractNumber?.proposal}}&nbsp;</span>
    <span translate>RE_ISSUE.SAVED_SUCCESSFULLY</span>
    <span *ngIf="state?.authorizationRequest" translate>
      RE_ISSUE.AUTHORIZATION_SEND
    </span>
  </div>
  <div class="rgi-ui-grid rgi-ui-grid-no-gutter">
    <div class="rgi-ui-col btn-group">
      <div class="story-grid-column-content">
        <button rgi-rx-button class="width100 text-uppercase" (click)="openStampModal()" color="primary"
                    data-qa="openStamp"><span translate>RE_ISSUE.PRINT</span></button>
      </div>
    </div>

    <div *ngIf="!state?.authorize" class="rgi-ui-col btn-group">
      <div class="story-grid-column-content">
      <button rgi-rx-button [disabled]="state.warnings?.length > 0" class="width100 text-uppercase" (click)="checkout()"
                    color="primary" data-qa="checkout"><span
        translate>RE_ISSUE.PROCEDE_TO_CHECKOUT</span></button>
      </div>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {of, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {POLICY_DATA_FIELD_TYPE} from '../../group-policy-constants/general';
import {
  BaseEntity,
  GPPolicyDataField,
  GPPolicyFieldUpdate
} from '../../group-policy-models/group-policy-issue-policy-data';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {formatISO} from 'date-fns';
import {RgiRxDateTimeFilterFn} from '@rgi/rx/ui';
import {getDatePickerFilter} from '../../group-policy-resources/group-policy-date.utils';
import {cloneDeep} from "lodash";

@Component({
  selector: 'rgi-gp-group-policy-fields',
  templateUrl: './group-policy-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPolicyFieldsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() policyDataFields: GPPolicyDataField[];
  @Input() parentForm: UntypedFormGroup;
  @Input() subformGroupName = 'policyDataFieldsForm';

  @Output() customAction = new EventEmitter<GPPolicyDataField>();

  @Output() fieldUpdate: EventEmitter<GPPolicyFieldUpdate> = new EventEmitter<GPPolicyFieldUpdate>();
  policyDataForm: UntypedFormGroup;
  public subscriptions = new Subscription();


  public readonly POLICY_DATA_FIELD_TYPE = POLICY_DATA_FIELD_TYPE;

  visibleFields: GPPolicyDataField[] = [];

  constructor(
    protected translationService: RgiRxTranslationService,
  ) {
    this.policyDataForm = new UntypedFormGroup({});
  }

  ngOnInit() {
    this.visibleFields = this.policyDataFields.filter(field => field.visible);
  }

  getCustomActionLabel(field: GPPolicyDataField) {
    switch (field.code) {
      case 'COINSURANCE_OUR_PERCENTAGE':
        const label = this.policyDataForm.get('COINSURANCE_TYPE').value === '2' ?
          '_GP_._BTN_._COINSURANCE_SHARES_' : '_GP_._BTN_._COINSURANCE_DELEGATED_';
        return this.translationService.translate(label);
      default:
        return of('');
    }
  }

  protected initForm() {
    this.visibleFields = this.policyDataFields.filter(field => field.visible);
    this.policyDataFields.forEach(field => {
      const clonedField = cloneDeep(field);
      if (clonedField.visible) {
        let fieldControl = this.policyDataForm.get(clonedField.code);
        if (fieldControl) {
          const fieldValue = this.getFieldValue(clonedField);
          fieldControl.setValue(fieldValue, {emitEvent: false});
          fieldControl.setValidators(this.getFieldValidators(clonedField));
        } else {
          fieldControl = this.manageNewField(fieldControl, clonedField);
        }

        if (!clonedField.editable && fieldControl.enabled) {
          fieldControl.disable({ emitEvent: false });
        } else if (clonedField.editable && fieldControl.disabled) {
          fieldControl.enable({ emitEvent: false });
        }

      }
    });

    Object.keys(this.policyDataForm.controls).forEach(key => {
      if (!key.includes('_MINUTES') && !this.policyDataFields.find(field => field.code === key)) {
        this.policyDataForm.removeControl(key);
      }
    });

    this.parentForm.setControl(this.subformGroupName, this.policyDataForm);
  }

  private manageNewField(fieldControl: AbstractControl, field: GPPolicyDataField) {
    if (field.type === POLICY_DATA_FIELD_TYPE.INSURANCE_HOUR) {
      fieldControl = this.manageInsuranceHourTypeFields(field, fieldControl);
    } else {
      fieldControl = new UntypedFormControl(this.getFieldValue(field), {
        validators: this.getFieldValidators(field),
        updateOn: field.type === POLICY_DATA_FIELD_TYPE.DATE || field.type === POLICY_DATA_FIELD_TYPE.NUMERIC ? 'blur' : 'change'
      });
      this.policyDataForm.setControl(field.code, fieldControl);
    }
    this.subscriptions.add(fieldControl.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(selectedValue => {
        if (!!selectedValue) {
          switch (field.code) {
            case 'PLATE_NUMBER':
              selectedValue = selectedValue.toUpperCase();
              fieldControl.setValue(selectedValue, {emitEvent: false});
              break;
          }
        }
        // const clonedField = cloneDeep(field);
        this.setFieldValue(field, selectedValue);
        if(this.isValueChanged(field, this.policyDataFields)){
          const fields = this.policyDataFields.map(poldataField => {
            if (poldataField.code === field.code) {
              poldataField.valueBool = field.valueBool;
              poldataField.valueNumb = field.valueNumb;
              poldataField.valueDate = field.valueDate;
              poldataField.valueStr = field.valueStr;
            }
            return poldataField;
          }).filter(poldataField => poldataField.visible && poldataField.editable && this.getFieldValue(poldataField) != null);

          this.fieldUpdate.emit({updatedField: field, updatedFieldList: fields});
        }

      }));
    return fieldControl;
  }


  private isValueChanged(field: GPPolicyDataField, fields: GPPolicyDataField[]): boolean {
    const originalField = fields.find(f => f.code === field.code);
    if (field.type === POLICY_DATA_FIELD_TYPE.DATE || field.type === POLICY_DATA_FIELD_TYPE.DATETIME){
      return new Date(field.valueDate).getTime() !== new Date(originalField.valueDate).getTime();
    } else {
      return this.getFieldValue(field) !== this.getFieldValue(originalField);
    }

  }

  protected manageInsuranceHourTypeFields(field: GPPolicyDataField, fieldControl: AbstractControl) {
    const fieldValues = this.getFieldValue(field);
    const valueH = Array.isArray(fieldValues) ? fieldValues[0] : '24';
    const valueMin = Array.isArray(fieldValues) ? fieldValues[1] : '00';
    fieldControl = new UntypedFormControl(valueH, {
      validators: [Validators.min(0), Validators.max(24)], updateOn: 'blur'
    });
    const minuteControl = new UntypedFormControl(valueMin, {
      validators: [Validators.min(0), Validators.max(59), this.minutesValidatorFn(field.code, this.policyDataForm)],
      updateOn: 'blur'
    });
    this.policyDataForm.setControl(field.code, fieldControl);
    this.policyDataForm.setControl(field.code + '_MINUTES', minuteControl);
    this.subscriptions.add(fieldControl.valueChanges.subscribe(selectedValue => {
        if (!selectedValue || '' + selectedValue.trim() === '') {
          fieldControl.setValue('24', {emitEvent: false});
        } else if (selectedValue && selectedValue.length === 1) {
          fieldControl.setValue('0' + selectedValue, {emitEvent: false});
        }
        minuteControl.markAsTouched();
        minuteControl.updateValueAndValidity({emitEvent: false});
      })
    );
    this.subscriptions.add(minuteControl.valueChanges.subscribe(selectedValue => {
        if (!selectedValue || '' + selectedValue.trim() === '') {
          minuteControl.setValue('00', {emitEvent: false});
        } else if (selectedValue && selectedValue.length === 1) {
          minuteControl.setValue('0' + selectedValue, {emitEvent: false});
        }
      })
    );
    return fieldControl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.policyDataFields &&
      JSON.stringify(changes.policyDataFields.currentValue) !== JSON.stringify(changes.policyDataFields.previousValue)) {
      this.initForm();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  protected getFieldValidators(field: GPPolicyDataField) {
    const validators = [];
    if (field.mandatory) {
      validators.push(Validators.required);
    }

    if (field.pattern) {
      validators.push(Validators.pattern(field.pattern));
    }

    if (field.maxLength) {
      validators.push(Validators.maxLength(parseInt(field.maxLength, 10)));
    }

    if (field.minLength) {
      validators.push(Validators.minLength(parseInt(field.minLength, 10)));
    }
    return validators;
  }

  protected minutesValidatorFn(hourFieldCode: string, formGroup: UntypedFormGroup): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      const hourField = formGroup.get(hourFieldCode);
      if (hourField) {
        hourField.markAsTouched();
      }
      control.markAsTouched();
      if (hourField && parseInt(hourField.value, 10) === 24 && control.value && parseInt(control.value, 10) > 0) {
        return {'_GP_._MSG_._HOUR_GRATER_24_': true};
      }
      return null;
    };
  }

  protected getFieldValue(field: GPPolicyDataField) {
    switch (field.type) {
      case POLICY_DATA_FIELD_TYPE.LIST:
      case POLICY_DATA_FIELD_TYPE.STRING:
        return field.valueStr;
      case POLICY_DATA_FIELD_TYPE.BOOLEAN:
        return field.valueBool ? 'true' : 'false';
      case POLICY_DATA_FIELD_TYPE.DATE:
        return new Date(field.valueDate);
      case POLICY_DATA_FIELD_TYPE.NUMERIC:
        return field.valueNumb == null || isNaN(Number(field.valueNumb)) ? field.valueNumb : Number(field.valueNumb);
      case POLICY_DATA_FIELD_TYPE.INSURANCE_HOUR:
        return field.valueStr && field.valueStr.length === 5 ? field.valueStr.split(':') : undefined;
      default:
        break;
    }
  }

  protected setFieldValue(field: GPPolicyDataField, value: any) {
    switch (field.type) {
      case POLICY_DATA_FIELD_TYPE.LIST:
      case POLICY_DATA_FIELD_TYPE.STRING:
        field.valueStr = value;
        break;
      case POLICY_DATA_FIELD_TYPE.BOOLEAN:
        field.valueBool = value;
        break;
      case POLICY_DATA_FIELD_TYPE.DATE:
        field.valueDate = formatISO(value, {representation: 'date'});
        break;
      case POLICY_DATA_FIELD_TYPE.NUMERIC:
        field.valueNumb = value;
        break;
      default:
        break;
    }
  }

  visibleFieldsTrackBy(_index, field: GPPolicyDataField) {
    return field.code;
  }

  allowedValuesTrackBy(_index, baseEntity: BaseEntity) {
    return baseEntity.code;
  }

  getDateFilter(field: GPPolicyDataField): RgiRxDateTimeFilterFn<Date> {
    if (!!field.minDate || !!field.maxDate) {
      return (pickerValue: Date) => {
        return getDatePickerFilter(field.minDate, field.maxDate, pickerValue);
      };
    }
    return () => true;
  }

}

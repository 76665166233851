import {FinancialService} from '../../../services/financial.service';
import {Component, OnInit, Input, Output, EventEmitter, Inject, AfterViewChecked} from '@angular/core';
import {PlcDateUtils} from '../../../utils/plc-date-utils';
import {Observable} from 'rxjs';
import {EMPTY_STR, JS_EVENT, PV_TOKEN} from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-profile-allocation',
  templateUrl: './profile-allocation.component.html',
  styleUrls: ['./profile-allocation.component.css']
})
export class ProfileAllocationComponent implements OnInit, AfterViewChecked {

  @Input() data: any;
  @Input() id: any;
  @Output() eventPropagation = new EventEmitter<any>();
  public onInitEnd = false;
  public fundDistribution: any;
  public allocation: any;
  public pageSize: number;
  public currentPage: number;


  constructor(
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected financialService: FinancialService) {
  }

  ngOnInit() {
    this.pageSize = 10;
    if (!!this.data && !!this.data.profileVersion) {
      this.eventPropagation.emit(JS_EVENT.LOADER.START);
      this.getProfileDistribution().subscribe(response => {
        this.allocation = response;
        this.setPage(1);
        this.onInitEnd = true;
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      });
    }
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  back() {
    const idCrypt = this.data.idCrypt;
    this.eventPropagation.emit({eventName: JS_EVENT.OPEN_PROFILE_DETAIL, idCrypt});
  }

  convert(date: string) {
    if (!!date) {
      if (date === '2999-12-31') {
        return EMPTY_STR;
      }
      return PlcDateUtils.isoToFormattedDate(date);
    }
  }

  public handlePageChange(page: number) {
    this.setPage(page);
  }

  public handlePageSizeChange(pageSize: any) {
    this.pageSize = pageSize;
    this.setPage(1);
  }

  protected setPage(page: number) {
    this.currentPage = page;

    const filteredFunds = this.allocation.fundDistribution.map(fund => {
      if (Number(fund.percentage) <= 0) {
        return { ...fund, percentage: EMPTY_STR };
      }
      return fund;
    });

    const pages = filteredFunds.reduce((all, one, i) => {
      const chunk = Math.floor(i / this.pageSize);
      all[chunk] = [].concat((all[chunk] || []), one);
      return all;
    }, []);

    this.fundDistribution = pages[page - 1] || []; // Ensure you handle the case where 'pages[page - 1]' is undefined
  }

  protected getProfileDistribution(): Observable<any> {
    return this.financialService.getProfileDistribution(this.data.profileVersion.profile.id, this.data.profileVersion.version.idCrypt);
  }

}

<div>
    <!-- style="min-height: 120em;" -->
    <div class="card shadow table-white">
        <div class="row colorBlu14" style="height: 40px;">
            &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
            <label ng-bind="label" style="color:white; font-size: 20px;">{{ '_CLAIMS_._GENERAL_DATA' | translate }}</label>
        </div>
        <div class="container-fluid table-white">
            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._POLICY_NUMBER' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.policyNumber}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CONTRACTOR' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.policyHolder}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PRODUCT' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.productDescription}}</label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._AGENCY' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.agency}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._ADMINISTRATIVE_REGULARITY' |
                        translate }}:</label>
                    <label ng-bind="label">{{potPayFE.administrativeReg}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._TECHNICAL_COVERAGE' |
                        translate }}:</label>
                    <label ng-bind="label">{{potPayFE.technicalReg}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MINISTERIAL_BRANCH' |
                        translate }}:</label>
                    <label ng-bind="label">{{potPayFE.ministerialBranch}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COINSURANCE_TYPE' |
                        translate }}:</label>
                    <label ng-bind="label">{{potPayFE.coassTypeDescr}} </label>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COMPLAINT_DATE' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.complaintDate| date:'dd/MM/yyyy'}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._OCCURRENCE_DATE' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.lossDate| date:'dd/MM/yyyy'}} </label>
                </div>
                <div class="col-xs-3 col-md-3" *ngIf="potPayFE.effectiveLossDate" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._EFFECTIVE_COMPLAINT_DATE' |
                        translate }}:</label>
                    <label ng-bind="label">{{potPayFE.effectiveLossDate| date:'dd/MM/yyyy'}} </label>
                </div>
                <div class="col-xs-3 col-md-3" *ngIf="potPayFE.claimType" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CLAIM_TYPE' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.claimType}} </label>
                </div>
            </div>
        </div>
        <div class="row colorBlu14" style=" height: 40px;">
            &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
            <label ng-bind="label" style="color:white; font-size: 20px;">{{ '_CLAIMS_._SX_SUBJECT' | translate }} &nbsp;</label>

            <!-- <button class="btn pull-right"
            style="margin:2px 4px 1px; background-color: #b1cce3;; color:White; border-color:white; font-size: 14px; " disabled>
{{ '_CLAIMS_._DAMAGE_CARD' | translate }}&nbsp;<span class="action-icon rgifont rgi-search"
                        style="width:10px; height:6px;"></span></button> -->

        </div>
        <div class="container-fluid table-white">
            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._NOMINATIVE' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.actorNominative}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._TAX_CODE__VAT_NUMBER' |
                        translate }}:</label>
                    <label ng-bind="label">{{potPayFE.actorFcVat}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PARTY_CODE' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.actorRole}} </label>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MESSAGE_._OPENED_POST_LITIGATION' |
                        translate }}:</label>
                    &nbsp;&nbsp;
                    <input type="checkbox" *ngIf="potPayFE.matterFound" class="btn btn-checkbox" checked readonly
                        disabled>
                    <input type="checkbox" *ngIf="!potPayFE.matterFound" class="btn btn-checkbox" readonly disabled>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MESSAGE_._PRESENCE_REQUEST_DAMAGE' |
                        translate }}:</label>
                    &nbsp;&nbsp;
                    <input type="checkbox" *ngIf="potPayFE.requestDamagesFound" class="btn btn-checkbox" checked
                        readonly disabled>
                    <input type="checkbox" *ngIf="!potPayFE.requestDamagesFound" class="btn btn-checkbox" readonly
                        disabled>
                </div>
                <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PRESENCE_LAWYERS' |
                        translate }}:</label>
                    &nbsp;&nbsp;
                    <input type="checkbox" *ngIf="potPayFE.ctpLawyerFound" class="btn btn-checkbox" checked readonly
                        disabled>
                    <input type="checkbox" *ngIf="!potPayFE.ctpLawyerFound" class="btn btn-checkbox" readonly disabled>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-3 col-md-3" style="border-right:10px; border-right-color: gray ;">
                    <label ng-bind="label" *ngIf="potPayFE.paymentPresent"
                        style="margin-right: 1%; font-weight: bold; color: #4981b4; font-size: 18px;  cursor: pointer; "
                        (click)="settlementsDetail($event)">{{ '_CLAIMS_._SETTLEMENTS' | translate }} &emsp; <span
                            class="action-icon rgifont rgi-chevron-right"></span></label>
                    <label ng-bind="label" *ngIf="!potPayFE.paymentPresent"
                        style="margin-right: 1%; font-weight: bold; color: gray; font-size: 18px; cursor:not-allowed;">{{
                        'Settlements' | translate }}
                        &emsp; <span class="action-icon rgifont rgi-chevron-right"></span></label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._RESERVED' | translate
                        }}:</label>
                    <label ng-bind="label"> € {{round(potPayFE.reserved,2)}} </label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CASHED' | translate
                        }}:</label>
                    <label ng-bind="label"> € {{round(potPayFE.paid,2)}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="border-right:1px; border-right-color: gray;">
                    <label ng-bind="label" *ngIf="potPayFE.receiptPresent"
                        style="margin-right: 1%; font-weight: bold; color: #4981b4; font-size: 18px; cursor: pointer; "
                        (click)="receiptsDetail($event)">{{ '_CLAIMS_._RECEIPTS' | translate }} &emsp; <span
                            class="action-icon rgifont rgi-chevron-right"></span></label>
                    <label ng-bind="label" *ngIf="!potPayFE.receiptPresent"
                        style="margin-right: 1%; font-weight: bold; color: gray; font-size: 18px; cursor:not-allowed;">{{
                        'Receipts' | translate }}
                        &emsp; <span class="action-icon rgifont rgi-chevron-right"></span></label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._TOTAL' | translate
                        }}:</label>
                    <label ng-bind="label"> € {{round(potPayFE.numReceipt,2)}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="border-right:1px; border-right-color: gray;">
                    <label ng-bind="label" *ngIf="potPayFE.compensationPresent"
                        style="margin-right: 1%; font-weight: bold; color: #4981b4; font-size: 18px; cursor: pointer; "
                        (click)="compensationsDetail($event)">{{ '_CLAIMS_._COMPENSATIONS' | translate }} &emsp; <span
                            class="action-icon rgifont rgi-chevron-right"></span></label>
                    <label ng-bind="label" *ngIf="!potPayFE.compensationPresent"
                        style="margin-right: 1%; font-weight: bold; color: gray; font-size: 18px; cursor:not-allowed;">
                        {{ '_CLAIMS_._COMPENSATIONS' | translate }}
                        &emsp; <span class="action-icon rgifont rgi-chevron-right"></span></label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._RESERVED' | translate
                        }}:</label>
                    <label ng-bind="label"> € {{round(potPayFE.totalReservedCompensation,2)}} </label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COLLECTED' | translate
                        }}:</label>
                    <label ng-bind="label"> € {{round(potPayFE.totalCashedCompensation,2)}} </label>
                </div>
                <div class="col-xs-3 col-md-3" style="border-right:1px; border-right-color: gray;">
                    <label ng-bind="label" *ngIf="potPayFE.assessmentPresent"
                        style="margin-right: 1%; font-weight: bold; color: #4981b4; font-size: 18px; cursor: pointer; "
                        (click)="assignementsDetail($event)">{{ '_CLAIMS_._ASSIGNMENT' | translate }} &emsp; <span
                            class="action-icon rgifont rgi-chevron-right"></span></label>
                    <label ng-bind="label" *ngIf="!potPayFE.assessmentPresent"
                        style="margin-right: 1%; font-weight: bold; color: gray; font-size: 18px; cursor:not-allowed;">{{ '_CLAIMS_._ASSIGNMENT' | translate }}
                        &emsp; <span class="action-icon rgifont rgi-chevron-right"></span></label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._INDEMNITY_COST' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.totalAssInd}} </label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._SETTLEMENT_COST' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.totalAssSpLiq}} </label>
                    <br>
                    <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._RESISTANCE_COST' | translate
                        }}:</label>
                    <label ng-bind="label">{{potPayFE.totalAssSpRes}} </label>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12" style="font-size: 14px;">&nbsp;</div>
            </div>
        </div>
    </div>
</div>
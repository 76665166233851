export enum OpenTypeSharedComponent {
    isAccordion = 'isAccordion',
    isTitleComponent = 'isTitleComponent',
    isReopened = 'isReopened',
    isNewClaim = 'isNewClaim',
    isPot = 'isPotentialClaim',
    isClaim = 'isClaim',
    isNewClaimReopen = 'isNewClaimReopen'
}


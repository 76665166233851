import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Parameter} from '../../../models/domain-models/parameter';
import {UntypedFormGroup} from '@angular/forms';
import {PaymentFrequency} from '../../../models/api-models/api-payment-frequency';
import {Agreement} from '../../../models/domain-models/parameters/agreement';
import {TaxType} from '../../../models/domain-models/parameters/tax-type';
import {PolicyDate} from '../../../models/domain-models/parameters/policy-date';
import {PolicyTime} from '../../../models/domain-models/parameters/policy-time';
import {Commissions} from '../../../models/domain-models/parameters/commissions';
import {CommissionsRegime} from '../../../models/domain-models/parameters/commissions-regime';
import {Constraint} from '../../../models/domain-models/parameters/constraint';
import {Adjustment} from '../../../models/domain-models/parameters/adjustement';
import {TacitRenewal} from '../../../models/domain-models/parameters/tacitRenewal';
import {PolicyTechnicalData} from '../../../models/domain-models/parameters/policy-technical-data';
import {NotDrivingDeclaration} from '../../../models/domain-models/parameters/notdriving';
import {ParametersService} from './parameters.service';
import {Observable, of, Subscription} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {GenericEntity} from '../../../models/domain-models/generic-entity';
import {Variable} from '../../../models/domain-models/variable';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {InstalmentDate} from '../../../models/api-models/instalment-date';
import {ReIssueQuotationState} from '../../re-issue-state-manager/re-issue-quotation.state';

@Component({
  selector: 're-issue-parameters',
  templateUrl: './re-issue-parameters.component.html',
  styleUrls: ['./re-issue-parameters.component.css']
})
export class ParametersComponent implements OnInit, OnDestroy {


  constructor(
    protected parametersService: ParametersService,
    protected translate: RgiRxTranslationService) {
  }


  @Input() set state(st: ReIssueQuotationState) {
    // this._state = st
    // this._policyTechnicalData = st.policyTechnicalData;
    // //if (st) {
    // this.loadPolicyTechnicalData(st);
    // this.loadParameters();
    // // TODO: add
    // // this.addErrorMessages(data);
    // //  }
  }

  get state(): ReIssueQuotationState {
    return this.stateLint;
  }

  parametersForm: UntypedFormGroup;
  parameters: Parameter[] = new Array<Parameter>();
  parametersRows: Parameter[][];
  submitted = false;
  // validationMessages: Message[] = [];
  validationMessages: string[] = [];
  nonBlockingMessages = [];

  paymentFrequencyValues: PaymentFrequency[] = new Array<PaymentFrequency>();
  agreementValues: Agreement[] = new Array<Agreement>();
  taxTypeValues: TaxType[] = new Array<TaxType>();
  policyEffectiveDate: PolicyDate;
  policyIssueDate: PolicyDate;
  policyExpireDate: PolicyDate;
  policyEffectiveTime: PolicyTime;
  selectedAgreement: Agreement | null;
  selectedTaxType: TaxType | null;
  selectedCommission: Commissions | null;
  selectedFirstInstalmentExpirationDate: PolicyDate | null;
  selectedPaymentFrequency: PaymentFrequency | null;
  commissionsRegime: CommissionsRegime;
  notes: string;
  constraint: Constraint;
  selectedInstalmentDate: InstalmentDate;
  instalmentDatesValues: InstalmentDate[] | null;
  adjustment: Adjustment;
  tacitRenewal: TacitRenewal;
  notDrivingDeclaration: NotDrivingDeclaration;
  isTacitRenewalVisible: boolean;
  policyTechnicalData: PolicyTechnicalData;

  // TODO: check the need for this stuff
  protected subscriptions: Subscription = new Subscription();
  areaCode = 'PARAMETERS';

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() recalculatePremium: EventEmitter<any> = new EventEmitter<any>();
  @Output() startLoader: EventEmitter<any> = new EventEmitter<any>();
  @Output() stopLoader: EventEmitter<any> = new EventEmitter<any>();
  @Output() validationMessagesParameter: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() nonBlockingMessagesParameter: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() reinitializeVariables: EventEmitter<any> = new EventEmitter<any>();
  @Input() stateObs: Observable<ReIssueQuotationState>;
  protected stateLint: ReIssueQuotationState;
  scontoPluriannualitaValues: GenericEntity[];
  scontoMultitestaValues: GenericEntity[];
  scontoPluriannualita: Variable;
  scontoMultitesta: Variable;

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.stateObs.subscribe(val => {
      this.stateLint = val;
      this.policyTechnicalData = val.policyTechnicalData;
      this.loadPolicyTechnicalData(val);
      this.loadParameters(val);
    });
  }

  loadPolicyTechnicalData(st: ReIssueQuotationState) {

    const found = false;
    // st.allAssets.forEach(asset => {
    //   if (asset.instances && !found) {
    //     asset.instances.forEach(element => {
    //       element.variableList.variables.forEach(variable => {
    //         switch (variable.code) {
    //           case '2SCPLU':
    //             this.scontoPluriannualitaValues = variable.variablesValue;
    //             this.scontoPluriannualita = variable;
    //             break;

    //           case '2SCMLT':
    //             this.scontoMultitestaValues = variable.variablesValue;
    //             this.scontoMultitesta = variable;
    //             break;

    //           default:
    //             break;
    //         }
    //       });
    //     });
    //     found = true;
    //   }
    // });


    const data = st.policyTechnicalData;

    this.paymentFrequencyValues = data.paymentFrequencyContainer.paymentFrequencyList;

    this.policyExpireDate = data.expireDate;

    // this.taxTypeValues = data.taxTypeContainer.taxTypeList;

    this.adjustment = data.adjustment;
    // TODO: check why it's null   CI ARRIVA NULL DALLA POLICY DATA --> First Instalment Expiration Date:
    if (this.taxTypeValues) {

      for (const taxType of this.taxTypeValues) {
        if (taxType.id === 1) {
          this.selectedTaxType = taxType;
        }
        if (taxType.selected) {
          this.selectedTaxType = taxType;
          break;
        }
      }
    }

    // TODO: check why it's null    CI ARRIVA NULL DALLA POLICY DATA
    if (this.agreementValues) {
      this.agreementValues.forEach(
        (agreementValue) => {
          if (agreementValue.selected) {
            this.selectedAgreement = agreementValue;
          }
        }
      );
    }

    if (this.commissionsRegime && this.commissionsRegime.commissionList) { // CI ARRIVA NULL DALLA POLICY DATA
      this.commissionsRegime.commissionList.forEach(
        (commissionRegimeValue) => {
          if (commissionRegimeValue.selected) {
            this.selectedCommission = commissionRegimeValue;
          }
        }
      );
    }

    if (this.paymentFrequencyValues) {
      this.paymentFrequencyValues.forEach(
        (paymentFrequency) => {
          if (paymentFrequency.selected) {
            this.selectedPaymentFrequency = paymentFrequency;
            // TODO: refactor logic
            if (paymentFrequency.instalmentDates) {
              paymentFrequency.instalmentDates.forEach(
                (instalmentDate) => {
                  if (instalmentDate.selected) {
                    this.selectedFirstInstalmentExpirationDate = instalmentDate;
                  }
                }
              );
            }
          }
        }
      );
    }

    this.instalmentDatesValues = st.proposal.instalmentDates.map((value) => {
      const date = new Date(value);
      const formattedDate = date.toLocaleDateString('en-GB');
      const selected = st.policyTechnicalData.selectedInstalmentDate === value;
      if (selected) {
        this.selectedInstalmentDate = new InstalmentDate(value, value, formattedDate, selected);
      }
      return new InstalmentDate(value, value, formattedDate, selected);
    });

  }

  loadParameters(st: ReIssueQuotationState) {

    this.parameters = [];
    // if (this.policyIssueDate.date)
    //   this.parameters.push(new Parameter('1', null, 'Issue Date', this.policyIssueDate
    //     ? String(this.policyIssueDate.date) : null, 4, null, false, true, false));
    // if (this.policyEffectiveDate.date)
    //   this.parameters.push(new Parameter('2', null, 'Effective Date', this.policyEffectiveDate
    //     ? String(this.policyEffectiveDate.date) : null, 4, null, false, false, false));

    // if (this.policyEffectiveDate) {
    //   this.parametersService.setEffectiveDateNum(this.policyEffectiveDate.date);
    // }

    // let effectiveTimeHour: string | null = null;
    // let effectiveTimeMinutes: string | null = null;

    // if (this.policyEffectiveTime) {
    //   effectiveTimeHour = String(this.policyEffectiveTime.hour);
    // }

    // if (effectiveTimeHour && effectiveTimeHour.length === 1) {
    //   effectiveTimeHour = '0' + effectiveTimeHour;
    // }

    // if (this.policyEffectiveTime) {
    //   effectiveTimeMinutes = String(this.policyEffectiveTime.minutes);
    // }

    // if (effectiveTimeMinutes && effectiveTimeMinutes.length === 1) {
    //   effectiveTimeMinutes = '0' + effectiveTimeMinutes;
    // }
    // if (effectiveTimeHour && effectiveTimeMinutes)
    //   this.parameters.push(new Parameter('3', null, 'Effective Time:',
    //     effectiveTimeHour + ':' + effectiveTimeMinutes, 6, null, false, false, false));


    let paymentFrequency: string;
    const msg = of(['RE_ISSUE.PAYMENT_FREQUENCY']);
    msg.pipe(mergeMap(r => {
      return this.translate.translateAll(...r);
    })).subscribe(next => {
      paymentFrequency = next [0];
    }).unsubscribe();

    this.parameters.push(new Parameter(ParametersService.ID_PAYMENT_FREQUECY, null, paymentFrequency,
      this.selectedPaymentFrequency ? String(this.selectedPaymentFrequency.id) : null, 0,
      this.paymentFrequencyValues,
      this.stateLint.policyTechnicalData.paymentFrequencyContainer.compulsory, false, false));


    let instalmentDatesLabel: string;
    const instalmentDatesMsg = of(['RE_ISSUE.NEXT_INSTALMENT_DATE']);
    instalmentDatesMsg.pipe(mergeMap(r => {
      return this.translate.translateAll(...r);
    })).subscribe(next => {
      instalmentDatesLabel = next [0];
    }).unsubscribe();

    this.parameters.push(new Parameter(ParametersService.ID_INSTALMENT_DATES, null, instalmentDatesLabel,
      this.selectedInstalmentDate ? String(this.selectedInstalmentDate.id) : null, 0,
      this.instalmentDatesValues.reverse(), true, false, false));

    // if (this.agreementValues && this.agreementValues.length > 0) {
    //   this.parameters.push(new Parameter('6', null, 'Agreement:', this.selectedAgreement
    //     ? String(this.selectedAgreement.idAgreement) : null, 0, this.agreementValues,
    //     this.policyTechnicalData.agreementContainer.compulsory, false, false));
    // }


    // TOO DO Da controllare se esistono scontistiche per questo prodotto
    // this.parameters.push(new Parameter(ParametersService.ID_SCONTO_MULTI, null,
    //   this.scontoMultitesta ? this.scontoMultitesta.extendedDescription : null,
    //   this.scontoMultitesta ? this.scontoMultitesta.value !== '-1' ? this.scontoMultitesta.value : '' : null, 5,
    //   null,
    //   false, true, false));


    // this.parameters.push(
    //   new Parameter(ParametersService.ID_SCONTO_PLURI, null,
    //     this.scontoPluriannualita ? this.scontoPluriannualita.extendedDescription : null,
    //     this.scontoPluriannualita ? this.scontoPluriannualita.value !== '-1' ? this.scontoPluriannualita.value : '' : null, 5,
    //     null,
    //     false,
    //     true,
    //     false));
    // Fine Too DO

    // this.parameters.push(new Parameter('3', null, 'Tax Category:', this.selectedTaxType
    //   ? String(this.selectedTaxType.id) : null, 0, this.taxTypeValues,
    //   this.policyTechnicalData.taxTypeContainer.compulsory, false, false));
    // if (this.selectedFirstInstalmentExpirationDate)
    //   this.parameters.push(new Parameter('8', null, 'First Instalment Expiration Date:',
    //     this.selectedFirstInstalmentExpirationDate
    //       ? String(this.selectedFirstInstalmentExpirationDate.date) : null, 0, null, false, false, false));

    // this.parameters.push(new Parameter('4', null, 'Commissions Regime:',
    //   this.selectedCommission ? String(this.selectedCommission.id) : null, 0,
    //   this.commissionsRegime ? this.commissionsRegime.commissionList : null,
    //   this.commissionsRegime ? this.commissionsRegime.compulsory : null, false, false));

    // if (this.notes)
    //   this.parameters.push(new Parameter('12', null, 'Notes:', this.notes, 5, null, false, false, false));


    // this.parameters.push(new Parameter('5', null, '% premio emesso rispetto a preventivo', '1', 0,
    //   [{
    //     id: 1,
    //     description: '100%',
    //     selected: true
    //   }], true, false, false))
    // this.parameters.push(new Parameter('14', null, 'Tacit Renewal',
    //   String(this.tacitRenewal.tacitRenewal), 3, null,
    //   false, !this.tacitRenewal.optional, false));

    // if (this.constraint) {
    //   this.parameters.push(new Parameter('11', null, 'Constraint',
    //     String(this.constraint.constraint), 3, null,
    //     false, !this.constraint.optional, false));
    // }

    // if (this.notDrivingDeclaration && this.notDrivingDeclaration.visible) {
    //   this.parameters.push(new Parameter('13', null, 'Not Driving Declaration',
    //     String(this.notDrivingDeclaration.selected), 3, null,
    //     false, false, false));
    // }

    this.parametersService.setInstalmentDateValues(this.instalmentDatesValues);
    this.parametersService.setPaymentFrequencyValues(this.paymentFrequencyValues);

    this.parametersForm = this.parametersService.toFormGroup(this.parameters);

    this.parameters.forEach(param => {
      const formParam = this.parametersForm.get(param.id);
      if (formParam) {
        if (formParam.errors
          && !formParam.errors.required) {
          formParam.setErrors(null);
        } else if (formParam.errors
          && formParam.errors.required) {
          formParam.markAsDirty();
        }

        const parameterValueChangesSubscription = formParam.valueChanges.subscribe(
          (data) => {
            if (formParam.errors
              && !formParam.errors.required) {
              formParam.setErrors(null);
            }
          }
        );
        this.subscriptions.add(parameterValueChangesSubscription);
      }
    });

    this.parameters.forEach(param => {
    });

    if (this.parametersForm.invalid) {
      this.validationMessages.push('It is necessary to provide all the mandatory Parameters');
      this.validationMessagesParameter.emit(this.validationMessages);
    }

    const parametersNum = this.parameters.length;

    if (parametersNum === 0) {
      return;
    }

    this.parametersRows = new Array<Array<Parameter>>(Math.ceil(parametersNum / 2));

    let i: number;
    let j = 0;

    for (i = 0; i < parametersNum; i++) {
      if (i !== 0 && i % 2 === 0) {
        j++;
      }
      if (!this.parametersRows[j]) {
        this.parametersRows[j] = [];
      }
      this.parametersRows[j].push(this.parameters[i]);
    }
  }


  onUpdateParameter(parameterId: string) {
    const obj: any = {id: parameterId, value: this.parametersForm.controls[parameterId].value};
    this.recalculatePremium.emit(obj);
  }

}

import { RgiRxTranslations } from "@rgi/rx/i18n";


export const RGI_RX_PAGINATOR_i18n = {
  it: {
    RGI_RX: {
      PAGINATOR: {
        START: 'Prima pagina',
        END: 'Ultima pagina',
        NEXT: 'Prossima pagina',
        PREVIOUS: 'Pagina precedente',
        PAGE: 'Pagina {{index}}',
        PAGE_SIZE: 'Numero elementi per pagina'
      }
    }
  },
  en: {
    RGI_RX: {
      PAGINATOR: {
        START: 'First page',
        END: 'Last page',
        NEXT: 'Next page',
        PREVIOUS: 'Previous page',
        PAGE: 'Page {{index}}',
        PAGE_SIZE: 'Number of elements per page'
      }
    }
  },
  es: {
    RGI_RX: {
      PAGINATOR: {
        START: 'Primera página',
        END: 'Última página',
        NEXT: 'Siguiente página',
        PREVIOUS: 'Página anterior',
        PAGE: 'Página {{index}}',
        PAGE_SIZE: 'Número de elementos por página'
      }
    }
  },
  fr: {
    RGI_RX: {
      PAGINATOR: {
        START: 'Première page',
        END: 'Dernière page',
        NEXT: 'Page suivante',
        PREVIOUS: 'Page précédente',
        PAGE: 'Page {{index}}',
        PAGE_SIZE: `Nombre d'éléments par page`
      }
    }
  },
  de: {
    RGI_RX: {
      PAGINATOR: {
        START: 'Erste Seite',
        END: 'Letzte Seite',
        NEXT: 'Nächste Seite',
        PREVIOUS: 'Vorherige Seite',
        PAGE: 'Seite {{index}}',
        PAGE_SIZE: 'Anzahl der Elemente pro Seite'
      }
    }
  }
};

export const RGI_RX_PAGINATOR_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: () => new Promise(resolve => resolve(RGI_RX_PAGINATOR_i18n.it))
  },
  {
    locale: 'en',
    load: () => new Promise(resolve => resolve(RGI_RX_PAGINATOR_i18n.en))
  },
  {
    locale: 'es',
    load: () => new Promise(resolve => resolve(RGI_RX_PAGINATOR_i18n.es))
  },
  {
    locale: 'fr',
    load: () => new Promise(resolve => resolve(RGI_RX_PAGINATOR_i18n.fr))
  },
  {
    locale: 'de',
    load: () => new Promise(resolve => resolve(RGI_RX_PAGINATOR_i18n.de))
  }
]

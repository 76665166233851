import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {RgiRxTranslationService} from './rgi-rx-translation.service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {LoggerFactory, RgiRxInterpolationParamsType} from '@rgi/rx';

@Directive({
  selector: '[rgiRxTranslate],[translate]'
})
export class RgiRxTranslateDirective implements OnInit, OnChanges, AfterViewChecked, AfterViewInit, OnDestroy {
  private readonly logger = LoggerFactory();
  private key: string;
  private originalContent: string;
  private translated: string;
  private missing = false;
  private languageChange: Subscription;
  private translateChange: Subscription;
  private interpolationParams?: RgiRxInterpolationParamsType;


  @Input('rgiRxTranslate') set rgiRxTranslate(key: string) {
    this.key = key;
  }

  @Input('translate') set translate(key: string) {
    this.key = key;
  }

  @Input('translateParams') set params(params: RgiRxInterpolationParamsType) {
    if (typeof params === 'string') {
      try {
        this.interpolationParams = JSON.parse(params);
        return;
      } catch (e) {
        this.logger.error(`RgiRxTranslateDirective: \n Invalid JSON string for params: ${params}, interpolation will not be performed`, e);
      }
    }
    this.interpolationParams = params;
  }


  constructor(private translateService: RgiRxTranslationService, private _element: ElementRef, private _ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.languageChange = this.translateService.getLanguageChange$().subscribe(
      next => {
        this.translated = undefined;
        this.translateNodes(this.originalContent);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.translate && !changes.translate.isFirstChange()) {
      this.translateNodes(changes.translate.currentValue);
    }
    if (!!changes.rgiRxTranslate && !changes.rgiRxTranslate.isFirstChange()) {
      this.translateNodes(changes.rgiRxTranslate.currentValue);
    }
    if (!!changes.params && !changes.params.isFirstChange()) {
      this.translateNodes();
    }
  }

  ngAfterViewInit(): void {
    this.originalContent = this.element.textContent.trim();
    this.translateNodes();
  }


  ngAfterViewChecked(): void {
    if (!this.translated && !this.missing) {
      this.translateNodes();
    }
  }


  translateNodes(query?: string) {
    const key = query ? query : this.getKey();
    this.translateChange = this.translateService.translate(key, this.interpolationParams)
      .pipe(take(1))
      .subscribe(next => {
        if (next) {
          this.translated = next;
          this.element.textContent = this.translated;
          this._ref.detectChanges();
          this.missing = false;
        } else {
          this.translated = undefined;
          this.element.textContent = this.key ? this.key : this.originalContent;
          this.missing = true;
        }
      });

  }


  get element(): HTMLElement {
    return this._element.nativeElement;
  }

  getContent(node: any): string {
    return !!node.textContent ? node.textContent : node.data;
  }

  setContent(node: any, content: string): void {
    if (!!node.textContent) {
      node.textContent = content;
    } else {
      node.data = content;
    }
  }

  ngOnDestroy(): void {
    if (!!this.languageChange) {
      this.languageChange.unsubscribe();
      this.languageChange = undefined;
    }
    if (!!this.translateChange) {
      this.translateChange.unsubscribe();
      this.translateChange = undefined;
    }
  }

  private getKey() {
    if (this.key) {
      return this.key;
    }
    return this.originalContent;
  }
}

<rgi-rx-expansion-panel attr.data-qa="policy-delivery-address" color="default" >
  <rgi-rx-expansion-panel-header >
    <span rgi-rx-expansion-panel-label translate>CONTRACT_CONSULTATION.POLICY_DELIVERY_METHOD</span>
  </rgi-rx-expansion-panel-header>
  <div class="rgi-ui-col rgi-ui-grid-3">
    <div class="rgi-ui-col rgi-cc-row">
      <span class="rgi-ui-text-5">{{'CONTRACT_CONSULTATION.POLICY_DELIVERY_METHOD ' | translate}}: </span>
      <span class="rgi-ui-title-3">{{contract?.contactPolicy?.communicationChannel?.description ? contract.contactPolicy.communicationChannel.description : "-"}}</span>
    </div>
    <div *ngIf="contract.contractAddress" class="rgi-ui-col rgi-cc-row">
      <span class="rgi-ui-text-5">{{'CONTRACT_CONSULTATION.POLICY_DELIVERY_METHOD ' | translate}}: </span>
      <span class="rgi-ui-title-3">{{contract.contractAddress.formattedAddress}}</span>
    </div>

    <div *ngIf="contract.contactPolicy?.email" class="rgi-ui-col rgi-cc-row">
      <span class="rgi-ui-text-5">{{'CONTRACT_CONSULTATION.EMAIL ' | translate}}: </span>
      <span class="rgi-ui-title-3">{{contract.contactPolicy.email}}</span>
    </div>
  </div>
</rgi-rx-expansion-panel>

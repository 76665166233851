import { Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {Factor} from "../../../group-policy-models/group-policy-issue-policy-data";
import { FACTOR_TYPE } from '../../../group-policy-constants/factors';
@Component({
  selector: 'rgi-gp-group-policy-factors-component',
  templateUrl: './group-policy-factors.component.html'
})
export class GroupPolicyPolicyFactorsComponent implements OnInit, OnDestroy {

  @Output() updateFactors = new EventEmitter<Factor>();
  @Output() customFactorAction = new EventEmitter<Factor>();
  @Input() factors: Factor[];
  @Input() parentForm: UntypedFormGroup;

  public readonly FACTOR_TYPE = FACTOR_TYPE;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  public onChangeFactor(factor: Factor) {
    this.updateFactors.emit(factor);
  }

}

import {Entity} from "../../../models/domain-models/entity";

export class SearchCardDto {
  proposalNumber: string;
  policyNumber: string;
  quoteNumber: string;
  companyCode: string;
  companyId: number;
  visibleCompaniesId: number[];
  productId: number;
  productCode: string;
  salePointId: number;
  salePointCode: string;
  subjectObjectId: number;
  plateNumber: string;
  categories: any;
  states: Entity;
  contractType: Entity;
  sortType: Entity;
  productTypeId: number;
  productTypeCode: number;
  searchParentNodes: boolean;
  contractTodayEffectDate: boolean;
  wholeSalesNetwork: boolean;
  branchId: number;
  independentAgentId: number;
  applicationNumber: string;
  imoShipName: string;
  subStates: Entity[];
  subjectRoles: Entity[];
  policyType: Entity;
  oblivionType: Entity;
  partySurname: string;
  partyDesignation: string;
  partyName: string;
  fileType: string;

}

<div *ngIf="stateManager.getState$() | async as state">
    <div class="container-fluid">
        <div class="container-fluid table-white">
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{'ICD.Type' | translate}}: 
                    <span *ngIf="routingData?.type === 0 ">{{ 'ICD.Stored' | translate }}</span>
                    <span *ngIf="routingData?.type === 1 ">{{ 'ICD.Not_stored' | translate }}</span>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.Function' | translate }}: <span>{{ routingData?.functionality?.description || '-' }}</span></div>
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.Product' | translate }}: <span>{{ routingData?.product?.description || '-' }}</span></div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.Competence_date_from' | translate }}: <span>{{ routingData?.competenceDateFrom | date:'dd/MM/yyyy' || '-' }}</span></div>
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.Competence_date_to' | translate }}: <span>{{ routingData?.competenceDateTo | date:'dd/MM/yyyy' || '-' }}</span></div>
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.Status' | translate }}: <span>{{ routingData?.status?.description || '-' }}</span></div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.ACTION' | translate }}: <span>{{ routingData?.action?.description || '-' }}</span></div>
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.Mismatch_amount_from' | translate }}: <span>{{ routingData?.mismatchAmountFrom || '-' }}</span></div>
                <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right">
                    {{ 'ICD.Mismatch_amount_to' | translate }}: <span>{{ routingData?.mismatchAmountTo || '-' }}</span></div>     
            </div>
        </div>
        <div class="header-container col-sm-12 col-xs-12">
            <div class="header-container-right col-sm-6 col-xs-6">
            <div class="header-container-small col-sm-12 col-xs-12">
                <div class="header-main">
                    <span class="header-title td-left">{{ 'ICD.Company_data' | translate }} - {{ routingData?.company?.description }}</span>
                </div>
                <div class="line">
                <div class="header-row col-sm-12 col-xs-12">
                    <div class="header-column col-sm-4 col-xs-4 td-left">{{ 'ICD.Movements' | translate }}</div>
                    <div class="header-column col-sm-4 col-xs-4 td-left">{{ 'ICD.Gross_premium' | translate }}</div>
                    <div class="header-column col-sm-4 col-xs-4 td-left">{{ 'ICD.Commissions_Indennity' | translate }}</div>
                </div>
                <div class="detail-row col-sm-12 col-xs-12">
                    <div class="detail-column col-sm-4 col-xs-4 td-left"><b>{{state.summaryData?.companyMainData?.movements}}</b></div>
                    <div class="detail-column col-sm-4 col-xs-4 td-left"><b>{{state.summaryData?.companyMainData?.grossPremium | number: '1.2-2'}}</b></div>
                    <div class="detail-column col-sm-4 col-xs-4 td-left"><b>{{state.summaryData?.companyMainData?.commissions | number: '1.2-2'}}</b></div>
                </div>
                </div>
            </div>
        </div>
        <div class="header-container-left col-sm-6 col-xs-6">
            <div class="header-container-small col-sm-12 col-xs-12">
                <div class="header-main">
                    <span class="header-title td-left">{{ 'ICD.Bank_data' | translate }}</span>
                </div>
                <div class="line">
                <div class="header-row col-sm-12 col-xs-12">
                    <div class="header-column col-sm-4 col-xs-4 td-left">{{ 'ICD.Movements' | translate }}</div>
                    <div class="header-column col-sm-4 col-xs-4 td-left">{{ 'ICD.Gross_premium' | translate }}</div>
                    <div class="header-column col-sm-4 col-xs-4 td-left">{{ 'ICD.Commissions_Indennity' | translate }}</div>
                </div>
                <div class="detail-row col-sm-12 col-xs-12">
                    <div class="detail-column col-sm-4 col-xs-4 td-left"><b>{{state.summaryData?.bankMainData?.movements}}</b></div>
                    <div class="detail-column col-sm-4 col-xs-4 td-left"><b>{{state.summaryData?.bankMainData?.grossPremium | number: '1.2-2'}}</b></div>
                    <div class="detail-column col-sm-4 col-xs-4 td-left"><b>{{state.summaryData?.bankMainData?.commissions | number: '1.2-2'}}</b></div>
                </div>
                </div>
            </div>
        </div>
        </div>

        <div>
            <table rgi-rx-table [dataSource]="state.summaryData?.details">
                <ng-container rgiRxColumnDef="description">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef>{{ 'ICD.Description' | translate }}</td>
                <td rgi-rx-cell *rgiRxCellDef="let row"> {{ row.description }} </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef>{{ 'ICD.Delta_company_vs_bank' | translate }}</td>
                </ng-container>
            
                <ng-container rgiRxColumnDef="movements">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef class="td-right">{{ 'ICD.Movements' | translate }}</td>
                <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.movements}} </td>
                <td rgi-rx-footer-cell *rgiRxFooterCellDef></td>
                </ng-container>

                <ng-container rgiRxColumnDef="commissions">
                    <td rgi-rx-header-cell *rgiRxHeaderCellDef class="td-right">{{ 'ICD.Commissions_Indennity' | translate }}</td>
                    <td rgi-rx-cell *rgiRxCellDef="let row" class="td-right"> {{row.commissions | number: '1.2-2'}} </td>
                    <td rgi-rx-footer-cell *rgiRxFooterCellDef class="td-right">{{ state.summaryData?.totData?.commissions | number: '1.2-2'}}</td>
                </ng-container>
            
                <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
                <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;" [ngClass]="{'group-sub': row.parent }"></tr>
                <tr rgi-rx-footer-row *rgiRxFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </div>
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button rgi-rx-button class="btn pull-right" (click)="close()" color="secondary" translate>ICD.CLOSE</button>
        </div>
    </div>
</div>

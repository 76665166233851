import {Injectable} from '@angular/core';
import {AbstractStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ReIssueStartCardResourceService} from '../re-issue-resources/re-issue-start-card-resource.service';
import {ReIssueStartCardBusinessService} from '../re-issue-business-services/re-issue-start-card-business.service';
import {StartCardProduct} from '../../models/domain-models/start-card-product';
import {StartCardNode} from '../../models/domain-models/start-card-node';
import {UntypedFormGroup} from '@angular/forms';
import {Company} from '../../models/check-pin-response';

export class ReIssueStartCardState extends State { // this is the model of the state
  public companyId: string;
  public nodeId: string;
  public productCode: string;
  public companyList: Array<Company>;
  public productList: Array<StartCardProduct>;
  public productTypeList: Array<StartCardProduct>;
  public nodeList: Array<StartCardNode>;
  public selectedNode: StartCardNode;
}

@Injectable()
export class ReIssueStartCardStateManagerService<T extends ReIssueStartCardState> extends AbstractStateManager<T> {


  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    private routingService: RoutingService,
    private startCardService: ReIssueStartCardResourceService,
    private reIssueStartCardBusinessService: ReIssueStartCardBusinessService) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<T>(this.activeRoute.key) ?
      stateStoreService.get<T>(this.activeRoute.key) : this.newReIssueStartCardState();

    this.updateState$(of(st)); // this update the state
  }

  initAllData(st: T, node: string): Observable<T> {

    const comp$ = this.reIssueStartCardBusinessService.getCompanies$(st);
    const prod$ = this.reIssueStartCardBusinessService.getProducts$(st, node);
    const node$ = this.reIssueStartCardBusinessService.getNode$(st, node);

    return combineLatest(comp$, prod$, node$).pipe(map(([companies, products, nodes]) => {
      st.companyList = companies;
      st.productList = products.products;
      st.productTypeList = products.productsType;
      st.nodeList = nodes;
      return st;

    }));
  }

  newReIssueStartCardState(): T {
    return new ReIssueStartCardState(this.activeRoute.key) as T;
  }

  goToNextPage(formGroup: UntypedFormGroup) {
    this.reIssueStartCardBusinessService.createProposal(this.activeRoute, formGroup);
  }

  validate() {
    this.reIssueStartCardBusinessService.validate();
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewLetterTemplatesComponent} from './letter-templates-single-page-card/new-letter-templates/new-letter-templates.component';
import {LetterTemplatesListComponent} from './letter-templates-single-page-card/letter-templates-list/letter-templates-list.component';
// tslint:disable-next-line:max-line-length
import {LetterTemplatesUsersListComponent} from './letter-templates-single-page-card/letter-templates-users-list/letter-templates-users-list.component';
import {LetterTemplatesComponent} from './letter-templates.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  CardService, DigitalClaimsCommonAngularModule, enumServiceProvider, getSystemPropertyProvider
} from '@rgi/digital-claims-common-angular';
import {BrowserModule} from '@angular/platform-browser';
import {EditorModule} from '@tinymce/tinymce-angular';
import { LetterTemplatesCardComponent } from './letter-templates-card/letter-templates-card.component';
import { LetterTemplatesSinglePageCardComponent } from './letter-templates-single-page-card/letter-templates-single-page-card.component';
import {RgiRxTableModule} from '@rgi/rx/ui';
// tslint:disable-next-line:max-line-length
import { DerogationLevelSelectorComponent } from './letter-templates-single-page-card/letter-templates-users-list/derogation-level-selector/derogation-level-selector.component';
import { UserCheckerComponent } from './letter-templates-single-page-card/letter-templates-users-list/user-checker/user-checker.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [
        LetterTemplatesComponent,
        NewLetterTemplatesComponent,
        LetterTemplatesListComponent,
        LetterTemplatesUsersListComponent,
        LetterTemplatesCardComponent,
        LetterTemplatesSinglePageCardComponent,
        DerogationLevelSelectorComponent,
        UserCheckerComponent,
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        NgSelectModule,
        DigitalClaimsCommonAngularModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        [NgbModule],
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        RgiRxTableModule,
        EditorModule,
    ],
    exports: [
        LetterTemplatesComponent
    ],
    providers: [
        { provide: 'letterTemplatesSinglePageCard', useValue: LetterTemplatesSinglePageCardComponent },
        { provide: 'letterTemplatesCard', useValue: LetterTemplatesCardComponent },
        { provide: 'letterTemplatesUsersList', useValue: LetterTemplatesUsersListComponent },
        { provide: 'newLetterTemplates', useValue: NewLetterTemplatesComponent },
        { provide: 'letterTemplatesList', useValue: LetterTemplatesListComponent },
        enumServiceProvider,
        getSystemPropertyProvider
    ]
})
export class LetterTemplatesModule {
  constructor(
    cardService: CardService,
  ) {
    // card letter templates - start
    const title = '_LETTER_TEMPLATES';
    const moduleName = 'letterTemplates';

    cardService.registerCard(
      moduleName,
      title,
      LetterTemplatesComponent,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      'Cross',
    );
    // card letter templates - end

    // this.addSubcard(cardService, this.translate.instant('New letter model'),
    // 'NewLetterTemplates', 'claimsFinder', NewLetterTemplatesComponent);

    // this.addSubcard(cardService, this.translate.instant('Models list'),
    // 'LetterTemplatesList', 'claimsFinder', LetterTemplatesListComponent);
    this.addSubcard(cardService, 'LetterTemplatesSinglePageCardComponent',
    'LetterTemplatesSinglePageCard', 'claimsFinder',
    LetterTemplatesSinglePageCardComponent);
}

private addSubcard(cardService: CardService,
                   subCardTitle: string,
                   subCardModuleName: string,
                   subCardParentCardName: string,
                   classN: any) {
    const routes = [{
      path: 'action', route: {
        template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
        controller: 'CardPassCtrl',
        label: 'Action'
      }
    }];

    const communication = {
      reload: 'reload'
    };

    cardService.registerSubCard(
      subCardModuleName,
      subCardTitle,
      classN,
      subCardParentCardName,
      null,
      'ClaimsCommunicationCtrl',
      communication,
      routes,
      false,
      false
    );
  }
}

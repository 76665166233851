import {Inject, Injectable, OnDestroy, TemplateRef, Type} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {RGI_RX_PUSH_OPTS, RgiRxPushMessage, RgiRxPushOptions, RgiRxPushOptionsRef} from './reaction-api';
import {filter, map, mergeMap} from 'rxjs/operators';
import {rgiRxPushMessageFactory} from './reaction-fns';


@Injectable({
  providedIn: 'root'
})
export class RgiRxPushMessageHandlerService implements OnDestroy {


  constructor(@Inject(RGI_RX_PUSH_OPTS) private opts: RgiRxPushOptions) {
  }

  private readonly _notifications: BehaviorSubject<RgiRxPushMessage[]> = new BehaviorSubject<RgiRxPushMessage[]>([]);

  /**
   * Submit Push messages
   * @param pushMessages an array of messages
   */
  private push(...pushMessages: RgiRxPushMessage[]) {
    const elements = this._notifications.getValue();

    pushMessages.forEach(
      message => {
        this.clearExisting(message);
        if (!message.id) {
          message.id = new Date().getTime().toString();
        }
      }
    );
    this._notifications.next(elements.concat(pushMessages));
  }

  /**
   * Clear notifications stored by tag
   * @param tag the tag as a filter
   */
  clearTag(tag: string) {
    const filteredToasts = this._notifications.getValue().filter(t => t.tag !== tag);
    this._notifications.next(filteredToasts);
  }

  /**
   * Clear all notifications
   */
  clear() {
    this._notifications.next([]);
  }

  /**
   * Get the push notification stream as observable
   * @param tag filter by push tag
   */
  get$(tag?: string): Observable<RgiRxPushMessage[]> {
    return combineLatest(of(tag), this._notifications.asObservable())
      .pipe(
        map(([tagString, push]) => {
          return this.filterByTag(tagString, push);
        }),
        filter(v => !!v)
      );
  }

  private filterByTag(tag: string, push: RgiRxPushMessage[]): RgiRxPushMessage[] {
    if (!!tag) {
      return push.filter(p => p.tag === tag);
      // (this.tag && n.tag==this.tag) || (!this.tag && !n.tag)
    }
    return push.filter(p => !p.tag);
  }

  /**
   * Get the last notification as observable.
   * This observable will receive the last push whenever new notifications are pushed.
   * @param tag filter last push only when meets the tag
   */
  last$(tag?: string): Observable<RgiRxPushMessage> {
    return this.get$(tag).pipe(
      mergeMap(value => of(value[value.length - 1])),
      filter(v => !!v)
    );
  }

  private get notifications(): RgiRxPushMessage[] {
    return this._notifications.getValue();
  }

  /**
   * Remove a single push message from the stream
   * @param pushMessage
   */
  remove(pushMessage: RgiRxPushMessage) {
    const current = this.notifications.filter(t => t !== pushMessage);
    this._notifications.next(current);
  }

  /**
   * Remove a list of notifications from the stream
   * @param pushMessages
   */
  removeList(pushMessages: RgiRxPushMessage[]) {
    const current = this.notifications.filter(t => !pushMessages.some(t2 => this.equals(t, t2)));
    this._notifications.next(current);
  }

  /**
   * Send a new push with status info
   * @param content the content of the notification
   * @param options the options of the notification
   */
  info(content?: string | TemplateRef<any> | Type<any>, options?: RgiRxPushOptionsRef) {
    const notification = rgiRxPushMessageFactory(content, 'info', this.opts, options);
    this.push(notification);
  }

  /**
   * Send a new push with status alert
   * @param content the content of the notification
   * @param options the options of the notification
   */
  danger(content?: string | TemplateRef<any> | Type<any>, options?: RgiRxPushOptionsRef) {
    const notification = rgiRxPushMessageFactory(content, 'danger', this.opts, options);
    this.push(notification);
  }


  /**
   * Send a new push with status warning
   * @param content the content of the notification
   * @param options the options of the notification
   */
  warning(content: string | TemplateRef<any> | Type<any>, options?: RgiRxPushOptionsRef) {
    const notification = rgiRxPushMessageFactory(content, 'warning', this.opts, options);
    this.push(notification);
  }

  /**
   * Send a new push with status default
   * @param content the content of the notification
   * @param options the options of the notification
   */
  message(content: string | TemplateRef<any> | Type<any>, options?: RgiRxPushOptionsRef) {
    const notification = rgiRxPushMessageFactory(content, 'default', this.opts, options);
    this.push(notification);
  }

  /**
   * Send a new push with status secondary
   * @param content the content of the notification
   * @param options the options of the notification
   */
  secondary(content: string | TemplateRef<any> | Type<any>, options?: RgiRxPushOptionsRef) {
    const notification = rgiRxPushMessageFactory(content, 'secondary', this.opts, options);
    this.push(notification);
  }

  /**
   * Send a new push with status success
   * @param content the content of the notification
   * @param options the options of the notification
   */
  success(content: string | TemplateRef<any> | Type<any>, options?: RgiRxPushOptionsRef) {
    const notification = rgiRxPushMessageFactory(content, 'success', this.opts, options);
    this.push(notification);
  }

  /**
   * Submit Push messages
   * @param pushes one or more RgiRxPushMessage to deliver
   */
  notify(...pushes: RgiRxPushMessage[]): void {
    this.push(...pushes);
  }


  private clearExisting(toast: RgiRxPushMessage) {
    const found = this.notifications.find(value => this.equals(value, toast));
    if (found) {
      const index = this.notifications.indexOf(found);
      this._notifications.next(this.notifications.splice(index, 1));
    }
  }

  /**
   * Compare the equality of RgiRxPushMessage
   * @param pushMessage1 the first to compare
   * @param pushMessage2 the latter to compare
   */
  protected equals(pushMessage1: RgiRxPushMessage, pushMessage2: RgiRxPushMessage): boolean {
    return pushMessage1 === pushMessage2;
  }

  ngOnDestroy(): void {
    this.clear();
    this._notifications.complete();
  }
}


@Injectable({
  providedIn: 'root',
  useExisting: RgiRxPushMessageHandlerService
})
/**
 * @deprecated use RgiRxPushMessageHandlerService
 * @see RgiRxPushMessageHandlerService
 */
export class PushMessageHandlerService extends RgiRxPushMessageHandlerService {}

<div class="note-container">
    <div *ngIf="data.hasLeftColumn" [ngClass]="['column','left', leftColumnStyle]">
        <div class="note">
            <span class="movement technical">{{data.technicalMovement}}</span> -
            <span class="movement">{{data.accountingMovement}} <p *ngIf="data.amount" style="float: right; cursor: pointer; color: #4981b4;" (click)="openDetailPayment()">€ {{data.amount}}</p></span><br />
            <span class="thin-text" translate>Accounting date</span>: {{data.accountingDate}}<br />
            <span class="thin-text">{{data.partyRole}}</span>: {{data.party}}<br />
            <span class="thin-text" translate>Type of loss</span>: {{data.typeOfLoss}}<br />
            <div class="footer">
                <span class="rgifont rgi-user"></span> {{ data.user }} |
                {{ data.date | date: 'dd/MM/yyyy - HH:mm:ss' }}
            </div>
        </div>
    </div>
    <div *ngIf="data.hasRightColumn" class="column right"></div>
</div>
import {TermFlatI} from './term-flatI';

export class ConditionFlatIResponse {

  termList: TermFlatI[];
  status: number;

  static copy(src: ConditionFlatIResponse): ConditionFlatIResponse {
    const cf = new ConditionFlatIResponse();
    cf.status = src.status;
    cf.termList = src.termList.map(TermFlatI.copy);

    return cf;
  }
}

import {GenericEntity} from './generic-entity';
import {Clause} from './clause';

export class Risk extends GenericEntity {

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public clauses: Array<Clause>) {
    super(id, code, description);
  }
}

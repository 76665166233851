import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RgiRxSemanticStatus } from '@rgi/rx';
import { VehicleData } from '../dsc-shared-model/dsc-model-vehicle';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
@Component({
  selector: 'claims-dsc-vehicle',
  templateUrl: './dsc-vehicle.component.html',
  styleUrls: ['./dsc-vehicle.component.scss']
})
export class DscVehicleComponent implements OnInit {

  @Input() vehicle: VehicleData;
  @Input() typeOpenComponent: OpenTypeSharedComponent;
  @Output() outputDataForm = new EventEmitter<any>();

  isCompanyVehicle = true;
  isParkedVehicle: string;
  isAccordion: boolean = false;
  expanded: boolean = true;

  ngOnInit(): void {
    this.isParkedVehicle = this.vehicle.parkedVehicle ? 'yes' : 'no';
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
  }
  openModalGetVehicleCounterpart() {
    // console.log('openModalGetVehicleCounterpart');
  }
  onNgModelChange(event: string) {
    this.vehicle.parkedVehicle = event === 'yes' ? true : false;
    this.outputDataForm.emit(this.vehicle);
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }
}

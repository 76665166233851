export class GenericEntity {
    constructor(
        public id: string,
        public code: string,
        public description: string) { }

    public isNull(): boolean {
        if (!this.id && !this.code && !this.description) {
            return true;
        } else if (this.id === '' && this.code === '' && this.description === '') {
            return true;
        } else {
            return false;
        }
    }
}

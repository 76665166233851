<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="onClose()">
    <span translate>lpc_buy_quote</span>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-secondary text-uppercase" (click)="onClose()" translate>lpc_close_button</button>
      <button class="rgi-ui-btn rgi-ui-btn-primary text-uppercase" (click)="onConfirm()" translate>lpc_continue_button</button>
  </rgi-rx-panel-footer>
  <form #container [formGroup]="formGroup">
    <div class="row">
        <rgi-rx-drop-container
                formControlName="holder"
                label="{{'lpc_policyHolder' | translate }}"
                viewField="nominative"
                select="PersonDragSelectionModal"
                data-qa="anag-select-party-drop"
                [selectData]="anagDropContainerSelectData"
                [ngClass]="{'holder-required': true}">
                <lpc-required required="true"></lpc-required>
        </rgi-rx-drop-container>
    </div>
    <div class="example-row">
      <rgi-rx-form-field>
        <label rgiRxLabel>{{'lpc_managementnode' | translate}}</label>
        <input rgiRxInput formControlName="node">
      </rgi-rx-form-field>
    </div>
    <div class="row" *ngIf="!!errors?.length">
      <lpc-error-messages [errors]="errors" [fieldType]="'modal'"></lpc-error-messages>
    </div>
  </form>
</rgi-rx-panel>

import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 're-issue-loader',
  templateUrl: './re-issue-loader.component.html',
  styleUrls: ['./re-issue-loader.component.scss']
})
export class ReIssueLoaderComponent implements OnInit {
  showLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  ngOnInit() {


  }
}

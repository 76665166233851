import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Report } from '../../model/report';

@Component({
  selector: 'analytics-report-item',
  templateUrl: './analytics-report-item.component.html'
})
export class AnalyticsReportItemComponent {
  @Input() template: Report;
  @Input() isAdminFunctionEnabled: boolean;

  @Output() openDashboard: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() editReport: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() copyReportModal: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() deleteReportModal: EventEmitter<Report> = new EventEmitter<Report>();

  constructor() { }

  onOpenDashboard(template: Report) {
    this.openDashboard.emit(template);
  }

  onEditReport(template: Report) {
    this.editReport.emit(template);
  }

  onCopyReportModal(template: Report) {
    this.copyReportModal.emit(template);
  }

  onDeleteReportModal(template: Report) {
    this.deleteReportModal.emit(template);
  }
}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            {{ '_CLAIMS_._POLICY_DETAIL' | translate }}
        </div>
        <div class="modal-body" style="padding-top: 5px">
            <div class="container-fluid table-white" *ngIf="contract"> 
                <div class="row">
                    <div class="col-xs-6 col-md-6" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MANAGEMENT_NODE' | translate }}:</label><br>
                        <label ng-bind="label" >{{contract.managementNode.description || '-'}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CONTRACT_STATUS' | translate }}:</label><br>
                        <label ng-bind="label" >{{contract.currentMovement.contractStatus.descrizione || '-'}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" *ngIf="!contract.bond">{{ '_CLAIMS_._FRACTIONING' | translate }}:</label>
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" *ngIf="contract.bond">{{ '_CLAIMS_._ISSUE_FRACTIONING' | translate }}:</label><br>
                       
                        <label ng-bind="label" >{{contract.fractioning.description || '-'}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._POLICY_TYPE' | translate }}:</label><br>
                        <label ng-bind="label" >{{contract.policyType.descrizione | translate }}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._ISSUE_DATE' | translate }}:</label><br>
                        <label ng-bind="label" >{{(contract.issueDate | date:'shortDate') || '-'}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._COVERAGE_EXPIRATION_DATE' | translate }}:</label><br>
                        <label ng-bind="label" >{{(contract.coverageEndDate | date:'shortDate') || '-'}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._COINSURANCE_SHARE' | translate }}:</label><br>
                        <label ng-bind="label" >{{ contract.coinsuranceShare || '-' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CANCELLATION_DATE' | translate }}:</label><br>
                        <label ng-bind="label" >{{(contract.cancellationDate | date:'shortDate') || '-'}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._INITIAL_EFFECTIVE_DATE' | translate }}:</label><br>
                        <label ng-bind="label" >{{(contract.initialEffectDate | date:'shortDate') || '-'}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._RESCINDABILITY_DATE' | translate }}:</label><br>
                        <label ng-bind="label" >{{(contract.dateRescindability | date:'shortDate') || '-'}}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._COINSURANCE_TYPE' | translate }}:</label><br>
                        <label ng-bind="label" >{{ contract.coinsuranceType.descrizione }}</label>
                    </div>
                </div>
                
                <div class="row">
                    
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._PREMIUM_ADJUSTMENT' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.regulation || contract.regulation === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf="contract.regulation === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._INDEXING' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.indexing || contract.indexing === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf="contract.indexing === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._LPS' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.lps || contract.lps === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf="contract.lps === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._INDIRECT_COINSURANCE' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.indirectCoinsurance || contract.indirectCoinsurance === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf=" contract.indirectCoinsurance === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                </div>

                <div class="row">
                    
                    
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._MIGRATED' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.currentMovement.migrated || contract.currentMovement.migrated === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf="contract.currentMovement.migrated === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._REINSURANCE' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.reinsurance || contract.reinsurance === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf="contract.reinsurance === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._BONDED' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.bonded || contract.bonded === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf="contract.bonded === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;" >{{ '_CLAIMS_._TACIT_RENEWAL' | translate }}:</label><br>
                        <label ng-bind="label" *ngIf="!contract.tacitRenewal || contract.tacitRenewal === false">{{'_CLAIMS_._NO' | translate }}</label>
                        <label ng-bind="label" *ngIf=" contract.tacitRenewal === true">{{'_CLAIMS_._YES' | translate }}</label>
                    </div>
                </div>
                
            </div>
            <div *ngFor="let unit of policy.assets">
                <rgi-rx-expansion-panel [expanded]="false" #first color="info">
                    <!-- Intestazione / Titolo -->
                    <div rgi-rx-expansion-panel-header>
                        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                            <div class="col-xs-6 col-md-6">
                                <label ng-bind="label">
                                    {{ unit.description }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- Form -->
                    <ng-container *ngTemplateOutlet="containerDetailPolicy"></ng-container>
                </rgi-rx-expansion-panel>

                <ng-template #containerDetailPolicy>
                    <div class="container-fluid sections" *ngFor="let risk of unit.risks">
                        <div class="row row_odd background-blue">
                            <div class="col-sm-12">{{risk.description}} <span
                                    class="pull-right">{{risk.premium.gross | currency:'EUR':true}}</span></div>
                        </div>
                        <div class="row factor-detail-list">
                            <!-- TODO: damage-factor directive START -->
                            <ng-template ngFor let-damageFactor [ngForOf]="risk.factors" let-i="index"
                                let-odd="odd">
                                <div class="col-sm-12 factor-detail-separator" *ngIf="i % 3 == 0"></div>
                                <div class="col-sm-4 col-xs-12 factor-detail-cell">
                                    <div class="col-sm-6 col-xs-6 factor-detail factor-detail-desc">
                                        {{damageFactor.factor.description}}:</div>
                                    <div class="col-sm-6 col-xs-6 factor-detail factor-detail-val">
                                        <span>{{damageFactor.value || '-'}}</span>
                                    </div>
                                </div>
                            </ng-template>
                            <!-- TODO: damage-factor directive STOP -->
                        </div>
                    </div>
                    <rgi-rx-expansion-panel [expanded]="false" #first color="info">
                        <!-- Intestazione / Titolo -->
                        <div rgi-rx-expansion-panel-header>
                            <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                                <div class="col-xs-6 col-md-6">
                                    <label ng-bind="label">
                                        {{ '_CLAIMS_._SUBJECTS' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Form -->
                        <ng-template rgiRxExpansionPanelContent>
                            <div class="tbld">
                                <div class="tbld_row" [ngClass]="{ tbld_row_odd: odd, tbld_row_even: even }"
                                    *ngFor="let subject of unit.subjects; let even = even; let odd = odd">
                                    <div class="tbld_col tbld_col_title">
                                        {{subject.roleType.descrizione}}
                                        <span ng-if="subject.idSubjectLock==primaryKey"><span
                                                translate>Main</span></span>
                                    </div>
                                    <div class="tbld_col tbld_col_value">
                                        <span
                                            class="drag-element card-color-blue card-draggable">{{subject.subject.nominative}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </rgi-rx-expansion-panel>
                    <rgi-rx-expansion-panel
                    *ngIf="unit.archiveSubjects && unit.archiveSubjects.length"
                    [expanded]="false" #first color="info">
                        <!-- Intestazione / Titolo -->
                        <div rgi-rx-expansion-panel-header>
                            <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                                <div class="col-xs-6 col-md-6">
                                    <label ng-bind="label">
                                        {{ '_CLAIMS_._ARCHIVE_SUBJECTS' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Form -->
                        <ng-template rgiRxExpansionPanelContent>
                            <table class="table table-striped table-responsive">
                                <tr *ngFor="let subject of unit.archiveSubjects">
                                    <!-- ng-click="openArchiveSubj(subject)" <- TODO: check action and what to do with it -->
                                    <th>{{subject.roleType.descrizione}}</th>
                                    <td>
                                        <span [ngSwitch]="subject.personType.codice">
                                            <span *ngSwitchCase="2">{{subject.denomination}}</span>
                                            <span *ngSwitchDefault>{{subject.name}}
                                                {{subject.surname}}</span>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </ng-template>
                    </rgi-rx-expansion-panel>
                    <rgi-rx-expansion-panel
                    *ngIf="unit.locations && unit.locations.length"
                    [expanded]="false" #first color="info">
                        <!-- Intestazione / Titolo -->
                        <div rgi-rx-expansion-panel-header>
                            <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                                <div class="col-xs-6 col-md-6">
                                    <label ng-bind="label">
                                        {{ '_CLAIMS_._LOCATIONS' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Form -->
                        <ng-template rgiRxExpansionPanelContent>
                            <table class="table table-responsive table-striped">
                                <tr *ngFor="let location of unit.locations">
                                    <th>
                                        <span [ngSwitch]="location.principal">
                                            <span *ngSwitchCase="true">{{ '_CLAIMS_._PRINCIPAL' | translate }}</span>
                                            <span *ngSwitchCase="false">{{ '_CLAIMS_._SECONDARY' | translate }}</span>
                                        </span>
                                    </th>
                                    <td *ngIf="!location.formatAddress">
                                        {{location.address}} {{location.houseNumber}} {{location.zipCode}}
                                        {{location.city}}
                                        {{location.adminLevel3}}
                                        <span *ngIf="location.province">({{location.province}})</span>
                                        <span *ngIf="location.adminLevel2">({{location.adminLevel2}})</span>
                                        <span *ngIf="location.nationAbbreviation"> -
                                            {{location.nationAbbreviation}}</span>
                                    </td>
                                    <td *ngIf="location.formatAddress">
                                        {{location.formatAddress}}
                                    </td>
                                </tr>
                            </table>
                        </ng-template>
                    </rgi-rx-expansion-panel>
                    <rgi-rx-expansion-panel
                    *ngIf="unit.activities && unit.activities.length"
                    [expanded]="false" #first color="info">
                        <!-- Intestazione / Titolo -->
                        <div rgi-rx-expansion-panel-header>
                            <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                                <div class="col-xs-6 col-md-6">
                                    <label ng-bind="label">
                                        {{ '_CLAIMS_._ACTIVITIES' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Form -->
                        <ng-template rgiRxExpansionPanelContent>
                            <table class="table table-striped table-responsive">
                                <tr *ngFor="let activity of unit.activities">
                                    <th>
                                        <span [ngSwitch]="activity.principal">
                                            <span *ngSwitchCase="true"><span
                                                    translate>Principal</span></span>
                                            <span *ngSwitchCase="false"><span
                                                    translate>'_CLAIMS_._SECONDARY'</span></span>
                                        </span>
                                    </th>
                                    <td>{{activity.description}} (<span translate>Code</span>:
                                        {{activity.code}})</td>
                                </tr>
                            </table>
                        </ng-template>
                    </rgi-rx-expansion-panel>
                    <rgi-rx-expansion-panel
                    *ngIf="unit.vehicles && unit.vehicles.length"
                    [expanded]="false" #first color="info">
                        <!-- Intestazione / Titolo -->
                        <div rgi-rx-expansion-panel-header>
                            <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                                <div class="col-xs-6 col-md-6">
                                    <label ng-bind="label">
                                        {{ '_CLAIMS_._VEHICLE_DATA' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Form -->
                        <ng-template rgiRxExpansionPanelContent *ngFor="let vehicle of unit.vehicles">
                            <div class="tbld">
                                <div class="tbld_row tbld_row_odd">
                                    <div class="tbld_col tbld_col_title tbld_row_odd">{{'_CLAIMS_._CLASS' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_odd">
                                        {{vehicle.classAsset && vehicle.classAsset.description || '-'}}
                                    </div>
                                    <div class="tbld_col tbld_col_title tbld_row_even">
                                        {{'_CLAIMS_._USE' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_even">
                                        {{vehicle.useAsset && vehicle.useAsset.description || '-'}}
                                    </div>
                                </div>
                                <div class="tbld_row tbld_row_even">
                                    <div class="tbld_col tbld_col_title tbld_row_odd">
                                        {{'_CLAIMS_._EXTENSION' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_odd">
                                        {{vehicle.extensionAsset && vehicle.extensionAsset.description || '-'}}
                                    </div>
                                    <div class="tbld_col tbld_col_title tbld_row_even">
                                        {{'_CLAIMS_._PLATE_TYPE' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_even">
                                        {{vehicle.licencePlateType && vehicle.licencePlateType.descrizione || '-'}}
                                    </div>
                                </div>
                                <div class="tbld_row tbld_row_odd">
                                    <div class="tbld_col tbld_col_title tbld_row_odd">
                                        {{'_CLAIMS_._SPECIAL_ABBREVIATION' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_odd">
                                        {{vehicle.specialAbbreviation && vehicle.specialAbbreviation.description || '-'}}
                                    </div>
                                    <div class="tbld_col tbld_col_title tbld_row_even">
                                        {{'_CLAIMS_._PLATE_NUMBER' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_even">
                                        {{vehicle.licencePlateNumber || '-'}}</div>
                                </div>
                                <div class="tbld_row tbld_row_even">
                                    <div class="tbld_col tbld_col_title tbld_row_odd">
                                        {{'_CLAIMS_._BRAND' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_odd">
                                        {{vehicle.brandDescription || '-'}}</div>
                                    <div class="tbld_col tbld_col_title tbld_row_even">
                                        {{'_CLAIMS_._MODEL' | translate}}
                                    </div>
                                    <div class="tbld_col tbld_col_value tbld_row_even">
                                        {{vehicle.modelDescription || '-'}}</div>
                                </div>
                            </div>

                        </ng-template>
                    </rgi-rx-expansion-panel>
                    <rgi-rx-expansion-panel
                    *ngIf="unit.factors && unit.factors.length"
                    [expanded]="false" #first color="info">
                        <!-- Intestazione / Titolo -->
                        <div rgi-rx-expansion-panel-header>
                            <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                                <div class="col-xs-6 col-md-6">
                                    <label ng-bind="label">
                                        {{ '_CLAIMS_._FACTORS' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Form -->
                        <ng-template rgiRxExpansionPanelContent>
                            <div class="tbld">
                                <div class="tbld_row" [ngClass]="{ tbld_row_odd: odd, tbld_row_even: even }"
                                    *ngFor="let damageFactor of unit.factors; let even = even; let odd = odd">
                                    <div class="tbld_col tbld_col_title" ng-if="divView">
                                        {{damageFactor.factor.description}}</div>
                                    <div class="tbld_col tbld_col_value" ng-if="divView">
                                        {{damageFactor.value || '-'}}</div>
                                </div>
                            </div>
                        </ng-template>
                    </rgi-rx-expansion-panel>
                    <rgi-rx-expansion-panel
                    *ngIf="unit.clauses && unit.clauses.length"
                    [expanded]="false" #first color="info">
                        <!-- Intestazione / Titolo -->
                        <div rgi-rx-expansion-panel-header>
                            <div class="row rgi-claims-title-section-shared" style="padding:5px;">
                                <div class="col-xs-6 col-md-6">
                                    <label ng-bind="label">
                                        {{ '_CLAIMS_._CLAUSES' | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Form -->
                        <ng-template rgiRxExpansionPanelContent>
                            <div class="tbld">
                                <div class="tbld_row" [ngClass]="{ tbld_row_odd: odd, tbld_row_even: even }"
                                    *ngFor="let clause of unit.clauses; let even = even; let odd = odd">
                                    <div class="tbld_col tbld_col_title">
                                        {{clause.clause.description}}
                                        <span *ngFor="let damageFactor of clause.factors">
                                            <br />{{damageFactor.factor.description}}:
                                            <span>{{damageFactor.value || '-'}}</span>
                                        </span>
                                    </div>
                                    <div class="tbld_col tbld_col_value" ng-bind-html="clause.text"
                                        ng-if="clause.text">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </rgi-rx-expansion-panel>
                </ng-template>
                <h5></h5>
            </div>
        </div>
        <div class="modal-footer">
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button class="btn btn-warning pull-right text-uppercase" (click)="closeModal()"
                        attr.data-qa="claim-opening-policy-details-close-btn">{{ '_CLAIMS_._BUTTONS_._CLOSE' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
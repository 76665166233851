import { Subject } from "../subject";
import { RoleType } from "./roleType";

export class Party {
    constructor(
        public subject: Subject,
        public roleType: RoleType,
        public idSubjectLock: string,
    ) {
    }
}
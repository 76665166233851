import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { Quotation } from '../model/quotation.model';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';

@Component({
  selector: 'lpc-quotation-recess',
  templateUrl: './lpc-quotation-recess.component.html',
  styleUrls: ['./lpc-quotation-recess.component.scss']
})
export class LpcQuotationRecessComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() label = null;
  @Input() hide: boolean;
  public qd: Quotation;
  @Input() set quotationData(quotationData: Quotation) {
    if (!!this.quotationData && !!this.quotationData.listWarrantyRow ) {
      this.removeNullWarranties(this.quotationData);
    }

    this.qd = quotationData;
  }
  @Input() repaidVisible = true;

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit(): void { }

  removeNullWarranties(quotationData: Quotation) {
    quotationData.listWarrantyRow = quotationData.listWarrantyRow
      .filter(warranty => {
        return (typeof(warranty.reimbursementAmount) !== 'undefined' && warranty.reimbursementAmount !== null) &&
        (typeof(warranty.recoveredCommission) !== 'undefined' && warranty.recoveredCommission !== null);
      });
  }

}

export const FACTOR_TYPE = {
  LIST: 'LIST',
  NUMERIC: 'NUMERIC',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  STRING: 'STRING'
};

export const FACTOR_ACCORDION = {
  DATIAP: '_GP_._LABEL_._DATIAP_',
  REGOPM: '_GP_._LABEL_._REGOPM_',
  TITOLI: '_GP_._LABEL_._TECHNICAL_ACC_TRANSACTIONS_',
  BATCHP: '_GP_._LABEL_._BATCHP_',
  TARIPM: '_GP_._LABEL_._TARIPM_'
};

export const FACTOR_PROP = 'CONFPM';

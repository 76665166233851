import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { ClaimOpeningDetailsComponent } from './components/claim-opening-details/claim-opening-details.component';
import { ClaimOpeningPolicySearchComponent } from './components/claim-opening-policy-search/claim-opening-policy-search.component';
import { ClaimOpeningPolicyResultsComponent } from './components/claim-opening-policy-results/claim-opening-policy-results.component';
import { ClaimOpeningComponent } from './claim-opening.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {RgiRxSnackbarModule} from '@rgi/rx/ui';
import {
  NgbDateCustomParserFormatter,
  CardService,
  DigitalClaimsCommonAngularModule,
  claimsSessionStatusProvider,
  cardPassServiceProvider,
  claimsBodyworkServiceProvider,
  anagFinderSubjectProvider,
  claimsServiceProvider,
  constFunctionListServiceProvider,
  eventServiceProvider,
  coreResultProvider,
  sessionServiceProvider,
  authServiceProvider,
  angularGridInstanceProvider,
  getSystemPropertyProvider
} from '@rgi/digital-claims-common-angular';
import { ClaimOpeningPolicyDetailsComponent } from './components/claim-opening-policy-details/claim-opening-policy-details.component';
import {
  OccurenceDataPanelContentComponent
} from './components/claim-opening-details/occurence-data-panel-content/occurence-data-panel-content.component';
import {
  DamageCategoryPanelContentComponent
} from './components/claim-opening-details/damage-category-panel-content/damage-category-panel-content.component';
import {
  PartyDataPanelContentComponent
} from './components/claim-opening-details/party-data-panel-content/party-data-panel-content.component';
import {
  DocumentManagementPanelContentComponent
} from './components/claim-opening-details/document-management-panel-content/document-management-panel-content.component';
import {
  PolicyDetailPanelContentComponent
} from './components/claim-opening-details/policy-detail-panel-content/policy-detail-panel-content.component';
import {
  DocumentCategoryPanelTitleComponent
} from './components/claim-opening-details/document-management-panel-content/document-category/document-category-panel-title.component';
import { DocumentComponent } from './components/claim-opening-details/document-management-panel-content/document/document.component';
import { CovertureByBranchPipe } from './components/claim-opening-details/damage-category-panel-content/filterPipe';
import {
  DocumentUploadComponent
} from './components/claim-opening-details/document-management-panel-content/document-upload/document-upload.component';
import {
  DocumentPreviewComponent
} from './components/claim-opening-details/document-management-panel-content/document-preview/document-preview.component';
import { PartyComponent } from './components/claim-opening-details/party/party.component';
import { PartyCheckboxesComponent } from './components/claim-opening-details/party-checkboxes/party-checkboxes.component';
import { DamagedAssetVehicleComponent } from './components/claim-opening-details/damaged-asset-vehicle/damaged-asset-vehicle.component';
import { DamagedAssetInjuryComponent } from './components/claim-opening-details/damaged-asset-injury/damaged-asset-injury.component';
import {
  DamagedAssetMaterialDamageComponent
} from './components/claim-opening-details/damaged-asset-material-damage/damaged-asset-material-damage.component';
import { SimilarClaimsComponent } from './components/claim-opening-policy-results/similar-claims/similar-claims.component';
import {
  PartyDataNewAssetComponent
} from './components/claim-opening-details/party-data-panel-content/party-data-new-asset/party-data-new-asset.component';
import { InjuryCodeComponent } from './components/claim-opening-details/occurence-data-panel-content/injury-code/injury-code.component';
import {
  InjuryAssetDetailComponent
} from './components/claim-opening-details/party-data-panel-content/injury-asset-detail/injury-asset-detail.component';
import {
  SearchHospitalComponent
} from './components/claim-opening-details/party-data-panel-content/search-hospital/search-hospital.component';
import { AddPartyComponent } from './components/claim-opening-details/party-data-panel-content/add-party/add-party.component';
import {
  SimilarClaimsByActorModalComponent
} from './components/claim-opening-details/similar-claims-by-actor-modal/similar-claims-by-actor-modal.component';
import {
  VehicleAssetDetailComponent
} from './components/claim-opening-details/party-data-panel-content/vehicle-asset-detail/vehicle-asset-detail.component';
import {RgiAnagModule} from "@rgi/anag";
import { DscComponentModule } from '../shared/dsc-component.module';

@NgModule({
    declarations: [
        ClaimOpeningComponent,
        ClaimOpeningDetailsComponent,
        ClaimOpeningPolicySearchComponent,
        ClaimOpeningPolicyResultsComponent,
        ClaimOpeningPolicyDetailsComponent,
        ClaimOpeningPolicyDetailsComponent,
        OccurenceDataPanelContentComponent,
        DamageCategoryPanelContentComponent,
        PartyDataPanelContentComponent,
        DocumentManagementPanelContentComponent,
        PolicyDetailPanelContentComponent,
        DocumentCategoryPanelTitleComponent,
        DocumentComponent,
        CovertureByBranchPipe,
        DocumentUploadComponent,
        DocumentPreviewComponent,
        PartyComponent,
        PartyCheckboxesComponent,
        DamagedAssetVehicleComponent,
        DamagedAssetInjuryComponent,
        DamagedAssetMaterialDamageComponent,
        SimilarClaimsComponent,
        PartyDataNewAssetComponent,
        InjuryCodeComponent,
        InjuryAssetDetailComponent,
        SearchHospitalComponent,
        AddPartyComponent,
        SimilarClaimsByActorModalComponent,
        VehicleAssetDetailComponent
    ],
    imports: [
        CommonModule,
        RgiRxSnackbarModule,
        PortalNgCoreModule,
        FormsModule,
        ReactiveFormsModule,
        DigitalClaimsCommonAngularModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        //TranslateModule,
        // TranslateModule.forRoot({
        //   loader: {
        //     provide: TranslateLoader,
        //     useClass: ClaimsMergeTranslationsLoader
        //   },
        //   missingTranslationHandler: {
        //     provide: MissingTranslationHandler,
        //     useClass: ClaimsLoggingMissingTranslationHandler
        //   }
        // }),
        [NgbModule],
        RgiAnagModule,
        DscComponentModule
    ],
    exports: [
        ClaimOpeningComponent,
        ClaimOpeningDetailsComponent,
        ClaimOpeningPolicySearchComponent,
        ClaimOpeningPolicyResultsComponent,
        ClaimOpeningPolicyDetailsComponent,
        InjuryAssetDetailComponent
    ],
    providers: [
        { provide: 'searchComponent', useValue: ClaimOpeningPolicySearchComponent },
        { provide: 'searchResultComponent', useValue: ClaimOpeningPolicyResultsComponent },
        { provide: 'detailsComponent', useValue: ClaimOpeningDetailsComponent },
        { provide: 'similarClaimsComponent', useValue: SimilarClaimsComponent },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        claimsSessionStatusProvider,
        cardPassServiceProvider,
        claimsBodyworkServiceProvider,
        anagFinderSubjectProvider,
        claimsServiceProvider,
        constFunctionListServiceProvider,
        eventServiceProvider,
        coreResultProvider,
        sessionServiceProvider,
        authServiceProvider,
        angularGridInstanceProvider,
        getSystemPropertyProvider
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ClaimOpeningModule {
  constructor(cardService: CardService) {

    const title = '_NEW_FNOL';
    const moduleName = 'claim-opening';
    const communication = {
      bodywork: 'bodywork',
      bodyworkfrompass: 'bodyworkFromPass',
      driver: 'driver',
      dsess: 'dsess',
      third: 'third',
      newSubject: 'newSubject'
    };
    const routes = [{
      path: 'newCarBodywork', route: {
        template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
        controller: 'CardPassCtrl',
        label: 'New Garage'
      }
    }];

    const cardOpeningModule = cardService.registerCard(
      moduleName,
      title,
      ClaimOpeningComponent,
      'ClaimsOpeningCommunicationCtrl',
      communication,
      routes
    );

    cardService.addRouteToParallelRoutingProvider(
      cardOpeningModule,
      'anagFinder',
      'result',
      {
        template: 'anag/cardfinder/anag-card-finder-result.tpl.html',
        controller: 'AnagCardFinderControllerComm_result',
        label: 'Edit - Anagrafica Finder'
      }
    );

  }
}

export class PcUseTax {
  public description: string;
  public code: string;
  public identification: string;

  constructor(code: string,
              description: string,
              identification: string) {
    this.code = code;
    this.description = description;
    this.identification = identification;
  }
}


import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';
import { ClaimListData, ClaimReport } from '../../../models/claim-report.model';
import { JS_EVENT } from '../../../models/consts/lpc-consts';
import { PlcDateUtils } from '../../../utils/plc-date-utils';
import { ClaimReportService } from './../../../services/claim-report.service';


@Component({
  selector: 'lpc-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.css']
})
export class ClaimListComponent implements OnInit {

  @Output() eventPropagation = new EventEmitter<any>();
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(),
    count: new UntypedFormControl(10)
  });

  public get availableClaims(): ClaimReport[] {
    if (!!this.claimListData) {
      return this.claimListData.availableClaims;
    }
  }
  public showNewClaim = false;
  public pageSizes = [10, 20, 30];
  public pageSize = 10;
  public page = 1;
  public count = 0;

  public claimListData: ClaimListData;

  public showMessage = false;

  constructor(
    private claimReportService: ClaimReportService,
    protected  translate: TranslationWrapperService) { }

  ngOnInit() {
    this.retrieveData().subscribe();
    this.formGroup.get('search').valueChanges.pipe(
      debounceTime(850),
      distinctUntilChanged(),
      switchMap(() => {
        return this.retrieveData();
      }),
    ).subscribe(result => {
      const regExp: RegExp = new RegExp(this.formGroup.get('search').value, 'i');
      this.claimListData.availableClaims = this.claimListData.availableClaims.filter(claim =>
        claim.insured.match(regExp) ||
        claim.status.match(regExp) ||
        claim.type.match(regExp));
    });

  }

  public retrieveData(): Observable<ClaimReport[]> {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    const observable: Observable<any> = this.claimReportService.getAvailablesClaimReports(
      this.claimReportService.getSubjectId(), this.claimReportService.getNodeId());
    return observable.pipe(
      tap( response => {
        this.claimListData = response;
        this.showNewClaim = this.claimListData.showNewClaim;
        this.count = this.claimListData.availableClaims.length;
        if (this.count === 0) {
          this.showMessage = true;
        }
      }), finalize(() => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
      })
    );

  }

  handlePageSizeChange(event) {
    this.pageSize = event;
    this.page = 1;
    this.retrieveData().subscribe();
  }

  public handlePageChange(event) {
    this.page = event;
    this.retrieveData().subscribe();
  }


  public back() {
    this.claimReportService.closeCard();
  }

  public openClaimDetailSession(id: any) {
    this.eventPropagation.emit({eventName: JS_EVENT.OPEN_CLAIM_DETAIL, claimId: id});
  }

  public formatDate(dateValue: string): string {
    if (!!dateValue) {
      return PlcDateUtils.isoToFormattedDate(PlcDateUtils.isoDateTimeToIsoDate(dateValue));
    }
  }

  public openNewClaimSession() {
    this.eventPropagation.emit({eventName: JS_EVENT.UPDATE_CLAIM_DETAIL, claimDetail: null});
  }
}

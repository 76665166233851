import {Component, EventEmitter, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DIALOG_DATA, RgiRxDatatableComponent, TableSchema} from '@rgi/rx/ui';
import {ApplicationInclusionModalData} from '../../../group-policy-models/group-policy-inquiry';
import {RgiRxValidators} from '@rgi/rx';
import {GroupPolicyResourceService} from '../../../group-policy-resources/group-policy-resource.service';
import {
  GPAppInclusionData,
  GPAppInclusionMotorData,
  inclusionReason,
  productCategories
} from '../../../group-policy-models/group-policy-application';
import {GroupPolicyIntegrationService} from '../../../group-policy-services/group-policy-integration.service';
import {
  GPMotorAppPayload,
  GPMotorAppPayloadContractNumber,
  GPMotorAppPayloadMasterPolicyRef
} from '../../../group-policy-models/group-policy-flow-integrations';
import {
  ErrorResp,
  GPPolicyDataField,
  GPPolicyFieldUpdate
} from '../../../group-policy-models/group-policy-issue-policy-data';
import {CLUSTER_LIST_APPINCLUSION_TABLE_SCHEMA} from '../../../group-policy-constants/cluster-list-tableschema';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {formatISO} from 'date-fns';
import {GroupPolicyApiRestErrorService} from "../../../group-policy-services/group-policy-api-rest-error.service";

export class GPAppInclusionModalOnCloseData {
  event: 'cancel' | 'confirm';
  file?: File;
  choice?: 'upload' | 'startInclusionFlow';
  inclusionFlowPayload: GPMotorAppPayload;
}

@Component({
  selector: 'rgi-gp-include-application-modal',
  templateUrl: './include-application-modal.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})

export class GPIncludeApplicationModalComponent implements OnInit {

  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  CLUSTER_LIST_APPINCLUSION_TABLE_SCHEMA: TableSchema = CLUSTER_LIST_APPINCLUSION_TABLE_SCHEMA;
  public modalClose = new EventEmitter<GPAppInclusionModalOnCloseData>();
  public dataInclusion: ApplicationInclusionModalData;
  assetField: GPPolicyDataField;
  clusterListTableData: Observable<any[]>;
  showUploadFileBtn;
  public modalUploadForm = new UntypedFormGroup({
    fileField: new UntypedFormControl('', RgiRxValidators.acceptFileType(['text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'])),
    choiceUpload: new UntypedFormControl('upload'),
    specialPlate: new UntypedFormControl(false)
  });
  public showSpecialPlate = false;
  protected subscription: Subscription = new Subscription();

  errors: ErrorResp[] = [];

  @ViewChild(RgiRxDatatableComponent) dt: RgiRxDatatableComponent;

  constructor(
    protected resourceService: GroupPolicyResourceService,
    protected integrationService: GroupPolicyIntegrationService,
    protected gpErrorService: GroupPolicyApiRestErrorService,
    @Optional() @Inject(DIALOG_DATA) dataInclusion: ApplicationInclusionModalData,
    @Inject('SHOW_UPLOAD_FILE_BTN') showUploadFileBtn: boolean) {
    this.dataInclusion = dataInclusion;
    this.showUploadFileBtn = showUploadFileBtn;
  }

  ngOnInit() {
    if (this.dataInclusion && this.dataInclusion.fields) {
      const field = this.dataInclusion.fields.find(f => 'CREATION_REASON' === f.code);
      if (field && field.allowedValues.length === 1) {
        field.valueStr = field.allowedValues[0].code;
        this.onPolicyDataUpdate({updatedField: field, updatedFieldList: this.dataInclusion.fields});
      }
      this.onInitSelectionBtn();
    }
  }

  emitEvent() {
    this.modalUploadForm.markAllAsTouched();
    if (this.modalUploadForm.valid) {
      this.errors = [];
      if (this.dataInclusion.policyTypeCluster){
        this.dataInclusion.fields.push(this.assetField);
      }
      this.resourceService.checkInclusionFeasible$(this.dataInclusion.proposalNumber, this.dataInclusion.fields).pipe(
        catchError(err => {
          this.errors = this.gpErrorService.manageErrors(err);
          return throwError(err);
        })
      )
      .subscribe(() => {

        const onCloseData = new GPAppInclusionModalOnCloseData();
        onCloseData.event = 'confirm';
        onCloseData.file = this.modalUploadForm.controls.fileField.value;
        onCloseData.choice = this.modalUploadForm.controls.choiceUpload.value;
        switch (this.dataInclusion.productCategory.code) {
          case productCategories.MOTOR:
            this.setMotorPayload(onCloseData);
            break;
          default:
            break;
        }
        this.modalClose.emit(onCloseData);
      });
    }
  }

  protected setMotorPayload(onCloseData: GPAppInclusionModalOnCloseData) {
    const subForm = this.modalUploadForm.get('policyDataFieldsForm') as UntypedFormGroup;

    const clusterCode = this.modalUploadForm.get('CLUSTER_CODE') ? this.modalUploadForm.get('CLUSTER_CODE').value : undefined;
    const assetCode = this.assetField ? this.modalUploadForm.get('ASSET_CODE').value : subForm.get('ASSET_CODE').value;
    // substitution
    if (parseInt(subForm.get('CREATION_REASON').value, 10) === parseInt(inclusionReason.SUBSTITUTION, 10)) {
      onCloseData.inclusionFlowPayload = new GPMotorAppPayload('substitution');
      onCloseData.inclusionFlowPayload.contractNumber = new GPMotorAppPayloadContractNumber(
        null,
        this.dataInclusion.policyNumber + subForm.get('APPLICATION_NUMBER').value,
      );
      onCloseData.inclusionFlowPayload.contractNumber.subClusterCode = clusterCode;
      onCloseData.inclusionFlowPayload.contractNumber.subAssetCode = assetCode;
      onCloseData.inclusionFlowPayload.contractNumber.isApplicationSubstitution = true;
    } else {
      // fields
      const effectiveDate = new Date(subForm.get('EFFECTIVE_DATE').value);
      let expirationDate;
      if (subForm.contains('EXPIRATION_DATE')) {
        const date = new Date(subForm.get('EXPIRATION_DATE').value);
        date.setHours(0, 0 , 0, 0);
        expirationDate = date.getTime();
      }
      effectiveDate.setHours(0, 0, 0, 0);
      const effectiveHour = subForm.get('EFFECTIVE_HOUR').value + ':' + subForm.get('EFFECTIVE_HOUR_MINUTES').value;
      // create payload
      onCloseData.inclusionFlowPayload = new GPMotorAppPayload();
      onCloseData.inclusionFlowPayload.masterpolicyRef = new GPMotorAppPayloadMasterPolicyRef(
        this.dataInclusion.proposalNumber,
        !!subForm.get('PLATE_NUMBER').value && !this.modalUploadForm.get('specialPlate').value ? subForm.get('PLATE_NUMBER').value.trim() : null,
        assetCode,
        parseInt(inclusionReason.NEW, 10),
        new Date(subForm.get('ISSUE_DATE').value).getTime(),
        effectiveDate.getTime(),
        expirationDate,
        effectiveHour,
        clusterCode,
        this.modalUploadForm.get('specialPlate').value,
        this.dataInclusion.product
      );
    }
  }

  protected getClustersForAssetCode(assetCode: string, inclusionEffDate?: Date) {
    if (assetCode) {
      if (!inclusionEffDate) {
        inclusionEffDate = this.modalUploadForm.get('policyDataFieldsForm')
        && this.modalUploadForm.get('policyDataFieldsForm').get('EFFECTIVE_DATE')
        && this.modalUploadForm.get('policyDataFieldsForm').get('EFFECTIVE_DATE').valid
          ? this.modalUploadForm.get('policyDataFieldsForm').get('EFFECTIVE_DATE').value
          : new Date();
      }
      // temporary workaround to set the date at the correct day, todo refactor after architecture directives
      const isoEffDate = new Date(formatISO(inclusionEffDate, {representation: 'date'})).toISOString();
      this.resourceService.getClusterListByAssetCode$(this.dataInclusion.proposalNumber, assetCode, isoEffDate).subscribe(
        (clusters) => {
          if (clusters) {
            this.modalUploadForm.get('CLUSTER_CODE').setValue(clusters[0].code);
            this.clusterListTableData = of(clusters.map(cluster => {
              return {
                code: cluster.code,
                clusterName: cluster.name,
                clusterOwner: cluster.owner,
                extDescr: cluster.description
              };
            }));
            if (!!this.dt && !!this.dt.dataSource) {
              this.clusterListTableData.pipe(take(1)).subscribe(next => this.dt.dataSource.update(next));
            }
          }
        }
      );
    }
  }

  public onPolicyDataUpdate($event: GPPolicyFieldUpdate) {
    const assetValue: string = !!this.dataInclusion.assetField ? this.dataInclusion.assetField.valueStr : null;
    let inclusionDate;
    if ($event.updatedField.code === 'EFFECTIVE_DATE' && this.modalUploadForm.get('policyDataFieldsForm').get('EFFECTIVE_DATE').valid) {
      inclusionDate = new Date($event.updatedField.valueDate);
      this.getClustersForAssetCode(assetValue, inclusionDate);
    }
    if (!!$event && $event.updatedField.reloadOnChange) {
      const proposalNumber = this.dataInclusion.proposalNumber;
      this.resourceService.retrieveApplicationInclusionData$(proposalNumber, $event.updatedFieldList).subscribe(
        inclusionData => {
          this.manageInclusionDataResponse(inclusionData, this.dataInclusion);
          if (this.dataInclusion.assetField) {
            this.assetField = this.dataInclusion.assetField;
            if (this.assetField) {
              this.modalUploadForm.setControl(this.assetField.code, new UntypedFormControl(
                {value: this.assetField.valueStr, disabled: !this.assetField.editable},
                {validators: this.assetField.mandatory ? [Validators.required] : []}
              ));
              if (this.dataInclusion.policyTypeCluster) {
                this.modalUploadForm.setControl('CLUSTER_CODE', new UntypedFormControl(
                  undefined, {validators: Validators.required}
                ));
                if (!!assetValue) {
                  this.dataInclusion.assetField.valueStr = assetValue;
                  this.modalUploadForm.get(this.assetField.code).setValue(assetValue, {emitEvent: false});
                }
                const inclusionEffDate = !!((inclusionData as GPAppInclusionMotorData)).effectiveDate ?
                  new Date((inclusionData as GPAppInclusionMotorData).effectiveDate.valueDate) : null;
                this.getClustersForAssetCode(this.assetField.valueStr, inclusionEffDate);
                this.modalUploadForm.get(this.assetField.code).valueChanges.subscribe(assetCode => {
                  this.getClustersForAssetCode(assetCode, inclusionEffDate);
                  this.assetField.valueStr = assetCode;
                });
              }
            }
          }
          return this.dataInclusion;
        });
    } else {
      this.alignFields($event.updatedField);
    }
  }

  protected manageInclusionDataResponse(resp: GPAppInclusionData | GPAppInclusionMotorData,
                                        modalData: ApplicationInclusionModalData) {
    switch (modalData.productCategory.code) {
      case productCategories.MOTOR:
        this.manageMotorFields(resp as GPAppInclusionMotorData, modalData);
        this.manageSpecialPlate(resp as GPAppInclusionMotorData, modalData);
        break;
      default:
        break;
    }
  }

  protected manageSpecialPlate(resp: GPAppInclusionMotorData, modalData: ApplicationInclusionModalData) {
    this.modalUploadForm.removeControl('specialPlate');
    this.subscription.unsubscribe();
    this.subscription.closed = false;
    this.showSpecialPlate = false;
    if (resp.creationReason.valueStr === inclusionReason.NEW) {
      this.modalUploadForm.addControl('specialPlate', new UntypedFormControl(false));
      this.subscription.add(this.modalUploadForm.get('specialPlate').valueChanges.subscribe(value => {
        const gpPolicyDataField = modalData.fields.find(el => el.code === 'PLATE_NUMBER');
        gpPolicyDataField.visible = !value;
        if (value) {
          this.modalUploadForm.get('policyDataFieldsForm').get('PLATE_NUMBER').clearValidators();
        } else {
          this.modalUploadForm.get('policyDataFieldsForm').get('PLATE_NUMBER').setValidators(gpPolicyDataField.mandatory ? [Validators.required] : []);
        }
        this.modalUploadForm.get('policyDataFieldsForm').get('PLATE_NUMBER').updateValueAndValidity({emitEvent: true});
        // force onchange trigger
        this.dataInclusion.fields = [...this.dataInclusion.fields];
      }));
      this.showSpecialPlate = true;
    }
  }

  protected manageMotorFields(resp: GPAppInclusionMotorData, modalData: ApplicationInclusionModalData) {
    const inclusionDataFields: GPPolicyDataField[] = [
      resp.creationReason
    ];
    modalData.policyTypeCluster = resp.policyTypeCluster;
    if (modalData.policyTypeCluster) {
      modalData.assetField = resp.assetCode;
    } else {
      inclusionDataFields.push(resp.assetCode);
    }
    if (resp.creationReason.valueStr === inclusionReason.NEW) {
      inclusionDataFields.push(resp.issueDate);
      inclusionDataFields.push(resp.effectiveDate);
      inclusionDataFields.push(resp.effectiveHour);
      inclusionDataFields.push(resp.plateNumber);
      inclusionDataFields.push(resp.expirationDate);
    } else {
      inclusionDataFields.push(resp.applicationNumber);
    }
    inclusionDataFields.sort((a, b) => {
      return a.order - b.order;
    });
    modalData.fields = inclusionDataFields;
  }

  private onInitSelectionBtn() {
    if (!this.showUploadFileBtn) {
      this.modalUploadForm.controls.choiceUpload.patchValue('startInclusionFlow');
    }
  }

  private alignFields(updatedField: GPPolicyDataField) {
    this.dataInclusion.fields.find(f => f.code === updatedField.code).valueStr = updatedField.valueStr;
  }
}

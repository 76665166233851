import {Component, Input, OnInit} from '@angular/core';
import {FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {of} from 'rxjs';
import {RgiRxOnContainerValueChange} from '@rgi/rx/ui';
import {
  ReIssueQuotationStateManagerService
} from '../../re-issue-state-manager/re-issue-quotation-state-manager.service';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ReIssueQuotationState} from '../../re-issue-state-manager/re-issue-quotation.state';

@Component({
  selector: 're-issue-third-person',
  templateUrl: './re-issue-third-person.component.html',
  styleUrls: ['./re-issue-third-person.component.css'],
})

export class ThirdPersonComponent implements OnInit {

  @Input() beneficiaryForm: any;

  thirdPersonForm: UntypedFormGroup;
  errorSubjectMessage = 'RE_ISSUE.WARNING_THIRDPERSON_SUBJECT';
  thirdPersonPresent: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public stateManager: ReIssueQuotationStateManagerService<ReIssueQuotationState>,
    public pushMessageHandler: PushMessageHandlerService,
    protected translate: RgiRxTranslationService,
    private translateService: RgiRxTranslationService
  ) {
    this.thirdPersonPresent = this.stateManager.getCurrentState().thirdPersonContact;
    this.thirdPersonForm = this.formBuilder.group({
      thirdPersonContact: [this.thirdPersonPresent ? this.thirdPersonPresent : undefined, ]
    });
  }

  ngOnInit() {
    const state = this.stateManager.getCurrentState();
    const tcp = state.proposal.assets[0].beneficiaryThirdContactPerson;
    if (tcp.objectId) {
      state.thirdPersonContact = tcp;
      this.thirdPersonForm.get('thirdPersonContact').patchValue(tcp);
    }
    this.pushMessageHandler.clearTag('thirdPersonError');
  }

  selectedThirdSubject(val: RgiRxOnContainerValueChange) {
    const state = this.stateManager.getCurrentState();
    if (val.changed.objectId) {
      this.pushMessageHandler.clearTag('thirdPersonError');
      if (this.checkSubject(state, val) || this.checkBeneficiaries(val) || this.checkPartyRoles(state, val)) {
        this.errorMessage(this.errorSubjectMessage);
        state.thirdPersonContact = null;
        this.getBeneficiary().reset();
        this.stateManager.updateState$(of(state));
      } else {
        const emitter = {idLatestPhotosThirdContactPerson: val.changed.idLatestPhotos};
        this.stateManager.putThirdContactPerson(
          this.stateManager.getCurrentState().resourceId, emitter)
          .subscribe(
            value => {
              if (value.outcome === 'OK') {
                state.thirdPersonContact = val.changed;
              } else {
                this.errorMessage(value.message);
                state.thirdPersonContact = null;
                this.getBeneficiary().reset();
                this.stateManager.updateState$(of(state));
              }
            }
          );
        this.stateManager.updateState$(of(state));
      }
    }
  }

  checkSubject(state: any, val: any) {
    return state.subject.objectId === val.changed.objectId;
  }

  checkPartyRoles(state: any, val: any) {
    let out = false;
    state.allAssets.forEach(asset => {
      asset.roles.forEach(role => {
        if (role.type.code === '4' && role.subject.id === val.changed.objectId) {
          out = true;
        }
      });
    });
    return out;
  }

  checkBeneficiaries(val: any) {
    let found = false;
    const state = this.stateManager.getCurrentState();
    state.beneficiaryParties.forEach(b => {
      const beneficiaries = b.split('_');
      if (beneficiaries[0] === val.changed.objectId) {
        found = true;
      }
    });
    return found;
  }

  removeBeneficiarySubject() {
    this.getBeneficiary().reset();
    const state = this.stateManager.getCurrentState();
    this.stateManager.deleteThirdContactPerson(
      this.stateManager.getCurrentState().resourceId)
      .subscribe(
        val => {
          state.thirdPersonContact = null;
          this.stateManager.updateState$(of(state));
        }
      );
  }


  getBeneficiary() {
    return this.thirdPersonForm.get('thirdPersonContact') as UntypedFormGroup;
  }

  errorMessage(message) {
    const tag = 'thirdPersonError';
    this.pushMessageHandler.clearTag(tag);
    if (message) {
      const msg: PushMessage = new PushMessage();
      const opts = {icon: 'rgi-ui-icon-warning'};
      msg.tag = tag;
      msg.status = 'info';
      msg.content = message;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
    }
  }
}

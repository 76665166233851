<lic-checkbox-accordion #accordion [toolName]="toolOption.name"
                        (toolCheck)="onAccordionClick($event)"
                        [disabled]="isToolMandatory"
                        [formGroup]="formGroup"
                        [active]="isToolActive">
  <lic-tool [funds]="funds" [investAmount]="investAmount"
            [definition]="definition"
            [investedProfiles]="investedProfiles"
            formControlName="tool" *ngIf="isActive && !!definition">
  </lic-tool>
</lic-checkbox-accordion>

import { EMPTY_STR, StepConfiguration, StepperConfiguration } from '../../../models/consts/lpc-consts';

export const POLICY_CLAUSE_TYPE_FORM = 'policyClauses';
export const INSURED_CLAUSE_TYPE_FORM = 'insuredClauses';
export const RISK_CLAUSE_TYPE_FORM = 'riskClauses';

export interface ClausesStepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'clauses'`
   * @stepField label `'lpc_variation_step_label'`
   * @stepField fieldId `'clauses'`
   * @stepField formName `'clauses'`
   */
  CLAUSES: ClausesStepConfiguration;
}

export interface ClausesStepConfiguration extends StepConfiguration {
  formChildren: {
    clauseInstance;
    codRisk;
    clauseList: 'clauses';

    // inside clauseList
    clauseText: 'text';
    clauseCode: 'code';
    clauseState;
  };
}

export const CLAUSES: ClausesStepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates'
  },
  /* QUESTS_FIN: {
    id: 'questsFin',
    label: 'lpc_financial_questionnaire_step',
    fieldId: 'questsFin',
    formName: EMPTY_STR
  }, */
  CLAUSES: {
    id: 'clauses',
    label: 'lpc_variation_step_label',
    fieldId: 'clauses',
    formName: 'clauses',
    formChildren: {
      clauseInstance: 'nomeIstanza',
      codRisk: 'codRisk',
      clauseList: 'clauses',
      // inside clauseList
      clauseState: 'clauseState',
      clauseCode: 'code',
      clauseText: 'text',
    }
  },
  /* QUESTS: {
    id: 'quests',
    label: 'lpc_avc_questionnaire',
    fieldId: 'quests',
    formName: EMPTY_STR
  }, */
  NOTES: {
    id: 'notes',
    label: EMPTY_STR,
    fieldId: 'notes',
    formName: 'notes'
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR
  },
};

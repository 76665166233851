import { Component, OnInit } from '@angular/core';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {
  ActionTypeIssueComplete, ACTION_ENUM_ISSUE_COMPLETED
} from '../../group-policy-models/group-policy-issue-confirm';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {GroupPolicyIntegrationService} from '../../group-policy-services/group-policy-integration.service';
import {GroupPolicyStateIssueConfirm} from '../../group-policy-state/group-policy-state';
import {
  GroupPolicyStateManagerIssueConfirm
} from '../../group-policy-state/group-policy-statemanager-issue-confirm/group-policy-statemanager-issue-confirm';
import {
  GroupPolicyApplicationInclusionService
} from '../../group-policy-services/group-policy-application-inclusion.service';
import {ApplicationInclusionModalData} from '../../group-policy-models/group-policy-inquiry';

@Component({
  selector: 'rgi-gp-group-policy-issue-confirm',
  templateUrl: './group-policy-issue-confirm.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyIssueConfirmComponent extends RoutableComponent implements OnInit {

  public stateIssue: GroupPolicyStateIssueConfirm;
  public previousStepData: DataForSteps;

  constructor(
    protected stateManagerIssue: GroupPolicyStateManagerIssueConfirm,
    protected activeRoute: ActiveRoute,
    protected gpIntegrationService: GroupPolicyIntegrationService,
    protected appInclusionService: GroupPolicyApplicationInclusionService) {
    super();
  }

  ngOnInit(): void {
    this.previousStepData = this.activeRoute.getRouteData();
    this.stateManagerIssue.getState$().subscribe((nextState: GroupPolicyStateIssueConfirm) => {
      this.initPage(nextState);
    });
  }

  protected initPage(nextState: GroupPolicyStateIssueConfirm) {
    this.stateIssue = nextState;
  }

  public callActionEmit(code: ActionTypeIssueComplete) {
    if (!!code) {
      switch (code) {
        case ACTION_ENUM_ISSUE_COMPLETED.INQUIRY:
          this.previousStepData.idParentSession = this.id;
          const isFromIssuePage = true;
          this.stateManagerIssue.actionGoToInquiry(this.previousStepData, isFromIssuePage);
          break;
        case ACTION_ENUM_ISSUE_COMPLETED.DOCUMENTS:
          //TODO action for documents
          break;
        case ACTION_ENUM_ISSUE_COMPLETED.PRINT:
          //TODO action for documents
          break;
        case ACTION_ENUM_ISSUE_COMPLETED.GENERATE_TITLE:
          //TODO action to generate title
          break;
        case ACTION_ENUM_ISSUE_COMPLETED.INCLUDE_APPLICATION:
          const modalData = {
            policyNumber: this.stateIssue.contractData.policyNumber,
            proposalNumber: this.stateIssue.contractData.proposalNumber,
            idComponent: this.activeRoute.id,
            product: this.previousStepData.product
          } as ApplicationInclusionModalData;
          this.appInclusionService.handleApplicationInclusion(modalData);
          break;
        case ACTION_ENUM_ISSUE_COMPLETED.END:
          this.stateManagerIssue.actionBackToHome(this.activeRoute);
          break;
      }
    }
  }
}

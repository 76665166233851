<!-- TABLE DETAIL -->
    <table rgi-rx-table [dataSource]="totalAllRisk">

        <ng-container rgiRxColumnDef="Movements">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.Movements</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row?.idDashBoard?.length}} </td>
        </ng-container>

        <ng-container rgiRxColumnDef="Comm. Bank Purchase">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_PURCH_BANK</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.intPurchaseCommAmt}} </td>
        </ng-container>

        <ng-container rgiRxColumnDef="Comm. Company Purchase">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_PURCH_COMPANY</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.extPurchaseCommAmt}} </td>
        </ng-container>

        <ng-container rgiRxColumnDef="Comm. Bank Collect">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_COLL_BANK</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.intCollCommAmt}} </td>
        </ng-container>

        <ng-container rgiRxColumnDef="Comm. Company Collect">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_COLL_COMPANY</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row"> {{row.extCollCommAmt}} </td>
        </ng-container>
        <!-- SUMMARY MODE -->
        <ng-container rgiRxColumnDef="Comm. Final Bank">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_FINAL_BANK</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row">{{row.sumIntPurchaseCollCommAmt}}</td>
        </ng-container>

        <ng-container rgiRxColumnDef="Comm. Final Company">
            <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>ICD.COMMISSIONS_FINAL_COMPANY</td>
            <td class="td-amount" rgi-rx-cell *rgiRxCellDef="let row">{{row.sumExtPurchaseCollCommAmt}}</td>
        </ng-container>
        <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
        <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
    </table>

<dashboard-detail-param [overview]="overview" [operation]="operation" [text]="modalConfirmText" (clear)="clear()" (apply)="apply()"
                        (actionSelected)="updateAction($event)"
                        (noteSelected)="updateNotes($event)" [commissionStatus]="commissionStatus">
</dashboard-detail-param>

import { Component, Input, OnInit } from '@angular/core';
import { Party } from '@rgi/digital-claims-common-angular';
import { TypeOfLossConstants } from '../constants/type-of-loss-constants';

@Component({
  selector: 'claims-involved-party',
  templateUrl: './involved-party.component.html',
  styleUrls: ['./involved-party.component.scss']
})
export class InvolvedPartyComponent implements OnInit {

  constructor(
    public typeOfLossConstants: TypeOfLossConstants,
    ) {
    }

  @Input()
  party: any;

  hasTolDamage: boolean = false;
  hasTolLesions: boolean = false;
  hasTolDeath: boolean = false;
  hasTolVehicle: boolean = false;
  hasLitigation: boolean = false;

  ngOnInit() {

    if (this.party.damageDescriptions) {
        // if (this.party.damageDescriptions.length) {
        //     this.hasTolDamage = true;
        // }

        for (const damageDescriptionsElement of this.party.damageDescriptions) {
        // for (let k = 0; k < this.party.damageDescriptions.length; k++) {

            let assetType = damageDescriptionsElement.enumAssetType;
            if (!assetType && damageDescriptionsElement.asset) {
                assetType = damageDescriptionsElement.asset.assetType;
            }
            if (assetType) {
                switch (assetType.codice) {
                case this.typeOfLossConstants.TYPE_OF_LOSS_VEHICLE:
                    this.hasTolVehicle = true;
                    break;
                case this.typeOfLossConstants.TYPE_OF_LOSS_PERSON:
                    this.hasTolLesions = true;
                    if (damageDescriptionsElement.asset && damageDescriptionsElement.asset.party
                        && damageDescriptionsElement.asset.party.deceaseDate) {
                        this.hasTolDeath = true;
                    }
                    break;
                case this.typeOfLossConstants.TYPE_OF_LOSS_THING:
                    this.hasTolDamage = true;
                    break;
                }
            }
            if (damageDescriptionsElement.litigationsList && damageDescriptionsElement.litigationsList.length) {
                this.hasLitigation = true;
            }
        }
    }
  }
}

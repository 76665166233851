import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ADDRESS_FORM_CONFIG, ADDRESS_INPUT_TYPE, ADDRESS_LAYOUT_VISIBLE} from '../../../anag-constants/anag-constants';
import {AnagAddressLayout} from '../../../anag-model/anag-domain/anag-address-config';
import {UntypedFormGroup} from '@angular/forms';
import {AnagApiEntity, AnagEntityIta} from '../../../anag-model/anag-api/anag-subject-api';
import { Observable, combineLatest, of } from 'rxjs';
import { tap, delay, map } from 'rxjs/operators';

@Component({
  selector: 'rgi-anag-uma-address-input',
  templateUrl: './anag-uma-address-input.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagUmaAddressInputComponent implements OnInit, OnChanges {

  @Input() formGroup: UntypedFormGroup;
  @Input() filteredOptionsValues: Array<AnagApiEntity | AnagEntityIta> | null;
  @Input() addressConfigField: AnagAddressLayout;
  @Input() fieldName: string;
  @Output() onZipChange: EventEmitter<any> = new EventEmitter<any>();
  optValue: string;
  optLabel: string;
  inputType: ADDRESS_INPUT_TYPE;
  displayConfig: any | null;
  isOptionListAvailable = false;
  isVisible = false;
  initialized = false;
  hasLoaded = false;
  filteredOptions;

  // let enum available in html template
  ADDRESS_INPUT_TYPE = ADDRESS_INPUT_TYPE;
  ADDRESS_FORM_CONFIG = ADDRESS_FORM_CONFIG;


  constructor() { }

  ngOnInit() {
    this.displayConfig = this.ADDRESS_FORM_CONFIG.get(this.fieldName);
    this.isFieldVisible();
    this.inputType = this.getOptType();
    this.optValue = this.getOptValue();
    this.optLabel = this.getOptLabel();
    this.initialized = true;
    this.isOptionListAvailable = !!this.filteredOptionsValues &&
    this.filteredOptionsValues.length > 0 && !!this.optValue && !!this.optLabel;
    if (this.isOptionListAvailable) {
      if (this.optValue === 'description') {
        this.filteredOptions =  Observable<AnagApiEntity[]>;
      } else {
        this.filteredOptions =  Observable<AnagEntityIta[]>;
      }
      this.filteredOptions = this.filter('');
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.filteredOptionsValues && this.initialized) {
      this.isOptionListAvailable = !!changes.filteredOptionsValues && !!changes.filteredOptionsValues.currentValue &&
        changes.filteredOptionsValues.currentValue.length > 0 && !!this.optValue && !!this.optLabel;
        if (this.isOptionListAvailable) {
          this.filteredOptions = this.filter('');
        } else {
          this.filteredOptions = undefined;
        }
    } else {
      this.isOptionListAvailable = false;
      this.filteredOptions = undefined;
    }
  }


  isFieldVisible() {
    if (this.addressConfigField ) {
      this.isVisible = this.addressConfigField.layout === ADDRESS_LAYOUT_VISIBLE;
    } else {
      this.isVisible = false;
    }
  }

  getOptValue() {
    return !!this.displayConfig ? this.displayConfig.optValue : null ;
  }
  getOptLabel() {
    return !!this.displayConfig ? this.displayConfig.optLabel : null ;
  }
  getOptType() {
    return !!this.displayConfig ? this.displayConfig.type : null ;
  }

  onZipBlur(fieldName: string) {
    if (fieldName === 'zip') {
      this.onZipChange.emit(this.formGroup.controls.zip.value);
    }
  }

  onAutoCompleteChange(event: any) {
    if (this.isOptionListAvailable) {
      const data = typeof event === 'object' ? event.description : event.toLowerCase();
      this.filteredOptions = this.filter(data);
    }
  }

  onAutoCompleteValueChange(event: any) {
    if (this.isOptionListAvailable) {
      const data = typeof event === 'object' ? event.value : event;
      this.filteredOptions = this.filter(data);
    }
  }

  restore() {
    if (this.isOptionListAvailable) {
      this.filteredOptions = this.filter('');
    }
  }

  private filter(data: any) {
    return combineLatest([of(this.filteredOptionsValues), of(data)])
      .pipe(
        tap(f => this.hasLoaded = false),
        delay(100),
        map(([v, d]) => v.filter(
          f =>
            !d ||
            (
              f['descrizione'] && f['descrizione'].toLowerCase().startsWith(d.toLowerCase())
            || (f['codice'] && (f['codice'] + '').toLowerCase().startsWith(d.toLowerCase()))
            || (f['description'] && f['description'].toLowerCase().startsWith(d.toLowerCase()))
            || (f['code'] && (f['code'] + '').toLowerCase().startsWith(d.toLowerCase())))
      )),
        tap(f => this.hasLoaded = true),
      );
  }
}

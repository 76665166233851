<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="onClose()">
    <div translate>{{getTitle()}}</div>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button
      class="rgi-ui-btn rgi-ui-btn-primary uppercase"
      *ngIf="editorMode === GRP_OBJ_MODE.VIEW"
      (click)="onConfirm()" translate>{{'_GP_._BTN_._CONFIRM_'}}
    </button>
    <button
      class="rgi-ui-btn rgi-ui-btn-primary uppercase"
      *ngIf="editorMode === GRP_OBJ_MODE.VIEW && isButtonNewVisible()"
      (click)="onNew()" translate>{{'_GP_._BTN_._NEW_'}}
    </button>
    <button
      class="rgi-ui-btn rgi-ui-btn-primary uppercase"
      *ngIf="editorMode === GRP_OBJ_MODE.INSERT || editorMode === GRP_OBJ_MODE.EDIT"
      (click)="onUndoEditNew()" translate>{{'_GP_._BTN_._UNDO_'}}
    </button>
    <button
      class="rgi-ui-btn rgi-ui-btn-primary uppercase"
      *ngIf="editorMode === GRP_OBJ_MODE.INSERT || editorMode === GRP_OBJ_MODE.EDIT"
      (click)="onConfirmEditNew()" translate>{{'_GP_._BTN_._CONFIRM_'}}
    </button>
  </rgi-rx-panel-footer>

  <div *ngIf="editorMode === GRP_OBJ_MODE.VIEW">
    <div class="rgi-ui-text-4 rgi-ui-info">
      <span translate>_GP_._LABEL_._OUR_PERC_</span>: {{ourPercentage + '%'}}
    </div>

    <rgi-rx-datatable [data]="tableData" [schema]="schema"
                      (onAction)="onActionClick($event)"
                      [selectable]="false">
    </rgi-rx-datatable>
    <br>
    <div class="rgi-ui-text-4 rgi-ui-info">
      <span translate>_GP_._LABEL_._TOTAL_PERC_</span>:
      <span [ngClass]="{'rgi-ui-text-4 rgi-ui-danger': totalPercentage > 100}">{{totalPercentage + '%'}}</span>
    </div>
    <br>
    <div class="rgi-ui-snackbar rgi-ui-danger" data-qa="invalid-perc" *ngIf="totalPercentage > 100" >
      <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
      <span class="rgi-ui-message" translate>_GP_._LABEL_._TOTAL_PERC_ERROR_</span>
    </div>
    <div class="rgi-ui-snackbar rgi-ui-danger" data-qa="invalid-perc" *ngIf="totalCommissions > 100" >
      <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
      <span class="rgi-ui-message" translate>_GP_._LABEL_._TOTAL_PERC_COMMISSIONS_ERROR_</span>
    </div>
  </div>

  <div *ngIf="editorMode === GRP_OBJ_MODE.INSERT || editorMode === GRP_OBJ_MODE.EDIT">
    <ng-container [formGroup]="coinsuranceShareForm">
      <rgi-rx-form-field>
        <label rgiRxLabel>
          <span translate>_GP_._LABEL_._COMPANY_</span>
          <span class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*<span translate>_GP_._LABEL_._REQUIRED_</span></span>
        </label>
        <select rgiRxNativeSelect formControlName="company" data-qa="company">
          <option value=""></option>
          <option *ngFor="let company of externalCompanies" [value]="company.id">{{company.description}}</option>
        </select>
        <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel>
          <span translate>_GP_._LABEL_._INTERMEDIARY_</span>
          <span class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*<span translate>_GP_._LABEL_._REQUIRED_</span></span>
        </label>
        <select rgiRxNativeSelect formControlName="intermediary" data-qa="intermediary">
          <option value=""></option>
          <option *ngFor="let intermediary of intermediaries" [value]="intermediary.id">{{intermediary.description}}</option>
        </select>
        <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel>
          <span translate>_GP_._LABEL_._SHARE_PERC_</span>
          <span class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*<span translate>_GP_._LABEL_._REQUIRED_</span></span>
        </label>
        <input type="number" rgiRxInput formControlName="percentageShare" data-qa="percentageShare">
        <div class="rgi-ui-error-message" translate>_GP_._MSG_._INVALID_FIELD_</div>
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel>
          <span translate>_GP_._LABEL_._COMMISSIONS_PERC_</span>
          <span class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*<span translate>_GP_._LABEL_._REQUIRED_</span></span>
        </label>
        <input type="number" rgiRxInput formControlName="percentageCommissions" data-qa="percentageCommissions">
        <div class="rgi-ui-error-message" translate>_GP_._MSG_._INVALID_FIELD_</div>
      </rgi-rx-form-field>

      <rgi-rx-form-field>
        <label rgiRxLabel>
          <span translate>_GP_._LABEL_._POLICY_NUMBER_</span>
          <span *ngIf="isThirdPartyMandate()" class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*<span translate>_GP_._LABEL_._REQUIRED_</span></span>
        </label>
        <input rgiRxInput formControlName="policyNumber" data-qa="policyNumber">
        <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
      </rgi-rx-form-field>

    </ng-container>
  </div>

</rgi-rx-panel>

import { Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import { Guardian, HeirsListSubjLink, SubjectData, SubjectReduced } from '../dsc-shared-model/dsc-model-subject';
import { InsurancePolicy } from '../dsc-shared-model/dsc-model-policy';
import { InjuryAsset, InjuryData } from '../dsc-shared-model/dsc-model-injury';
import { CategoryDetail } from '../dsc-shared-model/dsc-model-utility';
import { AnagFlowData, AnagIntegrationService, AnagRouteData } from '@rgi/anag';

@Component({
  selector: 'claims-dsc-policy-holder',
  templateUrl: './dsc-policy-holder.component.html'
})
export class DscPolicyHolderComponent implements OnInit {
  constructor(
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: any,
    public anagIntegrationService: AnagIntegrationService
  ) { }

  @Input() selectedNode: string;
  @Input() activeRouteId: string;
  @Input() policyHolder: SubjectReduced;
  @Input() roles;
  @Input() typeOpenComponent: OpenTypeSharedComponent;
  @Input() categorySelected: number;
  @Input() selectedPolicy: InsurancePolicy;
  @Input() policyNode: any;
  @Input() categoryList: CategoryDetail[] = [];
  @Input() listMinorGuardian: Guardian[] = [];
  @Input() chkInjury: boolean;
  @Input() heirsList: HeirsListSubjLink;
  @Output() outputDataFormHolder = new EventEmitter<{ subject: SubjectReduced, dataForm?: InjuryAsset }>();
  @Output() outputHeir = new EventEmitter<string>();
  subjectData: SubjectData;
  subjectDataInjury: InjuryData;
  isAccordion: boolean = false;
  checkFatalityDate: boolean = false
  fields =
    [
      'isOwner',
      'isDriver',
      'isPassenger',
      'chkMaterial',
      'materialDescription',
      'chkInjury',
    ];
  expanded: boolean = true;

  ngOnInit(): void {
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
  }

  outputFormChange(event, listMinorGuardian: Guardian[]) {
    const dataChange = { ...this.policyHolder };
    if (event) {
      this.chkInjury = event.chkInjury && (event.isDriver || event.isPassenger);
      dataChange.chkMaterial = event.chkMaterial;
      dataChange.materialDescription = event.materialDescription;
      dataChange.chkInjury = event.chkInjury;
      dataChange.isValidForm = event.isValidForm;
      dataChange.isDriver = event.isDriver;
      dataChange.isOwner = event.isOwner;
      dataChange.isPassenger = event.isPassenger;
      dataChange.isIncapacitated = event.isIncapacitated;
      dataChange.isMinor = event.isMinor;
    }
    dataChange.listMinorGuardianSubject = listMinorGuardian;
    this.subjectData = { subject: dataChange, dataForm: this.policyHolder.subjectDataFormInjury };
    this.outputDataFormHolder.emit(this.subjectData);
  }

  outputFormChangeInjury(event: InjuryData) {
    this.subjectDataInjury = { subject: this.policyHolder, dataForm: event.dataForm, roles: event.roles };
    this.outputDataFormHolder.emit(this.subjectDataInjury);
  }

  openDetail() {
    // const idNavigation = that.sessionService.open('anagFinder', 'detail', '', true, null, null, that.card.idSession);
    const flowData: AnagFlowData = new AnagFlowData();
    flowData.idParentSession = this.activeRouteId;
    flowData.nodeCode = this.selectedNode;
    flowData.partyRole='888';
    const anagRouteData: AnagRouteData = new AnagRouteData();
    const subject: any = this.policyHolder.subject.anagSubject;

    if (subject && !subject.node && this.policyNode) {
      subject.node = this.policyNode;
    }

    anagRouteData.party = subject;
    anagRouteData.redirectToMainFlow = true;
    anagRouteData.flowData = flowData;
    (this.anagIntegrationService as any).openDetailSubject(anagRouteData, this.activeRouteId);
    /* const idNavigation = this.sessionService.open('anagFinder', 'detail', '', true, null, null, this.activeRouteId);
    this.coreResult.setResult(idNavigation, 'detail', {
      subject: this.policyHolder.subject.anagSubject
    }); */
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }

  listMinorGuardianChange(event) {
    this.listMinorGuardian = event;
    this.outputFormChange(null, this.listMinorGuardian)
  }

  outputHeirData(event) {
    this.outputHeir.emit(event);
  }

}

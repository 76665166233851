import {Directive, TemplateRef} from '@angular/core';
import {RgiRxTemplateContentDirective} from '../rgi-ui-api';

@Directive({
  selector: 'ng-template[rgi-rx-tab-content],ng-template[rgiRxTabContent]'
})
export class RgiRxTabContentDirective extends RgiRxTemplateContentDirective {


  constructor(template: TemplateRef<any>) {
    super(template);
  }
}

export class SumDecimalsUtils {
    /**
   * Sums an array of decimal numbers with specified precision.
   * 
   * @param numbers Array of numbers to be summed.
   * @param factor The factor by which to multiply decimals for precision.
   *               For example, use 100 for precision to two decimal places.
   *               This should be a positive integer.
   * @returns The sum of the numbers, adjusted for the specified precision.
   */
    public static sumDecimalsArray(numbers: number[], factor: number): number {
        const sum = numbers.reduce((acc, num) => acc + Math.round(num * factor), 0);
        return sum / factor;
    }
}

import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { ROUTES_GP_GUARANTEES, ROUTES_GP_POLDATA } from '../../group-policy-constants/routes-constants';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import { ErrorCode, Factor } from '../../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyResourceService } from '../../group-policy-resources/group-policy-resource.service';
import { GroupPolicyApiRestErrorService } from '../../group-policy-services/group-policy-api-rest-error.service';
import { GroupPolicyCrossService } from '../../group-policy-services/group-policy-cross.service';
import { GroupPolicyIntegrationService } from '../../group-policy-services/group-policy-integration.service';
import {GroupPolicyStateConfigurationPm} from '../group-policy-state';

/**
 * * @description: Component used for configuration pm services
 */
@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpConfigurationPmService {

  constructor(
    protected groupPolicyService: GroupPolicyResourceService,
    protected routingService: RoutingService,
    protected gpErrorService: GroupPolicyApiRestErrorService,
    protected integrationService: GroupPolicyIntegrationService,
    protected crossService: GroupPolicyCrossService) { }

  public initConfigurationPmData$(
    groupPolicyStateConfigurationPm$: Observable<GroupPolicyStateConfigurationPm>,
    activeRoute: ActiveRoute): Observable<GroupPolicyStateConfigurationPm> {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    return groupPolicyStateConfigurationPm$.pipe(
      mergeMap((st: GroupPolicyStateConfigurationPm) => {
        return combineLatest(of(st),
          this.initVariables$(of(st), previousStepData.resId, 'true', 'true', 'true')
        );
      }),
      mergeMap(([st]) => {
        return combineLatest(of(st),
          this.retrieveFactors$(of(st), previousStepData.resId, 'true', 'true', 'true')
        );
      }),
      map(([st]: [GroupPolicyStateConfigurationPm, any]) => {
        st.errors = previousStepData.errors;
        st.type = previousStepData.type;
        st.proposalNumber = null;
        return st;
      })
    );
  }


  public retrieveFactors$(
    groupPolicyStateConfigurationPm$: Observable<GroupPolicyStateConfigurationPm>, uuid: string, visible = 'true',
    configurable = 'true', onlyApplication = 'true'): Observable<GroupPolicyStateConfigurationPm> {
    return groupPolicyStateConfigurationPm$.pipe(
      concatMap((state: GroupPolicyStateConfigurationPm) => {
        return combineLatest(of(state), this.groupPolicyService.retrieveFactors$(uuid, visible, configurable, onlyApplication));
      }),
      map(([state, factors]: [GroupPolicyStateConfigurationPm, Factor[]]) => {
        state.factors = factors;
        return state;
      })
    );
  }

  public updateFactors$(
    groupPolicyStateConfigurationPm$: Observable<GroupPolicyStateConfigurationPm>, uuid: string,
    factors: Factor[]): Observable<GroupPolicyStateConfigurationPm> {
    return groupPolicyStateConfigurationPm$.pipe(
      concatMap((state: GroupPolicyStateConfigurationPm) => {
        return combineLatest(of(state), this.groupPolicyService.updateFactors$(uuid, factors));
      }),
      map(([state, factorsResponse]: [GroupPolicyStateConfigurationPm, Factor[]]) => {
        state.factors = factorsResponse;
        return state;
      })
    );
  }

  initVariables$(state$: Observable<GroupPolicyStateConfigurationPm>, uuid: string, visible = 'true',
                 configurable = 'true', onlyApplication = 'true'): Observable<GroupPolicyStateConfigurationPm> {
    return state$.pipe(
      concatMap((state: GroupPolicyStateConfigurationPm) => {
        return combineLatest(of(state), this.groupPolicyService.initVariables$(uuid, visible, configurable, onlyApplication));
      }),
      map(([state]: [GroupPolicyStateConfigurationPm, any]) => {
        return state;
      })
    );
  }

  public actionBack$(
    groupPolicyStateConfigurationPm$: Observable<GroupPolicyStateConfigurationPm>,
    previousStepData: DataForSteps, activeRoute: ActiveRoute,
    targetRoute = ROUTES_GP_POLDATA): Observable<GroupPolicyStateConfigurationPm> {
    return groupPolicyStateConfigurationPm$.pipe(
      tap(() => {
        previousStepData.errors = [];
        previousStepData.type = ErrorCode.BLOCKING;
        this.integrationService.navigate(this.routingService, targetRoute, previousStepData, activeRoute);
      })
    );
  }

  public actionSave$(
    groupPolicyStateConfigurationPm$: Observable<GroupPolicyStateConfigurationPm>,
    uuid: string, nodeCode: string, activeRoute: ActiveRoute): Observable<GroupPolicyStateConfigurationPm> {
    return groupPolicyStateConfigurationPm$.pipe(
      concatMap((state: GroupPolicyStateConfigurationPm) => {
        state.errors = this.gpErrorService.cleanErrorsForCode(state.errors, 'FOOTER_ACTION');
        return this.groupPolicyService.actionCode$(uuid, 'CPM').pipe(
          map((resp) => {
            state.errors = this.gpErrorService.manageErrors(resp, 'FOOTER_ACTION');
            return state;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(state, 'FOOTER_ACTION'))
        );
      }),
      concatMap((st: GroupPolicyStateConfigurationPm) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_SAVE_ERROR');
        return this.groupPolicyService.saveAction$(uuid, nodeCode).pipe(
          map((saveResp) => {
            st.proposalNumber = saveResp.proposalNumber;
            this.crossService.showModalContractNumber(saveResp, activeRoute.id).subscribe();
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_SAVE_ERROR'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateConfigurationPm) => st)
    );
  }

  public actionGoToGuarantees$(
    groupPolicyStateConfigurationPm$: Observable<GroupPolicyStateConfigurationPm>,
    previousStepData: DataForSteps, activeRoute: ActiveRoute): Observable<GroupPolicyStateConfigurationPm> {
    return groupPolicyStateConfigurationPm$.pipe(
      concatMap((st: GroupPolicyStateConfigurationPm) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER_ACTION');
        return this.groupPolicyService.actionCode$(previousStepData.resId, 'CPM').pipe(
          map((resp) => {
            st.errors = this.gpErrorService.manageErrors(resp, 'FOOTER_ACTION');
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER_ACTION'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateConfigurationPm) => {
        if (!this.gpErrorService.containsBlockingErrors(st.errors)) {
          this.integrationService.navigate(this.routingService, ROUTES_GP_GUARANTEES, previousStepData, activeRoute);
        }
        return st;
      })
    );
  }

}

import {Inject, Injectable} from '@angular/core';
import {GP_COMMISSIONS_DISCOUNT_BUTTONS, GPCommissionDiscountBtnsConfig} from './gp-commission-discount.config';
import {GroupPolicyStateCluster, GroupPolicyStateGuarantees} from '../group-policy-state/group-policy-state';

@Injectable({
  providedIn: 'root'
})
export class GpCommissionDiscountBtnsService {

  constructor(
    @Inject(GP_COMMISSIONS_DISCOUNT_BUTTONS) protected buttonsConfig: GPCommissionDiscountBtnsConfig
  ) {
  }

  isCommissionFooterBtnVisible(guaranteesState: GroupPolicyStateGuarantees): boolean {
    const pageFooter = this.getPageFooter(guaranteesState);
    return pageFooter.commissions.showBtn;
  }

  getCommissionFooterBtnLabel(guaranteesState: GroupPolicyStateGuarantees): string {
    const pageFooter = this.getPageFooter(guaranteesState);
    return pageFooter.commissions.btnLabel;
  }

  isCommissionFooterBtnEnabled(guaranteesState: GroupPolicyStateGuarantees): boolean {
    return !!guaranteesState.commissions && !!guaranteesState.commissions.length;
  }

  isDiscountFooterBtnVisible(guaranteesState: GroupPolicyStateGuarantees): boolean {
    const pageFooter = this.getPageFooter(guaranteesState);
    return pageFooter.discount.showBtn;
  }

  getDiscountFooterBtnLabel(guaranteesState: GroupPolicyStateGuarantees): string {
    const pageFooter = this.getPageFooter(guaranteesState);
    return pageFooter.discount.btnLabel;
  }

  isDiscountFooterBtnEnabled(_guaranteesState: GroupPolicyStateGuarantees): boolean {
    return false;
  }

  private getPageFooter(guaranteesState: GroupPolicyStateGuarantees) {
    return guaranteesState.isClusterContract ? this.buttonsConfig.cluster.pageFooter :
      this.buttonsConfig.standard.pageFooter;
  }

  isClusterCommissionBtnVisible(): boolean {
    return this.buttonsConfig.cluster.clusterSection.showBtn;
  }

  getClusterCommissionBtnLabel(): string {
    return this.buttonsConfig.cluster.clusterSection.btnLabel;
  }

  isClusterCommDiscountBtnEnabled(stateCluster: GroupPolicyStateCluster): boolean {
    return !!stateCluster.editableCluster.commissions && stateCluster.editableCluster.commissions.length > 0;
  }
}

export interface ReasonRejected {
    code: string;
    description: string;
}
export interface DetailReceiptReturn {
    acceptedCheckbox: boolean;
    rejectedCheckbox: boolean;
    dateReceipt: string;
    note: string;
    codeReasonRejected: string;
    claimNumber: string;
    receiptId: string;
}

export const INIT_DETAIL_RECEIPT: DetailReceiptReturn = {
    acceptedCheckbox: false,
    rejectedCheckbox: false,
    dateReceipt: new Date().toDateString(),
    note: '',
    claimNumber: '',
    receiptId: '',
    codeReasonRejected: ''
};

export interface BodyRequest {
    receiptId: string;
    receiptDate: Date;
    receiptNote: string;
    receiptStatus: string;
    receiptIdMotivoAnnullo: string;
}

export interface IComboListReceipt {
    tipoCausale: string;
    dataSinistro: string;
    ramoMinisteriale: string;
}


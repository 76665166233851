import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[rgiGpFocusElement]'
})
export class FocusElementDirective implements AfterViewInit {
  currentElementName: string;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.currentElementName = this.el.nativeElement.getAttribute("data-qa");
    if (localStorage.getItem('element') === this.currentElementName) {
      this.el.nativeElement.focus();
    }
  }

  @HostListener('focus') onFocus() {
    localStorage.setItem('element', this.currentElementName);
  }

  @HostListener('blur') onBlur() {
    localStorage.removeItem('element');
  }

}

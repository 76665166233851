import { Factor } from '../../../models/policy.model';
import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lic-radio-group-factor',
  templateUrl: './radio-group-factor.component.html',
  styleUrls: ['./radio-group-factor.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class RadioGroupFactorComponent implements OnInit {
  value: string;
  options: { label: string; value: string; }[] = [];
  config: Factor;
  group: UntypedFormGroup;
  selectedValue = null;
  prevVal: string;
  modifiable: string;
  mandatory: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.value === null || this.config.value === '' || this.config.value === '-1') {
      this.selectedValue = null;
      this.prevVal = null;
    } else {
      this.selectedValue = this.config.value;
      this.prevVal = this.config.value;
    }
    this.group.controls[this.config.code].setValue(this.selectedValue);
    if (this.config.compulsory) {
      this.mandatory = 'Obbligatorio';
      this.group.controls[this.config.code].setValidators(Validators.required);
    } else {
      this.mandatory = 'Non obbligatorio';
    }
    if (this.config.modifiable) {
      this.modifiable = 'Modificabile';
      this.group.controls[this.config.code].enable();
    } else {
      this.modifiable = 'Non modificabile';
      this.group.controls[this.config.code].disable();
    }

    this.group.controls[this.config.code].valueChanges.subscribe(value => {
      if (this.prevVal !== value) {
        this.el.nativeElement.dispatchEvent(new CustomEvent('changeVal', {bubbles: true, detail: this.config.code}));
        this.prevVal = value;
      }
    });
  }

  setFactor(factor: Factor, form: UntypedFormGroup) {
    this.config = factor;
    this.value = this.config.description;
    for (const key in this.config.values) {
      if (key) {
        const valore: { label: string; value: string; } = {label: this.config.values[key].description,
          value: this.config.values[key].value};
        this.options.push(valore);
      }
    }
    this.group = form;
  }

}

<div class="grid-item card card-height-normal card-main">

  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title">{{ '_CLAIMS_._EXTRA_INFO' | translate }}
        <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
      </h3>
    </div>
  </div>


  <!-- modale -->
  <div class="container-fluid table-white" data-qa="transactions-list">

    <!-- tabella -->
    <div class="table-responsive table coreDT">
      <div *ngFor="let extrainfoHeader of listExtraInfoHeader; let even = even; let odd = odd; let eIHIndex = index"
        [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="table-row"
        attr.data-qa="extrainfo-result-{{extrainfoHeaderIndex}}">
  
        <div class="row head-result thDataTable col-sm-12">          
            <span attr.data-qa="eIH-descrizione-{{eIHIndex}}">{{ extrainfoHeader.descrizione }}</span>
        </div>
  
  
        <div class="row head-result thDataTable">

            
          <div class="col-sm-1" style="width: 5%;">
            <span attr.data-qa="extrainfo-typr-label">  &nbsp;  </span>
          </div>
  
          <div class="col-sm-3">
            <span attr.data-qa="extrainfo-typr-label">{{ '_CLAIMS_._TYPE' | translate }}</span>
          </div>
  

  
          <div class="col-sm-3">
            <span attr.data-qa="extrainfo-desc-label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</span>
          </div>

  
          <div class="col-sm-2">
            <span attr.data-qa="tot-amount-label">{{ '_CLAIMS_._AMOUNT' | translate }}</span>
          </div>
  
          <div class="col-sm-2">
            <span attr.data-qa="extrainfo-day-label">{{ '_CLAIMS_._DAYS' | translate }}</span>
          </div>
  
  
        </div>
  
  
  
        <div *ngFor="let extrainfo of extrainfoHeader.listExtraInfo; let even = even; let odd = odd; let extrainfoIndex = index"
          [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="row"
          attr.data-qa="extrainfo-result-{{extrainfoIndex}}">
        
          <div class="col-sm-1" style="width: 5%;">
            <span attr.data-qa="extrainfo-checked-{{extrainfoIndex}}">
              <!-- <input id="{{extrainfo.idExtraInfo}}" type="radio" value="{{extrainfo.idExtraInfo}}" name="" > -->
              <input *ngIf="isCompExtraInfoManage && extrainfo.selectable != null && extrainfo.selectable" required
                attr.data.qa="extrainfo-chk-{{extrainfoIndex}}" name="extraInfo" type="radio" id="{{extrainfo.idExtraInfo}}"
                [value]="extrainfo.idExtraInfo" [checked]="extrainfo.checked" [ngClass]="{submitted: submitted}"
                (change)="onSelectionChange(extrainfo.idExtraInfo,extrainfoHeader.chiave)">
            </span>
          </div>
        
                  
          <div class="col-sm-3">
            <span attr.data-qa="extrainfo-value-{{extrainfoIndex}}">{{ extrainfo.value }}</span>
          </div>
        
          <div class="col-sm-3">
            <span attr.data-qa="extrainfo-desc-{{extrainfoIndex}}">{{ extrainfo.desc}}</span>
          </div>

        
          <div class="col-sm-2">
            <span attr.data-qa="extrainfo-amount-{{extrainfoIndex}}">{{ extrainfo.amount | currency:'EUR':true}}</span>
          </div>
        
          <div class="col-sm-2">
            <span attr.data-qa="extrainfo-day-{{extrainfoIndex}}">{{ extrainfo.day }}</span>
          </div>

        </div>
  
    </div>
  </div>
  <br>
  

    <div *ngIf="isCompExtraInfoManage" class="btn-group btn-group-justified">
      <div class="btn-group">
        <button type="submit" class="btn btn-warning pull-right" attr.data-qa="extrainfo-btn" [disabled]="isConfermaDisabled"
          id="extrainfo-update-submit" (click)="confirmExtraInfo()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
      </div>
    </div>

 

</div>
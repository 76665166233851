export class City {

  constructor(
    public id: number,
    public cityName: string,
    public istatCode: string,
    public provinceCode: string,
    public cadastralCode: string,
    public zipCode: string,
    public exists: boolean,
  ) {
  }

}

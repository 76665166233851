import {Pipe, PipeTransform} from '@angular/core';
import {GPAsset, GpAssetUnitsClauses, GpUnitClause, SingleUnit} from '../../../group-policy-models/group-policy-issue-guarantees';

@Pipe({
  name: 'gpFilterAssetUnitsClauses'
})
export class GpFilterAssetUnitsClausesPipe implements PipeTransform {
  transform(assetUnitsClauses: GpAssetUnitsClauses[], asset: GPAsset, unit: SingleUnit): GpUnitClause {
    const gpAssetUnitsClauses = assetUnitsClauses.find(value => !!value && value.codeAsset === asset.codeAsset);
    return asset && gpAssetUnitsClauses && gpAssetUnitsClauses.unitsClauses && gpAssetUnitsClauses.unitsClauses.length > 0 ?
      gpAssetUnitsClauses.unitsClauses.find(value => value.codeUnit === unit.code && value.codeSection === unit.sectionCode) :
      null;
  }

}

import {Injectable} from '@angular/core';
import { ToolStatusOnCache } from '../lic-fund/model/SelectionState';

@Injectable({
  providedIn: 'root'
})
export class LicCacheService {

  private sessions: Map<string, any> = new Map<string, any>();
  private _toolStatusByUserInteraction: Map<string, ToolStatusOnCache> = new Map<string, ToolStatusOnCache>();

  constructor() { }

  public registerSession(id: string): any {
    if (!this.sessions.get(id)) {
      this.sessions.set(id, {
        investments: {
          investmentProfiles: {},
          tools: {}
        },
        transferData: {}
      });
    }
    return this.getSession(id);
  }

  public deleteSession(id: string): boolean {
    this._toolStatusByUserInteraction.clear();
    return this.sessions.delete(id);
  }

  public getSession(id: string): any {
    return this.sessions.get(id);
  }

  public getInvestmentToolsOf(id: string): any {
    const session = this.getSession(id);
    if (!!session) {
      return session.investments.tools;
    }
    return null;
  }

  public setToolStatusByUserInteraction(toolId: string, status: ToolStatusOnCache) {
    this._toolStatusByUserInteraction.set(toolId, status);
  }

  /**
   * Retrieves the tool status by user interaction from the cache.
   * @param toolId - The ID of the tool.
   * @returns The tool status if found, otherwise null.
   * possible values: CHECKED, REMOVED
   */
  getToolStatusByUserInteraction(toolId: string): ToolStatusOnCache {
    const status = this._toolStatusByUserInteraction.get(toolId);
    return !!status ? status : null;
  }
}

<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    <div class="rgi-ui-card-title" translate>
      _GP_._BTN_._COMMISSIONS_
    </div>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <div *ngIf="stateGuarantees.errorsCommissions && stateGuarantees.errorsCommissions.length" data-qa="errorMessage">
      <rgi-gp-group-policy-errors-component
        [errors]="stateGuarantees.errorsCommissions"
        [type]="stateGuarantees.typeCommissions">
      </rgi-gp-group-policy-errors-component>
    </div>
    <button
      class="rgi-ui-btn rgi-ui-btn-primary uppercase"
      (click)="saveCommissions()"
      [disabled]="commissionsForm.invalid" translate>{{'_GP_._BTN_._SAVE_'}}
    </button>
  </rgi-rx-panel-footer>

  <form [formGroup]="commissionsForm">
    <div class="rgi-ui-text-4 rgi-ui-info" *ngFor="let risk of commissions; index as i">
      <h3>{{risk.riskDescr}}</h3>
      <div style="display: flex; flex-direction: row;"
           *ngFor="let key of commissionsKeys[i].keys; index as j; let even = even">
        <ng-container *ngIf="even">
          <div style="flex: 40%;">
            <ng-container
              *ngTemplateOutlet="risksTemplate; context: {risk:risk, key:commissionsKeys[i].keys[j], group: commissionsForm}">
            </ng-container>
          </div>
          <div style="margin-left: 10px; flex: 40%;">
            <ng-container *ngIf="commissionsKeys[i].keys[j + 1]">
              <ng-container
                *ngTemplateOutlet="risksTemplate; context: {risk:risk, key:commissionsKeys[i].keys[j + 1], group: commissionsForm}">
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </form>

</rgi-rx-panel>

<ng-template #risksTemplate let-risk="risk" let-key="key" let-group="group">
  <form [formGroup]="group">
    <div class="rgi-ui-form-group">
      <label class="rgi-ui-form-control-label">
        <span translate>{{risk[getKey(key)].label}}</span>
      </label>
      <input
        [attr.data-qa]="key"
        formControlName="{{key}}"
        class="rgi-ui-form-control"
        type="number"
      />
    </div>
  </form>
</ng-template>

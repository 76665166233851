<div *ngIf="state">
  <div class="rgi-anag-flex-center">
    <div class="rgi-anag-circle"><span class="rgi-anag-icon-xl rgi-ui-icon-subject_data"></span></div>
    <div class="rgi-anag-flex-gr-1 anag-ml-m">
      <div class="rgi-ui-text-1 rgi-ui-info">
        <b>
          <ng-container *ngIf="stateMgr.isPartyPhysical; else legalPartyDenomination">
            <span translate>_ANAG_._LABEL_._NOMINATIVE_</span>
            <span>
              {{ state.party.surname ? state.party.surname : '_ANAG_._LABEL_._SURNAME_' | translate}}
              {{ state.party.birthName}}
              {{ state.party.name ? state.party.name : '_ANAG_._LABEL_._NAME_' | translate}}&nbsp;
            </span>
          </ng-container>
          <ng-template #legalPartyDenomination>
            {{ state.party.denomination ? state.party.denomination : '_ANAG_._LABEL_._COMPANY_NAME_' | translate}}&nbsp;
          </ng-template>
          -
        </b>
        {{ state.party.subjectType ? state.party.subjectType.descrizione : '' }}
      </div>
      <br>
      <div class="anag-ml-m rgi-ui-text-3">
        <div class="rgi-anag-w-100 rgi-anag-vm-s" *ngIf="state.party.language">
          <span translate>_ANAG_._LABEL_._LANGUAGE_</span>
          <b class="anag-ml-m" data-qa='language'>{{state?.party?.language?.descrizione}}</b>
        </div>
        <div class="rgi-anag-w-100 rgi-anag-vm-s" *ngIf="subjectUniqueKeyIsVisible">
          <span translate>_ANAG_._LABEL_._SUBJECT_UNIQUE_KEY_</span>
          <b class="anag-ml-m" data-qa='partyKey'>{{statelessService.getUniqueKeysValue(state.party)}}</b>
        </div>
        <div class="rgi-anag-flex-wrap">
          <div class="rgi-anag-w-50 rgi-anag-vm-s" *ngFor="let idData of identificationDataLabels">
            <span>{{idData.label | translate}}</span>
            <b class="anag-ml-m" [attr.data-qa]='idData.dataQa'>{{ idData.value }}</b>
          </div>
        </div>
        <div class="rgi-anag-w-100 rgi-anag-vm-s" *ngIf="state.party.leicode">
          <span translate>_ANAG_._LABEL_._LEI_CODE_</span>
          <b class="anag-ml-m" data-qa='partyKey'>{{state?.party?.leicode}}</b>
        </div>
      </div>
      <div class="anag-ml-m" *ngIf="state.editorModeEnable">
        <div class="rgi-anag-tip"></div>
        <div class="rgi-anag-bordered-rectangle">
          <span [class]="getIdentificationDataBadge()"></span>
          <span class="rgi-ui-text-4" translate>_ANAG_._LABEL_._FILL_ID_DATA_</span>
          <button class="rgi-ui-btn rgi-ui-btn-rounded" (click)="editIdentificationData()"
            *ngIf="state.editorModeEnable" [disabled]="state.blockedFlow" data-qa="edit-identification-data">
            <span class="rgi-ui-icon-edit rgi-anag-color-tone"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="rgi-anag-sections-container">
    <div class="anag-w-100 anag-section-card">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-icon-badge"></span>
        <span data-qa="key-data-badge" [class]="getSectionIconClass('keydata')"></span>
        <span class="anag-section-title rgi-ui-text-3" translate>_ANAG_._LABEL_._SUBJECT_DATA_</span>
        <button class="anag-section-edit-button" (click)="editKeyData()" data-qa="edit-key-data"
          *ngIf="state.editorModeEnable && !state.isUmaPartyHomonymyEnable" [disabled]="state.blockedFlow">
          <span class="rgi-ui-icon-edit2 anag-edit-icon"></span>
        </button>
      </div>
      <div class="anag-section-content">
        <div class="rgi-anag-w-100 rgi-ui-text-3 anag-section-label" *ngFor="let keyData of keyDataLabels">
          <div class="rgi-anag-flex">
            <span>{{keyData.label | translate}}</span>
            <b class="anag-ml-m" [attr.data-qa]='keyData.dataQa'>{{ keyData.value }}</b>
          </div>
          <hr class="anag-section-separator">
        </div>
      </div>
    </div>
    <div class="anag-w-100 anag-section-card">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-icon-placeholder"></span>
        <span data-qa="residence-badge" class="rgi-anag-badge" [ngClass]="{
          'rgi-ui-icon-confirmation rgi-anag-ok-badge': this.residenceAddressIsValid,
          'rgi-ui-icon-warning rgi-anag-ko-badge': !this.residenceAddressIsValid}"></span>
        <span class="anag-section-title rgi-ui-text-3">{{residenceOrOffice | translate}}</span>
        <button class="anag-section-edit-button" data-qa="edit-ubication" (click)="editUbication()"
          *ngIf="state.editorModeEnable && state.editResidenceEnable" [disabled]="state.blockedFlow">
          <span class="rgi-ui-icon-edit2 anag-edit-icon"></span>
        </button>
      </div>
      <div class="anag-section-content">
        <div class="rgi-ui-snackbar rgi-ui-default" *ngIf="!this.state.party.residence || isResidenceEmpty">
          <span class="rgi-ui-message" data-qa="no-addr-msg" translate>_ANAG_._LABEL_._NO_ADDRESS_MSG_</span>
        </div>
        <div class="rgi-anag-w-100 rgi-ui-text-3 anag-section-label" *ngFor="let residenceLablels of residenceLabels">
          <div class="rgi-anag-flex">
            <span>{{residenceLablels.label | translate}}</span>
            <b class="anag-ml-m" [attr.data-qa]='residenceLablels.dataQa'>{{ residenceLablels.value }}</b>
          </div>
          <hr class="anag-section-separator">
        </div>
      </div>
    </div>
    <div class="anag-w-100 anag-section-card" style="width: auto;">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-icon-privacy"></span>
        <span data-qa="privacy-badge" *ngIf="showPrivacyBadge" class="rgi-anag-badge" [ngClass]="{
          'rgi-ui-icon-confirmation rgi-anag-ok-badge': this.stateMgr.isPrivacyConsensusValid(),
          'rgi-ui-icon-warning rgi-anag-ko-badge': !this.stateMgr.isPrivacyConsensusValid()}"></span>
        <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._PRIVACY_' | translate}}</span>
        <button class="anag-section-edit-button" data-qa="edit-privacy" (click)="editPrivacy()"
          *ngIf="state.editorModeEnable" [disabled]="state.blockedFlow">
          <span class="rgi-ui-icon-edit2 anag-edit-icon"></span>
        </button>
      </div>
      <div class="anag-section-content">
        <div *ngFor="let labels of privacyLabels; let i = index">
          <div class="rgi-ui-grid-2-sm-1">
            <div class="rgi-ui-col-6-sm-12 rgi-ui-text-5" *ngFor="let label of labels">
              <span>{{label.label}}</span>
              <b class="anag-ml-m" [attr.data-qa]='label.dataQa'>{{ label.value }}</b>
            </div>
          </div>
          <hr *ngIf="i < privacyLabels.length - 1" class="anag-section-separator" />
        </div>
        <hr class="anag-section-separator" />
        <div class="rgi-ui-text-5">
        <span>{{'_ANAG_._LABEL_._ROBINSON_LIST_' | translate}}</span>
        <b class="anag-ml-m">{{this.state.party.robinsonList!==null && this.state.party.robinsonList!== undefined ? this.state.party.robinsonList === true ? this.positiveResp : this.negativeResp : this.noResp}} </b>
        </div>
        </div>
    </div>
    <div class="anag-w-100 anag-section-card">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-icon-contacts"></span>
        <span data-qa="contacts-badge" *ngIf="showContactsBadge" class="rgi-anag-badge" [ngClass]="{
          'rgi-ui-icon-confirmation rgi-anag-ok-badge': this.stateMgr.isContactsIsValid(),
          'rgi-ui-icon-warning rgi-anag-ko-badge': !this.stateMgr.isContactsIsValid()}"></span>
        <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._CONTACTS_' | translate}}</span>
        <button class="anag-section-edit-button" data-qa="edit-contacts" (click)="editContacts()"
          *ngIf="state.editorModeEnable" [disabled]="state.blockedFlow">
          <span class="rgi-ui-icon-edit2 anag-edit-icon"></span>
        </button>
      </div>
      <div class="anag-section-content">
        <div class="rgi-anag-w-100 rgi-ui-text-3 anag-section-label" *ngFor="let contact of contactsLabels">
          <div class="rgi-anag-flex">
            <span>{{contact.label | translate}}</span>
            <b class="anag-ml-m" [attr.data-qa]='contact.dataQa'>{{ contact.value }}</b>
          </div>
          <hr class="anag-section-separator">
        </div>
      </div>
    </div>
    <div>
    <div class="anag-w-100 anag-section-card" *ngIf="stateMgr.isPartyPhysical">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-icon-passport"></span>
        <span data-qa="document-badge" class="rgi-anag-badge" [ngClass]="{
          'rgi-ui-icon-confirmation rgi-anag-ok-badge': this.stateMgr.isDocumentValid(),
          'rgi-ui-icon-warning rgi-anag-ko-badge': !this.stateMgr.isDocumentValid()}"></span>
        <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._DOCUMENT_' | translate}}</span>
      </div>
      <div class="anag-section-content">
      <div class="anag-section-content" *ngIf="state.party.documents; else elseBlock">
      <div>
        <div rgi-rx-accordion>
          <rgi-rx-expansion-panel color="default" [expanded]="false" *ngFor="let doc of state.party.documents; let i = index" [attr.data-qa]="'anag-document_'+i">
            <rgi-rx-expansion-panel-header>
              {{doc.documentType?.descrizione | uppercase}}
            </rgi-rx-expansion-panel-header>

          <div class="rgi-ui-grid-2" *ngFor="let docLabel of documentLabels(doc)">
            <div class="rgi-ui-col">

              <div>{{docLabel.label | translate}}:</div>
            </div>
            <div class="rgi-ui-col" [attr.data-qa]='docLabel.dataQa'>
              <b translate>{{docLabel.value}}</b>
            </div>

          </div>
            <div *ngIf="doc?.drivingLicense && doc?.drivingLicense.length > 0">
              <div rgi-rx-accordion>
                <rgi-rx-expansion-panel color="default" *ngFor="let docDriving of doc?.drivingLicense; let i = index" [attr.data-qa]="'anag-document-driving-license_'+i">
                  <rgi-rx-expansion-panel-header>
                    {{ docDriving.descr }}{{ ' ' }}{{ docDriving.dendDrivingLicense | date:'dd/MM/yyyy'}}
                  </rgi-rx-expansion-panel-header>
                  <div class="rgi-ui-grid-2">
                    <div class="rgi-ui-col">
                      <div>{{'_ANAG_._LABEL_._RELEASE_DATE_' | translate}}:</div>
                    </div>
                    <div class="rgi-ui-col">
                      <b translate>{{docDriving.dstartDrivingLicense ? (docDriving.dstartDrivingLicense | date:'dd/MM/yyyy') : '-'}}</b>
                    </div>
                    <div class="rgi-ui-col">
                      <div>{{'_ANAG_._LABEL_._DRIVER_QUALIFICATION_CARD_' | translate}}:</div>
                    </div>
                    <div class="rgi-ui-col">
                      <b translate>{{docDriving.bdriverQualifCard ? '_ANAG_._LABEL_._YES_': '_ANAG_._LABEL_._NO_' }}</b>
                    </div>
                  </div>
                  </rgi-rx-expansion-panel>
              </div>
            </div>
            <div class="rgi-ui-col rgi-ui-grid-2" *ngIf="state.editorModeEnable">
              <button type="button" (click)="editDocument(doc,i)"
                      class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-edit2 rgi-anag-text-tone"></button>
              <button type="button" (click)="deleteDocument(doc,i)"
                      class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-delete rgi-anag-text-tone"></button>
            </div>
          </rgi-rx-expansion-panel>
        </div>
      </div>
      </div>
        <div class="rgi-ui-grid-1" *ngIf="state.editorModeEnable">
        <div class="rgi-ui-col">
          <div class="rgi-ui-panel-footer">
            <button type="button" class="rgi-ui-btn rgi-ui-btn-outline rgi-anag-text-tone" (click)="addDocument()" style="width: 95%"
                    translate>_ANAG_._BTN_._ADD_ </button>
          </div>
        </div>
      </div>
      </div>

      <ng-template #elseBlock>
        <div class="anag-section-content rgi-ui-snackbar-container">
          <div class="rgi-ui-snackbar-header rgi-ui-default">
            <span translate>_ANAG_._MSG_._NO_ELEMENT_TO_SHOW_</span>
          </div>
        </div>
      </ng-template >
    </div>
      </div>
    <div>
      <rgi-anag-party-link [party]="state.party" [edit]="state.editorModeEnable" (linkUpd)="updateLinks($event)"
        *ngIf="stateMgr.isPartyPhysical"></rgi-anag-party-link>
      <rgi-anag-ati [party]="state.party" (linkUpd)="updateLinks($event)"
        *ngIf="!stateMgr.isPartyPhysical"></rgi-anag-ati>
    </div>
    <div>
      <rgi-anag-payment-section *ngIf="state.party" [inputParty]="state.party" [stateMgrProvider]="stateMgrProvider">
      </rgi-anag-payment-section>
    </div>
    <div>
      <rgi-anag-address *ngIf="state.party" [otherAddressParty]="state.party.otherAddress">
      </rgi-anag-address>
    </div>
    <div class="anag-w-100 anag-section-card" *ngFor="let tableDynamicObjectsData of tablesDynamicObjectsData">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-icon-hands"></span>
        <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._COWORKERS_' | translate}}</span>
        <button class="anag-section-edit-button" data-qa="edit-dynamic-objects"
          (click)="editDynamicObject(tableDynamicObjectsData.dynamicObj)" *ngIf="state.editorModeEnable"
          [disabled]="state.blockedFlow">
          <span class="rgi-ui-icon-edit2 anag-edit-icon"></span>
        </button>
      </div>
      <div class="anag-section-content">
        <div class="rgi-ui-snackbar rgi-ui-default" *ngIf="tableDynamicObjectsData.noElementToShow">
          <span class="rgi-ui-message" translate>_ANAG_._MSG_._NO_ELEMENT_TO_SHOW_</span>
        </div>
        <rgi-rx-datatable [data]="tableDynamicObjectsData.dataSourceTable" *ngIf="!tableDynamicObjectsData.noElementToShow && !isIstanceOfLimitedDynamicObj" [schema]="PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA">
        </rgi-rx-datatable>
        <rgi-rx-datatable [data]="tableDynamicObjectsData.dataSourceTable" *ngIf="!tableDynamicObjectsData.noElementToShow && isIstanceOfLimitedDynamicObj" [schema]="PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA_LIM">
        </rgi-rx-datatable>
      </div>
    </div>
    <div class="anag-w-100 anag-section-card" *ngIf="!state.editorModeEnable">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-icon-nodes"></span>
        <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._NODES_' | translate}}</span>
      </div>
      <div class="anag-section-content">
        <b class="rgi-anag-w-100 rgi-ui-text-3 anag-section-label">
          {{'_ANAG_._LABEL_._PRODUCTION_NODES_' | translate}}
        </b>

        <div class="rgi-anag-w-100 rgi-ui-text-3 anag-section-label" *ngFor="let node of nodesLabels">
          <div class="rgi-anag-flex" style="margin-top: 20px">
            <span>-&nbsp;{{node.description}}<span>&nbsp;({{node.code}})</span></span>
          </div>
          <hr class="anag-section-separator">
        </div>
      </div>
    </div>
    <div class="anag-w-100 anag-section-card" *ngIf="showAntiTerrorism">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon rgi-ui-rgi-binoculars"> </span>
        <span class="rgifont rgi-binoculars header-icon"></span>
        <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._ANTI_TERRORISM_' | translate}} </span>
        <button class="anag-section-edit-button" data-qa="editTerrorism"
          *ngIf="stateMgr.getCurrentState().editorModeEnable" [disabled]="stateMgr.getCurrentState().blockedFlow"
          (click)="openModalTerrorism()">
          <span class="rgi-ui-icon-edit2 anag-edit-icon"> </span>
        </button>
      </div>
      <div class="anag-section-content">
        <div class="rgi-anag-w-100 rgi-ui-text-3">
          <span>{{'_ANAG_._LABEL_._COUNTERTERRORISM_STATUS_' | translate}} :</span>
          <b class="anag-ml-m" [ngStyle]="getStyleTerrorism()">
            {{state?.party?.counterTerrorismStatus ? state?.party?.counterTerrorismStatus?.descrizione : '-'}}
          </b>
          <hr class="anag-section-separator">
          <div>
            <span class="anag-m">{{'_ANAG_._LABEL_._COUNTERTERRORISM_INDICATOR_CHANGE_DATE_' | translate}}:</span>
            <b class="anag-ml-m">{{state.party.counterTerrorismDate ? (state.party.counterTerrorismDate | date:'dd/MM/yyyy') : '-'}}</b>
          </div>
        </div>

      </div>
    </div>
    <div class="anag-w-100 anag-section-card">
      <div class="anag-section-card-header">
        <span class="anag-section-card-icon  rgi-ui-icon-settings"> </span>
        <span class="rgifont rgi-settings header-icon"></span>
        <span class="anag-section-title rgi-ui-text-3" translate
          data-qa="externalIdenty">{{'_ANAG_._LABEL_._EXTERNALS_IDENTIFIERS_'}} </span>
      </div>
      <div *ngIf="state.party.subsysReferences && state.party.subsysReferences.length > 0 else emptyReference">
        <div class="anag-section-content" *ngFor="let subsysReference of state.party.subsysReferences; let i = index">
          <div class="rgi-anag-w-100 rgi-ui-text-3">
            <span translate>{{'_ANAG_._LABEL_._SUBSYSTEM_' | translate}}:</span>
            <b class="anag-ml-m" *ngIf="subsysReference"> {{subsysReference.chiave | translate}} </b>
            <div translate>
              <span class="anag-ml-m">{{'_ANAG_._LABEL_._IDENTIFIERS_SUBJECT_SUBSYSTEM_' | translate }}:</span>
              <b class="anag-ml-m" *ngIf="subsysReference">{{subsysReference.valore | translate}}</b>
            </div>
          </div>
          <hr *ngIf="i < state.party.subsysReferences.length - 1" class="anag-section-separator">
        </div>
      </div>
      <ng-template #emptyReference>
        <div class="anag-section-content">
          <div class="rgi-anag-w-100 rgi-ui-text-3">
            <span translate>{{'_ANAG_._LABEL_._SUBSYSTEM_' | translate}}:</span>
            <b class="anag-ml-m"></b>
            <hr class="anag-section-separator">
            <div translate>
              <span class="anag-ml-m">{{'_ANAG_._LABEL_._IDENTIFIERS_SUBJECT_SUBSYSTEM_' | translate }}:</span>
              <b class="anag-ml-m"></b>
            </div>
          </div>
        </div>
      </ng-template>
  </div>

    <div>
      <rgi-anag-tax-classification-section *ngIf="state.party && showTaxClassifications"
                                           [taxClassifications]="state.party.taxClassifications">
      </rgi-anag-tax-classification-section>
    </div>

    <div>
      <rgi-anag-rating-section *ngIf="state.party && showRatings" [inputParty]="state.party" [stateMgrProvider]="stateMgrProvider">
      </rgi-anag-rating-section>
    </div>

  </div>
  <div class="rgi-ui-snackbar rgi-ui-danger" *ngFor="let err of state?.errors" data-qa="rgi-anag-httpError">
    <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
    <span class="rgi-ui-message">{{err}}</span>
  </div>
  <ng-container *ngIf="state">
    
    <div class="rgi-ui-panel-footer rgi-anag-tm-1" *ngIf="state.editorModeEnable">
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionSaveParty()"
        [disabled]="!stateMgr.canSaveParty() || !birthAddressIsValid || !residenceAddressIsValid" data-qa="anag-btn-save" translate>_ANAG_._BTN_._SAVE_
      </button>
    </div>
    <div class="rgi-ui-panel-footer rgi-anag-tm-1" *ngIf="!state.editorModeEnable">
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionBack()" data-qa="anag-btn-back"
        translate>_ANAG_._BTN_._BACK_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionEdit()" *ngIf="isEditButtonVisible()"
        [disabled]="state.party.limitation" data-qa="anag-btn-edit" translate>_ANAG_._BTN_._EDIT_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionDelete()"
        *ngIf="isDeleteButtonVisible()" [disabled]="state.party.limitation" data-qa="anag-btn-delete"
        translate>_ANAG_._BTN_._DELETE_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionDeleteDefinitively()"
        *ngIf="isDeleteDefinitivelyButtonVisible()" [disabled]="state.party.limitation" data-qa="anag-btn-full-delete"
        translate>_ANAG_._BTN_._DELETE_DEFINITIVELY_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionHistory()" data-qa="anag-btn-history"
        translate>_ANAG_._BTN_._HISTORY_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionLimitationManagement()" *ngIf="isLimitationManagementVisible"
        data-qa="anag-btn-limitation" translate>_ANAG_._BTN_._LIMITATION_MANAGEMENT_
      </button>
    </div>
  </ng-container>
</div>

<ng-container [formGroup]="fieldsFormGroup">
  <div class="rgi-ui-grid-2-sm-1">
    <ng-container *ngFor="let field of formFields; trackBy: fieldsTrackBy">
      <div class="rgi-ui-col-6-sm-12" [class.rgipsales-flex]="field.type === FIELD_TYPE.CHECKBOX">
        <rgi-rx-form-field *ngIf="field.type !== FIELD_TYPE.DROPCONTAINER;"
                           [class.rgipsales-flex-gr-1]="field.type === FIELD_TYPE.CHECKBOX">

          <label [attr.rgiRxLabel]="field.type !== FIELD_TYPE.BOOLEAN"
                 [class.rgipsales-flex-gr-1]="field.type === FIELD_TYPE.CHECKBOX">
            <span>{{field?.label?.includes('_PCPSALES_') || field?.label?.includes('_RGIPNC_') ?
                (field?.label | translate) : (field?.label)}}</span>
            <span *ngIf="field.mandatory" class="rgi-ui-required rgi-ui-text-7 rgi-ui-danger">*</span>
          </label>

          <ng-container [ngSwitch]="field.type">
            <ng-container *ngSwitchCase="FIELD_TYPE.NUMERIC">
              <input type="number" rgiRxInput formControlName="{{field.code}}" [attr.data-qa]="field.code">
            </ng-container>

            <ng-container *ngSwitchCase="FIELD_TYPE.LIST">
              <select rgiRxNativeSelect formControlName="{{field.code}}" [attr.data-qa]="field.code">
                <option value="" [disabled]="field.mandatory"></option>
                <option *ngFor="let value of field.allowedValues; trackBy: allowedValuesTrackBy"
                        [value]="value.code" [selected]="value.code === field.value">{{value.description}}</option>
              </select>
            </ng-container>

            <ng-container *ngSwitchCase="FIELD_TYPE.BOOLEAN">
              <rgi-rx-button-toggle formControlName="{{field.code}}">
                <button rgi-rx-button rgiRxValueOption value="false" translate [attr.data-qa]="field.code+'_NO'">
                  _PSALES_._BTN_._NO_
                </button>
                <button rgi-rx-button rgiRxValueOption value="true" translate [attr.data-qa]="field.code+'_YES'">
                  _PSALES_._BTN_._YES_
                </button>
              </rgi-rx-button-toggle>
            </ng-container>

            <ng-container *ngSwitchCase="FIELD_TYPE.STRING">
              <input rgiRxInput formControlName="{{field.code}}" [attr.data-qa]="field.code">
            </ng-container>

            <ng-container *ngSwitchCase="FIELD_TYPE.DATE">
              <rgi-rx-date-picker [openOnClick]="false">
                <input [rgiRxDateTimeInput]="picker1"
                       [min]="getMinDate(field)"
                       [max]="getMaxDate(field)"
                       formControlName="{{field.code}}"
                       [attr.data-qa]="field.code">
              </rgi-rx-date-picker>
              <rgi-rx-date-time #picker1></rgi-rx-date-time>
            </ng-container>

            <ng-container *ngSwitchCase="FIELD_TYPE.DATETIME">
              <rgi-rx-date-picker [openOnClick]="false">
                <input [rgiRxDateTimeInput]="picker1"
                       [min]="getMinDate(field)"
                       [max]="getMinDate(field)"
                       formControlName="{{field.code}}"
                       [attr.data-qa]="field.code">
              </rgi-rx-date-picker>
              <rgi-rx-date-time #picker1 [pickerType]="'both'"></rgi-rx-date-time>
            </ng-container>

            <ng-container *ngSwitchCase="FIELD_TYPE.CHECKBOX">
              <input type="checkbox" rgiRxInput formControlName="{{field.code}}" [attr.data-qa]="field.code">
            </ng-container>

            <ng-container *ngSwitchCase="FIELD_TYPE.MULTISELECT">
              <rgi-rx-multiselect formControlName="{{field.code}}">
                <rgi-rx-option *ngFor="let option of field.allowedValues" [value]="option.code" [label]="option.description">
                  {{ option.description }}
                </rgi-rx-option>
              </rgi-rx-multiselect>
            </ng-container>

          </ng-container>

        </rgi-rx-form-field>
        <div *ngIf="field.type === FIELD_TYPE.DROPCONTAINER">
          <rgi-rx-drop-container formControlName="{{field.code}}"
                                 [label]="field.dropContainerLabel | translate"
                                 viewField="{{field.dropContainerViewField}}"
                                 select="{{field.dropContainerAction}}"
                                 [selectData]="field.dropContainerInputData">
          </rgi-rx-drop-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>


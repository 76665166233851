import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../../services/currency-cache.service';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Component({
  selector: 'lpc-annuity-table',
  templateUrl: './annuity-table.component.html',
  styleUrls: ['./annuity-table.component.scss'],
})
export class AnnuityTableComponent implements OnInit {

  constructor(
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,

  ) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  @Input() definitions: any;
  @Input() label: string;

  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  ngOnInit(): void {
  }
  
}

import { Risk } from './risk';
import { DamageSubject } from './subject';
import { DamageLocation } from './damage-location';
import { Activity } from './activity';
import { Vehicle } from './vehicle';
import { Clause } from './clause';
import { DamageFactor } from './damage-factor';

export class Unit {

  public code: string;
  public description: string;
  public risks: Risk[];
  public subjects: DamageSubject[];
  public archiveSubjects: DamageSubject[];
  public locations: DamageLocation[];
  public activities: Activity[];
  public vehicles: Vehicle[];
  public factors: DamageFactor[];
  public clauses: Clause[];

  constructor() { }
}

<div class="rgi-ui-snackbar-container">

  <ng-container *ngFor="let error of areaErrors" [ngSwitch]="error.level">
    <ng-container *ngSwitchCase="null">
      <div class="rgi-ui-snackbar-header rgi-ui-danger">
        <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
        <span class="rgi-ui-message">{{error.message}}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="ERROR_CODE.WARNING">
      <div class="rgi-ui-snackbar-header rgi-ui-warning">
        <span class="rgi-ui-icon-snackbar rgi-ui-icon-warning"></span>
        <span class="rgi-ui-message">{{error.message}}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="ERROR_CODE.INFO">
      <div class="rgi-ui-snackbar-header rgi-ui-info">
        <span class="rgi-ui-icon-snackbar rgi-ui-icon-info"></span>
        <span class="rgi-ui-message">{{error.message}}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="rgi-ui-snackbar-header rgi-ui-danger">
        <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
        <span class="rgi-ui-message">{{error.message}}</span>
      </div>
    </ng-container>
  </ng-container>

</div>

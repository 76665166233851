import {Component, ComponentRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExpectedDocument} from './expected-document';
import {Modal} from '../../modal';
import {ProposalService} from '../../proposal.service';

@Component({
  selector: 'mic-expected-documents-modal',
  templateUrl: './expected-documents-modal.component.html',
  styleUrls: ['expected-documents-modal.component.scss']
})
export class ExpectedDocumentsModalComponent implements Modal, OnInit {

  @Input()
  expectedDocuments: Array<ExpectedDocument>;
  @Input()
  messages: Array<string>;
  @Input()
  showStatus = false;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  componentRef: ComponentRef<any>;

  constructor(public proposalService: ProposalService) {
  }

  ngOnInit() {
    if (this.expectedDocuments) {
      this.expectedDocuments.sort(
        (a: ExpectedDocument, b: ExpectedDocument) => {
          if (a.priority > b.priority) {
            return 1;
          } else if (a.priority < b.priority) {
            return -1;
          } else {
            return 0;
          }
        }
      );

      for (let i = 0; i < this.expectedDocuments.length; i++) {
        this.expectedDocuments[i].name = String(i + 1) + '. ' + this.expectedDocuments[i].code
          + ' - ' + this.expectedDocuments[i].description;
      }
    }
  }

  close() {
    this.componentRef.destroy();
  }
}

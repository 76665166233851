import {Component, Input, OnInit} from '@angular/core';
import {TableSchema} from '@rgi/rx/ui';
import {state} from '@angular/animations';

@Component({
  selector: 'rgi-pnc-psales-tech-accounting-data-table',
  templateUrl: './tech-accounting-data-table.component.html'
})
export class TechAccountingDataTableComponent implements OnInit {
  constructor() { }
  @Input() schema: TableSchema;
  @Input() data: any[];

  protected readonly state = state;
  ngOnInit() {
  }
}

<div data-qa="life-option-detail" *ngIf="!!lifeOptionVisible">
  <div class="row" id="funds-row">
    <!-- PRIMA COLONNA COL-8 -->
    <div class="col-md-8">
      <!-- INIZIO DATI MOVIMENTO DINAMICO -->
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                    (click)="back()" data-qa="back-arrow"></span>
              <span class="separetor"> | </span>
              <span class="menu-title" translate>{{ data.optionDetail.toolName }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-12 life-detail-first-info">
          <div class="col-md-5 life-detail-img">
            <div class="hidden-xs hidden-sm anag-user-ico">
              <span class="rgifont life-detail-icon-padding rgi-folder"></span>
            </div>
          </div>
          <div class="col-md-7 life-detail-general-info">
            <div class="container-fluid anag-data" [ngSwitch]="data.optionDetail.toolId">
              <ng-container *ngSwitchCase="TOOL_CODES.SCHEDULED_PREMIUM">
                <div class="col-md-12">
                  <span data-qa="frequency-title" class="tbld_col_title" translate>lpc_frequecy</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="frequency" class="tbld_col_value">{{data.optionDetail.toolDetail.frequency}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="payment-amount-title" class="tbld_col_title" translate>lpc_payment_amount</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="payment-amount"
                        class="tbld_col_value">{{getSymbol(data.optionDetail.toolDetail.currency)}} {{data.optionDetail.toolDetail.amount | rgiCountryLayerNumberFormat}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="next-processing-date-title" class="tbld_col_title"
                        translate>lpc_elaboration_date</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="next-processing-date"
                        class="tbld_col_value">{{data.optionDetail.toolDetail.date | date: 'dd/MM/yyyy'}}</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="TOOL_CODES.SCHEDULED_PARTIAL_WITHDRAWAL">
                <div class="col-md-12">
                  <span data-qa="frequency-title" class="tbld_col_title" translate>lpc_frequecy</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="frequency" class="tbld_col_value">{{data.optionDetail.toolDetail.frequency}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="surrender-distribution-value" class="tbld_col_title" translate>lpc_TotalSurrender</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="surrender-distribution"
                        class="tbld_col_value">{{getSymbol(data.optionDetail.toolDetail.currency)}} {{data.optionDetail.toolDetail.amount | rgiCountryLayerNumberFormat}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="next-processing-date-title" class="tbld_col_title"
                        translate>lpc_elaboration_date</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="next-processing-date"
                        class="tbld_col_value">{{data.optionDetail.toolDetail.date | date: 'dd/MM/yyyy'}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="surrender-distribution-value" class="tbld_col_title"
                        translate>lpc_surrender_distribution</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="surrender-distribution"
                        class="tbld_col_value">{{data.optionDetail.toolDetail.distribution}}</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="TOOL_CODES.LOCK_IN">
                <div class="col-md-12">
                  <span data-qa="frequency-title" class="tbld_col_title" translate>lpc_frequecy</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="frequency" class="tbld_col_value">{{data.optionDetail.toolDetail.frequency}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="next-processing-date-title" class="tbld_col_title"
                        translate>lpc_elaboration_date</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="next-processing-date"
                        class="tbld_col_value">{{data.optionDetail.toolDetail.date | date: 'dd/MM/yyyy'}}</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="TOOL_CODES.STOP_LOSS">
                <div class="col-md-12">
                  <span data-qa="frequency-title" class="tbld_col_title" translate>lpc_frequecy</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="frequency" class="tbld_col_value">{{data.optionDetail.toolDetail.frequency}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="next-processing-date-title" class="tbld_col_title"
                        translate>lpc_elaboration_date</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="next-processing-date"
                        class="tbld_col_value">{{data.optionDetail.toolDetail.date | date: 'dd/MM/yyyy'}}</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="TOOL_CODES.PROGRESSIVE_SWITCH">
                <div class="col-md-12" *ngIf="!!data.optionDetail?.toolDetail?.frequency">
                  <span data-qa="frequency-title" class="tbld_col_title" translate>lpc_frequecy</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="frequency" class="tbld_col_value">{{data.optionDetail.toolDetail.frequency}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="next-processing-date-title" class="tbld_col_title"
                        translate>lpc_elaboration_date</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="next-processing-date"
                        class="tbld_col_value">{{data.optionDetail.toolDetail.date | date: 'dd/MM/yyyy'}}</span>
                </div>
                <div class="col-md-12" *ngIf="data.optionDetail.toolDetail.occuranceNumber > 0">
                  <span data-qa="occurrenceNumber-title" class="tbld_col_title" translate>lpc_occurrenceNumber</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="occurrenceNumber" class="tbld_col_value">{{data.optionDetail.toolDetail.occuranceNumber}}</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="TOOL_CODES.PERIODIC_COUPON">
                <div class="col-md-12">
                  <span data-qa="frequency-title" class="tbld_col_title" translate>lpc_frequecy</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="frequency" class="tbld_col_value">{{data.optionDetail.toolDetail.frequency}}</span>
                </div>
                <div class="col-md-12">
                  <span data-qa="next-processing-date-title" class="tbld_col_title" translate>lpc_elaboration_date</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="next-processing-date" class="tbld_col_value">
                    {{data.optionDetail.toolDetail.date | date: 'dd/MM/yyyy'}}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="TOOL_CODES.RISK_REDUCTION">
                <div class="col-md-12">
                  <span data-qa="next-processing-date-title" class="tbld_col_title" translate>lpc_elaboration_date</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="next-processing-date" class="tbld_col_value">
                    {{data.optionDetail.toolDetail.date | date: 'dd/MM/yyyy'}}
                  </span>
                </div>
                <div class="col-md-12">
                  <span data-qa="frequency-title" class="tbld_col_title" translate>lpc_frequecy</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="frequency" class="tbld_col_value">{{data.optionDetail.toolDetail.frequency}}</span>
                </div>
                <div class="col-md-12" *ngIf="data.optionDetail.toolDetail.occuranceNumber > 0">
                  <span data-qa="occurrenceNumber-title" class="tbld_col_title" translate>lpc_occurrenceNumber</span>
                  <span class="tbld_col_title">: </span>
                  <span data-qa="occurrenceNumber" class="tbld_col_value">{{data.optionDetail.toolDetail.occuranceNumber}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" *ngIf="hasToolOptions">
        <div class="col-md-12">
          <lpc-funds-table [toolData]="data.optionDetail.toolData" [toolId]="data.optionDetail.toolId"></lpc-funds-table>
        </div>
      </div>

    </div>
  </div>
</div>

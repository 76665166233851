import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ModalService} from '@rgi/rx/ui';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {of} from 'rxjs';
import {ReIssueBeneficiaryModalComponent} from '../re-issue-beneficiary-modal/re-issue-beneficiary-modal.component';
import {BeneficiaryDTO} from '../../../../models/domain-models/BeneficiaryDTO';
import {BeneficiaryPartiesDTO} from '../../../../models/domain-models/BeneficiaryPartiesDTO';
import {ReIssueMsgData} from '../../../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import {ReIssueMsgModalComponent} from '../../../re-issue-msg-modal/re-issue-msg-modal.component';
import {ReIssueQuotationState} from '../../../re-issue-state-manager/re-issue-quotation.state';
import {AbstractStateManager} from '@rgi/rx/state';
import {
  ReIssueQuotationBusinnessService
} from '../../../re-issue-business-services/re-issue-quotation-businness.service';
import {
  ReIssueQuotationStateManagerService
} from '../../../re-issue-state-manager/re-issue-quotation-state-manager.service';

@Component({
  selector: 're-issue-unit-beneficiaries',
  templateUrl: './re-issue-unit-beneficiaries.component.html',
  styleUrls: ['./re-issue-unit-beneficiaries.component.css'],
})
export class UnitBeneficiariesComponent implements OnInit, OnChanges {
  @Input() listBeneficiariesUnit;
  @Input() isBeneficiaryUnitVisibility;
  @Input() asset;
  @Input() unitCode;
  beneficiaryParties = [];
  ifError = false;
  istrad = false;
  anagBenef: string;
  spouseInsured: string;
  warningMessagge: string;
  errorThirdPerson: string;
// tslint:disable-next-line:max-line-length
  errorSubjectMessage: string;
  ngModel: any[];
  beneficiary = new BeneficiaryDTO();
  displayedColumns: string[] = ['party', 'percentage'];
  tableData: BeneficiaryPartiesDTO[];
  listBeneficiariesUnitFiltered: any[];

  constructor(
    protected fb: UntypedFormBuilder,
    public pushMessageHandler: PushMessageHandlerService,
    protected translate: RgiRxTranslationService,
    private translateService: RgiRxTranslationService,
    public stateManager: ReIssueQuotationStateManagerService<ReIssueQuotationState>,
    protected quotationBusinnessService: ReIssueQuotationBusinnessService<ReIssueQuotationState>,
    protected modalService: ModalService,
    protected customModalService: ModalService,
  ) {
  }


  ngOnInit() {
    if (this.unitCode) {
      this.tableData = [];
      this.getBeneficiaries(this.unitCode);
    }
    this.listBeneficiariesUnitFiltered = this.listBeneficiariesUnit.filter(b => b.assetKey === this.asset.asset.key && !b.packetCode);
    if (!this.beneficiary || !this.beneficiary.beneficiaryType) {
      let content = '';
      const msgContent = of(['RE_ISSUE.ERROR_BENEFICIARY_NOT_SELECTED']);
      msgContent.pipe(mergeMap(r => {
        return this.translateService.translateAll(...r);
      })).subscribe(next => {
        content = next[0];
      }).unsubscribe();
      const tag = 'beneficiaryTypeError' + '_' + this.asset.asset.key + '_' + this.unitCode;
      this.pushMessageHandler.clearTag(tag);
      const msg: PushMessage = new PushMessage();
      const opts = {icon: 'rgi-ui-icon-alert'};
      msg.tag = tag;
      msg.status = 'danger';
      msg.content = content;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
      console.log(this.pushMessageHandler);
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  openBeneficiaryModal(unitBeneficiariesConf, beneficiary) {
    const state = this.stateManager.getCurrentState();
    const {modal, component} = this.modalService.openComponent(ReIssueBeneficiaryModalComponent);
    component.unitBeneficiariesConf = unitBeneficiariesConf;
    component.thirdPersonContact = state.thirdPersonContact;
    component.beneficiary = beneficiary;
    const msg = of(['RE_ISSUE.BENEFICIARIES']);
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      component.title = next [0] + ' ' + unitBeneficiariesConf.section + ' ' +
        unitBeneficiariesConf.unit + ' (' + this.asset.asset.name + ')';
    }).unsubscribe();
    console.log(this.pushMessageHandler);


    modal.onClose.subscribe(value => {
      if (value) {
        const emmiter = {idLatestPhotosThirdContactPerson: null, beneficiaries: value};
          this.quotationBusinnessService.putBeneficiariesByAssetAndByUnit(
          this.stateManager.getCurrentState().resourceId, this.asset.asset.key, unitBeneficiariesConf.unitCode, emmiter)
          .subscribe(
          val => {
            if (val.outcome === 'OK') {
              const tag = 'beneficiaryErrors' + '_' + this.asset.asset.key + '_' + this.unitCode;
              this.pushMessageHandler.clearTag(tag);
              state.beneficiariesSelected.push(this.asset.asset.key + '_' + unitBeneficiariesConf.unitCode);
              this.beneficiary = value[0];
              if (this.beneficiary.parties.length === 0) {
                const party = new BeneficiaryPartiesDTO();
                party.partyPercentage = 100;
                const type = this.getBeneficiaryDescription(this.beneficiary.beneficiaryType);
                party.nominative = type;
                this.beneficiary.parties.push(party);
              } else {
                state.beneficiaryParties = state.beneficiaryParties.filter(b => {
                  b.split('_')[1] !== this.asset.asset.key && b.split('_')[2] !== unitBeneficiariesConf.unitCode;
                });
                this.beneficiary.parties.forEach(p => {
                  state.beneficiaryParties.push(p.objectId + '_' + this.asset.asset.key + '_' + unitBeneficiariesConf.unitCode);
                });
              }
              this.tableData = this.beneficiary.parties;
              this.pushMessageHandler.clearTag('beneficiaryTypeError' + '_' + this.asset.asset.key + '_' + this.unitCode);
              console.log(this.pushMessageHandler);
              this.stateManager.updateState$(of(state));
            }
          }
        );
      }
    });
  }

  private getBeneficiaries(unitCode: string) {
    this.quotationBusinnessService.getBeneficiariesByAssetAndByUnit(
    this.stateManager.getCurrentState().resourceId, this.asset.asset.key, unitCode)
    .subscribe(
      val => {
        if (val.beneficiaryType) {
          this.beneficiary = val;
          if (this.beneficiary.parties.length === 0) {
            const party = new BeneficiaryPartiesDTO();
            party.partyPercentage = 100;
            const type = this.getBeneficiaryDescription(this.beneficiary.beneficiaryType);
            party.nominative = type;
            this.beneficiary.parties.push(party);
          }
          this.tableData = this.beneficiary.parties;
          const state = this.stateManager.getCurrentState();
          state.beneficiariesSelected.push(this.asset.asset.key + '_' + unitCode);
          this.stateManager.updateState$(of(state));
        }
      }
    );
  }

  private getBeneficiaryDescription(beneficiaryCode: string) {
    const type = this.listBeneficiariesUnitFiltered[0]?.beneficiary.find(b => b.code === beneficiaryCode);
    return type ? type.description : null;
  }

  deleteBeneficiaries(unitBeneficiariesConf: any) {
    const msgData = new ReIssueMsgData();
    const msg = of(['RE_ISSUE.CONFIRM_BENEFICIARIES_DELETE']);
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      msgData.msg = next [0];
    }).unsubscribe();
    msgData.showButtonConfirm = true;
    const cancellationModal = this.customModalService.openComponent(ReIssueMsgModalComponent, msgData);
    cancellationModal.modal.onClose.subscribe(text => {
      if (text) {
        const state = this.stateManager.getCurrentState();
        this.quotationBusinnessService.deleteBeneficiariesByAssetAndByUnit(
          this.stateManager.getCurrentState().resourceId, this.asset.asset.key, unitBeneficiariesConf.unitCode)
          .subscribe(
            vaGet => {
              state.beneficiaryParties = state.beneficiaryParties.filter(b => {
                b.split('_')[1] !== this.asset.asset.key && b.split('_')[2] !== unitBeneficiariesConf.unitCode;
              });
              this.beneficiary = null;
              this.tableData = [];
              state.beneficiariesSelected.forEach(b => {
                if (b.split('_')[0] === this.asset.asset.key && b.split('_')[1] === unitBeneficiariesConf.unitCode) {
                  state.beneficiariesSelected.splice(state.beneficiariesSelected.indexOf(b), 1);
                }
              });
              let content = '';
              const msgContent = of(['RE_ISSUE.ERROR_BENEFICIARY_NOT_SELECTED']);
              msgContent.pipe(mergeMap(r => {
                return this.translateService.translateAll(...r);
              })).subscribe(next => {
                content = next[0];
              }).unsubscribe();
              const tag = 'beneficiaryTypeError' + '_' + this.asset.asset.key + '_' + unitBeneficiariesConf.unitCode;
              this.pushMessageHandler.clearTag(tag);
              const msg: PushMessage = new PushMessage();
              const opts = {icon: 'rgi-ui-icon-alert'};
              msg.tag = tag;
              msg.status = 'danger';
              msg.content = content;
              msg.dismissible = false;
              msg.options = opts;
              this.pushMessageHandler.notify(msg);
              this.stateManager.updateState$(of(state));
            }
          );

      }
    });
  }
}

<rgi-rx-panel class="lg-panel">
  <!-- HEADER -->
  <rgi-rx-panel-header [closeable]="true" (click)="actionClose()"><span translate>RE_ISSUE.SELECT_ASSET</span>
  </rgi-rx-panel-header>
  <!-- TABS -->
  <rgi-rx-tab-group (onTabSelectionChange)="onSelectionChange($event)" [selectedIndex]="">
    <rgi-rx-tab attr.data-qa="{{productAsset.description}} " *ngFor="let productAsset of productAssets; let i = index "
                label="{{productAsset.description}}">
      <ng-template rgiRxTabContent>
      </ng-template>

    </rgi-rx-tab>
    <!--    <rgi-rx-tab label="{{'RE_ISSUE.PERSON' | translate}}">-->
    <!--      <ng-template rgiRxTabContent>-->
    <!--      </ng-template>-->
    <!--    </rgi-rx-tab>-->
  </rgi-rx-tab-group>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary"
            (click)="actionClose()"
            data-qa="close" translate>
      RE_ISSUE.CLOSE
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary"
            (click)="actionConfirm()"
            data-qa="confirm" translate>
      RE_ISSUE.CONFIRM
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

import {Component} from '@angular/core';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {GroupPolicyIntegrationService} from '../../../group-policy-services/group-policy-integration.service';
import {GroupPolicyInquiryComponent} from '../../group-policy-inquiry.component';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import {DataForSteps} from '../../../group-policy-models/group-policy-issue-home';

@Component({
  selector: 'rgi-gp-gpsubstituted-policies-inquiry',
  templateUrl: './gp-substituted-policies-inquiry.component.html'
})
export class GpSubstitutedPoliciesInquiryComponent extends GpInquiryComponentDetail {

  displayedColumns: string[] = ['linkedType', 'policyNumber', 'policyEffectiveDate', 'movementEffectiveDate', 'product', 'action'];
  idParentSession: string;
  constructor(protected stateMngr: GroupPolicyStateManagerInquiry,
              protected integrationService: GroupPolicyIntegrationService,
              protected parent: GroupPolicyInquiryComponent) {
    super(stateMngr);
    this.idParentSession = this.parent.id;
  }

  openPolicyDetail(_row) {
   this.integrationService.goToInquiry({proposalNumber: _row.proposalNumber,idParentSession: this.idParentSession} as DataForSteps);
  }
}

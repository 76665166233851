import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {RgiRxDataTableExpansionModel, RgiRxTableExpansionTrigger} from '@rgi/rx/ui';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  FlexDiscountParam
} from '../../../../models/pc-portfolio-models/discounts-models/flex-discount-param';
import {PcAssetInstance} from '../../../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {AssetInstance, AssetInstanceWithTotal} from '../../../../models/domain-models/asset-instance';
import {AssetSection} from '../../../../models/domain-models/asset-section';
import {Unit} from '../../../../models/domain-models/unit';
import {AssetList} from '../../../../models/domain-models/quotation';
import {WarrantyDiscountSummary} from '../../../../models/domain-models/warranty-discount-summary';
import {ReIssueDiscountModeEnum} from '../../../../enums/re-issue-discount-mode-enum';
import {FlexDiscount} from '../../../../models/pc-portfolio-models/discounts-models/flex-discount';
import {Currency} from '../../../../models/domain-models/parameters/currency';
import {FlexDiscountUnit} from '../../../../models/pc-portfolio-models/discounts-models/flex-discount-unit';
import {FlexDiscountConfig} from '../../../../models/pc-portfolio-models/discounts-models/flex-discount-config';
import {DecimalPipe} from '@angular/common';
import {UnitService} from '../../re-issue-quotation-services/unit.service';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {Instalment} from '../../../../models/domain-models/instalment';
import {of} from 'rxjs';
import {DiscountFund} from '../../../../models/pc-portfolio-models/discounts-models/discount-fund';
import {DISCOUNT_FUND_SCHEMA} from '../../../../re-issue-constants/re-issue-discount-fund-schema';
import {
  FlexDiscountConfigDetails
} from '../../../../models/pc-portfolio-models/discounts-models/flex-discount-config-details';

@Component({
  selector: 'lib-re-issue-discount-modal',
  templateUrl: './re-issue-discount-modal.component.html',
  styleUrls: ['./re-issue-discount-modal.component.css']
})
export class ReIssueDiscountModalComponent implements OnInit, OnDestroy {

  private digitsInfo = '1.2-2';

  @Input() allAssets: Array<PcAssetInstance>;
  @Input() quotationAssets: AssetList;
  @Output() calculateDiscountOnTotal: EventEmitter<FlexDiscountParam> = new EventEmitter<FlexDiscountParam>();
  @Output() calculateDiscountOnSingleUnits: EventEmitter<FlexDiscountUnit[]> = new EventEmitter<FlexDiscountUnit[]>();

  assetInstances: Array<Array<AssetInstance>> = [];
  warranties: WarrantyDiscountSummary[] = [];
  instances: Array<AssetInstanceWithTotal> = [];

  discountForm: UntypedFormGroup;
  modalClose: EventEmitter<FlexDiscountParam> = new EventEmitter();

  displayedColumns = [
    'name', 'annualGross',
    'flexWithErosionMaxPercent', 'flexWithErosionPercent', 'flexWithErosionAmount',
    'flexWithoutErosionMaxPercent', 'flexWithoutErosionPercent', 'flexWithoutErosionAmount'];
  displayedSuperHeader = ['sh1', 'sh2', 'sh3', 'sh4'];
  discountAmountColumns = ['period', 'amount', 'residualAmount'];
  pageOptions = [5, 10, 20];

  customExpansionModel: RgiRxDataTableExpansionModel<any>;

  tableSchema = DISCOUNT_FUND_SCHEMA;

  percentWithErosion = '0.00';
  amountWithErosion = '0.00';
  percentWithoutErosion = '0.00';
  amountWithoutErosion = '0.00';
  totalDiscount = 0;

  flexDiscountConfig: FlexDiscountConfig;
  flexDiscounts: FlexDiscountParam;
  currency: Currency;
  localeId = '';

  TAG_DISCOUNTS_WITH_EROSION_ERROR = 'discounts_with_erosion_error';
  TAG_DISCOUNTS_WITHOUT_EROSION_ERROR = 'discounts_without_erosion_error';

  pushMessages: RgiRxPushMessage[] = [];

  recalculateEnabled = true;
  inputCaseDiscountOnTotalAreValid = [true, true, true, true];

  discountsFund: DiscountFund[] = [];
  discountsAmountShowed = false;
  totalGrossPremium = '0';

  constructor(private formBuilder: UntypedFormBuilder,
              private decimalPipe: DecimalPipe,
              private unitService: UnitService,
              private pushMessageHandlerService: PushMessageHandlerService,
              private translateService: RgiRxTranslationService) {
    this.localeId = this.unitService.getLocaleId();
    this.discountForm = this.formBuilder.group({
      warranties: this.formBuilder.array([])
    });

    this.customExpansionModel = new RgiRxDataTableExpansionModel<any>([], {
      multiple: false,
      canExpand: this.canExpand(),
      canContract: this.canContract()
    });
  }

  ngOnDestroy(): void {
    this.pushMessageHandlerService.clear();
  }

  ngOnInit() {
    this.setFlexDiscountsOnTotal();
    if (!this.flexDiscountConfig) {
      this.flexDiscountConfig = new FlexDiscountConfig();
    }
    this.allAssets.forEach((assetInstance: PcAssetInstance) => {
      const asset = this.quotationAssets.assets.find(assetQuotation => assetInstance.asset.key === assetQuotation.assetId);
      if (asset.instances) {
        this.assetInstances.push(asset.instances);
      }
    });

    this.assetInstances.forEach((assetInstance: Array<AssetInstance>) => {
      assetInstance.forEach((asset: AssetInstance) => {
        const assetInstanceWithTotal: AssetInstanceWithTotal = new AssetInstanceWithTotal();
        assetInstanceWithTotal.assetCode = asset.assetCode;
        assetInstanceWithTotal.sections = asset.sections;
        assetInstanceWithTotal.total = asset.productPremium.annual.gross;
        assetInstanceWithTotal.name = asset.name;
        this.instances.push(assetInstanceWithTotal);

        assetInstanceWithTotal.sections.forEach((section: AssetSection) => {
          if (section.unitList && section.unitList.length > 0) {
            section.unitList.forEach((unit: Unit) => {
              if (unit.selected) {
                const existsSection = this.existsSection(section.id);
                if (!existsSection) {
                  /** create section (not unit) */
                  this.addWarrantyToFormArray(this.warranties.length, section.id, unit,
                    section.extendedDescription ? section.extendedDescription : section.description,
                  false, null, null);
                }

                /** exists same unit in this.warranties? */
                const existsSameUnit = this.existsSameUnit(section.id, unit.code);

                /** getting the index to insert/modify this unit */
                const indexToInsert = this.getIndexToInsert(existsSection, section.id, unit.code);
                if (existsSameUnit) {
                  /* exists already this unit, so update the data */
                  this.updateWarrantyToFormArray(indexToInsert,
                    section.id,
                    unit);
                } else {
                  /** create new unit */
                  this.addWarrantyToFormArray(indexToInsert,
                    section.id,
                    unit,
                    section.extendedDescription ? section.extendedDescription : section.description,
                    true,
                    this.flexDiscountConfig.flexDiscountConfigWithErosion,
                    this.flexDiscountConfig.flexDiscountConfigWithErosion);
                }
              }
            });
          }
        });
      });
    });
    this.discountForm.patchValue(this.warranties);
  }

  private getIndexToInsert(existsSection: boolean, sectionId: string, assetCode: string) {
    let result = this.warranties.length;
    if (existsSection) {
      /** getting the index to insert/modify the unit with code=assetCode */
      result = this.getIndexToInsertUnit(sectionId, assetCode);
    }
    return result;
  }
  private existsSection(sectionId: string) {
    return this.warranties.find(warranty => !warranty.isUnit && warranty.sectionId === sectionId) !== undefined;
  }

  private existsSameUnit(sectionId: string, assetCode: string) {
    return this.warranties.find(
      warranty => warranty.isUnit && warranty.sectionId === sectionId && warranty.assetCode === assetCode) !== undefined;
  }

  private getIndexToInsertUnit(sectionId: string, assetCode: string) {
    let indexToInsert = this.warranties.length - 1;
    let found = false;
    let i = 0;
    while (i < this.warranties.length && !found) {
      const warranty = this.warranties[i];
      if (warranty.sectionId === sectionId && warranty.assetCode === assetCode) {
        /** found the unit in warranties */
        found = true;
        indexToInsert = i - 1;
      } else if (warranty.sectionId === sectionId && !found) {
        /** tracking the last unit into section with id=sectionId */
        indexToInsert = i;
      }
      i++;
    }
    return indexToInsert + 1;
  }

  setFlexDiscountsOnTotal() {
    /** case: FLEX_DISCOUNT_ON_TOTAL */
    this.percentWithErosion = this.formatValue(this.flexDiscounts ? this.flexDiscounts.flexWithErosion.percent : 0);
    this.percentWithoutErosion = this.formatValue(this.flexDiscounts ? this.flexDiscounts.flexWithoutErosion.percent : 0);
    this.amountWithErosion = this.formatValue(this.flexDiscounts ? this.flexDiscounts.flexWithErosion.amount : 0);
    this.amountWithoutErosion = this.formatValue(this.flexDiscounts ? this.flexDiscounts.flexWithoutErosion.amount : 0);
    this.totalDiscount = this.flexDiscounts ? Number(this.flexDiscounts.flexWithErosion.amount) + Number(this.flexDiscounts.flexWithoutErosion.amount) : 0;
  }

  private formatValue(value) {
    return this.decimalPipe.transform(value, this.digitsInfo, this.localeId);
  }

  reloadForm() {
    /** reload form after recalculate */
    this.assetInstances = [];
    this.warranties = [];
    this.discountForm = this.formBuilder.group({
      warranties: this.formBuilder.array([])
    });
    this.ngOnInit();
  }


  close() {
    this.pushMessageHandlerService.clear();
    this.modalClose.emit();
  }

  clearInputField(groupName: string, fieldName: string, index: number) {
    this.getWarranties().controls[index].get(groupName).get(fieldName).setValue(0);
  }

  clearInputFieldAndRemoveMessageError(groupName: string,
                                       fieldName: string,
                                       index: number,
                                       assetCode: string,
                                       tag: string,
                                       validIndex: number) {
    const value = this.getWarranties().controls[index].get(groupName).get(fieldName).value;
    if (value !== 0) {
      this.removeMessageError(assetCode + tag);
    }
    this.getWarranties().controls[index].get(groupName).get(fieldName).setValue(0);
    this.warranties[index].valid[validIndex] = true;
  }

  recalculateAndSave() {
    if (this.flexDiscountConfig.flexDiscountMode === ReIssueDiscountModeEnum.FLEX_DISCOUNT_ON_SINGLE_UNIT) {
      /** case: FLEX_DISCOUNT_ON_SINGLE_UNIT */
      const flexDiscounts: FlexDiscountUnit[] = [];
      let index = 0;
      this.getWarranties().controls.forEach(warrantyForm => {
        const warrantyInfo = this.warranties[index];
        if (warrantyInfo.isUnit) {
          const discount: FlexDiscountUnit = new FlexDiscountUnit();
          const flexDiscountParam: FlexDiscountParam = warrantyForm.value;
          discount.flexWithErosion = flexDiscountParam.flexWithErosion;
          discount.flexWithoutErosion = flexDiscountParam.flexWithoutErosion;
          discount.assetCode = warrantyInfo.assetCode;
          discount.sectionId = warrantyInfo.sectionId;
          flexDiscounts.push(discount);
        }
        index++;
      });
      this.calculateDiscountOnSingleUnits.emit(flexDiscounts);
   } else if (this.flexDiscountConfig.flexDiscountMode === ReIssueDiscountModeEnum.FLEX_DISCOUNT_ON_TOTAL) {
      /** case: FLEX_DISCOUNT_ON_TOTAL */
      if (this.validateFormsCaseDiscountOnTotal()) {
        /** form valid, so I can recalculate */
        const flexDiscount: FlexDiscountParam = new FlexDiscountParam();
        flexDiscount.flexWithErosion = new FlexDiscount();
        flexDiscount.flexWithErosion.percent = this.unitService.parseToNumberAndFormat(this.percentWithErosion);
        flexDiscount.flexWithErosion.amount = this.unitService.parseToNumberAndFormat(this.amountWithErosion);
        flexDiscount.flexWithoutErosion = new FlexDiscount();
        flexDiscount.flexWithoutErosion.percent = this.unitService.parseToNumberAndFormat(this.percentWithoutErosion);
        flexDiscount.flexWithoutErosion.amount = this.unitService.parseToNumberAndFormat(this.amountWithoutErosion);
        this.calculateDiscountOnTotal.emit(flexDiscount);
      }
    }
  }

  clearDiscounts() {
    this.pushMessageHandlerService.clear();
    this.getWarranties().controls.forEach(warranty => {
      warranty.get('flexWithErosion').get('percent').setValue(0);
      warranty.get('flexWithErosion').get('amount').setValue(0);
      warranty.get('flexWithoutErosion').get('percent').setValue(0);
      warranty.get('flexWithoutErosion').get('amount').setValue(0);
    });

    for (const warranty of this.warranties) {
      warranty.valid = [true, true, true, true];
    }
    this.percentWithErosion = '0.00';
    this.amountWithErosion = '0.00';
    this.percentWithoutErosion = '0.00';
    this.amountWithoutErosion = '0.00';
    this.inputCaseDiscountOnTotalAreValid = [true, true, true, true];
    this.recalculateEnabled = true;
  }

  canExpand = (): RgiRxTableExpansionTrigger => (row: any) => true;

  canContract = (): RgiRxTableExpansionTrigger => (row: any) => true;

  getWarranties() {
    return this.discountForm.controls.warranties as UntypedFormArray;
  }

  calculateTotal() {
    let total = 0;
    this.warranties.forEach(warranty => {
      if (warranty.isUnit) {
        total += Number(warranty.annualGross);
      }
    });
    return total;
  }

  addWarrantyToFormArray(index: number, sectionId: string, unit: Unit, sectionName: string,
                         isUnit: boolean, flexDiscountConfigWithErosion: FlexDiscountConfigDetails,
                         flexDiscountConfigWithoutErosion: FlexDiscountConfigDetails) {
    const warranty: WarrantyDiscountSummary = new WarrantyDiscountSummary();
    warranty.assetCode = unit.code;
    warranty.sectionId = sectionId;
    warranty.name = unit.description;
    warranty.sectionName = sectionName;
    warranty.isUnit = isUnit;
    warranty.annualGross = unit.annual ? unit.annual.gross : null;
    warranty.flexDiscounts = unit.annual ?  unit.annual.flexDiscounts : null;
    warranty.flexWithErosionMaxPercent = flexDiscountConfigWithErosion ? flexDiscountConfigWithErosion.percentMax :  null;
    warranty.flexWithoutErosionMaxPercent = flexDiscountConfigWithoutErosion ? flexDiscountConfigWithoutErosion.percentMax : null;
    warranty.flexWithErosionMinPercent = flexDiscountConfigWithErosion ? flexDiscountConfigWithErosion.percentMin : null;
    warranty.flexWithoutErosionMinPercent = flexDiscountConfigWithoutErosion ? flexDiscountConfigWithoutErosion.percentMin :  null;
    warranty.discountable = unit.discountable;
    this.warranties.splice(index, 0, warranty);
    const warrantyFormControl = this.buildFormControl(warranty, unit.discountable, flexDiscountConfigWithErosion ? flexDiscountConfigWithErosion.percentDefault : null, flexDiscountConfigWithoutErosion ? flexDiscountConfigWithoutErosion.percentDefault : null);
    this.getWarranties().insert(index, warrantyFormControl);
  }

  updateWarrantyToFormArray(index: number, sectionId: string, unit: Unit) {
    const warranty = this.warranties[index];
    const defaultValueWithErosion =  this.flexDiscountConfig && this.flexDiscountConfig.flexDiscountConfigWithErosion
      ? this.flexDiscountConfig.flexDiscountConfigWithErosion.percentDefault : null;
    const defaultValueWithoutErosion =  this.flexDiscountConfig && this.flexDiscountConfig.flexDiscountConfigWithoutErosion
      ? this.flexDiscountConfig.flexDiscountConfigWithoutErosion.percentDefault : null;
    warranty.annualGross = String(Number(warranty.annualGross) + Number(unit.annual.gross));
    warranty.flexDiscounts = this.buildFlexDiscount(warranty, unit.annual);
    this.warranties[index] = warranty;
    this.getWarranties().controls[index] = this.buildFormControl(warranty, unit.discountable,
      defaultValueWithErosion, defaultValueWithoutErosion);
  }

  buildFlexDiscount(warranty: WarrantyDiscountSummary, annual: Instalment) {
    const flexDiscountParam = new FlexDiscountParam();
    const flexWithErosion = new FlexDiscount();
    const flexWithoutErosion = new FlexDiscount();
    flexWithErosion.percent = warranty.flexDiscounts.flexWithErosion.percent;
    flexWithErosion.amount = Number(warranty.flexDiscounts.flexWithErosion.amount) + Number(annual.flexDiscounts.flexWithErosion.amount);
    flexWithoutErosion.percent = warranty.flexDiscounts.flexWithoutErosion.percent;
    flexWithoutErosion.amount = Number(warranty.flexDiscounts.flexWithoutErosion.amount) + Number(annual.flexDiscounts.flexWithoutErosion.amount);
    flexDiscountParam.flexWithErosion = flexWithErosion;
    flexDiscountParam.flexWithoutErosion = flexWithoutErosion;
    return flexDiscountParam;
  }

  buildFormControl(warranty: WarrantyDiscountSummary, discountable: boolean, defaultValueWithErosion: number, defaultValueWithoutErosion: number) {
    /** case: FLEX_DISCOUNT_ON_SINGLE_UNIT */
    const amountWithErosion = warranty.flexDiscounts && warranty.flexDiscounts.flexWithErosion
      ? warranty.flexDiscounts.flexWithErosion.amount : 0;
    const amountWithoutErosion = warranty.flexDiscounts && warranty.flexDiscounts.flexWithoutErosion
      ? warranty.flexDiscounts.flexWithoutErosion.amount : 0;
    const total = Number(warranty.annualGross) + Number(amountWithErosion) + Number(amountWithoutErosion);
    warranty.maxDiscountForAmount = warranty.flexWithErosionMaxPercent * total / 100;
    warranty.minDiscountForAmount = warranty.flexWithErosionMinPercent * total / 100;
    return this.formBuilder.group({
        flexWithErosion: this.formBuilder.group({
          amount: [amountWithErosion],
          percent: [warranty.flexDiscounts && warranty.flexDiscounts.flexWithErosion && warranty.flexDiscounts.flexWithErosion.percent !== 0
            ? warranty.flexDiscounts.flexWithErosion.percent : defaultValueWithErosion],
        }),
        flexWithoutErosion: this.formBuilder.group({
          amount: [amountWithoutErosion],
          percent: [warranty.flexDiscounts && warranty.flexDiscounts.flexWithoutErosion && warranty.flexDiscounts.flexWithoutErosion.percent !== 0 ? warranty.flexDiscounts.flexWithoutErosion.percent : defaultValueWithoutErosion],
        })
      });
  }

  isFlexDiscountModeOnSingleUnit() {
    return this.flexDiscountConfig && this.flexDiscountConfig.flexDiscountMode === ReIssueDiscountModeEnum.FLEX_DISCOUNT_ON_SINGLE_UNIT;
  }

  isFlexDiscountModeOnTotal() {
    return this.flexDiscountConfig  && this.flexDiscountConfig.flexDiscountMode === ReIssueDiscountModeEnum.FLEX_DISCOUNT_ON_TOTAL;
  }

  validateTotalCaseDiscountOnSingleUnit() {
    /** case: FLEX_DISCOUNT_ON_SINGLE_UNIT */
    let result = true;
    const indexTotal = this.getWarranties().controls.length;
    for (let i = 0; i < indexTotal; i++) {
      const row = this.warranties[i];
      if (row.isUnit) {
        result = result && this.subValidateCaseDiscountOnSingleUnit(i, row, row.flexWithErosionMaxPercent, row.flexWithErosionMinPercent, 'flexWithErosion', 'percent');
        result = result && this.subValidateCaseDiscountOnSingleUnit(i, row, row.flexWithErosionMaxPercent, row.flexWithErosionMinPercent, 'flexWithErosion', 'amount');
        result = result && this.subValidateCaseDiscountOnSingleUnit(i, row, row.flexWithoutErosionMaxPercent, row.flexWithoutErosionMinPercent, 'flexWithoutErosion', 'percent');
        result = result && this.subValidateCaseDiscountOnSingleUnit(i, row, row.flexWithoutErosionMaxPercent, row.flexWithoutErosionMinPercent, 'flexWithoutErosion', 'percent');
        result = result && this.subValidateCaseDiscountOnSingleUnit(i, row, row.flexWithoutErosionMaxPercent, row.flexWithoutErosionMinPercent, 'flexWithoutErosion', 'amount');
      }
    }
    return  result;
  }
  subValidateCaseDiscountOnSingleUnit(index: number,
                                      row: WarrantyDiscountSummary,
                                      percentMax: number,
                                      percentMin: number,
                                      formGroupName: string,
                                      formControlName: string) {
    /** case: FLEX_DISCOUNT_ON_SINGLE_UNIT */
    let result = true;
    const formControlGroup = this.getWarranties().controls[index].get(formGroupName);
    const isAmount = formControlName === 'amount';
    const value = formControlGroup.get(formControlName).value;
    if (((isAmount && (Number(value) < row.minDiscountForAmount || Number(value) > row.maxDiscountForAmount))
          || (!isAmount && (Number(value) < percentMin || Number(value) > percentMax)))
      && Number(value) !== 0) {
      result = false;
    }
    return result;
  }


  validateCaseDiscountOnSingleUnit(index: number,
                                   validIndex: number,
                                   diffValidIndex: number,
                                   row: WarrantyDiscountSummary,
                                   percentMax: number,
                                   percentMin: number,
                                   formGroupName: string,
                                   formControlName: string,
                                   otherFormControlName: string,
                                   errorCode: string,
                                   tag: string) {
    /** case: FLEX_DISCOUNT_ON_SINGLE_UNIT */
    const formControlGroup = this.getWarranties().controls[index].get(formGroupName);
    const isAmount = formControlName === 'amount';
    const value = formControlGroup.get(formControlName).value;
    if (((isAmount && (Number(value) < row.minDiscountForAmount || Number(value) > row.maxDiscountForAmount)) || (!isAmount && (Number(value) < percentMin || Number(value) > percentMax)))
      && Number(value) !== 0) {
      this.warranties[index].valid[validIndex] = false;
      this.warranties[index].valid[validIndex + diffValidIndex] = true;
      const prefix = '[' + row.sectionName + ' - ' + row.name + '] ';
      const errorMsg = this.buildMessage(prefix, [errorCode, 'RE_ISSUE.FLEX_DISCOUNT_RANGE_ALLOWED_VALUE'], percentMax, percentMin);
      this.removeMessageError(row.assetCode + tag);
      setTimeout(() => {
        this.notifyErrorMessage(row.assetCode + tag, null, errorMsg);
      });
    } else {
      // remove error
      this.warranties[index].valid[validIndex] = true;
      this.warranties[index].valid[validIndex + diffValidIndex] = true;
      this.removeMessageError(row.assetCode + tag);
    }
  }

  removeMessageError(id: string) {
    const indexMsg = this.pushMessages.findIndex(msg => msg.id === id);
    if (indexMsg > -1) {
      this.pushMessageHandlerService.remove(this.pushMessages[indexMsg]);
      this.pushMessages.splice(indexMsg, 1);
    }
  }

  validateFormsCaseDiscountOnTotal() {
    /** case: FLEX_DISCOUNT_ON_TOTAL */
    let result = true;

    result = result && this.checkErrorForm(0,
      'RE_ISSUE.FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR',
      'RE_ISSUE.FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR',
      this.TAG_DISCOUNTS_WITH_EROSION_ERROR,
      this.percentWithErosion,
      this.amountWithErosion);

    const formWithoutErosionValid = this.checkErrorForm(2,
      'RE_ISSUE.FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR',
      'RE_ISSUE.FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR',
      this.TAG_DISCOUNTS_WITHOUT_EROSION_ERROR,
      this.percentWithoutErosion,
      this.amountWithoutErosion);

    result = result && formWithoutErosionValid;

    this.recalculateEnabled = result;

    return result;
  }

  checkErrorForm(indexInput,
                 errorCodePercent: string,
                 errorCodeAmount: string,
                 tagError: string,
                 percentInput: string,
                 amountInput: string) {
    let result = true;
    let errorCode1 = null;
    let errorCode2 = null;
    const percentMax = this.flexDiscountConfig.flexDiscountConfigWithErosion.percentMax;
    const percentMin = this.flexDiscountConfig.flexDiscountConfigWithErosion.percentMin;

    const percentInputParsed: any = this.unitService.parseToNumberAndFormat(percentInput);
    const percentFromAmount = this.calculatePercentFromAmount(amountInput);
    if ((Number(percentInputParsed) > Number(percentMax) || Number(percentInputParsed) < Number(percentMin))
        && Number(percentFromAmount) === 0 && Number(percentInputParsed) !== 0) {
      errorCode1 = errorCodePercent;
      errorCode2 = 'RE_ISSUE.FLEX_DISCOUNT_RANGE_ALLOWED_VALUE';
      this.inputCaseDiscountOnTotalAreValid[indexInput] = false;
      this.inputCaseDiscountOnTotalAreValid[indexInput + 1] = true;
    } else if ((Number(percentFromAmount) > Number(percentMax) || Number(percentFromAmount) < Number(percentMin)) &&
              (Number(percentInputParsed) === 0 || percentInputParsed === '0.00') && Number(percentFromAmount) !== 0) {
      errorCode1 = errorCodeAmount;
      errorCode2 = 'RE_ISSUE.FLEX_DISCOUNT_RANGE_ALLOWED_VALUE';
      this.inputCaseDiscountOnTotalAreValid[indexInput + 1] = false;
      this.inputCaseDiscountOnTotalAreValid[indexInput] = true;
    }
    if (errorCode1 && errorCode2) {
      result = false;
      const errorMsg = this.buildMessage('', [errorCode1, errorCode2], percentMax, percentMin);
      setTimeout(() => {
        this.pushMessageHandlerService.clearTag(tagError);
        this.notifyErrorMessage(null, tagError, errorMsg);
      });
    } else {
      this.inputCaseDiscountOnTotalAreValid[indexInput + 1] = true;
      this.inputCaseDiscountOnTotalAreValid[indexInput] = true;
      this.pushMessageHandlerService.clearTag(tagError);
    }
    return result;
  }

  calculatePercentFromAmount(amount) {
    const amountFormatted = this.unitService.parseToNumberAndFormat(amount);
    const total = this.calculateTotal() + Number(this.totalDiscount);
    return (amountFormatted * 100) / total;
  }

  buildMessage(prefix: string, msgTagError: string[], valueMax, valueMin) {
    const msgTranslated = this.translateError(msgTagError);
    return prefix + msgTranslated + `${valueMin}-${valueMax}%`;
  }

  notifyErrorMessage(id: string, tag: string, content: string) {
    const errorMsg = new RgiRxPushMessage();
    errorMsg.id = id;
    errorMsg.tag = tag;
    errorMsg.status = 'danger';
    errorMsg.dismissible = false;
    errorMsg.options = {icon: 'rgi-ui-icon-alert'};
    errorMsg.content = content;
    this.pushMessages.push(errorMsg);
    this.pushMessageHandlerService.notify(errorMsg);
  }

  translateError(value: string[]) {
    const msg = of(value);
    let output: string;
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      output = next[0] + next[1];
    }).unsubscribe();
    return output;
  }

  getSymbolPercent(row: WarrantyDiscountSummary) {
    return row.isUnit ? '%' : '';
  }

  canRecalculate() {
    if (this.isFlexDiscountModeOnSingleUnit()) {
      return this.validateTotalCaseDiscountOnSingleUnit();
    }
    return this.recalculateEnabled;
  }
}

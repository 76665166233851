export class ReportElement {
  constructor(idQlikSheet: string, idQlikElement: string, typeCode: string, x: number, y: number, rows: number, cols: number) {
    this.idQlikSheet = idQlikSheet;
    this.idQlikElement = idQlikElement;
    this.typeCode = typeCode;
    this.x = x;
    this.y = y;
    this.rows = rows;
    this.cols = cols;
  }

  id: number;
  code: string;
  description: string;
  idQlikSheet: string;
  idQlikElement: string;
  typeCode: string;
  x: number;
  y: number;
  rows: number;
  cols: number;
}

<div class="ppevo-survey-eval-product" [ngClass]="productCfg.scoreStyleClass" [class.ppevo-selected]="product.selected">
    
    <div survey-eval-product-bar 
        class="ppevo-title-bar"
        [validation]="validation" 
        [product]="product"
        [editable]="isEdit"
        (select)="onSelectEmitter.emit($event)"
        (clickInfoProduct)="onClickInfoProductEmitter.emit($event)"
        [productCfg]="productCfg"
    ></div>

    <div class="ppevo-tree-body ppevo-sections"  *ngIf="productCfg.sectionsCfg.visibleSectionIds.length>0" [hidden]="!productCfg.expandContent">
        <survey-eval-section
            *ngFor="let sectionId of productCfg.sectionsCfg.visibleSectionIds"
            [coverages]="getCovsOrPksBySectionId(sectionId)"
            (clickInfo)="onClickInfoCoverage($event)"
            [editable]="isEdit"
            [productCfg]="productCfg"
            [sectionCfg]= "productCfg.sectionsCfg.map[sectionId]"
            (select) = "onSelectCoverage($event)"
            [validation]="validation && validation.coverages"
        ></survey-eval-section>
    </div>
</div>

export class InitContract {

  constructor(
    public id: string,
    public contractNumber: string,
    public editProposal: boolean,
    public policyType: string
  ) {
  }

}

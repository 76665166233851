import {Inject, Injectable} from '@angular/core';
import {AnagResourceService} from '../../anag-resources/anag-resource.service';
import {Observable} from 'rxjs';
import {AnagApiAddressLayoutConfig} from '../../anag-model/anag-api/anag-api-address-config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RgiRxUserService} from '@rgi/rx/auth';
import {RestApiConf} from '../../anag-constants/rest-api-conf';
import {AnagApiSubappAdminlevel, ExtendedFilter} from '../../anag-model/anag-api/anag-api-subapp-adminlevel';
import {AnagApiAddress} from '../../anag-model/anag-domain/anag-api-party';
import {NormalizeAddressResponse} from '../../anag-model/anag-api/normalize-address-response';
import {SystemProperty} from "../../anag-model/api-models/api-system-properties";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AnagUmaAddressService {

  hostPath: string;
  private baseApiUrlV2;
  private storedAlternativeRoleOffset: number;
  private systempropertyEndPoint;

  private alternativeRoleOffsetKey = 'PortfolioQuoteRolesConfigOffset';

  constructor(
    public anagResources: AnagResourceService,
    private httpClient: HttpClient,
    private userService: RgiRxUserService,
    @Inject('environment') private apiConf: RestApiConf,
  ) {
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path;
    this.baseApiUrlV2 = this.apiConf.api.portal.host + this.apiConf.api.portal.path + '/v2';
    this.systempropertyEndPoint = this.baseApiUrlV2 + '/common/config';
  }

  getAddressConfig$(addressType: string, countryCode: string): Observable<AnagApiAddressLayoutConfig> {
    return this.httpClient.get<AnagApiAddressLayoutConfig>(`${this.hostPath}/subapp/addressconfig/${countryCode}?function=${addressType}`);
  }

  getSystemPoperty(key: string): Observable<SystemProperty> {
    return this.httpClient.get<SystemProperty>(this.systempropertyEndPoint + '/' + key);
  }

  getAdminLevelValues$(addressType: string, countryCode: string, adminLevel: number, filterValue: string,
                       customLevelFilter?: ExtendedFilter): Observable<AnagApiSubappAdminlevel> {
    let httpParams: HttpParams = new HttpParams();
    if (filterValue) {
      httpParams = httpParams.set('filterValue', filterValue);
    }
    if (addressType) {
      httpParams = httpParams.set('addressType', addressType);
    }
    if (!!customLevelFilter && !!customLevelFilter.filterLvl && !!customLevelFilter.filterValue) {
      switch (customLevelFilter.filterLvl) {
        case 1:
          httpParams = httpParams.set('filterValueLevel1', customLevelFilter.filterValue);
          break;
        case 2:
          httpParams = httpParams.set('filterValueLevel2', customLevelFilter.filterValue);
          break;
        case 3:
          httpParams = httpParams.set('filterValueLevel3', customLevelFilter.filterValue);
          break;
        case 5:
          httpParams = httpParams.set('filterValueLevel5', customLevelFilter.filterValue);
          break;
      }
    }
    return this.httpClient.get<AnagApiSubappAdminlevel>(`${this.hostPath}/subapp/adminlevel/${countryCode}/${adminLevel}`,
      {params: httpParams});
  }

  normalizeAddress$(address: AnagApiAddress): Observable<NormalizeAddressResponse> {
    return this.httpClient.post<NormalizeAddressResponse>(`${this.hostPath}/anag/normalize`, {address});
  }

  async offsetAlternativeRole(): Promise<number> {
    return new Promise((resolve) => {
      if (this.storedAlternativeRoleOffset) {
        resolve(this.storedAlternativeRoleOffset);
      }
      this.getSystemPoperty(this.alternativeRoleOffsetKey).pipe(take(1)).subscribe(resp => {
        this.storedAlternativeRoleOffset = +resp.value;
        resolve(this.storedAlternativeRoleOffset);
      }, () => {
        resolve(0);
      });
    });
  }
}

<div class="panel-body">
  <lic-funds-message [leftPercent]="getLeftFundPercent(leftFundAmount)"
                     [leftAmount]="leftFundAmount"></lic-funds-message>
  <lic-funds-percentage-message *ngFor="let fund of percentErrors"
                                [error]="fund"></lic-funds-percentage-message>
  <div class="input-group">
    <label>
      <span translate>lic_name</span><span>: </span>
      <input style="margin-left: 5px;" name="filter" [(ngModel)]="searchPattern"/></label>
  </div>
  <div class="row" [formGroup]="fg">
    <div formArrayName="FAFunds">
      <div class="col-lg-3 fund-button-container" *ngFor="let fund of funds; let i = index"
           [hidden]="getHidden(fund.name)">
        <lic-fund-button [name]="fund.name" [formControlName]="i.toString()" [totalAmount]="amount"></lic-fund-button>
      </div>
    </div>
  </div>
</div>




<div class="rgi-gp-grid-container">
  <div *ngFor="let item of details; index as i">
    <div class="rgi-gp-flex-center">
      <span class="rgi-ui-text-5" *ngIf="!translateLabel; else translated">{{item.code}}</span>
      <ng-template #translated>
        <span class="rgi-ui-text-5" translate>{{item.code}}</span>
      </ng-template>
      <span class="rgi-ui-text-5" *ngIf="!item.multiDescriptions; else multiDescription">:
        <b data-qa="rgi-gp-policyDataValue">{{item.description}}</b>
      </span>
      <ng-template #multiDescription>
          <button class="rgi-ui-btn rgi-gp-flex" (click)="openModalListDescriptions(item)" data-qa="rgi-gp-policyDataValue">:
              <b class="rgi-ui-text-5" data-qa="rgi-gp-policyDataValue">{{item.multiDescriptions[0].description}}</b>
            <span class="rgi-ui-icon-info"></span>
          </button>
      </ng-template>
    </div>
    <div *ngIf="details.length > 3 && i < (integerDiv(details.length)*3)">
      <hr class="rgi-gp-separator">
    </div>
  </div>
</div>

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {AnagApiParty} from '../../../anag-model/anag-domain/anag-api-party';

@Component({
  selector: 'rgi-anag-key-data-modal',
  templateUrl: './key-data-modal.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class KeyDataModalComponent implements OnInit, OnModalClose {
  modalClose: EventEmitter<any> = new EventEmitter();
  inputParty: AnagApiParty;

  constructor(@Optional() @Inject(DIALOG_DATA) data: AnagApiParty) {
    if (data) {
      this.inputParty = data;
    }
  }

  ngOnInit() {
  }

  actionClose() {
    this.modalClose.emit();
  }

}

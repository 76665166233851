<div class="rgi-gp-section-title rgi-gp-subsection rgi-gp-mb-m">
  <b translate>_GP_._TITLE_._CLUSTER_DATA_</b>
</div>
<div class="rgi-gp-grid-container">
  <label class="rgi-ui-form-control-label">
    <span class="rgi-ui-text-5" translate>_GP_._LABEL_._DESCRIPTION_</span>
    <span class="rgi-ui-text-5">:
      <b data-qa="rgi-gp-policyDataValue">{{cluster.description}}</b>
    </span>
  </label>
</div>
<div>
  <hr class="rgi-gp-separator">
</div>
<rgi-gp-generic-detail [details]="getClusterProps(cluster)" [translateLabel]="true">
</rgi-gp-generic-detail>
<ng-container *ngIf="cluster.variables && cluster.variables.length">
  <div class="rgi-gp-section-title rgi-gp-subsection rgi-gp-mb-m">
    <b translate>_GP_._TITLE_._VARIABLES_</b>
  </div>
  <rgi-gp-generic-detail [details]="getClusterVariables(cluster)">
  </rgi-gp-generic-detail>
</ng-container>
<div class="rgi-gp-section-title rgi-gp-subsection rgi-gp-mb-m">
  <b translate>_GP_._TITLE_._GUARANTEES_</b>
</div>
<rgi-gp-units-detail [units]="getSelectedUnits()" [showTitle]="false">
</rgi-gp-units-detail>
<ng-container *ngIf="clauses?.assetClauses && clauses?.assetClauses.length>0">
  <div class="rgi-gp-section-title rgi-gp-subsection rgi-gp-mb-m" >
    <b translate>_GP_._TITLE_._CLAUSES_</b>
  </div>
  <rgi-gp-clauses-detail [clauses]="clauses.assetClauses" [showTitle]="false"></rgi-gp-clauses-detail>
</ng-container>
<div class="rgi-gp-w-100" *ngIf="!!proposalNumber && showApplicationList">
  <div class="rgi-gp-section-title rgi-gp-subsection rgi-gp-mb-m">
    <b translate>_GP_._TITLE_._APPLICATIONS_LIST_</b>
  </div>
  <rgi-gp-group-policy-applications-list
    [proposalNumber]="proposalNumber" [clusterCode]="cluster.code" [assetCode]="assetCode"
    [idParentSession]="idParentSession">
  </rgi-gp-group-policy-applications-list>
</div>

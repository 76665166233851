export class GenericEntity {
  constructor(
    public id: string,
    public code: string | null,
    public description: string | null) {
  }

  public isNull(): boolean {
    if (!this.id && !this.code && !this.description) {
      return true;
    } else if (this.id === '' && this.code === '' && this.description === '') {
      return true;
    } else {
      return false;
    }
  }
}

export class Enumerated extends GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public additionalData: Map<string, string>) {
    super(id, code, description);
  }
}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const httpRequest = request.clone({
      headers: request.headers.set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
    });

    return next.handle(httpRequest);
  }
}

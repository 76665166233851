<div modal-render="true" tabindex="-1" role="dialog" class="modal fade in" uib-modal-animation-class="fade"
    modal-in-class="in" ng-style="{'z-index': 1050 + index*10, display: 'block'}" uib-modal-window="modal-window"
    index="0" animate="animate" modal-animation="true" style="z-index: 1050; display: block;">

    <div class="modal-dialog  modal-lg">
        <div class="modal-new-close" (click)="cancel()">
            CHIUDI
        </div>
        <div class="modal-content">
            <div class="modal-new-style">
                <div class="modal-header">
                    <h3 class="modal-title" translate>Coinsurance</h3>
                </div>
                <div class="modal-body modal-body-max">
                    <div class="title-modal-quotation-axa">
                        <span translate="">{{isLeasingInstitute ? 'Dati polizza delegataria' : 'New coinsurance quote'}}</span>
                    </div>
                    <div *ngIf="quotaForm && quotaCoass" [formGroup]="quotaForm">
                        <div class="row">
							<div class="col-sm-6 col-xs-12">
								<label translate>Compagnia coassicuratrice/delegataria:</label>
								<pnc-required required="true" *ngIf="config.idCompanyRequired"></pnc-required>
								<select formControlName="company" class="select-nativa">
									<option *ngFor="let company of quotaCoass.companies; index as i; trackBy: genericEntitiesTrackByFnCustom1"
											[ngValue]="company.code">{{company?.description}}
									</option>
								 </select>
								 <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
							</div>
							<div class="col-sm-6 col-xs-12">
								<label translate>Intermediario:</label>
								<pnc-required required="true" *ngIf="config.idIntermediaryRequired"></pnc-required>
								<select formControlName="intermediary" class="select-nativa">
									<option *ngFor="let intermediary of quotaCoass.intermediaries; index as i; trackBy: genericEntitiesTrackByFnCustom1"
											[ngValue]="intermediary.code">{{intermediary?.description}}
									</option>
								 </select>
								 <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
							</div>
                            <div class="col-sm-6 col-xs-12">
                                <label translate>Percentuale quota:</label>
                                <pnc-required required="true"></pnc-required>
                                <input id="ourPercentage" min="0"  (keydown)="onKeyDown($event)" type="number" formControlName="ourPercentage"
                                    class="form-control mic-input">
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <label translate>Percentuale commissioni:</label>
                                
                                <input id="commissionPercentage" min="0" (keydown)="onKeyDown($event)" type="number" formControlName="commissionPercentage"
                                    class="form-control mic-input">
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <label translate>Numero polizza:</label>
                                <pnc-required required="true" *ngIf="config.policyNumberRequired"></pnc-required>
                                <input id="policyNumber" formControlName="policyNumber" class="form-control mic-input">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                 
                    <div class="modal-footer">
                        <button class="btn btn-white" (click)="back()" translate>CANCEL</button>
                       <button class="btn btn-primary"  (click)="onConfirm()" translate>CONFIRM</button>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop fade in" uib-modal-animation-class="fade" modal-in-class="in"
    uib-modal-backdrop="modal-backdrop" modal-animation="true" style="z-index: 1040;"></div>

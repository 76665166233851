import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { TypeOfLossDamageDto as TypeOfLossDto } from './type-of-loss-dto';

export class PaymentDto {

    companyAmount: number;
    insuredAmount: number;
    paymentType: ApiEnumType;
    tempId: number;
    typeOfLosses: TypeOfLossDto[];
}

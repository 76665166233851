import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChatSummaryComponent } from './chat-summary/chat-summary.component';
import { ChatHistoryComponent } from './chat-history/chat-history.component';
import { ChatNewMessageComponent } from './chat-new-message/chat-new-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../../assets/i18n/translations';

@NgModule({
    declarations: [
        ChatSummaryComponent,
        ChatHistoryComponent,
        ChatNewMessageComponent,
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS)
    ],
    exports: [
        ChatSummaryComponent,
        ChatHistoryComponent,
        ChatNewMessageComponent
    ]
})
export class ChatComponentsModule { }

import { Inject, Injectable, Optional } from '@angular/core';
import { PV_TOKEN, SurveyEvaluateStateLifeOperations } from '@rgi/life-postsales-card';
import { SurveyCommonOperations, SurveyEvalTools, SurveyEvaluateState } from '@rgi/ng-passpropro-survey';
import { DocumentResource } from '@rgi/ng-passpropro/documents';
import { RoutingService } from '@rgi/rx/router';
import { ModalService } from '@rgi/rx/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class SurveyEvaluateStateOperationsExt<S extends SurveyEvaluateState> extends SurveyEvaluateStateLifeOperations<S> {


    constructor(
        commOps: SurveyCommonOperations,
        modalService: ModalService,
        routingService: RoutingService,
        documentResource: DocumentResource,
        tools: SurveyEvalTools,
        @Inject(PV_TOKEN.CORE_SESSION_SERVICE) @Optional() protected sessionService,
        @Inject(PV_TOKEN.CORE_INJECTOR) @Optional() protected injector
    ) {
      super(commOps, modalService, routingService, documentResource, tools, sessionService, injector);
    }


    beforeUpdate$(st: S): Observable<S> {
        return super.beforeUpdate$(st).pipe(
            map(state => {
                if (state.navigation.map.PRINT) {
                    state.navigation.map.PRINT.visible = false;
                }

                if (state.evaluatorProducts.filter(p => p.selected).length <= 0) {
                    state.selectEnabled = state.isEdit;
                }
                return state;
            })
        );
    }
}

import {Component, ComponentRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssetInstance, AssetInstanceWithTotal} from '../../../../models/domain-models/asset-instance';
import {AssetSection} from '../../../../models/domain-models/asset-section';
import {AssetList} from '../../../../models/domain-models/quotation';
import {Unit} from '../../../../models/domain-models/unit';
import {Variable} from '../../../../models/domain-models/variable';
import {Variables} from '../../../../models/domain-models/variables';
import {WarrantySummary} from '../../../../models/domain-models/warranty-summary';
import {PcAssetInstance} from '../../../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {OnModalClose} from '@rgi/rx/ui';
import {GenericEntity} from '../../../../models/domain-models/generic-entity';
import {Currency} from '../../../../models/domain-models/parameters/currency';

@Component({
  selector: 'lib-re-issue-warranty-modal',
  templateUrl: './re-issue-warranty-modal.component.html',
  styleUrls: ['./re-issue-warranty-modal.component.css']
})
export class ReIssueWarrantyModalComponent implements OnInit, OnModalClose {

  componentRef: ComponentRef<any>;
  title: string;
  warranties: WarrantySummary[] = new Array<WarrantySummary>();
  arrayWarranties = new Array<WarrantySummary>();
  open = false;
  @Input() allAsset: Array<PcAssetInstance>;
  arrayInstances: Array<Array<AssetInstance>> = [];
  instances: Array<AssetInstanceWithTotal> = [];
  isOpen: Array<boolean> = [];
  @Input() assetsQuotation: AssetList;
  @Input() currency: Currency;
  @Output() modalClose = new EventEmitter();


  constructor() {
  }

  ngOnInit() {
    this.allAsset.forEach((assetInstance: PcAssetInstance) => {
      const asset = this.assetsQuotation.assets.find(assetQuotation => assetInstance.asset.key === assetQuotation.assetId);
      if (asset.instances) {
        this.arrayInstances.push(asset.instances);
      }
    });
    this.arrayInstances.forEach((val: Array<AssetInstance>) => {
      val.forEach((asse: AssetInstance) => {
        const assetInstanceWithTotal: AssetInstanceWithTotal = new AssetInstanceWithTotal();
        assetInstanceWithTotal.assetCode = asse.assetCode;
        assetInstanceWithTotal.clauses = asse.clauses;
        assetInstanceWithTotal.extensionCode = asse.extensionCode;
        assetInstanceWithTotal.extraDescription = asse.extraDescription;
        assetInstanceWithTotal.instanceDescription =
          this.allAsset.find(asset => asset.asset.key === asse.assetCode).asset.name;
        assetInstanceWithTotal.name = asse.name;
        assetInstanceWithTotal.packetUnitCode = asse.packetUnitCode;
        assetInstanceWithTotal.sections = asse.sections;
        assetInstanceWithTotal.showClauses = asse.showClauses;
        assetInstanceWithTotal.variableList = this.getVariables(assetInstanceWithTotal.sections);
        assetInstanceWithTotal.total = asse.productPremium.annual.gross;
        this.instances.push(assetInstanceWithTotal);
      });
    });
    this.instances.forEach(val => {
      this.warranties = [];
      val.sections.forEach((instances: AssetSection) => {
        if (instances.unitList) {
          instances.unitList.forEach((unit: Unit) => {
            if (unit.selected) {
              this.warranties.push(
                new WarrantySummary(
                  val.instanceDescription,
                  instances.extendedDescription ? instances.extendedDescription : instances.description,
                  unit.description,
                  unit.annual.gross,
                  unit.factors
                ));
            }
          });
        }
      });
    });
  }

  getElementFromList(value: string, list: Array<GenericEntity>) {
    return list.find((obj) => {
      return obj.code === value;
    });
  }

  getVariables(sections: AssetSection[]): Variables {
    const variables = new Variables();
    variables.variables = new Array<Variable>();
    sections.forEach(section => {
      if (section.unitList) {
        section.unitList.forEach(unit => {
          if (unit.selected) {
            if (unit.factors) {
              unit.factors.forEach(variable => {
                variables.variables.push(variable);
              });
            }
          }
        });
      }
    });
    return variables;
  }

  close() {
    this.modalClose.emit();
  }

}

export class VariableUnit {
  code?: string;
  variable: Variable[] = [];

  constructor() {
  }
}

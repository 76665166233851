import {QuestionFlatI} from './question-flat';

export class QuestionnaireFlatI {
  score?: number;
  code?: string;
  codeVersion?: string;
  customProperties?: any;
  codeType?: string;
  descriptionType?: string;
  questions?: QuestionFlatI[];
  description?: string;
  openDate?: string;
  category?: number;
  compulsory?: boolean;
  included?: boolean;

  constructor() {
  }
}

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)" id="dates">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
                    (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step *ngIf="isOperationRoleStepPresent" id="operationRoles" label="lpc_roles_variation" [errors]="errors" fieldId="operationRoles" (next)="onNext($event)">
    <lpc-roles-step-wrapper
      [active]="isActiveStep('operationRoles')"
      [roles]="operationRoles"
      [operationCode]="session.operation"
      [roleDefinition]="operationRolesAdmitted"
      (roleEvent)="handleRolesEvent($event)">
    </lpc-roles-step-wrapper>
  </lpc-step>

  <lpc-step *ngIf="hasProductFactors" label="lpc_variation_step_label" [errors]="errors" fieldId="factors" (next)="onNext($event)" id="factors">
    <div formGroupName="factors">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateFactors($event)"
                          [form]="formGroup.controls['factors']" [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event, !showCreditPayment)" id="quotation"
            (confirmAccept)="onNext($event, !showCreditPayment, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div formGroupName="quotation" class="overflow">
      <div *ngIf="quotationData">
        <h4 translate>lpc_life_policy</h4>
        <lpc-quotation-recess [quotationData]="quotationData"></lpc-quotation-recess>
      </div>
      <div *ngIf="damageQuotationData">
        <h4 translate>lpc_damages_policy</h4>
        <lpc-quotation-recess [quotationData]="damageQuotationData"></lpc-quotation-recess>
      </div>
      <div *ngIf="!!listPremiumAmount && !!listPremiumAmount.length">
        <h4 translate>lpc_life_policy</h4>
        <div class="row">
          <div class="col-sm-6" *ngFor="let premium of listPremiumAmount">
            <lpc-premium-table [premium]="premium"></lpc-premium-table>
          </div>
        </div>
      </div>
      <div *ngIf="!!damageListPremiumAmount && !!damageListPremiumAmount.length">
        <h4 translate>lpc_damages_policy</h4>
        <div class="row" *ngIf="!!damageListPremiumAmount && !!damageListPremiumAmount.length">
          <div class="col-sm-6" *ngFor="let premium of damageListPremiumAmount">
            <lpc-premium-table [premium]="premium"></lpc-premium-table>
          </div>
        </div>
      </div>

      <!--<div *ngIf="!!listPremiumAmount && !!listPremiumAmount.length">
        <lpc-premium-table [premiums]="listPremiumAmount"> </lpc-premium-table>
      </div>
      <div *ngIf="!!damageQuotationData && !!damageQuotationData.length">
        <lpc-premium-table [premiums]="damageQuotationData"> </lpc-premium-table>
      </div>-->
    </div>
  </lpc-step>

  <lpc-step *ngIf="showCreditPayment" [enableAuth]="isAuth" label="lpc_payment_data" [errors]="errors" [fieldId]="stepPaymentFieldId"
            (next)="onNext($event,true)" id="payment"
            (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-payment-step [required]="true" [paymentTypeDefinitions]="paymentTypes" formControlName="payment"
                      [summary]="isAfterId('payment')"></lpc-payment-step>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

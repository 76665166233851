<template #openClauseModal></template>
<div *ngIf="isSomeClauseVisible() ">
  <br clear="all">
  <!-- TITLE SECTION ----------------------------------------------------------------------------->
  <div *ngIf="header" class="nmf_cont_titsez nmf_cont_titsez_color">
    <span *ngIf="headerIcon" [ngClass]="headerIcon" class="header-icon rgifont nfm_font_size"></span>
    <h3 translate>{{header}}</h3>
  </div>
  <div class="nmf_cont_dati">
    <form [formGroup]="clauseForm">
      <div class="nmf_cont_clausole_riga">
        <div *ngFor="let clause of clauseList; index as i; trackBy: clausesTrackByFn" class="nmf_cont_clausole_col">
          <ng-container *ngIf="clause.visible">
            <!-- CLAUSOLE TIPO 1 --------------------------------------------------------------------------------------------------------------------->
            <div *ngIf="getClauseType(clause.propCLASEL) === 0 || getClauseType(clause.propCLASEL) === 1"
                 class="nmf_clausole">
              <div class="nmf_clausole_col nmf_clausole_col_check">
                <div #checkbox{{clause?.code}} (click)="toggleClause(clause)" [attr.disabled]="!isClauseEnabled(clause)" [formControlName]="clause.code"
                     [ngClass]="{'pointerEnabled':isClauseEnabled(clause), 'pointerDisabled':!isClauseEnabled(clause),
                          'checkRequired':clause?.compulsory}" class="btn btn-checkbox checkbox-unit"
                     ngDefaultControl type="checkbox">
                <span [attr.data-qa]="'clause_'+clause.code" [ngClass]="{'glyphicon-ok': clause?.selection }" class="glyphicon"
                      style="color: #f68020;">
                </span>
                </div>
              </div>
              <!-- TESTO -->
              <div class="nmf_clausole_col">
                {{ clause?.extendedDescription ? clause?.extendedDescription : clause?.description }}
                <mic-help [helpFile]="clause?.helpFile"></mic-help>

              </div>
              <!-- ICONA -->
              <div *ngIf="clause?.selection && ((!(clause?.textFormat === 1 && clause?.textType === '0'))
                  || (clause?.textFormat === 1 && clause?.textType === '0' && clause?.text?.length > 0))" class="nmf_clausole_col nmf_clausole_col_icon">
              <span (click)="openClause(clause)" [attr.data-qa]="'open-clause_'+clause.code"
                    class="icon rgifont rgi-pencil-square-o pull-right"></span>
              </div>
            </div>

            <!-- CLAUSOLE TIPO 2 --------------------------------------------------------------------------------------------------------------------->
            <div *ngIf="getClauseType(clause.propCLASEL) === 2 || getClauseType(clause.propCLASEL) === 3"
                 class="nmf_clausole">
              <!-- TESTO -->
              <div class="nmf_clausole_col">
                {{ clause?.extendedDescription ? clause?.extendedDescription : clause?.description }}
                <mic-help [helpFile]="clause?.helpFile"></mic-help>
              </div>
              <!-- SELECT -->
              <div class="nmf_clausole_col">
                <select (change)="selectClause(clause)" [attr.data-qa]="'clause_'+clause.code"
                        [formControlName]="clause.code"
                        class="select-nativa">
                  <option *ngIf="getClauseType(clause.propCLASEL) === 2" [value]="'0'"></option>
                  <option [value]="'1'">Yes</option>
                  <option [value]="'2'">No</option>
                </select>
                <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
              </div>
              <!-- ICONA -->
              <div *ngIf="clause?.selection && ((!(clause?.textFormat === 1 && clause?.textType === '0'))
                || (clause?.textFormat === 1 && clause?.textType === '0' && clause?.text?.length > 0))" class="nmf_clausole_col nmf_clausole_col_icon">
              <span (click)="openClause(clause)" [attr.data-qa]="'open-clause_'+clause.code"
                    class="icon rgifont rgi-pencil-square-o pull-right"></span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="(viewError && validationMessages && validationMessages?.length > 0)" id="error-container"
       style="margin-top: 2em">
    <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                      objectType="complex" type="error"></pnc-form-message>
  </div>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import {RgiRxTranslationService} from './rgi-rx-translation.service';
import {RGI_RX_LOCALE} from './i18n-api';

@Pipe({
  name: 'localeDescription',
})
export class RgiRxLocaleDescriptionPipe implements PipeTransform {
  constructor(private translateService: RgiRxTranslationService) {
  }

  transform(value: RGI_RX_LOCALE, ...args: any[]): any {
    return this.translateService.getLanguageDescription(value);
  }
}

import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import {RoutingOutletComponent, RoutingService} from '@rgi/rx/router';
import {EventService, LoadingIndicatorService, RgiRxTemplateInterpolationService} from '@rgi/rx';
import {StateStoreService} from '@rgi/rx/state';
import {ModalService} from '@rgi/rx/ui';
import {PortalLoadingIndicatorComponent} from './portal-ui/portal-loading-indicator/portal-loading-indicator.component';
import {tap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PortalCardService} from './portal-card.service';
import {PortalConfig} from "./rgi-rx-portal-api";
import {RgiRxHttpErrorService} from "@rgi/rx/http";

declare var angular: angular.IAngularStatic;

export function downgradeRootModule(config?: PortalConfig) {
  angular.module(config.module)
    .directive('rgiRxRoutingOutlet', downgradeComponent({component: RoutingOutletComponent}))
    .directive('rgiRxPortalLoadingIndicator', downgradeComponent({component: PortalLoadingIndicatorComponent}))
    .factory('RgiRxRoutingService', downgradeInjectable(RoutingService))
    .factory('RgiRxEventService', downgradeInjectable(EventService))
    .factory('RgiRxStateStoreService', downgradeInjectable(StateStoreService))
    .factory('RgiRxModalService', downgradeInjectable(ModalService))
    .factory('RgiRxTranslationService', downgradeInjectable(RgiRxTranslationService))
    .factory('RgiRxTemplateInterpolationService', downgradeInjectable(RgiRxTemplateInterpolationService))
    .factory('RgiRxPortalCardService', downgradeInjectable(PortalCardService))
    .factory('RgiRxPortalCardService', downgradeInjectable(PortalCardService))

  if (config.ui && config.ui.enableGlobalHttpLoadingIndicator) {

    angular.module(config.module)
      .factory('RgiRxLoadingIndicatorService', downgradeInjectable(LoadingIndicatorService))
      .run(['eventService', 'RgiRxLoadingIndicatorService', (eventService, loadingIndicationService: LoadingIndicatorService) => {
        loadingIndicationService
          .hasPending$()
          .subscribe(isPending => {
            if (isPending) {
              eventService.broadcastEvent(`start-loader`);
            } else {
              eventService.broadcastEvent(`stop-loader`);
            }
          });
      }]);
  }

  if (config.http && config.http.enableGlobalHttpErrorRender) {
    angular.module(config.module)
      .factory('RgiRxHttpErrorService', downgradeInjectable(RgiRxHttpErrorService))
      .run(['eventService', 'RgiRxHttpErrorService', (eventService, errorService: RgiRxHttpErrorService) => {
        errorService.error$(config.http.when).subscribe(
          next => {
            eventService.broadcastEvent('alertMsg', { strId: 'system_error', applicationArea: 'core', executionId: next.tracing ? next.tracing.id : ''});
          }
        );
      }]);


  }

  angular.module(config.module).run(['eventService', 'SESSION_EVENT', 'RgiRxRoutingService', 'RgiRxStateStoreService', (eventService, SESSION_EVENT, RoutingService: RoutingService, StateStoreService: StateStoreService) => {
    eventService.listenGlobalEvent(SESSION_EVENT.SESSION_REMOVED_EVT, (event, args) => {
      RoutingService.destroy(args.idSession);
      StateStoreService.destroyAllMatching(args.idSession);
    });
    RoutingService.destroyed$()
      .pipe(
        tap(destroyedRoute => {
          StateStoreService.destroy(destroyedRoute.key);
        })
      )
      .subscribe();
  }]);

}

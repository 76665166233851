import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { LegalFeeEntity } from '../domain/legal-fee-entity';
import { ExposureEntity } from '../domain/exposure-entity';

export interface IComboListReversal {
    receiptIdMotivoAnnullo: string; // motivo annullo (sempre 10)
    receiptDate: string; // data del sinistro
    receiptIdRamoMinisteriale: string; // ramo ministeriale
    receiptId: string; // id quietanza - Nuovo
}

export interface ReasonReversal {
    code: string;
    description: string;
}

export interface ResponseReasonReversal {
    operationType: string;
    reasonCancellation: any[][];
    exposureList: ExposureEntity[];
}

export interface ReversalBodyRequest {
    receiptId: string;
    receiptNote: string;
    receiptIdMotivoAnnullo: string;
    receiptDate: Date;
    exposureList: ExposureEntity[];
}

export interface ResponseSaveReversal {
    code: string;
    descr: string;
    idpotentialclaim?: string;
    potentialclaimnumber?: string;
    companycode?: string;
    detail?: string;
    messages?: string;
    savedCodeList?: string;
    objects?: string;
}

export interface DetailReversalReturn {
    receiptId: string;
    note: string;
    reasonCancelledSelected?: string;
    dateReceipt: string;
    claimNumber: string;
    exposureList: ExposureEntity[];
}

export const INIT_DETAIL_REVERSAL: DetailReversalReturn = {
    dateReceipt: '',
    note: '',
    claimNumber: '',
    receiptId: '',
    exposureList: []
};

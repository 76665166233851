import { IiabTranslationsKeys } from './iiab-translations-keys';

export const IIAB_ES: IiabTranslationsKeys = {
  _IIAB_: {
    _MOTOR_: {
      _NICE_ACTIMIZE_KO_: 'Nice Actimize no está disponible actualmente. Vuelva a abrir la solicitud de autorización más adelante para reactivar la selección de las partes de la propuesta.'
    },
    _OTP_: {
      _OTP_SERVICE_KO: 'El servicio OTP no está disponible. Por favor, inténtelo de nuevo más tarde.',
      _INITIATED_SIGNING_PROCESS_: 'Proceso de firma iniciado',
      _TITLE_: 'Firmar documentos',
      _DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_: 'El consentimiento de privacidad de la firma digital no está aceptado. No será posible firmar documentos.',
      _MOBILE_NOT_PRESENT_NOT_SIGNED_: 'El número de móvil no está presente. No será posible firmar documentos.',
      _EMAIL_NOT_PRESENT_NOT_SIGNED_: 'La dirección de correo electrónico no está presente. No será posible firmar documentos.',
      _DOCUMENT_DATA_NOT_PRESENT_NOT_SIGNED_: 'Faltan algunos datos en el documento. No será posible firmar documentos.',
      _GENERIC_NOT_SIGNED_ERROR_: 'No será posible firmar documentos.',
      _OTP_DOCUMENTS_: 'Firma Digital - Documentos',
      _CONSENSUSES_AND_CONTACTS_: 'Consensos y contactos',
      _OTP_DATA_MISSING_: 'Faltan algunos datos obligatorios. No será posible firmar documentos digitalmente.',
      _OTP_DOCUMENTS_MISSING_: 'No hay documentos digitales disponibles para este movimiento.',
      _BTN_: {
        _SIGN_DOCS_: 'Firmar documentos',
      }
    },
    _LABEL_: {
      _DESCRIPTION_: 'Descripción',
      _VALUE_: 'Valor',
      _RELEASE_DATE_: 'Fecha de Emisión',
      _EXPIRATION_DATE_: 'Fecha de Expiración' 
    },
    _ANAG_: {
      _TAX_ID_DIFFERENT_FROM_DOCUMENT_ID_: 'El Documento de Identidad (NIF/NIE/Pasaporte) informado en la Sección Documentos no coincide con el Documento de Identidad (NIF/NIE/Pasaporte) informado en el apartado Datos de Identificación'
    }
  },
  _LS_TITLE_TOTAL_SUMMARY_: 'Resumen total'
};

<div class="cc-modal-wrapper">
  <rgi-rx-panel class="operations-panel">
    <!-- HEADER -->
    <rgi-rx-panel-header (onClose)="closeModal()" [closeable]="true">
      <span translate>CONTRACT_CONSULTATION.ACTIONS</span>
    </rgi-rx-panel-header>
    <rgi-rx-expansion-panel *ngFor="let operationType of map | keyvalue" data-qa="action" color="default">
      <rgi-rx-expansion-panel-header  >
        {{operationType.key}}
      </rgi-rx-expansion-panel-header>
      <ul>
        <li class="action-container" *ngFor="let val of operationType.value">
          <a class="cc-actions-link">
            {{val.operation.description}}
            <span class="rgi-ui-icon-right-arrow"></span>
          </a>
        </li>
      </ul>
    </rgi-rx-expansion-panel>
    <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeModal()"><span translate>CONTRACT_CONSULTATION.CLOSE</span></button>
    </rgi-rx-panel-footer>
  </rgi-rx-panel>
</div>

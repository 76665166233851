
    <div class="modal-header">
      <div class="modal-title" translate>
          questionnaire
        </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <ppevo-questionnaire
        [disabled]="true"
        [questionnaireFlat]="questionnaireFlat"
        ></ppevo-questionnaire>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn-group btn-group-justified"
        style="margin-top: 0px !important; border: 0px !important; border-right: 0px !important;">
        <div class="btn-group">
          <button class="btn btn-warning" data-qa="close-button" (click)="closeModal()" translate>lpc_close_button</button>
        </div>
      </div>
    </div>

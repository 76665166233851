import { Unit } from './unit';
import { Asset } from './asset';
import { DamageLocation } from './damage-location';

export class Policy {

  public number: string;
  public description: string;
  public holder: string;
  public effectDate: Date;
  public expirationDate: Date;
  public objectId: number;
  public product: string;
  public units: Unit[];
  public assets: Asset[];
  public claimBranchCodes: string[];
  public nodeCode: string;
  public residenceHolder: DamageLocation;
  public asset: string;
  public isDummyPolicy: boolean;
  public category: string;
  public isCanceled: boolean;
  public cancellationDate: Date;
  public originalEffectDate: Date;
  constructor() { }
}

export class GpGenericModalData {
  title?: string;
  text: string;
  buttons?: GpActionButton[];

  constructor(title: string, text: string) {
    this.title = title;
    this.text = text;
  }
}

export class GpActionButton {
  label: string;
  action: string;
}

export enum ACTION_BTN_ENUM {
  INQUIRY = 'INQUIRY'
}

export const GO_TO_INQUIRY_BTN: GpActionButton = {label: '_GP_._BTN_._GOTO_INQUIRY_', action: ACTION_BTN_ENUM.INQUIRY};

import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CustomModalService} from '../../custom-modal.service';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {CoassContainer} from '../../models/domain-models/parameters/coass-container';
import {QuotaCoass} from '../../models/domain-models/quota-coass';
import {QuotaCoassList} from '../../models/domain-models/quota-coass-list';
import {QuotaCoinsuranceListModalComponent} from '../coinsurance-quote-list/coinsurance-quote-list.modal.component';
import {QuotaCoinsuranceModalComponent} from '../coinsurance-quote/coinsurance-quote.modal.component';
import {ParameterService} from '../parameters/parameters.service';
import {CoinsuranceService} from './coinsurance.service';

@Component({
    selector: 'mic-coinsurance',
    templateUrl: './coinsurance.component.html'
})
export class CoinsuranceComponent implements OnDestroy {

    @Input() parameterControlForm: UntypedFormGroup;
    @Input () editable = true;
    @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
    @Output() coinsuranceErrorEmitter: EventEmitter<any> = new EventEmitter<any>();

    @Input('coass')
    set setCoass(inputCoass) {
        const bkParameterControlForm = this.parameterControlForm;
        this.parameterControlForm = null;
        setTimeout(() => {
            this.coass = inputCoass;
            this.parametersService.addCoassToFormGroup(bkParameterControlForm, this.coass);
            if (this.editable === false) {
                bkParameterControlForm.get('coassType').disable();
                bkParameterControlForm.get('ourCoassPercentage').disable();
            }
            this.parameterControlForm = bkParameterControlForm;
            this.raiseErrors();
        });
    }

    @Output() updateParameter: EventEmitter<string> = new EventEmitter();
    @ViewChild('coassModalOverlay', { read: ViewContainerRef, static: true })
    coassModalOverlay: ViewContainerRef;

    coass: CoassContainer;
    subscriptions: Subscription = new Subscription();
    coassQuotes: QuotaCoassList;

    constructor(
        private parametersService: ParameterService,
        private coinsuranceService: CoinsuranceService,
        private modalService: CustomModalService) {

    }

    private raiseErrors() {
        /* i warning scattano come alert (non bloccanti) immediatamente,
         mentre gli errori vengono propagati al componente padre in quando scatteranno al cambio pagina*/
        if (this.coass.errors && this.coass.errors.length) {
            this.coinsuranceErrorEmitter.emit(this.coass.errors[0]);
        } else {
            this.coinsuranceErrorEmitter.emit(null);
        }
        if (this.coass.warnings && this.coass.warnings.length) {
            this.eventPropagation.emit({
                eventName: 'alertMsg',
                message: this.coass.warnings[0]
            });
        }
    }

    genericEntitiesTrackByFnCustom(genericEntity: GenericEntity) {
        return genericEntity.id;
    }

    onValueChange(fc: string) {

        if (fc && fc === 'coassType') {
            this.coass.ourCoassPercentage = null;
        }
        this.coass[fc] = this.parameterControlForm.controls[fc].value;
        this.updateParameter.emit(fc);
    }

    getFcVal(controlName) {

        return this.parameterControlForm.controls[controlName] ?
            this.parameterControlForm.controls[controlName].value : null;
    }

    onDivideQuote() {

        if (this.coass.config.policyLevelDelegation) {
            this.openEditCoassModal();
        } else {
            this.openLeasingInstituteModal();
        }
    }

    private openEditCoassModal() {

        this.coinsuranceService.getCoinsuranceQuotes().subscribe((quotas) => {
            this.coassQuotes = quotas;
            this.openEditCoassListQuoteModal(quotas);
        });
    }

    private openLeasingInstituteModal() {

        this.coinsuranceService.getCoinsuranceQuotes().subscribe((response) => {
            this.coassQuotes = response;
            if (response.quotas.length) {
                this.openEditCoassQuoteModal(response.quotas[0], () => this.onModifyQuoteCoass(true), true);
            } else {
                this.coinsuranceService.newCoinsuranceQuote().subscribe(newQuote => {
                    this.openEditCoassQuoteModal(newQuote, () => this.onModifyQuoteCoass(true), true);
                });
            }
        });
    }

    private openEditCoassListQuoteModal(quotes: QuotaCoassList) {

        const componentRef: ComponentRef<QuotaCoinsuranceListModalComponent>
            = this.modalService.openModal(this.coassModalOverlay, QuotaCoinsuranceListModalComponent);
        componentRef.instance.coassQuotes = quotes;
        componentRef.instance.coass = this.coass;
        this.subscriptions.add(
            componentRef.instance.createEditCoassEmitter.subscribe(quotaCoass => this.onCreateEditQuoteCoass(quotaCoass)));
        this.subscriptions.add(
            componentRef.instance.deleteCoassEmitter.subscribe(quotaCoass => this.onDeleteQuoteCoass(quotaCoass, componentRef)));
        this.subscriptions.add(
            componentRef.instance.modifiedCoass.subscribe(isModified => this.onModifyQuoteCoass(isModified)));
    }

    onCreateEditQuoteCoass(quotaCoass) {

        if (quotaCoass) {
            this.openEditCoassQuoteModal(quotaCoass, this.openEditCoassModal.bind(this));
        } else {
            this.coinsuranceService.newCoinsuranceQuote().subscribe(coassQuote => {
                this.openEditCoassQuoteModal(coassQuote, this.openEditCoassModal.bind(this));
            });
        }
    }

    private openEditCoassQuoteModal(quotaToEdit: QuotaCoass, callbackFn?: () => void, isLeasingInstitute?: boolean) {

        const componentRef: ComponentRef<QuotaCoinsuranceModalComponent>
            = this.modalService.openModal(this.coassModalOverlay, QuotaCoinsuranceModalComponent);
        componentRef.instance.quotaCoass = quotaToEdit;
        componentRef.instance.isLeasingInstitute = isLeasingInstitute;


        this.subscriptions.add(componentRef.instance.quoteConfirmEmitter.subscribe((quotaCoass) => {


            if (!quotaCoass) {
                componentRef.destroy();
                if (this.coass.config.policyLevelDelegation) {
                    this.openEditCoassModal();
                }
                return;
            }

            this.onSubmitQuote(quotaCoass, componentRef, callbackFn);
        }));
    }


    onDeleteQuoteCoass(quotaCoass: QuotaCoass, componentRef: ComponentRef<QuotaCoinsuranceListModalComponent>) {

        this.coassQuotes.quotas = this.coassQuotes.quotas.filter((coass) => coass.idQuota !== quotaCoass.idQuota);
        this.coinsuranceService.putCoinsuranceQuotes(this.coassQuotes).subscribe(quotes => {
            this.coinsuranceService.getCoinsurance().subscribe(coinsurance => {
                this.coassQuotes = quotes;
                this.fillPercentageAndErrors(coinsurance, quotes);
                componentRef.instance.coassQuotes = this.coassQuotes;
                this.raiseErrors();
            });
        });
    }

    private onSubmitQuote(quotaCoass: any, componentRef: ComponentRef<QuotaCoinsuranceModalComponent>, callbackFn: () => void) {

        this.coinsuranceService.putCoinsuranceQuotes(this.createAddEditRequest(quotaCoass)).subscribe(quotes => {
            this.coinsuranceService.getCoinsurance().subscribe(coinsurance => {
                this.coassQuotes = quotes;
                this.fillPercentageAndErrors(coinsurance, quotes);
                componentRef.destroy();
                if (callbackFn) {
                    callbackFn();
                }
                this.raiseErrors();
            });

        });
    }

    private fillPercentageAndErrors(coinsurance: CoassContainer, quotes?: QuotaCoassList) {

        this.coass.errors = coinsurance.errors;
        this.coass.warnings = coinsurance.warnings;
        this.coass.totalPercentage = coinsurance.totalPercentage;
        this.coass.ourCoassPercentage = coinsurance.ourCoassPercentage;

        if (quotes && quotes.warnings) {
            this.coass.warnings.push(...quotes.warnings);
        }
    }

    createAddEditRequest(quotaCoass) {

        const found = this.coassQuotes.quotas.find(quota => quota.idQuota === quotaCoass.idQuota);
        if (!found) {
            this.coassQuotes.quotas.push(quotaCoass);
        }
        return this.coassQuotes;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private onModifyQuoteCoass(isModified) {
        if (isModified) {
            this.updateParameter.emit('ourCoassPercentage');
        }
    }
}

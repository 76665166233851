import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnviromentsService } from './enviroments.service';
import { SystemProperties } from '../enum/life-issue.enum';

@Injectable({
  providedIn: 'root'
})
export class SystemPropertiesService {
  protected baseApiUrl: string;
  protected storedSystemProperties = new Map();

  constructor(protected httpClient: HttpClient, protected environment: EnviromentsService) {
    this.baseApiUrl = environment.baseApiURL;
  }

  loadAllProperties(): Observable<any> {
    const request: any = {
      filterKey:
        {
          keys: this.getKeys()
        }
    };

    return this.getSystemProp(request).pipe(
      tap((resp) => {
      this.storedSystemProperties = new Map();
      this.setPropertiesIntoMap(resp);
      })
    );
  }

  getKeys() {
    return [
      SystemProperties.PORTFOLIO_CONTRACTS,
      SystemProperties.LIFE_CONTRACTS,
      SystemProperties.LIFE_CONTEXTUAL_POL_PROP,
      SystemProperties.ACCEPT_PROP_IN_AUTH,
      SystemProperties.ACCEPT_ISSUE_PROP_IN_AUTH,
      SystemProperties.PREVAL_DELEGATE,
      SystemProperties.PREVAL_LEGALGUARDIAN,
      SystemProperties.PREVAL_THIRDPAYER
    ];
  }

  setPropertiesIntoMap(resp) {
    const lpcm = resp.systemProperties.filter(val => val.key === SystemProperties.LIFE_CONTRACTS);
    if (lpcm !== undefined && lpcm != null) {
      this.storedSystemProperties.set(SystemProperties.LIFE_CONTRACTS, lpcm[0].value);
    }
    const pcm = resp.systemProperties.filter(val => val.key === SystemProperties.PORTFOLIO_CONTRACTS);
    if (pcm !== undefined && pcm != null) {
      this.storedSystemProperties.set(SystemProperties.PORTFOLIO_CONTRACTS, pcm[0].value);
    }
    const lcpap = resp.systemProperties.filter(val => val.key === SystemProperties.LIFE_CONTEXTUAL_POL_PROP);
    if (lcpap !== undefined && lcpap != null) {
      this.storedSystemProperties.set(SystemProperties.LIFE_CONTEXTUAL_POL_PROP, lcpap[0].value === 'true');
    }
    const apiam = resp.systemProperties.filter(val => val.key === SystemProperties.ACCEPT_PROP_IN_AUTH);
    if (apiam !== undefined && apiam != null) {
      this.storedSystemProperties.set(SystemProperties.ACCEPT_PROP_IN_AUTH, apiam[0].value === 'true');
    }
    const aipiam = resp.systemProperties.filter(val => val.key === SystemProperties.ACCEPT_ISSUE_PROP_IN_AUTH);
    if (aipiam !== undefined && aipiam != null) {
      this.storedSystemProperties.set(SystemProperties.ACCEPT_ISSUE_PROP_IN_AUTH, aipiam[0].value === 'true');
    }
    const pd = resp.systemProperties.filter(val => val.key === SystemProperties.PREVAL_DELEGATE);
    if (pd !== undefined && pd !== null) {
      this.storedSystemProperties.set(SystemProperties.PREVAL_DELEGATE, pd[0].value);
    }
    const plg = resp.systemProperties.filter(val => val.key === SystemProperties.PREVAL_LEGALGUARDIAN);
    if (plg !== undefined && plg !== null) {
      this.storedSystemProperties.set(SystemProperties.PREVAL_LEGALGUARDIAN, plg[0].value);
    }
    const ptp = resp.systemProperties.filter(val => val.key === SystemProperties.PREVAL_THIRDPAYER);
    if (ptp !== undefined && ptp !== null) {
      this.storedSystemProperties.set(SystemProperties.PREVAL_THIRDPAYER, ptp[0].value);
    }
  }

  getSystemProp(request: any): Observable<any> {
    return this.httpClient.post(this.baseApiUrl + '/system/systemproperty', request);
  }

  getSystemPropByKeys(keys): Observable<any> {
    return this.httpClient.post(this.baseApiUrl + '/system/systemproperty', { filterKey: { keys } });
  }

  getStoredSystemProp(propCode): any {
    return this.storedSystemProperties.get(propCode);
  }

}

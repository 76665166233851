import {Inject, Injectable} from '@angular/core';

@Injectable()
export class ReIssueInterceptorsLoaderPortalService {

  constructor(
    @Inject('eventService') private eventService
  ) {
  }

  startAndStop(operation: string) {
    if (operation === 'start') {
      this.eventService.broadcastEvent('start-loader');
    } else if (operation === 'stop') {
      this.eventService.broadcastEvent('stop-loader');
    }
  }
}

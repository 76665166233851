<re-issue-stepper [currentStep]="currentStep" [labels]="stepperLabels"></re-issue-stepper>
<ng-container *ngIf="stateManager.getState$() | async as state">


  <form [formGroup]="summaryForm" class="margin-top">
    <h3 class="detail-title">
      <span translate>RE_ISSUE.CANCELLATION_SUMMARY</span>
    </h3>
    <div class="tbld" style="width: 50%;" data-qa="cancellation_data_field">
      <div class="tbld_row tbld_row_odd">
        <div class="tbld_col tbld_col_title"><span translate>RE_ISSUE.CANCELLATION_REASON</span>:</div>
        <div class="tbld_col tbld_col_value">{{state.cancellationReason.description}}</div>
      </div>
      <div class="tbld_row tbld_row_even">
        <div class="tbld_col tbld_col_title"><span translate>RE_ISSUE.ISSUE_DATE</span>:</div>
        <div class="tbld_col tbld_col_value">{{issueDate}}</div>
      </div>
      <div class="tbld_row tbld_row_odd">
        <div class="tbld_col tbld_col_title"><span translate>RE_ISSUE.EFFECTIVE_DATE</span>:</div>
        <div class="tbld_col tbld_col_value">{{effectiveDate}}</div>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12">
      <rgi-rx-form-field>
        <textarea attr.data-qa="note" rgiRxInput formControlName="note"></textarea>
        <label rgiRxLabel>Note</label>
      </rgi-rx-form-field>
    </div>
  </form>

  <div class="btn-group btn-group-justified">
    <div class="btn-group">
      <button data-qa="back" (click)="back()" rgi-rx-button color="secondary" class="btn pull-right" translate>
        RE_ISSUE.BACK
      </button>
    </div>
    <div class="btn-group">
            <span><button (click)="goToNextPage()" class="btn btn-warning pull-right" color="primary"
                          data-qa="Continue">
                          <span translate>RE_ISSUE.NEXT</span></button></span>
    </div>
  </div>
</ng-container>

import {ModalComponent} from '@rgi/portal-ng-core';
import {downgradeComponent} from '@angular/upgrade/static';
import { LifePostsalesSessionComponent } from '../cards/life-postsales-session/life-postsales-session.component';
import { LifeDetailComponent } from '../cards/life-detail-card/life-datail/life-detail.component';
import { LifeFinancialDetailComponent } from '../cards/life-detail-card/life-financial-detail/life-financial-detail.component';
import { LifePipDetailComponent } from '../cards/life-detail-card/life-pip-detail/life-pip-detail.component';
import { LifeMovementDetailComponent } from '../cards/life-detail-card/life-movement-detail/life-movement-detail.component';
import { FiscalDataDetailComponent } from '../cards/life-detail-card/fiscal-data-detail/fiscal-data-detail.component';
import { ReinvestmentDataDetailComponent } from '../cards/life-detail-card/reinvestment-data-detail/reinvestment-data-detail.component';
import { LoanDataDetailComponent } from '../cards/life-detail-card/loan-data-detail/loan-data-detail.component';
import { ExpiryOptionDetailComponent } from '../cards/life-detail-card/expiry-option-detail/expiry-option-detail.component';
import { LifeTrendDetailComponent } from '../cards/life-detail-card/life-trend-detail/life-trend-detail.component';
import { LifeOptionDetailComponent } from '../cards/life-detail-card/life-option-detail/life-option-detail.component';
import { AnagClaimReportComponent } from '../cards/anag-claim-report/anag-claim-report.component';
import { ClaimListComponent } from '../cards/anag-claim-report/claim-list/claim-list.component';
import { ClaimDetailComponent } from '../cards/anag-claim-report/claim-detail/claim-detail.component';
import { ClaimInsertComponent } from '../cards/anag-claim-report/claim-insert/claim-insert.component';
import { FinancialSearchCardComponent } from '../cards/financial-search-card/financial-search-card.component';
import { FinancialListComponent } from '../cards/financial-search-card/financial-list/financial-list.component';
import { ProfileDetailComponent } from '../cards/financial-search-card/profile-detail/profile-detail.component';
import { ProfileAllocationComponent } from '../cards/financial-search-card/profile-allocation/profile-allocation.component';
import { LifeBookingDetailComponent } from '../cards/life-detail-card/life-booking-detail/life-booking-detail.component';
import {
  lifeDetailCard,
  lifePostSalesSession,
  lifeFinancialDetail,
  lifeMovementDetail,
  lifePipDetail,
  lifeFiscalDataDetail,
  lifeLoanDataDetail,
  lifeExpiryOptionDetail,
  lifeTrendDetail,
  lifeOptionDetail,
  anagClaimReport,
  claimList,
  claimDetail,
  newClaim,
  financialSearchCard,
  lifeFundsList,
  profileDetail,
  profileAllocation,
  bookingDetail,
  premiumDetail, lifeInvestmentPlanDetail
} from './assets/cardTemplates';
import { JS_ROUTES } from '../models/consts/lpc-consts';
import { LpcPremiumDetailComponent } from '../cards/life-detail-card/lpc-premium-detail/lpc-premium-detail.component';
import {
  LifeInvestmentPlanDetailComponent
} from '../cards/life-detail-card/life-investment-plan-detail/life-investment-plan-detail.component';

export enum LPC_AJS_DIRECTIVE {
  /** POSTSALES_CARD = 'lpcLifePostsalesCard' */
  POSTSALES_CARD = 'lpcLifePostsalesCard',

  /** POSTSALES_SESSION = 'lpcLifePostsalesSession' */
  POSTSALES_SESSION = 'lpcLifePostsalesSession',

  /** LIFE_DETAIL = 'lpcLifeDetail' */
  LIFE_DETAIL = 'lpcLifeDetail',

  /** LIFE_FINANCIAL_DETAIL = 'lpcLifeFinancialDetail' */
  LIFE_FINANCIAL_DETAIL = 'lpcLifeFinancialDetail',

  /** LIFE_PIP_DETAIL = 'lpcLifePipDetail' */
  LIFE_PIP_DETAIL = 'lpcLifePipDetail',

  /** LIFE_MOVEMENT_DETAIL = 'lpcLifeMovementDetail' */
  LIFE_MOVEMENT_DETAIL = 'lpcLifeMovementDetail',

  /** LIFE_FISCAL_DATA_DETAIL = 'lpcFiscalDataDetail' */
  LIFE_FISCAL_DATA_DETAIL = 'lpcFiscalDataDetail',

  /** LIFE_REINVESTMENT_DETAIL = 'lpcReinvestmentDataDetail' */
  LIFE_REINVESTMENT_DETAIL = 'lpcReinvestmentDataDetail',

  /** LIFE_LOAN_DATA_DETAIL = 'lpcLoanDataDetail' */
  LIFE_LOAN_DATA_DETAIL = 'lpcLoanDataDetail',

  /** LIFE_EXPIRY_OPT_DETAIL = 'lpcExpiryOptionDetail' */
  LIFE_EXPIRY_OPT_DETAIL = 'lpcExpiryOptionDetail',

  /** LIFE_TREND_DETAIL = 'lpcLifeTrendDetail' */
  LIFE_TREND_DETAIL = 'lpcLifeTrendDetail',

  /** LIFE_OPTION_DETAIL = 'lpcLifeOptionDetail' */
  LIFE_OPTION_DETAIL = 'lpcLifeOptionDetail',

  /** LIFE_CLAIM_REPORT = 'lpcAnagClaimReport' */
  LIFE_CLAIM_REPORT = 'lpcAnagClaimReport',

  /** LIFE_CLAIM_LIST = 'lpcClaimList' */
  LIFE_CLAIM_LIST = 'lpcClaimList',

  /** LIFE_CLAIM_DETAIL = 'lpcClaimDetail' */
  LIFE_CLAIM_DETAIL = 'lpcClaimDetail',

  /** LIFE_CLAIM_INSERT = 'lpcClaimInsert' */
  LIFE_CLAIM_INSERT = 'lpcClaimInsert',

  /** LIFE_FINANCIAL_SEARCH = 'lpcFinancialSearchCard' */
  LIFE_FINANCIAL_SEARCH = 'lpcFinancialSearchCard',

  /** LIFE_FUND_LIST = 'lpcFundsList' */
  LIFE_FUND_LIST = 'lpcFundsList',

  /** LIFE_PROFILE_DETAIL = 'lpcProfileDetail' */
  LIFE_PROFILE_DETAIL = 'lpcProfileDetail',

  /** LIFE_PROFILE_ALLOCATION = 'lpcProfileAllocation' */
  LIFE_PROFILE_ALLOCATION = 'lpcProfileAllocation',

  /** LIFE_BOOKING_DETAIL = 'lpcLifeBookingDetail' */
  LIFE_BOOKING_DETAIL = 'lpcLifeBookingDetail',

  /** LIFE_PREMIUM_DETAIL = 'lpcPremiumDetail' */
  LIFE_PREMIUM_DETAIL = 'lpcPremiumDetail',

  /** LIFE_INVESTMENT_PLAN_DETAIL = 'lpcLifeInvestmentPlanDetail' */
  LIFE_INVESTMENT_PLAN_DETAIL = 'lpcLifeInvestmentPlanDetail'
}

/** please use the consts declared on the Object {@link LPC_AJS_DIRECTIVE} */
export function getLifePVJSDirective(directive: LPC_AJS_DIRECTIVE) {
  return !!directive ? directive + 'Directive' : '';
}


// @ts-ignore
// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

const LPC_TPL_URL = 'assets/';

const factory = (templateKey, template) => ['$templateCache', ($templateCache) => {
  $templateCache.put(LPC_TPL_URL + templateKey, template);
}];

export function AjsConfigurationLifePostsales() {
  angular.module('core').config([
    'eventServiceProvider',
    (evProvider) => {
      evProvider.registerEvent('gettextLanguageChanged', {type: 'i18n'});
    }
  ]);

  // registerPostSalesCard();
  registerPostSalesSessionCard();
  registerDetailCard();
  registerFinancialDetail();
  registerpipDataDetail();
  registerMovementDetail();
  registerFiscalDataDetail();
  registerExpiryOptionDetail();
  registerTrendDetail();
  registerOptionDetail();
  registerAnagForClaimCard();
  registerClaimListCard();
  registerFinancialSearchCard();
  registerFinancialList();
  registerFundDetail();
  registerProfileDetail();
  registerProfileAllocation();
  registerClaimDetail();
  registerNewClaimSession();
  registerReinvestmentDataDetail();
  registerLoanDataDetail();
  registerBookingDetailDetail();
  registerPremiumDetailDetail();
  registerInvestmentPlanDetail();

  angular.module('app')
      .directive(
          'pncModal',
          downgradeComponent({component: ModalComponent})
      );

  // *********************************** */



  /** REGISTRAZIONE CARD FLUSSO POSTSALES */
  /*function registerPostSalesCard() {
    const lifeModule = angular.module('ptfall-card-finder');
    lifeModule.config([
      'cardManagementServiceProvider',
      (cardManagementServiceProvider) => {
        const POST_SALES_CARD = {
          title: 'Post Sales',
          tpl: '',
          name: 'postSalesCard',
          category: 'Contract Management',
          type: 'main',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(POST_SALES_CARD);
      }
    ])
    .run(factory('lifePostSalesCard.tpl.html', lifePostSalesCard))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('postSalesCard', JS_ROUTES.POSTSALES_CARD, {
          template: 'assets/lifePostSalesCard.tpl.html',
          label: 'Home - New Life Post Sales Card',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(LPC_AJS_DIRECTIVE.POSTSALES_CARD, downgradeComponent({component: LifePostsalesCardComponent}));
  }*/

  /** REGISTRAZIONE CARD FLUSSO POSTSALES */


/****************** pagina routing life post-sales-card ******************** */
  function registerPostSalesSessionCard() {
    const lifeModule = angular.module('ptfall-card-finder');
    lifeModule.config([
      'gettext', 'cardManagementServiceProvider',
      (gettext, cardManagementServiceProvider) => {
        const LIFE_POSTSALES_SESSION = {
          title: 'Cambio Recapito Polizza n.',
          tpl: '',
          name: 'postsalesSessionCard',
          category: '',
          type: '',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(LIFE_POSTSALES_SESSION);
      }
    ])
    .run(factory('lifePostSalesSession.tpl.html', lifePostSalesSession))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('postsalesSessionCard', JS_ROUTES.POSTSALES_SESSION, {
          template: 'assets/lifePostSalesSession.tpl.html',
          label: 'Home - Life Postsales Card',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.POSTSALES_SESSION,
        downgradeComponent({component: LifePostsalesSessionComponent})
    );
  }

  /****************** pagina routing life post-sales-card ******************** */


  /******************* ROUTING DETTAGLIO VITA ******************** */
  function registerDetailCard() {
    angular.module('ptfall-card-finder').config([
      'gettext', 'cardManagementServiceProvider',
      (gettext, cardManagementServiceProvider) => {
        const LIFE_DETAIL_SESSION = {
          title: 'Detail Life',
          tpl: '',
          name: 'lifeDetailCard',
          category: '',
          type: '',
          subCards: [],
          subCardsActive: [],
          navigationInSession: true,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(LIFE_DETAIL_SESSION);
      }
    ])
    .run(factory('lifeDetailCard.tpl.html', lifeDetailCard))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_POLICY_DETAIL, {
          template: 'assets/lifeDetailCard.tpl.html',
          label: 'Detail Life',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_DETAIL,
        downgradeComponent({component: LifeDetailComponent})
    );
    /* SUBCARD */
  }



  /** Pagina dei Fondi */
  function registerFinancialDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('lifeFinancialDetail.tpl.html', lifeFinancialDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_FUNDS_DETAIL, {
          template: 'assets/lifeFinancialDetail.tpl.html',
          label: 'Financial Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_FINANCIAL_DETAIL,
        downgradeComponent({component: LifeFinancialDetailComponent})
    );
  }

  function registerpipDataDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('LifePipDetail.tpl.html', lifePipDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_PIPDATA_DETAIL, {
          template: 'assets/LifePipDetail.tpl.html',
          label: 'PIP',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_PIP_DETAIL,
        downgradeComponent({component: LifePipDetailComponent})
    );
  }


  // pagina dei movimenti
  function registerMovementDetail() {
   angular.module('ptfall-card-finder')
   .run(factory('lifeMovementDetail.tpl.html', lifeMovementDetail))
   .config([
     'parallelRoutingProvider',
     (pRouter) => {
       pRouter.when('ptfallFinder', JS_ROUTES.LIFE_MOVEMENT_DETAIL, {
         template: 'assets/lifeMovementDetail.tpl.html',
         label: 'Movement Detail',
         type: 'home',
         controller: 'AngularCommunicationCtrlPostsales'
       });
     }
   ]);

   angular
     .module('app')
     .directive(
        LPC_AJS_DIRECTIVE.LIFE_MOVEMENT_DETAIL,
         downgradeComponent({component: LifeMovementDetailComponent})
     );
  }

  // pagina dettaglio dati fiscali
  function registerFiscalDataDetail() {
    const lifeModule = angular.module('ptfall-card-finder')
    .run(factory('lifeFiscalDataDetail.tpl.html', lifeFiscalDataDetail));

    lifeModule.config([
        'parallelRoutingProvider',
        (pRouter) => {
            pRouter.when('ptfallFinder', JS_ROUTES.LIFE_FISCALDATA_DETAIL, {
                template: 'assets/lifeFiscalDataDetail.tpl.html',
                label: 'Fiscal Data Detail',
                type: 'home',
                controller: 'AngularCommunicationCtrlPostsales'
            });
        }
    ]);

    angular
    .module('app')
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_FISCAL_DATA_DETAIL,
        downgradeComponent({component: FiscalDataDetailComponent})
    );
  }

  // pagina dettaglio dati fiscali
  function registerReinvestmentDataDetail() {
    const lifeModule = angular.module('ptfall-card-finder')
    .run(factory('lifeFiscalDataDetail.tpl.html', lifeFiscalDataDetail));

    lifeModule.config([
        'parallelRoutingProvider',
        (pRouter) => {
            pRouter.when('ptfallFinder', JS_ROUTES.LIFE_REINVESTDATA_DETAIL, {
                template: 'assets/lifeFiscalDataDetail.tpl.html',
                label: 'Reinvestment Data Detail',
                type: 'home',
                controller: 'AngularCommunicationCtrlPostsales'
            });
        }
    ]);

    angular
      .module('app')
      .directive(
          LPC_AJS_DIRECTIVE.LIFE_REINVESTMENT_DETAIL,
          downgradeComponent({component: ReinvestmentDataDetailComponent})
      );

  }


  // pagina dettaglio dati fiscali
  function registerLoanDataDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('lifeLoanDataDetail.tpl.html', lifeLoanDataDetail))
    .config([
        'parallelRoutingProvider',
        (pRouter) => {
            pRouter.when('ptfallFinder', JS_ROUTES.LIFE_LOANDATA_DETAIL, {
                template: 'assets/lifeLoanDataDetail.tpl.html',
                label: 'Loan Data Detail',
                type: 'home',
                controller: 'AngularCommunicationCtrlPostsales'
            });
        }
    ]);

    angular
    .module('app')
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_LOAN_DATA_DETAIL,
        downgradeComponent({component: LoanDataDetailComponent})
    );
  }


  // pagina dettaglio opzione scadenza
  function registerExpiryOptionDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('lifeExpiryOptionDetail.tpl.html', lifeExpiryOptionDetail))
    .config([
        'parallelRoutingProvider',
        (pRouter) => {
            pRouter.when('ptfallFinder', JS_ROUTES.LIFE_EXPOPTION_DETAIL, {
                template: 'assets/lifeExpiryOptionDetail.tpl.html',
                label: 'Expiry Option Detail',
                type: 'home',
                controller: 'AngularCommunicationCtrlPostsales'
            });
        }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_EXPIRY_OPT_DETAIL,
        downgradeComponent({component: ExpiryOptionDetailComponent})
    );
  }


  function registerTrendDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('lifeTrendDetail.tpl.html', lifeTrendDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_TREND_DETAIL, {
          template: 'assets/lifeTrendDetail.tpl.html',
          label: 'Trend Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_TREND_DETAIL,
        downgradeComponent({component: LifeTrendDetailComponent})
    );
  }


  // pagina dei movimenti
  function registerOptionDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('lifeOptionDetail.tpl.html', lifeOptionDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_OPTION_DETAIL, {
          template: 'assets/lifeOptionDetail.tpl.html',
          label: 'Option Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_OPTION_DETAIL,
        downgradeComponent({component: LifeOptionDetailComponent})
    );
  }



  function registerAnagForClaimCard() {
    angular.module('ptfall-card-finder')
    .config([
      'cardManagementServiceProvider', 'gettext',
      (cardManagementServiceProvider, gettext) => {
        const ANAG_CLAIM_REPORT = {
          title: gettext('Denuncia Sinistro'),
          tpl: '',
          name: 'anagForClaimReport',
          category: 'Contract Management',
          type: 'main',
          functions: ['ptflife.claim.report'],
          systemPropPI: 'ActivateClaimReportManagement',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(ANAG_CLAIM_REPORT);
      }
    ])
    .run(factory('anagClaimReport.tpl.html', anagClaimReport))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('anagForClaimReport', JS_ROUTES.LIFE_CLAIMSREPORT, {
          template: 'assets/anagClaimReport.tpl.html',
          label: 'Claim Report',
          type: 'home'
        });
      }
    ]).directive(LPC_AJS_DIRECTIVE.LIFE_CLAIM_REPORT, downgradeComponent({component: AnagClaimReportComponent}));
  }

  // pagina lista denunce
  function registerClaimListCard() {
    const lifeModule = angular.module('ptfall-card-finder');
    lifeModule.config([
      'gettext', 'cardManagementServiceProvider',
      (gettext, cardManagementServiceProvider) => {
        const CLAIM_LIST_SESSION = {
          title: gettext('Elenco Denunce Sinistro'),
          tpl: '',
          name: 'claimListSession',
          category: '',
          type: '',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(CLAIM_LIST_SESSION);
      }
    ])
    .run(factory('claimList.tpl.html', claimList))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('claimListSession', JS_ROUTES.LIFE_CLAIMSLIST, {
          template: 'assets/claimList.tpl.html',
          label: 'Home - Claim List',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ]).directive(LPC_AJS_DIRECTIVE.LIFE_CLAIM_LIST, downgradeComponent({component: ClaimListComponent}));
  }


  function registerClaimDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('claimDetail.tpl.html', claimDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('claimListSession', JS_ROUTES.LIFE_CLAIMS_DETAIL, {
          template: 'assets/claimDetail.tpl.html',
          label: 'Claim Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
        LPC_AJS_DIRECTIVE.LIFE_CLAIM_DETAIL,
        downgradeComponent({component: ClaimDetailComponent})
    );
  }



  function registerNewClaimSession() {
    angular.module('ptfall-card-finder')
    .run(factory('newClaim.tpl.html', newClaim))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('claimListSession', JS_ROUTES.LIFE_NEW_CLAIM, {
          template: 'assets/newClaim.tpl.html',
          label: 'New Claim',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ]);

    angular
      .module('app')
      .directive(
        LPC_AJS_DIRECTIVE.LIFE_CLAIM_INSERT,
        downgradeComponent({component: ClaimInsertComponent})
      );
  }


  function registerFinancialSearchCard() {
    angular.module('ptfall-card-finder')
    .config([
      'cardManagementServiceProvider', 'gettext',
      (cardManagementServiceProvider, gettext) => {
        const FINANCIAL_SEARCH_CARD = {
          title: gettext('Ricerca Profili / Fondi'),
          tpl: '',
          name: 'financialSearchCard',
          category: 'Cross',
          functions: ['ptflife.consultation.fin'],
          type: 'main',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(FINANCIAL_SEARCH_CARD);
      }
    ])
    .run(factory('financialSearchCard.tpl.html', financialSearchCard))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('financialSearchCard', JS_ROUTES.LIFE_FINANCIAL_SEARCH_CARD, {
          template: 'assets/financialSearchCard.tpl.html',
          label: 'Home - Profiles / Funds Search',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
      LPC_AJS_DIRECTIVE.LIFE_FINANCIAL_SEARCH,
      downgradeComponent({component: FinancialSearchCardComponent})
    );
  }

  function registerFinancialList() {
    angular.module('ptfall-card-finder')
    .config([
      'gettext', 'cardManagementServiceProvider',
      (gettext, cardManagementServiceProvider) => {
        const FUND_LIST_SESSION = {
          title: gettext('Fondi di investimento'),
          tpl: '',
          name: 'fundListSession',
          category: '',
          type: '',
          subCards: [],
          subCardsActive: [],
          navigationInSession: false,
          customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: true
          }
        };
        cardManagementServiceProvider.register(FUND_LIST_SESSION);
      }
    ])
    .run(factory('lifeFundsList.tpl.html', lifeFundsList))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('fundListSession', JS_ROUTES.LIFE_FUNDS_LIST, {
          template: 'assets/lifeFundsList.tpl.html',
          label: 'Home - Fund List',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
      LPC_AJS_DIRECTIVE.LIFE_FUND_LIST,
      downgradeComponent({component: FinancialListComponent})
    );
  }

  function registerFundDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('lifeFinancialDetail.tpl.html', lifeFinancialDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('fundListSession', JS_ROUTES.LIFE_FUNDS_DETAIL, {
          template: 'assets/lifeFinancialDetail.tpl.html',
          label: 'Financial Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ]);
  }

  function registerProfileDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('profileDetail.tpl.html', profileDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('fundListSession', JS_ROUTES.LIFE_PROFILES_DETAIL, {
          template: 'assets/profileDetail.tpl.html',
          label: 'Profile Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
      LPC_AJS_DIRECTIVE.LIFE_PROFILE_DETAIL,
      downgradeComponent({component: ProfileDetailComponent})
    );
  }

  function registerProfileAllocation() {
    angular.module('ptfall-card-finder')
    .run(factory('profileAllocation.tpl.html', profileAllocation))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('fundListSession', JS_ROUTES.LIFE_PROFILES_ALLOCATION, {
          template: 'assets/profileAllocation.tpl.html',
          label: 'Profile Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
      LPC_AJS_DIRECTIVE.LIFE_PROFILE_ALLOCATION,
      downgradeComponent({component: ProfileAllocationComponent})
    );
  }

  function registerBookingDetailDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('bookingDetail.tpl.html', bookingDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_BOOKING_DETAIL, {
          template: 'assets/bookingDetail.tpl.html',
          label: 'Booking Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
      LPC_AJS_DIRECTIVE.LIFE_BOOKING_DETAIL,
      downgradeComponent({component: LifeBookingDetailComponent})
    );
  }

  function registerPremiumDetailDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('premiumDetail.tpl.html', premiumDetail))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_PREMIUM_DETAIL, {
          template: 'assets/premiumDetail.tpl.html',
          label: 'Booking Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsales'
        });
      }
    ])
    .directive(
      LPC_AJS_DIRECTIVE.LIFE_PREMIUM_DETAIL,
      downgradeComponent({component: LpcPremiumDetailComponent})
    );
  }

  // pagina dettaglio Piano di investimenti
  function registerInvestmentPlanDetail() {
    angular.module('ptfall-card-finder')
      .run(factory('lifeInvestmentPlanDetail.tpl.html', lifeInvestmentPlanDetail))
      .config([
        'parallelRoutingProvider',
        (pRouter) => {
          pRouter.when('ptfallFinder', JS_ROUTES.LIFE_INVESTMENT_PLAN_DETAIL, {
            template: 'assets/lifeInvestmentPlanDetail.tpl.html',
            label: 'Investment Plan Detail',
            type: 'home',
            controller: 'AngularCommunicationCtrlPostsales'
          });
        }
      ])
      .directive(
        LPC_AJS_DIRECTIVE.LIFE_INVESTMENT_PLAN_DETAIL,
        downgradeComponent({component: LifeInvestmentPlanDetailComponent})
      );
  }
}

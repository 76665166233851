import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {Parameter} from '../../models/domain-models/parameter';
import {UntypedFormGroup} from '@angular/forms';
import {ParameterService} from './parameters.service';
import {PolicyDate} from '../../models/domain-models/parameters/policy-date';
import {Agreement} from '../../models/domain-models/parameters/agreement';
import {TaxType} from '../../models/domain-models/parameters/tax-type';
import {CommissionsRegime} from '../../models/domain-models/parameters/commissions-regime';
import {Commissions} from '../../models/domain-models/parameters/commissions';
import {PaymentFrequency} from '../../models/api-models/api-payment-frequency';
import {Adjustment} from '../../models/domain-models/parameters/adjustement';
import {Constraint} from '../../models/domain-models/parameters/constraint';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {Notes} from '../../models/domain-models/parameters/notes';
import {ProposalService} from '../../proposal.service';
import {NotDrivingDeclaration} from '../../models/domain-models/parameters/notdriving';
import {Subscription} from 'rxjs';
import {Message} from '../../models/message';
import {InsuranceBackgroundService} from '../../insurance-background/insurance-background.service';
import {RiskCertificateService} from '../../risk-certificate/risk-certificate-service';
import {TranslateService} from '@ngx-translate/core';
import {TacitRenewal} from '../../models/domain-models/parameters/tacitRenewal';
import {QuotationService} from '../quotation.service';
import {Observable} from 'rxjs/internal/Observable';
import {PolicyLPSData} from '../../models/domain-models/parameters/policy-lps-data';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {CoassContainer} from '../../models/domain-models/parameters/coass-container';
import {FormsStatusService} from '../../forms-status.service';
import {ApiDisabledComponents} from '../../models/api-models/api-disabled-components';
import {PolicyText} from '../../models/domain-models/policy-text';

@Component({
  selector: 'mic-parameters',
  templateUrl: './parameters.component.html'
})
export class ParametersComponent implements OnInit, OnDestroy {

  parametersForm: UntypedFormGroup;
  parameters: Parameter[] = new Array<Parameter>();
  disabledFields = new Map();
  parametersRows: Parameter[][];
  submitted = false;
  // validationMessages: Message[] = [];
  validationMessages = [];
  nonBlockingMessages = [];
  coinsurancegMessages = [];

  paymentFrequencyValues: PaymentFrequency[] = new Array<PaymentFrequency>();
  agreementValues: Agreement[] = new Array<Agreement>();
  taxTypeValues: TaxType[] = new Array<TaxType>();
  policyEffectiveDate: PolicyDate;
  policyIssueDate: PolicyDate;
  policyExpireDate: PolicyDate;
  policyEffectiveTime: PolicyText;
  policyExpiryTime: PolicyText;
  selectedAgreement: Agreement;
  selectedTaxType: TaxType;
  selectedCommission: Commissions;
  selectedFirstInstalmentExpirationDate: PolicyDate;
  selectedPaymentFrequency: PaymentFrequency;
  commissionsRegime: CommissionsRegime;
  notes: Notes;
  constraint: Constraint;
  instalmentDates: Array<PolicyDate>;
  adjustment: Adjustment;
  tacitRenewal: TacitRenewal;
  policyTechnicalData: PolicyTechnicalData;
  notDrivingDeclaration: NotDrivingDeclaration;
  isTacitRenewalVisible;
  policyLps: boolean;
  policyLpsData: PolicyLPSData;
  coassContainer: CoassContainer;

  areaCode = 'PARAMETERS';

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() recalculatePremium: EventEmitter<any> = new EventEmitter<any>();
  @Output() validationMessagesParameter: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() nonBlockingMessagesParameter: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() reinitializeVariables: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected parametersService: ParameterService,
    protected proposalService: ProposalService,
    protected insuranceStatusService: InsuranceBackgroundService,
    protected riskCertificateService: RiskCertificateService,
    protected translate: TranslateService,
    @Inject('showTacitRenewal') showTacitRenewal: boolean,
    protected quotationService: QuotationService,
    protected formStatusService: FormsStatusService) {
    this.isTacitRenewalVisible = showTacitRenewal;
  }

  ngOnInit() {
    const contractId = this.proposalService.getContractId();
    // this.formStatusService.register(this.isFormsCompleted.bind(this));
    this.parametersService.retrievePolicyTechnicalData(contractId).subscribe(
      (data) => {
        this.parametersService.setPolicyTechnicalData(data);
        this.loadPolicyTechnicalData(data);

        const apiDisabledFields = this.proposalService.getContractDisabledFields();
        if (apiDisabledFields instanceof Observable) {
          apiDisabledFields.subscribe(fields => {
            this.proposalService.setDisabledFields(fields); // disable all fiels if policy_data is enabled=false
            this.manageDisabledFields(fields.disabledComponents.unhandledCase);
          });
        } else {
          this.manageDisabledFields(apiDisabledFields.unhandledCase);
        }

        this.loadParameters();
        this.addErrorMessages(data);

        if (data.errorMessages) {
          data.errorMessages.forEach(
            (errorMsg) => {
              if (errorMsg !== 'PDT_EFFDATE_OUTOFBOUNDS_MIN'
                && errorMsg !== 'PDT_EFFDATE_OUTOFBOUNDS_MAX'
                && errorMsg !== 'PDT_EXPDATE_OUTOFBOUNDS'
                && errorMsg !== 'REQ_NOT_DRIVEN'
                && errorMsg !== 'OVER_COVERAGE'
                && errorMsg !== 'ATR_SET_TO_ND'
                && !errorMsg.startsWith('COVERAGE_OVERLAP')) {
                this.validationMessages.push(new Message(this.areaCode, errorMsg));
              }
            }
          );
        }
        if (data.warningMessages) {
          data.warningMessages.forEach((errorMsg) => {
            this.nonBlockingMessages.push(new Message(this.areaCode, errorMsg));
          });
        }
        if (this.validationMessages.length > 0) {
          this.validationMessagesParameter.emit(this.validationMessages);
        }

        this.nonBlockingMessagesParameter.emit(this.nonBlockingMessages);
      }
    );
  }

  isFormsCompleted() {

    if (this.parametersForm.invalid) {
      return false;
    }
    if (this.coinsurancegMessages && this.coinsurancegMessages.length) {
      return this.coinsurancegMessages[0];
    }
    return true;
  }


  loadPolicyTechnicalData(data: PolicyTechnicalData) {

    this.selectedAgreement = null;
    this.selectedFirstInstalmentExpirationDate = null;
    this.selectedCommission = null;
    this.selectedPaymentFrequency = null;
    this.selectedTaxType = null;

    this.policyTechnicalData = data;

    this.paymentFrequencyValues = data.paymentFrequencyContainer.paymentFrequencyList;
    this.policyIssueDate = data.issueDate;
    this.policyEffectiveDate = data.effectiveDate;
    this.policyExpireDate = data.exipreDate;
    this.policyEffectiveTime = data.policyTime;
    this.policyExpiryTime = data.policyExpiryTime;
    this.agreementValues = data.agreementContainer.agreements;
    this.taxTypeValues = data.taxTypeContainer != null ? data.taxTypeContainer.taxTypeList : new Array<TaxType>();
    this.commissionsRegime = data.commissionsRegime;
    this.notes = data.notes;
    this.constraint = data.constraint;
    this.adjustment = data.adjustment;
    this.tacitRenewal = data.tacitRenewal;
    this.policyLps = data.policyLps;
    this.policyLpsData = data.policyLPSData;
    this.notDrivingDeclaration = data.notDrivingDeclaration;
    this.coassContainer = data.coassContainer;

    for (const taxType of this.taxTypeValues) {
      if (taxType.id === 1) {
        this.selectedTaxType = taxType;
      }
      if (taxType.selected) {
        this.selectedTaxType = taxType;
        break;
      }
    }

    if (this.agreementValues) {
      this.agreementValues.forEach(
        (agreementValue) => {
          if (agreementValue.selected) {
            this.selectedAgreement = agreementValue;
          }
        }
      );
    }

    if (this.commissionsRegime && this.commissionsRegime.commissionList) {
      this.commissionsRegime.commissionList.forEach(
        (commissionRegimeValue) => {
          if (commissionRegimeValue.selected) {
            this.selectedCommission = commissionRegimeValue;
          }
        }
      );
    }

    if (this.paymentFrequencyValues) {
      this.paymentFrequencyValues.forEach(
        (paymentFrequency) => {
          if (paymentFrequency.selected) {
            this.selectedPaymentFrequency = paymentFrequency;
            paymentFrequency.instalmentDates.forEach(
              (instalmentDate) => {
                if (instalmentDate.selected) {
                  this.selectedFirstInstalmentExpirationDate = instalmentDate;
                }
              }
            );
          }
        }
      );
    }
  }

  loadParameters() {

    this.parameters.length = 0;

    this.parameters.push(new Parameter('2', null, 'Effective Date', this.policyEffectiveDate
      ? String(this.policyEffectiveDate.date) : null, 4, null, false
      , !this.policyEffectiveDate.enabled, false));

    if (this.policyEffectiveDate) {
      this.parametersService.setEffectiveDate(this.policyEffectiveDate.date);
    }
    this.parameters.push(new Parameter('3', null, 'Effective Time:',
      this.policyEffectiveTime.value, 6, null, false
      , !this.policyEffectiveTime.enabled, false));
    this.parameters.push(new Parameter('4', null, 'Expiry Date', this.policyExpireDate
      ? String(this.policyExpireDate.date) : null, 4, null, false
      , !this.policyExpireDate.enabled, false));
    this.parameters.push(new Parameter('20', null, 'Policy Expiry Time:', this.policyExpiryTime
        ? String(this.policyExpiryTime.value) : null, 6, null, false
      , !this.policyExpiryTime.enabled, false));
    this.parameters.push(new Parameter('1', null, 'Issue Date', this.policyIssueDate
      ? String(this.policyIssueDate.date) : null, 4, null, false
      , !this.policyIssueDate.enabled, false));
    this.parameters.push(new Parameter('8', null, 'First Instalment Expiration Date:',
      this.selectedFirstInstalmentExpirationDate
        ? String(this.selectedFirstInstalmentExpirationDate.date) : null, 0, null, false
      , !this.policyTechnicalData.paymentFrequencyContainer.enabled, false));
    this.parameters.push(new Parameter('5', null, 'Payment Frequency:',
      this.selectedPaymentFrequency ? String(this.selectedPaymentFrequency.id) : null, 0,
      this.paymentFrequencyValues,
      this.policyTechnicalData.paymentFrequencyContainer.compulsory, !this.policyTechnicalData.paymentFrequencyContainer.enabled
      , false));
    if (this.tacitRenewal && this.isTacitRenewalVisible) {
      this.parameters.push(new Parameter('14', null, 'Tacit Renewal',
        String(this.tacitRenewal.tacitRenewal), 3, null,
        false, !this.tacitRenewal.enabled, false));
    }
    if (this.policyTechnicalData.taxTypeContainer != null) {
      this.parameters.push(new Parameter('7', null, 'Tax Category:', this.selectedTaxType
        ? String(this.selectedTaxType.id) : null, 0, this.taxTypeValues,
        this.policyTechnicalData.taxTypeContainer.compulsory, !this.policyTechnicalData.taxTypeContainer.enabled
        , false));
    }
    if (this.agreementValues && this.agreementValues.length > 0) {
      this.parameters.push(new Parameter('6', null, 'Agreement:', this.selectedAgreement
        ? String(this.selectedAgreement.idAgreement) : null, 0, this.agreementValues,
        this.policyTechnicalData.agreementContainer.compulsory, !this.policyTechnicalData.agreementContainer.enabled
        , false));
    }
    if (this.constraint && this.constraint.visible) {
      this.parameters.push(new Parameter('11', null, 'Constraint',
        String(this.constraint.constraint), 3, null,
        false, !this.constraint.enabled, false));
    }
    if (this.commissionsRegime && this.commissionsRegime.visible && this.commissionsRegime.commissionList
      && this.commissionsRegime.commissionList.length > 0) {
      this.parameters.push(new Parameter('9', null, 'Commissions Regime:',
        this.selectedCommission ? String(this.selectedCommission.id) : null, 0,
        this.commissionsRegime ? this.commissionsRegime.commissionList : null,
        this.commissionsRegime.compulsory, false, false));
    }
    this.parameters.push(new Parameter('12', null, 'Notes:',
      this.notes.text, 5, null, false, false, false));



    if (this.notDrivingDeclaration && this.notDrivingDeclaration.visible) {
      this.parameters.push(new Parameter('13', null, 'Not Driving Declaration',
        String(this.notDrivingDeclaration.selected), 3, null,
        false, false, false));
    }

    if (this.policyLps) {
      const lpsCountrySelected = this.policyLpsData.lpsCountries.find(value => value.selected);
      this.parameters.push(new Parameter('15', null, 'EU country',
        lpsCountrySelected ? String(lpsCountrySelected.id) : null, 0,
        this.policyLpsData.lpsCountries,
        false, false, false));

      this.parameters.push(new Parameter('16', null, 'Extra EU Country',
        this.policyLpsData.extraUeCountry.description, 5, null,
        false, this.policyLpsData.extraUeCountry.disabled, false));

      this.parameters.push(new Parameter('17', null, 'Currency',
        this.policyLpsData.currency, 5, null,
        false, false, false));

      this.parameters.push(new Parameter('18', null, 'Change date',
        String(this.policyLpsData.exchangeDate), 4, null,
        false, false, false));

      this.parameters.push(new Parameter('19', null, 'Exchange Rate €',
        String(this.policyLpsData.exchangeRate), 1, null,
        false, false, false));
    }

    this.parametersService.setPaymentFrequencyValues(this.paymentFrequencyValues);

    this.parametersForm = this.parametersService.toFormGroup(this.parameters);

    this.parameters.forEach(param => {
      if (this.parametersForm.get(param.id).errors
        && !this.parametersForm.get(param.id).errors.required) {
        this.parametersForm.get(param.id).setErrors(null);
      } else if (this.parametersForm.get(param.id).errors
        && this.parametersForm.get(param.id).errors.required) {
        this.parametersForm.get(param.id).markAsDirty();
      }
    });

    this.parameters.forEach(param => {
      const parameterValueChangesSubscription = this.parametersForm.get(param.id).valueChanges.subscribe(
        (data) => {
          if (this.parametersForm.get(param.id).errors
            && !this.parametersForm.get(param.id).errors.required) {
            this.parametersForm.get(param.id).setErrors(null);
          }
        }
      );
      this.subscriptions.add(parameterValueChangesSubscription);
    });

    if (this.parametersForm.invalid) {
      this.validationMessages.push('It is necessary to provide all the mandatory Parameters');
      this.validationMessagesParameter.emit(this.validationMessages);
    }

    const parametersNum = this.parameters.length;

    if (parametersNum === 0) {
      return;
    }

    this.parametersRows = new Array<Array<Parameter>>(Math.ceil(parametersNum / 2));

    let i: number;
    let j = 0;

    for (i = 0; i < parametersNum; i++) {
      if (i !== 0 && i % 2 === 0) {
        j++;
      }
      if (!this.parametersRows[j]) {
        this.parametersRows[j] = [];
      }
      this.parametersRows[j].push(this.parameters[i]);
    }

    this.disabledFields.forEach(field => {
      if (field && field.id && field.disabled && this.parametersForm.get(field.id)) {
        this.parametersForm.get(field.id).disable();
      }
    });
  }

  onUpdateParameter(parameterId: string) {

    if (!parameterId) {
      if (this.validationMessages.length === 0) {
        this.validationMessages.push('It is necessary to provide all the mandatory Parameters');
        this.validationMessagesParameter.emit(this.validationMessages);
      }
      return;
    } else {
      this.validationMessages.length = 0;
      this.validationMessagesParameter.emit(this.validationMessages);
    }

    this.validationMessages.length = 0;
    this.nonBlockingMessages.length = 0;

    const contractId = this.proposalService.getContractId();
    const policyTechnicalData = this.policyTechnicalData;
    const newValue: string = this.parametersForm.controls[parameterId].value;

    Object.keys(this.parametersForm.controls).forEach((fieldName) => {
      if (this.parametersForm.get(fieldName).errors && this.parametersForm.get(fieldName).errors.ngbDate) {
        this.parametersForm.get(fieldName).setErrors(null);
      }
    });

    switch (parameterId) {
      case '1': {
        const oldValue = policyTechnicalData.issueDate;
        policyTechnicalData.issueDate = new PolicyDate(
          new Date(newValue),
          oldValue.maxDate,
          oldValue.minDate,
          oldValue.modifiable,
          oldValue.enabled);
        break;
      }
      case '2': {
        const oldValue = policyTechnicalData.effectiveDate;
        policyTechnicalData.effectiveDate = new PolicyDate(
          new Date(newValue),
          oldValue.maxDate,
          oldValue.minDate,
          oldValue.modifiable,
          oldValue.enabled);
        break;
      }
      case '3': {
        policyTechnicalData.policyTime.value = newValue;
        break;
      }
      case '4': {
        const oldValue = policyTechnicalData.exipreDate;
        policyTechnicalData.exipreDate = new PolicyDate(
          new Date(newValue),
          oldValue.maxDate,
          oldValue.minDate,
          oldValue.modifiable,
          oldValue.enabled);
        break;
      }
      case '6': {
        policyTechnicalData.agreementContainer.agreements.forEach((agreement) => {
          if (!newValue) {
            agreement.selected = false;
          } else {
            agreement.selected = agreement.idAgreement === Number(newValue);
          }
        });
        break;
      }
      case '5': {
        policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach((paymentFrequency) => {
          if (!newValue) {
            paymentFrequency.selected = false;
          } else {
            paymentFrequency.selected = paymentFrequency.id === Number(newValue);
          }
        });
        break;
      }
      case '7': {
        policyTechnicalData.taxTypeContainer.taxTypeList.forEach((taxType) => {
          if (!newValue) {
            taxType.selected = false;
          } else {
            taxType.selected = taxType.id === Number(newValue);
          }
        });
        break;
      }
      case '8': {
        const formattedDate = new Date(newValue);
        policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach(
          (paymentFrequency) => {
            if (paymentFrequency.selected) {
              paymentFrequency.selected = true;
              paymentFrequency.instalmentDates.forEach((instalmentDate) => {
                const instDateFormatted = new Date(instalmentDate.date);
                if (!newValue) {
                  instalmentDate.selected = false;
                } else {
                  instalmentDate.selected = instDateFormatted.getTime() === formattedDate.getTime();
                }
              });
            }
          });
        break;
      }
      case '9': {
        policyTechnicalData.commissionsRegime.commissionList.forEach((commission) => {
          if (!newValue) {
            commission.selected = false;
          } else {
            commission.selected = commission.id === Number(newValue);
          }
        });
        break;
      }
      case '10': {
        policyTechnicalData.adjustment.adjustment = newValue === 'true';
        policyTechnicalData.contractAdjustment = newValue === 'true';
        break;
      }
      case '11': {
        policyTechnicalData.constraint.constraint = newValue === 'true';
        policyTechnicalData.contractContraint = newValue === 'true';
        break;
      }
      case '12': {
        if (!policyTechnicalData.notes) {
          policyTechnicalData.notes = new Notes(newValue, true);
        } else {
          policyTechnicalData.notes.text = newValue;
        }
        break;
      }
      case '13': {
        policyTechnicalData.notDrivingDeclaration.previousValue = policyTechnicalData.notDrivingDeclaration.selected;
        policyTechnicalData.notDrivingDeclaration.selected = newValue === 'true';
        policyTechnicalData.contractNotDrivingDeclaration = newValue === 'true';
        break;
      }
      case '14': {
        policyTechnicalData.tacitRenewal.tacitRenewal = newValue === 'true';
        break;
      }
      case '15': {
        policyTechnicalData.policyLPSData.lpsCountries.forEach((country) => {
          if (!newValue) {
            country.selected = false;
          } else {
            country.selected = String(country.id) === newValue;
          }
        });
        break;
      }
      case '16': {
        policyTechnicalData.policyLPSData.extraUeCountry.description = newValue;
        break;
      }
      case '17': {
        policyTechnicalData.policyLPSData.currency = newValue;
        break;
      }
      case '18': {
        policyTechnicalData.policyLPSData.exchangeDate = new Date(newValue);
        break;
      }
      case '19': {
        policyTechnicalData.policyLPSData.exchangeRate = Number(newValue);
        break;
      }
      default: {
        break;
      }
    }

    this.parametersService.updatePolicyTechnicalData(contractId, policyTechnicalData).subscribe(
      (data) => {
        // this.reinitializeVariables.emit();
        this.parametersService.setPolicyTechnicalData(data);
        this.loadPolicyTechnicalData(data);
        this.loadParameters();
        const today = new Date();

        this.validationMessages.length = 0;
        this.nonBlockingMessages.length = 0;

        this.addErrorMessages(data);

        if (data.errorMessages) {
          data.errorMessages.forEach(
            (errorMsg) => {
              if (errorMsg !== 'PDT_EFFDATE_OUTOFBOUNDS_MIN'
                && errorMsg !== 'PDT_EFFDATE_OUTOFBOUNDS_MAX'
                && errorMsg !== 'PDT_EXPDATE_OUTOFBOUNDS'
                && errorMsg !== 'REQ_NOT_DRIVEN'
                && errorMsg !== 'OVER_COVERAGE'
                && errorMsg !== 'ATR_SET_TO_ND'
                && !errorMsg.startsWith('COVERAGE_OVERLAP')) {
                this.validationMessages.push(new Message(this.areaCode, errorMsg));
              }
            }
          );
        }

        if (!data.errorMessages || data.errorMessages.length === 0) {
          this.parametersForm.controls['2'].setErrors(null);
          this.parametersForm.controls['2'].updateValueAndValidity();
          this.parametersForm.controls['4'].setErrors(null);
          this.parametersForm.controls['4'].updateValueAndValidity();
        }


        if (data.reloadInsuranceStatus && !data.recalculate) {
          this.insuranceStatusService.sendRecalculateInsuranceStatusSignal();
          this.riskCertificateService.sendRecalculateInsuranceStatusSignal();
        } else if (data.reloadInsuranceStatus && data.recalculate) {
          this.quotationService.setRefreshInsuranceStatusToken();
        }

        if (data.recalculate) {
          this.recalculatePremium.emit();
        } else {
          this.reinitializeVariables.emit();
        }

        if (this.validationMessages.length > 0) {
          this.validationMessagesParameter.emit(this.validationMessages);
        }

        this.nonBlockingMessagesParameter.emit(this.nonBlockingMessages);

      },
      err => {
        this.parametersService.retrievePolicyTechnicalData(contractId).subscribe(
          (data) => {
            this.parametersService.setPolicyTechnicalData(data);
            this.loadPolicyTechnicalData(data);
            this.loadParameters();
            this.nonBlockingMessages.length = 0;
            this.addErrorMessages(data);
            if (data.warningMessages) {
              data.warningMessages.forEach((errorMsg) => {
                this.nonBlockingMessages.push(new Message(this.areaCode, errorMsg));
              });
            }
            if (this.validationMessages.length > 0) {
              this.validationMessagesParameter.emit(this.validationMessages);
            }

            this.nonBlockingMessagesParameter.emit(this.nonBlockingMessages);
          }
        );
      });
  }

  manageDisabledFields(unhandledCase: boolean) {
    if (!unhandledCase) {
      const bEffectiveDate = this.proposalService.isFieldEnabled('effectiveDate');
      const bPolicyTime = this.proposalService.isFieldEnabled('policyTime');
      const bExpireDate = this.proposalService.isFieldEnabled('exipreDate'); // occhio a expire exipre
      const bPaymentFrequency = this.proposalService.isFieldEnabled('paymentFrequency');
      const bAgreement = this.proposalService.isFieldEnabled('agreement');
      const bTaxCategory = this.proposalService.isFieldEnabled('taxCategory');
      const bParamNotes = this.proposalService.isFieldEnabled('paramNotes');
      const bOutOfMagazine = this.proposalService.isFieldEnabled('outOfMagazine');
      const bUpdatableInsValue = this.proposalService.isFieldEnabled('updatableInsValue');
      const bConstraint = this.proposalService.isFieldEnabled('constraint');
      const bTacitRenewal = this.proposalService.isFieldEnabled('tacitRenewal');
      const bBirstInstalmentExpirationDate = this.proposalService.isFieldEnabled('firstInstalmentExpirationDate');

      if (bEffectiveDate !== null) {
        this.disabledFields.set('effectiveDate', {
          id: '2',
          disabled: !bEffectiveDate
        });
      } else {
        this.setDisabledBySectionConfig('effectiveDate', '2');
      }
      if (bPolicyTime !== null) {
        this.disabledFields.set('policyTime', { id: '3', disabled: !bPolicyTime });
      } else {
        this.setDisabledBySectionConfig('policyTime', '3');
      }
      if (bExpireDate !== null) {
        this.disabledFields.set('expireDate', { id: '4', disabled: !bExpireDate });
      } else {
        this.setDisabledBySectionConfig('expireDate', '4');
      }
      if (bPaymentFrequency !== null) {
        this.disabledFields.set('paymentFrequency', {
          id: '5',
          disabled: !bPaymentFrequency
        });
      } else {
        this.setDisabledBySectionConfig('paymentFrequency', '5');
      }
      if (bAgreement !== null) {
        this.disabledFields.set('agreement', { id: '6', disabled: !bAgreement });
      } else {
        this.setDisabledBySectionConfig('agreement', '6');
      }
      if (bTaxCategory !== null) {
        this.disabledFields.set('taxCategory', { id: '7', disabled: !bTaxCategory });
      } else {
        this.setDisabledBySectionConfig('taxCategory', '7');
      }
      if (bParamNotes !== null) {
        this.disabledFields.set('paramNotes', { id: '12', disabled: !bParamNotes });
      } else {
        this.setDisabledBySectionConfig('paramNotes', '12');
      }
      if (bOutOfMagazine !== null) {
        this.disabledFields.set('outOfMagazine', {
          id: null,
          disabled: !bOutOfMagazine
        });
      } else {
        this.setDisabledBySectionConfig('outOfMagazine', null);
      }
      if (bUpdatableInsValue !== null) {
        this.disabledFields.set('updatableInsValue', {
          id: null,
          disabled: !bUpdatableInsValue
        });
      } else {
        this.setDisabledBySectionConfig('updatableInsValue', null);
      }
      if (bConstraint !== null) {
        this.disabledFields.set('constraint', { id: '11', disabled: !bConstraint });
      } else {
        this.setDisabledBySectionConfig('constraint', '11');
      }
      if (bTacitRenewal !== null) {
        this.disabledFields.set('tacitRenewal', { id: '14', disabled: !bTacitRenewal });
      } else {
        this.setDisabledBySectionConfig('tacitRenewal', '14');
      }
      if (bBirstInstalmentExpirationDate !== null) {
        this.disabledFields.set('tacitRenewal', { id: '14', disabled: !bBirstInstalmentExpirationDate });
      } else {
        this.setDisabledBySectionConfig('bBirstInstalmentExpirationDate', '8');
      }
    }
  }

  coinsuranceErrorNotificator($event) {
    this.coinsurancegMessages.length = 0;
    if ($event) {
      this.coinsurancegMessages.push($event);
    }
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private addErrorMessages(data) {

    const today = new Date();

    if (data.errorMessages && data.errorMessages.includes('REQ_NOT_DRIVEN')
      && !data.errorMessages.includes('ATR_SET_TO_ND')) {
      const message1 = 'Effective date is ';
      const message2 = ' days greater than ATR expiration date. Check the specific Declaration';
      if (this.notDrivingDeclaration.visible) {
        this.nonBlockingMessages.push(new Message(this.areaCode, this.translate.instant(message1)
          + data.arrearsDays
          + this.translate.instant(message2)));
      }

    }
    if (data.errorMessages) {
      if (data.errorMessages.includes('OVER_COVERAGE')) {
        this.nonBlockingMessages.push(new Message(this.areaCode, 'Found a coverage. The certificate has been reset'));
      }
      if (data.errorMessages.includes('ATR_SET_TO_ND')) {
        this.nonBlockingMessages.push(new Message(this.areaCode, 'The Vehicle has been driven. The certificate has been reset'));
      }

      const coverageOverlapMsg = data.errorMessages.find((msg) => msg.startsWith('COVERAGE_OVERLAP'));
      if (coverageOverlapMsg) {
        this.nonBlockingMessages.push(new Message(this.areaCode, coverageOverlapMsg.replace('COVERAGE_OVERLAP|', '')));
      }
    }

    this.updateInsuranceStatusCompletenessErrors(data);

    if (data.errorMessages && data.errorMessages.includes('PDT_EFFDATE_OUTOFBOUNDS_MIN')) {
      const retrDays = this.getRetrDays(today, data.effectiveDate.minDate);
      this.validationMessages.push(new Message(this.areaCode,
        this.translate.instant('Effective Date cannot be dated back more than {{days}} from today', { days: retrDays })));
      this.parametersForm.controls['2'].setErrors({ outOfBoundDate: true });
      this.parametersForm.controls['2'].markAsDirty();
    }
    if (data.errorMessages && data.errorMessages.includes('PDT_EFFDATE_OUTOFBOUNDS_MAX')) {
      const anteDays = this.getAnteDays(today, data.effectiveDate.maxDate);
      this.validationMessages.push(new Message(this.areaCode,
        this.translate.instant('Effective Date cannot be postdated more than {{days}} from today', { days: anteDays })));
      this.parametersForm.controls['2'].setErrors({ outOfBoundDate: true });
      this.parametersForm.controls['2'].markAsDirty();
    }
    if (data.errorMessages && data.errorMessages.includes('PDT_EXPDATE_OUTOFBOUNDS')) {
      this.validationMessages.push(new Message(this.areaCode, 'Expiration Date is not valid'));
      this.parametersForm.controls['4'].setErrors({ outOfBoundDate: true });
      this.parametersForm.controls['4'].markAsDirty();
    }
    if (this.coassContainer && this.coassContainer.config.ourCoassPercentageRequired) {
      // controllo il valore di this.coassContainer.ourCoassPercentage,
      // ma solo se  prevista la coass (this.coassContainer.config.ourCoassPercentageRequired)
      if (this.coassContainer.ourCoassPercentage !== null) {
        if (this.coassContainer.ourCoassPercentage <= 0 || this.coassContainer.ourCoassPercentage >= 100) {
          this.validationMessages.push(new Message(this.areaCode,
            'La percentuale nostra (' + this.coassContainer.ourCoassPercentage +
            '%) non puo\' essere minore o uguale a 0% oppure maggiore o uguale a 100%'));
        }
      } else {
        this.validationMessages.push(new Message(this.areaCode, 'La percentuale nostra e\' un campo obbligatorio'));
      }

      // se "delega nostra" controllo che il totale delle percentuali (this.coassContainer.config.policyLevelDelegation) sia 100%
      if (this.coassContainer.config.policyLevelDelegation) {
        if (this.coassContainer.totalPercentage && this.coassContainer.totalPercentage > 100) {
          this.validationMessages.push(new Message(this.areaCode,
            // this.translate.instant('The total percentage of the policy ({{totPerc}}%) is greater than that allowed (100%)',
            //   {totPerc: this.coassContainer.totalPercentage})));
            'La percentuale totale della polizza (' + this.coassContainer.totalPercentage + '%) e\' maggiore di quanto permesso (100%)'));
        } else if (this.coassContainer.totalPercentage && this.coassContainer.totalPercentage < 100) {

          this.validationMessages.push(new Message(this.areaCode,
            // this.translate.instant('The total percentage of the policy ({{totPerc}}%) is lower than that allowed (100%)',
            //   { totPerc: this.coassContainer.totalPercentage })));
            'La percentuale totale della polizza (' + this.coassContainer.totalPercentage + '%) e\' minore di quanto permesso (100%)'));
        }
      } else {
        // se "delega altrui" devo verificare che la percentuale totale sia > 0% e minore o uguale a
        // 100% e che la percentuale del delegatario non sia nulla
        if (this.coassContainer.ourCoassPercentage !== null) {
          if (this.coassContainer.ourCoassPercentage === this.coassContainer.totalPercentage) {
            this.validationMessages.push(new Message(this.areaCode,
              'La percentuale del coassicuratore delegatario non puo\' essere nulla'));
          } else if (this.coassContainer.totalPercentage <= 0 || this.coassContainer.totalPercentage > 100) {
            this.validationMessages.push(new Message(this.areaCode,
              'La percentuale totale della polizza (' + this.coassContainer.totalPercentage +
              '%) deve essere maggiore di 0% e minore di quanto permesso (100%)'));
          }
        }

      }
    }

    if (this.nonBlockingMessages.length > 0) {
      this.nonBlockingMessagesParameter.emit(this.nonBlockingMessages);
    }
    if (this.validationMessages.length > 0) {
      this.validationMessagesParameter.emit(this.validationMessages);
    }
  }

  protected getRetrDays(today: any, minDate: any) {
    Math.round((today.getTime() - minDate) / (24 * 60 * 60 * 1000));
  }

  protected getAnteDays(today: any, maxDate: any) {
    Math.round((maxDate - today.getTime()) / (24 * 60 * 60 * 1000));
  }

  private updateInsuranceStatusCompletenessErrors(data) {
    const coverageOverlapCode = 'COVERAGE_OVERLAP';
    let completenessErrors = this.proposalService.getApiContract().completenessErrors;
    if (!completenessErrors) {
      completenessErrors = [];
    }
    if (data.errorMessages && data.errorMessages.some(msg => msg.startsWith(coverageOverlapCode))) {
      if (!completenessErrors.some(msg => msg.code === coverageOverlapCode)) {
        const coverageOverlapMsg = data.errorMessages.find((msg) => msg.startsWith(coverageOverlapCode));
        completenessErrors.push(new GenericEntity(null, coverageOverlapCode, coverageOverlapMsg.replace('COVERAGE_OVERLAP|', '')));
      }
    } else {
      const index = completenessErrors.findIndex((mess) => {
        return mess.code === coverageOverlapCode;
      });
      if (index !== -1) {
        completenessErrors.splice(index, 1);
      }
    }
    this.proposalService.setCompletenessErrors(completenessErrors);
  }

  protected setDisabledBySectionConfig(fieldName, fieldId) {
    this.disabledFields.set(fieldName, {
      id: fieldId,
      disabled: !this.isSectionEditable(ApiDisabledComponents.SECTION_POLICY_DATA)
    });
  }

  isSectionEditable(sectionKey) {
    return this.proposalService.isFieldEnabled(sectionKey) === false ? false : true;
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lpc-status-handler',
  templateUrl: './status-handler.component.html',
  styleUrls: ['./status-handler.component.css']
})
export class StatusHandlerComponent implements OnInit {

  @Input() statusCode: string;
  @Input() statusName: string;

  constructor() { }

  ngOnInit() {
  }

}

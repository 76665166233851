import { LicTranslationsKeys } from './lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_DE: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: '',
  lic_ProposalCorrectlyIssued: '',
  lic_LifeProposalCorrectlyIssued: '',
  lic_LifePolicyCorrectlyIssued: '',
  lic_DamagesPolicyCorrectlyIssued: '',
  lic_AuthorizatioProposalForwarded: '',
  lic_ProposalCorrectlySaved: '',
  lic_PreventiveCorrectlySaved: '',
  lic_benMorteNoAssic: '',
  lic_benMorteNoRef: '',
  lic_benefPresente: '',
  lic_refTerzoNoAssic: '',
  lic_refTerzoNoContr: '',
  lic_refTerzoPresente: '',
  lic_DelegateEqualToPolicyHolder: '',
  lic_LegalGuardianEqualToPolicyHolder: '',
  lic_ThirdPayerEqualToPolicyHolder: '',
  lic_DelegateEqualToPolicyHolderWarning: '',
  lic_LegalGuardianEqualToPolicyHolderWarning: '',
  lic_ThirdPayerEqualToPolicyHolderWarning: '',
  lic_MasterPolicyHolder: '',
  lic_SubjectXalreadyselectedfortherole: '',
  lic_InsuredAmount: '',
  lic_InvestedPremium: '',
  lic_FirstInstalment: '',
  lic_InsuredNotEqualHolder: '',
  lic_SaveEstimate: '',
  lic_LifeQuote: '',
  lic_PreventiveIssue: '',
  lic_ProposalIssue: '',
  lic_PolicyIssue: '',
  lic_appendixLabel: '',
  lic_summary_save_and_accept: '',
  lic_summary_save_accept_pol_issue: '',
  lic_summary_save_accept_prop_issue: '',
  lic_effectivebeneficiaryholder: '',
  lic_LifePolicy: '',
  lic_DamagesPolicy: '',
  lic_view: '',
  lic_premium_detail: '',
  lic_guarantee_summary: '',
  lic_commissions: '',
  lic_Parties: '',
  lic_Party: '',
  lic_Employer: '',
  lic_SpecificPIPdata: '',
  lic_prev_continue: '',
  lic_Transferdata: '',
  lic_Selectatleastonecontribution: '',
  lic_Globalpercentagemustbe100: '',
  lic_Selectcontributionsmandatoryfields: '',
  lic_Contributiondata: '',
  lic_Globalamountmustbemajorthan0: '',
  lic_Everycontributionselectedmustbemajorthan0: '',
  lic_error_mandatory_fields: '',
  lic_error_iban: '',
  lic_authorization_request: '',
  lic_selected_profiles_: '',
  lic_Disinvestmentsfunds: '',
  lic_reg41_comunication: '',
  lic_disinvestment_amount: '',
  lic_occurrenceNumber: '',
  lic_TheoccurenceNumbermustbeavalue: '',
  lic_Disinvestmentrange: '',
  lic_Incompleteinvestmentforthefunds: '',
  lic_Incompleteinvestmentfortheprofiles: '',
  lic_Settlementstoreinvest: '',
  lic_Policynumber: '',
  lic_Type: '',
  lic_Beneficiary: '',
  lic_State: '',
  lic_Net: '',
  lic_Nettoreinvest: '',
  lic_Reinvestmentmustbelargerthanquotation: '',
  lic_Mustselectatleastonesettlementtoreinvest: '',
  lic_Nettoreinvestmustbeequalorlessthannet: '',
  lic_Nosettlementstoreinvestexist: '',
  lic_Missingvalueonnettoreinvest: '',
  lic_information: '',
  lic_Percentage: '',
  lic_Durationin: '',
  lic_years: '',
  lic_months: '',
  lic_days: '',
  lic_Complementary: '',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: '',
  lic_Netavailable: '',
  lic_Proposal_Saved: '',
  lic_add_employer: '',
  lic_AvailableGuarantees: '',
  lic_error: '',
  lic_error_msg: '',
  lic_auth_error_msg: '',
  lic_common_risk: '',
  lic_Sustainability: '',
  lic_assignment_holder: '',
  lic_ecoSustainabilityAmount: '',
  lic_ecoSustainabilityPerc: '',
  lic_envSustainabilityAmount: '',
  lic_envSustainabilityPerc: '',
  lic_paiAmount: '',
  lic_paiPerc: '',
  lic_socialSustainabilityAmount: '',
  lic_socialSustainabilityPerc: '',
  lic_benefSevereDisability: '',
  lic_SecondInsured: '',
  lic_Secondinsuredmustbeaphysicalperson: '',
  lic_Selectasecondinsured: '',
  lic_Aderente: '',
  lic_NumeroProposta: '',
  lic_DescrizioneProdotto: '',
  lic_DescrizioneRischio: '',
  lic_CapitaleAssicurato: '',
  lic_SELEZIONA: '',
  lic_PrNetto: '',
  lic_ImpAcq: '',
  lic_profileInvestmentIncomplete: '',
  lic_fundsInvestmentIncomplete: '',
  lic_Clauses: '',
  lic_quote_type: '',
  lic_quote_type_anonymous: '',
  lic_Policyholder: '',
  lic_quote_type_registered: '',
  lic_text_required: '',
  lic_text_required_error: '',
  lic_close: '',
  lic_save: '',
  lic_NEXT: '',
  lic_policyData: '',
  lic_pipData: '',
  lic_parties: '',
  lic_assets: '',
  lic_quotation: '',
  lic_summary: '',
  lic_issue: '',
  lic_investments: '',
  lic_invest_value_range: '',
  lic_disinvestment_range: '',
  lic_mandatory_threshold_perc: '',
  lic_mandatory_one_fund: '',
  lic_invest_factor_min_validation: '',
  lic_invest_factor_max_validation: '',
  lic_invest_factor_eq_validation: '',
  lic_calculate_asset_volatility: '',
  lic_calculate_sustainability_asset: '',
  lic_occurance_number_range: '',
  lic_policy_holder: '',
  lic_guarantee: '',
  lic_co_applicant: '',
  lic_co_applicant_not_same_holder: '',
  lic_co_applicant_physical: '',
  lic_co_applicant_mandatory: '',
  lic_insured_must_be_physical: '',
  lic_mandatory_product: '',
  lic_mandatory_insured: '',
  lic_mandatory_holder: '',
  lic_contribution_frequency: '',
  lic_mandatory_benef: '',
  lic_mandatory_appendix_benef: '',
  lic_add_benef: '',
  lic_add_benef_perc: '',
  lic_benef_perc_range: '',
  lic_benef_total_perc: '',
  lic_guarantee_mandatory_data: '',
  lic_quotation_not_done: '',
  lic_quotation_modified_value_quote: '',
  lic_quotation_benef_custom_msg: '',
  lic_product: '',
  lic_managementNode: '',
  lic_branch: '',
  lic_agreement: '',
  lic_effect_date: '',
  lic_exp_date: '',
  lic_payment_frequency: '',
  lic_means_of_payment: '',
  lic_unit_type: '',
  lic_risk_type: '',
  lic_substate: '',
  lic_benefit: '',
  lic_decreasing_frequency: '',
  lic_damages: '',
  lic_effective_holder: '',
  lic_insured_role: '',
  lic_third_contact_person: '',
  lic_quotation_benef_owner_perc_validation: '',
  lic_subscriber: '',
  lic_yes: '',
  lic_no: '',
  lic_new_insured: '',
  lic_simple_select: '',
  lic_first_instalment: '',
  lic_next_instalment_premium: '',
  lic_payment_type: '',
  lic_first_instalment_payment_type: '',
  lic_annual_premium: '',
  lic_quote_button: '',
  lic_quote_save: '',
  lic_purchasing_commission: '',
  lic_beneficiaries_label: '',
  lic_general_data_label: '',
  lic_contractual_option_not_allowed: '',
  lic_investment_value_label: '',
  lic_investment_profile: '',
  lic_investment_funds: '',
  lic_calculate_finantial_parameters: '',
  lic_confirm_button: '',
  lic_cancel_button: '',
  lic_save_button: '',
  lic_continue_button: '',
  lic_next_button: '',
  lic_required: '',
  lic_node: '',
  lic_company: '',
  lic_product_type: '',
  lic_taxes_label: '',
  lic_fees_label: '',
  lic_frequency_interests: '',
  lic_frequency: '',
  lic_accessories: '',
  lic_taxable: '',
  lic_quotation_detail_modal: '',
  lic_guarantees_overview_modal: '',
  lic_gross_label: '',
  lic_int_fract_label: '',
  lic_next_instalment_label: '',
  lic_pure_premium_label: '',
  lic_extra_premium_label: '',
  lic_loadings_label: '',
  lic_total_label: '',
  lic_beneficiary_perc_is_over: '',
  lic_questionSTR_invalid: '',
  lic_purchase_commissions: '',
  lic_sales_commissions: '',
  lic_IBAN_formally_incorrect: '',
  lic_holder_physical_person_req: '',
  lic_holder_legal_person_req: '',
  lic_available_covers: '',
  lic_covers_overview: '',
  lic_covers: '',
  lic_cover: '',
  lic_config_profile_warning: '',
  lic_select: '',
  lic_Irrevocable_beneficiary: '',
  lic_Base: '',
  lic_Accessory: '',
  lic_investment_value: '',
  lic_volatility: '',
  lic_name: '',
  lic_to_be_invested: '',
  lic_info_investment_completed: '',
  lic_fund: '',
  lic_max_percentage: '',
  lic_min_percentage: '',
  lic_selected_funds: '',
  lic_allocation_exceeds: '',
  lic_percentage_threshold: '',
  lic_fund_distribution_identical: '',
  lic_fund_not_available: '',
  lic_details: '',
  lic_starting_fund: '',
  lic_fund_category: '',
  lic_equivalent: '',
  lic_policy_address: '',
  lic_policy_master_details: '',
  lic_administrative_data: '',
  lic_ordinary_mail: '',
  lic_threshold: '',
  lic_payment_method: '',
  lic_additional_payment: '',
  lic_contractual_options: '',
  lic_elements: '',
  lic_position_number: '',
  lic_questionnaires_section: '',
  lic_fees: '',
  lic__1TERM: '',
  lic__EFFOP: '',
  lic__OPZFR: '',
  lic__OPZPM: '',
  lic_expirationDate: '',
  lic__OPBEN: '',
  lic__MINTS: '',
  lic__MAXTS: '',
  lic__NUMOC: '',
  lic__AMTOP: '',
  lic__MALMT: '',
  lic_amount: '',
  lic_currency: '',
  lic__CIBAN: '',
  lic_fixedFees: '',
  lic__PCOUP: '',
  lic_holder: '',
  lic_ACTIVE_STATE: '',
  lic_MANDATORY_STATE: '',
  lic_DEACTIVATE_STATE: '',
  lic_premium_label: '',
  lic_holders_title: '',
  lic_beneficiary_qualification: '',
  lic_invalid_total_position_percentage: '',
  lic_beneficiary_position_mandatory: '',
  lic_role_is_mandatory: '',
  lic_effective_holder_duplicated: '',
  lic_beneficiary_perc_req_range: '',
  lic_beneficiary_perc_req: '',
  lic_beneficiary_from_party_req: '',
  lic_effective_holder_perc: '',
  lic_effective_holder_perc_req: '',
  lic_or_junction: '',
  lic_backdating_not_allowed: ''
};
export default LIC_DE ;

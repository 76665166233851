export class Formats {
    public static NUMBER_FORMATS = {
        CURRENCY_SYM: '$',
        DECIMAL_SEP: '.',
        GROUP_SEP: ',',
        PATTERNS: [
            {
                gSize: 3,
                lgSize: 3,
                maxFrac: 3,
                minFrac: 0,
                minInt: 1,
                negPre: '-',
                negSuf: '',
                posPre: '',
                posSuf: ''
            },
            {
                gSize: 3,
                lgSize: 3,
                maxFrac: 2,
                minFrac: 2,
                minInt: 1,
                negPre: '-\u00a4',
                negSuf: '',
                posPre: '\u00a4',
                posSuf: ''
            }
        ]
    };
}

import {AdapterprintTranslationsKeys} from './adapterprint-translations-keys';
export const ADAPTERPRINT_FR: AdapterprintTranslationsKeys = {
  _START_ADAPTERPRINT_: 'Adaptateur d\'impression ',
  _RESULT_ADAPTERPRINT_: 'Liste d\'impression des adaptateurs',
  _ADAPTERPRINT_: {
    _BTN_: {
      _BACK_: 'Précédent',
      _EMPTY_: 'Vide',
      _FIND_: 'RECHERCHER',
      _UNDO_: 'Annuler'
    },
    _LABEL_: {
      _ACTION_: 'Action',
      _ADDRESS_: 'Adresse',
      _LOT_: 'Lot',
      _CODE_: 'Code',
      _DATE_SENT_: 'Date d\'envoi',
      _DATE_TO_: 'Date à',
      _DESCRIPTION_: 'Description',
      _DOCUMENT_TYPE_: 'Type de document',
      _MANAGEMENT_NODE_: 'Nœud de gestion',
      _NODE_: 'Nœud',
      _NODE_CODE_: 'Code du nœud',
      _NODE_DESCRIPTION_: 'Description du nœud',
      _NODE_LIST_: 'Liste des nœuds',
      _POLICY_NUMBER_: 'Numéro de police',
      _ADDRESSED_: 'Bénéficiaire',
      _START_DATE_: 'Date du',
      _STATUS_: 'Statut',
      _POLICY_: 'Politique',
      _TEMPLATE_: 'Template',
      _OUTCOME_: 'Résultats',
      _USER_: 'Utilisateur'
    },
    _MSG_: {
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Les champs marqués en rouge sont obligatoires ou contiennent des valeurs incorrectes.',
      _NO_RESULTS_: 'Pas de résultat',
      _NOT_SENT_: 'Non envoyé',
      _SENT_OK_: 'Posté OK',
      _SENT_KO_: 'Posté KO',
    }
  },
};

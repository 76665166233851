import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {
  RgiRxErrorStateMatcher,
  RgiRxFormControl
} from '../form-elements/rgi-rx-form-elements-api';
import {RgiRxOptionComponent} from '../form-elements/rgi-rx-option/rgi-rx-option.component';
import {combineLatest, merge, of, Subscription} from 'rxjs';
import {auditTime, delay, filter, map, mergeMap, startWith, switchMap, tap} from 'rxjs/operators';
import {RgiRxAutoCompleteComponent} from '../auto-complete/rgi-rx-auto-complete/rgi-rx-auto-complete.component';
import {RgiRxAutoCompleteDirective} from '../auto-complete/rgi-rx-auto-complete.directive';
import {RgiRxMultiSelect} from './rgi-rx-multiselect.providers';
import {RgiRxMultiselectRemoveTriggerDirective} from './rgi-rx-multiselect-remove-trigger-for.directive';
import {RgiRxMultiselectContentDirective} from './rgi-rx-multiselect-content.directive';
import {RgiRxMultiselectInputContentDirective} from './rgi-rx-multiselect-input-content.directive';
import {RgiRxMultiselectCloseEvent, RgiRxMultiselectSelectionChangeEvent} from './rgi-rx-multiselect.api';
import {RgiRxMultiselectSelectedCounterDirective} from './rgi-rx-multiselect-selected-counter.directive';
import {TAB} from '@angular/cdk/keycodes';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {RgiRxKeyActiveDescendantKeyManagerProvider} from '../a11y';
import {isEqual} from 'lodash';
import {RGI_RX_FORM_FILED_INPUTS} from '../form-elements/rgi-rx-form-elements-meta';

let multiSelectIdCounter = 0;

@Component({
  selector: 'rgi-rx-multiselect',
  templateUrl: './rgi-rx-multiselect.component.html',
  providers: [
    {
      provide: RgiRxFormControl,
      useExisting: RgiRxMultiselectComponent
    },
    {
      provide: RgiRxMultiSelect,
      useExisting: RgiRxMultiselectComponent
    },
    RgiRxKeyActiveDescendantKeyManagerProvider
  ],
  host: {
    class: 'rgi-ui-multiselect',
    '[attr.id]': 'id',
    '[class.rgi-ui-error]': 'hasError()'
  },
  inputs: [
    ...RGI_RX_FORM_FILED_INPUTS
  ]
})
export class RgiRxMultiselectComponent<T = any> extends RgiRxFormControl<T[]> implements RgiRxMultiSelect, ControlValueAccessor, AfterViewInit, AfterContentInit, OnDestroy {
  @ViewChild(RgiRxAutoCompleteComponent, {static: true}) autoComplete: RgiRxAutoCompleteComponent;
  @ContentChild(RgiRxMultiselectContentDirective, {static: false}) content: RgiRxMultiselectContentDirective;
  @ContentChild(RgiRxMultiselectInputContentDirective, {static: false}) inputContent: RgiRxMultiselectInputContentDirective;
  @ContentChildren(RgiRxOptionComponent) options: QueryList<RgiRxOptionComponent<T>>;
  @ViewChildren(RgiRxMultiselectSelectedCounterDirective) inputChips: QueryList<RgiRxMultiselectSelectedCounterDirective>;
  @ContentChild(RgiRxMultiselectRemoveTriggerDirective, {static: false}) trigger: RgiRxMultiselectRemoveTriggerDirective;
  @ViewChild(RgiRxAutoCompleteDirective, {static: true}) autoCompleteDirective: RgiRxAutoCompleteDirective;

  @ViewChild(CdkTrapFocus, {static: false}) trapFocus: CdkTrapFocus;

  @Input() showSelectedInOverlay = true;
  @Input() showSelected = true;
  @Input() keepSelectedOptions = false;

  @Input() noMoreOptionsMessage = 'RGI_RX.MULTISELECT.NO_MORE_OPTIONS';

  /**
   * @description emit when the selection change
   */

  @Output() selectionChange = new EventEmitter<RgiRxMultiselectSelectionChangeEvent<T>>();
  /**
   * @description emit when the overlay is closed
   */
  @Output() close = new EventEmitter<RgiRxMultiselectCloseEvent<T>>();

  selectedOptions: RgiRxOptionComponent<T>[] = [];
  hasAllOptionsSelected = false;
  hasFilterNoMoreOptions = false;
  moreChips = 0;

  private changesSubscription = Subscription.EMPTY;
  private autoCompleteCloseSubscription = Subscription.EMPTY;
  private stateChangeSubscription = Subscription.EMPTY;
  private moreChipsSubscription = Subscription.EMPTY;

  constructor(_elementRef: ElementRef,
              _errorStateMatcher: RgiRxErrorStateMatcher,
              private keyManagerProvider: RgiRxKeyActiveDescendantKeyManagerProvider,
              @Optional() @Self() ngControl?: NgControl,
              @Optional() _parentForm?: NgForm,
              @Optional() _parentFormGroup?: FormGroupDirective) {
    super(_errorStateMatcher, ngControl, _parentForm, _parentFormGroup);
    if (!!ngControl) {
      ngControl.valueAccessor = this;
    }
    this.id = `rgi-rx-multiselect-${multiSelectIdCounter++}`;
  }

  get overlayTemplate() {
    return this.content && this.content.template || undefined;
  }

  get inputTemplate() {
    return this.inputContent && this.inputContent.template || undefined;
  }

  ngAfterViewInit(): void {
    this.stateChangeSubscription.unsubscribe();
    this.stateChangeSubscription = this.stateChanges
      .pipe(
        startWith(this.value),
        mergeMap(() => this.options.changes.pipe(startWith(this.options))),
        delay(0)
      )
      .subscribe((options: QueryList<RgiRxOptionComponent<T>>) => {
         this.selectedOptions = options.map(opt => {
           if (this.value && this.value.find(v => isEqual(opt.value, v))) {
             opt.active = true;
             opt.hidden = !this.keepSelectedOptions;
             return opt;
           } else {
             opt.active = false;
             opt.hidden = false;
             this.hasAllOptionsSelected = false;
             this.hasFilterNoMoreOptions = false;
           }
        }).filter(v => !!v);
      });

    this.moreChipsSubscription.unsubscribe();
    this.moreChipsSubscription = this.inputChips.changes.pipe(auditTime(250))
      .subscribe(_ => setTimeout(() => this.moreChips = this.inputChips.filter(c => !c.visible).length));
  }

  ngAfterContentInit(): void {
    this.keyManagerProvider.items = this.options;
    this.autoCompleteCloseSubscription.unsubscribe();
    this.autoCompleteCloseSubscription = this.autoCompleteDirective.onClose.subscribe(
      _ => {
        this.autoCompleteDirective.clear();
        this.close.emit({
          currentSelections: this.selectedOptions
        });
      }
    );
    const optionsSelected$ = this.options.changes.pipe(
      startWith(this.options),
      switchMap((options: QueryList<RgiRxOptionComponent<T>>) =>
        merge(...options.map(e => e.value$.pipe(map(() => e))))
      ),
      tap(option => {
        if (!this.isSelected(option)) {
          this.addOption(option);
        } else {
          this.removeOption(option);
        }
      })
    );

    this.changesSubscription.unsubscribe();
    combineLatest([this.autoCompleteDirective.onChange, merge(optionsSelected$, of(undefined))])
      .pipe(
        delay(0),
        tap(([inputValueChange, _]: [string, any]) => {
          const filtered = this.options.filter(opt => this.selectedOptions.indexOf(opt) === -1)
            .map(o => {
              if (o.label.toLowerCase().indexOf(inputValueChange ? inputValueChange.toLowerCase() : '') > -1) {
                o.hidden = false;
                return o;
              }
              o.hidden = true;
            }).filter(v => v);
          this.hasFilterNoMoreOptions = filtered.length === 0;
        })
      ).subscribe();
  }

  updateMoreCount() {
    this.inputChips.notifyOnChanges();
  }

  @Input()
  set value(value: T[] | undefined) {
    this._value = value;
  }

  get value(): T[] | undefined {
    return this._value;
  }

  @Input() @HostBinding('class.rgi-ui-disabled') get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
    if (this.options) {
      this.options.forEach(o => o.disabled = value);
    }
  }

  addOption(option: RgiRxOptionComponent<T>) {
    option.hidden = !this.keepSelectedOptions;
    option.active = true;
    this.selectedOptions.push(option);
    this.hasAllOptionsSelected = this.selectedOptions.length === this.options.length;
    this.selectionChange.emit({
      currentSelections: this.selectedOptions,
      option,
      type: 'selected'
    });
    this.update();
    this.onTouched();
  }

  removeOption(option: RgiRxOptionComponent<T>) {
    this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1);
    option.hidden = false;
    option.active = false;
    this.hasAllOptionsSelected = false;
    this.selectionChange.emit({
      currentSelections: this.selectedOptions,
      option,
      type: 'removed'
    });
    this.update();
    this.onTouched();
  }

  onChange = (changed: any) => {};

  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.stateChanges.next();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  update() {
    this._value = this.selectedOptions.map(o => o.value);
    this.moreChips = this.inputChips.filter(c => !c.visible).length;
    this.onChange(this.value);
    this.onTouched();
  }

  ngOnDestroy(): void {
    this.stateChangeSubscription.unsubscribe();
    this.autoCompleteCloseSubscription.unsubscribe();
    this.changesSubscription.unsubscribe();
    this.moreChipsSubscription.unsubscribe();
  }

  isSelected(value: RgiRxOptionComponent<T>) {
    return this.selectedOptions.indexOf(value) > -1;
  }


  onAutoCompleteValueChange($event: any) {
    const optionByValue = this.options.find(opt => opt.value === $event);
    if (optionByValue) {
      this.addOption(optionByValue);
    }
  }

  toggle($event: Event) {
    $event.stopPropagation();
    if (this.autoCompleteDirective.isOpen()) {
      this.autoCompleteDirective.closeOverlay();
      return;
    }
    this.autoCompleteDirective.openOverlay();
  }

  onInputBlur($event: FocusEvent) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    $event.preventDefault();

    this.focused = false;
  }

  onInputKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === TAB) {
      if (this.selectedOptions.length) {
        $event.preventDefault();
        this.trapFocus.focusTrap.focusFirstTabbableElement();
        return;
      }
      this.autoCompleteDirective.closeOverlay();
    }
  }
}

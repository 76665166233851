import {ContractNumber} from './contract-number';

export class PolicyNumber {
  contractNumber: ContractNumber;
  features: any;
  movementNumber: any;
  titleNumbers: any;
  error: any;

  constructor() {
  }
}

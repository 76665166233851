import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

  export const EN: RgiRxSerializedI18n = {
  START_EMISSION_FLOW_RE: 'Emission flow RE',
  START_POLICY_DATA: 'Non-Life Proposal - Policy Data',
  START_ASSETS_DATA: 'Non-Life Proposal - Assets Data',
  START_QUOTATION: 'Non-Life Proposal - Quotation',
  START_SUMMARY: 'Non-Life Proposal - Summary',
  START_PROPOSAL_MANAGEMENT: 'Non-Life Proposal - Proposal Management',
  START_SAVE_PROPOSAL: 'Non-Life Proposal - Save Proposal',
  START_EMISSION: 'Non-Life Proposal - Emission',
  START_REPRINT_DOCUMENTS: 'Reprint Documents',
  START_REPRINT_MOVEMENTS: 'Reprint Movements',
  START_SUBSTITUTION: 'Substitution',
  // Replace space with underscore
  RE_ISSUE: {
    DATE_CREDIT_CARD: 'Date entered is not valid',
    REASON: 'Reason',
    RECALCULATE_SAVE: 'Recalculate and Save',
    CLEAR_DISCOUNTS: 'Clear discounts',
    DISCOUNT_AMOUNT: 'Discounts amount',
    DISCOUNTS: 'Discounts management',
    WARRANTIES_SUMMARY: 'Warranty Summary',
    IS_MANDATORY: 'is mandatory',
    Country: 'Country',
    POLICY_DATA: 'Policy Data',
    ASSET_DATA: 'Good insured data',
    QUOTATION: 'Quotation',
    SUMMARY: 'Summary',
    VALIDATE_BRANCH: 'Please validate branch',
    FIELDS_MARKED_IN_RED: 'Fields marked in red are required or contain incorrect values',
    IS_REQUIRED: 'is required',
    COVERAGE_EXPIRE_DATE: 'Coverage Expiration Date',
    SUBSCRIPTION_INST_PREMIUM: 'Subscription Instalment Premium',
    INST_SIGN: 'Subscription Instalment: ',
    MANDATORY_QUESTIONNAIRE_NOT_FILLED: 'Warning, there is at least one mandatory questionnaire not filled in.',
    NATION: 'Nation',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    DATE_OF_BIRTH: 'Date of Birth',
    FACTORS: 'Factors',
    PLACE_OF_BIRTH: 'Place of Birth',
    DAMAGES_PROPOSAL_ASSET_DATA: 'Non-Life Proposal - Asset Data',
    SUBJECTS: 'Parties',
    INSURED_SUBJECTS: 'Insured Party',
    OCCUPATION: 'Occupation',
    FISCAL_CODE: 'Tax code',
    BACK: 'back',
    ADD_ASSET: 'Add Asset',
    NEXT: 'Next',
    CONTINUE: 'Continue',
    NEXT_START_CARD: 'Next',
    EMISSION: 'Issue',
    POLICY_N: 'Policy no.',
    SAVED_SUCCESSFULLY: 'has been saved successfully.',
    SUCCESSFULLY_FINALIZED: 'successfully finalised',
    PRINT_PIN: 'It is necessary to print and enter the PIN in order to finalise the contract',
    PRINT: 'Print',
    PRINT_ENTRY: 'PIN Entry',
    EXIT: 'Exit',
    ISSUE_CONFIRMATION: 'Issue Confirmation',
    ENTER_PIN_TO_CONFIRM_POLICY_ISSUE: 'Enter your PIN to confirm policy issue:',
    CONFIRM_PURCHASE: 'Confirm Purchase',
    POLICY_PREVIEW: 'Policy Preview',
    VALIDATE: 'Validate',
    POLICYHOLDER: 'Policyholder',
    CO_OWNERS: 'Co-policyholder',
    NODE: 'Node',
    NODE_START_CARD: 'Point of Sale',
    eMAIL: 'Email',
    PRODUCT: 'Product',
    BRANCH_OFFICE: 'Branch Office',
    FISCAL_CODE_VAT_NUMBER: 'Tax Code/VAT Number',
    RESIDENCE_ADDRESS: 'Residence Address',
    CONTACTS: 'Contacts',
    MOBILE_PHONE: 'Mobile Phone',
    EFFECTIVE_DATE: 'Effective Date',
    NEXT_INSTALMENT_DATE: 'Next Instalment Date',
    EXPIRE_DATE: 'Expiry Date',
    TACIT_RENEWAL: 'Tacit Renewal',
    TECHNICAL_DATA: 'Technical Data',
    PAYMENT_FREQUENCY: 'Payment Frequency',
    AGREEMENT: 'Agreement',
    PROPOSAL_MANAGEMENT: 'Proposal Management',
    ISSUE_DATE: 'Issue Date',
    POLICY_MANDATORY_DATA: 'Mandatory Policy Data',
    POLICY_NUMBER: 'Policy number',
    MEANS_OF_PAYMENT: 'Payment methods',
    SELECT_MEANS_OF_PAYMENT: 'Select payment method',
    COORDINATES_TO_REFUND: 'Refund coordinates',
    HEADING: 'Heading',
    ISSUE: 'Issue',
    '{{header}}': '',
    '{{ parameter?.description }}': '',
    QUESTIONNAIRES: 'Questionnaires',
    NOT_COMPILED: 'Not compiled',
    COMPILED: 'Compiled',
    TO_BE_FILLED_IN_BY_THE_SUBJECT: 'To be filled in by the party with the Policyholder role',
    CLAUSES: 'Clauses',
    ASSET_CLAUSES: 'Asset Clauses',
    INSURABILITY_REQUIREMENTS: 'Insurability requirements',
    PRE_DISCOUNT_PREMIUM: 'Pre-discount premium',
    GROSS: 'Gross',
    PREMIUM_INSTALLMENT: 'Premium installment',
    ANNUAL_PREMIUM: 'Annual premium',
    PREMIUM_RATE: 'Premium rate',
    RECALCULATE: 'Recalculate',
    VISUALIZE: 'Visualize',
    PREMIUM_DETAILS: 'Premium details',
    CONTRIBUTIONS_AND_TAXES: 'Contributions and Taxes',
    BOX: 'Box',
    LIST: 'List',
    COMMISSIONS: 'Commissions',
    AUTHORITY_LIMIT: 'Authority Limit',
    PERC_ACQUIRED_COMMISSIONS: '% Acquired Commission',
    PERC_ACQUIRED_CHARGES: '% Acquired Charges',
    PERC_COLLECTED_COMMISSIONS_NET: '% Collected Commission (Net)',
    PERC_COLLECTED_CHARGES: '% Collected Charges',
    APPLY: 'Apply',
    EDIT_AMOUNT: 'Edit Amount',
    COMMISSIONS_AT_INCEPTION_DATE: 'Commissions at Inception Date',
    ACQUIRED_COMMISSIONS: 'Acquired Commission',
    ACQUIRED_CHARGES: 'Acquired Charges',
    COLLECTED_COMMISSIONS_NET: 'Collected Commission (Net)',
    COLLECTED_CHARGES: 'Collected Charges',
    TOTAL: 'Total',
    AMOUNT: 'Amount',
    '{{ commission.fees ? \'Fees\' : commission.description }}': '',
    NEXT_COMM_INSTALMENT: 'Next Comm Instalment',
    RECALCULATE_AND_SAVE: 'Recalculate and Save',
    CLOSE: 'Close',
    ISSUE_STEPPER: 'Issue',
    INSTALMENT_AT_INCEPTION_DATE: 'Instalment at Inception Date',
    TAXABLE: 'Taxable',
    INSTALMENT_AT_INCEPTION_DATE_GROSS: 'Gross instalment at Inception Date:',
    INSURANCE_TAX: 'Insurance Tax',
    RATE: 'Rate',
    TAXES: 'Taxes',
    ANTIRACKET: '(including Anti-racket)',
    NEXT_INSTALMENT: 'Next instalment',
    NEXT_INSTALMENT_GROSS: 'Next Gross Instalment:',
    '{{questionnaireFlat?.descriptionType}}': '',
    QUOTATION_DETAILS: 'Quotation Details',
    '{{ premiumDetail.sectionTitle }}': '',
    NET: 'Net',
    ACCESSORIES: 'Accessories',
    INST_INTEREST: 'Instalment Interest',
    'Damages proposal - Quotation': 'Non-Life Proposal - Quotation',
    '{{assetInstance.instanceDescription}}': '',
    PARAMETERS: 'Policy Parameters',
    PROPOSAL_N: 'Proposal No.',
    AUTHORIZATION_SEND: 'and an authorisation request was sent',
    SAVING_QUOTE: 'Saving quote',
    PROCEDE_TO_CHECKOUT: 'Proceed to checkout',
    CHECKOUT: 'Checkout',
    CODE: 'Code',
    ACTION: 'Action',
    NODE_LIST: 'Node list',
    DRAG_SELECT: 'Drag/Select',
    COMPANY: 'Company',
    POLICY_STATE: 'Contract Status',
    INSURANCE_ASSETS: 'Insured Assets',
    SAVE_QUOTATION: 'Save Quotation',
    'Damages proposal - Summary': 'Non-Life Proposal - Summary',
    PROPOSAL_DATA: 'Proposal Data',
    OTHER_POLICY_DATA: 'Other Policy Data',
    SYSTEM_MESSAGE: 'SYSTEM MESSAGE',
    AN_ERROR_OCCURRED: 'An error occurred, please contact customer service',
    ERROR_DURING_SUBJECT_UPDATE: 'Error during party update',
    QUESTIONNAIRE_NOT_COMPLETE: 'Warning, the questionnaire is not complete. Fill in all the answers to continue.',
    THE_CONTRACTOR_CONFIRMS_THE_VALIDITY: 'The Policyholder confirms the validity of the answers provided',
    PACKAGE: 'Packages',
    POLICY_EXPIRE_DATE: 'Policy Expiry Date',
    NORMALIZE: 'Normalize',
    ASSETS_DATA: 'Assets Data',
    REPRINT_POLICY_DOCUMENTS: 'Reprint policy documents - Policy no.',
    OUTCOME: 'Outcome',
    AUTHORIZATION_REQUEST: 'Authorisation Request',
    ADDRESS: 'Addresses',
    MAIN: 'Main',
    DESCRIPTION: 'Description',
    ADD_ADDRESS: 'Add address',
    POLICYHOLDER_COMMUNICATION_ADDRESSES: 'Policyholder Communication Addresses',
    POSTAL_MAIL: 'Postal Mail',
    CHANGE_ADDRESS: 'Change Address',
    EMAIL: 'Email',
    CUSTOMER_RESERVED_AREA: 'Customer Reserved Area',
    POLICYHOLDER_EMAIL: 'Policyholder Email',
    POLICYHOLDER_RESIDENCE_ADDRESS: 'Policyholder Residence Address',
    POLICYHOLDER_DELIVERY_ADDRESS: 'Policyholder Delivery Address',
    OTHER_ADDRESS: 'Other Address',
    SUBSCRIPTION_INSTALMENT_TAXABLE: 'Subscription Instalment Taxable:',
    INSTALEMENT_INCEPTION_DATE: 'Instalment at Inception Date',
    FPS_TAXES: 'FPS Taxes',
    SUBSCRIPTION_INSTALMENT_GROSS: 'Subscription Instalment Gross:',
    NEXT_INSTALMENT_TAXABLE: 'Next Instalment Taxable:',
    ANNUAL_PREMIUM_TAXABLE: 'Annual Premium Taxable:',
    ANNUAL_PREMIUM_GROSS: 'Annual Premium Gross:',
    NEW: 'New',
    POLICY_CONTACT_ERROR: 'Enter at least one policyholder contact.',
    POLICY_CONTACT_RESERVED_AREA: 'The documentation will be deposited in the reserved client area, without sending any postal mail or any electronic mail.',
    CLAUSE_DETAIL: 'Clause Detail',
    SAVE: 'Save',
    PRODUCT_CODE: 'Product Code',
    PRODUCT_DESCRIPTION: 'Product Description',
    POLICY_EFFECT_DATE: 'Policy Effective Date',
    EMPTY: 'Empty',
    DEBIT_MEAN_OF_PAYMENT: 'Means of payment on debit',
    CREDIT_MEAN_OF_PAYMENT: 'means of payment on credit',
    ALTERNATIVE_POLICY_ADRESS: 'Enter an alternative policy address',
    VALID_POLICY_EMAIL: 'Enter a valid policy email',
    YES: 'Yes',
    MANDATORY_TEXT: 'Text of clause is mandatory',
    MANDATORY: 'mandatory',
    SELECT_PAYMENT_METHOD: 'Select a payment method',
    ERROR_ON_DATE: 'It is not possible to continue as the effective date must be before the expiry date.',
    SELECT_CONTRACTOR: 'Select a policyholder',
    MANDATORY_REGULATION: 'Fill in all the mandatory factors for the proposal regulation',
    BENEFICIARIES: 'Beneficiaries',
    BENEFICIARY_TYPE: 'Beneficiary type',
    BENEFICIARY: 'Beneficiary',
    ADD_BENEFICIARIES: 'Add Beneficiaries',
    ERROR_BENEFICIARY_NOT_SELECTED: 'Choose the beneficiary type',
    INSURED_THIRD_PERSON_ERROR: 'The insured must be a different party from the third-party contact person',
    SUBSCRIBER_THIRD_PERSON_ERROR: 'The subscriber must be a different party from the third-party contact person',
    EDIT_BENEFICIARIES: 'Edit Beneficiaries',
    DELETE_BENEFICIARIES: 'Delete Beneficiaries',
    ERROR_BENEFICIARY_QUOTAS: 'The total percentage must be 100',
    ERROR_BENEFICIARY_MIN_PARTIES: 'Minimum number of required beneficiaries',
    PERCENTAGE: 'Percentage',
    THIRD_PARTY_SUBJECT: 'Third-party Party',
    WARNING_BENEFICIARY_SUBJECT: 'The selected party has already been registered as a beneficiary, select another party.',
    WARNING_BENEFICIARY_THIRD_PERSON: 'The selected party has already been registered as third-party party, select another party.',
    WARNING_BENEFICIARY_LIMIT: 'Maximum number of beneficiaries reached',
    WARNING_THIRDPERSON_SUBJECT: 'The party selected as Third-party Party has already been registered as policyholder/insured/beneficiary, select another party',
    USUAL_OCCASIONAL_RESIDENCE: 'USUAL/OCCASIONAL RESIDENCE',
    INVALID_NUMBER: 'Format not accepted',
    ANAG_BENEF: 'Registry Beneficiaries',
    SPOUSE_INSURED: 'Spouse of the insured',
    ERROR_FORMAT_DATE: 'Date format error',
    DATE1_AFTER_DATE2: 'The expiration date is prior to the effective date',
    SELECT_NODE: 'Select node',
    DATA_CANCELLATION_POLICY: 'Data Cancellation policy',
    CANCELLATION_REASON: 'Cancellation Reason',
    CANCELLATION_PREMIUM_REPAYMENT: 'The cancellation provides for the premium repayment',
    RIGHTS: 'Rights',
    DATA_CANCELLATION: 'Data Cancellation',
    CONFIRMATION_CANCELLATION: 'Confirmation Cancellation',
    CANCELLATION_SUMMARY: 'Cancellation Summary',
    END: 'End',
    DOCUMENTS: 'Documents',
    POLICY: 'Policy',
    CANCELED_SUCCESSFULLY: 'cancelled successfully',
    CONFIRM_BENEFICIARIES_DELETE: 'Do you confirm the cancellation of beneficiaries?',
    BENEFICIARIES_DELETE: 'Do you confirm the\'cancellation of beneficiaries?',
    CURRENCY: 'Currency',
    LIEN: 'Lien',
    LIENS: 'Liens',
    EMPTY_LIST_LIEN: 'There are no elements to show',
    ERROR_MESSAGE_DATES : 'The effective date must be prior to the expiry date.',
    ERROR_MESSAGE_TYPE_LIEN : 'Invalid party, Lienor Entity must be legal type',
    ERROR_MESSAGE_NUMBER_BOND : 'Add at least one lien for each asset',
    POSTED_NOTICE_DATE: 'Cancellation Posted Notice date',
    NEW_LIEN: 'New Lien',
    EDIT_LIEN: 'Edit Lien',
    BODY_BINDING: 'Lienor entity',
    EFFECT_DATE: 'Effective Date',
    EXPIRY_DATE: 'Expiry date',
    SAVE_CHANGES: 'Save Changes',
    BODY_Binding_Mandatory: 'The lienor entity is mandatory',
    ISSUED_CORRECTLY: 'it has been issued correctly',
    PREMIUM: 'Premium',
    FEES: 'Fees',
    SELECT_ASSET: 'SELECT ASSET',
    DELETE_ASSET: 'Delete asset',
    ACTIVITY: 'Activity',
    SECONDARY_ACTIVITY: 'Secondary Activity',
    ACTIVITY_NOT_FOUND: 'Activity not found',
    SEARCH_ACTIVITY_BY: 'Search activity by',
    VALUE: 'Value',
    SEARCH: 'Search',
    NO_VALUE: '',
    PROPOSAL_REGULATION: 'Proposal regulation',
    REGULATION_EXPIRATION_DATE: 'Regulation expiration date',
    REGULATION_FRACTIONING: 'Regulation payment by instalments',
    REGULATION_TYPE: 'Regulation type',
    REGULATION_DAYS: 'Regulation days',
    PERCENTAGE_OF_MIN_QUOTE_PREMIUM: '% of min quote premium/ final',
    LPS: 'LPS',
    EU_COUNTRY: 'EU Country',
    EXTRA_EU_COUNTRY: 'Extra EU Country',
    CHANGE_DATE: 'Change date',
    EXCHANGE_RATE: 'Exchange rate',
    USE_TAX: 'Use tax',
    CO_INSURANCE: 'Co-Insurance',
    CO_INSURANCE_DEP: 'Co-Insurance Dep.',
    OUR_PERCENTAGE: 'Our Percentage',
    QUOTA_PERCENTAGE: 'Quota Percentage',
    COMMISSION_PERCENTAGE: 'Commission Percentage',
    PERCENTAGE_TOTAL: 'Total Percentage',
    PERCENTAGE_ERROR: 'Invalid Percentage',
    NO_QUOTAS_ERROR: 'No co-insurance shares entered for co-insurance',
    LEAD_COINS_ERROR: 'The total percentage of lead co-insurer must be 100',
    POLICY_QUOTAS: 'Policy Shares',
    NEW_QUOTA: 'New Co-Insurance Share',
    THIRD_PARTY_QUOTA: 'Leading Insurer Policy Data',
    QUOTA: 'Share',
    INTERMEDIARY: 'Intermediary',
    LEADING_INSURER: 'Leading Insurer',
    RECALCULATE_UPDATE: 'RECALCULATE AND UPDATE',
    RECALCULATE_DEFAULT_LPS_TAXES: 'RECALCULATE DEFAULT LPS TAXES',
    TAX_ANTI_MONEY_LAUNDERING: 'Anti-Money-Laundering Tax',
    COMMON_RISK_POLICIES: 'Common Risk Policies',
    COMPLEMENTARY_POLICIES: 'Complementary Policies',
    COMPLEMENTARY_POLICY_DATA: 'Complementary Policy Data',
    SECOND_RISK_POLICIES: 'Second Risk Policies',
    LINKED_POLICIES: 'Linked Policies',
    NOTES: 'Notes',
    INDIRECT_CO_INSURANCE_POLICIES_LIST: 'Indirect Co-insurance Policies List',
    INDIRECT_CO_INSURANCE_POLICIES: 'Indirect Co-insurance Policies',
    SECOND_RISK_POLICIES_LIST: 'Second Risk Policies List',
    ANNUAL_GROSS_PREM_BEFORE_CHARGES: 'Annual gross premium (before charges)',
    FLEX_DISCOUNT_WITH_EROSION: 'Flexibility with decrease of the amount discount',
    FLEX_DISCOUNT_WITHOUT_EROSION: 'Flexibility without decrease of the amount discount',
    FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR: 'Amount with decrease of the amount discount not valid. ',
    FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR: 'Amount without decrease of the amount discount not valid. ',
    FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR: 'Percent with decrease of the amount discount not valid. ',
    FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR: 'Percent without decrease of the amount discount not valid. ',
    FLEX_DISCOUNT_RANGE_ALLOWED_VALUE: 'Range of allowed values: ',
    FLEX_DISCOUNT_MAX_100: 'Enter a value less than ',
    DISCOUNT: 'discount',
    RESIDUAL_DISCOUNT: 'Residual Discount',
    SHARED_DISCOUNTS_AMOUNT: 'Shared Discounts Amount',
    DISCOUNT_TYPE: 'Discount Type',
    PERIOD: 'Period',
    RESIDUAL_AMOUNT: 'Residual Amount',
    NO: 'No',
    START: 'Start',
    LIMIT: 'Limit',
    ADD_POLICY: 'Add policy',
    INPUT_PREMIUM: 'Input Premium',
    DELETE:'Delete',
    ADD_CONTRACT:'Add Contract',
    SUBSTITUTION_ERROR: 'Warning. Unable to continue with replacement : contractor has not been changed !',
    QUOT_COMMISSIONS: {
      LABEL: {
        ACCESSORIES: 'Accessories',
        AUTHORITY_LIMIT: 'Authority Limit',
        ALL_RISK: 'All risks',
        ACQUIRED_CHARGES: 'Acquired Charges',
        ACQUIRED_COMMISSION: 'Acquired Commission',
        AMOUNT: 'Amount',
        ANNUAL_PREMIUM: 'Annual premium',
        ANTIRACKET: 'Anti-Money-Laundering Tax',
        COLLECTED_CHARGES: 'Collected Charges',
        COLLECTED_COMMISSION_NET: 'Collected Commission (Net)',
        COMMISSIONS: 'Commissions',
        CONTRIBUTIONS_TAXES: 'Contributions and Taxes',
        GROSS: 'Gross',
        INCEPTION_INSTALLMENT: 'Instalment at Inception Date',
        INSTALLMENT_INTEREST: 'Instalment Interest',
        INSTALLMENT_PREMIUM: 'Instalment premium',
        INSURANCE_TAX: 'Insurance Tax',
        NET: 'Net',
        PREMIUM_DETAIL: 'Issue Premium Detail',
        RATE: 'Rate',
        SIGNED_COMMISSIONS: 'Commissions at Inception Date',
        DEROGATED: 'Der.',
        NEXT_INSTALMENT_COMMISSIONS: 'Next Comm Instalment',
        RIGHTS: 'Rights',
        SSN: 'SSN',
        TAXABLE: 'Taxable',
        TAXES: 'Taxes',
        TOTAL: 'Total',
        VIEW: 'View',
        ASSIGN: 'Assign',
        ACQUIRED_COMMISSIONS_MANDATE: 'Acquired Commissions Mandate'
      }
    },
    FPS_LABEL: {
      NEXT_INSTALMENT: 'Next instalment',
      INCEPTION_INSTALLMENT: 'Instalment at Inception Date',
    },
    INDEXATION: 'Indexation',
    QUOTA_ALREADY_EXISTING: 'Share already exists',
    CO_POLICYHOLDER_SUBSCRIBER_ERROR: 'The co-policyholder must be a different party from the policyholder',
    CO_POLICYHOLDER_JOINT_SUBSCRIBER_ERROR: 'The co-policyholder must be a different party from the other co-policyholder',
    COMMON_RISK_POLICIES_LIST: 'Common Risk Policies List',
    COMMON_POLICY_DATA: 'Common Policy Data',
    LINKED_POLICIES_LIST: 'Linked Policies List',
    VALID_POLICY: 'Valid Policy',
    INVALID_POLICY: 'Invalid Policy',
    INVALID_POLICY_NUMBER: 'Invalid format of policy number',
    POLICY_ALREADY_ADDED_INDIRECT_COINSURANCE : 'The policy has already been added In Indirect Co-insurance',
    POLICY_ALREADY_ADDED_SECOND_RISK : 'The policy has already been added in Second Risk',
    POLICY_ALREADY_ADDED : 'The policy has already been added',
    NO_POLICY_ADDED : 'No policy has been added',
    AMOUNT_NOT_VALID: 'Amount not valid',
    GENERIC_SYSTEM_ERROR: 'Internal Server Error',
    PRE_QUOTATION_CLAUSES_AND_FACTORS: 'Pre-quotation Clauses and Factors',
    SAVE_AND_CLOSE: 'Save and close',
    FIND: 'FIND',
    SELECT_A_PACKAGE: 'Select a package',
  }
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  CardService,
  constFunctionListServiceProvider, containerCacheServiceProvider, enumServiceProvider,
  eventServiceProvider
} from '@rgi/digital-claims-common-angular';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { LimitModalComponent } from './components/limit-modal/limit-modal.component';
import { LimitsManagementComponent } from './limits-management.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [LimitsManagementComponent, LimitModalComponent],
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, NgbModule, PortalNgCoreModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        //TranslateModule
        // TranslateModule.forRoot({
        //   loader: {
        //     provide: TranslateLoader,
        //     useClass: ClaimsMergeTranslationsLoader
        //   },
        //   missingTranslationHandler: {
        //     provide: MissingTranslationHandler,
        //     useClass: ClaimsLoggingMissingTranslationHandler
        //   }
        // })
    ],
    exports: [
        LimitsManagementComponent
    ],
    providers: [
        containerCacheServiceProvider,
        constFunctionListServiceProvider,
        enumServiceProvider,
        eventServiceProvider
    ]
})
export class LimitsManagementModule {

  constructor(cardService: CardService) {

    const title = '_LIMITS_MANAGEMENT';
    const moduleName = 'limits-management';
    const parentCardName = 'claimsFinder';

    cardService.registerSubCard(
      moduleName,
      title,
      LimitsManagementComponent,
      parentCardName,
      ['claims.LimitType', 'claims.LimitLevel', 'claims.LimitSubtype']);
  }
}

import { RgiRxTranslations, RgiRxSerializedI18n } from '@rgi/rx/i18n';
import {DE, EN, ES, FR, IT} from './i18n_analytics';

export function loadFakeAsyncIT() {
    return new Promise<RgiRxSerializedI18n>(resolve => {
        // resolve const from a static import
        resolve(IT);
    });
}

export function loadFakeAsyncEN() {
    return new Promise<RgiRxSerializedI18n>(resolve => {
        // resolve const from a static import
        resolve(EN);
    });
}

export function loadFakeAsyncFR() {
    return new Promise<RgiRxSerializedI18n>(resolve => {
        // resolve const from a static import
        resolve(FR);
    });
}

export function loadFakeAsyncES() {
  return new Promise<RgiRxSerializedI18n>(resolve => {
    // resolve const from a static import
    resolve(ES);
  });
}

export function loadFakeAsyncDE() {
  return new Promise<RgiRxSerializedI18n>(resolve => {
    // resolve const from a static import
    resolve(DE);
  });
}

export const TRANSLATIONS: RgiRxTranslations = [
    {
        load: loadFakeAsyncIT,
        locale: 'it'
    },
    {
        load: loadFakeAsyncEN,
        locale: 'en'
    },
    {
        load: loadFakeAsyncFR,
        locale: 'fr'
    },
    {
      load: loadFakeAsyncES,
      locale: 'es'
    },
    {
      load: loadFakeAsyncDE,
      locale: 'de'
    }
];

import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";

export interface PaginatorChange {
  current: number;
  count: number;
  size: number;
}

@Injectable()
export class RgiRxDataPaginator {
  private readonly _currentPage = new BehaviorSubject<number>(0);
  private _pageSize: number;
  private _elementCount: number;


  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(value: number) {
    this._pageSize = value;
    this.pageIndex = 0;
  }


  get elementCount(): number {
    return this._elementCount;
  }

  set elementCount(value: number) {
    this._elementCount = value;
    this.emitChange();
  }


  get changes(): Observable<PaginatorChange> {
    return this._currentPage.asObservable()
      .pipe(
        map(page => {
          return {
            size: this._pageSize,
            count: this._elementCount,
            current: page
          };
        })
      );
  }

  get current(): number {
    return this._currentPage.getValue();
  }

  set pageIndex(page: number) {
    this._currentPage.next(page <= this.pagesCount ? page : this.pagesCount);
  }

  get pageIndex(): number {
    return this._currentPage.getValue() * this._pageSize;
  }

  next() {
    if (this.current < (this.pagesCount - 1)) {
      this._currentPage.next(this._currentPage.getValue() + 1);
    }
  }

  back() {
    if (this.current > 0) {
      this._currentPage.next(this._currentPage.getValue() - 1);
    }
  }

  first() {
    this._currentPage.next(0);
  }

  last() {
    const pagesNumber = this.pagesCount;
    this._currentPage.next(Math.abs(pagesNumber - 1));
  }

  getElementAbsoluteIndex(elementIndex: number) {
    return Math.abs(this.current * this.pageSize + elementIndex);
  }

  getElementPage(elementIndex: number) {
    if (this.elementCount <= elementIndex) {
      return Math.abs(this.pagesCount - 1);
    }
    return Math.floor(elementIndex / this.pageSize);
  }


  getPageOptions(max: number): number[] {
    const pageOptions: number[] = [];
    let index = this.current >= max ? this.current.valueOf() : 0;
    while (pageOptions.length < max && index < this.pagesCount) {
      pageOptions.push(index);
      index++;
    }
    if (pageOptions.length < max && pageOptions.length < this.pagesCount) {
      let left = this.current - 1;
      while (left > 0 && pageOptions.length < max) {
        pageOptions.push(left--);
      }
    }
    return pageOptions.sort((a, b) => a - b);
  }

  get pagesCount() {
    if (!this._pageSize || !this.elementCount) {
      return 0;
    }
    return Math.ceil(this._elementCount / this._pageSize);
  }

  private emitChange() {
    this._currentPage.next(this.current);
  }
}

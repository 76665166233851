import {ApiPremium} from './api-premium';
import {Section} from '../domain-models/section';
import {Package} from '../domain-models/package';

export class ApiQuotation {
  public isPackage: boolean;
  public isMultiPackages: boolean;
  public premium: ApiPremium;
  public sections: Array<Section>;
  public packages: Array<Package>;
  public isSwitchEnable: boolean;
  public isRecalculate: boolean;
  public policyInFPS: boolean;
  public showFPSTaxes: boolean;
  public showContributionsAndTaxes: boolean;
  public saveQuoteAllowed: boolean;

  constructor() {
  }
}

import { Component, OnInit, Output, EventEmitter, Input, LOCALE_ID, Inject } from '@angular/core';
import {
  EditablePanelContentComponent,
  PanelService,
  PanelSignal,
  DamagedAsset,
  Party,
  Outcome,
  CardComponentService,
  PartyTranslatorService,
  SelectableCovertureResponse,
  Field,
  DomainTranslatorFieldService,
  DomainField,
  Policy,
  SelectableCoverture
} from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../../claim-opening.service';
import { ClaimOpeningDetailsService } from '../claim-opening-details.service';
import { formatDate } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PartyData } from './dto/party-data';
import { Bodywork } from './dto/bodywork';
import { DamageCategory } from '../../claim-opening-policy-search/dto/damage-categories';
import { ExternalPolicy } from '../../../../external-portfolio/dto/external-policy';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartyDataNewAssetComponent } from './party-data-new-asset/party-data-new-asset.component';
import { UtilityService } from '../../../../external-portfolio/utility.service';
import { MultiPartyEntity } from './dto/multi-party-entity';
import { DamageCategoriesCacheEntity } from '../damage-category-panel-content/dto/damage-categories-cache-entity';
import { SimilarClaimsByActorModalComponent } from '../similar-claims-by-actor-modal/similar-claims-by-actor-modal.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';
import { AnagFlowData, AnagIntegrationService, AnagRouteData } from '@rgi/anag';


@Component({
  selector: 'claims-party-data-panel-content',
  templateUrl: './party-data-panel-content.component.html',
  styleUrls: ['./party-data-panel-content.component.scss']
})
export class PartyDataPanelContentComponent extends EditablePanelContentComponent implements OnInit {
  private static PROPERTY_LEGAL_PROTECTION_MANAGEMENT: string = 'ClaimsLegalProtectionManagement';

  @Output() stepperEventEmitter = new EventEmitter<number>();
  @Input() public card: any;
  @Output() bodyworkEvent = new EventEmitter<any>();

  @Input() damageCategoryInternal: DamageCategory;
  @Input() damageCategoriesData: DamageCategoriesCacheEntity;
  @Output() selectedAssetEmitter = new EventEmitter<any>();
  @Output() selectedThirdPartyEmitter = new EventEmitter<boolean>();
  @Output() editDone = new EventEmitter<boolean>();

  selectableCoverturesResponse: SelectableCovertureResponse;
  damagedAssetsWithoutDuplication: DamagedAsset[] = [];
  damagedAssetsWithoutDuplicationIdOnly: string[] = [];

  damagedAssetsVehicle: DamagedAsset[] = [];
  damagedAssetsIdsVehicle: string[] = [];

  damagedAssetsInjury: DamagedAsset[] = [];
  damagedAssetsIdsInjury: string[] = [];

  damagedAssetsMaterialDamage: DamagedAsset[] = [];
  damagedAssetsIdsMaterialDamage: string[] = [];

  partiesWithoutDuplication: Party[] = [];
  insuredWithoutDuplication: Party[] = [];
  partiesWithoutDuplicationIdOnly: string[] = [];
  insuredWithoutDuplicationIdOnly: string[] = [];
  validationMessages: string[];
  partyDataForm: UntypedFormGroup;
  emitter: any;
  bodyworkData: Bodywork;
  // TODO: add the conditional controls
  driverEnabled = true;
  addDriverEnabled = true;
  driverData: any;
  driverParty: Party;
  thirdParty: Party;
  partySlideCurrent: Party;
  indexSlide: number;
  fields: Field[];
  private panelReady = false;
  private panelOpenExpected = false;
  selectedAsset: any;
  savedFields: DomainField[];
  partyDataInternal: PartyData;
  thirdDataInternal: PartyData;
  thirdPartyEnabled: boolean;
  isAddAssetEnabled: boolean;
  selectedPartyRadio: Party;
  selectedInsuredRadio: Party;
  nodeIdSxFitt: string;
  checkSlide: boolean;
  typesOfLoss: { id: string, description: string }[] = [];
  searchFE: string;
  serachStringArray: string[];
  damageAccordionEnable: boolean = false;
  damageAccordionExpanded: boolean = true;
  @Input() enableMultiDamage: boolean;
  multiDamages: MultiPartyEntity;
  enableNewDamage: boolean = false;
  policyVerified: boolean;
  serviceCoverturesCalled: boolean = false;
  idAssetInsuredCurrent: string;
  isRelatedClaimsByPartyEnabled: boolean;
  abilitaAperturaSenzaCausale: boolean = false;
  @Input() public pes: any;

  constructor(
    panelService: PanelService,
    private claimOpeningService: ClaimOpeningService,
    private claimOpeningDetailsService: ClaimOpeningDetailsService,
    private domainTranslatorFieldService: DomainTranslatorFieldService,
    private cardComponentService: CardComponentService,
    private utilityService: UtilityService,
    public formBuilder: UntypedFormBuilder,
    private partyTranslatorService: PartyTranslatorService,
    private modalService: NgbModal,
    @Inject(LOCALE_ID) private locale: string,
    @Inject('eventService') private eventService: any,
    @Inject('claimsBodyworkService') private claimsBodyworkService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('AnagFinderSubject') private anagFinderSubject: any,
    @Inject('authService') private authService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    private rxTranslationsService: RgiRxTranslationService,
    public anagIntegrationService: AnagIntegrationService
  ) {
    super(panelService);
    this.emitter = eventService;
  }

  ngOnInit() {
    this.panelReady = false;
    this.panelOpenExpected = false;

    this.setPanelIncomplete();

    this.updateCoperturesForCategory(this.damageCategory);

    if (this.pes && this.pes.idClaim) {
      this.disablePanel();
      this.disablePanelContent();
    }

    if (this.claimOpeningService.getEnableMultiDamage()) {
      this.getSystemProperty.findProperty(PartyDataPanelContentComponent.PROPERTY_LEGAL_PROTECTION_MANAGEMENT).then((result) => {
        if (result && result.systemProperties && result.systemProperties.length) {
          for (const variable of result.systemProperties) {
            if (variable.key === PartyDataPanelContentComponent.PROPERTY_LEGAL_PROTECTION_MANAGEMENT) {
              if (variable.value.toLowerCase() === 'enable') {
                this.damageAccordionEnable = true;
                break;
              }
            }
          }
        }
      });
    } else {
      this.damageAccordionEnable = true;

      if (this.claimOpeningService.getGarctp() !== 'CTPT') {
        this.damageAccordionExpanded = false;
      }
    }

    this.utilityService.getNodeSxFitt().subscribe((result: any) => {
      this.nodeIdSxFitt = result;
    });

    this.valSlideParty();
    this.multiDamages = new MultiPartyEntity();
    this.multiDamages.partyList = new Array<PartyData>();
    this.getSystemProperty.findProperty('ClaimsRelatedClaimsByParty').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsRelatedClaimsByParty') {
            this.isRelatedClaimsByPartyEnabled = (variable.value === 'Enable');
          }
        }
      }
    });

    const listFunz = this.authService.getOperator().enabledFeatures;

    if (listFunz && listFunz.indexOf('SXSCOP') >= 0) {
      this.abilitaAperturaSenzaCausale = true;
    }
  }

  @Input()
  set partyData(partyData: PartyData) {
    this.partyDataInternal = partyData;
    if (partyData) {
      this.panelOpenExpected = true;
      this.updateCoperturesForCategory(this.damageCategory);
      this.savedFields = partyData.fields;
      if (partyData.bodywork) {
        this.bodyworkData = partyData.bodywork;
      }
    }
  }

  @Input()
  set driver(driver: any) {
    if (driver) {
      this.driverParty = this.partyTranslatorService.anagJsonToPartyDto(driver);
    } else {
      this.driverParty = null;
    }
  }

  @Input()
  set third(third: any) {
    if (third) {
      if (this.isRelatedClaimsByPartyEnabled) {
        this.eventService.broadcastEvent('start-loader');
        this.claimOpeningDetailsService.getClaimsByActor(third.data.name, third.data.surname, third.data.denomination).subscribe(
          (response: any) => {
            if (response && response.length > 0) {
              this.eventService.broadcastEvent('stop-loader');
              const modalRef = this.modalService.open(SimilarClaimsByActorModalComponent, {
                windowClass: 'basic',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
              });
              modalRef.componentInstance.claimList = response;
              modalRef.componentInstance.selectedSubjectId = third.id;
              modalRef.result.then(() => {
                this.setThirdParty(third);
              });
            } else {
              this.setThirdParty(third);
              this.eventService.broadcastEvent('stop-loader');
            }
          },
          (error: any) => {
            this.eventService.broadcastEvent('stop-loader');
          }
        );
      } else {
        this.setThirdParty(third);
      }
    } else {
      this.thirdParty = null;
    }
  }

  private setThirdParty(third: any) {
    this.thirdParty = this.partyTranslatorService.anagJsonToPartyDto(third);
    this.thirdParty.third = true;
    if (this.partiesWithoutDuplication.length === 0) {
      this.selectedPartyRadio = this.thirdParty;
    }
  }

  @Input()
  set bodywork(val: any) {
    if (val && val.data) {
      const driver = val.data.driver;
      const anag = driver ? driver.anagSubject : null;
      const residence = anag ? anag.residence : null;
      const phoneFax = residence ? residence.phoneFax : null;
      const payments = anag ? anag.payments : null;
      let emails = '';
      let name: string;
      let fiscalCode: string;
      if (anag && anag.emails.length > 0) {
        anag.emails.forEach((email: string) => {
          emails += email;
        });
      }
      if (driver) {
        fiscalCode = driver.vatCode ? driver.vatCode : driver.fiscalCode;
        if (driver.tipoSoggetto.codice === '1') {
          name = driver.givenName + ' ' + driver.surname;
        } else {
          name = driver.designation;
        }
      }
      this.bodyworkData = new Bodywork(
        val.data.code,
        name,
        fiscalCode,
        residence ? residence.formatAddress : null,
        phoneFax ? phoneFax.number : null,
        emails,
        payments ? payments.iban : null
      );
    }
  }

  @Input() set damageCategory(damageCategory: DamageCategory) {
    this.damageCategoryInternal = damageCategory;
    if (this.damageCategoryInternal) {
      this.updateCoperturesForCategory(this.damageCategoryInternal);
    }
  }

  search() {
    const str = this.serachStringArray.find(el => el.toUpperCase().indexOf(this.searchFE.toUpperCase()) >= 0);
    let index = -1;
    if (str) {
      index = this.serachStringArray.indexOf(str);
    }

    if (index >= 0) {
      this.checkSlide = false;
      this.indexSlide = index;
      this.partySlideCurrent = this.insuredWithoutDuplication[index];
      if (this.partySlideCurrent === this.selectedInsuredRadio) {
        this.checkSlide = true;
      }
    }
  }

  valSlideParty() {
    this.checkSlide = false;
    if (this.selectedInsuredRadio) {
      this.indexSlide = this.insuredWithoutDuplication.indexOf(this.selectedInsuredRadio);
      this.partySlideCurrent = this.selectedInsuredRadio;
      this.checkSlide = true;
    } else if (this.insuredWithoutDuplication) {
      this.indexSlide = 0;
      this.partySlideCurrent = this.insuredWithoutDuplication[0];
    }
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (!this.serviceCoverturesCalled) {
      this.updateCoperturesForCategory(this.damageCategory);
    }
    if (this.acceptSignals) {
      if (panelSignal === PanelSignal.open) {
        this.enableAndOpenContent();
      }
    }
    if (this.pes && this.pes.idClaim) {
      this.disablePanel();
      this.disablePanelContent();
    }
  }

  onConfirm() {
    if (this.enableMultiDamage || this.validateContent()) {
      this.storeData();
    }
    this.editDone.emit(true);
  }

  previousSlide() {
    if (this.indexSlide > 0) {
      this.checkSlide = false;
      this.indexSlide--;
      this.partySlideCurrent = this.insuredWithoutDuplication[this.indexSlide];
      if (this.partySlideCurrent === this.selectedInsuredRadio) {
        this.checkSlide = true;
      }
    }
  }

  nextSlide() {
    if (this.indexSlide < this.insuredWithoutDuplication.length - 1) {
      this.checkSlide = false;
      this.indexSlide++;
      this.partySlideCurrent = this.insuredWithoutDuplication[this.indexSlide];
      if (this.partySlideCurrent === this.selectedInsuredRadio) {
        this.checkSlide = true;
      }
    }
  }

  updateParty(party: Party, covertures: boolean) {
    const partiesWithoutDuplicationNew: Array<Party> = [];
    partiesWithoutDuplicationNew.push(party);
    this.partiesWithoutDuplication.forEach(aPar => {
      if (aPar.third) {
        partiesWithoutDuplicationNew.push(aPar);
      }
    });

    this.partiesWithoutDuplication = partiesWithoutDuplicationNew;
    this.selectedInsuredRadio = party;
    if (this.insuredWithoutDuplication.length > 1 &&
      covertures &&
      party.idAssetInsured &&
      party.idAssetInsured !== this.idAssetInsuredCurrent) {
      this.eventService.broadcastEvent('start-loader');
      this.updateCoperturesForCategory(this.damageCategory, party.idAssetInsured);
    }
    this.idAssetInsuredCurrent = party.idAssetInsured + '';
  }

  protected validateContent(): boolean {
    let valid = true;
    this.validationMessages = [];

    if (this.fields) {
      this.fields.forEach((el: Field) => {
        if (!el.disabled && el.mandatory && !el.value) {
          if (this.validationMessages.length === 0) {
            this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(
              res => this.validationMessages.push(res)
            )
          }
          valid = false;
        }
      });
    }

    if (this.isSelectedPartyError()) {
      this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY').subscribe(
        res => this.validationMessages.push(res)
      )
      valid = false;
    }

    if (this.isSelectedAssetError()) {
      this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET').subscribe(
        res => this.validationMessages.push(res)
      )
      valid = false;
    }

    if (this.driverEnabled && !this.driverParty) {
      this.rxTranslationsService.translate('_CLAIM_._DRIVER_MANDATORY').subscribe(
        res => this.validationMessages.push(res)
      )
      valid = false;
    }

    return valid;
  }

  protected updateCoperturesForCategory(damageCategory: DamageCategory, idBeneAss?: string) {
    const policy = this.claimOpeningService.getSessionSelectedPolicy(this.card.id);
    const data = this.claimOpeningService.getSessionOccurrenceDate(this.card.id);
    const referenceDate = this.claimOpeningService.getExistClMadePostuma() ?
      this.claimOpeningService.getCompliantDate() :
      this.claimOpeningService.getSessionOccurrenceDate(this.card.id);
    // Le coperture devono essere sempre caricate alla data denuncia!!
    if (this.damageCategoryInternal && referenceDate) {
      const dateString = data ? formatDate(data, 'dd-MM-yyyy', this.locale) : null;
      const dateRefString = referenceDate ? formatDate(referenceDate, 'dd-MM-yyyy', this.locale) : null;
      this.updateSelectableCopertures(policy, dateString, this.damageCategoryInternal, dateRefString, idBeneAss);
      this.serviceCoverturesCalled = true;
    }
  }

  protected updateSelectableCopertures(policy: Policy, dateString: string,
    damageCategory: DamageCategory,
    dateRefString: string, idBeneAss?: string) {
    let polExt: ExternalPolicy = null;
    let idTipoPortafoglio = '1'; // default portafoglio PASS

    if (this.claimOpeningService.getExternaPortfolio()) {
      idTipoPortafoglio = this.claimOpeningService.getTypePtf();
      polExt = this.claimOpeningService.getListaExternalPolicy()
        .find(aExtPol => aExtPol.policyId === (policy.objectId + ''));
    }
    this.serachStringArray = new Array<string>();
    // dateString --> data accadimento
    this.claimOpeningDetailsService.getSelectableCovertures(policy.number, dateString,
      damageCategory.idCategory, idTipoPortafoglio, polExt, dateRefString, idBeneAss).subscribe(
        (response: SelectableCovertureResponse) => {
          console.log('Selectable covertures', response);
          // make available to other components
          this.claimOpeningService.setSessionSelectableCoverturesResponse(this.card.id, response);
          // for internal use
          this.selectableCoverturesResponse = response;
          this.driverEnabled = this.selectableCoverturesResponse.driverrequest === 'true';
          this.thirdPartyEnabled = this.isThirdPartyEnabled(this.selectableCoverturesResponse.covertures);
          this.isAddAssetEnabled = this.addAssetEnabled(this.selectableCoverturesResponse.covertures);
          this.skimmingDamageAssetsAndParties(this.selectableCoverturesResponse);

          // translate fields
          if (response.fields) {
            const fieldsToTranslate = this.savedFields ? this.savedFields : response.fields;
            this.fields = this.domainTranslatorFieldService.toFields(fieldsToTranslate);
            const formVariables = {};
            this.fields.forEach((field: Field) => {
              if (field.type === 'DATE') {
                formVariables[field.id] = [{ value: '', disabled: field.disabled }];
              }
              this.partyDataForm = this.formBuilder.group(formVariables);
            });
          }

          // enable / disable fields initialization
          for (const key in this.fields) {
            if (this.fields.hasOwnProperty(key)) {
              const field: Field = this.fields[key];
              field.init();
            }
          }

          this.panelReady = true;

          if (this.partyDataInternal) {
            this.enableAndOpenContent();
            this.managePanelOpen();
            this.disablePanelContent();
            this.setPanelComplete();
            this.signalToNextPanel(PanelSignal.open);
          } else {
            this.managePanelOpen();
          }
          if (this.pes && this.pes.idClaim) {
            this.disablePanel();
            this.disablePanelContent();
          }
          this.valSlideParty();
          this.eventService.broadcastEvent('stop-loader');
        },
        (error: Error) => {
          console.log(error);
          this.eventService.broadcastEvent('stop-loader');
        }
      );
  }

  isThirdPartyEnabled(covertures: SelectableCoverture[]): boolean {

    for (const coverture of covertures) {
      if (coverture.partiescontainer && coverture.partiescontainer.thirdsubject) {
        return true;
      }
    }
    return false;
  }

  addAssetEnabled(covertures: SelectableCoverture[]): boolean {
    for (const coverture of covertures) {
      if (coverture.assetscontainer && coverture.assetscontainer.otherasset
        // && coverture.partiescontainer && coverture.partiescontainer.thirdsubject -> 28/05 tolto coltrollo per incongruenza
      ) {
        return true;
      }
    }
    return false;
  }

  openNewAssetModal(event: any) {
    event.stopPropagation();

    const modalRef = this.modalService.open(PartyDataNewAssetComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    const dann: Party = this.selectedPartyRadio ? this.selectedPartyRadio : this.partiesWithoutDuplication[0];

    modalRef.componentInstance.nextId = this.damagedAssetsMaterialDamage.length;
    modalRef.componentInstance.party = dann;
    if (dann.type === 'G') {
      modalRef.componentInstance.typesOfLoss = this.typesOfLoss.filter(el => el.id !== '2');
    } else {
      modalRef.componentInstance.typesOfLoss = this.typesOfLoss;
    }
    modalRef.componentInstance.card = this.card;
    modalRef.componentInstance.injuryCauseList = this.selectableCoverturesResponse.injuryCauseList;
    modalRef.componentInstance.injuryNatureList = this.selectableCoverturesResponse.injuryNatureList;
    modalRef.componentInstance.professionList = this.selectableCoverturesResponse.professionList;
    modalRef.componentInstance.injuryLocationList = this.selectableCoverturesResponse.injuryLocationList;

    this.claimOpeningService.setDamagedAssetsMaterialDamage(this.damagedAssetsMaterialDamage);
    this.claimOpeningService.setDamagedAssetsInjury(this.damagedAssetsInjury);
    this.claimOpeningService.setDamagedAssetsVehicle(this.damagedAssetsVehicle);
    this.claimOpeningService.setDamagedAssetsWithoutDuplication(this.damagedAssetsWithoutDuplication);
    this.claimOpeningService.setNextId(modalRef.componentInstance.nextId);
    this.claimOpeningService.setTypesOfLoss(modalRef.componentInstance.typesOfLoss);
    this.claimOpeningService.setParty(modalRef.componentInstance.party);
    this.claimOpeningService.setCard(modalRef.componentInstance.card);
    this.claimOpeningService.setInjuryCauseList(modalRef.componentInstance.injuryCauseList);
    this.claimOpeningService.setInjuryNatureList(modalRef.componentInstance.injuryNatureList);
    this.claimOpeningService.setInjuryLocationList(modalRef.componentInstance.injuryLocationList);
    this.claimOpeningService.setProfessionList(modalRef.componentInstance.professionList);


    const that = this;

    modalRef.result.then((result) => {
      if (result) {
        console.log('openNewAssetModal ->', result);
        if (result.assettype === '2') {
          this.claimOpeningService.getDamagedAssetsInjury().push(result);
        } else if (result.assettype === '1') {
          this.claimOpeningService.getDamagedAssetsVehicle().push(result);
        } else {
          this.claimOpeningService.getDamagedAssetsMaterialDamage().push(result);
        }
        this.claimOpeningService.getDamagedAssetsWithoutDuplication().push(result);
        if (this.damagedAssetsMaterialDamage.length === 1) {

        }
      }
    });

    return false;
  }

  protected storeData() {
    console.log('store data -> ', this.selectedPartyRadio);
    console.log('MultyParty -> ', this.enableMultiDamage);
    if (this.enableMultiDamage) {
      this.emitter.broadcastEvent('start-loader');
      this.multiDamages.uuid = this.claimOpeningService.getSessionUuid(this.card.id);
      this.multiDamages.selectedInsuredRadio = this.selectedInsuredRadio;
      this.claimOpeningDetailsService.savePartiesDataInCache(this.multiDamages).subscribe(
        (response: Outcome) => {
          if (response.code === Outcome.OK) {
            this.setPanelComplete();
            this.disablePanelContent();
            this.signalToNextPanel(PanelSignal.open);
            this.signalToNextPanel(PanelSignal.init);
            if (this.claimOpeningService.isSessionEventSet(this.card.id) || this.pes) {
              this.signalToPanel('document-management', PanelSignal.init);
            }
            this.stepperEventEmitter.emit(5);
          } else {
            this.validationMessages = [response.descr];
          }
          if (this.claimOpeningService.getSessionSelectableCoverturesResponse(this.card.id)) {
            this.emitter.broadcastEvent('stop-loader');
          }
        },
        (error: Error) => {
          console.error(error);
          this.validationMessages = ['Saving error'];
          this.emitter.broadcastEvent('stop-loader');
        });

    } else {
      const partyToSend: Party[] = this.selectedPartyRadio ? [this.selectedPartyRadio] : this.partiesWithoutDuplication;
      this.selectedThirdPartyEmitter.emit(this.selectedPartyRadio === this.thirdParty);
      const bodywork = new Bodywork(this.bodyworkData ? this.bodyworkData.code : null);
      let assDamaged = false;
      partyToSend.forEach(aPar => {
        aPar.injured = true;
        if (aPar === this.selectedInsuredRadio) {
          aPar.insured = true;
          assDamaged = true;
        }
      });

      if (!assDamaged) {
        this.selectedInsuredRadio.insured = true;
        this.selectedInsuredRadio.injured = false;
        partyToSend.push(this.selectedInsuredRadio);
      }
      const data = new PartyData(
        this.claimOpeningService.getSessionUuid(this.card.id),
        this.selectedDamagedAssets(),
        partyToSend,
        this.driverParty ? this.driverParty.id : null,
        this.fields ? this.domainTranslatorFieldService.toDomainFields(this.fields) : null,
        bodywork,
        null
      );
      this.emitter.broadcastEvent('start-loader');
      this.claimOpeningDetailsService.savePartyDataInCache(data).subscribe(
        (response: Outcome) => {
          if (response.code === Outcome.OK) {
            this.setPanelComplete();
            this.disablePanelContent();
            this.signalToNextPanel(PanelSignal.open);
            if (this.claimOpeningService.isSessionEventSet(this.card.id) || this.pes) {
              this.signalToPanel('document-management', PanelSignal.init);
            }
            this.stepperEventEmitter.emit(4);

          } else {
            this.validationMessages = [response.descr];
          }
          if (this.claimOpeningService.getSessionSelectableCoverturesResponse(this.card.id)) {
            this.emitter.broadcastEvent('stop-loader');
          }
        },
        (error: Error) => {
          console.error(error);
          this.validationMessages = ['Saving error'];
          this.emitter.broadcastEvent('stop-loader');
        });
    }
  }

  debugSelectableCoverturesResponse(selectableCoverturesResponse: SelectableCovertureResponse) {
    const debugged = this.cloneObject(selectableCoverturesResponse);
    let lastCoverture = debugged.covertures.length - 1;

    debugged.covertures.push(this.cloneObject(debugged.covertures[lastCoverture]));
    lastCoverture++;
    debugged.covertures[lastCoverture].assetscontainer.assettype = '3';
    for (const damagedAsset of debugged.covertures[lastCoverture].assetscontainer.damagedassets) {
      damagedAsset.id += '-mat';
    }

    debugged.covertures.push(this.cloneObject(debugged.covertures[lastCoverture]));
    lastCoverture++;
    debugged.covertures[lastCoverture].assetscontainer.assettype = '2';
    for (const damagedAsset of debugged.covertures[lastCoverture].assetscontainer.damagedassets) {
      damagedAsset.id += '-inj';
    }

    return debugged;
  }

  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  skimmingDamageAssetsAndParties(selectableCoverturesResponse: SelectableCovertureResponse) {

    console.log('skimmingDamageAssetsAndParties() - Before ', selectableCoverturesResponse);

    const debugCovertures = false;
    if (debugCovertures) {
      selectableCoverturesResponse = this.debugSelectableCoverturesResponse(selectableCoverturesResponse);
    }

    console.log('skimmingDamageAssetsAndParties() - Afeter', selectableCoverturesResponse);
    const mapParty = new Map<string, any>();

    const vehicleObs$ = this.rxTranslationsService.translate('_CLAIMS_._VEHICLE');
    const thingsObs$ = this.rxTranslationsService.translate('_CLAIMS_._THINGS');
    const physicalPersonObs$ = this.rxTranslationsService.translate('_CLAIMS_._PHYSICAL_PERSON');
    combineLatest([vehicleObs$, thingsObs$, physicalPersonObs$])
      .subscribe(
        ([vehicle, things, physicalPerson]) => {
          for (const selectableCoverture of selectableCoverturesResponse.covertures) {
            if (selectableCoverture.assetscontainer) {
              if (!this.typesOfLoss.find(tl => tl.id === selectableCoverture.assetscontainer.assettype)) {
                switch (selectableCoverture.assetscontainer.assettype) {
                  case '1':
                    this.typesOfLoss.push({ id: '1', description: vehicle });
                    break;
                  case '3':
                    this.typesOfLoss.push({ id: '3', description: things });
                    break;
                  case '2':
                    this.typesOfLoss.push({ id: '2', description: physicalPerson });
                    break;
                  default:
                    break;
                }
              }
            }
            // manage assets
            if (selectableCoverture.assetscontainer.damagedassets) {
              for (const damagedAsset of selectableCoverture.assetscontainer.damagedassets) {
                switch (selectableCoverture.assetscontainer.assettype) {
                  case '1':
                    this.addDamagedAssetVehicle(damagedAsset);
                    if (!this.typesOfLoss.find(tl => tl.id === '1')) {
                          this.typesOfLoss.push({ id: '1', description: vehicle });
                    }
                    break;
                  case '3':
                    this.addDamagedAssetMaterialDamage(damagedAsset);
                    if (!this.typesOfLoss.find(tl => tl.id === '3')) {
                          this.typesOfLoss.push({ id: '3', description: things });
                    }
                    break;
                  case '2':
                    this.addDamagedAssetInjury(damagedAsset);
                    if (!this.typesOfLoss.find(tl => tl.id === '2')) {
                          this.typesOfLoss.push({ id: '2', description: physicalPerson });
                    }
                    break;
                  default:
                    break;
                }

                if (this.damagedAssetsWithoutDuplication.filter(x => x.id === damagedAsset.id)[0] == null) {
                  this.damagedAssetsWithoutDuplication.push(damagedAsset);
                  this.damagedAssetsWithoutDuplicationIdOnly.push(damagedAsset.id.toString());
                }

              }
            }

            // manage parties
            if (selectableCoverture.partiescontainer.parties) {
              for (const parties of selectableCoverture.partiescontainer.parties) {
                if (this.insuredWithoutDuplication.filter(x => x.id === parties.id &&
                  x.idAssetInsured === parties.idAssetInsured)[0] == null) {
                  // this.partiesWithoutDuplication.push(parties);
                  this.insuredWithoutDuplication.push(parties);
                  this.partiesWithoutDuplicationIdOnly.push(parties.id.toString());

                  if (parties.policyHolder) {
                    // di default dece essere selezionato il contraente come assicurato
                    this.selectedInsuredRadio = parties;
                    this.updateParty(parties, false);
                  }
                  mapParty.set(parties.description, parties);
                  const party: Party = parties;
                  const src = party.description + '*' +
                    party.address + '*' +
                    party.fiscalcode + '*' +
                    party.piva + '*' +
                    party.name + '*' +
                    party.mail + '*' +
                    party.phoneNumber + '*' +
                    party.surname + '*';
                  this.serachStringArray.push(src);
                }
              }
            }

          }
        }
      )

    console.log('damagedAssetsWithoutDuplication');
    console.log(this.damagedAssetsWithoutDuplication);
    console.log('partiesWithoutDuplication');
    console.log(this.partiesWithoutDuplication);
    console.log('insuredWithoutDuplication');
    console.log(this.insuredWithoutDuplication);

    if (this.claimOpeningService.getExternaPortfolio()) {
      const polExt = this.claimOpeningService.getExternalPolicyCurrent();
      let parties = null;

      if (polExt.subjectList.find(sbj => sbj.codRole === 'ASS') &&
        polExt.subjectList.find(sbj => sbj.codRole === 'ASS').lastBusinessName &&
        mapParty.get(polExt.subjectList.find(sbj => sbj.codRole === 'ASS').lastBusinessName.toUpperCase())) {
        parties = mapParty.get(polExt.subjectList.find(sbj => sbj.codRole === 'ASS')
          .lastBusinessName.toUpperCase());
      } else if (polExt.subjectList.find(sbj => sbj.codRole === 'CNT') &&
        polExt.subjectList.find(sbj => sbj.codRole === 'CNT').lastBusinessName &&
        mapParty.get(polExt.subjectList.find(sbj => sbj.codRole === 'CNT').lastBusinessName.toUpperCase())) {
        parties = mapParty.get(polExt.subjectList.find(sbj => sbj.codRole === 'CNT')
          .lastBusinessName.toUpperCase());
      } else if ((!polExt.subjectList.find(sbj => sbj.codRole === 'ASS') ||
        !polExt.subjectList.find(sbj => sbj.codRole === 'ASS').lastBusinessName) &&
        this.insuredWithoutDuplication.length > 1) {
        parties = this.insuredWithoutDuplication[0];
      }

      if (parties != null) {
        this.partiesWithoutDuplication = [];
        this.insuredWithoutDuplication = [];
        // this.partiesWithoutDuplicationIdOnly = [];
        // this.partiesWithoutDuplication.push(parties);
        this.insuredWithoutDuplication.push(parties);
        this.partiesWithoutDuplicationIdOnly.push(parties.id.toString());
        this.updateParty(parties, false);
        const party: Party = parties;
        const src = party.description + '*' +
          party.address + '*' +
          party.fiscalcode + '*' +
          party.piva + '*' +
          party.name + '*' +
          party.mail + '*' +
          party.phoneNumber + '*' +
          party.surname + '*';
        this.serachStringArray.push(src);
      }
    }

    if (this.insuredWithoutDuplication.length === 0) {
      this.insuredWithoutDuplication.push(selectableCoverturesResponse.policyHolder);
      this.selectedInsuredRadio = selectableCoverturesResponse.policyHolder;
    } else if (this.insuredWithoutDuplication.length === 1) {
      this.selectedInsuredRadio = this.insuredWithoutDuplication[0];
      this.updateParty(this.selectedInsuredRadio, false);
    }

    // this.enableMultiDamage = selectableCoverturesResponse.enableMultiDamage;
  }

  addDamagedAssetInjury(damagedAsset: DamagedAsset) {
    if (this.damagedAssetsInjury.filter(x => x.id === damagedAsset.id)[0] == null) {
      this.damagedAssetsInjury.push(damagedAsset);
      this.damagedAssetsIdsInjury.push(damagedAsset.id.toString());
    }
  }

  addDamagedAssetMaterialDamage(damagedAsset: DamagedAsset) {
    if (this.damagedAssetsMaterialDamage.filter(x => x.id === damagedAsset.id)[0] == null) {
      this.damagedAssetsMaterialDamage.push(damagedAsset);
      this.damagedAssetsIdsMaterialDamage.push(damagedAsset.id.toString());
    }
  }

  addDamagedAssetVehicle(damagedAsset: DamagedAsset) {
    if (this.damagedAssetsVehicle.filter(x => x.id === damagedAsset.id)[0] == null) {
      this.damagedAssetsVehicle.push(damagedAsset);
      this.damagedAssetsIdsVehicle.push(damagedAsset.id.toString());
    }
  }

  selectedDamagedAssets(): DamagedAsset[] {
    let selectedAsset: DamagedAsset[] = [];

    if (this.damagedAssetsWithoutDuplication && this.damagedAssetsWithoutDuplication.length > 1) {
      for (const asset of this.damagedAssetsWithoutDuplication) {
        if (asset.id === this.selectedAsset) {
          selectedAsset = [asset];
          break;
        }
      }
    } else if (this.damagedAssetsWithoutDuplication) {
      selectedAsset = this.damagedAssetsWithoutDuplication;
    }
    this.selectedAssetEmitter.emit(selectedAsset);
    return selectedAsset;
  }

  isSelectedAssetError() {
    return this.damagedAssetsWithoutDuplication.length === 0 ||
      (this.isMultipleAssetsPresent() && !this.selectedAsset);
  }

  isSelectedPartyError(): boolean {
    return ((this.partiesWithoutDuplication.length === 0 && !this.thirdParty) ||
      (this.thirdParty || this.partiesWithoutDuplication.length > 1)) && !this.selectedPartyRadio;
  }

  partyDetails(party: Party) {
    const that = this;

    const nodeId = this.authService.getSalePointId();

    const request = {
      idSubject: party.id,
      idNode: this.nodeIdSxFitt
    };
    this.emitter.broadcastEvent('start-loader');

    this.anagFinderSubject.get(request).$promise.then(result => {
      if (result && result.subject) {
        const flowData: AnagFlowData = new AnagFlowData();
        flowData.idParentSession = that.card.idSession;
        flowData.nodeCode = this.authService.getSalePointCode();
        flowData.partyRole='888';
        const anagRouteData: AnagRouteData = new AnagRouteData();
        anagRouteData.party = result.subject;
        anagRouteData.redirectToMainFlow = true;
        anagRouteData.flowData = flowData;
        this.anagIntegrationService.openDetailSubject(anagRouteData);
        /* const idNavigation = that.sessionService.open('anagFinder', 'detail', '', true, null, null, that.card.idSession);
        that.coreResult.setResult(idNavigation, 'detail', {
          subject: result.subject
        });
        that.coreResult.setResult(idNavigation, 'previousSession', {
          sessionId: that.card.idSession,
          callback: () => {
            console.log('partyDetails() - callback');
          }
        }); */
      }
      this.emitter.broadcastEvent('stop-loader');

    });
  }

  transcodeNationCode(nationCode: string) {
    if (nationCode === 'IT') {
      return 'IT';
    } else {
      return 'Other';
    }
  }

  getNameFromType(party: Party) {
    if (party.type === 'F') {
      return party.surname + ' ' + party.name;
    } else {
      return party.description;
    }
  }

  addBodywork() {
    const nodeId = this.authService.getSalePointId();
    this.cardComponentService.emit({
      eventName: 'bodywork',
      salePointId: nodeId
    }, this.card.id);
  }

  deleteBodywork(event: any) {
    this.bodyworkData = null;
    event.stopPropagation();
  }

  @Input()
  set bodyworkIntegrationObj(value: any) {
    if (value) {
      this.emitter.broadcastEvent('start-loader');
      const filter = { code: value.GARAGE_CODE };
      const that = this;
      this.claimsBodyworkService.find(filter).then(
        (result: any) => {
          if (result.listCarBodyWork && result.listCarBodyWork.length > 0) {
            that.bodywork = { data: result.listCarBodyWork[0] };
          }
          this.emitter.broadcastEvent('stop-loader');
        }
      );
    }
  }

  openPanel() {
    this.panelOpenExpected = true;
    this.managePanelOpen();
  }

  managePanelOpen() {
    console.log('managePanelOpen() - this.panelReady , this.panelOpenExpected', this.panelReady, this.panelOpenExpected);
    if (this.panelReady && this.panelOpenExpected) {
      super.openPanel();
    }
  }

  closePanel() {
    this.panelOpenExpected = false;
    super.closePanel();
  }

  isMultipleAssetsPresent() {
    return this.damagedAssetsWithoutDuplication && this.damagedAssetsWithoutDuplication.length > 1;
  }

  onThirdPartyChange(event: any) {
    if (!event) {
      this.selectedPartyRadio = null;
    }
  }

  expandeDamageAccordion() {
    if (this.damageAccordionEnable) {
      this.damageAccordionExpanded = !this.damageAccordionExpanded;
    }
  }

  damageNominative(party: Party) {
    let res = '';
    if (party.description) {
      res = party.description;
    } else {
      res = party.surname + ' ' + party.name;
    }

    if (party.fiscalcode) {
      res += ' (' + party.fiscalcode + ')';
    } else if (party.piva) {
      res += ' (' + party.piva + ')';
    }

    return res;
  }

  damageAssetDescription(asset: DamagedAsset) {
    let temp = this.typesOfLoss.find(el => el.id === asset.assettype).description;

    if (asset.assettype === '3') {
      temp += ' - ' + asset.description;
    }

    return temp.length > 20 ? temp.substring(0, 20) + '....' : temp;
  }

  damgeExposureDescription(exposures: DamageCategoriesCacheEntity) {
    let res = '';
    exposures.damagecategories.forEach(el => {
      res += el.description + ' - ';
    });
    return res;
  }

  removeDamage(damage: PartyData) {
    this.multiDamages.partyList = this.multiDamages.partyList.filter(el => el !== damage);
    if (this.multiDamages.partyList.length === 0) {
      this.policyVerified = false;
    }
  }

  updateDamage(damage: PartyData) {
  }

  newDamage() {
    this.enableNewDamage = true;
  }

  damagePresent() {
    if (!this.enableMultiDamage) {
      return true;
    } else {
      return !this.enableNewDamage && this.multiDamages.partyList.length > 0;
    }
  }

  close(event: any) {
    this.enableNewDamage = false;
  }

  add(event: any) {
    this.enableNewDamage = false;
    this.policyVerified = true;
    this.stepperEventEmitter.emit(4);
  }
  // predisposition for future panel management
  // protected panelStatusRecieved(panelStatus: PanelStatus): void {
  //   super.panelStatusRecieved(panelStatus);
  //   this.initDataForm();
  //   if (panelStatus.contentEnabled) {
  //     this.partyDataForm.get('********').enable();
  //   } else {
  //     this.partyDataForm.get('********').disable();
  //   }
  // }

  // predisposition for future panel management
  // initDataForm() {
  //   if (!this.partyDataForm) {
  //     this.partyDataForm = this.formBuilder.group({*****}, {*****});
  //     this.partyDataForm.get('********').disable();
  //   }
  // }
  visualBoolean(value: boolean) {
    if (value) {
      return 'YES';
    } else {
      return 'NO';
    }
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {Clause} from '../models/domain-models/clause';
import {ClauseModalComponent} from './clause-modal/clause-modal.component';
import {UntypedFormGroup} from '@angular/forms';
import {ClauseService} from './clause-service';
import {CustomModalService} from '../custom-modal.service';
import {Subscription} from 'rxjs';
import {Message} from '../models/message';
import {TranslateService} from '@ngx-translate/core';
import {ProposalService} from '../proposal.service';
import {Risk} from '../models/domain-models/risk';
import {ApiDisabledComponents} from '../models/api-models/api-disabled-components';

@Component({
  selector: 'mic-clause',
  templateUrl: './clause.component.html',
  styleUrls: ['./clause.component.scss']
})

export class ClauseComponent implements OnInit, OnDestroy {

  @Input() clauseList: Array<Clause>;
  @Input() header: string;
  @Input() headerIcon: string;
  @Input() viewError = true;
  @Input() risk: Risk;
  @Input() areClausesEnabled = true;
  @ViewChild('openClauseModal', {
    read: ViewContainerRef,
    static: true
  }) openClauseModalViewContainerRef: ViewContainerRef;
  componentRefClaim: any;
  clauseForm: UntypedFormGroup;
  @Output() validationMessagesClause: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  @Output() validationMessages: Message[] = [];
  @Output() afterClauseInit: EventEmitter<Array<Clause>> = new EventEmitter<Array<Clause>>();
  @Output() clauseChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateUnit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter();
  public unitEnabled: boolean;
  messagesSubscription: Subscription;
  areaCode = 'CLAUSES';
  protected dataChanged = false;

  constructor(
    protected clauseService: ClauseService,
    protected modalService: CustomModalService,
    protected translate: TranslateService,
    protected proposalService?: ProposalService) {
  }

  get isUnitEnabled(): boolean {
    return this.unitEnabled;
  }

  @Input()
  set isUnitEnabled(unitEnabled: boolean) {

    this.unitEnabled = unitEnabled;

    if (this.clauseForm) {
      this.updateErrorMessages();
    }
  }

  ngOnInit() {
    if (this.clauseList) {
      this.clauseList.sort(
        (a: Clause, b: Clause) => {
          if (a.order > b.order) {
            return 1;
          } else if (a.order < b.order) {
            return -1;
          } else {
            return 0;
          }
        }
      );
      this.clauseForm = this.clauseService.toFormGroup(this.clauseList);
      this.updateErrorMessages();
      this.afterClauseInit.emit(this.clauseList);
    }
    if (this.areClausesEnabled === false) {
      this.proposalService.toggleFieldDisable(this.clauseForm, null, ApiDisabledComponents.SECTION_ASSET_CLAUSES); // TODO input parent
    }
  }

  toggleClause(clause: Clause) {
    if (this.isClauseEnabled(clause)) {
      if (this.isMutualExclusive(clause)) {
        return;
      }
      clause.selection = !clause.selection;
      this.clauseChange.emit(true);
      this.dataChanged = true;
      this.updateErrorMessages();
    }
  }



  selectClause(clause: Clause) {
    if (this.isClauseEnabled(clause)) {
      const selection = this.clauseForm.get(clause.code).value;
      if (this.isMutualExclusive(clause)) {
        if (selection === '1') {
          this.clauseForm.get(clause.code).setValue('2');
        } else {
          this.clauseForm.get(clause.code).setValue('1');
        }
        return;
      }
      clause.selection = selection === '1';
      this.clauseChange.emit(true);
      this.dataChanged = true;
      this.updateErrorMessages();
    }
  }

  isMutualExclusive(clauseToSelect: Clause): boolean {
    this.validationMessages.length = 0;
    let returnValue = false;
    this.clauseList.forEach(
      (clause) => {
        if (clause.propGSELC !== '0' && clauseToSelect.propGSELC === clause.propGSELC
          && clauseToSelect.code !== clause.code
          && clause.selection === true) {
          this.validationMessages.push(new Message(this.areaCode, 'Mutual Exclusion between Clauses.'));
          returnValue = true;
        }
      }
    );
    return returnValue;
  }

  openClause(clause: Clause) {
    if (!clause) {
      return;
    }
    clause = JSON.parse(JSON.stringify(clause));
    this.clauseService.setClause(clause);
    const modalClause = this.modalService.openModal(this.openClauseModalViewContainerRef, ClauseModalComponent, null, () => {
      let performCall = false;
      this.clauseList.forEach(
        (currentClause) => {
          if (currentClause.code === clause.code) {
            if (!modalClause.instance.textOutput || currentClause.text === modalClause.instance.textOutput) {
              performCall = false;
            } else {
              currentClause.text = modalClause.instance.textOutput;
              performCall = true;
            }
          }
        }
      );
      if (performCall) {
        if (this.risk) {
          this.clauseService.updateRiskClause(clause, this.risk.code).subscribe(
            data => {
              if (data) {
                this.updateUnit.emit(true);
              }

            });
        } else {
          this.clauseService.updateAssetClauses(this.clauseList).subscribe(
            data => {
            });
        }
      }
    });
  }

  onUnitSelected() {
    this.updateErrorMessages();
  }

  updateErrorMessages() {
    this.validationMessages.length = 0;
    for (const clause of this.clauseList) {
      if (this.isClauseEnabled(clause) && clause.compulsory && !clause.selection) {
        this.validationMessages.push(new Message(this.areaCode, clause.extendedDescription
          ? clause.extendedDescription : clause.description + ' ' + this.translate.instant('is required')));
        this.clauseForm.setErrors({
          compulsory: true
        });
      }
    }
    this.validationMessagesClause.emit(this.validationMessages);
  }

  clausesTrackByFn(index, clause: Clause) {
    return clause.code;
  }

  getClauseType(propClasel): number {
    if (!propClasel || propClasel === '0') {
      return 1;
    } else {
      return Number(propClasel.charAt(propClasel.length - 1));
    }
  }

  ngOnDestroy() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  isFormChanged(): boolean {
    return this.clauseForm.dirty || this.dataChanged;
  }


  isClauseEnabled(clause: Clause) {
    return (!!clause && clause.enable && (!!this.isUnitEnabled || this.isUnitEnabled === undefined)) && !this.clauseForm.disabled;
  }

  isSomeClauseVisible() {
    return this.clauseList && this.clauseList.length > 0 && this.clauseList.some(clause => clause.visible);
  }
}

import { RgiRxi18nModuleLoadType, RgiRxTranslations } from '@rgi/rx/i18n';
import { CLAIMS_EN } from './en';
import { CLAIMS_IT } from './it';
import { CLAIMS_ES } from './es';
import { CLAIMS_DE } from './de';
import { CLAIMS_FR } from './fr';
export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_EN);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_ES);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_DE);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_FR);
  });
}

export const CLAIMS_COMMON_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadDE,
    locale: 'de'
  },
  {
    load: loadFR,
    locale: 'fr'
  }
];

import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import { LIC_IT } from './it';
import LIC_EN from './en';
import { LIC_ES } from './es';
import LIC_FR from './fr';
import LIC_DE from './de';
import LIC_EU_ES from './eu-ES';
import LIC_GL_ES from './gl-ES';
import LIC_CA_ES from './ca-ES';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_EN);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_FR);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_DE);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_ES);
  });
}

export function loadEU_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_EU_ES);
  });
}

export function loadGL_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_GL_ES);
  });
}

export function loadCA_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_CA_ES);
  });
}


export const LIC_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadFR,
    locale: 'fr'
  },
  {
    load: loadDE,
    locale: 'de'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadEU_ES,
    locale: 'eu-ES'
  },
  {
    load: loadGL_ES,
    locale: 'gl-ES'
  },
  {
    load: loadCA_ES,
    locale: 'ca-ES'
  }
];

import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationService} from "@rgi/ng-passpropro-core"
import {filter, map, mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {combineLatest, of} from "rxjs";

export function notificationFactory(i: any) {
  return i.get('Notification');
}

export const notificationProvider = {
  provide: 'Notification',
  useFactory: notificationFactory,
  deps: ['$injector']
};

export function notificationPortingInizializerFactory(injector: Injector, notificationService: NotificationService, translateService: RgiRxTranslationService) {
  const initializer = () => {

    notificationService.get().pipe(
      map(toasts => toasts.filter(toast => !toast.tag)),
      filter(toasts => toasts.length > 0),
      mergeMap(value =>  {
        return combineLatest(of (value), translateService.translateAll("NG_PASSPROPRO_CORE.success", "NG_PASSPROPRO_CORE.error", "NG_PASSPROPRO_CORE.warning"))
      })
    ).subscribe(
      ([toasts, messages]) => {
        const notification: Function = injector.get('Notification');
        toasts.forEach(
          toast => {
            //fixme translations
            switch (toast.status) {
              case 'success':
                notification({message: toast.body || messages[0]}, "success");
                break;
              case 'danger':
                notification({message: toast.body || messages[1]}, "error");
                break;
              case 'warning':
                notification({message: toast.body || messages[2]}, "warning");
              default:
                notification({message: toast.body}, "default");
            }
          }
        );

        notificationService.removeList(toasts);

      }
    );
    return;
  }
  return initializer;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    notificationProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: notificationPortingInizializerFactory,
      deps: [Injector, NotificationService, RgiRxTranslationService],
      multi: true
    },

  ]
})
export class ServicesInteropModule {
}

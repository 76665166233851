import {PostSalesOperation} from '../api/rgi-pnc-postsales.api';
import {
  PNC_POSTSALES_OPERATIONS_CODE,
  PNC_POSTSALES_OPERATIONS_TYPES
} from '../resources/enums/pnc-postsales-operations-types';
import {API_PREFIX_PORTFOLIO} from '../resources/constants/rest-api-conf';
import {RGI_PNC_POSTSALES_STEPS} from '../resources/constants/steps';
import {PncPsalesMovementsStateManager} from "../state-managers/movements/movements-state-manager.service";
// Storno Movimento
export const PNC_PSALES_VD0062: PostSalesOperation = {
  type: PNC_POSTSALES_OPERATIONS_TYPES.ASSETS_DATA_AMENDMENTS,
  operationCode: PNC_POSTSALES_OPERATIONS_CODE.MOVEMENTS_REVERSAL,
  sessionTitle: '_PCPSALES_._SESSION_TITLE_._MOVEMENTS_REVERSAL_',
  steps: [
    {
      step: RGI_PNC_POSTSALES_STEPS.MOVEMENTS,
      manager: PncPsalesMovementsStateManager,
      deps: [],
      stepLabel: '_PCPSALES_._STEPS_._MOVEMENTS_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    }
  ]
};

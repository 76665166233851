import { LocaleDate } from '../lic-datepicker/lic-datepicker/model/locale-date';
import {DataInputField} from '../models/data-input-field';


export class LicDateUtils {
  public static isoToLocaleDate(iso: string): LocaleDate {
    if (iso === null || iso === '') {
      return null;
    }
    const date = new Date(iso);
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  public static dateToLocaleDate(date: Date): LocaleDate {
    if (date) {
      return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
    }
    return null;
  }

  /* This method has been written to avoid null or empty string parameters to return 1970 date */
  public static isoToDate(iso: string): Date | null {
    // TODO: might worth to validate iso string
    if (iso) {
      const date: Date = new Date(iso);
      date.setHours(0, 0, 0 , 0);
      return date;
    }
    return null;
  }

  public static getDates(): DataInputField[] {
    return [{code: '_VOEMI', label: '', value: new Date(), userInput: false },
            {code: '_1OEFF', label: '', value: new Date(), userInput: false },
      ] as DataInputField[];
  }

  // Converte Date in YMD
  public static localDateToString(local: LocaleDate): string {
    if (local !== null) {
      let stringMonth = null;
      let stringDay = null;
      local.month < 10 ? stringMonth = '0' + local.month : stringMonth = local.month.toString();
      local.day < 10 ? stringDay = '0' + local.day : stringDay = local.day.toString();
      return [stringDay, stringMonth, local.year.toString()].join('/');
    } else {
      return null;
    }
  }

  // change separator
  public static isoToFormattedDate(date: string, separator: string = '/'): string {
    return date.split('-').reverse().join(separator);
  }

  public static dateToString(date: Date): string | null {
    if (!!date) {
      return date.toISOString().substr(0, 10);
    }
    return null;
  }

  public static isoDateTimeToIsoDate(dateTime: string): string {
    const date: Date = new Date(dateTime);
    const year: string = date.getFullYear().toString();
    const month: string = '0' + (date.getMonth() + 1);
    const day: string = '0' + date.getDate();
    return year + '-' + month.substr(month.length - 2, 2) + '-' + day.substr(day.length - 2, 2);
  }

   // Converte Date in YMD
   static convertToYMD(d: Date): string {
    if (d !== null && d instanceof Date) {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        if (year < 1000) {
            return '';
        }

        return [year, month, day].join('/');
    } else {
        return null;
    }
  }
}

import {AddressApiContacts} from './address-api-contacts';

export class PolicyContact {

  constructor(
    public address: AddressApiContacts,
    public email: string,
    public channelType: number
  ) {
  }

}

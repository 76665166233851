<div [formGroup]="parentForm" data-qa-role="2" class="col-lg-6 col-md-6 col-sm-6">
  <input *ngIf="isEmpty(); else isFilled" class="drop-container" [value]="select" readonly (click)="openAnag()" data-qa="2-role-no-value">

  <ng-template #isFilled>
    <div class="input-group col-lg-6">
      <input formControlName="cosigned" value="{{fill()}}" style="width:100%" type="text" name="extraInformations_0" class="form-control effectiveHolderName" readonly="" data-qa="2-role-value">
      <div class="input-group-btn">
        <button [disabled]="disabled" type="button" class="btn btn-default" (click)="resetMethod()" data-qa="2-role-button-del">
          <span class="rgifont rgi-trash"></span>
        </button>
      </div>
    </div>
  </ng-template>
</div>

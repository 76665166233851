import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OccurrenceData } from './occurence-data-panel-content/dto/occurrence-data';
import { DamageCategoriesCacheEntity } from './damage-category-panel-content/dto/damage-categories-cache-entity';
import { PartyData } from './party-data-panel-content/dto/party-data';
import { Policy, SelectableCovertureResponse } from '@rgi/digital-claims-common-angular';
import { LoadPotentialClaimResponse } from './model/load-potential-claim-response';
import { Observable } from 'rxjs';
import { ExternalPolicy } from '../../../external-portfolio/dto/external-policy';
import { InjuryEntity } from './occurence-data-panel-content/dto/injury-entity';
import { FilterEntity } from './party-data-panel-content/dto/filter-entity';
import { MultiPartyEntity } from './party-data-panel-content/dto/multi-party-entity';
import { ComboInjuryEntity } from '../../../shared/dsc-shared-model/dsc-model-injury';

@Injectable({
  providedIn: 'root'
})
export class ClaimOpeningDetailsService {

  private baseApiUrl: string;
  private baseApiUrlRs: string;
  private urlServiceOccurrenceData = '/claims/occurrencedata';
  private urlServiceDamageCategories = '/claims/damagecategory';
  private urlServiceSelectableCovertures = '/claims/selectablecovertures';
  private urlServiceSavePotentialClaim = '/claims/potentialclaimsave';
  private urlServicePartyData = '/claims/partydata';
  private urlServicePartiesData = '/claims/partiesdata';
  private urlServiceLoadPotentialClaim = '/claims/potentialclaimload';
  private urlServiceLoadCatastropheEvents = '/claims/catastropheEvents';
  private urlServiceLoadOpeningModes = '/claims/openingModes';
  private urlServicePopulateInjuryComponent = '/claims/populateInjuryComponent';
  private urlServiceClaimVerifyClMadePostuma = '/claims/claimVerifyClMadePostuma';
  private urlServiceSerachHospital = '/claims/searchHospital';
  private urlServiceGetClaimsByActor = '/claims/claim/getClaimsByActor';
  private urlServiceConfigurationVehicle = '/claims/configurationVehicle';
  private urlServicePopulateInjuryCombo = '/claims/populateInjuryCombo';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrlRs = environment.api.portal.host + environment.api.portal.path;
  }

  saveOccurrenceDataInCache(input: OccurrenceData) {
    return this.httpClient.post(this.baseApiUrl + this.urlServiceOccurrenceData, input);
  }

  saveDamageCategoriesInCache(input: DamageCategoriesCacheEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlServiceDamageCategories, input);
  }

  savePartyDataInCache(input: PartyData) {
    return this.httpClient.post(this.baseApiUrl + this.urlServicePartyData, input);
  }

  savePartiesDataInCache(input: MultiPartyEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlServicePartiesData, input);
  }

  searchHospital(filter: FilterEntity) {
    return this.httpClient.post(this.baseApiUrl + this.urlServiceSerachHospital, filter);
  }

  getSelectableCovertures(
    policyNumber: string,
    occurrenceDate: string,
    categoryCode: string,
    idTipoPortafoglio: string,
    externalPolicy: ExternalPolicy,
    referenceDate: string,
    idBeneAss?: string
  ) {
    let queryString = '?policynumber=' + policyNumber;
    if (occurrenceDate) {
      queryString += '&occurrencedate=' + occurrenceDate;
    }
    if (categoryCode) {
      queryString += '&categorycode=' + categoryCode;
    }

    if (idTipoPortafoglio) {
      queryString += '&idTipoPortafoglio=' + idTipoPortafoglio;
    }

    if (idBeneAss) {
      queryString += '&idBeneAss=' + idBeneAss;
    }
    if (referenceDate) {
      queryString += '&referenceDate=' + referenceDate;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.httpClient.post<SelectableCovertureResponse>(this.baseApiUrl +
      this.urlServiceSelectableCovertures + queryString, externalPolicy, { headers } );
  }

  // _policyModifyData => oggetto mappato in angular js su claims-finder-controller-detail.js
  savePotentialClaim(input: string, claimNumber?: string, idTypePtf?: string, extPol?: ExternalPolicy, idPotentialClaimArg?: string) {
    let obs: Observable<any>;
    if (claimNumber) {
      obs = this.httpClient.post(this.baseApiUrl + this.urlServiceSavePotentialClaim,
        {
          uuid: input, potentialClaimNumber: claimNumber,
          typePtf: idTypePtf, externaPolicy: extPol, idPotentialClaim: idPotentialClaimArg
        });
    } else {
      obs = this.httpClient.post(this.baseApiUrl + this.urlServiceSavePotentialClaim,
        { uuid: input, typePtf: idTypePtf, externaPolicy: extPol, idPotentialClaim: idPotentialClaimArg});
    }
    return obs;
  }

  loadPotentialClaim(potentialClaimNumber: string): Observable<LoadPotentialClaimResponse> {
    const queryString = '?number=' + potentialClaimNumber;
    return this.httpClient.get<LoadPotentialClaimResponse>(this.baseApiUrl + this.urlServiceLoadPotentialClaim + queryString);
  }

  catastropheEvents(rami: Array<string>, lossDate: string) {
    const queryString = '?lossDate=' + lossDate;
    return this.httpClient.post(this.baseApiUrl + this.urlServiceLoadCatastropheEvents + queryString, rami);
  }

  openingModes() {
    return this.httpClient.get(this.baseApiUrl + this.urlServiceLoadOpeningModes);
  }

  populateInjuryComponent(injuryEntityCode: InjuryEntity): Observable<InjuryEntity> {
    return this.httpClient.post<InjuryEntity>(this.baseApiUrl + this.urlServicePopulateInjuryComponent, injuryEntityCode);
  }

  claimVerifyClMadePostuma(risks: Array<string>, uuid: string) {
    const queryString = '?uuid=' + uuid;
    return this.httpClient.post(this.baseApiUrl + this.urlServiceClaimVerifyClMadePostuma + queryString, risks);
  }

  getClaimsByActor(name: string, surname: string, denomination: string) {
    let queryString: string;
    if (denomination) {
      queryString = '?denomination=' + denomination;
    } else {
      queryString = '?name=' + name + '&surname=' + surname;
    }
    return this.httpClient.get(this.baseApiUrl + this.urlServiceGetClaimsByActor + queryString);
  }

  getListConfigurationVehicle() {
    return this.httpClient.get(this.baseApiUrl + this.urlServiceConfigurationVehicle);
  }

  getIBD(serviceInput: any) {
    return this.httpClient.post(this.baseApiUrlRs + '/claims/claim/opponentData', serviceInput);
  }

  getIBDLight(licensePlate: string, chassisNumber: string, plateType: string, vehicTypeCode: string, occurrenceDate: string) {
    return this.httpClient.get(this.baseApiUrl + '/claims/ibdDataLight?' +
    'licensePlate=' + licensePlate +
    '&chassisNumber=' + chassisNumber +
    '&plateType=' + plateType +
    '&vehicTypeCode=' + vehicTypeCode +
    '&occurrenceDate=' + occurrenceDate);
  }

  populateInjuryCombo(): Observable<ComboInjuryEntity> {
    return this.httpClient.get<ComboInjuryEntity>(this.baseApiUrl + this.urlServicePopulateInjuryCombo);
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileUpload } from './model/file-upload';
import { DocumentCategory } from './model/document-category';
import { ClaimDocument } from './model/claim-document';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private baseApiUrl: string;
  private oldRestUrl: string;
  private claimsUrl = '/claims';
  private documentCategoriesUrl = '/document/categories';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject('authService') private auth: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.oldRestUrl = environment.api.portal.host + environment.api.portal.path;
  }

  getDocumentCategories(uuid: string, type: string, pesId?: string) {
    let path = this.claimsUrl;
    if (uuid) {
      path += '/' + uuid;
    }
    path += this.documentCategoriesUrl + '?type=' + type;
    if (pesId) {
      path += '&pesId=' + pesId;
    }

    return this.httpClient.get(this.baseApiUrl + path);
  }

  uploadDocument(documentCategory: DocumentCategory, file: FileUpload) {
    return this.httpClient.post(this.uploadDocumentUrl(documentCategory), file);
  }

  deleteDocument(document: ClaimDocument) {
    return this.httpClient.delete(this.deleteDocumentUrl(document));
  }

  downloadDocument(documentId: string) {
    const companyCode = this.auth.getOperator().salePoint.company ? this.auth.getOperator().salePoint.company.code : null;
    const obj = {
      downloadDocumentInput: { companyCode, documentId }
    };
    return this.httpClient.post(this.downloadDocumentUrl(), obj);
  }

  private downloadDocumentUrl() {
    return this.oldRestUrl + this.claimsUrl + '/downloadDocument';
  }

  private uploadDocumentUrl(documentCategory: DocumentCategory) {
    const path = this.claimsUrl + '/' + documentCategory.uuid + this.documentCategoriesUrl + '/' + documentCategory.categoryId;
    return this.baseApiUrl + path;
  }

  private deleteDocumentUrl(document: ClaimDocument) {
    const path = this.claimsUrl + '/' + document.uuid + this.documentCategoriesUrl + '/' + document.categoryId + '/' + document.documentId;
    return this.baseApiUrl + path;
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core/testing';
import { retry } from 'rxjs/operators';
import { CompanyRiass } from './dto/company-riass';
import { AutosoftInfoEntity } from '../autosoft-board/dto/autosoft-info-entity';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private baseApiUrl: string;
  private urlexternalportfolio = '';
  private urlservice = '/claims';
  private mapPISystemProperties: Map<string, string> =  new Map<string, string>();
  private autosoftResult: AutosoftInfoEntity[];
  private searchForm: UntypedFormGroup;
  private faqListArch: any[];
  private faqSelArch: any;
  private srcCurrent: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
    ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  archiveAssistant(faqListArch, faqSelArch, srcCurrent) {
    this.srcCurrent = srcCurrent;
    this.faqSelArch = faqSelArch;
    this.faqListArch = faqListArch;
  }

  cleanArchAssistant() {
    this.srcCurrent = '';
    this.faqSelArch = null;
    this.faqListArch = null;
  }

  getArchiveAssistant() {
    if ( this.srcCurrent) {
      return [this.srcCurrent,
      this.faqSelArch,
      this.faqListArch];
    }
    return null;
  }

  setAutosoftResult(autosoftResult: AutosoftInfoEntity[], searchForm: UntypedFormGroup) {
    this.autosoftResult = autosoftResult;
    this.searchForm = searchForm;
  }

  getAutosoftResult(): AutosoftInfoEntity[] {
    return this.autosoftResult;
  }

  getSerachForm(): UntypedFormGroup {
    return this.searchForm;
  }

  getPISystemPorperty(nameProp: string): string {
    this.urlexternalportfolio = '/claims/utility';
    if (typeof this.mapPISystemProperties.get(nameProp) === 'undefined' || this.mapPISystemProperties.get(nameProp) === null) {
      const queryString = '?name=' + nameProp;

      this.httpClient.get(this.baseApiUrl + this.urlexternalportfolio + queryString).pipe(retry(2)).subscribe(
        (propValue: string) => {
          this.mapPISystemProperties.set(nameProp, propValue);
          return propValue;
         },
         (error: any) => {}
      );
    } else {
      return this.mapPISystemProperties.get(nameProp);
    }
  }

  getTranslation(code: string, params: string) {
      return this.httpClient.get(this.baseApiUrl + '/claims/translation?code=' + code +
                  (params != null ? ('&params=' + params) : ''));
  }

  getNodeSxFitt() {
    return this.httpClient.get(this.baseApiUrl + '/claims/nodoFittizioSx');
  }

  getTypeAssets(): any {
    const typeAssets: any = [
      {
        value: 'VEICOLO',
        label: 'VEICOLO'
      },
      {
        value: 'UBICAZIONE',
        label: 'UBICAZIONE'
      },
      // {
      //   value: 'S',
      //   label: 'SOGGETTO'
      // },
      {
        value: 'ALTRO',
        label: 'ALTRO'
      }
    ];

    return typeAssets;

  }

  getAutosoftEvents(
    event: string,
    assignmentNumber: string,
    dateFrom: string,
    dateTo: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/autosoftEvents?codeMessage=' + event + '&'
      + 'assignmentNumber=' + assignmentNumber + '&'
      + 'dateFrom=' + dateFrom + '&'
      + 'dateTo=' + dateTo);
  }


  getAutosoftTransmission(
    claimNumber: string,
    assignmentNumber: string,
    dateFrom: string,
    dateTo: string,
    inError: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/autosoftTransmissions?claimNumber=' + claimNumber + '&'
      + 'assignmentNumber=' + assignmentNumber + '&'
      + 'dateFrom=' + dateFrom + '&'
      + 'dateTo=' + dateTo + '&'
      + 'inError=' + inError);
  }

  getAutosoftNoTransmission(
    claimNumber: string,
    assignmentNumber: string,
    dateFrom: string,
    dateTo: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/autosoftNoTransmissions?claimNumber=' + claimNumber + '&'
      + 'assignmentNumber=' + assignmentNumber + '&'
      + 'dateFrom=' + dateFrom + '&'
      + 'dateTo=' + dateTo);
  }

  sendAutosoft(
    claimNumber: string,
    assignmentNumber: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/sendAutosoft?claimNumber=' + claimNumber + '&'
      + 'assignmentNumber=' + assignmentNumber, null
      );
  }


  retreiveEvent(
    claimNumber: string,
    idEvento: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice +
      '/retrieveEvent?claimNumber=' + claimNumber + '&'
      + 'idEvento=' + idEvento, null
      );
  }
}

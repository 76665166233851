import {Variables} from './variables';
import {AssetSection} from './asset-section';

export class AssetInstance {
  public extraDescription: string;
  public assetCode: string;
  public showClauses: string;
  public extensionCode: string;
  public packetUnitCode: string;
  public instanceDescription: string;
  public name: string;
  public clauses: {
    clauses: []
  };
  public variableList: Variables;
  public sections: Array<AssetSection>;
  public productPremium: any;

  get(code: string): AssetSection {
    return this.sections.find(section => section.code === code);
  }
}

export class AssetInstanceWithTotal extends AssetInstance {
  public total;

  constructor() {
    super();
  }
}

import { Inject, Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { DialogueHandlerSearch } from './dialogue-handler.model';
'./routes-constants';
import { ROUTES_DIALOGUE_HANDLER_LIST, ROUTES_DIALOGUE_HANDLER_LIST_ISSUE, ROUTES_DIALOGUE_HANDLER_NEW, ROUTES_DIALOGUE_HANDLER_SUMMARY_ISSUE } from '../constants/routes-constants';
import { FormSearch } from './dialogue-handler-search.model';

@Injectable()
export class DialogueHandlerIntegrationService {

  constructor(
    protected routingService: RoutingService,
    @Inject('sessionService') protected portalSession: any,
    @Inject('coreResult') protected portalCoreResult: any,
    ) {
  }

  closeSession(id) {
    this.routingService.destroy(id);
  }

  backToPrevPage(_id, _idParentSession) {
    this.routingService.destroy(_id);
  }

  navigate(routingService: RoutingService, destinationRoute: string, routeData: any, activeRoute?: ActiveRoute) {
    console.log("b")
    routingService.navigate(destinationRoute, routeData, activeRoute ? activeRoute.id : null);
  }

  goToDialogueHandlerList(filterData: any, activeRoute: ActiveRoute = null) {
    console.log("a")
    this.navigate(this.routingService, ROUTES_DIALOGUE_HANDLER_LIST, {filterList: filterData}, activeRoute);
  }
  
  openNewDialogue(filterData: any ,activeRoute: ActiveRoute = null){
    this.navigate(this.routingService, ROUTES_DIALOGUE_HANDLER_NEW, {filterList: filterData}, activeRoute);
    }

  goToDialogueHandlerSummary(filterData: any, activeRoute: ActiveRoute = null) {
    this.navigate(this.routingService, ROUTES_DIALOGUE_HANDLER_SUMMARY_ISSUE, {filterList: filterData}, activeRoute);
  }

}

import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { NotifierService } from '@rgi/portal-ng-core';
import { DocumentsService } from '@rgi/portal-ng-common';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { AuthorizationsDetailComponent, AuthorizationsRoutingService, AuthorizationsStatesService, AuthorizationsSearchService, CommonService, AuthorizationCardStateManagerDetail } from '@rgi/authorizations-card';
import { AuthorizationsService } from '../services/authorizations-services';
import { ModalService } from '@rgi/rx/ui';
import { ActiveRoute } from '@rgi/rx/router';
import { RgiRxUserService } from '@rgi/rx/auth';
import { CommonErrorResponse, PartiesService, PartyNiceActimizeCheckResponses } from '../../../ext-rest/life/api';

@Component({
    selector: 'ac-authorizations-detail',
    templateUrl: './authorizations-detail-ext.component.html',
    styleUrls: ['./authorizations-detail-ext.component.css'],
    providers: [AuthorizationsService, PartiesService]
})
export class AuthorizationsDetailExtComponent extends AuthorizationsDetailComponent implements OnInit, OnDestroy {

    @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
    @Output() eventPropagation = new EventEmitter<any>();

    authorizationList: any[];
    warningMessages: string[] = [];

    constructor(
      protected stateService: AuthorizationsStatesService,
      protected manager: AuthorizationCardStateManagerDetail,
      public activeRoute: ActiveRoute,
      protected authorizationsSearchService: AuthorizationsSearchService,
      protected routingService: AuthorizationsRoutingService,
      protected commonService: CommonService,
      protected documentsService: DocumentsService,
      protected translate: RgiRxTranslationService,
      protected notifierService: NotifierService,
      protected modalService: ModalService,
      protected userService: RgiRxUserService,
      protected partiesService: PartiesService,
      @Inject('showDocuments') showDocumentsEnabled?: boolean) {
        super(
          stateService,
          manager,
          activeRoute,
          authorizationsSearchService,
          routingService,
          commonService,
          documentsService,
          translate,
          notifierService,
          modalService,
          userService,
          showDocumentsEnabled
        );
    }

    ngOnInit() {
      super.ngOnInit();
      const selectedAuth = this.authorizationDetail.id;
      this.partiesService.getPartyNiceActimizeCheck(selectedAuth).subscribe(
          result => {
              const res = result as any
              if (res.error) {
                  result = res as CommonErrorResponse
                  console.error('', result);
              } else {
                  result = res as PartyNiceActimizeCheckResponses
                  if (result.content) {
                      for (const partyResponse of result.content) {
                        const messages = this.partiesService.getMessageUtil().getWarningsAsSimpleStrings(partyResponse.messages.messages);
                        this.warningMessages.push(...messages);
                      }
                  }
              }
            },
          error => {
              console.error('', error);
          }
      );
    }


    ngOnDestroy() {
        super.ngOnDestroy();
    }


    getWarningMessages(msgKey: string, msgValue: string) {
      this.translate.translate(msgKey, { partyName: msgValue }).subscribe(
          translation => {
              this.warningMessages.push(translation);

          }
      );
  }

}


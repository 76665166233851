import {Injectable} from '@angular/core';
import {ModalService} from '@rgi/rx/ui';
import {RGI_QUOTATION_MENU_ITEM_CODES} from '../resources/model/common/quotation/quotation-header-data';
import {
  PncTotalsPremiumModalComponent
} from '../components/pnc-quotation-modals/pnc-totals-premium-modal/pnc-totals-premium-modal.component';
import {
  PncAccountingDetailsModalComponent
} from '../components/pnc-quotation-modals/pnc-accounting-detail-modal/pnc-accounting-details-modal.component';
import {
  PncCommissionsModalComponent
} from '../components/pnc-quotation-modals/pnc-commissions-modal/pnc-commissions-modal/pnc-commissions-modal.component';
import {
  PncPolicyDetailModalComponent
} from '../components/pnc-quotation-modals/pnc-policy-detail-modal/pnc-policy-detail-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PncQuotationMenuService {

  constructor(
    protected modalService: ModalService
  ) {
  }

  openModal(actionCode: RGI_QUOTATION_MENU_ITEM_CODES, ctx: any) {
    switch (actionCode) {
      case RGI_QUOTATION_MENU_ITEM_CODES.TOTALS_PREMIUM:
        const premiumDetailModal = this.modalService.openComponent(PncTotalsPremiumModalComponent, ctx);
        premiumDetailModal.modal.enableClickBackground = false;
        break;
      case RGI_QUOTATION_MENU_ITEM_CODES.ACCOUNTING_DETAILS:
        const contrTaxesModal = this.modalService.openComponent(PncAccountingDetailsModalComponent, ctx);
        contrTaxesModal.modal.enableClickBackground = false;
        break;
      case RGI_QUOTATION_MENU_ITEM_CODES.COMMISSIONS:
        const commissionModal = this.modalService.openComponent(PncCommissionsModalComponent, ctx);
        commissionModal.modal.enableClickBackground = false;
        break;
      case RGI_QUOTATION_MENU_ITEM_CODES.POLICY_DETAILS:
        const policyDetails = this.modalService.openComponent(PncPolicyDetailModalComponent, ctx);
        policyDetails.modal.enableClickBackground = false;
        break;
    }
  }
}

<div *ngIf="showMessageModalOperation">
        <pnc-modal-basic #confirmModal dialogClass="custom-modal">
                <div class="app-modal-header">
                  <div class="modal-title" *ngIf="!serviceError">
                        <label translate>{{ title }}</label>
                  </div>
                  <div class="modal-title" *ngIf="serviceError">
                      <label translate>lpc_system_error</label>
                 </div>
                </div>
                <div class="app-modal-body">
                  <div class="confirm">
                      <span translate>{{ customMessage }}</span>
                  </div>
                </div>
                  <div class="app-modal-footer">
                    <div class="btn-group btn-group-justified" style="margin-top: 0px; border-right: 0px !important">
                      <div class="btn-group no-border" style="border-right: 0px !important">
                        <button class="btn btn-warning btn-secondary" data-qa="modal-close-button" (click)="OnClose()" translate>{{ backLabel }}</button>
                      </div>
                      <div class="btn-group no-border" style="border-right: 0px !important" *ngIf="!!nextLabel">
                          <button class="btn btn-warning" data-qa="modal-confirm-button" (click)="onConfirm($event)" translate>{{ nextLabel }}</button>
                      </div>
                    </div>
                  </div>
         </pnc-modal-basic>
</div>


import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterCarBodyWork, OutputTrustee } from './dsc-car-bodywork-filter.model';

@Injectable({
  providedIn: 'root',
})
export class DscDriverLicenseModalService {
  private baseApiUrl: string;
  urlTrustee: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('cardPassService') private cardPassService: any,
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.urlTrustee = '/claims/trustee';
  }

  searchTrustee(filter: FilterCarBodyWork): Observable<OutputTrustee> {
    const url = `${this.baseApiUrl}${this.urlTrustee}`;
    return this.httpClient.post<OutputTrustee>(url, filter);
  }

}

<div class="rgi-ui-grid-2">
  <div class="rgi-ui-col">
    <div class="rgi-re-section-title">
      <span class="rgi-re-header-icon rgi-re-icon-third-person"></span>
      <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.THIRD_PARTY_SUBJECT</span>
    </div>
    <form [formGroup]="thirdPersonForm" class="rgi-ui-form-group">
      <rgi-rx-drop-container formControlName="thirdPersonContact" viewField="nominative"
                             data-qa="thirdPerson" [select]="'searchOrCreateSubject'"
                             (onValueChange)="selectedThirdSubject($event)" (onRemove)="removeBeneficiarySubject()">
      </rgi-rx-drop-container>
      <rgi-rx-snackbar tag="thirdPersonError"></rgi-rx-snackbar>
    </form>
  </div>
</div>

import { PolicyService } from './../../../services/policy-service';
import { LicFundsComponent } from './../../../lic-karma-funds/component/lic-funds/lic-funds.component';
import {PassFundDefinition} from '../../../lic-fund/model/PassFundDefinition';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ToolDefinition} from '../../model/tool-definition';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Fund, Profile} from '../../../lic-fund/model/profile';
import {PassProfileDefinition} from '../../../lic-fund/model/PassProfileDefinition';
import {Rule, ToolConfDefinition} from '../../model/tool-conf-definition';
import {ValidatorService} from 'angular-iban';
import {KarmaProfileDefinition} from '../../../lic-karma-funds/model/karma-profile-definition';
import {PassUtils} from '../../../invest-standalone-session/utils/PassUtils';
import { FACTOR_ERROR_KEYS, FACTOR_TOOL_CODE, PPToolCode, ToolCode } from '../../../enum/life-issue.enum';
import { LicToolUtils } from './../../../../life-card/lic-tools/utils/lic-tool-utils';
import { ProfileDistribution } from './../../../../life-card/lic-tools/model/tool-profiles-distribution';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';


@Component({
  selector: 'lic-tool',
  templateUrl: './lic-tool.component.html',
  styleUrls: ['./lic-tool.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicToolComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicToolComponent),
      multi: true
    }
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class LicToolComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator, AfterContentInit {
  private $subscriptions: Subscription[] = [];
  defaultValue = true;

  /**
   * @description
   * used to update the total amount for the investments funds. It's used on disinvestment value change
   */
  @ViewChild('funds') toolFundsComponent: LicFundsComponent;

  @Input() definition: ToolDefinition;
  @Input() investAmount: number;
  @Input() funds: any;
  @Output() defaultFundsCheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * @description
   * used to set the max limit for a disinvestment for a fund based on the investment amount on the previous step
   */
  @Input() investedProfiles: KarmaProfileDefinition[];
  @Input()
  get defaultVal(): boolean {
    return this.defaultValue;
  }
  set defaultVal(val: boolean) {
    this.defaultValue = val;
    this.formGroup.get('defaultFunds').setValue(this.defaultValue);
    this.defaultFundsCheck.emit(this.defaultValue);
  }

  /**
   * @description
   * if it's the progressive switch activated i'll return the value of the disinvested fund as the total amount
   * @see RDDL-3672
   */
  get totalInvestmentAmount(): number {
    if (!LicToolUtils.isToolProgressiveSwitch(this.definition.operationCodeId)) {
      return this.investAmount;
    } else {
      let amount = 0;
      this.filteredDisinvestmentFunds
      .map(fund => fund.id)
      .filter(dis => !!dis)
      .forEach(disinvestmentFund => {
        const disinvestValue = this.formGroup.get('disinvestment')
        .get(this.disinvestmentProfileId)
        .get(disinvestmentFund).value;
        amount += !!disinvestValue ? disinvestValue.disinvestmentAmount : 0;
      });
      return amount;
    }
  }

  /**
   * @description
   * returns 'PERC' as the sliderProp to disable the amount setting just for the progressive switch
   * @see RDDL-3672
   */
  get sliderProp() {
    if (LicToolUtils.isToolProgressiveSwitch(this.definition.operationCodeId)) {
      return 'PERC';
    }
    return;
  }

  public disinvestmentProfileId: string = null;

  private isWithDisinvestmentFunds = false;

  selectedProfilesFunds: Map<string, Fund[]> = new Map<string, Fund[]>();
  readyProfilesFunds: Map<string, boolean> = new Map<string, boolean>();
  profiles: Profile[];
  selectedProfiles: Profile[];
  allReady = false;
  ready = false;
  private emptyFactors: { [key: string]: null } = {};
  private emptyProfiles: { [key: string]: any } = {};
  private isValueNull = true;
  public selectedProfileLabel = this.translate.getImmediate('lic_selected_profiles_');
  public investmentProfileDefinitions: KarmaProfileDefinition[] = [];

  public filteredDisinvestmentFunds: PassFundDefinition[] = [];
  private readonly factorsFormKey = 'factors';

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    factors: new UntypedFormGroup({}),
    investmentProfiles: new UntypedFormControl(),
    investmentFunds: new UntypedFormGroup({}),
    disinvestment: new UntypedFormGroup({}),
    defaultFunds: new UntypedFormControl(true)
  });
  errors: any = {};

  get showProfileInvestment(): boolean {
    if (this.definition.operationCode === PPToolCode.PROGRESSIVE_SWITCH) {
      return true;
    } else if (this.definition.operationCode === PPToolCode.STOP_LOSS) {
      return true;
    }
    return this.formGroup ? !this.formGroup.get('defaultFunds').value : false;
  }

  getFilteredDisinvestmentFunds(): PassFundDefinition[] {
    if (this.definition.disinvestmentProfiles) {
      let selectedFunds: PassFundDefinition[] = [];
      const selectedProfiles: PassProfileDefinition[] = this.definition.disinvestmentProfiles
        .filter(profile => !!this.funds[profile.id]);
      selectedProfiles.forEach(profile => {
        selectedFunds = selectedFunds.concat(selectedFunds, profile.funds.filter(fund => !!this.funds[profile.id][fund.id]));
      });
      return [...new Set(selectedFunds)];
    } else {
      return [];
    }
  }

  get minThreshold(): number {
    if (!!this.definition.disinvestmentProfiles) {
      const factorValue = this.getFactorValue(FACTOR_TOOL_CODE.MIN_THRESHOLD_CODE);
      if (!!factorValue) {
        return factorValue / 100;
      }
    }
    return 0;
  }

  get maxThreshold(): number {
    if (!!this.definition.disinvestmentProfiles) {
      const factorValue = this.getFactorValue(FACTOR_TOOL_CODE.MAX_THRESHOLD_CODE);
      if (!!factorValue) {
        return factorValue / 100;
      }
    }
    return 0;
  }

  private getFactorValue(factorCode: string) {
    const factorDef = this.definition.factors.find(
      factor => factor.code === factorCode
    );
    if (!!factorDef) {
      return factorDef.value;
    }
    return null;
  }

  get noGroupVisibleFactors(): ToolConfDefinition[] {
    return this.definition.factors.filter(
      factor => factor.visible && factor.group == null
    );
  }

  get groupVisibleFactors(): ToolConfDefinition[] {
    return this.definition.factors.filter(
      factor => factor.visible && !!factor.group
    );
  }

  get showFundDistribution(): boolean {
    if (this.isStopLoss || this.isToolLockIn) {
      return true;
    }
    let conditionForProfiles = false;
    let conditionForFunds = false;
    if (!!this.definition.investmentProfiles && this.definition.investmentProfiles.length > 1) {
      conditionForProfiles = true;
    }
    if (!!this.definition.investmentProfiles) {
      this.definition.investmentProfiles.map(dis => {
        if (dis.funds.length > 1) {
          conditionForFunds = true;
        }
      });
    }
    return (conditionForProfiles || conditionForFunds);
  }

  get showDefaultFundDistributionCheckbox(): boolean {
    return this.showFundDistribution && this.definition.operationCodeId === ToolCode.SCHEDULED_PREMIUM;
  }

  get isVisible(): boolean {
    if (this.isWithDisinvestmentFunds) {
      return !!this.filteredDisinvestmentFunds.length;
    } else {
      return true;
    }
  }

  get isStopLoss(): boolean {
    return LicToolUtils.isToolStopLoss(this.definition.operationCodeId);
  }

  get isToolLockIn(): boolean {
    return LicToolUtils.isToolLockIn(this.definition.operationCodeId);
  }

  get isProgressiveSwitch(): boolean {
    return LicToolUtils.isToolProgressiveSwitch(this.definition.operationCodeId);
  }

  // controllo se c'è un solo profilo ed un solo fondo selezionabile per mostrare il riassunto
  get isOnlyOneFundAvailable() {
    if (this.isStopLoss || this.isToolLockIn) {
      return false;
    }
    return !!this.definition.investmentProfiles && this.definition.investmentProfiles.length
      && this.definition.investmentProfiles.length === 1 &&
      !!this.definition.investmentProfiles[0].funds && this.definition.investmentProfiles[0].funds
      && this.definition.investmentProfiles[0].funds.length === 1;
  }

  constructor(
    public translate: TranslationWrapperService,
    protected numberPipe: RgiCountryLayerNumberFormatPipe,
    protected policyService: PolicyService) {
  }


  ngAfterContentInit(): void {
    const sameButFree = this.definition.profilesDistribution === ProfileDistribution.SAME_BUT_FREE;
    const defaultFunds = this.isSameDistributionMandatory() || sameButFree;
    this.formGroup.get('defaultFunds').setValue(defaultFunds);
  }

  isSameDistributionMandatory(): boolean {
    return this.definition.profilesDistribution === ProfileDistribution.SAME_AS_ISSUE;
  }

  ngOnInit() {
    console.log('LIC-TOOL ' + this.definition.operationCodeId);
    if (!!this.definition.investmentProfiles && this.definition.investmentProfiles.length > 0) {
      this.investmentProfileDefinitions = this.definition.investmentProfiles
      .map(profile => PassUtils.getKarmaProfileDefinitionFrom(profile));
      this.investmentProfileDefinitions.forEach(profileDefinition => {
        (this.formGroup.get('investmentFunds') as UntypedFormGroup).addControl(profileDefinition.id, new UntypedFormControl());
      });
    }
    this.definition.factors.forEach(factor => {
      this.createFactorControl(factor);
    });
    this.initIbanField();
    if (!!this.definition.disinvestmentProfiles && this.definition.disinvestmentProfiles.length > 0) {
      this.isWithDisinvestmentFunds = true;
      this.createFormForDisinvestmentProfiles();
    } else {
      // this.investmentProfilesForm();
    }
    if (!!this.definition.investmentProfiles && this.definition.investmentProfiles.length > 0) {
      this.profiles = this.buildProfiles(this.definition.investmentProfiles);
    }
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe(el => {
        this.onChange(this.isVisible ? this.formGroup.getRawValue() : null);
      })
    );
    this.$subscriptions.push(
      this.formGroup.get('defaultFunds').valueChanges.subscribe(newValue => {
        this.defaultFundsCheck.emit(newValue);
      }),
      this.formGroup.get('disinvestment').valueChanges.subscribe(val => {
        // on disinvestment change i'll update the total amount value to refresh the info message on the top
        if (LicToolUtils.isToolProgressiveSwitch(this.definition.operationCodeId)) {
          this.selectedProfileDefinitions.forEach((profile) => {
            if (!!this.toolFundsComponent) {
              this.toolFundsComponent.totalAmount = this.getInvestmentAmountOfProfile(profile);
            }
          });
        }
      })
    );
    if (!!this.formGroup.get('factors') && !!this.formGroup.get('factors').get(FACTOR_TOOL_CODE.PAYMENTMODES_CODE)) {
      if(this.formGroup.get('factors').get(FACTOR_TOOL_CODE.PAYMENTMODES_CODE).disabled) {
        this.updateIbanVisibility()
      } else {
        this.$subscriptions.push(
          this.formGroup.get('factors').get(FACTOR_TOOL_CODE.PAYMENTMODES_CODE).valueChanges.subscribe((value) => {
            this.definition.factors = this.updateIbanVisibility();
          })
        );
      }
    }
    this.ready = true;
  }

  protected createFactorControl(factor) {
    const formControl: UntypedFormControl = new UntypedFormControl();
    (this.formGroup.get('factors') as UntypedFormGroup).addControl(factor.code, formControl);
    if (factor.readOnly) {
      formControl.disable({emitEvent: false});
    }
    if (factor.type === 'IBAN') {
      formControl.setValidators([
        Validators.required,
        ValidatorService.validateIban
      ]);
    } else {
      formControl.setValidators([Validators.required]);
    }
    this.emptyFactors[factor.code] = null;
  }

  protected initIbanField() {
    if (!!this.definition.factors.find(factor => factor.code === FACTOR_TOOL_CODE.IBAN_CODE)) {
      this.definition.factors.find(factor => factor.code === FACTOR_TOOL_CODE.IBAN_CODE).visible = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.funds) {
      this.filteredDisinvestmentFunds = this.getFilteredDisinvestmentFunds();
    }
  }

  protected updateIbanVisibility(): ToolConfDefinition[] {
    const PAYMENT_RID = '3';
    const PAYMENT_TRANSFER = '4';
    const PAYMENT_BANKACCOUNT = '5';
    // Find factor with `FACTOR_TOOL_CODE.IBAN_CODE` and get its values.
    const paymentModeDefinition = this.definition.factors.find(factor => factor.code === FACTOR_TOOL_CODE.PAYMENTMODES_CODE).values

    // Assign single value's id or null if not exactly one item.
    const definitionPaymentModeValue = paymentModeDefinition.length === 1 ? paymentModeDefinition[0].id : null
        
    // Get user-selected payment mode from form, if any.
    const formPaymentModeValue = this.formGroup.get('factors').get(FACTOR_TOOL_CODE.PAYMENTMODES_CODE).value;
        
    // Use form value or fall back to definitions if form value is not set.
    const paymentMode = formPaymentModeValue ? formPaymentModeValue : definitionPaymentModeValue

    return this.definition.factors.map(factor => {
      if (factor.code === FACTOR_TOOL_CODE.IBAN_CODE) {
        factor.visible = [PAYMENT_RID, PAYMENT_TRANSFER, PAYMENT_BANKACCOUNT].includes(paymentMode);
        this.updateIBANValidation(factor);
      } 
      return factor;
    });
  }

  protected updateIBANValidation(factor: ToolConfDefinition): void {
    const formGroup: UntypedFormGroup = this.formGroup.get(this.factorsFormKey) as UntypedFormGroup;
    const formControl: UntypedFormControl = formGroup.get(factor.code) as UntypedFormControl;

    if (!!factor.visible) {
      if (!formControl) {
        formGroup.addControl(factor.code, new UntypedFormControl());
      }
      formGroup.get(factor.code).setValidators(
        (!!factor.mandatory) ? [Validators.required, ValidatorService.validateIban] : [ValidatorService.validateIban]
      );
    } else {
      if (!!formControl) {
        formControl.clearValidators();
        formGroup.removeControl(factor.code);
      }
    }
  }

  ruleInterpreter(factor: ToolConfDefinition, errors: { [key: string]: string | boolean }): { [key: string]: any } {
    if (!!factor.rules && !!factor.rules.length) {
      factor.rules.forEach(rule => {
        if (!rule.dependencies.length) {
          Object.assign(errors, this.ruleValidator(rule, factor.code));
        } else {
          rule.dependencies.forEach((dep) => {
            const dependency = this.formGroup.get('factors').get(dep.factorCode).value;
            if (dependency === dep.rule.value) {
              Object.assign(errors, this.ruleValidator(rule, factor.code));
            }
          });
        }
      });
    }
    return errors;
  }

  ruleValidator(rule: Rule, code: string): { [key: string]: any } {
    const target: number = Number(this.formGroup.get('factors').get(code).value);
    switch (rule.ruleType) {
      case 'GE':
        if (target < Number(rule.value)) {
          return {operator: 'GE', value: rule.value, factor: this.translate.getImmediate(code)};
        }
        break;
      case 'LE':
        if (target > Number(rule.value)) {
          return {operator: 'LE', value: rule.value, factor: this.translate.getImmediate(code)};
        }
        break;
      case 'EQ':
        if (target !== Number(rule.value)) {
          return {operator: 'EQ', value: rule.value, factor: this.translate.getImmediate(code)};
        }
        break;
    }
    return {};
  }

  /** @deprecated */
  lockinStoplossInvestmentProfiles() {
    this.definition.investmentProfiles.forEach(profile => {
      (this.formGroup.get('investmentProfiles') as UntypedFormGroup).addControl(
        'p' + profile.id,
        new UntypedFormControl({
          id: profile.id,
          percentage: 100,
          amount: 0,
          funds: []
        })
      );
      this.emptyProfiles['p' + profile.id] = {
        id: null,
        percentage: 0,
        amount: 0,
        funds: []
      };
    });
  }

  createFormForDisinvestmentProfiles() {
    if (!!this.definition.disinvestmentProfiles && this.definition.disinvestmentProfiles.length > 0) {
      this.setDisinvestmentProfileId();
      const formGroup: UntypedFormGroup = new UntypedFormGroup({});
      (this.formGroup.get('disinvestment') as UntypedFormGroup).addControl(
        this.disinvestmentProfileId,
        formGroup
      );
      this.filteredDisinvestmentFunds.forEach(fund => {
        const formControl: UntypedFormControl = new UntypedFormControl();
        formGroup.addControl(fund.id, formControl);
      });
    }
  }

  /**
   * @description
   * fired on any control change, it adds validation for the factors, disinvestment amount, threshold,
   * if the defaultFunds is false, checks also the investment fund for the tools.
   */
  validate(control: AbstractControl): { [key: string]: boolean | string } | null {
    const errors: { [key: string]: boolean } = {};
    if (!this.isValueNull) {
      this.definition.factors.map(factor => {
        this.ruleInterpreter(factor, errors);
        if (factor.visible) {
          const formFactor = this.formGroup.get('factors').get(factor.code);
          if (!!formFactor && formFactor.invalid) {
            if (formFactor.hasError(FACTOR_ERROR_KEYS.CODE_MISSING) || formFactor.hasError(FACTOR_ERROR_KEYS.REQUIRED)) {
              Object.assign(errors, {codeMissing: true});
            }
            if (formFactor.hasError(FACTOR_ERROR_KEYS.ERROR_MSG)) {
              Object.assign(errors, {errorMessage: formFactor.getError(FACTOR_ERROR_KEYS.ERROR_MSG)});
            }
            if (formFactor.hasError(FACTOR_ERROR_KEYS.IBAN)) {
              Object.assign(errors, {ibanError: true});
            }
          }
        }
      });
      if (!!this.definition.disinvestmentProfiles) {
        // in case of the progressiveSwitch ill have the disinvestmentAmount as field on the disinvestment component
        LicToolUtils.isToolProgressiveSwitch(this.definition.operationCodeId) ?
          Object.assign(errors, this.disinvestmentsAmountValidation())
          :
          Object.assign(errors, this.thresholdValidation());
      }
    }
    if (!this.isVisible) {
      Object.assign(errors, { noDisinvestmentOptions: true });
    }
    if (this.isWithDisinvestmentFunds && !this.atLeastOneDisinvestmentFundSelected()) {
      Object.assign(errors, { noOptionSelected: true });
    }
    if (!this.formGroup.get('defaultFunds').value && !this.isOnlyOneFundAvailable && this.showFundDistribution) {
      // se la distibuzione fondi identica è disabilitata controllo se sono stati selezionati dei fondi
      // di investmento per ogni profilo su cui è stato investimento,
      // se l'investimento sui fondi non è completo lancio l'errore
      const investmentProfilesForm = this.formGroup.get('investmentProfiles').value;
      const investmentFundsForm = this.formGroup.get('investmentFunds').value;
      if (!!investmentProfilesForm) {
        if (Object.keys(investmentProfilesForm).map(invest => investmentFundsForm[invest]).some(value => !value)) {
          Object.assign(errors, { investmentFunds: 'Incomplete investment for the funds' });
        }
      } else {
        Object.assign(errors, { investmentProfile: 'Incomplete investment for the profiles' });
      }
    }
    this.errors = errors;
    return !!Object.keys(errors).length ? errors : null;
  }

  /**
   * @description
   * checks if it's present at least one disinvestment fund selected
   * @returns
   * boolean
   */
  atLeastOneDisinvestmentFundSelected(): boolean {
    const value = this.formGroup.get('disinvestment').value;
    return Object.keys(value).some(profileKey => {
      return Object.keys(value[profileKey])
        .map(fundKey => !!value[profileKey][fundKey])
        .some(isPresent => isPresent);
    });
  }

  thresholdValidation(): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!!this.definition.disinvestmentProfiles && this.definition.disinvestmentProfiles.length > 0) {
      const formGroup = this.formGroup.get('disinvestment').get(this.disinvestmentProfileId) as UntypedFormGroup;
      Object.keys(formGroup.controls).forEach(controlKey => {
        const formControl: UntypedFormControl = formGroup.get(controlKey) as UntypedFormControl;
        if (formControl.hasError('min') || formControl.hasError('max')) {
          Object.assign(errors, {minMaxThreshold: true, min: this.minThreshold, max: this.maxThreshold});
        }
      });
    }
    return errors;
  }

  /**
   * @see RDDL-3672
   * @returns ValidationErrors
   * @example {minMaxDisinvestmentsAmount: boolean, min | max: number}
   */
  disinvestmentsAmountValidation(): ValidationErrors {
    // per uma = maggiore di 0 e minore o uguale al importo investito precedentemente sullo step "Fondi in cui investire"
    // adattare in modo da essere esteso per assimoco
    const errors: ValidationErrors = {};
    if (!!this.definition.disinvestmentProfiles && this.definition.disinvestmentProfiles.length > 0) {
      const formGroup = this.formGroup.get('disinvestment').get(this.disinvestmentProfileId) as UntypedFormGroup;
      Object.keys(formGroup.controls).forEach(controlKey => {
        const formControl: UntypedFormControl = formGroup.get(controlKey) as UntypedFormControl;
        if (!!formControl.value && !!formControl.value.disinvestmentAmount) {
          if (formControl.hasError('min') || formControl.hasError('max')) {
            Object.assign(errors, {
              minMaxDisinvestmentsAmount: true,
              min: this.numberPipe.transform(
                this.getMinDisinvestmentsAmount(),
                this.policyService.currentLocale,
                this.policyService.getFormatterOptionsWithDecimal('currency')
              ),
              max: this.numberPipe.transform(
                this.getMaxDisinvestmentsAmount(controlKey),
                this.policyService.currentLocale,
                this.policyService.getFormatterOptionsWithDecimal('currency')
              )
            });
          }
        }
      });
    }
    return errors;
  }

  /**
   * @returns number: 1
   * @see RDDL-3672
   */
  getMinDisinvestmentsAmount(): number {
    return 1;
  }

  /**
   * @returns number
   * @description
   * the amount of the fund selected on the "fund investments" step, that will be the maxDisinvestmentsAmount
   * @see RDDL-3672
   */
  getMaxDisinvestmentsAmount(fundKey: string): number {
    if (!!this.investedProfiles.length) {

      let investedProfile;
      for (const disinvestmentProfile of this.definition.disinvestmentProfiles) {
        const foundProfile = this.investedProfiles.find(profile => profile.id === disinvestmentProfile.id);
        if (foundProfile) {
          investedProfile = foundProfile;
          break;
        }
      }

      return !!investedProfile.funds.find(fund => fund.id === fundKey) ?
      investedProfile.funds.find(fund => fund.id === fundKey).amount : 0;
    } else {
      return 0;
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }


  writeValue(obj: any): void {
    if (!!obj) {
      this.isValueNull = false;
      this.formGroup.patchValue(obj, {emitEvent: false});
      if (!this.isVisible) {
        this.onChange(null);
      }
    } else {
      this.isValueNull = true;
    }
  }

  onChange(obj: any) {
  }

  buildProfiles(profiles: Array<PassProfileDefinition>): Array<Profile> {
    return profiles.map(profile => ({
      id: profile.id,
      profileId: !!profile.profileId ? profile.profileId : profile.id,
      name: profile.description,
      funds: profile.funds.map(fund => ({
        id: fund.id,
        profileId: !!profile.profileId ? profile.profileId : profile.id,
        name: fund.description,
        percent: 0,
        selected: false,
        currency: 0,
        funds: [],
        isPercent: true,
        minPercentage: parseFloat(fund.minPercentAllocation as string) * 100,
        maxPercentage: parseFloat(fund.maxPercentAllocation as string) * 100,
        readOnly: false
      })),
      selected: false,
      percent: 0,
      currency: 0,
      isPercent: true,
      minPercentage: 0,
      maxPercentage: 100,
      readOnly: false
    }));
  }

  public get selectedProfileDefinitions(): KarmaProfileDefinition[] {
    if (!!this.formGroup.get('investmentProfiles').value) {
      return this.investmentProfileDefinitions.filter(profileDefinition => {
        return !!this.formGroup.get('investmentProfiles').value[profileDefinition.id];
      });
    } else {
      return [];
    }
  }

  /**
   * @description
   * fixed bug due to the value set on the control investmentProfiles,
   * if i don't change the value of the component it will return a number value otherwise it will return an obj
   * so i'll get the percentage field for the profile and return it dividing by 100
   * @fires
   * getPercentageInvestmentProfile()
   * @param id profile id
   */
  getInvestmentAmountOfProfile(definition: KarmaProfileDefinition) {
    if (!!this.formGroup.get('investmentProfiles').value) {
      return this.totalInvestmentAmount * this.getPercentageInvestmentProfile(definition.id);
    }
    return 0;
  }

  /**
   * @description
   * first it will check the length of the control investmentProfiles (step inside the tool called "Profili di investimento")
   * if it has length it will cycle each profile and get the percentage summed
   * @returns number
   */
  private getPercentageInvestmentProfile(id): number {
    if (!!Object.keys(this.formGroup.get('investmentProfiles').value[id]).length) {
      let percentage = 0;
      Object.keys(this.formGroup.get('investmentProfiles').value[id])
      .filter(key => key === 'percentage')
      .forEach(perc => {
        percentage += this.formGroup.get('investmentProfiles').value[id][perc];
      });

      // ritorno diviso 100 perche è la percenutale di default con le cifre decimali
      return percentage / 100;
    } else {
      return this.formGroup.get('investmentProfiles').value[id];
    }
  }

  /**
   * @Description
   * Search the correct id for disinvestmentProfiles and set variable disinvestmentProfileId with this id.
   * Used as name for formcontrol in formgroup 'disinvestment'.
   * Call in createFormForDisinvestmentProfiles during ngOnit phase
   */
  private setDisinvestmentProfileId() {
    for (const disinvestmentProfile of this.definition.disinvestmentProfiles) {
      const foundProfile = this.investedProfiles.find(profile => profile.id === disinvestmentProfile.id);
      if (!!foundProfile) {
        this.disinvestmentProfileId = foundProfile.id;
        break;
      }
    }
    if (this.disinvestmentProfileId === null) {
      console.log('Disinvestment profile not present among invested profiles on policy');
      this.disinvestmentProfileId = this.definition.disinvestmentProfiles[0].id;
    }
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PPEVOPanelComponent} from "./component/panel.component";

@NgModule({
  declarations: [
    PPEVOPanelComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [PPEVOPanelComponent]
})
export class PanelModule { }


export interface FIELD {
  name: string;
  label: string;
  visible: number;
  functions: string;
  disabled: number;
  parent: string;
  type: string;
  fields: Array<FIELD>;
}



// CONSTANTS VALUES

export const CONTRACT_BRANCHES = {
  // use these values on formFilter
  all: 'consultationTabAll',
  pc: 'consultationTabPC',
  life: 'consultationTabLife'
};

export const CONTRACT_TYPES = {
  // use these values on formFilter
  individual: 'consultationTabIndividual',
  collective: 'consultationTabCollective'
};

export const CONSULTATION_FUNCTIONS = {
  FIND_CONTRACT: 'ptfall.contract.find',
  FIND_MASTER_POLICY: 'ptfall.contract.find.master',
  FIND_APPLICATION: 'ptfall.contract.find.application'
};

export const CONSULTATION_SUBTYPES = {
  // use these values on formFilter
  master: 'consultationTabMasterPolicy',
  application: 'consultationTabApplication'
};


// FIELDS CC ContractConsultation
// Branch
export const FIELD_CC_TABPC = {
  name: CONTRACT_BRANCHES.pc,
  label: 'P&C',
  visible: 1,
  type: 'tab',
  parent: 'branchTabGroup'
} as FIELD;
export const FIELD_CC_TABLIFE = {
  name: CONTRACT_BRANCHES.life,
  label: 'Life',
  visible: 1,
  type: 'tab',
  parent: 'branchTabGroup'
} as FIELD;
export const FIELD_CC_TABALL = {
  name: CONTRACT_BRANCHES.all,
  label: 'All',
  visible: 1,
  type: 'tab',
  parent: 'branchTabGroup'
} as FIELD;
export const TABGROUP_CC_BRANCH = {
  label: 'Contract Branch',
  name: 'branchTabGroup',
  visible: 1,
  type: 'tabgroup',
  fields: [FIELD_CC_TABALL, FIELD_CC_TABPC, FIELD_CC_TABLIFE]
} as FIELD;

// Type
export const FIELD_CC_TABINDIVIDUAL = {
  name: CONTRACT_TYPES.individual,
  label: 'Individual',
  visible: 1,
  type: 'tab',
  parent: 'type',
  // functions: [PTFALL_FUNCTIONS.FIND_CONTRACT],
} as FIELD;
export const FIELD_CC_TABCOLLECTIVE = {
  name: CONTRACT_TYPES.collective,
  label: 'Collective',
  visible: 1,
  type: 'tab',
  parent: 'type',
  // functions: [PTFALL_FUNCTIONS.FIND_MASTER_POLICY, PTFALL_FUNCTIONS.FIND_APPLICATION],
  fields: []
} as FIELD;
export const TABGROUP_CC_TYPE = {
  label: 'Type',
  name: 'type',
  visible: 1,
  type: 'tabgroup',
  fields: [FIELD_CC_TABINDIVIDUAL, FIELD_CC_TABCOLLECTIVE]
} as FIELD;

// Subtype
export const FIELD_CC_MASTERPOLICY = {
  label: 'Master policy number',
  name: 'masterPolicy',
  visible: 1,
  disabled: 1,
  type: 'text',
  parent: CONSULTATION_SUBTYPES.master
} as FIELD;
export const FIELD_CC_INDIPENDENTAGENT = {
  label: 'Independent Agent',
  name: 'independentAgent',
  visible: 1,
  type: 'select',
} as FIELD;
export const FIELD_CC_BRANCHOFFICE = {
  label: 'Branch Office',
  name: 'branch',
  visible: 1,
  type: 'select',
} as FIELD;
export const FIELD_CC_TABMASTERPOLICY = {
  name: CONSULTATION_SUBTYPES.master,
  label: 'Master Policy',
  visible: 1,
  type: 'tab',
  parent: 'subtype',
  // functions: [PTFALL_FUNCTIONS.FIND_MASTER_POLICY],
  fields: [FIELD_CC_MASTERPOLICY]
} as FIELD;
export const FIELD_CC_APPLICATIONNUMBER = {
  label: 'Application number',
  name: 'applicationNumber',
  visible: 1,
  type: 'text',
} as FIELD;
export const FIELD_CC_TABAPPLICATION = {
  name: CONSULTATION_SUBTYPES.application,
  label: 'Application',
  visible: 1,
  type: 'tab',
  parent: 'subtype',
  // functions: [PTFALL_FUNCTIONS.FIND_APPLICATION],
} as FIELD;
export const TABGROUP_CC_SUBTYPE = {
  label: 'Subtype',
  name: 'subtype',
  visible: 1,
  type: 'tabgroup',
  fields: [FIELD_CC_TABMASTERPOLICY, FIELD_CC_TABAPPLICATION]
} as FIELD;

export const FIELD_CC_PRODUCTTYPE = {
  label: 'Product type',
  name: 'productType',
  visible: 1,
  type: 'select'
} as FIELD;

export const FIELD_CC_PRODUCT = {
  label: 'Product',
  name: 'product',
  visible: 1,
  type: 'select'
} as FIELD;

export const FIELD_CC_NUMBERPLATE = {
  label: 'Plate number',
  name: 'numberPlate',
  visible: 0,
  type: 'text',
} as FIELD;
export const FIELD_CC_SHIPNAME = {
  label: 'Ship name / IMO code',
  name: 'shipName',
  visible: 0,
  type: 'text',
} as FIELD;

export const FIELD_CC_CONTRACTCONDITION = {
  label: 'Contract condition',
  name: 'contractCondition',
  visible: 1,
  type: 'text'
} as FIELD;

export const FIELD_CC_SUBJECT = {
  label: 'Subject',
  name: 'subject',
  visible: 1,
  type: 'text'
} as FIELD;

export const FIELD_CC_ISSUEDATE = {
  label: 'Issue date',
  name: 'issueDate',
  visible: 0,
  type: 'date'
} as FIELD;

export const FIELD_CC_EFFECTIVEDATE = {
  label: 'Effect date',
  name: 'effectDate',
  visible: 0,
  type: 'date'
} as FIELD;

export const FIELD_CC_EXPIRATIONDATE = {
  label: 'Expiration date',
  name: 'expirationDate',
  visible: 0,
  type: 'date'
} as FIELD;

export const FIELD_CC_CONTRACTSTATES = {
  label: 'Contract states',
  name: 'contractStates',
  visible: 0,
  type: 'select'
} as FIELD;

export const FIELD_CC_CONTRACTSUBSTATES = {
  label: 'Contract substates',
  name: 'contractSubstates',
  visible: 0,
  type: 'select'
} as FIELD;

export const FIELD_CC_COMPANY = {
  label: 'Company',
  name: 'company',
  visible: 1,
  type: 'select'
} as FIELD;

//Tab limitation GDPR

export const FIELD_CC_TAB_NOT_ANONYMIZED = {
  name: 'tabNotAnonymized',
  label: 'Not Anonymized',
  visible: 1,
  type: 'tab',
  parent: 'tabsLimitations'
} as FIELD;


export const FIELD_CC_TAB_ANONYMIZED = {
  name: 'tabAnonymized',
  label: 'Anonymized',
  visible: 1,
  type: 'tab',
  parent: 'tabsLimitations',
} as FIELD;

//Extraction's types
export const TABGROUP_CC_LIMITATIONS = {
  label: 'Limitations',
  name: 'tabsLimitations',
  visible: 1,
  type: 'tabgroup',
  fields: [FIELD_CC_TAB_NOT_ANONYMIZED, FIELD_CC_TAB_ANONYMIZED]
} as FIELD;


// Contract type
export const FIELD_CC_PROPOSALNUMBER = {
  label: 'Proposal number',
  name: 'proposalNumber',
  visible: 1,
  type: 'text',
} as FIELD;
export const FIELD_CC_POLICYNUMBER = {
  label: 'Policy number',
  name: 'policyNumber',
  visible: 1,
  type: 'text',
} as FIELD;
export const FIELD_CC_QUOTENUMBER = {
  label: 'Quote number',
  name: 'quoteNumber',
  visible: 1,
  type: 'text',
} as FIELD;
export const FIELD_CC_TAB_CONTRACTTYPE = {
  label: 'Contract type',
  name: 'contractType',
  visible: 1,
  disabled: 1,
  type: 'select',
} as FIELD;



import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimDocument } from '../../../new-payment/domain/claim-document';
import { NewPaymentService } from '../../../new-payment/new-payment.service';
import { PreviewDocModalComponent } from '../../../new-payment/preview-doc-modal/preview-doc-modal.component';
import { NoteComponent } from '../../objects/note.component';

@Component({
  selector: 'claims-document-note',
  templateUrl: './document-note.component.html',
  styleUrls: ['./document-note.component.scss']
})
export class DocumentNoteComponent implements NoteComponent, OnInit {
  @Input() data: any;
  @Input() rechargeEventEmitter: EventEmitter<boolean>;
  currencyStyle = '';
  rightColumnStyle = '';
  leftColumnStyle = '';
  document: ClaimDocument;

  constructor(
    @Inject('eventService') private eventService: any,
    @Inject('claimsDocumentService') private claimsDocumentService: any,
    private modalService: NgbModal,
    private newPaymentService: NewPaymentService) { }

  ngOnInit() {
    // this.rightColumnStyle = this.getRightColumnStyle();
    this.leftColumnStyle = this.getLeftColumnStyle();
  }

  private getLeftColumnStyle() {
    return !this.data.hasRightColumn ? 'hasOnlyLeft' : '';
  }

  getPreviewDocument() {
    this.eventService.broadcastEvent('start-loader');

    this.claimsDocumentService.preview(this.data, this.data.claim, null, 'documentId');

    // this.newPaymentService.previewDocument(idDocument)
    //   .subscribe((response: any) => {
    //     this.eventService.broadcastEvent('stop-loader');
    //     this.addMimeTypeToDocument(response.draftDocument.file);
    //     this.document = new ClaimDocument();
    //     this.document.file = response.draftDocument.file.binary;
    //     this.openPreviewModal();
    //   },
    //     (error: any) => {
    //       console.log(error);
    //       this.eventService.broadcastEvent('stop-loader');
    //     });
  }

  // private openPreviewModal() {
  //   const modalRef = this.modalService.open(PreviewDocModalComponent, {
  //     windowClass: 'basic',
  //     size: 'lg'
  //   });
  //   modalRef.componentInstance.document = this.document;
  // }

  // private addMimeTypeToDocument(file: any) {
  //   const fileType = file.mimeType;
  //   if (fileType.toUpperCase() === 'application/pdf'.toUpperCase() || fileType.toUpperCase() === 'docx'.toUpperCase()) {
  //     file.binary = 'data:application/pdf;base64,' + file.binary;
  //   } else if (fileType.toUpperCase() === 'txt'.toUpperCase()) {
  //     file.binary = 'data:text/plain;base64,' + file.binary;
  //   } else if (fileType.toUpperCase() === 'jpeg'.toUpperCase()) {
  //     file.binary = 'data:image/jpeg;base64,' + file.binary;
  //   } else if (fileType.toUpperCase() === 'png'.toUpperCase()) {
  //     file.binary = 'data:image/png;base64,' + file.binary;
  //   }
  // }

}

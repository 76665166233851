import { Injectable } from '@angular/core';
import { ActiveRoute, RgiRxRouteData, RoutingService } from '@rgi/rx/router';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import {ROUTES_HOME} from '@rgi/anag';

@Injectable()
export class ContractConsultationIntegrationService {

  constructor(protected routingService: RoutingService,
    protected i18nService: RgiRxTranslationService) {
  }

  navigate(routingService: RoutingService, destinationRoute: string, routeData: RgiRxRouteData<any>, activeRoute?: ActiveRoute) {
    routingService.navigate(destinationRoute, routeData, activeRoute ? activeRoute.id : undefined);
  }

  backToHome(activeRoute: ActiveRoute) {
    this.routingService.navigate(ROUTES_HOME, {}, activeRoute.id);
  }

  changeTitle(id: string, keyTitle: string) {}

  backToPrevPage(_id: any, _idParentSession: any) {
    this.routingService.destroy(_id);
  }

  openSubjectDetail(_subject: any, _idSession: string) {
    /* on portal is opened the card of subject detail the in angular8 native doesn't exist */
  }
}

import { Injectable } from '@angular/core';
import { BeneficiaryCathegory} from '../enum/life-issue.enum';
import { LifeSessionService } from './life-session-service';

@Injectable()
export class LayeredRuleService {

    private readonly LAYER_CH = {
      COUNTRY_CH: 'CH',
      BENEFICIARY_CATHEGORY_CH: {
        MORTE_3A: '4',
        MORTE_3B: '5',
      }
    };

    private _context = 'it';

    get context() {
      return this._context.toLocaleUpperCase();
    }
    set context(c) {
      this._context = c;
    }

    constructor(protected lifeSessionService: LifeSessionService) {}

    public isRequiredChekRolesIssuePolicy(currCountry?: string): boolean {
      const context = currCountry && currCountry.toLocaleUpperCase() || this.context;
      switch (context) {
        case this.LAYER_CH.COUNTRY_CH:
          return true;
        default:
          return false;
      }
    }

    public isDeathBeneficiary(category: string, currCountry?: string): boolean {
      // Il beneficiario morte corrisponde a '2' sul layer italia. Su svizzera (CH) i codici vengono estesi con '4' e '5' (3a, 3b)
      const context = currCountry && currCountry.toLocaleUpperCase() || this.context;
      switch (context) {
        case this.LAYER_CH.COUNTRY_CH:
          return (BeneficiaryCathegory.MORTE === category ||
            this.LAYER_CH.BENEFICIARY_CATHEGORY_CH.MORTE_3A === category ||
            this.LAYER_CH.BENEFICIARY_CATHEGORY_CH.MORTE_3B === category);

        default:
          return (BeneficiaryCathegory.MORTE === category);
      }
    }

}

<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    <span>{{title?.includes('_GP_') ? (title | translate) : (title)}}</span>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button *ngIf="!consultationMode" rgi-rx-button color="secondary"
            (click)="modalClose.emit()" data-qa="rgi-gp-btn-back" translate>
      _GP_._BTN_._UNDO_
    </button>
    <button *ngIf="!consultationMode" rgi-rx-button color="primary" [disabled]="selectedOptions.length === 0"
            (click)="onConfirm()" data-qa="rgi-gp-btn-confirm" translate>
      _GP_._BTN_._CONFIRM_
    </button>
    <button *ngIf="consultationMode" rgi-rx-button color="primary"
      (click)="modalClose.emit()" data-qa="rgi-gp-btn-close" translate>
      _GP_._BTN_._CLOSE_
    </button>
  </rgi-rx-panel-footer>
  <rgi-rx-datatable sortable="true" rgi-rx-qa="rgi"
                    [data]="data" [schema]="schema"
                    [selectable]="!consultationMode"
                    [sortable]="false"
                    [pageOptions]="[5,10,20,50]"
                    (select)="updateSelection($event)">
  </rgi-rx-datatable>
</rgi-rx-panel>

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {RgiRxDatatableComponent, RgiRxTableComponent, TableSchema} from '@rgi/rx/ui';
import {MULTIPLE_SUBSTITUTION_SCHEMA} from "./multiple-substitution-schema";
import {Policy} from "../../../../models/policyList";


@Component({
  selector: 'multiple-substitution',
  templateUrl: './multiple-substitution.component.html',
  styleUrls: ['./multiple-substitution.component.css']
})
export class MultipleSubstitutionComponent implements OnInit, OnChanges {

  @Input() policies: Array<Policy>;
  @Output() selectedPolicy: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(RgiRxDatatableComponent) table: RgiRxDatatableComponent<any>;

  tableData: Array<Policy> = [];
  tableSchema: TableSchema = MULTIPLE_SUBSTITUTION_SCHEMA;
  pageOptions: number[] = [10, 20, 50];
  selectedRow:any;
  isOnePolicy:boolean;

  constructor() {
  }
  ngOnInit() {
    this.checkIfSinglePolicy()
  }
  checkboxSelected(ctx:any, event:any){
    this.selectedRow= event.target.checked ? ctx.row : null;
    this.selectedPolicy.emit(this.selectedRow);
  }

  ngOnChanges(changes: SimpleChanges){
    this.updateTable(changes.policies.currentValue)
  }

  updateTable(data:any){
    if(this.table){
      this.table.dataSource.update(data);
      this.checkIfSinglePolicy()
    }
  }
  checkIfSinglePolicy(){
    this.isOnePolicy=this.policies.length===1;
    this.selectedRow = this.isOnePolicy ? this.policies[0] : this.selectedRow;
    this.selectedPolicy.emit(this.selectedRow);
  }
  delete(row:any){
    this.policies.splice(this.policies.indexOf(row), 1);
    if(this.selectedRow===row){
      this.selectedRow = null;
    }
    this.table.dataSource.update(this.policies);
    this.checkIfSinglePolicy()
  }
}

import {RgiRxFormControlDirective} from '../form-elements/rgi-rx-form-control.directive';
import {InjectionToken} from '@angular/core';

export interface RgiRxDateTimeMinValidationError {
  rgiRxDateTimeMin: {
    min: any;
    actual: any;
  };
}

export interface RgiRxDateTimeMaxValidationError {
  rgiRxDateTimeMax: {
    max: any;
    actual: any;
  };
}

export interface RgiRxDateTimeFilterValidationError {
  rgiRxDateTimeFilter: boolean;
}

export interface RgiRxDateTimeInRangeValidationError {
  rgiRxDateTimeRange: boolean;
}

export interface RgiRxParseValidationError {
  rgiRxDateTimeParse: {
    text: string;
  };
}


export interface RgiRxDateTimeChange<T> {
  source: RgiRxFormControlDirective<T>;
  value: T | T[];
  input: HTMLElement;
}

export type RgiRxDateTimeFilterFn<T> = (date: T | any) => boolean;


export interface RgiRxDatePickerConfig {
  openOnClick: boolean;
  showRemoveButton: boolean;
}

export const RGI_RX_DEFAULT_DATEPICKER_CONFIG: RgiRxDatePickerConfig = {
  openOnClick: true,
  showRemoveButton: false
};

export const RGI_RX_DATEPICKER_CONFIG = new InjectionToken<RgiRxDatePickerConfig>('RGI_RX_DATEPICKER_CONFIG');

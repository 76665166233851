import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {RgiRxLogger} from '@rgi/rx';
import {
  AnagIssueApiSubjectApi,
  AnagIssueCreateSubjectRequest,
  AnagIssueGetSubjectQueryString,
  AnagIssueSearchResultRequest,
  AnagIssueSubject,
  AnagIssueSubjectsFilter
} from './anag-issue';
import {AnagIssueSubjectResourceService} from './anag-issue-resource';

@Injectable({
  providedIn: 'root'
})
export class AnagIssueSubjectBusinessService {
  private usePolicyRole = true;

  constructor(
    private subjectResourceService: AnagIssueSubjectResourceService,
    protected logger: RgiRxLogger
  ) {
  }

  getBySearchResultRequest(
    searchRequest: AnagIssueSearchResultRequest
  ): Observable<Array<AnagIssueSubject>> {
    return this.subjectResourceService.getSubjectsByFilterPost(
      this.anagIssueSubjectsFilter(searchRequest)
    ).pipe(share());
  }

  createSubject(subject: AnagIssueApiSubjectApi, activeRoute: any): Observable<AnagIssueSubject> {
    return this.subjectResourceService.createSubject(
      this.anagIssueCreateSubjectRequest(subject),
      activeRoute
    ).pipe(
      share(),
      map(resSubj => {
        return this.anagIssueSubject(resSubj);
      })
    );
  }

  getSubject(subjectId: string, nodeId: string): Observable<AnagIssueSubject> {
    return this.subjectResourceService.getSubject(
      subjectId,
      nodeId
    ).pipe(
      share(),
      map(resSubj => {
        resSubj.fiscalCode = this.getFiscalCode(resSubj);
        return resSubj;
      })
    );
  }

  getSubjectComplete(subjectId: string, nodeId: string): Observable<AnagIssueApiSubjectApi> {
    return this.subjectResourceService.getSubjectComplete(
      subjectId,
      nodeId
    ).pipe(
      share(),
      map(resSubj => {
        resSubj.fiscalCode = this.getFiscalCode(resSubj);
        return resSubj;
      })
    );
  }

  putSubject(subject: AnagIssueApiSubjectApi): Observable<AnagIssueApiSubjectApi> {
    return this.subjectResourceService.putSubject(
      subject
    ).pipe(
      share()
    );
  }

  public getFiscalCode(subject: AnagIssueSubject): string {
    let result = subject.fiscalCode;
    if (!
        result
      && subject.partyKey
      && subject.partyKey[0]
      && subject.partyKey[0].key1
      && subject.partyKey[0].key1.type
      && subject.partyKey[0].key1.type.codice === '1'
    ) {
      result = subject.partyKey[0].key1.value;
    }
    return result ? result : '';
  }

  public updateSubject(subject: AnagIssueSubject) {

  }

  protected anagIssueSubjectsFilter(searchRequest: AnagIssueSearchResultRequest): AnagIssueSubjectsFilter {
    const request: AnagIssueSubjectsFilter = {
      filter: {
        address: {
          baseAddress: {}
        },
        nodeId: searchRequest.nodeId,
        name: searchRequest.lastName,
        partyKey: searchRequest.partyKey,
        subsysReference: {},
        surnameOrCompanyName: searchRequest.firstName,
        policyRole: undefined
      }
    };

    if (this.usePolicyRole) {
      request.filter.policyRole = searchRequest.policyRole;
    }

    this.logger.debug('AnagIssueSubjectBusinessService::anagIssueSubjectsFilter() - request', request);

    return request;
  }

  protected anagIssueGetSubjectQueryString(searchRequest: AnagIssueSearchResultRequest): AnagIssueGetSubjectQueryString {
    const getSubjectQueryString = new AnagIssueGetSubjectQueryString();
    getSubjectQueryString.name = searchRequest.firstName;
    getSubjectQueryString.surname = searchRequest.lastName;
    return getSubjectQueryString;
  }

  protected anagIssueSubject(subject: AnagIssueApiSubjectApi): AnagIssueSubject {
    const result = new AnagIssueSubject();
    result.name = subject.name;
    result.node = subject.node;
    result.birthAddress = subject.birthAddress;
    result.dateOfBirth = subject.dateOfBirth;
    result.denomination = subject.denomination;
    result.emails = subject.emails;
    result.fiscalCode = subject.fiscalCode;
    result.idLatestPhotos = subject.idLatestPhotos;
    result.objectId = subject.objectId;
    result.surname = subject.surname;

    return result;
  }

  protected anagIssueCreateSubjectRequest(subject: AnagIssueApiSubjectApi): AnagIssueCreateSubjectRequest {
    // TODO: bring to explicit creation
    return {
      subject
    };
  }


}

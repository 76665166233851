import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthHttpService} from './auth-http.service';
import {mergeMap, tap} from 'rxjs/operators';

@Injectable()
export class TokenAuthorizationInterceptorService implements HttpInterceptor {

  constructor(private httpKernel: AuthHttpService) {
  }

  intercept(req: HttpRequest<any>, nextHandler: HttpHandler): Observable<HttpEvent<any>> {
    return this.httpKernel.handlePreMatchingRefreshTokenFn$()
      .pipe(
        mergeMap(token => {
          return nextHandler.handle(this.httpKernel.setAuthorization(req, token))
            .pipe(
              mergeMap(next => this.httpKernel.handleRefreshTokenFn(next)),
              tap(next => {
                  this.httpKernel.handleRefreshTokenResponseHeaders(next);
              })
            );
        })
      );
  }
}

import { Factor } from '../../../models/factor.model';
import { Component, ViewEncapsulation, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-radio-group-factor',
  templateUrl: './lpc-radio-group-factor.component.html',
  styleUrls: ['./lpc-radio-group-factor.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class LpcRadioGroupFactorComponent implements OnInit {
  value: string;
  options = [];
  config: Factor;
  group: UntypedFormGroup;
  selectedValue = null;
  prevVal: string;
  modifiable: string;
  mandatory: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.value === null || this.config.value === EMPTY_STR || this.config.value === '-1') {
      this.selectedValue = null;
      this.prevVal = null;
    } else {
      this.selectedValue = this.config.value;
      this.prevVal = this.config.value;
    }
    this.group.controls[this.config.code].setValue(this.selectedValue);
    if (this.config.compulsory) {
      this.mandatory = 'Obbligatorio';
      this.group.controls[this.config.code].setValidators(Validators.required);
    } else {
      this.mandatory = 'Non obbligatorio';
    }
    if (this.config.modifiable) {
      this.modifiable = 'Modificabile';
      this.group.controls[this.config.code].enable();
    } else {
      this.modifiable = 'Non modificabile';
      this.group.controls[this.config.code].disable();
    }
  }

  setFactor(factor: Factor, form: UntypedFormGroup) {
    this.config = factor;
    this.value = this.config.description;
    for (const key in this.config.values) {
      if (key) {
        const valore: { label: string; value: string; } = {label: this.config.values[key].description,
          value: this.config.values[key].value};
        this.options.push(valore);
      }
    }
    this.group = form;
  }

  onClick() {
    if (this.prevVal !== this.group.controls[this.config.code].value) {
      this.el.nativeElement.dispatchEvent(new Event('changeVal', {bubbles: true}));
      this.prevVal = this.group.controls[this.config.code].value;
    }
  }

}

<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="anag-icon-m rgi-ui-icon-subject_data"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>_ANAG_._LABEL_._IDENTIFICATION_DATA_</span>
    <span class="rgi-ui-btn-close" (click)="actionClose()" data-qa="close-modal"></span>
  </div>
  <ng-container [formGroup]="identificationDataForm">
    <div class="rgi-ui-panel-content" *ngIf="stateMgr.getState$() | async as state">
      <div class="rgi-ui-grid-2">
        <div class="rgi-ui-col" *ngIf="identificationDataForm.get('language')">
          <rgi-rx-form-field>
            <label rgiRxLabel translate>_ANAG_._LABEL_._LANGUAGE_</label>
            <select rgiRxNativeSelect formControlName="language" data-qa="language">
              <option selected></option>
              <option *ngFor="let lang of languageList" [value]="lang.codice">
                {{lang.descrizione}}
              </option>
            </select>
          </rgi-rx-form-field>
        </div>
        <ng-container *ngIf="!stateMgr.isPartyPhysical" [formGroup]="legalPartyForm">
          <div class="rgi-ui-col" *ngIf="legalPartyForm.get('companyName')">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="companyName" data-qa="company-name">
              <label rgiRxLabel translate>_ANAG_._LABEL_._COMPANY_NAME_</label>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="legalPartyForm.get('openDate')">
            <rgi-rx-form-field style="display: contents">
              <div style="display: block">
                <label rgiRxLabel translate>_ANAG_._LABEL_._OPEN_DATE_</label>
                <div style="display:flex;">
                  <input [rgiRxDateTimeInput]="openDate" style="flex: 1" formControlName="openDate" max="maxDate"
                    data-qa="open-date" selectMode="single" rangeSeparator="-" placeholder="{{datepickerPlaceholder}}"
                    #inputToTriggerOpenDate="rgiRxDateTimeInput">
                  <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerOpenDate"></button>
                </div>
              </div>
              <rgi-rx-date-time #openDate pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="legalPartyForm.get('regCountry')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._REG_COUNTRY_</label>
              <select rgiRxNativeSelect formControlName="regCountry" data-qa="regCountry">
                <option selected></option>
                <option *ngFor="let country of countriesList" [value]="country.abbreviation">
                  {{country.description}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="stateMgr.isPartyPhysical" [formGroupName]="'physicalPartyForm'">
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('honorTitle')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._HONOR_TITLE_</label>
              <select rgiRxNativeSelect formControlName="honorTitle" data-qa="honorTitle">
                <option selected></option>
                <option *ngFor="let honTitle of honorTitleList" [value]="honTitle.codice">
                  {{honTitle.descrizione}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('surname')">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="surname" data-qa="rgi-anag-surname">
              <label rgiRxLabel translate>_ANAG_._LABEL_._SURNAME_</label>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('birthName')">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="birthName" data-qa="rgi-anag-second-surname">
              <label rgiRxLabel translate>_ANAG_._LABEL_._BIRTH_SURNAME_</label>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('name')">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="name" data-qa="rgi-anag-name">
              <label rgiRxLabel translate>_ANAG_._LABEL_._NAME_</label>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('gender')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._SEX_</label>
              <select rgiRxNativeSelect formControlName="gender" data-qa="gender">
                <option selected></option>
                <option *ngFor="let gender of genders" [value]="gender.codice">{{gender.descrizione | translate}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('genderIdentity')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._GENDER_IDENTITY_</label>
              <select rgiRxNativeSelect formControlName="genderIdentity" data-qa="gender-identity">
                <option selected></option>
                <option *ngFor="let gender of genderIdentities" [value]="gender.codice">{{gender.descrizione}}</option>
              </select>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('birthDate')">
            <rgi-rx-form-field style="display: contents">
              <div style="display: block">
                <label rgiRxLabel translate>_ANAG_._LABEL_._BIRTH_DATE_</label>
                <div style="display:flex;">
                  <input [rgiRxDateTimeInput]="birthDate" style="flex: 1" formControlName="birthDate"
                    data-qa="rgi-anag-birth-date" max="maxDate" selectMode="single" rangeSeparator="-"
                    placeholder="{{datepickerPlaceholder}}" #inputToTriggerBirthDate="rgiRxDateTimeInput">
                  <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerBirthDate"></button>
                </div>
              </div>
              <rgi-rx-date-time #birthDate pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('citizenship')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._CITIZENSHIP_</label>
              <select rgiRxNativeSelect formControlName="citizenship" data-qa="rgi-anag-citizenship">
                <option selected></option>
                <option *ngFor="let country of stateMgr.countriesList" [value]="country.abbreviation">
                  {{country.description}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('maritalStatus')">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._CIVIL_STATE_</label>
              <select rgiRxNativeSelect formControlName="maritalStatus" data-qa="rgi-anag-marital-status">
                <option selected></option>
                <option *ngFor="let maritalStatus of maritalStatusList" [value]="maritalStatus.codice">
                  {{maritalStatus.descrizione}}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <rgi-rx-form-field class="rgi-ui-col" *ngIf="physicalPartyForm.get('citizenshipPermit')">
            <label rgiRxLabel translate>_ANAG_._LABEL_._CITIZENSHIP_PERMIT_</label>
            <select rgiRxNativeSelect formControlName="citizenshipPermit" data-qa="rgi-anag-citizenship-permit">
              <option selected></option>
              <option *ngFor="let permit of state.citizenshipPermitTypes" [value]="permit.code">
                {{permit.description}}
              </option>
            </select>
          </rgi-rx-form-field>

          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('citizenshipExpire')">
            <rgi-rx-form-field style="display: contents">
              <div style="display: block">
                <label rgiRxLabel translate>_ANAG_._LABEL_._CITIZENSHIP_EXPIRE_</label>
                <div style="display:flex;">
                  <input [rgiRxDateTimeInput]="citizenshipExpire" style="flex: 1" formControlName="citizenshipExpire"
                    max="maxDate" data-qa="open-date" selectMode="single" rangeSeparator="-" placeholder="{{datepickerPlaceholder}}"
                    #inputToTriggerOpenDate="rgiRxDateTimeInput">
                  <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                    [rgiRxDateTimeTrigger]="inputToTriggerOpenDate"></button>
                </div>
              </div>
              <rgi-rx-date-time #citizenshipExpire pickerType="calendar" [hour12Timer]="true"></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>


          <div class="rgi-ui-col" *ngIf="physicalPartyForm.get('sonsNumber')">
            <rgi-rx-form-field>
              <input type="number" rgiRxInput min="0" formControlName="sonsNumber" data-qa="rgi-anag-sons">
              <label rgiRxLabel translate>_ANAG_._LABEL_._SONS_</label>
            </rgi-rx-form-field>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="stateMgr.isPartyPhysical" [formGroupName]="'physicalPartyForm'">
        <div class="rgi-anag-w-100">
          <div class="rgi-ui-title-1 rgi-ui-info rgi-anag-mb-m">
            <span class="rgi-ui-icon-marker"></span>
            <span translate>_ANAG_._LABEL_._BIRTH_PLACE_</span>
          </div>
          <rgi-anag-uma-address data-qa="birth-place-address" [addressType]="birthPlaceEnum()"
            [inputAddress]="getBirthAddress()" (outputAddress)="updateBirthAddress($event)"
            [parentForm]="birthAddressForm">
          </rgi-anag-uma-address>
        </div>
      </ng-container>
      <rgi-rx-form-field *ngIf="identificationDataForm.get('economicActivitySubgroup')">
        <label rgiRxLabel translate>_ANAG_._LABEL_._SAE_</label>
        <select rgiRxNativeSelect formControlName="economicActivitySubgroup" data-qa="economicActivitySubgroup">
          <option selected></option>
          <option *ngFor="let subgroup of economicActivitySubgroups" [value]="subgroup.codice">
            {{subgroup.descrizione}}
          </option>
        </select>
      </rgi-rx-form-field>
      <rgi-rx-form-field *ngIf="identificationDataForm.get('economicActivityGroup')">
        <label rgiRxLabel translate>_ANAG_._LABEL_._AE_</label>
        <select rgiRxNativeSelect formControlName="economicActivityGroup" data-qa="economicActivityGroup">
          <option selected></option>
          <option *ngFor="let subgroup of economicActivityGroups" [value]="subgroup.codice">
            {{subgroup.descrizione}}
          </option>
        </select>
      </rgi-rx-form-field>
      <rgi-rx-form-field *ngIf="stateMgr.isPartyPhysical" [formGroupName]="'physicalPartyForm'">
        <input type="checkbox" style="margin-left: 1rem;" rgiRxInput formControlName="politicallyExp" data-qa="rgi-anag-politically-exposed" [checked]="physicalPartyForm.get('politicallyExp').value">
        <label rgiRxLabel translate>_ANAG_._LABEL_._POTICALLY_EXP</label>
      </rgi-rx-form-field>


      <ng-container *ngIf="stateMgr.countriesList" [formGroup]="partyKeyForm">
        <div class="rgi-ui-title-1 rgi-ui-info rgi-anag-mb-m">
          <span class="rgi-ui-icon-marker"></span>
          <span translate>_ANAG_._LABEL_._SUBJECT_UNIQUE_KEY_</span>
        </div>
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._NATION_</label>
          <select rgiRxNativeSelect formControlName="country" data-qa="party-key-country">
            <option *ngFor="let country of stateMgr.countriesList" [value]="country.abbreviation">
              {{country.description}}</option>
          </select>
        </rgi-rx-form-field>
        <div *ngIf="state.partyKeyConf">
          <rgi-rx-form-field *ngIf="partyKeyForm.get('partyKey1') && state.partyKeyConf.key1">
            <input rgiRxInput formControlName="partyKey1" (blur)="onPartyKeyChange('partyKey1')"
              data-qa="rgi-anag-party-key1">
            <label rgiRxLabel>{{state.partyKeyConf.key1.label}}</label>
          </rgi-rx-form-field>
          <button type="button" class="rgi-ui-btn rgi-ui-btn-info rgi-anag-w-100"
            [disabled]="isCalculatePartyKeyDisabled()" data-qa="calculate-party-key" (click)="calculatePartyKey()"
            *ngIf="showCalculate" translate>
            _ANAG_._BTN_._CALCULATE_
          </button>
          <rgi-rx-form-field *ngIf="partyKeyForm.get('partyKey2') && state.partyKeyConf.key2">
            <input rgiRxInput formControlName="partyKey2" (blur)="onPartyKeyChange('partyKey2')"
              data-qa="rgi-anag-party-key2">
            <label rgiRxLabel>{{state.partyKeyConf.key2.label}}</label>
          </rgi-rx-form-field>
          <rgi-rx-form-field *ngIf="partyKeyForm.get('partyKey3') && state.partyKeyConf.key3">
            <input rgiRxInput formControlName="partyKey3" (blur)="onPartyKeyChange('partyKey3')"
              data-qa="rgi-anag-party-key3">
            <label rgiRxLabel>{{state.partyKeyConf.key3.label}}</label>
          </rgi-rx-form-field>
        </div>
      </ng-container>
      <ng-container [formGroup]="legalPartyForm" *ngIf="!this.stateMgr.isPartyPhysical">
        <rgi-rx-form-field *ngIf="legalPartyForm.get('corporateSector')">
          <label rgiRxLabel translate>_ANAG_._LABEL_._CORPORATE_SECTOR_</label>
          <select rgiRxNativeSelect formControlName="corporateSector">
            <option selected></option>
            <option *ngFor="let sector of corporateSectors" [value]="sector.codice">
              {{sector.descrizione}}
            </option>
          </select>
        </rgi-rx-form-field>
          </ng-container>
      <ng-container [formGroup]="identificationDataForm" *ngIf="isHidden === false || !this.stateMgr.isPartyPhysical || inputParty.subjectType.codice === '2'">
        <rgi-rx-form-field>
          <input rgiRxInput formControlName="leiCode" data-qa="rgi-anag-lei-code" (blur)="onLeiCodeChange('leiCode')">
          <label rgiRxLabel translate>_ANAG_._LABEL_._LEI_CODE_</label>
        </rgi-rx-form-field>
      </ng-container>

    </div>
    <rgi-rx-snackbar tag="data-tag"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="errorMsg-tag"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="privacy-tag"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="fiscalCodeMsg-tag"></rgi-rx-snackbar>
    <div class="rgi-ui-panel-footer">
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
        translate>_ANAG_._BTN_._CANCEL_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" [disabled]="isDisabled"
        (click)="updateIdentificationData()" data-qa="anag-btn-confirm" translate>_ANAG_._BTN_._CONFIRM_
      </button>
    </div>
  </ng-container>
</div>

<template #documentModalContainerRef></template>
<div class="nmf_cont_dati" *ngIf="documents">
    <!-- Input Documents -->
    <div class="pnc_cont_doc_titsez  pnc_cont_titsez_color">
        <span class="header-icon rgifont rgi-dati_aggiuntivi nfm_font_size"></span>
        <span class="inputDoc header-text" translate>PORTAL_NG_COMMON.INPUT_DOCUMENTS</span>
        <button type="button" *ngIf="addDocumentEnabled" class="btn btn-default btn-quotation-detail"
            (click)="addDocument()" [disabled]="!uploadDownloadEnabled">{{'PORTAL_NG_COMMON.ADD' | translate}}<span
                class="glyphicon glyphicon-plus"></span>
        </button>
    </div>
    <div *ngFor="let document of documents.inputDocuments">
        <!-- intestazione -->
        <div class="unit-container pnc-document-container pnc-document-container-color">
            <div class="row">
                <div class="col-md-7 col-sm-8">
                    <span class="icon rgifont" [class.rgi-plus]="!document?.open" [class.rgi-minus]="document?.open"
                        (click)="document.open = !document.open" [attr.aria-expanded]="!document?.open">
                    </span>
                    <span class="icon rgi-nmf-file"></span>
                    <b class="descrDocument pnc-file-name-color">{{document.description}}</b>
                    <span class="pnc_rounded_edge hidden-xs">{{document.files.length}} di {{document.max}} files
                        inseriti</span>
                </div>
                <div class="col-md-5 col-sm-4 colRight">
                    <div class="container-element">
                        <span class="pnc-status pnc-status-color">{{ 'PORTAL_NG_COMMON.STATE' | translate }}:</span>
                        <span [ngClass]="{'pnc-status-border': uploadFileEnabled}"
                            class="rgifont font-survey top-margin-ico rgi-survey_ko font-survey-ko"
                            *ngIf="document.files.length < document.min"></span>
                        <span [ngClass]="{'pnc-status-border': uploadFileEnabled}"
                            class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok"
                            *ngIf="document.files.length >= document.min"></span>
                        <span *ngIf="(document.toBeValidated)">
                            <span class="pnc-status pnc-status-color">{{ 'PORTAL_NG_COMMON.VALIDATION' | translate }}:</span>
                            <span
                                [ngClass]="{'pnc_element_validated' : arInpDocValidation[document.id], 'pnc-status-border': uploadFileEnabled}"
                                class="rgi-pnc rgi-pnc-seal"></span>
                        </span>
                        <button type="button" class="btn pnc_element_blue pnc-upload" *ngIf="uploadFileEnabled"
                            (click)="uploadFile(document.id)"
                            [disabled]="!uploadDownloadEnabled || document.files.length == document.max"
                            >{{'PORTAL_NG_COMMON.UPLOAD' | translate}} <span class="rgifont rgi-upload"></span>
                        </button>
                        <button type="button" *ngIf="deleteDocumentEnabled && !document.mandatory" class="btn pnc_element_blue pnc-upload"
                            (click)="deleteDocument(document.code)"
                            >{{'PORTAL_NG_COMMON.DELETE_DOC' | translate}}
                            <span class="glyphicon glyphicon-trash"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- contenuto -->
        <div class="panel-body pnc-document-container-border" [ngbCollapse]="!document?.open">
            <div *ngFor="let file of document.files">
                <!-- files-->
                <div class="row">
                    <div class="col-md-7 col-sm-8">
                        <span class="pnc-file-name-color"><b>{{file.name}}</b></span>
                    </div>
                    <div class="col-md-5 col-sm-4">
                        <div class="container-element">
                            <button *ngIf="(validationDocEnabled && document.toBeValidated)" type="button"
                                class="pnc_button_validation" (click)="validationFile(document.id, file.id)">
                                {{ 'PORTAL_NG_COMMON.VALIDATION' | translate     }} <span [ngClass]="{'pnc_element_validated' : file.validate}"
                                    class="rgi-pnc rgi-pnc-seal"> </span>
                            </button>

                            <button type="button" class="drag-del-item pnc-doc-btn" (click)="previewFile(file);">
                                <span class="glyphicon glyphicon-search"></span>
                            </button>
                            <button type="button" class="drag-del-item pnc-doc-btn" [disabled]="file.validate"
                                (click)="updateFile(document.id, file.id)">
                                <span class="rgifont rgi-pencil-square"></span>
                            </button>
                            <button *ngIf="deleteFileEnabled" type="button" class="drag-del-item pnc-doc-btn"
                                [disabled]="file.validate" (click)="deleteFile(document.id, document.code, file.id)">
                                <span class="glyphicon glyphicon-trash"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <ng-container *ngFor="let metadata of file.metadataList;">
                       <div *ngIf="metadata?.visible" class="col-md-4 col-sm-4 pnc-doc-riga-border"> {{metadata.description}}: <b>{{metadata.value}}</b></div>
                    </ng-container>
                    <div class="col-md-4 col-sm-4 pnc-doc-riga-border"
                        *ngFor="let elem of remainingItems(file)"></div>
                </div>
            </div>
            <div *ngIf="!document.files || document.files.length==0"><span translate>PORTAL_NG_COMMON.NO_FILES</span></div>
        </div>
    </div>

    <!-- Output Documents -->
<!--    <div class="pnc_cont_doc_titsez pnc_cont_titsez_color"-->
<!--        *ngIf="documents.outputDocuments && documents.outputDocuments.length">-->
<!--        <h1 translate>PORTAL_NG_COMMON.OUTPUT_DOCUMENTS</h1>-->
<!--    </div>-->
<!--    <div *ngFor="let document of documents.outputDocuments">-->
<!--        &lt;!&ndash; intestazione &ndash;&gt;-->
<!--        <div class="unit-container pnc-document-container pnc-document-container-color">-->
<!--            <div class="row">-->
<!--                <div class="col-md-7 col-sm-8">-->
<!--                    <span class="icon rgi-nmf-file"></span>-->
<!--                    <b class="descrDocument pnc-file-name-color">{{document.description}}</b>-->
<!--                </div>-->
<!--                <div class="col-md-5 col-sm-4 colRight">-->
<!--                    <div class="container-element">-->
<!--                        <span class="pnc-status pnc-status-color"><span translate>PORTAL_NG_COMMON.STATE</span>:</span>-->
<!--                        <span class="rgifont font-survey top-margin-ico rgi-survey_ko font-survey-ko pnc-status-border"-->
<!--                            *ngIf="!document.files[0] || !document.files[0].downloaded"></span>-->
<!--                        <span class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok pnc-status-border"-->
<!--                            *ngIf="document.files[0] && document.files[0].downloaded"></span>-->
<!--                        <button type="button" class="btn pnc_element_blue pnc-upload"-->
<!--                            (click)="downloadDocument(document)" [disabled]="!uploadDownloadEnabled">-->
<!--                            {{'PORTAL_NG_COMMON.DOWNLOAD' | translate}}&nbsp;<span class="rgifont rgi-download"></span>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>

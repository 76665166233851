import {
  Component,
  Inject,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalService } from '@rgi/rx/ui';
import { ClaimQuestionnaireService } from '../claim-questionnaire.service';
import {
  Questionnaire,
  QuestionnaireItems,
  ClaimNumberQuestionnair,
} from './claim-questionnaire.interface';
import {
  CardComponent,
  AlertService,
} from '@rgi/digital-claims-common-angular';
import { ClaimQuestionnaireEditDataComponent } from '../claim-questionnaire-edit-module/claim-questionnaire-edit-data.component';
import { OutputChangeMail } from '../claim-questionnaire-edit-module/claim-questionnaire-edit-data.interface';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
@Component({
  selector: 'claims-questionnaire',
  templateUrl: './claim-questionnaire.component.html',
  styleUrls: ['./claim-questionnaire.component.scss'],
})
export class ClaimQuestionnaireComponent extends CardComponent implements OnInit {
  @Input() inputQuestionnaire: Questionnaire;
  @Output() outputQuestionnaire: EventEmitter<Questionnaire> = new EventEmitter<Questionnaire>();
  externalOpen: boolean = false;

  potentialClaimNumber: string;
  questionnair: Questionnaire;
  authSrv: any;
  questionnaireEdit: boolean;
  display: boolean;
  questionnaireBody: any;
  questionAnswer: string;
  questionnaireItems: QuestionnaireItems[];
  questionnaireItemsOld: QuestionnaireItems[];
  claimNumberQuestionnair: ClaimNumberQuestionnair;
  displayButtons: boolean = false;
  jsonClaim: any;

  constructor(
    @Inject('coreResult') public coreResult: any,
    @Inject('containerCacheService') private cache,
    private questionnaireService: ClaimQuestionnaireService,
    private alertService: AlertService,
    private modalServiceRgi: ModalService,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.editVisualizationQuestionnaire();
    if (!this.inputQuestionnaire) {
      // se arriva l'oggetto vuol dire che non sto aprendo il questionario come card
      // ma lo sto aprendo come component dentro un altro component
      this.getClaimNumberQuestionnairBody();
      this.getQuestionnaireResponse();
    } else {
      this.questionnair = { ...this.inputQuestionnaire };
      this.displayButtons = true;
      this.questionnaireEdit = true;
      this.externalOpen = true;
      this.onChangeValue();
    }
  }

  onChangeValue() {
    if (this.externalOpen) {
      this.outputQuestionnaire.emit(this.questionnair);
    }
  }

  editVisualizationQuestionnaire() {
    this.questionnaireEdit = false;
    this.display = true;
  }

  getClaimNumberQuestionnairBody() {
    this.jsonClaim = this.cache.getCache().get(this.card.idSession).data;
    this.claimNumberQuestionnair = {
      claimNumber: this.jsonClaim.claimNumber
        ? this.jsonClaim.claimNumber
        : null,
      potentialClaimNumber: this.jsonClaim.reportNumber
        ? this.jsonClaim.reportNumber
        : null,
    };
  }

  getQuestionnaireResponse() {
    if (
      this.jsonClaim.questionnaireItems &&
      this.jsonClaim.questionnaireItems.length > 0
    ) {
      this.questionnaireService
        .getQuestionnaireResponse(this.claimNumberQuestionnair)
        .subscribe(
          (result) => {
            if (result.items.length > 0) {
              this.questionnair = { ...result };
              this.displayButtons = true;
            } else {
              this.displayButtons = false;
            }
          },
          (error) => {
            this.displayButtons = false;
          }
        );
    }
  }

  saveQuestionnaire(): any {
    this.questionnair.claimNumber = this.claimNumberQuestionnair.claimNumber;
    this.questionnair.potentialClaimNumber =
      this.claimNumberQuestionnair.potentialClaimNumber;
    this.questionnaireService
      .postSaveQuestionnaire(this.questionnair)
      .subscribe((result) => {
        if (result) {
          this.controlQuestionaire(result);
        }
      });
  }

  controlQuestionaire(result: Questionnaire) {
    if (result.outcome) {
      if (result.outcome.code === '0' && result.requestedArrivalDate) {
        this.openModalQuestionnaireEditData();
      } else if (result.outcome.code === '0' && !result.requestedArrivalDate) {
        this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._GENERIC_ERROR_ON_SAVE_QUESTIONNARIE')
          .subscribe(
            res =>  this.alertService.translatedMessage(res)
          );
      } else if (result.outcome.code === '1') {
        this.cancelQuestionnaire();
        this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL')
        .subscribe(
          res =>  this.alertService.translatedMessage(res)
        )
      }
    } else {
      this.cancelQuestionnaire();
      this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._GENERIC_ERROR_ON_SAVE_QUESTIONNARIE')
      .subscribe(
        res =>  this.alertService.translatedMessage(res)
      )
    }
    this.questionnaireEdit = false;
  }

  openModalQuestionnaireEditData() {
    this.modalServiceRgi
      .open(ClaimQuestionnaireEditDataComponent, {
        errorData: this.questionnair.arrivalDate,
      })
      .onClose.subscribe((response: OutputChangeMail) => {
        if (response.typeClose === 'Confirm') {
          this.questionnair.arrivalDate = response.dateChanged;
          return this.saveQuestionnaire();
        } else {
          this.cancelQuestionnaire();
          this.questionnaireEdit = false;
          this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._QUESTIONNAIRE_MODIFICATION_CANCELLED')
          .subscribe(
            res =>  this.alertService.translatedMessage(res)
          )
        }
      });
  }

  editQuestionnaire() {
    this.questionnaireEdit = true;
  }

  cancelQuestionnaire() {
    this.getQuestionnaireResponse();
    this.questionnaireEdit = false;
  }
}

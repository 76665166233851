import {DOCUMENT} from '@angular/common';
import {ApplicationRef, ComponentFactoryResolver, ComponentRef, EventEmitter, Inject, Injectable, InjectionToken, Injector, StaticProvider, TemplateRef, Type} from '@angular/core';
import {OnModalClose} from './modal-on-close';
import {ModalComponent} from './modal.component';
import {take} from 'rxjs/operators';


export type Content<T> = string | TemplateRef<T> | Type<T>;
export const DIALOG_DATA = new InjectionToken<any>('RgiRxDialogData');

@Injectable()
export class ModalService {


  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private document: any
  ) {
  }


  /**
   *
   * @param content string|TemplateRef|component class (ie: MyComponent)
   * @param data any
   * @return ModalComponent
   */
  open<T>(content: Content<T>, data?: any): ModalComponent {
    const modalInstance = this.createModal(content, data);
    modalInstance.open();
    return modalInstance;
  }


  /**
   *
   * @param component component class (ie: MyComponent)
   * @param data
   * @param providers
   */
  openComponent<C>(component: Type<C>, data?: any, providers?: StaticProvider[]): { modal: ModalComponent, component: C } {
    const instances = this.createModalComponent(component, data, providers);
    instances.modal.open();
    return instances;
  }


  /**
   *
   * @param content string|TemplateRef|component class (ie: MyComponent)
   * @param data any
   */
  createModal(content: any, data?: any): ModalComponent {
    const ngData = this.resolveNgContent(content, data);
    const modalRef = this.createModalRef(ngData.ngContent, data);
    this.createCloseSubscriptions(modalRef, ngData.componentRef && ngData.componentRef.instance, ngData.componentRef);
    return modalRef.instance;
  }


  /**
   *
   * @param component component class (ie: MyComponent)
   * @param data data to be passed as DIALOG_DATA token
   * @param providers any additional providers for the dialog
   */
  createModalComponent<C>(component: Type<C>, data?: any, providers?: StaticProvider[]): { modal: ModalComponent; component: C } {
    const ngData = this.resolveComponent(component, data, providers);
    const modalRef = this.createModalRef(ngData.ngContent, data);
    this.createCloseSubscriptions(modalRef, ngData.componentRef.instance, ngData.componentRef);
    return {
      modal: modalRef.instance,
      component: ngData.componentRef.instance
    };
  }


  protected createCloseSubscriptions<T>(modalRef: ComponentRef<ModalComponent>, compInstance: any, componentRef?: ComponentRef<T>) {
    if (compInstance && 'modalClose' in compInstance) {
      const modalCloseComp: OnModalClose = compInstance;
      if (modalCloseComp.modalClose instanceof EventEmitter) {
        this.subscribeCompClose(modalRef.instance, modalCloseComp, componentRef);
      }
    }
    this.subscribeModalClose(modalRef, componentRef);
  }


  protected createModalRef(ngContent: any[][], data?: any): ComponentRef<ModalComponent> {
    const modalFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const modalRef = modalFactory.create(this.injector, ngContent);

    const bodyEl: HTMLElement = this.document.body;
    let modalContainerEl = bodyEl.querySelector('div.rgi-rx-modal-container');
    if (!modalContainerEl) {
      modalContainerEl = this.document.createElement('div');
      const cdkContainer = bodyEl.querySelector('.cdk-overlay-container');
      modalContainerEl.classList.add('rgi-rx-modal-container');
      if (!cdkContainer) {
        bodyEl.appendChild(modalContainerEl);
      } else {
        bodyEl.insertBefore(modalContainerEl, cdkContainer);
      }
    }
    modalContainerEl.appendChild(modalRef.location.nativeElement);
    this.applicationRef.attachView(modalRef.hostView);
    modalRef.hostView.detectChanges();
    return modalRef;
  }


  protected subscribeModalClose<T>(modalRef: ComponentRef<ModalComponent>, componentRef: ComponentRef<T>) {
    modalRef.instance.onClose
      .pipe(
        take(1)
      )
      .subscribe(
        (data: any) => {
          this.applicationRef.detachView(modalRef.hostView);
          modalRef.destroy();
          if (componentRef) {
            componentRef.destroy();
          }
        }
      );
  }


  protected resolveNgContent<T>(content: Content<T>, data?: any): { ngContent: any[][], componentRef?: ComponentRef<T> } {
    if (typeof content === 'string') {
      const element = this.document.createTextNode(content);
      return {ngContent: [[element]]};
    }

    if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(null);
      return {ngContent: [viewRef.rootNodes]};
    }

    /** Otherwise it's a component */
    return this.resolveComponent(content, data);
  }


  protected resolveComponent<C>(component: Type<C>, data?: any, providers?: StaticProvider[]): { ngContent: any[][]; componentRef: ComponentRef<C> } {
    const factory = this.componentFactoryResolver.resolveComponentFactory<C>(component);
    const componentRef = factory.create(this.createInjector(data, providers));
    this.applicationRef.attachView(componentRef.hostView);
    return {
      ngContent: [[componentRef.location.nativeElement]],
      componentRef
    };
  }


  protected subscribeCompClose<T>(modalInstance: ModalComponent, modalCloseComp: OnModalClose, componentRef?: ComponentRef<T>) {
    modalCloseComp.modalClose
      .pipe(take(1))
      .subscribe(
        (data: any) => {
          componentRef.destroy();
          modalInstance.close(data);
        }
      );
  }

  protected createInjector(data: any, providers?: StaticProvider[]): Injector {
    let dialogProviders: StaticProvider[] = [
      {provide: DIALOG_DATA, useValue: data}
    ];
    if (!!providers) {
      dialogProviders = dialogProviders.concat(...providers);
    }
    return Injector.create({
      parent:
      this.injector,
      providers: dialogProviders
    });
  }
}

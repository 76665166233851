import {RgiRxTranslations} from '@rgi/rx/i18n';

export const RGI_RX_LOCALE_SELECTOR_i18n = {
  it: {
    RGI_RX: {
      LOCALE_SELECTOR: {
        LABEL: `Lingua`
      }
    }
  },
  en: {
    RGI_RX: {
      LOCALE_SELECTOR: {
        LABEL: 'Language'
      }
    }
  },
  es: {
    RGI_RX: {
      LOCALE_SELECTOR: {
        LABEL: 'Idioma'
      }
    }
  },
  fr: {
    RGI_RX: {
      LOCALE_SELECTOR: {
        LABEL: 'Langue'
      }
    }
  },
  de: {
    RGI_RX: {
      LOCALE_SELECTOR: {
        LABEL: 'Sprache'
      }
    }
  }
};

export const RGI_RX_LOCALE_SELECTOR_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: () => new Promise(resolve => {
      resolve(RGI_RX_LOCALE_SELECTOR_i18n.it);
    })
  },
  {
    locale: 'en',
    load: () => new Promise(resolve => {
      resolve(RGI_RX_LOCALE_SELECTOR_i18n.en);
    })
  },
  {
    locale: 'fr',
    load: () => new Promise(resolve => {
      resolve(RGI_RX_LOCALE_SELECTOR_i18n.fr);
    })
  },
  {
    locale: 'es',
    load: () => new Promise(resolve => {
      resolve(RGI_RX_LOCALE_SELECTOR_i18n.es);
    })
  },
  {
    locale: 'de',
    load: () => new Promise(resolve => {
      resolve(RGI_RX_LOCALE_SELECTOR_i18n.de);
    })
  }
]

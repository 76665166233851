<div [hidden]="summary">
  <!-- Distribution Message -->
  <div class="distribution-message" *ngIf="!!distributionConfig && !!messageInfos">
    <div class="message-distribution-info">
      <span translate>lpc_information</span><span>:</span>
      <ng-container *ngIf="messageInfos.amount >= 0">
        <span> {{messageInfos.amount | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}} </span>
      </ng-container>
      <ng-container *ngIf="messageInfos.percent >= 0" >
        <span> {{messageInfos.percent | rgiCountryLayerNumberFormat: currencyService.locale : percentFormatterOptions}} </span>
      </ng-container>
      <span translate>lpc_toDistribute</span>
    </div>
  </div>
  <!-- Distribution toggle -->
  <lpc-distribution-toggle
    *ngIf="distributionConfig && distributionConfig.showDistributionSwitch"
    [distributionDefault]="distributionConfig.distributionType"
    (clickEvent)="changeDistribution($event)">
  </lpc-distribution-toggle>
  <!-- end Distribution toggle -->
  <div [formGroup]="formGroup">
    <div formArrayName="array">
      <lpc-claim-beneficiary
        *ngFor="let control of value; let i = index; trackBy: trackFn"
        [questionnairesCode]="questionnairesCode"
        [paymentTypes]="paymentTypes" [formControlName]="i.toString()"
        [questFactorsArray]="questFactorsArray" [disabledQuestionArray]="disabledQuestionArray"
        [questionnairesDefinitions]="questionnairesDefinitions"
        [defaultAnswersArray]="defaultAnswersArray"
        [blockBeneficiaries]="blockBeneficiaries"
        [distributionConfig]="distributionConfig"
        (triggerQuestPreval)="onTriggerQuestPreval($event)"
        (loaderQuest)="loaderQuestManager($event)"
        (delete)="onDelete($event)"
        (deleteSubRole)="onDeleteSubRole($event)"
        #beneficiary>
      </lpc-claim-beneficiary>
    </div>
  </div>
  <div *ngIf="!blockBeneficiaries && showAddBeneficiary" class="col-lg-6 new-beneficiary-margin">
    <div class="rgifont rgi-value new-beneficiary-float"></div>
    <input [value]="'lpc_new_beneficiary' | translate" (click)="openAnagSubjectModal()" class="drop-container dashedSelect new-beneficiary" [attr.data-qa]="'dashed-select-beneficiary'" readonly>
    <div class="new-beneficiary-new-line"></div>
  </div>
</div>
<div [hidden]="!summary" data-qa="summary-beneficiaries-step">
  <div class="col-lg-12">
    <div *ngFor="let ben of getBenefSummary" class="col-lg-6">
      <span>{{ 'lpc_life_detail_beneficiary' | translate }}</span><span>: {{ben.name}}</span>
      <span *ngIf="!!ben.perc"> ({{ben.perc}}%)</span>
    </div>
  </div>
</div>

import {formatISO} from 'date-fns';

export function getDatePickerFilter(minDate: string, maxDate: string, pickerValue: Date): boolean {
  if (!!minDate || !!maxDate) {
    const valueSelected = new Date(formatISO(pickerValue, {representation : 'date'}));
    let ok = true;
    if (!!minDate) {
      const date = new Date(minDate);
      ok = ok && valueSelected.getTime() >= date.getTime();
    }
    if (!!maxDate) {
      const date = new Date(maxDate);
      ok = ok && valueSelected.getTime() <= date.getTime();
    }
    return ok;
  }
  return true;

}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EventService} from '@rgi/rx';
import {RgiRxDatatableComponent, RgiRxDatatableRowAction, TableSchema} from '@rgi/rx/ui';
import {Observable, of} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {APPLICATIONS_LIST_TABLE_SCHEMA} from '../../group-policy-constants/applications-list-tableschema';
import {CALLBACK_JUMP, CallBackEvent} from '../../group-policy-events/group-policy-event';
import {ApplicationReference, ApplicationsResponse} from '../../group-policy-models/group-policy-application';
import {GroupPolicyResourceService} from '../../group-policy-resources/group-policy-resource.service';
import {GroupPolicyIntegrationService} from '../../group-policy-services/group-policy-integration.service';
import {Mode} from '../../group-policy-models/group-policy-mode';


@Component({
  selector: 'rgi-gp-group-policy-applications-list',
  templateUrl: './group-policy-applications-list.component.html'
})
export class GroupPolicyApplicationsListComponent implements OnInit {

  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  public APPLICATIONS_LIST_TABLE_SCHEMA: TableSchema = APPLICATIONS_LIST_TABLE_SCHEMA;
  public applicationsTableData: Observable<any[]> = of([]);
  @Input()
  public proposalNumber: string;
  @Input()
  public onlyValidPolicy = false;
  @Input()
  public clusterCode: string;
  @Input()
  public assetCode: string;
  @Input()
  public groupPolicyMode: Mode;
  @Input()
  public idParentSession: string;

  @ViewChild(RgiRxDatatableComponent, {static: true}) dt: RgiRxDatatableComponent;

  constructor(
    public groupPolicyService: GroupPolicyResourceService,
    public integrationService: GroupPolicyIntegrationService,
    protected eventService: EventService
  ) {
  }

  ngOnInit(): void {
    this.retrieveApplications();
    this.eventService.listen<CallBackEvent>(CALLBACK_JUMP).subscribe(event => {
      if (event.event.idSession === this.idParentSession) {
        this.retrieveApplications();
      }
    });
  }

  retrieveApplications(): void {
    this.groupPolicyService.getApplicationsOfMasterPolicy$(this.proposalNumber, this.clusterCode, this.assetCode, this.onlyValidPolicy)
      .pipe(tap((resp: ApplicationsResponse) => {
          this.fillApplicationsTableData(resp.applications);
        })
      ).subscribe();
  }

  public onActionClick($event: RgiRxDatatableRowAction<any>) {
    if ($event) {
      this.integrationService.openApplicationDetail($event.row, this.idParentSession, this.groupPolicyMode);
    }
  }

  protected fillApplicationsTableData(applications: Array<ApplicationReference>) {
    this.applicationsTableData = of(applications.map(application => {
      return {
        idPolicy: application.idPolicy,
        contractNumber: application.contractNumber,
        contractDescription: application.contractDescription,
        categoryCode: application.category.code,
        categoryDescription: application.category.description,
        statusCode: application.status.code,
        statusDescription: application.status.description,
        managementNodeCode: application.managementNode.code,
        managementNodeDescription: application.managementNode.description,
        issueDate: application.issueDate,
        effectiveDate: application.effectiveDate,
        expirationDate: application.expirationDate,
        productCode: application.product.code,
        productDescription: application.product.description,
        proposalNumber: application.proposalNumber,
        policyNumber: application.policyNumber,
        userName: application.userName
      };
    }));
    this.applicationsTableData.pipe(take(1), tap(next => this.dt.dataSource.update(next))).subscribe();
  }

}

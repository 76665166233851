<div class="beneficiary" [formGroup]="formGroup" class="row">
  <div class="beneficiary-space-15 col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div rgi-rx-accordion [multi]="true">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
            <div rgi-rx-expansion-panel-header>=
              <span class="accordion-text" translate> {{ formGroup.getRawValue().subject.value.name }} </span>
            </div>
              <div class="row" data-qa="panel-button" content>
                <div class="col-md-6">
                  <lpc-claim-beneficiary-control
                    [editable]="editability()"
                    [distributionConfig]="distributionConfig"
                    [blockBeneficiaries]="blockBeneficiaries"
                    formControlName="subject"
                    (delete)="onDelete()" #mainBenef>
                  </lpc-claim-beneficiary-control>
                  <div style="clear:both"></div>
                  <div *ngIf="titleVisibility()" class="core-header-icon">
                    <span class="header-icon rgifont rgi-riparti_coass"></span>
                    <span class="header-text" translate>lpc_roles_connected_benef</span>
                    <span class="pull-right header-text"></span>
                  </div>
                  <div formArrayName="linkedRoles">
                    <div *ngFor="let role of beneficiaryRolesDefinition; let j = index" class="col-md-12">
                      <label *ngIf="labelVisibility(role.code)" class="lbl">{{role.label}}</label>
                      <lpc-required [required]="role?.mandatory"></lpc-required>
                      <div [formGroupName]="j.toString()">
                        <div formArrayName="subjects" *ngIf="subBeneficiaryCount.get(j) > 0">
                          <div *ngFor="let subRole of getSubjectRoles(j); let i = index;" class="beneficiary-space-10">
                            <lpc-claim-sub-beneficiary [showPercentage]="role.percentage"
                                                       [editable]="editability()" (delete)="deleteRole($event)"
                                                       [linkedRolesDefinition]="subRolesDefinition.get(role.code)" [formControlName]="i.toString()"
                                                       [linkedRoles]="subRole.value.linkedRoles || []"
                                                       (triggerQuestPreval)="onTriggerQuestPreval($event)">
                          </lpc-claim-sub-beneficiary>
                          </div>
                        </div>
                        <input *ngIf="canAddSubBeneficiaries(j)" [value]="'lpc_Seleziona' | translate"
                               (click)="openAnagSubjectModal(role.code)" class="col-lg-12 drop-container dashedSelect"
                               [attr.data-qa]="'dashed-select-beneficiary-' + role.label" readonly>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <lpc-payment-step
                    [editable]="editability()"
                    [required]="true"
                    [paymentTypeDefinitions]="paymentTypes"
                    formControlName="paymentMethod"
                    [isPaymentTypeEditable]="editability()"
                    (change)="onChangePayment($event)">
                  </lpc-payment-step>

                  <lib-questionnaires-manager [hidden]="!editability()" data-qa="questPostSalesAVCBenef" key="{{questionnaireKey}}"
                                              customQuestLoader="BY_CODE" [modalClass]="'life-quest-modal-flow'"
                                              [questionnairesCode]="filteredQuestionnairesCode"
                                              [savedIds]="savedQuestionnaireIds" [sq]="defaultAnswersArray"
                                              [f]="questFactorsArray" [dq]="disabledQuestionArray"
                                              (loaderQuest)="loaderQuestManager($event)"
                                              #avcQuestionnaire>
                  </lib-questionnaires-manager>

                  <div *ngIf="formGroup.get('editable').value === false" class="row">
                    <div class="col-lg-12" *ngFor="let questionnaire of getQuestionnaires()">
                      <div class="row">
                        <div class="questionnaire-block-white col-xs-2" data-qa="questCompiled">
                                <span class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok">
                                </span>
                        </div>
                        <div class="col-xs-10 large questionnaire-block">
                          <span [attr.data-qa]="questionnaire.id + 'quest_name'" translate>{{questionnaire.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
        </rgi-rx-expansion-panel>
    </div>
  </div>
</div>

import {AbstractStateManager, RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {CardAnalyticsReducerService} from './card-analytics-reducer.service';
import {of} from 'rxjs';
import { Report } from '../model/report';

export class CardAnalyticsStateDashboard extends State {

}


export class CardAnalyticsStateManagerDashboard extends RgiRxLocalStateManager<CardAnalyticsStateDashboard> {
  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, private reducer: CardAnalyticsReducerService) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<CardAnalyticsStateDashboard>(this.activeRoute.key) ?
      stateStoreService.get<CardAnalyticsStateDashboard>(this.activeRoute.key) : new CardAnalyticsStateDashboard(this.activeRoute.key);
    this.updateState$(of(st)); // this update the state
  }

  getReports(reports: Report[]) {
    const currentState = this.getCurrentState();
    this.updateState$(of(currentState));
  }

  actionOpen() {
    this.reducer.backHome(this.activeRoute);
  }

  removeSession(activeRoute: ActiveRoute) {
    this.reducer.removeSession(activeRoute);
  }

  editReport(report, activeRoute: ActiveRoute) {
    this.reducer.editReport(report, this.activeRoute);
  }


}


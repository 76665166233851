import {PncPsalesBaseState} from '../resources/states/pnc-postsales-state';
import {StateManagedComponent} from '@rgi/rx/state';
import {FieldChangeSubject, RgiPncPsalesStateManager} from './rgi-pnc-postsales-state.manager';
import {Directive, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FormService} from '../services/form.service';
import {PNC_PSALES_ACTIONS} from '../resources/constants/actions';
import {PncPsalesForm} from '../resources/model/common/form';

@Directive()
export abstract class RgiPncPostsalesStepComponent<S extends PncPsalesBaseState> extends StateManagedComponent<S, RgiPncPsalesStateManager<S>> implements OnInit {

  state: S;
  pageForm = new UntypedFormGroup({});
  protected subscriptions: Subscription = new Subscription();

  protected constructor(
    private _stateMgr: RgiPncPsalesStateManager<S>,
    private _formService: FormService
  ) {
    super(_stateMgr);
  }

  ngOnInit() {
    this._stateMgr.getState$().subscribe((st: S) => {
      this.state = st;
      this.addFormsToPage();
      this.registerFieldChangeObs();
      this.initStep();
    });
  }

  protected addFormsToPage() {
    Object.keys(this.state.forms).forEach(formName => {
      const form: PncPsalesForm = this._stateMgr.getFormByName(formName);
      if (form.fields && !!form.fields.length) {
        this.pageForm.setControl(formName, this._formService.createForm(form.fields));
      } else {
        this.pageForm.removeControl(formName);
      }
    });
    Object.keys(this.pageForm.controls).forEach(formName => {
      if (!this.state.forms[formName]) {
        this.pageForm.removeControl(formName);
      }
    });
    if (this.state.formStatus !== this.pageForm.status) {
      this._stateMgr.setFormStatus(this.pageForm.status);
    }
  }

  private registerFieldChangeObs() {
    this.subscriptions = new Subscription();
    Object.keys(this.pageForm.controls).forEach(formName => {
      const form = this.pageForm.get(formName) as UntypedFormGroup;
      Object.keys(form.controls).forEach(fieldCode => {
        const formField = form.get(fieldCode);
        this.subscriptions.add(formField.valueChanges.subscribe(value => {
          const subject: FieldChangeSubject = {
            formStatus: this.pageForm.status,
            fieldCode,
            formName,
            value
          };
          this._stateMgr.setFormObservables(subject);
        }));
      });
    });
  }

  protected initStep() {
  }

  getFG(name: string): UntypedFormGroup {
    return this.pageForm.get(name) as UntypedFormGroup;
  }

  get formKeys(): string[] {
    return Object.keys(this.state.forms);
  }

  back() {
    this._stateMgr.onAction(PNC_PSALES_ACTIONS.BACK);
  }

  continue() {
    if (this.pageForm) {
      this.pageForm.markAllAsTouched();
      if (!this.pageForm.invalid) {
        this._stateMgr.onAction(PNC_PSALES_ACTIONS.CONTINUE);
      }
    } else {
      this._stateMgr.onAction(PNC_PSALES_ACTIONS.CONTINUE);
    }
  }
}

import {Inject, Injectable} from '@angular/core';
import {
  AnagSubject,
  DataForSteps,
  GPLifeAppPayload,
  GPMotorAppPayload,
  GroupPolicyIntegrationService,
  Mode,
  ROUTES_GP_HOME
} from '@rgi/group-policy';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {GROUP_POLICY_ISSUE_CARD} from './group-policy.card.configurations';
import {PortalRouteDefault} from '@rgi/rx/portal';

@Injectable()
export class GroupPolicyPortalIntegrationService extends GroupPolicyIntegrationService {


  constructor(
    protected _routingService: RoutingService,
              @Inject('sessionService') protected portalSession: any,
              @Inject('parallelRouting') protected portalParallelRouting: any,
              @Inject('coreResult') protected portalCoreResult: any,
              @Inject('eventService') protected eventService: any) {
    super(_routingService);
  }


  closeSession(id) {
    this.eventService.broadcastEvent('alertMsg', { varMsg: 'Close the current session?', confirmAlert: true, strId: 'removeSession', idSession: id });
  }

  backToPrevPage(id: string, idParentSession: string) {
    if (!!idParentSession) {
      this.setActiveSession(id, idParentSession);
    } else {
      const inquirySession = this.portalSession.list().find(session => session.idSession === id);
      if (inquirySession) {
      try{
       this.portalParallelRouting.go(inquirySession.id, 'result');
      }catch (error){
         this.portalSession.remove(id);
      }
      }
    }
  }

  startLifeFlow(_id, data: GPLifeAppPayload, isCached = false, route = 'anag') {
    const cardId = this.portalSession.open('ptflifeProposal', route, '', true);
    if (isCached) {
      this.portalCoreResult.setResult(cardId, 'subject', this.portalCoreResult.getResultSimple('SubjectDetailsForGroupPolicy'));
      this.portalCoreResult.setResult(cardId, 'product', data.product);
      this.portalCoreResult.setResult(cardId, 'managementNode', data.idpv);
    }
    this.portalCoreResult.setResult(cardId, 'lifeProposalSettings', data);
  }

  startMotorFlow(paramsNMF: GPMotorAppPayload, idSession: string) {
    this.portalSession.open('newMotorContract', null, '', true, paramsNMF, null, idSession);
  }

  openApplicationDetail(_row: any, _idSession: string, _mode: Mode) {
    const cardName = _row.categoryCode === '7' ? 'detail-life' : 'detail-damage';
    const idNavigation = this.portalSession.open('ptfallFinder', cardName, '', true, null, null, _idSession);
    this.portalCoreResult.setResult(idNavigation, cardName,
      {
        isGroupPolicy: true,
        cancellationDate: null,
        category: {
          codice: _row.categoryCode,
          descrizione: _row.categoryDescription
        },
        description: _row.contractDescription,
        nodeCode: _row.managementNodeCode,
        nodeDescription: _row.managementNodeDescription,
        objectId: _row.idPolicy,
        policyNumber: _row.policyNumber,
        policyType: {
          codice: '3',
        },
        product: _row.productDescription,
        productCode: _row.productCode,
        proposalNumber: _row.proposalNumber,
        state: {
          codice: _row.statusCode,
          descrizione: _row.statusDescription
        },
      }
    );
  }

  openSubjectDetail(subject: AnagSubject, idSession: string) {
    const subjIdSession = this.portalSession.open('anagCardZoom', 'home', '', true);
    this.portalCoreResult.setResult(subjIdSession, 'detail-life-subj', subject);
    this.portalCoreResult.setResult(subjIdSession, 'detail-life-idSession', idSession);
  }

  addCssClass(id) {
    const inquirySession = this.portalSession.list().find(session => session.idSession === id);
    if (inquirySession) {
      const rootElement = document.querySelectorAll('div#cardcontainer_' + inquirySession.id + ' > div#ptfallFinder[is-session]')[0];
      const rootElGp = document.querySelectorAll('div#cardcontainer_' + inquirySession.id + ' > div#groupPolicyInquiry[is-session]')[0];
      if (rootElement) {
        rootElement.classList.add('group-policy-inquiry-card');
      }
      if (rootElGp) {
        rootElGp.classList.add('group-policy-inquiry-card');
      }
    }
  }

  removeCssClass(id) {
    const inquirySession = this.portalSession.list().find(session => session.idSession === id);
    if (inquirySession) {
      const rootElement = document.querySelectorAll('div#cardcontainer_' + inquirySession.id + ' > div#ptfallFinder[is-session]')[0];
      const rootElGp = document.querySelectorAll('div#cardcontainer_' + inquirySession.id + ' > div#groupPolicyInquiry[is-session]')[0];
      if (rootElement) {
        rootElement.classList.remove('group-policy-inquiry-card');
      }
      if (rootElGp) {
        rootElGp.classList.remove('group-policy-inquiry-card');
      }
    }
  }

  backToInquiry(_inquiryProposalNumber: string, _idSession: string, _idParentSession: string) {
    this.setActiveSession(_idSession, _idParentSession);
  }

  backToIssue(_idSession: string, _idParentSession: string) {
    this.setActiveSession(_idSession, _idParentSession);
  }

  navigate(routingService: RoutingService, destinationRoute: string, routeData: any, activeRoute: ActiveRoute) {
    if (activeRoute && ROUTES_GP_HOME === activeRoute.route) {
      super.navigate(routingService, destinationRoute, routeData, activeRoute);
    } else {
      const portalRoute = GROUP_POLICY_ISSUE_CARD.routes.find((route: PortalRouteDefault) => route.destination === destinationRoute).route;
      const gpSession = this.portalSession.list().find(session => session.idSession === activeRoute.id);
      this.portalCoreResult.setResult(gpSession.id, portalRoute, routeData);
      this.portalParallelRouting.go(gpSession.id, portalRoute);
    }
  }

  backToHome(activeRoute: ActiveRoute) {
    const gpSession = this.portalSession.list().find(session => session.idSession === activeRoute.id);
    this.portalSession.remove(gpSession.idSession);
  }

  goToInquiry(prevStepData: DataForSteps, id: string = null) {
    if (id) {
      this.portalSession.remove(id);
    }
    const cardId = this.portalSession.open('groupPolicyInquiry', 'detail-masterpolicies', '', true);
    this.portalCoreResult.setResult(cardId, 'detail-masterpolicies', prevStepData);
  }


  goToIssuePolicyPage(prevStepData: DataForSteps, id: string = null) {
    const cardId = this.portalSession.open(GROUP_POLICY_ISSUE_CARD.card.name, 'issueConfirm', '', true, null, null, id);
    this.portalCoreResult.setResult(cardId, 'issueConfirm', prevStepData);
  }

  /*
  * @param {string} id current session
  * @param {string} idParentSession id of the parent  origin session
  *  */
  protected setActiveSession(id: string, idParentSession: string) {
    this.portalSession.setActive(idParentSession);
    this.portalSession.remove(id);
  }

  goToEdit(prevStepData: DataForSteps) {
    const idNavigation = this.portalSession.open(GROUP_POLICY_ISSUE_CARD.card.name, 'policyData', null, true, null,
      null, prevStepData.idParentSession);
    this.portalCoreResult.setResult(idNavigation, 'policyData', prevStepData);
  }

  getRequestedInquiryMode(previousStepData: DataForSteps): string {
    return super.getRequestedInquiryMode(previousStepData);
  }

}

import {GenericEntity} from '../models/domain-models/generic-entity';
import {Answer} from './answer';

export class Question extends GenericEntity {

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public answerList: Array<Answer>,
    public status: number
  ) {
    super(id, code, description);
  }

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { RoutingService } from '@rgi/portal-ng-core';
import { ModalComponent, ModalService, RgiRxDataTableColumnSortDef, RgiRxDataTableExpansionModel, RgiRxDatatableComponent, RgiRxDatatableRowAction, RgiRxSwitchChange, TableRowTypeSchema, TableSchema } from '@rgi/rx/ui';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { UtilityMethod, actions, codiceFunzione, codiceMessaggio, dataFineValidita, dataInizioValidita, importo } from '../../constants/forfait.constants';
import { take } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { ModalForfaitComponent } from './modal-forfait/modal-forfait.component';
import { AddEditForfaitComponent } from './add-edit-forfait/add-edit-forfait.component';
import { OutputForfait } from '../../models/forfait/output-forfait.model';
import { ForfaitStateManager } from '../../state-managers/forfait.state.manager';
import { DeleteForfait } from '../../models/forfait/delete-forfait.model';
import { ForfaitState } from '../../states/forfait.state';

/**
 * Tabella.
 * Ordinamento dei componenti custom all'interno della tabella
 */
const CUSTOM_TEMPLATE_SORT_DEF: RgiRxDataTableColumnSortDef<OutputForfait>[] = [];

@Component({
  selector: "table-forfait",
  templateUrl: "./table-forfait.component.html",
})
export class TableForfaitComponent implements OnInit {
  subscriptions: Subscription[] = [];
  modal: ModalComponent | null = null

  constructor(
    @Inject('authService') private authService,
    protected routingService: RoutingService,
    public forfaitStateManager: ForfaitStateManager,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.checkState()
  }

  checkState() {
    this.subscriptions.push(this.forfaitStateManager.getState$().subscribe((state: ForfaitState) => {
      if (!!state) {
        if (!!state.search) {
          this.tableData = state.search
          this.tableData.forEach((outputForfait: OutputForfait) => {
            outputForfait.dataInizioValidita = UtilityMethod.transform(outputForfait.dataInizioValidita)
            outputForfait.dataFineValidita = UtilityMethod.transform(outputForfait.dataFineValidita)
          })
        }

        if (!!state.add) {
          state.add.dataInizioValidita = UtilityMethod.transform(state.add.dataInizioValidita);
          state.add.dataFineValidita = UtilityMethod.transform(state.add.dataFineValidita);

          this.selections.added.push(state.add);
          this.tableData.push(state.add);

          of(this.tableData).pipe(take(1)).subscribe(next => this.dt.dataSource.update(next));

          state.add = null
          this.modal.close()
          this.modal = null
        }

        if (!!state.modify) {
          const oldData: OutputForfait = this.tableData.find((outputForfait: OutputForfait) => outputForfait.idForfait == state.oldIdForfait)

          if (!!oldData) {
            state.modify.dataInizioValidita = UtilityMethod.transform(state.modify.dataInizioValidita);
            state.modify.dataFineValidita = UtilityMethod.transform(state.modify.dataFineValidita);

            this.selections.removed.push(oldData);
            this.tableData.splice(this.tableData.indexOf(oldData), 1);
            this.tableData.push(state.modify);

            of(this.tableData).pipe(take(1)).subscribe(next => this.dt.dataSource.update(next));

            state.oldIdForfait = null
            state.modify = null
            this.modal.close()
            this.modal = null
          }
        }

        if (!!state.delete) {
          const oldData: OutputForfait = this.tableData.find((outputForfait: OutputForfait) => outputForfait.idForfait == state.oldIdForfait)

          if (oldData) {
            this.selections.removed.push(oldData);
            this.tableData.splice(this.tableData.indexOf(oldData), 1);

            of(this.tableData).pipe(take(1)).subscribe(next => this.dt.dataSource.update(next));

            state.oldIdForfait = null
            state.delete = null
          }
        }
      }
    }));
  }

  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe()
        sub = null
      }
    })
  }



  /**
   * 
   * Tabella.
   * 
   */

  /** 
   * Visualizzazione componente 
   * */
  @ViewChild(RgiRxDatatableComponent, { static: true }) dt: RgiRxDatatableComponent;


  /** 
   * Ordinamento 
   */
  protected sortDef: RgiRxDataTableColumnSortDef<OutputForfait>[] | boolean = CUSTOM_TEMPLATE_SORT_DEF;


  /** 
   * Dati in arrivo dal servizio backend 
   */
  protected tableData: OutputForfait[] = []


  /** 
   * Customizzazione componente della libreria RGI RX UI 
   */
  protected customExpansionModel: RgiRxDataTableExpansionModel<OutputForfait> = new RgiRxDataTableExpansionModel<OutputForfait>(
    this.tableData, { multiple: false, canExpand: () => false, canContract: () => false }
  );


  /** 
   * Informazioni da visualizzare 
   */
  private rows: TableRowTypeSchema[] = [
    { name: codiceFunzione, title: '_CLAIMS_._FUNCTION' },
    { name: codiceMessaggio, title: '_CLAIMS_._CODE_MESSAGE' },
    { name: dataInizioValidita, title: '_CLAIMS_._VALIDITY_START_DATE' },
    { name: dataFineValidita, title: '_CLAIMS_._VALIDITY_END_DATE' },
    { name: importo, title: '_CLAIMS_._IMPORT' },
    {
      name: actions, title: '_CLAIMS_._BUTTONS_._ACTIONS',
      actions: [
        {
          name: 'CANCELLA',
          styleClass: 'rgi-ui-icon-delete',
        },
        {
          name: 'MODIFICA',
          styleClass: 'rgi-ui-icon-edit'
        }
      ],
    }
  ];


  /** 
   * Titoli delle informazioni da visualizzare 
   */
  private header: string[] = [
    codiceFunzione,
    codiceMessaggio,
    dataInizioValidita,
    dataFineValidita,
    importo,
    actions
  ];

  selections: SelectionChange<OutputForfait> = {
    source: new SelectionModel<OutputForfait>(),
    removed: [],
    added: []
  };


  /** 
   * Schema delle tabella 
   */
  protected schema: TableSchema = { rows: this.rows, header: this.header };


  /**
   * Funzione richiamata al momento del click dell'icona
   * per l'ordinamento della colonna della tabella
   */
  protected onToggleSortHeaders($event: RgiRxSwitchChange) {
    this.sortDef = $event.selected ? CUSTOM_TEMPLATE_SORT_DEF : false;
  }


  onActionClick(event: RgiRxDatatableRowAction<OutputForfait>) {
    if (event.name === 'CANCELLA') {
      this.modalService
        .open(ModalForfaitComponent, event.row)
        .onClose
        .subscribe(next => {
          if (!!next) {
            const deleteForfait: DeleteForfait = {
              idForfait: next
            }

            this.forfaitStateManager.deleteForfait$(deleteForfait);
            of(this.tableData).pipe(take(1)).subscribe(next => this.dt.dataSource.update(next));
          }
        });
    }

    if (event.name === 'MODIFICA') {
      this.modal = this.modalService.open(AddEditForfaitComponent, { idForfait: event.row.idForfait, importo: event.row.importo, stateManager: this.forfaitStateManager })
    }
  }



  /**
   * 
   * Bottoni.
   * 
   */
  protected aggiungi() {
    this.modal = this.modalService.open(AddEditForfaitComponent, { idForfait: null, importo: null, stateManager: this.forfaitStateManager })
  }
}
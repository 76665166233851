import {AdditionalLabel} from '../proposal-models/additional-label';

export class PcQuestionnaireFlatIResponse {
  questionnaireFlatResponse: QuestionFlateResponse[];
}

export class QuestionFlateResponse {
  additionalLabels?: AdditionalLabel[];
  code?: string;
  description?: string;
  number?: string;
  complete?: boolean;
  mandatory?: boolean;
  adequate?: boolean;
  questions?: PcQuestion[];
}

export class PcQuestion {
  additionalLabels?: AdditionalLabel[];
  code ?: string;
  description?: string;
  answers?: PcAnswer[];
}

export class PcAnswer {
  additionalLabels?: AdditionalLabel[];
  code?: string;
  description?: string;
  type?: number;
  default?: string;
  disabled?: boolean;
  selected?: boolean;
  values?: any;
  value?: string;
}

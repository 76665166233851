import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {EnvironmentService, ResponseResult} from "@rgi/ng-passpropro-core";
import {Document} from "../model/document";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {DocumentMetadata} from "../model/document-metadata";
import {RgiRxFileReaderService} from "@rgi/rx";

const BASE_PATH = 'v2/document';

@Injectable({providedIn: 'root'})
export class DocumentResource {

    constructor(
        private http: HttpClient,
        private environment: EnvironmentService,
        private rgiRxFileReader: RgiRxFileReaderService
    ) {
    }

    searchDocumentById(id: string): Observable<Document> {
        return this.http.get<ResponseResult<Document>>(
            `${this.environment.environmentUrl}/${BASE_PATH}/${id}`
        ).pipe(
            map(resp => {
                return this.fixFileName(resp.result);
            })
        );
    }

    deleteDocumentById(id: string): Observable<Document> {
        return this.http.delete<ResponseResult<Document>>(
            `${this.environment.environmentUrl}/${BASE_PATH}/${id}`
        ).pipe(
            map(resp => {
                return resp.result;
            })
        );
    }

    updateDocument(document: Document): Observable<Document> {
        return this.http.put<ResponseResult<Document>>(
            `${this.environment.environmentUrl}/${BASE_PATH}`,
            document
        ).pipe(
            map(resp => {
                return resp.result;
            })
        );
    }

    /*
    *
    * TODO - DA VERIFICARE
    *
    */

    /*
        uploadDocument(blob: Blob, documentName: string, documentMetadata: DocumentMetadata[]): Observable<Document> {
            return from(this.rgiRxFileReader.readAsText(blob)).pipe(
                filter(event => event.status == "complete"),
                map((eventComplete: FileReadCompleteEvent<any>) => eventComplete.data),
                map(binaryText => this.createDocument(binaryText, documentName, documentMetadata)),
                switchMap(requestBody => this.http.post<ResponseResult<Document>>(
                    `${this.environment.environmentUrl}/${BASE_PATH}`,
                    requestBody
                ).pipe(
                    map(resp => {
                        return resp.result;
                    })
                ))
            );
        }
    */

    private createDocument(binaryFile: string, documentName: string, documentMetadata: DocumentMetadata[]): Document {
        let base64OfbinaryFile: string = btoa(binaryFile);

        let document: Document = {
            data: base64OfbinaryFile,
            metadata: documentMetadata,
            name: documentName
        }

        return document;
    }

    downloadDocumentById(id: string): Observable<Blob> {
        return this.http.get(
            `${this.environment.environmentUrl}/${BASE_PATH}/download/${id}`,
            {
                headers: {
                    'Accept': '*/*'
                },
                responseType: 'blob'
            }
        );
    }

    searchDocumentListByMetadata(metadata: DocumentMetadata[]): Observable<Document[]> {
        return this.http.put<ResponseResult<Document[]>>(
            `${this.environment.environmentUrl}/${BASE_PATH}/filter`,
            metadata
        ).pipe(
            map(resp => {
                return resp.result.map(this.fixFileName);
            })
        );
    }

    private fixFileName(document: Document): Document {
        // fixme this can be perfomed by the rest endpoint
        let documentMetadata = document.metadata.find(meta => meta.key === 'name');
        if (documentMetadata && Array.isArray(documentMetadata.value)) {
            document.name = documentMetadata.value[0];
        }
        return document;
    }
}

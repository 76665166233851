import {Injectable} from '@angular/core';
import {RgiRxHttpError} from '@rgi/rx/http';
import {Observable, of, throwError} from 'rxjs';
import {ErrorCode, ErrorMsg, ErrorResp} from '../group-policy-models/group-policy-issue-policy-data';
import {
  GroupPolicyStateCluster,
  GroupPolicyStateConfigurationPm,
  GroupPolicyStateEdit,
  GroupPolicyStateGuarantees,
  GroupPolicyStateInquiry,
  GroupPolicyStatePolicyData,
  GroupPolicyStateProposalIssue,
  GroupPolicyStateSummary,
  GroupPolicyStateVcontConfirms,
  GroupPolicyStateVcontDate,
  GroupPolicyStateVcontQuestionnaire,
  GroupPolicyStateVcontVariation
} from '../group-policy-state/group-policy-state';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {Violation} from '../group-policy-models/group-policy-domain-types';
// tslint:disable:max-line-length

export type StateType = GroupPolicyStatePolicyData | GroupPolicyStateConfigurationPm | GroupPolicyStateSummary |
                        GroupPolicyStateGuarantees | GroupPolicyStateEdit | GroupPolicyStateVcontDate |
                        GroupPolicyStateVcontVariation | GroupPolicyStateVcontQuestionnaire |
                        GroupPolicyStateVcontConfirms | GroupPolicyStateCluster | GroupPolicyStateInquiry;

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyApiRestErrorService {

  constructor(protected pushMessageHandler: PushMessageHandlerService) { }

  public thereIsError(obj): boolean {
    return obj instanceof RgiRxHttpError;
  }

  public manageError(st: StateType, obj: any, errorCode: string, errorType = ErrorCode.BLOCKING) {
    if (!st.errors) {
      st.errors = Array<ErrorResp>();
    }
    if (typeof obj === 'string') {
      const error = {
        level: errorType,
        code: errorCode,
        message: obj
      } as ErrorResp;
      if (!st.errors.includes(error)) {
        st.errors.push(error);
      }
      st.type = errorType;
    } else if (Array.isArray(obj)) {
      obj.forEach(err => {
        err.errorCode = errorCode;
        if (!st.errors.includes(err)) {
          st.errors.push(err);
        }
      });
      st.type = errorType;
    }
  }

  /**
   * @deprecated The method should not be used
   */
  public cleanErrorsForCode(errors: Array<ErrorResp>, code: string | string[]): Array<ErrorResp> {
    if (!!errors) {
      if (Array.isArray(code)) {
        code.forEach(c => errors = errors.filter(err => !err.code || err.code === c));
        return errors;
      } else {
        return errors.filter(err => !err.code || !err.code.startsWith(code));
      }
    }
    return [];
  }

  public manageErrors(apiResponse: RgiRxHttpError | ErrorResp[] | ErrorMsg[], areaCode?: string): ErrorResp[] {
    let errors: ErrorResp[] = [];
    if (apiResponse instanceof RgiRxHttpError) {
      if (Array.isArray(apiResponse.errorBody)) {
        errors = errors.concat(apiResponse.errorBody);
      } else if (!!apiResponse.errorBody && apiResponse.errorBody instanceof ErrorResp || !!apiResponse.errorBody.message || !!apiResponse.errorBody.description) {
        errors.push(apiResponse.errorBody);
      } else if (!!apiResponse.errorBody && !!apiResponse.errorBody.violations) {
        errors = errors.concat(apiResponse.errorBody.violations);
      } else { // should be removed after assuring that all errors are catched correctly
        console.error('unexpected error type:');
        console.error(apiResponse);
      }
    } else if (!!apiResponse && Array.isArray(apiResponse) && !!apiResponse.length) {
      // @ts-ignore
      errors = apiResponse;
    }
    if (!!areaCode) { // temporary area code management before BE areaCode implementation
      errors = errors.map(err => {
        function getErrorDescriptionFromError(receivedErr: ErrorResp|ErrorMsg) {
          if (!!(receivedErr as ErrorResp).message) {
            return (receivedErr as ErrorResp).message;
          }
          if (!!(receivedErr as ErrorMsg).errorDescription) {
            return (receivedErr as ErrorMsg).errorDescription;
          }
          return (receivedErr as any).description;
        }

        function getErrorType(receivedErr: ErrorResp|ErrorMsg) {
          if (!!(receivedErr as ErrorResp).level) {
            return (receivedErr as ErrorResp).level;
          }
          if (!!(receivedErr as ErrorMsg).errorType) {
            if ((receivedErr as ErrorMsg).errorType === 0) {
              return ErrorCode.BLOCKING;
            } else if ((receivedErr as ErrorMsg).errorType === 1) {
              return ErrorCode.WARNING;
            } else {
              return ErrorCode.INFO;
            }
          }
          return ErrorCode.BLOCKING;
        }

        return {
          level: getErrorType(err),
          code: areaCode,
          message: getErrorDescriptionFromError(err)
        } as ErrorResp;
      });
    }
    return errors;
  }
  /**
   * @deprecated The method should not be used
   */
  public catchApiErrorFn(st: StateType, areaCode?: string): (err: RgiRxHttpError) => Observable<never> {
    return (err: RgiRxHttpError) => {
      if (err.status < 500) {
        st.errors = st.errors.concat(this.manageErrors(err, areaCode));
        return throwError(st);
      } else {
        return throwError(err);
      }
    };
  }

  public containsBlockingErrors(errors: ErrorResp[]): boolean {
    if (!!errors && !!errors.length) {
      return !!errors.find(err => err.level === ErrorCode.BLOCKING);
    }
    return false;
  }

  public isState(st: StateType | any): boolean {
    return st instanceof GroupPolicyStatePolicyData ||
      st instanceof GroupPolicyStateConfigurationPm ||
      st instanceof GroupPolicyStateSummary ||
      st instanceof GroupPolicyStateGuarantees ||
      st instanceof GroupPolicyStateEdit ||
      st instanceof GroupPolicyStateProposalIssue ||
      st instanceof GroupPolicyStateVcontDate ||
      st instanceof GroupPolicyStateVcontVariation ||
      st instanceof GroupPolicyStateVcontQuestionnaire ||
      st instanceof GroupPolicyStateVcontConfirms;
  }

  public manageStreamErrFn(): (err: StateType | any) => Observable<StateType | any> {
    return (err: StateType | any) => {
      if (this.isState(err)) {
        return of(err);
      } else {
        console.error(err);
        throw Error('Unhandled Error');
      }
    };
  }

  public catchApiErrorHandlerFn(st: StateType, tag: string): (err: RgiRxHttpError) => Observable<never> {
    return (err: RgiRxHttpError) => {
      if (err.status < 500) {
        if (err.errorBody && err.errorBody.violations && Array.isArray(err.errorBody.violations)) {
          this.manageViolationsArray(err.errorBody.violations, tag);
        } else {
          const error = err.errorBody && err.errorBody.description ? err.errorBody.description :
            'error found but the error structure is not implemented in FE\'s error management';
          this.pushMessageHandler.notify(new RgiRxPushMessage(error, {
              tag,
              status: 'danger',
              dismissible: true
            }
          ));
        }
        return throwError(st);
      }
    };
  }

  manageViolationsArray(violations: Violation[], tag: string) {

    violations.forEach(error => {
      let level: string;
      switch (error.level) {
        case 'BLOCKING':
        case 'ERROR':
          level = 'danger';
          break;
        case 'INFO':
          level = 'info';
          break;
        default:
          level = 'warning';
          break;
      }
      setTimeout(() => {
        this.pushMessageHandler.notify(new RgiRxPushMessage(error.message, {
            tag,
            status: level,
            dismissible: true
          }
        ));
      },  50);
    });
  }

  public cleanErrorsHandlerForTag(code: string | string[]) {
    if (Array.isArray(code)) {
      code.forEach(c => this.pushMessageHandler.clearTag(c));
    } else {
      this.pushMessageHandler.clearTag(code);
    }
  }

}

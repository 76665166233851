import {ApiPremiumGroup} from './api-premium-group';
import {Warning} from '../domain-models/warning';
import {ApiPremiumSection} from './api-premium-section';
import {ApiWarranty} from './api-warranty';

export class ApiPremium {

  constructor(
    public productPremium: ApiPremiumGroup,
    public sectionsPremium: Map<string, ApiPremiumSection>,
    public packagesPremium: Map<string, ApiPremiumSection>,
    public warnings: Array<Warning>,
    public warranties: Array<ApiWarranty>
  ) {
  }

}

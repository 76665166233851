import { Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { RelatedPolicies } from './../../../models/postsales-operations-response.model';

@Component({
  selector: 'lpc-contract-list-table',
  templateUrl: './lpc-contract-list-table.component.html',
  styleUrls: ['./lpc-contract-list-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcContractListTableComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcContractListTableComponent implements OnInit, ControlValueAccessor {

  @Input() contractList: RelatedPolicies[] = [];

  public pageSizes = [10, 20, 30];
  public pageSize = 10;
  public page = 1;
  public count = 0;
  public contractListData: RelatedPolicies[] = [];
  protected $subscriptions: Subscription[] = [];
  checkedAll = false;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(),
    count: new UntypedFormControl(10),
    contracts: new UntypedFormArray([])
  });

  @ViewChild('checkBoxComponent') checkBoxComponent: any;

  constructor() { }

  ngOnInit() {
    this.contractListData = this.contractList;
    this.count = this.contractListData.length;
    this.populateForm();
    this.$subscriptions.push(
      this.formGroup.get('search').valueChanges.pipe(
        /* debounceTime(200), */
        distinctUntilChanged()
      ).subscribe(result => {
        const seachVal = String(this.formGroup.get('search').value);
        const regExp: RegExp = new RegExp(seachVal.toUpperCase(), 'i');
        this.contractListData = this.contractList.filter(contract =>
          (!!contract.number && contract.number.match(regExp)) ||
          (!!contract.product && contract.product.toUpperCase().match(regExp)) ||
          (!!contract.insuranceForm && contract.insuranceForm.toUpperCase().match(regExp)));

        this.resetFormOnSearchEvent();
      }),
      this.formGroup.valueChanges.subscribe(val => {
        this.onChange(val);
      })
    );
  }

  onChange = (obj) => {
  }

  onTouched = () => {
  }

  writeValue(obj: any): void {
    if (!!obj) {
      this.formGroup.patchValue(obj, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  populateForm() {
    this.contractListData.forEach((pol, index) => {
      (this.formGroup.controls.contracts as UntypedFormArray).setControl(
        index,
        new UntypedFormGroup({ active: new UntypedFormControl(false), contractId: new UntypedFormControl(pol.id)})
      );
    });
  }

  resetFormOnSearchEvent() {
    this.resetCheckAll();
    this.contractList
    .filter(c => !this.contractListData.includes(c))
    .forEach((pol, index) => {
      (this.formGroup.controls.contracts as UntypedFormArray).controls
      .filter(control => control.get('contractId').value !== pol.id)
      .forEach(control => control.get('active').setValue(false));
      });
  }

  handlePageSizeChange(event) {
    this.pageSize = event;
    this.page = 1;
  }

  public handlePageChange(event) {
    this.page = event;
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    (this.formGroup.controls.contracts as UntypedFormArray).controls.forEach(control => control.get('active').setValue(this.checkedAll));
  }

  singleCheck() {
    this.resetCheckAll();
  }

  private resetCheckAll() {
    this.checkedAll = this.checkedAll === true ? false : this.checkedAll;
    this.checkBoxComponent.checked = this.checkedAll;
  }

  getCorrectIndex(id) {
    return (this.formGroup.controls.contracts as UntypedFormArray).value.findIndex(el => el.contractId === id);
  }

  getPolicySummary() {
    const contractControl = (this.formGroup.controls.contracts as UntypedFormArray).value;
    return (!!contractControl ? (contractControl as any[])
            .filter(control => control.active).map(v => v.contractId) : [])
            .map(contract => {
              const pol = this.contractList.find(c => c.id === contract);
              return {
                pol
              };
            });
  }

}

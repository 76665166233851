import {Inject, Injectable} from '@angular/core';
import {ClauseService} from '../clause/clause-service';
import {Clause} from '../models/domain-models/clause';
import {Section} from '../models/domain-models/section';
import {ProposalService} from '../proposal.service';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class ContractClauseService extends ClauseService {

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected proposalService: ProposalService
  ) {
    super(micHttpClient, environment, proposalService);
  }

  getPreQuotationClauses(contractId: string) {
    return this.httpClient.get<Array<Clause>>(this.contractEndpoint + '/' + contractId + '/contract-clauses');
  }

  updateAssetClauses(unitClauses: Array<Clause>) {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.put<Array<Section>>(this.contractEndpoint + '/' + contractId + '/contract-clauses', unitClauses);
  }
}

import { ChatUser } from './chat-user';

export class ChatMessage {

    chatMessageId: number;
    subject: string;
    body: string;
    read: boolean;
    sendDate: Date;
    type: number;
    claimId: number;
    claimNumber: string;
    potClaimId: number;
    potentialClaimNumber: string;
    myMessage: boolean;
    sender: ChatUser;
    recipient: ChatUser;
    otherUser: ChatUser;
    isFirstOfTheDay: boolean;

    constructor(sender: ChatUser, recipient: ChatUser) {
        this.sender = sender;
        this.recipient = recipient;
    }

}

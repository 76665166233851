<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

    <lpc-step label="{{TRANSFER_TO_OTHER_FUND_STEP.DATE.label}}" id="{{TRANSFER_TO_OTHER_FUND_STEP.DATE.id}}" [errors]="errors" fieldId="{{TRANSFER_TO_OTHER_FUND_STEP.DATE.fieldId}}" (next)="onNext($event)">
      <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="TRANSFER_TO_OTHER_FUND_STEP.DATE.formName" (dateChange)="onDateChange($event)"
        (reloadRequest)="updateData()" [hide]="activeStep<1">
      </lpc-dates-step>
    </lpc-step>

    <lpc-step id="{{TRANSFER_TO_OTHER_FUND_STEP.POLICY_FACTORS.id}}" *ngIf="hasProductFactor" label="{{TRANSFER_TO_OTHER_FUND_STEP.POLICY_FACTORS.label}}" [errors]="errors" fieldId="{{TRANSFER_TO_OTHER_FUND_STEP.POLICY_FACTORS.fieldId}}"
      (next)="onNext($event)">
      <div formGroupName="{{TRANSFER_TO_OTHER_FUND_STEP.POLICY_FACTORS.formName}}" class="row">
        <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateProductFactors($event)"
          [form]="formGroup.controls['factors']" [factors]="listProductFactor">
        </lpc-factor-adapter>
      </div>
    </lpc-step>

    <lpc-step id="{{TRANSFER_TO_OTHER_FUND_STEP.QUESTS_FIN.id}}" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')"
      *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" fieldId="{{TRANSFER_TO_OTHER_FUND_STEP.QUESTS_FIN.fieldId}}"
      label="{{TRANSFER_TO_OTHER_FUND_STEP.QUESTS_FIN.label}}" (next)="onNext($event)">
      <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
        [questionnairesCode]="getQuestMap('FIN')" [type]="'FIN'" [date]="effectiveDate" [productCode]="productCode"
        [savedIds]="questionnairesFromAuthorization" (loaderQuest)="loaderQuestManager($event)"
        (loaded)="countQuest($event)" #financialQuestionnaire>
      </lpc-questionnaire>
    </lpc-step>

    <lpc-step id="{{TRANSFER_TO_OTHER_FUND_STEP.ASSET_FACTORS.id}}" *ngIf="hasAssetFactor" label="{{TRANSFER_TO_OTHER_FUND_STEP.ASSET_FACTORS.label}}" [errors]="errors" fieldId="{{TRANSFER_TO_OTHER_FUND_STEP.ASSET_FACTORS.fieldId}}"
    (next)="onNext($event)">
    <div formGroupName="{{TRANSFER_TO_OTHER_FUND_STEP.ASSET_FACTORS.formName}}" class="row">
      <lpc-factor-adapter #assetFactorAdapter (changeValEmitter)="updateAssetFactors($event)"
        [form]="formGroup.controls['assetFactors']" [factors]="listAssetFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

    <lpc-step id="{{TRANSFER_TO_OTHER_FUND_STEP.DATA_TRANSFER.id}}" label="{{TRANSFER_TO_OTHER_FUND_STEP.DATA_TRANSFER.label}}" [errors]="errors" fieldId="{{TRANSFER_TO_OTHER_FUND_STEP.DATA_TRANSFER.fieldId}}"
      (next)="onNext($event)">
      <div *ngIf="!!draftDataTransfer.length" class="row">
        <lpc-form-field-handler [isActive]="isActiveStep(TRANSFER_TO_OTHER_FUND_STEP.DATA_TRANSFER.id)"
        [isOpen]="isActiveOrPassedStep(TRANSFER_TO_OTHER_FUND_STEP.DATA_TRANSFER.id)"  formControlName="dataTransfer" [definitions]="draftDataTransfer"></lpc-form-field-handler>
      </div>
    </lpc-step>

    <lpc-step id="{{TRANSFER_TO_OTHER_FUND_STEP.QUOTATION.id}}" [enableAuth]="isAuth" label="{{TRANSFER_TO_OTHER_FUND_STEP.QUOTATION.label}}" [errors]="errors" fieldId="{{TRANSFER_TO_OTHER_FUND_STEP.QUOTATION.fieldId}}" (next)="onNext($event, true)">
        <div formGroupName="quotation" class="row">
          <div *ngFor="let insuredSettlement of insuredSettlements">
            <p *ngIf="!!insuredSettlement.insured && insuredSettlement.insured.length > 1;else singleInsured" class="insured">
              <label translate>lpc_Assicurati</label>
              <label>: {{ insuredSettlement.insured[0] + ' - ' + insuredSettlement.insured[1] }}</label>
            </p>
            <ng-template #singleInsured>
              <p class="insured">
                <label translate>lpc_Assicurato</label>
                <label>: {{ insuredSettlement.insured[0] }}</label>
              </p>
            </ng-template>
            <lpc-definition-table
              [label]="'lpc_life_policy'"
              [definitions]="tableDefinitions"
              [rows]="insuredSettlement.settlement">
            </lpc-definition-table>
            <br>
          </div>
        </div>
      </lpc-step>



    <lpc-step
        id="beneficiaries" [enableAuth]="isAuth" label="Beneficiaries" [errors]="errors" fieldId="beneficiaries"
        (next)="onNext($event, false, 'beneficiaries')" [feErrors]="feErrorsBenef"
        feFieldId="beneficiaries"
        (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
            <lpc-claim-beneficiaries
            formControlName="beneficiaries" [paymentTypes]="paymentTypes"
            [beneficiaryType]="beneficiaryType"
            [idAssicurato]="idAssicurato" [summary]="!isActiveStep('beneficiaries')"
            [questionnairesCode]="getUnfilteredQuestMap('IST')"
            [questionnairesDefinitions]="questionnaireDefinitions"
            (triggerQuestPreval)="onTriggerQuestPreval($event)"
            (loaderQuest)="loaderQuestManager($event)"
            [questFactorsArray]="questFactorsArray"
            [disabledQuestionArray]="disabledQuestionArray"
            [blockBeneficiaries]="blockBeneficiaries"
            #beneficiaries >
            </lpc-claim-beneficiaries>
    </lpc-step>

    <lpc-step
          *ngIf="isAuth || !!errors && !!errors.length"
          id="{{TRANSFER_TO_OTHER_FUND_STEP.WARNING.id}}" label="{{TRANSFER_TO_OTHER_FUND_STEP.WARNING.label}}"
          (next)="publish()"
          [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
          (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
      <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    </lpc-step>

    <lpc-step [enableAuth]="isAuth" label="{{TRANSFER_TO_OTHER_FUND_STEP.DOCUMENT.label}}" [errors]="errors" fieldId="{{TRANSFER_TO_OTHER_FUND_STEP.DOCUMENT.fieldId}}" id="{{TRANSFER_TO_OTHER_FUND_STEP.DOCUMENT.id}}"
      [nextVisible]=false>
      <lpc-document-wrapper *ngIf="!!publishMessage" [contractId]="getContractId" [operationCode]="getOperationCode"
        [publishMessage]="publishMessage" [isAuth]="isAuth" [authorizationId]="authorizationId" [printMode]="printMode"
        [config]="documentsConfig" (documentEvent)="handleDocumentEvent($event)">
      </lpc-document-wrapper>
      <lpc-navigation-buttons id="last-step-buttons" (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'"
        [backVisible]="true" [nextVisible]="false">
      </lpc-navigation-buttons>
    </lpc-step>

  </lpc-stepper>

  <div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
    <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'">
    </lpc-navigation-buttons>
  </div>

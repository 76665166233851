import {TableRowTypeSchema, TableSchema} from '@rgi/rx/ui';
import {numberPipe} from '../../../../pipes/country-layer-number-format-datatable';
import {SUPPL_TECH_ACC_ENUM, SUPPL_TECH_ACC_MODEL_ENUM} from '../supplementary-tech-acc';
import {RgiRxDSLAssertion} from '@rgi/rx';


const totalRowStyle: { [styleClass: string]: RgiRxDSLAssertion } = {
  'rgipc-bg-info': {
    assert: {
      path: 'isTotal',
      value: true,
      condition: '='
    }
  }
};

export const SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP = new Map<string, TableRowTypeSchema>([
  [
    SUPPL_TECH_ACC_MODEL_ENUM.INSURED_RISKS,
    {name: SUPPL_TECH_ACC_MODEL_ENUM.RISK_DESCR, title: '_PCPSALES_._LABEL_._INSURED_RISKS_'}
  ],
  [
    SUPPL_TECH_ACC_MODEL_ENUM.NOT_INSURED_RISKS,
    {name: SUPPL_TECH_ACC_MODEL_ENUM.RISK_DESCR, title: '_PCPSALES_._LABEL_._NOT_INSURED_RISKS_'}
  ],
  [
    SUPPL_TECH_ACC_ENUM.NET,
    {
      name: SUPPL_TECH_ACC_ENUM.NET,
      title: '_PCPSALES_._LABEL_._NET_',
      styleClass: 'rgipc-text-right',
      format: numberPipe,
      styles: totalRowStyle
    }
  ],
  [
    SUPPL_TECH_ACC_ENUM.NET_INTERESTS,
    {
      name: SUPPL_TECH_ACC_ENUM.NET_INTERESTS,
      title: '_PCPSALES_._LABEL_._NET_INTERESTS_',
      styleClass: 'rgipc-text-right',
      format: numberPipe,
      styles: totalRowStyle
    }
  ],
  [
    SUPPL_TECH_ACC_ENUM.CHARGES,
    {
      name: SUPPL_TECH_ACC_ENUM.CHARGES,
      title: '_PCPSALES_._LABEL_._CHARGES_',
      styleClass: 'rgipc-text-right',
      format: numberPipe,
      styles: totalRowStyle
    }
  ],
  [
    SUPPL_TECH_ACC_ENUM.CHARGES_INTERESTS,
    {
      name: SUPPL_TECH_ACC_ENUM.CHARGES_INTERESTS,
      title: '_PCPSALES_._LABEL_._CHARGES_INTERESTS_',
      styleClass: 'rgipc-text-right',
      format: numberPipe,
      styles: totalRowStyle
    }
  ],
  [
    SUPPL_TECH_ACC_ENUM.TAXABLE,
    {
      name: SUPPL_TECH_ACC_ENUM.TAXABLE,
      title: '_PCPSALES_._LABEL_._TAXABLE_',
      styleClass: 'rgipc-text-right',
      format: numberPipe,
      styles: totalRowStyle
    }
  ],
  [
    SUPPL_TECH_ACC_ENUM.TOTAL_TAXES,
    {
      name: SUPPL_TECH_ACC_ENUM.TOTAL_TAXES,
      title: '_PCPSALES_._LABEL_._TAXES_',
      styleClass: 'rgipc-text-right',
      format: numberPipe,
      styles: totalRowStyle
    }
  ],
  [
    SUPPL_TECH_ACC_ENUM.GROSS,
    {
      name: SUPPL_TECH_ACC_ENUM.GROSS,
      title: '_PCPSALES_._LABEL_._GROSS_',
      styleClass: 'rgipc-text-right',
      format: numberPipe,
      styles: totalRowStyle
    }
  ],
  [
    SUPPL_TECH_ACC_ENUM.COLLECTED_COMMISSIONS,
    {name: SUPPL_TECH_ACC_ENUM.COLLECTED_COMMISSIONS, title: '_PCPSALES_._LABEL_._COLLECTED_COMMISSIONS_'}
  ],
  [
    SUPPL_TECH_ACC_ENUM.PURCHASED_COMMISSIONS,
    {name: SUPPL_TECH_ACC_ENUM.PURCHASED_COMMISSIONS, title: '_PCPSALES_._LABEL_._PURCHASED_COMMISSIONS_'}
  ],
]);

export const ACCOUNTING_DETAILS_SCHEMA: TableSchema = {
  rows: [
    {name: 'riskDescription', title: '', styles: totalRowStyle},
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.NET),
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.TOTAL_TAXES),
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.GROSS),
  ],
  header: ['riskDescription', SUPPL_TECH_ACC_ENUM.NET, SUPPL_TECH_ACC_ENUM.TOTAL_TAXES, SUPPL_TECH_ACC_ENUM.GROSS]
};

export const POLICY_DETAIL_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'ASSETS_RISKS',
      title: '',
      styles: totalRowStyle
    },
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.NET),
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.CHARGES),
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.NET_INTERESTS),
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.TAXABLE),
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.TOTAL_TAXES),
    SUPPLEMENTARY_TECH_ACC_TABLE_SCHEMA_MAP.get(SUPPL_TECH_ACC_ENUM.GROSS),
  ],
  header: ['ASSETS_RISKS', SUPPL_TECH_ACC_ENUM.NET, SUPPL_TECH_ACC_ENUM.CHARGES, SUPPL_TECH_ACC_ENUM.NET_INTERESTS, SUPPL_TECH_ACC_ENUM.TAXABLE, SUPPL_TECH_ACC_ENUM.TOTAL_TAXES, SUPPL_TECH_ACC_ENUM.GROSS]
};

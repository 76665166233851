<div class="ppevo-toast" *ngIf="notifications && notifications.length">
    <div *ngIf="showBar && notifications.length>1" class="ppevo-toast-bar" (click)="hide=!hide">
        <span class="ppevo-toast-bar-title" translate>{{title}}</span>
        <div class="ppevo-toast-bar-count">{{notifications.length}}</div>
        <div class="ppevo-toast-bar-toggle-btn" [ngClass]="{'ppevo-hide': hide}"></div>
    </div>
    <div *ngIf="!hide" class="ppevo-toast-list">
        <ppevo-panel
            *ngFor="let toast of notifications"
            [showHeader]="toast.header"
            [closable]="true" 
            [showFooter]="false" 
            [small]="true" 
            [status]="toast.status" 
            [autohide]="toast.delay"
            (close)="onClose(toast)"
        >
            <ng-container class="ppevo-toast-header" header>{{toast.header | translate}}</ng-container>
            <ng-container body>
                <div class="toast-message">{{toast.body | translate}}</div>
                <div *ngIf="!toast.header" class="ppevo-btn-close" (click)="onClose(toast)"></div>
            </ng-container>
        </ppevo-panel>
    </div>
</div>
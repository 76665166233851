import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { PersonType, RoleType, Roles } from '../../models/enum/lpc-subjects.enum';
import { PolicyRoles, PostsalesOperationObject, RelatedPolicies, Role } from '../../models/postsales-operations-response.model';
import { AnagSubject } from '../../models/subject.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { ComponentWithAnagModal } from './../../interfaces/component-with-anag-modal';
import { PV_TOKEN, StepConfiguration } from './../../models/consts/lpc-consts';

export const MDATI_STEP: {CONTRACTS: StepConfiguration, ROLES: StepConfiguration} = {
  CONTRACTS: {
    label: 'Other contract list',
    fieldId: 'contracts',
    id: 'contracts',
    formName: 'contractsList'
  },
  ROLES: {
    label: 'Subject Roles',
    fieldId: 'roles',
    id: 'roles',
    formName: 'roles'
  }
};

@Component({
  selector: 'lpc-modification-personal-data',
  templateUrl: './modification-personal-data.component.html',
  styleUrls: ['./modification-personal-data.component.css']
})
export class ModificationPersonalDataComponent extends AbsOperationComponent implements ComponentWithAnagModal, OnInit {

  protected operationDataKey: string;
  HOLDER = Roles.CONTRACTOR;
  public roles: PolicyRoles[] = [];
  public contractsList: RelatedPolicies[] = [];
  public requestSubject: Role;
  roleTypeToAdd: PersonType;

  get noPolicies() {
    return this.contractsList && this.contractsList.length === 0;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      roles: new UntypedFormArray([]),
      [MDATI_STEP.CONTRACTS.formName]: new UntypedFormControl({}),
      notes: new UntypedFormControl()
    });
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected anag: AnagService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected coreAuthService: any) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();

    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.roles = result.definitions.policyParty as PolicyRoles[];
        this.roles.forEach((role, index) => {
          (this.formGroup.get('roles') as UntypedFormArray).setControl(
            index,
            new UntypedFormGroup({
              modified: new UntypedFormControl(false),
              id: new UntypedFormControl(role.id)
            }
          ));
        });
      })
    );
  }

  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    if (!!result.definitions.relatedPolicies) {
      this.contractsList = (result.definitions.relatedPolicies as RelatedPolicies[]);
    }
    this.detectChanges();
  }

  protected getTransformedOperationData(): any {
    const contractControl = (this.formGroup.get(MDATI_STEP.CONTRACTS.formName) as UntypedFormArray).value;
    return {
      roles: !!this.requestSubject ? [this.requestSubject] : null,
      policies: !!contractControl.contracts ? (contractControl.contracts as any[])
                .filter(control => control.active)
                .map(v => v.contractId) : []
    };
  }

  /** @Implements {@see #editSubject in [ComponentWithAnagModal](./../../interfaces/component-with-anag-modal.ts)} */
  editSubject(subjId: string, role: RoleType) {
    this.roleCodeToAdd = role;
    this.$subscriptions.push(
      this.anag.getSubject(subjId, this.coreAuthService.getOperator().salePoint.objectId).subscribe(respSubject => {
        if (!!respSubject && !!respSubject.subject) {
          this.anag.openSubjectModifyModal(this as any, respSubject.subject, role);
        }
      }, err => {
        console.error(err);
        throw Error(err);
      })
    );
  }

  /** @Implements {@see ##openedAnagModifySession in [ComponentWithAnagModal](./../../interfaces/component-with-anag-modal.ts)} */
  openedAnagModifySession(obj: { opened: boolean, subject: AnagSubject, role: RoleType}) {
    const index = this.roles.findIndex(r => r.id === obj.subject.objectId);
    this.requestSubject = AnagService.subjectToRole(obj.subject, obj.role);
    ((this.formGroup.get(MDATI_STEP.ROLES.formName) as UntypedFormArray).controls[index] as UntypedFormGroup).get('modified').setValue(obj.opened);
  }

  getSummaryRoles(): PolicyRoles {
    return !!this.requestSubject ? this.requestSubject : {} as any;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PassproQuestionnaireModule } from '@rgi/ng-passpro';
import { NgPassproproSurveyModule } from '@rgi/ng-passpropro-survey';
import { QuestionnaireEditComponent } from './questionnaire-edit/questionnaire-edit.component';
import { QuestionnaireItemComponent } from './questionnaires-list/questionnaire-item/questionnaire-item.component';
import { QuestionnairesListComponent } from './questionnaires-list/questionnaires-list.component';
import { QuestionnairesManagerComponent } from './questionnaires-manager.component';
import { QuestionnairesManagerService } from './services/questionnaires-manager.service';
import { QuestionnaireCacheService } from './services/questionnaires-manager-cache.service';
import { SurveyVersionService } from './services/survey-version.service';
import { LoaderMobileComponent } from './loader-mobile/loader-mobile.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';

@NgModule({
  declarations: [
    QuestionnairesManagerComponent,
    QuestionnairesListComponent,
    QuestionnaireItemComponent,
    QuestionnaireEditComponent,
    LoaderMobileComponent
  ],
  imports: [
    BrowserModule,
    NgPassproproSurveyModule,
    PassproQuestionnaireModule,
    CommonModule,
    FormsModule,
    NgbModule,
    NgbAlertModule,
    RgiRxI18nModule
  ],
  exports: [
    QuestionnairesManagerComponent,
    QuestionnairesListComponent,
    QuestionnaireItemComponent,
    QuestionnaireEditComponent,
    LoaderMobileComponent
  ],
  providers: [
    QuestionnairesManagerService,
    QuestionnaireCacheService,
    SurveyVersionService
  ]
})
export class QuestionnairesManagerModule {
}

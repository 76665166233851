import { ActiveRoute } from "@rgi/rx/router";
import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import { DialogueHandlerSearch } from "../dialogue-handler-configuration/dialogue-handler.model";
import { Observable, of } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DialogueHandlerIntegrationService } from "../dialogue-handler-configuration/dialogue-handler-integration.service";
import { DialogueHandlerSearchService } from "./dialogue-handler-search.service";
import { Injectable } from "@angular/core";
import { FormSearch } from "../dialogue-handler-configuration/dialogue-handler-search.model";
import { NewDialogueViewConfiguration } from "../new-dialogue/new-dialogue.component";
import { ListDialogueViewConfiguration } from "../constants/listEnumConstants";

@Injectable()
export class DialogueHandlerSearchStateManager extends AbstractStateManager<DialogueHandlerSearch> {

    constructor(activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: DialogueHandlerSearchService,
        private integrationService: DialogueHandlerIntegrationService)
        {
        super(activeRoute, stateStoreService);
        const st = !!stateStoreService.get<DialogueHandlerSearch>(this.storeKey)
            ? stateStoreService.get<DialogueHandlerSearch>(this.activeRoute.key)
            : new DialogueHandlerSearch(this.storeKey);
            //Emit a new state and set or update the state object into the StateStoreService
            this.updateState$(this.statelesOps.initStateObject(of(st))); 
      }
      /**
      * Test comment
      * @param form
      * @param isValidForm
      */
      updateFormSearch$(form: FormSearch, isValidForm: boolean) {
        this.updateState$(this.statelesOps.updateFormHome(form, isValidForm, of(this.getCurrentState())));

      }

      goToDialogueHandlerList$(routeList?) {       
        const st = this.getCurrentState();  
        const viewCond = ListDialogueViewConfiguration.SEARCH_LIST_VIEW;
        this.updateState$(this.statelesOps.getDetailSearchResult(of(st), (routeList? routeList : this.activeRoute), viewCond));
    }
    
      
      goToDialogueHandlerSummary$() {
        const st = this.getCurrentState();
        this.integrationService.goToDialogueHandlerSummary(st.formSearch, this.activeRoute);
      }

      goToNewDialogue$() {
        const st = this.getCurrentState();
        this.integrationService.openNewDialogue(this.activeRoute);
        const viewCond = NewDialogueViewConfiguration.PARTIAL_VIEW_CONF;
        this.updateState$(this.statelesOps.getNewDialogueData(of(st), this.activeRoute, viewCond));
      }
}
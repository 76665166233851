<label [for]="id" *ngIf="label && label.length > 0">{{label}}</label>
<pnc-required [required]="required"></pnc-required>
<div class="form-group">
  <select class="core-select"  [(ngModel)]="selectedOption"  (change)="onChange()"
          [attr.id]="id"
          [attr.name]="name"
          [attr.disabled]="disabled ? '' : null"
          [attr.required]="required ? '' : null"  >
    <option value="" [ngValue]="" [attr.selected]="selectedOption === '' ? '' : null"></option>
    <option *ngFor="let option of options;" [ngValue]="option"
            [attr.selected]="selectedOption === option.value ? '' : null">{{getLabel(option)}}</option>
  </select>
  <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
</div>

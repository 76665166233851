import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { occurrenceDataEndPoint } from './occurrence-data.endPoint';
import {
  OutputOccurrenceData,
  UpdatePolicy,
  CodeMsg
} from './occurrence-config.model';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { GeneralClaimsData } from '../../shared/dsc-general-detail/dsc-general-detail.config';
import { AdditionalData } from '../../shared/dsc-additional-data/dsc-additional-data.model';
import { IncidentData } from '../../shared/dsc-incident-data/dsc-incident-data.model';
import {
  CircumstanceIncident,
  OutputLiabilityDetermination
} from '../../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import { DatePipe } from '@angular/common';
import { Outcome } from '../../shared/dsc-shared-model/dsc-model-utility';
import { AddressEntityData } from '../../shared/dsc-shared-model/dsc-model-address';
import { InjuryCode } from '../../shared/dsc-shared-model/dsc-model-injury';
import { AlertService } from '@rgi/digital-claims-common-angular';

@Injectable()
export class OccurrenceDataModifyService {

  private baseApiUrl: string;
  private baseApiUrlV2: string;
  public authSrv: any;
  private session: any;
  isFromDetail: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private datepipe: DatePipe,
    private alertService: AlertService,
    @Inject('authService') authService,
    @Inject('environment') environment: any,
    @Inject('sessionService') session: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('eventService') private eventService: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2/';
    this.authSrv = authService;
    this.session = session;
  }

  getDetailClaims$(st: Observable<OutputOccurrenceData>, sessionClaim: any): Observable<OutputOccurrenceData> {
    const body = { claimNumber: sessionClaim.claimNumber, potentialClaimNumber: sessionClaim.reportNumber };
    return st.pipe(
      mergeMap(state => {
        const serviceCall = this.httpClient.post<OutputOccurrenceData>(
          this.baseApiUrlV2 + occurrenceDataEndPoint.loadIncidentDataFull, body);
        return combineLatest([serviceCall, of(state)]);
      }), map(([response, state]) => {
        state.parentSession = sessionClaim.parentSession;
        state.parameterInputData = response.parameterInputData;
        state.additionalData = response.additionalData;
        state.addressEntity = response.addressEntity;
        state.buttonAlignPolicy = response.buttonAlignPolicy;
        state.buttonNormalizeEnable = response.buttonNormalizeEnable;
        state.buttonSaveVisibile = response.buttonSaveVisibile;
        if (response.circumstanceIncident !== null) {
          state.circumstanceIncident = { ...new CircumstanceIncident(), ...response.circumstanceIncident };
        } else {
          state.circumstanceIncident = null;
        }
        state.generalData = response.generalData;
        state.incidentData = response.incidentData;
        state.injuryCode = response.injuryCode;
        state.claimSession = sessionClaim;
        state.generalData.claimNumber = sessionClaim.claimNumber;
        state.generalData.potentialClaimNumber = sessionClaim.reportNumber;
        state.ministerialBranchcode = sessionClaim.ministerialBranchCodes;
        state.isEnabledButtonSave = false;
        let occurrenceHours = '00';
        let occurrenceMin = '00';
        let actualOccurrencHours = '00';
        let actualOccurrencMin = '00';
        if (state.incidentData.occurrenceTime && state.incidentData.occurrenceTime !== null
          && state.incidentData.occurrenceTime !== '') {
          occurrenceHours = state.incidentData.occurrenceTime.substring(0, 2);
          occurrenceMin = state.incidentData.occurrenceTime.substring(3, 5);
        }
        if (state.incidentData.actualOccurrenceTime && state.incidentData.actualOccurrenceTime !== null
          && state.incidentData.actualOccurrenceTime !== '') {
          actualOccurrencHours = state.incidentData.actualOccurrenceTime.substring(0, 2);
          actualOccurrencMin = state.incidentData.actualOccurrenceTime.substring(3, 5);
        }
        return state;
      })
    );
  }

  updateIncidentDataClaim$(occuranceData: OutputOccurrenceData): Observable<Outcome> {
    return this.httpClient.post<Outcome>(this.baseApiUrlV2 + occurrenceDataEndPoint.updateIncidentDataClaim, occuranceData);
  }

  updatePolicy$(updatePolicy: UpdatePolicy){
    return this.httpClient.post<Outcome>(this.baseApiUrlV2 + occurrenceDataEndPoint.updatePolicy, updatePolicy);
  }

  updateIncidentDataPotentialClaim$(occuranceData: OutputOccurrenceData): Observable<Outcome> {
    // no claimNumber
    return this.httpClient.post<Outcome>(this.baseApiUrlV2 + occurrenceDataEndPoint.updateIncidentDataPotentialClaim, occuranceData);
  }

  clearState$(stateOccurrence$: Observable<OutputOccurrenceData>): Observable<OutputOccurrenceData> {
    return stateOccurrence$.pipe(map((st) => {
      st.generalData = new GeneralClaimsData();
      st.incidentData = new IncidentData();
      st.addressEntity = new AddressEntityData();
      st.injuryCode = new InjuryCode();
      st.additionalData = new AdditionalData();
      st.buttonNormalizeEnable = false;
      st.buttonSaveVisibile = false;
      st.buttonAlignPolicy = false;
      return st;
    }
    ));
  }

  updateIncidentDate$(incidentData: IncidentData, state: Observable<OutputOccurrenceData>) {
    return state.pipe(map((st) => {
      st.incidentData = incidentData;
      return st;
    }));
  }

  updateAddressEntity$(addressEntity: AddressEntityData, state: Observable<OutputOccurrenceData>) {
    return state.pipe(map((st) => {
      st.addressEntity = addressEntity;
      return st;
    }));
  }

  updateInjuryCode$(injuryCode: InjuryCode, state: Observable<OutputOccurrenceData>) {
    return state.pipe(map((st) => {
      st.injuryCode = injuryCode;
      return st;
    }));
  }

  updateAdditionalData$(additionalData: AdditionalData, state: Observable<OutputOccurrenceData>) {
    return state.pipe(map((st) => {
      st.additionalData = additionalData;
      return st;
    }));
  }

  updateCircumstance$(circumstanceIncident: CircumstanceIncident, state: Observable<OutputOccurrenceData>) {
    return state.pipe(map((st) => {
      st.circumstanceIncident = circumstanceIncident;
      return st;
    }));
  }

  updateAllState$(occurrenceData: OutputOccurrenceData, state: Observable<OutputOccurrenceData>) {
    return state.pipe(map((st) => {
      st = occurrenceData;
      return st;
    }));
  }

  saveIncidentDataClaim$(st: Observable<OutputOccurrenceData>): Observable<OutputOccurrenceData>{
    return st.pipe(
      mergeMap(state => {
        const serviceCall = state.parameterInputData.claimNumber ? this.updateIncidentDataClaim$(state)
          : this.updateIncidentDataPotentialClaim$(state);
        return combineLatest([serviceCall, of(state)]);
      }), map(([response, state]) => {
        this.alertService.translatedMessage(response.detail);
        return state;
      }), mergeMap(state => {
        let request: string;
        let isClaim: boolean;
        if(state.claimSession.claimNumber) {
          let companyDesc = state.generalData.companyDescription.split(' ').join('+');
          request = `${this.baseApiUrl}/${occurrenceDataEndPoint.urlGenerateClaim}/${state.claimSession.claimNumber}?companyCode=${companyDesc}&internalCompanyCode=${state.claimSession.internalCompanyCode}`;
        } else {
          request = `${this.baseApiUrl}/${occurrenceDataEndPoint.urlGeneratePes}/${state.parameterInputData.potentialClaimNumber}?internalCompanyCode=${state.claimSession.internalCompanyCode}&potencialClaimTypeRequest=${state.claimSession.pesRequestType}`;
        }
        const serviceCall = this.httpClient.get<any>(request);
        return combineLatest([serviceCall, of(state)]);
      }), map(([responseFind, state]) => {
        state.parameterInputData.claimNumber ? state.newClaim = responseFind.claim : state.newClaim = responseFind.claimInquiry;
        if(state.claimSession) {
          state.newClaim.pesRequestType = state.claimSession.pesRequestType;
        }
        this.exit(state);
        return state;
      })
    );
  }

  loadIncidentDataFull(potClaimNumber: string, claimNum: string): Observable<OutputOccurrenceData> {
    return this.httpClient.post<OutputOccurrenceData>(this.baseApiUrlV2 + occurrenceDataEndPoint.loadIncidentDataFull,
      { potentialClaimNumber: potClaimNumber, claimNumber: claimNum });
  }

  calculateLiability(baremAObjectId: string, baremBObjectId: string): Observable<OutputLiabilityDetermination> {
    return this.httpClient.get<OutputLiabilityDetermination>(this.baseApiUrl + '/claims/liability'
      + `?idBaremCaseA=${baremAObjectId}&idBaremCaseB=${baremBObjectId}`);
  }

  exit(currentState: any) {
    const session = this.getCurrentSession();
    const newSession = currentState.newClaim;
    let finderSession = null;
    if(currentState.parentSession) {
      finderSession = currentState.parentSession;
    } else {
      finderSession = this.getFinderSession(session).idSession;
    }
    if (finderSession !== null) {
      this.session.remove(finderSession);
    }
    this.session.remove(session.idSession);
    this.reopenSession(newSession);
  }

  reopenSession(newSession: any) {
    this.eventService.broadcastEvent('start-loader');
    const idResult = this.session.open('claimsFinder', this.isFromDetail ? 'detail' : 'detail-pes', '', true);
    this.coreResult.setResult(idResult, this.isFromDetail ? 'detail' : 'detail-pes', newSession);
  }

  getFinderSession(currentSession) {
    const sessioneFinder = this.session.list().filter(el => el.name === 'claimsFinder'
    && (el.currentRoute === 'detail' || el.currentRoute === 'detail-pes') && el.idSession === currentSession.idSessionParent);
    if (sessioneFinder.length > 0) {
      this.isFromDetail = sessioneFinder[0].currentRoute === 'detail';
      return sessioneFinder[0];
    } else {
      return undefined;
    }
  }

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }
}

<rgi-rx-expansion-panel *ngIf="isAccordion else noAccordion" 
[expanded]="!(owner.objectId !== '' && owner.objectId === roles.holder.objectId)" #first color="info">
  <div rgi-rx-expansion-panel-header>
    <div class="row rgi-claims-title-section-shared" style="padding:5px;">
      <div class="col-xs-12 col-md-12">
        <label ng-bind="label">
          {{ '_CLAIMS_._OWNER' | translate }}
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="subject"></ng-container>
</rgi-rx-expansion-panel>

<ng-template #noAccordion>
  <ng-container *ngTemplateOutlet="subject"></ng-container>
</ng-template>
<ng-template #subject>
  <div class="row">
    <div class="col-sm-6 col-xs-12">
      <claims-subject-action [subject]="owner" [selectedNode]="selectedNode" [activeRouteId]="activeRouteId"
        (selectNewSubject)="selectNewSubject($event)" (deleteSubject)="deleteSubject($event)"></claims-subject-action>
    </div>
    <div class="col-sm-6 col-xs-12" *ngIf="owner?.subject?.anagSubject && 
      owner.objectId!='' && 
      owner.objectId != roles.holder.objectId">
      <button rgi-rx-button color="secondary" (click)="openDetail()">
        {{ '_CLAIMS_._DETAILS' | translate }}
        <span class="glyphicon glyphicon-pencil"></span>
      </button>
    </div>
  </div>
  <claims-subject-info [subject]="owner"></claims-subject-info>
  <claims-subject-form *ngIf="owner.objectId!='' && owner.objectId != roles.holder.objectId" 
    [subject]="{
      isDriver: owner.isDriver,
      isPassenger: owner.isPassenger,
      chkMaterial: owner.chkMaterial,
      materialDescription: owner.materialDescription,
      chkInjury: owner.chkInjury,
      isMinor: owner.isMinor
    }" 
    [fields]="fields" 
    [listMinorGuardian]="listMinorGuardian"
    (outputListMinorGuardian)="listMinorGuardianChange($event)"
    (outputDataForm)="outputFormChange($event, listMinorGuardian)"
  >
  </claims-subject-form>
  <div *ngIf="chkInjury && (owner.objectId!='' && owner.objectId != roles.holder.objectId)">
    <claims-dsc-phisical-injury
    [expanded]="chkInjury && owner.objectId!='' && owner.objectId != roles.holder.objectId"
    [injuryAssetInput]="owner.subjectDataFormInjury"
    [selectedNode]="selectedNode"
    [selectedPolicy]="selectedPolicy"
    [activeRouteId]="activeRouteId"
    [categorySelected]="categorySelected" 
    [categoryList]="categoryList" 
    [subject]="owner"
    [heirsList]="heirsList"
    (outputHeir)="outputHeirData($event)"
    (outputInjuryData)="outputFormChangeInjury($event)"></claims-dsc-phisical-injury>
  </div>
</ng-template>


import {Injectable} from '@angular/core';
import {QuestionnaireConditionsService} from './questionnaire-conditions.service';
import {
  AnswerContainerI,
  AnswerType,
  AnswerValueI,
  QuestionAnswerI,
  QuestionnaireI,
  QuestionStatuses
} from "../models";
import {AnswerFlatI, QuestionFlatI, QuestionnaireFlatI} from "../models/questionnaireFlat";
import {SectionQuestionI} from "../models/section-question";

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireValuesService {


  constructor(private conditionsService: QuestionnaireConditionsService) {
  }


  public assignValues(questionnaireVersion: QuestionnaireI, questionnaireFlat: QuestionnaireFlatI) {
    this.assignValuesCommon(questionnaireVersion, questionnaireFlat,
      (questionSectionVersion: QuestionAnswerI, answer: AnswerContainerI, questionFlatI: QuestionFlatI, answerFlatI: AnswerFlatI, section: SectionQuestionI) => {

        if (answer.answer.type == AnswerType.LIST && !answer.multivalue) {
          answer.value = answer.answer.answerValues.find(
            (answerValue: AnswerValueI) => answerValue.id == parseInt(answerFlatI.value)
          );
        } else if (answer.answer.type == AnswerType.DATE) {
          // language=JSRegexp
          if (answerFlatI.value) {
            if (typeof answerFlatI.value == 'string' && answerFlatI.value.match('^-?\\d+(\\.\\d+)?$')) {
              answer.value = new Date(parseInt(answerFlatI.value));
            } else {
              answer.value = new Date(answerFlatI.value);
            }
          }
        } else {
          answer.value = answerFlatI.value;
        }
        //questionSectionVersion.status = questionFlatI.status;
        questionSectionVersion.conditionsFlat = questionFlatI.conditions;
      });
  }


  public checkConditions(questionnaire: QuestionnaireI) {

    let allQuestions: QuestionAnswerI[] = [];

    questionnaire.sections.forEach(
      (questionnaireSectionVersion: SectionQuestionI) => {
        allQuestions = [...allQuestions, ...questionnaireSectionVersion.questions];

      }
    );

    this.conditionsService.checkQuestionsSections(allQuestions);

    questionnaire.sections.forEach(
      (section: SectionQuestionI) => {
        section.visible =
          section.questions.some(
            (question: QuestionAnswerI) => question.visible
          );
      }
    );
  }

  public assignValidationAllQuestions(questionnaire: QuestionnaireI) {
    return !questionnaire.sections
      .reduce((list, section: SectionQuestionI) => [...list, ...section.questions], [])
      .map(this.assignValidationQuestion)
      .some((qsv) => !qsv.isValid);
  }

  /** Function to validate single question answer
   *  The question is valid if:
   *  • is Visible
   *  • is Compulsory
   *  • has at least one Answer
   *  • the answer is truthy (can be zero (0) if answerType is DATE)
   *  OR
   *  • is NOT Compulsory
   * */
  public assignValidationQuestion(question: QuestionAnswerI): QuestionAnswerI {
    question.isValid = (question.visible && question.compulsory && question.answers[0]
        && (!!question.answers[0].value || (question.answers[0].answer.type == AnswerType.DATE && question.answers[0].value == 0))) ||
      (!question.compulsory);
    return question;
  }

  public assignFlatValues(questionnaireVersion: QuestionnaireI, questionnaireFlat: QuestionnaireFlatI) {
    this.assignValuesCommon(questionnaireVersion, questionnaireFlat,
      (question: QuestionAnswerI, answer: AnswerContainerI, questionFlat: QuestionFlatI, answerFlatI: AnswerFlatI, section: SectionQuestionI) => {
        questionFlat.visible = question.visible;
        questionFlat.compulsory = question.compulsory;

        if (questionFlat.section) {
          questionFlat.section.visibilita = section.visible;
        }
        if (!question.visible) {
          if ((question.conditionsFlat.length > 0 && question.status == QuestionStatuses.EXCLUDED) || question.status != QuestionStatuses.EXCLUDED) {
            answerFlatI.value = null;
          }

        } else if (answer.answer.type == AnswerType.LIST) {
          if (answer.multivalue) {
            if (typeof answer.value === 'object') {
              answerFlatI.value = "";
            } else {
              answerFlatI.value = answer.value;
            }
          } else {
            answerFlatI.value = answer.value ? answer.value.id : "";
          }

          if (answerFlatI.value === '') {
            answerFlatI.value = null;
          }

        } else if (answer.answer.type == AnswerType.NUMERIC) {
          answerFlatI.value = answer.value + "";
          if (answerFlatI.value === '') {
            answerFlatI.value = null;
          }
        } else if (answer.answer.type == AnswerType.DATE) {
          if (answer.value instanceof Date) {
            answer.value = answer.value.getTime();
          }
          //answerFlatI.value = answer.value ? answer.value + "" : null;
          answerFlatI.value = answer.value === 0 || answer.value ? answer.value + "" : null;
        } else {
          answerFlatI.value = answer.value;
        }
        if (answerFlatI.value === 'null') {
          answerFlatI.value = null;
        }
      });
  }

  public assignValuesCommon(questionnaire: QuestionnaireI, questionnaireFlat: QuestionnaireFlatI,
                            assignFunction: (question: QuestionAnswerI, answer: AnswerContainerI, questionFlat: QuestionFlatI, aswerFlatI: AnswerFlatI, section: SectionQuestionI) => void) {

    const answerMap = this.createAswerMap(questionnaireFlat.questions);

    questionnaire.sections.forEach(
      (section: SectionQuestionI) => {
        section.questions.forEach(
          (question: QuestionAnswerI) => {
            question.isValid = question.isValid || true;
            question.answers.forEach(
              (answer: AnswerContainerI) => {
                const key = this.createAnswerMap(section, question, answer);
                if (!answerMap[key]) {
                  console.error("-------> not find", answer);
                  return;
                }
                const data: { questionFlat: QuestionFlatI, answer: AnswerFlatI } = answerMap[key];

                assignFunction(question, answer, data.questionFlat, data.answer, section);
              }
            );
          }
        );
      }
    );

  }

  public disableAllQuestions(questionnaire: QuestionnaireI) {
    questionnaire.sections.forEach(
      section => {
        section.questions.forEach(
          question => question.isDisabled = true
        );
      }
    );
  }

  public enableAllQuestions(questionnaire: QuestionnaireI, except?: Array<{ code: string }>) {
    questionnaire.sections.forEach(
      section => {
        section.questions.filter(
          questionAnswer => except ? !except.find(exception => this.findExcludedQuestionsByCode(questionAnswer.question.code, exception.code)) : true
        ).forEach(
          question => question.isDisabled = false
        );
      }
    );
  }

  protected findExcludedQuestionsByCode(questToCheck: String, questExcluded: String) {
    return questExcluded.indexOf(".") > 0 ? questToCheck === questExcluded : questToCheck.split(".")[questToCheck.split(".").length - 1] === questExcluded;
  }

  protected createAswerMap(questionsFlat: QuestionFlatI[]) {
    const answerMap = {};
    questionsFlat.forEach(
      (questionFlat: QuestionFlatI) => {
        questionFlat.answers.forEach(
          (answer: AnswerFlatI) => {
            answerMap[this.createAnswerMapKeyByFlat(questionFlat, answer)] = {questionFlat, answer};
          }
        );
      }
    );
    return answerMap;
  }


  protected createAnswerMapKeyByFlat(questionFlat: QuestionFlatI, answer: AnswerFlatI): string {
    // const splitted = questionFlat.code.split(".");
    // const questionCode = splitted.length>1 ? splitted[1] : splitted[0];
    // return questionFlat.section.id+"."+questionCode+"."+answer.factorCode

    return questionFlat.code + "." + answer.factorCode;
  }


  protected createAnswerMap(section: SectionQuestionI, question: QuestionAnswerI, answer: AnswerContainerI): string {
    return /*section.questionnaireSection.id+"."+*/question.question.code + "." + answer.answer.code;
  }


}

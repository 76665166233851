export class Mode {
  private name: string;
  private functions: string[] = [];

  constructor(mode: string, functions?: string[]) {
    this.name = mode;
    if (functions) {
      this.functions = functions;
    }
  }

  addFunction(func: string): Mode {
    if (!this.hasFunction(func)) {
      this.functions.push(func);
    }
    return this;
  }

  hasFunction(func: string): boolean {
    return !!this.functions.find(el => el === func);
  }

  modeName() {
    return this.name;
  }

  getFunctions(): string[] {
    return this.functions;
  }

  setFunctions(newFunctions: string[]) {
    this.functions = newFunctions;
  }
}

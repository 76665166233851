import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose, RgiRxTabSelectionChange} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagStateManagerPartyEditor} from '../../../anag-states/party-editor/anag-state-manager-party-editor';
import {Subject, Subscription} from 'rxjs';
import {AnagEntityIta} from '../../../anag-model/anag-api/anag-subject-api';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagStorageService} from '../../../anag-resources/anag-storage.service';
import {RgiRxEventService, PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {AnagStatelessService} from '../../../anag-resources/anag-stateless.service';
import {
  AnagTaxClassData,
  AnagTaxClassification, AnagtaxClassificationData
} from "../../../anag-model/anag-domain/anag-tax-classification";
import {AnagStateKeyData} from "../../../anag-states/key-data/anag-state-manager-key-data";
import {RgiRxUserService} from '@rgi/rx/auth';
import {AnagCountry} from "../../../anag-model/anag-domain/anag-country";
import {AnagFormFieldConfig} from '../../../anag-model/anag-domain/anag-form-field-config';
import {AnagConfigService} from "../../../anag-resources/anag-config.service";

@Component({
  selector: 'rgi-anag-tax-classification',
  templateUrl: './anag-tax-classification.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagTaxClassificationComponent implements OnInit, OnModalClose, OnDestroy {

  modalClose: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription = new Subscription();
  tmpTaxClass: AnagtaxClassificationData;
  taxClassifications: AnagTaxClassification[];
  modalLabel: string;
  updateTaxCLassEmitter: Subject<void> = new Subject<void>();
  errorMsg: string;
  state: AnagStateKeyData;
  datepickerPlaceholder: string;
  originalFormValues: UntypedFormGroup;
  isFormDirty = false;

  newTaxClassificationForm: UntypedFormGroup = new UntypedFormGroup({
    country: new UntypedFormControl(undefined, Validators.required),
    type: new UntypedFormControl(undefined, Validators.required),
    countryStartDate: new UntypedFormControl(undefined, Validators.required)
  });

  newTaxClassificationFormMap = new Map<string, AnagFormFieldConfig>([
    ['tin', new AnagFormFieldConfig('tin', this.newTaxClassificationForm, [], 'taxClassifications')],
    ['classificationRisk', new AnagFormFieldConfig('classificationRisk', this.newTaxClassificationForm, [], 'taxClassifications')],
    ['classificationReason', new AnagFormFieldConfig('classificationReason', this.newTaxClassificationForm, [], 'taxClassifications')],
    ['classificationBasis', new AnagFormFieldConfig('classificationBasis', this.newTaxClassificationForm, [], 'taxClassifications')],
    ['classificationDate', new AnagFormFieldConfig('classificationDate', this.newTaxClassificationForm, [], 'taxClassifications')],
    ['countryEndDate', new AnagFormFieldConfig('countryEndDate', this.newTaxClassificationForm, [], 'taxClassifications')],
  ]);

  /*
  newTaxClassificationForm: FormGroup = new FormGroup({
    country: new FormControl(undefined, Validators.required),
    type: new FormControl(undefined, Validators.required),
    tin: new FormControl(undefined, undefined),
    classificationRisk: new FormControl(undefined, undefined),
    classificationReason: new FormControl(undefined, undefined),
    classificationBasis: new FormControl(undefined, undefined),
    classificationDate: new FormControl(undefined, undefined),
    countryStartDate: new FormControl(undefined, Validators.required),
    countryEndDate: new FormControl(undefined)
  });
*/

  filteredCountries: AnagCountry[];
  filteredRiskList: AnagEntityIta[];
  filteredReasonList: AnagEntityIta[];
  filteredBasisList: AnagEntityIta[];
  types: AnagEntityIta[];

  typeSelected: AnagEntityIta;
  editMode: boolean;

  constructor(
    public anagStorage: AnagStorageService,
    public eventService: RgiRxEventService,
    public statelessService: AnagStatelessService,
    public pushMessageHandler: PushMessageHandlerService,
    protected userService: RgiRxUserService,
    protected translateService: RgiRxTranslationService,
    public configService: AnagConfigService,
    public stateMgr: AnagStateManagerPartyEditor,
    @Optional() @Inject(DIALOG_DATA) data: AnagTaxClassData) {
    if (data) {
      this.taxClassifications = data.tacClassificationList;
      if (data.taxClassificationData) {
        this.tmpTaxClass = this.statelessService.deepClone(data.taxClassificationData);
      }
      if (data.modalLabel) {
        this.modalLabel = data.modalLabel;
      }
      if (data.editMode) {
        this.editMode = data.editMode;
      }
    }
  }

  ngOnInit() {
    this.datepickerPlaceholder = this.statelessService.getDatePickerPlaceholderLabel();
    this.configService.setConfigToForm(this.newTaxClassificationFormMap, this.stateMgr.getCurrentState().configuration);
    this.translation();
    this.pushMessageHandler.clearTag('error-msg-tag');
    this.initializeTypes();
    if (this.tmpTaxClass) {
      this.filterClassificationLists(this.tmpTaxClass.type.codice);
      this.loadTaxClassToEdit();
    } else {
      this.filterClassificationLists("1");
    }
    this.originalFormValues = this.newTaxClassificationForm.value;
    this.newTaxClassificationForm.valueChanges.subscribe(value => {
      this.isFormDirty = JSON.stringify(value) !== JSON.stringify(this.originalFormValues);
    });
  }

  getSelectedTabIndex(): number {
    return this.typeSelected ? this.typeSelected.descrizione === 'FATCA' ? 0 : 1 : undefined;
  }

  getDisabledTab() {
    return this.editMode && this.typeSelected ? this.typeSelected.descrizione === 'FATCA' ? 0 : 1 : false;
  }

  disableCountryIfNecessary() {
    if (this.typeSelected && this.typeSelected.descrizione === 'FATCA' || this.typeSelected.descrizione === 'CRS') {
      this.newTaxClassificationForm.get('country').disable();
    }
  }

  loadTaxClassToEdit() {
    let selCountry: AnagCountry = this.filteredCountries.find(country => {
      return country.abbreviation === this.tmpTaxClass.country.codice
    });
    this.newTaxClassificationForm.get('country').setValue(selCountry.abbreviation, {emitEvent: false});
    this.typeSelected = this.tmpTaxClass.type;
    this.newTaxClassificationForm.get('countryStartDate').setValue(this.tmpTaxClass.countryStartDate, {emitEvent: false});
    this.disableCountryIfNecessary();
    if (this.newTaxClassificationForm.get('tin')) {
      this.newTaxClassificationForm.get('tin').setValue(this.tmpTaxClass.tin, {emitEvent: false});
    }
    if (this.newTaxClassificationForm.get('classificationRisk')) {
      this.newTaxClassificationForm.get('classificationRisk').setValue(this.tmpTaxClass.riskClassification ? this.tmpTaxClass.riskClassification.codice : '', {emitEvent: false});
    }
    if (this.newTaxClassificationForm.get('classificationReason')) {
      this.newTaxClassificationForm.get('classificationReason').setValue(this.tmpTaxClass.reasonClassification ? this.tmpTaxClass.reasonClassification.codice : '', {emitEvent: false});
    }
    if (this.newTaxClassificationForm.get('classificationBasis')) {
      this.newTaxClassificationForm.get('classificationBasis').setValue(this.tmpTaxClass.basisClassification ? this.tmpTaxClass.basisClassification.codice : '', {emitEvent: false});
    }
    if (this.newTaxClassificationForm.get('classificationDate')) {
      this.newTaxClassificationForm.get('classificationDate').setValue(this.tmpTaxClass.classificationDate, {emitEvent: false});
    }
    if (this.newTaxClassificationForm.get('countryEndDate')) {
      this.newTaxClassificationForm.get('countryEndDate').setValue(this.tmpTaxClass.countryEndDate, {emitEvent: false});
    }
  }

  loadTaxClassToSave() {
    // let tmp : AnagTaxClassification = this.tmpTaxClass;
    if (!this.tmpTaxClass) {
      this.tmpTaxClass = new AnagtaxClassificationData();
    }

    if (this.isFormDirty) {

      let contryAbbreviation = this.newTaxClassificationForm.get('country').value;
      let selCountry: AnagCountry = this.filteredCountries.find(c => {
        return c.abbreviation === contryAbbreviation
      });
      this.tmpTaxClass.country = new AnagEntityIta(selCountry.abbreviation, selCountry.description);
      this.tmpTaxClass.countryStartDate = this.newTaxClassificationForm.get('countryStartDate').value;
      this.tmpTaxClass.type = this.typeSelected;

      if (this.newTaxClassificationForm.get('tin')) {
        this.tmpTaxClass.tin = this.newTaxClassificationForm.get('tin').value;
      }
      if (this.newTaxClassificationForm.get('classificationRisk')) {
        let riskClassCode = this.newTaxClassificationForm.get('classificationRisk').value;
        this.tmpTaxClass.riskClassification = this.filteredRiskList.find(c => {
          return c.codice === riskClassCode
        });
      }
      if (this.newTaxClassificationForm.get('classificationReason')) {
        let reasonClassCode = this.newTaxClassificationForm.get('classificationReason').value;
        this.tmpTaxClass.reasonClassification = this.filteredReasonList.find(c => {
          return c.codice === reasonClassCode
        });
      }
      if (this.newTaxClassificationForm.get('classificationBasis')) {
        let basisClassCode = this.newTaxClassificationForm.get('classificationBasis').value;
        this.tmpTaxClass.basisClassification = this.filteredBasisList.find(c => {
          return c.codice === basisClassCode
        });
      }
      if (this.newTaxClassificationForm.get('classificationDate')) {
        this.tmpTaxClass.classificationDate = this.newTaxClassificationForm.get('classificationDate').value;
      }
      if (this.newTaxClassificationForm.get('countryEndDate')) {
        this.tmpTaxClass.countryEndDate = this.newTaxClassificationForm.get('countryEndDate').value;
      }
      // this.tmpTaxClass.objectId = tmp.objectId;
    } else {
      this.tmpTaxClass = null;
    }
  }

  initializeTypes() {
    var t1 = {descrizione: 'FATCA', codice: '1'};
    var t2 = {descrizione: 'CRS', codice: '2'};
    this.types = [];
    this.types.push(t1);
    this.types.push(t2);
  }

  filterClassificationLists(typeSelectedCode: string) {
    this.anagStorage.getRiskClassifications(typeSelectedCode).subscribe(res => {
      this.filteredRiskList = res;
    });
    this.anagStorage.getReasonClassifications(typeSelectedCode).subscribe(res => {
      this.filteredReasonList = res;
    });
    this.anagStorage.getBasisClassifications(typeSelectedCode).subscribe(res => {
      this.filteredBasisList = res;
    });

    let fatcaType = this.types.find(c => {
      return c.descrizione === 'FATCA'
    });
    if (typeSelectedCode === fatcaType.codice) {
      this.typeSelected = fatcaType;
      let countryUS = this.anagStorage.countries.filter(country => {
        return country.abbreviation === 'US'
      });
      this.filteredCountries = countryUS;
      this.newTaxClassificationForm.get('country').setValue(countryUS[0].abbreviation, {emitEvent: false});
      this.newTaxClassificationForm.get('country').disable();
    } else { // CRS
      this.filteredCountries = this.anagStorage.countries.filter(country => {
        return country.abbreviation != 'US'
      });
      this.newTaxClassificationForm.get('country').reset();
      this.newTaxClassificationForm.get('country').enable();
    }
  }

  onTypeSelect(event) {
    let typeSelectedCode = this.newTaxClassificationForm.get('type').value;
    this.filterClassificationLists(typeSelectedCode);
  }

  onTypeTabSelected($event: RgiRxTabSelectionChange) {
    let typeSelectedDescr = $event.tab.label;
    let typeSel = this.types.find(c => {
      return c.descrizione === typeSelectedDescr
    });
    if (this.typeSelected?.codice !== typeSel?.codice) {
      this.typeSelected = typeSel;
      this.filterClassificationLists(typeSel.codice);
    }
  }

  actionClose() {
    this.modalClose.emit();
  }

  actionConfirm() {
    this.pushMessageHandler.clearTag('error-msg-tag');
    if (this.manageFormValid()) {
      this.loadTaxClassToSave();
      this.updateTaxCLassEmitter.next();
      if (this.tmpTaxClass) {
        this.modalClose.emit(this.tmpTaxClass);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  manageFormValid() {
    if (!!this.newTaxClassificationForm.get('countryStartDate')) {
      this.newTaxClassificationForm.get('countryStartDate').updateValueAndValidity();
    }
    if (!!this.newTaxClassificationForm.get('countryEndDate')) {
      this.newTaxClassificationForm.get('countryEndDate').updateValueAndValidity();
    }
    let isCountryValid = true;
    if (this.typeSelected) {
      isCountryValid = this.newTaxClassificationForm.get('country') ? !this.newTaxClassificationForm.get('country').invalid : false;
    }
    const isTinValid = this.newTaxClassificationForm.get('tin') ? this.newTaxClassificationForm.get('tin').valid : true;
    const isRiskValid = this.newTaxClassificationForm.get('classificationRisk') ? this.newTaxClassificationForm.get('classificationRisk').valid : true;
    const isReasonValid = this.newTaxClassificationForm.get('classificationReason') ? this.newTaxClassificationForm.get('classificationReason').valid : true;
    const isBasisValid = this.newTaxClassificationForm.get('classificationBasis') ? this.newTaxClassificationForm.get('classificationBasis').valid : true;
    const isClassificationDateValid = this.newTaxClassificationForm.get('classificationDate') ? this.newTaxClassificationForm.get('classificationDate').valid : true;
    const isStartDateValid = this.newTaxClassificationForm.get('countryStartDate') ? this.newTaxClassificationForm.get('countryStartDate').valid : false;
    const isEndDateValid = this.newTaxClassificationForm.get('countryEndDate') ? this.newTaxClassificationForm.get('countryEndDate').valid : true;

    let isFormValid = true;

    if (!isCountryValid || !isTinValid || !isRiskValid || !isBasisValid || !isReasonValid
      || !isClassificationDateValid || !isStartDateValid || !isEndDateValid) {
      this.newTaxClassificationForm.markAllAsTouched();
      isFormValid = false;
    } else {
      if (this.newTaxClassificationForm.get('countryStartDate').value && this.newTaxClassificationForm.get('countryEndDate') && this.newTaxClassificationForm.get('countryEndDate').value) {
        let dateOk = new Date(this.newTaxClassificationForm.get('countryStartDate').value) < new Date(this.newTaxClassificationForm.get('countryEndDate').value);
        if (!dateOk) {
          this.newTaxClassificationForm.get('countryStartDate').setErrors({'_ANAG_._MSG_._INVALID_DATE_VALUE': true});
          this.newTaxClassificationForm.get('countryEndDate').setErrors({'_ANAG_._MSG_._INVALID_DATE_VALUE': true});
          isFormValid = false;
        }
      }
    }
    if (isFormValid && this.newTaxClassificationForm.get('countryEndDate')?.value && this.tmpTaxClass?.countryEndDate !== this.newTaxClassificationForm.get('countryEndDate').value) {
      let selectedCountry: string = this.newTaxClassificationForm.get('country').value;
      let selCountryStartDate: Date = this.newTaxClassificationForm.get('countryStartDate').value;
      let selCountryEndDate: Date = this.newTaxClassificationForm.get('countryEndDate').value;
      if (this.taxClassifications) {
        this.taxClassifications.forEach(taxClass => {
          let taxSameTypeAndCountry: AnagtaxClassificationData[] = taxClass.taxClassificationsData.filter(t => {
            return t.type.codice === this.typeSelected.codice && t.country.codice === selectedCountry
            // && (this.tmpTaxClass && this.tmpTaxClass.objectId && t.objectId !== this.tmpTaxClass.objectId)
          });
          if (taxSameTypeAndCountry && taxSameTypeAndCountry.length > 0) {
            let booleanStop = false;
            taxSameTypeAndCountry.slice().reverse().forEach(el => {
              if (!el.classificationDeleted) {
                if (isFormValid && !booleanStop) {
                  if (el.countryEndDate) {
                    let elementCountryEndDate: Date = new Date(el.countryEndDate);
                    if (elementCountryEndDate > selCountryStartDate) {
                      isFormValid = false;
                      this.notifyFormValidationMsg(this.errorMsg);
                    }
                  } else {
                    let elementCountryStartDate: Date = new Date(el.countryStartDate);
                    if (!selCountryEndDate || elementCountryStartDate > selCountryEndDate) {
                      isFormValid = false;
                      this.notifyFormValidationMsg(this.errorMsg);
                    }
                  }
                }
                booleanStop = true;
              }
            });
          }
        })
      }
    }

    if (isFormValid) {
      this.pushMessageHandler.clearTag('error-msg-tag');
    }
    return isFormValid;
  }

  notifyFormValidationMsg(stringMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
      tag: 'error-msg-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  translation() {
    this.translateService.translate('_ANAG_._MSG_._TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_').subscribe(translated => this.errorMsg = translated).unsubscribe();
  }

  isFieldVisible(field: string) {
    return this.newTaxClassificationForm.get(field);
  }
}

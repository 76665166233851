import {Component, Input, OnInit} from '@angular/core';
import {Residence} from '../../models/residence';

@Component({
  selector: 'lpc-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() address: Residence;

  constructor() { }

  ngOnInit() {
  }

}

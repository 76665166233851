import { Injectable } from '@angular/core';
import { EvaluationResourceDefinition } from '@rgi/ng-passpropro';
import { Observable, of } from 'rxjs';
import {AnagSubject, Operator} from "../integration/model/anag-common.models";


@Injectable()
export class QuestionnaireResourcesService {


    constructor(){

    }

    getResources$(subject?:AnagSubject, operator?:Operator):Observable<EvaluationResourceDefinition[]> {
        const resources:EvaluationResourceDefinition[] = [];

        if(subject){
            const subjectResource = this.getSubjectResource(subject);
            resources.push(subjectResource);
        }

        if(operator){
            const operatorResource = this.getOperatorResource(operator);
            resources.push(operatorResource);
        }

        return of(resources);
    }


    getSubjectResource(subject:AnagSubject): EvaluationResourceDefinition {
        return this.createResource(subject, subject.objectId, "subject");
    }


    getOperatorResource(operator:Operator): EvaluationResourceDefinition{
        return this.createResource(operator, operator.objectId, "operator");
    }


    createResource(instance: {}, id: string, type: string): EvaluationResourceDefinition {
        return { instance, resourceDefinition: { id, type } }
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {RgiRxDataTableExpansionModel, TableSchema} from '@rgi/rx//ui';
import {MovementItem} from '../../../models/movement';
import {DATEFORMAT, TIMEFORMAT} from '../../../constants/consultation-utility';

export const MOVEMENTS_LIST_SCHEMA = {
  rows: [
    {
      name: 'tableExpansion',
      title: '',
    },
    {
      name: 'reasonType',
      title: 'CONTRACT_CONSULTATION.TYPE',
    },
    {
      name: 'description',
      title: 'CONTRACT_CONSULTATION.DESCRIPTION',
    },
    {
      name: 'issue',
      title: 'CONTRACT_CONSULTATION.ISSUE_DATE',
      format: {
        pipe: 'date',
        args: [DATEFORMAT]
      }
    },
    {
      name: 'effect',
      title: 'CONTRACT_CONSULTATION.EFFECTIVE_DATE',
    },
    {
      name: 'appendix',
      title: 'CONTRACT_CONSULTATION.NUMBER',
    },
    {
      name: 'user',
      title: 'CONTRACT_CONSULTATION.USER',
    },
    {
      name: 'actions',
      title: '',
      actions: [
        {
          name: 'DETAILS',
          styleClass: 'rgi-ui-icon-right-arrow'
        }
      ]
    }
  ],
  header: ['tableExpansion','reasonType', 'description', 'issue', 'effect', 'appendix', 'user', 'actions']
};


@Component({
  selector: 'consultation-movements',
  templateUrl: './consultation-movements.component.html',
  styleUrls: ['./consultation-movements.component.css']
})
export class ConsultationMovementsComponent {

  @Input()movements: MovementItem[] = [];

  tableSchema: TableSchema = MOVEMENTS_LIST_SCHEMA;
  pageOptions: number[] = [10, 20, 50];
  customExpansionModel: RgiRxDataTableExpansionModel<MovementItem>;

  TIMEFORMAT = TIMEFORMAT;
  DATEFORMAT = DATEFORMAT;

}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Extracts an item from an array based on a specified key-value pair.
 * If a field to extract is provided, it returns the value of that field from the found item.
 * If no field to extract is provided, it returns the found item itself.
 *
 * @param arr - The array to search in.
 * @param key - The key to match against in each item of the array.
 * @param value - The value to match against the key in each item of the array.
 * @param fieldToExtract - (Optional) The field to extract from the found item.
 * @returns The found item or the value of the specified field from the found item.
 */
@Pipe({
  name: 'extractFromArray'
})
export class ExtractFromArrayPipe implements PipeTransform {

  transform(arr: any[], key: string, value: any, fieldToExtract: string = null): any {
    if (!Array.isArray(arr)) {
      return arr;
    }
    if (!!fieldToExtract) {
      return arr.find(field => field[key] === value)[fieldToExtract];
    }
    return arr.find(field => field[key] === value);
  }
}

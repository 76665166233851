<div class="rgi-ui-snackbar-header" [ngClass]="snackbarNotification.getClass(snackbarNotification.notification)">

  <ng-container *ngIf="snackbarNotification.notification.detail">
    <span class="rgi-ui-icon rgi-ui-mr-md" [ngClass]="{'rgi-ui-icon-close-content': !expandHost.expanded, 'rgi-ui-icon-open-content' : !!expandHost.expanded}"></span>
  </ng-container>


  <ng-container *ngIf="snackbarNotification.notification.options && snackbarNotification.notification.options.icon">
    <span class="rgi-ui-icon-snackbar" [ngClass]="snackbarNotification.notification.options.icon"></span>
  </ng-container>
  <ng-template *ngIf="snackbarNotification.contentPortal as portal" #portalOutlet="cdkPortalOutlet"
               (attached)="snackbarNotification.onOutletAttached($event, portalOutlet, portal)"
               [cdkPortalOutlet]="portal"
  ></ng-template>
  <span *ngIf="snackbarNotification.isText(snackbarNotification.notification.content)" [translate]="$any(snackbarNotification.notification.content)" [translateParams]="snackbarNotification.notification.context"></span>
  <button role="button" [attr.aria-label]="'RGI_RX.SNACKBAR.CLOSE_LABEL' | translate" class="rgi-ui-btn-close" *ngIf="snackbarNotification.notification.dismissible"
          (click)="snackbarNotification.onRemove()"></button>
</div>

import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DigitalClaimsCommonAngularModule,
  CardService,
  enumServiceProvider,
  getSystemPropertyProvider
} from '@rgi/digital-claims-common-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AssistantComponent } from './assistant.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';


@NgModule({
    declarations: [
        AssistantComponent
    ],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        FormsModule,
        ReactiveFormsModule,
        DigitalClaimsCommonAngularModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        [NgbModule]
    ],
    exports: [
        AssistantComponent
    ],
    providers: [
        enumServiceProvider,
        getSystemPropertyProvider
    ]
})
export class AssistantModule {
  constructor(cardService: CardService) {
    const title = '_CLAIMS_PILLS';
    const moduleName = 'assistant';
    const parentCardName = 'claimsFinder';
    const communication = {};

    cardService.registerCard(
        moduleName,
        title,
        AssistantComponent,
        'ClaimsCommunicationCtrl'
    );

  //   cardService.registerSubCard(
  //     moduleName,
  //     title,
  //     AssistantComponent,
  //     parentCardName,
  //     null,
  //     'ClaimsCommunicationCtrl',
  //     communication
  // );
  }
 }

import {Injectable} from '@angular/core';
import {PdfModalComponent} from '@rgi/ng-passpropro-core';
import {combineLatest, forkJoin, merge, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {EvaluatorObjectI} from '../../evaluation/models/evaluator-object';
import {EvaluatorProductI} from '../../evaluation/models/evaluator-products';
import {SurveyVersionI} from '../../models/survey-version';
import {BTN__MAP_EVAL, SurveyEvaluateState} from '../models/survey-evaluate-state';
import {SurveyCommonOperations} from '../tools/survey-common-operations.service';
import {SurveyEvalTools} from '../tools/survey-eval-tools.service';
import {SurveyEditRouteData} from '../models/survey-edit-route-data';
import {RoutingService} from '@rgi/rx/router';
import {ModalService} from '@rgi/rx/ui';
import {DocumentMetadata, DocumentResource, Document as PassProDocument} from '@rgi/ng-passpropro/documents';
import {LoggerFactory} from '@rgi/rx';


@Injectable()
export class SurveyEvaluateStateOperations<S extends SurveyEvaluateState> {

  private readonly logger = LoggerFactory();

  constructor(
    private _commOps: SurveyCommonOperations,
    private _modalService: ModalService,
    private _routingService: RoutingService,
    private documentResource: DocumentResource,
    private _tools: SurveyEvalTools
  ) {

  }


  beforeUpdate$(st: S): Observable<S> {
    if (!st.navigation) {
      st.navigation = {
        stickable: true,
        map: BTN__MAP_EVAL,
      };
    }

    const hasProducts: boolean = st.productsCfg.hasVisibleProds;

    st.navigation.map.SAVE.visible = hasProducts && !!st.isEdit && !!st.selectEnabled;
    st.navigation.map.CONTINUE.visible = hasProducts && !!st.isEdit && st.integrationFilter && st.integrationFilter.allowContinue;
    st.navigation.map.MODIFY.visible = hasProducts && st.isEdit && !st.selectEnabled;
    st.navigation.map.PRINT.visible = !st.navigation.map.MODIFY.visible;
    st.initialized = true;
    return of(st);
  }


  getInit$(st$: Observable<S>): Observable<S> {
    return st$.pipe(
      tap(st => this._tools.validationTool.clearNotifications()),
      mergeMap(st => {
        const $documents = st.evaluatorProducts.map(evaluatorProduct => {
          const documentMetadata: DocumentMetadata = {
            value: [
              evaluatorProduct.cuuid
            ],
            key: 'product',
            type: undefined
          };

          const documentMetadata2: DocumentMetadata = {
            key: 'tag',
            value: [
              'KID',
              'DIP'
            ],
            type: undefined
          };
          return this.documentResource.searchDocumentListByMetadata([documentMetadata, documentMetadata2]).pipe(
            catchError(err => {
              this.logger.debug(`Missing files for product ${evaluatorProduct.code}`);
              return of([]);
            })
          );
        });

        return combineLatest([of(st), merge(...$documents)]);
      }),
      map(([st, docs]) => {
          st.selectGroupEnabled = this._tools.productsCfgTool.isGroupProductSelectionEnabled();
          st.groupProductsByLob = this._tools.productsCfgTool.isGroupProductsByLob();
          st.changed = st.selectEnabled;
          const documentList: { [key: string]: PassProDocument[] } = {};

          docs.forEach(doc => {
            const metadata: DocumentMetadata | undefined = doc.metadata.find(singleMetadata => singleMetadata.key === 'product');
            if (metadata && metadata.value && metadata.value[0]) {
              const documentKey = metadata.value[0];
              if (!documentList[documentKey]) {
                documentList[documentKey] = [];
              }
              documentList[documentKey].unshift(doc);
            }
          });
          st.documents = documentList;

          return st;
        }
      ),
      mergeMap(st => this.setProducts$(st, st.evaluatorProducts))
    );

  }


  getSave$(st$: Observable<S>): Observable<S> {

    const updateEvaluation = (st: S): Observable<S> => {
      return this.ensureAllPersisted(st).pipe(
        mergeMap(st => this.updateEvaluation$(st))
      );
    };

    return st$.pipe(
      mergeMap(st => this.validateEphemeral$(st)),
      mergeMap(st => this.isEvaluationValid(st) ? updateEvaluation(st) : of(st))
    );

  }


  getPrint$(st$: Observable<S>): Observable<S> {

    const saveAndPrint$ = (st: S): Observable<S> => {
      return this.ensureAllPersisted(st).pipe(
        mergeMap(st => this.updateEvaluation$(st)),
        mergeMap(st => this.doPrint$(st))
      );
    };

    const doByIfSurveyValid$ = (st: S) => {
      const valid = this._commOps.isSurveyValid(st.surveyVersion);
      if (valid) {
        return this.validateEphemeral$(st).pipe(
          mergeMap(st => this.isEvaluationValid(st) ? saveAndPrint$(st) : of(st)),
        );
      } else {
        return this.doPrint$(st);
      }

    };

    return st$.pipe(
      mergeMap(doByIfSurveyValid$)
    );

  }


  getContinue$(st$: Observable<S>): Observable<S> {

    const saveAndNavigate$ = (state: S): Observable<S> => {
      return this.ensureAllPersisted(state).pipe(
        mergeMap(st => this.updateEvaluation$(st)),
        map(st => this.resetValidation(st)),
        mergeMap(st => this.navigateToContinue(st))
      );
    };

    const doByIsEdit$ = (state: S): Observable<S> => {
      if (state.isEdit) {
        return this.validateEphemeral$(state).pipe(
          mergeMap(st => this.isEvaluationValid(st) ? saveAndNavigate$(st) : of(st))
        );
      } else {
        return of(state).pipe(
          mergeMap(st => this.navigateToContinue(st))
        );
      }
    };

    return st$.pipe(
      mergeMap(doByIsEdit$)
    );

  }


  getBack$(st$: Observable<S>): Observable<S> {
    return st$.pipe(
      map(st => this.resetValidation(st)),
      map(
        st => {
          st.evaluatorProducts = null;
          const routeData: SurveyEditRouteData = {
            surveyVersion: st.surveyVersion,
            isEdit: st.selectEnabled,
            navigateFrom: 'survey-evaluation'
          };
          this._routingService.navigate('survey-edit', routeData, st.activeRouteId, st.routeOptions);
          return st;
        }
      )
    );
  }


  getEnableModify$(st$: Observable<S>): Observable<S> {
    return st$.pipe(
      map(st => {
        st.selectEnabled = true;
        st.changed = true;
        return st;
      })
    );
  }


  getSelectProduct$(st$: Observable<S>, product: EvaluatorProductI): Observable<S> {
    return combineLatest(st$, of(product)).pipe(
      map(
        ([st, productSelected]) => {

          if (productSelected.selected) {
            st.evaluatorProducts.forEach(p => p.selected = productSelected.code == p.code);
          }

          return st;
        }
      ),
      mergeMap(st => this.createProdCfg$(st))
    );
  }


  getSelectCoverage$(st$: Observable<S>, covOrPkg: EvaluatorObjectI, product: EvaluatorProductI, isPackage: boolean, selected: boolean): Observable<S> {

    const updateSelection$ = ([st, {covOrPkg, product, isPackage, selected}]: [S, {
      covOrPkg: EvaluatorObjectI,
      product: EvaluatorProductI,
      isPackage: boolean,
      selected: boolean
    }]): Observable<[S, EvaluatorProductI]> => {
      const prod = this.findProductInList(st.evaluatorProducts, product);

      if (isPackage) {
        const pkg = prod.packages.find(pkg => covOrPkg.package.id == pkg.package.id);
        pkg.selected = selected;
      } else {
        prod.sections.some(
          section => {
            return section.coverages.some(
              cov => {
                if (cov.code == covOrPkg.code) {
                  cov.selected = selected;
                  return true;
                }
                return false;
              }
            );
          }
        );
      }

      return forkJoin([this.updateProductCfgSelectDeselectButtons$(st, prod), of(prod)]);
    };

    return combineLatest([st$, of({covOrPkg, product, isPackage, selected})]).pipe(
      mergeMap(updateSelection$),
      mergeMap(([st, prod]) => this.updateCfgByProd$(st, prod))
    );

  }


  getToggleSelectionForCoveragesOrPackages$(st$: Observable<S>, product: EvaluatorProductI, selected: boolean): Observable<S> {
    const deselectAll$ = ([st, product, selected]: [S, EvaluatorProductI, boolean]): Observable<S> => {
      const prod = this.findProductInList(st.evaluatorProducts, product);

      const productCfg = st.productsCfg.prodsCfgMap[product.code];

      prod.packages
        .filter(
          pkg => {
            const packageCfg = productCfg.pkgsCfg.pkgsMap[pkg.package.id];
            return packageCfg.visible && packageCfg.sellable;
          }
        )
        .forEach(pkg => pkg.selected = selected);

      prod.sections.forEach(
        sec => sec.coverages
          .filter(cov => {
              const covCfg = productCfg.covsCfg.covsMap[cov.code];
              return covCfg.visible && covCfg.sellable;
            }
          )
          .forEach(cov => cov.selected = selected)
      );

      return this.updateProductCfgSelectDeselectButtons$(st, prod);
    };

    return combineLatest(st$, of(product), of(selected)).pipe(
      mergeMap(deselectAll$),
      mergeMap(st => this.createProdCfg$(st))
    );
  }


  getToggleGroupingByLob$(st$: Observable<S>, enabled: boolean): Observable<S> {
    return combineLatest(st$, of(enabled)).pipe(
      map(
        ([st, enabled]) => {
          st.groupProductsByLob = enabled;
          return st;
        }
      )
    );
  }


  getToggleVisibilityLob$(st$: Observable<S>, code: string): Observable<S> {
    return combineLatest(st$, of(code)).pipe(
      map(
        ([st, code]) => {
          st.productsCfg.lobsCfg.visibilityMap[code] = !st.productsCfg.lobsCfg.visibilityMap[code];
          return st;
        }
      )
    );
  }


  getToggleProductContent(st$: Observable<S>, product: EvaluatorProductI): Observable<S> {
    return combineLatest([st$, of(product)]).pipe(
      map(
        ([st, prod]) => {
          st.productsCfg.prodsCfgMap[product.code].expandContent = !st.productsCfg.prodsCfgMap[product.code].expandContent;
          return st;
        }
      )
    );
  }


  protected navigateToContinue(state: S): Observable<S> {

    return of(state).pipe(
      tap(
        st => this._commOps.notifyProductsSelect(st.activeRouteId, st.evaluatorProducts, st.surveyVersion, st.integrationFilter, st.forwardData)
      )
    );
  }


  protected updateProductCfgSelectDeselectButtons$(st: S, prod: EvaluatorProductI): Observable<S> {
    st.productsCfg.prodsCfgMap[prod.code] = this._tools.productsCfgTool.setSelectDeselectButtonsVisibility(st.productsCfg.prodsCfgMap[prod.code], prod);
    return of(st);
  }


  protected openPrintModal([st, blob]: [S, Blob]): S {
    st.hasPrinted = true;
    this._modalService.open(PdfModalComponent, {file: blob});
    return st;
  }


  protected doPrint$(st: S): Observable<S> {
    const $combine = combineLatest(of(st), this._commOps.print$(st.surveyVersion));
    return $combine.pipe(
      map(([st, blob]) => this.openPrintModal([st, blob]))
    );
  }


  protected validateEphemeral$(state: S): Observable<S> {

    return combineLatest(of(state), this._commOps.validateEphemeral$(state.evaluatorProducts, state.surveyVersion)).pipe(
      mergeMap(
        ([st, [products, validations]]) => {
          st.evaluatorValidations = validations;
          return this.setProducts$(st, products);
        }
      ),
      mergeMap(st => this.createValidationCfg$(st))
    );

  }


  protected setProducts$(st: S, products: EvaluatorProductI[]): Observable<S> {

    return of({state: st, prods: products}).pipe(
      map(({state, prods}) => {
        state.evaluatorProducts = prods || [];
        return st;
      }),
      mergeMap(state => this.createProdCfg$(state))
    );
  }


  protected ensureAllPersisted(st: S): Observable<S> {

    const ensureSurveyPersisted = (st: S): Observable<[S, boolean]> => {

      let op$: Observable<S>;
      let needReCreateEvaluation = false;

      if (!this._commOps.isSurveyPersisted(st.surveyVersion)) {
        op$ = this.persistSurvey$(st);
      } else if (this._commOps.isEphemeral() && st.changed) {
        // la survey è persistita
        // quando si fa l'update del survey, viene creata una nuova SurveyVerVer senza valutazionem quindi bisogna ricrearla
        needReCreateEvaluation = true;
        op$ = this.updateSurvey$(st);
      } else {
        op$ = of(st);
      }

      return combineLatest(op$, of(needReCreateEvaluation));
    };

    return of(st).pipe(
      mergeMap(st => ensureSurveyPersisted(st)),
      mergeMap(([st, needReCreateEvaluation]) => st.evaluationPersisted && !needReCreateEvaluation ? of(st) : this.persistEvaluation$(st)),
    );

  }


  protected persistSurvey$(st: S): Observable<S> {
    const comb$ = combineLatest(of(st), this._commOps.createSurvey$(st.activeRouteId, st.routeOptions, st.surveyVersion, st.integrationFilter));
    return comb$.pipe(
      map(([st, surveyVersion]) => {
        st.surveyVersion = surveyVersion;
        return st;
      })
    );
  }


  protected updateSurvey$(st: S): Observable<S> {
    const updateSurvey$: Observable<SurveyVersionI> = this._commOps.updateSurvey$(st.activeRouteId, st.routeOptions, st.surveyVersion, st.integrationFilter);
    return combineLatest(of(st), updateSurvey$).pipe(
      map(([st, surveyVersion]) => {
        st.surveyVersion = surveyVersion;
        return st;
      })
    );
  }


  protected persistEvaluation$(st: S): Observable<S> {

    return combineLatest(of(st), this._commOps.evaluate$(st.activeRouteId, st.surveyVersion, st.integrationFilter)).pipe(
      map(
        ([st, response]) => {
          st.evaluationPersisted = true;
          return st;
        }
      )
    );

  }


  protected updateEvaluation$(st: S): Observable<S> {
    const updateEval$ = this._commOps.updateEvaluation$(st.surveyVersion, st.evaluatorProducts);

    return combineLatest(of(st), updateEval$).pipe(
      mergeMap(
        ([st, [products, validations]]) => {
          st.evaluatorValidations = validations;
          return this.setProducts$(st, products);
        }
      ),
      mergeMap(st => this.createValidationCfg$(st))
    );
  }


  protected createProdCfg$(st: S): Observable<S> {
    st.productsCfg = this._tools.productsCfgTool.createCfg(st.evaluatorProducts);
    return of(st);
  }


  protected updateCfgByProd$(st: S, product: EvaluatorProductI): Observable<S> {
    st.productsCfg = this._tools.productsCfgTool.updateCfgByProd(product, st.evaluatorProducts, st.productsCfg);
    return of(st);
  }


  protected createValidationCfg$(st: S): Observable<S> {
    st.validation = this._tools.validationTool.createValidationMap(st.evaluatorProducts, st.evaluatorValidations);
    return of(st).pipe(
      tap(
        st => {
          this._tools.validationTool.notifyValidation(st.validation, st.productsCfg);
        }
      )
    );
  }


  protected isEvaluationValid(st: S) {
    return !!(st.validation && st.validation.isValid);
  }


  protected resetValidation(st: S): S {
    st.validation = null;
    return st;
  }


  protected findProductInList(products: EvaluatorProductI[], product: EvaluatorProductI) {
    return products.find(p => product.code == p.code);
  }


}

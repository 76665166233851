import {PcInsuredAsset} from './../../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {Inject, Injectable} from '@angular/core';
import {ReIssueAssetsResourceService} from '../re-issue-assets-resource.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {ReIssueRefactorService} from './re-issue-refactor.service';
import {PcProductAsset} from '../../../models/pc-portfolio-models/assets-models/pc-product-asset';
import {PostAssetRequest} from '../../../models/pc-portfolio-models/assets-models/post-asset-request';
import {AcitvityPacket, PcAsset} from '../../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {ApiPcAddress} from '../../../models/api-models/apiPcAddress';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioAssetsResourceService extends ReIssueAssetsResourceService {

  private PROPOSALS_PATH = 'proposals/';
  private PRODUCT_ASSETS_PATH = '/product/assets';
  private apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF;

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any
  ) {
    super(http, apiConf);
  }

  getProductAssets(contract: string): Observable<Array<PcProductAsset>> {
    return this.http.get<Array<PcProductAsset>>(this.getProductAssetsUri(contract)).pipe(
      share()
    );
  }

  getAssets(proposalId: string): Observable<Array<PcInsuredAsset>> {
    return this.http.get<Array<PcInsuredAsset>>(
      this.getAssetUri(proposalId)
    ).pipe(
      share()
    );
  }


  getAddress(proposalId: string, key: string): Observable<ApiPcAddress> {
    return this.http.get<ApiPcAddress>(
      this.getAddressUri(proposalId, key)
    ).pipe(
      share()
    );
  }

  deleteAddress(proposalId: string, address: any, key: string) {
    return this.http.delete(
      this.getAddressDeleteUri(proposalId, key, address.key)
    ).pipe(
      share()
    );
  }

  createAddress(resourceId: string, address: any, instanceAssetKey: string) {
    return this.http.post<ApiPcAddress>(
      this.getAddressPostUri(resourceId, instanceAssetKey),
      address
    ).pipe(
      share()
    );
  }

  updateAddress(resourceId: string, address: any, instanceAssetKey: string) {
    return this.http.put<ApiPcAddress>(
      this.getAddressPutUri(resourceId, instanceAssetKey, address.id),
      address
    ).pipe(
      share()
    );
  }


  postAsset(contractId: string, assetRequest: PostAssetRequest): Observable<PcAsset> {
    return this.http.post<PcAsset>(this.getAssetUri(contractId), assetRequest).pipe(
      share(),
      map((asset: PcAsset) => {
        return asset;
      }),
    );
  }

  putAsset(contract: string, asset: PcAsset): Observable<PcAsset> {
    return this.http.put<PcAsset>(this.getAssetKeyUri(contract, asset.key), asset).pipe(
      share()
    );
  }

  getAsset(contract: string, assetKey: string): Observable<any> {
    return this.http.get<PcInsuredAsset>(this.getAssetKeyUri(contract, assetKey)).pipe(
      share()
    );
  }

  deleteAsset(contractId: string, assetKey: string) {
    return this.http.delete(this.getAssetKeyUri(contractId, assetKey)).pipe(
      share()
    );
  }

  searchActivity(proposalId: string, addressKey: string, input: string, byDescription: boolean, isSecondary: boolean): Observable<AcitvityPacket> {
    return this.http.get<any>(this.getSearchActivitiesUri(proposalId, addressKey, input, byDescription, isSecondary)).pipe(
      share()
    );
  }

  putActivities(activities: AcitvityPacket, proposalId: string, addressKey: string, isSecondary: boolean): Observable<AcitvityPacket> {
    return this.http.put<AcitvityPacket>(this.getActivitiesPutUri(proposalId, addressKey, isSecondary), activities).pipe(
      share()
    );
  }

  deleteActivity(proposalId: string, addressKey: string, isSecondary: boolean): Observable<AcitvityPacket> {
    return this.http.delete<AcitvityPacket>(this.getActivitiesPutUri(proposalId, addressKey, isSecondary)).pipe(
      share()
    );
  }

  getAutocomplete(proposalId: string, assetKey: string, input: string): Observable<any> {
    return this.http.get<any>(this.getAutocompleteUri(proposalId, assetKey, input)).pipe(
      share()
    );
  }

  getProductAssetsUri(contract: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contract +
      this.PRODUCT_ASSETS_PATH;
  }

  getAssetKeyUri(contractId: string, assetKey: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contractId +
      '/assets/' +
      assetKey;
  }

  getAddressUri(contractId: string, assetKey: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contractId +
      '/assets/' +
      assetKey +
      '/address';
  }

  getAddressDeleteUri(contractId: string, assetKey: string, addressKey: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contractId +
      '/assets/' +
      assetKey +
      '/address/' +
      addressKey;
  }

  getAddressPostUri(contractId: string, assetKey: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contractId +
      '/assets/' +
      assetKey +
      '/address';
  }

  getAddressPutUri(contractId: string, assetKey: string, addressId: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contractId +
      '/assets/' +
      assetKey +
      '/address/' +
      addressId;
  }


  // deleteAssetUri(contractId: string, assetKey: string) {
  //   return this.apiConf.api.portal.path +
  //     this.apiPcPortfolioConf.v2 +
  //     this.apiPcPortfolioConf.path +
  //     this.PROPOSALS_PATH +
  //     contractId +
  //     '/assets/' +
  //     assetKey;
  // }

  // getInsuredAssetUri(contract: string, assetKey: string): string {
  //   return this.getAssetsUri(contract) + '/' + assetKey;
  // }

  getAssetUri(contractId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      contractId +
      '/assets';
  }

  getActivitiesPutUri(proposalId: string, assetKey: string, secondary: boolean) {
    const activity: string = secondary ? '/secondary' : '/main';
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/assets/' +
      assetKey +
      '/activities' +
      activity;
  }

  getSearchActivitiesUri(proposalId: string, assetKey: string, input: string, byDescription: boolean, isSecondary: boolean) {
    const type: string = byDescription ? '?activityDescription=' : '?activityCode=';
    const activity: string = isSecondary ? '/secondary' : '/main';
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/assets/' +
      assetKey +
      '/activities' +
      activity +
      type +
      input;
  }

  getAutocompleteUri(proposalId: string, assetKey: string, input: string) {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      '/assets/' +
      assetKey +
      '/activities' +
      '/descriptions/' +
      input;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'claims-chat-summary',
  templateUrl: './chat-summary.component.html',
  styleUrls: ['./chat-summary.component.scss']
})
export class ChatSummaryComponent implements OnInit {

  @Input() chatSummary: any;
  @Output() eventClicked = new EventEmitter<boolean>();
  chats: any;

  constructor() { }

  ngOnInit() {
  }

  fireClickConversationEvent() {
    this.chatSummary.numberOfUnreadMessages = 0;
    this.eventClicked.emit(this.chatSummary.user);
  }

}

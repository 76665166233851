import { Component, OnInit, Input } from '@angular/core';
import { Policy } from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../../claim-opening.service';
import { DamageCategory } from '../../claim-opening-policy-search/dto/damage-categories';

@Component({
  selector: 'claims-policy-detail-panel-content',
  templateUrl: './policy-detail-panel-content.component.html',
  styleUrls: ['./policy-detail-panel-content.component.scss']
})
export class PolicyDetailPanelContentComponent implements OnInit {

  constructor(private claimOpeningService: ClaimOpeningService) { }

  @Input() policy: Policy;
  @Input() card: any;
  @Input() savedDamageCategory: DamageCategory;
  damageCategoryInternal: DamageCategory;
  damageDescription: string;
  validity: boolean;

  ngOnInit() {
    if (this.damageCategory) {
      this.damageDescription = this.damageCategory.description;
    }
    this.validity = this.claimOpeningService.getSessionAdministrativeCoverture(this.card.id);
  }

  @Input()
  set damageCategory(damageCategory: DamageCategory) {
    if (damageCategory) {
      this.damageDescription = damageCategory.description;
    } else {
      this.damageDescription = null;
    }
    this.damageCategoryInternal = damageCategory;
  }

  get damageCategory(): DamageCategory {
    return this.damageCategoryInternal;
  }

}

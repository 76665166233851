import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import {LpcFormFieldHandlerUtil} from '../../modules/lpc-form-field-handler/utils/lpc-form-field-handler.utils';
import {TaxRelief2022Model} from '../../models/tax-relief-2022-model';
import { TAX_RELIEF, TaxReliefConfig2022StepperConfig } from './config/tax-relief-2022-configuration';

@Component({
  selector: 'lpc-tax-relief-2022',
  templateUrl: './tax-relief-2022.component.html',
  styleUrls: ['./tax-relief-2022.component.scss'],
})
export class TaxRelief2022Component extends AbsOperationComponent {
  public readonly TAX_RELIEF_STEP: TaxReliefConfig2022StepperConfig =
    TAX_RELIEF;

  protected operationDataKey = 'taxrelief2022';
  public taxReliefDefinitions: TaxRelief2022Model[] = [];

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE)
    protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected anagService: AnagService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService
  ) {
    super(
      operations,
      cd,
      translate,
      injector,
      questCacheService,
      modalService,
      notifierService,
      plcQuestService,
      authService,
      anagService
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      [TAX_RELIEF.DATE.formName]: new UntypedFormControl(),
    });
  }

  ngOnInit() {
    this.initializeSession();

    this.$subscriptions.push(
      this.createDraft()
        .pipe()
        .subscribe((result) => {
          this.taxReliefDefinitions = result.definitions
            .taxRelief2022 as TaxRelief2022Model[];

          this.taxReliefDefinitions.forEach((trd) => {
            this.formGroup.addControl(trd.code, new UntypedFormControl({}));
            this.formGroup.get(trd.code).patchValue({
              definitionsFields: trd.taxReliefDetail.map((x) =>
                LpcFormFieldHandlerUtil.mapFormFieldData(x)
              ),
            });
          });
        })
    );
  }

  protected getTransformedOperationData(): any {
    return {};
  }
}


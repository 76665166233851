import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ModalService, OnModalClose} from '@rgi/rx/ui';
import {CoInsuranceDTO, CoInsuranceQuota, Company} from '../../../../../models/domain-models/co-insurance';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';
import {ReIssueCoInsuranceEnum} from '../../../../../enums/re-issue-co-insurance-enum';
import {of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 'lib-re-issue-new-quota-modal',
  templateUrl: './re-issue-new-quota-modal.component.html',
  styleUrls: ['./re-issue-new-quota-modal.component.css']
})
export class ReIssueNewQuotaModalComponent implements OnInit, OnDestroy, OnModalClose {


  @Input() coInsuranceDTO: CoInsuranceDTO;
  @Input() coInsuranceQuota?: CoInsuranceQuota;
  @Input() companies: Company[];
  @Input() intermediaries: Company[];
  @Input() percentageTotal: any;

  newQuotaForm: UntypedFormGroup;

  modalClose: EventEmitter<any> = new EventEmitter();

  TAG_QUOTA_ERRORS = 'QUOTA_errors';

  readonly ReIssueCoINsuranceEnum = ReIssueCoInsuranceEnum;

  closeModalQuote = true;

  constructor(protected modalService: ModalService,
              protected formBuilder: UntypedFormBuilder,
              protected errorService: PushMessageHandlerService,
              private translateService: RgiRxTranslationService) {
  }

  ngOnInit() {
    this.errorService.clear();
    this.newQuotaForm = this.formBuilder.group({
      company: new UntypedFormControl({
        value: this.coInsuranceQuota ? this.coInsuranceQuota.company.identification : '',
        disabled: false
      }, Validators.required),
      intermediary: new UntypedFormControl({
        value: this.coInsuranceQuota ? this.coInsuranceQuota.intermediary.identification : '',
        disabled: false
      }, Validators.required),
      quotaPerc: new UntypedFormControl({
        value: this.coInsuranceQuota ? this.coInsuranceQuota.quotaPerc : '',
        disabled: false
      }, [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
      commissionPerc: new UntypedFormControl({
        value: this.coInsuranceQuota ? this.coInsuranceQuota.commissionsPerc : '',
        disabled: false
      }, [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
      policyNumber: new UntypedFormControl({
        value: this.coInsuranceQuota ? this.coInsuranceQuota.policyNumber : '',
        disabled: false
      }, this.coInsuranceDTO.coinsuranceType.code == '4' ? [Validators.required, Validators.pattern('^[0-9]*$')] : Validators.pattern('^[0-9]*$')),
    }, {
      validator: [
        this.percentageValidator('quotaPerc'),
      ]
    });
  }

  percentageValidator(controlNameDate: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlNameDate];

      let sum = parseInt(control.value) + parseInt(this.percentageTotal);
      if (this.coInsuranceQuota) {
        sum -= parseInt(this.coInsuranceQuota.quotaPerc);
      }
      if (!control.valid) {
        control.setErrors({'RE_ISSUE.PERCENTAGE_ERROR': true});
      } else {
        if (control.value) {
          if (sum > 100 || parseInt(control.value) === 0 || parseInt(control.value) >= 100) {
            control.setErrors({'RE_ISSUE.PERCENTAGE_ERROR': true});
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({invalidPercentage: true});
        }
      }
    };
  }

  submit() {
    if (this.validateForm()) {
      if (this.coInsuranceQuota) {
        this.closeModalQuote = this.editQuota();
      } else {
        this.closeModalQuote = this.newQuota();
      }
      if (this.closeModalQuote) {
        this.modalClose.emit(this.coInsuranceDTO);
      } else {
        const company = this.companies.find(comp => {
          return comp.identification === this.newQuotaForm.controls.company.value;
        });
        const intermediary = this.intermediaries.find(inter => {
          return inter.identification === this.newQuotaForm.controls.intermediary.value;
        });
        this.errorService.clear();
        const errorMsg = new PushMessage();
        errorMsg.tag = this.TAG_QUOTA_ERRORS;
        errorMsg.status = 'danger';
        errorMsg.dismissible = false;
        errorMsg.options = {icon: 'rgi-ui-icon-alert'};
        const msg = of(['RE_ISSUE.QUOTA_ALREADY_EXISTING',
          'RE_ISSUE.COMPANY',
          'RE_ISSUE.INTERMEDIARY',
        ]);
        let msgData = '';
        msg.pipe(mergeMap(r => {
          return this.translateService.translateAll(...r);
        })).subscribe(next => {
          msgData = next [0] + '. ' +
            next [1] + ': ' +
            company.description +
            ', ' +
            next [2] +
            ': ' +
            intermediary.description
          ;
        }).unsubscribe();
        errorMsg.content = msgData;
        this.errorService.notify(errorMsg);
      }
    } else {
      this.newQuotaForm.markAsDirty();
      this.newQuotaForm.markAllAsTouched();

    }
  }

  newQuota() {
    const newQuota = new CoInsuranceQuota();
    newQuota.company = this.companies.find(comp => {
      return comp.identification === this.newQuotaForm.controls.company.value;
    });
    newQuota.intermediary = this.intermediaries.find(inter => {
      return inter.identification === this.newQuotaForm.controls.intermediary.value;
    });
    newQuota.commissionsPerc = this.newQuotaForm.controls.commissionPerc.value;
    newQuota.quotaPerc = this.newQuotaForm.controls.quotaPerc.value;
    newQuota.policyNumber = this.newQuotaForm.controls.policyNumber.value;

    const quota = this.coInsuranceDTO.coinsuranceQuotas.find(quota => {
      return quota.company.identification === newQuota.company.identification &&
        quota.intermediary.identification === newQuota.intermediary.identification;
    });

    if (quota) {
      return false;
    } else {
      this.coInsuranceDTO.coinsuranceQuotas.push(newQuota);
      return true;
    }
  }

  editQuota() {
    let quota = null;
    const company = this.companies.find(comp => {
      return comp.identification === this.newQuotaForm.controls.company.value;
    });
    const intermediary = this.intermediaries.find(inter => {
      return inter.identification === this.newQuotaForm.controls.intermediary.value;
    });
    if (company.identification !== this.coInsuranceQuota.company.identification ||
      intermediary.identification !== this.coInsuranceQuota.intermediary.identification) {
      quota = this.coInsuranceDTO.coinsuranceQuotas.find(quota => {
        return quota.company.identification === company.identification &&
          quota.intermediary.identification === intermediary.identification;
      });
    }
    if (quota) {
      return false;
    } else {
      this.coInsuranceDTO.coinsuranceQuotas.forEach(quota => {
        if (quota.company.identification === this.coInsuranceQuota.company.identification &&
          quota.intermediary.identification === this.coInsuranceQuota.intermediary.identification) {

          quota.company = this.companies.find(comp => comp.identification === this.newQuotaForm.controls.company.value);
          quota.intermediary = this.intermediaries.find(inter => {
            return inter.identification === this.newQuotaForm.controls.intermediary.value; });
          quota.commissionsPerc = this.newQuotaForm.controls.commissionPerc.value;
          quota.quotaPerc = this.newQuotaForm.controls.quotaPerc.value;
          quota.policyNumber = this.newQuotaForm.controls.policyNumber.value;
        }
      });
      return true;
    }
  }

  close() {
    this.modalClose.emit(this.coInsuranceDTO);
  }

  ngOnDestroy(): void {
    this.modalClose.emit();
  }

  validateForm() {
    this.errorService.clear();

    if (this.newQuotaForm.invalid) {
      const errorMsg = new PushMessage();
      errorMsg.tag = this.TAG_QUOTA_ERRORS;
      errorMsg.status = 'danger';
      errorMsg.dismissible = false;
      errorMsg.options = {icon: 'rgi-ui-icon-alert'};
      errorMsg.content = 'RE_ISSUE.FIELDS_MARKED_IN_RED';
      this.errorService.notify(errorMsg);
    }
    return !this.newQuotaForm.invalid;
  }

  resetFlag() {
    this.closeModalQuote = true;
  }
}


import {AnagTranslationsKeys} from './anag-translations-keys';
/* tslint:disable:max-line-length */
// Please keep the translation keys alphabetically ordered
export const ANAG_CA_ES: AnagTranslationsKeys = {
    _ANAG_: {
      _BTN_: {
        _ADD_: 'Afegir',
        _ADD_ADDRESS_: 'Afegiu adreça',
        _BACK_: 'Enrere',
        _CALCULATE_: 'Calculeu',
        _CANCEL_: 'Cancel·leu',
        _CLOSE_: 'Tanqueu',
        _CONFIRM_: 'Confirmeu',
        _DETAIL_: 'Informació',
        _EDIT_: 'Editeu',
        _EMPTY_: 'Buit',
        _FIND_: 'Trobeu',
        _NEW_: 'Nou',
        _NEW_PAYMENT_: 'Pagament nou',
        _NORMALIZE_: 'NORMALITZEU',
        _SAVE_: 'Deseu',
        _DELETE_: 'Suprimiu',
        _DELETE_DEFINITIVELY_: 'Suprimiu definitivament',
        _HISTORY_: 'Historial',
        _LIMITATION_MANAGEMENT_: 'Gestió de la limitació',
        _UNDO_: 'Desfeu',
        _CONTINUE_: 'Continueu'
      },
      _LABEL_: {
        _ACTIONS_: 'Accions',
        _ACTION_TERRORISM_: 'Informació sobre terrorisme',
        _ADDRESS_: 'Adreça',
        _AE_: 'IAE',
        _AGENCY_: 'Agència',
        _ATI_ASSOCIATIONS_: 'Associacions UTE',
        _BIRTH_DATE_: 'Data de naixement',
        _BIRTH_SURNAME_: 'Nom de pila',
        _BIRTH_PLACE_: 'Lloc de naixement',
        _CITIZENSHIP_: 'Nacionalitat',
        _CITY_OF_BIRTH_: 'Ciutat de naixement',
        _CIVIL_STATE_: 'Estat civil',
        _CLIENT_NUMBER_: 'Número de client',
        _COMPANY_NAME_: 'Nom de la companyia',
        _CONTACTS_: 'Contactes',
        _CORPORATE_FORM_: 'Forma corporativa',
        _CORPORATE_SECTOR_: 'Sector corporatiu',
        _CORPORATION_: 'Corporació',
        _COWORKER_: 'Codi fiscal del company de feina',
        _CREDIT_: 'Crèdit',
        _CRITERIA_: 'Criteris',
        _DEBIT_: 'Dèbit',
        _DOCUMENT_: 'Document',
        _DOCUMENT_NO_: 'Número de document',
        _DOCUMENT_TYPE_: 'Tipus de document',
        _EMAIL_: 'Correu electrònic',
        _EMAIL2_: 'Correu electrònic 2',
        _EMAIL3_: 'Correu electrònic 3',
        _EXPIRATION_DATE_: 'Data de venciment',
        _FAX_NO_: 'Número de fax',
        _FEMALE_: 'Dona',
        _FILL_ID_DATA_: 'Empleneu dades d\'identificació de la part',
        _FISCAL_CODE_: 'Codi fiscal',
        _FISCAL_CODE_VAT_NUMBER_: 'Codi fiscal/NIF',
        _GENDER_: 'Gènere',
        _GENDER_IDENTITY_: 'Identitat de gènere',
        _IDENTIFICATION_DATA_: 'Dades identificació',
        _INT_PREFIX_: 'Int. prefix',
        _LANDLINE_NO_: 'Número fix',
        _PHONE_NO_: 'Número telèfon',
        _LEADER_: 'Líder',
        _LINK_TYPE_: 'Tipus d\'enllaç',
        _LINKED_PARTIES_: 'Parts enllaçades',
        _MALE_: 'Home',
        _MAIN_CONTACT_: 'Contracte principal',
        _MANAGEMENT_NODE_: 'Node de gestió',
        _MOBILE_NO_: 'Número mòbil',
        _MOBILE_NO_2_: 'Número mòbil',
        _MUNICIPALITY_: 'Municipi',
        _NAME_: 'Nom',
        _NATION_: 'Nació',
        _NODES_: 'Nodes',
        _NO_ADDRESS_MSG_: 'Sense adreça',
        _NUMBER_: 'Número',
        _OPEN_DATE_: 'Data oberta',
        _PARTICIPANTS_: 'Participants',
        _PARTIES_: 'Subjectes',
        _PARTY_: 'Subjecte',
        _PARTY_DATA_: 'Dades de la part',
        _PARTY_MANAGEMENT_: 'Gestió de la part',
        _PARTY_MERGER_: 'Fusió de les parts',
        _PARTY_NUMBER_: 'Número de part',
        _PARTY_TYPE_: 'Tipus de part',
        _PAYMENT_: 'Pagament',
        _PERSON_TYPE_: 'Tipus de persona',
        _PHYSICAL_: 'Física',
        _POTICALLY_EXP: 'Part políticament exposada',
        _PREFIX_: 'Prefix',
        _PRIVACY_: 'Privacitat',
        _PRIVACY_CONSENSUS_DATE_: 'Data d\'acceptació de la privacitat',
        _CHANGED_ON_: 'Modificat el',
        _PRODUCTION_NODES_: 'Nodes de producció',
        _PROFESSION_: 'Professió',
        _PROFESSION_DETAIL_: 'Detall de professió',
        _REGISTERED_OFFICE_: 'Oficina enregistrada',
        _RELEASE_AUTHORITY_: 'Autoritat d\'emissió',
        _RELEASE_DATE_: 'Data d\'emissió',
        _RELEASE_LOCATION_: 'Ubicació de l’emissió',
        _REQUIRED_FIELD_: 'Requerit',
        _RESIDENCE_: 'Residència',
        _ANTI_TERRORISM_: 'Lluita contra el terrorisme',
        _RESIDENCE_REGISTERED_OFFICE_: 'Residència / Oficina registrada',
        _SAE_: 'CNAE',
        _SALE_POINT_: 'Punt de venda',
        _SAVED_OK_: 'S’ha desat correctament',
        _SELECT_PARTY_: 'Seleccioneu part',
        _SEX_: 'Sexe',
        _STATUS_: 'Estat',
        _STATUS_NOT_PROCESSED_: 'No processat',
        _STATUS_PROCESSED_: 'Processat',
        _SUBJECT_: 'Subjecte',
        _SUBJECT_DATA_: 'Dades de la part',
        _SUBJECT_TYPE_: 'Tipus de subjecte',
        _SUBJECT_UNIQUE_KEY_: 'Clau única de subjecte',
        _SUBJNO_: 'Núm. de part',
        _SUBSYSTEM_: 'Subsistema',
        _SUBSYSTEM_PERSON_ID_: 'ID de persona del subsistema',
        _SURNAME_: 'Cognoms',
        _SURNAMEORCOMPANY_: 'Cognoms / Nom de la companyia',
        _VOID_: ' ',
        _VAT_NUMBER_: 'NIF',
        _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Data editada de lluita contra el terrorisme',
        _COUNTER_TERRORISM_INDICATOR_: 'Estat de lluita contra el terrorisme',
        _DOMICILES_: 'Domicilis',
        _DOMICILE_: 'Domicili',
        _PRINCIPAL_ADDRESS_: 'Adreça principal',
        _AVAILABILITY_: 'Disponibilitat',
        _TYPE_: 'Tipus',
        _AVAILABILITY_START_DATE_: 'Data d\'inici de la disponibilitat',
        _AVAILABILITY_END_DATE_: 'Data de final de la disponibilitat',
        _AVAILABLE_FROM_: 'Formulari disponible',
        _AVAILABLE_UNTIL_: 'Unitat disponible',
        _INTERNATIONAL_DIALLING_CODE_: 'Codi de marcatge internacional',
        _LOCAL_AREA_DIAL_CODE_: 'Codi de marcatge d\'àrea local',
        _YES_: 'SÍ',
        _NO_: 'No',
        _COUNTERTERRORISM_STATUS_ : 'Estat de lluita contra el terrorisme',
        _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_ : 'Data editada de lluita contra el terrorisme',
        _ORIGIN_: 'Origen',
        _LANGUAGE_: 'Llengua',
        _HONOR_TITLE_ : 'Títol honorífic',
        _SONS_: 'Nombre de nens',
        _ROBINSON_LIST_: 'Llista Robinson',
        _CITIZENSHIP_PERMIT_: 'Permís de residència',
        _CITIZENSHIP_EXPIRE_: 'Residència vàlida fins a',
        _REG_COUNTRY_: 'País de registre',
        _EXTERNALS_IDENTIFIERS_: 'Identificadors externs',
        _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Identificadors Assumpte Subsistema',
        _TAX_CLASSIFICATION_: 'Classificacions fiscals',
        _RISK_CLASSIFICATION_ : 'Classificació de risc',
        _REASON_CLASSIFICATION_ : 'Classificació de motiu',
        _BASIS_CLASSIFICATION_ : 'Classificació de bàsic',
        _CLASSIFICATION_DATE_ : 'Data de classificació',
        _COUNTRY_START_DATE_ : 'Data d\'inici del país',
        _COUNTRY_END_DATE_ : 'Data de finalització del país',
        _NO_TAX_CLASSIFICATIONS_ : 'Sense classificació fiscal',
        _COUNTRY_ : 'País',
        _NOMINATIVE_ : 'Nominatiu',
        _HISTORY_CONSULTATION_: 'Consulta d\'història',
        _DO_NOT_REPLY_: 'No està configurat',
        _DRIVING_LICENSE_TYPE_: 'Tipus de permís de conduir',
        _DRIVER_QUALIFICATION_CARD_: 'Targeta de qualificació de conductor',
        _ADD_DRIVING_LICENSE_TYPE_: 'Afegiu tipus de permís de conduir',
        _PRIVACY_INFO_MSG_: 'ca-ES_The field consults the official advertising exclusion files known as the Robinson List (RL) and tells us whether the client is registered in the RL or not.\n' +
          ' \n' +
          'If the Robinson List is set to "Yes", we will not be able to send commercial communications.\n' +
          ' \n' +
          'If the Robinson List is set to "No", commercial communications will only be sent when the Exclude Advertising field is set to No.\n' +
          ' \n' +
          'If the client has not been consulted, the field appears with value "Do not Reply"',
        _LEI_CODE_: 'Identifiant Entité Juridique',
        _RATING_: 'Classificació',
        _RATING_DATE_: 'Data de qualificació',
        _RATING_PERIOD_: 'Període de qualificació',
        _RATING_USER_: 'Usuari',
        _RATING_ORIGIN_: 'Origen',
        _RATING_HISTORY_: 'Historial de qualificacions',
        _COWORKERS_: 'Empleats',
        _COMPANY_: 'Compagnia'
      },
      _MSG_: {
      _ASK_DELETION_CONFIRM_: 'Confirmeu l’eliminació del subjecte?',
      _DELETION_CONFIRMED_: 'El subjecte s’ha eliminat correctament',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Els camps marcats en vermell són obligatoris o contenen valors incorrectes',
      _ERROR_DATE_: 'Subjecte menor de 16 anys. El consentiment al tractament de dades s\'entén que ha estat donat pels progenitors/tutors legals.',
      _ERROR_DATE_SP_: 'Consentiment per al tractament de dades personals de menors de 14 anys',
      _INVALID_DATE_VALUE: 'Data no vàlida',
      _INVALID_FIELD_: 'Camp no vàlid',
      _LENGTHERR_: 'Longitud de camp no permesa',
      _LINK_ERROR_: 'Part ja introduïda',
      _LINK_ERROR_SAME_PARTY_: 'Impossible vincular una part amb si mateixa',
      _NO_ELEMENT_TO_SHOW_: 'No hi ha elements per mostrar',
      _NO_LINKED_PARTIES_: 'Sense vincles',
      _NO_RESULTS_: 'Sense resultats',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'No s’ha trobat l’adreça normalitzada',
      _PATTERNERR_: 'El camp conté caràcters incorrectes',
      _REFINE_SEARCH_: 'L’extracció és parcial. Afineu la cerca',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Empleneu almenys un de: Cognoms / Nom de la companyia, codi fiscal o NIF, número de client, número de part, Número de telèfon, codi postal vàlid o ID personal del subsistema',
      _WRONG_INPUT_DATA_: 'El flux de gestió de parts es crida amb valors d\'entrada no admesos.',
      _INVALID_END_DATE_DOMICILE_: 'Introduïu una data de fi de disponibilitat posterior a la data d\'inici de la disponibilitat',
      _INVALID_START_DATE_DOMICILE_: 'Introduïu una data d\'inici de disponibilitat anterior a la data de fi de la disponibilitat',
      _INHERITANCE_: 'Ja hi ha un registre amb la mateixa clau a la xarxa comercial. Premeu confirmar si voleu fer servir les dades del subjecte ja enregistrat al registre, en cas contrari no serà possible continuar amb aquesta millora de la clau única.',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'Ja hi ha una classificació fiscal vàlida per al país, tipus i període seleccionat',
      _INFO_HOMONYMIES_: 'Atenció. S’han trobat parts que possiblement siguin homonímies.\n' +
        'Seleccione un partido de la lista para acceder a editar sus datos o el botón CONTINÙA para continuar insertando el nuevo partido.',
      _INFO_NATURAL_PERSON_ALLOWED_: 'Només es permet persona natural',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Només es permet entitat legal',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'La data de llançament ha de ser anterior a la data de venciment',
      _IBAN_INVALID_ : 'Format IBAN incorrecte',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Introduïu una data de venciment posterior a la d\'emissió del document',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: 'Confirmeu l’eliminació de la classificació fiscal actual?',
        _ASK_DELETION_DOCUMENT_CONFIRM_: 'Confirmeu l’eliminació del document actual?',
        _DOC_ALREADY_EXIST: 'Número de document ja present',
        _INSERT_DOC_TYPE: 'Introduïu el tipus de document abans de continuar.',
        _INVALID_FORMAT_: 'Format invalide',
        _NORMALIZE_ERROR_MSG: 'És obligatori normalitzar l\'adreça'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Editeu part',
  _ANAG_TITLE_PARTY_DETAIL_: 'Detall de la part',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Gestió de la part',
  _ANAG_TITLE_PARTY_ZOOM_: 'Zoom de la part',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Resultat de la cerca',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: ''
};

import {Injectable} from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable, of} from 'rxjs';
import {AuthorizationCardStateStart} from './authorization-card-state';
import {AuthorizationsCardIntegrationService} from "../services/authorizations-card-integration.service";
import {Authorization} from "../resources/models/authorization";
import {Contract} from "../resources/models/contract";


@Injectable({
    providedIn: 'root'
})
export class AuthorizationCardReducerService {

    constructor(private router: RoutingService,
                private integrationService: AuthorizationsCardIntegrationService) {
    }


    // METHODS FOR STATE MANAGEMENT

    initPageOne(st$: Observable<AuthorizationCardStateStart>, activeRoute: ActiveRoute): Observable<AuthorizationCardStateStart> {
        return of();
    }

    removeSession(routeId: any) {
        this.integrationService.removeSession(routeId);
    }


    openGroupPolicySession(authorizationDetail: Authorization) {
        this.integrationService.openGroupPolicySession(authorizationDetail);
    }

    openNewReFlowSessionIssue(authorizationDetail: Authorization){
        this.integrationService.openNewReFlowSessionIssue(authorizationDetail);
    }

    openPassPortalStandardSession(contractId: string) {
        this.integrationService.openPassPortalStandarSession(contractId);
    }

    openNMFSession(authorizationDetail: Authorization) {
        this.integrationService.openNMFSession(authorizationDetail);
    }

    openNMFMandatoryDataSession(proposalNumber, policyNumber, createCopy, route, operation) {
        this.integrationService.openNMFMandatoryDataSession(proposalNumber, policyNumber, createCopy, route, operation);
    }

    openNMFAuthorizationSession(createCopy: boolean, route: string, authorizationDetail: Authorization) {
        this.integrationService.openNMFAuthorizationSession(createCopy, route, authorizationDetail);
    }

    openNewReFlowSession(authorizationDetail: Authorization) {
        this.integrationService.openNewReFlowSession(authorizationDetail);
    }

    modifyProposalLife(output, contract: Contract, title: string, idSession: string) {
        this.integrationService.modifyProposalLife(output, contract, title, idSession);
    }

    modifyVariationLife(authorizationDetail: Authorization, title: string, id: string) {
        this.integrationService.modifyVariationLife(authorizationDetail, title, id);
    }

    confirmVariationLife(authorizationDetail: Authorization, contract: Contract, id: string) {
        this.integrationService.confirmVariationLife(authorizationDetail, contract, id);
    }

    goToLifeIssueResult(output, title: string, id: string) {
        this.integrationService.goToLifeIssueResult(output, title, id);
    }

    openDefaultResolution(action, contract: Contract, authorizationDetail: Authorization) {
        this.integrationService.openDefaultResolution(action, contract, authorizationDetail);
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicKarmaDatepickerComponent} from './lic-karma-datepicker.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';


@NgModule({
  declarations: [LicKarmaDatepickerComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
  ],
  exports: [LicKarmaDatepickerComponent]
})
export class LicKarmaDatepickerModule { }

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReIssueTitlesResourceService {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private apiConf: any,
    // @Inject(REST_API_CONF_ACCOUNTING) private apiConfAccounting: RestApiConfigAccounting
  ) {
  }

  getTitles$(bodyContent: any): Observable<any> {

    const body = {input: bodyContent};
    return this.httpClient.post(this.getTitleUri(), body);
  }

  getTitleUri(): string {
    return this.apiConf.api.portal.path + '/accounting' + '/findertitles';
  }
}

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { QuestionnaireFlatI } from '@rgi/ng-passpro';
import { RoutingService } from '@rgi/rx/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { EvaluatorProductI } from '../../evaluation/models/evaluator-products';
import { SurveyIntegrationFilter } from '../../integration/integration.module';
import { QuestionnaireI } from '../../models/questionnaire';
import { SURVEY_VERSION_STATUS, SurveyI } from '../../models/survey';
import { SurveyVersionI } from '../../models/survey-version';
import { BTN_MAP_EDIT, SurveyEditState } from '../models/survey-edit-state';
import { SurveyEvaluateRouteData } from '../models/survey-evaluate-route-data';
import { SurveyCommonOperations } from '../tools/survey-common-operations.service';

@Injectable()
export class SurveyEditStateOperations<S extends SurveyEditState> {


    constructor(
        private _commOps: SurveyCommonOperations,
        private _datePipe: DatePipe,
        private _routingService: RoutingService,
    ) {

    }


    beforeUpdate$(st: S): Observable<S> {
        const readonly = st.saveMode == 'readonly';
        const hasResults = !!st.evaluatorProducts;
        if (!st.navigation) {
            st.navigation = {
                stickable: true,
                map: BTN_MAP_EDIT
            }
        }
        st.navigation.map['BACK'].visible = !!st.showBackButton;
        st.navigation.map['SAVE'].visible = st.acl.write && !!st.isEdit && !readonly;
        st.navigation.map['NEW_VERSION'].visible = st.acl.newVersion && !st.isEdit && !st.isNewVersion;
        st.navigation.map['EVALUATE'].visible = st.acl.evaluate && !!st.isEdit && !readonly;
        st.navigation.map['EDIT'].visible = st.acl.write && !st.isEdit && !readonly;
        st.navigation.map['SHOW_RESULTS'].visible = st.acl.evaluate && hasResults && (!st.isEdit || readonly);
        st.initialized = true;
        return of(st);
    }


    getInit$(st$: Observable<S>): Observable<S> {

        const ifCreateOrUpdate$ = (st: S): Observable<S> =>
            !!st.surveyVersion ? this.getInitUpdate$(of(st)) : this.getInitCreate$(of(st))

        return st$.pipe(
            mergeMap( st => this.updateUserPermissionsS(st) ),
            mergeMap(ifCreateOrUpdate$)
        );

    }


    getSelectQuestionnaire$(st$: Observable<S>, questionnaire: QuestionnaireI): Observable<S> {

        const updateQuest = (st: S, questionnaire: QuestionnaireI): Observable<S> =>
            combineLatest(of(st), of(questionnaire), this._commOps.getQuestFlatByQuest$(questionnaire)).pipe(
                mergeMap(stWithQuest => this.getUpdateSurveyQuestionnaire$(of(stWithQuest)))
            );

        const updateVoidQuest = (st: S): Observable<S> =>
            this.getUpdateSurveyQuestionnaire$(of([st, this._commOps.createVoidQuestionnaire(), this._commOps.createVoidQuestionnaireFlat()]));

        return combineLatest(st$, of(questionnaire)).pipe(
            mergeMap(
                ([st, questionnaire]) => questionnaire ? updateQuest(st, questionnaire) : updateVoidQuest(st)
            )
        );

    }


    getMakeNewVersion$(st$: Observable<S>): Observable<S> {
        const updateSt = (st: S): S => {
            st.isNewVersion = true;
            st.isEdit = true;
            st.surveyVersion.status = SURVEY_VERSION_STATUS.DRAFT;
            st.saveMode = 'update';
            return st;
        }

        const mergeAndUpdateQuest$ = (st: S): Observable<S> =>
            this.getMergeAndUpdateQuest$(of(this.getStWithQuest(st)));

        return st$.pipe(
            map(updateSt),
            mergeMap(mergeAndUpdateQuest$)
        );
    }


    getBack$(st$: Observable<S>): Observable<S> {
        return st$.pipe(
            map(
                st => {
                    st.isNewVersion = false; //ensure isNewVersion resetting when back
                    this._commOps.notifySurveyBack(st.activeRouteId);
                    return st;
                }
            ),
        );
    }


    getSaveSurvey$(st$: Observable<S>): Observable<S> {

        const getServiceForSave$ = (st: S): Observable<[S, SurveyVersionI]> => {
            let service$: Observable<SurveyVersionI>;
            if (st.saveMode === 'create') {
                service$ = this._commOps.createSurvey$(st.activeRouteId, st.routeOptions, st.surveyVersion, st.integrationFilter);
            } else if (!st.isNewVersion) {
                service$ = this._commOps.updateSurvey$(st.activeRouteId, st.routeOptions, st.surveyVersion, st.integrationFilter);
            } else {
                service$ = this._commOps.createNewVersion$(st.activeRouteId, st.routeOptions, st.surveyVersion, st.integrationFilter);
            }
            return combineLatest(of(st), service$);
        };

        const handleResult$ = ([st, result]: [S, SurveyVersionI]): Observable<S> => {
            st.surveyVersion = result;
            st.isNewVersion = false; //ensure isNewVersion resetting when save
            st.saveMode = 'update';
            return of(st);
        };

        return st$.pipe(
            mergeMap(getServiceForSave$),
            mergeMap(handleResult$),
        )
    }


    getEvaluation$(st$: Observable<S>): Observable<S> {

        const navigateToEvaluate = ([st, evaluatorProducts]: [S, EvaluatorProductI[]]): S => {
            const routeData: SurveyEvaluateRouteData = {
                surveyVersion: st.surveyVersion,
                evaluatorProducts: evaluatorProducts,
                isEdit: true,
                selectEnabled: true,
                integrationFilter: st.integrationFilter,
                evaluationPersisted: !this._commOps.isEphemeral(),
                forwardData: st.forwardData
            };

            this._routingService.navigate('survey-evaluate', routeData, st.activeRouteId, st.routeOptions);

            return st;
        }

        return st$.pipe(
            mergeMap(st => this._commOps.isEphemeral() ? of(st) : this.getSaveSurvey$(of(st))),
            mergeMap(st => this.makeEvaluation$(of(st))),
            map(navigateToEvaluate)
        );
    }


    getShowEvaluation$(st$: Observable<S>): Observable<S> {

        const navigateToEvaluate = (st: S): S => {
            st.isEdit = false;
            const canEvalEdit = st.saveMode != 'readonly';
            st.saveMode = 'readonly';

            const routeData: SurveyEvaluateRouteData = {
                surveyVersion: st.surveyVersion,
                evaluatorProducts: st.evaluatorProducts,
                isEdit: canEvalEdit,
                selectEnabled: false,
                integrationFilter: st.integrationFilter,
                evaluationPersisted: true,
                forwardData: st.forwardData
            }

            this._routingService.navigate('survey-evaluate', routeData, st.activeRouteId, st.routeOptions);
            return st;
        };

        return st$.pipe(
            map(navigateToEvaluate)
        );
    }


    getEnableEditMode$(st$: Observable<S>): Observable<S> {
        const updateSt = (st: S): S => {
            st.isEdit = true;
            return st;
        }

        const mergeAndUpdateQuest = (st: S): Observable<S> =>
            this.getMergeAndUpdateQuest$(of(this.getStWithQuest(st)));

        return st$.pipe(
            map(updateSt),
            mergeMap(mergeAndUpdateQuest)
        );
    }


    protected updateUserPermissionsS(st: S): Observable<S> {
        st.acl = this._commOps.createAcl();
        return of(st);
    }


    protected getInitUpdate$(st$: Observable<S>): Observable<S> {
        const updateSt = (st: S): S => {
            const surveyValid = this._commOps.isSurveyValid(st.surveyVersion);
            st.saveMode = surveyValid ? 'update' : 'readonly';

            /*
              1. Se è in readonly non può andare in edit
              2. quindi, se non è in readonly, deve andare in edit se non ha prodotti
              3. altrimenti isEdit è quello che gli viene passato dai parametri di routing
            */
            if (st.saveMode == 'readonly') {
                st.isEdit = false;
            } else if (!st.evaluatorProducts) {
                st.isEdit = true
            } else {
                // mi assicuro che sia booleano (se undefined diventa false)
                st.isEdit = !!st.isEdit;
            }
            return st;
        }

        const updateQuestionnaires$ = (st: S): Observable<S> => {
            const combine$ = combineLatest(of(st), this._commOps.getQuestionnaires$(st.integrationFilter));
            return combine$.pipe(
                map(([st, questionnaires]) => {
                    st.questionnaires = questionnaires;
                    const currentQuest = this.getStQuest(st);
                    const isCurrentQuestInQuestionnaires = st.questionnaires.some(q => q.code == currentQuest.code);
                    if (!isCurrentQuestInQuestionnaires) {
                        st.questionnaires = [currentQuest, ...st.questionnaires]
                    }
                    return st;
                })
            );
        };

        const mergeAndUpdateQuestIfEdit$ = (st: S): Observable<S> => {
            //return st.isEdit ? this.getMergeAndUpdateQuest$(of(this.getStWithQuest(st))) : of(st);

            const hasInputFactorChanged = this._commOps.hasInputFactorChanged(st.surveyVersion, st.integrationFilter);
            console.debug("-------->", "hasInputFactorChanged", hasInputFactorChanged);
            if (st.isEdit || hasInputFactorChanged) {
                console.debug("-------->", "merge input factor");
                st.isEdit = true;
                return this.getMergeAndUpdateQuest$(of(this.getStWithQuest(st)))
            } else {
                console.debug("-------->", "NOT merge input factor");
                return of(st);
            }

        }


        const checkHasEvaluation$ = (st: S) => {
            if (this._commOps.isSurveyPersisted(st.surveyVersion)) {
                const evaluation$ = this._commOps.loadEvaluation$(st.surveyVersion);
                return combineLatest(of(st), this._commOps.loadEvaluation$(st.surveyVersion)).pipe(
                    map(([st, products]) => {
                        st.evaluatorProducts = products;
                        return st;
                    })
                );
            } else {
                st.evaluatorProducts = null;
                return of(st);
            }

        }

        return st$.pipe(
            mergeMap(checkHasEvaluation$),
            map(updateSt),
            mergeMap(updateQuestionnaires$),
            mergeMap(mergeAndUpdateQuestIfEdit$)
        );

    }


    protected getInitCreate$(st$: Observable<S>) {

        const updateSt = (st: S): S => {
            st.isEdit = true;
            st.saveMode = 'create';
            st.surveyVersion = this.createSurveyVersionVoid();
            return st;
        }

        const getQuestionnairesAutoSelect$ = (st: S): Observable<[S, [QuestionnaireI, QuestionnaireFlatI, QuestionnaireI[]]]> => {

            const createQuestionnairesAutoselect$ = (integrationFilter: SurveyIntegrationFilter) =>
                this._commOps.getQuestionnaires$(integrationFilter).pipe(
                    mergeMap(this.autoSelectFromQuestionnaires$)
                );

            return combineLatest(of(st), createQuestionnairesAutoselect$(st.integrationFilter));
        }


        const updateSurveyQuest$ = ([st, [questionnaire, questionnaireFlat, questionnaires]]: [S, [QuestionnaireI, QuestionnaireFlatI, QuestionnaireI[]]]): Observable<S> => {
            st.questionnaires = questionnaires;

            return combineLatest(of(st), of(questionnaire), of(questionnaireFlat)).pipe(
                mergeMap(
                    (stWithQuest) => this.getUpdateSurveyQuestionnaire$(of(stWithQuest))
                )
            );
        }

        return st$.pipe(
            map(updateSt),
            mergeMap(getQuestionnairesAutoSelect$),
            mergeMap(updateSurveyQuest$)
        );
    }


    /**
     * Update question flat and update
     * @param st$
     */
    protected getMergeAndUpdateQuest$(stWithQuest$: Observable<[S, QuestionnaireI, QuestionnaireFlatI]>): Observable<S> {
        const mergeQuest = ([st, quest, questFlat]: [S, QuestionnaireI, QuestionnaireFlatI]): Observable<[S, QuestionnaireI, QuestionnaireFlatI]> =>
            combineLatest(
                of(st),
                of(quest),
                this._commOps.mergeQuestFlat$(questFlat)
            );

        const updateQuest = (stWithQuest: [S, QuestionnaireI, QuestionnaireFlatI]) => this.getUpdateSurveyQuestionnaire$(of(stWithQuest));

        return stWithQuest$.pipe(
            mergeMap(mergeQuest),
            mergeMap(updateQuest)
        );
    }


    protected autoSelectFromQuestionnaires$ = (questionnaires: QuestionnaireI[]): Observable<[QuestionnaireI, QuestionnaireFlatI, QuestionnaireI[]]> => {
        let questionnaire: QuestionnaireI;
        let questionnaireFlat$: Observable<QuestionnaireFlatI>;
        if (questionnaires.length === 1) {
            questionnaire = questionnaires[0];
            questionnaireFlat$ = this._commOps.getQuestFlatByQuest$(questionnaire);
        } else {
            questionnaire = this._commOps.createVoidQuestionnaire();
            questionnaireFlat$ = of(this._commOps.createVoidQuestionnaireFlat());
        }
        return combineLatest(of(questionnaire), questionnaireFlat$, of(questionnaires));
    }


    protected getUpdateSurveyQuestionnaire$(stWithQuestionnaire$: Observable<[S, QuestionnaireI, QuestionnaireFlatI]>): Observable<S> {
        return stWithQuestionnaire$.pipe(
            map(
                ([st, questionnaire, questionnaireFlat]) => {
                    st.surveyVersion.survey.questionnaire = questionnaire;
                    st.surveyVersion.lastVersion.questionnaire = questionnaireFlat;

                    const isCreate = st.saveMode == 'create';

                    const updated = this._commOps.updateSurveyInputFactor(st.surveyVersion, st.integrationFilter, questionnaireFlat, isCreate);
                    if (updated) {
                        st.isEdit = true;
                    }

                    return st;
                }
            )
        );
    }


    protected createSurveyVersionVoid(): SurveyVersionI {
        return {
            survey: {
                questionnaire: {
                    questionnaireType: {}
                }
            } as SurveyI,
            dateCreated: this._datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSZ"),
            versionNumber: 1,
            lastVersion: {}
        } as SurveyVersionI;
    }


    protected makeEvaluation$(st$: Observable<S>): Observable<[S, EvaluatorProductI[]]> {
        return st$.pipe(
            mergeMap(
                st => {
                    const evaluate$: Observable<EvaluatorProductI[]> =
                        this._commOps.isEphemeral() ?
                            this._commOps.evaluateEphemeral$(st.activeRouteId, st.surveyVersion, st.integrationFilter) :
                            this._commOps.evaluate$(st.activeRouteId, st.surveyVersion, st.integrationFilter);

                    return combineLatest(of(st), evaluate$);
                }
            )
        );

    }


    protected getStQuest = (st: S): QuestionnaireI => st.surveyVersion.survey.questionnaire;
    protected getStQuestFlat = (st: S): QuestionnaireFlatI => st.surveyVersion.lastVersion.questionnaire;
    protected getStWithQuest = (st: S): [S, QuestionnaireI, QuestionnaireFlatI] => [st, this.getStQuest(st), this.getStQuestFlat(st)];


}

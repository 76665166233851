
export class FormSearch {
    function: string = '';
    message: string = '';
    object: string = '';
    externalKey: string = '';
    damageMatch: string = '';
    taxIdCode: string = '';
    claim: string = '';
    complaint: string = '';
    association: string = '';
    company: string= '';
    ctpCompany: string = '';
    carLicensePlate: string = '';
    ctpCarLicensePlate: string = '';
    creationDateStart: Date;
    creationDateEnd: Date;
    elaborationDateStart: Date;
    elaborationDateEnd: Date;
    expiryDateStart: Date;
    expiryDateEnd: Date;
    claimDateStart: Date;
    claimDateEnd: Date;
    activeTask: boolean;
    errorMessage: boolean;
    similarFlag: boolean;
}

export class DialogueFunction {
    functionCode: string = '';
    functionDescription: string = '';
    functionStatusCode: string = '';
    statusDescription: string = '';
    dialogueMsgData: DialogueMsg[] = [];
  }

  export class DialogueMsg {
    dialogueMsgId: string = '';
    dialogueMsgCode: string = '';
    dialogueMsgDescription: string = '';
  }

  export class WrapperDialogueFunction {
    requestOutcome: Etype;
    response: DialogueFunction[] = [];
  }


  export class DialogueObject {
    functionCode: string = '';
    dialogueSubject: Etype[] = [];
  }

  export class WrapperDialogueCompany {
    requestOutcome: Etype;
    counterpartCompany: Etype[] = [];
    internalCompany: Etype[] = [];
  }
  export class WrapperDialogueObject {
    requestOutcome: Etype;
    response: DialogueObject[] = [];
  }


    export class Etype {
    code: string = '';
    description: string = '';
  }

  export class DialogueCompany {
    dialogueCtpCompany: Etype[] = [];
    dialogueInternalCompany: Etype[] = [];
  }

  export class SearchWrapperObject {
    dialogueFunctionResponse: DialogueFunction[] = [];
    dialogueObjectResponse: DialogueObject[] = [];
    // dialogueObjectResponse: WrapperDialogueObject;
    // dialogueCompResponse: DialogueCompany;
    dialogueCompResponse: WrapperDialogueCompany = new WrapperDialogueCompany();
  }


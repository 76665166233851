import {TableActionRowSchema, TableRowSchema, TableSchema} from '@rgi/rx/ui';
import {RGI_DOCUMENTS_ACTIONS} from './actions';

export const OUTBOUND_DOCS_TABLESCHEMA: TableSchema = {
  rows: [
    {
      name: 'description', title: 'RGI_SHARED_COMPONENTS.DESCRIPTION',
      styleClass: 'rgi-ui-title-2 rgi-comp-doc-description',
    },
    {
      name: 'statusDescription', title: 'RGI_SHARED_COMPONENTS.STATE'
    }
  ],
  header: ['description','statusDescription'],
};

export const DOWNLOAD_DOC_ACTION: TableActionRowSchema = {

  name: 'download', title: 'RGI_SHARED_COMPONENTS.DOWNLOAD',
  actions: [
    {
      name: RGI_DOCUMENTS_ACTIONS.DOWNLOAD,
      ariaLabel: 'download',
      description: 'RGI_SHARED_COMPONENTS.DOWNLOAD_DOC',
      styleClass: 'rgi-ui-icon-save rgi-ui-text-3 rgi-comp-doc-action rgi-ui-info',
      disabled: {
        assert: {
          path: 'isDownloadable',
          value: false,
          condition: '='
        }
      },
    },

  ]
};

export const ARCHIVE_DOC_ACTION: TableActionRowSchema = {

  name: 'archive', title: 'RGI_SHARED_COMPONENTS.ARCHIVE',
  actions: [
    {
      name: RGI_DOCUMENTS_ACTIONS.ARCHIVE,
      ariaLabel: 'archive document',
      description: 'RGI_SHARED_COMPONENTS.ARCHIVE_DOC',
      styleClass: 'rgi-ui-icon-close-folder rgi-ui-text-3 rgi-comp-doc-action rgi-ui-info',
      disabled: {
        assert: {
          path: 'isArchivable',
          value: false,
          condition: '='
        }
      },
    },

  ]
};

export const SIGN_DOC_ACTION: TableRowSchema = {
  name: 'sign', title: 'RGI_SHARED_COMPONENTS.SIGN',
};

export const SEND_DOC_ACTION: TableActionRowSchema = {

  name: 'send', title: 'RGI_SHARED_COMPONENTS.SEND',
  actions: [
    {
      name: RGI_DOCUMENTS_ACTIONS.SEND,
      ariaLabel: 'delivery',
      description: 'RGI_SHARED_COMPONENTS.DELIVERY_DOC',
      styleClass: 'rgi-ui-icon-send rgi-ui-text-3 rgi-comp-doc-action rgi-ui-info',
      disabled: {
        assert: {
          path: 'isSendable',
          value: false,
          condition: '='
        }
      },
    },

  ]
}

import { DocumentEntity } from './document-entity';
import { ExposureEntity } from './exposure-entity';
import { MethodPaymentEntity } from './method-payment-entity';
import { SubjectEntity } from './subject-entity';

export class PaymentEntity {

  public adjustmentDate: Date; // Data
  public quotaAmount: number; // importo quota NEW
  public forfaitAmount: number; // Importo forfait
  public adjustmentBeneficiary: string;
  public adjustmentId: string;
  public adjustmentNumber: string; // numero
  public amount: number;
  public cancellationDate: Date;
  public involvedParty: string;
  public involvedPartyId: string;
  public paymentDate: Date;
  public paymentMethod: string;
  public paymentType: string; // tipo pagamento
  public recordingDate: Date;
  public status: string;
  public codStatus: string;
  public technicalAdjustementType: string; // Tipo liquidazione tecnico (totale parziale)
  public typeVis: string; // 2-L/1-Q
  public number: string;
  public partyRole: string;
  public userLiq: string;
  public movementType: string;


  // NUOVI
  public paymentManagement: string; // gestione pagamento
  public receiptType: string; // tipo quietanza (enumerato)
  public priority: string; // urgenza (enumerato)
  public dueDate: Date; // data esigibilità
  public receiptDate: Date; // data quietanza
  public exposureList: Array<ExposureEntity>; // lista coperture da pagare in quietanza
  public totalAmount: number;
  public totalAmountNet: number; // importo al netto  ... importo da pagare
  public listBeneficiary: Array<SubjectEntity>; // lista beneficiary (BNF)
  public listPayee: Array<SubjectEntity>; // lista percipienti (PRC)
  public procedureCode: string;
  public separateReceipt: boolean;
  public methodPayment: MethodPaymentEntity;
  public driveType: string;
  public generatePayment: boolean;
  public documentList: Array<DocumentEntity>;
  public claimNumber: string;
  // multicurrency
  public currencyCode: string;
  public currencyCodeDate: Date;
  public currencyAmount: number;
  public legalFeeCurrencyAmount: number;
  public idPayment: string;
  public idActRef: string;
  public note: string;
  public offerAcceptance: boolean;

  // Restituzione quietanza
  public receiptStatus: string;
  public receiptReturnDate: string;
  public receiptAnnulloMotivo: string;
  public receiptNote: string;

  // Annullamento Quietanza / Liquidazione (RDC-236) - Inizio
  public offerCancellation: boolean; // Bottone annullamento Quietanza: true (abilitato); false (disabilitato)
  public liquidationTransferDelete: boolean; // Bottone annullamento/storno Liquidazione: true (abilitato); false (disabilitato)
  public operOfferCancellation: boolean; // Bottone annullamento Quietanza: true (abilitato il bottone); false (disabilitato e tooltip)
  public descError: string; // Messaggio per il tooltip ed eventuali errori
  // Annullamento Quietanza / Liquidazione (RDC-236) - Fine

  public liquidationButtonType: string; // RDC-734 - Review bottone ANNULLO/STORNO

}

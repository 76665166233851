import {AnagApiParty} from '@rgi/anag';
import {ActiveRoute} from '@rgi/rx/router';
import {GPIdentificationEntity} from './group-policy-domain-types';
import {GPAsset} from './group-policy-issue-guarantees';
import {
  ActionButton,
  BaseEntity,
  CoinsuranceShareList,
  ErrorResp,
  GPClause,
  GPPolicyDataField
} from './group-policy-issue-policy-data';
import {MPBalanceDetail} from './group-policy-balance';

export interface ContractDetails {
  proposalNumber: string;
  policyNumber: string;
  productDescription: string;
  effectDate: string;
  contractDetail: ContractDetail;
  actionsBotton: Array<ActionButton>;
  partiesDetail: PartiesDetail[];
  assetsDetail: GPAsset[];
  confPm: ConfPm;
  proposalSettings: ProposalSettings;
  variables: VariableFactor[];
  movements: MovementLight[];
  messages: ErrorResp[];
  balanceDetail: MPBalanceDetail;
  showAssetFullDetail: boolean;
  category: BaseEntity;
  coinsurance: CoinsuranceShareList;
  substitutedPolicies: SubstitutedPolicyRef[];
  applicationsToAuthorize: number;
  clauses: GPClause[];
}

export interface PartiesDetail {
  dateOfBirth: string;
  name: string;
  surname: string;
  companyName: string;
  personType: string;
  idPartyLock: number;
  idParty: number;
  role: string;
  description: string;
  maxInstances: number;
  showPercentage: true;
  minPercentage: number;
  percentage: number;
  linkedRoles: [
    null
  ];
}

export interface ContractDetail {
  mainElements: Element[];
  detailElements: Element[];
}

export interface Element {
  label: string;
  value: string;
}

export interface VariableFactor extends BaseEntity {
  value: string;
}

export interface ConfPm {
  applicationData: Element[];
  regulation: Element[];
  techAcc: Element[];
  executionBatch: Element[];
  tarifManagement: Element[];
}

export interface ProposalSettings {
  productCode: string;
  productType: string;
  idPolicyHolder: string;
  idNode: string;
  nodeCode: string;
  idMasterContract: string;
  typeAdhesion: string;
  contractStatus: BaseEntity;
}

export enum TypeAdhesion {
  CAI = 'CAI',
  CAC = 'CAC'
}

export interface AnagSubject {
  serviceInfo: {
    executionId: string;
    logs: string[];
    erroreOccorso: boolean;
    codiceErroreOccorso: number;
    tipoErroreOccorso: number;
    descrizioneErroreOccorso: string;
    dettagliSullErroreOccorso: string[];
    eccezioneOccorsaSerializzata: string[];
  };
  subject: AnagApiParty;
}

export interface MovementLight {
  description: string;
  effectDate: string;
  accTransAmount: number;
  accTransState: string;
  idMov: string;
}

export interface OperationsResponse {
  menu: MenuOperation[];
}
export interface MenuOperation {
  type: string;
  menu: string;
  operations: Operation[];
  expanded: boolean;
  description: string;
}

export interface Operation extends BaseEntity {
  jumpCommand: string;
}

export interface DataActions {
  managementNode: string;
  actions: MenuOperation[];
  id: string;
  node: string;
  proposalNumber: string;
  policyNumber: string;
  idPolicy: string;
  contractStatus: BaseEntity;
  activeRoute: ActiveRoute;
  product?: ProductDataActions;
  lastMovEffDate: string;
  effectiveDate: string;
  expireDate: string;
}

export interface ApplicationInclusionModalData {
  isCached: boolean;
  route: string;
  idComponent: string;
  proposalNumber: string;
  productCategory: GPIdentificationEntity;
  assetField?: GPPolicyDataField;
  fields?: GPPolicyDataField[];
  policyTypeCluster?: boolean;
  policyNumber: string;
  product: BaseEntity;
}

export interface InquiryNavigationInfo {
  idParentSession: string;
  isFromInquiry: boolean;
  proposalNunmber: string;
}

export interface GroupPolicyInquiryRouteData {
  proposalNumber: string;
  policyNumber: string;
}

export interface ProductDataActions {
  code: string;
  type: string;
}

export interface SubstitutedPolicyRef {
  contractType: string;
  policyId: string;
  policyNumber: string;
  proposalNumber: string;
  policyEffectDate: string;
  movementEffectDate: string;
  substitutionReasonDescription: string;
  policyHolderDescription: string;
  productDescription: string;
  policy: boolean;
}

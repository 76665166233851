import {GenericEntity} from './generic-entity';

export class Clause extends GenericEntity {

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public selected: string,
    public enable: string,
    public order: number,
    public compulsory: boolean,
    public text: string,
    public extendedDescription: string,
    public selection: boolean,
    public mandatoryText: boolean,
    public textFormat: number,
    public propertySelectionGroup: string,
    public propertySelectionType: string,
    public paramPP: string,
    public paramCurrent: string,
    public preQuotation: boolean,
    public editableText: boolean,
    public secondary: boolean,
    public visible: boolean,
    public propCollapseText: boolean,
    public clauseType: string,
    public dependentsList: boolean,
    public textType: string,
    public numCols: number,
    public numRows: number,
    public helpFile: string,
    public propCLACL: boolean,
    public propGSELC: string,
    public propCLASEL: string
  ) {
    super(id, code, description);
  }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GzipActivationStrategy, GzipProvider} from '../http-api';


@Injectable()
export class GzipRequestInterceptorService implements HttpInterceptor {

  constructor(private gzipProvider: GzipProvider, private activationStrategy: GzipActivationStrategy) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body && this.activationStrategy.shouldBeActivated(req)) {
      const clone = req.clone(
        {
          body: this.gzipProvider.gzip(req.serializeBody() as any).buffer,
          setHeaders: {
            'Content-Encoding': 'gzip',
            'Content-Type': req.detectContentTypeHeader() != null ? req.detectContentTypeHeader() : req.headers.get('Content-Type')
          }
        },
      );
      return next.handle(clone);
    }
    return next.handle(req);
  }


}

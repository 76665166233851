import {Variable} from '../../models/domain-models/variable';

export class WarrantySummary {

  constructor(
    public unitTitle: string,
    public sectionTitle: string,
    public variablesRows: Variable[][]
  ) {
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {Observable} from 'rxjs';

@Component({
  selector: 'rgi-rx-snackbar,[rgi-rx-snackbar]',
  templateUrl: './rgi-rx-snackbar.component.html',
  host: {
    class: 'rgi-ui-snackbar-container'
  }
})
/**
 * Snackbar component
 */
export class RgiRxSnackbarComponent implements OnInit {

  /**
   * Filter PushMessages by tag
   */
  @Input() tag?: string;


  notifications$: Observable<RgiRxPushMessage[]>;

  trackBy = (index: number, item: RgiRxPushMessage) => {
    return item.id;
  }


  constructor(public pushHandler: PushMessageHandlerService) {
  }

  ngOnInit() {
    this.notifications$ = this.pushHandler.get$(this.tag);
  }

  onClose(toast: RgiRxPushMessage) {
    this.pushHandler.remove(toast);
  }
}


import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Factor} from '../../../group-policy-models/group-policy-issue-policy-data';

/**
 * @author: dmasone
 * @description: Component used for manage string factors
 */
@Component({
    selector: 'rgi-gp-group-policy-factors-input-component',
    templateUrl: './group-policy-factors-input.component.html'
})
export class GroupPolicyPolicyFactorsInputComponent implements OnInit, OnDestroy {

    @Output() updateFactors = new EventEmitter<Factor>();
    @Input() factor: Factor;
    @Input() group: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.group.get(this.factor.code).valueChanges.subscribe(newValue => {
      this.factor.value = newValue;
      this.onChangeFactor();
    });
  }

  ngOnDestroy(): void {
  }

  public onChangeFactor() {
    this.updateFactors.emit(this.factor);
  }

  onCheckUpdate() {
    this.factor.editInApplication = !this.factor.editInApplication;
    this.updateFactors.emit(this.factor);
  }
}

<div class="grid-item card card-height-normal card-main">

  <div class="modal-header">
    <h3 class="modal-title modal_title_max_width">{{ '_CLAIMS_._COMPENSATIONS' | translate }}
      <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
    </h3>
  </div>
<!--  *ngIf="compensations && compensations.length > 0-->
<rgi-rx-datatable
                  [data] = "compensations"
                  [schema]="schema"
                  [sortHeader] = "true"
                  (onAction)="onActionDataTable($event)"
>
</rgi-rx-datatable>

<button style="width: 100%" (click)="closeModal($event)" rgi-rx-button color="primary">{{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>


</div>


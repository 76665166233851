export class Etype {
    code: string = '';
    description: string = '';
  }

export class WrapperNewDialogueObject {
    data: Etype[] = [];
    newDialogueRequest: NewDialogueRequest = new NewDialogueRequest();
}

export class DialogueFunction {
    functionCode: string = '';
    functionDescription: string = '';
    functionStatusCode: string = '';
    statusDescription: string = '';
    dialogueMsgData: DialogueMsg[] = [];
  }

  export class DialogueMsg {
    dialogueMsgId: string = '';
    dialogueMsgCode: string = '';
    dialogueMsgDescription: string = '';
  }


  export class DialogueObject {
    functionCode: string = '';
    dialogueSubject: Etype[] = [];
  }

  export class DialogueCompany {
    dialogueCtpCompany: Etype[] = [];
    dialogueInternalCompany: Etype[] = [];
  }

  export class DialogueRoleList {
    requestOutcome: Etype;
    data: Etype[] = [];
  }


  export class NewDialogueRequest {
    idClnotifcard: string = '';
    idHistoryStatus: string = '';
    idFunStatusSelected: string = '';
    note: string = '';
    claimNumberPass: string = '';
    newDialogue: boolean;
    externalIdentifyDamageParty: string = '';
    idTypeLoss: string = '';
    licensePlateUs: string = '';
    insuranceCodeUs: string = '';
    licensePlateOther: string = '';
    insuranceCodeOther: string = '';
    lossDate: string = '';
    tinVatDamageParty: string = '';
    roleCompanyUs: string = '';
    idDialogueSubject: string = '';
    parameters: Parameter[];
    documents: Document[];
  }

  export class Parameter {
    parameterCode: string = '';
    parameter: string = '';
    value: string = '';
    typeValue: string = '';
  }

  export class Document {
    idDocInfo: string = '';
    idDocument: string = '';
    fileName: string = '';
  }

  export class NewDialogueForm{
    function : string = '';
  }

  export class ActCausOutcome {
    requestOutcome: Etype;
    response: CausaliBySinistroList[] = [];
  }
  export class CausaliBySinistroList{
    causaleId: string = '';
    causaleDescr: string = '';
    actorId: string = '';
    actorExternalIdentifyDamageParty: string = '';
    actorName: string = '';
    licensePlateUS: string = '';
    agreementType: string = '';
    licensePlateOther: string = '';
    insuranceCodeUs: string = '';
    insuranceCodeOther: string = '';
    ruolo: Etype;
    tinVatDamageParty: string = '';
    lossDate: Date;
    
    selected: boolean = false;
  }


  export class ActDoc {
    requestOutcome: Etype;
    response: DocList[] = [];
  }

  export class DocList{
    docId: string;
    docName: string;
    docTitle: string;
    docCategory: string;
    docSubCategory: string;
    actorName: string;
  }

  export class FunParam {
    requestOutcome: Etype;
    response: FunParamList[] = [];
  }

  export class FunParamList {
      idParam: string;
      idFunctionStatus: string;
      paramCode: string;
      paramLabel: string;
      typeValue: string;
      defualtValue: string;
      listCode: any;
      listValue: any;
      required: boolean;
      maxLength: number;
      label: string;
  }

  export class NewWrapperObject{
    responseRoleList: DialogueRoleList = new DialogueRoleList();
    responseActCausOutcome: ActCausOutcome = new ActCausOutcome();
    responseActDoc: ActDoc = new ActDoc();
    responseFunParam: FunParam = new FunParam();
    responseFunctionDoc: FunctionDoc = new FunctionDoc();
  }

  export class ResponseSaveObject{
    requestOutcome: Etype;
    idnotifcard: number;
  }

  export class FunctionDoc{
    requestOutcome: Etype;
    response: FunctionDocData;
  }

  export class FunctionDocData{
    docId: string = '';
    docCode: string = '';
    dimensionMax: number;
    compulsory: boolean;
    numAttach: number;
  }

  export class PossibleAnswers{
    idFunctionStatusRequest: number;
    idFunctionStatus: number;
     codFunStatus: string = '';
     codFunction: string = '';
     desFunction: string = '';
     status: string = '';
     descStatus: string = '';
  }

    export class ResponseObject {
      requestOutcome : Etype;
      possibleAnswers : PossibleAnswers[] = [];
    }

    export class WrapperTableResponses {
      attoreCausale: CausaliBySinistroList[] = [];
      functionDoc: FunctionDocData[] = [];
      docList: DocList[] = [];
    }

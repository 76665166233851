import { Inject, OnInit, Injectable } from '@angular/core';
import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { INIT_ROLE_LEGALE_AVVERSARIO, INIT_SUCCESS_SERVICE, INIT_TIPO_COASS, INIT_TIPO_PAG_FATTURA } from './payment-constants.config';
export interface TypeEnum {
    eTypeCode: string;
    eTypeDescription: string;
}

@Injectable()
export class PaymentConstants {

    constructor(
        @Inject('enumService') private enumService: any,
    ) { }

    eTipoPagamentoList: TypeEnum[] = [];
    eTipoBeneFList: TypeEnum[] = [];
    eTipoQuietanzaList: TypeEnum[] = [];
    eTipoDefinizContList: TypeEnum[] = [];
    eGestionePagamentoList: TypeEnum[] = [];
    eMezzoPagamentoList: TypeEnum[] = [];
    eTipoCoassicurazioneList: TypeEnum[]  = INIT_TIPO_COASS;
    eRoleLegaleAvversarioList: TypeEnum[] = [];
    eTipoBenefList: TypeEnum[] = [];
    eAddresgetSingleypeList: TypeEnum[] = [];
    eShippingChannelList: TypeEnum[] = [];
    eTipoMovLiquidazList: TypeEnum[] = [];
    eCoerenzaDannoBeneList: TypeEnum[] = [];
    ePriorityList: TypeEnum[] = [];

    serverStatusList: TypeEnum[] = [] = INIT_SUCCESS_SERVICE;
    tipoPagFatturaList: TypeEnum[] = [] = INIT_TIPO_PAG_FATTURA;
    eFeeTypeList: TypeEnum[] = [];

    public eFeeType: TypeEnum[] = this.getSingleEFeeType();


    getSingleSuccessServer(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.serverStatusList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.SuccessServer');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.serverStatusList.length) {
                        this.serverStatusList.push(list);
                    }
                });
                filtroList = this.serverStatusList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.serverStatusList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }
    }

    getSingleEtipoPagamento(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eTipoPagamentoList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoPagamentoLiquidazione');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoPagamentoList.length) {
                        this.eTipoPagamentoList.push(list);
                    }
                });
                filtroList = this.eTipoPagamentoList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eTipoPagamentoList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }
    }
    getSingleEtipoQuietanza(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eTipoQuietanzaList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoQuietanza');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoQuietanzaList.length) {
                        this.eTipoQuietanzaList.push(list);
                    }
                });
                filtroList = this.eTipoQuietanzaList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eTipoQuietanzaList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }

    getSingleTipoPagFattura(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.tipoPagFatturaList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoPagFattura');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.tipoPagFatturaList.length) {
                        this.tipoPagFatturaList.push(list);
                    }
                });
                filtroList = this.tipoPagFatturaList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.tipoPagFatturaList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }

    getSingleETipoCoassicurazione(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eTipoCoassicurazioneList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoCoassicurazione');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoCoassicurazioneList.length) {
                        this.eTipoCoassicurazioneList.push(list);
                    }

                });
                filtroList = this.eTipoCoassicurazioneList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eTipoCoassicurazioneList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleERoleLegaleAvversario(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eRoleLegaleAvversarioList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.RuoloAttore');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eRoleLegaleAvversarioList.length) {
                        this.eRoleLegaleAvversarioList.push(list);
                    }
                });
                filtroList = this.eRoleLegaleAvversarioList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eRoleLegaleAvversarioList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }

    getSingleEGestionePagamento(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eGestionePagamentoList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.GestionePagamento');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eGestionePagamentoList.length) {
                        this.eGestionePagamentoList.push(list);
                    }
                });
                filtroList = this.eGestionePagamentoList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eGestionePagamentoList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleEtipoBenef(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eTipoBenefList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoBeneficiario');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoBenefList.length) {
                        this.eTipoBenefList.push(list);
                    }

                });
                filtroList = this.eTipoBenefList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eTipoBenefList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleEMezzoPagamento(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eMezzoPagamentoList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.MezzoPagamento');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eMezzoPagamentoList.length) {
                        this.eMezzoPagamentoList.push(list);
                    }
                });
                filtroList = this.eMezzoPagamentoList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eMezzoPagamentoList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleEAddresgetSingleype(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eAddresgetSingleypeList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('claims.AddresseeType');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eAddresgetSingleypeList.length) {
                        this.eAddresgetSingleypeList.push(list);
                    }
                });
                filtroList = this.eAddresgetSingleypeList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eAddresgetSingleypeList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleETipoDefinizCont(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eTipoDefinizContList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoDefinizTecnicaLiquidazione');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoDefinizContList.length) {
                        this.eTipoDefinizContList.push(list);
                    }
                });
                filtroList = this.eTipoDefinizContList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eTipoDefinizContList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleEShippingChannel(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eShippingChannelList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.ShippingChannelType');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eShippingChannelList.length) {
                        this.eShippingChannelList.push(list);
                    }
                });
                filtroList = this.eShippingChannelList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eShippingChannelList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleETipoMovLiquidaz(eTypeCode: string): TypeEnum {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eTipoMovLiquidazList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoMovimentoLiquid');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoMovLiquidazList.length) {
                        this.eTipoMovLiquidazList.push(list);
                    }

                });
                filtroList = this.eTipoMovLiquidazList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eTipoMovLiquidazList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleECoerenzaDannoBene(eTypeCode: string) {
        let filtroList: TypeEnum = {eTypeCode: '', eTypeDescription: ''};
        if (this.eCoerenzaDannoBeneList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('claims.BlackboxCoherence');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eCoerenzaDannoBeneList.length) {
                        this.eCoerenzaDannoBeneList.push(list);
                    }
                });
                filtroList = this.eCoerenzaDannoBeneList.find(res => res.eTypeCode === eTypeCode);
            }
            return filtroList;
        } else {
            filtroList = this.eCoerenzaDannoBeneList.find(res => res.eTypeCode === eTypeCode);
            return filtroList;
        }

    }
    getSingleEFeeType() {
        const filtroList: TypeEnum[] = [];
        if (this.eFeeTypeList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('claims.invoiceType');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eFeeTypeList.length) {
                        this.eFeeTypeList.push(list);
                    }
                });
            }
            return this.eFeeTypeList;
        } else {
            return this.eFeeTypeList;
        }

    }

    ////////////////////////////////////////////////////////////////////////

    public ETIPOQUIETANZA_ALL(enumService?: any): TypeEnum[] {
        if (this.eTipoQuietanzaList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoQuietanza');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoQuietanzaList.length) {
                        this.eTipoQuietanzaList.push(list);
                    }
                });
            }
            return this.eTipoQuietanzaList;
        } else {
            return this.eTipoQuietanzaList;
        }
    }
    public ETIPOCOASS_ALL(enumService?: any) {
        if (this.eTipoCoassicurazioneList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoCoassicurazione');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoCoassicurazioneList.length) {
                        this.eTipoCoassicurazioneList.push(list);
                    }

                });
            }
            return this.eTipoCoassicurazioneList;
        } else {
            return this.eTipoCoassicurazioneList;
        }
    }
    public ETIPOPAGAMENTO_ALL(enumService?: any): TypeEnum[] {
        if (this.eTipoPagamentoList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoPagamentoLiquidazione');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoPagamentoList.length) {
                        this.eTipoPagamentoList.push(list);
                    }

                });
            }
            return this.eTipoPagamentoList;
        } else {
            return this.eTipoPagamentoList;
        }
    }
    public ETIPODEFINIZCONT_ALL(enumService?: any): TypeEnum[] {
        if (this.eTipoDefinizContList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoDefinizTecnicaLiquidazione');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoDefinizContList.length) {
                        this.eTipoDefinizContList.push(list);
                    }
                });
            }
            return this.eTipoDefinizContList;
        } else {
            return this.eTipoDefinizContList;
        }
    }
    public ETIPOBENEF_ALL(enumService?: any): TypeEnum[] {
        if (this.eTipoBeneFList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoBeneficiario');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoBeneFList.length) {
                        this.eTipoBeneFList.push(list);
                    }
                });
            }
            return this.eTipoBeneFList;
        } else {
            return this.eTipoBeneFList;
        }
    }
    public EGESTIONEPAGAMENTO_ALL(enumService?: any): TypeEnum[] {
        if (this.eGestionePagamentoList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.GestionePagamento');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eGestionePagamentoList.length) {
                        this.eGestionePagamentoList.push(list);
                    }
                });
            }
            return this.eGestionePagamentoList;
        } else {
            return this.eGestionePagamentoList;
        }
    }
    public EADDRESSETYPE_ALL(enumService?: any): TypeEnum[] {
        if (this.eAddresgetSingleypeList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('claims.AddresseeType');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eAddresgetSingleypeList.length) {
                        this.eAddresgetSingleypeList.push(list);
                    }
                });
            }
            return this.eAddresgetSingleypeList;
        } else {
            return this.eAddresgetSingleypeList;
        }
    }

    public ECOERENZADANNOBENE_ALL(enumService?: any): TypeEnum[] {
        if (this.eCoerenzaDannoBeneList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('claims.BlackboxCoherence');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eCoerenzaDannoBeneList.length) {
                        this.eCoerenzaDannoBeneList.push(list);
                    }
                });
            }
            return this.eCoerenzaDannoBeneList;
        } else {
            return this.eCoerenzaDannoBeneList;
        }
    }

    public EMEZZOPAGAMENTO_ALL(enumService?: any): TypeEnum[] {
        if (this.eMezzoPagamentoList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.MezzoPagamento');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eMezzoPagamentoList.length) {
                        this.eMezzoPagamentoList.push(list);
                    }
                });
            }
            return this.eMezzoPagamentoList;
        } else {
            return this.eMezzoPagamentoList;
        }
    }

    public ESHIPPINGCHANNEL_ALL(enumService?: any): TypeEnum[] {
        if (this.eShippingChannelList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.ShippingChannelType');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eShippingChannelList.length) {
                        this.eShippingChannelList.push(list);
                    }
                });
            }
            return this.eShippingChannelList;
        } else {
            return this.eShippingChannelList;
        }
    }

    public ETIPOMOVLIQUIDAZ_ALL(enumService?: any): TypeEnum[] {
        if (this.eTipoMovLiquidazList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.TipoMovimentoLiquid');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.eTipoMovLiquidazList.length) {
                        this.eTipoMovLiquidazList.push(list);
                    }
                });
            }
            return this.eTipoMovLiquidazList;
        } else {
            return this.eTipoMovLiquidazList;
        }
    }

    public EPRIORITY_ALL(enumService?: any): TypeEnum[] {
        if (this.ePriorityList.length === 0) {
            const enumEl: ApiEnumType[] = this.enumService.getEnumList('sinistri.PaymentPriority');
            if (enumEl) {
                enumEl.forEach((respEnum: ApiEnumType) => {
                    const list = {
                        eTypeCode: respEnum.codice,
                        eTypeDescription: respEnum.descrizione
                    };
                    if (enumEl.length > this.ePriorityList.length) {
                        this.ePriorityList.push(list);
                    }
                });
            }
            return this.ePriorityList;
        } else {
            return this.ePriorityList;
        }
    }

}

<div class="anag-issue-modal issue-card-modal-content re-issue-card-styles">
  <div class="anag-issue-issue-card-issue-card-modal-header issue-card-modal-header">
    <div class="card-title">
      <span class="rgi-re-header-icon rgifont "></span>
      <span class="menu-title" translate>RE_ISSUE.INSURABILITY_REQUIREMENTS</span>
    </div>
    <span class="rgifont rgi-close pull-right" (click)="closeModal()"></span>
  </div>
  <div class="anag-issue-modal-body issue-card-modal-body">
    <div class="container-fluid">
      <div *ngIf="clauses">
        <div class="row clauseModal">
          <div class="row mb-4" *ngFor="let clause of clauseArray">
            <div class="col-sm-12 col-xs-12" style="text-align: justify;padding-left: 15%;padding-right: 15%;">
              <div [innerHTML]="clause"></div>
            </div>
          </div>
          <div class="row mb-4" *ngIf="isEmpty()">
            <div class="col-sm-12 col-xs-12" style="padding-left: 15%;padding-right: 15%;">
              <!--              <ppevo-yesno name="ppevo-yesno" [(ngModel)]="checked" (ngModelChange)="onChangeYesNo($event)"
                                         ngDefaultControl>
                            </ppevo-yesno>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="btn-group btn-group-justified">
              <div class="btn-group">
                <button class="btn btn-primary" (click)="closeModal()">Indietro</button>
              </div>
              <div class="btn-group">
                <button class="btn btn-primary" (click)="confirmClause()">Conferma</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

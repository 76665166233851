import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {BehaviorSubject, concat, Observable, of, Subscription} from 'rxjs';
import {delay, filter, map, skip, switchMap, tap} from 'rxjs/operators';
import {RGI_RX_LOADING_INDICATOR_CONFIG, RgiRxLoadingIndicator, RgiRxLoadingIndicatorConfig} from './reaction-api';

@Injectable({
  providedIn: 'root'
})
export class RgiRxLoadingIndicatorService extends RgiRxLoadingIndicator implements OnDestroy {
  private readonly _pending = new BehaviorSubject<number>(0);
  private flushSubscription = Subscription.EMPTY;

  constructor(@Optional() @Inject(RGI_RX_LOADING_INDICATOR_CONFIG) config?: RgiRxLoadingIndicatorConfig) {
    super();
    if (config && config.timeout) {
      this.createFlush(config.timeout);
    }
  }

  increment(count: number) {
    this._pending.next(Math.abs(this._pending.value + count));
  }

  decrement(count: number) {
    this._pending.next(Math.abs(this._pending.value - count));
  }

  hasPending$(): Observable<boolean> {
    return this._pending.asObservable().pipe(
      map(f => f > 0)
    );
  }

  private reset() {
    this._pending.next(0);
  }

  private createFlush(maxTimeout: number) {
    this.flushSubscription.unsubscribe();
    this.flushSubscription = this.hasPending$()
      .pipe(
        skip(1),
        switchMap(hasPending => {
          return concat(
            of(hasPending).pipe(filter(isPending => !isPending)),
            of({}).pipe(delay(maxTimeout), tap(() => this.reset()))
          );
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.flushSubscription.unsubscribe();
  }


}


/**
 * @deprecated use RgiRxLoadingIndicator
 * @see RgiRxLoadingIndicator
 */
export class LoadingIndicatorService extends RgiRxLoadingIndicatorService {
}

import {ActiveRoute, DynamicComponentLoader, RgiRxRoutingHostDirective} from '@rgi/rx/router';
import {
  isPortalRouteDefault,
  PortalCardConfig,
  PortalCards,
  PortalRoute,
  PortalRouteOptions,
  RGI_RX_PORTAL_CARDS
} from '../ajs-integration-api';
import {LoggerFactory} from '@rgi/rx';
import {ComponentFactoryResolver, ComponentRef, Inject, Injectable, Optional, StaticProvider} from '@angular/core';
import {tap} from 'rxjs/operators';
import {PortalCardService} from '../portal-card.service';
import {ICoreResult} from '../typings';

@Injectable()
export class PortalRouterComponentLoader extends DynamicComponentLoader {

  private readonly _logger = LoggerFactory();

  constructor(
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: ICoreResult,
    private portalCardService: PortalCardService,
    @Optional() @Inject('angularGridInstance') private gridInstance?: any
  ) {
    super();
  }

  load<T>(activeRoute: ActiveRoute, host: RgiRxRoutingHostDirective, providers?: StaticProvider[]): ComponentRef<T> {
    const portalCardConfig = this.portalCardService.searchCardByRouteInstance(activeRoute);
    if (portalCardConfig) {
      const origin = this.getOriginRoute(portalCardConfig, activeRoute);
      const routeOptions = activeRoute.routeOptions as PortalRouteOptions;
      if (portalCardConfig.card.navigationInSession && origin) {
        // eslint-disable-next-line eqeqeq
        if (origin.type !== 'home' && this.sessionService.get(activeRoute.id) == -1 && !routeOptions.isSubCard && !routeOptions.isPopupCard) {
          const id = this.sessionService.open(portalCardConfig.card.name, origin.route, origin.label);
          this.coreResult.setResult(id, origin.route, activeRoute.getRouteData());
          this._logger.debug(`Routing override by session open for card ${portalCardConfig.card.name}`, activeRoute);
          return;
        }
      }
    }
    host._onTickChange.pipe(
      tap(() => {
        if (!this.gridInstance || !this.gridInstance.cards) {
          this._logger.debug('onTickChange can not update grid because the angularGridInstance token is not registered. Please register the provider to allow tick change to have effect.');
          return;
        }
        this.gridInstance.cards.refresh();
      })
    ).subscribe();
    return super.load(activeRoute, host, providers);
  }

  private getOriginRoute(portalCardConfig: PortalCardConfig, activeRoute: ActiveRoute): PortalRoute {
    const portalRoute = portalCardConfig.routes.filter(isPortalRouteDefault).find(r => r.destination === activeRoute.route);
    return portalRoute ? portalRoute : undefined;
  }
}

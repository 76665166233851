import {Component, EventEmitter, Inject, Input, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {PolicyNumberAndHolder} from '../payments-data';

export class IbanCheckModalData {
  polHolderAndNumbers: Array<PolicyNumberAndHolder>;

  constructor(polHolderAndNumbers: Array<PolicyNumberAndHolder>) {
    this.polHolderAndNumbers = polHolderAndNumbers;
  }
}

@Component({
  selector: 'mic-rgi-payments-modal',
  templateUrl: './iban-check-modal.component.html'
})
export class IbanCheckModalComponent implements OnInit, OnModalClose {

  @Input() polHolderAndNumbers: Array<PolicyNumberAndHolder>;

  modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Optional() @Inject(DIALOG_DATA) modalData: IbanCheckModalData) {
    this.polHolderAndNumbers = modalData.polHolderAndNumbers;
  }

  ngOnInit() {

  }

  onCancel() {
    this.modalClose.emit(false);
  }

  onConfirm() {
    this.modalClose.emit(true);
  }

  getTitle() {
    return 'WARNING';
  }


}

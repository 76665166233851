import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponentWithAnagModal } from '../../interfaces/component-with-anag-modal';
import { EMPTY_STR } from '../../models/consts/lpc-consts';
import { Definition, Role } from '../../models/postsales-operations-response.model';
import { AnagSubject, PARTY_COMPLETE_KO } from '../../models/subject.model';
import { AnagService } from '../../services/anag.service';
import { Beneficiary } from '../lpc-beneficiary/model/beneficiary';
import { Roles, RoleType } from '../../models/enum/lpc-subjects.enum';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';


@Component({
  selector: 'lpc-third-party',
  templateUrl: './lpc-third-party.component.html',
  styleUrls: ['./lpc-third-party.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcThirdPartyComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcThirdPartyComponent),
      multi: true
    }
  ],
})
export class LpcThirdPartyComponent implements OnInit, OnDestroy, ControlValueAccessor, ComponentWithAnagModal, Validator {
  @Input() thirdPartyDefinition: Definition;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    thirdParty: new UntypedFormControl({
      value: {
        id: null,
        role: null,
        name: null,
        percentage: 0,
        personType: null,
        adult: null,
        linkedSubjectsRoles: []
      },
      type: null,
      code: EMPTY_STR,
      idAssicurato: EMPTY_STR,
      irrevocable: false
    })
  });
  private subscriptions: Subscription[] = [];
  public roleCodeToAdd = Roles.THIRDREFERENT;
  partyCompleteError: {subjIdPlusrole: string, messages: string[]}[] = [];

  constructor(protected anagService: AnagService, protected translate: TranslationWrapperService) {
  }

  ngOnInit() {
    this.formGroup.get('thirdParty').valueChanges.subscribe((value) => {
      this.writeValue(value);
      this.onChange(value);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  writeValue(obj: Beneficiary): void {
    if (!!obj) {
      this.formGroup.get('thirdParty').patchValue(obj, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {

  }

  onChange = (obj: Beneficiary) => {
  }


  deleteBen(event: Beneficiary) {
    this.removeMessagesByRole((event.value as Role).id, (event.value as Role).role);
    this.formGroup.get('thirdParty').setValue({
      value: {
        id: null,
        role: null,
        name: null,
        percentage: 0,
        personType: null,
        adult: null,
        linkedSubjectsRoles: []
      },
      type: null,
      code: EMPTY_STR,
      idAssicurato: EMPTY_STR,
      irrevocable: false
    });
  }

  openAnagSubjectModal() {
    this.anagService.openSubjectModal(this);
  }

  public validate(control: AbstractControl): { [key: string]: boolean } | null {
    const errors: { [key: string]: boolean } = {};
    if (!!this.partyCompleteError.length) {
      Object.assign(errors, {partycomplete: this.partyCompleteError});
    }
    return !!Object.keys(errors).length ? errors : null;
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    const role: Role = AnagService.subjectToRole(subject, this.roleCodeToAdd);
    this.subscriptions.push(
      this.anagService.checkPartyCompleted(
        Number(subject.objectId),
        Number(subject.idLatestPhotos),
        role.role,
        Number(this.anagService.managementNode)
      ).subscribe(res => {
        if (res.result === PARTY_COMPLETE_KO) {
          const roleDescr = this.thirdPartyDefinition.label;
          this.partyCompleteError.push({
            subjIdPlusrole: `${subject.objectId}-${role.role}`,
            messages: res.outcome.map(m => `${this.translate.getImmediate(roleDescr)} ${subject.nominative}: ${m}`)
          });
        } else {
          this.removeMessagesByRole(subject.objectId, role.role);
        }
        (this.formGroup.get('thirdParty').setValue({
          value: role,
          type: this.thirdPartyDefinition.code,
          code: '_ALTRO',
          idAssicurato: EMPTY_STR,
          irrevocable: false
        }));

        this.formGroup.get('thirdParty').updateValueAndValidity();
      })
    );
  }

  private removeMessagesByRole(subjId: string, role: RoleType) {
    this.partyCompleteError = this.partyCompleteError.filter(m => m.subjIdPlusrole !== `${subjId}-${role}`);
  }

  isPresent() {
    return this.formGroup.get('thirdParty').value.value.id;
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {MOTOR_PSALES_TRANSLATIONS} from './i18n/translations';
import {MOTOR_PSALES_VD0082} from './flows/pnc-postsales-VD0082.routes';
import {RgiPncPostsalesModule} from '@rgi/pnc-postsales';
import { MOTOR_PSALES_ESCIS } from './flows/pnc-postsales-ESCIS.routes';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RgiRxI18nModule.forRoot(MOTOR_PSALES_TRANSLATIONS),
    RgiPncPostsalesModule,
    RgiPncPostsalesModule.withOperation([MOTOR_PSALES_VD0082,
      MOTOR_PSALES_ESCIS
    ])
  ]
})
export class RgiMotorPostsalesModule { }

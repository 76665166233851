<div class="panel-body" [formGroup]="formGroup">
    <div class="row summary-container" *ngIf="!!selectedElementsForSummary && !!selectedElementsForSummary.length && !checksOnOverallAlloc">
      <lpc-karma-fund-summary [funds]="selectedElementsForSummary"></lpc-karma-fund-summary>
    </div>
    <div *ngIf="!checksOnOverallAlloc">
      <lpc-karma-fund-message *ngFor="let error of errors" [error]="error" [fromDisinvestments]="true"></lpc-karma-fund-message>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-6" *ngIf="!checksOnOverallAlloc">
        <span>
          <ng-select class="col-sm-6 col-lg-2" id="custom" [clearable]="false" formControlName="count"
            (change)="handlePageSizeChange($event)">
            <ng-option *ngFor="let size of pageSizes" [value]="size">
              {{ size }}
            </ng-option>
          </ng-select>
        </span>
        <span style="line-height: 37px;" translate>elements</span>
      </div>
      <div class="col-lg-6" [ngStyle]="{'text-align': checksOnOverallAlloc ? 'left' : 'right'}">
        <label>
          <span translate>Name: </span><input name="filter" formControlName="search" /></label>
      </div>
    </div>
    <br>
    <div class="row">
      <div formGroupName="funds">
        <div class="col-sm-12" *ngFor="let fund of funds | paginate: {
          id: 'pagination-'+funds.length,
          itemsPerPage: pageSize,
          currentPage: page,
          totalItems: count
        }">
          <lpc-karma-disinvestment-fund 
          *ngIf="!isFreeManagement"
                [checksOnOverallAlloc]="checksOnOverallAlloc"
                [investmentMode]="investmentMode"
                (fundSelected)="addToFunds($event)"
                [formControlName]="fund.id.toString()"
                [totalAmount]="totalAmount"
                [investedProfiles]="getInvestedAmount(fund.id)"
                [definition]="fund"
                [showSliderInput]="showSliderInput"
                [isprofile]="isprofile"
                (toggleChange)="onToggleClick($event)"
                #singleFinancialElement>
          </lpc-karma-disinvestment-fund>
          <lpc-karma-disinvestment-full-fund 
          *ngIf="isFreeManagement"
                [checksOnOverallAlloc]="checksOnOverallAlloc"
                [investmentMode]="investmentMode"
                (fundSelected)="addToFunds($event)"
                [formControlName]="fund.id.toString()"
                [totalAmount]="totalAmount"
                [investedProfiles]="getInvestedAmount(fund.id)"
                [definition]="fund"
                [isFullPreval]="isFullPreval"
                [showSliderInput]="showSliderInput"
                [isprofile]="isprofile"
                (toggleChange)="onToggleClick($event)"
                #singleFinancialElement>
          </lpc-karma-disinvestment-full-fund>
        </div>
      </div>
      <div class="row">
        <div class="right-align">
          <pagination-controls id="pagination-{{funds.length}}" previousLabel="" nextLabel=""
            (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>
        </div>
      </div>
    </div>
  </div>

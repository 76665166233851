import { AddressData } from '@rgi/digital-claims-common-angular';

export class OccurrenceData {
    constructor(
        public uuid: string,
        public reportDate: string,
        // public intermediaryDate: string,
        public authorityCode: string,
        public occurrenceAddress: AddressData,
        public occurrenceDescription: string,
        public noteDirection: string,
        public certifiedOccurrence: boolean,
        public corporateLitigation: boolean,
        public idOpeningMode: string,
        public idCatastrophe: string,
        public auditDate: string,
        public existClMadePostuma: boolean,
        public intermediaryDate: string,
        public departmentInjury?: string,
        public typeOfLossInjury?: string,
        public matterTypeInjury?: string,
        public injuryNode?: string,
        public noteInjury?: string,
        public allegationDescrInjury?: string,
        public insuredActivity?: string,
        public externalNumber?: string
    ) { }
}

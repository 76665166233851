import {State} from '@rgi/rx/state';
import {Company} from '../../models/company';
import {StartCardNode} from '../../models/start-card-node';
import {EnumValues} from '../../models/enum-type';
export class ConsultationStartCardState extends State {
  public companyId: string;
  public nodeId: string;
  public productCode: string;
  public companyList: Array<Company>;
  public productListIndividual=[];
  public productListCollective = [];
  public productTypeListIndividual = [];
  public productTypeListCollective = [];
  public nodeList: Array<StartCardNode>;
  public selectedNode: StartCardNode;
  public enumValues:  Array<EnumValues>;
}

export interface PassFundDefinition {

  id: string;
  description: string;
  minPercentAllocation: number | string;
  maxPercentAllocation: number | string;
  value?: any;
  amount?: number | string;
  percent?: string;
  percentage?: string;
  isinSymbol?: string;
  fundTypeId?: string;
}


export const FUND_TYPE = {
  GS: '4'
};

export const ROUTES_GP_HOME = 'grouppolicy.issue.home';
export const ROUTES_GP_EDIT_ISSUE = 'grouppolicy.issue.edit';
export const ROUTES_GP_PROP_ISSUE = 'grouppolicy.issue.proposalissue';
export const ROUTES_GP_POLDATA = 'grouppolicy.issue.policyData';
export const ROUTES_GP_CONF_PM = 'grouppolicy.issue.configurationPm';
export const ROUTES_GP_GUARANTEES = 'grouppolicy.issue.guarantees';
export const ROUTES_GP_SUMMARY = 'grouppolicy.issue.summary';
export const ROUTES_GP_ISSUE_CONFIRM = 'grouppolicy.issue.confirm';
export const ROUTES_GP_INQUIRY_DETAIL = 'grouppolicy.inquiry.detail';
export const ROUTES_GP_VCONT_DATES = 'grouppolicy.contractchange.dates';
export const ROUTES_GP_VCONT_VARIATION = 'grouppolicy.contractchange.variation';
export const ROUTES_GP_VCONT_QUESTIONNAIRE = 'grouppolicy.contractchange.questionnaire';
export const ROUTES_GP_VCONT_CONFIRMS = 'grouppolicy.contractchange.confirms';
export const ROUTES_GP_INQUIRY_CONTRACT_DETAIL = 'grouppolicy.inquiry.contractDetail';
export const ROUTES_GP_INQUIRY_APPLIST = 'grouppolicy.inquiry.applist';
export const ROUTES_GP_INQUIRY_APPCONF = 'grouppolicy.inquiry.appconf';
export const ROUTES_GP_INQUIRY_MOVEMENTS = 'grouppolicy.inquiry.movements';
export const ROUTES_GP_INQUIRY_PARTIES = 'grouppolicy.inquiry.parties';
export const ROUTES_GP_INQUIRY_UNITS = 'grouppolicy.inquiry.units';
export const ROUTES_GP_INQUIRY_ASSET_CLUSTERS = 'grouppolicy.inquiry.asset.clusters';
export const ROUTES_GP_INQUIRY_PMDATA = 'grouppolicy.inquiry.pmdata';
export const ROUTES_GP_INQUIRY_BALANCE_DETAIL = 'grouppolicy.inquiry.balance.detail';
export const ROUTES_GP_INQUIRY_COINSURANCES = 'grouppolicy.inquiry.coinsurances.detail';
export const ROUTES_GP_INQUIRY_SUBSTITUTED_POLICIES = 'grouppolicy.inquiry.substitutedpolicies';
export const ROUTES_GP_INQUIRY_PRODUCT_CLAUSES = 'grouppolicy.inquiry.productclauses';



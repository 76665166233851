import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { EvaluatorMarketI } from '../../../../evaluation/models';

@Component({
  selector: 'survey-eval-market-tree',
  templateUrl: './survey-eval-market-tree.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SurveyEvalMarketTreeComponent implements OnInit {

  @Input("markets") markets:EvaluatorMarketI
  @Input("expand") expand: boolean;
  @Input("expandChild") expandChild: {rule:boolean,msgs:boolean, vars: boolean, forms: boolean} = {rule:false,msgs:false, vars: false, forms: false};

  constructor() { }

  ngOnInit() {
  }

}

import {Injectable} from '@angular/core';
import { SCALAR_TYPE, walk } from '../reflection';
import { LoggerFactory } from '../logging/logging-providers';
import {RGI_RX_DEFAULT_TEMPLATE_REGEXP} from './compiler-api';


export interface RgiRxInterpolationParamsType {
  [key: string]: SCALAR_TYPE | RgiRxInterpolationParamsType;
}

@Injectable({
  providedIn: 'root'
})
export class RgiRxTemplateInterpolationService {
  private readonly logger = LoggerFactory();
  constructor() { }
  interpolate(key: string, params?: RgiRxInterpolationParamsType, pattern: RegExp = RGI_RX_DEFAULT_TEMPLATE_REGEXP) {
    pattern.lastIndex = 0;
    if (!params) {
      return key;
    }
    return key.replace(pattern, (substring: any, paramKey: any) => {
      const resolved = walk(paramKey, params);
      if (resolved === undefined) {
        this.logger.debug(`Param ${paramKey} is undefined for the interpolation string ${key}, the template will be rendered instead. Parameters are:`, params);
        return substring;
      }
      return resolved;
    });
  }
}

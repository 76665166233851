import {Inject, Injectable} from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {PNC_POSTSALES_CARD} from './pnc-postsales.card.configurations';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PncPostSalesIntegrationService} from '@rgi/pnc-postsales';

@Injectable()
export class PncPostsalesPortalIntegrationService extends PncPostSalesIntegrationService {

  constructor(protected _routingService: RoutingService,
              protected translateService: RgiRxTranslationService,
              @Inject('sessionService') protected portalSession: any,
              @Inject('parallelRouting') protected portalParallelRouting: any,
              @Inject('coreResult') protected portalCoreResult: any,
              @Inject('RGI_POSTSALES_OPEN_OLD_AJS_CONSULTATION') protected openOldAjsConsultation: boolean = false) {
    super(_routingService);
  }


  closeSession(id) {
    this.portalSession.remove(id);
  }

  navigate(destinationRoute: string, routeData: any, routingService: RoutingService, activeRoute: ActiveRoute, isProcessStarting?: boolean, sessionTitle?: string) {
    // _openSession(parent, route, title, navigationDisabled, navigationParams, advancedSearch, idSessionParent, origin)
    if (isProcessStarting) {
      const sessionDetailContract = this.portalSession.list().find(session => session.isActive);
      const cardId = this.portalSession.open(PNC_POSTSALES_CARD.card.name, destinationRoute, sessionTitle || '', true, null, null, sessionDetailContract.idSession);
      routeData.idParentSession = sessionDetailContract.idSession;
      this.portalCoreResult.setResult(cardId, destinationRoute, routeData);
    } else {
      super.navigate(destinationRoute, routeData, routingService, activeRoute);
      // const portalRoute = {route: destinationRoute, destination: destinationRoute};
      // const pncSession = this.portalSession.list().find(session => session.idSession === activeRoute.id);
      // this.portalCoreResult.setResult(pncSession.id, portalRoute, routeData);
      // this.portalParallelRouting.go(pncSession.id, portalRoute);
    }
  }


  /*
  * @param {string} id current session
  * @param {string} idParentSession id of the parent  origin session
  *  */
  protected setActiveSession(id: string, idParentSession: string) {
    this.portalSession.setActive(idParentSession);
    this.closeSession(id);
  }

  endNavigation(activeRouteId: string, idParentSession: string) {
    const activeOriginalActiveSession = this.portalSession.list().find(session => session.idSession === idParentSession);
    this.closeSession(activeRouteId);
    if (!!idParentSession && !!activeOriginalActiveSession && !!activeOriginalActiveSession.idSession) {
      this.closeSession(idParentSession);
    }
  }

  backToPrevPage(id: any, idParentSession: any) {
    const activeOriginalActiveSession = this.portalSession.list().find(session => session.idSession === idParentSession);
    if (!!idParentSession && !!activeOriginalActiveSession && !!activeOriginalActiveSession.idSession) {
      this.setActiveSession(id, idParentSession);
    }
  }

  changeTitle(id: string, msg: string, policyNumber: string) {
    this.translateService.translate(msg, {policyNumber}).subscribe(translated => {
      const pncSession = this.portalSession.list().find(session => session.idSession === id);
      if (pncSession) {
        pncSession.title = translated;
      }
    });
  }

openPolicyDetail(id: string, contractId: string, policyNumber: string) {
    if (this.openOldAjsConsultation) {
      const pncSession = this.portalSession.list().find(session => session.idSession === id);
      const cardId = this.portalSession.open('ptfdamageFinder', 'detail', '', true, null, null, pncSession.idSession);
      const damageContract = {policyNumber, objectId: contractId};
      this.portalCoreResult.setResult(cardId, 'detail-damage', damageContract);
    } else {
      const req: any = {
        contractId: contractId,
        contract: null,
        movementId: null,
        searchResults: [],
        userCode: null,
        policiesStack: [],
        showBack: false,
      };
      const cardId = this.portalSession.open('contractConsultationCard', 'contractDetails', '', true);
      this.portalCoreResult.setResult(cardId, 'contractDetails', req);
    }
  }

  openPolicyTemporaryDetail(id: string, resourceId: string, policyNumber: string) {
    if (this.openOldAjsConsultation) {
      const pncSession = this.portalSession.list().find(session => session.idSession === id);
      const cardId = this.portalSession.open('ptfdamageFinder', 'detailfromcache', '', true, null, null, pncSession.idSession);
      const damageContract = {resourceId,policyNumber};
      this.portalCoreResult.setResult(cardId, 'detailfromcache', damageContract);
    } else {
      const req: any = {
        contractId: resourceId,
        contract: null,
        movementId: null,
        searchResults: [],
        userCode: null,
        policiesStack: [],
        showBack: false,
      };
      const cardId = this.portalSession.open('contractConsultationCard', 'contractDetails', '', true);
      this.portalCoreResult.setResult(cardId, 'contractDetails', req);
    }
  }

}

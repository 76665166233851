import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {KarmaProfileDefinition} from '../../model/karma-profile-definition';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {LicObjectUtils} from '../../../utils/lic-object-utils';

@Component({
  selector: 'lic-funds',
  templateUrl: './lic-funds.component.html',
  styleUrls: ['./lic-funds.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicFundsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicFundsComponent),
      multi: true
    }
  ]
})
export class LicFundsComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    funds: new UntypedFormControl()
  });

  private $subscriptions: Subscription[] = [];

  get TOTAL_AMOUNT(): number {
    return this.totalAmount;
  }

  @Input() active = false;
  @Input() public definition: KarmaProfileDefinition;
  @Input() public totalAmount: number;
  @Input() public profileData: { [key: string]: number } = {};
  @Input() public sliderProperty: string;
  @Input() public isOpenDefault = false;
  @Input() public showSliderInput = true;

  // ASMC-10109 - usate per gestire l'apertura automatica del pannello
  public panelName = 'profile-panel';
  public activePanelList: string[] = [];

  private $selectedFunds: any[] = [];
  get selectedFunds(): any[] {
    return this.$selectedFunds;
  }

  get selectedFundsSummary(): { name: string, selected: boolean, value: string }[] {
    return this.selectedFunds.map(sf => {
      return {
        name: sf.name,
        selected: true,
        value: LicObjectUtils.formatPercentageString(sf.percent * 100)
      };
    });
  }

  constructor() { }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.get('funds').valueChanges.pipe(
        distinctUntilChanged((prev, curr) => LicObjectUtils.equal(prev, curr))
      ).subscribe(result => {
        this.$selectedFunds = this.getSelectedFunds();
        if (this.formGroup.get('funds').valid) {
          this.onChange(this.getRawData());
        } else {
          this.onChange(null);
        }
        this.onTouch();
      })
    );
    // apre il pannello se isOpenDefault è true
    this.activePanelList = this.isOpenDefault ? [this.panelName] : [];
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formGroup.get('funds').errors;
  }

  writeValue(obj: any): void {
    this.formGroup.get('funds').setValue(obj, { emitEvent: false });
    this.$selectedFunds = this.getSelectedFunds();
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  private getSelectedFunds(): any[] {
    return this.definition.funds.filter(fundDefinition => {
      return !!this.formGroup.get('funds').value && !!this.formGroup.get('funds').value[fundDefinition.id];
    }).map(fundDefinition => {
      const percent: number = this.formGroup.get('funds').value[fundDefinition.id];
      return {
        name: fundDefinition.description,
        amount: percent * this.totalAmount,
        percent
      };
    });
  }

  private getRawData(): any {
    const obj: any = {};
    Object.keys(this.formGroup.getRawValue().funds).filter(fundKey => {
      return !!this.formGroup.getRawValue().funds[fundKey];
    }).forEach(fundKey => {
      obj[fundKey] = this.formGroup.getRawValue().funds[fundKey];
    });
    return obj;
  }

}

<template #variablesModalOverlay></template>
<!-- TITLE SECTION ----------------------------------------------------------------------------->

<div *ngIf="filteredVariables?.length > 0 ">
  <div *ngIf="showHeader" class="nmf_cont_titsez nmf_cont_titsez_color">
    <span *ngIf="getVariablesType() === 'Asset'" class="header-icon rgifont rgi-fattori nfm_font_size"></span>
    <span *ngIf="getVariablesType() === 'Party'" class="header-icon rgi-nmf rgi-nmf-users_variables"></span>
    <h1 translate>{{getVariablesType()}} Variables</h1>
    <div *ngIf="variablesCompletenessErrors || partyAreaVariablesCompletenessErrors"
         class="nmf_errorautomaticbranch_icon"><span
      class="rgifont rgi-survey_ko"></span></div>
    <button (click)="goToEditVariables()" *ngIf="editable" class="btn btn-default btn-quotation-detail"
            translate type="button">Edit<span class="glyphicon glyphicon-pencil"></span>
    </button>
  </div>

  <div class="nmf_cont_dati">
    <div *ngFor="let variableRow of variablesRows" class="nmf_cont_riga">
      <div class="nmf_cont_single_data1">
        <div *ngFor="let variable of variableRow; index as i; trackBy: variablesTrackByFn"
             class="nmf_cont_single_data nmf_cont_single_data_color">
                    <span
                      *ngIf="variable?.code !== '1DTNA1'">{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}
                      :
                        <b>{{ variable?.dummyValue ? variable.dummyValue : variable.valueDescription }}</b></span>
          <span
            *ngIf="variable?.code === '1DTNA1'">{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}
            :
                        <b>{{ variable?.value }}</b></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <span translate>{{ title }}</span>
                    <span class="glyphicon glyphicon-remove pull-right cursorPointer" (click)="close()"></span>
                </h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="validationFileForm">
                    <div class="form-group">
                        <label translate>PORTAL_NG_COMMON.DOCUMENT_VALIDATION_MESSAGE</label>
                        <span (click)="file.validate = !file.validate"
                            class="btn btn-checkbox checkbox-unit nmf-unit-box-checkbox" type="checkbox">
                            <span class="glyphicon glyphicon-ok" *ngIf="file.validate">
                            </span>
                        </span>
                    </div>
                    <div class="form-group">
                        <div>
                          <label translate>PORTAL_NG_COMMON.NOTE</label>
                        </div>
                        <div><textarea formControlName="note" [rows]="5" class="pnc-notes-width" [(ngModel)]="file.note"></textarea></div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button class="btn btn-warning" (click)="confirmValidationFile()" translate>PORTAL_NG_COMMON.SAVE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

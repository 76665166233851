<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="true" #first color="info">
  <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
    <div *ngIf="titleAddress !== ''" class="row rgi-claims-title-section-shared">
      <div class="col-xs-6 col-md-6">
        <label ng-bind="label">
          {{ '_CLAIMS_._ADDRESS' | translate }}
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="containerAddress"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion">
  <div *ngIf="titleAddress !== ''" class="row rgi-claims-sub-title-section-shared">
    <div class="col-xs-12 col-md-12">
      <label ng-bind="label">
        {{ '_CLAIMS_._ADDRESS' | translate }}
      </label>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="containerAddress"></ng-container>
</div>

<ng-template #containerAddress>

  <div class="row" *ngIf="!italianContext">
    <rgi-anag-uma-address *ngIf="showUmaAddress"
      data-qa="dsc-address-detail"
      [parentForm]="addressDataForm"
      [inputAddress]="inputDataFormUma"
      [addressType]="'FUN_CLAIMS_OCC_RCA'"
      (outputAddress)="outputFormUmaAddress($event)">
    </rgi-anag-uma-address>
  </div>

  <div class="row" *ngIf="!showNormalizedTable && italianContext">
    <form [formGroup]="addressDataForm" *ngIf="hasLoadedCountry">
      <div class="form-group row">
        <div class="col-md-3 col-xs-3 col-sm-3">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._NATION' | translate }}</label>
            <input type="text" formControlName="country" rgiRxInput [rgiRxAutoComplete]="autoCompleteCountry"
              [minLength]="0" (onChange)="onCompleteCountry($event)" (onValueChange)="onValueChangeCountry($event)"
              [onlyMatching]="onlyMatching">
            <rgi-rx-auto-complete #autoCompleteCountry>
              <ng-template rgiRxAutoCompleteContent>
                <ng-container *ngIf="countries.length > 0">
                  <rgi-rx-option *ngFor="let country of filteredCountries" [value]="country.value">
                    {{country.value}}
                  </rgi-rx-option>
                  <rgi-rx-option class="rgi-ui-title-3"
                    *ngIf="!countries.length">{{'_CLAIMS_._MESSAGE_._NO_RESULT'
                    | translate}}</rgi-rx-option>
                </ng-container>
              </ng-template>
            </rgi-rx-auto-complete>
          </rgi-rx-form-field>
        </div>
        <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="isItalian">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._CITY' | translate }}</label>
            <input type="text" formControlName="city" rgiRxInput [disabled]="cities.length === 0"
              [rgiRxAutoComplete]="autoCompleteCity" [minLength]="0" (onChange)="onCompleteCity($event)"
              (onValueChange)="onValueChangeCity($event)" [onlyMatching]="onlyMatching">
            <rgi-rx-auto-complete #autoCompleteCity>
              <ng-template rgiRxAutoCompleteContent>
                <ng-container *ngIf="cities.length > 0">
                  <rgi-rx-option *ngFor="let city of filteredCities" [value]="city.nomecomune">
                    {{ city.description }}
                  </rgi-rx-option>
                  <rgi-rx-option class="rgi-ui-title-3" *ngIf="!cities.length">{{'_CLAIMS_._MESSAGE_._NO_RESULT' |
                    translate}}</rgi-rx-option>
                </ng-container>
              </ng-template>
            </rgi-rx-auto-complete>
          </rgi-rx-form-field>
        </div>
        <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="isItalian">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._PROVINCE' | translate }}</label>
            <input type="text" (keyup)="outputFormChange()"
              formControlName="extendedProvince" rgiRxInput disabled>
          </rgi-rx-form-field>
        </div>

        <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="isItalian">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._ZIP_CODE' | translate }}</label>
            <input type="text" formControlName="postCode" rgiRxInput [rgiRxAutoComplete]="autoCompleteCity"
              [minLength]="0" (onChange)="onCompleteZipCode($event)" (onValueChange)="onValueChangeZipCode($event)"
              [onlyMatching]="onlyMatching">
            <rgi-rx-auto-complete #autoCompleteCity>
              <ng-template rgiRxAutoCompleteContent>
                <ng-container *ngIf="filteredZipCodes.length > 0">
                  <rgi-rx-option *ngFor="let zip of filteredZipCodes" [value]="zip.id">
                    {{ zip.id }}
                  </rgi-rx-option>
                  <!-- <rgi-rx-option class="rgi-ui-title-3" *ngIf="!filteredZipCodes.length">{{'No results' | translate}}</rgi-rx-option> -->
                </ng-container>
              </ng-template>
            </rgi-rx-auto-complete>
          </rgi-rx-form-field>
        </div>

        <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="!isItalian">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._ADMIN_LEVEL_1' | translate }}</label>
            <input type="text" (keyup)="outputFormChange()"
              formControlName="adminLevel1" rgiRxInput [minLength]="0">
          </rgi-rx-form-field>
        </div>
        <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="!isItalian">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._ADMIN_LEVEL_2' | translate }}</label>
            <input type="text" (keyup)="outputFormChange()"
              formControlName="adminLevel2" rgiRxInput [minLength]="0">
          </rgi-rx-form-field>
        </div>
        <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="!isItalian">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._ADMIN_LEVEL_3' | translate }}</label>
            <input type="text" (keyup)="outputFormChange()"
              formControlName="adminLevel3" rgiRxInput [minLength]="0">
          </rgi-rx-form-field>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4 col-xs-4 col-sm-4">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._TOPONYM' | translate }}</label>
            <input type="text" formControlName="toponym" rgiRxInput [rgiRxAutoComplete]="autoCompleteToponym"
              [minLength]="0" (onChange)="onCompleteToponym($event)" (onValueChange)="onValueChangeToponym($event)"
              [onlyMatching]="onlyMatching" displayWith="description">
            <rgi-rx-auto-complete #autoCompleteToponym>
              <ng-template rgiRxAutoCompleteContent>
                <ng-container *ngIf="filteredToponyms.length > 0">
                  <rgi-rx-option *ngFor="let toponym of filteredToponyms" [value]="toponym">
                    {{ toponym.description }}
                  </rgi-rx-option>
                  <rgi-rx-option class="rgi-ui-title-3" *ngIf="!filteredToponyms.length">{{'No results' |
                    translate}}</rgi-rx-option>
                </ng-container>
              </ng-template>
            </rgi-rx-auto-complete>
          </rgi-rx-form-field>
        </div>
        <div class="col-md-6 col-xs-6 col-sm-6">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._ADDRESS' | translate }}</label>
            <input type="text" (keyup)="outputFormChange()" formControlName="address"
              rgiRxInput [minLength]="0">
          </rgi-rx-form-field>
        </div>
        <div class="col-md-2 col-xs-2 col-sm-2">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._CIVIC_NUMBER' | translate }}</label>
            <input type="number" (keyup)="outputFormChange()"
              formControlName="houseNumber" rgiRxInput [minLength]="0">
          </rgi-rx-form-field>
        </div>
      </div>

      <div class="row">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button rgi-rx-button class="wc-100" color="primary" (click)="normalizeAddress()"
              [disabled]="!enableNormalize">
              {{ '_CLAIMS_._NORMALIZE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row" *ngIf="showNormalizedTable">
    <div class="col-md-12">
      <rgi-rx-datatable [data]="dataSourceAddressNormalizedClaims" [schema]="schema" (onAction)="onActionClick($event)"
        [selectable]="false" [disablePaginator]="false" [pageOptions]="[5,10,20,50]">
      </rgi-rx-datatable>
    </div>
  </div>

</ng-template>

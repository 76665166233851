import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {AuthorizationsStates} from '../resources/constants/authorizations-states';
import {RoutingService} from '@rgi/rx/router';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsRoutingService implements OnDestroy {

  private previousState: AuthorizationsStates = AuthorizationsStates.START;
  protected navigation: EventEmitter<any>;
  private naviPosition: string;

  constructor(private router: RoutingService) {
  }

  forward(routeId:any, state: AuthorizationsStates) {
    if (state === AuthorizationsStates.LIST) {
      this.goToNextPage(routeId,'authorizations/list');
      this.previousState = state;
    } else if (state === AuthorizationsStates.DETAIL) {
      this.goToNextPage(routeId,'authorizations/detail');
      this.previousState = state;
    } else if (state === AuthorizationsStates.START) {
      this.previousState = state;
    }
  }

  register(navigation: EventEmitter<any>) {
    this.navigation = navigation;
  }

  backHome(routeId: any) {
    this.setNaviPosition("authorizations", routeId);
  }

  goToNextPage(routeId: any, nextPage, title?) {
    if (!!this.navigation) {
      this.navigation.emit(nextPage);
    }

    this.setNaviPosition(nextPage, routeId);
  }

  setNaviPosition(naviPosition: string, routeId: any) {
    this.naviPosition = naviPosition;
    this.router.navigate(naviPosition, {}, routeId);
  }

  getNaviPosition() {
    return this.naviPosition;
  }

  ngOnDestroy() {
  }

}

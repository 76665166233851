import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpcWarrantyDetailComponent } from './lpc-warranty-detail.component';

import { RgiCountryLayerModule } from '@rgi/country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';



@NgModule({
    declarations: [LpcWarrantyDetailComponent],
    imports: [
        CommonModule,
        RgiRxI18nModule,
        RgiCountryLayerModule
    ],
    exports: [LpcWarrantyDetailComponent]
})
export class LpcWarrantyDetailModule { }

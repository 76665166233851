import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PaymentFields } from '../../../models/meansofpayment.model';



@Component({
  selector: 'lic-datepicker-payment',
  templateUrl: './datepicker-payment.component.html',
  styleUrls: ['./datepicker-payment.component.css']
})
export class DatepickerPaymentComponent implements OnInit, OnDestroy {
  value: string;
  label: string;
  config: PaymentFields;
  group: UntypedFormGroup;
  prevVal: string;
  regex = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}$');

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.initDate();

    this.regex.test('12/05/2019');
  }

  parseYMD(dataYMD): Date {
    // console.log(dataYMD);
    return new Date(dataYMD);
  }

  initDate() {
    const validatori: ValidatorFn[] = [this.validatoreData];

    if (!this.group.contains(this.config.name)) {
      this.group.addControl(this.config.name, new UntypedFormControl());
    }
    if (this.config.value !== null && this.config.value !== '' && this.config.value !== '-1') {
      this.group.controls[this.config.name].setValue(this.parseYMD(this.config.value));
    } else {
      this.group.controls[this.config.name].setValue(null);
    }
    if (this.config.readOnly) {
      this.group.controls[this.config.name].enable();
    } else {
      this.group.controls[this.config.name].disable();
    }
    if (this.config.mandatory) {
      validatori.push(Validators.required);
    }
    this.group.controls[this.config.name].setValidators(validatori);
  }

  setPayment(payment: PaymentFields, form: UntypedFormGroup) {
    this.config = payment;
    this.label = this.config.label;
    this.group = form;
  }

  onChange() {
    if (this.prevVal !== this.group.controls[this.config.name].value) {
      this.el.nativeElement.dispatchEvent(new Event('changeVal', {bubbles: true}));
      this.prevVal = this.group.controls[this.config.name].value;
    }
  }

  validatoreData: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value instanceof Date && control.value.getFullYear() > 1900) {
      return null;
    }
    return {'Data non valida': true};
  }

  ngOnDestroy() {
    this.group.controls[this.config.name].clearValidators();
    this.group.controls[this.config.name].updateValueAndValidity();
  }

}

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {GpGenericModalData} from '../../../group-policy-models/gp-modals';


export enum GpConfirmationEvent {
  CLOSE = 'close',
  CONFIRM = 'confirm'
}

@Component({
  selector: 'rgi-gp-confirmation-modal',
  templateUrl: './gp-confirmation-modal.component.html'
})
export class GpConfirmationModalComponent implements OnModalClose {

  actions = GpConfirmationEvent;
  modalClose = new EventEmitter<GpConfirmationEvent>();
  text = '';
  title: string;

  constructor(@Optional() @Inject(DIALOG_DATA) data: GpGenericModalData) {
    this.text = data.text;
  }

}

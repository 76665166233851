import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-delete-report-modal',
  templateUrl: './delete-report-modal.component.html'
})
export class DeleteReportModalComponent implements OnInit {

  @Output() modalClose = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.modalClose.emit({close: true, deleted: false});
  }

  deleteReport() {
    this.modalClose.emit({close: true, deleted: true});
  }
}

<ng-container>
  <rgi-rx-expansion-panel attr.data-qa="{{type}}">
    <rgi-rx-expansion-panel-header>
      <input [(ngModel)]="isChecked" [disabled]="!isCheckBoxEditable()"
             class="rgi-ui-form-control" type="checkbox"
             (change)="emitUpdatePropertiesForm(); deleteAll();" id="{{type}}" attr.data-qa="CHECKBOX_{{type}}">
      <label for="{{type}}" rgiRxLabel translate>RE_ISSUE.{{type}}</label>
    </rgi-rx-expansion-panel-header>
    <span class="rgi-ui-text-4 rgi-ui-default" translate>RE_ISSUE.{{type}}_LIST</span>
    <table *ngIf="tableData$?.length > 0" rgi-rx-table [dataSource]="tableData$" (change)="emitUpdatePropertiesForm(); deleteAll();" attr.data-qa="{{type}}"  aria-describedby="policies" >

      <ng-container rgiRxColumnDef="company">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate> RE_ISSUE.COMPANY</th>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="company"> {{row.company?.description}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="policyNumber">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate> RE_ISSUE.POLICY_NUMBER</th>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="policyNumber"> {{row.policyNumber}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="expireDate">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.EXPIRE_DATE</th>
        <td rgi-rx-cell *rgiRxCellDef="let row" data-qa="expireDate">
          {{row.expireDate | date:'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="actions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.ACTION</th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <button (click)="openAddPolicyModal(row)" rgi-rx-button color="info" class="action-button"  data-qa="edit">
            <span class="rgi-re-header-icon rgifont rgi-ui-icon-edit nfm_font_size"></span>
          </button>
          <button (click)="delete(row)" rgi-rx-button color="info" class="action-button" data-qa="delete">
            <span class="rgi-re-header-icon rgifont rgi-ui-icon-delete nfm_font_size"></span>
          </button>
        </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="rgi-ui-grid-2">
      <button rgi-rx-button (click)="openAddPolicyModal(null)" [disabled]="!isChecked"  color="info" class="rgi-ui-btn text-uppercase" data-qa="addPolicy" translate>RE_ISSUE.ADD_POLICY</button>
    </div>
  </rgi-rx-expansion-panel>
</ng-container>
<br>

<ng-template ngFor let-panel [ngForOf]="panels">
  <div class="accordion-container" id="{{panel.id}}-container">
    <div role="tab" id="{{panel.id}}-header" [class]="'accordion-header ' + (panel.type ? 'bg-'+panel.type: type ? 'bg-'+type : '')">
      <button class="accordion-title btn-link" (click)="!!toggle(panel.id)"
              attr.data-qa="{{panel.id}}-button"
              [disabled]="panel.disabled" [class.collapsed]="!panel.isOpen"
              [attr.aria-expanded]="panel.isOpen" [attr.aria-controls]="panel.id">
        {{panel.title}}<ng-template *ngTemplateOutlet="panel.titleTpl?.templateRef;context: panel"></ng-template>
      </button>
    </div>
    <div id="{{panel.id}}" role="tabpanel" [attr.aria-labelledby]="panel.id + '-header'"
         class="collapse" [class.show]="panel.isOpen" *ngIf="!destroyOnHide || panel.isOpen">
      <div class="accordion-body">
        <ng-template [ngTemplateOutlet]="panel.contentTpl?.templateRef"></ng-template>
      </div>
    </div>
  </div>
</ng-template>

import {TaxDetailAPI} from '../../api/tax-detail';
import {TableSchema} from '@rgi/rx/ui';
import {ACCOUNTING_DETAILS_SCHEMA, POLICY_DETAIL_SCHEMA} from './quotation-table-schemas';

export class PolicyDetailModalData {
  tableRows: PolicyDetailModalRow[];
  schema: TableSchema = POLICY_DETAIL_SCHEMA;
}

export class PolicyDetailModalRow {
  ASSETS_RISKS: string;
  NET: string;
  CHARGES: string;
  NET_INTERESTS: string;
  TAXABLE: string;
  TOTAL_TAXES: string;
  GROSS: string;
  taxesDetail: TaxDetailAPI[];
  isRisk?: boolean = false;
  isTotal?: boolean = false;
}

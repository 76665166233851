import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';

export type Events = EventType[] | RgiRxEventConfig[];

export interface EventType {
  readonly eventName: string;
}

export interface RgiRxEventConfig  {
  eventName: string;
  replay: boolean;
  replayBuffer?: number;
  replayWindowTime?: number;
}

export type RgiRxEventStatus = 'bootstrap' | 'lifecycle' | 'clear';

export interface EventInstance<T extends EventType> {
  event: T;
  status?: RgiRxEventStatus;
}

export interface RgiRxEventInstance<T> {
  event: T;
  status?: RgiRxEventStatus;
}


export interface EventProvider {

  emit<T extends EventType>(lifecycleEvent: EventInstance<T>);
  emit<T>(name: string, event: T);

  listen<T extends EventType>(event: EventType | string): Observable<EventInstance<T>>;
  listen<T>(event: EventType | string): Observable<RgiRxEventInstance<T>>;

}

export const EVENTS = new InjectionToken<Events>('EVENTS');

import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {CompatibleProducts, PolicySubstitutionRequest, SubReason} from '../re-postsale-model/substitution';
import {RgiRxHttpClientWrapper} from "@rgi/rx/http";
import {map, share} from "rxjs/operators";
import {Company} from "../../../models/domain-models/co-insurance";
import {Observable} from "rxjs";
import {GenericEntity} from "@rgi/pnc-postsales";
import {SearchCardDto} from "../re-postsale-model/search-card-dto";


@Injectable({
  providedIn: 'root'
})
export class RePostsalesSubstitutionResourceService {
  private portfolioPolicyEndpoint: any;

  constructor(
    private httpClient: RgiRxHttpClientWrapper,
    @Inject('environment') private apiConf: any,
  ) {
    this.portfolioPolicyEndpoint = this.apiConf.api.portal.path + '/v2/portfolio/policy';
  }

  getCompanies(): Observable<Array<Company>> {
    const path = this.apiConf.api.portal.path + '/v2/common/companies/internal'
    return this.httpClient.get<any>(path).pipe(share(), map(val => {
        return val;
      })
    );
  }

  getProducts(body: any): Observable<any> {
    const path = this.apiConf.api.portal.path + '/admin/products'
    return this.httpClient.post<any>(path, body);
  }

  getNode$(node: string) {
    return this.httpClient.get<any>(this.apiConf.api.portal.path + '/admin/agencies?loginNodeId=' + node);
  }

  public getSubstitutionReasons(policyNumber: string) {
    const path = this.portfolioPolicyEndpoint + '/' + policyNumber + '/substitutionreasons';
    return this.httpClient.get<SubReason>(path, {params: new HttpParams()});
  }

  public getCompatibleProducts(policyNumber: string, operationTimestamp: number) {
    const path = this.portfolioPolicyEndpoint + '/' + policyNumber + '/compatibleproducts';
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('effectDate', String(operationTimestamp));
    return this.httpClient.get<CompatibleProducts>(path, {params: httpParams});
  }

  findPolicies(body: SearchCardDto) {
    return this.httpClient.post<any>(this.getFindPoliciesUri(), body).pipe(share(), map(val => {
        return val;
      })
    );
  }

  //
  // public sendSubstituentContract(policySubstitution: PolicySubstitution) {
  //   const path = this.apiConf.api.portal.path + '' + '/v2/portfolio/pc/policies/' + policySubstitution.policyNumber + '/substitute';
  //   const body = { inputSubstitutingProposal: policySubstitution };
  //   return this.httpClient.post<PolicySubstitution>(path, body);
  // }

  public sendSubstituentContract(policySubstitutionRequest: PolicySubstitutionRequest, policyNumber: string) {
    return this.httpClient.post<PolicySubstitutionRequest>(this.getSubstituteUri(policyNumber), policySubstitutionRequest);
  }

  getSubstituteUri(policyNumber:string){
    return this.apiConf.api.portal.path + '' + '/v2/portfolio/pc/policies/' + policyNumber + '/substitute';
  }
  getFindPoliciesUri() {
    return this.apiConf.api.portal.path + '/v2/portfolio/pc/policies/search';
  }

}

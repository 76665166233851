import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose, RgiRxDataTableExpansionModel} from '@rgi/rx/ui';
import {AccountingDetailsModalData} from '../../../resources/model/common/quotation/accounting-details-modal-data';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rgi-pnc-accounting-details-modal',
  templateUrl: './pnc-accounting-details-modal.component.html'
})
export class PncAccountingDetailsModalComponent implements OnInit, OnModalClose {

  modalClose = new EventEmitter<any>();

  public expansionModel = new RgiRxDataTableExpansionModel([], {
    multiple: true,
    canExpand: (_row) => true,
    canContract: (_row) => true
  });

  constructor(@Optional() @Inject(DIALOG_DATA) public data: AccountingDetailsModalData) {
  }

  ngOnInit() {
  }

}

<div [formGroup]="formGroup">

  <div>
      <lic-karma-fund-buttons formControlName="profiles"
                              [definition]="profiles"
                              [totalAmount]="totalAmount"></lic-karma-fund-buttons>
  </div>

  <div formGroupName="funds">
    <div *ngFor="let profile of selectedProfiles">
      <pnc-accordion #accordion activeIds="" [destroyOnHide]="false">
        <pnc-panel class='panel-item'>
          <ng-template let-isOpen="isOpen" class='panel-title' pncPanelTitle>
            <span class="accordion-icon" [class.rgi-plus]="!isOpen" [class.rgi-minus]="isOpen"></span>
            <span data-qa="panel-button" class="rgifont"></span>
            <span class="accordion-text" translate>{{ profile.description }}</span>
            <span class="pull-right header-text">  </span>
          </ng-template>
          <ng-template class="panel-container" pncPanelContent>
            <lic-karma-fund-buttons [formControlName]="profile.id.toString()"
                                    [definition]="profile.funds"
                                    [totalAmount]="getAmountOfProfile(profile.id.toString())"></lic-karma-fund-buttons>
          </ng-template>
        </pnc-panel>
      </pnc-accordion>
    </div>
  </div>
</div>

<h3 class="title-header">{{ '_CLAIMS_._NEW_DAMAGED' | translate }}
    <button type="button" class="btn btn-flat btn-sm" (click)="close()" title="Chiudi"
        style="border-color: white; float: right;">
        <span class="glyphicon glyphicon-remove-sign" style="width:10px; height:10px;"></span>
    </button>
</h3>
<br />
<div *ngFor="let party of partiesWithoutDuplication; let even = even; let odd = odd; let partyIndex = index"
    class="col-xs-12 party-container"
    [ngClass]="{'party-container-separator': thirdPartyEnabled || partiesWithoutDuplication.length > 1}">
    <div *ngIf="thirdParty || partiesWithoutDuplication.length > 1" class="col-md-1 col-sm-6 col-xs-12 text-padd-right">
        <input required class="party-select-radio" attr.data.qa="party-radio-{{party.id}}" name="selectedParty"
            type="radio" id="damaged-asset-radio-{{party.id}}" [value]="party" [(ngModel)]="selectedPartyRadio"
            [disabled]="!enabled || selectCovertures">
    </div>
    <div [ngClass]="thirdParty || partiesWithoutDuplication.length > 1 ? 'col-xs-11' : 'col-xs-12'">
        <claims-party [party]="party" componentId="{{partyIndex}}" [card]="card" partyType="insured" [editable]="false"
            [disabled]="!enabled || selectCovertures" [updateOn]="'save'" [mandatory]="false">
        </claims-party>
    </div>

    <claims-party-checkboxes [policyHolder]="party" [(driver)]="driverParty" [disabled]="!enabled || selectCovertures"
        *ngIf="driverEnabled">
    </claims-party-checkboxes>
</div>

<div *ngIf="thirdPartyEnabled" class="col-xs-12 party-container">
    <div *ngIf="thirdParty && partiesWithoutDuplication.length > 0" class="col-md-1 col-sm-6 col-xs-12 text-padd-right">
        <input required class="party-select-radio" attr.data.qa="party-radio-third-party" name="selectedParty"
            type="radio" id="damaged-asset-radio-thirdParty.id" [value]='thirdParty' [(ngModel)]="selectedPartyRadio"
            [disabled]="!enabled || selectCovertures">
    </div>
    <div [ngClass]="thirdParty || partiesWithoutDuplication.length > 1 ? 'col-xs-11' : 'col-xs-12'">
        <claims-party [(party)]="thirdParty" componentId="third" [card]="card" partyType="third" [editable]="true"
            [disabled]="!enabled || selectCovertures" [updateOn]="'save'" [mandatory]="false"
            (partyChange)="onThirdPartyChange($event)">
        </claims-party>
    </div>
</div>
<br />
<br />

<h3 class="title-header">{{ '_CLAIMS_._DAMAGED_ASSETS' | translate }}</h3>

<div class="damaged-assets-box">

    <span *ngIf="damagedAssetsVehicle?.length" attr.data-qa="damagedAsset-data-label">{{ '_CLAIMS_._VEHICLE' | translate
        }}</span>
    <br *ngIf="damagedAssetsVehicle?.length" />
    <div *ngFor="let damagedAsset of damagedAssetsVehicle; let even = even; let odd = odd; let damagedAssetIndex = index"
        class="container-fluid table-white" attr.data-qa="damagedAsset-data-panel-policyholder-{{damagedAssetIndex}}">

        <claims-damaged-asset-vehicle [damagedAsset]="damagedAsset" [damagedAssetIndex]="damagedAssetIndex"
            [allDamagedAssets]="damagedAssetsWithoutDuplication" [(selectedAsset)]="selectedAsset"
            [disabled]="!enabled || selectCovertures">
        </claims-damaged-asset-vehicle>
    </div>

    <br *ngIf="damagedAssetsVehicle?.length && damagedAssetsInjury?.length" />
    <br *ngIf="damagedAssetsVehicle?.length && damagedAssetsInjury?.length" />
    <span *ngIf="damagedAssetsInjury?.length" attr.data-qa="damagedAsset-data-label">{{ '_CLAIMS_._INJURY' | translate
        }}</span>
    <br *ngIf="damagedAssetsInjury?.length" />
    <div *ngFor="let damagedAsset of damagedAssetsInjury; let even = even; let odd = odd; let damagedAssetIndex = index"
        class="container-fluid table-white" attr.data-qa="damagedAsset-data-panel-policyholder-{{damagedAssetIndex}}">

        <claims-damaged-asset-injury [damagedAsset]="damagedAsset" [damagedAssetIndex]="damagedAssetIndex"
            [allDamagedAssets]="damagedAssetsWithoutDuplication" [(selectedAsset)]="selectedAsset"
            [disabled]="!enabled || selectCovertures">
        </claims-damaged-asset-injury>
    </div>

    <br *ngIf="(damagedAssetsVehicle?.length || damagedAssetsInjury?.length) && damagedAssetsMaterialDamage?.length" />
    <br *ngIf="(damagedAssetsVehicle?.length || damagedAssetsInjury?.length) && damagedAssetsMaterialDamage?.length" />
    <span *ngIf="damagedAssetsMaterialDamage?.length" attr.data-qa="damagedAsset-data-label">{{
        '_CLAIMS_._MATERIAL_DAMAGE' | translate }}</span>
    <br *ngIf="damagedAssetsMaterialDamage?.length">
    <div *ngFor="let damagedAsset of damagedAssetsMaterialDamage; let even = even; let odd = odd; let damagedAssetIndex = index"
        class="container-fluid table-white" attr.data-qa="damagedAsset-data-panel-policyholder-{{damagedAssetIndex}}">

        <claims-damaged-asset-material-damage [damagedAsset]="damagedAsset" [damagedAssetIndex]="damagedAssetIndex"
            [allDamagedAssets]="damagedAssetsWithoutDuplication" [(selectedAsset)]="selectedAsset"
            [disabled]="!enabled || selectCovertures">
        </claims-damaged-asset-material-damage>

    </div>

    <button type="button" class="btn btn-flat btn-sm" (click)="openNewAssetModal($event)"
        *ngIf="isAddAssetEnabled && selectedPartyRadio == thirdParty" [disabled]="!enabled || selectCovertures">
        {{ '_CLAIMS_._ADD_ASSET' | translate }}
        &nbsp;
        <span class="rgifont rgi-plus"></span>
    </button>
</div>
<br>

<claims-party *ngIf="driverEnabled" [(party)]="driverParty" componentId="driver" [card]="card" partyType="driver"
    [editable]="true" [disabled]="!enabled || !selectCovertures" [updateOn]="'save'" [mandatory]="true">
</claims-party>

<div class="row" *ngIf="partyDataForm">
    <form [formGroup]="partyDataForm">
        <div *ngFor="let field of fields" class="col-sm-6 col-xs-12">
            <claims-dynamic-field [field]="field" [formGroup]="partyDataForm"
                [disableAll]="!enabled || selectCovertures">
            </claims-dynamic-field>
        </div>
    </form>
</div>

<br>
<div *ngIf="selectableCoverturesResponse?.bodyworkrequest == 'true'" class="container-fluid table-white"
    attr.data-qa="party-data-panel-bodywork">

    <span attr.data-qa="damagedAsset-data-bodywork-label">{{ '_CLAIMS_._BODYWORK' | translate }}</span>

    <br>
    <div class="container-fluid table-white">
        <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 text-padd-right">
                <button type="button" class="btn btn-flat btn-sm width-100-perc" (click)="addBodywork()"
                    [disabled]="!enabled || selectCovertures">
                    <span *ngIf="!bodyworkData">{{ '_CLAIMS_._SELECT' | translate }}</span>
                    <span *ngIf="bodyworkData">{{ bodyworkData?.denomination }} </span>
                    <button *ngIf="bodyworkData" type="button" class="drag-del-item pull-right icon"
                        (click)="deleteBodywork($event)" [disabled]="!enabled || selectCovertures">
                        <span class="glyphicon glyphicon-trash"></span>
                    </button>
                </button>
            </div>
        </div>
    </div>
    <div class="container-fluid table-white">
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
                attr.data-qa="damagedAsset-data-panel-bodywork-denomination">{{ '_CLAIMS_._DENOMINATION' | translate }}:
                <span>{{ bodyworkData?.denomination || '-'}}</span>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
                attr.data-qa="damagedAsset-data-panel-bodywork-fiscal-code">{{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' |
                translate }}:
                <span>{{ bodyworkData?.vat || '-'}}</span>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
                attr.data-qa="damagedAsset-data-panel-bodywork-address">{{ '_CLAIMS_._ADDRESS' | translate }}:
                <span>{{ bodyworkData?.address || '-'}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
                attr.data-qa="damagedAsset-data-panel-bodywork-telephone">{{ '_CLAIMS_._TELEPHONE' | translate }}:
                <span>{{ bodyworkData?.telephone || '-'}}</span>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
                attr.data-qa="damagedAsset-data-panel-bodywork-email">
                {{ '_CLAIMS_._EMAIL' | translate }}:
                <span>{{ bodyworkData?.email || '-'}}</span>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right" attr.data-qa="damagedAsset-data-panel-iban">
                Iban: <span>{{bodyworkData?.iban|| '-'}}</span></div>
        </div>
    </div>
</div>

<div id="error-container" style="margin-top: 2em" *ngIf="validationMessages && validationMessages.length > 0">
    <pnc-form-message type="error" detail="validationError" [messages]="validationMessages"
        data-qa="party-data-panel-action-error-messages" objectType="complex"></pnc-form-message>
</div>

<div class="modal-footer" *ngIf="enabled && !selectCovertures">
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button class="btn btn-flat btn-sm text-uppercase" (click)="onConfirmDamage()"
                data-qa="party-data-panel-confirm-button">{{ '_CLAIMS_._SELECT' | translate }} {{
                '_CLAIMS_._TYPE_OF_LOSS' | translate }} <span class="glyphicon glyphicon-save-file"></span></button>
            <button class="btn btn-flat btn-sm text-uppercase" *ngIf="abilitaAperturaSenzaCausale"
                (click)="onConfirmDamage()" data-qa="party-data-panel-confirm-button">{{ '_CLAIMS_._BUTTONS_._ADD' | translate }}<span
                    class="glyphicon glyphicon-save-file"></span></button>
        </div>
    </div>
</div>
<claims-damage-category-panel-content panelId="damage-category" *ngIf="selectCovertures" [card]="card"
    nextPanelId="document-management" (stepperEventEmitter)="progress($event)" acceptSignals="true"
    [damageCategoriesData]="damageCategoriesData" [selectCovertures]=selectCovertures [selectedAsset]="selectedAsset"
    (editDone)="onEditDone($event)" [pes]="pes" [isSelectedThirdParty]="isSelectedThirdParty"
    [abilitaAperturaSenzaCausale]="abilitaAperturaSenzaCausale" [policy]="policy"
    (rectifyEventEmitter)="rectify($event)" (coverturesEventEmitter)="add($event)">
</claims-damage-category-panel-content>
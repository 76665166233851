import {Role} from './role';

export class Entitled {

  public role: Role;
  public useAlsoForSubscriber: boolean;
  public residenceChanged: boolean;
  public parties: Array<any>;
  public roles: Array<Role>;

  constructor() {
  }

}

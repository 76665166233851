import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {InsuranceStatus} from '../models/domain-models/insurance-status';
import {ClaimsReportYear} from '../models/domain-models/claims-report-year';
import {RiskCertificateCell} from './risk-certificate-cell';
import {GenericElement} from '../models/domain-models/generic-element';
import {InsuranceBackgroundService} from '../insurance-background/insurance-background.service';
import {CustomModalService} from '../custom-modal.service';
import {RiskCertificateService} from './risk-certificate-service';
import {RiskCertificateData} from './risk-certificate-data';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {Modal} from '../modal';

@Component({
  selector: 'mic-risk-certificate',
  templateUrl: './risk-certificate.component.html',
  styleUrls: ['./risk-certificate.component.scss']
})
export class RiskCertificateComponent implements OnInit, OnDestroy {

  @Input()
  injectedInsuranceStatus = false;

  insuranceStatus: InsuranceStatus;
  principalRespTotalAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();
  principalRespPeopleAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();
  principalRespThingsAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();
  principalRespMixedAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();

  pairRespTotalAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();
  pairRespPeopleAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();
  pairRespThingsAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();
  pairRespMixedAmounts: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();

  years: Array<number> = new Array<number>();

  riskCertificateTable: Array<Array<RiskCertificateCell>> = new Array<Array<RiskCertificateCell>>();


  riskCertificateModal: Type<Modal>;

  bersani = false;


  @ViewChild('editRiskCertificateModal', {read: ViewContainerRef, static: true})
  editRiskCertificateViewContainerRef: ViewContainerRef;

  componentRef: any;

  showPejus = false;
  showDeductibles = false;

  insuranceStatusData: GenericElement[] = new Array<GenericElement>();
  insuranceStatusDataRows: GenericElement[][];

  @Input() showATRHeader = false;
  @Input() showATRTable = false;
  @Input() showEdit = false;
  @Input() riskCertificateCompletenessErrors: boolean;

  @Output() riskCertificateError: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected translate: TranslateService,
    protected insuranceBackgroundService: InsuranceBackgroundService,
    protected modalService: CustomModalService,
    protected riskCertificateService: RiskCertificateService,
    @Inject('riskCertificateModalComponent') riskCertificateModalComponent: Type<Modal>
  ) {
    this.riskCertificateModal = riskCertificateModalComponent;
  }

  @Input()
  set insuranceStatusObj(insuranceStatus) {
    if (insuranceStatus) {
      this.insuranceStatus = insuranceStatus;
      this.populateRiskCertificateHeader();
      this.populateRiskCertificate();
    }
  }

  ngOnInit() {
    if (!this.injectedInsuranceStatus) {
      this.initializeInsuranceStatus();

      const recalculateInsuranceStatusSubscription = this.riskCertificateService.getRecalculateInsuranceStatusSignal().subscribe(
        (event) => {
          this.initializeInsuranceStatus();
        }
      );
      this.subscriptions.add(recalculateInsuranceStatusSubscription);
    }
  }

  goToEditRiskCertificate() {

    this.riskCertificateService.setRiskCertificateData(
      new RiskCertificateData(
        this.principalRespTotalAmounts, this.principalRespPeopleAmounts, this.principalRespThingsAmounts,
        this.principalRespMixedAmounts, this.pairRespTotalAmounts, this.pairRespPeopleAmounts,
        this.pairRespThingsAmounts, this.pairRespMixedAmounts, this.years, this.showPejus,
        this.insuranceStatus.pejusPerc, this.showDeductibles, this.insuranceStatus.deductiblesNumber,
        this.insuranceStatus.deductiblesAmount,
        this.insuranceStatus.originPlate, this.insuranceStatus.originChassis, this.insuranceStatus.originPolicyNumber,
        (this.insuranceStatus.originUniqueClassFrom) ? this.insuranceStatus.originUniqueClassFrom.replace(/^0+/, '')
          : this.insuranceStatus.originUniqueClassFrom,
        (this.insuranceStatus.originUniqueClassTo) ? this.insuranceStatus.originUniqueClassTo.replace(/^0+/, '')
          : this.insuranceStatus.originUniqueClassTo, this.insuranceStatus.policyExpirationDate,
        this.insuranceStatus.rateForm, this.insuranceStatus.companyId, this.riskCertificateTable, this.insuranceStatus,
        this.showATRHeader, this.showATRTable, this.insuranceStatus.lastYearOfTheReport,
        this.insuranceStatus.properties.originUniqueClassVisible
      )
    );

    this.modalService.openModal(this.editRiskCertificateViewContainerRef, this.riskCertificateModal,
      this.riskCertificateError, () => {
        this.initializeInsuranceStatus();
      });
  }

  genericElementsTrackByFn(index, genericElement: GenericElement) {
    return genericElement.label;
  }

  riskCertificateCellsTrackByFn(index, riskCertificateCell: RiskCertificateCell) {
    return riskCertificateCell.id;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected populateRiskCertificateHeader() {

    this.insuranceStatusData.length = 0;

    if (this.insuranceStatus) {

      const rateCode = this.insuranceStatus.rateForm ? this.insuranceStatus.rateForm.code : 'null';

      this.showPejus = rateCode === '4';
      this.showDeductibles = rateCode === '2' || rateCode === '3';

      if (this.insuranceStatus.policyExpirationDate) {
        this.insuranceStatusData.push(new GenericElement('Policy Expiration Date:',
          String(new Date(this.insuranceStatus.policyExpirationDate))));
      }
      if (this.insuranceStatus.originUniqueClassFrom) {
        this.insuranceStatusData.push(new GenericElement('Unique Class of Origin:',
          this.insuranceStatus.originUniqueClassFrom.replace(/^0+/, '')));
      }
      if (this.insuranceStatus.originUniqueClassTo) {
        this.insuranceStatusData.push(new GenericElement('Assigned Unique Class:',
          this.insuranceStatus.originUniqueClassTo.replace(/^0+/, '')));
      }
      if (this.insuranceStatus.rateForm && this.insuranceStatus.rateForm.description !== '') {
        this.insuranceStatusData.push(new GenericElement('Rate of Origin:',
          this.insuranceStatus.rateForm.description));
      }
      if (this.insuranceStatus.originPlate) {
        this.insuranceStatusData.push(new GenericElement('Plate of Origin:', this.insuranceStatus.originPlate));
      }
      if (this.insuranceStatus.originChassis) {
        this.insuranceStatusData.push(new GenericElement('Chassis of Origin:', this.insuranceStatus.originChassis));
      }
      if (this.insuranceStatus.companyId && this.insuranceStatus.companyId.description !== '') {
        this.insuranceStatusData.push(new GenericElement('Company of Origin:', this.insuranceStatus.companyId.description));
      }
      if (this.insuranceStatus.originPolicyNumber) {
        this.insuranceStatusData.push(new GenericElement('Policy Number of Origin:', this.insuranceStatus.originPolicyNumber));
      }
      if (this.showPejus && this.insuranceStatus.pejusPerc) {
        this.insuranceStatusData.push(new GenericElement('Pejus:', String(this.insuranceStatus.pejusPerc)));
      }
      if (this.showDeductibles && this.insuranceStatus.deductiblesNumber) {
        this.insuranceStatusData.push(new GenericElement('Number of unpaid deductibles:',
          String(this.insuranceStatus.deductiblesNumber)));
      }
      if (this.showDeductibles && this.insuranceStatus.deductiblesAmount) {
        this.insuranceStatusData.push(new GenericElement('Amount of unpaid deductibles:',
          String(this.insuranceStatus.deductiblesAmount)));
      }
      if (this.insuranceStatus.bersani) {
        const bersani = this.insuranceStatus.bersani === true ?
          this.translate.instant('YES') : this.translate.instant('NO');
        this.insuranceStatusData.push(new GenericElement('Law 134 Benefit:', String(bersani)));
      }

      if (this.insuranceStatus.name) {
        this.insuranceStatusData.push(new GenericElement('Name:', String(this.insuranceStatus.name)));
      }

      if (this.insuranceStatus.surnameOrCompanyName) {
        this.insuranceStatusData.push(new GenericElement('Surname/Company Name:', String(this.insuranceStatus.surnameOrCompanyName)));
      }

      if (this.insuranceStatus.fiscalCode) {
        this.insuranceStatusData.push(new GenericElement('Fiscal Code:', String(this.insuranceStatus.fiscalCode)));
      }

      if (this.insuranceStatus.iurCode) {
        this.insuranceStatusData.push(new GenericElement('IUR code:', String(this.insuranceStatus.iurCode)));
      }

      if (this.insuranceStatus.lawCode && this.insuranceStatus.lawCode.code) {
        this.insuranceStatusData.push(new GenericElement('Law code:', this.insuranceStatus.lawCode.description));
      }
    }

    const insuranceDataNum = this.insuranceStatusData.length;

    if (insuranceDataNum === 0) {
      return;
    }

    this.insuranceStatusDataRows = new Array<Array<GenericElement>>(Math.ceil(insuranceDataNum / 3));

    let i: number;
    let j = 0;

    for (i = 0; i < insuranceDataNum; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!this.insuranceStatusDataRows[j]) {
        this.insuranceStatusDataRows[j] = [];
      }
      this.insuranceStatusDataRows[j].push(this.insuranceStatusData[i]);
    }

  }

  protected populateRiskCertificate() {

    let claimsReportsYears: Array<ClaimsReportYear> = this.insuranceStatus.claimsReportYears;
    let i: number;

    claimsReportsYears = claimsReportsYears.sort((a, b) => {
      if (a.year > b.year) {
        return 1;
      } else if (a.year > b.year) {
        return -1;
      } else {
        return 0;
      }
    });

    const currentYear = this.riskCertificateService.getCurrentYear(this.insuranceStatus.lastYearOfTheReport);
    let numOfYearsToShow: number = this.riskCertificateService.getNumberOfYears(currentYear);

    if (claimsReportsYears.length === numOfYearsToShow || claimsReportsYears.length < numOfYearsToShow) {
      i = 0;
    } else {
      i = claimsReportsYears.length - numOfYearsToShow;
      numOfYearsToShow = numOfYearsToShow + i;
    }

    let year = currentYear;
    this.years.length = 0;
    for (i; i < numOfYearsToShow; i++) {
      this.years.push(year);
      year--;
    }

    this.years = this.years.reverse();

    numOfYearsToShow = this.riskCertificateService.getNumberOfYears(currentYear);

    if (claimsReportsYears.length === numOfYearsToShow || claimsReportsYears.length < numOfYearsToShow) {
      i = 0;
    } else {
      i = claimsReportsYears.length - numOfYearsToShow;
      numOfYearsToShow = numOfYearsToShow + i;
    }

    this.principalRespTotalAmounts.length = 0;
    this.principalRespPeopleAmounts.length = 0;
    this.principalRespThingsAmounts.length = 0;
    this.principalRespMixedAmounts.length = 0;
    this.pairRespTotalAmounts.length = 0;
    this.pairRespPeopleAmounts.length = 0;
    this.pairRespThingsAmounts.length = 0;
    this.pairRespMixedAmounts.length = 0;

    let k = 0;

    for (i; i < numOfYearsToShow; i++) {
      year = currentYear - (numOfYearsToShow - (i + 1));

      const principalRespAmounts = claimsReportsYears[i].principalResponsibility;
      const pairRespAmounts = claimsReportsYears[i].pairResponsibility;

      this.principalRespTotalAmounts.push(new RiskCertificateCell(i + '.principal_total', year,
        this.convertNegativeValueIntoString(principalRespAmounts.total), 'principal_total'));
      this.principalRespPeopleAmounts.push(new RiskCertificateCell(i + '.principal_people', year,
        this.convertNegativeValueIntoString(principalRespAmounts.ofWhichPerson), 'principal_people'));
      this.principalRespThingsAmounts.push(new RiskCertificateCell(i + '.principal_things', year,
        this.convertNegativeValueIntoString(principalRespAmounts.ofWhichThings), 'principal_things'));
      this.principalRespMixedAmounts.push(new RiskCertificateCell(i + '.principal_mixed', year,
        this.convertNegativeValueIntoString(principalRespAmounts.ofWhichMixed), 'principal_mixed'));
      this.pairRespTotalAmounts.push(new RiskCertificateCell(i + '.pair_total', year,
        this.convertNegativeValueIntoString(pairRespAmounts.total), 'pair_total'));
      this.pairRespPeopleAmounts.push(new RiskCertificateCell(i + '.pair_people', year,
        this.convertNegativeValueIntoString(pairRespAmounts.ofWhichPerson), 'pair_people'));
      this.pairRespThingsAmounts.push(new RiskCertificateCell(i + '.pair_things', year,
        this.convertNegativeValueIntoString(pairRespAmounts.ofWhichThings), 'pair_things'));
      this.pairRespMixedAmounts.push(new RiskCertificateCell(i + '.pair_mixed', year,
        this.convertNegativeValueIntoString(pairRespAmounts.ofWhichMixed), 'pair_mixed'));


      const riskCertificateColumn: Array<RiskCertificateCell> = new Array<RiskCertificateCell>();

      riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_total', year,
        this.convertNegativeValueIntoString(principalRespAmounts.total), 'principal_total'));
      riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_people', year,
        this.convertNegativeValueIntoString(principalRespAmounts.ofWhichPerson), 'principal_people'));
      riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_things', year,
        this.convertNegativeValueIntoString(principalRespAmounts.ofWhichThings), 'principal_things'));
      riskCertificateColumn.push(new RiskCertificateCell(i + '.principal_mixed', year,
        this.convertNegativeValueIntoString(principalRespAmounts.ofWhichMixed), 'principal_mixed'));
      riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_total', year,
        this.convertNegativeValueIntoString(pairRespAmounts.total), 'pair_total'));
      riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_people', year,
        this.convertNegativeValueIntoString(pairRespAmounts.ofWhichPerson), 'pair_people'));
      riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_things', year,
        this.convertNegativeValueIntoString(pairRespAmounts.ofWhichThings), 'pair_things'));
      riskCertificateColumn.push(new RiskCertificateCell(i + '.pair_mixed', year,
        this.convertNegativeValueIntoString(pairRespAmounts.ofWhichMixed), 'pair_mixed'));

      this.riskCertificateTable[k] = riskCertificateColumn;

      k++;
    }
    if (this.injectedInsuranceStatus) {
      this.showATRHeader = true;
      this.showATRTable = true;
    }
  }

  private initializeInsuranceStatus() {
    if (this.insuranceBackgroundService.getInsuranceStatusObject()) {
      this.insuranceStatus = this.insuranceBackgroundService.getInsuranceStatusObject();
      this.bersani = this.insuranceStatus.bersani;
      this.populateRiskCertificateHeader();
      this.populateRiskCertificate();
    }

    const insuranceStatusSubscription = this.insuranceBackgroundService.getInsuranceStatusObs().subscribe(data => {
      this.insuranceStatus = data;
      this.bersani = this.insuranceStatus.bersani;
      this.populateRiskCertificateHeader();
      this.populateRiskCertificate();
    });
    this.subscriptions.add(insuranceStatusSubscription);
  }

  private convertNegativeValueIntoString(amount): string {
    if (amount !== null && amount !== undefined) {
      if (amount === -1) {
        return 'N.A.';
      } else if (amount === -2) {
        return 'N.D.';
      } else if (amount === -3) {
        return '--';
      } else {
        return amount;
      }
    }
  }

}

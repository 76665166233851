import {ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional} from '@angular/core';
import {AbsOperationComponent} from '../abs-operation-component/abs-operation.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {
  Agreement, PostsalesOperationObject
} from '../../models/postsales-operations-response.model';
import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifierService} from '@rgi/portal-ng-core';
import {AnagService} from '../../services/anag.service';
import {PlcQuestService} from '../../services/plc-quest.service';
import { AuthService } from '../../services/auth.service';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';


@Component({
  selector: 'lpc-convention-insertion-exclusion',
  templateUrl: './convention-insertion-exclusion.component.html',
  styleUrls: ['./convention-insertion-exclusion.scss']
})
export class ConventionInsertionExclusionComponent extends AbsOperationComponent implements OnInit {

  protected operationDataKey;
  public agreements: Agreement[];
  public selectedAgreement: Agreement;

  public getFilteredErrors() {
    return !!this.errors ? this.errors.filter(err => err.severity !== 'info') : [];
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      agreement: new UntypedFormControl('', Validators.required)
    });
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(res => this.populateConventionMenu(res))
    );
  }

  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    this.populateConventionMenu(result);
  }

  protected populateConventionMenu(result: PostsalesOperationObject) {
    this.agreements = result.definitions.conventionsList as Agreement[];
    let agreement = !!this.agreements && result.data.operationData.data.convention.code;
    if (!!this.agreements && this.agreements.length === 1) {
      agreement = this.agreements[0].code;
    }
    this.formGroup.get('agreement').setValue(agreement);
  }

  protected getTransformedOperationData(): any {
    return {
      convention: !!this.formGroupValue.agreement ? this.agreements.find(ag => ag.code === this.formGroupValue.agreement) : null
    };
  }
  get agreementSelected() {
    const agreement = !!this.formGroupValue.agreement ? this.agreements.find(ag => ag.code === this.formGroupValue.agreement) : null;
    return !!agreement ? agreement.description : EMPTY_STR;

  }

}

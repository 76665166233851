import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxRouter, RoutingService} from './routing.service';
import {
  RgiRxRouterDefaultUrlHandlingStrategy,
  RgiRxRouterOptions,
  RgiRxRouterUrlHandlingStrategy,
  RouteComponentLoader,
  Routes,
  ROUTES
} from './router.api';
import {RgiRxRoutingHostDirective} from './directives/rgi-rx-routing-host.directive';
import {RoutingOutletComponent} from './routing-outlet/routing-outlet.component';
import {DynamicComponentLoader} from './dynamic-component-loader';
import {RgiRxRouterLinkDirective} from './directives/rgi-rx-router-link.directive';
import {RgiRxDefaultRouterTitleStrategy, RgiRxRouterTitleStrategy} from './router.title-strategy';
import {rgiRxRouterProvideRoutes} from './standalone/router.providers';


@NgModule({
  declarations: [RoutingOutletComponent, RgiRxRoutingHostDirective, RgiRxRouterLinkDirective],
  imports: [
    CommonModule
  ],
  exports: [
    RoutingOutletComponent,
    RgiRxRoutingHostDirective,
    RgiRxRouterLinkDirective
  ],
  providers: [
    {provide: RouteComponentLoader, useExisting: DynamicComponentLoader},
    {provide: RgiRxRouter, useExisting: RoutingService},
    {provide: RgiRxRouterUrlHandlingStrategy, useExisting: RgiRxRouterDefaultUrlHandlingStrategy},
    {provide: RgiRxRouterTitleStrategy, useExisting: RgiRxDefaultRouterTitleStrategy}
  ]
})
export class RgiRxRoutingModule {
  static forRoot(config: Routes, opts?: RgiRxRouterOptions): ModuleWithProviders<RgiRxRoutingModule> {
    return {
      ngModule: RgiRxRoutingModule,
      providers: [
        rgiRxRouterProvideRoutes(config, opts)
      ]
    };
  }
}


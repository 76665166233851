import {Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, ModalService, OnModalClose} from '@rgi/rx/ui';
import {Subscription} from 'rxjs';
import {
  AnagStateManagerPartyEditor,
  AnagStatePartyEditor
} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {DOCUMENT_ISSUING_AUTHORITY_ENUM, DOCUMENT_TYPE_ENUM} from '../../anag-constants/enums-constants';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagFormFieldConfig} from '../../anag-model/anag-domain/anag-form-field-config';
import {AnagConfigService} from '../../anag-resources/anag-config.service';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {distinctUntilChanged} from "rxjs/operators";
import {ANAG_DOCUMENT_OBJ_MODE, ANAG_FUNCTIONS} from "../../anag-constants/anag-constants";
import {
  AnagDocument,
  AnagDrivingLicense
} from '../../anag-model/anag-domain/anag-document';
import {AnagUtilsService} from "../../anag-resources/anag-utils.service";
import {AnagExtensionsService} from "../../anag-resources/anag-extensions.service";
import { RgiRxUserAuthorizationService } from '@rgi/rx/auth';

@Component({
  selector: 'rgi-anag-document',
  templateUrl: './anag-document.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagDocumentComponent implements OnInit, OnModalClose, OnDestroy {

  modalClose: EventEmitter<any> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  inputParty: AnagApiParty;
  clonedState: AnagStatePartyEditor;
  maxDate = new Date();
  editorMode = ANAG_DOCUMENT_OBJ_MODE.EDIT;
  ANAG_DOCUMENT_OBJ_MODE = ANAG_DOCUMENT_OBJ_MODE;
  submitted = false;
  isNumberDocPresent: boolean;
  drivingLicenseList: Array<AnagDrivingLicense> = new Array<AnagDrivingLicense>();
  drivingLicenseModify: AnagDrivingLicense;
  indexDrivingLicense: number;
  editMode = false;
  document: AnagDocument;
  documentForm: UntypedFormGroup = new UntypedFormGroup({});
  canAddLicenseType: boolean = false;
  canEditLicenseType: boolean = false;
  canDeleteLicenseType: boolean = false;
  

  documentFormMap = new Map<string, AnagFormFieldConfig>([
    ['documentType', new AnagFormFieldConfig('documentType', this.documentForm, [], 'document')],
    ['documentNumber', new AnagFormFieldConfig('documentNumber', this.documentForm, [], 'document')],
    ['releaseDate', new AnagFormFieldConfig('releaseDate', this.documentForm, [], 'document')],
    ['expirationDate', new AnagFormFieldConfig('expirationDate', this.documentForm, [], 'document')],
    ['locationsRelease', new AnagFormFieldConfig('locationsRelease', this.documentForm, [], 'document')],
    ['authoritiesRelease', new AnagFormFieldConfig('authoritiesRelease', this.documentForm, [], 'document')],
  ]);

  constructor(
    public pushMessageHandler: PushMessageHandlerService,
    public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public statelessService: AnagStatelessService,
    public configService: AnagConfigService,
    protected modalService: ModalService,
    protected anagUtilsService: AnagUtilsService,
    public anagExtensionsService: AnagExtensionsService,
    public authorizationService: RgiRxUserAuthorizationService,
    @Optional() @Inject(DIALOG_DATA) document: AnagDocument) {
    this.clonedState = this.statelessService.deepClone(this.stateMgr.getCurrentState());
    this.inputParty = this.clonedState.party;
    if (document) {
      this.document = document;
    }
  }

  ngOnInit() {
    this.pushMessageHandler.clearTag('error-tag');
    this.pushMessageHandler.clearTag('doc-number-error-tag');
    this.pushMessageHandler.clearTag('no-doc-error-tag');
    this.configService.setConfigToForm(this.documentFormMap, this.clonedState.configuration);
    if (this.document) {
      this.adaptPartyToForm();
    }
    if (!this.stateMgr.getCurrentState().isValidDrivingLecense && this.inputParty?.dateOfBirth && this.drivingLicenseList?.length > 0) {
      const filter = this.anagUtilsService.createCheckDrivingLicenseFilter(this.inputParty?.dateOfBirth, this.documentForm.get('expirationDate').value, this.drivingLicenseList);
      this.anagStorage.checkDrivingLicense(filter).subscribe(resp => {
        if (resp.outcomeCode === '0') {
          this.notifyErrorMsg(resp.message,null);
        }
      });
    }
    this.manageSubscriptions();
    if(this.documentForm.get('documentNumber').value) {
      this.isNumberDocPresent = true;
    }
    this.canAddLicenseType = this.stateMgr.getCurrentState().editorModeEnable && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.addLicenseType);
    this.canEditLicenseType = this.stateMgr.getCurrentState().editorModeEnable && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.editLicenseType);
    this.canDeleteLicenseType = this.stateMgr.getCurrentState().editorModeEnable && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.deleteLicenseType);
  }

  manageSubscriptions() {
    this.subscriptions.add(this.documentForm.get('releaseDate').valueChanges.pipe(distinctUntilChanged()).subscribe( date => {
      this.pushMessageHandler.clearTag('error-tag');
      this.pushMessageHandler.clearTag('doc-number-error-tag');
      this.pushMessageHandler.clearTag('no-doc-error-tag');
      this.configService.dateComparisonValidator(this.documentForm.get('releaseDate'), this.documentForm.get('expirationDate'));
    }));
    this.subscriptions.add(this.documentForm.get('expirationDate').valueChanges.pipe(distinctUntilChanged()).subscribe(date => {
      this.pushMessageHandler.clearTag('error-tag');
      this.pushMessageHandler.clearTag('doc-number-error-tag');
      this.pushMessageHandler.clearTag('no-doc-error-tag');
      this.configService.dateComparisonValidator(this.documentForm.get('releaseDate'), this.documentForm.get('expirationDate'));
    }));
    this.subscriptions.add(this.documentForm.get('documentType').valueChanges.pipe(distinctUntilChanged()).subscribe(docType => {
      if (docType !== '2') {
        this.drivingLicenseList = [];
      }
      this.onChangeDocumentType();
    }));
  }

  addDrivingLicenceType() {
    this.editorMode = ANAG_DOCUMENT_OBJ_MODE.DRIVING_LICENCE_TYPE;
  }
  adaptPartyToForm() {
	let doc = this.document;
    if (doc.documentType) {
      this.setControlValue(this.documentForm.get('documentType'), doc.documentType.codice);
      this.onChangeDocumentType();
    }
    if (doc.authoritiesRelease) {
      this.setControlValue(this.documentForm.get('authoritiesRelease'), doc.authoritiesRelease.codice);
    }
    if (doc.releaseDate) {
      this.setControlValue(this.documentForm.get('releaseDate'), new Date(doc.releaseDate));
    }
    if (doc.expirationDate) {
      this.setControlValue(this.documentForm.get('expirationDate'), new Date(doc.expirationDate));
    }
    this.setControlValue(this.documentForm.get('documentNumber'), doc.documentNumber);
    this.setControlValue(this.documentForm.get('locationsRelease'), doc.locationsRelease);
    if (doc.drivingLicense && doc.drivingLicense.length > 0) {
      this.drivingLicenseList = this.statelessService.deepClone(doc.drivingLicense);
    }
}

  setControlValue(control: AbstractControl, value: any) {
    if (control && value) {
      control.setValue(value);
    }
  }

  get documentTypes() {
    return this.anagStorage.getEnumsByCode(DOCUMENT_TYPE_ENUM);
  }

  get authorities() {
    return this.anagStorage.getEnumsByCode(DOCUMENT_ISSUING_AUTHORITY_ENUM);
  }

  actionClose() {
    this.modalClose.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateDocument() {
    this.pushMessageHandler.clearTag('doc-number-error-tag');
    this.pushMessageHandler.clearTag('no-doc-error-tag');
    this.pushMessageHandler.clearTag('error-tag');
    this.submitted = true;
    let isDocumentNumberUnique = true;
      if (!this.isNumberDocPresent) {
        isDocumentNumberUnique = this.anagUtilsService.isDocumentNumberUnique(this.inputParty.documents, this.documentForm);
      }

      if (isDocumentNumberUnique) {
        if(this.documentForm.get('documentType').value) {
          if (this.documentForm.valid) {
            if (this.inputParty?.dateOfBirth && this.drivingLicenseList?.length > 0) {
              this.checkDrivingLicenseAndUpdateDocument();
            } else {
              const cont = {
                document: this.documentForm.getRawValue(),
                drivingLicenseList: this.drivingLicenseList,
                isValidDrivingLicense: true
              }
              this.modalClose.emit(cont);
            }
          } else {
            this.notifyErrorMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_', 'error-tag');
          }
        } else  {
          this.notifyErrorMsg('_ANAG_._MSG_._INSERT_DOC_TYPE','no-doc-error-tag');
        }
      } else {
        this.notifyErrorMsg('_ANAG_._MSG_._DOC_ALREADY_EXIST', 'doc-number-error-tag');
      }

  }

  notifyErrorMsg(errorMsg,tag) {
    this.pushMessageHandler.clearTag(tag);
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
      tag: tag,
      status: 'danger',
      dismissible: false
    }));
  }
  isFieldVisible(field: string) {
    return this.documentForm.get(field);
  }

  get datepickerPlaceholder() {
    return this.statelessService.getDatePickerPlaceholderLabel();
  }

  deleteDrivingLicense(i: number) {
    if (this.drivingLicenseList && this.drivingLicenseList.length > 0) {
      this.drivingLicenseList.splice(i, 1);
    }
  }

  editDrivingLicense(drivingLecense: AnagDrivingLicense, i: number) {
    this.drivingLicenseModify = drivingLecense;
    this.indexDrivingLicense = i;
    this.editMode = true;
    this.editorMode = ANAG_DOCUMENT_OBJ_MODE.DRIVING_LICENCE_TYPE;
  }

  onCloseDrivingLicence() {
    this.editMode = false;
    this.drivingLicenseModify = null;
    this.editorMode = ANAG_DOCUMENT_OBJ_MODE.EDIT;
  }

  onConfirmDrivingLicence(drivingType: AnagDrivingLicense) {
    this.editorMode = ANAG_DOCUMENT_OBJ_MODE.EDIT;
    if (drivingType) {
      if (this.editMode) {
        this.drivingLicenseList[this.indexDrivingLicense] = drivingType;
        this.drivingLicenseModify = null;
        this.editMode = false;
      } else {
        this.drivingLicenseList.push(drivingType);
      }
    }
  }
  onDocNumberChange() {
    this.isNumberDocPresent = false;
  }

  checkDrivingLicenseAndUpdateDocument() {
    const filter = this.anagUtilsService.createCheckDrivingLicenseFilter(this.inputParty?.dateOfBirth, this.documentForm.get('expirationDate').value, this.drivingLicenseList);
    this.anagStorage.checkDrivingLicense(filter).subscribe(resp => {
      if (resp.outcomeCode !== '0') {
        const cont = {document: this.documentForm.getRawValue() , drivingLicenseList: this.drivingLicenseList , isValidDrivingLicense: true }
        this.modalClose.emit(cont);
      } else {
        this.notifyErrorMsg(resp.message,null);
      }
    });
  }

  onChangeDocumentType() {
    this.anagExtensionsService.onChangeDocType(this.documentFormMap, this.documentForm, this.clonedState);
  }
}

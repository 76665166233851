<div class="message" >
  <div class="message-nok" [hidden]="isLowerThanRequired()">
    <span>{{'lic_information' | translate}}</span>
    <span>:</span>
    <span> {{formatter.setCurrencyVal(getLeftAmount())}} </span>
    <span>{{'lic_or_junction' | translate}}</span>
    <span> {{formatter.setDecimalVal(getLeftPercentage(), 3)}}% </span>
    <span>{{'lic_to_be_invested' | translate}}</span>
  </div>
  <div class="message-ok" [hidden]="isOk()">
    <span>{{'lic_info_investment_completed' | translate}}</span>
  </div>
  <div class="message-error" [hidden]="isHigherThenRequired()">
    <span>{{'lic_allocation_exceeds' | translate}}</span>
    <span> {{formatter.setCurrencyVal(getLeftAmount())}}</span>
  </div>
</div>

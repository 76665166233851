<form [formGroup]="formGroup">
  <div class="form-group">
    <div class="row">
      <label class="label-core" translate>lpc_search_filter</label>
      <div class="btn-group btn-group-justified" style="margin-top: auto;">
        <label
        class="btn rgi-radio-btn btn-primary"
        *ngIf="typeSearchProfiles"
        [ngClass]="{'bg-active':!!showProfiles}"
        translate
        (click)="onProfilesClick()">lpc_profiles</label>
      <label
        class="btn rgi-radio-btn btn-primary"
        *ngIf="typeSearchFunds"
        [ngClass]="{'bg-active':!showProfiles}"
        translate
        (click)="onFundsClick()">lpc_funds</label>
      </div>
    </div>
    <div *ngIf="showProfiles" formGroupName="profileSearch">
      <div class="example-row">
        <label class="label-core" translate>lpc_profile_name</label>
        <input formControlName="profile" class="form-control">
      </div>
      <div class="example-row">
        <label class="label-core" translate>lpc_product</label>
        <ng-select formControlName="product">
          <ng-option *ngFor="let product of products" [value]="product.id">{{product.description}}</ng-option>
        </ng-select>
      </div>
    </div>
    <div *ngIf="!showProfiles"formGroupName="fundSearch">
      <div class="example-row">
        <label class="label-core" translate>lpc_fund_name</label>
        <input formControlName="fund" class="form-control">
      </div>

      <div class="example-row">
        <label class="label-core" translate>lpc_ISIN_code</label>
        <input formControlName="isin" class="form-control">
      </div>

      <div class="example-row">
        <label class="label-core" translate>lpc_Fund_Code</label>
        <input formControlName="fundCode" class="form-control">
      </div>

    </div>

    <!-- Buttons -->
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button type="button" (click)="clean()" class="btn btn-warning btn-secondary pull-right text-uppercase"
          data-qa="financial-searc-empty-btn" [disabled]="errorPresent()">
          <span translate>lpc_empty</span>
        </button>
      </div>
      <div class="btn-group">
        <button type="submit" (click)="openSession()"
          class="btn btn-warning pull-right text-uppercase" [disabled]="errorPresent()" data-qa='financial-searc-find'>
          <span translate>lpc_find</span>
        </button>
      </div>
    </div>

    <div class="example-row" *ngIf="!!errors?.length">
      <pnc-form-message *ngFor="let error of errors" [type]="error.type" [messages]="getErrors(error.type)"  detail="validationError"></pnc-form-message>
    </div>

    </div>


    <div *ngIf="mobileLoaderActive" >
      <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
    </div>
</form>



import { Component, OnInit, Input, Inject } from '@angular/core';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';

@Component({
  selector: 'claims-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss']
})
export class GeneralDataComponent implements OnInit {
  @Input() potPayFE: InitPotentialPaymentEntity;
  @Input() currentStep: number;
  session: any;

  constructor(
    @Inject('sessionService') session: any,
    private modalService: NgbModal
  ) {
    this.session = session;
  }


  ngOnInit() {
  }

  round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }


  settlementsDetail(content) {
    content.stopPropagation();

    const modalRef = this.modalService.open(PaymentModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.potPayFE = this.potPayFE;
    modalRef.componentInstance.settlementsDetails = true;

    modalRef.result.then((result) => {
      if (result) {
        console.log('Hello');
      }
    });

    return false;

  }

  receiptsDetail(content) {
    content.stopPropagation();

    const modalRef = this.modalService.open(PaymentModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.potPayFE = this.potPayFE;
    modalRef.componentInstance.receiptsDetails = true;

    modalRef.result.then((result) => {
      if (result) {
        console.log('Hello');
      }
    });

    return false;
  }

  compensationsDetail(content) {
    content.stopPropagation();

    const modalRef = this.modalService.open(PaymentModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.potPayFE = this.potPayFE;
    modalRef.componentInstance.compensationsDetails = true;

    modalRef.result.then((result) => {
      if (result) {
        console.log('Hello');
      }
    });

    return false;
  }

  assignementsDetail(content) {
    content.stopPropagation();

    const modalRef = this.modalService.open(PaymentModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.potPayFE = this.potPayFE;
    modalRef.componentInstance.assignementsDetails = true;

    modalRef.result.then((result) => {
      if (result) {
        console.log('Hello');
      }
    });

    return false;
  }

  /*   closeSession() {
      if (confirm(this.translate.instant('Click CONFIRM to proceed or CANCEL to cancel operation.'))) {
        const session = this.getCurrentSession();
        this.session.remove(session.idSession);
      }
    } */

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }
}

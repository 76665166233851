import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import {ANAG_EN} from './en';
import {ANAG_IT} from './it';
import {ANAG_FR} from './fr';
import {ANAG_ES} from './es';
import {ANAG_DE} from './de';
import {ANAG_EU_ES} from "./eu-ES";
import {ANAG_CA_ES} from "./ca-ES";
import {ANAG_GL_ES} from "./gl-ES";

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_EN);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_FR);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_ES);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_DE);
  });
}

export function loadEU(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_EU_ES);
  });
}

export function loadCA(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_CA_ES);
  });
}

export function loadGL(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ANAG_GL_ES);
  });
}


export const ANAG_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadFR,
    locale: 'fr'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadDE,
    locale: 'de'
  },
  {
    load: loadEU,
    locale: 'eu-ES'
  },
  {
    load: loadCA,
    locale: 'ca-ES'
  },
  {
    load: loadGL,
    locale: 'gl-ES'
  }
];

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ClaimOpeningDetailsService } from '../../claim-opening/components/claim-opening-details/claim-opening-details.service';
import { AlertService } from '@rgi/digital-claims-common-angular';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import { ComboInjuryEntity, INIT_COMBO_INJURY, InjuryCode, InjuryEntity } from '../dsc-shared-model/dsc-model-injury';

@Component({
  selector: 'claims-dsc-injury-code',
  templateUrl: './dsc-injury-code.component.html',
  styleUrls: ['./dsc-injury-code.component.scss']
})
export class DscInjuryCodeComponent implements OnInit {

  @Input() injuryCode: InjuryCode; // Oggetto contenente i dati di injury code
  @Input() disabledForm: boolean = false; // Oggetto utilizzato per abilitare / disabilitare i campi del form
  @Input() ministerialBranchcode: string;
  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Output() outputFormInjuryCode: EventEmitter<InjuryCode> = new EventEmitter();

  isAccordion: boolean = false;
  dataForm: UntypedFormGroup; // Form in cui inserire i dati
  dataCombo: ComboInjuryEntity; // Oggetto contenente i dati delle multiselect
  enableFormDisplay: boolean = false; // Oggetto utilizzato per la visualizzazione del form dopo il caricamento dei dati

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public openingDetailsService: ClaimOpeningDetailsService) {
  }

  ngOnInit() {
    this.dataForm = this.formBuilder.group({
      allegationDescription: ['', Validators.nullValidator], // Reparto
      allegationSel: ['', Validators.nullValidator], // Tipo perdita
      departmentCodeSel: ['', Validators.required], // Tipologia civile/penale
      injuryCodeSel: ['', Validators.nullValidator], // Descrizione asserzione
      lossTypeSel: ['', Validators.nullValidator],
      injuryNote: ['', Validators.nullValidator],
      matterType: ['', Validators.nullValidator],
      ministerialBranch: ['', Validators.nullValidator],
      severityCodeSel: ['', Validators.nullValidator],
      subHospital: ['', Validators.nullValidator],
    });
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
    this.populateCombo();
  }

  /**
   * Popola le multiselect del form
   */
  populateCombo() {
    this.dataCombo = INIT_COMBO_INJURY;
    const injuryEntity: InjuryEntity = new InjuryEntity();
    injuryEntity.ministerialBranch =
      this.injuryCode.ministerialBranch ? this.injuryCode.ministerialBranch : this.ministerialBranchcode;
    this.openingDetailsService.populateInjuryComponent(injuryEntity)
      .subscribe((response: InjuryEntity) => {
        this.dataCombo.listDepartment = response.listDepartment; // Reparto
        this.dataCombo.listTypeCivilPenal = response.listTypeMatter; // Tipologia civile/penale
        this.dataCombo.listTypeOfDamage = response.listTypeofLoss; // Tipo perdita
        this.dataForm.patchValue(this.injuryCode, {
          emitEvent: false
        });
        this.enableForm();
      }, err => {
        this.alertService.translatedMessage('Error loading data');
        // Abilita la visualizzazione dell'intero form e dei relativi campi
        this.enableFormDisplay = true;
      });
  }

  /**
   * Abilita / Disabilita i campi del form
   */
  enableForm() {
    if (this.disabledForm) {
      this.dataForm.get('allegationDescription').disable();
      this.dataForm.get('allegationSel').disable();
      this.dataForm.get('departmentCodeSel').disable();
      this.dataForm.get('injuryCodeSel').disable();
      this.dataForm.get('lossTypeSel').disable();
      this.dataForm.get('injuryNote').disable();
      this.dataForm.get('matterType').disable();
      this.dataForm.get('ministerialBranch').disable();
      this.dataForm.get('severityCodeSel').disable();
      this.dataForm.get('subHospital').disable();
    } else {
      this.dataForm.get('allegationDescription').enable();
      this.dataForm.get('allegationSel').enable();
      this.dataForm.get('departmentCodeSel').enable();
      this.dataForm.get('injuryCodeSel').enable();
      this.dataForm.get('lossTypeSel').enable();
      this.dataForm.get('injuryNote').enable();
      this.dataForm.get('matterType').enable();
      this.dataForm.get('ministerialBranch').enable();
      this.dataForm.get('severityCodeSel').enable();
      this.dataForm.get('subHospital').enable();
    }

    // Abilita la visualizzazione dell'intero form e dei relativi campi
    this.enableFormDisplay = true;
    this.outputFormChange();
  }

  outputFormChange() {
    const injuryCodeUpdate: InjuryCode = {
      ...this.injuryCode
    };
    injuryCodeUpdate.allegationDescription = this.dataForm.controls.allegationDescription.value;
    injuryCodeUpdate.allegationSel = this.dataForm.controls.allegationSel.value;
    injuryCodeUpdate.departmentCodeSel = this.dataForm.controls.departmentCodeSel.value;
    injuryCodeUpdate.injuryCodeSel = this.dataForm.controls.injuryCodeSel.value;
    injuryCodeUpdate.lossTypeSel = this.dataForm.controls.lossTypeSel.value;
    injuryCodeUpdate.injuryNote = this.dataForm.controls.lossTypeSel.value;
    injuryCodeUpdate.matterType = this.dataForm.controls.matterType.value;
    injuryCodeUpdate.ministerialBranch = this.dataForm.controls.ministerialBranch.value;
    injuryCodeUpdate.severityCodeSel = this.dataForm.controls.severityCodeSel.value;
    injuryCodeUpdate.subHospital = this.dataForm.controls.subHospital.value;
    injuryCodeUpdate.isValidForm = this.dataForm.valid;
    this.outputFormInjuryCode.emit(injuryCodeUpdate);
  }

}

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpRequest} from "@angular/common/http";
import {API_PATHS, ApiPaths} from "../core.api";
import {EnvironmentService} from "./environment.service";


//(\/v2\/product\/)(.*)|(\/v2\/product\?)(.*)
// ((\/v2\/product\/)|(\/v2\/product\?))(.*)

///((v2\/product)|(v2\/product?))(.*)
@Injectable()
export class URILocatorService {



  private apiPaths: ApiPaths = [];
  constructor(
    private environment: EnvironmentService,
    @Optional() @Inject(API_PATHS) apiPathsConfiguration?: ApiPaths[]
  ) {
    if (apiPathsConfiguration) {
      apiPathsConfiguration.forEach(
        token => {
          token.forEach(
            value => {
              this.apiPaths.push(value);
            }
          )
        }
      )
    }
  }


  isRelativeApiPath(request: HttpRequest<any>): boolean {
    return !!this.apiPaths.find(value => {
      const escapedPath = value.split("/").join("\\/")
      const RELATIVE_PATTERN = new RegExp(`((${escapedPath}\/)|(${escapedPath}\?))(.*)`)
      let url = this.introspectURL(request.url);
      let envUrl = this.introspectURL(this.environment.environmentUrl);
      let urlWithoutEnvPath = url.pathname.replace(`${envUrl.pathname}/`, "");
      let matched = urlWithoutEnvPath.match(RELATIVE_PATTERN)
      return matched !== null && (matched[0] === value || matched[2] !== undefined)
    });
  }
  private introspectURL(request: string): URL {
    try {
      return new URL(`${request}`)
    } catch (e) {
      return new URL(`http://host.dom${request}`)
    }
  }
}

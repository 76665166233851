<div class="rgipc-quotation-header-container rgipc-mb-l">
  <div class="rgipc-quotation-header">
    <div class="rgipc-flex-center">
      <rgi-pnc-quotation-header-installment [headerInstallments]="data.headerInstallments" class="rgipc-flex-center">
      </rgi-pnc-quotation-header-installment>
      <rgi-rx-panel-footer class="rgipc-ml-m" *ngIf="data.buttons?.length">
        <ng-container *ngFor="let btn of data.buttons">
          <button rgi-rx-button *ngIf="btn.visible"
                  [color]="btn.color"
                  (click)="onBtnAction(btn.action)"
                  [disabled]="btn.disabled"
                  [attr.data-qa]="btn.label">{{btn.label | translate}}
          </button>
        </ng-container>
      </rgi-rx-panel-footer>
      <rgi-pnc-quotation-menu *ngIf="data.menu?.items?.length"
                              [menuData]="data.menu"
                              class="rgipc-ml-m">
      </rgi-pnc-quotation-menu>
    </div>
  </div>
</div>

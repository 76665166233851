import {IdRoleVector, PostsalesError, Role} from './../models/postsales-operations-response.model';
import {InputFieldDefinition} from '../models/postsales-operations-response.model';
import {PlcDateUtils} from './plc-date-utils';
import {LpcStepperComponent} from '../modules/lpc-stepper/component/lpc-stepper/lpc-stepper.component';
import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import { formatNumber } from '@angular/common';
import { EMPTY_STR, ERROR_TYPE_CURRENT, SEVERITY_ERROR_BLOCKING } from '../models/consts/lpc-consts';

export class PlcObjectUtils {
  public static addToSet(set: any[], item: any) {
    let includes = false;
    set.forEach(setItem => {
      if (PlcObjectUtils.equal(setItem, item)) {
        includes = true;
      }
    });
    if (!includes) {
      set.push(item);
    }
  }

  public static mapInputFields(
    inputFields: InputFieldDefinition[]
  ): { [key: string]: any } {
    const obj: { [key: string]: any } = {};
    inputFields.forEach(inputField => {
      obj[inputField.code] = inputField.defaultValue;
    });
    return obj;
  }

  public static mapInputFieldsAsDate(
    inputFields: InputFieldDefinition[]
  ): { [key: string]: Date } {
    const obj: { [key: string]: any } = {};
    inputFields.forEach(inputField => {
      obj[inputField.code] = PlcDateUtils.isoToDate(inputField.defaultValue);
    });
    return obj;
  }

  public static isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  public static equal(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (obj1 !== Object(obj1) && obj2 !== Object(obj2)) {
      return obj1 === obj2;
    }

    if ((obj1 !== Object(obj1)) !== (obj2 !== Object(obj2))) {
      return false;
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    for (const key of Object.keys(obj1)) {
      if (!Object.keys(obj2).includes(key)) {
        return false;
      }
      if (!PlcObjectUtils.equal(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  public static merge(target: any, ...sources: any): any {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (PlcObjectUtils.isObject(target) && PlcObjectUtils.isObject(source)) {
      for (const key in source) {
        if (PlcObjectUtils.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          PlcObjectUtils.merge(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return PlcObjectUtils.merge(target, ...sources);
  }

  public static asValidArray<T>(array: T[]): T[] {
    return array ? array : [];
  }

  public static isArrayEmpty<T>(array: T[]): boolean {
    return !!array && !!array.length;
  }

  public static roundToDecimalTrunc(num: number, decimal: number = 0): number {
    const value = PlcObjectUtils.clone(num);
    const dec: number = Math.pow(10, decimal);
    return Math.trunc(value * dec) / dec;
  }

  public static roundToDecimal(num: number, decimal: number = 0): number {
    const dec: number = Math.pow(10, decimal);
    return Math.round(num * dec) / dec;
  }

  public static countDigit(value: number): number {
    return Math.floor(Math.log10(Math.abs(value))) + 1;
  }

  public static formatNumberLocale(num: number, locale: string): string {
    return formatNumber(num, locale, '1.2-2');
  }

  /**
   * Returns a generic PostsalesError error.
   *
   * @param LpcStepperComponent. the stepper component
   * @param number. The step number
   * @param string. The message to add on the PostsalesError object
   *
   */
  public static getGenericPostsalesError(stepper: LpcStepperComponent, step: number, msg: string): PostsalesError {
    return {
      context: stepper.steps[step].fieldId,
      errorId: EMPTY_STR,
      errorMessage: msg,
      severity: SEVERITY_ERROR_BLOCKING,
      type: ERROR_TYPE_CURRENT
    };
  }

  public static flattenFormErrors(control: AbstractControl, errors: ValidationErrors): ValidationErrors {
    Object.assign(errors, control.errors);
    if (control instanceof UntypedFormGroup) {
      Object.keys(control.controls).forEach(key => {
        const child = control.get(key);
        Object.assign(errors, child.errors);
        Object.assign(errors, PlcObjectUtils.flattenFormErrors(child, errors));
      });
    }
    return errors;
  }

  /**
   * Returns the boolen from a string value.
   * @param string. A value that is suppose to be in a string format as
   * 'true', 'TRUE', 'false' or 'FALSE'
   */
  public static getBooleanString(str: string): boolean {
    if (str.toLocaleLowerCase() === 'true') {
      return true;
    } else if (str.toLocaleLowerCase() === 'false') {
      return false;
    } else {
      throw new Error('Please provide "true" or "false" as string in order to return a true boolean value');
    }
  }

  /**
   * @description
   * Returns an array of PostsalesError without duplicates.
   * @param array of PostsalesError
   *
   */
  public static removeDuplicatesFromPostSalesErrors(errorsList) {
    const returnStatement = errorsList.reduce((unique, o) => {
      if (!unique.some(obj => obj.errorMessage === o.errorMessage && obj.context === o.context)) {
        unique.push(o);
      }
      return unique;
    }, []);
    return returnStatement;
  }

  /**
   * Returns an array of string or boolean without duplicates.
   *
   * @param array of string or boolean
   *
   */
  public static removeDuplicatesFromArray(array): Array<string | boolean> {
    return ([...new Set(array)] as Array<string | boolean>);
  }

  public static convertRolesIntoIdRoleVector(array: Array<Role>): Array<IdRoleVector> {
    if (array && array.length > 0) {
      return array.map((element) => {
        return {
          id: element.id,
          role: element.role
        } as IdRoleVector;
      });
    } else {
      return [];
    }
  }

  public static areIdRoleVectorsEquals(arrayA: Array<IdRoleVector>, arrayB: Array<IdRoleVector>): boolean {
    if (arrayA.length === arrayB.length) {
      const orderedA = this.orderIdRoleVector(arrayA);
      const orderedB = this.orderIdRoleVector(arrayB);

      return orderedA.every((a, index) => {
        return this.compareIdRoleVector(a, orderedB[index]) === 0;
      });
    } else {
      return false;
    }
  }

  public static orderIdRoleVector(array: Array<IdRoleVector>): Array<IdRoleVector> {
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < (array.length - i - 1); j++) {
        if (this.compareIdRoleVector(array[j], array[j + 1]) > 0) {
          const tmp = array[j];
          array[j] = array[j + 1];
          array[j + 1] = tmp;
        }
      }
    }
    return array;
  }

  public static compareIdRoleVector(a: IdRoleVector, b: IdRoleVector): number {
    if (+a.role > +b.role) {
      return 1;
    } else if (+a.role === +b.role) {
      if (+a.id > +b.id) {
        return 1;
      } else if (+a.id === +b.id) {
        return 0;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  }

  /**
   * @description
   * serializes the object as a JSON string and then deserializes it, effectively creating a deep copy.
   * It should be noted that this method can only "deep copy" plain old data,
   * not complex objects and their prototype.
   */
  public static clone(obj): any {
    return JSON.parse(JSON.stringify(obj));
  }


  public static isBoolean(value): boolean {
    return (typeof value === 'boolean');
  }

  /**
   * @description
   * iter on controls of the formGroup for the current step control
   * and populate an array with all the error messages even form the inner control present on the form
   * @param formGroup FormGroup
   */
  public static getAllErrorMsgFromForm(formGroup: UntypedFormGroup): {error: string}[] {
    const formControlErrors: any[] = [];
    Object.keys(formGroup.controls).forEach(stepControl => {
      if (!!formGroup.get(stepControl).errors) {
        formControlErrors.push(formGroup.get(stepControl).errors);
      } else if (!!(formGroup.get(stepControl) as UntypedFormGroup).controls) {
        Object.keys((formGroup.get(stepControl) as UntypedFormGroup).controls).forEach(innerControl => {
          const INNER_CONTROL = formGroup.get(stepControl).get(innerControl);
          if (!!INNER_CONTROL) {
            if (!!INNER_CONTROL.errors && (!INNER_CONTROL.hasError('error') || !INNER_CONTROL.hasError('required'))) {
                  Object.keys(INNER_CONTROL.errors).forEach(errCode => {
                    const msg = INNER_CONTROL.errors[errCode];
                    if (!this.isBoolean(msg)) {
                      formControlErrors.push({
                        error: msg
                      });
                    }
                  });
            } else if (!!INNER_CONTROL.errors) {
              formControlErrors.push(INNER_CONTROL.errors);
            }
          }
        });
      }
    });

    return formControlErrors;
  }

  /**
   * @description
   * metodo che, a partire dalle definitions,
   * recupera i beneficiari dal form e converte la percentuale (se in formato stringa) in un numero
   */
  public static getBeneficiariesFromForm(beneficiariesDefinitions, formGroup): any[] {
    const subjects = [];
    if (!!beneficiariesDefinitions && beneficiariesDefinitions.length > 0 && !!formGroup && !!formGroup.get('beneficiaries')) {
      beneficiariesDefinitions.forEach(definition => {
        const fgBenef: UntypedFormGroup = formGroup.get('beneficiaries').get('b' + definition.code);
        if (!!fgBenef && !!fgBenef.value && !!fgBenef.value.subjects && fgBenef.value.subjects.length > 0) {
          fgBenef.value.subjects.forEach(sbj => {
            // percentage directive -> il valore è in questo formato "13,43" mentre in request ci va 13.43
            if (!!sbj && !!sbj.value && !!sbj.value.percentage && typeof sbj.value.percentage === 'string') {
              sbj.value.percentage = +sbj.value.percentage.replace(',', '.');
            }
            subjects.push(sbj);
          });
        }
      });
    }
    return subjects;
  }

  public static hasSystemError(errors: PostsalesError[]) {
    const safeErrors = !!errors ? errors : [];
    return safeErrors.filter(error => error.errorId === 'SystemException').length > 0;
  }
}

import { Injectable } from '@angular/core';
import { ApiTranslatorService } from '../api-translator.service';
import { ApiClaim } from '../../model/api/api-claim';
import { ApiParty } from '../../model/api/api-party';
import { ApiTypeOfLoss } from '../../model/api/api-type-of-loss';
import { ApiAppointment } from '../../model/api/api-appointment';

@Injectable({
  providedIn: 'root'
})
export class ApiTranslatorClaimService extends ApiTranslatorService {

  jsonClaimToApiClaim(jsonClaim: any): ApiClaim {
    const apiClaim = new ApiClaim();
    apiClaim.claimNumber = jsonClaim.claimNumber;
    apiClaim.taxBranches = jsonClaim.taxBranches;
    apiClaim.idClaim = jsonClaim.idClaim;
    if (jsonClaim.companyCode == null) {
        apiClaim.aniaCompanyCode = jsonClaim.aniaCompanyCode;
    } else {
        apiClaim.aniaCompanyCode = jsonClaim.companyCode;
    }
    apiClaim.reportNumber = jsonClaim.reportNumber;
    apiClaim.policyNumber = jsonClaim.policyNumber;
    apiClaim.policyType = jsonClaim.policyType;
    apiClaim.idPotentialClaim = jsonClaim.idPotencialClaim;

    if (jsonClaim.claimParties) {
      apiClaim.claimParties = this.jsonClaimPartiesToApiClaimParties(jsonClaim.claimParties);
    }

    return apiClaim;
  }

  jsonClaimPartiesToApiClaimParties(jsonParties: any[]): ApiParty[] {
    const apiParties = [];

    for (const jsonParty of jsonParties) {
      apiParties.push(this.jsonClaimPartyToApiClaimParty(jsonParty));
    }

    return apiParties;
  }

  jsonClaimPartyToApiClaimParty(jsonParties: any): ApiParty {
    const apiParty = new ApiParty();
    apiParty.identifier = jsonParties.identifier;
    apiParty.subject = jsonParties.subject;
    apiParty.role = jsonParties.role;
    if (jsonParties.damageDescriptions) {
      apiParty.damageDescriptions = this.jsonTypesOfLossToApiTypesOfLoss(jsonParties.damageDescriptions);
    }

    return apiParty;
  }

  jsonTypesOfLossToApiTypesOfLoss(jsonTypesOfLoss: any[]): ApiTypeOfLoss[] {
    const apiTypesOfLoss = [];

    for (const jsonTypeOfLoss of jsonTypesOfLoss) {
      apiTypesOfLoss.push(this.jsonTypeOfLossToApiTypeOfLoss(jsonTypeOfLoss));
    }

    return apiTypesOfLoss;
  }

  jsonTypeOfLossToApiTypeOfLoss(jsonTypesOfLoss: any): ApiTypeOfLoss {
    const apiTypeOfLoss = new ApiTypeOfLoss();

    apiTypeOfLoss.assetType = jsonTypesOfLoss.assetType;
    apiTypeOfLoss.damagesDescriptionId = jsonTypesOfLoss.damagesDescriptionId;
    apiTypeOfLoss.causative = jsonTypesOfLoss.causative;
    if (jsonTypesOfLoss.appointmentList) {
      apiTypeOfLoss.appointmentList = this.jsonAppointmentsToApiAppointment(jsonTypesOfLoss.appointmentList);
    }

    return apiTypeOfLoss;
  }

  jsonAppointmentsToApiAppointment(jsonAppointments: any[]): ApiAppointment[] {
    const apiAppointment = [];

    for (const jsonAppointment of jsonAppointments) {
      apiAppointment.push(this.jsonAppointmentToApiAppointment(jsonAppointment));
    }

    return apiAppointment;
  }

  jsonAppointmentToApiAppointment(jsonAppointment: any): ApiAppointment {

    const apiAppointment = new ApiAppointment();

    apiAppointment.appointmentDate = jsonAppointment.appointmentDate;
    apiAppointment.appointmentNumber = jsonAppointment.appointmentNumber;
    apiAppointment.appointmentState = jsonAppointment.appointmentState;
    apiAppointment.expert = jsonAppointment.expert;
    apiAppointment.expertType = jsonAppointment.expertType;

    return apiAppointment;

  }
}

export class MeansOfPaymentConfigInput {
  public paymentConfigInput: PaymentConfigInput;

  constructor() {
    this.paymentConfigInput = new PaymentConfigInput();
  }
}

export class PaymentConfigInput {
  public function: string;
  public nodeId: string;
  public product: string;
  public subjectId: string;

  constructor() {
  }
}

import { Serialized18n } from '@rgi/rx/i18n';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_LIFE_PPEVO_FR: Serialized18n = {
 /* "Il Contraente è una persona": 'Le titulaire est',
 "Data di nascita": 'Date de naissance',
 'La sua consistenza patrimoniale complessiva è:': 'Leur patrimoine total est:',
 'Il suo reddito netto annuo è:': 'Leur revenu net annuel est:',
 'La sua capacità media di risparmio annuo, valutata… degli impegni finanziari già in essere è pari a:': 'Leur capacité moyenne d’épargne annuelle, compte tenu des engagements financiers existants, est de:',
 'I suoi impegni finanziari mensili sono:': 'Leurs engagements financiers mensuels sont:',
 "E' intestatario di un mutuo o di un finanziamento?": 'Sont-ils titulaires d’un prêt hypothécaire ou d’un emprunt?' */
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RePostsalesCancellationSummaryResourceService {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
  }
}

import {SaveProposalResponse} from './../../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map, share} from 'rxjs/operators';
import {ProposalDataRequest} from '../../../models/pc-portfolio-models/proposal-models/proposal-data-request';
import {
  ProposalDataResponse,
  ProposalsApiRespose
} from '../../../models/pc-portfolio-models/proposal-models/proposal-data-response';
import {ProposalDataPutRequest} from '../../../models/pc-portfolio-models/proposal-models/proposal-data-put-request';
import {IssueProposalResponse} from '../../../models/pc-portfolio-models/proposal-models/issue-proposal-response';
import {REST_API_PORTFOLIO_PC_CONF} from '../re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioProposalService {
  private PROPOSALS_PATH = 'proposals/';
  private SAVE_PROPOSAL_PATH = '/save';
  private AUTHORIZATION_PROPOSAL_PATH = '/authorization';
  private ISSUE_PROPOSAL_PATH = '/issue';
  private apiPcPortfolioConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
    this.apiPcPortfolioConf = REST_API_PORTFOLIO_PC_CONF ;

  }

  initProposal(request: ProposalDataRequest): Observable<ProposalDataResponse> {
    return this.http.post<ProposalsApiRespose>(this.getInitProposalUri(), request).pipe(
      map((resp: ProposalsApiRespose) => {
        console.log('initProposal: ', resp);
        return this.mapProposalsApiRespose(resp);
      }),
      share()
    );
  }

  getProposal(proposalId: string): Observable<ProposalDataResponse> {
    return this.http.get<ProposalDataResponse>(this.getProposalSummaryDataUri(proposalId)).pipe(
      share()
    );
  }

  putProposal(proposalId: string, proposalData: ProposalDataPutRequest): Observable<ProposalDataResponse> {
    return this.http.put<ProposalDataResponse>(this.getProposalSummaryDataUri(proposalId), proposalData).pipe(
      share(),
      catchError(error => {
        return this.getProposal(proposalId);
      })
    );
  }

  saveProposal(proposalId: string, proposalRequest: any): Observable<SaveProposalResponse> {
    return this.http.put<SaveProposalResponse>(this.getSaveProposalUri(proposalId), proposalRequest).pipe(
      share()
    );
  }

  saveAuthorization(proposalId: string, proposalRequest: any): Observable<SaveProposalResponse> {
    return this.http.put<SaveProposalResponse>(this.getAuthorizationProposalUri(proposalId), proposalRequest).pipe(
      share()
    );
  }

  issueProposal(proposalId: string, issueProposalRequest: any): Observable<IssueProposalResponse> {
    return this.http.put<IssueProposalResponse>(this.getIssueProposalUri(proposalId), issueProposalRequest).pipe(
      share()
    );
  }

  getIssueProposalUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      this.ISSUE_PROPOSAL_PATH;
  }

  getSaveProposalUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      this.SAVE_PROPOSAL_PATH;
  }

  getAuthorizationProposalUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId +
      this.AUTHORIZATION_PROPOSAL_PATH;
  }

  getProposalSummaryDataUri(proposalId: string): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      this.PROPOSALS_PATH +
      proposalId;
  }

  // TODO: put 'int/proposals' to apiConf constants
  getInitProposalUri(): string {
    return this.apiConf.api.portal.path +
      this.apiPcPortfolioConf.v2 +
      this.apiPcPortfolioConf.path +
      'proposals';
  }

  protected mapProposalsApiRespose(apiResponse: ProposalsApiRespose): ProposalDataResponse {
    const resuls = new ProposalDataResponse();

    resuls.additionalLabels = apiResponse.proposalSummary.additionalLabels;
    resuls.agreement = apiResponse.proposalSummary.agreement;
    resuls.assets = apiResponse.proposalSummary.assets;
    resuls.automaticRenewal = apiResponse.proposalSummary.automaticRenewal;
    resuls.company = apiResponse.proposalSummary.company;
    resuls.contractNumber = apiResponse.proposalSummary.contractNumber;
    resuls.currency = apiResponse.proposalSummary.currency;
    resuls.effectDate = apiResponse.proposalSummary.effectDate;
    resuls.instalmentDates = apiResponse.proposalSummary.instalmentDates;
    resuls.id = apiResponse.id;
    resuls.issuingDate = apiResponse.proposalSummary.issuingDate;
    resuls.managementNode = apiResponse.proposalSummary.managementNode;
    resuls.policyExpiration = apiResponse.proposalSummary.policyExpiration;
    resuls.premium = apiResponse.proposalSummary.premium;
    resuls.premiumAdjustmentConfig = apiResponse.proposalSummary.premiumAdjustmentConfig;
    resuls.minimumPremiumRegulationPercentage = apiResponse.proposalSummary.minimumPremiumRegulationPercentage;
    resuls.product = apiResponse.proposalSummary.product;
    resuls.proposalExpiration = apiResponse.proposalSummary.proposalExpiration;
    resuls.renewal = apiResponse.proposalSummary.renewal;
    resuls.resourceId = apiResponse.proposalSummary.resourceId;

    return resuls;
  }
}

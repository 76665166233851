import {
  Component, EventEmitter,
  Inject,
  Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponentWithAnagModal } from '../../interfaces/component-with-anag-modal';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { SalesNode, Agency } from '../../models/sales-node.model';
import { AnagSubject } from '../../models/subject.model';
import { AnagService } from '../../services/anag.service';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { ClaimReportService } from '../../services/claim-report.service';
import { RoleType } from '../../models/enum/lpc-subjects.enum';

@Component({
  selector: 'lpc-anag-claim-report',
  templateUrl: './anag-claim-report.component.html',
  styleUrls: ['./anag-claim-report.component.css']
})
export class AnagClaimReportComponent
  implements OnInit, OnDestroy, ComponentWithAnagModal {
  @Output() eventPropagation = new EventEmitter<any>();
  @Input() set selectedSubject(subject: AnagSubject) {
    this.anagService.receiveSubjectFromModalX(subject);
  }
  @Input() id: string;

  protected subscriptions: Subscription[] = [];

  public descriptions: SalesNode[] = [];
  public formGroup: UntypedFormGroup;
  public submitted = false;
  public roleCodeToAdd: RoleType = 'anagClaimSubject' as RoleType;

  constructor(
    protected claimReportService: ClaimReportService,
    protected angularCommunication: AngularCommunicationService,
    protected anagService: AnagService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any
  ) {
    this.claimReportService.getAvailableNodes().subscribe(response => {
      this.descriptions = response.agencies.map((y: Agency) => {
        return new SalesNode(y);
      });
    });
  }

  ngOnInit() {
    this.activate();
    this.subscriptions.push(
      this.angularCommunication.communication.subscribe(val => {
        this.eventPropagation.emit(val);
      }),
      this.anagService.modalOpening.subscribe(val => {
        if (!!val.event) {
          this.eventPropagation.emit(val.event);
        }
      })
    );
  }

  private activate() {
    this.initForm();
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({
      subject: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      node: new UntypedFormControl(null, {
        validators: [Validators.required]
      })
    });
  }

  selectChangeHandlerNode(event: any) {
    this.formGroup.get('node').setValue(event.target.value);
  }

  cleanNode() {
    this.formGroup.get('node').setValue(null);
  }

  isPresent() {
    return this.formGroup.get('subject').value;
  }

  deleteSbj(event) {
    this.formGroup.get('subject').setValue(null);
  }

  openAnagSubjectModal() {
    this.anagService.openSubjectModal(this);
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    this.claimReportService.storeSubject(subject);
    this.formGroup
      .get('subject')
      .setValue({ id: subject.objectId, name: subject.nominative });
  }

  clean() {
    this.submitted = false;
    this.resetEvents();
    this.activate();
  }

  openSession() {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.resetEvents();
      const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
      if (sessionService != null) {
        const idPv = this.formGroup.get('node').value;
        const idSbj = this.formGroup.get('subject').value.id;
        this.claimReportService
        .storeSubjectAndNode(idSbj, idPv, sessionService.open('claimListSession', 'home', EMPTY_STR, true));
      }
    }
  }

  ngOnDestroy() {
    this.resetEvents();
  }

  resetEvents() {
    this.subscriptions.forEach(el => el.unsubscribe());
    this.anagService.resetModalOpen();
  }

}

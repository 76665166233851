import {Inject, Injectable} from '@angular/core';
import {PncPostsalesInputData} from '../resources/model/pnc-postsales-input-data';
import {PncPostSalesIntegrationService} from '../services/pnc-postsales-integration.service';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Observable, of} from 'rxjs';
import {PncPsalesBaseState} from '../resources/states/pnc-postsales-state';
import {PNC_POSTSALES_OPERATIONS, PostSalesOperation} from '../api/rgi-pnc-postsales.api';
import {flatten, LoggerFactory} from '@rgi/rx';
import {PNC_POSTSALES_OPERATIONS_TYPES} from '../resources/enums/pnc-postsales-operations-types';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {getRouteData} from '../resources/states/state-utils';

@Injectable({
  providedIn: 'root'
})
export class PncPostSalesOrchestratorService {

  private readonly logger = LoggerFactory();
  private operationsFlowMap: Map<string, string[]> = new Map();
  private sessionTitlesMap: Map<string, string> = new Map();

  constructor(
    protected routingService: RoutingService,
    protected integrationService: PncPostSalesIntegrationService,
    protected translateService: RgiRxTranslationService,
    @Inject(PNC_POSTSALES_OPERATIONS) operations: PostSalesOperation[][],
  ) {

    flatten<PostSalesOperation[]>(operations)
      .forEach(op => {
        this.sessionTitlesMap.set(this.getOperationFlowKey(op.type, op.operationCode), op.sessionTitle);
        const routes = op.steps.map(step => {
          return this.getOperationFlowKey(op.type, op.operationCode) + step.step.routeName;
        });
        this.operationsFlowMap.set(this.getOperationFlowKey(op.type, op.operationCode), routes);
      });

    this.logger.debug(`PncPostSalesOrchestratorService registered routes`, this.operationsFlowMap);
  }

  processOperation(navigationData: PncPostsalesInputData) {
    if (navigationData && !!navigationData.operationType) {
      const opCode = navigationData.currentOperation ? navigationData.currentOperation.code : null;
      const flowKey = this.getOperationFlowKey(navigationData.operationType, opCode);
      if (this.operationsFlowMap.has(flowKey)) {
        const sessionTitle = this.sessionTitlesMap.get(flowKey);
        this.translateService.translate(sessionTitle, {policyNumber: navigationData.policyNumber}).subscribe(title => {
          this.integrationService.navigate(
            this.operationsFlowMap.get(flowKey)[0], navigationData, this.routingService, null, true, title);
        });
      }
    }
  }

  goToNextStep<T extends PncPsalesBaseState>(state: T, activeRoute: ActiveRoute): Observable<T> {
    const currentRoute = activeRoute.route;
    const routes = this.operationsFlowMap.get(this.getOperationFlowKey(state.operationType, state.currentOperation.code));
    const currentIndex = routes.indexOf(currentRoute);
    if (!!routes[currentIndex + 1]) {
      this.integrationService.navigate(routes[currentIndex + 1], getRouteData(state), this.routingService, activeRoute);
    } else {
      this.integrationService.endNavigation(activeRoute.id, state.idParentSession);
    }
    return of(state);
  }

  goToPreviousStep$<T extends PncPsalesBaseState>(state: T, activeRoute: ActiveRoute): Observable<T> {
    const currentRoute = activeRoute.route;
    const routes = this.operationsFlowMap.get(this.getOperationFlowKey(state.operationType, state.currentOperation?.code));
    const currentIndex = routes.indexOf(currentRoute);
    if (!!routes[currentIndex - 1]) {
      this.integrationService.navigate(routes[currentIndex - 1], getRouteData(state), this.routingService, activeRoute);
    } else {
      this.integrationService.closeSession(activeRoute.id);
    }
    return of(state);
  }

  getNextStepRoute<T extends PncPsalesBaseState>(st: T, activeRoute: ActiveRoute): string {
    const routes = this.operationsFlowMap.get(this.getOperationFlowKey(st.operationType, st.currentOperation.code));
    const currentRoute = activeRoute.route;
    const currentIndex = routes.indexOf(currentRoute);
    return routes[currentIndex + 1];
  }

  private getOperationFlowKey(opType: string, opCode: string): string {
    let key = 'pncpsales/' + opType + '/';
    if (opType !== PNC_POSTSALES_OPERATIONS_TYPES.CANCELLATION && opType !== PNC_POSTSALES_OPERATIONS_TYPES.EXCLUSION && !!opCode) {
      key += opCode + '/';
    }
    return key;
  }

  getPreviousRoutes(activeRoute: ActiveRoute): string[] {
    const routeData = activeRoute.getRouteData<PncPostsalesInputData>();
    const operationType = routeData.operationType;
    const operationCode = routeData.currentOperation.code;

    const routes = this.operationsFlowMap.get(this.getOperationFlowKey(operationType, operationCode));
    return routes.slice(0, routes.indexOf(activeRoute.route));
  }
}

import { ServiceInfo } from "../../dsc-shared-model/dsc-model-utility";

export interface FilterCarBodyWork {
  filterCarBodyWork: {
    trusteeType: string;
    typeBodywork: string;
  };
}
export interface DataTable {
  data: CarData[];
  arrayBodyworkTypes: Enum[];
}
export class FormDataModel {
  trusteeType: string = '';
  code: string = '';
  name: string = '';
  surname: string = '';
  businessName: string = '';
  fiscalCode: string = '';
  vatCode: string = '';
  typeBodywork: string = '';
}


export interface CarData {
  carBodywork: {
    availableReplacementAuto: boolean;
    availableExamination: boolean;
    availableVehicleRetirement: boolean;
    vehicleBrandSpecialization: any;
  };
  code: string;
  trusteeType: string;
  driver: {
    objectId: string;
    chkInjury: boolean;
    chkMaterial: any;
    claimsAddress: {
      city: string;
      cityCode: any;
    };
    givenName: string;
    anagSubject: any;
  };
  endDate: string;
  startDate: string;
  objectId: string;
}

export interface OutputTrustee {
  serviceInfo: ServiceInfo;
  listCarBodyWork: CarData[];
}



export interface CarDataMapped {
  code: string;
  name: string;
  nominative: string;
  type: string;
}


export interface Enum {
  codice: string;
  descrizione: string;
 }



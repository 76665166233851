import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose, RgiRxDataTableExpansionModel} from '@rgi/rx/ui';
import {PolicyDetailModalData} from '../../../resources/model/common/quotation/policy-detail-modal-data';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rgi-pnc-policy-detail-modal',
  templateUrl: './pnc-policy-detail-modal.component.html'
})
export class PncPolicyDetailModalComponent implements OnInit, OnModalClose {

  modalClose = new EventEmitter<any>();

  public expansionModel = new RgiRxDataTableExpansionModel([], {
    multiple: true,
    canExpand: (_row) => true,
    canContract: (_row) => true
  });

  constructor(@Optional() @Inject(DIALOG_DATA) public data: PolicyDetailModalData) {
  }

  ngOnInit() {
  }

}

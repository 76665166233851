import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { PersonType, Roles } from '../../models/enum/lpc-subjects.enum';
import { Role, RoleDefinition } from '../../models/postsales-operations-response.model';

@Component({
  selector: 'lpc-roles-step',
  templateUrl: './lpc-roles-step.component.html',
  styleUrls: ['./lpc-roles-step.component.scss']
})
export class LpcRolesStepComponent implements OnInit {

  @Input() roles: Role[] = [];
  @Input() operationCode: string;
  @Input() roleDefinition: RoleDefinition[] = [];

  /**
   * @description boolean input to handle the view of the checkbox for the delegate role,
   * it's valorized by the value of the systemProperty - PrevalorizationDelegate.
   * It's true when the policy holder is an adult and the property is true
   */
  @Input() enableDelegateCheckbox: boolean;
  /**
   * @description boolean input to handle the view of the checkbox for the legal guardian role
   * it's valorized by the value of the systemProperty - PrevalorizationLegalGuardian.
   * It's true when the policy holder is an adult and the property is true
   */
  @Input() enableLegalGuardianCheckbox: boolean;
  /**
   * @description boolean input to handle the view of the checkbox for the third payer role
   * it's valorized by the value of the systemProperty - PrevalorizationThirdPayer.
   * It's true when the policy holder is an adult and the property is true
   */
  @Input() enableThirdPayerCheckbox: boolean;

  @Output() roleEvent = new EventEmitter<any>();

  public getRoleCode(role): string {
    if (role === 'contractor') {
        return Roles.CONTRACTOR;
    }
    if (role === 'effectiveHolder') {
        return Roles.EFFECTIVEHOLDER;
    }
    if (role === 'delegate') {
        return Roles.DELEGATE;
    }
    if (role === 'legalGuardian') {
        return Roles.LEGAL_GUARDIAN;
    }
    if (role === 'thirdPayer') {
        return Roles.THIRDPAYER;
    } else {
        return null;
    }
  }

    constructor(protected  translate: TranslationWrapperService) {
    }

    ngOnInit() {
    }

    get isHolderPresent(): boolean {
        return this.getRolesDataByCodes(this.getRoleCode('contractor')).length === 1;
    }

    getRolesDataByCodes(...codes: string[]): Role[] {
        return this.roles.filter(el => codes.includes(el.role));
    }

    getExcludedRolesDataByCodes(...codes: string[]): Role[] {
        return this.roles.filter(el => !codes.includes(el.role));
    }

    getDefinitionsByCodes(...codes: string[]): RoleDefinition[] {
        return this.roleDefinition.filter(el => codes.includes(el.code));
    }

    getExcludedDefinitionsByCodes(...codes: string[]): RoleDefinition[] {
        return this.roleDefinition.filter(el => !codes.includes(el.code));
    }

    propagateEvent(event, action) {
        this.roleEvent.emit({eventName: action, data: event});
    }

    public checkRoles(): string[] {
        return []; // utilizzato in EXT
    }

    public enableCheckbox(roleCode: string): boolean {
        const contractor = this.roles.find(role => role.role === Roles.CONTRACTOR);
        if (roleCode === Roles.DELEGATE && !!contractor) {
            return this.enableDelegateCheckbox && contractor.adult && contractor.personType === PersonType.PHYSICAL;
        }
        if (roleCode === Roles.THIRDPAYER && !!contractor) {
            return this.enableThirdPayerCheckbox && contractor.adult && contractor.personType === PersonType.PHYSICAL;
        }
        if (roleCode === Roles.LEGAL_GUARDIAN && !!contractor) {
            return this.enableLegalGuardianCheckbox && contractor.adult && contractor.personType === PersonType.PHYSICAL;
        }
        return false;
    }

}

import {State} from '@rgi/rx/state';

export class AuthorizationCardStateStart extends State {

}

export class AuthorizationCardStateList extends State {

}

export class AuthorizationCardStateDetail extends State {

}


import {Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {
  NotificationService,
  PPEVONavigationBtnDirective,
} from '@rgi/ng-passpropro-core';
import {FormSurveyEditComponent} from '../form-survey-edit/form-survey-edit.component';
import {QuestionnaireI} from '../../models/questionnaire';
import {QuestionnaireService} from '@rgi/ng-passpropro/questionnaire';
import {QuestionnaireComponent} from '@rgi/ng-passpro';
import { SurveyEditState } from '../../state/models/survey-edit-state';
import { SurveyEditStateManagerService } from '../../state/survey-edit-state-manager.service';
import {RoutableComponent} from "@rgi/rx/router";
import {ModalComponent} from "@rgi/rx/ui";
import {RgiRxTranslationService} from "@rgi/rx/i18n";

@Component({
  selector: 'ng-passpropro-survey-edit',
  templateUrl: './survey-edit.component.html',
  providers: [
    {provide: DatePipe, useClass: DatePipe}
  ]
})
export class SurveyEditComponent extends RoutableComponent implements OnInit {

  st: SurveyEditState;
  notes: String

  @ViewChild(QuestionnaireComponent) questionnaireComp: QuestionnaireComponent;
  @ViewChild(FormSurveyEditComponent) formSurveyEditComponent: FormSurveyEditComponent;


  constructor(private _questionnaireService: QuestionnaireService,
              private notificationService: NotificationService,
              private translateService: RgiRxTranslationService,
              private _stMng: SurveyEditStateManagerService<SurveyEditState>)
  {
    super();
  }


  ngOnInit() {
    this._stMng.actionInit();
    this._stMng.getState$().subscribe( st =>  this.st = st );
  }


  onSelectQuestionnaire(questionnaire:QuestionnaireI){
    this._stMng.actionSelectQuestionnaire(questionnaire);
  }


  onBtnClick(params:{btn:PPEVONavigationBtnDirective, event: Event}){
    this._stMng.actionNavigationClick(params.btn.btnId, this.validate);
  }


  onClickSectionInfoNotes(sectionId:number, notesModal:ModalComponent){
    this._questionnaireService.getSectionNotes(sectionId).subscribe(
      (text) => {
        this.notes = text;
        notesModal.open();
      }
    )
  }


  onClickQuestionInfoNotes(questionId:number, notesModal:ModalComponent){
    this._questionnaireService.getQuestionNotes(questionId).subscribe(
      (text) => {
        this.notes = text;
        notesModal.open();
      }
    )
  }


  protected validate = ():boolean => {

    if (this.formSurveyEditComponent) {
      if( !this.formSurveyEditComponent.isValid() ){
        this.formSurveyEditComponent.showErrors();
        this.showValidationError();
        return false;
      }
    }

    if (!this.questionnaireComp.validate()) {
      this.showValidationError();
      return false;
    }
    return true;
  }


  protected showValidationError(){
    this.translateService.translate("NG_PASSPROPRO_SURVEY.form-validation-error").subscribe((res:string)=>{
      this.notificationService.push({
        body: res,
        status: "danger",
        delay: 5000
      });
    });

  }


  /**
   * pipe per ngFor per mantenere l'ordinamento naturale delle chiavi dell'oggetto che si sta ciclando con il pipe keyvalue (di angular)
   */
  preserveKeysOrder = () => 0;


}

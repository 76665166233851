import {Component, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {AuthorizationsStatesService} from '../services/authorizations-states.service';
import {AuthorizationsActions} from '../resources/constants/authorizations-actions';
import {Authorization} from '../resources/models/authorization';
import {AuthorizationsSearchService} from '../services/authorizations-search.service';
import {GenericElement} from '../resources/models/generic-element';
import {AuthorizationsStates} from '../resources/constants/authorizations-states';
import {CommonService} from '../routing/common.service';
import {Contract} from '../resources/models/contract';
import {Document, DocumentFile, Documents, DocumentsService} from '@rgi/portal-ng-common';
import {Actions} from '../resources/constants/actions';
import {Subsystem} from '../resources/constants/subsystem';
import {RequestTypeEnum} from '../resources/constants/request-type-enum';
import {ContractData, LifeSavePolicyResponse} from '../resources/models/life.model';
import {AuthorizationsRoutingService} from '../routing/authorizations-routing.service';
import {PolicyType} from '../resources/constants/policy-type';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ActiveRoute} from '@rgi/rx/router';
import {StateManagedComponent} from '@rgi/rx/state';
import {AuthorizationCardStateDetail} from '../authorization-card-state/authorization-card-state';
import {AuthorizationCardStateManagerDetail} from '../authorization-card-state/authorization-card-statemanager-detail';
import {NotifierService} from "@rgi/portal-ng-core";
import {DIALOG_DATA, ModalService, OnModalClose} from "@rgi/rx/ui";
import {RgiRxUserService} from "@rgi/rx/auth";
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'ac-authorizations-detail',
    templateUrl: './authorizations-detail.component.html',
    host: {
        class: 'rgi-authorizations-style'
    },
    providers: [AuthorizationsStatesService]
})
export class AuthorizationsDetailComponent extends StateManagedComponent<AuthorizationCardStateDetail, AuthorizationCardStateManagerDetail> implements OnInit, OnDestroy {

    @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

    authorizationDetail: any;
    authorizationRequestData: Array<any> = new Array<any>();
    authorizationRequestDataRows: GenericElement[][];
    statusData: Array<any> = new Array<any>();
    statusDataRows: GenericElement[][];
    requestOperationData: Array<any> = new Array<any>();
    requestOperationDataRows: GenericElement[][];
    proposalData: Array<any> = new Array<any>();
    proposalDataRows: GenericElement[][];
    contractId: string;
    contract: Contract;
    showDocuments: boolean;
    buttons: string[] = [];

    documentsValidated = true;
    documents: Documents;
    isEditable = false;
    writePermission: any;
    isSubCard = false;

    private readonly PRODUCT_TYPE_AUTO = '8';
    private isRefresh = false;

    constructor(protected stateService: AuthorizationsStatesService,
                protected manager: AuthorizationCardStateManagerDetail,
                public activeRoute: ActiveRoute,
                protected authorizationsSearchService: AuthorizationsSearchService,
                protected routingService: AuthorizationsRoutingService,
                protected commonService: CommonService,
                protected documentsService: DocumentsService,
                protected translate: RgiRxTranslationService,
                protected notifierService: NotifierService,
                protected modalService: ModalService,
                protected userService: RgiRxUserService,
                @Inject('showDocuments') showDocumentsEnabled?: boolean) {
        super(manager);
        this.showDocuments = showDocumentsEnabled;
    }

    ngOnInit() {
        this.buttons = [];
        const user = this.userService.getUser();
        if (user && user.enabledFeatures) {
            this.writePermission = user.enabledFeatures.includes('AU');
        }

        if (!this.authorizationsSearchService.isSubCard()) {
            this.buttons.push('_AUTH_._BACK');
        }

        this.stateService.setCurrentState(AuthorizationsStates.DETAIL);
        const routeData = this.activeRoute.getRouteData();
        if(routeData && routeData['id']) {
            this.isSubCard = true;
            // if i'm releading the page i need to use updated data
            if (!this.isRefresh) {
                this.authorizationDetail = routeData;
            } else {
                this.authorizationDetail = this.authorizationsSearchService.getAuthorization();
            }
        } else {
            this.authorizationDetail = this.authorizationsSearchService.getAuthorization();
        }
        // eslint-disable-next-line max-len
        this.buttons = !!this.authorizationDetail.actions ? this.buttons.concat(this.authorizationDetail.actions) : this.buttons;
        this.populateRequestData();
        this.populateStatusData();
        this.populateRequestOperationData();
        this.populateProposalData();
        this.loadContract();
    }

    refreshComponent(authorization?: {changedAuthorizationStatus: any, authorization: Authorization}) {
        // RELOAD AUTH
        this.authorizationsSearchService.getAuthorizationDetail(this.authorizationDetail).subscribe(
            (item) => {
                this.isRefresh = true;
                this.authorizationsSearchService.setAuthorization(item);
                this.resetAll();
                this.ngOnInit();
            },
            (err) => {
                console.log('Reload auth FAILED');
            });
    }

    resetAll() {
        this.authorizationDetail = null;
        this.authorizationRequestData = new Array<any>();
        this.authorizationRequestDataRows = new Array<Array<GenericElement>>();
        this.statusData = new Array<any>();
        this.statusDataRows = new Array<Array<GenericElement>>();
        this.requestOperationData = new Array<any>();
        this.requestOperationDataRows = new Array<Array<GenericElement>>();
        this.proposalData = new Array<any>();
        this.proposalDataRows = new Array<Array<GenericElement>>();
        this.contractId = null;
        this.contract = null;
        this.showDocuments = null;
        this.documentsValidated = true;
        this.documents = null;
        this.isEditable = false;
        /* this.modalBody = null;
        this.modalTitle = null;
        this.showModal = null; */
    }

    populateRequestData() {
        if (this.authorizationDetail) {
            if (this.authorizationDetail.requestCode) {
                this.authorizationRequestData.push(new GenericElement('_AUTH_._CODE', this.authorizationDetail.requestCode));
            }
            if (this.authorizationDetail.workflowState) {
                this.authorizationRequestData.push(new GenericElement('_AUTH_._STATUS',
                    this.authorizationDetail.workflowState.description));
            }
            if (this.authorizationDetail.requestDate) {
                this.authorizationRequestData.push(new GenericElement('_AUTH_._REQUEST_DATE',
                    String(this.authorizationDetail.requestDate), true));
            }
            if (this.authorizationDetail.derogationLevel) {
                this.authorizationRequestData.push(new GenericElement('_AUTH_._DEROGATION_LEVEL',
                    this.authorizationDetail.derogationLevel));
            }
            if (this.authorizationDetail.requestUser) {
                this.authorizationRequestData.push(new GenericElement('_AUTH_._REQUEST_USER',
                    this.authorizationDetail.requestUser.description));
            }
            if (this.authorizationDetail.company) {
                this.authorizationRequestData.push(new GenericElement('Company:',
                    this.authorizationDetail.company.description));
            }
            if (this.authorizationDetail.requestNode) {
                this.authorizationRequestData.push(new GenericElement('Node:',
                    this.authorizationDetail.requestNode.description));
            }

            // TODO: indirizzo??? Non c'è nella response
            // this.authorizationRequestData.push(new GenericElement('Address:', 'Not available'));

            if (this.authorizationDetail.subsystem) {
                let label;
                this.translate.translate('_AUTH_._SUBSYSTEM').subscribe(text => label = text);
                this.authorizationRequestData.push(new GenericElement(label + ':', this.authorizationDetail.subsystem.description));
            }
        }
        this.authorizationRequestDataRows = new Array<Array<GenericElement>>(Math.ceil(this.authorizationRequestData.length / 2));
        this.loadDataRows(this.authorizationRequestData, this.authorizationRequestDataRows);
    }

    populateStatusData() {
        if (this.authorizationDetail) {
            let label;
            if (this.authorizationDetail.lastEvent) {
                this.translate.translate('_AUTH_._LAST_ACTION').subscribe(text => label = text);
                this.statusData.push(new GenericElement(label, this.authorizationDetail.lastEvent.description));
            }
            if (this.authorizationDetail.competentLevel) {
                this.translate.translate('_AUTH_._COMPETENCE_LEVEL').subscribe(text => label = text);
                this.statusData.push(new GenericElement(label, this.authorizationDetail.competentLevel.description));
            }
            if (this.authorizationDetail.lastActionDate) {
                this.translate.translate('_AUTH_._LAST_ACTION_DATE').subscribe(text => label = text);
                this.statusData.push(new GenericElement(label, String(this.authorizationDetail.lastActionDate), true));
            }
            if (this.authorizationDetail.state) {
                this.translate.translate('_AUTH_._LAST_ACTION_DATE').subscribe(text => label = text);
                this.statusData.push(new GenericElement(label, this.authorizationDetail.state.description), true);
            }
            if (this.authorizationDetail.priority) {
                this.translate.translate('_AUTH_._PRIORITY').subscribe(text => label = text);
                this.statusData.push(new GenericElement(label, this.authorizationDetail.priority.description));
            }
            if (this.authorizationDetail.note) {
                this.translate.translate('_AUTH_._NOTES').subscribe(text => label = text);
                this.statusData.push(new GenericElement(label, this.authorizationDetail.note));
            }
        }
        this.statusDataRows = new Array<Array<GenericElement>>(Math.ceil(this.statusData.length / 2));
        this.loadDataRows(this.statusData, this.statusDataRows);
    }

    populateRequestOperationData() {
        let label;
        if (this.authorizationDetail) {
            if (this.authorizationDetail.operation) {
                this.translate.translate('_AUTH_._OPERATION').subscribe(text => label = text);
                this.requestOperationData.push(new GenericElement(label, this.authorizationDetail.operation));
            }
            if (this.authorizationDetail.effectiveDate) {
                this.translate.translate('_AUTH_._EFFECTIVE_DATE').subscribe(text => label = text);
                this.requestOperationData.push(new GenericElement(label, String(this.authorizationDetail.effectiveDate), true));
            }
            if (this.authorizationDetail.operationNote) {
                this.translate.translate('_AUTH_._NOTES').subscribe(text => label = text);
                this.requestOperationData.push(new GenericElement(label, this.authorizationDetail.operationNote));
            }
        }
        this.requestOperationDataRows = new Array<Array<GenericElement>>(Math.ceil(this.requestOperationData.length / 2));
        this.loadDataRows(this.requestOperationData, this.requestOperationDataRows);
    }

    populateProposalData() {
        if (this.authorizationDetail) {
            let label;
            if (this.authorizationDetail.productType) {
                this.translate.translate('_AUTH_._PRODUCT_TYPE').subscribe(text => label = text);
                this.proposalData.push(new GenericElement(label, this.authorizationDetail.productType.description));
            }
            if (this.authorizationDetail.product) {
                this.translate.translate('_AUTH_._PRODUCT').subscribe(text => label = text);
                this.proposalData.push(new GenericElement(label, this.authorizationDetail.product.description));
            }
            if (this.authorizationDetail.subscriber) {
                this.translate.translate('_AUTH_._SUBSCRIBER').subscribe(text => label = text);
                this.proposalData.push(new GenericElement(label, this.authorizationDetail.subscriber.description));
            }
            if (this.authorizationDetail.policyType && this.authorizationDetail.policyType.description) {
                this.proposalData.push(new GenericElement('_AUTH_._CONTRACT_TYPE', this.authorizationDetail.policyType.description));
            }
            if (!!this.authorizationDetail.product && !!this.authorizationDetail.product.description && !!this.authorizationDetail.plate) {
                if (this.authorizationDetail.policyType && this.authorizationDetail.policyType.code !== '3') {
                    this.proposalData.push(new GenericElement('_AUTH_._DESCRIPTION', this.authorizationDetail.product.description));
                } else {
                    this.proposalData.push(new GenericElement('_AUTH_._DESCRIPTION', this.authorizationDetail.plate));
                }
            }
            if (!!this.authorizationDetail.masterPolicyNumber || !!this.authorizationDetail.masterProposalNumber) {
                if (this.authorizationDetail.masterPolicyNumber) {
                    this.translate.translate('_AUTH_._PM_POLICY_NUMBER').subscribe(text => label = text);
                    this.proposalData.push(new GenericElement('_AUTH_._REFERENCE'
                        , label.concat(' ').concat(this.authorizationDetail.masterPolicyNumber)));
                } else {
                    this.translate.translate('_AUTH_._PM_PROPOSAL_NUMBER').subscribe(text => label = text);
                    this.proposalData.push(new GenericElement('_AUTH_._REFERENCE'
                        , label.concat(' ').concat(this.authorizationDetail.masterProposalNumber)));
                }
            }
        }
        this.proposalDataRows = new Array<Array<GenericElement>>(Math.ceil(this.proposalData.length / 2));
        this.loadDataRows(this.proposalData, this.proposalDataRows);
    }

    loadDataRows(objData: Array<any>, objRow: GenericElement[][]) {
        const staticDataNum = objData.length;

        if (staticDataNum === 0) {
            return;
        }

        let i: number;
        let j = 0;

        for (i = 0; i < staticDataNum; i++) {
            if (i !== 0 && i % 2 === 0) {
                j++;
            }
            if (!objRow[j]) {
                objRow[j] = new Array();
            }
            objRow[j].push(objData[i]);
        }

    }

    genericElementsTrackByFn(index, genericElement: GenericElement) {
        return genericElement.label;
    }

    private loadContract() {
        if (this.authorizationDetail.subsystem.code === Subsystem.LIFE) {
            this.setLifeContractId(this.authorizationDetail.proposalNumber);
        } else {
            this.commonService.loadContract(this.authorizationDetail.proposalNumber).subscribe(
                (data) => {
                    if (data?.id) {
                        this.contractId = data.id;
                        this.contract = data;

                        if (this.showDocuments) {
                            this.loadDocuments();
                        }
                    }
                },
                (err) => {
                    console.log('Contract not found');
                });
        }

    }

  protected setLifeContractId(proposalNumber: string) {
    this.commonService.getLifePolicyId(proposalNumber).subscribe(
      (resp) => {
        this.contractId = resp.policyId;
      },
      (err) => {
        console.log('Contract not found');
      });
  }

    private loadDocuments() {

        this.documentsService.getDocuments(this.contractId).subscribe((documents: Documents) => {
                this.documents = documents;

                this.documents.inputDocuments.forEach((doc: Document) => {
                    if (doc.toBeValidated) {
                        doc.files.forEach((file: DocumentFile) => {
                            if (file.validate !== true) {
                                this.documentsValidated = false;
                            }
                        });
                    }
                });
            });
    }

    private removeContract() {
        this.commonService.removeContract(this.contractId).subscribe();
    }

    ngOnDestroy() {
        this.removeContract();
        this.stateService.resetState(this.activeRoute.id);
    }

    verifyDocuments(event: any) {
        this.documentsValidated = event.validated;
    }

    back(event?: {changedAuthorizationStatus: any, authorization: Authorization}) {
        const authFilter = this.authorizationsSearchService.getAuthorizationFilter();
        this.authorizationsSearchService.getAuthorizationsList(authFilter).subscribe(authList => {
            if (!!authList) {
                this.authorizationsSearchService.setFilteredAuthorizations(authList);
            }
            if (event?.changedAuthorizationStatus) {
                this.refreshComponent(event);
            } else {
                this.removeContract();
                this.stateService.nextState(this.activeRoute.id, this.navigation, AuthorizationsActions.BACK);
            }
        },
        (err) => {
            throw new Error(err);
        });
    }

    private cancelRequestResponseOk(resp: any) {
        if (resp.updatedSuccessfully || resp.outcome.outcome.codice === '0') {
            if (!!this.authorizationDetail.requestType.id && +this.authorizationDetail.requestType.id === 2) {
                // TODO translations
                this.openModalWithMessage('La richiesta di autorizzazione e la proposta sono state annullate correttamente', 'back');
            } else {
                // TODO translations
                this.openModalWithMessage('La richiesta di autorizzazione è stata annullata correttamente', 'back');
            }
        } else {
            if (!!resp.errorMessage) {
                // TODO translations
                this.openModalWithMessage(resp.errorMessage, null);
            } else {
                // TODO translations
                this.openModalWithMessage('Errore nel servizio annulla proposta', null);
            }
        }
    }

    doAction(action: string) {

        if (this.authorizationDetail.subsystem.code === Subsystem.LIFE) {
            this.doActionLife(action);
        } else {
            switch (action) {
                case Actions.BACK: {
                    this.back();
                    break;
                }
                case Actions.BUY: {
                    this.authorizationsSearchService.getContractOrigin(this.authorizationDetail.proposalNumber,
                        null).subscribe(resp => {
                        if (resp && resp.origin && resp.origin === 'NewMotorFlow') {
                            this.manager.openNMFMandatoryDataSession(this.authorizationDetail.proposalNumber, null, false, 'mandatorydata', null);
                        }else if ( resp?.origin === 'NewPortalReFlow') {
                            this.manager.openNewReFlowSessionIssue(this.authorizationDetail);
                        } else {
                            this.defaultResolution(action);
                        }
                    });
                    break;
                }
                case Actions.CONFIRM_VARIATION:
                case Actions.VARIATION_SUMMARY: {
                    this.authorizationsSearchService.getContractOrigin(this.authorizationDetail.proposalNumber,
                        null).subscribe(resp => {
                        if (resp && resp.origin && resp.origin === 'NewMotorFlow') {
                            this.manager.openNMFAuthorizationSession(false, 'mandatorydata', this.authorizationDetail);
                        } else {
                            this.defaultResolution(action);
                        }
                    });
                    break;
                }
                case Actions.MODIFY_PROPOSAL: {
                    switch (this.authorizationDetail.policyType.code) {
                        case PolicyType.INDIVIDUAL: {
                            if (this.authorizationDetail.productType.code === '8') {
                                this.authorizationsSearchService.getContractOrigin(
                                    this.authorizationDetail.proposalNumber, null).subscribe(resp => {

                                    if (this.authorizationDetail.productType.code === this.PRODUCT_TYPE_AUTO &&
                                        resp?.origin === 'NewMotorFlow') {
                                        this.manager.openNMFSession(this.authorizationDetail);
                                    } else if ( resp?.origin === 'NewPortalReFlow') {
                                        this.manager.openNewReFlowSession(this.authorizationDetail);
                                    } else {
                                        this.manager.openPassPortalStandardSession(this.contractId);
                                    }
                                });
                            } else {
                                this.manager.openPassPortalStandardSession(this.contractId);
                            }
                            break;
                        }
                        case PolicyType.MASTER: {
                            this.manager.openGroupPolicySession(this.authorizationDetail);
                        }
                    }


                    break;
                }
                case Actions.CANCEL: {
                    if (String(this.authorizationDetail.requestType.id) === RequestTypeEnum.AUTORIZZA) {
                        this.authorizationsSearchService.cancelRequestDamage(this.authorizationDetail.proposalNumber).subscribe((resp) => {
                            this.cancelRequestResponseOk(resp);
                        }, () => {
                            // TODO translations
                            this.openModalWithMessage('ERRORE SERVIZIO DI ANNULLO PROPOSTA', null);
                        });
                    } else {
                        this.authorizationsSearchService.cancelRequest(this.authorizationDetail.id).subscribe((resp) => {
                            this.cancelRequestResponseOk(resp);
                        }, () => {
                            // TODO translations
                            this.openModalWithMessage('ERRORE SERVIZIO DI ANNULLO PROPOSTA', null);
                        });
                    }
                    break;
                }
                default: {
                    this.defaultResolution(action);
                }
            }
        }
    }

    private defaultResolution(action) {
        this.manager.openDefaultResolution(action, this.contract, this.authorizationDetail);
    }

    doActionLife(action: string) {
        switch (action) {
            case Actions.BACK: {
                this.back();
                break;
            }
            case Actions.CANCEL: {
                this.authorizationsSearchService.cancelRequest(this.authorizationDetail.id).subscribe((resp) => {
                    this.cancelRequestResponseOk(resp);
                }, (err) => {
                    // TODO translations
                    this.openModalWithMessage('ERRORE SERVIZIO DI ANNULLO PROPOSTA', null);
                });
                break;
            }
            case Actions.MODIFY_PROPOSAL: {
                this.authorizationsSearchService.getLifeProposal(this.contractId).subscribe((response: any) => {
                        response.output.proposal.authorizationId = this.authorizationDetail.id;
                        let title = '';
                        this.translate.translate('_AUTH_._MODIFY_AUTHORIZATION_LABEL').subscribe(text => title = text);
                        this.manager.modifyProposalLife(response.output, this.contract, title, this.activeRoute.id);

                    },
                    (err) => {
                        // TODO translations
                        this.openModalWithMessage('Errore nel servizio di recupero proposta', null);
                    });
                const sub = this.notifierService.onNotify().subscribe((msg: { id: string }) => {
                    sub.unsubscribe();
                    if (msg.id === 'LIFE_ISSUE_MODIFY_AUTH_OK') {
                        this.refreshComponent();
                    } else if (msg.id === 'LIFE_ISSUE_MODIFY_AUTH_KO') {
                        console.error('LIFE MODIFY AUTH KO');
                    }
                });
                break;
            }
            case Actions.SAVE_PROPOSAL: {
                if (this.authorizationDetail.subsystem.code === Subsystem.LIFE) {
                    this.saveProposalFromAuthLife();
                    const sub = this.notifierService.onNotify().subscribe((msg: { id: string }) => {
                        sub.unsubscribe();
                        if (msg.id === 'LIFE_ISSUE_OK') {
                            this.refreshComponent();
                        } else if (msg.id === 'LIFE_ISSUE_KO') {
                            console.log('LIFE ISSUE KO');
                        }
                    });
                }
                break;
            }
            case Actions.SAVE_POLICY: {
                if (this.authorizationDetail.subsystem.code === Subsystem.LIFE) {
                    this.savePolicyFromAuthLife();
                    const sub = this.notifierService.onNotify().subscribe((msg: { id: string }) => {
                        sub.unsubscribe();
                        if (msg.id === 'LIFE_ISSUE_OK') {
                            this.refreshComponent();
                        } else if (msg.id === 'LIFE_ISSUE_KO') {
                            console.log('LIFE ISSUE KO');
                        }
                    });
                }
                break;
            }
            case Actions.CONFIRM_VARIATION: {
                this.authorizationsSearchService
                    .lifeConfirmVariation(this.contractId, this.authorizationDetail.id)
                    .subscribe((resp: any) => {
                        /* lifePolicyNumber: string, lifeDamageProduct: boolean, damagePolicyNumber: string, errors: string[] */
                        if (resp.errors != null && resp.errors.length > 0) {
                            // TODO translations
                            this.openModalWithMessage(resp.errors[0].errorMessage, null);
                        } else {
                            // TODO: Aprire il flusso auth con la pagina delle stampe
                            this.refreshComponent();
                            // TODO translations ##REFACOTR##
                            this.openModalWithMessage('_AUTH_._OPERATION_CONFIRMED', null);
                        }
                        this.manager.confirmVariationLife(this.authorizationDetail, this.contract, this.activeRoute.id);
                    });
                break;
            }
            case Actions.MODIFY_VARIATION: {
                let title = '';
                this.translate.translate('_AUTH_._MODIFY_AUTHORIZATION_LABEL').subscribe(text => title = text);

                this.manager.modifyVariationLife(this.authorizationDetail, title, this.activeRoute.id);

                const sub = this.notifierService.onNotify().subscribe((msg: { id: string }) => {
                    sub.unsubscribe();
                    if (msg.id === 'MODIFY_VARIATION_OK') {
                        this.refreshComponent();
                    } else if (msg.id === 'MODIFY_VARIATION_KO') {
                        console.log('MODIFY_VARIATION');
                    }
                });
                break;
            }
            default: {
                this.manager.openDefaultResolution(action, this.contract, this.authorizationDetail);
            }
        }
    }

    saveProposalFromAuthLife() {
        this.authorizationsSearchService
            .lifeAuthsaveProposal(this.contractId, this.authorizationDetail.requestUser.description, this.authorizationDetail.id)
            .subscribe((resp: any) => {
                if (resp.errorMessages != null && resp.errorMessages.length > 0) {
                    // TODO translations
                    this.openModalWithMessage('Errore nel servizio di salvataggio proposta', null);
                } else {
                    this.authorizationsSearchService.getLifeProposal(this.contractId).subscribe((prop: any) => {
                        prop.output.proposal.authorizationId = this.authorizationDetail.id;
                        this.emitSavePolEvent(prop.output);
                    });
                }
            });
    }

    savePolicyFromAuthLife() {
        this.authorizationsSearchService
            .lifeAuthsaveProposal(this.contractId, this.authorizationDetail.requestUser.description, this.authorizationDetail.id)
            .subscribe((resp: any) => {
                if (resp.errorMessages != null && resp.errorMessages.length > 0) {
                    // TODO translations
                    this.openModalWithMessage('Errore nel servizio di salvataggio proposta', null);
                } else {
                    this.authorizationsSearchService
                        .lifeAuthsavePolicy(this.contractId, this.authorizationDetail.requestUser.description, this.authorizationDetail.id)
                        .subscribe((r: LifeSavePolicyResponse) => {
                            if (r.errorMessages != null && r.errorMessages.length > 0) {
                                // TODO translations
                                this.openModalWithMessage('Errore nel servizio di salvataggio polizza', null);
                            } else {
                                this.authorizationsSearchService.getLifeProposal(this.contractId).subscribe((prop: any) => {
                                    /* let proposalFixed = {
                                      proposal: {
                                        authorizationId: this.authorizationDetail.id,
                                        contractData: this.getContractData(r),
                                        installment: [
                                          {movementId: r.lifeMovementId}
                                        ]
                                      },
                                      quote: {
                                        productID: r.productId,
                                        product: {
                                          operationCode: r.operationCode
                                        },
                                        effectiveDate: r.effectiveDate
                                      }
                                    }; */
                                    prop.output.proposal.authorizationId = this.authorizationDetail.id;
                                    prop.output.proposal.contractData = this.getContractData(r);
                                    prop.output.proposal.installment = [{movementId: r.lifeMovementId}];
                                    prop.output.quote.productID = r.productId;
                                    let title = '';
                                    this.translate.translate('_AUTH_._MODIFY_AUTHORIZATION_LABEL').subscribe(text => title = text);
                                    this.manager.goToLifeIssueResult(prop.output, title, this.activeRoute.id);
                                });
                            }
                        });
                }
            });
    }

    getContractData(data: LifeSavePolicyResponse): ContractData[] {
        return [{
            contractId: data.lifePolicyId,
            objectId: null,
            policyNumber: data.lifePolicyNumber,
            proposalNumber: data.proposalNumber
        },
            {
                contractId: data.damagePolicyId,
                objectId: null,
                policyNumber: data.damagePolicyNumber,
                proposalNumber: null
            }];
    }

    emitSavePolEvent(prop: any) {
        let title = '';
        this.translate.translate('_AUTH_._CONFIRM_PROP_POL_AUTH').subscribe(text => title = text);
        this.manager.goToLifeIssueResult(prop, title, this.activeRoute.id);
    }

    openModalWithMessage(systemMessage, callback) {
        const data: any = {
            systemMessage: systemMessage
        }

        const {modal, component} = this.modalService.openComponent(AuthModalDialogCancelComponent, data);
        modal.onClose.subscribe(event => {
            if ('ok' === event && callback) {
                if (callback === 'back') {
                    this.back();
                }
            }
        });
    }

    transformButtonLabelIfNecessary(action: string) {
        switch (action) {
            case Actions.BUY: {
                return 'Issue';
            }
            case Actions.CANCEL: {
                return '_AUTH_._CANCEL_REQUEST';
            }
            case Actions.SAVE_POLICY: {
                return '_AUTH_._ISSUE_POLICY';
            }
            case Actions.SAVE_PROPOSAL: {
                return '_AUTH_._ISSUE_PROPOSAL';
            }
            case Actions.CONFIRM_VARIATION: {
                return '_AUTH_._CONFIRM_VARIATION';
            }
            case Actions.MODIFY_VARIATION: {
                return '_AUTH_._MODIFY_VARIATION';
            }
            case Actions.MODIFY_PROPOSAL: {
                return '_AUTH_._MODIFY_PROPOSAL';
            }
            default: {
                return action;
            }
        }
    }

}

@Component({
    selector: 'ui-modal-dialog-cancel',
    template: `
        <rgi-rx-panel>
            <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()"></rgi-rx-panel-header>
            <rgi-rx-panel-footer>
                <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose.emit()">close</button>
                <button class="rgi-ui-btn rgi-ui-btn-warning" (click)="modalClose.emit('ok')">ok</button>
            </rgi-rx-panel-footer>
            <p>{{ data.systemMessage }}</p>
        </rgi-rx-panel>
    `
})
export class AuthModalDialogCancelComponent implements OnModalClose {
    modalClose = new EventEmitter<any>();

    data: any

    constructor(@Optional() @Inject(DIALOG_DATA) data: any) {
        this.data = data;
    }

}

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent, RoutingService } from '@rgi/portal-ng-core';
import { ApiElaborationRequestFilter } from '../../api/api-elaboration-request-filter';
import { ElaborationRequest } from '../../dto/elaboration-request';
import { ElaborationRequestDetails } from '../../dto/elaboration-request-details';
import { ElaborationRequestService } from '../../elaboration-request.service';
import { ElaborationRequestDetailComponent } from '../elaboration-request-detail/elaboration-request-detail.component';


@Component({
  selector: 'claims-elaboration-request-list',
  templateUrl: './elaboration-request-list.component.html',
  styleUrls: ['./elaboration-request-list.component.scss']
})
export class ElaborationRequestListComponent extends CardComponent implements OnInit, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  elaborationRequestsList: ElaborationRequest[] = [];
  elaborationRequestFilter: ApiElaborationRequestFilter;
  totalPages: number;
  sessionServiceInject: any;
  arrPage: number[] = [];
  errorMessage: string;
  emitter: any;
  currentPage: number;
  routes: any = null;

  set elaborationRequests(elaborationRequest: ElaborationRequest[]) {
    this.elaborationRequestsList = elaborationRequest;
  }

  get elaborationRequests() {
    return this.elaborationRequestsList;
  }

  constructor(
    @Inject('sessionService') sessionServiceInject: any,
    @Inject('eventService') private eventService: any,
    @Inject('authService') private authService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private elaborationRequestService: ElaborationRequestService,
    private routingService: RoutingService,
  ) {
    super(cardComponentService, sessionService);
    this.sessionServiceInject = sessionServiceInject;
    this.emitter = eventService;
    this.routes = {
      detail: ElaborationRequestDetailComponent
    };
  }

  ngOnInit(): void {
    this.elaborationRequestsList = this.elaborationRequestService.getSessionElaborationRequests(this.card.id);
    this.elaborationRequestFilter = this.elaborationRequestService.getSessionElaborationRequestFilter(this.card.id);
  }

  hasResult() {
    if (this.elaborationRequestsList && this.elaborationRequestsList.length > 0) {
      return true;
    }
    return false;
  }

  back() {
    const session = this.getCurrentSession();
    this.elaborationRequestService.setSessionElaborationRequests(session.idSession, null);
    this.sessionServiceInject.remove(session.idSession);
  }

  getCurrentSession() {
    return this.sessionServiceInject.list().find(el => el.isActive);
  }

  elaborationrequestDetail(code: number) {
    // perform search
    this.eventService.broadcastEvent('start-loader');
    this.elaborationRequestService.getElaborationRequestOutcome(code).subscribe((res: any) => {
      // build from parent class
      const details = new ElaborationRequestDetails(res.response[0]);
      this.elaborationRequestService.setElaborationRequestDetailList(details);
      this.eventService.broadcastEvent('stop-loader');
      this.routingService.loadComponent(this.routes.detail);
      (this.routingService.componentRef.instance as ElaborationRequestDetailComponent).card = this.card;
      // this.session.open('claimsExperts', 'home', '', true);
      this.eventService.broadcastEvent('stop-loader');
    },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );
  }

}

<div class="row">
  <div calss="col-lg-12">
    <lpc-roles-control
      [roleDefinitions]="getDefinitionsByCodes(getRoleCode('contractor'))"
      [roles]="getRolesDataByCodes(getRoleCode('contractor'))"
      (add)="propagateEvent($event,'add')"
      (delete)="propagateEvent($event, 'delete')">
    </lpc-roles-control>
  </div>
</div>
<div *ngIf="roles?.length !== 0">
  <div class="core-header-icon ">
    <span class="header-text" translate>lpc_holders</span>
    <span class="pull-right header-text"> </span>
  </div>
  <div class="row">
  <div *ngFor="let definition of getExcludedDefinitionsByCodes(getRoleCode('contractor'), getRoleCode('effectiveHolder')) ">
    <div [ngClass]="(enableDelegateCheckbox || enableLegalGuardianCheckbox || enableThirdPayerCheckbox) ? 'col-lg-12' : 'col-lg-6'">
      <lpc-roles-control
        [roleDefinitions]="[definition]"
        [roles]="getRolesDataByCodes(definition.code)"
        [enableDelegateCheckbox]="enableCheckbox(getRoleCode('delegate'))"
        [enableLegalGuardianCheckbox]="enableCheckbox(getRoleCode('legalGuardian'))"
        [enableThirdPayerCheckbox]="enableCheckbox(getRoleCode('thirdPayer'))"
        [contractor]="getRolesDataByCodes(getRoleCode('contractor'))[0]"
        (add)="propagateEvent($event, 'add')"
        (addSub)="propagateEvent($event, 'addSub')"
        (delete)="propagateEvent($event, 'delete')"
        (deleteSub)="propagateEvent($event, 'deleteSub')"
        (selectedRoleEqualHolder)="propagateEvent($event, 'selectedRoleEqualHolder')"
        (checkboxWarning)="propagateEvent($event, 'checkboxWarning')">
      </lpc-roles-control>
    </div>
  </div>
  </div>
  <div class="row">
    <div *ngIf="!!getDefinitionsByCodes(getRoleCode('effectiveHolder')).length" class="core-header-icon ">
      <span class="header-text" translate>lpc_effective_holders</span>
      <span class="pull-right header-text"> </span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <lpc-roles-control
        [roleDefinitions]="getDefinitionsByCodes(getRoleCode('effectiveHolder'))"
        [roles]="getRolesDataByCodes(getRoleCode('effectiveHolder'))"
        (add)="propagateEvent($event, 'addRole')"
        (delete)="propagateEvent($event, 'deleteRole')">
      </lpc-roles-control>
    </div>
  </div>
</div>
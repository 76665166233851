import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { Definition, InputFieldDefinition, StringDefinitionField } from '../../models/postsales-operations-response.model';
import { Address } from '../../modules/lpc-policy-contact/models/address';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-change-contact',
  templateUrl: './change-contact.component.html',
  styleUrls: ['./change-contact.component.css']
})
export class ChangeContactComponent extends AbsOperationComponent {

  protected operationDataKey = 'contact';

  public addresses: Address[] = [];
  public emails: string[] = [];
  public homeInsurances: string[] = [];
  public addressesVisible = false;
  public emailsVisible = false;
  public homeInsurancesVisible = false;
  public isAddressReceived = false;
  public handleNewContact = EMPTY_STR;
  public newContact: Address;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected anag: AnagService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {

          this.formGroup.get('dates')
            .setValue(PlcObjectUtils.mapInputFieldsAsDate(result.definitions.dates as InputFieldDefinition[]), { emitEvent: false });
          this.formGroup.get('contact')
            .setValue(result.data.operationData.data, { emitEvent: false});
          if (result.data.operationData.data.postalAddress) {
            this.addresses.push(result.data.operationData.data.postalAddress);
          }
          if (result.data.operationData.data.email) {
            this.emails.push(result.data.operationData.data.email);
          }
          if (result.data.operationData.data.homeInsurance) {
            this.homeInsurances.push(result.data.operationData.data.homeInsurance);
          }
        }),
        switchMap(result => {
            this.emailsVisible = (result.definitions.emailAddress as Definition).visible;
            this.addressesVisible = (result.definitions.postalAddress as Definition).visible;
            this.homeInsurancesVisible = (result.definitions.homeInsuranceAddress as Definition).visible;
            this.handleNewContact = (result.definitions.lifePolicyContactsManagement as StringDefinitionField).value;
            return this.anag.getSubjectContactOptions(
              this.session.contractor,
              this.session.managementNode
            );
        })
      ).subscribe(
        result => {
          result.postalAddresses.forEach(el => {
            PlcObjectUtils.addToSet(this.addresses, el);
          });
          result.emails.forEach(el => {
            PlcObjectUtils.addToSet(this.emails, el);
          });
          result.phonesOrEmails.forEach(el => {
            PlcObjectUtils.addToSet(this.homeInsurances, el);
          });
          this.detectChanges();
        })
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      contact: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

}

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Instalments } from './lpc-warranty-detail/lpc-instalments.model';

@Component({
  selector: 'lpc-warranty-detail-modal',
  templateUrl: './lpc-warranty-detail-modal.component.html',
  styleUrls: ['./lpc-warranty-detail-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LpcWarrantyDetailModalComponent implements OnInit {
  @Input() instalments: Instalments;
  @Input() showFirstInstallment = true;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

}

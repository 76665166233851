import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {BeneficiaryRole, Role} from '../../../models/postsales-operations-response.model';
import {AnagSubject, PARTY_COMPLETE_KO} from '../../../models/subject.model';
import {AnagService} from '../../../services/anag.service';
import {Beneficiary} from '../model/beneficiary';
import {LinkedRole} from '../model/claim-beneficiary';
import {ComponentWithAnagModal} from '../../../interfaces/component-with-anag-modal';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { RoleType } from '../../../models/enum/lpc-subjects.enum';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';

@Component({
  selector: 'lpc-claim-sub-beneficiary',
  templateUrl: './claim-sub-beneficiary.component.html',
  styleUrls: ['./claim-sub-beneficiary.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcClaimSubBeneficiaryComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcClaimSubBeneficiaryComponent),
      multi: true
    }
  ]
})
export class LpcClaimSubBeneficiaryComponent implements OnInit, ControlValueAccessor, Validator, ComponentWithAnagModal {

  @Input() linkedRolesDefinition: BeneficiaryRole[];
  @Input() showPercentage: boolean;
  @Input() editable: boolean;
  @Input() linkedRoles: any[];
  @Output() triggerQuestPreval: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>(); // fixme use proper type
  private $subscriptions: Subscription[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    subject: new UntypedFormControl(),
    linkedRoles: new UntypedFormArray([])
  });

  partyCompleteError: {subjIdPlusrole: string, messages: string[]}[] = [];

  public roleCodeToAdd: RoleType;

  public get value(): LinkedRole[] {
    return this.formGroup.get('linkedRoles').value;
  }

  constructor(protected anagService: AnagService, protected translate: TranslationWrapperService) { }

  ngOnInit() {
    this.initLinkedSubjects();
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe(() => {
        this.onChange(this.formGroup.getRawValue());
        this.onTouch();
      })
    );
  }

  private initLinkedSubjects() {
    this.linkedRoles.forEach(beneficiary => {
      const linkedRoles = this.formGroup.get('linkedRoles') as UntypedFormArray;
      linkedRoles.push(new UntypedFormControl(beneficiary));
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: Beneficiary): void {
    if (!!obj) {
      this.formGroup.patchValue(obj, {emitEvent: false});
    }
  }

  onChange(obj: Beneficiary) {
  }

  onTouch() {
  }

  onDelete() {
    const subBeneficiary = this.formGroup.get('subject').value.value;
    this.removeMessagesByRole(subBeneficiary.id, subBeneficiary.role);
    this.delete.emit({
      id: subBeneficiary.id,
      roleCode: subBeneficiary.role
    });
  }

  openAnagSubjectModal(code: RoleType) {
    this.roleCodeToAdd = code;
    this.anagService.openSubjectModal(this);
  }

  trackFn(index: any, item: Beneficiary) {
    if ((item.value as Role).id) {
      return (item.value as Role).id;
    } else {
      return index;
    }
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    const roleConverted: Role = AnagService.subjectToRole(subject, this.roleCodeToAdd);
    const linkedRoles = this.formGroup.get('linkedRoles') as UntypedFormArray;

    this.$subscriptions.push(
      this.anagService.checkPartyCompleted(
        Number(subject.objectId),
        Number(subject.idLatestPhotos),
        roleConverted.role,
        Number(this.anagService.managementNode)
      ).subscribe(res => {
        if (res.result === PARTY_COMPLETE_KO) {
          const roleDescr = this.linkedRolesDefinition.find(r => r.code === roleConverted.role).label;
          this.partyCompleteError.push({
            subjIdPlusrole: `${subject.objectId}-${roleConverted.role}`,
            messages: res.outcome.map(m => `${this.translate.getImmediate('lpc_life_detail_beneficiary')} ${roleDescr} ${subject.nominative}: ${m}`)
          });
        } else {
          this.removeMessagesByRole(subject.objectId, roleConverted.role);
        }
        linkedRoles.push(new UntypedFormControl({
          value: roleConverted,
          type: subject.subjectType.codice,
          code: '_ALTRO',
          idAssicurato: EMPTY_STR,
          irrevocable: true
        }));
        this.triggerQuestPreval.emit({
          subject,
          roleCodeToAdd: this.roleCodeToAdd
        });
        this.formGroup.updateValueAndValidity();
      })
    );
  }

  private removeMessagesByRole(subjId: string, role: RoleType) {
    this.partyCompleteError = this.partyCompleteError.filter(m => m.subjIdPlusrole !== `${subjId}-${role}`);
  }

  deleteSubRole(index: string, event: any) {
    const linkedRoles = this.formGroup.get('linkedRoles') as UntypedFormArray;
    linkedRoles.removeAt(+index);
  }

  getLinkedRolesOfParentRole(parentRole: string): BeneficiaryRole[] {
    return (this.linkedRolesDefinition || []).filter(role => role.availForRole.toString() === parentRole);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors: any = {};

    if (!(this.formGroup.get('linkedRoles') as UntypedFormArray).controls.length && this.hasMandatoryLinkedRoles()) {
      errors.empty = true;
    }
    (this.formGroup.get('linkedRoles') as UntypedFormArray).controls.forEach(ctr => {
      const code = ctr.value.value.role;
      const mandatory: boolean = !!this.getSubLinkedRoleByCode(code) ? this.getSubLinkedRoleByCode(code).mandatory : false;
      if (mandatory && !ctr.value.value) {
        errors.empty = true;
      } else if (ctr.value.value.personType === '2') {
        errors.personType = true;
      }
      if (!!this.partyCompleteError.length) {
        Object.assign(errors, {partycomplete: this.partyCompleteError});
      }
    });
    if (!!Object.keys(errors).length) {
      return errors;
    } else {
      return null;
    }
  }

  private getSubLinkedRoleByCode(code: string): BeneficiaryRole | undefined {
    return (this.linkedRolesDefinition || []).find(role => role.code === code);
  }

  private hasMandatoryLinkedRoles(): boolean {
    return (this.linkedRolesDefinition || []).some(role => role.mandatory);
  }

  canAddSubBeneficiaries(role: BeneficiaryRole): boolean {
    const maxCardinality = role.maxCardinality || 1;
    const subBeneficiaries = this.value;
    return (subBeneficiaries.length < maxCardinality) && this.editable;
  }
}

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export class Notify {
  id: string;

  constructor(id: string) {
    this.id = id;
  }
}

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class NotifierService {

  private subject = new Subject<Notify>();

  constructor() { }

  public notifyComponent(id: string) {
    this.subject.next(new Notify(id));
  }

  public onNotify(): Observable<Notify> {
    return this.subject.asObservable();
  }
}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content"  >
      
      <div class="modal-header">
            <h3 class="modal-title">{{ '_CLAIMS_._COMMENTS_FORM' | translate }}
            <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
            </h3>
      </div>

      <div class="modal-body" style="padding-top: 5px; ">
        <div class="row" *ngIf="numberActiveComments() === 0">
            <div class="col-xs-12" style="font-size: 16px;" >
                <label ng-bind="label" style="font-weight: bold; text-align: center;">{{ '_CLAIMS_._THERE_ARE_NO_COMMENTS' | translate }}/{{ '_CLAIMS_._NO_PERMISSION' | translate }}  </label>
            </div>
        </div>
        <div class="row" *ngFor="let aComm of comments">
            <div class="col-xs-12" style="font-size: 16px;" *ngIf="showComment(aComm)">
                <label ng-bind="label" style="font-weight: bold; color: #6290b6;">{{ aComm.userIns }} | {{ aComm.dInsStr }} </label>
            </div>
            <div class="col-xs-12" style="font-size: 16px;" *ngIf="showComment(aComm)">
                <label ng-bind="label" style="font-weight: bold;">{{ aComm.text }}  </label>
            </div>
            <div class="col-xs-12" style="font-size: 16px;" *ngIf="showComment(aComm) && !aComm.canceled">
                <div style="display:flex;">
                    
                    <button class="button-right" (click)="cancel(aComm.userCommentId)" 
                    style="border: 0px; background-color: white;color: #eaa470;">
                        <span class="rgifont rgi-cancel"></span>&nbsp;
                        <span>{{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="col-xs-12" style="font-size: 16px;" *ngIf="showComment(aComm) && aComm.canceled">
                <label ng-bind="label" style="font-weight: bold; color: grey;">{{ '_CLAIMS_._CANCELLATION' | translate }} : {{ aComm.userCanc }} | {{ aComm.dCancStr }} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12" style="font-size: 16px;">
                <label ng-bind="label" style="color:#72BCFF; font-size: 20px; font-weight: bold;">{{ '_CLAIMS_._BUTTONS_._NEW' | translate }}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12" style="font-size: 16px;">
                <textarea class=" col-sm-12" [(ngModel)]="newComment" placeholder="{{ '_CLAIMS_._COMMENT' | translate }}" 
                    id="body" cols="30" rows="3"
                    name="body" maxlength="4000" ngModel required></textarea>
            </div>
        </div>
        <div class="row">
            <div class="btn-group btn-group-justified">
                <div class="btn-group" >
                    <button class="btn btn-warning text-uppercase" (click)="addComment()" 
                        >{{ '_CLAIMS_._BUTTONS_._ADD' | translate }}</button>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>

import {ActiveRoute, Routes, RoutingService} from '@rgi/rx/router';
import {ConsultationStartCardComponent} from './consultation/components/consultation-start-card/consultation-start-card.component';
import {ConsultationListComponent} from './consultation/components/consultation-list/consultation-list.component';
import {
  ConsultationStartCardStateManagerService
} from './consultation/state-managers/consultation-start-card-state-manager.service';
import {StateStoreService} from '@rgi/rx/state';
import {
  ConsultationStartCardResourceService
} from './consultation/services/resource/consultation-start-card-resource.service';
import {
  ConsultationStartCardBusinessService
} from './consultation/services/business/consultation-start-card-business.service';
import {
  ConsultationListStateManagerService
} from './consultation/state-managers/consultation-list-state-manager.service';
import {
  ConsultationListBusinessService
} from './consultation/services/business/consultation-list-business.service';
import {
  ConsultationContractDetailsComponent
} from './consultation/components/consultation-contract-details/consultation-contract-details.component';
import {
  ConsultationContractDetailsStateManagerService
} from './consultation/state-managers/consultation-contract-details-state-manager.service';
import {
  ConsultationContractDetailsResourceService
} from './consultation/services/resource/consultation-contract-details-resource.service';
import {
  ConsultationContractDetailsBusinessService
} from './consultation/services/business/consultation-contract-details-business.service';
import {
  ConsultationListResourceService
} from './consultation/services/resource/consultation-list-resource.service';


export const CONTRACT_CONSULTATION_ROUTES: Routes = [
  {
    route: 'consultation-start-card',
    component: ConsultationStartCardComponent,
    providers: [
      {
        provide: ConsultationStartCardStateManagerService,
        useClass: ConsultationStartCardStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ConsultationStartCardResourceService, ConsultationStartCardBusinessService],
      }
    ]
  },
  {
    route: 'consultation-results',
    component: ConsultationListComponent,
    providers: [
      {
        provide: ConsultationListStateManagerService,
        useClass: ConsultationListStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ConsultationListResourceService, ConsultationListBusinessService],
      }
    ]
  },
  {
    route: 'contract-details',
    component: ConsultationContractDetailsComponent,
    providers: [
      {
        provide: ConsultationContractDetailsStateManagerService,
        useClass: ConsultationContractDetailsStateManagerService,
        deps: [ActiveRoute, StateStoreService, RoutingService, ConsultationContractDetailsResourceService, ConsultationContractDetailsBusinessService],
      }
    ]
  },
];

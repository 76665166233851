import { TranslationWrapperService } from '../../../../../i18n/translation-wrapper.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'licFinantialParamCalculatorButton'
})
export class LicFinantialParamCalculatorPipe implements PipeTransform {

  constructor(protected translate: TranslationWrapperService) {}

  transform(value: string , _CALVO: boolean, _CALSO: boolean): string {
    if (_CALVO && !_CALSO) {
      return this.translate.getImmediate('lic_calculate_asset_volatility');
    }
    if (!_CALVO && _CALSO) {
      return this.translate.getImmediate('lic_calculate_sustainability_asset');
    }
    return this.translate.getImmediate(value);
  }

}

import { EMPTY_STR, StepConfiguration, StepperConfiguration } from '../../../models/consts/lpc-consts';

export interface RolesStepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'roles'`
   * @stepField label `'lpc_variation_step_label'`
   * @stepField fieldId `'roles'`
   * @stepField formName `'roles'`
   */
  ROLES: RolesStepConfiguration;
}

export interface RolesStepConfiguration extends StepConfiguration {
  formChildren: {
    presetValueDelegato;
    presetValueTutoreLegale;
    presetValueTerzoPagatore;
  };
}

export const ROLES: RolesStepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates'
  },
  QUESTS_FIN: {
    id: 'questsFin',
    label: 'lpc_financial_questionnaire_step',
    fieldId: 'questsFin',
    formName: EMPTY_STR
  },
  ROLES: {
    id: 'roles',
    label: 'lpc_variation_step_label',
    fieldId: 'roles',
    formName: 'roles',
    formChildren: {
      presetValueDelegato: 'presetValueDelegato',
      presetValueTutoreLegale: 'presetValueTutoreLegale',
      presetValueTerzoPagatore: 'presetValueTerzoPagatore'
    }
  },
  PAYMENT: {
    id: 'payment',
    label: 'lpc_payment_method',
    fieldId: 'payment',
    formName: 'payment'
  },
  QUESTS: {
    id: 'quests',
    label: 'lpc_avc_questionnaire',
    fieldId: 'quests',
    formName: EMPTY_STR
  },
  NOTES: {
    id: 'notes',
    label: EMPTY_STR,
    fieldId: 'notes',
    formName: 'notes'
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR
  },
};


export const _ROLES = {
  _HOLDER: 'contractor',
  _EFFECTIVE_HOLDER: 'effectiveHolder'
};

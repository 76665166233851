import { ValidationSubjectsService } from './../../../services/validation-subjects.service';
import { PolicyService, Insured } from './../../../services/policy-service';
import { EventNotificator } from '../../../event-notificator';
import { LifeRoleService } from './../../../services/life-role.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectSubjectComponent } from '../select-subject/select-subject.component';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { AnagEditPartyResolver } from '@rgi/anag';



@Component({
  selector: 'lic-select-insured',
  templateUrl: './select-insured.component.html',
  styleUrls: ['./select-insured.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectInsuredComponent extends SelectSubjectComponent implements OnInit, EventNotificator  {

  @Input() parentForm: UntypedFormGroup;
  disabled: boolean;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Input() role: string;

  label: string;
  select;

  constructor(
    lifeRoleService: LifeRoleService,
    policyService: PolicyService,
    protected validationService: ValidationSubjectsService,
    translateService: TranslationWrapperService,
    protected anagEditResolver: AnagEditPartyResolver) {
    super(lifeRoleService, policyService, translateService, anagEditResolver);
    this.select = this.translateService.getImmediate('lic_select');
  }

  ngOnInit() {
    this.label = this.translateService.getImmediate('lic_new_insured');
    this.getInsured();
    this.parentForm.controls.insured.setValue(this.val);
  }

  getInsured() {
    this.disabled = false;
    if (this.policyService.getInsuredLogic() === Insured.COULD_BE_EQUAL ||
    this.policyService.getInsuredLogic() === Insured.MUST_BE_EQUAL ) {
      if (!this.validationService.isLegal(this.lifeRoleService.getPolicyHolder())) {
        if (this.val == null) {
          this.lifeRoleService.setPolicyInsuredEqualToPolicyHolder();
          this.val = this.lifeRoleService.getPolicyInsured();
        }
        if (this.policyService.getInsuredLogic() === Insured.MUST_BE_EQUAL) {
          this.disabled = true;
        }
      }
    }
  }

}

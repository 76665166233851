import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {
  RGI_RX_DROP_SELECTION_DATA,
  RgiRxDatatableRowAction,
  RgiRxDropContainerSelectData,
  TableSchema
} from '@rgi/rx/ui';

@Component({
  selector: 'consultation-start-card-node-modal',
  templateUrl: './consultation-start-card-node-modal.component.html',
  styleUrls: ['./consultation-start-card-node-modal.component.css']
})
export class ConsultationStartCardNodeModalComponent implements OnInit {
  code = 'CONTRACT_CONSULTATION.CODE';
  description = 'CONTRACT_CONSULTATION.DESCRIPTION';
  action = '';

  constructor(@Inject(RGI_RX_DROP_SELECTION_DATA) public dropSelectionData: RgiRxDropContainerSelectData<any, any>) {
  }

  // EVENT EMMITTER ON CLOSE
  @Output() modalClose = new EventEmitter();

  // TABLE SCHEMA

  schema: TableSchema = {
    rows: [
      {
        name: 'code', title: this.code,
      },
      {
        name: 'description', title: this.description,
      },
      {
        name: 'action', title: this.action,
        actions: [{
          name: 'selectNode',
          styleClass: 'rgi-ui-icon-check'
        }]
      }
    ],
    header: ['code', 'description', 'action']
  };


  ngOnInit() {
  }

  // CLOSE MODAL EVENT

  closeModal() {
    this.modalClose.emit();
  }

  // SELECT NODE EVENT

  // TODO : PARAMETRIZE WITH AGENCY -MODEL
  onActionClick(event: RgiRxDatatableRowAction<any>) {
    if (event.name === 'selectNode') {
      this.modalClose.emit(event.row);
    }
  }
}

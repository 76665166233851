<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header">
    <span class="anag-icon-m rgi-ui-icon-history"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1">{{'_ANAG_._LABEL_._HISTORY_CONSULTATION_' | translate}}</span>
    <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
  </div>
  <div style="padding:10px 12px">
<rgi-rx-datatable *ngIf="taxClassificationsHistory && taxClassificationsHistory.length"
                  [data]="taxClassificationHistoryTableData"
                  [schema]="TAX_CLASSIFICATION_HISTORY_LIST_MODAL_TABLE_SCHEMA"
                  [selectable]="false" [pageOptions]="[10,25,50,100]"
                  [expansionRow]="taxClassificationDetail">
</rgi-rx-datatable>
    <ng-template #taxClassificationDetail let-taxClassificationRow>
      <div class="rgi-ui-grid rgi-ui-grid-no-gutter">
        <div class="rgi-ui-col">
          <p class="rgi-ui-text-4 rgi-ui-info">TIN</p>
          <p data-qa="tax-classification-tin">{{taxClassificationRow.tin ? taxClassificationRow.tin : '-'}}</p>
        </div>
        <div class="rgi-ui-col">
          <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._RISK_CLASSIFICATION_</p>
          <p data-qa="tax-classification-risk">{{taxClassificationRow.riskClassificationDescr ? taxClassificationRow.riskClassificationDescr : '-'}}</p>
        </div>
        <div class="rgi-ui-col">
          <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._REASON_CLASSIFICATION_</p>
          <p data-qa="tax-classification-reason">{{taxClassificationRow.reasonClassificationDescr ? taxClassificationRow.reasonClassificationDescr : '-'}}</p>
        </div>
        <div class="rgi-ui-col">
          <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._BASIS_CLASSIFICATION_</p>
          <p data-qa="tax-classification-basis">{{taxClassificationRow.basisClassificationDescr ? taxClassificationRow.basisClassificationDescr : '-'}}</p>
        </div>
        <div class="rgi-ui-col">
          <p class="rgi-ui-text-4 rgi-ui-info" translate>_ANAG_._LABEL_._CLASSIFICATION_DATE_</p>
          <p data-qa="tax-classification-classification-date">{{taxClassificationRow.classificationDate}}</p>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="rgi-ui-panel-footer">
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
            translate>_ANAG_._BTN_._CLOSE_
    </button>
  </div>
</div>

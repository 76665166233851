<div class="msg-modal-content">
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="actionClose()"><span translate> RE_ISSUE.SYSTEM_MESSAGE</span>
    </rgi-rx-panel-header>
    <rgi-rx-panel-footer>

      <button *ngIf="!data.showButtonContinue" class="rgi-ui-btn rgi-ui-btn-primary"
              (click)="actionClose()" data-qa="close" translate> RE_ISSUE.CLOSE
      </button>

      <button *ngIf="data.showButtonContinue && !data.showButtonConfirm" class="rgi-ui-btn rgi-ui-btn-primary"
              (click)="actionConfirm()" data-qa="continue" translate> RE_ISSUE.CONTINUE
      </button>

      <button *ngIf="data.showButtonConfirm" class="rgi-ui-btn rgi-ui-btn-primary"
               (click)="actionConfirm()" data-qa="confirm" translate> RE_ISSUE.CONFIRM
      </button>

    </rgi-rx-panel-footer>
    <p><span data-qa="modal-msg">{{data.msg}}</span></p>
  </rgi-rx-panel>
</div>

import { EMPTY_STR, StepperConfiguration } from '../../../models/consts/lpc-consts';

export interface CloseStepConfiguration extends StepperConfiguration {
  CLOSE: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
    formChildren: { 
      assignmentClosedCause: string;
    };
  };
}

export const CLOSE_ASSIGNMENT_CONFIG: CloseStepConfiguration = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates',
  },
  CLOSE: {
    id: 'close',
    label: EMPTY_STR,
    fieldId: 'close',
    formName: 'close',
    formChildren: {
      assignmentClosedCause: 'assignmentClosedCause',
    }
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: 'warnings',
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: 'documents',
  },
};

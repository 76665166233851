<rgi-rx-panel class="lg-panel" data-qa="premiumDetailModal">
  <!-- HEADER -->
  <rgi-rx-panel-header class="rgi-cc-background-white rgi-cc-header-border" id="header-panel" [closeable]="true" data-qa="close" (onClose)="close()">
    <span class="rgi-ui-title-2" translate>{{data?.title}}</span>
  </rgi-rx-panel-header>
  <div class="rgi-ui-grid rgi-ui-panel-header">
    <span class="rgi-ui-col rgi-cc-padding-bottom-0" translate>CONTRACT_CONSULTATION.TOTAL_GROSS</span>
    <span class="rgi-ui-col-2 rgi-cc-padding-bottom-0 rgi-cc-text-align-right" data-qa="total">{{data?.total | currency : data?.currency?.code}}</span>
  </div>
  <div class="rgi-cc-triangle">
    <span translate></span>
  </div>
  <table rgi-rx-table class="rgi-cc-table-layout-fixed" [dataSource]="data?.dataTable" *ngIf="!isTableEmpty()" aria-label="Premium Detail Table">
    <ng-container rgiRxColumnDef="description">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-ui-title-3" translate></td>
      <td rgi-rx-cell *rgiRxCellDef="let row" [attr.data-qa]="row.description"> {{row.description}} </td>
    </ng-container>
    <ng-container rgiRxColumnDef="net">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-ui-title-3" translate>CONTRACT_CONSULTATION.NET</td>
      <td rgi-rx-cell *rgiRxCellDef="let row" [attr.data-qa]="row.net"> {{row.net | currency : data?.currency?.code}} </td>
    </ng-container>
    <ng-container rgiRxColumnDef="accessories">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-ui-title-3" translate>CONTRACT_CONSULTATION.ACCESSORIES</td>
      <td rgi-rx-cell *rgiRxCellDef="let row" [attr.data-qa]="row.accessories"> {{row.accessories | currency : data?.currency?.code}} </td>
    </ng-container>
    <ng-container rgiRxColumnDef="fractioningInterests">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-ui-title-3" translate>CONTRACT_CONSULTATION.FRACTIONING_INTERESTS</td>
      <td rgi-rx-cell *rgiRxCellDef="let row"  [attr.data-qa]=" row.fractioningInterestsNet"> {{row.fractioningInterestsNet | currency :data?. currency?.code}} </td>
    </ng-container>
    <ng-container rgiRxColumnDef="taxable">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-ui-title-3" translate>CONTRACT_CONSULTATION.TAXABLE</td>
      <td rgi-rx-cell *rgiRxCellDef="let row" [attr.data-qa]=" row.taxable"> {{row.taxable | currency : data?.currency?.code}} </td>
    </ng-container>
    <ng-container rgiRxColumnDef="taxes">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-ui-title-3" translate>CONTRACT_CONSULTATION.TAXES</td>
      <td rgi-rx-cell *rgiRxCellDef="let row" [attr.data-qa]=" row.tax"> {{row.tax | currency : data?.currency?.code}} </td>
    </ng-container>
    <ng-container rgiRxColumnDef="gross">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-ui-title-3" translate>CONTRACT_CONSULTATION.GROSS</td>
      <td rgi-rx-cell *rgiRxCellDef="let row" [attr.data-qa]=" row.gross"> {{row.gross | currency : data?.currency?.code}} </td>
    </ng-container>
    <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"
        class="rgi-cc-background-white"></tr>
    <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <span *ngIf="isTableEmpty()" class="rgi-ui-text-3 rgi-cc-row">No data.</span>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" data-qa="close-button" translate>CONTRACT_CONSULTATION.CLOSE</button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

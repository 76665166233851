import {Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {ANAG_FUNCTIONS, CORE_ADDRESS_CONFIG} from '../../anag-constants/anag-constants';
import {RgiRxEventService, PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {AnagApiAddress, AnagApiOtherAddress} from '../../anag-model/anag-domain/anag-api-party';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagAddressData} from '../../anag-model/anag-domain/anag-route-data';
import {Subject, Subscription} from 'rxjs';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TYPE_RESIDENCE} from '../../anag-constants/enums-constants';
import {AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {RgiRxUserAuthorizationService} from '@rgi/rx/auth';
import { AnagUtilsService } from '../../anag-resources/anag-utils.service';

@Component({
  selector: 'rgi-anag-ubication-component',
  templateUrl: './ubication.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagUbicationComponent implements OnInit, OnModalClose, OnDestroy {
  modalClose: EventEmitter<any> = new EventEmitter();


  subscriptions: Subscription = new Subscription();
  tmpAddress: AnagApiAddress;
  tmpOtherAddress: AnagApiOtherAddress;
  addressType: string;
  modalLabel: string;
  hideNormalize: boolean;
  isNormalizeOptional: boolean;
  showOtherAddress: boolean;
  disabledMainAddress: boolean;
  typeDomicile: Array<AnagEntityIta>;
  mainAddress = new UntypedFormControl('');
  residenceType = new UntypedFormControl(undefined);
  otherAddressAvailabilityIsValid: string;
  updateAddrEmitter: Subject<void> = new Subject<void>();
  ubicationForm = new UntypedFormGroup({});
  errorMsg: string;
  labelAddress: string;
  labelAvailability: string;
  labelContacts: string;
  normalizeErrorMsg: string;
  otherAddressForm: UntypedFormGroup = new UntypedFormGroup({
    mainAddress: this.mainAddress,
    residenceType: this.residenceType,
  });

  constructor(
    public anagStorage: AnagStorageService,
    public eventService: RgiRxEventService,
    public statelessService: AnagStatelessService,
    public pushMessageHandler: PushMessageHandlerService,
    protected authorizationService: RgiRxUserAuthorizationService,
    protected translateService: RgiRxTranslationService,
    protected anagUtilsService: AnagUtilsService,
    @Optional() @Inject(DIALOG_DATA) public data: AnagAddressData) {
    if (data) {
      if (data.address) {
        this.tmpAddress = this.statelessService.deepClone(data.address);
      }
      if (data.otherAddress) {
        this.tmpOtherAddress = this.statelessService.deepClone(data.otherAddress);
      } else {
        this.tmpOtherAddress = new AnagApiOtherAddress();
      }
      this.addressType = data.addressType ? data.addressType : null;
      if (data.modalLabel) {
        this.modalLabel = data.modalLabel;
      }
      this.anagStorage.getSystemProperties$(CORE_ADDRESS_CONFIG.normalize).subscribe(systemProp => {
        this.hideNormalize = systemProp.value === 'Disable';
      });

      this.isNormalizeOptional = this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.normalize);

      this.showOtherAddress = data.showOtherAddress ? data.showOtherAddress : false;
      this.disabledMainAddress = data.disabledMainAddress ? data.disabledMainAddress : false;
    }
    this.typeDomicile = this.anagStorage.getEnumsByCode(TYPE_RESIDENCE);
    this.manageSubscriptions();
  }

  ngOnInit() {
    this.translation();
    this.pushMessageHandler.clearTag('ubication-tag');
    if (this.tmpOtherAddress) {
      this.adaptInputOtherAddressToForm(this.tmpOtherAddress);
      if (this.disabledMainAddress) {
        this.otherAddressForm.get('mainAddress').disable();
      }
    }
  }

  actionClose() {
    this.modalClose.emit();
  }


  checkNormalize() : boolean {
    return this.anagUtilsService.checkNormalize(this.isNormalizeOptional, this.ubicationForm);
  }


  actionConfirm() {
    this.pushMessageHandler.clearTag('ubication-tag');
    this.pushMessageHandler.clearTag('normalization-tag');
    if(this.checkNormalize()) {
      this.notifyNormalize(this.normalizeErrorMsg);
    }
    else if (this.manageFormValid()) {
      this.updateAddrEmitter.next();
      if (this.showOtherAddress) {
        this.tmpOtherAddress.baseAddress = this.tmpAddress;
        this.modalClose.emit(this.tmpOtherAddress);
      } else if (this.tmpAddress) {
        this.modalClose.emit(this.tmpAddress);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private adaptInputOtherAddressToForm(tmpOtherAddress: AnagApiOtherAddress) {
    // tslint:disable-next-line:max-line-length
    this.updateFormField(this.otherAddressForm.get('mainAddress'), tmpOtherAddress.mainAddress != null ? tmpOtherAddress.mainAddress.toString() : null);
    // tslint:disable-next-line:max-line-length
    this.updateFormField(this.otherAddressForm.get('residenceType'), this.tmpOtherAddress.addressType ? this.tmpOtherAddress.addressType.codice : null);
  }

  protected updateFormField(control: AbstractControl, value: string) {
    if (control && value !== control.value) {
      control.setValue(value, {emitEvent: false});
    }
  }

  manageSubscriptions() {
    this.subscriptions.add(this.otherAddressForm.valueChanges.subscribe(formData => {
      this.adaptFormToApiOtherAddressContacts(formData);
    }));
  }

  adaptFormToApiOtherAddressContacts(formData: any): any {
    this.tmpOtherAddress.mainAddress = formData.mainAddress;
    this.tmpOtherAddress.addressType = this.typeDomicile.find(x => x.codice === formData.residenceType);
    return this.tmpOtherAddress;
  }

  updateResidenceAddress(address: AnagApiAddress) {
    this.tmpAddress = address;
  }

  updateOtherAddressAvailabilityValidity(msg: boolean) {
    this.notifyFormValidationMsg(msg);
  }

  manageFormValid() {
    const addressIsValid = this.ubicationForm.get('addressForm') ? this.ubicationForm.get('addressForm').valid : false;
    const availabilityIsValid = this.ubicationForm.get('availabilityOtherAddressForm') ?
                                this.ubicationForm.get('availabilityOtherAddressForm').valid : true;
    const contactsIsValid = this.ubicationForm.get('contactsAddressForm') ?
                                this.ubicationForm.get('contactsAddressForm').valid : true;
    if (!addressIsValid || !availabilityIsValid || !contactsIsValid) {
      let msg = this.errorMsg;
      if (!addressIsValid) {
        this.ubicationForm.get('addressForm').markAllAsTouched();
      }
      if (!availabilityIsValid) {
        msg += ':' +  ' ' + this.labelAvailability;
      }
      if (!contactsIsValid) {
        msg += ':' + ' ' + this.labelContacts;
      }
      this.notifyFormValidationMsg(msg);
    }
    return addressIsValid && availabilityIsValid && contactsIsValid;
  }

  notifyFormValidationMsg(stringMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
      tag: 'ubication-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  notifyNormalize(stringMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
      tag: 'normalization-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  translation() {
    this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(translated =>  this.errorMsg = translated).unsubscribe();
    this.translateService.translate('_ANAG_._LABEL_._ADDRESS_').subscribe(translated =>  this.labelAddress = translated).unsubscribe();
    this.translateService.translate('_ANAG_._LABEL_._AVAILABILITY_').subscribe(translated =>  this.labelAvailability = translated).unsubscribe();
    this.translateService.translate('_ANAG_._LABEL_._CONTACTS_').subscribe(translated =>  this.labelContacts = translated).unsubscribe();
    this.translateService.translate('_ANAG_._MSG_._NORMALIZE_ERROR_MSG').subscribe(translated =>  this.normalizeErrorMsg = translated).unsubscribe();
  }
}

import { Injectable, EventEmitter } from '@angular/core';

// TODO: move to services
@Injectable({
  providedIn: 'root'
})
export class CardComponentService {
  private eventEmitters: EventEmitter<any>[];

  addEventEmitter(eventEmitter: EventEmitter<any>, idCard: string) {
    if (!this.eventEmitters) {
      this.eventEmitters = [];
    }

    this.eventEmitters[idCard] = eventEmitter;
  }


  emit(event: any, idCard: string) {
    if (this.eventEmitters && this.eventEmitters[idCard]) {
      this.eventEmitters[idCard].emit(event);
    }
  }

  constructor() { }
}

<form [formGroup]="homeForm" *ngIf="state">

  <rgi-rx-form-field>
    <label rgiRxLabel>
      <span translate>_GP_._LABEL_._NODE_</span>
    </label>
    <select rgiRxNativeSelect formControlName="node" data-qa="node">
      <option value=""></option>
      <option *ngFor="let node of state.nodes" [value]="node.code">{{node.description}}</option>
    </select>
    <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
  </rgi-rx-form-field>

  <rgi-rx-form-field *ngIf="showCompanyField">
    <label rgiRxLabel>
      <span translate>_GP_._LABEL_._COMPANY_</span>
    </label>
    <select rgiRxNativeSelect formControlName="company" data-qa="company">
      <option value=""></option>
      <option *ngFor="let company of state.companies" [value]="company.code">{{company.description}}</option>
    </select>
    <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
  </rgi-rx-form-field>

  <rgi-rx-form-field>
    <label rgiRxLabel>
      <span translate>_GP_._LABEL_._PRODUCT_</span>
    </label>
    <select rgiRxNativeSelect formControlName="filterProduct" data-qa="filterProduct">
      <option value=""></option>
      <option *ngFor="let product of state.products" [value]="product.code">{{product.description}}</option>
    </select>
    <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
  </rgi-rx-form-field>

  <rgi-rx-form-field>
    <label rgiRxLabel>
      <span translate>_GP_._LABEL_._ISSUE_DATE_</span>
    </label>
    <rgi-rx-date-picker [openOnClick]="false">
      <input [rgiRxDateTimeInput]="issueDate" formControlName="issueDate" data-qa="issueDate">
    </rgi-rx-date-picker>
    <rgi-rx-date-time #issueDate></rgi-rx-date-time>
    <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
  </rgi-rx-form-field>

  <rgi-rx-form-field>
    <label rgiRxLabel>
      <span translate>_GP_._LABEL_._EFFECTIVE_DATE_</span>
    </label>
    <rgi-rx-date-picker [openOnClick]="false">
      <input [rgiRxDateTimeInput]="effectiveDate" formControlName="effectiveDate" data-qa="effectiveDate">
    </rgi-rx-date-picker>
    <rgi-rx-date-time #effectiveDate></rgi-rx-date-time>
    <div class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
  </rgi-rx-form-field>

  <div *ngIf="!!state.errors && !!state.errors.length" data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component
      [errors]="state.errors">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button rgi-rx-button color="secondary" (click)="resetHome()" data-qa="buttonsReset" translate>
      _GP_._BTN_._EMPTY_
    </button>
    <button rgi-rx-button color="primary" (click)="callActionNewFlowIssue()"
            [disabled]="homeForm.invalid && homeForm.touched" data-qa="buttonsNext" translate>
      _GP_._BTN_._NEW_
    </button>
  </div>

</form>

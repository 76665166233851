import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComponentWithAnagModal } from '../../interfaces/component-with-anag-modal';
import { EMPTY_STR, ERROR_TYPE_CURRENT, JS_EVENT, PV_TOKEN, SEVERITY_ERROR_BLOCKING } from '../../models/consts/lpc-consts';
import { Roles } from '../../models/enum/lpc-subjects.enum';
import { AssignmentHolderType, AssignmentType, BenefitDefinition, Company, InputFieldDefinition } from '../../models/postsales-operations-response.model';
import { AnagSubject } from '../../models/subject.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { customAmountValidator } from '../../utils/custom-validators/custom-validators-utils';
import { PlcDateUtils } from '../../utils/plc-date-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';


@Component({
  selector: 'lpc-open-assignment',
  templateUrl: './open-assignment.component.html',
  styleUrls: ['./open-assignment.component.css']
})
export class OpenAssignmentComponent extends AbsOperationComponent implements OnInit, ComponentWithAnagModal {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  public assignmentDates: InputFieldDefinition[];
  public assignmentHolderType: AssignmentHolderType[];
  public assignmentTypes: AssignmentType[];
  public companies: Company[];
  public benefit: string;
  public roleCodeToAdd = Roles.VINCOLATARIO;
  protected operationDataKey = 'assignmentOpenInfo';


  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anagService: AnagService,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authenticationService: any,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anagService);
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      openAssignment: new UntypedFormGroup({
        assignmentHolderType: new UntypedFormControl(null, [Validators.required]),
        assignmentHolder: new UntypedFormControl(null, [Validators.required]),
        policyNumber: new UntypedFormControl(),
        benefit: new UntypedFormControl(),
        assignmentType: new UntypedFormControl(null, [Validators.required])
      })
    });
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(this.createDraft().subscribe(result => {
      this.assignmentDates = result.definitions.assignmentDates as InputFieldDefinition[];
      this.initAssignmentForm(result);
      this.assignmentHolderType = result.definitions.assignmentTypesCreditor as  AssignmentHolderType[];
      this.assignmentTypes = result.definitions.assignmentTypes as AssignmentType[];
      this.companies = result.definitions.companies as Company[];
      this.benefit = (result.definitions.benefit as BenefitDefinition).value;
    }));
    const assignmentForm = this.formGroup.get('openAssignment') as UntypedFormGroup;
    this.removeFeErrors(assignmentForm);
    assignmentForm.get('policyNumber').valueChanges.subscribe(value => {
      this.$feErrors = [];
      if (!!value) {
        assignmentForm.addControl('assignmentCompany', new UntypedFormControl(null, [Validators.required]));
      } else {
        assignmentForm.removeControl('assignmentCompany');
      }
    });

    assignmentForm.get('assignmentType').valueChanges.subscribe(value => {
      this.$feErrors = [];
      if (value === '2' || value === '4') {
        assignmentForm.addControl('amount', new UntypedFormControl(null, customAmountValidator));
        assignmentForm.get('benefit').setValue(this.benefit);
        assignmentForm.get('benefit').disable();
        assignmentForm.get('amount').valueChanges.subscribe(val => {
          this.$feErrors = [];
          if (Number.parseFloat(val) >= Number.parseFloat(this.formGroupValue.openAssignment.benefit)) {
            const msg = this.translate.getImmediate('lpc_amount_less_of_benefit');
            this.$feErrors.push({
              context: 'openAssignment',
              errorId: EMPTY_STR,
              errorMessage: msg,
              severity: SEVERITY_ERROR_BLOCKING,
              type: ERROR_TYPE_CURRENT
            });
            assignmentForm.get('amount').setErrors({lpc_amount_less_of_benefit: msg});
          } else {
            if (assignmentForm.get('amount').hasError('lpc_amount_less_of_benefit')) {
              delete assignmentForm.get('amount').errors.lpc_amount_less_of_benefit;
            }
          }
        });
      } else {
        assignmentForm.removeControl('amount');
        assignmentForm.get('benefit').enable();
        assignmentForm.get('benefit').setValue(null);
      }
    });
  }

  public showSummaryValue(controlName) {
    return !this.isActiveStep('openAssignment') && this.formGroup && this.formGroup.get('openAssignment')
      && this.formGroup.get('openAssignment').get(controlName) && this.formGroup.get('openAssignment').get(controlName).value;
  }

  private removeFeErrors(assignmentForm: UntypedFormGroup) {
    assignmentForm.get('assignmentHolder').valueChanges.subscribe(value => this.$feErrors = []);
    assignmentForm.get('assignmentHolderType').valueChanges.subscribe(value => this.$feErrors = []);
  }

  protected initAssignmentForm(result: any) {
    this.assignmentDates.map(date => {
      if (date.code === '_ASSIGNMENT_OPEN_DATA' ) {
          (this.formGroup.get('openAssignment') as UntypedFormGroup).addControl(
            date.code,
            new UntypedFormControl(PlcDateUtils.isoToDate(date.defaultValue))
          );
          this.formGroup.get('openAssignment').get(date.code).setValue(PlcDateUtils.isoToDate(this.formGroup.value.dates._1OEFF));
        } else {
          (this.formGroup.get('openAssignment') as UntypedFormGroup).addControl(
            date.code,
            new UntypedFormControl(PlcDateUtils.isoToDate(date.defaultValue))
          );
        }
      if (!date.editable) {
          this.formGroup.get('openAssignment').get(date.code).disable();
        }
    });
    const assignmentData = result.data.operationData.data;
    if (!!assignmentData) {
      this.formGroup.get('openAssignment').patchValue({
        assignmentHolderType: assignmentData.creditorType,
        _ASSIGNMENT_EXPIRE_DATA: PlcDateUtils.isoToDate(assignmentData.expireDate),
        creditor: assignmentData.creditor,
        policyNumber: assignmentData.contractNumber,
        assignmentCompany: assignmentData.company,
        assignmentType: assignmentData.assignmentType,
        amount: !!assignmentData.amount ? assignmentData.amount.toString() : EMPTY_STR
      });
      this.setCreditor(assignmentData.creditor);
    }
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      tap(result => {
        this.benefit = (result.definitions.benefit as BenefitDefinition).value;
        this.assignmentDates = result.definitions.assignmentDates as InputFieldDefinition[];
        this.initAssignmentForm(result);
      })
    );
  }

  public getAssignmentTypeDescription(typeCode: string): string {
    if (!!this.assignmentTypes && !!typeCode) {
      return this.assignmentTypes.find(code => code.codice === typeCode).descrizione;
    }
  }

  public getAssignmentHolderTypeDescription(typeCode: string): string {
    if (!!this.assignmentHolderType && !!typeCode) {
      return this.assignmentHolderType.find(code => code.codice === typeCode).descrizione;
    }
  }

  public getCompanyDescription(companyCode: string): string {
    if (!!this.companies && !!companyCode) {
      return this.companies.find(code => code.codice === companyCode).descrizione;
    }
  }

  public deleteSbj(event) {
    this.formGroup.get('openAssignment').get('assignmentHolder').setValue(null);
  }

  public openAnagSubjectModal() {
    this.anagService.openSubjectModal(this);
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    this.formGroup.get('openAssignment').get('assignmentHolder').setValue({ id: subject.objectId, name: subject.nominative });
  }

  setCreditor(creditor: any) {
    if (!creditor) {
      return;
    }
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.anagService.getSubject(creditor, this.authenticationService.getOperator().salePoint.objectId).subscribe(respSubject => {
      if (!!respSubject && !!respSubject.subject) {
        this.receiveAnagSubjectFromModal(respSubject.subject);
      }
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    }, err => {
      console.error(err);
    });
  }

  showAmountAndBenefit(): boolean {
    return !!this.formGroupValue.openAssignment.assignmentType
    && (this.formGroupValue.openAssignment.assignmentType === '2'
    || this.formGroupValue.openAssignment.assignmentType === '4');
  }

  getTransformedOperationData() {
    const openAssignmentFormValue = this.formGroupValue.openAssignment;
    const creditorType = openAssignmentFormValue.assignmentHolderType;
    const creditor = openAssignmentFormValue.assignmentHolder;
    const policyNumber = openAssignmentFormValue.policyNumber;
    const benefit = openAssignmentFormValue.benefit;
    const assignmentType = openAssignmentFormValue.assignmentType;
    const company = openAssignmentFormValue.assignmentCompany;
    const amount =  openAssignmentFormValue.amount;
    return  {
      creditorType,
      openDate: PlcDateUtils.isoToDate(this.formGroup.value.dates._1OEFF),
      expireDate: this.formGroup.get('openAssignment').get('_ASSIGNMENT_EXPIRE_DATA').value,
      creditor: !!creditor ? creditor.id : null,
      contractNumber: policyNumber,
      benefit,
      assignmentType,
      company,
      amount
    };
  }

}

import {Inject, Injectable} from '@angular/core';
import {Variable} from '../models/domain-models/variable';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {GISService} from '../gis-service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {ProposalService} from '../proposal.service';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  private baseApiUrlV2;
  private contractPortfolioEndpoint;
  private contractMotorEndpoint;
  private lastSelectedVariablesType: string;
  private cachedVariables: Array<Variable>;
  private cachedContractVariables: Array<Variable>;


  private variablesChannel: BehaviorSubject<Array<Variable>> =
    new BehaviorSubject<Array<Variable>>(null);

  private variablesContractChannel: BehaviorSubject<Array<Variable>> =
    new BehaviorSubject<Array<Variable>>(null);

  protected httpClient: HttpClient;


  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected gisService: GISService,
    protected proposalService: ProposalService) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';
    this.contractPortfolioEndpoint = this.baseApiUrlV2 + '/portfolio/contract';
    this.contractMotorEndpoint = this.baseApiUrlV2 + '/motor/contract';

    this.httpClient = this.micHttpClient.getHttpClient();

  }

  toFormGroup(variables: Variable[]) {
    const group: any = {};

    variables.forEach(variable => {

      const formControl = this.getVariableFormControl(variable, null);

      group[variable.identificationCode] = formControl;
    });
    const formGroup = new UntypedFormGroup(group);
    formGroup.updateValueAndValidity();
    return formGroup;
  }

  getVariableFormControl(variable: Variable, isUnitSelected: boolean): UntypedFormControl {
    variable.dependent = true;

    let value;
    if (variable.type === 0) {
      if (variable.variableClass === 7 && variable.valueDescription) {
        value = this.gisService.getMostRecentCities().find(
          (city) => {
            return parseInt(city.istatCode, 10) === Number(variable.value);
          }
        );
      } else if (variable.variableClass === 8 && variable.value) {
        value = this.gisService.getProvinces().find(
          (province) => {
            const provinceId = variable.value.split('.')[0];
            return province.id === Number(provinceId);
          }
        );
      } else {
        value = variable.value;
      }

    } else if (variable.type === 4) {
      if (variable.value) {
        const date: Array<string> = variable.value.split('/');
        const day = Number(date[0]);
        const month = Number(date[1]) - 1;
        const year = Number(date[2]);
        value = new Date(year, month, day);
      } else {
        value = null;
      }
    } else if (variable.type === 5) {
      value = variable.value != null ? variable.value : variable.value || '';
    } else {
      value = variable.value != null ? +variable.value : variable.value || '';
    }

    const formControl = new UntypedFormControl(value);
    if (!variable.editable && !isUnitSelected) {
      formControl.disable({emitEvent: false});
    }
    formControl.setValidators(this.getVariableValidators(variable, isUnitSelected));
    return formControl;
  }

  getVariableValidators(variable: Variable, isUnitSelected: boolean) {
    const validators = [];
    if (!!variable.valueMin) {
      validators.push(Validators.min(Number(variable.valueMin)));
    }

    if (!!variable.valueMax) {
      validators.push(Validators.max(Number(variable.valueMax)));
    }

    if (variable.compulsory && (isUnitSelected === null || isUnitSelected === undefined || isUnitSelected)) {
      validators.push(this.variableValidator(variable));
    }

    return validators;
  }

  variableUnitValidator(variable: Variable, isUnitSelected: boolean): ValidatorFn {

    return (control: AbstractControl): {
      [key: string]: any
    } | null => {
      if (variable.compulsory && isUnitSelected) {
        if (!control.value || control.value === '-1') {
          return {required: true};
        }
        return null;
      }
    };
  }


  variableValidator(variable: Variable): ValidatorFn {

    return (control: AbstractControl): {
      [key: string]: any
    } | null => {
      if (variable.compulsory) {
        if (control.value === undefined || control.value === '' || control.value === null || control.value === '-1') {
          return {required: true};
        }
        return null;
      }
    };
  }

  getVariables() {
    const contractId = this.proposalService.getContractId();
    const inputDateOfBirth = this.proposalService.getDateOfBirth();
    let httpParams: HttpParams = new HttpParams();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }
    return this.httpClient.get<Array<Variable>>(this.contractPortfolioEndpoint + '/' + contractId + '/variables', {params: httpParams});
  }

  getContractVariables() {
    const contractId = this.proposalService.getContractId();
    const inputDateOfBirth = this.proposalService.getDateOfBirth();
    let httpParams: HttpParams = new HttpParams();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }
    return this.httpClient.get<Array<Variable>>(this.contractPortfolioEndpoint + '/'
      + contractId + '/contract-variables', {params: httpParams});
  }


  updateVariables(variables: Array<Variable>) {
    let httpParams: HttpParams = new HttpParams();
    const contractId = this.proposalService.getContractId();
    const inputDateOfBirth = this.proposalService.getDateOfBirth();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }
    const path = this.contractPortfolioEndpoint + '/' + contractId + '/variables';
    return this.httpClient.put<Array<Variable>>(path, variables, {params: httpParams});
  }

  updateContractVariables(variables: Array<Variable>) {
    let httpParams: HttpParams = new HttpParams();
    const contractId = this.proposalService.getContractId();
    const inputDateOfBirth = this.proposalService.getDateOfBirth();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }
    const path = this.contractPortfolioEndpoint + '/' + contractId + '/contract-variables';
    return this.httpClient.put<Array<Variable>>(path, variables, {params: httpParams});
  }


  updatePartyVariables(variables: Array<Variable>) {
    let httpParams: HttpParams = new HttpParams();
    const contractId = this.proposalService.getContractId();
    const inputDateOfBirth = this.proposalService.getDateOfBirth();
    if (inputDateOfBirth) {
      httpParams = httpParams.set('dateOfBirth', String(inputDateOfBirth));
    }
    const path = this.contractMotorEndpoint + '/' + contractId + '/party/variables';
    return this.httpClient.put<Array<Variable>>(path, variables, {params: httpParams});
  }

  getLastSelectedVariablesType() {
    return this.lastSelectedVariablesType;
  }

  public getContractVariablesObserable() {
    return this.variablesContractChannel.asObservable();
  }


  setLastSelectedVariablesType(variableType: string) {
    this.lastSelectedVariablesType = variableType;
  }

  public getVariablesObserable() {
    return this.variablesChannel.asObservable();
  }

  public setVariables(newVariables: Array<Variable>) {
    this.cachedVariables = newVariables;
    this.variablesChannel.next(newVariables);
  }

  public setContractVariables(newVariables: Array<Variable>) {
    this.cachedContractVariables = newVariables;
    this.variablesContractChannel.next(newVariables);
  }


  getCachedVariables() {
    return this.cachedVariables;
  }

  getCachedContractVariables() {
    return this.cachedContractVariables;
  }


  public getVariablesObject(): Array<Variable> {
    return this.variablesChannel.value;
  }

  public getContractVariablesObject(): Array<Variable> {
    return this.variablesContractChannel.value;
  }

}

import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {StateService} from './state.service';
import {State} from './models/state';
import {Subscription} from 'rxjs';
import {ProposalService} from './proposal.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService implements OnDestroy {

  private previousState: State = State.START;
  private subscriptions: Subscription = new Subscription();
  private eventPropagation: EventEmitter<any> = new EventEmitter();
  private navigation: EventEmitter<any> = new EventEmitter();
  private naviPosition: string;
  private cardIdSession: string;

  constructor(
    protected proposalService: ProposalService,
    protected stateService: StateService) {
    this.initialize(stateService);
  }

  initialize(stateService: StateService) {
    const stateChangeSubscription = stateService.getStateChange().subscribe(
      (state: State) => {

        if (state === State.QUOTATION_STANDARD_PLATE
          || state === State.QUOTATION_WITHOUT_PLATE
          || state === State.QUOTATION_ALTERNATIVE_PLATE) {
          this.goToNextPage('quote', 'P&C New Proposal - Quotation');
          this.previousState = state;
        } else if (
          state === State.EDIT_ASSET_DATA_WITHOUT_PLATE
          || state === State.EDIT_ASSET_DATA_STANDARD_PLATE
          || state === State.EDIT_ASSET_DATA_ALTERNATIVE_PLATE) {
          this.goToNextPage('asset', 'P&C New Proposal - Asset Data');
          this.previousState = state;
        } else if (state === State.ENTITLED || state === State.ENTITLED_WITHOUT_PLATE) {
          this.goToNextPage('entitled');
          this.previousState = state;
        } else if (state === State.PROPOSAL || state === State.PROPOSAL_WITHOUT_PLATE) {
          this.goToNextPage('proposal');
          this.previousState = state;
        } else if (state === State.TELEMATICS) {
          this.goToNextPage('mandatorydata');
          this.previousState = state;
        } else if (state === State.SUMMARY) {
          this.goToNextPage('summary');
          this.previousState = state;
        } else if (state === State.START) {
          this.previousState = state;
        } else if (state === State.SUBSTITUTED_CONTRACTS) {
          this.goToNextPage('substitution');
          this.previousState = state;
        } else if (state === State.AMENDMENT_DATES) {
          this.goToNextPage('amendment');
          this.previousState = state;
        }
      }
    );
    this.subscriptions.add(stateChangeSubscription);
  }

  getNavigationEventEmitter() {
    return this.navigation;
  }

  getPropagationEventEmitter() {
    return this.eventPropagation;
  }

  goToNextPage(nextPage, title?) {
    if (this.previousState === State.START && !!this.eventPropagation) {
      const apiContract = this.proposalService.getApiContract();
      const resourceId = apiContract ? apiContract.id : null;
      this.eventPropagation.emit({
        eventName: 'leaveMainPage',
        parent: 'newMotorContract',
        route: null,
        resourceId,
        title
      });
      this.setNaviPosition(nextPage);
    } else if (!!this.navigation) {
      this.navigation.emit(nextPage);
    }
  }

  setNaviPosition(naviPosition: string) {
    this.naviPosition = naviPosition;
  }

  getNaviPosition() {
    return this.naviPosition;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setPreviousState(state) {
    this.previousState = state;
  }

  addSubscription(subscription) {
    this.subscriptions.add(subscription);
  }

  getPreviousState() {
    return this.previousState;
  }

  setIdCardSession(idSession: string) {
    this.cardIdSession = idSession;
  }

  getIdCardSession(): string {
    return this.cardIdSession;
  }
}

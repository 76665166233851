import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { PlcCurrencyUtils } from '../../../utils/plc-currency';
import { ToolCode } from '../../../models/enums/vita.enum';
import { ToolData } from '../../../models/contractual-option.model';

@Component({
  selector: 'lpc-life-option-detail',
  templateUrl: './life-option-detail.component.html',
  styleUrls: ['./life-option-detail.component.css']
})
export class LifeOptionDetailComponent implements OnInit, AfterViewChecked {

  @Input() data: any;
  @Output() eventPropagation = new EventEmitter<any>();
  @Input() id: string;

  public lifeOptionVisible = false;

  public TOOL_CODES = ToolCode;

  public hasToolOptions = true;


  constructor(@Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix) { }

  ngOnInit() {
    console.log(this.data, 'data');
    if (!!this.data.optionDetail) {
      this.lifeOptionVisible = true;

      const toolDataArray = this.data.optionDetail.toolData as ToolData[] || [];

      const hasToolOptions = toolDataArray.filter(tooldata => !!tooldata.fund || (!!tooldata.profiles && !!tooldata.profiles.length));
      this.hasToolOptions = !!hasToolOptions.length;
    }
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }



  public getSymbol(symbol: string) {
    return PlcCurrencyUtils.fixEuroSymbol(symbol);
  }
}

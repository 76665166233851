import {Inject, Injectable} from '@angular/core';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {Observable} from 'rxjs';
import {UserService} from '@rgi/rx/auth';

import {GenericEntity, PncDomainService, RestApiConf, SelectableEntity} from '@rgi/pnc-postsales';

@Injectable({
  providedIn: 'root'
})
export class MotorDomainService extends PncDomainService{

  constructor(
    @Inject('environment')apiConf: RestApiConf,
    httpClient: RgiRxHttpClientWrapper,
    userService: UserService) {
    super(apiConf, httpClient, userService);
  }

  getAssetsList$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string): Observable<GenericEntity[]> {
    return this.httpClient.get<any>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/assets`, {});
  }

  getAssetNations$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string, assetCode: string): Observable<SelectableEntity[]> {
    return this.httpClient.get<any>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/assets/${assetCode}/nations`, {});
  }

  setAssetNations$(policyNumber: string, operationType: string, operationCode: string, prefix: string, resourceId: string, assetCode: string, selectedCountries: SelectableEntity[]): Observable<any> {
    return this.httpClient.put<any>(`${this.hostPath}/${prefix}/policies/${policyNumber}/operations/variations/${operationType}/${operationCode}/drafts/${resourceId}/assets/${assetCode}/nations`, selectedCountries);
  }

}

import { Component, OnInit, forwardRef, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup, UntypedFormControl } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './ngb-date-custom-parser-formatter';

@Component({
  selector: 'pnc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class DatepickerComponent implements OnInit, ControlValueAccessor {

  @Input() label;
  @Input() id;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') val;
  @Input() options;
  @Input() disabled;
  @Input() required;
  @Input() customFormControlName;
  @Input() customFormGroup;
  @Input() placeholder;
  @Input() dateType;
  @Input() minDate;
  @Input() maxDate;
  @Output() dateSelect: EventEmitter<any> = new EventEmitter<any>();

  onChange: any = () => { };
  onTouch: any = () => { };

  constructor(config: NgbDatepickerConfig) {
    if (this.minDate) {
      config.minDate = {
        year: this.minDate.year,
        month: this.minDate.month,
        day: this.minDate.day
      };
    }
    if (this.maxDate) {
      config.maxDate = {
        year: this.maxDate.year,
        month: this.maxDate.month,
        day: this.maxDate.day
      };
    }
  }

  ngOnInit() {
    // Placeholder management
    // TODO gestione internazionalizzazione
    if (!this.placeholder) {
      if (!this.dateType) {
        this.placeholder = 'gg/mm/aaaa';
      } else {
        this.placeholder = 'mm/aaaa';
      }
    }

    // Required management by FormControl
    if (!this.required) {
      const formControl = this.customFormGroup.controls[this.customFormControlName];
      const validator: any = formControl.validator && formControl.validator(new UntypedFormControl());
      this.required = validator && validator.required;
    }

  }

  set value(obj) {
    this.val = obj;
    this.onChange(obj);
    this.onTouch();
  }

  get value() {
    return this.val;
  }

  onDateSelect(event) {
    this.dateSelect.emit(event);
  }

  writeValue(obj: any): void {
    if (obj != null && !(obj instanceof Date)) {
      obj = new Date(obj);
    }
    this.val = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { Role, RoleDefinition } from '../../../models/postsales-operations-response.model';

@Component({
  selector: 'lpc-subroles-control',
  templateUrl: './lpc-subroles-control.component.html',
  styleUrls: ['./lpc-subroles-control.component.scss']
})
export class LpcSubRolesControlComponent implements OnInit {

  @Input() roleDefinition: RoleDefinition;
  @Input() roles: Role[] = [];
  @Input() formGroup: UntypedFormGroup;
  @Input() disableButtons: boolean;

  @Output() addSubj: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteSubj: EventEmitter<{ id: string, roleCode: string }> = new EventEmitter<{ id: string, roleCode: string }>();

  public selectLabel: string;

  constructor(protected translateService: TranslationWrapperService) {
    this.selectLabel = this.translateService.getImmediate('lpc_select_label');
  }

  get subRolesFormArray() {
    return this.formGroup.get('subRoles') as UntypedFormArray;
  }

  ngOnInit() {
    this.roles.forEach((subject) => {
      this.subRolesFormArray.push(new UntypedFormGroup({
        id: new UntypedFormControl(subject.id),
        name: new UntypedFormControl(subject.name),
        percentage: new UntypedFormControl(Number(subject.percentage))
      }));
    });
  }

  onSelect(roleCode) {
    this.addSubj.emit(roleCode);
  }

  getMinCardinality(role: RoleDefinition): number {
    return Number(role.minCardinality);
  }


  getFilteredRolesOf(filter: string): Role[] {
    return this.roles.filter((role) => role.role === filter);
  }

  onDeleteSubRole(index, roleCode) {
    const val = this.subRolesFormArray.get(index.toString()).value;
    this.deleteSubj.emit({ id: val.id, roleCode});
  }

  isSelectable(definition: RoleDefinition): boolean {
    return Number(definition.maxCardinality) > this.roles.filter((role) => role.role === definition.code).length;
  }


}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReIssueLoaderComponent} from './re-issue-loader/re-issue-loader.component';
import {ErrorManagentComponent} from './error-managent/error-managent.component';
import {RgiRxI18nModule} from '@rgi/rx/i18n';

@NgModule({
    declarations: [
        ReIssueLoaderComponent,
        ErrorManagentComponent
    ],
    imports: [
        CommonModule,
        RgiRxI18nModule
    ],
    exports: [
        ReIssueLoaderComponent,
        ErrorManagentComponent
    ]
})
export class ReIssueCoreModule {
}

export class Company {

  constructor(
    public objectId: string,
    public aniaCode: string,
    public extension: any,
    public code: string,
    public damages: boolean,
    public description: string,
    public life: boolean) {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateReserveComponent } from './update-reserve.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    CardService,
    containerCacheServiceProvider
} from '@rgi/digital-claims-common-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { RgiRxDatePickerModule, RgiRxFormElementsModule, RgiRxNativeDateTimeModule } from '@rgi/rx/ui';
import { DscComponentModule } from '../shared/dsc-component.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
@NgModule({
    declarations: [
        UpdateReserveComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        DscComponentModule
    ],
    exports: [
        UpdateReserveComponent,
    ],
    providers: [
        containerCacheServiceProvider
    ]
})
export class UpdateReserveModule {
    constructor(cardService: CardService) {
        const title = '_UPDATE_RESERVE';
        const moduleName = 'update-reserve';

        const cardOpeningModule = cardService.registerCard(
            moduleName,
            title,
            UpdateReserveComponent,
            undefined,
            undefined,
            undefined,
            undefined,
            false,
          );
    }

}

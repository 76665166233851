import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutingHostDirective, RoutingService } from '@rgi/portal-ng-core';
import { ClaimsElasticListComponent } from './claims-elastic-list/claims-elastic-list.component';
import { ClaimsElasticSearchComponent } from './claims-elastic-search/claims-elastic-search.component';
import { ClaimsElasticService } from './claims-elastic.service';
import { Claim } from './dto/claim';

@Component({
  selector: 'claims-claims-elastic',
  templateUrl: './claims-elastic.component.html',
  styleUrls: ['./claims-elastic.component.scss']
})
export class ClaimsElasticComponent extends CardComponent implements AfterViewInit {
  @ViewChild(RoutingHostDirective, { static: true }) hostViewRef: RoutingHostDirective;
  routes: { search: any; list: any; };
  claims: Claim[] = [];

  constructor(
    private routingService: RoutingService,
    sessionService: SessionService,
    cardComponentService: CardComponentService,
    @Inject('claimsElasticSearchComponent') claimsElasticSearchComponent,
    @Inject('claimsElasticListComponent') claimsElasticListComponent,
    private claimsElasticService: ClaimsElasticService
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      search: claimsElasticSearchComponent,
      list: claimsElasticListComponent
    };
  }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;

    this.claims = this.claimsElasticService.getSessionClaims(this.card.id);

    if (this.claims) {
      this.routingService.loadComponent(this.routes.list);
    } else {
      this.routingService.loadComponent(this.routes.search);
    }

    (this.routingService.componentRef.instance as ClaimsElasticSearchComponent).card = this.card;
    (this.routingService.componentRef.instance as ClaimsElasticListComponent).card = this.card;
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ReIssueStartCardState,
  ReIssueStartCardStateManagerService
} from '../re-issue-state-manager/re-issue-start-card-state-manager.service';
import {take} from 'rxjs/operators';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {of, Subscription} from 'rxjs';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ModalService, RGI_RX_DROP_SELECTION_HANDLER} from '@rgi/rx/ui';
import {
  ReIssueStartCardNodeModalComponent
} from './re-issue-start-card-modal/re-issue-start-card-node-modal/re-issue-start-card-node-modal.component';
import {ReIssueAuthBusinessService} from '../re-issue-business-services/re-issue-auth-business.service';
import {UserService} from '@rgi/rx/auth';
import {OperatorLight} from '@rgi/anag/lib/services/external-service';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';
import {RoutableComponent} from '@rgi/rx/router';


@Component({
  selector: 'rgi-re-issue-start-card',
  templateUrl: './re-issue-start-card.component.html',
  styleUrls: ['./re-issue-start-card.component.css'],
  providers: [{
    provide: RGI_RX_DROP_SELECTION_HANDLER,
    useValue: {
      name: 'nodeSelection',
      handler: ReIssueStartCardNodeModalComponent
    },
    multi: true
  }]
})
export class ReIssueStartCardComponent extends RoutableComponent implements OnInit, OnDestroy {

  state: ReIssueStartCardState;
  startCardFormGroup: UntypedFormGroup;
  effectDateValid = true;
  errorMessage: string;
  isError = false;
  sub = Subscription.EMPTY;
  private nodeDefault: any;

  constructor(
    public stateManager: ReIssueStartCardStateManagerService<ReIssueStartCardState>,
    private formBuilder: UntypedFormBuilder,
    private operatorService: UserService,
    private errorMsgService: PushMessageHandlerService) {
    super();
    this.startCardFormGroup = this.formBuilder.group(
      {
        company: [, Validators.required],
        //  productType: [, Validators.required],
        node: ['', Validators.required],
        effectiveDate: [new Date(), [Validators.required, this.effectDateValidator.bind(this)]],
        product: [, Validators.required]
      }
    );
    this.nodeDefault = this.operatorService.getUser<OperatorLight>().salePoint;
  }

  ngOnInit() {

    this.stateManager.getState$().pipe(take(1)).subscribe(state => {
      this.state = state;
      this.tickHostChange();
      this.stateManager.initAllData(state, this.nodeDefault.objectId).subscribe(state1 => {
        if (state1.companyList && state1.companyList.length === 1) {
          this.startCardFormGroup.patchValue({
            company: state1.companyList[0]
          });
        }
        if (state1.nodeList && state1.nodeList.length === 1) {
          state1.selectedNode = state1.nodeList[0];
          this.startCardFormGroup.patchValue({
            node: state1.selectedNode
          });

        }
        if (this.nodeDefault && state1.nodeList && state1.nodeList.length > 0) {
          state1.nodeList.forEach(node => {
            if (node.code === this.nodeDefault.code) {
              this.startCardFormGroup.patchValue({
                node
              });
            }
          });
        }
        this.stateManager.updateState$(of(state1));
      });

    });


    this.errorMsgService.clear();

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.errorMsgService.clear();
  }

  goToNextPage() {
    this.startCardFormGroup.markAsDirty();
    this.startCardFormGroup.markAsTouched();
    this.startCardFormGroup.markAllAsTouched();

    Object.keys(this.startCardFormGroup.controls).forEach(key => {
      this.startCardFormGroup.controls[key].markAsDirty();
    });
    this.formValidation();
    if (!this.isError) {
      this.stateManager.goToNextPage(this.startCardFormGroup);
    } else {
      this.notifyErrors();
      if (this.sub === Subscription.EMPTY) {
        this.sub = new Subscription();
        this.subscribeControls();
      }
    }
  }

  notifyErrors() {
    this.errorMsgService.clearTag('errorMessage');
    if (this.isError) {
      this.setErrorMessage();
    }
    this.tickHostChange();
  }

  formValidation() {
    this.validateEffectDate();

    if (this.startCardFormGroup.valid && this.effectDateValid) {
      this.stateManager.validate();
      this.isError = false;
    } else {
      this.isError = true;
      this.stateManager.validate();
    }
  }

  setErrorMessage() {
    const msg: PushMessage = new PushMessage();
    const opts = {icon: 'rgi-ui-icon-alert'};
    msg.tag = 'errorMessage';
    msg.status = 'danger';
    msg.content = 'RE_ISSUE.FIELDS_MARKED_IN_RED';
    msg.dismissible = false;
    msg.options = opts;
    this.errorMsgService.notify(msg);
  }

// TODO : NODE MANAGMENT ADD this.stateManager.updateState$(of(st)); TO RETURN
  clear() {
    this.startCardFormGroup.reset();
    this.errorMsgService.clear();
    this.tickHostChange();
  }

  validateEffectDate() {
    const dateStr = this.startCardFormGroup.controls.effectiveDate.value;
    this.effectDateValid = this.isDateValid(dateStr);

    if (this.effectDateValid) {
      this.startCardFormGroup.controls.effectiveDate.setErrors(null);
    } else {
      this.startCardFormGroup.controls.effectiveDate.setErrors({'RE_ISSUE.ERROR_FORMAT_DATE': true});
      this.startCardFormGroup.markAsDirty();
    }
  }

  isDateValid(dateStr: string): boolean {
    if (dateStr !== null) {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateStrFormatted = ('0' + day.toString()).slice(-2) + '/' + ('0' + month.toString()).slice(-2) + '/' + year.toString();
      const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
      if (!dateRegex.test(dateStrFormatted)) {
        return false;
      }
      return true;
    }
    return false;
  }

  effectDateValidator(control: AbstractControl): ValidationErrors {
    const dateStr = control.value;
    const effectDateValid = this.isDateValid(dateStr);
    if (!effectDateValid) {
      return {'RE_ISSUE.ERROR_FORMAT_DATE': true};
    }
    return null;
  }

  subscribeControls() {
    const effectDateSub = this.startCardFormGroup.controls.effectiveDate.valueChanges.subscribe(value => {
      this.formValidation();
      this.notifyErrors();
    });
    const productSub = this.startCardFormGroup.controls.product.valueChanges.subscribe(value => {
      this.formValidation();
      this.notifyErrors();
    });
    const nodeSub = this.startCardFormGroup.controls.node.valueChanges.subscribe(value => {
      this.formValidation();
      this.notifyErrors();
    });
    const companySub = this.startCardFormGroup.controls.company.valueChanges.subscribe(value => {
      this.formValidation();
      this.notifyErrors();
    });
    this.sub.add(effectDateSub);
    this.sub.add(productSub);
    this.sub.add(nodeSub);
    this.sub.add(companySub);
  }

  /*controlDate(val: any){
    var today = new Date();
    var inputDate = val;
    var diffMs = today.getTime() - inputDate.getTime();
    var diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
    if(diffDays > 360 ){
      this.startCardFormGroup.controls.effectiveDate.setErrors({ 'RE_ISSUE.DATE_ERROR_360': true });
      this.startCardFormGroup.markAsDirty();
      this.buttonDisabled = true;
    }else{
      this.startCardFormGroup.controls.effectiveDate.setErrors(null);
      this.buttonDisabled = false;
    }

  }
*/
}


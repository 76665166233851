import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import {RGI_RX_FORM_ELEMENTS_i18n} from './i18n/localization';

export function load18nFormElementsIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_FORM_ELEMENTS_i18n.it);
  });
}

export function load18nFormElementsEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_FORM_ELEMENTS_i18n.en);
  });
}

export function load18nFormElementsFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_FORM_ELEMENTS_i18n.fr);
  });
}

export function load18nFormElementsES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_FORM_ELEMENTS_i18n.es);
  });
}

export function load18nFormElementsDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_FORM_ELEMENTS_i18n.de);
  });
}


export const RGI_RX_FORM_ELEMENTS_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: load18nFormElementsIT
  },
  {
    locale: 'en',
    load: load18nFormElementsEN
  },
  {
    locale: 'fr',
    load: load18nFormElementsFR
  },
  {
    locale: 'es',
    load: load18nFormElementsES
  },
  {
    locale: 'de',
    load: load18nFormElementsDE
  }
];

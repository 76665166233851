import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Toast} from "./toast-api";
import {Notification} from "./notification-api";

@Injectable()
export class NotificationService implements Notification {

  private readonly _toasts: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

  constructor() {
  }

  push(toast: Toast) {
    var elements = this._toasts.getValue();
    this.clearExisting(toast);
    elements.push(toast);
    this._toasts.next(elements);
  }


  /**
   * Remove toast notification with tag
   * @param tag string
   */
  clearTag(tag?:string){
    const filteredToasts = this._toasts.getValue().filter( t => (tag && t.tag!=tag) || (!tag && t.tag) );
    this._toasts.next(filteredToasts);
  }


  clear() {
    this._toasts.next([]);
  }

  get(): Observable<Toast[]> {
    return this._toasts.asObservable();
  }

  get toasts(): Toast[] {
    return this._toasts.getValue();
  }

  remove(toast: Toast) {
    let current = this.toasts.filter(t => t !== toast);
    this._toasts.next(current);
  }

  removeList(toasts: Toast[]){
    let current = this.toasts.filter(t => !toasts.some( t2 => this.equals(t, t2) ));
    this._toasts.next(current);
  }

  success(body?: string, header: string = "success") {
    const toast = new Toast();
    toast.body = body;
    toast.status = "success";
    toast.header = header;
    this.push(toast);
  }

  error(body?: string, header: string = "error") {
    var toast = new Toast();
    toast.body = body
    toast.status = "danger";
    toast.header = header;
    this.push(toast);
  }

  warning(body: string, header: string = "warning") {
    var toast = new Toast();
    toast.body = body;
    toast.status = "warning";
    toast.header = header;
    this.push(toast);
  }

  message(body: string, header: string = "message") {
    var toast = new Toast();
    toast.body = body;
    toast.status = "default";
    toast.header = header;
    this.push(toast);
  }

  clearExisting(toast: Toast) {
    const found = this.toasts.find(value =>  this.equals(value, toast) );
    if (found) {
      var index = this.toasts.indexOf(found);
      this._toasts.next(this.toasts.splice(index, 1));
    }
  }

  protected equals(toast1: Toast, toast2:Toast):boolean{
    return toast1.header == toast2.header && toast1.body == toast2.body;
  }

}

import {QuestionnaireFlatIResponse} from './../../models/domain-models/questionnaires-flati-response';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, share} from 'rxjs/operators';
import {Quotation} from '../../models/domain-models/quotation';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';

@Injectable({
  providedIn: 'root'
})
export class ReIssueQuotationResourceService {

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any
  ) {
  }

  get(contract: string, recalculate: string): Observable<Quotation> {
    return this.http.get<Quotation>(this.getQuotationUri(contract, recalculate)).pipe(
      share(),
      map((output) => {
        return output;
      }));
  }

  getQuotationUri(contract: string, recalculate: string): string {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/quotation?recalculate=' + recalculate;
  }

  getQuestionnaires(contract: string, code: string): Observable<QuestionnaireFlatIResponse> {
    return this.http.get<QuestionnaireFlatIResponse>(this.getQuestionnairesUri(contract, code)).pipe(
      share(),
      map((output) => {
        return output;
      }));
  }

  putQuestionnaire(contract: string, questionnaireFlat: QuestionnaireFlatIResponse, code: string): Observable<QuestionnaireFlatIResponse> {
    return this.http.put<QuestionnaireFlatIResponse>(this.getQuestionnairesUri(contract, code), questionnaireFlat).pipe(
      share()
    );
  }

  getQuestionnairesUri(contract: string, code: string): string {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/questionnaire/' + code;
  }

  recalculatePut$(quotation: Quotation, resourceId: string): Observable<Quotation> {
    return this.http.get<Quotation>(this.putQuotationUri(resourceId)).pipe(map((quot) => {
      return quot;
    }));

  }

  putQuotationUri(contract: string): string {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/quotation?recalculate=1';
  }

  policyTechnicalDataPut$(policyTechnicalData: PolicyTechnicalData, resourceId: string): Observable<PolicyTechnicalData> {
    policyTechnicalData.expireDate.date = policyTechnicalData.effectiveDate.date;
    return this.http.put<PolicyTechnicalData>(this.putPolicyUri(resourceId), policyTechnicalData);
  }

  putPolicyUri(contract: string): string {
    return this.apiConf.api.portal.path + '/qqcontract/contract/' + contract + '/policytechnicaldata';
  }
}

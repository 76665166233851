<div class="modal" *ngIf="stopEvaluation">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="!nodeSearched && !idNodeLogin">
      <div class="modal-header">
        <h3 class="modal-title">
          <span> Node Search </span>
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="nodeSearchForm">
          <div class="form-group row">
            <label>{{ 'PORTAL_NG_COMMON.CODE' | translate }}:</label>
            <pnc-required required="true"></pnc-required>
            <input type="text" formControlName="code" class="form-control">
          </div>
          <div class="form-group row">
            <label translate>{{ 'PORTAL_NG_COMMON.DESCRIPTION' | translate }}:</label>
            <pnc-required required="true"></pnc-required>
            <input type="text" formControlName="description" class="form-control">
          </div>
          <div id="error-container" style="margin-top: 2em" *ngIf="validationMessages && validationMessages.length > 0">
            <pnc-form-message type="error" detail="validationError" [messages]="validationMessages"
              data-qa="action-error-messages"></pnc-form-message>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button class="btn btn-warning" (click)="cancel()" translate>PORTAL_NG_COMMON.CANCEL</button>
          </div>
          <div class="btn-group">
            <button class="btn btn-warning" (click)="onSubmit()" [disabled]="false" translate>PORTAL_NG_COMMON.CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-content" *ngIf="nodeSearched">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>PORTAL_NG_COMMON.NODE_LIST</span> <span> ({{nodes.length}}) </span>
        </h3>
      </div>
      <div class="modal-body">
        <pnc-table [arrayOfElements]="tableElement" (eventEmitter)="onButtonClicked($event)">
        </pnc-table>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified m-t-0">
          <div class="btn-group">
            <button class="btn btn-warning" (click)="cancel()" translate>PORTAL_NG_COMMON.CANCEL</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

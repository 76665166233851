import { DialogueHandlerNewDialogueService } from './dialogue-handler-service/dialogue-handler-new-dialogue.service';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder} from '@angular/forms';
import {CardService, DigitalClaimsCommonAngularModule, enumServiceProvider, getSystemPropertyProvider} from '@rgi/digital-claims-common-angular';
import {BrowserModule} from '@angular/platform-browser';
import {EditorModule} from '@tinymce/tinymce-angular';
import {RgiRxAutoCompleteModule, RgiRxDatePickerModule, RgiRxDragDropModule, RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxNativeDateTimeModule, RgiRxPanelModule, RgiRxTabModule, RgiRxTableModule, RgiRxTooltipModule} from '@rgi/rx/ui';
import { DialogueHandlerSearchComponent } from './dialogue-handler-search/dialogue-handler-search.component';
import { DialogueHandlerListComponent } from './dialogue-handler-list/dialogue-handler-list.component';
import { DialogueSummaryComponent } from './dialogue-summary/dialogue-summary.component';
import { NewDialogueComponent } from './new-dialogue/new-dialogue.component';
import { ActiveRoute, RgiRxRoutingModule } from '@rgi/rx/router';
import { DIALOGUE_HANDLER_ROUTES } from './dialogue-handler.route';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../../assets/i18n/translations';
import { RgiRxPortalModule } from '@rgi/rx/portal';
import { DIALOGUE_HANDLER_CARD } from './dialogue-handler-configuration/dialogue-handler.card';
import { RgiRxFormModule } from '@rgi/rx';
import { RgiAnagModule } from '@rgi/anag';
import { DialogueHandlerIntegrationService } from './dialogue-handler-configuration/dialogue-handler-integration.service';
import { DialogueHandlerSearchService } from './dialogue-handler-service/dialogue-handler-search.service';
import { DialogueHandlerListService } from './dialogue-handler-service/dialogue-handler-list.service';
import { DialogueHandlerListStateManager } from './dialogue-handler-service/dialogue-handler-list.state.manager';
import { DialogueHandlerSearchStateManager } from './dialogue-handler-service/dialogue-handler-search.state.manager';
import { DialogueHandlerNewDialogueStateManager } from './dialogue-handler-service/dialogue-handler-new-dialogue.state.manager';
import { DialogueHandlerSummaryStateManager } from './dialogue-handler-service/dialogue-handler-summary.state.manager';




@NgModule({
  declarations: [
    DialogueHandlerSearchComponent,
    DialogueHandlerListComponent,
    DialogueSummaryComponent,
    NewDialogueComponent,
  ],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    NgSelectModule,
    DigitalClaimsCommonAngularModule,
    RgiRxI18nModule,
    RgiRxFormElementsModule,
    RgiRxDatePickerModule,
    RgiRxNativeDateTimeModule,
    RgiRxPortalModule.forRoot(DIALOGUE_HANDLER_CARD),
    RgiRxRoutingModule.forRoot(DIALOGUE_HANDLER_ROUTES),
    RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    FormsModule,
    ReactiveFormsModule,
    RgiRxAutoCompleteModule,
    RgiRxFormModule,
    RgiRxTooltipModule,
    RgiRxExpansionModule,
    RgiRxDragDropModule,
    RgiAnagModule,
    BrowserModule,
    RgiRxTableModule,
    EditorModule,
    RgiRxModalModule,
    RgiRxPanelModule,
    RgiRxFormElementsModule,
    RgiRxTabModule,
    RgiRxExpansionModule
  ],
  exports: [
    DialogueHandlerSearchComponent,
    DialogueHandlerListComponent,
    DialogueSummaryComponent,
    NewDialogueComponent,
  ],
  providers: [
    enumServiceProvider,
    getSystemPropertyProvider,
    DialogueHandlerSearchService,
    DialogueHandlerListService,
    DialogueHandlerNewDialogueService,
    UntypedFormBuilder,
    DialogueHandlerIntegrationService,
    DialogueHandlerListStateManager,
    DialogueHandlerSearchStateManager,
    DialogueHandlerNewDialogueStateManager,
    DialogueHandlerSummaryStateManager,
    ActiveRoute,
  ],
})
export class DialogueHandlerModule {}
  /* constructor(
    cardService: CardService,
    private translate: TranslateService
  ) {
    // card dialogue handler - start
    const title = this.translate.instant('Dialogues Handler');
    const moduleName = 'dialogueHandler';

    cardService.registerCard(
      moduleName,
      title,
      DialogueHandlerComponent,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      'Claims'
    ); */
    // card letter templates - end

    // this.addSubcard(cardService, this.translate.instant('New letter model'),
    // 'NewLetterTemplates', 'claimsFinder', NewLetterTemplatesComponent);

    // this.addSubcard(cardService, this.translate.instant('Models list'),
    // 'LetterTemplatesList', 'claimsFinder', LetterTemplatesListComponent);
    //this.addSubcard(cardService, 'LetterTemplatesSinglePageCardComponent',
    //'LetterTemplatesSinglePageCard', 'claimsFinder',
   // LetterTemplatesSinglePageCardComponent);
  //}
// private addSubcard(cardService: CardService,
//                    subCardTitle: string,
//                    subCardModuleName: string,
//                    subCardParentCardName: string,
//                    classN: any) {
//     const routes = [{
//       path: 'action', route: {
//         template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
//         controller: 'CardPassCtrl',
//         label: 'Action'
//       }
//     }];

//     const communication = {
//       reload: 'reload'
//     };

//     cardService.registerSubCard(
//       subCardModuleName,
//       subCardTitle,
//       classN,
//       subCardParentCardName,
//       null,
//       'ClaimsCommunicationCtrl',
//       communication,
//       routes,
//       false,
//       false
//     );
//   }
// }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AnagFlowData } from '@rgi/anag';
import { ActiveRoute, RoutableComponent } from '@rgi/rx/router';
import { ModalService } from '@rgi/rx/ui';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AnagApiSubject, PaymentsPayload, Role } from '../../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyVcontRouteData } from '../../group-policy-models/group-policy-vcont-date';
import { SubcauseModel } from '../../group-policy-models/group-policy-vcont-variation';
import { GroupPolicyStateVcontVariation } from '../../group-policy-state/group-policy-state';
import { GroupPolicyStateManagerVariation } from '../../group-policy-state/group-policy-statemanager-variation/group-policy-stateless-op-variation';



/**
 * @author: dmasone
 * @description: Component used for render vcont variation step
 */
@Component({
  selector: 'rgi-gp-group-policy-vcont-variation',
  templateUrl: './group-policy-vcont-variation.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyVcontVariationComponent extends RoutableComponent implements OnInit, OnDestroy {

  public static readonly ROLES_PARTY_FORM = 'rolesPartyForm';
  public static readonly SUBCAUSE = 'subcause';
  public static readonly CONTRACTOR = 'contractor';

  public vertical = true;
  public showIndex = false;
  public stateVcontVariation: GroupPolicyStateVcontVariation;
  public paymentValidationChecker: Subject<void> = new Subject<void>();
  public previousStepData: GroupPolicyVcontRouteData;
  public isPaymentOk = true;
  public renderSubcauses = false;
  public variationForm = new UntypedFormGroup({
    rolesPartyForm: new UntypedFormGroup({})
  });

  constructor(
    protected activeRoute: ActiveRoute,
    protected modalService: ModalService,
    protected stateManagerVcontVariation: GroupPolicyStateManagerVariation) {
    super();
  }


  ngOnInit(): void {
    this.previousStepData = this.activeRoute.getRouteData<GroupPolicyVcontRouteData>();
    this.stateManagerVcontVariation.getState$().pipe(
      tap((state: GroupPolicyStateVcontVariation) => {
        console.log(state);
      })
    ).subscribe((nextState: GroupPolicyStateVcontVariation) => {
      this.initPage(nextState);
    });
  }

  ngOnDestroy(): void { }

  protected initPage(nextState: GroupPolicyStateVcontVariation) {
    this.stateVcontVariation = nextState;
    if (this.stateVcontVariation.allRoleParties) {
      this.addRolesOnForm();
    }
    this.stateVcontVariation.showSubcauses ? this.addSubcausesForm() : this.removeSubcausesForm();

    this.paymentValidationChecker.next();
  }

  protected addSubcausesForm() {
    const rolesPartyForm = this.variationForm.get(GroupPolicyVcontVariationComponent.ROLES_PARTY_FORM) as UntypedFormGroup;
    const subcauseControl = rolesPartyForm.get(GroupPolicyVcontVariationComponent.SUBCAUSE);
    if (subcauseControl) {
      this.renderSubcauses = true;
      subcauseControl.setValue(this.stateVcontVariation.subcause && this.stateVcontVariation.subcause.code);
    } else {
      this.renderSubcauses = true;
      rolesPartyForm.addControl(GroupPolicyVcontVariationComponent.SUBCAUSE, new UntypedFormControl(
        this.stateVcontVariation.subcause && this.stateVcontVariation.subcause.code,
        [Validators.required]
      ));
    }
    rolesPartyForm.get(GroupPolicyVcontVariationComponent.SUBCAUSE).updateValueAndValidity();
  }

  protected removeSubcausesForm() {
    const rolesPartyForm = this.variationForm.get(GroupPolicyVcontVariationComponent.ROLES_PARTY_FORM) as UntypedFormGroup;
    this.renderSubcauses = false;
    rolesPartyForm.removeControl(GroupPolicyVcontVariationComponent.SUBCAUSE);
    rolesPartyForm.updateValueAndValidity();
  }

  protected addRolesOnForm() {
    const rolesPartyForm = this.variationForm.get(GroupPolicyVcontVariationComponent.ROLES_PARTY_FORM) as UntypedFormGroup;
    rolesPartyForm.reset();
    const roleParties = this.stateVcontVariation.allRoleParties;
    let isLinkedRole = false;
    Object.keys(roleParties).forEach(roleKey => {
      roleParties[roleKey].forEach((roleParty, i) => {
        roleParty.nominative = roleParty.companyName ? roleParty.companyName :
          roleParty.surname + ' ' + roleParty.name;

        const partyRoleControl = rolesPartyForm.get(`${roleParty.role}_${i}`);
        if (partyRoleControl) {
          partyRoleControl.setValue(roleParty.idParty ? roleParty : undefined);
        } else {
          rolesPartyForm.addControl(`${roleParty.role}_${i}`, new UntypedFormControl(roleParty.idParty ?
            roleParty : undefined));
        }

        this.addControlPercentage(roleParty, rolesPartyForm, isLinkedRole);
        if (roleParty.idParty) {
          if (roleParty.linkedRoles) {
            Object.keys(roleParty.linkedRoles).forEach((linkedRoleKey, j) => {
              roleParty.linkedRoles[linkedRoleKey].forEach(linkedRole => {
                linkedRole.nominative = linkedRole.companyName ? linkedRole.companyName :
                  linkedRole.surname + ' ' + linkedRole.name;

                // tslint:disable-next-line:no-shadowed-variable
                const partyRoleControl = rolesPartyForm.get(`${roleParty.role}_${linkedRole.role}_${j}`);
                if (partyRoleControl) {
                  partyRoleControl.setValue(linkedRole.idParty ? linkedRole : undefined);
                } else {
                  rolesPartyForm.addControl(`${roleParty.role}_${linkedRole.role}_${j}`, new UntypedFormControl(linkedRole.idParty ?
                    linkedRole : undefined));
                }

                if (linkedRole.idParty) {
                  isLinkedRole = true;
                  this.addControlPercentage(linkedRole, rolesPartyForm, isLinkedRole);
                }
              });
            });
          }
        }
      });
    });
  }

  public addControlPercentage(roleParty: Role, rolesPartyForm: UntypedFormGroup, isLinkedRole: boolean) {
    const formControlName = isLinkedRole ?
      `linked_${roleParty.role}_${roleParty.idParty}_percentage` :
      `${roleParty.role}_${roleParty.idParty}_percentage`;
    if (roleParty.idParty && roleParty.showPercentage) {
      const linkedRoleControl = rolesPartyForm.get(formControlName);
      if (linkedRoleControl) {
        linkedRoleControl.patchValue(roleParty.percentage, { emitEvent: false });
      } else {
        rolesPartyForm.addControl(
          formControlName,
          new UntypedFormControl(roleParty.percentage, [Validators.required, this.customValidatorPercentage(roleParty.minPercentage)])
        );
      }


      rolesPartyForm.get(formControlName).valueChanges.pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
        .subscribe(percentage => {
          if (percentage) {
            if (rolesPartyForm.get(formControlName).valid) {
              this.setPercentageOnRole(roleParty, percentage);
            }
            this.variationForm.get(GroupPolicyVcontVariationComponent.ROLES_PARTY_FORM).patchValue({ formControlName: percentage });
          }
        });
    }
  }

  public onChangeSubcause() {
    const rolesPartyForm = this.variationForm.get(GroupPolicyVcontVariationComponent.ROLES_PARTY_FORM) as UntypedFormGroup;
    const value = rolesPartyForm.get(GroupPolicyVcontVariationComponent.SUBCAUSE).value;
    if (value) {
      this.stateManagerVcontVariation
        .actionSetSubcause(
          this.stateVcontVariation.subcauses
            .filter((subcause: SubcauseModel) => subcause.code === value)[0], this.previousStepData
        );
    }
  }

  public customValidatorPercentage(minPercentage = 0): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      return (control.value < minPercentage) || (control.value > 100) ? { overLimit: true } : null;
    };
  }

  protected setPercentageOnRole(roleParty: Role, percentage: number) {
    this.stateManagerVcontVariation.actionSetPercentageOnRole(roleParty, percentage, this.previousStepData, this.renderSubcauses);
  }

  public getSubjectDropContainerData(rolekey: number): AnagFlowData {
    const data = new AnagFlowData();
    data.partyRole = '' + rolekey;
    data.idParentSession = this.activeRoute.id;
    data.nodeCode = this.previousStepData.node;
    return data;
  }

  public onPartySelected($event, roleKey: number) {
    const showSubcauses: boolean = (+roleKey === 1) ?
      $event.changed && +$event.changed.objectId !== +this.stateVcontVariation.firstSubjectContract.idParty :
      this.stateVcontVariation.showSubcauses;
    this.setPartySelected($event.changed, '' + roleKey, showSubcauses);
  }

  public setPartySelected(subj: AnagApiSubject, role: string, showSubcauses = false) {
    this.stateManagerVcontVariation.actionSetPartyRole(subj, this.previousStepData, role, showSubcauses);
  }

  public deleteSubject(role: string, idParty: number) {
    const rolesPartyForm = this.variationForm.get(GroupPolicyVcontVariationComponent.ROLES_PARTY_FORM) as UntypedFormGroup;
    let delSubcauses = false;
    if (role === '1') {
      delSubcauses = !!rolesPartyForm.get(GroupPolicyVcontVariationComponent.SUBCAUSE);
      rolesPartyForm.reset();
    }
    this.stateManagerVcontVariation.actionDeleteRole(this.previousStepData, role, idParty, delSubcauses);
  }

  public setPaymentMethod(payload: PaymentsPayload) {
    this.stateManagerVcontVariation.setPaymentMethod(payload, this.previousStepData);
  }

  public callActionGoForword() {
    this.stateManagerVcontVariation.actionGoForword(this.previousStepData.resId);
  }

  public callActionBack(targetRoute?: string) {
    this.stateManagerVcontVariation.actionBack(this.previousStepData, this.activeRoute, targetRoute);
  }

  public callActionClose() {
    this.stateManagerVcontVariation.actionClose(this.previousStepData);
  }

}

import {ComponentFactoryResolver, ComponentRef, Directive, Input, OnDestroy, ViewContainerRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import {PaymentFieldDefinition} from '../../../models/postsales-operations-response.model';
import { LpcPaymentWrapperService } from './lpc-payment-wrapper.service';


@Directive({
  selector: '[lpcPaymentLoader]',
})
export class LpcPaymentLoaderDirective implements OnDestroy {
  element = {
    label: EMPTY_STR,
    value: EMPTY_STR
  };

  constructor(
    protected view: ViewContainerRef,
    protected resolver: ComponentFactoryResolver,
    protected lpcPaymentWrapperService: LpcPaymentWrapperService) { }

  protected ref: ComponentRef<any>;

  @Input() group: UntypedFormGroup;
  @Input() editable = true;
  @Input() set lpcPaymentLoader(config: PaymentFieldDefinition) {

    this.view.clear();
    if (config) {
      this.lpcPaymentWrapperService.createLpcPaymentFields(this.resolver, this.ref, this.view, this.group, config, this.editable);
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.destroy();
    }
  }
}

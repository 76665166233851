<div class="container-fluid table-white">
    <div class="row">
        <div class="col-md-1 col-sm-6 col-xs-12 text-padd-right" *ngIf="isMultipleAssetsPresent()">
            <input required class="asset-select-radio" attr.data.qa="damaged-asset-radio-{{damagedAsset.id}}"
                name="selectedAsset" type="radio" id="damaged-asset-radio-{{damagedAsset.id}}" [value]="damagedAsset.id"
                [(ngModel)]="selectedAssetRadio"
                [ngClass]="{'submitted-with-error': isSelectedAssetError(), 'asset-select-radio': true}"
                [disabled]="disabled">
        </div>
        <div class="col-sm-6 col-xs-12 text-padd-right"
            [ngClass]="{'col-md-3': isMultipleAssetsPresent(), 'col-md-4': !isMultipleAssetsPresent()}"
            attr.data-qa="damagedAsset-data-panel-asset-{{damagedAsset.id}}">{{ '_CLAIMS_._ASSET' | translate }}:
            <span>{{ '_CLAIMS_._THINGS' | translate }}</span></div>
        <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right"
            attr.data-qa="damagedAsset-data-panel-assetdescription-{{damagedAsset.id}}">
{{ '_CLAIMS_._ASSET_DESCRIPTION' | translate }}: <span>{{damagedAsset.description || '-'}}</span></div>
    </div>
</div>
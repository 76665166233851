import { Input, Output, EventEmitter, OnDestroy, Directive } from '@angular/core';
import { PanelStatus, PanelStatusState, PanelStatusChange } from '../dto/panel-status';
import { PanelSignal, PanelSignalEvent } from '../dto/panel-signal';
import { PanelService } from '../panel.service';
import { AccordionComponent } from '@rgi/portal-ng-core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class EditablePanelElement implements OnDestroy {
  @Input() nextPanelId: string;
  @Input() pncAccordion: AccordionComponent;
  @Input() acceptSignals = false;
  @Output() panelSignal = new EventEmitter<PanelSignalEvent>();
  @Output() panelStatusChange = new EventEmitter<PanelStatusChange>();
  protected panelStatus: PanelStatus;
  private panelIdValue: string;
  private debugOn = false;
  private signalSubscription: Subscription;
  private statusSubscription: Subscription;

  constructor(private panelService: PanelService) {
  }

  ngOnDestroy(): void {
    if (this.signalSubscription) {
      this.signalSubscription.unsubscribe();
    }
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

  @Input() set panelId(p: string) {
    const subscribe = p && this.panelIdValue !== p;
    this.panelIdValue = p;
    if (subscribe) {
      this.initPanelStatusSubscription(p);
      this.initPanelSignalSubscription(p);
    }
  }

  get panelId(): string {
    return this.panelIdValue;
  }

  setPanelComplete() {
    if (this.debugOn) {
      console.log('EditablePanelElement::setPanelComplete()', this);
    }
    const statusChange = this.newPanelStatusChange();
    this.status.state = PanelStatusState.complete;
    this.broadcastPanelStatus(statusChange);
  }

  setPanelIncomplete() {
    if (this.debugOn) {
      console.log('EditablePanelElement::setPanelIncomplete()', this);
    }
    const statusChange = this.newPanelStatusChange();
    this.status.state = PanelStatusState.incomplete;
    this.broadcastPanelStatus(statusChange);
  }

  disablePanelContent() {
    if (this.debugOn) {
      console.log('EditablePanelElement::disablePanelContent()', this);
    }
    const statusChange = this.newPanelStatusChange();
    this.status.contentEnabled = false;
    this.broadcastPanelStatus(statusChange);
  }

  enablePanelContent() {
    if (this.debugOn) {
      console.log('EditablePanelElement::enablePanelContent()', this);
    }
    if (this.status.panelEnabled) {
      const statusChange = this.newPanelStatusChange();
      this.status.contentEnabled = true;
      this.broadcastPanelStatus(statusChange);
    }
  }

  enablePanelAndContent() {
    if (this.debugOn) {
      console.log('EditablePanelElement::enablePanelAndContent()', this);
    }
    const statusChange = this.newPanelStatusChange();
    this.status.panelEnabled = true;
    this.enablePanelContent();
  }

  enableAndOpenContent() {
    if (this.debugOn) {
      console.log('EditablePanelElement::enableAndOpenContent()', this);
    }
    const statusChange = this.newPanelStatusChange();
    this.status.contentEnabled = true;
    this.enableAndOpenPanel();
  }

  enablePanel() {
    if (this.debugOn) {
      console.log('EditablePanelElement::enablePanel()', this);
    }
    const statusChange = this.newPanelStatusChange();
    this.status.panelEnabled = true;
    this.broadcastPanelStatus(statusChange);
  }

  disablePanel() {
    if (this.debugOn) {
      console.log('EditablePanelElement::disablePanel()', this);
    }
    const statusChange = this.newPanelStatusChange();
    this.status.panelEnabled = false;
    this.status.titleEnabled = false;
    this.status.contentEnabled = false;
    this.broadcastPanelStatus(statusChange);
  }

  enableAndOpenPanel() {
    if (this.debugOn) {
      console.log('EditablePanelElement::enableAndOpenPanel()', this);
    }
    this.status.panelEnabled = true;
    this.openPanel();
  }

  openPanel() {
    if (this.debugOn) {
      console.log('EditablePanelElement::openPanel()', this);
    }
    const propagate = !this.isPanelOpened();
    if (this.isPanelEnabled()) {
      this.status.panelOpened = true;
      this.openPncPanel();
      if (propagate) {
        const statusChange = this.newPanelStatusChange();
        this.broadcastPanelStatus(statusChange);
      }
    }
  }

  isPanelOpened(): boolean {
    if (this.debugOn) {
      console.log('EditablePanelElement::isPanelOpened()', this);
      console.log('EditablePanelElement::isPanelOpened() - value', this.status.panelOpened);
    }
    return this.status.panelOpened;
  }

  isPanelEnabled(): boolean {
    if (this.debugOn) {
      console.log('EditablePanelElement::isPanelEnabled()', this);
      console.log('EditablePanelElement::isPanelEnabled() - value', this.status.panelEnabled);
    }
    return this.status.panelEnabled;
  }

  closePanel() {
    if (this.debugOn) {
      console.log('EditablePanelElement::closePanel()', this);
    }
    const propagate = this.isPanelOpened();
    if (this.isPanelEnabled()) {
      this.status.panelOpened = false;
      this.closePncPanel();
      if (propagate) {
        const statusChange = this.newPanelStatusChange();
        this.broadcastPanelStatus(statusChange);
      }
    }
  }

  signalToPanel(panelId: string, signal: PanelSignal) {
    this.panelService.signalToPanel(panelId, signal);
    this.emitSignal(panelId, signal);
  }

  emitSignal(panelId: string, signal: PanelSignal) {
    const signalEvent = new PanelSignalEvent();
    signalEvent.panelId = panelId;
    signalEvent.panelSignal = signal;

    this.panelSignal.emit(signalEvent);
  }

  signalToNextPanel(signal: PanelSignal) {
    this.signalToPanel(this.nextPanelId, signal);
  }

  protected abstract panelStatusRecieved(panelStatus: PanelStatus);
  protected abstract panelSignalRecieved(panelSignal: PanelSignal);

  protected broadcastPanelStatus(statusChange: PanelStatusChange | undefined) {
    this.panelService.updatePanelStatus(this.panelId, this.panelStatus);
    if (statusChange) {
      if (this.debugOn) {
        console.log('EditablePanelElement::broadcastPanelStatus()', this);
        console.log('EditablePanelElement::broadcastPanelStatus() - statusChange', statusChange);
      }
      statusChange.newStatus = this.cloneStatus();
      this.panelStatusChange.emit(statusChange);
    }
  }

  get status(): PanelStatus {
    if (!this.panelStatus) {
      this.panelStatus = this.getFirstStatus();
    }
    return this.panelStatus;
  }

  set status(panelStatus: PanelStatus) {
    this.panelStatus = panelStatus;
  }

  protected getFirstStatus(): PanelStatus {
    const status = new PanelStatus();
    status.panelId = this.panelId;
    status.contentEnabled = false;
    status.panelEnabled = false;
    status.panelOpened = false;
    status.state = PanelStatusState.incomplete;
    status.titleEnabled = false;
    return status;
  }

  protected initPanelStatusSubscription(panelId: string) {
    const that = this;
    this.statusSubscription = this.panelService.subscribeToPanelStatuses(panelId, {
      next: (panelStatus) => {
        that.panelStatusRecieved(panelStatus);
      }
    });
  }

  protected initPanelSignalSubscription(panelId: string) {
    const that = this;
    this.signalSubscription = this.panelService.subscribeToPanelSignals(panelId, {
      next: (panelSignal) => {
        that.panelSignalRecieved(panelSignal);
      }
    });
  }

  protected newPanelStatusChange() {
    const statusChange = new PanelStatusChange();
    statusChange.oldStatus = this.cloneStatus();
    return statusChange;
  }

  private cloneStatus() {
    return JSON.parse(JSON.stringify(this.status));
  }

  protected openPncPanel() {
    if (this.debugOn) {
      console.log('EditablePanelElement::openPncPanel()', this);
    }
    if (this.isPanelEnabled()) {
      const panelId = this.pncAccordionPanelId();
      if (this.pncAccordion && panelId) {
        if (this.pncAccordion.panels) {
          this.pncAccordion.panels.find(p => p.id === panelId).disabled = false;
        }
        if (!this.pncAccordion.isExpanded(panelId)) {
          this.pncAccordion.expand(panelId);
          if (!this.isPanelOpened()) {
            this.openPanel();
          }
        }
      }
    }
  }

  protected isPncPanelOpen() {
    const panelId = this.pncAccordionPanelId();
    return this.pncAccordion.isExpanded(panelId);
  }

  protected closePncPanel() {
    if (this.debugOn) {
      console.log('EditablePanelElement::closePncPanel()', this);
    }
    if (this.isPanelEnabled()) {
      const panelId = this.pncAccordionPanelId();
      if (this.pncAccordion && this.isPncPanelOpen()) {
        if (this.isPncPanelOpen()) {
          this.pncAccordion.collapse(panelId);
          if (this.isPanelOpened()) {
            this.closePanel();
          }
        }
      }
    }
  }

  protected pncAccordionPanelId() {
    return this.panelId;
  }

  protected subscribeToPncUpdates() {
    const that = this;

    this.pncAccordion.panelChange.subscribe((changeEvent) => {
      that.pncPanelChangeEvent(changeEvent);
    });
  }

  protected pncPanelChangeEvent(changeEvent) {
    const panelId = this.pncAccordionPanelId();
    if (this.debugOn) {
      console.log('EditablePanelElement::pncPanelChangeEvent() - this', this);
      console.log('EditablePanelElement::pncPanelChangeEvent() - event', changeEvent);
      console.log('EditablePanelElement::pncPanelChangeEvent() - panelId', panelId);
    }
    if (changeEvent.panelId === panelId) {
      if (this.debugOn) {
        console.log('EditablePanelElement::pncPanelChangeEvent() - panelOK');
      }
      if (changeEvent.nextState) {
        if (!this.isPanelOpened()) {
          if (this.debugOn) {
            console.log('EditablePanelElement::pncPanelChangeEvent() - panelOpening');
          }
          this.openPanel();
        }
      } else {
        if (this.isPanelOpened()) {
          if (this.debugOn) {
            console.log('EditablePanelElement::pncPanelChangeEvent() - panelClosing');
          }
          this.closePanel();
        }
      }
    }
  }
}

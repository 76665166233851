import {TableSchema} from '@rgi/rx/ui';

export const PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'fiscalCode',
      title: '_ANAG_._LABEL_._FISCAL_CODE_'
    },
    {
      name: 'name',
      title: '_ANAG_._LABEL_._NAME_'
    },
    {
      name: 'surname',
      title: '_ANAG_._LABEL_._SURNAME_'
    }
  ],
  header: ['fiscalCode', 'name', 'surname']
};

export const PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA: TableSchema = {
  rows: [
    ...PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA.rows,
    {
      name: 'action',
      title: '_ANAG_._LABEL_._VOID_',
      actions: [
        {
          name: 'EDIT',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-edit2'
        }, {
          name: 'DELETE',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-delete',
        }
      ]
    }
  ],
  header: [...PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA.header, 'action']
};

export const PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA_LIM: TableSchema = {
  rows: [
    {
      name: 'name',
      title: '_ANAG_._LABEL_._NAME_'
    },
    {
      name: 'surname',
      title: '_ANAG_._LABEL_._SURNAME_'
    }
  ],
  header: ['fiscalCode', 'name', 'surname']
};

export const PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA_LIM: TableSchema = {
  rows: [
    {
      name: 'name',
      title: '_ANAG_._LABEL_._NAME_'
    },
    {
      name: 'surname',
      title: '_ANAG_._LABEL_._SURNAME_'
    },
    {
      name: 'action',
      title: '_ANAG_._LABEL_._VOID_',
      actions: [
        {
          name: 'EDIT',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-edit2'
        }, {
          name: 'DELETE',
          styleClass: 'rgi-ui-btn-outline rgi-ui-icon-delete',
        }
      ]
    }
  ],
  header: ['fiscalCode', 'name', 'surname', 'action']
};


import {Inject, NgModule, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonIddService} from './common-idd.service';
import { AjsIntegrationConfig, AJS_NG_PASSPROPRO_CONFIG } from '@rgi/ng-passpropro-core/portal'; // this is really bad
import { EvaluationResourceDefinition } from '@rgi/ng-passpropro';
import {AnagSubject} from "@rgi/ng-passpropro/questionnaire";
import {Source} from "@rgi/ng-passpropro/product";
import { AnagQuestionnaireVersionSaveRequest } from './model/integration-models';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [CommonIddService]
})
class IntegrationModule {


  constructor(commonIddService: CommonIddService, @Inject(AJS_NG_PASSPROPRO_CONFIG) @Optional() ajsIntegrationConfig: any|AjsIntegrationConfig) {
    if (ajsIntegrationConfig && ajsIntegrationConfig.passportal) {
      commonIddService.register();
    }
  }

}

export type SurveyInputFactor = {code:string, value:string};

class SurveyIntegrationFilter {
  versions?: Array<string>;
  products?: Array<string>;
  allowContinue?: boolean;
  subject?: AnagSubject;
  questionnaires?: Array<string>;
  userCode?: string;
  inputFactors?: SurveyInputFactor[];
  sources?: Array<Source>;
  lockedQuestions?: Array<{ code: string }>;
  resources?: EvaluationResourceDefinition[];
}


export {
  IntegrationModule,
  SurveyIntegrationFilter,
  CommonIddService,
  AnagQuestionnaireVersionSaveRequest
}


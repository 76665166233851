<div class="container-fluid table-white">
    <div class="row">
        <div class="col-sm-6 col-xs-12 ptb-05">
            {{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' | translate }}:
            <span>{{ subject?.fiscalCode || subject?.vatCode || '-' }}</span>
        </div>
        <div class="col-sm-6 col-xs-12 ptb-05">
            {{ '_CLAIMS_._ADDRESS' | translate }}:
            <span>{{ subject?.formatAddress || '-' }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-xs-12 ptb-05">
            {{ '_CLAIMS_._TELEPHONE' | translate }}:
            <span>{{ subject?.number || '-' }}</span>
        </div>
        <div class="col-sm-6 col-xs-12 ptb-05">
            {{ '_CLAIMS_._EMAIL' | translate }}:
            <span>{{ subject?.email || '-' }}</span>
            <!-- <span *ngIf="subject?.anagSubject?.pec">
                {{ 'Pec' | translate }}
                <span class="glyphicon glyphicon-ok"></span>
            </span> -->
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Inject, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DetailCancellationReturn, IComboListCancellation, INIT_DETAIL_CANCELLATION,
  ReasonCancelled, ResponseReasonCancelled, receiptCancellation } from './cancellation.interface';
import { NewPaymentService } from '../new-payment.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, formatDate } from '@angular/common';
import { DIALOG_DATA } from '@rgi/rx/ui';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-cancellation-modal',
  templateUrl: './cancellation-modal.component.html',
  styleUrls: ['./cancellation-modal.component.scss']
})
export class CancellationModalComponent implements OnInit {

  @Input() inputDetailCancellation: any; // Dati inviati dalla modale padre

  detailCancellationForm: UntypedFormGroup; // Form in cui inputare i dati
  detailCancellation: DetailCancellationReturn = INIT_DETAIL_CANCELLATION; // Valorizzazione dei dati da inviare al BE

  reasonCancelledSelected: ReasonCancelled; // Motivazione selezionata per l'annullamento Quitanza/Liquidazione
  reasonCancelledList: ReasonCancelled[] = []; // Elenco delle motivazione di annullamento Quietanza/Liquidazione

  today = new Date();
  currentReceiptDate: Date;

  idClaim: string = ''; // Id del sinistro
  receiptId: string = ''; // Id della quietanza
  modalTitle: string = ''; // Titolo della modale
  claimNumber: string = ''; // Numero del sinistro
  receiptDate: string = ''; // Data del sinistro
  operationType: string = ''; // Tipo di operazione di annullamento: quietanza (AQ) o liquidazione (AL)
  descErrOrTooltip: string = ''; // Descrizione errore / tooltip
  datepickerPlaceholder: string = ''; // Campo calendario del form
  ministerialBranchCodes: string = ''; // Ramo ministeriale

  enableButton: boolean = false; // Abilitazione del bottone "Conferma"

  modalClose = new EventEmitter<any>(); // Chiusura della modale

  constructor(
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private paymentService: NewPaymentService,
    public translateService: RgiRxTranslationService,
    @Optional() @Inject(DIALOG_DATA) data: any) {

    this.inputDetailCancellation = data.detailObject; // Dati ricevuti dalla modale padre
    this.inputDetailCancellation.selectedClaim = data.claimCurrent; // Dati del sinistro ricevuti dalla modale padre
    this.currentReceiptDate = data.currentReceiptDate; // Data della quietanza ricevuta dalla modale padre
    this.operationType = data.operationType;
  }

  ngOnInit() {
    this.initForm();

    this.modalTitle = (this.operationType === receiptCancellation)
      ? 'Receipt Cancellation' : 'Settlements Cancellation'; // Titolo dinamico se Quietanza o Liquidazione

    this.populateVariables();

    if (this.inputDetailCancellation) {
      if (this.operationType === receiptCancellation) { // Se annullo quietanza
        this.getReasonsReceiptDelete();
      } else { // Se annullo liquidazione
        this.getReasonsCancelSettlements();
      }
    }

    this.enableConfirmButton();
  }

  /**
   * Inizializzazione del form con i relativi validatori
   */
  initForm() {
    this.detailCancellationForm = this.formBuilder.group({
      note: [''],
      reasonCancelled: [''],
      dateCancellation: [new Date(), [Validators.required, Validators.max, Validators.min]]
    });
  }

  /**
   * Valorizzazione delle variabili utili alla modale
   */
  populateVariables() {
    if (this.inputDetailCancellation.selectedClaim) {
      this.idClaim = this.inputDetailCancellation.selectedClaim.idClaim ? this.inputDetailCancellation.selectedClaim.idClaim : '';
      this.receiptDate = this.inputDetailCancellation.selectedClaim.reportDate ? this.inputDetailCancellation.selectedClaim.reportDate : '';
      this.claimNumber = this.inputDetailCancellation.selectedClaim.claimNumber
        ? this.inputDetailCancellation.selectedClaim.claimNumber : '';
      this.ministerialBranchCodes = this.inputDetailCancellation.selectedClaim.ministerialBranchCodes
        ? this.inputDetailCancellation.selectedClaim.ministerialBranchCodes : '';
      this.receiptId = this.inputDetailCancellation.paramsIntegrationPass.NAVI_PAR_SETTLEMENTID
        ? this.inputDetailCancellation.paramsIntegrationPass.NAVI_PAR_SETTLEMENTID : '';
    }
  }

  /**
   * Chiusura della modale
   * @param responseBody Dati inviati al chiamante per l'elaborazione del BE
   */
  closeModal(responseBody: any) {
    this.detailCancellation = INIT_DETAIL_CANCELLATION;
    this.modalClose.emit(responseBody);
  }

  /**
   * Evento scatenato alla selezione del bottone "Conferma" presente nella modale.
   * Viene verificata la validazione del form e dei relativi dati inseriti
   */
  confirmCancellationReturn() {
    if (this.validationForm() && this.detailCancellationForm.valid) {
      this.detailCancellation.dateReceipt = this.formatDateTimezone(this.detailCancellationForm.get('dateCancellation').value);
      this.detailCancellation.note = this.detailCancellationForm.get('note').value;
      this.detailCancellation.reasonCancelledSelected = this.detailCancellationForm.get('reasonCancelled').value === ''
      ? null : this.detailCancellationForm.get('reasonCancelled').value;
      this.detailCancellation.claimNumber = this.claimNumber;
      this.detailCancellation.receiptId = this.receiptId; // Id quietanza
      this.closeModal(this.detailCancellation);
    }
  }

  /**
   * Recupero dell'elenco delle motivazioni di annullamento quietanza dal servizio esposto dal BE
   */
  getReasonsReceiptDelete() {
    const body: IComboListCancellation = {
      receiptIdMotivoAnnullo: '10',
      receiptDate: this.formatDateTimezone(this.receiptDate),
      receiptIdRamoMinisteriale: this.ministerialBranchCodes,
      receiptId: this.receiptId
    };
    this.paymentService.getReasonsReceiptDeleteList(body, this.claimNumber).subscribe((res: ResponseReasonCancelled) => {
      if (res) {
        this.reasonCancelledList.push(...this.initComboReasonCancelledList(res));
        this.validationForm();
      }
    });
  }

  /**
   * Recupero dell'elenco delle motivazioni di annullamento liquidazione dal servizio esposto dal BE
   */
  getReasonsCancelSettlements() {
    const body: IComboListCancellation = {
      receiptIdMotivoAnnullo: '10',
      receiptDate: this.formatDateTimezone(this.receiptDate),
      receiptIdRamoMinisteriale: this.ministerialBranchCodes,
      receiptId: this.receiptId
    };
    this.paymentService.getReasonsCancelSettlementsList(body, this.claimNumber).subscribe((res: ResponseReasonCancelled) => {
      if (res) {
        this.reasonCancelledList.push(...this.initComboReasonCancelledList(res));
        this.validationForm();
      }
    });
  }

  /**
   * Validazione dei dati inseriti nel form
   * @returns Ritorna true se il form è valido altrimenti false
   */
  validationForm(): boolean {
    this.detailCancellationForm.get('dateCancellation').setValidators(Validators.required);
    const dateValue = this.detailCancellationForm.get('dateCancellation').value;
    if (dateValue === null || dateValue === '' || !this.checkDate(dateValue)) {
      return false;
    }
    if (this.reasonCancelledList.length > 0) {
      this.detailCancellationForm.get('reasonCancelled').setValidators(Validators.required);
      this.detailCancellationForm.get('note').setValidators(Validators.nullValidator);
      this.detailCancellationForm.get('note').setErrors(null);
      if (this.detailCancellationForm.get('reasonCancelled').value === '') {
        this.translateService.translate('_CLAIMS_._SELECT_REASON_CANCELLATION').subscribe(res=> {
          this.descErrOrTooltip=res;
        }
        )
        return false;
      }
    } else {
      this.detailCancellationForm.get('note').setValidators(Validators.required);
      if (this.detailCancellationForm.get('note').value === '') {
        this.descErrOrTooltip = 'Enter notes';
        return false;
      }
    }
    return true;
  }

  /**
   * Popolazione della variabile, utilizzata nel form, contenente l'elenco delle motivazioni di annullamento
   * @param lista Contiene il JSON di risposta del servizio esposto
   * @returns Ritorna la lista delle motivazioni di annullamento
   */
  initComboReasonCancelledList(lista: ResponseReasonCancelled): ReasonCancelled[] {
    const reasonCancelledComboList: ReasonCancelled[] = [];
    const items = lista.reasonCancellation;

    if (items.length > 0 && items[0] !== undefined && items[0] !== null && items[0].length > 0) {
      this.initReasonCancelledList();

      const codeReason = items[0];
      const valueReason = items[1];

      codeReason.forEach((element, index) => {
        const combo: ReasonCancelled = {
          code: element,
          description: valueReason[index]
        };
        reasonCancelledComboList.push(combo);
      });

    }

    return reasonCancelledComboList;
  }

  /**
   * Inizializzazione della lista delle motivazioni di annullamento
   */
  initReasonCancelledList() {
    this.reasonCancelledList.push(
      {
        code: '',
        description: ''
      }
    );
  }

  /**
   * Metodo utilizzato per la formattazione delle date
   * @param date Riceve in input una stringa contenente una data
   * @returns Ritorna una data, come stringa, formattata 'dd-MM-yyyy'
   */
  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    const dataOutput = this.datePipe.transform(miaData, 'dd-MM-yyyy');
    return dataOutput;
  }

  /**
   * Abilita/Disabilita il bottone "Conferma" presente nel form
   */
  enableConfirmButton() {
    if (this.validationForm()) {
      this.enableButton = true;
    } else {
      this.enableButton = false;
    }
  }

  /**
   * Esegue la validazione delle date dopo averle formattate secondo il formato 'yyyy-MM-dd'
   * @param data Riceve in input una stringa contenente la data selezionata nel calendario
   * @returns Ritorna true se la data rispetta il range imposto (valida) altrimenti false (non valida)
   */
  checkDate(data: string): boolean {
    const calendarDate = formatDate(new Date(data), 'yyyy-MM-dd', 'it_IT');
    const todayDate = formatDate(this.today, 'yyyy-MM-dd', 'it_IT');
    const minDate = formatDate(this.currentReceiptDate, 'yyyy-MM-dd', 'it_IT');

    if (calendarDate <= todayDate && calendarDate >= minDate) {
      this.detailCancellationForm.get('dateCancellation').setErrors(null);
      this.descErrOrTooltip = '';
      return true;
    } else {
      this.detailCancellationForm.get('dateCancellation').setErrors({ require: true });
      this.translateService.translate('_CLAIMS_._THE_CANCELLATION_DATE_IS_NOT_VALID').subscribe(res=> {
        this.descErrOrTooltip=res;
      }
      )
      return false;
    }
  }

}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Lien} from '../../models/domain-models/lien';
import {MicHttpService} from '../../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class LiensService {

  private baseApiUrlV2;
  private contractEndpoint;
  protected httpClient: HttpClient;

  private liens: Array<Lien> = new Array<Lien>();
  private bindingEntityMandatory: boolean;
  private effectiveDateMandatory: boolean;
  private expirationDateMandatory: boolean;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any
  ) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.contractEndpoint = this.baseApiUrlV2 + '/portfolio/contract';

    this.httpClient = this.micHttpClient.getHttpClient();
  }

  getLiens(contractId: string) {
    return this.httpClient.get<Array<Lien>>(this.contractEndpoint + '/' + contractId + '/bonds');
  }

  updateLiens(contractId: string, liens: Array<Lien>) {
    return this.httpClient.put<Array<Lien>>(this.contractEndpoint + '/' + contractId + '/bonds', liens);
  }

  setLiensList(liens: Array<Lien>) {
    return this.liens = liens;
  }

  getLiensList(): Lien[] {
    return this.liens;
  }

  addLien(lien: Lien) {
    this.liens.push(lien);
  }

  clearLiensList() {
    this.liens.length = 0;
  }

  removeLien(id) {
    // capire se si può aggiungere l'unsubscribe
    this.liens = this.liens.filter(
      (lien: Lien) => {
        return lien.id !== id;
      }
    );
  }

  getNewLienId() {
    let id = Math.max.apply(Math, this.getLiensList().map(
      (lien: Lien) => lien.id
    )) + 1;

    if (id < 0) {
      id = 1;
    }
    return id;
  }

  getBindingEntityMandatory() {
    return this.bindingEntityMandatory;
  }

  setBindingEntityMandatory(bindingEntityMandatory: boolean) {
    this.bindingEntityMandatory = bindingEntityMandatory;
  }

  getEffectiveDateMandatory() {
    return this.effectiveDateMandatory;
  }

  setEffectiveDateMandatory(effectiveDateMandatory: boolean) {
    this.effectiveDateMandatory = effectiveDateMandatory;
  }

  getExpirationDateMandatory() {
    return this.expirationDateMandatory;
  }

  setExpirationDateMandatory(expirationDateMandatory: boolean) {
    this.expirationDateMandatory = expirationDateMandatory;
  }

}

import {Component, ContentChildren, EventEmitter, OnDestroy, OnInit, Output, QueryList} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {ModalService} from '@rgi/rx/ui';
import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {GroupPolicyDateAdapter} from '../../adapters/ngbDateCustomParserFormatter ';
import {
  GroupPolicyModalCoinsurancesComponent
} from '../../group-policy-components/group-policy-modals/group-policy-modal-coinsurances/group-policy-modal-coinsurances.component';
import {CONTRACT_STATUS, dateRejex, ibanRejex} from '../../group-policy-constants/general';
import {DragActionExtDirective} from '../../group-policy-ext/group-policy-ext-directive/drag-action-ext.directive';
import {ACTION_PARTY_ROLE_ENUM, PartyRoleParentData} from '../../group-policy-models/gp-party-role';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {
  ContractAddressesPut,
  ErrorCode,
  ErrorResp,
  Factor,
  GPClause,
  GPPolicyFieldUpdate,
  GroupPolicyContactsManagement,
  PaymentsField,
  PaymentsPayload,
  PortfolioContactsManagement
} from '../../group-policy-models/group-policy-issue-policy-data';
import {GroupPolicyCrossService} from '../../group-policy-services/group-policy-cross.service';
import {GroupPolicyIntegrationService} from '../../group-policy-services/group-policy-integration.service';
import {GroupPolicyStatePolicyData} from '../../group-policy-state/group-policy-state';
import {
  GroupPolicyStatemanagerPolicyData
} from '../../group-policy-state/group-policy-statemanager-policy-data/group-policy-statemanager-policy-data';
import {GPVariableService} from '../../group-policy-services/gpvariable.service';

@Component({
  selector: 'rgi-gp-group-policy-policy-data',
  templateUrl: './group-policy-policy-data.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyPolicyDataComponent extends RoutableComponent implements OnInit, OnDestroy {

  public static readonly convention = 'convention';
  public static readonly paymentFrequency = 'paymentFrequency';
  public static readonly indexing = 'indexing';
  public static readonly rolesPartyForm = 'rolesPartyForm';
  public static readonly debMeansOfPayment = 'debMeansOfPayment';
  public static readonly credMeansOfPayment = 'credMeansOfPayment';
  public static readonly debit = 'debit';
  public static readonly credit = 'credit';
  public static readonly ciban = 'ciban';
  public static readonly dcardexpire = 'dcardexpire';

  constructor(
    public activeRoute: ActiveRoute,
    protected dateAdapter: GroupPolicyDateAdapter,
    protected stateManagerPolicyData: GroupPolicyStatemanagerPolicyData,
    protected modalService: ModalService,
    protected integrationService: GroupPolicyIntegrationService,
    protected crossService: GroupPolicyCrossService,
    protected translationService: RgiRxTranslationService,
    protected variableService: GPVariableService) {
    super();
  }

  @ContentChildren(DragActionExtDirective, { descendants: true }) dragExtensions: QueryList<DragActionExtDirective>;
  @Output() eventPropagation = new EventEmitter<any>();
  public statePolicyData: GroupPolicyStatePolicyData;
  public previousStepData: DataForSteps;
  public groupPolicyContactsManagement = GroupPolicyContactsManagement;
  public portfolioContactsManagement = PortfolioContactsManagement;
  public contractAddresses: ContractAddressesPut;
  public contractStates = CONTRACT_STATUS;
  public paymentValidationChecker: Subject<void> = new Subject<void>();
  isPaymentOk = true;
  public policyDataForm = new UntypedFormGroup({});

  ngOnInit() {
    this.previousStepData = this.activeRoute.getRouteData();
    this.stateManagerPolicyData.getState$().pipe(
      tap((state: GroupPolicyStatePolicyData) => {
        this.createFormControls(state);
      })
    ).subscribe((nextState: GroupPolicyStatePolicyData) => {
      this.initPage(nextState);
    });
  }

  protected initPage(nextState: GroupPolicyStatePolicyData) {
    this.statePolicyData = nextState;
    if (this.statePolicyData.factors) {
      this.updateVariablesForm(this.statePolicyData.factors);
    }
    this.checkTotalCoinsurancePercentage();
    this.paymentValidationChecker.next();
  }

  protected createFormControls(state: GroupPolicyStatePolicyData) {
    if (state.conventions) {
      const value = state.conventions.value ? state.conventions.value.code : '';
      this.policyDataForm.addControl('convention', new UntypedFormControl(value));
      if (!state.conventions.editableValue) {
        this.policyDataForm.controls.convention.disable();
      } else {
        this.policyDataForm.controls.convention.enable();
      }
    }

    if (state.indexing) {
      const value = state.indexing.value ? state.indexing.value.code : '';
      this.policyDataForm.addControl('indexing',
        new UntypedFormControl(value, state.indexing.mandatory ? Validators.required : []));
    }

    if (state.paymentFrequencies) {
      const value = state.paymentFrequencies.value ? state.paymentFrequencies.value.code : '';
      this.policyDataForm.addControl('paymentFrequency',
        new UntypedFormControl(value, Validators.required));
      if (!state.paymentFrequencies.editableValue) {
        this.policyDataForm.controls.paymentFrequency.disable();
      } else {
        this.policyDataForm.controls.paymentFrequency.enable();
      }
    }
  }

  ngOnDestroy(): void { }

  public callActionGoToConfigurationPm() {
    this.stateManagerPolicyData.actionGoToConfigurationPm(this.previousStepData.resId, this.contractAddresses,
      this.statePolicyData.groupPolicyContactsManagement !== this.groupPolicyContactsManagement.NONE);
  }

  public callActionSave() {
    this.stateManagerPolicyData.actionSave(this.previousStepData, this.contractAddresses,
      this.statePolicyData.groupPolicyContactsManagement !== this.groupPolicyContactsManagement.NONE);
  }

  public callActionSystemProp() {
    this.stateManagerPolicyData.getSystemProp(this.previousStepData);
  }

  public updateAgreement() {
    const agreement = this.policyDataForm.get(GroupPolicyPolicyDataComponent.convention).value ?
      this.policyDataForm.get(GroupPolicyPolicyDataComponent.convention).value : null;
    this.stateManagerPolicyData.actionUpdateAgreement(this.previousStepData, agreement);
  }

  public updatePaymentFrequency() {
    const paymentFrequency = this.policyDataForm.get(GroupPolicyPolicyDataComponent.paymentFrequency).value ?
      this.policyDataForm.get(GroupPolicyPolicyDataComponent.paymentFrequency).value : null;
    this.stateManagerPolicyData.actionUpdatePaymentFrequency(this.previousStepData, paymentFrequency);
  }

  public updateIndexing() {
    const indexing = this.policyDataForm.get(GroupPolicyPolicyDataComponent.indexing).value ?
      this.policyDataForm.get(GroupPolicyPolicyDataComponent.indexing).value : null;
    this.stateManagerPolicyData.actionSetIndexing(indexing, this.previousStepData);
  }

  public updateFactors(variableToUpdate: Factor) {
    const variableControl = this.variablesFG.get(variableToUpdate.code);
    this.variableService.updateVariableInList(variableToUpdate, variableControl.value, this.statePolicyData.factors);
    this.stateManagerPolicyData.actionUpdateFactors(this.previousStepData, this.statePolicyData.factors);
  }

  public updateContacts(contractAddresses: ContractAddressesPut) { this.contractAddresses = contractAddresses; }

  protected updateVariablesForm(variables: Factor[]) {
    if (this.variablesFG) {
      this.variableService.updateVariablesFormGroup(variables, this.variablesFG);
    } else {
      this.policyDataForm.addControl('variablesFG', this.variableService.createVariablesFormGroup(variables));
    }
  }

  protected cleanPaymentForm(type: string) {
    Object.keys(this.policyDataForm.controls).forEach(key => {
      if (type === GroupPolicyPolicyDataComponent.credit && key.indexOf('cred_') > -1) {
        this.policyDataForm.removeControl(key);
      }
      if (type === GroupPolicyPolicyDataComponent.debit && key.indexOf('deb_') > -1) {
        this.policyDataForm.removeControl(key);
      }
    });
    this.policyDataForm.updateValueAndValidity();
  }

  protected intiPaymentFormFields(type: string, paymentFields: PaymentsField[]) {
    if (paymentFields && paymentFields.length > 0) {
      paymentFields.forEach((field: PaymentsField) => {
        const validators = [];
        if (field.mandatory) {
          validators.push(Validators.required);
        }
        if (field.name === GroupPolicyPolicyDataComponent.ciban) {
          validators.push(Validators.pattern(ibanRejex));
        }
        if (field.name === GroupPolicyPolicyDataComponent.dcardexpire) {
          validators.push(Validators.pattern(dateRejex));
        }
        const control = new UntypedFormControl(field.value, validators);
        if (type === GroupPolicyPolicyDataComponent.credit) {
          this.policyDataForm.addControl('cred_' + field.name, control);
        } else if (type === GroupPolicyPolicyDataComponent.debit) {
          this.policyDataForm.addControl('deb_' + field.name, control);
        }
      });
      this.policyDataForm.updateValueAndValidity();
    }
  }

  public back() {
    if (this.statePolicyData.fromInquiryInfo.isFromInquiry &&
      this.statePolicyData.fromInquiryInfo.idParentSession &&
      this.statePolicyData.fromInquiryInfo.proposalNunmber) {
      this.integrationService.backToInquiry(this.statePolicyData.fromInquiryInfo.proposalNunmber,
        this.id,
        this.statePolicyData.fromInquiryInfo.idParentSession);
    }
  }

  public onFieldAction(code: string) {
    switch (code) {
      case 'COINSURANCE_OUR_PERCENTAGE':
      case '1PCOAS':
        this.openCoinsurancesModal();
        break;
    }
  }

  public openCoinsurancesModal() {
    const coinsuranceModal = this.modalService.openComponent(GroupPolicyModalCoinsurancesComponent, this.statePolicyData.coinsurances);
    coinsuranceModal.modal.enableClickBackground = false;
    coinsuranceModal.modal.onClose.subscribe(coinsuranceShares => {
      if (coinsuranceShares) {
        this.stateManagerPolicyData.actionSetCoinsurancesShareData(coinsuranceShares, this.previousStepData);
      }
    });
  }

  protected checkTotalCoinsurancePercentage() {
    const msg = {
      level: ErrorCode.BLOCKING,
      code: 'COINSURANCE_ERROR',
    } as ErrorResp;
    this.translationService.translate('_GP_._LABEL_._TOTAL_PERC_ERROR_').subscribe(translated => msg.message = translated);
    if (this.statePolicyData.errors && this.statePolicyData.errors.includes(msg)) {
      const index = this.statePolicyData.errors.findIndex(err => err.message === msg.message);
      delete this.statePolicyData.errors[index];
    }

    if (this.isTotalCoinsurancesPercentageOver()) {
      if (this.statePolicyData.errors === undefined) {
        this.statePolicyData.errors = [];
      }
      this.statePolicyData.type = ErrorCode.BLOCKING;
      this.statePolicyData.errors.push(msg);
    }
  }

  protected getCoinsurancePercentageVariable() {
    return this.statePolicyData.factors.find(el => el.code === '1PCOAS');
  }

  protected getCoinsurancePercentageField() {
    return this.statePolicyData.policyDataFields.find(el => el.code === 'COINSURANCE_OUR_PERCENTAGE');
  }

  public getCoinsuranceOurPercentage() {
    const percentageField = this.getCoinsurancePercentageField();
    if (percentageField) {
      return percentageField.valueNumb ? percentageField.valueNumb : 0;
    }

    const percentageVariable = this.getCoinsurancePercentageVariable();
    return percentageVariable && percentageVariable.value ? Number(percentageVariable.value) : 0;
  }

  public isTotalCoinsurancesPercentageOver() {
    let totalPercentage = this.getCoinsuranceOurPercentage();
    this.statePolicyData.coinsurances.coinsuranceShares.forEach(el => totalPercentage += el.percentageShare);
    return totalPercentage > 100;
  }

  public updateClauses(event: GPClause[]) {
    this.stateManagerPolicyData.actionUpdateClauses(event, this.previousStepData);
  }

  public disableContinue() {
    return this.policyDataForm.invalid ||
      !this.statePolicyData.allRoleParties[1][0].idParty ||
      this.checkClauses() ||
      !this.isPaymentOk ||
      ( this.statePolicyData.errors && this.statePolicyData.errors.filter( err => err.code && !err.code.startsWith('FOOTER') && err.level === ErrorCode.BLOCKING ).length > 0);
  }

  public checkClauses() {
    if (this.statePolicyData.clauses && !!this.statePolicyData.clauses.length) {
      for (const clause of this.statePolicyData.clauses) {
        if (clause.mandatoryText && (!clause.text || clause.text.trim().length === 0)) {
          return true;
        }
      }
    }
    return false;
  }

  public setPaymentMethod(payload: PaymentsPayload) {
    this.stateManagerPolicyData.setPaymentMethod(payload, this.previousStepData);
  }

  public onPolicyDataUpdate($event: GPPolicyFieldUpdate) {
    if ($event) {
      this.stateManagerPolicyData.actionUpdatePolicyData($event.updatedFieldList, this.previousStepData);
    }
  }

  onPolicyDataExtensionUpdate($event: GPPolicyFieldUpdate) {
    if ($event) {
      this.stateManagerPolicyData.actionUpdateExtensionData($event.updatedFieldList, this.previousStepData);
    }
  }

  onPartyAction(partyData: PartyRoleParentData) {
    if (!!partyData) {
      switch (partyData.action) {
        case ACTION_PARTY_ROLE_ENUM.EDITPARTY:
          this.stateManagerPolicyData.actionModifySubject(partyData.idParty);
          break;
        case ACTION_PARTY_ROLE_ENUM.SETPARTYSELECTED:
          this.stateManagerPolicyData.actionSetPartyRole(partyData.party, this.previousStepData, partyData.role);
          break;
        case ACTION_PARTY_ROLE_ENUM.DELETEPARTYROLE:
          this.stateManagerPolicyData.actionDeleteRole(this.previousStepData, partyData.role, partyData.idParty);
          break;
        case ACTION_PARTY_ROLE_ENUM.ADDPERCENTAGEONROLE:
          this.stateManagerPolicyData.actionSetPercentageOnRole(partyData, this.previousStepData);
      }
    }
  }

  evalIntermediateSaveVisibility() {
    return this.crossService.isContractStatusUndefined(this.previousStepData);
  }

  get variablesFG() {
    return this.policyDataForm.get('variablesFG') as UntypedFormGroup;
  }
}

import { Component, OnInit, Inject, LOCALE_ID, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import {
  ActivityDictionary,
  ActivityGroup,
  ActivityUser,
  ApiClaim,
  ActivityDto,
  Outcome,
  ActivityBusinessKeyClaim,
  ActivityTask,
  ActivityVariable,
  ApiTranslatorClaimService,
  Activityconstants
} from '@rgi/digital-claims-common-angular';
import { ApiParty } from '@rgi/digital-claims-common-angular/lib/model/api/api-party';
import { ApiTypeOfLoss } from '@rgi/digital-claims-common-angular/lib/model/api/api-type-of-loss';
import { ManualDeadlineService } from './manual-deadline.service';
import { formatDate } from '@angular/common';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-manual-deadline',
  templateUrl: './manual-deadline.component.html',
  styleUrls: ['./manual-deadline.component.scss']
})
export class ManualDeadlineComponent implements OnInit {

  private static PROPERTY_SET_DEFAULT_USER: string = 'ClaimsActivitiesWorkloadDefaultUser';
  private static PROPERTY_ENABLED: string = 'Enable';
  private static PROPERTY_GROUP_DEFAULT = 'GroupCodeDefault';

  public setDefaultUser: boolean = false;

  enabled: boolean = true;
  formGroup: UntypedFormGroup;
  hasError: boolean = false;
  errorMessage: string = null;
  errorMessages = [];
  validationMessages: string[];
  idUserIns: number;
  aniaCompanyCode: string;

  // FORM CONTROL VARIABLE
  insDate: UntypedFormControl;
  expirationDate: UntypedFormControl;
  idActDict: UntypedFormControl;
  involvedParty: UntypedFormControl;
  typeOfLoss: UntypedFormControl;
  message: UntypedFormControl;
  assignedToGroup: UntypedFormControl;
  assigneeGroup: UntypedFormControl;
  assigneeUser: UntypedFormControl;

  // SELECTED + CHECKED
  selectedDamagePosition: any;
  selectedGroup: any;
  groupCheckChecked: boolean = false;

  // BEANS + DTOs
  categories: ActivityDictionary[];
  damagePositions: ApiParty[];
  filteredTypeOfLosses: ApiTypeOfLoss[];
  groups: ActivityGroup[];
  filteredActivityUsers: ActivityUser[];
  apiClaim: ApiClaim;
  activityDto: ActivityDto;
  activityBusinessKeyBean: ActivityBusinessKeyClaim;
  activityTaskBean: ActivityTask;
  activityVariableBeanClaimNumber: ActivityVariable;
  activityVariableBeanAniaCompanyCode: ActivityVariable;
  activityVariableBeanPotClaimNumber: ActivityVariable;

  constructor(
    @Inject('containerCacheService') private cache: any,
    @Inject('authService') private auth: any,
    @Inject('eventService') private eventService: any,
    @Inject(LOCALE_ID) private locale: string,
    private activityConstants: Activityconstants,
    @Inject('getSystemProperty') private getSystemProperty: any,
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private manualDeadlineService: ManualDeadlineService,
    public translateService: RgiRxTranslationService
  ) { }

  ngOnInit() {
    this.damagePositions = this.apiClaim.claimParties;
    this.aniaCompanyCode = this.apiClaim.aniaCompanyCode;
    this.idUserIns = this.auth.getOperator().objectId;
    const DICT_AREA_REMINDER = '6';
    this.getActivityDictionariesByArea(DICT_AREA_REMINDER);
    this.getActivityGroups();
    this.initDataForm();
    this.getProperties();
  }

  getActivityDictionariesByArea(area: string) {

    this.manualDeadlineService.getActivityDictionariesByArea(area)
      .subscribe((foundActivityDictionaries: ActivityDictionary[]) => {

        if (foundActivityDictionaries.length > 0) {
          this.categories = foundActivityDictionaries;
        }
      });
  }

  // INIT FORM

  initDataForm() {

    const today = new Date();

    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({
        insDate: [today],
        expirationDate: [, Validators.required],
        idActDict: [, Validators.required],
        involvedParty: [, Validators.required],
        typeOfLoss: [, Validators.required],
        message: [, Validators.required],
        assignedToGroup: [],
        assigneeGroup: [, Validators.required],
        assigneeUser: []
      }, {
        validator: [this.validateExpirationDate('expirationDate')]
      });
      this.formGroup.get('insDate').disable();
      this.formGroup.get('assigneeUser').disable();
    }

    if (this.damagePositions && this.damagePositions.length === 1) {
      this.formGroup.get('involvedParty').setValue(this.damagePositions[0].identifier);
      this.selectedDamagePosition = this.damagePositions[0].identifier;
      this.getTypeOfLossesByDamagePosition(this.selectedDamagePosition);
    }
  }

  onCategorySelected(event: any) {
    const idActSel = Number(event.target.value);
    const aDict = this.categories.find(el => el.idActDict === idActSel);

    if (aDict && aDict.actConfigurationBean) {
      const defGroup = aDict.actConfigurationBean.find(conf => conf.ruleName === ManualDeadlineComponent.PROPERTY_GROUP_DEFAULT);

      if (defGroup) {
        this.formGroup.get('assigneeGroup').setValue(defGroup.ruleValue);
        this.selectedGroup = defGroup.ruleValue;
        this.getActivityUsersByGroup(this.selectedGroup);
        this.formGroup.get('assigneeUser').enable();
      }
    }
  }



  // CATEGORY, DAMAGE POSITION & TYPE OF LOSS

  onDamagePositionOptionSelected(event: any) {
    this.selectedDamagePosition = event.target.value;
    this.getTypeOfLossesByDamagePosition(this.selectedDamagePosition);
  }

  getTypeOfLossesByDamagePosition(selectedDamagePosition: any) {
    this.damagePositions.forEach(damagePosition => {
      if (damagePosition.identifier === selectedDamagePosition) {
        this.filteredTypeOfLosses = damagePosition.damageDescriptions;
      }
    });
    if (this.filteredTypeOfLosses && this.filteredTypeOfLosses.length === 1) {
      this.formGroup.get('typeOfLoss').setValue(this.filteredTypeOfLosses[0].damagesDescriptionId);
    }
  }

  // GROUP & USER

  onGroupCheckboxChange(event: any) {
    // console.log(event.target.checked);
    this.groupCheckChecked = event.target.checked;
    if (this.groupCheckChecked) {
      this.formGroup.get('assigneeUser').reset();
      this.formGroup.get('assigneeUser').disable();
    } else {
      this.formGroup.get('assigneeGroup').reset();
    }
  }

  onGroupOptionSelected(event: any) {
    this.selectedGroup = event.target.value;
    if (!this.groupCheckChecked && this.selectedGroup !== '') {
      this.getActivityUsersByGroup(this.selectedGroup);
      this.formGroup.get('assigneeUser').enable();
    }
  }

  // VALIDATION

  validateExpirationDate(controlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      if (control.value) {
        if (control.value instanceof Date) {
          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if (inputDate < currentDate) {
            control.setErrors({ futureDate: true });
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({ invalidDate: true });
        }
      } else {
        const validator: any = control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  // SERVICES CALL

  getActivityGroups() {
    this.manualDeadlineService.getActivityGroups()
      .subscribe((foundActivityGroups: ActivityGroup[]) => {
        if (foundActivityGroups.length > 0) {
          this.groups = foundActivityGroups;
        }
      });
  }

  getActivityUsersByGroup(selectedGroup: any) {
    this.groups.forEach(group => {
      if (group.code === selectedGroup) {
        this.manualDeadlineService.getActivityUsersByGroup(selectedGroup)
            .subscribe((foundActivityUsers: ActivityUser[]) => {
        if (foundActivityUsers.length > 0) {
            this.filteredActivityUsers = foundActivityUsers;
            if (this.setDefaultUser) {
              let realName = this.filteredActivityUsers[0].userRealName;
              this.translateService.translate('_CLAIMS_._SELECT_BY_DEFAULT')
              .subscribe(res =>  realName += ' (' +res + ')')
              this.filteredActivityUsers[0].userRealName = realName;
              this.formGroup.controls.assigneeUser.setValue(this.filteredActivityUsers[0].code);
            }
        }
      });
      }
    });
  }

  saveActivity(activityDto: ActivityDto) {
    this.manualDeadlineService.saveActivity(activityDto).subscribe(
      (response: Outcome) => {
        if (response.code === Outcome.OK) {
          this.activeModal.close(response);
        } else {
          this.validationMessages = [response.descr];
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error: Error) => {
        console.error(error);
        this.validationMessages = ['Saving error'];
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  // COMANDI MODALE

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  onConfirm() {
    if (this.formGroup.valid) {
      this.eventService.broadcastEvent('start-loader');
      this.activityDto = new ActivityDto();
      this.activityDto.assigneeGroup = this.formGroup.get('assigneeGroup').value;
      this.activityDto.assigneeUser = this.formGroup.get('assigneeUser').value;
      this.activityDto.expirationDate = this.formGroup.get('expirationDate').value;
      this.activityDto.message = this.formGroup.get('message').value;
      this.activityDto.idActDict = this.formGroup.get('idActDict').value;
      this.activityDto.expirationDate = this.formatDate(this.formGroup.get('expirationDate').value);

      this.setAdditionalInfoToActivityDto();
      this.saveActivity(this.activityDto);
    }
    return null;
  }

  setAdditionalInfoToActivityDto() {
    this.activityBusinessKeyBean = new ActivityBusinessKeyClaim();
    this.activityTaskBean = new ActivityTask();
    this.activityVariableBeanClaimNumber = new ActivityVariable();
    this.activityVariableBeanAniaCompanyCode = new ActivityVariable();
    this.activityVariableBeanPotClaimNumber = new ActivityVariable();

    if (this.activityDto) {
      this.activityDto.claimNumber = this.apiClaim.claimNumber;
      this.activityDto.idUserIns = this.idUserIns;
      this.activityDto.decoupStatus = 2;
      this.activityDto.operationType = 1;
      this.activityDto.status = 1;
      this.activityDto.triggerEvent = 1;
      this.activityDto.priority = 2;
      this.activityDto.integrationType = 1;
      this.activityDto.title = null;
      this.activityDto.instanceId = this.activityConstants.INSTANCE_ID_NO_BPM;
      this.activityDto.potClaimNumber = this.apiClaim.reportNumber;
      this.activityBusinessKeyBean.idParty = this.formGroup.get('involvedParty').value;
      this.activityBusinessKeyBean.idTypeOfLoss = this.formGroup.get('typeOfLoss').value;
      this.activityBusinessKeyBean.idClaim = this.apiClaim.idClaim;
      this.activityBusinessKeyBean.idUserIns = this.idUserIns;
      this.activityDto.actBusinessKeyClaimBean = this.activityBusinessKeyBean;
      this.activityBusinessKeyBean.idPotClaim = this.apiClaim.idPotentialClaim;
      this.activityDto.actTaskBean = [];
      this.activityTaskBean.assigneeGroup = this.formGroup.get('assigneeGroup').value;
      this.activityTaskBean.assigneeUser = this.formGroup.get('assigneeUser').value;
      this.activityTaskBean.idActDict = this.formGroup.get('idActDict').value;
      this.activityTaskBean.message = this.formGroup.get('message').value;
      this.activityTaskBean.expirationDate = this.formatDate(this.formGroup.get('expirationDate').value);
      this.activityTaskBean.idUserIns = this.idUserIns;
      this.activityTaskBean.taskId = this.activityConstants.TASK_ID_NO_BPM;
      this.activityTaskBean.idActTask = null;
      this.activityTaskBean.priority = 2;
      this.activityTaskBean.triggerEvent = 1;
      this.activityTaskBean.operationType = 1;
      this.activityTaskBean.decoupStatus = 2;
      this.activityTaskBean.status = 1;
      this.activityDto.actTaskBean.push(this.activityTaskBean);

      this.activityDto.actVariableBean = [];
      this.activityVariableBeanClaimNumber.varName = 'NumSinistro';
      this.activityVariableBeanClaimNumber.varType = 1;
      this.activityVariableBeanClaimNumber.varValueStr = this.apiClaim.claimNumber;
      this.activityVariableBeanClaimNumber.idUserIns = this.idUserIns;
      this.activityVariableBeanClaimNumber.idUserUpd = this.idUserIns;
      this.activityDto.actVariableBean.push(this.activityVariableBeanClaimNumber);
      this.activityVariableBeanAniaCompanyCode.varName = 'Compagnia';
      this.activityVariableBeanAniaCompanyCode.varValueStr = this.aniaCompanyCode;
      this.activityVariableBeanAniaCompanyCode.varType = 1;
      this.activityVariableBeanAniaCompanyCode.idUserIns = this.idUserIns;
      this.activityVariableBeanAniaCompanyCode.idUserUpd = this.idUserIns;
      this.activityDto.actVariableBean.push(this.activityVariableBeanAniaCompanyCode);
      this.activityVariableBeanPotClaimNumber.varName = 'numeroPES';
      this.activityVariableBeanPotClaimNumber.varType = 1;
      this.activityVariableBeanPotClaimNumber.varValueStr = this.apiClaim.reportNumber;
      this.activityVariableBeanPotClaimNumber.idUserIns = this.idUserIns;
      this.activityVariableBeanPotClaimNumber.idUserUpd = this.idUserIns;
      this.activityDto.actVariableBean.push(this.activityVariableBeanPotClaimNumber);

    }
  }

  // UTILITIES

  protected formatDate(date: Date) {
    const formatdate = 'dd-MM-yyyy';
    const dateString = formatDate(date, formatdate, this.locale);
    return dateString;
  }

  getProperties() {
    const promises: any[] = [];
    promises.push(this.getSystemProperty.findProperty(ManualDeadlineComponent.PROPERTY_SET_DEFAULT_USER));
    this.eventService.broadcastEvent('start-loader');
    Promise.all(promises).then((results) => {
      results.forEach((result) => {
        if (result && result.systemProperties && result.systemProperties.length) {
          for (const variable of result.systemProperties) {
            if (variable.key === ManualDeadlineComponent.PROPERTY_SET_DEFAULT_USER) {
              if (variable.value && ManualDeadlineComponent.PROPERTY_ENABLED === variable.value) {
                this.setDefaultUser = true;
                this.formGroup.get('assigneeUser').setValidators([Validators.required]);
              }
              break;
            }
          }
        }
      });
      this.eventService.broadcastEvent('stop-loader');
    });
  }

}

<rgi-rx-panel class="rgi-modal">
  <rgi-rx-panel-header [closeable]="true" (onClose)="onClose()">
    <div translate>_GP_._TITLE_._AUTHORIZATION_REQUEST_</div>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button
      class="rgi-ui-btn rgi-ui-btn-secondary uppercase"
      (click)="onClose()" translate>{{'_GP_._BTN_._UNDO_'}}
    </button>
    <button
      class="rgi-ui-btn rgi-ui-btn-primary uppercase"
      (click)="onConfirm()" translate>{{'_GP_._BTN_._CONFIRM_'}}
    </button>
  </rgi-rx-panel-footer>
  <ng-container [formGroup]="authorizationDataFormGroup">
    <rgi-rx-tab-group [selectedIndex]="selectedIndex">
      <rgi-rx-tab label="{{'_GP_._LABEL_._REQUEST_' | translate}}">
        <ng-template rgiRxTabContent>
          <div class="rgi-gp-section-title-small">
            <b translate>_GP_._LABEL_._AUTHORIZATION_REQUEST_INSERT_</b>
          </div>
          <rgi-gp-generic-detail [details]="getDemandInfo(authorizationData.demandInfo)"  [translateLabel]="true">
          </rgi-gp-generic-detail>

          <div class="rgi-gp-section-title-small">
            <b translate>_GP_._LABEL_._REQUEST_STATE_</b>
          </div>
          <rgi-gp-generic-detail [details]="getStatusInfo(authorizationData.statusInfo)"  [translateLabel]="true">
          </rgi-gp-generic-detail>

          <div class="rgi-gp-section-title-small">
            <b>{{'_GP_._LABEL_._PROPOSAL_AUTHORIZATION_INFO_' | translate }}: {{authorizationData.proposalInfo.proposalNumber}} {{'_GP_._SUMMARY_._STATUS_' | translate }}: {{authorizationData.proposalInfo.proposalStatus}}</b>
          </div>
          <rgi-gp-generic-detail [details]="getProposalInfo(authorizationData.proposalInfo)"  [translateLabel]="true">
          </rgi-gp-generic-detail>

          <div class="rgi-gp-flex-row rgi-gp-mt-l">
            <div class="rgi-gp-field-container">
              <div class="rgi-gp-field">
                <rgi-rx-form-field>
                  <label rgiRxLabel>
                    <span translate>_GP_._LABEL_._USER_APPLICANT_</span>
                  </label>
                  <select rgiRxNativeSelect formControlName="userApplicat" data-qa="userApplicat">
                    <option value=""></option>
                    <option *ngFor="let user of authorizationData.usersApplicant" [value]="user.code">{{user.description}}</option>
                  </select>
                </rgi-rx-form-field>
              </div>
            </div>
            <div class="rgi-gp-field-container">
              <div class="rgi-gp-field">
                <rgi-rx-form-field>
                  <label rgiRxLabel>
                    <span translate>_GP_._LABEL_._PRIORITY_</span>
                  </label>
                  <select rgiRxNativeSelect formControlName="priority" data-qa="priority">
                    <option value=""></option>

                    <option *ngFor="let priorities of authorizationData.priorities" [value]="priorities.id">{{priorities.description}}</option>
                  </select>
                </rgi-rx-form-field>
              </div>
            </div>
          </div>
          <div class="rgi-gp-flex-row">
            <rgi-rx-form-field data-qa="rgi-gp-modal-textarea" class="rgi-gp-mb-0 rgi-gp-w-100">
              <textarea rgiRxInput formControlName="note"></textarea>
              <label rgiRxLabel><span translate>_GP_._LABEL_._NOTE_</span></label>
            </rgi-rx-form-field>
          </div>
        </ng-template>
      </rgi-rx-tab>
      <rgi-rx-tab label="{{'_GP_._LABEL_._NOTIFICATIONS_' | translate}}">
        <ng-template rgiRxTabContent>
          <rgi-rx-datatable [data]="warningListTableData"
                            [schema]="WARNING_LIST_AUTHORIZATION_TABLE_SCHEMA"
                            [expansionRow]="warningDetailRow"
                            [selectable]="false">
          </rgi-rx-datatable>
          <ng-template #warningDetailRow let-warningRow>
            <label class="rgi-ui-form-control-label">
              <span class="rgi-ui-text-5" translate>_GP_._LABEL_._DESCRIPTION_</span>
              <span class="rgi-ui-text-5">:
                <b data-qa="rgi-gp-policyDataValue">{{warningRow.warningDescription}}</b>
              </span>
            </label>

          </ng-template>
        </ng-template>
      </rgi-rx-tab>
    </rgi-rx-tab-group>
  </ng-container>


</rgi-rx-panel>

import { EvaluatorProductI } from '../../evaluation/models/evaluator-products';
import { SurveyIntegrationFilter } from '../../integration/integration.module';
import { SurveyVersionI } from '../../models/survey-version';
import { EvalValidationMap } from '../state-manager-survey.module';
import { EvaluatedItem } from '@rgi/ng-passpropro';
import { EvalProductsCfg } from './eval-product-cfg';
import { State } from '@rgi/rx/state';
import { NavigationBtnCfg } from './navigation';
import { Document } from '@rgi/ng-passpropro/documents';


export const BTN__MAP_EVAL = {
  'BACK': { styleClass: 'ppevo-btn-back', label: 'NG_PASSPROPRO_CORE.back', visible: true },
  'PRINT': { styleClass: 'ppevo-btn-highlighted', label: 'NG_PASSPROPRO_SURVEY.print', visible: true },
  'MODIFY': { styleClass: 'ppevo-btn-highlighted', label: 'NG_PASSPROPRO_SURVEY.eval_modify', visible: false },
  'SAVE': { styleClass: '', label: 'NG_PASSPROPRO_SURVEY.eval_save', visible: false },
  'CONTINUE': { styleClass: '', label: 'NG_PASSPROPRO_SURVEY.eval_continue', visible: false },
};

export class SurveyEvaluateState extends State{
    isEdit: boolean = false;
    selectEnabled: boolean = false;
    changed: boolean = false;
    allowSelections = false;
    hasPrinted = false;
    documents: {[key: string]: Document[]};
    evaluatorProducts: EvaluatorProductI[];
    validation: EvalValidationMap;
    evaluatorValidations: EvaluatedItem[];
    selectGroupEnabled: boolean = true;
    groupProductsByLob: boolean = false;
    surveyVersion: SurveyVersionI;
    integrationFilter: SurveyIntegrationFilter;
    activeRouteId?: string;
    routeOptions?: any;
    evaluationPersisted: boolean = false;
    productsCfg: EvalProductsCfg;
    navigation: NavigationBtnCfg = {
      stickable: true,
      map: BTN__MAP_EVAL
    }
    forwardData?: any
    initialized: boolean = false;
  }

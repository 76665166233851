import {Risk} from './risk';
import {Variable} from './variable';
import {Instalment} from './instalment';
import {Beneficiary} from './beneficiary';
import {Clause} from './clause';

export class Unit {
  public id: string;
  public code: string;
  public description: string;
  public warrantyCode: string;
  public warrantyId: string;
  public selected: boolean;
  public enabled: boolean;
  public risk: Risk;
  public clauseUnit: Clause[];
  public open: boolean;
  public extendedDescription: string;
  public variables: Variable[];
  public annual: Instalment;
  public instalment: Instalment;
  public subscriptionInstalment: Instalment;
  public factors: Variable[];
  public beneficiaries: Beneficiary[];
  public inputPremiumType: any;
  public inputPremium: any;
  public discountable: boolean;

  protected INSURED_SUM_CODE = '3SA';
  public isInsuredSum() {
    return this.factors.find(f => f.code === this.INSURED_SUM_CODE) ?
      this.factors.find(f => f.code === this.INSURED_SUM_CODE).visible
      && this.factors.find(f => f.code === this.INSURED_SUM_CODE).value != null
      : true;
  }
}

<rgi-rx-panel class="rgiportal-modal-xs">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">{{data.title}}</rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button (click)="confirmAddDocument()" color="primary" translate>RGI_PORTAL_COMPONENTS.ADD</button>
  </rgi-rx-panel-footer>
  <form [formGroup]="addDocumentForm">
    <rgi-rx-form-field>
      <label rgiRxLabel translate>RGI_PORTAL_COMPONENTS.DOCUMENT_TYPE</label>
      <select rgiRxNativeSelect formControlName="documentType">
        <option value=""></option>
        <option *ngFor="let documentType of arDocumentType" [value]="documentType.code">{{ documentType.description }}</option>
      </select>
    </rgi-rx-form-field>
  </form>
</rgi-rx-panel>

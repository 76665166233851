import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NoteComponent } from '../../objects/note.component';

@Component({
  selector: 'claims-reserve-note',
  templateUrl: './reserve-note.component.html',
  styleUrls: ['./reserve-note.component.scss']
})
export class ReserveNoteComponent implements NoteComponent, OnInit {

  @Input() data: any;
  @Input() rechargeEventEmitter: EventEmitter<boolean>;
  currencyStyle = '';
  rightColumnStyle = '';

  constructor() { }

  ngOnInit() {
    this.rightColumnStyle = this.getRightColumnStyle();
    this.currencyStyle = 'note-' + this.data.currencySymbol;
  }

  private getRightColumnStyle() {
    return !this.data.hasLeftColumn ? 'hasOnlyRight' : '';
  }
}

import {PncPsalesBaseState} from '../resources/states/pnc-postsales-state';
import {AbstractStateManager, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPostSalesOrchestratorService} from '../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../services/pnc-postsales-integration.service';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {merge, Observable, of, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {PncPsalesHttpErrorService} from '../services/pnc-postsales-http-error.service';
import {PNC_PSALES_ACTIONS} from '../resources/constants/actions';
import {PncPsalesForm} from '../resources/model/common/form';
import {RgiFormField} from '../resources/model/common/rgi-form-field';

export interface FieldChangeSubject {
  formName: string;
  fieldCode: string;
  value: any;
  formStatus?: string;
}

/**
 * Base class for all the state managers
 * provides the basic functionalities for the state management, such as the form callback registration and the navigation
 */
export abstract class RgiPncPsalesStateManager<S extends PncPsalesBaseState> extends AbstractStateManager<S> {

  private readonly fieldChanges$ = new Subject<FieldChangeSubject>(); // buffer
  private formObsSub: Subscription = Subscription.EMPTY;
  private formFieldChanges = new Subscription();

  /**
   * the constructor invokes the stateStore to recover an extisting state or create a new one, invoking the initState$ method
   */
  protected constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    private _orchestrator: PncPostSalesOrchestratorService,
    private _integrationService: PncPostSalesIntegrationService,
    private _pushMessageHandler: RgiRxPushMessageHandlerService,
    private _errorService: PncPsalesHttpErrorService,
    private _context?: any
  ) {
    super(activeRoute, stateStoreService);
    setTimeout(() => {
      const isStored = stateStoreService.has(this.storeKey);
      const initialState = isStored ? of(stateStoreService.get<S>(this.storeKey)) : of(new PncPsalesBaseState(this.activeRoute) as S);
      this.updateState$(isStored ? initialState : this.initState$(initialState));
    });

  }

  /**
   * @param {Observable<S>} state - the state observable
   * @returns {Observable<S>} - the updated state observable
   */
  abstract initState$(state: Observable<S>): Observable<S>;

  onAction(action: string): Observable<S> | void {
    switch (action) {
      case PNC_PSALES_ACTIONS.BACK:
        this.actionBack();
        break;
      case PNC_PSALES_ACTIONS.CONTINUE:
        this.actionContinue();
        break;
    }
  }

  actionBack() {
    this.stateStoreService.destroy(this.storeKey);
    this.orchestrator.goToPreviousStep$(this.getCurrentState(), this.activeRoute);
  }

  actionContinue() {
    this.updateState$(this.orchestrator.goToNextStep(this.getCurrentState(), this.activeRoute));
  }

  protected registerOnFormFieldChange(form: string, fieldGroup: string[], callback: (state: S, fieldCode: string, fieldValue: any) => Observable<S> | S): void {
    this.formFieldChanges.add(this.fieldChanges$.asObservable().pipe(
      filter(changes => {
        let isOk: boolean = changes.formName === form;
        isOk = isOk && fieldGroup.includes(changes.fieldCode);
        return isOk;
      }),
      distinctUntilChanged((previous, current) => {
        return previous.value === current.value;
      }),
      switchMap(change => {
        const state = this.getCurrentState();
        state.formStatus = change.formStatus || state.formStatus;
        if (state.forms[change.formName]?.fields[change.fieldCode]) {
          state.forms[change.formName].fields[change.fieldCode].value = change.value;
        }
        const res = callback(state, change.fieldCode, change.value);
        if (this.isIsObservable(res)) {
          return res;
        }
        return of(res);
      }))
      .subscribe(next => {
        this.updateState$(of(next));
      }));
  }

  private isIsObservable<T>(value: Observable<T> | T): value is Observable<T> {
    return (value as Observable<T>).subscribe !== undefined;
  }

  dispose() {
    super.dispose();
    // my disposes
    this.formObsSub.unsubscribe();
    this.formFieldChanges.unsubscribe();
  }

  setFormObservables(subject: FieldChangeSubject) {
    this.fieldChanges$.next(subject);
  }

  setFormStatus(status: string) {
    const currentState = this.getCurrentState();
    currentState.formStatus = status;
    this.updateState$(of(currentState));
  }

  getFormByName(formName: string, st?: S): PncPsalesForm {
    const state = st || this.getCurrentState();
    return state.forms[formName];
  }

  getFormFields(formName: string, st?: S): RgiFormField[] {
    const form = this.getFormByName(formName, st);
    return form ? form.fields : undefined;
  }

  getField(formName: string, formFieldCode: string, st?: S): RgiFormField {
    const form = this.getFormByName(formName);
    return form?.fields?.find(f => f.code === formFieldCode);
  }

  get errorService(): PncPsalesHttpErrorService {
    return this._errorService;
  }

  get orchestrator(): PncPostSalesOrchestratorService {
    return this._orchestrator;
  }

  get integrationService(): PncPostSalesIntegrationService {
    return this._integrationService;
  }

  get pushMessageHandler(): RgiRxPushMessageHandlerService {
    return this._pushMessageHandler;
  }

  get context(): any {
    return this._context;
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TimelineComponentsModule } from './components/timeline.components.module';
import { TimelineComponent } from './timeline.component';
import {
    CardService,
    containerCacheServiceProvider
} from '@rgi/digital-claims-common-angular';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [
        TimelineComponent
    ],
    imports: [
        BrowserModule,
        TimelineComponentsModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
    exports: [
        TimelineComponent,
    ],
    providers: [
        containerCacheServiceProvider
    ]
})
export class TimelineModule {
    constructor(cardService: CardService) {
        const title = '_TIMELINE';
        const moduleName = 'timeline';
        const parentCardName = 'claimsFinder';

        cardService.registerSubCard(
            moduleName,
            title,
            TimelineComponent,
            parentCardName
        );

      }
}


import {AnagTranslationsKeys} from './anag-translations-keys';
/* tslint:disable:max-line-length */
// Please keep the translation keys alphabetically ordered
export const ANAG_EN: AnagTranslationsKeys = {
  _ANAG_: {
    _BTN_: {
      _ADD_: 'Add',
      _ADD_ADDRESS_: 'Add Address',
      _BACK_: 'Back',
      _CALCULATE_: 'Calculate',
      _CANCEL_: 'Cancel',
      _CLOSE_: 'Close',
      _CONFIRM_: 'Confirm',
      _DETAIL_: 'Detail',
      _EDIT_: 'Edit',
      _EMPTY_: 'Empty',
      _FIND_: 'Find',
      _NEW_: 'New',
      _NEW_PAYMENT_: 'New Payment',
      _NORMALIZE_: 'NORMALIZE',
      _SAVE_: 'Save',
      _DELETE_: 'Delete',
      _DELETE_DEFINITIVELY_: 'Delete Definitively',
      _HISTORY_: 'Log',
      _LIMITATION_MANAGEMENT_: 'Limitation Management',
      _UNDO_: 'Undo',
      _CONTINUE_: 'Continue'
    },
    _LABEL_: {
      _ACTIONS_: 'Actions',
      _ACTION_TERRORISM_: 'Terrorism information',
      _ADDRESS_: 'Address',
      _AE_: 'AE',
      _AGENCY_: 'Agency',
      _ATI_ASSOCIATIONS_: 'ATI Associations',
      _BIRTH_DATE_: 'Birth Date',
      _BIRTH_SURNAME_: 'Surname of birth / Second Surname',
      _BIRTH_PLACE_: 'Birth Place',
      _CITIZENSHIP_: 'Citizenship',
      _CITY_OF_BIRTH_: 'City of birth',
      _CIVIL_STATE_: 'Marital Status',
      _CLIENT_NUMBER_: 'Customer Number',
      _COMPANY_NAME_: 'Company Name',
      _CONTACTS_: 'Contacts',
      _CORPORATE_FORM_: 'Corporate Form',
      _CORPORATE_SECTOR_: 'Corporate Sector',
      _CORPORATION_: 'Corporation',
      _COWORKER_: 'Co-worker tax code',
      _CREDIT_: 'Credit',
      _CRITERIA_: 'Criteria',
      _DEBIT_: 'Debit',
      _DOCUMENT_: 'Document',
      _DOCUMENT_NO_: 'Document number',
      _DOCUMENT_TYPE_: 'Document type',
      _EMAIL_: 'Email',
      _EMAIL2_: 'Email 2',
      _EMAIL3_: 'Email 3',
      _EXPIRATION_DATE_: 'Expiration Date',
      _FAX_NO_: 'Fax Number',
      _FEMALE_: 'Female',
      _FILL_ID_DATA_: 'Fill in identification party data',
      _FISCAL_CODE_: 'Tax code',
      _FISCAL_CODE_VAT_NUMBER_: 'Tax code / VAT number',
      _GENDER_: 'Gender',
      _GENDER_IDENTITY_: 'Gender identity',
      _IDENTIFICATION_DATA_: 'Identification Data',
      _INT_PREFIX_: 'Int. Prefix',
      _LANDLINE_NO_: 'Landline Number',
      _PHONE_NO_: 'Phone Number',
      _LEADER_: 'Leader',
      _LINK_TYPE_: 'Link type',
      _LINKED_PARTIES_: 'Linked Parties',
      _MALE_: 'Male',
      _MAIN_CONTACT_: 'Main contact',
      _MANAGEMENT_NODE_: 'Management Node',
      _MOBILE_NO_: 'Mobile number',
      _MOBILE_NO_2_: 'Mobile number 2',
      _MUNICIPALITY_: 'Municipality',
      _NAME_: 'Name',
      _NATION_: 'Nation',
      _NODES_: 'Nodes',
      _NO_ADDRESS_MSG_: 'No address',
      _NUMBER_: 'Number',
      _OPEN_DATE_: 'Registration date',
      _PARTICIPANTS_: 'Participants',
      _PARTIES_: 'Parties',
      _PARTY_: 'Party',
      _PARTY_DATA_: 'Party Data',
      _PARTY_MANAGEMENT_: 'Party Management',
      _PARTY_MERGER_: 'Party Merger',
      _PARTY_NUMBER_: 'Party Number',
      _PARTY_TYPE_: 'Party Type',
      _PAYMENT_: 'Payment',
      _PERSON_TYPE_: 'Person Type',
      _PHYSICAL_: 'Physical',
      _POTICALLY_EXP: 'Politically exposed party',
      _PREFIX_: 'Prefix',
      _PRIVACY_: 'Privacy consents',
      _PRIVACY_CONSENSUS_DATE_: 'Date',
      _CHANGED_ON_: 'Changed on',
      _PRODUCTION_NODES_: 'Production nodes',
      _PROFESSION_: 'Profession',
      _PROFESSION_DETAIL_: 'Profession Detail',
      _REGISTERED_OFFICE_: 'Registered Office',
      _RELEASE_AUTHORITY_: 'Release Authority',
      _RELEASE_DATE_: 'Release Date',
      _RELEASE_LOCATION_: 'Release Location',
      _REQUIRED_FIELD_: 'Required',
      _RESIDENCE_: 'Residence',
      _ANTI_TERRORISM_: 'Anti-Terrorism',
      _RESIDENCE_REGISTERED_OFFICE_: 'Residence / Registered office',
      _SAE_: 'SAE',
      _SALE_POINT_: 'Point of Sale',
      _SAVED_OK_: 'saved successfully',
      _SELECT_PARTY_: 'Select Party',
      _SEX_: 'Sex',
      _STATUS_: 'Status',
      _STATUS_NOT_PROCESSED_: 'Not processed',
      _STATUS_PROCESSED_: 'Processed',
      _SUBJECT_: 'Party',
      _SUBJECT_DATA_: 'Party data',
      _SUBJECT_TYPE_: 'Type of Party',
      _SUBJECT_UNIQUE_KEY_: 'Party Unique Key',
      _SUBJNO_: 'Party no.',
      _SUBSYSTEM_: 'Subsystem',
      _SUBSYSTEM_PERSON_ID_: 'Sub-system Person ID',
      _SURNAME_: 'Surname',
      _SURNAMEORCOMPANY_: 'Surname / Company Name',
      _VOID_: ' ',
      _VAT_NUMBER_: 'VAT number',
      _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Date Modified Counter-Terrorism',
      _COUNTER_TERRORISM_INDICATOR_: 'Counter-Terrorism Status',
      _DOMICILES_: 'Domiciles',
      _DOMICILE_: 'Domicile',
      _PRINCIPAL_ADDRESS_: 'Principal address',
      _AVAILABILITY_: 'Availability',
      _TYPE_: 'Type',
      _AVAILABILITY_START_DATE_: 'Availability start date',
      _AVAILABILITY_END_DATE_: 'Availability end date',
      _AVAILABLE_FROM_: 'Available from',
      _AVAILABLE_UNTIL_: 'Available until',
      _INTERNATIONAL_DIALLING_CODE_: 'International dialling code',
      _LOCAL_AREA_DIAL_CODE_: 'Local area dial code',
      _YES_: 'Yes',
      _NO_: 'No',
      _COUNTERTERRORISM_STATUS_: 'Counter-Terrorism Status',
      _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_: 'Date Modified Counter-Terrorism',
      _ORIGIN_: 'Origin',
      _LANGUAGE_ : 'Language',
      _HONOR_TITLE_ : 'Honorary Title',
      _SONS_: 'Number of children',
      _ROBINSON_LIST_: 'Robinson List',
      _CITIZENSHIP_PERMIT_: 'Residence permit',
      _CITIZENSHIP_EXPIRE_: 'Residence valid until',
      _REG_COUNTRY_: 'Registration country',
      _EXTERNALS_IDENTIFIERS_: 'External Identifiers',
      _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Identifiers Party Sub-system',
      _TAX_CLASSIFICATION_: 'Tax Classifications',
      _RISK_CLASSIFICATION_ : 'Risk Classification',
      _REASON_CLASSIFICATION_ : 'Reason Classification',
      _BASIS_CLASSIFICATION_ : 'Basis Classification',
      _CLASSIFICATION_DATE_ : 'Classification Date',
      _COUNTRY_START_DATE_ : 'Country Start Date',
      _COUNTRY_END_DATE_ : 'Country End Date',
      _NO_TAX_CLASSIFICATIONS_ : 'No Tax Classifications',
      _COUNTRY_ : 'Country' ,
      _NOMINATIVE_ : 'Nominative',
      _HISTORY_CONSULTATION_: 'History consultation',
      _DO_NOT_REPLY_: 'Not set',
      _DRIVING_LICENSE_TYPE_: 'Driving license type',
      _DRIVER_QUALIFICATION_CARD_: 'Driver qualification card',
      _ADD_DRIVING_LICENSE_TYPE_:'Add driving licence type',
      _PRIVACY_INFO_MSG_: 'The field consults the official advertising exclusion files known as the Robinson List (RL) and tells us whether the client is registered in the RL or not.\n' +
        ' \n' +
        'If the Robinson List is set to "Yes", we will not be able to send commercial communications.\n' +
        ' \n' +
        'If the Robinson List is set to "No", commercial communications will only be sent when the Exclude Advertising field is set to No.\n' +
        ' \n' +
        'If the client has not been consulted, the field appears with value "Do not Reply"',
      _LEI_CODE_: 'LEI code',
      _RATING_: 'Rating',
      _RATING_DATE_: 'Rating date',
      _RATING_PERIOD_: 'Rating period',
      _RATING_USER_: 'User',
      _RATING_ORIGIN_: 'Origin',
      _RATING_HISTORY_: 'Rating History',
      _COWORKERS_: 'Coworkers',
      _COMPANY_: 'Company'
    },
    _MSG_: {
      _ASK_DELETION_CONFIRM_: 'Confirm the deletion of the party?',
      _DELETION_CONFIRMED_: 'The party was deleted correctly',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Fields marked in red are required or contain incorrect values',
      _ERROR_DATE_: 'Party under 16 years. The consent to data processing is provided by parents/legal guardians',
      _ERROR_DATE_SP_: 'Consent for the processing of personal data for children under 14',
      _INVALID_DATE_VALUE: 'Invalid date',
      _INVALID_FIELD_: 'Invalid field',
      _LENGTHERR_: 'Field length not allowed',
      _LINK_ERROR_: 'Party already inserted',
      _LINK_ERROR_SAME_PARTY_: 'Impossible to link a party to themselves',
      _NO_ELEMENT_TO_SHOW_: 'There are no elements to show',
      _NO_LINKED_PARTIES_: 'No Links',
      _NO_RESULTS_: 'No Results',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'No normalized address found',
      _PATTERNERR_: 'Field contains wrong characters',
      _REFINE_SEARCH_: 'The extraction is partial. Please refine your search',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Fill in at least one of: Surname / Company Name, Birth Name / Second Surname, Unique key, Client Number, Party Number, Phone number, valid Zip Code or Sub-system Person ID',
      _WRONG_INPUT_DATA_: 'The Party Management Flow is invoked with unsupported input values',
      _INVALID_END_DATE_DOMICILE_: 'Insert an availability end date that is later than the availability start date!',
      _INVALID_START_DATE_DOMICILE_: 'Insert a start date of availability that is earlier than the end date of availability!',
      _INHERITANCE_: 'There is already a registry with the same unique key in the sales network. Press confirm if you want to use the data of the party already registered in the registry, otherwise, it will not be possible to continue with this enhancement of the unique key',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'There is already a valid tax classification for the selected country, type and period',
      _INFO_HOMONYMIES_: `Warning! Parties have been found that may be homonyms.
                Select a party from the list to access editing his data or the button CONTINUE to continue inserting the new party.`,
      _INFO_NATURAL_PERSON_ALLOWED_: 'Natural Person only allowed',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Legal Person only allowed',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'The release date must be prior to the expiration date',
      _IBAN_INVALID_: 'Incorrect IBAN format',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Insert an Expiry Date that is later than the issue date of the document',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: 'Confirm deletion of current tax classification?',
      _ASK_DELETION_DOCUMENT_CONFIRM_: 'Confirm the deletion of the current document?',
      _DOC_ALREADY_EXIST: 'Document number already exists',
      _INSERT_DOC_TYPE: 'Insert the document type before continuing.',
      _INVALID_FORMAT_: 'Invalid format',
      _NORMALIZE_ERROR_MSG: 'It\'s mandatory to normalize the address'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Edit Party',
  _ANAG_TITLE_PARTY_DETAIL_: 'Party Detail',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Party Management',
  _ANAG_TITLE_PARTY_ZOOM_: 'Party Zoom',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Search Results',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: 'Search Results Homonymy'
};

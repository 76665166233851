export class PremiumSummary {

  constructor(
    public gross: string,
    public net: string,
    public fractioningInterest: string,
    public taxes: string,
    public fees: string
  ) {
  }
}

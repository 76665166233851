export class TaxregimeEntity {
    public percCash: number;
    public percIVA: number;
    public percWithHolding: number; // percentuale ritenuta
    public legalCode: string;
    public aggregateRegime: string;
    public percCash2: number; // INPS
    public percTaxableAmount: number;
    public taxRegimeId: string;
    public description: string;
    public typeCode: string;
}

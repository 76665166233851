import {Injector, NgModule} from '@angular/core';
import {NgPassproproProductModule} from "../ng-passpropro-product.module";
import {SOURCE_ENVIRONMENT_KEYS, SOURCE_ENVIRONMENT_PROVIDER, SourceEnvironment} from '../product-api';
import {downgrade} from "./ajs-configuration";
import {AJS_NG_PASSPROPRO_CONFIG} from "@rgi/ng-passpropro-core/portal";

export function sourceEnvironmentFactory(authService, injector:Injector){
  const ajsPassProProConfig = injector.get(AJS_NG_PASSPROPRO_CONFIG)
  if (ajsPassProProConfig == null || ajsPassProProConfig.passportal === false){
     console.warn("Ignoring default source environment because passportal is not enabled");
    return {};
  }
  const o:SourceEnvironment = {};
  o[SOURCE_ENVIRONMENT_KEYS.USERNAME] = authService.getUsername();
  o[SOURCE_ENVIRONMENT_KEYS.NODE] = authService.getSalePointId();
  return o;
}

@NgModule({
  declarations: [],
  imports: [
    NgPassproproProductModule
  ],
  exports: [],
  providers:[
    {
      provide: SOURCE_ENVIRONMENT_PROVIDER,
      deps:['authService', Injector],
      multi:true,
      useFactory:sourceEnvironmentFactory
    }
  ]
})
export class NgPassproproProductAjsModule {


  constructor() {
    downgrade();
  }
}

import {Directive, ElementRef, Optional, Self} from '@angular/core';
import {RgiRxErrorStateMatcher, RgiRxFormControl} from './rgi-rx-form-elements-api';
import {FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {FocusOptions} from '@angular/cdk/a11y';
import {RGI_RX_FORM_FILED_INPUTS} from './rgi-rx-form-elements-meta';

@Directive({
  selector: '[rgiRxFormControl]',
  host: {
    '[class.rgi-ui-form-control]': 'inheritDefaultClass',
    '[id]': 'id',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false',
    '[attr.aria-required]': 'required ? "true" : "false"',
    '[attr.aria-invalid]': '""+hasError()',
    '[attr.aria-labelledby]': 'ariaLabelledBy && ariaLabelledBy.length ? ariaLabelledBy.join(" ") : null',
    '[attr.aria-describedby]': 'ariaDescribedBy && ariaDescribedBy.length ? ariaDescribedBy.join(" ") : null',
  },
  inputs: [
    ...RGI_RX_FORM_FILED_INPUTS
  ]
})
/**
 * Directive that provides rgi/rx bindings for any element that support required and disabled properties
 * @see RgiRxFormControl
 * @see RgiRxErrorStateMatcher
 */
export class RgiRxFormControlDirective<T> extends RgiRxFormControl<T> {
  private _inheritDefaultClass = true;

  constructor(
    public elementRef: ElementRef,
    _errorStateMatcher: RgiRxErrorStateMatcher,
    @Optional() @Self() ngControl?: NgControl,
    @Optional() _parentForm?: NgForm,
    @Optional()  _parentFormGroup?: FormGroupDirective,
  ) {
    super(_errorStateMatcher, ngControl, _parentForm, _parentFormGroup);
  }

  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  _handleClick() {
    if (!this.focused) {
      this.focus();
    }
  }


  get inheritDefaultClass(): boolean {
    return this._inheritDefaultClass;
  }

  /**
   * @description define if existing classes should be inherited when extending the directive
   * @param value
   */
  set inheritDefaultClass(value: boolean) {
    this._inheritDefaultClass = value;
  }
}

import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {ReIssueSaveQuoteResourceService} from '../re-issue-resources/re-issue-save-quote-resource.service';
import {ReIssueSaveQuoteManager} from '../re-issue-state-manager/re-issue-save-quote-state-manager.service';
// tslint:disable-next-line:max-line-length
import {
  ReIssuePortfolioWarningsResourceService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-warnings-resource.service';
import {Warning} from '../../models/domain-models/warning';

@Injectable({
  providedIn: 'root'
})
export class ReIssueSaveQuoteBusinessService<T extends ReIssueSaveQuoteManager> {
  protected readonly POST_QUOTATION_WARNING_LEVEL = 'POST_QUOTATION';

  constructor(
    protected saveQuoteResourceService: ReIssueSaveQuoteResourceService,
    protected portfolioWarningsResourceService: ReIssuePortfolioWarningsResourceService
  ) {
  }

  saveQuote(state: T): Observable<T> {
    return this.saveQuoteResourceService.postContract(state.contractNumber.proposalNumberEncrypted).pipe(
      map((res: any) => {
        state.resourceId = res.id;
        return state;
      })
    );
  }

  postWarnings(state: T): Observable<T> {
    return this.portfolioWarningsResourceService.postWarnings(state.resourceId, this.POST_QUOTATION_WARNING_LEVEL).pipe(
      map(warnings => {
        state.warnings = this.getBlockingWarnings(warnings);
        state.nonBlockingWarnings = this.getNonBlockingWarnings(warnings);
        return state;
      })
    );
  }

  getNonBlockingWarnings(warnings: any): Array<Warning> {
    const nonBlockingWarnings = new Array<Warning>();

    warnings.forEach((warning: Warning) => {
      if (warning.warningMessage) {
        nonBlockingWarnings.push(warning);
      }
    });

    return nonBlockingWarnings;
  }

  getBlockingWarnings(warnings: any): Array<Warning> {
    const blockingWarnings = new Array<Warning>();

    warnings.forEach((warning: Warning) => {
      if (warning.blockingMessage || warning.authorizationMessage) {
        blockingWarnings.push(warning);
      }
    });

    return blockingWarnings;
  }
}

import {Component, EventEmitter, Input, Output, ViewEncapsulation, OnInit} from '@angular/core';
import {AnswerValueI} from '../../models/answer-value';

@Component({
  selector: 'ppevo-multivalue',
  templateUrl: './multivalue.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MultivalueComponent implements OnInit {


  @Input() answerValues: AnswerValueI[];
  @Input() ngModel:string;
  @Input() disabled: boolean = false;
  @Input() values:string = "";

  @Output() ngModelChange = new EventEmitter<string>();

  selectedList: {} = {};

  
  constructor() { }


  ngOnInit(): void {
      
      if( !this.values || typeof(this.values) != "string") {
        return;
      }

      const idList:string[] = this.values.split(";");
      
      this.selectedList = this.answerValues.reduce(
          (selectedList, av) => {
            
            if(  idList.some(id => parseInt(id) == av.id)  ){
              selectedList[av.id] = av.id;
            }
            
            return selectedList;
          },
          {}
      );
      
      this.ngModel = Object.keys(this.selectedList).join(";");
  }


  onClick(id){
    
    if(this.disabled){
      return;
    }

    if(this.selectedList[id]){
      delete this.selectedList[id];
    }else {
      this.selectedList[id] = id;
    }

    this.ngModel = Object.keys(this.selectedList).join(";");
    this.ngModelChange.emit(this.ngModel);
  }


  sortAnswerValues = (values: AnswerValueI[]):AnswerValueI[] => values.sort((v1, v2)=> v1.order-v2.order);
}

import {Directive, ElementRef, HostListener, Input, OnInit, Optional} from '@angular/core';
import {ActiveRoute, RgiRxRouteData, RgiRxRouteOptions} from '../router.api';
import {RoutingService} from '../routing.service';

export abstract class RgiRxRouterLink {
  targetId?: string;
  link: string;
  protected readonly isAnchorElement: boolean;
  routeData?: RgiRxRouteData<any>;

  protected constructor(protected _elementRef: ElementRef, protected _activeRoute?: ActiveRoute) {
    const tagName = _elementRef.nativeElement.tagName;
    this.isAnchorElement = tagName === 'A' || tagName === 'AREA';
  }

  abstract navigate(): void;

  protected makeOptions(): RgiRxRouteOptions {
    if (!this._activeRoute) {
      return {};
    }
    return {
      relativeTo: this._activeRoute,
    };
  }
}


@Directive({
  selector: '[rgiRxRouterLink]',
  exportAs: 'rgiRxRouterLink'
})
export class RgiRxRouterLinkDirective extends RgiRxRouterLink{

  /**
   * Set a new target id.
   * default ActiveRoute.id
   * @see ActiveRoute
   */
  @Input() targetId?: string;
  /**
   * the target route
   * @see Route
   */
  @Input('rgiRxRouterLink') link: string;
  /**
   * RouteData to be passed to the next ActiveRoute
   * @see RgiRxRouteData
   */
  @Input() routeData?: RgiRxRouteData<any>;

  constructor(
    private _routingService: RoutingService,
    _elementRef: ElementRef,
    @Optional() _activeRoute?: ActiveRoute
  ) {
    super(_elementRef, _activeRoute);
  }

  @HostListener('click', ['$event'])
  _handleClick(event: MouseEvent) {
    this.navigate();
  }

  public navigate() {
    this._routingService.navigate(this.link, this.routeData, this.targetId ? this.targetId : this._activeRoute ? this._activeRoute.id : undefined, this.makeOptions());
  }
}

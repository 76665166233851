<div class="box-detail">
  <header class="box-detail-header rgi-gp-flex-row">
    <label translate>_GP_._TITLE_._CONTRACTDETAIL_</label>
    {{contractDetails.policyNumber ? contractDetails.policyNumber : contractDetails.proposalNumber}}
    <span class="rgi-ui-icon-close2 rgi-gp-flex-push-right"  (click)="closeSession()"></span>
  </header>
  <div *ngIf="contractDetails && contractDetails.messages" class="rgi-ui-snackbar-container" data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="contractDetails.messages"
      [type]="contractDetails?.messages[0]?.level || 'WARNING'">
    </rgi-gp-group-policy-errors-component>
  </div>
  <div class="box-detail-body">
    <div class="box-detail-internal">
      <div class="icon-in-circle">
        <span class="rgi-ui-icon-listino-funzioni"></span>
      </div>
    </div>
    <div class="box-detail-internal">
      <div>
        <h2>{{contractDetails.productDescription}}</h2>
        <p *ngFor="let mainElement of contractDetails.contractDetail.mainElements">
          {{mainElement.label}}: {{mainElement.value}}
        </p>
        <a (click)="expandOtherData = !expandOtherData">
          <span translate>_GP_._LABEL_._OTHER_DATA_</span>
          <span [ngClass]="expandOtherData ? 'rgi-ui-icon-up-arrow' : 'rgi-ui-icon-select-arrow' "></span>
        </a>
        <div *ngIf="expandOtherData" class="gp-expand-data">
          <p *ngFor="let detailElement of contractDetails.contractDetail.detailElements">
            {{detailElement.label}}: {{detailElement.value}}
          </p>
        </div>
      </div>
    </div>
    <div class="rgi-ui-grid-1 rgi-gp-w-100">
      <div class="rgi-ui-col">
        <rgi-rx-snackbar [tag]="'INQUIRY_DETAIL'"></rgi-rx-snackbar>
      </div>
    </div>
    <div class="box-detail-internal">
      <div class="box-detail-internal-content gp-font-size-40">
        {{contractDetails.policyNumber ? contractDetails.policyNumber : contractDetails.proposalNumber}}
      </div>
      <div class="box-detail-internal-content">
        <span *ngIf="contractDetails.policyNumber; else proposalNumbLabel">
          <span translate>_GP_._LABEL_._POLICY_NUMBER_</span>
        </span>
        <ng-template #proposalNumbLabel>
          <span translate>_GP_._LABEL_._PROPOSAL_NUMBER_</span>
        </ng-template>
      </div>
    </div>
    <div class="box-detail-internal">
      <div class="box-detail-internal-content gp-font-size-40">
        {{contractDetails.effectDate | gpDate}} </div>
      <div class="box-detail-internal-content" translate>_GP_._LABEL_._EFFECTIVE_DATE_</div>
    </div>
    <div class="box-detail-buttons">
      <div class="rgi-ui-panel-footer">
        <button class="rgi-ui-btn rgi-ui-btn-secondary" *ngIf="isBackVisible" (click)="backToPrevPage()" translate>
          _GP_._BTN_._BACK_
        </button>
        <ng-container *ngFor="let button of contractDetails.actionsBotton">
          <button class="rgi-ui-btn rgi-ui-btn-primary"
                  *ngIf="button.isButtonVisible"
                  [disabled]="!button.isButtonActive"
                  (click)="doButtonAction(button.code)">
            {{button.description}}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { RgiRxInterpolationParamsType } from '@rgi/rx';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class TranslationWrapperService {
  constructor(private rgiRxTranslationService: RgiRxTranslationService) {}

  getDeferred(key: string, params?: RgiRxInterpolationParamsType): Observable<string | null> {
    return this.rgiRxTranslationService.translate(key, params).pipe(
        take(1)
    );
   }

   getImmediate(key: string, params?: RgiRxInterpolationParamsType): string | null {
    let translatedValue: string | null = null;

    this.rgiRxTranslationService.translate(key, params).pipe(
      take(1)
    ).subscribe(value => {
      translatedValue = !!value && value || key;
    });

    return translatedValue;
   }

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, ApiClaim, ApiTranslatorClaimService, CardComponent, FunctionService } from '@rgi/digital-claims-common-angular';
import { AccordionComponent } from '@rgi/portal-ng-core';
import { LimitModalComponent } from './components/limit-modal/limit-modal.component';
import { LimitClaimDto } from './dto/limit-claim-dto';
import { LimitDetailDto } from './dto/limit-detail-dto';
import { LimitDto } from './dto/limit-dto';
import { LimitsManagementService } from './limits-management.service';

@Component({
  selector: 'claims-limits-management',
  templateUrl: './limits-management.component.html',
  styleUrls: ['./limits-management.component.scss']
})
export class LimitsManagementComponent extends CardComponent implements OnInit {
  @ViewChild(AccordionComponent) accordionComponent: AccordionComponent;

  limits: LimitDto[];
  limitsCounter: LimitDto[];
  policyNumber: string;
  policyType: string;
  idClaim: number;
  claim: ApiClaim;
  selectedDetailType: any;
  defaultRadioChecked: string;
  isIndividualPolicy: boolean = false;
  showClaims: boolean = false;
  activeDetail: string = '';
  activeClaims: string = '';

  isAuthorizedForNewLimit: boolean = false;
  isAuthorizedForEditLimit: boolean = false;

  formatValidityDate: string;
  formatStartDate: string;

  constructor(
    @Inject('containerCacheService') private cache,
    @Inject('eventService') private eventService,
    private alertService: AlertService,
    private apiTranslatorClaimService: ApiTranslatorClaimService,
    private functionService: FunctionService,
    private limitsManagementService: LimitsManagementService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {

    const jsonClaim = this.cache.getCache().get(this.card.idSession).data;

    this.claim = this.apiTranslatorClaimService.jsonClaimToApiClaim(jsonClaim);
    this.policyNumber = this.claim.policyNumber;
    this.policyType = this.claim.policyType;
    this.idClaim = this.claim.idClaim;

    this.isIndividualPolicy = this.policyType === 'Individuale';

    this.isAuthorizedForNewLimit = this.functionService.isAuthorizedForClaimFeature('CLAIMS_INSERT_LIMIT');
    this.isAuthorizedForEditLimit = this.functionService.isAuthorizedForClaimFeature('CLAIMS_EDIT_LIMIT');

    this.reloadData();

    this.activeDetail = 'active';

    this.formatValidityDate = 'dd/MM/yyyy';
    this.formatStartDate = 'dd/MM/yyyy HH:mm:ss';
  }

  reloadData() {

    if (this.idClaim) {
      this.getLimistGeneralData(this.idClaim);
      this.getLimitsCounterGeneralData(this.idClaim);
    }
  }

  onTabClick(clicked: any, idLimit: number) {
    if (clicked === 'claims') {
      this.getLimitClaims(idLimit, this.idClaim.toString());
      this.activeClaims = 'active';
      this.activeDetail = '';
      this.showClaims = true;
    } else {
      this.activeDetail = 'active';
      this.activeClaims = '';
      this.showClaims = false;
    }
  }

  onTabCounterClick(clicked: any, idLimitCounter: number) {
    if (clicked === 'claims') {
      this.getLimitCounterClaims(idLimitCounter, this.idClaim.toString());
      this.activeClaims = 'active';
      this.activeDetail = '';
      this.showClaims = true;
    } else {
      this.activeDetail = 'active';
      this.activeClaims = '';
      this.showClaims = false;
    }
  }

  hasResult(): boolean {
    return true;
  }

  isClaims(): boolean {
    return this.showClaims;
  }

  showSubtypologyCode(limit: LimitDto) {

    const subtypeCode = limit.subtype.codice;
    return subtypeCode && subtypeCode !== '4'; // DOMICILIATARI
  }

  // SERVICE 1
  getLimistGeneralData(idClaim: number) {
    this.eventService.broadcastEvent('start-loader');
    this.limitsManagementService.getLimitsGeneralDataByIdClaim(idClaim)
      .subscribe((foundLimits: LimitDto[]) => {
        if (foundLimits.length > 0) {
          this.limits = foundLimits;
        }
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  getLimitsCounterGeneralData(idClaim: number) {
    this.eventService.broadcastEvent('start-loader');
    this.limitsManagementService.getLimitsCounterGeneralDataByIdClaim(idClaim)
      .subscribe((foundLimitsCounter: LimitDto[]) => {
        if (foundLimitsCounter.length > 0) {
          this.limitsCounter = foundLimitsCounter;
        }
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  // SERVICE 2
  getLimistDetails(idLimit: number) {
    this.limitsManagementService.getLimitDetailsByIdLimit(idLimit)
      .subscribe((foundLimitDetails: LimitDetailDto[]) => {
        if (foundLimitDetails) {
          if (this.limits) {
            let index = 0;
            this.limits.forEach(limit => {
              if (limit.id === idLimit) {
                this.limits[index].limitDetailList = foundLimitDetails;
              }
              index++;
            });
          }
        }
      });
  }

  getLimitCounterDetails(idLimitCounter: number) {
    this.limitsManagementService.getLimitCounterDetailsByIdLimitCounter(idLimitCounter)
      .subscribe((foundLimitCounterDetails: LimitDetailDto[]) => {
        if (foundLimitCounterDetails) {
          if (this.limitsCounter) {
            let index = 0;
            this.limitsCounter.forEach(limitCounter => {
              if (limitCounter.id === idLimitCounter) {
                this.limitsCounter[index].limitDetailList = foundLimitCounterDetails;
              }
              index++;
            });
          }
        }
      });
  }

  // SERVICE 3
  getLimitClaims(idLimit: number, claimIdToExclude: string) {
    this.limitsManagementService.getLimitCumulativeClaimsByIdLimit(idLimit, claimIdToExclude)
      .subscribe((foundLimitClaims: LimitClaimDto[]) => {
        if (foundLimitClaims) {
          if (this.limits) {
            let index = 0;
            this.limits.forEach(limit => {
              if (limit.id === idLimit) {
                this.limits[index].limitClaimList = foundLimitClaims;
              }
              index++;
            });
          }
        }
      });
  }

  getLimitCounterClaims(idLimitCounter: number, claimIdToExclude: string) {
    this.limitsManagementService.getLimitCounterCumulativeClaimsByIdLimitCounter(idLimitCounter, claimIdToExclude)
      .subscribe((foundLimitCounterClaims: LimitClaimDto[]) => {
        if (foundLimitCounterClaims) {
          if (this.limitsCounter) {
            let index = 0;
            this.limitsCounter.forEach(limitCounter => {
              if (limitCounter.id === idLimitCounter) {
                this.limitsCounter[index].limitClaimList = foundLimitCounterClaims;
              }
              index++;
            });
          }
        }
      });
  }

  cancelLimit(limit: LimitDto) {

    limit.active = false;

    this.eventService.broadcastEvent('start-loader');
    this.limitsManagementService.newLimit(this.idClaim, limit).subscribe(
      () => {
        this.eventService.broadcastEvent('stop-loader');
        this.reloadData();
      }
    );
  }

  openLimitModal(event: any, limit: LimitDto, type: string) {
    event.stopPropagation();

    const modalRef = this.modalService.open(LimitModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });

    modalRef.componentInstance.apiClaim = this.claim;
    modalRef.componentInstance.limit = limit || new LimitDto();
    modalRef.componentInstance.modalType = type;

    modalRef.result.then(
      (outcome) => {

        if (outcome) {

          if (outcome.code === '1') {

            this.reloadData();
          } else {

            this.alertService.translatedMessage(outcome.descr);
          }
        }
      }
    );

    return false;
  }
}

import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';

import {ToolConfDefinition} from '../../model/tool-conf-definition';
import {FormFieldDefinition} from '../../../models/pip.model';

@Component({
  selector: 'lic-tool-conf[definition]',
  templateUrl: './lic-tool-conf.component.html',
  styleUrls: ['./lic-tool-conf.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicToolConfComponent),
      multi: true
    }
  ]
})
export class LicToolConfComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  private $subscriptions: Subscription[] = [];

  @Input() definition: ToolConfDefinition | FormFieldDefinition;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    factor: new UntypedFormControl(null)
  });

  public inputType: 'DATE' | 'ENUM' | 'DOUBLE' | 'BENEFICIARY' | 'IBAN' | null;

  constructor() {
  }

  ngOnInit(): void {
    this.$subscriptions.push(
      this.formGroup.get('factor').valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouch();
      }),
    );
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definition) {
      this.inputType = changes.definition.currentValue.type;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  writeValue(obj: any): void {
    this.formGroup.get('factor').setValue(obj, {emitEvent: false});
  }

  onChange(obj: any) {
  }

  onTouch() {
  }
}

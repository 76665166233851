import {State} from '@rgi/rx/state';
import {InstalmentContainer} from '../../models/details/instalment';
import {ContractDetails} from '../../models/details/contract-details';

export interface ConsultationContractDetailsNavigationData {
  contractNumber: string;
  policies:any
  userCode:string;
}

export class ConsultationContractDetailState extends State {
  contractNumber: string;
  searchResults:any
  contract: ContractDetails;
  signInstalment: InstalmentContainer;
  nextInstalment: InstalmentContainer;
  userCode:string;
}

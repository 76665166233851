import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label, ThemeService} from 'ng2-charts';

@Component({
  selector: 'lpc-funds-chart',
  templateUrl: './lpc-funds-chart.component.html',
  styleUrls: ['./lpc-funds-chart.component.css'],
  providers: [ThemeService]
})
export class LpcFundsChartComponent implements OnInit {

  @Input() lineChartData: ChartDataSets[];
  @Input() lineChartLabels: Label[];
  @Input() lineChartColors: Color[];
  @Input() lineChartLegend: boolean;
  @Input() lineChartType: ChartType;
  @Input() lineChartPlugins?: any[];
  @Input() lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    annotation: true,
    legend: {position: 'bottom'}
  };

  constructor() { }

  ngOnInit() {
  }

}

export const receiptCancellation = 'AQ';
export const settlementsCancellation = 'AL';

export interface ReasonCancelled {
    code: string;
    description: string;
}

export interface ResponseReasonCancelled {
    operationType: string;
    reasonCancellation: any[][];
}

export interface DetailCancellationReturn {
    receiptId: string;
    note: string;
    reasonCancelledSelected?: string;
    dateReceipt: string;
    claimNumber: string;
}

export const INIT_DETAIL_CANCELLATION: DetailCancellationReturn = {
    dateReceipt: '',
    note: '',
    claimNumber: '',
    receiptId: ''
};

export interface CancellationBodyRequest {
    receiptId: string;
    receiptNote: string;
    receiptIdMotivoAnnullo: string;
    receiptDate: Date;
}

export interface IComboListCancellation {
    receiptIdMotivoAnnullo: string; // motivo annullo (sempre 10)
    receiptDate: string; // data del sinistro
    receiptIdRamoMinisteriale: string; // ramo ministeriale
    receiptId: string; // id quietanza - Nuovo
}

export interface ResponseSaveCancelled {
    code: string;
    descr: string;
    idpotentialclaim?: string;
    potentialclaimnumber?: string;
    companycode?: string;
    detail?: string;
    messages?: string;
    savedCodeList?: string;
    objects?: string;
}

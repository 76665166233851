import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges, OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {RgiRxRouterLink} from '../directives/rgi-rx-router-link.directive';
import {ActiveRoute, RgiRxRouteData, RgiRxRouteOptions} from '../router.api';
import {RgiRxRouteLocationOptions, RgiRxRouterLocation} from './router.location.api';
import {RgiRxHistoryRouter} from './rgi-rx-router-location-facade';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {fromEvent, Subscription} from 'rxjs';

@Directive({
  selector: '[rgiRxRouterUrlLink]',
  exportAs: 'rgiRxRouterUrlLink'
})
export class RgiRxRouterUrlLinkDirective extends RgiRxRouterLink implements OnInit, DoCheck, OnChanges, OnDestroy {
  private mouseOverSubscription = Subscription.EMPTY;

  @HostBinding('attr.target') @Input() target?: string;

  /**
   * Allow the link to replace the current history state
   */

  @Input() replaceState = false;

  @Input('rgiRxRouterUrlLink') link: string;

  /**
   * Whether it should render the href attribute with the external link
   */
  @Input() renderHref = true;


  @Input() renderHrefStrategy: 'mouseover' | 'always' = 'mouseover';

  /**
   * RouteData for the current generated link
   */
  @Input() routeData?: RgiRxRouteData<any>;

  /**
   * Any state for the location history
   */
  @Input() state?: any;

  private _disableLink = false;

  private _preserveQueryString = false;

  constructor(private router: RgiRxHistoryRouter,
              elementRef: ElementRef,
              private renderer: Renderer2,
              private _location: RgiRxRouterLocation,
              private keyValueDiffers: KeyValueDiffers,
              @Optional() private activeRoute: ActiveRoute
  ) {
    super(elementRef, activeRoute);
  }

  /**
   * Weather should preserve the actual query string data
   */
  @Input() get preserveQueryString(): boolean {
    return this._preserveQueryString;
  }

  set preserveQueryString(value: boolean) {
    this._preserveQueryString = value;
  }

  /**
   * Disable navigation on click for this link
   */
  @Input() get disableLink(): boolean {
    return this._disableLink;
  }

  set disableLink(value: boolean) {
    this._disableLink = coerceBooleanProperty(value);
  }

  private routeDataDiffer: KeyValueDiffer<unknown, unknown>;

  ngOnInit(): void {
    if (this.routeData) {
      this.routeDataDiffer = this.keyValueDiffers.find(this.routeData).create();
    }
    if (this.renderHrefStrategy === 'mouseover') {
      this.subscribeMouseOver();
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.link || changes.routeData) {
      if (changes.routeData && !changes.routeData.isFirstChange() && changes.routeData.currentValue && !this.routeDataDiffer) {
        this.routeDataDiffer = this.keyValueDiffers.find(changes.routeData.currentValue).create();
      }
      if (this.isAnchorElement && this.renderHrefStrategy === 'always') {
        this.mouseOverSubscription.unsubscribe();
        this.updateHref();
      }
    }
    if (changes.renderHrefStrategy && changes.renderHrefStrategy.currentValue === 'mouseover') {
      this.subscribeMouseOver();
    }
  }


  private subscribeMouseOver() {
    this.mouseOverSubscription.unsubscribe();
    this.mouseOverSubscription = fromEvent(this._elementRef.nativeElement, 'mouseover')
      .subscribe(
      next => this.updateHref()
    );
  }

  ngDoCheck(): void {
    if (this.routeDataDiffer && this.routeData) {
      const keyValueChanges = this.routeDataDiffer.diff(this.routeData);
      if (keyValueChanges && this.renderHrefStrategy === 'always') {
        this.updateHref();
      }
    }
  }


  navigate() {
    const url = this.url ? this.url : this.link;
    this.router.navigateByURL(url, this.routeData, this.makeOptions());
  }

  private generateLinkRef() {
    return this.router.findRouteByURL(this.link, this.routeData, this.makeOptions());
  }

  private updateHref() {
    if (!this.renderHref || !this.url) {
      return;
    }
    const linkRef = this._location.toExternalURL(this.url);
    if (linkRef) {
      this.renderer.setAttribute(this._elementRef.nativeElement, 'href', linkRef);
      return;
    }
    this.renderer.removeAttribute(this._elementRef.nativeElement, 'href');
  }

  get url(): string | undefined {
    const linkRef = this.generateLinkRef();
    if (!linkRef) {
      return;
    }
    return linkRef.uriDef.url;
  }

  @HostListener('click', ['$event'])
  _handleClick(event: MouseEvent) {
    event.preventDefault();
    if (!this.disableLink) {
      this.navigate();
    }
  }

  ngOnDestroy(): void {
    this.mouseOverSubscription.unsubscribe();
  }



  protected makeOptions(): RgiRxRouteLocationOptions {
    const rgiRxRouteOptions = super.makeOptions() as RgiRxRouteLocationOptions;
    rgiRxRouteOptions.replaceState = !!this.replaceState;
    rgiRxRouteOptions.preserveQueryString = !!this.preserveQueryString;
    rgiRxRouteOptions.state = this.state;
    return rgiRxRouteOptions;
  }
}

<div class="ppevo-survey-eval-section">

    <div class="ppevo-title">
        <span *ngIf="sectionCfg.isPackage" class="ppevo-icon-package"></span>
        <span *ngIf="!sectionCfg.isPackage" class="ppevo-description">{{sectionCfg.label}}</span>
        <span *ngIf="sectionCfg.isPackage" class="ppevo-description" translate>NG_PASSPROPRO_SURVEY.packages</span>
        <div class="ppevo-line"></div>
    </div>
    <div class="ppevo-coverages">
        <survey-eval-coverage
            *ngFor="let cov of coverages"
            [hidden]="isCoverageHidden(cov)"
            [coverage]="cov"
            (clickInfo)="onClickInfo($event)"
            [editable]="isEdit"
            (select) = "onSelectCoverage($event)"
            [isPackage]="sectionCfg.isPackage"
            [validation]="getValidation(cov)"
            [cfg]="sectionCfg.isPackage ? productCfg.pkgsCfg.pkgsMap[cov.package.id] : productCfg.covsCfg.covsMap[cov.code]"
        ></survey-eval-coverage>
    </div>
</div>

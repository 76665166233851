import { PcProposal } from './../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import { ContractNumber } from './../../models/contract-number';
import { Injectable } from '@angular/core';


import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import { AbstractStateManager, State, StateStoreService } from '@rgi/rx/state';
import { Observable } from 'rxjs';
import { PolicyTechnicalData } from '../../models/domain-models/parameters/policy-technical-data';
import { ReIssueProposalManagementNavigationData } from './re-issue-proposal-management-state-manager.service';
import { ReIssueSaveQuoteBusinessService } from '../re-issue-business-services/re-issue-save-quote-business.service';
import { Warning } from '../../models/domain-models/warning';
import { PolicyContact } from '../../models/domain-models/policy-contact';
import { AnagIssueSubject } from '../re-issue-anag/anag-issue';
import { ReIssuePolicyDataNavigationData } from './re-issue-policy-data-state';
import { ReissueAssetNavigationDataI } from './re-issue-asset-state-manager.service';
import { ReIssueQuotationNavigationDataI } from './re-issue-quotation-state-manager.service';
import {ProposalStatus} from '../../models/domain-models/quotation';

export class ReIssueSaveQuoteManager extends State {
  resourceId: string;
  policyTechnicalData: PolicyTechnicalData;
  subject: AnagIssueSubject;
  productCode: string;
  contractNumber: ContractNumber;
  proposalStatus: ProposalStatus;
  node: any;
  companyId: string;
  isPersonaGiuridica: boolean;
  warnings: Warning[] | null;
  nonBlockingWarnings: Warning[] | null;
  authorize: boolean;
  authorizationRequest: boolean;

  proposal: PcProposal;
  contact: PolicyContact;
}

export interface ReIssueSaveQuoteNavigationData {
  isPersonaGiuridica: boolean;
  resourceId: string;
  policyTechnicalData: PolicyTechnicalData;
  subject: AnagIssueSubject;
  productCode: string;
  contractNumber: ContractNumber;
  proposalStatus: ProposalStatus;
  node: any;
  companyId: string;
  authorize: boolean;
  authorizationRequest: boolean;
  contact: PolicyContact;

  proposal: PcProposal;
}

@Injectable({
  providedIn: 'root'
})
export class ReIssueSaveQuoteStateManagerService<T extends ReIssueSaveQuoteManager> extends AbstractStateManager<T> {
  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected reIssueSaveQuoteBusinessService: ReIssueSaveQuoteBusinessService<T>) {
    super(activeRoute, stateStoreService);
    const st =
      !!stateStoreService.get<T>(this.activeRoute.key) ?
        stateStoreService.get<T>(this.activeRoute.key) : this.newReIssueSaveQuoteManager();

    this.updateState$(this.initQuote(st));
  }

  initQuote(st: T): Observable<T> {
    const data = this.activeRoute.getRouteData<ReIssueSaveQuoteNavigationData>();
    st.resourceId = data.resourceId;
    st.policyTechnicalData = data.policyTechnicalData;
    st.subject = data.subject;
    st.productCode = data.productCode;
    st.contractNumber = data.contractNumber;
    st.proposalStatus = data.proposalStatus;
    st.node = data.node;
    st.companyId = data.companyId;
    st.isPersonaGiuridica = data.isPersonaGiuridica;
    st.proposal = data.proposal;
    st.authorize = data.authorize;
    st.authorizationRequest = data.authorizationRequest;
    st.contact = data.contact;
    return this.reIssueSaveQuoteBusinessService.postWarnings(st);
  }

  newReIssueSaveQuoteManager() {
    return new ReIssueSaveQuoteManager(this.activeRoute.key) as T;
  }

  goToProposalManagement() {

    // const state = this.getCurrentState();
    // this.reIssueSaveQuoteBusinessService.saveQuote(state).subscribe(st => {
    this.getState$().subscribe(st => {
      const request: ReIssueProposalManagementNavigationData = {
        resourceId: st.resourceId,
        policyTechnicalData: st.policyTechnicalData,
        subject: st.subject,
        productCode: st.productCode,
        proposalStatus: st.proposalStatus,
        backNavigate: 're-issue-summary',
        policy: null,
        contractId: null,
        node: st.node,
        companyId: st.companyId,
        contractNumber: st.contractNumber,
        isBuyProposal: false,
        isPersonaGiuridica: st.isPersonaGiuridica,
        authorize: st.authorize,

        proposal: st.proposal,
        contact: st.contact,
        isFromExternalCard: false
      };

      this.routingService.navigate('re-issue-proposal-management', request, this.activeRoute.id);
    });


  }

  goToIthStep(i) {
    this.navigateToIthStep(i);
  }

  private navigateToIthStep(i) {
    const st = this.getCurrentState();
    const data = this.prepareData(st, i);
    this.routingService.navigate(data.route, data.object, this.activeRoute.id);
  }

  private prepareData(st: T, i: number) {
    let data: { object: any, route: string } = { object: null, route: '' };
    switch (i) {
      case 0:
        data.object = this.prepareReIssuePolicyData(st);
        data.route = 're-issue-policy-data';
        break;
      case 1:
        data.object = this.prepareReIssueAssetData(st);
        data.route = 're-issue-asset';
        break;
      case 2:
        data.object = this.prepareReIssueQuotationData(st);
        data.route = 're-issue-quotation';
        break;
      case 3:
        data.object = this.prepareReIssueSummarynData(st);
        data.route = 're-issue-summary';
        break;
      default:
        console.log('Unknown step');
        // Additional logic for unknown steps
        break;
    }

    return data;
  }

  private prepareReIssuePolicyData(st: T) {
    const dataPolicy: ReIssuePolicyDataNavigationData = {
      contractorSubject: st.subject,
      previousContractor:st.subject,
      resourceId: st.resourceId,
      productName: null,
      productId: null,
      node: st.node,
      productCode: st.productCode,
      companyId: st.companyId,
      policyTechnicalData: st.policyTechnicalData,
      editMode: null,
      editFunctionality: null,
      isSubstitution: null,
      backFromStep: true,
      isToDisableFraz: null,
      authorize: null,
      thirdPersonContact: null
    };
    return dataPolicy;
  }

  private prepareReIssueAssetData(st: T) {
    const dataAsset: ReissueAssetNavigationDataI = {
      resourceId: st.resourceId,
      contractorSubject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: null,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: null,
      productId: null,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: null,
      editFunctionality: null,
      isSubstitution: null,
      isSubjectModified: false,
      isToDisableFraz: null,
      authorize: null,
      bIntermediateSaving: null,
      thirdPersonContact: null
    };
    return dataAsset;
  }

  private prepareReIssueQuotationData(st: T): ReIssueQuotationNavigationDataI {
    const dataQuotation: ReIssueQuotationNavigationDataI = {
      resourceId: st.resourceId,
      subject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      lpsData: null,
      branchOffice: st.policyTechnicalData.branchCode,
      productName: null,
      productId: null,
      productCode: st.productCode,
      node: st.node,
      companyId: st.companyId,
      editMode: null,
      editFunctionality: null,
      isPersonaGiuridica: null,
      resetQuestionnaire: false,
      isSubjectModified: false,
      isSubstitution: null,
      isToDisableFraz: null,
      authorize: null,
      bIntermediateSaving: null,
      clausesFromAsset: null
    };
    return dataQuotation;
  }

  private prepareReIssueSummarynData(st: T) {
    const dataSummary = {
      resourceId: st.resourceId,
      subject: st.subject,
      policyTechnicalData: st.policyTechnicalData,
      branchOffice: null,
      productName: null,
      allAssets: null,
      productCode: null,
      proposalStatus: null,
      node: null,
      companyId: null,
      contact: st.contact
    };
    return dataSummary;
  }

}

import {InjectionToken} from '@angular/core';

export class GPButtonCfg {
  showBtn: boolean;
  btnLabel: string;
}

export class GPCommissionDiscountBtnsConfig {
  cluster: {
    pageFooter: {
      commissions: GPButtonCfg;
      discount: GPButtonCfg;
    };
    clusterSection: GPButtonCfg
  };
  standard: {
    pageFooter: {
      commissions: GPButtonCfg;
      discount: GPButtonCfg;
    };
  };
}

export const GP_COMMISSIONS_DISCOUNT_BUTTONS = new InjectionToken<GPCommissionDiscountBtnsConfig>('GP_COMMISSIONS_DISCOUNT_BUTTONS');

export const GP_COMMISSIONS_DISCOUNT_CONFIG: GPCommissionDiscountBtnsConfig = {
  cluster: {
    pageFooter: {
      commissions: {
        showBtn: false,
        btnLabel: ''
      },
      discount: {
        showBtn: false,
        btnLabel: ''
      },
    },
    clusterSection: {
      showBtn: true,
      btnLabel: '_GP_._BTN_._COMMISSIONS_DISCOUNT_'
    }
  },
  standard: {
    pageFooter: {
      commissions: {
        showBtn: true,
        btnLabel: '_GP_._BTN_._COMMISSIONS_DISCOUNT_'
      },
      discount: {
        showBtn: false,
        btnLabel: ''
      },
    }
  }
};

import {Inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsultationListBusinessService {
  constructor(
    @Inject('sessionService') private sessionService: any
  ) {}

  remove(id) {
    this.sessionService.remove(id);
  }

}

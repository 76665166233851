<div class="col-md-12 life-detail-margin-box life-detail-box" *ngIf="!!generalData" id="consulting-detail">

  <div class="row life-detail-card-title-container">
    <div class="row life-detail-title">
      <div class="card-title">
        <span class="header-icon rgifont  "></span>
        <span class="menu-title" translate>{{header}}</span>
        <span class="ng-hide"> </span> <span class="ng-hide"></span>
        <span *ngIf="movDetail" class="attach-to-title ">{{movDetail}}</span>
      </div>
      <div class="hidden-lg">
        <span class="rgifont pull-right maximize-session-card  hidden-sm hidden-xs ng-hide rgi-arrows-alt card-header-single-icon"></span>
        <span class="rgifont rgi-times-circle pull-right close-session-card" (click)="removeSession()"></span>
      </div>
    </div>
  </div>

  <!-- MESSAGES AND WARNINGS -->
  <div class="col-md-12 life-detail-messages-container">
    <div *ngIf="hasMessages">
      <pnc-floating-error-messages
        [blockingMsgs]="getMessages('2')"
        [warningMsgs]="getMessages('1')"
      >
      </pnc-floating-error-messages>
    </div>
  </div>

  <div class="col-md-12 life-detail-first-info">
    <div class="col-md-5 life-detail-img">
      <div class="hidden-xs hidden-sm anag-user-ico">
        <span class="rgifont life-detail-icon-padding rgi-listini_funzioni"></span>
      </div>
    </div>
    <div class="col-md-7 life-detail-general-info">
      <h3 class="col-md-12 card-color-blue" style="margin-top: 0px !important;">
        {{generalData.descrProd}}
      </h3>
      <div class="container-fluid anag-data">
        <div class="col-md-12" *ngFor="let element of generalDataMap | keyvalue: asIsOrder">
          <span class="tbld_col_title" translate>{{element.key}}</span><span class="tbld_col_title">: </span><span class="tbld_col_value">{{element.value}}</span>
        </div>
      </div>
    </div>
  </div>
   <div class="col-md-12">
    <div class="col-md-6 life-detail-infobox">
      <div [ngClass]="setMaxFontSize ? 'life-increase-font-size' : 'life-medium-increase-font-size'" style="height: 60px;">
        {{!!generalData.polNum ? generalData.polNum: generalData.propNum}}
      </div>
      <span class="tbld_col_title" style="color: white; font-size: 18px;" translate>{{label}}</span>
    </div>
    <div class="col-md-6 life-detail-infobox">
      <div class="life-increase-font-size" style="height: 60px;">
        {{generalData.effDate}}
      </div>
      <span class="tbld_col_title" style="color: white; font-size: 18px;" translate>lpc_fiscalDate</span>
    </div>
  </div>
  <div *ngIf="buttons" class="col-md-12">
    <div class="btn-group btn-group-justified">
      <div class="btn-group" ng-controller="ContainerSessionCtrl">
        <button type="button" (click)="propagateAction('BACK')" data-qa="life-detail-back" class="btn btn-warning btn-secondary pull-right text-uppercase" translate>lpc_BACK</button>
      </div>
      <div *ngFor="let action of avalActionOperations" class="btn-group">
        <button type="submit" (click)="propagateAction(action.action)" [attr.data-qa]="'life-detail-'+ action.label" class="btn btn-warning pull-right text-uppercase" translate>{{action.label}}</button>
      </div>
    </div>
  </div>
</div>

<ng-container [formGroup]="group">
  <div [ngClass]="{'rgi-gp-flex-end': showCustomAction(), 'rgi-gp-flex-field-container': factor.minMaxVisible}">
    <rgi-rx-form-field [ngClass]="{'rgi-gp-flex-70': showCustomAction(), 'rgi-gp-flex-item': true}">
      <label rgiRxLabel>{{factor.description}}</label>
      <input type="text" rgiCountryLayerInputNumberFormat rgiGpFocusElement formControlName="{{factor.code}}"
             [attr.data-qa]="'factor' + factor.code" [maximumFractionDigits]="2">
    </rgi-rx-form-field>
    <rgi-rx-form-field class="rgi-gp-flex-item" *ngIf="factor.minMaxVisible">
      <label rgiRxLabel>Min</label>
      <input type="text" rgiCountryLayerInputNumberFormat rgiGpFocusElement formControlName="{{factor.code + '_MIN'}}"
             [attr.data-qa]="'factor' + factor.code + '_MIN'" [maximumFractionDigits]="2">
    </rgi-rx-form-field>
    <rgi-rx-form-field class="rgi-gp-flex-item" *ngIf="factor.minMaxVisible">
      <label rgiRxLabel>Max</label>
      <input type="text" rgiCountryLayerInputNumberFormat rgiGpFocusElement formControlName="{{factor.code  + '_MAX'}}"
             [attr.data-qa]="'factor' + factor.code + '_MAX'" [maximumFractionDigits]="2">
    </rgi-rx-form-field>
    <button *ngIf="showCustomAction()"
            rgi-rx-button color="secondary" class="uppercase rgi-gp-flex-30"
            (click)="customFactorAction.emit(factor)" data-qa="buttonsCoinsurances"
            translate>{{ getCustomActionLabel() }}
    </button>
  </div>
</ng-container>

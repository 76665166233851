import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ExposureEntity } from '../domain/exposure-entity';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { LegalFeeOmniaEntity } from '../domain/legal-fee-omnia-entity';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { NewPaymentService } from '../new-payment.service';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-ctrl-modal',
  templateUrl: './invoice-ctrl-modal.component.html',
  styleUrls: ['./invoice-ctrl-modal.component.scss']
})
export class InvoiceCtrlModalComponent implements OnInit {
  @Input()  newPayFormModal: UntypedFormGroup;
  @Input()  exp: ExposureEntity;
  @Input()  idCampoExp: string;
  @Input()  idCampoSubExp: string;
  @Input()  potPayFE: InitPotentialPaymentEntity;

  public feeOmniaData: LegalFeeOmniaEntity;
  public extension: string;
  public today: NgbDate;
  public errorMessage = null;
  public blockModify: boolean = false;
  public inModify: boolean = false;
  public consult: boolean = false;
  authSrv: any;
  eFailureService: TypeEnum;

  constructor(
    public activeModal: NgbActiveModal,
    @Inject('eventService') private eventService: any,
    @Inject('authService') authService,
    private paymentConstants: PaymentConstants,
    private formBuilder: UntypedFormBuilder,
    public translateService: RgiRxTranslationService,
    private newPaymentService: NewPaymentService) {
      this.authSrv = authService;
     }


  initEnums() {
    this.eFailureService = this.paymentConstants.getSingleSuccessServer(ENUMSLISTPAYMENT.FAILURE_SERVICE);
  }
  ngOnInit() {
    this.initEnums();

    this.feeOmniaData = this.exp.feeDataOmnia;
    this.extension = '';
    this.blockModify = false;

    if (this.idCampoSubExp == null) {
      this.extension = this.idCampoExp;
    } else {
      this.extension = this.idCampoSubExp;
    }

    if (this.newPayFormModal) {
      this.consult = false;
      this.newPayFormModal.addControl('feeNumberCtrl' + this.extension,
        new UntypedFormControl({ value: this.feeOmniaData.feeNumber, disabled: false }, Validators.nullValidator));
      this.newPayFormModal.addControl('feeDateCtrl' + this.extension,
        new UntypedFormControl({ value: new Date(this.feeOmniaData.feeDate), disabled: false }, Validators.nullValidator));
      this.newPayFormModal.addControl('feeAmountCtrl' + this.extension,
        new UntypedFormControl({ value: this.feeOmniaData.feeAmount, disabled: false }, Validators.nullValidator));
    } else {
      this.consult = true;
      this.newPayFormModal =  this.formBuilder.group({});
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  modifyFee() {
    this.inModify = true;
  }

  saveFee() {
    this.eventService.broadcastEvent('start-loader');
    this.feeOmniaData.feeNumber = this.newPayFormModal.controls['feeNumberCtrl' + this.extension].value;
    this.feeOmniaData.feeDate = this.newPayFormModal.controls['feeDateCtrl' + this.extension].value;
    this.exp.feeDataOmnia.feeAmount = this.newPayFormModal.controls['feeAmountCtrl' + this.extension].value;
    this.newPaymentService.dataFeeOmniaModify(this.exp)
    .subscribe((outcome: any) => {
      console.log('dataFeeOmniaModify - outcome:  ', outcome);

      if (outcome.code === this.eFailureService.eTypeCode) {
       // la fattura non è stata modificata perche esistente
       this.errorMessage = outcome.descr;
      } else {
        this.verifyExistance();
        this.inModify = false;
      }
      this.eventService.broadcastEvent('stop-loader');

    },
    (error: any) => {
      this.eventService.broadcastEvent('stop-loader');
    });
  }

  cancelFee() {
    this.inModify = false;
  }

  verifyExistance() {
    if ((this.newPayFormModal.controls['feeNumberCtrl' + this.extension].valid &&
         this.newPayFormModal.controls['feeNumberCtrl' + this.extension] &&
         this.newPayFormModal.controls['feeDateCtrl' + this.extension].valid &&
         this.newPayFormModal.controls['feeDateCtrl' + this.extension]) && !this.inModify) {
        this.eventService.broadcastEvent('start-loader');

        this.feeOmniaData.feeNumber = this.newPayFormModal.controls['feeNumberCtrl' + this.extension].value;
        this.feeOmniaData.feeDate = this.newPayFormModal.controls['feeDateCtrl' + this.extension].value;
        this.newPaymentService.dataFeeOmniaVerify(this.exp)
          .subscribe((feeResponse: LegalFeeOmniaEntity) => {
            console.log('verifyExistance - outcome:  ', feeResponse);
            this.feeOmniaData = feeResponse;
            this.exp.feeDataOmnia = feeResponse;

            this.newPayFormModal.controls['feeAmountCtrl' + this.extension].setValue(this.feeOmniaData.feeAmount);
            const listFunz = this.authSrv.getOperator().enabledFeatures;

            if (listFunz && listFunz.indexOf('SXMIDC') === 0) {
             this.blockModify = true;
            }

            this.eventService.broadcastEvent('stop-loader');
        },
        (error: any) => {
          this.eventService.broadcastEvent('stop-loader');
        });
    }


  }

  calculatePercentage(amount: number) {
    if (!this.consult) {
      return this.round(( amount * 100) / this.newPayFormModal.controls['feeAmountCtrl' + this.extension].value, 2);
    } else {
      return this.round((amount * 100) / this.feeOmniaData.feeAmount, 2) ;
    }
  }

  valueTotalAmount() {
    if (!this.consult) {
      return (this.feeOmniaData.amountPaid ? this.feeOmniaData.amountPaid : 0)
      + this.newPayFormModal.controls['subExpLegalFeeAmount' + this.exp.idCampo].value;
    } else {
      return (this.feeOmniaData.amountPaid ? this.feeOmniaData.amountPaid : 0)
      + this.exp.legalFeeAmount;
    }
  }

  valueRemainderAmount() {
    if (!this.consult) {
      return  this.newPayFormModal.controls['feeAmountCtrl'
      + this.extension].value - (this.feeOmniaData.amountPaid ? this.feeOmniaData.amountPaid : 0);
    } else {
      return  this.feeOmniaData.feeAmount - (this.feeOmniaData.amountPaid ? this.feeOmniaData.amountPaid : 0);
    }
  }

  valueCurrentAmount() {
    if (!this.consult) {
     return  this.newPayFormModal.controls['subExpLegalFeeAmount' + this.exp.idCampo].value;
    } else {
      return this.exp.legalFeeAmount;
    }
   }

  onConfirm() {
    this.eventService.broadcastEvent('start-loader');
    if (!this.newPayFormModal.controls['feeNumberCtrl' + this.extension].valid ||
        !this.newPayFormModal.controls['feeNumberCtrl' + this.extension] ||
        !this.newPayFormModal.controls['feeDateCtrl' + this.extension].valid ||
        !this.newPayFormModal.controls['feeDateCtrl' + this.extension] ||
        !this.newPayFormModal.controls['feeAmountCtrl' + this.extension].valid ||
        ! this.newPayFormModal.controls['feeAmountCtrl' + this.extension]) {
      this.errorMessage = 'Fee data missing';
      this.eventService.broadcastEvent('stop-loader');
    } else if (this.calculatePercentage(this.valueTotalAmount()) > 100) {
      this.translateService.translate('_CLAIMS_._SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.eventService.broadcastEvent('stop-loader');
    }  else {

      this.exp.feeDataOmnia.feeAmount = (this.newPayFormModal.controls['feeAmountCtrl' + this.extension].value == null) ?
                                      0 :
                                      this.newPayFormModal.controls['feeAmountCtrl' + this.extension].value;
      this.exp.feeDataOmnia.feeNumber = this.newPayFormModal.controls['feeNumberCtrl' + this.extension].value;
      this.exp.feeDataOmnia.feeDate = this.newPayFormModal.controls['feeDateCtrl' + this.extension].value;

      this.exp.feeDataOmniaOK = true;
      this.errorMessage = null;
      this.eventService.broadcastEvent('stop-loader');
      this.activeModal.dismiss();
      this.newPayFormModal.controls.correctModalClose.setValue(true);
    }
  }

  round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

}

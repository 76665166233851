import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import { ActiveRoute } from '@rgi/rx/router';
import { CardAnalyticsReducerService } from './card-analytics-reducer.service';
import {Observable, of, Subscription} from 'rxjs';
import { Report } from '../model/report';
import { ReportService } from '../services/report.service';

export class CardAnalyticsStateHome extends State {
  reports: Report[];
}


export class CardAnalyticsStateManagerHome extends RgiRxLocalStateManager<CardAnalyticsStateHome>{
  private reportSubjectSubscription: Subscription = Subscription.EMPTY;

  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, private reducer: CardAnalyticsReducerService,
              private reportService: ReportService) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<CardAnalyticsStateHome>(this.activeRoute.key) ?
      stateStoreService.get<CardAnalyticsStateHome>(this.activeRoute.key) : new CardAnalyticsStateHome(this.activeRoute.key);

    this.reportSubjectSubscription = this.reportService.getReportsSubject().subscribe(res => {
      const state = this.getCurrentState();
      if (state) {
        state.reports = res;
        this.updateState$(of(state));
      }
    });

    const initState$ = this.reducer.initPageOne(of(st), this.activeRoute);
    this.updateState$(initState$); // this will update the state
  }

  getAdminEnabled() {
    return this.reducer.getAdminEnabled();
  }

  getQlikLinkEnabled() {
    return this.reducer.getQlikLinkEnabled();
  }

  actionOpen(template) {
    this.reducer.openDashboard(template, this.activeRoute);
  }

  deleteReport(template) {
    this.reducer.deleteReport(template, this.activeRoute);
  }

  editReport(template) {
    this.reducer.editReport(template, this.activeRoute);
  }

  copyReport(template) {
    this.reducer.copyReport(template, this.activeRoute);
  }

  dispose() {
    super.dispose();
    this.reportSubjectSubscription.unsubscribe();
  }

  newReport() {
    this.reducer.openCreateReport(this.activeRoute);
  }

}


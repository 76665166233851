import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Activity, ActivityListService, ActivityVariable, ApiClaim,
ApiTranslatorClaimService, CardComponent, EnumType } from '@rgi/digital-claims-common-angular';
import { AllActivity } from '@rgi/digital-claims-common-angular/lib/model/domain/activity/activity-all';
import { ManualDeadlineComponent } from '../manual-deadline/manual-deadline.component';

@Component({
  selector: 'claims-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent extends CardComponent implements OnInit {

  @Input() activitiesList = [];
  currentList: Activity[];
  closedActivityList: Activity[];
  private claim: ApiClaim;
  tabActive: boolean = true;
  priorityLabel: EnumType[] = this.enumService.getEnumList('activity.priority');

  constructor(
    @Inject('containerCacheService') private cache,
    @Inject('authService') private authService,
    @Inject('enumService') private enumService,
    public activityListService: ActivityListService,
    private apiTranslatorClaimService: ApiTranslatorClaimService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    const jsonClaim = this.cache.getCache().get(this.card.idSession).data;
    this.claim = this.apiTranslatorClaimService.jsonClaimToApiClaim(jsonClaim);
    this.getActivitiesFromClaim(this.claim);
  }

  getActivitiesFromClaim(claim: ApiClaim) {
    let num = '';
    if (this.authService.getOperator().liquidationCentre && this.authService.getOperator().liquidationCentre.code) {
      if (!claim.claimNumber) {
        num = claim.reportNumber;
      } else {
        num = claim.claimNumber;
      }
      this.activityListService.getAllActivitiesFromClaimNumber(num)
    .subscribe((allAct: AllActivity) => {

      if (allAct.opened.length > 0) {
        allAct.opened = this.getAdditionalInfo(claim, allAct.opened);
        this.currentList = allAct.opened;
      } else {
        this.currentList = null;
      }

      if (allAct.closed.length > 0) {
        allAct.closed = this.getAdditionalInfo(claim, allAct.closed);
        this.closedActivityList = allAct.closed;
      } else {
        this.closedActivityList = null;
      }
    });
    }
    // TODO gestire card con utente loggato in retevendita
  }

  getAdditionalInfo(claim: ApiClaim, activities: Activity[]): Activity[] {
    for (const activity of activities) {
      if (activity.actBusinessKeyClaimBean && activity.actBusinessKeyClaimBean.idParty) {
        activity.involvedParty = claim.getInvolvedPartyFromId(activity.actBusinessKeyClaimBean.idParty);
      }
      if (activity.actBusinessKeyClaimBean && activity.actBusinessKeyClaimBean.idTypeOfLoss) {
        activity.typeOfLoss = claim.getTypeOfLossFromId(activity.actBusinessKeyClaimBean.idTypeOfLoss);
      }
      if (activity.actVariableBean) {
        activity.documentKey = this.getVariableByName(activity.actVariableBean, 'DocumentKey');
      }
      this.priorityLabel.forEach(pr => {
        if (activity.priority.toString() === pr.codice) {
          activity.descrPriority = pr.descrizione;
        }
      });
      // TODO commenta prima del commit
      // activity.actTaskBean[0].message = 'Descrizione di prova per il popover...';
      // Descrizione di prova per il popover...Descrizione di prova per il popover...';
    }
    return activities;
  }

  getVariableByName(variableArray: ActivityVariable[], name: string) {
    for (const variable of variableArray) {
      if (variable.varName === name) {
        return variable.varValueStr;
      }
    }
  }

  hasResult(): boolean {
    return !!this.currentList && this.currentList.length > 0;
  }

  hasClosedResult(): boolean {
    return !!this.closedActivityList && this.closedActivityList.length > 0;
  }

  dateGreaterThenToday(activity: Activity): boolean {
    return activity.expirationDate.getTime() > (new Date()).getTime();
    // return true;
  }

  manualDeadline(event: any) {
    event.stopPropagation();

    const modalRef = this.modalService.open(ManualDeadlineComponent, {
      windowClass: 'basic',
      size: 'lg'
    });

    modalRef.componentInstance.apiClaim = this.claim;

    modalRef.result.then((result) => {
      if (result) {
        this.getActivitiesFromClaim(this.claim);
      }
    });

    return false;
  }
  isClaim(): boolean {
    return this.claim && this.claim.claimNumber !== null && this.claim.claimNumber !== '';
  }

  @Input() set reloadsubcard(val: boolean) {
    if (val) {
      console.log('ricevuto evento reload: ', val);
      this.getActivitiesFromClaim(this.claim);
    }
  }

  onChangeTab(nw: boolean) {
    this.tabActive = nw;
}

}

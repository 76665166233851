import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EnvironmentService} from "./environment.service";
import {URILocatorService} from "./urilocator.service";


export function onEnvironmentInitialize(environmentService: EnvironmentService) {
   let lambda = (): Promise<any> => {
     return environmentService.init().toPromise().then(
      (res) => {
        console.debug("ngPassProPro environment", res);
      }
    , reason => {
        console.error("Unable to load passpropro.environment. it's must be present either a static provider for NG_PASSPROPRO_CONFIG.environment or a dynamic configuration endpoint ");
       })
  };
   return lambda;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    EnvironmentService,
    URILocatorService,
    {provide: APP_INITIALIZER, useFactory: onEnvironmentInitialize, deps: [EnvironmentService], multi: true}
  ]
})
export class EnvironmentModule { }

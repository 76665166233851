import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const ES: RgiRxSerializedI18n  = {
  START_EMISSION_FLOW_RE: 'Flujo de emisiones RE',
  START_POLICY_DATA: 'Datos de la póliza',
  START_ASSETS_DATA: 'Datos de bien asegurado',
  START_QUOTATION: 'Cotización',
  START_SUMMARY: 'Resumen',
  START_PROPOSAL_MANAGEMENT: 'Gestión de propuesta',
  START_SAVE_PROPOSAL: 'Guardar propuesta',
  START_EMISSION: 'Emisión',
  START_REPRINT_DOCUMENTS: 'Reimpresión de documentos',
  START_REPRINT_MOVEMENTS: 'Reimpresión de movimientos',
  START_SUBSTITUTION: 'Reemplazo',
  // Replace space with underscore
  RE_ISSUE: {
    DATE_CREDIT_CARD: 'No es posible continuar ya que la fecha de vigencia no puede retrasarse más de 360 días a partir de hoy',
    REASON: 'Motivo',
    RECALCULATE_SAVE: 'Recalcular y Guardar',
    CLEAR_DISCOUNTS: 'Suprimir descuentos',
    DISCOUNT_AMOUNT: 'Importe de descuentos',
    DISCOUNTS: 'Gestión de descuentos',
    WARRANTIES_SUMMARY: 'Resumen de garantías',
    IS_MANDATORY: 'es obligatorio',
    Country: 'País',
    POLICY_DATA: 'Datos de la póliza',
    ASSET_DATA: 'Datos de bienes asegurados',
    QUOTATION: 'Cotización',
    SUMMARY: 'Resumen',
    VALIDATE_BRANCH: 'Valide la sucursal',
    FIELDS_MARKED_IN_RED: 'Los campos marcados en rojo son obligatorios o contienen valores incorrectos',
    IS_REQUIRED: 'es necesario',
    COVERAGE_EXPIRE_DATE: 'Fecha de caducidad de la cobertura',
    SUBSCRIPTION_INST_PREMIUM: 'Prima de cuota de suscripción',
    INST_SIGN: 'Plazo de suscripción: ',
    MANDATORY_QUESTIONNAIRE_NOT_FILLED: 'Atención, hay al menos un cuestionario obligatorio sin cumplimentar',
    NATION: 'Nación',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    DATE_OF_BIRTH: 'Fecha de nacimiento',
    FACTORS: 'Factores',
    PLACE_OF_BIRTH: 'Lugar de nacimiento',
    DAMAGES_PROPOSAL_ASSET_DATA: 'Propuesta de daños- datos del bien asegurado',
    SUBJECTS: 'Sujetos',
    INSURED_SUBJECTS: 'Sujeto asegurado',
    OCCUPATION: 'Profesión',
    FISCAL_CODE: 'Código fiscal',
    BACK: 'Atrás',
    ADD_ASSET: 'Añadir bien asegurado',
    NEXT: 'Continuar',
    CONTINUE: 'Próximo',
    NEXT_START_CARD: 'Continuar',
    EMISSION: 'Emisión',
    POLICY_N: 'Núm. de póliza',
    SAVED_SUCCESSFULLY: 'Se ha guardado correctamente',
    SUCCESSFULLY_FINALIZED: 'Ha finalizado correctamente',
    PRINT_PIN: 'Es necesario imprimir e introducir el PIN para finalizar el contrato',
    PRINT: 'Imprimir',
    PRINT_ENTRY: 'Introducción del PIN',
    EXIT: 'Salir',
    ISSUE_CONFIRMATION: 'Confirmación de emisión',
    ENTER_PIN_TO_CONFIRM_POLICY_ISSUE: 'Introduzca su PIN para confirmar la emisión de la póliza:',
    CONFIRM_PURCHASE: 'Confirmar compra',
    POLICY_PREVIEW: 'Vista previa de la póliza',
    VALIDATE: 'Validar',
    POLICYHOLDER: 'Tomador',
    NODE: 'Nodo',
    NODE_START_CARD: 'Punto de venta',
    eMAIL: 'Correo electrónico',
    PRODUCT: 'Producto',
    BRANCH_OFFICE: 'Sucursal',
    FISCAL_CODE_VAT_NUMBER: 'Código fiscal/número de NIF',
    RESIDENCE_ADDRESS: 'Dirección de residencia',
    CONTACTS: 'Contactos',
    MOBILE_PHONE: 'Teléfono móvil',
    EFFECTIVE_DATE: 'Fecha de vigencia',
    NEXT_INSTALMENT_DATE: 'Fecha del próximo plazo',
    EXPIRE_DATE: 'Fecha de vencimiento',
    TACIT_RENEWAL: 'Renovación tácita',
    TECHNICAL_DATA: 'Datos técnicos',
    PAYMENT_FREQUENCY: 'Frecuencia de pago',
    AGREEMENT: 'Acuerdo',
    PROPOSAL_MANAGEMENT: 'Gestión de propuesta',
    ISSUE_DATE: 'Fecha de emisión',
    POLICY_MANDATORY_DATA: 'Datos de la póliza obligatorios',
    POLICY_NUMBER: 'Número de póliza',
    MEANS_OF_PAYMENT: 'Métodos de pago',
    SELECT_MEANS_OF_PAYMENT: 'Selecciona método de pago',
    COORDINATES_TO_REFUND: 'Coordenadas de la devolución',
    HEADING: 'Encabezamiento',
    ISSUE: 'Emisión',
    '{{header}}': '',
    '{{ parameter?.description }}': '',
    QUESTIONNAIRES: 'Cuestionarios',
    NOT_COMPILED: 'No recopilado',
    COMPILED: 'Recopilado',
    TO_BE_FILLED_IN_BY_THE_SUBJECT: 'A cumplimentar por el sujeto con el rol de contratante',
    CLAUSES: 'Cláusulas',
    ASSET_CLAUSES: 'Cláusulas de bienes asegurados',
    INSURABILITY_REQUIREMENTS: 'Requisitos de asegurabilidad',
    PRE_DISCOUNT_PREMIUM: 'Prima previa al descuento',
    GROSS: 'En bruto',
    PREMIUM_INSTALLMENT: 'Prima previa al descuento',
    ANNUAL_PREMIUM: 'Prima anual',
    PREMIUM_RATE: 'Tipo de prima',
    RECALCULATE: 'Recalcular',
    VISUALIZE: 'Visualizar',
    PREMIUM_DETAILS: 'Detalles de la prima',
    CONTRIBUTIONS_AND_TAXES: 'Cotizaciones e impuestos',
    BOX: 'Casilla',
    LIST: 'Lista',
    COMMISSIONS: 'Comisiones',
    AUTHORITY_LIMIT: 'Límite de autoridad',
    PERC_ACQUIRED_COMMISSIONS: '% de comisiones obtenidas',
    PERC_ACQUIRED_CHARGES: '% de gastos contraídos',
    PERC_COLLECTED_COMMISSIONS_NET: '% Comisión Cobrada (Neta)',
    PERC_COLLECTED_CHARGES: '% de gastos cobrados',
    APPLY: 'Aplicar',
    EDIT_AMOUNT: 'Editar importe',
    COMMISSIONS_AT_INCEPTION_DATE: 'Comisiones a la fecha de inicio',
    ACQUIRED_COMMISSIONS: ' de comisiones obtenidas',
    ACQUIRED_CHARGES: ' de gastos contraídos',
    COLLECTED_COMMISSIONS_NET: ' Comisión Cobrada (Neta)',
    COLLECTED_CHARGES: ' Gastos cobrados',
    TOTAL: 'Total',
    AMOUNT: 'Importe',
    '{{ commission.fees ? \'Fees\' : comisión.descripción }}': '',
    NEXT_COMM_INSTALMENT: 'Próximo plazo de la com.',
    RECALCULATE_AND_SAVE: 'Recalcular y Guardar',
    CLOSE: 'Cerrar',
    INSTALMENT_AT_INCEPTION_DATE: 'Plazo a la fecha de inicio',
    TAXABLE: 'Imponible',
    INSTALMENT_AT_INCEPTION_DATE_GROSS: 'Pazo bruto a la fecha de inicio:',
    INSURANCE_TAX: 'Impuesto sobre el seguro',
    RATE: 'Tarifa',
    TAXES: 'Impuestos',
    ANTIRACKET: '(Incluido la lucha contra la extorsión)',
    NEXT_INSTALMENT: 'Nuevo plazo',
    NEXT_INSTALMENT_GROSS: 'Próximo plazo bruto',
    '{{questionnaireFlat?.descriptionType}}': '',
    QUOTATION_DETAILS: 'Información de cotización',
    '{{ premiumDetail.sectionTitle }}': '',
    NET: 'Neto',
    ACCESSORIES: 'Complementarios',
    INST_INTEREST: 'Interés a plazos',
    'Damages proposal - Quotation': 'Propuesta no vida-cotización',
    '{{assetInstance.instanceDescription}}': '',
    PARAMETERS: 'Parámetros de la póliza',
    PROPOSAL_N: 'Número de propuesta',
    AUTHORIZATION_SEND: 'y se envió una solicitud de autorización',
    SAVING_QUOTE: 'Guardar cotización',
    PROCEDE_TO_CHECKOUT: 'Proceder al pago',
    CHECKOUT: 'Pago',
    CODE: 'Código',
    ACTION: 'Acción',
    NODE_LIST: 'Lista de nodos',
    DRAG_SELECT: 'Arrastrar/seleccionar',
    COMPANY: 'Compañía',
    POLICY_STATE: 'Estado de la póliza',
    INSURANCE_ASSETS: 'Bienes asegurados',
    SAVE_QUOTATION: 'Guardar cotización',
    'Damages proposal - Summary': 'Propuesta de daños-resumen',
    PROPOSAL_DATA: 'Datos de la propuesta',
    OTHER_POLICY_DATA: 'Otros datos de la póliza',
    SYSTEM_MESSAGE: 'MENSAJE DEL SISTEMA',
    AN_ERROR_OCCURRED: 'Se ha producido un error, póngase en contacto con el servicio de atención al cliente',
    ERROR_DURING_SUBJECT_UPDATE: 'Error durante la actualización del sujeto',
    MANDATORY_REGULATION: 'Rellene todos los factores obligatorios para el reglamento de la propuesta',
    QUESTIONNAIRE_NOT_COMPLETE: 'Atención, el cuestionario no está completo. Cumplimente todas las respuestas para continuar',
    THE_CONTRACTOR_CONFIRMS_THE_VALIDITY: 'El contratante confirma la validez de las respuestas facilitadas',
    PACKAGE: 'Conjuntos',
    POLICY_EXPIRE_DATE: 'Fecha de vencimiento de la póliza',
    NORMALIZE: 'Normalizar',
    ASSETS_DATA: 'Datos de bien asegurado',
    REPRINT_POLICY_DOCUMENTS: 'Reimprimir documentos de la póliza- núm. de póliza',
    OUTCOME: 'Resultado',
    AUTHORIZATION_REQUEST: 'Petición de autorización',
    ADDRESS: 'Direcciones',
    MAIN: 'Principal',
    DESCRIPTION: 'Descripción',
    ADD_ADDRESS: 'Añadir dirección',
    POLICYHOLDER_COMMUNICATION_ADDRESSES: 'Dirección de correo del tomador',
    POSTAL_MAIL: 'Correo postal',
    CHANGE_ADDRESS: 'Cambiar dirección',
    EMAIL: 'Correo electrónico',
    CUSTOMER_RESERVED_AREA: 'Área reservada al cliente',
    POLICYHOLDER_EMAIL: 'Correo electrónico del tomador',
    POLICYHOLDER_RESIDENCE_ADDRESS: 'Dirección de residencia del tomador',
    POLICYHOLDER_DELIVERY_ADDRESS: 'Dirección de entrega del tomador',
    OTHER_ADDRESS: 'Otras direcciones',
    SUBSCRIPTION_INSTALMENT_TAXABLE: 'Cuota de suscripción sujeta a impuesto',
    SUBSCRIPTION_INSTALMENT_GROSS: 'Cuota bruta de suscripción',
    NEXT_INSTALMENT_TAXABLE: 'Plazo siguiente sujeto a impuesto',
    ANNUAL_PREMIUM_TAXABLE: 'Prima anual sujeta a impuesto:',
    ANNUAL_PREMIUM_GROSS: 'Prima anual bruta:',
    NEW: 'Nuevo',
    POLICY_CONTACT_ERROR: 'Introduzca al menos un contacto del tomador',
    CLAUSE_DETAIL: 'Información de la cláusula',
    SAVE: 'Guardar',
    PRODUCT_CODE: 'Código de producto',
    PRODUCT_DESCRIPTION: 'Descripción de producto',
    POLICY_EFFECT_DATE: 'Fecha de vigencia de la póliza',
    EMPTY: 'Vacío',
    DEBIT_MEAN_OF_PAYMENT: 'Medios de pago a débito',
    CREDIT_MEAN_OF_PAYMENT: 'Medios de pago a crédito',
    ALTERNATIVE_POLICY_ADRESS: 'Introduzca una dirección de la póliza alternativa',
    YES: 'Sí',
    MANDATORY_TEXT: 'El texto de la cláusula es obligatorio',
    MANDATORY: 'obligatorio',
    SELECT_PAYMENT_METHOD: 'Seleccione un método de pago',
    ERROR_ON_DATE: 'No es posible continuar ya que la fecha de vigencia debe ser anterior a la fecha de vencimiento',
    SELECT_CONTRACTOR: 'Seleccionar un contratante',
    BENEFICIARIES: 'Beneficiarios',
    BENEFICIARY_TYPE: 'Tipo de beneficiario',
    BENEFICIARY: 'Beneficiario',
    PERCENTAGE: 'Porcentaje',
    THIRD_PARTY_SUBJECT: 'Sujeto tercero',
    WARNING_BENEFICIARY_SUBJECT: 'El sujeto seleccionado ya ha sido registrado como beneficiario, seleccione otro sujeto',
    WARNING_BENEFICIARY_THIRD_PERSON: 'El sujeto seleccionado ya ha sido registrado como beneficiario, seleccione otro sujeto',
    WARNING_BENEFICIARY_LIMIT: 'Número máximo de beneficiarios alcanzado',
    WARNING_THIRDPERSON_SUBJECT: 'El sujeto seleccionado ya ha sido registrado como sujeto tercero, seleccione otro sujeto',
    USUAL_OCCASIONAL_RESIDENCE: 'RESIDENCIA HABITUAL/OCASIONAL',
    ANAG_BENEF: 'Registro beneficiarios',
    SPOUSE_INSURED: 'Cónyuge del asegurado',
    ERROR_FORMAT_DATE: 'Error formato fecha',
    DATE1_AFTER_DATE2: 'La fecha de vencimiento es inferior a la fecha de vigencia',
    SELECT_NODE: 'Seleccionar nodo',
    DATA_CANCELLATION_POLICY: 'Fecha de cancelación de la póliza',
    CANCELLATION_REASON: 'Motivo de la cancelación',
    CANCELLATION_PREMIUM_REPAYMENT: 'La cancelación prevé el reembolso de la prima',
    RIGHTS: 'Derechos',
    DATA_CANCELLATION: 'Fecha de cancelación',
    CONFIRMATION_CANCELLATION: 'Confirmación cancelación',
    CANCELLATION_SUMMARY: 'Resumen de cancelación',
    END: 'Fin',
    DOCUMENTS: 'Documentos',
    POLICY: 'Póliza',
    CANCELED_SUCCESSFULLY: 'cancelado correctamente',
    CONFIRM_CANCELLATION: 'Desea confirmar la cancelación de la póliza',
    CURRENCY: 'Moneda',
    POSTED_NOTICE_DATE: 'Fecha de aviso de anulación',
    ISSUED_CORRECTLY: 'se ha emitido correctamente',
    PREMIUM: 'Prima',
    FEES: 'Tasas',
    SELECT_ASSET: 'SELECCIONAR BIEN ASEGURADO',
    ACTIVITY: 'Actividades',
    SECONDARY_ACTIVITY: 'Actividad secundaria',
    ACTIVITY_NOT_FOUND: 'Actividad no encontrada',
    SEARCH_ACTIVITY_BY: 'Buscar actividad por',
    VALUE: 'Valor',
    SEARCH: 'Buscar',
    NO_VALUE: 'No hay valor',
    PROPOSAL_REGULATION: 'Regulación de propuesta',
    REGULATION_EXPIRATION_DATE: 'Fecha de vencimiento de la regulación',
    REGULATION_FRACTIONING: 'Fraccionamiento de la regulación',
    REGULATION_TYPE: 'Tipo de regulación',
    REGULATION_DAYS: 'Días de regulación',
    PERCENTAGE_OF_MIN_QUOTE_PREMIUM: '% de prima de cotización mínima / final',
    LPS: 'LPS',
    EU_COUNTRY: 'País de la UE',
    EXTRA_EU_COUNTRY: 'País fuera de la UE',
    CHANGE_DATE: 'Fecha de cambio',
    EXCHANGE_RATE: 'Tipo de cambio',
    USE_TAX: 'Impuesto de uso',
    CO_INSURANCE: 'Coaeguro',
    CO_INSURANCE_DEP: 'Dep. de coaeguro',
    OUR_PERCENTAGE: 'Nuestro Porcentaje',
    QUOTA_PERCENTAGE: 'Porcentaje de Cuota',
    COMMISSION_PERCENTAGE: 'Porcentaje de Comisión',
    PERCENTAGE_TOTAL: 'Porcentaje Total',
    PERCENTAGE_ERROR: 'Porcentaje inválido',
    NO_QUOTAS_ERROR: 'No se han ingresado cuotas de coaseguro',
    LEAD_COINS_ERROR: 'El porcentaje total del coasegurador líder debe ser 100',
    POLICY_QUOTAS: 'Cuotas de Póliza',
    NEW_QUOTA: 'Nueva cuota de coaseguro',
    THIRD_PARTY_QUOTA: 'Datos de Póliza del Asegurador Líder',
    QUOTA: 'Cuota',
    INTERMEDIARY: 'Intermediario',
    LEADING_INSURER: 'Asegurador Líder',
    RECALCULATE_UPDATE: 'RECALCULAR Y ACTUALIZAR',
    RECALCULATE_DEFAULT_LPS_TAXES: 'RECALCULAR IMPUESTOS LPS POR DEFECTO',
    TAX_ANTI_MONEY_LAUNDERING: 'Impuesto Anti Lavado de Dinero',
    COMMON_RISK_POLICIES: 'Pólizas de Riesgo Común',
    COMPLEMENTARY_POLICIES: 'Pólizas Complementarias',
    COMPLEMENTARY_POLICY_DATA: 'Datos de Póliza Complementaria',
    SECOND_RISK_POLICIES: 'Pólizas de Segundo Riesgo',
    LINKED_POLICIES: 'Pólizas Vinculadas',
    NOTES: 'Notas',
    INDIRECT_CO_INSURANCE_POLICIES_LIST: 'Lista de pólizas de coaseguro indirecto',
    INDIRECT_CO_INSURANCE_POLICIES: 'Pólizas de coaseguro indirecto',
    SECOND_RISK_POLICIES_LIST: 'Lista de Pólizas de Segundo Riesgo',
    ANNUAL_GROSS_PREM_BEFORE_CHARGES: 'Prima anual (excluyendo derechos)',
    FLEX_DISCOUNT_WITH_EROSION: 'Flexibilidad con la erosión de descuentos',
    FLEX_DISCOUNT_WITHOUT_EROSION: 'Flexibilidad sin erosión de descuentos',
    FLEX_DISCOUNT_AMOUNT_WITH_EROSION_ERROR: 'Monto con la erosión de descuentos no válido. ',
    FLEX_DISCOUNT_AMOUNT_WITHOUT_EROSION_ERROR: 'Monto sin erosión de descuentos no válido. ',
    FLEX_DISCOUNT_PERCENT_WITH_EROSION_ERROR: 'Porcentaje con la erosión de descuentos no válida. ',
    FLEX_DISCOUNT_PERCENT_WITHOUT_EROSION_ERROR: 'Porcentaje sin erosión de descuentos no válida. ',
    FLEX_DISCOUNT_RANGE_ALLOWED_VALUE: 'Range de valores permitidos: ',
    FLEX_DISCOUNT_MAX_100: 'Introducir un valor menor que ',
    DISCOUNT: 'descuento',
    RESIDUAL_DISCOUNT: 'Descuento restante',
    SHARED_DISCOUNTS_AMOUNT: 'Compartir descuentos',
    DISCOUNT_TYPE: 'Tipo de descuentos',
    PERIOD: 'Periodo',
    RESIDUAL_AMOUNT: 'Monto restante',
    NO: 'No',
    START: 'Inicio',
    LIMIT: 'Límite',
    ADD_POLICY: 'Agregar póliza',
    INPUT_PREMIUM: 'Prima de entrada',
    DELETE:'Borrar',
    QUOT_COMMISSIONS: {
      LABEL: {
        ACCESSORIES: 'Accesorios',
        AUTHORITY_LIMIT: 'Límite de autoridad',
        ALL_RISK: 'Todo riesgo',
        ACQUIRED_CHARGES: 'Cargos adquiridos',
        ACQUIRED_COMMISSION: 'Comisión adquirida',
        AMOUNT: 'Monto',
        ANNUAL_PREMIUM: 'Prima anual',
        ANTIRACKET: 'Impuesto Anti Lavado de Dinero',
        COLLECTED_CHARGES: 'Cargos cobrados',
        COLLECTED_COMMISSION_NET: 'Comisión cobrada (Neta)',
        COMMISSIONS: 'Comisiones',
        CONTRIBUTIONS_TAXES: 'Contribuciones e impuestos',
        GROSS: 'Bruto',
        INCEPTION_INSTALLMENT: 'Cuota de inicio',
        INSTALLMENT_INTEREST: 'Interés de cuota',
        INSTALLMENT_PREMIUM: 'Prima de cuota',
        INSURANCE_TAX: 'Impuesto de seguro',
        NET: 'Neto',
        PREMIUM_DETAIL: 'Detalle de prima',
        RATE: 'Tasa',
        SIGNED_COMMISSIONS: 'Comisiones a la fecha de inicio',
        DEROGATED: 'Derogado',
        NEXT_INSTALMENT_COMMISSIONS: 'Próxima comisión de cuota',
        RIGHTS: 'Derechos',
        SSN: 'SSN',
        TAXABLE: 'Gravable',
        TAXES: 'Impuestos',
        TOTAL: 'Total',
        VIEW: 'Ver',
        ASSIGN: 'Asignar',
        ACQUIRED_COMMISSIONS_MANDATE: 'Mandato de comisiones adquiridas'
      }
    },
    FPS_LABEL: {
      NEXT_INSTALMENT: 'Próxima cuota',
      INCEPTION_INSTALLMENT: 'Cuota de inicio',
    },
    INDEXATION: 'Indexación',
    QUOTA_ALREADY_EXISTING: 'Cuota ya existente',
    CO_POLICYHOLDER_SUBSCRIBER_ERROR: 'El co-titular debe ser una parte diferente al titular de la póliza',
    CO_POLICYHOLDER_JOINT_SUBSCRIBER_ERROR: 'El co-titular debe ser una parte diferente a los otros co-titulares',
    COMMON_RISK_POLICIES_LIST: 'Lista de Pólizas de Riesgo Común',
    COMMON_POLICY_DATA: 'Datos Comunes de la Póliza',
    LINKED_POLICIES_LIST: 'Lista de Pólizas Vinculadas',
    VALID_POLICY: 'Póliza Válida',
    INVALID_POLICY: 'Póliza Inválida',
    INVALID_POLICY_NUMBER: 'Formato inválido de número de póliza',
    POLICY_ALREADY_ADDED : 'La póliza ya ha sido agregada',
    NO_POLICY_ADDED : 'No se ha agregado ninguna póliza',
    AMOUNT_NOT_VALID: 'Monto no válido',
    GENERIC_SYSTEM_ERROR: 'Error interno del servidor',
    SAVE_AND_CLOSE: 'Guardar y cerrar'
  }
};

import { Pipe, PipeTransform } from '@angular/core';

/**
 * The `FactorFormatterPipe` is designed to format data based on type codes.
 * It currently handles number formatting with specified decimal precision and maximum length.
 * Other types are returned unchanged.
 * 
 * @param value The value to be formatted.
 * @param type The type identifier (e.g., '1' for numbers).
 * @param format The formatting object.
 * @param locale The locale identifier (e.g., 'it-IT').
 * @returns The formatted value or an error message if the value is invalid.
 */
@Pipe({
  name: 'factorFormatter'
})
export class FactorFormatterPipe implements PipeTransform {

  transform(value: any, type: string, format: any, locale = 'it-IT'): any {
      // Handle formatting based on the type argument
      switch (type) {
        case '1': // Type '1' corresponds to 'number'
          return this.formatNumber(value, format, locale);
        case '4': // Type '1' corresponds to 'number'
          return this.formatDate(value, locale);
        default:
          // For all other types, return the value unchanged
          return value;
      }
  }


  /**
   * Formats a given date value according to the specified locale.
   *
   * @param {any} value - The date value to be formatted. Can be a Date object, timestamp, or any value recognized by the Date constructor.
   * @param {string} locale - The locale string that specifies the format to be used. For example, 'en-US' for United States English or 'fr-FR' for French (France).
   * @returns {string} - The formatted date string according to the specified locale.
   */
    private formatDate(value: any, locale: string) {

      // do not attempt to format a string, it will cause an error, 
      if(typeof value === 'string') {
        return value
      }
      const formatter = new Intl.DateTimeFormat(locale);
      return formatter.format(value);
  }

  /**
   * Formats a number based on the provided formatting object.
   * 
   * @param value The number to format.
   * @param format The format object, which specifies the decimal precision, alignment, etc.
   * @param locale The locale for formatting.
   * @returns The formatted number if applicable, otherwise returns 'ERR' for invalid numbers.
   */
  private formatNumber(value: any, format: any, locale: string): string {

    if (!format) {
      console.log(`No format found for value: ${value}, returning the original value`);
      return value;
    }
  

    if (value === null || value === undefined) {
      return 'ERR';
    }

    const { precision, maxLen, alignment, pad } = format;
    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return 'ERR';
    }

    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
      useGrouping: format.grouping
    });

    let formattedValue = formatter.format(numberValue);

    // Handle padding and alignment
    if (maxLen && formattedValue.length < maxLen) {
      const padChar = pad === 0 ? '0' : ' ';
      if (alignment === 1) {
        formattedValue = formattedValue.padStart(maxLen, padChar);
      } else {
        formattedValue = formattedValue.padEnd(maxLen, padChar);
      }
    }

    // Check if the formatted value exceeds the maxLen
    if (maxLen && formattedValue.length > maxLen) {
      return 'ERR';
    }

    return formattedValue;
  }
}

import { ViewContainerRef, Directive} from '@angular/core';

@Directive({
  selector: '[lpcLifePostSalesHost]'
})
export class LifePostSalesHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { GenericEntity } from '../generic-entity';
import { ConfMetadata } from './conf-metadata';
import { DocumentFile } from './document-file';

export class Document {

  public id: string;
  public code: string;
  public description: string;

  public requireSignature: boolean;
  public mandatory: boolean;
  public toBeValidated: boolean;
  public attachedType: GenericEntity;
  public min: number;
  public max: number;
  public type: GenericEntity;
  public state: GenericEntity;
  public added: boolean;
  public confMetadataList: Array<ConfMetadata>;
  public files: Array<DocumentFile>;

  constructor() { }

}

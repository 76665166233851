import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {PartyAsset} from '../../models/domain-models/party-asset';
import {ApiPutPartiesRequest} from '../../models/api-models/api-put-parties-request';
import {REST_API_CONTACT_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePartyResourceService {
  private apiContactConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any) {
    this.apiContactConf = REST_API_CONTACT_CONF;
  }

  get(contractId: string): Observable<Array<PartyAsset>> {
    return this.http.get<Array<PartyAsset>>(this.getContractPartiesConf(contractId)).pipe(
      share()
    );
  }

  put(contractId: string, parties: ApiPutPartiesRequest): Observable<ApiPutPartiesRequest> {
    return this.http.put<ApiPutPartiesRequest>(this.getContractPartiesConf(contractId), parties).pipe(
      share()
    );
  }

  getContractPartiesConf(contractId: string): string {
    return this.apiConf.api.portal.path + this.apiContactConf.path + contractId + this.apiContactConf.partiesPath;
  }


}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { EventNotificator } from '../../../event-notificator';
import { BeneficiaryCathegory, LicCustomProperties, Roles } from './../../../enum/life-issue.enum';
import { MeanOfPayment, MeansOfPayment, PaymentFields, Payments, PaymentType } from './../../../models/meansofpayment.model';
import { ChoiceBeneficiaryData, ExtProperties, InstalmentType } from './../../../models/policy.model';
import { BeneficiaryService } from './../../../services/beneficiary.service';
import { CustomPropertiesService } from './../../../services/custom-properties.service';
import { PolicyService } from './../../../services/policy-service';
import { LifeRoleService } from './../../../services/life-role.service';
import { AnagEditPartyResolver } from '@rgi/anag';
import { Subject } from './../../../models/subject.model';

@Component({
  selector: 'lic-select-subject-ben',
  templateUrl: './select-subject-ben.component.html',
  styleUrls: ['./select-subject-ben.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectSubjectBenComponent implements OnInit, EventNotificator, OnDestroy {

  @Input() key: number;
  @Input() percentualeMin: number;
  @Input() submitted: boolean;
  @Input() cat: any;
  @Output() delete = new EventEmitter<any>();
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() subjetAnagEditedEvent = new EventEmitter<{subject: {roleCode: string}, cat: string, index: number,
  relatedSubj: any, withPercentage: boolean, obj: any}>();
  @Output() relatedSubjectDeletion = new EventEmitter<{cat: string, benef: any, subjId: string}>();
  /**
   * @description
   * input to handle the checkbox visibility
   */
  @Input() _REG41Property = false;
  protected $subscribtions: Subscription[] = [];

  severeDisabilityCheckBox: boolean;

  public get REG41CheckBox() {
    return this._REG41Property && this.cat === BeneficiaryCathegory.VITA;
  }

  public get isBenefCedola() {
    return this.cat === BeneficiaryCathegory.CEDOLA;
  }

  public paymentTypes: MeanOfPayment[] = [];

  public get paymentFieldDefinitions(): PaymentFields[] {
    if (!!this.resultPayments.credMeansOfPayment) {
      const paymentTypeDefinition = this.resultPayments.credMeansOfPayment
      .find(paymTypes => paymTypes.meanOfPayment.identification === this.customForm.get('paymentInfo').get('idPaymentType').value);
      if (!!paymentTypeDefinition) {
        return paymentTypeDefinition.paymentFields;
      }
      return [];
    } else {
      return [];
    }
  }

  public loadingPayments: boolean = null;
  public resultPayments: MeansOfPayment = null;

  validationMessages: string[] = [];
  public select;

  @Input() customForm: UntypedFormGroup;

  constructor(
    public lifeRoleService: LifeRoleService,
    public beneficiaryService: BeneficiaryService,
    protected paymentInfo: CustomPropertiesService,
    protected policyService: PolicyService,
    protected translateService: TranslationWrapperService,
    protected anagEditResolver: AnagEditPartyResolver
  ) {
    this.select = this.translateService.getImmediate('lic_select');
  }

  ngOnInit() {
    this.initializePaymentsCedola();
    this.$subscribtions.push(
      this.customForm.get('value').valueChanges.subscribe(value => {
        if (!!value && this.isBenefCedola && !this.paymentInfo.cachedCedolaPayments) {
          this.loadingPayments = true;
          this.checkIfCedolaAndGetPayments().subscribe();
        } else if (!!value && this.isBenefCedola && !!this.paymentInfo.cachedCedolaPayments) {
          this.initializePayments(this.paymentInfo.cachedCedolaPayments);
        }
      })
    );
    this.initsevereDisability();
  }

  initsevereDisability() {
    let value = false;
    if (this.showBenefSevereDisability()) {
      value = (this.getSevereDisabilityExtProperties().valore === 'true');
    }
    this.severeDisabilityCheckBox = value;

    const control = new UntypedFormControl(value);
    control.valueChanges.subscribe(nv => this.updateSevereDisability(nv));
    this.customForm.addControl('severeDisability', control);
  }

  /**
   * @description
   * in case the benef Coupon is prevalorized it checks if you cached the payments available
   * it takes from the cache otherwise it calls the service.
   */
  private initializePaymentsCedola() {
    if (!this.isEmpty() && this.isBenefCedola && !!this.paymentInfo.cachedCedolaPayments) {
      this.initializePayments(this.paymentInfo.cachedCedolaPayments);
    } else if (!this.isEmpty() && this.isBenefCedola && !this.paymentInfo.cachedCedolaPayments) {
      this.loadingPayments = true;
      this.$subscribtions.push(
        this.checkIfCedolaAndGetPayments().subscribe()
      );
    }
  }

  private initializePayments(res: MeansOfPayment) {
    this.resultPayments = res;
    if (!!this.resultPayments.credMeansOfPayment.length) {
      this.paymentTypes = this.resultPayments.credMeansOfPayment.map(paym => paym.meanOfPayment);
    }
    this.customForm.get('percentage').setValue('100');
    this.customForm.get('percentage').disable();
  }

  isEmpty() {
    return this.customForm.get('value').value == null;
  }

  paymentTypeSelected(value: {code: string, description: string, identification: string}) {
    const installmentType = new InstalmentType('', '');
    const paymentType = {codice: value.identification, descrizione: value.description} as PaymentType;
    const payment: Payments = new Payments(installmentType, paymentType);
    this.beneficiaryService.setPaymentCedola(this.getThisSubjId(), payment);
  }

  deleteReletedSubject(event) {
    this.relatedSubjectDeletion.emit(event);
  }

  resetMethod() {
    const event = {
      key: this.key,
      cat: this.cat,
      subject: this.customForm.get('value').value
    };
    this.delete.emit(event);
    this.beneficiaryService.deleteBenef(this.cat, this.getThisSubjId());
  }

  getThisSubjId(): string {
    return this.customForm.get('value').value.objectId.toString();
  }

  fill() {
   /* if (this.customForm.get('value').value.name != null) {
      return this.customForm.get('value').value.surname + ' ' + this.customForm.get('value').value.name;
    } else if (this.customForm.get('value').value.denomination != null) {
      return this.customForm.get('value').value.denomination;
    } else {
      return this.customForm.get('value').value;
    }*/
    const subject = this.lifeRoleService.getStoredSubject(this.getThisSubjId());
    return subject.nominative || subject.denomination;
  }

  public openAnag(sub?: { role: string, withPercentage: boolean }) {
    const event = {
      subject: {
        eventName: 'requireSubject',
        sessionParent: 'anagFinder',
        sessionRoute: 'home',
        sessionTitle: 'Seleziona soggetto',
        navigationDisabled: true,
        roleCode: !!sub && !!sub.role ? sub.role : Roles.BENEFICIARY
      },
      cat: this.cat,
      index : this.key,
      relatedSubj: !!sub ? sub.role : null,
      withPercentage: !!sub ? sub.withPercentage : null
    };
    this.eventPropagation.emit(event);
  }

  editSubject() {
    const subjId = this.getThisSubjId();
    this.openSubjectModifyModal(this.lifeRoleService.storedSubjectMap.get(subjId), Roles.BENEFICIARY);
   }

   editReletedSubject(obj: { role: string, withPercentage: boolean, subject: Subject, openedEdit: boolean }) {
      const event = {
        subject: {
          roleCode: obj.role
        },
        cat: this.cat,
        index : this.key,
        relatedSubj: obj.role,
        withPercentage: obj.withPercentage,
        obj
      };
      this.subjetAnagEditedEvent.emit(event);
   }

   openSubjectModifyModal(subject: Subject, role: string) {
    return this.anagEditResolver.editParty(
      subject as any,
      this.lifeRoleService.getAnagFlowData(role, subject.subjectType.codice)
    ).subscribe(editedParty => {
      this.lifeRoleService.storeSubject(editedParty);
      return this.openedAnagModifySession({ openedEdit: true, subject: editedParty, role });
    });
  }

  openedAnagModifySession(obj: { openedEdit: boolean, subject: Subject, role: string}) {
    const event = {
      subject: {
        roleCode: obj.role
      },
      cat: this.cat,
      index : this.key,
      relatedSubj: null,
      withPercentage: null,
      obj
    };

    this.subjetAnagEditedEvent.emit(event);
  }

  classStyle() {
    return this.customForm.get('percentage').invalid && this.customForm.get('percentage').dirty && this.submitted;
  }

  getListOfRelatedRoles() {
    return this.beneficiaryService.getRelatedRolesList(this.cat, this.customForm.get('value').value.objectId.toString());
  }


  ngOnDestroy(): void {
    this.$subscribtions.forEach(sub => sub.unsubscribe());
  }

  public checkIfCedolaAndGetPayments(): Observable<MeansOfPayment> {
    return this.paymentInfo.get_meansOfPayment(this.getPaymentRequest()).pipe(
      tap(res => this.paymentInfo.cachedCedolaPayments = res.meansOfPayment),
      switchMap(res => of(res.meansOfPayment)),
      take(2),
      catchError(err => {
        this.loadingPayments = false;
        return throwError(err);
      }),
      tap(res => {
        this.initializePayments(res);
        this.loadingPayments = false;
      })
    );
  }

  private getPaymentRequest() {
    return {
      paymentConfigInput: {
        product: null,
        function: 'LIFE_CONTR',
        subfunction: 'COUPON',
        paymentType: null,
        nodeId: this.policyService.idPvManagement
      }
    };
  }

  showBenefSevereDisability(): boolean {
    return  this.getSevereDisabilityExtProperties() != null;
  }

  getSevereDisabilityExtProperties(): ExtProperties {
    if (this.policyService.mainProposal.proposal.beneficiaryData != null) {
      if (this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData != null) {
        let benefData: ChoiceBeneficiaryData;
        if (!!this.customForm.get('value').value) {
          benefData = this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData
            .find( cbenef => cbenef.typeBeneficiary.codice === this.cat
              && cbenef.beneficiaryLifeParty.party.objectID === this.getThisSubjId());
          if (benefData != null) {
            if (benefData.extProperties != null && benefData.beneficiaryLifeParty && benefData.beneficiaryLifeParty.physicalLegal === '1') {
              const severeDisabilityProp = benefData.extProperties.find(prop => prop.chiave === LicCustomProperties.SEVERE_DISABILITY);
              return  severeDisabilityProp;
            }
          }
        }
      }
    }

    return null;
  }

  updateSevereDisability(value: boolean) {
    if (this.showBenefSevereDisability()) {
      this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData
      .find( cbenef => cbenef.typeBeneficiary.codice === this.cat && cbenef.beneficiaryLifeParty.party.objectID === this.getThisSubjId())
      .extProperties.find(prop => prop.chiave === LicCustomProperties.SEVERE_DISABILITY).valore = value;
    }
  }

}

import { ActiveRoute } from '@rgi/rx/router';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import { of } from 'rxjs';
import { GroupPolicyVcontRouteData } from '../../group-policy-models/group-policy-vcont-date';
import { GroupPolicyStateVcontConfirms } from '../group-policy-state';
import { GroupPolicyStatelessOpConfirmsService } from './group-policy-stateless-op-confirms.service';


/**
 * @author: dmasone
 * @description: State Manager used for render vcont confirms step
 */
export class GroupPolicyStateManagerConfirms extends AbstractStateManager<GroupPolicyStateVcontConfirms> {

    constructor(activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: GroupPolicyStatelessOpConfirmsService) {
        super(activeRoute, stateStoreService);
        const statePolicyData = this.stateStoreService.has(this.activeRoute.key) ?
            this.stateStoreService.get<GroupPolicyStateVcontConfirms>(this.activeRoute.key) :
            new GroupPolicyStateVcontConfirms(this.activeRoute.key);
        const initial$ = this.statelesOps.initConfirms$(of(statePolicyData), this.activeRoute);
        this.updateState$(initial$);
    }

    public actionBack(previousStepData: GroupPolicyVcontRouteData, activeRoute: ActiveRoute, targetRoute: string) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionBack$(of(st), previousStepData, activeRoute, targetRoute));
    }

    public actionClose(previousStepData: GroupPolicyVcontRouteData) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.actionClose$(of(st), previousStepData, this.activeRoute));
    }

}

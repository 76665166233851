/**
 * IIAB CH REST API
 * Rest API's for Workflow services
 *
 * OpenAPI spec version: 0.0.1
 * Contact: info@rgigroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TypeWallet = 'Life' | 'Damage';

export const TypeWallet = {
    Life: 'Life' as TypeWallet,
    Damage: 'Damage' as TypeWallet
};
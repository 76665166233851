import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ConfirmComponent } from './confirm.component';
import { PanelModule } from '../panel/panel.module';
import { ConfirmService } from './confirm.service';
import {RgiRxI18nModule} from "@rgi/rx/i18n";

@NgModule({
    declarations: [
        ConfirmComponent
    ],
    imports: [
        CommonModule,
        PanelModule,
        RgiRxI18nModule
    ],
    exports: [ConfirmComponent],
    providers: [ConfirmService]
})
export class ConfirmModule { }

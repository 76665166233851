import { State } from '@rgi/rx/state';
import { EvaluatorProductI } from '../../evaluation/models/evaluator-products';
import { SurveyIntegrationFilter } from '../../integration/integration.module';
import { QuestionnaireI } from '../../models/questionnaire';
import { SurveyVersionI } from '../../models/survey-version';
import { NavigationBtnCfg } from './navigation';
import { SurveyAcl } from './survey-acl';


export const BTN_MAP_EDIT = {
    'BACK': { styleClass: 'ppevo-btn-back', label: 'NG_PASSPROPRO_CORE.back', visible: false },
    'SAVE': { styleClass: 'ppevo-btn-highlighted', label: 'NG_PASSPROPRO_CORE.save', visible: false },
    'NEW_VERSION': { styleClass: 'ppevo-btn-highlighted', label: 'NG_PASSPROPRO_CORE.newversion', visible: false },
    'EVALUATE': { styleClass: '', label: 'NG_PASSPROPRO_SURVEY.evaluate', visible: false },
    'EDIT': { styleClass: '', label: 'NG_PASSPROPRO_CORE.edit', visible: false },
    'SHOW_RESULTS': { styleClass: '', label: 'NG_PASSPROPRO_SURVEY.showresults', visible: false }
};

export class SurveyEditState extends State {
    /**
     * An object that allow to set properties for the integration when used outside the simulation environments
     */
    integrationFilter?: SurveyIntegrationFilter;
    /**
     * The survey version to load. If not set the state of the component is new.
     */
    surveyVersion?: SurveyVersionI;
    /**
     * The list of available questionnaires
     */
    questionnaires?: Array<QuestionnaireI> = [];
    /**
     * Whether the state should be considered edit an existing survey
     */
    isEdit?: boolean = false;
    /**
     * Whether the state should be considered implicit versioning of the survey
     */
    isNewVersion?: boolean = false;

    saveMode?: 'create' | 'update' | 'readonly' = 'create';

    activeRouteId?: string;

    routeOptions?: any;

    evaluatorProducts?: EvaluatorProductI[] | null;

    showBackButton?: boolean = false;

    navigation: NavigationBtnCfg = {
        stickable: true,
        map: BTN_MAP_EDIT
    }

    navigateFrom?: 'survey-evaluation' | any;

    forwardData?: any

    initialized: boolean = false;

    acl: SurveyAcl = {
        read: false,
        write: false,
        newVersion: false,
        evaluate: false
    }
}

import {Observable} from 'rxjs';
import {InjectionToken, TemplateRef, Type} from '@angular/core';
import {RgiRxSemanticStatus} from '../rgi-rx-api';

export interface LoadingIndicator {
  hasPending$(): Observable<boolean>;
}

export abstract class RgiRxLoadingIndicator implements LoadingIndicator {
  abstract hasPending$(): Observable<boolean>;
}

export interface RgiRxLoadingIndicatorConfig {
  timeout?: number;
}

export const RGI_RX_LOADING_INDICATOR_CONFIG = new InjectionToken<RgiRxLoadingIndicatorConfig>('RGI_RX_LOADING_INDICATOR_CONFIG')


export type RgiRxPushMessageContent = string | TemplateRef<any> | Type<any>;


/**
 * A single instance of a push notification
 */
export class RgiRxPushMessage {
  /**
   * the identifier of the push
   */
  id?: string;
  /**
   * Content to display. Could be either a string, a TemplateRef or a Component type (dynamic)
   */
  content: RgiRxPushMessageContent;

  /**
   * A detail content to display. Could be either a string, a TemplateRef or a Component type (dynamic)
   */
  detail?: RgiRxPushMessageContent;

  /**
   * Status of the notification, either warning, alert, info, default or any other string.
   * This status is used to determine the severity of the message and receivers can apply different logics from it.
   * For instance a UI element can change it's class or and error handler can filter the messages by status.
   */

  /**
   * Any data that should be provided as context for ng-templates or components types
   */
  context?: any;
  status?: RgiRxSemanticStatus | string;
  /**
   * Delay the push notification in milliseconds
   */
  delay?: number;
  /**
   * A tag used to specify a domain or content in which the push has been submitted or has to be listened.
   * Observers may act differently based on this condition
   */
  tag?: string;
  /**
   * Whether the push can be dismissed by user or software interaction
   */
  dismissible?: boolean;

  /**
   * Additional options for data display
   */
  options?: {
    icon?: string
    expanded?: boolean;
  };


  constructor(content: RgiRxPushMessageContent = '', opts: {
    detail?: RgiRxPushMessageContent,
    status?: RgiRxSemanticStatus | string,
    delay?: number|undefined,
    tag?: string,
    dismissible?: boolean|undefined,
    options?: {
      icon?: string,
      expanded?: boolean
    };
    context?: any
  } = {
    status: 'default',
    options: {
      expanded: false
    }
  }) {
    this.content = content;
    this.context = opts.context;
    this.id = new Date().getTime().toString();
    this.tag = opts.tag;
    this.delay = opts.delay;
    this.dismissible = opts.dismissible;
    this.status = opts.status;
    this.options = opts.options;
    this.detail = opts.detail;
  }
}

/**
 * @deprecated use RgiRxPushMessage
 */
export class PushMessage extends RgiRxPushMessage {}

/**
 * Options for the push notification.
 * @see PushMessage
 */
export interface RgiRxPushOptions {
  delay?: number;
  dismissible?: boolean;
  tag?: string;
  icon?: string;
  expanded?: boolean;
}

export interface RgiRxPushOptionsRef extends RgiRxPushOptions {
  context?: any;
  detail?: string | TemplateRef<any> | Type<any>;
}

/**
 * An injection token to provide defaults for PushMessage options
 * @see RgiRxPushOptions
 */
export const RGI_RX_PUSH_OPTS = new InjectionToken<RgiRxPushOptions>('RGI_RX_PUSH_OPTS');

export const RGI_RX_DEFAULT_PUSH_OPTS: RgiRxPushOptions = {tag: undefined, delay: 5000, dismissible: false};




import {GenericEntity} from './generic-entity';

export class ClaimDetail {

  constructor(
    public id: number,
    public malus: string,
    public state: GenericEntity,
    public responsabilityType: GenericEntity,
    public responsabilityPortion: number,
    public totPayedAmount: number,
    public year: number,
    public description: string,
    public damageType: GenericEntity,
    public claimNumber: string
  ) {
  }
}

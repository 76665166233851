import {ExistingProvider, forwardRef, InjectionToken, TemplateRef, Type, ViewContainerRef} from '@angular/core';

export interface DecoratorRef {
  templateRef: TemplateRef<any>;
  context: any;
}

export interface DecoratorProvider {
  (ref: DecoratorRef, viewContainer: ViewContainerRef);
}

export interface Decorator {
  selector: string;
  decorator: Type<any> | DecoratorProvider;
}

export const RGI_RX_DECORATOR = new InjectionToken<Decorator>('RGI_RX_DECORATOR');
export const RGI_RX_DECORATOR_REF = new InjectionToken<DecoratorRef>('RGI_RX_DECORATOR_REF');


export function provideDecoratorHost(component: Type<RgiRxDecoratorHost>): ExistingProvider {
  return {
    provide: RgiRxDecoratorHost,
    useExisting: forwardRef(() => component)
  }
}

export abstract class RgiRxDecoratorHost {
}

import {Inject, Injectable} from '@angular/core';
import {
  GP_INQUIRY_COMPONENTS,
  GpInquiryComponentInterface
} from '../group-policy-resources/gp-inquiry-component-interface';
import {flatten} from '@rgi/rx';

@Injectable({
  providedIn: 'root'
})
export class GPInquiryService {

  componentsMap = new Map<string, GpInquiryComponentInterface>();

  constructor(
    @Inject(GP_INQUIRY_COMPONENTS) components: GpInquiryComponentInterface[][]
  ) {
    const flattenComponents = flatten<GpInquiryComponentInterface[]>(components);
    flattenComponents.forEach(component => this.componentsMap.set(component.route, component));
  }

  getComponents(): GpInquiryComponentInterface[] {
    return Array.from(this.componentsMap.values());
  }
}

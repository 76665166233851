import {Component} from '@angular/core';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import { Mode } from '../../../group-policy-models/group-policy-mode';
import { GroupPolicyInquiryComponent } from '../../group-policy-inquiry.component';

@Component({
  selector: 'rgi-gp-applications-list-inquiry',
  templateUrl: 'group-policy-applications-list-inquiry.component.html'
})
export class GroupPolicyApplicationsListInquiryComponent extends GpInquiryComponentDetail {
  currentMode: Mode;
  idParentSession: string;
  constructor(
    protected parent: GroupPolicyInquiryComponent,
    public stateMngr: GroupPolicyStateManagerInquiry
    ) {
    super(stateMngr);
    this.currentMode = this.stateMngr.getMode(this.stateMngr.getCurrentState().inquiryMode);
    this.idParentSession = this.parent.id;
  }

  get isApplicationsListVisible(): boolean {
    return this.contractDetails.assetsDetail &&
      this.contractDetails.assetsDetail[0] &&
      this.contractDetails.assetsDetail[0].structure.code !== '2';
  }
}

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Contributions and Taxes</span>
        </h3>
      </div>
      <div [formGroup]="lpsAmountForm" class="modal-body">

        <div *ngFor="let type of instalmentTypes">
        <div class="contributions-taxes-summary hidden-xs">
          <div class="summary-net">
            <span>{{type | translate}} </span>
            <span translate>Taxable:</span>
            <span class="amount">
              {{ getInstalmentPremiumByType(type).taxable | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span>
            <span></span>
          </div>
          <div class="summary-gross summary-net">
            <span translate>{{type}} Gross:</span>
            <span class="amount">
              {{ getInstalmentPremiumByType(type).gross | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span><span></span>
          </div>
        </div>

        <div class="tbld tbld_contributions_taxes hidden-xs">
          <div class="tbld_row tbld_row_contributions_taxes_header">
            <div class="tbld_col tbld_col_gross"></div>
            <div class="tbld_col tbld_col_text tbld_col_taxes">
              <span translate>Taxable</span></div>
            <div *ngIf="getTaxComponentsKeys().length == 0" class="tbld_col tbld_col_text tbld_col_taxes"><span translate>SSN</span></div>
            <div class="tbld_col tbld_col_group tbld_col_insurance tbld_col_text"><span translate>Insurance
                                Tax</span></div>
            <div *ngFor="let key of getTaxComponentsKeys()" class="tbld_col tbld_col_group tbld_col_insurance tbld_col_text"><span>{{taxComponents[key]}}</span></div>
            <div class="tbld_col tbld_col_gross tbld_col_text"><span translate>Gross</span></div>
          </div>
          <div class="tbld_row tbld_row_contributions_taxes">
            <div class="tbld_col"></div>
            <div class="tbld_col"></div>
            <div *ngIf="getTaxComponentsKeys().length == 0" class="tbld_col"></div>
            <div class="tbld_col tbld_col_group">
              <div class="tbld_col tbld_col_single tbld_col_text"><span translate>Rate</span></div>
              <div class="tbld_col tbld_col_single tbld_col_single_center tbld_col_text"><span
                translate>Taxes</span></div>
              <div class="tbld_col tbld_col_antiracket tbld_col_text"><span translate>Tax Anti Money
                                    Laundering</span></div>
            </div>
            <div *ngFor="let key of getTaxComponentsKeys()" class="tbld_col tbld_col_group">
              <div class="tbld_col tbld_col_single tbld_col_text"><span translate>Basis</span></div>
              <div class="tbld_col tbld_col_single tbld_col_single_center tbld_col_text"><span translate>Tax %</span></div>
              <div class="tbld_col tbld_col_antiracket tbld_col_text"><span translate>Tax Amount</span></div>
            </div>
            <div class="tbld_col"></div>
          </div>
          <div *ngFor="let warranty of getWarrantiesPremiumByType(type)"
               class="tbld_row tbld_row_contributions_taxes">
            <div class="tbld_col tbld_col_text">{{ warranty.title }}</div>
            <div class="tbld_col">{{ warranty.taxableAmount | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
            <div *ngIf="getTaxComponentsKeys().length == 0" class="tbld_col">{{ warranty.ssn | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</div>
            <div class="tbld_col tbld_col_group">
              <div class="tbld_col tbld_col_single">
                <span>{{ warranty.taxRate | number : '1.2-2' :'it'}}&nbsp;%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ warranty.taxes | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span></div>
              <div class="tbld_col tbld_col_single tbld_col_gross">
                <span>{{ warranty.antiMoneyLaunderingTaxes | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
            </div>
            <div *ngFor="let taxKey of getTaxComponentsKeys()" class="tbld_col tbld_col_group">
              <div *ngIf="isTaxComponentPresent(taxKey, warranty.taxComponents) else emptyTaxComponent">
                <div class="tbld_col tbld_col_single">
                  <span>{{ getWarrantyTaxComponent(taxKey, warranty.taxComponents).basePremium | currency:'EUR':'symbol':'1.2-2' :'it'}}</span>
                </div>
                <div class="tbld_col tbld_col_single tbld_col_single_center">
                  <span>{{ getWarrantyTaxComponent(taxKey, warranty.taxComponents).taxRate | number : '1.2-10' :'it'}}&nbsp;%</span>
                </div>
                <div class="tbld_col tbld_col_single tbld_col_gross">
                  <span>{{ getWarrantyTaxComponent(taxKey, warranty.taxComponents).amount | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
                </div>
              </div>
            </div>
            <div class="tbld_col">{{ warranty.grossAmount | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
          </div>

          <ng-template #emptyTaxComponent>
            <div class="tbld_col tbld_col_single"><span></span></div>
            <div class="tbld_col tbld_col_single tbld_col_single_center"><span></span></div>
            <div class="tbld_col tbld_col_single tbld_col_gross"><span></span></div>
          </ng-template>

          <div *ngIf="isPolicyInLPS" class="tbld_row tbld_row_contributions_taxes">
            <div class="tbld_col tbld_col_text">LPS</div>
            <div class="tbld_col"></div>
            <div class="tbld_col"></div>
            <div class="tbld_col tbld_col_group">
              <div class="tbld_col tbld_col_single">
                <span></span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <input (blur)="calculateTotalTaxes()" [formControlName]="getLpsAmountFormName(type)" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty" [attr.data-qa]="getLpsAmountDataQa(type)" max="100"
                       min="0"
                       step=".01"
                       type="number">
              </div>
              <div class="tbld_col tbld_col_single tbld_col_gross">
                <span></span>
              </div>
            </div>
            <div class="tbld_col"></div>
          </div>

          <div class="tbld_row tbld_row_contributions_taxes_total">
            <div class="tbld_col tbld_col_text" translate>Total</div>
            <div class="tbld_col">
              {{ getTotalTaxesByType(type)?.totalTaxable | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
            <div *ngIf="getTaxComponentsKeys().length == 0" class="tbld_col">
              {{ getTotalTaxesByType(type)?.totalSSN | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
            <div class="tbld_col tbld_col_group">
              <div class="tbld_col tbld_col_single">
                <span>&nbsp;</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ getTotalTaxesByType(type)?.totalTaxes | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_gross">
                {{ getTotalTaxesByType(type)?.totalAntiLaunderingMoneyTaxes | currency:'EUR':'symbol':'1.2-2':'it'}}
              </div>
            </div>
            <div *ngFor="let taxKey of getTaxComponentsKeys()" class="tbld_col tbld_col_group">
              <div class="tbld_col tbld_col_single"><span></span></div>
              <div class="tbld_col tbld_col_single tbld_col_single_center"><span></span></div>
              <div class="tbld_col tbld_col_single tbld_col_gross">
                {{ getTotalTaxesByType(type)?.totalTaxComponentsAmount[taxKey] | currency:'EUR':'symbol':'1.2-2':'it'}}
              </div>
            </div>
            <div class="tbld_col">
              {{ getTotalTaxesByType(type)?.totalGrossAmounts | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
          </div>
        </div>

        <br class="hidden-xs" clear="all">
        </div>

      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-contributions-taxes-modal-close" translate>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LicStepperComponent} from '../lic-stepper/lic-stepper.component';

@Component({
  selector: 'lic-step',
  templateUrl: './lic-step.component.html',
  styleUrls: ['./lic-step.component.scss']
})
export class LicStepComponent implements OnChanges {

  @Input() public label: string;
  @Input() public disabled = false;
  @Input() public open = false;
  @Input() public disableModify = false;

  stepper: LicStepperComponent;
  index: number;

  private $active = false;

  get active(): boolean {
    return this.$active;
  }

  constructor(private elementRef: ElementRef) {
  }

  activate() {
    this.$active = true;
    this.elementRef.nativeElement.classList.add('active');
  }

  deactivate() {
    this.$active = false;
    this.elementRef.nativeElement.classList.remove('active');
  }

  onHeaderClick() {
    if (!this.disabled) {
      this.stepper.slideTo(this.index);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open && changes.open.currentValue) {
      this.elementRef.nativeElement.classList.add('open');
    } else {
      this.elementRef.nativeElement.classList.remove('open');
    }
  }

}

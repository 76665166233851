import { Component, Inject, Input, OnInit } from '@angular/core';
import { LegalFeeEntity } from '../domain/legal-fee-entity';
import { ExposureEntity } from '../domain/exposure-entity';

@Component({
  selector: 'claims-fee-data-view-modal',
  templateUrl: './fee-data-view-modal.component.html',
  styleUrls: ['./fee-data-view-modal.component.scss']
})
export class FeeDataViewModalComponent implements OnInit {

  @Input() description: string;
  @Input() feeEntity: LegalFeeEntity;
  public isContextSpain: boolean = false;

  constructor(
    @Inject('authService') private authService
  ) { 
    let localContext = this.authService.getDefaultContext();
    this.isContextSpain = localContext === 'ES';
  }

  ngOnInit() {
  }

  protected isLegalSpain() {
    return this.feeEntity && this.feeEntity.country === 'ES';
  }
}

import {EventEmitter, InjectionToken, Type} from '@angular/core';
import {AnswerContainerI} from "./models";


export interface NgPassProAnswer {
  answer: AnswerContainerI;
  isValid: boolean;
  disabled: boolean;
  change: EventEmitter<unknown>;
  isRequired: boolean;
}

/**
 * A function that should return true when the answer data matches a criteria
 */
export type DynamicAnswerWhenPredicate =  (answer:AnswerContainerI) => boolean;

/**
 * Provider type for NG_PASSPRO_DYNAMIC_ANSWER
 */
export type NgPassproDynamicAnswer = {
  /**
   * Should supply a predicate function that returns if the condition satisfy the requirement of the provider
   */
  when : DynamicAnswerWhenPredicate
  /**
   * A component that implements NgPassProAnswer
   */
  component: Type<NgPassProAnswer>
}

export const NG_PASSPRO_DYNAMIC_ANSWER = new InjectionToken<NgPassproDynamicAnswer>("NG_PASSPRO_DYNAMIC_ANSWER");

import {Property} from '../assets-models/pc-section';
import {AdditionalLabel} from '../proposal-models/additional-label';

export class Factor {
  additionalLabels?: AdditionalLabel[];
  code?: string;
  description?: string;
  help?: string;
  type?: string;
  format?: string;
  modifiable?: boolean;
  mandatory?: boolean;
  tag?: string;
  sticky?: string;
  properties?: Property[];
  value?: string;
  values?: any[];
  visible?: boolean;

  public isNullAndMandatory() {
    return (this.value === '-1' || this.value === null) && this.mandatory;
  }
}

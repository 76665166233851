import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import {Beneficiary} from '../model/beneficiary';

@Component({
  selector: 'lpc-beneficiary-text-control',
  templateUrl: './lpc-beneficiary-text-control.component.html',
  styleUrls: ['./lpc-beneficiary-text-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcBeneficiaryTextControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcBeneficiaryTextControlComponent),
      multi: true
    }
  ],
})
export class LpcBeneficiaryTextControlComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() public value: Beneficiary;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    value: new UntypedFormGroup({
      text: new UntypedFormControl(EMPTY_STR, Validators.required)
    }),
    type: new UntypedFormControl(),
    code: new UntypedFormControl(),
    idAssicurato: new UntypedFormControl(),
    irrevocable: new UntypedFormControl(),
    expiryCommunication: new UntypedFormControl(null),
    creditPayment: new UntypedFormControl(null),
    idBenefLock: new UntypedFormControl(null),
    positionNumberCode: new UntypedFormControl(null)
  });

  constructor() { }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((value) => {
      this.writeValue(value);
      this.onChange(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const value: Beneficiary = changes.value.currentValue;
      this.formGroup.setValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.formGroup.patchValue(obj, {emitEvent: false});
  }

  onChange(obj: Beneficiary) {
  }

  validate(control: AbstractControl): { [key: string]: boolean } | null {
    return this.formGroup.get('value').get('text').errors;
  }
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  CommissionDetail,
  PNC_POSTSALES_COMMISSION_TYPE
} from '../../../../../models/domain-models/parameters/commission-detail';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 're-issue-commission-input',
  templateUrl: './re-issue-commission-input.component.html',
  styleUrls: ['./re-issue-commission-input.component.css']
})
export class ReIssueCommissionInputComponent implements OnInit {
  @Input() commission: CommissionDetail;
  @Input() formGroup: UntypedFormGroup;
  @Input() field: string;
  inputValue: number;
  commRisk: boolean;
  controlName: string;

  PNC_POSTSALES_COMMISSION_TYPE = PNC_POSTSALES_COMMISSION_TYPE;

  constructor() {
  }

  ngOnInit() {
    this.commRisk = this.isCommRisk();
    this.inputValue = Number(this.commission.subscriptionInstalment[this.field]);
    this.controlName = this.getControlName();
  }

  isCommRisk() {
    return !!this.commission.commissionType && this.commission.commissionType === PNC_POSTSALES_COMMISSION_TYPE.RISK;
  }

  getControlName() {
    return !!this.commission.risk ? this.field + '_' + this.commission.risk.id : this.field;
  }


  setTwoNumberDecimal($event) {
    $event.target.value = parseFloat($event.target.value).toFixed(2);
    this.inputValue = $event.target.value;
  }

}

import {Component, ComponentRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PassProductsService} from '../pass-products.service';
import {RoutableComponent} from '../routable-component';
import {EventNotificator} from '../event-notificator';
import {ApiProduct} from '../models/api-models/api-product';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {ValidateGenericEntity} from '../start-card/generic-entity.validator';
import {LicensePlate} from '../models/domain-models/license-plate';
import {CommonService} from '../common.service';
import {ApiAssetType} from '../models/api-models/api-asset-type';
import {StateService} from '../state.service';
import {State} from '../models/state';
import {ProposalService} from '../proposal.service';
import {Modal} from '../modal';
import {Subscription} from 'rxjs';
import {Message} from '../models/message';
import {TranslateService} from '@ngx-translate/core';
import {hasAssetNotFoundErrors, hasTechnicalDataErrors} from '../start-card/plates-utils';
import {Action} from '../models/action';
import {SubstitutionService} from '../substitution/substitution.service';
import {QuotationService} from '../quotation/quotation.service';
import {OperatorService} from '@rgi/portal-ng-core';
import {ApiContract} from '../models/api-models/api-contract';
import {ErrorMessagesService} from '../error-messages.service';

@Component({
  selector: 'mic-pa-modal',
  templateUrl: './product-asset-modal-component.html',
  styleUrls: ['product-asset-modal-component.scss']
})
export class ProductAssetModalComponent implements Modal, OnInit, OnDestroy, RoutableComponent, EventNotificator {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  otherPlates = false;
  flowWithoutPlate = false;
  wrongDataFromAnia = false;
  dataInAniaNotFound = false;


  specPlateTypes: GenericEntity[] = [];
  specPlatePrefixes: string[] = [];
  specPrefixesFormat: GenericEntity[] = [];
  validFormat: GenericEntity;
  countriesList: GenericEntity[] = [];
  products: GenericEntity[] = [];
  assetTypes: ApiAssetType[] = [];
  productAssetForm: UntypedFormGroup;
  componentRef: ComponentRef<any>;
  validationMessages: Message[] = [];
  isPlateKnown = false;
  submitted = false;
  productToAssetTypesMap: Map<string, Array<ApiAssetType>>;
  plateTypes: Array<GenericEntity> = new Array<GenericEntity>();
  isPlateTypeValid: boolean;
  plateTypeValidityChecked = false;
  vehicleTypes: Array<GenericEntity> = new Array<GenericEntity>();
  vehicleProperties;
  onCancel: any;
  bRecalculateQuotation = false;
  needToSetAssetType = false;


  areaCode = 'PRODUCT_ASSET_MODAL';
  areaCodeForeingPlate = 'PRODUCT_ASSET_MODAL_FOREING_PLATE';

  protected subscriptions: Subscription = new Subscription();
  isTestPlate = false;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected passProductsService: PassProductsService,
    protected commonService: CommonService,
    protected stateService: StateService,
    protected translate: TranslateService,
    protected operatorService: OperatorService,
    protected proposalService: ProposalService,
    protected substitutionService: SubstitutionService,
    protected quotationService: QuotationService,
    protected errorMessagesService: ErrorMessagesService
    ) {
  }

  get isSubstitution() {
    return this.proposalService.isSubstitution;
  }

  protected getForm() {
    return this.productAssetForm;
  }

  protected buildForm() {
    this.productAssetForm = this.formBuilder.group(
      {
        plateType: ['', (this.otherPlates || this.wrongDataFromAnia || this.dataInAniaNotFound ? [Validators.required] : null)],
        specPlateType: [''],
        specPlatePrefix: [''],
        countries: [''],
        licensePlate: [''],
        product: ['', Validators.required],
        assetType: ['', Validators.required],
        vehicleType: ['']
      }
    );
  }

  ngOnInit() {
    this.setOtherPlates();
    this.setFlowWithoutPlate();
    this.setWrongDataFromAnia();
    this.setDataInAniaNotFound();


    this.plateTypeValidityChecked = false;

    this.buildForm();
    const productAssetFormValueChangesSubscription = this.getForm().valueChanges.subscribe((val) => {
      this.validateForm();
    });
    this.subscriptions.add(productAssetFormValueChangesSubscription);

    if (this.otherPlates === true) {
      this.commonService.getSpecialPlatesTypes().subscribe(resp => {
        this.specPlateTypes = resp;
      });

      this.commonService.getEnumValues('COUNTRIES', null).subscribe(resp => {
        this.countriesList = resp;
      });
    }

    if (this.proposalService.getInclusionData() != null) {
      const dataElement = this.proposalService.getInclusionData().data;
      if (dataElement.product != null) {
        this.getForm().get('product').setValue(dataElement.product);
        this.products.push(dataElement.product);
      }
      if (dataElement.assetCode != null) {
        this.passProductsService.getAssetTypes(dataElement.product.code).subscribe(data => {
            this.assetTypes = data.filter( el => el.code === dataElement.assetCode);
            this.setOnlyOneAsset();
            this.otherPlates = true;

            this.initializePlateTypes();
            this.initializeVehicleTypes();
          }
        );
      }
    } else {
      this.initProductList();
      this.initializePlateTypes();
      this.initializeVehicleTypes();
    }

    this.isPlateTypeValid = false;

  }

  protected setOtherPlates() {
    this.otherPlates = this.stateService.getCurrentState() === State.PRODUCT_ASSET_SELECTION_ALTERNATIVE_PLATE;
  }
  protected setFlowWithoutPlate() {
    this.flowWithoutPlate = this.stateService.getCurrentState() === State.PRODUCT_ASSET_SELECTION_WITHOUT_PLATE ||
      this.stateService.getCurrentState() === State.EDIT_ASSET_DATA_WITHOUT_PLATE;
  }

  protected setWrongDataFromAnia() {
    this.wrongDataFromAnia = this.stateService.getCurrentState() === State.PRODUCT_ASSET_SELECTION_WRONG_DATA_IN_ANIA;
  }

  protected setDataInAniaNotFound() {
    this.dataInAniaNotFound = this.stateService.getCurrentState() === State.PRODUCT_ASSET_SELECTION_DATA_IN_ANIA_NOT_FOUND;
  }

  protected setIsTestPlate() {
    this.isTestPlate = this.proposalService.getApiContract() && this.proposalService.getApiContract().testPlate;
    if (this.isTestPlate) {
      this.getForm().get('plateType').patchValue(this.plateTypes.find(plateType => plateType.code === '3'));
    }
  }

  showVehicleType() {
    let showVehicleType = (this.proposalService.getAssetType() || this.getForm().controls.assetType.value)
      && !this.getAniaVehicleTypeCode();
    if (showVehicleType) {
      this.getForm().get('vehicleType').setValidators(Validators.required);
    }
    return showVehicleType;

  }

  onSubmit() {
    this.submitted = true;
    Object.keys(this.getForm().controls).forEach(field => {
      this.getForm().get(field).markAsDirty();
    });

    this.validateForm();

    if (this.getForm().valid) {

      this.onSubmitChangeAsset();
      // this.routingService.setLastVisitedView('start');
    }
  }

  protected onSubmitChangeAsset() {
    if (this.wrongDataFromAnia) {
      this.proposalService.refreshAsset();
      this.proposalService.refreshContractData();
      this.proposalService.refreshProductAndAssetType();
    }

    const controls = this.getForm().controls;

    this.proposalService.setProduct(controls.product.value);
    this.proposalService.setAssetType(controls.assetType.value);

    if (!this.flowWithoutPlate && (this.otherPlates || this.wrongDataFromAnia || this.dataInAniaNotFound || this.isTestPlate)) {
      this.setPlate();
    }

    this.proposalService.setIsReadyToCreateNewContract(true);

    this.componentRef.destroy();
  }

  substitutionSubmit() {
    this.submitted = true;
    this.validateForm();
    console.log(this.validationMessages); // todo controlLi
    this.setPlate();
    const controls = this.getForm().controls;


    const nodeId = this.operatorService.getSalePointLogin().objectId;
    const contractId = this.proposalService.getContractId();
    const plateNumber = this.proposalService.getPlateNumber().plateNumber;
    const plateType = controls.plateType && controls.plateType.value && controls.plateType.value !== '' ? controls.plateType.value : null;
    const assetCode = this.proposalService.subAssetCode;
    this.proposalService.updateVehicleFromPlate('', nodeId, contractId, plateNumber, plateType, assetCode).subscribe(
      (contract: ApiContract) => {
        const products = contract.products;

        if (hasAssetNotFoundErrors(contract) || hasTechnicalDataErrors(contract)) {
          // this.needToSetAssetType = true;
          this.wrongDataFromAnia = true;
          this.dataInAniaNotFound = true;
          this.submitted = false;
        } else /*if ((products.length > 1) || (products.length === 1 && products[0].assets.length > 1)) {
          // not managed state in substitution flow (for now..?)
          this.stateService.nextState(Action.MULTIPLE_PRODUCTS_ASSETS_FOUND_STANDARD_PLATE_WORKFLOW);
          this.proposalService.setMultipleProductsAssetTypes(products);
        } else*/ if (products.length === 1) {

          this.proposalService.setApiContract(contract);
          this.proposalService.setAsset(contract.vehicle);
          this.proposalService.setProduct(controls.product.value);
          this.proposalService.setAssetType(controls.assetType.value);

          if (this.stateService.getCurrentState() === State.QUOTATION_STANDARD_PLATE
            || this.stateService.getCurrentState() === State.QUOTATION_ALTERNATIVE_PLATE
            || this.stateService.getCurrentState() === State.QUOTATION_WITHOUT_PLATE) {
            this.bRecalculateQuotation = true;
          }
          this.cancel(Action.EDIT_QUOTATION_BUTTON_PRESSED);
        }
      },
      err => {
        if (err.status > 400 && err.status < 500) {
          this.validationMessages.push(err.error.messages[0]);
        }
      });

  }

  onProductChange(clearForm = true) {

    this.isPlateTypeValid = false;

    if (clearForm) {
      this.getForm().patchValue({
        specPlateType: null,
        specPlatePrefix: null,
        countries: null,
        licensePlate: null,
        assetType: null,
        vehicleType: null,
      });
      if (!this.isTestPlate) {
        this.getForm().patchValue({plateType: null});
      }
    }

    this.proposalService.setAssetType(null);

    const productCode = this.getForm().controls.product.value.code;
    if (productCode) {

      if (this.isPlateKnown) {
        this.assetTypes = this.productToAssetTypesMap.get(productCode);
            this.getForm().get('assetType').setValidators(Validators.required);
        this.setOnlyOneAsset();
        this.checkAndSetPlateType();
      } else {
        this.passProductsService.getAssetTypes(productCode).subscribe(
          data => {
            this.assetTypes = data;
            this.getForm().get('assetType').setValidators(Validators.required);
            this.setOnlyOneAsset();
            this.checkAndSetPlateType();
          }
        );
      }
    }
  }

  onVehicleTypeChange() {
    if (this.isVehicleRimorchioOrMacchinaAgricola()) {
      this.doNotShowCertificatoIdoneitaTecnicaAmongPlateTypes();
    }
    if (this.stateService.getCurrentState() === State.PRODUCT_ASSET_SELECTION_STANDARD_PLATE
      || this.stateService.getCurrentState() === State.PRODUCT_ASSET_SELECTION_WRONG_DATA_IN_ANIA
      || this.stateService.getCurrentState() === State.PRODUCT_ASSET_SELECTION_DATA_IN_ANIA_NOT_FOUND) {
      this.isPlateTypeValid = false;
      this.validatePlateType();
    }
  }

  onPlateTypeChange() {
    this.isPlateTypeValid = false;
    this.otherPlates = false;
    this.validatePlateType();

    const plateTypeCode: string = this.getForm().controls.plateType.value.code;

    // 6 -> TARGA SPECIALE, 4 -> TARGA ESTERA
    if (plateTypeCode === '6' || plateTypeCode === '4') {

      this.otherPlates = true;

      this.getForm().patchValue({
        specPlateType: null,
        specPlatePrefix: null,
        licensePlate: null,
        countries: null
      });

      // Mandatory fields management
      if (plateTypeCode === '6') {
        this.commonService.getSpecialPlatesTypes().subscribe(resp => {
          this.specPlateTypes = resp;
        });

        this.setSpecialPlateValidators();

      } else if (plateTypeCode === '4') {
        this.commonService.getEnumValues('COUNTRIES', null).subscribe(resp => {
          this.countriesList = resp;
        });

        this.setForeignPlateValidators();

      } else {
        this.clearSpecialPlateAndForeignPlateValidators();
      }

      this.getForm().updateValueAndValidity();
    }
  }

  onAssetTypeChange() {
    this.isPlateTypeValid = false;
    this.getForm().get('vehicleType').reset();
    this.proposalService.setAssetType(null);
    const assetType = this.getForm().controls.assetType.value;
    this.getForm().patchValue({vehicleType: null});
    if (!this.isTestPlate && this.plateTypes && this.plateTypes.length > 1) {
      this.getForm().patchValue({ plateType: null });
    }
    this.proposalService.setAssetType(assetType);
    this.validatePlateType();
  }

  getAniaVehicleTypeCode() {

    try {

      if (!this.getForm() || !this.getForm().controls.product.value
        || !this.getForm().controls.assetType.value
        || !this.productToAssetTypesMap) {
        return 'NULL';
      }

      const product: ApiProduct = this.getForm().controls.product.value;
      const assetType = this.assetTypes.find(
        (asset) => this.getForm().controls.assetType.value.code === asset.code);

      if (product && assetType) {
        this.proposalService.setVehicleType(assetType.aniaVehicleTypeCode);
        return assetType.aniaVehicleTypeCode;
      } else {
        return 'NULL';
      }

    } catch (e) {
      return 'NULL';
    }
  }

  onAlternativePlateTypeChange() {
    this.getForm().patchValue({
      specPlateType: null,
      specPlatePrefix: null,
      licensePlate: null,
      countries: null
    });

    // Mandatory fields management
    if (this.getForm().controls.plateType.value === '1') {
      this.setSpecialPlateValidators();
    } else if (this.getForm().controls.plateType.value === '2') {
      this.setForeignPlateValidators();
    } else {
      this.clearSpecialPlateAndForeignPlateValidators();
    }
    this.getForm().updateValueAndValidity();
  }

  onSpecialPlateTypeChange() {
    this.getForm().patchValue({
      specPlatePrefix: null,
      licensePlate: null
    });

    const type: GenericEntity = this.getForm().controls.specPlateType.value;
    if (type) {
      this.commonService.getSpecialPlatesPrefixes(type).subscribe(resp => {
        this.specPrefixesFormat = resp;
        this.specPlatePrefixes = this.specPrefixesFormat.map(item => item.description)
          .filter((value, index, self) => self.indexOf(value) === index);
      });
    }
  }

  cancel(action?: Action) {
    action ? this.onCancel = action : this.onCancel = true;
    this.proposalService.setIsReadyToCreateNewContract(false);
    this.componentRef.destroy();
  }

  genericEntitiesTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  validateForeignPlate() {
    if (!/^(?=.*[A-Z])(?=.*\d)[A-Z\d]{4,8}$/.test(this.getForm().controls.licensePlate.value.toUpperCase())) {
      this.getForm().controls.licensePlate.setErrors({incorrect: true});
      const translatedText = this.translate.instant(
        'The format of the license plate must be between 4 and 8 characters long, and must contain at least one number and one letter'
      );
      const message = new Message(this.areaCodeForeingPlate, translatedText);
      if (!this.errorMessagesService.includesMessage(this.validationMessages, message)) {
        this.validationMessages.push(message);
      }
    } else {
      this.getForm().controls.licensePlate.setErrors(null);
    }
  }

  protected setPlate() {
    const controls = this.getForm().controls;
    let plate: LicensePlate;

    let plateNumber: string;

    if (controls.specPlatePrefix && controls.specPlatePrefix.value && controls.specPlateType.value.code !== 'AS') {
      // Only for special plate, but not for 'historic cars'
      plateNumber = controls.specPlatePrefix.value + controls.licensePlate.value;
    } else {
      plateNumber = controls.licensePlate.value;
    }

    if (this.isSubstitution) {
      plate = this.proposalService.getPlateNumber();
      plateNumber = this.getForm().controls.licensePlate.value;

      if (plateNumber) {
        plate.plateNumber = String(plateNumber).trim().toUpperCase();
        this.proposalService.setPlateNumber(plate);
      }
    }

    if (this.otherPlates) {
      const formPlate = controls.plateType.value;
      plate = new LicensePlate(plateNumber, null, new GenericEntity(formPlate.id, formPlate.code, formPlate.description),
        controls.specPlateType.value, this.validFormat, controls.countries.value);
      this.proposalService.setPlateNumber(plate);
    } else if (this.wrongDataFromAnia || this.dataInAniaNotFound || this.isTestPlate) {
      plate = this.proposalService.getPlateNumber();
      plate.plateType = controls.plateType.value;
    }
  }

  protected validatePlateType() {
    if (!this.isTestPlate) {

      const plateType: GenericEntity = this.getForm().controls.plateType.value;
      let assetType: ApiAssetType = this.proposalService.getAssetType();
      if (this.assetTypes.length === 1) {
        assetType = this.getForm().controls.assetType.value;
      }
      const plate: LicensePlate = this.proposalService.getPlateNumber();

      if (assetType && plateType && plate && plate.plateNumber) {

        const vehicleType = this.getForm().controls.vehicleType.value;
        const vehicleTypeCode = assetType?.aniaVehicleTypeCode ? assetType.aniaVehicleTypeCode : (vehicleType?.code ? vehicleType.code : null);

        this.commonService.getPlateType(
          this.proposalService.getPlateNumber().plateNumber,
          vehicleTypeCode, '', this.proposalService.getNodeId())
          .subscribe((data: GenericEntity) => {

            this.plateTypeValidityChecked = true;

            if (data.description === plateType.description) {
              this.proposalService.setVehicleType(vehicleTypeCode);
              this.isPlateTypeValid = true;
              this.clearErrorMessages();
            } else {
              this.getForm().controls.plateType.setErrors({plateTypeNotValid: true});
              this.setInvalidPlateTypeError();
            }
          },
          err => {
            this.getForm().controls.plateType.setErrors({plateTypeNotValid: true});
            this.setInvalidPlateTypeError();
          });
      }
    }

  }

  protected clearErrorMessages() {
    this.validationMessages.length = 0;
  }

  protected setInvalidPlateTypeError() {
    const controls = this.getForm().controls;

    this.clearErrorMessages();

    if (controls.plateType.errors) {
      this.validationMessages.push(new Message(this.areaCode, 'Plate number is not consistent with the plate type'));
    }
  }

  protected initProductList() {
    this.productToAssetTypesMap = new Map();
    let multipleProductsAssetTypes: Array<ApiProduct>;

    if (!this.wrongDataFromAnia && !this.dataInAniaNotFound) {
      multipleProductsAssetTypes = this.proposalService.getMultipleProductsAssetTypes();
    } else {
      multipleProductsAssetTypes = null;
    }

    if (multipleProductsAssetTypes) {
      this.isPlateKnown = true;

      multipleProductsAssetTypes.forEach(
        (prod: ApiProduct) => {
          const assetTypes = new Array<ApiAssetType>();

          prod.assets.forEach(
            (y: ApiAssetType) => {
              assetTypes.push(y);
            }
          );

          this.productToAssetTypesMap.set(prod.code, assetTypes);
        }
      );

      this.products = multipleProductsAssetTypes;
      // } else if (this.isSubstitution) {
      //   const product = this.proposalService.getApiContract().products[0];
      //   this.getForm().get('product').setValue(product);
    } else {
      const idPv = this.operatorService.getSalePointLogin().objectId;
      this.passProductsService.getProducts(idPv).subscribe((data: any) => {
        const motorProducts = [];
        data.products.forEach((product: any) => {
          if (product.policyCategory.codice === '1') {
            motorProducts.push({
              id: product.product.identification,
              code: product.product.code,
              description: product.product.description,
            });
          }
        });
        this.products = motorProducts;
        if (this.products && this.products.length === 1) {
          this.getForm().get('product').setValue(this.products[0]);
          this.onProductChange();
        }
      });
    }
  }

  protected initializePlateTypes() {
    let plateNum = this.proposalService.getPlateNumber()?.plateNumber ?? '';
    this.commonService.getPlateTypes(plateNum).subscribe((data) => {

      const otherPlatesList = data.filter((plateType: GenericEntity) => {
        return plateType.code === '4' || plateType.code === '6';
      });

      if (this.otherPlates) {
        this.plateTypes = otherPlatesList;
      } else {
        this.plateTypes = data.filter((plateType: GenericEntity) => {
          return otherPlatesList.indexOf(plateType) < 0;
        });
      }

      this.setIsTestPlate();
    });
  }

  protected initializeVehicleTypes() {
    this.commonService.getVehicleTypes().subscribe(data => {
      this.vehicleTypes = data;
    });
  }

  protected isVehicleRimorchioOrMacchinaAgricola() {
    const selectedVehicleType = this.getForm().controls.vehicleType.value;
    return selectedVehicleType.code === 'R' || selectedVehicleType.code === 'S';
  }

  protected doNotShowCertificatoIdoneitaTecnicaAmongPlateTypes() {
    this.plateTypes = this.plateTypes.filter((plateType: GenericEntity) => {
      return !plateType.description.startsWith('Certificato');
    });
  }

  protected setSpecialPlateValidators() {
    this.clearSpecialPlateAndForeignPlateValidators();
    this.getForm().controls.specPlateType.setValidators([Validators.required, ValidateGenericEntity]);
    this.getForm().controls.specPlatePrefix.setValidators([Validators.required, ValidateGenericEntity]);
    this.getForm().controls.licensePlate.setValidators([Validators.required, ValidateGenericEntity]);
    this.updateValueSpecialCase();
    // this.getForm().controls.countries.setValidators(null);
  }

  protected setForeignPlateValidators() {
    this.clearSpecialPlateAndForeignPlateValidators();
    this.getForm().controls.countries.setValidators([Validators.required, ValidateGenericEntity]);
    this.getForm().controls.licensePlate.setValidators([Validators.required, ValidateGenericEntity]);
    this.updateValueSpecialCase();
    // this.getForm().controls.specPlateType.setValidators(null);
    // this.getForm().controls.specPlatePrefix.setValidators(null);
  }

  protected clearSpecialPlateAndForeignPlateValidators() {
    this.getForm().controls.specPlateType.setValidators(null);
    this.getForm().controls.specPlatePrefix.setValidators(null);
    this.getForm().controls.countries.setValidators(null);
    this.getForm().controls.licensePlate.setValidators(null);
    this.updateValueSpecialCase();
  }

  protected updateValueSpecialCase() {
    this.getForm().controls.specPlateType.updateValueAndValidity();
    this.getForm().controls.specPlatePrefix.updateValueAndValidity();
    this.getForm().controls.countries.updateValueAndValidity();
    this.getForm().controls.licensePlate.updateValueAndValidity();
  }

  protected setOnlyOneAsset() {
    if (this.assetTypes && this.assetTypes.length === 1) {
      this.getForm().patchValue({
        assetType: this.assetTypes[0]
      });
      this.getForm().controls.assetType.markAllAsTouched();
    }
  }

  protected validateForm() {

    this.clearErrorMessages();

    this.validateChangeAssetForm();
  }

  protected validateChangeAssetForm() {
    const controls = this.getForm().controls;
    if (controls.product.errors && controls.product.errors.required && !this.isSubstitution) {
      this.validationMessages.push(new Message(this.areaCode, 'Product is mandatory'));
    }
    if (controls.assetType.errors && controls.assetType.errors.required && this.assetTypes.length > 1) {
      this.validationMessages.push(new Message(this.areaCode, 'Asset Type is mandatory'));
    }
    if (controls.plateType.errors) {
      if (controls.plateType.errors.required) {
        this.validationMessages.push(new Message(this.areaCode, 'Plate Type is mandatory'));
      }
      if (controls.plateType.errors.plateTypeNotValid) {
        this.validationMessages.push(new Message(this.areaCode, 'Plate Type is not valid'));
      }
    }
    if (controls.specPlateType.errors && controls.specPlateType.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Special Plate Type is mandatory'));
    }
    if (controls.specPlatePrefix.errors && controls.specPlatePrefix.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Prefix is mandatory'));
    }
    if (controls.countries.errors && controls.countries.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Countries is mandatory'));
    }
    if (controls.licensePlate.errors && controls.licensePlate.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'License Plate is mandatory'));
    }
    if (controls.specPlatePrefix.value && controls.licensePlate.value) {
      this.validateSpecialPlate(controls);
    }
    if (controls.plateType.value && controls.plateType.value.code === '4' && controls.licensePlate.value) {
      this.validateForeignPlate();
    }
  }
  protected validateSpecialPlate(controls) {
    let plateNum: string = controls.licensePlate.value;
    if (plateNum) {
      plateNum = plateNum.toUpperCase();
    }

    const formats = this.specPrefixesFormat.filter(data => {
      return data.description === controls.specPlatePrefix.value;
    });

    this.validFormat = null;
    for (const formatObj of formats) {
      const format: string = formatObj.code;

      /*
       * Special cases:
       * Corpo forestale: FD - VA;
       * Protezione Civile: TN - ZS;
       * Vigili del Fuoco: TN - BZ - FW
       * rimorchi-motocicli-trattrici: R - M - T
       */
      const SPECIAL_CASES: string[] = ['FD', 'VA', 'TN', 'ZS', 'TN', 'BZ', 'FW', 'R', 'M', 'T'];
      for (const specCase of SPECIAL_CASES) {
        const posSpecCase: number = format.indexOf(specCase);
        if (posSpecCase !== -1 && plateNum.indexOf(specCase) !== posSpecCase) {
          continue;
        }
      }

      let isOk = true;
      for (let l = 0; l < format.length; l++) {
        const c = format.charAt(l);

        if (((c === 'N') && !/^(\d{1})$/.test(plateNum.charAt(l))) || ((c === 'A') &&
          !/^([A-Z]{1})$/.test(plateNum.charAt(l))) || ((c === 'X') &&
          !/^([A-Z0-9]{1})$/.test(plateNum.charAt(l)))) {
          isOk = false;
          break;
        }
      }

      if (!isOk || plateNum.length !== format.length) {
        continue;
      } else {
        this.validFormat = formatObj;
        break;
      }
    }

    if (!this.validFormat) {
      controls.licensePlate.setErrors({invalidDate: true});
      const listFormat = this.specPrefixesFormat.filter(elem => {
        if (elem.description === this.getForm().controls.specPlatePrefix.value) {
          return elem;
        }
      }).map((format) => {
        return format.code;
      }).join(',');

      const msg = this.translate.instant(
        'Error in the format of the plate. Admissible formats: {{format}} (N: number, A: letter, X: alphanumeric)', {format: listFormat}
      );
      this.validationMessages.push(new Message(this.areaCode, msg));
    }
  }

  checkAndSetPlateType() {
    if (this.plateTypes && this.plateTypes.length === 1) {
      this.getForm().patchValue({
        plateType: this.plateTypes[0]
      });
      this.getForm().controls.plateType.markAllAsTouched();
      this.validatePlateType();
    }
  }
}

<form [formGroup]="addressForm">
  <div *ngIf="!showNormalizedAddressesResults ; else elseBlock">
    <rgi-rx-form-field>
      <label rgiRxLabel translate>_ANAG_._LABEL_._NATION_</label>
      <select rgiRxNativeSelect formControlName="countryCode" data-qa="umaaddr-country-code">
        <option selected></option>
        <option *ngFor="let country of countriesList" [value]="country.abbreviation">
          {{country.description}}
        </option>
      </select>
    </rgi-rx-form-field>
    <div *ngIf="addressConfig">
        <rgi-anag-uma-address-input *ngFor="let addressInput of orderedAddressInputKeyList"
                                    (onZipChange)="updateLevelsByZip($event)"
                                    [formGroup]="addressForm"
                                    [fieldName]=addressInput
                                    [filteredOptionsValues]="filteredValuesMap.get(addressInput)"
                                    [addressConfigField]="orderedAddressInputMapClone.get(addressInput)">
        </rgi-anag-uma-address-input>

      <div class="rgi-ui-snackbar rgi-ui-warning" *ngIf="noNormalizedAddressFounded">
        <span class="rgi-ui-icon-snackbar rgi-ui-icon-warning"></span>
        <span class="rgi-ui-message">{{'_ANAG_._MSG_._NORMALIZED_ADDRESS_NOT_FOUND_' | translate}}</span>
        <span class="rgi-ui-btn-close" (click)="noNormalizedAddressFounded=false"></span>
      </div>
      <div class="rgi-ui-snackbar rgi-ui-danger" *ngIf="normalizerServiceError" data-qa="rgi-anag-httpError">
        <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
        <span class="rgi-ui-message">{{normalizerServiceError}}</span>
        <span class="rgi-ui-btn-close" (click)="normalizerServiceError = null"></span>
      </div>
      <div class="rgi-ui-panel-footer rgi-anag-tm-1" *ngIf="isNormalizationVisibile">
        <button class="rgi-ui-btn rgi-ui-btn-icon" data-qa="rgi-anag-normalize" (click)="normalize()">{{'_ANAG_._BTN_._NORMALIZE_' |
          translate}}</button>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <rgi-rx-datatable [data]="normalizedAddressesTableData" [schema]="NORMALIZED_ADDRESSES_TABLE_SCHEMA"
      (onAction)="onActionClick($event)">
    </rgi-rx-datatable>
  </ng-template>

</form>

import { STEP } from './../../models/consts/lpc-consts';
import { QuestionnaireType } from './../../modules/lpc-questionnaire/model/questionnaire-type.enum';
import { ChangeDetectorRef, Component, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { questionnaireCacheServiceInjectionToken } from '@rgi/questionnaires-manager';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ComponentWithQuotation } from '../../interfaces/component-with-quotation';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import { FactorDefinition } from '../../models/postsales-operations-response.model';
import { Beneficiary } from '../../modules/lpc-beneficiary/model/beneficiary';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcCommissionDetailModalComponent } from '../../modules/lpc-commission-detail-modal/lpc-commission-detail-modal.component';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { _OPERATION_CODES } from '../../modules/lpc-operations-list/model/lpc-operations-constants';
import { Premium } from '../../modules/lpc-premium/model/premium.model';
import { QuestionnaireLevel } from '../../modules/lpc-questionnaire/model/questionnaire-level.enum';
import { LpcQuotationDetailModalComponent } from '../../modules/lpc-quotation-detail-modal/lpc-quotation-detail-modal.component';
import { Quotation } from '../../modules/lpc-quotation/model/quotation.model';
import { LpcWarrantyDetailModalComponent } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail-modal.component';
import { Instalments } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail/lpc-instalments.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { PlcQuotationUtils } from '../../utils/plc-quotation-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import {
  BeneficiaryDefinition, Instalment, PaymentTypeDefinition, PostsalesOperationObject, QuotationDefinition, Risk
} from './../../models/postsales-operations-response.model';
import { InstalmentType, QuotationRiskData } from './dynamic-operation.model';
import { DYNAMIC_OP_STEPS } from './configuration/dynamic-op-configuration';

@Component({
  selector: 'lpc-dynamic-operation',
  templateUrl: './dynamic-operation.component.html',
  styleUrls: ['./dynamic-operation.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // TODO: check if there is a better way
    // @ts-ignore
    questionnaireCacheServiceInjectionToken
  ]
})

export class DynamicOperationComponent extends AbsOperationComponent implements ComponentWithQuotation {
  public readonly OPERATION_CODES = _OPERATION_CODES;
  public readonly HEALT_QUEST_CODE = QuestionnaireType.SANITARY;
  public readonly DUE_DILIGENCE_QUEST_CODE = QuestionnaireType.DUE_DILIGENCE;
  public readonly DYNAMIC_STEP = DYNAMIC_OP_STEPS;
  public readonly SINGLE_BENEF_CONTROL = 'b';

  protected operationDataKey: string;
  public listProductFactor: FactorDefinition[] = [];
  public listGoodsFactor: FactorDefinition[] = [];
  public risks: Risk[] = [];
  public requestFactor: RequestFactor[] = [];
  public requestGoodFactor: RequestFactor[] = [];
  public showFormuleModal = false;
  public beneficiaryDefinitions: BeneficiaryDefinition[] = [];
  public beneficiaryData: Beneficiary[] = [];
  public selectedBeneficiariesForSummary: any[];
  public quotationDefinition: QuotationDefinition;
  public quotationData: Quotation;
  public risksForUnits: Risk[] = [];
  public productType: string;

  // QUOTATION DETAIL MODAL
  public annualPremium: Premium;
  public firstInstalment: Premium;
  public nextInstalment: Premium;

  // WARRANTIES DETAIL MODAL
  public instalments: Instalments;

  // COMMISSIONS DETAIL MODAL
  riskFromOperationData: Risk[] = [];
  enableProvModal: boolean;

  // QUOTATION CONTROLS BUTTONS
  public disableQuote = true;
  public disableDetails = true;
  public disableNext = false;

  public disableBeneficiary: boolean;
  protected $paymentTypes: PaymentTypeDefinition[] = [];

  get hasListProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  get atSummaryProductFactor(): boolean {
    return this.stepper.active > 1;
  }

  get hasListGoodsFactor(): boolean {
    return !!this.listGoodsFactor && !!this.listGoodsFactor.length;
  }

  get atSummaryGoodsFactor(): boolean {
    if (this.hasListProductFactor) {
      return this.stepper.active > 2;
    } else {
      return this.stepper.active > 1;
    }
  }

  private $currentStep: string;
  get currentStep(): string {
    return this.$currentStep;
  }
  get hasBeneficiaryDefinitions(): boolean {
    return !!this.beneficiaryDefinitions && !!this.beneficiaryDefinitions.length;
  }

  get hasAVCQuestionnaires(): boolean {
    return !!this.questionnaireDefinitions &&
    !!this.questionnaireDefinitions.filter(qst => qst.level === QuestionnaireLevel.DUE_DILIGENCE).length;
  }

  get hasPaymentTypes(): boolean {
    return !!this.$paymentTypes && !!this.$paymentTypes.length;
  }

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;

  @ViewChild('goodsFactorAdapter') goodsFactorAdapter: LpcFactorAdapterComponent;

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      [STEP.DATE.formName]: new UntypedFormControl(),
      [STEP.ROLES.formName]: new UntypedFormControl(),
      [STEP.POLICY_FACTORS.formName]: new UntypedFormGroup({}),
      [STEP.ASSET_FACTORS.formName]: new UntypedFormGroup({}),
      [STEP.QUESTS_HLT.formName]: new UntypedFormGroup({}),
      [STEP.QUOTATION.formName]: new UntypedFormControl(),
      [DYNAMIC_OP_STEPS.BENEFICIARIES.formName]: new UntypedFormGroup({}),
      [STEP.RISKS.formName]: new UntypedFormControl(),
      [STEP.PAYMENT.formName]: new UntypedFormControl()
    });
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.initializeInstalments();
    this.disableQuote = true;
    this.disableDetails = true;
    // this.createDraft().subscribe();
    this.formGroup.get(this.DYNAMIC_STEP.BENEFICIARIES.formName).valueChanges.subscribe(el => {
      this.getFeErrors();
    });
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {
          this.createDraftResponse(result);
        })
      ).subscribe()
    );
  }

  createDraftResponse(result) {
    if (!!result.data.operationData.data) {
      this.formGroup.get(this.STEP.PAYMENT.formName).setValue(result.data.operationData.data.payment, {emitEvent: false});
    }
  }

  initializeInstalments() {
    this.instalments = {
      firstInstalmentSection: [],
      nextInstalmentSection: [],
      annualPremiumSection: [],
      totAnnualPremium: null,
      totFirstInstalment: null,
      totNextInstalment: null
    };
  }

  updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload(this.STEP.POLICY_FACTORS.id).subscribe(result => {
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    }));
  }

  updateGoodsFactors(factors: RequestFactor[]) {
    this.requestGoodFactor = factors;
    this.$subscriptions.push(this.onReload(this.STEP.ASSET_FACTORS.id).subscribe(result => {
      this.listGoodsFactor = result.definitions.goodsFactors as FactorDefinition[];
    }));
  }

  // to do refactor
  onNextFactors(control, step) {
    this.$feErrors = [];
    if (step === this.STEP.ASSET_FACTORS.id) {
      this.goodsFactorAdapter.updateFactors(true);
    } else {
      this.factorAdapter.updateFactors(true);
    }

    /* if (this.isFormValid(this.formGroup.get(control))) {
      if (!this.hasBlockingErrorsOnSteps(step)) {
        this.$subscriptions.push(
          this.updateDraft(step, false, 'DYNAMC').subscribe(result => {
            this.commonResponseHandling(result);
            this.stepper.next();
          })
        );
      }
    } else {
      this.setFeErrors('policyFactors');
    } */
    this.onNext(step, false);
  }

  // to do refactor
  /** @deprecated */
  public onNextGoodsFactors() {
    this.$feErrors = [];
    this.goodsFactorAdapter.updateFactors(true);
    if (this.isFormValid(this.formGroup.get(this.STEP.POLICY_FACTORS.formName))) {
      if (!this.hasBlockingErrorsOnSteps(this.STEP.ASSET_FACTORS.id)) {
        this.$subscriptions.push(
          this.updateDraft(this.STEP.ASSET_FACTORS.id, false, 'DYNAMC').subscribe(result => {
            this.commonResponseHandling(result);
            this.stepper.next();
          })
        );
      }
    } else {
      this.setFeErrors(this.STEP.ASSET_FACTORS.id);
    }
  }

  // to do refactor
  onNextQuotation(control, step, publish = false, isConfirmAndAccept: boolean = false) {
    this.$feErrors = [];
    if (this.isFormValid(this.formGroup.get(control))) {
      this.$subscriptions.push(
        this.updateDraft(step, false, 'DYNAMC').subscribe(result => {
          this.$currentStep = step;
          if (this.hasBeneficiaryDefinitions && this.currentStep === this.STEP.QUOTATION.id) {
            this.handlingBeneficiaries(result);
          }
          this.commonResponseHandling(result);
          if (!this.hasBlockingErrorsOnSteps(step)) {
            publish = this.haveToDoPublish(step);
            this.doPublishOrNext(publish, isConfirmAndAccept);
          }
        })
      );
    } else {
      this.setFeErrors(step);
    }
  }

  // to do refactor
  onNext(step, publish = false, context = null, isConfirmAndAccept = false) {
    this.$feErrors = [];
    if (this.isFormValid(this.formGroup.get(step))) {
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, step, this).pipe(
        tap(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
          if (!!this.avcQuestionnaire) {
            this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(this.avcQuestionnaire);
          }
        }),
        switchMap(() => {
          return this.updateDraft(step, false, 'DYNAMC');
        })
      ).subscribe(result => {
          this.$currentStep = step;
          if (this.hasBeneficiaryDefinitions && this.currentStep === this.STEP.QUOTATION.id) {
            this.handlingBeneficiaries(result);
          }
          this.commonResponseHandling(result);
          if (!this.hasBlockingErrorsOnSteps(step)) {
            publish = this.haveToDoPublish(step);
            this.doPublishOrNext(publish, isConfirmAndAccept);
          }
        })
      );
    } else {
      this.setFeErrors(step);
    }
  }

  recalculate(disable: boolean) {
    if (this.formGroup.get(this.STEP.RISKS.formName).valid) {
      this.updateOnQuotation(disable);
    } else {
      this.setFeErrors(this.STEP.QUOTATION.id);
    }
  }

  updateOnQuotation(disable: boolean) {
    this.$subscriptions.push(
      this.updateDraft(this.STEP.QUOTATION.id, true, 'DYNAMC').subscribe(result => {
        // disableQuote è la negazione di disable perchè se disabilito (il continua) devo abilitare il ricalcola
        const disableQuote = !disable;
        this.commonResponseHandling(result, disableQuote);
        this.disableNext = disable;
      })
    );
  }

  onReload(step): Observable<PostsalesOperationObject> {
    this.$feErrors = [];
    return this.updateDraft(step, true, 'DYNAMC');
  }


  commonResponseHandling(response: PostsalesOperationObject, disableQuote = true) {
    this.listProductFactor = response.definitions.productFactors as FactorDefinition[];
    this.listGoodsFactor = response.definitions.goodsFactors as FactorDefinition[];
    this.quotationDefinition = response.definitions.quotationDefinition as QuotationDefinition;
    this.beneficiaryDefinitions = response.definitions.beneficiaries as BeneficiaryDefinition[];
    this.$paymentTypes = response.definitions.paymentTypes as PaymentTypeDefinition[];
    this.risks = this.createAllRisksArray(this.quotationDefinition.risks, true);
    this.risksForUnits = this.quotationDefinition.risks;
    this.updateModals(this.quotationDefinition);
    this.riskFromOperationData = this.createRiskFromOperationData(response.data.operationData.data.risks);
    this.detectChanges();
    this.disableQuote = disableQuote;
    this.disableDetails = !disableQuote; // se il tasto ricalcola è disabilitato il dettaglio delle garanzie sarà accessibile
    this.productType = this.quotationDefinition.productTypeCode;
  }

  createRiskFromOperationData(vectorOfRisk: QuotationRiskData[]): Risk[] {
    const newRisks = [];
    let provFound = false;
    if (vectorOfRisk && vectorOfRisk.length > 0) {
      vectorOfRisk.forEach((risk) => {
        const newSubRisks = [];

        if (!!risk && !!risk.subRisks && risk.subRisks.length > 0) {
          risk.subRisks.forEach(sRisk => {
            newSubRisks.push({
              annualPremium: null,
              code: sRisk.code,
              description: this.getRiskDescription(sRisk.code),
              formulas: [],
              subRisks: [],
              selected: sRisk.selected,
              factors: sRisk.factors,
              purchaseCommissions: sRisk.purchaseCommissions
            });
            if (sRisk.purchaseCommissions && sRisk.purchaseCommissions.length > 0) {
              provFound = true;
            }
          });
        }

        newRisks.push({
          annualPremium: null,
          code: risk.code,
          description: this.getRiskDescription(risk.code),
          formulas: [],
          subRisks: newSubRisks,
          selected: risk.selected,
          factors: risk.factors,
          purchaseCommissions: risk.purchaseCommissions
        });
        if (risk.purchaseCommissions != null && risk.purchaseCommissions.length > 0) {
          provFound = true;
        }
      });
    }
    // abilito pulsante solo se c'è una provvigione da vedere
    this.enableProvModal = provFound;

    return newRisks;
  }

  getRiskDescription(riskCode: string) {
    let description = EMPTY_STR;

    this.quotationDefinition.risks.forEach(risk => {
      if (description === EMPTY_STR) {
        if (risk.code === riskCode) {
          description = risk.description;
        } else {
          risk.subRisks.forEach(sRisk => {
            if (sRisk.code === riskCode) {
              description = sRisk.description;
            }
          });
        }
      }
    });

    return description;
  }

  handlingBeneficiaries(response) {
    const beneficiaries: Beneficiary[] = response.data.operationData.data.beneficiaries as Beneficiary[];
    const beneficiariesDefinitions = (response.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    beneficiariesDefinitions.forEach(el => {
      (this.formGroup.get(this.DYNAMIC_STEP.BENEFICIARIES.formName) as UntypedFormGroup).addControl(
        this.SINGLE_BENEF_CONTROL + el.code,
        new UntypedFormControl({
          code: null,
          subjects: [],
          irrevocable: false
        })
      );
    });
    this.beneficiaryDefinitions = beneficiariesDefinitions;
    this.beneficiaryData = beneficiaries;
    beneficiariesDefinitions.forEach(definition => {
      if (!!this.beneficiaryData) {
        const ben: Beneficiary = this.beneficiaryData.find(el => el.type === definition.code);
        this.formGroup
        .get(this.DYNAMIC_STEP.BENEFICIARIES.formName)
        .get(this.SINGLE_BENEF_CONTROL + definition.code)
        .enable({emitEvent: false});

        this.formGroup.get(this.DYNAMIC_STEP.BENEFICIARIES.formName)
        .get(this.SINGLE_BENEF_CONTROL + definition.code)
        .setValue({
          code: !!ben ? ben.code : null,
          subjects: this.beneficiaryData.filter(el => el.type === definition.code),
          irrevocable: !!ben ? ben.irrevocable : false
        }, {emitEvent: false});
        if (!definition.editable) {
          this.formGroup
          .get(this.DYNAMIC_STEP.BENEFICIARIES.formName)
          .get(this.SINGLE_BENEF_CONTROL + definition.code)
          .disable({emitEvent: false});
        }
      }
    });
  }

  createAllRisksArray(risks: Risk[], isForFormulas: boolean): Risk[] {
    const riskArray: Risk[] = [];
    risks.map(risk => {
      if (isForFormulas) {
        if (!!risk.formulas) {
          riskArray.push(risk);
        }
      } else {
        riskArray.push(risk);
      }
      if (!!risk.subRisks) {
        risk.subRisks.map(subrisk => {
          if (isForFormulas) {
            if (!!subrisk.formulas) {
              riskArray.push(subrisk);
            }
          } else {
            riskArray.push(subrisk);
          }
        });
      }
    });
    return riskArray;
  }

  public closeFormuleModal(event) {
    this.showFormuleModal = false;
  }

  updateWarrantiesModal(quotationDefinition: QuotationDefinition) {
    this.instalments.firstInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.FIRST_INSTALMENT
    );
    this.instalments.nextInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.NEXT_INSTALMENT
    );
    this.instalments.annualPremiumSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.ANNUAL_PREMIUM
    );
    if (!!quotationDefinition.annualPremium) {
      quotationDefinition.annualPremium.description = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    if (!!quotationDefinition.firstInstalment) {
      quotationDefinition.firstInstalment.description = this.translate.getImmediate(InstalmentType.FIRST_INSTALMENT);
    }
    if (!!quotationDefinition.nextInstalment) {
      quotationDefinition.nextInstalment.description = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }

    this.instalments.totFirstInstalment = quotationDefinition.firstInstalment;
    this.instalments.totAnnualPremium = quotationDefinition.annualPremium;
    this.instalments.totNextInstalment = quotationDefinition.nextInstalment;
  }

  updateModals(quotationDefinition: QuotationDefinition) {
    this.updatePremiumModal(quotationDefinition);
    this.updateWarrantiesModal(quotationDefinition);
  }

  isFormValid(form): boolean {
    return !!form ? form.disabled ? true : form.valid : true;
  }

  openFormuleModal($event) {
    this.showFormuleModal = true;
  }

  saveFormulas(event) {
    this.updateDraft(this.STEP.QUOTATION.id, true, 'DYNAMC').subscribe(result => {
      PlcQuotationUtils.updateFormulasFromModel(result);
      this.disableNext = false;
      this.disableQuote = true;
      this.commonResponseHandling(result);
    });
  }

  updateFormulasFromModel(result: any) {
    if (!!result.data.operationData.data.riskFormulasObject[0]
      && result.data.operationData.data.riskFormulasObject[0].formulas.length > 0
      && result.definitions.quotationDefinition.risks[0].formulas.length > 0) {
       for (let i = 0; i < result.definitions.quotationDefinition.risks[0].formulas.length; i++) {
         result.definitions.quotationDefinition.risks[0].formulas[i].value =
         result.data.operationData.data.riskFormulasObject[0].formulas[i].value;
       }
   }
  }

  openQuotationModal(event) {
    const modalRef = this.modalService.open(LpcQuotationDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.firstInstalment = this.firstInstalment;
    modalRef.componentInstance.nextInstalment = this.nextInstalment;
    modalRef.componentInstance.annualPremium = this.annualPremium;
    modalRef.componentInstance.quotationData = this.quotationData;
  }

  openProvModal(event) {
    const modalRef = this.modalService.open(LpcCommissionDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.risks = this.riskFromOperationData;
  }

  openWarrantiesModal(event) {
    const modalRef = this.modalService.open(LpcWarrantyDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    modalRef.componentInstance.instalments = this.instalments;
  }

  updatePremiumModal(quotationDefinition: QuotationDefinition) {
    this.annualPremium = PlcQuotationUtils.instalmentToPremium(quotationDefinition.annualPremium);
    if (this.annualPremium) {
      this.annualPremium.typeDescription = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    this.firstInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.firstInstalment);
    if (!!this.firstInstalment) {
      this.firstInstalment.typeDescription = this.translate.getImmediate(InstalmentType.FIRST_INSTALMENT);
    }
    this.nextInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.nextInstalment);
    if (!!this.nextInstalment) {
      this.nextInstalment.typeDescription = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }
    this.quotationData = PlcQuotationUtils.quotationDefToQuotationData(quotationDefinition);
  }

  // creating sections for warranties detail modal
  createInstalmentSectionWarranties(risks: Risk[], instalmentType: string): Instalment[] {
    return PlcQuotationUtils.createInstalmentSectionWarranties(risks, instalmentType);
  }

  protected getTransformedOperationData(): any {
    return {
      beneficiaries: PlcObjectUtils.getBeneficiariesFromForm(this.beneficiaryDefinitions, this.formGroup),
      listGoodFactor: this.requestGoodFactor,
      listProductFactor: this.requestFactor,
      risks: !!this.formGroupValue.risks ? this.formGroupValue.risks.risks : [],
      riskFormulasObject: this.formGroupValue.quotation
        ? this.formGroupValue.quotation.riskFormulasObject
        : [],
      payment: !!this.formGroupValue[this.STEP.PAYMENT.formName] ? this.formGroupValue[this.STEP.PAYMENT.formName] : {}
    };
  }

  getFeErrors() {
    LpcBeneficiaryUtils.getBeneficiariesErrors(this.formGroup, this.translate);
   }

   isQuestionnaireVisible(): boolean {
    return this.session.operation === this.OPERATION_CODES.OVERPREMIUMOPERATION ||
    this.session.operation === this.OPERATION_CODES.CHANGEWARRANTYOPERATION ||
    this.session.operation === this.OPERATION_CODES.CAPITALVARIATION;
  }
  
  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);

    if (this.isAfterId(this.DYNAMIC_STEP.BENEFICIARIES.id)) {
      this.handleBeneficiariesSummary();
    }
  }

  /**
   * Gestisce la visualizzazione dei beneficiari dopo che si è passato lo step
   * (ESTENDERE A PROGETTO PER CAMBIARE LA VISUALIZZAZIONE)
   */
  handleBeneficiariesSummary() {
    this.selectedBeneficiariesForSummary = LpcBeneficiaryUtils.getBeneficiariesForSummary(
      this.beneficiaryDefinitions, this.formGroup, null, this.translate
    );
  }

  haveToDoPublish(step: string) {
    // se esiste lo step di warning non posso fare la publish
    if (this.isWarningStepVisible()) {
      return false;
    } else {
      switch (step) {
        case this.STEP.PAYMENT.id:
          return !this.hasBeneficiaryDefinitions && !this.hasAVCQuestionnaires;
        case this.STEP.QUOTATION.id:
          return !this.hasBeneficiaryDefinitions && !this.hasAVCQuestionnaires && !this.hasPaymentTypes;
        case this.STEP.QUESTS.id:
          return !this.hasBeneficiaryDefinitions;
        case this.DYNAMIC_STEP.BENEFICIARIES.id:
          return true;
      }
    }
  }

  isWarningStepVisible(): boolean {
    return this.isAuth || !!this.errors && !!this.errors.length;
  }

}




// @ts-ignore
// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

export function AngularCommunicationCtrlIssue(
  $log, $scope, $controller, eventService, sessionService, CONTAINER_EVENT, gettext,
  $uibModal, pRouter, coreResult, ANAG_CARD_ZOOM, cardPassService, anagManageSubjectValidationService, ANAG_CARD_MANAGE, CORE_EVENT) {
  if (!$scope.data) {
    $scope.data = {};
  }

  $scope.navi = null;
  $scope.selectedSubject = {};

  // Data from AngularJS should be stored in the coreResult service and read here.
  // In this way you can have access at those data in the $scope.data field and send them in Angular using downgraded card template.
  if (coreResult) {
    $scope.data = {
      anag: coreResult.getResult($scope.id, 'anag'),
      newFlow: coreResult.getResult($scope.id, 'newFlow'),
      subject: coreResult.getResult($scope.id, 'subject'),
      forwardData: coreResult.getResult($scope.id, 'forwardData'),
      product: coreResult.getResult($scope.id, 'product'),
      productEvaluation: coreResult.getResult($scope.id, 'productEvaluation'),
      isFromPreventive: coreResult.getResult($scope.id, 'isFromPreventive'),
      info: coreResult.getResult($scope.id, 'list'),
      profileVersion: coreResult.getResult($scope.id, 'profileAlloc'),
      isSaveFromAuth: coreResult.getResult($scope.id, 'isSaveFromAuth'),
      isFromConsultation: coreResult.getResult($scope.id, 'isFromConsultation'),
      proposalFromAuth: coreResult.getResult($scope.id, 'proposalFromAuth'),
      proposalFromDetail: coreResult.getResult($scope.id, 'proposalFromDetail'),
      authorizationId : coreResult.getResult($scope.id, 'authorizationId'),
      contractId : coreResult.getResult($scope.id, 'contractId'),
      causeCode : coreResult.getResult($scope.id, 'causeCode'),
      operationDescription : coreResult.getResult($scope.id, 'operationDescription'),
      isFromQuoteModification: coreResult.getResult($scope.id, 'isFromQuoteModification'),
      isFromProposalModification: coreResult.getResult($scope.id, 'isFromProposalModification'),
      isFromBuyQuote: coreResult.getResult($scope.id, 'isFromBuyQuote'),
      lifeProposalSettings: coreResult.getResult($scope.id, 'lifeProposalSettings'),
      isAnonymous: coreResult.getResult($scope.id, 'isAnonymous'),
      quoteFromDetail: coreResult.getResult($scope.id, 'quoteFromDetail'),
      hideButtonSaveUndefinedProposal: coreResult.getResult($scope.id, 'hideButtonSaveUndefinedProposal'),
      sessionId: $scope.id,
    };
  }


  $log.debug('Create angular event communication controller');

  const unlistenEvent = eventService.listenGlobalEvent('angular-card-event', (event, evtDetails) => {
    if (evtDetails.cardId === $scope.card.id) {
      if (evtDetails.subEvent === 'start-progress-bar') {
        $scope.startProgressBar();
        return;
      }
      if (evtDetails.subEvent === 'stop-progress-bar') {
        $scope.completeProgressBar();
        return;
      }
    }
  });
  $scope.$on('$destroy', unlistenEvent);

  $scope.wrapEvent = (event) => {
    if (event === 'exit') {
      $scope.removeSession($scope.card.idSession);
    } else if (event === 'layoutChanged') {
      // Update the grid of the card
      $scope.updateGrid();
    } else if (event.eventName && event.eventName === 'leaveMainPage') {
      sessionService.open(event.sessionParent, event.sessionRoute, event.sessionTitle, event.navigationDisabled);
    } else if (event.eventName && event.eventName === 'requireSubject') {
      openAnagFinderInPopup();
    } else if (event.eventName && event.eventName === 'leaveMainPage') {
      if (event.navi) {
        $scope.navi = event.navi;
      }
    } else if (event === 'startLoader') {
      eventService.broadcastEvent(CONTAINER_EVENT.START_LOADER_EVENT);
    } else if (event === 'stopLoader') {
      eventService.broadcastEvent(CONTAINER_EVENT.STOP_LOADER_EVENT);
    } else if (event.eventName && event.eventName === 'setTitle') {
      $scope.card.title = gettext(event.title);
    } else if (event === 'backToResult') {
      pRouter.go($scope.id, 'result');
    } else if (event.eventName && event.eventName === 'backToMainPage') {
      console.log('backToMainPage event received');
      const sessionStore = sessionService.list();
      sessionStore.forEach(session => {
        console.log('Removing session: ' + session.idSession);
        sessionService.remove(session.idSession);
      });
    } else if (event.eventName && event.eventName === 'removeCurrentSession') {
      sessionService.remove($scope.card.idSession);
    } else if (event.eventName && event.eventName === 'editSubj') {
      editSubject(event.subject, event.role);
    } else if (event.eventName && event.eventName === 'setSubjectOnCoreResult') {
      if (!event.isAnonymous) {
        coreResult.setResult(event.id, 'subject', event.subject);
      }
      coreResult.setResult(event.id, 'product', {code: event.product});
      coreResult.setResult(event.id, 'isAnonymous', event.isAnonymous);
      coreResult.setResult(event.id, 'isFromPreventive', true);
      coreResult.setResult(event.id, 'anag', event.anag);
      coreResult.setResult(event.id, 'lifeProposalSettings', event.data);
    } else if (event.eventName && event.eventName === 'goToLifeIssueResult') {
      const idSessionLifeResult = $scope.card.idSession;
      const cardIdLifeResult = sessionService.open(event.parent, event.route, event.title, true, false, false, idSessionLifeResult);
      coreResult.setResult(cardIdLifeResult, 'isSaveFromAuth', true);
      coreResult.setResult(cardIdLifeResult, 'isFromConsultation', event.origin === 'consultation' ? true : false);
      coreResult.setResult(cardIdLifeResult, 'proposalFromAuth', event.proposal);
    } else if (event.eventName && 'modify_quote' === event.eventName) {
      const idSessionQuoteModify = $scope.card.idSession;
      const cardIdQuoteModify = sessionService.open(event.parent, event.route, event.title, true, false, false, idSessionQuoteModify);
      coreResult.setResult(cardIdQuoteModify, 'isFromQuoteModification', true);
      coreResult.setResult(cardIdQuoteModify, 'quoteFromDetail', event.proposal);
    } else if (event.eventName && 'buy_quote' === event.eventName) {
      const idSessionQuoteBuy = $scope.card.idSession;
      const cardIdQuoteBuy = sessionService.open(event.parent, event.route, event.title, true, false, false, idSessionQuoteBuy);
      coreResult.setResult(cardIdQuoteBuy, 'isFromBuyQuote', true);
      coreResult.setResult(cardIdQuoteBuy, 'quoteFromDetail', event.proposal);
    } else if (event.eventName === 'openLifeProposalFlow') {
      // const idSessionLifeProposalFlow = $scope.card.idSession;
      const constlLifeProposalFlow = sessionService.open(event.card, event.route, '', true);
      coreResult.setResult(constlLifeProposalFlow, 'lifeProposalSettings', event.data);
    } else if (event.eventName && event.eventName === 'modify-life-proposal') {
      const idSession = $scope.card.idSession;
      const cardId = sessionService.open(event.parent, event.route, event.title, true, false, false, idSession);
      coreResult.setResult(cardId, 'isFromProposalModification', true);
      if (!!event.overrideShowButton && !!event.hideButtonSave) {
        coreResult.setResult(cardId, 'hideButtonSaveUndefinedProposal', true);
      }
      coreResult.setResult(cardId, 'proposalFromDetail', event.proposal);
    }
  };

  $scope.selectedSubject = {};

  $scope.onDropContainerComplete = (received) => {
    $scope.selectedSubject.received = received;
  };


  function editSubject(subject, role) {
    if (subject == null) {
        subject = $scope.subject;
    }
    let subjectRole = null;
    if ($scope.card.popupCardFilter) {
        subjectRole = $scope.card.popupCardFilter.role;
    }
    if (subject.limitation === false) {
      const _constraints = {
          node: !$scope.enableSalePoint
      };
      const modalInstance = $uibModal.open({
          templateUrl: 'anag/cardmanage/partials/modal-add-subject-enter.tpl.html',
          controller: 'AnagCardManageController_party',
          size: 'lg',
          resolve: {
              subject: () => {
                  return subject;
              },
              configuration: () => {
                  const filters = [
                      { type: 'subject', data: subject }
                  ];
                  if ($scope.card.popupCardFilter) {
                      filters.push({ type: 'popupCardFilter', data: $scope.card.popupCardFilter });
                  }
                  return anagManageSubjectValidationService.createConfigurationObject(filters);
              },
              constraints: () => {
                  return _constraints;
              },
              subjectRole: () => {
                  return subjectRole;
              },
              category: () => {
                  return null;
              },
              dynamicObject: () => {
                  return null;
              },
              dynamicObjectInstances: () => {
                  return null;
              },
              callInternationalPrefixes: () => {
                  return false;
              },
              parentSession: () => {
                if ($scope.card.idSession) {
                  return $scope.card.idSession;
                }
                return null;
              }
          }
      });

      modalInstance.result.then((res) => {
          $scope.selectedSubject.openModifySession = {opened: true, subject: res.subject, role};
          const idNavigation = sessionService.open(
            ANAG_CARD_MANAGE.CARD_NAME,
            'home',
            '',
            true,
            null,
            null,
            $scope.card.idSession,
            $scope.card.id
          );
          coreResult.setResult(idNavigation, 'newSubj', {
              subject: res.subject,
              configuration: res.configuration,
              constraints: _constraints
          });
      });
      modalInstance.closed.then((res) => {
        $scope.selectedSubject.openModifySession = {opened: false, subject: res.subject , role };
      });
    } else if (subject.limitation === true) {
        eventService.broadcastEvent(CORE_EVENT.ALERT_EVENT, {
            applicationArea: 'anag/finder',
            strId: 'anag_modifySubject_limited'
        });
    }
  }

  function openAnagFinderInPopup() {
    $uibModal.open({
      animation: true,
      templateUrl: 'coreportal/draggable/partials/modal-card.tpl.html',
      windowTemplateUrl: 'coreportal/draggable/partials/modal-container.tpl.html',
      controller: 'DraggableModalCardCtrl',
      size: 'drag-popup-card',
      backdropClass: 'drag-popup-backdrop',
      scope: $scope,
      resolve: {
        popupCardName: () => {
          return 'anagFinder';
        },
        modalDrag: () => {
          return false;
        },
        popupCardFilter: () => {
          return {};
        }
      },
      parentSession: () => {
        if ($scope.card.idSession) {
          return $scope.card.idSession;
        }
        return null;
      }
    });
  }
}

AngularCommunicationCtrlIssue.$inject = ['$log', '$scope', '$controller', 'eventService',
'sessionService', 'CONTAINER_EVENT', 'gettext', '$uibModal', 'parallelRouting', 'coreResult',
'ANAG_CARD_ZOOM', 'cardPassService', 'anagManageSubjectValidationService', 'ANAG_CARD_MANAGE', 'CORE_EVENT',
];


export function AngularCommunicationIssue() {
  // 'use strict';

  angular.module('angular-comm-card-controllers')
  /* .config(['eventServiceProvider', 'parallelRoutingProvider', (evProvider, pRouter) => {
    evProvider.registerEvent('angular-card-event', {type: 'card-event'});
    pRouter.when('ptfallFinder', 'pass', {
      template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
      controller: 'CardPassCtrl',
      label: 'pass'
    });
  }]) */
  .controller('AngularCommunicationCtrlIssue', AngularCommunicationCtrlIssue);

}

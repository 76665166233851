import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RoutableComponent} from '@rgi/rx/router';
import {RgiRxUserService} from '@rgi/rx/auth';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {ConsultationStartCardStateManagerService} from '../../state-managers/consultation-start-card-state-manager.service';
import {take} from 'rxjs/operators';
import {merge, of, Subscription} from 'rxjs';
import {ModalService, RgiRxOnContainerValueChange} from '@rgi/rx/ui';
import {StartCardFindDto} from '../../models/start-card-find-dto';
import {ConsultationStartCardState} from '../../state-managers/states/consultation-start-card-state';
import {CATEGORIES} from '../../constants/consultation-contract-branch-categories';
import {
  ConsultationStartCardActionModalComponent
} from './consultation-start-card-action-modal/consultation-start-card-action-modal.component';
import {
  ConsultationStartCardReportModalComponent
} from './consultation-start-card-report-modal/consultation-start-card-report-modal.component';
import {PolicyOperationPayload} from '../../models/policy-operations';
import {ACTIONS, COLLECTIVE, INDIVIDUAL} from '../../constants/consultation-enabled-features-visibility';
import {AnagFlowData} from '@rgi/anag';
import {ConsultationAnagSubjectBusinessService} from '../../services/consultation-anag-service';
import {RoleConstants} from '../../constants/role-constants';
import {CONTRACT_TYPES} from '../../constants/consultation-contract-types';
import {Entity} from '../../models/entity';
import {CategoriesFilterEnum} from '../../enumerations/categories-filter.enum';
import {ContractTypeEnum} from '../../enumerations/contract-type.enum';
import {ContractBranchEnum} from '../../enumerations/contract-branch.enum';
import {SubtypeEnum} from '../../enumerations/subtype.enum';
import {TypeEnum} from '../../enumerations/type.enum';
import {LimitationEnum} from '../../enumerations/limitation.enum';
import {
  ContractConsultationFieldConfigurationService
} from '../../services/contract-consultation-field-configuration.service';
import {
  FIELD, TABGROUP_CC_LIMITATIONS,FIELD_CC_TABMASTERPOLICY,FIELD_CC_TABAPPLICATION, FIELD_CC_TAB_NOT_ANONYMIZED,FIELD_CC_TAB_ANONYMIZED,
  FIELD_CC_BRANCHOFFICE,FIELD_CC_TABPC , FIELD_CC_TABLIFE, FIELD_CC_TABALL, FIELD_CC_TABINDIVIDUAL, FIELD_CC_TABCOLLECTIVE,
  TABGROUP_CC_BRANCH, FIELD_CC_COMPANY, FIELD_CC_CONTRACTCONDITION, FIELD_CC_CONTRACTSTATES, FIELD_CC_CONTRACTSUBSTATES, FIELD_CC_EFFECTIVEDATE, FIELD_CC_EXPIRATIONDATE,
  FIELD_CC_INDIPENDENTAGENT, FIELD_CC_ISSUEDATE, FIELD_CC_PRODUCT, FIELD_CC_PRODUCTTYPE, FIELD_CC_SUBJECT,
  TABGROUP_CC_SUBTYPE, FIELD_CC_TAB_CONTRACTTYPE, FIELD_CC_NUMBERPLATE, FIELD_CC_SHIPNAME,
  TABGROUP_CC_TYPE, FIELD_CC_POLICYNUMBER, FIELD_CC_PROPOSALNUMBER, FIELD_CC_APPLICATIONNUMBER, FIELD_CC_QUOTENUMBER
} from '../../constants/consultation-contract-card-fields';
import {validateInputContractNumber} from '../../validators/common.validators';

@Component({
  selector: 'consultation-start-card',
  templateUrl: './consultation-start-card.component.html',
  styleUrls: ['./consultation-start-card.component.scss']
})
export class ConsultationStartCardComponent extends RoutableComponent implements OnInit, OnDestroy {

  readonly ContractTypeEnum = ContractTypeEnum;
  readonly ContractBranchEnum = ContractBranchEnum;
  readonly SubtypeEnum = SubtypeEnum;
  readonly TypeEnum = TypeEnum;
  readonly LimitationEnum = LimitationEnum;

  subjectRoles: any = [];
  contractStates: Array<Entity> = [];
  contractSubstates: Array<Entity> = [];

  public readonly CONTRACT_TYPES = CONTRACT_TYPES;
  state: ConsultationStartCardState;
  startCardFormGroup: UntypedFormGroup;
  readonly roleKeys = RoleConstants;
  operatorLight: any;
  nodeDefault: any;
  independentAgentList: any;
  branchesList: any;
  productList: any;
  productTypeList = [];
  operationTypes: Array<string> = [];

  typeIndividualVisibility = false;
  typeCollectiveVisibility = false;
  actionButtonVisibility = false;
  typeDefault: any;
  sub = new Subscription();

  ISSUE_DATE_FIELD_CODE = '3';

  // Enums for enabling/disabling fields
  FIELD_CC_TABPC = FIELD_CC_TABPC;
  FIELD_CC_TABLIFE = FIELD_CC_TABLIFE;
  FIELD_CC_TABALL = FIELD_CC_TABALL;
  TABGROUP_CC_BRANCH = TABGROUP_CC_BRANCH;
  FIELD_CC_TABINDIVIDUAL = FIELD_CC_TABINDIVIDUAL;
  FIELD_CC_TABCOLLECTIVE = FIELD_CC_TABCOLLECTIVE;
  TABGROUP_CC_TYPE = TABGROUP_CC_TYPE;
  FIELD_CC_TABMASTERPOLICY = FIELD_CC_TABMASTERPOLICY;
  FIELD_CC_TABAPPLICATION = FIELD_CC_TABAPPLICATION;
  TABGROUP_CC_SUBTYPE = TABGROUP_CC_SUBTYPE;
  FIELD_CC_INDIPENDENTAGENT = FIELD_CC_INDIPENDENTAGENT;
  FIELD_CC_BRANCHOFFICE = FIELD_CC_BRANCHOFFICE;
  FIELD_CC_PRODUCTTYPE = FIELD_CC_PRODUCTTYPE;
  FIELD_CC_PRODUCT = FIELD_CC_PRODUCT;
  FIELD_CC_CONTRACTCONDITION = FIELD_CC_CONTRACTCONDITION;
  FIELD_CC_SUBJECT = FIELD_CC_SUBJECT;
  FIELD_CC_ISSUEDATE = FIELD_CC_ISSUEDATE;
  FIELD_CC_EFFECTIVEDATE = FIELD_CC_EFFECTIVEDATE;
  FIELD_CC_EXPIRATIONDATE  = FIELD_CC_EXPIRATIONDATE ;
  FIELD_CC_CONTRACTSTATES = FIELD_CC_CONTRACTSTATES;
  FIELD_CC_CONTRACTSUBSTATES = FIELD_CC_CONTRACTSUBSTATES;
  FIELD_CC_COMPANY = FIELD_CC_COMPANY;
  FIELD_CC_TAB_NOT_ANONYMIZED = FIELD_CC_TAB_NOT_ANONYMIZED;
  FIELD_CC_TAB_ANONYMIZED = FIELD_CC_TAB_ANONYMIZED;
  TABGROUP_CC_LIMITATIONS = TABGROUP_CC_LIMITATIONS;
  FIELD_CC_POLICYNUMBER = FIELD_CC_POLICYNUMBER;
  FIELD_CC_PROPOSALNUMBER = FIELD_CC_PROPOSALNUMBER;
  FIELD_CC_APPLICATIONNUMBER = FIELD_CC_APPLICATIONNUMBER;
  FIELD_CC_QUOTENUMBER = FIELD_CC_QUOTENUMBER;
  FIELD_CC_TAB_CONTRACTTYPE = FIELD_CC_TAB_CONTRACTTYPE;
  FIELD_CC_NUMBERPLATE = FIELD_CC_NUMBERPLATE;
  FIELD_CC_SHIPNAME = FIELD_CC_SHIPNAME;

  private readonly ENUM_SUBJECTROLE: string = 'ptfall.RuoloSoggetto';
  private readonly ENUM_CONTRACTSTATES : string = 'ptfall.StatiDiPolizza';
  private readonly ENUM_CONTRACTSUBSTATES : string = 'ptfall.Sottostato Movimento';
  private readonly enumCodeList = [this.ENUM_SUBJECTROLE, this.ENUM_CONTRACTSTATES, this.ENUM_CONTRACTSUBSTATES];


  constructor(
    public stateManager: ConsultationStartCardStateManagerService<ConsultationStartCardState>,
    private formBuilder: UntypedFormBuilder,
    private operatorService: RgiRxUserService,
    private errorMsgService: PushMessageHandlerService,
    protected subjectBusinessService: ConsultationAnagSubjectBusinessService,
    protected modalService: ModalService,
    protected contractConsultationFieldConfigurationService: ContractConsultationFieldConfigurationService
    ) {

    super();

    this.nodeDefault = this.operatorService.getUser<any>().salePoint;
    this.operatorLight = this.operatorService.getUser<any>();
    this.independentAgentList = this.operatorLight.salePoint.realPointOfSale.producers;
    this.branchesList =  this.operatorLight.salePoint.realPointOfSale.branches;

    if (this.operatorLight.enabledFeatures) {
      this.filterEnabledFeatures();
      this.typeDefault = this.typeIndividualVisibility && this.typeCollectiveVisibility || this.typeIndividualVisibility ?
        TypeEnum.INDIVIDUAL : this.typeCollectiveVisibility && !this.typeIndividualVisibility ? TypeEnum.COLLECTIVE : null;

    } else {
      // STANDALONE DEFAULTS
      this.typeDefault = TypeEnum.INDIVIDUAL;
      this.typeIndividualVisibility = true;
      this.typeCollectiveVisibility = true;
      this.actionButtonVisibility = true;
    }
    this.createForm();
  }

  ngOnInit() {
    this.initializeState();
    this.subscribeControls();
  }

  initializeState() {
    this.stateManager.getState$().pipe(take(1)).subscribe(state => {
      this.state = state;
      this.tickHostChange();
      this.stateManager.initAllData(state, this.nodeDefault.objectId, this.typeDefault,this.enumCodeList).subscribe(state1 => {
        if (state1.companyList && state1.companyList.length === 1) {
          this.startCardFormGroup.patchValue({
            company: state1.companyList[0]
          });
        }
        if (state1.nodeList && state1.nodeList.length === 1) {
          state1.selectedNode = state1.nodeList[0];
          this.startCardFormGroup.patchValue({
            salePoint: state1.selectedNode
          });

        }
        if (this.nodeDefault && state1.nodeList && state1.nodeList.length > 0) {
          state1.nodeList.forEach(node => {
            if (node.code === this.nodeDefault.code) {
              this.startCardFormGroup.patchValue({
                salePoint: node
              });
            }
          });
        }
        if (state1.enumValues) {
          const enumValuesSubjRole = state1.enumValues.find(enumVal => enumVal.code == this.ENUM_SUBJECTROLE );
          enumValuesSubjRole?.values.forEach(this.pushToList.call(this, this.subjectRoles ));
          const enumValuesContractStates = state1.enumValues.find(enumVal => enumVal.code == this.ENUM_CONTRACTSTATES );
          enumValuesContractStates?.values.forEach(this.pushToList.call(this, this.contractStates ));
          const enumValuesContractSubStates = state1.enumValues.find(enumVal => enumVal.code == this.ENUM_CONTRACTSUBSTATES );
          enumValuesContractSubStates?.values.forEach(this.pushToList.call(this, this.contractSubstates ));
        }
        const isDefaultIndividual: boolean = this.typeDefault === TypeEnum.INDIVIDUAL;
        this.productTypeList = isDefaultIndividual ? [...state1.productTypeListIndividual] : [...state1.productTypeListCollective]  ;
        this.productList = isDefaultIndividual ? [...state1.productListIndividual] : [...state1.productListCollective];
        this.stateManager.updateState$(of(state1));
      });
    });
  }

  private pushToList(list: Array<Entity>) {
    return enumCode => {
      let enumType: Entity = new Entity();
      enumType.code = enumCode.codice;
      enumType.description = enumCode.descrizione;
      list.push(enumType);
    };
  }

  getSubjectDropContainerData(role) {
    const data = new AnagFlowData();
    data.partyRole = '' + role;
    const formValues = this.startCardFormGroup.value;
    if (formValues.salePoint) {
      data.nodeCode = formValues.salePoint.code;
    }
    return data;
  }
  createForm() {
    this.startCardFormGroup = this.formBuilder.group(
      {
        contractBranch: [ContractBranchEnum.ALL, ],
        type: [this.typeDefault, ],
        subtype: [SubtypeEnum.MASTER_POLICY, ],
        limitation: [LimitationEnum.NOT_ANONYMIZED, ],
        salePoint: ['', Validators.required],
        subnet: [{value: true, disabled: true}, ],
        wholeSalesNetwork: [{value: false, disabled: false}, ],
        company: [null, ],
        independentAgent: [null, ],
        branchOffice: [null, ],
        contract: [null, ],
        contractType: [ContractTypeEnum.POLICIES_OR_PROPOSALS, Validators.required],
        proposalNumber: [null, ],
        policyNumber: [null, ],
        quoteNumber: [null, ],
        applicationNumber: [null, ],
        productType: [null, ],
        product: [null, ],
        plateNumber: [null, ],
        // plateNumber: [null, PlateValidator.bind(this)],
        imoShipName: [null, ],
        condition: ['false', ],
        subject: [null, ],
        subjectRoles: [null, ],
        issueDate: [null, ],
        effectiveDate: [null,],
        expirationDate: [null,],
        contractState: [null,],
        contractSubstate: [null,],
      }
    );
  }

  findPolicy() {
    this.errorMsgService.clear();
    if (this.startCardFormGroup.value.wholeSalesNetwork &&
      !this.startCardFormGroup.value.policyNumber &&
      !this.startCardFormGroup.value.proposalNumber) {
      this.notifyError('CONTRACT_CONSULTATION.VALORIZE_NUMBER', 'danger');
      return;
    }
    const body = this.buildFilterPolicy();
    this.stateManager.findPolicies(body).subscribe(result => {
      if (result.policyRows.length) {
        this.stateManager.goToNextPage(result,this.operatorLight.username);
      } else {
        this.notifyError('CONTRACT_CONSULTATION.NO_RESULTS', 'default');
      }
    });
  }

  buildFilterPolicy(){
    const body: StartCardFindDto = new StartCardFindDto();
    const formValues = this.startCardFormGroup.value;
    if (formValues.branchOffice) {
      body.branchId = formValues.branchOffice.objectId;
    }
    if (formValues.company) {
      body.companyId = formValues.company.id;
      body.companyCode = formValues.company.code;
    }
    body.wholeSalesNetwork = formValues.wholeSalesNetwork;
    body.contractTodayEffectDate = formValues.condition;
    body.categories = this.filterCategories();
    if (formValues.contractType) {
      body.contractType = new Entity();
      body.contractType.code = formValues.contractType;
    }
    if (formValues.independentAgent) {
      body.independentAgentId = formValues.independentAgent.identification;
    }
    if (formValues.limitation && formValues.limitation !== LimitationEnum.NOT_ANONYMIZED) {
      body.oblivionType = new Entity();
      body.oblivionType.code = formValues.limitation;
    }
    if (formValues.policyNumber && formValues.policyNumber !== '') {
      body.policyNumber = validateInputContractNumber(formValues.policyNumber);
    } else if (formValues.proposalNumber && formValues.proposalNumber !== '') {
      body.proposalNumber = validateInputContractNumber(formValues.proposalNumber);
    }
    if (formValues.product) {
      body.productCode = formValues.product.product.code;
      body.productId = formValues.product.product.identification;
    }
    if (formValues.productType) {
      body.productTypeCode = formValues.productType.code;
      body.productTypeId = formValues.productType.identification;
    }
    if (formValues.plateNumber && formValues.plateNumber !== '') {
      body.plateNumber = formValues.plateNumber; //TODO validate
    }

    if (formValues.imoShipName && formValues.imoShipName !== '') {
      body.imoShipName = formValues.imoShipName;   //TODO validate
    }

    if (formValues.quoteNumber && formValues.quoteNumber !== '') {
      body.quoteNumber = validateInputContractNumber(formValues.quoteNumber);
    }
    if (formValues.applicationNumber && formValues.applicationNumber !== '') {
      body.applicationNumber = validateInputContractNumber(formValues.applicationNumber);
    }

    if (formValues.salePoint) {
      body.salePointId = formValues.salePoint.idSp;
      body.salePointCode = formValues.salePoint.code;
    }
    if (formValues.subject) {
      body.subjectObjectId = formValues.subject.objectId;
    }

    if(formValues.subjectRoles){
      body.subjectRoles = formValues.subjectRoles;
    }

    if(formValues.issueDate){
      body.issueDateFrom = formValues.issueDate[0] ? new Date(formValues.issueDate[0]): null;
      body.issueDateTo = formValues.issueDate[1] ? new Date(formValues.issueDate[1]) : null;
    }
    if(formValues.effectiveDate){
      body.effectDateFrom = formValues.effectiveDate[0] ? new Date(formValues.effectiveDate[0]) : null;
      body.effectDateTo = formValues.effectiveDate[1] ? new Date(formValues.effectiveDate[1]): null;
    }
    if(formValues.expirationDate){
      body.expirationDateFrom = formValues.expirationDate[0] ? new Date(formValues.expirationDate[0]) : null;
      body.expirationDateTo = formValues.expirationDate[1] ? new Date(formValues.expirationDate[1]) : null;
    }
    if(formValues.contractState){
      body.states = formValues.contractState;
    }
    if(formValues.contractSubstate){
      body.subStates = formValues.contractSubstate;
    }

    body.policyType = new Entity();
    body.policyType.code = formValues.type;

    if (formValues.type === TypeEnum.COLLECTIVE && formValues.subtype) {
      body.policyType.code = formValues.subtype;
    }

    body.sortType = new Entity();
    body.sortType.code = this.ISSUE_DATE_FIELD_CODE;

    return body;
  }

  filterCategories() {
    switch (this.startCardFormGroup.value.contractBranch) {
      case ContractBranchEnum.ALL: {
        return CATEGORIES;
      }
      case ContractBranchEnum.PC: {
        return CATEGORIES.filter(x => {
          return x.code !== CategoriesFilterEnum.LIFE;
        });
      }
      case ContractBranchEnum.LIFE: {
        return CATEGORIES.filter(x => {
          return x.code === CategoriesFilterEnum.LIFE;
        });
      }
    }
  }
  filterEnabledFeatures() {
    INDIVIDUAL.forEach(code => {
      if (this.operatorLight.enabledFeatures.indexOf(code) >= 0) {
        this.typeIndividualVisibility = true;
      }
    });
    COLLECTIVE.forEach(code => {
      if (this.operatorLight.enabledFeatures.indexOf(code) >= 0) {
        this.typeCollectiveVisibility = true;
      }
    });
    ACTIONS.forEach(code => {
      if (this.operatorLight.enabledFeatures.indexOf(code) >= 0) {
        this.actionButtonVisibility = true;
      }
    });
  }
  emptyForm() {
    this.createForm();
    this.subscribeControls();
    this.errorMsgService.clear();
  }
  openReportModal() {
    const {modal} = this.modalService.openComponent(ConsultationStartCardReportModalComponent);
    modal.onClose.subscribe((extension: string) => {
      if(extension) {
        this.stateManager.createReport(extension, this.buildFilterPolicy());
      }
    });
  }
  openActionsModal() {
    const proposal = this.startCardFormGroup.value.proposalNumber;
    const policy = this.startCardFormGroup.value.policyNumber;
    this.errorMsgService.clear();
    if (!policy && !proposal) {
      this.notifyError('CONTRACT_CONSULTATION.VALORIZE_NUMBER', 'danger');
      return;
    }
    const contractType = policy ? ContractTypeEnum.POLICIES : proposal ? ContractTypeEnum.PROPOSALS : null;

    const body = new PolicyOperationPayload();

    body.userCode = this.operatorLight.username;
    body.productionNodeCode = this.operatorLight.salePoint.code;
    body.authenticationNodeCode = this.operatorLight.salePoint.code;
    body.objectId = {
      code: policy ? policy : proposal,
      description: contractType
    };


    this.stateManager.getPolicyOperations(body).subscribe(response => {
      this.operationTypes = []
      response.operations.forEach(operation => {
        if (!this.operationTypes.find(type => {
          return type === operation.type;
        })) {
          this.operationTypes.push(operation.type);
        }
      });
      if (this.operationTypes.length) {
        const {modal, component} = this.modalService.openComponent(ConsultationStartCardActionModalComponent);
        component.operations = response.operations;
        component.operationTypes = this.operationTypes;
      } else {
        this.notifyError('CONTRACT_CONSULTATION.NO_ACTIONS', 'default');
      }

    });
  }

  anagSubjectSelected(val: RgiRxOnContainerValueChange) {
    this.subjectBusinessService.getSubject(val.changed.objectId, val.changed.node.identification).subscribe(subj => {});
  }

  removeSubjectFormData() {
    this.startCardFormGroup.get('SubjectRole').setValue(null);
  }

  filterProductsByProductType(productType: any) {
    if (productType) {
      this.productList = this.productList.filter(prod => {
        return prod.productType.code === productType.code;
      });
    }
    this.startCardFormGroup.get('product').setValue(null);
  }

  filterProductsByContractBranch(contractBranch: ContractBranchEnum) {
    this.productTypeList = this.productTypeList.filter(productTypeList => {
      switch (contractBranch) {
        case ContractBranchEnum.ALL: return true;
        case ContractBranchEnum.PC: return productTypeList.policyCategory.codice !== CategoriesFilterEnum.LIFE;
        case ContractBranchEnum.LIFE: return productTypeList.policyCategory.codice === CategoriesFilterEnum.LIFE;
      }
    });
    this.productList = this.productList.filter(product => {
      switch (contractBranch) {
        case ContractBranchEnum.ALL: return true;
        case ContractBranchEnum.PC: return product.policyCategory.codice !== CategoriesFilterEnum.LIFE;
        case ContractBranchEnum.LIFE: return product.policyCategory.codice === CategoriesFilterEnum.LIFE;
      }
    });
  }

  subscribeControls() {
    const formSub =   this.startCardFormGroup.valueChanges.subscribe(val => {
      this.errorMsgService.clear();
      if (!this.startCardFormGroup.value.salePoint) {
        this.notifyError( 'CONTRACT_CONSULTATION.FIELDS_MARKED_IN_RED', 'danger');
      }
      if (!this.startCardFormGroup.value.contractType) {
        this.notifyError( 'CONTRACT_CONSULTATION.FIELDS_MARKED_IN_RED', 'danger');
      }
    });

    merge(
      this.startCardFormGroup.controls.type.valueChanges,
      this.startCardFormGroup.controls.limitation.valueChanges
    ).subscribe(() => {
      this.startCardFormGroup.get('subject').setValue(null);
    });

    const productsSub = merge(
      this.startCardFormGroup.controls.productType.valueChanges,
      this.startCardFormGroup.controls.contractBranch.valueChanges,
      this.startCardFormGroup.controls.type.valueChanges
    ).subscribe(() => {
      this.manageProductList(this.stateManager.getCurrentState(),
        this.startCardFormGroup.controls.type.value === TypeEnum.COLLECTIVE); // Get Collective or Individual products
      this.filterProductsByContractBranch(this.startCardFormGroup.controls.contractBranch.value); // Filter by ALL or PC or LIFE
      this.filterProductsByProductType(this.startCardFormGroup.controls.productType.value); // Filter by product type
    });

    this.sub.add(formSub);
    this.sub.add(productsSub);
  }

  manageProductList(state: any, isCollective: boolean) {
    if (isCollective && !state.productListCollective.length) {
      this.stateManager.getProducts(this.state, this.nodeDefault.objectId, TypeEnum.COLLECTIVE).subscribe(res => {
        this.productTypeList = state.productTypeListCollective = res.productsType;
        this.productList = state.productListCollective = res.products;
      });
    } else if (!isCollective && !state.productListIndividual.length) {
      this.stateManager.getProducts(this.state, this.nodeDefault.objectId, TypeEnum.INDIVIDUAL).subscribe(res => {
        this.productTypeList = state.productTypeListIndividual = res.productsType;
        this.productList = state.productListIndividual = res.products;
      });
    } else {
      this.productTypeList = isCollective ? state.productTypeListCollective : state.productTypeListIndividual;
      this.productList = isCollective ? state.productListCollective : state.productListIndividual;
    }
  }
  notifyError(content: string, status: string) {
      const msg: RgiRxPushMessage = new RgiRxPushMessage();
      const opts = {icon: 'rgi-ui-icon-alert'};
      msg.tag = 'consultationErrorMessage';
      msg.status = status;
      msg.content = content;
      msg.dismissible = false;
      msg.options = opts;
      this.errorMsgService.notify(msg);
  }

  isPolicyNumVisible() {
    return (this.startCardFormGroup.value.contractType !== ContractTypeEnum.PROPOSALS
      && (this.startCardFormGroup.value.limitation !== LimitationEnum.ANONYMIZED
        && this.startCardFormGroup.value.contractType !== ContractTypeEnum.QUOTE_W_REG_PARTY
        && this.startCardFormGroup.value.contractType !== ContractTypeEnum.ANONYMOUS_QUOTE)
      || (this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE
        && (this.startCardFormGroup.value.contractType === ContractTypeEnum.QUOTE_W_REG_PARTY
          || this.startCardFormGroup.value.contractType === ContractTypeEnum.ANONYMOUS_QUOTE
          || this.startCardFormGroup.value.contractType === ContractTypeEnum.PROPOSALS)))
      &&
      (!(this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE &&
        this.startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION &&
        this.startCardFormGroup.value.contractType === ContractTypeEnum.POLICIES))
        &&
      (!(this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE &&
        this.startCardFormGroup.value.subtype === SubtypeEnum.MASTER_POLICY &&
        this.startCardFormGroup.value.contractType === ContractTypeEnum.PROPOSALS));
  }

  isMasterPolicyVisible() {
    return this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE &&
      (!(this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE &&
        this.startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION &&
        this.startCardFormGroup.value.contractType === ContractTypeEnum.POLICIES)) &&
      (!(this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE &&
        this.startCardFormGroup.value.subtype === SubtypeEnum.MASTER_POLICY &&
        this.startCardFormGroup.value.contractType === ContractTypeEnum.PROPOSALS));
  }

  isProposalNumVisible() {
    return this.startCardFormGroup.value.limitation === LimitationEnum.NOT_ANONYMIZED
        && this.startCardFormGroup.value.contractType !== ContractTypeEnum.POLICIES
        && (((this.startCardFormGroup.value.type === TypeEnum.INDIVIDUAL
              || (this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE
                  && this.startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION))
              && this.startCardFormGroup.value.contractType !== ContractTypeEnum.QUOTE_W_REG_PARTY
              && this.startCardFormGroup.value.contractType !== ContractTypeEnum.ANONYMOUS_QUOTE)
          || (this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE
            && this.startCardFormGroup.value.subtype === SubtypeEnum.MASTER_POLICY));
  }
  isProductVisible() {
    return !(this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE
        && this.startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION)
      || this.startCardFormGroup.value.type !== TypeEnum.COLLECTIVE
      && this.startCardFormGroup.value.subtype !== SubtypeEnum.APPLICATION
      && this.startCardFormGroup.value.limitation !== LimitationEnum.ANONYMIZED;
  }

  isApplicationNumberVisible() {
   return this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE
     && this.startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION
     && this.startCardFormGroup.value.contractType !== ContractTypeEnum.PROPOSALS
     && this.startCardFormGroup.value.contractType !== ContractTypeEnum.QUOTE_W_REG_PARTY
     && this.startCardFormGroup.value.contractType !== ContractTypeEnum.ANONYMOUS_QUOTE;
  }

  isQuoteNumberVisible() {
    return (this.startCardFormGroup.value.type === TypeEnum.INDIVIDUAL
          || (this.startCardFormGroup.value.type === TypeEnum.COLLECTIVE &&
              this.startCardFormGroup.value.subtype === SubtypeEnum.APPLICATION))
      && (this.startCardFormGroup.value.contractType === ContractTypeEnum.QUOTE_W_REG_PARTY
          || this.startCardFormGroup.value.contractType === ContractTypeEnum.ANONYMOUS_QUOTE);
  }

  resetNumberValues() {
    this.startCardFormGroup.get('proposalNumber').setValue(null);
    this.startCardFormGroup.get('policyNumber').setValue(null);
    this.startCardFormGroup.get('quoteNumber').setValue(null);
  }

  resetProductFiled() {
    this.startCardFormGroup.get('productType').setValue(null);
    this.startCardFormGroup.get('product').setValue(null);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  // return if the field is set to visible on the customization of the card set by the user
  isFieldVisible(fieldToSearch: FIELD): boolean {
    let visible = true;
    if (this.contractConsultationFieldConfigurationService.getFields(this.id)) {
      let fieldOnCard = this.contractConsultationFieldConfigurationService.getFields(this.id).find(field => field.name === fieldToSearch.name);
      if (fieldOnCard?.visible === 0) {
        visible = false;
      }
    }
    return visible;
  }

}

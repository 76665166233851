import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiTranslatorPolicyService, Policy, PolicyService } from '@rgi/digital-claims-common-angular';
import { RgiRxDataTableDataSource, RgiRxDatasource } from '@rgi/rx/ui';
import { DscPolicyDetailComponent } from './dsc-policy-detail/dsc-policy-detail.component';
import { ModalService } from '@rgi/rx/ui';
import { CategoryList } from './dsc-policy-list.config';
import { InsurancePolicy } from '../dsc-shared-model/dsc-model-policy';

@Component({
  selector: 'claims-dsc-policy-list',
  templateUrl: './dsc-policy-list.component.html',
  styleUrls: ['./dsc-policy-list.component.scss'],
})
export class DscPolicyListComponent implements OnInit {

  @Component({
    selector: 'claims-dsc-policy-list',
    templateUrl: './dsc-policy-list.component.html',
    styleUrls: ['./dsc-policy-list.component.scss']
  })

  @Input() policyList: InsurancePolicy[];
  @Input() selectedCategories: CategoryList[] = [];
  @Input() showTitle: boolean = false;
  @Output() searchSimilarClaims: EventEmitter<InsurancePolicy> = new EventEmitter<InsurancePolicy>();
  displayedColumnsPolicy: string[] = ['number', 'description', 'holder',
    'originalEffectDate', 'expirationDate', 'cancellationDate', 'units', 'buttons'];

  constructor(
    private policyService: PolicyService,
    private translatorService: ApiTranslatorPolicyService,
    private modalServiceRgi: ModalService
  ) { }
  datasourcePolicyList: any;
  externalPortfolio: boolean = false;

  ngOnInit() {
    this.initObjectForm();

  }

  initObjectForm() {
    /* this.datasourcePolicyList  = this.policyList ? this.policyList : []; */
    this.datasourcePolicyList = new RgiRxDataTableDataSource(this.policyList);
  }

  numberOfRow(): boolean {
    return true;
  }

  isPolicyCanceled(policy: any): boolean {
    return policy.isCanceled;
  }
  isPolicyEnabled(policy: InsurancePolicy): boolean {
    let interserction = false;
    const codesInCategory: CategoryList[] = this.selectedCategories.filter((res: CategoryList) => res.codice === '1');
    if (codesInCategory.length > 0) {
      interserction = policy.category.codice === codesInCategory[0].codice;
    }
    return interserction;
  }
  selectPolicyDetail(policy: InsurancePolicy) {
    this.searchSimilarClaims.emit(policy);
  }

  policyDetails(policyFromList: InsurancePolicy) {
    const req = {
      filterPolicies: null
    };

    this.policyService.getPolicyDetail(Number(policyFromList.objectId))
      .subscribe((response: any) => {
        const contractRes = response.contract;
        if (contractRes) {

          const policyDetail = this.translatorService.apiPolicyToDtoPolicy(contractRes);
          this.modalServiceRgi.open(DscPolicyDetailComponent, {
            policy: policyDetail,
            contract: contractRes,
          }).onClose.subscribe(res => {
            // onclose modal
          });
        }
      },
        (error: Error) => {
          // console.log(error);
        }
      );
  }
}

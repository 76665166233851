import { EventInstance, EventType } from "@rgi/rx";
import { State } from "@rgi/rx/state";
import { FormSearch, SearchWrapperObject } from "./dialogue-handler-search.model";
import { ClaimModel, DialogueHandlerListResponse } from "./dialogue-handler-list.model";
import { NewDialogueRequest, NewWrapperObject, ResponseObject, ResponseSaveObject } from "./new-dialogue.model";
import { CausalResponse, DettaglioSinistroDenuncia, DialogueSummaryObject, MessageList, OutcomeService, SummaryForm } from "./dh-summary.model";
export const CALLBACK_JUMP: EventType = { eventName: 'CALLBACK_JUMP' };
export class GenericHandlerComboList {
  id: string;
  description: string
}

export class ResponseFromSearchState extends State{
  formSearch: FormSearch = new FormSearch();
  responseFromSearchList: DialogueHandlerListResponse[] = [];
  listFromClaim: DialogueHandlerListResponse[][] = [];
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  newWrapperObject?: NewWrapperObject = new NewWrapperObject();
  viewConfig: string;
  claim: ClaimModel = new ClaimModel();
  claimSession: any;
  idParentSession?: string;
  checkList?: string = null;
}

export class DetailObjectToList {
  formSearch: FormSearch = new FormSearch();
  responseFromSearchList: DialogueHandlerListResponse[] = [];
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  newWrapperObject?: NewWrapperObject = new NewWrapperObject();
  viewConfig: string;
  idParentSession?: string;
  idClnotifcard?: number;
}

export class DialogueHandlerSearch extends State {
  formSearch: FormSearch = new FormSearch();
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  viewConfig: string;
  errorMessage: string;
  idParentSession?: string;
}


export class NewDialogueState extends State {
  newDialogueRequest: NewDialogueRequest = new NewDialogueRequest (); //è il form della NewDialogue
  // wrapperNewDialogueObject: WrapperNewDialogueObject = new WrapperNewDialogueObject();
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  newWrapperObject: NewWrapperObject = new NewWrapperObject();
  dialogueSummaryObject: DialogueSummaryObject = new DialogueSummaryObject();
  // responseSaveObject: Etype = new Etype();
  outcomeService: ResponseSaveObject = new ResponseSaveObject();
  responseObject: ResponseObject = new ResponseObject();
  messageList: MessageList = new MessageList();
  viewConfig: string;
  claimNumberPass: string;
  list:  DialogueHandlerListResponse = new DialogueHandlerListResponse();
  idParentSession?: string;
}

//oggetto comune a tutti i casi di popolamento per la NEW
export class ObjectToNew{
  newDialogueRequest: NewDialogueRequest = new NewDialogueRequest ();
  // wrapperNewDialogueObject: WrapperNewDialogueObject = new WrapperNewDialogueObject();
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  newWrapperObject: NewWrapperObject = new NewWrapperObject();
  viewConfig: string;
  claimNumberPass: string;
  idParentSession?: string;
}

export class DetailObjectToNew{
  // wrapperNewDialogueObject: WrapperNewDialogueObject = new WrapperNewDialogueObject();
  newWrapperObject: NewWrapperObject = new NewWrapperObject();
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  viewConfig: string;
  idParentSession?: string;
}

export class ObjectToResp{
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  responseObject: ResponseObject = new ResponseObject();
  dialogueSummaryObject: DialogueSummaryObject = new DialogueSummaryObject();
  viewConfig: string;
  list:  DialogueHandlerListResponse = new DialogueHandlerListResponse();
  messageList: MessageList = new MessageList();
  idParentSession?: string;
}


export class SummaryState extends State {
  dialogueSummaryObject: DialogueSummaryObject = new DialogueSummaryObject();
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  responseFromSearchList: DialogueHandlerListResponse = new DialogueHandlerListResponse();
  dettaglioSinistroDenuncia: DettaglioSinistroDenuncia = new DettaglioSinistroDenuncia();
  summaryForm: SummaryForm = new SummaryForm();
  outcomeService: OutcomeService = new OutcomeService();
  causalResponse: CausalResponse = new CausalResponse();
  viewConfig: string;
  reassociationPossible: boolean;
  idParentSession?: string = null;
  idClnotifcard?: number;
  dialogueList?: DialogueHandlerListResponse[] = [];
  checkList?: string = null;
  formSearch?: FormSearch = new FormSearch();
}

export class ListObjectToSummary{
  dialogueSummaryObject: DialogueSummaryObject = new DialogueSummaryObject();
  searchWrapperObject: SearchWrapperObject = new SearchWrapperObject();
  responseFromSearchList: DialogueHandlerListResponse = new DialogueHandlerListResponse();
  viewConfig: string;
  idParentSession?: string = null;
  outcomeService?: OutcomeService;
  idClnotifcard?: number;
  dialogueList?: DialogueHandlerListResponse[] = [];
  checkList?: string = null;
  formSearch?: FormSearch = new FormSearch();
}



export interface CallBackEvent extends EventType {
    idSession?: string;
}

export class CallBackJumpInstance implements EventInstance<CallBackEvent> {
    event: CallBackEvent;
    constructor(idSession?: string) {
      this.event = {
        eventName: CALLBACK_JUMP.eventName,
        idSession
      };
    }
  }


import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Authority } from '../../dto/authority/authority';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthoritiesService {

  private baseApiUrl: string;
  private serviceInvolvedAuthoritiesUrl = '/claims/involvedauthorities';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  getInvolvedAuthorities(): Observable<Authority[]> {
    return this.httpClient.get(this.baseApiUrl + this.serviceInvolvedAuthoritiesUrl).pipe(map(this.getAuthoritiesMapFunction));
  }

  private getAuthoritiesMapFunction(result): Authority[] {
    let respAuthorities: Authority[] = [];
    respAuthorities = result.involvedAuthorities.sort((a: Authority, b: Authority) => {
      return a.descriptionAuthority.localeCompare(b.descriptionAuthority);
    });
    const noAuth = respAuthorities.filter(x => x.codeAuthority === '99');
    const noAuthExcluded = respAuthorities.filter(x => x.codeAuthority !== '99');
    return noAuth.concat(noAuthExcluded);
  }

}

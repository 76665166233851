import {ModalService, RgiRxDropContainerSelectData, RgiRxOnSelectNode} from '@rgi/rx/ui';
import {Observable, Subject} from 'rxjs';
import {AnagModalComponent} from '../anag-components/modal/anag-modal.component';
import {Injectable} from '@angular/core';
import {AnagRouteData} from '../anag-model/anag-domain/anag-route-data';
import {tap} from 'rxjs/operators';
import {SUBJECT_SELECTED} from '../anag-events/anag-event';
import {AnagFlowData} from '../anag-model/anag-domain/anag-flow-data';

@Injectable({
  providedIn: 'root'
})
export class AnagDropSearchResolver implements RgiRxOnSelectNode<any, any, any> {

  constructor(
    private modalService: ModalService
  ) {
  }

  onSelect$(model: RgiRxDropContainerSelectData<any, AnagFlowData>): Observable<any> {
    const observable = new Subject();
    const routeData = new AnagRouteData();
    routeData.flowData = model ? model.selectData : undefined;
    routeData.isSearchFlow = true;
    routeData.isEditFlow = true;
    const modal = this.modalService.openComponent(AnagModalComponent, routeData);
    modal.modal.onClose.pipe(tap(onCloseData => {
      if (onCloseData && onCloseData.event) {
        const event = onCloseData.event;
        switch (event.eventName) {
          case SUBJECT_SELECTED.eventName: {
            observable.next(event.routeData.party);
            break;
          }
          default:
            break;
        }
      }
    })).subscribe();
    return observable;
  }
}

import { ClaimDetail } from '../../shared/dsc-shared-model/dsc-model-claims';

export class GeneratedClaimDetail {
    claim: ClaimDetail = null;
    resourceId: string = '';
    serviceInfo: any = null;
}

export interface EavObject {
    SUBJECT_EXTENSION_ROOT: any;
}

export interface ExtensionSubjectProperty {
    chiave: string;
    valore: string;
}

export interface ExtensionSubject {
    properties: ExtensionSubjectProperty[];
}

export interface NewClaim {
    newClaimSaveInput: NewClaimSaveInput;
}

export interface NewClaimSaveInput {
    chcCode: string;
    claim: ClaimDetail;
    draft: boolean;
    temporary: boolean;
}

export interface OutputSetClaim {
    openClaim: boolean;
    claim: any;
}

export class LicErrorsUtils {

    public static isObject(item: any): boolean {
      return item && typeof item === 'object' && !Array.isArray(item);
    }

    public static getPreviousPageMessages(messageFromPreviousPage: any[], type: string): { message: string, type: string}[] {
        const messages = [];
        if (!!messageFromPreviousPage && messageFromPreviousPage.length > 0) {
            messageFromPreviousPage.forEach(m => {
                if (m.type === type) {
                messages.push({ message: m.message, type});
                }
            });
        }
        return messages;
    }

  }

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RoutableComponent } from '../routable-component';
import { LicCacheService } from '../services/lic-cache.service';
import { PolicyService } from '../services/policy-service';
import { CardsNavigationService } from './cards-navigation.service';

@Component({
    selector: 'lic-navigation',
    templateUrl: './cards-navigation.component.html',
    styleUrls: ['./cards-navigation.component.scss']
})
export class CardsNavigationComponent implements OnInit, RoutableComponent {
    @Output() navigation = new EventEmitter<string>();
    public isResponsive = false;
    public routes: any[];
    public INNER_WIDTH = 768;
    public lastPageName: string;

    constructor(
      protected cardsNavigationService: CardsNavigationService,
      protected policyService: PolicyService,
      protected cache: LicCacheService
    ) {}

    get currentRouteIndex() {
      return this.routes.findIndex(r => !!r.current);
    }

    get currentRouteName() {
      const current = this.routes.find(r => !!r.current);
      return !!current ? current.name : '';
    }

    isLastStep(index) {
      return !!this.routes && index === this.routes.length - 1;
    }

    // indica se è lo step precedente (non disabilitato) a quello attivo
    displayPreviousStep(stepName) {
      let previousStepToShow = null;
      for (let i = this.currentRouteIndex - 1; i >= 0; i--) {
        if (!this.routes[i].blocked && !previousStepToShow) {
          previousStepToShow =  this.routes[i].name;
        }
      }
      return previousStepToShow === stepName;
    }

    ngOnInit(): void {
      this.isResponsive = window.innerWidth <= this.INNER_WIDTH;
      this.routes = this.cardsNavigationService.steps;
      this.lastPageName = this.cardsNavigationService.STEP.ISSUE.route;
      this.cardsNavigationService.currentCards.subscribe((updatedSteps: any) => {
        this.routes = updatedSteps; // mantengo gli step aggiornati dal service
      });
    }

    // azione al click su un step precedente
    public navigateTo(route: string) {
      const stepIndex = this.routes.findIndex(step => step.name === route);
      // se non sono in issue && non clicco lo step corrente o successivo && se lo step non è bloccato
      if (this.currentRouteName !== this.lastPageName && this.currentRouteIndex > stepIndex && !this.routes[stepIndex].blocked) {
        this.doSomethingBeforeEmitNavigationEvent(stepIndex);
        this.navigation.emit(route);
        this.cardsNavigationService.setCurrentCards(route);
      }
    }

    public doSomethingBeforeEmitNavigationEvent(stepIndex: number) {
      // se torno alle pagine precedenti agli investimenti devo svuotare la cache con gli investimenti selezionati
      /* const investmentsIndex = this.routes.findIndex(step => step.name === 'investments');
      if (investmentsIndex > stepIndex) {
        this.cache.deleteSession(this.policyService.mainProposalResourceId);
      } */
    }

    public onResize(event) { this.isResponsive = event.target.innerWidth <= this.INNER_WIDTH; }

    public isEnabled(state: number) { return state === 1; }

}

<div class="full-history-container">
    <div class="history-container disable-scrollbars col-md-12" [ngClass]="{'history-container-subcard': (claim || potentialClaim) && !blogMode,
                    'history-container-blog': blogMode }" #scrollMe [scrollTop]="scrollMe.scrollHeight">
        <div *ngFor="let message of messages">
            <div class="first-of-the-day" *ngIf="message.isFirstOfTheDay">
                <span>{{message.sendDate | date: 'dd/MM/yyyy' }}</span>
            </div>
            <div class="chat-history-container">
                <div class="column" [ngClass]="{'right': message.myMessage, 'left': !message.myMessage}">
                    <div class="message" [ngClass]="{'right': message.myMessage, 'left': !message.myMessage}">
                        <div class="big col-sm-12" *ngIf="blogMode && !message.myMessage">
                            <span class="rgifont rgi-user"></span>&nbsp;{{message.otherUser.userName}}
                        </div>
                        <div class="big col-sm-12">
                            {{message.subject}}
                        </div>
                        <div *ngIf="message.claimNumber && !claim" class="big col-sm-12">
                            <span>{{ '_CLAIMS_._CLAIM' | translate }}</span>:
                            <span class="drag-element card-color-blue card-draggable">{{message.claimNumber}}</span>
                        </div>
                        <div *ngIf="!message.claimNumber && message.potentialClaimNumber && !potentialClaim && !claim"
                            class="big col-sm-12">
                            <span>{{ '_CLAIMS_._POTENTIAL_CLAIM_INQUIRY' | translate }}</span>:
                            {{message.potentialClaimNumber}}
                        </div>
                        <div class="body col-sm-12">
                            {{ message.body }}
                        </div>
                        <div class="date">
                            {{ message.sendDate | date: 'HH:mm'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <claims-chat-new-message *ngIf="!blogMode" [recipient]="otherUser" [senderName]="loggedUser" [claim]="claim"
        [potentialClaim]="potentialClaim" (eventSent)="onMessageSent()">
    </claims-chat-new-message>
</div>
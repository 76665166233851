<ng-container *rgiRxDecorable="'survey-eval-header-item'; context: {st: st}"></ng-container>

<div class="ppevo-survey-title">
    <i class="ppevo-icon-sitemap"> </i>
    <span translate>NG_PASSPROPRO_SURVEY.productresult</span>
</div>

<!-- Per questo sarebbe carino fare un componente invece di usare questo... il fatto è che ppevo-si-no ha dentro i valori "cablati" SI/NO -->
<ng-container *ngIf="st.productsCfg.hasVisibleProds">

    <div *ngIf="st.selectGroupEnabled" class="ppevo-lobgroup-mode-selector">
        <div class="ppevo-multivalue-box">
            <div (click)="toggleGroupingByLob(true)" [class.active]="st.groupProductsByLob"
                class="ppevo-value ppevo-value-yes" translate>NG_PASSPROPRO_SURVEY.group-lob</div>
        </div>
        <div class="ppevo-multivalue-box">
            <div (click)="toggleGroupingByLob(false)" [class.active]="!st.groupProductsByLob"
                class="ppevo-value ppevo-value-no" translate>NG_PASSPROPRO_SURVEY.group-product</div>
        </div>
    </div>

    <div class="ppevo-survey-price-box">
        <span class="ppevo-label" translate>NG_PASSPROPRO_SURVEY.total-price</span>
        <span class="ppevo-price">{{st.productsCfg.totalPricing.price.value | number : '1.2-2'}}</span>
        <span class="ppevo-currency" translate>NG_PASSPROPRO_SURVEY.currency</span>
    </div>

    <div class="ppevo-break"></div>

    <div class="ppevo-color-legend-box">
        <div class="ppevo-high">
            <span class="ppevo-icon"></span>
            <span translate>NG_PASSPROPRO_SURVEY.high</span>
        </div>
        <div class="ppevo-medium">
            <span class="ppevo-icon"></span>
            <span translate>NG_PASSPROPRO_SURVEY.medium</span>
        </div>
        <div class="ppevo-low">
            <span class="ppevo-icon"></span>
            <span translate>NG_PASSPROPRO_SURVEY.low</span>
        </div>
    </div>
</ng-container>

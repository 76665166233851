import { Address } from './address';

export class PolicyContactInfo {
  addresses: Address[] = [];
  emails: string[] = [];
  homeInsurances: string[] = [];
  emailsVisible?: boolean;
  addressesVisible?: boolean;
  homeInsurancesVisible?: boolean;

}

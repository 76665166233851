import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RgiRxOnContainerValueChange} from '@rgi/rx/ui';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';
import {mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {of} from 'rxjs';
import {
  ReIssueQuotationStateManagerService
} from '../../re-issue-state-manager/re-issue-quotation-state-manager.service';
import {ReIssueQuotationState} from '../../re-issue-state-manager/re-issue-quotation.state';

@Component({
  selector: 're-issue-beneficiaries',
  templateUrl: './re-issue-beneficiaries.component.html',
  styleUrls: ['./re-issue-beneficiaries.component.css'],
})
export class BeneficiariesComponent implements OnInit, OnChanges {
  @Input() beneficiariesProduct;
  @Input() listBeneficiariesUnit;
  @Input() isBeneficiaryUnitVisibility;
  @Input() beneficiaryForm;
  beneficiaryParties = [];
  ifError = false;
  istrad = false;
  anagBenef: string;
  spouseInsured: string;
  warningMessagge: string;
  errorThirdPerson: string;
// tslint:disable-next-line:max-line-length
  errorSubjectMessage: string;
  ngModel: any[];

  constructor(
    protected fb: UntypedFormBuilder,
    public pushMessageHandler: PushMessageHandlerService,
    protected translate: RgiRxTranslationService,
    private translateService: RgiRxTranslationService,
    public stateManager: ReIssueQuotationStateManagerService<ReIssueQuotationState>
  ) {
  }


  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.setTraductions();
    if (changes.listBeneficiariesUnit) {
      const beneficiaries = changes.listBeneficiariesUnit.currentValue;
      if (this.getBeneficiary().length < beneficiaries.length) {
        beneficiaries.forEach((beneficiary, index) => {
          const anagBeneficiary = beneficiary.beneficiary.find(b => b.description === 'Beneficiari d\'Anagrafe');
          if (anagBeneficiary) {
            anagBeneficiary.description = this.getAnagBenefTranslsated();
          }
          const spouseInsured = beneficiary.beneficiary.find(b => b.description === 'Coniuge dell\'Ass.');
          if (spouseInsured) {
            spouseInsured.description = this.getSpouseInsuredTranslated();
          }
          if (!this.getBeneficiary().controls.find(x => x.value.unit === beneficiary.unit && x.value.section === beneficiary.section)) {
            const state = this.stateManager.getCurrentState();
            this.pushMessageHandler.clearTag(this.listBeneficiariesUnit[index].unitCode);
            const fbGroup: UntypedFormGroup = this.fb.group({
              unit: [beneficiary.unit],
              section: [beneficiary.section],
              unitCode: [beneficiary.unitCode],
              beneficiaryType: [, Validators.required],
              beneficiaryParties: this.fb.array([]),
              beneficiaryVisibility: [,],
              anagMax: [,],
              anagMin: [,]
            });

            (this.beneficiaryForm.controls.beneficiary as UntypedFormArray).push(
              fbGroup
            );
            if (state.beneficiarySelected.length > 0) {
              // state.beneficiarySelected = this.updateBeneficiary();
              state.beneficiarySelected.forEach((elem, i) => {
                if (elem.beneficiaryType) {
                  this.getBeneficiary().controls[index].patchValue({
                    beneficiaryType: elem.beneficiaryType,
                  });
                  this.beneficiaryType(index);
                }
                if (elem.subject) {
                  this.getBeneficiaryParties(index).controls[i].patchValue({
                    beneficiaryParty: elem.subject,
                    percentageBeneficiary: Number(elem.percentage).toFixed(0),
                    percentageVisibility: true
                  });
                }
              });
              this.beneficiarySubject(index);
            }
          }
        });
      } else if (this.getBeneficiary().length > beneficiaries.length) {
        if (beneficiaries.length === 0) {
          this.getBeneficiary().controls.forEach((ben, index) => {
            this.getBeneficiary().removeAt(index);
          });
        } else {
          beneficiaries.forEach(beneficiary => {
            this.getBeneficiary().removeAt(
              this.getBeneficiary().controls.findIndex(
                x => x.value.unit !== beneficiary.unit || x.value.section !== beneficiary.section));
          });
        }
      }
    }
  }

  setTraductions() {
    const errorSubjectMessage = of(['RE_ISSUE.WARNING_BENEFICIARY_SUBJECT']);
    errorSubjectMessage.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(val => {
      this.errorSubjectMessage = val [0];
    }).unsubscribe();
    const warningMessagge = of(['RE_ISSUE.WARNING_BENEFICIARY_LIMIT']);
    warningMessagge.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(val => {
      this.warningMessagge = val [0];
    }).unsubscribe();
    const errorThirdPerson = of(['RE_ISSUE.WARNING_BENEFICIARY_THIRD_PERSON']);
    errorThirdPerson.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(val => {
      this.errorThirdPerson = val [0];
    }).unsubscribe();
  }


  getAnagBenefTranslsated(): string {
    const anagBenef = of(['RE_ISSUE.ANAG_BENEF']);
    anagBenef.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(val => {
      this.anagBenef = val [0];
    }).unsubscribe();
    return this.anagBenef;
  }

  getSpouseInsuredTranslated(): string {
    const spouseInsured = of(['RE_ISSUE.SPOUSE_INSURED']);
    spouseInsured.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(val => {
      this.spouseInsured = val [0];
    }).unsubscribe();
    return this.spouseInsured;
  }

  selectedBeneficiaryType(i: number) {
    this.clearFormArray(this.getBeneficiaryParties(i));
    this.beneficiaryType(i);
  }


  beneficiaryType(i: number) {
    this.pushMessageHandler.clearTag(this.listBeneficiariesUnit[i].unitCode);
    this.listBeneficiariesUnit[i].beneficiary.forEach(ben => {
      if (this.getBeneficiaryType(i).value === ben.code) {
        this.beneficiaryForm.controls.beneficiary.controls[i].controls.anagMax.setValue(ben.soggettiAnagMax);
        this.beneficiaryForm.controls.beneficiary.controls[i].controls.anagMin.setValue(ben.soggettiAnagMin);
      }
      if (!this.getBeneficiaryType(i).value) {
        this.beneficiaryForm.controls.beneficiary.controls[i].controls.anagMax.setValue(0);
        this.beneficiaryForm.controls.beneficiary.controls[i].controls.anagMin.setValue(0);
      }
    });
    if (this.getBeneficiary().value[i].anagMax > 0) {
      this.setValidatorRequired(true);
      this.getBeneficiaryParties(i).push(this.newBeneficiaryParties());
    } else {
      this.setValidatorRequired(false);
    }
  }

  selectedBeneficiarySubject(val: RgiRxOnContainerValueChange, i: number, j: number) {
    if (val.changed.objectId) {
      this.pushMessageHandler.clearTag(this.listBeneficiariesUnit[i].unitCode);
      if (this.checkSubject(val.changed, i) && this.checkThirdPerson(val.changed, i)) {
        this.beneficiarySubject(i);
      } else {
        this.removeBeneficiarySubject(i, j);
        !this.checkThirdPerson(val.changed, i) ?
          this.errorMessage(i, this.errorThirdPerson) : this.errorMessage(i, this.errorSubjectMessage);
      }
    }
  }

  beneficiarySubject(i: number) {
    this.setPercVisibility(this.getBeneficiaryParties(i));
    if (this.getBeneficiaryParties(i).length === this.getBeneficiary().value[i].anagMax) {
      if (this.getBeneficiary().controls[i].value.beneficiaryVisibility === true) {
        this.errorMessage(i, this.warningMessagge);
      }
    } else {
      this.getBeneficiaryParties(i).push(this.newBeneficiaryParties());
    }
  }

  checkBeneficiary(i: number) {
    if (this.getBeneficiaryParties(i).length === this.getBeneficiary().at(i).value.anagMax) {
      this.errorMessage(i, this.warningMessagge);
    } else {
      this.pushMessageHandler.clearTag(this.listBeneficiariesUnit[i].unitCode);
    }
  }

  checkThirdPerson(subject: any, i: number) {
    const thirdPersonContact = this.stateManager.getCurrentState().thirdPersonContact;
    return thirdPersonContact ? thirdPersonContact.objectId !== subject.objectId : true;
  }

  checkSubject(subject: any, i: number) {
    // tslint:disable-next-line:max-line-length
    return this.getBeneficiaryParties(i).controls.filter(x => x.value.beneficiaryParty.objectId === subject.objectId || x.value.beneficiaryParty.id === subject.objectId).length <= 1;
  }

  errorMessage(i: number, message) {
    if (message) {
      const msg: PushMessage = new PushMessage();
      const opts = {icon: 'rgi-ui-icon-warning'};
      msg.tag = this.listBeneficiariesUnit[i].unitCode;
      msg.status = 'info';
      msg.content = message;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
    }
  }

  setPercVisibility(beneficiaryParties: UntypedFormArray) {
    beneficiaryParties.controls.forEach((beneficiaryParty: UntypedFormGroup) => {
      Object.keys(beneficiaryParty.controls).forEach(key => {
        if (key === 'percentageVisibility') {
          beneficiaryParty.controls[key].setValue(true);
        }
        if (key === 'percentageBeneficiary') {
          beneficiaryParty.controls[key].setValidators([Validators.pattern('^([0-9]|[1-9][0-9]|100)$'), Validators.required]);
        }
      });
    });
  }

  setValidatorRequired(check: boolean) {
    (this.beneficiaryForm.controls.beneficiary as UntypedFormArray).controls.forEach((form: UntypedFormGroup) => {
      Object.keys(form.controls).forEach(key => {
        if (key === 'beneficiaryVisibility') {
          form.controls[key].setValue(check);
        }
      });
    });
  }

  removeBeneficiarySubject(i: number, index: number) {
    this.getBeneficiaryParties(i).removeAt(index);
    this.checkBeneficiary(i);
    if (this.getBeneficiaryParties(i).length === 0 ||
      this.getBeneficiaryParties(i).controls.filter(x => !x.value.beneficiaryParty).length === 0) {
      this.getBeneficiaryParties(i).push(this.newBeneficiaryParties());
    }
  }

  newBeneficiaryParties(): UntypedFormGroup {
    return this.fb.group({
      beneficiaryParty: [,],
      percentageBeneficiary: [,],
      percentageVisibility: [,]
    });
  }

  getBeneficiaryParties(i: number) {
    return this.getBeneficiary()
      .at(i)
      .get('beneficiaryParties') as UntypedFormArray;
  }

  getBeneficiaryType(i: number) {
    return this.getBeneficiary()
      .at(i)
      .get('beneficiaryType');
  }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  getBeneficiary() {
    return this.beneficiaryForm.get('beneficiary') as UntypedFormArray;
  }
}

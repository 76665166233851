import {AnagApiEntity} from './anag-subject-api';

export class AnagApiNode extends AnagApiEntity {
  public identification: string;

  constructor(code, description, id) {
    super(code, description);
    this.identification = id;
  }
}

import {ContractPayment, IdentificationEntity, MeanOfPayment, PaymentsField} from '../../payments-data';

export function sortMeansOfPayment(meansOfPayment: MeanOfPayment[]): MeanOfPayment[] {
  if (meansOfPayment && meansOfPayment.length > 0) {
    meansOfPayment.sort((a: MeanOfPayment, b: MeanOfPayment) => {
      return a.paymentConfig.meanOfPayment.description.localeCompare(b.paymentConfig.meanOfPayment.description);
    });
  }

  return meansOfPayment;
}


export function mapPayment(
  identificationEntity: IdentificationEntity,
  idPayType: string,
  idSettlType: number,
  pf: PaymentsField[]
): ContractPayment {
  const contractPayment: ContractPayment = {
    payment: {
      meanPayment: {
        id: identificationEntity.id,
        code: identificationEntity.code,
        description: identificationEntity.description
      },
      idPaymentType: idPayType
    },
    idSettlementType: idSettlType
  };
  if (pf) {
    pf.forEach((singleEl: PaymentsField) => {
      const value = singleEl.value;
      switch (singleEl.name.toUpperCase()) {
        case 'CIBAN':
          contractPayment.payment.iban = value;
          break;
        case 'CBIC':
          contractPayment.payment.bic = value;
          break;
        case 'IDCRDCARD':
          contractPayment.payment.creditCardId = value ? +value : null;
          break;
        case 'DCARDEXPE':
          contractPayment.payment.creditCardExpireDate = value ? new Date(value) : null;
          break;
        case 'CHOLDER':
          contractPayment.payment.holder = value;
          break;
        case 'DACTIVATIONSDD':
          contractPayment.payment.sddActivationDate = value ? new Date(value) : null;
          break;
        case 'DNOTIFICATION':
          contractPayment.payment.notificationDate = value ? new Date(value) : null;
          break;
        case 'CCHECKNUM':
          contractPayment.payment.checkNumber = value;
          break;
        case 'NCASH':
          contractPayment.payment.cash = value ? +value : null;
          break;
        case 'CNUMTRANSACTION':
          contractPayment.payment.transactionNumber = value;
          break;
        case 'CUIC':
          contractPayment.payment.uic = value;
          break;
        case 'CFOREIGNCURR':
          contractPayment.payment.foreignCurrency = value;
          break;
        case 'CFOREIGNACC':
          contractPayment.payment.foreignAccount = value;
          break;
        case 'DADJ':
          contractPayment.payment.adjustmentDate = value ? new Date(value) : null;
          break;
        case 'CDESCRTRANSF':
          contractPayment.payment.transferDescr = value;
          break;
        case 'CBANKNAME':
          contractPayment.payment.bankName = value;
          break;
        case 'CBANKADDRESS':
          contractPayment.payment.bankAddress = value;
          break;
        case 'CHOLDERKEY':
          contractPayment.payment.holderKey = value;
          break;
        case 'CHOLDERADDRESS':
          contractPayment.payment.holderAddress = value;
          break;
        case 'ESTATESDD':
          contractPayment.payment.sddIdState = value ? +value : null;
          break;
        case 'DCANCSDD':
          contractPayment.payment.sddCancelDate = value ? new Date(value) : null;
          break;
        case 'CCODSDD':
          contractPayment.payment.sddCode = value;
          break;
        case 'CSUBSCRIBER':
          contractPayment.payment.subscriber = value;
          break;
        case 'CSUBSCRIBERKEY':
          contractPayment.payment.subscriberKey = value;
          break;
        case 'CRUM':
          contractPayment.payment.rum = value;
          break;
        default:
      }
    });
  }
  return contractPayment;
}

export function creditCardToApiDate(value: string | null): string | null {
  if (value != null) {
    let dateParts;
    if (value.includes('-')) {
      return value;
    } else {
      dateParts = value.split('/');
    }
    return (`${dateParts[1]}-${dateParts[0]}-01`);
  }
  return null;
}

export const ibanRejex = '^(?:(?:IT|SM)\\d{2}[A-Z]\\d{10}([A-Z0-9]{12})|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}' +
  '[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}' +
  '[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|' +
  '(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d' +
  '{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$';

// mio test

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {REST_API_TECHDATA_CONF} from './re-issue-resources-path';


@Injectable({
  providedIn: 'root'
})
export class ReIssueTechnicalDataResourceService {
  protected TECH_DETAIL_PATH = '/data'; // FOR MOCK SERVICE ONLY
  protected apiTechDataConf: any;

  constructor(
    protected http: HttpClient,
    @Inject('environment') protected apiConf: any,
  ) {
    this.apiTechDataConf = REST_API_TECHDATA_CONF;
  }

  get(resourceId: string): Observable<PolicyTechnicalData> {
    return this.http.get<PolicyTechnicalData>(this.technicalDetailsUri(resourceId)).pipe(
      share()
    );
  }

  put(resourceId: string, data: PolicyTechnicalData): Observable<PolicyTechnicalData> {
    return this.http.put<PolicyTechnicalData>(this.technicalDetailsUri(resourceId), data).pipe(
    );
  }

  protected technicalDetailsUri(resourceId: string): string {
    return this.apiConf.api.portal.path + this.apiTechDataConf.path + resourceId + this.apiTechDataConf.technicalDataPath;
  }


}


import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {tap} from 'rxjs/operators';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {
  GroupPolicyStatemanagerHome
} from '../../group-policy-state/group-policy-statemanager-home/group-policy-statemanager-home';
import {GroupPolicyStateHome} from '../../group-policy-state/group-policy-state';

@Component({
  selector: 'rgi-gp-group-policy-home',
  templateUrl: './group-policy-home.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyHomeComponent extends RoutableComponent implements OnInit {

  public state: GroupPolicyStateHome;
  public previousStepData: DataForSteps;

  constructor(
    protected stateManagerHome: GroupPolicyStatemanagerHome,
    protected activeRoute: ActiveRoute) {
    super();
  }

  homeForm = new UntypedFormGroup({
    node: new UntypedFormControl('', Validators.required),
    company: new UntypedFormControl('', Validators.required),
    filterTarget: new UntypedFormControl(),
    filterTypeProduct: new UntypedFormControl(),
    filterProduct: new UntypedFormControl('', Validators.required),
    issueDate: new UntypedFormControl('', Validators.required),
    effectiveDate: new UntypedFormControl('', [Validators.required])
  });

  ngOnInit(): void {
    this.homeForm.controls.issueDate.disable();
    this.previousStepData = this.activeRoute.getRouteData();
    this.manageFormUpdates();
    this.stateManagerHome.getState$().pipe(
      tap((state: GroupPolicyStateHome) => {
          this.homeForm.patchValue(
            {
              issueDate: state.issueDate,
              node: state.selectedNode,
              effectiveDate: state.effectiveDate,
              filterProduct: state.selectedProduct,
              company: state.selectedCompany
            }, {emitEvent: false});
          this.tickHostChange();
        }
      )
    )
      .subscribe((nextState: GroupPolicyStateHome) => {
          this.state = nextState;
        }
      );
  }

  protected manageFormUpdates() {
    this.homeForm.get('node').valueChanges.subscribe(() => {
      this.stateManagerHome.actionSearchAvailableProducts(this.homeForm.getRawValue());
    });
  }

  /**
   * @deprecated use manageFormUpdates (already used in ngOnInit)
   */
  onChangeNode(_$event: Event) {
    throw new Error('remove onChangeNode callback from your template');
  }

  callActionNewFlowIssue() {
    this.homeForm.markAllAsTouched();
    if (this.homeForm.valid) {
      this.stateManagerHome.actionStartNewFlow(this.homeForm.getRawValue());
    }
  }

  resetHome() {
    this.homeForm.reset();
    this.stateManagerHome.initHome();
  }

  get showCompanyField(): boolean {
    return this.stateManagerHome.showCompanyField();
  }
}

<div>
  <form [formGroup]="newLetterTemplateForm" data-qa="new-letter-template-form">
    <div class="col-sm-12 container-new-letter-template">
      <div class="col-sm-6">
        <label class="label-core"
               attr.data-qa="new-letter-template-titolo-label">{{ '_CLAIMS_._TITLE' | translate }}</label>
        <input class="form-control" formControlName="titolo" id="titolo" type="text"
               name="titolo" attr.data-qa="new-letter-template-titolo-input">
      </div>

      <div class="col-sm-6">
        <label ng-bind="label">{{ '_CLAIMS_._DEROGATION_LEVEL' | translate }}</label>
        <select class="core-select form-control" formControlName="livelloDeroga" id="livelloDeroga" name="livelloDeroga"
                attr.data-qa="livello-deroga-select">
          <option></option>
          <option *ngFor="let livelloDeroga of livelliDeroga; " value={{livelloDeroga.value}}>
            {{livelloDeroga.desc}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>

      <div class="col-sm-6 padding-top-25">
        <label ng-bind="label">{{ '_CLAIMS_._MANAGE_PHASE' | translate }}</label>
        <select class="core-select form-control" formControlName="idFaseGestione" id="idFaseGestione" name="idFaseGestione"
                attr.data-qa="fase-gestione-select">
          <option></option>
          <option *ngFor="let faseGestione of fasiGestione;" value={{faseGestione.id}}>
            {{faseGestione.desc}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>
    </div>

    <div class="col-sm-12 padding-top-25">
      <editor formControlName="corpoLettera" id="corpoLettera" name="corpoLettera"></editor>
    </div>

    <span *ngIf='errorMessage' class="col-sm-12 content-error validate-error" attr.data-qa="new-letter-template-error">
              <span class="rgifont rgi-exclamation-triangle"></span>
              <span>{{errorMessage}}</span>
          </span>

    <div class="btn-group btn-group-justified padding-top-25">
      <div class="btn-group">
        <button type="button" class="btn btn-warning pull-right" (click)="back()"
                attr.data-qa="new-letter-template-empty-btn">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
      </div>
      <div class="btn-group">
        <button type="submit" class="btn btn-warning pull-right" attr.data-qa="new-letter-template-find-btn"
                id="new-letter-template-submit" (click)="continue()">{{ '_CLAIMS_._BUTTONS_._NEXT' | translate }}</button>
      </div>
    </div>
  </form>
</div>

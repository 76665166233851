import { Injectable } from '@angular/core';
import { ActiveRoute } from '@rgi/rx/router';
import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import { Observable, of } from "rxjs";
import { mergeMap } from 'rxjs/operators';
import { QuestionnaireI } from "../models";
import { SurveyEditRouteData } from './models/survey-edit-route-data';
import { BTN_MAP_EDIT, SurveyEditState } from './models/survey-edit-state';
import { SurveyEditStateOperations } from './operations/survey-edit-state-operations.service';



@Injectable()
export class SurveyEditStateManagerService<S extends SurveyEditState> extends AbstractStateManager<S>{


  /**
   * il construttore viene invocato ogni volta che cambio route
   *
   */
  constructor(
      activeRoute: ActiveRoute,
      stateStoreService: StateStoreService
    , private _ops: SurveyEditStateOperations<S>
  ) {

    super(activeRoute, stateStoreService);

    let st = this.initState();
    this.updateState$(of(st));

  }


  protected initState():S{
    let st:S = this.stateStoreService.get(this.activeRoute.key) || <S>(new SurveyEditState(this.activeRoute.key));
    st.activeRouteId = this.activeRoute.id;
    st.routeOptions = this.activeRoute.routeOptions;
    if (!st.navigation) {
      st.navigation = {
        stickable: true,
        map: BTN_MAP_EDIT,
      }
    }
    const routeData = <SurveyEditRouteData>this.activeRoute.getRouteData();
    st.surveyVersion = routeData.surveyVersion;
    st.isEdit = !!routeData.isEdit;
    st.showBackButton = typeof(routeData.showBackButton)=='boolean' ? routeData.showBackButton : st.showBackButton;
    st.isNewVersion = typeof(routeData.isNewVersion)=='boolean' ? routeData.isNewVersion : st.isNewVersion;
    st.integrationFilter = routeData.integrationFilter || st.integrationFilter;
    st.navigateFrom = routeData.navigateFrom;
    st.forwardData = routeData.forwardData;
    st.initialized = false;
    return st;
  }


  protected emitState(st$: Observable<S>) {
    this.updateState$(
      st$.pipe(
        mergeMap( st => this._ops.beforeUpdate$(st) )
      )
    );
  }


  actionInit() {
    let st:S = this.stateStoreService.get(this.activeRoute.key) || <S>(new SurveyEditState(this.activeRoute.key));
    let st$: Observable<S> = this._ops.getInit$(of(st));
    this.emitState(st$);
  }


  actionSelectQuestionnaire(questionnaire: QuestionnaireI) {
    let st$: Observable<S> = this._ops.getSelectQuestionnaire$(of(this.getCurrentState()), questionnaire);
    this.emitState(st$);
  }


  /**
   * Override this action when add custom button
   * @param btnId
   * @param validate function to validate form data when need
   * @example
   * if( btnId==='myButton') {
   *    const st$ = getDoSomeOperation$(of(this.getCurrentState()));
   *    this.emitState(st$);
   * }else {
   *    super.actionNavigationClick(btnId, validate)
   * }
   */
  actionNavigationClick(btnId: string, validate: () => boolean) {
    let st$: Observable<S>;

    switch (btnId) {
      case 'BACK':
        st$ = this._ops.getBack$(of(this.getCurrentState()));
        break;
      case 'SAVE':
        if (validate()) {
          st$ = this._ops.getSaveSurvey$(of(this.getCurrentState()));
        }
        break;
      case 'NEW_VERSION':
        st$ = this._ops.getMakeNewVersion$(of(this.getCurrentState()));
        break;
      case 'EVALUATE':
        if (validate()) {
          st$ = this._ops.getEvaluation$(of(this.getCurrentState()));
        }
        break;
      case 'EDIT':
        st$ = this._ops.getEnableEditMode$(of(this.getCurrentState()));
        break;
      case 'SHOW_RESULTS':
        validate(); // force update questionnaire flat
        st$ = this._ops.getShowEvaluation$(of(this.getCurrentState()));
        break;

    }

    st$ && this.emitState(st$);
  }

}







import {Question} from './question';
import {Condition} from './condition';
import {QuestionnaireAnswer} from './questionnaire-answer';
import {QuestionStatuses} from './question-statuses';

export class TermFlat {
  questionDescription: string;
  factorCode: string;
  questionCode: string;
  sectionCode: string;
  factorDescription: string;
  sectionDescription: string;
  operatorCode: any;
  type: string;
  value: any;
  operator: number;
  isResult?:boolean
}

export class ConditionFlat {
  terms: TermFlat[];
  status: number;
}

export interface QuestionSectionVersion {
    question: Question;
    id: number;
    text: string;
    condition: Array<Condition>;
    questionnaireAnswers: Array<QuestionnaireAnswer>;
    order: number;
    status: QuestionStatuses;

    compulsory?: boolean;
    visible?: boolean;
    isValid?: boolean;
    conditionsFlat?: ConditionFlat[];
}

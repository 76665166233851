import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LicHelpContentComponent } from '../lic-help-content/lic-help-content.component';
import { LicHelpService } from '../lic-help-service';

@Component({
  selector: 'lic-help-icon',
  templateUrl: './lic-help-icon.component.html',
  styleUrls: [ './lic-help-icon.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class LicHelpIconComponent {

  @Input() helpFile: string;
  @Input() title: string;

  constructor(
    protected modalService: NgbModal,
    protected service: LicHelpService) {
  }

  public get visible(): boolean {
    return !!this.helpFile;
  }

  public showHelp(event: Event): void {
    event.stopPropagation();
    if (!this.visible) {
      return;
    }
    this.service.getHelpContent(this.helpFile)
      .subscribe(htmlContent => {
        const dialog = this.modalService.open(LicHelpContentComponent, {
          centered: true,
          size: 'lg',
          windowClass: 'in',
          backdropClass: 'light-blue-backdrop in'
        });
        dialog.componentInstance.title = this.title;
        dialog.componentInstance.html = htmlContent;
    });
  }

}

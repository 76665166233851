import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Configuration} from '../model/configuration';
import {Report} from '../model/report';
import {ReportDetail} from '../model/report-detail';
import {ConfigurationService} from '../services/configuration.service';
import {EnigmaService} from '../services/enigma.service';
import {ReportService} from '../services/report.service';
import {embed} from '@nebula.js/stardust';
import {ReportArea} from '../model/report-area';
import {elementTypes} from '../constants/elementsType';
import {StateManagedComponent} from '@rgi/rx/state';
import {
  CardAnalyticsStateEditReport,
  CardAnalyticsStateManagerEditReport
} from '../card-analytics-state/card-analytics-state-manager-editreport';
import {FormBuilder, Validators} from '@angular/forms';
import {NEBULA_CONF} from '../constants/nebulaConf';
import {QLIK_THEMES} from '../constants/qlikThemes';
import {ReportElement} from '../model/report-element';
import {switchMap, tap} from 'rxjs/operators';
import {PARAMS_DIMENSIONS_LIST, PARAMS_SESSION_OBJECT} from '../constants/qlikApiParams';
import {LoggerService} from '../services/logger.service';
import {Observable, Subscription} from 'rxjs';
import LoadingSubject from '../model/loading-subject';
import {UpdateReportModalComponent} from '../modals/update-report-modal/update-report-modal.component';
import {ModalService} from '@rgi/rx/ui';
import {AnalyticsSharedService} from '../shared/analytics-shared.service';

@Component({
  selector: 'lib-card-analytics-edit-report',
  templateUrl: './card-analytics-edit-report.component.html',
  host: {
    class: 'rgi-analytics'
  }
})
export class CardAnalyticsEditReportComponent
  extends StateManagedComponent<CardAnalyticsStateEditReport, CardAnalyticsStateManagerEditReport> implements OnInit, OnDestroy {

  allFilters: any[] = [];
  app: string;
  areChartsVisible = false;
  configuration: Configuration;
  displayCharts = true;
  docList: any;
  filterDocumentsByStream: any[];
  filterLoaded = false;
  filterSelect: any[] = [];
  filterSheetsByApp: any;
  loadingSubjects: LoadingSubject[] = [];
  nebula: any;
  nebulaCharts = [];
  nebulaConf: typeof embed;
  newReportFilters: any[] = [];
  objects: any[] = [];
  reportAreas: ReportArea[];
  reportDetail: ReportDetail;
  reportFilters: any[] = [];
  reportForm: any;
  report: Report;
  selectedCharts: any[] = [];
  selectedFilters: any[] = [];
  selectedSheet = [];
  sessionTimeoutSubscription: Subscription = Subscription.EMPTY;
  sheet: any;
  sheetList: any[];
  sheetSelect: any[] = [];
  showApp = false;
  showButton = true;
  showForm = false;
  showSheet = false;
  streamList: any;
  themes = QLIK_THEMES;
  currentSession: any;

  constructor(public activeRoute: ActiveRoute,
              private configurationService: ConfigurationService,
              private enigmaService: EnigmaService,
              private fb: FormBuilder,
              private logger: LoggerService,
              private manager: CardAnalyticsStateManagerEditReport,
              private modalService: ModalService,
              private reportService: ReportService,
              private renderer: Renderer2,
              private routingService: RoutingService,
              private sharedService: AnalyticsSharedService) {
    super(manager);
    this.nebulaConf = embed.createConfiguration(NEBULA_CONF);
  }

  async ngOnInit() {
    this.report = this.activeRoute.getRouteData();

    this.configurationService.getConfiguration().pipe(
      switchMap(resultConfiguration => {
        this.configuration = resultConfiguration;
        return this.reportService.getReport(this.report.id);
      })
    ).subscribe(
      async res => {
        this.reportDetail = res;

        // REACTIVE FORM
        this.initializeForm(res);

        this.showForm = true;
        await this.initApp(res.idQlikApp);

        this.app = res.idQlikApp;

        this.streamList = await this.currentSession.getStreamList();
        this.docList = await this.currentSession.getDocList();
        this.selectStream();
        // await this.openDocument(res.idQlikApp);

        const app = await this.currentSession.openDoc(res.idQlikApp);
        this.nebula = this.nebulaConf(app);

        this.areChartsVisible = true;
        await this.openDocument(res.idQlikApp);

        const selections = await this.nebula.selections();
        selections.mount(document.querySelector('.toolbar'));
        const filtersFromQlik = await app.createSessionObject(PARAMS_DIMENSIONS_LIST);
        const filterLayout = await filtersFromQlik.getLayout();
        this.filterSelect = [];

        filterLayout.qDimensionList.qItems.forEach((element: any) => {
          this.filterSelect.push({id: element.qInfo.qId, title: element.qMeta.title, sheet: ''});
          this.allFilters.push(element);
        });

        await Promise.all(this.reportDetail.elements.map(async sheet => {

          if (sheet.typeCode === elementTypes.FILTER) {
            let field: any;
            field = this.allFilters.filter(filter => filter.qInfo.qId === sheet.idQlikElement);
            if (field.length > 0) {
              this.reportFilters.push({id: field[0].qInfo.qId, title: field[0].qMeta.title});
              this.selectedFilters.push({id: field[0].qInfo.qId, title: field[0].qMeta.title});
              let tempNewReportFilters = [{
                id: sheet.idQlikElement, title: field[0].qMeta.title,
                sheet: sheet.idQlikSheet
              }];
              this.newReportFilters = [...tempNewReportFilters];
            }
            return;
          }

          const tempSheet = this.filterSheetsByApp.filter((item: any) => item.qInfo.qId === sheet.idQlikSheet);

          await Promise.all(tempSheet[0].qData.cells.map(async (element: any) => {
            const appObject = await app.getObject(element.name);
            const layout = await appObject.getLayout();
            const object = {
              name: null,
              title: null,
              type: null,
              sheet: null,
              isChecked: null
            };

            object.title = element.name;
            object.name = element.name;
            object.sheet = tempSheet[0].qInfo.qId;


            if (sheet.idQlikElement === object.name) {
              const isObjectPresent = this.objects.some(obj => obj.name === object.name);
              if (isObjectPresent) {
                this.objects = this.objects.filter(obj => obj.name !== object.name);
              }
              object.isChecked = true;
              this.selectedCharts.push({idElement: object.name, idSheet: sheet.idQlikSheet});
            } else {
              object.isChecked = false;
            }

            if (layout !== null) {
              object.title = layout.title;
            }
            object.type = element.type;

            if (NEBULA_CONF.types.map(e => e.name).indexOf(layout.visualization) !== -1) {
              const isObjectPresent = this.objects.some(obj => obj.name === object.name);
              if (!isObjectPresent) {
                this.objects.push(object);
                this.addLoader(element.name);
              }
            }
          }));

        }));

        this.filterLoaded = true;
        this.areChartsVisible = true;
        this.displayCharts = true;
        for (const element of this.objects) {
          const n = await this.sharedService.renderCharts(element.name, this.nebula, '.chart' + element.name);
          this.nebulaCharts = [...this.nebulaCharts, n];
          this.completeLoading(element.name);
        }
      },
      err => {
        this.logger.error(err);
      }
    );

    this.reportService.getReportAreas().subscribe(
      res => {
        this.reportAreas = res;
      },
      err => {
        this.logger.error(err);
      }
    );
  }

  async ngOnDestroy() {
    this.logger.log('EDIT REPORT DESTROYED');
    await this.sharedService.unrenderCharts(this.nebulaCharts, this.nebula);
  }

  initializeForm(res: any) {
    this.reportForm = this.fb.group({
      name: [res.description, [Validators.required]],
      area: [res.areaId, [Validators.required]],
      elements: this.fb.array(res.elements, [Validators.required]),
      app: [res.idQlikApp, [Validators.required]],
      sheets: [[], Validators.required],
      stream: [res.idQlikStream, [Validators.required]],
      theme: [res.qlikTheme],
      enableSelections: [res.enableSelections],
      enableInteractions: [res.enableInteractions],
      maxCols: [res.maxCols, [Validators.required]],
      maxRows: [res.maxRows, [Validators.required]],
      filters: [res.elements.filter(el => el.typeCode === elementTypes.FILTER)
        .map(id => id.idQlikElement)]
    });
  }

  selectStream(): void {
    this.showSheet = false;
    if (
      this.docList &&
      this.docList.length > 0
    ) {
      this.filterDocumentsByStream = [];
      this.docList.forEach((document: any) => {
        if (document.qMeta && document.qMeta.stream && document.qMeta.stream.id !== undefined && document.qMeta.stream.id !== null &&
          document.qMeta.stream.id === this.reportDetail.idQlikStream
        ) {
          this.filterDocumentsByStream.push(document);
          this.showApp = true;
        }
      });
    }
  }

  updateReport() {
    const elements: ReportElement[] = [];
    for (const element of this.selectedCharts) {
      if (!elements.some(el => el.idQlikSheet === element.idSheet && el.idQlikElement === element.idElement)) {
        const els = this.reportDetail.elements.find(rep => rep.idQlikElement === element.idElement);
        elements.push(new ReportElement(element.idSheet, element.idElement, elementTypes.CHART, els ? els.x : 0, els ? els.y : 0,
          els ? els.rows : 1, els ? els.cols : 1));
      }
    }

    for (const element of this.reportFormValues.filters) {
      elements.push(new ReportElement('', element, elementTypes.FILTER, 0, 0, 0, 0));
    }

    const report = new ReportDetail(this.reportDetail.id, this.reportFormValues.name, this.reportFormValues.area,
      this.reportFormValues.app, this.reportFormValues.stream, this.reportFormValues.enableSelections ? 1 : 0,
      this.reportFormValues.enableInteractions ? 1 : 0, elements, this.reportFormValues.theme, this.reportFormValues.maxRows,
      this.reportFormValues.maxCols);


    this.reportService.updateReport(report).pipe(
      tap(
        res => {
          this.logger.log(res);
          const {modal} = this.modalService.openComponent(UpdateReportModalComponent);
          modal.onClose.subscribe(() => {
            this.logger.log('Closed successfully');
          });

        },
        err => {
          this.logger.error(err);
        })).subscribe(
      res => {
        this.logger.log(res);
        this.manager.actionOpen();
      },
      err => {
        this.logger.error(err);
      });
  }

  async back() {
    this.manager.backToReport(this.report);
  }

  async selectApp(event: any): Promise<void> {
    const app = event.target.value;
    await this.initApp(app);
    this.showSheet = false;
    this.sheetList = [];
    await this.openDocument(app);
    this.showSheet = true;
  }

  async initApp(app: string) {
    const url = this.configuration.protocolSocket + '://' + this.configuration.host + '/' + this.configuration.proxy +
      '/app/' + app;
    this.currentSession = await this.enigmaService.getSession(url);
    // this.showSheet = true;
    this.areChartsVisible = false;
    this.showButton = true;
  }

  async openDocument(app: string) {
    this.showSheet = true;
    const document = await this.currentSession.openDoc(app);
    if (document) {
      const sessionObject = await document.createSessionObject(PARAMS_SESSION_OBJECT);
      if (sessionObject) {
        const layout = await sessionObject.getLayout();
        this.sheetList = [];
        if (
          layout &&
          layout.qAppObjectList &&
          layout.qAppObjectList.qItems &&
          layout.qAppObjectList.qItems.length > 0
        ) {
          layout.qAppObjectList.qItems.forEach((element) => {
            this.sheetList.push(element);
          });
        }
        this.sheetSelect = [];
        this.filterSheetsByApp = this.sheetList;
        this.filterSheetsByApp.forEach(res => {
          this.sheetSelect.push({id: res.qInfo.qId, title: res.qMeta.title, qData: res.qData});
          this.reportDetail.elements.forEach(element => {
            if (element.idQlikSheet === res.qInfo.qId && !this.selectedSheet.some((elem => elem.id === res.qInfo.qId))) {
              this.selectedSheet.push({id: res.qInfo.qId, title: res.qMeta.title, qData: res.qData});
            }
          });
        });
        this.reportForm.get('sheets').setValue(this.selectedSheet.map(sheet => sheet.id));

      }
    }

  }

  onReportChange($event: any) {
    if ($event.target.checked) {
      this.reportDetail.areaId = $event.target.value;
    } else {
      this.reportDetail.areaId = null;
    }
  }

  async showCharts() {
    this.areChartsVisible = false;
    this.displayCharts = false;
    this.showButton = true;
    const sheetFromQlik = await this.currentSession.getActiveDoc();
    this.nebula = this.nebulaConf(sheetFromQlik);
    this.objects = [];
    this.selectedCharts = [];
    this.nebulaCharts = [];
    const reportFilters = this.reportDetail.elements.filter(el => el.typeCode === elementTypes.FILTER);
    this.reportForm.get('filters').setValue(reportFilters.map(id => id.idQlikElement));
    this.loadingSubjects = [];

    if (sheetFromQlik) {



      this.areChartsVisible = true;
      this.displayCharts = true;

      for (const sheetId of this.reportFormValues.sheets) {
        const tempSheet = this.filterSheetsByApp.find((item: any) => item.qInfo.qId === sheetId);
        for (const element of tempSheet.qData.cells) {
          const obj = await sheetFromQlik.getObject(element.name);
          const layout = await obj.getLayout();
          const object = await this.getObjectFromQlik(sheetFromQlik, element.name, tempSheet, reportFilters, layout);
          this.addLoader(element.name);
          await this.updateSelections();
          this.processObject(object, tempSheet, layout);
        }
      }

      for (const element of this.objects) {
        const n = this.sharedService.renderCharts(element.name, this.nebula, '.chart' + element.name);
        this.nebulaCharts = [...this.nebulaCharts, n];
        this.completeLoading(element.name);
      }
    }
  }

  processObject(object: any, tempSheet: any, layout: any) {
    const isObjectPresent = this.objects.some(res => res.name === object.name);

    if (isObjectPresent) {
      this.objects = this.objects.filter(res => res.name !== object.name);
    }

    object.isChecked = this.reportDetail.elements.some(el => el.idQlikElement === object.name);

    if (object.isChecked) {
      this.selectedCharts.push({idElement: object.name, idSheet: tempSheet.qInfo.qId});
    }

    if (NEBULA_CONF.types.map(e => e.name).includes(layout.visualization)) {
      this.objects.push(object);
      this.addLoader(object.name);
    }
  }

  async getObjectFromQlik(sheetFromQlik: any, name: any, tempSheet: any, reportFilters: any, layout: any) {

    if (layout === null) {
      return null;
    }

    const object = {
      name: name,
      title: layout.title || name,
      type: null,
      sheet: tempSheet.qInfo.qId,
      isChecked: false
    };

    const filtersFromQlik = await sheetFromQlik.createSessionObject(PARAMS_DIMENSIONS_LIST);
    const filterLayout = await filtersFromQlik.getLayout();

    this.filterSelect = [];
    this.reportFilters = [];

    filterLayout.qDimensionList.qItems.forEach(item => {
      this.filterSelect.push({id: item.qInfo.qId, title: item.qMeta.title, sheet: tempSheet.qInfo.qId});
      this.allFilters.push(item);
      const newReportFilters = [...reportFilters.filter(el => el.idQlikElement === item.qInfo.qId)];
    });

    this.reportDetail.elements.forEach(el => {
      if (el.idQlikElement === object.name) {
        object.isChecked = true;
        this.selectedCharts.push({idElement: object.name, idSheet: tempSheet.qInfo.qId});
      }
    });

    object.type = layout.qInfo.qType;

    return object;
  }

  async updateSelections() {
    const selections = await this.nebula.selections();
    // selections.unmount(document.querySelector('.toolbar'));
    selections.mount(document.querySelector('.toolbar'));
  }

  onCheckChange($event: any, item: any) {
    if ($event.target.checked) {
      const idSheet = this.objects.filter(elem => elem.name === $event.target.value);
      this.selectedCharts.push({idElement: $event.target.value, idSheet: idSheet[0].sheet});
      this.renderer.addClass(document.querySelector('#chart' + item.name), 'analytics-checked-chart');
    } else {
      this.selectedCharts = this.selectedCharts.filter(chart => chart.idElement !== $event.target.value);
      this.renderer.removeClass(document.querySelector('#chart' + item.name), 'analytics-checked-chart');
    }
  }

  get reportFormValues() {
    return this.reportForm.value;
  }

  getLoading$(chartId: string): Observable<boolean> {
    const matchedSubject = this.loadingSubjects.find(el => el.id === chartId);
    const matchedValue = matchedSubject ? matchedSubject.value : null;
    return matchedValue.asObservable();
  }

  completeLoading(chartId: string) {
    const matchedSubject = this.loadingSubjects.find(el => el.id === chartId);
    if (matchedSubject) {
      const value = matchedSubject.value.getValue();
      matchedSubject.value.next(!value);
    }
  }

  private addLoader(name: string) {
    this.loadingSubjects.push(new LoadingSubject(name, true));
  }

  openQlikSense(): void {
    const url = this.getQlikUrl();
    window.open(url, '_blank');
  }

  getQlikUrl(): string {
    return this.configuration.protocolHttp + '://' + this.configuration.host + '/' +
      this.configuration.proxy + '/sense/app/' + this.reportForm.value.app;
  }
}


export type QueryParamValue = string | boolean | number;
// export type BuildReturnType = 'absolute' | 'relative';

export enum BuildReturnType { ABSOLUTE, RELATIVE}

export interface UrlBuilderOptions {
  hashPrefix: string;
}

export class UrlBuilder {

  static readonly INPUT_ERROR_MESSAGE = 'Url parts cannot contain ? or # characters';

  private _baseUrl: string;
  private _paths: string[] = [];
  private _hashPaths: string[] = [];
  private _queryParams = new URLSearchParams();
  private _hashParams = new URLSearchParams();
  private _hash: string;
  private _options: UrlBuilderOptions = {
    hashPrefix: ''
  };

  private static buildPath(...urlParts: string[]) {
    return urlParts
      .map(p => p.replace(/\/{2,}/, '/').replace(/^\/?(.*?)\/?$/, '$1'))
      .join('/');
  }

  private static checkInputs(...inputs: string[]) {
    if (inputs.some(p => p.indexOf('?') > -1 || p.indexOf('#') > -1)) {
      throw new Error(this.INPUT_ERROR_MESSAGE);
    }
  }

  withOptions(options: UrlBuilderOptions): UrlBuilder {
    this._options = {...this._options, ...options};
    return this;
  }

  baseUrl(baseUrl: string): UrlBuilder {
    UrlBuilder.checkInputs(baseUrl);

    try {
      const _ = new URL(baseUrl);
      this._baseUrl = baseUrl;
    }catch (e) {
      this._paths = [baseUrl].concat(this._paths);
    }

    return this;
  }

  path(...paths: string[]): UrlBuilder {
    UrlBuilder.checkInputs(...paths);
    this._paths = this._paths.concat(paths);
    return this;
  }

  queryParam(key: string, value: QueryParamValue, overrideParam = false): UrlBuilder {
    UrlBuilder.checkInputs(key, value.toString());
    if (overrideParam) {
      this._queryParams.set(key, value.toString());
    } else {
      this._queryParams.append(key, value.toString());
    }
    return this;
  }

  queryParams(params: {[k: string]: QueryParamValue | QueryParamValue[]}, overrideParams = false): UrlBuilder {
    Object.entries(params).forEach(([key, value]) => {
      const values = (value instanceof Array) ? value : [value];
        values.forEach((v, i) => {
          this.queryParam(key, v, overrideParams && (i === 0));
        });
    });
    return this;
  }

  hash(...paths: string[]): UrlBuilder {
    UrlBuilder.checkInputs(...paths);
    this._hashPaths = this._hashPaths.concat(paths);
    return this;
  }

  hashParam(key: string, value: QueryParamValue, overrideParam = false): UrlBuilder {
    UrlBuilder.checkInputs(key, value.toString());
    if (overrideParam) {
      this._hashParams.set(key, value.toString());
    } else {
      this._hashParams.append(key, value.toString());
    }
    return this;
  }

  hashParams(params: {[k: string]: QueryParamValue | QueryParamValue[]}, overrideParams = false): UrlBuilder {
    Object.entries(params).forEach(([key, value]) => {
      const values = (value instanceof Array) ? value : [value];
      values.forEach((v, i) => {
        this.hashParam(key, v, overrideParams && (i === 0));
      });
    });
    return this;
  }

  build(type: BuildReturnType.RELATIVE) : string;
  build(type?: BuildReturnType.ABSOLUTE) : URL;
  build(type: BuildReturnType.ABSOLUTE | BuildReturnType.RELATIVE = BuildReturnType.ABSOLUTE): URL | string {
    let url;
    if (this._baseUrl) {
      url = new URL(UrlBuilder.buildPath(...this._paths), this._baseUrl);
    } else {
      url = new URL(UrlBuilder.buildPath(...this._paths), document.location.protocol + document.location.host);
    }

    if (Array.from(this._queryParams as any).length) {
      url = new URL(`${url.href}?${this._queryParams.toString()}`);
    }

    if (this._hashPaths.length) {
      this._hash = new URL(UrlBuilder.buildPath(...this._hashPaths), document.location.protocol + document.location.host).pathname;
    }
    const hashParamsLength = Array.from(this._hashParams as any).length;
    if (this._hash || hashParamsLength) {
      url = new URL(`${url.href}#${this._options.hashPrefix}${this._hash || ''}${this._hash && hashParamsLength ? '?' : ''}${this._hashParams.toString()}`);
    }

    if (type === BuildReturnType.RELATIVE) {
      return url.href.replace(`${url.protocol}//${url.host}`);
    } else {
      return url;
    }
  }
}

import {Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren} from '@angular/core';
import {ConfigurationService} from '../services/configuration.service';
import {CookieAnalyticsService} from '../services/cookie.service';
import {EnigmaService} from '../services/enigma.service';
import {ActiveRoute} from '@rgi/rx/router';
import {LoadingIndicator} from '@rgi/rx';
import {Configuration} from '../model/configuration';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {delay, map, startWith} from 'rxjs/operators';
import {embed} from '@nebula.js/stardust';
import {ReportService} from '../services/report.service';
import {ReportArea} from '../model/report-area';
import {ModalComponent, ModalService} from '@rgi/rx/ui';
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {CardAnalyticsStateManagerNewReport} from '../card-analytics-state/card-analytics-state-manager-newreport';
import {elementTypes} from '../constants/elementsType';
import {NEBULA_CONF} from '../constants/nebulaConf';
import {QLIK_THEMES} from '../constants/qlikThemes';
import {ReportElement} from '../model/report-element';
import {ReportDetail} from '../model/report-detail';
import {LoggerService} from '../services/logger.service';
import {PARAMS_DIMENSIONS_LIST, PARAMS_SESSION_OBJECT} from '../constants/qlikApiParams';
import LoadingSubject from '../model/loading-subject';
import {AnalyticsSharedService} from '../shared/analytics-shared.service';

@Component({
  selector: 'lib-card-analytics-new-report',
  templateUrl: './card-analytics-new-report.component.html',
  host: {
    class: 'rgi-analytics'
  }
})

export class CardAnalyticsNewReportComponent implements OnInit, OnDestroy, LoadingIndicator {

  allFilters: any[] = [];
  app: any;
  areChartsVisible = false;
  chartsLoaded = false;
  configuration: Configuration;
  currentSession: any;
  docList: any;
  document: any;
  dropdownSettings = {};
  filterDocumentsByStream: any[];
  filterSelect: any[] = [];
  filterSheetsByApp: any[];
  filters: any[] = [];
  focusables = ['input', 'select', 'textarea'];
  inputsLoader$ = new BehaviorSubject<boolean>(true);
  loadingSubjects: LoadingSubject[] = [];
  modal: ModalComponent;
  nebula: any;
  nebulaConf: typeof embed;
  objects: any[] = [];
  openedApp: string;
  reportForm: any;
  reportName = '';
  reports: ReportArea[] = [];
  selectedCharts: any[] = [];
  selectedReport: any = '';
  sessionStatus: boolean;
  sessionTimeoutSubscription: Subscription = Subscription.EMPTY;
  sheet: any[] = [];
  sheetList: any[];
  sheetLoading$ = new BehaviorSubject<boolean>(true);
  sheetSelect: any[] = [];
  sheetSelected: any[] = [];
  showApp = false;
  showButton = false;
  showSheet = false;
  streamList: any;
  submitted = false;
  themes = QLIK_THEMES;
  urlToQlikApp = '';
  nebulaCharts = [];


  constructor(public activeRoute: ActiveRoute,
              private configurationService: ConfigurationService,
              private cookieAnalyticsService: CookieAnalyticsService,
              private el: ElementRef,
              private enigmaService: EnigmaService,
              private fb: UntypedFormBuilder,
              private logger: LoggerService,
              private manager: CardAnalyticsStateManagerNewReport,
              private modalService: ModalService,
              private renderer: Renderer2,
              private reportService: ReportService,
              private sharedService: AnalyticsSharedService) {
    this.reportService.getReportAreas().subscribe(
      res => {
        this.reports = res;
      },
      err => {
        this.logger.error(err);
      }
    );
    this.nebulaConf = embed.createConfiguration(NEBULA_CONF);
  }

  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  onCheckChange($event: any, item: any) {
    const checkArray: UntypedFormArray = this.reportForm.get('elements') as UntypedFormArray;
    if ($event.target.checked) {
      checkArray.push(new UntypedFormControl($event.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((el: UntypedFormControl) => {
        if (el.value === $event.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    if ($event.target.checked) {
      this.selectedCharts.push(item);
      this.renderer.addClass(document.querySelector('#chart' + item.name), 'analytics-checked-chart');
    } else {
      this.selectedCharts = this.selectedCharts.filter(el => el !== item.name);
      this.renderer.removeClass(document.querySelector('#chart' + item.name), 'analytics-checked-chart');
    }
    this.logger.log(this.selectedCharts);
  }

  onReportChange($event: any) {
    if ($event.target.checked) {
      this.selectedReport = $event.target.value;
    } else {
      this.selectedReport = '';
    }
    this.logger.log(this.selectedReport);
  }

  ngOnInit() {
    this.initializeForm();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      qData: '',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.sessionTimeoutSubscription = this.enigmaService.getIsSessionInTimeout$().subscribe(
      res => {
        if (res) {
          this.enigmaService.setIsSessionTimeout$(false);
          this.manager.removeSession(this.activeRoute);
        }
      }
    );

    this.configurationService.getConfiguration().subscribe(
      resultConfiguration => {
        this.configuration = resultConfiguration;
        this.cookieAnalyticsService.setCookieAnalytics(this.configuration).subscribe(async data => {
          this.logger.log(data);

          await this.initApp();

          this.logger.log('Real stream list', this.streamList);
          this.streamList = await this.currentSession.getStreamList();
          this.streamList.sort((a, b) => a.qName < b.qName ? -1 : 1);
          this.logger.log(this.streamList);
          this.logger.log('Real doc list', this.docList);
          this.docList = await this.currentSession.getDocList();
        });

      });
  }

  async ngOnDestroy() {
    await this.sharedService.unrenderCharts(this.nebulaCharts, this.nebula);
    this.logger.log('NEW REPORT DESTROYED');
  }

  initializeForm() {
    this.reportForm = this.fb.group({
      name: [null, [Validators.required]],
      area: [null, [Validators.required]],
      elements: this.fb.array([], [Validators.required]),
      app: [null, [Validators.required]],
      sheet: [null, [Validators.required]],
      stream: [null, [Validators.required]],
      theme: ['sense', []],
      enableSelections: [1, []],
      enableInteractions: [1, []],
      maxRows: [8,  [Validators.min(1), Validators.max(20), Validators.required]],
      maxCols: [6,  [Validators.min(1), Validators.max(20), Validators.required]],
      filters: [null, []]
    });
  }

  streamLoader(): Observable<boolean> {
    return of(this.streamList).pipe(
      map(streamList => !!streamList),
      startWith(!this.streamList)
    );
  }

  appLoader(): Observable<boolean> {
    return of(this.reportForm.value.stream).pipe(
      map(stream => !!stream),
      startWith(!this.reportForm.value.stream)
    );
  }

  selectStream(): void {
    this.showApp = true;
    this.showSheet = false;
    this.areChartsVisible = false;

    if (this.docList && this.docList.length > 0) {
      this.filterDocumentsByStream = [];
      this.docList.forEach((document: any) => {
        if (document.qMeta && document.qMeta.stream && document.qMeta.stream.id !== undefined && document.qMeta.stream.id !== null &&
          document.qMeta.stream.id === this.reportForm.value.stream
        ) {
          this.filterDocumentsByStream.push(document);
        }
      });
    }
  }

  async selectApp(event: any) {
    // Prevent sheets loading
    const selectedValue = event.target.value;

    if (selectedValue === '0') {
      this.showSheet = false;
      return;
    }
    this.urlToQlikApp = selectedValue;
    this.showSheet = true;
    this.inputsLoader$.next(true);
    this.sheetLoading$.next(true);
    this.areChartsVisible = false;
    this.formValue.sheet.reset();
    this.sheetSelected = [];
    await this.initApp(selectedValue);
    this.sheetList = [];
    await this.openDocument();
    this.sheetLoading$.next(false);

  }

  openQlikSense(): void {
    const qlikUrl = this.configuration.protocolHttp + '://' + this.configuration.host + '/' +
      this.configuration.proxy + '/sense/app/' + this.urlToQlikApp;
    this.sharedService.openQlikSense(qlikUrl);
  }

  async initApp(app = 'engineData') {
    this.areChartsVisible = false;
    const url = this.configuration.protocolSocket + '://' + this.configuration.host + '/' + this.configuration.proxy +
      '/app/' + app;
    // await this.enigmaService.init(
    //   this.configuration.protocolSocket + '://' + this.configuration.host + '/' +
    //   this.configuration.proxy + '/app',
    //   this.reportForm.value.app
    // );
    this.currentSession = await this.enigmaService.getSession(url);
    // await this.openDocument();
    this.showButton = true;
  }

  async showCharts() {
    this.areChartsVisible = false;
    this.showButton = true;
    const sheetFromQlik = await this.currentSession.getActiveDoc();
    this.nebula = this.nebulaConf(sheetFromQlik);
    this.objects = [];
    this.filters = [];
    this.loadingSubjects = [];
    if (sheetFromQlik) {
      this.areChartsVisible = true;
      await Promise.all(this.reportForm.value.sheet.map(async (sheet: any) => {
        const tempSheet = this.filterSheetsByApp.filter(item => item.qInfo.qId === sheet);
        await Promise.all(tempSheet[0].qData.cells.map(async (element: any) => {
          const filtersFromQlik = await sheetFromQlik.createSessionObject(PARAMS_DIMENSIONS_LIST);
          const filterLayout = await filtersFromQlik.getLayout();
          this.filterSelect = [];
          this.allFilters = [];

          filterLayout.qDimensionList.qItems.forEach(filter => {
            this.filterSelect.push({id: filter.qInfo.qId, title: filter.qMeta.title, sheet: tempSheet[0].qInfo.qId});
            this.allFilters.push(filter);
          });

          const objFromQlik = await sheetFromQlik.getObject(element.name);
          const layout = await objFromQlik.getLayout();
          const object = {
            name: null,
            title: null,
            type: null,
            sheet: null
          };
          object.title = element.name;
          object.name = element.name;
          object.sheet = tempSheet[0].qInfo.qId;
          if (layout !== null) {
            object.title = layout.title;
          }
          object.type = element.type;

          if (NEBULA_CONF.types.map(e => e.name).indexOf(layout.visualization) !== -1) {
            this.objects.push(object);
            this.loadingSubjects.push(new LoadingSubject(element.name, true));
          }
        }));
      }));

      const selections = await this.nebula.selections();
      selections.mount(document.querySelector('.listbox'));
      await this.renderCharts();
    }
  }

  async renderCharts() {
    for (let element of this.objects) {
      this.chartsLoaded = true;
      const n = await this.sharedService.renderCharts(element.name, this.nebula, '.chart' + element.name);
      this.nebulaCharts = [...this.nebulaCharts, n];
      this.completeLoading(element.name);
    }
  }

  getLoading$(chartId: string): Observable<boolean> {
    const matchedSubject = this.loadingSubjects.find((el: any) => el.id === chartId);
    const matchedValue = matchedSubject ? matchedSubject.value : null;
    return matchedValue.asObservable();
  }

  completeLoading(chartId: string) {
    const matchedSubject = this.loadingSubjects.find((el: any) => el.id === chartId);
    if (matchedSubject) {
      const value = matchedSubject.value.getValue();
      matchedSubject.value.next(!value);
    }
  }

  async closeSession() {
    this.manager.actionOpen();
  }

  private async openDocument() {
    const document = await this.currentSession.openDoc(this.reportForm.value.app);
    this.document = document;
    if (document) {
      const sessionObject = await document.createSessionObject(PARAMS_SESSION_OBJECT);
      if (sessionObject) {
        const layout: any = await sessionObject.getLayout();
        this.sheetList = [];
        if (layout && layout.qAppObjectList && layout.qAppObjectList.qItems && layout.qAppObjectList.qItems.length > 0) {
          layout.qAppObjectList.qItems.forEach((element) => {
            this.sheetList.push(element);
          });
        }

        this.sheetList.sort((a, b) => a.qMeta.title < b.qMeta.title ? -1 : 1);
        this.sheetSelect = [];
        this.filterSheetsByApp = this.sheetList;
        this.filterSheetsByApp.forEach(res => {
          this.sheetSelect.push({id: res.qInfo.qId, title: res.qMeta.title, qData: res.qData});
        });

        this.inputsLoader$.next(false);

        this.logger.log(this.sheetSelect);
        if (this.filterSheetsByApp[0]) {
          this.sheetSelected[0] = this.filterSheetsByApp[0];
        }
      } else {
        this.logger.error('session object is null');
      }
    } else {
      this.logger.error('document is null');
    }
  }


  getFormErrors(form: AbstractControl) {
    if (form instanceof FormControl) {
      return form.errors || null;
    }
    if (form instanceof UntypedFormGroup) {
      const groupErrors = form.errors;
      const formErrors = groupErrors ? {groupErrors} : {};
      Object.keys(form.controls).forEach(key => {
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          formErrors[key] = error;
        }
      });
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  openSaveModal(savereport) {
    this.modal = this.modalService.open(savereport);
  }

  closeTemplate() {
    this.modal.close();
  }

  saveReport(savereport) {
    const elements: ReportElement[] = [];
    this.submitted = true;
    if (this.reportForm.invalid) {
      const input = this.el.nativeElement.querySelector(
        this.focusables.map((x) => `${x}.ng-invalid`).join(','),
      );

      if (input) {
        input.focus();
      }
      return;
    }

    this.selectedCharts && this.selectedCharts.forEach((element, index) => {
      const x = index % this.reportFormValues.maxCols;
      const y = Math.floor(index / this.reportFormValues.maxCols) % this.reportFormValues.maxRows;
      elements.push(new ReportElement(element.sheet,element.name,elementTypes.CHART,x,y,1,1));
    });


    this.formValue.filters.value && this.formValue.filters.value.forEach((element) => {
      elements.push(new ReportElement(element.sheet,element.id,elementTypes.FILTER,0,0,1,1));
    });

    const data = new ReportDetail(null, this.reportFormValues.name, this.reportFormValues.area,this.reportFormValues.app,
      this.reportFormValues.stream, this.reportFormValues.enableSelections ? 1 : 0,
      this.reportFormValues.enableInteractions ? 1 : 0, elements, this.reportFormValues.theme, this.reportFormValues.maxRows, this.reportFormValues.maxCols);

    this.reportService.createReport(data).subscribe(
      () => {
        this.modal = this.modalService.open(savereport);
        this.manager.actionOpen();
      },
      err => {
        this.logger.error(err);
      }
    );
  }


  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
      this.renderer.removeClass(element.nativeElement.parentElement, 'analytics-checked-chart');
    });
    this.selectedReport = '';
    this.selectedCharts = [];
    this.reportName = '';
    this.reportForm.reset();
  }

  get reportFormValues() {
    return this.reportForm.value;
  }

  get formValue() {
    return this.reportForm.controls;
  }

  hasPending$(): Observable<boolean> {
    const booleanObservable = of(false).pipe(delay(5000));
    return booleanObservable.pipe(
      startWith(true)
    );
  }

  getSheetsLoading(): Observable<boolean> {
    return this.sheetLoading$;
  }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {InsuranceStatus} from '../../models/domain-models/insurance-status';
import {ClaimModalComponent} from '../claim-modal/claim-modal.component';
import {ClaimDetail} from '../../models/domain-models/claim-detail';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {ProposalService} from '../../proposal.service';
import {InsuranceBackgroundService} from '../../insurance-background/insurance-background.service';
import {CustomModalService} from '../../custom-modal.service';
import {RiskCertificateService} from '../risk-certificate-service';
import {ClaimsData} from '../claims-data';
import {TranslationService} from '../../translations.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'mic-equal-resp-claims-det',
  templateUrl: './equal-resp-claims-det.component.html',
  styleUrls: ['equal-resp-claims-det.component.scss']
})
export class EqualResponsabilityClaimsDetailsComponent implements OnInit {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Input() insuranceStatus: InsuranceStatus;
  @Input() showEdit = true;

  @ViewChild('openClaimModal', {read: ViewContainerRef, static: true}) claimModalViewContainerRef: ViewContainerRef;
  componentRefClaim: any;
  genericDamageTypeList: Array<GenericEntity> = [
    new GenericEntity('1', '', this.translation.instant('Things')),
    new GenericEntity('2', '', this.translation.instant('People')),
    new GenericEntity('3', '', this.translation.instant('Mixed'))
  ];

  constructor(
    protected proposalService: ProposalService,
    protected insuranceBackgroundService: InsuranceBackgroundService,
    protected modalService: CustomModalService,
    protected riskCertificateService: RiskCertificateService,
    protected trans: TranslationService,
    protected translation: TranslateService) {
  }

  ngOnInit() {
  }

  onSubmit() {
  }

  cancel() {
  }

  openClaim(isNewClaim: boolean, claimDetails: ClaimDetail) {

    let yearList;
    let selectedClaimDetails;

    if (claimDetails != null) {
      // Edit
      yearList = new Array<string>();
      yearList.push(String(claimDetails.year));
      selectedClaimDetails = claimDetails;
    } else {
      // Add
      yearList = this.calcolateClaimYear(this.insuranceStatus);
      selectedClaimDetails = new ClaimDetail(0, '', null, null, null, 0, null, '', null, '');
    }

    this.riskCertificateService.setClaimsData(
      new ClaimsData(
        isNewClaim, this.insuranceStatus, this.genericDamageTypeList, yearList, selectedClaimDetails
      )
    );

    this.modalService.openModal(this.claimModalViewContainerRef, ClaimModalComponent);
  }

  calcolateClaimYear(insuranceStatus: InsuranceStatus) {
    const claimYearList = new Array<string>();
    const claimForYear = new Map<number, number>();

    // conto il numero di elementi in pagina per anno
    for (const claimDetailElement of insuranceStatus.claimDetails) {
      claimForYear.set(Number(claimDetailElement.year),
        (claimForYear.get(Number(claimDetailElement.year)) ? claimForYear.get(Number(claimDetailElement.year)) : 0) + 1);
    }


    insuranceStatus.claimsReportYears.forEach(claimReportYear => {
      if (claimReportYear && claimReportYear.pairResponsibility && claimReportYear.pairResponsibility.total > '0' &&
        Number(claimReportYear.pairResponsibility.total) >
        (claimForYear.get(claimReportYear.yearReal) ? claimForYear.get(claimReportYear.yearReal) : 0)) {
        claimYearList.push(String(claimReportYear.yearReal));
      }
    });

    return claimYearList;
  }

  removeClaim(id) {
    this.insuranceStatus.claimDetails = this.insuranceStatus.claimDetails.filter(obj => obj.id !== id);
    const dateOfBirth = this.proposalService.getDateOfBirth();
    const contractId = this.proposalService.getContractId();
    this.insuranceBackgroundService.updateInsuranceStatus(contractId, this.insuranceStatus, dateOfBirth).subscribe(
      (data) => {
        this.insuranceBackgroundService.setCorrectIdOnCompany(data);
        this.insuranceBackgroundService.setInsuranceStatus(data);
        this.cancel();
      }
    );
  }

  claimDetailsTrackByFn(index, claimDetail: ClaimDetail) {
    return claimDetail.id;
  }

  translateBoolean(value) {
    return this.trans.translateBoolean(value);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { EventNotificator } from '../../../event-notificator';
import { LifeRoleService } from '../../../services/life-role.service';
import { LifeSessionService } from '../../../services/life-session-service';
import { SelectSubjectComponent } from '../select-subject/select-subject.component';
import { PolicyRole } from './../../../models/policy.model';
import { PolicyService } from './../../../services/policy-service';
import { AnagEditPartyResolver } from '@rgi/anag';



@Component({
  selector: 'lic-select-holder',
  templateUrl: './select-holder.component.html',
  styleUrls: ['./select-holder.component.css']
})
export class SelectHolderComponent extends SelectSubjectComponent implements OnInit, EventNotificator {

  @Input() parentForm: UntypedFormGroup;
  @Input() val: PolicyRole;
  @Input() showTrash = true;
  @Input() disableTrash = false;
  @Input() roleCode: string;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  public editable = true;
  public select;
  public label: string;
  coreAuthService: any;

  constructor(protected lifeSessionService: LifeSessionService,
              public lifeRoleService: LifeRoleService,
              public policyService: PolicyService,
              public translateService: TranslationWrapperService,
              protected anagEditResolver: AnagEditPartyResolver) {
    super(lifeRoleService, policyService, translateService, anagEditResolver);
    this.select = this.translateService.getImmediate('lic_select');
  }

  ngOnInit() {
    this.role = this.roleCode;
    this.label = this.lifeSessionService.partyRoles.find(el => el.codice === this.roleCode).descrizione;
    this.val = this.lifeRoleService.getPolicyHolder();
  }

  public resetMethod() {
    this.val = null;
    this.delete.emit(this.val);
  }

  get hideHolderDeleting(): boolean {
    return !this.showTrash || this.policyService.isFromProposalModification;
  }

}

export const CONSULTATION_LIST_SCHEMA = {
    rows: [
        {
            name: 'tableExpansion',
            title: '',
        },
        {
            name: 'number',
            title: 'CONTRACT_CONSULTATION.NUMBER',
        },
        {
            name: 'description',
            title: 'CONTRACT_CONSULTATION.DESCRIPTION',
        },
        {
            name: 'contractingSubject',
            title: 'CONTRACT_CONSULTATION.POLICYHOLDER',
        },
        {
            name: 'effectDate',
            title: 'CONTRACT_CONSULTATION.EFFECTIVE_DATE',
            format: {
              pipe: 'date',
              args: ['dd/MM/yyyy']
            }
        },
        {
            name: 'state',
            title: 'CONTRACT_CONSULTATION.STATE',
        },
        {
            name: 'actions',
            title: '',
            actions: [
              {
                name: 'PASTE',
                styleClass: 'rgi-ui-icon-paste'
              },
              {
                name: 'DETAILS',
                styleClass: 'rgi-ui-icon-right-arrow'
              }
            ]
        }
    ],
    header: ['tableExpansion', 'number', 'description', 'contractingSubject', 'effectDate', 'state', 'actions']
};

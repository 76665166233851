import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';
import { OpenTypeSharedComponent } from '../../dsc-shared-model/dsc-model-enums';
import { AddressEntityData } from '../../dsc-shared-model/dsc-model-address';

@Component({
  selector: 'claims-dsc-damaged-parts-modal',
  templateUrl: './dsc-damaged-parts-modal.component.html',
  styleUrls: ['./dsc-damaged-parts-modal.component.scss'],
})
export class DscDamagedPartsModalComponent implements OnModalClose {
  enumTypeOpenTitle: OpenTypeSharedComponent = OpenTypeSharedComponent.isTitleComponent;
  @Input() addressData: AddressEntityData;
  updatedAddressData: AddressEntityData = null;
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Optional() @Inject(DIALOG_DATA) data: any) {
    this.addressData = data.address;
  }


  submitForm() {
    this.addressData = this.updatedAddressData
    this.closeModal();
  }

  closeModal() {
    this.modalClose.emit(this.addressData);
  }

  outputAddressData(event: AddressEntityData) {
    this.updatedAddressData = event;
  }


}

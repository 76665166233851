import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  Self,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {RgiRxValueOptionDirective} from '../rgi-rx-value-option.directive';
import {startWith, switchMap, tap} from 'rxjs/operators';
import {RgiRxSemanticStatus, SCALAR_TYPE} from '@rgi/rx';
import {merge, Subject, Subscription} from 'rxjs';
import {RgiRxButtonDirective} from '../rgi-rx-button/rgi-rx-button-directive';
import {
  RgiRxControlForwardClass,
  RgiRxErrorStateMatcher,
  RgiRxFormControl
} from '../rgi-rx-form-elements-api';
import {RGI_RX_OPTION_A11Y_CONFIG, RgiRxOptionA11yConfig} from '../../a11y';
import {RGI_RX_FORM_FILED_INPUTS} from '../rgi-rx-form-elements-meta';

@Component({
  selector: 'rgi-rx-button-toggle',
  templateUrl: './rgi-rx-button-toggle.component.html',
  providers: [
    {
      provide: RgiRxFormControl,
      useExisting: RgiRxButtonToggleComponent
    },
    {
      provide: RGI_RX_OPTION_A11Y_CONFIG,
      useValue: {
        useAriaPressed: true
      } as RgiRxOptionA11yConfig
    }
  ],
  host: {
    '[id]': 'id',
    class: 'rgi-ui-button-toggle rgi-ui-form-control',
    '[class.rgi-ui-button-toggle-rounded]': 'variant === "rounded"',
    '[attr.required]': 'required',
    '[class.rgi-ui-error]': 'hasError()',
    role: 'group',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false',
    '[attr.aria-disabled]': 'disabled',
    '[attr.aria-label]': 'ariaLabel'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: [
    ...RGI_RX_FORM_FILED_INPUTS
  ]
})
export class RgiRxButtonToggleComponent extends RgiRxFormControl<any> implements OnChanges, ControlValueAccessor, AfterContentInit, OnDestroy {
  private _valueOptionSubscription = Subscription.EMPTY;
  private _color: RgiRxSemanticStatus | string = 'default';
  private _variant?: | 'dashed' | 'rounded' | 'icon' | string;
  private _valueChange = new Subject<SCALAR_TYPE>();

  @ContentChildren(RgiRxValueOptionDirective, {descendants: true}) _valueOptions: QueryList<RgiRxValueOptionDirective<any>>;
  @ContentChildren(RgiRxButtonDirective, {descendants: true}) _buttons: QueryList<RgiRxButtonDirective>;

  @Output() onToggle = new EventEmitter<SCALAR_TYPE>();

  onChange = (changed) => {};
  onTouched = () => {};

  private _valueChangeSubscription: Subscription = Subscription.EMPTY;

  constructor(_elementRef: ElementRef,
              private _changeDetectorRef: ChangeDetectorRef,
              _errorStateMatcher: RgiRxErrorStateMatcher,
              @Optional() @Self() ngControl?: NgControl,
              @Optional() _parentForm?: NgForm,
              @Optional() _parentFormGroup?: FormGroupDirective) {
    super(_errorStateMatcher, ngControl, _parentForm, _parentFormGroup);
    if (!!ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    this._valueChangeSubscription = this._valueChange
      .pipe(
        startWith(this.value),
        tap(value => {
          setTimeout(() => {
              this._valueOptions.filter(opt => opt.value !== value).forEach(o => o.active = false);
              const rgiRxValueOptionDirective = this._valueOptions.find(opt => opt.value === value);
              if (rgiRxValueOptionDirective) {
                rgiRxValueOptionDirective.active = true;
              }
          });
        })
      ).subscribe();

    const buttonChange$ = this._buttons.changes.pipe(
      startWith(this._buttons),
      tap((buttons) => {
        setTimeout(
          () => {
            buttons.forEach(
              (btn: RgiRxButtonDirective) => {
                btn.color = this.color;
                btn.variant = this.variant;
                btn.disabled = this.disabled;
                return btn;
              }
            );
          });
      })
    );

    const valueOption$ = this._valueOptions.changes.pipe(
      startWith(this._valueOptions),
      switchMap(options => {
        setTimeout(() => {
          this._valueOptions.forEach(v => v.disabled = this.disabled);
        });
        const value$ = options.map(option => option.value$);
        return merge(...value$);
      }),
      tap(value => {
        this._valueOptions.filter(opt => opt.value !== value).forEach(o => o.active = false);
        this.updateValue(value as SCALAR_TYPE);
        this.onTouched();
      })
    );
    this._valueOptionSubscription = merge(buttonChange$, valueOption$).subscribe();
  }

  ngOnDestroy(): void {
    this._valueOptionSubscription.unsubscribe();
    this._valueChangeSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this._valueOptions && !!changes.disabled && !changes.disabled.firstChange) {
      this._valueOptions.notifyOnChanges();
    }
    if (!!this._buttons && !!changes.color && !changes.color.firstChange) {
      this._buttons.notifyOnChanges();
    }
    if (!!this._buttons && !!changes.variant && !changes.variant.firstChange) {
      this._buttons.notifyOnChanges();
    }
  }


  @Input() get color(): RgiRxSemanticStatus | string {
    return this._color;
  }

  set color(value: RgiRxSemanticStatus | string) {
    this._color = value;
  }

  @Input() get variant(): 'dashed' | 'rounded' | 'icon' | string {
    return this._variant;
  }

  set variant(value: 'dashed' | 'rounded' | 'icon' | string) {
    this._variant = value;
  }

  @Input() @HostBinding('class.rgi-ui-disabled') get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
  }


  @Input() get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this._valueOptions) {
      this._valueOptions.notifyOnChanges();
    }
  }

  writeValue(obj: any): void {
    this._value = obj;
    this._valueChange.next(this.value);
  }

  private updateValue(value: SCALAR_TYPE) {
    this.writeValue(value);
    this.onChange(value);
    this.onToggle.emit(value);
  }

  get forwardClasses(): RgiRxControlForwardClass {
    return ['rgi-ui-form-group-label-static'];
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ExpertEntity} from './dto/expert-entity';
import {Observable} from 'rxjs';
import {ExpertDetailEntity} from './dto/expert-detail-entity';


@Injectable({
  providedIn: 'root'
})
export class ExpertUtilityService {

  private baseApiUrl: string;
  private baseApiUrlRs: string;
  private urlservice = '/claims';
  private expertList: ExpertEntity[] = null;
  // experts to be fetched when in the 'new expert link modal'
  private expertLinkedSearchList: ExpertEntity[] = null;
  private searchForm: UntypedFormGroup;
  private searchFormLinkedExpert: UntypedFormGroup;
  private expertDetail: ExpertDetailEntity;
  private linkedExpertsDetail: ExpertEntity[] = null;

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrlRs = environment.api.portal.host + environment.api.portal.path;
  }

  getLinkedExpertsDetail(): ExpertEntity[] {
    return this.linkedExpertsDetail;
  }

  setLinkedExpertsDetail(tmp: ExpertEntity[]) {
    this.linkedExpertsDetail = tmp;
  }

  getExpertLinkedSearchList(): ExpertEntity[] {
    return this.expertLinkedSearchList;
  }

  setExpertLinkedSearchList(tmp: ExpertEntity[]) {
    this.expertLinkedSearchList = tmp;
  }

  getExperts(): ExpertEntity[] {
    return this.expertList;
  }

  setExperts(expertList: ExpertEntity[]) {
    this.expertList = expertList;
  }

  getExpertDetail(): ExpertDetailEntity {
    return this.expertDetail;
  }

  setExpertDetail(expDetail: ExpertDetailEntity) {
    this.expertDetail = expDetail;
  }

  getExpertSearchResult(): Observable<any> {
    // only pass relevant values
    const varMap = new Map();
    this.expertList = [];
    // also manage uma
    if (this.searchForm.value.isUmaUniqueKeyEnabled) {
      const country = this.searchForm.value.country;
      // console.log('COUNTRY : ');
      if (country !== '') {
        // filter out not relevant from form
        Object.keys(this.searchForm.controls).forEach((key: string) => {
          const control = this.searchForm.get(key);
          // console.log('KEY: ', key);
          // if the first two letters are capitals, it's an uma field
          if (/[A-Z]/.test(key.charAt(0)) && /[A-Z]/.test(key.charAt(1))) {
            const ct = key.substring(0, 2);
            // console.log('comparing countries : ', country , ' and  ', ct);
            if (ct !== country) {
              this.searchForm.removeControl(key);
              // console.log('key removed : ', key);
            } else {
              // chosen country, check which fields to remove
              if (this.searchForm.value.subjectType === '1') {
                if (key.substring(2, 4) === '-G') {
                  this.searchForm.removeControl(key);
                }
              } else if (this.searchForm.value.subjectType === '2') {
                if (key.startsWith(country + '-C')) {
                  this.searchForm.removeControl(key);
                }
              }
            }
          }
        });
      }
    }

    Object.keys(this.searchForm.controls).forEach((key: string) => {
      const control = this.searchForm.get(key);
      // console.log('KEY : ', key, ' VALUE: ' , control.value);
      if (this.searchForm.value[key] && this.searchForm.value[key].length) {
        // TODO : is this case considered?
        // make sure to remove UMA fields that may have become not relevant
        // possibly due to multiple sequences of "country selection - input values - country change"
        const country = this.searchForm.value.country;
        if (country && country.length > 0 && key.startsWith(country)) {
          // it is a UMA key. extract only end of substring IT-G-Ckey2 or IT-Ckey2
          key = key.substring(key.length - 4, key.length);
        }
        varMap.set(key, control.value);
      }
    });

    console.log(varMap);
    let getQueries = '';
    varMap.forEach((value, key, map) => {
      getQueries = getQueries.concat(key + '=' + value + '&');
    });
    // remove last '&'
    getQueries = getQueries.slice(0, -1);

    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/getExpertsListFiltered?' + getQueries
    );

    //  return this.expertList;
  }


  // basically the same behaviour of the main search,
  // the form saved to utility changes
  // result MUST omit the current expert, limit case
  getExpertLinkSearchResult(): Observable<any> {
    // only pass relevant values
    const varMap = new Map();
    this.expertLinkedSearchList = [];
    // also manage uma
    if (this.searchFormLinkedExpert.value.isUmaUniqueKeyEnabled) {
      const country = this.searchFormLinkedExpert.value.country;
      // console.log('COUNTRY : ');
      if (country !== '') {
        // filter out not relevant from form
        Object.keys(this.searchFormLinkedExpert.controls).forEach((key: string) => {
          const control = this.searchFormLinkedExpert.get(key);
          // console.log('KEY: ', key);
          // if the first two letters are capitals, it's an uma field
          if (/[A-Z]/.test(key.charAt(0)) && /[A-Z]/.test(key.charAt(1))) {
            const ct = key.substring(0, 2);
            // console.log('comparing countries : ', country , ' and  ', ct);
            if (ct !== country) {
              this.searchFormLinkedExpert.removeControl(key);
              // console.log('key removed : ', key);
            } else {
              // chosen country, check which fields to remove
              if (this.searchFormLinkedExpert.value.subjectType === '1') {
                if (key.substring(2, 4) === '-G') {
                  this.searchFormLinkedExpert.removeControl(key);
                }
              } else if (this.searchFormLinkedExpert.value.subjectType === '2') {
                if (key.startsWith(country + '-C')) {
                  this.searchFormLinkedExpert.removeControl(key);
                }
              }
            }
          }
        });
      }
    }

    Object.keys(this.searchFormLinkedExpert.controls).forEach((key: string) => {
      const control = this.searchFormLinkedExpert.get(key);
      // console.log('KEY : ', key, ' VALUE: ' , control.value);
      if (this.searchFormLinkedExpert.value[key] && this.searchFormLinkedExpert.value[key].length) {
        // TODO : is this case considered?
        // make sure to remove UMA fields that may have become not relevant
        // possibly due to multiple sequences of "country selection - input values - country change"
        const country = this.searchFormLinkedExpert.value.country;
        if (country && country.length > 0 && key.startsWith(country)) {
          // it is a UMA key. extract only end of substring IT-G-Ckey2 or IT-Ckey2
          key = key.substring(key.length - 4, key.length);
        }
        varMap.set(key, control.value);
      }
    });

    console.log(varMap);
    let getQueries = '';
    varMap.forEach((value, key, map) => {
      getQueries = getQueries.concat(key + '=' + value + '&');
    });
    // remove last '&'
    getQueries = getQueries.slice(0, -1);

    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/getExpertsListFiltered?' + getQueries
    );

    //  return this.expertList;
  }

  getExpertSearchFormGroup(): UntypedFormGroup {
    if (this.searchForm) {
      return this.searchForm;
    } else {
      return null;
    }
  }

  setExpertsSearchFormGroup(formGroup: UntypedFormGroup) {
    this.searchForm = this.formBuilder.group(formGroup.getRawValue());
  }

  setLinkExpertsSearchFormGroup(formGroup: UntypedFormGroup) {
    this.searchFormLinkedExpert = this.formBuilder.group(formGroup.getRawValue());
  }

  getLinkExpertsSearchFormGroup(): UntypedFormGroup {
    if (this.searchFormLinkedExpert) {
      return this.searchFormLinkedExpert;
    } else {
      return null;
    }
  }

  setExpertSearchResult(expertList: ExpertEntity[], searchForm: UntypedFormGroup) {
    this.expertList = expertList;
    this.searchForm = searchForm;
  }

  cleanExpertSearch() {
    this.expertList = null;
    this.searchForm = null;
    this.expertDetail = null;
    this.expertLinkedSearchList = null;
    this.searchFormLinkedExpert = null;
  }

// servizi rest

  getConfigExpertsFilter() {
    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/expertsSearchConfiguration');
  }

  getExpertDetails(id: bigint) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/getExpertDetails?id=' + id);
  }

  // generic call, one expert to (1-n) experts
  saveNewExpertLink(idExpertMain: string, newLinksExperts: string[]) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/createLinkExpert',
      {idExpert: idExpertMain, idExpertLinked: newLinksExperts});
  }

  getLinkedExperts(idExpert: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/getListLinkedExpert?id=' + idExpert);
  }

  deleteExpertLink(idExpertTax: string, idExpertDelete: string) {
    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/deleteLinkExpert',
      {idExpert : idExpertTax, idLinkedExpert: idExpertDelete});
  }


}

<form [formGroup]="formGroup">
  <div *ngIf="addressesVisible && !!addresses.length">
    <div class="title-accordion" data-qa="accordion-postalAddress">
      <label>
        <input type="radio" class="btn btn-link stretched-link" formControlName="contactType" value="POSTAL_MAIL">
        INDIRIZZO POSTALE
      </label>
    </div>
    <div class="submenu" *ngIf="isSelectedContactType('POSTAL_MAIL')">
      <div *ngFor="let address of addresses">
        <label class="radio-custom-control-label">
          <input formControlName="postalAddress" data-qa="radio-postalAddresses" type="radio" class="radio-custom-control-input" [value]="address">
          <lpc-address [address]="address"></lpc-address>
        </label>
        <button type="button" class="delete-contact" *ngIf="isDeleatable(address)" (click)="deleteContact(address)">
          <span class="rgifont rgi-trash"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="exaple-row add-contact" *ngIf="isSelectedContactType('POSTAL_MAIL') && handleNewContact === '3'">
    <input *ngIf="!isAddressReceived"
        data-qa="add-new-contact"
        class="drop-container dashedSelect add-new-contact"
        value="{{'Nuovo'}}"
        (click)="addNewContact()" style="margin-left: 0px" readonly> <!-- aggiunto style inline per non mettere l'important sul css -->
  </div>
  <div *ngIf="emailsVisible && !!emails.length">
    <div class="title-accordion" data-qa="accordion-radio">
      <label>
        <input type="radio" class="btn btn-link stretched-link" formControlName="contactType" value="EMAIL">
        EMAIL
      </label>
    </div>
    <div class="submenu" *ngIf="isSelectedContactType('EMAIL')">
      <div *ngFor="let email of emails">
        <label class="radio-custom-control-label">
          <input formControlName="email" type="radio" data-qa="radio-email" class="radio-custom-control-input" [value]="email">
          <span>{{ email }}</span>
        </label>
      </div>
    </div>
  </div>
  <div *ngIf="homeInsurancesVisible && !!homeInsurances.length">
    <div class="title-accordion" data-qa="accordion-homeInsurances">
      <label>
        <input type="radio" class="btn btn-link stretched-link" formControlName="contactType" value="HOME_INSURANCE">
        HOME INSURANCE
      </label>
    </div>
    <div class="submenu" *ngIf="isSelectedContactType('HOME_INSURANCE')">
      <div *ngFor="let homeInsurance of homeInsurances">
        <label class="radio-custom-control-label">
          <input formControlName="phoneOrEmail" type="radio" class="radio-custom-control-input" data-qa="radio-homeInsurance" [value]="homeInsurance">
          <span>{{ homeInsurance }}</span>
        </label>
      </div>
    </div>
  </div>
</form>

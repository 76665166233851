<template #coassModalOverlay></template>


<div [formGroup]="parameterControlForm" *ngIf="parameterControlForm">
<!--
    <mb-advanced-select formControlName="coassType" label="Coassicurazione:" required="true" [items]="coass.coassTypes"
        bindValue="id" bindLabel="description" clearable="1" [customTrackByFn]="genericEntitiesTrackByFnCustom"
        class='col-sm-4 col-xs-12' (changeValue)="onValueChange('coassType')" disableButtonMode="true">
    </mb-advanced-select>
-->
	<div class="col-sm-4 col-xs-12">
		<label translate>Coinsurance:</label>
		<pnc-required required="true"></pnc-required>
		<select (change)="onValueChange('coassType')" formControlName="coassType" class="select-nativa">
			<option *ngFor="let ct of coass.coassTypes; index as i; trackBy: genericEntitiesTrackByFnCustom"
					[ngValue]="ct.id">{{ct?.description}}
			</option>
		 </select>
		 <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
	</div>
    <div class="col-sm-4 col-xs-12" *ngIf="coass.config.divideCoassVisible">
        <label class="label" translate>Percentuale nostra:</label>
        <pnc-required required="true" *ngIf="coass.config.ourCoassPercentageRequired"></pnc-required>
        <input (change)="onValueChange('ourCoassPercentage')" id="ourCoassPercentage" type="number"
            formControlName="ourCoassPercentage" min="0" class="form-control mic-input">
    </div>
    <div class=" col-sm-4 col-xs-12" *ngIf="coass.config.divideCoassVisible">
		<div class="label">&nbsp;</div>
        <button class="btn btn-primary btn-block"  (click)="onDivideQuote()" translate="">{{coass.config.divideCoassName}}</button>
    </div>
</div>

import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';

@Component({
  selector: 'lpc-reinvestment-data-detail',
  templateUrl: './reinvestment-data-detail.component.html',
  styleUrls: ['./reinvestment-data-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReinvestmentDataDetailComponent implements OnInit, AfterViewChecked {
  // formatter Options
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2
  };

  @Input() id: string;
  @Input() data: any;
  @Output() eventPropagation = new EventEmitter<any>();

  constructor(@Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix, @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
  }

  ngOnInit() {
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  ngAfterViewChecked() {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

}

import { State, Unit } from "./dsc-model-utility";

export class InsurancePolicy {
    extension: any;
    causative: {
      code: string;
      description: string;
      identification: string;
    };
    policyNumber: string;
    premium: string;
    licensePlateNumber: any;
    subscriptorVatNumber: any;
    issueDate: string;
    subscriptorFiscalCode: string;
    enumobliviontype: any;
    expirationDate: string;
    cancellationDate: any;
    currencyDescription: string;
    proposalNumber: string;
    currencySymbol: string;
    validityExpirationDate: string;
    originalEffectDate: string;
    policyType: {
      codice: string;
      descrizione: string;
    };
    currencyNumberOfDecimals: string;
    currencyCode: string;
    description: string;
    nodeDescription: string;
    contractingSubject: string;
    state: State = new State()
    currencyId: string;
    objectId: string;
    subscriptorId: string;
    effectDate: string;
    product: string;
    nodeCode: string;
    productCode: string;
    category: {
      codice: string;
      descrizione: string;
    };
    isCancelled?: boolean;
    units: Unit[] = [];
    claimBranchCodes: any;
  }

  export class PolicyData {
    policyNumber: string = '';
    greenCardNumber: string = '';
    effectDate: Date = null;
    expirationDate: Date = null;
    agencyDescription: string = '';
    isMaterialLossCovertureOnPolicy: boolean = false;
    address: any;
    phone: string[] = [];
    email: string[] = [];
    company: any;
}
  
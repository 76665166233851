<div class="btn-group" ngbDropdown (openChange) = "dropdownToggle($event)">
	<button id="actions-button" type="button" class="btn btn-xs btn-flat btn-primary" ngbDropdownToggle>
        <span class="caret" *ngIf="!functionsLoading"></span> 
        <span class="rgifont rgi-refresh spin" *ngIf="functionsLoading"></span>
	</button>
	<ul class="dropdown-menu task-actions-dropdown-menu dropdown-menu-right" ngbDropdownMenu role="menu" aria-labelledby="actions-button">
		<li role="menuitem" *ngIf="runActivityEnabled">
            <a role="button" (click)="runActivity()">{{ '_CLAIMS_._RUN_ACTIVITY' | translate }}</a></li>
		<li role="menuitem" *ngIf="updateAssigneeEnabled">
            <a role="button" (click)="updateAssignee()">{{ '_CLAIMS_._TAKE_CHARGE_ACTIVITY' | translate }}</a>
	    </li>
	    <li role="menuitem">
	      <a role="button" (click)="reassigntask($event)">{{ '_CLAIMS_._REASSESS_TASK' | translate }}</a>
		</li>

		<!--
	    <li role="menuitem">
          <a role="button" (click)="related()">{{ '_CLAIMS_._RELATED' | translate }}</a>
		</li>
		-->
    </ul>    
</div>

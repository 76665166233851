export const RGI_RX_FORM_ELEMENTS_i18n = {
  it: {
    required: 'richiesto',
    max: '{{actual}} è maggiore del valore massimo accettato {{max}}',
    min: '{{actual}} è minore del valore minimo accettato {{min}}',
    pattern: '{{actualValue}} formato non accettato, richiesto {{requiredPattern}}',
    minlength: `valore troppo corto, richiesta la lunghezza di {{requiredLength}} caratteri ma l'attuale è {{actualLength}}`,
    maxlength: `valore troppo lungo, richiesta la lunghezza di {{requiredLength}} caratteri ma l'attuale è {{actualLength}}`,
    rgiRxAcceptFileType: 'solo le seguenti estensioni sono supportate: {{rgiRxAcceptFileType}}',
    email: 'la mail non è valida',
    RGI_RX: {
      INPUT_FILE: {
        SELECT: 'seleziona',
        SUPPORTED: 'formati file supportati'
      }
    }
  },
  en: {
    required: 'required',
    max: '{{actual}} is greater than maximum accepted value {{max}}',
    min: '{{actual}} is lower than minimum accepted value {{min}}',
    pattern: '{{actualValue}} format not accepted, required {{requiredPattern}}',
    minlength: 'value is too short, required {{requiredLength}} characters but actual is {{actualLength}}',
    maxlength: 'value is too long, required {{requiredLength}} characters but actual is {{actualLength}}',
    email: 'email is not valid',
    rgiRxAcceptFileType: 'only files with following extensions are allowed: {{rgiRxAcceptFileType}}',
    RGI_RX: {
      INPUT_FILE: {
        SELECT: 'select',
        SUPPORTED: 'supported file formats'
      }
    }
  },
  es: {
    required: 'requerido',
    max: '{{actual}} es mayor que el valor máximo aceptado {{max}}',
    min: '{{actual}} es inferior al valor mínimo aceptado {{min}}',
    pattern: '{{actualValue}} formato no aceptado, obligatorio {{requiredPattern}}',
    minlength: 'el valor es demasiado corto, requiere {{requiredLength}} caracteres pero el valor real es {{actualLength}}',
    maxlength: 'el valor es demasiado largo, requiere {{requiredLength}} caracteres pero el valor real es {{actualLength}}',
    email: 'el correo no es válido',
    rgiRxAcceptFileType: 'Solo se permiten archivos con las siguientes extensiones: {{rgiRxAcceptFileType}}',
    RGI_RX: {
      INPUT_FILE: {
        SELECT: 'seleccione',
        SUPPORTED: 'formatos de archivo compatibles'
      }
    }
  },
  fr: {
    required: 'requis',
    max: '{{actual}} est supérieur à la valeur maximale acceptée {{max}}',
    min: '{{actual}} est inférieur à la valeur minimale acceptée {{min}}',
    pattern: '{{actualValue}} format non accepté, obligatoire {{requiredPattern}}',
    minlength: 'la valeur est trop courte, {{requiredLength}} caractères requis, mais la valeur réelle est {{actualLength}}',
    maxlength: 'la valeur est trop longue, {{requiredLength}} caractères requis, mais la valeur réelle est {{actualLength}}',
    email: `l'email n'est pas valide`,
    rgiRxAcceptFileType: 'Seuls les fichiers avec les extensions suivantes sont autorisés: {{rgiRxAcceptFileType}}',
    RGI_RX: {
      INPUT_FILE: {
        SELECT: 'sélectionner',
        SUPPORTED: 'formats de fichiers pris en charge'
      }
    }
  },
  de: {
    required: 'erforderlich',
    max: '{{actual}} ist größer als der maximal akzeptierte Wert {{max}}',
    min: '{{actual}} ist niedriger als der akzeptierte Mindestwert {{min}}',
    pattern: '{{actualValue}} Format nicht akzeptiert, erforderlich {{requiredPattern}}',
    minlength: 'wert ist zu kurz, benötigte {{requiredLength}} Zeichen, aber tatsächliche ist {{actualLength}}',
    maxlength: 'wert ist zu lang, erforderliche {{requiredLength}} Zeichen, aber tatsächliche ist {{actualLength}}',
    email: 'email ist ungültig',
    rgiRxAcceptFileType: 'es sind nur Dateien mit den folgenden Erweiterungen zulässig: {{rgiRxAcceptFileType}}',
    RGI_RX: {
      INPUT_FILE: {
        SELECT: 'auswählen',
        SUPPORTED: 'Unterstützte Dateiformate'
      }
    }
  }
};

<div class="rgi-gp-mb-1">
  <div class="rgi-gp-section">
    <span class="rgi-gp-icon rgi-ui-icon-payment rgi-gp-section-title">
      <b translate>_GP_._TITLE_._MEANS_PAYMENT_</b>
    </span>

    <button class="rgi-ui-btn rgi-ui-btn-outline rgi-gp-title-btn" *ngIf="canAddPayment()"
            data-qa="rgi-gp-add-payment-btn" (click)="addPaymentMethod()" [disabled]="isSectionDisabled">
      {{'_GP_._BTN_._ADD_PAYMENT_' | translate}}
      <span class="rgi-ui-icon-plus"></span>
    </button>
  </div>
  <form [formGroup]="paymentsForm">
    <div class="rgi-ui-text-3" data-qa="rgi-gp-debit-payments" *ngIf="getDebitMeans().length">
      <b translate>_GP_._LABEL_._DEBIT_PAYMENT_</b>
    </div>
    <rgi-rx-form-field *ngFor="let debit of getDebitMeans(); index as i">
      <input type="radio" value="{{i}}" rgiRxInput formControlName="debitRadio">
      <span [attr.data-qa]="'deb_'+i+'_description'">{{getLabel(debit)}}</span>
      <button *ngIf="debit.editable" class="rgi-ui-btn" [disabled]="isSectionDisabled"
              data-qa="rgi-gp-edit-debit-method" (click)="editPaymentMethod(debit)" color="info">
        <span class="rgi-ui-icon rgi-ui-icon-edit"></span>
      </button>
    </rgi-rx-form-field>
    <div class="rgi-ui-text-3" data-qa="rgi-gp-credit-payments" *ngIf="getCreditMeans().length">
      <b translate>_GP_._LABEL_._CREDIT_PAYMENT_</b>
    </div>
    <rgi-rx-form-field *ngFor="let credit of getCreditMeans(); index as i">
      <input type="radio" value="{{i}}" rgiRxInput formControlName="creditRadio">
      <span [attr.data-qa]="'cred_'+i+'_description'">{{getLabel(credit)}}</span>
      <button *ngIf="credit.editable" class="rgi-ui-btn" [disabled]="isSectionDisabled"
              data-qa="rgi-gp-edit-credit-method" (click)="editPaymentMethod(credit)" color="info">
        <span class="rgi-ui-icon rgi-ui-icon-edit"></span>
      </button>
    </rgi-rx-form-field>
  </form>
</div>



<template #questionnaireModalContainerRef></template>
<div class="nmf_cont_page">
  <div class="nmf_cont_sez">
    <div class="nmf_cont_titsez nmf_cont_titsez_color">
      <span class="header-icon rgifont rgi-questionari nfm_font_size"></span>
      <span class="header-text" translate>Questionnaires</span>&nbsp;
      <span class="pull-right header-text"> </span>
    </div>
    <div class="nmf_cont_dati">
      <div class="col-md-12 col-sm-12">
        <div *ngFor="let questionnaire of questionnaires" class="col-md-6 col-sm-12 odd-element">
          <div *ngIf="!questionnaire.categoryAdeguatezza" class="questionnaire-block-white col-xs-1">
            <span *ngIf="questionnaire.complete && !questionnaire.differentFromParty"
                  class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok"></span>
            <span *ngIf="!questionnaire.complete"
                  class="rgifont font-survey top-margin-ico rgi-survey_ko font-survey-ko"></span>
            <span *ngIf="questionnaire.differentFromParty"
                  class="rgifont font-survey top-margin-ico rgi-survey_ko font-survey-maybe"></span>
          </div>
          <div *ngIf="questionnaire.categoryAdeguatezza" class="questionnaire-block-white col-xs-1">
            <span *ngIf="questionnaire.complete && questionnaire.adequate"
                  class="rgifont font-survey top-margin-ico rgi-survey_ok font-survey-ok"></span>
            <span *ngIf="!questionnaire.complete || !questionnaire.adequate"
                  class="rgifont font-survey top-margin-ico rgi-survey_ko font-survey-ko"></span>
          </div>
          <div class="questionnaire-block col-xs-10">
            <div class="col-xs-12 large">
              <span>{{ questionnaire.description ? questionnaire.description : questionnaire.code }}</span><span></span>
            </div>
            <div *ngIf="!questionnaire.categoryAdeguatezza" class="col-xs-9 medium">
              <span *ngIf="questionnaire.complete && !questionnaire.differentFromParty" translate>Is compiled</span>
              <span *ngIf="!questionnaire.complete" translate>To Be Completed</span>
              <span *ngIf="questionnaire.differentFromParty" translate>Is compiled</span>
            </div>
            <div *ngIf="questionnaire.categoryAdeguatezza" class="col-xs-9 medium">
              <span *ngIf="questionnaire.complete" translate>Completed</span>
              <span *ngIf="!questionnaire.complete" translate>To Be Completed</span>
              -
              <span *ngIf="questionnaire.adequate" translate>Adequate</span>
              <span *ngIf="!questionnaire.adequate" translate>Not Adequate</span>
            </div>
            <div class="col-xs-3">
              <div class="to-right">
                <div *ngIf="!questionnaire.complete" class="questionnaire-button">
                  <span (click)="openQuestionnaire(questionnaire.code)" class="rgifont rgi-plus-square"></span>
                </div>
                <div *ngIf="questionnaire.complete || questionnaire.differentFromParty"
                     class="questionnaire-button">
                  <span (click)="editQuestionnaire(questionnaire.code)" class="rgifont rgi-pencil-square-o"></span>
                </div>
                <div *ngIf="questionnaire.complete || questionnaire.differentFromParty"
                     class="questionnaire-button">
                  <span (click)="deleteQuestionnaire(questionnaire.code)" class="rgifont rgi-trash"></span>
                </div>
              </div>
            </div>
            <div class="col-xs-9 light">
              <span translate>Filled by</span>
              <span>: {{ questionnaire?.author }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Policy } from '@rgi/digital-claims-common-angular';
import { LOCALE_ID, Inject } from '@angular/core';

@Component({
  selector: 'claims-claim-opening-policy-details',
  templateUrl: './claim-opening-policy-details.component.html',
  styleUrls: ['./claim-opening-policy-details.component.scss']
})
export class ClaimOpeningPolicyDetailsComponent implements OnInit {
  @Input()
  policy: Policy;
  @Input() contract: any;
  // TODO: check the meaning of this value
  primaryKey: number;

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.primaryKey = 0;
  }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

}

export const RGI_RX_MULTISELECT_i18n = {
  it: {
    RGI_RX: {
      MULTISELECT: {
        NO_MORE_OPTIONS: `Non ci sono più opzioni disponibili`
      }
    }
  },
  en: {
    RGI_RX: {
      MULTISELECT: {
        NO_MORE_OPTIONS: 'No more options available'
      }
    }
  },
  es: {
    RGI_RX: {
      MULTISELECT: {
        NO_MORE_OPTIONS: 'No hay más opciones disponibles'
      }
    }
  },
  fr: {
    RGI_RX: {
      MULTISELECT: {
        NO_MORE_OPTIONS: 'Plus aucune option disponible'
      }
    }
  },
  de: {
    RGI_RX: {
      MULTISELECT: {
        NO_MORE_OPTIONS: 'Keine weiteren Optionen verfügbar'
      }
    }
  }
};

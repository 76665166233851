import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountingTransactionsService } from './accounting-transactions.service';
import { AccountTransaction } from './dto/account-transaction';
import { FilterTAT } from './dto/filter-tat';
import { FilterTATDetails } from './dto/filter-tat-details';

@Component({
  selector: 'claims-accounting-transactions',
  templateUrl: './accounting-transactions.component.html',
  styleUrls: ['./accounting-transactions.component.scss']
})
export class AccountingTransactionsComponent implements OnInit {

  numeroPolizza: string;
  codiceNodo: string;
  currentPageIndex: number;
  public transactionsResult: AccountTransaction[] = [];
  public transctionPage: AccountTransaction[] = [];

  public transactions: AccountTransaction[] = [];
  public arrayTransactions: AccountTransaction[][] = new Array<AccountTransaction[]>();

  public tat: AccountTransaction;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public accountingTransactionsService: AccountingTransactionsService,
    private activeModal: NgbActiveModal,
    @Inject('eventService') private eventService: any,
  ) {

  }

  ngOnInit() {

    const filter = new FilterTAT();
    const filterDetail = new FilterTATDetails();

    filterDetail.policyNumber = this.numeroPolizza;
    // filterDetail.categories = "1"; //EntTitolo.ENUM_ECARICOTIPO_PARTITE_TECNICHE_POLIZZA
    filterDetail.considerSubTreeOfManagementNode = true;

    filter.input = filterDetail;

    this.currentPageIndex = 0;

    this.accountingTransactionsService.getTitoli(filter).subscribe((results: any) => {

      if (results != null) {
        this.transactionsResult = results.output;

        // if (this.transactionsResult.length <= 10) {
        //   this.arrayTransactions.push(this.transactionsResult);
        // } else {

        if (this.transactionsResult != null && this.transactionsResult.length > 0) {

          for (let i = 0; i < this.transactionsResult.length; i++) {

            this.tat = this.transactionsResult[i];
            if (this.tat.recordType !== '2') {
              this.transctionPage.push(this.tat);
            }

            if (this.transctionPage.length === 10) {
              this.arrayTransactions.push(this.transctionPage);
              this.transctionPage = new Array<AccountTransaction>();
            } else if (this.transctionPage.length < 10 && i === this.transactionsResult.length - 1) {
              this.arrayTransactions.push(this.transctionPage);
            }
          }

        }

        // }

        this.setTransactionsList(this.currentPageIndex);

      }



    });

  }


  public setTransactionsList(currentPageIndex: number) {
    this.transactions = this.arrayTransactions[currentPageIndex];
  }


  @Input() set titoli(titoli) {
    this.numeroPolizza = titoli[0];
    this.codiceNodo = titoli[1];
  }


  // @Input() set managementNodeCode(managementNodeCode) {
  //   this.codiceNodo = managementNodeCode ;
  // }

  getRecordTypeDescription(recordTypeCode: any) {

    let recordType = '';

    let nRecordType = 0;

    if (recordTypeCode != null) {
      nRecordType = Number.parseInt(recordTypeCode, 10);
    }

    switch (nRecordType) {
      case 1:
        recordType = 'PTP';
        break;
      case 2:
        recordType = 'PTS';
        break;
      case 4:
        recordType = 'PNT';
        break;
      case 8:
        recordType = 'PVA';
        break;
      default:
        recordType = ' ';
    }

    return recordType;
  }


  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    this.eventPropagation.emit('chiusuraModale');
    return false;
  }


}

import { Injectable } from '@angular/core';
import { InvestmentPlanData, InvestmentPlanFund, InvestmentPlanProfile } from '../lic-karma-funds/model/karma-profile';

@Injectable({
  providedIn: 'root'
})
export class InvestmentPlanCacheService {

  private investmentPlans: Map<string, InvestmentPlanData> = new Map<string, InvestmentPlanData>();

  constructor() { }
  public registerInvestmentPlan(id: string, planData: InvestmentPlanData): InvestmentPlanData {
    if (!this.investmentPlans.get(id)) {
      this.investmentPlans.set(id, planData);
    }
    return this.getInvestmentPlan(id);
  }

  public deleteInvestmentPlan(id: string): boolean {
    return this.investmentPlans.delete(id);
  }

  public getInvestmentPlan(id: string): InvestmentPlanData {
    return this.investmentPlans.get(id);
  }

  public getInvestmentPlanProfiles(id: string): InvestmentPlanProfile[] {
    const plan = this.getInvestmentPlan(id);
    return plan ? plan.profiles : [];
  }

  public getInvestmentPlanFunds(profileId: string): InvestmentPlanFund[] {
    const plan = this.investmentPlans.values().next().value;
    const profile = plan ? plan.profiles.find(el => el.id === profileId) : null;
    return profile ? profile.funds : [];
  }
}

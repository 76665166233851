import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { OperationWithFinancialStepComponent } from '../../interfaces/impl/operation-with-financial-step.component';
import {
  InvestmentAdapterConfig,
  OperationWithInvestment,
} from '../../interfaces/operation-with-financial-step';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import { FeesFactor } from '../../models/fees-factor.model';
import {
  FactorDefinition,
  PostsalesOperationObject,
} from '../../models/postsales-operations-response.model';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { KarmaProfileDefinition } from '../../modules/lpc-karma-funds/model/karma-profile-definition';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AngularCommunicationService } from './../../services/angular-communication.service';
import {
  CurrencyCacheService,
  LpcCurrencyCache,
} from '../../services/currency-cache.service';
import { OperationPropertyCode } from '../../models/operation-property-code.enum';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import {VolatilityType} from '../../modules/lpc-karma-funds/model/karma-volatility';

@Component({
  selector: 'lpc-change-combination',
  templateUrl: './change-combination.component.html',
  styleUrls: ['./change-combination.component.scss'],
})
/**
 * Represents a component for changing combinations related to financial operations and investments.
 */
export class ChangeCombinationComponent extends OperationWithFinancialStepComponent implements OnInit, OperationWithInvestment {

  // Constants and Options
  protected operationDataKey = 'change-combination';
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency',
  };

  // ViewChilds
  @ViewChild('factorAdapter')
  factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('goodsFactorAdapter')
  goodsFactorAdapter: LpcFactorAdapterComponent;

  // Arrays and Properties
  listProductFactor: FactorDefinition[] = [];
  listAssetFactor: FactorDefinition[] = [];
  requestFactor: RequestFactor[] = [];
  requestGoodFactor: RequestFactor[] = [];
  managementFees: string;

  // Computed Properties (Getter Methods)
  /**
   * Checks if there are any product factors available.
   */
  get hasListProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  /**
   * Checks if there are any asset factors available.
   */
  get hasAssetFactor(): boolean {
    return !!this.listAssetFactor && !!this.listAssetFactor.length;
  }

  // Configuration Objects (Getter Methods)
  /**
   * Gets the configuration for lpc_Investment_profiles.
   */
  get getInvestmentProfileConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      investmentMode: undefined,
      enableVolatility: this.isWithVolatility(),
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: true,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true,
    };
  }

  /**
   * Gets the configuration for lpc_investment_funds.
   */
  get getInvestmentFundConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      fundFormGroupName: 'investmentFunds',
      investmentMode: undefined,
      enableVolatility: this.isWithVolatility(),
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: false,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true,
    };
  }

  constructor(
    // protected operation: MockChangeCombinationService,
    @Inject(PV_TOKEN.POSTSALES_SERVICE)
    protected operations: PostsalesOperationsService,
    protected anag: AnagService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected karmaService: KarmaFundService,
    protected angularCommunicationService: AngularCommunicationService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    @Optional()
    @Inject(LpcCurrencyCache)
    protected currencyService: CurrencyCacheService
  ) {
    super(
      operations,
      cd,
      translate,
      injector,
      questCacheService,
      modalService,
      notifierService,
      plcQuestService,
      authService,
      anag,
      karmaService,
      angularCommunicationService
    );
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  /**
   * Lifecycle hook called after the component has been initialized.
   */
  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.updateDefinitions(result);
        this.initDisinvestmentProfiles(
          result.definitions.disinvestmentProfiles as KarmaProfileDefinition[]
        );
        this.populateInvestmentForms(result);
      })
    );
  }

  /**
   * Checks if a form is valid.
   * @param form The form to check.
   * @returns True if the form is valid or disabled, false otherwise.
   */
  isFormValid(form): boolean {
    return form.disabled ? true : form.valid;
  }

  protected getFormGroup() {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      assetFactors: new UntypedFormGroup({}),
      disinvestmentProfiles: new UntypedFormGroup({}),
      disinvestmentFunds: new UntypedFormGroup({}),
      investmentProfiles: new UntypedFormGroup({}),
      investmentFunds: new UntypedFormGroup({}),
      costs: new UntypedFormControl(),
    });
  }

  /**
   * Updates the product factors based on the provided factors.
   * @param factors The factors to update.
   */
  updateProductFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(
      this.onReload('factors').subscribe(result => {
        this.listProductFactor = result.definitions
          .productFactors as FactorDefinition[];
      })
    );
  }

  /**
   * Updates the asset factors based on the provided factors.
   * @param factors The factors to update.
   */
  updateAssetFactors(factors: RequestFactor[]) {
    this.requestGoodFactor = factors;
    this.$subscriptions.push(
      this.onReload('assetFactors').subscribe(result => {
        this.listAssetFactor = result.definitions
          .goodsFactors as FactorDefinition[];
      })
    );
  }

  /**
   * Checks if the current operation supports volatility calculation.
   * @returns True if volatility calculation is possible, false otherwise.
   */
  public isWithVolatility(): boolean {
    if (
      this.hasOperationPropertyByCode(
        OperationPropertyCode.VOLATILITY_CALCULATION
      )
    ) {
      return PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(
          OperationPropertyCode.VOLATILITY_CALCULATION
        ).booleanValue
      );
    }
    return false;
  }

  /**
   * Handles the next step for factors.
   * @param control The control to check for validity.
   * @param step The current step.
   * @param feFieldId The ID of the field in case of field errors.
   */
  onNextFactors(control, step, feFieldId) {
    this.$feErrors = [];
    if (
      this.isFormValid(this.formGroup.get(control)) &&
      !this.hasBlockingErrorsOnSteps(step)
    ) {
      this.$subscriptions.push(
        this.updateDraft(step, false).subscribe(result => {
          this.stepper.next();
        })
      );
    } else {
      this.setFeErrors(feFieldId);
    }
  }

  /**
   * Updates the definitions based on the provided result object.
   * @param result The result object to update definitions from.
   */
  private updateDefinitions(result: PostsalesOperationObject): void {
    this.listProductFactor = result.definitions
      .productFactors as FactorDefinition[];
    this.listAssetFactor = result.definitions
      .goodsFactors as FactorDefinition[];
    this.managementFees = (result.definitions.feesFactor as FeesFactor)
      ? (result.definitions.feesFactor as FeesFactor).defaultValue
      : '0';

    // aggiorno il total amount a partire dal disinvestimento effettuato
    if (!!this.disinvestmentProfiles && this.disinvestmentProfiles.length > 0) {
      this.totalAmountToInvest =
        this.disinvestmentProfiles
          .map(profile => profile.amount)
          .reduce((acc, curr) => acc + curr) - +this.managementFees;
    }
  }

  /**
   * Gets the volatility type for the operation.
   * @returns The volatility type.
   */
  public volatilityType() {
    return VolatilityType.changeCombination;
  }

  /**
   * Updates the draft for the given step with optional reload and operation data type.
   * @param step The step to update.
   * @param reload Flag indicating whether to reload the draft or not.
   * @param opDataType The operation data type to use in the update.
   * @returns An Observable emitting the updated result.
   */
  protected updateDraft(
    step: string,
    reload?: boolean,
    opDataType?: string
  ): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      concatMap(result => {
        // Extracts the 'disinvestmentProfiles' property from the 'result.definitions' object.
        const disinvestmentProfiles: any =
          result.definitions.disinvestmentProfiles;
        // Calls the 'canCalculateVolatility' method with the 'step' parameter to check if volatility calculation is possible.
        const canCalculateVolatility = this.canCalculateVolatility(step) &&
        (!!result.data.operationData.data.investmentProfiles[0].funds && result.data.operationData.data.investmentProfiles[0].funds.length);
        // Checks if the 'reload' flag is false and 'disinvestmentProfiles' exists and is not an empty array.
        if (
          !reload &&
          !!disinvestmentProfiles &&
          !!disinvestmentProfiles.length
        ) {
          // Calls the 'updateProfilesAndFunds' method to update certain profiles and funds based on 'result.definitions'.
          this.updateProfilesAndFunds(step, result.definitions);
          // Checks if volatility calculation is possible.
          if (canCalculateVolatility) {
            // Calls the 'onCalculateVolatility' method to initiate the volatility calculation.
            this.onCalculateVolatility();
            // Returns an Observable that emits the 'result' object after performing the volatility calculation.
            return this.calculateVolatility().pipe(map(_ => result));
          }
        }
        // If the above condition is not met, updates certain definitions based on 'result'.
        this.updateDefinitions(result);
        // Checks if the 'reload' flag is false and volatility calculation is possible.
        if (!reload && canCalculateVolatility) {
          // Calls the 'onCalculateVolatility' method to initiate the volatility calculation.
          this.onCalculateVolatility();
          // Returns an Observable that emits the 'result' object after performing the volatility calculation.
          return this.calculateVolatility().pipe(map(_ => result));
        }
        // If none of the above conditions are met, returns an Observable that emits the 'result' object as is.
        return of(result);
      })
    );
  }

  // ---------------------------------------- INVESTIMENTI ----------------------------------------

  // salto sempre lo step dei disinvestimenti (disinvestimento sempre fisso)
  public get skipDisinvestment(): boolean {
    return true;
  }

  private initDisinvestmentProfilesAndFunds(step, definitions: any): void {
    if (this.getNextStep(step) === this.firstDisinvestmentStep) {
      const disinvestmentProfilesDefinition = definitions.disinvestmentProfiles;
      if (!!disinvestmentProfilesDefinition) {
        // Removes existing form controls for 'disinvestmentFunds'.
        this.disinvestmentProfiles.forEach(profile => {
          (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).removeControl(
            profile.id.toString()
          );
        });
        // Updates the 'disinvestmentProfiles' property with the new 'disinvestmentProfilesDefinition'.
        this.disinvestmentProfiles = disinvestmentProfilesDefinition;
        // Initializes form values for 'disinvestmentProfiles' and 'disinvestmentFunds'.
        this.disinvestmentProfiles.forEach(profile => {
          const fundsFormValue: any = {};
          // Sets the default value for the 'profile' in 'profilesFormValue'.
          const profileValue =
            LpcKarmaFundUtils.getElementDefaultValue(profile);
          const profileId = profile.id.toString();
          const profilesFormValue = {
            [profileId]: this.convertToFractionOf100(profileValue),
          };
          // Iterates over the 'funds' array in the current 'profile'.
          profile.funds.forEach(fund => {
            // Retrieves default value for the current 'fund'.
            const fundValue = LpcKarmaFundUtils.getElementDefaultValue(fund);
            const fundId = fund.id.toString();
            // Sets the default value for the 'fund' in 'fundsFormValue'.
            fundsFormValue[fundId] = this.convertToFractionOf100(fundValue);
          });
          // Sets the 'profilesFormValue' in the 'disinvestmentProfiles' form control.
          this.formGroup
            .get('disinvestmentProfiles')
            .get(profileId)
            .setValue(profilesFormValue, { emitEvent: false });
          // Adds new form control for each 'profile' in 'disinvestmentFunds' with 'profile.id' as the control name.
          (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).addControl(
            profileId,
            new UntypedFormControl()
          );
          // Sets the 'fundsFormValue' in the newly created form control for each 'profile'.
          this.formGroup
            .get('disinvestmentFunds')
            .get(profileId)
            .setValue(fundsFormValue, { emitEvent: false });
        });
      }
    }
  }

  /**
   * Converts a value to a fraction of 100.
   * @param value The value to convert.
   * @returns The value as a fraction of 100.
   */
  public convertToFractionOf100(value: number | string = 0): number {
    if (!value) {
      return 0;
    }
    // Convert the input value to a number (if it's a string containing a comma, replace it with a dot).
    const parsedValue =
      typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;

    // Check if the parsed value is a valid number.
    if (isNaN(parsedValue) || typeof parsedValue !== 'number') {
      throw new Error(
        'Invalid input. Please provide a valid number or string representation of a number.'
      );
    }

    // Calculate the fraction of 100 and return it.
    return parsedValue / 100;
  }

  /**
   * Initializes disinvestment profiles based on the provided definitions.
   * @param definitions The definitions to initialize disinvestment profiles from.
   */
  public initDisinvestmentProfiles(
    definitions: KarmaProfileDefinition[]
  ): void {
    this.disinvestmentProfiles = definitions;

    // PREVALORIZZO I DISINVESTIMENTI
    if (this.disinvestmentProfiles.length > 1) {
      this.disinvestmentProfiles.forEach(p => {
        const definitionVal = Number(p.percent || p.percentage || 0);
        const val =
          definitionVal > 0
            ? definitionVal
            : p.maxPercentAllocation === p.minPercentAllocation
            ? p.minPercentAllocation
            : null;
        if (!this.formGroup.get('disinvestmentProfiles').get(p.id)) {
          (this.formGroup.get('disinvestmentProfiles') as UntypedFormGroup).addControl(
            p.id,
            new UntypedFormControl(val)
          );
        }
      });
    } else if (this.disinvestmentProfiles.length === 1) {
      const profileId = this.disinvestmentProfiles[0].id;
      if (!this.formGroup.get('disinvestmentProfiles').get(profileId)) {
        (this.formGroup.get('disinvestmentProfiles') as UntypedFormGroup).addControl(
          profileId,
          new UntypedFormControl(1)
        );
      }
    }
  }

  /**
   * Updates profiles and funds based on the provided step and definitions.
   * @param step The step to update.
   * @param definitions The definitions to update profiles and funds from.
   */
  public updateProfilesAndFunds(step, definitions) {
    // DISINVESTIMENTI - VISIBILITà STEP
    if (
      !!definitions.disinvestmentProfiles &&
      definitions.disinvestmentProfiles.length > 0
    ) {
      this.showDisinvestment = true;
    } else {
      this.showDisinvestment = false;
    }
    this.initDisinvestmentProfilesAndFunds(step, definitions);

    super.updateProfilesAndFunds(step, definitions);
  }

  // ---------------------------------------- INVESTIMENTI ----------------------------------------

  /**
   * Gets the transformed operation data with updated lpc_Investment_profiles.
   * @returns The transformed operation data.
   */
  protected getTransformedOperationData(): any {
    const investmentProfiles: any = LpcKarmaFundUtils.getBackendStructureOfProfiles(
      this.investmentProfiles,
      this.formGroup.getRawValue().investmentProfiles,
      this.formGroup.getRawValue().investmentFunds,
    );
    return {
      listProductFactor: PlcObjectUtils.asValidArray(this.requestFactor),
      listGoodFactor: PlcObjectUtils.asValidArray(this.requestGoodFactor),
      investmentProfiles,
    };
  }
}

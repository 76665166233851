import {QuestionFlatIResponse} from './question-flat-response';

export class QuestionnaireFlatIResponse {
  score?: number;
  code?: string;
  codeVersion?: string;
  customProperties?: any;
  codeType?: string;
  descriptionType?: string;
  questionList?: QuestionFlatIResponse[];
  description?: string;
  openDate?: string;
  category?: number;
  compulsory?: boolean;
  included?: boolean;

  constructor() {
  }
}

import { ActiveRoute } from '@rgi/rx/router';
import { ReIssueTitleUtilityService } from '../re-issue-utility/re-issue-title-utility.service';

import { ContactsEvent } from '../../models/domain-models/contacts-event';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  ReIssuePolicyDataStateManagerService
} from '../re-issue-state-manager/re-issue-policy-data-state-manager.service';
import { StepperSection } from '../../models/stepper-section';
import { Message } from '../../models/message';
import { of } from 'rxjs';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { ReIssuePolicyDataState } from '../re-issue-state-manager/re-issue-policy-data-state';
import { PushMessageHandlerService, RgiRxPushMessage } from '@rgi/rx';
import { ModalService } from '@rgi/rx/ui';
import { map, mergeMap, take } from 'rxjs/operators';
import { QuestionnaireFlatI } from '../../models/domain-models/questionnaire-flatI';
import { CommonResourceService } from '../re-issue-address-service/common-resource.service';
import { SystemProperty } from '../../models/system-properties/api-system-properties';
import { ReIssueMsgData } from '../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import { ReIssueMsgModalComponent } from '../re-issue-msg-modal/re-issue-msg-modal.component';
import { UntypedFormBuilder } from '@angular/forms';
import { AnagIssuePhoneNumber, AnagIssueSubject } from '../re-issue-anag/anag-issue';
import { Warning } from '../../models/domain-models/warning';
import { ReIssueClausesService } from '../re-issue-clauses/re-issue-clauses-service';
import {
  RegulationPremium
} from '../../models/pc-portfolio-models/regulation-models/pc-regulation-premium';
import {RgiRxUserAuthorizationService, RgiRxUserService} from '@rgi/rx/auth';
import { LpsData } from '../../models/domain-models/parameters/policy-lps-data';
import { IndexType } from '../../models/domain-models/parameters/index-type';
import { PolicyGenericDto } from '../../models/pc-portfolio-models/policy-generic-dto';
import { ReIssueProductsConfigEnum } from '../../enums/re-issue-products-config-enum';
import {
  ReIssueGenericAccordionComponent
} from "./re-issue-expansion-panels/re-issue-generic-accordion/re-issue-generic-accordion.component";

@Component({
  selector: 're-issue-policy-data',
  templateUrl: './re-issue-policy-data.component.html',
  styleUrls: ['./re-issue-policy-data.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReIssuePolicyDataComponent implements OnInit {
  @ViewChild('technicalDetailsComponent') technicalDetailsComponent: any;
  questionnairesMessages: Message[] = [];
  areaCode = 'POLICY_DATA';
  HIDDEN = ReIssueProductsConfigEnum.HIDDEN;

  technicalDetailsValid = false;
  errorMessageValid = true;
  effectDateValid = false;
  expireDateValid = false;
  generalDataValid = false;
  variablesValid = false;
  regulationPremiumValid = true;
  instalmentDateValid: boolean;
  paymentFrequencyValid: boolean;
  submitted = false;
  formSubmitted = false;
  questionnaireSubmitted = false;
  isSubjectSelected: boolean;
  validClauseForm = true;
  isDirectionalUser: boolean;
  coInsuranceFormValid = true;
  coInsurancePercValid = true;
  coInsuranceQuotasValid = true;
  coInsuranceValid = true;
  indirectCoInsuranceValid = true;
  secondRiskPoliciesValid = true;
  commonRiskPoliciesValid = true;
  linkedPoliciesValid = true;
  complementaryPoliciesValid = true;
  currentStep = StepperSection.policyData;
  stepperLabels = ['RE_ISSUE.POLICY_DATA', 'RE_ISSUE.ASSETS_DATA', 'RE_ISSUE.QUOTATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.ISSUE_STEPPER'];
  errorMessage: string;
  errorSubjectMessage: string;
  missingSubjectMessage: string;
  questionnairesErrorMessage: string;
  expireDateMessage: string;
  selectPaymentMethod: string;
  readonly PROPERTY_INTERMEDIATE_SAVING = 'PortafoglioSalvataggioIntermedio';
  bIntermediateSaving: boolean;
  protected formBuilder: UntypedFormBuilder;
  msgData: string;
  TAG_CTR_ERRORS: 'errors';
  TAG_INSURED_REQUIRED_ERROR = 'insured_required_error';
  TAG_DATE_ERROR = 'date_error';
  TAG_FORM_ERRORS = 'form_errors';
  TAG_COINSURANCE_ERROR = 'coins_error';
  TAG_REGULATION_ERROR = 'regulation_error';
  TAG_ACCORDION_ERROR = 'accordion_error';
  formComplete: boolean;
  regulationPremium: RegulationPremium = null;
  lpsData: LpsData;
  isSaveDisabled: boolean = true;

  @ViewChild('commonRiskPolicies') commonRiskPoliciesComponent: ReIssueGenericAccordionComponent;
  @ViewChild('linkedPolicies') linkedPoliciesComponent: ReIssueGenericAccordionComponent;
  @ViewChild('complementaryPolicies') complementaryPoliciesComponent: ReIssueGenericAccordionComponent;

  constructor(
    public stateManager: ReIssuePolicyDataStateManagerService<ReIssuePolicyDataState>,
    private translateService: RgiRxTranslationService,
    private commonService: CommonResourceService,
    protected customModalService: ModalService,
    private reIssueTitleUtilityService: ReIssueTitleUtilityService,
    private userService: RgiRxUserService,
    private activeRoute: ActiveRoute,
    protected errorService: PushMessageHandlerService,
    protected clausesService: ReIssueClausesService,
    private userAuthorizationService: RgiRxUserAuthorizationService
  ) {
  }

  ngOnInit() {
    this.stateManager.getState$().pipe(
      take(1),
      map(st => {
        if (!st.backFromStep) {
          this.stateManager.initForm();
        }
      })
    ).subscribe();

    this.updateSubjectModified(false);
    this.bIntermediateSaving = false;
    const st = this.stateManager.getCurrentState();
    this.commonService.getSystemPoperty(this.PROPERTY_INTERMEDIATE_SAVING).subscribe(
      (data: SystemProperty) => {
        this.bIntermediateSaving = data && data.value === '1';
        st.bIntermediateSaving = this.bIntermediateSaving;
        this.reIssueTitleUtilityService.setDescriptions(st.productName,
          st.node.description,
          st.contractorSubject,
          this.activeRoute.id);
        this.reIssueTitleUtilityService.updateNavigationTitle();
      }
    );

    this.isSubjectSelected = st.contractorSubject && st.contractorSubject.objectId != null;


    this.stateManager.getLpsData();
    const isHeadOfOffice = this.userAuthorizationService.isAuthorizedFor('admin.config.operatortype.headoffice');
    this.isDirectionalUser = this.userService.getUser<any>().isDirectionalUser || isHeadOfOffice;

  }

  updateSubjectModified(val: boolean) {
    const st = this.stateManager.getCurrentState();
    st.isSubjectModified = val;
    this.stateManager.updateState$(of(st));
  }

  onContactsDataChanges(event: ContactsEvent) {
    const st = this.stateManager.getCurrentState();
    // if (this.isSubjectSelected && state.contractorSubject) {
    switch (event.key) {
      case 'email':
        this.updateSubjectEmail(st, event.value);
        break;
      case 'mobilePhone':
        this.updateSubjectMobilePhone(st, event.value);
        break;
    }
  }

  updateSubjectEmail(st: ReIssuePolicyDataState, email: any) {
    if (st.contractorSubject.emails && st.contractorSubject.emails.length) {
      if (st.contractorSubject.emails[0] !== (email as string)) {
        st.contractorSubject.emails[0] = email;
        this.stateManager.putSubject(st);
      }
    } else {
      st.contractorSubject.emails = new Array<string>();
      st.contractorSubject.emails.push(email);
      this.stateManager.putSubject(st);
    }
    this.isSaveDisabled = false;
  }

  updateSubjectMobilePhone(st: ReIssuePolicyDataState, mobilePhone: any) {
    if (st.contractorSubject.mobilePhone && st.contractorSubject.mobilePhone.length) {
      if (st.contractorSubject.mobilePhone[0].number !== (mobilePhone as string)) {
        st.contractorSubject.mobilePhone[0] = this.updateContactNumber(st.contractorSubject.mobilePhone[0], mobilePhone);
        this.stateManager.putSubject(st);
      }
    } else {
      st.contractorSubject.mobilePhone = new Array<AnagIssuePhoneNumber>();
      st.contractorSubject.mobilePhone.push(this.setContactNumber(mobilePhone));
      this.stateManager.putSubject(st);
    }
  }


  protected setContactNumber(newPhoneNumber: string) {
    return {
      extensionSubject: null,
      internationalPrefix: null,
      localPrefix: null,
      number: newPhoneNumber,
      objectId: null
    };
  }

  protected updateContactNumber(contractorPhone: any, newPhoneNumber: string) {
    return {
      extensionSubject: contractorPhone.extensionSubject ? contractorPhone.extensionSubject : null,
      internationalPrefix: contractorPhone.internationalPrefix ? contractorPhone.internationalPrefix : null,
      localPrefix: contractorPhone.localPrefix ? contractorPhone.localPrefix : null,
      number: newPhoneNumber,
      objectId: contractorPhone.objectId ? contractorPhone.objectId : null
    };
  }

  onTechnicalDetailsFormChange(event: any) {
    if (this.effectDateValid && this.expireDateValid) {
      this.stateManager.updateProposalForm(event);
      this.validateForms();
      this.isSaveDisabled = false;
    }
  }


  onAgreementsChange(event: any) {
    this.stateManager.putAgreement(event);

    this.validateForms();
    this.isSaveDisabled = false;
  }

  onUseTaxesChange(event: any) {
    this.stateManager.putUseTax(event);
    this.validateForms();
    this.isSaveDisabled = false;
  }

  onCurrencyChange(event: any) {
    this.stateManager.putCurrency(event);
    this.validateForms();
    this.isSaveDisabled = false;
  }

  onIndexationChange(event: any) {
    this.putIndexType();
    this.isSaveDisabled = false;
  }

  onRegulationFormValidChange(val) {
    this.regulationPremiumValid = val;
    this.validateForms();
    this.isSaveDisabled = false;
  }

  onRegulationPremiumChange(event: RegulationPremium) {
    this.regulationPremium = event;
    if (event) {
      this.stateManager.putRegulationPremium(event).subscribe(() => {
        this.validateRegulationPremiumMessages();
        this.validateForms();
        this.isSaveDisabled = false;
      });
    }
  }

  onVariablesFormChange(event: any) {
    this.stateManager.updateProductVariables(event);
    this.validateForms();
    this.isSaveDisabled = false;
  }

  validateForms() {
    this.errorService.clearTag(this.TAG_DATE_ERROR);
    this.errorService.clearTag(this.TAG_FORM_ERRORS);
    this.errorService.clearTag(this.TAG_INSURED_REQUIRED_ERROR);
    this.errorService.clearTag(this.TAG_COINSURANCE_ERROR);
    this.errorService.clearTag(this.TAG_ACCORDION_ERROR);


    this.formComplete = this.technicalDetailsValid &&
      this.errorMessageValid &&
      this.effectDateValid &&
      this.expireDateValid &&
      this.generalDataValid &&
      this.variablesValid &&
      this.validClauseForm &&
      this.indirectCoInsuranceValid &&
      this.regulationPremiumValid &&
      this.coInsuranceValid &&
      this.commonRiskPoliciesValid &&
      this.linkedPoliciesValid &&
      this.complementaryPoliciesValid &&
      this.secondRiskPoliciesValid;

    if (this.formComplete || !this.submitted) {
      return;
    }
    if (!this.expireDateValid) {
      this.notifyErrorMessage(this.TAG_DATE_ERROR, 'RE_ISSUE.ERROR_ON_DATE');
    }
    if (!this.technicalDetailsValid) {
      this.notifyErrorMessage(this.TAG_FORM_ERRORS, 'RE_ISSUE.FIELDS_MARKED_IN_RED');
    }
    if (!this.coInsurancePercValid || !this.coInsuranceQuotasValid) {
      this.notifyErrorMessage(this.TAG_COINSURANCE_ERROR, !this.coInsuranceQuotasValid ? 'RE_ISSUE.NO_QUOTAS_ERROR' : 'RE_ISSUE.LEAD_COINS_ERROR');
    }
    if (!this.isSubjectSelected) {
      this.notifyErrorMessage(this.TAG_INSURED_REQUIRED_ERROR, 'RE_ISSUE.SELECT_CONTRACTOR');
    }

    if (!this.regulationPremiumValid) {
      this.validateRegulationPremiumMessages();
    }

    const accordionErrorMessages = [];
    if (!this.commonRiskPoliciesValid) {
      accordionErrorMessages.push(this.getAccordionErrorTranslation(['RE_ISSUE.NO_POLICY_ADDED', 'RE_ISSUE.COMMON_RISK_POLICIES']));
    }
    if (!this.linkedPoliciesValid) {
      accordionErrorMessages.push(this.getAccordionErrorTranslation(['RE_ISSUE.NO_POLICY_ADDED', 'RE_ISSUE.LINKED_POLICIES']));
    }
    if (!this.complementaryPoliciesValid) {
      accordionErrorMessages.push(this.getAccordionErrorTranslation(['RE_ISSUE.NO_POLICY_ADDED', 'RE_ISSUE.COMPLEMENTARY_POLICIES']));
    }
    if (!this.indirectCoInsuranceValid) {
      accordionErrorMessages.push(this.getAccordionErrorTranslation(['RE_ISSUE.NO_POLICY_ADDED', 'RE_ISSUE.INDIRECT_CO_INSURANCE_POLICIES']));
    }
    if (!this.secondRiskPoliciesValid) {
      accordionErrorMessages.push(this.getAccordionErrorTranslation(['RE_ISSUE.NO_POLICY_ADDED', 'RE_ISSUE.SECOND_RISK_POLICIES']));
    }
    accordionErrorMessages.forEach(error => {
      // Timeout prevents from overwriting the snackbar
     // setTimeout(() => {
        this.notifyErrorMessage(this.TAG_ACCORDION_ERROR, error);
     // });
    });

  }

  getAccordionErrorTranslation(value: string[]) {
    const msg = of(value);
    let output: string;
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      output = next[0] + ' in ' + next[1];
    }).unsubscribe();
    return output;
  }

  getAdditionalTechnicalData() {
    return this.stateManager.getCurrentState().policyTechnicalData?.additionalTechnicalData;
  }

  notifyErrorMessage(tag: string, content: string) {
    const errorMsg = new RgiRxPushMessage();
    errorMsg.tag = tag;
    errorMsg.status = 'danger';
    errorMsg.dismissible = false;
    errorMsg.options = { icon: 'rgi-ui-icon-alert' };
    errorMsg.content = content;
    this.errorService.notify(errorMsg);
  }

  notifyWarningMessage(message: Warning, blocker: boolean) {
    const errorMsg = new RgiRxPushMessage();
    errorMsg.tag = this.TAG_CTR_ERRORS;
    errorMsg.status = blocker ? 'danger' : 'info';
    errorMsg.dismissible = false;
    errorMsg.options = blocker ? { icon: 'rgi-ui-icon-alert' } : { icon: 'rgi-ui-icon-info' };
    errorMsg.content = message.description;
    this.errorService.notify(errorMsg);

  }

  submitForm() {
    this.formSubmitted = true;
    this.submitted = true;

    this.validateQuestionnairesWarnings(this.stateManager.getCurrentState());
    this.validateForms();
    this.putIndexType();
    if (this.checkQuestionnaires() && this.formComplete && this.regulationPremiumValid) {
      this.stateManager.submitForm(this.errorSubjectMessage).subscribe(st => {
        const block: boolean = st.warnings && !!st.warnings.find(warning => warning.blockingMessage);
        if (st.warnings && st.warnings.length) {
          st.warnings.forEach(message => {
            this.notifyWarningMessage(message, block);
          });
        }
        if (!block) {
          this.goToNextPage(st);
        }
      });
    }
  }

  private putIndexType() {
    const st = this.stateManager.getCurrentState();
    const indexType = this.technicalDetailsComponent.proposalsForm.controls.indexation.value;
    this.stateManager.putIndexType((indexType === null || indexType === '') ? new IndexType(null) : indexType)
      .subscribe(() => {
        this.onRegulationPremiumChange(st.policyTechnicalData.regulationPremiumResponse.regulationPremium);
      });
  }

  validateRegulationPremiumMessages() {
    const st = this.stateManager.getCurrentState();
    let valid = true;
    this.errorService.clearTag(this.TAG_REGULATION_ERROR);
    if (st.policyTechnicalData.regulationPremiumResponse
      && st.policyTechnicalData.regulationPremiumResponse.messages
      && st.policyTechnicalData.regulationPremiumResponse.messages.length > 0) {
      valid = false;
      st.policyTechnicalData.regulationPremiumResponse.messages.forEach((message) => {
        this.notifyErrorMessage(this.TAG_REGULATION_ERROR, message.description);
      });
    }
    if(st.isProposalRegulationChecked) {
      if (!st.policyTechnicalData.regulationPremiumResponse.regulationPremium.premiumRegulationType?.code
        || !st.policyTechnicalData.regulationPremiumResponse.regulationPremium.regulationFractioning?.code) {
        valid = false;
        this.notifyErrorMessage(this.TAG_REGULATION_ERROR, 'RE_ISSUE.MANDATORY_REGULATION');
      }
    }
    
    this.regulationPremiumValid = valid;
  }

  onTechnicalDetailsValidForm(event: any) {

    const checkForm = event !== this.technicalDetailsValid;
    if (checkForm) {
      this.technicalDetailsValid = event;
      this.validateForms();
      this.isSaveDisabled = false;
    }
  }

  onEffectDateValidForm(event: any) {
    const checkForm = event !== this.effectDateValid;
    if (checkForm) {
      this.effectDateValid = event;
      this.validateForms();
      this.isSaveDisabled = false;
    }
  }

  onExpireDateValidForm(event: any) {
    const checkForm = event !== this.expireDateValid;
    if (checkForm) {
      this.expireDateValid = event;
      this.validateForms();
      this.isSaveDisabled = false;
    }
  }

  onInstalmentDateChange(event: any) {
    const checkForm = event !== this.instalmentDateValid;
    if (event && checkForm) {
      this.stateManager.putInstalmentDate(event);

      this.instalmentDateValid = event;
      this.validateForms();
      this.isSaveDisabled = false;

    } else if (event && checkForm) {
      this.instalmentDateValid = false;
    }
  }


  onPaymentFrequencyChange(event: any) {
    if (event) {
      this.stateManager.putPaymentFrequency(event);

      this.paymentFrequencyValid = event;
      this.validateForms();
      this.isSaveDisabled = false;
    } else {
      this.paymentFrequencyValid = false;
    }
  }

  onAutomaticRenewalChange(event: any) {
    if (event) {
      this.stateManager.putAutomaticRenewal(event);
      this.isSaveDisabled = false;
    }
  }

  onValidFormVariables(event: any) {
    this.variablesValid = event;
    this.validateForms();
    this.isSaveDisabled = false;
  }

  onGeneralDataValidForm(event: any) {
    this.generalDataValid = event;
    this.validateForms();
  }

  onSubjectSelected(event: any) {

    this.isSubjectSelected = !!event;
    this.validateForms();

    const currentState = this.stateManager.getCurrentState();
    if (currentState.warnings) {
      const index = currentState.warnings.findIndex(x => {
        return x.code === 'xxx';
      });
      if (index !== -1) {
        currentState.warnings.splice(index, 1);
        this.stateManager.updateState$(of(currentState));
      }
    }

    if (event) {
      this.stateManager.putSelectedSubject(event);
      this.reIssueTitleUtilityService.resetNavigationTitle();
      this.reIssueTitleUtilityService.setDescriptions(this.stateManager.getCurrentState().productName,
        this.stateManager.getCurrentState().node.description,
        this.stateManager.getCurrentState().contractorSubject,
        this.activeRoute.id);
      this.reIssueTitleUtilityService.updateNavigationTitle();
    } else {

      this.updateSubjectModified(true);
      this.reIssueTitleUtilityService.resetNavigationTitle();
      this.reIssueTitleUtilityService.setDescriptions(this.stateManager.getCurrentState().productName,
        this.stateManager.getCurrentState().nodeName,
        null,
        this.activeRoute.id);
      this.reIssueTitleUtilityService.updateNavigationTitle();
    }
  }

  onCoOwnerSelected(event: AnagIssueSubject) {
    if (event) {
      this.stateManager.putSelectedCoOwners(event);
      this.isSaveDisabled = false;
    }
  }

  onCoOwnerDeleted(subjectId: string) {
    const state = this.stateManager.getCurrentState();
    this.stateManager.deleteCoOwner(state.resourceId, subjectId).pipe(take(1)).subscribe(() => {
      this.stateManager.getCoOwners();
    });
  }

  checkQuestionnaires() {
    let check = false;
    const currentState = this.stateManager.getCurrentState();
    if (currentState.questionnaireList && currentState.questionnaireList.length) {
      if (currentState.questionnairesComplete && currentState.questionnairesAdequacy) {
        check = true;
      }
    } else {
      check = true;
    }
    return check;
  }

  onQuestionnaireValidChange(event: boolean) {
  }

  onQuestionnaireSubmit(event: QuestionnaireFlatI) {
    this.stateManager.updateState$(
      this.stateManager.putQuestionnaire(event).pipe(
        map(st => {
          // this.validateForms();
          this.validateQuestionnairesWarnings(st);
          this.questionnaireSubmitted = true;
          this.isSaveDisabled = false;
          return st;
        })
      )
    );
  }

  validateQuestionnairesWarnings(st: ReIssuePolicyDataState) {
    this.questionnairesMessages = [];
    if (st.questionnaireList && !st.questionnairesComplete) {
      this.questionnairesMessages.push(new Message(this.areaCode, this.questionnairesErrorMessage));
    }
    if (st.questionnairesWarnings) {
      st.questionnairesWarnings.forEach(war => {
        this.questionnairesMessages.push({ area: '', text: war.description });
      });
    }
  }

  saveProposal(authorizationRequest) {
    this.formSubmitted = true;
    this.submitted = true;

    this.validateQuestionnairesWarnings(this.stateManager.getCurrentState());
    this.validateForms();
    this.putIndexType();

    if (this.checkQuestionnaires() && this.formComplete && this.regulationPremiumValid) {
      this.stateManager.submitForm(this.errorSubjectMessage).subscribe(st => {
        const block: boolean = st.warnings && !!st.warnings.find(warning => warning.blockingMessage);
        if (st.warnings && st.warnings.length) {
          st.warnings.forEach(message => {
            this.notifyWarningMessage(message, block);
          });
        }
        if (!block) {
          this.stateManager.saveProposal().pipe(take(1)).subscribe(state => {
            this.isSaveDisabled = true;
            const msgData = new ReIssueMsgData();
            const msg = of(['RE_ISSUE.PROPOSAL_N',
              'RE_ISSUE.SAVED_SUCCESSFULLY']);
            msg.pipe(mergeMap(r => {
              return this.translateService.translateAll(...r);
            })).subscribe(next => {
              this.msgData = next[0] +
                ' ' + state.proposal.contractNumber.proposal + ' ' + next[1];
            }).unsubscribe();
            msgData.msg = this.msgData;
            msgData.showButtonConfirm = false;
            this.customModalService.openComponent(ReIssueMsgModalComponent, msgData);
          });
        }
      });
    }
  }


  goToNextPage(st: ReIssuePolicyDataState) {
    this.stateManager.goToNextPage(st);
  }


  updateClause(val: any) {

    this.validClauseForm = val.formValid;

    if (val.clause) {
      const state = this.stateManager.getCurrentState();
      state.clauses = state.clauses.map(cl => cl.code === val.clause.code ? val.clause : cl);
      this.clausesService.putClauses(state.resourceId, state.clauses).subscribe(
        data => {
          state.clauses = data;
          this.stateManager.updateState$(of(state));
          this.validateForms();
          this.isSaveDisabled = false;
        }
      );
    }
  }

  updateLpsDataOutput(val: LpsData) {
    this.stateManager.putLpsData(val);
    this.validateForms();
  }

  putCoInsurance(val: any) {
    this.validateCoInsurance(val);
    if (this.coInsuranceValid) {
      this.stateManager.putCoInsurance().subscribe(st => {
        this.stateManager.updateState$(of(st));
        this.isSaveDisabled = false;
      });
    }
    this.validateForms();
  }

  validateCoInsurance(val: any) {
    this.coInsuranceFormValid = val.formValid;
    this.coInsurancePercValid = val.percentageValid;
    this.coInsuranceQuotasValid = val.quotasValid;
    this.coInsuranceValid = this.coInsuranceFormValid && this.coInsurancePercValid && this.coInsuranceQuotasValid;
  }

  commonRiskCheckboxChange(val: boolean) {
    if (!val && this.getAdditionalTechnicalData()?.commonRiskPolicies?.length > 0) {
      this.deleteAllCommonRiskPolicies();
    }
    this.getAdditionalTechnicalData().commonRiskPoliciesChecked = val;
    this.validateCommonRiskPolicies();
  }

  validateCommonRiskPolicies() {
    this.commonRiskPoliciesValid = this.getAdditionalTechnicalData()?.commonRiskPoliciesChecked ? this.getAdditionalTechnicalData()?.commonRiskPolicies?.length > 0 : true;
    this.validateForms();
  }

  searchCommonRiskPolicy(policyDto: PolicyGenericDto) {
    this.stateManager.searchCommonRiskPolicy(policyDto).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
      this.commonRiskPoliciesComponent.checkForErrorMessage();
      this.validateCommonRiskPolicies();
    });
  }
  deleteCommonRiskPolicy(policyNumber: string) {
    this.stateManager.deleteCommonRiskPolicy(policyNumber).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
      this.validateCommonRiskPolicies();
    });
  }
  deleteAllCommonRiskPolicies() {
    this.stateManager.deleteAllCommonRiskPolicies().subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
      this.validateCommonRiskPolicies();
    });
  }

  linkedPoliciesCheckboxChange(val: boolean) {
    if (!val && this.getAdditionalTechnicalData()?.linkedPolicies?.length > 0) {
      this.deleteAllLinkedPolicies();
    }
    this.getAdditionalTechnicalData().linkedPoliciesChecked = val;
    this.validateLinkedPolicies();
  }
  validateLinkedPolicies() {
    this.linkedPoliciesValid = this.getAdditionalTechnicalData()?.linkedPoliciesChecked ? this.getAdditionalTechnicalData()?.linkedPolicies?.length > 0 : true;
    this.validateForms();
  }
  searchLinkedPolicy(policyDto: PolicyGenericDto) {
    this.stateManager.searchLinkedPolicy(policyDto).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
      this.linkedPoliciesComponent.checkForErrorMessage();
      this.validateLinkedPolicies();
    });
  }
  deleteLinkedPolicy(policyNumber: string) {
    this.stateManager.deleteLinkedPolicy(policyNumber).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
      this.validateLinkedPolicies();
    });
  }

  deleteAllLinkedPolicies() {
    this.stateManager.deleteAllLinkedPolicies().subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
      this.validateLinkedPolicies();
    });
  }

  putIndirectCoInsurance(val: PolicyGenericDto) {
    this.stateManager.putIndirectCoInsurance(val).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
    });
  }

  deleteIndirectCoInsurance(objectId: number) {
    const state = this.stateManager.getCurrentState();
    this.stateManager.deleteIndirectCoInsurance(state.resourceId, objectId).pipe(take(1)).subscribe(() => {
    });
  }

  deleteAllIndirectCoInsurancePolicies() {
    this.stateManager.deleteAllIndirectCoInsurancePolicies().pipe(take(1)).subscribe(() => {
    });
  }

  updatePropertiesIndirectCoInsuranceForm(data: any) {
    if(!!this.getAdditionalTechnicalData()) {
      this.getAdditionalTechnicalData().indirectCoinsuranceChecked = data.isChecked;
      this.stateManager.updateState$(of(this.stateManager.getCurrentState()));
      if (data.isFormValid !== null) {
        this.indirectCoInsuranceValid = data.isFormValid;
      }
      this.validateForms();
    }
  }

  putSecondRiskPolicy(val: PolicyGenericDto) {
    this.stateManager.putSecondRiskPolicy(val).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
    });
  }

  deleteSecondRiskPolicy(objectId: number) {
    const state = this.stateManager.getCurrentState();
    this.stateManager.deleteSecondRiskPolicy(state.resourceId, objectId).pipe(take(1)).subscribe(() => {
    });
  }

  deleteAllSecondRiskPolicies() {
    this.stateManager.deleteAllSecondRiskPolicies().pipe(take(1)).subscribe(() => {
    });
  }

  updatePropertiesSecondRiskForm(data: any) {
    if(!!this.getAdditionalTechnicalData()) {
      this.getAdditionalTechnicalData().secondRiskPoliciesChecked = data.isChecked;
      this.stateManager.updateState$(of(this.stateManager.getCurrentState()));
      if (data.isFormValid !== null) {
        this.secondRiskPoliciesValid = data.isFormValid;
      }
      this.validateForms();
    }
}

  complementaryPoliciesCheckboxChange(val: boolean) {
    if (!val && this.getAdditionalTechnicalData()?.complementaryPolicies?.length > 0) {
      this.deleteAllComplementaryPolicies();
    }
    this.getAdditionalTechnicalData().complementaryPoliciesChecked = val;
    this.validateComplementaryPolicies();
  }

  searchComplementaryPolicy(policyDto: PolicyGenericDto) {
    this.stateManager.searchComplementaryPolicy(policyDto).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
      this.complementaryPoliciesComponent.checkForErrorMessage();
      this.validateComplementaryPolicies();
    });
  }

  validateComplementaryPolicies() {
    this.complementaryPoliciesValid = this.getAdditionalTechnicalData()?.complementaryPoliciesChecked ? this.getAdditionalTechnicalData()?.complementaryPolicies?.length > 0 : true;
    this.validateForms();
  }

  deleteComplementaryPolicy(policyNumber: string) {
    this.stateManager.deleteComplementaryPolicy(policyNumber).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.isSaveDisabled = false;
    });
  }

  deleteAllComplementaryPolicies() {
    this.stateManager.deleteComplementaryPolicies().pipe(take(1)).subscribe(() => { });
  }

  proposalRegulationCheckboxChange(val: boolean) {
    this.stateManager.getCurrentState().isProposalRegulationChecked = val;
  }
}

import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import { LPC_IT } from './it';
import LPC_EN from './en';
import LPC_FR from './fr';
import LPC_DE from './de';
import { LPC_LIFE_PPEVO_EN } from './ppevo-questionnaire/en';
import { LPC_LIFE_PPEVO_ES } from './ppevo-questionnaire/es';
import { LPC_LIFE_PPEVO_DE } from './ppevo-questionnaire/de';
import { LPC_LIFE_PPEVO_IT } from './ppevo-questionnaire/it';
import { LPC_LIFE_PPEVO_FR } from './ppevo-questionnaire/fr';
import { LPC_GL_ES } from './es/gl-ES';
import LPC_CA_ES from './es/ca-ES';
import LPC_EU_ES from './es/eu-ES';
import LPC_ES_ES from './es/es-ES';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_EN);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_FR);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_ES_ES);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_DE);
  });
}

export function loadEU_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_EU_ES);
  });
}

export function loadGL_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_GL_ES);
  });
}

export function loadCA_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_CA_ES);
  });
}


export const LPC_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadFR,
    locale: 'fr'
  },
  {
    load: loadDE,
    locale: 'de'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadEU_ES,
    locale: 'eu-ES'
  },
  {
    load: loadGL_ES,
    locale: 'gl-ES'
  },
  {
    load: loadCA_ES,
    locale: 'ca-ES'
  }
];

/* ###################################################################### */

export function loadENPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_LIFE_PPEVO_EN)
  })
}

export function loadESPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_LIFE_PPEVO_ES)
  })
}

export function loadDEPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_LIFE_PPEVO_DE)
  })
}

export function loadITPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_LIFE_PPEVO_IT)
  })
}

export function loadFRPPEVOLIFE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LPC_LIFE_PPEVO_FR)
  })
}

export const LPC_TRANSLATIONS_PPEVO_QUEST_LIFE: RgiRxTranslations = [
  {
    load: loadENPPEVOLIFE,
    locale: 'en'
  },
  {
    load: loadESPPEVOLIFE,
    locale: 'es'
  },
  {
    load: loadDEPPEVOLIFE,
    locale: 'de'
  },
  {
    load: loadITPPEVOLIFE,
    locale: 'it'
  },
  {
    load: loadFRPPEVOLIFE,
    locale: 'fr'
  }
];


import { MasterPolicy } from './../models/masterpolicy.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnviromentsService } from './enviroments.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MasterPolicyService {
    private baseApiUrl: string;
    private masterPolicy: MasterPolicy = null;

    constructor(protected http: HttpClient,
                protected environment: EnviromentsService) {
      this.baseApiUrl = environment.baseApiURL;
    }

    getMasterPolicyById(id: string): Observable<any> {
      return this.http.get(this.baseApiUrl + '/v2/life/masterpolicies/' + id);
    }

    setMasterPolicy(pm: MasterPolicy) {
      this.masterPolicy = pm;
    }

    getMasterPolicy(): MasterPolicy {
      return this.masterPolicy;
    }

}

<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="onCancel()">
    {{ getTitle() | translate }}
  </rgi-rx-panel-header>

  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="onCancel()" data-qa="rgi-btn-cancel" translate>
      UNDO
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" data-qa="rgi-btn-confirm" (click)="onConfirm()" translate>
      CONFIRM
    </button>
  </rgi-rx-panel-footer>
  <div translate>
    CHECK_IBAN
  </div>
  <div *ngIf="polHolderAndNumbers.length === 1">{{polHolderAndNumbers[0].policyNumber}}, {{polHolderAndNumbers[0].holder}}</div>
  <div class="container-fluid quote-flex-table quote-proposal-flex padding-bottom-10" *ngIf="polHolderAndNumbers.length > 0">
    <div class="row bg-blue2">
      <div class="col-6  "><span translate>Policy Number</span></div>
      <div class="col-6  "><span translate>Policy Holder</span></div>
    </div>
    <ng-container *ngFor="let element of polHolderAndNumbers">
      <div class="row bg-grey">
        <div class="col-6  flexRowMinHeight">{{ element.policyNumber }}</div>
        <div class="col-6  flexRowMinHeight">{{ element.holder }}</div>
      </div>
    </ng-container>
  </div>
  <div translate>
    PROCEED
  </div>
</rgi-rx-panel>

import {APP_INITIALIZER, Inject, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnimatedLoadingDirective} from './animated-loading.directive';
import {RgiRxLoadingOverlayHostComponent} from './rgi-rx-loading-overlay-host/rgi-rx-loading-overlay-host.component';
import {RgiRxLoadingOverlayService} from './rgi-rx-loading-overlay.service';
import {
  RGI_RX_LOADING_OVERLAY_CONFIG,
  RGI_RX_LOADING_OVERLAY_DEFAULT_CONFIG,
  RgiRxLoadingOverlay, RgiRxLoadingOverlayConfig
} from './rgi-rx-loading.api';
import {RgiRxReactionModule} from '@rgi/rx';
import {OverlayModule} from '@angular/cdk/overlay';

export function initLoadingOverlayFactory(config: RgiRxLoadingOverlayConfig, loadingOverlay: RgiRxLoadingOverlayService) {
  return () => {
    return new Promise(resolve => {
      if (config.auto) {
        loadingOverlay.observeIndicator(config.debounceTimeMillis);
      }
      resolve(config.auto);
    });
  };
}

@NgModule({
  declarations: [AnimatedLoadingDirective, RgiRxLoadingOverlayHostComponent],
  imports: [
    CommonModule,
    RgiRxReactionModule,
    OverlayModule
  ],
  providers: [
    RgiRxLoadingOverlayService,
    {provide: RgiRxLoadingOverlay, useExisting: RgiRxLoadingOverlayService},
    {
      provide: RGI_RX_LOADING_OVERLAY_CONFIG,
      useValue: RGI_RX_LOADING_OVERLAY_DEFAULT_CONFIG
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLoadingOverlayFactory,
      multi: true,
      deps: [RGI_RX_LOADING_OVERLAY_CONFIG, RgiRxLoadingOverlayService]
    }
  ],
  exports: [AnimatedLoadingDirective]
})
export class RgiRxLoadingModule {
}

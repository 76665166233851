import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CdkStepHeader, StepState} from '@angular/cdk/stepper';
import {FocusMonitor, FocusOrigin} from '@angular/cdk/a11y';
import {RgiRxStepLabelDirective} from '../rgi-rx-step-label.directive';

export interface RgiRxStepHeader {
  state: StepState;
  label: string;
  index: number;
  optional: boolean;
  hasError: boolean;
  active: boolean;
  showIndex: boolean;
  errorMessage: string;
}

export interface RgiRxStepHeaderContext {
  $implicit: RgiRxStepHeader;
}


export interface RgiRxStepHeaderTouchEvent {
  index: number;
}

@Component({
  selector: 'rgi-rx-step-header',
  templateUrl: './rgi-rx-step-header.component.html',
  host: {
    class: 'rgi-ui-step',
    role: 'tab'
  },
  providers: [
    {
      provide: CdkStepHeader,
      useExisting: RgiRxStepHeaderComponent
    }
  ]
})
export class RgiRxStepHeaderComponent extends CdkStepHeader implements AfterViewInit, OnDestroy, RgiRxStepHeader {

  private _active: boolean;
  private _hasError: boolean;

  @Output() onStepHeaderTouch = new EventEmitter<RgiRxStepHeaderTouchEvent>();

  /** State of the given step. */
  @Input() state: StepState;

  /** Label of the given step. */
  @Input() label: string;


  @Input() stepLabel?: RgiRxStepLabelDirective;

  /** Error message to display when there's an error. */
  @Input() errorMessage: string;

  /** Index of the given step. */
  @Input() index: number;

  /** Show index on header */
  @Input() showIndex = true;

  /** Whether the given step label is active. */
  @Input() @HostBinding('class.rgi-ui-active') get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  /** Whether the given step is optional. */
  @Input() optional: boolean;


  @Input() get hasError(): boolean {
    return this._hasError;
  }

  set hasError(value: boolean) {
    this._hasError = value;
  }

  constructor(_elementRef: ElementRef, private _focusMonitor: FocusMonitor) {
    super(_elementRef);
  }

  ngAfterViewInit(): void {
    this._focusMonitor.monitor(this._elementRef, true);
  }

  ngOnDestroy(): void {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  override focus(origin?: FocusOrigin, options?: FocusOptions) {
    if (origin) {
      this._focusMonitor.focusVia(this._elementRef, origin, options);
    } else {
      this._elementRef.nativeElement.focus(options);
    }
  }


  _getCtx(): RgiRxStepHeaderContext {
    return {
      $implicit: {
        state: this.state,
        label: this.label,
        hasError: this.hasError,
        active: this.active,
        index: this.index,
        errorMessage: this.errorMessage,
        optional: this.optional,
        showIndex: this.showIndex
      }
    };
  }


}

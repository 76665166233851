<div class="col-md-12 life-detail-margin-box life-detail-box" id="pip-detail" data-qa="pip-mc-container">
    <div class="row life-detail-card-title-container">
        <div class="row life-detail-title">
            <div class="card-title">
                <span class="header-icon rgifont "></span>
                <span class="menu-title" translate>lpc_PIP_Data</span>
            </div>
        </div>
    </div>
    <div class="tlbd">
        <div *ngFor="let data of pipDataConfig" class="tlbd_row life-detail-subject-box">
            <div class="tbld_col tbld_col_title life-detail-table-width-5"><span translate>{{data.label}}</span>
                <button data-qa="open-pip-contrib-data-button" class="btn dt-btn pull-right"
                    (click)="emitPipEvent(data.event)">
                    <span class="rgifont rgi-chevron-right"></span>
                </button>
            </div>
        </div>
    </div>
</div>

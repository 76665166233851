<ng-container *ngIf="questionnaire">
  <ng-container *ngFor="let section of questionnaire.sections">
    <ppevo-questionnaire-section 
          (change)="onChange($event)" 
          *ngIf="section.visible"
          [inputSection]="section"
          (clickSectionInfoNotes)="onClickSectionInfoNotes($event)"
          (clickQuestionInfoNotes)="onClickQuestionInfoNotes($event)"
          >
      </ppevo-questionnaire-section>
  </ng-container>
</ng-container>
import {NgModule} from '@angular/core';
import {RgiCountryLayerCurrencyModule} from './currency/rgi-country-layer-currency.module';
import {RgiCountryLayerNumberFormatModule} from './number-format/rgi-country-layer-number-format.module';
import {RgiCountryLayerFormsModule} from './forms/rgi-country-layer-forms.module';
import {RgiCountryLayerIbanModule} from './iban/rgi-country-layer-iban.module';
import {RGI_COUNTRY_LAYER_CONFIG, RgiCountryLayerConfig} from './rgi-country-layer.api';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {IT} from './i18n/it';
import {EN} from './i18n/en';
import {DE} from './i18n/de';
import {ES} from './i18n/es';
import {FR} from './i18n/fr';

@NgModule({
  imports: [
    RgiCountryLayerCurrencyModule,
    RgiCountryLayerNumberFormatModule,
    RgiCountryLayerIbanModule,
    RgiCountryLayerFormsModule,
    RgiRxI18nModule.forRoot([
      {locale: 'it', load: () => new Promise(resolve => resolve(IT))},
      {locale: 'en', load: () => new Promise(resolve => resolve(EN))},
      {locale: 'de', load: () => new Promise(resolve => resolve(DE))},
      {locale: 'es', load: () => new Promise(resolve => resolve(ES))},
      {locale: 'fr', load: () => new Promise(resolve => resolve(FR))}
    ])
  ],
  exports: [
    RgiCountryLayerCurrencyModule,
    RgiCountryLayerNumberFormatModule,
    RgiCountryLayerFormsModule,
    RgiCountryLayerIbanModule,
  ],
  providers: [
    {
      provide: RGI_COUNTRY_LAYER_CONFIG,
      useValue: {
        syncWithI18n: true
      } as RgiCountryLayerConfig
    }
  ]
})
export class RgiCountryLayerModule {
}

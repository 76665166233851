import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {CommissionDetailModalData} from '../../../../resources/model/common/quotation/commission-detail-modal-data';

@Component({
  selector: 'rgi-pnc-commissions-modal',
  templateUrl: './pnc-commissions-modal.component.html'
})
export class PncCommissionsModalComponent implements OnInit,OnModalClose {
  modalClose = new EventEmitter<any>();
  displayedColumns: string[] = ['signedCommissions', 'netPurchaseCommissions', 'feePurchaseCommissions', 'netCollectionCommissions',
                                'feeCollectionCommissions', 'totCommissions'];
  constructor(
    @Optional() @Inject(DIALOG_DATA) public inputData: CommissionDetailModalData
  ) { }

  ngOnInit() {
  }

}

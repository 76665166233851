import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {Subscription} from 'rxjs';
import {
  RePostsalesStateManagerSubstitutionService
} from '../re-postsale-state-manager/re-postsales-state-manager-substitution.service';
import {RePostsalesSubstitutionState} from '../re-postsale-state-manager/re-postsales-substitution-state';
import {PolicySubstitutionRequest} from '../re-postsale-model/substitution';
import {take} from 'rxjs/operators';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';
import {ModalService} from "@rgi/rx/ui";
import {ReIssueTypeEnum} from "../../../enums/re-issue-type-enum";
import {ReAddContractModalComponent} from "./re-add-contract-modal/re-add-contract-modal.component";
import {SearchCardDto} from "../re-postsale-model/search-card-dto";
import {AddContractPolicyListComponent} from "./add-contract-policy-list/add-contract-policy-list.component";

@Component({
  selector: 're-substitution-start',
  templateUrl: './re-substitution-start.component.html'
})
export class ReSubstitutionStartComponent implements OnInit, OnDestroy {

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  substitutionForm: UntypedFormGroup;
  policyNumber: string;
  TAG_ERRORS_SUBREASON = 'subreason_errors';
  TAG_ERRORS_PRODUCT = 'product_errors';
  sub: Subscription = new Subscription();
  policies:any;
  isMultipleSubstitutionEnabled:boolean;
  substitutionMainPolicy:any;


  constructor(
    public activeRoute: ActiveRoute,
    protected formBuilder: UntypedFormBuilder,
    public stateManager: RePostsalesStateManagerSubstitutionService<RePostsalesSubstitutionState>,
    public routingService: RoutingService,
    protected errorService: PushMessageHandlerService,
    protected customModalService: ModalService) {
  }

  ngOnInit() {
    this.substitutionForm = this.formBuilder.group({
      substReason: [null, Validators.required],
      effect: [null, {validators: Validators.required, updateOn: 'blur'}],
      product: [null, Validators.required]
    });
    const st  = this.stateManager.getCurrentState();
    this.isMultipleSubstitutionEnabled = st.contract.multipleSubstitution;
    this.getPolicies();
    this.initControls();
    this.subscribeControls();
  }

  subscribeControls() {
    const substReasonSub = this.substitutionForm.controls.substReason.valueChanges.subscribe(value => {
      this.onSubstitutionReasonChange();
    });

    const productSub = this.substitutionForm.controls.product.valueChanges
      .subscribe(
        value => {
          if (value) {
            this.onProductChange(value);
          }
        });

    const effect = this.substitutionForm.controls.effect;
    const effectDateSub = effect.valueChanges.subscribe(value => {
      if (value) {
        const date = new Date(this.substitutionForm.controls.effect.value).getTime();
        this.stateManager.getCompatibleProducts((date)).pipe(take(1)).subscribe(st => {
          // Update selectedProduct if only one
          this.substitutionForm.controls.product.enable();
          const products = st.productList;
          const one = products.length === 1;
          if (one) {
            this.substitutionForm.controls.product.setValue(st.productList[0].id);
          }
          // Notify error msg
          this.notifyErrors();
        });
      }
    });
    this.sub.add(productSub);
    this.sub.add(substReasonSub);
    this.sub.add(effectDateSub);
  }

  private initControls() {
    this.substitutionForm.controls.effect.disable();
    this.substitutionForm.controls.product.disable();
  }


  onSubmit() {

    const state = this.stateManager.getCurrentState();
    const validationMessages = [...state.validationMsgProductList, ...state.validationMsgReasonList];
    if (validationMessages.length === 0) {
      const st = this.stateManager.getCurrentState();
      const effectTimestamp = new Date(this.substitutionForm.controls.effect.value);

      const substReason = this.substitutionForm.controls.substReason.value;
      const productId = this.substitutionForm.controls.product.value;
      const mainPolicyNumber = this.substitutionMainPolicy.policyNumber;
      const policiesList : string[] = [];
      this.policies.filter(policy => policy.policyNumber != mainPolicyNumber).forEach(otherPolicy=>policiesList.push(otherPolicy.policyNumber));

      const policySubstitutionRequest = new PolicySubstitutionRequest(
        effectTimestamp.getTime(),
        substReason,
        productId,
        policiesList
      );

      this.stateManager.createSubstituentContract(policySubstitutionRequest, mainPolicyNumber).pipe(take(1)).subscribe(data => {

        if (data && data.messages && data.messages.length > 0) {
          return;
        } else if (data && data.isSubstituted) {
          const policyNavigationData = {
            resourceId: data.resourceId,
            productName: st.contract.product.description,
            node: st.node,
            productCode:st.contract.product.code,
            productId:st.contract.product.identification,
            policyTechnicalData: null,
            companyId: st.contract.company.code,
            editMode: true,
            editFunctionality: false,
            isSubstitution: true,
            policyDataState: null,
            isToDisableFraz: null,
            previousContractor: st.contract.contractor.subject
          };
          this.routingService.navigate('re-issue-policy-data', policyNavigationData, this.activeRoute.id);
        }
      });
    }
  }

  openAddContractModal() {
    const {modal:addContractModal, component:reAddContractModalComponent} = this.customModalService.createModalComponent(ReAddContractModalComponent)
    const state = this.stateManager.getCurrentState();
    this.stateManager.getNodes().pipe(take(1)).subscribe(() => {
      reAddContractModalComponent.nodeList = state.nodeList;
    });
    this.stateManager.getProducts(ReIssueTypeEnum.INDIVIDUAL).pipe(take(1)).subscribe(() => {
      reAddContractModalComponent.productTypeList = state.productTypes;
      reAddContractModalComponent.productList = state.productList;
    });
    this.stateManager.getCompanies().pipe(take(1)).subscribe(companies => {
      reAddContractModalComponent.companyList = companies;
    });

    reAddContractModalComponent.updateProducts.subscribe(category => {
      this.stateManager.getProducts(category).pipe(take(1)).subscribe(() => {
        reAddContractModalComponent.productTypeList = state.productTypes;
        reAddContractModalComponent.productList = state.productList;
      });
    });

    addContractModal.open();

    reAddContractModalComponent.openPoliciesList.subscribe(body => {
      if(body)
        this.stateManager.findPolicies(body).subscribe(res=>{
          const {modal:policyList, component} = this.customModalService.openComponent(AddContractPolicyListComponent);
          component.policies = res.policyRows;

          policyList.onClose.subscribe(policies=>{
            if(policies){
              addContractModal.close();
              this.policies = [...this.policies,...policies];
            }
          })
        });
    });
  }

  onSubstitutionReasonChange() {
    const state = this.stateManager.getCurrentState();
    const subReasonValue = this.substitutionForm.controls.substReason.value;
    const subReasonSelected = state.reasonList.find(reason => reason.id === subReasonValue);
    if (subReasonSelected) {
      this.substitutionForm.patchValue({
        product: null,
        effect: null
      });

      const effect = this.substitutionForm.controls.effect;
      effect.enable();

      if (subReasonSelected.effectiveDate) {
        effect.setValue(new Date(subReasonSelected.effectiveDate));

        if (!subReasonSelected.effectiveDateEnabled) {
          effect.disable();
        }
      }

    } else {
      this.substitutionForm.patchValue({
        effectDate: null
      });
    }
  }

  onProductChange(value: string) {
    const state = this.stateManager.getCurrentState();
    const productSelected = state.productList.find(product => product.id === value);
  }

  private notifyErrors() {
    const state = this.stateManager.getCurrentState();
    this.errorService.clearTag(this.TAG_ERRORS_SUBREASON);
    state.validationMsgReasonList.forEach(msg => {
      const errorMsg = new PushMessage();
      errorMsg.tag = this.TAG_ERRORS_SUBREASON;
      errorMsg.status = 'danger';
      errorMsg.dismissible = false;
      errorMsg.options = {icon: 'rgi-ui-icon-alert'};
      errorMsg.content = msg.text;
      this.errorService.notify(errorMsg);
    });

    this.errorService.clearTag(this.TAG_ERRORS_PRODUCT);
    state.validationMsgProductList.forEach(msg => {
      const errorMsg = new PushMessage();
      errorMsg.tag = this.TAG_ERRORS_PRODUCT;
      errorMsg.status = 'danger';
      errorMsg.dismissible = false;
      errorMsg.options = {icon: 'rgi-ui-icon-alert'};
      errorMsg.content = msg.text;
      this.errorService.notify(errorMsg);
    });

  }

  getPolicies(){
    const st = this.stateManager.getCurrentState();
    const body = new SearchCardDto();
    body.policyNumber = st.contract.policyNumber;
    body.salePointId = st.contract.managementNode.identification;
    body.salePointCode = st.contract.managementNode.code;

    this.stateManager.findPolicies(body).subscribe(res=>{
      this.policies =res.policyRows;
    });

  }
  onMainPolicyChange(event:any){
    this.substitutionMainPolicy = event;
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }


}

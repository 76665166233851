import {Injectable} from '@angular/core';
import {PaymentFrequency} from '../../../models/api-models/api-payment-frequency';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Parameter} from '../../../models/domain-models/parameter';
import {DatePipe} from '@angular/common';
import {RgiRxLogger} from '@rgi/rx';
import {InstalmentDate} from '../../../models/api-models/instalment-date';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  private effectiveDate: Date;
  private paymentFrequencyValues = new Array<PaymentFrequency>();
  private instalmentDateValues = new Array<InstalmentDate>();

  public static ID_PAYMENT_FREQUECY = '1';
  public static CODE_PAYMENT_FREQUECY = 'paymentFrequency';
  public static ID_INSTALMENT_DATES = '2';
  public static CODE_INSTALMENT_DATES = 'instalmentDate';
  public static ID_SCONTO_PLURI = '4';
  public static ID_SCONTO_MULTI = '5';

  constructor(
    private datePipe: DatePipe,
    protected logger: RgiRxLogger,
  ) {
  }

  getEffectiveDate() {
    return this.effectiveDate;
  }

  setEffectiveDateNum(date: number) {
    this.effectiveDate = new Date(date);
    this.logger.debug('ParametersService::setEffectiveDateNum() - effectiveDate', this.effectiveDate);
    this.logger.debug('ParametersService::setEffectiveDateNum() - date', date);
  }

  public setPaymentFrequencyValues(value: Array<PaymentFrequency>) {
    this.paymentFrequencyValues = value;
  }

  public getPaymentFrequencyValues() {
    return this.paymentFrequencyValues;
  }

  public setInstalmentDateValues(value: Array<InstalmentDate>) {
    this.instalmentDateValues = value;
  }

  public getInstalmentDateValues() {
    return this.instalmentDateValues;
  }

  toFormGroup(parameters: Parameter[]): UntypedFormGroup {
    const group: any = {};
    parameters.forEach(parameter => {
      let value;
      if (parameter.type === 0) {
        if (parameter.id === '8') {
          if (parameter.value) {
            value = this.datePipe.transform(new Date(Number(parameter.value)), 'dd/MM/yyyy');
          } else {
            value = null;
          }
        } else {
          if (parameter.value) {
            value = parameter.value;
          } else {
            value = null;
          }
        }
      } else if (parameter.type === 4) {
        if (parameter.value) {
          value = new Date(Number(parameter.value));
        } else {
          value = null;
        }
      } else {
        value = parameter.value ? parameter.value.replace(',', '.') : null;
      }
      group[parameter.id] = new UntypedFormControl(value, parameter.compulsory ? Validators.required : null);
    });
    return new UntypedFormGroup(group);
  }
}

import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { PaymentFieldDefinition } from '../../../models/postsales-operations-response.model';



@Component({
  selector: 'lpc-payment-datepicker',
  templateUrl: './lpc-payment-datepicker.component.html',
  styleUrls: ['./lpc-payment-datepicker.component.css']
})
export class LpcPaymentDatepickerComponent implements OnInit {
  value: string;
  label: string;
  config: PaymentFieldDefinition;
  editable: boolean;
  group: UntypedFormGroup;
  prevVal: string;
  regex = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}$');

  constructor(protected el: ElementRef) {}

  ngOnInit() {
    this.initDate();
    this.regex.test('12/05/2019');
    if (!this.editable) {
      this.group.get(this.config.code).disable();
    }
  }

  parseYMD(dataYMD): Date {
    return new Date(dataYMD);
  }

  initDate() {
    const validatori: ValidatorFn[] = [this.validatoreData];

    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.values !== null && this.config.values !== EMPTY_STR && this.config.values !== '-1') {
      this.group.controls[this.config.code].setValue(this.parseYMD(this.config.values));
    } else {
      this.group.controls[this.config.code].setValue(null);
    }
    if (this.config.editable) {
      this.group.controls[this.config.code].enable();
    } else {
      this.group.controls[this.config.code].disable();
    }
    if (this.config.required) {
      validatori.push(Validators.required);
    }
    this.group.controls[this.config.code].setValidators(validatori);
  }

  setPayment(payment: PaymentFieldDefinition, form: UntypedFormGroup, editable: boolean) {
    this.config = payment;
    this.label = this.config.label;
    this.group = form;
    this.editable = editable;
  }

  onChange() {
    if (this.prevVal !== this.group.controls[this.config.code].value) {
      this.el.nativeElement.dispatchEvent(new Event('changeVal', {bubbles: true}));
      this.prevVal = this.group.controls[this.config.code].value;
    }
  }

  validatoreData: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value instanceof Date && control.value.getFullYear() > 1900) {
      return null;
    }
    return {'Data non valida': true};
  }
}

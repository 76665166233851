import {ComponentFactoryResolver, ComponentRef, EventEmitter, Injectable, Type, ViewContainerRef} from '@angular/core';
import {Modal} from './modal';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomModalService {

  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {
  }

  openModal(
    viewContainerRef: ViewContainerRef,
    modalComponent: Type<Modal>,
    eventPropagation?: EventEmitter<any>,
    onDestroyFunction?: () => void) {

    let componentRef: ComponentRef<any>;
    viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalComponent);
    componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.componentRef = componentRef;
    const eventPropagationSuscription = new Subscription();

    if (eventPropagation) {
      eventPropagationSuscription.add(componentRef.instance.eventPropagation.subscribe((event) => {
        eventPropagation.emit(event);
      }));
    }

    /*     if (onDestroyFunction) {
          componentRef.onDestroy(
            onDestroyFunction
          );
        }
     */

    function onDestroyComponentRef() {
      eventPropagationSuscription.unsubscribe();
      if (onDestroyFunction) {
        onDestroyFunction();
      }
    }

    componentRef.onDestroy(onDestroyComponentRef);

    return componentRef;
  }

}

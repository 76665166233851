export class Bodywork {
    constructor(
        public code: string,
        public denomination?: string,
        public vat?: string,
        public address?: string,
        public telephone?: string,
        public email?: string,
        public iban?: string
    ) { }
}

<div class="box-detail" *ngIf="!!contractDetails.coinsurance && !!contractDetails.coinsurance?.coinsuranceShares?.length">
    <header class="box-detail-header" translate>_GP_._TITLE_._COINSURANCES_</header>
    <div class="box-detail-body">
        <table rgi-rx-table [dataSource]="contractDetails.coinsurance?.coinsuranceShares">
            <ng-container rgiRxColumnDef="type">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._TYPE_</td>
                <td rgi-rx-cell *rgiRxCellDef="let row"> {{contractDetails.coinsurance?.coinsuranceType.description}} </td>
            </ng-container>

            <ng-container rgiRxColumnDef="company">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._COMPANY_</td>
                <td rgi-rx-cell *rgiRxCellDef="let row">{{row?.company?.description}}</td>
            </ng-container>

            <ng-container rgiRxColumnDef="percentage">
                <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._SUMMARY_._PERCENTAGE_</td>
                <td rgi-rx-cell *rgiRxCellDef="let row">{{row?.percentageShare + ' %'}}</td>
            </ng-container>

            <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedCoinsuranceColumns"></tr>
            <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedCoinsuranceColumns;"></tr>
        </table>
    </div>
</div>

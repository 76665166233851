import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {RgiCountryLayerValidators} from "../utils/rgi-country-layer-validator.service";

@Directive({
  selector: '[rgiCountryLayerIbanValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: RgiCountryLayerIbanValidatorDirective, multi: true}],
})
export class RgiCountryLayerIbanValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    return RgiCountryLayerValidators.iban(c);
  }
}

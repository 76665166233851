
<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="actionClose()">
    <span class="anag-icon-m rgi-ui-icon-stars-rating"></span>
    {{'_ANAG_._LABEL_._RATING_' | translate}}
  </rgi-rx-panel-header>

    <form [formGroup]="ratingForm" (submit)="updateRating()" *ngIf="editorMode === ANAG_DOCUMENT_OBJ_MODE.EDIT">
      <div *ngIf="stateMgr.getState$() | async as state">
        <div class="rgi-ui-grid-2">
          <div class="rgi-ui-col" *ngIf="isFieldVisible('rating')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>_ANAG_._LABEL_._RATING_</label>
                <select rgiRxNativeSelect [formControlName]="'rating'" data-qa="rating">
                  <option selected></option>
                  <option *ngFor="let rating of stateMgr.ratingList | async; index as i; trackBy: genericEntitiesTrackByFn" [ngValue]="rating">{{rating.descrizione}}</option>
                </select>
              </rgi-rx-form-field>
          </div>

          <div class="rgi-ui-col" *ngIf="isFieldVisible('ratingPeriod')">
            <rgi-rx-form-field style="display: block">
              <label rgiRxLabel><span>{{ '_ANAG_._LABEL_._RATING_PERIOD_' | translate }}</span></label>
              <div style="display:flex;">
                <input [rgiRxDateTimeInput]="ratingPeriodPicker"
                       style="flex: 1"
                       data-qa="rgi-anag-rating-date"
                       selectMode="single"
                       rangeSeparator="-"
                       formControlName="ratingPeriod"
                       [placeholder]="datepickerPlaceholder" #inputToTriggerratingPeriodDate="rgiRxDateTimeInput">
                <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                        [rgiRxDateTimeTrigger]="inputToTriggerratingPeriodDate"></button>
              </div>
              <rgi-rx-date-time #ratingPeriodPicker
                                pickerType="calendar"
                                [hour12Timer]="true"
              ></rgi-rx-date-time>
            </rgi-rx-form-field>
          </div>
        </div>
      </div>
      <rgi-rx-snackbar tag="error-tag"></rgi-rx-snackbar>
    </form>

  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionClose()"
                data-qa="anag-btn-cancel" translate>_ANAG_._BTN_._CANCEL_
        </button>
        <button class="rgi-ui-btn rgi-ui-btn-primary" type="submit" (click)="updateRating()"
                data-qa="anag-btn-confirm" translate>_ANAG_._BTN_._CONFIRM_
        </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

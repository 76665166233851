import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PolicyPremium, RiskPremium } from '../../models/policy.model';
import { DamagesInstalments, DamageRisk } from '../../models/damage.model';
import { PolicyService } from '../../services/policy-service';

@Component({
  selector: 'lic-warranties-table',
  templateUrl: './warranties-table.component.html',
  styleUrls: ['./warranties-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WarrantiesTableComponent implements OnInit {
  public locale = 'it-IT';
  public optionCurrency: Intl.NumberFormatOptions = { style: 'currency' };

  @Input() riskPremium: RiskPremium[];
  @Input() policyPremium: PolicyPremium;
  @Input() ifNotSingleFrequency: boolean;
  @Input() warrantiesDescr: Map<string, string>;

  @Input() damagesInstalment: DamagesInstalments[];
  @Input() damageRisk: DamageRisk[];
  danni: boolean;

    /* DA ELIIMINARE!!!!!!! */
    grossDR = 0;
    taxesDR = 0;
    fractioningInterestsAccessoriesDR = 0;
    fractioningInterestsNetDR = 0;
    accessoriesDR = 0;
    netDR = 0;
    imponibileDR = 0;
    /* DA ELIIMINARE!!!!!!! */

  constructor(protected policyService: PolicyService) {
    this.locale = policyService.currentLocale;
    this.optionCurrency.currency = policyService.currencyCode;
  }

  ngOnInit() {
    this.danni = this.damagesInstalment != null && this.damagesInstalment.length !== 0;

    /* DA ELIIMINARE!!!!!!! */
    if (this.danni) {
      this.calculateAnnualPremiumDR();
    }
    /* DA ELIIMINARE!!!!!!! */
  }

  sumVariables(ex1, ex2, ex3, ex4): string {
    return ((+ex1) + (+ex2) + (+ex3) + (+ex4)).toString();
  }

  /* DA ELIIMINARE!!!!!!! */
  calculateAnnualPremiumDR() {
    this.damageRisk.forEach((dr) => {
      this.grossDR = this.grossDR + (+dr.annualPremium.gross);
      this.taxesDR = this.taxesDR + (+dr.annualPremium.taxes);
      this.fractioningInterestsAccessoriesDR = this.fractioningInterestsAccessoriesDR + (+dr.annualPremium.fractioningInterestsAccessories);
      this.fractioningInterestsNetDR = this.fractioningInterestsNetDR + (+dr.annualPremium.fractioningInterestsNet);
      this.accessoriesDR = this.accessoriesDR + (+dr.annualPremium.accessories);
      this.netDR = this.netDR + (+dr.annualPremium.net);
      this.imponibileDR = this.imponibileDR +
                          (+dr.annualPremium.net) +
                          (+dr.annualPremium.accessories) +
                          (+dr.annualPremium.fractioningInterestsAccessories) +
                          (+dr.annualPremium.fractioningInterestsNet);
    });
  }
  /* DA ELIIMINARE!!!!!!! */
}

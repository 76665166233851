<br>
<form [formGroup]="addAssetForm">
<div class="container-fluid">
    <div class="form-group row" *ngIf="visibleNominative">
        <div class="col-sm-6 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._NOMINATIVE' | translate }}</label>
        </div>
        <div class="col-sm-6 col-xs-12" style="text-decoration: underline;">
           {{party.description}}
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-12 col-xs-12">
            <h3 class="title-header">{{ '_CLAIMS_._DAMAGED_PARTS' | translate }}</h3>
        </div>
    </div>
    
    <div class="form-group row">
        <div [ngClass]="classCaus" >
            <label ng-bind="label">{{ '_CLAIMS_._CAUSE_OF_INJURY' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryCause" id="injuryCause" name="injuryCause"
            attr.data-qa="injury-asset-detail-injuryCause-input" (change)="chargeNature()">
            <option></option>
            <option *ngFor="let el of injuryCauseList; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div [ngClass]="classNat">
            <label ng-bind="label">{{ '_CLAIMS_._NATURE_OF_INJURY' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryNature" id="injuryNature" name="injuryNature"
            attr.data-qa="injury-asset-detail-injuryNature-input" (change)="chargeLocations()">
            <option></option>
            <option *ngFor="let el of injuryNatureCombo; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12" *ngIf="!visGraphicBody">
            <label ng-bind="label">{{ '_CLAIMS_._INJURY_LOCATION' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryPlace" id="injuryPlace" name="injuryPlace"
            attr.data-qa="injury-asset-detail-injuryPlace-input" >
            <option></option>
            <option *ngFor="let el of injuryLocationCombo; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-1 col-xs-12" *ngIf="!visGraphicBody">
            <br>
            <button class="btn btn-warning pull-right" (click)="addDamage($event)"
                        attr.data-qa="injury-asset-detail-doc-btn">{{ '_CLAIMS_._BUTTONS_._ADD' | translate }}</button>
        </div>

    </div>
   
    <div class="form-group row" *ngIf="!visGraphicBody">
        <div class="col-sm-12 col-xs-12">
            <table style="width: 100%;">
                <tr class="head-result thDataTable">
                    <td>{{ '_CLAIMS_._NATURE_OF_INJURY' | translate }}</td><td>{{ '_CLAIMS_._INJURY_LOCATION' | translate }}</td><td></td>
                </tr>
                <tr *ngIf="!this.addAssetForm.controls.damageList.value || this.addAssetForm.controls.damageList.value.length === 0">
                    <td colspan="3" style="text-align: center;">{{ '_CLAIMS_._NO_RESULT' | translate }}</td>
                </tr>
                <tr *ngFor="let aDam of this.addAssetForm.controls.damageList.value; 
                    let even = even; 
                    let odd = odd; 
                    let damIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }">
                    <td>{{ aDam.nature }}</td><td>{{ aDam.location }}</td>
                    <td style="text-align: right;">
                     <button type="button" class="btn btn-flat btn-sm" (click)="removeDamage(damIndex)" style="border-color: white;" >
                        <span class="glyphicon glyphicon-trash" style="width:10px; height:10px;"></span>
                     </button> 
                    </td>
                </tr>
            </table>
            
        </div>
       

    </div>

    <div class="form-group row" *ngIf="visGraphicBody">
        <div class="col-sm-4 col-xs-12">
        <div class="form-group row" >
            <div class="col-sm-6 col-xs-12">
                <label ng-bind="label">{{ '_CLAIMS_._UNCODABLE' | translate }}</label> <br>
                <input class="col-sm-3 form-control" formControlName="notCodeable" id="notCodeable" type="checkbox" *ngIf="!disableNotCodeable" 
                    name="notCodeable" class="form-control btn-checkbox checkbox-unit" attr.data-qa="injury-asset-detail-notCodeable-input"  (click)="addVisualDamage('99')">
                    <input class="col-sm-3 form-control" formControlName="notCodeable" id="notCodeable" type="checkbox" *ngIf="disableNotCodeable" disabled
                    name="notCodeable" class="form-control btn-checkbox checkbox-unit" attr.data-qa="injury-asset-detail-notCodeable-input"  >
            </div>
            <div class="col-sm-6 col-xs-12">
                <label ng-bind="label">{{ '_CLAIMS_._MULTIPLE' | translate }}</label> <br>
                <input class="col-sm-3 form-control" formControlName="multiple" id="multiple" type="checkbox" *ngIf="!disableMultiple"
                    name="multiple" class="form-control btn-checkbox checkbox-unit" attr.data-qa="injury-asset-detail-multiple-input"  (click)="addVisualDamage('77')">
                    <input class="col-sm-3 form-control" formControlName="multiple" id="multiple" type="checkbox" *ngIf="disableMultiple" disabled
                    name="multiple" class="form-control btn-checkbox checkbox-unit" attr.data-qa="injury-asset-detail-multiple-input"  >
            </div>
         </div>
         <div class="form-group row" >
            <table style="width: 100%; font-size: 12px;">
                <tr class="head-result thDataTable">
                    <td style="width: 100px;">{{ '_CLAIMS_._NATURE_OF_INJURY' | translate }}</td><td>{{ '_CLAIMS_._INJURY_LOCATION' | translate }}</td><td></td>
                </tr>
                <tr *ngIf="!this.addAssetForm.controls.damageList.value || this.addAssetForm.controls.damageList.value.length === 0">
                    <td colspan="3" style="text-align: center;">{{ '_CLAIMS_._NO_RESULT' | translate }}</td>
                </tr>
                <tr *ngFor="let aDam of this.addAssetForm.controls.damageList.value; 
                    let even = even; 
                    let odd = odd; 
                    let damIndex = index" [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }">
                    <td>{{ aDam.nature }}</td><td style="padding-left: 3px;">{{ aDam.location }}</td>
                    <td style="text-align: right;">
                     <button type="button" class="btn btn-flat btn-sm" (click)="removeDamage(damIndex)" style="border-color: white;" >
                        <span class="glyphicon glyphicon-trash" style="width:10px; height:10px;"></span>
                     </button> 
                    </td>
                </tr>
            </table>
         </div>
         
        </div>
        <!-- NUOVO BODY   -->

        <div class="col-sm-8 col-xs-12 ">
            <table  border="0" cellpadding="0" cellspacing="0" align="center" style="z-index: 1; position: relative;">
            <tr>
            <td> <!-- tabella corpo fronte-->
                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" >
                    <tr><td   align="center" colspan="3" ><img style="margin: 0px 0px -5px 0px;" src="{{rootImg}}cranio_front.png" ></td></tr>
                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr >
                                    <td align="right" class="tooltipT"><img  style="margin: -5px 10px 0px 122px; cursor: pointer;" src="{{mappaBody[34]}}" (click)="addVisualDamage('34')" /><span class="tooltiptextT" style="margin: 0px 0px 0px -60px;">{{mappaBodyDescr[34] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin: -5px 12px 8px -11px; cursor: pointer;"  src="{{mappaBody[31]}}" (click)="addVisualDamage('31')"/><span class="tooltiptextT" style="margin: 0px 0px 0px -140px;">{{mappaBodyDescr[31] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin: -5px 130px 8px -4px; cursor: pointer;"  src="{{mappaBody[32]}}" (click)="addVisualDamage('32')"/><span class="tooltiptextT" >{{mappaBodyDescr[32] | translate }}</span></td>
                                    <td align="left" class="tooltipT"><img style="margin:-5px 0px 0px -131px; cursor: pointer;"  src="{{mappaBody[33]}}" (click)="addVisualDamage('33')"/><span class="tooltiptextT" style="margin: 0px 0px 0px -130px;">{{mappaBodyDescr[33] | translate }}</span></td>
                                </tr>
                            </table>	
                        </td>
                    </tr>
                    <tr><td align="center" colspan="3" class="tooltipR"><img style="margin: -10px 0px 1px 0px; cursor: pointer;"  src="{{mappaBody[15]}}" (click)="addVisualDamage('15')"/><span class="tooltiptextR" style="margin: -10px 20px 0px 0px;">{{mappaBodyDescr[15] | translate }}</span></td></tr>
                    <tr><td align="center" colspan="3" class="tooltipR"><img  style="margin: -21px 3px -1px 0px; cursor: pointer;"  src="{{mappaBody[16]}}" (click)="addVisualDamage('16')"/><span class="tooltiptextR" style="margin: -15px 30px 0px 0px;">{{mappaBodyDescr[16] | translate }}</span></td></tr>
                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>
                                    <td align="right"  style="position: relative; z-index: 1;" class="tooltipT"><img style="margin: -7px -107px 0px 39px; cursor: pointer;"  src="{{mappaBody[42]}}" (click)="addVisualDamage('42')"/><span class="tooltiptextT" style="margin: 0px -65px 0px 0px;">{{mappaBodyDescr[42] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin: -7px -10px 1px 105px; cursor: pointer;"  src="{{mappaBody[40]}}" (click)="addVisualDamage('40')"/><span class="tooltiptextT" style="margin: 0px 0px 0px -120px;">{{mappaBodyDescr[40] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin:-6px 75px 0px -39px; cursor: pointer;"  src="{{mappaBody[39]}}" (click)="addVisualDamage('39')"/><span class="tooltiptextT" style="margin: 0px 0px 0px -40px;">{{mappaBodyDescr[39] | translate }}</span></td>
                                    <td align="left" class="tooltipT"><img style="margin:-7px 2px 0px -106px; cursor: pointer;"  src="{{mappaBody[41]}}" (click)="addVisualDamage('41')" /><span class="tooltiptextT" style="margin: 0px 0px 0px -100px;" >{{mappaBodyDescr[41] | translate }}</span></td>
                                </tr>
                            </table>	
                        </td>
                    </tr>

                    <tr >
                        <td align="right" class="tooltipT"><img style="margin: -8px 0px 45px 64px; cursor: pointer;"  src="{{mappaBody[44]}}" (click)="addVisualDamage('44')" /><span class="tooltiptextT" style="    margin: 0px 0px 0px -100px;">{{mappaBodyDescr[44] | translate }}</span></td>
                        <td align="center" class="tooltipR"><img style="margin: -5px 0px 0px 0px; cursor: pointer;"  src="{{mappaBody[22]}}" (click)="addVisualDamage('22')" /><span class="tooltiptextR" style="margin: 0px 0px 0px 30px;">{{mappaBodyDescr[22] | translate }}</span></td>
                        <td align="left" class="tooltipT"><img style="margin: -9px 63px 42px 0px; cursor: pointer;"  src="{{mappaBody[43]}}" (click)="addVisualDamage('43')" /><span class="tooltiptextT" style="margin: 0px 0px -10px -20px;">{{mappaBodyDescr[43] | translate }}</span></td>
                    </tr>

                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>
                                    <td align="right" class="tooltipT"><img style="margin: -55px -5px 24px 59px; cursor: pointer;"  src="{{mappaBody[45]}}" (click)="addVisualDamage('45')" /><span class="tooltiptextT" style="margin: -40px 0px 0px -130px;">{{mappaBodyDescr[45] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin: -30px -3px 14px 10px; cursor: pointer;"  src="{{mappaBody[62]}}" (click)="addVisualDamage('62')" /><span class="tooltiptextT" style="margin: -32px 0px 0px -129px;">{{mappaBodyDescr[62] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin:-31px -6px 15px 37px; cursor: pointer;"  src="{{mappaBody[61]}}" (click)="addVisualDamage('61')" /><span class="tooltiptextT" style="margin: -30px 0px 0px 40px;">{{mappaBodyDescr[61] | translate }}</span></td>
                                    <td align="left" class="tooltipT"><img style="margin:-56px 58px 23px 6px; cursor: pointer;"  src="{{mappaBody[46]}}" (click)="addVisualDamage('46')" /><span class="tooltiptextT" style="margin: -40px 0px 0px 0px;">{{mappaBodyDescr[46] | translate }}</span></td>
                                </tr>
                            </table>	
                        </td>
                    </tr>

                    <tr >
                        <td align="right" class="tooltipR"><img style="margin: -40px 20px 31px 0px; cursor: pointer;"  src="{{mappaBody[47]}}" (click)="addVisualDamage('47')"/><span class="tooltiptextR" style="margin: -50px 10px 0px 0px;">{{mappaBodyDescr[47] | translate }}</span></td>
                        <td align="center" class="tooltipR"><img style="margin: -40px 0px 7px 0px; cursor: pointer;"  src="{{mappaBody[21]}}" (click)="addVisualDamage('21')"/><span class="tooltiptextR" style="margin: -20px 0px 0px 20px;">{{mappaBodyDescr[21] | translate }}</span></td>
                        <td align="left" class="tooltipR"><img style="margin: -41px 0px 30px 18px; cursor: pointer;"  src="{{mappaBody[48]}}" (click)="addVisualDamage('48')"/><span class="tooltiptextR" style="margin: -30px 30px 0px 10px;">{{mappaBodyDescr[48] | translate }}</span></td>
                    </tr>

                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>
                                    <td align="right" class="tooltipT"><img style="margin: -58px 1px 36px 37px; cursor: pointer;"  src="{{mappaBody[50]}}" (click)="addVisualDamage('50')"/><span class="tooltiptextT" style="margin: -15px 5px 0px -110px;">{{mappaBodyDescr[50] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin: -50px -1px 0px 21px; cursor: pointer;"  src="{{mappaBody[64]}}" (click)="addVisualDamage('64')"/><span class="tooltiptextT" style="margin: 0px 18px -50px -135px;">{{mappaBodyDescr[64] | translate }}</span></td>
                                    <td class="tooltipT"><img style="margin:-49px 43px 0px -1px; cursor: pointer;"  src="{{mappaBody[63]}}" (click)="addVisualDamage('63')"/><span class="tooltiptextT" style="margin: 0px 0px -50px 0px;">{{mappaBodyDescr[63] | translate }}</span></td>
                                    <td align="left" class="tooltipT"><img style="margin:-49px 38px 44px -28px; cursor: pointer;"  src="{{mappaBody[49]}}" (click)="addVisualDamage('49')"/><span class="tooltiptextT" style="margin: -15px 5px 0px -30px;">{{mappaBodyDescr[49] | translate }}</span></td>
                                </tr>
                            </table>	
                        </td>
                    </tr>

                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>
                                    <td align="right" class="tooltipT"><img style="margin: -5px 0px 4px 15px; cursor: pointer;"  src="{{mappaBody[66]}}" (click)="addVisualDamage('66')"/><span class="tooltiptextT" style="margin: -5px -30px 0px -90px;">{{mappaBodyDescr[66] | translate }}</span></td>
                                    
                                    <td align="left" class="tooltipT"><img style="margin:-9px 5px 0px 13px; cursor: pointer;"  src="{{mappaBody[65]}}" (click)="addVisualDamage('65')"/><span class="tooltiptextT" style="margin: -5px 0px 0px -25px;">{{mappaBodyDescr[65] | translate }}</span></td>
                                </tr>
                            </table>	
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>
                                    <td align="right" class="tooltipT"><img style="margin: -5px 4px 0px -2px; cursor: pointer;"  src="{{mappaBody[68]}}" (click)="addVisualDamage('68')"/><span class="tooltiptextT" style="margin: 0px -30px -50px -90px;">{{mappaBodyDescr[68] | translate }}</span></td>
                                    
                                    <td align="left" class="tooltipT"><img style="margin: -5px 1px 0px -1px; cursor: pointer;"  src="{{mappaBody[67]}}" (click)="addVisualDamage('67')"/><span class="tooltiptextT" style="margin: 0px 0px -50px -30px;">{{mappaBodyDescr[67] | translate }}</span></td>
                                </tr>
                            </table>	
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" >
                                <tr>
                                    <td align="right" class="tooltipT"><img style="margin: -6px 0px 0px 6px; cursor: pointer;"  src="{{mappaBody[72]}}" (click)="addVisualDamage('72')"/><span class="tooltiptextT"  style="margin: -5px 0px -5px -80px;">{{mappaBodyDescr[72] | translate }}</span></td>
                                    
                                    <td align="left" class="tooltipT"><img style="margin: -6px 0px 0px 7px; cursor: pointer;"  src="{{mappaBody[71]}}" (click)="addVisualDamage('71')"/><span class="tooltiptextT" style="margin: -5px 0px -10px -30px;">{{mappaBodyDescr[71] | translate }}</span></td>
                                </tr>
                            </table>	
                        </td>
                    </tr>


                </table>
            </td>
            <td> <!-- tabella corpo dorso-->
                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" style="position: relative; z-index: 0; margin: 37px 0px 0px -30px;">
                    <tr><td align="center" colspan="3" class="tooltipR"><img style="margin: 0px 0px -2px -4px; cursor: pointer;" src="{{mappaBody[11]}}" (click)="addVisualDamage('11')"/><span class="tooltiptextR">{{mappaBodyDescr[11] | translate }}</span> </td></tr>
                    <tr><td align="center" colspan="3" class="tooltipR"><img style="margin: 0px 0px 0px -4px; cursor: pointer;"  src="{{mappaBody[23]}}" (click)="addVisualDamage('23')"/><span class="tooltiptextR" style="margin: -10px 20px 0px 30px;">{{mappaBodyDescr[23] | translate }}</span></td></tr>
                    <tr >
                        <td align="right" class="tooltipT"><img style="margin: -45px 0px 24px -4px; cursor: pointer;"  src="{{mappaBody[36]}}" (click)="addVisualDamage('36')"/><span class="tooltiptextT">{{mappaBodyDescr[36] | translate }}</span> </td>
                        
                        <td align="center" class="tooltipR"><img style="margin: -4px 0px 0px 0px; cursor: pointer;"  src="{{mappaBody[24]}}" (click)="addVisualDamage('24')"/><span class="tooltiptextR" style="margin: 0px 0px 0px 60px;">{{mappaBodyDescr[24] | translate }}</span></td>
                        
                        <td align="left" class="tooltipT"><img style="margin: 0px 0px 65px 0px; cursor: pointer;"  src="{{mappaBody[35]}}" (click)="addVisualDamage('35')"/><span class="tooltiptextT">{{mappaBodyDescr[35] | translate }}</span> </td>
                    </tr>

                    <tr >
                        <td align="center" ><img style="margin: -72px -32px 1px 6px; "  src="{{rootImg}}braccio_sin_dors.png"></td>
                        <td align="center" class="tooltipT"><img style="margin: -7px 0px 0px 0px; cursor: pointer;"  src="{{mappaBody[25]}}" (click)="addVisualDamage('25')"/><span class="tooltiptextT" style="margin: -8px 0px 0px 40px;">{{mappaBodyDescr[25] | translate }}</span></td>
                        <td align="center" ><img style="margin: -73px 1px 0px -43px; "  src="{{rootImg}}braccio_des_dors.png"></td>
                    </tr>
                    <tr >
                        <td align="center" ><img style="margin: -8px -26px 5px 0px; "  src="{{rootImg}}polso_sin_dors.png"></td>
                        <td align="center" class="tooltipT"><img style="margin: -9px 0px 0px 0px; cursor: pointer;"  src="{{mappaBody[26]}}" (click)="addVisualDamage('26')"/><span class="tooltiptextT" style="margin: -7px 0px 0px 40px;">{{mappaBodyDescr[26] | translate }}</span></td>
                        <td align="center" ><img style="margin: -10px 0px 2px -32px; "  src="{{rootImg}}polso_des_dors.png"></td>
                    </tr>
                    <tr >
                        <td align="right"  ><img style="margin: -6px 18px 196px 12px; "  src="{{rootImg}}mano_sin_dors.png"></td>
                        <td align="center"  ><img style="margin: -30px 0px 0px 0px;"  src="{{rootImg}}gambe_dors.png"></td>
                        <td align="left"  ><img style="margin: -4px 18px 195px 18px; "  src="{{rootImg}}mano_dest_dors.png"></td>
                        
                    </tr>
                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>
                                    <td align="right" class="tooltipT"><img style="margin: -35px -3px 34px -6px; cursor: pointer;"  src="{{mappaBody[70]}}" (click)="addVisualDamage('70')"/><span class="tooltiptextT" style="margin: 0px 0px 0px -90px;">{{mappaBodyDescr[70] | translate }}</span></td>
                                    <td align="left" class="tooltipT"><img style="margin: -39px -24px 29px 18px; cursor: pointer;"  src="{{mappaBody[69]}}" (click)="addVisualDamage('69')"/><span class="tooltiptextT" style="margin: -27px 0px 0px -23px;">{{mappaBodyDescr[69] | translate }}</span></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>	
                                    <td align="right"  ><img style="margin: -54px 7px 25px -1px; "  src="{{rootImg}}piede_sin_dors.png"></td>
                                    <td align="left"  ><img style="margin: -54px 2px 25px 5px; "  src="{{rootImg}}piede_dest_dors.png"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
            </tr>
            </table>
        </div>
      <!--- fine -->
    </div>

    <div class="form-group row">
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._FURTHER_INJURY_INDICATOR' | translate }}</label> <br>
            <input class="col-sm-3 form-control" formControlName="moreInjury" id="moreInjury" type="checkbox"
            name="moreInjury" class="form-control btn-checkbox checkbox-unit" attr.data-qa="injury-asset-detail-moreInjury-input" >
        </div>
        <div class="col-sm-8 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._PROFESSIONAL_QUALIFICATION' | translate }} </label>
            <select class="core-select form-control" formControlName="profession" id="profession" name="profession"
            attr.data-qa="injury-asset-detail-profession-input">
            <option></option>
            <option *ngFor="let el of professionList; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        

    </div>

    <div class="form-group row">
        <!---->
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._PERCENTAGE_OF_PERMANENT_DISABILITY' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="percIP" id="percIP" type="number"
            name="percIP" attr.data-qa="injury-asset-detail-percIP-input">
        </div>
        <div class="col-sm-5 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE' | translate }}</label>
            <input class="col-sm-5 form-control" formControlName="ggITB" id="ggITB" type="number"
            name="ggITB" attr.data-qa="injury-asset-detail-ggITB-input" >
           
        </div>
        <div class="col-sm-3 col-xs-12">
            <pnc-datepicker label="{{ '_CLAIMS_._FATALITY_DATE' | translate }}" customFormControlName="fatalDate"
            [customFormGroup]="addAssetForm" attr.data-qa="injury-asset-detail-fatalDate-input"
            id="fatalDate">  
            </pnc-datepicker> 
        </div>
        
    </div>
    <div class="form-group row">
        <!---->
        
        <claims-select-autocomplete attr.data-qa="injury-asset-detail-country-input"
            label="{{ '_CLAIMS_._FIRST_AID_COUNTRY' | translate }}" [(value)]="selectedCountryER" [values]="countries" [required]="false"
            [disabled]="false" [customFormGroup]="addAssetForm"
            [customFormControlName]="'selectedCountryER'"  (valueChange)="enableProviders($event)">
        </claims-select-autocomplete>
     
        <div class="col-sm-4 col-xs-12" *ngIf="disableSearchProviders" >
            <label ng-bind="label">{{ '_CLAIMS_._FIRST_AID_PLACE' | translate }}</label><br>
            <input class="col-sm-6 form-control" formControlName="placeER" id="placeER" type="text"
            name="placeER" attr.data-qa="injury-asset-detail-placeER-input" *ngIf="!disablePlaceER">
            <input class="col-sm-6 form-control" formControlName="placeER" id="placeER" type="text"
            name="placeER" attr.data-qa="injury-asset-detail-placeER-input" *ngIf="disablePlaceER" disabled>
        </div>
        <div class="col-sm-4 col-xs-12" *ngIf="!disableSearchProviders" >
            <label ng-bind="label">{{ '_CLAIMS_._HEALTHCARE_PROVIDERS' | translate }}</label>
            <input class="col-sm-6 form-control" formControlName="descriptionER" id="descriptionER" type="text"
            name="descriptionER" attr.data-qa="injury-asset-detail-descriptionER-input" disabled>
        </div>
        <div class="col-sm-2 col-xs-12">
            <br>
            <button class="btn btn-warning pull-right" (click)="searchHealthcareProviders($event)"
                 attr.data-qa="injury-asset-detail-HealthcareProviders-btn" [disabled]="disableSearchProviders">{{ '_CLAIMS_._HEALTHCARE_PROVIDERS' | translate | uppercase}}</button>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-sm-2 col-xs-12" style="margin-top: 2px;">
            <label ng-bind="label">{{ '_CLAIMS_._BIOLOGICAL_DAMAGE' | translate }}</label> <br>
            <input class="col-sm-3 form-control" formControlName="biologicalDamage" id="biologicalDamage" type="checkbox"
            name="biologicalDamage" class="form-control btn-checkbox checkbox-unit" attr.data-qa="injury-asset-detail-biologicalDamage-input" >
           
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._SUBROGATION_SOCIAL_ASS_EMPLOYER' | translate }} </label>
            <select class="core-select form-control" formControlName="surroga" id="surroga" name="surroga"
            attr.data-qa="injury-asset-detail-surroga-input">
            <option></option>
            <option *ngFor="let el of surrogaAnsw; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-5 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._DOCTOR' | translate }}</label><br>
            <input class="col-sm-6 form-control" formControlName="doctorName" id="doctorName" type="text"
            name="doctorName" attr.data-qa="injury-asset-detail-doctorName-input" disabled>
        </div>
        <div class="col-sm-1 col-xs-12">
            <br>
            <button class="btn btn-warning pull-right" (click)="searchDoc($event)"
                        attr.data-qa="injury-asset-detail-doc-btn">{{ '_CLAIMS_._BUTTONS_._SEARCH' | translate | uppercase}}</button>
        </div>
    </div>
    <!-- <div class="form-group row">
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._NATURE_OF_INJURY' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryNature1" id="injuryNature1" name="injuryNature1"
            attr.data-qa="injury-asset-detail-injuryNature1-input">
            <option></option>
            <option *ngFor="let el of injuryNatureCombo1; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._NATURE_OF_INJURY' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryNature2" id="injuryNature2" name="injuryNature2"
            attr.data-qa="injury-asset-detail-injuryNature2-input">
            <option></option>
            <option *ngFor="let el of injuryNatureCombo2; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._NATURE_OF_INJURY' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryNature3" id="injuryNature3" name="injuryNature3"
            attr.data-qa="injury-asset-detail-injuryNature3-input">
            <option></option>
            <option *ngFor="let el of injuryNatureCombo3; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._INJURY_LOCATION' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryPlace1" id="injuryPlace1" name="injuryPlace1"
            attr.data-qa="injury-asset-detail-injuryPlace1-input">
            <option></option>
            <option *ngFor="let el of injuryLocationCombo1; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._INJURY_LOCATION' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryPlace2" id="injuryPlace2" name="injuryPlace2"
            attr.data-qa="injury-asset-detail-injuryPlace2-input">
            <option></option>
            <option *ngFor="let el of injuryLocationCombo2; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._INJURY_LOCATION' | translate }} </label>
            <select class="core-select form-control" formControlName="injuryPlace3" id="injuryPlace3" name="injuryPlace3"
            attr.data-qa="injury-asset-detail-injuryPlace3-input">
            <option></option>
            <option *ngFor="let el of injuryLocationCombo3; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
    </div> -->
</div>
</form>
import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {
  AnagApiParty,
  AnagConfigService, AnagEntityIta,
  AnagPrivacyComponent,
  AnagStatelessService,
  AnagStateManagerPartyEditor,
  AnagStorageService
} from '@rgi/anag';
import {PushMessageHandlerService} from "@rgi/rx";
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {RgiRxUserService} from "@rgi/rx/auth";

@Component({
  selector: 'rgi-anag-privacy',
  templateUrl: './anag-privacy.component-ext.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagPrivacyComponentExt extends AnagPrivacyComponent implements OnInit, OnModalClose, AfterViewInit {

  constructor(
    public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public anagStatelessService: AnagStatelessService,
    public pushMessageHandler: PushMessageHandlerService,
    protected translateService: RgiRxTranslationService,
    public configService: AnagConfigService,
    protected userService: RgiRxUserService,
    @Optional() @Inject(DIALOG_DATA) party: AnagApiParty
  ) {
    super(stateMgr, anagStorage, anagStatelessService, pushMessageHandler, translateService, configService, party);
  }

  protected readonly EXADVCONS_CODE = '000002';
  protected readonly ROBINSONLIST_FX = 'ROLEDT';
  protected readonly EXADVCONS_FX = 'EXAD2W';

  ngAfterViewInit(): void {

    if (!this.isRobinsonListEditable()) {
      this.privacyForm.controls['robinsonList'].disable();
    }
    if (!this.isExcludeAdvertisingConsentEditable()) {
      this.privacyForm.controls[Number(this.EXADVCONS_CODE)].disable();
    }
  }

  private isRobinsonListEditable() {
    return this.userService.getUser().enabledFeatures.includes(this.ROBINSONLIST_FX);
  }

  private isExcludeAdvertisingConsentEditable() {
    return this.userService.getUser().enabledFeatures.includes(this.EXADVCONS_FX) || !this.inputParty || this.isExcludeAdvertisingConsentNo();
  }

  private isExcludeAdvertisingConsentNo() {
    if (this.inputParty.privacyConsensus) {
      let filteredPrivacy = this.inputParty.privacyConsensus.filter(
        p => Number(p.privacy.code) === Number(this.EXADVCONS_CODE));
      if (filteredPrivacy && filteredPrivacy.length === 1) {
        return filteredPrivacy[0].privacyConsensusValue.codice !== '1' || !filteredPrivacy[0].privacyConsentDateUpd;
      } else if (filteredPrivacy && filteredPrivacy.length === 0) {
        // if there aren't consensus you can edit because it's like no
        return true;
      }
    }
  }

  questionOptionsFiltered(question: AnagEntityIta) {
    return super.questionOptions;
  }
}

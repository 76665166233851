
export class PlcGraphicFix {

  public static fixCardGraphic(id: string) {
    const actualElem = document.getElementById('life-detail-card-wrapper');
    if (!!actualElem) {
      const cardContainer = actualElem.parentElement.parentElement;
      cardContainer.classList.add('life-detail-card-container');
    }
    // vietato ai minori di 18
    // per ripristinare il titolo e i bordi della consultazione
    if (!!id) {
      const parent = document.getElementById('card-container-' + id).parentElement;
      parent.getElementsByClassName('card-title-container')[0].setAttribute('style', 'display:none');
      parent.getElementsByClassName('card-container')[0].setAttribute('style', 'background-color:#CBD6E0; margin-bottom:-20px');
    }
  }

}

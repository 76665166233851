export class PcPolicy {
  company: any;
  node: any;
  branch?: any;
  product: any;
  status: any;
  contractNumber: any;
  issueDate: string;
  effectDate: string;
  expireDate: string;
  movement: any;
  roles: any;
  assets: any;
  messages: any[];
  extensions: any[];
  additionalLabels?: any;
}

import {
  PNC_POSTSALES_OPERATIONS_TYPES,
  PncDomainService,
  PncPsalesConfirmationStateManager,
  PncPsalesRequiredDataStateManagerDates,
  PncPsalesSummaryStateManagerVariations,
  PncPsalesTechAccountingStateManager,
  PostSalesOperation,
  RGI_PNC_POSTSALES_STEPS
} from '@rgi/pnc-postsales';
import {API_PREFIX_MOTOR} from '../resources/constants/http';
import {RGI_MOTOR_POSTSALES_STEPS} from '../resources/constants/steps';
import {
  MotorPsalesRequiredDataStateManagerAssetsNations
} from '../ops-managers/variations/required-data/required-data-state-manager-assets-nations';
import {MotorDomainService} from '../resources/http/motor-domain.service';
import {MOTOR_POSTSALES_OPERATIONS_CODE} from '../resources/constants/motor-operations';

// Estensione Carte Verde - Green Card Extension
export const MOTOR_PSALES_VD0082: PostSalesOperation = {
  type: PNC_POSTSALES_OPERATIONS_TYPES.ASSETS_DATA_AMENDMENTS,
  operationCode: MOTOR_POSTSALES_OPERATIONS_CODE.EXTENSION_GREEN_CARD,
  sessionTitle: '_MOTORPSALES_._SESSION_TITLE_._EXTENSION_GREEN_CARD_',
  steps: [
    {
      step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
      manager: PncPsalesRequiredDataStateManagerDates,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._VARIATION_DATES_',
      context: {apiPrefix: API_PREFIX_MOTOR}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.TECHNICAL_ACCOUNTING,
      manager: PncPsalesTechAccountingStateManager,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._TECHNICAL_ACCOUNTING_',
      context: {apiPrefix: API_PREFIX_MOTOR}
    },
    {
      step: RGI_MOTOR_POSTSALES_STEPS.EXTENSION_LIST,
      manager: MotorPsalesRequiredDataStateManagerAssetsNations,
      deps: [MotorDomainService],
      stepLabel: '_MOTORPSALES_._STEPS_._EXTENSION_LIST_',
      context: {apiPrefix: API_PREFIX_MOTOR}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
      manager: PncPsalesSummaryStateManagerVariations,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
      context: {apiPrefix: API_PREFIX_MOTOR}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
      manager: PncPsalesConfirmationStateManager,
      deps: [],
      stepLabel: ''
    }
  ]
};

import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {QuestionnaireResourceModule} from "./resource/questionnaire-resource.module";
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {NG_PASSPROPRO_QUESTIONNAIRE_TRANSLATIONS} from "./i18n";


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        QuestionnaireResourceModule,
        RgiRxI18nModule.forRoot(NG_PASSPROPRO_QUESTIONNAIRE_TRANSLATIONS)
    ],
    providers: [{provide: DatePipe, useClass: DatePipe}]
})
export class NgPassProProQuestionnaireModule {
}

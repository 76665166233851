import {AfterContentInit, ContentChild, Directive, Input, OnInit} from '@angular/core';
import {RgiRxTabContentDirective} from './rgi-rx-tab-content.directive';
import {RgiRxVirtualDOMError} from '@rgi/rx';
import {RgiRxTabLabelDirective} from './rgi-rx-tab-label.directive';


@Directive({
  selector: 'rgi-rx-tab,[rgi-rx-tab]',
  host: {
    class: 'rgi-ui-tab'
  }
})
export class RgiRxTabDirective implements AfterContentInit {

  @Input() label: string;
  @Input() disabled: boolean;
  @ContentChild(RgiRxTabContentDirective, {static: false}) content: RgiRxTabContentDirective;
  @ContentChild(RgiRxTabLabelDirective, {static: false}) labelTemplate?: RgiRxTabLabelDirective;

  ngAfterContentInit(): void {
    if (!this.content) {
      throw new RgiRxVirtualDOMError('rgi-rx-tab must have an rgiRxTabContent ng-template defined');
    }
  }

}

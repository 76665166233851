import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {of} from 'rxjs';
import {Commission} from '../../../group-policy-models/group-policy-issue-guarantees';
import {GroupPolicyStateGuarantees} from '../../../group-policy-state/group-policy-state';
import {
  GroupPolicyStatelessOpGuaranteesService
} from '../../../group-policy-state/group-policy-statemanager-guarantees/group-policy-stateless-op-guarantees.service';
import {DataForSteps} from '../../../group-policy-models/group-policy-issue-home';

/**
 * @author: dmasone
 * @description: Modal used for render commissions
 */
@Component({
  selector: 'rgi-gp-modal-commissions-life',
  templateUrl: './group-policy-modal-commissions-life.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyModalCommissionsLifeComponent implements OnModalClose {

  public modalClose = new EventEmitter<any>();
  public stateGuarantees: GroupPolicyStateGuarantees;
  public commissions: Commission[];
  public uuid: string;
  public commissionsKeys = [];
  public commissionsForm = new UntypedFormGroup({});

  constructor(@Optional() @Inject(DIALOG_DATA) data: DataForSteps,
              protected statelesOps: GroupPolicyStatelessOpGuaranteesService) {
    this.stateGuarantees = data.state;
    this.uuid = data.resId;
    this.statelesOps.retrieveCommissions$(of(this.stateGuarantees), this.uuid)
      .subscribe((st: GroupPolicyStateGuarantees) => {
        this.commissions = st.commissions;
        this.fillForm();
      });
  }


  public getKey(key: string): string {
    return key.substring(0, key.indexOf('_'));
  }

  public saveCommissions() {
    this.updateValues();
    this.statelesOps.updateCommissions$(of(this.stateGuarantees), this.uuid, this.commissions)
      .subscribe((st: GroupPolicyStateGuarantees) => {
        if (st.errors.length > 0 || st.errorsCommissions.length === 0) {
          this.modalClose.emit();
        }
      });

  }

  protected updateValues() {
    const values = this.commissionsForm.getRawValue();
    this.commissions.forEach((risk, index) => {
      if (risk) {
        Object.keys(risk).forEach((key: string) => {
          if (key && key !== 'idRisk' && key !== 'riskDescr' && risk[key] !== null) {
            const id = key + '_' + this.commissions[index]['idRisk'];
            this.commissions[index][key].value = values[id];
          }
        });
      }
    });
  }

  protected fillForm() {
    this.commissions.forEach((risk, index) => {
      if (risk) {
        this.commissionsKeys.push({keys: []});
        Object.keys(risk).forEach((key: string) => {
          if (key && key !== 'idRisk' && key !== 'riskDescr' && risk[key] !== null) {
            const id = key + '_' + this.commissions[index]['idRisk'];
            this.commissionsKeys[index].keys.push(id);
            this.commissionsForm.addControl(
              id, new UntypedFormControl({
                value: this.commissions[index][key].value,
                disabled: !this.commissions[index][key].editable
              })
            );
            this.commissionsForm.controls[id].setValidators([Validators.min(0), Validators.max(100)]);
          }
        });
      }
    });
  }

}

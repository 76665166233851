export const restEndPoint = {
    // POPULATING THE SEARCH
    // functionAvailaibleList
    fillInSearchFunMes: '/claims/functionAvailaibleList', /* riempie le select di function e message nella search */
    fillInSearchOggetto: '/claims/dialogueSubjectAvailaibleList', /* riempie le select di object nella search */      
    fillInSearchCompany: '/claims/companyList', /* riempie le select delle compagnie nella search*/
 
    //MOVE TO LIST
    goToSearchResults: '/claims/searchDialogues', /* dalla search porta alla lista */
 
    /* dalla lista porta al summary */
    goToNewDialogue: '/claims/goToNewDialogue', /* dalla search o dalla list apre il nuovo dialogo */
    saveNewDialogue: '/claims/saveNewDialogue', /* salva il nuovo dialogo o risponde dalla summary */
    associaAlSinistro:'/claims/saveNewDialogue', /* associa il dialogo al sinistro*/

    // POPULATING THE NEW DIALOGUE
    roleListActiveRoleCompany:'/claims/roleList',
    functionParam:'/claims/functionParam',
    attoreCausaleBySinistroList:'/claims/attoreCausaleBySinistroList',//prima tabella
    attoreDocumenti:'/claims/attoreDocumenti', //terza tabella
    functionDoc:'/claims/functionDoc', //seconda tabella
    saveDialogue:'/claims/saveDialogueManualActivity',
    response:'/claims/possibleAnswerFromConfig',

    // POPULATING SUMMARY
    summary:'/claims/getPossibleResponses',
    cancelService:'/claims/cancelDateAtNow/',
    resetAdvanceService:'/claims/exCompanyEqualsExRegulations/',
    associatedDialogue: '/claims/associatedDialogue',
    dettaglioSinistroDenuncia: '/claims/dettaglioSinistroDenuncia',
    isReassociationPossible: '/claims/isReassociationPossible'

   
}
import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import {RgiRxAbstractDropContainer, RgiRxOnDragSort, RgiRxOnDragSortEnded} from '../rgi-rx-drag-drop.api';
import {ModalService} from '../../modal/modal.service';
import {NgControl} from '@angular/forms';
import {RgiRxOnDragBodyRemove} from '../rgi-rx-drag-body/rgi-rx-drag-body.component';
import {RgiRxDropSelectionResolverService} from '../rgi-rx-drop-selection-resolver.service';
import {CdkDragDrop, CdkDragSortEvent, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {RgiRxDragActionDirective} from '../rgi-rx-drag-action.directive';
import {RgiRxDragRemoveDirective} from '../rgi-rx-drag-remove.directive';
import {RgiRxDropBodyComponent} from '../rgi-rx-drop-body/rgi-rx-drop-body.component';
import {RgiRxDragLabelDirective} from '../rgi-rx-drag-label.directive';
import {RgiRxDragValueDirective} from '../rgi-rx-drag-value.directive';
import {RgiRxDropLabelDirective} from '../rgi-rx-drop-label.directive';
import {isEqual} from 'lodash';

let rgiRxDropListCounter = 0;

@Component({
  selector: 'rgi-rx-drop-list-container',
  templateUrl: './rgi-rx-drop-list-container.component.html',
  styleUrls: ['./rgi-rx-drop-list-container.component.scss'],
  host: {
    '[class.rgi-ui-disabled]': 'disabled',
    '(change)': 'onChange($event.target.value)',
    '(blur)': 'onTouched()'
  },
  inputs: [
    'viewField',
    'field',
    'label',
    'disabled',
    'connectedTo',
    'predicate',
    'select',
    'selectData'
  ],
  outputs: [
    'onDragEnter',
    'onDragExit',
    'onDrop',
    'onRemove',
    'onValueChange'
  ],
  providers: [
    {
      provide: RgiRxAbstractDropContainer, useExisting: RgiRxDropListContainerComponent,
    },
    RgiRxDropSelectionResolverService
  ]
})
export class RgiRxDropListContainerComponent extends RgiRxAbstractDropContainer<any[]> implements AfterViewInit, OnDestroy {


  @Output() onDragSort = new EventEmitter<RgiRxOnDragSort>();
  @Output() onDragSortEnded = new EventEmitter<RgiRxOnDragSortEnded>();
  private _sortable = false;

  @ContentChildren(RgiRxDragActionDirective) dragActions: QueryList<RgiRxDragActionDirective>;
  @ViewChild(CdkDropList, {static: false}) cdkDropList: CdkDropList;
  @ContentChild(RgiRxDragRemoveDirective, {static: false}) dragRemove: RgiRxDragRemoveDirective;
  @ViewChild(RgiRxDropBodyComponent, {static: false}) dropBodyComponent: RgiRxDropBodyComponent;
  @ContentChild(RgiRxDragLabelDirective, {static: false}) dragLabelDirective: RgiRxDragLabelDirective;
  @ContentChild(RgiRxDragValueDirective, {static: false}) dragValueDirective: RgiRxDragValueDirective;
  @ContentChild(RgiRxDropLabelDirective, {static: false}) dropLabelDirective: RgiRxDropLabelDirective;


  constructor(
    private modalService: ModalService,
    private elementRef: ElementRef,
    @Optional() private _ngControl?: NgControl
  ) {
    super();
    // setting the value accessor here to prevent cyclic dependency
    if (_ngControl) {
      _ngControl.valueAccessor = this;
    }
    this.id = `rgi-rx-drop-list-container-${rgiRxDropListCounter++}`;
  }


  @Input() get sortable(): boolean {
    return this._sortable;
  }

  set sortable(value: boolean) {
    this._sortable = value;
  }


  ngAfterViewInit(): void {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  updateModel(selection: any) {
    if (Array.isArray(selection)) {
      const modelChanges = selection.map(p => this.getModel(p, this.field));
      this.model = this.model.concat(modelChanges);
      this.onChange(this.model);
      this.onValueChange.next({
        changed: modelChanges
      });
    } else {
      const modelChange = this.getModel(selection, this.field);
      this.model.push(modelChange);
      this.onChange(this.model);
      this.onValueChange.next({
        changed: modelChange
      });
    }
  }


  remove(event: RgiRxOnDragBodyRemove): void {
    if (this.dragRemove && event.origin === 'drag') {
      this.dragRemove.onRemove.emit(event);
      return;
    }
    this.model = this.model.filter(f => !isEqual(f, event.drag.model));
    this.onChange(this.model);
    this.onRemove.emit({
      data: event.drag.model,
      event: event.event,
      origin: event.origin
    });
  }

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.model, event.previousIndex, event.currentIndex);
      this.onDragSortEnded.emit({
        source: {
          data: event.item.data,
          element: event.container.element
        },
        target: {
          data: event.container.data,
          element: event.container.element
        }
      });
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.onDrop.emit({
        source: {
          data: event.item.data,
          element: event.previousContainer.element
        },
        target: {
          data: event.container.data,
          element: event.container.element
        }
      });
    }
  }

  resolve() {
    this.dropBodyComponent.resolve();
  }

  sort(event: CdkDragSortEvent) {
    this.onDragSort.emit({
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
      source: {
        data: event.item.data,
        element: event.item.element
      },
      target: {
        data: event.container.data,
        element: event.container.element
      }

    });
  }


  writeValue(obj: any[]) {
    if (!obj) {
      super.writeValue([]);
      return;
    }
    super.writeValue(obj);
  }
}

import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import {
  DataTransferDefinition,
  FiscalDataFolders,
  FormFieldsDefinition,
  PostsalesOperationObject,
} from '../../models/postsales-operations-response.model';
import { LpcFormFieldHandlerUtil } from '../../modules/lpc-form-field-handler/utils/lpc-form-field-handler.utils';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { AnagService } from '../../services/anag.service';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { BackPackDataStepperConfig, BACKPACK_TRANSFER } from './config/backpack-transfer-configuration';

@Component({
  selector: 'lpc-backpack-transfer',
  templateUrl: './backpack-transfer.component.html',
  styleUrls: ['./backpack-transfer.component.scss'],
})
export class BackpackTransferComponent extends AbsOperationComponent {
  public readonly BACKPACK_TRANSFER_STEP: BackPackDataStepperConfig =
    BACKPACK_TRANSFER;

  protected operationDataKey = 'backpack-transfer';
  dataTransferDefinitions: DataTransferDefinition[];
  fiscalFolderData20012006: FormFieldsDefinition[] = [];
  fiscalFolderDataBefore2001: FormFieldsDefinition[] = [];
  fiscalFolderDataPost2006: FormFieldsDefinition[] = [];
  fiscalDataFolder: FormFieldsDefinition[];

  fiscalData: [{}];

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE)
    protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected karmaService: KarmaFundService,
    protected angularCommunicationService: AngularCommunicationService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(
      operations,
      cd,
      translate,
      injector,
      questCacheService,
      modalService,
      notifierService,
      plcQuestService,
      authService,
      anag
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      [BACKPACK_TRANSFER.DATE.formName]: new UntypedFormControl([{}]),
      [BACKPACK_TRANSFER.SELECT_TRANSFER.formName]: new UntypedFormControl([{}]),
      [BACKPACK_TRANSFER.FISCAL_DATA_HEADER.formName]: new UntypedFormControl([{}]),
      [BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_2001_2006.formName]: new UntypedFormControl([{}]),
      [BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_BEFORE_2001.formName]: new UntypedFormControl([{}]),
      [BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_POST_2006.formName]: new UntypedFormControl([{}]),
    });
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe()
    );
  }

  updateDraftHandleResponse(
    result: PostsalesOperationObject,
    step: string,
    reload?: boolean,
    opDataType?: string
  ) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    if (!this.dataTransferDefinitions) {
      this.dataTransferDefinitions =
      result.definitions.reserveTransferList as DataTransferDefinition[];
    } else if (step === BACKPACK_TRANSFER.SELECT_TRANSFER.id) {
      this.fiscalData = [
        {
          fiscalFolderDataPost2006: result.definitions
            [BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_POST_2006.formName] as FormFieldsDefinition[],
          fiscalFolderData20012006: result.definitions
            [BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_2001_2006.formName] as FormFieldsDefinition[],
          fiscalFolderDataBefore2001: result.definitions
            [BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_BEFORE_2001.formName] as FormFieldsDefinition[],
        },
      ];
      this.fiscalDataFolder = result.definitions
        .fiscalDataHeader as FormFieldsDefinition[];
      this.formGroup
        .get(BACKPACK_TRANSFER.FISCAL_DATA_HEADER.formName)
        .patchValue({
          definitionsFields: this.fiscalDataFolder.map((x) =>
            LpcFormFieldHandlerUtil.mapFormFieldData(x)
          ),
        });
    }
    this.detectChanges();
  }

  protected getTransformedOperationData(): any {
    return {
      reserveTransferSelected: !!this.formGroupValue.reserveTransferSelect
        ? this.formGroupValue.reserveTransferSelect.data
        : '',
      fiscalDataTransfersFolder: !!this.formGroupValue[BACKPACK_TRANSFER.FISCAL_DATA_HEADER.formName]
        .definitionsFields
        ? this.fiscalDataFormFields()
        : {
            headerDataTransfer: [],
            fiscalFolderData20012006: [],
            fiscalFolderDataBefore2001: [],
            fiscalFolderDataPost2006: [],
          },
    };
  }

  fiscalDataFormFields(): FiscalDataFolders {
    return {
      headerDataTransfer: LpcFormFieldHandlerUtil.extractFormFieldData(
        this.formGroupValue[BACKPACK_TRANSFER.FISCAL_DATA_HEADER.formName].definitionsFields,
        ['TRAZACompanyNameOfPreviousPensionFund']
      ),
      fiscalFolderData20012006: !!this.formGroupValue[BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_2001_2006.formName]
        .definitionsFields
        ? LpcFormFieldHandlerUtil.extractFormFieldData(
            this.formGroupValue[BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_2001_2006.formName].definitionsFields
          )
        : [],
      fiscalFolderDataBefore2001: !!this.formGroupValue
        [BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_BEFORE_2001.formName].definitionsFields
        ? LpcFormFieldHandlerUtil.extractFormFieldData(
            this.formGroupValue[BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_BEFORE_2001.formName].definitionsFields
          )
        : [],
      fiscalFolderDataPost2006: !!this.formGroupValue[BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_POST_2006.formName]
        .definitionsFields
        ? LpcFormFieldHandlerUtil.extractFormFieldData(
            this.formGroupValue[BACKPACK_TRANSFER.FISCAL_FOLDER_DATA_POST_2006.formName].definitionsFields
          )
        : [],
    };
  }

  changedTransfer(event) {
    this.fiscalDataFolder = null;
    this.formGroup.get(BACKPACK_TRANSFER.FISCAL_DATA_HEADER.formName).reset({definitionsFields: null});
    this.formGroup.get(BACKPACK_TRANSFER.FISCAL_DATA_HEADER.formName).clearValidators();
    this.formGroup.get(BACKPACK_TRANSFER.FISCAL_DATA_HEADER.formName).updateValueAndValidity();
  }
}

import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {RgiRxFileReaderService} from '@rgi/rx';
import {UserService} from '@rgi/rx/auth';
import {RgiRxHttpClientFactory, RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {Observable, of} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {GroupPolicyDateAdapter} from '../adapters/ngbDateCustomParserFormatter ';
import {RestApiConf} from '../group-policy-constants/rest-api-conf';
import {
  ApplicationsResponse,
  GPAppInclusionData,
  GPAppInclusionMotorData
} from '../group-policy-models/group-policy-application';
import {AnagSubject, ContractDetails, OperationsResponse} from '../group-policy-models/group-policy-inquiry';
import {IssuePolicyData} from '../group-policy-models/group-policy-issue-confirm';
import {
  Commission,
  GPAsset,
  GpAssetUnitsClauses,
  LinkedPolicyQuotation,
  Surveys
} from '../group-policy-models/group-policy-issue-guarantees';
import {
  Agency,
  Company,
  DemandAndNeeds,
  IdentificationKey,
  NewFlowIssueRequest,
  NewFlowIssueResponse,
  Product,
  ProductsReq
} from '../group-policy-models/group-policy-issue-home';
import {
  BaseEntity,
  ChecksAllResp,
  CoinsuranceShareList,
  ContractAddressesPut,
  ContractAddressesResp,
  Entity, ErrorMsg,
  ErrorResp,
  Factor,
  GPClause,
  GPExtensionDataSections,
  GpListValues,
  GPPolicyDataField,
  Indexing,
  MeanOfPayment,
  PaymentConfig,
  PaymentFrequency,
  paymentsCode,
  paymentsIDs,
  PaymentsPayload,
  Property,
  ResultMessage,
  Role,
  SaveResp,
  SubjectContract,
  SystemProp
} from '../group-policy-models/group-policy-issue-policy-data';
import {ButtonsAction, Documents, Summary} from '../group-policy-models/group-policy-issue-summary';
import {
  ActionsResponse,
  DatesPayload,
  NewFlowPayload,
  NewFlowPostSalesResponse,
  NextRouteResponse,
  SaveDateResponse
} from '../group-policy-models/group-policy-vcont-date';
import {
  DeleteRolePostSalesResponse,
  DeleteSubcauseResponse,
  PartyRolePostSalesResponse,
  PaymentsPostSalesResponse,
  SaveOperationResponse,
  SetSubcauseResponse,
  SubcauseModel
} from '../group-policy-models/group-policy-vcont-variation';
import {OperatorLight} from '../services/external-service';
import {GpProposalAuthorization} from '../group-policy-models/group-policy-authorization';
import {GPIdentificationEntity} from '../group-policy-models/group-policy-domain-types';
import {GPRecoverAppsPayload} from '../group-policy-models/gp-move-application';
import {AnagApiParty} from '@rgi/anag';
import {GPCluster, GPLightCluster} from '../group-policy-models/group-policy-cluster';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyResourceService {

  protected hostPath: string;
  protected _externalCompanies: Array<Entity> = new Array();
  protected _intermediaries: Array<Entity> = new Array();

  protected httpClient: RgiRxHttpClientWrapper;

  constructor(
    protected httpClientFactory: RgiRxHttpClientFactory,
    @Inject('environment') protected apiConf: RestApiConf,
    protected userService: UserService,
    protected dateAdapter: GroupPolicyDateAdapter,
    protected fileReaderService: RgiRxFileReaderService) {
    this.httpClient = this.httpClientFactory.buildWrapper();
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path;
  }

  /**
   * @param newFlowIssueReq input obj to start issue
   * @param demandAndNeeds questionnaire
   */
  newFlowIssueGroupPolicy(newFlowIssueReq: NewFlowIssueRequest, demandAndNeeds?: DemandAndNeeds): Observable<NewFlowIssueResponse> {
    newFlowIssueReq.userCode = this.userService.getUser<OperatorLight>().username;
    if (demandAndNeeds) {
      newFlowIssueReq.demandAndNeedsData = demandAndNeeds;
    }
    return this.httpClient.post<NewFlowIssueResponse>(`${this.hostPath}/v2/grouppolicies/contract-drafts`, newFlowIssueReq);
  }

  newFlowPostSalesGroupPolicy$(payload: NewFlowPayload, proposalNumber: string): Observable<NewFlowPostSalesResponse> {
    return this.httpClient.post<NewFlowPostSalesResponse>(`${this.hostPath}/v2/group-policies-postsales/${proposalNumber}/draft`, payload);
  }

  getProducts$(data?: ProductsReq): Observable<Product[]> {
    let params = new HttpParams();
    if (data) {
      if (data.codeSP) {
        params = params.append('codeSP', data.codeSP);
      }
      if (data.effectiveDate) {
        params = params.append('effectiveDate', this.dateAdapter.toApi(data.effectiveDate));
      }
      if (data.filterProductsCategory) {
        params = params.append('filterProductsCategory', data.filterProductsCategory);
      }
      if (data.filterSalableProduct) {
        params = params.append('filterSalableProduct', data.filterSalableProduct.toString());
      }
      if (this.userService.isLoggedIn()) {
        params = params.append('userCode', this.userService.getUser<OperatorLight>().username);
      }
    }
    return this.httpClient.get<Product[]>(`${this.hostPath}/v2/products/products`, { params });
  }

  getCompanies$(): Observable<Company[]> {
    return this.httpClient.get<Company[]>(`${this.hostPath}/v2/common/companies/internal`);
  }

  getNodes$(): Observable<Agency[]> {
    let params = new HttpParams();
    if (this.userService.isLoggedIn()) {
      params = params.append('loginNodeId', this.userService.getUser<OperatorLight>().salePoint.objectId);
    }
    return this.httpClient.get<Agency[]>(`${this.hostPath}/v2/common/agencies`, { params });
  }

  updatePartyRole$(uuid: string, subj: SubjectContract): Observable<any> {
    return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/roles`, subj);
  }

  editProposal(proposalNumber: string): Observable<IdentificationKey> {
    return this.httpClient.get<IdentificationKey>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${proposalNumber}/edit`);
  }

  proposalIssue(proposalNumber: string): Observable<IdentificationKey> {
    return this.httpClient.get<IdentificationKey>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${proposalNumber}/issue`);
  }

  getPartyRoles$(uuid: string): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/roles`);
  }

  getPaymentFrequencies$(uuid: string): Observable<PaymentFrequency> {
    return this.httpClient.get<PaymentFrequency>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/payments-frequency`);
  }

  updatePaymentFrequency$(uuid: string, paymentFrequency: BaseEntity): Observable<any> {
    return this.httpClient.put(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/payments-frequency`, paymentFrequency);
  }

  getConventions$(uuid: string): Observable<GpListValues> {
    return this.httpClient.get<GpListValues>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/agreements`);
  }

  updateAgreement$(uuid: string, agreement: BaseEntity): Observable<any> {
    return this.httpClient.put(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/agreements`, agreement);
  }


  retrieveFactors$(uuid: string, visible = 'true', configurable = 'true',
                   onlyApplication = 'true'): Observable<Factor[]> { // TODO: Delete headers
    let params = new HttpParams();
    let headers = new HttpHeaders();
    params = params.append('visible', visible);
    params = params.append('configurable', configurable);
    params = params.append('onlyApplication', onlyApplication);
    headers = headers.append('RGI_user', this.userService.getUser<OperatorLight>().username);
    return this.httpClient.get<Factor[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/variables`, { headers, params });
  }

  updateFactors$(uuid: string, factors: Factor[]): Observable<Factor[]> { // TODO: Delete headers
    let headers = new HttpHeaders();
    headers = headers.append('RGI_user', this.userService.getUser<OperatorLight>().username);
    return this.httpClient.put<Factor[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/variables`,
      factors, { headers });
  }

  initVariables$(uuid: string, visible = 'true', configurable = 'true', onlyApplication = 'true'): Observable<any> {
    let params = new HttpParams();
    params = params.append('onlyVisible', visible);
    params = params.append('configurable', configurable);
    params = params.append('onlyApplication', onlyApplication);
    return this.httpClient.put(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/initvariables`, null, { params });
  }

  getPaymentTypePages$(uuid: string, paymentType: paymentsCode): Observable<Property> {
    return this.httpClient.get<Property>(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/properties?code=${paymentType}`);
  }

  getCreditPaymentValues$(uuid: string): Observable<PaymentConfig> {
    const input = {
      function: 'CONTRACT',
      paymentType: '1',
    };

    return this.httpClient.post<PaymentConfig>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/payments`, input);
  }

  getDebitPaymentValues$(uuid: string): Observable<PaymentConfig> {
    const input = {
      function: 'CONTRACT',
      paymentType: '2',
    };

    return this.httpClient.post<PaymentConfig>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/payments`, input);
  }

  getPaymentConfig$(uuid: string, paymentType?: paymentsIDs): Observable<PaymentConfig> {
    const payload = {
      function: 'CONTRACT',
      paymentType: paymentType ? paymentType : null
    };
    return this.httpClient.post<PaymentConfig>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/payments`, payload);
  }

  checksAll$(uuid: string): Observable<ChecksAllResp> {
    return this.httpClient.get<ChecksAllResp>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/roles/checksAll`);
  }

  setPayments$(uuid: string, payload: PaymentsPayload[]): Observable<any> {
    return this.httpClient.put<any>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/payments`, payload);
  }

  saveAction$(uuid: string, nodeCode: string): Observable<SaveResp> {  // TODO: Delete headers
    let headers = new HttpHeaders();
    if (nodeCode) {
      headers = headers.append('RGI_idPv', nodeCode);
    }
    return this.httpClient.post<SaveResp>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}`, {}, { headers });
  }

  getAssets$(uuid: string): Observable<GPAsset[]> {
    return this.httpClient.get<GPAsset[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets`);
  }

  getAvailableAssets$(uuid: string): Observable<BaseEntity[]> {
    return this.httpClient.get<BaseEntity[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/availableassets`);
  }

  addAssets$(uuid: string, assetCodes: string[]) {
    return this.httpClient.put(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets`, assetCodes);
  }

  updateAssets$(uuid: string, assets: GPAsset[]) {
    return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets`, assets);
  }

  deleteAsset$(uuid: string, assetCode: string) {
    return this.httpClient.delete<ErrorMsg[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}`);
  }

  deleteRole$(uuid: string, role: string, idParty: string) {
    return this.httpClient.delete(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/roles/${role}/parties/${idParty}`);
  }

  retrieveSummary$(uuid: string): Observable<Summary> {
    return this.httpClient.get<Summary>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/summaries`);
  }

  retrieveActions$(uuid: string, flow?: string): Observable<ButtonsAction[]> {
    let params = new HttpParams();
    if (flow) {
      params = params.append('flow', flow);
    }
    return this.httpClient.get<ButtonsAction[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/summaryactions`, { params });
  }

  saveProposalActions$(uuid: string, nodeCode: string): Observable<SaveResp> {  // TODO: Delete headers
    let headers = new HttpHeaders();
    if (nodeCode) {
      headers = headers.append('RGI_idPv', nodeCode);
    }
    return this.httpClient.post<SaveResp>(`${this.hostPath}/v2/grouppolicies/contracts/${uuid}`, {}, { headers });
  }


  saveContractDrafts$(uuid: string, nodeCode: string, payload): Observable<SaveResp> {  // TODO: Delete headers
    let headers = new HttpHeaders();
    if (nodeCode) {
      headers = headers.append('RGI_idPv', nodeCode);
    }
    return this.httpClient.post<SaveResp>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}`, payload, { headers });
  }

  actionCode$(uuid: string, page: 'TPM' | 'CPM' | 'UPM'): Observable<ErrorResp[]> {
    return this.httpClient.post<ErrorResp[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/actions?actionCode=${page}`, {});
  }

  getSystemProp$(keys: string[]): Observable<SystemProp> {
    const request: any = {
      filterKey: { keys }
    };
    return this.httpClient.post<SystemProp>(this.hostPath + '/system/systemproperty', request);
  }

  retrieveContractAddresses$(uuid: string): Observable<ContractAddressesResp> {
    return this.httpClient.get<ContractAddressesResp>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/contract-addresses`);
  }

  updateContractAddresses$(uuid: string, payload: ContractAddressesPut): Observable<any> {
    return this.httpClient.put<any>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/contract-addresses`, payload);
  }

  retrieveCommissions$(uuid: string): Observable<Commission[]> {
    return this.httpClient.get<Commission[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/commissions`);
  }

  updateCommissions$(uuid: string, payload: Commission[]): Observable<any> {
    return this.httpClient.put<any>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/commissions`, payload);
  }

  retrieveQuestionnaires$(uuid: string, level: '1' | '2'): Observable<Surveys[]> {
    return this.httpClient.get<Surveys[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/surveys?level=${level}`);
  }

  updateQuestionnaires$(uuid: string, payload: Surveys[]): Observable<any> {
    return this.httpClient.put<any>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/surveys`, payload);
  }

  retrieveLinkedPolicyQuotation$(uuid: string): Observable<LinkedPolicyQuotation> {
    return this.httpClient.get<LinkedPolicyQuotation>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/quotations`);
  }

  getApplicationsOfMasterPolicy$(proposalNumber: string,
                                 clusterCode: string,
                                 assetCode: string,
                                 onlyValidPolicy?: boolean,
                                 pageNum?: number,
                                 rowsOfPage?: number,
                                 _filter?: string): Observable<ApplicationsResponse> {
    let params = new HttpParams();
    if (pageNum) {
      params = params.append('pageNum', String(pageNum));
    }
    if (rowsOfPage) {
      params = params.append('rowsOfPage', String(rowsOfPage));
    }
    if (_filter) {
      params = params.append('filter', _filter);
    }
    if (onlyValidPolicy) {
      params = params.append('onlyValidPolicy', String(onlyValidPolicy));
    }
    if (clusterCode && assetCode) {
      params = params.append('clusterCode', clusterCode);
      params = params.append('assetCode', assetCode);
    }
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<ApplicationsResponse>(`${this.hostPath}/v2/grouppolicies/master-contracts/${proposalNumber}/applications`, {params});
  }

  getContractDetail$(contractNumber: string, idMovement: string): Observable<ContractDetails> {
    let params = new HttpParams();
    if (idMovement) {
      params = params.append('idMovement', idMovement);
    }
    return this.httpClient.get<ContractDetails>(`${this.hostPath}/v2/grouppolicies/master-contracts/${contractNumber}/details`, {params});
  }

  retrieveDocuments$(contractNumber: string, operationCode?: string): Observable<Documents[]> {
    let params = new HttpParams();
    if (operationCode) {
      params = params.append('operationCode', operationCode);
    }
    return this.httpClient.get<Documents[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${contractNumber}/documents`, {params});
  }

  saveDocuments$(contractNumber: string, payload: Documents[], operationCode?: string): Observable<any> {
    const path = (operationCode) ?
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${contractNumber}/documents?operationCode=${operationCode}` :
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${contractNumber}/documents`;
    return this.httpClient.put<any>(path, payload);
  }

  getSubjectDetail$(idParty: string): Observable<AnagSubject> {
    return this.httpClient.get<AnagSubject>
      (`${this.hostPath}/anag/subject/${idParty}?idNode=${this.userService.getUser<OperatorLight>().salePoint.objectId}`);
  }

  getPolicyOperations$(proposalNumber: string): Observable<OperationsResponse> {
    return this.httpClient.get<OperationsResponse>(`${this.hostPath}/v2/grouppolicies/master-contracts/${proposalNumber}/operations`);

  }

  askBatch$(operationCode: string, proposalNumber: string): Observable<any> {
    const req = {
      operationCode
    };
    return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/batch-operations`, req);
  }

  contractRevocation$(contractNumber: string): Observable<any> {
    return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contracts/${contractNumber}/revocations`, {});
  }

  uploadFile$(contractNumber: string, fileName: string, file: File): Observable<any> {
    let params = new HttpParams();
    params = params.append('fileName', fileName);
    const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    return this.fileReaderService.readAsArrayBuffer(file).pipe(
      filter(event => {
        return event.status === 'complete';
      }),
      mergeMap(ev => {
        return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contracts/${contractNumber}/uploads`,
          (ev as any).data, { headers, params });
      })
    );
  }

  getExternalCompanies$(): Observable<Entity[]> {
    if (this._externalCompanies.length <= 0) {
      return this.httpClient.get<Entity[]>(`${this.hostPath}/v2/common/companies`).pipe(
        map(externalCompanies => {
          this._externalCompanies = externalCompanies;
          return externalCompanies;
        })
      );
    }
    return of(this._externalCompanies);
  }

  getIntermediaries$(): Observable<Entity[]> {
    if (this._intermediaries.length <= 0) {
      return this.httpClient.get<Entity[]>(`${this.hostPath}/v2/common/intermediaries`).pipe(
        map(intermediaries => {
          this._intermediaries = intermediaries;
          return intermediaries;
        })
      );
    }
    return of(this._intermediaries);
  }

  retrieveCoinsurances$(uuid: string): Observable<CoinsuranceShareList> {
    return this.httpClient.get<CoinsuranceShareList>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/coinsurances`);
  }

  updateCoinsurances$(uuid: string, payload: CoinsuranceShareList) {
    return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/coinsurances`, payload);
  }

  retrieveIndexing$(uuid: string): Observable<Indexing> {
    return this.httpClient.get<Indexing>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/indexing`);
  }

  updateIndexing$(uuid: string, payload: BaseEntity) {
    return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/indexing`, payload);
  }

  getProductClauses$(uuid: string): Observable<GPClause[]> {
    return this.httpClient.get<GPClause[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/clauses`);
  }

  updateProductClauses$(uuid: string, payload: GPClause[]): Observable<GPClause[]> {
    return this.httpClient.post<GPClause[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/clauses`, payload);
  }

  getAvailablePayments$(uuid: string): Observable<MeanOfPayment[]> {
    return this.httpClient.get<MeanOfPayment[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/availablepayments`);
  }

  getAssetUnitsClauses$(uuid: string): Observable<GpAssetUnitsClauses[]> {
    return this.httpClient.get<GpAssetUnitsClauses[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/clauses`);
  }

  updateAssetClauses$(uuid: string, assetCode: string, payload: GPClause[]): Observable<any> {
    return this.httpClient.post(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}/clauses`,
      payload);
  }

  updateAssetUnitClauses$(uuid: string, assetCode: string, unitCode: string, sectionCode: string, payload: GPClause[]): Observable<any> {
    return this.httpClient.post(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}/units/${unitCode}/${sectionCode}/clauses`,
      payload);
  }

  retrivePolicyData$(uuid: string): Observable<GPPolicyDataField[]> {
    return this.httpClient.get<GPPolicyDataField[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/policydata`);
  }

  updatePolicyData$(uuid: string, payload: GPPolicyDataField[]) {
    return this.httpClient.post<GPPolicyDataField[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/policydata`, payload);
  }

  savePolicy$(uuid: string, policyNumber: string): Observable<IssuePolicyData> {
    let params: HttpParams = new HttpParams();
    if (policyNumber) {
      params = params.append('policyNumber', policyNumber);
    }
    return this.httpClient.post<IssuePolicyData>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/policy`, {}, {params});
  }

  savePolicyByProposalNumber$(proposalNumber: string, policyNumber: string): Observable<IssuePolicyData> {
    let params: HttpParams = new HttpParams();
    if (policyNumber) {
      params = params.append('policyNumber', policyNumber);
    }
    return this.httpClient.post<IssuePolicyData>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/policy`, {}, {params});
  }

  getDatesPostSales$(uuid: string): Observable<Factor[]> {
    return this.httpClient.get<Factor[]>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/dates`);
  }

  saveDatesPostSales$(uuid: string, payload: DatesPayload[]): Observable<SaveDateResponse> {
    return this.httpClient.put<SaveDateResponse>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/dates`, payload);
  }

  actionsPostSales$(uuid: string, actionCode: string): Observable<ActionsResponse> {
    return this.httpClient.post<ActionsResponse>
      (`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/actions?actionCode=${actionCode}`, {});
  }

  nextRoutePostSales$(uuid: string, currentroute: string): Observable<NextRouteResponse> {
    return this.httpClient.get<NextRouteResponse>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/route/${currentroute}/next`);
  }

  getPartyRolesPostSales$(uuid: string): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/roles`);
  }

  getAvailablePaymentsPostSales$(uuid: string): Observable<MeanOfPayment[]> {
    return this.httpClient.get<MeanOfPayment[]>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/availablepayments`);
  }

  getPaymentTypePagesPostSales$(uuid: string, paymentType: paymentsCode): Observable<Property> {
    return this.httpClient.get<Property>(
      `${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/properties?code=${paymentType}`);
  }

  getPaymentConfigPostSales$(uuid: string, paymentType?: paymentsIDs): Observable<PaymentConfig> {
    const payload = {
      function: 'CONTRACT',
      paymentType: paymentType ? paymentType : null
    };
    return this.httpClient.post<PaymentConfig>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/payments`, payload);
  }

  deleteRolePostSales$(uuid: string, role: string, idParty: number): Observable<DeleteRolePostSalesResponse> {
    return this.httpClient.delete<DeleteRolePostSalesResponse>
      (`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/roles/${role}/parties/${idParty}`);
  }

  setPaymentsPostSales$(uuid: string, payload: PaymentsPayload[]): Observable<PaymentsPostSalesResponse> {
    return this.httpClient.put<PaymentsPostSalesResponse>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/payments`, payload);
  }

  checksAllPostSales$(uuid: string): Observable<ChecksAllResp> {
    return this.httpClient.get<ChecksAllResp>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/roles/checksAll`);
  }

  updatePartyRolePostSales$(uuid: string, subj: SubjectContract): Observable<PartyRolePostSalesResponse> {
    return this.httpClient.post<PartyRolePostSalesResponse>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/roles`, subj);
  }

  checkChangesPostSales$(uuid: string): Observable<any> {
    return this.httpClient.get<ChecksAllResp>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/roles/checkChanges`);
  }

  getSubcausePostSales$(uuid: string): Observable<SubcauseModel> {
    return this.httpClient.get<SubcauseModel>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/subcause/`);
  }

  setSubcausePostSales$(uuid: string, payload: SubcauseModel): Observable<SetSubcauseResponse> {
    return this.httpClient.post<SetSubcauseResponse>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/subcause/`, payload);
  }

  getSubcausesPostSales$(uuid: string): Observable<SubcauseModel[]> {
    return this.httpClient.get<SubcauseModel[]>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/subcauses/`);
  }

  deleteSubcausePostSales$(uuid: string): Observable<DeleteSubcauseResponse> {
    return this.httpClient.delete<DeleteSubcauseResponse>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/subcause/`);
  }

  retrieveQuestionnairesPostSales$(uuid: string, level: '1' | '2'): Observable<Surveys[]> {
    return this.httpClient.get<Surveys[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/surveys?level=${level}`);
  }

  retrieveDocumentsPostSales$(contractNumber: string, operationCode?: string): Observable<Documents[]> {
    let params = new HttpParams();
    if (operationCode) {
      params = params.append('operationCode', operationCode);
    }
    return this.httpClient.get<Documents[]>(`${this.hostPath}/v2/group-policies-postsales/draft/${contractNumber}/documents`, { params });
  }

  saveOperationPostSales$(uuid: string): Observable<SaveOperationResponse> {
    return this.httpClient.post<SaveOperationResponse>(`${this.hostPath}/v2/group-policies-postsales/draft/${uuid}/operation/`, {});
  }

  getNotifications$(uuid: string): Observable<ErrorResp[]> {
    return this.httpClient.get<ErrorResp[]>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/notifications`);
  }

  checkDerogations$(uuid: string): Observable<any> {
    return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/checkderogations`, {});
  }

  retrieveApplicationInclusionField$(proposalNumber: string, fieldCode: string): Observable<GPPolicyDataField> {
    return this.httpClient.get<any>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/inclusiondata/${fieldCode}`);
  }

  retrieveApplicationInclusionData$(proposalNumber: string, fields: GPPolicyDataField[])
    : Observable<GPAppInclusionData | GPAppInclusionMotorData> {
    return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/inclusiondata`, fields);
  }

  checkInclusionFeasible$(proposalNumber: string, fields: GPPolicyDataField[]) {
    if(!fields){
      fields = [];
    }
    return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/inclusion/feasible`, fields);
  }

  retrieveExtensionData$(uuid: string): Observable<GPExtensionDataSections> {
    return this.httpClient.get<GPExtensionDataSections>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/extensiondata`);
  }

  updateExtensionData$(uuid: string, payload: GPPolicyDataField[]) {
    return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/extensiondata`, payload);
  }

  getUnitInitialState$(): Observable<BaseEntity[]> {
    return this.httpClient.get<BaseEntity[]>(`${this.hostPath}/v2/grouppolicies/enums/asset/unit/initialstatuses`);
  }

  updatePartyOnContract$(uuid: string, party: AnagApiParty): Observable<any> {
    return this.httpClient.post<any>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/roles/${party.idLatestPhotos}`, {});
  }
  createCluster$(uuid: string, codeAsset: string): Observable<GPCluster> {
    return this.httpClient.put<GPCluster>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${codeAsset}/clusters`, {});
  }

  getAssetClasses$(assetCode: string): Observable<BaseEntity[]> {
    return this.httpClient.get<BaseEntity[]>(`${this.hostPath}/v2/products/assets/${assetCode}/classes`);
  }

  getAssetExtensions$(uuid: string, assetCode: string, classCode: string, useCode: string): Observable<BaseEntity[]> {
    return this.httpClient.get<BaseEntity[]>
      (`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}/class/${classCode}/use/${useCode}/extensions`);
  }

  getAssetUses$(assetCode: string, classCode: string): Observable<BaseEntity[]> {
    return this.httpClient.get<BaseEntity[]>(`${this.hostPath}/v2/products/assets/${assetCode}/classes/${classCode}/uses`);
  }

  getAssetSectors$(assetCode: string, classCode: string, useCode: string): Observable<BaseEntity[]> {
    return this.httpClient.get<BaseEntity[]>
      (`${this.hostPath}/v2/products/assets/${assetCode}/classes/${classCode}/uses/${useCode}/sectors`);
  }

  updateCluster$(uuid: string, codeAsset: string, clusterToUpdate: GPCluster): Observable<GPCluster> {
    return this.httpClient.post<GPCluster>(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${codeAsset}/clusters/${clusterToUpdate.code}`, clusterToUpdate);
  }

  checkCluster$(uuid: string, codeAsset: string, clusterToUpdate: GPCluster): Observable<any> {
    return this.httpClient.post<any>(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${codeAsset}/clusters/${clusterToUpdate.code}/check`, undefined);
  }

  deleteCluster$(uuid: string, codeAsset: string, codeCluster: string) {
    return this.httpClient.delete<any>(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${codeAsset}/clusters/${codeCluster}`);
  }

  getClusterListByAssetCode$(proposalNumber: string, codeAsset: string, inclusionEffDate?: string): Observable<GPLightCluster[]> {
    let params = new HttpParams();
    if (inclusionEffDate) {
      params = params.append('inclusionEffDate', inclusionEffDate);
    }
    return this.httpClient.get<any>(
      `${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/inclusiondata/assets/${codeAsset}/clusters`, {params});
  }

  getClusterDetails$(uuid: string, codeAsset: string, codeCluster: string): Observable<GPCluster> {
    return this.httpClient.get<GPCluster>(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${codeAsset}/clusters/${codeCluster}`);
  }

  getClusterDetailsFromContract$(proposalNumber: string, codeAsset: string, codeCluster: string, idMovement: string): Observable<GPCluster> {
    let params = new HttpParams();
    if (idMovement) {
      params = params.append('idMovement', idMovement);
    }
    return this.httpClient.get<GPCluster>(
      `${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/assets/${codeAsset}/clusters/${codeCluster}`, {params});
  }
  getClusterAssetUnitClausesFromContract$(proposalNumber: string, codeAsset: string, codeCluster: string, idMovement: string): Observable<GpAssetUnitsClauses[]> {
    let params = new HttpParams();
    if (idMovement) {
      params = params.append('idMovement', idMovement);
    }
    return this.httpClient.get<GpAssetUnitsClauses[]>(
      `${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/assets/${codeAsset}/clusters/${codeCluster}/clauses`, {params});
  }

  copyCluster$(uuid: string, codeAsset: string, codeCluster: string): Observable<GPCluster> {
    return this.httpClient.put<GPCluster>(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${codeAsset}/clusters/${codeCluster}/clone`, {});
  }

  getClusterAssetUnitClauses$(uuid: string, codeAsset: string, codeCluster: string): Observable<GpAssetUnitsClauses[]> {
    return this.httpClient.get<GpAssetUnitsClauses[]>(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${codeAsset}/clusters/${codeCluster}/clauses`);
  }


  updateClusterAssetClauses$(uuid: string, assetCode: string, codeCluster: string, payload: GPClause[]): Observable<any> {
    return this.httpClient.post(
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}/clusters/${codeCluster}/clauses`,
      payload);
  }

  // tslint:disable-next-line:max-line-length
  updateClusterAssetUnitClauses$(uuid: string, assetCode: string, codeCluster: string, unitCode: string, sectionCode: string, payload: GPClause[]): Observable<any> {
    return this.httpClient.post(
      // tslint:disable-next-line:max-line-length
      `${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}/clusters/${codeCluster}/units/${unitCode}/${sectionCode}/clauses`,
      payload);
  }

  retrieveAuthorizationData$(resId?: string, proposalNumber?: string): Observable<GpProposalAuthorization> {
    if (!!resId) {
      return this.httpClient.get<GpProposalAuthorization>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${resId}/authorization`);
    } else if (!!proposalNumber) {
      return this.httpClient.get<GpProposalAuthorization>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/authorization`);
    }
  }

  saveAuthorizationData$(resId: string, proposalNumber: string, payload: GpProposalAuthorization) {
    if (!!resId) {
      return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contract-drafts/${resId}/authorization`, payload);
    } else if (!!proposalNumber) {
      return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/authorization`, payload);
    }
  }

  getProductCategory$(proposalNumber: string): Observable<GPIdentificationEntity> {
    return this.httpClient.get<GPIdentificationEntity>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/product/category`);
  }

  getProductCategoryFromDraft$(uuid: string): Observable<GPIdentificationEntity> {
    return this.httpClient.get<GPIdentificationEntity>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/product/category`);
  }

  alignApplicationOfContractDraft$(resId: string) {
    return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contract-drafts/${resId}/applications/align`, {});
  }

  alignApplicationOfContract$(proposalNumber: string) {
    return this.httpClient.post(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/applications/align`, {});
  }

  retrieveClusterCommissions$(uuid: string, assetCode: string, clusterCode: string): Observable<Commission[]> {
    return this.httpClient.get<Commission[]>
      (`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}/clusters/${clusterCode}/commissions`);
  }

  updateClusterCommissions$(uuid: string, assetCode: string, clusterCode: string, payload: Commission[]): Observable<any> {
    return this.httpClient.put<any>
      (`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/assets/${assetCode}/clusters/${clusterCode}/commissions`, payload);
  }

  cloneMasterPolicy$(proposalNumber: string): Observable<IdentificationKey> {
    return this.httpClient.post<IdentificationKey>(`${this.hostPath}/v2/grouppolicies/contracts/${proposalNumber}/clone`, {});
  }

  createAmendmentProposal$(proposalNumber: string, effDate: string): Observable<any> {
    let params = new HttpParams();
    if (effDate) {
      params = params.append('effectiveDate', effDate);
    }
    return this.httpClient.post<NewFlowPostSalesResponse>
      (`${this.hostPath}/v2/group-policies-postsales/${proposalNumber}/amendment/draft`, {}, {params});
  }

  getMPBalanceDetail$(contractNumber: string, idMovement: string) {
    let params = new HttpParams();
    if (idMovement) {
      params = params.append('idMovement', idMovement);
    }
    return this.httpClient.get<ContractDetails>(`${this.hostPath}/v2/grouppolicies/contracts/${contractNumber}/balance`, {params});
  }

  masterPolicySubstitution$(proposalNumber: string): Observable<IdentificationKey> {
    return this.httpClient.post<IdentificationKey>(`${this.hostPath}/v2/group-policies-postsales/${proposalNumber}/substitute`, {});
  }

  recoverApplications$(proposalNumber: string, payload: GPRecoverAppsPayload): Observable<any> {
    return this.httpClient.post<any>
      (`${this.hostPath}/v2/group-policies-postsales/${proposalNumber}/substitutionproposals/recoverapplications`, payload);
  }

  manualPolicyNumber$(uuid: string): Observable<ResultMessage> {
    return this.httpClient.get<ResultMessage>(`${this.hostPath}/v2/grouppolicies/contract-drafts/${uuid}/manualpolicynumber`, {});
  }

  manualPolicyNumberFromProposal$(proposalNumber: string): Observable<ResultMessage> {
    return this.httpClient.get<ResultMessage>
      (`${this.hostPath}/v2/grouppolicies/contract-drafts/${proposalNumber}/manualpolicynumberFromProposal`, {});
  }
}

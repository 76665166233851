<ng-container *ngIf="headerInstallments && headerInstallments.length">
  <div *ngFor="let headerInstalment of headerInstallments; index as i"
       [class.rgipc-font-bold]="headerInstalment.boldSection">
    <div class="rgipc-flex-center">
      <div>
        <div class="rgi-ui-text-4 rgi-ui-info">
          {{headerInstalment.installmentType | translate}}
        </div>
        <div class="rgipc-flex-baseline">
          <div class="rgi-ui-text-1 rgipc-premium-amount rgi-ui-info">
            {{ headerInstalment.amount | rgiCountryLayerNumberFormat:undefined:{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          } }}

          </div>
          <div class="rgi-ui-text-5 rgi-ui-info">
            /{{headerInstalment.amountType | translate}}
          </div>
        </div>
      </div>
      <div *ngIf="!!headerInstallments[i+1]" class="rgipc-vertical-separator"></div>
    </div>
  </div>
</ng-container>

import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { ExternalPolicy } from '../../dto/external-policy';
import { CacheService } from '../../cache.service';
import { ExternalPortfolioService } from '../../external-portfolio.service';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ExternalPortfolioSubjectsComponent } from '../external-portfolio-subjects/external-portfolio-subjects.component';
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Subscription, combineLatest } from 'rxjs';
import { ExternalPolicyWarrenty } from '../../dto/external-policy-warrenty';
import { ExternalPolicyAsset } from '../../dto/external-policy-asset';
import { HttpErrorResponse } from '@angular/common/http';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { ExtraInfo } from '../../dto/external-policy-extrainfo';
import { RgiRxTranslationService } from '@rgi/rx/i18n';




@Component({
  selector: 'claims-external-portfolio-details',
  templateUrl: './external-portfolio-details.component.html',
  styleUrls: ['./external-portfolio-details.component.scss']
})
export class ExternalPortfolioDetailsComponent implements OnInit, OnDestroy, RoutableComponent {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  public errorMessage: string = '';
  public errorMessage2: string;
  public errore: boolean;
  public classMsg: string = 'alert alert-danger';
  public classMsg2: string = 'alert alert-danger';
  public modPolicy: ExternalPolicy;
  public modWarrent: ExternalPolicyWarrenty = new ExternalPolicyWarrenty();
  public modAsset: ExternalPolicyAsset = new ExternalPolicyAsset();
  public companies: any;
  public listPolicies: Array<ExternalPolicy> = new Array<ExternalPolicy>();
  public isDisabilitato: boolean;
  public consultazione: boolean;
  public authSrv: any;
  private subscription: Subscription;
  public provinces: any[];
  public countries: any[];
  public comunisAss: any[];
  public comunisCnt: any[];
  public capsCnt: any[];
  public capsAss: any[];
  public today: NgbDate;
  public modPolicyForm: UntypedFormGroup;
  public attivaOutRangeS = '';
  public attivaOutRangeN = '';
  public outRangeVal: boolean;
  public descrCompany = '';
  public valueBar = 0;
  private policyNumberOld: string = '';
  @Output() eventSent = new EventEmitter<Response>();

  public listExtraInfo: Array<ExtraInfo> = new Array<ExtraInfo>();

  constructor(
    @Inject('authService') authService,
    @Inject('eventService') private eventService: any,
    private modalService: NgbModal,
    private servCache: CacheService,
    public formBuilder: UntypedFormBuilder,
    public translateService: RgiRxTranslationService,
    private policyServ: ExternalPortfolioService) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());

    this.authSrv = authService;

    this.companies = this.authSrv.getOperator().visibleCompanies;
    if (this.companies) {
      this.companies = this.companies.filter(this.isCompanyExt);
    }

    // if ( this.modPolicy.version != null && this.modPolicy.version !== '0' ) {
    //     this.modPolicy.origin = 'COMPAGNIA';
    // } else {
    //     this.modPolicy.origin = 'MANUALE';
    // }

    this.getProvinces();
    this.getCountries();

    // if(this.modPolicy.stateCode != null && this.modPolicy.stateCode) {
    //   this.caricaComuni('CNT');
    //   if(this.modPolicy.municipality !=  null && this.modPolicy.municipality){
    //     this.caricaCap('CNT')
    //   }
    // }

    // if(this.modPolicy.stateCodeInsured != null && this.modPolicy.stateCodeInsured) {
    //   this.caricaComuni('ASS');
    //   if(this.modPolicy.municipalityInsured !=  null && this.modPolicy.municipalityInsured){
    //     this.caricaCap('ASS')
    //   }
    // }

  }

  isCompanyExt(element, index, array) {
      return (element.aniaCode !== '142');
  }

  ngOnInit() {
    // console.log('ONINIT ********************************');

      // if(this.modPolicy.assetList !=null && this.modPolicy.assetList[0].warrentyList != null){
      //   this.modPolicyForm.controls.codTerm.setValue(this.modPolicy.assetList[0].warrentyList[0].codTerm);
      //   this.modPolicyForm.controls.riskObject.setValue(this.modPolicy.assetList[0].warrentyList[0].riskObject);
      //   this.modPolicyForm.controls.branch.setValue(this.modPolicy.assetList[0].warrentyList[0].branch);
      // }
      // if(this.modPolicy.assetList !=null){
      //   this.modPolicyForm.controls.assetInsured.setValue(this.modPolicy.assetList[0].description);
      //   this.modPolicyForm.controls.assetInsuredType.setValue(this.modPolicy.assetList[0].type);
      // }
    this.isDisabilitato = false;
    this.consultazione = false;
    this.modPolicy = this.servCache.getSelectedExternalPolicyClear();
    console.log('VERSIONE ' + this.modPolicy.version);
    console.log('VERSIONE MANUALE' + this.modPolicy.manualVersion);
    if (this.servCache.isDisabiliato() || this.modPolicy.originType === 'WS') {
          this.isDisabilitato = true;
          this.consultazione = true;
     } else {
          if ( this.modPolicy.origin !== 'MANUALE' ) {
            this.isDisabilitato = true;
          }
     }

    this.descrCompany = this.companies.find(value => value.aniaCode === this.modPolicy.company) ?
                     this.companies.find(value => value.aniaCode === this.modPolicy.company).description : '';

    this.valOutRange(this.modPolicy.outRange, true);

    this.policyNumberOld = this.modPolicy.policyNumber;
    let branch = '';
    let codTerm = '';
    let riskObject = '';
    let description = '';
    let type = '';

    if (this.modPolicy.assetList &&
      this.modPolicy.assetList[0].warrentyList &&
      this.modPolicy.assetList[0].warrentyList[0]) {
      branch = this.modPolicy.assetList[0].warrentyList[0].branch;
      codTerm = this.modPolicy.assetList[0].warrentyList[0].codTerm;
      riskObject = this.modPolicy.assetList[0].warrentyList[0].riskObject;
      this.modWarrent = this.modPolicy.assetList[0].warrentyList[0];
    }

    if (this.modPolicy.assetList && this.modPolicy.assetList[0]) {
      description = this.modPolicy.assetList[0].description;
      type = this.modPolicy.assetList[0].type;
      this.modAsset = this.modPolicy.assetList[0];
    }

    if (this.modPolicy.assetList &&
      this.modPolicy.assetList[0].extraInfoList &&
      this.modPolicy.assetList[0].extraInfoList[0]) {
      this.setExtraInfo(this.modPolicy.assetList[0].extraInfoList);
    }

    if (!this.modPolicy.assetList) {
     this.modPolicy.assetList =  new Array(1);
    }

    if (!this.modAsset.warrentyList) {
     this.modAsset.warrentyList = new Array(1);
    }

    this.modPolicyForm = this.formBuilder.group({
      policyNumber: new UntypedFormControl({value: this.modPolicy.policyNumber, disabled: this.isDisabilitato},
        Validators.nullValidator),
      suspensionDateFrom: new UntypedFormControl({value: this.modPolicy.suspensionDateFrom != null ?
               new Date(this.modPolicy.suspensionDateFrom) : this.modPolicy.suspensionDateFrom, disabled: this.isDisabilitato},
        Validators.nullValidator),
      suspensionDateTo: new UntypedFormControl({value: this.modPolicy.suspensionDateTo != null ?
               new Date(this.modPolicy.suspensionDateTo) : this.modPolicy.suspensionDateTo, disabled: this.isDisabilitato},
        Validators.nullValidator),
      premiumFrom: new UntypedFormControl({value: this.modPolicy.premiumFrom != null ?
              new Date(this.modPolicy.premiumFrom) : this.modPolicy.premiumFrom, disabled: this.isDisabilitato},
        Validators.nullValidator),
      premiumTo: new UntypedFormControl({value: this.modPolicy.premiumTo != null ?
              new Date(this.modPolicy.premiumTo) : this.modPolicy.premiumTo, disabled: this.isDisabilitato},
        Validators.nullValidator),
      ggMora: new UntypedFormControl({value: this.modPolicy.ggMora, disabled: this.isDisabilitato},
        Validators.nullValidator),
      policySplitting: new UntypedFormControl({value: this.modPolicy.policySplitting, disabled: this.isDisabilitato},
        Validators.nullValidator),
      releaseDate: new UntypedFormControl({value: this.modPolicy.releaseDate != null ?
              new Date(this.modPolicy.releaseDate) : this.modPolicy.releaseDate, disabled: this.isDisabilitato},
        Validators.nullValidator),
      policyReplacedNumber: new UntypedFormControl({value: this.modPolicy.policyReplacedNumber, disabled: this.consultazione},
        Validators.nullValidator),
      policyReplacementNumber: new UntypedFormControl({value: this.modPolicy.policyReplacementNumber, disabled: this.consultazione},
        Validators.nullValidator),
      productCode: new UntypedFormControl({value: this.modPolicy.productCode, disabled: this.isDisabilitato},
        Validators.nullValidator),
      productDescription: new UntypedFormControl({value: this.modPolicy.productDescription, disabled: this.isDisabilitato},
        Validators.nullValidator),
      branch: new UntypedFormControl({value: branch, disabled: this.isDisabilitato},
        Validators.nullValidator),
      codTerm: new UntypedFormControl({value: codTerm, disabled: this.isDisabilitato},
        Validators.nullValidator),
      riskObject: new UntypedFormControl({value: riskObject, disabled: this.isDisabilitato},
        Validators.nullValidator),
      diaria: new UntypedFormControl({value: this.modPolicy.diaria, disabled: this.isDisabilitato},
        Validators.nullValidator),
      ggDiaria: new UntypedFormControl({value: this.modPolicy.ggDiaria, disabled: this.isDisabilitato},
        Validators.nullValidator),
      agency: new UntypedFormControl({value: this.modPolicy.agency, disabled: this.isDisabilitato},
        Validators.nullValidator),
      assetInsured: new UntypedFormControl({value: description, disabled: this.consultazione},
        Validators.nullValidator),
      assetInsuredType: new UntypedFormControl({value: type, disabled: this.consultazione},
        Validators.nullValidator),
      denomination: new UntypedFormControl({value: this.modPolicy.denomination, disabled: this.isDisabilitato},
        Validators.required),
      firstName: new UntypedFormControl({value: this.modPolicy.firstName, disabled: this.isDisabilitato},
        Validators.nullValidator),
      fiscalCodeVat: new UntypedFormControl({value: this.modPolicy.fiscalCodeVat, disabled: this.isDisabilitato},
        Validators.nullValidator),
      subjectCode: new UntypedFormControl({value: this.modPolicy.subjectCode, disabled: this.isDisabilitato},
        Validators.required),
      // birthDate: new FormControl({value: this.modPolicy.birthDate, disabled: this.isDisabilitato}, Validators.nullValidator),
      // gender: new FormControl({value: this.modPolicy.gender, disabled: this.isDisabilitato}, Validators.nullValidator),
      address: new UntypedFormControl({value: this.modPolicy.address, disabled: this.isDisabilitato},
        Validators.nullValidator),
      municipality: new UntypedFormControl({value: this.modPolicy.municipality, disabled: this.isDisabilitato},
        Validators.nullValidator),
      postalCode: new UntypedFormControl({value: this.modPolicy.postalCode, disabled: this.isDisabilitato},
        Validators.nullValidator),
      province: new UntypedFormControl({value: this.modPolicy.stateCode, disabled: this.isDisabilitato},
        Validators.nullValidator),
      denominationInsured: new UntypedFormControl({value: this.modPolicy.denominationInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      firstNameInsured: new UntypedFormControl({value: this.modPolicy.firstNameInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      fiscalCodeVatInsured: new UntypedFormControl({value: this.modPolicy.fiscalCodeVatInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      subjectCodeInsured: new UntypedFormControl({value: this.modPolicy.subjectCodeInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      addressInsured: new UntypedFormControl({value: this.modPolicy.addressInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      municipalityInsured: new UntypedFormControl({value: this.modPolicy.municipalityInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      postalCodeInsured: new UntypedFormControl({value: this.modPolicy.postalCodeInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      provinceInsured: new UntypedFormControl({value: this.modPolicy.stateCodeInsured, disabled: this.isDisabilitato},
        Validators.nullValidator),
      policyCeiling: new UntypedFormControl({value: this.modPolicy.policyCeiling, disabled: this.consultazione},
        Validators.required),
      uncovered: new UntypedFormControl({value: this.modPolicy.uncovered, disabled: this.consultazione},
        Validators.nullValidator),
      miniDiscoveredValue: new UntypedFormControl({value: this.modPolicy.miniDiscoveredValue, disabled: this.consultazione},
        Validators.nullValidator),
      deductible: new UntypedFormControl({value: this.modPolicy.deductible, disabled: this.consultazione},
        Validators.nullValidator),
      regAmm: new UntypedFormControl({value: this.modPolicy.regAmm, disabled: this.consultazione},
        Validators.nullValidator),
      country: new UntypedFormControl({value: this.modPolicy.country, disabled: this.isDisabilitato},
        Validators.nullValidator),
      countryInsured: new UntypedFormControl({value: this.modPolicy.countryInsured, disabled: this.isDisabilitato},
        Validators.nullValidator)
    });
  }

  getProvinces() {
    if (this.servCache.getProvinces() == null) {
     this.policyServ.getProvincesList().subscribe( (response: any[]) => {
        // this.provinces = response;
        this.servCache.setProvinces(response);
        this.provinces = this.servCache.getProvinces();
      },
      (error: Error) => console.log(error)
      );
    } else {
     this.provinces = this.servCache.getProvinces();
    }
  }


  getCountries() {
    if (this.servCache.getCountries() == null) {
      this.policyServ.getCountriesList().subscribe( (response: any[]) => {
     // this.countries = response;
       this.servCache.setCountries(response);
       this.countries = this.servCache.getCountries();
      },
      (error: Error) => console.log(error)
      );
    } else {
     this.countries = this.servCache.getCountries();
    }
  }

  caricaComuni(type: string) {
    let prov = '';
    if (type === 'CNT') {
      this.policyServ.getTownListByProvince(this.modPolicyForm.controls.province.value).subscribe( (response: any[]) => {
        this.comunisCnt = response;
      },
        (error: Error) => console.log(error)
      );
    } else if (type === 'ASS') {
      prov = this.modPolicyForm.controls.provinceInsured.value;
      this.policyServ.getTownListByProvince(this.modPolicyForm.controls.provinceInsured.value).subscribe( (response: any[]) => {
        this.comunisAss = response;
      },
        (error: Error) => console.log(error)
      );
    }
  }

  caricaCap(type: string) {
    if (type === 'CNT') {
      for (const valore of this.comunisCnt) {
        if (valore.descrizione === this.modPolicyForm.controls.municipality.value) {
            this.capsCnt = valore.listaCap;
            if (this.capsCnt.find(aCap => aCap === this.modPolicyForm.controls.postalCode.value) == null) {
              this.capsCnt.push(this.modPolicyForm.controls.postalCode.value);
            }
            break;
        }
      }
    } else if (type === 'ASS') {
      for (const valore of this.comunisAss) {
        if (valore.descrizione === this.modPolicyForm.controls.municipalityInsured.value) {
            this.capsAss = valore.listaCap;
            if (this.capsAss.find(aCap => aCap === this.modPolicyForm.controls.postalCodeInsured.value) == null) {
              this.capsAss.push(this.modPolicyForm.controls.postalCodeInsured.value);
            }
            break;
        }
      }
    }
  }

  back() {
    this.listPolicies.push(this.modPolicy);
    this.servCache.setListExternalPolicy(this.listPolicies);
    this.navigation.emit('searchResult');
  }

  annulla() {
    this.valueBar = 0;
    this.valueBar = 20;
    this.subscription = this.policyServ.deletePolicy(this.modPolicy.policyId).subscribe( (response: any) => {
      this.eventSent.emit(response);
      this.valueBar = 100;
      if ((response.status && response.status !== 200) || (response.retCode && response.retCode === 'ERRORE') ||
          (response.retCode && response.retCode !== '000')) {
        this.errore = true;
        this.translateService.translate('_CLAIMS_._MESSAGE_._UPLOAD_ERROR').subscribe(res=> {
          this.errorMessage=res + ': ' + (response.status ? response.status : '') + '  '
          + (response.retCode ? response.retCode : '') + '  ' + (response.errori ? response.errori : '') + '  '
          + (response.message ? response.message : '');
        }
        )
      } else {
        this.errore = true;
        this.classMsg = 'alert alert-success';
        this.translateService.translate('_CLAIMS_._MESSAGE_._OPERATION_PROPERLY_TERMINATED').subscribe(res=> {
          this.errorMessage=res;
        }
        )
      }
      // alert('POLIZZA ANNULLATA');
      // this.alertService.translatedMessage('Polizza esterna salvata');
    },
    (error: HttpErrorResponse) => {
      this.eventSent.emit(null);
      this.errorMessage = error.message;
      this.valueBar = 100;
    });
  }

  conferma() {
    this.valueBar = 0;
    this.modPolicy.policyNumber = this.modPolicyForm.controls.policyNumber.value;
    this.modPolicy.suspensionDateTo = this.modPolicyForm.controls.suspensionDateTo.value;
    this.modPolicy.suspensionDateFrom = this.modPolicyForm.controls.suspensionDateFrom.value;
    this.modPolicy.premiumFrom = this.modPolicyForm.controls.premiumFrom.value;
    this.modPolicy.premiumTo = this.modPolicyForm.controls.premiumTo.value;
    this.modPolicy.releaseDate = this.modPolicyForm.controls.releaseDate.value;
    this.modPolicy.ggMora = this.modPolicyForm.controls.ggMora.value;
    this.modPolicy.outRange = this.outRangeVal.toString();
    this.modPolicy.policyReplacedNumber = this.modPolicyForm.controls.policyReplacedNumber.value;
    this.modPolicy.policyReplacementNumber = this.modPolicyForm.controls.policyReplacementNumber.value;
    this.modPolicy.productCode = this.modPolicyForm.controls.productCode.value;
    this.modPolicy.productDescription  = this.modPolicyForm.controls.productDescription.value;
    this.modWarrent.branch = this.modPolicyForm.controls.branch.value;
    this.modWarrent.codTerm = this.modPolicyForm.controls.codTerm.value;
    this.modWarrent.riskObject = this.modPolicyForm.controls.riskObject.value;
    this.modPolicy.diaria = this.modPolicyForm.controls.diaria.value;
    this.modPolicy.ggDiaria = this.modPolicyForm.controls.ggDiaria.value;
    this.modPolicy.agency = this.modPolicyForm.controls.agency.value;
    this.modPolicy.assetInsured = this.modPolicyForm.controls.assetInsured.value;
    this.modAsset.description = this.modPolicyForm.controls.assetInsured.value;
    this.modAsset.type = this.modPolicyForm.controls.assetInsuredType.value;
    this.modPolicy.denomination = this.modPolicyForm.controls.denomination.value;
    this.modPolicy.firstName   = this.modPolicyForm.controls.firstName.value;
    this.modPolicy.fiscalCodeVat = this.modPolicyForm.controls.fiscalCodeVat.value;
    this.modPolicy.subjectCode = this.modPolicyForm.controls.subjectCode.value;
    this.modPolicy.policySplitting = this.modPolicyForm.controls.policySplitting.value;
    this.modPolicy.country     = this.modPolicyForm.controls.country.value;
    this.modPolicy.countryInsured     = this.modPolicyForm.controls.countryInsured.value;
    this.modPolicy.address     = this.modPolicyForm.controls.address.value;
    this.modPolicy.municipality = this.modPolicyForm.controls.municipality.value;
    this.modPolicy.postalCode  = this.modPolicyForm.controls.postalCode.value;
    this.modPolicy.stateCode   = this.modPolicyForm.controls.province.value;
    this.modPolicy.denominationInsured = this.modPolicyForm.controls.denominationInsured.value;
    this.modPolicy.firstNameInsured    = this.modPolicyForm.controls.firstNameInsured.value;
    this.modPolicy.subjectCodeInsured  = this.modPolicyForm.controls.subjectCodeInsured.value;
    this.modPolicy.fiscalCodeVatInsured = this.modPolicyForm.controls.fiscalCodeVatInsured.value;
    this.modPolicy.addressInsured = this.modPolicyForm.controls.addressInsured.value;
    this.modPolicy.municipalityInsured = this.modPolicyForm.controls.municipalityInsured.value;
    this.modPolicy.postalCodeInsured   = this.modPolicyForm.controls.postalCodeInsured.value;
    this.modPolicy.stateCodeInsured    = this.modPolicyForm.controls.provinceInsured.value;
    this.modPolicy.policyCeiling = this.modPolicyForm.controls.policyCeiling.value;
    this.modPolicy.uncovered   = this.modPolicyForm.controls.uncovered.value;
    this.modPolicy.miniDiscoveredValue = this.modPolicyForm.controls.miniDiscoveredValue.value;
    this.modPolicy.deductible  = this.modPolicyForm.controls.deductible.value;
    this.modPolicy.regAmm = this.modPolicyForm.controls.regAmm.value;
    this.valueBar = 10;
    this.errore = false;
    this.classMsg = 'alert alert-danger';
    this.classMsg2 = 'alert alert-danger';
    this.errorMessage = '';
    this.errorMessage2 = null;

    this.validazioneDatiImporti();

    if (!this.isDisabilitato) {
      this.validazioneDati();
    }
    if (!this.errore) {
        this.eventService.broadcastEvent('start-loader');
        this.modAsset.warrentyList[0] = this.modWarrent;
        this.modPolicy.assetList[0] = this.modAsset;
        this.valueBar = 40;
        this.valueBar = 70;
        this.subscription = this.policyServ.modifyPolicy(this.modPolicy).subscribe( (response: any) => {
        this.eventSent.emit(response);
        if ((response.status && response.status !== 200) || (response.retCode && response.retCode === 'ERRORE') ||
            (response.retCode && response.retCode !== '000')) {
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._UPLOAD_ERROR').subscribe(res=> {
            this.errorMessage=res+ ': ' + (response.status ? response.status : '') + '  '
            + (response.retCode ? response.retCode : '') + '  ' + (response.errori ? response.errori : '') + '  '
            + (response.message ? response.message : '');
          }
          )

          this.valueBar = 100;
        } else {
          const obs$1 = this.translateService.translate('_CLAIMS_._UPDATE');  
          const obs$2 = this.translateService.translate('_CLAIMS_._POLICY_DATA');
          const obs$3 = this.translateService.translate('_CLAIMS_._OPERATION_PROPERLY_TERMINATED'); 
          combineLatest([
            obs$1,
            obs$2,
            obs$3,
          ]).subscribe(([update,policyData,operation]) => {
          this.errore = true;
          this.classMsg = 'alert alert-success';
          this.errorMessage= update + ' ' + policyData + ' - ' + operation;
          }
        )
          console.log('Allineamento idPolizza e versione');
          this.modPolicy.policyId =  response.polizza.idPolizza;
          this.modPolicy.manualVersion = response.polizza.versioneManuale;
          this.modPolicy.version =  response.polizza.versione;
          console.log('Aggiornamento numero polizza');
          console.log(this.policyNumberOld);
          console.log(this.modPolicy.policyNumber);
          
          if (this.policyNumberOld !== this.modPolicy.policyNumber) {
            this.policyServ.updatePolicyNumber (this.policyNumberOld, this.modPolicy.policyNumber, response.polizza.idPolizza)
            .subscribe ((response2: any) => {
              const obs$1 = this.translateService.translate('_CLAIMS_._UPDATE');  
              const obs$2 = this.translateService.translate('_CLAIMS_._POLICY_NUMBER');
              combineLatest([
                obs$1,
                obs$2
              ]).subscribe(([update, policyNumber]) => {
                this.errorMessage2= '\n' + update + ' ' + policyNumber + ' - ' + this.translateService.translate(response2.descr);
              }
            )
              if (response2.code === 'OK') {
                 this.classMsg2 = 'alert alert-success';
              }
              this.valueBar = 100;
            });
          } else {
            this.valueBar = 100;
          }
        }
        this.eventService.broadcastEvent('stop-loader');
        // this.alertService.translatedMessage('Polizza esterna salvata');
      },
      (error: HttpErrorResponse) => {
        this.eventSent.emit(null);
        this.errore = true;
        this.errorMessage = error.message;
        this.valueBar = 100;
        this.eventService.broadcastEvent('stop-loader');
      });
    } else {
      this.valueBar = 0;
    }
  }


  selectSubject(modPolicy: ExternalPolicy, tipoSogg: string) {

    // this.emitter.broadcastEvent('start-loader');

    // const policyDetail = this.translatorService.apiPolicyToDtoPolicy(contractRes);

    const modalRef = this.modalService.open(ExternalPortfolioSubjectsComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.modPolicy = this.modPolicy;
    modalRef.componentInstance.modPolicyForm = this.modPolicyForm;
    modalRef.componentInstance.tipoSogg = tipoSogg;
    modalRef.componentInstance.provinces = this.provinces;
    modalRef.componentInstance.countries = this.countries;
    modalRef.componentInstance.comunisCnt = this.comunisCnt;
    modalRef.componentInstance.comunisAss = this.comunisAss;
 }

 valOutRange(code: string, forza: boolean) {
    if (!this.isDisabilitato || forza) {
      if (code === 'S' || code === 'true') {
        this.attivaOutRangeS = 'active';
        this.attivaOutRangeN = '';
        this.outRangeVal = true;
      } else {
        this.attivaOutRangeS = '';
        this.attivaOutRangeN = 'active';
        this.outRangeVal = false;
      }
    }
  }

  validazioneDatiImporti() {
    const n1 = this.modPolicy.uncovered ? parseFloat(this.modPolicy.uncovered + '') : 0;
    const n2 = this.modPolicy.deductible ? parseFloat(this.modPolicy.deductible + '') : 0;
    const n3 = this.modPolicy.miniDiscoveredValue ? parseFloat(this.modPolicy.miniDiscoveredValue + '') : 0;
    const n4 = this.modPolicy.policyCeiling ? parseFloat(this.modPolicy.policyCeiling + '') : 0;

    if (isNaN(n1.valueOf()) || isNaN(n2.valueOf()) || isNaN(n3.valueOf()) || isNaN(n4.valueOf())) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._INCOMPLETE_COMPILATION').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.errore = true;
    }

    if (this.modPolicy.uncovered < 0 || this.modPolicy.deductible < 0 ||
      this.modPolicy.miniDiscoveredValue < 0 || this.modPolicy.policyCeiling < 0 ||
      this.modPolicy.uncovered > 100) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._INCOMPLETE_COMPILATION').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.errore = true;
    }
  }

  validazioneDati() {
    if (
      (!this.modPolicy.company || this.modPolicy.company == null) ||
      (!this.modPolicy.policyNumber || this.modPolicy.policyNumber == null) ||
      (!this.modPolicy.effectDate || this.modPolicy.effectDate == null) ||
      (!this.modPolicy.expirationDate || this.modPolicy.expirationDate == null) ||
      (!this.modPolicy.subjectCode || this.modPolicy.subjectCode == null) ||
    //  (!this.modPolicy.subjectCodeInsured || this.modPolicy.subjectCodeInsured == null) ||
      (!this.modPolicy.denomination || this.modPolicy.denomination == null) ||
    //  (!this.modPolicy.denominationInsured || this.modPolicy.denominationInsured == null) ||
      // (!this.modPolicy.fiscalCodeVat || this.modPolicy.fiscalCodeVat == null) ||
      // (!this.modPolicy.fiscalCodeVatInsured || this.modPolicy.fiscalCodeVatInsured == null) ||
       (this.modPolicy.policyCeiling == null)
      ) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._MANDATORY_DATA_MISSING').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.errore = true;
    }

    if ((this.modPolicy.policyReplacedNumber != null && this.modPolicy.policyReplacementNumber == null) ||
       (this.modPolicy.policyReplacedNumber == null && this.modPolicy.policyReplacementNumber != null) ||
       (this.modPolicy.subjectCode === 'F' && this.modPolicy.firstName == null) ||
       (this.modPolicy.subjectCodeInsured === 'F' && this.modPolicy.firstNameInsured == null)) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._INCOMPLETE_COMPILATION').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
    }

    if (this.modPolicy.country === 'IT' && this.modPolicy.fiscalCodeVat) {
      this.verifyFiscalCode(this.modPolicy.fiscalCodeVat);
    }

    if (this.modPolicy.countryInsured === 'IT' && this.modPolicy.fiscalCodeVatInsured) {
      this.verifyFiscalCode(this.modPolicy.fiscalCodeVatInsured);
    }

    if (this.errore) {
        this.modPolicy.assetList =  new Array(1);
        this.modAsset.warrentyList = new Array(1);
        return;
    }
  }

  verifyFiscalCode(cfPiva) {
    if (cfPiva.length !== 0 && cfPiva.length !== 16 && cfPiva.length !== 11 &&  cfPiva.length !== 7) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE__VAT_UNCORRECT').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      this.errore = true;
    }

    if (cfPiva.length === 7) {
      // PIVA SANMARINO
      const n1 = parseFloat(cfPiva.substring(0, 2));
      const n2 = parseFloat(cfPiva.substring(2, cfPiva.length));

      if (!isNaN(n1.valueOf()) || isNaN(n2.valueOf())) {
        console.log('KO PIVA SM');
       
        this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_UNCORRECT').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
      } else { console.log('OK PIVA SM'); }

    }

    if (cfPiva.length === 11) {
      // PIVA ITALIANA
      const n = parseFloat(cfPiva);
      console.log(n);

      if (isNaN(n.valueOf())) {
        console.log('KO PIVA');
        this.translateService.translate('_CLAIMS_._MESSAGE_._VAT_UNCORRECT').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.errore = true;
      } else { console.log('OK PIVA'); }

    }

    if (cfPiva.length === 16) {
      // CODICE FISCALE ITALIANO
      for (let i = 0; i < cfPiva.length; i++) {
        const aChar = cfPiva.charAt(i);
        console.log(aChar);
        const n = parseFloat(aChar);
        console.log(n);

        if (i < 6 || i === 8 || i === 11 || i === 15) {
          // il carattene non deve essere numerico
          if ( !isNaN(n.valueOf()) ) {
            console.log('KO');
            this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE_UNCORRECT').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            this.errore = true;
          } else { console.log('OK'); }
        }
        if ((i >= 6 && i < 8) || (i >= 9 && i < 11) || (i >= 12 && i < 15)) {
          // il carattene non deve essere numerico
          if (isNaN(n.valueOf())) {
            console.log('KO');
            this.translateService.translate('_CLAIMS_._MESSAGE_._FISCAL_CODE_UNCORRECT').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            this.errore = true;
          } else { console.log('OK'); }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
       this.subscription.unsubscribe();
    }
    this.valueBar = 0;
  }


  setExtraInfo(arExtraInfo: ExtraInfo[]) {

    for (const extraInfo of arExtraInfo) {

      this.listExtraInfo.push(extraInfo);

      if (extraInfo.sottoLivelli != null && extraInfo.sottoLivelli.length > 0) {
        this.setExtraInfo(extraInfo.sottoLivelli);
      }

    }
  }

}

import {GenericEntity} from './generic-entity';

export class Enumerated extends GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public additionalData: Map<string, string>) {
    super(id, code, description);
  }
}

import {EquipmentType} from './equipmentType';

export class Equipment {

  constructor(
    public type: EquipmentType,
    public appearanceDate: Date,
    public updatingDate: Date,
    public creationDate: Date,
    public priceListEuro: number,
    public statusCod: string,
    public stutusDescription: string
  ) {
  }
}

import { ExternalPolicy } from '../../../../external-portfolio/dto/external-policy';

export class SimilarclaimsCriteriaEntity {
    public policyNumber: string;
    public occurrenceDate: Date;
    public companyCode: string;
    public typePtf: string;
    public branchesCodes: Array<string>;
    public externalPolicy: ExternalPolicy;
}

import {RGI_RX_DATEPICKER_i18n} from './i18n/localization';
import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';

export function loadDatePickeri18nIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DATEPICKER_i18n.it);
  });
}

export function loadDatePickeri18nEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DATEPICKER_i18n.en);
  });
}

export function loadDatePickeri18nFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DATEPICKER_i18n.fr);
  });
}

export function loadDatePickeri18nES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DATEPICKER_i18n.es);
  });
}

export function loadDatePickeri18nDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_DATEPICKER_i18n.de);
  });
}


export const RGI_RX_DATEPICKER_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: loadDatePickeri18nIT
  },
  {
    locale: 'en',
    load: loadDatePickeri18nEN
  },
  {
    locale: 'fr',
    load: loadDatePickeri18nFR
  },
  {
    locale: 'es',
    load: loadDatePickeri18nES
  },
  {
    locale: 'de',
    load: loadDatePickeri18nDE
  }
];

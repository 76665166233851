<label class="switch">
  <input #input type="checkbox"
                  [id]="inputId"
                  [attr.name]="inputName"
                  [attr.data-qa]="qa"
                  [checked]="checked"
                  [disabled]="disabled"
                  [required]="required"
                  (change)="onChangeEvent($event)"
                  (click)="onInputClick($event)"
                  />
  <span class="slider"></span>
</label>

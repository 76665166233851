import { Observable, of } from 'rxjs';
import { AvailableOperation } from '../../../models/availableOperation';
import { Policy } from '../../../models/life-detail.model';
import { MOCK_POLICY, MOCK_POLICY_DETAIL } from '../../../models/mock/mock-policy';
import {
  PostsalesError,
  PostsalesOperationObject,
  PostsalesOperationsResponse
} from '../../../models/postsales-operations-response.model';
import { PostsalesSession } from '../../../models/postsales-session';
import { PostsalesOperations } from '../../../services/postsales-operations.interface';
import { PlcResponseUtils } from '../../../utils/plc-response-utils';
import { AbsOperationComponent } from '../../abs-operation-component/abs-operation.component';
import { MOCK_TERMINATE_RESCISSION_RESPONSE } from './mock-terminate-rescission.response';
import {DocumentService} from '../../../services/document.service';
import { Injectable } from "@angular/core";

@Injectable()
export class MockTerminateRescissionService implements PostsalesOperations {

  constructor() { }

  baseUrl: string;
  errors: PostsalesError[];
  lastInitializedSession: PostsalesSession;
  session: PostsalesSession;
  documentsConfig: any;

  createDraft(session: PostsalesSession): Observable<PostsalesOperationObject> {
    return of(PlcResponseUtils.responseToObject(MOCK_TERMINATE_RESCISSION_RESPONSE));
  }

  getAvailableOperations(): Observable<AvailableOperation[]> {
    return undefined;
  }

  getPolicy(polNum: string, salePointId: string): Observable<Policy> {
    return of(MOCK_POLICY);
  }

  getPolicyDetail(polNum: string, spId: any): Observable<any> {
    return of(MOCK_POLICY_DETAIL);
  }

  publish(session: PostsalesSession, note: string): Observable<PostsalesOperationsResponse> {
    return of(MOCK_TERMINATE_RESCISSION_RESPONSE);
  }

  onEndPublish(response, publishAndAccept: boolean): Observable<PostsalesOperationsResponse> {
    return of(null);
  }

  updateDraft(
    session: PostsalesSession,
    dates: { [p: string]: Date },
    data: { [p: string]: any },
    operationRoles: { [p: string]: any },
    modified: { [p: string]: boolean },
    step?: string,
    reload?: boolean,
    opDataType?: string
  ): Observable<PostsalesOperationObject> {
    return of(PlcResponseUtils.responseToObject(MOCK_TERMINATE_RESCISSION_RESPONSE));
  }

  passFlowDraftByOPCode(
    policyNumber: string,
    operationDate: string,
    operationCode: string,
    operation: string,
    amountSegragatedFunds?: number,
    amountNotSegragatedFunds?: number,
    payMode?: any,
    ticketId?: any,
    ticketIdCrypt?: any,
    questionnairesToSave?: any,
    amountValue?: number,
    issuedateCode?: string,
    effectdateCode?: string
  ): Observable<any> {
    return undefined;
  }

  passFlowPublishedByOPCode(policyNumber: any, idDraft: any, operationCode: string): Observable<any> {
    return undefined;
  }

  checkOperationRolesOnNext(subjects: any[]): Observable<any> {
    return of([]);
  }

  handleOperationRolesCreate(subjects: any[]) {
  }

  checkQuestionnaireOnNext(caller: AbsOperationComponent, quest: any, step: string) {
    return of([]);
  }

  getDocumentService(): DocumentService {
    return undefined;
  }

}

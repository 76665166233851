<ng-container>
  <div class="rgi-ui-text-3 rgi-gp-mb-m" translate *ngIf="showTitle">
    _GP_._TITLE_._GUARANTEES_
  </div>
  <div class="rgi-gp-mb-l" *ngFor="let unit of units">
    <rgi-rx-expansion-panel [attr.data-qa]="unit.unit.code" [ngClass]="{'rgi-gp-collapsed': (!unit.unit.listVariable || !unit.unit.listVariable.length) && (!unit.unit.clauses || !unit.unit.clauses.length) }">
      <div rgi-rx-expansion-panel-header color="default">
        <div class="rgi-gp-font-family rgi-gp-flex" rgi-rx-expansion-panel-label>
          {{unit.unit.description}}
          <div data-qa="rgi-gp-initialstatus-descr">
            &nbsp;{{ '- ' + unit.unit.startStatusDescr }}
          </div>
        </div>
      </div>
      <ng-template rgiRxExpansionPanelContent>
        <div *ngIf="getUnitDetails(unit.unit.listVariable, unit.unit.commissions)" class="rgi-gp-mb-m">
          <div class="rgi-ui-text-3 rgi-gp-mb-m" translate>
            _GP_._TITLE_._VARIABLES_
          </div>
          <rgi-gp-generic-detail [details]="getUnitDetails(unit.unit.listVariable, unit.unit.commissions)">
          </rgi-gp-generic-detail>
        </div>
        <div *ngIf="unit.unit.clauses">
          <rgi-gp-clauses-detail [clauses]="unit.unit.clauses"></rgi-gp-clauses-detail>
        </div>
      </ng-template>
    </rgi-rx-expansion-panel>
    <ng-container *ngIf="!!unit.linkedUnit">
      <div *ngFor="let linkedUnit of unit.linkedUnit">
        <rgi-rx-expansion-panel [attr.data-qa]="linkedUnit.code" [ngClass]="{'rgi-gp-collapsed': !linkedUnit.listVariable || !linkedUnit.listVariable.length}">
          <div rgi-rx-expansion-panel-header color="default">
            <div class="rgi-gp-font-family rgi-gp-flex" rgi-rx-expansion-panel-label>
              {{linkedUnit.description}}
              <div data-qa="rgi-gp-linkedUnit-initialstatus-descr">
                &nbsp;{{ '- ' + linkedUnit.startStatusDescr }}
              </div>
            </div>
          </div>
          <ng-template rgiRxExpansionPanelContent>
            <rgi-gp-generic-detail [details]="getUnitDetails(linkedUnit.listVariable,unit.unit.commissions)">
            </rgi-gp-generic-detail>
          </ng-template>
        </rgi-rx-expansion-panel>
      </div>
    </ng-container>
  </div>
</ng-container>

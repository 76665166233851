export class AnagIssueSubjectType {
    codice: string;
    descrizione: string;
}

export class AnagIssueSubjectTypeEmail {
}

export class AnagIssuePhoneNumber {
    extensionSubject: string;
    internationalPrefix: string;
    localPrefix: string;
    number: string;
    objectId: string;
}

export class AnagIssuePartyKeyType {
    codice: string;
    descrizione: string;
}

export class AnagIssueKey {
    label: string;
    state: string;
    type: AnagIssuePartyKeyType;
    value: string;
}

export class AnagIssuePartyKey {
    objectId?: string | null;
    key1?: AnagIssueKey | null;
    country?: string | null;
    key2?: AnagIssueKey | null;
    extensionSubject?: string | null;
    key3?: AnagIssueKey | null | undefined;
    main?: boolean;
}

export class AnagIssuePersonType {
    codice: string;
    descrizione: string;
}

export class AnagIssueSubjectCountry {
    codice: string;
    descrizione: string;
}

export class AnagIssueToponym {
    codice: string;
    descrizione: string;
}

export class AnagIssueProvince {
    codice: string;
    descrizione: string;
}

export class AnagIssueApiResidence {
    country: AnagIssueSubjectCountry;
    city: string;
    cab: string;
    normalized: boolean;
    latitude: string;
    longitude: string;
    placeAddress: string;
    number: string;
    toponym: AnagIssueToponym;
    cap: string;
    province: AnagIssueProvince;
    addressAddition: any;
    specialMention: any;
    countryCode: string;
    adminLevel3Short: string;
    formatAddress: string;
    adminLevel3: string;
    adminLevel1: string;
    subLocality: string;
    adminLevel2: string;
    locality: string;
    detailCode: string;
    adminLevel2Short: string;
    fraction: string;
    at: string;
    postalAddress: string;
    extensionSubject: string;
    adminLevel1Short: string;
}

export class AnagIssueSubject {
    objectId?: string;
    vat: string | undefined;
    dateOfBirth: string;
    subjectType: AnagIssueSubjectType;
    denomination: string | null;
    emails: Array<string>;
    node: AnagIssueApiNode;
    cityOfBirth: string | undefined;
    mobilePhone: Array<AnagIssuePhoneNumber>;
    countryOfBirth: string | undefined;
    birthAddress: AnagIsssueBirthAddress;
    extensionSubject: any | undefined;
    surname: string;
    fiscalCode: string;
    name: string;
    company: AnagIssuePartyKeyType;
    partyKey: Array<AnagIssuePartyKey>;
    personType: AnagIssuePersonType;
    partyNumber: string;
    idLatestPhotos?: string | null;
    nominative: string | null;
    privacyConsensus: any;
    residence: AnagIssueApiResidence | null;
    professionDetail: AnagIssueApiEnum;
    professionType: AnagIssueApiEnum;
    clientNumber: string;
    payments: any;
}

export class AnagIssueApiNode {
    public identification: string;
    public code: string;
    public description: string;
}

export class AnagIssueSex {
    codice: string;
    descrizione: string;
}

export class AnagIsssueBirthAddress {
    public countryCode: string | null;
    public adminLevel1: string | null;
    public adminLevel1Short: string | null;
    public adminLevel2: string | null;
    public adminLevel2Short: string | null;
    public adminLevel3: string | null;
    public adminLevel3Short: string | null;
    public cap: string | null;
    public normalized: boolean;
    public locality: string | null;
    public subLocality: string | null;
    public toponym: any;
    public placeAddress: string | null;
    public number: string | null;
    public at: string | null;

}

export class AnagIsssueResidence {
    public country: any;
    public province: any;
}

export class AnagIssueApiEnum {
    public descrizione?: string;
    public codice: string;
}

export class AnagIssueApiEnumEng {
    public description?: string;
    public code: string;
}


export class AnagIssueApiSubjectApi extends AnagIssueSubject {
    // public objectId?: string;
    public sex: AnagIssueSex;
    public dateOfBirth: string;
    public denomination: string | null;
    public subjectType: AnagIssueSubjectType;
    public emails: Array<string>;
    public node: AnagIssueApiNode;
    public mobilePhone: Array<AnagIssuePhoneNumber>;
    public surname: string;
    public fiscalCode: string;
    public name: string;
    public company: AnagIssuePartyKeyType;
    public partyKey: Array<AnagIssuePartyKey>;
    public personType: AnagIssuePersonType;
    public idLatestPhotos?: string;
    public privacyConsensus: any;
    public birthAddress: AnagIsssueBirthAddress;
    // public residence: AnagIsssueResidence;
    public professionType: AnagIssueApiEnum;
    public professionDetail: AnagIssueApiEnum;
    public citizenship: AnagIssueApiEnum;
    public privacyConsensusDate: string;
    public maritalStatus: AnagIssueApiEnum;
    public sottogruppoAE: AnagIssueApiEnumEng;

}

export class AnagIssueSearchResultRequest {
    public firstName: string;
    public lastName: string;
    public nationality: string;
    public partyKey: AnagIssuePartyKey | null;
    public vat: string;
    public socialSecurityNumber: string;
    public nodeId: string;
    public node: any;
    public policyRole: string;

    public constructor() {
    }

}

export class AnagIssueSubjectsFilter {
    filter: {
        address: {
            baseAddress: any;
        },
        surnameOrCompanyName: string;
        name: string;
        partyKey: AnagIssuePartyKey | null;
        subsysReference: any;
        nodeId: string;
        policyRole: string | undefined;
    };
}

export class AnagIssueGetSubjectQueryString {
    public name: string;
    public surname: string;
}

export class AnagIssueApiServiceInfo {
    public executionId: string;
    public logs: string;
    public codiceErroreOccorso: string;
    public tipoErroreOccorso: string;
    public descrizioneErroreOccorso: string;
    public dettagliSullErroreOccorso: string;
    public eccezioneOccorsaSerializzata: string;
}

export class AnagIssueApiResponse {
    public serviceInfo: AnagIssueApiServiceInfo;
}

export class AnagIssueApiSubjects extends AnagIssueApiResponse {
    public output: AnagIssueApiSubjectsOutput;
}

export class AnagIssueApiSubjectsOutput {
    public totalCounter: string;
    public outcome: any;
    public subjects: Array<AnagIssueSubject>;
}

export class AnagIssueCreateSubjectRequest {
    subject: AnagIssueApiSubjectApi;
}

export class AnagIssueApiCreateSubject extends AnagIssueApiResponse {
    public subject: AnagIssueApiSubjectApi;
}

export class AnagIssueApiGetSubjectResponse {
    public outcome: any;
    public subject: AnagIssueSubject;
}

export class AnagIssueApiCompleteSubjectResponse {
    public outcome: any;
    public subject: AnagIssueApiSubjectApi;
}

export class AnagIssueCountry {
    public objectId: string;
    public ext: any;
    public description: string;
    public abbreviation: string;
    public taxCode: string;
    public uiccode: string;
}

export class PcCountry {
    additionalLabels: any;
    code: string;
    description: string;
}












